import { Query } from "@datorama/akita";
import { VitalState, vitalStore, VitalStore } from "./vital.store";

/**
 * Provides vital queries
 */
export class VitalQuery extends Query<VitalState> {
  latestVital$ = this.select((state) => state.latestVital);
  vitals$ = this.select(state => state.vitals);
  vitalsAverage$ = this.select(state => state.vitalsAverage);
  totalVitals$ = this.select(state => state.totalVitals);
  view$ = this.select(state => state.view);
  mode$ = this.select(state => state.mode);
  startDate$ = this.select(state => state.startDate);
  endDate$ = this.select(state => state.endDate);
  selectedPage$ = this.select(state => state.selectedPage);
  pageSize$ = this.select(state => state.pageSize);

  constructor(protected store: VitalStore) {
    super(store);
  }
}

export const vitalQuery = new VitalQuery(vitalStore);
