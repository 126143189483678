export function descendingComparator<T>(a: T, b: T, orderBy: any, objectKey?: string | null) {
    let aValue = a[orderBy] ? isNaN(Number(a[orderBy])) ? a[orderBy].toString().toLowerCase() : a[orderBy] : "";
    let bValue = b[orderBy] ? isNaN(Number(a[orderBy])) ? b[orderBy].toString().toLowerCase() : b[orderBy] : "";
    if (objectKey) {
        aValue = a[objectKey][orderBy] ? isNaN(Number(a[objectKey][orderBy])) ? a[objectKey][orderBy].toString().toLowerCase() : a[objectKey][orderBy] : "";
        bValue = b[objectKey][orderBy] ? isNaN(Number(b[objectKey][orderBy])) ? b[objectKey][orderBy].toString().toLowerCase() : b[objectKey][orderBy] : "";
    }

    if (bValue < aValue) {
        return -1;
    }
    if (bValue > aValue) {
        return 1;
    }
    return 0;
}