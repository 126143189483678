import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme) =>
  createStyles({
    notificationToolTipText: {
      fontFamily: 'Be Vietnam Pro',
      fontSize: 12,
      fontWeight: 400,
      color: colors.white,
    },
    arrow: {
      color: colors.mediumGray,
    },
    customWidth: {
      width: '244px',
      maxWidth: '244px',
      backgroundColor: colors.mediumGray,
      padding: '8px 12px',
    },
    tooltipPlacement: {
      margin: '8px 0',
    },
    newDesignWidth: {
      width: '252px',
      maxWidth: '252px',
      backgroundColor: colors.navy,
      padding: '12px 16px',
      borderRadius: 8
    },
    newDesignArrow: {
      color: colors.navy,
    }
  })
);
