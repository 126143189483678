import { Box, Divider, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { LabInputNames } from "../../inputs/constants/inputs.constants";
import { DnaTableComponent } from "../components/dnaTableComponent/DnaTableComponent";
import { HealthRecommendationComponent } from '../components/healthRecommendationComponent/HealthRecommendationComponent';
import { LabResultComponent } from "../components/labResultComponent/LabResultComponent";
import { LabelPosition, ProgressDiagramComponent } from "../components/progressDiagramComponent/ProgressDiagramComponent";
import { riskColorMap } from "../constants/healthReport.constants";
import { HealthReportMode, HealthReportSections, HealthReportVersion, recommendationRowsCapacity, RecommendationTypesNumbers } from '../models/healthReport.models';
import { HealthReportBasePage, HealthReportMobileBasePage } from "./HealthReportBasePage";
import { useStyles } from './healthReport.styles';
import { HealthReportPageProps } from "./healthReportPageProps";
import { renderScaleChartComponent } from "../helpers/renderScaleChartComponent";
import { TitlesService } from "../../../constants/title.constants";
import { LabGroup } from "../../inputs/models/input.models";
import commonUseStyles from "../../common/styles/common.styles";
import clsx from "clsx";
import { RecommendationSingleSelectComponent } from '../components/recommendationSingleSelectComponent/RecommendationSingleSelectComponent';
import { WildHealthButton } from '../../common/components/wildHealthButton/WildHealthButton';

export const HealthReportChronicDisease4Page: React.FC<HealthReportPageProps> = (props: HealthReportPageProps) => {
    const {
        report,
        mode,
        handleChanges,
        handleSaveChanges,
        isChanged,
        pageNumber,
        practice,
        recommendations,
        handleSelected,
        handleChangesNewRecommendation,
        handleAddRecommendation,
        handleSaveChangesNew,
    } = props;
    const commonClasses = commonUseStyles();
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const labValues = report.labReport.values;

    return (
        props.mode !== HealthReportMode.PrintMode && isSmallScreen ? (
            <HealthReportMobileBasePage section={HealthReportSections.ChronicDisease} pageNumber={pageNumber} practice={practice}>
                <>
                    <Box px={2}>
                        <Box>
                            <Box>
                                <span className={classes.reportTitle}>Insulin Resistance</span>
                            </Box>
                            <Box mt="10px">
                                <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeMediumReport, commonClasses.textRegularReport)}>Your Genetics and Labs</span>
                            </Box>
                            <Box fontSize="14px" lineHeight="22px">
                                <Box mt="12px">
                                    <span>
                                        Insulin is a hormone made by the pancreas and is responsible for transporting glucose
                                        from your blood stream into your cells. Insulin resistance is the reduced effectiveness
                                        of insulin at transporting glucose- the cells become “resistant to it.” There are
                                        many theories. At {TitlesService.simpleBaseTitle} we believe in the suitcase theory: if your cells are
                                        full of glucose, it becomes harder to fit glucose in them and the insulin doesn’t
                                        work as well. Thus the treatment is reducing glucose, ie consumption. If insulin
                                        resistance goes unchecked it will lead to pre-diabetes, and often
                                        full blown type 2 diabetes.
                                    </span>
                                </Box>
                                <Box mt="10px">
                                    <span>Although there are many facets to fixing insulin resistance, the basics are:</span>
                                    <br />
                                    <span>1. Diet: low carb and fasting to reduce glucose in the cells</span>
                                    <br />
                                    <span>2. Exercise. Increase glucose utilization</span>
                                    <br />
                                    <span>3. Sleep and hormonal optimization- to optimize insulin effectiveness.</span>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Divider className="report-divider" />
                    <Box px={2}>
                        <Box>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Box>
                                        <Box width={1} display="flex" justifyContent="center" mt={-2}>
                                            {renderScaleChartComponent(report.dietAndNutritionReport.carbonIntoleranceScore)}
                                        </Box>
                                        <Box display="flex" justifyContent="center" mt={2}>
                                            <span className="text-medium">
                                                Carb Intolerance: {Math.round(report.dietAndNutritionReport.carbonIntoleranceScore.scorePercent)}%
                                            </span>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box mt="20px" className="text-light-medium">
                                        <Box>
                                            <span className={classes.reportSubTitle}>Insulin Resistance Labs</span>
                                        </Box>
                                        <Box mt={1}>
                                            <LabResultComponent label="Fasting Glucose" value={labValues.getLabReportValue(LabInputNames.FastingGlucose)} />
                                            <LabResultComponent label="Fasting Insulin" value={labValues.getLabReportValue(LabInputNames.FastingInsulin)} />
                                            <LabResultComponent label="HOMA-IR" value={{
                                                name: LabInputNames.Homir,
                                                group: LabGroup.InsulinResistanceAndMetabolism,
                                                date: new Date(),
                                                value: report.alphaReport.homir,
                                                notification: null,
                                                range: null
                                            }} />
                                            <LabResultComponent label="Ferritin" value={labValues.getLabReportValue(LabInputNames.Ferritin)} />
                                            <LabResultComponent label="TRIG:HDL" value={{
                                                name: LabInputNames.TrigHdl,
                                                group: LabGroup.InsulinResistanceAndMetabolism,
                                                date: new Date(),
                                                value: report.alphaReport.hdlTrigRatio,
                                                notification: null,
                                                range: null
                                            }} />
                                            <LabResultComponent label="Hgb-A1c" value={labValues.getLabReportValue(LabInputNames.HgbA1C)} />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box mt="20px">
                                        <DnaTableComponent
                                            dna={report.dietAndNutritionReport.carbonIntoleranceScore.dna}
                                            colorMap={riskColorMap}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Divider className="report-divider" />
                    <Box px={2}>
                        <Box>
                            <Box>
                                <span
                                    className={classes.reportTitle}>Insulin Resistance Score: {report.dietAndNutritionReport.insulinResistanceScore == null
                                        ? "Unavailable"
                                        : Math.round(report.dietAndNutritionReport.insulinResistanceScore?.scorePercent) + "%"}</span>
                            </Box>
                            <Box mt="10px">
                                <ProgressDiagramComponent
                                    height={22}
                                    colors={['#626d4b', '#e10003']}
                                    data={[
                                        {
                                            label: '',
                                            value: report.dietAndNutritionReport.insulinResistanceScore?.scorePercent,
                                            labelPosition: LabelPosition.Bottom
                                        }
                                    ]}
                                    unavailable={report.dietAndNutritionReport.insulinResistanceScore == null}
                                />
                            </Box>
                        </Box>
                        <Box mt={3}>
                            <Box>
                                <span className={classes.reportTitle}>Your Insulin Resistance Recommendations</span>
                                <Box display="flex" justifyContent="flex-end" width={1}>
                                    {
                                        parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation) && HealthReportMode.EditMode === mode && <WildHealthButton
                                            id="health-recommendation-new-save"
                                            onClick={() => handleSaveChangesNew()}
                                            disabled={!isChanged}>
                                            <Box >
                                                Save changes
                                            </Box>
                                        </WildHealthButton>
                                    }
                                </Box>
                            </Box>
                            <Box mt="10px">
                            {parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
                                ? <Box>
                                    <RecommendationSingleSelectComponent
                                        currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.InsulinResistance)}
                                        recommendation={report.longevityReport.insulinResistanceRecommendation}
                                        type={RecommendationTypesNumbers.InsulinResistance}
                                        handleSelected={handleSelected}
                                        handleChanges={handleChangesNewRecommendation}
                                        handleAddRecommendation={handleAddRecommendation}
                                        mode={mode}
                                    />
                                </Box>
                                : <HealthRecommendationComponent
                                    mode={mode}
                                    rows={recommendationRowsCapacity[RecommendationTypesNumbers.InsulinResistance]}
                                    recommendation={report.longevityReport.insulinResistanceRecommendation}
                                    handleContentChanges={!handleChanges ? handleChanges : (value) => {
                                        handleChanges('longevityReport.insulinResistanceRecommendation.content', value)
                                    }}
                                    handleManualContentChanges={!handleChanges ? handleChanges : (value) => {
                                        handleChanges('longevityReport.insulinResistanceRecommendation.manualContent', value)
                                    }}
                                    handleSaveChanges={handleSaveChanges}
                                    isChanged={isChanged} />
                                }
                            </Box>
                        </Box>
                    </Box>
                </>
            </HealthReportMobileBasePage>
        ) : (
            <HealthReportBasePage section={HealthReportSections.ChronicDisease} pageNumber={pageNumber} practice={practice}>
                <>
                    <Box px={15} pt={5}>
                        <Box>
                            <Box>
                                <span className="report-title">Insulin Resistance</span>
                            </Box>
                            <Box>
                                <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeMediumReport, commonClasses.textMediumReport)}>Your Genetics and Labs</span>
                            </Box>
                            <Box>
                                <span>
                                    Insulin is a hormone made by the pancreas and is responsible for transporting glucose
                                    from your blood stream into your cells. Insulin resistance is the reduced effectiveness
                                    of insulin at transporting glucose- the cells become “resistant to it.” There are
                                    many theories. At {TitlesService.simpleBaseTitle} we believe in the suitcase theory: if your cells are
                                    full of glucose, it becomes harder to fit glucose in them and the insulin doesn’t
                                    work as well. Thus the treatment is reducing glucose, ie consumption. If insulin
                                    resistance goes unchecked it will lead to pre-diabetes, and often
                                    full blown type 2 diabetes.
                                </span>
                                <br />
                                <br />
                                <span>Although there are many facets to fixing insulin resistance, the basics are:</span>
                                <br />
                                <span>1. Diet: low carb and fasting to reduce glucose in the cells</span>
                                <br />
                                <span>2. Exercise. Increase glucose utilization</span>
                                <br />
                                <span>3. Sleep and hormonal optimization- to optimize insulin effectiveness.</span>
                            </Box>
                        </Box>
                    </Box>
                    <Divider className="report-divider" />
                    <Box px={15} pt={3}>
                        <Box>
                            <Grid container>
                                <Grid item xs={4}>
                                    <Box>
                                        <Box mt={5}>
                                            <Box display="flex" justifyContent="center">
                                                <Box width={1}>
                                                    {renderScaleChartComponent(report.dietAndNutritionReport.carbonIntoleranceScore)}
                                                </Box>
                                            </Box>
                                            <Box display="flex" justifyContent="center" mt={2}>
                                                <span className="text-medium">
                                                    Carb Intolerance: {Math.round(report.dietAndNutritionReport.carbonIntoleranceScore.scorePercent)}%
                                                </span>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box>
                                        <Box className="text-light-medium" px={2}>
                                            <Box>
                                                <span className="report-sub-title">Insulin Resistance Labs</span>
                                            </Box>
                                            <Box>
                                                <LabResultComponent label="Fasting Glucose" value={labValues.getLabReportValue(LabInputNames.FastingGlucose)} />
                                                <LabResultComponent label="Fasting Insulin" value={labValues.getLabReportValue(LabInputNames.FastingInsulin)} />
                                                <LabResultComponent label="HOMA-IR" value={{
                                                    name: LabInputNames.Homir,
                                                    group: LabGroup.InsulinResistanceAndMetabolism,
                                                    date: new Date(),
                                                    value: report.alphaReport.homir,
                                                    notification: null,
                                                    range: null
                                                }} />
                                                <LabResultComponent label="Ferritin" value={labValues.getLabReportValue(LabInputNames.Ferritin)} />
                                                <LabResultComponent label="TRIG:HDL" value={{
                                                    name: LabInputNames.TrigHdl,
                                                    group: LabGroup.InsulinResistanceAndMetabolism,
                                                    date: new Date(),
                                                    value: report.alphaReport.hdlTrigRatio,
                                                    notification: null,
                                                    range: null
                                                }} />
                                                <LabResultComponent label="Hgb-A1c" value={labValues.getLabReportValue(LabInputNames.HgbA1C)} />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box display="flex" justifyContent="flex-end" mt={2}>
                                        <Box width="60%">
                                            <DnaTableComponent
                                                dna={report.dietAndNutritionReport.carbonIntoleranceScore.dna}
                                                colorMap={riskColorMap}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Divider className="report-divider" />
                    <Box px={15} pt={3}>
                        <Box>
                            <Box>
                                <span
                                    className="report-title">Insulin Resistance Score: {report.dietAndNutritionReport.insulinResistanceScore == null
                                        ? "Unavailable"
                                        : Math.round(report.dietAndNutritionReport.insulinResistanceScore?.scorePercent) + "%"}</span>
                            </Box>
                            <Box mt={5}>
                                <ProgressDiagramComponent
                                    height={22}
                                    colors={['#626d4b', '#e10003']}
                                    data={[
                                        {
                                            label: '',
                                            value: report.dietAndNutritionReport.insulinResistanceScore?.scorePercent,
                                            labelPosition: LabelPosition.Bottom
                                        }
                                    ]}
                                    unavailable={report.dietAndNutritionReport.insulinResistanceScore == null}
                                />
                            </Box>
                        </Box>
                        <Box mt={5}>
                            <Box display="flex" justifyContent="space-between">
                                <span className="report-title">Your Insulin Resistance Recommendations</span>
                                {
                                parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation) && HealthReportMode.EditMode === mode && <WildHealthButton
                                    id="health-recommendation-new-save"
                                    onClick={() => handleSaveChangesNew()}
                                    disabled={!isChanged}>
                                    <Box >
                                        Save changes
                                    </Box>
                                </WildHealthButton>
                            }
                            </Box>
                            <Box mt={2}>
                            {parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
                                ? <Box>
                                    <RecommendationSingleSelectComponent
                                        currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.InsulinResistance)}
                                        recommendation={report.longevityReport.insulinResistanceRecommendation}
                                        type={RecommendationTypesNumbers.InsulinResistance}
                                        handleSelected={handleSelected}
                                        handleChanges={handleChangesNewRecommendation}
                                        handleAddRecommendation={handleAddRecommendation}
                                        mode={mode}
                                    />
                                </Box>
                                : <HealthRecommendationComponent
                                    mode={mode}
                                    rows={recommendationRowsCapacity[RecommendationTypesNumbers.InsulinResistance]}
                                    recommendation={report.longevityReport.insulinResistanceRecommendation}
                                    handleContentChanges={!handleChanges ? handleChanges : (value) => {
                                        handleChanges('longevityReport.insulinResistanceRecommendation.content', value)
                                    }}
                                    handleManualContentChanges={!handleChanges ? handleChanges : (value) => {
                                        handleChanges('longevityReport.insulinResistanceRecommendation.manualContent', value)
                                    }}
                                    handleSaveChanges={handleSaveChanges}
                                    isChanged={isChanged} />
                                }
                            </Box>
                        </Box>
                    </Box>
                </>
            </HealthReportBasePage>
        )
    )
}
