import { Box, Dialog, DialogActions, DialogContent, DialogContentText, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useRef } from 'react';
import { useFacade } from './schedulingInformationDialog.hooks';
import { DialogTitle } from '../../../../common/components/dialogTitle/DialogTitle';
import { WildHealthButton } from '../../../../common/components/wildHealthButton/WildHealthButton';
import { useStyles } from './scheduilngInformationDialog.style';
import { EngagementCriteriaType, EngagementCriteriaTypeBody, EngagementCriteriaTypeTitle } from '../constants/myTaskImages';

interface SchedulingInformationDialogProps{
    taskType: EngagementCriteriaType;
    isOpen: boolean;
    handleOpenSchedulingInfo: Function;
}

export const SchedulingInformationDialog: React.FC<SchedulingInformationDialogProps> = ((props: SchedulingInformationDialogProps) => {
    const 
        {
            isOpen,
            taskType,
            handleOpenSchedulingInfo
        } = props;
    
    const dialogMobileRef = useRef();

    const [
        state,
        handleInit,
        handleUpdate,
        handleReset
    ] = useFacade(dialogMobileRef, handleOpenSchedulingInfo);


    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const classes = useStyles();

    return (
        (isSmallScreen && isOpen)?
        <Box>
            <Box className={classes.backGrnd} onClick={() => {handleOpenSchedulingInfo(false, false)}}></Box>
            <div className={classes.container} ref={dialogMobileRef} onMouseDown={(event) => {handleInit(event)}} onMouseMove={(event)=>{handleUpdate(event)}} onMouseUp={(event)=>{handleReset(event)}} onMouseLeave={(event)=>{handleReset(event)}} onTouchStart={(event)=>{handleInit(event)}} onTouchMove={(event)=>{handleUpdate(event)}} onTouchEnd={(event)=>{handleReset(event)}}>
                <Box display="flex" className={classes.boxToSwap} mt={3}></Box>
                <Box display="flex" paddingLeft={4} paddingRight={4} justifyContent='space-between' flexDirection='column'>
                    <Box display="flex" className="wh-tw-text-black wh-tw-font-poppins wh-tw-text-xl wh-tw-font-medium" mt={3}>{EngagementCriteriaTypeTitle[taskType]}</Box>
                    <Box display="flex" mt={2} className="wh-tw-text-gray600 wh-tw-font-beVietnamPro">{EngagementCriteriaTypeBody[taskType]}</Box>
                    <Box display="flex" mt={3} mb={6}>
                        <WildHealthButton id="scheduleButton" fullWidth size="large" style={{borderRadius: 50}} onClick={() => {handleOpenSchedulingInfo(false, true)}}>Schedule Appointment</WildHealthButton>
                    </Box>
                </Box>
            </div>
        </Box>:
        <Box>
            <Dialog
                maxWidth="sm"
                open={isOpen}
                onClose={() => {handleOpenSchedulingInfo(false, false)}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{paper: classes.dialog}}
            >
                <DialogTitle id="dialog-title" onClose={() => {handleOpenSchedulingInfo(false, false)}}>
                </DialogTitle>

                <DialogContent className='wh-tw-w-[425px]'>
                    <Box p={2} className="wh-tw-text-black wh-tw-font-poppins wh-tw-text-xl wh-tw-font-medium">
                        {EngagementCriteriaTypeTitle[taskType]}
                    </Box>
                    <Box px={2}>
                        <DialogContentText id="alert-dialog-description" className="wh-tw-text-gray600 wh-tw-font-beVietnamPro">
                            {EngagementCriteriaTypeBody[taskType]}
                        </DialogContentText>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Box mr={4} mb={4}>
                        <WildHealthButton id={`confirm-scheduling`} size="large" style={{borderRadius: 50}} onClick={() => {handleOpenSchedulingInfo(false, true)}}>
                            Schedule Now
                        </WildHealthButton>
                    </Box>
                </DialogActions>
            </Dialog>
        </Box>
    );
})