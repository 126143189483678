import {useEffect, useState} from "react";
import {ExternalAuthProvider} from "../../../auth/models/auth.enums";
import {ExternalAuthorizationProviderModel} from "../../../auth/models/auth.models";
import {Client} from "@fullscript/fullscript-js/dist/client";
import {patientStore} from "../../../patients/stores/patientsStore";
import {PatientModel} from "../../../patients/models/patient.model";
import {authService} from "../../../auth/services/auth.service";
import {thirdPartyIntegrationService} from "../../../auth/services/thirdPartyIntegration.service";
import {EventListenerPayload, Fullscript} from "@fullscript/fullscript-js";
import {navigationService} from "../../../../services/navigation.service";
import {useHistory} from "react-router";
import {Feature} from "@fullscript/fullscript-js/dist/feature";
import {supplementsService} from "../../services/supplements.service";
import {FullscriptSupplementModel} from "../../models/fullscriptSupplementModels";

interface RecommendFullscriptSupplementsComponentState {
    isLoading: boolean;
    patient: PatientModel;
    provider: ExternalAuthorizationProviderModel;
    client: Client;
    feature: Feature;
}

let stateContext: RecommendFullscriptSupplementsComponentState;

export function useFacade(patientId: number, isOpen: boolean, onOrdered?: (supplements: FullscriptSupplementModel[]) => void): [
    RecommendFullscriptSupplementsComponentState
] {
    const history = useHistory();
    const [state, setState] = useState({
        isLoading: true,
        patient: null,
        provider: null,
        externalAuth: null,
        client: null,
        feature: null
    } as RecommendFullscriptSupplementsComponentState);

    stateContext = state;

    const handleTreatmentPlanActivated = (payload: EventListenerPayload<"treatmentPlan.activated">) => {
        supplementsService.byTreatmentPlan(payload.data.treatmentPlan.id).subscribe(supplements => {
            if (onOrdered) {
                supplements.forEach(x => {
                    x.date = new Date();
                });

                onOrdered(supplements);
            }
        })
    };

    const initClient = (patient: PatientModel, sessionToken: string, provider: ExternalAuthorizationProviderModel) => {
        const env = provider.baseUrl.includes('snd')
            ? "us-snd"
            : "us";

        const fullscriptClient = Fullscript({
            publicKey: provider.publicKey,
            env: env
        });

        const feature = fullscriptClient.create("treatmentPlan", {
            secretToken: sessionToken,
            patient: {
                id: patient.universalId,
                firstName: patient.firstName,
                lastName: patient.lastName,
                email: patient.email,
            }
        });

        feature.mount("treatment-plan-container").then();
        feature.on("treatmentPlan.activated", handleTreatmentPlanActivated);

        setState(state => ({
            ...state,
            isLoading: false,
            patient: patient,
            provider: provider,
            client: fullscriptClient,
            feature: feature
        }));
    }

    useEffect(() => {
        if (!isOpen) {
            stateContext?.feature?.off("treatmentPlan.activated", handleTreatmentPlanActivated);

            setState(state => ({
                ...state,
                isLoading: true,
                patient: null,
                provider: null,
                client: null,
                feature: null
            }));
        } else {
            thirdPartyIntegrationService.getSessionToken(ExternalAuthProvider.fullscript).subscribe(
                sessionToken => {
                    if (sessionToken && sessionToken.length) {
                        authService.getExternalAuthorizationProviders().subscribe(providers => {
                            const provider = providers?.find(x => x.provider === ExternalAuthProvider.fullscript);
                            const patient = patientStore.getValue().targetPatient;

                            initClient(patient, sessionToken, provider);
                        });
                    } else {
                        navigationService.toThirdPartyIntegrationLoginPage(history, ExternalAuthProvider.fullscript);
                    }
                },
                () => {
                    navigationService.toThirdPartyIntegrationLoginPage(history, ExternalAuthProvider.fullscript);
                }
            );
        }

        return () => {};
    }, [patientId, isOpen]);

    return [
        state
    ];
}