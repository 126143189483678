import {GeneralValidator} from "../../../../common/validation/general-validator";
import {stringLengthValidationRule, linkValidationRule} from "../../../../common/validation/general-validation-rules";

export const manageShortcutsComponentValidator = new GeneralValidator({
    // User form
    'name': [stringLengthValidationRule(1, 100)],
    'displayName': [stringLengthValidationRule(1, 100)],
    'content': [stringLengthValidationRule(1, 2500)],
    'purchaseLink': [stringLengthValidationRule(1, 250), linkValidationRule]
});
