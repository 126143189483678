import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { onEmit } from "../../../common/helpers/on-emit";
import {patientProductsService} from "../../../products/services/patientProducts.service";
import {PatientProductModel} from "../../../products/models/patientProducts.model";
import {productsQuery} from "../../../products/stores/productsStore/products.query";
import {ProductSubType} from "../../../products/enums/productSubType";
import {ProductType} from "../../../products/enums/productType";

interface AppointmentsComponentState {
    isLoading: boolean;
    orders: PatientProductModel[];
}

export function useFacade(patientId: number): [
    AppointmentsComponentState, 
    Array<string>,
    (id: number) => void,
] {
    const [state, setState] = useState({
        isLoading: true,
        orders: [],
    } as AppointmentsComponentState);

    const columns = [
        'Product Type',
        'Purchase Date',
        'Total price',
        'Payment Status',
    ];

    const handleGoToInvoice = (id: number) => {
        if (patientId) {
            patientProductsService.getPatientInvoiceLink(id, patientId).subscribe((res) => {
                window.open(res, '_blank').focus();
            });
        } else {
            patientProductsService.getMyInvoiceLink(id).subscribe((res) => {
                window.open(res, '_blank').focus();
            });
        }
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<PatientProductModel[]>(productsQuery.patientProducts$, products => {
                if (products) {
                    const additionalProducts = products.filter(el => el.productSubType === ProductSubType.Additional && el.productType === ProductType.PhysicianVisit)
                    setState(state => ({ ...state, orders:  additionalProducts}));
                }
            }),
        ];

        const cb = () => setState(state => ({ ...state, isLoading: false }));

        if (patientId) {
            patientProductsService.getPatientProducts(patientId).subscribe(cb, cb);
        } else {
            patientProductsService.getMyProducts().subscribe(cb, cb);
        }

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [patientId]);

    return [state, columns, handleGoToInvoice];
}