import { Query } from '@datorama/akita';
import { BannersState, BannersStore, bannersStore } from "./bannersStore";
import { BannerModel } from '../../../../models/banner.model';

/**
 * Provides banners queries
 */
export class BannersQuery extends Query<BannersState> {
    banners$ = this.select(state => state.banners);

    constructor(protected store: BannersStore) {
        super(store);
    }

    /**
     * Returns all Banners
     */
    public getAllBanners(): BannerModel[] {
        return this.getValue().banners;
    }
}

export const bannersQuery = new BannersQuery(bannersStore);
