import { Box } from "@material-ui/core";
import React from "react";
import { AgreementModel } from "../../models/agreement.model";
import { PaymentPeriodModel } from "../../models/paymentPeriod.models";
import { PaymentPlanModel } from "../../models/paymentPlan.models";
import { PaymentPriceModel } from "../../models/paymentPrice.models";
import { confirmAgreementService } from "../../services/confirmAgreement.service";
import { useStyles } from "./ConfirmAgreementsCheckBoxComponent.styles";

export interface ConfirmAgreementsCheckBoxComponentProps {
    paymentPlan: PaymentPlanModel;
    paymentPeriod: PaymentPeriodModel;
    paymentPrice: PaymentPriceModel;
}

export const ConfirmAgreementsCheckBoxComponent: React.FC<ConfirmAgreementsCheckBoxComponentProps> = (props: ConfirmAgreementsCheckBoxComponentProps) => {
    const {
        paymentPlan,
        paymentPeriod,
        paymentPrice,
    } = props;

    const classes = useStyles();

    const handleShowAgreements = (agreement: AgreementModel) => {
        confirmAgreementService.view(
            agreement,
            paymentPlan,
            paymentPeriod,
            paymentPrice
        );
    }

    return (
        <>
            <Box className={classes.agreementsWrap}>
                <Box className={classes.agreements}>
                    <p className={classes.textWithLink}>By continuing, you accept terms and conditions of our </p>
                    <button id="confirm-agreements-check-box-agreements-patient" onClick={() => handleShowAgreements(paymentPeriod.agreements[0])} className={classes.btnLink}>Patient Agreement</button>
                    <p className={classes.textWithLink}>as well as our </p>
                    <button id="confirm-agreements-check-box-agreements-coaching" onClick={() => handleShowAgreements(paymentPeriod.agreements[1])} className={classes.btnLink}>Coaching Client Agreement</button>
                </Box>
            </Box>
        </>
    );
}
