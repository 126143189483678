import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { confirmService } from "../../../../services/confirm.service";
import {
    AppointmentTagsModel,
    EmployeeAppointmentModel,
    AppointmentsSequenceInfoModel
} from "../../models/appointments.models";
import { appointmentsService } from "../../services/appointments.service";
import { Subscription } from "rxjs";
import { onEmit } from "../../../common/helpers/on-emit";
import { QuestionnaireResultModel } from "../../../questionnaire/models/questionnaire.models";
import { questionnaireQuery } from "../../../questionnaire/stores/questionnaireStore";
import { appointmentsQuery } from "../../stores/appointments";
import { navigationService } from "../../../../services/navigation.service";
import {NoteModel} from "../../../notes/models/notes.models";

interface AppointmentInfoPopoverComponentState {
    menuAnchor: HTMLElement | null;
    notesMenuAnchor: HTMLElement | null;
    inviteDialogOpen: boolean;
    followUpFormId?: number;
    tags: AppointmentTagsModel[];
    sequenceInfo: AppointmentsSequenceInfoModel;
}

/**
 * Custom Hook to manage a view Model for Appointments component
 */
export function useFacade(appointment: EmployeeAppointmentModel): [
    AppointmentInfoPopoverComponentState,
    (event: React.MouseEvent<HTMLButtonElement>) => void,
    (event: React.MouseEvent<HTMLButtonElement>) => void,
    (event: any) => void,
    (event: any) => void,
    (toggle: boolean) => void,
    (appointmentId: number) => void,
    (id: number) => void,
    (note: NoteModel) => void,
] {
    const history = useHistory();
    const [state, setState] = useState({
        menuAnchor: null,
        notesMenuAnchor: null,
        followUpFormId: null,
        sequenceInfo: null,
        tags: null
    } as AppointmentInfoPopoverComponentState);

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setState({ ...state, menuAnchor: event.currentTarget });
    };

    const handleOpenNotesMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setState({ ...state, notesMenuAnchor: event.currentTarget });
    };

    const handleViewNote = (note: NoteModel) => {
        navigationService.toPatientProfileNote(history, appointment.patient.id, note.id);
    }

    const handleCloseNotesMenu = (event: any) => {
        event.stopPropagation();
        setState({ ...state, notesMenuAnchor: null });
    };

    const handleCloseMenu = (event: any) => {
        event.stopPropagation();
        setState({ ...state, menuAnchor: null });
    };

    const handleToggleInviteDialog = (toggle: boolean) => {
        setState({ ...state, inviteDialogOpen: toggle });
    }

    const handleNoShowAppointment = (appointmentId: number) => {
        confirmService.confirm(
            'Are you sure?',
            'This action will mark the appointment with “patient did not show up” status.',
            'Yes',
            'Cancel',
            'danger')
            .subscribe(
                () => appointmentsService.setNoShowAppointment(appointmentId)
            );
    }

    const goToPatientVisitPrep = (id: number) => {
        navigationService.toPatientQuestionnaireVisitPrep(history, id)
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<QuestionnaireResultModel[]>(questionnaireQuery.questionnaireResultsByAppointments$, questionnaireResults => {
                const questionnaireResult = questionnaireResults?.find(x => x?.appointmentId === appointment.id);
                setState(state => ({
                    ...state, 
                    followUpFormId: questionnaireResult ? questionnaireResult.id : null
                }));
            }),
            onEmit<EmployeeAppointmentModel[]>(appointmentsQuery.appointmentsIncludeTags$, appointments => {
                const employeeAppointment = appointments?.find(x => x.id === appointment.id);
                setState(state => ({
                    ...state,
                    tags: employeeAppointment ? employeeAppointment.tags : []
                }))
            }),
            onEmit<AppointmentsSequenceInfoModel[]>(appointmentsQuery.appointmentSequenceInfo$, sequenceInfo => {
                const sequence = sequenceInfo?.find(x => x.id === appointment.id);
                setState(state => ({
                    ...state,
                    sequenceInfo: sequence
                }))
            })
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [appointment]);

    return [
        state,
        handleOpenMenu,
        handleOpenNotesMenu,
        handleCloseMenu,
        handleCloseNotesMenu,
        handleToggleInviteDialog,
        handleNoShowAppointment,
        goToPatientVisitPrep,
        handleViewNote
    ];
}