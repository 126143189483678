import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
    createStyles({
        root: {
            padding: 30
        },
        toolBar: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',

            width: '100%',
            paddingBottom: 20,
        },
    })
);
