import { InputBase } from '@material-ui/core';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        label: {
            color: colors.gray1,
            minHeight: 38,
        },
        message: {
            minHeight: 30,
        },
        errorMessage: {
            color: colors.bad,
        },
        warningMessage: {
            color: colors.average2,
        },
    })
);

export const WildHealthTextField = withStyles((theme) => ({
    root: {
        borderRadius: 4,
        backgroundColor: colors.white,
        border: `1px solid ${colors.gray1}`,
        '&.modified': {
            backgroundColor: '#ffe86355',
        },
        '&.initialized': {
            backgroundColor: '#d2d2d255',
        },
        '&.error': {
            borderColor: `${colors.bad} !important`,
        },
    },
    input: {
        fontSize: 22,
        position: 'relative',
        padding: 12,
        '&:focus': {
            borderColor: theme.palette.primary.main,
        },
    },
  }))(InputBase);