import { Box, FormControl, MenuItem, Select} from '@material-ui/core';
import {NextTimeSpan} from '../../models/notes.models';
import React from 'react';

interface SelectIntervalComponentProps {
    handleChange: (value: NextTimeSpan) => void,
    selected: NextTimeSpan,
    items: NextTimeSpan[],
    disabled?: boolean;
}

export const SelectIntervalComponent: React.FC<SelectIntervalComponentProps> = (props: SelectIntervalComponentProps) => {
    const {
        handleChange,
        selected,
        items,
        disabled = false
    } = props;

    return (
        <Box>
            <FormControl color='primary' variant="outlined" size="small" fullWidth>
                <Select
                    style={{width: "100%"}}
                    value={selected}
                    disabled={disabled}
                    onChange={(event) => handleChange(event.target.value as NextTimeSpan)}>
                    {
                        items.map((item, i) => {
                            return <MenuItem key={i} value={item}>{NextTimeSpan[item].toString()}</MenuItem>
                        })
                    }
                </Select>
            </FormControl>
        </Box>
    );
}