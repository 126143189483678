import { createStyles, fade, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        search: {
            position: 'relative',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.common.white, 0.25),
            },
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: 'auto',
            },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            justifyContent: 'center',
            pointerEvents: 'none',
            position: 'absolute',
            alignItems: 'center',
            display: 'flex',
            height: '100%',
        },
        inputRoot: {
            color: 'inherit',
            background: '#fff',
        },
        inputField: {
            transition: theme.transitions.create('width'),
            padding: theme.spacing(1, 1, 1, 0),
            paddingLeft: `10px`,
            borderRadius: theme.shape.borderRadius,
            background: '#fff',
            height: 24,
        },
    }),
);
