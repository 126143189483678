import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        titleSection: {
            display: 'flex',
            alignItems: 'center',

            [theme.breakpoints.down("xs")]: {
                alignItems: 'flex-start'
            },
        },
        cardTitle: {
            fontSize: 18,
            fontWeight: 500,
            width: '100%',
            paddingLeft: '12px',
            [theme.breakpoints.down("xs")]: {
                paddingLeft: 0,
                marginBottom: '10px'
            },
        },
        description: {
            fontSize: 14,
            color: colors.gray1,
            paddingTop: 16,
            overflow: 'hidden'
        },
        descriptionCollapsed: {
            height: '78px'
        },
        descriptionExpanded: {
            minHeight: '78px'
        },
        cardMain: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            boxSizing: 'border-box',
            boxShadow: "0px 4px 9px rgba(168, 173, 198, 0.15)",
            borderRadius: "2px",
            border: `1px solid ${colors.stroke}`,
            padding: 20,
            minHeight: 218,
        },
        readMore: {
            color: colors.main,
            cursor: 'pointer',
            fontWeight: 500
        },
        questionImage: {
            [theme.breakpoints.down("xs")]: {
                marginTop: 5,
            },
        },
        statusSection: {
            paddingLeft: '12px',
            display: 'flex',
            alignItems: 'center',

            [theme.breakpoints.down("xs")]: {
                display: 'block'
            },
        }
    })
);