import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { authQuery } from "../../../auth/stores/auth";
import { onEmit } from "../../../common/helpers/on-emit";
import { NoteModel, NotesGroupModel, NotesType } from "../../models/notes.models";
import { notesService } from "../../services/notes.service";
import { notesQuery } from "../../stores/notes";

interface MenuButton {
    anchorEl: HTMLElement;
    isOpen: boolean;
}

interface ManagedNotesCardsComponentState {
    isLoading: boolean;
    internalNotesGroups: NotesGroupModel[];
    visitNotesGroups: NotesGroupModel[];
    createNote: MenuButton;
}

export function useFacade(patientId: number): [ManagedNotesCardsComponentState, (toShow: boolean, event?: React.MouseEvent<HTMLButtonElement>) => void, Function] {
    const [state, setState] = useState({
        isLoading: true,
        internalNotesGroups: [],
        visitNotesGroups: [],
        createNote: { anchorEl: null, isOpen: false },
    } as ManagedNotesCardsComponentState);

    const handleCreateNoteToggle = (toShow: boolean, event?: React.MouseEvent<HTMLButtonElement>) => {
        const anchorEl = event != null && event.currentTarget != null ? event.currentTarget : null;
        setState({...state, createNote: { anchorEl: anchorEl, isOpen: toShow }});
    }
    
    const handleGetCurrentNote = (note : NoteModel) =>{
        let result = note.amendedNotes.find(x => x.completedAt == null && authQuery.getEmployeeId() == x.employee?.id);

        if(result != null ) {
            return result;
        }

        return note;
    }

    /**
     * Load all ongoing cares
     * Manage subscriptions with auto-cleanup
     */
    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<NotesGroupModel[]>(notesQuery.notesGroups$, notesGroups => {
                const internal = notesGroups.map(i => { return { employee: i.employee, notes: i.notes.filter(n => n.type === NotesType.Internal )}}).filter(i => i.notes.length > 0);
                const visit = notesGroups.map(i => { return { employee: i.employee, notes: i.notes.filter(n => n.type !== NotesType.Internal )}}).filter(i => i.notes.length > 0);

                setState(state => ({
                    ...state,
                    internalNotesGroups: internal,
                    visitNotesGroups: visit,
                }));
            })
        ];

        notesService.getByEmployee(patientId).subscribe(() => {
            setState(state => ({
                ...state,
                isLoading: false
            }));
        });

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, [patientId]);

    return [state, handleCreateNoteToggle, handleGetCurrentNote];
}