import { Box, FormControl, MenuItem, Select} from '@material-ui/core';
import commonUseStyles from '../../../common/styles/common.styles';
import { DurationModel } from '../../models/times.models';
import React from 'react';
import clsx from 'clsx';

interface SelectDurationComponentProps {
    handleChange: (value: number) => void,
    selected: DurationModel,
    durations: DurationModel[],
    disabled?: boolean
}

export const SelectDurationComponent: React.FC<SelectDurationComponentProps> = (props: SelectDurationComponentProps) => {
    const {
        handleChange,
        selected,
        durations,
        disabled = false
    } = props;

    const commonClasses = commonUseStyles();

    return (
        <Box>
            <Box>
                <span className={clsx(commonClasses.size14, commonClasses.colorGray1)}>Appointment Duration</span>
            </Box>
            <Box mt={1}>
                <FormControl color='primary' variant="outlined" size="small" fullWidth disabled={disabled}>
                    <Select
                        style={{width: "100%"}}
                        value={selected?.value}
                        onChange={(event) => handleChange(event.target.value as number)}>
                        {
                            durations.map((duration, i) => {
                                return <MenuItem key={i} value={duration.value}>{duration.name}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            </Box>
        </Box>
    );
}