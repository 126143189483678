import {
    Box,
    FormControl, IconButton,
    MenuItem, Select,
    Table,
    TableBody,
    TableContainer,
    TableFooter,
    TableHead,
    TableSortLabel
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import FaceIcon from '@material-ui/icons/Face';
import Chip from '@material-ui/core/Chip'
import { colors } from "../../../common/constants/colors";
import moment from "moment";
import React from 'react';
import { PermissionType } from '../../../auth/models/auth.enums';
import { ProtectedElement } from "../../../common/components/ProtectedElement";
import { SearchComponent } from '../../../common/components/searchComponent/SearchComponent';
import { WildHealthContainer } from '../../../common/components/wildHealthContainer/WildHealthContainer';
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { PaginationComponent } from '../../../common/pagination/components/paginationComponent/PaginationComponent';
import { pageSizes } from '../../../common/pagination/models/page-sizes';
import {
    DnaFilesFilters,
    DnaFilesSynchronizationFilters,
    DnaFileSynchronizationStatuses
} from '../../constants/dnaFiles.constants';
import { useFacade } from './dnaFilesMonitorComponent.hooks';
import ReplayIcon from '@material-ui/icons/Replay';

export const DNAFilesMonitorComponent: React.FC = () => {
    const [
        {
            isLoading,
            dnaFilesView,
            sortingColumns,
            sortingSource,
            selectedFilter,
            pageSize,
            totalCount,
            selectedPage
        },
        handleSorting,
        handleSearch,
        handleDownloadClick,
        handleSelect,
        handleSyncStatusChange,
        handleRetry,
        handlePageChange,
        handlePageSizeChange,
        getAllAvailablePages
    ] = useFacade();

    const emptyPlaceholder = () => {
        return <span style={{color: '#999999'}}>(empty)</span>
    }

    const renderTable = () => {
        if (isLoading) {
            return <WildHealthLinearProgress/>
        }

        if (!isLoading && (!dnaFilesView || !dnaFilesView.length)) {
            return <WildHealthPlaceHolder message="No current records found"/>
        }

        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <WildHealthTableRow>
                            {
                                sortingColumns.map((item, index) =>
                                    <WildHealthTableCell style={{width: '16%'}} key={index}>
                                        <TableSortLabel
                                            id={`dna-files-monitor-sorting-${item.title}`}
                                            active={sortingSource === item.source}
                                            direction={sortingSource === item.source ? item.direction : 'asc'}
                                            onClick={() => handleSorting(item.source, item.direction)}
                                        >
                                            {item.title}
                                        </TableSortLabel>
                                    </WildHealthTableCell>
                                )
                            }
                            <WildHealthTableCell style={{width: '10%'}}>
                                Download
                            </WildHealthTableCell>
                            {
                                selectedFilter === DnaFilesFilters.notSynchronized &&
                                <WildHealthTableCell style={{width: '10%'}}>
                                    Retry
                                </WildHealthTableCell>
                            }
                        </WildHealthTableRow>
                    </TableHead>
                    <TableBody>
                        {
                            dnaFilesView.map((file, index) =>
                                <WildHealthTableRow key={index}>
                                    <WildHealthTableCell> {file.displayName ?? emptyPlaceholder() } { file.isFileTagged && <Chip style={{ backgroundColor: colors.average1}} icon={<FaceIcon />} label="DNA not matched" />} </WildHealthTableCell>
                                    <WildHealthTableCell> {moment(file.date).format("L") ?? emptyPlaceholder()} </WildHealthTableCell>
                                    <WildHealthTableCell>
                                        {
                                            !file.isFileFailed &&
                                            <Box width={0.5}>
                                                <FormControl color='primary' variant="outlined" size="small" fullWidth>
                                                    <Select style={{background: '#FFF'}}
                                                            value={file.isFileSynced.toString()}
                                                            onChange={(v) => handleSyncStatusChange(file.name, v.target.value as boolean)}>
                                                        {
                                                            Array.from(DnaFileSynchronizationStatuses).map(status => {
                                                                return <MenuItem key={status[0]}
                                                                                    value={status[1]}>{status[0]}</MenuItem>
                                                            })
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        }
                                    </WildHealthTableCell>
                                    <WildHealthTableCell>
                                        {
                                            !file.isFileFailed &&
                                            <>
                                                {
                                                    file.downloading
                                                        ? <WildHealthLinearProgress/>
                                                        : <ProtectedElement
                                                            permissions={[PermissionType.AddonOrders]}
                                                            element={
                                                                <span
                                                                    id={`dna-files-monitor-download-${file.name}`}
                                                                    className='pointer-link'
                                                                    onClick={() => handleDownloadClick(file.name)}
                                                                >
                                                                    {<GetAppIcon/>}
                                                                </span>
                                                            }
                                                        />
                                                }
                                            </>
                                        }
                                    </WildHealthTableCell>
                                    {
                                        selectedFilter === DnaFilesFilters.notSynchronized &&
                                        <WildHealthTableCell>
                                            {
                                                file.retrying
                                                    ? <WildHealthLinearProgress/>
                                                    : <ProtectedElement
                                                        permissions={[PermissionType.AddonOrders]}
                                                        element={
                                                            <IconButton
                                                                id={`dna-files-monitor-retry-${file.name}`}
                                                                onClick={() => handleRetry(file.name)}>
                                                                    <ReplayIcon/>
                                                            </IconButton>
                                                        }
                                                    />
                                            }
                                        </WildHealthTableCell>
                                    }
                                </WildHealthTableRow>
                            )
                        }
                    </TableBody>
                    <TableFooter>
                            <WildHealthTableRow style={{ backgroundColor: "white" }}>
                                <WildHealthTableCell colSpan={7}>
                                    <PaginationComponent
                                        pageSizes={pageSizes}
                                        pageSize={pageSize}
                                        selectedPage={selectedPage}
                                        handlePageSizeChange={handlePageSizeChange}
                                        totalCount={totalCount}
                                        availablePages={getAllAvailablePages()}
                                        handlePageSelect={handlePageChange} />
                                </WildHealthTableCell>
                            </WildHealthTableRow>
                        </TableFooter>
                </Table>
            </TableContainer>
        )
    }

    return (
        <>
            <Box my={2} display="flex" justifyContent="space-between">
                <SearchComponent handleSearch={handleSearch} placeholder="Search by barcode"/>

                <Box width={300}>
                    <FormControl color='primary' variant="outlined" size="small" fullWidth>
                        <Select
                            style={{background: '#FFF'}}
                            id="demo-simple-select-outlined"
                            value={selectedFilter}
                            onChange={(v) => handleSelect(v.target.value)}
                        >
                            {
                                Array.from(DnaFilesSynchronizationFilters).map(status =>
                                    <MenuItem
                                        key={status[1]}
                                        value={status[1]}
                                    >
                                        {status[0]}
                                    </MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Box>
            <WildHealthContainer margin={0} minHeight={'100%'}>
                {renderTable()}
            </WildHealthContainer>
        </>
    );
}