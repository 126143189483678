import {AddressModel, getInitAddress} from "../../common/models/address.models";

export interface PracticeProfileModel {
    id: number;
    name: string;
    preferredUrl: string;
    address: AddressModel;
    phoneNumber: string;
    email: string;
    hideAddressOnSignUp: boolean;
}

export const getDefaultPracticeModel = () => {
    return {
        id: 0,
        name: '',
        email: '',
        phoneNumber: '',
        preferredUrl: '',
        address: getInitAddress(),
        hideAddressOnSignUp: false,
    };
}
