import React from 'react';
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import {useFacade} from "./startQuestionnairePage.hooks";
import {TitlesService} from "../../../../constants/title.constants";
import {AuthenticatedLayoutComponent} from "../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent";
import {WildHealthPlaceHolder} from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { MenuItemTypes } from '../../../../models/menu.models';
import { PagesNamesService } from '../../../../constants/pagesNames.constants';

export const StartQuestionnairePage: React.FC = (props: any) => {
    const questionnaireId = Number(props.match.params.questionnaireId);
    const isLoading = useFacade(questionnaireId);

    return (
        <>
            <AuthenticatedLayoutComponent
                title={TitlesService.startQuestionnaireTitle}
                pageName={PagesNamesService.questionnaire}
                selectedMenuItem={MenuItemTypes.Questionnaire}
            >
            {
                isLoading
                    ? <WildHealthLinearProgress />
                    : <WildHealthPlaceHolder message="Can't start questionnaire." />
            }
            </AuthenticatedLayoutComponent>
        </>
    )
}
