import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles(theme => ({
    planCard: {
        lineHeight: 1.5,
        background: colors.white,
        boxShadow: '0px 7px 60px rgba(168, 173, 198, 0.12)',
        borderRadius: 2,
        height: '100%',
        padding: '30px',
        width: '100%'
    },
    planName: {
        color: colors.linkBlack,
        fontSize: 20,
        fontWeight: 600,
        lineHeight: '27px'
    },
    planBenefits: {
        textAlign: 'center',
    },
    label: {
        fontWeight: 'bold',
        fontSize: '12px',
        lineHeight: '22px',
        height: '22px',
        paddingRight: 10,
        paddingLeft: 10,
        border: 'none',
        backgroundColor: colors.lightBlue,
        color: colors.white,
        textAlign: 'center',
        borderRadius: 2,
    },
    startPrice: {
        fontSize: 48,
        lineHeight: '64px',
        letterSpacing: '0.02em',
    },
    spacingZero: {
        letterSpacing: 0,
    },
    moneyStyle: {
        color: colors.main,
    },
    benefitsContainer: {
        background: colors.lightGray,
        padding: '20px',
    }
}));