import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { useFacade } from "./selectFellowshipPlanComponent.hooks";
import { SelectPaymentPlanCardComponent } from "../../../payment/components/selectPaymentPlanCardComponent/SelectPaymentPlanCardComponent";
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { FellowshipRegistrationBackButton } from '../fellowshipRegistrationButton/FellowshipRegistrationBackButton';


interface SelectFellowshipPlanComponentProps {
    goNext: () => void;
    goBack: () => void
}

export const SelectFellowshipPlanComponent: React.FC<SelectFellowshipPlanComponentProps> = (props: SelectFellowshipPlanComponentProps) => {
    const {
        goNext,
        goBack
    } = props;

    const [
        {
            plans,
            selectedPlan
        },
        handlePlanSelect,
    ] = useFacade(goNext);

    const renderContent = (): JSX.Element => {
        if (!plans || !plans.length) {
            return (
                <Box mt={5}>
                    <WildHealthLinearProgress />
                </Box>
            );
        }

        return (
            <>
                <Box mt={7} display="flex" justifyContent="space-between" alignItems="center">
                    <Box minWidth="100px">
                        <FellowshipRegistrationBackButton goBack={() => goBack()} />
                    </Box>
                    <Box textAlign="center" flex={1}>
                        <h2>Fellowship Patient Plans</h2>
                    </Box>
                    <Box minWidth="100px" />
                </Box>
                <Box mt={5}>
                    <Grid container alignItems="stretch" justify='center' spacing={2}>
                        {
                            plans.map((plan) => {
                                return (
                                    <Grid key={plan.id} item md={4} sm={6} xs={12}>
                                        <Box height={1} width={1} display="flex" flexDirection="column">
                                            <Box height={1} width={1} alignItems="stretch">
                                                <SelectPaymentPlanCardComponent
                                                    plan={plan}
                                                    hideLimited
                                                    isSelected={plan.id === selectedPlan?.id}
                                                    handlePlanSelect={handlePlanSelect}
                                                />
                                            </Box>
                                        </Box>
                                    </Grid>
                                )
                            })}
                    </Grid>
                </Box>
            </>
        );
    }

    return (
        <Box px="50px">
            {renderContent()}
        </Box>
    );
}