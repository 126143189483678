import { makeStyles } from '@material-ui/core/styles';
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme) => ({
  root: {
    borderColor: colors.mediumGray,
    borderRadius: 4,
    '& .MuiOutlinedInput-root': {
      borderColor: colors.mediumGray,
      borderRadius: 4,
    },
  },
  label: {
    fontSize: 14,
    fontWeight: 500,
    color: colors.gray900,
    textTransform: 'uppercase',
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  unchecked: {
    flex: 1,
    border: `1px solid #C4C4C4`,
    borderRadius: 4,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: colors.gray700,
    cursor: 'pointer'
  },
  checked: {
    position: 'relative',
    flex: 1,
    border: `1px solid ${colors.lime}`,
    borderRadius: 4,
    backgroundColor: colors.lightMint20,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: colors.navy,
    cursor: 'pointer'
  },
  checkIcon: {
    position: 'absolute',
    left: 20,
    fill: colors.darkMint,
  },
  helperText: {
    marginTop: 4,
    marginLeft: 14,
    marginRight: 14
  }
}));