import { withStyles } from "@material-ui/core/styles";
import { TableRow } from "@material-ui/core";

export const WildHealthTableRow = withStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "#F7FAFC",
    },
  },
}))(TableRow);
