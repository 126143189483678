import Axios from 'axios-observable';
import { Observable } from "rxjs";
import { authHeader } from "../../common/helpers/auth-header";
import { snackService } from "../../common/snack/state";
import {productsStore, ProductsStore} from "../stores/productsStore/products.store";
import {PatientProductModel} from "../models/patientProducts.model";

/**
 * Provides method for working with patient products
 */
export class PatientProductsService {
    private defaultUrl = `${process.env.REACT_APP_API_URL}PatientProducts`;

    constructor(private store: ProductsStore) {
    }

    public getPatientProducts(id: number): Observable<PatientProductModel[]> {
        const config = { headers: authHeader() };
        const url = `${this.defaultUrl}?patientId=${id}`;

        return new Observable<PatientProductModel[]>(observer => {
            Axios.get<Array<PatientProductModel>>(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({ patientProducts: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                    }
                );
        });
    }

    public refreshPatientProducts(patientId: number) {
        const config = { headers: authHeader() };
        const url = `${this.defaultUrl}?patientId=${patientId}`;

        Axios.get<Array<PatientProductModel>>(url, config)
            .pipe()
            .subscribe(
                (response) => {
                    this.store.update({ patientProducts: response.data });
                },
                error => {
                    snackService.commonErrorHandler(error);
                }
            );
    }


    public getMyProducts(): Observable<PatientProductModel[]> {
        const config = { headers: authHeader() };
        const url = `${this.defaultUrl}/My`;

        return new Observable<PatientProductModel[]>(observer => {
            Axios.get<Array<PatientProductModel>>(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({ patientProducts: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                    }
                );
        });
    }

    public getPatientInvoiceLink(productId: number, patientId: number): Observable<any> {
        const config = { headers: authHeader() };
        let url = `${this.defaultUrl}/${productId}/DeductibleInvoice/Link?patientId=${patientId}`;

        return new Observable((observer) => {
            Axios.get<any>(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next(response.data.result);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getMyInvoiceLink(productId: number): Observable<any> {
        const config = { headers: authHeader() };
        let url = `${this.defaultUrl}/My/${productId}/DeductibleInvoice/Link`;

        return new Observable((observer) => {
            Axios.get<any>(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next(response.data.result);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }
}

export const patientProductsService = new PatientProductsService(productsStore);
