import React from 'react';
import {
  Box,
  TextField,
  IconButton,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
  Divider
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useStyles } from "../manageRecommendationsComponent/manageRecommendationsComponent.styles";
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import { RecommendationCategoryTagNames, RecommendationModel, LogicalOperatorNames, MetricModel, RecommendationClassificationNames, VerificationMethodNames } from '../../../recommendations/models/recommendations.model';
import { IErrorState } from "../../../common/validation/error-state";
import { getNumericKeys } from '../../../common/helpers/enum-mapper';
import clsx from 'clsx';

export interface CreateRecommendationDialogProps extends IErrorState {
  isOpen: boolean;
  isLoading: boolean;
  params: RecommendationModel;
  metrics: MetricModel[];
  handleChanges: (field: string, value: any) => void;
  handleClose: () => void;
  handleSave: () => void;
  handleAddTrigger: (id: number) => void;
}

export const CreateRecommendationDialog: React.FC<CreateRecommendationDialogProps> = (props: CreateRecommendationDialogProps) => {
  const { isOpen, isLoading, params, metrics, errors, handleChanges, handleClose, handleSave, handleAddTrigger } = props;

  const classes = useStyles();

  return (
    <>
      <Dialog
        onClose={() => handleClose()}
        className={classes.root}
        open={isOpen}
      >
        <DialogContent className={classes.dialogContent}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <span className="wh-tw-text-2xl wh-tw-black wh-tw-font-medium">{params.id > 0 ? 'Update Recommendation' : 'New Recommendation'}</span>
            </Box>
            <Box display="flex" alignItems="center">
              <IconButton
                id="new-recommendation-dialog-close"
                className={classes.dialogTitleCloseAction}
                onClick={() => handleClose()}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Box mt={5}>
            <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Content *</Box>
            <TextField
              id={`content`}
              fullWidth
              required
              multiline
              rows={5}
              size="small"
              InputProps={{ className: 'input', classes }}
              variant="outlined"
              value={params.content}
              error={!!errors['content']}
              helperText={errors['content']}
              disabled={!isOpen || isLoading}
              onChange={(event) => handleChanges(event.target.id, event.target.value)}
            />
          </Box>
          <Box mt={2}>
            <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Order *</Box>
            <TextField
              id={`order`}
              fullWidth
              required
              type="number"
              size="small"
              InputProps={{ className: 'input', classes }}
              variant="outlined"
              value={params.order}
              error={!!errors['order']}
              helperText={errors['order']}
              disabled={!isOpen || isLoading}
              onChange={(event) => handleChanges(event.target.id, event.target.value)}
            />
          </Box>
          <Box mt={2}>
            <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Categories</Box>
            <FormControl
              variant="outlined"
              color='primary'
              required
              error={!!errors[`tags`]}
              size="small"
              disabled={!isOpen || isLoading}
              classes={{ root: classes.root }}
              fullWidth>
              <Select
                style={{ background: '#FFF' }}
                id="tags"
                classes={{ root: classes.root }}
                value={params.tags}
                inputProps={{
                  id: 'type-label',
                  classes,
                }}
                renderValue={() => {
                  if (!params.tags || !params.tags.length) {
                    return <Box className="wh-tw-text-mediumGray">Please select tag</Box>;
                  }

                  return params.tags.length === 1 ? `${params.tags.length} tag selected` : `${params.tags.length} tags selected`
                }}
                displayEmpty
                onChange={(v) => handleChanges("tags", v.target.value)}>
                {
                  getNumericKeys(RecommendationCategoryTagNames).map((value, i) => {
                    return <MenuItem key={i} value={value}>
                        <Box display="flex" alignItems="center" justifyContent="space-between" width={1}>
                        <Box className={clsx("wh-tw-text-sm wh-tw-font-medium", params.tags.includes(value) ? "wh-tw-text-primaryV" : "wh-tw-text-black")}>{RecommendationCategoryTagNames[value]}</Box>
                        {params.tags.includes(value) && <CheckIcon className="wh-tw-text-primaryV" />}
                      </Box>
                    </MenuItem>
                  })
                }
              </Select>
            </FormControl>
          </Box>
          <Box mt={2}>
            <FormControl
              variant="outlined"
              color='primary'
              required
              error={!!errors[`classification`]}
              size="small"
              disabled={!isOpen || isLoading}
              classes={{ root: classes.root }}
              fullWidth>
              <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Classification Type</Box>
              <Select
                style={{ background: '#FFF' }}
                classes={{ root: classes.root }}
                value={params.classification}
                error={!!errors[`classification`]}
                inputProps={{
                  id: 'classification-label',
                  classes,
                }}
                onChange={(v) => handleChanges("classification", v.target.value)}
                renderValue={() => {
                  if (!params.classification) {
                    return <Box className="wh-tw-text-mediumGray">Please select classification type</Box>;
                  }

                  return RecommendationClassificationNames[params.classification]
                }}
                displayEmpty
              >
                {
                  getNumericKeys(RecommendationClassificationNames).map((value, i) => {
                    return <MenuItem key={i} value={value}>{RecommendationClassificationNames[value]}</MenuItem>
                  })
                }
              </Select>
              <FormHelperText>{errors[`classification`]}</FormHelperText>
            </FormControl>
          </Box>
          <Box mt={2}>
            <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Verification Methods</Box>
            <FormControl
              variant="outlined"
              color='primary'
              required
              error={!!errors[`verificationMethods`]}
              size="small"
              disabled={!isOpen || isLoading}
              classes={{ root: classes.root }}
              fullWidth>
              <Select
                style={{ background: '#FFF' }}
                id="verificationMethods"
                classes={{ root: classes.root }}
                value={params.tags}
                inputProps={{
                  id: 'verification-methods-label',
                  classes,
                }}
                renderValue={() => {
                  if (!params.verificationMethods || !params.verificationMethods.length) {
                    return <Box className="wh-tw-text-mediumGray">Please select verification method</Box>;
                  }

                  return params.verificationMethods.length === 1 ? `${params.verificationMethods.length} method selected` : `${params.verificationMethods.length} methods selected`
                }}
                displayEmpty
                onChange={(v) => handleChanges("verificationMethods", v.target.value)}>
                {
                  getNumericKeys(VerificationMethodNames).map((value, i) => {
                    return <MenuItem key={i} value={value}>
                        <Box display="flex" alignItems="center" justifyContent="space-between" width={1}>
                        <Box className={clsx("wh-tw-text-sm wh-tw-font-medium", params.verificationMethods.includes(value) ? "wh-tw-text-primaryV" : "wh-tw-text-black")}>{VerificationMethodNames[value]}</Box>
                        {params.verificationMethods.includes(value) && <CheckIcon className="wh-tw-text-primaryV" />}
                      </Box>
                    </MenuItem>
                  })
                }
              </Select>
            </FormControl>
          </Box>
          <Box mt={2}>
            <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={3}>Triggers</Box>
            <Box my={2} display="flex" justifyContent="space-between">
              <Box display="flex" alignItems="flex-end" className="wh-tw-text-sm wh-tw-text-gray1">Summary</Box>
              <Box>
                <WildHealthButton
                  id="add-recommendation-trigger-button"
                  disabled={!params.id}
                  loading={isLoading}
                  onClick={() => handleAddTrigger(0)}
                >
                  <Box className="wh-tw-text-base wh-tw-font-medium">Add Trigger</Box>
                </WildHealthButton>
              </Box>
            </Box>
            <Divider className="wh-tw-bg-stroke" />
            {params.recommendationTriggers.length > 0 && (
              <Box my={2} p={1}>
                <Box flex={1} display="flex" alignItems="center" mb={1}>
                  <Box width={0.2} className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-black">Operator</Box>
                  <Box width={0.3} className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-black">Confidence</Box>
                  <Box width={0.5} className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-black">Components</Box>
                </Box>
                <Divider className="wh-tw-bg-stroke" />
                {params.recommendationTriggers.map(trigger => (
                  <Box key={trigger.id} display="flex" alignItems="center" justifyContent="space-between">
                    <Box flex={1} display="flex" alignItems="center">
                      <Box width={0.25}>
                        <Box className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-black">
                          {LogicalOperatorNames[trigger.logicalOperator]}
                        </Box>
                      </Box>
                      <Box width={0.25}>
                        <Box className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-black">
                          {trigger.confidence}
                        </Box>
                      </Box>
                      <Box width={0.5}>
                        <Box className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-black">
                          {trigger.recommendationTriggerComponents.length > 0 && trigger.recommendationTriggerComponents.map(component => (`${metrics.find(x => x.id === component.metricId)?.label} ${metrics.find(x => x.id === component.metricId)?.classificationType.name}`)).join(', ')}
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      <IconButton
                        id={`edit-trigger-${trigger.id}`}
                        className={classes.menuIcon}
                        disabled={isLoading}
                        onClick={(e) => handleAddTrigger(trigger.id)}
                      >
                        <MoreHorizIcon />
                      </IconButton>
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
          <Box mt={5} display="flex" justifyContent="flex-end" alignItems="center">
            <Box mx={2}>
              <WildHealthButton
                id="new-recommendation-cancel"
                onClick={() => handleClose()}
                color="tertiary"
                size='medium'
                disabled={!isOpen || isLoading}
              >
                Cancel
              </WildHealthButton>
            </Box>
            <Box>
              <WildHealthButton
                id="save-recommendation-button"
                size='medium'
                onClick={handleSave}
                loading={isLoading}
              >
                {params.id > 0 ? 'Update' : 'Save'}
              </WildHealthButton>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}