import React, { useEffect, useState } from 'react';
import { Box, Divider } from "@material-ui/core";
import { onEmit } from "../../../../common/helpers/on-emit";
import commonUseStyles from '../../../../common/styles/common.styles';
import WildHealthLinearProgress from "../../../../common/components/WildHealthLinearProgress";
import { PatientMedicationModel } from '../../../../patientSupplements/models/patientMedicationModel';
import { patientMedicationsQuery } from '../../../../patientSupplements/stores/patientMedicationsStore/patientMedications.query';
import { patientsMedicationsService } from '../../../../patientSupplements/services/patientMedications.service';
import { medicationsService } from '../../../../patientSupplements/services/medications.service';
import { Subscription } from 'rxjs';

export interface MedicationsAnswerComponentProps {
    patientId?: number | null;
    question: string;
}

export interface MedicationsAnswerComponentState {
    medications: PatientMedicationModel[],
    isLoading: boolean
}

export const MedicationsAnswerComponent: React.FC<MedicationsAnswerComponentProps> = (props: MedicationsAnswerComponentProps) => {
    const {
        patientId,
        question,
    } = props;

    const [state, setState] = useState({
        medications: [],
        isLoading: true
    } as MedicationsAnswerComponentState)

    const commonClasses = commonUseStyles();

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<PatientMedicationModel[]>(patientMedicationsQuery.patientMedications$, medications => {
                setState(state => ({
                    ...state,
                    medications: medications
                }));
            })
        ];

        const cb = () => {
            setState(state => ({
                ...state,
                isLoading: false
            }));
        }
        

        if (patientId) {
            medicationsService.get(patientId).subscribe(cb, cb)
        } else {
            patientsMedicationsService.get().subscribe(cb, cb)
        }

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [])

    const renderAnswer = () => {
        if (!state.medications.length) {
            return (
                <Box mt={2}>
                    <span>(no answer)</span>
                </Box>
            )
        }

        return (
            <>
                {
                    state.medications.map((medication, index) => (
                        <>
                            <Box key={index} py={2}>
                                <Box>
                                    <span>Name of Medication: <span className={commonClasses.textMedium}>{medication.name}</span></span>
                                </Box>
                                <Box>
                                    <span>Strength: <span className={commonClasses.textMedium}>{medication.dosage}</span></span>
                                </Box>
                                <Box>
                                    <span>Dosing Instruction: <span className={commonClasses.textMedium}>{medication.instructions}</span></span>
                                </Box>
                            </Box>
                            <Divider key={`${index}-divider`} />
                        </>
                    ))
                }
            </>
        )
    }
    const renderContent = (): JSX.Element => {
        if (state.isLoading) {
            return (<WildHealthLinearProgress />)
        }

        return (
            <>
                <Box className={commonClasses.colorGray1}>
                    <span>{question}</span>
                </Box>
                {
                    renderAnswer()
                }
            </>
        )
    }

    return (
        <>
            {
                renderContent()
            }
        </>
    )
}