import { ListItemText } from "@material-ui/core";
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      maxHeight: 300,
      overflow: "auto",
      "& .MuiListItem-divider": {
        borderBottomColor: colors.stroke,
      },
      background: colors.white,
    },
    title: {
      color: colors.gray1,
      fontFamily: "Be Vietnam Pro",
      fontWeight: 500,
      fontSize: 12,
      textTransform: "uppercase",
      padding: "16px 16px 0 16px",
      [theme.breakpoints.down("xs")]: {
        paddingBottom: 10,
      },
    },
    titleIcon: {
      fill: colors.gray1,
    },
    icon: {
      fill: colors.bad,
    },
    item: {
      "&:hover": {
        "& $removeUser": {
          visibility: "visible",
        },
      },
      background: "transparent",
    },
    removeUser: {
      visibility: "hidden",
    },
    addUser: {
      color: colors.main,
      fontFamily: "Be Vietnam Pro",
      fontWeight: 500,
      fontSize: 14,
    },
    avatar: {
      [theme.breakpoints.down("xs")]: {
        maxWidth: 34,
        height: 34,
      },
    },
  })
);

export const Member = withStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .MuiListItemText-primary": {
      color: colors.black,
      fontFamily: "Be Vietnam Pro",
      fontWeight: 500,
      fontSize: 16,
    },
    "& .MuiListItemText-secondary": {
      color: colors.gray1,
      fontFamily: "Be Vietnam Pro",
      fontSize: 12,
    },
  },
}))(ListItemText);
