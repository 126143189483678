import React, { useEffect, useState } from "react";
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { useHistory } from "react-router";
import { Subscription } from "recompose";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import { accountService } from "../../services/account.service";
import { onEmit } from "../../modules/common/helpers/on-emit";
import { applicationQuery, applicationStore } from "../../stores/applicationStore";
import { MenuItem, MenuItemTypes } from '../../models/menu.models';
import { User } from "../../modules/common/models/user.models";
import { patientsService } from '../../modules/patients/services/patients.service';
import { navigationService } from "../../services/navigation.service";
import { authQuery } from "../../modules/auth/stores/auth";

interface CollapsibleMenuItem extends MenuItem {
  isOpened: boolean;
}

interface NavigationComponentState {
  anchorEl: HTMLButtonElement;
  isOpen: boolean;
  menus: CollapsibleMenuItem[];
  isUsersLoading: boolean;
  users: User[];
  selectedUser: User;
}

export function useFacade(selectedMenuItem: MenuItemTypes, classes: any): [
  NavigationComponentState, Function, () => JSX.Element[], Function,
  (id: number) => void,
  (searchQuery: string) => void
] {
  const history = useHistory();
  const [state, setState] = useState({
    anchorEl: null,
    isOpen: false,
    menus: [],
    isUsersLoading: false,
    users: [],
    selectedUser: null,
  } as NavigationComponentState);

  const handleToggle = (event?: React.MouseEvent<HTMLButtonElement>) => {
    const anchorEl = event && event.currentTarget ? event.currentTarget : null;
    setState(state => ({
      ...state,
      isOpen: !state.isOpen,
      anchorEl: anchorEl
    }));
  }

  const handleLogOut = () => {
    accountService.logout();
  }

  const handleMenuItemToggle = (item: CollapsibleMenuItem, index: number) => {
    state.menus.forEach((i, idx) => {
      if (idx !== index) {
        i.isOpened = false;
      }
    });
    item.isOpened = !item.isOpened;
    setState(state => ({ ...state }));
    applicationStore.update({ isSideBarOpen: true });
  }

  const renderChildMenuItems = (item: CollapsibleMenuItem, key: string) => {
    const action = (child: MenuItem) => {
      state.menus.forEach(i => i.isOpened = false);
      child.action(history);
      applicationStore.update({ isSideBarOpen: false });
    };
    return (
      <Collapse key={key} in={item.isOpened} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {
            item.children.map((child, index) => {
              if (child.type === MenuItemTypes.Account) {
                return;
              }
              const isSelected = child.type === selectedMenuItem;

              return (
                <ListItem
                  id={`mobile-nav-bar-${child.title}`}
                  button
                  key={index}
                  hidden={!item.isOpened}
                  disabled={child.disabled}
                  className={clsx(classes.menuItem, {
                    [classes.menuItemSelected]: isSelected && !child.disabled,
                  }, authQuery.isEmployeeUser() && "wh-tw-pl-10")}
                  onClick={() => action(child)}
                >
                  {child?.icon && (
                    <ListItemIcon className={classes.menuIcon}>
                      {child.icon(isSelected)}
                    </ListItemIcon>
                  )}
                  <ListItemText className={classes.nested} primary={child.title} />
                  <ListItemIcon className={classes.menuIcon}>
                    {child.badge}
                  </ListItemIcon>
                </ListItem>
              )
            })
          }
        </List>
      </Collapse>
    )
  }

  const renderMenuItems = () => {
    const menuItems: JSX.Element[] = [];

    state.menus.forEach((item, index) => {
      if (item.disabled) {
        return;
      }
      let isSelected = item.type === selectedMenuItem;
      const hasChildren = item.children?.length > 0;
      const action = hasChildren ?
        () => {
          handleMenuItemToggle(item, index);
        } :
        () => {
          item.action(history);
        };

      if (hasChildren && !isSelected) {
        isSelected = item.children.find(i => i.type === selectedMenuItem) != null;
      }

      const listItem = (
        <ListItem
          id={`mobile-nav-bar-${item.title}`}
          className={clsx(classes.menuItem, {
            [classes.menuItemSelected]: isSelected && !item.disabled,
          })}
          selected={isSelected}
          disabled={item.disabled}
          onClick={() => action()}
          key={index}
          button
        >
          {authQuery.isPatientUser() && item?.icon && (
            <ListItemIcon className={clsx(classes.menuIcon, classes.menuIconSize)}>
              {item.icon(isSelected)}
            </ListItemIcon>
          )}
          {authQuery.isPatientUser() && (
            <ListItemIcon className={clsx(classes.menuIcon, classes.menuIconSize)}>
              {item.badge}
            </ListItemIcon>
          )}
          <ListItemText
            className={classes.menuTitle}
            primary={item.title}
            hidden={false}
          />
          {authQuery.isEmployeeUser() && (
            <ListItemIcon className={clsx(classes.menuIcon, classes.menuIconSize)}>
              {item.badge}
            </ListItemIcon>
          )}
          {
            hasChildren ? item.isOpened ? <ExpandLessIcon /> : <ExpandMoreIcon /> : <></>
          }
        </ListItem>
      );

      menuItems.push(listItem);

      if (hasChildren)
        menuItems.push(renderChildMenuItems(item, `${index}-children`));
    });

    return menuItems;
  }

  const handleUserSelect = (id: number) => {
    const user = state.users.find(i => i.id === id);
    if (user) {
      navigationService.toNewTabManagePatientProfile(id);
    }
  }

  const handleUserSearch = (searchQuery: string) => {
    setState(state => ({ ...state, isUsersLoading: true }));

    const successCB = (employees) => setState(state => ({ ...state, isUsersLoading: false, users: employees }));
    const errorCB = () => setState(state => ({ ...state, isUsersLoading: false }));

    patientsService.getAll([], [], [], [], [], [], searchQuery, 0, 10).subscribe(successCB, errorCB);
  }

  useEffect(() => {
    const subscriptions: Subscription[] = [
      onEmit<MenuItem[]>(applicationQuery.menuItems$, menus => {
        setState(state => ({ ...state, menus: menus.map(i => i as CollapsibleMenuItem) }));
      }),
    ];

    return () => {
      subscriptions.map(it => it.unsubscribe())
    };
  }, []);

  return [state, handleToggle, renderMenuItems, handleLogOut, handleUserSelect, handleUserSearch];
}