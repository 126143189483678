import React from 'react';
import { BackTitleComponent } from '../../../../components/pageTitleComponent/PageTitleComponent';
import { WildHealthHeaderComponent } from '../../../common/components/wildHealthHeaderComponent/WildHealthHeaderComponent';
import { LabOrderIFrameComponent } from '../labOrderIFrameComponent/LabOrderIFrameComponent';
import {Gender} from "../../../common/models/user.models";

interface NewLabsOrderComponentProps {
    patientId: number;
    gender: Gender;
    handleNavigateToOrders: () => void;
}

export const NewLabsOrderComponent: React.FC<NewLabsOrderComponentProps> = (props: NewLabsOrderComponentProps) => {
    const { patientId, gender, handleNavigateToOrders } = props;

    const title = (
        <BackTitleComponent
            title='New Order'
            actionSize='small'
            action={handleNavigateToOrders}
        />
    )

    return (
        <WildHealthHeaderComponent title={title}>
            <LabOrderIFrameComponent patientId={patientId} gender={gender} />
        </WildHealthHeaderComponent>
    );
}