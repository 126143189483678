import { useEffect, useState } from "react";
import {navigationService} from "../../../../services/navigation.service";
import {useHistory} from "react-router";
import { authQuery } from "../../stores/auth";
import { Subscription } from "recompose";

interface ActivateProfilePageState {
    verificationSent: boolean;
}

export function useFacade(): [
    ActivateProfilePageState,
    () => void,
    () => void] {

    const [state, setState] = useState({
        verificationSent: false
    } as ActivateProfilePageState);

    const history = useHistory();

    const handleVerificationSent = () => {
        setState({...state, verificationSent: true});
    }

    const handleVerificationSubmit = () => {
        navigationService.toDefault(history);
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
        ];

        if (authQuery.isVerified())
            navigationService.toDashboard(history);

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, []);

    return [
        state,
        handleVerificationSent,
        handleVerificationSubmit
    ];
}