/**
 * Represents Address Model
 */
export interface AddressModel {
    country: string;
    city: string;
    state: string;
    zipCode: string;
    streetAddress1: string;
    streetAddress2: string;
}

const emptyAddress = {
    country: 'USA',
    city: '',
    state: '',
    zipCode: '',
    streetAddress1: '',
    streetAddress2: '',
}


export const getInitAddress = () => Object.assign({}, emptyAddress);
