export interface LocationModel {
    id: number;
    name: string;
    description: string;
    country: string;
    city: string;
    state: string;
    zipCode: string;
    streetAddress1: string;
    streetAddress2: string;
    careCoordinatorId: number | null;
    type: LocationType;
}

export interface CreateLocationModel {
    name: string;
    description: string;
    country: string;
    city: string;
    state: string;
    zipCode: string;
    streetAddress1: string;
    streetAddress2: string;
}

export interface UpdateLocationModel {
    id: number;
    name: string;
    description: string;
    country: string;
    city: string;
    state: string;
    zipCode: string;
    streetAddress1: string;
    streetAddress2: string;
    careCoordinatorId: number | null;
    type: LocationType;
}

export interface LocationFilterModel {
    id: number;
    name: string;
    isSelected: boolean;
}

export interface PatientLocationModel {
    name: string;
    hoverText: string;
}

export enum LocationType {
    Default = 0,
    HeadOffice = 5,
    Fellowship = 10
}