import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogTitle: {
            paddingTop: '30px !important',
            paddingLeft: '40px !important',
            paddingRight: '40px !important'
        },
        dialogContent: {
            paddingLeft: 40,
            paddingRight: 40,
            [theme.breakpoints.down("xs")]: {
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: 0,
            },
        },
        dialogActions: {
            paddingBottom: 30,
            paddingLeft: 40,
            paddingRight: 40
        },
        actionSection: {
            borderTop: "1px solid",
            borderTopColor: "#3C3C435C",
        },
        acceptBtn: {
            textAlign: "center",
            borderLeft: "1px solid",
            borderLeftColor: "#3C3C435C",
        }
    })
);
