import Axios from 'axios-observable';
import { snackService } from "../../common/snack/state";
import { API_URL } from "../../../config";
import { subscriptionStore, SubscriptionStore } from "../stores/subscriptionStore";
import { authHeader } from "../../common/helpers/auth-header";
import {
    ActivateSubscriptionModel,
    BuyNewSubscriptionModel,
    CancelSubscriptionModel,
    SubscriptionModel,
    PauseSubscriptionModel,
    ManualPaymentModel,
    CreateManualPaymentModel
} from '../models/subscription.models';
import { Observable } from 'rxjs';

/**
 * Provides method for working with subscriptions
 */
export class SubscriptionService {
    private url = `${API_URL}Subscription`;
    private manualPaymentUrl = `${API_URL}ManualPayments`;

    constructor(private subscriptionStore: SubscriptionStore) {
    }

    /**
     * Returns active subscription
     */
    public getCurrent(): void {
        const config = {
            headers: authHeader()
        };
        Axios.get(`${this.url}/Current/My`, config)
            .pipe()
            .subscribe(
                (response) => {
                    this.subscriptionStore.update({ activeSubscription: response.data });
                }
            );
    }

    /**
     * Returns patient current subscription
     */
    public getPatientCurrent(patientId: number): Observable<any> {
        const config = {
            headers: authHeader()
        };

        return new Observable(observer => {
            Axios
                .get(`${this.url}/Current?patientId=${patientId}`, config)
                .pipe()
                .subscribe(
                    response => {
                        this.subscriptionStore.update({ activeSubscription: response.data });
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error()
                        observer.complete()
                    }
                );
        });
    }

    /**
     * Returns all my subscriptions
     */
    public getMy() {
        const config = {
            headers: authHeader()
        };

        return new Observable(observer => Axios
            .get(`${this.url}/My`, config)
            .pipe()
            .subscribe(
                response => {
                    this.subscriptionStore.update({ mySubscriptions: response.data });
                    observer.next(response.data);
                    observer.complete();
                },
                error => {
                    snackService.commonErrorHandler(error);
                    observer.error(error);
                    observer.complete();
                }
            )
        );
    }

    /**
     * Returns all patient subscriptions
     */
    public get(patientId: number): Observable<any> {
        const config = {
            headers: authHeader()
        };

        return new Observable(observer => {
            Axios
                .get(`${this.url}?patientId=${patientId}`, config)
                .pipe()
                .subscribe(
                    response => {
                        this.subscriptionStore.update({ patientSubscriptions: response.data });
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error()
                        observer.complete()
                    }
                );
        });
    }

    /**
     * Replace current with a paid payment plan
     */
    public Replace(model: BuyNewSubscriptionModel): Observable<SubscriptionModel> {
        const config = {
            headers: authHeader()
        };

        return new Observable(observer => {
            Axios.post(`${this.url}/Replace`, model, config)
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Cancel current plan
     */
    public Cancel(model: CancelSubscriptionModel): Observable<SubscriptionModel> {
        const config = {
            headers: authHeader()
        };

        return new Observable(observer => {
            Axios.post(`${this.url}/Cancel`, model, config)
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Activate subscription
     */
    public Activate(model: ActivateSubscriptionModel): Observable<SubscriptionModel> {
        const config = {
            headers: authHeader()
        };

        return new Observable(observer => {
            Axios.post<SubscriptionModel>(`${this.url}/Activate`, model, config)
                .pipe()
                .subscribe(
                    (response) => {
                        subscriptionStore.addPatientSubscription(response.data);
                        snackService.success('Membership was successfully activated.')
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public pausePlan(model: PauseSubscriptionModel): Observable<SubscriptionModel> {
        const config = {
            headers: authHeader()
        };

        return new Observable(observer => {
            Axios.post<SubscriptionModel>(`${this.url}/Pause`, model, config)
                .pipe()
                .subscribe(
                    (response) => {
                        subscriptionStore.updatePatientSubscription(response.data);
                        snackService.success('The plan was suspended!')
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public reactivePlan(subscriptionId: number): Observable<SubscriptionModel> {
        const config = {
            headers: authHeader()
        };

        return new Observable(observer => {
            Axios.post<SubscriptionModel>(`${this.url}/${subscriptionId}/Resume`, {}, config)
                .pipe()
                .subscribe(
                    (response) => {
                        subscriptionStore.updatePatientSubscription(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public getManualPayment(manualPaymentId: number): Observable<ManualPaymentModel> {
        let url = `${this.manualPaymentUrl}/${manualPaymentId}`;
        const config = {
            headers: authHeader()
        };
        return new Observable<ManualPaymentModel>(observer => {
            Axios.get<ManualPaymentModel>(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        subscriptionStore.update({ manualPayment: response.data })
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public addPremiumBilling(payload: CreateManualPaymentModel): Observable<ManualPaymentModel> {
        const config = {
            headers: authHeader()
        };

        return new Observable(observer => {
            Axios.post<ManualPaymentModel>(`${this.manualPaymentUrl}`, payload, config)
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }
}

export const subscriptionService = new SubscriptionService(subscriptionStore);