import { useEffect, useState } from 'react';
import { Subscription } from 'recompose';
import { DataSpecificationsEnum } from '../../../common/constants/data-specifications';
import { onEmit } from '../../../common/helpers/on-emit';
import { PatientModel } from "../../../patients/models/patient.model";
import { patientsService } from "../../../patients/services/patients.service";
import { patientsQuery } from '../../../patients/stores/patientsStore';

interface CoachPatientProfileComponentState {
    isLoading: boolean;
    patient: PatientModel;
}

export function useFacade(patientId: number): [
    CoachPatientProfileComponentState,
    (patient: PatientModel) => string] {

    const [state, setState] = useState({
        isLoading: true,
        patient: null,
    } as CoachPatientProfileComponentState);

    const displayPatientName = (patient: PatientModel) => {
        return patient
            ? `${patient.firstName} ${patient.lastName}`
            : 'Loading...';
    }

    /**
     * Load all ongoing cares
     * Manage subscriptions with auto-cleanup
     */
    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<PatientModel>(patientsQuery.targetPatient$, targetPatient => {
                if (targetPatient?.id === patientId) {
                    setState(state => ({ ...state, patient: targetPatient, isLoading: false }));
                }
            }),
        ];

        patientsService.get(patientId, DataSpecificationsEnum.UpdatePatientSpecification);

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, [patientId]);

    return [
        state,
        displayPatientName
    ];
}