import { useEffect, useState } from 'react';
import { Subscription } from 'recompose';
import { confirmService } from '../../../../services/confirm.service';
import { authQuery } from '../../../auth/stores/auth';
import { onEmit } from '../../../common/helpers/on-emit';
import { ResetPasswordModel } from "../../../common/models/user.models";
import { handleCompare } from "../../../common/sorting/helpers/handle-compare";
import { SortingDirection } from "../../../common/sorting/models/sorting-destination";
import { ISortingState } from "../../../common/sorting/models/sorting-state";
import { LocationModel } from '../../../locations/models/locations.models';
import { locationsService } from '../../../locations/services/locations.service';
import { locationsQuery } from '../../../locations/stores';
import { EmployeeShortModel, EmployeeShortViewModel } from "../../models/employee.models";
import { RoleModel } from '../../models/role.models';
import { employeeService } from "../../services/employees.service";
import { employeesQuery } from "../../stores/employeesStore";

export enum SortingSource {
    none,
    names = 'lastName',
    email = 'email',
    npi = 'npi',
    role = 'role',
}

interface ManageEmployeesComponentState extends ISortingState {
    isLoading: boolean;
    currentEmployeeEmail: string;
    employees: EmployeeShortViewModel[];
    isEditOpen: boolean;
    isResetPasswordOpen: boolean;
    selectedEmployeeId: number;
    selfManagingEmployee: boolean;
    isEmployeeActionsOpen: boolean;
    employeeActionsAnchorEl: null | HTMLElement;
    selectedRoleId: number;
    roles: RoleModel[];
    search: string;
}

const noneLocation = [{id: 0, country: '', city: 'All', state: '', zipCode: '', streetAddress: ''}];

export function useFacade(locationId?: number): [
    ManageEmployeesComponentState,
    (value: boolean) => void,
    (value: boolean) => void,
    () => void,
    () => void,
    (value: boolean, employeeId: number, employeeEmail: string, target: HTMLButtonElement) => void,
    (newPassword: string, confirmPassword: string) => void,
    (source: string, direction: SortingDirection) => void,
    (value: string) => void,
    (event: React.ChangeEvent<HTMLInputElement>) => void,
    (value: string) => void] {

    const [state, setState] = useState({
        isLoading: true,
        currentEmployeeEmail: authQuery.getEmail(),
        employees: [],
        sortingColumns: [
            {title: 'Names', source: SortingSource.names, direction: SortingDirection.Asc},
            {title: 'Email', source: SortingSource.email, direction: SortingDirection.Asc},
            {title: 'NPI', source: SortingSource.npi, direction: SortingDirection.Asc},
            {title: 'Role', source: SortingSource.role, direction: SortingDirection.Asc},
        ],
        sortingSource: SortingSource.none,
        isEditOpen: false,
        isResetPasswordOpen: false,
        selectedEmployeeId: 0,
        selfManagingEmployee: true,
        isEmployeeActionsOpen: false,
        employeeActionsAnchorEl: null,
        selectedRoleId: 0,
        roles: []
    } as ManageEmployeesComponentState);

    const handleToggleEditEmployee = (value: boolean) => {
        setState(state => ({
            ...state,
            isEditOpen: value,
            isEmployeeActionsOpen: false,
            employeeActionsAnchorEl: null
        }))
    }

    const handleToggleResetEmployeePassword = (value: boolean) => {
        setState(state => ({
            ...state,
            isResetPasswordOpen: value,
            isEmployeeActionsOpen: false,
            employeeActionsAnchorEl: null
        }))
    }

    const handleToggleEmployeeActions = (value: boolean, employeeId: number, employeeEmail: string, target: HTMLButtonElement) => {
        setState(state => ({
            ...state,
            isEmployeeActionsOpen: value,
            selectedEmployeeId: employeeId,
            selfManagingEmployee: state.currentEmployeeEmail === employeeEmail,
            employeeActionsAnchorEl: target
        }))
    }

    const handleResendEmployeeInvite = () => {
        const employee = state.employees.find(i => i.id === state.selectedEmployeeId);
        setState(state => ({
            ...state,
            isEmployeeActionsOpen: false,
            employeeActionsAnchorEl: null
        }));

        confirmService.confirm(`Are you sure you want to resend invite for ${employee.names}?`)
            .subscribe(() => {
                employeeService.resentEmployeeInvite(state.selectedEmployeeId);
            });
    }

    const handleDeleteEmployee = () => {
        const employee = state.employees.find(i => i.id === state.selectedEmployeeId);
        setState(state => ({
            ...state,
            isEmployeeActionsOpen: false,
            employeeActionsAnchorEl: null
        }));

        confirmService.confirm(`Are you sure you want to delete ${employee.names} user?`)
            .subscribe(() => {
                employeeService.deleteEmployee(state.selectedEmployeeId);
            });
    }

    const handleSubmitResetPassword = (newPassword: string, confirmPassword: string) => {
        const model = {
            newPassword: newPassword,
            confirmPassword: confirmPassword
        } as ResetPasswordModel

        employeeService.resetEmployeePassword(state.selectedEmployeeId, model);
    }

    const setDirection = (source: string, direction: SortingDirection) => {
        let itemIndex = state.sortingColumns.findIndex(item => item.source === source);
        state.sortingColumns[itemIndex].direction = direction;
        setState(state => ({...state, columns: state.sortingColumns}));
    }

    const handleSorting = (source: string, direction: SortingDirection) => {
        if (state.sortingSource === source) {
            direction = direction === SortingDirection.Asc
                ? SortingDirection.Desc
                : SortingDirection.Asc;

            setDirection(source, direction);
        }

        setState(state => ({
            ...state,
            sortingSource: source,
            employees: state.employees.sort((e1, e2) => handleCompare(e1, e2, direction, source))
        }));
    }

    const handleSearch = (value: string) => {
        setState(state => ({...state, isLoading: true}));

        const role = state.roles.find(i => i.id === state.selectedRoleId);

        searchEmployeesAction(value, locationId, role?.id)
            .subscribe(() => setState(state => ({
                ...state,
                isLoading: false
            })));
    }

    const handleSearchInputChange = (value: string) => {
        setState(state => ({ ...state, search: value }));
    }

    const handleSelectRole = (event: React.ChangeEvent<HTMLInputElement>) => {
        const id = parseInt((event.target as HTMLInputElement).value);
        const role = state.roles.find(i => i.id === id);

        setState(state => ({...state, isLoading: true, selectedRoleId: id}));

        searchEmployeesAction(state.search, locationId, role?.id)
        .subscribe(() => setState(state => ({
            ...state,
            isLoading: false
        })));
    }

    const searchEmployeesAction = (value: string, locationId?: number, roleId?: number) => {
        return employeeService.getActive(value, locationId, roleId);
    }

    const getEmployeesAction = (locationId?: number) => {
        return locationId ? employeeService.getActive('', locationId) : employeeService.getActive()
    }

    /**
     * Load all ongoing cares
     * Manage subscriptions with auto-cleanup
     */
    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<EmployeeShortModel[]>(employeesQuery.employees$, employees => {
                setState(state => ({
                    ...state,
                    employees: employees.filter(e => locationId ? e.locationIds.includes(locationId) : e).map(e => {
                        return {id: e.id, lastName: e.lastName, names: `${e.firstName} ${e.lastName}`, email: e.email, npi: e.npi, role: e.role}
                    })
                }))
            }),
            onEmit<LocationModel[]>(locationsQuery.availableLocations$, locations => {
                setState(state => ({...state, locations: Array.prototype.concat(noneLocation, locations)}))
            }),
            onEmit<RoleModel[]>(employeesQuery.roles$, roles => {
                setState(state => ({...state, roles: roles}))
            }),
        ];

        getEmployeesAction(locationId)
            .subscribe(() => setState(state => ({
                ...state,
                isLoading: false
            })));

        locationsService.getAvailable(authQuery.getCurrentPracticeId()).subscribe();

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, []);

    return [
        state,
        handleToggleEditEmployee,
        handleToggleResetEmployeePassword,
        handleResendEmployeeInvite,
        handleDeleteEmployee,
        handleToggleEmployeeActions,
        handleSubmitResetPassword,
        handleSorting,
        handleSearch,
        handleSelectRole,
        handleSearchInputChange
    ];
}