import React, { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { getLastObject } from "../../../common/helpers/get-last-object";
import { onEmit } from "../../../common/helpers/on-emit";
import { PageSliderNavigationContext } from "../../../common/models/navigation.models";
import {
    HealthReportMode,
    HealthReportModel,
    HealthReportPages,
    HealthReportStatus,
    HealthReportVersion,
    RecommendationDisplayModel
} from "../../models/healthReport.models";
import { healthReportServices } from "../../services/healthReport.services";
import { healthReportsQuery } from "../../stores/healthReportsStore";
import '../../a4Pages'
import {
    HealthReportAppendixPage,
    HealthReportChronicDisease0Page,
    HealthReportChronicDisease1Page,
    HealthReportChronicDisease2Page,
    HealthReportChronicDisease3Page,
    HealthReportChronicDisease4Page,
    HealthReportChronicDisease5Page,
    HealthReportDetails1Page,
    HealthReportDetails2Page,
    HealthReportDetails3Page,
    HealthReportDetails4Page,
    HealthReportDetails5Page,
    HealthReportDietAndNutrition0Page,
    HealthReportDietAndNutrition1Page,
    HealthReportDietAndNutrition2Page,
    HealthReportDietAndNutrition3Page,
    HealthReportDietAndNutrition4Page,
    HealthReportDietAndNutrition5Page,
    HealthReportDietAndNutrition6Page,
    HealthReportExerciseAndRecovery0Page,
    HealthReportExerciseAndRecovery1Page,
    HealthReportExerciseAndRecovery2Page,
    HealthReportExerciseAndRecovery3Page,
    HealthReportExerciseAndRecovery4Page,
    HealthReportExerciseAndRecovery5Page,
    HealthReportExerciseAndRecovery6Page,
    HealthReportExerciseAndRecovery7Page,
    HealthReportLongevity0Page,
    HealthReportLongevity1Page,
    HealthReportLongevity2Page,
    HealthReportMicrobiome0Page,
    HealthReportMicrobiome1Page,
    HealthReportMicrobiome2Page,
    HealthReportNeurobehavioral0Page,
    HealthReportNeurobehavioralPage,
    HealthReportSleep0Page,
    HealthReportSleepPage,
    HealthReportStartPage,
} from "../../a4Pages";
import { patientHealthReportService } from "../../services/patientHealthReport.service";
import { PermissionType, UserType } from "../../../auth/models/auth.enums";
import { authQuery } from "../../../auth/stores/auth";
import { getDefaultPracticeModel, PracticeProfileModel } from "../../../account/models/practiceProfile.model";
import { profileQuery } from "../../../account/stores/profileStore";
import { profileService } from "../../../account/services/profile.service";
import moment from "moment";
import { HealthReportRecommendationsPage } from "../../a4Pages/HealthReportRecommendationsPage";
import { confirmService } from "../../../../services/confirm.service";
import { snackService } from "../../../common/snack/state";

export interface NavigationStep {
    from: HealthReportPages;
    to: HealthReportPages;
    name: string;
}

/**
 * Represents Create Shortcuts Component state
 */
interface EmployeeHealthReportComponentState {
    currentPage: HealthReportPages;
    report: HealthReportModel;
    recommendations: RecommendationDisplayModel[];
    isChanged: boolean;
    mode: HealthReportMode;
    practice: PracticeProfileModel;
    isLoading: boolean;
}

/**
 * Custom Hook to manage a view Model for Create shortcuts component
 */
export function useFacade(patientId: number, isReadonly: boolean): [
    EmployeeHealthReportComponentState,
    PageSliderNavigationContext,
    JSX.Element,
    Array<NavigationStep>,
    JSX.Element,] {

    const [state, setState] = useState({
        currentPage: HealthReportPages.StartPage,
        report: null,
        recommendations: [],
        dnaInputs: null,
        isChanged: false,
        mode: HealthReportMode.EditMode,
        practice: getDefaultPracticeModel(),
        isLoading: true,
    } as EmployeeHealthReportComponentState);

    const goTo = (page: HealthReportPages) => {
        if (state.isChanged) {
            if (parseFloat(state.report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)) {
                const selectedRecommendations = state.recommendations.filter(el => el.isSelected)
                const recommendationsToSend = selectedRecommendations.map(el => {
                    return `${el.recommendationId}`.includes('xxx')
                        ? {
                            type: el.type,
                            baseId: el.baseId,
                            content: el.content,
                        }
                        : {
                            recommendationId: el.recommendationId,
                            baseId: el.baseId,
                            type: el.type,
                            content: el.content,
                        }
                })
                const reportToSend = {
                    ...state.report,
                    recommendations: recommendationsToSend
                }

                healthReportServices.save(reportToSend);
            } else {
                healthReportServices.save(state.report);
            }

        }
        setState(state => ({ ...state, currentPage: page, isChanged: false }));

        const step = getNavigationSteps(state.report?.status?.version)
            .find(i => i.from <= page && page <= i.to);

        if (step) {
            document.getElementById(`${step.from}-step`).scrollIntoView();
        }
        const reportStart = document.getElementById('report-start');
        if (reportStart) {
            reportStart.scrollIntoView();
        }
    }

    const handleChanges = (field: string, value: string) => {
        const report = state.report;
        const keys = field.split(".");
        const key = keys.pop();
        const lastObject = getLastObject(keys, report);
        lastObject[key] = value;

        setState(state => ({ ...state, report: { ...report }, isChanged: true }));
    }

    const handleSaveChanges = () => {
        healthReportServices.save(state.report);
        setState(state => ({ ...state, isChanged: false }));
    }

    const handleSaveChangesNew = () => {
        const selectedRecommendations = state.recommendations.filter(el => el.isSelected === true)
        const recommendationsToSend = selectedRecommendations.map(el => {
            return `${el.recommendationId}`.includes('xxx')
                ? {
                    type: el.type,
                    baseId: el.baseId,
                    content: el.content,
                }
                : {
                    recommendationId: el.recommendationId,
                    baseId: el.baseId,
                    id: el.id,
                    type: el.type,
                    content: el.content,
                }
        })
        const reportToSend = {
            ...state.report,
            recommendations: recommendationsToSend
        }

        healthReportServices.save(reportToSend);
        setState(state => ({ ...state, isChanged: false }));
    }


    const handleCancel = () => {
        confirmService.confirm(
            'Discard Changes',
            'Are you sure you want to quit without saving the changes?',
            'Yes',
            'No',
            'danger')
            .subscribe(
                () => {
                    healthReportServices.getById(state.report.id, patientId)
                    setState({ ...state, isChanged: false })
                    snackService.deleteSuccess('Changes was cancelled.')
                }
            );
    }


    const handleSelected = (id: number | string, isSelected: boolean) => {
        const recommendationForIsSelected = state.recommendations.find(el => el.id === id)
        recommendationForIsSelected.isSelected = isSelected
        setState({ ...state, isChanged: true })
    }

    const handleChangesNewRecommendation = (id: number | string, value: string) => {
        const recommendationForIsSelected = state.recommendations.find(el => el.id === id)
        recommendationForIsSelected.content = value
        setState({ ...state, isChanged: true })
    }

    const handleAddRecommendation = (type: number, index: number) => {
        const newRecommendation = {
            recommendationId: `xxx${index}`,
            id: moment().unix() + index,
            baseId: moment().unix() + index,
            type: type,
            content: '',
            isSelected: true,
        };
        setState({
            ...state,
            recommendations: [
                ...state.recommendations,
                newRecommendation,
            ]
        })
    }

    const healthReportPageProps = {
        mode: state.mode,
        report: state.report,
        isChanged: state.isChanged,
        practice: state.practice,
        handleChanges: patientId ? handleChanges : null,
        handleSaveChanges: handleSaveChanges,
        handleSelected,
        handleChangesNewRecommendation,
        handleAddRecommendation,
        handleSaveChangesNew,
        handleCancel,
        recommendations: state.recommendations,
    }

    const _defaultReportPages: Map<HealthReportPages, JSX.Element> = new Map<HealthReportPages, JSX.Element>([
        [HealthReportPages.StartPage, <HealthReportStartPage {...healthReportPageProps} />],
        [HealthReportPages.DietAndNutrition0, <HealthReportDietAndNutrition0Page {...healthReportPageProps} pageNumber={1} />],
        [HealthReportPages.DietAndNutrition1, <HealthReportDietAndNutrition1Page {...healthReportPageProps} pageNumber={2} />],
        [HealthReportPages.DietAndNutrition2, <HealthReportDietAndNutrition2Page {...healthReportPageProps} pageNumber={3} />],
        [HealthReportPages.DietAndNutrition3, <HealthReportDietAndNutrition3Page {...healthReportPageProps} pageNumber={4} />],
        [HealthReportPages.DietAndNutrition4, <HealthReportDietAndNutrition4Page {...healthReportPageProps} pageNumber={5} />],
        [HealthReportPages.DietAndNutrition5, <HealthReportDietAndNutrition5Page {...healthReportPageProps} pageNumber={6} />],
        [HealthReportPages.DietAndNutrition6, <HealthReportDietAndNutrition6Page {...healthReportPageProps} pageNumber={7} />],
        [HealthReportPages.ExerciseAndRecovery0, <HealthReportExerciseAndRecovery0Page {...healthReportPageProps} pageNumber={8} />],
        [HealthReportPages.ExerciseAndRecovery1, <HealthReportExerciseAndRecovery1Page {...healthReportPageProps} pageNumber={9} />],
        [HealthReportPages.ExerciseAndRecovery2, <HealthReportExerciseAndRecovery2Page {...healthReportPageProps} pageNumber={10} />],
        [HealthReportPages.ExerciseAndRecovery3, <HealthReportExerciseAndRecovery3Page {...healthReportPageProps} pageNumber={11} />],
        [HealthReportPages.ExerciseAndRecovery4, <HealthReportExerciseAndRecovery4Page {...healthReportPageProps} pageNumber={12} />],
        [HealthReportPages.ExerciseAndRecovery5, <HealthReportExerciseAndRecovery5Page {...healthReportPageProps} pageNumber={13} />],
        [HealthReportPages.ExerciseAndRecovery6, <HealthReportExerciseAndRecovery6Page {...healthReportPageProps} pageNumber={14} />],
        [HealthReportPages.ExerciseAndRecovery7, <HealthReportExerciseAndRecovery7Page {...healthReportPageProps} pageNumber={15} />],
        [HealthReportPages.Sleep0, <HealthReportSleep0Page {...healthReportPageProps} pageNumber={16} />],
        [HealthReportPages.Sleep1, <HealthReportSleepPage {...healthReportPageProps} pageNumber={17} />],
        [HealthReportPages.Neurobehavioral0, <HealthReportNeurobehavioral0Page {...healthReportPageProps} pageNumber={18} />],
        [HealthReportPages.Neurobehavioral1, <HealthReportNeurobehavioralPage {...healthReportPageProps} pageNumber={19} />],
        [HealthReportPages.Microbiome0, <HealthReportMicrobiome0Page {...healthReportPageProps} pageNumber={20} />],
        [HealthReportPages.Microbiome1, <HealthReportMicrobiome1Page {...healthReportPageProps} pageNumber={21} />],
        [HealthReportPages.Microbiome2, <HealthReportMicrobiome2Page {...healthReportPageProps} pageNumber={22} />],
        [HealthReportPages.ChronicDisease0, <HealthReportChronicDisease0Page {...healthReportPageProps} pageNumber={23} />],
        [HealthReportPages.ChronicDisease1, <HealthReportChronicDisease1Page {...healthReportPageProps} pageNumber={24} />],
        [HealthReportPages.ChronicDisease2, <HealthReportChronicDisease2Page {...healthReportPageProps} pageNumber={25} />],
        [HealthReportPages.ChronicDisease3, <HealthReportChronicDisease3Page {...healthReportPageProps} pageNumber={26} />],
        [HealthReportPages.ChronicDisease4, <HealthReportChronicDisease4Page {...healthReportPageProps} pageNumber={27} />],
        [HealthReportPages.ChronicDisease5, <HealthReportChronicDisease5Page {...healthReportPageProps} pageNumber={28} />],
        [HealthReportPages.Longevity0, <HealthReportLongevity0Page {...healthReportPageProps} pageNumber={29} />],
        [HealthReportPages.Longevity1, <HealthReportLongevity1Page {...healthReportPageProps} pageNumber={30} />],
        [HealthReportPages.Longevity2, <HealthReportLongevity2Page {...healthReportPageProps} pageNumber={31} />],
        [HealthReportPages.Details5, <HealthReportDetails5Page {...healthReportPageProps} pageNumber={32} />],
        [HealthReportPages.Appendix, <HealthReportAppendixPage {...healthReportPageProps} pageNumber={33} />],
        [HealthReportPages.Details1, <HealthReportDetails1Page {...healthReportPageProps} pageNumber={34} />],
        [HealthReportPages.Details2, <HealthReportDetails2Page {...healthReportPageProps} pageNumber={35} />],
        [HealthReportPages.Details3, <HealthReportDetails3Page {...healthReportPageProps} pageNumber={36} />],
        [HealthReportPages.Details4, <HealthReportDetails4Page {...healthReportPageProps} pageNumber={37} />],
    ]);


    const _defaultReportPagesNew: Map<HealthReportPages, JSX.Element> = new Map<HealthReportPages, JSX.Element>([
        [HealthReportPages.StartPage, <HealthReportStartPage {...healthReportPageProps} />],
        [HealthReportPages.DietAndNutrition0, <HealthReportDietAndNutrition0Page {...healthReportPageProps} pageNumber={1} />],
        [HealthReportPages.DietAndNutrition1, <HealthReportDietAndNutrition1Page {...healthReportPageProps} pageNumber={2} />],
        [HealthReportPages.DietAndNutrition2, <HealthReportDietAndNutrition2Page {...healthReportPageProps} pageNumber={3} />],
        [HealthReportPages.DietAndNutrition3, <HealthReportDietAndNutrition3Page {...healthReportPageProps} pageNumber={4} />],
        [HealthReportPages.DietAndNutrition4, <HealthReportDietAndNutrition4Page {...healthReportPageProps} pageNumber={5} />],
        [HealthReportPages.DietAndNutrition5, <HealthReportDietAndNutrition5Page {...healthReportPageProps} pageNumber={6} />],
        [HealthReportPages.DietAndNutrition6, <HealthReportDietAndNutrition6Page {...healthReportPageProps} pageNumber={7} />],
        [HealthReportPages.ExerciseAndRecovery0, <HealthReportExerciseAndRecovery0Page {...healthReportPageProps} pageNumber={8} />],
        [HealthReportPages.ExerciseAndRecovery1, <HealthReportExerciseAndRecovery1Page {...healthReportPageProps} pageNumber={9} />],
        [HealthReportPages.ExerciseAndRecovery2, <HealthReportExerciseAndRecovery2Page {...healthReportPageProps} pageNumber={10} />],
        [HealthReportPages.ExerciseAndRecovery7, <HealthReportExerciseAndRecovery7Page {...healthReportPageProps} pageNumber={11} />],
        [HealthReportPages.Sleep0, <HealthReportSleep0Page {...healthReportPageProps} pageNumber={12} />],
        [HealthReportPages.Sleep1, <HealthReportSleepPage {...healthReportPageProps} pageNumber={13} />],
        [HealthReportPages.Neurobehavioral0, <HealthReportNeurobehavioral0Page {...healthReportPageProps} pageNumber={14} />],
        [HealthReportPages.Neurobehavioral1, <HealthReportNeurobehavioralPage {...healthReportPageProps} pageNumber={15} />],
        [HealthReportPages.Microbiome0, <HealthReportMicrobiome0Page {...healthReportPageProps} pageNumber={16} />],
        [HealthReportPages.Microbiome1, <HealthReportMicrobiome1Page {...healthReportPageProps} pageNumber={17} />],
        [HealthReportPages.Microbiome2, <HealthReportMicrobiome2Page {...healthReportPageProps} pageNumber={18} />],
        [HealthReportPages.ChronicDisease0, <HealthReportChronicDisease0Page {...healthReportPageProps} pageNumber={19} />],
        [HealthReportPages.ChronicDisease1, <HealthReportChronicDisease1Page {...healthReportPageProps} pageNumber={20} />],
        [HealthReportPages.ChronicDisease2, <HealthReportChronicDisease2Page {...healthReportPageProps} pageNumber={21} />],
        [HealthReportPages.ChronicDisease3, <HealthReportChronicDisease3Page {...healthReportPageProps} pageNumber={22} />],
        [HealthReportPages.ChronicDisease4, <HealthReportChronicDisease4Page {...healthReportPageProps} pageNumber={23} />],
        [HealthReportPages.ChronicDisease5, <HealthReportChronicDisease5Page {...healthReportPageProps} pageNumber={24} />],
        [HealthReportPages.Longevity0, <HealthReportLongevity0Page {...healthReportPageProps} pageNumber={25} />],
        [HealthReportPages.Longevity1, <HealthReportLongevity1Page {...healthReportPageProps} pageNumber={26} />],
        [HealthReportPages.Longevity2, <HealthReportLongevity2Page {...healthReportPageProps} pageNumber={27} />],
        [HealthReportPages.Details5, <HealthReportDetails5Page {...healthReportPageProps} pageNumber={28} />],
        [HealthReportPages.Appendix, <HealthReportAppendixPage {...healthReportPageProps} pageNumber={29} />],
        [HealthReportPages.Details1, <HealthReportDetails1Page {...healthReportPageProps} pageNumber={30} />],
        [HealthReportPages.Details2, <HealthReportDetails2Page {...healthReportPageProps} pageNumber={31} />],
        [HealthReportPages.Details3, <HealthReportDetails3Page {...healthReportPageProps} pageNumber={32} />],
        [HealthReportPages.Details4, <HealthReportDetails4Page {...healthReportPageProps} pageNumber={33} />],
    ]);

    const _defaultReportPagesRecommendation: Map<HealthReportPages, JSX.Element> = new Map<HealthReportPages, JSX.Element>([
        [HealthReportPages.StartPage, <HealthReportStartPage {...healthReportPageProps} />],
        [HealthReportPages.DietAndNutrition0, <HealthReportDietAndNutrition0Page {...healthReportPageProps} pageNumber={1} />],
        [HealthReportPages.DietAndNutrition1, <HealthReportDietAndNutrition1Page {...healthReportPageProps} pageNumber={2} />],
        [HealthReportPages.DietAndNutrition2, <HealthReportDietAndNutrition2Page {...healthReportPageProps} pageNumber={3} />],
        [HealthReportPages.DietAndNutrition3, <HealthReportDietAndNutrition3Page {...healthReportPageProps} pageNumber={4} />],
        [HealthReportPages.DietAndNutrition4, <HealthReportDietAndNutrition4Page {...healthReportPageProps} pageNumber={5} />],
        [HealthReportPages.DietAndNutrition5, <HealthReportDietAndNutrition5Page {...healthReportPageProps} pageNumber={6} />],
        [HealthReportPages.DietAndNutrition6, <HealthReportDietAndNutrition6Page {...healthReportPageProps} pageNumber={7} />],
        [HealthReportPages.ExerciseAndRecovery0, <HealthReportExerciseAndRecovery0Page {...healthReportPageProps} pageNumber={8} />],
        [HealthReportPages.ExerciseAndRecovery1, <HealthReportExerciseAndRecovery1Page {...healthReportPageProps} pageNumber={9} />],
        [HealthReportPages.ExerciseAndRecovery2, <HealthReportExerciseAndRecovery2Page {...healthReportPageProps} pageNumber={10} />],
        [HealthReportPages.ExerciseAndRecovery7, <HealthReportExerciseAndRecovery7Page {...healthReportPageProps} pageNumber={11} />],
        [HealthReportPages.Sleep0, <HealthReportSleep0Page {...healthReportPageProps} pageNumber={12} />],
        [HealthReportPages.Sleep1, <HealthReportSleepPage {...healthReportPageProps} pageNumber={13} />],
        [HealthReportPages.Neurobehavioral0, <HealthReportNeurobehavioral0Page {...healthReportPageProps} pageNumber={14} />],
        [HealthReportPages.Neurobehavioral1, <HealthReportNeurobehavioralPage {...healthReportPageProps} pageNumber={15} />],
        [HealthReportPages.Microbiome0, <HealthReportMicrobiome0Page {...healthReportPageProps} pageNumber={16} />],
        [HealthReportPages.Microbiome1, <HealthReportMicrobiome1Page {...healthReportPageProps} pageNumber={17} />],
        [HealthReportPages.Microbiome2, <HealthReportMicrobiome2Page {...healthReportPageProps} pageNumber={18} />],
        [HealthReportPages.ChronicDisease0, <HealthReportChronicDisease0Page {...healthReportPageProps} pageNumber={19} />],
        [HealthReportPages.ChronicDisease1, <HealthReportChronicDisease1Page {...healthReportPageProps} pageNumber={20} />],
        [HealthReportPages.ChronicDisease2, <HealthReportChronicDisease2Page {...healthReportPageProps} pageNumber={21} />],
        [HealthReportPages.ChronicDisease3, <HealthReportChronicDisease3Page {...healthReportPageProps} pageNumber={22} />],
        [HealthReportPages.ChronicDisease4, <HealthReportChronicDisease4Page {...healthReportPageProps} pageNumber={23} />],
        [HealthReportPages.ChronicDisease5, <HealthReportChronicDisease5Page {...healthReportPageProps} pageNumber={24} />],
        [HealthReportPages.Longevity0, <HealthReportLongevity0Page {...healthReportPageProps} pageNumber={25} />],
        [HealthReportPages.Longevity1, <HealthReportLongevity1Page {...healthReportPageProps} pageNumber={26} />],
        [HealthReportPages.Longevity2, <HealthReportLongevity2Page {...healthReportPageProps} pageNumber={27} />],
        [HealthReportPages.Details5, <HealthReportDetails5Page {...healthReportPageProps} pageNumber={28} />],
        [HealthReportPages.Appendix, <HealthReportAppendixPage {...healthReportPageProps} pageNumber={29} />],
        [HealthReportPages.Details1, <HealthReportDetails1Page {...healthReportPageProps} pageNumber={30} />],
        [HealthReportPages.Details2, <HealthReportDetails2Page {...healthReportPageProps} pageNumber={31} />],
        [HealthReportPages.Details3, <HealthReportDetails3Page {...healthReportPageProps} pageNumber={32} />],
        [HealthReportPages.Details4, <HealthReportDetails4Page {...healthReportPageProps} pageNumber={33} />],
        [HealthReportPages.Recommendations, <HealthReportRecommendationsPage {...healthReportPageProps} pageNumber={34} />],
    ]);

    const _defaultReportPagesRecommendationForPatient: Map<HealthReportPages, JSX.Element> = new Map<HealthReportPages, JSX.Element>([
        [HealthReportPages.StartPage, <HealthReportStartPage {...healthReportPageProps} />],
        [HealthReportPages.DietAndNutrition0, <HealthReportDietAndNutrition0Page {...healthReportPageProps} pageNumber={1} />],
        [HealthReportPages.DietAndNutrition1, <HealthReportDietAndNutrition1Page {...healthReportPageProps} pageNumber={2} />],
        [HealthReportPages.DietAndNutrition2, <HealthReportDietAndNutrition2Page {...healthReportPageProps} pageNumber={3} />],
        [HealthReportPages.DietAndNutrition3, <HealthReportDietAndNutrition3Page {...healthReportPageProps} pageNumber={4} />],
        [HealthReportPages.DietAndNutrition4, <HealthReportDietAndNutrition4Page {...healthReportPageProps} pageNumber={5} />],
        [HealthReportPages.DietAndNutrition5, <HealthReportDietAndNutrition5Page {...healthReportPageProps} pageNumber={6} />],
        [HealthReportPages.DietAndNutrition6, <HealthReportDietAndNutrition6Page {...healthReportPageProps} pageNumber={7} />],
        [HealthReportPages.ExerciseAndRecovery0, <HealthReportExerciseAndRecovery0Page {...healthReportPageProps} pageNumber={8} />],
        [HealthReportPages.ExerciseAndRecovery1, <HealthReportExerciseAndRecovery1Page {...healthReportPageProps} pageNumber={9} />],
        [HealthReportPages.ExerciseAndRecovery2, <HealthReportExerciseAndRecovery2Page {...healthReportPageProps} pageNumber={10} />],
        [HealthReportPages.ExerciseAndRecovery7, <HealthReportExerciseAndRecovery7Page {...healthReportPageProps} pageNumber={11} />],
        [HealthReportPages.Sleep0, <HealthReportSleep0Page {...healthReportPageProps} pageNumber={12} />],
        [HealthReportPages.Sleep1, <HealthReportSleepPage {...healthReportPageProps} pageNumber={13} />],
        [HealthReportPages.Neurobehavioral0, <HealthReportNeurobehavioral0Page {...healthReportPageProps} pageNumber={14} />],
        [HealthReportPages.Neurobehavioral1, <HealthReportNeurobehavioralPage {...healthReportPageProps} pageNumber={15} />],
        [HealthReportPages.Microbiome0, <HealthReportMicrobiome0Page {...healthReportPageProps} pageNumber={16} />],
        [HealthReportPages.Microbiome1, <HealthReportMicrobiome1Page {...healthReportPageProps} pageNumber={17} />],
        [HealthReportPages.Microbiome2, <HealthReportMicrobiome2Page {...healthReportPageProps} pageNumber={18} />],
        [HealthReportPages.ChronicDisease0, <HealthReportChronicDisease0Page {...healthReportPageProps} pageNumber={19} />],
        [HealthReportPages.ChronicDisease1, <HealthReportChronicDisease1Page {...healthReportPageProps} pageNumber={20} />],
        [HealthReportPages.ChronicDisease2, <HealthReportChronicDisease2Page {...healthReportPageProps} pageNumber={21} />],
        [HealthReportPages.ChronicDisease3, <HealthReportChronicDisease3Page {...healthReportPageProps} pageNumber={22} />],
        [HealthReportPages.ChronicDisease4, <HealthReportChronicDisease4Page {...healthReportPageProps} pageNumber={23} />],
        [HealthReportPages.ChronicDisease5, <HealthReportChronicDisease5Page {...healthReportPageProps} pageNumber={24} />],
        [HealthReportPages.Longevity0, <HealthReportLongevity0Page {...healthReportPageProps} pageNumber={25} />],
        [HealthReportPages.Longevity1, <HealthReportLongevity1Page {...healthReportPageProps} pageNumber={26} />],
        [HealthReportPages.Longevity2, <HealthReportLongevity2Page {...healthReportPageProps} pageNumber={27} />],
        [HealthReportPages.Details5, <HealthReportDetails5Page {...healthReportPageProps} pageNumber={28} />],
        [HealthReportPages.Appendix, <HealthReportAppendixPage {...healthReportPageProps} pageNumber={29} />],
        [HealthReportPages.Details1, <HealthReportDetails1Page {...healthReportPageProps} pageNumber={30} />],
        [HealthReportPages.Details2, <HealthReportDetails2Page {...healthReportPageProps} pageNumber={31} />],
        [HealthReportPages.Details3, <HealthReportDetails3Page {...healthReportPageProps} pageNumber={32} />],
        [HealthReportPages.Details4, <HealthReportDetails4Page {...healthReportPageProps} pageNumber={33} />],
    ]);


    const _defaultMobileReportPages: Map<HealthReportPages, JSX.Element> = new Map<HealthReportPages, JSX.Element>([
        [HealthReportPages.StartPage, <HealthReportStartPage {...healthReportPageProps} />],
        [HealthReportPages.DietAndNutrition0, <>
            <HealthReportDietAndNutrition0Page {...healthReportPageProps} pageNumber={1} />
            <HealthReportDietAndNutrition1Page {...healthReportPageProps} pageNumber={2} />
            <HealthReportDietAndNutrition2Page {...healthReportPageProps} pageNumber={3} />
            <HealthReportDietAndNutrition3Page {...healthReportPageProps} pageNumber={4} />
            <HealthReportDietAndNutrition4Page {...healthReportPageProps} pageNumber={5} />
            <HealthReportDietAndNutrition5Page {...healthReportPageProps} pageNumber={6} />
            <HealthReportDietAndNutrition6Page {...healthReportPageProps} pageNumber={7} />
        </>],
        [HealthReportPages.ExerciseAndRecovery0, <>
            <HealthReportExerciseAndRecovery0Page {...healthReportPageProps} pageNumber={8} />
            <HealthReportExerciseAndRecovery1Page {...healthReportPageProps} pageNumber={9} />
            <HealthReportExerciseAndRecovery2Page {...healthReportPageProps} pageNumber={10} />
            <HealthReportExerciseAndRecovery3Page {...healthReportPageProps} pageNumber={11} />
            <HealthReportExerciseAndRecovery4Page {...healthReportPageProps} pageNumber={12} />
            <HealthReportExerciseAndRecovery5Page {...healthReportPageProps} pageNumber={13} />
            <HealthReportExerciseAndRecovery6Page {...healthReportPageProps} pageNumber={14} />
            <HealthReportExerciseAndRecovery7Page {...healthReportPageProps} pageNumber={15} />
        </>],
        [HealthReportPages.Sleep0, <>
            <HealthReportSleep0Page {...healthReportPageProps} pageNumber={16} />
            <HealthReportSleepPage {...healthReportPageProps} pageNumber={17} />
        </>],
        [HealthReportPages.Neurobehavioral0, <>
            <HealthReportNeurobehavioral0Page {...healthReportPageProps} pageNumber={18} />
            <HealthReportNeurobehavioralPage {...healthReportPageProps} pageNumber={19} />
        </>],
        [HealthReportPages.Microbiome0, <>
            <HealthReportMicrobiome0Page {...healthReportPageProps} pageNumber={20} />
            <HealthReportMicrobiome1Page {...healthReportPageProps} pageNumber={21} />
            <HealthReportMicrobiome2Page {...healthReportPageProps} pageNumber={22} />
        </>],
        [HealthReportPages.ChronicDisease0, <>
            <HealthReportChronicDisease0Page {...healthReportPageProps} pageNumber={23} />
            <HealthReportChronicDisease1Page {...healthReportPageProps} pageNumber={24} />
            <HealthReportChronicDisease2Page {...healthReportPageProps} pageNumber={25} />
            <HealthReportChronicDisease3Page {...healthReportPageProps} pageNumber={26} />
            <HealthReportChronicDisease4Page {...healthReportPageProps} pageNumber={27} />
            <HealthReportChronicDisease5Page {...healthReportPageProps} pageNumber={28} />
        </>],
        [HealthReportPages.Longevity0, <>
            <HealthReportLongevity0Page {...healthReportPageProps} pageNumber={29} />
            <HealthReportLongevity1Page {...healthReportPageProps} pageNumber={30} />
            <HealthReportLongevity2Page {...healthReportPageProps} pageNumber={31} />
        </>],
        [HealthReportPages.Details5, <HealthReportDetails5Page {...healthReportPageProps} pageNumber={32} />],
        [HealthReportPages.Appendix, <HealthReportAppendixPage {...healthReportPageProps} pageNumber={33} />],
        [HealthReportPages.Details1, <>
            <HealthReportDetails1Page {...healthReportPageProps} pageNumber={34} />
            <HealthReportDetails2Page {...healthReportPageProps} pageNumber={35} />
            <HealthReportDetails3Page {...healthReportPageProps} pageNumber={36} />
            <HealthReportDetails4Page {...healthReportPageProps} pageNumber={37} />
        </>],
    ]);

    const _defaultMobileReportPagesNew: Map<HealthReportPages, JSX.Element> = new Map<HealthReportPages, JSX.Element>([
        [HealthReportPages.StartPage, <HealthReportStartPage {...healthReportPageProps} />],
        [HealthReportPages.DietAndNutrition0, <>
            <HealthReportDietAndNutrition0Page {...healthReportPageProps} pageNumber={1} />
            <HealthReportDietAndNutrition1Page {...healthReportPageProps} pageNumber={2} />
            <HealthReportDietAndNutrition2Page {...healthReportPageProps} pageNumber={3} />
            <HealthReportDietAndNutrition3Page {...healthReportPageProps} pageNumber={4} />
            <HealthReportDietAndNutrition4Page {...healthReportPageProps} pageNumber={5} />
            <HealthReportDietAndNutrition5Page {...healthReportPageProps} pageNumber={6} />
            <HealthReportDietAndNutrition6Page {...healthReportPageProps} pageNumber={7} />
        </>],
        [HealthReportPages.ExerciseAndRecovery0, <>
            <HealthReportExerciseAndRecovery0Page {...healthReportPageProps} pageNumber={8} />
            <HealthReportExerciseAndRecovery1Page {...healthReportPageProps} pageNumber={9} />
            <HealthReportExerciseAndRecovery2Page {...healthReportPageProps} pageNumber={10} />
            <HealthReportExerciseAndRecovery7Page {...healthReportPageProps} pageNumber={11} />
        </>],
        [HealthReportPages.Sleep0, <>
            <HealthReportSleep0Page {...healthReportPageProps} pageNumber={12} />
            <HealthReportSleepPage {...healthReportPageProps} pageNumber={13} />
        </>],
        [HealthReportPages.Neurobehavioral0, <>
            <HealthReportNeurobehavioral0Page {...healthReportPageProps} pageNumber={14} />
            <HealthReportNeurobehavioralPage {...healthReportPageProps} pageNumber={15} />
        </>],
        [HealthReportPages.Microbiome0, <>
            <HealthReportMicrobiome0Page {...healthReportPageProps} pageNumber={16} />
            <HealthReportMicrobiome1Page {...healthReportPageProps} pageNumber={17} />
            <HealthReportMicrobiome2Page {...healthReportPageProps} pageNumber={18} />
        </>],
        [HealthReportPages.ChronicDisease0, <>
            <HealthReportChronicDisease0Page {...healthReportPageProps} pageNumber={19} />
            <HealthReportChronicDisease1Page {...healthReportPageProps} pageNumber={20} />
            <HealthReportChronicDisease2Page {...healthReportPageProps} pageNumber={21} />
            <HealthReportChronicDisease3Page {...healthReportPageProps} pageNumber={22} />
            <HealthReportChronicDisease4Page {...healthReportPageProps} pageNumber={23} />
            <HealthReportChronicDisease5Page {...healthReportPageProps} pageNumber={24} />
        </>],
        [HealthReportPages.Longevity0, <>
            <HealthReportLongevity0Page {...healthReportPageProps} pageNumber={25} />
            <HealthReportLongevity1Page {...healthReportPageProps} pageNumber={26} />
            <HealthReportLongevity2Page {...healthReportPageProps} pageNumber={27} />
        </>],
        [HealthReportPages.Details5, <HealthReportDetails5Page {...healthReportPageProps} pageNumber={28} />],
        [HealthReportPages.Appendix, <HealthReportAppendixPage {...healthReportPageProps} pageNumber={29} />],
        [HealthReportPages.Details1, <>
            <HealthReportDetails1Page {...healthReportPageProps} pageNumber={30} />
            <HealthReportDetails2Page {...healthReportPageProps} pageNumber={31} />
            <HealthReportDetails3Page {...healthReportPageProps} pageNumber={32} />
            <HealthReportDetails4Page {...healthReportPageProps} pageNumber={33} />
        </>],
    ]);

    const _defaultMobileReportPagesRecommendation: Map<HealthReportPages, JSX.Element> = new Map<HealthReportPages, JSX.Element>([
        [HealthReportPages.StartPage, <HealthReportStartPage {...healthReportPageProps} />],
        [HealthReportPages.DietAndNutrition0, <>
            <HealthReportDietAndNutrition0Page {...healthReportPageProps} pageNumber={1} />
            <HealthReportDietAndNutrition1Page {...healthReportPageProps} pageNumber={2} />
            <HealthReportDietAndNutrition2Page {...healthReportPageProps} pageNumber={3} />
            <HealthReportDietAndNutrition3Page {...healthReportPageProps} pageNumber={4} />
            <HealthReportDietAndNutrition4Page {...healthReportPageProps} pageNumber={5} />
            <HealthReportDietAndNutrition5Page {...healthReportPageProps} pageNumber={6} />
            <HealthReportDietAndNutrition6Page {...healthReportPageProps} pageNumber={7} />
        </>],
        [HealthReportPages.ExerciseAndRecovery0, <>
            <HealthReportExerciseAndRecovery0Page {...healthReportPageProps} pageNumber={8} />
            <HealthReportExerciseAndRecovery1Page {...healthReportPageProps} pageNumber={9} />
            <HealthReportExerciseAndRecovery2Page {...healthReportPageProps} pageNumber={10} />
            <HealthReportExerciseAndRecovery7Page {...healthReportPageProps} pageNumber={11} />
        </>],
        [HealthReportPages.Sleep0, <>
            <HealthReportSleep0Page {...healthReportPageProps} pageNumber={12} />
            <HealthReportSleepPage {...healthReportPageProps} pageNumber={13} />
        </>],
        [HealthReportPages.Neurobehavioral0, <>
            <HealthReportNeurobehavioral0Page {...healthReportPageProps} pageNumber={14} />
            <HealthReportNeurobehavioralPage {...healthReportPageProps} pageNumber={15} />
        </>],
        [HealthReportPages.Microbiome0, <>
            <HealthReportMicrobiome0Page {...healthReportPageProps} pageNumber={16} />
            <HealthReportMicrobiome1Page {...healthReportPageProps} pageNumber={17} />
            <HealthReportMicrobiome2Page {...healthReportPageProps} pageNumber={18} />
        </>],
        [HealthReportPages.ChronicDisease0, <>
            <HealthReportChronicDisease0Page {...healthReportPageProps} pageNumber={19} />
            <HealthReportChronicDisease1Page {...healthReportPageProps} pageNumber={20} />
            <HealthReportChronicDisease2Page {...healthReportPageProps} pageNumber={21} />
            <HealthReportChronicDisease3Page {...healthReportPageProps} pageNumber={22} />
            <HealthReportChronicDisease4Page {...healthReportPageProps} pageNumber={23} />
            <HealthReportChronicDisease5Page {...healthReportPageProps} pageNumber={24} />
        </>],
        [HealthReportPages.Longevity0, <>
            <HealthReportLongevity0Page {...healthReportPageProps} pageNumber={25} />
            <HealthReportLongevity1Page {...healthReportPageProps} pageNumber={26} />
            <HealthReportLongevity2Page {...healthReportPageProps} pageNumber={27} />
        </>],
        [HealthReportPages.Details5, <HealthReportDetails5Page {...healthReportPageProps} pageNumber={28} />],
        [HealthReportPages.Appendix, <HealthReportAppendixPage {...healthReportPageProps} pageNumber={29} />],
        [HealthReportPages.Details1, <>
            <HealthReportDetails1Page {...healthReportPageProps} pageNumber={30} />
            <HealthReportDetails2Page {...healthReportPageProps} pageNumber={31} />
            <HealthReportDetails3Page {...healthReportPageProps} pageNumber={32} />
            <HealthReportDetails4Page {...healthReportPageProps} pageNumber={33} />
            <HealthReportRecommendationsPage {...healthReportPageProps} pageNumber={34} />
        </>],
    ]);

    const _defaultMobileReportPagesRecommendationForPatient: Map<HealthReportPages, JSX.Element> = new Map<HealthReportPages, JSX.Element>([
        [HealthReportPages.StartPage, <HealthReportStartPage {...healthReportPageProps} />],
        [HealthReportPages.DietAndNutrition0, <>
            <HealthReportDietAndNutrition0Page {...healthReportPageProps} pageNumber={1} />
            <HealthReportDietAndNutrition1Page {...healthReportPageProps} pageNumber={2} />
            <HealthReportDietAndNutrition2Page {...healthReportPageProps} pageNumber={3} />
            <HealthReportDietAndNutrition3Page {...healthReportPageProps} pageNumber={4} />
            <HealthReportDietAndNutrition4Page {...healthReportPageProps} pageNumber={5} />
            <HealthReportDietAndNutrition5Page {...healthReportPageProps} pageNumber={6} />
            <HealthReportDietAndNutrition6Page {...healthReportPageProps} pageNumber={7} />
        </>],
        [HealthReportPages.ExerciseAndRecovery0, <>
            <HealthReportExerciseAndRecovery0Page {...healthReportPageProps} pageNumber={8} />
            <HealthReportExerciseAndRecovery1Page {...healthReportPageProps} pageNumber={9} />
            <HealthReportExerciseAndRecovery2Page {...healthReportPageProps} pageNumber={10} />
            <HealthReportExerciseAndRecovery7Page {...healthReportPageProps} pageNumber={11} />
        </>],
        [HealthReportPages.Sleep0, <>
            <HealthReportSleep0Page {...healthReportPageProps} pageNumber={12} />
            <HealthReportSleepPage {...healthReportPageProps} pageNumber={13} />
        </>],
        [HealthReportPages.Neurobehavioral0, <>
            <HealthReportNeurobehavioral0Page {...healthReportPageProps} pageNumber={14} />
            <HealthReportNeurobehavioralPage {...healthReportPageProps} pageNumber={15} />
        </>],
        [HealthReportPages.Microbiome0, <>
            <HealthReportMicrobiome0Page {...healthReportPageProps} pageNumber={16} />
            <HealthReportMicrobiome1Page {...healthReportPageProps} pageNumber={17} />
            <HealthReportMicrobiome2Page {...healthReportPageProps} pageNumber={18} />
        </>],
        [HealthReportPages.ChronicDisease0, <>
            <HealthReportChronicDisease0Page {...healthReportPageProps} pageNumber={19} />
            <HealthReportChronicDisease1Page {...healthReportPageProps} pageNumber={20} />
            <HealthReportChronicDisease2Page {...healthReportPageProps} pageNumber={21} />
            <HealthReportChronicDisease3Page {...healthReportPageProps} pageNumber={22} />
            <HealthReportChronicDisease4Page {...healthReportPageProps} pageNumber={23} />
            <HealthReportChronicDisease5Page {...healthReportPageProps} pageNumber={24} />
        </>],
        [HealthReportPages.Longevity0, <>
            <HealthReportLongevity0Page {...healthReportPageProps} pageNumber={25} />
            <HealthReportLongevity1Page {...healthReportPageProps} pageNumber={26} />
            <HealthReportLongevity2Page {...healthReportPageProps} pageNumber={27} />
        </>],
        [HealthReportPages.Details5, <HealthReportDetails5Page {...healthReportPageProps} pageNumber={28} />],
        [HealthReportPages.Appendix, <HealthReportAppendixPage {...healthReportPageProps} pageNumber={29} />],
        [HealthReportPages.Details1, <>
            <HealthReportDetails1Page {...healthReportPageProps} pageNumber={30} />
            <HealthReportDetails2Page {...healthReportPageProps} pageNumber={31} />
            <HealthReportDetails3Page {...healthReportPageProps} pageNumber={32} />
            <HealthReportDetails4Page {...healthReportPageProps} pageNumber={33} />
        </>],
    ]);

    const _healthReportPages = new Map<number, Map<HealthReportPages, JSX.Element>>(
        [
            [
                parseFloat(HealthReportVersion.OldVersion), _defaultReportPages
            ],
            [
                parseFloat(HealthReportVersion.New), _defaultReportPagesNew
            ],
            [
                parseFloat(HealthReportVersion.SetRecommendation), authQuery.getType() == UserType.Patient ? _defaultReportPagesRecommendationForPatient : _defaultReportPagesRecommendation
            ]
        ]
    );

    const _healthReportMobilePages = new Map<number, Map<HealthReportPages, JSX.Element>>(
        [
            [
                parseFloat(HealthReportVersion.OldVersion), _defaultMobileReportPages
            ],
            [
                parseFloat(HealthReportVersion.New), _defaultMobileReportPagesNew
            ],
            [
                parseFloat(HealthReportVersion.SetRecommendation), authQuery.getType() == UserType.Patient ? _defaultMobileReportPagesRecommendationForPatient : _defaultMobileReportPagesRecommendation
            ]
        ]
    );

    const _recommendationNavigationSteps: Array<NavigationStep> = [
        { from: HealthReportPages.DietAndNutrition0, to: HealthReportPages.DietAndNutrition6, name: "Diet" },
        { from: HealthReportPages.ExerciseAndRecovery0, to: HealthReportPages.ExerciseAndRecovery7, name: "Exercise" },
        { from: HealthReportPages.Sleep0, to: HealthReportPages.Sleep1, name: "Sleep" },
        { from: HealthReportPages.Neurobehavioral0, to: HealthReportPages.Neurobehavioral1, name: "Neurobehavioral" },
        { from: HealthReportPages.Microbiome0, to: HealthReportPages.Microbiome2, name: "Microbiome" },
        { from: HealthReportPages.ChronicDisease0, to: HealthReportPages.ChronicDisease5, name: "Chronic Disease" },
        { from: HealthReportPages.Longevity0, to: HealthReportPages.Longevity2, name: "Longevity" },
        { from: HealthReportPages.Details5, to: HealthReportPages.Details5, name: "Labs" },
        { from: HealthReportPages.Appendix, to: HealthReportPages.Appendix, name: "Appendix" },
        { from: HealthReportPages.Details1, to: HealthReportPages.Details4, name: "Genomic Glossary" },
        { from: HealthReportPages.Recommendations, to: HealthReportPages.Recommendations, name: "Recommendations Summary" },
    ];

    const _defaultNavigationSteps: Array<NavigationStep> = [
        { from: HealthReportPages.DietAndNutrition0, to: HealthReportPages.DietAndNutrition6, name: "Diet" },
        { from: HealthReportPages.ExerciseAndRecovery0, to: HealthReportPages.ExerciseAndRecovery7, name: "Exercise" },
        { from: HealthReportPages.Sleep0, to: HealthReportPages.Sleep1, name: "Sleep" },
        { from: HealthReportPages.Neurobehavioral0, to: HealthReportPages.Neurobehavioral1, name: "Neurobehavioral" },
        { from: HealthReportPages.Microbiome0, to: HealthReportPages.Microbiome2, name: "Microbiome" },
        { from: HealthReportPages.ChronicDisease0, to: HealthReportPages.ChronicDisease5, name: "Chronic Disease" },
        { from: HealthReportPages.Longevity0, to: HealthReportPages.Longevity2, name: "Longevity" },
        { from: HealthReportPages.Details5, to: HealthReportPages.Details5, name: "Labs" },
        { from: HealthReportPages.Appendix, to: HealthReportPages.Appendix, name: "Appendix" },
        { from: HealthReportPages.Details1, to: HealthReportPages.Details4, name: "Genomic Glossary" },
    ];

    const _navigationSteps: Map<number, Array<NavigationStep>> = new Map<number, Array<NavigationStep>>([
        [
            parseFloat(HealthReportVersion.OldVersion), _defaultNavigationSteps
        ],
        [
            parseFloat(HealthReportVersion.New), _defaultNavigationSteps
        ],
        [
            parseFloat(HealthReportVersion.SetRecommendation), authQuery.getType() === UserType.Patient ? _defaultNavigationSteps : _recommendationNavigationSteps
        ],
    ]);

    const getNavigationSteps = (version: HealthReportVersion) => {
        const steps = _navigationSteps.get(parseFloat(version));

        return steps ?? _defaultNavigationSteps;
    }

    const getReportPagesSteps = (version: HealthReportVersion) => {
        const pages = _healthReportPages.get(parseFloat(version));
        return pages ?? _defaultReportPages;
    }

    const getMobileReportPagesSteps = (version: HealthReportVersion) => {
        const pages = _healthReportMobilePages.get(parseFloat(version));
        return pages ?? _defaultMobileReportPages;
    }

    const canGoNext = (): boolean => ((parseFloat(state.report?.status?.version) === parseFloat(HealthReportVersion.SetRecommendation)) && (authQuery.getType() !== UserType.Patient)) ? state.currentPage !== HealthReportPages.Recommendations : state.currentPage !== HealthReportPages.Details4;

    const canGoBack = (): boolean => state.currentPage !== HealthReportPages.StartPage;

    const reportPage = getReportPagesSteps(state.report?.status?.version).get(state.currentPage);

    const mobileReportPage = getMobileReportPagesSteps(state.report?.status?.version).get(state.currentPage);

    const navigationContext = {
        nextButton: "Next",
        backButton: "Back",
        canGoNext: canGoNext,
        canGoBack: canGoBack,
        goNext: () => goTo(increasePage(state.currentPage)),
        goBack: () => goTo(decreasePage(state.currentPage)),
        goTo: goTo
    } as PageSliderNavigationContext

    const increasePage = (currentPage: HealthReportPages) => {
        const allPages = Array.from(getReportPagesSteps(state.report?.status?.version).keys());

        return allPages[allPages.findIndex(x => x === currentPage) + 1];
    }

    const decreasePage = (currentPage: HealthReportPages) => {
        const allPages = Array.from(getReportPagesSteps(state.report?.status?.version).keys());

        return allPages[allPages.findIndex(x => x === currentPage) - 1];
    }

    /**
     * Load all ongoing cares
     * Manage subscriptions with auto-cleanup
     */
    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<HealthReportModel>(healthReportsQuery.report$, report => {
                setState(state => ({
                    ...state,
                    mode: isReadonly
                        ? HealthReportMode.ReadonlyMode
                        : report?.status?.status !== HealthReportStatus.Submitted
                            ? HealthReportMode.EditMode
                            : HealthReportMode.ReadonlyMode,
                    report,
                    recommendations: report?.recommendations?.map(el => {
                        return {
                            ...el,
                            isSelected: true,
                        }
                    }),
                }));
            }),
            onEmit<PracticeProfileModel>(profileQuery.practiceProfile$, practice => {
                if (practice) {
                    setState(state => ({
                        ...state,
                        practice: practice
                    }));
                }
            })
        ];

        const cb = () => setState(state => ({ ...state, isLoading: false }));

        const action = () => {
            if (authQuery.hasPermission(PermissionType.LocationAnalytics)) {
                profileService.getPracticeProfile().subscribe(cb, cb);
            } else {
                cb();
            }
        };

        if (patientId) {
            healthReportServices.getLast(patientId).subscribe(() => action(), cb);
        } else {
            patientHealthReportService.getLast().subscribe(() => action(), cb);
        }

        return () => {
            subscriptions.map(it => it.unsubscribe());
        };
    }, [patientId, isReadonly]);

    return [state, navigationContext, reportPage, getNavigationSteps(state.report?.status?.version), mobileReportPage];
}