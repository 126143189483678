import Axios from 'axios-observable';
import {snackService} from "../../common/snack/state";
import {authHeader} from "../../common/helpers/auth-header";
import {Observable} from "rxjs";
import {questionnaireStore, QuestionnaireStore} from "../stores/questionnaireStore";
import {
    QuestionnaireModel,
    QuestionnaireResultModel,
    QuestionnaireSubType,
    QuestionnaireType,
    SaveQuestionnaireResultModel
} from "../models/questionnaire.models";

/**
 * Provides methods for working with Questionnaire
 */
export class QuestionnaireService {
    private url = `${process.env.REACT_APP_API_URL}Questionnaire`;

    constructor(private store: QuestionnaireStore) {
    }

    public startByType(intakeId: string, type: QuestionnaireType): void {
        Axios.get(`${this.url}/start?intakeId=${intakeId}&type=${type}`)
            .pipe()
            .subscribe(
                (response) => {
                    this.store.update({targetResult: response.data});
                },
                error => {
                    snackService.commonErrorHandler(error);
                }
            );
    }
    
    public startById(id: number, appointmentId?: number): Observable<QuestionnaireResultModel> {
        let url = `${this.url}/${id}/start`

        if (appointmentId) {
            url += `?appointmentId=${appointmentId}`
        }

        return new Observable(observer => {
            Axios.get(url, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({targetResult: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public startByIdOnBehalf(id: number, patientId: number, appointmentId?: number): Observable<QuestionnaireResultModel> {
        let url = `${this.url}/${id}/startOnBehalf?patientId=${patientId}`

        if (appointmentId) {
            url += `&appointmentId=${appointmentId}`
        }

        return new Observable(observer => {
            Axios.get(url, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({targetResult: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public getMyAvailable() {
        return new Observable(observer => {
            Axios.get(`${this.url}/my/available`, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({availableQuestionnaires: response.data});

                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);

                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public getAvailable(patientId: number): Observable<any> {
        return new Observable(observer => {
            Axios.get(`${this.url}/available?patientId=${patientId}`, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({availableQuestionnaires: response.data});

                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);

                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public getAllMyResults(): Observable<QuestionnaireResultModel[]> {
        return new Observable(observer => {
            Axios.get(`${this.url}/my/results`, { headers: authHeader() })
            .pipe()
            .subscribe(
                (response) => {
                    this.store.update({questionnaireResults: response.data});

                    observer.next(response.data);
                    observer.complete();
                },
                error => {
                    snackService.commonErrorHandler(error);

                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }

    public getResults(patientId: number): Observable<QuestionnaireResultModel[]> {
        return new Observable(observer => {
            Axios.get(`${this.url}/results?patientId=${patientId}`, { headers: authHeader() })
            .pipe()
            .subscribe(
                (response) => {
                    this.store.update({questionnaireResults: response.data});

                    observer.next(response.data);
                    observer.complete();
                },
                error => {
                    snackService.commonErrorHandler(error);

                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }

    public getLatestResults(patientId: number, types: QuestionnaireType[]): Observable<QuestionnaireResultModel[]> {
        let typesQuery = '';
        types.forEach(type => typesQuery += `&types=${type}`);

        return new Observable(observer => {
            Axios.get(`${this.url}/results/latest?patientId=${patientId}${typesQuery}`, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public getLatestHealthFormsResults(patientId: Number): Observable<QuestionnaireResultModel[]> {
        return new Observable(observer => {
            Axios.get(`${this.url}/results/healthForms/latest?patientId=${patientId}`, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public getResultsByEmployee(patientId: number, resultId?: number): Observable<QuestionnaireResultModel[]> {
        return new Observable(observer => {
            Axios.get(`${this.url}/results/${resultId}?patientId=${patientId}`, { headers: authHeader() })
            .pipe()
            .subscribe(
                (response) => {
                    this.store.update({questionnaireResultsByEmployee: [response.data]});

                    observer.next(response.data);
                    observer.complete();
                },
                error => {
                    snackService.commonErrorHandler(error);

                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }

    public getResult(id: number): Observable<QuestionnaireResultModel> {
        return new Observable<QuestionnaireResultModel>((observer) => {
            Axios.get(`${this.url}/my/results/${id}`, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({targetResult: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public saveByIntakeId(intakeId: string, model: SaveQuestionnaireResultModel): Observable<QuestionnaireResultModel> {
        return new Observable<QuestionnaireResultModel>(observer => {
            Axios.post<QuestionnaireResultModel>(`${this.url}/results/anonymous?intakeId=${intakeId}`, model)
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({targetResult: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public submitByIntakeId(intakeId: string, model: SaveQuestionnaireResultModel): Observable<void> {
        return new Observable<void>((observer) => {
            Axios.post(`${this.url}/results/anonymous/submit?intakeId=${intakeId}`, model)
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({targetResult: response.data});
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public save(model: SaveQuestionnaireResultModel): Observable<QuestionnaireResultModel> {
        return new Observable<QuestionnaireResultModel>(observer => {
            Axios.post<QuestionnaireResultModel>(`${this.url}/my/results`, model, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.updateQuestionnaireResult(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public saveAsEmployee(model: SaveQuestionnaireResultModel): Observable<QuestionnaireResultModel> {
        return new Observable<QuestionnaireResultModel>(observer => {
            Axios.post<QuestionnaireResultModel>(`${this.url}/results`, model, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        snackService.success('Saved successfully!');
                        this.store.updateQuestionnaireResult(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getHealthForms(): Observable<any> {
        return new Observable(observer => {
            Axios.get(`${this.url}/my/availableHealthForms`, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({
                            healthForms: response.data.forms,
                            questionnaireBanner: response.data.banner
                        });

                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);

                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public getNotificationAvailable(): Observable<any> {
        return new Observable(observer => {
            Axios.get(`${this.url}/my/availableHealthForms/any`, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({
                            notificationMedicalHistory: response.data,
                        });

                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);

                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public getHealthFormsByPatientId(patientId: number): Observable<any> {
        return new Observable(observer => {
            Axios.get(`${this.url}/availableHealthForms?patientId=${patientId}`, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({healthForms: response.data.forms});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);

                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public submitAsPatient(model: SaveQuestionnaireResultModel): Observable<void> {
        return new Observable(observer => {
            Axios.post(`${this.url}/my/result/submit`, model, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({targetResult: response.data});
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public submitAsEmployee(model: SaveQuestionnaireResultModel): Observable<void> {
        return new Observable(observer => {
            Axios.post(`${this.url}/result/submit`, model, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({targetResult: response.data});
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public getFollowUpForms(appointmentIds: number[]): Observable<QuestionnaireResultModel[]> {
        return new Observable(observer => {
            Axios.get(`${this.url}/FollowUpForms/${appointmentIds.join(',')}`, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({questionnaireResultsByAppointments: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }
}

export const questionnaireService = new QuestionnaireService(questionnaireStore);