import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 2,
      backgroundColor: colors.white,
      boxShadow: "0px 7px 60px rgba(168, 173, 198, 0.12)",
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      maxHeight: "calc(100vh - 156px) !important",
      minHeight: 625,
      [theme.breakpoints.down("xs")]: {
        minHeight: "calc(100vh - 156px) !important",
      },
    },
    header: {
      padding: "16px 24px",
      [theme.breakpoints.down("xs")]: {
        padding: 16,
        paddingRight: 0,
        borderBottom: `1px solid ${colors.separators}`,
      },
    },
    title: {
      display: "flex",
      alignItems: "center",
      color: colors.black,
      fontWeight: 500,
      fontSize: 20,
      [theme.breakpoints.down("xs")]: { fontSize: 18 },
    },
    divider: {
      backgroundColor: colors.stroke,
    },
    thread: {
      flex: 1,
      minHeight: 200,
      height: "calc(100vh - 398px)",
      maxHeight: "calc(100vh - 398px)",
      overflow: "auto",
    },
    sendMessage: {
      flexShrink: 0,
    },
    employeeType: {
      fontFamily: "Be Vietnam Pro",
      fontSize: 12,
      color: colors.gray1,
    },
    employeeNames: {
      fontFamily: "Be Vietnam Pro",
      fontSize: 16,
      fontWeight: 500,
      color: colors.black,
    },
    awayNotification: {
      padding: 5,
      display: "flex",
      alignItems: "center",
      borderTop: "1px solid",
      borderBottom: "1px solid",
      color: colors.average1,
      borderColor: colors.average1,
      background: `${colors.average2}0D`,
    },
    notification: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontFamily: "Be Vietnam Pro",
      fontSize: 14,
      borderRadius: 8,
      background: colors.navy100,
      color: colors.secondary,
    },

    notificationIcon: {
      fontSize: 24,
      cursor: 'pointer',
      [theme.breakpoints.down("xs")]: {
        fontSize: 16,
        color: colors.gray2,
        opacity: 0.4,
        "&:hover": {
          color: colors.gray1,
        },
      },
    },
    notificationToolTip: {
      fontFamily: "Be Vietnam Pro",
      fontSize: 14,
      margin: "5px",
    },
    notificationList: {
      listStyle: "inside",
    },
    notificationToolTipText: {
      margin: "5px",
    },
    popper: {
      left: "-5px !important",
    },
    tooltip: {
      width: '225px',
      maxWidth: '225px',
      padding: 8,
      background: colors.lightBlack,
    },
    arrow: {
      color: colors.lightBlack,
    },
    tooltipPlacement: {
      margin: '12px 0',
    }
  })
);
