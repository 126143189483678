import { Box, Button, CircularProgress, Grid, IconButton, useTheme, useMediaQuery } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import GetAppIcon from '@material-ui/icons/GetApp';
import moment from 'moment';
import React from 'react';
import { AuthenticatedLayoutComponent } from "../../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent";
import { PageTitleComponent } from '../../../../../components/pageTitleComponent/PageTitleComponent';
import { TitlesService } from '../../../../../constants/title.constants';
import { MenuItemTypes } from "../../../../../models/menu.models";
import WildHealthLinearProgress from "../../../../common/components/WildHealthLinearProgress";
import { MedicationsSupplementsComponent } from '../../../components/medicationsSupplements/MedicationsSupplementsComponent';
import { NoteQuestionnaireResult } from "../../../components/noteQuestionnaireResult/NoteQuestionnaireResult";
import { NoteSignatureComponent } from "../../../components/noteSignature/NoteSignatureComponent";
import { getInitQuestionnaires, NoteRecommendation } from "../../../constants/initialConsult.constants";
import { useFacade } from './patientInitialConsultPageOld.hooks';
import { colors } from "../../../../common/constants/colors";
import clsx from 'clsx';
import commonUseStyles from "../../../../common/styles/common.styles";
import { WildHealthButton } from '../../../../common/components/wildHealthButton/WildHealthButton';
import { NoteRecommendationsComponentOld } from '../../../components/noteRecommendations/Old/NoteRecommendationsComponentOld';


interface PatientInitialConsultPageOldProps {
    noteId: number;
}

export const PatientInitialConsultPageOld: React.FC<PatientInitialConsultPageOldProps> = (props: PatientInitialConsultPageOldProps) => {
    const noteId = props.noteId;

    const [
        {
            note,
            content,
            medications,
            supplements,
            isPdfDownloading
        },
        handleGoBack,
        handleDownloadPdf
    ] = useFacade(noteId);

    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const isLoading = !content || !note;

    const renderTitle = () => {
        return `${note.employee.firstName} - ${moment(note.visitDate).format("ll")}`;
    }

    const renderMedicationsSupplements = () => {
        return <MedicationsSupplementsComponent medications={medications} supplements={supplements} />
    }

    const renderRecommendations = () => {
        const recommendations = JSON.parse(content?.content) as NoteRecommendation[];
        return (
            <NoteRecommendationsComponentOld
                recommendations={recommendations && recommendations.length ? recommendations.filter(x => x.isSelected) : []}
            />
        );
    }

    const renderQuestionnaire = () => {
        return <NoteQuestionnaireResult logs={content.logs} questionnaire={getInitQuestionnaires()} />
    }

    const displayDownloadPdfButton = () => {
        if (isPdfDownloading === true) {
            return (
                <Box width="70px" display="flex" justifyContent="center">
                    <CircularProgress size={24} style={{ color: colors.main }} />
                </Box>
            )
        }

        return (
            <Button id="patient-initial-consult-download-pdf" onClick={() => {
                handleDownloadPdf(note, content, medications, supplements);
            }}>
                <Box display="flex" alignItems="center" mt={1}>
                    <Box>
                        <GetAppIcon />
                    </Box>
                    <Box mt={-1}>
                        PDF
                    </Box>
                </Box>
            </Button>
        );
    }

    const renderNote = () => {
        return (
            <>
                {isSmallScreen && <Box mb={3} className={clsx(commonClasses.size20, commonClasses.textMedium, commonClasses.colorBlack)}>{note.name}</Box>}
                <Grid container spacing={5}>
                    <Grid item>
                        {renderRecommendations()}
                        {renderMedicationsSupplements()}
                        {renderQuestionnaire()}
                    </Grid>
                </Grid>

                <Box py={isSmallScreen ? 2 : 5}>
                    <NoteSignatureComponent note={note} noteId={note.id} />
                </Box>

            </>
        )
    }

    const pageName = (
        <PageTitleComponent
            startAdornment={
                <IconButton id="patient-initial-consult-go-back" onClick={() => handleGoBack()}>
                    <ArrowBackIcon />
                </IconButton>
            }
            title={
                isLoading
                    ? 'Loading . . .'
                    : renderTitle()
            }
            endAdornment={
                displayDownloadPdfButton()
            }
        />
    );

    return (
        <AuthenticatedLayoutComponent
            title={TitlesService.noteDetailTitle}
            pageName={pageName}
            selectedMenuItem={MenuItemTypes.Notes}
        >
            <Box className={isSmallScreen && commonClasses.bgMain}>
                {isSmallScreen && (
                    <>
                        <Box py={2} display="flex" alignItems="center">
                            <IconButton id="patient-initial-consult-go-back" onClick={() => handleGoBack()}>
                                <ArrowBackIcon />
                            </IconButton>
                            <Box className={clsx(commonClasses.size14, commonClasses.textRegular, commonClasses.colorGray1)}>
                                Back to Notes
                            </Box>
                        </Box>
                        <Box position="fixed" right={16} bottom={40} zIndex={1202}>
                            <WildHealthButton id="download-pdf" width="48px" loading={isPdfDownloading} style={{ borderRadius: "50%", minWidth: 48, height: 48 }} onClick={() => handleDownloadPdf(note, content, medications, supplements)}>
                                <GetAppIcon className={commonClasses.colorWhite} />
                            </WildHealthButton>
                        </Box>
                    </>
                )}
                {
                    isLoading ?
                        <WildHealthLinearProgress /> :
                        <Box p={isSmallScreen ? 2 : 5} pb={4} className={isSmallScreen && commonClasses.bgWhiteMain}>
                            {renderNote()}
                        </Box>
                }
            </Box>
        </AuthenticatedLayoutComponent>
    )
};