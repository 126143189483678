import { useEffect, useState } from 'react';
import { Subscription } from "recompose";
import { onEmit } from "../../../common/helpers/on-emit";
import { NotesGroupModel } from '../../models/notes.models';
import { notesQuery } from '../../stores/notes';
import {notesService} from "../../services/notes.service";

interface PatientNotesPageState {
    groups: NotesGroupModel[];
    isLoading: boolean;
}

export function useFacade(): [PatientNotesPageState] {
    const [state, setState] = useState({
        groups: [],
        isLoading: true,
    } as PatientNotesPageState);

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<NotesGroupModel[]>(notesQuery.notesGroups$, groups => {
                setState(state => ({...state, groups}));
            })
        ];

        const cb = () => setState(state => ({...state, isLoading: false}));

        notesService.getByPatient().subscribe(cb, cb);

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [state];
}