import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Subscription } from "recompose";
import { navigationService } from "../../../../services/navigation.service";
import { onEmit } from "../../../common/helpers/on-emit";
import { GoalModel, goalsFiltersStateTrackNames, GoalsFilterState } from "../../../notes/models/notes.models";
import { goalsService } from "../../../notes/services/goals.service";
import { notesQuery, notesStore } from "../../../notes/stores/notes";
import { PlatformName, Track } from "../../../tracking/tracker";


interface HealthPlanComponentState {
    isLoading: boolean;
    goals: GoalModel[];
    topGoals: GoalModel[];
    filterState: GoalsFilterState;
}

export function useFacade(patientId?: number): [
    HealthPlanComponentState,
    (filterState: GoalsFilterState) => void,
    (goal: GoalModel) => void,
] {
    const [state, setState] = useState({
        isLoading: true,
        filterState: GoalsFilterState.TopGoals,
        goals: [],
        topGoals: [],
    } as HealthPlanComponentState);
    const history = useHistory();

    const handleFilterStateChanges = (filterState: GoalsFilterState) => {
        if (!patientId) {
            navigationService.toMyHealthPlan(history, filterState)
        } else {
            notesStore.setGoalsTab(filterState)
        }
    }

    const handleOpenGoal = (goal: GoalModel) => {
        goalsService.openView(goal).subscribe();
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<GoalModel[]>(
                notesQuery.currentGoals$,
                (goals) => {
                    const topGoals = goals.filter(x => x.isTopGoal)
                    topGoals.length ? handleFilterStateChanges(GoalsFilterState.TopGoals) : handleFilterStateChanges(GoalsFilterState.AllGoals)
                    setState((state) => ({
                        ...state,
                        goals: goals.filter(x => !x.isTopGoal),
                        topGoals: topGoals,
                    }));
                }
            ),
            onEmit<GoalsFilterState>(
                notesQuery.goalsTab$,
                (goalsTab) => {
                    
                    Track("health_plan_click", {
                        type: goalsFiltersStateTrackNames[goalsTab],
                        platform: PlatformName()
                    })

                    setState((state) => ({
                        ...state,
                        filterState: goalsTab,
                    }));
                }
            ),
        ];

        const cb = () => setState((state) => ({ ...state, isLoading: false }));

        if (patientId) {
            goalsService.getCurrentGoalsByEmployee(patientId).subscribe(cb, cb);
        } else {
            goalsService.getCurrentGoalsByPatient().subscribe(cb, cb);
        }

        return () => {
            subscriptions.map((it) => it.unsubscribe());
        };
    }, [patientId]);

    return [state, handleFilterStateChanges, handleOpenGoal];
}