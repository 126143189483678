import React, {useState} from "react";
import {Edit} from '@material-ui/icons';
import {WildHealthRadio} from "../../../common/components/WildHealthRadio";
import {Box, IconButton, TextField} from "@material-ui/core";
import commonUseStyles from "../../../common/styles/common.styles";
import moment from "moment";
import {WildHealthButton} from "../../../common/components/wildHealthButton/WildHealthButton";

enum StateMode {
    View = 1,
    Edit = 2
}

enum DateMode {
    Weeks = 1,
    Months = 2
}

interface GoalsCompletionDateComponentState {
    stateMode: StateMode
    dateMode: DateMode;
    value: number;
}

interface GoalsCompletionDateComponentProps {
    visitDate: Date;
    completionDate: Date | null;
    handleChanges: (date: Date) => void;
}

export const GoalsCompletionDateComponent: React.FC<GoalsCompletionDateComponentProps> = (props: GoalsCompletionDateComponentProps) => {
    const {
        visitDate,
        completionDate,
        handleChanges
    } = props;

    const commonClasses = commonUseStyles();

    const [state, setState] = useState({
        stateMode: StateMode.View,
        dateMode: DateMode.Weeks,
        value: 0
    } as GoalsCompletionDateComponentState);

    const handleValueChanges = (value: number) => {
        if (value < 0) {
            return;
        }

        setState(state => ({
            ...state,
            value: Number(value)
        }));
    }

    const handleStateModChanges = (mode: StateMode) => {
        setState(state => ({
            ...state,
            stateMode: mode
        }));
    }

    const handleDateModChanges = (mode: DateMode) => {
        setState(state => ({
            ...state,
            dateMode: mode
        }));
    }

    const handleSubmit = () => {
        const mewCompletionDate = state.dateMode === DateMode.Weeks
            ? moment(visitDate).add(state.value, 'weeks').toDate()
            : moment(visitDate).add(state.value, 'months').toDate();

        handleChanges(mewCompletionDate);

        setState(state => ({
            ...state,
            value: 0,
            dateMode: DateMode.Months,
            stateMode: StateMode.View
        }))
    }

    const renderViewComponent = () => {
        return (
            <Box display="flex" alignItems="center">
                <Box>
                    {
                        completionDate !== null
                            ? moment(completionDate).format('L') + ' - '
                            : 'No completion date'
                    }
                </Box>
                {
                    completionDate &&
                    <Box className={commonClasses.subtitle} ml={1}>
                        {
                            moment(completionDate).startOf('day').fromNow()
                        }
                    </Box>
                }
                <Box ml={2}>
                    <IconButton color="primary" onClick={() => handleStateModChanges(StateMode.Edit)}>
                        <Edit />
                    </IconButton>
                </Box>
            </Box>
        )
    }

    const renderEditComponent = () => {
        return (
            <Box>
                <Box display="flex">
                    <Box width="75px">
                        <TextField
                            id='enter-goal-completion-date'
                            fullWidth
                            type='number'
                            size="small"
                            variant="outlined"
                            value={Number(state.value).toString()}
                            InputProps={{ className: 'input' }}
                            onChange={(e) => handleValueChanges(Number(e.target.value))}
                        />
                    </Box>
                    <Box display="flex" alignItems="center" mx={2}>
                        <Box>
                            <WildHealthRadio
                                checked={state.dateMode === DateMode.Weeks}
                                onChange={() => handleDateModChanges(DateMode.Weeks)}
                            />
                        </Box>
                        <Box>
                            <span>Weeks</span>
                        </Box>
                    </Box>
                    <Box display="flex" alignItems="center">
                        <Box>
                            <WildHealthRadio
                                checked={state.dateMode === DateMode.Months}
                                onChange={() => handleDateModChanges(DateMode.Months)}
                            />
                        </Box>
                        <Box>
                            <span>Months</span>
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" mt={2}>
                    <Box>
                        <WildHealthButton
                            width={111}
                            id='cancel-note-completion-date'
                            onClick={() => { handleStateModChanges(StateMode.View) }}
                            color='tertiary'
                        >
                            Cancel
                        </WildHealthButton>
                    </Box>
                    <Box ml={2}>
                        <WildHealthButton
                            width={150}
                            id='save-note-completion-date'
                            style={{ whiteSpace: 'nowrap' }}
                            onClick={() => handleSubmit()}
                        >
                            <span>Save Changes</span>
                        </WildHealthButton>
                    </Box>
                </Box>
            </Box>
        )
    }

    return (
        <>
            {
                state.stateMode === StateMode.View
                    ? renderViewComponent()
                    : renderEditComponent()
            }
        </>
    );
}