import React from 'react';
import {useLocation} from "react-router-dom";
import { useHistory } from 'react-router';
import { Box, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import InfoIcon from '@material-ui/icons/Info';
import clsx from 'clsx';
import { useFacade } from "./managePatientProfilePage.hooks";
import { useStyles } from './managePatientProfilePage.styles';
import { AuthenticatedLayoutComponent } from '../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { PageTitleComponent } from '../../../../components/pageTitleComponent/PageTitleComponent';
import { TitlesService } from '../../../../constants/title.constants';
import { ManagePatientProfileComponent } from "../../components/managePatientProfileComponent/ManagePatientProfileComponent";
import { PatientInfoTooltipComponent } from '../../components/patientInfoTooltipComponent/PatientInfoTooltipComponent';
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { navigationService } from '../../../../services/navigation.service';
import { patientProfileStore } from '../../stores/patientProfileStore';

export const ManagePatientProfilePage: React.FC = (props: any) => {
    const patientId = Number(props.match.params.patientId);
    const tab = new URLSearchParams(props.location.search).get('tab');
    const resultId = new URLSearchParams(props.location.search).get('resultId');

    const [
        {
            isLoading,
            patient,
        },
        displayPatientName,
    ] = useFacade(patientId);
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();

    if (tab) {
        patientProfileStore.setTab(tab);
    }

    const pageName = (
        <PageTitleComponent
            patientId={patientId}
            startAdornment={
                <IconButton disabled={!location.key} id={`page-title-go-back-${displayPatientName(patient)}`} onClick={() => navigationService.toManagePatients(history)} size='medium'>
                    <ArrowBackIcon />
                </IconButton>
            }
            title={displayPatientName(patient)}
            endAdornment={
                patient ?
                    <Box position='relative'>
                        <InfoIcon className={clsx(classes.icon, "wh-tw-text-gray2")} fontSize='small' />
                        <PatientInfoTooltipComponent patientId={patientId} />
                    </Box>
                    : <></>
            }
        />
    );

    return (
        <AuthenticatedLayoutComponent
            hideBackground
            title={TitlesService.patientProfileTitle}
            pageName={pageName}
        >
            {isLoading ? (
                <WildHealthLinearProgress />
            ) : (
                <ManagePatientProfileComponent
                    patientId={patientId}
                    patientIntakeId={patient.intakeId}
                    pageName={pageName}
                    resultId={+resultId} />
            )}
        </AuthenticatedLayoutComponent>
    );
}
