import { useEffect, useState } from 'react';
import { Subscription } from 'recompose';
import { onEmit } from '../../../common/helpers/on-emit';
import { IErrorState } from '../../../common/validation/error-state';
import { addEmployerProfileValidator } from "./addEmployerProfile.validator";
import { getLastObject } from '../../../common/helpers/get-last-object';
import { employerProductsService } from '../../services/employerProducts.service';
import { EmployerProductModel } from '../../../payment/models/employerProduct.model';
import { employerProductsQuery } from '../../stores/employerProductsStore';
import { CreateEmployerProductModel } from '../../models/employerProduct.model';

interface ManageEmployersProfileComponentState extends IErrorState {
    isLoading: boolean;
    isAdding: boolean;
    isAddOpen: boolean;
    search: string;
    employers: EmployerProductModel[];
    originalEmployers: EmployerProductModel[];
    request: CreateEmployerProductModel;
}

const initEmployerProductModel: CreateEmployerProductModel = {
    name: '',
    key: '',
    description: '',
    bannerCdnUrl: '',
    inlineSupportedPaymentPriceIds: '',
    discounts: [],
    inclusions: []
};

export function useFacade(): [
    ManageEmployersProfileComponentState,
    (query: string) => void,
    (status: boolean) => void,
    (field: string, value: any) => void,
    () => void
] {

    const [state, setState] = useState({
        isLoading: true,
        isAdding: false,
        isAddOpen: false,
        search: '',
        employers: [],
        originalEmployers: [],
        request: null,
        errors: {},
    } as ManageEmployersProfileComponentState);

    const setEmployers = (employers: EmployerProductModel[]) => {
        setState((state) => {
            return {
                ...state,
                isLoading: false,
                employers: employers,
                totalCount: employers.length,
            }
        });
    }

    const handleSearchSubmit = (query: string) => {
        const searchQuery = query ?? '';
        setState(state => ({
            ...state,
            isLoading: true
        }));

        const trimedSearch = decodeURIComponent(searchQuery).trim().toLowerCase();
        const arrayTrimSearchKey = trimedSearch.split(' ');

        const filteredEmployers = state.originalEmployers?.filter(
            (e) =>
                arrayTrimSearchKey.find((item) =>
                    e.name?.toLowerCase().includes(item)
                ) !== undefined ||
                arrayTrimSearchKey.find((item) =>
                    e.key?.toLowerCase().includes(item)
                ) !== undefined
        );
        setEmployers(filteredEmployers)
    }

    const handleToggle = (status: boolean) => {
        if (status) {
            setState(state => ({ ...state, request: Object.assign({}, initEmployerProductModel), isAddOpen: status }));
        } else {
            setState(state => ({ ...state, request: null, isAddOpen: false, errors: {} }));
        }
    }

    const handleChanges = (field: string, value: any) => {
        addEmployerProfileValidator.validateAndSetState(state, setState, field, value);

        const params = state.request;
        const keys = field.split(".");
        const key = keys.pop();
        const lastObject = getLastObject(keys, params);
        lastObject[key] = value;

        setState(state => ({...state, request: Object.assign({}, params)}));
    }

    const handleCreate = () => {
        addEmployerProfileValidator.validateObjectAndSetState(state, setState, state.request);

        if (!addEmployerProfileValidator.stateIsValid(state)) {
            return
        }
        setState(state => ({...state, isAdding: true}));

        const cb = () => setState(state => ({...state, isAdding: false, isAddOpen: false, request: null}))

        employerProductsService.createEmployerProduct(state.request).subscribe(cb, cb)
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<EmployerProductModel[]>(employerProductsQuery.employerProducts$, employerProducts => {
                const employers = employerProducts.filter(e => !e.isDefault)
                setState(state => ({
                    ...state,
                    employers: employers,
                    originalEmployers: employers,
                }))
            }),
        ];

        const cb = () => setState(state => ({ ...state, isLoading: false }))

        employerProductsService.getAllEmployerProducts().subscribe(cb, cb);

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, []);

    return [
        state,
        handleSearchSubmit,
        handleToggle,
        handleChanges,
        handleCreate
    ];
}