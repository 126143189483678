import React from 'react';
import clsx from 'clsx';
import {
  Box,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { useFacade } from './createBannerComponent.hooks';
import { useStyles } from "./createBannerComponent.styles";
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import commonUseStyles from '../../../common/styles/common.styles';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import { BannerDisplayType, bannerTypesNames } from '../../../../models/banner.model';

export const CreateBannerComponent: React.FC = () => {
  const [
    {
      isLoading,
      isSubmitted,
      params,
      errors
    },
    handleChanges,
    handleCancel,
    handleCreate,
  ] = useFacade();

  const commonClasses = commonUseStyles();
  const classes = useStyles();

  if (isLoading) {
    return <WildHealthLinearProgress />
  }

  return (
    <Box px="30px" pt="22px" pb="30px" className={commonClasses.bgWhiteMain}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box className={clsx(commonClasses.size20, commonClasses.textMedium, commonClasses.colorBlack)}>Create a banner</Box>
        <Box display="flex" alignItems="center">
          <Box width="132px" mr="20px">
            <WildHealthButton
              id="cancel-button"
              disabled={isSubmitted || isLoading}
              onClick={() => handleCancel()}
              color="secondary"
              fullWidth
              style={{ height: 48 }}
            >
              <Box className={clsx(commonClasses.size16, commonClasses.textMedium)}>Cancel</Box>
            </WildHealthButton>
          </Box>
          <Box width="132px">
            <WildHealthButton
              id="create-banner-button"
              disabled={isSubmitted}
              loading={isSubmitted || isLoading}
              onClick={() => handleCreate()}
              fullWidth
              style={{ height: 48 }}
            >
              <Box className={clsx(commonClasses.size16, commonClasses.textMedium)}>Create</Box>
            </WildHealthButton>
          </Box>
        </Box>
      </Box>
      <Box width={1} mt={2}>
        <Box display="flex">
          <Box width={0.6} pr="20px">
            <Box className={classes.label} mb={1}>Name *</Box>
            <TextField
              fullWidth
              size="small"
              required
              InputProps={{className: 'input', classes}}
              id="name"
              placeholder=""
              value={params.name}
              variant="outlined"
              disabled={isSubmitted || isLoading}
              error={!!errors['name']}
              helperText={errors['name']}
              onChange={(v) => handleChanges(v.target.id, v.target.value)}
            />
          </Box>
          <Box width={0.4}>
            <FormControl
              variant="outlined"
              color='primary'
              required
              error={!!errors[`type`]}
              size="small"
              disabled={isSubmitted || isLoading}
              classes={{ root: classes.root }}
              fullWidth>
              <Box className={classes.label} mb={1}>Banner Display Type </Box>
              <Select
                style={{ background: '#FFF' }}
                classes={{ root: classes.root }}
                value={params.type}
                error={!!errors[`type`]}
                inputProps={{
                  name: 'Banner Display Type',
                  id: 'type-label',
                  classes,
                }}
                renderValue={() => {
                  if (params.type === null) {
                    return <Box className={commonClasses.colorMediumGray}>Select</Box>;
                  }

                  return bannerTypesNames.get(params.type);
                }}
                displayEmpty
                onChange={(v) => handleChanges("type", v.target.value)}
              >
                <MenuItem value={BannerDisplayType.All}>All</MenuItem>
                <MenuItem value={BannerDisplayType.Patient}>Patient</MenuItem>
                <MenuItem value={BannerDisplayType.Employee}>Employee</MenuItem>
              </Select>
              <FormHelperText>{errors[`type`]}</FormHelperText>
            </FormControl>
          </Box>
        </Box>
        <Box mt={2}>
          <Box className={classes.label} mb={1}>Copy*</Box>
          <TextField
            id={`copy`}
            fullWidth
            required
            multiline
            rows={5}
            size="small"
            InputProps={{ className: 'input', classes }}
            variant="outlined"
            value={params.copy}
            error={!!errors['copy']}
            helperText={errors['copy']}
            disabled={isSubmitted || isLoading}
            onChange={(event) => handleChanges(event.target.id, event.target.value)}
          />
        </Box>
      </Box>
    </Box>
  )
}