import { makeStyles } from "@material-ui/core";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme) => ({
    root: {
        borderColor: colors.gray4,
        borderRadius: 2,
        '& label.Mui-focused': {
            color: colors.main,
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: colors.main,
            },
            borderColor: colors.gray4,
            borderRadius: 2,
        },
    },
    dialogContent: {
        padding: '30px 40px',
        width: 540,
    },
    dialogTitleCloseAction: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));
