import {ChangeEventHandler, useState} from 'react';
import { labOrdersService } from "../../services/labOrders.service";

export interface CancelLabOrderDialogueState { 
    isOpen: boolean;
    orderId: number;
    reason: string;
    canCancel: boolean;
    handleCancel: Function;
}

export function useFacade(): [CancelLabOrderDialogueState, ChangeEventHandler, Function, Function]{
    const [state, setState] = useState({
        isOpen: false,
        orderId: 0,
        reason: null,
        canCancel: false
    } as CancelLabOrderDialogueState);

    const setDefaultState = () => {
        setState({
            ...state,
            isOpen: false,
            orderId: 0,
            reason: null,
            canCancel: false,
            handleCancel: null
        });
    }

    const handleUpdateReason = (e: any) => {
        const reason = e.target.value;
        const canCancel = e.target.value.length > 0;
        setState({
            ...state,
            reason: reason,
            canCancel: canCancel
        })
    }

    const handleClose = () => {
        setDefaultState();
    }

    const handleSubmitCancel = () => {
        state.handleCancel(state.orderId, state.reason);
        setDefaultState();
    }

    labOrdersService.onCancel.subscribe((callbackModel) => {
        setState({
            ...state,
            isOpen: true,
            handleCancel: callbackModel.callback,
            orderId: callbackModel.data
        });
    })

    return [state, handleUpdateReason, handleClose, handleSubmitCancel]
}