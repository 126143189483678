import {
    Box, IconButton, Dialog, DialogActions, DialogContent, Typography,
    Button, useMediaQuery, useTheme,
} from "@material-ui/core";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import clsx from 'clsx';
import React from 'react';
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import Cancel from "@img/icons/cancel.svg";
import commonUseStyles from '../../../common/styles/common.styles';
import {useStyles} from "./recommendFullscriptSupplementsComponent.styles";
import {useFacade} from "./recommendFullscriptSupplementsComponent.hooks";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import {FullscriptSupplementModel} from "../../models/fullscriptSupplementModels";

export interface RecommendFullscriptSupplementsComponentProps {
    open: boolean;
    handleClose: () => void;
    onOrdered?: (supplements: FullscriptSupplementModel[]) => void;
    patientId?: number;
}

export const RecommendFullscriptSupplementsComponent: React.FC<RecommendFullscriptSupplementsComponentProps> = (props: RecommendFullscriptSupplementsComponentProps) => {
    const classes = useStyles();
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const {
        open,
        handleClose,
        patientId
    } = props;

    const [
        {
            isLoading
        }
    ] = useFacade(patientId, open, props.onOrdered);

    return (
        <>
            <Dialog
                maxWidth="md"
                fullWidth
                fullScreen={isSmallScreen}
                className={classes.dialog}
                onClose={() => handleClose()}
                open={open}
            >
                {
                    !isSmallScreen &&
                    <MuiDialogTitle
                        className={classes.dialogTitle}
                        disableTypography
                    >
                        <Typography variant="h6">Recommend Fullscript Supplements</Typography>
                        <Box pl={1} pr={1}>
                            <IconButton id="patient-supplements-update-close" onClick={() => {
                                handleClose()
                            }} >
                                <img src={Cancel} alt='Cancel' />
                            </IconButton>
                        </Box>

                    </MuiDialogTitle>
                }
                <DialogContent className={classes.dialogContent}>
                    {
                        isSmallScreen &&
                        <Box py={1} display="flex" justifyContent="space-between">
                            <Button onClick={() => handleClose()}>
                                <span className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorGray1, commonClasses.capitalize)}>
                                    Cancel
                                </span>
                            </Button>
                        </Box>
                    }
                    <>
                        {
                            isLoading
                                ? <WildHealthLinearProgress/>
                                : <Box width="100%" height="100%" id="treatment-plan-container">

                                </Box>
                        }
                    </>
                </DialogContent>
                {
                    !isSmallScreen &&
                    <DialogActions className={classes.action}>
                        <WildHealthButton
                            id="patient-supplements-update-cancel"
                            onClick={() => { handleClose() }}
                            color="tertiary"
                            size='large'
                        >
                            Cancel
                        </WildHealthButton>
                    </DialogActions>
                }
            </Dialog>
        </>
    );
}
