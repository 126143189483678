import {IconButton, TextField} from "@material-ui/core";
import {createStyles, makeStyles, Theme, withStyles} from "@material-ui/core/styles";
import {colors} from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            position: "relative",
            justifyContent: "space-between",
        },
        message: {
            flex: 1,
        },
        icon: {
            fill: "currentColor",
        },
        sendIcon: {
            fill: colors.main,
            width: 24,
            height: 24
        },
        imageAttachment: {
            backgroundRepeat: "no-repeat",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 2,
            backgroundSize: 44,
            height: 44,
            width: 44,
            "&:hover": {
                "& $removeAttachment": {
                    visibility: "visible",
                },
            },
        },
        attachment: {
            border: `1px solid ${colors.stroke}`,
            borderRadius: 2,
            background: colors.gray8,
            display: "flex",
            alignItems: "center",
            maxWidth: 140,
            height: 44,

            "&:hover": {
                "& $removeAttachment": {
                    visibility: "visible",
                },
            },
        },
        attachmentIcon: {
            color: colors.gray2,
        },
        attachmentTitle: {
            fontSize: 14,
            fontWeight: 500,
            color: colors.black,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        removeAttachment: {
            visibility: "hidden",
        },
        disabledMessage: {
            position: "absolute",
            zIndex: 1,
            display: "flex",
            width: "100%",
            height: "100%",
            opacity: 0,
        },
        compactMessageInput: {
            borderRadius: '17px !important',
            padding: '7px',
            paddingRight: '14px',
            background: colors.separators,
            border: 'none !important',
        },
        compactMessageInputAdornment: {
            width: '15px !important',
            padding: '0px !important',
            margin: '0px !important'
        }
    })
);

export const InputField = withStyles(() => ({
    root: {
        "&:focus": {
            outline: "none !important",
        },
        "& label.Mui-focused": {
            outline: "none !important",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "transparent",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "transparent",
            },
            "&:hover fieldset": {
                borderColor: "transparent",
            },
            "&.Mui-focused fieldset": {
                outline: "none !important",
                borderColor: "transparent",
            },
        },
    },
}))(TextField);

export const ActionButton = withStyles((theme: Theme) => ({
    root: {
        fill: "currentColor",
        color: colors.main,
        "&:hover": {
            color: `#${colors.main}CC`,
        },
        "&:disabled": {
            color: colors.gray2,
        },
    },
}))(IconButton);
