import { Button, Theme, withStyles } from "@material-ui/core";

export const DiscardButton = withStyles((theme: Theme) => ({
    root: {
        color: '#7C8A9D',
        backgroundColor: '#EFF1F4',
        border: '1px solid #EFF1F4',
        textTransform: 'none',
        borderRadius: 2,
        '&:hover': {
            backgroundColor: '#EFF1F499',
            border: '1px solid #EFF1F499',
        },
        '&:disabled': {
            color: '#AFBACA',
            backgroundColor: '#ECEEEF',
            border: '1px solid #ECEEEF',
        },
    },
}))(Button);
