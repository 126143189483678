import { useEffect, useState } from 'react';
import { TutorialDialogProps } from './TutorialDialog';

export interface TutorialModel {
    id: number,
    featuredImage: JSX.Element,
    title: string,
    content: string,
}

interface TutorialDialogState {
    currentStep: TutorialModel;
}

export function useFacade(props: TutorialDialogProps): [
    TutorialDialogState,
  () => void,
  () => void,
  () => void
] {
    const [state, setState] = useState({
        currentStep: null
    } as TutorialDialogState);

    const handleGoNext = () => {
        const currentIndex = props.tutorialContent.findIndex(c => c.id === state.currentStep.id);

        if(currentIndex + 1 === props.tutorialContent.length) {
            return;
        }

        setState({...state, currentStep: props.tutorialContent[currentIndex + 1]})
    }

    const handleGoBack = () => {
        const currentIndex = props.tutorialContent.findIndex(c => c.id === state.currentStep.id);

        if(currentIndex === 0) {
            return;
        }

        setState({...state, currentStep: props.tutorialContent[currentIndex - 1]})
    }

    const handleSkip = () => {
        setState({...state, currentStep: props.tutorialContent[props.tutorialContent.length - 1]})
    }

    const handleClose = () => {
        setState(state => ({
          ...state,
          currentStep: null
        }))
        props.handleClose()
    }

    useEffect(() => {
        if (props.opened && props.tutorialContent.length) {
            setState(state => ({
              ...state,
              currentStep: props.tutorialContent[0]
            }))
        }
    }, [props.opened, props.tutorialContent]);

    return [
        state,
        handleGoNext,
        handleSkip,
        handleClose
    ];
}