import { GeneralValidator } from "../../../common/validation/general-validator";
import {
  notWhitespaceValidationRule,
  requiredValidationRule,
  notNegativeValidationRule
} from "../../../common/validation/general-validation-rules";

export const modifyBannerValidator = new GeneralValidator({
  'name': [notWhitespaceValidationRule(), requiredValidationRule()],
  'copy': [notWhitespaceValidationRule(), requiredValidationRule()],
  'type': [requiredValidationRule()],
  'isActive': [requiredValidationRule()],
  'priority': [notNegativeValidationRule("Priority can not be negative"), requiredValidationRule()]
});