import React, { useEffect, useState } from 'react';
import { Subscription } from "rxjs";
import { dnaOrdersService } from '../../services/dnaOrdersService';
import { DnaOrdersComponent } from "../dnaOrdersComponent/DnaOrdersComponent";
import { DnaOrderReplacementComponent } from "../dnaOrderReplacementComponent/DnaOrderReplacementComponent";
import { addOnsQuery } from '../../../addons/stores';
import { OrderType, CreateOrderModel } from '../../models/orders.models';
import { AddOnModel } from '../../../addons/models/addOns.models';
import {onEmit} from "../../../common/helpers/on-emit";

enum DnaStates {
    Orders,
    Replacement,
}

interface DnaComponentState {
    viewState: DnaStates;
    isLoadingNewKit: boolean;
    addOns: AddOnModel[];
    isNewOrderDialogOpen: boolean;
}

const defaultState: DnaComponentState = {
    viewState: DnaStates.Orders,
    isLoadingNewKit: false,
    addOns: [],
    isNewOrderDialogOpen: false,
}

export function useFacade(patientId: number, addOnsLoading: boolean): [JSX.Element] {
    const [state, setState] = useState(defaultState);

    const handleNavigateToOrders = () => {
        setState(state => ({ ...state, viewState: DnaStates.Orders }))
    }

    const handleNavigateToReplacement = () => {
        setState(state => ({ ...state, viewState: DnaStates.Replacement }))
    }

    const handleOrderKit = (model: CreateOrderModel) => {
        setState(state => ({ ...state, isLoadingNewKit: true, isNewOrderDialogOpen: false }))
        dnaOrdersService.create(model).subscribe(
            () => { setState(state => ({ ...state, isLoadingNewKit: false })) },
            () => { setState(state => ({ ...state, isLoadingNewKit: false })) }
        )
    }

    const handleToggleNewOrderDialog = () => {
        setState(state => ({ ...state, isNewOrderDialogOpen: !state.isNewOrderDialogOpen }));
    }

    const stateViews = new Map<DnaStates, JSX.Element>([
        [
            DnaStates.Orders,
            <DnaOrdersComponent
                isLoadingNewKit={state.isLoadingNewKit || addOnsLoading}
                isNewOrderDialogOpen={state.isNewOrderDialogOpen}
                patientId={patientId}
                addOns={state.addOns}
                handleNavigateToReplacement={handleNavigateToReplacement}
                handleOrderKit={handleOrderKit}
                handleToggleNewOrderDialog={handleToggleNewOrderDialog}
            />
        ],
        [
            DnaStates.Replacement,
            <DnaOrderReplacementComponent
                isLoadingNewKit={state.isLoadingNewKit || addOnsLoading}
                isNewOrderDialogOpen={state.isNewOrderDialogOpen}
                patientId={patientId}
                addOns={state.addOns}
                handleNavigateToOrders={handleNavigateToOrders}
                handleOrderKit={handleOrderKit}
                handleToggleNewOrderDialog={handleToggleNewOrderDialog}
            />
        ],
    ]);

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<AddOnModel[]>(addOnsQuery.addOns$, addOns => {
                if (!addOnsLoading && addOns.length) {
                    setState(state => ({...state, addOns: addOnsQuery.getByType(OrderType.Dna)}))
                }
            })
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [addOnsLoading]);

    return [stateViews.get(state.viewState)];
}
