import { createStyles, makeStyles } from "@material-ui/core/styles";
import { colors } from "../../../../../common/constants/colors";

export const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontSize: 16,
      lineHeight: '22px',
      fontWeight: 500,
      color: colors.black,
    },
    circleGreen:{
      borderRadius:"50%",
      width:6,
      height:6,
      backgroundColor:"#8ABD7C",
      display:'inline-block',
      position:'relative',
      bottom:3
    },
    circleOrange:{
      borderRadius:"50%",
      width:6,
      height:6,
      backgroundColor:"#FFA125",
      display:'inline-block',
      position:'relative',
      bottom:3
    },
    circleGray:{
      borderRadius:"50%",
      width:6,
      height:6,
      backgroundColor: colors.gray1,
      display:'inline-block',
      position:'relative',
      bottom:3
    },
    cardDivider: {
      backgroundColor: "#EFF3F8",
      margin: '10px auto',
      height: 1,
      width:'100%'
    },
    container:{
      width:'100%'
    },
    historyLinkText: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "16px",
      marginRight: 11,
    },
    historyLink: {
      color: colors.main,
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
    },
  })
);


