import { Box, IconButton } from '@material-ui/core';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { useFacade } from './SelectTrialPaymentPlanComponent.hooks';
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import commonUseStyles from '../../../common/styles/common.styles';

export const SelectTrialPaymentPlanComponent: React.FC = () => {
    const [
        {
            plan,
            isOpen
        },
        handleSelect,
        handleClose
    ] = useFacade();
    
    const commonClasses = commonUseStyles();

    if (!plan) {
        return <></>
    }

    return (
        <>
            <Box width="420px">
                <Dialog maxWidth="xs" open={isOpen} onClose={() => handleClose()} aria-labelledby="form-dialog-title">
                    <Box width={1} display="flex" justifyContent="flex-end">
                        <Box m={1}>
                            <IconButton id="select-trial-payment-close" aria-label="close" onClick={() => handleClose()}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <DialogContent>
                        <Box px={3}>
                            <Box width={1} textAlign="center" className={`${commonClasses.primaryTitle} text-bold size-2`}>
                                {plan.displayName}
                            </Box>
                            <Box mt={3}>
                                <Box textAlign="center">
                                    <span className="text-medium secondary-font size-1_5">
                                        {plan.title}
                                    </span>
                                </Box>
                                <Box mt={3}>
                                    {
                                        plan.benefits.map((benefit, index) =>
                                            <Box key={index} p={2} textAlign="center" className="text-medium">
                                                {
                                                    benefit.text &&
                                                    <pre style={{ whiteSpace: 'pre-wrap' }}> {benefit.text}</pre>
                                                }
                                            </Box>
                                        )
                                    }
                                </Box>
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Box width={1} display="flex" justifyContent="center" pb={3}>
                            <Box width={0.5}>
                                <WildHealthButton
                                    id="select-trial-payment-plan"
                                    onClick={() => handleSelect()}
                                    fullWidth
                                >
                                   <span className="select-button"> Select </span>
                                </WildHealthButton>
                            </Box>
                        </Box>
                    </DialogActions>
                </Dialog>
            </Box>
        </>
    )
}
