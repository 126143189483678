import {Box, Divider, Grid, Link, Typography} from "@material-ui/core";
import moment from "moment";
import {ReactComponent as DotIcon} from '@img/icons/Dot.svg';
import {ReactComponent as TopGoal} from '@img/icons/TopGoal.svg';
import React from "react";
import {
    AttachmentModel,
    FollowUpContent,
    GoalModel,
    HistoryAndPhysicalContent,
    IcdCode,
    MdmDataModel,
    MdmMode,
    MdmModel,
    MdmPatientType,
    NoteContentModel,
    NoteModel,
    NotesType,
    SectionType,
    SoapContent
} from "../models/notes.models";
import {colors} from "../../common/constants/colors";
import {TextMarkdownComponent} from "../components/textMarkdownComponent/TextMarkdownComponent";
import clsx from "clsx";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import CheckIcon from "@material-ui/icons/Check";
import {ProtectedElement} from "../../common/components/ProtectedElement";
import {UserType} from "../../auth/models/auth.enums";
import {PatientMedicationModel} from "../../patientSupplements/models/patientMedicationModel";
import {PatientSupplementModel, SupplementSource} from "../../patientSupplements/models/patientSupplementModel";
import {notesService} from "../services/notes.service";
import {supplementsService} from "../../patientSupplements/services/supplements.service";
import {medicationsService} from "../../patientSupplements/services/medications.service";
import {
    followUpNotePdfService,
    historyAndPhysicalPdfService,
    initialConsultNotePdfService,
    initialConsultNotePdfServiceOld,
    internalNotePdfService,
    soapNotePdfService
} from "../services/notes-pdf.service";
import {patientsSupplementsService} from "../../patientSupplements/services/patientSupplements.service";
import {patientsMedicationsService} from "../../patientSupplements/services/patientMedications.service";
import {Observable} from "rxjs";
import {authQuery} from "../../auth/stores/auth";
import {ApoeModel, YesNo} from "../../inputs/models/input.models";
import {ApoeSoursConstants} from "../../healthReport/constants/apoe.constants";
import {FeatureComponent} from "../../common/components/featureFlags/FeatureComponent";
import {FeatureFlag} from "../../common/components/featureFlags/featureFlags.models";

export const canDiscardNote = (note: NoteModel): boolean => {
    if (!note) {
        return true;
    }

    return note.employee?.id === authQuery.getEmployeeId();
}

const mdmPatientTypes = new Map<MdmPatientType, string>()
    .set(MdmPatientType.Established, 'Established Patient')
    .set(MdmPatientType.New, 'New Patient');

export const renderNoteTitle = (note) => {
    return <span>{note.name} - {moment(note.visitDate).format("ll")}</span>
}

export const renderTextSection = (title: string, text: string, commonClasses, isSmallScreen: boolean, divider: boolean = true) => {
    return (
        <Box>
            <span className={isSmallScreen ? clsx(commonClasses.size16, commonClasses.textMedium, commonClasses.colorBlack) : commonClasses.secondaryTitle}>
                {title}
            </span>
            <Box py={2}>
                {
                    divider && <Divider />
                }
            </Box>
            <span style={{ whiteSpace: 'pre-line' }}>
                <TextMarkdownComponent content={text} />
            </span>
        </Box>
    )
}

export const getApoeInfo = (items) => {
    const apoeStatus = items.find(it => it.key === 'APOE_STATUS_VALUE');
    const apoeLabel = items.find(it => it.key === 'APOE_STATUS_LABEL');
    const apoeScore = items.find(it => it.key === 'APOE_ACCURACY_SCORE_VALUE');
    return !!apoeStatus ? `${apoeLabel?.value}: Apo-${(apoeStatus.value && true) ? apoeStatus.value : ''} (${parseFloat(apoeScore.value)}%)` : ''
}

export const isFutureAppointment = (note: NoteModel) => {
    if (!note) {
        return true;
    }

    return note.isFutureAppointment;
}

export const itemWithDescription = (item) => {
    if (item.name) {
        if (item.value) {
            return `${item.name} - ${item.value}`;
        } else {
            return item.name;
        }
    }

    return item.value
}

export const renderApoeSection = (apoeInfo: ApoeModel) => {

    if (apoeInfo.hideApoe === YesNo.Yes) {
        return <></>
    }

    const getRecommendationText = () => {
        return apoeInfo.source === ApoeSoursConstants.BloodTest
            ? 'This result is based in a blood test confirmation.'
            : 'The APOE result was obtained through outside source and was not a blood test.  The confidence in accuracy is not known.'
    }

    return (
        <Box display='flex' flexDirection='column' mt={4}>
            <Box fontSize='20px' fontWeight='500'>
                Screening/Health Maintenance
            </Box>
            <Box display='flex' mt={3}>
                <Box display='flex'>
                    <Box display='flex' alignItems='center' width='100%'>
                        <Box display='flex' justifyContent='space-between' width='100%' alignItems='center'>
                            {apoeInfo.score
                                ? <Box display='flex'>
                                    <span style={{ fontWeight: "bold" }}>Your APOE Status</span>: Apo-{apoeInfo.score}  {apoeInfo.source === ApoeSoursConstants.BloodTest ? `(99%)` : ''}
                                    <Box color={colors.bad} ml={1}>*</Box>
                                </Box>
                                : <Box>No result</Box>}
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box mt={2} display='flex' flexWrap='wrap' width='100%'>
                {apoeInfo.colonoscopy?.value && <Box pr={2} minWidth='200px' width='50%'>
                    <Box style={{ fontWeight: "bold" }}>Colonoscopy in past:</Box>
                    <Box mt={1} >{`${moment(apoeInfo.colonoscopy.value).format('MM/DD/YYYY')}`}</Box>
                </Box>}
                {apoeInfo.psa?.value && <Box pr={2} minWidth='200px' width='50%'>
                    <Box style={{ fontWeight: "bold" }}>PSA in the past:</Box>
                    <Box mt={1} >{`${moment(apoeInfo.psa.value).format('MM/DD/YYYY')}`}</Box>
                </Box>}
            </Box>
            {apoeInfo.score
                ? <Box display='flex' mt={3}>
                    <Box color={colors.bad} mr={1}>*</Box>
                    <Box color={colors.gray1} fontSize={14}>
                        {getRecommendationText()}
                    </Box>
                </Box>
                : <></>}
        </Box>
    )
}

export const renderItemsSection = (
    title: string,
    items: string[],
    commonClasses,
    isSmallScreen: boolean,
    divider: boolean = true,
    description: string = '',
    isDiagnosis = false,
    isApoe: string = '') => {
    return (
        <Box>
            <span className={commonClasses.secondaryTitle}>
                {title}
            </span>
            <Box py={2}>
                {
                    divider && <Divider />
                }
            </Box>
            {
                description &&
                <Box pb={2}>
                    <span className={isSmallScreen ? commonClasses.smallTitle : commonClasses.subtitle}>
                        {description}
                    </span>
                </Box>
            }
            {!!isApoe && <Box>{isApoe}</Box>}
            <Box display={!isSmallScreen && "flex"} flexDirection={isDiagnosis && "column"} flexWrap="wrap">
                {
                    items.map((item, index) => {
                        return (
                            !!item
                                ?
                                <Box display="flex" key={index} mt={isDiagnosis && 1} mr={!isDiagnosis && 5}>
                                    <Box mt={1}>
                                        <DotIcon style={{ width: '8px', height: '8px' }} />
                                    </Box>
                                    <Box ml={1}>
                                        <TextMarkdownComponent content={item} />
                                    </Box>
                                </Box>
                                :
                                <></>
                        )
                    })
                }
            </Box>
        </Box>
    )
}


export const renderMedicationsAndSupplementsToStart = (medicationsSupplements, commonClasses, flags) => {
    return (
        <Box>
            <span className={commonClasses.secondaryTitle}>
                Medications and Supplements Added During the Visit
            </span>
            <Box py={2}>
                <Divider />
            </Box>
            <Box>
                <Grid container spacing={2}>
                    <Grid item md={6} sm={12} xs={12}>
                        <Box>
                            <Grid container>
                                <Grid item lg={3} md={4} sm={12} xs={12}>
                                    <Box mb={1} className={commonClasses.subtitle}>
                                        Supplements:
                                    </Box>
                                </Grid>
                                <Grid item lg={9} md={8} sm={12} xs={12}>
                                    {
                                        medicationsSupplements.supplements.filter(x => !x.isStopped && !x.isInCurrent).map((supplement, index) => {
                                            return (
                                                <Box key={index} display="flex">
                                                    <Box >
                                                        {supplement.name} - {supplement.dosage}
                                                    </Box>
                                                    <Box px={1}>
                                                        <ProtectedElement
                                                            element={supplement.purchaseLink && <Link
                                                                id='note-order-here'
                                                                className={commonClasses.colorMain}
                                                                onClick={() => window.open(supplement.purchaseLink, '_blank')}
                                                            >
                                                                <Typography className={commonClasses.textMedium} display='inline'>
                                                                    <span> {
                                                                        supplement.source === SupplementSource.Fullscript
                                                                            ? 'Purchase via Fullscript'
                                                                            : 'Purchase Here'
                                                                    } </span>
                                                                </Typography>
                                                            </Link>}
                                                            permissions={[]}
                                                            userType={UserType.Patient}
                                                        />
                                                    </Box>
                                                </Box>
                                            )
                                        })
                                    }
                                    {
                                        (medicationsSupplements.fullscriptSupplements ?? []).map((supplement, index) => {
                                            return (
                                                <Box key={index} display="flex">
                                                    <Box >
                                                        {supplement.name} - {supplement.dosage} {supplement.date ? `(${moment(supplement.date).format('L')})` : ''}
                                                    </Box>
                                                    <Box px={1}>
                                                        <ProtectedElement
                                                            element={supplement.purchaseLink && <Link
                                                                id='note-order-here'
                                                                className={commonClasses.colorMain}
                                                                onClick={() => window.open(supplement.purchaseLink, '_blank')}
                                                            >
                                                                <Typography className={commonClasses.textMedium} display='inline'>
                                                                    <span>Purchase via Fullscript</span>
                                                                </Typography>
                                                            </Link>}
                                                            permissions={[]}
                                                            userType={UserType.Patient}
                                                        />
                                                    </Box>
                                                </Box>
                                            )
                                        })
                                    }
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <Box>
                            <Grid container>
                                <Grid item lg={3} md={4} sm={12} xs={12}>
                                    <Box mb={1} className={commonClasses.subtitle}>
                                        Medications:
                                    </Box>
                                </Grid>
                                <Grid item lg={9} md={8} sm={12} xs={12}>
                                    <FeatureComponent notFeatureFlag={FeatureFlag.RxntIntegration}>
                                        {
                                            medicationsSupplements.medications.filter(x => !x.isStopped && !x.isInCurrent).map((medication, index) => {
                                                return (
                                                    <Box key={index}>
                                                        {medication.name} - {medication.dosage}
                                                    </Box>
                                                )
                                            })
                                        }
                                    </FeatureComponent>
                                    <FeatureComponent featureFlag={FeatureFlag.RxntIntegration}>
                                        {
                                            medicationsSupplements.rxntMedications?.filter(x => !x.isStopped && !x.isInCurrent).map((medication, index) => {
                                                return (
                                                    <Box key={index}>
                                                        {medication.name} - {medication.dosingInstructions}
                                                    </Box>
                                                )
                                            })
                                        }
                                    </FeatureComponent>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export const renderEmptyMedicationsAndSupplementsToStart = (commonClasses) => {
    return (
        <Box>
            <span className={commonClasses.secondaryTitle}>
                Medications and Supplements Added During the Visit
            </span>
            <Box py={2}>
                <Divider />
            </Box>
            <Box>
                No changes were made during this visit.
            </Box>
        </Box>
    );
}

export const renderEmptyMedicationsAndSupplementsToStop = (commonClasses) => {
    return (
        <Box>
            <span className={commonClasses.secondaryTitle}>
                Medications and Supplements Stopped During the Visit
            </span>
            <Box py={2}>
                <Divider />
            </Box>
            <Box>
                No changes were made during this visit.
            </Box>
        </Box>
    );
}

export const renderMedicationsAndSupplementsToStop = (medicationsSupplements, commonClasses, flags) => {
    return (
        <Box>
            <span className={commonClasses.secondaryTitle}>
                Medications and Supplements Stopped During the Visit
            </span>
            <Box py={2}>
                <Divider />
            </Box>
            <Box>
                <Grid container spacing={2}>
                    <Grid item md={6} sm={12} xs={12}>
                        <Box display="flex" justifyContent="space-between">
                            <Grid container>
                                <Grid item lg={3} md={4} sm={12} xs={12}>
                                    <Box mb={1} className={commonClasses.subtitle}>
                                        Supplements:
                                    </Box>
                                </Grid>
                                <Grid item lg={9} md={8} sm={12} xs={12}>
                                    {
                                        medicationsSupplements.supplements.filter(x => x.isStopped).map((supplement, index) => {
                                            return (
                                                <Box key={index} display="flex">
                                                    <Box>
                                                        {supplement.name} - {supplement.dosage}
                                                    </Box>
                                                    <Box px={1}>
                                                        <ProtectedElement
                                                            element={supplement.purchaseLink && <Link
                                                                id='note-order-here'
                                                                className={commonClasses.colorMain}
                                                                onClick={() => window.open(supplement.purchaseLink, '_blank')}
                                                            >
                                                                <Typography className={commonClasses.textMedium} display='inline'>
                                                                    <span> {
                                                                        supplement.source === SupplementSource.Fullscript
                                                                            ? 'Purchase via Fullscript'
                                                                            : 'Purchase Here'
                                                                    } </span>
                                                                </Typography>
                                                            </Link>}
                                                            permissions={[]}
                                                            userType={UserType.Patient}
                                                        />
                                                    </Box>
                                                </Box>
                                            )
                                        })
                                    }
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <Box display="flex" justifyContent="space-between">
                            <Grid container>
                                <Grid item lg={3} md={4} sm={12} xs={12}>
                                    <Box mb={1} className={commonClasses.subtitle}>
                                        Medications:
                                    </Box>
                                </Grid>
                                <Grid item lg={9} md={8} sm={12} xs={12}>
                                    <FeatureComponent notFeatureFlag={FeatureFlag.RxntIntegration}>
                                        {
                                            medicationsSupplements.medications.filter(x => x.isStopped).map((medication, index) => {
                                                return (
                                                    <Box key={index}>
                                                        {medication.name} - {medication.dosage}
                                                    </Box>
                                                )
                                            })
                                        }
                                    </FeatureComponent>
                                    <FeatureComponent featureFlag={FeatureFlag.RxntIntegration}>
                                        {
                                            medicationsSupplements.rxntMedications?.filter(x => x.isStopped).map((medication, index) => {
                                                return (
                                                    <Box key={index}>
                                                        {medication.name} - {medication.dosingInstructions}
                                                    </Box>
                                                )
                                            })
                                        }
                                    </FeatureComponent>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export const renderVitals = (physicalData, commonClasses, isSmallScreen: boolean) => {
    if (!physicalData) {
        return <></>;
    }

    return (
        <Box>
            <span className={commonClasses.secondaryTitle}>
                Physical
            </span>
            <Box py={2}>
                <Divider />
            </Box>
            <Box>
                {!physicalData.unableToObtain && <Box>
                    <span className={commonClasses.secondaryTitle}>
                        Vitals
                    </span>
                </Box>}
                <Box mt={2} display="flex">
                    <Box pr={4}>
                        {
                            !physicalData.unableToObtain && <>
                                <Box mt={1} height='24px' whiteSpace='nowrap'>
                                    <span className={commonClasses.subtitle}>
                                        Blood Pressure:
                                    </span>
                                </Box>
                                {isSmallScreen && <Box mt={1} height='24px'>
                                    {!!physicalData.systolicBP && physicalData.systolicBP}  {!!physicalData.diastolicBP && `/ ${physicalData.diastolicBP}`}
                                </Box>}
                                <Box mt={1} height='24px'>
                                    <span className={commonClasses.subtitle}>
                                        HR:
                                    </span>
                                </Box>
                                {isSmallScreen && <Box mt={1} mb={2}>
                                    {!!physicalData.heartRate && physicalData.heartRate}
                                </Box>}
                                <Box mt={1} height='24px'>
                                    <span className={commonClasses.subtitle}>
                                        Temperature:
                                    </span>
                                </Box>
                                {isSmallScreen && <Box mt={1} height='24px'>
                                    {!!physicalData.temperature && physicalData.temperature}
                                </Box>}
                                <Box mt={1} height='24px' whiteSpace='nowrap'>
                                    <span className={commonClasses.subtitle}>
                                        Weight (lbs):
                                    </span>
                                </Box>
                                {isSmallScreen && <Box mt={1} height='24px'>
                                    {!!physicalData.weight && physicalData.weight}
                                </Box>}
                                <Box mt={1} height='24px' whiteSpace='nowrap'>
                                    <span className={commonClasses.subtitle}>
                                        Height (inches):
                                    </span>
                                </Box>
                                {isSmallScreen && <Box mt={1} height='24px'>
                                    {!!physicalData.height && physicalData.height}
                                </Box>}
                            </>
                        }
                        <Box mt={1}>
                            <span className={commonClasses.subtitle}>
                                Physical:
                            </span>
                        </Box>
                        {isSmallScreen && <Box mt={1}>
                            <span style={{ whiteSpace: 'pre-line' }}>
                                <TextMarkdownComponent content={physicalData.physicalExam} />
                            </span>
                        </Box>}
                    </Box>
                    {!isSmallScreen
                        && <Box>
                            {!physicalData.unableToObtain && <>
                                <Box mt={1} height='24px'>
                                    {!!physicalData.systolicBP && physicalData.systolicBP}  {!!physicalData.diastolicBP && `/ ${physicalData.diastolicBP}`}
                                </Box>
                                <Box mt={1} height='24px'>
                                    {!!physicalData.heartRate && physicalData.heartRate}
                                </Box>
                                <Box mt={1} height='24px'>
                                    {!!physicalData.temperature && physicalData.temperature}
                                </Box>
                                <Box mt={1} height='24px'>
                                    {!!physicalData.weight && physicalData.weight}
                                </Box>
                                <Box mt={1} height='24px'>
                                    {!!physicalData.height && physicalData.height}
                                </Box></>}
                            <Box mt={1}>
                                <span style={{ whiteSpace: 'pre-line' }}>
                                    <TextMarkdownComponent content={physicalData.physicalExam} />
                                </span>
                            </Box>
                        </Box>}
                </Box>
            </Box>
        </Box>
    )
}

export const renderNextAppointmentDate = (
    nextCoachAppointmentDate,
    nextProviderAppointmentDate,
    commonClasses,
    isSmallScreen: boolean
) => {
    return (
        <Box>
            <span className={commonClasses.secondaryTitle}>
                Next Appointment
            </span>
            <Box py={2}>
                <Divider />
            </Box>
            <Box display="flex">
                <Box pr={5}>
                    {
                        nextCoachAppointmentDate &&
                        <>
                            <Box>
                                <span className={commonClasses.subtitle}>
                                    Coach Visit:
                                </span>
                            </Box>
                            {isSmallScreen && <Box mt="10px">
                                <span>
                                    {
                                        moment(nextCoachAppointmentDate).endOf('day').fromNow()
                                    }
                                </span>
                            </Box>}
                        </>
                    }
                    {
                        nextProviderAppointmentDate &&
                        <>
                            <Box mt={2}>
                                <span className={commonClasses.subtitle}>
                                    Provider Visit:
                                </span>
                            </Box>
                            {isSmallScreen && <Box mt="10px">
                                <span>
                                    {
                                        moment(nextProviderAppointmentDate).endOf('day').fromNow()
                                    }
                                </span>
                            </Box>}
                        </>
                    }
                </Box>
                {!isSmallScreen && <Box>
                    {
                        nextCoachAppointmentDate &&
                        <Box>
                            <span>
                                {
                                    moment(nextCoachAppointmentDate).endOf('day').fromNow()
                                }
                            </span>
                        </Box>
                    }
                    {
                        nextProviderAppointmentDate &&
                        <Box mt={2}>
                            <span>
                                {
                                    moment(nextProviderAppointmentDate).endOf('day').fromNow()
                                }
                            </span>
                        </Box>
                    }
                </Box>}
            </Box>
        </Box>
    )
}

export const renderMdm = (mdm: MdmDataModel, mdmCodes: MdmModel[], commonClasses, appointmentDuration = 0) => {
    if (!mdmCodes.length) {
        return <></>
    }

    const mdmCode = mdmCodes.find(x => x.id === mdm.selectedCodeId);
    const categories = mdmCode.categories.filter(x => (mdm.selectedCategoryIds ?? [mdm.selectedCategoryId]).some(t => t === x.id));

    return (
        <Box>
            <span className={commonClasses.secondaryTitle}>
                MDM
            </span>
            <Box py={2}>
                <Divider />
            </Box>
            <Box mb={2}>
                <TextMarkdownComponent content={mdm.mdmText} />
            </Box>
            <Box mb={2}>
                <span className={commonClasses.secondaryTitle}>
                    Coding
                </span>
            </Box>
            <Box>
                <Grid container spacing={2}>
                    <Grid item md={2} sm={12}>
                        <Box>
                            <span className={commonClasses.subtitle}>
                                {mdmPatientTypes.get(mdm.selectedMdmPatientType)}
                            </span>
                        </Box>
                    </Grid>
                    <Grid item md={10} sm={12}>
                        <Box style={{ fontWeight: 500 }}>
                            {
                                mdmCodes.find(x => x.id === mdm.selectedCodeId)?.code
                            }
                        </Box>
                        <Box>
                            {
                                mdm.selectedMode === MdmMode.TimeBased
                                    ? <Box display="flex">
                                        <Box>
                                            Time Based Billing
                                        </Box>
                                        <Box px={2}>
                                            <DotIcon style={{ width: '8px', height: '8px' }} />
                                        </Box>
                                        <Box>
                                            <span>
                                                I spent {mdm.spentTime + appointmentDuration} minutes meeting with the patient, reviewing results, updating plan and treatments, and/or consulting another provider.
                                            </span>
                                        </Box>
                                    </Box>
                                    : <Box display="flex">
                                        <Box mr={3}>
                                            {mdmCode.title}
                                        </Box>
                                        <Box>
                                            {
                                                mdm.showCodeDescription &&
                                                <Box>
                                                    {mdmCode.description}
                                                </Box>
                                            }
                                            {
                                                categories.map((category) => {
                                                    return (
                                                        mdm.selectedCategoryItems.map((item, index) => {
                                                            const allItems = category.items;
                                                            const categoryItem = allItems?.find(x => x.id === item.id);

                                                            if (!categoryItem) {
                                                                return <></>
                                                            }

                                                            return (
                                                                <Box display='flex'>
                                                                    <Box px={2}>
                                                                        <DotIcon fill={colors.gray3} style={{ width: '8px', height: '8px' }} />
                                                                    </Box>
                                                                    <Box key={index}>
                                                                        {
                                                                            categoryItem.description
                                                                        }
                                                                        {
                                                                            categoryItem.allowInput
                                                                                ? ` ${item.inputValue ?? ''}`
                                                                                : ''
                                                                        }
                                                                    </Box>
                                                                </Box>
                                                            )
                                                        })
                                                    )
                                                })
                                            }
                                        </Box>
                                    </Box>

                            }
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export const renderEducation = (educationText: string, commonClasses) => {
    return (
        <Box p={1}>
            <Grid container spacing={2}>
                <Grid item md={2} sm={12} xs={12} lg={2} xl={2}>
                    <span className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorGray1)}>
                        Education:
                    </span>
                </Grid>
                <Grid item md={10} sm={12} xs={12} lg={10} xl={10}>
                    {educationText}
                </Grid>
            </Grid>
        </Box>
    )
};

export const renderGoals = (title: string, goals: GoalModel[], commonClasses, isSmallScreen: boolean) => {
    const sortedGoals = goals.sort((x, y) => {
        return (x.isTopGoal === y.isTopGoal) ? 0 : x.isTopGoal ? -1 : 1;
    });
    return (
        <Box>
            <Box pb={2}>
                <span className={isSmallScreen ? clsx(commonClasses.size16, commonClasses.textMedium, commonClasses.colorBlack) : commonClasses.secondaryTitle}>
                    {title}
                </span>
            </Box>
            {
                sortedGoals.map((goal, index) => {
                    return (
                        <Box p={2} mb={1} width={1} key={index} position='relative' style={{
                            borderRadius: '2px',
                            border: goal.isCompleted ? `1px solid ${colors.accent1}` : 'none',
                            background: goal.isCompleted ? colors.lightGray : colors.bg
                        }}>
                            {goal.isTopGoal && <Box position='absolute' width='34px' height='29px' top='-5px' left='74px'><TopGoal /></Box>}
                            <Grid container spacing={2}>

                                <Grid item md={2} sm={12} xs={12}>
                                    <Box>
                                        <span className={isSmallScreen ? clsx(commonClasses.size16, commonClasses.textMedium, commonClasses.colorGray1) : commonClasses.subtitle}>
                                            Goal {index + 1}:
                                        </span>
                                    </Box>
                                </Grid>
                                <Grid item md={5} sm={12} xs={12}>
                                    <span>
                                        {
                                            goal.name
                                        }
                                    </span>
                                </Grid>
                                <Grid item md={5} sm={12} xs={12}>
                                    <Box display="flex" justifyContent="flex-end">
                                        {
                                            goal.isCompleted
                                                ? <Box style={{ color: colors.main }} display="flex" alignItems="center">
                                                    <Box>
                                                        <CheckIcon />
                                                    </Box>
                                                    <Box ml={1}>
                                                        <span>Success Achieved</span>
                                                    </Box>
                                                </Box>
                                                : <Box display="flex" alignItems="center">
                                                    <Box >
                                                        <FiberManualRecordIcon style={{
                                                            color: colors.average1,
                                                            height: '8px',
                                                            width: '8px'
                                                        }} />
                                                    </Box>
                                                    <Box ml={1}>
                                                        <span>Striving for Success</span>
                                                    </Box>
                                                </Box>
                                        }
                                    </Box>
                                </Grid>
                                <Grid item md={2} sm={12} xs={12}>
                                    <Box>
                                        <span className={isSmallScreen ? clsx(commonClasses.size16, commonClasses.textMedium, commonClasses.colorGray1) : commonClasses.subtitle}>
                                            Est. Completion Date:
                                        </span>
                                    </Box>
                                </Grid>
                                <Grid item md={10} sm={12} xs={12}>
                                    <span>
                                        {
                                            goal.completionDate
                                                ? moment(goal.completionDate).format('L')
                                                : 'No completion date'
                                        }
                                    </span>
                                </Grid>
                                <Grid item md={2} sm={12} xs={12}>
                                    <Box>
                                        <span className={isSmallScreen ? clsx(commonClasses.size16, commonClasses.textMedium, commonClasses.colorGray1) : commonClasses.subtitle}>
                                            Interventions:
                                        </span>
                                    </Box>
                                </Grid>
                                <Grid item md={10} sm={12} xs={12}>
                                    <Box>
                                        {
                                            goal.interventions.map((intervention, index) => {
                                                return (
                                                    <Box key={index}>
                                                        <TextMarkdownComponent content={intervention.name} />
                                                    </Box>
                                                )
                                            })
                                        }
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    )
                })
            }
        </Box>
    )
}

export const IsSectionAmendHistoryAndPhysical = (originalContent: HistoryAndPhysicalContent, amendContent: HistoryAndPhysicalContent, sectionType: SectionType): boolean => {
    if (!originalContent || !amendContent) {
        return false;
    }
    switch (sectionType) {
        case SectionType.Hpi: {
            return originalContent.HPI !== amendContent.HPI
        }
        case SectionType.Diagnosis: {
            return JSON.stringify(originalContent.diagnosis) !== JSON.stringify(amendContent.diagnosis)
        }
        case SectionType.MedicationAndSupplements: {
            return JSON.stringify(originalContent.medicationsSupplements) !== JSON.stringify(amendContent.medicationsSupplements)
        }
        case SectionType.PMH: {
            if (amendContent?.pmhVersion) {
                return JSON.stringify(originalContent.PMHnew) !== JSON.stringify(amendContent.PMHnew)
            }
            return JSON.stringify(originalContent.PMH) !== JSON.stringify(amendContent.PMH)
        }
        case SectionType.Ros: {
            return JSON.stringify(originalContent.ROS) !== JSON.stringify(amendContent.ROS)
        }
        case SectionType.Physical: {
            return JSON.stringify(originalContent.physicalData) !== JSON.stringify(amendContent.physicalData)
        }
        case SectionType.Mdm: {
            return JSON.stringify(originalContent.mdmData) !== JSON.stringify(amendContent.mdmData)
        }
        case SectionType.Plan: {
            return JSON.stringify(originalContent.plan) !== JSON.stringify(amendContent.plan)
        }
    }
}

export const IsSectionAmendSoap = (originalContent: SoapContent, amendContent: SoapContent, sectionType: SectionType): boolean => {
    if (!originalContent || !amendContent) {
        return false;
    }
    switch (sectionType) {
        case SectionType.Diagnosis: {
            return JSON.stringify(originalContent.diagnosis) !== JSON.stringify(amendContent.diagnosis)
        }
        case SectionType.MedicationAndSupplements: {
            return JSON.stringify(originalContent.medicationsSupplements) !== JSON.stringify(amendContent.medicationsSupplements)
        }
        case SectionType.Ros: {
            return JSON.stringify(originalContent.ROS) !== JSON.stringify(amendContent.ROS)
        }
        case SectionType.Physical: {
            return JSON.stringify(originalContent.physicalData) !== JSON.stringify(amendContent.physicalData)
        }
        case SectionType.Mdm: {
            return JSON.stringify(originalContent.mdmData) !== JSON.stringify(amendContent.mdmData)
        }
        case SectionType.Plan: {
            return JSON.stringify(originalContent.plan) !== JSON.stringify(amendContent.plan)
        }
    }
}

export const GenerateAmendedChangesForSoap = (originalContent: SoapContent, amendContent: SoapContent) => {
    var edited: Array<string> = new Array<string>();

    const sectionForChecking = [
        SectionType.Diagnosis,
        SectionType.MedicationAndSupplements,
        SectionType.Ros,
        SectionType.Physical,
        SectionType.Mdm,
        SectionType.Plan
    ];

    var isAmended = sectionForChecking.some(el => IsSectionAmendSoap(originalContent, amendContent, el))
    if (isAmended) {
        edited.push("Amended areas in this note:");

        if (IsSectionAmendSoap(originalContent, amendContent, SectionType.Diagnosis)) {
            edited.push("Diagnosis was updated.");
        }

        if (IsSectionAmendSoap(originalContent, amendContent, SectionType.MedicationAndSupplements)) {
            edited.push("Medication And Supplements were updated.");
        }

        if (IsSectionAmendSoap(originalContent, amendContent, SectionType.Ros)) {
            edited.push("ROS was updated.");
        }

        if (IsSectionAmendSoap(originalContent, amendContent, SectionType.Physical)) {
            edited.push("Physical was updated.");
        }

        if (IsSectionAmendSoap(originalContent, amendContent, SectionType.Mdm)) {
            edited.push("Mdm data was updated.");
        }

        if (IsSectionAmendSoap(originalContent, amendContent, SectionType.Plan)) {
            edited.push("Plan was updated.");
        }
    }

    return edited;
}

export const GetMaximumVersionOfNote = (note: NoteModel) => {
    if (note?.amendedNotes?.length > 0) {
        let resultNotes = note.amendedNotes.filter(x => x.completedAt !== null && x.completedAt !== undefined);
        if (resultNotes?.length > 0) {
            return resultNotes.sort((x, y) => y?.version - x?.version)[0];
        }
    }

    return note;
}

export const GetCurrentDraft = (note: NoteModel) => {
    if (note?.amendedNotes?.length > 0) {
        let resultNotes = note.amendedNotes.filter(x => !x.completedAt);
        if (resultNotes?.length > 0) {
            return resultNotes.sort((x, y) => y?.version - x?.version)[0];
        }
    }

    return note;
}

export const IsSectionAmendICC = (originalContent: NoteContentModel, amendContent: NoteContentModel): boolean => {
    return originalContent.internalContent !== amendContent.internalContent
        || originalContent.content !== amendContent.content
        || JSON.stringify(originalContent.logs) !== JSON.stringify(amendContent.logs);
}

export const IsSectionAmendFollowUp = (originalContent: FollowUpContent, amendContent: FollowUpContent, sectionType: SectionType): boolean => {
    if (!originalContent || !amendContent) {
        return false;
    }

    switch (sectionType) {
        case SectionType.MedicationAndSupplements: {
            return JSON.stringify(originalContent.medicationsSupplements) !== JSON.stringify(amendContent.medicationsSupplements)
        }
        case SectionType.Plan: {
            return JSON.stringify(originalContent.plan) !== JSON.stringify(amendContent.plan)
        }
        case SectionType.NextAppointment: {
            return JSON.stringify(originalContent.steps) !== JSON.stringify(amendContent.steps)
        }
        case SectionType.Mdm: {
            return JSON.stringify(originalContent.goals) !== JSON.stringify(amendContent.goals)
        }
    }
}

export const GenerateAmendedChangesForFollowUp = (originalContent: FollowUpContent, amendContent: FollowUpContent, internalContent: string, originalInternalContent: string) => {
    var edited: Array<string> = new Array<string>();

    const sectionForChecking = [
        SectionType.Plan,
        SectionType.MedicationAndSupplements,
        SectionType.Mdm,
        SectionType.NextAppointment
    ];

    var isAmended = sectionForChecking.some(el => IsSectionAmendFollowUp(originalContent, amendContent, el))

    if (isAmended) {
        edited.push("Amended areas in this note:");

        if (IsSectionAmendFollowUp(originalContent, amendContent, SectionType.Plan)) {
            edited.push("Goals were updated.");
        }

        if (IsSectionAmendFollowUp(originalContent, amendContent, SectionType.MedicationAndSupplements)) {
            edited.push("Medication And Supplements were updated.");
        }

        if (IsSectionAmendFollowUp(originalContent, amendContent, SectionType.NextAppointment)) {
            edited.push("Next Steps were updated.");
        }

        if (IsSectionAmendFollowUp(originalContent, amendContent, SectionType.Mdm)) {
            edited.push("Goals were updated.");
        }

        if (internalContent !== originalInternalContent) {
            edited.push("Internal content was updated.");
        }
    }

    return edited;
}

export const GenerateAmendedChangesForHistoryAndPhysical = (originalContent: HistoryAndPhysicalContent, amendContent: HistoryAndPhysicalContent) => {
    var edited: Array<string> = new Array<string>();

    const sectionForChecking = [
        SectionType.Hpi,
        SectionType.Diagnosis,
        SectionType.MedicationAndSupplements,
        SectionType.PMH,
        SectionType.Ros,
        SectionType.Physical,
        SectionType.Mdm,
        SectionType.Plan,
    ];

    var isAmended = sectionForChecking.some(el => IsSectionAmendHistoryAndPhysical(originalContent, amendContent, el))

    if (isAmended) {
        edited.push("Amended areas in this note:");

        if (IsSectionAmendHistoryAndPhysical(originalContent, amendContent, SectionType.Hpi)) {
            edited.push("HPI was updated.");
        }

        if (IsSectionAmendHistoryAndPhysical(originalContent, amendContent, SectionType.Diagnosis)) {
            edited.push("Diagnosis was updated.");
        }

        if (IsSectionAmendHistoryAndPhysical(originalContent, amendContent, SectionType.MedicationAndSupplements)) {
            edited.push("Medication And Supplements were updated.");
        }

        if (IsSectionAmendHistoryAndPhysical(originalContent, amendContent, SectionType.PMH)) {
            edited.push("PMH was updated.");
        }

        if (IsSectionAmendHistoryAndPhysical(originalContent, amendContent, SectionType.Ros)) {
            edited.push("ROS was updated.");
        }

        if (IsSectionAmendHistoryAndPhysical(originalContent, amendContent, SectionType.Physical)) {
            edited.push("Physical was updated.");
        }

        if (IsSectionAmendHistoryAndPhysical(originalContent, amendContent, SectionType.Mdm)) {
            edited.push("Mdm data was updated.");
        }

        if (IsSectionAmendHistoryAndPhysical(originalContent, amendContent, SectionType.Plan)) {
            edited.push("Plan was updated.");
        }
    }

    return edited;
}



export const handleDownloadNoteByEmployeePdfBd = (note: NoteModel, type: UserType, flags): Observable<boolean> => {
    const isEmployeeView = type === UserType.Employee
    return new Observable<boolean>(observer => {
        switch (note.type) {
            case NotesType.Initial: {
                let pdfContent: NoteContentModel;
                let pdfMedications: PatientMedicationModel[];
                let pdfSupplements: PatientSupplementModel[];

                notesService.getContentByEmployee(note.id, note.patientId).subscribe((content) => {
                    pdfContent = content;
                    supplementsService.get(note.patientId).subscribe((supplements) => {
                        pdfSupplements = supplements;
                        medicationsService.get(note.patientId).subscribe((medications) => {
                            pdfMedications = medications;
                            if (note.isOldIcc){
                                initialConsultNotePdfServiceOld.downloadNotePdf(note, pdfContent, pdfMedications, pdfSupplements, isEmployeeView, note.id, ).subscribe(
                                    (result) => {
                                        observer.next(result);
                                        observer.complete();
                                    },
                                    error => {
                                        observer.error(error);
                                        observer.complete();
                                    }
                                );
                            }
                            else{
                                initialConsultNotePdfService.downloadNotePdf(note, pdfContent, pdfMedications, pdfSupplements, isEmployeeView, note.id, ).subscribe(
                                    (result) => {
                                        observer.next(result);
                                        observer.complete();
                                    },
                                    error => {
                                        observer.error(error);
                                        observer.complete();
                                    }
                                );
                            }
                        });
                    });
                });
                break;
            }
            case NotesType.FollowUp: {
                notesService.getContentByEmployee(note.id, note.patientId).subscribe((result) => {
                    followUpNotePdfService.downloadNotePdf(note, JSON.parse(result.content), isEmployeeView, note.id, result.internalContent, flags).subscribe(
                        (result) => {
                            observer.next(result);
                            observer.complete();
                        },
                        error => {
                            observer.error(error);
                            observer.complete();
                        }
                    );
                });
                break;
            }
            case NotesType.Blank:
            case NotesType.Internal: {
                notesService.getContentByEmployee(note.id, note.patientId).subscribe((result) => {
                    let attachments = result?.attachmentsEmployee?.map((item, index) => {
                        const file = item as File;
                        return {
                            tempId: Date.now() + index,
                            id: item.attachmentId,
                            file: file,
                            ForDelete: false,
                            noteId: GetMaximumVersionOfNote(note).id,
                            fileName: item.fileName,
                            fileSize: item.fileSize
                        } as AttachmentModel;
                    });
                    internalNotePdfService.downloadNotePdf(note, result.internalContent, attachments, isEmployeeView, note.id, false, false).subscribe(
                        (result) => {
                            observer.next(result);
                            observer.complete();
                        },
                        error => {
                            observer.error(error);
                            observer.complete();
                        }
                    );
                });
                break;
            }
            case NotesType.Soap: {
                notesService.getContentByEmployee(note.id, note.patientId).subscribe((result) => {
                    soapNotePdfService.downloadNotePdf(note, JSON.parse(result.content), isEmployeeView, note.id, flags).subscribe(
                        (result) => {
                            observer.next(result);
                            observer.complete();
                        },
                        error => {
                            observer.error(error);
                            observer.complete();
                        }
                    );
                });
                break;
            }
            case NotesType.HistoryAndPhysicalInitial: {
                notesService.getContentByEmployee(note.id, note.patientId).subscribe((result) => {
                    historyAndPhysicalPdfService.downloadNotePdf(note, JSON.parse(result.content), isEmployeeView, note.id, flags).subscribe(
                        (result) => {
                            observer.next(result);
                            observer.complete();
                        },
                        error => {
                            observer.error(error);
                            observer.complete();
                        }
                    );
                });
                break;
            }
            case NotesType.HistoryAndPhysicalFollowUp: {
                notesService.getContentByEmployee(note.id, note.patientId).subscribe((result) => {
                    historyAndPhysicalPdfService.downloadNotePdf(note, JSON.parse(result.content), isEmployeeView, note.id, flags).subscribe(
                        (result) => {
                            observer.next(result);
                            observer.complete();
                        },
                        error => {
                            observer.error(error);
                            observer.complete();
                        }
                    );
                });
                break;
            }
            case NotesType.HistoryAndPhysicalGroupVisit: {
                notesService.getContentByEmployee(note.id, note.patientId).subscribe((result) => {
                    historyAndPhysicalPdfService.downloadNotePdf(note, JSON.parse(result.content), isEmployeeView, note.id, flags).subscribe(
                        (result) => {
                            observer.next(result);
                            observer.complete();
                        },
                        error => {
                            observer.error(error);
                            observer.complete();
                        }
                    );
                });
                break;
            }
            default:
                break;
        }
    })
}

export const handleDownloadNoteByPatientPdfBd = (note: NoteModel, flags): Observable<boolean> => {
    return new Observable<boolean>(observer => {
        switch (note.type) {
            case NotesType.Initial: {
                let pdfContent: NoteContentModel;
                let pdfMedications: PatientMedicationModel[];
                let pdfSupplements: PatientSupplementModel[];

                notesService.getContentByPatient(note.id).subscribe((content) => {
                    pdfContent = content;
                    patientsSupplementsService.get().subscribe((supplements) => {
                        pdfSupplements = supplements;
                        patientsMedicationsService.get().subscribe((medications) => {
                            pdfMedications = medications;
                            if(note.isOldIcc){
                                initialConsultNotePdfServiceOld.downloadNotePdf(note, pdfContent, pdfMedications, pdfSupplements, false, note.id).subscribe((result) => {
                                    observer.next(result);
                                    observer.complete();
                                },
                                error => {
                                    observer.error(error);
                                    observer.complete();
                                })
                            }
                            else{
                                initialConsultNotePdfService.downloadNotePdf(note, pdfContent, pdfMedications, pdfSupplements, false, note.id, '').subscribe(
                                    (result) => {
                                        observer.next(result);
                                        observer.complete();
                                    },
                                    error => {
                                        observer.error(error);
                                        observer.complete();
                                    }
                                );
                            }
                        });
                    });
                });
                break;
            }
            case NotesType.FollowUp: {
                notesService.getContentByPatient(note.id).subscribe((result) => {
                    followUpNotePdfService.downloadNotePdf(note, JSON.parse(result.content), false, note.id, '', flags).subscribe(
                        (result) => {
                            observer.next(result);
                            observer.complete();
                        },
                        error => {
                            observer.error(error);
                            observer.complete();
                        }
                    );
                });
                break;
            }
            case NotesType.Blank:
            case NotesType.Internal: {
                notesService.getContentByPatient(note.id).subscribe((result) => {
                    internalNotePdfService.downloadNotePdf(note, result.internalContent, result.attachmentsEmployee, false, note.id, false, false).subscribe(
                        (result) => {
                            observer.next(result);
                            observer.complete();
                        },
                        error => {
                            observer.error(error);
                            observer.complete();
                        }
                    );

                });
                break;
            }
            case NotesType.Soap: {
                notesService.getContentByPatient(note.id).subscribe((result) => {
                    soapNotePdfService.downloadNotePdf(note, JSON.parse(result.content), false, note.id, flags).subscribe(
                        (result) => {
                            observer.next(result);
                            observer.complete();
                        },
                        error => {
                            observer.error(error);
                            observer.complete();
                        }
                    );
                });
                break;
            }
            case NotesType.HistoryAndPhysicalInitial: {
                notesService.getContentByPatient(note.id).subscribe((result) => {
                    historyAndPhysicalPdfService.downloadNotePdf(note, JSON.parse(result.content), false, note.id, flags).subscribe(
                        (result) => {
                            observer.next(result);
                            observer.complete();
                        },
                        error => {
                            observer.error(error);
                            observer.complete();
                        }
                    );
                });
                break;
            }
            case NotesType.HistoryAndPhysicalFollowUp: {
                notesService.getContentByPatient(note.id).subscribe((result) => {
                    historyAndPhysicalPdfService.downloadNotePdf(note, JSON.parse(result.content), false, note.id, flags).subscribe(
                        (result) => {
                            observer.next(result);
                            observer.complete();
                        },
                        error => {
                            observer.error(error);
                            observer.complete();
                        }
                    );
                });
                break;
            }
            case NotesType.HistoryAndPhysicalGroupVisit: {
                notesService.getContentByPatient(note.id).subscribe((result) => {
                    historyAndPhysicalPdfService.downloadNotePdf(note, JSON.parse(result.content), false, note.id, flags).subscribe(
                        (result) => {
                            observer.next(result);
                            observer.complete();
                        },
                        error => {
                            observer.error(error);
                            observer.complete();
                        }
                    );
                });
                break;
            }
            default:
                break;
        }
    })
}

export const commonDiagnoses: IcdCode[] = [
    {
        id: 23599,
        code: 'R94.5',
        description: 'Abnormal LFTs',
    },
    {
        id: 71610,
        code: 'Z13.6',
        description: 'Counseling for Cardiovascular Disease',
    },
    {
        id: 72203,
        code: 'Z71.3',
        description: 'Dietary Counseling',
    },
    {
        id: 3832,
        code: 'E78.5',
        description: 'Dyslipidemia',
    },
    {
        id: 23430,
        code: 'R79.82',
        description: 'Elevated CRP',
    },
    {
        id: 23400,
        code: 'R74.01',
        description: 'Elevated liver enzymes',
    },
    {
        id: 8672,
        code: 'I10',
        description: 'Essential Hypertension',
    },
    {
        id: 72211,
        code: 'Z71.82',
        description: 'Exercise Counseling',
    },
    {
        id: 71472,
        code: 'Z00.00',
        description: 'General Adult Medical Examination without abnormal findings',
    },
    {
        id: 72216,
        code: 'Z71.9',
        description: 'General Counseling',
    },
    {
        id: 3506,
        code: 'E16.8',
        description: 'Hyperinsulinemia',
    },
    {
        id: 3827,
        code: 'E78.1',
        description: 'Hypertriglyceridemia',
    },
    {
        id: 3564,
        code: 'E29.1',
        description: 'Hypogonadism/Low Testosterone',
    },
    {
        id: 4990,
        code: 'G47.00',
        description: 'Insomnia',
    },
    {
        id: 3919,
        code: 'E88.81',
        description: 'Metabolic Syndrome',
    },
    {
        id: 23398,
        code: 'R73.09',
        description: 'Other abnormal glucose',
    },
    {
        id: 23397,
        code: 'R73.03',
        description: 'Prediabetes',
    },
    {
        id: 23432,
        code: 'R79.89',
        description: 'Other abnormalities of blood chemistry',
    },
    {
        id: 72309,
        code: 'Z79.899',
        description: 'Other drug monitoring - long term use',
    },
    {
        id: 3662,
        code: 'E66.3',
        description: 'Overweight',
    },
    {
        id: 3413,
        code: 'E11.9',
        description: 'Type 2 Diabetes',
    },
    {
        id: 3629,
        code: 'E55.9',
        description: 'Vitamin D Deficiency',
    },
    {
        id: 3633,
        code: 'E56.9',
        description: 'Vitamin Deficiency, unspecified',
    },
    {
        id: 23336,
        code: 'R53.82',
        description: 'Chronic Fatigue, unspecified',
    },
    {
        id: 4673,
        code: 'F90.0',
        description: 'Attention-deficit Hyperactivity disorder, unspecified type',
    },
    {
        id: 4472,
        code: 'F32.A',
        description: 'Depression, unspecified',
    },
    {
        id: 10890,
        code: 'K58.2',
        description: 'Mixed irritable bowel syndrome',
    },
    {
        id: 3040,
        code: 'E03.9',
        description: 'Hypothyroidism, unspecified',
    },
    {
        id: 3841,
        code: 'E79.0',
        description: 'Hyperuricemia without signs of inflammatory arthritis and tophaceous disease',
    },
    {
        id:71635,
        code: 'Z15.89',
        description: 'Genetic susceptibility to other disease'
    },
    {
        id: 2844,
        code: 'D64.9',
        description: 'Anemia, unspecified'
    }
]
