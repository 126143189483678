import {useHistory} from "react-router";
import {authQuery} from "../../stores/auth";
import {navigationService} from "../../../../services/navigation.service";
import {useEffect} from "react";
import {Subscription} from "rxjs";
import {ExternalAuthorizationProviderModel} from "../../models/auth.models";
import {ExternalAuthProvider} from "../../models/auth.enums";
import {auth2UrlBuilder} from "../../helpers/auth2UrlBuilder";
import { authService } from "../../services/auth.service";

export function useFacade(provider: string, from: string) {
    const _history = useHistory();

    if (authQuery.isLoggedIn()) {
        navigationService.toDashboard(_history);
    }

    const redirect = (authorizationProvider: ExternalAuthorizationProviderModel) => {
        window.location.href = auth2UrlBuilder.build(authorizationProvider, from);
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            authService.getExternalAuthorizationProviders().subscribe(providers=> {
                const authorizationProvider = providers.find(x => x.provider === ExternalAuthProvider[provider]);
                if (authorizationProvider) {
                    redirect(authorizationProvider);
                }
            })
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);
}