import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        main: {
            padding: "30px",
            [theme.breakpoints.down("xs")]: {
                padding: 16,
            },
        },
        navigation: {
            bottom: 0
        },
        content: {
            width: '672px',
            [theme.breakpoints.down("xs")]: {
                width: '100%',
            },
        }
    }),
);
