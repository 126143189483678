import { ReactComponent as NoInformationIcon } from '@img/icons/HealthSectionEmptyState.svg';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton'
import { FeatureComponent } from '../../../common/components/featureFlags/FeatureComponent'
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress'
import { FeatureFlag } from '../../../common/components/featureFlags/featureFlags.models'
import { WildHealthLinkButton } from '../../../common/components/WildHealthLinkButton'
import { ProtectedElement } from '../../../common/components/ProtectedElement'
import { navigationService } from '../../../../services/navigation.service'
import { useStyles } from './HealthSummaryWrapperComponent.styles'
import { UserType } from '../../../auth/models/auth.enums'
import { authQuery } from '../../../auth/stores/auth'
import { Box, Button, Grid } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import { useHistory } from 'react-router'
import React from 'react'
import {healthSummaryRxntMedicationsService} from "../../services/healthSummaryRxntMedicationsService";
import {
    ManageRxntButtonComponent
} from "../../../patientSupplements/components/manageRxntButton/ManageRxntButtonComponent";
import {healthSummaryRxntAllergiesService} from "../../services/HealthSummaryRxntAllergiesService";


export interface HealthSummaryWrapperComponentProps {
    isLoading: boolean
    patientId?: number
    isEmpty: boolean
    title: string
    mapKey?: string
    handleAdd?: Function
    handleEdit?: Function
    handleSave?: Function
    handleCancel?: Function
    editMode?: boolean
    disableUpdateButton?: boolean
    children: React.ReactNode
}

export const HealthSummaryWrapperComponent: React.FC<HealthSummaryWrapperComponentProps> = (props: HealthSummaryWrapperComponentProps) => {
    const {
        isLoading,
        patientId = null,
        isEmpty,
        title,
        mapKey,
        handleAdd,
        handleEdit,
        handleSave,
        handleCancel,
        editMode,
        disableUpdateButton,
        children
    } = props

    const rxntMapKeys = [
        healthSummaryRxntMedicationsService.mapKey,
        healthSummaryRxntAllergiesService.mapKey
    ];

    const history = useHistory();

    const classes = useStyles();

    const loadingItem = () => (<WildHealthLinearProgress />)

    const linkQuestionnaireButton = () => {
        const clickAction = authQuery.getType() === UserType.Employee
            ? () => navigationService.toPatientHealthForms(history, patientId)
            : () => navigationService.toMyHealthForms(history)


        return <WildHealthLinkButton
            linkButton={true}
            id={'questionnaire-button'}
            content={'questionnaire'}
            onClick={clickAction}
        />
    }

    const getEmptyMessage = (): string => {
        switch (mapKey) {
            case 'MEDICATIONS': return 'No medications prescribed.'
            case 'SUPPLEMENTS': return 'No supplements prescribed.'
            case 'ALLERGIES': return 'No known drug allergies'
            default: return 'No information provided.'
        }
    }

    const getEmptyDescription = () => {
        switch (mapKey) {
            case 'MEDICATIONS':
            case 'SUPPLEMENTS':
                return '';
            case 'FAMILY_HISTORY': return <Box>No pertinent family history.</Box>
            case 'PROBLEMS_LIST': return <Box>No pertinent problem list.</Box>
            case 'SURGICAL_HISTORY': return <Box>No pertinent surgical history.</Box>
            default: return <Box>Complete your {linkQuestionnaireButton()} to have your info displayed here.</Box>
        }
    }

    const emptyContent = () => {
        return <Box className={classes.empty}>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                className={classes.root}
            >
                <Box textAlign="center" width="inherit" display="flex" flexDirection="column" alignItems="center">
                    <NoInformationIcon />
                    <Box className={classes.message} textAlign="center">{getEmptyMessage()}</Box>
                    {getEmptyDescription()}
                </Box>
            </Grid>
        </Box>
    }

    return <Box className={classes.container}>
        <Box className={classes.titleConteiner}>
            <Box className={classes.title}>
                {title}
            </Box>

            <FeatureComponent featureFlag={FeatureFlag.HealthSummaryEdit}>
                {handleAdd && !isEmpty && !disableUpdateButton &&
                    <ProtectedElement
                        element={
                            <Button className={classes.button} onClick={(e) => { handleAdd(e) }}>
                                <AddIcon fontSize='small' />
                                <Box className={classes.buttonText}>Add</Box>
                            </Button>
                        }
                        userType={UserType.Employee}
                        permissions={[]}
                    />
                }
                {disableUpdateButton && rxntMapKeys.includes(mapKey) &&
                    <ProtectedElement
                        element={<ManageRxntButtonComponent patientId={patientId}/>}
                        userType={UserType.Employee}
                        permissions={[]}
                    />
                }
                {handleEdit && !disableUpdateButton &&
                    <ProtectedElement
                        element={
                            editMode
                                ?
                                <Box display='flex'>
                                    <Box mr={1}>
                                        <WildHealthButton
                                            id='bnt-11'
                                            onClick={() => {
                                                handleEdit(false)
                                                handleCancel()
                                            }}
                                            size='default'
                                            color='tertiary'>
                                            Cancel
                                    </WildHealthButton>
                                    </Box>
                                    <Box>
                                        <WildHealthButton
                                            id='btn-12'
                                            onClick={(e) => { handleSave() }}
                                            size='default'>
                                            Save
                                    </WildHealthButton>
                                    </Box>
                                </Box>
                                :
                                <Button className={classes.button} onClick={() => { handleEdit(true) }}>
                                    <EditIcon fontSize='small' />
                                    <Box className={classes.buttonText}>Edit</Box>
                                </Button>
                        }
                        userType={UserType.Employee}
                        permissions={[]}
                    />
                }
            </FeatureComponent>
        </Box>
        <Box pt={isEmpty ? '40px' : '20px'} pb={isEmpty ? '80px' : '30px'}>
            {
                isLoading
                    ? loadingItem()
                    : isEmpty && !editMode ? emptyContent() : children
            }
        </Box>
    </Box>
}