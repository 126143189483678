import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { onEmit } from "../../../common/helpers/on-emit";
import { IErrorState } from "../../../common/validation/error-state";
import { newPatientMedicationModel, PatientMedicationModel } from "../../models/patientMedicationModel";
import { medicationsService } from "../../services/medications.service";
import { patientsMedicationsService } from "../../services/patientMedications.service";
import { patientMedicationsQuery } from "../../stores/patientMedicationsStore/patientMedications.query";
import { PatientMedicationUpdateProps } from "./PatientMedicationUpdateComponent";
import { patientMedicationUpdateComponentValidator } from "./patientMedicationUpdateComponent.validator";
import { toCurrentTimeZone } from '../../../timezones/helpers/timezone';

interface PatientMedicationUpdateComponentState extends IErrorState {
    model: PatientMedicationModel;
}

const getInitialState = () => ({
    model: newPatientMedicationModel(),
    errors: {}
});

export function useFacade(props: PatientMedicationUpdateProps): [
    PatientMedicationUpdateComponentState,
    Function,
    Function,
    Function
] {

    const [state, setState] = useState(getInitialState() as PatientMedicationUpdateComponentState);

    const handleChanges = (field: string, value: string) => {
        patientMedicationUpdateComponentValidator.validateAndSetState(state, setState, field, value);

        const model = {...state.model};
        model[field] = value
        setState({...state, model: model})
    }

    const handleSave = (patientId: number) => {
        patientMedicationUpdateComponentValidator.validateObjectAndSetState(state, setState, state.model);
        if (!patientMedicationUpdateComponentValidator.stateIsValid(state)) {
            return;
        }

        setState(state => ({
            ...state, 
            ...Object.assign({}, getInitialState())
        }));
        props.handleClose();
        const model = {...state.model};
        model.startDate = state.model.startDate ? toCurrentTimeZone(state.model.startDate) : null;

        const service = props.patientId ? medicationsService : patientsMedicationsService;
        const action = model.id ? service.edit : service.create;
        action(model, patientId).subscribe();
    }

    const handleClose = () => {
        setState(state => ({
            ...state,
            ...getInitialState()
        }))

        props.handleClose();
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<PatientMedicationModel>(patientMedicationsQuery.editModel$, editModel => {
                setState(state => ({...state, model: editModel }));
            })
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [
        state,
        handleClose,
        handleChanges,
        handleSave
    ];
}