import { GeneralValidator } from "../../../common/validation/general-validator";
import {
  notWhitespaceValidationRule,
  requiredValidationRule,
} from "../../../common/validation/general-validation-rules";

export const createCommonOrderValidator = new GeneralValidator({
  'company': [notWhitespaceValidationRule(), requiredValidationRule()],
  'name': [notWhitespaceValidationRule(), requiredValidationRule()],
  'cost': [notWhitespaceValidationRule()],
});