import React, { useState } from "react";
import Recaptcha from 'react-google-invisible-recaptcha';
import { SITE_KEY } from "../../../../config";
import { snackService } from "../../../common/snack/state";
import { IErrorState } from "../../../common/validation/error-state";
import { VerifyProfileModel } from "../../models/auth.models";
import { authService } from "../../services/auth.service";
import { VerifyProfileComponentValidator } from "./verifyProfilePageValidator.validator";

interface VerifyProfileComponentState extends IErrorState {
    model: VerifyProfileModel;
    inProgress: boolean;
}

export function useFacade(handleVerificationSubmit: () => void): [
    VerifyProfileComponentState,
    () => JSX.Element,
    (field: string, value: string) => void,
    (event: React.MouseEvent<HTMLButtonElement>) => void] {

    const _siteKey = SITE_KEY;
    let _recaptcha = undefined;

    const [state, setState] = useState({
        model: {
            code: ''
        },
        inProgress: false,
        errors: {}
    } as VerifyProfileComponentState);

    const handleChanges = (field: string, value: string) => {
        state.model[field] = value;
        setState({
            ...state,
            model: { ...state.model }
        });

        VerifyProfileComponentValidator.validateAndSetState(state, setState, field, value);
    }

    const renderRecaptcha = () => {
        return (
            <Recaptcha
                ref={ref => _recaptcha = ref}
                sitekey={_siteKey}
                onResolved={(token) => {
                    state.model.antiSpamToken = token;
                    const cb = () => setState(state => ({ ...state, inProgress: false }));
                    authService.verify(state.model).subscribe(() => {
                        cb();
                        handleVerificationSubmit();
                    }, cb);
                }}
            />
        )
    }

    const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        VerifyProfileComponentValidator.validateObjectAndSetState(state, setState, state.model);
        if (!VerifyProfileComponentValidator.stateIsValid(state)) {
            snackService.error('Code is not valid.');
        }
        setState(state => ({ ...state, inProgress: true }));

        _recaptcha.execute();
    }

    return [
        state,
        renderRecaptcha,
        handleChanges,
        handleSubmit
    ];
}