import Axios from 'axios-observable';
import { Observable } from "rxjs";
import { authHeader } from "../../common/helpers/auth-header";
import { snackService } from "../../common/snack/state";
import {productsStore, ProductsStore} from "../stores/productsStore/products.store";
import {ProductModel} from "../models/product.model";

/**
 * Provides method for working with products
 */
export class ProductsService {
    private defaultUrl = process.env.REACT_APP_API_URL;

    constructor(private store: ProductsStore) {
    }

    public getProducts(): Observable<ProductModel[]> {
        const config = {headers: authHeader()};
        const url = `${this.defaultUrl}Products`;

        return new Observable<ProductModel[]>(observer => {
            Axios.get<Array<ProductModel>>(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({products: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                    }
                );
        });
    }
}

export const productsService = new ProductsService(productsStore);
