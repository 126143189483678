import { Box, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import "../../inputs/helpers/labInputs.extensions";
import { LabInputNames } from "../../inputs/constants/inputs.constants";
import { CircleDiagramComponent } from '../components/circleDiagramComponent/CircleDiagramComponent';
import { ColorDescriptionComponent } from '../components/colorDescriptionComponent/colorDescriptionComponent';
import './healthReport.scss';
import { HealthReportBasePage, HealthReportMobileBasePage } from "./HealthReportBasePage";
import { HealthReportPageProps } from "./healthReportPageProps";
import { HealthReportSections, HealthReportMode } from "../models/healthReport.models";
import { DnaTableComponent } from "../components/dnaTableComponent/DnaTableComponent";
import { LabResultComponent } from "../components/labResultComponent/LabResultComponent";
import { riskColorMap } from "../constants/healthReport.constants";
import { renderScaleChartComponent } from "../helpers/renderScaleChartComponent";
import { useStyles } from './healthReport.styles';
import { LabGroup } from "../../inputs/models/input.models";
import commonUseStyles from "../../common/styles/common.styles";
import clsx from "clsx";

export const HealthReportDietAndNutrition1Page: React.FC<HealthReportPageProps> = (props: HealthReportPageProps) => {
    const {
        report,
        pageNumber,
        practice
    } = props;

    const classes = useStyles();
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const labValues = report.labReport.values;

    const cycleDiagramData = [
        {
            name: "CARBS",
            value: report.dietAndNutritionReport.recommendedMacros.carb,
            color: "#80b31b"
        },
        {
            name: "FATS",
            value: report.dietAndNutritionReport.recommendedMacros.fat,
            color: "#eed511"
        },
        {
            name: "PROTEINS",
            value: report.dietAndNutritionReport.recommendedMacros.pro,
            color: "#129ad4"
        }
    ];

    const apoeAccuracyScore = report.dietAndNutritionReport?.apoeAccuracyScore;

    return (
        props.mode !== HealthReportMode.PrintMode && isSmallScreen ? (
            <HealthReportMobileBasePage section={HealthReportSections.DietAndNutrition} pageNumber={pageNumber} practice={practice}>
                <Box>
                    <Box px={2}>
                        <Box>
                            <span className={classes.reportTitle}>Macronutrients</span>
                        </Box>
                        <>
                            <Box mt="10px" fontSize="14px" lineHeight="normal">
                                <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeMediumReport, commonClasses.textRegularReport)}>Some recommendations are true for all, independent of your specific genomics:</span>
                            </Box>
                            <Box mt="20px" fontSize="14px" lineHeight="22px">
                                <span>-&nbsp;&nbsp;&nbsp;Eat mostly plants. Meat and grains should always be considered side items on your plate.</span><br />
                                <span>-&nbsp;&nbsp;&nbsp;Do not eat processed food. It’s not real food if your great grandmother wouldn’t recognize it.</span><br />
                                <span>-&nbsp;&nbsp;&nbsp;Avoid vegetable oils (soybean, canola). These cause inflammation and are unhealthy forms of fat. Use extra virgin olive oil or avocado oil instead.</span><br />
                                <span>-&nbsp;&nbsp;&nbsp;Do not eat all of the time. A daily 12-hour fasting window is considered the minimum. Fasting improves metabolic flexibility and allows for cellular regeneration.</span>
                            </Box>
                        </>

                    </Box>
                    <Box px={2}>
                        <Box mt="20px">
                            <span className={classes.reportTitle}>SNPS</span>
                        </Box>
                        <Box mt="10px">
                            <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeMediumReport, commonClasses.textRegularReport)}>  Your genetic blueprint (single nucleotide polymorphisms)</span>
                        </Box>
                        <Box textAlign="center" mt="20px">
                            <span className={classes.reportTitle}>Your Diet and Nutrition</span>
                        </Box>
                        <Box textAlign="center" mt="10px">
                            <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeMediumReport, commonClasses.textRegularReport)}>Your genetic predisposition to fat, carbs, and saturated fats:</span>
                        </Box>
                        <Box display="flex" justifyContent="center" mt="20px">
                            <Grid container>
                                <Grid item xs={1} />
                                <Grid item xs={5}>
                                    <Box>
                                        <ColorDescriptionComponent
                                            color="#4c9000"
                                            description="0 Risk Alleles"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={1} />
                                <Grid item xs={5}>
                                    <Box>
                                        <ColorDescriptionComponent
                                            color="#eed511"
                                            description="1 Risk Allele"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={1} />
                                <Grid item xs={5}>
                                    <Box>
                                        <ColorDescriptionComponent
                                            color="#ff9100"
                                            description="2 Risk Alleles"
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={1} />
                                <Grid item xs={5}>
                                    <Box>
                                        <ColorDescriptionComponent
                                            color="#b9b9b9"
                                            description="Missing SNP"
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box display="flex" justifyContent="center" mt="10px">
                            <Grid container>
                                <Grid item xs={12}>
                                    <Box>
                                        <Box>
                                            <Box width={1} display="flex" justifyContent="center">
                                                {renderScaleChartComponent(report.dietAndNutritionReport.fatIntoleranceScore)}
                                            </Box>
                                        </Box>
                                        <Box display="flex" justifyContent="center" mt={2}>
                                            <span className="text-medium">
                                                Fat Intolerance
                                            </span>
                                        </Box>
                                        <Box mt="10px">
                                            <DnaTableComponent
                                                dna={report.dietAndNutritionReport.fatIntoleranceScore.dna}
                                                colorMap={riskColorMap}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box>
                                        <Box>
                                            <Box width={1} display="flex" justifyContent="center">
                                                {renderScaleChartComponent(report.dietAndNutritionReport.carbonIntoleranceScore)}
                                            </Box>
                                        </Box>
                                        <Box display="flex" justifyContent="center" mt={2}>
                                            <span className="text-medium">
                                                Carb Intolerance
                                            </span>
                                        </Box>
                                        <Box mt="10px">
                                            <DnaTableComponent
                                                dna={report.dietAndNutritionReport.carbonIntoleranceScore.dna}
                                                colorMap={riskColorMap}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box>
                                        <Box>
                                            <Box width={1} display="flex" justifyContent="center">
                                                {renderScaleChartComponent(report.dietAndNutritionReport.satFatIntoleranceScore)}
                                            </Box>
                                        </Box>
                                        <Box display="flex" justifyContent="center" mt={2}>
                                            <span className="text-medium">
                                                Sat Fat Intolerance
                                            </span>
                                        </Box>
                                        <Box mt="10px">
                                            <DnaTableComponent
                                                dna={report.dietAndNutritionReport.satFatIntoleranceScore.dna}
                                                colorMap={riskColorMap}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box px={2} pt="20px">
                        <Grid container>
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="flex-start" mt={0.5}>
                                    <Box>
                                        <span className={classes.reportSubTitle}>
                                            Your APOE status:
                                        </span>
                                    </Box>
                                    <Box ml={3} display="flex" alignItems="center">
                                        <Box className={classes.reportSubTitle} mr={0.5}>
                                            Apo-{apoeAccuracyScore?.apoe}
                                        </Box>
                                        <Box className={clsx(commonClasses.size16, commonClasses.textRegular)}>
                                            {/* ({apoeAccuracyScore?.scorePercent}%) */}
                                            <span className={commonClasses.colorBad}>* </span>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box>
                                    <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeSmallReport, commonClasses.textRegularReport)}>
                                        Derived from a polygenic algorithm and contributes to all three nutrition scores.
                                    </span>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box px={2} mt="20px">
                        <Grid container>
                            <Grid item xs={12}>
                                <Box className="text-light-medium">
                                    <Box>
                                        <span className={classes.reportSubTitle}>Carb Related Labs</span>
                                    </Box>
                                    <Box pt={1}>
                                        <LabResultComponent label="Hgb-A1c" value={labValues.getLabReportValue(LabInputNames.HgbA1C)} />
                                        <LabResultComponent label="Fasting Glucose" value={labValues.getLabReportValue(LabInputNames.FastingGlucose)} />
                                        <LabResultComponent label="HOMA-IR" value={{
                                            name: LabInputNames.Homir,
                                            group: LabGroup.InsulinResistanceAndMetabolism,
                                            date: new Date(),
                                            value: report.alphaReport.homir,
                                            notification: null,
                                            range: null
                                        }} />
                                        <LabResultComponent label="TRIG:HDL" value={{
                                            name: LabInputNames.TrigHdl,
                                            group: LabGroup.InsulinResistanceAndMetabolism,
                                            date: new Date(),
                                            value: report.alphaReport.hdlTrigRatio,
                                            notification: null,
                                            range: null
                                        }} />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box className="text-light-medium" mt="20px">
                                    <Box>
                                        <span className={classes.reportSubTitle}>Lipid Panel</span>
                                    </Box>
                                    <Box pt={1}>
                                        <LabResultComponent label="Total Cholesterol" value={labValues.getLabReportValue(LabInputNames.TotalCholesterol)} />
                                        <LabResultComponent label="HDL" value={labValues.getLabReportValue(LabInputNames.HdlCholesterol)} />
                                        <LabResultComponent label="LDL" value={labValues.getLabReportValue(LabInputNames.LdlCholesterol)} />
                                        <LabResultComponent label="TRIG" value={labValues.getLabReportValue(LabInputNames.Triglycerides)} />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="center" mt="20px">
                                    <Box display="flex" justifyContent="center" alignItems="flex-end">
                                        <CircleDiagramComponent data={cycleDiagramData} radius={80} percentColor="black" />
                                    </Box>
                                    <Box display="flex" alignItems="center" ml={2}>
                                        <Box>
                                            <Box>
                                                <ColorDescriptionComponent
                                                    color="#80b31b"
                                                    description="Carbs"
                                                />
                                            </Box>
                                            <Box>
                                                <ColorDescriptionComponent
                                                    color="#eed511"
                                                    description="Fats"
                                                />
                                            </Box>
                                            <Box>
                                                <ColorDescriptionComponent
                                                    color="#129ad4"
                                                    description="Proteins"
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box mt="10px" lineHeight="16px" display="flex"><Box mr={0.5} className={commonClasses.colorBad}>* </Box><span className={clsx(commonClasses.size14, commonClasses.textRegular)}>Your APOE result is discussed in detail later in the report.</span></Box>
                    </Box>
                </Box>
            </HealthReportMobileBasePage>
        ) : (
            <HealthReportBasePage section={HealthReportSections.DietAndNutrition} pageNumber={pageNumber} practice={practice}>
                <Box>
                    <Box px={15} pt={5} pb={0}>
                        <Box>
                            <span className={classes.reportTitle}>Macronutrients</span>
                        </Box>
                        <>
                            <Box>
                                <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeMediumReport, commonClasses.textMediumReport)}>Some recommendations are true for all, independent of your specific genomics:</span>
                            </Box>
                            <Box mt={1}>
                                <span>-&nbsp;&nbsp;&nbsp;Eat mostly plants. Meat and grains should always be considered side items on your plate.</span><br />
                                <span>-&nbsp;&nbsp;&nbsp;Do not eat processed food. It’s not real food if your great grandmother wouldn’t recognize it.</span><br />
                                <span>-&nbsp;&nbsp;&nbsp;Avoid vegetable oils (soybean, canola). These cause inflammation and are unhealthy forms of fat. Use extra virgin olive oil or avocado oil instead.</span><br />
                                <span>-&nbsp;&nbsp;&nbsp;Do not eat all of the time. A daily 12-hour fasting window is considered the minimum. Fasting improves metabolic flexibility and allows for cellular regeneration.</span>
                            </Box>
                        </>

                    </Box>
                    <Box pl={15} pr={15}>
                        <Box mt={1}>
                            <span className={classes.reportTitle}>SNPS</span>
                            <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeMediumReport, commonClasses.textMediumReport)}>  Your genetic blueprint (single nucleotide polymorphisms)</span>
                        </Box>
                        <Box display="flex" justifyContent="center" mt={2}>
                            <span className={classes.reportTitle}>Your Diet and Nutrition</span>
                        </Box>
                        <Box display="flex" justifyContent="center">
                            <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeMediumReport, commonClasses.textMediumReport)}>Your genetic predisposition to fat, carbs, and saturated fats:</span>
                        </Box>
                        <Box display="flex" justifyContent="center">
                            <Grid container>
                                <Grid item xs={4} md={4} lg={4}>
                                    <Box>
                                        <Box display="flex" justifyContent="center">
                                            <Box width={1}>
                                                {renderScaleChartComponent(report.dietAndNutritionReport.fatIntoleranceScore)}
                                            </Box>
                                        </Box>
                                        <Box display="flex" justifyContent="center" mt={2}>
                                            <span className="text-medium">
                                                Fat Intolerance
                                            </span>
                                        </Box>
                                        <Box mt={2} px={7}>
                                            <DnaTableComponent
                                                dna={report.dietAndNutritionReport.fatIntoleranceScore.dna}
                                                colorMap={riskColorMap}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={4} md={4} lg={4}>
                                    <Box>
                                        <Box display="flex">
                                            <Box width={1}>
                                                {renderScaleChartComponent(report.dietAndNutritionReport.carbonIntoleranceScore)}
                                            </Box>
                                        </Box>
                                        <Box display="flex" justifyContent="center" mt={2}>
                                            <span className="text-medium">
                                                Carb Intolerance
                                            </span>
                                        </Box>
                                        <Box mt={2} px={7}>
                                            <DnaTableComponent
                                                dna={report.dietAndNutritionReport.carbonIntoleranceScore.dna}
                                                colorMap={riskColorMap}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={4} md={4} lg={4}>
                                    <Box>
                                        <Box display="flex" justifyContent="center">
                                            <Box width={1}>
                                                {renderScaleChartComponent(report.dietAndNutritionReport.satFatIntoleranceScore)}
                                            </Box>
                                        </Box>
                                        <Box display="flex" justifyContent="center" mt={2}>
                                            <span className="text-medium">
                                                Sat Fat Intolerance
                                            </span>
                                        </Box>
                                        <Box mt={2} px={7}>
                                            <DnaTableComponent
                                                dna={report.dietAndNutritionReport.satFatIntoleranceScore.dna}
                                                colorMap={riskColorMap}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box pr={15} pl={15} pt={0.5}>
                        <Grid container>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={2}>
                                <Box>
                                    <ColorDescriptionComponent
                                        color="#4c9000"
                                        description="0 Risk Alleles"
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={2}>
                                <Box>
                                    <ColorDescriptionComponent
                                        color="#eed511"
                                        description="1 Risk Allele"
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={2}>
                                <Box>
                                    <ColorDescriptionComponent
                                        color="#ff9100"
                                        description="2 Risk Alleles"
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={2}>
                                <Box>
                                    <ColorDescriptionComponent
                                        color="#b9b9b9"
                                        description="Missing SNP"
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="flex-start" mt={0.5}>
                                    <Box>
                                        <span className={classes.reportSubTitle}>
                                            Your APOE status:
                                        </span>
                                    </Box>
                                    <Box ml={3} display="flex" alignItems="center">
                                        <Box className={classes.reportSubTitle} mr={0.5}>
                                            Apo-{apoeAccuracyScore?.apoe}
                                        </Box>
                                        <Box className={clsx(commonClasses.size16, commonClasses.textRegular)}>
                                            {/* ({apoeAccuracyScore?.scorePercent}%) */}
                                            <span className={commonClasses.colorBad}>* </span>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box>
                                    <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeSmallReport)}>
                                        Derived from a polygenic algorithm and contributes to all three nutrition scores.
                                    </span>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box pr={15} pl={15} mt={0.5}>
                        <Grid container>
                            <Grid item xs={4}>
                                <Box className="text-light-medium" px={5}>
                                    <Box>
                                        <span className={classes.reportSubTitle}>Carb Related Labs</span>
                                    </Box>
                                    <Box>
                                        <LabResultComponent label="Hgb-A1c" value={labValues.getLabReportValue(LabInputNames.HgbA1C)} />
                                        <LabResultComponent label="Fasting Glucose" value={labValues.getLabReportValue(LabInputNames.FastingGlucose)} />
                                        <LabResultComponent label="HOMA-IR" value={{
                                            name: LabInputNames.Homir,
                                            group: LabGroup.InsulinResistanceAndMetabolism,
                                            date: new Date(),
                                            value: report.alphaReport.homir,
                                            notification: null,
                                            range: null
                                        }} />
                                        <LabResultComponent label="TRIG:HDL" value={{
                                            name: LabInputNames.TrigHdl,
                                            group: LabGroup.InsulinResistanceAndMetabolism,
                                            date: new Date(),
                                            value: report.alphaReport.hdlTrigRatio,
                                            notification: null,
                                            range: null
                                        }} />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box className="text-light-medium" px={5}>
                                    <Box>
                                        <span className={classes.reportSubTitle}>Lipid Panel</span>
                                    </Box>
                                    <Box>
                                        <LabResultComponent label="Total Cholesterol" value={labValues.getLabReportValue(LabInputNames.TotalCholesterol)} />
                                        <LabResultComponent label="HDL" value={labValues.getLabReportValue(LabInputNames.HdlCholesterol)} />
                                        <LabResultComponent label="LDL" value={labValues.getLabReportValue(LabInputNames.LdlCholesterol)} />
                                        <LabResultComponent label="TRIG" value={labValues.getLabReportValue(LabInputNames.Triglycerides)} />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box display="flex" justifyContent="flex-start">
                                    <Box display="flex" justifyContent="center" alignItems="flex-end">
                                        <CircleDiagramComponent data={cycleDiagramData} radius={80} percentColor="black" />
                                    </Box>
                                    <Box display="flex" alignItems="center" ml={2}>
                                        <Box>
                                            <Box>
                                                <ColorDescriptionComponent
                                                    color="#80b31b"
                                                    description="Carbs"
                                                />
                                            </Box>
                                            <Box>
                                                <ColorDescriptionComponent
                                                    color="#eed511"
                                                    description="Fats"
                                                />
                                            </Box>
                                            <Box>
                                                <ColorDescriptionComponent
                                                    color="#129ad4"
                                                    description="Proteins"
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box mt={2} lineHeight="16px" display="flex"><Box mr={0.5} className={commonClasses.colorBad}>* </Box><span className={clsx(commonClasses.size14, commonClasses.textRegular)}>Your APOE result is discussed in detail later in the report.</span></Box>
                    </Box>
                </Box>
            </HealthReportBasePage>
        )
    )
}
