import {
    Box,
    createStyles,
    Dialog,
    DialogContent,
    IconButton,
    Theme,
    Typography,
    WithStyles,
    withStyles,
    TextField,
    Divider,
    Button
} from '@material-ui/core';
import React from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { colors } from "../../../common/constants/colors";
import { SignOffEmployeeType, useFacade } from "./signOffDialogComponent.hooks";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import commonUseStyles from "../../../common/styles/common.styles";
import { WildHealthRadio } from "../../../common/components/WildHealthRadio";
import { useStyles } from "./signOffDialogComponent.styles";
import { Autocomplete } from "@material-ui/lab";
import { EmployeeShortModel } from "../../../employee/models/employee.models";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2)
        },
        title: {
            color: colors.systemBlack,
            textTransform: 'none',
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    }
    );

    interface SignOffDialogProps {
        isOrderCreate?: boolean;
    }

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose?: () => void;
}

export const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;

    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6" className={classes.title}>{children}</Typography>
            {
                onClose &&
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            }
        </MuiDialogTitle>
    );
});

export const SignOffDialogComponent: React.FC<SignOffDialogProps> = (props: SignOffDialogProps) => {
    const {isOrderCreate = false} = props
    const [
        {
            isOpen,
            note,
            selectedEmployeeType,
            assignedEmployees,
            otherEmployees,
            selectedEmployeeId
        },
        handleSelectedEmployeeType,
        handleEmployeeSelect,
        handleNotesChanges,
        handleClose,
        handleSubmit,
        handleKeyDown
    ] = useFacade();

    const commonClasses = commonUseStyles();
    const classes = useStyles();

    return (
        <Dialog
            scroll='paper'
            fullWidth
            maxWidth='sm'
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <Box mx={3}>
                <DialogTitle id="dialog-title" onClose={handleClose}><span>Request Approval</span></DialogTitle>
            </Box>

            <DialogContent>
                <Box px={2}>
                    <Box display="flex" alignItems="center">
                        <Box>
                            <WildHealthRadio
                                checked={selectedEmployeeType === SignOffEmployeeType.Assigned}
                                onChange={() => handleSelectedEmployeeType(SignOffEmployeeType.Assigned)}
                            />
                        </Box>
                        <Box>
                            <span className={commonClasses.inputLabel}>
                                Please choose a user to request approval.
                            </span>
                        </Box>
                    </Box>
                    <Box display="flex">
                        {
                            assignedEmployees?.length > 0 &&
                            assignedEmployees.map((employee, index) => {
                                return (
                                    <Box key={index} mr={1}>
                                        <Button
                                            disabled={selectedEmployeeType !== SignOffEmployeeType.Assigned}
                                            onClick={() => handleEmployeeSelect(employee.id)}
                                            className={selectedEmployeeId === employee.id
                                                ? classes.selectedEmployeeButton
                                                : classes.selectEmployeeButton}>
                                            <span>
                                                {
                                                    employee.firstName + ' ' + employee.lastName
                                                }
                                            </span>
                                        </Button>
                                    </Box>
                                )
                            })
                        }
                    </Box>
                    <Box mt={2} display="flex" alignItems="center">
                        <Box>
                            <WildHealthRadio
                                checked={selectedEmployeeType === SignOffEmployeeType.Other}
                                onChange={() => handleSelectedEmployeeType(SignOffEmployeeType.Other)}
                            />
                        </Box>
                        <Box>
                            <span className={commonClasses.inputLabel}>
                                Or add other employee within the practice.
                            </span>
                        </Box>
                    </Box>

                    <Box pb={1}>
                        <Autocomplete
                            size="small"
                            fullWidth
                            disablePortal
                            options={otherEmployees}
                            value={otherEmployees.find(x => x.id === selectedEmployeeId)}
                            onChange={(event, employee: EmployeeShortModel) => handleEmployeeSelect(employee.id)}
                            disabled={selectedEmployeeType !== SignOffEmployeeType.Other || !otherEmployees.length}
                            getOptionLabel={(employee) => employee.firstName + ' ' + employee.lastName}
                            renderInput={(params) => <TextField size='small' variant="outlined" {...params} />}
                        />
                    </Box>
                </Box>
            </DialogContent>

            <Divider />

            {!isOrderCreate && <DialogContent>
                <Box py={1} px={2} mt={1}>
                    <Box pb={1}>
                        <span className={commonClasses.inputLabel}>
                            Additional Note (Optional)
                        </span>
                    </Box>
                    <Box>
                        <TextField
                            id="sign-off-additional-note"
                            onChange={(event) => handleNotesChanges(event.target.value)}
                            onKeyDown={(e) => handleKeyDown(e)}
                            value={note}
                            fullWidth
                            multiline
                            variant="outlined"
                            maxRows={5}
                            minRows={5}
                        />
                    </Box>
                </Box>
            </DialogContent>}
            <Box display="flex" justifyContent="flex-end" px={5} py={3}>
                <Box>
                    <WildHealthButton id="cancel-sign-off" size="large" color="tertiary" onClick={() => handleClose()}>
                        Cancel
                    </WildHealthButton>
                </Box>
                <Box ml={1}>
                    <WildHealthButton disabled={!selectedEmployeeId} id="submit-sign-off" size="large"
                        onClick={() => handleSubmit()}>
                        Request Approval
                    </WildHealthButton>
                </Box>
            </Box>
        </Dialog>
    );
}
