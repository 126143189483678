import {Box, Divider, useMediaQuery, useTheme} from "@material-ui/core";
import React from "react";
import {WildHealthExternalLinkButton} from "../../../common/components/WildHealthLinkButton";
import {WildHealthPlaceHolder} from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import {PatientMedicationModel} from "../../../patientSupplements/models/patientMedicationModel";
import {PatientSupplementModel, SupplementSource} from "../../../patientSupplements/models/patientSupplementModel";
import commonUseStyles from "../../../common/styles/common.styles";
import clsx from 'clsx';

interface MedicationsSupplementsComponentProps {
    medications: PatientMedicationModel[];
    supplements: PatientSupplementModel[];
}

export const MedicationsSupplementsComponent: React.FC<MedicationsSupplementsComponentProps> = (props: MedicationsSupplementsComponentProps) => {
    const { medications, supplements } = props;
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const renderContent = (collection: (PatientMedicationModel | PatientSupplementModel)[], type: string) => {
        return collection.map((item, index) => {
            const purchaseLink = (item as PatientSupplementModel).purchaseLink;
            const source = (item as PatientSupplementModel).source;

            if (isSmallScreen) {
                return (
                    <Box key={index} mb="20px">
                        <Box mb="10px" className={clsx(commonClasses.size16, commonClasses.textMedium, commonClasses.colorGray1)}>{`${type} ${index + 1}:`}</Box>
                        <Box mb="10px" className={clsx(commonClasses.size16, commonClasses.textMedium, commonClasses.colorBlack)}>{item.name}</Box>
                        <Box mb="10px" className={clsx(commonClasses.size16, commonClasses.textMedium, commonClasses.colorBlack)}>{item.dosage}</Box>
                        <WildHealthExternalLinkButton id={`medications-supplements-link-purchase-${item.id}`} link={purchaseLink} children={<span>
                            {
                                source === SupplementSource.Fullscript
                                    ? 'Purchase via Fullscript'
                                    : 'Purchase here'
                            }
                        </span>} />
                    </Box>
                )
            }

            return (
                <Box key={index} my={1} display="flex">
                    <Box width={0.25}>
                        <pre style={{ whiteSpace: 'pre-wrap' }} className="regular-size">
                            {`${index + 1}. ${item.name}`}
                        </pre>
                    </Box>
                    <Box width={0.25}>
                        <pre style={{ whiteSpace: 'pre-wrap' }} className="regular-size">
                            {item.dosage}
                        </pre>
                    </Box>
                    <Box width={0.50}>
                        <WildHealthExternalLinkButton id={`medications-supplements-link-purchase-${item.id}`} link={purchaseLink} children={<span>
                            {
                                source === SupplementSource.Fullscript
                                    ? 'Purchase via Fullscript'
                                    : 'Purchase here'
                            }
                        </span>} />
                    </Box>
                </Box>
            )
        })
    }

    return (
        <Box mb={2}>
            <Box>
                <span className={isSmallScreen ? clsx(commonClasses.size16, commonClasses.textMedium, commonClasses.colorBlack) : "size-1_5 text-medium uppercase"}>Medications</span>
            </Box>
            {isSmallScreen && <Box py={2}>
                <Divider className={commonClasses.renderDivider} />
            </Box>}
            <Box mt={!isSmallScreen && 1} mb={3}>
                {
                    !medications || !medications.length
                        ? <WildHealthPlaceHolder message="No medications" />
                        : renderContent(medications, "Medication")
                }
            </Box>
            <Box>
                <span className={isSmallScreen ? clsx(commonClasses.size16, commonClasses.textMedium, commonClasses.colorBlack) : "size-1_5 text-medium uppercase"}>Supplements</span>
            </Box>
            {isSmallScreen && <Box py={2}>
                <Divider className={commonClasses.renderDivider} />
            </Box>}
            <Box mt={!isSmallScreen && 3}>
                {
                    !supplements || !supplements.length
                        ? <WildHealthPlaceHolder message="No supplements" />
                        : renderContent(supplements, "Supplement")
                }
            </Box>
        </Box>
    )
}