import React from 'react';
import {
    FormControl,
    MenuItem,
    Select
} from '@material-ui/core';

interface SelectPageComponentProps {
    pageSize: number;
    pageSizes: number[];
    handlePageSizeSelect: (pageSize: number) => void;
}

export const SelectPageComponent: React.FC<SelectPageComponentProps> = (props: SelectPageComponentProps) => {
    const {
        pageSize,
        pageSizes,
        handlePageSizeSelect
    } = props;

    return (
        <>
            <FormControl variant="outlined" size="small" fullWidth  style={{maxHeight: "2rem"}}>
                <Select style={{background: '#FFF'}}
                        id="demo-simple-select-outlined"
                        value={pageSize}
                        onChange={(v) => handlePageSizeSelect(Number(v.target.value))}>
                    {

                        pageSizes.map(size => {
                            return(<MenuItem key={size} value={size}>{size}</MenuItem>)
                        })
                    }
                </Select>
            </FormControl>
        </>
    )
};