import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import { Subscription } from "rxjs";
import { navigationService } from "../../../../services/navigation.service";
import { onEmit } from "../../../common/helpers/on-emit";
import { PageSliderNavigationContext } from "../../../common/models/navigation.models";
import { PaymentPlanModel } from "../../models/paymentPlan.models";
import { paymentPlansQuery, PaymentSteps } from "../../stores/paymentPlansStore";
import { authQuery } from "../../../auth/stores/auth";
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isFeatureFlag } from '../../../common/components/featureFlags/featureFlags';
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import { addressService } from "../../../account/services/address.service";
import {
    FinishCheckoutPreauthorizeComponent
} from "../../components/finishCheckoutPreauthorizeComponent/FinishCheckoutPreauthorizeComponent";
import {preauthorizeRequestService} from "../../services/preauthorizeRequest.service";
import {paymentPlansService} from "../../services/paymentPlans.service";
import {PaymentPeriodModel} from "../../models/paymentPeriod.models";
import {PaymentPriceModel} from "../../models/paymentPrice.models";
import {EmployerProductModel} from "../../models/employerProduct.model";
import {PreauthorizeRequestModel} from "../../models/preauthorizeRequest.model";

interface PreauthorizeSignUpState {
    step: PaymentSteps;
    progress: number;
    progressBarOpened: boolean;
    bannerHeight: number;
    preauthorizeRequest: PreauthorizeRequestModel;
    paymentPlan: PaymentPlanModel;
    paymentPeriod: PaymentPeriodModel;
    paymentPrice: PaymentPriceModel;
    employer: EmployerProductModel;
    isLoading: boolean;
}

interface ScrollElement {
    current: HTMLDivElement
}

/**
 * Custom Hook to manage a view Model for GetStarted page view components
 */
export function useFacade(requestToken: string): [JSX.Element, PreauthorizeSignUpState, boolean, ScrollElement] {
    const history = useHistory();
    const featureFlags = useFlags();
    const OnboardingRedesignV2 = isFeatureFlag(featureFlags, FeatureFlag.OnboardingRedesignV2);

    const [state, setState] = useState({
        selectedPlan: null,
        selectedPeriod: null,
        step: PaymentSteps.Checkout,
        progress: 0,
        progressBarOpened: false,
        bannerHeight: 0,
        preauthorizeRequest: null,
        paymentPlan: null,
        paymentPeriod: null,
        paymentPrice: null,
        employer: null,
        isLoading: true,
    } as PreauthorizeSignUpState);

    const prevScrollY = useRef<HTMLHeadingElement>(null);

    const navigationContext = {
        nextButton: "Next",
        backButton: "Back",
        canGoNext: () => false,
        canGoBack: () => false,
        goNext: () => {},
        goBack: () => {}
    } as PageSliderNavigationContext

    const checkoutContent = () => {
        return <FinishCheckoutPreauthorizeComponent
            paymentPlan={state.paymentPlan}
            paymentPrice={state.paymentPrice}
            paymentPeriod={state.paymentPeriod}
            navigationContext={navigationContext}
            preauthorizeRequest={state.preauthorizeRequest}
            topBannerHeight={state.bannerHeight}
            handleSetProgress={(progress) => setState(state => ({ ...state, progress}))}
        />
    }

    const StepsContent = new Map<number, JSX.Element>([
        [PaymentSteps.Checkout, checkoutContent()]
    ]);

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<PaymentPlanModel[]>(paymentPlansQuery.regularPlans$, plans => setState(state => ({
                ...state,
                plans: plans
            }))),
            onEmit<PaymentPlanModel>(paymentPlansQuery.trialPlan$, trialPlan => setState(state => ({
                ...state,
                trialPlan: trialPlan
            })))
        ];

        addressService.getStates().subscribe();
        if (authQuery.isLoggedIn() && authQuery.isRegistrationCompleted()) {
            navigationService.toDashboard(history);
        } else {
            preauthorizeRequestService.getByToken(requestToken).subscribe(
                (request) => {
                    if (request.employerProductId) {
                        paymentPlansService.getEmployerProductById(request.employerProductId).subscribe(
                            (employer) => {
                                paymentPlansService.getById(request.paymentPlanId, authQuery.getCurrentPracticeId(), employer.key).subscribe(
                                    (paymentPlan) => {
                                        setState(state => ({
                                            ...state,
                                            isLoading: false,
                                            employer: employer,
                                            paymentPlan: paymentPlan,
                                            preauthorizeRequest: request,
                                            paymentPeriod: paymentPlan.periods.find(x => x.id === request.paymentPeriodId),
                                            paymentPrice: paymentPlan.periods.find(x => x.id === request.paymentPeriodId).prices.find(x => x.id === request.paymentPriceId)
                                        }))
                                    }
                                )
                            }
                        )
                    } else {
                        paymentPlansService.getById(request.paymentPlanId, authQuery.getCurrentPracticeId()).subscribe(
                            (paymentPlan) => {
                                setState(state => ({
                                    ...state,
                                    isLoading: false,
                                    paymentPlan: paymentPlan,
                                    preauthorizeRequest: request,
                                    paymentPeriod: paymentPlan.periods.find(x => x.id === request.paymentPeriodId),
                                    paymentPrice: paymentPlan.periods.find(x => x.id === request.paymentPeriodId).prices.find(x => x.id === request.paymentPriceId)
                                }))
                            }
                        )
                    }
                },
                () => {
                    navigationService.toGetStarted(history);
                }
            )
        }

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    useEffect(() => {
        navigationService.goUp();
    }, [state.step])

    useEffect(() => {
        function scrollEvent() {
            if (prevScrollY) {

                if (prevScrollY.current) {
                    let rect = prevScrollY.current.getBoundingClientRect()
                    setState(state => ({ ...state, bannerHeight: rect.bottom > 0 ? rect.bottom : 0 }))
                }
            }
        }

        if (prevScrollY && prevScrollY?.current) {
            let rect = prevScrollY.current.getBoundingClientRect()
            setState(state => ({ ...state, bannerHeight: rect.bottom > 0 ? rect.bottom : 0 }))
        }

        document.addEventListener('scroll', scrollEvent)

        return () => {
            document.removeEventListener('scroll', scrollEvent)
        }
    }, []);

    return [StepsContent.get(state.step), state, OnboardingRedesignV2, prevScrollY];
}
