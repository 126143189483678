import { AddressModel } from "../../common/models/address.models";
import { AntiSpamModel } from "../../common/models/antiSpam.model";
import { Gender, User } from "../../common/models/user.models";
import { EmployeeShortModel, FellowShortModel } from "../../employee/models/employee.models";
import { AgreementConfirmationModel, ConfirmAgreementModel } from "../../payment/models/agreement.model";
import { PatientSubscriptionModel, SubscriptionModel } from "../../payment/models/subscription.models";
import { LocationModel, PatientLocationModel } from "../../locations/models/locations.models";
import { PatientLeadSourceModel } from "../../leadSources/models/leadSource.models";
import { AuthModel } from "../../auth/models/auth.models";
import { PaymentPriceModel } from "../../payment/models/paymentPrice.models";
import { OrderType } from "../../orders/models/orders.models";
import { PatientAppointmentShortModel } from "../../appointments/models/appointments.models";
import { LastConversationModel } from "../../conversations/models/conversation.models";
import { PatientAddOnsModel } from "../../addons/models/patientAddOn.models";
import { GeneralInputsModel } from "../../inputs/models/input.models";
import { PatientSupplementModel } from "../../patientSupplements/models/patientSupplementModel";
import { PatientMedicationModel } from "../../patientSupplements/models/patientMedicationModel";
import { EngagementCriteriaType } from "../../widgets/components/myTasksWidgetComponent/constants/myTaskImages";
import {KeyValueModel} from "../../healthReport/models/healthReport.models";

export interface RegisterPatientModel extends AntiSpamModel {
    inviteCode: string;
    paymentPeriodId: number;
    paymentPriceId: number;
    agreements: ConfirmAgreementModel[];
    employeeId: number | null;
    linkedEmployeeId: number | null;
    founderId: number | null;
    leadSource: PatientLeadSourceModel;
    practiceId: number;
    paymentToken: string;
    email: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    birthday: Date;
    gender: Gender;
    password: string;
    confirmPassword: string;
    billingAddress: AddressModel;
    shippingAddress: AddressModel;
    addOnIds: number[];
    marketingSMS: boolean;
    employerProductKey: string;
    promoCode?: string;
}

export interface RegisterPreauthorizePatientModel extends AntiSpamModel {
    practiceId: number;
    preauthorizeRequestToken: string;
    email: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    birthday: Date;
    gender: Gender;
    password: string;
    confirmPassword: string;
    billingAddress: AddressModel;
    shippingAddress: AddressModel;
    agreements: ConfirmAgreementModel[];
    addOnIds: number[];
}

export interface CreatePatientModel {
    email: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    birthday: Date;
    gender: Gender;
    billingAddress: AddressModel;
    shippingAddress: AddressModel;
    paymentToken: string;
    paymentPeriodId: number;
    paymentPriceId: number;
    founderId: number | null;
    addOnIds: number[];
    marketingSMS: boolean;
}

export interface MyPatientsFilterModel {
    employeeId: number;
    lastMessageSentGreaterThanDaysAgo: number | null;
    lastCoachingVisitGreaterThanDaysAgo: number | null;
    lastAppointmentGreaterThanDaysAgo: number | null;
    planRenewalDateLessThanDaysFromToday: number | null;
    daysSinceIccWithoutImcScheduledFromToday: number | null;
    daysSinceSignUpWithoutIccScheduledFromToday: number | null;
    includesTags: string[];
    planName: string | null;
    hasFollowUpMedicalScheduled: boolean | null;
}

export interface PatientStartRegistrationModel {
    practiceId: number;
    fellowId: number;
    firstName: string;
    lastName: string;
    birthday: Date;
    phoneNumber: string;
    email: string;
    gender: Gender;
}

export interface SendPatientToWaitListModel extends AntiSpamModel {
    email: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    state: string;
    paymentPlanId: number;
    practiceId: number;
}

export interface TransferPatientToLocationModel {
    id: number;
    locationId: number;
}

export interface FellowshipNoteModel {
    patientId: number;
    note: string;
}

export interface CreatedPatientModel {
    id: number;
    universalId?: string;
    firstName: string;
    lastName: string;
    email: string;
    intakeId: string;
    authentication: AuthModel;
    agreements: AgreementConfirmationModel[];
}

export interface PatientOptionsModel {
    isFellow: boolean;
}


/**
 * Represents patient short model
 */
export interface PatientShortModel extends User {
    intakeId: string;
    createdAt: Date;
    onBoardingStatus: PatientOnBoardingStatus;
    employees: EmployeeShortModel[];
    subscription: SubscriptionModel;
    location: LocationModel;
    orderTypes: OrderType[];
}

/**
 * Represents submit patient model
 */
export interface SubmitPatientModel {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    birthday: Date;
    gender: Gender;
    phoneNumber: string;
    employeeIds: number[];
    billingAddress: AddressModel;
    shippingAddress: AddressModel;
    options: PatientOptionsModel;
    locationId: number;
}

/**
 * Represents patient model
 */
export interface PatientModel extends User {
    intakeId: string;
    locationId: number;
    createdAt: Date;
    onBoardingStatus: PatientOnBoardingStatus;
    journeyStatus: PatientJourneyStatus;
    dnaStatus: PatientDnaStatus;
    labsStatus: PatientLabsStatus;
    epigeneticStatus: PatientEpigeneticStatus;
    subscription: SubscriptionModel;
    assignedEmployees: EmployeeShortModel[];
    options: PatientOptionsModel;
    leadSource: PatientLeadSourceModel;
    generalInputs: GeneralInputsModel;
    supplements: PatientSupplementModel[];
    medications: PatientMedicationModel[];
    linkToPaymentVendor?: string;
    tags: PatientTagModel[];
    settings: KeyValueModel[];
    isMobileAppInstalled: boolean;
    isPremium: boolean;
}

/**
 * Represents employee patient model
 */
export interface MyPatientModel {
    patientId: number;
    email: string;
    firstName: string;
    lastName: string;
    startDate: Date;
    pod: PatientLocationModel;
    healthCoach: EmployeeCoachProviderShortModel;
    provider: EmployeeCoachProviderShortModel;
    activePlan: PatientSubscriptionModel;
    addOns: PatientAddOnsModel[];
    lastMessage: LastConversationModel;
    lastAppointment: PatientAppointmentShortModel;
    nextAppointment: PatientAppointmentShortModel;
    tags: PatientTagModel[];
    daysSinceHealthCoachAndNoImcScheduled: number;
    daysSinceSignUpAndNoIccScheduled: number;
    lastAppointmentCompletedHealthCoachDate: Date;
    lastAppointmentScheduledProviderDate: Date;
}

export interface EmployeeCoachProviderShortModel {
    name: string;
    hoverText: string;
}

export interface EmployeeViewModelShort {
    firstName: string;
    lastName: string;
}
export interface PatientViewModel {
    id: number;
    orderTypes: string[];
    lastName: string;
    name: string;
    plan: string;
    joinedPractice: number;
    providers: EmployeeViewModelShort[];
    coaches: EmployeeViewModelShort[];
    location: LocationModel;
    shippingAddress: AddressModel;
    paymentPrice: PaymentPriceModel;
}

export interface UpdatePatientModel extends SubmitPatientModel {
    leadSource: PatientLeadSourceModel;
}

export interface UpdatePatientStatusesModel {
    id: number,
    journeyStatus: PatientJourneyStatus,
    dNAStatus: PatientDnaStatus,
    labsStatus: PatientLabsStatus,
    epigeneticStatus: PatientEpigeneticStatus
}

export interface PracticumPatientModel {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    birthday: Date;
    phoneNumber: string;
    fellow: FellowShortModel;
    isEnrolled: boolean;
    createdAt: Date;
    registrationDate: Date;
    note?: string;
}
export interface AssignedPatientModel {
    patientId: number;
    email: string;
    firstName: string;
    lastName: string;
    startDate: Date;
    pod: PatientLocationModel;
    healthCoach: EmployeeCoachProviderShortModel;
    provider: EmployeeCoachProviderShortModel;
    activePlan: PatientSubscriptionModel;
}

export interface UnverifiedInsurancePatientModel {
    patientId: number;
    email: string;
    firstName: string;
    lastName: string;
}

export interface PatientTagModel {
    name: string;
    description: string;
    sentiment: TagSentiment;
}

export enum TagSentiment {
    Success = 5,
    Normal = 10,
    Warning = 15,
    Danger = 20
}

export enum PatientOnBoardingStatus {
    New = 5,
    Completed = 10
}

export enum PatientDnaStatus {
    NA = 0,
    Preparing = 1,
    InProgress = 5,
    Completed = 10,
    Failed = 15
}

export enum PatientLabsStatus {
    NA = 0,
    Preparing = 1,
    Ordered = 2,
    InProgress = 5,
    Resulted = 10
}

export enum PatientEpigeneticStatus {
    NA = 0,
    Preparing = 1,
    InProgress = 5,
    Completed = 10,
    Failed = 15
}

export enum PatientJourneyStatus {
    Other = 1,
    New = 2,
    InitialCoachingCallScheduled = 4,
    InitialCoachingCallCompleted = 8,
    LabsPending = 16,
    ReportSent = 32,
    RepeatLabsOrdered = 64,
    ScheduleFUCoachingConsult = 128,
    FUCoachingConsultScheduled = 256,
    InitialMDVisit = 512,
    ScheduleMDVisit = 1024,
    MDVisitScheduled = 2048,
    LongevityMonth1 = 2050,
    LongevityMonth2 = 2055,
    LongevityMonth3 = 2060,
    LongevityMonth4 = 2065,
    LongevityMonth5 = 2070,
    LongevityMonth6 = 2075,
    BrainOptimizationMonth1 = 2080,
    BrainOptimizationMonth2 = 2085,
    BrainOptimizationMonth3 = 2090,
    BrainOptimizationMonth4 = 2095,
    BrainOptimizationMonth5 = 2100,
    BrainOptimizationMonth6 = 2105,
    GutHealthMonth1 = 2110,
    GutHealthMonth2 = 2115,
    GutHealthMonth3 = 2120,
    GutHealthMonth4 = 2125,
    GutHealthMonth5 = 2130,
    GutHealthMonth6 = 2135,
    MindfulnessMonth1 = 2140,
    MindfulnessMonth2 = 2145,
    MindfulnessMonth3 = 2150,
    MindfulnessMonth4 = 2155,
    MindfulnessMonth5 = 2160,
    MindfulnessMonth6 = 2165,
    AthleticOptimizationMonth1 = 2170,
    AthleticOptimizationMonth2 = 2175,
    AthleticOptimizationMonth3 = 2180,
    AthleticOptimizationMonth4 = 2185,
    AthleticOptimizationMonth5 = 2190,
    AthleticOptimizationMonth6 = 2195,
}

export enum ChronologicalEventType {
    None = 0,
    PatientCreated = 10,
    HealthCoachOrProviderAssigned = 20,
    VisitCompleted = 30,
    NotePublished = 40,
    SupplementUpdated = 50,
    InternalMessageSent = 60,
    LabsOrdered = 70
}

export const ChronologicalEventTypeNames = new Map<ChronologicalEventType, string>([
    [ChronologicalEventType.None, 'None'],
    [ChronologicalEventType.PatientCreated, 'Patient Created'],
    [ChronologicalEventType.HealthCoachOrProviderAssigned, 'Care Team Assignment'],
    [ChronologicalEventType.VisitCompleted, 'Visit Completed'],
    [ChronologicalEventType.NotePublished, 'Notes Published'],
    [ChronologicalEventType.SupplementUpdated, 'Supplements Updated'],
    [ChronologicalEventType.InternalMessageSent, 'Internal Message'],
    [ChronologicalEventType.LabsOrdered, 'Labs Ordered'],
]);

export const PatientJourneyStatusNames = new Map<PatientJourneyStatus, string>([
    [PatientJourneyStatus.New, 'New'],
    [PatientJourneyStatus.InitialCoachingCallScheduled, 'Initial Coaching Call Scheduled'],
    [PatientJourneyStatus.InitialCoachingCallCompleted, 'Initial Coaching Call Completed'],
    [PatientJourneyStatus.LabsPending, 'Labs Pending'],
    [PatientJourneyStatus.ReportSent, 'Report Sent'],
    [PatientJourneyStatus.RepeatLabsOrdered, 'Repeat Labs Ordered'],
    [PatientJourneyStatus.ScheduleFUCoachingConsult, 'Schedule FU Coaching Consult'],
    [PatientJourneyStatus.FUCoachingConsultScheduled, 'FU Coaching Consult Scheduled'],
    [PatientJourneyStatus.InitialMDVisit, 'Initial MD Visit'],
    [PatientJourneyStatus.ScheduleMDVisit, 'Schedule MD Visit'],
    [PatientJourneyStatus.MDVisitScheduled, 'MD Visit Scheduled'],
    [PatientJourneyStatus.LongevityMonth1, 'Longevity Month 1'],
    [PatientJourneyStatus.LongevityMonth2, 'Longevity Month 2'],
    [PatientJourneyStatus.LongevityMonth3, 'Longevity Month 3'],
    [PatientJourneyStatus.LongevityMonth4, 'Longevity Month 4'],
    [PatientJourneyStatus.LongevityMonth5, 'Longevity Month 5'],
    [PatientJourneyStatus.LongevityMonth6, 'Longevity Month 6'],
    [PatientJourneyStatus.BrainOptimizationMonth1, 'Brain Optimization Month 1'],
    [PatientJourneyStatus.BrainOptimizationMonth2, 'Brain Optimization Month 2'],
    [PatientJourneyStatus.BrainOptimizationMonth3, 'Brain Optimization Month 3'],
    [PatientJourneyStatus.BrainOptimizationMonth4, 'Brain Optimization Month 4'],
    [PatientJourneyStatus.BrainOptimizationMonth5, 'Brain Optimization Month 5'],
    [PatientJourneyStatus.BrainOptimizationMonth6, 'Brain Optimization Month 6'],
    [PatientJourneyStatus.GutHealthMonth1, 'Gut Health Month 1'],
    [PatientJourneyStatus.GutHealthMonth2, 'Gut Health Month 2'],
    [PatientJourneyStatus.GutHealthMonth3, 'Gut Health Month 3'],
    [PatientJourneyStatus.GutHealthMonth4, 'Gut Health Month 4'],
    [PatientJourneyStatus.GutHealthMonth5, 'Gut Health Month 5'],
    [PatientJourneyStatus.GutHealthMonth6, 'Gut Health Month 6'],
    [PatientJourneyStatus.MindfulnessMonth1, 'Mindfulness Month 1'],
    [PatientJourneyStatus.MindfulnessMonth2, 'Mindfulness Month 2'],
    [PatientJourneyStatus.MindfulnessMonth3, 'Mindfulness Month 3'],
    [PatientJourneyStatus.MindfulnessMonth4, 'Mindfulness Month 4'],
    [PatientJourneyStatus.MindfulnessMonth5, 'Mindfulness Month 5'],
    [PatientJourneyStatus.MindfulnessMonth6, 'Mindfulness Month 6'],
    [PatientJourneyStatus.AthleticOptimizationMonth1, 'Athletic Optimization Month 1'],
    [PatientJourneyStatus.AthleticOptimizationMonth2, 'Athletic Optimization Month 2'],
    [PatientJourneyStatus.AthleticOptimizationMonth3, 'Athletic Optimization Month 3'],
    [PatientJourneyStatus.AthleticOptimizationMonth4, 'Athletic Optimization Month 4'],
    [PatientJourneyStatus.AthleticOptimizationMonth5, 'Athletic Optimization Month 5'],
    [PatientJourneyStatus.AthleticOptimizationMonth6, 'Athletic Optimization Month 6'],
    [PatientJourneyStatus.Other, 'Other']
]);
export interface PatientPharmacyInfoModel {
    streetAddress : string;
    name: string;
    phone: string;
    country: string;
    city: string;
    state: string;
    zipCode: string;
}

const emptyPatientPharmacyInfo = {
    country: '',
    city: '',
    state: '',
    zipCode: '',
    streetAddress: '',
    name: '',
    phone: ''
}

export const getInitPharmacyInfo = () => Object.assign({}, emptyPatientPharmacyInfo);

export interface ChronologicalEventModel {
    title: string;
    body: string;
    createdAt: Date,
    type: ChronologicalEventType;
    payload: any;
    order: number;
}

export enum PatientNpsScoreType {
    PostImc = 10,
    MonthFive = 20,
    MonthTen = 30
}

export const PatientNpsScoreTypeNames = new Map<PatientNpsScoreType, string>([
    [PatientNpsScoreType.PostImc, 'NPS post IMC'],
    [PatientNpsScoreType.MonthFive, '5-month NPS'],
    [PatientNpsScoreType.MonthTen, '10-month NPS'],
]);

export enum PatientNpsScoreTopic {
    Doctor = 10,
    HealthCoach = 20,
    Product = 30,
    OverallWh = 40
}

export interface PatientNpsScoreModel {
    patientId: number;
    npsScoreType: PatientNpsScoreType;
    npsTopic: PatientNpsScoreTopic;
    submittedAtWeek: Date;
    npsScore: number;
}

export interface PatientTaskModel {
    displayName: string;
    type: EngagementCriteriaType;
    analyticsEvent: string;
    reason: string;
}

export interface EngagementPatientModel {
    patientId: number;
    patientName: string;
    notificationTitle: string;
    createdAt: Date;
    isPremium: boolean;
    assignedProviders: string[];
    assignedHealthCoaches: string[];
}

export interface HealthCoachEngagementTaskModel {
    patientId: number;
    patientFullName: string;
    engagementId: number;
    eventName: string;
}

export interface DelinquentStatusModel {
    reason: string;
    asOf: Date;
    paymentLink: string
    paymentLinkText: string
}

export enum JourneyTaskAction
{
    None = 0,
    // Onboarding
    DownloadLabRequisition = 1,
    CompleteHealthForm = 2,
    OpenApp = 3,
    ScheduleCall = 4,
    
    // Planning & Goal Setting
    ReviewHealthReport = 100,
    ReviewHealthPlan = 101,
    VisitAcademy = 102,
}

export interface JourneyTaskCTAModel {
    title: string;
    link: string;
    action: string;
    filePath?: string;
    id: number;
    priority: number;
    isArchived: boolean;
}

export interface TodoPatientJourneyTaskModel {
    id: number;
    title: string;
    description: string;
    isRequired: boolean;
    group: string;
    priority: number;
    taskCTA: JourneyTaskCTAModel;
}

export interface TodoPatientJourneyTaskCategoryModel {
    category: string;
    requiredTasks: TodoPatientJourneyTaskModel[];
    optionalTasks: TodoPatientJourneyTaskModel[];
    isLocked: boolean;
}

export interface CompletedPatientJourneyTaskModel {
    id: number;
    title: string;
    description: string;
    group: string;
    priority: number;
    isDismissed: boolean;
    completedBy: string;
    canUndo: boolean;
    taskCTA: JourneyTaskCTAModel;
}

export interface CompletedPatientJourneyTaskCategoryModel {
    category: string;
    requiredTasks: CompletedPatientJourneyTaskModel[];
    optionalTasks: CompletedPatientJourneyTaskModel[];
}

export interface PatientJourneyRewardModel {
    id: number;
    title: string;
    description: string;
    priority: number;
    taskCTA: JourneyTaskCTAModel;
}

export interface PatientJourneyModel {
    todo: TodoPatientJourneyTaskCategoryModel[];
    completed: CompletedPatientJourneyTaskCategoryModel[];
    rewards: PatientJourneyRewardModel[];
}

export enum JourneyTasksTabs {
    Todo = 0,
    Completed = 1,
    Reward = 2
}

export interface PaymentPlanUpdateEventModel {
    title: string;
    createdAt: Date;
}

export interface PaymentPlanHistoryModel {
    paymentPlanName: string;
    startDate: Date;
    endDate: Date;
    events: PaymentPlanUpdateEventModel[];
}

export enum ActionType {
    StandardPlanUpSell = 1
}

export enum ActionStatus {
    Active = 1,
    Completed = 10
}

export enum ActionReactionType {
    Accept = 1,
    Decline = 2
}

export interface CallToActionModel {
    id: number;
    createdAt: Date;
    type: ActionType;
    status: ActionStatus;
    reactions: ActionReactionType[];
}

export interface ReactOnCallToActionModel {
    id: number;
    reactionType: ActionReactionType;
    details: string;
}
