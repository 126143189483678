import React from "react";
import { Redirect, Route } from "react-router";
import { LogOutTimeOutComponent } from "./logOutTimeOutComponent/LogOutTimeOutComponent";

export enum RedirectPermissions {
    Normal = 0,
    Unauthorized = 5,
    NotVerified = 10,
    NoConfirmedAgreements = 15,
}

export const ProtectedRoute = ({redirectPermission, component: Component, ...rest}) => {

    if (redirectPermission === RedirectPermissions.Unauthorized) {
        return <Redirect to={{
            pathname: '/login',
            state: {
                from: (window.location.pathname + window.location.search).replace('//', '/')
            }
        }}/>
    }

    if (redirectPermission === RedirectPermissions.NotVerified) {
        return <Redirect to='/verify-profile'/>
    }

    if (redirectPermission === RedirectPermissions.NoConfirmedAgreements) {
        return <Redirect to='/confirm-agreements'/>
    }

    return (
        <Route {...rest} render={(props) => (
            <>
                <Component {...props} />
                <LogOutTimeOutComponent />
            </>
        )}/>
    )
}
