import React from "react";
import {Box, TextField, useMediaQuery, useTheme} from "@material-ui/core";
import {colors} from "../../../common/constants/colors";
import {useFacade} from "./searchFullscriptSupplementComponent.hooks";
import {Autocomplete} from "@material-ui/lab";
import {FullscriptSupplementModel} from "../../models/fullscriptSupplementModels";

export interface SearchFullscriptSupplementComponentProps {
    initialValue?: string;
    handleSelect: Function;
}

export const SearchFullscriptSupplementComponent: React.FC<SearchFullscriptSupplementComponentProps> = (props: SearchFullscriptSupplementComponentProps) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const [
        state,
        handleSearchQueryChanges,
        handleSubmit,
    ] = useFacade(props);

    return (
        <Box p={!isSmallScreen && 1.5} pt={0.5} display="flex" flexDirection="column">
            <Box display="flex" pl={0.5} fontSize={14} color={colors.gray1}>
                Name of Supplement
                <Box pl={0.5} color={colors.bad}>*</Box>
            </Box>
            <Box>
                <Autocomplete
                    size="small"
                    freeSolo
                    disableClearable
                    fullWidth
                    loading={state.isLoading}
                    options={state.supplements}
                    defaultValue={{name: props.initialValue, brand: null} as FullscriptSupplementModel}
                    getOptionLabel={option => option.name + ` ${option.brand ? '(' + option.brand.name + ')' : ''}` }
                    onChange={(e, value: FullscriptSupplementModel) => handleSubmit(value)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth
                            variant='outlined'
                            autoFocus
                            value={props.initialValue ?? ''}
                            InputProps={{
                                ...params.InputProps,
                                className: 'input',
                                type: 'text'
                            }}
                            onChange={(e) => handleSearchQueryChanges(e.target.value)}
                        />
                    )}
                />
            </Box>
        </Box>
    )
}
