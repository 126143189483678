import Axios from 'axios-observable';
import { authHeader } from "../../common/helpers/auth-header";
import { snackService } from "../../common/snack/state";
import { documentsStore, DocumentsStore } from "../stores/documentsStore";
import { FileInputModel, FileInputType, } from "../models/input.models";
import { Observable, Subject } from "rxjs";
import { CallbackSimpleModel } from "../../common/models/callback.model";
import { DocumentCategory, documentCategoryNames, DocumentModel } from '../../documents/models/documents.models';
const FileDownload = require('js-file-download');

export class PatientDocumentsService {
    private urlDocuments = `${process.env.REACT_APP_API_URL}Documents`;
    private urlUploads = `${this.urlDocuments}/UploadDocuments`;

    constructor(private documentsStore: DocumentsStore) {
    }

    public onUpload = new Subject<CallbackSimpleModel<FileInputType>>();


    /**
     * Returns all user uploads
     */

    public getDocuments(): Observable<DocumentModel[]> {

        const config = { headers: authHeader() };

        return new Observable((observer) => {
            Axios.get<DocumentModel[]>(`${this.urlDocuments}/GetDocuments/Patient`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.documentsStore.update({ documentList: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    /**
     * Downloads uploaded file
     */

    public download(id: number, name: string): Observable<any> {
        return new Observable(observer => {
            Axios.get(`${this.urlDocuments}/DownloadDocument/Patient?documentId=${id}`, {
                headers: authHeader(),
                responseType: 'blob',
            })
                .pipe()
                .subscribe(
                    (response) => {
                        FileDownload(response.data, decodeURIComponent(name));
                        snackService.success('File successfully downloaded!');
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    // /**
    //  * Views uploaded file
    //  */
    public viewinline(input: DocumentModel): Observable<any> {
        return new Observable(observer => {
            Axios.get(`${this.urlDocuments}/Document/${input.id}/viewinline/Patient`, {
                headers: authHeader(),
                responseType: 'blob',
            })
                .pipe()
                .subscribe(
                    (response) => {
                        const file = new Blob([response.data], { type: response.data.type });
                        const fileURL = URL.createObjectURL(file);
                        window.open(fileURL, '_blank');

                        snackService.success('File successfully opened!');
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Returns all user uploads
     */
    public upload(): Observable<FileInputModel> {
        return new Observable(observer => {
            this.onUpload.next({
                callback: (files: any, documentType: DocumentCategory, viewableFileIndexes: number[], isSendToKb: boolean) => {
                    if (!files) {
                        observer.error();
                        observer.complete();
                        return;
                    }

                    const formData = new FormData();
                    formData.append('attachmentType', documentType.toString());
                    files.forEach((item) => {
                        formData.append('documents', item.file, item.name);
                    });
                    if (viewableFileIndexes.length) {
                        viewableFileIndexes.forEach(item => {
                            formData.append('IsViewableByPatientFileIndexes[]', item.toString());
                        });
                    }
                    const generativeAiKbInlineModel = {
                        isSendToKb: isSendToKb,
                    };
                    formData.append('generativeAiKbInline', JSON.stringify(generativeAiKbInlineModel));

                    Axios.post(`${this.urlUploads}/Patient`, formData, { headers: authHeader() })
                        .pipe()
                        .subscribe(
                            (response) => {
                                snackService.success(`Files successfully uploaded to ${documentCategoryNames[documentType]}`);
                                observer.next(response.data);
                                observer.complete();
                            },
                            error => {
                                snackService.commonErrorHandler(error);
                                observer.error(error);
                                observer.complete();
                            }
                        );
                }
            });
        });
    }
}

export const patientDocumentsService = new PatientDocumentsService(documentsStore);