import moment from "moment";

export function displayDate(date: Date): string {
    return moment(date).format('L');
}

export function displayAge(date: Date): string {
  return moment().diff(date, 'years') === 0 ?  `${moment().diff(date, 'month')} months old` :`${moment().diff(date, 'years')} years old`
}

export function displayShortAge(date: Date): string {
  return moment().diff(date, 'years') === 0 ?  `${moment().diff(date, 'month')} months old` :`${moment().diff(date, 'years')} y/o`
}


