import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { confirmService } from "../../../../services/confirm.service";
import { displayMoney } from "../../../common/helpers/display-money";
import { onEmit } from "../../../common/helpers/on-emit";
import { EpigeneticOrderModel } from "../../models/epigeneticOrders.models";
import { epigeneticOrdersService } from "../../services/epigeneticOrders.service";
import { epigeneticOrdersQuery } from "../../stores/epigeneticOrdersStore";
import { addOnsService } from "../../../addons/services/addOns.service";
import { authQuery } from "../../../auth/stores/auth";
import { AddOnModel } from "../../../addons/models/addOns.models";
import { PatientProductModel } from "../../../products/models/patientProducts.model";
import { productsQuery } from "../../../products/stores/productsStore";
import { ProductType } from "../../../products/enums/productType";
import { patientProductsService } from "../../../products/services/patientProducts.service";


interface EpigeneticOrderViewModel extends EpigeneticOrderModel {
    isOpen: boolean;
}

interface EpigeneticComponentState {
    isLoading: boolean;
    orders: EpigeneticOrderViewModel[];
    addOns: AddOnModel[];
    isLoadingNewKit: boolean;
    selectedOrder: EpigeneticOrderViewModel | null;
    isOpened: boolean;
    hasFreeEpigeneticOrders: boolean;
}

export function useFacade(patientId: number): [EpigeneticComponentState, Array<string>, (selectedOrderId: number) => void, () => void] {
    const [state, setState] = useState<EpigeneticComponentState>({
        isLoading: true,
        orders: [],
        addOns: [],
        isLoadingNewKit: false,
        selectedOrder: null,
        isOpened: false,
        hasFreeEpigeneticOrders: false,
    });

    const columns = [
        'Order Date',
        'Status',
        'Provider Order #',
        'Paid Date',
    ];

    const updateFreeEpigeneticOrders = () => {
        productsQuery.patientProducts$.subscribe(products => {
            if (products) {
                const freeEpigeneticOrders = products.filter(el =>
                    el.productType === ProductType.FreeEpigeneticOrder &&
                    el.usedAt === null &&
                    (el.expiredAt === null || new Date(el.expiredAt) > new Date())
                );
                setState(state => ({ ...state, hasFreeEpigeneticOrders: freeEpigeneticOrders.length > 0 }));
            }
        });
    }

    const handleToggleOrderDetails = (selectedOrderId: number) => {
        const item = state.orders.find(i => i.id === selectedOrderId);
        if (item) {
            item.isOpen = !item.isOpen;
            setState(state => ({ ...state, selectedOrder: item, isOpened: item.isOpen }));
        }
    }

    const handleOrderKit = () => {
        const addOns = state.addOns;

        if (addOns.length) {
            const addOn = addOns[0];

            const orderKit = () => {
                setState(state => ({ ...state, isLoadingNewKit: true }));
                epigeneticOrdersService.create({ patientId: patientId, addOnIds: [addOn.id] }).subscribe(
                    () => {
                        setState(state => ({ ...state, isLoadingNewKit: false }));
                        patientProductsService.refreshPatientProducts(patientId);
                        updateFreeEpigeneticOrders();
                    },
                    () => { setState(state => ({ ...state, isLoadingNewKit: false })); }
                )
            };

            if (state.hasFreeEpigeneticOrders) {
                orderKit();
            } else {
                confirmService.confirm('Are you sure?', `This will charge the patient's payment source ${displayMoney(addOn.price, '$')}. Would you like to proceed?`).subscribe(
                    () => { orderKit(); }
                );
            }
        }
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<EpigeneticOrderModel[]>(epigeneticOrdersQuery.orders$, orders => {
                setState(state => ({ ...state, orders: orders.map(i => ({ ...i, isOpen: false })) }));
            }),
            onEmit<PatientProductModel[]>(productsQuery.patientProducts$, products => {
                if (products) {
                    const freeEpigeneticOrders = products.filter(el =>
                        el.productType === ProductType.FreeEpigeneticOrder &&
                        el.usedAt === null &&
                        (el.expiredAt === null || new Date(el.expiredAt) > new Date())
                    );
                    setState(state => ({ ...state, hasFreeEpigeneticOrders: freeEpigeneticOrders.length > 0 }));
                }
            }),
        ];

        const cb = () => setState(state => ({ ...state, isLoading: false }));

        if (patientId) {
            epigeneticOrdersService.get(patientId).subscribe(cb, cb);
        } else {
            epigeneticOrdersService.getMyOrders().subscribe(cb, cb);
        }

        addOnsService.getEpigeneticAddOns(authQuery.getPracticeId()).subscribe(
            (data) => {
                setState(state => ({
                    ...state,
                    addOns: data
                }));
            }
        )

        return () => {
            subscriptions.forEach(it => it.unsubscribe());
        };
    }, [patientId]);

    return [state, columns, handleToggleOrderDetails, handleOrderKit];
}
