import React from 'react';
import { Box } from "@material-ui/core";
import { AuthenticatedLayoutComponent } from '../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { PagesNamesService } from "../../../../constants/pagesNames.constants";
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from "../../../../models/menu.models";
import { ManageShortcutsComponent } from "../../components/shortcuts/manageShortcutsComponent/ManageShortcutsComponent";

export const ManageShortcutsPage: React.FC = () => {

    return (
        <AuthenticatedLayoutComponent
            title={TitlesService.coachShortcutsTitle}
            pageName={PagesNamesService.coachShortcuts}
            selectedMenuItem={MenuItemTypes.Shortcuts}
            hideBackground
        >
            <Box p={2} pt={4}>
                <ManageShortcutsComponent />
            </Box>
        </AuthenticatedLayoutComponent>
    )
}