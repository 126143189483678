import React from 'react';
import { Box, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Drawer, Collapse, useTheme, useMediaQuery } from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import clsx from 'clsx';
import { useFacade } from "./patientCompletedTasksComponent.hooks";
import { useStyles } from "./patientCompletedTasksComponent.styles";
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import { handleCta } from '../../helpers/taskCtaHandler';

interface PatientCompletedTasksComponentProps {
    patientId: number | null;
}

export const PatientCompletedTasksComponent: React.FC<PatientCompletedTasksComponentProps> = (props: PatientCompletedTasksComponentProps) => {
    const [
        {
            isUpdating,
            completedTask,
            isSideAreaOpen,
            selectedTask,
            categoriesExpanded
        },
        handleToggleSideArea,
        handleExpand,
        handleUndo
    ] = useFacade();

    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const renderSideArea = () => {
        if (selectedTask) {
            return (
                <Box className={clsx('flat-scroll', classes.sideContainer)} display="flex" flexDirection="column">
                    {!isSmallScreen && 
                        <Box display="flex" alignItems="center" justifyContent="space-between" pb={2}>
                            <Box className="wh-tw-font-medium wh-tw-text-xl wh-tw-text-gray900">Completed Task </Box>
                            <IconButton
                                id={`task-${selectedTask.id}`}
                                size="small"
                                onClick={() => handleToggleSideArea(null, false)}
                            >
                                <CloseIcon className="wh-tw-text-gray900" />
                            </IconButton>
                        </Box>
                    }
                    <Box pt={3}>
                        <Box className="wh-tw-font-medium wh-tw-text-lg wh-tw-text-center md:wh-tw-text-left">{selectedTask.title}</Box>
                        <Box mt={1.5} className="wh-tw-text-sm wh-tw-text-gray900">{selectedTask.description}</Box>
                    </Box>
                    {selectedTask.taskCTA &&
                        <Box flex={1} display="flex" alignItems="flex-end" justifyContent="center" pt={2}>
                            <WildHealthButton
                                id="mark-complete-button"
                                fullWidth
                                size="large"
                                borderRadius={50}
                                disabled={isUpdating || !!props.patientId}
                                onClick={() => handleCta(selectedTask)}
                            >
                                {selectedTask.taskCTA.title}
                            </WildHealthButton>
                        </Box>
                    }
                </Box>
            )
        }

        return <></>
    }

    return (
        <Box width={1} pt={1} pb={3}>
            {!!completedTask.length
                ? completedTask.map((item, index) => {
                    return (
                        <Box key={index} pb={2}>
                            <Box display="flex" justifyContent="flex-start" alignItems="stretch" mt={2}>
                                <Box id={`patient-lab-inputs-expand-${item.category}`} onClick={() => handleExpand(item.category)}
                                    className="wh-tw-cursor-pointer">
                                    {!categoriesExpanded.includes(item.category) ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                                </Box>
                                <Box ml={1}>
                                    <span className="wh-tw-text-lg wh-tw-font-medium wh-tw-font-beVietnamPro">{item.category}</span>
                                </Box>
                            </Box>
                            <Collapse in={!categoriesExpanded.includes(item.category)} timeout="auto" unmountOnExit>
                                <Box mt={2}>
                                    <List className={classes.list}>
                                        {item.requiredTasks.map((task) =>
                                            <ListItem className={classes.listItem} key={task.id}>
                                                <ListItemIcon>
                                                    <IconButton
                                                        id={`task-${task.id}`}
                                                        size="small"
                                                        onClick={() => handleUndo(task.id)}
                                                        disabled={isUpdating || !!props.patientId || !task.canUndo}
                                                    >
                                                        {task.isDismissed ? <RadioButtonUncheckedIcon className={!task.canUndo ? "wh-tw-text-gray400": "wh-tw-text-primaryV"} /> : <CheckCircleIcon className={!task.canUndo ? "wh-tw-text-gray400": "wh-tw-text-primaryV"} />}
                                                    </IconButton>
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={task.title}
                                                    className="wh-tw-cursor-pointer"
                                                    onClick={() => !props.patientId && handleCta(task)}
                                                />
                                                <ListItemSecondaryAction>
                                                    <IconButton
                                                        id={`task-${task.id}-side-area-drawer`}
                                                        size="small"
                                                        onClick={() => handleToggleSideArea(task, true)}
                                                        disabled={isUpdating}
                                                    >
                                                        <KeyboardArrowRightIcon className="wh-tw-text-gray600" />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        )}
                                    </List>
                                </Box>
                                {item.optionalTasks && item.optionalTasks.length > 0 && 
                                    <Box mt={3}>
                                        <Box mb={1}>
                                            <span className="wh-tw-text-xxs wh-tw-font-medium wh-tw-font-beVietnamPro">Optional</span>
                                        </Box>
                                        <List className={classes.list}>
                                            {item.optionalTasks.map((task) =>
                                                <ListItem className={classes.listItem} key={task.id}>
                                                    <ListItemIcon>
                                                        <IconButton
                                                            id={`task-${task.id}`}
                                                            size="small"
                                                            onClick={() => handleUndo(task.id)}
                                                            disabled={isUpdating || !!props.patientId || !task.canUndo}
                                                        >
                                                            {task.isDismissed ? <RadioButtonUncheckedIcon className={!task.canUndo ? "wh-tw-text-gray400": "wh-tw-text-primaryV"} /> : <CheckCircleIcon className={!task.canUndo ? "wh-tw-text-gray400": "wh-tw-text-primaryV"} />}
                                                        </IconButton>
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={task.title}
                                                        className="wh-tw-cursor-pointer"
                                                        onClick={() => handleCta(task)}
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <IconButton
                                                            id={`task-${task.id}-side-area-drawer`}
                                                            size="small"
                                                            onClick={() => handleToggleSideArea(task, true)}
                                                            disabled={isUpdating}
                                                        >
                                                            <KeyboardArrowRightIcon className="wh-tw-text-gray600" />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            )}
                                        </List>
                                    </Box>
                                }
                            </Collapse>
                        </Box>
                    )
                })
                : <Box px={2} className={classes.empty}></Box>
            }
            <Drawer
                id="side-area-drawer"
                anchor='right'
                classes={{ paper: classes.papper }}
                open={isSideAreaOpen}
                onClose={() => handleToggleSideArea(null, false)}
            >
                <Box className={classes.sideMobile}>
                    {renderSideArea()}
                </Box>
            </Drawer>
        </Box>
    );
}