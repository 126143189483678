import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { useHistory } from 'react-router';
import { onEmit } from "../../../common/helpers/on-emit";
import { syncRecordsService } from "../../services/syncRecords.service";
import { SyncRecordTypeModel } from "../../models/syncRecords.model";
import { syncRecordsQuery } from "../../stores/syncRecordsStore";
import { navigationService } from '../../../../services/navigation.service';

interface SyncRecordsState {
    isLoading: boolean;
    syncRecordTypes: SyncRecordTypeModel[];
}

export function useFacade(): [
    SyncRecordsState,
    (path: string) => void
] {

    const history = useHistory();

    const [state, setState] = useState({
        isLoading: true,
        syncRecordTypes: [],
    } as SyncRecordsState);

    const handleGoTargetSyncRecordType = (path: string) => {
        const typeName = path.split('/')[1];
        const practiceId = path.split('/')[2];
        navigationService.toSyncRecordType(history, typeName, practiceId);
        return;
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<SyncRecordTypeModel[]>(syncRecordsQuery.syncRecordTypes$, syncRecordTypes => {
                setState({
                    ...state,
                    syncRecordTypes
                });
            }),
        ];

        const cb = () => setState(state => ({ ...state, isLoading: false }));

        syncRecordsService.getSyncRecords().subscribe(cb, cb);

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, []);

    return [
        state,
        handleGoTargetSyncRecordType
    ];
}