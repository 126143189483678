import { Query } from "@datorama/akita";
import { DNAFilesState, dnaFilesStore, DNAFilesStore } from "./dnaFiles.store";
import { DnaFileModel } from "../../models/dnaFlies.models";

export class DNAFilesQuery extends Query<DNAFilesState> {

    dnaFiles$ = this.select(state => state.dnaFiles);

    constructor(protected store: DNAFilesStore) {
        super(store);
    }

    public getFiles(): DnaFileModel[] {
        return this.getValue().dnaFiles;
    }
}

export const dnaFilesQuery = new DNAFilesQuery(dnaFilesStore);