import { createStyles, makeStyles } from "@material-ui/core/styles";
import { colors } from "../../../../../common/constants/colors";

export const useStyles = makeStyles(() =>
    createStyles({
        breakWord: {
            wordBreak: 'break-word',
        },
        tabTitle: {
            width:'48%'
        },
        borderTitle:{
            backgroundColor: colors.main
        },
        button: {
            color: colors.main,
            fontSize: 14
        },
        buttonText: {
            marginLeft: '6px',
            textTransform: 'capitalize'
        },
        textGray: {
            color: colors.gray1,
        }
    })
);

