import {HealthSummaryDataModel, HealthSummaryMapModel} from '../models/healthSummary.models'
import {healthSummaryMedicationsService} from './healthSummaryMedications.service'
import {healthSummarySupplementsService} from './healthSummarySupplements.service'
import {healthSummaryAllergiesService} from './healthSummaryAllergies.service'
import {healthSummaryVitalsService} from './healthSummaryVitals.service'
import {healthSummaryStore, HealthSummaryStore} from '../stores'
import {authHeader} from '../../common/helpers/auth-header'
import {snackService} from '../../common/snack/state'
import Axios from 'axios-observable'
import {Observable} from 'rxjs'
import {isFeatureFlag} from "../../common/components/featureFlags/featureFlags";
import {FeatureFlag} from "../../common/components/featureFlags/featureFlags.models";
import {healthSummaryRxntAllergiesService} from "./HealthSummaryRxntAllergiesService";
import {healthSummaryRxntMedicationsService} from "./healthSummaryRxntMedicationsService";

export class HealthSummaryService {
    private url = `${process.env.REACT_APP_API_URL}HealthSummary`

    constructor(private healthSummaryStore: HealthSummaryStore) {
    }

    public getJsonDataService(mapKey: string, flags) {
        switch(mapKey) {
            case healthSummaryAllergiesService.mapKey:
                return isFeatureFlag(flags, FeatureFlag.RxntIntegration)
                    ? healthSummaryRxntAllergiesService
                    : healthSummaryAllergiesService;
            case healthSummaryMedicationsService.mapKey:
                return isFeatureFlag(flags, FeatureFlag.RxntIntegration)
                    ? healthSummaryRxntMedicationsService
                    : healthSummaryMedicationsService;
            case healthSummarySupplementsService.mapKey: return healthSummarySupplementsService;
            case healthSummaryVitalsService.mapKey: return healthSummaryVitalsService;
            default: return null;
        }
    }

    /**
     * Returns patients health summary model
     */
    public getData(patientId?: number): Observable<HealthSummaryDataModel[]> {
        const url = this.url + (patientId ? `/?patientId=${patientId}` : '')

        const config = { headers: authHeader() }
        return new Observable<HealthSummaryDataModel[]>(observer => {
            Axios.get<HealthSummaryDataModel[]>(url, config)
                .pipe()
                .subscribe(
                    response => {
                        this.healthSummaryStore.setData(response.data)
                        observer.next(response.data)
                        observer.complete()
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.error(error)
                        observer.complete()
                    }
                )
        })
    }

    public getMapData(patientId: number, map: HealthSummaryMapModel, flags) {
        if(map.useJsonData){
            this.getJsonDataService(map.key, flags).get(patientId)
        }
    }

    /**
     * Returns patients health summary model
     */
    public create(patientId: number, map: HealthSummaryMapModel, model: HealthSummaryDataModel, flags): Observable<any> {
        if(map.useJsonData){
            return this.getJsonDataService(map.key, flags).create(patientId, model)
        }

        model.patientId = patientId

        const config = { headers: authHeader() }

        return new Observable<HealthSummaryDataModel[]>(observer => {
            Axios.post(`${this.url}`, model, config).pipe()
                .pipe()
                .subscribe(
                    response => {
                        this.healthSummaryStore.add(response.data, true)
                        snackService.success("The element is successfully created.")
                        observer.next(response.data)
                        observer.complete()
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.error(error)
                        observer.complete()
                    }
                )
        })
    }

    /**
     * Returns patients health summary model
     */
     public update(patientId: number, map: HealthSummaryMapModel, model: HealthSummaryDataModel, flags): Observable<any> {
        if(map.useJsonData){
            return this.getJsonDataService(map.key, flags).update(patientId, model)
        }

        model.patientId = patientId

        const config = { headers: authHeader() }
        return new Observable<any>(observer => {
            Axios.put(`${this.url}`, model, config).pipe()
                .pipe()
                .subscribe(
                    response => {
                        this.healthSummaryStore.edit(response.data, true)
                        snackService.success("The element is successfully updated.")
                        observer.next(response.data)
                        observer.complete()
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.error(error)
                        observer.complete()
                    }
                )
        })
    }

    /**
     * Returns patients health summary model
     */
     public batchUpdate(values: HealthSummaryDataModel[]): Observable<any> {
        const url = this.url + '/batch'

        const config = { headers: authHeader() }
        return new Observable<any>(observer => {
            Axios.post<HealthSummaryDataModel[]>(url, values, config)
                .pipe()
                .subscribe(
                    response => {
                        snackService.success("Changes are saved.")
                        observer.next(response.data)
                        observer.complete()
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.error(error)
                        observer.complete()
                    }
                )
        })
    }

    /**
     * Returns patients health summary model
     */
     public delete(patientId: number, map: HealthSummaryMapModel, itemKey: string, flags) {
        if(map.useJsonData){
            this.getJsonDataService(map.key, flags).delete(itemKey)
            return
        }

        const config = { headers: authHeader() }
        return Axios.delete(`${this.url}?patientId=${patientId}&key=${itemKey}`, config).pipe()
            .subscribe(
                response => {
                    this.healthSummaryStore.delete(itemKey)
                    snackService.success("The element is successfully deleted.")
                },
                error => {
                    snackService.commonErrorHandler(error)
                }
            )
    }

    /**
     * Returns health summary map
     */
    public getMap() {
        const url = `${this.url}/map`
        
        const config = { headers: authHeader() }

        return Axios.get(url, config)
            .pipe()
            .subscribe(
                (response) => {
                    this.healthSummaryStore.update({map: response.data})
                },
                error => {
                    snackService.commonErrorHandler(error)

                }
            )
    }
}

export const healthSummaryService = new HealthSummaryService(healthSummaryStore)