import { Box, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { DnaTableComponent } from "../components/dnaTableComponent/DnaTableComponent";
import { LabelPosition, ProgressDiagramComponent } from '../components/progressDiagramComponent/ProgressDiagramComponent';
import { exerciseRiskColorMap } from "../constants/healthReport.constants";
import { HealthReportSections, HealthReportMode } from "../models/healthReport.models";
import { HealthReportBasePage, HealthReportMobileBasePage } from "./HealthReportBasePage";
import { useStyles } from './healthReport.styles';
import { HealthReportPageProps } from "./healthReportPageProps";
import commonUseStyles from "../../common/styles/common.styles";
import clsx from "clsx";

export const HealthReportExerciseAndRecovery1Page: React.FC<HealthReportPageProps> = (props: HealthReportPageProps) => {
    const {
        report,
        pageNumber,
        practice
    } = props;
    const commonClasses = commonUseStyles();
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        props.mode !== HealthReportMode.PrintMode && isSmallScreen ? (
            <HealthReportMobileBasePage section={HealthReportSections.ExerciseAndRecovery} pageNumber={pageNumber} practice={practice}>
                <Box px={2}>
                    <Box>
                        <Box>
                            <span className={classes.reportTitle}>Your Exercise And Recovery Genomics And Labs</span>
                        </Box>
                        <Box mt="10px" lineHeight="19px">
                            <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeMediumReport, commonClasses.textRegularReport)}>
                                {'Your genetic predisposition to strength vs. endurance training, rate of recovery and intensity tolerance:'}
                            </span>
                        </Box>
                    </Box>
                    <Box>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box>
                                    <Box display="flex" justifyContent="center" mt={2}>
                                        <Box width={0.8} display="flex" justifyContent="space-between" className="text-medium normal-line-height">
                                            <Box>
                                                <p>Strength</p>
                                            </Box>
                                            <Box>
                                                <p>Endurance</p>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box display="flex" justifyContent="center" mt="10px">
                                        <Box width={0.8}>
                                            <ProgressDiagramComponent
                                                height={22}
                                                colors={['#129ad4', '#fc002e']}
                                                data={[
                                                    {
                                                        label: "",
                                                        value: report.exerciseAndRecoveryReport.strengthAndEnduranceScore.scorePercent,
                                                        labelPosition: LabelPosition.Bottom
                                                    },
                                                ]}
                                                unavailable={!report.exerciseAndRecoveryReport.strengthAndEnduranceScore.isCalculated}
                                            />
                                        </Box>
                                    </Box>
                                    <Box textAlign="center" className="text-medium" mt="10px">
                                        {report.exerciseAndRecoveryReport.strengthAndEnduranceScore.result}
                                    </Box>
                                    <Box mt={1}>
                                        <DnaTableComponent
                                            dna={report.exerciseAndRecoveryReport.strengthAndEnduranceScore.dna}
                                            colorMap={exerciseRiskColorMap} />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box>
                                    <Box display="flex" justifyContent="center" mt="30px">
                                        <Box width={0.8} display="flex" justifyContent="space-between" className="text-medium normal-line-height">
                                            <Box>
                                                <p>Slow</p>
                                                <p>Recovery</p>
                                            </Box>
                                            <Box textAlign="right">
                                                <p>Fast</p>
                                                <p>Recovery</p>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box display="flex" justifyContent="center" mt="10px">
                                        <Box width={0.8}>
                                            <ProgressDiagramComponent
                                                height={22}
                                                colors={['#129ad4', '#fc002e']}
                                                data={[
                                                    {
                                                        label: "",
                                                        value: report.exerciseAndRecoveryReport.recoveryScore.scorePercent,
                                                        labelPosition: LabelPosition.Bottom
                                                    }
                                                ]}
                                                unavailable={!report.exerciseAndRecoveryReport.recoveryScore.isCalculated}
                                            />
                                        </Box>
                                    </Box>
                                    <Box textAlign="center" className="text-medium" mt="10px">
                                        {report.exerciseAndRecoveryReport.recoveryScore.result}
                                    </Box>
                                    <Box mt={1}>
                                        <DnaTableComponent
                                            dna={report.exerciseAndRecoveryReport.recoveryScore.dna}
                                            colorMap={exerciseRiskColorMap} />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box>
                                    <Box display="flex" justifyContent="center" mt="30px">
                                        <Box width={0.8} display="flex" justifyContent="space-between" className="text-medium normal-line-height">
                                            <Box>
                                                <p>Low</p>
                                                <p>Intensity</p>
                                            </Box>
                                            <Box textAlign="right">
                                                <p>High</p>
                                                <p>Intensity</p>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box display="flex" justifyContent="center" mt="10px">
                                        <Box width={0.8}>
                                            <ProgressDiagramComponent
                                                height={22}
                                                colors={['#129ad4', '#fc002e']}
                                                data={[
                                                    {
                                                        label: "",
                                                        value: report.exerciseAndRecoveryReport.intensityScore.scorePercent,
                                                        labelPosition: LabelPosition.Bottom
                                                    }
                                                ]}
                                                unavailable={!report.exerciseAndRecoveryReport.intensityScore.isCalculated}
                                            />
                                        </Box>
                                    </Box>
                                    <Box textAlign="center" className="text-medium" mt="10px">
                                        {report.exerciseAndRecoveryReport.intensityScore.result}
                                    </Box>
                                    <Box mt={1}>
                                        <DnaTableComponent
                                            dna={report.exerciseAndRecoveryReport.intensityScore.dna}
                                            colorMap={exerciseRiskColorMap} />
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </HealthReportMobileBasePage>
        ) : (
            <HealthReportBasePage section={HealthReportSections.ExerciseAndRecovery} pageNumber={pageNumber} practice={practice}>
                <Box>
                    <Box px={15} pt={5}>
                        <Box>
                            <Box display="flex" justifyContent="center">
                                <span className="report-title">Your Exercise And Recovery Genomics And Labs</span>
                            </Box>
                            <Box display="flex" justifyContent="center" mt={1.5}>
                                <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeMediumReport, commonClasses.textMediumReport)}>
                                    {'Your genetic predisposition to strength vs. endurance training, rate of recovery and intensity tolerance:'}
                                </span>
                            </Box>
                        </Box>
                        <Box mt={7}>
                            <Grid container>
                                <Grid item xs={4} md={4} lg={4}>
                                    <Box>
                                        <Box display="flex" justifyContent="center">
                                            <Box width={0.8} display="flex" justifyContent="space-between" className="text-medium normal-line-height" mt={2.45}>
                                                <Box>
                                                    <p>Strength</p>
                                                </Box>
                                                <Box>
                                                    <p>Endurance</p>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box display="flex" justifyContent="center" mt={2}>
                                            <Box width={0.8}>
                                                <ProgressDiagramComponent
                                                    height={22}
                                                    colors={['#129ad4', '#fc002e']}
                                                    data={[
                                                        {
                                                            label: "",
                                                            value: report.exerciseAndRecoveryReport.strengthAndEnduranceScore.scorePercent,
                                                            labelPosition: LabelPosition.Bottom
                                                        },
                                                    ]}
                                                    unavailable={!report.exerciseAndRecoveryReport.strengthAndEnduranceScore.isCalculated}
                                                />
                                            </Box>
                                        </Box>
                                        <Box textAlign="center" className="text-medium" mt={2}>
                                            {report.exerciseAndRecoveryReport.strengthAndEnduranceScore.result}
                                        </Box>
                                        <Box display="flex" justifyContent="center">
                                            <Box mt={7} width="80%">
                                                <DnaTableComponent
                                                    dna={report.exerciseAndRecoveryReport.strengthAndEnduranceScore.dna}
                                                    colorMap={exerciseRiskColorMap} />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={4} md={4} lg={4}>
                                    <Box>
                                        <Box display="flex" justifyContent="center">
                                            <Box width={0.8} display="flex" justifyContent="space-between" className="text-medium normal-line-height">
                                                <Box>
                                                    <p>Slow</p>
                                                    <p>Recovery</p>
                                                </Box>
                                                <Box textAlign="right">
                                                    <p>Fast</p>
                                                    <p>Recovery</p>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box display="flex" justifyContent="center" mt={2}>
                                            <Box width={0.8}>
                                                <ProgressDiagramComponent
                                                    height={22}
                                                    colors={['#129ad4', '#fc002e']}
                                                    data={[
                                                        {
                                                            label: "",
                                                            value: report.exerciseAndRecoveryReport.recoveryScore.scorePercent,
                                                            labelPosition: LabelPosition.Bottom
                                                        }
                                                    ]}
                                                    unavailable={!report.exerciseAndRecoveryReport.recoveryScore.isCalculated}
                                                />
                                            </Box>
                                        </Box>
                                        <Box textAlign="center" className="text-medium" mt={2}>
                                            {report.exerciseAndRecoveryReport.recoveryScore.result}
                                        </Box>
                                        <Box display="flex" justifyContent="center">
                                            <Box mt={7} width="80%">
                                                <DnaTableComponent
                                                    dna={report.exerciseAndRecoveryReport.recoveryScore.dna}
                                                    colorMap={exerciseRiskColorMap} />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={4} md={4} lg={4}>
                                    <Box>
                                        <Box display="flex" justifyContent="center">
                                            <Box width={0.8} display="flex" justifyContent="space-between" className="text-medium normal-line-height">
                                                <Box>
                                                    <p>Low</p>
                                                    <p>Intensity</p>
                                                </Box>
                                                <Box textAlign="right">
                                                    <p>High</p>
                                                    <p>Intensity</p>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box display="flex" justifyContent="center" mt={2}>
                                            <Box width={0.8}>
                                                <ProgressDiagramComponent
                                                    height={22}
                                                    colors={['#129ad4', '#fc002e']}
                                                    data={[
                                                        {
                                                            label: "",
                                                            value: report.exerciseAndRecoveryReport.intensityScore.scorePercent,
                                                            labelPosition: LabelPosition.Bottom
                                                        }
                                                    ]}
                                                    unavailable={!report.exerciseAndRecoveryReport.intensityScore.isCalculated}
                                                />
                                            </Box>
                                        </Box>
                                        <Box textAlign="center" className="text-medium" mt={2}>
                                            {report.exerciseAndRecoveryReport.intensityScore.result}
                                        </Box>
                                        <Box display="flex" justifyContent="center">
                                            <Box mt={7} width="80%">
                                                <DnaTableComponent
                                                    dna={report.exerciseAndRecoveryReport.intensityScore.dna}
                                                    colorMap={exerciseRiskColorMap} />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </HealthReportBasePage>
        )
    )
}
