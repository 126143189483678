import React from 'react';
import { withRouter } from 'react-router';
import { AuthenticatedLayoutComponent } from '../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from "../../../../models/menu.models";
import { AlertsHistoryComponent } from '../../../employee/components/alertsHistoryComponent/AlertsHistoryComponent';

class AlertsHistoryPage extends React.Component<any> {
    render() {
        return (
            <AuthenticatedLayoutComponent
                hideBackground
                title={TitlesService.alertsHistoryTitle}
                pageName={PagesNamesService.alertsHistory}
                selectedMenuItem={MenuItemTypes.Dashboard}
            >
                <AlertsHistoryComponent />
            </AuthenticatedLayoutComponent>
        )
    }
}

export default withRouter(AlertsHistoryPage);
