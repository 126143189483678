import React from 'react';
import { useHistory } from 'react-router';
import { Box } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import clsx from 'clsx';
import { AuthenticatedLayoutComponent } from '../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from "../../../../models/menu.models";
import { commonUseStyles } from "../../../common/styles/common.styles";
import { navigationService } from '../../../../services/navigation.service';
import { ConfigurationEditComponent } from '../../components/configurationEditComponent/ConfigurationEditComponent';

export const InsuranceConfigurationEditPage: React.FC<any> = (props: any) => {
  const configurationId = Number(props.match.params.configurationId);

  const history = useHistory();
  const commonClasses = commonUseStyles();

  return (
    <AuthenticatedLayoutComponent
      hideBackground
      title={TitlesService.insuranceConfigurationsTitle}
      pageName={PagesNamesService.insuranceConfigurations}
      selectedMenuItem={MenuItemTypes.InsuranceConfigurations}
    >
      <Box p="30px">
        <Box id="insurance-configurations-go-back" py={2} display="flex" alignItems="center" className={clsx(commonClasses.colorGray1, commonClasses.backgroundColorBg, commonClasses.pointer)} onClick={() => navigationService.goBack(history)}>
          <Box>
            <ArrowBack fontSize="small"/>
          </Box>
          <Box ml={1}>
            <span>Back</span>
          </Box>
        </Box>
        <ConfigurationEditComponent configurationId={configurationId} />
      </Box>
    </AuthenticatedLayoutComponent>
  )
}
