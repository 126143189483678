import React from 'react';
import {Box, Divider} from '@material-ui/core';
import {displayMoney} from "../../../common/helpers/display-money";
import {PaymentPlanModel} from "../../models/paymentPlan.models";
import {PaymentPeriodModel} from "../../models/paymentPeriod.models";
import {DiscountModel, DiscountSource, PaymentPriceModel, PaymentPriceType, PaymentStrategy} from "../../models/paymentPrice.models";
import {SelectAddOnModel} from '../../../addons/models/addOns.models';
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from 'clsx';
import {
    SelectAddOnsCardComponent
} from "../../../addons/components/SelectAddOnsCardTestComponent/SelectAddOnsCardComponent";
import {useStyles} from "./billingSummaryComponent.styles";
import {ReactComponent as InfoCircleIcon} from '@img/icons/InfoCircleIcon.svg';
import {
    WildHealthClickableTooltip
} from "../../../common/components/WildHealthClickableTooltip/WildHealthClickableTooltip";

interface BillingSummaryComponentProps {
    selectedPlan?: PaymentPlanModel;
    selectedPeriod?: PaymentPeriodModel;
    selectedPrice?: PaymentPriceModel;
    selectedAddOns?: SelectAddOnModel[];
    hasDiscount?: boolean;
    hasContainer?: boolean;
    addOns?: SelectAddOnModel[];
    handleToggleSelectingAddOn?: (id: number, value: boolean) => void;
}

export const BillingSummaryTestedComponent: React.FC<BillingSummaryComponentProps> = (props: BillingSummaryComponentProps) => {
    const {
        selectedPlan,
        selectedPrice,
        selectedAddOns,
        hasDiscount,
        addOns,
        handleToggleSelectingAddOn,
    } = props;

    const classes = useStyles();
    const commonClasses = commonUseStyles();

    const customTooltipClasses = {
        tooltip: classes.customWidth,
        arrow: classes.arrow,
        tooltipPlacementBottom: classes.tooltipPlacement,
        tooltipPlacementTop: classes.tooltipPlacement,
    };

    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const displayPostfix = (paymentPlan: PaymentPlanModel, paymentPrice: PaymentPriceModel) => {
        return paymentPlan.name === 'PRECISION_CARE_PACKAGE' ? '' : paymentPrice.strategy === PaymentStrategy.FullPayment ? '/yr' : '/mo'
    };

    const startupFee = selectedPrice?.startupFee ?? 0;
    const subTotalPrice = selectedPrice?.originalPrice ?? 0;
    const discount = selectedPrice?.discount ?? 0;
    const totalPrice = (selectedPrice?.originalPrice ?? 0) + startupFee - discount +
        (selectedAddOns?.reduce((sum, current) => sum + current.price, 0) ?? 0);

    const tooltipContent = (
        <>
            <p className={classes.notificationToolTipText}>
                Includes:
            </p>
            <Box pl={2}>
                <ul className={classes.startupFeeTooltip}>
                    <li>Proprietary genetic testing</li>
                    <li>Replacement DNA kit if needed</li>
                    <li>Personal onboarding support via care coordinator</li>
                </ul>
            </Box>
        </>
    )

    const showYearMonth = (discountModel: DiscountModel, paymentStrategy:PaymentStrategy) => {
        if(discountModel.source == DiscountSource.Coupon || discountModel.source == DiscountSource.Insurance){
            if(paymentStrategy == PaymentStrategy.FullPayment){
                return " / year";
            }
            if(paymentStrategy == PaymentStrategy.PartialPayment){
                return " / month";
            }
        }
    }

    const renderContent = () => {
        return (
            <>
                <Box pl={2} py={1}>
                    <Box display="flex" justifyContent="space-between">
                        {
                            selectedPlan && [
                                <Box key={`plan-${subTotalPrice}`} className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorDarkGray)}>
                                    Plan Price
                                </Box>,
                                <Box key={`plan-info-${subTotalPrice}`}>
                                    <span className={clsx(commonClasses.size18, commonClasses.textMedium, commonClasses.colorLinkBlack)}>
                                        {displayMoney(subTotalPrice, '$', false, true)}{displayPostfix(selectedPlan, selectedPrice)}
                                    </span>
                                </Box>
                            ]
                        }
                    </Box>
                    <Box mt={2}>
                        {
                            hasDiscount && discount > 0 && !!selectedPrice.discounts &&
                            <Box>
                                {
                                    selectedPrice.discounts.map(x=>{
                                        return(
                                        <Box key={`promo-code-${x.amount}-${x.source}`} display="flex" justifyContent="space-between">
                                            <Box className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorDarkGray)}>
                                                <span>
                                                    {x.name}
                                                </span>
                                            </Box>
                                            <Box textAlign="right">
                                                <span className={clsx(commonClasses.textMedium, commonClasses.colorAccent1)}>
                                                    {displayMoney(x.amount, '$', true, true)}{showYearMonth(x, selectedPrice.strategy)}
                                                </span>
                                            </Box>
                                        </Box>)
                                    })
                                }  
                            </Box>
                        }
                        {
                            startupFee > 0 &&
                            <Box key={`startup-fee-${startupFee}`} display="flex" justifyContent="space-between" mt={2}>
                                <Box display="flex" className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorDarkGray)}>
                                    <span>
                                        One Time Startup Fee
                                    </span>
                                    <WildHealthClickableTooltip
                                        tooltipContent={tooltipContent}
                                        isOpened={open}
                                        handleClose={handleTooltipClose}
                                        customClasses={customTooltipClasses}
                                    >
                                        <Box ml={1} className={commonClasses.pointer}>
                                            <InfoCircleIcon onClick={handleTooltipOpen} />
                                        </Box>
                                    </WildHealthClickableTooltip>
                                </Box>
                                <Box textAlign="right">
                                    <span className={clsx(commonClasses.size18, commonClasses.textMedium, commonClasses.colorLinkBlack)}>
                                        ${startupFee}
                                    </span>
                                </Box>
                            </Box>
                        }
                    </Box>
                </Box>
                <Box mt={2}>
                    <Divider />
                </Box>
                <Box mt={2}>
                    {
                        !selectedPlan.isTrial &&
                        <SelectAddOnsCardComponent
                            items={addOns}
                            handleSelectingItem={handleToggleSelectingAddOn}
                        />
                    }
                </Box>
                <Box mt={2}>
                    <Divider />
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                    <Box className={clsx(commonClasses.size16, commonClasses.textMedium, commonClasses.colorDarkGray)}>
                        <span>
                            Total Due Today
                        </span>
                    </Box>
                    <Box textAlign="right">
                        <span className={clsx(commonClasses.size22, commonClasses.textMedium, commonClasses.colorLinkBlack)}>
                            {displayMoney(totalPrice, '$', false, true)}
                        </span>
                    </Box>
                </Box>
            </>
        )
    }

    return (
        renderContent()
    )
};

export const BillingSummaryComponent: React.FC<BillingSummaryComponentProps> = (props: BillingSummaryComponentProps) => {
    const {
        selectedPlan,
        selectedPrice,
        selectedAddOns,
        hasDiscount,
    } = props;

    const classes = useStyles();
    const commonClasses = commonUseStyles();

    const customTooltipClasses = {
        tooltip: classes.newDesignWidth,
        arrow: classes.newDesignArrow,
        tooltipPlacementBottom: classes.tooltipPlacement,
        tooltipPlacementTop: classes.tooltipPlacement,
    };

    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const startupFee = selectedPrice?.startupFee ?? 0;
    const subTotalPrice = selectedPrice?.originalPrice ?? 0;
    const discount = selectedPrice?.discount ?? 0;
    const totalPrice = (selectedPrice?.originalPrice ?? 0) + startupFee - discount +
        (selectedAddOns?.reduce((sum, current) => sum + current.price, 0) ?? 0);

    const tooltipContent = (
        <>
            <p className={classes.notificationToolTipText}>
                What’s Included?
            </p>
            <Box pl={2}>
                <ul className={classes.startupFeeTooltip}>
                    <li>Wild Health Genetic Test Kit</li>
                    <li>1x replacement DNA kit, as needed</li>
                    <li>1-on-1 onboarding support by a Care Coordinator</li>
                    <li>Shipping & processing</li>
                </ul>
            </Box>
        </>
    )

    const getDiscountWording = () => {
        switch (selectedPrice.type) {
            case PaymentPriceType.Insurance: return 'Insurance Discount';
            case PaymentPriceType.Default: return selectedPrice.strategy === PaymentStrategy.FullPayment
                ? 'Annual Savings'
                : '';
            case PaymentPriceType.PromoCode: return selectedPrice.strategy === PaymentStrategy.PartialPayment
                ? `Promo Discount (${selectedPrice.paymentCoupon?.code})`
                : 'Annual Savings & Promo Code'
            case PaymentPriceType.InsurancePromoCode: return 'Insurance Discount & Promo Code'
        }
    }

    return (
        <>
            <Box>
                <Box display="flex" justifyContent="space-between">
                    {
                        selectedPlan && [
                            <Box key={`plan-${subTotalPrice}`} className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorGray700)}>
                                Subtotal
                            </Box>,
                            <Box key={`plan-info-${subTotalPrice}`}>
                                <span className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorLinkBlack)}>
                                    {displayMoney(subTotalPrice, '$')}
                                </span>
                            </Box>
                        ]
                    }
                </Box>
                <Box mt={1}>
                    {
                        startupFee > 0 &&
                        <Box key={`startup-fee-${startupFee}`} display="flex" justifyContent="space-between" mt={1}>
                            <Box display="flex" className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorGray700)}>
                                <span>One-Time Start-Up Fee</span>
                                <WildHealthClickableTooltip
                                    tooltipContent={tooltipContent}
                                    isOpened={open}
                                    handleClose={handleTooltipClose}
                                    customClasses={customTooltipClasses}
                                >
                                    <Box ml={1} className={commonClasses.pointer}>
                                        <InfoCircleIcon onClick={handleTooltipOpen} />
                                    </Box>
                                </WildHealthClickableTooltip>
                            </Box>
                            <Box textAlign="right">
                                <span className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorLinkBlack)}>
                                    ${startupFee}
                                </span>
                            </Box>
                        </Box>
                    }
                    {
                        hasDiscount && discount > 0 &&
                        <Box key={`promo-code-${discount}`} display="flex" justifyContent="space-between" mt={1}>
                            <Box className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorGray700)}>
                                <span>
                                    {getDiscountWording()}
                                </span>
                            </Box>
                            <Box textAlign="right">
                                <span className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorLinkBlack)}>
                                    {displayMoney(discount, '$', true)}
                                </span>
                            </Box>
                        </Box>
                    }
                </Box>
            </Box>
            <Box my={2}>
                <Divider />
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box className={clsx(commonClasses.textMedium, commonClasses.colorLinkBlack)}>
                    <span>
                        Total Due Today
                    </span>
                </Box>
                <Box textAlign="right">
                    <span className={clsx(commonClasses.size18, commonClasses.textMedium, commonClasses.colorLinkBlack)}>
                        {displayMoney(totalPrice, '$')}
                    </span>
                </Box>
            </Box>
        </>
    )
};