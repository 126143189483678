import { GeneralValidator } from "../../../common/validation/general-validator";
import {
  notWhitespaceValidationRule,
  requiredValidationRule,
  numberValidationRule,
} from "../../../common/validation/general-validation-rules";

export const createCommonGoalValidator = new GeneralValidator({
  'name': [notWhitespaceValidationRule(), requiredValidationRule()],
  'completionValue': [numberValidationRule, requiredValidationRule()],
  'category': [requiredValidationRule()],
});