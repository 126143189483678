import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { onEmit } from "../../../common/helpers/on-emit";
import {
    QuestionnaireModel,
    QuestionnaireResultModel
} from "../../models/questionnaire.models";
import { questionnaireService } from "../../services/questionnaire.service";
import { questionnaireQuery } from "../../stores/questionnaireStore";
import { navigationService } from "../../../../services/navigation.service";
import { useHistory } from "react-router-dom";
import { PatientProfileModel } from "../../../account/models/patientProfile.model";
import { profileService } from "../../../account/services/profile.service";
import { profileQuery } from "../../../account/stores/profileStore";

export interface PatientQuestionnairePageState {
    isLoading: boolean;
    result: QuestionnaireResultModel;
    questionnaire: QuestionnaireModel;
    profile: PatientProfileModel;
}

export function useFacade(questionnaireResultId: number): [PatientQuestionnairePageState, Function, Function] {
    const history = useHistory();
    const [state, setState] = useState({
        isLoading: true,
        result: null,
        questionnaire: null,
    } as PatientQuestionnairePageState);

    const handleGoToQuestionnaires = () => {
        navigationService.toMyHealthForms(history);
    }

    const handleGoToDashboard = () => {
        navigationService.toDashboard(history);
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<QuestionnaireResultModel>(questionnaireQuery.targetResult$, result => {
                if (result) {
                    setState(state => ({
                        ...state,
                        result: result,
                        questionnaire: result.questionnaire
                    }));
                }
            }),
            onEmit<PatientProfileModel>(profileQuery.patientProfile$, profile => {
                setState(state => ({
                    ...state,
                    profile: profile
                }))
            })
        ];

        profileService.getPatientProfile().subscribe(() => {
            questionnaireService.getResult(questionnaireResultId).subscribe(() => {
                setState((state) => ({
                    ...state,
                    isLoading: false
                }));
            });
        });

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    };

    useEffect(useEffectCB, []);

    return [state, handleGoToQuestionnaires, handleGoToDashboard];
}