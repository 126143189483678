import React from "react";
import { Box, Dialog, DialogContent, Button, useTheme, useMediaQuery } from '@material-ui/core';
import { SelectDateTimeComponent } from '../../../appointments/components/selectDateTime/SelectDateTimeComponent';
import { DialogTitle } from '../../../common/components/dialogTitle/DialogTitle';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import { useFacade } from './scheduleMessageDialogComponent.hooks';
import { useStyles } from "./scheduleMessageDialogComponent.styles";

interface ScheduleMessageDialogComponentProps {
    isOpen: boolean;
    handleToggle: () => void;
    handleSubmit: (date: Date, time: Date) => void;
}

export const ScheduleMessageDialogComponent: React.FC<ScheduleMessageDialogComponentProps> = (props: ScheduleMessageDialogComponentProps) => {
    const {isOpen, handleToggle, handleSubmit} = props;

    const [
        {
            canSubmit,
            selectedTime,
            selectedDate,
            minDate
        },
        getAvailability,
        handleChangeTime,
        handleChangeDate,
        handleConfirm,
        handleCancel,
    ] = useFacade(handleSubmit, handleToggle);

    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <Dialog
            fullScreen={isSmallScreen}
            maxWidth="md"
            className={classes.dialog}
            open={isOpen}
            onClose={() => handleCancel()}
        >
            {
                !isSmallScreen &&
                <DialogTitle id="dialog-title" onClose={() => handleCancel()}>
                    <Box px={3}>
                        Schedule Message Delivery
                    </Box>
                </DialogTitle>
            }

            <DialogContent className={classes.dialogContent}>
                <Box width={isSmallScreen ? 1 : "548px"}>
                    {
                        isSmallScreen &&
                        <Box pt={1} pb={3} display="flex" justifyContent="space-between">
                            <Box flex={1}>
                                <Button onClick={() => handleCancel()}>
                                    <span className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-primaryV wh-tw-capitalize">
                                        Cancel
                                    </span>
                                </Button>
                            </Box>
                            <Box flex={2} textAlign="center">
                                <span className="wh-tw-text-xl wh-tw-font-medium wh-tw-text-black">
                                    Schedule Message Delivery
                                </span>
                            </Box>
                            <Box flex={1}></Box>
                        </Box>
                    }
                    <Box py={3}>
                        <SelectDateTimeComponent
                            selectedTime={selectedTime}
                            selectedDate={selectedDate}
                            minDate={minDate}
                            handleChangeTime={(time: Date) => handleChangeTime(time)}
                            handleChangeDate={(date: Date) => handleChangeDate(date)}
                            availability={getAvailability()}
                            disabledTile={false}
                            patientTimezone='Time'
                        />
                    </Box>
                    <Box display="flex" justifyContent="flex-end">
                        <Box hidden={isSmallScreen}>
                            <WildHealthButton
                                id="schedule-message-cancel"
                                onClick={() => handleCancel()}
                                color='tertiary'
                                size='large'
                            >
                                Cancel
                            </WildHealthButton>
                        </Box>
                        <Box ml={!isSmallScreen && 1} flex={isSmallScreen && 1}>
                            <WildHealthButton
                                id="schedule-message-delivery"
                                disabled={!canSubmit}
                                onClick={() => handleConfirm()}
                                size='large'
                                fullWidth={isSmallScreen}
                            >
                                Schedule Delivery
                            </WildHealthButton>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}