import Axios from 'axios-observable';
import {authHeader} from '../../common/helpers/auth-header';
import {snackService} from '../../common/snack/state';
import {LocationsStore, locationsStore} from '../stores';
import {CreateLocationModel, LocationModel, UpdateLocationModel} from "../models/locations.models";
import {Observable, Subject} from "rxjs";
import {CallbackModel} from "../../common/models/callback.model";

export class LocationsService {
    private url = `${process.env.REACT_APP_API_URL}Locations`;

    constructor(private locationsStore: LocationsStore) {
    }

    public locationOnCreate = new Subject<CallbackModel<LocationModel>>();
    public locationOnUpdate = new Subject<CallbackModel<LocationModel>>();

    public createLocation(): Observable<CreateLocationModel> {
        return new Observable(observer => {
            this.locationOnCreate.next({
                data: null,
                callback: (result: CreateLocationModel) => {
                    if (result) {
                        observer.next(result);
                    }
                    observer.complete();
                }
            });
        });
    }

    public updateLocation(location: LocationModel): Observable<UpdateLocationModel> {
        return new Observable(observer => {
            this.locationOnUpdate.next({
                data: location,
                callback: (result: UpdateLocationModel) => {
                    if (result) {
                        observer.next(result);
                    }
                    observer.complete();
                }
            });
        });
    }

    public getAvailable(practiceId: number): Observable<LocationModel[]> {
        const config = {headers: authHeader()};

        return new Observable<LocationModel[]>(observer => {
            Axios.get<LocationModel[]>(`${this.url}?practiceId=${practiceId}`, config).pipe()
                .subscribe(
                    response => {
                        this.locationsStore.update({availableLocations: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public create(model: CreateLocationModel): Observable<LocationModel> {
        const config = {headers: authHeader()};

        return new Observable<LocationModel>(observer => {
            Axios.post<LocationModel>(this.url, model, config).pipe()
                .subscribe(
                    response => {
                        this.locationsStore.add(response.data);
                        snackService.success('Pod was successfully created!');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public update(model: UpdateLocationModel): Observable<LocationModel> {
        const config = {headers: authHeader()};

        return new Observable<LocationModel>(observer => {
            Axios.put<LocationModel>(this.url, model, config).pipe()
                .subscribe(
                    response => {
                        this.locationsStore.edit(response.data);
                        snackService.success('Pod was successfully updated!');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }
}

export const locationsService = new LocationsService(locationsStore);
