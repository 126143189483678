import {IErrorState} from "../../../common/validation/error-state";
import {useState} from "react";
import {CouponCodeModel} from "../../models/paymentCoupon.model";
import {applyCouponCodeValidator} from "./applyPaymentCoupon.validator";


interface ApplyPaymentCouponState extends IErrorState {
    model: CouponCodeModel;
}

export function useFacade(couponCode: string, applyCoupon: Function): [ApplyPaymentCouponState, Function, Function] {
    const [state, setState] = useState({
        model: {
            couponCode: couponCode ?? ''
        },
        errors: {}
    } as ApplyPaymentCouponState);

    const handleCouponChanges = (value: string) => {
        state.model.couponCode = value;
        setState({
            ...state,
            model: {...state.model}
        });

        applyCouponCodeValidator.validateAndSetState(state, setState, "couponCode", state.model.couponCode);
    }

    const handleCouponApply = () => {
        applyCouponCodeValidator.validateAndSetState(state, setState, "couponCode", state.model.couponCode);
        if (applyCouponCodeValidator.stateIsValid(state)) {
            applyCoupon(state.model.couponCode);
        }
    };

    return [state, handleCouponChanges, handleCouponApply];
}