import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { AppointmentStatus } from "../../../../../appointments/models/appointments.enums";
import { EmployeeAppointmentModel, PatientAppointmentModel } from "../../../../../appointments/models/appointments.models";
import { appointmentsService } from "../../../../../appointments/services/appointments.service";
import { appointmentsQuery } from "../../../../../appointments/stores/appointments";
import { onEmit } from "../../../../../common/helpers/on-emit";
import { PatientModel } from "../../../../../patients/models/patient.model";
import { patientsQuery } from "../../../../../patients/stores/patientsStore";

export enum AppointmentsEnum {
  Upcoming,
  Previous
}
interface AppointmentWidgetComponentState {
  isLoading: boolean;
  menuAnchorEl: HTMLElement;
  appointments: EmployeeAppointmentModel[];
  previousAppointments: EmployeeAppointmentModel[];
  selectedId: number;
  selectedAppointment: EmployeeAppointmentModel;
  patient: {
    firstName: string;
    lastName: string;
  },
  tabSelected: AppointmentsEnum;
}

export function useFacade(patientId: number): [
  AppointmentWidgetComponentState,
  (id: number, menuAnchorEl?: HTMLElement) => void,
  () => void,
  () => void,
  (event: React.ChangeEvent<{}>, newValue: number) => void,
] {
  const [state, setState] = useState({
    isLoading: true,
    menuAnchorEl: null,
    appointments: [],
    previousAppointments: [],
    selectedId: null,
    selectedAppointment: null,
    patient: {},
    tabSelected: AppointmentsEnum.Upcoming
  } as AppointmentWidgetComponentState);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setState(state => ({
      ...state,
      tabSelected: newValue
    }))
  }

  const handleToggleMenu = (appointmentId: number, menuAnchorEl?: HTMLElement) => {
    const appointment = state.appointments.find(x => x.id === appointmentId);
    if (!appointment) {
      setState(state => ({ ...state, menuAnchorEl: null }));
      return;
    }
    setState(state => ({
      ...state,
      menuAnchorEl,
      selectedId: appointmentId,
      selectedAppointment: appointment,
    }))
  }

  const handleCancel = () => {
    setState(state => ({ ...state, menuAnchorEl: null }));
    appointmentsService.openCancelAppointmentSchedule()
      .subscribe(
        () => appointmentsService.cancelAsPatient(state.selectedId)
      );
  }

  const handleRescheduleAppointment = () => {
    setState(state => ({ ...state, menuAnchorEl: null }));
    const appointment = state.appointments.find(x => x.id === state.selectedAppointment.id);
    if (!appointment) {
      return;
    }

    appointmentsService.openPatientAppointmentReschedule(state.selectedAppointment, patientId).subscribe();
  }


  const useEffectCB = () => {
    const subscriptions: Subscription[] = [
      onEmit<PatientModel>(patientsQuery.targetPatient$, targetPatient => {
        if (targetPatient?.id === Number(patientId)) {
          setState(state => ({
            ...state,
            patient: {
              firstName: targetPatient.firstName,
              lastName: targetPatient.lastName,
            },
          }));
        }
      }),
      onEmit<PatientAppointmentModel[]>(appointmentsQuery.patientAppointments$, appointments => {
        const filteredAppointments = appointments?.filter(appt => appt.status !== AppointmentStatus.Canceled)
        if (filteredAppointments.length > 5) {
          setState(state => ({ ...state, appointments:  filteredAppointments.slice(0, 5)}));
        } else {
          setState(state => ({ ...state, appointments:  filteredAppointments}));
        }
        
      }),
    ];


    appointmentsService.getPatientAppointmentsById(patientId, null, new Date()).subscribe(
      (previousAppt: PatientAppointmentModel[]) => {
        appointmentsService.getPatientAppointmentsById(patientId, new Date()).subscribe(() => {
          if (previousAppt && previousAppt.length > 5) {
            setState(state => ({ ...state, isLoading: false, previousAppointments: previousAppt.slice(0, 5) }));
          } else {
            setState(state => ({ ...state, isLoading: false, previousAppointments: previousAppt }));
          }
        },
          () => {
            setState(state => ({ ...state, isLoading: false }));
          });
      },
      () => {
        setState(state => ({ ...state, isLoading: false }));
      }
    )


    return (() => {
      subscriptions.map(it => it.unsubscribe())
    })
  }

  useEffect(useEffectCB, [patientId]);

  return [state, handleToggleMenu, handleRescheduleAppointment, handleCancel, handleTabChange];
}
