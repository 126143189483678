import React from "react";
import moment from 'moment';
import { AreaChart, Area, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import { colors } from "../../../common/constants/colors";
import { InputModel } from "../../models/input.models";

interface LabResultsGraphComponentProps {
  data: InputModel[];
  renderRange?: boolean;
  minValue?: number;
  maxValue?: number;
}

function CustomTooltip({ payload, active }: {payload?: any, active?: boolean}) {
  if (active) {
    return (
      <div className="wh-tw-bg-navy900 wh-tw-p-2 wh-tw-rounded-lg">
        <div className="wh-tw-text-white wh-tw-text-xs">{`${payload[0].value}`}</div>
      </div>
    );
  }

  return null;
}

export const LabResultsGraphComponent: React.FC<LabResultsGraphComponentProps> = (
  props: LabResultsGraphComponentProps
) => {
  const { data, renderRange, minValue, maxValue } = props;
  
  const graphData: object[] = data.map((value) => {
    return {
      min: minValue,
      max: maxValue,
      value: value.value,
      date: moment(value.date).format("MM/DD")
    };
  });

  const CheckIsNormal = (value: number): boolean => {
    if (minValue !== null && value <= minValue) {
      return false;
    }

    return !(maxValue !== null && value >= maxValue);
  };

  const CustomizedDot = (props) => {
    const { cx, cy, value } = props;

    if (CheckIsNormal(value[1])) {
      return (
        <circle
          key={`${cx}-${value[1]}`}
          cx={cx}
          cy={cy}
          r={2}
          stroke={colors.navy900}
          strokeWidth={3.5}
          fill={colors.navy900}
        />
      );
    }

    return (
      <circle
        cx={cx}
        cy={cy}
        r={2}
        stroke={colors.bad}
        strokeWidth={3.5}
        fill={colors.bad}
      />
    );
  };

  return (
    <ResponsiveContainer
      width={"100%"}
      height={160}
    >
      <AreaChart
        data={graphData}
        margin={{
          top: 5,
          right: 0,
          left: -25,
          bottom: 5,
        }}
      >
        {renderRange && minValue !== null && (
          <Area
            key='min-value'
            isAnimationActive={false}
            type='step'
            dataKey='min'
            stroke={colors.gray2}
            strokeWidth={1}
            strokeDasharray='10 10'
            fill={colors.white}
            fillOpacity={0}
          />
        )}
        {renderRange && maxValue !== null && (
          <Area
            key='max-value'
            isAnimationActive={false}
            type='step'
            dataKey='max'
            stroke={colors.gray2}
            strokeWidth={1}
            strokeDasharray='10 10'
            fill={colors.white}
            fillOpacity={0}
          />
        )}
        <Area
          key='value'
          isAnimationActive={false}
          type='linear'
          dataKey='value'
          stroke={colors.navy900}
          strokeWidth={2}
          fill={colors.white}
          fillOpacity={0.25}
          dot={CustomizedDot}
        />
        <CartesianGrid stroke={colors.gray200} vertical={false} />
        <XAxis dataKey="date" padding={{ left: 30, right: 30 }} axisLine={false} tickLine={false} tick={{fontSize: 10}} />
        <YAxis axisLine={false} tickLine={false} tick={{fontSize: 10}} />
        <Tooltip content={<CustomTooltip />} cursor={false} />
      </AreaChart>
    </ResponsiveContainer>
  );
};
