import React from "react"
import { Box, Dialog, DialogContent, Button, useTheme, useMediaQuery } from "@material-ui/core";
import {DialogTitle} from '../../../common/components/dialogTitle/DialogTitle';
import { SelectUserComponent } from "../../../common/components/selectUserComponent/SelectUserComponent";
import { useFacade } from "./selectUserDialogComponent.hooks";
import { Observable } from "rxjs";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { useStyles } from "./selectUserDialogComponent.styles";

interface SelectUserDialogComponentProps {
    isOpen: boolean;
    title: string;
    message: string;
    placeholder?: string;
    actionName: string;
    handleGetUsers: (searchQuery: string) => Observable<any>;
    handleCancel: () => void;
    handleSubmit: (userId: number) => void;
}

export const SelectUserDialogComponent: React.FC<SelectUserDialogComponentProps> = (props: SelectUserDialogComponentProps) => {
    const { isOpen, title, message, placeholder = 'Select User', actionName, handleGetUsers, handleCancel, handleSubmit } = props;

    const [
        {
            isUsersLoading,
            users,
            canSubmit,
            selectedUser,
        },
        handleUserSelect,
        handleUserSearch,
        handleCloseDialog,
        handleApprove,
    ] = useFacade(handleGetUsers, handleSubmit, handleCancel);

    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <Dialog
            fullScreen={isSmallScreen}
            maxWidth="md"
            className={classes.dialog}
            open={isOpen}
            onClose={() => handleCloseDialog()}
            aria-labelledby="form-dialog-title"
        >
            {
                !isSmallScreen &&
                <DialogTitle id='dialog-title' onClose={() => handleCloseDialog()}>{title}</DialogTitle>
            }
            <DialogContent className={classes.dialogContent}>
                <Box width={isSmallScreen ? 1 : "548px"}>
                    {
                        isSmallScreen &&
                        <Box pt={1} pb={3} display="flex" justifyContent="space-between">
                            <Button onClick={() => handleCloseDialog()}>
                                <span className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-primaryV wh-tw-capitalize">
                                    Cancel
                                </span>
                            </Button>
                            <Box>
                                <span className="wh-tw-text-xl wh-tw-font-medium wh-tw-text-black">
                                    {title}
                                </span>
                            </Box>
                            <Button disabled={!canSubmit} onClick={() => handleApprove()}>
                                <span className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-primaryV wh-tw-capitalize">
                                    {actionName}
                                </span>
                            </Button>
                        </Box>
                    }
                    <SelectUserComponent
                        title={message}
                        placeholder={isUsersLoading ? "Loading..." : placeholder}
                        selectedUser={selectedUser}
                        users={isUsersLoading ? [] : users}
                        handleChange={(user) => handleUserSelect(user)}
                        handleInputChange={handleUserSearch}
                        isLoading={isUsersLoading}
                    />
                </Box>
                {
                    !isSmallScreen &&
                    <Box display="flex" justifyContent="flex-end" py={2}>
                        <Box>
                            <WildHealthButton
                                id="select-user-cancel"
                                onClick={() => handleCloseDialog()}
                                color='tertiary'
                                size='large'
                            >
                                Cancel
                            </WildHealthButton>
                        </Box>
                        <Box ml={1}>
                            <WildHealthButton
                                id={`select-user-${actionName}`}
                                disabled={!canSubmit}
                                onClick={() => handleApprove()}
                                size='large'
                            >
                                {actionName}
                            </WildHealthButton>
                        </Box>
                    </Box>
                }
            </DialogContent>
        </Dialog>
    )
}
