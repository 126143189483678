import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { colors } from "../../constants/colors";

interface useStylesProps {
  minWidth?: number;
  paddingRight?: number;
  paddingLeft?: number;
  borderRadius?: number;
  fontWeight?: number;
}

export const useStyles = (props: useStylesProps) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        fontSize: 14,
        fontWeight: props.fontWeight,
        height: 30,
        borderRadius: props.borderRadius,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "fit-content",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        minWidth: props.minWidth,
        paddingRight: props.paddingRight,
        paddingLeft: props.paddingLeft,
        [theme.breakpoints.down("xs")]: {
          maxWidth: 224,
        },
      },
      good: {
        color: colors.good,
        backgroundColor: `${colors.good}14`,
        [theme.breakpoints.down("xs")]: {
          backgroundColor: `${colors.good}24`,
        },
      },
      selected: {
        color: colors.white,
        backgroundColor: colors.main,
      },
      average1: {
        color: colors.average1,
        backgroundColor: `${colors.average1}14`,
      },
      average2: {
        color: colors.average2,
        backgroundColor: `${colors.average2}14`,
      },
      bad: {
        color: colors.rose700,
        backgroundColor: colors.rose100,
      },
      normal: {
        color: colors.main,
        backgroundColor: `${colors.main}24`,
      },
      neutral: {
        color: colors.gray1,
        backgroundColor: `${colors.separators}`,
        [theme.breakpoints.down("xs")]: {
          color: colors.black,
          backgroundColor: colors.gray3,
        },
      },
      success: {
        color: colors.white,
        backgroundColor: `${colors.good}`,
        [theme.breakpoints.down("xs")]: {
          backgroundColor: `${colors.good}`,
        },
      },
    })
  );
