import {GeneralValidator} from "../../../common/validation/general-validator";
import {rangeValidationRule} from "../../../common/validation/general-validation-rules";
import {LabInputNames} from "../../constants/inputs.constants";
import { LabRangeModel, LabRangeType, LabValidationSource } from "../../models/input.models";

export const labInputsComponentValidator = new GeneralValidator({
    //Lipids
    [LabInputNames.TotalCholesterol]: [rangeValidationRule(0, null, "Value should be positive number.")],
    [LabInputNames.HdlCholesterol]: [rangeValidationRule(0, null, "Value should be positive number.")],
    [LabInputNames.LdlCholesterol]: [rangeValidationRule(0, null, "Value should be positive number.")],
    [LabInputNames.Triglycerides]: [rangeValidationRule(0, null, "Value should be positive number.")],
    [LabInputNames.LdlP]: [rangeValidationRule(0, null, "Value should be positive number.")],
    [LabInputNames.SmallLdlP]: [rangeValidationRule(0, null, "Value should be positive number.")],
    [LabInputNames.LpA]: [rangeValidationRule(0, null, "Value should be positive number.")],

    //Hormones
    [LabInputNames.Dhea]: [rangeValidationRule(0, null, "Value should be positive number.")],
    [LabInputNames.Igf]: [rangeValidationRule(0, null, "Value should be positive number.")],
    [LabInputNames.TestosteroneTotal]: [rangeValidationRule(0, null, "Value should be positive number.")],
    [LabInputNames.TestosteroneFree]: [rangeValidationRule(0, null, "Value should be positive number.")],
    [LabInputNames.Shbg]: [rangeValidationRule(0, null, "Value should be positive number.")],
    [LabInputNames.Estradio]: [rangeValidationRule(0, null, "Value should be positive number.")],
    [LabInputNames.Progesterone]: [rangeValidationRule(0, null, "Value should be positive number.")],
    [LabInputNames.Lh]: [rangeValidationRule(0, null, "Value should be positive number.")],
    [LabInputNames.Fsh]: [rangeValidationRule(0, null, "Value should be positive number.")],
    [LabInputNames.Tsh]: [rangeValidationRule(0, null, "Value should be positive number.")],
    [LabInputNames.FreeT4]: [rangeValidationRule(0, null, "Value should be positive number.")],
    [LabInputNames.FreeT3]: [rangeValidationRule(0, null, "Value should be positive number.")],
    [LabInputNames.Cortisol]: [rangeValidationRule(0, null, "Value should be positive number.")],

    //Methylation
    [LabInputNames.Homocysteine]: [rangeValidationRule(0, null, "Value should be positive number.")],
    [LabInputNames.Ast]: [rangeValidationRule(0, null, "Value should be positive number.")],
    [LabInputNames.Alt]: [rangeValidationRule(0, null, "Value should be positive number.")],
    [LabInputNames.B12]: [rangeValidationRule(0, null, "Value should be positive number.")],
    [LabInputNames.Folate]: [rangeValidationRule(0, null, "Value should be positive number.")],
    [LabInputNames.FolateRbc]: [rangeValidationRule(0, null, "Value should be positive number.")],
    [LabInputNames.Tmao]: [rangeValidationRule(0, null, "Value should be positive number.")],
    [LabInputNames.UricAcid]: [rangeValidationRule(0, null, "Value should be positive number.")],

    //Inflammation
    [LabInputNames.Crp]: [rangeValidationRule(0, null, "Value should be positive number.")],
    [LabInputNames.AgRatio]: [rangeValidationRule(0, null, "Value should be positive number.")],
    [LabInputNames.LpPla2]: [rangeValidationRule(0, null, "Value should be positive number.")],
    [LabInputNames.OxLdl]: [rangeValidationRule(0, null, "Value should be positive number.")],

    //Vitamins and MicroNutrients
    [LabInputNames.Ferritin]: [rangeValidationRule(0, null, "Value should be positive number.")],
    [LabInputNames.Omega3]: [rangeValidationRule(0, null, "Value should be positive number.")],
    [LabInputNames.VitaminD]: [rangeValidationRule(0, null, "Value should be positive number.")],
    [LabInputNames.CoQ10]: [rangeValidationRule(0, null, "Value should be positive number.")],

    //Insulin resistance / Metabolism
    [LabInputNames.FastingGlucose]: [rangeValidationRule(0, null, "Value should be positive number.")],
    [LabInputNames.FastingInsulin]: [rangeValidationRule(0, null, "Value should be positive number.")],
    [LabInputNames.HgbA1C]: [rangeValidationRule(0, null, "Value should be positive number.")],
});

export const WhOptimalRanges = new Map<string, LabRangeModel>([
    [LabInputNames.AgRatio, {from: 1, to: null, dimension: '', type: LabRangeType.MoreThen, source: LabValidationSource.Custom}],
    [LabInputNames.Crp, {from: null, to: 1, dimension: 'mg/L', type: LabRangeType.LessThanOrEqual, source: LabValidationSource.Custom}],
    [LabInputNames.LpPla2, {from: null, to: 200, dimension: 'nmol/min/mL', type: LabRangeType.LessThen, source: LabValidationSource.Custom}],
    [LabInputNames.OxLdl, {from: null, to: 60, dimension: 'U/L', type: LabRangeType.LessThen, source: LabValidationSource.Custom}],
    [LabInputNames.FastingGlucose, {from: null, to: 100, dimension: 'mg/dL', type: LabRangeType.LessThen, source: LabValidationSource.Custom}],
    [LabInputNames.FastingInsulin, {from: null, to: 5, dimension: 'mIU/mL', type: LabRangeType.LessThanOrEqual, source: LabValidationSource.Custom}],
    [LabInputNames.HgbA1C, {from: null, to: 5.5, dimension: '%', type: LabRangeType.LessThen, source: LabValidationSource.Custom}],
    [LabInputNames.LpA, {from: null, to: 75, dimension: 'nmol/L', type: LabRangeType.LessThen, source: LabValidationSource.Custom}],
    [LabInputNames.SmallLdlP, {from: null, to: 527, dimension: 'nmol/L', type: LabRangeType.LessThen, source: LabValidationSource.Custom}],
    [LabInputNames.LdlP, {from: null, to: 1000, dimension: 'nmol/L', type: LabRangeType.LessThen, source: LabValidationSource.Custom}],
    [LabInputNames.HdlCholesterol, {from: 50, to: null, dimension: 'mg/dL', type: LabRangeType.MoreThen, source: LabValidationSource.Custom}],
    [LabInputNames.TotalCholesterol, {from: null, to: 200, dimension: 'mg/dL', type: LabRangeType.LessThen, source: LabValidationSource.Custom}],
    [LabInputNames.LdlCholesterol, {from: null, to: 100, dimension: 'mg/dL', type: LabRangeType.LessThen, source: LabValidationSource.Custom}],
    [LabInputNames.ApolipoproteinB, {from: null, to: 90, dimension: 'mg/dL', type: LabRangeType.LessThanOrEqual, source: LabValidationSource.Custom}],
    [LabInputNames.Triglycerides, {from: null, to: 150, dimension: 'mg/dL', type: LabRangeType.LessThen, source: LabValidationSource.Custom}],
    [LabInputNames.UricAcid, {from: null, to: 5, dimension: 'mg/dL', type: LabRangeType.LessThen, source: LabValidationSource.Custom}],
    [LabInputNames.FolateRbc, {from: 280, to: null, dimension: 'ng/mL', type: LabRangeType.MoreThen, source: LabValidationSource.Custom}],
    [LabInputNames.Folate, {from: 12, to: 25, dimension: 'ng/mL', type: LabRangeType.FromTo, source: LabValidationSource.Custom}],
    [LabInputNames.B12, {from: 500, to: 1500, dimension: 'pg/mL', type: LabRangeType.FromTo, source: LabValidationSource.Custom}],
    [LabInputNames.Alt, {from: null, to: 20, dimension: 'U/L', type: LabRangeType.LessThanOrEqual, source: LabValidationSource.Custom}],
    [LabInputNames.Ast, {from: null, to: 20, dimension: 'U/L', type: LabRangeType.LessThanOrEqual, source: LabValidationSource.Custom}],
    [LabInputNames.Homocysteine, {from: null, to: 7, dimension: 'umol/L', type: LabRangeType.LessThen, source: LabValidationSource.Custom}],
    [LabInputNames.Tmao, {from: null, to: 5, dimension: 'uM', type: LabRangeType.LessThen, source: LabValidationSource.Custom}],
    [LabInputNames.Ferritin, {from: 30, to: 100, dimension: '', type: LabRangeType.FromTo, source: LabValidationSource.Custom}],
    [LabInputNames.VitaminD, {from: 50, to: 100, dimension: 'ng/mL', type: LabRangeType.FromTo, source: LabValidationSource.Custom}],
    [LabInputNames.CoQ10, {from: 0.75, to: null, dimension: 'ug/mL', type: LabRangeType.MoreThen, source: LabValidationSource.Custom}],
    [LabInputNames.Omega3, {from: 5.5, to: null, dimension: '', type: LabRangeType.MoreThen, source: LabValidationSource.Custom}],
]);