import React from 'react';
import './QuestionComponent.scss';
import { Box, TextField, } from "@material-ui/core";
import { QuestionComponentProps } from "./QuestionComponentProps";

export const QuestionNumberComponent: React.FC<QuestionComponentProps> = (props: QuestionComponentProps) => {
    const {
        disabled,
        question,
        answer,
        handleChanges,
        inline,
        error
    } = props;


    const inputProps = () => {
        return {
            min: 0,
            onKeyDown: (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
        }
    }

    return (
        <>
            <Box display="flex" justifyContent="flex-start"
                className={inline ? 'inline-input-question' : 'input-question'}>
                <Box flex={!question.dimension && 1}>
                    <TextField
                        fullWidth
                        type='number'
                        size="small"
                        id={question.name}
                        variant="outlined"
                        InputProps={{ className: 'input', inputProps: inputProps() }}
                        value={answer ?? ""}
                        error={!!error}
                        helperText={error}
                        disabled={disabled}
                        onChange={(v) => handleChanges(v.target.value)}
                    />
                </Box>
                {question.dimension && (
                    <Box ml={2} fontWeight="fontWeightLight" pt={1}>
                        {question.dimension}
                    </Box>
                )}
            </Box>
        </>
    )
}