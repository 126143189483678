import {GeneralValidator} from "../../../common/validation/general-validator";
import {
    stringLengthValidationRule,
    checkManyQuestionValidationRule,
    notWhitespaceValidationRule
} from "../../../common/validation/general-validation-rules";

export const healthGoalsQuestionnaireValidator = new GeneralValidator({
    'YOUR_INTENTION': [stringLengthValidationRule(1, 500), notWhitespaceValidationRule()],
    'YOUR_EXERCISE_GOALS': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'PERSONAL_EXERCISE_GOAL': [stringLengthValidationRule(1, 500), notWhitespaceValidationRule()],
    'EXERCISE_ROUTINE_LOOK_LIKE': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'YOUR_NUTRITION_GOALS': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'PERSONAL_NUTRITION_GOAL': [stringLengthValidationRule(1, 500), notWhitespaceValidationRule()],
    'TYPE_OF_FOOD_DO_YOU_EAT': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'YOUR_SLEEP_GOALS': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'PERSONAL_SLEEP_GOAL': [stringLengthValidationRule(1, 500), notWhitespaceValidationRule()],
    'GOOD_AND_BAD_SLEEP_ROUTINE': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'YOUR_MINDFULNESS_GOALS': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'PERSONAL_MINDFULNESS_GOAL': [stringLengthValidationRule(1, 500), notWhitespaceValidationRule()],
    'MINDFULNESS_PRACTICE_LOOK': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'YOUR_SOCIAL_CONNECTIONS': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'PERSONAL_SOCIAL_CONNECTION_GOAL': [stringLengthValidationRule(1, 500), notWhitespaceValidationRule()],
    'DESCRIBE_SOCIAL_CONNECTIONS': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'TAKE_TIME_TO_WALK_OUTSIDE': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'HEALS_HAPPINESS_PERFORMANCE': [stringLengthValidationRule(1, 500), notWhitespaceValidationRule()],
    'HEALTH_FEELING': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'HEALS_COMMENTS': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'OVERALL_PHYSICAL_HEALTH': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'FREE_CHRONIC_PAIN': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'FREE_CHRONIC_PAIN_COMMENTS': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'GET_SICK': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'SICK_COMMENTS': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'HEALTH_PHYSICAL_FEELING': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'HEALS_PHYSICAL_COMMENTS': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'HEALTH_MORE_OR_LESS': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'HEALS_MORE_OR_LESS_COMMENTS': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'HAPPINESS_ENJOY_LIFE': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'HAPPINESS_ENJOY_LIFE_COMMENT': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'HAPPINESS_CONNECTIONS': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'HAPPINESS_CONNECTIONS_COMMENT': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'HAPPINESS_OPTIMISTIC_PESSIMISTIC': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'HAPPINESS_OPTIMISTIC_PESSIMISTIC_COMMENT': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'HAPPINESS_COACH_THERAPIST': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'HAPPINESS_COACH_THERAPIST_COMMENT': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'DEPRESSION_BORDERED': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'DEPRESSION_BORDERED_COMMENT': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'HAPPINESS_INTO_MY_80': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'HAPPINESS_INTO_MY_80_COMMENT': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'HAPPINESS_OVERALL': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'HAPPINESS_OVERALL_COMMENT': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'PERFORMANCE_METRICS': [checkManyQuestionValidationRule],
    'PERFORMANCE_METRICS_EXPLAIN': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'PERFORMANCE_ACTIVITY_IMPORTANT': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'PERFORMANCE_ACTIVITY_IMPORTANT_COMMENT': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'PERFORMANCE_IMPROVEMENT': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'PERFORMANCE_IMPROVEMENT_COMMENT': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'PERFORMANCE_CONFIDENT_80S': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'PERFORMANCE_CONFIDENT_80S_COMMENT': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'PERFORMANCE_OVERALL': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'PERFORMANCE_OVERALL_COMMENT': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
});