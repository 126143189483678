import { HealthSummaryDataModel, HealthSummaryMapModel } from '../models/healthSummary.models'
import { getKey } from '../helpers/healthSummaryHelper'
import { Store, StoreConfig } from '@datorama/akita'

/**
 * Represents health summary state
 */
export interface HealthSummaryState {
    originData: HealthSummaryDataModel[]
    data: HealthSummaryDataModel[]
    map: HealthSummaryMapModel[]
    jsonDataSections: number
    jsonDataLoaded: number
}

/**
 * Creates initial state
 */
export function createInitialState(): HealthSummaryState {
    return {
        jsonDataSections: 0,
        jsonDataLoaded: 0,
        originData: [],
        data: [],
        map: []
    };
}

/**
 * Provides health summary states management
 */
@StoreConfig({name: 'healthSummary', resettable: true })
export class HealthSummaryStore extends Store<HealthSummaryState> {
    constructor() {
        super(createInitialState())
    }

    public setData(data: HealthSummaryDataModel[]) {
        const newData = this.getValue().data.concat(data)

        this.updateOrigin(newData)
    }

    public add(item: HealthSummaryDataModel, updateOrigin?: boolean) {
        const newData = [...this.getValue().data, item]

        updateOrigin ? this.updateOrigin(newData) : this.update({ data: newData })
    }

    public edit(item: HealthSummaryDataModel, updateOrigin?: boolean) {
        const data = this.getValue().data

        data[data.indexOf(data.find(x => x.key === item.key))] = item

        updateOrigin ? this.updateOrigin(data) : this.update({ data: [...data] })
    }

    
    public updateDataAfterSave() {
        const data = this.getValue().data

        const updatedData = data.filter(el => !el.isDeleted).map(el => {
            return {
                ...el,
                isNew: false,
            }
        })

        this.update({ data: [...updatedData] })
    }

    public delete(key: string, soft?: boolean) {
        if (soft) {
            const data = this.getValue().data

            const item = data.find(x => x.key === key)
            
            item.isDeleted = true

            this.update({ data: [...data] })
            
            return
        }

        this.update({ data: this.getValue().data.filter(x => x.key !== key) })
    }



    public clearSection(mapKey: string) {
        const map = this.getValue().map.find(x => x.key === mapKey)

        const keys = (map?.items ?? []).map(x => x.key).concat(mapKey)

        const newData = this.getValue().data.filter(x=> !keys.includes(getKey(x.key)))

        this.update({ data: newData })
    }

    public refreshData() {
        this.update({ data: [...this.getValue().originData] })
    }

    public clear() {
        this.update(createInitialState())
    }

    public sectionLoaded() {
        this.update({ jsonDataLoaded: this.getValue().jsonDataLoaded + 1 })
    }

    public isLoading(): boolean {
        const data = this.getValue()

        return data.jsonDataLoaded > 0 && data.jsonDataLoaded >= data.jsonDataSections
    }

    private updateOrigin(data: HealthSummaryDataModel[]) {
        this.update({ data: [...data], originData: data.map(x=> ({...x})) })
    }
}

export const healthSummaryStore = new HealthSummaryStore()
