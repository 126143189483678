import { useState } from 'react';
import { confirmService } from '../../../../services/confirm.service';
import { IErrorState } from '../../validation/error-state';

export interface ConfirmWithReasonDialogState extends IErrorState {
    isOpen: boolean;
    title: string;
    message: string;
    acceptTitle: string;
    newChoise: number;
    handleAnswer: Function;
}

const defaultState = {
    isOpen: false,
    title: '',
    message: '',
    acceptTitle: 'Submit',
    newChoise: null,
} as ConfirmWithReasonDialogState

export function useFacade(): [
    ConfirmWithReasonDialogState,
    () => void,
    () => void,
    (newChoise: any) => void,
] {
    const [state, setState] = useState(Object.assign({}, defaultState));

    const handleCancel = () => {
        handleAnswer(false);
    }

    const handleConfirm = () => {
        handleAnswer(true);
    }

    const handleDataCategoriesSelect = (newChoise: any) => {
        setState({ ...state, newChoise });
    }

    const handleAnswer = (result: boolean) => {
        setState(state => ({ ...defaultState, reason: '' }));
        state.handleAnswer(result, state.newChoise);
    }

    confirmService.onQuestionWithSelected.subscribe((question) => {
        setState(state => ({
            ...state,
            isOpen: true,
            title: question.data.title,
            message: question.data.message,
            acceptTitle: question.data.acceptTitle ?? state.acceptTitle,
            reason: question.data.newChoise,
            handleAnswer: question.callback
        }));
    })

    return [state, handleCancel, handleConfirm, handleDataCategoriesSelect];
}