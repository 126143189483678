import { AppointmentLocationType, AppointmentTargetType, AppointmentWithType, AppointmentReasonType, AppointmentReasonTypeNames } from "../../models/appointments.enums";
import {
    AppointmentTypeConfigurationModel,
    AppointmentTypeModel,
    CreateAllDayAppointmentModel,
    CreateAppointmentModel
} from "../../models/appointments.models";
import { AvailabilityModel, EmployeeAvailabilityModel } from "../../models/availability.models";
import { EmployeeModel, EmployeeShortModel } from "../../../employee/models/employee.models";
import { LocationModel, LocationType } from "../../../locations/models/locations.models";
import { availabilityQuery } from "../../stores/availability/availability.query";
import { timezonesService } from "../../../timezones/services/timezones.service";
import { locationsService } from "../../../locations/services/locations.service";
import { employeeService } from "../../../employee/services/employees.service";
import { patientsService } from "../../../patients/services/patients.service";
import { PatientShortModel } from "../../../patients/models/patient.model";
import { appointmentsService } from "../../services/appointments.service";
import { availabilityService } from "../../services/availability.service";
import { coachAppointmentValidator } from "./coachAppointment.validator";
import { employeesQuery } from "../../../employee/stores/employeesStore";
import { TimeZoneModel } from "../../../timezones/models/timezone.model";
import { patientsQuery } from "../../../patients/stores/patientsStore";
import { DurationModel, durations } from "../../models/times.models";
import { IErrorState } from "../../../common/validation/error-state";
import { timezonesQuery } from "../../../timezones/stores/timezones";
import { appointmentsQuery } from "../../stores/appointments";
import { locationsQuery } from "../../../locations/stores";
import { onEmit } from "../../../common/helpers/on-emit";
import { useEffect, useState } from "react";
import { authQuery } from "../../../auth/stores/auth";
import { Subscription } from "recompose";
import moment from "moment";
import { isCoachRole, isProviderRole } from "../../../common/constants/roles";
import {availabilityStore} from "../../stores/availability/availability.store";
import { GeneralValidator } from "../../../common/validation/general-validator";
import { source } from "../../constants/appointment.constants";

export interface CreateAppointmentComponentState extends IErrorState {
    open: boolean;
    showDuration: boolean;
    patients: PatientShortModel[];
    locations: LocationModel[];
    availabilities: EmployeeAvailabilityModel[];
    availability: AvailabilityModel[];
    employees: EmployeeShortModel[];
    allCoaches: EmployeeShortModel[],
    allProviders: EmployeeShortModel[],
    patientEmployees: EmployeeModel[];
    appointmentTypes: AppointmentTypeModel[];
    patientTimeZone: TimeZoneModel;
    employeeTimeZone: TimeZoneModel;
    timeZones: TimeZoneModel[];

    name: string;
    selectedTargetType: AppointmentTargetType;
    selectedAppointmentType: AppointmentTypeModel;
    selectedAppointmentConfiguration: AppointmentTypeConfigurationModel;
    selectedWithType: AppointmentWithType;
    selectedPatient: PatientShortModel;
    selectedDate: Date;
    selectedTime: Date;
    selectedDuration: DurationModel;
    selectedLocationType: AppointmentLocationType;
    selectedLocation: LocationModel;
    selectedEmployees: EmployeeShortModel[];
    comment: string,

    isAppointmentTypesLoading: boolean;
    isAvailabilityLoading: boolean,
    isPatientsLoading: boolean,

    isLoading: boolean,
    minDate: Date,
    reason: string,
    selectedReasonType: AppointmentReasonType,

    isEmployee: boolean
}

const defaultInitialState: CreateAppointmentComponentState =
{
    open: false,
    showDuration: false,
    patients: [],
    locations: [],
    availabilities: [],
    availability: [],
    employees: [],
    allCoaches: [],
    allProviders: [],
    patientEmployees: [],
    appointmentTypes: [],
    patientTimeZone: null,
    employeeTimeZone: timezonesQuery.getMyTimezone(),
    timeZones: [],
    name: null,
    selectedTargetType: AppointmentTargetType.Patient,
    selectedAppointmentType: null,
    selectedAppointmentConfiguration: null,
    selectedWithType: AppointmentWithType.HealthCoach,
    selectedPatient: null,
    selectedEmployees: [],
    selectedDate: new Date(),
    selectedDuration: durations[1],
    selectedTime: null,
    selectedLocationType: AppointmentLocationType.Online,
    selectedLocation: null,
    comment: null,
    isAppointmentTypesLoading: false,
    isAvailabilityLoading: true,
    isPatientsLoading: false,
    isLoading: false,
    minDate: new Date(),
    reason: '',
    selectedReasonType: null,
    errors: {},
    isEmployee: false
}

/**
 * Custom Hook to manage a view Model for Create shortcuts component
 */
export function useFacade(initialState = defaultInitialState): [
    CreateAppointmentComponentState,
    (id: number) => void,
    (searchQuery: string) => void,
    (employeeId: number) => void,
    (employees: EmployeeShortModel[]) => void,
    (targetType: AppointmentTargetType) => void,
    (type: AppointmentTypeModel) => void,
    (type: AppointmentWithType) => void,
    (locationType: AppointmentLocationType) => void,
    (date: Date) => void,
    (time: Date) => void,
    (durationValue: number, type: AppointmentTargetType) => void,
    (show: boolean) => void,
    (comment: string) => void,
    (name: string) => void,
    (event: any) => void,
    () => void,
    (value: string) => void,
    (value: any) => void
] {
    const [state, setState] = useState(initialState);

    const handlePatientSelect = (id: number) => {
        const patient = state.patients.find(i => i.id === id);

        if (patient) {
            coachAppointmentValidator.validateAndSetState(state, setState, 'selectedPatient', patient);
            setState(state => ({
                ...state,
                selectedPatient: patient,
                isAppointmentTypesLoading: true
            }));

            patientsService.getPatientTimeZone(patient.id);
            appointmentsService.getAvailableAppointmentTypesAsync(patient.id).subscribe(
                () => {
                    setState(state => ({
                        ...state,
                        isAppointmentTypesLoading: false,
                    }));
                },
                () => {
                    setState(state => ({
                        ...state,
                        isAppointmentTypesLoading: false,
                    }));
                }
            );
        }
    };

    const handlePatientSearch = (searchQuery: string) => {
        setState(state => ({ ...state, isPatientsLoading: true }));

        patientsService.getAll([], [], [], [], [], [], searchQuery, 0, 1000).subscribe(() => {
            setState(state => ({ ...state, isPatientsLoading: false }));
        });
    }

    const handleEmployeeSelect = (employeeId: number) => {
        const employee = state.employees.find(x => x.id === employeeId);

        state.selectedEmployees = state.selectedEmployees.filter(x => x.roleId !== employee.roleId);

        setState({ ...state, selectedEmployees: [...state.selectedEmployees, employee] });
    }

    const handleEmployeesSelect = (employees: EmployeeShortModel[]) => {
        setState(state => ({ ...state, selectedEmployees: employees }));
    }

    const handleChangeTargetType = (targetType: AppointmentTargetType) => {
        removeErrors();

        setState({
            ...state,
            selectedTargetType: targetType,
            selectedEmployees: [],
            selectedDuration: getTargetDurations(targetType)[0]
        });
    }

    const handleChangeAppointmentType = (type: AppointmentTypeModel) => {
        const configuration = type.configurations[0];
        const duration = durations.find(x => x.patientMode && x.value === configuration.duration);
        const withType = configuration.withType;

        setState({
            ...state,
            selectedAppointmentType: type,
            selectedDuration: duration,
            selectedWithType: withType,
            selectedAppointmentConfiguration: configuration,
            selectedEmployees: []
        });
    }

    const handleChangeAppointmentWithType = (withType: AppointmentWithType) => {
        const configuration = state.selectedAppointmentType
            .configurations
            .filter(x => x.duration === state.selectedDuration.value)
            .find(x => x.withType === withType);

        setState(state => ({
            ...state,
            selectedWithType: withType,
            selectedAppointmentConfiguration: configuration,
            selectedEmployees: []
        }));
    }

    const handleChangeLocationType = (locationType: AppointmentLocationType) => {
        setState({ ...state, selectedLocationType: locationType });
    }

    const handleChangeDate = (date: Date) => {
        setState({ ...state, selectedDate: date, selectedTime: null });
    }

    const handleChangeTime = (time: Date) => {
        coachAppointmentValidator.validateAndSetState(state, setState, 'selectedTime', time);

        setState({ ...state, selectedTime: time });
    }

    const getTargetDurations = (type: AppointmentTargetType) => {
        const patientMode = type === AppointmentTargetType.Patient;

        return durations.filter(x => x.patientMode === patientMode);
    }

    const handleChangeDuration = (durationValue: number, type: AppointmentTargetType) => {
        const duration = getTargetDurations(type).find(x => x.value === durationValue);
        const configuration = state.selectedAppointmentType
            ? state.selectedAppointmentType
                .configurations
                .find(x => x.withType === state.selectedWithType && x.duration === duration.value)
            : null;

        setState(state => ({
            ...state,
            selectedDuration: duration,
            selectedAppointmentConfiguration: configuration
        }));
    }

    const handleShowDuration = (show: boolean) => {
        setState({ ...state, showDuration: show });
    }

    const handleChangeComment = (comment: string) => {
        coachAppointmentValidator.validateAndSetState(state, setState, 'comment', comment);

        setState({ ...state, comment: comment })
    }

    const handleChangeName = (name: string) => {
        coachAppointmentValidator.validateAndSetState(state, setState, 'name', name);

        setState({ ...state, name: name })
    }

    const removeErrors = () => {
        Object.keys(state.errors).forEach(key => {
            delete state.errors[key];
        });
    }

    const createPatientAppointment = () => {
        coachAppointmentValidator.validateAndSetState(state, setState, 'comment', state.comment);
        coachAppointmentValidator.validateAndSetState(state, setState, 'selectedPatient', state.selectedPatient);
        coachAppointmentValidator.validateAndSetState(state, setState, 'selectedEmployees', state.selectedEmployees);
        coachAppointmentValidator.validateAndSetState(state, setState, 'selectedTime', state.selectedTime);

        if (!reasonIsSelected()) {
            return;
        }

        if (!coachAppointmentValidator.stateIsValid(state)) {
            return;
        }

        const createModel = {
            patientId: state.selectedPatient.id,
            employeeIds: state.selectedEmployees.map(x => x.id),
            startDate: moment(state.selectedTime).toDate(),
            endDate: moment(state.selectedTime).add(state.selectedDuration.value, 'm').toDate(),
            locationType: state.selectedLocationType,
            appointmentTypeId: state.selectedAppointmentType.id,
            appointmentTypeConfigurationId: state.selectedAppointmentConfiguration.id,
            comment: state.comment,
            name: state.selectedAppointmentType.name,
            locationId: state.selectedLocation.id,
            timeZoneId: state.employeeTimeZone?.id,
            reason: state.selectedReasonType && state.selectedReasonType !== AppointmentReasonType.Other && state.selectedReasonType !== AppointmentReasonType.OtherTestFollowUp ? AppointmentReasonTypeNames[state.selectedReasonType] : state.reason,
            reasonType: state.selectedReasonType,
            source: source
        } as CreateAppointmentModel

        setState(state => ({ ...state, isLoading: true }));

        appointmentsService.createAsEmployee(createModel).subscribe(() => {
            setState(defaultInitialState);
        }, () => {
            setState(state => ({ ...state, isLoading: false }));
        })
    }

    const createOtherAppointment = () => {
        coachAppointmentValidator.validateAndSetState(state, setState, 'comment', state.comment);
        coachAppointmentValidator.validateAndSetState(state, setState, 'name', state.name);
        coachAppointmentValidator.validateAndSetState(state, setState, 'selectedEmployees', state.selectedEmployees);
        coachAppointmentValidator.validateAndSetState(state, setState, 'selectedTime', state.selectedTime);

        if (!reasonIsSelected()) {
            return;
        }

        if (!coachAppointmentValidator.stateIsValid(state)) {
            return;
        }

        const createModel = {
            patientId: 0,
            employeeIds: state.selectedEmployees.map(x => x.id),
            startDate: moment(state.selectedTime).toDate(),
            endDate: moment(state.selectedTime).add(state.selectedDuration.value, 'm').toDate(),
            locationType: AppointmentLocationType.Online,
            appointmentTypeId: null,
            appointmentTypeConfigurationId: null,
            comment: state.comment,
            name: state.name,
            locationId: state.selectedLocation.id,
            timeZoneId: state.employeeTimeZone?.id,
            reason: state.selectedReasonType && state.selectedReasonType !== AppointmentReasonType.Other && state.selectedReasonType !== AppointmentReasonType.OtherTestFollowUp ? AppointmentReasonTypeNames[state.selectedReasonType] : state.reason,
            reasonType: state.selectedReasonType,
            source: source
        } as CreateAppointmentModel

        setState(state => ({ ...state, isLoading: true }));

        appointmentsService.createOther(createModel).subscribe(() => {
            setState(defaultInitialState);
        }, () => {
            setState(state => ({ ...state, isLoading: false }));
        });
    }

    const createAllDayAppointment = () => {
        coachAppointmentValidator.validateAndSetState(state, setState, 'comment', state.comment);
        coachAppointmentValidator.validateAndSetState(state, setState, 'name', state.name);
        coachAppointmentValidator.validateAndSetState(state, setState, 'selectedEmployees', state.selectedEmployees);

        if (!coachAppointmentValidator.stateIsValid(state)) {
            return;
        }

        const createModel = {
            employeeIds: state.selectedEmployees.map(x => x.id),
            date: state.selectedDate,
            comment: state.comment,
            name: state.name,
            timeZoneId: timezonesQuery.getMyTimezone().id,
            source: source
        } as CreateAllDayAppointmentModel

        setState(state => ({ ...state, isLoading: true }));

        appointmentsService.createAllDay(createModel).subscribe(() => {
            setState(defaultInitialState);
        }, () => {
            setState(state => ({ ...state, isLoading: false }));
        });
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        removeErrors();

        if (state.selectedDuration.fullDay) {
            createAllDayAppointment();
            return;
        }

        switch (state.selectedTargetType) {
            case AppointmentTargetType.Patient: {
                createPatientAppointment();
                break;
            }
            case AppointmentTargetType.Other: {
                createOtherAppointment()
                break;
            }
        }
    }

    const handleClose = () => {
        setState(defaultInitialState);
    }

    const getAvailability = (): AvailabilityModel[] => {
        let result = [];
        let resultInitialized = false;

        state.selectedEmployees.forEach(employee => {
            const employeeAvailability = state.availabilities.find(x => x.employeeId === employee.id)?.availability;

            if (!employeeAvailability) {
                return [];
            }

            if (!resultInitialized) {
                result = employeeAvailability;
                resultInitialized = true;
            }

            result = result.filter(value => employeeAvailability.some(x => moment(x.start).isSame(moment(value.start))));
        });

        setState(state => ({
            ...state,
            selectedDate: availabilityQuery.getEarliestAvailability()
        }));

        return result;
    }

    const getDayEnd = (): Date => {
        return moment(new Date()).add(6, 'months').add(-2, 'day').toDate()
    }

    const queryAvailability = (isEmployee: boolean) => {
        setState(state => ({ ...state, isAvailabilityLoading: true }));

        if (state.selectedEmployees.length) {
            const employeeIds = state.selectedEmployees.map(employee => employee.id);
            availabilityService.getByEmployeeIds(employeeIds, new Date(), getDayEnd(), state.selectedAppointmentConfiguration?.id ?? null, isEmployee).subscribe();
        }
    }

    const setLocations = (locations: LocationModel[]) => {
        if (!locations || !locations.length) {
            return;
        } 

        const headOffice = locations.find(x => x.type === LocationType.HeadOffice);

        setState(state => ({
            ...state,
            selectedLocation: headOffice ?? locations[0],
            locations: locations
        }));
    }

    const handleChangeReason = (value: string) => {
        setState({...state, reason: value});
    }

    const handleSelectReason = (value: any) => {
        setState(state => ({
            ...state,
            selectedReasonType: value,
            errors: GeneralValidator.removeError({ errors: GeneralValidator.removeError(state, 'otherReason')}, 'selectedReason')
        }));
    }

    const reasonIsSelected = (): boolean => {
        if (!state.selectedAppointmentType?.requiresReasonType) {
            return true;
        }

        if (!state.selectedReasonType) {
            setState(state => ({
                ...state,
                errors: GeneralValidator.addError(state, 'selectedReason', 'Please, select a reason.')
            }));
    
            return false;
        }

        if (state.selectedReasonType !== AppointmentReasonType.Other && state.selectedReasonType !== AppointmentReasonType.OtherTestFollowUp) {
            setState(state => ({
                ...state,
                errors: GeneralValidator.removeError(state, 'selectedReason')
            }));

            return true;
        }

        if (state.reason) {
            setState(state => ({
                ...state,
                errors: GeneralValidator.removeError(state, 'otherReason')
            }));

            return true;
        }

        setState(state => ({
            ...state,
            errors: GeneralValidator.addError(state, 'otherReason', 'Please, enter a specific reason.')
        }));

        return false;
    }

    useEffect(() => {
        const isEmployee = authQuery.isEmployeeUser();
        queryAvailability(isEmployee);
    }, [state.selectedDuration]);

    useEffect(() => {
        const availability = getAvailability();
        setState(state => ({
            ...state,
            availability: availability,
            selectedDate: availability?.length ? availability[0].start : new Date(),
            minDate: availability?.length ? availability[0].start : new Date()
        }))
    }, [state.availabilities]);

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit(employeesQuery.patientEmployees$, employees => {
                setState(state => ({
                    ...state,
                    patientEmployees: employees,
                }))
            }),
        ];

        if (state.selectedPatient) {
            employeeService.getAssignedToById(state.selectedPatient.id).subscribe();
        }

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [state.selectedPatient]);

    useEffect(() => {
        availabilityStore.reset();

        const subscriptions: Subscription[] = [
            onEmit(availabilityQuery.availabilities$, availabilities => {
                const isLoading = !state.selectedEmployees.map(x => x.id).every(x => availabilities.map(a => a.employeeId).includes(x));
                setState(state => ({
                    ...state,
                    availabilities: availabilities.map(a => ({
                        employeeId: a.employeeId,
                        availability: a.availability.filter(x => moment(x.start).minutes() % 15 === 0)
                    }) as EmployeeAvailabilityModel),
                    isAvailabilityLoading: isLoading,
                    selectedTime: null
                }))
            }),
        ];

        state.open && queryAvailability(authQuery.isEmployeeUser());

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [state.selectedEmployees]);

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit(patientsQuery.patients$, patients => {
                setState(state => ({ ...state, patients: patients }));
            }),
            onEmit(locationsQuery.availableLocations$, locations => {
                setLocations(locations)
            }),
            onEmit(employeesQuery.employees$, employees => {
                setState(state => ({ 
                    ...state, 
                    employees: employees,
                    allCoaches: employees.filter(x => isCoachRole(x.roleId)),
                    allProviders: employees.filter(x => isProviderRole(x.roleId)),
                }));
            }),
            onEmit(appointmentsQuery.allAppointmentTypes$, types => {
                setState(state => ({ ...state, appointmentTypes: types }))
            }),
            onEmit(patientsQuery.patientTimeZone$, timeZone => {
                setState(state => ({ ...state, patientTimeZone: timeZone }))
            }),
            onEmit(timezonesQuery.timezones$, timezones => {
                setState(state => ({ ...state, timeZones: timezones }))
            }),

            appointmentsService.appointmentScheduleOnOpen.subscribe(() => {
                setState(state => ({
                    ...state, open: true,
                }))
            })
        ];

        locationsService.getAvailable(authQuery.getCurrentPracticeId()).subscribe();
        employeeService.getAllPracticeEmployees();
        appointmentsService.getAllAppointmentTypes();
        timezonesService.getTimezones();

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [state.open]);

    return [
        state,
        handlePatientSelect,
        handlePatientSearch,
        handleEmployeeSelect,
        handleEmployeesSelect,
        handleChangeTargetType,
        handleChangeAppointmentType,
        handleChangeAppointmentWithType,
        handleChangeLocationType,
        handleChangeDate,
        handleChangeTime,
        handleChangeDuration,
        handleShowDuration,
        handleChangeComment,
        handleChangeName,
        handleSubmit,
        handleClose,
        handleChangeReason,
        handleSelectReason
    ]
}