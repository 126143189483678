import { useState } from "react";
import { appointmentsService } from "../../services/appointments.service";
import {IErrorState} from "../../../common/validation/error-state";
import { sendInviteDialogValidator } from "./sendInviteDialogComponent.validator";


interface SendInviteDialogComponentState extends IErrorState {
    email: string
    isLoading: boolean;
}

export function useFacade(appointmentId: number, handleClose: () => void): [
    SendInviteDialogComponentState,
    (email: string) => void,
    () => void
] {
    const [state, setState] = useState({
        email: null,
        isLoading: false,
        errors: {}
    } as SendInviteDialogComponentState);

    const handleEditEmail = (email: string) => {
        sendInviteDialogValidator.validateAndSetState(state, setState, 'email', email)
        setState({...state, email: email})
    }

    const handleSubmit = () => {

        sendInviteDialogValidator.validateObjectAndSetState(state, setState, state);
        if (!sendInviteDialogValidator.stateIsValid(state)) {
            return;
        }

        setState((state) => ({...state, isLoading: true}));

        const inviteModel = {
            appointmentId: appointmentId,
            email: state.email.trim()
        }

        appointmentsService.sendAppointmentInvite(inviteModel).subscribe(() => {
            setState((state) => ({...state, isLoading: false}));
            handleClose();
        })
    }

    return [state, handleEditEmail, handleSubmit];
}