import { Box, Dialog, DialogActions, DialogContent, IconButton, } from "@material-ui/core";
import React from "react";
import { RecommendationsDialogComponentProps } from "../../../common/dialogs/dialogProps";
import { useFacade } from "./recommendationsDialogComponent.hooks";
import DialogTitle from "@material-ui/core/DialogTitle";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import CloseIcon from "@material-ui/icons/Close";
import { HealthReportDialogName, RecommendationSetType } from "../../models/healthReport.models";
import { ChevronLeft } from '@material-ui/icons';
import { BackButtonDialog } from "../../../common/components/wildHealthButton/BackButton";
import { RecommendationsComponent } from "../recommendationsComponent/RecommendationsComponent";
import { RecommendationsWithPreviousComponent } from "../recommendationsWithPreviousComponent/RecommendationsWithPreviousComponent";
import { RecommendationsUpdatePreviousComponent } from "../recommendationsUpdatePreviousComponent/RecommendationsUpdatePreviousComponent";


export const RecommendationsDialogComponent: React.FC<RecommendationsDialogComponentProps> = (props: RecommendationsDialogComponentProps) => {
    const {
        title,
        type,
        patientId,
        open,
        handleClose,
        handleGoBack,
        handleReportGenerate,
        handleReportRegenerate,
    } = props;


    const [
        state,
        handleSubmitUpdate,
        handleSubmit,
        handleCancel,
        handleCancelRegenerate,
    ] = useFacade(handleClose, patientId, open, handleReportGenerate, handleReportRegenerate);


    const getSubTitle = (): string => {
        switch (type) {
            case RecommendationSetType.New:
                return "Use New Set of Recommendations"
            case RecommendationSetType.Previous:
                return "Use Set from Selected Report"
            case RecommendationSetType.Compare:
                return "Compare With Previous Report"
        }
    }

    const getContent = (): JSX.Element => {
        switch (type) {
            case RecommendationSetType.New:
            case RecommendationSetType.NewAndSkipReview:
                return <RecommendationsComponent
                    isPrepereRecommendation={state.isPrepereRecommendation}
                />
            case RecommendationSetType.Previous:
                return <RecommendationsWithPreviousComponent
                    isPrepareRecommendation={state.isPrepereRecommendation}
                />
            case RecommendationSetType.Compare:
                return <RecommendationsUpdatePreviousComponent
                    isPrepareRecommendation={state.isPrepereRecommendation}
                />
        }
    }

    return (
        <Dialog maxWidth="lg" fullWidth open={open} onClose={() => handleClose()} aria-labelledby="form-dialog-title">

            <DialogTitle>
                <Box pl="20px" display="flex" justifyContent="space-between" alignItems="center">
                    {title}
                    <IconButton id="fecommendations-dialog-close" onClick={() => handleClose()}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>

            <DialogContent>
                <Box m={3} my={1} fontWeight="500" >
                    {getSubTitle()}
                    {getContent()}
                </Box>
            </DialogContent>

            <DialogActions>
                <Box display="flex" m={3} justifyContent="space-between" width="100%">
                    <Box>
                        <BackButtonDialog id='recommendations-dialog-go-back' onClick={() => handleGoBack()}>
                            <Box
                                display="flex"
                                alignItems="center"
                            >
                                <ChevronLeft fontSize="small" />
                                <Box ml={1}>
                                    <span>Back</span>
                                </Box>
                            </Box>
                        </BackButtonDialog>
                    </Box>
                    <Box display="flex">
                        <Box mx={3}>
                            <WildHealthButton
                                id="recommendations-dialog-cancel"
                                color="tertiary"
                                onClick={() => title === HealthReportDialogName.New ? handleCancel(): handleCancelRegenerate()}
                            >
                                Cancel
                            </WildHealthButton>
                        </Box>
                        {title === HealthReportDialogName.New
                            ? <Box>
                                <WildHealthButton
                                    id="recommendations-dialog-generate"
                                    onClick={() => handleSubmit()}
                                >
                                    Generate Report
                                </WildHealthButton>
                            </Box>
                            : <Box>
                                <WildHealthButton
                                    id="recommendations-dialog-generate"
                                    onClick={() => handleSubmitUpdate()}
                                >
                                    Update Report
                                </WildHealthButton>
                            </Box>}

                    </Box>
                </Box>
            </DialogActions>
        </Dialog>
    );
}
