import {Query} from '@datorama/akita';
import {ShortcutsState, shortcutsStore, ShortcutsStore, ShortcutGroupTabs} from "./shortcuts.store";
import {ShortcutGroupModel} from "../../models/shortcut.models";

/**
 * Provides shortcuts queries
 */
export class ShortcutsQuery extends Query<ShortcutsState> {

    groups$ = this.select(state => state.groups);
    tab$ = this.select(state => state.tab);
    targetShortcut$ = this.select(state => state.targetShortcut);

    constructor(protected store: ShortcutsStore) {
        super(store);
    }

    public getGroups(isPersonal: boolean): ShortcutGroupModel[] {
        return this.getValue().groups.filter(x => x.isPersonal === isPersonal);
    }

    public getTab(): ShortcutGroupTabs {
        return this.getValue().tab;
    }
}

export const shortcutsQuery = new ShortcutsQuery(shortcutsStore);
