import { useState } from "react";

interface CheckboxDropdownState {
    anchorEl: HTMLElement | null
}

export function useFacade():
    [
        CheckboxDropdownState,
        (event: React.MouseEvent<HTMLElement>) => void,
        () => void
    ] 
{
    const [state, setState] = useState({
    } as CheckboxDropdownState);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setState({...state, anchorEl: event.currentTarget});
    };
    
    const handleClose = () => {
        setState({...state, anchorEl: null});
    };

    return [state, handleOpen, handleClose];
}