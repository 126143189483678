import { Store, StoreConfig } from '@datorama/akita';
import { EpigeneticOrderModel } from '../../models/epigeneticOrders.models';

/**
 * Represents lab orders state
 */
export interface EpigeneticOrdersState {
    orders: EpigeneticOrderModel[];
}

/**
 * Creates initial state
 */
export function createInitialState(): EpigeneticOrdersState {
    return {
        orders: [],
    };
}

@StoreConfig({name: 'epigeneticOrders', resettable: true })
export class EpigeneticOrdersStore extends Store<EpigeneticOrdersState> {
    constructor() {
        super(createInitialState());
    }

    public addOrder(order: EpigeneticOrderModel): void {
        this.update({orders: [...this.getValue().orders, order]});
    }

    public updateOrder(order: EpigeneticOrderModel): void {
        const orders = this.getValue().orders.map((x) => (x.id === order.id) ? order : x);
        this.update({orders: orders});
    }
}

export const epigeneticOrdersStore = new EpigeneticOrdersStore();
