import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { colors } from "../../../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& label.Mui-focused': {
                color: colors.main,
            },
            '& .MuiOutlinedInput-root': {
                borderColor: colors.mediumGray,
                borderRadius: 8,
                '&.Mui-focused fieldset': {
                    borderColor: colors.main,
                },
            },
            '& .MuiDialog-paper': {
                borderRadius: 20,
            },
        },
        dialogTitle: {
            margin: 0,
            padding: theme.spacing(2),
        },
        dialogTitleCloseAction: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    }),
);
