import React from "react";
import {useFacade} from "./appointmentsStatisticComponent.hooks";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import {WildHealthPlaceHolderBase} from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolderBase";
import {Box, Grid, Tooltip} from "@material-ui/core";
import {useStyles} from "./appointmentsStatisticComponent.styles";
import commonUseStyles from "../../../common/styles/common.styles";
import InfoIcon from '@material-ui/icons/Info';

interface AppointmentsStatisticComponentProps {
    employeeId: number;
    isLoading: boolean;
}

export const AppointmentsStatisticComponent: React.FC<AppointmentsStatisticComponentProps> = (props: AppointmentsStatisticComponentProps) => {
    const {
        employeeId,
        isLoading
    } = props;

    const [
        {
            statistic
        }
    ] = useFacade(employeeId);

    const classes = useStyles();
    const commonClasses = commonUseStyles();

    if (isLoading) {
        return <WildHealthLinearProgress />
    }

    if (!statistic) {
        return <WildHealthPlaceHolderBase message={"No data"} />
    }

    const renderMeetingsBreakdown = (_15min: number, _30min: number, _60min: number) => {
        return (
            <Box display="flex">
                <Box className={commonClasses.colorGray1}>
                    Meetings Breakdown
                </Box>
                <Box ml={1}>
                    <Tooltip placement="top" title={
                        <Box p={1}>
                            <Box>
                                15 min meeting: {_15min}
                            </Box>
                            <Box>
                                30 min meeting: {_30min}
                            </Box>
                            <Box>
                                60 min meeting: {_60min}
                            </Box>
                        </Box>
                    }>
                        <Box className={commonClasses.colorGray2}>
                            <InfoIcon/>
                        </Box>
                    </Tooltip>
                </Box>
            </Box>
        )
    }

    const renderBlock = (title: string, value: number, meetingBreakdown: JSX.Element = null) => {
        return (
            <Box className={classes.statisticBlock}>
                <Box>
                    <span className={commonClasses.size16}>
                        {title}
                    </span>
                </Box>
                <Box display="flex" alignItems="center" flexWrap='wrap' justifyContent="space-between">
                    <Box minWidth='140px'>
                        <span className={classes.statisticTitle}>
                            {value + ' Hours'}
                        </span>
                    </Box>
                    {
                        meetingBreakdown &&
                        <Box mt={1}  minWidth='200px'>
                            {
                                meetingBreakdown
                            }
                        </Box>
                    }
                </Box>
            </Box>
        )
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={3} lg={3}>
                    {renderBlock('Total Visit Hours', statistic.visitHours, renderMeetingsBreakdown(statistic.booked15MinuteMeetings, statistic.booked25MinuteMeetings, statistic.booked55MinuteMeetings))}
                </Grid>
                <Grid item xs={6} sm={6} md={3} lg={3}>
                    {renderBlock('Total Patient Available Time', statistic.availableHours, renderMeetingsBreakdown(statistic.available15MinuteMeetings, statistic.available25MinuteMeetings, statistic.available55MinuteMeetings))}
                </Grid>
                <Grid item xs={6} sm={6} md={3} lg={3}>
                    {renderBlock('Total Administrative Hours', statistic.administrativeHours)}
                </Grid>
                <Grid item xs={6} sm={6} md={3} lg={3}>
                    {renderBlock('Blocked Time', statistic.blockedHours)}
                </Grid>
            </Grid>
        </>
    )
}