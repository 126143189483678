import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container:{
            width: '100%',
            zIndex: 1601,
            position: 'fixed',
            backgroundColor:'white',
            borderRadius: '5%',
            color: 'black',
            left: 0,
            bottom: '-10px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
        },
        backGrnd:{
            width: '100vh',
            height: '100vh',
            zIndex: 1600,
            backgroundColor:"gray",
            opacity: 0.5,
            position: 'fixed',
            top: 0,
            left: 0
        },
        boxToSwap:{
            width: '65px',
            height: '5px',
            backgroundColor: '#DDDDDD',
            marginTop: '25px',
            borderRadius: '100px'
        },
        dialog:{
            borderRadius: 20
        }
    })
);
