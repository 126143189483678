import {
  emailValidationRule,
  firstNameValidationRule,
  lastNameValidationRule,
  notWhitespaceValidationRule,
} from "../../../../../common/validation/general-validation-rules";
import { GeneralValidator } from "../../../../../common/validation/general-validator";

export const addEmployeesButtonComponentValidator = new GeneralValidator({
  'firstName': [firstNameValidationRule, notWhitespaceValidationRule()],
  'lastName': [lastNameValidationRule, notWhitespaceValidationRule()],
  'email': [emailValidationRule],
});
