import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 'fit-content',
            [theme.breakpoints.up('lg')]: {
                width: 730,
            },
        },
        title: {
            fontFamily: 'Be Vietnam Pro',
            fontWeight: 500,
            fontSize: 20,
        },
        invoiceTitle: {
            fontFamily: 'Be Vietnam Pro',
            fontWeight: 'bold',
            fontSize: 46,
        },
        invoiceSubTitle: {
            fontFamily: 'Poppins',
            fontWeight: 600,
            fontSize: 24,
            color: '#7C8A9D',
        },
        companyTitle: {
            color: '#7C8A9D',
            fontFamily: 'Be Vietnam Pro',
            fontWeight: 400,
            fontSize: 14,
        },
        companySubTitle: {
            fontFamily: 'Be Vietnam Pro',
            fontWeight: 500,
            fontSize: 16,
        },
        invoice: {
            border: '1px solid #EAEFF5',
            boxSizing: 'border-box',
            boxShadow: '0px 4px 9px rgba(168, 173, 198, 0.15)',
            borderRadius: 2,

            padding: 10,
            [theme.breakpoints.up('sm')]: {
                padding: 50,
            },
        },
        total: {
            fontFamily: 'Poppins',
            fontWeight: 600,
            fontSize: 24,

            padding: '10px 0px',
            [theme.breakpoints.up('sm')]: {
                padding: '50px 0px',
            },
        },
    })
);
