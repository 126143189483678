import {ConversationModelBase, ConversationType} from "../../models/conversation.models";
import React from "react";
import {useFacade} from "./playgroundChatComponent.hooks";
import {useStyles} from "./playgroundChatComponent.styles";
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import {Box, Divider, IconButton} from "@material-ui/core";
import {InputField} from "../sendMessageComponent/sendMessageComponent.styles";
import {SendPlaygroundMessageComponent} from "../sendPlaygroundMessageComponent/SendPlaygroundMessageComponent";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import {PlaygroundThreadComponent} from "../playgroundThreadComponent/PlaygroundThreadComponent";

export interface PlaygroundChatComponentProps {
    targetConversation: ConversationModelBase | null;
    type: ConversationType;
}

export const PlaygroundChaComponent: React.FC<PlaygroundChatComponentProps> = (props: PlaygroundChatComponentProps) => {
    const [
        {
            targetConversation,
            author,
            editSubject,
            newSubject
        },
        handleSendMessage,
        toggleEditSubject,
        handleSubjectChanges,
        submitNewSubject
    ] = useFacade(props.targetConversation, props.type);

    const classes = useStyles();

    if (!targetConversation) {
        return <Box>
            <WildHealthLinearProgress p={3}/>
        </Box>
    }

    return (
        <>
            <Box p={1} px={3} height={75} display="flex" justifyContent="space-between" alignItems="center">
                <Box width={1}>
                    {
                        editSubject
                            ? <InputField
                                fullWidth
                                value={newSubject}
                                id="edit-subject"
                                variant="outlined"
                                placeholder="Enter new subject here..."
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    handleSubjectChanges(e.target.value)
                                }
                            /> :
                            <Box pl={2}>
                                {targetConversation.subject}
                            </Box>

                    }
                </Box>
                <Box display="flex">
                    {
                        editSubject
                            ? <>
                                <IconButton className="wh-tw-text-primaryV" onClick={() => toggleEditSubject()}>
                                    <CancelOutlinedIcon fontSize="large"/>
                                </IconButton>
                                <IconButton className="wh-tw-text-primaryV" onClick={() => submitNewSubject()}>
                                    <CheckCircleIcon fontSize="large"/>
                                </IconButton>
                            </> :
                            <>
                                <IconButton onClick={() => toggleEditSubject()}>
                                    <EditIcon/>
                                </IconButton>
                            </>

                    }

                </Box>
            </Box>
            <Divider/>
            <Box className={classes.thread}>
                <PlaygroundThreadComponent
                    conversation={targetConversation}
                    author={author}
                />
            </Box>
            <Divider/>
            <SendPlaygroundMessageComponent
                message={''}
                handleSubmit={handleSendMessage}
            />
        </>
    )
}