import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: colors.white,
    paddingTop: "20px"
  },
  main: {
    width: '60%',
    margin: '0 auto',
  },
  mainSmall: {
    width: '100%',
    margin: '0 auto',
  },
  consultationWidget: {
    width: '100%',
    height: 'calc(100vh - 200px)',
  },
}));