import {
    Avatar,
    Box,
    Card,
    CardContent,
    Divider,
    IconButton,
    Popover,
    Typography,
    useMediaQuery,
    useTheme
} from "@material-ui/core";
import DateRangeIcon from '@material-ui/icons/DateRange';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import WatchLaterRoundedIcon from '@material-ui/icons/WatchLaterRounded';
import moment from "moment";
import React from "react";
import {
    AppointmentOptionsDialogComponent
} from "../../../appointments/components/appointmentOptionsDialogComponent/AppointmentOptionsDialogComponent";
import {
    CreatePatientAppointmentDialogComponent
} from '../../../appointments/components/createPatientAppointmentUpdatedDialog/CreatePatientAppointmentDialogComponent';
import {
    PatientRescheduleAppointmentDialogComponent
} from '../../../appointments/components/patientRescheduleAppointmentDialog/PatientRescheduleAppointmentDialogComponent';
import {
    AppointmentPurposeType,
    AppointmentStatus,
    AppointmentWithType
} from '../../../appointments/models/appointments.enums';
import {PatientAppointmentModel} from '../../../appointments/models/appointments.models';
import {AccentButton} from "../../../common/components/wildHealthButton/AccentButton";
import {SecondaryButton} from "../../../common/components/wildHealthButton/SecondaryButton";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import {WildHealthMenuItem} from "../../../common/components/wildHealthMenu/WildHealthMenu";
import {displayShortName} from "../../../conversations/helpers/messages.converter";
import {useFacade} from "./appointmentWidgetComponent.hooks";
import {useStyles} from "./appointmentWidgetComponent.styles";
import {getAvailableAppointmentType, getAvailableWithTypes} from "../../../appointments/helpers/appointmentHelper";
import { EmployeeType, employeeTypesNames, employeeTypesNamesForAppointment } from "../../../employee/models/employee.enums";
import { profileQuery } from "../../../account/stores/profileStore";

export interface AppointmentWidgetComponentProps {
    patientId: number | null;
}

export const AppointmentWidgetComponent: React.FC<AppointmentWidgetComponentProps> = (props: AppointmentWidgetComponentProps) => {
    const [
        {
            isLoading,
            isAppointmentTypesLoading,
            menuAnchorEl,
            assignedEmployees,
            appointmentTypes,
            appointments,
            locations,
            timeZone,
            appointmentsSummary
        },
        handleToggleMenu,
        handleReschedule,
        handleCancel,
        handleJoin
    ] = useFacade(props.patientId);

    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const coachSectionWithTypes: Array<AppointmentWithType> = [AppointmentWithType.HealthCoach];
    const providerSectionWithTypes: Array<AppointmentWithType> = [AppointmentWithType.HealthCoachAndProvider, AppointmentWithType.Provider];

    const hasFollowUp = Boolean(appointmentTypes.find((appointmentType) => appointmentType.purpose === AppointmentPurposeType.FollowUp));
    const denomenator = appointmentsSummary?.availableCoachAppointments;

    const getAppointmentsByWithType = (withTypes: AppointmentWithType[], appointments: PatientAppointmentModel[]): PatientAppointmentModel[] => {
        return appointments.filter(x => x.status !== AppointmentStatus.Canceled && withTypes.includes(x.withType));
    }

    const getTitle = (appointment: PatientAppointmentModel): string => {
        return appointment.employees
            .map(e => `${e.firstName} ${e.lastName}`)
            .join(', ');
    }

    const getAvatarLetters = (appointment: PatientAppointmentModel): string => {
        return appointment.employees.length === 1
            ? displayShortName(appointment.employees[0].firstName, appointment.employees[0].lastName)
            : appointment.employees.map(x => x.firstName.trim()[0]).join(',');
    }

    const renderAppointments = (withTypes: AppointmentWithType[], position: EmployeeType) => {
        const targetAppointments = getAppointmentsByWithType(withTypes, appointments);

        const availableWithTypes = getAvailableWithTypes(appointmentTypes);

        const targetWithType = availableWithTypes.find(x => withTypes.includes(x));

        const availableType = getAvailableAppointmentType(appointmentTypes, targetWithType);

        const employee = assignedEmployees?.find(el => el.role.displayName === employeeTypesNames.get(position))

        const isCanCreate = position === EmployeeType.HealthCoach ? denomenator > 0 : true;

        if (!targetAppointments || !targetAppointments.length) {
            return (
                <Box minHeight='110px'>
                    <CreatePatientAppointmentDialogComponent
                        locations={locations}
                        assignedEmployees={assignedEmployees}
                        appointmentTypes={appointmentTypes}
                        appointmentWithType={targetWithType}
                        timeZone={timeZone}
                        disableSchedule={!(!!availableType && !!employee && isCanCreate)}
                        buttonType={SecondaryButton}
                        hasFollowUp={hasFollowUp}
                        patientId={props.patientId}
                    />
                </Box>
            );
        }

        const byStartDate = (a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
        const appointment = targetAppointments.sort(byStartDate)[0];

        return (
            <Box my={1}  className={classes.appointmentContainer}>
                <Box className={classes.appointmentContent}>
                    <Box className={classes.employeeAvatars}>
                        <Avatar variant="rounded" src={profileQuery.getPhoto(appointment.employees[0].id)}>
                            {getAvatarLetters(appointment)}
                        </Avatar>
                    </Box>
                    <Box ml={1} width='100%'>
                        <Box display='flex' justifyContent='space-between'>
                            <Box>
                                <Box className={classes.employeeName}>
                                    {getTitle(appointment)}
                                </Box>
                                <Box className={classes.dateTime}>
                                    <Box>
                                        <DateRangeIcon className={classes.dateIcon} />
                                    </Box>
                                    <Box ml={1}>
                                        <Typography className={classes.date} color="textSecondary" gutterBottom>
                                            {moment(appointment.startDate).format(isSmallScreen?'ddd, MMM DD':'ddd, MMMM DD')}
                                        </Typography>
                                    </Box>

                                    <Box ml={1}>
                                        <WatchLaterRoundedIcon className={classes.timeIcon} />
                                    </Box>
                                    <Box ml={1}>
                                        <Typography className={classes.time} gutterBottom>
                                            {`${moment(appointment.startDate).format('hh:mm a')} - ${moment(appointment.endDate).format('hh:mm a')}`}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box>
                                <IconButton id="appointment-widget-more-menu" onClick={(e) => handleToggleMenu(appointment.id, e.currentTarget)} className={classes.menuIcon}>
                                    <MoreHorizIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box mt={1}>
                    <AccentButton id="appointment-widget-join" onClick={() => handleJoin(appointment.joinLink)} fullWidth={isSmallScreen} >
                        <Box mx={4}>Join</Box>
                    </AccentButton>
                </Box>
            </Box>
        )
    }

    const renderSection = (position: EmployeeType, withTypes: AppointmentWithType[]) => {
        return (
            <Box my={1.5}>
                <Box>
                    <Typography className={classes.position}>
                        {employeeTypesNamesForAppointment.get(position)} Visit
                    </Typography>
                </Box>
                <Box mt={1.5}>
                    {renderAppointments(withTypes, position)}
                </Box>
            </Box>
        )
    }

    const renderContent = () => {
        if (isLoading || isAppointmentTypesLoading) {
            return <WildHealthLinearProgress />
        }

        return (
            <Box>
                <PatientRescheduleAppointmentDialogComponent patientId={props.patientId} />

                {renderSection(EmployeeType.HealthCoach, coachSectionWithTypes)}
                <Divider className={classes.divider} />
                {renderSection(EmployeeType.Provider, providerSectionWithTypes)}
                <Popover
                    keepMounted
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl)}
                    onClose={() => handleToggleMenu(0)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <WildHealthMenuItem id="appointment-widget-cancel" onClick={() => handleCancel()}>Cancel</WildHealthMenuItem>
                    <WildHealthMenuItem id="appointment-widget-reschedule" onClick={() => handleReschedule()}>Reschedule</WildHealthMenuItem>
                </Popover>
                {
                    props.patientId && <>
                        <Box py={2}>
                            <Divider className={classes.divider} />
                        </Box>
                        <AppointmentOptionsDialogComponent patientId={props.patientId} />
                    </>
                }
            </Box>
        )
    }

    return (
        <Card className={classes.root} variant="elevation" elevation={0}>
            <CardContent className={classes.content}>
                <Typography className={classes.title} gutterBottom>
                    Upcoming Appointments
                </Typography>

                {renderContent()}
            </CardContent>
        </Card>
    );
}