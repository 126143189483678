import React from 'react';
import { Practice } from '../../auth/models/auth.enums';
import { authQuery } from '../../auth/stores/auth';

export interface OptionalComponentProps {
    children: JSX.Element;
    defaultContent?: JSX.Element;
    practiceId?: Practice;
}

export const OptionalComponent: React.FC<OptionalComponentProps> = (props: OptionalComponentProps) => {
    const {
        children,
        defaultContent = <></>,
        practiceId = 0,
    } = props;

    const canShow = practiceId === 0 ? authQuery.isDefaultPractice() : authQuery.getCurrentPracticeId() === Number(practiceId);

    return (canShow ? children : defaultContent);
};
