import Axios from 'axios-observable';
import { Observable } from 'rxjs';
import { authHeader } from '../../common/helpers/auth-header';
import { snackService } from '../../common/snack/state';
import { InsuranceStore, insuranceStore } from '../../insurance/stores/insurance.store';
import { CommentModel, CreateCommentModel } from '../models/comment.models';

/**
 * Provides method for working with Comments
 */
export class CommentsService {
    private url = `${process.env.REACT_APP_API_URL}Comments`;

    constructor(private commentsStore: InsuranceStore) {
    }

    public getComments(universalId: string): Observable<CommentModel[]> {
        const config = { headers: authHeader() };

        return new Observable(observer => {
            Axios.get<CommentModel[]>(`${this.url}/${universalId}`, config).pipe()
                .subscribe(
                    response => {
                        this.commentsStore.update({ comments: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        });
    }

    public saveComment(model: CreateCommentModel): Observable<CommentModel> {
        const config = { headers: authHeader() };

        return new Observable(observer => {
            Axios.post<CommentModel>(`${this.url}`, model, config).pipe()
                .subscribe(
                    response => {
                        this.commentsStore.updateComment(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        });
    }

    public updateComment(commentId: number, model: CreateCommentModel): Observable<CommentModel> {
        const config = { headers: authHeader() };

        return new Observable(observer => {
            Axios.put<CommentModel>(`${this.url}/${commentId}`, model, config).pipe()
                .subscribe(
                    response => {
                        this.commentsStore.updateComment(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        });
    }

    public deleteComment(commentId: number) {
        const config = { headers: authHeader() };

        return new Observable(observer => {
            Axios.delete(`${this.url}/${commentId}`, config).pipe()
                .subscribe(
                    response => {
                        this.commentsStore.removeComment(commentId);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }
}

export const commentsService = new CommentsService(insuranceStore);
