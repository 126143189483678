import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles(() =>
    createStyles({
        statisticBlock: {
            background: colors.gray8,
            padding: 24,
            height: '100%',
        },
        statisticTitle: {
            fontWeight: 500,
            fontSize: 24
        }
    })
);