export enum PermissionType {
    None = 0,
    Coaching = 5,
    AddonOrders = 10,
    Shortcuts = 20,
    Appointments = 25,
    EnrollmentNotifications = 30,
    ManageEmployees = 35,
    ManagePatients = 40,
    HealthReportGeneration = 45,
    VisitNoteCreation = 50,
    ClinicAnalytics = 55,
    LabsModification = 60,
    ViewOnly = 65,
    PatientAnalytics = 70,
    LocationAnalytics = 75,
    LmdPatientSignUpPage = 80,
    ManageLicensees = 85,
    ManageLocations = 90,
    MigrationBetweenLocations = 95,
    ManageFellowship = 100,
    FellowshipNotifications = 105,
    Conversations = 110,
    DNAFileManager = 115,
    ManageInsurance = 120,
    ManageLabPricing = 125,
    ManageMessages = 130,
    ManageForwarding = 135,
    AssignUnmatchedResults = 140,
    InvoiceManagement = 145,
    AiHealthCoachAssist = 150,
    OpsAdminTools = 155,
    ManageKbDocuments = 160,
    PremiumBilling = 165
}

export enum UserType {
    Patient = 1,
    Employee = 2,
    Unspecified = 3
}

export enum Practice {
    WildHealth = 1
}

export enum Roles {
    Coach = 1,
    Provider = 2,
}

export enum ExternalAuthProvider {
    Google = 5,
    fullscript = 10
}
