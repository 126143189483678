import { makeStyles } from "@material-ui/core";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme) => ({
  historyContainer: {
    minHeight: 'calc(100vh - 300px)'
  },
  filters: {
    minWidth: 300,
  },
  nested: {
    paddingRight: theme.spacing(2),
    minWidth: 20,
  },
  activeIcon: {
    color: colors.main,
  },
  avatar: {
    width: 24,
    height: 24,
    backgroundColor: colors.gray4,
    color: colors.gray1,
    fontSize: 12,
  },
  avatarPreparing: {
    width: 6,
    height: 6,
    backgroundColor: colors.gray1,
    borderRadius: '50%',
  },
  avatarGood: {
    width: 6,
    height: 6,
    backgroundColor: colors.good,
    borderRadius: '50%',
  },
  avatarOrange: {
    width: 6,
    height: 6,
    backgroundColor: colors.average1,
    borderRadius: '50%',
  },
  avatarDanger: {
    width: 6,
    height: 6,
    backgroundColor: colors.bad,
    borderRadius: '50%',
  },
  avatarNA: {
    width: 6,
    height: 6,
    borderRadius: '50%',
    border: `1px solid ${colors.mediumGray}`,
  },
  avatarEmpty: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 24,
    height: 24,
    fontSize: 11,
    borderRadius: '50%',
    background: '#F6F8FB',
    color: colors.gray1,
    marginRight: 12
  },
  customTooltip: {
    backgroundColor: colors.lightBlack,
    padding: 12,
    fontSize: 14,
  },
  arrow: {
    color: colors.lightBlack,
  },
  banner: {
    backgroundColor: colors.gray3,
    width: '100%',
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    [theme.breakpoints.down("xs")]: {
      display: 'inherit'
    },
  },
  nextUpSection: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down("xs")]: {
      justifyContent: 'space-between',
    },
  },
  nextUp: {
    backgroundColor: colors.average2,
    padding: '5px 10px',
    fontSize: '14px',
    width: 'auto',
    borderRadius: 2,

    [theme.breakpoints.down("xs")]: {
      width: 'fit-content',
    },
  },
  start: {
    backgroundColor: colors.main,
    padding: '9px 30px',
    fontSize: '14px',
    color: colors.white,
    width: 'auto',
    cursor: 'pointer !important',
    borderRadius: 2,
    textTransform: 'capitalize',

    [theme.breakpoints.down("xs")]: {
      width: 'fit-content',
    },
  },
  bannerMessage: {
    width: '75%',
    textAlign: 'center',

    [theme.breakpoints.down("xs")]: {
      textAlign: 'left',
      width: '100%',
      marginTop: 16
    },
  },
  noCompleted: {
    [theme.breakpoints.down("xs")]: {
      textAlign: 'center',
      marginTop: '150px',
      fontSize: 20,
      fontWeight: 600,
      paddingLeft: 48,
      paddingRight: 48,
    },
  },
  image: {
    maxWidth: 300,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 444,
    },
  },
}));
