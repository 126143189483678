import { Box, Grid, IconButton, TextField, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import { useFacade } from "./waitListDialogComponent.hooks";
import { phoneNumberInput } from "../../../common/components/PhoneNumberInput";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";

export const WaitListDialogComponent: React.FC = () => {
    const [
        {
            model,
            limitationReason,
            isOpen,
            isSent,
            errors,
            isLoading
        },
        renderRecaptcha,
        handleChanges,
        handleSubmit,
        handleClose
    ] = useFacade();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const renderForm = () => {
        return (
            <>
                <Box display="flex" justifyContent="center">
                    <Grid item md={10} sm={12} xs={12}>
                        <Box textAlign="center" style={{ lineHeight: '1.6' }}>
                            <span className="secondary-font size-1_5">
                                {limitationReason}
                            </span>
                        </Box>
                    </Grid>
                </Box>
                <Box display="flex" justifyContent="center" py={5}>
                    <Grid item md={6} sm={12} xs={12}>
                        <Grid container spacing={2}>
                            <Grid item md={6} sm={12} xs={12}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    required
                                    id="firstName"
                                    variant="outlined"
                                    value={model.firstName}
                                    label="First Name"
                                    error={!!errors['firstName']}
                                    helperText={errors['firstName']}
                                    onChange={(v) => {
                                        handleChanges(v.target.id, v.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                <Box>
                                    <TextField
                                        required
                                        fullWidth
                                        size="small"
                                        id="lastName"
                                        variant="outlined"
                                        value={model.lastName}
                                        label="Last Name"
                                        error={!!errors['lastName']}
                                        helperText={errors['lastName']}
                                        onChange={(v) => {
                                            handleChanges(v.target.id, v.target.value)
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Box width={1} mt={2}>
                            <TextField
                                fullWidth
                                required
                                size="small"
                                id="phoneNumber"
                                variant="outlined"
                                label="Phone number"
                                value={model.phoneNumber}
                                error={!!errors['phoneNumber']}
                                helperText={errors['phoneNumber']}
                                InputProps={{
                                    inputComponent: phoneNumberInput,
                                }}
                                onChange={(v) => {
                                    handleChanges(v.target.id, v.target.value)
                                }}
                            />
                        </Box>
                        <Box mt={2} width={1}>
                            <TextField
                                required
                                fullWidth
                                size="small"
                                id="email"
                                variant="outlined"
                                value={model.email}
                                label="Email"
                                error={!!errors['email']}
                                helperText={errors['email']}
                                onChange={(v) => {
                                    handleChanges(v.target.id, v.target.value)
                                }}
                            />
                        </Box>
                        <Box mt={2} width={1}>
                            <TextField
                                required
                                fullWidth
                                size="small"
                                id="state"
                                variant="outlined"
                                value={model.state}
                                label="State"
                                error={!!errors['state']}
                                helperText={errors['state']}
                                onChange={(v) => {
                                    handleChanges(v.target.id, v.target.value)
                                }}
                            />
                        </Box>
                        <Box width={1} display="flex" justifyContent="center" style={{
                            paddingBottom: isMobile ? '1rem' : '5rem',
                            paddingTop: isMobile ? '1rem' : '5rem'
                        }}>
                            <Grid item md={4} xs={12} sm={12}>
                                <WildHealthButton
                                    id="waitlist-join"
                                    loading={isLoading}
                                    onClick={() => handleSubmit()}
                                    fullWidth
                                >
                                    <span className="select-button">  Join Waitlist </span>
                                </WildHealthButton>
                            </Grid>
                        </Box>
                        <Box>
                            {renderRecaptcha()}
                        </Box>
                    </Grid>
                </Box>
            </>
        );
    }

    const renderSuccessMessage = () => {
        return (
            <Box height={1} display="flex" alignItems="center">
                <Box textAlign="center" pb={10}>
                    <Box height="100px" fontSize="90px">
                        <CheckCircleOutlineIcon fontSize="inherit" className="text-accent" />
                    </Box>
                    <Box mt={2} textAlign="center">
                        <span className="secondary-font size-1_5">
                            Thank you for being patient with us as we grow.  We will contact you when we have availability.
                        </span>
                    </Box>
                </Box>
            </Box>
        )
    }

    return (
        <>
            <Dialog
                fullWidth
                maxWidth={'md'}
                fullScreen={isMobile}
                open={isOpen}
                onClose={() => handleClose()}
                aria-labelledby="form-dialog-title">

                <Box width={1} display="flex" justifyContent="flex-end">
                    <Box m={1}>
                        <IconButton id="wait-list-close" aria-label="close" onClick={() => handleClose()}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                <DialogContent>
                    {
                        isSent
                            ? renderSuccessMessage()
                            : renderForm()
                    }
                </DialogContent>
            </Dialog>
        </>
    )
}
