import { Box, Button, CircularProgress, Grid, IconButton, useTheme, useMediaQuery, Tooltip } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import GetAppIcon from '@material-ui/icons/GetApp';
import moment from 'moment';
import React from 'react';
import { AuthenticatedLayoutComponent } from "../../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent";
import { PageTitleComponent } from '../../../../../components/pageTitleComponent/PageTitleComponent';
import { TitlesService } from '../../../../../constants/title.constants';
import { MenuItemTypes } from "../../../../../models/menu.models";
import WildHealthLinearProgress from "../../../../common/components/WildHealthLinearProgress";
import { MedicationsSupplementsComponent } from '../../../components/medicationsSupplements/MedicationsSupplementsComponent';
import { NoteRecommendationsComponent } from "../../../components/noteRecommendations/NoteRecommendationsComponent";
import { NoteSignatureComponent } from "../../../components/noteSignature/NoteSignatureComponent";
import { useFacade } from './patientInitialConsultPage.hooks';
import { colors } from "../../../../common/constants/colors";
import clsx from 'clsx';
import commonUseStyles from "../../../../common/styles/common.styles";
import { WildHealthButton } from '../../../../common/components/wildHealthButton/WildHealthButton';
import { HealthSummaryListDataModel } from '../../../../healthSummary/models/healthSummary.models';
import { WildHealthTableRow } from '../../../../common/components/wildHealthTable/WildHealthTableRow';
import { WildHealthTableCell } from '../../../../common/components/wildHealthTable/WildHealthTableCell';
import InfoIcon from '@material-ui/icons/InfoRounded';

interface PatientInitialConsultPageNewProps {
    noteId: number;
}

export const PatientInitialConsultPageNew: React.FC<PatientInitialConsultPageNewProps> = (props: PatientInitialConsultPageNewProps) => {
    const noteId = props.noteId;

    const [
        {
            note,
            content,
            medications,
            supplements,
            isPdfDownloading,
            internalContent
        },
        handleGoBack,
        handleDownloadPdf
    ] = useFacade(noteId);

    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const isLoading = !content || !note || !internalContent;

    const renderTitle = () => {
        return `${note.employee.firstName} - ${moment(note.visitDate).format("ll")}`;
    }

    const renderMedicationsSupplements = () => {
        return <MedicationsSupplementsComponent medications={medications} supplements={supplements} />
    }

    const renderRecommendations = () => {
        const recommendation = JSON.parse(content?.content);
        return (
            <NoteRecommendationsComponent
                recommendation={recommendation}
            />
        );
    }

    const renderBloodPressure = () => {
        if(internalContent.systolicBloodPressure <= 0 || internalContent.diastolicBloodPressure <= 0){
            return (<React.Fragment></React.Fragment>)
        }

        return (
            <Box>
                <Box>
                    <span className={"size-1_5 text-medium uppercase"}>Blood Pressure</span>
                </Box>
                <Box mt={3}>
                    {
                        internalContent.systolicBloodPressure &&  internalContent.diastolicBloodPressure &&
                        <Box>
                            {internalContent.systolicBloodPressure} / {internalContent.diastolicBloodPressure}
                        </Box>
                    }
                </Box>
            </Box>
        );
    }

    const renderIntention = () => {
        if(internalContent.intention == null || internalContent.intention == undefined){
            return (<React.Fragment></React.Fragment>)
        }

        return (
            <Box>
                <Box>
                    <span className={"size-1_5 text-medium uppercase"}>Intention</span>
                </Box>
                <Box mt={3}>
                    {
                        internalContent.intention && internalContent.intention.length &&
                        <Box>
                            {internalContent.intention}
                        </Box>
                    }
                </Box>
            </Box>
        );
    }

    const renderRowProblemList = (item: HealthSummaryListDataModel) => {
        return (
            <Box>
                <WildHealthTableRow key={item.key}>
                    <WildHealthTableCell>
                        <Box ml={-1.5} display='flex'>
                            <Box width='30px' minWidth='30px'>
                                {item.tooltip && <Tooltip placement="top" arrow
                                    title={item.tooltip}>
                                    <InfoIcon fontSize='small' style={{ fill: colors.gray2 }} />
                                </Tooltip>}
                            </Box>
                            {
                                item.name
                            }
                        </Box>
                    </WildHealthTableCell>
                    <WildHealthTableCell>
                        <Box display="flex" flexWrap="wrap">
                            {item.value ?? '--'}
                        </Box>
                    </WildHealthTableCell>
                </WildHealthTableRow>
            </Box>
        );
    }

    const renderProblemList = () => {
        if(!internalContent.currentMedicalConditions){
            return (<React.Fragment></React.Fragment>)
        }

        return (
            <Box>
                <Box>
                    <span className={"size-1_5 text-medium uppercase"}>Problem List</span>
                </Box>
                {
                    internalContent.currentMedicalConditions.map((item) => {
                        return renderRowProblemList(item);
                    })
                }
            </Box>
        );
    }

    const displayDownloadPdfButton = () => {
        if (isPdfDownloading === true) {
            return (
                <Box width="70px" display="flex" justifyContent="center">
                    <CircularProgress size={24} style={{ color: colors.main }} />
                </Box>
            )
        }

        return (
            <Button id="patient-initial-consult-download-pdf" onClick={() => {
                handleDownloadPdf(note, content, medications, supplements);
            }}>
                <Box display="flex" alignItems="center" mt={1}>
                    <Box>
                        <GetAppIcon />
                    </Box>
                    <Box mt={-1}>
                        PDF
                    </Box>
                </Box>
            </Button>
        );
    }

    const renderNote = () => {
        return (
            <>
                {isSmallScreen && <Box mb={3} className={clsx(commonClasses.size20, commonClasses.textMedium, commonClasses.colorBlack)}>{note.name}</Box>}
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        {renderIntention()}
                    </Grid>
                    <Grid item xs={12}>
                        {renderBloodPressure()}
                    </Grid>
                    <Grid item xs={12}>
                        {renderProblemList()}
                    </Grid>
                    <Grid item xs={12}>
                        {renderMedicationsSupplements()}
                    </Grid>
                    <Grid item xs={7}>
                        {renderRecommendations()}
                    </Grid>
                </Grid>

                <Box py={isSmallScreen ? 2 : 5}>
                    <NoteSignatureComponent note={note} noteId={note.id} />
                </Box>

            </>
        )
    }

    const pageName = (
        <PageTitleComponent
            startAdornment={
                <IconButton id="patient-initial-consult-go-back" onClick={() => handleGoBack()}>
                    <ArrowBackIcon />
                </IconButton>
            }
            title={
                isLoading
                    ? 'Loading . . .'
                    : renderTitle()
            }
            endAdornment={
                displayDownloadPdfButton()
            }
        />
    );

    return (
        <AuthenticatedLayoutComponent
            title={TitlesService.noteDetailTitle}
            pageName={pageName}
            selectedMenuItem={MenuItemTypes.Notes}
        >
            <Box className={isSmallScreen && commonClasses.bgMain}>
                {isSmallScreen && (
                    <>
                        <Box py={2} display="flex" alignItems="center">
                            <IconButton id="patient-initial-consult-go-back" onClick={() => handleGoBack()}>
                                <ArrowBackIcon />
                            </IconButton>
                            <Box className={clsx(commonClasses.size14, commonClasses.textRegular, commonClasses.colorGray1)}>
                                Back to Notes
                            </Box>
                        </Box>
                        <Box position="fixed" right={16} bottom={40} zIndex={1202}>
                            <WildHealthButton id="download-pdf" width="48px" loading={isPdfDownloading} style={{ borderRadius: "50%", minWidth: 48, height: 48 }} onClick={() => handleDownloadPdf(note, content, medications, supplements)}>
                                <GetAppIcon className={commonClasses.colorWhite} />
                            </WildHealthButton>
                        </Box>
                    </>
                )}
                {
                    isLoading ?
                        <WildHealthLinearProgress /> :
                        <Box p={isSmallScreen ? 2 : 5} pb={4} className={isSmallScreen && commonClasses.bgWhiteMain}>
                            {renderNote()}
                        </Box>
                }
            </Box>
        </AuthenticatedLayoutComponent>
    )
};