import React from 'react';
import clsx from "clsx";
import { Avatar, Box, Divider, Grid, useTheme, useMediaQuery } from "@material-ui/core";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { ConversationAuthorModel, ConversationModelBase, EmployeeConversationParticipantModel } from "../../models/conversation.models";
import { SendMessageComponent } from "../sendMessageComponent/SendMessageComponent";
import { InternalThreadComponent } from "../internalThreadComponent/InternalThreadComponent";
import { useFacadeInternal as useFacade } from "./../conversationComponent/conversationComponent.hooks";
import { useStyles, useDropStyles } from "../conversationComponent/conversationComponent.styles";
import moment from "moment";
import { toCurrentTimeZone } from "../../../timezones/helpers/timezone";
import { displayShortName } from "../../helpers/messages.converter";
import { CloudUploadOutlined } from "@material-ui/icons";
import Dropzone from "react-dropzone";
import { SendMessageComponentMobile } from '../sendMessageComponentMobile/SendMessageComponentMobile';
import { ProtectedElement } from '../../../common/components/ProtectedElement';
import { SelectShortcutComponent } from '../../../healthReport/components/selectShortcutComponent/SelectShortcutComponent';
import { UserType } from '../../../auth/models/auth.enums';

interface InternalConversationComponentProps {
    conversation: ConversationModelBase;
    author: ConversationAuthorModel;
    threadHeight?: string;
    signedBy: EmployeeConversationParticipantModel;
}

export const InternalConversationComponent: React.FC<InternalConversationComponentProps> = (props: InternalConversationComponentProps) => {
    const {
        conversation,
        author,
        threadHeight,
        signedBy,
    } = props;

    const classes = useStyles();
    const dropClasses = useDropStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const [
        {
            isLoadingMessages,
            scrollToIndex,
            lastReadMessageIndex,
            messages,
            typing,
            toSendAttachments,
            awayMessageEnabled
        },
        handleSendMessage,
        handleTyping,
        handleDropAttachment,
        handleRemoveAttachment,
        handleNavigateToSettings
    ] = useFacade(conversation, author);

    const DynamicSendMessageComponent = isSmallScreen
        ? SendMessageComponentMobile
        : SendMessageComponent;

    const fileTypes = ["application/pdf", "image/jpeg", "image/png"];

    const displayName = (employee: EmployeeConversationParticipantModel): string => {
        return `${employee.firstName} ${employee.lastName}`;
    }

    const displayDate = (date: Date): string => {
        return date ? `on ${moment(toCurrentTimeZone(date)).format("MMM D, YYYY")}` : '';
    }

    return (
        <>
            <Dropzone onDrop={handleDropAttachment} accept={fileTypes}>
                {({ getRootProps, getInputProps, isDragActive }) => {
                    const rootProps = getRootProps();
                    const handleAttach = rootProps["onClick"];
                    delete rootProps["onClick"];

                    const sendMessageSection = (
                        !signedBy ?
                            <>
                                <Divider className={classes.divider} orientation='horizontal' />
                
                                <Box className={classes.sendMessage}>
                                    <DynamicSendMessageComponent
                                        handleSubmit={(message) => handleSendMessage(message)}
                                        handleTyping={handleTyping}
                                        placeholder='Reply'
                                        canAttach
                                        attachments={toSendAttachments}
                                        handleAttach={handleAttach}
                                        handleRemoveAttachment={handleRemoveAttachment}
                                        inputProps={getInputProps()}
                                    />
                                </Box>
                            </> :
                            <Box className={classes.closedTicket}>
                                {` This conversation has been signed off by ${displayName(signedBy)} ${displayDate(signedBy.signDate)}.`}
                            </Box>
                    )

                    return isDragActive ? (
                        <div {...rootProps} className={dropClasses.dropContainer}>
                            <CloudUploadOutlined className={dropClasses.dropIcon} />
                            <span className={dropClasses.dropTitle}>Release to Upload</span>
                        </div>
                    ) : <>
                        <Box
                            id='thread'
                            className={clsx(classes.thread, 'flat-scroll')}
                            height={threadHeight}
                            maxHeight={threadHeight}
                        >
                            <InternalThreadComponent
                                isLoadingMessages={isLoadingMessages}
                                messages={conversation ? messages : []}
                                lastReadMessageIndex={lastReadMessageIndex}
                                scrollToIndex={scrollToIndex}
                                threadStartId={`thread-start-${conversation?.id}`}
                                participants={[...conversation.employees.filter(i => !i.isDeleted), ...conversation.patients]}
                                author={author}
                            />
                        </Box>

                        <Grid container alignItems='center'>
                            {
                                typing.map((item, index) =>
                                    <Box key={index} display='flex' alignItems='center' ml={1} mb={1} px={1}>
                                        <Avatar
                                            className={classes.typingAvatar}
                                            variant="rounded"
                                            src={item.image}
                                        >
                                            {displayShortName(item.firstName, item.lastName)}
                                        </Avatar>
                                        <Box ml={1}>
                                            {`${item.firstName} ${item.lastName}`}
                                        </Box>
                                    </Box>
                                )
                            }
                            {
                                typing.length ?
                                    <Box mb={1}>
                                        is typing . . .
                                    </Box> :
                                    <></>
                            }
                        </Grid>
                        {awayMessageEnabled && (
                            <Box width={1} className="wh-tw-px-5 wh-tw-py-2.5">
                                <Box display="flex" alignItems="center" justifyContent="space-between" className="wh-tw-p-2.5 wh-tw-bg-gray8">
                                    <Box className="wh-tw-text-black wh-tw-text-sm wh-tw-font-medium">Your account has an away message turned on. If you want to turn it off, this can be adjusted in your message settings.</Box>
                                    <Box display="flex" alignItems="center" className="wh-tw-cursor-pointer">
                                        <Box className="wh-tw-text-primaryV wh-tw-text-sm wh-tw-font-medium wh-tw-px-2" onClick={handleNavigateToSettings}>Open Settings</Box>
                                        <ChevronRightIcon className="wh-tw-text-primaryV" />
                                    </Box>
                                </Box>
                            </Box>
                        )}
                        {sendMessageSection}
                        
                        <ProtectedElement
                            element={<SelectShortcutComponent />}
                            permissions={[]}
                            userType={UserType.Employee}
                        />
                    </>

                }}
            </Dropzone>
        </>
    )
}