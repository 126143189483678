import { Badge, Button, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Popover } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import NotificationsNoneRoundedIcon from '@material-ui/icons/NotificationsNoneRounded';
import moment from 'moment';
import React from 'react';
import { NotificationModel } from "../../models/notifications.models";
import { useFacade } from "./NotificationsButtonComponent.hooks";
import { useStyles } from "./notificationsButtonComponent.styles";
import { WildHealthSaveClickableElement } from "../../../common/components/wildHealthSaveClickableElement/WildHealthSaveClickableElement";
import {toCurrentTimeZone} from "../../../timezones/helpers/timezone";

const notificationsPopoverProps: any = {
    id: "notificationsPopover",
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    }
};

const messageLength = 150;

export const NotificationsButtonComponent: React.FC = () => {
    const [
        { anchorEl, showNotifications, notifications },
        handleNavigateTo,
        handleDelete,
        handleDeleteAll,
        handleToggleNotifications
    ] = useFacade();

    const classes = useStyles();

    const displayDate = (date: Date) => {
        return moment(toCurrentTimeZone(date)).format("MMM D - hh:mm a");
    }

    const renderTitle = (notification: NotificationModel) => {
        return `${notification.subject} (${displayDate(notification.createdAt)})`;
    }

    const renderMessage = (notification: NotificationModel) => {
        return notification.text.length < messageLength ? notification.text : `${notification.text.substring(0, messageLength)} . . .`;
    }

    return (
        <>
            <Button
                id={`notifications-button=${notificationsPopoverProps.id}`}
                key={`notifications-button=${notificationsPopoverProps.id}`}
                aria-describedby={notificationsPopoverProps.id}
                className={classes.iconButton} onClick={(event) => handleToggleNotifications(event)}
                disabled={notifications.length < 1}
                color="inherit"
            >
                <Badge badgeContent={notifications.length} max={99} color="secondary" overlap="rectangle">
                    <NotificationsNoneRoundedIcon />
                </Badge>
            </Button>
            <Popover
                key={`popover=${notificationsPopoverProps.id}`}
                id={notificationsPopoverProps.id}
                anchorEl={anchorEl}
                onClose={(event) => handleToggleNotifications(event)}
                open={showNotifications}
                anchorOrigin={notificationsPopoverProps.anchorOrigin}
                transformOrigin={notificationsPopoverProps.transformOrigin}
            >
                <List className={classes.list}>
                    <ListItem button key={-1}>
                        <WildHealthSaveClickableElement
                            id="notifications-btn-delete-all"
                            component={ListItemText}
                            onClick={() => handleDeleteAll()}
                            primary="Clear All"
                        />
                    </ListItem>
                    {notifications.map((item, index) =>
                        <ListItem button key={index}>
                            <ListItemText
                                primary={renderTitle(item)}
                                secondary={renderMessage(item)}
                                onClick={ () => handleNavigateTo(item)}
                            />
                            <ListItemSecondaryAction>
                                <WildHealthSaveClickableElement
                                    id={`notifications-btn-delete-${item.id}`}
                                    onClick={() => handleDelete(item.id)}
                                    component={IconButton}
                                    aria-label="delete"
                                    timeout={500}
                                    edge="end"
                                >
                                    <CloseIcon />
                                </WildHealthSaveClickableElement>
                            </ListItemSecondaryAction>
                        </ListItem>
                    )}
                </List>
            </Popover>
        </>
    );
}
