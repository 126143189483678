import { Box, Link, Typography, useTheme, useMediaQuery, RootRef } from "@material-ui/core";
import React, { useEffect, useRef } from 'react';
import {
    BarChart,
    Bar,
    Cell,
    XAxis,
    YAxis,
    Tooltip,
    ReferenceLine
} from "recharts";
import { ScoreTermsModel } from '../../../healthScore/models/score.model';
import { useStyles, OptionTooltip } from "./GeneralChartComponent.style";
import { colors } from "../../../common/constants/colors";
import clsx from 'clsx';
import commonUseStyles from '../../../common/styles/common.styles';
import { useFacade } from './generalChartComponent.hooks';
import trendingUp from "@img/icons/TrendingUp.svg";
import trendingDown from "@img/icons/TrendingDown.svg";
import { WildHealthClickableTooltip } from "../../../common/components/WildHealthClickableTooltip/WildHealthClickableTooltip";


interface GeneralChartComponentProps {
    title: string,
    color: string,
    data: ScoreTermsModel[],
}

export const GeneralChartComponent: React.FC<GeneralChartComponentProps> = (props: GeneralChartComponentProps) => {
    const {
        title,
        color,
    } = props;

    const refBlock = useRef(null);

    const [
        { data , barSize },
        handleChartTooltipToggle,
    ] = useFacade(props.data, refBlock);

    const classes = useStyles();
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const customTooltipClasses = {
        tooltip: classes.tooltip,
        arrow: classes.arrow,
        tooltipPlacementTop: classes.tooltipPlacement,
        tooltipPlacementBottom: classes.tooltipPlacement,
    };

    const defaulScoreHeight = 40;

    const displayDataWithValue = data.filter(el => el.displayValue !== null)

    const renderTooltip = ({ payload }) => {
        if (payload.length) {
            const text = payload[0];
            return <Box className={!isSmallScreen && classes.chartTooltip}>{text.payload.hoverOverText}</Box>
        }
        return <></>
    }

    const renderItemName = (name: string) => {
        const x = name.split(" ")
        return x.map((el, index) => <span className={classes.paddingRight} key={index}>{el}</span>)
    }

    const getFillColor = (value: number) => {
        if (value < 0) {
            return colors.bad
        }

        if (value < 1 && value > 0) {
            return colors.accent2
        }
        return colors.main
    }

    const smallChartWidth = window.innerWidth - 70;

    return (
        <RootRef rootRef={refBlock}>
            <Box width="100%" display='flex' flexDirection="column" justifyContent='space-between'>
                <Box>
                    <Box display='flex' alignItems='center'>
                        <Typography className={clsx(classes.title, commonClasses.capitalize)}>
                            {title.replace('_', ' ')}
                        </Typography>
                    </Box>
                    <Box className={clsx([defaulScoreHeight * displayDataWithValue.length > 380 && classes.itemContainer], 'flat-scroll')}>
                        {displayDataWithValue && displayDataWithValue.length !== 0 && (
                            <>
                                {isSmallScreen ? (
                                    displayDataWithValue.map((item, index) => {
                                        return (
                                            <Box className={classes.itemContent} key={index}>
                                                    <Box>
                                                        <Box display='flex' alignItems='center' justifyContent="space-between" className={classes.scoreSection}>
                                                            <Box display='flex' alignItems='center'>
                                                                <WildHealthClickableTooltip
                                                                    tooltipContent={item.hoverOverName}
                                                                    isOpened={item.isOpenedTitle}
                                                                    handleClose={() => handleChartTooltipToggle(item.name, false, "title")}
                                                                    customClasses={customTooltipClasses}
                                                                >
                                                                    <span onClick={() => handleChartTooltipToggle(item.name, true, "title")}>{renderItemName(item.name)}</span>
                                                                </WildHealthClickableTooltip>
                                                                {item.referenceLink &&
                                                                    <span style={{ color: `${colors.main}`, paddingLeft: 4 }}>
                                                                        <Link
                                                                            id={`general-chart-${index}`}
                                                                            color='inherit'
                                                                            href={item.referenceLink}
                                                                            target="_blank"
                                                                            rel="noopener"
                                                                        >
                                                                            [{index + 1}]
                                                                            </Link>
                                                                    </span>
                                                                }
                                                            </Box>
                                                            <WildHealthClickableTooltip
                                                                tooltipContent={item.hoverOverText}
                                                                isOpened={item.isOpenedGraph}
                                                                handleClose={() => handleChartTooltipToggle(item.name, false, "score")}
                                                                customClasses={customTooltipClasses}
                                                            >
                                                                <img src={item.displayValue > 0 ? trendingUp : trendingDown} alt="img" onClick={() => handleChartTooltipToggle(item.name, true, "score")} />
                                                            </WildHealthClickableTooltip>
                                                        </Box>
                                                    </Box>
                                                <BarChart
                                                    layout='vertical'
                                                    width={smallChartWidth}
                                                    height={defaulScoreHeight}
                                                    data={[item]}
                                                >
                                                    <XAxis hide type="number" domain={[-10, 10]} />
                                                    <YAxis
                                                        axisLine={false}
                                                        tickLine={false}
                                                        type="category"
                                                        width={0}
                                                        tick={false}
                                                    />
                                                    <Bar
                                                        dataKey="displayValue"
                                                        tickCount={1}
                                                        fill={color}
                                                        barSize={14}
                                                        background={{ fill: colors.separators }}
                                                    >
                                                        <Cell key={`cell-${index}`} fill={getFillColor(item.displayValue)} strokeWidth="200" />
                                                    </Bar>
                                                </BarChart>
                                            </Box>
                                        )
                                    })
                                ) : (
                                        <Box className={classes.itemContent}>
                                            <Box>
                                                {
                                                    displayDataWithValue.map((item, index) => {
                                                        return (
                                                            <Box key={index} display='flex' alignItems='center' className={classes.scoreSection}>
                                                                <OptionTooltip title={item.hoverOverName} arrow placement="top">
                                                                    <span>{renderItemName(item.name)}
                                                                        {item.referenceLink &&
                                                                            <span style={{ color: `${colors.main}` }}>
                                                                                <Link
                                                                                    id={`general-chart-${index}`}
                                                                                    color='inherit'
                                                                                    href={item.referenceLink}
                                                                                    target="_blank"
                                                                                    rel="noopener"
                                                                                >
                                                                                    [{index + 1}]
                                                                </Link>
                                                                            </span>
                                                                        }
                                                                    </span>
                                                                </OptionTooltip>
                                                            </Box>
                                                        )
                                                    })
                                                }
                                            </Box>
                                            <BarChart
                                                layout='vertical'
                                                width={barSize}
                                                height={defaulScoreHeight * displayDataWithValue.length}
                                                data={displayDataWithValue}
                                            >
                                                <XAxis hide type="number" domain={[-10, 10]} />
                                                <YAxis
                                                    axisLine={false}
                                                    tickLine={false}
                                                    type="category"
                                                    width={0}
                                                    tick={false}
                                                />
                                                <Tooltip
                                                    content={renderTooltip}
                                                />

                                                <ReferenceLine x={0} stroke={colors.stroke} />
                                                <Bar
                                                    dataKey="displayValue"
                                                    tickCount={1}
                                                    fill={color}
                                                    barSize={14}
                                                >
                                                    {
                                                        displayDataWithValue.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={getFillColor(entry.displayValue)} strokeWidth="200" />
                                                        ))
                                                    }
                                                </Bar>
                                            </BarChart>
                                        </Box>
                                    )}
                            </>
                        )}
                    </Box>
                </Box>
                <Box className={classes.cardFooter}>
                    <Box display="flex" width={isSmallScreen ? 1 : barSize} justifyContent='center' alignItems='center'>
                        <Box className={classes.indicator} p={1} mr={3}>-</Box>
                        <Box className={classes.divider}> </Box>
                        <Box className={classes.indicator} p={1} ml={3}>+</Box>
                    </Box>
                </Box>

            </Box>
        </RootRef>
    )
}

