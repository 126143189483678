import {Box} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import React from 'react';
import {LayoutComponent} from '../../../../components/layoutComponent/LayoutComponent';
import {AuthenticatedLayoutComponent} from '../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import {TitlesService} from '../../../../constants/title.constants';
import {authQuery} from '../../../auth/stores/auth';
import {useFacade} from "./questionnaireCompletedPage.hooks";
import {MenuItemTypes} from '../../../../models/menu.models';

export const QuestionnaireCompletedPage: React.FC = (props: any) => {
    const intakeId = props?.location?.state?.intakeId;
    const [
        handleGoToDashboard
    ] = useFacade();

    if (!intakeId && !authQuery.isLoggedIn()) {
        handleGoToDashboard();
    }

    const content = (
        <>
            <Box textAlign="center">
                <Box height="120px" fontSize="100px">
                    <CheckCircleOutlineIcon fontSize="inherit" className="text-accent"/>
                </Box>
                <Box mt={1}>
                    <span
                        className="secondary-font size-2">Thank you for completing the health questionnaire!
                    </span>
                </Box>
            </Box>
        </>
    );

    return authQuery.isLoggedIn()
        ? <AuthenticatedLayoutComponent
            title={TitlesService.healthQuestionnaireCompletedTitle}
            selectedMenuItem={MenuItemTypes.Questionnaire}>
            {content}
        </AuthenticatedLayoutComponent>
        : <LayoutComponent title={TitlesService.healthQuestionnaireCompletedTitle}>
            {content}
        </LayoutComponent>

}
