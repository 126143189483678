import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../constants/colors';

export const commonUseStyles = makeStyles((theme: Theme) =>
    createStyles({
        mainContent: {
            minHeight: '100vh !important'
        },
        content: {
            padding: 25
        },
        dashboard: {
            backgroundColor: colors.bg,
            width: '100%',
            minHeight: '100vh'
        },
        slogan: {
            fontFamily: 'Be Vietnam Pro, sans-serif',
            fontSize: 16,
            fontStyle: 'italic',
            lineHeight: 1.47,
            letterSpacing: 0.57,
            textAlign: 'left',
            color: colors.black,
        },
        whForm: {
            padding: '44px 42px',
            fontSize: 16,
            fontWeight: 400,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.57,
            letterSpacing: '0.53px',
            textAlign: 'left',
            minHeight: 'calc(100vh - 131px)',
            background: colors.bg,
        },
        whWhiteForm: {
            padding: '44px 42px',
            fontSize: 16,
            fontWeight: 400,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.57,
            letterSpacing: '0.53px',
            textAlign: 'left',
            minHeight: 'calc(100vh - 131px)',
            background: colors.white,
        },
        boxShadow: {
            boxShadow: '0px 4px 9px rgba(168, 173, 198, 0.15)',
        },
        subtitle: {
            color: colors.gray1,
            fontSize: 14,
        },
        error: {
            color: colors.bad,
            fontSize: 14,
        },
        italic: {
            fontStyle: 'italic'
        },
        label: {
            color: colors.darkGray,
            fontSize: 14,
            fontWeight: 400,
        },
        backBtn: {
            height: 48,
            width: 'auto',
            color: colors.darkGray,
            fontWeight: 400,
            backgroundColor: colors.white,
            textTransform: 'none !important' as any
        },
        whButton: {
            color: `${colors.white} !important`,
            backgroundColor: `${colors.primaryV} !important`,
            border: `1px solid ${colors.primaryV}`,
            textTransform: 'none',
            borderRadius: 2,
            '&:hover': {
                background: `${colors.mint700} !important`,
            },
            '&:disabled': {
                color: `${colors.gray400} !important`,
                backgroundColor: `${colors.disabled} !important`,
                border: `1px solid ${colors.disabled}`,
            },
        },
        whiteButton: {
            color: `${colors.black} !important`,
            backgroundColor: `${colors.white} !important`,
            border: `1px solid ${colors.white}`,
            textTransform: 'none',
            borderRadius: 2,
            '&:hover': {
                background: `${colors.main} !important`,
                color: `${colors.white} !important`,
            },
            '&:disabled': {
                color: colors.gray2,
                backgroundColor: colors.disabled,
                border: `1px solid ${colors.disabled}`,
            },
        },
        grayButton: {
            color: `${colors.black} !important`,
            backgroundColor: `${colors.white} !important`,
            border: `1px solid ${colors.lightGray}`,
            textTransform: 'none',
            borderRadius: 2,
            '&:hover': {
                background: `${colors.main} !important`,
                color: `${colors.white} !important`,
            },
            '&:disabled': {
                color: colors.gray2,
                backgroundColor: colors.disabled,
                border: `1px solid ${colors.disabled}`,
            },
        },
        breakWord: {
            wordBreak: 'break-all',
        },
        checkBox: {
            color: colors.main,
            '&$checked': {
                color: colors.main,
            },
        },


        //Fonts
        primaryFont: {
            fontFamily: 'Be Vietnam Pro'
        },
        secondaryFont: {
            fontFamily: 'Poppins'
        },
        tertiaryFont: {
            fontFamily: 'Palatino Normal-Italic, sans-serif'
        },

        //Fonts sizes
        size46: {
            fontSize: 46
        },
        size40: {
            fontSize: 40
        },
        size34: {
            fontSize: 34
        },
        size30: {
            fontSize: 30
        },
        size28: {
            fontSize: 28
        },
        size24: {
            fontSize: 24
        },
        size22: {
            fontSize: 22
        },
        size20: {
            fontSize: 20
        },
        size18: {
            fontSize: 18
        },
        size16: {
            fontSize: 16
        },
        size14: {
            fontSize: 14
        },
        size12: {
            fontSize: 12
        },
        size10: {
            fontSize: 10
        },

        //Fonts weights
        textBold: {
            fontWeight: 700
        },
        textSemiBold: {
            fontWeight: 600
        },
        textMedium: {
            fontWeight: 500
        },
        textRegular: {
            fontWeight: 400
        },

        //Colors
        colorMain: {
            color: colors.main
        },
        colorBlack: {
            color: colors.black
        },
        colorWhite: {
            color: colors.white
        },
        colorBg: {
            color: colors.bg
        },
        backgroundColorBg: {
            backgroundColor: colors.bg
        },
        backgroundColorAccent2: {
            backgroundColor: colors.accent2
        },
        backgroundColorLightGray: {
            backgroundColor: colors.lightGray
        },
        colorAccent1: {
            color: colors.accent1
        },
        colorAccent2: {
            color: colors.accent2
        },
        colorAccent3: {
            color: colors.accent3
        },
        colorGray1: {
            color: colors.gray1
        },
        colorGray2: {
            color: colors.gray2
        },
        colorGray3: {
            color: colors.gray3
        },
        colorGray700: {
            color: colors.gray700
        },
        colorGray900: {
            color: colors.gray900
        },
        backgroundGray3: {
            backgroundColor: colors.gray3
        },
        colorStroke: {
            color: colors.stroke
        },
        colorSeparators: {
            color: colors.separators
        },
        colorLightGray: {
            color: colors.lightGray
        },
        colorDarkGray: {
            color: colors.darkGray
        },
        colorMediumGray: {
            color: colors.mediumGray
        },
        colorBad: {
            color: colors.bad
        },
        colorAverage1: {
            color: colors.average1
        },
        colorAverage2: {
            color: colors.average2
        },
        colorGood: {
            color: colors.good
        },
        colorDisabled: {
            color: colors.disabled
        },
        colorMainDark: {
            color: colors.mainDark
        },
        colorLinkBlack: {
            color: colors.linkBlack
        },
        colorLightBlue: {
            color: colors.lightBlue
        },
        backgroundBad: {
            backgroundColor: colors.badBg
        },
        backgroundGood: {
            backgroundColor: colors.goodBg
        },
        backgroundAverage1: {
            backgroundColor: colors.average1Bg
        },
        colorNavy: {
            color: colors.navy
        },
        colorLightNavy: {
            color: colors.lightNavy
        },
        colorCharcoal: {
            color: colors.charcoal
        },
        colorMint: {
            color: colors.mint
        },
        colorDarkMint: {
            color: colors.darkMint
        },

        // Font style for Report

        tertiaryFontReport: {
            fontFamily: 'Be Vietnam Pro',
            fontStyle: 'italic !important',
            fontWeight: 100,
        },

        sizeBigReport: {
            fontSize: '1.5rem !important',
            [theme.breakpoints.down("xs")]: {
                fontSize: '1.125rem !important',
            },
        },

        textMediumReport: {
            fontWeight: 500,
        },

        textRegularReport: {
            fontWeight: 400,
        },

        sizeSmallReport: {
            fontSize: '1rem !important',
        },

        sizeMediumReport: {
            fontSize: '1rem !important',
        },

        capitalize: {
            textTransform: 'capitalize',
            '&:first-letter': {
                textTransform: 'capitalize',
            }
        },

        //Tooltip
        notificationToolTipText: {
            margin: "5px",
        },
        popper: {
            left: "-5px !important",
        },
        tooltip: {
            width: '225px',
            maxWidth: '225px',
            padding: 8,
            background: colors.lightBlack,
        },
        arrow: {
            color: colors.lightBlack,
        },
        tooltipPlacement: {
            margin: '12px 0',
        },
        notificationIcon: {
            fontSize: 24,
            cursor: 'pointer',
            [theme.breakpoints.down("xs")]: {
              fontSize: 16,
              color: colors.gray2,
              opacity: 0.4,
              "&:hover": {
                color: colors.gray1,
              },
            },
        },

        //Other
        pointer: {
            cursor: 'pointer'
        },
        nowrap: {
            whiteSpace: 'nowrap',
        },
        underline: {
            textDecoration: 'underline'
        },
        uppercase: {
            textTransform: 'uppercase',
        },
        lineThrough: {
            textDecoration: 'line-through'
        },
        fullWidth: {
            width: '100%'
        },
        btnLink: {
            border: 'none',
            backgroundColor: colors.white,
            color: colors.main,
            textDecoration: 'underline',
            fontSize: 16,
            padding: 0,
            cursor: 'pointer'
        },
        btnLinkToNotes: {
            border: 'none',
            color: colors.main,
            fontSize: 16,
            padding: 0,
            cursor: 'pointer'
        },
        primaryTitle: {
            fontSize: 20,
            color: colors.main,
            fontWeight: 'bolder',
            textTransform: 'uppercase',
            fontFamily: 'Poppins',
        },
        secondaryTitle: {
            fontSize: 20,
            color: colors.black,
            fontWeight: 500,
        },
        textField: {
            borderColor: colors.mediumGray,
            borderRadius: 8,
        },
        cursorTransparent: {
            caretColor: 'transparent'
        },
        inputLabel: {
            fontSize: 14,
            color: colors.gray1
        },
        bgMain: {
            backgroundColor: colors.bg,
            width: '100%'
        },
        bgWhiteMain: {
            backgroundColor: colors.white,
            width: '100%'
        },
        renderDivider: {
            backgroundColor: colors.separators,
        },
        mediumTitle: {
            fontSize: 18,
            color: colors.black,
            fontWeight: 500,
        },
        smallTitle: {
            color: colors.gray1,
            fontSize: 16,
            fontWeight: 400
        },
        infoContainer: {
            padding: "10px 20px",
            backgroundColor: colors.info,
            borderRadius: 2,
        },

        title: {
            fontSize: 40,
            fontWeight: 500,
            lineHeight: '50px',
            [theme.breakpoints.down("xs")]: {
                fontSize: 28,
                lineHeight: '34px'
            },
        },
        semiTitle: {
            fontSize: 20,
            fontWeight: 500,
            lineHeight: '28px',
            [theme.breakpoints.down("xs")]: {
                fontSize: 18,
                lineHeight: '25px'
            }
        }

    }),
);

export default commonUseStyles;