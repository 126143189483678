import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Subscription } from "rxjs";
import { navigationService } from "../../../../services/navigation.service";
import { onEmit } from "../../../common/helpers/on-emit";
import { PageSliderNavigationContext } from "../../../common/models/navigation.models";
import { SelectPaymentPeriodComponent } from "../../components/selectPaymentPeriodComponent/SelectPaymentPeriodComponent";
import { ConsultationComponent } from "../../components/consultationComponent/ConsultationComponent";
import { PaymentPeriodModel } from "../../models/paymentPeriod.models";
import { PaymentPlanModel } from "../../models/paymentPlan.models";
import { paymentPlansService } from "../../services/paymentPlans.service";
import { onboardingService } from "../../services/onboarding.service";
import { paymentPlansQuery, PaymentSteps } from "../../stores/paymentPlansStore";
import { onboardingConfigurationQuery } from "../../stores/onboardingConfigurationStore";
import { authQuery } from "../../../auth/stores/auth";
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isFeatureFlag } from '../../../common/components/featureFlags/featureFlags';
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import { FinishCheckoutComponent } from "../../components/finishCheckoutComponent/FinishCheckoutComponent";
import { PaymentCouponModel } from "../../models/paymentPrice.models";
import {
    FinishCheckoutRedesignComponent,
} from "../../components/finishCheckoutRedesignComponent/FinishCheckoutComponent";
import {
    FinishCheckoutExperimentComponent,
} from "../../components/finishCheckoutExperimentComponent/FinishCheckoutComponent";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { addressService } from "../../../account/services/address.service";

interface ConsultationGetStartedState {
  plans: PaymentPlanModel[];
  trialPlan: PaymentPlanModel;
  selectedPlan: PaymentPlanModel | null;
  selectedPeriod: PaymentPeriodModel | null;
  step: PaymentSteps;
  consultationFormId: string;
  coupon?: PaymentCouponModel;
  progress: number;
  progressBarOpened: boolean;
}

/**
 * Custom Hook to manage a view Model for Consultation GetStarted page view components
 */
export function useFacade(): [JSX.Element, ConsultationGetStartedState, boolean] {
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const featureFlags = useFlags();
  const planName = queryParams.get('plan');
  const OnboardingRedesignV2 = isFeatureFlag(featureFlags, FeatureFlag.OnboardingRedesignV2);
  const CheckoutSocialProof = isFeatureFlag(featureFlags, FeatureFlag.CheckoutSocialProof);

  const [state, setState] = useState({
    selectedPlan: null,
    selectedPeriod: null,
    step: PaymentSteps.SelectPlan,
    consultationFormId: '',
    progress: 0,
    progressBarOpened: false
  } as ConsultationGetStartedState);

  const getPlanName = () => {
    if (state.plans?.length === 1) {
      return state.plans[0].name;
    }

    return planName;
  }

  const canGoBack = () => state.step > PaymentSteps.SelectPlan;

  const canGoNext = () => {
    switch (state.step) {
      case PaymentSteps.SelectPlan:
        return state.selectedPlan;
      case PaymentSteps.SelectPeriod:
        return state.selectedPeriod
    }
  }

  const handleNext = () => {
    switch (state.step) {
      case PaymentSteps.SelectPlan:
        return setState(state => ({ ...state, step: PaymentSteps.SelectPeriod }));
      case PaymentSteps.SelectPeriod:
        return setState(state => ({ ...state, step: PaymentSteps.Checkout, progressBarOpened: true }));
    }
  };

  const goBackFromCheckoutPage = () => {
    const step = state.selectedPlan.periods.length === 1
      ? PaymentSteps.SelectPlan
      : PaymentSteps.SelectPeriod;

    setState(state => ({
      ...state,
      step: step,
      progressBarOpened: false
    }));
  }

  const handleBack = () => {
    switch (state.step) {
      case PaymentSteps.SelectPeriod:
        return setState(state => ({ ...state, step: PaymentSteps.SelectPlan }));
      case PaymentSteps.Checkout:
        return goBackFromCheckoutPage();
    }
  };

  const navigationContext = {
    nextButton: "Next",
    backButton: "Back",
    canGoNext: canGoNext,
    canGoBack: canGoBack,
    goNext: handleNext,
    goBack: handleBack
  } as PageSliderNavigationContext

  const selectPlanContent = () => {
    return (
      <ConsultationComponent
        planName={getPlanName()}
        plans={state.plans}
        consultationFormId={state.consultationFormId}
      />
    )
  }

  const checkoutContent = () => {
    if (!state.plans || !state.plans.length) {
      return <WildHealthLinearProgress />
    }

    return CheckoutSocialProof ? <FinishCheckoutExperimentComponent
      plans={state.plans}
      navigationContext={navigationContext}
      handleSetProgress={(progress) => setState(state => ({ ...state, progress}))}
    /> : OnboardingRedesignV2 ? <FinishCheckoutRedesignComponent
      plans={state.plans}
      navigationContext={navigationContext}
      handleSetProgress={(progress) => setState(state => ({ ...state, progress}))}
    /> : <FinishCheckoutComponent navigationContext={navigationContext} plans={state.plans} />;
  }

  const StepsContent = new Map<number, JSX.Element>([
    [PaymentSteps.SelectPlan, selectPlanContent()],
    [PaymentSteps.SelectPeriod, <SelectPaymentPeriodComponent navigationContext={navigationContext} />],
    [PaymentSteps.Checkout, checkoutContent()]
  ]);

  useEffect(() => {
    const subscriptions: Subscription[] = [
      onEmit<PaymentPlanModel[]>(paymentPlansQuery.regularPlans$, plans => setState(state => ({
        ...state,
        plans: plans
      }))),
      onEmit<PaymentPlanModel>(paymentPlansQuery.trialPlan$, trialPlan => setState(state => ({
        ...state,
        trialPlan: trialPlan
      }))),
      onEmit<PaymentPlanModel>(paymentPlansQuery.selectedPlan$, selectedPlan => {
        if (selectedPlan) {
          setState(state => ({
            ...state,
            selectedPlan: selectedPlan,
            step: PaymentSteps.SelectPeriod
          }));
        } else {
          setState(state => ({
            ...state, selectedPlan: selectedPlan
          }));
        }
      }),
      onEmit<PaymentPeriodModel>(paymentPlansQuery.selectedPeriod$, selectedPeriod => {
        if (selectedPeriod) {
          setState(state => ({
            ...state,
            selectedPeriod: selectedPeriod,
            step: PaymentSteps.Checkout,
            progressBarOpened: true
          }));
        } else {
          setState(state => ({
            ...state,
            selectedPeriod: selectedPeriod
          }));
        }
      }),
      onEmit<string>(onboardingConfigurationQuery.consultationFormId$, formId => setState(state => ({
        ...state,
        consultationFormId: formId
      }))),
      onEmit<boolean>(paymentPlansQuery.isCouponApplied$, () => {
        setState(state => ({
          ...state,
          coupon: paymentPlansQuery.getCoupon()
        }));
      }),
    ];

    addressService.getStates().subscribe();
    if (authQuery.isLoggedIn() && authQuery.isRegistrationCompleted()) {
      navigationService.toDashboard(history);
    } else {
      paymentPlansService.getActive(authQuery.getCurrentPracticeId()).subscribe();
      onboardingService.getConfiguration(authQuery.getCurrentPracticeId()).subscribe();
    }

    return () => {
      subscriptions.map(it => it.unsubscribe())
    };
  }, []);

  useEffect(() => {
    navigationService.goUp();
  }, [state.step])

  return [StepsContent.get(state.step), state, OnboardingRedesignV2];
}
