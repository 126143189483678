import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Subscription } from "rxjs";
import { onEmit } from "../../../common/helpers/on-emit";
import { navigationService } from "../../../../services/navigation.service";
import { snackService } from "../../../common/snack/state";
import { accountService } from '../../../../services/account.service';
import { SubscriptionModel, SubscriptionStatus } from '../../models/subscription.models';
import { subscriptionService } from '../../services/subscription.service';
import { subscriptionQuery } from '../../stores/subscriptionStore';

/**
 * Represents payment succeeded page state
 */
interface PaymentSucceededPageState {
    videoDialogOpen: boolean;
    showStickySection: boolean;
    isLoading: boolean;
    activeMembership: SubscriptionModel;
}

/**
 * Custom Hook to manage a view Model for Payment Succeeded page view components
 */
export function useFacade(): [
    PaymentSucceededPageState,
    Function,
    Function,
    Function,
    Function,
] {
    const history = useHistory();

    const [state, setState] = useState({
        isLoading: true,
        activeMembership: null,
        videoDialogOpen: false,
        showStickySection: false
    } as PaymentSucceededPageState);

    const handleGoToQuestionnaire = () => {
        navigationService.toMyHealthForms(history);
    }

    const handleGoToDashboard = () => {
        navigationService.toDashboard(history);
    }

    const handleToggleVideoDialog = (toggle: boolean) => {
        setState({ ...state, videoDialogOpen: toggle });
    }

    const handleLogin = () => {
        accountService.logout()
        navigationService.postLogoutRedirect(history)
    }

    useEffect(() => {
        snackService.postNativeDeviceEvent('registrationSuccessEvent');
        const subscriptions: Subscription[] = [
            onEmit<SubscriptionModel[]>(subscriptionQuery.mySubscriptions$, subscriptions => {
                const active = subscriptions.find(s => s.status === SubscriptionStatus.Active || s.status === SubscriptionStatus.Paused);
                setState(state => ({
                    ...state,
                    activeMembership: active,
                }));
            }),
        ];

        const cb = () => setState(state => ({...state, isLoading: false}));
        subscriptionService.getMy().subscribe(cb, cb);

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [state, handleGoToQuestionnaire, handleGoToDashboard, handleToggleVideoDialog, handleLogin]
}
