import {colors} from "../constants/colors";

function decodeHTMLEntities(text) {
    var entities = [
        ['amp', '&'],
        ['apos', '\''],
        ['#x27', '\''],
        ['#x2F', '/'],
        ['#39', '\''],
        ['#47', '/'],
        ['lt', '<'],
        ['gt', '>'],
        ['nbsp', ' '],
        ['quot', '"']
    ];

    for (var i = 0, max = entities.length; i < max; ++i)
        text = text.replace(new RegExp('&' + entities[i][0] + ';', 'g'), entities[i][1]);

    return text;
}

export const createTextWithLinks = (text) => {
    if (text === null)
        return "";
        
    let match = text.match(/\[([^\]]+)]\(([^)]+)\)/);
    while(match != null) {
        text = text.replace(/\[([^\]]+)]\(([^)]+)\)/, `<a style="color:${colors.main}; text-decoration: underline; padding-right: 4px;" target="_blank" href="$2">$1</a>`);
        match = text.match(/\[([^\]]+)]\(([^)]+)\)/);
    }
    return decodeHTMLEntities(text);
};