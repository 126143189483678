import React from "react";
import { Box, Divider } from "@material-ui/core";
import { ConversationAuthorModel, EmployeeConversationModel } from "../../models/conversation.models";
import { useFacade } from "./stickyChatComponent.hooks";
import { SendMessageCompactComponent } from "../sendMessageCompactComponent/SendMessageCompactComponent";
import Dropzone from "react-dropzone";
import { ConversationState } from "../../models/conversationState.models";
import { EditScheduledMessage } from "../editScheduledMessage/EditScheduledMessage";
import { ScheduleMessageDialogComponent } from "../scheduleMessageDialogComponent/ScheduleMessageDialogComponent";
import { CloudUploadOutlined } from "@material-ui/icons";
import clsx from "clsx";
import { useStyles } from "./stickyChatComponent.style";
import { useDropStyles } from "../conversationComponent/conversationComponent.styles";
import { convertMessagesToGroupsForAiDemo } from "../../helpers/messages.converter";
import { colors } from '../../../common/constants/colors';
import { ThreadComponentForAiDemo } from "../threadComponent/ThreadComponentForAiDemo";
import { ActionButton } from "../sendMessageComponentMobile/sendMessageComponentMobile.styles";
import { ReactComponent as AttachIcon } from '@img/icons/Attach.svg';
import { EditAiMessage } from "../editAiMessage/EditAiMessage";

interface StickyChatComponentProps {
    conversation: EmployeeConversationModel;
    author: ConversationAuthorModel;
}

export const StickyChatComponentForAiDemo: React.FC<StickyChatComponentProps> = (props: StickyChatComponentProps) => {
    const {
        conversation,
        author,
    } = props;

    const [
        {
            isLoadingMessages,
            isScheduleDialogOpen,
            scheduledMessageForEditing,
            scheduledAttachmentsForEditing,
            toSendAttachments,
            emptyMessage,
            scrollToIndex,
            lastReadMessageIndex,
            aiMessageForEditing,
            detailForAiMessage,
            messagesViewForAi,
            scrollToIndexForAi,
            isAiLoading
        },
        handleSendMessage,
        handleDropAttachment,
        handleRemoveAttachment,
        handleOpenScheduleDialog,
        handleToggleScheduleDialog,
        handleScheduleMessage,
        handleEndEditScheduledMessage,
        handleSetMessageUnread,
        handleUpdateInteraction,
        handleEndEditAiMessage,
        handleEditInteraction,
        handleSendMessageForAiDemo
    ] = useFacade(conversation, author);

    const classes = useStyles();
    const dropClasses = useDropStyles();
    const isScheduledMessageEditing = Boolean(scheduledMessageForEditing);
    const isAiMessageForEditing = Boolean(aiMessageForEditing);

    const messageGroups = conversation
        ? convertMessagesToGroupsForAiDemo(messagesViewForAi)
        : [];

    const fileTypes = [
        "application/pdf",
        'image/jpeg',
        'image/png',
    ];

    return (
        <Dropzone
            onDrop={handleDropAttachment}
            accept={fileTypes}
        >
            {
                ({ getRootProps, getInputProps, isDragActive }) => {
                    const rootProps = getRootProps();
                    const handleAttach = rootProps['onClick'];
                    delete rootProps['onClick']

                    const sendMessageSection = (
                        conversation?.state !== ConversationState.Closed ?
                            <>
                                <Divider className={classes.divider} orientation='horizontal' />

                                <Box className={classes.sendMessage} {...rootProps}>
                                    <Box className={classes.noneOutline} display={isScheduledMessageEditing ? 'block' : 'none'}>
                                        <EditScheduledMessage
                                            message={
                                                isScheduledMessageEditing ? scheduledMessageForEditing.message : ""
                                            }
                                            attachments={isScheduledMessageEditing ? scheduledAttachmentsForEditing : []}
                                            attachmentsNew={toSendAttachments}
                                            handleConfirm={(message, removeAttachmentIds, attachments) => handleOpenScheduleDialog(message, removeAttachmentIds, attachments)}
                                            handleDiscard={() => handleEndEditScheduledMessage()}
                                            handleRemoveAttachmentNew={handleRemoveAttachment}
                                        />
                                        <Box p={1}>
                                            <ActionButton id="send-message-attach" size='medium' onClick={handleAttach}>
                                                <AttachIcon />
                                            </ActionButton>
                                        </Box>
                                    </Box>
                                    <Box className={classes.noneOutline} display={isScheduledMessageEditing || isAiMessageForEditing ? 'none' : 'block'}>
                                        <SendMessageCompactComponent
                                            handleSubmit={(message) => handleSendMessageForAiDemo(message)}
                                            handleRemoveAttachment={handleRemoveAttachment}
                                            handleSchedule={(message) => handleOpenScheduleDialog(message)}
                                            inputProps={getInputProps()}
                                            handleAttach={handleAttach}
                                            attachments={toSendAttachments}
                                            emptyMessage={emptyMessage}
                                            handleTyping={() => { }}
                                            canSchedule
                                            canAttach
                                        />
                                    </Box>
                                    <Box
                                        className={classes.noneOutline}
                                        display={isAiMessageForEditing ? "block" : "none"}
                                    >
                                        <EditAiMessage
                                            message={
                                                isAiMessageForEditing ? detailForAiMessage : ""
                                            }
                                            handleConfirm={(message) => handleEditInteraction(message)}
                                            handleDiscard={() => handleEndEditAiMessage()}
                                        />
                                    </Box>
                                    <ScheduleMessageDialogComponent
                                        isOpen={isScheduleDialogOpen}
                                        handleToggle={() => handleToggleScheduleDialog()}
                                        handleSubmit={(date, time) => handleScheduleMessage(date, time)}
                                    />
                                </Box>
                            </> :
                            <Box className={classes.closedTicket}>
                                This ticket has been closed.
                            </Box>
                    )

                    return (
                        isDragActive ?
                            <div {...rootProps} className={dropClasses.dropContainer}>
                                <CloudUploadOutlined className={dropClasses.dropIcon} />
                                <span className={dropClasses.dropTitle}>Release to Upload</span>
                            </div> :
                            <>
                                <Box
                                    id='thread'
                                    className={clsx(classes.thread, 'flat-scroll')}
                                    height={340}
                                    {...rootProps}
                                >
                                    <ThreadComponentForAiDemo
                                        isLoadingMessages={isLoadingMessages}
                                        isAiLoading={isAiLoading}
                                        messageGroups={messageGroups}
                                        threadStartId={`thread-start-${conversation?.id}`}
                                        background={colors.bg}
                                        isCompact={true}
                                        handleSetMessageUnread = {handleSetMessageUnread}
                                        lastReadMessageIndex={lastReadMessageIndex}
                                        scrollToIndex={scrollToIndexForAi}
                                        handleUpdateInteraction={handleUpdateInteraction}
                                    />
                                </Box>
                                {sendMessageSection}
                            </>
                    )
                }
            }
        </Dropzone>
    )
}