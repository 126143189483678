import { Box, useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { WildHealthButton } from '../../common/components/wildHealthButton/WildHealthButton';
import { HealthRecommendationComponent } from "../components/healthRecommendationComponent/HealthRecommendationComponent";
import { RecommendationSingleSelectComponent } from '../components/recommendationSingleSelectComponent/RecommendationSingleSelectComponent';
import {
    HealthReportMode,
    HealthReportSections,
    HealthReportVersion,
    recommendationRowsCapacity, RecommendationTypesNumbers
} from "../models/healthReport.models";
import { HealthReportBasePage, HealthReportMobileBasePage } from "./HealthReportBasePage";
import { useStyles } from './healthReport.styles';
import { HealthReportPageProps } from "./healthReportPageProps";

export const HealthReportSupplementsPage: React.FC<HealthReportPageProps> = (props: HealthReportPageProps) => {
    const {
        report,
        handleChanges,
        handleSaveChanges,
        pageNumber,
        isChanged,
        mode,
        practice,
        recommendations,
        handleSelected,
        handleChangesNewRecommendation,
        handleAddRecommendation,
        handleSaveChangesNew,
    } = props;
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        props.mode !== HealthReportMode.PrintMode && isSmallScreen ? (
            <HealthReportMobileBasePage section={HealthReportSections.Details} pageNumber={pageNumber} practice={practice} firstPage>
                <Box px={2}>
                    <Box>
                        <Box>
                            <span className={classes.reportTitle}>Recommended Supplements</span>
                            <Box display="flex" justifyContent="flex-end" width={1}>
                                {
                                    parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation) && HealthReportMode.EditMode === mode && <WildHealthButton
                                        id="health-recommendation-new-save"
                                        onClick={() => handleSaveChangesNew()}
                                        disabled={!isChanged}>
                                        <Box >
                                            Save changes
                                        </Box>
                                    </WildHealthButton>
                                }
                            </Box>
                        </Box>

                        <Box mt="10px">
                            {parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
                                ? <Box>
                                    <RecommendationSingleSelectComponent
                                        currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.Supplements)}
                                        recommendation={report.overallReport.supplementsRecommendation}
                                        type={RecommendationTypesNumbers.Supplements}
                                        handleSelected={handleSelected}
                                        handleChanges={handleChangesNewRecommendation}
                                        handleAddRecommendation={handleAddRecommendation}
                                        mode={mode}
                                    />
                                </Box>
                                : <HealthRecommendationComponent
                                    mode={mode}
                                    rows={recommendationRowsCapacity[RecommendationTypesNumbers.Supplements]}
                                    recommendation={report.overallReport.supplementsRecommendation}
                                    handleContentChanges={!handleChanges ? handleChanges : (value) => {
                                        handleChanges('overallReport.supplementsRecommendation.content', value)
                                    }}
                                    handleManualContentChanges={!handleChanges ? handleChanges : (value) => {
                                        handleChanges('overallReport.supplementsRecommendation.manualContent', value)
                                    }}
                                    handleSaveChanges={handleSaveChanges}
                                    isChanged={isChanged} />
                            }
                        </Box>
                    </Box>
                </Box>
            </HealthReportMobileBasePage>
        ) : (
            <HealthReportBasePage section={HealthReportSections.Details} pageNumber={pageNumber} practice={practice}>
                <Box px={15} pt={5}>
                    <Box>
                        <Box display="flex" justifyContent="space-between">
                            <span className="report-title">Recommended Supplements</span>
                            {
                                parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation) && HealthReportMode.EditMode === mode && <WildHealthButton
                                    id="health-recommendation-new-save"
                                    onClick={() => handleSaveChangesNew()}
                                    disabled={!isChanged}>
                                    <Box >
                                        Save changes
                                    </Box>
                                </WildHealthButton>
                            }
                        </Box>

                        <Box mt={1}>
                            {parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
                                ? <Box>
                                    <RecommendationSingleSelectComponent
                                        currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.Supplements)}
                                        recommendation={report.overallReport.supplementsRecommendation}
                                        type={RecommendationTypesNumbers.Supplements}
                                        handleSelected={handleSelected}
                                        handleChanges={handleChangesNewRecommendation}
                                        handleAddRecommendation={handleAddRecommendation}
                                        mode={mode}
                                    />
                                </Box>
                                : <HealthRecommendationComponent
                                    mode={mode}
                                    rows={recommendationRowsCapacity[RecommendationTypesNumbers.Supplements]}
                                    recommendation={report.overallReport.supplementsRecommendation}
                                    handleContentChanges={!handleChanges ? handleChanges : (value) => {
                                        handleChanges('overallReport.supplementsRecommendation.content', value)
                                    }}
                                    handleManualContentChanges={!handleChanges ? handleChanges : (value) => {
                                        handleChanges('overallReport.supplementsRecommendation.manualContent', value)
                                    }}
                                    handleSaveChanges={handleSaveChanges}
                                    isChanged={isChanged} />
                            }
                        </Box>
                    </Box>
                </Box>
            </HealthReportBasePage>
        )
    )
}
