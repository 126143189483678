import React from 'react';
import {Box, Card, CardContent} from "@material-ui/core";
import paymentCouponIcon from '@img/paymentCouponIcon.png';
import commonUseStyles from '../../../common/styles/common.styles';

interface PaymentCouponAppliedComponentProps {
    paymentCoupon : string;
}

export const PaymentCouponAppliedComponent: React.FC<PaymentCouponAppliedComponentProps> = (props: PaymentCouponAppliedComponentProps) => {
    const { paymentCoupon } = props;
    const commonClasses = commonUseStyles();
    
    return (
        <>
            <Card>
                <CardContent>
                    <Box mt={2} display="flex" id="couponApplied" justifyContent="flex-start">
                        <Box>
                            <img style={{width: '50px'}} src={paymentCouponIcon} alt="img"/>
                        </Box>
                        <Box pl={3} className={commonClasses.primaryTitle}>
                            '{paymentCoupon}' has been applied!
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </>
    )
};