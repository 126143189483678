import {
  Box,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
  TextField,
  Collapse,
  Popover,
  IconButton
} from '@material-ui/core';
import React from 'react';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { useFacade } from './createPromoCodeComponent.hooks';
import { useStyles, DiscountAmountField, WildHealthSwitch } from "./createPromoCodeComponent.styles";
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from 'clsx';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import { WildHealthMenuItem } from '../../../common/components/wildHealthMenu/WildHealthMenu';
import { DiscountTypeNames, DiscountType } from "../../../payment/models/paymentCoupon.model";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckIcon from '@material-ui/icons/Check';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { WildHealthDatePicker } from '../../../common/components/WildHealthDatePicker';
import moment from 'moment';

export const CreatePromoCodeComponent: React.FC = () => {
  const [
    {
      isLoading,
      isSubmitted,
      isSpecific,
      isSeeMoreOption,
      params,
      activePaymentPlans,
      errors,
      menuAnchorEl
    },
    handleToggleMoreOptions,
    handleToggleDiscountType,
    handleToggleSpecificChange,
    handleChanges,
    handleCancel,
    handleCreate,
  ] = useFacade();

  const commonClasses = commonUseStyles();
  const classes = useStyles();

  if (isLoading) {
    return <WildHealthLinearProgress />
  }

  const getExpirationDate = () => {
    return params.expirationDate
      ? moment(params.expirationDate).format('YYYY-MM-DD')
      : null
  }

  return (
    <Box px="30px" pt="22px" pb="30px" className={commonClasses.bgWhiteMain}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box className={clsx(commonClasses.size20, commonClasses.textMedium, commonClasses.colorBlack)}>Create a promo code</Box>
        <Box display="flex" alignItems="center">
          <Box width="132px" mr="20px">
            <WildHealthButton
              id="cancel-button"
              disabled={isSubmitted || isLoading}
              onClick={() => handleCancel()}
              color="secondary"
              fullWidth
              style={{ height: 48 }}
            >
              <Box className={clsx(commonClasses.size16, commonClasses.textMedium)}>Cancel</Box>
            </WildHealthButton>
          </Box>
          <Box width="132px">
            <WildHealthButton
              id="create-promo-code-button"
              disabled={isSubmitted}
              loading={isSubmitted || isLoading}
              onClick={() => handleCreate()}
              fullWidth
              style={{ height: 48 }}
            >
              <Box className={clsx(commonClasses.size16, commonClasses.textMedium)}>Create</Box>
            </WildHealthButton>
          </Box>
        </Box>
      </Box>
      <Box width={1} mt={2}>
        <Box display="flex">
          <Box width={0.3} pr="20px">
            <Box className={classes.label} mb={1}>Promo Code*</Box>
            <TextField
              fullWidth
              size="small"
              required
              InputProps={{className: 'input', classes}}
              id="code"
              placeholder="e.g. WILD20"
              value={params.code}
              variant="outlined"
              disabled={isSubmitted}
              error={!!errors['code']}
              helperText={errors['code']}
              onChange={(v) => handleChanges(v.target.id, v.target.value)}
            />
          </Box>
          <Box width={0.3} pr="20px">
            <Box className={classes.label} mb={1}>Description (optional)</Box>
            <TextField
              fullWidth
              size="small"
              InputProps={{className: 'input', classes}}
              id="description"
              placeholder=""
              value={params.description}
              variant="outlined"
              disabled={isSubmitted}
              onChange={(v) => handleChanges(v.target.id, v.target.value)}
            />
          </Box>
          <Box width={0.2} pr="20px">
            <Box className={classes.label} mb={1}>Discount Amount*</Box>
            <DiscountAmountField
              fullWidth
              size="small"
              id="discount"
              placeholder="e.g. 15"
              value={params.discount}
              variant="outlined"
              disabled={isSubmitted}
              error={!!errors['discount']}
              helperText={errors['discount']}
              onChange={(v) => handleChanges(v.target.id, v.target.value)}
              InputProps={{
                inputProps: {
                  className: classes.searchBase,
                },
                startAdornment: <Box display="flex" alignItems="center" className={clsx(commonClasses.size16, commonClasses.textMedium)}>
                  {DiscountTypeNames.get(params.discountType)}
                  <IconButton
                    id="scheduled-messages-more-actions"
                    onClick={(e) => handleToggleDiscountType(e.currentTarget)}
                    size='small'
                  >
                    {Boolean(menuAnchorEl) ? <ArrowDropUpIcon className={classes.icon} /> : <ArrowDropDownIcon className={classes.icon} />}
                  </IconButton>
                </Box>,
              }}
            />
          </Box>
          <Box width={0.2}>
            <Box className={classes.label} mb={1}>Expiration date</Box>
            <WildHealthDatePicker
              id={`expirationDate`}
              minDate={new Date()}
              fullWidth
              size="small"
              disabled={isSubmitted}
              placeholder="MM/DD/YYYY"
              openTo="year"
              format="MM/DD/yyyy"
              value={getExpirationDate()}
              error={!!errors[`expirationDate`]}
              helperText={errors[`expirationDate`]}
              inputVariant="outlined"
              views={["year", "month", "date"]}
              onChange={(momentDate) =>
                handleChanges(`expirationDate`, momentDate.format("YYYY-MM-DD")
              )}
            />
          </Box>
        </Box>
        <Box mt={2}>
          <Box display="flex" alignItems="center">
            <Box className={classes.label} mr={2}>Apply to specific products</Box>
            <Box>
              <WildHealthSwitch
                checked={isSpecific}
                onChange={handleToggleSpecificChange}
              />
            </Box>
          </Box>
        </Box>
        {isSpecific && (
          <Box mt={2} width={0.35} pr="20px">
            <FormControl
              variant="outlined"
              color='primary'
              required
              error={!!errors[`paymentPlan`]}
              size="small"
              disabled={!isSpecific || isLoading}
              classes={{ root: classes.root }}
              fullWidth>
              <Select
                style={{ background: '#FFF' }}
                classes={{ root: classes.root }}
                value={params.paymentPlans}
                error={!!errors[`paymentPlans`]}
                inputProps={{
                  name: 'Integration vendor',
                  id: 'vendor-label',
                  classes,
                }}
                renderValue={() => {
                  if (!params.paymentPlans || !params.paymentPlans.length) {
                    return <Box className={commonClasses.colorMediumGray}>Find a product</Box>;
                  }

                  return params.paymentPlans.length === 1 ? `${params.paymentPlans.length} plan selected` : `${params.paymentPlans.length} plans selected`
                }}
                displayEmpty
                onChange={(v) => handleChanges("paymentPlans", v.target.value)}
              >
                {activePaymentPlans?.map(plan => (
                  <MenuItem key={`${plan.id}-${plan.name}`} value={plan.id}>
                    <Box display="flex" alignItems="center" justifyContent="space-between" width={1}>
                      <Box className={clsx(commonClasses.size14, commonClasses.textMedium, {
                        [commonClasses.colorMain]: params.paymentPlans.includes(plan.id),
                        [commonClasses.colorBlack]: !params.paymentPlans.includes(plan.id),
                      })}>{plan.displayName}</Box>
                      {params.paymentPlans.includes(plan.id) && <CheckIcon className={classes.activeIcon} />}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error>{errors[`paymentPlans`]}</FormHelperText>
            </FormControl>
          </Box>
        )}
        <Box mt={2}>
          <Box id="see-more-option" className={classes.seeMoreBtn} onClick={handleToggleMoreOptions}>
            <Box className={clsx(commonClasses.colorMain, commonClasses.textMedium)} mr={2}>
              See more options
            </Box>
            {isSeeMoreOption ? <ExpandLessIcon className={classes.activeIcon}/> : <ExpandMoreIcon className={classes.activeIcon}/>}
          </Box>
          <Collapse in={isSeeMoreOption} timeout="auto" unmountOnExit>
            <Box mt={2} display="flex" alignItems="center">
              <Box display="flex" alignItems="center" mr="60px">
                <Box className={classes.label} mr={2}>Discount startup fee</Box>
                <Box>
                  <WildHealthSwitch
                    checked={params.isDiscountStartupFee}
                    onChange={(e) => handleChanges("isDiscountStartupFee", e.target.checked)}
                  />
                </Box>
              </Box>
              <Box display="flex" alignItems="center" mr="60px">
                <Box className={classes.label} mr={2}>Discount labs</Box>
                <Box>
                  <WildHealthSwitch
                    checked={params.isDiscountLabs}
                    onChange={(e) => handleChanges("isDiscountLabs", e.target.checked)}
                  />
                </Box>
              </Box>
              <Box display="flex" alignItems="center">
                <Box className={classes.label} mr={2}>Applies to insurance</Box>
                <Box>
                  <WildHealthSwitch
                    checked={params.isAppliedForInsurance}
                    onChange={(e) => handleChanges("isAppliedForInsurance", e.target.checked)}
                  />
                </Box>
              </Box>
            </Box>
          </Collapse>
        </Box>
      </Box>
      
      <Popover
          keepMounted
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={() => handleToggleDiscountType(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <WildHealthMenuItem id="discount-type-percent" className={clsx(commonClasses.size16, commonClasses.textMedium)} onClick={() => handleChanges('discountType', DiscountType.Percentage)}>{DiscountTypeNames.get(DiscountType.Percentage)}</WildHealthMenuItem>
          <WildHealthMenuItem id="discount-type-nominal" className={clsx(commonClasses.size16, commonClasses.textMedium)} onClick={() => handleChanges('discountType', DiscountType.Nominal)}>{DiscountTypeNames.get(DiscountType.Nominal)}</WildHealthMenuItem>
        </Popover>
    </Box>
  )
}