import React from "react";
import {
  Box,
  createStyles,
  Dialog,
  DialogContent,
  IconButton,
  Theme,
  Typography,
  WithStyles,
  withStyles,
  useTheme,
  useMediaQuery,
  Avatar,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import { ReactComponent as CalendarIcon } from "@img/icons/CalendarIcon.svg";
import { useStyles } from "./CreatePatientAppointmentDialogComponent.styles";
import { useFacade } from "./createPatientAppointmentDialogComponent.hook";
import { CreatePatientAppointmentFormComponent } from "../createPatinentAppointmentForm/CreatePatientAppointmentFormComponent";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { LocationModel } from "../../../locations/models/locations.models";
import { EmployeeModel } from "../../../employee/models/employee.models";
import { AppointmentTypeModel } from "../../models/appointments.models";
import { AppointmentWithType } from "../../models/appointments.enums";
import commonUseStyles from "../../../common/styles/common.styles";
import { TimeZoneModel } from "../../../timezones/models/timezone.model";
import { getTargetAppointmentType } from "../../helpers/appointmentHelper";
import { displayShortName } from "../../../conversations/helpers/messages.converter";
import { EmployeeType, employeeTypesNames } from "../../../employee/models/employee.enums";
import { profileQuery } from "../../../account/stores/profileStore";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;

  const commonClasses = commonUseStyles();

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography className={clsx(commonClasses.size24, commonClasses.textMedium)}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          id="create-patient-appointment-close"
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

interface ScheduleAppointmentComponentProps {
  assignedEmployees: EmployeeModel[];
  appointmentWithType: AppointmentWithType;
  appointmentTypes: AppointmentTypeModel[];
  locations: LocationModel[];
  timeZone: TimeZoneModel;
  patientId?: number | null;
  disableSchedule?: boolean;
  position: EmployeeType;
  hasFollowUp?: boolean;
  forScheduleToReview?: boolean;
}

export const CreatePatientAppointmentDialogRedesignComponent: React.FC<
  ScheduleAppointmentComponentProps
> = (props: ScheduleAppointmentComponentProps) => {
  const {
    assignedEmployees,
    appointmentWithType,
    appointmentTypes,
    locations,
    timeZone,
    patientId = null,
    disableSchedule = false,
    position,
    forScheduleToReview = false
  } = props;

  const [state, handleToggleDialog, handleGoToConversations, handleContinueDialog] = useFacade();

  const commonClasses = commonUseStyles();
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const targetAppointmentType = getTargetAppointmentType(appointmentTypes, appointmentWithType);
  const employee = assignedEmployees?.find(el => el.role.displayName === employeeTypesNames.get(position))

  const scheduleAppointmentButton = () => {
    return <Box minWidth={!forScheduleToReview && '230px'} className={classes.buttonSection}>
      <WildHealthButton
        disabled={disableSchedule || !employee}
        id="create-schedule-appointment"
        onClick={handleToggleDialog}
        fullWidth
        color="primary"
        size="medium"
      >
        <CalendarIcon />
        <Box ml={2} className="wh-tw-text-sm wh-tw-font-medium">{forScheduleToReview ? 'Schedule to Review' : state.toContinueDialog ? 'Appointment Confirmation' : 'Schedule Appointment'}</Box>
      </WildHealthButton>
    </Box>
  };
  return (
    <>
      {forScheduleToReview ? scheduleAppointmentButton() : (
        <Box>
          <span className="wh-tw-text-sm wh-tw-text-black wh-tw-font-medium">{targetAppointmentType?.dashboardMessageTitle}</span>
          <span className="wh-tw-text-sm wh-tw-text-black">{targetAppointmentType?.dashboardMessageDescription}</span>
          <Box className={classes.scheduleAppointment}>
            {employee && <Box className={classes.employeeSection}>
              <Box>
                <Avatar src={profileQuery.getPhoto(employee.id)} className="wh-tw-bg-navy800">
                  {displayShortName(employee.firstName, employee.lastName)}
                </Avatar>
              </Box>
              <Box pl={1}>
                <Box className="wh-tw-font-medium wh-tw-text-black">
                  {employee.firstName} {employee.lastName}
                </Box>
                <Box className="wh-tw-text-sm wh-tw-text-gray600">
                  {employee.role.displayName}
                </Box>
              </Box>
            </Box>}
            {scheduleAppointmentButton()}
          </Box>
        </Box>
      )}

      <Dialog
        fullScreen={isSmallScreen}
        maxWidth="md"
        open={state.toShowDialog}
        className={classes.dialog}
        onClose={handleToggleDialog}>
        {
          !isSmallScreen &&
          <DialogTitle id="customized-dialog-title" onClose={handleToggleDialog}>
            <span className={clsx(commonClasses.size24, commonClasses.textMedium, classes.dialogTitle)}>
              {state.toContinueDialog ? "Appointment Confirmation" : "Schedule Appointment"}
            </span>
          </DialogTitle>
        }
        <DialogContent className={classes.dialogContent}>
          <Box width={isSmallScreen ? 1 : "548px"}>
            <CreatePatientAppointmentFormComponent
              isContinueDialog={state.toContinueDialog}
              handleContinueDialog={handleContinueDialog}
              toggleDialog={handleToggleDialog}
              assignedEmployees={assignedEmployees}
              appointmentTypes={appointmentTypes}
              locations={locations}
              appointmentWithType={appointmentWithType}
              handleCloseDialog={handleToggleDialog}
              timeZone={timeZone}
              patientId={patientId}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
