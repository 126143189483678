import {GoalCategory} from "../../../../notes/models/notes.models";
import DietImage from '@img/DietGoal.png';
import ExerciseImage from '@img/ExerciseGoal.png';
import MindfulnessImage from '@img/MindfulnessGoal.png';
import LongevityImage from '@img/LongevityGoal.png';
import NeuroImage from '@img/NeuroGoal.png';
import SleepImage from '@img/SleepGoal.png';
import OtherImage from '@img/OtherGoal.png';
import DietFullImage from '@img/DietGoalFull.png';
import ExerciseFullImage from '@img/ExerciseGoalFull.png';
import MindfulnessFullImage from '@img/MindfulnessGoalFull.png';
import LongevityFullImage from '@img/LongevityGoalFull.png';
import NeuroFullImage from '@img/NeuroGoalFull.png';
import SleepFullImage from '@img/SleepGoalFull.png';
import OtherFullImage from '@img/OtherGoalFull.png';

export const healthGoalImages = new Map<GoalCategory, any>()
    .set(GoalCategory.Diet, DietImage)
    .set(GoalCategory.Exercise, ExerciseImage)
    .set(GoalCategory.Mindfulness, MindfulnessImage)
    .set(GoalCategory.Longevity, LongevityImage)
    .set(GoalCategory.Neuro, NeuroImage)
    .set(GoalCategory.Sleep, SleepImage)
    .set(GoalCategory.Other, OtherImage);

export const healthGoalFullImages = new Map<GoalCategory, any>()
    .set(GoalCategory.Diet, DietFullImage)
    .set(GoalCategory.Exercise, ExerciseFullImage)
    .set(GoalCategory.Mindfulness, MindfulnessFullImage)
    .set(GoalCategory.Longevity, LongevityFullImage)
    .set(GoalCategory.Neuro, NeuroFullImage)
    .set(GoalCategory.Sleep, SleepFullImage)
    .set(GoalCategory.Other, OtherFullImage)