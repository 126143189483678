export enum EmployeeType
{
    Unspecified = 0,
    HealthCoach = 1,
    Provider = 2,
    Staff = 3,
    RegistrationManager = 4,
    VirtualAssistant = 5
}

export const employeeTypesNames = new Map<EmployeeType, string>([
    [EmployeeType.Provider, 'Provider'],
    [EmployeeType.HealthCoach, 'Health Coach'],
    [EmployeeType.Staff, 'Staff'],
    [EmployeeType.RegistrationManager, 'Registration Manager'],
    [EmployeeType.VirtualAssistant, 'Virtual Assistant']
]);

export const employeeTypesNamesForAppointment = new Map<EmployeeType, string>([
    [EmployeeType.Provider, 'Physician'],
    [EmployeeType.HealthCoach, 'Health Coach'],
    [EmployeeType.Staff, 'Staff'],
    [EmployeeType.RegistrationManager, 'Registration Manager']
]);