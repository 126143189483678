import { useEffect, useState } from "react";
import { Subscription, timer } from 'rxjs';

/**
 * Custom Hook to manage a view Model for Notifications component
 */
export function useFacade(onClick: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void, timeout: number): [boolean, (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void] {
    const [canExecute, setCanExecute] = useState(true);


    const handleOnClick = (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setCanExecute(() => false);
        onClick(event);
    }

    const useEffectCB = () => {
        const timerSubscription = timer(timeout).subscribe(() => {
            setCanExecute(() => true);
            timerSubscription.unsubscribe();
        });

        const subscriptions: Subscription[] = [timerSubscription];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    };

    useEffect(useEffectCB, [canExecute, timeout]);

    return [canExecute, handleOnClick];
}