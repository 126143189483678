import { ChangeEvent, useEffect, useState } from "react";
import moment from "moment";
import { pageSizes } from "../../../common/pagination/models/page-sizes";
import { IPaginationState } from "../../../common/pagination/models/pagination-state";
import { getAvailablePages } from "../../../common/pagination/helpers/get-evailable-pages";
import { PlatformName, Track } from "../../../tracking/tracker";
import { CreateMessageModel, MessageTypeDeliveryModel, nameMessageType, MessageType, AdminPanelMessageModel } from "../../models/message.models";
import { conversationsService } from "../../services/conversations.service";
import { Subscription } from "rxjs/internal/Subscription";
import { onEmit } from "../../../common/helpers/on-emit";
import { employeeConversationsQuery } from "../../stores/employeeConversationsStore/employeeConversations.query";

interface FilterOptionsViewModel {
  optionId: number;
  title: string;
  status: string | number;
  description?: string;
  value: number;
  valueString: string;
}

interface PatientFilterStatusViewModel {
  id: string;
  objectName: string;
  title: string;
  description?: string;
  options: FilterOptionsViewModel[];
}

interface MyPatientsFiltersState {
  isOpen: boolean;
  anchorEl: HTMLButtonElement;
  filters: PatientFilterStatusViewModel[];
}

interface ManageMessagingToolsState extends IPaginationState {
  isLoading: boolean;
  isSendingMessage: boolean;
  messages: AdminPanelMessageModel[];
  filtersState: MyPatientsFiltersState;
  filteredTitle: string;
  page: number;
  rowsPerPage: number;
  options: FilterOptionsViewModel[];
  selectedFilterId: string;
  selectedOptionId: number;
  selectedFilter: PatientFilterStatusViewModel;
  selectedOption: FilterOptionsViewModel;
  title: string;
  description: string;
  selectedMethods: MessageTypeDeliveryModel[];
  methodsOfDelivery: MessageTypeDeliveryModel[];
  eligiblePatients: number;
  searchQuery: string;
}

export function useFacade(): [
  ManageMessagingToolsState,
  (event?: React.MouseEvent<HTMLButtonElement>) => void,
  () => void,
  (query: string) => void,
  () => number[],
  (value: any) => void,
  (page: number) => void,
  (filterId: string) => void,
  (optionId: number) => void,
  (event: ChangeEvent<HTMLTextAreaElement>) => void,
  (event: ChangeEvent<HTMLTextAreaElement>) => void,
  () => void,
  () => void,
  (value: any) => void,
] {

  const [state, setState] = useState({
    isLoading: true,
    isSendingMessage: false,
    messages: [],
    filtersState: {
      isOpen: false,
      anchorEl: null,
      filters: [
        {
          id: "LastMessageSentGreaterThanDaysAgo",
          objectName: "lastMessage",
          title: "Last Message Sent",
          description: "since last message sent",
          options: [
            {
              optionId: 1,
              title: "> 10 days ago",
              description: "> 10 days",
              status: moment(new Date()).add(-10, 'day').format('MM/DD/YYYY'),
              value: 10
            },
            {
              optionId: 2,
              title: "> 20 days ago",
              description: "> 20 days",
              status: moment(new Date()).add(-20, 'day').format('MM/DD/YYYY'),
              value: 20
            },
            {
              optionId: 3,
              title: "> 30 days ago",
              description: "> 30 days",
              status: moment(new Date()).add(-30, 'day').format('MM/DD/YYYY'),
              value: 30
            },
            {
              optionId: 4,
              title: "> 60 days ago",
              description: "> 60 days",
              status: moment(new Date()).add(-60, 'day').format('MM/DD/YYYY'),
              value: 60
            }
          ]
        },
        {
          id: "LastCoachingVisitGreaterThanDaysAgo",
          objectName: "lastAppointmentCompletedHealthCoachDate",
          title: "Last Coaching Visit",
          description: "since last Coaching Visit (of any kind)",
          options: [
            {
              optionId: 1,
              title: "> 10 days ago",
              description: "> 10 days",
              status: moment(new Date()).add(-10, 'day').format('MM/DD/YYYY'),
              value: 10
            },
            {
              optionId: 2,
              title: "> 20 days ago",
              description: "> 20 days",
              status: moment(new Date()).add(-20, 'day').format('MM/DD/YYYY'),
              value: 20
            },
            {
              optionId: 3,
              title: "> 30 days ago",
              description: "> 30 days",
              status: moment(new Date()).add(-30, 'day').format('MM/DD/YYYY'),
              value: 30
            },
            {
              optionId: 4,
              title: "> 60 days ago",
              description: "> 60 days",
              status: moment(new Date()).add(-60, 'day').format('MM/DD/YYYY'),
              value: 60
            }
          ]
        },
        {
          id: "LastAppointmentGreaterThanDaysAgo",
          objectName: "lastAppointment",
          title: "Last Appointment (any type)",
          description: "since last appointment (of any type)",
          options: [
            {
              optionId: 1,
              title: "> 10 days ago",
              description: "> 10 days",
              status: moment(new Date()).add(-10, 'day').format('MM/DD/YYYY'),
              value: 10
            },
            {
              optionId: 2,
              title: "> 20 days ago",
              description: "> 20 days",
              status: moment(new Date()).add(-20, 'day').format('MM/DD/YYYY'),
              value: 20
            },
            {
              optionId: 3,
              title: "> 30 days ago",
              description: "> 30 days",
              status: moment(new Date()).add(-30, 'day').format('MM/DD/YYYY'),
              value: 30
            },
            {
              optionId: 4,
              title: "> 60 days ago",
              description: "> 60 days",
              status: moment(new Date()).add(-60, 'day').format('MM/DD/YYYY'),
              value: 60
            }
          ]
        },
        {
          id: "PlanRenewalDateLessThanDaysFromToday",
          objectName: "activePlan",
          title: "Plan Renewal Date",
          description: "until patients plan is due a renewal",
          options: [
            {
              optionId: 1,
              title: "< 30 days from today",
              description: "< 30 days",
              status: moment(new Date()).add(30, 'day').format('MM/DD/YYYY'),
              value: 30
            },
            {
              optionId: 2,
              title: "< 60 days from today",
              description: "< 60 days",
              status: moment(new Date()).add(60, 'day').format('MM/DD/YYYY'),
              value: 60
            }
          ]
        },
        {
          id: "DaysSinceIccWithoutImcScheduledFromToday",
          objectName: "daysSinceHealthCoachAndNoImcScheduled",
          title: "Days since ICC without IMC Scheduled",
          description: "since ICC without an IMC scheduled",
          options: [
            {
              optionId: 1,
              title: "> 30 days",
              description: "> 30 days",
              status: 30,
              value: 30
            },
            {
              optionId: 2,
              title: "> 45 days",
              description: "> 45 days",
              status: 45,
              value: 45
            },
            {
              optionId: 3,
              title: "> 60 days",
              description: "> 60 days",
              status: 60,
              value: 60
            }
          ]
        },
        {
          id: "DaysSinceSignUpWithoutIccScheduledFromToday",
          objectName: "daysSinceSignUpAndNoIccScheduled",
          title: "Days since Sign Up without ICC scheduled",
          description: "since signup without an ICC schedule",
          options: [
            {
              optionId: 1,
              title: "> 5 days",
              description: "> 5 days",
              status: 5,
              value: 5
            },
            {
              optionId: 2,
              title: "> 10 days",
              description: "> 10 days",
              status: 10,
              value: 10
            },
            {
              optionId: 3,
              title: "> 20 days",
              description: "> 20 days",
              status: 20,
              value: 20
            },
            {
              optionId: 4,
              title: "> 30 days",
              description: "> 30 days",
              status: 30,
              value: 30
            }
          ]
        },
        {
          id: "IncludesTags",
          objectName: "tags",
          title: "Tags",
          description: "tag",
          options: []
        },
        {
          id: "PlanName",
          objectName: "activePlan",
          title: "Plans",
          description: "Plan",
          options: []
        }
      ]
    },
    filteredTitle: '',
    page: 0,
    rowsPerPage: pageSizes[0],
    totalCount: 0,
    selectedPage: 1,
    pageSize: pageSizes[0],
    options: [],
    selectedFilterId: null,
    selectedOptionId: null,
    selectedFilter: null,
    selectedOption: null,
    title: '',
    description: '',
    methodsOfDelivery: [
      {
        id: MessageType.Email,
        name: nameMessageType.get(MessageType.Email),
      },
      {
        id: MessageType.Sms,
        name: nameMessageType.get(MessageType.Sms),
      },
      {
        id: MessageType.ClarityMessage,
        name: nameMessageType.get(MessageType.ClarityMessage),
      },
      {
        id: MessageType.MobileMessage,
        name: nameMessageType.get(MessageType.MobileMessage),
      },
    ],
    selectedMethods: [],
    eligiblePatients: null,
    searchQuery: '',
  } as ManageMessagingToolsState);


  const handleToggleFilters = (event?: React.MouseEvent<HTMLButtonElement>) => {
    const anchorEl = event && event.currentTarget ? event.currentTarget : null;
    state.filtersState.isOpen = !state.filtersState.isOpen;
    state.filtersState.anchorEl = anchorEl;
    setState(state => ({ ...state }));
  }


  const handleApplyFilters = () => {
    let selectedOpt: any = state.selectedOption?.value

    if (state.selectedFilter?.id === "PlanName") {
      selectedOpt = state.selectedOption.valueString
    }

    if (state.selectedFilter?.id === "IncludesTags") {
      selectedOpt = [state.selectedOption.valueString]
    }

    conversationsService.getFilteredPatients(state.selectedFilter?.id, selectedOpt).subscribe(
      (count) => setState(state => ({ ...state, eligiblePatients: count }))
    )
    handleToggleFilters();
  }

  const handleFilterSelect = (filterId: string) => {
    const selectedFilter = state.filtersState.filters.find(filter => filter.id === filterId);
    if (selectedFilter) {
      setState({
        ...state,
        selectedFilterId: filterId,
        selectedFilter: selectedFilter,
        options: selectedFilter.options,
        selectedOptionId: null,
        selectedOption: null,
      })
    }
  }

  const handleOptionSelect = (optionId: number) => {
    const selectedOption = state.options.find(o => o.optionId === optionId);
    if (selectedOption) {
      state.filteredTitle = [selectedOption.description, state.selectedFilter.description].join(" ").trim();
      setState({
        ...state,
        selectedPage: 1,
        selectedOptionId: optionId,
        selectedOption: selectedOption,
      })
    }
  }

  const getAllAvailablePages = () => {
    return getAvailablePages(state);
  }

  const handlePageSizeChange = (value: any) => {
    if (value === state.pageSize) {
      return;
    }
    const cb = () => setState(state => ({ ...state, isLoading: false }));
    setState(state => ({
      ...state,
      pageSize: value,
      selectedPage: 1,
      isLoading: true,
    }));
    conversationsService.getAll(0, value, state.searchQuery).subscribe(cb, cb)
  }

  const handlePageChange = (page: number) => {
    if (page === state.selectedPage) {
      return;
    }
    const cb = () => setState(state => ({ ...state, isLoading: false }));
    setState(state => ({
      ...state,
      selectedPage: page,
      isLoading: true
    }));
    conversationsService.getAll(page - 1, state.pageSize, state.searchQuery).subscribe(cb, cb)
  }

  const handleTitleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    let value = event.currentTarget.value;
    if (value.length > 39) {
      value = event.currentTarget.value.slice(0, 39)
    }
    setState(state => ({ ...state, title: value }));
  }

  const handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    let value = event.currentTarget.value;
    if (value.length > 160) {
      value = event.currentTarget.value.slice(0, 160)
    }
    setState(state => ({ ...state, description: value }));
  }

  const handleCancel = () => {
    setState(state => ({
      ...state,
      title: '',
      description: '',
      selectedOption: null,
      selectedFilterId: null,
      selectedOptionId: null,
      selectedMethods: [],
      filteredTitle: '',
      eligiblePatients: null,
    }))
  }

  const handleClearFilters = () => {
    setState(state => ({
      ...state,
      selectedOption: null,
      selectedFilterId: null,
      selectedOptionId: null,
      filteredTitle: '',
      eligiblePatients: null,
    }))
  }


  const handleMethodsChange = (value) => {
    setState(state => ({
      ...state,
      selectedMethods: value
    }));
  }

  const handleSendMessage = () => {
    setState(state => ({
      ...state,
      isSendingMessage: true
    }));
    const model: CreateMessageModel = {
      subject: state.title,
      body: state.description,
      types: state.selectedMethods.map(el => el.id),
      patientsFilterModel: {
      },
    };
    let selectedOpt: any = state.selectedOption?.value

    if (state.selectedFilter?.id === "PlanName") {
      selectedOpt = state.selectedOption.valueString
    }

    if (state.selectedFilter?.id === "IncludesTags") {
      selectedOpt = [state.selectedOption.valueString]
    }

    if (!state.selectedOption) {
      model.patientsFilterModel['employeeId'] = 1 // filter is required, for sending to all patients without filtering
    } else {
      model.patientsFilterModel[state.selectedFilterId] = selectedOpt
    }

    conversationsService.createMessage(model).subscribe(
      () => {
        setState(state => ({
          ...state,
          isSendingMessage: false,
        }))
        handleCancel();
        conversationsService.getAll(state.selectedPage - 1, state.pageSize, state.searchQuery).subscribe()
      },
      () => {
        setState(state => ({
          ...state,
          isSendingMessage: false
        }));
      }
    )
  }

  const handleSearchSubmit = (query = '') => {

    setState(state => ({...state, isLoading: true, searchQuery: query}));
    const cb = () => setState(state => ({ ...state, isLoading: false }));

    conversationsService.getAll(0, state.pageSize, query).subscribe(cb, cb)
  }

  useEffect(() => {
    if (state.selectedOption) {
      handleApplyFilters();

      Track("my_patients_filter_applied", {
        filter_type: state.selectedFilter.title,
        filter: state.selectedOption.description,
        platform: PlatformName()
      })
    }
  }, [state.selectedOption])

  const useEffectCB = () => {
    const subscriptions: Subscription[] = [
      onEmit<AdminPanelMessageModel[]>(employeeConversationsQuery.messagesAdminTools$, messagesAdminTools => {
          setState(state => ({
            ...state,
            messages: messagesAdminTools,
          }));
      }),
      onEmit<number>(employeeConversationsQuery.messagesTotalCount$, messagesTotalCount => {
        setState(state => ({
          ...state,
          totalCount: messagesTotalCount,
        }));
    }),
    ];

    const cb = () => setState(state => ({ ...state, isLoading: false }));

    conversationsService.getPlansAndTags().subscribe(
      (resp) => {
        if (resp.tags && resp.tags.length) {
          const options = resp.tags.map((tag, index) => {
            const option = {} as FilterOptionsViewModel;
            option['optionId'] = index + 1;
            option['status'] = option['title'] = option['description'] = option['value'] = option['valueString'] = tag;
            return option;
          })
          state.filtersState.filters.forEach(filter => {
            if (filter.id === "IncludesTags") {
              filter.options = options;
            }
          });
        }

        if (resp.paymentPlans && resp.paymentPlans.length) {
          const options = resp.paymentPlans.map((planName, index) => {
            const option = {} as FilterOptionsViewModel;
            option['optionId'] = index + 1;
            option['status'] = option['title'] = option['description'] = option['value'] = option['valueString'] = planName;
            return option;
          })
          state.filtersState.filters.forEach(filter => {
            if (filter.id === "PlanName") {
              filter.options = options;
            }
          });
        }

      }
    )
    conversationsService.getAll(state.selectedPage - 1, state.pageSize, '').subscribe(cb, cb)
    return () => {
      subscriptions.map(it => it.unsubscribe())
    };

  }

  useEffect(useEffectCB, []);

  return [
    state,
    handleToggleFilters,
    handleClearFilters,
    handleSearchSubmit,
    getAllAvailablePages,
    handlePageSizeChange,
    handlePageChange,
    handleFilterSelect,
    handleOptionSelect,
    handleTitleChange,
    handleDescriptionChange,
    handleCancel,
    handleSendMessage,
    handleMethodsChange,
  ];
}