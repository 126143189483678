import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Box } from '@material-ui/core';
import React, { useState, useEffect, useCallback } from "react";
import commonUseStyles from '../../../common/styles/common.styles';
import { ReactComponent as LockIcon } from '@img/icons/lock.svg';
import { ReactComponent as Lock } from '@img/icons/LockIcon.svg';
import { useStyles } from "./paymentCardComponent.style";
import clsx from 'clsx';

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: '#000',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#B9B9B9'
            }
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a'
        }
    }
};

interface PaymentCardComponentProps {
    handleTokenChange: (paymentMethod: any) => void,
    isCardExist: boolean,
    cardSaved: boolean,
}

export const PaymentCardTestedComponent: React.FC<PaymentCardComponentProps> = (props: PaymentCardComponentProps) => {
    const {
        handleTokenChange,
        isCardExist,
        cardSaved,
    } = props;

    const stripe = useStripe();
    const elements = useElements();
    const commonClasses = commonUseStyles();
    const classes = useStyles();

    const [state, setState] = useState({
        error: null,
        isSavedCardData: cardSaved,
    });

    const handleChange = useCallback(async (event) => {
        if (!stripe || !elements) {
            return;
        }

        if (event.error) {
            setState(state => ({ ...state, error: event.error.message, isSavedCardData: false }));
            handleTokenChange(null)
        } else {
            const card = elements.getElement(CardElement);
            const paymentMethod = await stripe.createPaymentMethod({ type: "card", card: card });

            if (paymentMethod.error) {
                setState(state => ({ ...state, error: paymentMethod.error.message }));
                handleTokenChange(null)
            } else {
                setState(state => ({ ...state, error: null }));
                handleTokenChange(paymentMethod)
                setState(state => ({ ...state, error: null }));
            }
        }
    }, [stripe, elements, handleTokenChange])

    useEffect(() => {
        !isCardExist && handleChange(false)
    }, [isCardExist, handleChange]);

    return (
        <div className="form-row">
            {state.isSavedCardData && <Box className={classes.notification}>Card details are already saved.</Box>}
            <Box className={commonClasses.label} mb={1}>Card Number*</Box>
            <CardElement
                id="card-element"
                options={CARD_ELEMENT_OPTIONS}
                onChange={(event) => handleChange(event)}
                className={commonClasses.textField}
            />
            <div className="card-errors" role="alert">{state.error}</div>
            <Box mt={3} display="flex" alignItems="center">
                <LockIcon />
                <Box pl={1} className={clsx(commonClasses.size12, commonClasses.textRegular, commonClasses.colorDarkGray)}>Secured with 256-bit TLS encryption</Box>
            </Box>
        </div>
    );
}

export const PaymentCardComponent: React.FC<PaymentCardComponentProps> = (props: PaymentCardComponentProps) => {
    const {
        handleTokenChange,
        isCardExist,
        cardSaved,
    } = props;

    const stripe = useStripe();
    const elements = useElements();
    const commonClasses = commonUseStyles();
    const classes = useStyles();

    const [state, setState] = useState({
        error: null,
        isSavedCardData: cardSaved,
    });

    const handleChange = useCallback(async (event) => {
        if (!stripe || !elements) {
            return;
        }

        if (event.error) {
            setState(state => ({ ...state, error: event.error.message, isSavedCardData: false }));
            handleTokenChange(null)
        } else {
            const card = elements.getElement(CardElement);
            const paymentMethod = await stripe.createPaymentMethod({ type: "card", card: card });

            if (paymentMethod.error) {
                setState(state => ({ ...state, error: paymentMethod.error.message }));
                handleTokenChange(null)
            } else {
                setState(state => ({ ...state, error: null }));
                handleTokenChange(paymentMethod)
                setState(state => ({ ...state, error: null }));
            }
        }
    }, [stripe, elements, handleTokenChange])

    useEffect(() => {
        !isCardExist && handleChange(false)
    }, [isCardExist, handleChange]);

    return (
        <div className="form-row">
            {state.isSavedCardData && <Box className={classes.notification}>Card details are already saved.</Box>}
            <Box mb={1}>
                <span className={classes.label}>Credit card number <span className={commonClasses.colorMint}>*</span></span>
            </Box>
            <CardElement
                id="card-element"
                options={CARD_ELEMENT_OPTIONS}
                onChange={(event) => handleChange(event)}
                className={classes.textField}
            />
            <div className="card-errors" role="alert">{state.error}</div>
            <Box mt={1.5} display="flex" alignItems="center">
                <Lock />
                <Box pl={1} className={clsx(classes.label, commonClasses.capitalize)}>Secured with End-to-End Encryption</Box>
            </Box>
        </div>
    );
}