import { useEffect, useState } from 'react';
import { useHistory } from "react-router";
import { Observable, Subscription } from 'rxjs';
import { navigationService } from "../../../../services/navigation.service";
import { getLastObject } from "../../../common/helpers/get-last-object";
import { goToError } from "../../../common/helpers/go-to-error";
import { onEmit } from "../../../common/helpers/on-emit";
import { PageSliderNavigationContext } from "../../../common/models/navigation.models";
import { IErrorState } from "../../../common/validation/error-state";
import {
    QuestionnaireModel,
    QuestionnairePages,
    QuestionnaireResultModel,
    QuestionnaireType,
    SaveQuestionnaireResultModel
} from "../../models/questionnaire.models";
import { questionnaireService } from "../../services/questionnaire.service";
import { questionnaireQuery } from "../../stores/questionnaireStore";
import { questionnaireSurveyPageValidator } from "./initialQuestionnaireSurveyComponent.validator";
import { CardioRespiratoryQuestionnairePageFields } from "./pages/CardioRespiratorySurvey";
import { ExerciseQuestionnairePageFields } from "./pages/ExerciseSurvey";
import { GeneralQuestionnairePageFields, GeneralQuestionnairePatientFields } from "./pages/GeneralSurvey";
import { MedicalQuestionnairePageFields } from "./pages/MedicalSurvey";
import { NutritionQuestionnairePageFields } from "./pages/NutritionSurvey";
import { SleepQuestionnairePageFields } from "./pages/SleepSurvey";
import { StressQuestionnairePageFields } from "./pages/StressSurvey";
import {PatientProfileModel} from "../../../account/models/patientProfile.model";
import {profileService} from "../../../account/services/profile.service";
import {profileQuery} from "../../../account/stores/profileStore";

const pageFields = [
    {
        page: QuestionnairePages.General,
        fields: GeneralQuestionnairePageFields
    },
    {
        page: QuestionnairePages.Medical,
        fields: MedicalQuestionnairePageFields
    },
    {
        page: QuestionnairePages.Nutrition,
        fields: NutritionQuestionnairePageFields
    },
    {
        page: QuestionnairePages.Exercise,
        fields: ExerciseQuestionnairePageFields
    },
    {
        page: QuestionnairePages.CardioRespiratory,
        fields: CardioRespiratoryQuestionnairePageFields
    },
    {
        page: QuestionnairePages.Sleep,
        fields: SleepQuestionnairePageFields
    },
    {
        page: QuestionnairePages.Stress,
        fields: StressQuestionnairePageFields
    }
];

interface QuestionnaireSurveyPageState extends IErrorState {
    currentPage: QuestionnairePages;
    result: QuestionnaireResultModel;
    patient: PatientProfileModel;
    questionnaire: QuestionnaireModel;
    canSubmit: boolean;
    isLoading: boolean;
}

export function useAnonymousFacade(intakeId: string): [Function, (result: SaveQuestionnaireResultModel, history: any) => Observable<unknown>, Function, Function, Function] {
    return [
        (result: SaveQuestionnaireResultModel) => {
            return new Observable(observer => {
                questionnaireService.saveByIntakeId(intakeId, result).subscribe(
                    () => {
                        observer.next();
                        observer.complete();
                    },
                    (error) => {
                        observer.error(error);
                        observer.complete();
                    }
                );
            });
        },
        (result: SaveQuestionnaireResultModel, history) => {
            return new Observable(observer => {
                return questionnaireService.submitByIntakeId(intakeId, result).subscribe(
                    () => {
                        navigationService.toQuestionnaireCompleted(history, intakeId);
                        observer.next();
                        observer.complete();
                    },
                    (error) => {
                        observer.error(error);
                        observer.complete();
                    }
                );
            });
        },
        () => {
            return questionnaireService.startByType(intakeId, QuestionnaireType.Initial);
        },
        () => {
            profileService.getPatientProfileByIntakeId(intakeId).subscribe();
        },
        (profile: PatientProfileModel) => {
            profileService.updatePatientProfileByIntakeId(intakeId, profile).subscribe();
        }
    ];
}

export function useAuthorizedFacade(): [Function, (result: SaveQuestionnaireResultModel, history: any) => Observable<unknown>, Function, Function, Function] {
    return [
        (result: SaveQuestionnaireResultModel) => {
            return new Observable(observer => {
                questionnaireService.save(result).subscribe(
                    () => {
                        observer.next();
                        observer.complete();
                    },
                    (error) => {
                        observer.error(error);
                        observer.complete();
                    }
                );
            });
        },
        (result: SaveQuestionnaireResultModel, history) => {
            return new Observable(observer => {
                return questionnaireService.submitAsPatient(result).subscribe(
                    () => {
                        navigationService.toQuestionnaireCompleted(history);
                        observer.next();
                        observer.complete();
                    },
                    (error) => {
                        observer.error(error);
                        observer.complete();
                    }
                );
            });
        },
        () => { },
        () => {
            profileService.getPatientProfile().subscribe()
        },
        (profile: PatientProfileModel) => {
            profileService.updatePatientProfile(profile).subscribe();
        }
    ]
}

export function useFacade(saveQuestionnaire: Function, submitQuestionnaire: (result: SaveQuestionnaireResultModel, history: any) => Observable<unknown>, getQuestionnaire: Function, getPatient: Function, savePatient: Function): [
    QuestionnaireSurveyPageState,
    Function,
    Function,
    Function
] {
    const [state, setState] = useState({
        currentPage: QuestionnairePages.General,
        questionnaire: null,
        result: null,
        patient: null,
        canSubmit: true,
        isLoading: false,
        errors: {}
    } as QuestionnaireSurveyPageState);

    /**
     * Validates questionnaire page
     * @param page
     */
    const validatePage = (page: QuestionnairePages) => {
        const fields = pageFields.find(x => x.page === page);
        if (!fields) {
            return;
        }

        fields.fields.forEach(field => {
            const value = state.result.answers.find(x => x.key === field)?.value ?? '';
            questionnaireSurveyPageValidator.validateAndSetState(state, setState, field, value);
        });

        if (page === QuestionnairePages.General) {
            GeneralQuestionnairePatientFields.forEach(field => {
                const keys = field.split(".");
                const key = keys.pop();
                const lastObject = getLastObject(keys, state.patient);
                const value = lastObject[key];
                questionnaireSurveyPageValidator.validateAndSetState(state, setState, field, value);
            });
        }
    }

    /**
     * Handles changes. Additionally does field validation
     * @param field
     * @param value
     */
    const handleChanges = (field: string, value: string) => {
        questionnaireSurveyPageValidator.validateAndSetState(state, setState, field, value);

        const result = state.result;
        const answer = result.answers.find(x => x.key === field);
        if (answer) {
            answer.value = value;
        } else {
            result.answers.push({
                key: field,
                value: value
            });
        }

        result.answers = [...result.answers];
        setState({...state, result});
    }

    const handlePatientChanges = (field: string, value: string) => {
        questionnaireSurveyPageValidator.validateAndSetState(state, setState, field, value);

        const patient = state.patient;
        const keys = field.split(".");
        const key = keys.pop();
        const lastObject = getLastObject(keys, patient);
        lastObject[key] = value;

        setState({...state, patient: {...patient}});
    }

    const history = useHistory();

    const handleSubmit = () => {
        if (!state.canSubmit) {
            return;
        }

        state.canSubmit = false;

        validatePage(state.currentPage);
        if (!questionnaireSurveyPageValidator.stateIsValid(state)) {
            goToError(state);
            return;
        }

        const cb = () => setState(state => ({...state, canSubmit: true}));
        submitQuestionnaire(getSaveModel(state.result), history).subscribe(cb, cb);
    }

    /**
     * Navigates to specified page
     * @param page
     * @param direction
     */
    const goTo = (page: QuestionnairePages, direction: number = 1) => {
        if (direction > 0) {
            validatePage(state.currentPage); 
            if (!questionnaireSurveyPageValidator.stateIsValid(state)) {
                goToError(state);
                return;
            }
        } else {
            const errors = state.errors;
            for (let error in errors) {
                delete errors[error];
            }
            setState({
                ...state,
                errors: Object.assign({}, errors)
            });
        }

        if (state.currentPage === QuestionnairePages.General)
        {
            savePatient(state.patient);
        }

        setState(state => ({
            ...state,
            isLoading: true,
        }));

        saveQuestionnaire(getSaveModel(state.result)).subscribe(
            () => {
                setState(state => ({
                    ...state,
                    currentPage: page,
                    isLoading: false,
                }));

                navigationService.goUp();
            },
            () => {
                setState(state => ({
                    ...state,
                    isLoading: false,
                }));
            }
        )
    }

    const getSaveModel = (result: QuestionnaireResultModel): SaveQuestionnaireResultModel => {
        return Object.assign(result, {
            answers: result.answers,
            questionnaireResultId: result.id
        });
    }

    /**
     * Returns if can go back
     */
    const canGoBack = (): boolean => {
        return state.currentPage !== QuestionnairePages.General
    };

    /**
     * Goes back
     */
    const goBack = () => goTo(state.currentPage - 1, -1);

    /**
     * Returns if can go next
     */
    const canGoNext = (): boolean => {
        return true;
    };

    /**
     * Goes next
     */
    const goNext = (): void => {
        if (state.currentPage === QuestionnairePages.Stress) {
            return handleSubmit();
        } else {
            return goTo(state.currentPage + 1);
        }
    };

    /**
     * Returns navigation context
     */
    const getNavigationContext = (): PageSliderNavigationContext => {
        return {
            nextButton: state.currentPage !== QuestionnairePages.Stress ? 'Next' : 'Submit',
            backButton: "Back",
            canGoNext: canGoNext,
            canGoBack: canGoBack,
            goNext: goNext,
            goBack: goBack
        } as PageSliderNavigationContext
    }

    /**
     * Load all ongoing cares
     * Manage subscriptions with auto-cleanup
     */
    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<QuestionnaireResultModel>(questionnaireQuery.targetResult$, result => {
                if (result) {
                    setState(state => ({
                        ...state,
                        result: result,
                        questionnaire: result.questionnaire
                    }));
                }
            }),
            onEmit<PatientProfileModel>(profileQuery.patientProfile$, patient => {
                if (patient) {
                    setState(state => ({
                        ...state,
                        patient: patient
                    }));
                }
            }),
        ];

        getQuestionnaire();
        getPatient();

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [getQuestionnaire, getPatient]);

    return [state, getNavigationContext, handleChanges, handlePatientChanges]
}
