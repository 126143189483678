import { HealthSummaryDataModel, HealthSummaryMapModel } from '../../models/healthSummary.models'
import { healthSummaryService } from '../../services/healthSummary.service'
import { healthSummaryQuery, healthSummaryStore } from '../../stores'
import { onEmit } from '../../../common/helpers/on-emit'
import { useState, useEffect } from 'react'
import {useFlags} from "launchdarkly-react-client-sdk";

export interface PatientHealthSummaryComponentState {
    data: HealthSummaryDataModel[]
    map: HealthSummaryMapModel[]
    isDataPresent: boolean
    isLoading: boolean
}

export function useFacade(patientId?: number): [
    PatientHealthSummaryComponentState
] {
    const [state, setState] = useState({
        isDataPresent: false,
        isLoading: true,
        data: null,
        map: []
    } as PatientHealthSummaryComponentState);

    const flags = useFlags();

    const getJsonData = (map: HealthSummaryMapModel[]) => {
        const jsonDataSections = map.filter(x => x.useJsonData)

        healthSummaryStore.update({ jsonDataSections: jsonDataSections.length })

        jsonDataSections.forEach(x => healthSummaryService.getMapData(patientId, x, flags))
    }

    useEffect(() => {
        const subscriptions = [
            onEmit<HealthSummaryDataModel[]>(healthSummaryQuery.data$, data => {
                setState(state => ({
                    ...state,
                    isDataPresent: !!data.length,
                    data: data.filter(x => !x.isDeleted),
                    isLoading: !healthSummaryStore.isLoading()
                }))
            }),
            onEmit<HealthSummaryMapModel[]>(healthSummaryQuery.map$, map => {
                setState(state => ({
                    ...state,
                    map: map
                }))

                getJsonData(map)
            })
        ]

        healthSummaryStore.clear();
        healthSummaryService.getMap();
        healthSummaryService.getData(patientId).subscribe();

        return () => {
            subscriptions.map(it => it.unsubscribe());
            healthSummaryStore.clear();
        }

    }, [patientId])

    return [
        state
    ]
}