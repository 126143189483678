import {
    Box,
    FormControlLabel,
    Grid,
} from '@material-ui/core';
import React from 'react';
import { WildHealthSwitcher } from "../../../../common/components/WIldHealthSwitcher";
import { ChronologicalSummaryComponent } from '../../../../patients/components/ChronologicalSummaryComponent/ChronologicalSummaryComponent';
import { AddressInfoWidgetComponent } from '../patientInformationWidget/addressInfoWidgetComponent/AddressInfoWidgetComponent';
import { AppointmentsInfoWidgetComponent } from '../patientInformationWidget/appointmentsInfoWidgetComponent/AppointmentsInfoWidgetComponent';
import { GeneralInfoWidgetComponent } from '../patientInformationWidget/generalInfoWidgetComponent/GeneralInfoWidgetComponent';
import { HealthFormsInfoWidgetComponent } from '../patientInformationWidget/healthFormsInfoWidgetComponent/HealthFormsInfoWidgetComponent';
import { MembershipInfoWidgetComponent } from '../patientInformationWidget/membershipInfoWidgetComponent/MembershipInfoWidgetComponent';
import { OrdersInfoWidgetComponent } from '../patientInformationWidget/ordersInfoWidgetComponent/OrdersInfoWidgetComponent';
import { PharmacyInfoWidgetComponent } from '../patientInformationWidget/pharmacyInfoWidgetComponent/PharmacyInfoWidgetComponent.';
import { useFacade } from "./patientInformationRedesignComponent.hooks";
import { useStyles } from './patientInformationRedesignComponent.styles';
import { ChronologicalEventType } from '../../../../patients/models/patient.model';
import { DemographicExportWidgetComponent } from '../patientInformationWidget/demographicExportWidgetComponent/DemographicExportWidgetComponent';
import {WildHealthButton} from "../../../../common/components/wildHealthButton/WildHealthButton";
import {FeatureFlag} from "../../../../common/components/featureFlags/featureFlags.models";
import {FeatureComponent} from "../../../../common/components/featureFlags/FeatureComponent";

interface PatientInformationComponentProps {
    patientId: number;
}

const FilterByEventType = [
    ChronologicalEventType.LabsOrdered,
    ChronologicalEventType.NotePublished,
    ChronologicalEventType.VisitCompleted,
    ChronologicalEventType.SupplementUpdated,
    ChronologicalEventType.InternalMessageSent,
    ChronologicalEventType.HealthCoachOrProviderAssigned,
]

export const PatientInformationRedesignComponent: React.FC<PatientInformationComponentProps> = (props: PatientInformationComponentProps) => {
    const { patientId } = props;
    const [
        {
            isHealthCoachView,
        },
        handleLoginToRxNT,
        handleViewToggle,
    ] = useFacade();

    const classes = useStyles();

    return (
        <Box p={4}>
            <Box display="flex" alignItems='center' justifyContent="space-between" pb={3}>
                <Box display="flex" alignItems='center'>
                    <Box className={isHealthCoachView ? classes.active : classes.subtitle}>
                        Health Coach
                    </Box>
                    <Box pl={3} className={isHealthCoachView ? classes.switcherReverse : ''}>
                        <FormControlLabel
                            control={<WildHealthSwitcher
                                checked
                                onChange={() => handleViewToggle()}
                                name="inputsModeToggle" />
                            }
                            label=""
                        />
                    </Box>
                    <Box className={isHealthCoachView ? classes.subtitle : classes.active}>
                        Care Coordinator
                    </Box>
                </Box>
                <Box display="flex" alignItems='center' justifyContent="flex-end">
                    <FeatureComponent featureFlag={FeatureFlag.RxntIntegration}>
                        <Box>
                            <WildHealthButton id={`login-rxnt-button`} borderRadius={5} size="medium" onClick={() => handleLoginToRxNT(patientId)}>
                                Login RxNT
                            </WildHealthButton>
                        </Box>
                    </FeatureComponent>
                    <Box ml={2}>
                        <DemographicExportWidgetComponent patientId={patientId} />
                    </Box>
                </Box>
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box p={4} className={classes.widget}>
                        <GeneralInfoWidgetComponent patientId={patientId} />
                    </Box>
                    {!isHealthCoachView
                        && <Box className={classes.widget}>
                            <AddressInfoWidgetComponent patientId={patientId} />
                        </Box>
                    }
                    <Box p={4} className={classes.widget}>
                        <PharmacyInfoWidgetComponent patientId={patientId} />
                    </Box>
                    {isHealthCoachView
                        && <Box p={4} className={classes.widget}>
                            <HealthFormsInfoWidgetComponent patientId={patientId} />
                        </Box>}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box display='flex' height='100%'>
                        <Box flex={1}>
                            <Box p={4} className={classes.widget}>
                                <MembershipInfoWidgetComponent patientId={patientId} />
                            </Box>
                            {!isHealthCoachView
                                && <Box p={4} className={classes.widget}>
                                    <OrdersInfoWidgetComponent patientId={patientId} />
                                </Box>
                            }
                            {isHealthCoachView
                                && < Box p={4} className={classes.widget}>
                                    <AppointmentsInfoWidgetComponent patientId={patientId} />
                                </Box>
                            }
                        </Box>
                        <Box pb={4}>
                            <ChronologicalSummaryComponent patientId={patientId} filterTypes={FilterByEventType} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box >
    );
}