import React from 'react';
import { TabItem } from '../../../common/components/wildHealthTabControl/wildHealthTabControl.hooks';
import { FellowsComponent } from "../fellows/FellowsComponent";
import { PracticumPatientsComponent } from "../practicumPatients/PracticumPatientsComponent";

export function useFacade( pmFellowship: boolean, rosterId: number): [
    TabItem[]
] {
    const tabItems: TabItem[] = [
        {
            title: 'Roster',
            content: <FellowsComponent pmFellowship={pmFellowship} rosterId={rosterId} />,
            keepMounted: true,
        },
        {
            title: 'Practicum Patients',
            content: <PracticumPatientsComponent rosterId={rosterId}/>,
            keepMounted: true,
        },
        
    ];
   
    return [tabItems]
}