import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: colors.bg,
      [theme.breakpoints.down("xs")]: {
          height: "fit-content",
      },
    },
    cardContent: {
      padding: 0,
    },
    content: {
      backgroundColor: '#fff',
      height: "100%",
      padding: 16,
      borderRadius: 8
    },
    title: {
      fontSize: 20,
      fontWeight: 500,
      fontFamily: "Poppins",
      color: colors.linkBlack,
    },
    noAppointmentsContainer: {
      paddingTop: 50,
      paddingBottom: 50,
    },
    noAppointments: {
      fontSize: 16,
    },
    appointmentContainer: {
      [theme.breakpoints.down("xs")]: {
        // height: 110,
      },
    },
    appointmentContent: {
      display: "flex",
      marginTop: 16,
      marginBottom: 24,
      [theme.breakpoints.down("xs")]: {
        // margin: 0,
      },
    },
    employeeAvatars: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        alignItems: "flex-start",
      },
    },
    employeeImage: {
      height: 70,
      width: 70,
    },
    imagePlaceholder: {
      height: "auto",
      width: "100%",
      color: "#7C8A9D",
    },
    employeeName: {
      fontSize: 18,
      fontWeight: 500,
    },
    position: {
      color: colors.gray600,
      fontSize: 16,
      fontWeight: 500,
    },
    time: {
      fontSize: 14,
      fontWeight: 400,
      color: colors.black,
    },
    date: {
      fontSize: 14,
      fontWeight: 400,
      color: colors.black,
    },
    dateIcon: {
      fill: colors.gray600,
      width: 18,
      height: 18,
    },
    timeIcon: {
      fill: colors.gray600,
      width: 18,
      height: 18,
    },
    dateTime: {
      display: "flex",
      alignItems: "center",
      marginTop: 4,
    },
    divider: {
      background: colors.separators,
      marginTop: 20,
      marginBottom: 20
    },
    menuIcon: {
      [theme.breakpoints.down("xs")]: {
        "&.MuiIconButton-root": {
          padding: 0,
          color: colors.black,
        },
      },
    },
    manageButtons: {
      display: "flex",
      alignItems: "center",
      gridGap: 16,
      width: '100%',
      [theme.breakpoints.down("xs")]: {
        flexDirection: 'column'
      },
    },
    dialog: {
      [theme.breakpoints.down("xs")]: {
        '& .MuiDialog-paper': {
          borderRadius: '10px 10px 0 0',
          margin: 0,
          marginTop: 'auto'
        },
      },
    },
    dialogTitle: {
      display: 'flex',
      justifyContent: "flex-end",
      margin: 0,
      paddingRight: 24,
      paddingLeft: 24,
      paddingTop: 24,
      paddingBottom: 0,
    },
    dialogContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: 'center',
      maxWidth: 545,
      paddingTop: 0,
      [theme.breakpoints.down("xs")]: {
        marginRight: 0,
        marginLeft: 0,
      },
    },
    dialogActions: {
      paddingBottom: 30,
      paddingLeft: 24,
      paddingRight: 24
    },
  })
);
