import React from 'react';
import moment from 'moment';
import { Box, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select, TextField, Grid, createStyles, makeStyles, Theme } from '@material-ui/core';
import { FellowShortModel } from '../../../employee/models/employee.models';
import { PracticumPatientCreateModel } from '../../models/fellowship.model';
import { WildHealthDatePicker } from "../../../common/components/WildHealthDatePicker";
import { Remove } from '@material-ui/icons';
import { phoneNumberInput } from '../../../common/components/PhoneNumberInput';
import { Gender } from '../../../common/models/user.models';
import clsx from 'clsx';
import commonUseStyles from '../../../common/styles/common.styles';
import { colors } from '../../../common/constants/colors';
import { fullName } from '../../../common/helpers/display-name';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderColor: colors.gray4,
            borderRadius: 2,
            '& .MuiOutlinedInput-root': {
                borderColor: colors.gray4,
                borderRadius: 2,
            },
        },
    }),
);

interface CreatePracticumPatientFormProps {
    practicumPatient: PracticumPatientCreateModel;
    fellows: FellowShortModel[];
    handleChanges: Function,
    handleDelete: Function,
    errors: { [id: string]: string; };
}

export const CreatePracticumPatientForm: React.FC<CreatePracticumPatientFormProps> = (props: CreatePracticumPatientFormProps) => {
    const {
        practicumPatient,
        fellows,
        handleChanges,
        handleDelete,
        errors
    } = props;

    const classes = useStyles();
    const commonClasses = commonUseStyles();

    const getBirthday = () => {
        return practicumPatient.birthday
            ? moment(practicumPatient.birthday).format('YYYY-MM-DD')
            : null
    }

    return (
        <Box mt={4}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box>
                    <span className={clsx(commonClasses.size20, commonClasses.textRegular)}>Patient {practicumPatient.number}</span>
                </Box>
                <Box>
                    <IconButton
                        id="create-practicum-patient-delete"
                        onClick={() => handleDelete(practicumPatient.number)}
                    >
                        <Remove
                            style={{
                                color: colors.main,
                                backgroundColor: colors.gray3
                            }}
                        />
                    </IconButton>
                </Box>
            </Box>
            <Box mt={3} width={1}>
                <Box className={commonClasses.inputLabel} mb={1}>Select Fellow</Box>
                <FormControl
                    variant="outlined"
                    color='primary'
                    size="small"
                    fullWidth
                    classes={{ root: classes.root }}
                    error={!!errors[`fellowId${practicumPatient.number}`]}
                >
                    <Select
                        classes={{ root: classes.root }}
                        value={practicumPatient.fellowId}
                        inputProps={{
                            classes,
                        }}
                        renderValue={() => {
                            if (practicumPatient.fellowId === null) {
                                return <Box className={commonClasses.colorGray1}>Please Select a Fellow</Box>;
                            }

                            return fullName(fellows.find((f) => f.id === practicumPatient.fellowId));
                        }}
                        displayEmpty
                        onChange={(event) => handleChanges(practicumPatient.number, event.target.value as number, "fellowId")}
                        disabled={!fellows.length}
                    >
                        {
                            fellows.map((fellow, index) =>
                                <MenuItem key={index} value={fellow.id}>
                                    {fullName(fellow)}
                                </MenuItem>
                            )
                        }
                    </Select>
                    <FormHelperText>{errors[`fellowId${practicumPatient.number}`]}</FormHelperText>
                </FormControl>
            </Box>
            <Box mt={3} display="flex">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6}>
                        <Box className={commonClasses.inputLabel} mb={1}>Patient First Name</Box>
                        <TextField
                            id={`firstName`}
                            fullWidth
                            required
                            size="small"
                            InputProps={{ className: 'input', classes }}
                            placeholder="First Name"
                            variant="outlined"
                            error={!!errors[`firstName${practicumPatient.number}`]}
                            helperText={errors[`firstName${practicumPatient.number}`]}
                            value={practicumPatient.firstName ?? ""}
                            onChange={(v) => {
                                handleChanges(practicumPatient.number, v.target.value, v.target.id)
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Box className={commonClasses.inputLabel} mb={1}>Patient Last name</Box>
                        <TextField
                            id={`lastName`}
                            fullWidth
                            required
                            size="small"
                            InputProps={{ className: 'input', classes }}
                            placeholder="Last Name"
                            variant="outlined"
                            error={!!errors[`lastName${practicumPatient.number}`]}
                            helperText={errors[`lastName${practicumPatient.number}`]}
                            value={practicumPatient.lastName ?? ""}
                            onChange={(v) => {
                                handleChanges(practicumPatient.number, v.target.value, v.target.id)
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box mt={3} display="flex">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6}>
                        <Box className={commonClasses.inputLabel} mb={1}>Patient Date of Birth</Box>
                        <FormControl
                            variant="outlined"
                            color='primary'
                            required
                            error={!!errors[`birthday${practicumPatient.number}`]}
                            size="small"
                            classes={{ root: classes.root }}
                            fullWidth
                        >
                            <WildHealthDatePicker
                                id={`birthday`}
                                required
                                disableFuture
                                fullWidth
                                size="small"
                                placeholder="MM/DD/YYYY"
                                openTo="year"
                                format="MM/DD/yyyy"
                                value={getBirthday()}
                                helperText={null}
                                error={!!errors[`birthday${practicumPatient.number}`]}
                                inputVariant="outlined"
                                views={["year", "month", "date"]}
                                onChange={(momentDate) =>
                                    handleChanges(practicumPatient.number, momentDate.format("YYYY-MM-DD"), "birthday")
                                }
                            />
                            <FormHelperText error>{errors[`birthday${practicumPatient.number}`]}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Box className={commonClasses.inputLabel} mb={1}>Patient Phone Number (Optional)</Box>
                        <TextField
                            id={`phoneNumber`}
                            fullWidth
                            size="small"
                            placeholder="(000) 000-0000"
                            variant="outlined"
                            error={!!errors[`phoneNumber${practicumPatient.number}`]}
                            helperText={errors[`phoneNumber${practicumPatient.number}`]}
                            value={practicumPatient.phoneNumber ?? ""}
                            InputProps={{
                                className: 'input',
                                inputComponent: phoneNumberInput,
                                classes,
                            }}
                            onChange={(v) => {
                                handleChanges(practicumPatient.number, v.target.value, v.target.id)
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box mt={3} display="flex">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6}>
                        <Box className={commonClasses.inputLabel} mb={1}>Preferred Patient Email</Box>
                        <TextField
                            id={`email`}
                            fullWidth
                            required
                            size="small"
                            InputProps={{ className: 'input', classes }}
                            placeholder="Email Address"
                            variant="outlined"
                            error={!!errors[`email${practicumPatient.number}`]}
                            helperText={errors[`email${practicumPatient.number}`]}
                            value={practicumPatient.email ?? ""}
                            onChange={(v) => {
                                handleChanges(practicumPatient.number, v.target.value, v.target.id)
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Box className={commonClasses.inputLabel} mb={1}>Select Assigned Sex at Birth</Box>
                        <FormControl
                            variant="outlined"
                            color='primary'
                            required
                            error={!!errors[`gender${practicumPatient.number}`]}
                            size="small"
                            classes={{ root: classes.root }}
                            fullWidth
                        >
                            <Select
                                id="gender"
                                style={{ background: '#FFF' }}
                                classes={{ root: classes.root }}
                                value={practicumPatient.gender === Gender.None ? '' : practicumPatient.gender}
                                error={!!errors[`gender`]}
                                inputProps={{
                                    name: 'Biological Gender',
                                    id: 'gender-label',
                                    classes,
                                }}
                                renderValue={() => {
                                    if (practicumPatient.gender === Gender.None || practicumPatient.gender === null) {
                                        return <Box className={commonClasses.colorGray1}>Please Select</Box>;
                                    }

                                    return Gender[practicumPatient.gender];
                                }}
                                displayEmpty
                                onChange={(v) => handleChanges(practicumPatient.number, v.target.value.toString(), "gender")}
                            >
                                <MenuItem value={Gender.Male}>{Gender[Gender.Male]}</MenuItem>
                                <MenuItem value={Gender.Female}>{Gender[Gender.Female]}</MenuItem>
                            </Select>
                            <FormHelperText>{errors[`gender${practicumPatient.number}`]}</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}