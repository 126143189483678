import {useEffect, useState} from "react";
import {Subscription} from "recompose";
import {onEmit} from "../../../common/helpers/on-emit";
import {SortingDirection} from "../../../common/sorting/models/sorting-destination";
import {ISortingState} from "../../../common/sorting/models/sorting-state";
import {handleCompare} from "../../../common/sorting/helpers/handle-compare";
import {IErrorState} from "../../../common/validation/error-state";
import {IPaginationState} from "../../../common/pagination/models/pagination-state";
import {getAvailablePages} from "../../../common/pagination/helpers/get-evailable-pages";
import {pageSizes} from "../../../common/pagination/models/page-sizes";
import {createRecommendationValidator} from "../createRecommendationDialog/createRecommendation.validator";
import {
  createRecommendationTriggerValidator
} from "../createRecommendationTriggerDialog/createRecommendationTrigger.validator";
import {confirmService} from "../../../../services/confirm.service";
import {
  LogicalOperator,
  MetricModel,
  RecommendationModel,
  RecommendationTriggerModel
} from "../../../recommendations/models/recommendations.model";
import {recommendationsService} from "../../../recommendations/services/recommendations.service";
import {healthReportsQuery} from "../../../healthReport/stores/healthReportsStore";
import {RecommendationTypes} from "../../../healthReport/models/healthReport.models";

export enum SortingSource {
  None,
  Content = "content",
  Order = "order",
}

interface ManageRecommendationsState extends IPaginationState, ISortingState, IErrorState {
  isLoading: boolean;
  isMetricLoading: boolean;
  isSubmitting: boolean;
  isOpen: boolean;
  isOpenAddTrigger: boolean;
  menuAnchorEl: HTMLButtonElement | null;
  searchKey: string;
  metrics: MetricModel[];
  recommendations: RecommendationModel[];
  originalRecommendations: RecommendationModel[];
  selectedRecommendation: RecommendationModel | null;
  createRecommendation: RecommendationModel;
  createRecommendationTrigger: RecommendationTriggerModel;
}

const initialRecommendationParmas: RecommendationModel = {
  id: 0,
  content: '',
  order: 0,
  tags: [],
  recommendationTriggers: [],
  classification: null,
  verificationMethods: []
}

const initialTriggerParmas: RecommendationTriggerModel = {
  id: 0,
  type: RecommendationTypes.Methylation,
  recommendationId: 0,
  confidence: 0,
  logicalOperator: LogicalOperator.And,
  recommendationTriggerComponents: []
}

let stateContext: ManageRecommendationsState;

export function useFacade(): [
  ManageRecommendationsState,
  (search: string) => void,
  (source: string, direction: SortingDirection) => void,
  () => number[],
  (value: any) => void,
  (page: number) => void,
  (field: string, value: any) => void,
  (field: string, value: any, index: number) => void,
  (id: number, menuAnchorEl: HTMLButtonElement) => void,
  () => void,
  () => void,
  () => void,
  (id: number) => void,
  () => void,
  () => void,
  (index: number) => void
] {
  const [state, setState] = useState({
    isLoading: true,
    isMetricLoading: true,
    isSubmitting: false,
    isOpen: false,
    isOpenAddTrigger: false,
    menuAnchorEl: null,
    searchKey: '',
    recommendations: [],
    originalRecommendations: [],
    selectedRecommendation: null,
    createRecommendation: initialRecommendationParmas,
    createRecommendationTrigger: initialTriggerParmas,
    sortingColumns: [
      { title: 'Content', source: null, direction: null },
      { title: 'Order', source: SortingSource.Order, direction: SortingDirection.Desc },
      { title: 'Triggers', source: null, direction: null },
      { title: 'Categories', source: null, direction: null },
    ],
    sortingSource: SortingSource.None,
    totalCount: 0,
    selectedPage: 1,
    pageSize: pageSizes[0],
    errors: {}
  } as ManageRecommendationsState);

  stateContext = state;

  const handleOnFilter = (search: string) => {
    setState(state => ({...state, searchKey: search}));
  }

  const handleSearchSubmit = (query: string) => {
    const searchQuery = query ?? '';

    const trimedSearch = decodeURIComponent(searchQuery).trim().toLowerCase();
    // const arrayTrimSearchKey = trimedSearch.split(' ');

    const filteredRecommendations = state.originalRecommendations?.filter(
    (e) =>
      e.content?.toLowerCase().includes(trimedSearch)
      // arrayTrimSearchKey.find((item) =>
      //   e.content?.toLowerCase().includes(item)
      // ) !== undefined
    );
  
    setState(state => ({
      ...state,
      isLoading: false,
      selectedPage: 1,
      recommendations: searchQuery ? filteredRecommendations : state.originalRecommendations,
      totalCount:  searchQuery ? filteredRecommendations.length : state.originalRecommendations.length,
    }));
  }

  const setDirection = (source: string, direction: SortingDirection) => {
    const itemIndex = state.sortingColumns.findIndex(item => item.source === source);
    state.sortingColumns[itemIndex].direction = direction;
    setState(state => ({ ...state, columns: state.sortingColumns }));
  }

  const handleSorting = (source: string, direction: SortingDirection) => {
    if (state.sortingSource === source) {
      direction = direction === SortingDirection.Asc
        ? SortingDirection.Desc
        : SortingDirection.Asc;

      setDirection(source, direction);
    }
    setState(state => ({
      ...state,
      sortingSource: source,
      recommendations: state.recommendations.sort((p1, p2) => handleCompare(p1, p2, direction, source))
    }));
  }

  const getAllAvailablePages = () => {
    return getAvailablePages(state);
  }

  const handlePageSizeChange = (value: any) => {
    if (value === state.pageSize) {
      return;
    }

    setState(state => ({
      ...state,
      pageSize: value,
      selectedPage: 1
    }));
  }

  const handlePageChange = (page: number) => {
    if (page === state.selectedPage) {
      return;
    }

    setState(state => ({
      ...state,
      selectedPage: page
    }));
  }

  const handleChanges = (field: string, value: any) => {
    createRecommendationValidator.validateAndSetState(state, setState, field, value);

    const params = state.createRecommendation;
    
    if (field === 'tags') {
      const index = params.tags.findIndex(x => x === value);
      index > -1 ? params.tags.splice(index, 1) : params.tags.push(value);
    } else if (field === 'verificationMethods') {
      const index = params.verificationMethods.findIndex(x => x === value);
      index > -1 ? params.verificationMethods.splice(index, 1) : params.verificationMethods.push(value);
    } else {
      params[field] = value;
    }

    setState({...state, createRecommendation: Object.assign({}, params)});
  }

  const handleTriggerChanges = (field: string, value: any, index: number) => {
    createRecommendationTriggerValidator.validateAndSetState(state, setState, field, value);

    const params = state.createRecommendationTrigger;
    
    if (field === 'metricId') {
      params.recommendationTriggerComponents = params.recommendationTriggerComponents.map((x, i) => i === index ? {...x, metricId: value?.id, classificationTypeOptionId: null} : x)
    } else if (field === 'classificationTypeOptionId') {
      params.recommendationTriggerComponents = params.recommendationTriggerComponents.map((x, i) => i === index ? {...x, classificationTypeOptionId: value} : x)
    } else {
      params[field] = value;
    }

    setState({...state, createRecommendationTrigger: Object.assign({}, params)});
  }

  const handleSave = () => {
    createRecommendationValidator.validateObjectAndSetState(state, setState, state.createRecommendation);
    if (!createRecommendationValidator.stateIsValid(state)) {
      return;
    }

    setState({...state, isSubmitting: true })

    const cb = () => setState(state => ({ ...state, isSubmitting: false, isOpen: false, selectedRecommendation: null, createRecommendation: Object.assign({}, initialRecommendationParmas) }));

    const action = state.createRecommendation.id > 0 ? recommendationsService.updateRecommendation(state.createRecommendation) : recommendationsService.createRecommendation(state.createRecommendation)
    
    action.subscribe(cb, cb)
  }

  const handleSaveTrigger = () => {
    createRecommendationTriggerValidator.validateObjectAndSetState(state, setState, state.createRecommendationTrigger);
    if (!createRecommendationTriggerValidator.stateIsValid(state)) {
      return;
    }

    setState({...state, isSubmitting: true })

    const cb = () => setState(state => ({ ...state, isSubmitting: false, isOpenAddTrigger: false, selectedRecommendation: null, createRecommendationTrigger: Object.assign({}, initialTriggerParmas) }));

    const action = state.createRecommendationTrigger.id > 0 ? recommendationsService.updateRecommendationTrigger(state.createRecommendationTrigger) : recommendationsService.createRecommendationTrigger(state.createRecommendationTrigger)
    
    action.subscribe(cb, cb)
  }
  
  const handleDelete = () => {
    setState(state => ({
      ...state,
      menuAnchorEl: null
    }));

    confirmService.confirm(
      'Delete Common Order',
      'Are you sure you want to delete this recommendation?',
      'Yes',
      'Cancel',
      'danger')
      .subscribe(() => {
        setState(state => ({
          ...state,
          isSubmitting: true,
        }));

        const cb = () => setState(state => ({ ...state, isSubmitting: false, selectedRecommendation: null }));
        recommendationsService.deleteRecommendation(state.selectedRecommendation.id).subscribe(cb, cb);
      });
  }

  const handleToggleAction = (id: number, menuAnchorEl: HTMLButtonElement) => {
    const recommendation = state.recommendations.find(r => r.id === id);
    setState(state => ({
      ...state,
      menuAnchorEl,
      selectedRecommendation: recommendation,
      isOpen: false,
      isOpenAddTrigger: false,
      createRecommendation: Object.assign({}, initialRecommendationParmas),
      createRecommendationTrigger: Object.assign({}, initialTriggerParmas),
      errors: {}
    }));
  }
  
  const handleToggleRecommendation = () => {
    setState(state => ({
      ...state,
      isOpen: !state.isOpen,
      isOpenAddTrigger: false,
      menuAnchorEl: null,
      createRecommendation: Object.assign({}, state.selectedRecommendation ? {...state.selectedRecommendation} : initialRecommendationParmas),
      errors: {}
    }))
  }

  const handleToggleTrigger = (id: number) => {
    const trigger = state.selectedRecommendation.recommendationTriggers.find(x => x.id === id)
    setState((state) => ({
      ...state,
      isOpenAddTrigger: !state.isOpenAddTrigger,
      isOpen: false,
      menuAnchorEl: null,
      createRecommendationTrigger: Object.assign({}, trigger ? {...trigger} : {...initialTriggerParmas, recommendationId: state.selectedRecommendation.id}),
      errors: {}
    }))
  }

  const handleAddComponent = () => {
    const initalComponent = { id: null, metricId: null, classificationTypeOptionId: null }
    setState((state) => ({
      ...state,
      createRecommendationTrigger: Object.assign({}, {...state.createRecommendationTrigger, recommendationTriggerComponents: [...state.createRecommendationTrigger.recommendationTriggerComponents, initalComponent]})
    }))
  }

  const handleRemoveComponent = (index: number) => {
    const components = state.createRecommendationTrigger.recommendationTriggerComponents.filter((x, i) => i !== index)
    setState((state) => ({
      ...state,
      createRecommendationTrigger: Object.assign({}, {...state.createRecommendationTrigger, recommendationTriggerComponents: [...components]})
    }))
  }

  const useEffectCB = () => {
    const subscriptions: Subscription[] = [
      onEmit<RecommendationModel[]>(healthReportsQuery.recommendations$, recommendations => {
        setState(state => ({
          ...state,
          recommendations,
          originalRecommendations: recommendations,
          totalCount: recommendations.length
        }));
      }),
      onEmit<MetricModel[]>(healthReportsQuery.metrics$, metrics => {
        setState(state => ({
          ...state,
          metrics,
        }));
      }),
    ];

    const cb = () => setState(state => ({ ...state, isLoading: false }));
    const metricsCB = () => setState(state => ({ ...state, isMetricLoading: false }));

    recommendationsService.getAllRecommendations().subscribe(cb, cb)
    recommendationsService.getMetrics().subscribe(metricsCB, metricsCB)

    return () => {
      subscriptions.map(it => it.unsubscribe())
    };
  }

  useEffect(useEffectCB, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!stateContext.isLoading) {
        handleSearchSubmit(stateContext.searchKey);
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn)
  }, [stateContext.searchKey]);

  return [
    state,
    handleOnFilter,
    handleSorting,
    getAllAvailablePages,
    handlePageSizeChange,
    handlePageChange,
    handleChanges,
    handleTriggerChanges,
    handleToggleAction,
    handleToggleRecommendation,
    handleSave,
    handleDelete,
    handleToggleTrigger,
    handleSaveTrigger,
    handleAddComponent,
    handleRemoveComponent
  ];
}