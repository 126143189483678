import { environmentTypes, environmentPriority, FeatureFlag } from "./featureFlags.models";
import { Practice } from "../../../auth/models/auth.enums";
import { UserType } from "../../../auth/models/auth.enums";
import { authQuery } from "../../../auth/stores/auth";

export const isFeatureFlag = (flags: any, flag: FeatureFlag): boolean => {
    if (!anyFlags(flags)) {
        return false;
    }

    const environmentPriorityIndex = getEnvironmentPriority(getEnvironmentType(authQuery.getCurrentPracticeId()));

    return getFlag(flags, environmentPriorityIndex, authQuery.getType(), flag);
}

export const getValueFeatureFlag = (flags: any, flag: FeatureFlag): string => {
    if (!anyFlags(flags)) {
        return null;
    }

    const environmentPriorityIndex = getEnvironmentPriority(getEnvironmentType(authQuery.getCurrentPracticeId()));

    return getStringValueFlag(flags, environmentPriorityIndex, authQuery.getType(), flag)
}

export const anyFlags = (flags: any) => {
    return flags && Object.keys(flags).length
}

const getEnvironmentPriority = (env: string): number => {
    return environmentPriority.indexOf(env);
}

const getEnvironmentType = (practiceId: number): string => {
    switch (practiceId) {
        case Practice.WildHealth: return environmentTypes.WildHealth;
        default: return environmentTypes.Licensing;
    }
}

const getflagName = (envPrfix: string, userType: string, flag: FeatureFlag) => {
    return `${envPrfix}${userType}${FeatureFlag[flag]}`;
}

const getFlag = (flags: any, envPriorityIndex: number, userType: UserType, flag: FeatureFlag): boolean => {
    for (let index = envPriorityIndex; index < environmentPriority.length; index++) {
        const userFeatureFlag = flags[getflagName(environmentPriority[index], UserType[userType], flag)];
        if (userFeatureFlag !== undefined) {
            return userFeatureFlag;
        }

        const allUsersFeatureFlag = flags[getflagName(environmentPriority[index], 'All', flag)];
        if (allUsersFeatureFlag !== undefined) {
            return allUsersFeatureFlag;
        }
    }

    return true;
}

const getStringValueFlag = (flags: any, envPriorityIndex: number, userType: UserType, flag: FeatureFlag): string => {
    for (let index = envPriorityIndex; index < environmentPriority.length; index++) {
        const userFeatureFlag = flags[getflagName(environmentPriority[index], UserType[userType], flag)];
        if (userFeatureFlag !== undefined) {
            return userFeatureFlag;
        }

        const allUsersFeatureFlag = flags[getflagName(environmentPriority[index], 'All', flag)];
        if (allUsersFeatureFlag !== undefined) {
            return allUsersFeatureFlag;
        }
    }

    return "CONTROL";
}