import React from "react";
import {Box} from "@material-ui/core";
import {NoteModel} from "../../models/notes.models";
import moment from "moment";
import commonUseStyles from "../../../common/styles/common.styles";
import clsx from 'clsx';
import {toTimeZoneDate} from "../../../timezones/helpers/timezone";
import {timezonesQuery} from "../../../timezones/stores/timezones";

interface NoteSignatureComponentProps {
    note: NoteModel;
    noteId: number;
}

export const NoteSignatureComponent: React.FC<NoteSignatureComponentProps> = (props: NoteSignatureComponentProps) => {
    const {
        note,
        noteId
    } = props;

    const commonClasses = commonUseStyles();

    const getCompletedBy = (note: NoteModel) => {
        if (note.completedBy) {
            return note.completedBy
        } else {
            return note.employee.firstName + ' ' + note.employee.lastName;
        }
    }

    const getCurrentNote = (note: NoteModel, noteId:number) => {
        if(note.id == noteId || noteId == null){
            return note;
        }
        return note.amendedNotes.find(x => x.id == noteId);
    }

    const currentNote = getCurrentNote(note, noteId);

    const timezone = timezonesQuery.getMyTimezone();

    const completedAt = moment(toTimeZoneDate(currentNote.completedAt, timezone));

    const isAmended = currentNote.version > 0;

    return (
        <Box display="flex" justifyContent="flex-end">
            <Box>
                <Box display="flex" alignItems="center">
                    <Box pl={2} className={clsx(commonClasses.size16, commonClasses.textRegular)}>
                        {
                            isAmended ?
                            'Amended and electronically signed by '
                            :
                            'Electronically signed by '
                        }
                        <b>
                            {
                                getCompletedBy(currentNote)
                            }
                        </b>
                    </Box>
                </Box>
                <Box mt={1} display="flex" alignItems="center">
                    <Box pl={2} className={clsx(commonClasses.size16, commonClasses.textRegular)}>
                        {
                            "On " + completedAt.format('ll') + ", at " + completedAt.format('LT')
                        }
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}