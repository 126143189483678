import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { colors } from "../../modules/common/constants/colors";

const drawerWidth = 300;
const drawerCloseWidth = 92;

type Props = {
    topBannerHeight: number;
};

export const useStyles = makeStyles<Theme, Props>((theme) =>
    createStyles({
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            overflow: 'hidden',
        },
        drawerPaper: {
            border: "none",
            top: ({ topBannerHeight }) => topBannerHeight
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            width: drawerCloseWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        content: {
            overflowX: 'hidden',
            overflowY: 'auto',
            height: '100%',
            width: '100%',
        },
        menuList: {
            padding: 14,
            overflowX: 'hidden',
            overflowY: 'auto',
            paddingBottom: 50,
        },
        menuIconSize: {
            width: 20,
            height: 20,
        },
        menuIcon: {
            minWidth: 0,
        },
        menuTitle: {
            marginLeft: 20,
            fontFamily: 'Be Vietnam Pro',
            fontWeight: 500,
            fontSize: 14,
            lineHeight: 19,
        },
        menuItem: {
            borderRadius: 2,
            height: 40,
            margin: '4px 0',
            paddingLeft: 20,
            paddingRight: 20,
            color: colors.gray1,
        },
        childrenMenuItem: {
            borderRadius: 2,
            margin: '2px 0',
            padding: '5px 20px',
            color: colors.gray1,
        },
        menuItemSelected: {
            background: `${colors.main}0F !important`,
            color: colors.main,
        },
        toggleButton: {
            position: 'absolute',
            borderRadius: 0,
            width: '100%',
            bottom: 0,
            padding: 10,
        },
        logo: {
            width: 145,
            maxHeight: 45,
        },
        nested: {
            paddingLeft: theme.spacing(2),
        },
    }),
);