import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { ColumnViewModel } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { onEmit } from "../../../common/helpers/on-emit";
import { LabOrderModel } from "../../models/labOrders.models";
import { labOrdersQuery } from "../../stores/labOrdersStore";
import { labInvoicePdfService } from "../../services/invoice-pdf.service";
import { addOnProvidersImages } from "../../../addons/models/addOns.models";

interface LabOrderInvoiceComponentState {
    isLoading: boolean;
    invoice: LabOrderModel;
    total: number;
}

export function useFacade(patientId: number, orderId: number): [LabOrderInvoiceComponentState, Array<ColumnViewModel>, () => void] {
    const [state, setState] = useState({
        isLoading: true,
        invoice: null,
        total: 0,
    } as LabOrderInvoiceComponentState);

    const columns: ColumnViewModel[] = [
        { title: 'Test Code', alignment: 'left' },
        { title: 'Description', alignment: 'left' },
        { title: 'Price', alignment: 'right' }
    ];

    const handleExportInvoice = () => {
        labInvoicePdfService.toDataURL(addOnProvidersImages.get(state.invoice.provider), function(dataUrl) {
            labInvoicePdfService.downloadNotePdf(state.invoice, dataUrl);
        });
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<LabOrderModel[]>(labOrdersQuery.orders$, labs => {
                if (!labs || !labs.length) {
                    return;
                }

                const order = labs.find(i => i.id === orderId);
                if (!order) {
                    return;
                }

                let total = 0;

                order.items.forEach(item => total += item.price);
                order.provider = 200;
                setState(state => ({
                    ...state,
                    invoice: order,
                    total: total,
                    isLoading: false,
                }));
            })
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [orderId]);

    return [state, columns, handleExportInvoice];
}