import { useEffect, useState } from 'react';
import { Subscription } from 'rxjs';
import { authQuery } from '../../../auth/stores/auth';
import { onEmit } from '../../../common/helpers/on-emit';
import { EmployeeType } from '../../../employee/models/employee.enums';
import {ChatFilterTypes, EmployeeConversationModel, llmConversationTypes} from "../../models/conversation.models";
import { employeeConversationsQuery } from '../../stores/employeeConversationsStore/employeeConversations.query';
import { employeeConversationsStore } from '../../stores/employeeConversationsStore/employeeConversations.store';
import { navigationService } from '../../../../services/navigation.service';
import { useHistory } from "react-router";

interface EmployeeConversationsTabsComponentState {
    isLoading: boolean;
    selectedTab: number;
    targetConversation: EmployeeConversationModel;
    conversations: EmployeeConversationModel[];
    conversationProxies: any[];
    employeeType: EmployeeType;
}

let stateContext: EmployeeConversationsTabsComponentState = null;


export function useFacade(): [
    EmployeeConversationsTabsComponentState,
    (tab: number) => void,
    () => ChatFilterTypes[],
    () => void,
] {
    const [state, setState] = useState({
        isLoading: true,
        selectedTab: 0,
        targetConversation: null,
        conversations: [],
        conversationProxies: [],
        employeeType: authQuery.getEmployeeType(),
    } as EmployeeConversationsTabsComponentState);

    const history = useHistory();

    stateContext = state;

    const handleChangeTab = (tab: number) => {
        employeeConversationsStore.setTab(tab);
    }

    const handleGetFilters = () => {
        const result = [ChatFilterTypes.Active];

        switch (state.employeeType) {
            case EmployeeType.Provider:
                return [...result, ChatFilterTypes.Assigned];
            case EmployeeType.Staff:
                return [...result, ChatFilterTypes.Closed];
            case EmployeeType.HealthCoach:
            default:
                return result;
        }
    }

    const handleNavigateToSettings = () => {
        navigationService.toMessageSettings(history);
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<number>(employeeConversationsQuery.tab$, tab =>
                setState(state => ({ ...state, selectedTab: tab }))
            ),
            onEmit<EmployeeConversationModel[]>(employeeConversationsQuery.conversations$, conversations => {
                setState(state => ({ ...state, isLoading: !employeeConversationsQuery.isReady(), conversations: conversations }));
            }),
            onEmit<EmployeeConversationModel>(employeeConversationsQuery.targetConversation$, conversation => {

                // prevent default conversation components from loading llm conversations
                if (conversation && llmConversationTypes.includes(conversation.type)) {
                    return;
                }

                setState(state => ({ ...state, targetConversation: conversation }))
            }),
        ];

        setState(state => ({ ...state, isLoading: !employeeConversationsQuery.isReady(), conversations: employeeConversationsQuery.getConversations() }));

        return () => {
            subscriptions.map(it => it.unsubscribe());
        };
    }, []);

    return [
        state,
        handleChangeTab,
        handleGetFilters,
        handleNavigateToSettings,
    ];
}