import { Box, FormControl, MenuItem, Select, Table, TableBody, TableContainer, TableHead, useTheme, useMediaQuery } from "@material-ui/core";
import React from "react";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { WildHealthContainer } from "../../../common/components/wildHealthContainer/WildHealthContainer";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { CommonPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/CommonPlaceHolder";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { patientsSubmissionsComponentColumns, useFacade } from "./patientsSubmissionsComponent.hooks";
import { useStyles } from "./patientsSubmissionsComponent.styles";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FeatureComponent } from "../../../common/components/featureFlags/FeatureComponent";
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import { PagesNamesService } from "../../../../constants/pagesNames.constants";

export const PatientsSubmissionsComponent: React.FC = () => {

    const classes = useStyles();

    const [
        {
            isLoading,
            isNavigating,
            locations,
            targetLocationId,
            targetChatId,
            conversations,
        },
        handleFiltering,
        handleNavigateToChat,
        handleToggleMessage,
    ] = useFacade();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const renderTable = () => {
        if (isLoading) {
            return (
                <Box p={5}>
                    <WildHealthLinearProgress />
                </Box>
            )
        }

        if (!conversations || !conversations.length) {
            return <CommonPlaceHolder message="No submissions found." />
        }

        if (isSmallScreen) {
            return (
                <Box width={1}>
                    {
                        conversations.map((item, index) => {
                            const location = locations.find(i => i.id === item.locationId);

                            return (
                                <Box key={index} p={2} mb={2} className="wh-tw-bg-white">
                                    <Box display='flex' alignItems='center' justifyContent='space-between' mb={2.5}>
                                        <Box>
                                            <Box pb={0.5} className="wh-tw-cursor-pointer" onClick={() => handleToggleMessage(item.id)}><span className="wh-tw-font-medium wh-tw-text-sm">{`${item.patients[0].firstName} ${item.patients[0].lastName}`}</span></Box>
                                            <Box><span className="wh-tw-text-xs">{location?.name ?? ''}</span></Box>
                                        </Box>
                                        <Box className="wh-tw-text-sm">{item.subject}</Box>
                                    </Box>
                                    <WildHealthButton
                                        id="patients-submissions-navigate-chat"
                                        onClick={() => handleNavigateToChat(item)}
                                        disabled={isNavigating || !location}
                                        fullWidth
                                    >
                                        {
                                            targetChatId === item.id ?
                                                'Navigating to Chat' :
                                                'Accept Ticket And Open'
                                        }
                                    </WildHealthButton>
                                </Box>
                            )
                        })
                    }
                </Box>
            )
        }

        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <WildHealthTableRow>
                            <WildHealthTableCell style={{ width: '16px' }} align="left">
                            </WildHealthTableCell>
                            {
                                patientsSubmissionsComponentColumns.map((item, index) =>
                                    <WildHealthTableCell key={index} align="left">
                                        {item}
                                    </WildHealthTableCell>
                                )
                            }
                        </WildHealthTableRow>
                    </TableHead>
                    <TableBody>
                        {
                            conversations.map((item, index) => {
                                const location = locations.find(i => i.id === item.locationId);

                                return (
                                    <>
                                        <WildHealthTableRow key={index}>
                                            <WildHealthTableCell className={classes.cursorPointer} style={{ width: '16px' }} onClick={() => handleToggleMessage(item.id)} >
                                                <Box mt={0.5}> {item?.isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}</Box>
                                            </WildHealthTableCell>
                                            <WildHealthTableCell className={classes.cursorPointer} onClick={() => handleToggleMessage(item.id)} align="left">
                                                <span>{`${item.patients[0].firstName} ${item.patients[0].lastName}`}</span>
                                            </WildHealthTableCell>
                                            <WildHealthTableCell align="left">
                                                {location?.name ?? ''}
                                            </WildHealthTableCell>
                                            <WildHealthTableCell align="left">
                                                {item.subject}
                                            </WildHealthTableCell>
                                            <WildHealthTableCell align="left">
                                                <WildHealthButton
                                                    id="patients-submissions-navigate-chat"
                                                    onClick={() => handleNavigateToChat(item)}
                                                    disabled={isNavigating || !location}
                                                >
                                                    {
                                                        targetChatId === item.id ?
                                                            'Navigating to Chat' :
                                                            'Accept Ticket and Open'
                                                    }
                                                </WildHealthButton>
                                            </WildHealthTableCell>
                                        </WildHealthTableRow>
                                        {item?.isOpen && <WildHealthTableRow key={index} className={classes.messagePreview}>
                                            <WildHealthTableCell style={{ width: '16px' }} >
                                            </WildHealthTableCell>
                                            <WildHealthTableCell colSpan={4} align="left">
                                                <Box className={classes.descriptionTitle}>Message Preview</Box>
                                                <Box mb={2.5}>{item.description}</Box>
                                            </WildHealthTableCell>
                                        </WildHealthTableRow>}
                                    </>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    return (
        <Box py={2} px={isSmallScreen ? 2 : 5} width={1}>
            <Box my={2} display="flex" alignItems="center" justifyContent="space-between">
                <Box>
                    <FeatureComponent featureFlag={FeatureFlag.Navigation}>
                        <Box className="wh-tw-font-semibold wh-tw-text-2xl wh-tw-black">{PagesNamesService.patientsSubmissions}</Box>
                    </FeatureComponent>
                </Box>
            </Box>
            <Box my={2} justifyContent="space-between" className={!isSmallScreen && "wh-tw-flex"}>
                <Box display='flex' alignItems='flex-end' mb={isSmallScreen && 2.5}>
                    {
                        !isLoading &&
                        <Box className={classes.title}>
                            {`${conversations.length} submissions`}
                        </Box>
                    }
                </Box>

                <Box className={classes.filter}>
                    <FormControl
                        fullWidth
                        color='primary'
                        variant="outlined"
                        size="small"
                    >
                        <Select
                            value={targetLocationId}
                            onChange={(e) => handleFiltering(Number(e.target.value))}
                        >
                            <MenuItem key={0} value={0}>
                                All pods
                            </MenuItem>
                            {
                                locations
                                    .map((location, index) =>
                                        <MenuItem key={index} value={location.id}>
                                            {location.name}
                                        </MenuItem>
                                    )
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Box>

            <WildHealthContainer margin={0} minHeight='calc(100vh - 246px)' minWidth='100%' hideBackground={isSmallScreen}>
                {renderTable()}
            </WildHealthContainer>
        </Box>
    )
};