import { Box, Dialog, DialogActions, DialogContent, FormControl, MenuItem, Select } from "@material-ui/core";
import React from "react";
import { DialogComponentProps } from "../../../common/dialogs/dialogProps";
import { useFacade } from "./verifyInsuranceComponent.hooks";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import clsx from "clsx";
import commonUseStyles from "../../../common/styles/common.styles";
import { DialogTitle } from "../../../common/components/dialogTitle/DialogTitle";

export const VerifyInsuranceComponent: React.FC<DialogComponentProps> = (props: DialogComponentProps) => {
    const {
        open,
        handleSubmit,
        handleClose
    } = props;

    const [
        {
            isVerifiedItem
        },
        handleVerifiedItemChanges,
        handleCancel,
        handleConfirm
    ] = useFacade(handleSubmit, handleClose);
    const commonClasses = commonUseStyles();

    return (
        <Dialog fullWidth open={open} onClose={() => handleCancel()} aria-labelledby="form-dialog-title">
            <Box mt={2} mx={3} ><DialogTitle onClose={() => handleCancel()} id='dialog-title'>Verify Insurance</DialogTitle></Box>
            <Box m={2} mt={0}>
                <DialogContent>
                    <Box pb={2}>
                        <Box>
                            <Box className={clsx(commonClasses.size14, commonClasses.colorGray1)}>
                                Verified
                            </Box>
                            <Box mt={1}>
                                <FormControl
                                    variant="outlined"
                                    color='primary'
                                    size="small" fullWidth>
                                    <Select
                                        style={{ background: '#FFF' }}
                                        id="selectedVerifiedItem.id"
                                        value={isVerifiedItem}
                                        onChange={(v) => handleVerifiedItemChanges(v.target.value.toString())}>
                                              <MenuItem key='yes' value='Yes'>Yes</MenuItem>
                                              <MenuItem key='no' value='No'>No</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>

                <DialogActions style={{ padding: '8px 24px' }}>
                    <Box mr={5}>
                        <WildHealthButton
                            id="cancel-verify-insurance"
                            onClick={() => handleCancel()}
                            width={100}
                            size='large'
                            color='tertiary'
                        >
                            Cancel
                        </WildHealthButton>
                    </Box>
                    <Box>
                        <WildHealthButton
                            width={100}
                            size='large'
                            id="verify-insurance-dialog"
                            onClick={() => handleConfirm()}>
                            Verify
                        </WildHealthButton>
                    </Box>
                </DialogActions>
            </Box>
        </Dialog>
    );
}
