import { useState } from "react";
import { LabInputModel } from "../../models/input.models";
import { PlatformName, Track } from "../../../tracking/tracker";

interface PatientLabInputsSectionComponentState {
    isSideAreaOpen: boolean;
    selectedLabInputId: number;
}

export function useFacade(inputs: LabInputModel[]): [
    PatientLabInputsSectionComponentState,
    (id: number, open: boolean) => void,
] {
    const [state, setState] = useState({
        isSideAreaOpen: false,
        selectedLabInputId: 0
    } as PatientLabInputsSectionComponentState);

    const handleToggleSideArea = (id: number, open: boolean) => {
        const selectedLabInput = inputs.find(x => x.id === id)
        if (selectedLabInput) {
            Track("lab_selected", {
                lab_name: selectedLabInput.name,
                platform: PlatformName(),
            });
        }
        setState(state => ({ ...state, isSideAreaOpen: open, selectedLabInputId: id }))
    }

    return [
        state,
        handleToggleSideArea,
    ];
}
