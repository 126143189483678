import { GeneralValidator } from "../../../common/validation/general-validator";
import { notWhitespaceValidationRule, rangeValidationRule, stringLengthValidationRule } from "../../../common/validation/general-validation-rules";

export const insuranceComponentValidator = new GeneralValidator({
    'memberId': [stringLengthValidationRule(1, null, "Member id should not be empty."), notWhitespaceValidationRule()],
    'insurance.id': [rangeValidationRule(1, null, "Insurance Provider should not be empty."), notWhitespaceValidationRule()],
    'insuranceId': [stringLengthValidationRule(1, null, "Insurance Provider should not be empty."), notWhitespaceValidationRule()],
    'policyHolder.firstName': [stringLengthValidationRule(1, null, "First name should not be empty."), notWhitespaceValidationRule()],
    'policyHolder.lastName': [stringLengthValidationRule(1, null, "Last name should not be empty."), notWhitespaceValidationRule()],
    'policyHolder.dateOfBirth': [stringLengthValidationRule(1, null, "Date of birth should not be empty."), notWhitespaceValidationRule()],
    'policyHolder.relationship': [stringLengthValidationRule(1, null, "Relationship should not be empty."), notWhitespaceValidationRule()],
});