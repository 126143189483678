import { useEffect, useState } from 'react'
import { Subscription } from 'rxjs/internal/Subscription';
import { onEmit } from '../../../common/helpers/on-emit';
import { apoes, ApoeSoursConstants } from '../../../healthReport/constants/apoe.constants';
import { ApoeModel, YesNo } from '../../../inputs/models/input.models';
import { inputsService } from '../../../inputs/services/inputs.service';
import { patientInputsService } from '../../../inputs/services/patientInputs.service';
import { inputsQuery } from '../../../inputs/stores/inputsStore/inputs.query';
import { DetailedQuestionNames } from '../../../questionnaire/static/detailedQuestionnaire.static';
import { HealthSummaryDataModel } from '../../models/healthSummary.models';
import { IErrorState } from '../../../common/validation/error-state';

export interface ApoeItemModel {
    name: string;
}

export interface BlockItemApoeComponentState extends IErrorState {
    editMode: boolean,
    isLoading: boolean,
    apoes: string[],
    selectedApoe: string,
    hideApoe: YesNo,
    selectedSours: string,
    colonoscopy: HealthSummaryDataModel,
    psa: HealthSummaryDataModel,
}

export function useFacade(patientId: number): [
    BlockItemApoeComponentState,
    Function,
    Function,
    Function,
    Function,
    Function,
    Function,
    Function,
    Function,
] {
    const [state, setState] = useState({
        apoes: apoes,
        isLoading: true,
        selectedApoe: null,
        selectedSours: ApoeSoursConstants.BloodTest,
        hideApoe: YesNo.None,
        editMode: false,
        colonoscopy: null,
        psa: null,
        errors: {}
    } as BlockItemApoeComponentState)


    const initialColonoscopy = {
        isDeleted: false,
        patientId: patientId,
        isNew: true,
        value: '',
        name: '',
        key: DetailedQuestionNames.DATE_OF_LAST_SCREENING,
        id: null,
        tooltip: '',
    }

    const initialPsa = {
        isDeleted: false,
        patientId: patientId,
        isNew: true,
        value: '',
        name: '',
        key: DetailedQuestionNames.DATE_OF_PSA_OR_PROSTATE_EXAM,
        id: null,
        tooltip: '',
    }

    const handleEditMode = (editMode: boolean) => {
        setState({
            ...state,
            editMode: editMode
        })
    }

    const handleDateColonoscopyChanges = (val) => {
        setState(state => ({
            ...state,
            colonoscopy: {
                ...state.colonoscopy,
                value: val
            }
        }))
    }

    const handleDatePsaChanges = (val) => {
        setState(state => ({
            ...state,
            psa: {
                ...state.psa,
                value: val
            }
        }))
    }

    const handleHideApoe = (val: YesNo) => {
        setState(state => ({
            ...state,
            hideApoe: val
        }))
    }

    const handleSelectedSours = (val: string) => {
        setState(state => ({
            ...state,
            selectedSours: val
        }))
    }

    const handleEditValue = (value: string) => {
        setState({
            ...state,
            selectedApoe: value
        })
    }

    const saveChanges = () => {
        setState(state => ({ ...state, isLoading: true }))

        const model = {
            hideApoe: state.hideApoe,
            score: state.selectedApoe,
            source: state.selectedSours,
            colonoscopy: state.colonoscopy,
            psa: state.psa,
        } as ApoeModel

        inputsService.updateApoeInfo(patientId, model).subscribe(() => {
            setState(state => ({
                ...state,
                isLoading: false,
                editMode: false
            }))
        },
            () => {
                setState(state => ({
                    ...state,
                    isLoading: false,
                }))
            })

    }

    const cancelChanges = () => {
        const apoeFromStore = inputsQuery.getApoe()
        if (apoeFromStore) {
            setState(state => ({
                ...state,
                editMode: false,
                hideApoe: apoeFromStore.hideApoe,
                selectedApoe: apoeFromStore.score,
                selectedSours: apoeFromStore.source ?? ApoeSoursConstants.BloodTest,
                colonoscopy: apoeFromStore.colonoscopy ? apoeFromStore.colonoscopy : initialColonoscopy,
                psa: apoeFromStore.psa ? apoeFromStore.psa : initialPsa,
            }))
        }
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<ApoeModel>(inputsQuery.apoeInfo$, apoeInfo => {
                if (apoeInfo) {
                    setState(state => ({
                        ...state,
                        hideApoe: apoeInfo.hideApoe,
                        selectedSours: apoeInfo.source ?? ApoeSoursConstants.BloodTest,
                        selectedApoe: apoeInfo.score,
                        colonoscopy: apoeInfo.colonoscopy ? apoeInfo.colonoscopy : initialColonoscopy,
                        psa: apoeInfo.psa ? apoeInfo.psa : initialPsa,
                    }))
                }

            })
        ];

        const cb = () => setState(state => ({ ...state, isLoading: false }))

        if (patientId) {
            inputsService.getApoe(patientId).subscribe(cb, cb)
        } else {
            patientInputsService.getApoe().subscribe(cb, cb)
        }


        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, []);

    return [
        state,
        handleEditValue,
        handleSelectedSours,
        handleHideApoe,
        handleEditMode,
        cancelChanges,
        saveChanges,
        handleDateColonoscopyChanges,
        handleDatePsaChanges
    ]
}