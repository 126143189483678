import React from 'react';
import './QuestionComponent.scss';
import { Box, TextField } from "@material-ui/core";
import { QuestionComponentProps } from "./QuestionComponentProps";
import { TextFieldNoteComponent } from '../../../notes/components/textFieldNoteComponent/TextFieldNoteComponent';
import commonUseStyles from '../../../common/styles/common.styles';

export const QuestionTextComponent: React.FC<QuestionComponentProps> = (props: QuestionComponentProps) => {
    const {
        disabled,
        question,
        answer,
        error,
        handleChanges,
        componentInNotes
    } = props;

    const commonClasses = commonUseStyles();

    return (
        <>
            <Box display="flex" justifyContent="flex-start" className="input-question" width={1}>
                {componentInNotes
                    ? <TextFieldNoteComponent
                        rows={5}
                        className={commonClasses.textMedium}
                        placeholder={question.variants ? question.variants[0] : ''}
                        title={question?.displayName && question.displayName.length ? question.displayName : ""}
                        id={question.name}
                        content={answer ?? ""}
                        error={!!error}
                        helperText={error}
                        disabled={disabled}
                        handleChanged={handleChanges}
                    />
                    : <TextField
                        fullWidth
                        className="input text-question"
                        style={{ background: '#FFFFFF' }}
                        type='text'
                        placeholder={question.variants ? question.variants[0] : ''}
                        size="small"
                        multiline
                        rows={5}
                        id={question.name}
                        variant="outlined"
                        value={answer ?? ""}
                        error={!!error}
                        helperText={error}
                        disabled={disabled}
                        onChange={(v) => handleChanges(v.target.value)}
                    />
                }
            </Box>
        </>
    )
}