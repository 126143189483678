import React from 'react';
import {
    Box,
    Dialog,
    DialogContent,
    IconButton,
    TextField,
    Divider
} from "@material-ui/core";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { useFacade } from "./addEmployeesButtonComponent.hooks";
import { useStyles } from "./addEmployeesButtonComponent.styles";
import { WildHealthButton } from "../../../../../common/components/wildHealthButton/WildHealthButton";

interface AddEmployeesButtonComponentProps {
    productId: number;
}

export const AddEmployeesButtonComponent: React.FC<AddEmployeesButtonComponentProps> = (props: AddEmployeesButtonComponentProps) => {
    const classes = useStyles();
    const [
        {
            isLoading,
            isOpen,
            creatRequests,
            errors,
        },
        isCheckedForCreating,
        handleChanges,
        handleCreate,
        handleToggle,
        handleAddRequest
    ] = useFacade(props.productId);

    return (
        <>
            <WildHealthButton
                id="add-employee-create-user"
                size='medium'
                borderRadius={4}
                disabled={isLoading}
                onClick={() => handleToggle(true)}
            >
                <span className="wh-tw-font-beVietnamPro">Add Employee(s)</span>
            </WildHealthButton>

            <Dialog
                maxWidth="md"
                onClose={() => handleToggle(false)}
                className={classes.root}
                open={isOpen}
            >
                <MuiDialogTitle
                    className={classes.dialogTitle}
                    disableTypography
                >
                    <IconButton
                        id="add-employee-close"
                        className={classes.dialogTitleCloseAction}
                        onClick={() => handleToggle(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                </MuiDialogTitle>

                <DialogContent>
                    <Box className="wh-tw-text-xl wh-tw-font-medium" mb={3}>Add Employee</Box>
                    {creatRequests.map((request, index) => (
                        <Box mb={3} width={1}>
                            <Box mb={3} display="flex" gridGap={12} key={`employee-profile-${index}`}>
                                <Box width={190}>
                                    <Box className="wh-tw-text-xxs wh-tw-text-gray600 wh-tw-uppercase" mb={1}>First name <span className="wh-tw-text-primaryV">*</span></Box>
                                    <TextField
                                        fullWidth
                                        required
                                        InputProps={{ className: 'input', classes }}
                                        id="firstName"
                                        variant="outlined"
                                        disabled={isLoading || !isOpen}
                                        error={!!errors[`firstName-${index}`]}
                                        helperText={errors[`firstName-${index}`]}
                                        value={request?.firstName}
                                        onChange={(event) => handleChanges(index, event.target.id, event.target.value)}
                                    />
                                </Box>
                                <Box width={190}>
                                    <Box className="wh-tw-text-xxs wh-tw-text-gray600 wh-tw-uppercase" mb={1}>Last name <span className="wh-tw-text-primaryV">*</span></Box>
                                    <TextField
                                        fullWidth
                                        required
                                        InputProps={{ className: 'input', classes }}
                                        id="lastName"
                                        variant="outlined"
                                        disabled={isLoading || !isOpen}
                                        error={!!errors[`lastName-${index}`]}
                                        helperText={errors[`lastName-${index}`]}
                                        value={request?.lastName}
                                        onChange={(event) => handleChanges(index, event.target.id, event.target.value)}
                                    />
                                </Box>
                                <Box width={328}>
                                    <Box className="wh-tw-text-xxs wh-tw-text-gray600 wh-tw-uppercase" mb={1}>Email <span className="wh-tw-text-primaryV">*</span></Box>
                                    <TextField
                                        fullWidth
                                        required
                                        InputProps={{ className: 'input', classes }}
                                        id="email"
                                        variant="outlined"
                                        disabled={isLoading || !isOpen}
                                        error={!!errors[`email-${index}`]}
                                        helperText={errors[`email-${index}`]}
                                        value={request?.email}
                                        onChange={(event) => handleChanges(index, event.target.id, event.target.value)}
                                    />
                                </Box>
                            </Box>
                            <Divider />
                        </Box>
                    ))}
                    <Box display="flex" alignItems="center" gridGap={4} className="wh-tw-cursor-pointer" onClick={() => isCheckedForCreating() && handleAddRequest()}>
                        <AddCircleOutlineIcon className={clsx(isCheckedForCreating() ? "wh-tw-text-primaryV" : "wh-tw-text-gray400")} />
                        <Box className={clsx("wh-tw-text-sm wh-tw-font-medium", isCheckedForCreating() ? "wh-tw-text-primaryV" : "wh-tw-text-gray400")}>Add New</Box>
                    </Box>
                    <Box my={3} display="flex" alignItems="center" justifyContent="flex-end" gridGap={16}>
                        <Box>
                            <WildHealthButton
                                id="add-employee-cancel"
                                onClick={() => handleToggle(false)}
                                size="large"
                                color="secondary"
                                borderRadius={50}
                                disabled={isLoading || !isOpen}
                            >
                                <span className="wh-tw-font-beVietnamPro wh-tw-font-medium">Cancel</span>
                            </WildHealthButton>
                        </Box>
                        <Box>
                            <WildHealthButton
                                id="add-employee-save"
                                onClick={() => handleCreate()}
                                size="large"
                                borderRadius={50}
                                disabled={!isCheckedForCreating() || !isOpen || Object.keys(errors).length > 0}
                                loading={isLoading}
                            >
                                <Box px={2} className="wh-tw-font-beVietnamPro wh-tw-font-medium">Add {isCheckedForCreating() && `(${creatRequests.length})`}</Box>
                            </WildHealthButton>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
}
