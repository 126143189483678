import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { colors } from '../../common/constants/colors';


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sections: {
            backgroundColor: colors.white,
            padding: 30,
            height: '100%',
        },
        title: {
            fontWeight: 600,
            fontSize: 24,
        },
        subTitle: {
            padding: 24,
        }

    }),
);