import {SubscriptionState, SubscriptionStore, subscriptionStore} from './subscriptionStore';
import {Query} from '@datorama/akita';

/**
 * Provides payment queries
 */
export class SubscriptionQuery extends Query<SubscriptionState> {
    subscription$ = this.select(state => state.activeSubscription);
    mySubscriptions$ = this.select(state => state.mySubscriptions);
    patientSubscriptions$ = this.select(state => state.patientSubscriptions);
    manualPayment$ = this.select(state => state.manualPayment);

    constructor(protected store: SubscriptionStore) {
        super(store);
    }
}

export const subscriptionQuery = new SubscriptionQuery(subscriptionStore);
