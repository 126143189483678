import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Subscription } from 'rxjs';
import {HealthScoreCategoriesDisplaytModel, HealthScoreModel, ScoreTermsModel} from "../../models/score.model";
import {navigationService} from "../../../../services/navigation.service";
import {onEmit} from "../../../common/helpers/on-emit";
import {healthScoreQuery} from "../../stores";
import {healthScoreService} from "../../services/healthScore.service";
import {questionnaireService} from "../../../questionnaire/services/questionnaire.service";
import {QuestionnaireSubType} from "../../../questionnaire/models/questionnaire.models";


export interface HealthScoreComponentState {
    isLoading: boolean,
    categories: HealthScoreCategoriesDisplaytModel[],
    generalScore: number,
    labsScore: ScoreTermsModel[],
    filteredGeneralScore: any,
    isCompletedQuestionnaires: boolean,
    isResultsFromLabs: boolean,
}

const orderLabsTerms = [
    "crp",
    "total_cholesteral",
    "hdl_c",
    "tc_hdl_ratio",
    "triglycerides",
    "hba1c",
    "glucose",
    "sodium",
    "egfr",
    "albumin",
    "ast_delta",
    "alt_sgpt",
    "ast_sgpt",
    "alt_delta",
    "dhea",
    "testosterone",
    "thyroid",
    "homocysteine",
    "vitamin_d",
    "uric_acid",
    "cac",
    "white_blood_cell_count",
    "haemoglobin_concentration",
    "hct",
    "mean_corpuscular_haemoglobin_concentration_g_per_dl",
    "red_blood_cell_distribution_width",
    "monocytes_number",
    "neutrophils_number",
    "red_blood_cell_count"
]

export function useFacade(patientId: number | null): [
    HealthScoreComponentState,
    () => void,
] {
    const [state, setState] = useState({
        isLoading: true,
        categories: [
            {
                name: 'Labs',
                hoverText: 'This data is sourced from your latest lab results which have implications on your current and future health outlook.'
            },
            {
                name: 'Biomarkers',
                hoverText: 'This data is sourced from your health intake and vitals. We assess multiple biomarkers, like weight, BMI, pulse rate, and blood pressure, which all have direct implications on your current and future health outlook.'
            },
            {
                name: 'Lifestyle',
                hoverText: 'This data is sourced from your health intake. Things like sleep, stress, mindfulness, alcohol intake, and smoking habits have direct implications on your current and future health outlook.'
            },
            {
                name: 'Risk scores',
                hoverText: 'This data is sourced from your health intake and vitals. Metabolic health and cardiovascular disease risk are among the considerations.'
            }
        ],
        generalScore: 0,
        labsScore: null,
        filteredGeneralScore: [],
        isCompletedQuestionnaires: false,
        isResultsFromLabs: false,
    } as HealthScoreComponentState);

    const history = useHistory();

    const handleComplete = () => {
        if(patientId) {
            navigationService.toPatientHealthForms(history, patientId) 
        } else {
            navigationService.toMyHealthForms(history)
        } 
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<HealthScoreModel>(healthScoreQuery.healthScore$, result => {

                if (result) {
                    if(result.patientScore.scoredTerms < 10){
                        setState(state => ({ ...state, isResultsFromLabs: false }))
                        return;
                    }

                    const filteredGeneralCatgory = result.categories.map(categori => {
                        return result.terms.filter(el => !!el.name && el.termCategory === categori.name)
                    })
                    const filteredLabCategory = filteredGeneralCatgory.find(el => el[0].termCategory === 'lab')

                    let labsTerms = [];

                    orderLabsTerms.map(x => 
                    {
                        const term = filteredLabCategory?.find(t => t.term === x);

                        if(term){
                            labsTerms.push(term);
                        }
                    });
                    
                    filteredLabCategory?.map(x => 
                    {
                        if(!orderLabsTerms.includes(x.term)){
                            labsTerms.push(x);
                        }
                    }
                    );

                    setState(state => ({
                        ...state,
                        generalScore: result.patientScore.score,
                        isResultsFromLabs: true,
                        labsScore: labsTerms.map(el => {
                            return el.displayValue === 0 ? {
                                ...el,
                                displayValue: 1.5,
                            } : el
                        }),
                        filteredGeneralScore: filteredGeneralCatgory.filter(el => el[0].termCategory !== 'lab')

                    }));
                }

            })
        ];

        const cb = () => setState(state => ({ ...state, isLoading: false }));

        if (patientId) {
            questionnaireService.getResults(patientId).subscribe(

                (result) => {
                    const isCompleted = !!result.filter(q => q.submittedAt && q.questionnaire.subType === QuestionnaireSubType.MedicalHistoryIncomplete).length
                    setState(state => ({
                        ...state,
                        isCompletedQuestionnaires: isCompleted
                    }));

                    if (isCompleted) {
                                healthScoreService.getByEmployee(patientId).subscribe(cb, cb)
                    } else {
                        cb()
                    }
                },

                cb
            )
        } else {
            questionnaireService.getAllMyResults().subscribe(

                (result) => {
                    const isCompleted = !!result.filter(q => q.submittedAt && q.questionnaire.subType === QuestionnaireSubType.MedicalHistoryIncomplete).length
                    setState(state => ({
                        ...state,
                        isCompletedQuestionnaires: isCompleted
                    }));

                    if (isCompleted) {
                        healthScoreService.getByPatient().subscribe(cb, cb)
                    } else {
                        cb()
                    }
                },

                cb
            )
        }

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [patientId]);

    return [state, handleComplete];
}