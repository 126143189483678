import { Box, Checkbox, CircularProgress, IconButton, TextField} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useCallback } from 'react';
import commonUseStyles from '../../styles/common.styles';
import debounce from 'lodash.debounce'
import { colors } from '../../constants/colors';
import { UserViewModel } from './SelectUserComponent';
import { useStyles } from './multiSelectUserComponent.styles';
import CloseIcon from '@material-ui/icons/Close';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

interface MultiSelectUserComponentProps {
    title: string;
    users: UserViewModel[];
    selectedUsersIds: number[];
    isLoading: boolean;
    handleUsersSelect: (users: number[]) => void;
    handleInputChange: (input: string) => void;
}

export const MultiSelectUserComponent: React.FC<MultiSelectUserComponentProps> = (props: MultiSelectUserComponentProps) => {
    const {
        title,
        handleUsersSelect,
        handleInputChange,
        users,
        selectedUsersIds,
        isLoading,
    } = props;

    const [state] = useState({inputText: ''});

    const commonClasses = commonUseStyles();
    const classes = useStyles();

    const debouncedHandleTextChange = useCallback(debounce(function() {
        handleInputChange(state.inputText)
    }, 1500), [])

    const handleTextChange = (text: string) => {
        state.inputText = text;
        debouncedHandleTextChange();
    }

    const displayName = (user: UserViewModel): string => {
        return `${user.firstName} ${user.lastName}`;
    }

    const renderOption = (user, { selected }) => {
        return (
            <>
                <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" className={classes.checkIcon} />}
                    checked={selectedUsersIds.includes(user.id)}
                />
                <Box ml={1}>
                    {displayName(user)}
                </Box>
            </>
        )
    }

    const renderInput = (params) => {
        return (
            <TextField
                {...params}
                margin="normal"
                variant="outlined"
                onChange={(e) => handleTextChange(e.currentTarget.value)}
                placeholder="Health Coach/Provider/Staff"
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (<>
                        {isLoading ? <CircularProgress style={{color: colors.main}} size={20} /> : null}
                        {params.InputProps.endAdornment}
                    </>),
                }}
            />
        )
    }

    const renderTag = (values: UserViewModel[], getTagProps: ({ index }: { index: number }) => {}) => {
        return (
            <>
            {
                values.map((user, index) => {
                    const props: any = getTagProps({index});
                    return (
                        <Box key={index} className={classes.selectedUser}>
                            <Box className={classes.selectedUserTitle}>
                                {displayName(user)}
                            </Box>
                            <IconButton
                                id={`multi-select-user-delete-${user.id}`}
                                onClick={() => props.onDelete()}
                                className={classes.removeSelectedUser}
                                size='small'
                            >
                                <CloseIcon style={{fontSize: 14}} />
                            </IconButton>
                        </Box>
                    )
                })
            }
            </>
        )
    }

    const maxItemsToDisplay = 2;

    return (
        <Box>
            <Box>
                <span className={clsx(commonClasses.size14, commonClasses.colorGray1)}>{title}</span>
            </Box>
            <Box>
                <Autocomplete
                    multiple
                    size='small'
                    options={users}
                    disableCloseOnSelect
                    limitTags={maxItemsToDisplay}
                    renderOption={renderOption}
                    renderTags={renderTag}
                    getOptionLabel={(user) => displayName(user)}
                    renderInput={(params) => renderInput(params)}
                    onChange={(e, users) => handleUsersSelect((users as UserViewModel[]).map(i => i.id))}
                />
            </Box>
        </Box>
    );
}