import { PracticeProfileModel } from "../modules/account/models/practiceProfile.model";
import { CreatedPatientModel } from "../modules/patients/models/patient.model";
import { RoutesConstants } from "../constants/route.constants";
import { ConversationType } from "../modules/conversations/models/conversation.models";
import { GoalsFilterState, NoteModel, NotesType } from "../modules/notes/models/notes.models";
import { patientProfileQuery, patientProfileStore } from "../modules/employee/stores/patientProfileStore";
import { authQuery } from "../modules/auth/stores/auth";
import {ExternalAuthProvider, PermissionType, UserType} from '../modules/auth/models/auth.enums';
import { MenuItemTypes } from "../models/menu.models";
import { notesStore } from "../modules/notes/stores/notes";
import {ReferralOrderModel} from "../modules/orders/models/referralOrders.models";
import {OtherOrderModel} from "../modules/orders/models/otherOrders.models";

export class NavigationService {

    public goUp(): void {
        window.scrollTo(0, 0);
    }

    public goDown(): void {
        window.scrollTo(0, document.body.scrollHeight);
    }

    public goBack(history): void {
        const pathHistory = patientProfileQuery.getPath();

        if (pathHistory.length > 0) {
            history.push(pathHistory);
            patientProfileStore.setPath("");
            this.goUp();
            return;
        }

        history.goBack();
        this.goUp();
    }

    public toDefault(history): void {
        history.push(RoutesConstants.default);
        this.goUp();
    }

    public toGetStarted(history): void {
        history.push(RoutesConstants.getStarted);
        this.goUp();
    }

    public toConsultGetStarted(history): void {
        history.push(RoutesConstants.consultationGetStarted);
        this.goUp();
    }

    public postLogoutRedirect(history): void {

        this.toLogin(history);
    }

    public toLogin(history, from?: string): void {
        history.push({
            pathname: RoutesConstants.login,
            state: {
                from: from,
            }
        });
        this.goUp();
    }

    public toThirdPartyIntegrationLoginPage(history, provider: ExternalAuthProvider): void {
        let redirectTo = history.location.pathname;
        if (history.location.search) {
            redirectTo += history.location.search;
        }

        history.push({
            pathname: RoutesConstants.thirdPartyIntegrationLogin.replace(':provider', ExternalAuthProvider[provider]),
            state: {
                from: redirectTo,
            }
        });
    }

    public toAuth2LoginPage(history, provider: ExternalAuthProvider): void {
        let redirectTo = history.location.pathname;
        if (history.location.search) {
            redirectTo += history.location.search;
        }

        history.push({
            pathname: RoutesConstants.auth2Login.replace(':provider', ExternalAuthProvider[provider]),
            state: {
                from: redirectTo,
            }
        });
    }

    public toRxNTLoginPage(history, patientId: number, from?: string): void {
        history.push({
            pathname: RoutesConstants.loginRxNT,
            state: {
                from: from,
                patientId: patientId
            }
        });
        this.goUp();
    }

    public toDashboard(history): void {
        history.push(RoutesConstants.dashboard);
        this.goUp();
    }

    public toHealthScore(history): void {
        history.push(RoutesConstants.healthScore);
        this.goUp();
    }

    public toHabitScore(history): void {
        history.push(RoutesConstants.habitScore);
        this.goUp();
    }

    public toMyPatients(history): void {
        history.push(RoutesConstants.myPatients);
        this.goUp();
    }

    public toManagePatients(history): void {
        history.push(RoutesConstants.managePatients);
        this.goUp();
    }

    public toIntakePatients(history): void {
        history.push(RoutesConstants.intakePatients);
        this.goUp();
    }

    public toShortcuts(history): void {
        history.push(RoutesConstants.shortcuts);
        this.goUp();
    }

    public toGeneral(history): void {
        history.push(RoutesConstants.generalInputs);
        this.goUp();
    }

    public toDocuments(history): void {
        history.push(RoutesConstants.documents);
        this.goUp();
    }

    public toLabs(history): void {
        history.push(RoutesConstants.labsInputs);
        this.goUp();
    }

    public toMicrobiome(history): void {
        history.push(RoutesConstants.microbiomeInputs);
        this.goUp();
    }

    public toDataFiles(history): void {
        history.push(RoutesConstants.dataFilesInputs);
        this.goUp();
    }

    public toPatientUpcommingAppointments(history): void {
        history.push(RoutesConstants.patientUpcommingAppointments);
        this.goUp();
    }

    public toPatientPastAppointments(history): void {
        history.push(RoutesConstants.patientPastAppointments);
        this.goUp();
    }

    public toPatientProfilePastAppointments(history, patientId: number){
        patientProfileStore.setPath(history.location.pathname);
        
        patientProfileStore.setTab(`${MenuItemTypes.Appointments}-parent-${MenuItemTypes.PastAppointments}`);
        history.push(`${RoutesConstants.managePatientProfile}/${patientId}`);
        this.goUp();
    }

    public toPatientNotes(history): void {
        history.push(RoutesConstants.patientNotes);
        this.goUp();
    }

    public toOrders(history): void {
        history.push(RoutesConstants.patientOrders);
        this.goUp();
    }

    public toPatientNote(history, noteId: number, noteType: NotesType) {
        history.push(`${RoutesConstants.patientNote}/${noteId}?noteType=${noteType}`);
        this.goUp();
    }

    public toPatientProfileNote(history, patientId: number, noteId?: number) {
        patientProfileStore.setPath(history.location.pathname);
        
        patientProfileStore.setTab(`${MenuItemTypes.Notes}`);
        if (noteId) {
            history.push({
                pathname: `${RoutesConstants.managePatientProfile}/${patientId}`,
                search: `${noteId}`,
            });
        } else {
            history.push(`${RoutesConstants.managePatientProfile}/${patientId}`);
        }
       
        this.goUp();
    }

    public toPatientInitialConsult(history, noteId: number): void {
        history.push(`${RoutesConstants.patientInitialConsult}/${noteId}`);
        this.goUp();
    }

    public toPatientFollowUp(history, noteId: number): void {
        history.push(`${RoutesConstants.patientFollowUp}/${noteId}`);
        this.goUp();
    }

    public toPatientBlank(history, noteId: number): void {
        history.push(`${RoutesConstants.patientBlank}/${noteId}`);
        this.goUp();
    }

    public toPatientHistoryAndPhysical(history, noteId: number): void {
        history.push(`${RoutesConstants.patientHistoryAndPhysical}/${noteId}`);
        this.goUp();
    }

    public toPatientHistoryAndPhysicalFollowup(history, noteId: number): void {
        history.push(`${RoutesConstants.patientHistoryAndPhysicalFollowup}/${noteId}`);
        this.goUp();
    }

    public toPatientHistoryAndPhysicalGroupVisit(history, noteId: number): void {
        history.push(`${RoutesConstants.patientHistoryAndPhysicalGroupVisit}/${noteId}`);
        this.goUp();
    }

    public toPatientSoap(history, noteId: number): void {
        history.push(`${RoutesConstants.patientSoap}/${noteId}`);
        this.goUp();
    }

    public toCreatePatientInitialConsult(history, noteId: number): void {
        history.push(`${RoutesConstants.createInitialConsultNote}/${noteId}`);
        this.goUp();
    }

    public toCreatePatientFollowUp(history, noteId: number): void {
        history.push(`${RoutesConstants.createFollowUpNote}/${noteId}`);
        this.goUp();
    }

    public toCreatePatientBlank(history, noteId: number): void {
        history.push(`${RoutesConstants.createSimpleNote}/${noteId}`);
        this.goUp();
    }

    public toEmployeeNotes(history): void {
        history.push(RoutesConstants.employeeNotes);
        this.goUp();
    }

    public toBuyNewSubscription(history): void {
        history.push(RoutesConstants.buyNewSubscription);
        this.goUp();
    }

    public toAccountMembershipActivation(history): void {
        history.push(RoutesConstants.accountMembershipActivation);
        this.goUp();
    }

    public toPaymentSuccess(history, patient: CreatedPatientModel): void {
        history.push({
            pathname: RoutesConstants.paymentSuccess,
            state: { patient: patient }
        });

        this.goUp();
    }

    public toMobilePaymentSuccess(history, patient: CreatedPatientModel): void {
        history.push({
            pathname: RoutesConstants.mobilePaymentSuccess,
            state: { patient: patient }
        });

        this.goUp();
    }

    public toJoinPracticeSuccess(history, patient: CreatedPatientModel, practice: PracticeProfileModel): void {
        history.push({
            pathname: RoutesConstants.joinPracticeSuccess,
            state: {
                patient: patient,
                practice: practice
            }
        });

        this.goUp();
    }

    public toBuySubscriptionSuccess(history, patient): void {
        history.push({
            pathname: RoutesConstants.buySubscriptionSuccess,
            state: { patient: patient }
        });

        this.goUp();
    }

    public toMobileBuySubscriptionSuccess(history, patient): void {
        history.push({
            pathname: RoutesConstants.mobileBuySubscriptionSuccess,
            state: { patient: patient }
        });

        this.goUp();
    }

    public toQuestionnaireCompleted(history, intakeId?: string): void {
        history.push({
            pathname: RoutesConstants.healthQuestionnaireCompleted,
            state: {
                intakeId: intakeId
            }
        });

        this.goUp();
    }

    public toQuestionnaire(history, intakeId: string): void {
        history.push(`${RoutesConstants.healthQuestionnaire}/${intakeId}`);
        this.goUp();
    }

    public toMyQuestionnaires(history): void {
        history.push(RoutesConstants.questionnaires);
        this.goUp();
    }

    public toStartedQuestionnaires(history, id: number): void {
        history.push(`${RoutesConstants.startQuestionnaire}/${id}`);
        this.goUp();
    }

    public toHealthSummary(history): void {
        history.push(RoutesConstants.healthSummary);
        this.goUp();
    }

    public toMyHealthForms(history): void {
        history.push(RoutesConstants.healthForms);
        this.goUp();
    }

    public toMyQuestionnaire(history, questionnaireResultId: number): void {
        history.push(`${RoutesConstants.questionnaire}/${questionnaireResultId}`);
        this.goUp();
    }

    public toMyHealthReport(history): void {
        history.push(RoutesConstants.healthReport);
        this.goUp();
    }

    public toAddOnReports(history): void {
        history.push(RoutesConstants.addOnReports);
        this.goUp();
    }

    public toMyHealthPlan(history, tab = GoalsFilterState.TopGoals): void {
        notesStore.setGoalsTab(tab)
        history.push(RoutesConstants.healthPlan);
        this.goUp();
    }

    public toPatientHealthPlan(history, patientId, tab = GoalsFilterState.TopGoals): void {
        notesStore.setGoalsTab(tab)
        patientProfileStore.setPath(history.location.pathname);

        patientProfileStore.setTab(`${MenuItemTypes.HealthPlan}`);
        history.push(`${RoutesConstants.managePatientProfile}/${patientId}`);
        this.goUp();
    }

    public toPatientConversation(history, patientId): void {
        patientProfileStore.setPath(history.location.pathname);

        patientProfileStore.setTab(`${MenuItemTypes.Messaging}`);
        history.push(`${RoutesConstants.managePatientProfile}/${patientId}`);
        this.goUp();
    }

    public toPatientDashboard(history, patientId): void {
        patientProfileStore.setPath(history.location.pathname);

        patientProfileStore.setTab(`${MenuItemTypes.Dashboard}`);
        history.push(`${RoutesConstants.managePatientProfile}/${patientId}`);
        this.goUp();
    }

    public toPatientHealthReport(history, patientId): void {
        patientProfileStore.setPath(history.location.pathname);

        patientProfileStore.setTab(`${MenuItemTypes.HealthReport}`);
        history.push(`${RoutesConstants.managePatientProfile}/${patientId}`);
        this.goUp();
    }

    public toPatientLabResults(history, patientId): void {
        patientProfileStore.setPath(history.location.pathname);

        patientProfileStore.setTab(`${MenuItemTypes.Results}-parent-${MenuItemTypes.LabsInputs}`);
        history.push(`${RoutesConstants.managePatientProfile}/${patientId}`);
        this.goUp();
    }

    public toPatientLabFile(history, patientId): void {
        patientProfileStore.setPath(history.location.pathname);

        patientProfileStore.setTab(`${MenuItemTypes.Results}-parent-${MenuItemTypes.DataFilesInputs}`);
        history.push(`${RoutesConstants.managePatientProfile}/${patientId}`);
        this.goUp();
    }

    public toPatientDocuments(history, patientId): void {
        patientProfileStore.setPath(history.location.pathname);

        patientProfileStore.setTab(`${MenuItemTypes.Questionnaire}-parent-${MenuItemTypes.Documents}`);
        history.push(`${RoutesConstants.managePatientProfile}/${patientId}`);
        this.goUp();
    }

    public toPatientUpcomingAppts(history, patientId): void {
        patientProfileStore.setPath(history.location.pathname);

        patientProfileStore.setTab(`${MenuItemTypes.Appointments}-parent-${MenuItemTypes.UpcommingAppointments}`);
        history.push(`${RoutesConstants.managePatientProfile}/${patientId}`);
        this.goUp();
    }
   
    public toPatientOrders(history, patientId, orderId?: number): void {
        patientProfileStore.setPath(history.location.pathname);

        patientProfileStore.setTab(`${MenuItemTypes.Orders}`);
        if (orderId) {
            history.push({
                pathname: `${RoutesConstants.managePatientProfile}/${patientId}`,
                search: `${orderId}`,
            });
        } else {
            history.push(`${RoutesConstants.managePatientProfile}/${patientId}`);
        }

        this.goUp();
    }

    public toOtherOrder(history, order: OtherOrderModel): void {
        history.push({
            pathname: `${RoutesConstants.otherOrder}`,
            state: {
                patientId: order.patientId,
                order: order
            }
        });
        this.goUp();
    }

    public toReferralOrder(history, order: ReferralOrderModel): void {
        history.push({
            pathname: `${RoutesConstants.referralOrder}`,
            state: {
                patientId: order.patientId,
                order: order
            }
        });
        this.goUp();
    }

    public toPatientOrdersNewTab(patientId: number): void {
        window.open(`${RoutesConstants.managePatientProfile}/${patientId}?tab=${MenuItemTypes.Orders}`, '_blank');
        this.goUp();
    }

    public toPatientQuestionnaireResult(patientId: number, resultId: number) {
        window.open(`${RoutesConstants.managePatientProfile}/${patientId}?tab=${MenuItemTypes.Questionnaire}-parent-${MenuItemTypes.HealthForms}&resultId=${resultId}`, '_blank');
    }

    public toPatientHealthForms(history, patientId: number){
        patientProfileStore.setPath(history.location.pathname);
        
        patientProfileStore.setTab(`${MenuItemTypes.Questionnaire}-parent-${MenuItemTypes.HealthForms}`);
        history.push(`${RoutesConstants.managePatientProfile}/${patientId}`);
        this.goUp();
    }

    public toQuestionnaireResult(history, patientId: number): void {
        history.push(`${RoutesConstants.healthQuestionnaireResult}/${patientId}`);
        this.goUp();
    }

    public toPatientQuestionnaireVisitPrep(history, patientId): void {
        patientProfileStore.setPath(history.location.pathname);

        patientProfileStore.setTab(`${MenuItemTypes.Questionnaire}-parent-${MenuItemTypes.VisitPrep}`);
        history.push(`${RoutesConstants.managePatientProfile}/${patientId}`);
        this.goUp();
    }

    public toForgotPassword(history): void {
        history.push(RoutesConstants.forgotPassword);
        this.goUp();
    }

    public toSubmitPatient(history, patientId: number): void {
        history.push(`${RoutesConstants.submitPatient}/${patientId}`);
        this.goUp();
    }

    public toManagePatientProfile(history, patientId: number) {
        patientProfileStore.setPath(history.location.pathname);

        history.push(`${RoutesConstants.managePatientProfile}/${patientId}`);
        this.goUp();
    }

    public toNewTabManagePatientProfile(patientId: number) {
        window.open(`${RoutesConstants.managePatientProfile}/${patientId}`, '_blank');
    }

    public toPatientsSupplements(history): void {
        history.push(RoutesConstants.supplements);
        this.goUp();
    }

    public toPatientsProfileSupplements(history, patientId): void {
        patientProfileStore.setPath(history.location.pathname);

        patientProfileStore.setTab(`${MenuItemTypes.Supplements}`);
        history.push(`${RoutesConstants.managePatientProfile}/${patientId}`);
        this.goUp();
    }

    public toCoachAppointments(history): void {
        history.push(RoutesConstants.coachAppointments);
        this.goUp();
    }

    public toManageEmployees(history): void {
        history.push(RoutesConstants.manageEmployees);
        this.goUp();
    }

    public toResetPassword(history): void {
        history.push(RoutesConstants.resetPassword);
        this.goUp();
    }

    public toMyProfile(history): void {
        history.push(RoutesConstants.accountProfile);
        this.goUp();
    }

    public toMyMembership(history): void {
        history.push(RoutesConstants.accountMembership);
        this.goUp();
    }

    public toSignOffNote(history, noteId): void {
        history.push(`${RoutesConstants.signOffNote}/${noteId}`);
        this.goUp();
    }

    public toCreateInitialConsultNote(history, patientId: number, appointmentId: number | null = null, note: NoteModel = null, fromDraftNotePage: boolean = false): void {

        history.push({
            pathname: `${RoutesConstants.createInitialConsultNote}/${patientId}`,
            state: {
                appointmentId: appointmentId,
                note: note,
                fromDraftNotePage: fromDraftNotePage
            }
        });
        this.goUp();
    }

    public toCreateFollowUpNote(history, patientId: number, appointmentId: number | null = null, note: NoteModel = null, fromDraftNotePage: boolean = false): void {
        history.push({
            pathname: `${RoutesConstants.createFollowUpNote}/${patientId}`,
            state: {
                appointmentId: appointmentId,
                note: note,
                fromDraftNotePage: fromDraftNotePage
            }
        });
        this.goUp();
    }

    public toCreateSimpleNote(history, type: NotesType, patientId: number, appointmentId: number | null = null, note: NoteModel = null, fromDraftNotePage: boolean = false): void {
        history.push({
            pathname: `${RoutesConstants.createSimpleNote}/${type}/${patientId}`,
            state: {
                appointmentId: appointmentId,
                note: note,
                fromDraftNotePage: fromDraftNotePage
            }
        });
        this.goUp();
    }

    public toCreateSoapNote(history, patientId: number, appointmentId: number | null = null, note: NoteModel = null, fromDraftNotePage: boolean = false): void {

        history.push({
            pathname: `${RoutesConstants.createSoapNote}/${patientId}`,
            state: {
                appointmentId: appointmentId,
                note: note,
                fromDraftNotePage: fromDraftNotePage
            }
        });
        this.goUp();
    }

    public toCreateHistoryAndPhysicalNote(history, patientId: number, appointmentId: number | null = null, note: NoteModel = null, fromDraftNotePage: boolean = false): void {

        history.push({
            pathname: `${RoutesConstants.createHistoryAndPhysicalNote}/${patientId}`,
            state: {
                appointmentId: appointmentId,
                note: note,
                fromDraftNotePage: fromDraftNotePage
            }
        });
        this.goUp();
    }

    public toCreateHistoryAndPhysicalFollowUpNote(history, patientId: number, appointmentId: number | null = null, note: NoteModel = null, fromDraftNotePage: boolean = false): void {

        history.push({
            pathname: `${RoutesConstants.createHistoryAndPhysicalFollowUpNote}/${patientId}`,
            state: {
                appointmentId: appointmentId,
                note: note,
                fromDraftNotePage: fromDraftNotePage
            }
        });
        this.goUp();
    }

    public toCreateHistoryAndPhysicalGroupVisitNote(history, patientId: number, appointmentId: number | null = null, note: NoteModel = null, fromDraftNotePage: boolean = false): void {

        history.push({
            pathname: `${RoutesConstants.createHistoryAndPhysicalGroupVisitNote}/${patientId}`,
            state: {
                appointmentId: appointmentId,
                note: note,
                fromDraftNotePage: fromDraftNotePage
            }
        });
        this.goUp();
    }

    public toDNAFiles(history): void {
        history.push(RoutesConstants.dnafilesMonitor);
        this.goUp();
    }

    public toDNAOrderDropship(history): void {
        history.push(RoutesConstants.dnaOrderDropship);
        this.goUp();
    }
  
    public toUnmatchedLabs(history): void {
        history.push(RoutesConstants.unmatchedLabs);
        this.goUp();
    }

    public toPrivacyPolicy(): void {
        window.open(RoutesConstants.wildHealthPrivacyPolicy, '_blank');
    }

    public toTermsOfUse(): void {
        window.open(RoutesConstants.wildHealthTermsOfUse, '_blank');
    }

    public toTermsOfService(): void {
        window.open(RoutesConstants.wildHealthTermsOfService, '_blank');
    }

    public toNoticeOfPrivacy(): void {
        window.open(RoutesConstants.wildHealthNoticeOfPrivacy, '_blank');
    }

    public toCancellationForm(): void {
        window.open(RoutesConstants.cancellationForm, '_blank');
    }

    public toPhysicianPairingQuizForm() {
        window.open(RoutesConstants.physicianPairingQuizForm, '_blank');
    }

    public toLeadSources(history): void {
        history.push(RoutesConstants.leadSources);
        this.goUp();
    }

    public toFellowshipSubmissionReceived(history): void {
        history.push(RoutesConstants.fellowshipSubmissionReceived);
        this.goUp();
    }

    public toVitalsHistory(history, patientId?: number, isDataSetDialogOpen?: boolean): void {
        patientProfileStore.setPath(history.location.pathname);
        history.push({
            pathname: RoutesConstants.vitalsHistory,
            state: {
                isDataSetDialogOpen: isDataSetDialogOpen,
                patientId: patientId,
            }
        });
        this.goUp();
    }

    public toConversations(history, conversationId?: number): void {
        if (authQuery.getType() === UserType.Employee) {
            if (authQuery.hasPermission(PermissionType.Conversations)) {
                history.push({
                    pathname: RoutesConstants.employeeConversations,
                    state: {
                        conversationId: conversationId,
                    }
                });
            }
            else if (authQuery.hasPermission(PermissionType.ManagePatients)) {
                history.push(RoutesConstants.managePatients);
            }
            else if (authQuery.hasPermission(PermissionType.ManageEmployees)) {
                history.push(RoutesConstants.manageEmployees);
            } else {
                history.push(RoutesConstants.accountMembership);
            }
        } else {
            history.push({
                pathname: RoutesConstants.conversations,
                state: {
                    conversationId: conversationId,
                }
            });
        }
        this.goUp();
    }

    public toPatientsSubmissions(history): void {
        history.push(RoutesConstants.patientsSubmissions);
        this.goUp();
    }

    public toChat(history, conversationType: ConversationType): void {
        history.push(`${RoutesConstants.chat}/${conversationType}`);
        this.goUp();
    }

    public toMessageSettings(history): void {
        history.push(RoutesConstants.messageSettings);
        this.goUp();
    }

    public toGenericPlayground(history): void {
        history.push(RoutesConstants.genericPlayground);
        this.goUp();
    }

    public toDraftNotes(history): void {
        history.push(RoutesConstants.draftNotes);
        this.goUp();
    }

    public toSignOffNotes(history): void {
        history.push(RoutesConstants.signOffNotes);
        this.goUp();
    }

    public toAwaitingApproval(history): void {
        history.push(RoutesConstants.awaitingApproval);
        this.goUp();
    }

    public toFellowship(history, id): void {
        history.push({
            pathname: RoutesConstants.fellowship,
            state: {
                id: id
            }
        });
        this.goUp();
    }

    public toFellowPatients(history, fellowId: number, rosterId: number): void {
        history.push({
            pathname: `${RoutesConstants.fellowPatients}/${fellowId}`,
            state: {
                id: rosterId
            }
        });
        this.goUp();
    }

    public toInsurance(history): void {
        history.push(RoutesConstants.accountInsurance);
        this.goUp();
    }

    public toPatientCourseHistory(history): void {
        history.push(RoutesConstants.patientCourseHistory);
        this.goUp();
    }

    public toNewTabNorthPassAcademy() {
        window.open(RoutesConstants.northPassAcademy, '_blank');
        this.goUp();
    }

    public toWildHealth(): void {
        window.location.href = RoutesConstants.wildHealthDomain;
    }

    public toNewTabFromLinkUrl(linkUrl: string) {
        window.open(`${linkUrl}`, '_blank');
        this.goUp();
    }

    public toReferralLanding(history): void {
        history.push(RoutesConstants.referralLanding);
        this.goUp();
    }

    public toLinkFromMyJourney(path): void {
        window.open(`${path}`);
    }

    public toEngineeringAdminTool(history): void {
        history.push(RoutesConstants.engineeringAdminTool);
        this.goUp();
    }

    public toEmployerAdmin(history): void {
        history.push(RoutesConstants.employerAdmin);
        this.goUp();
    }

    public toSyncRecordDashboard(history): void {
        history.push(RoutesConstants.syncRecords);
        this.goUp();
    }

    public toSyncRecordType(history, typeName: string, practiceId: string): void {
        history.push(`${RoutesConstants.syncRecordType}/${typeName}/${practiceId}`);
        this.goUp();
    }

    public toTargetSyncRecordList(history, statusId: number, showSearchResult: boolean = false): void {
        history.push({
            pathname: `${RoutesConstants.syncRecordsList}/${statusId}`,
            state: {
                showSearchResult: showSearchResult
            }
        });
        this.goUp();
    }

    public toSyncRecordReconcile(history, syncRecordId: number): void {
        history.push(`${RoutesConstants.syncRecordsReconcile}/${syncRecordId}`);
        this.goUp();
    }

    public toEligibleInsurances(history): void {
        history.push(RoutesConstants.manageInsurances);
        this.goUp();
    }

    public toInsuranceConfigurations(history): void {
        history.push(RoutesConstants.insuranceConfigurations);
        this.goUp();
    }

    public toInsuranceConfigurationEdit(history, configurationId: number): void {
        history.push(`${RoutesConstants.insuranceConfigurations}/${configurationId}`);
        this.goUp();
    }

    public toManagePromoCodes(history): void {
        history.push(RoutesConstants.managePromoCodes);
        this.goUp();
    }
    
    public toManageMessaging(history): void {
        history.push(RoutesConstants.manageMessaging);
        this.goUp();
    }  
    
    public toMessageForwarding(history): void {
        history.push(RoutesConstants.messageForwarding);
        this.goUp();
    }

    public toAlertsHistory(history): void {
        history.push(RoutesConstants.alertsHistory);
        this.goUp();
    }

    public toPatientResponsibility(history): void {
        history.push(RoutesConstants.patientResponsibility);
        this.goUp();
    }

    public toManageBanners(history): void {
        history.push(RoutesConstants.manageBanners);
        this.goUp();
    }

    public toHealthReportTemplates(history): void {
        history.push(RoutesConstants.healthReportTemplates);
        this.goUp();
    }

    public toCommonGoals(history): void {
        history.push(RoutesConstants.manageCommonGoals);
        this.goUp();
    }

    public toCommonGoalEdit(history, goalId: number): void {
        history.push(`${RoutesConstants.manageCommonGoals}/${goalId}`);
        this.goUp();
    }

    public toCommonMdms(history): void {
        history.push(RoutesConstants.manageCommonMdms);
        this.goUp();
    }

    public toCommonMdmEdit(history, mdmId: number): void {
        history.push(`${RoutesConstants.manageCommonMdms}/${mdmId}`);
        this.goUp();
    }

    public toCommonSupplements(history): void {
        history.push(RoutesConstants.manageCommonSupplements);
        this.goUp();
    }

    public toCommonOrders(history): void {
        history.push(RoutesConstants.manageCommonOrders);
        this.goUp();
    }

    public toRecommendations(history): void {
        history.push(RoutesConstants.manageRecommendations);
        this.goUp();
    }

    public toOpsAdminTools(history, patientId?: number): void {
        history.push({
            pathname: RoutesConstants.opsAdminTools,
            state: {
                patientId: patientId,
            }
        });
        this.goUp();
    }

    public toPatientJourneyTasks(history, patientId?: number, selectedTab?: number): void {
        patientProfileStore.setPath(history.location.pathname);
        history.push({
            pathname: RoutesConstants.patientJourneyTasks,
            state: {
                patientId: patientId,
                selectedTab: selectedTab
            }
        });
        this.goUp();
    }
    
    public toKBDocuments(history): void {
        history.push(RoutesConstants.kbDocuments);
        this.goUp();
    }

    public toManageConversationTemplates(history): void {
        history.push(RoutesConstants.manageConversationTemplates);
        this.goUp();
    }

    public toManageEmployersProfiles(history): void {
        history.push(RoutesConstants.manageEmployersProfiles);
        this.goUp();
    }

    public toEmployerProfileDetail(history, productId: number): void {
        history.push(`${RoutesConstants.manageEmployersProfiles}/${productId}`);
        this.goUp();
    }
}

export const navigationService = new NavigationService();