import React from 'react';
import { useFacade } from "./practicumPatientsComponent.hooks"
import { Box, IconButton, Table, TableBody, TableContainer, TableHead, FormControlLabel, Checkbox } from '@material-ui/core';
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { displayEnrollment } from "../../helpers/displayEnrollment";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import moment from "moment";
import { renderEmptyValue } from '../../../common/helpers/empty-value';
import { SortingDirection } from '../../../common/sorting/models/sorting-destination';
import { SortingSource } from '../../models/fellowship.enums';
import { WildHealhTableSortLabel } from '../../../common/components/wildHealthTableSortLabel/WildHealthTableSortLabel';
import clsx from 'clsx';
import commonUseStyles from '../../../common/styles/common.styles';
import { ReactComponent as Note } from '@img/icons/MedicalHistory.svg';
import { colors } from '../../../../modules/common/constants/colors';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

interface PracticumPatientsComponentProps {
    rosterId: number,
}

export const PracticumPatientsComponent: React.FC<PracticumPatientsComponentProps> = (props: PracticumPatientsComponentProps) => {
    const { rosterId } = props;
    const [
        {
            practicumPatients,
            isLoading,
            sortingColumns,
            sortingSource,
            hideTestPatients
        },
        handleOpenNote,
        handleSorting,
        handleToggleTestPatients
    ] = useFacade(rosterId);

    const commonClasses = commonUseStyles();

    const enrolledColumn = sortingColumns.find(x => x.source === SortingSource.Enrollment);

    const label = <Box
        ml={1}
        component="div"
        display="flex"
        className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorBlack)}>
        <span>Hide Test Patients</span>
    </Box>;
    const controlIcon = <CheckBoxOutlineBlankIcon style={{ fill: colors.mediumGray }} />;
    const controlCheckedIcon = <CheckBoxIcon style={{ fill: colors.accent1 }} />;
    const control = (
        <Checkbox
            icon={controlIcon}
            color="primary"
            checkedIcon={controlCheckedIcon}
            name={`Hide-Test-Patients-CheckBox`}
            checked={hideTestPatients}
            onChange={(e) => handleToggleTestPatients(e.target.checked)}
        />
    );

    const renderContent = (): JSX.Element => {
        if (isLoading) {
            return (<WildHealthLinearProgress />)
        }

        if (!practicumPatients.length) {
            return (<WildHealthPlaceHolder message="No data." />)
        }

        return (
            <Box>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box>
                        <span className={clsx(commonClasses.size20, commonClasses.textMedium)}>Practicum Patients</span>
                    </Box>
                    <Box display="flex " alignItems="center">
                        <FormControlLabel control={control} label={label} />
                    </Box>
                </Box>
                <Box mt={2}>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <WildHealthTableRow>
                                    <WildHealthTableCell>Patient Name</WildHealthTableCell>
                                    <WildHealthTableCell>Fellow Name</WildHealthTableCell>
                                    <WildHealthTableCell>Date of Birth</WildHealthTableCell>
                                    <WildHealthTableCell>Phone Number</WildHealthTableCell>
                                    <WildHealthTableCell>Email</WildHealthTableCell>
                                    <WildHealthTableCell>Date of Submission</WildHealthTableCell>
                                    <WildHealthTableCell>
                                        <WildHealhTableSortLabel
                                            id={`practicum-patients-${enrolledColumn.title}`}
                                            isActive={sortingSource === SortingSource.Enrollment}
                                            sortingDirection={sortingSource === enrolledColumn.source ? enrolledColumn.direction : SortingDirection.Asc}
                                            onClick={() => handleSorting(enrolledColumn.source, enrolledColumn.direction)}
                                            title={enrolledColumn.title}
                                        />
                                    </WildHealthTableCell>
                                    <WildHealthTableCell>Note</WildHealthTableCell>
                                </WildHealthTableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    practicumPatients.map((patient, index) =>
                                        <WildHealthTableRow key={index}>
                                            <WildHealthTableCell>{`${patient.firstName} ${patient.lastName}`}</WildHealthTableCell>
                                            <WildHealthTableCell>{`${patient.fellow?.firstName} ${patient.fellow?.lastName}`}</WildHealthTableCell>
                                            <WildHealthTableCell>{moment(patient.birthday).format("MM/DD/yyyy")}</WildHealthTableCell>
                                            <WildHealthTableCell>{patient.phoneNumber && patient.phoneNumber.length ? patient.phoneNumber : renderEmptyValue("empty")}</WildHealthTableCell>
                                            <WildHealthTableCell>{patient.email}</WildHealthTableCell>
                                            <WildHealthTableCell>{moment(patient.registrationDate).format("MM/DD/yyyy")}</WildHealthTableCell>
                                            <WildHealthTableCell>{displayEnrollment(patient.isEnrolled)}</WildHealthTableCell>
                                            <WildHealthTableCell>
                                                <IconButton id={`practicum-patients-note`} onClick={() => handleOpenNote(patient.id, patient.note)} >
                                                    <Note fill={colors.main} />
                                                </IconButton>
                                            </WildHealthTableCell>
                                        </WildHealthTableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        )
    }

    return (
        <Box p="30px">
            {
                renderContent()
            }
        </Box>
    )
}