import { Box, Dialog, DialogContent } from "@material-ui/core";
import clsx from "clsx";
import moment from "moment";
import React from "react";
import { WildHealthButton } from "../wildHealthButton/WildHealthButton";
import commonUseStyles from "../../../common/styles/common.styles";
import { DialogComponentProps } from "../../dialogs/dialogProps";
import { DialogTitle } from "../dialogTitle/DialogTitle";
import { WildHealthDatePicker } from "../WildHealthDatePicker";
import { useFacade } from "./selectDataDialogComponent.hooks";
import { Observable } from "rxjs";
import { IOSCalender } from "./IOSCalender";
import { isIOS } from "react-device-detect";
import { useStyles } from "./SelectDataDialogComponent.styles";

interface SelectDataDialogComponentProps extends DialogComponentProps {
  title: string;
  message: string;
  actionName: string;
  disableDates: Date[];
  disablePast?: boolean;
  disableFuture?: boolean;
  handleCheckDate?: (date: Date) => Observable<void>;
}

export const SelectDataDialogComponent: React.FC<SelectDataDialogComponentProps> = (
  props: SelectDataDialogComponentProps
) => {
  const {
    open,
    title,
    message,
    actionName,
    handleSubmit,
    handleClose,
    disableDates,
    disablePast,
    disableFuture,
    handleCheckDate,
  } = props;

  const [
    { selectedDate, canSubmit, errors },
    isDateInDisabledRange,
    handleDateChanges,
    handleCloseDialog,
    handleSubmitForm,
  ] = useFacade(open, handleSubmit, handleClose, disableDates, handleCheckDate);

  const commonClasses = commonUseStyles();
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={() => handleCloseDialog()}
      aria-labelledby='form-dialog-title'
    >
      <DialogTitle
        id='dialog-title'
        onClose={() => handleCloseDialog()}
        classes={{ title: classes.dialogTitle }}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => handleSubmitForm(e)}>
          {isIOS ? (
            <IOSCalender handleDateChanges={handleDateChanges} />
          ) : (
            <Box py={1}>
              <Box>
                <span className={clsx(commonClasses.size14, commonClasses.colorGray1)}>
                  {message}
                </span>
              </Box>
              <Box mt={2}>
                <WildHealthDatePicker
                  fullWidth
                  disablePast={disablePast}
                  disableFuture={disableFuture}
                  placeholder='MMM DD, yyyy'
                  openTo='year'
                  format='MMM DD, yyyy'
                  inputProps={{ disabled: true }}
                  inputVariant='outlined'
                  error={!!errors["selectedDate"]}
                  helperText={errors["selectedDate"]}
                  value={selectedDate}
                  onChange={(momentDate) => handleDateChanges(momentDate?.toDate())}
                  shouldDisableDate={(day: moment.Moment) => isDateInDisabledRange(day)}
                  useKeyboardDatePicker
                  name='selectedDate'
                />
              </Box>
            </Box>
          )}

          <Box display='flex' justifyContent='flex-end' py={2}>
            <Box>
              <WildHealthButton
                id='select-data-cancel'
                color='tertiary'
                onClick={() => handleCloseDialog()}
              >
                Cancel
              </WildHealthButton>
            </Box>
            <Box ml={2}>
              <WildHealthButton
                id={`select-data-submit-${actionName}`}
                onClick={(e) => handleSubmitForm(e)}
                disabled={!canSubmit}
                type='submit'
              >
                {actionName}
              </WildHealthButton>
            </Box>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};
