import { Box, useTheme, useMediaQuery } from '@material-ui/core';
import clsx from "clsx";
import React from 'react';
import commonUseStyles from "../../common/styles/common.styles";
import { HealthReportMode, HealthReportSections } from "../models/healthReport.models";
import { HealthReportBasePage, HealthReportMobileBasePage } from "./HealthReportBasePage";
import { HealthReportPageProps } from "./healthReportPageProps";
import { RoutesConstants } from '../../../constants/route.constants';
import { ImageConstants } from '../../../constants/image.constants';
import img from "@img/HealthReport_Pg8_Image.png";

export const HealthReportExerciseAndRecovery0Page: React.FC<HealthReportPageProps> = (props: HealthReportPageProps) => {
    const { pageNumber, practice } = props;

    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        props.mode !== HealthReportMode.PrintMode && isSmallScreen ? (
            <HealthReportMobileBasePage section={HealthReportSections.ExerciseAndRecovery} pageNumber={pageNumber} practice={practice} firstPage>
                <Box px={2}>
                    <Box>
                        <span
                            className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeBigReport, commonClasses.textRegularReport)}>{'Personalized Exercise Plan'}</span>
                    </Box>
                    <Box mt={1} fontSize="14px" lineHeight="22px">
                        <>
                            <Box mt={1.5}>
                                <p>
                                    We use a combination of 3 major polygenic scores to help develop your personalized workout
                                    plan. These include Strength v Endurance, Recovery, and HIIT. Multiple genes are in play
                                    for each of these scores, so rather than go over each SNP individually, we will summarize
                                    them here. Specifics can be seen in the details section of the report.
                                </p>
                            </Box>
                            <Box mt="10px">
                                <p>
                                    Strength versus endurance is a simple measure of your potential. This does not mean that
                                    those with a genetic preference for endurance cannot perform strength activities, or that
                                    those with strength genetics cannot run marathons. It is simply a way of personalizing the
                                    optimal rep/weight schemes and regularity of exercise. Your genetics are always modifiable.
                                </p>
                            </Box>
                            <Box mt="10px">
                                <p>
                                    Recovery is simple, but there are a lot of genes at play. Some people can workout 7 days
                                    a week and perform at the top of their game, while others need days to recover between
                                    workouts. Although some of this is training related and thus epigenetic in nature,
                                    a great deal of your potential resides in your genes. We’ll review your genes and
                                    create a recovery score, and thus help dictate how often you should workout. This can
                                    be paired with objective recovery metrics such as heart rate variability and resting
                                    heart rate to optimize your exercise plan.
                                </p>
                            </Box>
                            <Box mt="10px">
                                <p>
                                    HIIT training is beneficial for just about everyone. But the intensity and volume of HIIT
                                    training can be optimized for your genetics. We’ll assess HIIT related genes
                                    and use those to build a report.
                                </p>
                            </Box>
                        </>
                    </Box>
                    <Box mt="30px">
                        <img src={RoutesConstants.cdnBaseLink + ImageConstants.healthReportPg8ImagePath} style={{ width: '100%' }} alt="img" />
                    </Box>
                </Box>
            </HealthReportMobileBasePage>
        ) : (
            <HealthReportBasePage section={HealthReportSections.ExerciseAndRecovery} pageNumber={pageNumber} practice={practice}>
                <Box>
                    <Box px={15} pt={5}>
                        <Box>
                            <span
                                className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeBigReport, commonClasses.textMediumReport)}>{'Personalized Exercise Plan'}</span>
                        </Box>
                        <Box mt={1}>
                            <>
                                <Box mt={1.5}>
                                    <p>
                                        We use a combination of 3 major polygenic scores to help develop your personalized workout
                                        plan. These include Strength v Endurance, Recovery, and HIIT. Multiple genes are in play
                                        for each of these scores, so rather than go over each SNP individually, we will summarize
                                        them here. Specifics can be seen in the details section of the report.
                                    </p>
                                </Box>
                                <Box mt={1.5}>
                                    <p>
                                        Strength versus endurance is a simple measure of your potential. This does not mean that
                                        those with a genetic preference for endurance cannot perform strength activities, or that
                                        those with strength genetics cannot run marathons. It is simply a way of personalizing the
                                        optimal rep/weight schemes and regularity of exercise. Your genetics are always modifiable.
                                    </p>
                                </Box>
                                <Box mt={1.5}>
                                    <p>
                                        Recovery is simple, but there are a lot of genes at play. Some people can workout 7 days
                                        a week and perform at the top of their game, while others need days to recover between
                                        workouts. Although some of this is training related and thus epigenetic in nature,
                                        a great deal of your potential resides in your genes. We’ll review your genes and
                                        create a recovery score, and thus help dictate how often you should workout. This can
                                        be paired with objective recovery metrics such as heart rate variability and resting
                                        heart rate to optimize your exercise plan.
                                    </p>
                                </Box>
                                <Box mt={1.5}>
                                    <p>
                                        HIIT training is beneficial for just about everyone. But the intensity and volume of HIIT
                                        training can be optimized for your genetics. We’ll assess HIIT related genes
                                        and use those to build a report.
                                    </p>
                                </Box>
                            </>
                        </Box>
                        <Box mt={10}>
                            {
                                props.mode === HealthReportMode.PrintMode
                                    ? <img src={img} style={{ width: '100%' }} alt="img" />
                                    : <img src={RoutesConstants.cdnBaseLink + ImageConstants.healthReportPg8ImagePath} style={{ width: '100%' }} alt="img" />
                            }
                        </Box>
                    </Box>
                </Box>
            </HealthReportBasePage>
        )
    )
}
