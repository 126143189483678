import { patientsSupplementsService } from '../../patientSupplements/services/patientSupplements.service'
import { PatientSupplementModel } from '../../patientSupplements/models/patientSupplementModel'
import { supplementsService } from '../../patientSupplements/services/supplements.service'
import { HealthSummaryDataModel } from '../models/healthSummary.models'
import { healthSummaryStore, HealthSummaryStore } from '../stores'
import { Observable } from 'rxjs'

export class HealthSummarySupplementsService {
    public mapKey = 'SUPPLEMENTS'

    constructor(private healthSummaryStore: HealthSummaryStore) {
    }

    /**
     * Returns patients health summary model
     */
    public get(patientId?: number): void {
        const method = patientId ? supplementsService.get(patientId) : patientsSupplementsService.get()

        method.subscribe((supplements) => {
            const supplementsData = supplements.map(x => ({ 
                key: `${this.mapKey}-${x.id}`, 
                name: x.name, 
                value: x.dosage}
            ))

            this.healthSummaryStore.sectionLoaded()
            this.healthSummaryStore.clearSection(this.mapKey)
            this.healthSummaryStore.setData(supplementsData) 
        })
    }

    /**
     * Returns patients health summary model
     */
    public create(patientId: number, model: HealthSummaryDataModel): Observable<any> {
        const payload = {name: model.name, dosage: model.value} as PatientSupplementModel

        return supplementsService.create(payload, patientId)
    }

    /**
     * Returns patients health summary model
     */
     public update(patientId: number, model: HealthSummaryDataModel): Observable<any> {
        const id = +model.key.replace(`${this.mapKey}-`, '')

        const payload = {
            id: id,
            name: model.name, 
            dosage: model.value
        } as PatientSupplementModel

        return supplementsService.edit(payload)
    }

    public delete(key: string): void{
        const id = +key.replace(`${this.mapKey}-`, '')
        
        supplementsService.delete(id).subscribe(() => {
            this.healthSummaryStore.delete(key)
        })
    }
}

export const healthSummarySupplementsService = new HealthSummarySupplementsService(healthSummaryStore)