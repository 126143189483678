import React from "react";
import {
    AuthenticatedLayoutComponent
} from "../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent";
import {TitlesService} from "../../../../constants/title.constants";
import {PagesNamesService} from "../../../../constants/pagesNames.constants";
import {MenuItemTypes} from "../../../../models/menu.models";
import {useFacade} from "./signOffNotesPage.hooks";
import {WildHealthTabControl} from "../../../common/components/wildHealthTabControl/WildHealthTabControl";
import {useStyles} from "../draftNotesPage/draftNotesPage.styles";
import {Box} from "@material-ui/core";
import {
    SelectShortcutComponent
} from "../../../healthReport/components/selectShortcutComponent/SelectShortcutComponent";
import {UserType} from "../../../auth/models/auth.enums";
import {ProtectedElement} from "../../../common/components/ProtectedElement";

export const SignOffNotesPage: React.FC = () => {
    const [
        tabItems
    ] = useFacade();

    const classes = useStyles();

    return (
        <AuthenticatedLayoutComponent
            title={TitlesService.draftNotesTitle}
            pageName={PagesNamesService.signOffNotes}
            selectedMenuItem={MenuItemTypes.SignOffNotes}
            hideBackground
        >
            <Box className={classes.sections}>
                <WildHealthTabControl items={tabItems} />
            </Box>
            <ProtectedElement
                element={<SelectShortcutComponent />}
                permissions={[]}
                userType={UserType.Employee}
            />
        </AuthenticatedLayoutComponent>
    )
}