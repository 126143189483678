import { createStyles, makeStyles } from "@material-ui/core/styles";
import { colors } from "../../../../../common/constants/colors";

export const useStyles = makeStyles(() =>
  createStyles({
    size14: {
      fontSize: 14,
    },
    button: {
      color: colors.main,
      fontSize: 14
    },
    buttonText: {
      marginLeft: '6px',
      textTransform: 'capitalize'
    },
    textGray: {
      color: colors.gray1,
    },
    breakWord: {
      wordBreak: 'break-word',
    },
    mobileApp: {
      color: colors.good,
      backgroundColor: `${colors.good}14`,
      margin: '16px -32px',
      padding: '10px 30px'
    },
    noMobileApp: {
      color: colors.gray1,
      backgroundColor: `${colors.gray1}14`,
      margin: '16px -32px',
      padding: '10px 30px'
    },
    avatar: {
      width: 46,
      height: 46,
    },
    space: {
      paddingRight: 8
    }
  })
);


