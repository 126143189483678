import { Box } from '@material-ui/core';
import commonUseStyles from '../../../common/styles/common.styles';
import React from 'react';
import clsx from 'clsx';
import { FeatureComponent } from '../../../common/components/featureFlags/FeatureComponent';
import { FeatureFlag } from '../../../common/components/featureFlags/featureFlags.models';
import {TimeZoneModel} from "../../../timezones/models/timezone.model";

interface SelectTimezoneComponentProps {
    timeZones: TimeZoneModel[],
    value: string,
    handleChange?: (value: string) => void,
    disabled?: boolean
}

export const SelectTimezoneComponent: React.FC<SelectTimezoneComponentProps> = (props: SelectTimezoneComponentProps) => {
    const {
        timeZones,
        value
    } = props;

    const commonClasses = commonUseStyles();

    return (
        <Box>
            <Box>
                <span className={clsx(commonClasses.size14, commonClasses.colorGray1)}>My Timezone</span>
            </Box>
                <FeatureComponent featureFlag={FeatureFlag.AppointmentsUseBrowserTimeZone}>
                    <span>{timeZones.find(x=> x.id === value)?.displayName}</span>
                </FeatureComponent>
        </Box>
    );
}