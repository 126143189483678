import React, {FC} from 'react';
import {Box, IconButton, makeStyles, Typography} from "@material-ui/core";
import {colors} from "../../../../common/constants/colors";
import {PaymentCouponModel} from "../../../models/paymentPrice.models";
import CloseIcon from "@material-ui/icons/Close";

interface HeaderCouponProps {
    coupon: PaymentCouponModel,
    handleClose:Function,
    bannerOpened:boolean
}

const useStyles = makeStyles({
    root: {
        backgroundColor: colors.good,
        position:"relative",
        zIndex:2000,
        maxHeight:50,
        width:"100%"
    },
    btnClose:{
        color:colors.white
    },
    span: {
        color:colors.white
    }
})
const HeaderCouponApplied: FC<HeaderCouponProps> = (props: HeaderCouponProps) => {


    const classes = useStyles()
    const {coupon, bannerOpened, handleClose} = props;
    const isCouponApplied = !!coupon;


    return (
        isCouponApplied &&
        bannerOpened ?
        <Box className={classes.root} p={1}  display="flex" alignItems= "center" >
            <Box p={1} flexGrow={1} textAlign="center">
                <Typography >
                    <span className={classes.span}>{coupon?.detail} discount has been applied!</span>
                </Typography>
            </Box>
            <Box position="absolute" right="8px">
                <IconButton className={classes.btnClose} onClick={()=>handleClose()}><CloseIcon /></IconButton>
            </Box>
        </Box>
        : null
    )
}

export default HeaderCouponApplied;