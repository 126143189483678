import { useState, useEffect } from "react";
import { Subscription } from "rxjs";
import { onEmit } from "../../../common/helpers/on-emit";
import { ChronologicalEventModel, ChronologicalEventType } from "../../models/patient.model";
import { patientsService } from "../../services/patients.service";
import { patientsQuery } from "../../stores/patientsStore";

interface ChronologicalSummaryFiltersState {
  isOpen: boolean;
  anchorEl: HTMLButtonElement;
  filters: ChronologicalEventType[];
}

interface ChronologicalSummaryComponentState {
  isTimelineOpen: boolean;
  chronologicalEvents: ChronologicalEventModel[];
  chronologicalEventsView: ChronologicalEventModel[];
  isLoading: boolean;
  filtersState: ChronologicalSummaryFiltersState;
}

/**
 * Custom Hook to manage a view Model for Biomarkers component
 */
export function useFacade(patientId: number | null): [
  ChronologicalSummaryComponentState,
  (event?: React.MouseEvent<HTMLButtonElement>) => void,
  (open: boolean) => void,
  () => void,
  () => void,
  (type: ChronologicalEventType) => void,
] {

  const [state, setState] = useState({
    chronologicalEvents: [],
    chronologicalEventsView: [],
    isTimelineOpen: false,
    isLoading: true,
    filtersState: {
      isOpen: false,
      anchorEl: null,
      filters: [],
    },
  } as ChronologicalSummaryComponentState);

  const handleToggleSideArea = (open: boolean) => {
    setState({ ...state, isTimelineOpen: open });
  }

  const handleToggleFilters = (event?: React.MouseEvent<HTMLButtonElement>) => {
    const anchorEl = event && event.currentTarget ? event.currentTarget : null;
    state.filtersState.isOpen = !state.filtersState.isOpen;
    state.filtersState.anchorEl = anchorEl;
    setState(state => ({ ...state }));
  }

  const handleClearFilters = () => {
    state.filtersState.isOpen = false;
    state.filtersState.anchorEl = null;
    state.filtersState.filters = [];
    state.chronologicalEventsView = [...state.chronologicalEvents]
    setState(state => ({ ...state }));
  }


  const handleApplyFilters = () => {
    if (!state.filtersState.filters.length) {
      handleClearFilters()
      return;
    }
    state.filtersState.isOpen = false;
    state.filtersState.anchorEl = null;
    state.chronologicalEventsView = state.chronologicalEvents.filter(el => state.filtersState.filters.includes(el.type))
    setState(state => ({ ...state }));
  }

  const handleAddFilterType = (type: ChronologicalEventType) => {

    if (state.filtersState.filters.includes(type)) {
      state.filtersState.filters = state.filtersState.filters.filter(el => el != type)
    } else {
      state.filtersState.filters.push(type)
    }

    setState(state => ({ ...state }));
  }

  useEffect(() => {
    setState(state => ({ ...state, isLoading: true }))

    const subscriptions: Subscription[] = [
      onEmit<ChronologicalEventModel[]>(patientsQuery.chronologicalEvents$, chronologicalEvents => {
        const sortedChronologicalEvents = []
        const ordersForSorting = []
        chronologicalEvents.forEach(el => {
          if (!ordersForSorting.includes(el.order)) {
            ordersForSorting.push(el.order)
          }
        })
        ordersForSorting.sort((el1, el2) => el2 - el1).forEach(el => {
          sortedChronologicalEvents.push(...chronologicalEvents.filter(event => event.order === el).sort((x, y) => (new Date(y.createdAt)).getTime() - (new Date(x.createdAt)).getTime()))
        })
        setState(state => ({
          ...state,
          chronologicalEvents: sortedChronologicalEvents,
          chronologicalEventsView: sortedChronologicalEvents,
          isLoading: false,
        }));

      }),
    ];

    const cb = () => setState(state => ({ ...state, isLoading: false }));

    patientsService.getPatientTimeline(patientId).subscribe(cb, cb)

    return () => {
      subscriptions.map((it) => it.unsubscribe());
    };
  }, [patientId]);

  return [
    state,
    handleToggleFilters,
    handleToggleSideArea,
    handleClearFilters,
    handleApplyFilters,
    handleAddFilterType,
  ];
}
