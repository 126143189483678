import { Badge } from "@material-ui/core";
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      minHeight: 387,
      overflow: "auto",
      [theme.breakpoints.down("xs")]: {
        minHeight: "auto",
        "& .MuiStepper-root": {
          padding: "16px 0 0 0",
        },
      },
    },
    step: {
      padding: 5,
    },
    stepBorder: {
      marginBottom: 16,
      borderBottom: `1px solid ${colors.separators}`,
    },
    stepSelected: {
      background: colors.lightGray,
    },
    subjectItem: {
      background: colors.gray3,
      marginBottom: "0.25rem",
    },
    statusDate: {
      fontWeight: "normal",
      fontSize: 14,
      paddingTop: 5,
      paddingRight: 5,
      color: colors.gray1,
    },
    divider: {
      backgroundColor: colors.stroke,
    },
    icon: {
      fill: colors.main,
    },
    closedIcon: {
      fill: colors.main,
      marginTop: 8,
      marginRight: 8
    },
    historyTitle: {
      fontFamily: "Be Vietnam Pro",
      fontWeight: 500,
      fontSize: 18,
      color: colors.black,
      paddingLeft: 24,
      [theme.breakpoints.down("xs")]: { fontSize: 16, paddingLeft: 0 },
    },
    activeIcon: {
      stroke: colors.main,
      height: 18,
      width: 18,
      marginTop: 8,
      marginRight: 8
    },
    stepLabelRoot: {
      alignItems: "initial"
    },
    historyButton: {
      width: "fit-content",
      cursor: "pointer",
      gridGap: 10,
      marginTop: 4
    }
  })
);

export const UnreadMessages = withStyles((theme: Theme) => ({
  root: {
    "& .MuiBadge-badge": {
      background: colors.main,
      color: colors.white,
    },
  },
}))(Badge);
