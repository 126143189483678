import {useEffect, useState} from "react";
import {Subscription} from "recompose";
import {onEmit} from "../../../common/helpers/on-emit";
import {ConversationModelBaseView, PatientConversationModel} from "../../models/conversation.models";
import {conversationsViewService} from "../../services/conversationsView.service";
import {patientConversationsQuery} from "../../stores/patientConversationsStore/patientConversations.query";
import {patientConversationsStore} from "../../stores/patientConversationsStore/patientConversations.store";
import {conversationsService} from "../../services/conversations.service";
import {
    StaffOrHealthCoachMessagesEnum
} from "../patientConversationsViewComponent/patientConversationsViewComponent.hooks";

export enum StaffChatState {
    History,
    Selected
}

interface StaffChatComponentState {
    targetConversation: PatientConversationModel;
    view: StaffChatState;
    isLoadingMessages: boolean;
    messagesStaff: ConversationModelBaseView[]
}


export function useFacade(): [
    StaffChatComponentState,
    (conversation: any) => void,
    () => void,
    (StaffOrHealthCoachMessagesEnum)=> void
] {
    const [state, setState] = useState({
        targetConversation: null,
        isLoadingMessages: false,
        view: StaffChatState.History,
        messagesStaff: null,
    } as StaffChatComponentState);

    const handleOpenConversation = (conversation) => {
        patientConversationsStore.setTargetConversation(conversation);
        setState(state => ({
            ...state,
            view: StaffChatState.Selected,
            isLoadingMessages: true,
            targetConversation: conversation
        }));
    }

    const handleNavigateToMessageHistory = () => {
        patientConversationsStore.setTargetConversation(null);
        setState(state => ({ ...state, view: StaffChatState.History }));
    }

    const handleDownloadMessages = (staffOrHealth: StaffOrHealthCoachMessagesEnum) => {
        conversationsService.downloadAllMessages(state.targetConversation,
            state.messagesStaff,
            StaffOrHealthCoachMessagesEnum.STAFF)
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<ConversationModelBaseView[]>(patientConversationsQuery.targetStaffConversation$, messages =>
                setState(state => ({ ...state, messagesStaff: messages }))
            ),
        ];

        if (state.targetConversation && state.targetConversation.vendorExternalId) {
            conversationsViewService.getPatientAllMessagesStaff(state.targetConversation.vendorExternalId).subscribe(() => {
                setState(state => ({ ...state, isLoadingMessages: false }))
            }, () => {
                setState(state => ({ ...state, isLoadingMessages: false }))
            });
        }

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [state.targetConversation]);

    return [
        state,
        handleOpenConversation,
        handleNavigateToMessageHistory,
        handleDownloadMessages
    ];
}
