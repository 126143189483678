import { useEffect, useState } from "react";
import { IErrorState } from "../../../common/validation/error-state";
import { BannerModel, BannerDisplayType } from "../../../../models/banner.model";
import { modifyBannerValidator } from "../manageBannersComponent/modifyBanner.validator";
import { bannersService } from "../../services/banners.service";

interface UpdateBannerComponentState extends IErrorState {
    banner: BannerModel;
    isLoading: boolean;
}

const initModel: BannerModel = {
    id: 0,
    name: '',
    copy: '',
    type: BannerDisplayType.All,
    isActive: true,
    priority: 0
};


export function useFacade(
    selectedBanner: BannerModel,
    isOpen: boolean,
    handleToggleDialog: (value: boolean) => void
): [
    UpdateBannerComponentState,
    (field: string, value: any) => void,
    () => void
] {
    const [state, setState] = useState({
        banner: initModel,
        errors: {},
        isLoading: false,
    } as UpdateBannerComponentState);

    const handleChanges = (field: string, value: any) => {
        modifyBannerValidator.validateAndSetState(state, setState, field, value);

        const banner = state.banner;

        banner[field] = value;

        setState({ ...state, banner });
    }

    const handleUpdate = () => {
        modifyBannerValidator.validateObjectAndSetState(state, setState, state.banner);
        if (!modifyBannerValidator.stateIsValid(state)) {
            return;
        }

        setState((state) => ({
            ...state,
            isLoading: true
        }));

        const cb = () => {
            setState((state) => ({
                ...state,
                errors: {},
                banner: Object.assign({}, initModel),
                isLoading: false
            }));

            handleToggleDialog(false);
        }

        bannersService.updateBanner(state.banner).subscribe(cb, cb);
    }

    const useEffectCB = () => {
        if (isOpen) {
            setState((state) => ({
                ...state,
                errors: {},
                banner: Object.assign({}, selectedBanner)
            }));
        }
    };

    useEffect(useEffectCB, [isOpen]);

    return [
        state,
        handleChanges,
        handleUpdate
    ];
}