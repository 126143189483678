import {
    Avatar,
    Box, Button,
    Collapse, List,
    ListItem,
    ListItemIcon,
    ListItemText, Popover,
    Table,
    TableBody, TableContainer,
    TableFooter,
    TableHead,
    TableSortLabel,
    Tooltip,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import moment from "moment";
import React from 'react';
import { SearchComponent } from '../../../common/components/searchComponent/SearchComponent';
import { WildHealthContainer } from '../../../common/components/wildHealthContainer/WildHealthContainer';
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { WildHealthLinkButton } from '../../../common/components/WildHealthLinkButton';
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { PaginationComponent } from "../../../common/pagination/components/paginationComponent/PaginationComponent";
import { pageSizes } from "../../../common/pagination/models/page-sizes";
import { useFacade } from './manageAllPatientsComponent.hooks';
import { useStyles } from "./manageAllPatientsComponent.styles";
import commonUseStyles from '../../../common/styles/common.styles';
import { toCurrentTimeZone } from '../../../timezones/helpers/timezone';
import { displayLocation } from "../../../locations/helpers/displayLocation";
import { EmployeeType } from '../../models/employee.enums';
import { authQuery } from '../../../auth/stores/auth';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { navigationService } from '../../../../services/navigation.service';
import { useHistory } from 'react-router';
import { FeatureComponent } from '../../../common/components/featureFlags/FeatureComponent';
import { FeatureFlag } from '../../../common/components/featureFlags/featureFlags.models';
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { displayShortName } from '../../../conversations/helpers/messages.converter';

const filtersPopoverProps: any = {
    id: "notificationsPopover",
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
    },
    transformOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
    },
};

export const ManageAllPatientsComponent: React.FC = () => {

    const classes = useStyles();
    const commonClasses = commonUseStyles();

    const history = useHistory();

    const [
        {
            isLoading,
            patients,
            filtersState,
            totalCount,
            sortingColumns,
            sortingSource,
            pageSize,
            selectedPage
        },
        handleToggleFilters,
        handleToggleAddOns,
        handleToggleState,
        handleTogglePlans,
        handleToggleProviders,
        handleToggleCoaches,
        handleTogglePods,
        handleApplyFilters,
        handleSorting,
        handleSearch,
        handleSearchState,
        handleSearchQueryChanges,
        handlePageSizeChange,
        getAllAvailablePages,
        handlePageChange,
        handleOnPaymentChanges,
        handleOnPodChanges,
        handleProviderChanges,
        handleCoachChanges,
        handleOnStateChanges,
        handleClearFilters,
        handleOnAddOnsChanges,
        handleSearchPlan,
        handleSelectAllState,
        handleSelectAllAddOns,
    ] = useFacade();

    const cutLongText = (text: string): string => {
        return text.length > 20 ? `${text.slice(0, 20)}...` : text
    }

    const emptyPlaceholder = () => {
        return <span style={{ color: '#999999' }}>-</span>
    }

    const emptyAvaPlaceholder = () => {
        return <Box className={classes.avatarEmpty}>-</Box>
    }

    const renderEmployeesName = (employees) => {
        return employees.map(x => `${x.firstName} ${x.lastName}`).join(', ')
    }

    const renderAvatar = (employees, type: number) => {
        if (employees.length === 1) {
            return <Tooltip placement="top" arrow title={`${employees[0].firstName} ${employees[0].lastName}`}>
                <Avatar variant='circle' src={''} className={type === EmployeeType.HealthCoach ? classes.avatarCoaches : classes.avatarProvider}>
                    {displayShortName(employees[0].firstName, employees[0].lastName)}
                </Avatar>
            </Tooltip>
        }

        if (employees.length > 1) {
            return <Tooltip placement="top" arrow title={renderEmployeesName(employees)}>
                <Avatar variant='circle' src={''} className={type === EmployeeType.HealthCoach ? classes.avatarCoaches : classes.avatarProvider}>
                    {employees.length}
                </Avatar>
            </Tooltip>
        }

        return emptyAvaPlaceholder();
    }

    const renderTable = () => {
        if (isLoading) {
            return <WildHealthLinearProgress />
        }

        if (!patients || !patients.length) {
            return <WildHealthPlaceHolder message="No current records found" />
        }

        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <WildHealthTableRow>
                            {
                                sortingColumns.map((item, index) =>
                                    <WildHealthTableCell style={{ width: '16%' }} key={index}>
                                        {item.source
                                            ? <TableSortLabel
                                                id={`manage-all-patients-${item.title}`}
                                                active={sortingSource === item.source}
                                                direction={sortingSource === item.source ? item.direction : 'asc'}
                                                onClick={() => handleSorting(item.source, item.direction)}
                                            >
                                                {item.title}
                                            </TableSortLabel>
                                            : item.title
                                        }
                                    </WildHealthTableCell>
                                )
                            }
                        </WildHealthTableRow>
                    </TableHead>
                    <TableBody>
                        {
                            patients.map((patient, index) =>
                                <WildHealthTableRow key={index}>
                                    <WildHealthTableCell>
                                        <WildHealthLinkButton
                                            id={`manage-all-patients-${patient.id}`}
                                            onClick={() => navigationService.toManagePatientProfile(history, patient.id)}
                                            content={patient.name}
                                        />
                                    </WildHealthTableCell>
                                    <WildHealthTableCell> {moment(toCurrentTimeZone(new Date(patient.joinedPractice))).format('MM/DD/YYYY')}</WildHealthTableCell>
                                    {!authQuery.isLicensingPractice() &&
                                        <WildHealthTableCell>
                                            {
                                                patient.plan ?? emptyPlaceholder()
                                            }
                                        </WildHealthTableCell>
                                    }
                                    <WildHealthTableCell>
                                        {renderAvatar(patient.providers, EmployeeType.Provider)}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell>
                                        {renderAvatar(patient.coaches, EmployeeType.HealthCoach)}
                                    </WildHealthTableCell>
                                    <Tooltip placement="top" arrow title={patient.orderTypes.join(', ')}>
                                        <WildHealthTableCell> {patient.orderTypes.length ? cutLongText(patient.orderTypes.join(', ')) : emptyPlaceholder()} </WildHealthTableCell>
                                    </Tooltip>
                                    {!authQuery.isLicensingPractice() &&
                                        <WildHealthTableCell> {patient.paymentPrice && patient.paymentPrice.paymentCoupon ? patient.paymentPrice.paymentCoupon.code : emptyPlaceholder()} </WildHealthTableCell>
                                    }
                                    <WildHealthTableCell> {patient.shippingAddress ? patient.shippingAddress.state : emptyPlaceholder()} </WildHealthTableCell>
                                    <WildHealthTableCell> {patient.location ? displayLocation(patient.location) : emptyPlaceholder()} </WildHealthTableCell>
                                </WildHealthTableRow>
                            )
                        }
                    </TableBody>
                    <TableFooter>
                        <WildHealthTableRow style={{ backgroundColor: "white" }}>
                            <WildHealthTableCell colSpan={authQuery.isLicensingPractice() ? 7 : 9}>
                                <PaginationComponent
                                    pageSizes={pageSizes}
                                    pageSize={pageSize}
                                    selectedPage={selectedPage}
                                    handlePageSizeChange={handlePageSizeChange}
                                    totalCount={totalCount}
                                    availablePages={getAllAvailablePages()}
                                    handlePageSelect={handlePageChange} />
                            </WildHealthTableCell>
                        </WildHealthTableRow>
                    </TableFooter>
                </Table >
            </TableContainer >
        )
    }

    return (
        <>
            <Box my={2} display="flex" alignItems="center" justifyContent="space-between">
                <Box>
                    <FeatureComponent featureFlag={FeatureFlag.Navigation}>
                        <Box display="flex" alignItems='center'>
                            <Box mr={2} className={clsx(commonClasses.size24, commonClasses.textSemiBold, commonClasses.colorBlack)}>
                                {PagesNamesService.allPatients}
                            </Box>
                        </Box>
                    </FeatureComponent>
                </Box>
                <Box display="flex" alignItems="center">
                    <Box mr={2}>
                        <SearchComponent
                            placeholder="Search by Name, ID or Email"
                            handleSearch={handleSearch}
                            handleChanges={handleSearchQueryChanges}
                        />
                    </Box>
                    <Button
                        id="manage-all-patients-filter"
                        aria-describedby={filtersPopoverProps.id}
                        onClick={(event) => handleToggleFilters(event)}
                        startIcon={<FilterListIcon />}
                        className={commonClasses.whButton}
                    >
                        Filter
                    </Button>
                </Box>
            </Box>

            <Box>
                <WildHealthContainer margin={0} minHeight={'100%'}>
                    {renderTable()}
                </WildHealthContainer>
            </Box>

            <Popover
                id={filtersPopoverProps.id}
                anchorEl={filtersState.anchorEl}
                onClose={() => handleToggleFilters()}
                open={filtersState.isOpen}
                anchorOrigin={filtersPopoverProps.anchorOrigin}
                transformOrigin={filtersPopoverProps.transformOrigin}
            >
                <Box width="365px">
                    <List className={clsx(classes.filters, 'flat-scroll')}>

                        <ListItem id="manage-all-patients-add-ons" button onClick={handleToggleAddOns}>
                            <ListItemText primary={<span className="text-medium">Add-Ons</span>} />
                            {filtersState.isAddOnsOpen ? <ExpandLess className={classes.activeIcon} /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={filtersState.isAddOnsOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem
                                    id={`manage-all-patients-add-ons-select-all`}
                                    key="manage-all-patients-add-ons-select-all" button className={classes.nested}
                                    onClick={() => handleSelectAllAddOns()}
                                >
                                    <ListItemIcon>
                                        {filtersState.isAllAddOnsSelected ? <CheckBoxIcon className={classes.activeIcon} /> : <CheckBoxOutlineBlankIcon />}
                                    </ListItemIcon>
                                    <ListItemText primary='Select All' />
                                </ListItem>
                                {
                                    filtersState.filters.addOns.map((item, index) =>
                                        <ListItem
                                            id={`manage-all-patients-pod-${index}`}
                                            key={index} button className={classes.nested}
                                            onClick={() => handleOnAddOnsChanges(item.name, !item.isSelected)}
                                        >
                                            <ListItemIcon>
                                                {item.isSelected ? <CheckBoxIcon className={classes.activeIcon} /> : <CheckBoxOutlineBlankIcon />}
                                            </ListItemIcon>
                                            <ListItemText primary={item.name} />
                                        </ListItem>
                                    )
                                }
                            </List>
                        </Collapse>

                        <ListItem id="manage-all-patients-state" className={classes.divider} button onClick={handleToggleState}>
                            <ListItemText primary={<span className="text-medium">State</span>} />
                            {filtersState.isStateOpen ? <ExpandLess className={classes.activeIcon} /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={filtersState.isStateOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem>
                                    <Box >
                                        <SearchComponent handleSearch={handleSearchState} handleChanges={handleSearchState} placeholder="Search by state" />
                                    </Box>
                                </ListItem>
                                <ListItem
                                    id={`manage-all-patients-state-select-all`}
                                    key="manage-all-patients-state-select-all" button className={classes.nested}
                                    onClick={() => handleSelectAllState()}
                                >
                                    <ListItemIcon>
                                        {filtersState.isAllStateSelected ? <CheckBoxIcon className={classes.activeIcon} /> : <CheckBoxOutlineBlankIcon />}
                                    </ListItemIcon>
                                    <ListItemText primary='Select All' />
                                </ListItem>
                                {
                                    filtersState.filters.state.map((item, index) =>
                                        <ListItem
                                            id={`manage-all-patients-state-${index}`}
                                            key={index} button className={classes.nested}
                                            onClick={() => handleOnStateChanges(item.name, !item.isSelected)}
                                        >
                                            <ListItemIcon>
                                                {item.isSelected ? <CheckBoxIcon className={classes.activeIcon} /> : <CheckBoxOutlineBlankIcon />}
                                            </ListItemIcon>
                                            <ListItemText primary={item.name} />
                                        </ListItem>
                                    )
                                }
                            </List>
                        </Collapse>

                        {!authQuery.isLicensingPractice() &&
                            <>
                                <ListItem id="manage-all-patients-plans" className={classes.divider} button onClick={handleTogglePlans}>
                                    <ListItemText primary={<span className="text-medium">Plans</span>} />
                                    {filtersState.isPlansOpen ? <ExpandLess className={classes.activeIcon} /> : <ExpandMore />}
                                </ListItem>
                                <Collapse in={filtersState.isPlansOpen} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItem>
                                            <Box >
                                                <SearchComponent handleSearch={handleSearchPlan} handleChanges={handleSearchPlan} placeholder="Search by plan" />
                                            </Box>
                                        </ListItem>
                                        {
                                            filtersState.filters.paymentPlans.map((item, index) =>
                                                <ListItem
                                                    id="manage-all-patients-on-payment"
                                                    key={index} button className={classes.nested}
                                                    onClick={() => handleOnPaymentChanges(item.periodId, !item.isSelected)}
                                                >
                                                    <ListItemIcon>
                                                        {item.isSelected ? <CheckBoxIcon className={classes.activeIcon} /> : <CheckBoxOutlineBlankIcon />}
                                                    </ListItemIcon>
                                                    <ListItemText primary={item.name} />
                                                </ListItem>
                                            )
                                        }
                                    </List>
                                </Collapse>
                            </>
                        }
                        <ListItem id="manage-all-patients-providers" className={classes.divider} button onClick={handleToggleProviders}>
                            <ListItemText primary={<span className="text-medium">Providers</span>} />
                            {filtersState.isProvidersOpen ? <ExpandLess className={classes.activeIcon} /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={filtersState.isProvidersOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {
                                    filtersState.filters.providers.map((item, index) =>
                                        <ListItem
                                            id={`manage-all-patients-provider-${item.id}`}
                                            key={index} button className={classes.nested}
                                            onClick={() => handleProviderChanges(item.id, !item.isSelected)}
                                        >
                                            <ListItemIcon>
                                                {item.isSelected ? <CheckBoxIcon className={classes.activeIcon} /> : <CheckBoxOutlineBlankIcon />}
                                            </ListItemIcon>
                                            <ListItemText primary={`${item.firstName} ${item.lastName}`} />
                                        </ListItem>
                                    )
                                }
                            </List>
                        </Collapse>

                        <ListItem id="manage-all-patients-coaches" className={classes.divider} button onClick={handleToggleCoaches}>
                            <ListItemText primary={<span className="text-medium">Coaches</span>} />
                            {filtersState.isCoachesOpen ? <ExpandLess className={classes.activeIcon} /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={filtersState.isCoachesOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {
                                    filtersState.filters.coaches.map((item, index) =>
                                        <ListItem
                                            id={`manage-all-patients-coache-${item.id}`}
                                            key={index} button className={classes.nested}
                                            onClick={() => handleCoachChanges(item.id, !item.isSelected)}
                                        >
                                            <ListItemIcon>
                                                {item.isSelected ? <CheckBoxIcon className={classes.activeIcon} /> : <CheckBoxOutlineBlankIcon />}
                                            </ListItemIcon>
                                            <ListItemText primary={`${item.firstName} ${item.lastName}`} />
                                        </ListItem>
                                    )
                                }
                            </List>
                        </Collapse>

                        <ListItem id="manage-all-patients-pods" className={classes.divider} button onClick={handleTogglePods}>
                            <ListItemText primary={<span className="text-medium">Pods</span>} />
                            {filtersState.isPodsOpen ? <ExpandLess className={classes.activeIcon} /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={filtersState.isPodsOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {
                                    filtersState.filters.locations.map((item, index) =>
                                        <ListItem
                                            id={`manage-all-patients-pod-${item.id}`}
                                            key={index} button className={classes.nested}
                                            onClick={() => handleOnPodChanges(item.id, !item.isSelected)}
                                        >
                                            <ListItemIcon>
                                                {item.isSelected ? <CheckBoxIcon className={classes.activeIcon} /> : <CheckBoxOutlineBlankIcon />}
                                            </ListItemIcon>
                                            <ListItemText primary={item.name} />
                                        </ListItem>
                                    )
                                }
                            </List>
                        </Collapse>
                    </List>
                    <Box p={2} display="flex" justifyContent="space-between" alignItems='center'>
                        <WildHealthLinkButton
                            onClick={() => handleClearFilters()}
                            content={<Box display="flex" alignItems='center'> <CloseIcon className={classes.closeIcon} />  <span className={classes.activeIcon}> Clear All </span></Box>}
                            id="manage-all-patients-clear-all"
                        />

                        <WildHealthButton
                            id="manage-all-patients-apply-filter"
                            onClick={() => handleApplyFilters()}
                        >
                            Apply filter
                        </WildHealthButton>
                    </Box>
                </Box>
            </Popover>
        </>
    );
}