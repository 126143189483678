import React, {useEffect, useState} from 'react';
import {Box, IconButton, Table, TableBody, TableContainer, TableHead} from "@material-ui/core";
import {Add} from '@material-ui/icons';
import {colors} from '../../../common/constants/colors';
import {PatientSupplementModel, SupplementSource} from '../../../patientSupplements/models/patientSupplementModel';
import {
    PatientSupplementsUpdateComponent
} from '../../../patientSupplements/components/patientSupplementsUpdateComponent/PatientSupplementsUpdateComponent';
import {WildHealthTableCell} from '../../../common/components/wildHealthTable/WildHealthTableCell';
import {WildHealthTableRow} from '../../../common/components/wildHealthTable/WildHealthTableRow';
import {SecondaryButton} from '../../../common/components/wildHealthButton/SecondaryButton';
import clsx from 'clsx';
import commonUseStyles from '../../../common/styles/common.styles';
import {Subscription} from 'recompose';
import {
    patientSupplementsQuery
} from '../../../patientSupplements/stores/patientSupplementsStore/patientSupplements.query';
import {QuestionModel, QuestionType} from '../../models/questionnaire.models';
import {onEmit} from '../../../common/helpers/on-emit';
import {supplementsService} from '../../../patientSupplements/services/supplements.service';
import {patientsSupplementsService} from '../../../patientSupplements/services/patientSupplements.service';
import {MedicalHistoryAndLifestyleQuestionNames} from '../../static/medicalHistroryQuestionnaire.static';
import {FeatureComponent} from "../../../common/components/featureFlags/FeatureComponent";
import {FeatureFlag} from "../../../common/components/featureFlags/featureFlags.models";
import {
    PatientSupplementsUpdateFullscriptComponent
} from "../../../patientSupplements/components/patientSupplementsUpdateFullscriptComponent/PatientSupplementsUpdateFullscriptComponent";

export interface QuestionnaireSupplementsComponentProps {
    patientId?: number | null;
    question: QuestionModel;
    handleChanges: (field: string, value: string) => void
}

interface QuestionnaireSupplementsComponentState {
    supplements: PatientSupplementModel[];
    dialogOpened: boolean;
}

export const QuestionnaireSupplementsComponent: React.FC<QuestionnaireSupplementsComponentProps> = (props: QuestionnaireSupplementsComponentProps) => {
    const {
        patientId = null,
        question,
        handleChanges
    } = props;

    const [state, setState] = useState({
        supplements: [],
        dialogOpened: false
    } as QuestionnaireSupplementsComponentState)

    const commonClasses = commonUseStyles();

    const handleOpenDialog = (open: boolean) => {
        setState({ ...state, dialogOpened: open })
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<PatientSupplementModel[]>(patientSupplementsQuery.patientSupplements$, supplements => {
                if (supplements && supplements.length) {
                    setState(state => ({ ...state, supplements: supplements }))
                    handleChanges(MedicalHistoryAndLifestyleQuestionNames.SUPPLEMENTS, supplements.map(x => x.id).join(','));
                }
            })
        ];

        if (patientId) {
            supplementsService.get(patientId).subscribe()
        }
        else {
            patientsSupplementsService.get().subscribe()
        }

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    const renderContent = (): JSX.Element => {
        if (state.supplements.length) {
            return (
                <Box>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <WildHealthTableRow>
                                    <WildHealthTableCell>Name</WildHealthTableCell>
                                    <WildHealthTableCell>Dosage</WildHealthTableCell>
                                </WildHealthTableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    state.supplements.map((supplement, index) => (
                                        <WildHealthTableRow key={index}>
                                            <WildHealthTableCell>{supplement.name}</WildHealthTableCell>
                                            <WildHealthTableCell>{`${supplement.dosage}, ${supplement.instructions}`}</WildHealthTableCell>
                                        </WildHealthTableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box display="flex" justifyContent="flex-start" alignItems="center" mt={1}>
                        <Box>
                            <IconButton id="questionnaire-supplements-open" data-testid="questionnaireSupplementsOpenBtn" onClick={() => handleOpenDialog(true)}>
                                <Add style={{ color: colors.main, backgroundColor: colors.gray3 }} />
                            </IconButton>
                        </Box>
                        <Box>
                            <span className={clsx(commonClasses.colorMain, commonClasses.textMedium, commonClasses.size14)}>Add Supplement</span>
                        </Box>
                    </Box>
                </Box>
            )
        }

        return (
            <Box textAlign='center'>
                <Box mt={5}>
                    <span className={clsx(commonClasses.colorGray1, commonClasses.textSemiBold, commonClasses.size20, commonClasses.secondaryFont)}>No data yet</span>
                </Box>
                <Box mt={3}>
                    <span className={commonClasses.colorGray1}>Add supplements by clicking the button below.</span>
                </Box>
                <Box mt={2}>
                    <SecondaryButton id="questionnaire-supplements-add" data-testid="questionnaireSupplementsAddBtn" onClick={() => handleOpenDialog(true)}>Add Supplement</SecondaryButton>
                </Box>
            </Box>
        )
    }

    return (
        <>
            <FeatureComponent notFeatureFlag={FeatureFlag.FullscriptIntegration}>
                <PatientSupplementsUpdateComponent
                    opened={state.dialogOpened}
                    patientId={patientId}
                    handleClose={() => handleOpenDialog(false)}
                />
            </FeatureComponent>
            <FeatureComponent featureFlag={FeatureFlag.FullscriptIntegration}>
                <PatientSupplementsUpdateFullscriptComponent
                    opened={state.dialogOpened}
                    fullscript
                    source={SupplementSource.Intake}
                    patientId={patientId}
                    handleClose={() => handleOpenDialog(false)}
                />
            </FeatureComponent>
            <Box>
                <span className={commonClasses.textMedium}>{question.displayName}</span>
                {
                    (question.type === QuestionType.CheckMany || question.type === QuestionType.SelectMany) &&
                    <span className={commonClasses.colorGray1}> (Check all that apply.)</span>
                }
                {
                    question.optional &&
                    <span className={commonClasses.colorGray1}> (Optional)</span>
                }
            </Box>
            {
                renderContent()
            }
        </>
    )
}