import Axios from 'axios-observable';
import { authHeader } from "../../common/helpers/auth-header";
import { snackService } from "../../common/snack/state";
import { inputsStore, InputsStore } from "../stores/inputsStore";
import {
    FileInputDataProvider,
    FileInputModel,
    FileInputType,
    GeneralInputsModel, LabInputModel,
    MicrobiomeInputModel,
    UploadFileInputModel
} from "../models/input.models";
import { Observable, Subject } from "rxjs";
import { CallbackModel } from "../../common/models/callback.model";
const FileDownload = require('js-file-download');

export class PatientInputsService {
    private urInputs = `${process.env.REACT_APP_API_URL}PatientInputs`;
    private urlUploads = `${this.urInputs}/Uploads`;
    private urlGeneral = `${this.urInputs}/General`;
    private urlLab = `${this.urInputs}/Lab`;
    private urlMicrobiome = `${this.urInputs}/microbiome`;
    private urlApoe = `${this.urInputs}/apoe`;

    constructor(private inputsStore: InputsStore) {
    }

    public onUpload = new Subject<CallbackModel<FileInputType>>();

    /**
     * Returns all patients uploads
     */
    public getUploads() {
        return new Observable(observer => {
            Axios.get(this.urlUploads, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.inputsStore.update({ fileInputs: response.data });
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Downloads uploaded file
     */
    public download(input: FileInputModel): Observable<any> {
        return new Observable(observer => {
            Axios.get(`${this.urlUploads}/${input.id}/download`, {
                headers: authHeader(),
                responseType: 'blob',
            })
                .pipe()
                .subscribe(
                    (response) => {
                        FileDownload(response.data, `${input.file.name}`);
                        snackService.success('File successfully downloaded!');
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Views uploaded file
     */
    public viewinline(input: FileInputModel): Observable<any> {
        return new Observable(observer => {
            Axios.get(`${this.urlUploads}/${input.id}/viewinline`, {
                headers: authHeader(),
                responseType: 'blob',
            })
                .pipe()
                .subscribe(
                    (response) => {
                        const file = new Blob([response.data], { type: 'application/pdf' });

                        const fileURL = URL.createObjectURL(file);

                        window.open(fileURL, '_blank');

                        snackService.success('File successfully opened!');
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Returns all patients uploads
     */
    public upload(type: FileInputType): Observable<FileInputModel> {
        return new Observable(observer => {
            this.onUpload.next({
                data: type,
                callback: (file: any, dataProvider: FileInputDataProvider) => {
                    if (!file) {
                        observer.error();
                        observer.complete();
                        return;
                    }

                    const model: UploadFileInputModel = {
                        file: file,
                        type: type,
                        dataProvider: dataProvider
                    };

                    const formData = new FormData();
                    formData.append('file', model.file);
                    formData.append('type', model.type.toString());
                    formData.append('dataProvider', model.dataProvider.toString());

                    Axios.post(this.urlUploads, formData, { headers: authHeader() })
                        .pipe()
                        .subscribe(
                            (response) => {
                                this.inputsStore.addFileInput(response.data);
                                snackService.success('File successfully uploaded!');
                                observer.next(response.data);
                                observer.complete();
                            },
                            error => {
                                snackService.commonErrorHandler(error);
                                observer.error(error);
                                observer.complete();
                            }
                        );
                }
            });
        });
    }

    /**
     * Deletes upload
     */
    public deleteUpload(id: number): void {
        Axios.delete(`${this.urlUploads}/${id}`, { headers: authHeader() })
            .pipe()
            .subscribe(
                (response) => {
                    this.inputsStore.deleteFileInput(response.data);
                    snackService.success('File successfully deleted!');
                },
                error => snackService.commonErrorHandler(error)
            );
    }

    public getGeneralInputs(): void {
        Axios.get(this.urlGeneral, { headers: authHeader() })
            .pipe()
            .subscribe(
                (response) => {
                    this.inputsStore.update({ generalInputs: response.data });
                },
                error => snackService.commonErrorHandler(error)
            );
    }

    public updateGeneralInputs(inputs: GeneralInputsModel): Observable<any> {
        const config = { headers: authHeader() };
        return new Observable(observer => {
            Axios.put(this.urlGeneral, inputs, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.inputsStore.update({ generalInputs: response.data });
                        snackService.success('Inputs successfully saved!');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Returns lab inputs
     */
    public getLabInputs(): Observable<LabInputModel[]> {
        return new Observable(observer => {
            Axios.get<LabInputModel[]>(this.urlLab, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.inputsStore.update({ labInputs: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    /**
     * Returns microbiome inputs
     */
    public getMicrobiomeInputs(): Observable<MicrobiomeInputModel[]> {
        return new Observable(observer => {
            Axios.get<MicrobiomeInputModel[]>(this.urlMicrobiome, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.inputsStore.update({ microbiomeInputs: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

        /**
     * APOE info
     */
        public getApoe(): Observable<void> {
            return new Observable(observer => {
                Axios.get(`${this.urlApoe}`, { headers: authHeader() })
                    .pipe()
                    .subscribe(
                        (response) => {
                            this.inputsStore.update({ apoeInfo: response.data });
                            observer.next();
                            observer.complete();
                        },
                        error => {
                            snackService.commonErrorHandler(error);
                            observer.error(error);
                            observer.complete();
                        }
                    );
            });
        }
}

export const patientInputsService = new PatientInputsService(inputsStore);