import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginBottom: 20,
        },
        timeButton: {
            borderRadius: 4,
            color: colors.black,
            background: colors.white,
            border: 'solid 1px',
            borderColor: colors.stroke,
            boxShadow: '0px 4px 9px rgba(168, 173, 198, 0.15)'
        },
        timeButtonSelected: {
            background: colors.lightGray,
            borderColor: colors.accent1
        },
        navigateButton: {
            minWidth: "24px !important",
            width: 24,
            height: 24,
            margin: 6,
            borderRadius: 4,
            backgroundColor: colors.white,
        },
        navigationIcon: {
            width: 14,
            height: 14,
        },
        timeSection: {
            maxHeight: 320,
            overflow: "auto"
        },
        customWidth: {
            width: '185px',
            maxWidth: '185px',
            padding: '8px 12px',
        },
        tooltipPlacement: {
            margin: '8px 0',
        },
        timePicker: {
            height: 48
        }
    })
);
