import React from "react";
import { useFacade } from "./stickyChatContainerComponent.hooks";
import {
    Box,
    Card,
    CardHeader,
    Divider,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Avatar
} from "@material-ui/core";
import RemoveIcon from '@material-ui/icons/Remove';
import { useStyles } from "./stickyChatContainerComponent.style";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { StickyChatComponent } from "../stickyChatComponent/StickyChatComponent";
import { StickyChatComponentForAiDemo } from "../stickyChatComponent/StickyChatComponentForAiDemo";
import ConversationsIcon from "../../../../components/iconComponent/ConversationIcon";
import { ReactComponent as AttachIcon } from '@img/icons/Attach.svg';
import AppointmentsIcon from "../../../../components/iconComponent/AppointmentsIcon";
import PeopleIcon from "@material-ui/icons/People";
import { AttachmentsDialogComponent } from "../attachmentsDialog/AttachmentsDialogComponent";
import { ScheduledMessagesDialogComponent } from "../scheduledMessagesDialog/ScheduledMessagesDialogComponent";
import { ActiveUsersDialogComponent } from "../activeUsersDialog/ActiveUsersDialogComponent";
import { ConversationState } from "../../models/conversationState.models";
import { SelectUserDialogComponent } from "../selectUserDialogComponent/SelectUserDialogComponent";
import { employeeService } from "../../../employee/services/employees.service";
import { FeatureComponent } from "../../../common/components/featureFlags/FeatureComponent";
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import { ReactComponent as AiIcon } from '@img/icons/AiIcon.svg';
import clsx from "clsx";

export const StickyChatContainerComponent: React.FC = () => {
    const classes = useStyles();
    const [
        {
            menu,
            isLoading,
            isVisible,
            isCollapsed,
            isCollapsedForAiDemo,
            isDashboard,
            patient,
            author,
            conversations,
            targetConversation,
            isAttachmentsDialogOpen,
            isScheduledDialogOpen,
            isActiveUsersDialogOpen,
            isAddUserOpen,
            members
        },
        handleCollapse,
        handleMenuToggle,
        handleToggleAttachmentsDialog,
        handleGoToConversation,
        handleToggleScheduledDialog,
        handleToggleActiveUsersDialog,
        handleToggleAddUser,
        handleAddUser,
        canAddUser,
        handleRemoveUserFromThread
    ] = useFacade();

    if (isLoading || !isVisible || !conversations.length) {
        return <></>
    }

    if (isCollapsed) {
        if (isDashboard) return <></>;
        return (
            <Box position="fixed" right={20} bottom={20} zIndex={1202} display="flex" gridGap={12}>
                <FeatureComponent featureFlag={FeatureFlag.D2CDemo}>
                    <IconButton aria-label="collapse" onClick={() => handleCollapse(true)}>
                        <Avatar className={clsx(classes.avatar, "wh-tw-bg-primaryV")} variant="circular"><AiIcon /></Avatar>
                    </IconButton>
                </FeatureComponent>
                <IconButton aria-label="collapse" onClick={() => handleCollapse()}>
                    <AccountCircleIcon className={classes.collapsed} />
                </IconButton>
            </Box>
        )
    }

    const getBodyPadding = () => {
        if (navigator.vendor.match(/google/i)) {
            return 41
        }
        if (navigator.vendor.match(/apple/i)) {
            return 20
        }
        if (navigator.userAgent.match(/firefox\//i)) {
            return 37
        }
        if (navigator.userAgent.match(/edge\//i)) {
            return 37
        }
        if (navigator.userAgent.match(/trident\//i)) {
            return 37
        }
        return 20
    }

    return (
        <div>
            <Box position="fixed" right={menu.isOpen ? getBodyPadding() : 20} bottom={20} zIndex={1202}>
                <Card className={classes.root}>
                    <CardHeader
                        className={classes.header}
                        avatar={
                            <AccountCircleIcon className={classes.avatarIcon} />
                        }
                        action={
                            <IconButton aria-label="collapse" onClick={() => handleCollapse()}>
                                <RemoveIcon />
                            </IconButton>
                        }
                        title={
                            <Box display="flex" alignItems="center">
                                <Box>
                                    <span>{patient?.firstName + ' ' + patient?.lastName}</span>
                                </Box>
                                <Box ml={2}>
                                    <IconButton
                                        size="small"
                                        onClick={(e) => handleMenuToggle(true, e)}>
                                        <MoreHorizIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        }
                    />
                    {isCollapsedForAiDemo ? (
                        <StickyChatComponent
                            conversation={targetConversation}
                            author={author}
                        />
                    ) : (
                        <StickyChatComponentForAiDemo
                            conversation={targetConversation}
                            author={author}
                        />
                    )}
                </Card>
                <Menu
                    keepMounted
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    anchorEl={menu.anchorEl}
                    open={menu.isOpen}
                    onClose={() => handleMenuToggle(false)}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem disabled={!targetConversation.proxy} onClick={() => handleGoToConversation(targetConversation)}>
                        <ListItemIcon className={classes.menuIcon}>
                            <ConversationsIcon isSelected={false} />
                        </ListItemIcon>
                        <ListItemText>Open in Messages</ListItemText>
                    </MenuItem>
                    <Divider />
                    <MenuItem disabled={!canAddUser()} onClick={() => handleToggleActiveUsersDialog(true)}>
                        <ListItemIcon>
                            <PeopleIcon className={classes.menuIcon} />
                        </ListItemIcon>
                        <ListItemText>Active Users on Thread</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => handleToggleAttachmentsDialog(true)}>
                        <ListItemIcon>
                            <AttachIcon className={classes.menuIcon} />
                        </ListItemIcon>
                        <ListItemText>Attachments</ListItemText>
                    </MenuItem>
                    <MenuItem disabled={!targetConversation.proxy} onClick={() => handleToggleScheduledDialog(true)}>
                        <ListItemIcon className={classes.menuIcon}>
                            <AppointmentsIcon isSelected={false} />
                        </ListItemIcon>
                        <ListItemText>Scheduled Messages</ListItemText>
                    </MenuItem>
                </Menu>
            </Box>
            <AttachmentsDialogComponent
                conversationProxy={!!targetConversation.proxy}
                open={isAttachmentsDialogOpen}
                handleClose={() => handleToggleAttachmentsDialog(false)}
            />
            <ScheduledMessagesDialogComponent
                open={isScheduledDialogOpen}
                handleClose={() => handleToggleScheduledDialog(false)}
            />
            <ActiveUsersDialogComponent
                members={members}
                disableAddMember={targetConversation.state === ConversationState.Closed}
                disableRemoveMember={targetConversation.state === ConversationState.Closed}
                handleToggleAddUser={() => handleToggleAddUser()}
                handleRemoveUserFromThread={(id) => handleRemoveUserFromThread(id)}
                open={isActiveUsersDialogOpen}
                handleClose={() => handleToggleActiveUsersDialog(false)}
            />
            <SelectUserDialogComponent
                isOpen={isAddUserOpen}
                title='Add User'
                message='Select User:'
                actionName='Add User'
                handleGetUsers={(search) => employeeService.getActive(search)}
                handleCancel={() => handleToggleAddUser()}
                handleSubmit={(id) => handleAddUser(id)}
            />
        </div>
    )
}