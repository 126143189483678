import {
    Box,
    createStyles,
    FormControl,
    IconButton,
    InputLabel,
    makeStyles,
    Menu,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableSortLabel
} from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React from 'react';
import {PermissionType} from "../../../auth/models/auth.enums";
import {ProtectedElement} from "../../../common/components/ProtectedElement";
import {SearchComponent} from '../../../common/components/searchComponent/SearchComponent';
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import {WildHealthPlaceHolder} from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import {WildHealthTableCell} from "../../../common/components/wildHealthTable/WildHealthTableCell";
import {WildHealthTableRow} from "../../../common/components/wildHealthTable/WildHealthTableRow";
import {CreateEmployeeButtonComponent} from "../createEmployeeButton/CreateEmployeeButtonComponent";
import {ResetPatientPasswordDialogComponent} from "../../../account/components/resetPatientPasswordDialogComponent/ResetPatientPasswordDialogComponent";
import {UpdateEmployeeComponent} from "../updateEmployee/UpdateEmployeeComponent";
import {useFacade} from "./manageEmployeesComponent.hooks";

interface ManageEmployeesComponentProps {
    maxHeight?: number;
    locationId?: number;
}

export const ManageEmployeesComponent: React.FC<ManageEmployeesComponentProps> = (props: ManageEmployeesComponentProps) => {
    const { maxHeight, locationId } = props;
    
    const [
        {
            isLoading,
            employees,
            sortingColumns,
            sortingSource,
            isEditOpen,
            isResetPasswordOpen,
            currentEmployeeEmail,
            selectedEmployeeId,
            selfManagingEmployee,
            isEmployeeActionsOpen,
            employeeActionsAnchorEl,
            selectedRoleId,
            roles
        },
        handleToggleEditEmployee,
        handleToggleResetEmployeePassword,
        handleResendEmployeeInvite,
        handleDeleteEmployee,
        handleToggleEmployeeActions,
        handleSubmitResetPassword,
        handleSorting,
        handleSearch,
        handleSelectRole,
        handleSearchInputChange
    ] = useFacade(locationId);

    const isDeleteAvailable = (locationId?: number): boolean => !locationId;

    const employeeTableClass = maxHeight ? { height: `${maxHeight}px` } : {};
    const classes = makeStyles(() =>
        createStyles({
            employeeTable: employeeTableClass,
            selectLabel: { backgroundColor: "white" }
        })
    )();

    const renderTable = () => {
        if (isLoading) {
            return <WildHealthLinearProgress/>
        }

        if (!isLoading && (!employees || !employees.length)) {
            return <WildHealthPlaceHolder message="No current records found"/>
        }

        return (
            <TableContainer className={classes.employeeTable}>
                <Table>
                    <TableHead>
                        <WildHealthTableRow>
                            {
                                sortingColumns.map((item, index) =>
                                    <WildHealthTableCell
                                        key={index}
                                    >
                                        <TableSortLabel
                                            id={`manage-employees-sorting-${item.title}`}
                                            active={sortingSource === item.source}
                                            direction={sortingSource === item.source ? item.direction : 'asc'}
                                            onClick={() => handleSorting(item.source, item.direction)}
                                        >
                                            {item.title}
                                        </TableSortLabel>
                                    </WildHealthTableCell>
                                )
                            }
                            <ProtectedElement
                                element={
                                    <WildHealthTableCell>
                                        Actions
                                    </WildHealthTableCell>}
                                permissions={[PermissionType.ManageEmployees]}
                            />

                        </WildHealthTableRow>
                    </TableHead>
                    <TableBody>
                        {
                            employees.map((item, index) =>
                                <WildHealthTableRow key={index}>
                                    {
                                        Object.values(item).slice(2).map((field, fieldIndex) =>
                                            <WildHealthTableCell key={`${index}${fieldIndex}`}>
                                                {field}
                                            </WildHealthTableCell>
                                        )
                                    }
                                    <ProtectedElement
                                        element={
                                            <WildHealthTableCell>
                                                <IconButton  id={`manage-employees-actions-${item.id}`} onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                                                    handleToggleEmployeeActions(!isEmployeeActionsOpen, item.id, item.email, event.currentTarget)}>
                                                    <MoreVertIcon/>
                                                </IconButton>
                                            </WildHealthTableCell>}
                                        permissions={[PermissionType.ManageEmployees]}
                                    />
                                </WildHealthTableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    return (
        <Box p={3}>
            <Box mb={2} display="flex" justifyContent="space-between">
                <Box display="flex " alignItems="center">
                    <ProtectedElement
                        element={<CreateEmployeeButtonComponent
                            locationId={locationId}
                        />}
                        permissions={[PermissionType.ManageEmployees]}
                    />
                </Box>
                <Box display="flex" alignItems="center">
                    <Box width={300} marginRight={2}>
                        <SearchComponent
                            placeholder="Search by Name, ID or Email"
                            handleChanges={(value: string) => handleSearchInputChange(value)}
                            handleSearch={(value: string) => handleSearch(value)}
                        />
                    </Box>
                    <Box width={300}>
                        <FormControl color='primary' variant="outlined" size="small" fullWidth>
                            <InputLabel className={classes.selectLabel}>
                                Role
                            </InputLabel>
                            <Select
                                className="input"
                                value={selectedRoleId}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                                onChange={handleSelectRole}
                            >
                                {
                                    roles &&
                                    [(
                                        <MenuItem key={0} value={0}>
                                            All
                                        </MenuItem>
                                    )]
                                    .concat(roles
                                        .map((item) =>
                                            <MenuItem key={item.id} value={item.id}>
                                                {item.displayName}
                                            </MenuItem>
                                        )
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
            </Box>
            {
                renderTable()
            }
            <UpdateEmployeeComponent
                isOpen={isEditOpen}
                employeeId={selectedEmployeeId}
                selfManagingEmployee={selfManagingEmployee}
                handleToggleDialog={(value: boolean) => handleToggleEditEmployee(value)}
            />
            <ResetPatientPasswordDialogComponent
                open={isResetPasswordOpen}
                handleSubmit={handleSubmitResetPassword}
                handleClose={() => handleToggleResetEmployeePassword(false)}
            />
            <ProtectedElement
                element={
                    <Menu
                        keepMounted
                        anchorEl={employeeActionsAnchorEl}
                        open={Boolean(employeeActionsAnchorEl)}
                        onClose={() => handleToggleEmployeeActions(false, 0, currentEmployeeEmail, null)}
                    >
                        <MenuItem  id="manage-employees-edit" onClick={() => handleToggleEditEmployee(true)}>
                            Edit
                        </MenuItem>
                        <MenuItem id="manage-employees-reset-password" onClick={() => handleToggleResetEmployeePassword(true)}>
                            Reset Password
                        </MenuItem>
                        <MenuItem id="manage-employees-resend-invite" onClick={() => handleResendEmployeeInvite()} disabled={false}>
                            Resend Invite
                        </MenuItem>
                        {isDeleteAvailable(locationId) && 
                        <MenuItem id="manage-employees-delete" onClick={() => handleDeleteEmployee()} disabled={selfManagingEmployee}>
                            Delete
                        </MenuItem>}
                    </Menu>}
                permissions={[PermissionType.ManageEmployees]}
            />
        </Box>
    )
}