import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { colors } from "../../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& label.Mui-focused': {
                color: colors.main,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: colors.main,
                },
            },
        },
        dialogTitle: {
            margin: 0,
            padding: theme.spacing(2),
        },
        dialogTitleCloseAction: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        dialogContent: {
            minHeight: 400,
            paddingLeft: 40,
            paddingRight: 40,
            paddingBottom: 30,
            [theme.breakpoints.down("xs")]: {
                padding: 16,
            },
        },
        sectionTitle: {
            fontSize: 18,
            fontWeight: 500,
        },
        contextStyle: {
            fontFamily: 'Be Vietnam Pro',
            fontSize: 16,
        },
        employerBanner: {
            height: 75,
        },
    }),
);
