import React from 'react';
import {
    Checkbox,
    createStyles,
    FormHelperText,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Theme
} from '@material-ui/core';
import {EmployeeShortModel} from "../../models/employee.models";
import {makeStyles} from "@material-ui/core/styles";
import {WildHealthPlaceHolder} from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { colors } from "../../../common/constants/colors";

interface AssignEmployeesComponentProps {
    error?: string;
    employees: EmployeeShortModel[];
    selectedEmployeesIds: number[];
    handleEmployeeSelect: Function;
    disabled: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        list: {
            width: '100%',
            overflow: 'auto',
            maxHeight: '200px',
            border: `1px solid #c6c6c6`,
            borderRadius: '5px',
            backgroundColor: theme.palette.background.paper
        },
        checkBox: {
            color: colors.main,
            '&$checked': {
                color: colors.main
            },
        },
        checked: {},
    }),
);

export const AssignEmployeesComponent: React.FC<AssignEmployeesComponentProps> = (props: AssignEmployeesComponentProps) => {
    const style = useStyles();
    const {
        error,
        employees,
        selectedEmployeesIds,
        handleEmployeeSelect,
        disabled
    } = props;

    if (!employees || !employees.length) {
        return (
            <WildHealthPlaceHolder message="Nothing to show" />
        )
    }

    return (
        <>
            <List className={style.list}>
                {employees.map((employee) => {
                    const labelId = `checkbox-list-label-${employee}`;

                    return (
                        <ListItem id={`assign-employee-${employee.id}`}
                                  key={employee.id}
                                  role={undefined}
                                  disabled={disabled}
                                  dense button
                                  onClick={() => handleEmployeeSelect(employee.id)}>
                            <ListItemIcon>
                                <Checkbox
                                    size="small"
                                    classes={{
                                        root: style.checkBox,
                                        checked: style.checked
                                    }}
                                    edge="start"
                                    checked={selectedEmployeesIds.includes(employee.id)}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{'aria-labelledby': labelId}}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId}
                                          primary={`${employee.firstName} ${employee.lastName}`}/>
                        </ListItem>
                    );
                })}
            </List>
            {
                error && error.length &&
                <FormHelperText error={true}>{error}</FormHelperText>
            }
        </>
    )
};