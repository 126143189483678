import { Theme, withStyles } from "@material-ui/core";
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { colors } from '../../constants/colors';

export const FilterButtonGroup = withStyles((theme: Theme) => ({
    root: {
        '& .Mui-selected': {
            color: colors.black,
            background: colors.gray3,
        },
    },
    grouped: {
        color: colors.gray1,
        fontFamily: 'Be Vietnam Pro',
        fontSize: 14,
        fontWeight: 500,
        width: 176,
        borderRadius: 2,
        textTransform: 'capitalize',

        '&:not(:first-child)': {
            border: '0px',
            padding: 7,
            marginLeft: theme.spacing(0.5),
        },
        '&:first-child': {
            padding: 7,
            border: '0px',
        },
        '& .Mui-selected': {
            color: colors.black,
            background: colors.gray3,
        },
    },
}))(ToggleButtonGroup);
