import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogTitle: {
            paddingTop: '30px !important',
            paddingLeft: '40px !important',
            paddingRight: '40px !important',
            paddingBottom: '30px !important'
        },
        dialogContent: {
            paddingLeft: 40,
            paddingRight: 40,
            paddingBottom: 30
        },
        dialogActions: {
            paddingTop: 20,
            paddingBottom: 30,
            paddingLeft: 40,
            paddingRight: 40,
            boxShadow: '0px 2px 13px 0px rgba(168, 173, 198, 0.25)'
        },
    })
);