import { TextField } from '@material-ui/core';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        list: {
            width: '100%',
            overflow: 'auto',
            height: '200px',
            maxHeight: '200px',
            // border: `1px solid #c6c6c6`,
            // borderRadius: '5px',
            backgroundColor: theme.palette.background.paper,
        },
        search: {
            backgroundColor: colors.gray100,
            borderRadius: 50,
        },
        searchBase: {
            padding: 16,
            fontFamily: 'Be Vietnam Pro',
            fontSize: 14,
        },
        icon: {
            color: colors.black,
        },
    }),
);

export const SearchTextField = withStyles({
    root: {
        '& label.Mui-focused': {
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'transparent',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': { 
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'transparent',
            },
        },
    },
})(TextField);