import { Box } from '@material-ui/core';
import React from 'react';
import { AuthenticatedLayoutComponent } from '../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { TitlesService } from '../../../../constants/title.constants';
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { useFacade } from "./intakeSpecialistQuestionnaireResultPage.hooks";
import {
    InitialQuestionnaireResultBaseComponent
} from "../../components/initialQuestionnaireResultComponent/InitialQuestionnaireResultComponent";
import { ProtectedElement } from '../../../common/components/ProtectedElement';
import { PermissionType } from '../../../auth/models/auth.enums';
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { BackTitleComponent } from '../../../../components/pageTitleComponent/PageTitleComponent';
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import {Gender} from "../../../common/models/user.models";

export const IntakeSpecialistQuestionnaireResultPage: React.FC = (props: any) => {
    const patientId = props.match.params.patientId;
    const [
        {
            isLoading,
            currentPage,
            questionnaire,
            result,
        },
        getNavigationContext,
        goToSubmitPatientPage
    ] = useFacade(patientId);

    const renderContent = () => {
        if (isLoading) {
            return <WildHealthLinearProgress />
        }

        if (!result && !questionnaire) {
            return (
                <Box>
                    <Box>
                        <WildHealthPlaceHolder message="Health Questionnaire has not been completed." />
                    </Box>
                    <Box mt={5} display="flex" justifyContent="flex-end">
                        <Box>
                            <ProtectedElement
                                element={
                                    <WildHealthButton  
                                        id="intake-specialist-create-patient"
                                        onClick={() => goToSubmitPatientPage()}
                                    >
                                        Proceed to Create Patient
                                    </WildHealthButton>}
                                permissions={[PermissionType.ManagePatients]}
                            />
                        </Box>
                    </Box>
                </Box>
            )
        }

        return (
            <InitialQuestionnaireResultBaseComponent
                result={result}
                gender={Gender.None}
                currentPage={currentPage}
                questionnaire={questionnaire}
                getNavigationContext={getNavigationContext}
            />
        )
    }

    const pageName = (
        <BackTitleComponent title={PagesNamesService.healthQuestionnaireResult} />
    );

    return (
        <AuthenticatedLayoutComponent
            pageName={pageName}
            title={TitlesService.healthQuestionnaireResultTitle}
        >
            <Box p={2}>
                {renderContent()}
            </Box>
        </AuthenticatedLayoutComponent>
    )
}
