import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { onEmit } from "../../../common/helpers/on-emit";
import { TodoPatientJourneyTaskModel, PatientJourneyModel, TodoPatientJourneyTaskCategoryModel } from "../../models/patient.model";
import { patientsService } from "../../services/patients.service";
import { patientsQuery } from "../../stores/patientsStore";
import { PlatformName, Track } from "../../../tracking/tracker";

interface PatientTodoTasksComponentState {
    isUpdating: boolean;
    todoTask: TodoPatientJourneyTaskCategoryModel[];
    completes: number[];
    isSideAreaOpen: boolean;
    selectedTask: TodoPatientJourneyTaskModel;
    categoriesExpanded: string[];
}

export function useFacade(): [
    PatientTodoTasksComponentState,
    (task: TodoPatientJourneyTaskModel, isArchived: boolean) => void,
    (task: TodoPatientJourneyTaskModel | null, open: boolean) => void,
    (category: string) => void
] {
    const [state, setState] = useState({
        isUpdating: false,
        todoTask: [],
        completes: [],
        isSideAreaOpen: false,
        selectedTask: null,
        categoriesExpanded: []
    } as PatientTodoTasksComponentState);

    const handleComplete = (task: TodoPatientJourneyTaskModel, isArchived: boolean) => {
        setState(state => ({...state, isUpdating: true, isSideAreaOpen: false, selectedTask: null }));
        const cb = () => setState(state => ({...state, isUpdating: false}))
        const handleNext = () => {
            if (!isArchived) {
                setState(state => ({...state, completes: [...state.completes, task.id]}));
            }
    
            patientsService.getPatientJourneyTasks().subscribe(cb, cb)
        }
        if (isArchived) {
            Track("timeline_task_archived", {
                title: task.title,
                platform: PlatformName(),
            })
        } else {
            Track("timeline_task_completed", {
                title: task.title,
                source: 'patient',
                platform: PlatformName(),
            })
        }

        const action = isArchived ? patientsService.dismissPatientJourneyTask(task.id) : patientsService.completePatientJourneyTask(task.id)
        action.subscribe(handleNext, cb);
    }

    const handleToggleSideArea = (task: TodoPatientJourneyTaskModel | null, open: boolean) => {
        if (task) {
            Track("timeline_task_details", {
                title: task.title,
                platform: PlatformName(),
            });
        }
        setState(state => ({ ...state, isSideAreaOpen: open, selectedTask: task }))
    }

    const handleExpand = (category: string) => {
        const categoriesExpanded = state.categoriesExpanded.some(x => x === category)
            ? state.categoriesExpanded.filter(x => x !== category)
            : [...state.categoriesExpanded, category]
        setState({
            ...state,
            categoriesExpanded: categoriesExpanded
        });
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<PatientJourneyModel>(patientsQuery.patientJourneyTasks$, patientJourneyTasks => {
                setState(state => ({
                    ...state,
                    todoTask: patientJourneyTasks.todo,
                    completes: []
                }));
            }),
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [
        state,
        handleComplete,
        handleToggleSideArea,
        handleExpand,
    ];
}