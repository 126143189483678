import {useEffect, useState} from "react";
import {Subscription} from "recompose";
import {GetMaximumVersionOfNote, handleDownloadNoteByEmployeePdfBd} from "../../helpers/noteHelpers";
import {AttachmentModel, downloadNoteOptions, NoteModel, NotesType} from "../../models/notes.models";
import {notesService} from "../../services/notes.service";
import {notesStore} from "../../stores/notes";
import {confirmService} from "../../../../services/confirm.service";
import {UserType} from "../../../auth/models/auth.enums";
import {internalNotePdfService} from "../../services/notes-pdf.service";
import {useFlags} from "launchdarkly-react-client-sdk";

interface SimpleComponentState {
    note: NoteModel;
    content: AttachmentModel[];
    internalContent: string;
    isPdfDownloading: boolean;
    originalContent: AttachmentModel[];
    originalInternalContent: string;
    noteId: number;
}

export function useFacade(patientId: number, note: NoteModel): [SimpleComponentState, (note: NoteModel) => void, Function, Function] {
    const [state, setState] = useState({
        note: note,
        content: null,
        internalContent: '',
        isPdfDownloading: false,
        noteId: GetMaximumVersionOfNote(note)?.id
    } as SimpleComponentState);

    const flags = useFlags();

    const getCurrentNote = (noteId: number) => {
        return note.id === noteId
            ? note
            : note.amendedNotes.find(x => x.id === noteId);
    }

    const isFilesDifferent = (original: AttachmentModel[], current: AttachmentModel[]) =>{
        if(!original || !current){
            return false;
        }

        let isDifferent = false;

        original.map(x => {
            let result = current.find(y => y.fileName === x.fileName);
            if(!result){
                isDifferent = true;
            }
        });

        if(original.length !== current.length){
            isDifferent = true;
        }

        return isDifferent;
    }

    const handleDownloadPdf = (note: NoteModel) => {
        const cb = () => setState({ ...state, isPdfDownloading: false });
        if (note.type !== NotesType.Internal) {
            confirmService.confirmAndSelectedNoteViewType(
                'Select the view type of the note',
                downloadNoteOptions,
            ).subscribe(
                (type) => {
                    setState(state => ({ ...state, isPdfDownloading: true }));
                    const isEmployeeView = type === UserType.Employee
                    notesService.downloadNote(state.noteId, note.name, isEmployeeView).subscribe(
                        cb,
                        () => {
                            const currentNote = getCurrentNote(state.noteId);
                            internalNotePdfService.downloadNotePdf(currentNote, state.internalContent, state.content, isEmployeeView, state.noteId, state.originalInternalContent !== state.internalContent, isFilesDifferent(state.originalContent, state.content)).subscribe(cb, cb);
                        }
                    )
                },
                () => { }
            );
        } else {
            setState(state => ({ ...state, isPdfDownloading: true }));
            notesService.downloadNote(state.noteId, note.name, true).subscribe(
                cb,
                () => {
                    const currentNote = getCurrentNote(state.noteId);
                    handleDownloadNoteByEmployeePdfBd(currentNote, UserType.Employee, flags).subscribe(cb, cb);
                }
            )
        }
    }

    const handleChooseNote = (noteIdNew: number) => {
        setState(state => ({
            ...state,
            noteId: noteIdNew,
            isContentLoading: true
        }));
        
        notesService.getContentByEmployee(noteIdNew, patientId).subscribe(
            (result) => {
                let attachments = result?.attachmentsEmployee?.map((item,index)=>{
                    const file = item as File;
                    return {
                        tempId: Date.now() + index,
                        id: item.attachmentId,
                        file: file,
                        ForDelete: false,
                        noteId: GetMaximumVersionOfNote(note).id,
                        fileName: item.fileName,
                        fileSize: item.fileSize
                    } as AttachmentModel;
                });

                setState(state => ({
                    ...state,
                    isContentLoading: false,
                    content:attachments,
                    internalContent: result.internalContent,
                }))
            }
        );
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [];

        if (note) {
            subscriptions.push(
                
                notesService.getContentByEmployee(GetMaximumVersionOfNote(note).id, patientId).subscribe(result =>{
                    
                    let attachments = result?.attachmentsEmployee?.map((item,index)=>{
                        const file = item as File;
                        return {
                            tempId: Date.now() + index,
                            id: item.attachmentId,
                            file: file,
                            ForDelete: false,
                            noteId: GetMaximumVersionOfNote(note).id,
                            fileName: item.fileName,
                            fileSize: item.fileSize
                        } as AttachmentModel;
                    });
                    
                    setState(state => ({
                        ...state,
                        content: attachments,
                        noteId: GetMaximumVersionOfNote(note).id,
                        internalContent: result.internalContent,
                    }))
                }
                ),
                notesService.getContentByEmployee(note.id, patientId).subscribe(result =>{
                    
                    let attachments = result?.attachmentsEmployee?.map((item,index)=>{
                        const file = item as File;
                        return {
                            tempId: Date.now() + index,
                            id: item.attachmentId,
                            file: file,
                            ForDelete: false,
                            noteId: GetMaximumVersionOfNote(note).id,
                            fileName: item.fileName,
                            fileSize: item.fileSize
                        } as AttachmentModel;
                    });
                    
                    setState(state => ({
                        ...state,
                        originalContent:attachments,
                        noteId: GetMaximumVersionOfNote(note).id,
                        originalInternalContent: result.internalContent,
                    }))
                }
                )
            );
        }

        return () => {
            notesStore.resetTargetContent()
            subscriptions.map(it => it.unsubscribe())
        };
    }, [note, patientId]);

    return [state, handleDownloadPdf, handleChooseNote, isFilesDifferent];
}