import React from 'react';
import { Box, Button, FormControl, MenuItem, Select } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { useFacade } from "./uploadInputsDialog.hooks";
import BackupIcon from '@material-ui/icons/Backup';
import { allowedFileExtensions } from "../../constants/inputs.constants";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import commonUseStyles from '../../../common/styles/common.styles';
import { FileInputDataProvider, FileInputType } from '../../models/input.models';

export const UploadInputsDialog: React.FC = () => {
    const [
        {
            isOpen,
            file,
            type,
            dataProvider,
            dataProviders
        },
        handleFileUpload,
        handleDataProviderSelect,
        handleSubmit,
        handleClose
    ] = useFacade();
    const commonClasses = commonUseStyles();

    const allDataProviders = {
        [FileInputDataProvider.Ancestry]: 'Ancestry',
        [FileInputDataProvider.LabCorpElation]: 'Lab Corp Previous EMR',
        [FileInputDataProvider.LabCorpOriginal]: 'Lab Corp Original',
        [FileInputDataProvider.Andme]: '23andMe',
        [FileInputDataProvider.Gutbio]: 'Gutbio',
        [FileInputDataProvider.Quest]: 'Quest'
    };

    const getAllowedExtensions = (): string => {
        return allowedFileExtensions[`${type}:${dataProvider}`];
    }

    const displayInputType = (type: FileInputType) => {
        switch (type) {
            case FileInputType.DNAReport:
                return "DNA Report";
            case FileInputType.LabResults:
                return "Lab Results";
            case FileInputType.MicrobiomeData:
                return "Microbiome Data";
        }
    }

    const displayFileName = (file) => {
        if (file) {
            return <div> {file.name} </div>
        }

        return <div className={commonClasses.tertiaryFontReport}>No File Chosen</div>
    }
    return (
        <>
            <Dialog maxWidth="md" open={isOpen} onClose={() => handleClose()} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Upload {displayInputType(type)}</DialogTitle>
                <DialogContent>
                    <Box style={{ width: '700px' }} py={3}>
                        <Box display="flex" justifyContent="between">
                            <Box width={0.2}>
                                <Box pt={1}>
                                    File:
                                </Box>
                            </Box>
                            <Box width={0.8} display="flex" justifyContent="space-between">
                                <Box className="compact-text" pt={1}>
                                    {displayFileName(file)}
                                </Box>
                                <Box display="flex" justifyContent="flex-end">
                                    <Button size="small"  className={commonClasses.whButton} component="label">
                                        <BackupIcon />
                                        <input type="file" style={{ display: "none" }}
                                            onChange={(e) => handleFileUpload(e)}
                                            accept={getAllowedExtensions()} />
                                    </Button>
                                </Box>

                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="between" mt={3}>
                            <Box width={0.2}>
                                <Box pt={1}>
                                    Data Provider:
                                </Box>
                            </Box>
                            <Box width={0.8}>
                                <FormControl color='primary' fullWidth size="small" variant="outlined" className="drop-down">
                                    <Select value={dataProvider}
                                        onChange={(v) => handleDataProviderSelect(v.target.value)}>
                                        {
                                            dataProviders.map((dataProvider) => {
                                                return <MenuItem
                                                    key={dataProvider}
                                                    value={dataProvider}>
                                                    {allDataProviders[dataProvider]}
                                                </MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <WildHealthButton
                        id="upload-inputs-cancel"
                        color="secondary"
                        onClick={() => handleClose()}
                    >
                        Cancel
                    </WildHealthButton>
                    <Box pr={2}>
                        <WildHealthButton
                            id="upload-inputs-upload"
                            onClick={() => handleSubmit()}
                        >
                            Upload
                        </WildHealthButton>
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    )
}