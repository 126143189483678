import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles(() =>
    createStyles({
        rootView: {
            fontSize: 14,
            minHeight: 28,
            borderRadius: 2,
            display: 'flex',
            alignItems: 'center',
            width: 'fit-content',
            whiteSpace: 'nowrap',
            paddingRight: 10,
            paddingLeft: 10,
            backgroundColor: colors.separators,
        },
        tooltip: {
            position: 'absolute',
            background: colors.lightBlack,
            color: colors.white,
            boxShadow: ` 0px 4px 9px rgba(168, 173, 198, 0.15)`,
            borderRadius: 2,
            padding: 16,
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 14,
            display: 'none',
            width: 'max-content',
            maxWidth: '35vw',
            marginLeft: '-40px',
            zIndex: 1,
        },
        button: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: '8px',
            color: colors.main,
            fontSize: 14
        },
        iconInfo: {
            cursor: 'pointer',
            '&:hover ~ .tooltip-element': {
                display: 'flex',
            },
        },
        reasonDot: {
            paddingRight: '6px',
            paddingLeft: '6px',
        },
        reasonName: {
            whiteSpace: 'pre-line',
        },
        notificationToolTipText: {
            lineHeight: 1.5,
            fontFamily: 'Be Vietnam Pro',
            fontWeight: 400,
            fontSize: 14,
            color: colors.white,
        },
        arrow: {
            color: colors.lightBlack,
        },
        customWidth: {
            width: '35vw',
            maxWidth: '35vw',
            backgroundColor: colors.lightBlack,
            padding: 16,
            boxShadow: ` 0px 4px 9px rgba(168, 173, 198, 0.15)`,
        },
        tooltipPlacement: {
            margin: '8px 0',
        },
    })
);
