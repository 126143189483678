import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
    createStyles({
        appointmentCard: {
            backgroundColor: "#FFFFFF",
            border: "1px solid #EAEFF5 !important",
            boxShadow: "0px 4px 9px rgba(168, 173, 198, 0.15) !important",
            boxSizing:"border-box",
            borderRadius: "2px !important",
            minWidth:"336px"
        },
        dialogTitle: {
            paddingTop: '30px !important',
            paddingLeft: '40px !important',
            paddingRight: '40px !important',
            paddingBottom: '30px !important'
        },
        dialogContent: {
            paddingLeft: 40,
            paddingRight: 40,
            paddingBottom: 30
        },
        dialogActions: {
            paddingBottom: 30,
            paddingLeft: 40,
            paddingRight: 40
        },
    })
);
