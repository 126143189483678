import { Box, Dialog, DialogContent, Grid, IconButton } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { Group, useFacade } from './attachmentsDialogComponent.hooks';
import commonUseStyles from '../../../common/styles/common.styles';
import { DialogTitle } from '../../../common/components/dialogTitle/DialogTitle';
import { WildHealthTabControl } from '../../../common/components/wildHealthTabControl/WildHealthTabControl';
import { TabItem } from '../../../common/components/wildHealthTabControl/wildHealthTabControl.hooks';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import GetAppIcon from '@material-ui/icons/GetApp';
import { FileName, useStyles } from '../attachmentsComponent/attachmentsComponent.styles';
import { AttachedFileModel } from '../../models/message.models';
import DescriptionIcon from '@material-ui/icons/Description';
import { AttachmentsPlaceHolder } from '../../../common/components/wildHealthPlaceHolder/AttachmentsPlaceHolder';
import { useStylesSimpleTabs } from '../../../common/components/wildHealthTabControl/wildHealthTabControl.styles';
import 'react-awesome-slider/dist/styles.css';
import { SliderDialogComponent } from '../sliderDialog/SliderDialogComponent';
import { conversationsService } from '../../services/conversations.service';

interface AttachmentstDialogComponentProps {
    open: boolean;
    handleClose: () => void;
    conversationProxy: boolean;
}

export const AttachmentsDialogComponent: React.FC<AttachmentstDialogComponentProps> = (props: AttachmentstDialogComponentProps) => {
    const {
        open,
        handleClose,
        conversationProxy
    } = props;

    const [
        {
            groups,
            isSliderDialogOpen,
            selectedNumber,
            isLoading
        },
        handleDownloadAttachment,
        handleToggleSliderDialog,
        setUrl
    ] = useFacade(conversationProxy);

    const commonClasses = commonUseStyles();
    const classes = useStyles()
    const sideBarTabsStyle = useStylesSimpleTabs();

    const renderImage = (media: AttachedFileModel, index: number) => {
        if (!media.url) {
            setUrl(media)
            return (
                <Box
                    key={index}
                    m={1}
                    className={classes.imageFile}
                    style={{ backgroundImage: `url(${media.url})` }}
                >
                    <IconButton
                        id="attachments-download"
                        className={classes.fileButton}
                        onClick={() => handleToggleSliderDialog(true, index)}
                        size="small"
                    >
                        <GetAppIcon className={classes.downloadIcon} />
                    </IconButton>
                </Box>
            );
        }
        return (
            <Box
                key={index}
                m={1}
                className={classes.imageFile}
                style={{ backgroundImage: `url(${media.url})` }}
            >
                <IconButton
                    id="attachments-download"
                    className={classes.fileButton}
                    onClick={() => handleToggleSliderDialog(true, index)}
                    size="small"
                >
                    <GetAppIcon className={classes.downloadIcon} />
                </IconButton>
            </Box>
        );
    };

    const renderPdf = (media: AttachedFileModel, index: number) => {
        return (
            <Grid item md={6} sm={12}>
                <Box key={index} className={classes.document}>
                    <IconButton
                        className={classes.fileButton}
                        id="attachments-download-file"
                        onClick={() => handleDownloadAttachment(media)}
                        size='small'
                    >
                        <DescriptionIcon className={classes.fileIcon} />
                        <GetAppIcon className={classes.downloadIcon} />
                    </IconButton>
                    <Box ml={1}>
                        {conversationProxy ? <FileName primary={media.file.state?.filename ?? media?.fileName} /> : <FileName primary={media.file[0]?.filename} />}
                    </Box>
                </Box>
            </Grid>
        )
    }

    const renderGroup = (group: Group, index: number) => {
        const isImageGroup = group.title.includes('Image');
        const items = group.items.map(isImageGroup ? renderImage : renderPdf);

        return <Box className={clsx(classes.contentContainer, 'flat-scroll')} mt={1}>
            <Grid key={`${index}-fragment`} spacing={1} container>{items}</Grid>
            {isImageGroup && <SliderDialogComponent
                group={groups.find((group) => group.title.includes("Image")) ?? []}
                selectedNumber={selectedNumber}
                open={isSliderDialogOpen}
                handleClose={() => handleToggleSliderDialog(false)}

            />}
        </Box >
    }

    const renderContent = () => {
        let pdfs, images;
        if (groups && groups.length) {
            pdfs = groups.find((group) => group.title.includes("PDFs"));
            images = groups.find((group) => group.title.includes("Image"));
        }
        const tabs: TabItem[] = [
            {
                title: "Images",
                content: images ? (
                    renderGroup(images, 1)
                ) : (
                    <AttachmentsPlaceHolder message="No Images yet." />
                ),
            },
            {
                title: "PDFs",
                content: pdfs ? (
                    renderGroup(pdfs, 0)
                ) : (
                    <AttachmentsPlaceHolder message="No Pdfs yet." />
                ),
            },
        ];
        return (
            <Box width="100%" className={classes.content}>
                <WildHealthTabControl items={tabs} className={sideBarTabsStyle} />
            </Box>
        );
    }

    return (
        <>
            <Dialog
                fullWidth
                maxWidth='sm'
                open={open}
                onClose={handleClose}>

                <DialogTitle
                    id="customized-dialog-title-attachments"
                    classes={{ root: classes.dialogTitle }}
                    onClose={() => handleClose()}
                >
                    <span className={clsx(commonClasses.size24, commonClasses.textMedium)}>
                        Attachments
                    </span>
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    {isLoading
                        ? <WildHealthLinearProgress />
                        : renderContent()}
                </DialogContent>
            </Dialog>
        </>
    );
}