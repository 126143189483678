import {
    Box,
    FormControl,
    FormHelperText,
    Grid,
    MenuItem,
    Select,
    TextField,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import moment from "moment";
import React from 'react';
import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Gender, User } from '../../../common/models/user.models';
import { IErrorState } from "../../../common/validation/error-state";
import commonUseStyles from '../../../common/styles/common.styles';
import { phoneNumberInput } from '../../../common/components/PhoneNumberInput';
import { ReactComponent as CalendarIcon } from '@img/icons/CalendarIcon.svg';
import { useStyles } from "./patientInfoRedesignComponent.styles";
import {Autocomplete} from "@material-ui/lab";
import {addressQuery} from "../../../account/stores/addressStore";
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import { colors } from '../../../common/constants/colors';

interface PatientInfoRedesignComponentProps extends IErrorState {
    payer: User;
    handleChanges: (field: string, value: any) => void;
    displayLoginInfo?: boolean,
    readonlyFields: string[],
    hiddenFields: string[],
    handleValidate: (field: string) => void
}

export const PatientInfoRedesignComponent: React.FC<PatientInfoRedesignComponentProps> = (props: PatientInfoRedesignComponentProps) => {
    const {
        payer,
        handleChanges,
        errors,
        hiddenFields,
        readonlyFields,
        handleValidate
    } = props;

    const commonClasses = commonUseStyles();
    const classes = useStyles();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const getBirthday = () => {
        return payer.birthday
            ? moment(payer.birthday).format('YYYY-MM-DD')
            : null
    }

    return (
        <form autoComplete="off">
            <Box pt={isSmallScreen ? 1 : 2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6} hidden={hiddenFields.includes('firstName')}>
                        <Box className={commonClasses.label} mb={1}>First name*</Box>
                        <TextField
                            fullWidth
                            size="small"
                            required
                            InputProps={{ className: 'input', classes }}
                            id="firstName"
                            placeholder="First name"
                            value={payer.firstName}
                            variant="outlined"
                            disabled={readonlyFields.includes('firstName')}
                            helperText={errors['firstName']}
                            error={!!errors['firstName']}
                            onChange={(v) => handleChanges(v.target.id, v.target.value)}
                            onBlur={() => handleValidate('firstName')}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} hidden={hiddenFields.includes('lastName')}>
                        <Box className={commonClasses.label} mb={1}>Last name*</Box>
                        <TextField
                            fullWidth
                            size="small"
                            required
                            InputProps={{ className: 'input', classes }}
                            id="lastName"
                            placeholder="Last name"
                            value={payer.lastName}
                            variant="outlined"
                            disabled={readonlyFields.includes('lastName')}
                            helperText={errors['lastName']}
                            error={!!errors['lastName']}
                            onChange={(v) => handleChanges(v.target.id, v.target.value)}
                            onBlur={() => handleValidate('lastName')}
                        />
                    </Grid>
                </Grid>
            </Box>
            {isSmallScreen ? (
                <>
                    <Box mt={isSmallScreen ? 1 : 2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} lg={6} hidden={hiddenFields.includes('birthday')}>
                                <FormControl
                                    variant="outlined"
                                    color='primary'
                                    required
                                    error={!!errors[`birthday`]}
                                    size="small"
                                    disabled={readonlyFields.includes('birthday')}
                                    classes={{ root: classes.root }}
                                    fullWidth>
                                    <Box className={commonClasses.label} mb={1}>Date of birth*</Box>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            id={`birthday`}
                                            disableFuture
                                            fullWidth
                                            size="small"
                                            required
                                            placeholder="MM/DD/YYYY"
                                            format="MM/DD/YYYY"
                                            openTo="year"
                                            value={getBirthday()}
                                            helperText={null}
                                            error={!!errors[`birthday`]}
                                            inputVariant="outlined"
                                            disabled={readonlyFields.includes('birthday')}
                                            views={["year", "month", "date"]}
                                            onClick={(e) => e.stopPropagation()}
                                            onChange={(momentDate) => handleChanges(`birthday`, momentDate === null ?
                                                "" : momentDate.format("YYYY-MM-DD"))}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                                "edge": "end",
                                            }}
                                            keyboardIcon={<CalendarIcon fill={colors.gray500}/>}
                                            onBlur={() => handleValidate('birthday')}
                                            maxDate={moment(new Date()).add(-18, 'years')}
                                        />
                                        <FormHelperText error>{errors[`birthday`]}</FormHelperText>
                                    </MuiPickersUtilsProvider>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <Box mb={1} className={commonClasses.label}>
                                    State of Residence*
                                </Box>
                                <Autocomplete
                                    size='small'
                                    disableClearable
                                    placeholder="Select"
                                    classes={{ root: classes.root }}
                                    options={addressQuery.getStates().filter(x => payer.state?.id !== x.id)}
                                    getOptionLabel={x => x.name}
                                    onChange={(e, value) => handleChanges('state', value)}
                                    value={payer.state}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder="Select"
                                            error={!!errors['state']}
                                            InputProps={{ ...params.InputProps, type: 'search' }}
                                        />
                                    )}
                                    onBlur={() => handleValidate('state')}
                                />
                                <FormHelperText error>{errors[`state`]}</FormHelperText>
                            </Grid>
                        </Grid>
                        <Box mt={isSmallScreen ? 1 : 2}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6} lg={6} hidden={hiddenFields.includes('gender')}>
                                    <FormControl
                                        variant="outlined"
                                        color='primary'
                                        required
                                        error={!!errors[`gender`]}
                                        size="small"
                                        disabled={readonlyFields.includes('gender')}
                                        classes={{ root: classes.root }}
                                        fullWidth>
                                        <Box className={commonClasses.label} mb={1}>Select Assigned Sex at Birth*</Box>
                                        <Select
                                            style={{ background: '#FFF' }}
                                            id="gender"
                                            classes={{ root: classes.root }}
                                            value={payer.gender === Gender.None ? '' : payer.gender}
                                            error={!!errors[`gender`]}
                                            inputProps={{
                                                name: 'Biological Gender',
                                                id: 'gender-label',
                                                classes,
                                            }}
                                            renderValue={() => {
                                                if (payer.gender === Gender.None) {
                                                    return <Box className={commonClasses.colorMediumGray}>Select</Box>;
                                                }

                                                return Gender[payer.gender];
                                            }}
                                            displayEmpty
                                            onChange={(v) => handleChanges("gender", v.target.value.toString())}
                                            onBlur={() => handleValidate('gender')}
                                        >
                                            <MenuItem value={Gender.Male}>{Gender[Gender.Male]}</MenuItem>
                                            <MenuItem value={Gender.Female}>{Gender[Gender.Female]}</MenuItem>
                                        </Select>
                                        <FormHelperText>{errors[`gender`]}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box mt={isSmallScreen ? 1 : 2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} lg={6} hidden={hiddenFields.includes('phoneNumber')}>
                                <Box className={commonClasses.label} mb={1}>Phone number*</Box>
                                <TextField
                                    fullWidth
                                    size="small"
                                    required
                                    variant="outlined"
                                    id="phoneNumber"
                                    placeholder="(###) ###-####"
                                    disabled={readonlyFields.includes('phoneNumber')}
                                    helperText={errors['phoneNumber']}
                                    error={!!errors['phoneNumber']}
                                    value={payer.phoneNumber}
                                    InputProps={{ className: 'input', inputComponent: phoneNumberInput, classes }}
                                    onChange={(v) => handleChanges(v.target.id, v.target.value)}
                                    onBlur={() => handleValidate('phoneNumber')}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </>
            ) : (
                <>
                    <Box mt={isSmallScreen ? 1 : 2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} lg={6} hidden={hiddenFields.includes('phoneNumber')}>
                                <Box className={commonClasses.label} mb={1}>Phone number*</Box>
                                <TextField
                                    fullWidth
                                    size="small"
                                    required
                                    variant="outlined"
                                    id="phoneNumber"
                                    placeholder="(###) ###-####"
                                    disabled={readonlyFields.includes('phoneNumber')}
                                    helperText={errors['phoneNumber']}
                                    error={!!errors['phoneNumber']}
                                    value={payer.phoneNumber}
                                    InputProps={{ className: 'input', inputComponent: phoneNumberInput, classes }}
                                    onChange={(v) => handleChanges(v.target.id, v.target.value)}
                                    onBlur={() => handleValidate('phoneNumber')}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6} hidden={hiddenFields.includes('birthday')}>
                                <FormControl
                                    variant="outlined"
                                    color='primary'
                                    required
                                    error={!!errors[`birthday`]}
                                    size="small"
                                    disabled={readonlyFields.includes('birthday')}
                                    classes={{ root: classes.root }}
                                    fullWidth>
                                    <Box className={commonClasses.label} mb={1}>Date of birth*</Box>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            id={`birthday`}
                                            disableFuture
                                            fullWidth
                                            size="small"
                                            required
                                            placeholder="MM/DD/YYYY"
                                            format="MM/DD/YYYY"
                                            openTo="year"
                                            value={getBirthday()}
                                            helperText={null}
                                            error={!!errors[`birthday`]}
                                            inputVariant="outlined"
                                            disabled={readonlyFields.includes('birthday')}
                                            views={["year", "month", "date"]}
                                            onClick={(e) => e.stopPropagation()}
                                            onChange={(momentDate) => handleChanges(`birthday`, momentDate === null ?
                                                "" : momentDate.format("YYYY-MM-DD"))}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                                "edge": "end",
                                            }}
                                            keyboardIcon={<CalendarIcon fill={colors.gray500}/>}
                                            onBlur={() => handleValidate('birthday')}
                                            maxDate={moment(new Date()).add(-18, 'years')}
                                        />
                                        <FormHelperText error>{errors[`birthday`]}</FormHelperText>
                                    </MuiPickersUtilsProvider>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box mt={isSmallScreen ? 1 : 2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} lg={6}>
                                <Box mb={1} className={commonClasses.label}>
                                    State of Residence*
                                </Box>
                                <Autocomplete
                                    size='small'
                                    disableClearable
                                    placeholder="Select"
                                    classes={{ root: classes.root }}
                                    options={addressQuery.getStates().filter(x => payer.state?.id !== x.id)}
                                    getOptionLabel={x => x.name}
                                    onChange={(e, value) => handleChanges('state', value)}
                                    value={payer.state}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder="Select"
                                            error={!!errors['state']}
                                            InputProps={{ ...params.InputProps, type: 'search' }}
                                        />
                                    )}
                                    onBlur={() => handleValidate('state')}
                                />
                                <FormHelperText error>{errors[`state`]}</FormHelperText>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6} hidden={hiddenFields.includes('gender')}>
                                <FormControl
                                    variant="outlined"
                                    color='primary'
                                    required
                                    error={!!errors[`gender`]}
                                    size="small"
                                    disabled={readonlyFields.includes('gender')}
                                    classes={{ root: classes.root }}
                                    fullWidth>
                                    <Box className={commonClasses.label} mb={1}>Select Assigned Sex at Birth*</Box>
                                    <Select
                                        style={{ background: '#FFF' }}
                                        id="gender"
                                        classes={{ root: classes.root }}
                                        value={payer.gender === Gender.None ? '' : payer.gender}
                                        error={!!errors[`gender`]}
                                        inputProps={{
                                            name: 'Biological Gender',
                                            id: 'gender-label',
                                            classes,
                                        }}
                                        renderValue={() => {
                                            if (payer.gender === Gender.None) {
                                                return <Box className={commonClasses.colorMediumGray}>Select</Box>;
                                            }

                                            return Gender[payer.gender];
                                        }}
                                        displayEmpty
                                        onChange={(v) => handleChanges("gender", v.target.value.toString())}
                                        onBlur={() => handleValidate('gender')}
                                    >
                                        <MenuItem value={Gender.Male}>{Gender[Gender.Male]}</MenuItem>
                                        <MenuItem value={Gender.Female}>{Gender[Gender.Female]}</MenuItem>
                                    </Select>
                                    <FormHelperText>{errors[`gender`]}</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            )}
        </form>
    )
}

export const PatientInfoComponent: React.FC<PatientInfoRedesignComponentProps> = (props: PatientInfoRedesignComponentProps) => {
    const {
        payer,
        handleChanges,
        errors,
        hiddenFields,
        readonlyFields,
        handleValidate
    } = props;

    const commonClasses = commonUseStyles();
    const classes = useStyles();

    const getBirthday = () => {
        return payer.birthday
            ? moment(payer.birthday).format('YYYY-MM-DD')
            : null
    }

    return (
        <form autoComplete="off">
            <Box pt={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12} hidden={hiddenFields.includes('firstName')}>
                        <Box mb={1}>
                            <span className={classes.label}>First name <span className={commonClasses.colorMint}>*</span></span>
                        </Box>
                        <TextField
                            fullWidth
                            size="small"
                            required
                            InputProps={{ className: 'input', classes }}
                            id="firstName"
                            placeholder="First name"
                            value={payer.firstName}
                            variant="outlined"
                            disabled={readonlyFields.includes('firstName')}
                            helperText={errors['firstName']}
                            error={!!errors['firstName']}
                            onChange={(v) => handleChanges(v.target.id, v.target.value)}
                            onBlur={() => handleValidate('firstName')}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} hidden={hiddenFields.includes('lastName')}>
                        <Box mb={1}>
                            <span className={classes.label}>Last name <span className={commonClasses.colorMint}>*</span></span>
                        </Box>
                        <TextField
                            fullWidth
                            size="small"
                            required
                            InputProps={{ className: 'input', classes }}
                            id="lastName"
                            placeholder="Last name"
                            value={payer.lastName}
                            variant="outlined"
                            disabled={readonlyFields.includes('lastName')}
                            helperText={errors['lastName']}
                            error={!!errors['lastName']}
                            onChange={(v) => handleChanges(v.target.id, v.target.value)}
                            onBlur={() => handleValidate('lastName')}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} hidden={hiddenFields.includes('birthday')}>
                        <FormControl
                            variant="outlined"
                            color='primary'
                            required
                            error={!!errors[`birthday`]}
                            size="small"
                            disabled={readonlyFields.includes('birthday')}
                            classes={{ root: classes.root }}
                            fullWidth>
                            <Box mb={1}>
                                <span className={classes.label}>Date of birth <span className={commonClasses.colorMint}>*</span></span>
                            </Box>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    id={`birthday`}
                                    disableFuture
                                    fullWidth
                                    size="small"
                                    required
                                    placeholder="MM/DD/YYYY"
                                    format="MM/DD/YYYY"
                                    openTo="year"
                                    value={getBirthday()}
                                    helperText={null}
                                    error={!!errors[`birthday`]}
                                    inputVariant="outlined"
                                    disabled={readonlyFields.includes('birthday')}
                                    views={["year", "month", "date"]}
                                    onClick={(e) => e.stopPropagation()}
                                    onChange={(momentDate) => handleChanges(`birthday`, momentDate === null ?
                                        "" : momentDate.format("YYYY-MM-DD"))}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                        "edge": "end",
                                    }}
                                    keyboardIcon={<CalendarIcon fill={colors.gray500}/>}
                                    onBlur={() => handleValidate('birthday')}
                                    maxDate={moment(new Date()).add(-18, 'years')}
                                />
                                <FormHelperText error>{errors[`birthday`]}</FormHelperText>
                            </MuiPickersUtilsProvider>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} hidden={hiddenFields.includes('gender')}>
                        <FormControl
                            variant="outlined"
                            color='primary'
                            required
                            error={!!errors[`gender`]}
                            size="small"
                            disabled={readonlyFields.includes('gender')}
                            classes={{ root: classes.root }}
                            fullWidth>
                            <Box mb={1}>
                                <span className={classes.label}>Sex Assigned at Birth <span className={commonClasses.colorMint}>*</span></span>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <Box className={payer.gender === Gender.Male ? classes.checked : classes.unchecked} onClick={() => handleChanges("gender", Gender.Male)}>
                                    {payer.gender === Gender.Male && <CheckOutlinedIcon className={classes.checkIcon} />}
                                    {Gender[Gender.Male]}
                                </Box>
                                <Box mx={1} />
                                <Box className={payer.gender === Gender.Female ? classes.checked : classes.unchecked} onClick={() => handleChanges("gender", Gender.Female)}>
                                    {payer.gender === Gender.Female && <CheckOutlinedIcon className={classes.checkIcon} />}
                                    {Gender[Gender.Female]}
                                </Box>
                            </Box>
                            <FormHelperText>{errors[`gender`]}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} hidden={hiddenFields.includes('phoneNumber')}>
                        <Box mb={1}>
                            <span className={classes.label}>Phone <span className={commonClasses.colorMint}>*</span></span>
                        </Box>
                        <TextField
                            fullWidth
                            size="small"
                            required
                            variant="outlined"
                            id="phoneNumber"
                            placeholder="(###) ###-####"
                            disabled={readonlyFields.includes('phoneNumber')}
                            helperText={errors['phoneNumber']}
                            error={!!errors['phoneNumber']}
                            value={payer.phoneNumber}
                            InputProps={{ className: 'input', inputComponent: phoneNumberInput, classes }}
                            onChange={(v) => handleChanges(v.target.id, v.target.value)}
                            onBlur={() => handleValidate('phoneNumber')}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} hidden={hiddenFields.includes('state')}>
                        <Box mb={1}>
                            <span className={classes.label}>State of Residence <span className={commonClasses.colorMint}>*</span></span>
                        </Box>
                        <Autocomplete
                            size='small'
                            disableClearable
                            placeholder="Select"
                            classes={{ root: classes.root }}
                            options={addressQuery.getStates().filter(x => payer.state?.id !== x.id)}
                            getOptionLabel={x => x.name}
                            onChange={(e, value) => handleChanges('state', value)}
                            value={payer.state}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Select"
                                    error={!!errors['state']}
                                    InputProps={{ ...params.InputProps, type: 'search' }}
                                />
                            )}
                            onBlur={() => handleValidate('state')}
                        />
                        <FormHelperText error className={classes.helperText}>{errors[`state`]}</FormHelperText>
                    </Grid>
                </Grid>
            </Box>
        </form>
    )
}
