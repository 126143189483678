import {RosterModel} from "../../models/roster.model";
import {Store, StoreConfig} from "@datorama/akita";

export interface RostersState {
    rosters: RosterModel[];
}

/**
 * Creates initial state
 */
export function createInitialState(): RostersState {
    return {
        rosters: []
    };
}

/**
 * Provides roster state management
 */
@StoreConfig({ name: 'rosters', resettable: true })
export class RostersStore extends Store<RostersState> {
    constructor() {
        super(createInitialState());
    }

    public addRoster(roster: RosterModel) {
        const rosters = this.getValue().rosters.slice();
        rosters.push(roster);
        this.update({rosters: rosters});
    }

    public updateRoster(roster: RosterModel) {
        const rosters = this.getValue().rosters.slice();

        if (rosters.find(x => x.id === roster.id)) {
            this.update({rosters: rosters.map(x => x.id === roster.id ? roster : x)});
        } else {
            this.addRoster(roster);
        }
    }
}

export const rostersStore = new RostersStore();