import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { onEmit } from "../../../common/helpers/on-emit";
import { handleCompare } from "../../../common/sorting/helpers/handle-compare";
import { SortingDirection } from "../../../common/sorting/models/sorting-destination";
import { ISortingState } from "../../../common/sorting/models/sorting-state";
import { pageSizes } from "../../../common/pagination/models/page-sizes";
import { IPaginationState } from "../../../common/pagination/models/pagination-state";
import { getAvailablePages } from "../../../common/pagination/helpers/get-evailable-pages";
import { employerProductsService } from "../../services/employerProducts.service";
import { EmployerProductModel } from "../../models/employerProduct.model";
import { employerProductsQuery } from "../../stores/employerProductsStore";

export enum SortingSource {
    None,
    Name = "name",
    Key = "key"
}

interface ManageEmployersState extends IPaginationState, ISortingState {
    isLoading: boolean;
    employers: EmployerProductModel[];
    originalEmployers: EmployerProductModel[];
    isOpenEmployerDetail: boolean;
    selectedEmployerId: number;
    page: number;
    rowsPerPage: number;
}

export function useFacade(): [
    ManageEmployersState,
    (query: string) => void,
    (source: string, direction: SortingDirection) => void,
    () => number[],
    (value: any) => void,
    (page: number) => void,
    (status: boolean, employerId: number) => void
] {

    const [state, setState] = useState({
        isLoading: true,
        employers: [],
        originalEmployers: [],
        isOpenEmployerDetail: false,
        selectedEmployerId: 0,
        sortingColumns: [
            {title: 'Name', source: SortingSource.Name, direction: SortingDirection.Asc},
            {title: 'Key', source: SortingSource.Key, direction: SortingDirection.Asc},
        ],
        sortingSource: SortingSource.None,
        page: 0,
        rowsPerPage: pageSizes[0],
        totalCount: 0,
        selectedPage: 1,
        pageSize: pageSizes[0]
    } as ManageEmployersState);

    const setEmployers = (employers: EmployerProductModel[]) => {
        setState((state) => {
            return {
                ...state,
                isLoading: false,
                employers: employers,
                totalCount: employers.length,
            }
        });
    }

    const handleSearchSubmit = (query: string) => {
        const searchQuery = query ?? '';
        setState(state => ({
            ...state,
            selectedPage: 1,
            isLoading: true
        }));

        const trimedSearch = decodeURIComponent(searchQuery).trim().toLowerCase();
        const arrayTrimSearchKey = trimedSearch.split(' ');

        const filteredEmployers = state.originalEmployers?.filter(
            (e) =>
                arrayTrimSearchKey.find((item) =>
                    e.name?.toLowerCase().includes(item)
                ) !== undefined ||
                arrayTrimSearchKey.find((item) =>
                    e.key?.toLowerCase().includes(item)
                ) !== undefined
        );
        setEmployers(filteredEmployers)
    }

    const getAllAvailablePages = () => {
        return getAvailablePages(state);
    }

    const handlePageSizeChange = (value: any) => {
        if (value === state.pageSize) {
            return;
        }

        setState(state => ({
            ...state,
            pageSize: value,
            selectedPage: 1
        }));
    }

    const handlePageChange = (page: number) => {
        if (page === state.selectedPage) {
            return;
        }

        setState(state => ({
            ...state,
            selectedPage: page
        }));
    }

    const setDirection = (source: string, direction: SortingDirection) => {
        let itemIndex = state.sortingColumns.findIndex(item => item.source === source);
        state.sortingColumns[itemIndex].direction = direction;
        setState(state => ({...state, columns: state.sortingColumns}));
    }

    const handleSorting = (source: string, direction: SortingDirection) => {
        if (state.sortingSource === source) {
            direction = direction === SortingDirection.Asc
                ? SortingDirection.Desc
                : SortingDirection.Asc;

            setDirection(source, direction);
        }

        setState(state => ({
            ...state,
            sortingSource: source,
            employers: state.employers.sort((e1, e2) => handleCompare(e1, e2, direction, source))
        }));
    }

    const handleToggleEmployerDetail = (status: boolean, employerId: number) => {
        setState(state => ({
            ...state,
            isOpenEmployerDetail: status,
            selectedEmployerId: employerId
        }))
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<EmployerProductModel[]>(employerProductsQuery.employerProducts$, employerProducts => {
                const employers = employerProducts.filter(e => !e.isDefault)
                setState(state => ({
                    ...state,
                    employers: employers,
                    originalEmployers: employers,
                    totalCount: employers.length
                }))
            }),
        ];

        const cb = () => setState(state => ({ ...state, isLoading: false }));

        employerProductsService.getAllEmployerProducts().subscribe(cb, cb);

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, []);

    return [
        state,
        handleSearchSubmit,
        handleSorting,
        getAllAvailablePages,
        handlePageSizeChange,
        handlePageChange,
        handleToggleEmployerDetail
    ];
}