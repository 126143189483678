import { PaymentStore, paymentStore } from '../stores/paymentStore';
import Axios from 'axios-observable';
import { snackService } from "../../common/snack/state";
import { API_URL } from "../../../config";
import { IntegrationVendor, PaymentIntentModel } from '../models/payment.models';
import { Observable } from 'rxjs';
import { authHeader, authHeaderForStripe } from '../../common/helpers/auth-header';
import { RoutesConstants } from '../../../constants/route.constants';

/**
 * Provides method for working with payments
 */
export class PaymentService {
    constructor(private paymentStore: PaymentStore) {
    }

     /**
     * Returns merchant credentials
     */
    public getCredentials(practiceId: number): void {
        let url = `${API_URL}payments/credentials?practiceId=${practiceId}`;
        Axios.get(url)
            .pipe()
            .subscribe(
                (resp) => {
                    this.paymentStore.update({ credentials: resp.data });
                },
                error => snackService.commonErrorHandler(error)
            );
    }


    /**
    *  Checking  integration system
    */
    public isIntegratedWithPaymentSystem(patientId: number, vendor: IntegrationVendor): Observable<any> {

        const config = { headers: authHeader() };

        let url = `${API_URL}Payments/LinkedWithIntegrationSystem?patientId=${patientId}&vendor=${vendor}`;

        return new Observable((observer) => {
            Axios.get<any>(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    /**
    *  Returns link for editing
    */
    public getPatientSubscriptionsLink(): Observable<any> {
        const config = { headers: authHeader() };
        let url = `${API_URL}Payments/CustomerPortal`;

        return new Observable((observer) => {
            Axios.get<any>(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getPatientBuyProducts(productId: number): Observable<string> {
        const config = { headers: authHeader() };

        let url = `${API_URL}Payments/buy-products?productId=${productId}`;

        return new Observable((observer) => {
            Axios.get<any>(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getPatientBuyProductsOnBehalf(productId: number, patientId: number): Observable<string> {
        const config = { headers: authHeader() };

        let url = `${API_URL}Payments/buy-products-on-behalf?productId=${productId}&patientId=${patientId}`;

        return new Observable((observer) => {
            Axios.get<any>(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }
    public createStripePaymenIntent(model: PaymentIntentModel): Observable<any> {
        const config = {
            headers: authHeaderForStripe()
        };
        const params = new URLSearchParams()
        params.append('amount', `${model.amount}`)
        params.append('currency', `${model.currency}`)
        params.append('payment_method_types[]', 'card')

        return new Observable(observer => {
            Axios.post(`${RoutesConstants.stripeApiUrl}/payment_intents`, params, config)
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }
}

export const paymentService = new PaymentService(paymentStore);
