import { Store, StoreConfig } from '@datorama/akita';
import { MenuItemTypes } from '../../../../models/menu.models';
import { MetaTagsModel } from '../../models/metaTags.models';
import { PatientNpsScoreModel, PaymentPlanHistoryModel } from '../../../patients/models/patient.model';

/**
 * Represents patientProfile state
 */
export interface PatientProfileState {
    selectedTab: string;
    patientId: number;
    pathHistory: string;
    metaTagsModel: MetaTagsModel;
    npsScores: PatientNpsScoreModel[];
    paymentPlanHistories: PaymentPlanHistoryModel[];
}

/**
 * Creates initial state
 */
export function createInitialState(): PatientProfileState {
    return {
        selectedTab:  `${MenuItemTypes.Profile}`,
        patientId: 0,
        pathHistory: "",
        metaTagsModel: null,
        npsScores: [],
        paymentPlanHistories: []
    };
}

/**
 * Provides patientProfile states management
 */
@StoreConfig({name: 'patientProfile', resettable: true })
export class PatientProfileStore extends Store<PatientProfileState> {
    constructor() {
        super(createInitialState());
    }

    public setTab(tab: string) {
        this.update({selectedTab: tab});
    }

    public setPath(pathHistory){
        this.update({pathHistory:pathHistory});
    }

    public setPatientId(patientId: number) {
        this.update({patientId: patientId});
    }

    public SetMetaTags(model: MetaTagsModel) {
        this.update({ metaTagsModel: model });
    }
}

export const patientProfileStore = new PatientProfileStore();
