import {Paper, Box} from '@material-ui/core';
import React from 'react';
import { LayoutComponent } from '../../../../components/layoutComponent/LayoutComponent';
import { TitlesService } from "../../../../constants/title.constants";
import { WaitListDialogComponent } from "../../components/waitListDialogComponent/WaitListDialogComponent";
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from 'clsx';
import HeaderProgressBar from "../../components/finishCheckoutRedesignComponent/headerProgressBar/HeaderProgressBar";
import {useStyles} from "./preauthorizeSignUpPage.styles";
import {useFacade} from "./preauthorizeSignUpPage.hooks";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";

export const PreauthorizeSignUpPage: React.FC = (props: any) => {
    const requestId = props?.match?.params?.requestId ?? new URLSearchParams(props.location.search).get('requestId');

    const [
        page,
        {
            isLoading,
            progress,
            progressBarOpened,
            bannerHeight
        },
        isOnboardingRedesign,
        prevScrollY
    ] = useFacade(requestId);

    const classes = useStyles();
    const commonClasses = commonUseStyles();

    return (
        <Box>
            <div ref={prevScrollY}>
                <HeaderProgressBar progress={progress} isOpened={progressBarOpened} />
            </div>
            <LayoutComponent title={TitlesService.getStartedTitle} employerKey={false} redesign={isOnboardingRedesign} topBannerHeight={bannerHeight}>
                <Paper
                    className={clsx(classes.content, commonClasses.whWhiteForm)}
                    variant="elevation"
                    square
                >
                    {
                        isLoading
                            ? <WildHealthLinearProgress/>
                            : page
                    }
                </Paper>
                <WaitListDialogComponent />
            </LayoutComponent>
        </Box>
    )
}
