import {createStyles, makeStyles} from "@material-ui/core"
import DeleteIcon from '@material-ui/icons/Delete'
import React from "react";
import {colors} from "../../constants/colors";

const useStyles = makeStyles(() =>
    createStyles({
        icon: {
            fill: colors.main,
        }
    })
)
export function WildHealthTrashIcon({handleOnClick}) {

    const classes = useStyles();

    return (
        <DeleteIcon className={classes.icon} onClick={handleOnClick}/>
    )
}