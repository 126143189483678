import { ValidationRule } from "./validation-rule";
import moment from "moment";

export const optionalValidation = (emptyValue: any, validationRules: ValidationRule[]): ValidationRule => {
    const message = validationRules.map(x => x.message).join('. ');
    return {
        function: (value) => {
            if (value === emptyValue) {
                return true;
            }

            return validationRules.every(rule => rule.function(value));
        },
        message: message
    }
}

export const regexpValidationRule = (regexp: RegExp, message: string = null): ValidationRule => {
    return {
        function: (value) => {
            return regexp.test(value ?? "");
        },
        message: message ?? 'Value is incorrect.'
    }
}

export const rangeValidationRule = (from: number | null = null, to: number | null = null, message: string = null): ValidationRule => {
    return {
        function: (value: number) => {
            if (from !== null && +value < from) {
                return false;
            }

            if (to !== null && +value > to) {
                return false;
            }

            return true;
        },
        message: message ?? `Value should be in range from ${from} to ${to}.`
    }
}

export const nullableRangeValidationRule = (from: number | null = null, to: number | null = null, message: string = null): ValidationRule => {
    return {
        function: (value) => {
            if (value.toString().length === 0) {
                return true;
            }

            if (isNaN(value)) {
                return true;
            }

            const rule = rangeValidationRule(from, to, message);
            return rule.function(value);
        },
        message: message ?? `Value should be in range from ${from} to ${to}.`
    }
}

export const notWhitespaceValidationRule = (message: string = null): ValidationRule => {
    return {
        function: (value) => {
            return !value.length ? true : value.trim() !== '';
        },
        message: message ?? `Whitespaces are wrong format.`
    }
}

export const notIncludeWhitespaceValidationRule = (message: string = null): ValidationRule => {
    return {
        function: (value) => {
            return !/\s/g.test(value);
        },
        message: message ?? `Value should not include the whitespace.`
    }
}

export const notEmptyValidationRule = (message: string = null): ValidationRule => {
    return {
        function: (value) => {
            return value !== null && value !== '';
        },
        message: message ?? `Please, enter a value.`
    }
}

export const notEmptyArrayValidationRule = (message: string = null): ValidationRule => {
    return {
        function: (value) => {
            if (Array.isArray(value)) {
                return value.length > 0;
            }

            return false;
        },
        message: message ?? `Please, enter a value.`
    }
}

export const notNegativeValidationRule = (message: string = null): ValidationRule => {
    return {
        function: (value) => {
            return value >= 0;
        },
        message: message ?? `This field should be only a positive value.`
    }
}

export const stringLengthValidationRule = (from: number = null, to: number = null, message: string = null): ValidationRule => {
    return {
        function: (value) => {
            if (from !== null && value.length < from) {
                return false;
            }

            if (to !== null && value.length > to) {
                return false;
            }

            return true;
        },
        message: message ?? `Length should be in range from ${from} to ${to}.`
    }
}

export const functionValidationRule = (func: Function, message: string = null): ValidationRule => {
    return {
        function: func,
        message: message ?? `Value is incorrect.`
    }
}

export const requiredValidationRule = (from: number = null, message = null): ValidationRule => {
    return stringLengthValidationRule(from ?? 1, null, message ?? 'This field is required.');
}

export const maxLengthValidationRule = (to: number, message = null): ValidationRule => {
    return stringLengthValidationRule(null, to, message ?? `Max length is ${to}.`);
}

export const firstNameValidationRule = stringLengthValidationRule(1, 50, 'First name should be between 1 and 50 characters.');

export const lastNameValidationRule = stringLengthValidationRule(1, 50, 'Last name should be between 1 and 50 characters.');

/* eslint-disable */
export const emailValidationRule = regexpValidationRule(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, 'Invalid email address.');

export const phoneNumberValidationRule = functionValidationRule((phoneNumber) => {
    const oldValidation = regexpValidationRule(new RegExp('^\\([0-9]{3}\\) [0-9]{3}-[0-9]{4}$'), 'Invalid phone number.');
    const newValidation = phoneNumberValidationTestedRule;

    if (oldValidation.function(phoneNumber)) {
        return true;
    }

    if (newValidation.function(phoneNumber)) {
        return true;
    }

    return false
}, 'Invalid phone number.');

export const phoneNumberValidationTestedRule = regexpValidationRule(new RegExp('^\\+[1]{1} \\([0-9]{3}\\) [0-9]{3}-[0-9]{4}$'), 'Invalid phone number.');

export const linkSegmentValidationRule = regexpValidationRule(new RegExp('^([\\w\\d]+)(\\-[\\w\\d]+)*$', 'i'), 'Link is invalid.');

export const checkQuestionValidationRule = regexpValidationRule(new RegExp('(\\[.{1,}\\])|("o":"[A-z0-9]{1,})'), 'This field is required.');

export const linkValidationRule = functionValidationRule((value) => {
    if (!value)
        return true;

    return new RegExp('^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$', 'i').test(value);
}, 'Link is invalid.');

export const integerValidationRule = functionValidationRule((value) => {
    return Number.isInteger(+value);
}, "Value should be integer.")

export const birthdayValidationRule: ValidationRule = {
    function: (value) => {
        try {
            const data = moment(value).format('MM/DD/YYYY');
            const regex = new RegExp(/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/);
            if (moment(data).isAfter(moment(new Date()))) return false;
            return regex.test(data ?? "");
        } catch {
            return false;
        }
    },
    message: 'Invalid date of birth.'
} as ValidationRule

export const newBirthdayValidationRule: ValidationRule = {
    function: (value) => {
        try {
            if (value === 'Invalid date') return true;
            const data = moment(value).format('MM/DD/YYYY');
            const regex = new RegExp(/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/);
            if (moment(data).isAfter(moment(new Date()))) return false;
            return regex.test(data ?? "");
        } catch {
            return false;
        }
    },
    message: 'Invalid date of birth.'
} as ValidationRule

export const adultValidationRule: ValidationRule = {
    function: (value) => {
        try {
            if (value === 'Invalid date') return true;
            const data = moment(value).format('MM/DD/YYYY');
            const regex = new RegExp(/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/);
            if (moment(data).isAfter(moment(new Date()).add(-18, 'years'))) return false;
            return regex.test(data ?? "");
        } catch {
            return false;
        }
    },
    message: 'You must be 18 years old or older to sign up.'
} as ValidationRule

export const nextAppointmentDateValidationRule: ValidationRule = {
    function: (value) => {
        try {
            const data = moment(value).format('MM/DD/YYYY');
            const regex = new RegExp(/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/);
            if (!moment(data).isAfter(moment(new Date()))) return false;
            return regex.test(data ?? "");
        } catch {
            return false;
        }
    },
    message: 'Invalid date format.'
} as ValidationRule

export const addressValidationRules = {
    streetAddress1: stringLengthValidationRule(1, 100),
    streetAddress2: stringLengthValidationRule(0, 100),
    city: stringLengthValidationRule(1, 50),
    state: stringLengthValidationRule(1, 50),
    country: stringLengthValidationRule(1, 50),
    zipCode: stringLengthValidationRule(1, 10)
}

export const checkManyQuestionValidationRule = stringLengthValidationRule(0, 350, 'Length should be in range only to 350.')

export const numberValidationRule: ValidationRule = {
    function: (value) => {
        try {
            if (isNaN(Number(value))) return false;
            if (Number(value) > 0) return true;
            return false;
        } catch {
            return false;
        }
    },
    message: 'Invalid value'
} as ValidationRule

export const minNumberValidationRule = (from: number, message: string | null = null): ValidationRule => ({
  function: (value: number) => {
    if (value < from) {
      return false;
    }
    return true;
  },
  message: message ?? `Invalid value`,
});

export const maxNumberValidationRule = (to: number, message: string | null = null): ValidationRule => ({
  function: (value: number) => {
    if (value > to) {
      return false;
    }
    return true;
  },
  message: message ?? `Invalid value`,
});