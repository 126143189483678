import React from "react"
import { Box, Dialog, DialogContent, Button, useTheme, useMediaQuery } from "@material-ui/core";
import {DialogTitle} from '../../../common/components/dialogTitle/DialogTitle';
import { SelectUserComponent } from "../../../common/components/selectUserComponent/SelectUserComponent";
import { useFacade } from "./createInternalConversationDialogComponent.hooks";
import { MultiSelectUserComponent } from "../../../common/components/selectUserComponent/MultiSelectUserComponent";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { useStyles } from "./createInternalConversationDialogComponent.styles";

interface CreateInternalConversationDialogComponentProps {
    isOpen: boolean;
    handleCancel: () => void;
    handleSubmit: (patientId: number, employeeIds: number[]) => void;
}

export const CreateInternalConversationDialogComponent: React.FC<CreateInternalConversationDialogComponentProps> = (props: CreateInternalConversationDialogComponentProps) => {
    const { isOpen, handleCancel, handleSubmit } = props;

    const [
        {
            isPatientsLoading,
            selectedUsersIds,
            selectedPatient,
            isUsersLoading,
            canSubmit,
            patients,
            users,
        },
        handlePatientSelect,
        handleUsersSelect,
        handlePatientSearch,
        handleUserSearch,
        handleCloseDialog,
        handleApprove
    ] = useFacade(handleSubmit, handleCancel);

    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <Dialog
            fullScreen={isSmallScreen}
            maxWidth="md"
            className={classes.dialog}
            open={isOpen}
            onClose={() => handleCloseDialog()}
            aria-labelledby="form-dialog-title"
        >
            {
                !isSmallScreen &&
                <DialogTitle id='dialog-title' onClose={() => handleCloseDialog()}>New Internal Message</DialogTitle>
            }
            <DialogContent className={classes.dialogContent}>
                <Box width={isSmallScreen ? 1 : "548px"}>
                    {
                        isSmallScreen &&
                        <Box pt={1} pb={4} display="flex" justifyContent="space-between">
                            <Button onClick={() => handleCloseDialog()}>
                                <span className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-primaryV wh-tw-capitalize">
                                    Cancel
                                </span>
                            </Button>
                            <Box>
                                <span className="wh-tw-text-xl wh-tw-font-medium wh-tw-text-black">
                                    New Internal Message
                                </span>
                            </Box>
                            <Button disabled={!canSubmit} onClick={() => handleApprove()}>
                                <span className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-primaryV wh-tw-capitalize">
                                    Create
                                </span>
                            </Button>
                        </Box>
                    }
                    <Box>
                        <SelectUserComponent
                            title='Select Patient'
                            placeholder='Select Patient'
                            selectedUser={selectedPatient}
                            users={patients}
                            handleChange={(user) => handlePatientSelect(user)}
                            handleInputChange={handlePatientSearch}
                            isLoading={isPatientsLoading}
                        />
                    </Box>
                    <Box mt={2}>
                        <MultiSelectUserComponent
                            title='Select Health Coach/Provider/Staff'
                            users={users}
                            selectedUsersIds={selectedUsersIds}
                            handleUsersSelect={(users) => handleUsersSelect(users)}
                            handleInputChange={handleUserSearch}
                            isLoading={isUsersLoading}
                        />
                    </Box>
                    {
                        !isSmallScreen &&
                        <Box display="flex" justifyContent="flex-end" py={2}>
                            <Box>
                                <WildHealthButton
                                    id="create-internal-conversation-cancel"
                                    onClick={() => handleCloseDialog()}
                                    color='tertiary'
                                    size='large'
                                >
                                    Cancel
                                </WildHealthButton>
                            </Box>
                            <Box ml={1}>
                                <WildHealthButton
                                    id="create-internal-conversation-new-message"
                                    disabled={!canSubmit}
                                    onClick={() => handleApprove()}
                                    size='large'
                                >
                                    Create New Internal Message
                                </WildHealthButton>
                            </Box>
                        </Box>
                    }
                </Box>
            </DialogContent>
        </Dialog>
    )
}
