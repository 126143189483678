import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { colors } from "../../constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
            [theme.breakpoints.down("xs")]: {
                '& .MuiDialog-paper': {
                    borderRadius: '10px 10px 0 0',
                    margin: 0,
                    marginTop: 'auto'
                },
            },
        },
        dialogCloseButton: {
            [theme.breakpoints.down("xs")]: {
                position: 'absolute',
                top: 0,
                right: 0,
            },
        },
        dialogTitle: {
            display: 'flex',
            justifyContent: "flex-end",
            margin: 0,
            paddingRight: 10,
            paddingLeft: 10,
            paddingTop: 10,
            paddingBottom: 0,
        },
        content: {
            display: "flex",
            flexDirection: "column",
            alignItems: 'center',
            maxWidth: 640,
            paddingTop: 0,
            [theme.breakpoints.down("xs")]: {
                marginRight: 0,
                marginLeft: 0,
            },
        },
        action: {
            display: 'flex',
            justifyContent: 'center',
            color: colors.main,
            paddingRight: theme.spacing(5),
            paddingLeft: theme.spacing(5),
            paddingBottom: theme.spacing(4),
            paddingTop: theme.spacing(6),
            [theme.breakpoints.down("xs")]: {
                paddingRight: theme.spacing(3),
                paddingLeft: theme.spacing(3),
                paddingTop: theme.spacing(2.5),
            },
        },
    }),
);
