import {
    Box,
    Button,
    Collapse,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Popover
} from "@material-ui/core";
import React, { useState } from 'react';
import { useFacade } from "./profileComponent.hooks";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { displayLocation } from "../../../locations/helpers/displayLocation";
import { ProtectedElement } from "../../../common/components/ProtectedElement";
import { Practice, UserType } from "../../../auth/models/auth.enums";
import { OptionalComponent } from "../../../common/components/OptionalComponent";
import { FeatureComponent } from "../../../common/components/featureFlags/FeatureComponent";
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import { insuranceQuery } from "../../../insurance/stores/insurance.query";

const profilePopoverProps: any = {
    id: "profilePopover",
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
    }
};

export const ProfileComponent: React.FC = () => {
    const [
        {
            anchorEl,
            userName,
            locations,
            targetLocationId,
            isOpen,
        },
        handleToggle,
        handleSwitchLocation,
        handleGoToMyAccount,
        handleGoToMyMembership,
        handleLogOut,
        handleGoToMyInsurance
    ] = useFacade();

    const [locationsIsOpen, setLocationsIsOpen] = useState(false);

    const renderLocationList = () => {
        if (!locations || !locations.length) {
            return <></>
        }

        const targetLocation = locations.find(x => x.id === targetLocationId);
        const filteredLocations = locations.filter(x => x.id !== targetLocationId)
        if (!targetLocation) {
            return <></>
        }

        return (
            filteredLocations.length
                ?
                <>
                    <ListItem id="lication-list-toggle" button onClick={() => setLocationsIsOpen(!locationsIsOpen)}>
                        <ListItemIcon>
                            <LocationOnIcon />
                        </ListItemIcon>
                        <ListItemText primary={displayLocation(targetLocation)} />
                        {locationsIsOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={locationsIsOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {
                                filteredLocations.map((x, index) => {
                                    return (
                                        <ListItem
                                            id={`location-list-${displayLocation(x)}`}
                                            button
                                            key={index}
                                            onClick={() => handleSwitchLocation(x)}
                                            selected={x.id === targetLocationId}>
                                            <ListItemIcon>

                                            </ListItemIcon>
                                            <ListItemText primary={displayLocation(x)} />
                                        </ListItem>
                                    )
                                })
                            }
                        </List>
                    </Collapse>
                </>
                :
                <ListItem>
                    <ListItemIcon>
                        <LocationOnIcon />
                    </ListItemIcon>
                    <ListItemText primary={displayLocation(targetLocation)} />
                </ListItem>
        )
    }
    return (
        <>
            <Box display="flex" alignItems="center">
                <Button
                    id="profile-menu-toggle-username"
                    aria-describedby={profilePopoverProps.id}
                    onClick={(event) => handleToggle(event)}
                    color="inherit">
                    <AccountCircleIcon className="wh-tw-text-gray700" />
                    {isOpen ? <ExpandLess /> : <ExpandMore />}
                </Button>
            </Box>

            <Popover
                id={`profile-menu-toggle-${profilePopoverProps.id}`}
                anchorEl={anchorEl}
                onClose={(event) => handleToggle(event)}
                open={isOpen}
                anchorOrigin={profilePopoverProps.anchorOrigin}
                transformOrigin={profilePopoverProps.transformOrigin}
            >
                <Box width="250px">
                    <List component="nav" aria-label="main mailbox folders">
                        <ListItem id="nav-my-profile" button onClick={() => handleGoToMyAccount()}>
                            <ListItemText primary="Profile" />
                        </ListItem>
                        <ProtectedElement
                            element={
                                <ListItem id="nav-my-membership" button onClick={() => handleGoToMyMembership()}>
                                    <ListItemText primary="Membership" />
                                </ListItem>
                            }
                            permissions={[]}
                            userType={UserType.Patient}
                        />
                        {insuranceQuery.getInsuranceAvailability() && <OptionalComponent practiceId={Practice.WildHealth}>
                            <ProtectedElement
                                element={
                                    <FeatureComponent featureFlag={FeatureFlag.InsuranceProfile}>
                                        <ListItem id="nav-my-insurance" button onClick={() => handleGoToMyInsurance()}>
                                            <ListItemText primary="Insurance" />
                                        </ListItem>
                                    </FeatureComponent>
                                }
                                permissions={[]}
                                userType={UserType.Patient}
                            />
                        </OptionalComponent>}

                        <ProtectedElement
                            element={renderLocationList()}
                            permissions={[]}
                            userType={UserType.Employee}
                        />
                    </List>
                    <Divider />
                    <List component="nav" aria-label="secondary mailbox folders">
                        <ListItem id="nav-logout" button onClick={() => handleLogOut()}>
                            <ListItemText primary="Sign Out" />
                        </ListItem>
                    </List>
                </Box>
            </Popover>
        </>
    );
}
