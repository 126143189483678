import {useEffect, useState} from "react";
import {onEmit} from "../../../common/helpers/on-emit";
import {PatientModel} from "../../../patients/models/patient.model";
import {patientsQuery} from "../../../patients/stores/patientsStore";
import {
    HealthSummaryNoteKeySectionNames,
    IcdCode,
    NotesType
} from "../../../notes/models/notes.models";
import {icdCodesService} from "../../../notes/services/icdCodes.service";
import {Observable, Subscription} from "rxjs";
import {notesService, SignOffModel} from "../../../notes/services/notes.service";
import {AddOnModel} from "../../../addons/models/addOns.models";
import {addOnsQuery} from "../../../addons/stores";
import {OrderType} from "../../models/orders.models";
import {authQuery} from "../../../auth/stores/auth";
import {CoverageModel} from "../../../insurance/models/insurance.models";
import {insuranceQuery} from "../../../insurance/stores/insurance.query";
import {insuranceService} from "../../../insurance/services/insurance.service";
import {CoverageStatus} from "../../../insurance/enums/coverageStatus";
import {CreateReferralOrderItemModel, ReferralOrderModel} from "../../models/referralOrders.models";
import {referralOrdersService} from "../../services/referralOrders.service";
import {EmployeeType} from "../../../employee/models/employee.enums";
import {snackService} from "../../../common/snack/state";
import {addOnsService} from "../../../addons/services/addOns.service";
import {Gender} from "../../../common/models/user.models";
import {confirmService} from "../../../../services/confirm.service";
import {healthSummaryService} from "../../../healthSummary/services/healthSummary.service";
import {HealthSummaryMapModel} from "../../../healthSummary/models/healthSummary.models";
import {healthSummaryQuery} from "../../../healthSummary/stores";
import {getKey} from "../../../healthSummary/helpers/healthSummaryHelper";
import {getOrderItemDescription} from "../../helpers/get-order-item-description";

interface CreateReferralOrderContent {
    patientName: string;
    dateOfBirth: Date | string;
    insuranceProvider: string;
    insuranceId: string;
    clinicalReasonForReferral: string;
    urgencyTimeToAppointment: string;
    diagnosis: IcdCode[];
    primaryDiagnosesId: number | null;
    patientPreferences: string;
}

interface OrdersComponentState {
    isLoading: boolean;
    isLoadingIcd: boolean;
    isProcessing: boolean;
    orderId: number;
    employeeId: number;
    content: CreateReferralOrderContent;
    searchQuery: string;
    diagnosis: IcdCode[];
    addOns: AddOnModel[];
    item: CreateReferralOrderItemModel;
}

const defaultInitialContent: CreateReferralOrderContent =
    {
        patientName: '',
        dateOfBirth: '',
        insuranceProvider: '',
        insuranceId: '',
        clinicalReasonForReferral: '',
        urgencyTimeToAppointment: '',
        diagnosis: [],
        primaryDiagnosesId: null,
        patientPreferences: '',
    }

export function useFacade(patientId: number, targetOrder: ReferralOrderModel | null, handleGoBack: Function): [
    OrdersComponentState,
    () => void,
    () => void,
    () => void,
    () => void,
    (value) => void,
    (primaryDiagnosisId: number | null) => void,
    (value: string, id: number) => void,
    (value: string) => void,
    (value: string) => void,
    (value: string) => void,
    (value: string) => void,
    (value: string) => void,
    () => boolean,
    () => boolean,
    (searchQuery: string) => Observable<IcdCode[]>
] {
    const [state, setState] = useState({
        isLoading: true,
        isLoadingIcd: false,
        isProcessing: false,
        employeeId: authQuery.getEmployeeId(),
        searchQuery: '',
        diagnosis: [],
        content: defaultInitialContent,
        addOns: [],
        item: null,
        orderId: null,
    } as OrdersComponentState);

    const canConfirm = (): boolean => {
        return state.item
            && state.content.insuranceId
            && state.content.clinicalReasonForReferral
            && state.content.diagnosis
            && state.content.diagnosis.find(x => x.id === state.content.primaryDiagnosesId)
            && state.content.insuranceProvider
            && state.content.urgencyTimeToAppointment
            && !state.isProcessing
            && authQuery.getEmployeeType() === EmployeeType.Provider
    }

    const canSendForApproval = (): boolean => {
        return state.item
            && state.content.insuranceId
            && state.content.clinicalReasonForReferral
            && state.content.diagnosis
            && state.content.diagnosis.find(x => x.id === state.content.primaryDiagnosesId)
            && state.content.insuranceProvider
            && state.content.urgencyTimeToAppointment
            && !state.isProcessing
    }

    const handleSendForApproval = () => {
        notesService.openSignOff({
            patientId: patientId,
            noteType: NotesType.HistoryAndPhysicalFollowUp
        } as SignOffModel).subscribe(([employeeId, _]) => {

            setState(state => ({...state, isProcessing: true}))
            const cb = () => setState(state => ({...state, isProcessing: false}));
            const modelUpdate = {
                id: state.orderId,
                employeeId: employeeId,
                items: [state.item],
                data: {
                    ...state.content,
                    diagnosis: JSON.stringify(state.content.diagnosis),
                    primaryDiagnosesId: state.content.primaryDiagnosesId?.toString() ?? ''
                },
                sendForReview: true,
                isCompleted: false,
            }
            const modelCreate = {
                patientId: patientId,
                employeeId: employeeId,
                items: [state.item],
                data: {
                    ...state.content,
                    diagnosis: JSON.stringify(state.content.diagnosis),
                    primaryDiagnosesId: state.content.primaryDiagnosesId?.toString() ?? ''
                },
                sendForReview: true,
                isCompleted: false,
            }

            !state.orderId
                ? referralOrdersService.create(modelCreate).subscribe(
                    () => {
                        cb();
                        snackService.success('New order is sent for approval!');
                        handleGoBack();
                    },
                    cb)
                : referralOrdersService.update(modelUpdate).subscribe(
                    () => {
                        cb();
                        handleGoBack();
                    },
                    cb)
        })
    }

    const handleSaveAsDraft = () => {
        setState(state => ({...state, isProcessing: true}))
        const cb = () => setState(state => ({...state, isProcessing: false}));
        const modelCreate = {
            patientId: patientId,
            employeeId: state.employeeId,
            items: state.item ? [state.item] : [],
            data: {
                ...state.content,
                diagnosis: JSON.stringify(state.content.diagnosis),
                primaryDiagnosesId: state.content.primaryDiagnosesId?.toString() ?? ''
            },
            sendForReview: false,
            isCompleted: false,
        }

        const modelUpdate = {
            id: state.orderId,
            employeeId: state.employeeId,
            items: state.item ? [state.item] : [],
            data: {
                ...state.content,
                diagnosis: JSON.stringify(state.content.diagnosis),
                primaryDiagnosesId: state.content.primaryDiagnosesId?.toString() ?? ''
            },
            sendForReview: false,
            isCompleted: false,
        }

        !state.orderId
            ? referralOrdersService.create(modelCreate).subscribe(
                () => {
                    cb();
                    handleGoBack();
                },
                cb)
            : referralOrdersService.update(modelUpdate).subscribe(
                () => {
                    cb();
                    handleGoBack();
                },
                cb)
    }

    const handleDiscard = () => {
        setState(state => ({...state, isProcessing: true}));

        const cb = () => {
            setState(state => ({...state, isProcessing: false}));
            handleGoBack();
        }

        confirmService.confirm(
            'Discard Order',
            'Are you sure you want to quit without saving the order?',
            'Yes',
            'No',
            'danger')
            .subscribe(
                () => {
                    if (state.orderId) {
                        referralOrdersService.delete(state.orderId).subscribe(cb, cb);
                    } else {
                        handleGoBack();
                    }
                },
            );


    }

    const handleSaveAndComplete = () => {
        setState(state => ({...state, isProcessing: true}))
        const cb = () => setState(state => ({...state, isProcessing: false}));
        const modelCreate = {
            patientId: patientId,
            employeeId: state.employeeId,
            items: state.item ? [state.item] : [],
            data: {
                ...state.content,
                diagnosis: JSON.stringify(state.content.diagnosis),
                primaryDiagnosesId: state.content.primaryDiagnosesId?.toString() ?? ''
            },
            sendForReview: false,
            isCompleted: true,
        }

        const modelUpdate = {
            id: state.orderId,
            employeeId: state.employeeId,
            items: state.item ? [state.item] : [],
            data: {
                ...state.content,
                diagnosis: JSON.stringify(state.content.diagnosis),
                primaryDiagnosesId: state.content.primaryDiagnosesId?.toString() ?? ''
            },
            sendForReview: false,
            isCompleted: true,
        }

        !state.orderId
            ? referralOrdersService.create(modelCreate).subscribe(
                () => {
                    cb();
                    handleGoBack();
                },
                cb)
            : referralOrdersService.update(modelUpdate).subscribe(
                () => {
                    cb();
                    handleGoBack();
                },
                cb)
    }

    const handleDiagnosisChange = (value) => {
        if (value) {
            setState(state => ({
                ...state,
                content: {
                    ...state.content,
                    diagnosis: value
                }
            }));
        }
    }

    const handlePrimaryDiagnosisChange = (primaryDiagnosisId: number | null) => {
        setState(state => ({
            ...state,
            content: {
                ...state.content,
                primaryDiagnosesId: state.content.primaryDiagnosesId === primaryDiagnosisId ? null : primaryDiagnosisId
            }
        }));
    }

    const handleAdditionalInformationChanges = (value: string, id: number) => {
        const diagnoses = state.content.diagnosis.find(el => el.id === id)
        diagnoses.additionalInformation = value;
        setState(state => ({
            ...state,
            content: {
                ...state.content,
                diagnosis: state.content.diagnosis.map(x => x.id === id ? diagnoses : x).slice()
            }
        }));
    }

    const handleAddOnSelect = (value: string) => {
        setState(state => ({
            ...state,
            item: {
                addOnId: Number(value),
                description: '',
            }
        }));
    }

    const handleAddonDescriptionChanges = (value: string) => {
        setState(state => ({
            ...state,
            item: {
                ...state.item,
                description: value,
            }
        }));
    }

    const handleReasonChanges = (value: string) => {
        setState(state => ({
            ...state,
            content: {
                ...state.content,
                clinicalReasonForReferral: value
            }
        }));
    }

    const handleUrgencyChanges = (value: string) => {
        setState(state => ({
            ...state,
            content: {
                ...state.content,
                urgencyTimeToAppointment: value
            }
        }));
    }

    const handlePreferenceChanges = (value: string) => {
        setState(state => ({
            ...state,
            content: {
                ...state.content,
                patientPreferences: value
            }
        }));
    }

    const getIcdCodesAutoComplete = (searchQuery: string): Observable<IcdCode[]> => {
        return new Observable<IcdCode[]>((observer) => {
            icdCodesService.get(searchQuery).subscribe(
                (data) => {
                    observer.next(data);
                    observer.complete();
                },
                () => {
                    observer.next([]);
                    observer.complete();
                }
            )
        })
    }

    const handleAutoComplete = (searchQuery: string): Observable<IcdCode[]> => {
        return new Observable<IcdCode[]>((observer) => {
            icdCodesService.get(searchQuery).subscribe(
                (data) => {
                    observer.next(data);
                    observer.complete();
                },
                () => {
                    observer.next([]);
                    observer.complete();
                }
            )
        })
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (getIcdCodesAutoComplete && state.searchQuery) {
                setState(state => ({
                    ...state,
                    isLoadingIcd: true
                }));
                getIcdCodesAutoComplete(state.searchQuery).subscribe(
                    (diagnosis) => {
                        setState(state => ({
                            ...state,
                            diagnosis: diagnosis,
                            isLoadingIcd: false
                        }));
                    },
                    () => {
                        setState(state => ({
                            ...state,
                            diagnosis: [],
                            isLoadingIcd: false
                        }));
                    })
            }
        }, 1500);

        return () => clearTimeout(delayDebounceFn)
    }, [state.searchQuery]);

    const getDiagnosis = (map: HealthSummaryMapModel[]) => {
        healthSummaryService.getData(patientId).subscribe(data => {
            if (data && data.length) {
                const section = map.find(el => el.key === HealthSummaryNoteKeySectionNames.PROBLEMS_LIST);

                if (!section) {
                    return;
                }

                const keys = [...section.items.map(x => x.key), section.key]
                const values = data.filter(x => keys.includes(getKey(x.key)));
                const diagnosis = values.map(x => {
                    return {
                        id: +x.key.match(/\d+/g).join(''),
                        code: x.value,
                        description: x.name,
                        additionalInformation: x.tooltip
                    } as IcdCode
                }).filter(x => x.code?.length > 0);

                setState((state) => ({
                    ...state,
                    content: {
                        ...state.content,
                        diagnosis: diagnosis
                    }
                }))
            }
        })
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<PatientModel>(patientsQuery.targetPatient$, patient => {
                if (patient && patient.id === patientId) {
                    setState(state => ({
                        ...state,
                        content: {
                            ...state.content,
                            patientName: `${patient.firstName} ${patient.lastName}`,
                            dateOfBirth: patient.birthday
                        }
                    }));
                }
            }),
            onEmit<CoverageModel[]>(insuranceQuery.coverages$, coverages => {
                if (coverages !== null && coverages.length) {
                    const coverage = coverages
                        .filter(x => x.status === CoverageStatus.Active)
                        .sort((a, b) => a.priority - b.priority)
                        .find(x => x.status === CoverageStatus.Active);

                    if (coverage) {
                        setState(state => ({
                            ...state,
                            content: {
                                ...state.content,
                                insuranceId: coverage?.memberId,
                                insuranceProvider: coverage?.insurance?.name
                            }
                        }));
                    } else {
                        setState(state => ({
                            ...state,
                            content: {
                                ...state.content,
                                insuranceId: 'Cash only',
                                insuranceProvider: 'Cash only',
                            }
                        }));
                    }
                } else {
                    setState(state => ({
                        ...state,
                        content: {
                            ...state.content,
                            insuranceId: 'Cash only',
                            insuranceProvider: 'Cash only',
                        }
                    }));
                }
            }),
            onEmit<HealthSummaryMapModel[]>(healthSummaryQuery.map$, map => {
                if (targetOrder == null && map && map.length) {
                    getDiagnosis(map)
                }
            })
        ];

        if (targetOrder !== null) {
            setState(state => ({
                ...state,
                orderId: targetOrder.id,
                item: !!targetOrder.items.length
                    ? {
                        addOnId: targetOrder.items[0].addOnId,
                        description: getOrderItemDescription(targetOrder.items[0])
                    }
                    : null,
                employeeId: targetOrder.signedBy?.id
                    ? targetOrder.signedBy.id
                    : authQuery.getEmployeeId(),
                content: {
                    patientName: targetOrder.data.patientName,
                    dateOfBirth: targetOrder.data.dateOfBirth,
                    insuranceProvider: targetOrder.data.insuranceProvider,
                    insuranceId: targetOrder.data.insuranceId,
                    clinicalReasonForReferral: targetOrder.data.clinicalReasonForReferral,
                    urgencyTimeToAppointment: targetOrder.data.urgencyTimeToAppointment,
                    diagnosis: JSON.parse(targetOrder.data.diagnosis),
                    primaryDiagnosesId: +targetOrder.data.primaryDiagnosesId,
                    patientPreferences: targetOrder.data.patientPreferences,
                }
            }));
        } else {
            insuranceService.getCoveragesByEmploy(patientId).subscribe();
            healthSummaryService.getMap();
        }

        addOnsService.getOrdering(authQuery.getPracticeId(), Gender.None, true).subscribe(() => {
            setState(state => ({
                ...state,
                addOns: addOnsQuery.getByType(OrderType.Referral),
                isLoading: false,
            }));
        });

        return () => {
            subscriptions.map(it => it.unsubscribe());
        };
    }, [patientId]);

    return [
        state,
        handleSendForApproval,
        handleSaveAsDraft,
        handleDiscard,
        handleSaveAndComplete,
        handleDiagnosisChange,
        handlePrimaryDiagnosisChange,
        handleAdditionalInformationChanges,
        handleReasonChanges,
        handleUrgencyChanges,
        handlePreferenceChanges,
        handleAddOnSelect,
        handleAddonDescriptionChanges,
        canConfirm,
        canSendForApproval,
        handleAutoComplete
    ];
}