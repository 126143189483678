import {
    Box,
    Checkbox,
    Container,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    MenuItem,
    Select,
    TextField,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import React, {useMemo} from 'react';
import {UpdateAddressTestedForm} from "../../../common/components/updateAddressTestForm/UpdateAddressForm";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import {PageSliderNavigationContext} from "../../../common/models/navigation.models";
import {BillingSummaryTestedComponent} from "../billingSummaryTestComponent/BillingSummaryComponent";
import {WelcomePatientInfoComponent} from '../welcomePatientInfoComponent/WelcomePatientInfoComponent';
import {PatientInfoRedesignComponent} from '../patientInfoRedesignComponent/PatientInfoRedesignComponent';
import {ApplyPaymentCouponTestedComponent} from '../paymentCouponTestComponent/ApplyPaymentCouponComponent';
import {PaymentCouponAppliedTestedComponent} from '../paymentCouponTestComponent/PaymentCouponAppliedComponent';
import {PaymentSummaryTestedComponent} from "../paymentSummaryTestComponent/PaymentSummaryComponentGetStarted";
import {GetStartedStep, useFacade} from "./FinishCheckoutComponent.hooks";
import {useStyles} from "./finishCheckoutComponent.styles";
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from 'clsx';
import {RegistrationBackButton} from '../registrationButton/RegistrationBackButton';
import {RegistrationNextTestedButton} from '../registrationButton/RegistrationNextTestButton';
import {PageSliderComponent} from "../../../common/components/PageSliderComponent";
import {PaymentCardTestedComponent} from "../paymentCardTestComponent/PaymentCardComponent";
import {ReactComponent as LockIcon} from '@img/icons/lock.svg';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {ReactComponent as MastercardLogo} from '@img/getStarted/mastercardLogo.svg';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import {ConfirmAlternativeAddOnDialog} from '../confirmAlternativeAddOnDialog/ConfirmDialog'
import {colors} from '../../../common/constants/colors';
import {navigationService} from "../../../../services/navigation.service";
import {authQuery} from '../../../auth/stores/auth';
import {InsuranceCheckoutComponent} from "../../../insurance/components/insuranceComponent/InsuranceCheckoutComponent";
import {RegistrationSkipButton} from "../registrationButton/RegistrationSkipButton";

import {PaymentPlanModel} from '../../models/paymentPlan.models';
import {getPlanForAutoSelect} from "../../helpers/getPlanForAutoSelect";

interface FinishCheckoutComponentProps {
    plans: PaymentPlanModel[];
    specifiedPlan?: string;
    defaultPlan?: string;
    navigationContext: PageSliderNavigationContext;
    employerKey?: string;
}

export const FinishCheckoutComponent: React.FC<FinishCheckoutComponentProps> = (props: FinishCheckoutComponentProps) => {
    const {
        plans,
        specifiedPlan = null,
        defaultPlan = null,
        navigationContext,
        employerKey = ""
    } = props;

    const [
        {
            paymentPlan,
            paymentPeriod,
            paymentPrice,
            publicKey,
            addOns,
            popUpData,
            couponCode,
            coupon,
            isCouponApplied,
            paymentPriceType,
            payer,
            errors,
            sameAsBillingAddress,
            enterDiscountCode,
            leadSources,
            selectedLeadSource,
            step,
            isSubmitted,
            isCardExist,
            cardSaved,
            isAgreeContinue,
            isAgreeSMS,
            paymentCardInfo,
            isLoading
        },
        isMarketingSMSButton,
        isConsultationGetStartedPath,
        handleHasInsurance,
        handleTokenChange,
        selectAlternativeAddOn,
        handlePriceSelect,
        handleChanges,
        renderRecaptcha,
        handleToggleSelectingAddOn,
        getSelectedAddOns,
        handleCouponChanges,
        applyCoupon,
        resetCoupon,
        handleSameAsBillingAddress,
        handleEnterDiscountCode,
        handleLeadSourceChanges,
        handleSubmitNextStep,
        handleSubmitBackStep,
        isCheckedAlternativeAddOn,
        handleAgree,
        isCheckedWelcomePatient,
        isCheckedPersonalInfo,
        isCheckedBillingDetail,
        handleOnBlurValidate
    ] = useFacade(navigationContext, employerKey);

    const classes = useStyles();
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const stripePromise = useMemo(() => loadStripe(`${publicKey}`), [publicKey]);
    const isPlanAutoSelect = Boolean(getPlanForAutoSelect(plans, specifiedPlan, defaultPlan));

    if (!publicKey) {
        return <WildHealthLinearProgress />
    }

    const displayOtherLeadSource = () => {
        const leadSource = leadSources.find(x => x.id === selectedLeadSource.leadSourceId);
        if (leadSource === undefined || !leadSource.isOther)
            return <></>

        return <Box mt={3}>
            <TextField
                fullWidth
                required
                size="small"
                type="text"
                label="Other Source"
                InputProps={{ className: 'input', classes }}
                id="selectedLeadSource.otherLeadSource"
                variant="outlined"
                helperText={errors['selectedLeadSource.otherLeadSource']}
                error={!!errors['selectedLeadSource.otherLeadSource']}
                value={selectedLeadSource.otherLeadSource}
                onChange={(v) => {
                    handleLeadSourceChanges(v.target.id, v.target.value)
                }}
            />
        </Box>
    }

    const displayPodcastSource = () => {
        const leadSource = leadSources.find(x => x.id === selectedLeadSource.leadSourceId && x.name === 'Podcast');
        if (leadSource === undefined)
            return <></>

        return <Box mt={3}>
            <TextField
                fullWidth
                size="small"
                type="text"
                label="Which podcast?"
                InputProps={{ className: 'input', classes }}
                id="selectedLeadSource.podcastSource"
                variant="outlined"
                value={selectedLeadSource.podcastSource}
                onChange={(v) => {
                    handleLeadSourceChanges(v.target.id, v.target.value)
                }}
            />
        </Box>
    }

    const label = <Box
        ml={1}
        component="div"
        display="flex"
        className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorDarkGray)}>
        <span>
            I agree to receive occasional SMS marketing communications
        </span>
    </Box>;
    const controlIcon = <CheckBoxOutlineBlankIcon style={{ fill: colors.mediumGray }} />;
    const controlCheckedIcon = <CheckBoxIcon style={{ fill: colors.accent1 }} />;
    const control = (
        <Checkbox
            icon={controlIcon}
            color="primary"
            checkedIcon={controlCheckedIcon}
            name={`Agree-SMS-CheckBox`}
            checked={isAgreeSMS}
            onChange={(event) => handleAgree(event, 'isAgreeSMS')}
        />
    );

    const labelTermsAndPolicy = '';
    const controlTermsAndPolicy = (
        <Checkbox
            icon={controlIcon}
            color="primary"
            checkedIcon={controlCheckedIcon}
            name={`Agree-Continue-CheckBox`}
            checked={isAgreeContinue}
            onChange={(event) => handleAgree(event, 'isAgreeContinue')}
        />
    );

    const renderWelcomeStep = () => (
        <>
            <Box textAlign="center">
                <span className={clsx(commonClasses.size22, commonClasses.textMedium, commonClasses.colorDarkGray)}>Continue your personalized health journey</span>
            </Box>
            <Box mt={3}>
                <span className={clsx(commonClasses.size14, commonClasses.textRegular, commonClasses.colorDarkGray)}>You’re just 3 steps away from your best health. All we need is some basic personal info, shipping and billing details to complete your sign up. Start by making an account!</span>
            </Box>
            <Box className={classes.firstStepMain}>
                <WelcomePatientInfoComponent
                    payer={payer}
                    handleChanges={handleChanges}
                    hiddenFields={[]}
                    readonlyFields={[]}
                    errors={errors}
                />
            </Box>
        </>
    )

    const renderStepFirstMarketing = () => (
        <Box>
            <Box mt={isSmallScreen ? 3 : 6}>
                <FormControlLabel control={control} label={label} />
            </Box>
            <Box mt={2} display="flex" alignItems="center">
                <FormControlLabel control={controlTermsAndPolicy} label={labelTermsAndPolicy} className={classes.formControl} />
                <span className={clsx(commonClasses.size14, commonClasses.textRegular, commonClasses.colorMediumGray)}>
                    By clicking the button below I agree to the
                    <span onClick={() => navigationService.toTermsOfUse()} className={classes.termsPolicyLink}>Terms of Use</span>
                    <span>, </span>
                    <span onClick={() => navigationService.toTermsOfService()} className={classes.termsPolicyLink}>Terms of Service</span>
                    <span>, </span>
                    <span onClick={() => navigationService.toPrivacyPolicy()} className={classes.termsPolicyLink}>Privacy Policy</span>
                    <span>, and</span>
                    <span onClick={() => navigationService.toNoticeOfPrivacy()} className={classes.termsPolicyLink}>Notice of Privacy Practices</span>.
                </span>
            </Box>
        </Box>
    )

    const renderAskInsuranceStep = () => {
        return (
            <>
                <Box textAlign="center">
                    <h2>Do you have insurance?</h2>
                </Box>
                <Box mt={2} textAlign="center">
                <span>
                    We offer plans regardless of your insurance status, but it will help us determine the right plan for you.
                </span>
                </Box>
            </>
        )
    }

    const renderAskMedicareStep = () => {
        return (
            <>
                <Box textAlign="center">
                    <h2>Do you have Medicare?</h2>
                </Box>
                <Box mt={2} textAlign="center">
                <span>
                    We offer plans regardless of your insurance status, but it will help us determine the right plan for you.
                </span>
                </Box>
            </>
        )
    }

    const renderCaptureInsuranceStep = () => (
        <>
            <Box>
                <InsuranceCheckoutComponent user={payer} handleSubmit={() => handleSubmitNextStep()} handleCancel={() => handleHasInsurance(false)} />
            </Box>
        </>
    )

    const renderCheckInsuranceStep = () => (
        <>
            <Box textAlign="center">
                <h2>Not in network yet</h2>
            </Box>
            <Box mt={2} textAlign="center">
                <span>
                    We are not in network yet with your insurance. However, we offer discounted cash pricing for out-of-network patients.
                </span>
            </Box>
        </>
    )

    const renderPersonalInfoStep = () => (
        <>
            <Box textAlign="center">
                <span className={clsx(commonClasses.size22, commonClasses.textMedium, commonClasses.colorDarkGray)}>Personal Information</span>
            </Box>
            <PatientInfoRedesignComponent
                payer={payer}
                handleChanges={handleChanges}
                hiddenFields={[]}
                readonlyFields={[]}
                errors={errors}
                handleValidate={handleOnBlurValidate}
            />
            {
                isMarketingSMSButton && renderStepFirstMarketing()
            }
        </>
    )

    const renderMarketingSMSSubCopy = () => (
        <Box pt={3}>
            <span className={clsx(commonClasses.size12, commonClasses.textRegular, commonClasses.colorGray1)}>By checking this box, I acknowledge and agree that Wild Health and Wellington Provider Group may use my contact information to send me communications by email and text message, including automatically generated text messages, on Wild Health's products and other related educational content. I understand that my consent to receive these communications is not a condition of receiving any good or service. I also understand that message and data charges may apply.</span>
        </Box>
    )

    const renderBillingStep = () => (
        <>
            <Box mb={4}>
                <Box textAlign="center">
                    <span className={clsx(commonClasses.size22, commonClasses.textMedium, commonClasses.colorDarkGray)}>Billing Details</span>
                </Box>
                <Box textAlign="center">
                    <span className={clsx(commonClasses.size14, commonClasses.textRegular, commonClasses.colorGray1)}>Cancel your plan anytime</span>
                </Box>
                <Box mt={3}>
                    <Elements stripe={stripePromise}>
                        <PaymentCardTestedComponent
                            handleTokenChange={handleTokenChange}
                            isCardExist={isCardExist}
                            cardSaved={cardSaved}
                        />
                    </Elements>
                </Box>
                <Box pt={3}>
                    <UpdateAddressTestedForm
                        address={payer.billingAddress}
                        prefix={"billingAddress."}
                        errors={errors}
                        handleChanges={handleChanges}
                        handleValidate={handleOnBlurValidate} />
                </Box>
            </Box>
            <Box mt={isSmallScreen ? 2 : 3}>
                <button id="finish-checkout-as-billing-address" className={classes.btnLink} onClick={() => handleSameAsBillingAddress()}>
                    {sameAsBillingAddress ? (
                        <>
                            <RemoveIcon />
                            <Box ml={1}>
                                <span>My shipping address is the same as my billing address</span>
                            </Box>
                        </>
                    ) : (
                            <>
                                <AddIcon />
                                <Box ml={1}>
                                    <span>Add a different shipping address</span>
                                </Box>
                            </>
                        )}
                </button>
            </Box>
            {
                sameAsBillingAddress &&

                <Box>
                    <Box mb={3} mt={4} className={classes.smallTitle}>
                        Shipping Address
                    </Box>
                    <UpdateAddressTestedForm
                        address={payer.shippingAddress}
                        prefix={"shippingAddress."}
                        errors={errors}
                        handleChanges={handleChanges}
                        handleValidate={handleOnBlurValidate}
                    />
                </Box>
            }
            {
                !paymentPlan?.isFounder &&
                <Box mb={4}>
                    <Box mt={4}>
                        <Box className={commonClasses.subtitle} mb={1}>
                            How did you hear about us?*
                        </Box>
                        <Box>
                            <FormControl
                                variant="outlined"
                                classes={{ root: classes.root }}
                                color='primary'
                                required
                                error={!!errors[`selectedLeadSource`]}
                                size="small" fullWidth>
                                <Select
                                    style={{ background: '#FFF' }}
                                    id="selectedLeadSource.leadSourceId"
                                    value={selectedLeadSource.leadSourceId === 0 ? '' : selectedLeadSource.leadSourceId}
                                    error={!!errors[`selectedLeadSource.leadSourceId`]}
                                    onChange={(v) => handleLeadSourceChanges("selectedLeadSource.leadSourceId", v.target.value.toString())}>
                                    {
                                        leadSources.map((source, i) => {
                                            return <MenuItem key={i} value={source.id}>{source.name}</MenuItem>
                                        })
                                    }
                                </Select>
                                <FormHelperText>{errors[`selectedLeadSource.leadSourceId`]}</FormHelperText>
                            </FormControl>
                        </Box>
                        {
                            displayOtherLeadSource()
                        }
                        {
                            displayPodcastSource()
                        }
                    </Box>
                </Box>
            }
            <Box mt={isSmallScreen ? 5 : 6} textAlign="left">
                <button id="finish-checkout-redeem-discount"
                    className={enterDiscountCode ? classes.couponBtnLink2 : classes.couponBtnLink1}
                    onClick={() => handleEnterDiscountCode()}>
                    Redeem a discount code
                </button>
                {enterDiscountCode &&
                    <Box mt={2}>
                        {
                            !isCouponApplied &&
                            <ApplyPaymentCouponTestedComponent
                                handleCouponChanges={handleCouponChanges}
                                applyCoupon={applyCoupon}
                                couponCode={couponCode}
                                isSmallScreen={isSmallScreen}
                            />
                        }
                        {
                            isCouponApplied &&
                            <PaymentCouponAppliedTestedComponent paymentCoupon={coupon.code}
                                couponDiscount={coupon.detail}
                                handleRemove={resetCoupon} />
                        }
                    </Box>
                }
            </Box>
        </>

    )

    const renderCheckoutStep = () => (
        <>
            <Box>
                <Box textAlign="center">
                    <span className={clsx(commonClasses.size22, commonClasses.textMedium, commonClasses.colorDarkGray)}>Order Summary</span>
                </Box>
                <Box mt={3} className={isSmallScreen ? classes.orderDetailSM : classes.orderDetail}>
                    <Box className={clsx(commonClasses.size18, commonClasses.textMedium, commonClasses.colorLinkBlack)}>
                        Order Details
                    </Box>
                    <Box mt={2} display="flex" alignItems="center">
                        <Box
                            className={clsx(commonClasses.size16, commonClasses.textMedium, commonClasses.colorDarkGray)}>
                            Selected Plan:
                        </Box>
                        <Box ml={1}
                            className={clsx(commonClasses.size18, commonClasses.textMedium, commonClasses.colorMain)}>
                            {
                                paymentPlan.displayName
                            }
                        </Box>
                    </Box>
                    <Box mt={2}>
                        <PaymentSummaryTestedComponent
                            priceType={paymentPriceType}
                            selectedPeriod={paymentPeriod}
                            selectedPrice={paymentPrice}
                            handlePriceSelect={handlePriceSelect}
                            isCouponApplied={isCouponApplied} />
                    </Box>
                    <Box mt={2} mb={2}>
                        <Divider />
                    </Box>
                    <BillingSummaryTestedComponent
                        selectedPlan={paymentPlan}
                        selectedPeriod={paymentPeriod}
                        selectedPrice={paymentPrice}
                        selectedAddOns={getSelectedAddOns()}
                        hasContainer
                        hasDiscount
                        addOns={addOns}
                        handleToggleSelectingAddOn={handleToggleSelectingAddOn}
                    />
                </Box>
                <Box mt={3} className={!isSmallScreen && classes.paymentMethodBlock}>
                    <Box className={clsx(commonClasses.size18, commonClasses.textMedium, commonClasses.colorLinkBlack)}>
                        Payment Method
                    </Box>
                    <Box mt={isSmallScreen ? 2 : 3} px={isSmallScreen ? 2 : 4} py={isSmallScreen ? 2 : 3}
                        className={classes.creditCard}>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Box
                                className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorDarkGray)}>
                                <span>Credit Card</span>
                            </Box>
                            <Box>
                                <MastercardLogo />
                            </Box>
                        </Box>
                        <Box mt={2} display="flex" alignItems="center" justifyContent="space-between">
                            <Box
                                className={clsx(commonClasses.size18, commonClasses.textRegular, commonClasses.colorLinkBlack)}>
                                <span>XXXX XXXX XXXX {paymentCardInfo && paymentCardInfo.paymentMethod.card ? paymentCardInfo.paymentMethod.card.last4 : ''}</span>
                            </Box>
                            <Box
                                className={clsx(commonClasses.size18, commonClasses.textRegular, commonClasses.colorDarkGray)}>
                                <span>{paymentCardInfo && paymentCardInfo.paymentMethod.card ? paymentCardInfo.paymentMethod.card.exp_month : ''}/{paymentCardInfo && paymentCardInfo.paymentMethod.card ? paymentCardInfo.paymentMethod.card.exp_year.toLocaleString().substr(-2) : ''}</span>
                            </Box>
                        </Box>
                    </Box>
                    <Box mt={isSmallScreen ? 2 : 3} pl={!isSmallScreen && 4} display="flex" alignItems="center">
                        <LockIcon />
                        <Box pl={1}
                            className={clsx(commonClasses.size12, commonClasses.textRegular, commonClasses.colorDarkGray)}>Secured
                            with 256-bit TLS encryption</Box>
                    </Box>
                </Box>
            </Box>
            {
                renderRecaptcha()
            }
        </>
    )

    const btnName = () => {
        switch (step) {
            case GetStartedStep.Welcome:
                return "Get Healthy";
            case GetStartedStep.PersonalInfo:
                return "Billing Details";
            case GetStartedStep.AskInsurance:
                return "YES";
            case GetStartedStep.AskMedicare:
                return "YES";
            case GetStartedStep.CaptureInsurance:
                return "Continue";
            case GetStartedStep.CheckInsurance:
                return "Continue";
            case GetStartedStep.Billing:
                return "Review";
            case GetStartedStep.Checkout:
                return "Start Membership";
        }
    }

    const disabledStatus = () => {
        switch (step) {
            case GetStartedStep.Welcome:
                return !isCheckedWelcomePatient();
            case GetStartedStep.PersonalInfo:
                return !isCheckedPersonalInfo();
            case GetStartedStep.AskInsurance:
                return false;
            case GetStartedStep.CaptureInsurance:
                return false;
            case GetStartedStep.CheckInsurance:
                return false;
            case GetStartedStep.Billing:
                return !isCheckedBillingDetail();
            case GetStartedStep.Checkout:
                return false;
        }
    }

    const renderBackButton = () => {
        if (step > GetStartedStep.PersonalInfo) {
            if (authQuery.isLoggedIn() && isConsultationGetStartedPath) {
                return <></>;
            }
            return <RegistrationBackButton goBack={handleSubmitBackStep} />;
        }
        if (!isConsultationGetStartedPath) {
            if (!isPlanAutoSelect && step === GetStartedStep.Welcome) {
                return <PageSliderComponent context={navigationContext} />;
            }
            return <></>;
        }
        return <></>;
    }

    const renderDefaultNextButton = () => {
        return (
            <Box mt={6}>
                <Grid container justify="center">
                    <Grid item xs={12} md={step === 1 ? 6 : 4} lg={step === 1 ? 6 : 4}>
                        <RegistrationNextTestedButton
                            goNext={handleSubmitNextStep}
                            label={btnName()}
                            isLoading={isLoading || isSubmitted}
                            disabled={disabledStatus()}
                        />
                    </Grid>
                </Grid>
            </Box>
        )
    }

    const renderWelcomeStepNextButton = () => {
        return (
            <>
                {
                    renderDefaultNextButton()
                }
            </>
        )
    }

    const renderAskInsuranceNextButton = () => {
        return (
            <>
                {
                    renderDefaultNextButton()
                }
                <Box mt={1}>
                    <Grid container justify="center">
                        <Grid item xs={12} md={4} lg={4}>
                            <RegistrationSkipButton
                                skip={() => handleHasInsurance(false)}
                                label="NO"
                                disabled={false}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </>
        )
    }

    const renderNextButton = (step: GetStartedStep): JSX.Element => {
        switch (step) {
            case GetStartedStep.Welcome: return renderWelcomeStepNextButton();
            case GetStartedStep.PersonalInfo: return renderDefaultNextButton();
            case GetStartedStep.AskInsurance: return renderAskInsuranceNextButton();
            case GetStartedStep.AskMedicare: return renderAskInsuranceNextButton();
            case GetStartedStep.CaptureInsurance: return <></>;
            case GetStartedStep.CheckInsurance: return renderDefaultNextButton();
            case GetStartedStep.Billing: return renderDefaultNextButton();
            case GetStartedStep.Checkout: return renderDefaultNextButton();
        }
    }

    return (
        <>

            <Container maxWidth="lg" className={isSmallScreen ? classes.containerSmall : classes.container}>
                <Box className={step === 1 ? classes.welcomeMain : classes.main}>
                    <Box ml={isSmallScreen ? -2 : 0}>
                        {!isSubmitted && renderBackButton()}
                    </Box>
                    {step === GetStartedStep.Welcome && renderWelcomeStep()}
                    {step === GetStartedStep.AskInsurance && renderAskInsuranceStep()}
                    {step === GetStartedStep.AskMedicare && renderAskMedicareStep()}
                    {step === GetStartedStep.CaptureInsurance && renderCaptureInsuranceStep()}
                    {step === GetStartedStep.CheckInsurance && renderCheckInsuranceStep()}
                    {step === GetStartedStep.PersonalInfo && renderPersonalInfoStep()}
                    {step === GetStartedStep.Billing && renderBillingStep()}
                    {step === GetStartedStep.Checkout && renderCheckoutStep()}
                    {
                        renderNextButton(step)
                    }
                    {step === GetStartedStep.PersonalInfo && isMarketingSMSButton && isAgreeSMS && renderMarketingSMSSubCopy()}
                </Box>
                <ConfirmAlternativeAddOnDialog
                    popUpData={popUpData}
                    isChecked={isCheckedAlternativeAddOn()}
                    onConfirm={selectAlternativeAddOn}
                />
            </Container>
        </>
    )
}
