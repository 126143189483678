import React from 'react';
import { Box } from "@material-ui/core";
import { GeneralResultComponentHealthForms } from "./pages/GeneralResultComponentHealthForms";
import {
    QuestionnaireModel,
    QuestionnaireResultModel,
    QuestionnaireSubject,
    QuestionnaireSubType
} from "../../models/questionnaire.models";
import {
    questionViewIntention,
    questionViewMedicalHistory,
    questionViewLifestyle,
    questionViewGoals,
    questionViewHealth,
    questionViewPerformance,
    questionViewHappiness,
    detailedMedicalHistory,
    detailedSurgeryHistory,
    detailedSocialHistory,
    detailedWomenHealthHistory,
    detailedFamilyHistory,
    detailedScreeningHistory,
    detailedVaccinesHistory,
    detailedHealthReviewHistory
} from '../../helpers/questionnaireStaticData'
import { WildHealthTabControl } from '../../../common/components/wildHealthTabControl/WildHealthTabControl';
import { TabItem } from '../../../common/components/wildHealthTabControl/wildHealthTabControl.hooks';
import {Gender} from "../../../common/models/user.models";
import {useFlags} from "launchdarkly-react-client-sdk";
import {isFeatureFlag} from "../../../common/components/featureFlags/featureFlags";
import {FeatureFlag} from "../../../common/components/featureFlags/featureFlags.models";
import {MedicalHistoryAndLifestyleQuestionNames} from "../../static/medicalHistroryQuestionnaire.static";

export interface InitialQuestionnaireResultComponentHealthFormsProps {
    result: QuestionnaireResultModel;
    questionnaire: QuestionnaireModel;
    patientId?: number | null;
    gender: Gender;
}

export const InitialQuestionnaireResultComponentHealthForms: React.FC<InitialQuestionnaireResultComponentHealthFormsProps> = (props: InitialQuestionnaireResultComponentHealthFormsProps) => {
    const {
        questionnaire,
        result,
        patientId,
        gender
    } = props;

    const flags = useFlags();
    const rxntIntegration = isFeatureFlag(flags, FeatureFlag.RxntIntegration);
    const rxntIgnore = [
        MedicalHistoryAndLifestyleQuestionNames.ANY_MEDICATIONS,
        MedicalHistoryAndLifestyleQuestionNames.DRUG_ALLERGIES
    ];

    const tabsGoals: TabItem[] = [
        {
            title: QuestionnaireSubject.Intention,
            keepMounted: true,
            content:
                <GeneralResultComponentHealthForms
                    gender={gender}
                    result={result}
                    questionnaire={questionnaire}
                    questionView={questionViewIntention}
                    patientId={patientId}
                />,
        },
        {
            title: QuestionnaireSubject.Goals,
            keepMounted: true,
            content:
                <GeneralResultComponentHealthForms
                    gender={gender}
                    result={result}
                    questionnaire={questionnaire}
                    questionView={questionViewGoals}
                    groupWithTitle={true}
                    patientId={patientId}
                />,
        },
        {
            title: QuestionnaireSubject.Health,
            keepMounted: true,
            content:
                <GeneralResultComponentHealthForms
                    gender={gender}
                    result={result}
                    questionnaire={questionnaire}
                    questionView={questionViewHealth}
                    patientId={patientId}
                />,
        },
        {
            title: QuestionnaireSubject.Happiness,
            keepMounted: true,
            content:
                <GeneralResultComponentHealthForms
                    gender={gender}
                    result={result}
                    questionnaire={questionnaire}
                    questionView={questionViewHappiness}
                    patientId={patientId}
                />,
        },
        {
            title: QuestionnaireSubject.Performance,
            keepMounted: true,
            content:
                <GeneralResultComponentHealthForms
                    gender={gender}
                    result={result}
                    questionnaire={questionnaire}
                    questionView={questionViewPerformance}
                    patientId={patientId}
                />,
        },
    ];

    const tabsMedicalHistoryAndLifestyle: TabItem[] = [
        {
            title: QuestionnaireSubject.MedicalHistory,
            keepMounted: true,
            content:
                <GeneralResultComponentHealthForms
                    gender={gender}
                    result={result}
                    questionnaire={questionnaire}
                    questionView={questionViewMedicalHistory.filter(x => !rxntIntegration || !x.some(k => rxntIgnore.includes(k)))}
                    patientId={patientId}
                />,
        },
        {
            title: QuestionnaireSubject.Lifestyle,
            keepMounted: true,
            content:
                <GeneralResultComponentHealthForms
                    gender={gender}
                    result={result}
                    questionnaire={questionnaire}
                    questionView={questionViewLifestyle}
                    patientId={patientId}
                />,
        },
    ];

    const tabsDetailedHistoryFemale: TabItem[] = [
        {
            title: QuestionnaireSubject.MedicalHistory,
            keepMounted: true,
            content:
                <GeneralResultComponentHealthForms
                    gender={gender}
                    result={result}
                    questionnaire={questionnaire}
                    questionView={detailedMedicalHistory}
                    patientId={patientId}
                />,
        },
        {
            title: QuestionnaireSubject.SurgicalHistory,
            keepMounted: true,
            content:
                <GeneralResultComponentHealthForms
                    gender={gender}
                    result={result}
                    questionnaire={questionnaire}
                    questionView={detailedSurgeryHistory}
                    patientId={patientId}
                />,
        },
        {
            title: QuestionnaireSubject.SocialHistory,
            keepMounted: true,
            content:
                <GeneralResultComponentHealthForms
                    gender={gender}
                    result={result}
                    questionnaire={questionnaire}
                    questionView={detailedSocialHistory}
                    patientId={patientId}
                />,
        },
        {
            title: QuestionnaireSubject.WomensHealthHistory,
            keepMounted: true,
            content:
                <GeneralResultComponentHealthForms
                    gender={gender}
                    result={result}
                    questionnaire={questionnaire}
                    questionView={detailedWomenHealthHistory}
                    patientId={patientId}
                />,
        },
        {
            title: QuestionnaireSubject.FamilyHistory,
            keepMounted: true,
            content:
                <GeneralResultComponentHealthForms
                    gender={gender}
                    result={result}
                    questionnaire={questionnaire}
                    questionView={detailedFamilyHistory}
                    patientId={patientId}
                />,
        },
        {
            title: QuestionnaireSubject.Screening,
            keepMounted: true,
            content:
                <GeneralResultComponentHealthForms
                    gender={gender}
                    result={result}
                    questionnaire={questionnaire}
                    questionView={detailedScreeningHistory}
                    patientId={patientId}
                />,
        },
        {
            title: QuestionnaireSubject.Vaccines,
            keepMounted: true,
            content:
                <GeneralResultComponentHealthForms
                    gender={gender}
                    result={result}
                    questionnaire={questionnaire}
                    questionView={detailedVaccinesHistory}
                    patientId={patientId}
                />,
        },
    ];

    const tabsDetailedHistoryMale: TabItem[] = [
        {
            title: QuestionnaireSubject.MedicalHistory,
            keepMounted: true,
            content:
                <GeneralResultComponentHealthForms
                    gender={gender}
                    result={result}
                    questionnaire={questionnaire}
                    questionView={detailedMedicalHistory}
                    patientId={patientId}
                />,
        },
        {
            title: QuestionnaireSubject.SurgicalHistory,
            keepMounted: true,
            content:
                <GeneralResultComponentHealthForms
                    gender={gender}
                    result={result}
                    questionnaire={questionnaire}
                    questionView={detailedSurgeryHistory}
                    patientId={patientId}
                />,
        },
        {
            title: QuestionnaireSubject.SocialHistory,
            keepMounted: true,
            content:
                <GeneralResultComponentHealthForms
                    gender={gender}
                    result={result}
                    questionnaire={questionnaire}
                    questionView={detailedSocialHistory}
                    patientId={patientId}
                />,
        },
        {
            title: QuestionnaireSubject.FamilyHistory,
            keepMounted: true,
            content:
                <GeneralResultComponentHealthForms
                    gender={gender}
                    result={result}
                    questionnaire={questionnaire}
                    questionView={detailedFamilyHistory}
                    patientId={patientId}
                />,
        },
        {
            title: QuestionnaireSubject.Screening,
            keepMounted: true,
            content:
                <GeneralResultComponentHealthForms
                    gender={gender}
                    result={result}
                    questionnaire={questionnaire}
                    questionView={detailedScreeningHistory}
                    patientId={patientId}
                />,
        },
        {
            title: QuestionnaireSubject.Vaccines,
            keepMounted: true,
            content:
                <GeneralResultComponentHealthForms
                    gender={gender}
                    result={result}
                    questionnaire={questionnaire}
                    questionView={detailedVaccinesHistory}
                    patientId={patientId}
                />,
        },
        {
            title: QuestionnaireSubject.Review,
            keepMounted: true,
            content:
                <GeneralResultComponentHealthForms
                    gender={gender}
                    result={result}
                    questionnaire={questionnaire}
                    questionView={detailedHealthReviewHistory}
                    patientId={patientId}
                />,
        },
    ];

    const getTabs = () => {
        switch (result.questionnaire.subType) {
            case QuestionnaireSubType.MedicalHistoryIncomplete: return tabsMedicalHistoryAndLifestyle;
            case QuestionnaireSubType.GoalsIncomplete: return tabsGoals;
            case QuestionnaireSubType.DetailedHistoryIncomplete: switch (gender) {
                case Gender.Female: return tabsDetailedHistoryFemale;
                case Gender.Male: return tabsDetailedHistoryMale;
            } break;
            default: return [];
        }
    }

    return (
        <Box>
            <Box mb={3}>
                <WildHealthTabControl items={getTabs()} />
            </Box>
        </Box>
    )
}