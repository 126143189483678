import { Box, GridList, GridListTile, GridListTileBar, IconButton, useTheme, useMediaQuery, CircularProgress, Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import React from 'react';
import { AuthenticatedLayoutComponent } from "../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent";
import { PageTitleComponent } from '../../../../components/pageTitleComponent/PageTitleComponent';
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from "../../../../models/menu.models";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { WildHealthPlaceHolder } from '../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder';
import { NoteSignatureComponent } from "../../components/noteSignature/NoteSignatureComponent";
import { useFacade } from './patientBlankPage.hooks';
import { useStyles } from './patientBlankPage.styles';
import { TextMarkdownComponent } from '../../components/textMarkdownComponent/TextMarkdownComponent';
import clsx from 'clsx';
import commonUseStyles from "../../../common/styles/common.styles";
import { notesService } from '../../services/notes.service';
import DescriptionIcon from '@material-ui/icons/Description';
import GetAppIcon from '@material-ui/icons/GetApp';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';

export const PatientBlankPage: React.FC = (props: any) => {
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const noteId = Number(props.match.params.noteId);

    const [
        {
            note,
            content,
            internalContent,
            isPdfDownloading
        },
        handleGoBack,
        handleDownloadPdf
    ] = useFacade(noteId);

    const classes = useStyles();

    const isLoading = !content || !note;

    const getFileSize = (size: number) => {
        if (size < 1024) {
            return size + ' bytes';
        } else if (size > 1024 && size < 1048576) {
            return (size / 1024).toFixed(1) + ' KB';
        } else if (size > 1048576) {
            return (size / 1048576).toFixed(1) + ' MB';
        }
    }
    
    const openAttachment = (item) => {
        notesService.download(item.id, item.fileName).subscribe();
    }

    const renderInternalNote = () => {
        return (
            <Box>
                <Box my={!isSmallScreen && 2} className={isSmallScreen ? commonClasses.secondaryTitle : classes.title}>
                    <span className='uppercase'>Notes</span>
                </Box>
                <Box my={2}>
                    {
                        internalContent ?
                            <TextMarkdownComponent content={internalContent} classLink={classes.renderText} /> :
                            <WildHealthPlaceHolder message="No Notes." />
                    }
                </Box>
            </Box>
        )
    }

    const renderAttachments = () => {
        return (
            <Box>
                <Box my={!isSmallScreen && 2} className={isSmallScreen ? commonClasses.secondaryTitle : classes.title}>
                    <span className='uppercase'>Attachments:</span>
                </Box>
                <Box my={2}>
                    {
                        content?.length ?
                            <div className={classes.attachments}>
                                <GridList className={classes.list} cellHeight={400}>
                                    {
                                        content?.map((item, index) =>
                                            <GridListTile key={index}>
                                                <Box display='flex' alignItems='center' justifyContent='center' height="80%">
                                                    <IconButton id="simple-download-file" className={classes.fileButton} size='small' onClick={() => openAttachment(item)}>
                                                        <DescriptionIcon className={classes.fileIcon} />
                                                        <GetAppIcon className={classes.downloadIcon} />
                                                    </IconButton>
                                                </Box>
                                                <GridListTileBar
                                                    title={decodeURIComponent(item.fileName)}
                                                    subtitle={getFileSize(item.fileSize)}
                                                    actionIcon={<Box m={1} className={classes.icon}><AttachFileIcon /></Box>}
                                                />
                                            </GridListTile>
                                        )
                                    }
                                </GridList>
                            </div> :
                            <WildHealthPlaceHolder message="No attachments." />
                    }
                </Box>
            </Box>
        )
    }

    const displayDownloadPdfButton = () => {
        if (isPdfDownloading === true) {
            return (
                <Box width="70px" display="flex" justifyContent="center">
                    <CircularProgress size={24} className={classes.colorMain} />
                </Box>
            );
        }
        return (
            <Button id="patient-blank-download-pdf" onClick={() => handleDownloadPdf(note)}>
                <Box display="flex" alignItems="center" mt={1}>
                    <Box>
                        <GetAppIcon />
                    </Box>
                    <Box mt={-1}>
                        PDF
                    </Box>
                </Box>
            </Button>
        );
    }

    const renderNote = () => {
        return (
            <>
                <Box mt={!isSmallScreen && 5}>
                    {renderInternalNote()}
                    {renderAttachments()}
                </Box>
                <Box mt={7}>
                    <NoteSignatureComponent note={note} noteId={note.id} />
                </Box>
            </>
        )
    }

    const pageName = (
        <PageTitleComponent
            startAdornment={
                <IconButton id="patient-blank-go-back" onClick={() => handleGoBack()}>
                    <ArrowBackIcon />
                </IconButton>
            }
            title={
                isLoading
                    ? 'Loading . . .'
                    : note.name
            }
            endAdornment={
                displayDownloadPdfButton()
            }
        />
    );

    return (
        <AuthenticatedLayoutComponent
            title={TitlesService.noteDetailTitle}
            pageName={pageName}
            selectedMenuItem={MenuItemTypes.Notes}
        >
            <Box className={isSmallScreen && commonClasses.bgMain}>
                {isSmallScreen && (
                    <>
                        <Box py={2} display="flex" alignItems="center">
                            <IconButton id="patient-blank-go-back" onClick={() => handleGoBack()}>
                                <ArrowBackIcon />
                            </IconButton>
                            <Box className={clsx(commonClasses.size14, commonClasses.textRegular, commonClasses.colorGray1)}>
                                Back to Notes
                            </Box>
                        </Box>
                        <Box position="fixed" right={16} bottom={40} zIndex={1202}>
                            <WildHealthButton id="download-pdf" width="48px" loading={isPdfDownloading} style={{ borderRadius: "50%", minWidth: 48, height: 48 }} onClick={() => handleDownloadPdf(note)}>
                                <GetAppIcon className={commonClasses.colorWhite} />
                            </WildHealthButton>
                        </Box>
                    </>
                )}
                {
                    isLoading ?
                        <WildHealthLinearProgress /> :
                        <Box p={isSmallScreen ? 2 : 5} className={isSmallScreen && commonClasses.bgWhiteMain}>
                            {renderNote()}
                        </Box>
                }
            </Box>
        </AuthenticatedLayoutComponent>
    )
};
