import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles(theme => ({
    planCard: {
        lineHeight: 1.5,
        border: `1px solid ${colors.stroke}`,
        borderRadius: 2,
        height: '100%',
        boxShadow: '0px 4px 9px rgba(168, 173, 198, 0.15)',
    },
    planPricing: {
        backgroundColor: colors.lightGray,
        padding: '20px 20px 20px 20px',
    },
    planBenefits: {
        padding: '20px 20px 20px 20px',
    },
    label: {
        fontWeight: 'bold',
        fontSize: '12px',
        lineHeight: '22px',
        height: '22px',
        paddingRight: 10,
        paddingLeft: 10,
        border: 'none',
        backgroundColor: colors.accent1,
        color: colors.white,
        textAlign: 'center',
        borderRadius: 15,
    },
    spacingZero: {
        letterSpacing: 0,
    }
}));