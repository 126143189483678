import { StepConnector, withStyles, createStyles, makeStyles, Theme } from "@material-ui/core";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
          borderColor: colors.mediumGray,
          borderRadius: 2,
          '& .MuiOutlinedInput-root': {
            borderColor: colors.mediumGray,
            borderRadius: 2,
          },
          '& .MuiOutlinedInput-input': {
            '&:disabled': {
                backgroundColor: colors.gray6,
            },
          },
        },
        stepper: {
            backgroundColor: colors.white,
            paddingLeft: 0,
            paddingRight: 0,
            [theme.breakpoints.down('xs')]: {
                marginTop: 8
            },
        },
        step: {
            padding: 0,
        },
        stepLabel: {
            padding: 0,
            fontWeight: 500,
            fontSize: 12,
            color: colors.black
        },
    })
);

export const StepperConnector = withStyles({
    active: {
        '& $line': {
            borderStyle: 'solid',
            opacity: 1
        },
    },
    completed: {
        '& $line': {
            borderStyle: 'solid',
            opacity: 1
        },
    },
    line: {
        borderColor: colors.gray2,
        borderRadius: 1,
        borderTopWidth: 1,
    },
})(StepConnector);
  
export const useStepIconStyles = makeStyles({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        width: 24,
        height: 24,
        alignItems: 'center',
    },
    active: {
        color: colors.main,
    },
    incompleted: {
        color: colors.gray3,
    },
});
