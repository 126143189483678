import React, { useState } from "react";
import { LocationModel } from "../../models/locations.models";
import { locationsService } from "../../services/locations.service";
import { ManageLocationsComponent } from "../manageLocationsComponent/ManageLocationsComponent";
import { ManageLocationUsersComponent } from "../manageLocationUsersComponent/ManageLocationUsersComponent";


enum ManageLocationsStates {
    List = 0,
    Manage = 1
}

interface ManageLocationsCommonComponentState {
    stateView: ManageLocationsStates;
    selectedLocation: LocationModel;
}

export function useFacade(): [JSX.Element] {
    const [state, setState] = useState({
        stateView: ManageLocationsStates.List,
        selectedLocation: null
    } as ManageLocationsCommonComponentState);

    const handleGoBack = () => {
        setState(state => ({
            ...state,
            stateView: ManageLocationsStates.List
        }));
    }

    const handleEditLocation = () => {
        locationsService.updateLocation(state.selectedLocation).subscribe(model => {
            locationsService.update(model).subscribe();
            setState(state => ({
                ...state,
                selectedLocation: model,
            }));
        });
    }

    const handleGoToManageLocation = (location: LocationModel) => {
        setState(state => ({
            ...state,
            selectedLocation: location,
            stateView: ManageLocationsStates.Manage
        }));
    }

    const stateViews = new Map<ManageLocationsStates, JSX.Element>([
        [
            ManageLocationsStates.List,
            <ManageLocationsComponent
                handleGoToManageLocation={handleGoToManageLocation}
            />
        ],
        [
            ManageLocationsStates.Manage,
            <ManageLocationUsersComponent
                handleGoBack={handleGoBack}
                handleEdit={handleEditLocation}
                location={state.selectedLocation}
            />
        ]
    ]);

    const view = (stateViews.get(state.stateView));

    return [view];
}