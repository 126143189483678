import { WildHealthAutocompleteComponent } from '../../../common/components/wildhealthAutocomplete/WildHealthAutocompleteComponent'
import { HealthSummaryDataModel, HealthSummaryListDataModel, HealthSummaryMapModel } from '../../models/healthSummary.models'
import { Box, Button, IconButton, Menu, MenuItem, Table, TableBody, TableContainer, TableHead, TextField, Tooltip } from '@material-ui/core'
import { WildHealthTableCell } from '../../../common/components/wildHealthTable/WildHealthTableCell'
import { WildHealthTableRow } from '../../../common/components/wildHealthTable/WildHealthTableRow'
import { FeatureComponent } from '../../../common/components/featureFlags/FeatureComponent'
import { FeatureFlag } from '../../../common/components/featureFlags/featureFlags.models'
import { ProtectedElement } from '../../../common/components/ProtectedElement'
import { useStyles } from './healthSummaryListItemsComponent.styles'
import { PermissionType } from '../../../auth/models/auth.enums'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import React from 'react'
import InfoIcon from '@material-ui/icons/InfoRounded';
import { colors } from '../../../common/constants/colors'
import { Observable } from 'rxjs'
import AddIcon from '@material-ui/icons/Add'
import { HealthSummaryListItemsNoteComponentState } from '../../../notes/components/createHistoryAndPhysicalComponent/createHistoryAndPhysicalNoteComponent.hooks'
import {
    ManageRxntButtonComponent
} from "../../../patientSupplements/components/manageRxntButton/ManageRxntButtonComponent";

export interface HealthSummaryListItemsNoteComponentProps {
    data: HealthSummaryDataModel[],
    map: HealthSummaryMapModel,
    getIcdCodesAutoComplete: (searchQuery: string) => Observable<string[]>,
    handleToggleActions: Function,
    handleEditRowValue: Function,
    handleEditRowName: Function,
    handleAddNewRow: Function,
    handleDelete: Function,
    handleEdit: Function,
    handleBlur: Function,
    saveValue: Function,
    state: HealthSummaryListItemsNoteComponentState,
    patientId: number;
    section: string,
    rxnt: boolean
}

export const HealthSummaryListItemsNoteComponent: React.FC<HealthSummaryListItemsNoteComponentProps> = (props: HealthSummaryListItemsNoteComponentProps) => {
    const {
        data,
        map,
        getIcdCodesAutoComplete,
        handleToggleActions,
        handleEditRowValue,
        handleEditRowName,
        handleAddNewRow,
        handleDelete,
        handleEdit,
        handleBlur,
        saveValue,
        state,
        patientId,
        section,
        rxnt
    } = props

    const classes = useStyles();

    const getName = (key: string): string => {
        return map.items.find(x => x.key === key)?.name
    }

    const getNameBtn = (): string => {
        switch (section) {
            case 'allergies': return 'Add Allergy';
            case 'famHX': return 'Add Family Condition';
            case 'surgicalHX': return 'Add Surgical Condition';
            case 'currentMedicalConditions': return 'Add Medical Condition';
            default: return 'Add Condition';
        }
    }

    const getData = () => {
        return data?.map(x => ({
            name: x.name ? x.name : getName(x.key),
            key: x.key,
            value: x.value,
            tooltip: x.tooltip ? x.tooltip : ''
        }) as HealthSummaryListDataModel) ?? []
    }

    const renderToggleButtonCell = (key: string) => (
        <IconButton onClick={(e) => { handleToggleActions(key, e.currentTarget, section) }}>
            <MoreHorizIcon />
        </IconButton>
    )

    const renderMenuItems = (key: string) => {
        return (
            <Menu
                anchorEl={state.anchorEl}
                open={state.isMenuOpened && key === state.selectedKey}
                onClose={() => { handleToggleActions(0, '', '') }}
            >
                <MenuItem disabled={map.editRestricted  || (map.key === 'PROBLEMS_LIST' && rxnt)} onClick={() => { handleEdit() }}>
                    Edit
                </MenuItem>
                <MenuItem disabled={map.editRestricted} onClick={() => { handleDelete() }}>
                    Delete
                </MenuItem>
            </Menu>
        )
    }

    const renderItemRow = (item: HealthSummaryListDataModel) => {
        return <WildHealthTableRow key={item.key}>
            <WildHealthTableCell>
                <Box ml={-1.5} display='flex'>
                    <Box width='30px' minWidth='30px'>
                        {item.tooltip && <Tooltip placement="top" arrow
                            title={item.tooltip}>
                            <InfoIcon fontSize='small' style={{ fill: colors.gray2 }} />
                        </Tooltip>}
                    </Box>
                    {
                        item.name
                    }
                </Box>
            </WildHealthTableCell>
            <WildHealthTableCell>
                <Box display="flex" flexWrap="wrap">
                    {item.value ?? '--'}
                </Box>
            </WildHealthTableCell>
            {!state.addNewRow && !map.editRestricted &&
                <FeatureComponent featureFlag={FeatureFlag.HealthSummaryEdit}>
                    <WildHealthTableCell className={classes.actions}>
                        <Box display='flex' alignItems='center'>
                            <ProtectedElement
                                permissions={[PermissionType.Coaching, PermissionType.ManagePatients]}
                                element={renderToggleButtonCell(item.key)}
                            />
                        </Box>
                        <ProtectedElement
                            element={renderMenuItems(item.key)}
                            permissions={[PermissionType.Coaching, PermissionType.ManagePatients]}
                        />
                    </WildHealthTableCell>
                </FeatureComponent>
            }
        </WildHealthTableRow>
    }

    const getEditCell = (type: string, value: string, handleEdit: Function) => {
        return <TextField
            onChange={(e) => { handleEdit(e.target.value) }}
            onBlur={(e) => { handleBlur(e) }}
            autoFocus={type === 'name'}
            placeholder="Type here..."
            className={classes.input}
            id={`list-edit-${type}`}
            variant="outlined"
            value={value}
            size="small"
            fullWidth
            required
        />
    }

    const renderRowEditor = () => {
        if (map.key === 'PROBLEMS_LIST') {
            return <WildHealthTableRow >
                <WildHealthTableCell>
                    <WildHealthAutocompleteComponent
                        getAutoComplete={getIcdCodesAutoComplete}
                        saveChanges={(e, value) => { saveValue(value) }}
                        id={'auto-complete-value'}
                        value={state.editRowValue}
                    />
                </WildHealthTableCell>
            </WildHealthTableRow>
        }

        return (<WildHealthTableRow >
            <WildHealthTableCell>
                {getEditCell('name', state.editRowName, handleEditRowName)}
            </WildHealthTableCell>
            <WildHealthTableCell>
                {getEditCell('value', state.editRowValue, handleEditRowValue)}
            </WildHealthTableCell>
        </WildHealthTableRow>)
    }

    return <Box>
        <TableContainer>
            <Table>
                <TableHead>
                    <WildHealthTableRow>
                        <WildHealthTableCell>
                            <Box ml={2}>
                                {map.nameColumn}
                            </Box>
                        </WildHealthTableCell>
                        <WildHealthTableCell>
                            {map.valueColumn}
                        </WildHealthTableCell>
                        {!state.addNewRow &&
                            <FeatureComponent featureFlag={FeatureFlag.HealthSummaryEdit}>
                                <WildHealthTableCell>
                                </WildHealthTableCell>
                            </FeatureComponent>
                        }
                    </WildHealthTableRow>
                </TableHead>
                <TableBody>
                    {
                        getData().map((item) =>
                            state.isEditorOpened && state.selectedKey === item.key
                                ? renderRowEditor()
                                : renderItemRow(item))
                    }
                    {
                        state.addNewRow && section === state.editSection && renderRowEditor()
                    }
                </TableBody>
            </Table>
        </TableContainer>
        {section !== state.editSection && !map.editRestricted && <Button className={classes.button} onClick={() => {
            handleAddNewRow(section, map)
        }}>
            <AddIcon fontSize='small' />
            <Box className={classes.buttonText}>{getNameBtn()}</Box>
        </Button>}
        {
            rxnt && section === 'allergies' && <Box mt={1}>
                <ManageRxntButtonComponent patientId={patientId}/>
            </Box>
        }
    </Box>
}