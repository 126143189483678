import moment from "moment";
import _ from 'lodash';
import {useEffect, useState} from "react";
import {Subscription} from "recompose";
import {confirmService} from "../../../../services/confirm.service";
import {onEmit} from "../../../common/helpers/on-emit";
import {mediumPageSizes} from "../../../common/pagination/models/page-sizes";
import {IPaginationState} from "../../../common/pagination/models/pagination-state";
import {IErrorState} from "../../../common/validation/error-state";
import {GeneralValidator} from "../../../common/validation/general-validator";
import {LabInputNotificationType, VitalValueSourceType} from "../../../inputs/models/input.models";
import {inputsService} from "../../../inputs/services/inputs.service";
import {patientInputsService} from "../../../inputs/services/patientInputs.service";
import {
    CreateVitalModel,
    UpdateVitalValueModel,
    VitalModel,
    VitalValueModel,
} from "../../models/vital.model";
import {vitalService} from "../../services/vital.service";
import {
    vitalQuery,
    vitalStore,
    VitalsHistoryView,
    VitalsHistoryMode,
} from "../../stores";
import {
    vitalsErrorMessages,
    VitalsNames,
    vitalsNormalValuesValidator,
    vitalsValidator,
} from "../../validators/vital.validator";
import {calculateBmi} from "../../helpers/calculate-bmi";
import {getUniqDates} from "../../helpers/get-unique-dates";

interface PopoverModel {
    id: number;
    anchor: HTMLElement | null;
}

interface VitalsHistoryComponentState extends IPaginationState, IErrorState {
    isLoading: boolean;
    isSubmitting: boolean;
    isChanged: boolean;
    canAddNewDataSet: boolean;
    mode: VitalsHistoryMode;
    view: VitalsHistoryView;
    isDataSetDialogOpen: boolean;
    items: VitalModel[];
    startDate: Date;
    endDate: Date;
    popovers: PopoverModel[];
    dates: Array<number>;
    isSelectDateRangeOpen: boolean;
    selectDateRangeAnchor: HTMLElement | null;
    itemEditDate: String | null;
    itemEditIndex: number | null;
}

const defaultState = {
    isLoading: true,
    isSubmitting: false,
    isChanged: false,
    canAddNewDataSet: true,
    mode: VitalsHistoryMode.Normal,
    view: VitalsHistoryView.Graph,
    isDataSetDialogOpen: false,
    items: [],
    startDate: new Date(),
    endDate: new Date(),
    totalCount: 2,
    selectedPage: 1,
    pageSize: mediumPageSizes[1],
    popovers: [],
    dates: [],
    isSelectDateRangeOpen: false,
    selectDateRangeAnchor: null,
    errors: {},
    itemEditDate: null,
    itemEditIndex: null,
} as VitalsHistoryComponentState;

export function useFacade(
    patientId: number | null,
    isDataSetDialogOpen?: boolean
): [
    VitalsHistoryComponentState,
    Array<string>,
    (mode: VitalsHistoryMode) => void,
    (view: VitalsHistoryView) => void,
    () => void,
    () => void,
    (open: boolean) => void,
    (date: Date) => void,
    (vitalId: number, valueId: number, value: string) => void,
    (value: any) => void,
    (page: number) => void,
    (event: React.MouseEvent<HTMLElement, MouseEvent>, inputId: number) => void,
    (inputId: number) => void,
    (anchor?: HTMLElement) => void,
    (startDate: Date, endDate: Date) => void,
    (itemEditDate: String, itemEditIndex: number) => void
] {
    const [state, setState] = useState({...defaultState, isDataSetDialogOpen});

    const getEmptyValue = (date: Date, name?: string): VitalValueModel => {
        const castedDate = new Date(date);

        const emptyValue = {
            id: castedDate.getTime(),
            date: castedDate,
            value: null,
            name: name ?? "",
            isChanged: false,
            isInitialized: false,
            editable: true,
            notification: {
                notificationType: LabInputNotificationType.Empty,
                message: "",
            },
        } as VitalValueModel;

        return Object.assign({}, emptyValue);
    };

    const addMissingValuesToVitals = (items: VitalModel[], dates: number[]) => {
        items.forEach((item) => {
            const toPush = [];

            dates.forEach((date) => {
                if (
                    !item.values
                        .map((i) => new Date(i.date).getTime())
                        .includes(new Date(date).getTime())
                ) {
                    toPush.push(getEmptyValue(new Date(date), item.name));
                }
            });

            item.values = [...item.values, ...toPush];
            item.values = _.compact(item.values)
                .sort((p, n) => new Date(p.date).getTime() - new Date(n.date).getTime())
                .reverse();
        });

        return items;
    };

    const validateValues = (items: VitalModel[]) => {
        items.forEach((vital) => {
            vital.values.forEach((value) => {
                if (value.value !== null) {
                    const error = vitalsNormalValuesValidator.validate(
                        vital.name,
                        value.value.toString()
                    );
                    if (error) {
                        value.notification = {
                            notificationType: LabInputNotificationType.Error,
                            message: error,
                        };
                    }
                }
            });
        });
    };

    const getColumns = (dates: Array<number>) => {
        const columns = ["Vitals"];

        if (state.view === VitalsHistoryView.Graph) {
            columns.push("Trend Over Time");
        } else {
            dates.forEach((date) => columns.push(moment(date).format("MM/DD/YYYY")));
            columns.push("");
        }

        return columns;
    };

    const handleConfirmDiscardChanges = (flow: () => void) => {
        if (
            state.mode === VitalsHistoryMode.Edit &&
            (state.isChanged || !state.canAddNewDataSet)
        ) {
            confirmService
                .confirm("You have unsaved changes", "Do you want to continue?")
                .subscribe(() => {
                    handleCancel();
                    flow();
                });
        } else {
            flow();
        }
    };

    const switchMode = (mode: VitalsHistoryMode) => {
        if (mode === VitalsHistoryMode.Edit) {
            switchView(VitalsHistoryView.Values);
        }

        vitalStore.setMode(mode);
    };

    const switchView = (view: VitalsHistoryView) => {
        const flow = () => {
            vitalStore.setView(view);
            vitalStore.setMode(VitalsHistoryMode.Normal);
        };

        handleConfirmDiscardChanges(flow);
    };

    const handleCancel = () => {
        const value = vitalStore.getValue();
        vitalStore.update({vitals: [...value.vitals]});

        vitalStore.setMode(VitalsHistoryMode.Normal);
        setState((state) => ({
            ...state,
            isChanged: false,
            canAddNewDataSet: true,
            totalCount: value.totalVitals,
            errors: {},
        }));
    };

    const handleSaveChanges = () => {
        setState((state) => ({...state, isSubmitting: true}));

        let createSuccess = false;
        let removeSuccess = false;
        let updateSuccess = false;

        const originalItems = vitalStore.getValue().vitals;
        const createdValues: CreateVitalModel[] = [];
        const updatedValues: UpdateVitalValueModel[] = [];
        const removedValues: number[] = [];

        const allValues = state.items
            .map((x, index) =>
                x.values.map((v) => ({...v, vitalId: x.id, vitalIndex: index}))
            )
            .flat();

        allValues.filter(x => x.sourceType !== VitalValueSourceType.MobileApplication).forEach((value) => {
            if (value.isChanged) {
                const isOriginalValue = originalItems[value.vitalIndex].values
                    .map((i) => i.id)
                    .includes(value.id);

                if (value.isInitialized) {
                    if (isOriginalValue) {
                        updatedValues.push({
                            valueId: value.id,
                            value: value.value,
                            dateTime: value.date,
                            vitalId: value.vitalId,
                        });
                    } else {
                        createdValues.push({
                            name: value.name,
                            value: value.value,
                            dateTime: value.date,
                        });
                    }
                } else if (isOriginalValue) {
                    removedValues.push(value.id);
                }
            }
        });

        const errorCB = () => setState((state) => ({...state, isSubmitting: false}));

        const unlock = () => {
            state.items.forEach((vital) =>
                vital.values.forEach((value) => (value.isChanged = false))
            );

            const cb = () =>
                setState((state) => ({
                    ...state,
                    isSubmitting: false,
                    isChanged: false,
                    canAddNewDataSet: true,
                }));
            vitalService
                .get(
                    state.selectedPage,
                    state.pageSize,
                    state.startDate,
                    state.endDate,
                    patientId
                )
                .subscribe(cb, cb);

            vitalStore.setMode(VitalsHistoryMode.Normal);
        };

        const createCB = () => {
            createSuccess = true;
            if (updateSuccess && removeSuccess) {
                unlock();
            }
        };

        const removeCB = () => {
            removeSuccess = true;
            if (createSuccess && updateSuccess) {
                unlock();
            }
        };

        const updateCB = () => {
            updateSuccess = true;
            if (createSuccess && removeSuccess) {
                unlock();
            }
        };

        if (createdValues.length) {
            vitalService.create(createdValues, patientId).subscribe(createCB, errorCB);
        } else createSuccess = true;

        if (removedValues.length) {
            vitalService.remove(removedValues, patientId).subscribe(removeCB, errorCB);
        } else removeSuccess = true;

        if (updatedValues.length) {
            vitalService
                .updateVitalValue(updatedValues, patientId)
                .subscribe(updateCB, errorCB);
        } else updateSuccess = true;

        if (!createdValues.length && !updatedValues.length) {
            vitalStore.setMode(VitalsHistoryMode.Normal);
            setState((state) => ({...state, isSubmitting: false}));
        }
    };

    const handleToggleDataSetDialog = (open: boolean) => {
        setState((state) => ({...state, isDataSetDialogOpen: open}));
    };

    const handleEditVitalItem = (itemEditDate: String, itemEditIndex: number) => {
        setState((state) => ({...state, itemEditDate, itemEditIndex}));
    };

    const handleAddDataSet = (date: Date) => {
        const flow = () => {
            const emptyValue: VitalValueModel = getEmptyValue(date);
            state.dates = [...state.dates, date.getTime()].sort((a, b) => a - b).reverse();

            state.items.forEach((item) => {
                item.values = [
                    ...item.values,
                    Object.assign({}, {...emptyValue, name: item.name}),
                ]
                    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
                    .reverse();
            });

            const itemEditIndex = state.dates.findIndex(
                (x) => new Date(x).getTime() === new Date(date).getTime()
            );
            const itemEditDate = moment(date).format("MM/DD/YYYY");

            setState((state) => ({
                ...state,
                canAddNewDataSet: false,
                totalCount: state.totalCount + 1,
                itemEditDate,
                itemEditIndex,
            }));
            vitalStore.setMode(VitalsHistoryMode.Edit);
            vitalStore.setView(VitalsHistoryView.Values);
        };

        if (state.totalCount === state.pageSize) {
            handleConfirmDiscardChanges(flow);
        } else {
            flow();
        }
    };

    const handleEditVitalValue = (vitalId: number, valueId: number, value: string) => {
        const vital = state.items.find((i) => i.id === vitalId);
        if (vital) {
            const vitalValue = vital.values.find((i) => i.id === valueId);
            if (vitalValue) {
                if (value === null || value.length < 1) {
                    vitalValue.isInitialized = false;
                }

                if (value !== null && value.length > 0 && !vitalValue.isInitialized) {
                    vitalValue.isInitialized = true;
                }

                vitalValue.isChanged = true;
                vitalValue.value = !value.length ? null : Math.abs(Number(value));

                const errors = validateFields(
                    vital.name,
                    vitalValue.id,
                    vitalValue.value,
                    vitalValue.date
                );

                if (vital.name === VitalsNames.Height || vital.name === VitalsNames.Weight) {
                    const index = vital.values.indexOf(vitalValue);
                    const bmiVitals = state.items.find(i => i.name === VitalsNames.BMI);

                    if (bmiVitals.values[index]) {
                        const bmiValue = calculateBmi(state.items, index);
                        handleEditVitalValue(bmiVitals.id, bmiVitals.values[index].id, bmiValue);
                    }
                }

                setState((state) => ({...state, errors, isChanged: true}));
            }
        }
    };

    const validateFields = (
        vital: string,
        valueId: number,
        value: number | null,
        date: Date
    ) => {
        const isSystolic = vital === VitalsNames.SystolicBloodPressure;
        const isDiastolic = vital === VitalsNames.DiastolicBloodPressure;

        if (isDiastolic || isSystolic) {
            const systolicBloodPressure = state.items
                .find((i) => i.name === VitalsNames.SystolicBloodPressure)
                ?.values.find((i) => new Date(i.date).getTime() === new Date(date).getTime());

            const diastolicBloodPressure = state.items
                .find((i) => i.name === VitalsNames.DiastolicBloodPressure)
                ?.values.find((i) => new Date(i.date).getTime() === new Date(date).getTime());

            const systolicValue = isSystolic ? value : systolicBloodPressure?.value;
            const diastolicValue = isDiastolic ? value : diastolicBloodPressure?.value;

            if ((systolicValue || diastolicValue) && diastolicValue >= systolicValue) {
                return GeneralValidator.addError(
                    state,
                    `${VitalsNames.DiastolicBloodPressure}${diastolicBloodPressure.id}`,
                    vitalsErrorMessages.diastolicIsGraterThanSystolic
                );
            } else if (systolicValue && !diastolicValue) {
                return GeneralValidator.addError(
                    state,
                    `${VitalsNames.DiastolicBloodPressure}${diastolicBloodPressure.id}`,
                    vitalsErrorMessages.systolicAndDiastolicNotFiled
                );
            } else {
                vitalsValidator.validateAndSetState(
                    state,
                    setState,
                    VitalsNames.DiastolicBloodPressure,
                    diastolicValue,
                    diastolicBloodPressure.id.toString()
                );
                vitalsValidator.validateAndSetState(
                    state,
                    setState,
                    VitalsNames.SystolicBloodPressure,
                    systolicValue,
                    systolicBloodPressure.id.toString()
                );
                return state.errors;
            }
        }

        vitalsValidator.validateAndSetState(
            state,
            setState,
            vital,
            value,
            valueId.toString()
        );

        return state.errors;
    };

    const handlePageSizeChange = (value: any) => {
        if (value === state.pageSize) {
            return;
        }

        const flow = () => {
            setState((state) => ({...state, isLoading: true}));
            vitalStore.setPage(1);
            vitalStore.setPageSize(value);

            const cb = () => setState((state) => ({...state, isLoading: false}));
            vitalService
                .get(1, value, state.startDate, state.endDate, patientId)
                .subscribe(cb, cb);
        };

        handleConfirmDiscardChanges(flow);
    };

    const handlePageChange = (page: number) => {
        if (page === state.selectedPage) {
            return;
        }

        const flow = () => {
            vitalStore.setPage(page);
            setState((state) => ({...state, isLoading: true}));

            const cb = () => setState((state) => ({...state, isLoading: false}));
            vitalService
                .get(page, state.pageSize, state.startDate, state.endDate, patientId)
                .subscribe(cb, cb);
        };

        handleConfirmDiscardChanges(flow);
    };

    const handlePopoverOpen = (
        event: React.MouseEvent<HTMLElement, MouseEvent>,
        inputId: number
    ) => {
        const popover = state.popovers.find((x) => x.id === inputId);
        popover.anchor = event.currentTarget;
        setState({...state, popovers: state.popovers});
    };

    const handlePopoverClose = (inputId: number) => {
        const popover = state.popovers.find((x) => x.id === inputId);
        popover.anchor = null;
        setState({...state, popovers: state.popovers});
    };

    const handleSelectDateRangeToggle = (anchor?: HTMLElement) => {
        setState((state) => ({
            ...state,
            isSelectDateRangeOpen: Boolean(anchor),
            selectDateRangeAnchor: anchor,
        }));
    };

    const handleDateRangeChange = (startDate: Date, endDate: Date) => {
        const flow = () => {
            setState((state) => ({...state, isLoading: true, selectedPage: 1}));
            vitalStore.setDateRange(startDate, endDate);

            const cb = () => setState((state) => ({...state, isLoading: false}));
            vitalService
                .get(1, state.pageSize, startDate, endDate, patientId)
                .subscribe(cb, cb);
        };

        handleConfirmDiscardChanges(flow);
    };

    const prepareVitals = (vitals: VitalModel[]) => {
        const vitalsCopy = JSON.parse(JSON.stringify(vitals));

        validateValues(vitalsCopy);
        const dates = getUniqDates(vitalsCopy);
        const items = addMissingValuesToVitals(vitalsCopy, dates);

        setState((state) => ({
            ...state,
            dates: dates,
            items: items,
            popovers: vitalsCopy.map((i) => ({id: i.id, anchor: null})),
        }));
    };

    const validationFieldsAndSetBmi = () => {
        state.items.forEach(vital => {
            vital.values.forEach((value, index) => {
                if (value.value !== null) {
                    handleEditVitalValue(vital.id, value.id, value.value.toString())
                    if (vital.name === VitalsNames.Weight || vital.name === VitalsNames.Height) {
                        const bmiVitals = state.items.find(i => i.name === VitalsNames.BMI);

                        if (bmiVitals.values[index]) {
                            const bmiValue = calculateBmi(state.items, index);
                            handleEditVitalValue(bmiVitals.id, bmiVitals.values[index].id, bmiValue);
                        }
                    }
                }
            })
        })
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<VitalModel[]>(vitalQuery.vitals$, (vitals) => prepareVitals(vitals)),
            onEmit<number>(vitalQuery.totalVitals$, (totalVitals) =>
                setState((state) => ({...state, totalCount: totalVitals}))
            ),
            onEmit<number>(vitalQuery.view$, (view) =>
                setState((state) => ({...state, view}))
            ),
            onEmit<number>(vitalQuery.mode$, (mode) =>
                setState((state) => ({...state, mode}))
            ),
            onEmit<Date>(vitalQuery.startDate$, (startDate) =>
                setState((state) => ({...state, startDate}))
            ),
            onEmit<Date>(vitalQuery.endDate$, (endDate) =>
                setState((state) => ({...state, endDate}))
            ),
            onEmit<number>(vitalQuery.selectedPage$, (selectedPage) =>
                setState((state) => ({...state, selectedPage}))
            ),
            onEmit<number>(vitalQuery.pageSize$, (pageSize) =>
                setState((state) => ({...state, pageSize}))
            )
        ];

        const store = vitalStore.getValue();
        const cb = () => setState((state) => ({...state, isLoading: false, errors: {}}));
        vitalService
            .get(store.selectedPage, store.pageSize, store.startDate, store.endDate, patientId)
            .subscribe(cb, cb);

        if (patientId) {
            inputsService.getGeneralInputs(patientId);
        } else {
            patientInputsService.getGeneralInputs();
        }

        return () => {
            subscriptions.map((it) => it.unsubscribe());
        };
    };

    useEffect(useEffectCB, []);

    useEffect(() => {
        if (state.mode === VitalsHistoryMode.Edit) {
            validationFieldsAndSetBmi()
        }

    }, [state.mode]);

    return [
        state,
        getColumns(state.dates),
        switchMode,
        switchView,
        handleCancel,
        handleSaveChanges,
        handleToggleDataSetDialog,
        handleAddDataSet,
        handleEditVitalValue,
        handlePageSizeChange,
        handlePageChange,
        handlePopoverOpen,
        handlePopoverClose,
        handleSelectDateRangeToggle,
        handleDateRangeChange,
        handleEditVitalItem,
    ];
}
