import React from "react";
import {
    AuthenticatedLayoutComponent
} from "../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent";
import {TitlesService} from "../../../../constants/title.constants";
import {PagesNamesService} from "../../../../constants/pagesNames.constants";
import {MenuItemTypes} from "../../../../models/menu.models";
import {OtherOrderModel} from "../../models/otherOrders.models";
import {navigationService} from "../../../../services/navigation.service";
import {useHistory} from "react-router";
import {NewOtherOrderComponent} from "../../components/newOtherOrderComponent/NewOtherOrderComponent";
import {Box} from "@material-ui/core";

export const OtherOrderPagePage: React.FC = (props: any) => {
    const history = useHistory();
    const patientId = props?.location?.state?.patientId as number;
    const order = props?.location?.state?.order as OtherOrderModel | null;
    const handleGoBack = () => {
        navigationService.toDashboard(history);
    }

    return (
        <AuthenticatedLayoutComponent
            hideBackground
            title={TitlesService.otherOrderTitle}
            pageName={PagesNamesService.otherOrder}
            selectedMenuItem={MenuItemTypes.Dashboard}
        >
            <Box pt={2}>
                <NewOtherOrderComponent patientId={patientId} targetOrder={order} handleGoBack={handleGoBack}/>
            </Box>
        </AuthenticatedLayoutComponent>
    )
}