import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { onEmit } from "../../../../common/helpers/on-emit";
import { IErrorState } from "../../../../common/validation/error-state";
import { PatientModel } from "../../../../patients/models/patient.model";
import { usersService } from "../../../../../services/users.service";
import { patientsQuery } from "../../../stores/patientsStore";

interface SessionRecordingSettingWidgetState extends IErrorState {
  isLoading: boolean;
  isSaveChanges: boolean;
  edit: boolean;
  patient: PatientModel;
  meetingRecordingConsent: boolean;
  meetingRecordingConsentDraft: boolean;
}

export function useFacade(): [
  SessionRecordingSettingWidgetState,
  () => void,
  () => void,
  () => void,
  () => void,
] {
  const [state, setState] = useState({
    isLoading: true,
    isSaveChanges: false,
    edit: false,
    patient: null,
    meetingRecordingConsent: false,
    meetingRecordingConsentDraft: false,
    errors: {},
  } as SessionRecordingSettingWidgetState);

  const handleEditToggle = () => {
    setState(state => ({
      ...state,
      edit: !state.edit,
      meetingRecordingConsentDraft: state.meetingRecordingConsent
    }));
  }

  const handleReset = () => {
    setState(state => ({
      ...state,
      edit: !state.edit,
      meetingRecordingConsentDraft: state.meetingRecordingConsent
    }));

  }

  const handleSave = () => {

    setState(state => ({ ...state, isSaveChanges: true }));
    const model = {
        email: state.patient.email,
        firstName: state.patient.firstName,
        lastName: state.patient.lastName,
        gender: state.patient.gender.toString(),
        birthDate: state.patient.birthday,
        phoneNumber: state.patient.phoneNumber,
        smsMarketing: false,
        billingAddress: state.patient.billingAddress,
        shippingAddress: state.patient.shippingAddress,
        meetingRecordingConsent: state.meetingRecordingConsentDraft
    };

    usersService.update(model).subscribe(
      () => {
        setState(state => ({ ...state, isSaveChanges: false, edit: !state.edit, meetingRecordingConsent: state.meetingRecordingConsentDraft }));
      },
      () => {
        setState(state => ({ ...state, isSaveChanges: false }));
      }
    )
  }

  const handleChanges = () => {
    setState({ ...state, meetingRecordingConsentDraft: !state.meetingRecordingConsentDraft });
  }

  const useEffectCB = () => {
    const subscriptions: Subscription[] = [
      onEmit<PatientModel>(patientsQuery.targetPatient$, targetPatient => {
        setState(state => ({
            ...state,
            patient: targetPatient,
        }));
      }),
    ];

    const cb = () => setState(state => ({ ...state, isLoading: false }));

    usersService.getMe().subscribe((response) => setState(state => ({ ...state, isLoading: false, meetingRecordingConsent: response.meetingRecordingConsent })), cb)

    return () => {
      subscriptions.map(it => it.unsubscribe())
    };
  }


  useEffect(useEffectCB, []);

  return [
    state,
    handleChanges,
    handleEditToggle,
    handleReset,
    handleSave,
  ];
}
