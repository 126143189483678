import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    container: {
      width: "100%",
      paddingTop: 16,
      paddingBottom: 16,
      [theme.breakpoints.down("xs")]: {
        padding: 16
      },
    },
    title: {
      fontWeight: 500,
      fontSize: 20,
      marginBottom: 16
    },
  })
);
