import {Query} from '@datorama/akita';
import {OrdersState, ordersStore, OrdersStore} from "./orders.store";

/**
 * Provides patient queries
 */
export class OrdersQuery extends Query<OrdersState> {
    tab$ = this.select(state => state.tab);
    referralOrders$ =  this.select(state => state.referralOrders);
    otherOrders$ =  this.select(state => state.otherOrders);
    orderOtherForReview$ =  this.select(state => state.orderOtherForReview);
    orderReferralForReview$ =  this.select(state => state.orderReferralForReview);

    constructor(protected store: OrdersStore) {
        super(store);
    }
}

export const ordersQuery = new OrdersQuery(ordersStore);