import React from 'react';
import {Snackbar, useTheme, useMediaQuery} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import {useFacade, SnackType} from './state';
import clsx from 'clsx';

export const CommonSnack: React.FC = () => {
    const [{isOpen, type, messages, duration, vertical, horizontal}] = useFacade();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <Snackbar open={isOpen}
                  style={{zIndex: 3000}}
                  autoHideDuration={duration}
                  anchorOrigin={{vertical: isSmallScreen ? 'top' : vertical, horizontal: isSmallScreen ? 'center' : horizontal}}>
            <MuiAlert elevation={6} variant="filled" severity={type} className={clsx(type === SnackType.success && 'wh-tw-bg-primaryV')}>
                <ul>
                    {
                        messages.map((message, idx) => {
                            return <li key={idx}>{message}</li>
                        })
                    }
                </ul>
            </MuiAlert>
        </Snackbar>
    )
}
