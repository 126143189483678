import moment from "moment";
import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { addOnProvidersNames } from "../../../addons/models/addOns.models";
import { onEmit } from "../../../common/helpers/on-emit";
import { LabOrderModel } from "../../models/labOrders.models";
import { labOrdersService } from "../../services/labOrders.service";
import { labOrdersQuery } from "../../stores/labOrdersStore";
import { OrderType } from "../../models/orders.models";
import { confirmService } from "../../../../services/confirm.service";
import { addOnsQuery } from "../../../addons/stores";
import { toCurrentTimeZone } from '../../../timezones/helpers/timezone';
import { displayMoney } from "../../../common/helpers/display-money";

interface LabOrdersComponentState {
    isLoading: boolean;
    selectedOrderId: number;
    isDownloadingRequisition: boolean;
    orders: LabOrderModel[];
    menuAnchorEl: HTMLButtonElement;
    targetOrder: LabOrderModel;
}


export function useFacade(patientId: number, orderId: number, navigateToInvoice: (orderId: number) => any): [
    LabOrdersComponentState,
    Array<string>,
    () => void,
    () => void,
    () => boolean,
    (orderId: number, menuAnchorEl?: HTMLButtonElement) => void,
    () => void,
    () => void,
    () => void
] {
    const [state, setState] = useState({
        isLoading: true,
        selectedOrderId: 0,
        isDownloadingRequisition: false,
        orders: [],
        menuAnchorEl: null,
        targetOrder: null
    } as LabOrdersComponentState);

    const columns = [
        'Date Ordered',
        'Expected Collection Date',
        'Lab Provider',
        'Invoice Status',
        'Action'
    ];

    const handleToggleMenu = (orderId: number, menuAnchorEl?: HTMLButtonElement) => {
        setState(state => ({ 
            ...state, 
            menuAnchorEl,
            selectedOrderId: orderId,
            targetOrder: orderId > 0 ? state.orders.find(pc => pc.id === orderId) : null
        }))
    }

    const handleNavigateToInvoiceFromState = () => {
        const orderId = state.selectedOrderId;
        navigateToInvoice(orderId);
    }

    const handleViewRequisition = () => {
        const orderId = state.selectedOrderId;
        const order = state.orders.find(o => o.id == orderId);
        if (order) {
            setState(state => ({ ...state, isDownloadingRequisition: true, selectedOrderId: orderId }));

            const cb = () => setState(state => ({ ...state, isDownloadingRequisition: false, selectedOrderId: 0 }));

            const provider = addOnProvidersNames.get(order.provider) ?? 'Requisition';

            const fileName = `${provider} ${moment(toCurrentTimeZone(order.orderedAt)).format('MM/DD/YYYY')}`;

            if (patientId) {
                labOrdersService.getRequisition(orderId, fileName).subscribe(
                    (blob) => {
                        const downloadUrl = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = downloadUrl;
                        link.setAttribute('download', fileName);
                        link.click();
                        cb();
                    }, cb);
            } else {
                labOrdersService.getMyRequisition(orderId).subscribe(
                    (blob) => {
                        const downloadUrl = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = downloadUrl;
                        link.setAttribute('download', fileName);
                        link.click();
                        cb();
                    }, cb);
            }

        }
    }

    const canBuyNewLabOrder = (): boolean => {
        const addOns = addOnsQuery.getByType(OrderType.Lab) ?? [];
        const addOn = addOns.find(x => x.name === 'Advanced Blood Analysis');

        return addOn !== undefined && orderId !== 0;
    }

    const handleBuyNewLabOrder = () => {
        const addOns = addOnsQuery.getByType(OrderType.Lab);
        if (addOns.length) {
            const addOn = addOns.find(x => x.name === 'Advanced Blood Analysis')
            confirmService.confirm('Are you sure?', `This will charge the patient's payment source ${displayMoney(addOn.price, '$')}. Would you like to proceed?`).subscribe(
                () => labOrdersService.create({ patientId: patientId, addOnIds: [addOn.id] }).subscribe(() => { })
            );
        }
    }

    const handleCancelOrder = () => {
        const labOrderId = state.selectedOrderId
        if (labOrderId > 0) 
        {
            labOrdersService.cancelOrder(labOrderId).subscribe()
        }
    }

    const handleMarkOrderAsPaid = () => {
        const labOrderId = state.selectedOrderId
        const cb = () => setState(state => ({ ...state, menuAnchorEl: null, selectedOrderId: 0, targetOrder: null }))
        if (labOrderId > 0) 
        {
            labOrdersService.markAsPaidOrder(labOrderId).subscribe(cb, cb)
        }
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<LabOrderModel[]>(labOrdersQuery.orders$, labs => {
                setState(state => ({
                    ...state,
                    orders: labs,
                }));
            })
        ];

        const cb = () => setState(state => ({ ...state, isLoading: false }));

        if (patientId) {
            labOrdersService.get(patientId).subscribe(cb, cb);
        } else {
            labOrdersService.getMyOrders().subscribe(cb, cb);
        }


        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [patientId]);

    return [state, columns, handleViewRequisition, handleBuyNewLabOrder, canBuyNewLabOrder, handleToggleMenu, handleNavigateToInvoiceFromState, handleCancelOrder, handleMarkOrderAsPaid];
}