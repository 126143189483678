import { useState } from 'react';
import { appointmentsService } from '../../services/appointments.service';

export interface ConfirmDialogState {
  isOpen: boolean;
  title: string;
  message: string;
  acceptTitle: string,
  cancelTitle: string,
  variant: 'default' | 'danger',
  handleAnswer: Function;
}

export function useFacade(): [ConfirmDialogState, Function] {

  const [state, setState] = useState({
    isOpen: false,
    title: null,
    message: null,
    acceptTitle: 'Yes',
    cancelTitle: 'Cancel',
    variant: 'default'
  } as ConfirmDialogState);

  const handleAnswer = (result: boolean) => {
    setState({ ...state, isOpen: false, title: null, message: null, acceptTitle: 'Yes', cancelTitle: 'Cancel', variant: 'default' });
    state.handleAnswer(result);
    setState({ ...state, isOpen: false, title: null, message: null, acceptTitle: 'Yes', cancelTitle: 'Cancel', variant: 'default' });
  }

  appointmentsService.cancelPatientAppointmentScheduleOnOpen.subscribe((question) => {
    setState({
      ...state,
      isOpen: true,
      handleAnswer: question.callback
    });
  })

  return [state, handleAnswer];
}