import {authQuery} from "../../auth/stores/auth";
import {paymentQuery} from "../../payment/stores/paymentStore";

export const authHeader = () => {
    const token = authQuery.getToken();
    const externalAuth = authQuery.getExternalAuth();

    if (token) {
        const headers = {
            'Authorization': 'Bearer ' + token
        };

        if (externalAuth) {
            headers[externalAuth.provider] = externalAuth.accessToken;
        }

        return headers;
    } else {
        return {};
    }
}

export const authHeaderForStripe = () => {
    const token = paymentQuery.getSecretkey();

    if (token) {
        return {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        };
    } else {
        return {};
    }
}
