import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Subscription } from 'recompose';
import { ApplicationInformationModel } from '../../models/application.models';
import { MenuItem, MenuItemTypes } from '../../models/menu.models';
import { onEmit } from '../../modules/common/helpers/on-emit';
import { applicationQuery, applicationStore } from "../../stores/applicationStore";
import { WildHealthTooltip } from '../../modules/common/components/WildHealthTooltip';

interface CollapsibleMenuItem extends MenuItem {
    isOpened: boolean;
}

/**
 * Represents for Side Bar Component state
 */
interface SideBarComponentState {
    applicationInformation: ApplicationInformationModel;
    roleId: number;
    menus: CollapsibleMenuItem[];
}

/**
 * Custom Hook to manage a view Model for Side Bar component
 */
export function useFacade(isOpen: boolean, selectedMenuItem: MenuItemTypes, classes: any): [
    () => void,
    () => JSX.Element[]
] {
    const [state, setState] = useState({
        applicationInformation: null,
        roleId: 0,
        menus: [],
    } as SideBarComponentState);

    const history = useHistory();

    const handleToggle = () => {
        const isOpen = !applicationQuery.getIsSideBarOpen();

        if (!isOpen) {
            state.menus.forEach(i => i.isOpened = false);
        }

        applicationStore.update({ isSideBarOpen: isOpen });
    }

    const handleMenuItemToggle = (item: CollapsibleMenuItem) => {
        item.isOpened = !item.isOpened;
        setState(state => ({ ...state }));
    }

    const renderChildMenuItems = (item: CollapsibleMenuItem, key: string) => {
        return (
            <Collapse key={key} in={item.isOpened} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {
                        item.children.map((child, index) => {
                            const isSelected = child.type === selectedMenuItem;

                            return (
                                <ListItem
                                    id={`side-bar-${child.title}`}
                                    button
                                    key={index}
                                    hidden={!item.isOpened}
                                    disabled={child.disabled}
                                    className={clsx(classes.childrenMenuItem, {
                                        [classes.menuItemSelected]: isSelected && !child.disabled,
                                    })}
                                    onClick={() => child.action(history)}
                                >
                                    <ListItemIcon className={classes.menuIcon}>
                                        {child.icon(isSelected)}
                                    </ListItemIcon>
                                    <ListItemText className={classes.nested} primary={child.title} />
                                    <ListItemIcon className={classes.menuIcon}>
                                        {child.badge}
                                    </ListItemIcon>
                                </ListItem>
                            )
                        })
                    }
                </List>
            </Collapse>
        )
    }

    const renderMenuItems = () => {
        const menuItems: JSX.Element[] = [];

        state.menus.forEach((item, index) => {
            let isSelected = item.type === selectedMenuItem;
            const hasChildren = item.children?.length > 0;
            const action = hasChildren ?
                () => {
                    handleMenuItemToggle(item);
                    if (!isOpen)
                        handleToggle();
                } :
                () => {
                    item.action(history);
                };

            if (hasChildren && !isSelected) {
                isSelected = item.children.find(i => i.type === selectedMenuItem) != null;
            }

            const listItem = (
                <ListItem
                    id={`side-bar-${item.title}`}
                    className={clsx(classes.menuItem, {
                        [classes.menuItemSelected]: isSelected && !item.disabled,
                    })}
                    selected={isSelected}
                    disabled={item.disabled}
                    onClick={() => action()}
                    key={index}
                    button
                >
                    <ListItemIcon className={clsx(classes.menuIcon, classes.menuIconSize)}>
                        {item.icon(isSelected)}
                    </ListItemIcon>
                    <ListItemIcon className={clsx(classes.menuIcon, classes.menuIconSize)}>
                        {item.badge}
                    </ListItemIcon>
                    <ListItemText
                        className={classes.menuTitle}
                        primary={item.title}
                        hidden={!isOpen}
                    />
                    {
                        isOpen && hasChildren ? item.isOpened ? <ExpandLessIcon /> : <ExpandMoreIcon /> : <></>
                    }
                </ListItem>
            );

            menuItems.push(isOpen ? listItem : <WildHealthTooltip key={`${index}-tooltip`} title={item.title} placement="left">{listItem}</WildHealthTooltip>);

            if (hasChildren)
                menuItems.push(renderChildMenuItems(item, `${index}-children`));
        });

        return menuItems;
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<ApplicationInformationModel>(applicationQuery.information$, applicationInformation => {
                setState(state => ({ ...state, applicationInformation }));
            }), onEmit<MenuItem[]>(applicationQuery.menuItems$, menus => {
                setState(state => ({ ...state, menus: menus.map(i => i as CollapsibleMenuItem) }));
            }),
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [handleToggle, renderMenuItems];
}