import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        addIcon: {
            backgroundColor: colors.separators,
            color: colors.main,
            cursor: 'pointer',
        },
        addText: {
            color: colors.main
        },
        textFiel: {
            background: colors.disabled, 
        }
    })
);
