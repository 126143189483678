import {GeneralValidator} from "../../../common/validation/general-validator";
import {rangeValidationRule} from "../../../common/validation/general-validation-rules";

export const generalInputsComponentValidator = new GeneralValidator({
    "exerciseActivitiesFrequency": [rangeValidationRule(0, 7, "Value should be in range 0 - 7.")],
    "meditationFrequency": [rangeValidationRule(0, 7, "Value should be in range 0 - 7.")],
    "rem": [rangeValidationRule(0, null, "Value should be positive number.")],
    "deepSleep": [rangeValidationRule(0, 5, "Value should be in range from 0 - 5.")],
    "sleep": [rangeValidationRule(0, null, "Value should be positive number.")],
    "chronologicalAge": [rangeValidationRule(0, null, "Value should be positive number.")],
    "realAge": [rangeValidationRule(0, null, "Value should be positive number.")],
    "biologicalAge": [rangeValidationRule(0, null, "Value should be positive number.")],
    "mesa": [rangeValidationRule(0, 100, "Value should be in range 0 - 100.")],
});