import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { AppointmentOptions, AppointmentTypeModel } from "../../models/appointments.models";
import { onEmit } from "../../../common/helpers/on-emit";
import { appointmentsQuery } from "../../stores/appointments";
import { appointmentsService } from "../../services/appointments.service";
import { AppointmentPurposeType, AppointmentWithType } from "../../models/appointments.enums";
import { appointmentsOptionsService } from "../../services/appoitmentOptions.service";
import { IErrorState } from "../../../common/validation/error-state";
import { appointmentOptionsValidator } from "./appointmentOptions.validator";
import {getAvailableWithTypes} from "../../helpers/appointmentHelper";

interface AppointmentOptionsDialogComponentState extends IErrorState {
    isOpen: boolean;
    isSaving: boolean;
    hasChanges: boolean;
    isOverwriting: boolean;
    overwriteDate: Date | null;
    selectedWithType: AppointmentWithType;
    selectedPurpose: AppointmentPurposeType;
    appointmentTypes: AppointmentTypeModel[];
    appointmentOptions: AppointmentOptions[];
    target: AppointmentOptions;
}

const emptyOptions = (patientId: number, withType: AppointmentWithType, purpose: AppointmentPurposeType): AppointmentOptions => {
    return {
        id: null,
        nextAppointmentDate: null,
        withType: withType,
        purpose: purpose,
        patientId: patientId
    }
}

export function useFacade(patientId: number): [
    AppointmentOptionsDialogComponentState,
    (withType: AppointmentWithType) => void,
    (purpose: AppointmentPurposeType) => void,
    (field: string, value: string) => void,
    () => void,
    () => void,
    () => void,
] {
    const [state, setState] = useState({
        isOpen: false,
        isSaving: false,
        hasChanges: false,
        isOverwriting: false,
        overwriteDate: null,
        selectedWithType: AppointmentWithType.HealthCoach,
        selectedPurpose: AppointmentPurposeType.Welcome,
        appointmentTypes: [],
        appointmentOptions: [],
        target: emptyOptions(patientId, AppointmentWithType.HealthCoach, AppointmentPurposeType.Consult),
        errors: {}
    } as AppointmentOptionsDialogComponentState);

    const handleWithTypeSelect = (withType: AppointmentWithType) => {
        const targetPurpose = state.appointmentTypes.find(x => x.configurations.some(t => t.withType === withType))?.purpose ?? null;

        let correspondingOptions = state.appointmentOptions
            .find(x => x.purpose === targetPurpose && x.withType === withType);

        if (!correspondingOptions) {
            correspondingOptions = emptyOptions(patientId, withType, targetPurpose);
        }

        setState(state => ({
            ...state,
            hasChanges: correspondingOptions.id == null,
            selectedWithType: withType,
            selectedPurpose: targetPurpose,
            overwriteDate: getOverwriteDate(targetPurpose, withType),
            target: Object.assign({}, correspondingOptions)
        }))
    }

    const handlePurposeSelect = (purpose: AppointmentPurposeType) => {
        let correspondingOptions = state.appointmentOptions
            .find(x => x.purpose === purpose && x.withType === state.selectedWithType);

        if (correspondingOptions == null) {
            correspondingOptions = emptyOptions(patientId, state.selectedWithType, purpose);
        }

        setState(state => ({
            ...state,
            hasChanges: correspondingOptions.id == null,
            selectedPurpose: purpose,
            overwriteDate: getOverwriteDate(purpose, state.selectedWithType),
            target: Object.assign({}, correspondingOptions)
        }))
    }

    const handleChanges = (field: string, value: string) => {
        appointmentOptionsValidator.validateAndSetState(state, setState, field, value);
        const options = state.target;

        options[field] = value;

        setState(state => ({
            ...state,
            hasChanges: true,
            isOverwriting: checkIfOverwriting(options),
            target: options
        }));
    }

    const checkIfOverwriting = (target: AppointmentOptions) => {
        const purpose = state.selectedPurpose;
        const withType = state.selectedWithType;
        if (!target || !purpose || !withType) {
            return false;
        }

        const previousOption = state
            .appointmentOptions
            .find(x => x.purpose === purpose && x.withType === withType);

        if (!previousOption) {
            return false;
        }

        return target.nextAppointmentDate < previousOption.nextAppointmentDate;
    }

    const getOverwriteDate = (
        purpose: AppointmentPurposeType,
        withType: AppointmentWithType,
        options: AppointmentOptions[] = null): Date | null => {
        if (!purpose || !withType) {
            return null;
        }

        if (!options) {
            options = state.appointmentOptions
        }

        const previousOption = options.find(x => x.purpose === purpose && x.withType === withType);

        return previousOption?.nextAppointmentDate ?? null;
    }

    const handleOpen = () => {
        setState(state => ({
            ...state,
            isOpen: true,
            hasChanges: false,
            isOverwriting: false,
            overwriteDate: null
        }));
    }

    const handleSave = () => {
        appointmentOptionsValidator.validateObjectAndSetState(state, setState, state.target);
        if (appointmentOptionsValidator.stateIsValid(state)) {
            if (state.target) {
                setState(state => ({
                    ...state,
                    isSaving: true
                }));

                const cb = () => {
                    setState(state => ({
                        ...state,
                        isSaving: false
                    }));
                    handleClose();
                }
                if (state.target.id) {
                    appointmentsOptionsService.updateOptions(state.target).subscribe(cb, cb);
                } else {
                    appointmentsOptionsService.createOptions(state.target).subscribe(cb, cb);
                }
            }
        }
    }

    const handleClose = () => {
        setState(state => ({
            ...state,
            isOpen: false,
            hasChanges: false,
            isOverwriting: false,
            overwriteDate: null,
            target: emptyOptions(patientId, AppointmentWithType.HealthCoach, AppointmentPurposeType.Consult),
            selectedPurpose: AppointmentPurposeType.Welcome,
            selectedWithType: AppointmentWithType.HealthCoach
        }));
    }

    const setupInitialTarget = (types: AppointmentTypeModel[], options: AppointmentOptions[]) => {
        const availableWithTypes = getAvailableWithTypes(types);
        const option = options?.find(x => availableWithTypes.includes(x.withType));
        setState(state => ({
            ...state,
            target: option ? Object.assign({}, option) : state.target,
            selectedWithType: option ? option.withType : state.selectedWithType,
            selectedPurpose: option ? option.purpose : state.selectedPurpose,
            overwriteDate: getOverwriteDate(
                option ? option.purpose : state.selectedPurpose,
                option ? option.withType : state.selectedWithType,
                options
            )
        }));
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<AppointmentTypeModel[]>(appointmentsQuery.allAppointmentTypes$, types => {
                const options = appointmentsQuery.getValue().appointmentOptions;

                setState(state => ({
                    ...state,
                    appointmentTypes: types
                }));

                setupInitialTarget(types, options)
            }),
            onEmit<AppointmentOptions[]>(appointmentsQuery.appointmentOptions$, options => {
                const types = appointmentsQuery.getValue().appointmentTypes;

                setState(state => ({
                    ...state,
                    appointmentOptions: options
                }));

                setupInitialTarget(types, options);
            }),
        ];

        if (state.isOpen) {
            appointmentsService.getAvailableAppointmentTypes(patientId);
            appointmentsOptionsService.getOptions(patientId).subscribe();
        }

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    };

    useEffect(useEffectCB, [patientId, state.isOpen]);

    return [
        state,
        handleWithTypeSelect,
        handlePurposeSelect,
        handleChanges,
        handleSave,
        handleOpen,
        handleClose
    ];
}