import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React from 'react';
import { AuthenticatedLayoutComponent } from '../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { PageTitleComponent } from '../../../../components/pageTitleComponent/PageTitleComponent';
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from "../../../../models/menu.models";
import { ManageAllPatientsComponent } from "../../components/manageAllPatientsComponent/ManageAllPatientsComponent";
import { useHistory } from 'react-router';
import { navigationService } from "../../../../services/navigation.service";
import { FeatureComponent } from '../../../common/components/featureFlags/FeatureComponent';
import { FeatureFlag } from '../../../common/components/featureFlags/featureFlags.models';

export const ManageAllPatientsPage: React.FC = () => {
    const history = useHistory();

    const pageName = (
        <PageTitleComponent
            startAdornment={
                <FeatureComponent featureFlag={FeatureFlag.Navigation}>
                    <IconButton id={`page-title-go-back-${PagesNamesService.allPatients}`} onClick={() => navigationService.goBack(history)}>
                        <ArrowBackIcon />
                    </IconButton>
                </FeatureComponent>
            }
            title={PagesNamesService.allPatients}
        />
    );

    return (
        <AuthenticatedLayoutComponent
            hideBackground
            title={TitlesService.allPatientsTitle}
            pageName={pageName}
            selectedMenuItem={MenuItemTypes.ManagePatients}
        >
            <ManageAllPatientsComponent />
        </AuthenticatedLayoutComponent>
    )
}