import React from 'react';
import { withRouter } from 'react-router';
import { AuthenticatedLayoutComponent } from '../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from "../../../../models/menu.models";
import { MessageForwardingComponent } from '../../components/messageForwardingComponent/MessageForwardingComponent';

class MessageForwardingPage extends React.Component<any> {
    render() {
        return (
            <AuthenticatedLayoutComponent
                hideBackground
                title={TitlesService.messagingAdminPanelTitle}
                pageName={PagesNamesService.messageForwarding}
                selectedMenuItem={MenuItemTypes.MessageForwarding}
            >
                <MessageForwardingComponent />
            </AuthenticatedLayoutComponent>
        )
    }
}

export default withRouter(MessageForwardingPage);
