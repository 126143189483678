import { AppBar, Box, Toolbar } from '@material-ui/core';
import React from 'react';
import { authQuery } from "../../../modules/auth/stores/auth";
import { useFacade } from "./commonHeaderComponent.hooks";
import { useStyles } from './commonHeaderComponent.styles';

export interface CommonHeaderComponentProps {
    employerKey?: boolean;
    hideMenu?: boolean;
    couponApplied?: boolean;
    isLicensingPage?: boolean;
    topBannerHeight?: number;
}

export const CommonHeaderComponent: React.FC<CommonHeaderComponentProps> = (props: CommonHeaderComponentProps) => {
    const { employerKey, hideMenu, isLicensingPage, topBannerHeight = 0 } = props;

    const isAuthorized = authQuery.isLoggedIn();
    const classes = useStyles({ topBannerHeight });

    const [
        Title,
        MobileMenuButton,
        MobileMenu,
        DesktopMenu,
        userAccountButtons,
        DesktopChildrenMenus,
    ] = useFacade(authQuery.getType(), employerKey);

    return (
        <AppBar className={classes.root}>
            {!isLicensingPage && <Toolbar className={classes.toolbar}>
                <Box className={classes.titleSection}>
                    {Title}
                </Box>
                <Box className={classes.userAccountBtnSection}>
                    {!hideMenu && DesktopMenu}
                    {!hideMenu && MobileMenuButton}
                    {isAuthorized && !hideMenu && userAccountButtons}
                </Box>
            </Toolbar>}
            {MobileMenu}
            {DesktopChildrenMenus}
        </AppBar>
    )
}
