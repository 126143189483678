import React from "react";
import { makeStyles, StepConnector, StepIconProps, withStyles } from "@material-ui/core";
import { CheckCircle, RadioButtonUnchecked } from '@material-ui/icons';
import clsx from 'clsx';
import { colors } from "../../../common/constants/colors";

export const FellowshipStepConnector = withStyles({
    active: {
        '& $line': {
            borderColor: colors.main,
        },
    },
    completed: {
        '& $line': {
            borderColor: colors.main,
        },
    },
    line: {
        borderColor: '#eaeaf0',
        borderRadius: 1,
        height: 60,
        marginTop: 8
    },
})(StepConnector);
  
const useFellowshipStepIconStyles = makeStyles({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 24,
        alignItems: 'center',
    },
    active: {
        color: colors.main,
    },
    incompleted: {
        color: 'currentColor',
    },
    completed: {
        color: colors.main,
        zIndex: 1,
    },
});
  
export function FellowshipStepIcon(props: StepIconProps) {
    const classes = useFellowshipStepIconStyles();
    const { active, completed } = props;
  
    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            {completed ? <CheckCircle className={classes.completed} /> : <RadioButtonUnchecked className={classes.incompleted} />}
        </div>
    );
}

