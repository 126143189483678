import { paymentPlansService } from "../../services/paymentPlans.service";
import { PaymentPlanModel } from "../../models/paymentPlan.models";
import { paymentPlansQuery } from "../../stores/paymentPlansStore";
import { useEffect, useState, useRef } from "react";
import { Subscription } from "rxjs";
import { onEmit } from "../../../common/helpers/on-emit";
import { Track, PlatformName } from "../../../tracking/tracker";
import {getPlanForAutoSelect} from "../../helpers/getPlanForAutoSelect";
import { PageSliderNavigationContext } from "../../../common/models/navigation.models";

export interface SelectPaymentPlanComponentState {
    selectedPlan: PaymentPlanModel;
    correctInviteCode: boolean;
    showMobileFooter: boolean;
}

interface ScrollElement {
    current: HTMLDivElement
}

export function useFacade(plans: PaymentPlanModel[], specifiedPlan: string | null, defaultPlan: string | null, navigationContext: PageSliderNavigationContext): [
    SelectPaymentPlanComponentState,
    ScrollElement,
    Function,
    Function,
    Function
] {
    const [state, setState] = useState({
        correctInviteCode: false,
        selectedPlan: paymentPlansQuery.getSelectedPlan(),
        showMobileFooter: false,
    } as SelectPaymentPlanComponentState);

    const prevScrollY = useRef<HTMLHeadingElement>(null);

    const handleTrialPlanSelect = (plan: PaymentPlanModel) => {
        paymentPlansService.showTrialPlan(plan).subscribe((result) => {
            if (result) {
                handlePlanSelect(plan);
            }
        });
    };

    const handlePlanSelect = (plan: PaymentPlanModel) => {
        paymentPlansService.selectPlan(plan);

        Track("Button Click", {
            type: 'select_plan',
            plan_type: plan.displayName,
            acValue: plan.displayName,
            platform: PlatformName()
        })
        navigationContext.goNext();
    };

    const isPlanAutoSelect = () => {
        const plan = getPlanForAutoSelect(plans, specifiedPlan, defaultPlan);

        return !(plan == null || false);
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<PaymentPlanModel>(paymentPlansQuery.selectedPlan$, selectedPlan => {
                setState(state => ({
                    ...state,
                    selectedPlan
                }));
            })
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [])

    useEffect(() => {
        Track("onboarding_plan_page", {
            group: 'test',
            platform: PlatformName(),
        });
    }, [])

    useEffect(() => {

        function scrollEvent() {
            if (prevScrollY) {

                if (prevScrollY.current) {
                    let rect = prevScrollY.current.getBoundingClientRect()

                    if (rect.bottom > window.scrollY) {
                        setState({ ...state, showMobileFooter: false, correctInviteCode: true })
                    } else {
                        setState({ ...state, showMobileFooter: true, correctInviteCode: true })
                    }
                }
            }
        }

        document.addEventListener('scroll', scrollEvent)

        return () => {
            document.removeEventListener('scroll', scrollEvent)
        }
    }, []);

    useEffect(() => {
        const plan = getPlanForAutoSelect(plans, specifiedPlan, defaultPlan);
        if (plan) {
            handlePlanSelect(plan);
        }
    }, [plans, specifiedPlan, defaultPlan, getPlanForAutoSelect])

    return [
        state,
        prevScrollY,
        handleTrialPlanSelect,
        handlePlanSelect,
        isPlanAutoSelect
    ]
}
