import React from 'react';
import { ReactComponent as Results } from '@img/icons/Results.svg';
import { IconProps } from './IconProps';
import { colors } from '../../modules/common/constants/colors';

const ResultsIcon: React.FC<IconProps> = (props: IconProps) => {
    const { isSelected } = props;

    return (
        <Results fill={isSelected ? colors.main : colors.gray2} />
    )
}

export default ResultsIcon;