import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogTitle: {
            padding: 30,
            paddingLeft: 40,
            paddingRight: 40,
        },
        dialogTitleCloseAction: {
            position: 'absolute',
            right: 20,
            top: 20,
            color: theme.palette.grey[500],
        },
        dialogContent : {
            paddingTop: 0,
            paddingBottom: 30,
            paddingLeft: 40,
            paddingRight: 40,
        },
        dialogActions: {
            paddingTop: 0,
            paddingBottom: 30,
            paddingLeft: 40,
            paddingRight: 40,
        }
    }
));

