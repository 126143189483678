import { useState } from "react";
import { useHistory } from "react-router-dom";
import { confirmService } from "../../../../../services/confirm.service";
import { navigationService } from "../../../../../services/navigation.service";
import { EmployeeAppointmentModel } from "../../../models/appointments.models";
import { appointmentsService } from "../../../services/appointments.service";

interface AppointmentDayItemComponentState {
    menuAnchor: HTMLElement;
    notesMenuAnchor: HTMLElement;
    editDialogOpen: boolean;
}

export function useFacade(): [
    AppointmentDayItemComponentState,
    (appointmentId: number) => void,
    (joinLink: string) => void,
    (event: React.MouseEvent<HTMLButtonElement>) => void,
    (event: any) => void,
    (event: React.MouseEvent<HTMLButtonElement>) => void,
    (event: any) => void,
    (toggle: boolean) => void,
    (appointment: EmployeeAppointmentModel) => void,
    (id: number) => void
] {
    const [state, setState] = useState({
        menuAnchor: null
    } as AppointmentDayItemComponentState);

    const handleCancelAppointment = (appointmentId: number) => {
        confirmService.confirm(
            'Cancel Appointment',
            'Are you sure you want to cancel the appointment?',
            'Cancel Appointment',
            'Discard',
            'danger')
            .subscribe(
                () => appointmentsService.cancelAsEmployee(appointmentId)
            );
    }

    const handleJoinAppointment = (joinLink: string) => {
        window.open(joinLink, '_blank');
    }

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setState({ ...state, menuAnchor: event.currentTarget });
    };

    const handleCloseMenu = (event: any) => {
        event.stopPropagation();
        setState({ ...state, menuAnchor: null });
    };

    const handleOpenNotesMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setState({ ...state, notesMenuAnchor: event.currentTarget });
    };

    const handleCloseNotesMenu = (event: any) => {
        event.stopPropagation();
        setState({ ...state, notesMenuAnchor: null });
    };

    const history = useHistory();

    const goToPatientProfile = (id: number) => {
        navigationService.toManagePatientProfile(history, id)
    }

    const handleToggleEditDialog = (toggle: boolean) => {
        setState({ ...state, editDialogOpen: toggle });
    }

    const handleRescheduleAppointment = (appointment: EmployeeAppointmentModel) => {
        appointmentsService.openAppointmentReschedule(appointment).subscribe();
    }

    return [
        state,
        handleCancelAppointment,
        handleJoinAppointment,
        handleOpenMenu,
        handleCloseMenu,
        handleOpenNotesMenu,
        handleCloseNotesMenu,
        handleToggleEditDialog,
        handleRescheduleAppointment,
        goToPatientProfile
    ];
}