import { LocationModel } from '../models/locations.models';
import { LocationsState, LocationsStore, locationsStore } from './locations.store';
import { Query } from '@datorama/akita';

/**
 * Provides method for working with locations store
 */
export class LocationsQuery extends Query<LocationsState> {
    availableLocations$ = this.select(state => state.availableLocations);

    constructor(protected store: LocationsStore) {
        super(store);
    }

    /**
     * Returns locations
     */
    public getToken(): Array<LocationModel> {
        return this.getValue().availableLocations;
    }
}

export const locationsQuery = new LocationsQuery(locationsStore);