import { Box, IconButton, Dialog, DialogActions, DialogContent, useMediaQuery, useTheme } from "@material-ui/core";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import { WildHealthButton } from "../../components/wildHealthButton/WildHealthButton";
import { useStyles } from "./healthFormWelcome.styles";
import Cancel from "@img/icons/cancel.svg";
import Welcome from "@img/icons/welcome.svg";
import clsx from 'clsx';
import commonUseStyles from '../../../common/styles/common.styles';

export interface HealthFormWelcomeProps {
    opened: boolean;
    handleClose: () => void;
}

export const HealthFormWelcome: React.FC<HealthFormWelcomeProps> = (props: HealthFormWelcomeProps) => {
    const classes = useStyles();
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <>
            <Dialog
                onClose={() => props.handleClose()}
                open={props.opened}
                className={classes.dialog}
            >
                {!isSmallScreen && <MuiDialogTitle
                    className={classes.dialogTitle}
                    disableTypography
                >
                    <Box pl={1} pr={1}>
                        <IconButton id="health-form-welcome-close" onClick={() => {
                            props.handleClose()
                        }}>
                            <img src={Cancel} alt='Cancel' />
                        </IconButton>
                    </Box>

                </MuiDialogTitle>}

                <DialogContent className={classes.content}>
                    {isSmallScreen && <IconButton
                        id="health-form-welcome-close"
                        onClick={() => {
                            props.handleClose()
                        }}
                        className={classes.dialogCloseButton}
                    >
                        <img src={Cancel} alt='Cancel' />
                    </IconButton>}
                    <Box>
                        <img
                            src={Welcome}
                            alt='Welcome' />
                    </Box>
                    <Box mb="12px" className={clsx(commonClasses.size20, commonClasses.textMedium, commonClasses.colorBlack)}>
                        Health Forms!
                    </Box>
                    <Box textAlign="center" className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorBlack)}>
                        In order for our providers to give you the most effective and individualized 
                        recommendations to improve your health, please complete these health 
                        forms to offer insight into your medical and family history.
                    </Box>
                </DialogContent>

                <DialogActions className={classes.action}>
                    <WildHealthButton
                        id="health-form-proceed"
                        onClick={() => {
                            props.handleClose()
                        }}
                        size='large'
                        fullWidth={isSmallScreen}
                    >
                        Proceed
                    </WildHealthButton>
                </DialogActions>
            </Dialog>
        </>
    );
}
