import { Button } from "@material-ui/core";
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: colors.bg,
            [theme.breakpoints.down("xs")]: {
                height: "fit-content",
            },
        },
        content: {
            padding: 0,
        },
        title: {
            fontSize: 20,
            fontWeight: 500,
            color: colors.black,
        },
        link: {
            fontSize: 14,
            fontWeight: 500,
            color: colors.main,
            cursor: 'pointer'
        },
        filterButton: {
            color: colors.gray1,
            cursor: 'pointer',
            fontWeight: 500,
            fontSize: 15,
            height: 40,
            padding: 10,
            paddingLeft: 15,
            paddingRight: 15
        },
        selectedFilterButton: {
            cursor: 'pointer',
            fontWeight: 500,
            fontSize: 15,
            background: colors.lightGray2,
            color: colors.main,
            borderRadius: 20,
            height: 40,
            padding: 10,
            paddingLeft: 15,
            paddingRight: 15
        },
        navigateContainer: {
            width: 70,
        },
        navigateButton: {
            padding: 10,
            minWidth: 28,
            width: 28,
            height: 28,
        },
        box: {
            background: colors.main,
            borderRadius: 8,
            height: 360,
            width: "100%",
            color: colors.white,
            fontSize: 14,
            padding: "24px 30px",
            overflow: "auto",
            [theme.breakpoints.down("xs")]: {
                maxWidth: 276,
                height: 326,
                flexShrink: 0,
            },
        },
        goalTitle: {
            fontSize: 20,
            fontWeight: 500,
        },
        noHealthGoalsText: {
            fontSize: 15,
            fontWeight: 600,
            color: colors.main,
        },
        noHealthGoalsSubText: {
            fontSize: 15,
            fontWeight: 500,
            color: colors.gray1,
        },
        imageContainer: {
            minWidth: 180,
            minHeight: 88,
            borderRadius: 12,
            background: colors.lightGray2,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            color: colors.white,
            display: 'flex',
            padding: 20,
            alignItems: 'center',
        },
    })
);

export const NavigateButton = withStyles((theme: Theme) => ({
    root: {
        color: colors.main,
        backgroundColor: colors.stroke,
        borderRadius: 2,
        "&:hover": {
            backgroundColor: `${colors.stroke}CC`,
        },
        "&:disabled": {
            color: colors.gray2,
            backgroundColor: colors.disabled,
        },
    },
}))(Button);
