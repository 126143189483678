import React from "react";
import { Box, Divider, useTheme, useMediaQuery } from "@material-ui/core";
import { QuestionnaireSection } from "../../../questionnaire/models/questionnaire.models";
import { NoteLogModel } from "../../models/notes.models";
import { AnswerComponent } from "../../../questionnaire/components/answerComponent/AnswerComponent";
import commonUseStyles from "../../../common/styles/common.styles";
import clsx from 'clsx';

interface NoteQuestionnaireResultProps {
    questionnaire: QuestionnaireSection[];
    logs: NoteLogModel[];
}

export const NoteQuestionnaireResult: React.FC<NoteQuestionnaireResultProps> = (props: NoteQuestionnaireResultProps) => {
    const {
        questionnaire,
        logs
    } = props;
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <Box>
            <Box>
                <span className={isSmallScreen ? clsx(commonClasses.size16, commonClasses.textMedium, commonClasses.colorBlack) : "size-1_5 text-medium uppercase"}>Questionnaire</span>
            </Box>
            {isSmallScreen && <Box py={2}>
                <Divider className={commonClasses.renderDivider} />
            </Box>}
            {
                questionnaire.map((section, sectionIndex) =>
                    <React.Fragment key={sectionIndex}>
                        <Box mt={!isSmallScreen && 5} mb={isSmallScreen && 2}>
                            <span className={isSmallScreen ? clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorBlack, commonClasses.capitalize) : "size-1_25 text-medium uppercase"}>
                                {section.name}
                            </span>
                        </Box>
                        {isSmallScreen && sectionIndex > 0 && <Box pb={2}>
                            <Divider className={commonClasses.renderDivider} />
                        </Box>}
                        <Box mb={isSmallScreen ? "30px" : 10}>
                            {
                                section.questions.map((question, index) =>
                                    <Box key={index} mb={isSmallScreen && "20px"}>
                                        <AnswerComponent
                                            answer={logs.find(x => x.key === question.name)?.value ?? ''}
                                            question={question}
                                            componentInNotes
                                        />
                                    </Box>
                                )
                            }
                        </Box>
                    </React.Fragment>
                )
            }
        </Box>
    )
}