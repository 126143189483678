import React from 'react';
import { ReactComponent as Dashboard } from '@img/icons/Dashboard.svg';
import { IconProps } from './IconProps';
import { colors } from '../../modules/common/constants/colors';

const DashboardIcon: React.FC<IconProps> = (props: IconProps) => {
    const { isSelected } = props;

    return (
        <Dashboard fill={isSelected ? colors.main : colors.gray2} />
    )
}

export default DashboardIcon;