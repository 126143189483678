export const roles = {
    portalAdmin: 18,
    licensingProvider: 43,
    careCoordinator: 99,
    intakeSpecialist: 138,
    admin: 138,
    coach: 211,
    provider: 328,
    fellow: 422,
    patient: 405,
    staff: 491,
    viewOnly: 542,
    registrationManager: 543,
    virtualAssistant: 696
};

export const isCoachRole = (roleId: number) : boolean => {
    return [roles.coach].includes(roleId);
}

export const isProviderRole = (roleId: number) : boolean => {
    return [roles.provider, roles.licensingProvider, roles.portalAdmin].includes(roleId);
}

export const isStaffRole = (roleId: number) : boolean => {
    return [roles.staff, roles.careCoordinator].includes(roleId);
}

export const isStaffOrAdmin = (roleId: number) : boolean => {
    return [roles.staff, roles.careCoordinator, roles.portalAdmin].includes(roleId);
}

