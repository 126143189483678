import React from "react";
import {useHistory} from "react-router";
import {navigationService} from "../../../../services/navigation.service";
import {
    AuthenticatedLayoutComponent
} from "../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent";
import {TitlesService} from "../../../../constants/title.constants";
import {PagesNamesService} from "../../../../constants/pagesNames.constants";
import {MenuItemTypes} from "../../../../models/menu.models";
import {ReferralOrderModel} from "../../models/referralOrders.models";
import {NewReferralOrderComponent} from "../../components/newReferralOrderComponent/NewReferralOrderComponent";
import {Box} from "@material-ui/core";

export const ReferralOrderPagePage: React.FC = (props: any) => {
    const history = useHistory();
    const patientId = props?.location?.state?.patientId as number;
    const order = props?.location?.state?.order as ReferralOrderModel | null;
    const handleGoBack = () => {
        navigationService.toDashboard(history);
    }

    return (
        <AuthenticatedLayoutComponent
            hideBackground
            title={TitlesService.referralOrderTitle}
            pageName={PagesNamesService.referralOrder}
            selectedMenuItem={MenuItemTypes.Dashboard}
        >
            <Box pt={2}>
                <NewReferralOrderComponent patientId={patientId} targetOrder={order} handleGoBack={handleGoBack}/>
            </Box>
        </AuthenticatedLayoutComponent>
    )
}