import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderColor: colors.gray4,
      borderRadius: 2,
      '& label.Mui-focused': {
        color: colors.main,
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          borderColor: colors.main,
        },
        borderColor: colors.gray4,
        borderRadius: 2,
      },
    },
    dialog: {
      [theme.breakpoints.down("xs")]: {
        paddingTop: '205px !important',
        paddingLeft: '0px !important',
        paddingRight: '0px !important',
        paddingBottom: '0px !important',
        '& .MuiDialog-paperFullScreen': {
          borderRadius: '20px 20px 0 0',
        },
      },
    },
    dialogContent: {
      padding: '30px 40px',
      width: 540,
      height: 540,
      [theme.breakpoints.down("xs")]: {
        padding: 16,
        width: 'auto',
      },
    },
    dialogTitle: {
      display: 'flex',
      justifyContent: "space-between",
      alignItems: "center",
      margin: 0,
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(5),
      paddingTop: 30,
      boxShadow: "0px 2px 13px rgba(168, 173, 198, 0.14)"
    },
    action: {
      color: colors.main,
      paddingRight: theme.spacing(5),
      paddingLeft: theme.spacing(5),
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
      boxShadow: "0px 2px 13px rgba(168, 173, 198, 0.25)"
    },
    fixedButton: {
      fill: colors.main,
      width: 58,
      height: 58
    },
    icon: {
      fill: colors.main,
    },
  })
);
