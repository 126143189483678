import { Box, useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { LabInputNames } from "../../inputs/constants/inputs.constants";
import { LabResultComponent } from '../components/labResultComponent/LabResultComponent';
import { dnaDefinitions, HealthReportSections, HealthReportMode } from "../models/healthReport.models";
import { HealthReportBasePage, HealthReportMobileBasePage } from "./HealthReportBasePage";
import { useStyles } from './healthReport.styles';
import { HealthReportPageProps } from "./healthReportPageProps";

export const HealthReportExerciseAndRecovery7Page: React.FC<HealthReportPageProps> = (props: HealthReportPageProps) => {
    const {
        pageNumber,
        report,
        practice
    } = props;

    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const labValues = report.labReport.values;

    const exerciseDnaKeys = [
        "PPARGC1A(rs8192678)",
        "PPAR-α(rs4253778)",
        "PPAR-α(rs135549)",
        "ADRB3(rs4994)",
        "NRF(rs7181866)",
        "AGT(rs699)",
        "TRHR(rs16892496)",
        "BDKRB2(rs1799722)",
        "CRP(rs1205)",
        "SLC30A8(rs13266634)",
        "COL5A1(rs12722)",
        "TNF(rs1800629)",
        "ACTN3(rs1815739)",
    ]

    return (
        props.mode !== HealthReportMode.PrintMode && isSmallScreen ? (
            <HealthReportMobileBasePage section={HealthReportSections.ExerciseAndRecovery} pageNumber={pageNumber} practice={practice}>
                <Box px={2}>
                    <Box>
                        <span className={classes.reportTitle}>Detailed Exercise & Recovery Genetics and Labs</span>
                    </Box>
                    <Box mt="10px">
                        <Box>
                            <span className={classes.reportSubTitle}>Genetics</span>
                        </Box>
                        <Box mt={1} fontSize="14px" lineHeight="22px">
                            {
                                exerciseDnaKeys.map(key => {
                                    return <Box key={key} mt={1}>
                                        <span className="text-light-medium"><span className="text-bold">{key}:</span> {dnaDefinitions[key]}</span>
                                    </Box>
                                })
                            }

                        </Box>
                    </Box>
                    <Box mt="20px">
                        <Box>
                            <span className={classes.reportSubTitle}>Labs</span>
                        </Box>
                        <Box mt={1}>
                            <LabResultComponent label="DHEA" value={labValues.getLabReportValue(LabInputNames.Dhea)} />
                            <LabResultComponent label="iGF" value={labValues.getLabReportValue(LabInputNames.Igf)} />
                            <LabResultComponent label="CRP" value={labValues.getLabReportValue(LabInputNames.Crp)} />
                            <LabResultComponent label="Testosterone, Free" value={labValues.getLabReportValue(LabInputNames.TestosteroneFree)} />
                            <LabResultComponent label="SHBG" value={labValues.getLabReportValue(LabInputNames.Shbg)} />
                        </Box>
                    </Box>
                </Box>
            </HealthReportMobileBasePage>
        ) : (
            <HealthReportBasePage section={HealthReportSections.ExerciseAndRecovery} pageNumber={pageNumber} practice={practice}>
                <>
                    <Box px={15} pt={5}>
                        <Box>
                            <span className="report-title">Detailed Exercise & Recovery Genetics and Labs</span>
                        </Box>
                        <Box mt={3}>
                            <Box>
                                <span className="report-sub-title">Genetics</span>
                            </Box>
                            <Box mt={1}>
                                {
                                    exerciseDnaKeys.map(key => {
                                        return <Box key={key} mt={1}>
                                            <span className="text-light-medium"><span className="text-bold">{key}:</span> {dnaDefinitions[key]}</span>
                                        </Box>
                                    })
                                }

                            </Box>
                        </Box>
                        <Box mt={3}>
                            <Box>
                                <span className="report-sub-title">Labs</span>
                            </Box>
                            <Box mt={1}>
                                <Box width={0.3}>
                                    <LabResultComponent label="DHEA" value={labValues.getLabReportValue(LabInputNames.Dhea)} />
                                    <LabResultComponent label="iGF" value={labValues.getLabReportValue(LabInputNames.Igf)} />
                                    <LabResultComponent label="CRP" value={labValues.getLabReportValue(LabInputNames.Crp)} />
                                    <LabResultComponent label="Testosterone, Free" value={labValues.getLabReportValue(LabInputNames.TestosteroneFree)} />
                                    <LabResultComponent label="SHBG" value={labValues.getLabReportValue(LabInputNames.Shbg)} />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </>
            </HealthReportBasePage>
        )
    )
}
