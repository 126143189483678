import {
    PaymentPlansState,
    paymentPlansStore,
    PaymentPlansStore
} from './paymentPlans.store';
import {Query} from '@datorama/akita';
import {PaymentPlanModel} from "../../models/paymentPlan.models";
import {PaymentPeriodModel} from "../../models/paymentPeriod.models";
import {PaymentCouponModel, PaymentPriceModel} from "../../models/paymentPrice.models";
import { EmployerProductModel } from '../../models/employerProduct.model';

/**
 * Provides payment plan queries
 */
export class PaymentPlansQuery extends Query<PaymentPlansState> {

    plans$ = this.select(state => state.plans);
    trialPlan$ = this.select(state => state.trialPlan);
    regularPlans$ = this.select(state => state.regularPlans);
    activationPlans$ = this.select(state => state.activationPlans);
    founderPlans$ = this.select(state => state.founderPlans);
    proposedPlans$ = this.select(state => state.proposedPlans);
    upsellPlans$ = this.select(state => state.upsellPlans);
    activePaymentPlans$ = this.select(state => state.activePaymentPlans);
    selectedPlan$ = this.select(state => state.selectedPlan);
    selectedPeriod$ = this.select(state => state.selectedPeriod);
    selectedPrice$ = this.select(state => state.selectedPrice);
    isCouponApplied$ = this.select(state=> state.isCouponApplied);
    coupon$ = this.select(state=> state.coupon);
    couponCode$ = this.select(state=> state.couponCode);
    employerProduct$ = this.select(state=> state.employerProduct);
    promoCodes$ = this.select(state => state.promoCodes);

    constructor(protected store: PaymentPlansStore) {
        super(store);
    }

    /**
     * Returns selected payment plan
     */
    getSelectedPlan(): PaymentPlanModel {
        return this.getValue().selectedPlan;
    }

    /**
     * Returns selected payment period
     */
    getSelectedPeriod(): PaymentPeriodModel {
        return this.getValue().selectedPeriod;
    }

    /**
     * Returns selected payment price
     */
    getSelectedPrice(): PaymentPriceModel {
        return this.getValue().selectedPrice;
    }

    /**
     * Returns coupon code
     */
    public getCouponCode(): string {
        return this.getValue().couponCode;
    }

    /**
     * Returns coupon
     */
    public getCoupon(): PaymentCouponModel {
        return this.getValue().coupon;
    }

    /**
     * Returns is Payment Coupon Applied
     */
    public isPaymentCouponApplied() : boolean {
        return this.getValue().isCouponApplied;
    }

    /**
     * Returns banerCdnUrl for employer
     */
    public getEmployerProduct(): EmployerProductModel {
        return this.getValue().employerProduct;
    }
}

export const paymentPlansQuery = new PaymentPlansQuery(paymentPlansStore);