import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sections: {
            backgroundColor: colors.white,
            height: '100%',
            marginTop: '28px'
        }
    }),
);