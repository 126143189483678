import { Store, StoreConfig } from '@datorama/akita';
import { LeadSourceModel } from '../models/leadSource.models';


/**
 * Represents lead sources state
 */
export interface LeadSourcesState {
    leadSources: LeadSourceModel[];
}

export function createInitialState(): LeadSourcesState {
    return {
        leadSources: []
    };
}

@StoreConfig({name: 'leadSources', resettable: true })
export class LeadSourcesStore extends Store<LeadSourcesState> {
    constructor() {
        super(createInitialState());
    }

    public deleteLeadSource(model: LeadSourceModel) {
        const leadSources = this.getValue().leadSources.filter(x => x.id !== model.id);
        this.update({leadSources: leadSources});
    }

    public createLeadSource(model: LeadSourceModel) {
        this.update({leadSources: [...this.getValue().leadSources, model]});
    }

    public changeActivity(model: LeadSourceModel) {
        const leadSources = this.getValue().leadSources.map(p => p.id === model.id
            ? {
                id: p.id,
                name: p.name,
                isOther: p.isOther,
                isActive: model.isActive,
                count: p.count,
            } as LeadSourceModel
            : p);
        this.update({leadSources: leadSources});
    }
}

export const leadSourcesStore = new LeadSourcesStore();
