import Axios from "axios-observable";
import { Observable } from "rxjs";
import { authHeader } from "../../common/helpers/auth-header";
import { snackService } from "../../common/snack/state";
import { UpdateInsuranceStatesModel, InsuranceModel, AddInsuranceModel, RemitModel, InsuranceConfiguration, CreateInsuranceConfigurationModel, UpdateInsuranceConfigurationModel, CreateServiceConfigurationModel, ServiceConfiguration, UpdateServiceConfigurationModel, InsurancePlanModel } from "../models/insurance.models";
import { insuranceStore, InsuranceStore } from "../stores/insurance.store";

/**
 * Provides method for working with Insurance
 */
export class InsuranceManagementService {
  private url = `${process.env.REACT_APP_API_URL}InsuranceManagement`;
  private configurationsUrl = `${process.env.REACT_APP_API_URL}InsuranceConfigurations`;

  constructor(private insuranceStore: InsuranceStore) {
  }

  /**
   * Returns all insurances
   */
  public getInsurances(stateId?: number, age?: number): Observable<InsuranceModel[]> {
    let url = `${this.url}/Insurances`;
    if (stateId) {
      url += `?stateId=${stateId}`;
    }

    if (age) {
      const separator = url.includes('?') ? '&' : '?'
      url += `${separator}age=${age}`;
    }

    const config =  { headers: authHeader() }

    return new Observable(observer => {
      Axios.get(url, config)
        .pipe()
        .subscribe(
          (response) => {
            this.insuranceStore.update({insurances: response.data});
            observer.next(response.data);
            observer.complete();
          },
          error => {
            snackService.commonErrorHandler(error)
            observer.error();
          }
        );
    })
  }

  /**
   * Add Insurance
   */
  public addInsurance(model: AddInsuranceModel): Observable<InsuranceModel> {
    let url = `${this.url}/Insurance`;
    const config =  { headers: authHeader() }

    return new Observable(observer => {
      Axios.post<InsuranceModel>(url, model, config)
        .pipe()
        .subscribe(
          (response) => {
            this.insuranceStore.updateInsuranceStates(response.data);
            snackService.success("Added successfully!")
            observer.next(response.data);
            observer.complete();
          },
          error => {
            snackService.commonErrorHandler(error)
            observer.error();
          }
        );
    })
  }

  /**
   * Add eligible states for an existing carrier
   */
  public addInsuranceStates(model: UpdateInsuranceStatesModel): Observable<InsuranceModel> {
    let url = `${this.url}/AddInsuranceStates`;
    const config =  { headers: authHeader() }

    return new Observable(observer => {
      Axios.post<InsuranceModel>(url, model, config)
        .pipe()
        .subscribe(
          (response) => {
            this.insuranceStore.updateInsuranceStates(response.data);
            snackService.success("Added the eligible states for insurance successfully!")
            observer.next(response.data);
            observer.complete();
          },
          error => {
            snackService.commonErrorHandler(error)
            observer.error();
          }
        );
    })
  }

  /**
   * Remove eligible states for an existing carrier
   */
  public removeInsuranceStates(model: UpdateInsuranceStatesModel): Observable<InsuranceModel> {
    let url = `${this.url}/RemoveInsuranceStates`;
    const config =  { headers: authHeader() }

    return new Observable(observer => {
      Axios.post<InsuranceModel>(url, model, config)
        .pipe()
        .subscribe(
          (response) => {
            this.insuranceStore.updateInsuranceStates(response.data);
            snackService.success("Removed the states for insurance successfully!")
            observer.next(response.data);
            observer.complete();
          },
          error => {
            snackService.commonErrorHandler(error)
            observer.error();
          }
        );
    })
  }

  public getNoteRemit(noteId: number): Observable<RemitModel[]> {
    let url = `${this.url}/Note/${noteId}/Remit`;
    let config = { headers: authHeader() }

    return new Observable(observer => {
      Axios.get<RemitModel[]>(url, config)
        .pipe()
        .subscribe(
          (response) => {
            observer.next(response.data);
            observer.complete();
          },
          error => {
            snackService.commonErrorHandler(error)
            observer.error();
          }
        );
    })
  }

  public createPatientResponsibilityInvoice(noteId: number, copay: number, coinsurance: number, deductible: number) {
    let url = `${this.url}/Note/${noteId}/PatientResponsibility`;
    let config = { headers: authHeader() }

    return new Observable(observer => {
      Axios.post<RemitModel>(url, { 
        copayAmount: copay, 
        coinsuranceAmount: coinsurance, 
        deductibleAmount: deductible 
      }, config)
        .pipe()
        .subscribe(
          (response) => {
            snackService.success("Patient responsibility invoice successfully created!")
            observer.next(response.data);
            observer.complete();
          },
          error => {
            snackService.commonErrorHandler(error)
            observer.error();
          }
        );
    })
  }

  /**
   * Returns all insurance configurations
   */
  public getInsuranceConfigurations(): Observable<InsuranceConfiguration[]> {
    let url = `${this.configurationsUrl}`;

    const config =  { headers: authHeader() }

    return new Observable(observer => {
      Axios.get(url, config)
        .pipe()
        .subscribe(
          (response) => {
            this.insuranceStore.update({insuranceConfigurations: response.data});
            observer.next(response.data);
            observer.complete();
          },
          error => {
            snackService.commonErrorHandler(error)
            observer.error();
          }
        );
    })
  }

  /**
   * Create Insurance Configuration
   */
  public createInsuranceConfiguration(model: CreateInsuranceConfigurationModel): Observable<InsuranceConfiguration> {
    let url = `${this.configurationsUrl}`;
    const config =  { headers: authHeader() }

    return new Observable(observer => {
      Axios.post<InsuranceConfiguration>(url, model, config)
        .pipe()
        .subscribe(
          (response) => {
            snackService.success("Configuration successfully created!")
            observer.next(response.data);
            observer.complete();
          },
          error => {
            snackService.commonErrorHandler(error)
            observer.error();
          }
        );
    })
  }

  /**
   * Returns the insurance configuration by Id
   */
  public getInsuranceConfigurationById(id: number): Observable<InsuranceConfiguration> {
    let url = `${this.configurationsUrl}/${id}`;

    const config =  { headers: authHeader() }

    return new Observable(observer => {
      Axios.get<InsuranceConfiguration>(url, config)
        .pipe()
        .subscribe(
          (response) => {
            this.insuranceStore.update({ targetInsuranceConfiguration: response.data });
            observer.next(response.data);
            observer.complete();
          },
          error => {
            snackService.commonErrorHandler(error)
            observer.error();
          }
        );
    })
  }

  /**
   * Update Insurance Configuration by Id
   */
  public updateInsuranceConfiguration(model: UpdateInsuranceConfigurationModel): Observable<InsuranceConfiguration> {
    let url = `${this.configurationsUrl}/${model.id}`;
    const config =  { headers: authHeader() }

    return new Observable(observer => {
      Axios.post<InsuranceConfiguration>(url, model, config)
        .pipe()
        .subscribe(
          (response) => {
            snackService.success("Basic Configuration successfully updated!")
            observer.next(response.data);
            observer.complete();
          },
          error => {
            snackService.commonErrorHandler(error)
            observer.error();
          }
        );
    })
  }

  /**
   * Delete Insurance Configuration
   */
  public deleteInsuranceConfiguration(configurationId: number): Observable<InsuranceConfiguration> {
    let url = `${this.configurationsUrl}/${configurationId}`;
    const config =  { headers: authHeader() }

    return new Observable(observer => {
      Axios.delete<InsuranceConfiguration>(url, config)
        .pipe()
        .subscribe(
          (response) => {
            this.insuranceStore.removeInsuranceConfiguration(configurationId);
            snackService.success('Configuration successfully deleted!')
            observer.next(response.data);
            observer.complete();
          },
          error => {
            snackService.commonErrorHandler(error)
            observer.error();
          }
        );
    })
  }

  /**
   * Create Service Configuration
   */
  public createServiceConfiguration(model: CreateServiceConfigurationModel): Observable<ServiceConfiguration> {
    let url = `${this.configurationsUrl}/${model.insuranceConfigId}/ServiceConfigurations`;
    const config =  { headers: authHeader() }

    return new Observable(observer => {
      Axios.post<ServiceConfiguration>(url, model, config)
        .pipe()
        .subscribe(
          (response) => {
            snackService.success("Service Configuration successfully created!")
            observer.next(response.data);
            observer.complete();
          },
          error => {
            snackService.commonErrorHandler(error)
            observer.error();
          }
        );
    })
  }

  /**
   * Update Service Configuration by Id
   */
  public updateServiceConfiguration(model: UpdateServiceConfigurationModel): Observable<ServiceConfiguration> {
    let url = `${this.configurationsUrl}/${model.insuranceConfigId}/ServiceConfigurations/${model.id}`;
    const config =  { headers: authHeader() }

    return new Observable(observer => {
      Axios.post<ServiceConfiguration>(url, model, config)
        .pipe()
        .subscribe(
          (response) => {
            snackService.success("Service Configuration successfully updated!")
            observer.next(response.data);
            observer.complete();
          },
          error => {
            snackService.commonErrorHandler(error)
            observer.error();
          }
        );
    })
  }

  /**
   * Delete Service Configuration by Id
   */
  public deleteServiceConfiguration(configurationId: number, serviceConfigId: number): Observable<ServiceConfiguration> {
    let url = `${this.configurationsUrl}/${configurationId}/ServiceConfigurations/${serviceConfigId}`;
    const config =  { headers: authHeader() }

    return new Observable(observer => {
      Axios.delete<ServiceConfiguration>(url, config)
        .pipe()
        .subscribe(
          (response) => {
            snackService.success('Service Configuration successfully deleted!')
            observer.next(response.data);
            observer.complete();
          },
          error => {
            snackService.commonErrorHandler(error)
            observer.error();
          }
        );
    })
  }

  public uploadConfigurationLogo(configurationId: number, model: FormData): Observable<string> {
    const config = {
      headers: authHeader()
    };

    return new Observable((observer) =>
      Axios.post<string>(`${this.configurationsUrl}/${configurationId}/Logo`, model, config)
          .pipe()
          .subscribe(
              response => {
                  snackService.success("Logo successfully uploaded!");
                  observer.next(response.data);
                  observer.complete();
              },
              error => {
                  snackService.commonErrorHandler(error);
                  observer.error();
                  observer.complete();
              }
          )
    );
  }

  /**
   * Get available insurance plans
   */
  public getAvailableInsurancePlans(id: number): Observable<InsurancePlanModel[]> {
    let url = `${this.configurationsUrl}/${id}/AvailableInsurancePlans`;

    const config =  { headers: authHeader() }

    return new Observable(observer => {
      Axios.get<InsurancePlanModel[]>(url, config)
        .pipe()
        .subscribe(
          (response) => {
            observer.next(response.data);
            observer.complete();
          },
          error => {
            snackService.commonErrorHandler(error)
            observer.error();
          }
        );
    })
  }
}

export const insuranceManagementService = new InsuranceManagementService(insuranceStore);
