import { useState, useEffect } from "react";
import moment from "moment";

interface ScheduleAppointmentFormComponentState {
    activeStartDate: Date;
    canMoveBack: boolean;
    isOpen: boolean;
}

/**
 * Custom Hook to manage a view Model for Appointments component
 */
export function useFacade(minDate: Date): [
    ScheduleAppointmentFormComponentState,
    () => void,
    () => void,
    (status: boolean) => void,
] {

    const [state, setState] = useState({
        canMoveBack: false,
        activeStartDate: minDate,
        isOpen: false,
    } as ScheduleAppointmentFormComponentState);

    if(isNaN(Date.parse(state.activeStartDate.toString())) && !isNaN(Date.parse(minDate.toString()))){
        setState((state) => ({
            ...state,
            activeStartDate: minDate
        }));
    }

    const canMoveBack = () => {
        return moment(state.activeStartDate).isAfter(minDate);
    }

    const handleShowPreviousMonthView = () => {
        state.activeStartDate.setMonth(state.activeStartDate.getMonth() - 1);
        setState({ ...state, activeStartDate: state.activeStartDate, canMoveBack: canMoveBack() })
    }

    const handleShowNextMonthView = () => {
        state.activeStartDate = new Date(state.activeStartDate.getFullYear(), state.activeStartDate.getMonth() + 1, state.activeStartDate.getDate());
        setState({ ...state, activeStartDate: state.activeStartDate, canMoveBack: canMoveBack() })
    }

    const handleTooltipToggle = (status: boolean) => {
        setState({ ...state, isOpen: status });
    }

    useEffect(() => {
        setState((state) => ({
            ...state,
            activeStartDate: minDate,
            canMoveBack: canMoveBack()
        }));
    }, [minDate]);

    return [
        state,
        handleShowPreviousMonthView,
        handleShowNextMonthView,
        handleTooltipToggle,
    ];
}
