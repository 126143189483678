import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { colors } from "../../modules/common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        content: {
            maxWidth: '100%',
            flexGrow: 1,
            position: 'relative'
        },
        banner: {
            position: 'relative',
            width: '100%',
            textAlign: 'center',
            backgroundColor: colors.mainDark,
            color: colors.white,
            fontSize: 14,
            fontWeight: 400,
            padding: 16,
            paddingRight: 32,
            zIndex: theme.zIndex.drawer - 1,
        },
        link: {
            color: colors.bad,
            textDecoration: 'underline',
            '&:hover': {
                color: `${colors.bad}90 !important`
            }
        },
        bannerLinkUrl: {
          color: colors.white,
          textDecoration: 'underline',
          '&:hover': {
            color: colors.white,
          },
        }
    }),
);