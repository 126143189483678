import React from 'react';
import {Box, Tooltip, useMediaQuery, useTheme} from "@material-ui/core";
import {QuestionComponentProps} from "./QuestionComponentProps";
import {QuestionCheckComponentCastom} from "./QuestionCheckComponentCastom";
import {QuestionTextComponent} from "./QuestionTextComponent";
import {QuestionType} from "../../models/questionnaire.models";
import {QuestionTimeComponent} from "./QuestionTimeComponent";
import {QuestionNumberComponent} from "./QuestionNumberComponent";
import {QuestionCheckOneComponent} from "./QuestionCheckOneComponent";
import {QuestionRateComponent} from "./QuestionRateComonent";
import {QuestionSelectCustomComponent} from "./QusetionSelectCustomComponent";
import { useStyles } from "./questionnaire.styles";
import { QuestionCheckComponent } from './QuestionCheckComponent';
import {QuestionDateComponent} from './QuestionDateComponent';
import InfoIcon from "@material-ui/icons/Info";
import { WildHealthClickableTooltip } from '../../../common/components/WildHealthClickableTooltip/WildHealthClickableTooltip';

export const QuestionComponent: React.FC<QuestionComponentProps> = (props: QuestionComponentProps) => {
    const {
        question,
        answer,
        handleChanges,
        inline,
        error,
        disabled = false,
        componentInNotes = false,
    } = props;
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const [open, setOpen] = React.useState(false);
  
    const handleTooltipClose = () => {
      setOpen(false);
    };
  
    const handleTooltipOpen = () => {
      setOpen(true);
    };

    const renderQuestion = (type: QuestionType) => {
        switch (type) {
            case QuestionType.SelectMany: return <QuestionCheckComponentCastom
                answer={answer}
                handleChanges={handleChanges}
                question={question}
                inline={inline}
                error={error}
            />   
            case QuestionType.CheckMany: return <QuestionCheckComponent
                answer={answer}
                handleChanges={handleChanges}
                question={question}
                inline={inline}
                error={error}
            />

            case QuestionType.CheckOne: return <QuestionCheckOneComponent
                answer={answer}
                handleChanges={handleChanges}
                question={question}
                inline={inline}
                error={error}
            />

            case QuestionType.SelectOne: return <QuestionSelectCustomComponent
                answer={answer}
                handleChanges={handleChanges}
                question={question}
                inline={inline}
                error={error}
            />

            case QuestionType.NumericInput: return <QuestionNumberComponent
                disabled={disabled}
                answer={answer}
                handleChanges={handleChanges}
                question={question}
                inline={inline}
                error={error}
            />

            case QuestionType.TextInput: return <QuestionTextComponent
                disabled={disabled}
                answer={answer}
                handleChanges={handleChanges}
                question={question}
                inline={inline}
                error={error}
                componentInNotes={componentInNotes}
            />

            case QuestionType.TimeInput: return <QuestionTimeComponent
                answer={answer}
                handleChanges={handleChanges}
                question={question}
                inline={inline}
                error={error}
            />

            case QuestionType.Rate: return <QuestionRateComponent
                answer={answer}
                handleChanges={handleChanges}
                question={question}
                inline={inline}
                error={error}
            />

            case QuestionType.DateInput: return <QuestionDateComponent
                answer={answer}
                handleChanges={handleChanges}
                question={question}
                inline={inline}
                error={error}
            />
        }
    }

    const isShowTitle = () => {
        return !(question.type === QuestionType.TextInput && componentInNotes)
    }

    const tooltipContent = (content: string) => {
        return <p className="wh-tw-text-sm">{content}</p>
    }

    return (
        <>
            <Box display={inline ? 'flex' : 'block'} justifyContent="flex-start" mt={isSmallScreen ? 3 : 5}>
                {
                  isShowTitle() &&
                    <Box mb={isSmallScreen ? 0.5 : 2}>
                        {
                            question?.displayName && question.displayName.length > 0 &&
                            <span className={classes.title}>
                                {question.displayName}
                            </span>
                        }
                        {
                            question?.detail &&
                            <span className={classes.titleInfo}> {question.detail}</span>
                        }
                        {
                            question?.info && !isSmallScreen && 
                                <Tooltip title={tooltipContent(question?.info)} placement="top" arrow>
                                    <span className="wh-tw-ml-3">
                                        <InfoIcon className="wh-tw-text-xl wh-tw-text-gray2" />
                                    </span>
                                </Tooltip>
                        }
                        {
                            question?.info && isSmallScreen && 
                            <WildHealthClickableTooltip
                                tooltipContent={tooltipContent(question?.info)}
                                isOpened={open}
                                handleClose={handleTooltipClose}
                            >
                                <InfoIcon className="wh-tw-ml-3 wh-tw-text-xl wh-tw-text-gray2" onClick={handleTooltipOpen} />
                            </WildHealthClickableTooltip>
                        }
                        {
                            question?.description && question.description.length > 0 &&
                            <Box mt={0.5}>
                                <span className={classes.titleInfo}>{question.description}</span>
                            </Box>
                        }
                        {
                            question?.displayName && question.displayName.length > 0 && (question.type ===  QuestionType.CheckMany ||  question.type === QuestionType.SelectMany) &&
                            <span className={classes.titleInfo}> (Check all that apply)</span>
                        }
                        {
                            !question?.detail && question.optional &&
                            <span className={classes.titleInfo}> (Optional)</span>
                        }
                    </Box>
                }
                <Box>
                    { question && renderQuestion(question.type) }
                </Box>
                {
                    question?.hint && question.hint.length &&
                    <Box mt={1}>
                        <span className={classes.hint}>{question.hint}</span>
                    </Box>
                }
            </Box>
        </>
    )
}