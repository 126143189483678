import React from 'react';
import { ReactComponent as Users } from '@img/icons/Users.svg';
import { IconProps } from './IconProps';
import { colors } from '../../modules/common/constants/colors';

const UsersIcon: React.FC<IconProps> = (props: IconProps) => {
    const { isSelected } = props;

    return (
        <Users fill={isSelected ? colors.main : colors.gray2} />
    )
}

export default UsersIcon;