export enum IntegrationVendor {
    OpenPm = 1,
    Hint = 5,
    Stripe = 10,
    Twilio = 15,
    Elation = 20,
    Northpass = 25,
    Clarity = 95,
    None = 99,
}

export const integrationVendorNames: { [vendor: number]: string } = {
    [IntegrationVendor.OpenPm]: 'OpenPm',
    [IntegrationVendor.Hint]: 'Hint',
    [IntegrationVendor.Stripe]: 'Stripe',
    [IntegrationVendor.Twilio]: "Twilio",
    [IntegrationVendor.Elation]: "Elation",
    [IntegrationVendor.Northpass]: "Northpass",
    [IntegrationVendor.Clarity]: "Clarity",
    [IntegrationVendor.None]: "None",
}