import { Box } from '@material-ui/core';
import React from 'react';
import { AppointmentTargetType, appointmentTargetTypeNames } from '../../models/appointments.enums';
import { WildHealthRadio } from '../../../common/components/WildHealthRadio';

interface SelectTargetTypeComponentProps {
    value: AppointmentTargetType;
    handleChange: (type: AppointmentTargetType) => void;
}

export const SelectTargetTypeComponent: React.FC<SelectTargetTypeComponentProps> = (props: SelectTargetTypeComponentProps) => {
    const {
        value,
        handleChange
    } = props;

    const targetTypeKeys = Object.keys(AppointmentTargetType).filter(x => isNaN(+x));

    return (
        <Box>
            <Box display="flex" justifyContent="flex-start" mt={1}>
                {
                    targetTypeKeys.map((key, i) => (
                        <Box key={i} display="flex" justifyContent="flex-start" alignItems="center" mr={2}>
                            <Box>
                                <WildHealthRadio
                                    checked={value === AppointmentTargetType[key]}
                                    onChange={() => handleChange(AppointmentTargetType[key])}
                                    value={value}
                                    data-testid={value === AppointmentTargetType[key] ? `checked_${AppointmentTargetType[key]}` : `unchecked_${AppointmentTargetType[key]}`}
                                />
                            </Box>
                            <Box>
                                {appointmentTargetTypeNames[AppointmentTargetType[key]]}
                            </Box>
                        </Box>
                    ))
                }
            </Box>
        </Box>
    );
}
