import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles(() =>
    createStyles({
        h1: {
            fontSize: 26,
            fontWeight: 600,
            '& > span': {
                fontSize: 26,
                fontWeight: 600,
            }
        },
        h2: {
            fontSize: 22,
            fontWeight: 600,
            '& > span': {
                fontSize: 22,
                fontWeight: 600,
            }
        },
        h3: {
            fontSize: 18,
            fontWeight: 600,
            '& > span': {
                fontSize: 18,
                fontWeight: 600,
            }
        },
        bold: {
            fontWeight: 600,
            '& > span': {
                fontWeight: 600,
            }
        },
        italic: {
            fontStyle: 'italic',
            '& > span': {
                fontStyle: 'italic',
            }
        },
        blockquote: {
            background: colors.stroke,
            '& > span': {
                background: colors.stroke,
            }
        },
        list: {

        },
        line: {
            width: '100%',
            borderBottom: `2px solid ${colors.stroke}`,
            '& > span': {
                width: '100%',
                borderBottom: `2px solid ${colors.stroke}`,
            }
        },
        link: {
            textDecoration: "underline",
            textDecorationColor: colors.main,
            color: colors.main,
            '& > span': {
                textDecoration: "underline",
                textDecorationColor: colors.main,
                color: colors.main,
            }
        },
        breakWord: {
            wordBreak: "break-word",
        },
        string: {
            paddingLeft: 4,
            paddingRight: 4
        }
    })
);
