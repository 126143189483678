import React from "react";
import {useFacade} from "./newSupportTicketsBadgeComponent.hooks";
import {Badge} from "@material-ui/core";

export const NewSupportTicketsBadgeComponent: React.FC = () => {
    const [newTickets] = useFacade();

    return (
        <>
            {
                newTickets
                    ? <Badge badgeContent={'...'} color="secondary"/>
                    : <></>
            }
        </>
    );
}