import React from "react";
import { Box, FormControl, Select, MenuItem, Dialog, DialogContent, DialogActions } from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import moment from 'moment';
import { useFacade } from "./demographicExportWidgetComponent.hooks";
import { useStyles } from "./demographicExportWidgetComponent.styles";
import { DialogTitle } from "../../../../../common/components/dialogTitle/DialogTitle";
import { WildHealthButton } from '../../../../../common/components/wildHealthButton/WildHealthButton';
import { toCurrentTimeZone } from "../../../../../timezones/helpers/timezone";
import { OrderStatus } from "../../../../../orders/models/orders.models";
import { ReferralOrderModel } from "../../../../../orders/models/referralOrders.models";
import { OtherOrderModel } from "../../../../../orders/models/otherOrders.models";
import { GetMaximumVersionOfNote } from "../../../../../notes/helpers/noteHelpers";


export interface DemographicExportWidgetComponentProps {
  patientId: number | null;
}

export const DemographicExportWidgetComponent: React.FC<DemographicExportWidgetComponentProps> = (
  props: DemographicExportWidgetComponentProps
) => {

    const [
        {
            isLoading,
            isDownloading,
            isActionsOpen,
            isSelectedDemographic,
            isSelectedDiagnostic,
            isSelectedReferral,
            isSelectedNote,
            diagnosticOrders,
            selectDiagnostic,
            referralOrders,
            selectReferral,
            notes,
            selectNote
        },
        handleToggleActions,
        handleToggleDemographic,
        handleToggleDiagnostic,
        handleToggleReferral,
        handleToggleNotes,
        handleSelectOrder,
        handleSelectNote,
        handleDownload
    ] = useFacade(props.patientId);

    const classes = useStyles();

    const emptyPlaceHolder = () => {
        return <span className="text-accent-secondary">(empty)</span>
    }

    const displayValue = (value: Date) => {
        return value ? moment(toCurrentTimeZone(value)).format("MM/DD/yyyy") : emptyPlaceHolder();
    }

    const displayName = (order: ReferralOrderModel | OtherOrderModel) => {
        const data = order.status === OrderStatus.Completed 
            ? displayValue(order.statusLogs.find(el => el.status === OrderStatus.Completed).date)
            : displayValue(order.orderedAt)
        if (order.items.length && order.items[0]?.description) {
            return `${order.items[0]?.description} ${data}`
        }

        return data
    }

    const displayNoteTitle = (note) => {
        const title = GetMaximumVersionOfNote(note)?.title;
        return title ?? ' - '
    }

    return (
        <Box>
            <WildHealthButton
                id={`open-dialog-button`}
                borderRadius={4}
                size="medium"
                disabled={isLoading || isDownloading}
                onClick={() => handleToggleActions()}
            >
                Export
            </WildHealthButton>
            
            <Dialog
                open={isActionsOpen}
                onClose={() => handleToggleActions()}
                className={classes.dialog}
            >
                <DialogTitle
                    id='dialog-title'
                    onClose={() => handleToggleActions()}
                >
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Box mb={2} px={1.5} width={390} className="wh-tw-text-xl wh-tw-font-medium wh-tw-font-poppins">Please choose what you would like to export</Box>
                    <Box width={1} px={1.5}>
                        <Box display="flex" alignItems="center" mb={1} className="wh-tw-cursor-pointer" onClick={handleToggleDemographic}>
                            {
                                isSelectedDemographic ? <CheckCircleIcon className="wh-tw-text-primaryV" /> : <RadioButtonUncheckedIcon className="wh-tw-text-primaryV" />
                            }
                            <Box ml={0.5} className="wh-tw-font-beVietnamPro">Patient Demographics</Box>
                        </Box>
                        <Box display="flex" alignItems="center" mb={1} className="wh-tw-cursor-pointer" onClick={handleToggleDiagnostic}>
                            {
                                isSelectedDiagnostic ? <CheckCircleIcon className="wh-tw-text-primaryV" /> : <RadioButtonUncheckedIcon className="wh-tw-text-primaryV" />
                            }
                            <Box ml={0.5} className="wh-tw-font-beVietnamPro">Diagnostic Order</Box>
                        </Box>
                        {isSelectedDiagnostic && (
                            <Box pl={3.5} mb={1}>
                                <FormControl
                                    variant="outlined"
                                    color='primary'
                                    aria-required
                                    size="small"
                                    classes={{ root: classes.root }}
                                    fullWidth>
                                    <Box className="wh-tw-text-xxs wh-tw-text-gray600 wh-tw-uppercase" mb={1}>Select order <span className="wh-tw-text-primaryV">*</span></Box>
                                    <Select
                                        style={{ background: '#FFF' }}
                                        classes={{ root: classes.root }}
                                        value={selectDiagnostic?.id}
                                        inputProps={{
                                            name: 'Diagnostic Order',
                                            id: 'order-label',
                                            classes,
                                        }}
                                        renderValue={() => {
                                            if (selectDiagnostic === null) {
                                                return <Box className="wh-tw-text-mediumGray">Select order</Box>;
                                            }

                                            return displayName(selectDiagnostic);
                                        }}
                                        displayEmpty
                                        onChange={(v) => handleSelectOrder(v.target.value)}
                                    >
                                        {diagnosticOrders.map(order => <MenuItem key={order.id} value={order.id}>{displayName(order)}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Box>
                        )}
                        <Box display="flex" alignItems="center" mb={1} className="wh-tw-cursor-pointer" onClick={handleToggleReferral}>
                            {
                                isSelectedReferral ? <CheckCircleIcon className="wh-tw-text-primaryV" /> : <RadioButtonUncheckedIcon className="wh-tw-text-primaryV" />
                            }
                            <Box ml={0.5} className="wh-tw-font-beVietnamPro">Referral Order</Box>
                        </Box>
                        {isSelectedReferral && (
                            <Box pl={3.5} mb={1}>
                                <FormControl
                                    variant="outlined"
                                    color='primary'
                                    aria-required
                                    size="small"
                                    classes={{ root: classes.root }}
                                    fullWidth>
                                    <Box className="wh-tw-text-xxs wh-tw-text-gray600 wh-tw-uppercase" mb={1}>Select order <span className="wh-tw-text-primaryV">*</span></Box>
                                    <Select
                                        style={{ background: '#FFF' }}
                                        classes={{ root: classes.root }}
                                        value={selectReferral?.id}
                                        inputProps={{
                                            name: 'Referral Order',
                                            id: 'order-label',
                                            classes,
                                        }}
                                        renderValue={() => {
                                            if (selectReferral === null) {
                                                return <Box className="wh-tw-text-mediumGray">Select order</Box>;
                                            }

                                            return displayName(selectReferral);
                                        }}
                                        displayEmpty
                                        onChange={(v) => handleSelectOrder(v.target.value)}
                                    >
                                        {referralOrders.map(order => <MenuItem key={order.id} value={order.id}>{displayName(order)}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Box>
                        )}
                        <Box display="flex" alignItems="center" mb={1} className="wh-tw-cursor-pointer" onClick={handleToggleNotes}>
                            {
                                isSelectedNote ? <CheckCircleIcon className="wh-tw-text-primaryV" /> : <RadioButtonUncheckedIcon className="wh-tw-text-primaryV" />
                            }
                            <Box ml={0.5} className="wh-tw-font-beVietnamPro">Patient Notes</Box>
                        </Box>
                        {isSelectedNote && (
                            <Box pl={3.5} mb={1}>
                                <FormControl
                                    variant="outlined"
                                    color='primary'
                                    aria-required
                                    size="small"
                                    classes={{ root: classes.root }}
                                    fullWidth>
                                    <Box className="wh-tw-text-xxs wh-tw-text-gray600 wh-tw-uppercase" mb={1}>Select note <span className="wh-tw-text-primaryV">*</span></Box>
                                    <Select
                                        style={{ background: '#FFF' }}
                                        classes={{ root: classes.root }}
                                        value={selectNote?.id}
                                        inputProps={{
                                            name: 'Patient Note',
                                            id: 'note-label',
                                            classes,
                                        }}
                                        renderValue={() => {
                                            if (selectNote === null) {
                                                return <Box className="wh-tw-text-mediumGray">Select note</Box>;
                                            }

                                            return `${selectNote.name} ${selectNote.isCompleted ? '' : '(draft)'} ${displayNoteTitle(selectNote)}`;
                                        }}
                                        displayEmpty
                                        onChange={(v) => handleSelectNote(v.target.value)}
                                    >
                                        {notes.map(note => <MenuItem key={note.id} value={note.id}>{`${note.name} ${note.isCompleted ? '' : '(draft)'} ${displayNoteTitle(note)} - ${displayValue(note.completedAt)}`}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Box>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Box my={2}>
                        <WildHealthButton id={`cancel-dialog`} borderRadius={50} color="secondary" size="large" onClick={() => handleToggleActions()}>
                            Cancel
                        </WildHealthButton>
                    </Box>
                    <Box my={2} px={1.5}>
                        <WildHealthButton id={`delete-lab-button`} borderRadius={50} size="large" disabled={!(isSelectedDemographic || (isSelectedDiagnostic && selectDiagnostic) || (isSelectedReferral && selectReferral) || (isSelectedNote && selectNote))} onClick={() => handleDownload()}>
                            Export
                        </WildHealthButton>
                    </Box>
                </DialogActions>
            </Dialog>
        </Box >
    );
};
