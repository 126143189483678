import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    checkBoxIcon: {
        fill: "#537b98",
    },
    displayName: {
        fontWeight: 'bold',
        fontSize: 31,
    },
    address: {
        fontFamily: 'Be Vietnam Pro',
        fontSize: 19,
    },
    image: {
        width: '100%',
        maxWidth: '100%',
    },
    content: {
        backgroundColor: '#fff !important',
        paddingBottom: 30,
    },
});