import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  FormControl,
  Select,
  MenuItem,
  TextField,
  FormHelperText,
  Checkbox,
  IconButton,
  CircularProgress,
  Dialog,
  DialogContent
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import TrashIcon from '@material-ui/icons/Delete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import EditIcon from '@material-ui/icons/Edit'
import { useFacade } from './manageCommonGoalsComponent.hooks';
import { useStyles } from "./manageCommonGoalsComponent.styles";
import { WildHealthContainer } from '../../../common/components/wildHealthContainer/WildHealthContainer';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { FeatureComponent } from "../../../common/components/featureFlags/FeatureComponent";
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import { GoalCategory, goalCategoryNames } from '../../../notes/models/notes.models';

export const ManageCommonGoalsComponent: React.FC = () => {
  const [
    {
      isLoading,
      isSubmitting,
      isOpen,
      commonGoals,
      createCommonGoal,
      removingGoalId,
      errors,
      sortingColumns,
      sortingSource,
    },
    handleChanges,
    handleSorting,
    handleCreateGoal,
    handleDeleteGoal,
    handleGoToEdit,
    handleToggleNewGoal
  ] = useFacade();

  const classes = useStyles();

  const controlIcon = <CheckBoxOutlineBlankIcon className="wh-tw-text-mediumGray" />;
  const controlCheckedIcon = <CheckBoxIcon className="wh-tw-text-primaryV" />

  const renderTable = () => {
    if (isLoading) {
      return <WildHealthLinearProgress />
    }

    if (!isLoading && (!commonGoals || !commonGoals.length)) {
      return <WildHealthPlaceHolder message="No available common goals found" />
    }

    return (
      <>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {
                    sortingColumns.map((item, index) =>
                        <WildHealthTableCell key={index}>
                            {item.source
                                ? <TableSortLabel
                                    id={`sorting-goals-${item.title}`}
                                    active={sortingSource === item.source}
                                    direction={sortingSource === item.source ? item.direction : 'asc'}
                                    onClick={() => handleSorting(item.source, item.direction)}
                                >
                                    {item.title}
                                </TableSortLabel>
                                : item.title
                            }
                        </WildHealthTableCell>
                    )
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {commonGoals.map(goal => (
                <WildHealthTableRow key={goal.id}>
                  <WildHealthTableCell align="left" style={{ width: '30%' }}>
                      <Box className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-black">
                          {goal.name}
                      </Box>
                  </WildHealthTableCell>
                  <WildHealthTableCell align="left">
                      <Box className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-black">
                          {goalCategoryNames[goal.category]}
                      </Box>
                  </WildHealthTableCell>
                  <WildHealthTableCell align="left">
                      <Box className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-black">
                          {goal.completionValue} months
                      </Box>
                  </WildHealthTableCell>
                  <WildHealthTableCell align="left">
                      <Box className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-black">
                          {goal.isCompleted ? 'Yes' : 'No'}
                      </Box>
                  </WildHealthTableCell>
                  <WildHealthTableCell style={{ width: '15%' }}>
                    <IconButton
                      id="edit-goal-btn"
                      aria-describedby={goal.name}
                      disabled={isSubmitting}
                      onClick={() => handleGoToEdit(goal.id)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      id="remove-goal-btn"
                      aria-describedby={goal.name}
                      disabled={isSubmitting}
                      onClick={() => handleDeleteGoal(goal.id)}
                    >
                      {isSubmitting && removingGoalId === goal.id ? <CircularProgress size={24} className="wh-tw-text-primaryV" /> : <TrashIcon />}
                    </IconButton>
                  </WildHealthTableCell>
                </WildHealthTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }

  return (
    <Box py={2} px={10} width={1}>
      <Box my={2} display="flex" alignItems="center" justifyContent="space-between">
          <Box>
              <FeatureComponent featureFlag={FeatureFlag.Navigation}>
                  <Box className="wh-tw-font-semibold wh-tw-text-2xl wh-tw-black">{PagesNamesService.commonGoals}</Box>
              </FeatureComponent>
          </Box>
          <Box>
              <WildHealthButton
                  id="create-common-goal-button"
                  disabled={isLoading}
                  loading={isSubmitting}
                  onClick={handleToggleNewGoal}
                  fullWidth
                  style={{ height: 48 }}
              >
                  <Box className="wh-tw-text-base wh-tw-font-medium">New Common Goal</Box>
              </WildHealthButton>
          </Box>
      </Box>

      <WildHealthContainer margin={0} minHeight={'100%'}>
        {renderTable()}
      </WildHealthContainer>
      <Dialog
          onClose={handleToggleNewGoal}
          className={classes.root}
          open={isOpen}
      >
          <DialogContent className={classes.dialogContent}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Box>
                      <span className="wh-tw-text-2xl wh-tw-black wh-tw-font-medium">New Common Goal</span>
                  </Box>
                  <Box display="flex" alignItems="center">
                      <IconButton
                          id="new-common-goal-dialog-close"
                          className={classes.dialogTitleCloseAction}
                          onClick={handleToggleNewGoal}
                      >
                          <CloseIcon />
                      </IconButton>
                  </Box>
              </Box>
              <Box mt={5}>
                  <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Name*</Box>
                  <TextField
                      id={`name`}
                      fullWidth
                      required
                      size="small"
                      InputProps={{ className: 'input', classes }}
                      variant="outlined"
                      value={createCommonGoal.name}
                      error={!!errors['name']}
                      helperText={errors['name']}
                      disabled={!isOpen || isSubmitting}
                      onChange={(event) => handleChanges(event.target.id, event.target.value)}
                  />
              </Box>
              <Box mt={2}>
                  <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Completion Months*</Box>
                  <TextField
                      id={`completionValue`}
                      fullWidth
                      required
                      type="number"
                      size="small"
                      InputProps={{ className: 'input', classes }}
                      variant="outlined"
                      value={createCommonGoal.completionValue}
                      error={!!errors['completionValue']}
                      helperText={errors['completionValue']}
                      disabled={!isOpen || isSubmitting}
                      onChange={(event) => handleChanges(event.target.id, event.target.value)}
                  />
              </Box>
              <Box mt={2}>
                  <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      error={!!errors[`category`]}
                      size="small"
                      disabled={!isOpen || isSubmitting}
                      classes={{ root: classes.root }}
                      fullWidth>
                      <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Goal Category*</Box>
                      <Select
                          style={{ background: '#FFF' }}
                          classes={{ root: classes.root }}
                          value={createCommonGoal.category}
                          error={!!errors[`category`]}
                          inputProps={{
                              name: 'Goal category',
                              id: 'category-label',
                              classes,
                          }}
                          renderValue={() => {
                              if (createCommonGoal.category === null) {
                                  return <Box className="wh-tw-text-mediumGray">Select</Box>;
                              }

                              return goalCategoryNames[createCommonGoal.category];
                          }}
                          displayEmpty
                          onChange={(v) => handleChanges("category", v.target.value)}
                      >
                          <MenuItem value={GoalCategory.Diet}>Diet</MenuItem>
                          <MenuItem value={GoalCategory.Exercise}>Exercise</MenuItem>
                          <MenuItem value={GoalCategory.Sleep}>Sleep</MenuItem>
                          <MenuItem value={GoalCategory.Neuro}>Neuro</MenuItem>
                          <MenuItem value={GoalCategory.Longevity}>Longevity</MenuItem>
                          <MenuItem value={GoalCategory.Mindfulness}>Mindfulness</MenuItem>
                          <MenuItem value={GoalCategory.Other}>Other</MenuItem>
                      </Select>
                      <FormHelperText>{errors[`category`]}</FormHelperText>
                  </FormControl>
              </Box>
              <Box mt={2}>
                  <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>IsCompleted*</Box>
                  <Box display="flex" alignItems="center">
                      <Checkbox
                          icon={controlIcon}
                          color="primary"
                          checkedIcon={controlCheckedIcon}
                          checked={createCommonGoal.isCompleted}
                          onChange={() => handleChanges("isCompleted", true)}
                      />
                      <Box mr={2}>Yes</Box>
                      <Checkbox
                          icon={controlIcon}
                          color="primary"
                          checkedIcon={controlCheckedIcon}
                          checked={!createCommonGoal.isCompleted}
                          onChange={() => handleChanges("isCompleted", false)}
                      />
                      <Box>No</Box>
                  </Box>
              </Box>
              <Box mt={5} display="flex" justifyContent="flex-end" alignItems="center">
                  <Box mx={2}>
                      <WildHealthButton
                          id="new-goal-cancel"
                          onClick={handleToggleNewGoal}
                          color="tertiary"
                          size='medium'
                          disabled={!isOpen || isSubmitting}
                      >
                          Cancel
                      </WildHealthButton>
                  </Box>
                  <Box>
                      <WildHealthButton
                          id="save-goal-button"
                          size='medium'
                          onClick={handleCreateGoal}
                          loading={isSubmitting}
                      >
                          Save
                      </WildHealthButton>
                  </Box>
              </Box>
          </DialogContent>
      </Dialog>
    </Box>
  );
}