import { Box, List, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { TabItem, useFacade } from './wildHealthTabMenuControl.hooks';
import { useStyles } from './wildHealthTabMenuControl.styles';
import MenuIcon from '@img/icons/Menu.svg';
import { WildHealthLinkButton } from "../WildHealthLinkButton";
import { FeatureComponent } from '../featureFlags/FeatureComponent';
import { FeatureFlag } from '../featureFlags/featureFlags.models';
import { MenuItemTypes } from '../../../../models/menu.models';

export interface WildHealthTabMenuControlProps {
    items: TabItem[];
    initTab?: string;
    onChangeCB?: (tab: string) => void;
    pageName: JSX.Element
}

export const WildHealthTabMenuControl: React.FC<WildHealthTabMenuControlProps> = (props: WildHealthTabMenuControlProps) => {
    const {
        items,
        initTab =  `${MenuItemTypes.Profile}`,
        onChangeCB,
        pageName,
    } = props;

    const [
        {
            isOpen,
        },
        tabs,
        tabPanels,
        handleToggle,
    ] = useFacade(items, initTab, onChangeCB);

    const classes = useStyles();

    return (
        <Box display='flex'>
            <Box
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: isOpen,
                    [classes.drawerClose]: !isOpen,
                })}
            >
                <Box display='flex' justifyContent='space-between' alignItems='center' my={1}>
                    <Box>
                        <IconButton id="side-bar-menu-toggle" onClick={() => handleToggle()}>
                            <img src={MenuIcon} alt="Menu Icon" />
                        </IconButton>
                    </Box>
                    {
                        isOpen && [
                            <Box key='link'>
                                <WildHealthLinkButton
                                    id="side-bar-link-dashboard"
                                    onClick={() => handleToggle()}
                                    content={()=>{}}
                                />
                            </Box>,
                            <Box key='space' />
                        ]
                    }
                </Box>
                <List className={clsx(classes.menuList, 'flat-scroll')}>
                    {tabs}
                </List>
            </Box>

            <div className={classes.content}>
                <FeatureComponent featureFlag={FeatureFlag.Navigation}>
                    <Box className={classes.title}>
                        {pageName}
                    </Box>
                </FeatureComponent>

                {tabPanels}
            </div>
        </Box>
    );
}
