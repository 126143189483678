import {
  Box,
  createStyles,
  Dialog,
  DialogContent,
  IconButton,
  Theme,
  Tooltip,
  Typography,
  WithStyles,
  withStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { CreatePatientAppointmentFormComponent } from "../createPatinentAppointmentForm/CreatePatientAppointmentFormComponent";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { useStyles } from "./CreatePatientAppointmentDialogComponent.styles";
import { LocationModel } from "../../../locations/models/locations.models";
import { useFacade } from "./createPatientAppointmentDialogComponent.hook";
import { EmployeeModel } from "../../../employee/models/employee.models";
import { AppointmentTypeModel } from "../../models/appointments.models";
import { AppointmentWithType } from "../../models/appointments.enums";
import commonUseStyles from "../../../common/styles/common.styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import clsx from "clsx";
import { TimeZoneModel } from "../../../timezones/models/timezone.model";
import { ReactComponent as CalendarIcon } from "@img/icons/CalendarIcon.svg";
import { ReactComponent as InfoCircleIcon } from "@img/icons/InfoCircle.svg";
import { colors } from "../../../common/constants/colors";
import { WildHealthLinkButton } from "../../../../modules/common/components/WildHealthLinkButton";
import { authQuery } from "../../../auth/stores/auth";
import { UserType } from "../../../auth/models/auth.enums";
import DateRange from "@material-ui/icons/DateRange";
import {getTargetAppointmentType} from "../../helpers/appointmentHelper";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;

  const commonClasses = commonUseStyles();

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography className={clsx(commonClasses.size24, commonClasses.textMedium)}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          id="create-patient-appointment-close"
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

interface ScheduleAppointmentComponentProps {
  assignedEmployees: EmployeeModel[];
  appointmentWithType: AppointmentWithType;
  appointmentTypes: AppointmentTypeModel[];
  locations: LocationModel[];
  timeZone: TimeZoneModel;
  patientId?: number | null;
  disableSchedule?: boolean;
  buttonType?: any;
  hasFollowUp?: boolean;
}

export const CreatePatientAppointmentDialogComponent: React.FC<
  ScheduleAppointmentComponentProps
> = (props: ScheduleAppointmentComponentProps) => {
  const {
    assignedEmployees,
    appointmentWithType,
    appointmentTypes,
    locations,
    timeZone,
    patientId = null,
    disableSchedule = false,
  } = props;

  const [state, handleToggleDialog, handleGoToConversations, handleContinueDialog] = useFacade();

  const commonClasses = commonUseStyles();
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const targetAppointmentType = getTargetAppointmentType(appointmentTypes, appointmentWithType);

  const infoIcon = () => {
    return authQuery.getType() === UserType.Employee && authQuery.isDefaultPractice() ? (
      <Tooltip title={targetAppointmentType?.unavailabilityReason}>
        <InfoCircleIcon />
      </Tooltip>
    ) : (
      <InfoCircleIcon />
    );
  };

  const scheduleAppointmentButton = () => {
    const basicStyle = {
      borderColor: colors.main,
      borderStyle: "solid",
      backgroundColor: colors.white,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: colors.main,
    };

    return isSmallScreen ? (
      <WildHealthButton
        disabled={disableSchedule}
        id="create-schedule-appointment"
        onClick={handleToggleDialog}
        style={{
          borderWidth: 1,
          borderRadius: 2,
          height: 34,
          ...basicStyle,
        }}
        fullWidth
      >
        {state.toContinueDialog ? "Appointment Confirmation" : "Schedule Appointment"}
        <Box ml={1}>
          <DateRange fontSize="small" />
        </Box>
      </WildHealthButton>
    ) : (
      <WildHealthButton
        disabled={disableSchedule}
        id="create-schedule-appointment"
        onClick={handleToggleDialog}
        style={{
          borderWidth: 2,
          borderRadius: 8,
          width: 72,
          height: 52,
          ...basicStyle,
        }}
      >
        <CalendarIcon />
      </WildHealthButton>
    );
  };

  return (
    <>
      <Box className={disableSchedule && classes.disableSchedule}>
        {disableSchedule && (
          <Box className={classes.notAvailableCard}>
            <Box flex={1}>{infoIcon()}</Box>
            <Box className={classes.notAvailableCardText}>
              Visits become available to schedule as you progress along your journey.{" "}
              <a
                className={classes.notAvailableCardSubText}
                onClick={() => handleGoToConversations()}
              >
                Message us{" "}
              </a>{" "}
              if you need to schedule a visit sooner
            </Box>
          </Box>
        )}
        <Box className={disableSchedule && classes.blurBackground}>
          <Box className={classes.greatNews}>
            {targetAppointmentType?.dashboardMessageTitle}
          </Box>
          <Box className={classes.scheduleAppointment}>
            <Box flex={1} mr={2}>
              <Box className={classes.dashboardMessageDescription}>
                {targetAppointmentType?.dashboardMessageDescription}
              </Box>
              <Box mt={0.5}>
                <span className={classes.scheduleVisit}>
                  {" "}
                  Please schedule your Zoom visit
                </span>
                <WildHealthLinkButton
                  onClick={() => {
                    if (!disableSchedule) {
                      handleToggleDialog();
                    }
                  }}
                  content={<span className={classes.hereText}>here</span>}
                  id="schedule-appointment-link"
                />
              </Box>
            </Box>
            {scheduleAppointmentButton()}
          </Box>
        </Box>
      </Box>

      <Dialog
        fullScreen={isSmallScreen}
        maxWidth="md"
        open={state.toShowDialog}
        className={classes.dialog}
        onClose={handleToggleDialog}>
        {
          !isSmallScreen &&
          <DialogTitle id="customized-dialog-title" onClose={handleToggleDialog}>
            <span className={clsx(commonClasses.size24, commonClasses.textMedium, classes.dialogTitle)}>
              {state.toContinueDialog ? "Appointment Confirmation" : "Schedule Appointment"}  
            </span>
          </DialogTitle>
        }
        <DialogContent className={classes.dialogContent}>
          <Box width={isSmallScreen ? 1 : "548px"}>
            <CreatePatientAppointmentFormComponent
              isContinueDialog={state.toContinueDialog}
              handleContinueDialog={handleContinueDialog}
              toggleDialog={handleToggleDialog}
              assignedEmployees={assignedEmployees}
              appointmentTypes={appointmentTypes}
              locations={locations}
              appointmentWithType={appointmentWithType}
              handleCloseDialog={handleToggleDialog}
              timeZone={timeZone}
              patientId={patientId}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
