import { Box, Divider, Grid, Link, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import WildHealthLinearProgress from "../../../../../common/components/WildHealthLinearProgress";
import { QuestionnaireStatusType } from "../../../../../questionnaire/models/questionnaire.models";
import { useFacade } from "./healthFormsInfoWidgetComponent.hooks";
import { useStyles } from "./healthFormsInfoWidgetComponent.styles";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";


export interface HealthFormsInfoWidgetComponentProps {
  patientId: number | null;
}

interface HealthFormNameAndClass {
  name: string,
  class: string
}


const shortHealthFormsStatusNames = new Map<QuestionnaireStatusType, HealthFormNameAndClass>([
  [QuestionnaireStatusType.Completed, { name: 'Completed', class: '' } as HealthFormNameAndClass],
  [QuestionnaireStatusType.InCompleted, { name: 'In Progress', class: '' } as HealthFormNameAndClass],
  [QuestionnaireStatusType.New, { name: 'Not Started', class: '' } as HealthFormNameAndClass],
]);

export const HealthFormsInfoWidgetComponent: React.FC<HealthFormsInfoWidgetComponentProps> = (
  props: HealthFormsInfoWidgetComponentProps
) => {

  const [
    {
      isLoading,
      healthForms,
      pendingQuestionnaires,
      availableQuestionnaires,
    },
    handleGoToHealthForms
  ] = useFacade(props.patientId);

  const classes = useStyles();

  shortHealthFormsStatusNames.get(QuestionnaireStatusType.Completed).class = classes.circleGreen;
  shortHealthFormsStatusNames.get(QuestionnaireStatusType.InCompleted).class = classes.circleOrange;
  shortHealthFormsStatusNames.get(QuestionnaireStatusType.New).class = classes.circleGray;

  const renderContent = () => {
    if (isLoading) {
      return <WildHealthLinearProgress />;
    }

    return (
      <Box mt={5}>
        <Grid container spacing={3}>
          {
            [...healthForms, ...pendingQuestionnaires, ...availableQuestionnaires].map((x, index) => (
              <Fragment key={index}>
                <Grid item xs={8} sm={8} md={8} lg={8} key={index * index}>
                  <Box>
                    {x.name}
                  </Box>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} key={index * index * index}>
                  <Box display="flex">
                    <Box>
                      {
                        <Box className={shortHealthFormsStatusNames.get(x.status).class}></Box>
                      }
                    </Box>
                    <Box ml={2}>
                      {shortHealthFormsStatusNames.get(x.status).name}
                    </Box>
                  </Box>
                </Grid>
                <Divider className={classes.cardDivider}></Divider>
              </Fragment>
            ))
          }
        </Grid>
      </Box>
    );
  };

  return (
    <Box className={classes.container}>
      <Box display="flex" justifyContent="space-between">
        <Box className={classes.title}>
          Health Forms
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Link
            id='SeeAllHealthForm'
            className={classes.historyLink}
            onClick={() => handleGoToHealthForms()}
          >
            <Typography className={classes.historyLinkText} display='inline'>
              <span> See All </span>
            </Typography>
            <ArrowForwardIcon color='inherit' fontSize='small' />
          </Link>
        </Box>
      </Box>
      <Box>
        {renderContent()}
      </Box>
    </Box >
  );
};
