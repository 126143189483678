import React from 'react';
import { IconProps } from './IconProps';
import { ReactComponent as PlaygroundSvgIcon } from '@img/icons/PlaygroundIcon.svg';
import { ReactComponent as PlaygroundDisabledIcon } from '@img/icons/PlaygroundIconDisabled.svg';

const PlaygroundIcon: React.FC<IconProps> = (props: IconProps) => {
    const { isSelected } = props;

    return isSelected
        ? <PlaygroundSvgIcon/>
        : <PlaygroundDisabledIcon/>
}

export default PlaygroundIcon;