import React from 'react';
import { Box, IconButton, useTheme, useMediaQuery } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import { WildHealthTabControl } from "../../../common/components/wildHealthTabControl/WildHealthTabControl";
import { TabItem } from "../../../common/components/wildHealthTabControl/wildHealthTabControl.hooks";
import { useStylesFullWidthTabs } from '../../../common/components/wildHealthTabControl/wildHealthTabControl.styles';
import { ChatsListComponent } from '../chatsListComponent/ChatsListComponent';
import { useFacade } from "./employeeConversationsTabsComponent.hooks";
import { ChatFilterTypes, ConversationType } from '../../models/conversation.models';
import { employeeService } from '../../../employee/services/employees.service';
import { patientsService } from '../../../patients/services/patients.service';
import { conversationsService } from '../../services/conversations.service';
import { EmployeeType } from '../../../employee/models/employee.enums';
import { CreateInternalConversationDialogComponent } from '../createInternalConversationDialogComponent/CreateInternalConversationDialogComponent';
import { SelectUserDialogComponent } from '../selectUserDialogComponent/SelectUserDialogComponent';
import { ChatWithPatientItemComponent, InternalChatItemComponent } from '../chatItemComponent/ChatItemComponent';
import { useStyles } from '../employeeChatComponent/employeeChatComponent.styles';
import { authQuery } from '../../../auth/stores/auth';
import { ConversationState } from '../../models/conversationState.models';

export const EmployeeMessagesTabsComponent: React.FC = () => {
    const tabsStyles = useStylesFullWidthTabs();
    const classes = useStyles();
    const [
        {
            isLoading,
            selectedTab,
            employeeType,
            conversations,
            targetConversation,
        },
        handleChangeTab,
        handleGetFilters,
        handleNavigateToSettings,
    ] = useFacade();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    // adding a null check to avoid render conversations wo patients
    const patientConversations = conversations.filter(i => (i.proxy &&
        (i.type === ConversationType.Support || i.type === ConversationType.HealthCare) && i.patients.length > 0) ||
        (i.state === ConversationState.Closed && (i.type === ConversationType.Support || i.type === ConversationType.HealthCare) && i.patients.length > 0)
    );

    const internalConversations = conversations.filter(i => (i.proxy && i.type === ConversationType.Internal) || (i.state === ConversationState.Closed && i.type === ConversationType.Internal));

    const tabs: TabItem[] = [
        {
            title: `Patients (${patientConversations.filter((pConversation) => pConversation.unreadMessages > 0).length})`,
            content: (
                <ChatsListComponent
                    isLoading={isLoading}
                    conversations={patientConversations.sort((a, b) => {
                        if (b.unreadMessages > 0) {
                            return 1
                        }})}
                    targetConversation={targetConversation}
                    filters={handleGetFilters()}
                    chatTemplate={ChatWithPatientItemComponent}
                    newMessageComponent={SelectUserDialogComponent}
                    newMessageProps={{
                        title: 'New Message',
                        message: isSmallScreen ? '' : 'To:',
                        placeholder: 'Select Patient Name',
                        actionName: isSmallScreen ? 'Create' : 'Create New Message',
                        handleGetUsers: (search) => patientsService.getAll([], [], [], [], [], [], search, 0, 1000),
                        handleSubmit: (patientId) =>
                            conversationsService.createConversation({ targetId: patientId, type: employeeType === EmployeeType.Staff ? ConversationType.Support : ConversationType.HealthCare })
                                .subscribe()
                    }}
                />
            ),
            keepMounted: true,
        },
        {
            title: `Internal (${internalConversations.filter((iConversation) => iConversation.unreadMessages > 0).length})`,
            content: (
                <ChatsListComponent
                    isLoading={isLoading}
                    isInternal={true}
                    conversations={internalConversations.sort((a, b) => {
                        if (b.unreadMessages > 0) {
                            return 1
                        }})}
                    targetConversation={targetConversation}
                    chatTemplate={InternalChatItemComponent}
                    filters={[ChatFilterTypes.Active, ChatFilterTypes.SignedOff]}
                    newMessageComponent={CreateInternalConversationDialogComponent}
                    newMessageProps={{
                        handleGetUsers: (search) => employeeService.getActive(search),
                        handleSubmit: (patientId, employeeIds) =>
                            conversationsService.createInternalConversation({ patientId: patientId, employeeIds: employeeIds })
                                .subscribe()
                    }}
                />
            ),
            keepMounted: true,
        },
    ];

    const renderSetting = () => {
        return (authQuery.getEmployeeType() !== EmployeeType.Staff &&
            <Box mx={1}>
                <IconButton id="employee-chat-settings" onClick={() => handleNavigateToSettings()}>
                    <SettingsIcon className={classes.settingsIcon} />
                </IconButton>
            </Box>
        );
    }

    return (
        <WildHealthTabControl
            items={tabs}
            className={tabsStyles}
            initTab={selectedTab}
            onChangeCB={handleChangeTab}
            otherContent={renderSetting()}
        />
    )
};
