import React from "react";
import { Badge, Box, Card, CardContent, Grid } from "@material-ui/core";
import { useStyles } from "./navigationWidgetComponent.style";
import { useFacade } from "./navigationWidgetComponent.hooks";
import { ReactComponent as CalendarIcon } from "@img/icons/CalendarIcon.svg";
import { ReactComponent as VisitNoteIcon } from "@img/icons/VisitNoteIcon.svg";
import { ReactComponent as MessagesIcon } from "@img/icons/MessagesIcon.svg";
import { ReactComponent as LabsIcon } from "@img/icons/LabsIcon.svg";
import { ReactComponent as GoalsIcon } from "@img/icons/GoalsIcon.svg";
import { ReactComponent as BiometricsIcon } from "@img/icons/BiometricsIcon.svg";
import { colors } from "../../../common/constants/colors";
import { NavigationItemModel, NavigationItemsType } from "../../../common/models/navigation.models";

export interface NavigationWidgetComponentProps {
    patientId: number | null;
}

export const NavigationWidgetComponent: React.FC<NavigationWidgetComponentProps> = (props: NavigationWidgetComponentProps) => {
    const [
        {
            navigationItems
        },
        handleNavigate,
    ] = useFacade(props.patientId);

    const classes = useStyles();

    const getIcon = (type: NavigationItemsType) => {
        switch (type) {
            case NavigationItemsType.Appointments: return <CalendarIcon width={31} height={30} fill={colors.main} />;
            case NavigationItemsType.VisitNotes: return <VisitNoteIcon />;
            case NavigationItemsType.Messaging: return <MessagesIcon />;
            case NavigationItemsType.Labs: return <LabsIcon stroke={colors.main}/>;
            case NavigationItemsType.MyGoals: return <GoalsIcon />;
            case NavigationItemsType.Biometrics: return <BiometricsIcon />;
            default: return <></>;
        }
    }

    const renderItem = (item: NavigationItemModel, index: number) => {
        return (
            <Grid key={index} item md={4} lg={2} sm={4} xs={4}>
                <Box className={classes.itemContainer} onClick={() => handleNavigate(item.type)}>
                    {item.badge && <Badge className={classes.badge} key={`badge-navigation${item.type}`}  variant="dot" color="error"/>}
                    {getIcon(item.type)}
                    <Box className={classes.title}>{item.name}</Box>
                </Box>
            </Grid>
        )
    }

    return (
        <Card className={classes.root} variant="elevation" elevation={0}>
            <CardContent className={classes.content}>
                <Grid container spacing={2}>
                    {
                        navigationItems.map((item, index) => {
                            return renderItem(item, index);
                        })
                    }
                </Grid>
            </CardContent>
        </Card>
    );
}