import React from 'react';
import { withRouter } from 'react-router';
import { AuthenticatedLayoutComponent } from '../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from "../../../../models/menu.models";
import { ManageCommonSupplementsComponent } from '../../components/manageCommonSupplementsComponent/ManageCommonSupplementsComponent';

class ManageCommonSupplementsPage extends React.Component<any> {
    render() {
        return (
            <AuthenticatedLayoutComponent
                hideBackground
                title={TitlesService.commonSupplementsTitle}
                pageName={PagesNamesService.commonSupplements}
                selectedMenuItem={MenuItemTypes.ManageCommonSupplements}
            >
                <ManageCommonSupplementsComponent />
            </AuthenticatedLayoutComponent>
        )
    }
}

export default withRouter(ManageCommonSupplementsPage);
