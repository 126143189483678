import { Query } from '@datorama/akita';
import { HealthReportTemplatesState, HealthReportTemplatesStore, healthReportTemplatesStore } from "./healthReportTemplates.store";
import { ReportTemplateModel } from '../../models/reportTemplate.models';

/**
 * Provides Health Report Templates queries
 */
export class HealthReportTemplatesQuery extends Query<HealthReportTemplatesState> {
    reportTemplates$ = this.select(state => state.reportTemplates);

    constructor(protected store: HealthReportTemplatesStore) {
        super(store);
    }

    /**
     * Returns all Templates
     */
    public getAllTemplates(): ReportTemplateModel[] {
        return this.getValue().reportTemplates;
    }
}

export const healthReportTemplatesQuery = new HealthReportTemplatesQuery(healthReportTemplatesStore);
