import chartView from "@img/icons/chartView.svg";
import valuesView from "@img/icons/valuesView.svg";
import { Box, Grid, Table, TableBody, TableContainer, TableFooter, TableHead, Tooltip } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import DateRangeIcon from '@material-ui/icons/DateRange';
import React from 'react';
import { useHistory } from 'react-router';
import { navigationService } from "../../../../services/navigation.service";
import { SelectDataDialogComponent } from "../../../common/components/selectDataSetDialogComponent/SelectDataDialogComponent";
import { SelectViewModeComponent } from '../../../common/components/selectViewModeComponent/SelectViewModeComponent';
import { AccentButton } from '../../../common/components/wildHealthButton/AccentButton';
import { BackButton } from '../../../common/components/wildHealthButton/BackButton';
import { DiscardButton } from '../../../common/components/wildHealthButton/DiscardButton';
import { OptionsButton } from '../../../common/components/wildHealthButton/OptionsButton';
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { CommonPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/CommonPlaceHolder";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { PaginationComponent } from "../../../common/pagination/components/paginationComponent/PaginationComponent";
import { getAvailablePages } from "../../../common/pagination/helpers/get-evailable-pages";
import { SelectDataRangePopoverComponent } from "../../../common/components/selectDataRangePopoverComponent/SelectDataRangePopoverComponent";
import { VitalRowComponent } from "../vitalRowComponent/VitalRowComponent";
import { useFacade } from './vitalsHistoryComponent.hooks';
import { VitalsHistoryMode, VitalsHistoryView } from '../../stores';
import { useStyles } from './vitalsHistoryComponent.styles';
import { VitalModel } from "../../models/vital.model";
import { mediumPageSizes } from "../../../common/pagination/models/page-sizes";
import clsx from "clsx";
import { vitalService } from "../../services/vital.service";
import { vitalsValidator } from "../../validators/vital.validator";
import { useStyles as useCheckboxDropdownStyles } from "../../../common/components/checkBoxDropdown/checkboxDropdown.styles";

export interface VitalsHistoryComponentProps {
    patientId: number | null;
    isDataSetDialogOpen: boolean;
}

export const VitalsHistoryComponent: React.FC<VitalsHistoryComponentProps> = (props: VitalsHistoryComponentProps) => {
    const [
        state,
        columns,
        switchMode,
        switchView,
        handleCancel,
        handleSaveChanges,
        handleToggleDataSetDialog,
        handleAddDataSet,
        handleEditVitalValue,
        handlePageSizeChange,
        handlePageChange,
        handlePopoverOpen,
        handlePopoverClose,
        handleSelectDateRangeToggle,
        handleDateRangeChange,
    ] = useFacade(props.patientId, props.isDataSetDialogOpen);

    const {
        isLoading,
        isSubmitting,
        isChanged,
        canAddNewDataSet,
        mode,
        view,
        isDataSetDialogOpen,
        items,
        popovers,
        startDate,
        endDate,
        isSelectDateRangeOpen,
        selectDateRangeAnchor,
        errors,
    } = state;

    const classes = useStyles();
    const checkboxDropdownClasses = useCheckboxDropdownStyles();
    const history = useHistory();

    const hasItems = items && items.length > 0 && items[0].values?.length > 0;

    const actionButtons = new Map<VitalsHistoryMode, JSX.Element>([
        [
            VitalsHistoryMode.Normal,
            <AccentButton
                id="vitals-history-edit"
                onClick={() => switchMode(VitalsHistoryMode.Edit)}
            >
                Edit Vitals
            </AccentButton>
        ],
        [
            VitalsHistoryMode.Edit,
            <Box display='flex'>
                <Box mr={1}>
                    <DiscardButton
                        id="vitals-history-cancel"
                        disabled={isSubmitting}
                        onClick={() => handleCancel()}
                    >
                        Cancel
                    </DiscardButton>
                </Box>
                <Box>
                    <AccentButton
                        id="vitals-history-save"
                        disabled={isSubmitting || !isChanged || !vitalsValidator.stateIsValid(state)}
                        onClick={() => handleSaveChanges()}
                    >
                        Save Changes
                    </AccentButton>
                </Box>
            </Box>
        ],
    ]);

    const viewModes = [
        {
            id: VitalsHistoryView.Graph,
            title: 'Graph View',
            icon: <img alt="img"
                src={chartView}
                width={24} />,
        },
        {
            id: VitalsHistoryView.Values,
            title: 'Values View',
            icon: <img alt="img"
                src={valuesView}
                width={24} />,
        },
    ]

    const renderAddNewDataSet = () => {
        const button = (
            <OptionsButton
                id="vitals-history-set-add-new-data"
                className={clsx(checkboxDropdownClasses.placeHolder, { [checkboxDropdownClasses.placeHolderOpen]: isDataSetDialogOpen })}
                onClick={() => handleToggleDataSetDialog(true)}
                disabled={isSubmitting || isLoading || !canAddNewDataSet}
                variant='outlined'
                size='small'
            >
                <Box display="flex" alignItems="center">
                    <Box ml={1}>
                        <span>Add New Data Set</span>
                    </Box>
                    <Box ml={1}>
                        <AddIcon fontSize="small" />
                    </Box>
                </Box>
            </OptionsButton>
        )

        return canAddNewDataSet ? button :
            <Tooltip title='Please save or discard changes to proceed with adding new data set'>
                <span>{button}</span>
            </Tooltip>
    }

    const renderGoBack = () => {
        return (
            <Box
                className={classes.backButton}
                pb={2}
            >
                <BackButton id="vitals-history-go-back" onClick={() => navigationService.goBack(history)}
                >
                    <Box
                        display="flex"
                        alignItems="center"
                    >
                        <ArrowBack fontSize="small" />
                        <Box ml={1}>
                            <span>Back to Dashboard</span>
                        </Box>
                    </Box>
                </BackButton>
            </Box>
        )
    }

    const renderOptions = () => {
        return (
            <Box p={3}>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                >
                    <Grid
                        item
                        xs
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                    >
                        <Box pr={1} py={0.5}>
                            <SelectViewModeComponent
                                selectedId={view}
                                options={viewModes}
                                disabled={isLoading || !hasItems || isSubmitting}
                                handleChanges={(id: number) => switchView(id as VitalsHistoryView)}
                            />
                        </Box>
                        <Box pr={1} py={0.5}>
                            {renderAddNewDataSet()}
                        </Box>
                        <Box py={0.5}>
                            <OptionsButton
                                id="vitals-history-set-date-range"
                                className={clsx(checkboxDropdownClasses.placeHolder, { [checkboxDropdownClasses.placeHolderOpen]: isSelectDateRangeOpen })}
                                onClick={(e) => handleSelectDateRangeToggle(e.currentTarget)}
                                disabled={isSubmitting || isLoading}
                                variant='outlined'
                                size='small'
                                fullWidth
                            >
                                <Box display="flex" alignItems="center">
                                    <Box ml={1}>
                                        <span>Set Date Ranges</span>
                                    </Box>
                                    <Box ml={1}>
                                        <DateRangeIcon fontSize="small" />
                                    </Box>
                                </Box>
                            </OptionsButton>
                        </Box>
                    </Grid>

                    <Box justifyContent="flex-end">
                        {hasItems && actionButtons.get(mode)}
                    </Box>
                </Grid>
            </Box>
        )
    }

    const getVitals = () => {
        const vitals: Array<VitalModel[]> = [];

        items.forEach(vital => {
            if (!vitals.map(i => i[0].displayName).includes(vital.displayName)) {
                vitals.push([vital]);
            } else {
                const item = vitals.find(i => i[0].displayName === vital.displayName);
                item.push(vital);
            }
        });

        return vitals;
    }

    const renderTable = () => {
        if (isLoading) {
            return (
                <Box p={2}>
                    <WildHealthLinearProgress />
                </Box>
            )
        }

        if (!hasItems) {
            return <CommonPlaceHolder message='No vitals history in this period.' />
        }

        const groupedVitals = getVitals();

        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <WildHealthTableRow>
                            {
                                columns.map((item, index) =>
                                    <WildHealthTableCell key={index}
                                        className={classes.cell}>{item}</WildHealthTableCell>
                                )
                            }
                        </WildHealthTableRow>
                    </TableHead>
                    <TableBody>
                        {
                            groupedVitals.map((vitals, index) => {
                                let onChangeCB = (value) => value;

                                return (
                                    <React.Fragment key={index}>
                                        <VitalRowComponent
                                            index={index}
                                            vitals={vitals}
                                            view={view}
                                            mode={mode}
                                            errors={errors}
                                            onChangeCB={onChangeCB}
                                            handleChanges={handleEditVitalValue}
                                            popoverProps={{
                                                popoverAnchorEl: popovers.find(x => x.id === vitals[0].id)?.anchor,
                                                handlePopoverOpen: handlePopoverOpen,
                                                handlePopoverClose: handlePopoverClose,
                                            }}
                                        />
                                    </React.Fragment>
                                )
                            })
                        }
                    </TableBody>
                    {
                        mode !== VitalsHistoryMode.Edit &&
                        <TableFooter>
                            <WildHealthTableRow style={{ backgroundColor: "white" }}>
                                <WildHealthTableCell colSpan={11}>
                                    <PaginationComponent
                                        pageSizes={mediumPageSizes}
                                        pageSize={state.pageSize}
                                        selectedPage={state.selectedPage}
                                        handlePageSizeChange={handlePageSizeChange}
                                        totalCount={state.totalCount}
                                        availablePages={getAvailablePages(state)}
                                        handlePageSelect={handlePageChange} />
                                </WildHealthTableCell>
                            </WildHealthTableRow>
                        </TableFooter>
                    }
                </Table>
            </TableContainer>
        )
    }

    const renderContent = () => {
        return (
            <>
                {renderOptions()}
                {renderTable()}

                <SelectDataDialogComponent
                    title='Add New Data Set'
                    message='Select Date For New Data Set'
                    actionName='Add Data Set'
                    open={isDataSetDialogOpen}
                    handleClose={() => handleToggleDataSetDialog(false)}
                    handleSubmit={(date: Date) => handleAddDataSet(date)}
                    handleCheckDate={(date: Date) => vitalService.checkDate(date, props.patientId)}
                    disableDates={[]}
                    disableFuture
                />

                <SelectDataRangePopoverComponent
                    isOpen={isSelectDateRangeOpen}
                    anchorEl={selectDateRangeAnchor}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(dates: Array<Date>) => handleDateRangeChange(dates[0], dates[1])}
                    handleClose={() => handleSelectDateRangeToggle()}
                />
            </>
        )
    }

    return (
        <Box>
            {renderGoBack()}
            {renderContent()}
        </Box>
    )
}