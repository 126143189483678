import React from 'react';
import { AuthenticatedLayoutComponent } from '../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from "../../../../models/menu.models";
import { SyncRecordTypeComponent } from '../../components/syncRecordTypeComponent/SyncRecordTypeComponent';
import { Box } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { navigationService } from '../../../../services/navigation.service';
import { useHistory } from 'react-router';
import { commonUseStyles } from "../../../common/styles/common.styles";
import clsx from 'clsx';
import { authQuery } from '../../../auth/stores/auth';


export const SyncRecordTypePage: React.FC = (props: any) => {
    const typeName = props?.match?.params?.typeName ?? "Default";
    const practiceId = props?.match?.params?.practiceId ?? authQuery.getCurrentPracticeId();

    const history = useHistory();
    const commonClasses = commonUseStyles();

    return (
        <AuthenticatedLayoutComponent
            hideBackground
            title={TitlesService.syncRecordsTitle}
            selectedMenuItem={MenuItemTypes.SyncRecords}
        >
            <Box id="sync-records-dashboard-go-back" py={2} display="flex" alignItems="center" className={clsx(commonClasses.colorGray1, commonClasses.backgroundColorBg, commonClasses.pointer)} onClick={() => {navigationService.toSyncRecordDashboard(history)}}>
                <Box>
                    <ArrowBack fontSize="small"/>
                </Box>
                <Box ml={1}>
                    <span>Back to Sync Records</span>
                </Box>
            </Box>
            <Box className={commonClasses.bgWhiteMain} >
                <SyncRecordTypeComponent typeName={typeName} practiceId={practiceId} />
            </Box>
        </AuthenticatedLayoutComponent>
    )
}

