import {useEffect, useState} from "react";
import {ConversationModelBase, ConversationType} from "../../models/conversation.models";
import {Subscription} from "recompose";
import {onEmit} from "../../../common/helpers/on-emit";
import {employeeConversationsQuery} from "../../stores/employeeConversationsStore/employeeConversations.query";

interface PlaygroundChatListComponentState {
    isLoading: boolean;
    conversations: ConversationModelBase[];
}

export function useFacade(type: ConversationType, patientId?: number): [PlaygroundChatListComponentState] {
    const [state, setState] = useState({
        isLoading: true,
        conversations: []
    } as PlaygroundChatListComponentState);

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<ConversationModelBase[]>(employeeConversationsQuery.conversations$, conversations =>
                {
                    const filteredConversations = conversations
                        .filter(x => x.type === type)
                        .filter(x => !patientId || x.patients.map(t => t.patientId).includes(patientId));

                    setState(state => ({
                        ...state,
                        isLoading: !employeeConversationsQuery.isReady(),
                        conversations: filteredConversations
                    }))
                }
            )
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [type, patientId]);

    return [
        state
    ];
}