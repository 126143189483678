import React, { useState } from "react";
import Recaptcha from 'react-google-invisible-recaptcha';
import { SITE_KEY } from "../../../../config";
import { IErrorState } from "../../../common/validation/error-state";
import { SendProfileVerificationModel } from "../../models/auth.models";
import { authService } from "../../services/auth.service";
import { sendProfileVerificationComponentValidator } from "./sendProfileVerificationComponent.validator";
import { authQuery } from "../../stores/auth";

interface SendProfileVerificationComponentState extends IErrorState {
    model: SendProfileVerificationModel;
    inProgress: boolean;
}

export function useFacade(handleVerificationSent: () => void): [
    SendProfileVerificationComponentState,
    () => JSX.Element,
    (field: string, value: string) => void,
    (event: React.MouseEvent<HTMLButtonElement>) => void] {

    const _siteKey = SITE_KEY;
    let _recaptcha = undefined;

    const [state, setState] = useState({
        model: {
            newPhoneNumber: authQuery.getPhoneNumber()
        },
        inProgress: false,
        errors: {}
    } as SendProfileVerificationComponentState);

    const handleChanges = (field: string, value: string) => {
        state.model[field] = value;
        setState({
            ...state,
            model: { ...state.model }
        });

        sendProfileVerificationComponentValidator.validateAndSetState(state, setState, field, value);
    }

    const renderRecaptcha = () => {
        return (
            <Recaptcha
                ref={ref => _recaptcha = ref}
                sitekey={_siteKey}
                onResolved={(token) => {
                    state.model.antiSpamToken = token;
                    const cb = () => setState(state => ({ ...state, inProgress: false }));
                    authService.sendVerification(state.model).subscribe(() => {
                        cb();
                        handleVerificationSent();
                    }, cb);
                }}
            />
        )
    }

    const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        sendProfileVerificationComponentValidator.validateObjectAndSetState(state, setState, state.model);
        if (!sendProfileVerificationComponentValidator.stateIsValid(state)) {
            return;
        }
        setState(state => ({ ...state, inProgress: true }));

        _recaptcha.execute();
    }

    return [
        state,
        renderRecaptcha,
        handleChanges,
        handleSubmit,
    ];
}