import { createStyles, makeStyles, Theme } from "@material-ui/core";

const drawerWidth = 300;
const drawerCloseWidth = 92;

type Props = {
    topBannerHeight: number;
};

export const useStyles = makeStyles<Theme, Props>((theme) =>
    createStyles({
        root: {
            height: 65,
            backgroundColor: '#F9F9FB',
            color: '#000000',
            zIndex: theme.zIndex.drawer - 1,
            top: ({ topBannerHeight }) => topBannerHeight
        },
        drawerOpen: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            marginLeft: drawerCloseWidth,
            width: `calc(100% - ${drawerCloseWidth}px)`,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        title: {
            fontFamily: 'Poppins',
            fontWeight: 600,
            fontSize: 24,
            color: '#091D36',
        },
        grow: {
            flexGrow: 1,
        },
        logo: {
            height: 50,
        },
        toolbar: {
            paddingLeft: 28,
        },
    }),
);