import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Subscription } from "recompose";
import { confirmService } from "../../../../services/confirm.service";
import { navigationService } from "../../../../services/navigation.service";
import { authQuery } from "../../../auth/stores/auth";
import { DataSpecificationsEnum } from "../../../common/constants/data-specifications";
import { onEmit } from "../../../common/helpers/on-emit";
import { snackService } from "../../../common/snack/state";
import { PatientModel } from "../../../patients/models/patient.model";
import { patientsService } from "../../../patients/services/patients.service";
import { patientsQuery } from "../../../patients/stores/patientsStore";
import { ConversationAuthorModel, EmployeeConversationModel, EmployeeConversationParticipantModel } from "../../models/conversation.models";
import { conversationsService } from "../../services/conversations.service";
import { employeeConversationsQuery } from "../../stores/employeeConversationsStore/employeeConversations.query";
import { EmployeeShortModel } from "../../../employee/models/employee.models";
import { employeesQuery } from "../../../employee/stores/employeesStore";

interface InternalChatComponentState {
    author: ConversationAuthorModel;
    patient: PatientModel;
    isAddUserOpen: boolean;
    isSideAreaOpen: boolean;
    employees: EmployeeShortModel[];
}

export function useFacade(targetConversation?: EmployeeConversationModel): [InternalChatComponentState, EmployeeConversationParticipantModel, EmployeeConversationParticipantModel, (id: number) => void, () => void, (id: number) => void, () => void, (open: boolean) => void] {
    const [state, setState] = useState({
        author: null,
        patient: null,
        targetConversation: null,
        isAddUserOpen: false,
        isSideAreaOpen: false,
        employees: []
    } as InternalChatComponentState);

    const signedBy = targetConversation ? targetConversation.employees.find(i => i.isSigned && !i.isDeleted) : null;
    const me = targetConversation ? targetConversation.employees.find(i => i.email === authQuery.getEmail() && !i.isDeleted) : null;

    const history = useHistory();

    const handleNavigateToPatient = (id: number) => {
        navigationService.toNewTabManagePatientProfile(id);
    }

    const handleToggleAddUser = () => {
        setState(state => ({...state, isAddUserOpen: !state.isAddUserOpen}))
    }

    const handleAddUser = (id: number) => {
        const cb = () => setState(state => ({...state, isAddUserOpen: false}));
        conversationsService.addEmployeeToConversationModel({conversationId: targetConversation.id, employeeId: id}).subscribe(cb, cb);
    }

    const handleSignOff = () => {
        const sign = () => conversationsService.signOffConversation(targetConversation.id).subscribe(
            () => snackService.success(`The conversation has been ${signedBy && !me?.isSigned ? 'acknowledged' : 'signed off'}!`),
            () => snackService.error('Sign Off failed!')
        );

        const actionName = signedBy && !me?.isSigned ? 'Acknowledge' : 'Sign Off';
        confirmService.confirm(actionName, `Are You Sure You Want to ${actionName} this Conversation?`, actionName).subscribe(sign)
    }

    const handleToggleSideArea = (open: boolean) => {
        setState({ ...state, isSideAreaOpen: open });
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<PatientModel>(patientsQuery.targetPatient$, targetPatient => {
                if (targetPatient) {
                    setState(state => ({...state, patient: targetPatient}))
                }
            }),
            onEmit<ConversationAuthorModel>(employeeConversationsQuery.author$, author =>
                setState(state => ({...state, author: author}))
            ),
            onEmit<EmployeeShortModel[]>(employeesQuery.employees$, employees =>
                setState(state => ({...state, employees}))
            ),
        ];

        if (targetConversation && targetConversation.patients?.length) {
            patientsService.get(targetConversation.patients[0].patientId, DataSpecificationsEnum.PatientUserSpecification);
        }

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [targetConversation]);

    return [state, signedBy, me, handleNavigateToPatient, handleToggleAddUser, handleAddUser, handleSignOff, handleToggleSideArea];
}
