import { Box, FormControl, FormControlLabel, Select} from "@material-ui/core";
import React from "react";
import { Practice } from "../../../../../auth/models/auth.enums";
import { OptionalComponent } from "../../../../../common/components/OptionalComponent";
import { PaymentPlanDisplayComponent } from "../../../../../common/components/paymentPlanDisplayComponent/PaymentPlanDisplayComponent";
import { WildHealthButton } from "../../../../../common/components/wildHealthButton/WildHealthButton";
import WildHealthLinearProgress from "../../../../../common/components/WildHealthLinearProgress";
import { WildHealthSwitcher } from "../../../../../common/components/WIldHealthSwitcher";
import { TransferPatientToLocationComponent } from "../../../../../locations/components/transferPatientToLocationComponent/TransferPatientToLocationComponent";
import { useFacade } from "./membershipInfoWidgetComponent.hooks";
import { useStyles } from "./membershipInfoWidgetComponent.styles";
import {
    SmsReminderToggleComponent
} from "../../../../../conversations/components/smsReminderToggleComponent/SmsReminderToggleComponent";
import VideoCamOffIcon from '@material-ui/icons/VideocamOffOutlined';
import Typography from '@material-ui/core/Typography';

export interface MembershipInfoWidgetComponentProps {
  patientId: number | null;
}

export const MembershipInfoWidgetComponent: React.FC<MembershipInfoWidgetComponentProps> = (
  props: MembershipInfoWidgetComponentProps
) => {

  const [
    {
      isLoading,
      patient,
      isChanged
    },
    handleIsFellowChange,
    handleSubmit
  ] = useFacade(props.patientId);

  const classes = useStyles();

  const renderContent = () => {
    if (isLoading) {
      return <WildHealthLinearProgress />;
    }

    return (<Box>
              <Box mt={2}>
                  <TransferPatientToLocationComponent patient={patient} classStyle={classes.textGray}/>
              </Box>
              <Box mt={2}>
                  <PaymentPlanDisplayComponent patient={patient} classStyle={classes.textGray}/>
              </Box>
              <Box mt={3}>
                <Box>
                  <Box display="flex"  alignItems="center">
                    <Box width={0.4} className={classes.textGray}>
                      Lead source:
                    </Box>
                    {
                      patient?.leadSource?.otherLeadSource ?? '-'
                    }       
                    </Box>
                  </Box>
                  <Box mt={2}>
                    <OptionalComponent practiceId={Practice.WildHealth}>
                      <Box>
                        <Box display="flex" alignItems='center'>
                        <Box width={0.4} className={classes.textGray} >
                          Fellowship Patient:
                        </Box>

                        <Box display="flex" >
                            <Box p={1} pl={0}>
                                <span>
                                    Off
                                </span>
                            </Box>
                            <Box ml={3}>
                                <FormControlLabel
                                    control={<WildHealthSwitcher checked={patient?.options?.isFellow} onChange={() => handleIsFellowChange(!patient?.options?.isFellow)} />}
                                    label=""
                                />
                            </Box>
                            <Box p={1}>
                                <span>
                                    On
                                </span>
                            </Box>
                            {
                              isChanged &&
                              <Box ml={2}> 
                                <WildHealthButton id="fellowship" onClick={()=>handleSubmit()}>Save</WildHealthButton>
                              </Box>
                            }
                        </Box>
                    </Box>
                      </Box>
                    </OptionalComponent>
                  </Box>
                  <Box mt={2}>
                      <SmsReminderToggleComponent patient={patient}/>
                  </Box>
                  {
                    !(patient.userOptions?.meetingRecordingConsent ?? false) &&
                    <Box className={classes.recordingConsentSection}>
                        <VideoCamOffIcon className={classes.recordingConsentIcon}></VideoCamOffIcon>
                        <Typography className={classes.recordingConsentText}>
                            Appointment recording has been disabled by the patient
                        </Typography>
                    </Box> 
                  }
            </Box>
    </Box>);
  }

  return (
    <Box>
      <Box className={classes.title}>
        Membership Info
      </Box>
      <Box>
        {renderContent()}
      </Box>
    </Box >
  );
};
