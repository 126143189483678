import React, { useEffect, useState } from 'react';
import { Box, IconButton } from '@material-ui/core';
import { AnswerComponent } from "../answerComponent/AnswerComponent";
import {
    AnswerModel,
    QuestionnaireModel,
    QuestionnaireResultModel,
    QuestionnaireType,
    SaveQuestionnaireResultModel
} from "../../models/questionnaire.models";
import commonUseStyles from '../../../common/styles/common.styles';
import EditIcon from '@material-ui/icons/Edit';
import { colors } from "../../../common/constants/colors";
import { QuestionComponent } from "../questionComponent/QuestionComponent";
import { IErrorState } from "../../../common/validation/error-state";
import { questionnaireService } from "../../services/questionnaire.service";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { questionnairesResultListComponentValidator } from './questionnairesResultListComponent.validator';
import { goToError } from "../../../common/helpers/go-to-error";
import { SupplementsAnswerComponent } from '../answerComponent/customAnswers/SupplementsAnswerComponent';
import { MedicationsAnswerComponent } from '../answerComponent/customAnswers/MedicationsAnswerComponent';
import { AllergiesAnswerComponent } from '../answerComponent/customAnswers/AllergiesAnswerComponent';
import { MedicalHistoryAndLifestyleQuestionNames } from '../../static/medicalHistroryQuestionnaire.static';
import { hasAnswer } from '../../helpers/has-answer';
import { getSpecificQuestions } from '../../helpers/specific-questions-provider';
import { isGenderMatch } from "../../helpers/is-gender-match";
import { Gender } from "../../../common/models/user.models";
import {useFlags} from "launchdarkly-react-client-sdk";
import {isFeatureFlag} from "../../../common/components/featureFlags/featureFlags";
import {FeatureFlag} from "../../../common/components/featureFlags/featureFlags.models";

interface QuestionnairesResultListComponentProps {
    result: QuestionnaireResultModel;
    questionnaire: QuestionnaireModel;
    questions: string[];
    title?: string;
    patientId?: number | null;
    gender: Gender;
}

interface QuestionnairesResultListComponentState extends IErrorState {
    result: QuestionnaireResultModel;
    questionnaire: QuestionnaireModel;
    edit: boolean;
    answers: AnswerModel[];
    draftAnswers: AnswerModel[];
    saving: boolean;
}

const getTargetAnswers = (questions: string[], result: QuestionnaireResultModel): AnswerModel[] =>
    result.answers
        .filter(x => questions.find(q => q === x.key) !== undefined)
        .map(x => ({ key: x.key, value: x.value } as AnswerModel));

export const QuestionnairesResultListComponent: React.FC<QuestionnairesResultListComponentProps> = (props: QuestionnairesResultListComponentProps) => {
    const {
        result,
        title,
        questions,
        questionnaire,
        patientId,
        gender
    } = props;
    const commonClasses = commonUseStyles();

    const featureFlags = useFlags();
    const rxntIntegration = isFeatureFlag(featureFlags, FeatureFlag.RxntIntegration);
    const [state, setState] = useState({
        questionnaire: questionnaire,
        answers: [],
        draftAnswers: [],
        edit: false,
        saving: false,
        errors: {}
    } as QuestionnairesResultListComponentState);

    const cloneAnswers = (answers: AnswerModel[]): AnswerModel[] => answers.map(x => ({ ...x }));

    useEffect(() => {
        const targetAnswers = getTargetAnswers(questions, result);
        setState((state) => ({ ...state, answers: targetAnswers, draftAnswers: cloneAnswers(targetAnswers) }))
    }, [])

    const validate = () => {
        questions.forEach(field => {
            const value = state.draftAnswers.find(x => x.key === field)?.value ?? '';
            questionnairesResultListComponentValidator.validateAndSetState(state, setState, field, value);
        });
    }

    const handleChanges = (field: string, value: string) => {
        if(value === ""){
            value = undefined;
        }

        questionnairesResultListComponentValidator.validateAndSetState(state, setState, field, value);

        let resultTest = state.draftAnswers;
        const answer = resultTest.find(x => x.key === field);
        if (answer) {
            answer.value = value;
        } else {
            resultTest.push({
                key: field,
                value: value
            });
        }

        resultTest = [...resultTest];
        setState({ ...state, draftAnswers: resultTest });
    }

    const handleEdit = () => {
        setState({ ...state, edit: true });
    }
    const handleCancel = () => {
        setState({ ...state, edit: false, draftAnswers: cloneAnswers(state.answers) });
    }
    const handleSubmit = () => {
        validate();

        if (!questionnairesResultListComponentValidator.stateIsValid(state)) {
            goToError(state);
            return;
        }

        setState((state) => ({ ...state, saving: true }));

        if (patientId) {
            questionnaireService.saveAsEmployee(getSaveModel(result)).subscribe(() => {
                setState({ ...state, edit: false, answers: cloneAnswers(state.draftAnswers), saving: false });
            },
                () => setState((state) => ({ ...state, saving: false })));
        } else {
            questionnaireService.save(getSaveModel(result)).subscribe(() => {
                setState({ ...state, edit: false, answers: cloneAnswers(state.draftAnswers), saving: false });
            },
                () => setState((state) => ({ ...state, saving: false })));
        }
    }

    const getQuestionsRequirements = (answers: AnswerModel[]): { [id: string]: () => boolean } => {
        const parentQuestions = questionnaire.questions.filter(x => x.childrenNames.length);
        const childQuestions = questionnaire.questions.filter(x => x.parentAnswers.length);

        return Object.assign({}, ...childQuestions.map((x) => ({
            [x.name]: () => {
                const parentQuestion = parentQuestions.find(pq => pq.childrenNames.includes(x.name));
                return x.parentAnswers.some(pc => hasAnswer(answers, parentQuestion.name, pc))
            }
        })));
    }

    const draftQuestionsRequirements = getQuestionsRequirements(state.draftAnswers);
    const questionsRequirements = getQuestionsRequirements(state.answers);

    const requiredQuestions = state.questionnaire.questions.filter(x => questions.some(q => q === x.name) && !x.optional);

    const IsSectionValid = requiredQuestions.every(x => hasAnswer(state.draftAnswers, x.name) || (questionsRequirements[x.name] !== undefined && questionsRequirements[x.name]() === false))
        && questionnairesResultListComponentValidator.stateIsValid(state)

    const getSaveModel = (result: QuestionnaireResultModel): SaveQuestionnaireResultModel => {
        return {
            answers: state.draftAnswers,
            questionnaireResultId: result.id,
            patientId: patientId,
        } as SaveQuestionnaireResultModel;
    }

    const renderAnswer = (question: string, key: number): JSX.Element => {
        const requirement = questionsRequirements[question];

        if (requirement === undefined || requirement() === true) {

            switch (question) {
                case MedicalHistoryAndLifestyleQuestionNames.SUPPLEMENTS: return <SupplementsAnswerComponent
                    key={`question-${key}`}
                    patientId={patientId}
                    question={questionnaire.questions.find(x => x.name === question).displayName}
                />
                case MedicalHistoryAndLifestyleQuestionNames.MEDICATIONS: return <MedicationsAnswerComponent
                    key={`question-${key}`}
                    patientId={patientId}
                    question={questionnaire.questions.find(x => x.name === question).displayName}
                />
                case MedicalHistoryAndLifestyleQuestionNames.ALLERGIES: {
                    if (rxntIntegration) {
                        return <React.Fragment key={`empty-question-${key}`} />;
                    }
                    return <AllergiesAnswerComponent
                        key={`question-${key}`}
                        patientId={patientId}
                        answer={state.answers.find(x => x.key === question)?.value ?? ''}
                        question={questionnaire.questions.find(x => x.name === question).displayName}
                    />
                }
                default: return <AnswerComponent
                    key={`question-${key}`}
                    questionnaire={questionnaire}
                    result={result}
                    answer={state.answers.find(x => x.key === question)?.value ?? ''}
                    question={questionnaire.questions.find(x => x.name === question)}
                />
            }
        } else {
            return <React.Fragment key={`empty-question-${key}`} />;
        }
    }

    const questionComponents = getSpecificQuestions(handleChanges, state.questionnaire, state.result, state.draftAnswers, state.errors, patientId);

    const renderQuestion = (question: string): JSX.Element => {
        const parentQuestion = questionnaire.questions.find(x => x.childrenNames.includes(question));
        const requirement = draftQuestionsRequirements[question];
        const parentRequirement = draftQuestionsRequirements[parentQuestion?.name];

        if ((requirement === undefined || requirement() === true) && (parentRequirement === undefined || parentRequirement() === true)) {
            if (Object.keys(questionComponents).includes(question)) {
                return questionComponents[question];
            }

            return <QuestionComponent
                inline={false}
                error={state.errors[question]}
                answer={state.draftAnswers.find(x => x.key === question)?.value || null}
                handleChanges={(value) => handleChanges(question, value)}
                question={questionnaire.questions.find(x => x.name === question)}
            />
        } else {
            return (<></>)
        }
    }

    const renderQuestions = () => {
        return questions.map((question, index) => {
            const originQuestion = questionnaire.questions.find(x => x.name === question);

            if (!isGenderMatch(originQuestion, gender)) {
                return <React.Fragment key={`empty-gender-${index}`} />;
            }

            return (
                <Box key={index}>
                    {state.edit
                        ? <>
                            {
                                renderQuestion(question)
                            }
                        </>
                        : <>
                            {
                                renderAnswer(question, index)
                            }
                        </>
                    }
                </Box>
            )
        })
    }

    return (
        <Box display='flex' justifyContent="space-between">
            <Box width={1}>
                {
                    title &&
                    <Box className={commonClasses.secondaryTitle}>
                        {title}
                    </Box>
                }
                {
                    renderQuestions()
                }
                {
                    state.edit &&
                    <Box display='flex' mt={2.5} justifyContent='flex-end'>
                        <WildHealthButton
                            id="questionnaire-result-list-cancel"
                            onClick={() => { handleCancel() }}
                            color="tertiary"
                            size='large'
                            disabled={state.saving}
                        >
                            Cancel
                        </WildHealthButton>
                        <Box pl={0.5}>
                            <WildHealthButton
                                id="questionnaire-result-list-save"
                                width={95}
                                disabled={!IsSectionValid}
                                onClick={() => { handleSubmit() }}
                                autoFocus
                                size='large'
                                loading={state.saving}
                            >
                                Save
                            </WildHealthButton>
                        </Box>
                    </Box>
                }
            </Box>
            {
                !state.edit && state.questionnaire.type === QuestionnaireType.HealthForms &&
                <Box>
                    <IconButton id="questionnaire-result-list-edit" data-testid="questionnaireResultListEditBtn" onClick={() => handleEdit()}>
                        <EditIcon
                            style={{ color: colors.main, fontSize: 24 }}
                        />
                    </IconButton>
                </Box>
            }
        </Box>
    )
}
