import { useEffect, useState } from "react";
import { onEmit } from "../../../common/helpers/on-emit";
import { HealthReportModel, HealthReportRecommendationDefaultModel, RecommendationDisplayModel, RecommendationTypes } from "../../models/healthReport.models";
import { healthReportsQuery } from "../../stores/healthReportsStore/healthReports.query";
import { healthReportsStore } from "../../stores/healthReportsStore/healthReports.store";
import { Subscription } from "rxjs/internal/Subscription";
import moment from "moment";


interface RecommendationsComponentState {
    sections: { [id: number]: boolean };
    sectionsIsChanged: { [id: number]: boolean };
    isChanged: boolean;
    recommendations: RecommendationDisplayModel[],
    recommendationsPrevious: RecommendationDisplayModel[],
    isSelectedAllNew: boolean,
    isSelectedAllPrev: boolean,
}

export function useFacade(
    patientId: number,
    isPrepereRecommendation: boolean,
): [
        RecommendationsComponentState,
        (section: RecommendationTypes) => void,
        (id: number | string, isSelected: boolean) => void,
        (id: number | string, isSelected: boolean) => void,
        (id: number | string, value: string) => void,
        (type: number) => RecommendationDisplayModel[],
        (type: number) => RecommendationDisplayModel[],
        (type: number, index: number) => void,
        () => void,
        () => void,
        (recommendation: RecommendationDisplayModel) => void,
    ] {
    const [state, setState] = useState(
        {
            sections: {
                [RecommendationTypes.Macronutrient]: false,
                [RecommendationTypes.Methylation]: false,
                [RecommendationTypes.VitaminsAndMicronutrients]: false,
                [RecommendationTypes.KryptoniteFoods]: false,
                [RecommendationTypes.SuperFoods]: false,
                [RecommendationTypes.ExerciseAndRecovery]: false,
                [RecommendationTypes.Longevity]: false,
                [RecommendationTypes.Microbiome]: false,
                [RecommendationTypes.Neurobehavioral]: false,
                [RecommendationTypes.Sleep]: false,
                [RecommendationTypes.Cardiovascular]: false,
                [RecommendationTypes.Dementia]: false,
                [RecommendationTypes.InsulinResistance]: false,
                [RecommendationTypes.Inflammation]: false,
                [RecommendationTypes.Supplements]: false,
            },
            sectionsIsChanged: {
                [RecommendationTypes.Macronutrient]: false,
                [RecommendationTypes.Methylation]: false,
                [RecommendationTypes.VitaminsAndMicronutrients]: false,
                [RecommendationTypes.KryptoniteFoods]: false,
                [RecommendationTypes.SuperFoods]: false,
                [RecommendationTypes.ExerciseAndRecovery]: false,
                [RecommendationTypes.Longevity]: false,
                [RecommendationTypes.Microbiome]: false,
                [RecommendationTypes.Neurobehavioral]: false,
                [RecommendationTypes.Sleep]: false,
                [RecommendationTypes.Cardiovascular]: false,
                [RecommendationTypes.Dementia]: false,
                [RecommendationTypes.InsulinResistance]: false,
                [RecommendationTypes.Inflammation]: false,
                [RecommendationTypes.Supplements]: false,
            },
            isChanged: false,
            recommendations: [],
            recommendationsPrevious: [],
            isSelectedAllNew: false,
            isSelectedAllPrev: true,
        } as RecommendationsComponentState
    );

    const handleExpandSection = (section: RecommendationTypes) => {
        const sections = state.sections;
        sections[section] = !sections[section];
        setState({ ...state, sections: sections });
    }

    const checkSelectedAll = () => {
        const selectedAllNew = !state.recommendations.find(el => el.isSelected === false)
        const selectedAllPrev = !state.recommendationsPrevious.find(el => el.isSelected === false)
        setState({ ...state, isSelectedAllNew: selectedAllNew, isSelectedAllPrev: selectedAllPrev });
    }

    const handleSelectedNew = (id: number | string, isSelected: boolean) => {
        const recommendationForIsSelected = state.recommendations.find(el => el.id === id)

        if(state.recommendationsPrevious.find(el => el.baseId === recommendationForIsSelected.baseId)) {
            const recommendationPreviousForIsSelected = state.recommendationsPrevious.find(el => el.baseId === recommendationForIsSelected.baseId)
            recommendationPreviousForIsSelected.isSelected = !isSelected
        }

        recommendationForIsSelected.isSelected = isSelected
        setState({ ...state })
        checkSelectedAll()
    }

    const handleSelectedPrew = (id: number | string, isSelected: boolean) => {
        const recommendationPreviousForIsSelected = state.recommendationsPrevious.find(el => el.id === id)
        if(state.recommendations.find(el => el.baseId === recommendationPreviousForIsSelected.baseId)) {
            const recommendationForIsSelected = state.recommendations.find(el => el.baseId === recommendationPreviousForIsSelected.baseId)
            recommendationForIsSelected.isSelected = !isSelected
        }
        recommendationPreviousForIsSelected.isSelected = isSelected
        setState({ ...state })
        checkSelectedAll()
    }

    const handleChangesPrev = (id: number | string, value: string) => {
        const recommendationForIsSelected = state.recommendationsPrevious.find(el => el.id === id)
        recommendationForIsSelected.content = value
        setState({ ...state })
    }

    const hendleUpdateRecommendation = (recommendation: RecommendationDisplayModel) => {
        const recommendationForUpdated = state.recommendationsPrevious.find(el => el.baseId === recommendation.baseId)
        if (recommendationForUpdated) {
            recommendationForUpdated.content = recommendation.content
            setState({ ...state })
        } else {
            const updatedRecommendation = [{...recommendation, isSelected: true}, ...state.recommendationsPrevious ]
            setState({ ...state, recommendationsPrevious:updatedRecommendation })
        }
    }

    const getRecommendationNew = (type: number): RecommendationDisplayModel[] => {
        return state.recommendations.filter(el => el.type === type)
    }

    const getRecommendationCurrent = (type: number): RecommendationDisplayModel[] => {
        return state.recommendationsPrevious.filter(el => el.type === type)
    }

    const handleAddRecommendation = (type: number, index: number) => {
        const newRecommendation = {
            recommendationId: `xxx${index}`,
            id: moment().unix() + index,
            baseId: moment().unix() + index,
            type: type,
            content: '',
            isSelected: true,
        };
        setState({
            ...state,
            recommendationsPrevious: [
                ...state.recommendationsPrevious,
                newRecommendation,
            ]
        })
    }

    const prepereRecommendationToSend = () => {
        const selectedRecommendations = state.recommendationsPrevious.filter(el => el.isSelected)
        const selectedRecommendationsPrevious = state.recommendations.filter(el => el.isSelected)

        const recommendationsToSend = [...selectedRecommendations, ...selectedRecommendationsPrevious].map(el => {
            return `${el.recommendationId}`.includes('xxx')
                ? {
                    type: el.type,
                    baseId: el.baseId,
                    content: el.content,
                }
                : {
                    recommendationId: el.recommendationId,
                    baseId: el.baseId,
                    type: el.type,
                    content: el.content,
                }
        })
        healthReportsStore.putRecommendationToSend(recommendationsToSend)
    }

    const handleSelectedAllNew = () => {
        if (!state.isSelectedAllNew) {
            state.recommendations.forEach(el => el.isSelected = !state.isSelectedAllNew)
            state.recommendationsPrevious.forEach(el => el.isSelected = state.isSelectedAllNew)
            setState({ ...state, isSelectedAllNew: true, isSelectedAllPrev: false})
        } else {
            setState({ ...state, isSelectedAllPrev: false, isSelectedAllNew: false })
        }
    }

    const handleSelectedAllPrevious = () => {
        if (!state.isSelectedAllPrev) {
            state.recommendationsPrevious.forEach(el => el.isSelected = !state.isSelectedAllPrev)
            state.recommendations.forEach(el => el.isSelected = state.isSelectedAllPrev)
            setState({ ...state, isSelectedAllPrev: true, isSelectedAllNew: false })
        } else {
            setState({ ...state, isSelectedAllPrev: false, isSelectedAllNew: false })
        }
    }

    useEffect(() => {
        prepereRecommendationToSend()
    }, [isPrepereRecommendation]);


    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<HealthReportModel>(healthReportsQuery.reportForRecommendation$, report => { 
                setState(state => ({
                    ...state,
                    recommendationsPrevious: report.recommendations.map(el => {
                        return {
                            recommendationId: el.recommendationId,
                            baseId: el.baseId,
                            id: el.id,
                            type: el.type,
                            content: el.content,
                            isSelected: true,
                        }
                    }),
                }));
            }),
            onEmit<HealthReportRecommendationDefaultModel[]>(healthReportsQuery.recommendationsDefault$, recommendationsDefault => {       
                setState(state => ({
                    ...state,
                    recommendations: recommendationsDefault.map(el => {
                        return {
                            recommendationId: el.recommendationId,
                            baseId: +el.recommendationId,
                            id: el.id,
                            type: el.recommendationType,
                            content: el.recommendation.content,
                            isSelected: false,
                        }
                    })
                }));
            }),
        ]
        
        return () => {
            subscriptions.map(i => i.unsubscribe())
        };
    }, [patientId]);

    return [
        state,
        handleExpandSection,
        handleSelectedNew,
        handleSelectedPrew,
        handleChangesPrev,
        getRecommendationNew,
        getRecommendationCurrent,
        handleAddRecommendation,
        handleSelectedAllNew,
        handleSelectedAllPrevious, 
        hendleUpdateRecommendation,
    ];
}
