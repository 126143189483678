import React from 'react';
import clsx from 'clsx';
import { Box, FormControl, IconButton, MenuItem, Select, InputLabel } from '@material-ui/core';
import { useStyles } from "./SelectFellowShipRoleComponent.styles";
import { useFacade } from "./selectFellowshipRoleComponent.hooks";
import { FellowRoleSubmissionType, FellowRoleType } from '../../models/fellowship.enums';
import { CreatePracticumPatientForm } from "../createPracticumPatientForm/CreatePracticumPatientForm";
import { Add } from '@material-ui/icons';
import { navigationService } from '../../../../services/navigation.service';
import { FellowshipRegistrationNextButton } from "../fellowshipRegistrationButton/FellowshipRegistrationNextButton";
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import commonUseStyles from '../../../common/styles/common.styles';
import { colors } from '../../../common/constants/colors';
import { fullName } from '../../../common/helpers/display-name';
import { rostersQuery } from '../../../rosters/stores/rosters';
import { NoPmRoster } from '../../../rosters/models/roster.model';

interface SelectFellowshipRoleComponentProps {
    goNext: () => void
}

export const SelectFellowshipRoleComponent: React.FC<SelectFellowshipRoleComponentProps> = (props: SelectFellowshipRoleComponentProps) => {
    const {
        goNext
    } = props;

    const [
        {
            isLoading,
            selectedRole,
            selectedFellowRoleSubmission,
            fellows,
            quarterFellows,
            selectedPatientFellowId,
            selectedRoster,
            selectedLinkingFellowId,
            practicumPatients,
            errors
        },
        handleChangeRole,
        handleChangeFellowSubmission,
        handleFellowSelect,
        handleLinkFellow,
        handleCreatePracticumPatient,
        handleDeletePracticumPatient,
        handleUndoDeletePracticumPatient,
        handlePracticumPatientChanges,
        handleSubmitPracticumPatients,
        isSubmitPracticumPatientsMode,
        handleQuarterSelect,
        canGoNext
    ] = useFacade()

    const classes = useStyles();
    const commonClasses = commonUseStyles();


    const rosters = rostersQuery.getRosters().filter(el => (el.id === NoPmRoster.January21 || el.id === NoPmRoster.July21 || el.id === NoPmRoster.January22) );

    const renderFellowPatientConetnt = (): JSX.Element => {
        return (
            <Box mt={6}>
                <Box>
                    <Box>
                        <span className={clsx(commonClasses.size20, commonClasses.textMedium)}>Please select the fellowship class of your fellow.</span>
                    </Box>
                    <Box mt={3}>
                        <Box className={commonClasses.inputLabel} mb={1}>Select Class</Box>
                        <FormControl
                            color='primary'
                            variant="outlined"
                            size="small"
                            fullWidth
                            classes={{ root: classes.root }}
                        >
                            <Select
                                classes={{ root: classes.root }}
                                value={selectedRoster?.name ?? null}
                                inputProps={{
                                    classes,
                                }}
                                renderValue={() => {
                                    if (selectedRoster === null) {
                                        return <Box className={commonClasses.colorGray1}>Please Class</Box>;
                                    }

                                    return selectedRoster?.name;
                                }}
                                displayEmpty
                                onChange={(event) => handleQuarterSelect(event.target.value as number)}
                            >
                                {
                                    rosters.map((roster) =>
                                        <MenuItem key={roster.id} value={roster.id}>
                                            {roster.name}
                                        </MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                <Box mt={5}>
                    <Box>
                        <span className={clsx(commonClasses.size20, commonClasses.textMedium)}>Please select the fellow you will be working with in conjunction.</span>
                    </Box>
                    <Box mt={4}>
                        <Box className={commonClasses.inputLabel} mb={1}>Fellow</Box>
                        <FormControl
                            variant="outlined"
                            color='primary'
                            size="small"
                            classes={{ root: classes.root }}
                            fullWidth
                        >
                            <Select
                                classes={{ root: classes.root }}
                                value={selectedPatientFellowId}
                                inputProps={{
                                    classes,
                                }}
                                renderValue={() => {
                                    if (selectedPatientFellowId === null) {
                                        return <Box className={commonClasses.colorGray1}>Please Select</Box>;
                                    }

                                    return fullName(quarterFellows.find((f) => f.id === selectedPatientFellowId));
                                }}
                                displayEmpty
                                onChange={(event) => handleFellowSelect(event.target.value as number)}
                                disabled={!quarterFellows.length}
                            >
                                {
                                    quarterFellows.map((fellow, index) =>
                                        <MenuItem key={index} value={fellow.id}>
                                            {fullName(fellow)}
                                        </MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
            </Box>
        )
    }

    const renderFellowRegisterAsPatientConetnt = (): JSX.Element => {
        return (
            <Box mt={5}>
                <Box>
                    <Box>
                        <span className={clsx(commonClasses.size20, commonClasses.textMedium)}>Please select your fellowship class.</span>
                    </Box>
                    <Box mt={3}>
                        <Box className={commonClasses.inputLabel} mb={1}>Select Class</Box>
                        <FormControl
                            color='primary'
                            variant="outlined"
                            size="small"
                            fullWidth
                            classes={{ root: classes.root }}
                        >
                            <Select
                                classes={{ root: classes.root }}
                                value={selectedRoster?.name ?? null}
                                inputProps={{
                                    classes,
                                }}
                                renderValue={() => {
                                    if (selectedRoster === null) {
                                        return <Box className={commonClasses.colorGray1}>Please Class</Box>;
                                    }

                                    return selectedRoster?.name;
                                }}
                                displayEmpty
                                onChange={(event) => handleQuarterSelect(event.target.value as number)}
                            >
                                {
                                    rosters.map((roster) =>
                                        <MenuItem key={roster.id} value={roster.id}>
                                            {roster.name}
                                        </MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                <Box mt={5}>
                    <Box>
                        <span className={clsx(commonClasses.size20, commonClasses.textMedium)}>Select your name from the roster.</span>
                    </Box>
                    <Box mt={4}>
                        <Box className={commonClasses.inputLabel} mb={1}>Select Fellow</Box>
                        <FormControl
                            variant="outlined"
                            color='primary'
                            size="small"
                            classes={{ root: classes.root }}
                            fullWidth
                        >
                            <Select
                                classes={{ root: classes.root }}
                                value={selectedLinkingFellowId}
                                inputProps={{
                                    classes,
                                }}
                                renderValue={() => {
                                    if (selectedLinkingFellowId === null) {
                                        return <Box className={commonClasses.colorGray1}>Please Select</Box>;
                                    }

                                    return fullName(quarterFellows.find((f) => f.id === selectedLinkingFellowId));
                                }}
                                displayEmpty
                                onChange={(event) => handleLinkFellow(event.target.value as number)}
                                disabled={!quarterFellows.length}
                            >
                                {
                                    quarterFellows.map((fellow, index) =>
                                        <MenuItem key={index} value={fellow.id}>
                                            {fullName(fellow)}
                                        </MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
            </Box>
        )
    }

    const renderPracticumPatients = (): JSX.Element => {
        return (
            <Box mt={6}>
                <Box>
                    <h2 className={clsx(commonClasses.size24, commonClasses.textSemiBold, commonClasses.colorBlack)}>Practicum Patient</h2>
                </Box>
                {
                    practicumPatients.filter(x => !x.deleted).map((patient, index) => (
                        <Box key={index} mt={5}>
                            <CreatePracticumPatientForm
                                practicumPatient={patient}
                                fellows={fellows}
                                handleChanges={handlePracticumPatientChanges}
                                handleDelete={handleDeletePracticumPatient}
                                errors={errors}
                            />
                        </Box>
                    ))
                }
                <Box mt={5} display="flex" justifyContent="space-between" alignItems="start">
                    <Box display="flex" justifyContent="flex-start" alignItems="center">
                        <Box>
                            <IconButton
                                id="select-fellowship-role-add-another"
                                onClick={() => handleCreatePracticumPatient()}
                            >
                                <Add
                                    style={{
                                        color: colors.main,
                                        backgroundColor: colors.gray3
                                    }}
                                />
                            </IconButton>
                        </Box>
                        <Box ml={1}>
                            <span className={clsx(commonClasses.colorMain, commonClasses.textMedium, commonClasses.size14)}>Add Another Patient</span>
                        </Box>
                    </Box>
                    <Box>
                        {
                            practicumPatients.filter(x => x.deleted).map((patient, index) => (
                                <Box key={index} className={classes.undoDeleteBox} display="flex" alignItems="center" mt={1} px={3}>
                                    <Box>
                                        <span>Patient {patient.number} Deleted</span>
                                    </Box>
                                    <Box
                                        ml={5}
                                        id="select-fellowship-role-undo"
                                        onClick={() => handleUndoDeletePracticumPatient(patient.number)}
                                    >
                                        <span className={classes.undoDelete}>Undo</span>
                                    </Box>
                                </Box>
                            ))
                        }
                    </Box>
                </Box>
            </Box>
        );
    }

    const renderFellowConetnt = (): JSX.Element => {
        return (
            <Box mt={6}>
                <Box>
                    <span className={clsx(commonClasses.size20, commonClasses.textMedium)}>Would you like to submit a practicum patient or register as a patient?</span>
                </Box>
                <Box mt={3} display="flex" justifyContent="space-between">
                    <Box
                        id="select-fellowship-role-submit"
                        className={
                            clsx(classes.selectCard, {
                                [classes.selectCardActive]: selectedFellowRoleSubmission === FellowRoleSubmissionType.SubmitPracticumPatient
                            })
                        }
                        onClick={() => handleChangeFellowSubmission(FellowRoleSubmissionType.SubmitPracticumPatient)}
                    >
                        <span>Submit a Practicum Patient</span>
                    </Box>
                    <Box
                        id="select-fellowship-role-register"
                        ml={2}
                        className={
                            clsx(classes.selectCard, {
                                [classes.selectCardActive]: selectedFellowRoleSubmission === FellowRoleSubmissionType.RegisterAsPatient
                            })
                        }
                        onClick={() => handleChangeFellowSubmission(FellowRoleSubmissionType.RegisterAsPatient)}
                    >
                        <span>Register as a Patient</span>
                    </Box>
                </Box>
                {
                    selectedRole === FellowRoleType.Fellow && selectedFellowRoleSubmission === FellowRoleSubmissionType.SubmitPracticumPatient && renderPracticumPatients()
                }
                {
                    selectedRole === FellowRoleType.Fellow && selectedFellowRoleSubmission === FellowRoleSubmissionType.RegisterAsPatient && renderFellowRegisterAsPatientConetnt()
                }
            </Box>
        )
    }

    const renderRoleContent = (): JSX.Element => {
        switch (selectedRole) {
            case FellowRoleType.Fellow:
                return renderFellowConetnt();
            case FellowRoleType.FellowPatient:
                return renderFellowPatientConetnt();
            case FellowRoleType.No:
                return (<></>);
            default:
                return (<></>);
        }
    }

    const renderContent = (): JSX.Element => {
        if (!fellows || !fellows.length) {
            return (
                <Box mt={5}>
                    <WildHealthLinearProgress />
                </Box>
            )
        }

        return (
            <>
                <Box mt={10}>
                    <h2 className={clsx(commonClasses.size20, commonClasses.textSemiBold, commonClasses.colorBlack)}>Select Role</h2>
                </Box>
                <Box mt={5}>
                    <Box>
                        <span className={clsx(commonClasses.size20, commonClasses.textMedium, commonClasses.colorBlack)}>Are you a fellow or will you be a patient of a fellow?</span>
                    </Box>
                    <Box mt={3} display="flex" justifyContent="space-between">
                        <Box
                            id="select-fellowship-role-fellow"
                            className={
                                clsx(classes.selectCard, {
                                    [classes.selectCardActive]: selectedRole === FellowRoleType.Fellow
                                })
                            }
                            onClick={() => handleChangeRole(FellowRoleType.Fellow)}
                        >
                            <span>Fellow</span>
                        </Box>
                        <Box
                            id="select-fellowship-role-patient"
                            mx={2}
                            className={
                                clsx(classes.selectCard, {
                                    [classes.selectCardActive]: selectedRole === FellowRoleType.FellowPatient
                                })
                            }
                            onClick={() => handleChangeRole(FellowRoleType.FellowPatient)}
                        >
                            <span>Patient of a Fellow</span>
                        </Box>
                        <Box
                            id="select-fellowship-role-no"
                            className={
                                clsx(classes.selectCard, {
                                    [classes.selectCardActive]: selectedRole === FellowRoleType.No
                                })
                            }
                            onClick={() => navigationService.toWildHealth()}
                        >
                            <span>No</span>
                        </Box>
                    </Box>
                </Box>
                {
                    renderRoleContent()
                }
                <Box mt={5}>
                    {
                        !isSubmitPracticumPatientsMode() &&
                        <FellowshipRegistrationNextButton
                            canGoNext={canGoNext()}
                            goNext={goNext}
                        />
                    }
                    {
                        isSubmitPracticumPatientsMode() &&
                        <FellowshipRegistrationNextButton
                            isLoading={isLoading}
                            canGoNext={canGoNext()}
                            goNext={handleSubmitPracticumPatients}
                            label={"Submit"}
                        />
                    }
                </Box>
            </>
        );
    }
    return (
        <Box px="20%">
            {
                renderContent()
            }
        </Box>
    );
}