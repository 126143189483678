import React from "react";
import { useStyles } from "./myTaskItemComponent.style";
import { Box, Button, createStyles, Dialog, DialogContent, IconButton, Theme, Typography, useMediaQuery, useTheme, withStyles, WithStyles } from "@material-ui/core";
import { myTaskFullImages } from "../constants/myTaskImages";
import { PatientTaskModel } from "../../../../patients/models/patient.model";
import NotificationsNoneRoundedIcon from '@material-ui/icons/NotificationsNoneRounded';
import { useFacade } from "./myTaskItemComponent.hook";
import { CreatePatientAppointmentFormComponent } from "../../../../appointments/components/createPatinentAppointmentForm/CreatePatientAppointmentFormComponent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import commonUseStyles from "../../../../common/styles/common.styles";
import { SchedulingInformationDialog } from "../SchedulingInformationDialog/schedulingInformationDialog";

export interface MyTaskItemComponentProps {
    task: PatientTaskModel;
    patientId?: number | null;
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;

    const commonClasses = commonUseStyles();

    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography className={clsx(commonClasses.size24, commonClasses.textMedium)}>
                {children}
            </Typography>
            {onClose ? (
                <IconButton
                    id="create-patient-appointment-close"
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export const MyTaskItemComponent: React.FC<MyTaskItemComponentProps> = (props: MyTaskItemComponentProps) => {
    const {
        patientId = null,
        task
    } = props;

    const [{
        assignedEmployees,
        appointmentWithType,
        appointmentTypes,
        locations,
        timeZone,
        toContinueDialog,
        toShowDialog,
        showDialogSchedulingInfo,
    },
        handleToggleDialog,
        handleContinueDialog,
        handleOpenSchedulingInfo,
    ] = useFacade(task, patientId);

    const classes = useStyles();
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <Box className={classes.imageContainer} style={{
            backgroundImage: `url(${myTaskFullImages.get(task.type)})`
        }}>
            <Box p={3} flexGrow={1}>
                <Box className={classes.label} >
                    <NotificationsNoneRoundedIcon style={{ height: 18 }} />
                    <Box>Reminder</Box>
                </Box>
                <Box mt={2} className={classes.title} >
                    {task.displayName}
                </Box>
                <Box mt={2} display="flex" alignItems="center" gridGap={12}>
                    <Button
                        id="my-tasks-schedule-appt"
                        className={classes.scheduleAppt}
                        onClick={handleToggleDialog}
                    >
                        Schedule Appointment
                    </Button>

                    <Button
                        id="my-tasks-why-schedule"
                        className={classes.openDialog}
                        onClick={() => {handleOpenSchedulingInfo(true, false)}}
                    >
                        Why Schedule?
                    </Button>
                </Box>
            </Box>
            <Dialog
                fullScreen={isSmallScreen}
                maxWidth="md"
                open={toShowDialog}
                className={classes.dialog}
                onClose={handleToggleDialog}>
                {
                    !isSmallScreen &&
                    <DialogTitle id="customized-dialog-title" onClose={handleToggleDialog}>
                        <span className={clsx(commonClasses.size24, commonClasses.textMedium, classes.dialogTitle)}>
                            {toContinueDialog ? "Appointment Confirmation" : "Schedule Appointment"}
                        </span>
                    </DialogTitle>
                }
                <DialogContent className={classes.dialogContent}>
                    <Box width={isSmallScreen ? 1 : "548px"}>
                        <CreatePatientAppointmentFormComponent
                            isContinueDialog={toContinueDialog}
                            handleContinueDialog={handleContinueDialog}
                            toggleDialog={handleToggleDialog}
                            assignedEmployees={assignedEmployees}
                            appointmentTypes={appointmentTypes}
                            locations={locations}
                            appointmentWithType={appointmentWithType}
                            handleCloseDialog={handleToggleDialog}
                            timeZone={timeZone}
                            patientId={patientId}
                        />
                    </Box>
                </DialogContent>
            </Dialog>
            <SchedulingInformationDialog isOpen={showDialogSchedulingInfo} taskType={task.type} handleOpenSchedulingInfo={handleOpenSchedulingInfo}></SchedulingInformationDialog>
        </Box>

    )
}