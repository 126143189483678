import { InsuranceServiceType, InsuranceVerificationElibilityCode, InsuranceVerificationServiceType, InsuranceVerificationTimePeriodQualifier } from "../enums/insuranceServiceType";

export const insuranceServiceTypeLabel = {
    [InsuranceServiceType.ProviderVisit]: 'Provider Visit',
    [InsuranceServiceType.HealthCoachVisit]: 'Health Coach Visit'
}

export const insuranceVerificationEligibilityCodeLabel = {
    [InsuranceVerificationElibilityCode.ActiveCoverage]: 'Active Coverage',
    [InsuranceVerificationElibilityCode.InactiveCoverage]: 'Inactive Coverage',
    [InsuranceVerificationElibilityCode.Coinsurance]: 'Coinsurance',
    [InsuranceVerificationElibilityCode.Copay]: 'Copay',
    [InsuranceVerificationElibilityCode.Deductible]: 'Deductible',
    [InsuranceVerificationElibilityCode.Limitation]: 'Limitation',
    [InsuranceVerificationElibilityCode.OutOfPocket]: 'Out Of Pocket',
    [InsuranceVerificationElibilityCode.Noncovered]: 'Non-covered',
    [InsuranceVerificationElibilityCode.BenefitDisclaimer]: 'Benefit Disclaimer',
    [InsuranceVerificationElibilityCode.Other]: 'Other',
    [InsuranceVerificationElibilityCode.ContactEntity]: 'Contact Following Entity for Eligibility or Benefit Information',
    [InsuranceVerificationElibilityCode.PrimaryCareProvider]: 'Primary Care Provider',
}

export const insuranceVerificationTimePeriodQualifierLabel = {
    [InsuranceVerificationTimePeriodQualifier.Day]: 'Day',
    [InsuranceVerificationTimePeriodQualifier.Years]: 'Years',
    [InsuranceVerificationTimePeriodQualifier.ServiceYears]: 'Service Years',
    [InsuranceVerificationTimePeriodQualifier.CalendarYear]: 'Calendar Year',
    [InsuranceVerificationTimePeriodQualifier.Visit]: 'Visit',
    [InsuranceVerificationTimePeriodQualifier.Remaining]: 'Remaining',
    [InsuranceVerificationTimePeriodQualifier.Admission]: 'Admission'
}

export const insuranceVerificationServiceTypeLabel = {
    [InsuranceVerificationServiceType.MedicalCare]: 'Medical Care',
    [InsuranceVerificationServiceType.Surgical]: 'Surgical',
    [InsuranceVerificationServiceType.Consultation]: 'Consultation',
    [InsuranceVerificationServiceType.DiagnosticXRay]: 'Diagnostic X-Ray',
    [InsuranceVerificationServiceType.DiagnosticLab]: 'Diagnostic Lab',
    [InsuranceVerificationServiceType.RadiationTherapy]: 'Radiation Therapy',
    [InsuranceVerificationServiceType.Anesthesia]: 'Anesthesia',
    [InsuranceVerificationServiceType.SurgicalAssistance]: 'Surgical Assistance',
    [InsuranceVerificationServiceType.Blood]: 'Blood',
    [InsuranceVerificationServiceType.DurableMedicalEquipmentUsed]: 'Durable Medical Equipment Used',
    [InsuranceVerificationServiceType.DurableMedicalEquipmentPurchased]:  'Durable Medical Equipment Purchased',
    [InsuranceVerificationServiceType.RenalSupplies]: 'Renal Supplies',
    [InsuranceVerificationServiceType.PreAdmissionTesting]: 'Pre Admission Testing',
    [InsuranceVerificationServiceType.DurableMedicalEquipmentRental]: 'Durable Medical Equipment Rental',
    [InsuranceVerificationServiceType.PneumoniaVaccine]: 'Pneumonia Vaccine',
    [InsuranceVerificationServiceType.SecondSurgicalOpinion]: 'Second Surgical Opinion',
    [InsuranceVerificationServiceType.ThirdSurgicalOpinion]: 'Third Surgical Opinion',
    [InsuranceVerificationServiceType.SocialWork]: 'Social Work',
    [InsuranceVerificationServiceType.DiagnosticDental]: 'Diagnostic Dental',
    [InsuranceVerificationServiceType.Periodontics]: 'Periodontics',
    [InsuranceVerificationServiceType.Restorative]: 'Restorative',
    [InsuranceVerificationServiceType.Endodontics]: 'Endodontics',
    [InsuranceVerificationServiceType.MaxillofacialProsthetics]: 'Maxillofacial Prosthetics',
    [InsuranceVerificationServiceType.AdjunctiveDentalServices]: 'Adjunctive Dental Services',
    [InsuranceVerificationServiceType.PlanCoverageAndGeneralBenefits]: 'Plan Coverage And General Benefits',
    [InsuranceVerificationServiceType.PlanWaitingPeriod]: 'Plan Waiting Period',
    [InsuranceVerificationServiceType.Chiropractic]: 'Chiropractic',
    [InsuranceVerificationServiceType.DentalCare]: 'Dental Care',
    [InsuranceVerificationServiceType.DentalCrowns]: 'Dental Crowns',
    [InsuranceVerificationServiceType.DentalAccident]: 'Dental Accident',
    [InsuranceVerificationServiceType.Orthodontics]: 'Orthodontics',
    [InsuranceVerificationServiceType.Prosthodontics]: 'Prosthodontics',
    [InsuranceVerificationServiceType.OralSurgery]: 'Oral Surgery',
    [InsuranceVerificationServiceType.PreventiveDental]: 'Preventive Dental',
    [InsuranceVerificationServiceType.HomeHealthCare]: 'Home Health Care',
    [InsuranceVerificationServiceType.HomeHealthPrescriptions]: 'Home Health Prescriptions',
    [InsuranceVerificationServiceType.Hospice]: 'Hospice',
    [InsuranceVerificationServiceType.RespiteCare]: 'Respite Care',
    [InsuranceVerificationServiceType.Hospitalization]: 'Hospitalization',
    [InsuranceVerificationServiceType.HospitalizationInpatient]: 'Hospitalizaton Inpatient',
    [InsuranceVerificationServiceType.HospitalRoomAndBoard]: 'Hospital Room And Board',
    [InsuranceVerificationServiceType.LongTermCare]: 'Long Term Care',
    [InsuranceVerificationServiceType.MajorMedical]: 'Major Medical',
    [InsuranceVerificationServiceType.MedicallyRelatedTransportation]: 'Medically Related Transportation',
    [InsuranceVerificationServiceType.PlanCoverage]: 'Plan Coverage',
    [InsuranceVerificationServiceType.InvitroFertilization]: 'Invitro Fertilization',
    [InsuranceVerificationServiceType.MRIScan]: 'MRI Scan',
    [InsuranceVerificationServiceType.DonorProcedures]: 'Donor Procedures',
    [InsuranceVerificationServiceType.Acupuncture]: 'Acupuncture',
    [InsuranceVerificationServiceType.NewbornCare]: 'Newborn Care',
    [InsuranceVerificationServiceType.Pathology]: 'Pathology',
    [InsuranceVerificationServiceType.SmokingCessation]: 'Smoking Cessation',
    [InsuranceVerificationServiceType.WellBabyCare]: 'Well Baby Care',
    [InsuranceVerificationServiceType.Maternity]: 'Maternity',
    [InsuranceVerificationServiceType.Transplants]: 'Transplants',
    [InsuranceVerificationServiceType.Audiology]: 'Audiology',
    [InsuranceVerificationServiceType.InhalationTherapy]: 'Inhalation Therapy',
    [InsuranceVerificationServiceType.DiagnosticMedical]: 'Diagnostic Medical',
    [InsuranceVerificationServiceType.PrivateDutyNursing]: 'Private Duty Nursing',
    [InsuranceVerificationServiceType.Porsthetics]: 'Porsthetics',
    [InsuranceVerificationServiceType.Dialysis]: 'Dialysis',
    [InsuranceVerificationServiceType.Otology]: 'Otology',
    [InsuranceVerificationServiceType.Chemotherapy]: 'Chemotherapy',
    [InsuranceVerificationServiceType.AllergyTesting]: 'Allergy Testing',
    [InsuranceVerificationServiceType.Immunizations]: 'Immunizations',
    [InsuranceVerificationServiceType.RoutinePhysical]: 'Routine Physical',
    [InsuranceVerificationServiceType.FamilyPlanning]: 'Family Planning',
    [InsuranceVerificationServiceType.Infertility]: 'Infertility',
    [InsuranceVerificationServiceType.Abortion]: 'Abortion',
    [InsuranceVerificationServiceType.HivTreatment]: 'Hiv Treatment',
    [InsuranceVerificationServiceType.EmergencyServices]: 'Emergency Services',
    [InsuranceVerificationServiceType.CancerTreatment]: 'Cancer Treatment',
    [InsuranceVerificationServiceType.RetailPharmacy]: 'Retail Pharmacy',
    [InsuranceVerificationServiceType.FreeStandingPrescriptionDrug]: 'Free Standing Prescription Drug',
    [InsuranceVerificationServiceType.MailOrderPharmacy]: 'Mail Order Pharmacy',
    [InsuranceVerificationServiceType.BrandNamePrescriptionDrug]: 'Brand Name Prescription Drug',
    [InsuranceVerificationServiceType.GenericPrescriptionDrug]: 'Generic Prescription Drug',
    [InsuranceVerificationServiceType.Podiatry]: 'Podiatry',
    [InsuranceVerificationServiceType.DentalTestAndExaminations]: 'Dental Test And Examinations',
    [InsuranceVerificationServiceType.PeriodontalSurgicalServices]: 'Periodontal Surgical Services',
    [InsuranceVerificationServiceType.DentureRepairs]: 'Denture Repairs',
    [InsuranceVerificationServiceType.DentalNonSurgicalExtractions]: 'Dental Non Surgical Extractions',
    [InsuranceVerificationServiceType.PrescriptionDrug]: 'Prescription Drug',
    [InsuranceVerificationServiceType.BariatricServices]: 'Bariatric Services',
    [InsuranceVerificationServiceType.Psychiatric]: 'Psychiatric',
    [InsuranceVerificationServiceType.Psychotherapy]: 'Psychotherapy',
    [InsuranceVerificationServiceType.PsychiatricInpatient]: 'Psychiatric Inpatient',
    [InsuranceVerificationServiceType.PsychiatricOutpatient]: 'Psychiatric Outpatient',
    [InsuranceVerificationServiceType.Rehabilitation]: 'Rehabilitation',
    [InsuranceVerificationServiceType.RehabilitationInpatient]: 'Rehabilitation Inpatient',
    [InsuranceVerificationServiceType.RehabilitationOutpatient]: 'Rehabilitation Outpatient',
    [InsuranceVerificationServiceType.OccupationalTherapy]: 'Occupational Therapy',
    [InsuranceVerificationServiceType.PhysicalMedicine]: 'Physical Medicine',
    [InsuranceVerificationServiceType.SpeechTherapy]: 'Speech Therapy',
    [InsuranceVerificationServiceType.SkilledNursingCare]: 'Skilled Nursing Care',
    [InsuranceVerificationServiceType.SubstanceAbuse]: 'Substance Abuse',
    [InsuranceVerificationServiceType.AlcoholismTreatment]: 'Alcoholism Treatment',
    [InsuranceVerificationServiceType.DrugAddiction]: 'Drug Addiction',
    [InsuranceVerificationServiceType.Optometry]: 'Optometry',
    [InsuranceVerificationServiceType.Frames]: 'Frames',
    [InsuranceVerificationServiceType.Lenses]: 'Lenses',
    [InsuranceVerificationServiceType.RoutineEyeExam]: 'Routine Eye Exam',
    [InsuranceVerificationServiceType.NonmedicallyNecessaryPhysical]: 'Nonmedically Necessary Physical',
    [InsuranceVerificationServiceType.ExperimentalDrugTherapy]: 'Experimental Drug Therapy',
    [InsuranceVerificationServiceType.BurnCare]: 'Burn Care',
    [InsuranceVerificationServiceType.IndependentMedicalEvaluation]: 'Independent Medical Evaluation',
    [InsuranceVerificationServiceType.PsychiatricTreatmentPartialHospitalization]: 'Psychiatric Treatment Partial Hospitalization',
    [InsuranceVerificationServiceType.DayCare]: 'Day Care',
    [InsuranceVerificationServiceType.CognitiveTherapy]: 'Cognitive Therapy',
    [InsuranceVerificationServiceType.MassageTherapy]: 'Massage Therapy',
    [InsuranceVerificationServiceType.PulmonaryRehabilitation]: 'Pulmonary Rehabilitation',
    [InsuranceVerificationServiceType.CardiacRehabilitation]: 'Cardiac Rehabilitation',
    [InsuranceVerificationServiceType.Pediatric]: 'Pediatric',
    [InsuranceVerificationServiceType.NurseryRoomAndBoard]: 'Nursery Room And Board',
    [InsuranceVerificationServiceType.Orthopedic]: 'Orthopedic',
    [InsuranceVerificationServiceType.Cardiac]: 'Cardiac',
    [InsuranceVerificationServiceType.Lymphatic]: 'Lymphatic',
    [InsuranceVerificationServiceType.Gastrointestinal]: 'Gastrointestinal',
    [InsuranceVerificationServiceType.Endocrine]: 'Endocrine',
    [InsuranceVerificationServiceType.Neurology]: 'Neurology',
    [InsuranceVerificationServiceType.Gynecological]: 'Gynecological',
    [InsuranceVerificationServiceType.Obstetrical]: 'Obstetrical',
    [InsuranceVerificationServiceType.ObstetricalGynecological]: 'Obstetrical Gynecological',
    [InsuranceVerificationServiceType.SickPhysicianVisit]: 'Sick Physician Visit',
    [InsuranceVerificationServiceType.WellPhysicianVisit]: 'Well Physician Visit',
    [InsuranceVerificationServiceType.CoronaryCare]: 'Coronary Care',
    [InsuranceVerificationServiceType.ScreeningXRay]: 'Screening X-Ray',
    [InsuranceVerificationServiceType.ScreeningLaboratory]: 'Screening Laboratory',
    [InsuranceVerificationServiceType.MammogramHighRisk]: 'Mammogram High Risk',
    [InsuranceVerificationServiceType.MammogramLowRisk]: 'Mammogram Low Risk',
    [InsuranceVerificationServiceType.FluVaccination]: 'Flu Vaccination',
    [InsuranceVerificationServiceType.EyewearAccessories]: 'Eyewear Accessories',
    [InsuranceVerificationServiceType.CaseManagement]: 'Case Management',
    [InsuranceVerificationServiceType.Dermatology]: 'Dermatology',
    [InsuranceVerificationServiceType.DurableMedicalEquipment]: 'Durable Medical Equipment',
    [InsuranceVerificationServiceType.DiabeticSupplies]: 'Diabetic Supplies',
    [InsuranceVerificationServiceType.AppliedBehavioralAnalysisTherapy]: 'Applied Behavioral Analysis Therapy',
    [InsuranceVerificationServiceType.NonMedicalEquipment]: 'Non Medical Equipment',
    [InsuranceVerificationServiceType.PsychiatricEmergency]: 'Psychiatric Emergency',
    [InsuranceVerificationServiceType.StepDownUnit]: 'Step Down Unit',
    [InsuranceVerificationServiceType.SkilledNursingFacilityHeadLevelOfCare]: 'Skilled Nursing Facility Head Level Of Care',
    [InsuranceVerificationServiceType.SkilledNursingFacilityVentilatorLevelOfCare]: 'Skilled Nursing Facility Ventilator Level Of Care',
    [InsuranceVerificationServiceType.LevelOfCare1]: 'Level Of Care 1',
    [InsuranceVerificationServiceType.LevelOfCare2]: 'Level Of Care 2',
    [InsuranceVerificationServiceType.LevelOfCare3]: 'Level Of Care 3',
    [InsuranceVerificationServiceType.LevelOfCare4]: 'Level Of Care 4',
    [InsuranceVerificationServiceType.Radiographs]: 'Radiographs',
    [InsuranceVerificationServiceType.DiagnosticImaging]: 'Diagnostic Imaging',
    [InsuranceVerificationServiceType.FixedProsthodontics]: 'Fixed Prosthodontics',
    [InsuranceVerificationServiceType.RemovableProsthodontics]: 'Removable Prosthodontics',
    [InsuranceVerificationServiceType.IntraoralImages]: 'Intraoral Images',
    [InsuranceVerificationServiceType.OralEvaluation]: 'Oral Evaluation',
    [InsuranceVerificationServiceType.DentalProphylaxis]: 'Dental Prophylaxis',
    [InsuranceVerificationServiceType.PanoramicImages]: 'Panoramic Images',
    [InsuranceVerificationServiceType.Sealants]: 'Sealants',
    [InsuranceVerificationServiceType.FluorideTreatments]: 'Fluoride Treatments',
    [InsuranceVerificationServiceType.DentalImplants]: 'Dental Implants',
    [InsuranceVerificationServiceType.TemporomandibularJointDysfunction]: 'Temporomandibular Joint Dysfunction',
    [InsuranceVerificationServiceType.LongTermCarePharmacy]: 'Long Term Care Pharmacy',
    [InsuranceVerificationServiceType.ComprehensiveMedicationTherapyManagementReview]: 'Comprehensive Medication Therapy Management Review',
    [InsuranceVerificationServiceType.TargetedMedicationTherapyManagementReview]: 'Targeted Medication Therapy Management Review',
    [InsuranceVerificationServiceType.DietaryServices]: 'Dietary Services',
    [InsuranceVerificationServiceType.IntensiveCardiacRehabilitation]: 'Intensive Cardiac Rehabilitation',
    [InsuranceVerificationServiceType.ConvenienceCare]: 'Convenience Care',
    [InsuranceVerificationServiceType.Telemedicine]: 'Telemedicine',
    [InsuranceVerificationServiceType.PharmacistServices]: 'Pharmacist Services',
    [InsuranceVerificationServiceType.DiabeticEducation]: 'Diabetic Education',
    [InsuranceVerificationServiceType.EarlyIntervention]: 'Early Intervention',
    [InsuranceVerificationServiceType.PreventiveServices]: 'Preventive Services',
    [InsuranceVerificationServiceType.SpecialtyPharmacy]: 'Specialty Pharmacy',
    [InsuranceVerificationServiceType.DurableMedicalEquipmentNew]: 'Durable Medical Equipment New',
    [InsuranceVerificationServiceType.CATScan]: 'CAT Scan',
    [InsuranceVerificationServiceType.Ophthalmology]: 'Ophthalmology',
    [InsuranceVerificationServiceType.ContactLenses]: 'ContactLenses',
    [InsuranceVerificationServiceType.FertilityPreservation]: 'Fertility Preservation',
    [InsuranceVerificationServiceType.MedicallyTailoredMeals]: 'Medically Tailored Meals',
    [InsuranceVerificationServiceType.IVTherapy]: 'IV Therapy',
    [InsuranceVerificationServiceType.MedicalCoverage]: 'Medical Coverage',
    [InsuranceVerificationServiceType.SocialWorkCoverage]: 'Social Work Coverage',
    [InsuranceVerificationServiceType.DentalCoverage]: 'Dental Coverage',
    [InsuranceVerificationServiceType.HearingCoverage]: 'Hearing Coverage',
    [InsuranceVerificationServiceType.PrescriptionDrugCoverage]: 'Prescription Drug Coverage',
    [InsuranceVerificationServiceType.VisionCoverage]: 'Vision Coverage',
    [InsuranceVerificationServiceType.OrthodontiaCoverage]: 'Orthodontia Coverage',
    [InsuranceVerificationServiceType.MentalHealthCoverage]: 'Mental Health Coverage',
    [InsuranceVerificationServiceType.GenericPrescriptionDrugFormulary]: 'Generic Prescription Drug Formulary',
    [InsuranceVerificationServiceType.GenericPrescriptionDrugNonFormulary]: 'Generic Prescription Drug Non Formulary',
    [InsuranceVerificationServiceType.Allergy]: 'Allergy',
    [InsuranceVerificationServiceType.IntesiveCare]: 'Intesive Care',
    [InsuranceVerificationServiceType.MentalHealth]: 'Mental Health',
    [InsuranceVerificationServiceType.NeonatalIntensiveCare]: 'Neonatal Intensive Care',
    [InsuranceVerificationServiceType.Oncology]: 'Oncology',
    [InsuranceVerificationServiceType.PositronEmissionTomographyScan]: 'Positron Emission Tomography Scan',
    [InsuranceVerificationServiceType.PhysicalTherapy]: 'Physical Therapy',
    [InsuranceVerificationServiceType.Pulmonary]: 'Pulmonary',
    [InsuranceVerificationServiceType.Renal]: 'Renal',
    [InsuranceVerificationServiceType.ResidentialPsychiatricTreatment]: 'Residential Psychiatric Treatment',
    [InsuranceVerificationServiceType.SeriousMentalHealth]: 'Serious Mental Health',
    [InsuranceVerificationServiceType.TransitionalCare]: 'Transitional Care',
    [InsuranceVerificationServiceType.TransitionalNurseryCare]: 'Transitional Nursery Care',
    [InsuranceVerificationServiceType.UrgentCare]: 'Urgent Care'
}