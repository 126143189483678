import { Box, Table, TableBody, TableContainer, TableHead, IconButton, ListItemIcon, Popover, Typography, FormControlLabel } from '@material-ui/core';
import React from 'react';
import { useFacade } from "./fellowsComponent.hooks";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { FellowshipRosterPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/FellowshipRosterPlaceHolder";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { renderEmptyValue } from '../../../common/helpers/empty-value';
import clsx from 'clsx';
import commonUseStyles from '../../../common/styles/common.styles';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { ProtectedElement } from "../../../common/components/ProtectedElement";
import { AddFellowButtonComponent } from "../addFellowButton/AddFellowButtonComponent";
import { UpdateFellowComponent } from '../updateFellow/UpdateFellowComponent';
import { PermissionType } from "../../../auth/models/auth.enums";
import { WildHealthMenuItem } from "../../../common/components/wildHealthMenu/WildHealthMenu";
import { WildHealthLinkButton } from '../../../common/components/WildHealthLinkButton';
import { WildHealthSwitcher } from '../../../common/components/WIldHealthSwitcher';

const fellowActionPopoverProps: any = {
  id: "fellowActionPopover",
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
};

interface FellowsComponentProps {
  pmFellowship: boolean;
  rosterId: number;
}

export const FellowsComponent: React.FC<FellowsComponentProps> = (props: FellowsComponentProps) => {
  const { pmFellowship, rosterId } = props;
  const [
    {
      fellows,
      isLoading,
      selectedFellow,
      isEditOpen,
      selfManagingEmployee,
      currentEmployeeEmail,
      isFellowActionsOpen,
      fellowActionsAnchorEl,
      isRosterActive,
    },
    handleIsRosterActive,
    handleToggleFellowActions,
    handleToggleEditFellow,
    handleDeleteFellow,
    handleGoToFellowPatients,
  ] = useFacade(rosterId);

  const commonClasses = commonUseStyles();

  const renderTable = (): JSX.Element => {
    if (isLoading) {
      return (<WildHealthLinearProgress />)
    }

    if (!fellows.length) {
      return (
        <Box pt={8}>
          <FellowshipRosterPlaceHolder message="There are no fellows in this roster yet." />
        </Box>
      );
    }

    return (
      <Box mt={2}>
        <TableContainer>
          <Table>
            <TableHead>
              <WildHealthTableRow>
                <WildHealthTableCell>Fellow Name</WildHealthTableCell>
                <WildHealthTableCell>Credentials</WildHealthTableCell>
                <WildHealthTableCell>Fellow's Email</WildHealthTableCell>
                <WildHealthTableCell>Phone Number</WildHealthTableCell>
                {pmFellowship && <WildHealthTableCell style={{ width: '5%' }} />}
              </WildHealthTableRow>
            </TableHead>
            <TableBody>
              {
                fellows.map((fellow, index) =>
                  <WildHealthTableRow key={index}>
                    <WildHealthTableCell>
                      <WildHealthLinkButton
                        id={`fellows-link-fellow-patients-${fellow.id}`}
                        onClick={() => handleGoToFellowPatients(fellow.id)}
                        content={`${fellow.firstName} ${fellow.lastName}`}
                      />
                    </WildHealthTableCell>
                    <WildHealthTableCell>{fellow.credentials && fellow.credentials.length ? fellow.credentials : renderEmptyValue("empty")}</WildHealthTableCell>
                    <WildHealthTableCell>{fellow.email}</WildHealthTableCell>
                    <WildHealthTableCell>{fellow.phoneNumber && fellow.phoneNumber.length ? fellow.phoneNumber : renderEmptyValue("empty")}</WildHealthTableCell>
                    {pmFellowship && (
                      <WildHealthTableCell align="left">
                        <IconButton
                          id={`edit-fellow-roster-${fellow.id}`}
                          onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleToggleFellowActions(!isFellowActionsOpen, fellow.id, fellow.email, event.currentTarget)}
                        >
                          <MoreHorizIcon />
                        </IconButton>
                      </WildHealthTableCell>
                    )}
                  </WildHealthTableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    )
  }

  return (
    <Box p="30px">
      <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <span className={clsx(commonClasses.size20, commonClasses.textMedium)}>Roster</span>
        </Box>
        <Box display="flex" >
          <Box p={1} px={0}>
            <span>
              Inactive
            </span>
          </Box>
          <Box ml={3}>
            <FormControlLabel
              control={<WildHealthSwitcher checked={isRosterActive} onChange={() => handleIsRosterActive()} />}
              label=""
            />
          </Box>
          <Box p={1} px={0}>
            <span>
              Active
            </span>
          </Box>
          {pmFellowship && (
            <Box ml={5} display="flex " alignItems="center">
              <ProtectedElement
                element={<AddFellowButtonComponent rosterId={rosterId} />}
                permissions={[PermissionType.ManageEmployees]}
              />
            </Box>
          )}
        </Box>
      </Box>
      {
        renderTable()
      }
      <UpdateFellowComponent
        isOpen={isEditOpen}
        selectedFellow={selectedFellow}
        selfManagingEmployee={selfManagingEmployee}
        handleToggleDialog={(value: boolean) => handleToggleEditFellow(value)}
      />
      <ProtectedElement
        element={
          <Popover
            id={fellowActionPopoverProps.id}
            anchorEl={fellowActionsAnchorEl}
            open={isFellowActionsOpen}
            anchorOrigin={fellowActionPopoverProps.anchorOrigin}
            transformOrigin={fellowActionPopoverProps.transformOrigin}
            onClose={() => handleToggleFellowActions(false, 0, currentEmployeeEmail, null)}
          >
            <WildHealthMenuItem
              id="fellowship-roster-edit"
              onClick={() => handleToggleEditFellow(true)}
            >
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <Typography variant="inherit" noWrap>
                Edit
              </Typography>
            </WildHealthMenuItem>
            <WildHealthMenuItem
              id="fellowship-roster-delete"
              onClick={() => handleDeleteFellow()}
            >
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <Typography variant="inherit" noWrap>
                Delete
              </Typography>
            </WildHealthMenuItem>
          </Popover>
        }
        permissions={[PermissionType.ManageEmployees]}
      />
    </Box>
  )
}