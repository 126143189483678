import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { onEmit } from "../../../common/helpers/on-emit";
import { PatientConversationModel } from "../../models/conversation.models";
import { patientConversationsQuery } from "../../stores/patientConversationsStore/patientConversations.query";
import { notificationsQuery, notificationsStore } from "../../../notifications/stores/notifications";
import { NotificationModel, NotificationType } from "../../../notifications/models/notifications.models";
import { notificationsService } from "../../../notifications/services/notifications.service";
import { profileService } from "../../../account/services/profile.service";


interface PatientConversationsComponentState {
    isSupportLoading: boolean;
    isHealthCareLoading: boolean;
    isSupportPhotosLoading: boolean;
    isHCPhotosLoading: boolean;
    conversationProxies: any[];
    supportConversations: PatientConversationModel[];
    healthCareConversation: PatientConversationModel;
    notifications: NotificationModel[];
    targetConversation: PatientConversationModel;
}

let stateContext: PatientConversationsComponentState = null;

export function useFacade(): [PatientConversationsComponentState] {

    const [state, setState] = useState({
        isSupportLoading: true,
        isHealthCareLoading: true,
        isSupportPhotosLoading: true,
        isHCPhotosLoading: true,
        conversationProxies: [],
        supportConversations: [],
        healthCareConversation: null,
    } as PatientConversationsComponentState);

    stateContext = state;

    const handleRemoveTargetNotifications = () => {
        if(!!state.targetConversation && !! state.notifications){
            if (state.notifications.length){
                const targetConversationNotifications = state.notifications.filter(x => x.linkData.includes(state.targetConversation.id.toString()) || x.type == NotificationType.UnreadMessagesCount);

                if(targetConversationNotifications.length){
                    notificationsStore.deleteNotifications(targetConversationNotifications);
                    notificationsService.deleteBulk(targetConversationNotifications);
                }
            }
        }
    }

    useEffect(() => {
        const supportPhotosCB = () => setState(state => ({...state, isSupportPhotosLoading: false}))
        const hcPhotosCB = () => setState(state => ({...state, isHCPhotosLoading: false}))
        const subscriptions: Subscription[] = [
            onEmit<PatientConversationModel[]>(patientConversationsQuery.supportConversations$, conversations => {
                setState(state => ({ ...state, supportConversations: conversations }))
                if (conversations && conversations.length) {
                    setState(state => ({...state, isSupportPhotosLoading: true}))
                    const employeeIds = conversations.map(r => r.employees).reduce((e1: number[], e2) => {
                        for (const item of e2)
                        {
                            if(!e1.includes(item.employeeId)) {
                                e1.push(item.employeeId)
                            }
                        }

                        return e1;
                    }, [])
                    if (employeeIds.length) {
                        profileService.getEmployeeProfilePhotos(employeeIds.join(',')).subscribe(supportPhotosCB, supportPhotosCB)
                    } else {
                        supportPhotosCB()
                    }
                } else {
                    supportPhotosCB()
                }
            }),
            onEmit<PatientConversationModel>(patientConversationsQuery.heathCareConversation$, conversation => {
                setState(state => ({ ...state, healthCareConversation: conversation }))
                if (conversation && conversation.employees.length) {
                    setState(state => ({...state, isHCPhotosLoading: true}))
                    const employeeIds = conversation.employees.map(e => e.employeeId).join(',')
                    profileService.getEmployeeProfilePhotos(employeeIds).subscribe(hcPhotosCB, hcPhotosCB)
                } else {
                    hcPhotosCB()
                }
            }),
            onEmit<boolean>(patientConversationsQuery.isLoading$, isLoading => {
                setState(state => ({ ...state, isHealthCareLoading: isLoading, isSupportLoading: isLoading}))
            })
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    useEffect(() => {
        const subscriptions = [
            onEmit<NotificationModel[]>(notificationsQuery.notifications$, () => {
                const conversationNotifications = notificationsQuery.getNotificationsByTypes([
                    NotificationType.UnreadMessagesCount,
                    NotificationType.NewMessage
                ]);

                setState(state => ({ ...state, notifications: conversationNotifications }))
            }),
            onEmit<PatientConversationModel>(patientConversationsQuery.targetConversation$, conversation => {
                if (conversation) {
                    setState(state => ({ ...state, targetConversation: conversation }));
                }
            }),
        ]

        return () => {
            subscriptions.map(it => it.unsubscribe());
        };
    }, [])

    handleRemoveTargetNotifications();

    return [state];
}
