import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        icon: {
            cursor: 'pointer',
            '&:hover ~ .tooltip-element': {
                display: 'flex',
            },
        },
    }),
);
