import { GeneralValidator } from "../../../common/validation/general-validator";
import {
  notWhitespaceValidationRule,
  requiredValidationRule,
  integerValidationRule,
  numberValidationRule,
} from "../../../common/validation/general-validation-rules";

export const modifyConversationTemplateValidator = new GeneralValidator({
  'name': [notWhitespaceValidationRule(), requiredValidationRule()],
  'description': [notWhitespaceValidationRule(), requiredValidationRule()],
  'text': [notWhitespaceValidationRule(), requiredValidationRule()],
  'type': [requiredValidationRule()],
  'order': [integerValidationRule, numberValidationRule, requiredValidationRule()],
});