import { useEffect, useState } from "react";
import { useHistory } from 'react-router';
import { Subscription } from "recompose";
import { onEmit } from "../../../common/helpers/on-emit";
import { pageSizes } from "../../../common/pagination/models/page-sizes";
import { IPaginationState } from "../../../common/pagination/models/pagination-state";
import { getAvailablePages } from "../../../common/pagination/helpers/get-evailable-pages";
import { InsuranceModel, InsuranceConfiguration } from "../../models/insurance.models";
import { insuranceQuery } from "../../stores/insurance.query";
import { insuranceManagementService } from "../../services/insuranceManagement.service";
import { SortingDirection } from "../../../common/sorting/models/sorting-destination";
import { ISortingState } from "../../../common/sorting/models/sorting-state";
import { handleCompare } from "../../../common/sorting/helpers/handle-compare";
import { authQuery } from "../../../auth/stores/auth";
import { navigationService } from "../../../../services/navigation.service";

export enum SortingSource {
  None,
  Name = "name"
}

export enum SortingObject {
  None,
  Insurance = "insurance",
}

interface InsuranceConfigurationsState extends IPaginationState, ISortingState {
  isLoading: boolean;
  isConfigLoading: boolean;
  isSubmitted: boolean;
  isAddingConfiguration: boolean;
  insuranceConfigurations: InsuranceConfiguration[];
  originalInsuranceConfigurations: InsuranceConfiguration[];
  insuranceCarriers: InsuranceModel[];
  insurances: InsuranceModel[];
  selectedCarrierId: number;
  searchKey: string;
  removingConfigurationId: number;
  page: number;
  rowsPerPage: number;
}

let stateContext: InsuranceConfigurationsState;

export function useFacade(): [
  InsuranceConfigurationsState,
  (value: any) => void,
  () => void,
  (insuranceId: number) => void,
  (query: string) => void,
  () => number[],
  (value: any) => void,
  (page: number) => void,
  (configurationId: number) => void
] {
  const history = useHistory();
  const [state, setState] = useState({
    isLoading: true,
    isOrganiztionLoading: true,
    isConfigLoading: true,
    isSubmitted: false,
    isAddingConfiguration: false,
    insuranceConfigurations: [],
    originalInsuranceConfigurations: [],
    insuranceCarriers: [],
    insurances: [],
    selectedCarrierId: 0,
    searchKey: '',
    removingConfigurationId: 0,
    page: 0,
    rowsPerPage: pageSizes[0],
    totalCount: 0,
    selectedPage: 1,
    pageSize: pageSizes[1],
    sortingColumns: [
      {title: 'Insurance', direction: SortingDirection.Asc, source: SortingSource.Name, object: SortingObject.Insurance },
      {title: 'Insurance Plans'},
      {title: 'Start Date'},
      {title: 'End'},
      {title: 'Actions'},
    ],
    sortingSource: SortingSource.None,
  } as InsuranceConfigurationsState);

  stateContext = state;

  const handleOnFilter = (search: string) => {
    setState(state => ({...state, searchKey: search}));
  }

  const handleSearchSubmit = (query: string) => {
    const searchQuery = query ?? '';

    const trimedSearch = decodeURIComponent(searchQuery).trim().toLowerCase();
    const arrayTrimSearchKey = trimedSearch.split(' ');

    const filteredConfigurations = state.originalInsuranceConfigurations?.filter(
      (e) =>
        arrayTrimSearchKey.find((item) =>
          e.insurance.name?.toLowerCase().includes(item)
        ) !== undefined
    );
    
    setState(state => ({
      ...state,
      isLoading: false,
      selectedPage: 1,
      insuranceConfigurations: searchQuery ? filteredConfigurations : state.originalInsuranceConfigurations,
      totalCount:  searchQuery ? filteredConfigurations.length : state.originalInsuranceConfigurations.length,
    }));
  }

  const getAllAvailablePages = () => {
    return getAvailablePages(state);
  }

  const handlePageSizeChange = (value: any) => {
    if (value === state.pageSize) {
      return;
    }

    setState(state => ({
      ...state,
      pageSize: value,
      selectedPage: 1
    }));
  }

  const handlePageChange = (page: number) => {
    if (page === state.selectedPage) {
      return;
    }

    setState(state => ({
      ...state,
      selectedPage: page
    }));
  }

  const handleGoToEdit = (configurationId: number) => {
    navigationService.toInsuranceConfigurationEdit(history, configurationId);
    return;
  }

  const handleChanges = (value: any) => {
    setState({...state, selectedCarrierId: value });
  }

  const handleCreateInsuranceConfiguration = () => {
    setState({...state, isAddingConfiguration: true })

    const cb = () => setState(state => ({ ...state, isAddingConfiguration: false }));

    const addConfiguration:any = {
      insuranceId: state.selectedCarrierId,
      practiceId: authQuery.getCurrentPracticeId(),
      startDate: new Date(),
      endDate: new Date()
    }
    insuranceManagementService.createInsuranceConfiguration(addConfiguration).subscribe(() => {
      setState(state => ({
        ...state,
        selectedCarrierId: 0,
        searchKey: '',
      }))
      insuranceManagementService.getInsuranceConfigurations().subscribe(cb, cb);
    }, cb);
  }

  const handleRemoveConfiguration = (configurationId: number) => {
    const targetConfiguration = state.insuranceConfigurations.find(i => i.id === configurationId);
    if (!targetConfiguration) return;
    setState({...state, removingConfigurationId: configurationId, isSubmitted: true })
    const cb = () => setState(state => ({ ...state, removingConfigurationId: 0, isSubmitted: false }));

    insuranceManagementService.deleteInsuranceConfiguration(configurationId).subscribe(cb, cb);
  }

  const useEffectCB = () => {
    const subscriptions: Subscription[] = [
      onEmit<InsuranceModel[]>(insuranceQuery.insurances$, insurances => {
        const sortedInsurances = insurances.sort((p1, p2) => handleCompare(p1, p2, SortingDirection.Desc, SortingSource.Name));
        setState(state => ({
          ...state,
          insuranceCarriers: sortedInsurances,
        }));
      }),
      onEmit<InsuranceConfiguration[]>(insuranceQuery.insuranceConfigurations$, insuranceConfigurations => {
        const sortedInsuranceConfigurations = insuranceConfigurations.sort((p1, p2) => handleCompare(p1, p2, SortingDirection.Desc, SortingSource.Name, SortingObject.Insurance));
        setState(state => ({
          ...state,
          insuranceConfigurations: sortedInsuranceConfigurations,
          originalInsuranceConfigurations: sortedInsuranceConfigurations,
          totalCount: sortedInsuranceConfigurations.length
        }));
      })
    ];

    const cb = () => setState(state => ({ ...state, isLoading: false }));
    const getConfigCB = () => setState(state => ({ ...state, isConfigLoading: false }))

    insuranceManagementService.getInsurances().subscribe(cb, cb);
    insuranceManagementService.getInsuranceConfigurations().subscribe(getConfigCB, getConfigCB);

    return () => {
      subscriptions.map(it => it.unsubscribe())
    };
  }

  useEffect(useEffectCB, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!stateContext.isSubmitted && !stateContext.isLoading) {
        handleSearchSubmit(stateContext.searchKey);
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn)
  }, [stateContext.searchKey]);

  return [
    state,
    handleChanges,
    handleCreateInsuranceConfiguration,
    handleRemoveConfiguration,
    handleOnFilter,
    getAllAvailablePages,
    handlePageSizeChange,
    handlePageChange,
    handleGoToEdit
  ];
}