import { AddressState, addressStore, AddressStore } from './address.store';
import { StateModel } from '../../models/address.model';
import {Query} from '@datorama/akita';

/**
 * Provides profile queries
 */
export class AddressQuery extends Query<AddressState> {
    states$ = this.select(state => state.states);

    constructor(protected store: AddressStore) {
        super(store);
    }

    public getStates(): StateModel[] {
        return this.getValue().states;
    }

    public findState(lookup: string): StateModel {
        return this.getStates().find(state => state.name === lookup || state.abbreviation === lookup) || null;
    }
}

export const addressQuery = new AddressQuery(addressStore);
