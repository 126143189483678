import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { onEmit } from "../../../common/helpers/on-emit";
import {RxntMedicationModel} from "../../models/patientMedicationModel";
import { patientMedicationsQuery } from "../../stores/patientMedicationsStore/patientMedications.query";
import { pageSizes } from "../../../common/pagination/models/page-sizes";
import { getAvailablePages } from "../../../common/pagination/helpers/get-evailable-pages";
import {RxntAllergyModel} from "../../models/patientAllergiesModel";
import { patientAllergiesQuery } from "../../stores/patientAllergyStore/patientAllergies.query";
import {prescriptionService} from "../../services/prescription.service";

interface PatientMedicationsRxNTComponentState {
    isLoading: boolean;
    totalCount: number;
    selectedPage: number;
    pageSize: number;
    medications: RxntMedicationModel[];
    medicationsPart: RxntMedicationModel[];
    allergies: RxntAllergyModel[];
}

export function useFacade(patientId: number | null): [
    PatientMedicationsRxNTComponentState,
    (value: any) => void,
    (page: number) => void,
    () => number[]
] {
    const [state, setState] = useState({
        isLoading: true,
        totalCount: 0,
        selectedPage: 1,
        pageSize: pageSizes[2],
        medications: [],
        medicationsPart: [],
        allergies: [],
    } as PatientMedicationsRxNTComponentState);

    const handlePageSizeChange = (value: any) => {

        if (value === state.pageSize) {
            return;
        }

        const start = 0;
        const end = 1 * value > state.totalCount ? state.totalCount : state.selectedPage * value;
        const medicationsPartView = state.medications.slice(start, end)

        setState(state => ({
            ...state,
            pageSize: value,
            selectedPage: 1,
            medicationsPart: medicationsPartView
        }));
    }

    const handlePageChange = (page: number) => {
        if (page === state.selectedPage) {
            return;
        }

        const start = (page - 1) * state.pageSize;
        const end = page * state.pageSize > state.totalCount ? state.totalCount : page * state.pageSize;
        const medicationsPartView = state.medications.slice(start, end)

        setState(state => ({
            ...state,
            selectedPage: page,
            medicationsPart: medicationsPartView
        }));
    }

    const getAllAvailablePages = () => {
        return getAvailablePages(state);
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<RxntMedicationModel[]>(patientMedicationsQuery.rxntMedications$, medications => {
                const medicationsPartView = medications.slice(0, pageSizes[2])

                setState(state => ({
                    ...state,
                    medications: medications,
                    totalCount: medications.length,
                    selectedPage: 1,
                    pageSize: pageSizes[2],
                    medicationsPart: medicationsPartView,
                }));
            }),
            onEmit<RxntAllergyModel[]>(patientAllergiesQuery.rxntAllergies$, allergies => {
                if (allergies) {
                    setState(state => ({
                        ...state,
                        allergies
                    }));
                }
            })
        ];

        patientId
            ? prescriptionService.getPatientActiveMeds(patientId).subscribe(() => setState((state) => ({ ...state, isLoading: false })))
            : prescriptionService.getMyActiveMeds().subscribe(() => setState((state) => ({ ...state, isLoading: false })));

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [patientId]);

    return [
        state,
        handlePageSizeChange,
        handlePageChange,
        getAllAvailablePages
    ];
}