import { notWhitespaceValidationRule, stringLengthValidationRule } from "../../../common/validation/general-validation-rules";
import { GeneralValidator } from "../../../common/validation/general-validator";

export class MedicationComponentFieldNames {
    static readonly itemName: string = 'name';
    static readonly dosage: string = 'dosage';
    static readonly instructions: string = 'instructions';
    static readonly startDate: string = 'startDate';
}

export const medicationComponentValidator = new GeneralValidator({
    [MedicationComponentFieldNames.itemName]: [stringLengthValidationRule(1, 250), notWhitespaceValidationRule()],
    [MedicationComponentFieldNames.dosage]: [stringLengthValidationRule(0, 250), notWhitespaceValidationRule()],
    [MedicationComponentFieldNames.instructions]: [stringLengthValidationRule(0, 250), notWhitespaceValidationRule()],
});
