import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { onEmit } from "../../common/helpers/on-emit";
import { IPaginationState } from "../../common/pagination/models/pagination-state";
import { pageSizes } from "../../common/pagination/models/page-sizes";
import { getAvailablePages } from "../../common/pagination/helpers/get-evailable-pages";
import { ISortingState } from "../../common/sorting/models/sorting-state";
import { healthReportServices } from "../../healthReport/services/healthReport.services";
import { healthReportsQuery } from "../../healthReport/stores/healthReportsStore/healthReports.query";
import { ReportsUnderReviewModel } from "../../healthReport/models/healthReport.models";
import { confirmService } from "../../../services/confirm.service";


interface ManageAllReportsState extends IPaginationState, ISortingState {
    isLoading: boolean;
    reports: ReportsUnderReviewModel[];
    isSubmitReport: boolean,
}

export function useFacade(): [
    ManageAllReportsState,
    (value: any) => void,
    () => number[],
    (page: number) => void,
    (reportId: number, patientId: number) => void
] {
    const [state, setState] = useState({
        isLoading: true,
        reports: [],
        totalCount: 0,
        selectedPage: 1,
        pageSize: pageSizes[0],
        isSubmitReport: false,
    } as ManageAllReportsState);

    const handlePublishReport = (reportId: number, patientId: number) => {
        confirmService.confirm(
            'Are you sure?',
            'This will sign the report and make it visible to the patient. Published reports cannot be updated.')
            .subscribe(
                () => {
                    healthReportServices.publish(reportId, patientId).subscribe(() => {
                        setState(state => ({ ...state, isSubmitReport: !state.isSubmitReport  }))
                    })
                }
            );
    }

    const handlePageSizeChange = (value: any) => {
        if (value === state.pageSize) {
            return;
        }

        setState(state => ({
            ...state,
            isLoading: true,
            pageSize: value,
            selectedPage: 1
        }));

        healthReportServices.getAllUnderReview(
            0,
            value,
        ).subscribe(() => setState(state => ({...state, isLoading: false})));
    }

    const handlePageChange = (page: number) => {
        if (page === state.selectedPage) {
            return;
        }

        setState(state => ({
            ...state,
            isLoading: true,
            selectedPage: page
        }));

        healthReportServices.getAllUnderReview(
            (page - 1) * state.pageSize,
            state.pageSize,
        ).subscribe(() => setState(state => ({...state, isLoading: false})));
    }

    const getAllAvailablePages = () => {
        return getAvailablePages(state);
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<ReportsUnderReviewModel[]>(healthReportsQuery.reportsUnderReview$, reports => {
                setState(state => ({
                    ...state,
                    reports: reports
                }))
            }),
            onEmit<number>(healthReportsQuery.reportsTotalCount$, reportsTotalCount => {
                if (!reportsTotalCount) {
                    healthReportServices.getNotificationAwaitingApproval().subscribe();
                }
                setState(state => ({
                    ...state,
                    totalCount: reportsTotalCount
                }))
            }),
        ];

        healthReportServices.getAllUnderReview(
            0,
            state.pageSize,
        ).subscribe(() => setState(state => ({ ...state, isLoading: false })));

        return (() => {
            subscriptions.map(it => it.unsubscribe())
        })
    }

    useEffect(useEffectCB, [state.isSubmitReport]);

    return [
        state,
        handlePageSizeChange,
        getAllAvailablePages,
        handlePageChange,
        handlePublishReport,
    ];
}