import { TextField, Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontFamily: 'Be Vietnam Pro',
            fontWeight: 500,
            fontSize: 24,
        },
        searchBox: {
            width: 'auto',
            [theme.breakpoints.up('sm')]: {
                width: 310,
            },
        },
        search: {
            borderColor: colors.gray4,
        },
        searchBase: {
            padding: '10px 14px',
            fontFamily: 'Be Vietnam Pro',
            fontSize: 14,
        },
        content: {
            minHeight: 211,

            [theme.breakpoints.up('sm')]: {
                minHeight: 459,
            },
        },
        icon: {
            color: colors.gray1,
        },
        notificationIcon: {
            fontSize: 16,
            color: colors.gray2,
            display: 'flex',
            marginTop: 'auto',
            marginBottom: 'auto',
        },
        notificationToolTipText: {
            fontFamily: 'Be Vietnam Pro',
            fontSize: '12px',
            fontWeight: 400,
        },
        totalPriceText: {
            color: colors.black,
            fontWeight: 700,
            fontSize: '22px',
            textAlign: 'right',
        },
        totalContext: {
            marginRight: '14px',
        },
        totalPriceContent: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingInline: '40px',
            minHeight: '62px',
        },
        tooltipContent: {
            display: 'flex',
            alignItems: 'center',
        },
        customWidth: {
            width: '410px',
            maxWidth: '410px',
            backgroundColor: colors.lightBlack,
            padding: '10px',
        },
        selectedText: {
            color: colors.gray1,
            fontFamily: 'Be Vietnam Pro',
            fontSize: '14px',
            fontWeight: 400,
            marginRight: '10px',
        },
        arrow: {
            color: colors.lightBlack
        },
        headerBox: {
            [theme.breakpoints.up('sm')]: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            },
        },
        menuIcon: {
          [theme.breakpoints.down("xs")]: {
            "&.MuiIconButton-root": {
              padding: 0,
              color: colors.black,
            },
          },
        },
        dropIcon: {
            color: colors.main,
            fontSize: 18,
        },
    })
);

export const SearchTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: colors.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: colors.main,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: colors.gray4,
            },
            '&:hover fieldset': {
                borderColor: colors.gray1,
            },
            '&.Mui-focused fieldset': {
                borderColor: colors.main,
            },
        },
    },
})(TextField);

export const UploadButton = withStyles((theme: Theme) => ({
    root: {
        minWidth: 34,
        width: 34,
        height: 34,
        padding: 0,
        border: 'none',
        borderRadius: 2,
        color: colors.gray1,
        backgroundColor: colors.stroke,
        '&:hover': {
            backgroundColor: colors.stroke,
        },
        '&:disabled': {
            color: colors.gray2,
            backgroundColor: colors.disabled,
            cursor: 'not-allowed',
        },
    },
}))(Button);
