import React from "react";
import { AuthenticatedLayoutComponent } from "../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent";
import { PagesNamesService } from "../../../../constants/pagesNames.constants";
import { TitlesService } from "../../../../constants/title.constants";
import { MenuItemTypes } from "../../../../models/menu.models";
import { HealthReportComponent } from "../../components/healthReportComponent/HealthReportComponent";

export const PatientHealthReportPage: React.FC = () => {
    return (
        <AuthenticatedLayoutComponent
            hideBackground
            title={TitlesService.healthReportTitle}
            pageName={PagesNamesService.healthReport}
            selectedMenuItem={MenuItemTypes.MyHealthReport}
        >
            <HealthReportComponent isReadonly/>
        </AuthenticatedLayoutComponent>
    )
}
