import {Color} from '@material-ui/lab';

export const SnackType = {
    success: 'success' as Color,
    info: 'info' as Color,
    warning: 'warning' as Color,
    error: 'error' as Color
};

export type VerPosition = 'top' | 'bottom';
export type HorPosition = 'left' | 'center' | 'right';

export interface SnackState {
    isOpen: boolean;
    messages: string[];
    type: Color;
    duration: number;
    vertical: VerPosition;
    horizontal: HorPosition;
}
