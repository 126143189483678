import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Popover,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import moment from "moment";
import React, { useState } from "react";
import Calendar from "react-calendar";
import { useStyles } from "./selectDataRangePopoverComponent.styles";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";

export const ranges = new Map<string, () => Date[]>([
  [
    "This week",
    () => [
      moment().week(moment(new Date()).week()).startOf("isoWeek").toDate(),
      moment().endOf("day").toDate(),
    ],
  ],
  [
    "Last week",
    () => {
      let week = moment(new Date()).week() - 1;
      return [
        moment().week(week).startOf("isoWeek").toDate(),
        moment().week(week).endOf("isoWeek").toDate(),
      ];
    },
  ],
  [
    "This month",
    () => [
      moment().month(moment(new Date()).month()).startOf("month").toDate(),
      moment().endOf("day").toDate(),
    ],
  ],
  [
    "Last month",
    () => {
      let month = moment(new Date()).month() - 1;
      return [
        moment().month(month).startOf("month").toDate(),
        moment().month(month).endOf("month").toDate(),
      ];
    },
  ],
  [
    "This year",
    () => [
      moment().year(moment(new Date()).year()).startOf("year").toDate(),
      moment().endOf("day").toDate(),
    ],
  ],
  [
    "Last year",
    () => {
      let year = moment(new Date()).year() - 1;
      return [
        moment().year(year).startOf("year").toDate(),
        moment().year(year).endOf("year").toDate(),
      ];
    },
  ],
]);

interface SelectDataRangePopoverComponentProps {
  isOpen: boolean;
  anchorEl: any;
  startDate: Date;
  endDate: Date;
  onChange: (dates: Array<Date>) => void;
  handleClose: () => void;
}

interface SelectDataRangePopoverComponentState {
  activeStartDate: Date;
}

export const SelectDataRangePopoverComponent: React.FC<
  SelectDataRangePopoverComponentProps
> = (props: SelectDataRangePopoverComponentProps) => {
  const { isOpen, anchorEl, startDate, endDate, onChange, handleClose } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [state, setState] = useState({
    activeStartDate: new Date(),
  } as SelectDataRangePopoverComponentState);

  const setDataRange = (dates: Date[]) => {
    if (dates.length) {
      const activeStartDate = new Date(dates[0].getFullYear(), dates[0].getMonth());
      setState((state) => ({ ...state, activeStartDate: activeStartDate }));
      onChange(dates);
    }
  };

  const handleShowPreviousMonthView = () => {
    state.activeStartDate.setMonth(state.activeStartDate.getMonth() - 1);
    setState((state) => ({ ...state }));
  };

  const handleShowNextMonthView = () => {
    state.activeStartDate.setMonth(state.activeStartDate.getMonth() + 1);
    setState((state) => ({ ...state }));
  };

  const handleCanMoveNext = () => {
    const today = new Date();
    return !(
      state.activeStartDate.getFullYear() === today.getFullYear() &&
      state.activeStartDate.getMonth() === today.getMonth()
    );
  };

  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      className={classes.root}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={handleClose}
    >
      <Box display='flex'>
        {!isSmallScreen && (
          <>
            <Box>
              <List component='nav'>
                {Array.from(ranges).map((item, index) => (
                  <ListItem
                    id='select-data-range'
                    key={index}
                    button
                    onClick={() => setDataRange(item[1]())}
                  >
                    <ListItemText primary={item[0]} />
                  </ListItem>
                ))}
              </List>
            </Box>

            <Divider className={classes.divider} />
          </>
        )}

        <Box p={1} px={2}>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Box>
              <span className={classes.dateTitle}>
                {moment(state.activeStartDate).format("MMMM yyyy")}
              </span>
            </Box>
            <Box>
              <Button
                id='select-data-range-prev-month'
                onClick={handleShowPreviousMonthView}
                className={classes.navigateButton}
              >
                <ArrowBackIosRoundedIcon className={classes.navigationIcon} />
              </Button>
              <Button
                id='select-data-range-next-month'
                disabled={!handleCanMoveNext()}
                className={classes.navigateButton}
                onClick={handleShowNextMonthView}
              >
                <ArrowForwardIosRoundedIcon className={classes.navigationIcon} />
              </Button>
            </Box>
          </Box>
          <Box maxWidth='400px'>
            <Calendar
              onChange={onChange}
              activeStartDate={state.activeStartDate}
              calendarType='US'
              value={[startDate, endDate]}
              showNavigation={false}
              maxDate={new Date()}
              selectRange
              className={classes.calander}
            />
          </Box>
        </Box>
      </Box>
    </Popover>
  );
};
