import {useEffect, useState} from 'react';
import {useHistory} from "react-router";
import {Subscription} from 'rxjs';
import {navigationService} from "../../../../services/navigation.service";
import {getLastObject} from "../../../common/helpers/get-last-object";
import {onEmit} from "../../../common/helpers/on-emit";
import {IErrorState} from "../../../common/validation/error-state";
import {LocationModel} from '../../../locations/models/locations.models';
import {locationsService} from '../../../locations/services/locations.service';
import {locationsQuery} from '../../../locations/stores';
import {PatientModel, SubmitPatientModel} from "../../../patients/models/patient.model";
import {patientsService} from "../../../patients/services/patients.service";
import {patientsQuery} from "../../../patients/stores/patientsStore";
import {EmployeeShortModel} from "../../models/employee.models";
import {employeeService} from "../../services/employees.service";
import {employeesQuery} from "../../stores/employeesStore";
import {submitPatientPageValidator} from "./submitPatientPage.validator";
import {authQuery} from "../../../auth/stores/auth";
import { DataSpecificationsEnum } from '../../../common/constants/data-specifications';

export const pageFields = [
    'firstName',
    'lastName',
    'birthday',
    'gender',
    'email',
    'phoneNumber',
    'billingAddress.streetAddress1',
    'billingAddress.streetAddress2',
    'billingAddress.city',
    'billingAddress.state',
    'billingAddress.zipCode',
    'billingAddress.country',
    'shippingAddress.streetAddress1',
    'shippingAddress.streetAddress2',
    'shippingAddress.city',
    'shippingAddress.state',
    'shippingAddress.zipCode',
    'shippingAddress.country',
    'assignedEmployeeIds'
];


/**
 * Represents Submit Patient Page state
 */
interface SubmitPatientPageState extends IErrorState {
    patient: PatientModel,
    employees: EmployeeShortModel[],
    locations: LocationModel[],
    isLoading: boolean;
}

/**
 * Custom Hook to manage a view Model for Submit patient page
 */
export function useFacade(patientId: number): [SubmitPatientPageState, Function, Function, Function, Function, Function] {
    const history = useHistory();
    const [state, setState] = useState({
        patient: null,
        employees: [],
        locations: [],
        isLoading: false,
        errors: {}
    } as SubmitPatientPageState);

    const handleEmployeeSelect = (id: number) => {
        if (state.patient.assignedEmployees.map(el => el.id).includes(id)) {
            state.patient.assignedEmployees = state.patient.assignedEmployees.filter(x => x.id !== id);
        } else {
            state.patient.assignedEmployees = [...state.patient.assignedEmployees, state.employees.find(el => el.id === id)];
        }

        setState({
            ...state,
            patient: {...state.patient}
        });

        submitPatientPageValidator.validateAndSetState(state, setState, 'assignedEmployeeIds', state.patient.assignedEmployees.map(el => el.id));
    }

    const handleOptionsChanges = (field: string, value: boolean) => {
        const patient = state.patient;
        patient.options[field] = value;
        setState({
            ...state,
            patient: {...patient}
        });
    }

    /**
     * Validates questionnaire page
     */
    const validateForm = () => {
        pageFields.forEach(field => {
            const keys = field.split(".");
            const key = keys.pop();
            const lastObject = getLastObject(keys, state.patient);
            const value = lastObject[key];
            submitPatientPageValidator.validateAndSetState(state, setState, field, value);
        });
    }

    /**
     * Handles changes. Additionally does field validation
     * @param field
     * @param value
     */
    const handleChanges = (field: string, value: string) => {
        submitPatientPageValidator.validateAndSetState(state, setState, field, value);

        const keys = field.split(".");
        const key = keys.pop();
        const lastObject = getLastObject(keys, state.patient);
        lastObject[key] = value;

        setState({...state, patient: Object.assign({}, state.patient)});
    }

    /**
     * Handles location changes
     * @param locationId
     */
    const handleLocationChanges = (locationId: number) => {
        employeeService.getActiveCoachesAndProviders(locationId).subscribe();
        setState({...state, patient: {...state.patient, locationId: locationId, assignedEmployees: []}});
    }

    const handleSubmit = () => {
        validateForm();

        if (!submitPatientPageValidator.stateIsValid(state)) {
            return;
        }

        const model: SubmitPatientModel = {
            id: state.patient.id,
            firstName: state.patient.firstName,
            lastName: state.patient.lastName,
            email: state.patient.email,
            phoneNumber: state.patient.phoneNumber,
            birthday: state.patient.birthday,
            gender: state.patient.gender,
            billingAddress: state.patient.billingAddress,
            shippingAddress: state.patient.shippingAddress,
            employeeIds: state.patient.assignedEmployees.map(el => el.id),
            options: state.patient.options,
            locationId: state.patient.locationId
        }

        setState(state => ({...state, isLoading: true}));

        patientsService.submit(model).subscribe(
            () => {
                setState(state => ({...state, isLoading: false}));
                navigationService.toIntakePatients(history);
            },
            () => {
                setState(state => ({...state, isLoading: false}));
            })
    }

    /**
     * Load all ongoing cares
     * Manage subscriptions with auto-cleanup
     */
    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<PatientModel>(patientsQuery.targetPatient$, targetPatient => {
                if (targetPatient?.id === Number(patientId)) {
                    setState(state => ({
                        ...state,
                        patient: targetPatient
                    }));

                    if (targetPatient) {
                        employeeService.getActiveCoachesAndProviders(targetPatient.locationId).subscribe();
                    }
                }
            }),
            onEmit<EmployeeShortModel[]>(employeesQuery.couchesAndProviders$, employees => {
                setState(state => ({
                    ...state,
                    employees: employees
                }));
            }),
            onEmit<LocationModel[]>(locationsQuery.availableLocations$, locations => {
                setState(state => ({
                    ...state,
                    locations: locations,
                }))
            })
        ];

        patientsService.get(patientId, DataSpecificationsEnum.SubmitPatientSpecification);
        locationsService.getAvailable(authQuery.getCurrentPracticeId()).subscribe();

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [patientId]);

    return [state, handleEmployeeSelect, handleOptionsChanges, handleChanges, handleSubmit, handleLocationChanges]
}