import { createStyles, makeStyles } from "@material-ui/core/styles";
import {colors} from "../../../../common/constants/colors";

export const useStyles = makeStyles(() =>
    createStyles({
        imageContainer: {
            height: '225px !important',
            width: '100% !important',
            borderRadius: '12px 12px 12px 12px',
            background: colors.lightGray2,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover'
        },
        category: {
            background: colors.lightGray2,
            color: colors.gray1,
            borderRadius: 6,
            fontWeight: 600,
            fontSize: 12,
            height: 28,
            padding: 4,
            paddingTop: 6
        },
        date: {
            fontSize: 12,
            color: colors.gray1,
            fontWeight: 500
        },
        title: {
            fontSize: 18,
            fontWeight: 500,
            fontFamily: "Be Vietnam Pro",
            color: colors.black
        },
        intervention: {
            whiteSpace: 'pre-line'
        }
    })
);
