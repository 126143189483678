import moment from 'moment';
import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { onEmit } from "../../../common/helpers/on-emit";
import { graphPageSizes } from "../../../common/pagination/models/page-sizes";
import { IPaginationState } from "../../../common/pagination/models/pagination-state";
import { IErrorState } from '../../../common/validation/error-state';
import { LabInputNotificationType } from "../../../inputs/models/input.models";
import { VitalModel, VitalsDateRangeType, VitalValueModel } from "../../models/vital.model";
import { vitalService } from "../../services/vital.service";
import { vitalQuery, VitalsHistoryView } from "../../stores";
import { vitalsNormalValuesValidator } from '../../validators/vital.validator';
import {getUniqDates} from "../../helpers/get-unique-dates";

interface VitalsHistoryComponentState extends IPaginationState, IErrorState {
    isLoading: boolean;
    view: VitalsHistoryView;
    range: VitalsDateRangeType;
    avg: number
    items: VitalModel[];
    vitalFromStore: VitalModel[];
    dates: Array<number>;
}

const defaultState = {
    isLoading: true,
    view: VitalsHistoryView.Graph,
    range: VitalsDateRangeType.SevenDays,
    avg: 1,
    items: [],
    vitalFromStore: [],
    totalCount: 0,
    selectedPage: 1,
    pageSize: graphPageSizes[0],
    dates: [],
} as VitalsHistoryComponentState

export function useFacade(patientId: number | null): [
    VitalsHistoryComponentState,
    Array<string>,
    (view: VitalsHistoryView) => void,
    (range: VitalsDateRangeType) => void,
    (value: any) => void,
    (page: number) => void,
] {
    const [state, setState] = useState({ ...defaultState, });

    const getEmptyValue = (date: Date, name?: string): VitalValueModel => {
        const castedDate = new Date(date);

        const emptyValue = {
            id: castedDate.getTime(),
            date: castedDate,
            value: null,
            name: name ?? '',
            notification: {
                notificationType: LabInputNotificationType.Empty,
                message: '',
            },
        } as VitalValueModel;

        return Object.assign({}, emptyValue);
    }

    const addMissingValuesToVitals = (items: VitalModel[], dates: number[]) => {
        items.forEach(item => {
            const toPush = [];

            dates.forEach(date => {
                if (!item.values.map(i => new Date(i.date).getTime()).includes(new Date(date).getTime())) {
                    toPush.push(getEmptyValue(new Date(date), item.name));
                }
            });

            item.values = [...item.values, ...toPush]
            item.values = item.values.sort((p, n) => new Date(p.date).getTime() - new Date(n.date).getTime()).reverse();
        });
        return items;
    }

    const validateValues = (items: VitalModel[]) => {
        items.forEach(vital => {
            vital.values.forEach(value => {
                if (value.value !== null) {
                    const error = vitalsNormalValuesValidator.validate(vital.name, value.value.toString());
                    if (error) {
                        value.notification = {
                            notificationType: LabInputNotificationType.Error,
                            message: error,
                        }
                    }
                }
            });
        });
    }

    const getColumns = (dates: Array<number>) => {
        const columns = ['Metric'];

        if (state.view === VitalsHistoryView.Graph) {
            columns.push('Values');
        } else {
            dates.forEach(date => columns.push(moment(date).format("MM/DD/YYYY")));
            columns.push('');
        }

        return columns;
    }

    const switchView = (view: VitalsHistoryView) => {
        setState(state => ({ ...state, view: view, pageSize: graphPageSizes[0], selectedPage: 1 }));
    }

    const handlePageSizeChange = (value: any) => {
        if (value === state.pageSize) {
            return;
        }
        setState(state => ({ ...state, pageSize: value, selectedPage: 1 }));

    }

    const handlePageChange = (page: number) => {
        if (page === state.selectedPage) {
            return;
        }
        setState(state => ({ ...state, selectedPage: page }));
    }

    const switchRange = (range: VitalsDateRangeType) => {
        switch (range) {
            case VitalsDateRangeType.SevenDays:
                setState(state => ({ ...state, avg: 1 }));
                break;
            case VitalsDateRangeType.SixMonth:
                setState(state => ({ ...state, avg: 7 }));
                break;
            case VitalsDateRangeType.ThirtyDays:
                setState(state => ({ ...state, avg: 1 }));
                break;
            case VitalsDateRangeType.Year:
                setState(state => ({ ...state, avg: 30 }));
                break;

        }
        setState(state => ({ ...state, range: range }));
        handleDateRangeChange(range)
    }

    const handleDateRangeChange = (range: VitalsDateRangeType) => {

        setState(state => ({ ...state, isLoading: true, selectedPage: 1 }));
        const cb = () => setState(state => ({ ...state, isLoading: false }));
        vitalService.getVitalAverage(patientId, range).subscribe(cb, cb);


    }

    const prepareVitals = () => {
        const vitalsCopy = JSON.parse(JSON.stringify(state.vitalFromStore));

        validateValues(vitalsCopy);
        const numStart = (state.selectedPage - 1) * state.pageSize
        const numEnd = state.selectedPage * state.pageSize
        const dates = getUniqDates(vitalsCopy);
        const datesView = dates.slice(numStart, numEnd)
        const items = addMissingValuesToVitals(vitalsCopy, dates);

        setState(state => ({
            ...state,
            dates: datesView,
            items: items,
            totalCount: dates.length,
        }))
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<VitalModel[]>(vitalQuery.vitalsAverage$, vitals => {
                setState(state => ({
                    ...state, vitalFromStore: vitals.sort((a, b) => {
                        let x = a.displayName.toLowerCase();
                        let y = b.displayName.toLowerCase();
                        
                        if(x === "blood pressure diastolic" && y === "blood pressure systolic"){
                            return 1;
                        }
                        return x < y ? -1 : x > y ? 1 : 0;
                    })
                }))
            }),
        ];
        const cb = () => setState(state => ({ ...state, isLoading: false }));
        vitalService.getVitalAverage(patientId, state.range).subscribe(cb, cb);

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    };

    useEffect(() => {
        if (state.vitalFromStore.length) {
            prepareVitals()
        }
    }, [state.vitalFromStore, state.pageSize, state.selectedPage]);

    useEffect(useEffectCB, []);

    return [
        state,
        getColumns(state.dates),
        switchView,
        switchRange,
        handlePageSizeChange,
        handlePageChange,
    ];
}