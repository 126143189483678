import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles(() =>
    createStyles({
        selectEmployeeButton: {
            border: '1px solid ' + colors.stroke,
            borderRadius: '2px',
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '22px',
            paddingRight: '22px',
            cursor: 'pointer',
            boxShadow: '0px 4px 9px rgba(168, 173, 198, 0.15)',
        },
        selectedEmployeeButton: {
            background: colors.lightGray,
            border: '1px solid ' + colors.accent1,
            borderRadius: '2px',
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '22px',
            paddingRight: '22px',
            cursor: 'pointer',
            boxShadow: '0px 4px 9px rgba(168, 173, 198, 0.15)',
        }
    })
);
