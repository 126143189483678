import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { HealthReportPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/HealthReportPlaceHolder";
import { HealthReportToolbarComponent } from "../healthReportToolbarComponent/HealthReportToolbarComponent";
import { SelectShortcutComponent } from "../selectShortcutComponent/SelectShortcutComponent";
import { useFacade } from "./healthReportComponent.hooks";
import { useStyles } from "./healthReportComponent.styles";

interface HealthReportComponentProps {
    patientId?: number | null;
    isReadonly?: boolean
}

export const HealthReportComponent: React.FC<HealthReportComponentProps> = (props: HealthReportComponentProps) => {
    HealthReportComponent.defaultProps = {
        isReadonly: false
    }
    const { patientId, isReadonly } = props;
    const [
        {
            report,
            currentPage,
            isLoading,
            practice
        },
        navigationContext,
        reportPage,
        navigationSteps,
        mobileReportPage
    ] = useFacade(patientId, isReadonly);

    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    if (isLoading) {
        return (
            <WildHealthLinearProgress />
        )
    }

    if (!report) {
        return (
            <div className={classes.root}>
                <HealthReportPlaceHolder message='You have no reports published at the moment.' />
            </div>
        )
    }

    return (
        <Box width={1}>
            <Box className={classes.toolBarSection}>
                <HealthReportToolbarComponent
                    navigationContext={navigationContext}
                    navigationSteps={navigationSteps}
                    currentPage={currentPage}
                    patientId={patientId}
                    report={report}
                    practice={practice}
                />
            </Box>

            <Box className={clsx(classes.report, 'flat-scroll')} mt={1}>
                <div id='report-start' />
                <Box>
                    {isSmallScreen ? mobileReportPage : reportPage}
                    {patientId && <SelectShortcutComponent />}
                </Box>
            </Box>
        </Box>
    )
}