import React from 'react';
import { Box } from "@material-ui/core";
import { PageSliderComponent } from '../../../common/components/PageSliderComponent';
import { GeneralValidator } from '../../../common/validation/general-validator';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { QuestionnaireModel, QuestionnaireResultModel } from '../../models/questionnaire.models';
import { QuestionnaireProgressComponent } from '../questionnaireProgressComponent/QuestionnaireProgressComponent';
import { useFacade } from './questionnaireComponent.hooks';
import { useStyles } from './QuestionnaireComponent.styles';
import { Gender } from "../../../common/models/user.models";
import { colors } from '../../../common/constants/colors';
import { WildHealthCheckbox } from '../../../common/components/WildHealthCheckbox';
import { MedicalHistoryAndLifestyleSectionNames } from '../../static/medicalHistroryQuestionnaire.static';

export interface QuestionnaireComponentProps {
    questionnaire: QuestionnaireModel;
    result: QuestionnaireResultModel;
    sections: { [key: string]: string[] };
    validator: GeneralValidator;
    gender: Gender;
    patientId?: number;
    handleGoBack?: Function;
}

export const QuestionnaireComponent: React.FC<QuestionnaireComponentProps> = (props: QuestionnaireComponentProps) => {
    const { questionnaire, result, sections, validator, gender, patientId, handleGoBack } = props;

    const [state, currentSection, getProgress, handleToggleBloodPressure] = useFacade(result, questionnaire, sections, validator, gender, patientId, handleGoBack);

    const classes = useStyles();

    const renderContent = (): JSX.Element => {
        if (state.isLoading) {
            return <WildHealthLinearProgress />
        }

        return (
            <>
                {
                    currentSection
                }
                {state.currentSection === MedicalHistoryAndLifestyleSectionNames.DEMOGRAPHICS_AND_BIOMETRICS_BLOOD_PRESSURE && <Box mt={1}>
                    <WildHealthCheckbox
                        id="have-blood-pressure-CheckBox"
                        label={`I don't have my blood pressure reading`}
                        fill={colors.lightMint}
                        checkStatus={state.hasBloodPressure}
                        handleCheck={(event) => handleToggleBloodPressure(event.target.checked)}
                    />
                </Box>}
                <Box className={classes.navigation} mt={5}>
                    <PageSliderComponent context={state.navigationContext} hideNext={state.hideNext} backCenter={state.hideNext} />
                </Box>
            </>
        )
    }

    return (
        <Box display="flex" justifyContent="center" className={classes.main}>
            <Box className={classes.content}>
                <Box mb={5}>
                    <QuestionnaireProgressComponent
                        progress={getProgress()}
                        timeLabel='15 min'
                    />
                </Box>
                {
                    renderContent()
                }
            </Box>
        </Box>
    )
}