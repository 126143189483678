import Axios from "axios-observable";
import {authHeader} from "../../common/helpers/auth-header";
import {healthReportsStore, HealthReportsStore} from "../stores/healthReportsStore/healthReports.store"
import {snackService} from "../../common/snack/state";
import {Observable} from "rxjs";
import { HealthReportModel } from "../models/healthReport.models";

export class PatientHealthReportServices {
    private url = `${process.env.REACT_APP_API_URL}PatientHealthReport`;

    constructor(private healthReportsStore: HealthReportsStore) {
    }

    /**
     * Returns patient report
     */
    public getLast(): Observable<HealthReportModel>{
        return new Observable(observer => {
            Axios.get(`${this.url}/latest`, {
                headers: authHeader()
            })
                .pipe()
                .subscribe(
                    (response) => {
                        this.healthReportsStore.update({report: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.next(error);
                        observer.error();
                    }
                );
        });
    }

    public getById(reportId: number): void {
        Axios.get(`${this.url}/${reportId}`, {
            headers: authHeader()
        })
            .pipe()
            .subscribe(
                (response) => {
                    this.healthReportsStore.update({report: response.data});
                },
                error => snackService.commonErrorHandler(error)
            );
    }

    public getAll(): void {
        Axios.get(this.url, {
            headers: authHeader()
        })
            .pipe()
            .subscribe(
                (response) => {
                    this.healthReportsStore.update({reports: response.data});
                },
                error => snackService.commonErrorHandler(error)
            );
    }

    public generate(): Observable<any> {
        return new Observable(observer => {
            Axios.post(this.url, {}, {
                headers: authHeader()
            })
                .pipe()
                .subscribe(
                    (response) => {
                        this.healthReportsStore.update({report: response.data});
                        snackService.success('Report generated successfully!');
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.next();
                        observer.error();
                    }
                );
        })
    }
}

export const patientHealthReportService = new PatientHealthReportServices(healthReportsStore);
