import { useState } from "react";
import { HabitBiomarkersModel } from '../../healthScore/models/score.model';
export interface PatientDashboardPageState {
    isLoading: boolean,
    generalScore: HabitBiomarkersModel[],
}

export function useFacade(): [
    PatientDashboardPageState
] {
    const [state] = useState({
        isLoading: false,
        generalScore: [],
    } as PatientDashboardPageState);

    return [state];
}