import { List, ListItem, ListItemText, Box, Button, Popover } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Subscription } from 'recompose';
import { MenuItem, MenuItemTypes } from '../../models/menu.models';
import { onEmit } from '../../modules/common/helpers/on-emit';
import { applicationQuery } from "../../stores/applicationStore";
import { User } from "../../modules/common/models/user.models";
import { patientsService } from '../../modules/patients/services/patients.service';
import { navigationService } from "../../services/navigation.service";

const menuPopoverProps: any = {
  id: "menuPopover",
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  }
};

interface CollapsibleMenuItem extends MenuItem {
  isOpened: boolean;
  anchorEl: HTMLButtonElement;
}

/**
 * Represents for Side Bar Component state
 */
interface NavigationComponentState {
  menus: CollapsibleMenuItem[];
  isUsersLoading: boolean;
  users: User[];
  selectedUser: User;
}

/**
 * Custom Hook to manage a view Model for Side Bar component
 */
export function useFacade(selectedMenuItem: MenuItemTypes, classes: any, commonClasses: any): [
  NavigationComponentState,
  () => JSX.Element[],
  (id: number) => void,
  (searchQuery: string) => void
] {
  const [state, setState] = useState({
    menus: [],
    isUsersLoading: false,
    users: [],
    selectedUser: null,
  } as NavigationComponentState);

  const history = useHistory();

  const handleMenuItemToggle = (item: CollapsibleMenuItem, event?: React.MouseEvent<HTMLButtonElement>) => {
    item.isOpened = !item.isOpened;
    item.anchorEl = event && event.currentTarget ? event.currentTarget : null;
    setState(state => ({ ...state }));
  }

  const renderChildMenuItems = (item: CollapsibleMenuItem, key: string) => {
    const action = (child: MenuItem, event?: React.MouseEvent<HTMLButtonElement>) => {
      item.isOpened = !item.isOpened;
      item.anchorEl = event && event.currentTarget ? event.currentTarget : null;
      setState(state => ({ ...state }));
      child.action(history);
    };

    return (
      <Popover key={key}
        id={`header-nav-menu-toggle-${key}`}
        anchorEl={item.anchorEl}
        onClose={() => handleMenuItemToggle(item)}
        open={item.isOpened || false}
        anchorOrigin={menuPopoverProps.anchorOrigin}
        transformOrigin={menuPopoverProps.transformOrigin}
      >
        <Box width="220px">
          <List component="nav" aria-label="main mailbox folders">
            {
              item.children.map((child, index) => {
                const isSelected = child.type === selectedMenuItem;

                return (
                  <ListItem
                    id={`header-navbar-${child.title}`}
                    button
                    key={index}
                    disabled={child.disabled}
                    className={clsx(classes.menuItem, {
                      [classes.menuItemSelected]: isSelected && !child.disabled,
                    })}
                    onClick={() => action(child)}
                  >
                    <ListItemText>
                      <Box position="relative">
                        {child.title}
                        <Box position="absolute" top={-20} right={12}>
                          {child.badge}
                        </Box>
                      </Box>
                    </ListItemText>
                  </ListItem>
                )
              })
            }
          </List>
        </Box>
      </Popover>
    )
  }

  const menuItemsToIgnore = [
    MenuItemTypes.Profile,
    MenuItemTypes.GenericPlayground
  ];

  const renderMenuItems = () => {
    const menuItems: JSX.Element[] = [];

    state.menus.forEach((item, index) => {
      // Include some menu items for full size nav menu
      // Because they show as individual elements
      if (menuItemsToIgnore.includes(item.type)) {
        return;
      }

      let isSelected = item.type === selectedMenuItem;
      const hasChildren = item.children?.length > 0;
      const action = hasChildren ?
        (event?: React.MouseEvent<HTMLButtonElement>) => {
          handleMenuItemToggle(item, event);
        } :
        () => {
          item.action(history);
        };

      if (hasChildren && !isSelected) {
        isSelected = item.children.find(i => i.type === selectedMenuItem) != null;
      }

      const listItem = (
        <Button
          id={`header-nav-button-${index}`}
          key={`header-nav-button-${index}`}
          aria-describedby={`header-nav-button=${index}`}
          className={isSelected ? classes.selectedButton : classes.iconButton}
          onClick={(event) => action(event)}
          disabled={item.disabled}
          color="inherit"
        >
          <Box display={'flex'} position={'relative'} className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.nowrap)}>
            {item.title}
            {item.badge && (
              <Box className={clsx(classes.menuIcon, classes.menuIconSize, classes.badge)}>
                {item.badge}
              </Box>
            )}
          </Box>
          {
            hasChildren ? item.isOpened ? <ExpandLessIcon className={classes.expandIcon} /> : <ExpandMoreIcon className={classes.expandIcon} /> : <></>
          }
        </Button>
      );

      menuItems.push(listItem);

      if (hasChildren)
        menuItems.push(renderChildMenuItems(item, `${index}-children`));
    });

    return menuItems;
  }

  const handleUserSelect = (id: number) => {
    const user = state.users.find(i => i.id === id);
    if (user) {
      navigationService.toNewTabManagePatientProfile(id);
    }
  }

  const handleUserSearch = (searchQuery: string) => {
    setState(state => ({ ...state, isUsersLoading: true }));

    const successCB = (employees) => setState(state => ({ ...state, isUsersLoading: false, users: employees }));
    const errorCB = () => setState(state => ({ ...state, isUsersLoading: false }));

    patientsService.getAll([], [], [], [], [], [], searchQuery, 0, 10).subscribe(successCB, errorCB);
  }

  useEffect(() => {
    const subscriptions: Subscription[] = [
      onEmit<MenuItem[]>(applicationQuery.menuItems$, menus => {
        setState(state => ({ ...state, menus: menus.map(i => i as CollapsibleMenuItem) }));
      }),
    ];

    return () => {
      subscriptions.map(it => it.unsubscribe())
    };
  }, []);

  return [state, renderMenuItems, handleUserSelect, handleUserSearch];
}