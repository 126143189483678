
import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { onEmit } from "../../../common/helpers/on-emit";
import { FellowModel } from "../../../employee/models/employee.models";
import { employeeService } from '../../../employee/services/employees.service';
import { employeesQuery } from '../../../employee/stores/employeesStore';
import { confirmService } from '../../../../services/confirm.service';
import { authQuery } from '../../../auth/stores/auth';
import { useHistory } from "react-router";
import { navigationService } from "../../../../services/navigation.service";
import { rostersQuery } from "../../../rosters/stores/rosters/rosters.query";
import { rostersService } from "../../../rosters/services/rosters.service";

export interface FellowsComponentState {
  fellows: FellowModel[];
  isLoading: boolean;
  isEditOpen: boolean;
  isFellowActionsOpen: boolean;
  fellowActionsAnchorEl: HTMLButtonElement;
  selectedFellow: FellowModel;
  currentEmployeeEmail: string;
  selfManagingEmployee: boolean;
  isRosterActive: boolean;
}

export function useFacade(rosterId: number): [
  FellowsComponentState,
  () => void,
  (value: boolean, fellowId: number, email: string, target: HTMLButtonElement) => void,
  (value: boolean) => void,
  () => void,
  (fellowId: number) => void,
] {
  const [state, setState] = useState({
    fellows: [],
    isLoading: true,
    isEditOpen: false,
    isFellowActionsOpen: false,
    fellowActionsAnchorEl: null,
    selectedFellow: null,
    currentEmployeeEmail: authQuery.getEmail(),
    selfManagingEmployee: true,
    isRosterActive: false,
  } as FellowsComponentState);

  const history = useHistory();

  const handleGoToFellowPatients = (fellowId: number) => {
      navigationService.toFellowPatients(history, fellowId, rosterId);
  }

  const handleToggleFellowActions = (value: boolean, fellowId: number, email: string, target: HTMLButtonElement) => {
    const fellow = state.fellows.find(f => f.id === fellowId);
    setState(state => ({
      ...state,
      isFellowActionsOpen: value,
      fellowActionsAnchorEl: target,
      selectedFellow: fellow,
      selfManagingEmployee: state.currentEmployeeEmail === email,
    }));
  }

  const handleToggleEditFellow = (value: boolean) => {
    setState(state => ({
      ...state,
      isEditOpen: value,
      isFellowActionsOpen: false,
      fellowActionsAnchorEl: null
    }))
  }

  const handleDeleteFellow = () => {
    setState(state => ({
      ...state,
      isFellowActionsOpen: false,
      fellowActionsAnchorEl: null
    }));

    confirmService.confirm(
      'Delete Fellow',
      'Are you sure you want to delete this fellow from the roster?',
      'Yes',
      'Cancel',
      'danger')
      .subscribe(() => {
        employeeService.deleteFellow(state.selectedFellow.id);
      });
  }

  const handleIsRosterActive = () => {
    if (state.isRosterActive) {
      rostersService.deactivate(rosterId).subscribe(
        () => {
          setState({ ...state, isRosterActive: false })
        }
      )
    } else {
      rostersService.activate(rosterId).subscribe(
        () => {
          setState({ ...state, isRosterActive: true })
        }
      )
    }
  }

  useEffect(() => {
    const subscriptions: Subscription[] = [
      onEmit<FellowModel[]>(employeesQuery.fellows$, fellows => {
        setState(state => ({
          ...state,
          fellows: fellows,
        }));
      }),
    ];
    setState({ ...state, fellows: [], isLoading: true })

    const cb = () => setState(state => ({ ...state, isLoading: false, isRosterActive: rostersQuery.isActive(rosterId) }))
    employeeService.getFellowsByRoster(rosterId).subscribe(cb, cb);

    return () => {
      subscriptions.map(it => it.unsubscribe())
    };
  }, [rosterId])

  return [state, handleIsRosterActive, handleToggleFellowActions, handleToggleEditFellow, handleDeleteFellow, handleGoToFellowPatients]
}