import React from 'react';
import { withRouter } from 'react-router';
import { AuthenticatedLayoutComponent } from '../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from "../../../../models/menu.models";
import { ManageApiResponseComponent } from '../../components/manageApiResponseComponent/ManageApiResponseComponent';

class EngineeringAdminPage extends React.Component<any> {
  render() {
    return (
      <AuthenticatedLayoutComponent
        hideBackground
        title={TitlesService.engineeringAdminTitle}
        pageName={PagesNamesService.engineeringAdminTool}
        selectedMenuItem={MenuItemTypes.EngineeringAdminTool}
      >
        <ManageApiResponseComponent />
      </AuthenticatedLayoutComponent>
    )
  }
}

export default withRouter(EngineeringAdminPage);
