import { ListItemText, IconButton } from '@material-ui/core';
import { createStyles, makeStyles, withStyles, Theme } from '@material-ui/core/styles';
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        myMessages: {
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
        },
        messages: {
            alignItems: "flex-end",
        },
        messagesBlock: {
            maxWidth: '65%',
            [theme.breakpoints.down("xs")]: {
                minWidth: '305px',
            },
        },
        compactMessagesBlock: {
            maxWidth: '75%',
            display: 'inline-block',
            wordBreak: 'break-word',
        },
        messageFileButton: {
            "& $fileIcon": {
                color: colors.main,
            },
            "& $downloadIcon": {
                color: colors.main,
            },
        },
        myMessageFileButton: {
            "& $fileIcon": {
                color: colors.white,
            },
            "& $downloadIcon": {
                color: colors.white,
            },
        },
        fileButton: {
            "&:hover": {
                "& $fileIcon": {
                    display: "none",
                },
                "& $downloadIcon": {
                    display: "inline-block",
                },
            },
        },
        fileIcon: {
            display: 'inline-block',
        },
        downloadIcon: {
            display: 'none',
        },
        messageLinkUrl: {
            display: 'inline-block',
            wordBreak: 'break-word',
            color: colors.main,
            textDecoration: 'underline',
            "&:hover": {
                color: colors.main,
            },
        },
        myMessageLinkUrl: {
            display: 'inline-block',
            wordBreak: 'break-word',
            color: colors.mint700,
            textDecoration: 'underline',
            "&:hover": {
                color: colors.mint900,
            },
        },
        breakText: {
            display: 'inline-block',
            wordBreak: 'break-word',
        },
        messageAuthor:{
            color: colors.gray1,
            fontWeight: 500,
            fontSize: 12,
            margin: '0 15px',
        },
        messageView: {
            whiteSpace: 'pre-line', 
            display: 'inline-block',
            wordBreak: 'break-word',
        },
        dialogTitle: {
            padding: 30,
            paddingLeft: 40,
            paddingRight: 40,
        },
        dialogTitleCloseAction: {
            position: 'absolute',
            right: 20,
            top: 20,
            color: theme.palette.grey[500],
        },
        dialogContent : {
            paddingTop: 0,
            paddingBottom: 30,
            paddingLeft: 40,
            paddingRight: 40,
        },
        dialogActions: {
            paddingTop: 0,
            paddingBottom: 30,
            paddingLeft: 40,
            paddingRight: 40,
        },
        notificationToolTipText: {
            fontFamily: 'Be Vietnam Pro',
            fontSize: 12,
            fontWeight: 400,
            color: colors.white,
        },
        arrow: {
            color: colors.lightBlack,
        },
        customWidth: {
            width: '280px',
            maxWidth: '280px',
            backgroundColor: colors.lightBlack,
            padding: 10,
        },
        tooltipPlacement: {
            margin: '8px 0',
        },
    }
));

export const MyMessage = withStyles({
    root: {
        margin: '0 15px',
        display: 'grid',
        justifyContent: 'flex-end',
        "& .MuiListItemText-primary": {
            padding: 10,
            color: colors.black,
            background: colors.mint100,
            borderRadius: "6px 6px 0px 6px"
        },
        "& .MuiListItemText-secondary": {
            margin: '5px 0',
            display: 'flex',
            justifyContent: 'flex-end',
        },
    }
})(ListItemText);

export const MyCompactMessage = withStyles({
    root: {
        margin: '0 5px',
        display: 'grid',
        justifyContent: 'flex-end',
        "& .MuiListItemText-primary": {
            padding: 5,
            color: colors.black,
            background: colors.mint100,
            borderRadius: "6px 6px 0px 6px"
        },
        "& .MuiListItemText-secondary": {
            margin: '5px 0',
            display: 'flex',
            justifyContent: 'flex-end',
        },
    }
})(ListItemText);

export const MyDeletedMessage = withStyles({
    root: {
        margin: '0 15px',
        display: 'grid',
        justifyContent: 'flex-end',
        "& .MuiListItemText-primary": {
            padding: 10,
            color: colors.black,
            background: colors.stroke,
            borderRadius: "6px 6px 0px 6px"
        },
        "& .MuiListItemText-secondary": {
            margin: '5px 0',
            display: 'flex',
            justifyContent: 'flex-end',
        },
    }
})(ListItemText);

export const MyDeletedCompactMessage = withStyles({
    root: {
        margin: '0 5px',
        display: 'grid',
        justifyContent: 'flex-end',
        "& .MuiListItemText-primary": {
            padding: 5,
            color: colors.black,
            background: colors.stroke,
            borderRadius: "6px 6px 0px 6px"
        },
        "& .MuiListItemText-secondary": {
            margin: '5px 0',
            display: 'flex',
            justifyContent: 'flex-end',
        },
    }
})(ListItemText);

export const Message = withStyles({
    root: {
        margin: '0 15px',
        display: 'grid',
        justifyContent: 'flex-start',
        "& .MuiListItemText-primary": {
            padding: 10,
            border: `1px solid ${colors.stroke}`,
            color: colors.black,
            background: colors.gray100,
            boxShadow: "0px 4px 9px rgba(168, 173, 198, 0.15)",
            borderRadius: "6px 6px 6px 0px"
        },
        "& .MuiListItemText-secondary": {
            margin: '5px 0',
            display: 'flex',
            justifyContent: 'flex-start',
        },
    }
})(ListItemText);

export const CompactMessage = withStyles({
    root: {
        margin: '0 5px',
        display: 'grid',
        justifyContent: 'flex-start',
        "& .MuiListItemText-primary": {
            padding: 5,
            border: `1px solid ${colors.stroke}`,
            color: colors.black,
            background: colors.gray100,
            boxShadow: "0px 4px 9px rgba(168, 173, 198, 0.15)",
            borderRadius: "6px 6px 6px 0px"
        },
        "& .MuiListItemText-secondary": {
            margin: '5px 0',
            display: 'flex',
            justifyContent: 'flex-start',
        },
    }
})(ListItemText);

export const DeletedMessage = withStyles({
    root: {
        margin: '0 15px',
        display: 'grid',
        justifyContent: 'flex-start',
        "& .MuiListItemText-primary": {
            padding: 10,
            border: `1px solid ${colors.stroke}`,
            background: colors.stroke,
            boxShadow: "0px 4px 9px rgba(168, 173, 198, 0.15)",
            borderRadius: "6px 6px 6px 0px"
        },
        "& .MuiListItemText-secondary": {
            margin: '5px 0',
            display: 'flex',
            justifyContent: 'flex-start',
        },
    }
})(ListItemText);

export const DeletedCompactMessage = withStyles({
    root: {
        margin: '0 5px',
        display: 'grid',
        justifyContent: 'flex-start',
        "& .MuiListItemText-primary": {
            padding: 5,
            border: `1px solid ${colors.stroke}`,
            background: colors.stroke,
            boxShadow: "0px 4px 9px rgba(168, 173, 198, 0.15)",
            borderRadius: "6px 6px 6px 0px"
        },
        "& .MuiListItemText-secondary": {
            margin: '5px 0',
            display: 'flex',
            justifyContent: 'flex-start',
        },
    }
})(ListItemText);

export const DiscardButton = withStyles((theme: Theme) => ({
    root: {
        fill: 'currentColor',
        color: colors.gray1,
        background: colors.gray4,
        width: 42,
        height: 42,
        '&:hover': {
            color: `${colors.gray1}CC`,
            background: `${colors.gray4}CC`,
        },
        '&:disabled': {
            color: `${colors.gray1}99`,
            background: colors.disabled,
        },
    },
}))(IconButton);

export const ConfirmButton = withStyles((theme: Theme) => ({
    root: {
        fill: 'currentColor',
        color: colors.white,
        background: colors.main,
        width: 42,
        height: 42,
        '&:hover': {
            color: `${colors.white}CC`,
            background: `${colors.main}CC`,
        },
        '&:disabled': {
            color: `${colors.gray1}99`,
            background: colors.disabled,
        },
    },
}))(IconButton);

