import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontSize: '1.6rem',
            color: '#292929 !important'
        },
        sectionTitle: {
            fontFamily: 'SFProText',
            maxWidth: 500,
            fontSize: 22,
            fontWeight: 500,
            lineHeight: 1.2,
        },
        colorMain: {
            color: colors.main
        }
    })
);
