import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import {WildHealthPlaceHolder} from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import _ from 'lodash';
import {Box, Table, TableBody, TableContainer, TableFooter, TableHead, TableSortLabel} from "@material-ui/core";

import {WildHealthTableRow} from "../../../common/components/wildHealthTable/WildHealthTableRow";
import {WildHealthTableCell} from "../../../common/components/wildHealthTable/WildHealthTableCell";
import moment from "moment";
import {authQuery} from "../../../auth/stores/auth";
import {PaginationComponent} from "../../../common/pagination/components/paginationComponent/PaginationComponent";
import {pageSizes} from "../../../common/pagination/models/page-sizes";
import React from "react";
import {useFacade} from "./manageDraftNotesComponent.hooks";

import {WildHealthTrashIcon} from "../../../common/components/wildHealthTrashIcon/WildHealthTrashIcon";
import {ManageNotesComponentProps} from "../manageNotes/ManageNotesComponent";
import commonUseStyles from "../../../common/styles/common.styles";
import { SortingDirection } from "../../../common/sorting/models/sorting-destination";

export const ManageDraftNotesComponent: React.FC<ManageNotesComponentProps> = (props: ManageNotesComponentProps) => {

    const [{
        isLoading,
        draftNotes,
        totalCount,
        sortingColumns,
        sortingSource,
        pageSize,
        selectedPage
    },
        handleSorting,
        handlePageSizeChange,
        getAllAvailablePages,
        handlePageChange,
        handleDeleteDraftNote,
        handleViewDetails
    ] = useFacade();

    const classes = commonUseStyles();

    const renderTable = () => {
        if (isLoading) {
            return <WildHealthLinearProgress/>
        }

        if (!_.size(draftNotes)) {
            return <WildHealthPlaceHolder message="No current records found"/>
        }

        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <WildHealthTableRow>
                            <WildHealthTableCell style={{width: '30%'}} key={'patientName'}> Patient
                            Name
                            </WildHealthTableCell>
                            {
                                sortingColumns.map((item, index) =>
                                    <WildHealthTableCell style={{width: '30%'}} key={index}>
                                        {item.source
                                            ? <TableSortLabel
                                                id={`manage-all-notes-${item.title}`}
                                                active={sortingSource === item.source}
                                                direction={sortingSource === item.source ? item.direction : SortingDirection.Asc}
                                                onClick={() => handleSorting(item.source, item.direction)}
                                            >
                                                {item.title}
                                            </TableSortLabel>
                                            : item.title
                                        }
                                    </WildHealthTableCell>
                                )
                            }
                        </WildHealthTableRow>
                    </TableHead>
                    <TableBody>
                        {
                            draftNotes.map((note, index) =>
                                <WildHealthTableRow key={index} >
                                    <WildHealthTableCell onClick={()=>handleViewDetails(note)} className={classes.pointer}>
                                        {`${note.owner.firstName} ${note.owner.lastName}`}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell>
                                        {note.noteTypeName}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell>
                                        {moment(note.visitDate).format('LL')}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell>
                                        <WildHealthTrashIcon handleOnClick={()=>handleDeleteDraftNote(note)} />
                                    </WildHealthTableCell>
                                </WildHealthTableRow>
                            )
                        }
                    </TableBody>
                    <TableFooter>
                        <WildHealthTableRow style={{backgroundColor: "white"}}>
                            <WildHealthTableCell colSpan={authQuery.isLicensingPractice() ? 7 : 9}>
                                <PaginationComponent
                                    pageSizes={pageSizes}
                                    pageSize={pageSize}
                                    selectedPage={selectedPage}
                                    handlePageSizeChange={handlePageSizeChange}
                                    totalCount={totalCount}
                                    availablePages={getAllAvailablePages()}
                                    handlePageSelect={handlePageChange}/>
                            </WildHealthTableCell>
                        </WildHealthTableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        )
    }

    return (
        <Box p={5}>
            {
                renderTable()
            }
        </Box>
    )
}

