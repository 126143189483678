import { StepConnector, withStyles } from "@material-ui/core";

export const WildHealthVerticalStepperConnector = withStyles({
    vertical: {
        marginLeft: 7,
    },
    active: {
        "& $line": {
            borderColor: "#AFBACA"
        }
    },
    completed: {
        "& $line": {
            borderColor: "#AFBACA"
        }
    },
    line: {
        borderColor: "#AFBACA",
        borderTopWidth: 3,
        borderRadius: 1
    }
})(StepConnector);

export const WildHealthHorizontalStepperConnector = withStyles({
    alternativeLabel: {
        top: 6,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        "& $line": {
            borderColor: "#AFBACA"
        }
    },
    completed: {
        "& $line": {
            borderColor: "#AFBACA"
        }
    },
    line: {
        borderColor: "#AFBACA",
        borderTopWidth: 1,
        borderRadius: 1
    }
})(StepConnector);