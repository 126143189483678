import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { onEmit } from "../../../common/helpers/on-emit";
import { healthReportsQuery } from "../../stores/healthReportsStore";
import {notesQuery} from "../../../notes/stores/notes";

interface HealthReportBadgeComponentState {
    reportsNotifications: boolean;
    notesNotifications: boolean;
}

export function useFacade(type: "notes" | "reports" | null): [boolean] {
    const [state, setState] = useState({
        reportsNotifications: false,
        notesNotifications: false
    } as HealthReportBadgeComponentState);

    const any = () => {
        switch (type) {
            case "notes": return state.notesNotifications;
            case "reports": return state.reportsNotifications;
            default: return state.notesNotifications || state.reportsNotifications;
        }
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<boolean>(healthReportsQuery.notificationAwaitingApproval$, notification => {
                setState((state) => ({
                    ...state,
                    reportsNotifications: notification
                }));
            }),
            onEmit<boolean>(notesQuery.notificationAwaitingApproval$, notification => {
                setState((state) => ({
                    ...state,
                    notesNotifications: notification
                }));
            }),
        ];

        return (() => {
            subscriptions.map(it => it.unsubscribe())
        })
    }, []);

    return [any()];
}