import {authQuery} from "../../auth/stores/auth";
import {defaultDosage} from "../constants/medicationsAndSupplementsContants";

export enum SupplementSource {
    Supplement = 0,
    Intake = 1,
    Notes = 2,
    Fullscript = 3
}

export interface PatientSupplementModel {
    id: number;
    name: string;
    dosage: string;
    source: SupplementSource;
    instructions: string;
    purchaseLink: string;
    orderedAt: Date | null;
    createdAt: Date | null;
}

export const newPatientSupplementModel = (source: SupplementSource) : PatientSupplementModel => ({
    id: 0,
    name: '',
    dosage: '',
    source: source,
    instructions: authQuery.isEmployeeUser() ? defaultDosage : '',
    purchaseLink: '',
    orderedAt: null,
    createdAt: null
})

export const supplementSources = new Map<SupplementSource, string>([
    [SupplementSource.Supplement, 'Supplement'],
    [SupplementSource.Intake, 'Intake'],
    [SupplementSource.Notes, 'Notes'],
    [SupplementSource.Fullscript, 'Fullscript']
]);