import { Box, Collapse, IconButton, Step, StepLabel, Stepper, Table, TableBody, TableContainer, TableHead, TableRow, Tooltip, useMediaQuery, useTheme, TextField, Dialog, DialogContent } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import moment from 'moment';
import React from 'react';
import { WildHealthHeaderComponent } from '../../../common/components/wildHealthHeaderComponent/WildHealthHeaderComponent';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { WildHealthExternalLinkButton } from '../../../common/components/WildHealthLinkButton';
import { CommonPlaceHolder } from '../../../common/components/wildHealthPlaceHolder/CommonPlaceHolder';
import { WildHealthStepIcon } from '../../../common/components/wildHealthStepper/WildHealthStepIcon';
import { WildHealthHorizontalStepperConnector, WildHealthVerticalStepperConnector } from '../../../common/components/wildHealthStepper/WildHealthStepperConnector';
import { WildHealthTableCell } from '../../../common/components/wildHealthTable/WildHealthTableCell';
import { WildHealthTableRow } from '../../../common/components/wildHealthTable/WildHealthTableRow';
import { orderStatusIndexes, shortOrderStatusNames, orderStatusNames, OrderStatus } from '../../models/orders.models';
import { useFacade } from './dnaOrdersComponent.hooks';
import { useStyles } from './dnaOrdersComponent.styles';
import Zoom from '@material-ui/core/Zoom';
import { WildHealthStatusLabel } from '../../../common/components/wildHealthStatusLabel/WildHealthStatusLabel';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import { ProtectedElement } from "../../../common/components/ProtectedElement";
import { PermissionType, UserType } from "../../../auth/models/auth.enums";
import { authQuery } from '../../../auth/stores/auth';
import { toCurrentTimeZone } from '../../../timezones/helpers/timezone';
import commonUseStyles from "../../../common/styles/common.styles";
import Cancel from "@img/icons/cancel.svg";
import clsx from 'clsx';
import { AddOnModel } from '../../../addons/models/addOns.models';
import { CreateOrderModel } from '../../models/orders.models';
import { NewDnaOrderDialog } from '../newDnaOrderDialogComponent/NewDnaOrderDialogComponent';

interface DnaOrdersComponentProps {
    isLoadingNewKit?: boolean;
    isNewOrderDialogOpen?: boolean;
    patientId?: number;
    addOns?: AddOnModel[];
    handleNavigateToReplacement?: () => void;
    handleOrderKit?: (model: CreateOrderModel) => void;
    handleToggleNewOrderDialog?: Function;
}

export const DnaOrdersComponent: React.FC<DnaOrdersComponentProps> = (props: DnaOrdersComponentProps) => {
    const { patientId, addOns, handleNavigateToReplacement, handleOrderKit, handleToggleNewOrderDialog } = props;
    const commonClasses = commonUseStyles();
    const classes = useStyles();

    const [
        {
            isLoading,
            orders,
            selectedOrder,
            isOpened,
        },
        columns,
        handleToggleOrderDetails,
        handleToggleEditOrder,
        handleChange,
        handleUpdateOrder
    ] = useFacade(patientId);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const orderManageSectionWithTypes: Array<PermissionType> = [PermissionType.Coaching, PermissionType.ManagePatients];
    const canEdit = (permissions: PermissionType[], userType: UserType): boolean => {
        switch (userType) {
            case UserType.Patient: return authQuery.getType() === UserType.Patient;
            case UserType.Employee: return permissions.length
                ? authQuery.getPermissions().some(p => permissions.includes(p))
                : authQuery.getType() === UserType.Employee;
            default: return false;
        }
    }

    const title = 'DNA Orders';

    const emptyPlaceHolder = () => {
        return <span className="text-accent-secondary">(empty)</span>
    }

    const displayDataValue = (value: Date) => {
        return value ? moment(toCurrentTimeZone(value)).format("MM/DD/yyyy") : emptyPlaceHolder();
    }

    const displayValue = (value: string | number) => {
        return value ?? emptyPlaceHolder();
    }

    const displayUpsLink = (value: string) => {
        if (value) {
            return (
                <Tooltip
                    title="Click here to track"
                    TransitionComponent={Zoom}
                    placement="top"
                    leaveDelay={500}
                    arrow
                >
                    <span>
                        <WildHealthExternalLinkButton id={`dna-orders-tracking`} link={`https://www.ups.com/track?loc=en_US&tracknum=${value}&requester=ST/`} >
                            <span className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorMain)}>#{value}</span>
                        </WildHealthExternalLinkButton>
                    </span>
                </Tooltip>
            )
        } else return emptyPlaceHolder();
    }

    const displayUspsLink = (value: string) => {
        if (value) {
            return (
                <Tooltip
                    title="Click here to track"
                    TransitionComponent={Zoom}
                    placement="top"
                    leaveDelay={500}
                    arrow
                >
                    <span>
                        <WildHealthExternalLinkButton id={`dna-orders-usps-tracking`} link={`https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLc=2&text28777=&tLabels=${value}%2C&tABt=false`} >
                            <span className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorMain)}>#{value}</span>
                        </WildHealthExternalLinkButton>
                    </span>
                </Tooltip>
            )
        } else return emptyPlaceHolder();
    }

    const mapOrders = () => {
        const rows = [];

        orders.forEach((item, index) => {
            rows.push(
                <WildHealthTableRow key={`dna-row-${index}`}>
                    <WildHealthTableCell align="left">
                        <Box display='flex' alignItems='center'>
                            <IconButton id={`dna-orders-details-${item.id}`} onClick={() => handleToggleOrderDetails(item.id)}>
                                {item.isOpen ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                            <Box ml={1}>
                                {displayDataValue(item.orderedAt)}
                            </Box>
                        </Box>
                    </WildHealthTableCell>
                    <WildHealthTableCell align="left">
                        <WildHealthStatusLabel>
                            {orderStatusNames.get(item.status)}
                        </WildHealthStatusLabel>
                    </WildHealthTableCell>
                    <WildHealthTableCell align="left">
                        {displayValue(item.number)}
                    </WildHealthTableCell>
                    <WildHealthTableCell align="left">
                        {item.isEdit ? (
                            <TextField
                                id={`barcode`}
                                variant="outlined"
                                size="small"
                                value={item.barcode ?? ''}
                                onChange={(v) => handleChange(item.id, v.target.id, v.target.value)}
                                InputProps={{ className: 'input' }}
                            />) : displayValue(item.barcode)
                        }
                    </WildHealthTableCell>
                    <WildHealthTableCell align="left">
                        {item.isEdit ? (
                            <TextField
                                id={`patientShippingNumber`}
                                variant="outlined"
                                size="small"
                                value={item.patientShippingNumber ?? ''}
                                onChange={(v) => handleChange(item.id, v.target.id, v.target.value)}
                                InputProps={{ className: 'input' }}
                            />) : displayUpsLink(item.patientShippingNumber)
                        }
                    </WildHealthTableCell>
                    <WildHealthTableCell align="left">
                        {item.isEdit ? (
                            <TextField
                                id={`laboratoryShippingNumber`}
                                variant="outlined"
                                size="small"
                                value={item.laboratoryShippingNumber ?? ''}
                                onChange={(v) => handleChange(item.id, v.target.id, v.target.value)}
                                InputProps={{ className: 'input' }}
                            />) : displayUspsLink(item.laboratoryShippingNumber)
                        }
                    </WildHealthTableCell>
                    {canEdit(orderManageSectionWithTypes, UserType.Employee) && (
                        <WildHealthTableCell align="left">
                            {item.isEdit ? (
                                <WildHealthButton id={`dna-orders-view-${item.id}`} color={"secondary"} loading={item.isUpdating} onClick={() => handleUpdateOrder(item.id)}>
                                    Update
                                </WildHealthButton>
                            ) : (
                                <WildHealthButton id={`dna-orders-edit-${item.id}`} color={"secondary"} onClick={() => handleToggleEditOrder(item.id)}>
                                    Edit
                                </WildHealthButton>
                            )}
                        </WildHealthTableCell>
                    )}
                </WildHealthTableRow>
            );

            let names = Array.from(shortOrderStatusNames);
            names.push(item.status === OrderStatus.Failed ? [OrderStatus.Failed, 'Failed'] : [OrderStatus.Completed, 'Completed']);

            if (item.isOpen) {
                rows.push(
                    <TableRow key={`dna-row-${index}-1`}>
                        <WildHealthTableCell colSpan={6}>
                            <Collapse in={item.isOpen} timeout="auto" unmountOnExit>
                                <Stepper
                                    alternativeLabel={!isMobile}
                                    connector={isMobile ? <WildHealthVerticalStepperConnector /> : <WildHealthHorizontalStepperConnector />}
                                    activeStep={orderStatusIndexes.get(item.status)}
                                    orientation={isMobile ? "vertical" : 'horizontal'}
                                >
                                    {
                                        names.map((step, index) => {
                                            const date = item.statusLogs.find(i => i.status === step[0])?.date;

                                            return (
                                                <Step key={`dna-step-${index}`}>
                                                    <StepLabel StepIconComponent={WildHealthStepIcon}>
                                                        {step[1]}
                                                        {
                                                            date && <span className={classes.statusDate}>{`(${moment(date).format("MM/DD/yyyy")})`}</span>
                                                        }
                                                    </StepLabel>
                                                </Step>
                                            )
                                        })
                                    }
                                </Stepper>
                            </Collapse>
                        </WildHealthTableCell>
                    </TableRow>
                );
            }
        });
        return rows;
    }

    const renderSmallContent = () => {
        if (isLoading) {
            return (
                <WildHealthLinearProgress />
            )
        }

        if (!orders.length) {
            return <Box height="calc(100vh - 225px)" className={commonClasses.bgWhiteMain}>
                <CommonPlaceHolder message={`No DNA orders yet.`} />
            </Box>
        }

        let names = Array.from(shortOrderStatusNames);
        names.push(selectedOrder ? selectedOrder.status === OrderStatus.Failed ? [OrderStatus.Failed, 'Failed'] : [OrderStatus.Completed, 'Completed'] : [OrderStatus.Ordered, 'Ordered']);

        return (
            <Box className={classes.content}>
                {
                    orders.map((item, index) =>
                        <Box mb="10px" p={2} className={commonClasses.bgWhiteMain} key={index}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Box display="flex" alignItems="center">
                                    <Box className={clsx(commonClasses.textMedium, commonClasses.size14, commonClasses.colorBlack)} mr={0.5}>
                                        {displayDataValue(item.orderedAt)}
                                    </Box>
                                    <IconButton id={`dna-orders-details-${item.id}`} onClick={() => handleToggleOrderDetails(item.id)}>
                                        <ChevronRightIcon className={commonClasses.colorBlack} />
                                    </IconButton>
                                </Box>
                                <Box px={2} py={1} className={commonClasses.renderDivider}>
                                    <span className={commonClasses.colorGray1}>{orderStatusNames.get(item.status)}</span>
                                </Box>
                            </Box>
                            <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                                <span className={clsx(commonClasses.textMedium, commonClasses.size14, commonClasses.colorGray2)}>{'Provider Order #'}</span>
                                <Box className={clsx(commonClasses.textRegular, commonClasses.size14, commonClasses.colorBlack)}>
                                    {displayValue(item.number)}
                                </Box>
                            </Box>
                            <Box mt={1.5} display="flex" justifyContent="space-between" alignItems="center">
                                <span className={clsx(commonClasses.textMedium, commonClasses.size14, commonClasses.colorGray2)}>{'Barcode'}</span>
                                <Box className={clsx(commonClasses.textRegular, commonClasses.size14, commonClasses.colorBlack)}>
                                    {item.isEdit ? (
                                        <TextField
                                            id={`barcode`}
                                            variant="outlined"
                                            size="small"
                                            value={item.barcode ?? ''}
                                            onChange={(v) => handleChange(item.id, v.target.id, v.target.value)}
                                            InputProps={{ className: 'input' }}
                                        />) : displayValue(item.barcode)
                                    }
                                </Box>
                            </Box>
                            <Box mt={1.5} display="flex" justifyContent="space-between" alignItems="center">
                                <span className={clsx(commonClasses.textMedium, commonClasses.size14, commonClasses.colorGray2)}>{'Outbound Shipping #'}</span>
                                <Box className={clsx(commonClasses.textRegular, commonClasses.size14, commonClasses.colorMain)}>
                                    {item.isEdit ? (
                                        <TextField
                                            id={`patientShippingNumber`}
                                            variant="outlined"
                                            size="small"
                                            value={item.patientShippingNumber ?? ''}
                                            onChange={(v) => handleChange(item.id, v.target.id, v.target.value)}
                                            InputProps={{ className: 'input' }}
                                        />) : displayUpsLink(item.patientShippingNumber)
                                    }
                                </Box>
                            </Box>
                            <Box mt={1.5} display="flex" justifyContent="space-between" alignItems="center">
                                <span className={clsx(commonClasses.textMedium, commonClasses.size14, commonClasses.colorGray2)}>{'Return Shipping #'}</span>
                                <Box className={clsx(commonClasses.textRegular, commonClasses.size14, commonClasses.colorMain)}>
                                    {item.isEdit ? (
                                        <TextField
                                            id={`laboratoryShippingNumber`}
                                            variant="outlined"
                                            size="small"
                                            value={item.laboratoryShippingNumber ?? ''}
                                            onChange={(v) => handleChange(item.id, v.target.id, v.target.value)}
                                            InputProps={{ className: 'input' }}
                                        />) : displayUspsLink(item.laboratoryShippingNumber)
                                    }
                                </Box>
                            </Box>
                            {canEdit(orderManageSectionWithTypes, UserType.Employee) && (
                                <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                                    <Box />
                                    <Box>
                                        {item.isEdit ? (
                                            <WildHealthButton id={`dna-orders-view-${item.id}`} color={"secondary"} loading={item.isUpdating} onClick={() => handleUpdateOrder(item.id)}>
                                                Update
                                            </WildHealthButton>
                                        ) : (
                                            <WildHealthButton id={`dna-orders-edit-${item.id}`} color={"secondary"} onClick={() => handleToggleEditOrder(item.id)}>
                                                Edit
                                            </WildHealthButton>
                                        )}
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    )
                }
                {selectedOrder && (
                    <Dialog
                        fullScreen={isSmallScreen}
                        className={classes.dialog}
                        onClose={() => handleToggleOrderDetails(selectedOrder.id)}
                        open={isOpened}
                    >
                        <DialogContent className={classes.dialogContent}>
                            <Box position="absolute" right={0} top={0} p={0.5}>
                                <IconButton id="dna-orders-details-close" onClick={() => handleToggleOrderDetails(selectedOrder.id)} >
                                    <img src={Cancel} className={classes.closeIcon} alt='Cancel' />
                                </IconButton>
                            </Box>

                            <Box textAlign="center">
                                <span className={clsx(commonClasses.size20, commonClasses.textMedium, commonClasses.colorBlack)}>
                                    {'Status History'}
                                </span>
                            </Box>
                            <Stepper
                                alternativeLabel={!isMobile}
                                connector={<WildHealthVerticalStepperConnector />}
                                activeStep={orderStatusIndexes.get(selectedOrder.status)}
                                orientation={"vertical"}
                            >
                                {
                                    names.map((step, index) => {
                                        const date = selectedOrder.statusLogs.find(i => i.status === step[0])?.date;

                                        return (
                                            <Step key={`dna-step-${index}`}>
                                                <StepLabel StepIconComponent={WildHealthStepIcon}>
                                                    {step[1]}
                                                    {
                                                        date && <span className={classes.statusDate}>{`(${moment(date).format("MM/DD/yyyy")})`}</span>
                                                    }
                                                </StepLabel>
                                            </Step>
                                        )
                                    })
                                }
                            </Stepper>
                        </DialogContent>
                    </Dialog>
                )}
            </Box>
        )
    }

    const renderContent = () => {
        if (isLoading) {
            return (
                <WildHealthLinearProgress />
            )
        }

        if (!orders.length) {
            return <CommonPlaceHolder message={`No ${title} yet.`} />
        }

        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <WildHealthTableRow>
                            {
                                columns.map((item, index) =>
                                    <WildHealthTableCell key={`dna-cell-${index}`} align="left">
                                        {item}
                                    </WildHealthTableCell>
                                )
                            }
                        </WildHealthTableRow>
                    </TableHead>
                    <TableBody>
                        {mapOrders()}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const header = (
        <Box display='flex' justifyContent='space-between'>
            <span>
                {title}
            </span>
            <ProtectedElement
                element={
                    <>
                        <Box display='flex'>
                            <Box mr={2}>
                                <WildHealthButton id="dna-orders-replacement" color={"secondary"} onClick={() => handleNavigateToReplacement()}>
                                    Order Replacement
                                </WildHealthButton>
                            </Box>

                            <WildHealthButton id="dna-orders-new-kit" loading={props.isLoadingNewKit} disabled={!(addOns?.length)} onClick={() => handleToggleNewOrderDialog()}>
                                Place New Order
                            </WildHealthButton>
                        </Box>
                        <NewDnaOrderDialog
                            open={props.isNewOrderDialogOpen}
                            patientId={patientId}
                            addOns={addOns}
                            handleClose={() => handleToggleNewOrderDialog()}
                            handleSubmit={handleOrderKit}
                        />
                    </>
                }
                permissions={orderManageSectionWithTypes}
            />
        </Box>
    )

    return isSmallScreen ? renderSmallContent() : (
        <WildHealthHeaderComponent title={header}>
            <Box className={classes.content}>
                {renderContent()}
            </Box>
        </WildHealthHeaderComponent>
    );
}