import {Query} from '@datorama/akita';
import { PatientAllergiesState, PatientAllergiesStore, patientAllergiesStore } from './patientAllergies.store';

/**
 * Provides allergies queries
 */
export class PatientAllergiesQuery extends Query<PatientAllergiesState> {

    patientAllergies$ = this.select(state => state.patientAllergies);
    rxntAllergies$ = this.select(state => state.rxntAllergies);
    editModel$ = this.select(state => state.editModel);

    constructor(protected store: PatientAllergiesStore) {
        super(store);
    }
}

export const patientAllergiesQuery = new PatientAllergiesQuery(patientAllergiesStore);