import { useEffect, useState } from "react";
import { LeadSourceModel } from "../../models/leadSource.models";
import { onEmit } from "../../../common/helpers/on-emit";
import { Subscription } from "rxjs";
import { leadSourcesQuery } from "../../stores/leadSources.query"
import { leadSourcesService } from "../../services/leadSources.service"
import { manageLeadSourcesComponentValidator } from "./manageLeadSourcesComponent.validator";
import { IErrorState } from "../../../common/validation/error-state";

interface ManageLeadSourcesComponentState extends IErrorState {
    isLoading: boolean;
    leadSources: LeadSourceModel[];
    creationName: string;
    anchorElements: HTMLElement[],
    isCreating: boolean;
}

export function useFacade(): [
    ManageLeadSourcesComponentState,
    () => void,
    (id: number) => void,
    (id: number) => void,
    (field: string, value: any) => void,
    (event: React.MouseEvent<HTMLButtonElement>, index: number) => void,
    (index: number) => void
] {
    const [state, setState] = useState({
        leadSources: [],
        creationName: '',
        anchorElements: [],
        isLoading: true,
        errors: {},
        isCreating: false
    } as ManageLeadSourcesComponentState);

    const handleCreateChanges = (field: string, value: any) => {
        manageLeadSourcesComponentValidator.validateAndSetState(state, setState, field, value);
        setState({ ...state, creationName: value });
    }

    const handleCreate = () => {
        manageLeadSourcesComponentValidator.validateAndSetState(state, setState, "creationName", state.creationName);
        if (!manageLeadSourcesComponentValidator.stateIsValid(state)) {
            return;
        }
        setState(state => ({ ...state, isCreating: true }));

        const cb = () => {
            setState(state => ({
                ...state,
                isCreating: false,
            }));
        }

        leadSourcesService.create(state.creationName.trim()).subscribe(() => {
            setState((state) => ({
                ...state,
                creationName: ''
            }))
            cb();
        }, cb);
    }

    const handleDelete = (id: number) => {
        leadSourcesService.delete(id);
    }

    const handleActivityChange = (id: number) => {
        leadSourcesService.changeActivity(id);
    }

    const updateAnchors = () => {
        setState({ ...state, anchorElements: state.anchorElements })
    }

    const handleOpenOptionsMenu = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
        state.anchorElements = state.anchorElements.map(() => null);
        state.anchorElements[index] = event.currentTarget;
        updateAnchors();
    };

    const handleCloseOptionsMenu = (index: number) => {
        state.anchorElements[index] = null;
        updateAnchors();
    };

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<LeadSourceModel[]>(leadSourcesQuery.leadSources$, leadSources => {
                setState(state => ({
                    ...state,
                    leadSources: leadSources
                }))
            }),

        ];

        leadSourcesService.getAll().subscribe(
            () => {
                setState(state => ({
                    ...state,
                    isLoading: false
                }))
            },
            () => {
                setState(state => ({
                    ...state,
                    isLoading: false
                }))
            }
        )

        return (() => {
            subscriptions.map(it => it.unsubscribe())
        })
    }

    useEffect(useEffectCB, []);

    return [
        state,
        handleCreate,
        handleDelete,
        handleActivityChange,
        handleCreateChanges,
        handleOpenOptionsMenu,
        handleCloseOptionsMenu
    ];
}