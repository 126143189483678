import { Avatar, Box, ListItemAvatar } from "@material-ui/core";
import React from "react";
import { displayShortName } from "../../helpers/messages.converter";
import { useStyles } from "./messageAvatarComponent.styles";
import { ReactComponent as AiIcon } from '@img/icons/AiIcon.svg';
import clsx from "clsx";

interface MessageAvatarComponentProps {
  image: string;
  firstName: string;
  lastName: string;
  isAi?: boolean;
  marginBottom?: number;
}

export const MessageAvatarComponent: React.FC<MessageAvatarComponentProps> = (
  props: MessageAvatarComponentProps
) => {
  const classes = useStyles();
  const { image, firstName, lastName, isAi = false, marginBottom = 4 } = props;

  return (
    <Box display="flex" justifyContent="flex-end" height="100%" mb={marginBottom}>
      <ListItemAvatar classes={{ root: classes.root }} >
        <Avatar className={clsx(classes.avatar, isAi && "wh-tw-bg-primary")} variant="circular" src={image}>{isAi ? <AiIcon /> : displayShortName(firstName, lastName)}</Avatar>
      </ListItemAvatar>
    </Box>
  );
};
