import React from 'react';
import { ReactComponent as SelectedChild } from '@img/icons/SelectedChild.svg';
import { IconProps } from './IconProps';
import { colors } from '../../modules/common/constants/colors';

const SelectedChildIcon: React.FC<IconProps> = (props: IconProps) => {
    const { isSelected } = props;

    return (
        <SelectedChild fill={isSelected ? colors.main : '#00000000'} />
    )
}

export default SelectedChildIcon;