import {Query} from '@datorama/akita';
import {ProfileState, profileStore, ProfileStore} from "./profile.store";

/**
 * Provides profile queries
 */
export class ProfileQuery extends Query<ProfileState> {
    practiceInfo$ = this.select(state => state.practiceInfo);

    patientProfile$ = this.select(state => state.patientProfile);

    employeeProfile$ = this.select(state => state.employeeProfile);

    businessProfile$ = this.select(state => state.businessProfile);

    practiceProfile$ = this.select(state => state.practiceProfile);

    practiceShortInfo$ = this.select(state => state.practiceShortInfo);

    patientProfileProblemList$ = this.select(state => state.patientProfileProblemList);

    pharmacyInfo$ = this.select(state => state.pharmacyInfo);

    employeePhotos$ = this.select(state => state.employeePhotos);

    delinquentStatus$ = this.select(state => state.delinquentStatus);

    constructor(protected store: ProfileStore) {
        super(store);
    }

    public getPhoto(id: number): string {
        const employeePhoto = this.getValue().employeePhotos.find(p => p.employeeId === id);
        return employeePhoto ? employeePhoto.photoUrl : '';
    }
}

export const profileQuery = new ProfileQuery(profileStore);
