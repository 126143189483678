import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      paddingTop: "30px !important",
      paddingLeft: "40px !important",
      paddingRight: "40px !important",
      paddingBottom: "30px !important",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: '0px !important',
        paddingRight: '0px !important',
        paddingBottom: '0px !important',
        '& .MuiDialog-paperFullScreen': {
          borderRadius: '20px 20px 0 0',
        },
      },
    },
    dialogContent: {
      paddingLeft: 40,
      paddingRight: 40,
      paddingBottom: 30,
      [theme.breakpoints.down("xs")]: {
        padding: 16,
      },
    },
    dialogTitle: {
      padding: 24,
    },
    disableSchedule: {
      position: "relative",
    },
    blurBackground: {
      filter: "blur(5px)",
    },
    notAvailableCard: {
      backgroundColor: colors.accent1,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "20px 32px",
      borderRadius: 8,
      position: "absolute",
      bottom: 0,
      zIndex: 10,
      [theme.breakpoints.down("xs")]: {
        padding: "10px 20px",
      },
    },
    notAvailableCardText: {
      fontSize: 12,
      color: colors.white,
      fontWeight: 500,
      marginLeft: 24,
      [theme.breakpoints.down("xs")]: {
        fontSize: 14,
        fontWeight: 400,
        marginLeft: 14,
      },
    },
    notAvailableCardSubText: {
      fontSize: 12,
      color: colors.white,
      fontWeight: 600,
      textDecoration: "underline",
      [theme.breakpoints.down("xs")]: {
        fontSize: 14,
        fontWeight: 500,
      },
    },
    greatNews: {
      fontWeight: 600,
      fontSize: 14,
      lineHeight: "24px",
      color: colors.darkBlue,
      paddingRight: 4,
      [theme.breakpoints.down("xs")]: {
        fontWeight: 500,
        fontSize: 16,
        lineHeight: "18px",
        color: colors.main,
      },
    },
    scheduleAppointment: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: 8,
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        gap: 20,
      },
    },
    employeeSection: {
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        width: '100%'
      },
    },
    buttonSection: {
      [theme.breakpoints.down("xs")]: {
        width: '100%'
      },
    },
    scheduleVisit: {
      fontWeight: 400,
      fontSize: 14,
      color: colors.linkBlack,
      lineHeight: "18px",
      paddingRight: "4px",
      [theme.breakpoints.down("xs")]: {
        fontSize: 16,
        color: colors.black,
      },
    },
    dashboardMessageDescription: {
      [theme.breakpoints.down("xs")]: {
        color: colors.gray1,
      },
    },
    hereText: {
      fontSize: 14,
      fontWeight: 700,
      textDecoration: "underline",
      [theme.breakpoints.down("xs")]: {
        fontSize: 16,
        fontWeight: 400,
        color: colors.main,
      },
    },
    actionHeight: {
      height: '48px'
    }
  })
);
