import React from 'react';
import { CheckboxDropdownItem } from '../../models/checkboxDropdown.models';
import { Box, Menu, MenuItem, } from '@material-ui/core';
import { useFacade } from "./checkboxDropdown.hooks";
import { useStyles, FilterButton } from "./checkboxDropdown.styles";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { OptionsButton } from '../wildHealthButton/OptionsButton';
import clsx from 'clsx';

interface CheckboxDropdownProps {
    placeHolder: JSX.Element | string,
    items: CheckboxDropdownItem[],
    handleCheck: (key: string, value: boolean) => void,
    isMobile?: boolean;
    width?: number;
    height?: number;
}

export const CheckboxDropdown: React.FC<CheckboxDropdownProps> = (props: CheckboxDropdownProps) => {
    const {
        placeHolder,
        items,
        handleCheck,
        isMobile,
        width = 150,
    } = props;

    const classes = useStyles();

    const [
        {
            anchorEl,
        },
        handleOpen,
        handleClose] = useFacade();

    return (
        <>
            <Box className={classes.checkboxDropdownWrapper}>
                {isMobile ? (
                    <FilterButton
                        id="checkbox-dropdown-open"
                        onClick={(event) => handleOpen(event)}
                    >
                        {placeHolder}
                    </FilterButton>
                ) : (
                    <OptionsButton
                        id="checkbox-dropdown-open"
                        fullWidth
                        className={clsx(classes.placeHolder, { [classes.placeHolderOpen]: Boolean(anchorEl) })}
                        onClick={(event) => handleOpen(event)}
                        variant='outlined'
                    >
                        {placeHolder}
                    </OptionsButton>
                )}

                <Menu
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => handleClose()}
                >
                    {
                        items.map((item, index) => {
                            return (
                                <MenuItem id={`checkbox-dropdown-check${item.name}`} key={index} onClick={() => handleCheck(item.key, !item.selected)} style={{ width: `${width}px` }}>
                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                        <Box width="30%" mt="3.5px">
                                            {
                                                item.selected ? <CheckCircleIcon className="wh-tw-text-primaryV" /> : <RadioButtonUncheckedIcon className="wh-tw-text-primaryV" />
                                            }
                                        </Box>
                                        <Box ml={2} width="70%">
                                            <span>{item.name}</span>
                                        </Box>
                                    </Box>
                                </MenuItem>
                            )
                        })
                    }
                </Menu>
            </Box>
        </>
    )
}