import {HealthSummaryDataModel} from '../models/healthSummary.models'
import {healthSummaryStore, HealthSummaryStore} from "../stores"
import {Observable} from 'rxjs'
import {prescriptionService} from "../../patientSupplements/services/prescription.service";
import Axios from "axios-observable";
import {snackService} from "../../common/snack/state";

export class HealthSummaryRxntAllergiesService {
    public mapKey = 'ALLERGIES'

    constructor(private healthSummaryStore: HealthSummaryStore) {
    }


    public get(patientId?: number): void {
        const method = patientId
            ? prescriptionService.getPatientAllergies(patientId)
            : prescriptionService.getMyAllergies()

        method.subscribe((allergies) => {
                const allergiesData = allergies.map(
                    (x, index) => (
                        {
                            key: `${this.mapKey}-${index}`,
                            name: x.name,
                            value: x.description
                        }
                    ) as HealthSummaryDataModel)

                this.healthSummaryStore.sectionLoaded()
                this.healthSummaryStore.clearSection(this.mapKey)
                this.healthSummaryStore.setData(allergiesData)
            },
            () => {
                this.healthSummaryStore.sectionLoaded()
                this.healthSummaryStore.clearSection(this.mapKey)
                this.healthSummaryStore.setData([])
            })
    }

    public create(patientId: number, model: HealthSummaryDataModel): Observable<any> {
        return null;
    }

    public update(patientId: number, model: HealthSummaryDataModel): Observable<any> {
        return null;
    }

    public delete(key: string): void {
        return null;
    }
}

export const healthSummaryRxntAllergiesService = new HealthSummaryRxntAllergiesService(healthSummaryStore)