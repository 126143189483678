import { createStyles, makeStyles } from "@material-ui/core/styles";
import { colors } from "../../../../../common/constants/colors";

export const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontSize: 16,
      lineHeight: '22px',
      fontWeight: 500,
      color: colors.black,
    },
    textGray: {
      color: colors.gray1,
    },
    recordingConsentSection: {
      borderRadius: 4,
      backgroundColor: colors.lightGray,
      height: 70,
      display: 'flex',
      alignItems: 'center',
      padding: 15,
      marginTop: 15
  },
  recordingConsentIcon: {
      color: colors.gray500,
      marginRight: 15,
      marginLeft: 15
  },
  recordingConsentText: {
      color: colors.gray500,
      fontSize: 14,
      fontWeight: 500,
      paddingLeft: 15,
      paddingRight: 15
  }
  }),
  
);


