import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { colors } from "../../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderColor: colors.gray4,
      borderRadius: 2,
      '& .MuiOutlinedInput-root': {
        borderColor: colors.gray4,
        borderRadius: 2,
      },
    },
    headerSection: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 16,
    },
    title: {
      fontSize: 16,
      lineHeight: '22px',
      fontWeight: 500,
      color: colors.black,
    },
    button: {
      color: colors.main,
      fontSize: 14
    },
    buttonText: {
      marginLeft: '6px',
      textTransform: 'capitalize'
    },
    textGray: {
      color: colors.gray1,
      fontSize: 14,
      minWidth: 80,
    },
    breakWord: {
      flex: 1,
      paddingRight: 24,
      [theme.breakpoints.down('xs')]: {
        paddingRight: 0
      },
    },
  })
);


