import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {LinearProgress} from '@material-ui/core';
import { colors } from "../../../modules/common/constants/colors";

class WildHealthLinearProgress extends Component<any> {
    render() {
        const {classes} = this.props;
        return <LinearProgress {...this.props} classes={{
            colorPrimary: classes.colorPrimary,
            barColorPrimary: classes.barColorPrimary
        }}/>;
    }
}

const styles = props => ({
    colorPrimary: {
        backgroundColor: '#bbd4e3',
    },
    barColorPrimary: {
        backgroundColor: colors.main,
    }
});

export default withStyles(styles)(WildHealthLinearProgress);