import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Subscription } from "recompose";
import { navigationService } from "../../../../services/navigation.service";
import { onEmit } from '../../../common/helpers/on-emit';
import { GetMaximumVersionOfNote } from '../../helpers/noteHelpers';
import { FollowUpContent, NoteModel } from '../../models/notes.models';
import { followUpNotePdfService } from '../../services/notes-pdf.service';
import { notesService } from '../../services/notes.service';
import { notesQuery } from '../../stores/notes';
import {useFlags} from "launchdarkly-react-client-sdk";

interface PatientInitialConsultPageState {
    note: NoteModel;
    content: FollowUpContent;
    isPdfDownloading: boolean;
}

export function useFacade(noteId: number): [PatientInitialConsultPageState, () => void, (note: NoteModel) => void] {
    const [state, setState] = useState({
        note: null,
        content: null,
        isPdfDownloading: false,
    } as PatientInitialConsultPageState);

    const flags = useFlags();

    const history = useHistory();

    const handleGoBack = () => {
        navigationService.toPatientNotes(history);
    }

    const handleDownloadPdf = (note: NoteModel) => {
        setState(state => ({...state, isPdfDownloading: true}));

        const cb = () => setState({ ...state, isPdfDownloading: false });

        const noteForDownload = GetMaximumVersionOfNote(note);
        notesService.downloadNote(noteForDownload.id, noteForDownload.name, false).subscribe(
            cb,
            () => {
                followUpNotePdfService.downloadNotePdf(noteForDownload, state.content, false, noteForDownload.id, '', flags).subscribe(cb, cb);
            }
        )
    }

    /**
     * Load all ongoing cares
     * Manage subscriptions with auto-cleanup
     */
    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<NoteModel[]>(notesQuery.notes$, notes => {
                const note = notes?.find(x => x.id === noteId);
                if (note) {
                    setState(state => ({
                        ...state,
                        note
                    }));

                    notesService.getContentByPatient(GetMaximumVersionOfNote(note).id).subscribe(result =>
                        setState(state => ({
                            ...state,
                            content: JSON.parse(result.content),
                        }))
                    )
                }
            })
        ];

        if (!state.note) {
            notesService.getByPatient().subscribe();
        }

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, [noteId]);

    return [state, handleGoBack, handleDownloadPdf];
}