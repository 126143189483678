import { FailedDnaBadgeComponent } from "../modules/dnaFiles/components/failedDnaBadgeComponent/FailedDnaBadgeComponent";
import { FeatureFlag } from "../modules/common/components/featureFlags/featureFlags.models";
import { isFeatureFlag } from "../modules/common/components/featureFlags/featureFlags";
import MedicalHistoryIcon from "./../components/iconComponent/MedicalHistoryIcon";
import SelectedChildIcon from "./../components/iconComponent/SelectedChildIcon";
import ConversationsIcon from "./../components/iconComponent/ConversationIcon";
import AppointmentsIcon from "./../components/iconComponent/AppointmentsIcon";
import HealthReportIcon from "./../components/iconComponent/HealthReportIcon";
import { PermissionType, UserType } from "../modules/auth/models/auth.enums";
import AllPatientsIcon from "./../components/iconComponent/AllPatientsIcon";
import MyPatientsIcon from "./../components/iconComponent/MyPatientsIcon";
import HealthScoreIcon from "../components/iconComponent/HealthScoreIcon";
import PendingNotesIcon from "../components/iconComponent/PendingNotesIcon";
import FellowshipIcon from "./../components/iconComponent/FellowshipIcon";
import ShortcutsIcon from "./../components/iconComponent/ShortcutsIcon";
import LicensingIcon from "./../components/iconComponent/LicensingIcon";
import AnalyticsIcon from "./../components/iconComponent/AnalyticsIcon";
import DashboardIcon from "./../components/iconComponent/DashboardIcon";
import IntakeIcon from "./../components/iconComponent/IntakeIcon";
import UsersIcon from "./../components/iconComponent/UsersIcon";
import NotesIcon from "./../components/iconComponent/NotesIcon";
import AcademyIcon from "./../components/iconComponent/AcademyIcon";
import DNAIcon from "./../components/iconComponent/DNAIcon";
import PersonIcon from "./../components/iconComponent/PersonIcon";
import HealthPlanIcon from "../components/iconComponent/HealthPlan";
import LabsIcon from "./../components/iconComponent/LabsIcon";
import { MenuItem, MenuItemTypes } from "../models/menu.models";
import { roles, isStaffRole } from "../modules/common/constants/roles";
import { navigationService } from "./navigation.service";
import { accountService } from "./account.service";
import React from "react";
import { UnreadMessagesBadgeComponent } from "../modules/conversations/components/unreadMessagesBadgeComponent/UnreadMessagesBadgeComponent";
import { authQuery } from "../modules/auth/stores/auth";
import { EmployeeType } from "../modules/employee/models/employee.enums";
import { NewSupportTicketsBadgeComponent } from "../modules/conversations/components/newSupportTicketsBadgeComponent/NewSupportTicketsBadgeComponent";
import { ConversationsBadgeComponent } from "../modules/conversations/components/conversationBudgeComponent/ConversationsBadgeComponent";
import { UncompletedMedicalHistoryBadgeComponent } from "../modules/questionnaire/components/uncompletedMedicalHistoryBadgeComponent/UncompletedMedicalHistoryBadgeComponent";
import { HealthReportBadgeComponent } from "../modules/healthReport/components/healthReportBadgeComponent/HealthReportBadgeComponent";
import { insuranceQuery } from "../modules/insurance/stores/insurance.query";
import { UncompletedHealthFormsBadgeComponent } from "../modules/questionnaire/components/uncompletedMedicalHistoryBadgeComponent/UncompletedHealthFormsBadgeComponent";
import { confirmService } from "./confirm.service";
import { rostersService } from "../modules/rosters/services/rosters.service";
import { navigationNumber, RosterModel } from "../modules/rosters/models/roster.model";
import { UnmatchedLabsBadgeComponent } from "../modules/unmatchedLabs/components/unmatchedLabsBadgeComponent/UnmatchedLabsBadgeComponent";
import { handleCompare } from "../modules/common/sorting/helpers/handle-compare";
import { SortingDirection } from "../modules/common/sorting/models/sorting-destination";

const getPages = (featureFlags: any, rosters = []) => ({
    conversations: {
        title: 'Messaging',
        icon: (isSelected: boolean) => <ConversationsIcon isSelected={isSelected} />,
        badge: <UnreadMessagesBadgeComponent />,
        type: MenuItemTypes.Messaging,
        action: (history) => {
            navigationService.toConversations(history)
        }
    },
    staffConversation: {
        title: 'Messaging',
        icon: (isSelected: boolean) => <ConversationsIcon isSelected={isSelected} />,
        badge: <ConversationsBadgeComponent />,
        type: MenuItemTypes.Messaging,
        children: [
            {
                title: 'All Messages',
                icon: (isSelected: boolean) => <SelectedChildIcon isSelected={isSelected} />,
                badge: <UnreadMessagesBadgeComponent />,
                type: MenuItemTypes.Messaging,
                action: (history) => {
                    navigationService.toConversations(history)
                }
            },
            {
                title: 'Patient Submissions',
                icon: (isSelected: boolean) => <SelectedChildIcon isSelected={isSelected} />,
                badge: <NewSupportTicketsBadgeComponent />,
                type: MenuItemTypes.PatientsSubmissions,
                action: (history) => {
                    navigationService.toPatientsSubmissions(history)
                }
            }
        ]
    },
    myPatients: {
        title: 'My Patients',
        icon: (isSelected: boolean) => <MyPatientsIcon isSelected={isSelected} />,
        type: MenuItemTypes.MyPatients,
        action: (history) => {
            navigationService.toMyPatients(history)
        }
    },
    allPatients: {
        title: 'All Patients',
        icon: (isSelected: boolean) => <AllPatientsIcon isSelected={isSelected} />,
        type: MenuItemTypes.ManagePatients,
        action: (history) => {
            navigationService.toManagePatients(history)
        }
    },
    intake: {
        title: 'Intake',
        icon: (isSelected: boolean) => <IntakeIcon isSelected={isSelected} />,
        type: MenuItemTypes.IntakePatients,
        action: (history) => {
            navigationService.toIntakePatients(history)
        }
    },
    shortcuts: {
        title: 'Shortcuts',
        icon: (isSelected: boolean) => <ShortcutsIcon isSelected={isSelected} />,
        type: MenuItemTypes.Shortcuts,
        action: (history) => {
            navigationService.toShortcuts(history)
        }
    },
    dnaFiles: {
        title: 'DNA files',
        icon: (isSelected: boolean) => <DNAIcon isSelected={isSelected} />,
        badge: <FailedDnaBadgeComponent />,
        type: MenuItemTypes.DNAFiles,
        action: (history) => {
            navigationService.toDNAFiles(history)
        }
    },
    dnaOrderDropship: {
        title: 'DNA Order Dropship',
        icon: (isSelected: boolean) => <DNAIcon isSelected={isSelected} />,
        badge: <FailedDnaBadgeComponent />,
        type: MenuItemTypes.DNAOrderDropship,
        action: (history) => {
            navigationService.toDNAOrderDropship(history)
        }
    },
    appointments: {
        title: 'Appointments',
        icon: (isSelected: boolean) => <AppointmentsIcon isSelected={isSelected} />,
        type: MenuItemTypes.Appointments,
        action: (history) => {
            navigationService.toCoachAppointments(history)
        }
    },
    usersAndPods: {
        title: 'Users & Pods',
        icon: (isSelected: boolean) => <UsersIcon isSelected={isSelected} />,
        type: MenuItemTypes.Employees,
        action: (history) => {
            navigationService.toManageEmployees(history)
        }
    },
    analytics: {
        title: 'Analytics',
        icon: (isSelected: boolean) => <AnalyticsIcon isSelected={isSelected} />,
        type: MenuItemTypes.Analytics,
        action: (history) => {
            navigationService.toLeadSources(history)
        }
    },
    fellowship: {
        title: 'PM Education',
        icon: (isSelected: boolean) => <FellowshipIcon isSelected={isSelected} />,
        type: MenuItemTypes.Fellowship,
        children: [
            rosters.map((roster) =>
            ({
                title: roster.name,
                icon: (isSelected: boolean) => <SelectedChildIcon isSelected={isSelected} />,
                type: 100 + roster.id,
                action: (history) => {
                    navigationService.toFellowship(history, roster.id)
                }
            } as MenuItem)),
            {
                title: 'Add New Roster',
                icon: (isSelected: boolean) => <SelectedChildIcon isSelected={isSelected} />,
                type: navigationNumber + MenuItemTypes.Fellowship,
                action: () => {
                    confirmService.confirmWithReason('Add New Roster', 'Roster title', 'Enter roster title', 'Create', '').subscribe(
                        (title) => {
                            rostersService.create({name: title}).subscribe()
                        }
                    );
                }
            },
        ]
    },
    licensing: {
        title: 'Licensing',
        icon: (isSelected: boolean) => <LicensingIcon isSelected={isSelected} />,
        action: () => {
            accountService.loginToLicenceApp();
        }
    },
    pendingNotes: {
        title: 'Notes',
        icon: (isSelected: boolean) => <PendingNotesIcon isSelected={isSelected} />,
        badge: <HealthReportBadgeComponent type={null} />,
        type: MenuItemTypes.PendingNotes,
        children: [
            {
                title: 'Draft Notes',
                icon: (isSelected: boolean) => <SelectedChildIcon isSelected={isSelected} />,
                type: MenuItemTypes.DraftNotes,
                action: (history) => {
                    navigationService.toDraftNotes(history)
                },
                disabled: false,
            },
            {
                title: 'Awaiting Approval',
                icon: (isSelected: boolean) => <SelectedChildIcon isSelected={isSelected} />,
                badge: <HealthReportBadgeComponent type="reports" />,
                type: MenuItemTypes.AwaitingApproval,
                action: (history) => {
                    navigationService.toAwaitingApproval(history)
                },
            },
        ].filter(x => x)
    },
    employerAdmin: {
        title: 'Employer Admin',
        icon: (isSelected: boolean) => <UsersIcon isSelected={isSelected} />,
        type: MenuItemTypes.EmployerAdmin,
        action: (history) => {
            navigationService.toEmployerAdmin(history)
        }
    },
    engineeringAdmin: {
        title: 'Engineering Admin Tool',
        icon: (isSelected: boolean) => <UsersIcon isSelected={isSelected} />,
        type: MenuItemTypes.EngineeringAdminTool,
        action: (history) => {
            navigationService.toEngineeringAdminTool(history)
        }
    },
    insuranceCarriers: {
        title: 'Insurance Carriers',
        icon: (isSelected: boolean) => <UsersIcon isSelected={isSelected} />,
        type: MenuItemTypes.EligibleInsurances,
        action: (history) => {
            navigationService.toEligibleInsurances(history)
        }
    },
    insuranceConfigurations: {
        title: 'Insurance Configurations',
        icon: (isSelected: boolean) => <UsersIcon isSelected={isSelected} />,
        type: MenuItemTypes.InsuranceConfigurations,
        action: (history) => {
            navigationService.toInsuranceConfigurations(history)
        }
    },
    opsAdminTools: {
        title: 'OPS Admin Tools',
        icon: (isSelected: boolean) => <UsersIcon isSelected={isSelected} />,
        type: MenuItemTypes.OpsAdminTools,
        action: (history) => {
            navigationService.toOpsAdminTools(history)
        }
    },
    managePromoCodes: {
        title: 'Promo Codes',
        icon: (isSelected: boolean) => <UsersIcon isSelected={isSelected} />,
        type: MenuItemTypes.PromoCodes,
        action: (history) => {
            navigationService.toManagePromoCodes(history)
        }
    },
    managePatientResponsibility: {
        title: 'Patient Responsibility',
        icon: (isSelected: boolean) => <UsersIcon isSelected={isSelected} />,
        type: MenuItemTypes.ManagePatientResponsibility,
        action: (history) => {
            navigationService.toPatientResponsibility(history)
        }
    },
    manageBanners: {
        title: 'Banners',
        icon: (isSelected: boolean) => <UsersIcon isSelected={isSelected} />,
        type: MenuItemTypes.Banners,
        action: (history) => {
            navigationService.toManageBanners(history)
        }
    },
    manageHealthReportTemplates: {
        title: 'Health Report Templates',
        icon: (isSelected: boolean) => <HealthReportIcon isSelected={isSelected} />,
        type: MenuItemTypes.HealthReportTemplates,
        action: (history) => {
            navigationService.toHealthReportTemplates(history)
        }
    },
    manageCommonGoals: {
        title: 'Common Goals',
        icon: (isSelected: boolean) => <PendingNotesIcon isSelected={isSelected} />,
        type: MenuItemTypes.ManageCommonGoals,
        action: (history) => {
            navigationService.toCommonGoals(history)
        }
    },
    manageCommonMdms: {
        title: 'Common MDM Plans',
        icon: (isSelected: boolean) => <PendingNotesIcon isSelected={isSelected} />,
        type: MenuItemTypes.ManageCommonGoals,
        action: (history) => {
            navigationService.toCommonMdms(history)
        }
    },
    manageCommonSupplements: {
        title: 'Common Supplements',
        icon: (isSelected: boolean) => <PendingNotesIcon isSelected={isSelected} />,
        type: MenuItemTypes.ManageCommonSupplements,
        action: (history) => {
            navigationService.toCommonSupplements(history)
        }
    },
    manageCommonOrders: {
        title: 'Common Orders',
        icon: (isSelected: boolean) => <PendingNotesIcon isSelected={isSelected} />,
        type: MenuItemTypes.ManageCommonOrders,
        action: (history) => {
            navigationService.toCommonOrders(history)
        }
    },
    manageRecommendations: {
        title: 'Recommendations',
        icon: (isSelected: boolean) => <PendingNotesIcon isSelected={isSelected} />,
        type: MenuItemTypes.Recommendations,
        action: (history) => {
            navigationService.toRecommendations(history)
        }
    },
    kbDocuments: {
        title: 'Knowledge Base Documents',
        icon: (isSelected: boolean) => <UsersIcon isSelected={isSelected} />,
        type: MenuItemTypes.KBDocuments,
        action: (history) => {
            navigationService.toKBDocuments(history)
        }
    },
    manageConversationTemplates: {
        title: 'Conversation Templates',
        icon: (isSelected: boolean) => <ConversationsIcon isSelected={isSelected} />,
        type: MenuItemTypes.ManageConversationTemplates,
        action: (history) => {
            navigationService.toManageConversationTemplates(history)
        }
    },
    manageEmployersProfiles: {
        title: 'Employers Profiles',
        icon: (isSelected: boolean) => <UsersIcon isSelected={isSelected} />,
        type: MenuItemTypes.ManageEmployersProfiles,
        action: (history) => {
            navigationService.toManageEmployersProfiles(history)
        }
    },
    profile: {
        title: 'Profile',
        icon: (isSelected: boolean) => <PersonIcon isSelected={isSelected} />,
        type: MenuItemTypes.Profile,
        action: (history) => {
            navigationService.toMyProfile(history);
        },
    },
});

export class MainMenuService {

    private dashboardMenuItem: MenuItem = {
        title: 'Dashboard',
        icon: (isSelected: boolean) => <DashboardIcon isSelected={isSelected} />,
        type: MenuItemTypes.Dashboard,
        action: (history) => {
            navigationService.toDashboard(history)
        }
    };



    private getEmployeeMenuItems = (pages: any, roleId: number): Map<MenuItem, PermissionType[]> => {
        const result = new Map<MenuItem, PermissionType[]>([]);

        result.set(this.getConversation(pages, roleId), [
            PermissionType.Conversations
        ]);

        result.set(pages.myPatients, [
            PermissionType.Coaching,
            PermissionType.ViewOnly
        ]);

        result.set(pages.dnaFiles, [
            PermissionType.DNAFileManager,
        ]);

        result.set(pages.dnaOrderDropship, [
            PermissionType.Coaching,
            PermissionType.ManagePatients,
            PermissionType.ViewOnly
        ]);

        result.set(pages.intake, [
            PermissionType.ManagePatients,
            PermissionType.ViewOnly
        ]);

        result.set(pages.shortcuts, [
            PermissionType.Shortcuts,
            PermissionType.ViewOnly
        ]);

        result.set(pages.appointments, [
            PermissionType.Appointments,
            PermissionType.ViewOnly
        ]);

        if (authQuery.getEmployeeType() === EmployeeType.Provider || authQuery.getEmployeeType() === EmployeeType.HealthCoach) {
            result.set(pages.pendingNotes, [
                PermissionType.ManagePatients,
                PermissionType.ViewOnly
            ]);
        }

        result.set(pages.usersAndPods, [
            PermissionType.ManageEmployees,
            PermissionType.ManageLocations,
            PermissionType.ViewOnly
        ]);

        result.set(pages.allPatients, [
            PermissionType.ManagePatients,
            PermissionType.ViewOnly
        ]);

        result.set(pages.analytics, [
            PermissionType.PatientAnalytics,
            PermissionType.ViewOnly
        ]);

        result.set(pages.fellowship, [
            PermissionType.ManageFellowship,
            PermissionType.ViewOnly
        ]);

        result.set(pages.licensing, [
            PermissionType.ManageLicensees
        ]);

        if (authQuery.getRoleId() === roles.portalAdmin) {
            result.set(pages.employerAdmin, [
                PermissionType.ManageEmployees,
                PermissionType.ViewOnly
            ]);
            result.set(pages.engineeringAdmin, [
                PermissionType.ManageEmployees,
                PermissionType.ViewOnly
            ]);
            result.set(pages.insuranceCarriers, [
                PermissionType.ManageInsurance,
                PermissionType.ViewOnly
            ]);
            result.set(pages.managePromoCodes, [
                PermissionType.ManageEmployees,
                PermissionType.ViewOnly
            ]);
            result.set(pages.managePatientResponsibility, [
                PermissionType.ManageInsurance,
                PermissionType.ViewOnly
            ]);
            result.set(pages.manageBanners, [
                PermissionType.ManageEmployees,
                PermissionType.ViewOnly
            ]);
            result.set(pages.manageCommonGoals, [
                PermissionType.ManagePatients,
                PermissionType.ViewOnly
            ]);
            result.set(pages.manageCommonMdms, [
                PermissionType.ManagePatients,
                PermissionType.ViewOnly
            ]);
            result.set(pages.manageCommonSupplements, [
                PermissionType.ManagePatients,
                PermissionType.ViewOnly
            ]);
            result.set(pages.manageCommonOrders, [
                PermissionType.ManagePatients,
                PermissionType.ViewOnly
            ]);
            result.set(pages.manageRecommendations, [
                PermissionType.ManageEmployees,
                PermissionType.ViewOnly
            ]);
            result.set(pages.manageEmployersProfiles, [
                PermissionType.ManageEmployees,
                PermissionType.ViewOnly
            ]);
        }

        result.set(pages.kbDocuments, [
            PermissionType.ManageKbDocuments
        ]);

        if (isStaffRole(authQuery.getRoleId())) {
            result.set(pages.opsAdminTools, [
                PermissionType.OpsAdminTools
            ]);
        }

        result.set(pages.manageHealthReportTemplates, [
            PermissionType.ManageEmployees,
        ]);

        if (isStaffRole(authQuery.getRoleId()) || authQuery.getRoleId() === roles.portalAdmin) {
            result.set(pages.manageConversationTemplates, []);
        }

        result.set(pages.profile, []);

        return result;
    }

    private getEmployeeNavigationMenuItems = (userPermissions: PermissionType[], featureFlags: any, roleId: number, rosters?: RosterModel[]): MenuItem[] => [
        this.checkPermission(userPermissions, [
            PermissionType.Conversations
        ]) && this.getConversationMenu(roleId),
        this.checkPermission(userPermissions, [
            PermissionType.Appointments,
            PermissionType.ViewOnly
        ]) && {
            title: 'Appointments',
            type: MenuItemTypes.Appointments,
            action: (history) => {
                navigationService.toCoachAppointments(history)
            }
        },
        (authQuery.getEmployeeType() === EmployeeType.Provider || authQuery.getEmployeeType() === EmployeeType.HealthCoach) && this.checkPermission(userPermissions, [
            PermissionType.ManagePatients,
            PermissionType.ViewOnly
        ]) && {
            title: 'Notes',
            action: null,
            type: MenuItemTypes.PendingNotes,
            badge: <HealthReportBadgeComponent type={null} />,
            children: [
                {
                    title: 'Draft Notes',
                    type: MenuItemTypes.DraftNotes,
                    icon: (isSelected: boolean) => <SelectedChildIcon isSelected={isSelected} />,
                    action: (history) => {
                        navigationService.toDraftNotes(history)
                    },
                    disabled: false,
                },
                isFeatureFlag(featureFlags, FeatureFlag.SignOffNotes) && {
                    title: 'Sign off Notes',
                    type: MenuItemTypes.SignOffNotes,
                    icon: (isSelected: boolean) => <SelectedChildIcon isSelected={isSelected} />,
                    badge: <HealthReportBadgeComponent type="notes" />,
                    action: (history) => {
                        navigationService.toSignOffNotes(history)
                    },
                    disabled: false,
                },
                {
                    title: 'Awaiting Approval',
                    icon: (isSelected: boolean) => <SelectedChildIcon isSelected={isSelected} />,
                    badge: <HealthReportBadgeComponent type="reports" />,
                    type: MenuItemTypes.AwaitingApproval,
                    action: (history) => {
                        navigationService.toAwaitingApproval(history)
                    },
                },
            ].filter(x => x)
        },
        {
            title: 'Patients',
            action: null,
            type: MenuItemTypes.Patients,
            children: [
                this.checkPermission(userPermissions, [
                    PermissionType.ManagePatients,
                    PermissionType.ViewOnly
                ]) && {
                    title: 'All Patients',
                    type: MenuItemTypes.ManagePatients,
                    icon: (isSelected: boolean) => <SelectedChildIcon isSelected={isSelected} />,
                    action: (history) => {
                        navigationService.toManagePatients(history)
                    }
                },
                this.checkPermission(userPermissions, [
                    PermissionType.Coaching,
                    PermissionType.ViewOnly
                ]) && {
                    title: 'My Patients',
                    type: MenuItemTypes.MyPatients,
                    icon: (isSelected: boolean) => <SelectedChildIcon isSelected={isSelected} />,
                    action: (history) => {
                        navigationService.toMyPatients(history)
                    }
                },
                this.checkPermission(userPermissions, [
                    PermissionType.ManagePatients,
                    PermissionType.ViewOnly
                ]) && {
                    title: 'Intake',
                    type: MenuItemTypes.IntakePatients,
                    icon: (isSelected: boolean) => <SelectedChildIcon isSelected={isSelected} />,
                    action: (history) => {
                        navigationService.toIntakePatients(history)
                    }
                },
            ].filter(x => x)
        },
        {
            title: 'Settings',
            action: null,
            type: MenuItemTypes.Settings,
            badge: this.checkPermission(userPermissions, [
                PermissionType.AssignUnmatchedResults,
            ]) ? <UnmatchedLabsBadgeComponent /> : <></>,
            children: [
                this.checkPermission(userPermissions, [
                    PermissionType.Shortcuts,
                    PermissionType.ViewOnly
                ]) && {
                    title: 'Shortcuts',
                    type: MenuItemTypes.Shortcuts,
                    action: (history) => {
                        navigationService.toShortcuts(history)
                    }
                },
                this.checkPermission(userPermissions, [
                    PermissionType.PatientAnalytics,
                    PermissionType.ViewOnly
                ]) && {
                    title: 'Analytics',
                    type: MenuItemTypes.Analytics,
                    action: (history) => {
                        navigationService.toLeadSources(history)
                    }
                },
                this.checkPermission(userPermissions, [
                    PermissionType.ManageLicensees
                ]) && {
                    title: 'Licensing',
                    action: () => {
                        accountService.loginToLicenceApp();
                    }
                },
                this.checkPermission(userPermissions, [
                    PermissionType.DNAFileManager
                ]) && {
                    title: 'DNA',
                    badge: <FailedDnaBadgeComponent />,
                    type: MenuItemTypes.DNAFiles,
                    action: (history) => {
                        navigationService.toDNAFiles(history)
                    }
                },
                this.checkPermission(userPermissions, [
                    PermissionType.Coaching,
                    PermissionType.ManagePatients,
                    PermissionType.ViewOnly
                ]) && {
                    title: 'DNA Order Dropship',
                    badge: <FailedDnaBadgeComponent />,
                    type: MenuItemTypes.DNAOrderDropship,
                    action: (history) => {
                        navigationService.toDNAOrderDropship(history)
                    }
                },
                this.checkPermission(userPermissions, [
                    PermissionType.ManageEmployees,
                    PermissionType.ManageLocations,
                    PermissionType.ViewOnly
                ]) && {
                    title: 'Users & Pods',
                    type: MenuItemTypes.Employees,
                    action: (history) => {
                        navigationService.toManageEmployees(history)
                    }
                },
                (authQuery.getRoleId() === roles.portalAdmin) && this.checkPermission(userPermissions, [
                    PermissionType.ManageEmployees,
                    PermissionType.ViewOnly
                ]) && {
                    title: 'Employer Admin',
                    type: MenuItemTypes.EmployerAdmin,
                    action: (history) => {
                        navigationService.toEmployerAdmin(history)
                    }
                },
                (authQuery.getRoleId() === roles.portalAdmin) && this.checkPermission(userPermissions, [
                    PermissionType.ManageEmployees,
                    PermissionType.ViewOnly
                ]) && {
                    title: 'Engineering Admin Tool',
                    type: MenuItemTypes.EngineeringAdminTool,
                    action: (history) => {
                        navigationService.toEngineeringAdminTool(history)
                    }
                },
                (authQuery.getRoleId() === roles.portalAdmin) && this.checkPermission(userPermissions, [
                    PermissionType.ManageMessages,
                    PermissionType.ViewOnly
                ]) && {
                    title: 'Messages',
                    type: MenuItemTypes.MessagingAdminPanel,
                    action: (history) => {
                        navigationService.toManageMessaging(history)
                    }
                },
                this.checkPermission(userPermissions, [
                    PermissionType.ManageForwarding,
                    PermissionType.ViewOnly
                ]) && {
                    title: 'Forwarding',
                    type: MenuItemTypes.MessageForwarding,
                    action: (history) => {
                        navigationService.toMessageForwarding(history)
                    }
                },
                (authQuery.getRoleId() === roles.portalAdmin) && this.checkPermission(userPermissions, [
                    PermissionType.ManageInsurance,
                    PermissionType.ViewOnly
                ]) && {
                    title: 'Insurance Carriers',
                    type: MenuItemTypes.EligibleInsurances,
                    action: (history) => {
                        navigationService.toEligibleInsurances(history)
                    }
                },
                (authQuery.getRoleId() === roles.portalAdmin) && this.checkPermission(userPermissions, [
                    PermissionType.ManageInsurance,
                    PermissionType.ViewOnly
                ]) && {
                    title: 'Insurance Configurations',
                    type: MenuItemTypes.InsuranceConfigurations,
                    action: (history) => {
                        navigationService.toInsuranceConfigurations(history)
                    }
                },
                isStaffRole(authQuery.getRoleId()) && this.checkPermission(userPermissions, [
                    PermissionType.OpsAdminTools,
                ]) && {
                    title: 'OPS Admin Tools',
                    type: MenuItemTypes.OpsAdminTools,
                    action: (history) => {
                        navigationService.toOpsAdminTools(history)
                    }
                },
                (authQuery.getRoleId() === roles.portalAdmin) && this.checkPermission(userPermissions, [
                    PermissionType.ManageEmployees,
                    PermissionType.ViewOnly
                ]) && {
                    title: 'Promo Codes',
                    type: MenuItemTypes.PromoCodes,
                    action: (history) => {
                        navigationService.toManagePromoCodes(history)
                    }
                },
                (authQuery.getRoleId() === roles.portalAdmin) && this.checkPermission(userPermissions, [
                    PermissionType.ManageInsurance,
                    PermissionType.ViewOnly
                ]) && {
                    title: 'Patient Responsibility',
                    type: MenuItemTypes.ManagePatientResponsibility,
                    action: (history) => {
                        navigationService.toPatientResponsibility(history)
                    }
                },
                this.checkPermission(userPermissions, [
                    PermissionType.AssignUnmatchedResults
                ]) && {
                    title: 'Unmatched Labs',
                    badge: <UnmatchedLabsBadgeComponent />,
                    type: MenuItemTypes.UnmatchedLabs,
                    action: (history) => {
                        navigationService.toUnmatchedLabs(history)
                    }
                },
                (authQuery.getRoleId() === roles.portalAdmin) && this.checkPermission(userPermissions, [
                    PermissionType.ManageEmployees,
                    PermissionType.ViewOnly
                ]) && {
                    title: 'Banners',
                    type: MenuItemTypes.Banners,
                    action: (history) => {
                        navigationService.toManageBanners(history)
                    }
                },
                (authQuery.getRoleId() === roles.portalAdmin) && this.checkPermission(userPermissions, [
                    PermissionType.ManageEmployees
                ]) && {
                    title: 'Health Report Templates',
                    type: MenuItemTypes.HealthReportTemplates,
                    action: (history) => {
                        navigationService.toHealthReportTemplates(history)
                    }
                },
                (authQuery.getRoleId() === roles.portalAdmin) && this.checkPermission(userPermissions, [
                    PermissionType.ManagePatients,
                    PermissionType.ViewOnly
                ]) && {
                    title: 'Common Goals',
                    type: MenuItemTypes.ManageCommonGoals,
                    action: (history) => {
                        navigationService.toCommonGoals(history)
                    }
                },
                (authQuery.getRoleId() === roles.portalAdmin) && this.checkPermission(userPermissions, [
                    PermissionType.ManagePatients,
                    PermissionType.ViewOnly
                ]) && {
                    title: 'Common MDM Plans',
                    type: MenuItemTypes.ManageCommonMdms,
                    action: (history) => {
                        navigationService.toCommonMdms(history)
                    }
                },
                (authQuery.getRoleId() === roles.portalAdmin) && this.checkPermission(userPermissions, [
                    PermissionType.ManagePatients,
                    PermissionType.ViewOnly
                ]) && {
                    title: 'Common Supplements',
                    type: MenuItemTypes.ManageCommonSupplements,
                    action: (history) => {
                        navigationService.toCommonSupplements(history)
                    }
                },
                (authQuery.getRoleId() === roles.portalAdmin) && this.checkPermission(userPermissions, [
                    PermissionType.ManagePatients,
                    PermissionType.ViewOnly
                ]) && {
                    title: 'Common Orders',
                    type: MenuItemTypes.ManageCommonOrders,
                    action: (history) => {
                        navigationService.toCommonOrders(history)
                    }
                },
                (authQuery.getRoleId() === roles.portalAdmin) && this.checkPermission(userPermissions, [
                    PermissionType.ManageEmployees,
                    PermissionType.ViewOnly
                ]) && {
                    title: 'Recommendations',
                    type: MenuItemTypes.Recommendations,
                    action: (history) => {
                        navigationService.toRecommendations(history)
                    }
                },
                this.checkPermission(userPermissions, [
                    PermissionType.ManageKbDocuments
                ]) && {
                    title: 'Knowledge Base Documents',
                    type: MenuItemTypes.KBDocuments,
                    action: (history) => {
                        navigationService.toKBDocuments(history)
                    }
                },
                (isStaffRole(authQuery.getRoleId()) ||(authQuery.getRoleId() === roles.portalAdmin)) && {
                    title: 'Conversation Templates',
                    type: MenuItemTypes.ManageConversationTemplates,
                    action: (history) => {
                        navigationService.toManageConversationTemplates(history)
                    }
                },
                (authQuery.getRoleId() === roles.portalAdmin) && this.checkPermission(userPermissions, [
                    PermissionType.ManageEmployees,
                    PermissionType.ViewOnly
                ]) && {
                    title: 'Employers Profiles',
                    type: MenuItemTypes.ManageEmployersProfiles,
                    action: (history) => {
                        navigationService.toManageEmployersProfiles(history)
                    }
                },
            ].filter(x => x).sort((a, b) => 
                handleCompare(b, a, SortingDirection.Asc, "title")
            )
        },
        this.checkPermission(userPermissions, [
            PermissionType.ManageFellowship,
            PermissionType.ViewOnly
        ]) && {
            title: 'PM Education',
            action: null,
            type: MenuItemTypes.Fellowship,
            children: [
                ...rosters.map(roster => {
                    return                 {
                        title: roster.name,
                        type: navigationNumber + roster.id,
                        action: (history) => {
                            navigationService.toFellowship(history, roster.id)
                        }
                    }
                }),
                {
                    title: 'Add New Roster',
                    type: navigationNumber + MenuItemTypes.Fellowship,
                    action: () => {
                        confirmService.confirmWithReason('Add New Roster', 'Roster title', 'Enter roster title', 'Create', '').subscribe(
                            (title) => {
                                rostersService.create({name: title}).subscribe()
                            }
                        );
                    }
                },
            ]
        },
        isFeatureFlag(featureFlags, FeatureFlag.LlmPlayground) &&{
            title: 'Generic Playground',
            type: MenuItemTypes.GenericPlayground,
            action: (history) => {
                navigationService.toGenericPlayground(history)
            }
        },
        {
            title: 'Profile',
            type: MenuItemTypes.Profile,
            action: (history) => {
                navigationService.toMyProfile(history)
            }
        },
    ].filter(menuItem => menuItem);

    private getPatientMenuItems = (featureFlags: any): MenuItem[] => [
        {
            title: 'Dashboard',
            icon: (isSelected: boolean) => <DashboardIcon isSelected={isSelected} />,
            type: MenuItemTypes.Dashboard,
            action: (history) => {
                navigationService.toDashboard(history)
            }
        },
        {
            title: 'Messaging',
            icon: (isSelected: boolean) => <ConversationsIcon isSelected={isSelected} />,
            badge: <UnreadMessagesBadgeComponent />,
            type: MenuItemTypes.Messaging,
            action: (history) => {
                navigationService.toConversations(history)
            },
        },
        isFeatureFlag(featureFlags, FeatureFlag.MenuHealthScoreNew) && {
            title: 'Health Score',
            icon: (isSelected: boolean) => <HealthScoreIcon isSelected={isSelected} />,
            type: MenuItemTypes.HealthScore,
            action: (history) => {
                navigationService.toHealthScore(history)
            },
        },
        {
            title: 'Health Report',
            icon: (isSelected: boolean) => <HealthReportIcon isSelected={isSelected} />,
            type: MenuItemTypes.HealthReport,
            children: [
                {
                    title: 'My Health Report',
                    icon: (isSelected: boolean) => <SelectedChildIcon isSelected={isSelected} />,
                    type: MenuItemTypes.MyHealthReport,
                    action: (history) => {
                        navigationService.toMyHealthReport(history)
                    },
                },
                isFeatureFlag(featureFlags, FeatureFlag.AddOnReportPage) && {
                    title: 'Add-On Reports',
                    icon: (isSelected: boolean) => <SelectedChildIcon isSelected={isSelected} />,
                    type: MenuItemTypes.AddOnReports,
                    action: (history) => {
                        navigationService.toAddOnReports(history)
                    },
                },
            ].filter(x => x),
        },
        isFeatureFlag(featureFlags, FeatureFlag.HealthPlanDashboard) && {
            title: 'Health Plan',
            icon: (isSelected: boolean) => <HealthPlanIcon isSelected={isSelected} />,
            type: MenuItemTypes.HealthPlan,
            action: (history) => {
                navigationService.toMyHealthPlan(history)
            },
        },
        {
            title: 'Labs',
            icon: (isSelected: boolean) => <LabsIcon isSelected={isSelected} />,
            type: MenuItemTypes.Results,
            children: [
                {
                    title: 'Lab Results',
                    icon: (isSelected: boolean) => <SelectedChildIcon isSelected={isSelected} />,
                    type: MenuItemTypes.LabsInputs,
                    action: (history) => {
                        navigationService.toLabs(history)
                    },
                },
                {
                    title: 'Lab Orders',
                    icon: (isSelected: boolean) => <SelectedChildIcon isSelected={isSelected} />,
                    type: MenuItemTypes.Orders,
                    action: (history) => {
                        navigationService.toOrders(history)
                    }
                },
            ],
        },
        {
            title: 'Visit Notes',
            icon: (isSelected: boolean) => <NotesIcon isSelected={isSelected} />,
            type: MenuItemTypes.Notes,
            action: (history) => {
                navigationService.toPatientNotes(history)
            }
        },
        authQuery.isSubscriptionStatusActive() &&
        {
            title: 'Appointments',
            icon: (isSelected: boolean) => <AppointmentsIcon isSelected={isSelected} />,
            type: MenuItemTypes.Appointments,
            children: [
                {
                    title: 'Upcoming Appts',
                    icon: (isSelected: boolean) => <SelectedChildIcon isSelected={isSelected} />,
                    type: MenuItemTypes.UpcommingAppointments,
                    action: (history) => {
                        navigationService.toPatientUpcommingAppointments(history)
                    },
                },
                {
                    title: 'Past Appts',
                    icon: (isSelected: boolean) => <SelectedChildIcon isSelected={isSelected} />,
                    type: MenuItemTypes.PastAppointments,
                    action: (history) => {
                        navigationService.toPatientPastAppointments(history)
                    },
                },
            ],
        },
        {
            title: 'Medical History',
            icon: (isSelected: boolean) => <MedicalHistoryIcon isSelected={isSelected} />,
            badge: <UncompletedMedicalHistoryBadgeComponent />,
            type: MenuItemTypes.Questionnaire,
            children: [
                {
                    title: 'Health Forms',
                    icon: (isSelected: boolean) => <SelectedChildIcon isSelected={isSelected} />,
                    badge: <UncompletedHealthFormsBadgeComponent />,
                    type: MenuItemTypes.HealthForms,
                    action: (history) => {
                        navigationService.toMyHealthForms(history)
                    },
                },
                {
                    title: 'Documents',
                    icon: (isSelected: boolean) => <SelectedChildIcon isSelected={isSelected} />,
                    type: MenuItemTypes.Documents,
                    action: (history) => {
                        navigationService.toDocuments(history)
                    },
                },
                {
                    title: 'Supplements & Medications',
                    icon: (isSelected: boolean) => <SelectedChildIcon isSelected={isSelected} />,
                    type: MenuItemTypes.Supplements,
                    action: (history) => {
                        navigationService.toPatientsSupplements(history)
                    }
                },
                {
                    title: 'Microbiome',
                    icon: (isSelected: boolean) => <SelectedChildIcon isSelected={isSelected} />,
                    type: MenuItemTypes.MicrobiomeInputs,
                    action: (history) => {
                        navigationService.toMicrobiome(history)
                    },
                },
            ].filter(x => x),
        },
        isFeatureFlag(featureFlags, FeatureFlag.AcademyPatientChart) && {
            title: 'Academy',
            icon: (isSelected: boolean) => <AcademyIcon isSelected={isSelected} />,
            type: MenuItemTypes.CourseHistory,
            action: (history) => {
                navigationService.toPatientCourseHistory(history)
            }
        },
        {
            title: 'Account',
            icon: (isSelected: boolean) => <PersonIcon isSelected={isSelected} />,
            type: MenuItemTypes.Account,
            children: [
                {
                    title: 'Orders',
                    icon: (isSelected: boolean) => <SelectedChildIcon isSelected={isSelected} />,
                    type: MenuItemTypes.Orders,
                    action: (history) => {
                        navigationService.toOrders(history)
                    }
                },
                {
                    title: 'Data Files',
                    icon: (isSelected: boolean) => <SelectedChildIcon isSelected={isSelected} />,
                    type: MenuItemTypes.DataFilesInputs,
                    action: (history) => {
                        navigationService.toDataFiles(history)
                    },
                },
                {
                    title: 'Refer a Friend',
                    icon: (isSelected: boolean) => <SelectedChildIcon isSelected={isSelected} />,
                    type: MenuItemTypes.ReferralLanding,
                    action: (history) => {
                        navigationService.toReferralLanding(history)
                    }
                },
                {
                    title: 'Profile',
                    icon: (isSelected: boolean) => <SelectedChildIcon isSelected={isSelected} />,
                    type: MenuItemTypes.Profile,
                    action: (history) => {
                        navigationService.toMyProfile(history);
                    },
                },
                {
                    title: 'Membership',
                    icon: (isSelected: boolean) => <SelectedChildIcon isSelected={isSelected} />,
                    type: MenuItemTypes.Membership,
                    action: (history) => {
                        navigationService.toMyMembership(history);
                    },
                },
                isFeatureFlag(featureFlags, FeatureFlag.InsuranceProfile) && insuranceQuery.getInsuranceAvailability() && {
                    title: 'Insurance',
                    icon: (isSelected: boolean) => <SelectedChildIcon isSelected={isSelected} />,
                    type: MenuItemTypes.Insurance,
                    action: (history) => {
                        navigationService.toInsurance(history);
                    },
                },
                {
                    title: 'Log Out',
                    icon: (isSelected: boolean) => <SelectedChildIcon isSelected={isSelected} />,
                    type: MenuItemTypes.Account,
                    action: () => {
                        accountService.logout();
                    },
                },
            ].filter(x => x)
        },
    ].filter(menuItem => menuItem);

    private getConversation(pages: any, roleId?: number) {
        switch (roleId) {
            case roles.staff:
            case roles.careCoordinator:
            case roles.admin:
                return pages.staffConversation;

            case roles.portalAdmin:
            case roles.licensingProvider:
            case roles.provider:
            case roles.coach:
            case roles.fellow:
            default:
                return pages.conversations;
        }
    }

    private getConversationMenu(roleId?: number) {
        switch (roleId) {
            case roles.staff:
            case roles.careCoordinator:
            case roles.admin:
                return {
                    title: 'Messaging',
                    badge: <ConversationsBadgeComponent />,
                    type: MenuItemTypes.Messaging,
                    children: [
                        {
                            title: 'All Messages',
                            icon: (isSelected: boolean) => <SelectedChildIcon isSelected={isSelected} />,
                            badge: <UnreadMessagesBadgeComponent />,
                            type: MenuItemTypes.Messaging,
                            action: (history) => {
                                navigationService.toConversations(history)
                            }
                        },
                        {
                            title: 'Patient Submissions',
                            icon: (isSelected: boolean) => <SelectedChildIcon isSelected={isSelected} />,
                            badge: <NewSupportTicketsBadgeComponent />,
                            type: MenuItemTypes.PatientsSubmissions,
                            action: (history) => {
                                navigationService.toPatientsSubmissions(history)
                            }
                        }
                    ]
                };

            case roles.portalAdmin:
            case roles.licensingProvider:
            case roles.provider:
            case roles.coach:
            case roles.fellow:
            default:
                return {
                    title: 'Messaging',
                    icon: (isSelected: boolean) => <ConversationsIcon isSelected={isSelected} />,
                    badge: <UnreadMessagesBadgeComponent />,
                    type: MenuItemTypes.Messaging,
                    action: (history) => {
                        navigationService.toConversations(history)
                    }
                };
        }
    }

    private checkPermission(userPermissions: PermissionType[], permissions: PermissionType[]) {
        if (permissions.some(p => userPermissions.includes(p)))
            return true;
        return false;
    }

    private menuItems = new Map<UserType, (featureFlags: any, userPermissions?: PermissionType[], roleId?: number, rosters?: RosterModel[]) => MenuItem[]>([
        [UserType.Patient, (featureFlags) => this.getPatientMenuItems(featureFlags)],
        [UserType.Employee, (featureFlags, userPermissions, roleId, rosters) => this.getMenuItemsByPermissions(featureFlags, userPermissions, roleId, rosters)]
    ]);

    public getMenuItems(featureFlags: object, userType: UserType, userPermissions?: PermissionType[], roleId?: number, rosters?: RosterModel[]) {
        const action = this.menuItems.get(userType);

        if (action) {
            return action(featureFlags, userPermissions, roleId, rosters);
        }

        return [];
    }

    public getMenuItemsByPermissions(featureFlags: any, userPermissions?: PermissionType[], roleId?: number, rosters?:  RosterModel[]) {
        const dashboard: MenuItem[] = [this.dashboardMenuItem];

        if (!userPermissions || !userPermissions.length) {
            return dashboard;
        }

        const employeePages = getPages(featureFlags, rosters);
        const employeeMenuItems = this.getEmployeeMenuItems(employeePages, roleId);
        const menuItems = Array.from(employeeMenuItems)
            .filter(([item]) => item)
            .map(([item, permissions]) => ({ item, permissions }));

        const availableItems = menuItems
            .filter(({ permissions }) => permissions.some(p => userPermissions.includes(p)))
            .map(c => c.item);

        const employeeMenu = isFeatureFlag(featureFlags, FeatureFlag.Navigation) ? this.getEmployeeNavigationMenuItems(userPermissions, featureFlags, roleId, rosters) : availableItems;

        const filteredItems = employeeMenu.filter(x => x.children?.length > 0 || x.action !== null);

        return dashboard.concat(filteredItems);
    }
}

export const mainMenuService = new MainMenuService();