import React from "react";
import {MicrobiomeValueModel, MicrobiomeValueRangeModel} from "../../models/healthReport.models";
import {Box} from "@material-ui/core";
import {riskColorMap} from "../../constants/healthReport.constants";
import {descendingComparator} from "../../../common/sorting/helpers/object-comparer";
import {useStyles} from "./microbiomeItemComponent.styles"

interface MicrobiomeItemComponentProps {
    value: MicrobiomeValueModel;
}

export const MicrobiomeItemComponent: React.FC<MicrobiomeItemComponentProps> = (props: MicrobiomeItemComponentProps) => {
    const {value} = props;

    const classes = useStyles();

    const determineSegmentColor = (risk: string): string => {
        if (value.value === null) {
            return riskColorMap['null'];
        }

        return riskColorMap[risk];
    }

    const calculateMultiplier = (ranges: MicrobiomeValueRangeModel[]): number => {
        const max = ranges.map(x => x.to).reduce((p, v) => {
            return ( p > v ? p : v );
        });

        return 100 / max;
    }

    const multiplier = calculateMultiplier(value.ranges);

    return (
        <>
            <Box>
                <span className="size-1_25">
                    {value.name}
                </span>
                <Box display="flex">
                    <Box width={0.5} pr={1}>
                        <Box className={classes.progress}>
                            {
                                value.ranges
                                    .sort((r1, r2) => -descendingComparator(r1, r2, 'from'))
                                    .map((range, index) => {
                                        return <div key={index} className={classes.segment} style={{
                                            width: `${range.to * multiplier - range.from * multiplier}%`,
                                            background: determineSegmentColor(range.risk)
                                        }}>
                                        </div>
                                })
                            }
                        </Box>
                        <Box width={1} pr="13.5px" ml="-13.5px">
                            {
                                <div className={classes.value}
                                     style={{marginLeft: `${(value.value ?? 0) * multiplier}%`}}>
                                    {(value.value ?? 0).toFixed(0)}
                                </div>
                            }
                        </Box>
                    </Box>
                    <Box width={0.5} pl={5}>
                        <span className="text-medium">
                            {
                                value.value !== null
                                    ? value.comment
                                    : "No data provided. Not Calculated."
                            }
                        </span>
                    </Box>
                </Box>
            </Box>
        </>
    );
}

export const MicrobiomeItemMobileComponent: React.FC<MicrobiomeItemComponentProps> = (props: MicrobiomeItemComponentProps) => {
    const {value} = props;

    const classes = useStyles();

    const determineSegmentColor = (risk: string): string => {
        if (value.value === null) {
            return riskColorMap['null'];
        }

        return riskColorMap[risk];
    }

    const calculateMultiplier = (ranges: MicrobiomeValueRangeModel[]): number => {
        const max = ranges.map(x => x.to).reduce((p, v) => {
            return ( p > v ? p : v );
        });

        return 100 / max;
    }

    const multiplier = calculateMultiplier(value.ranges);

    return (
        <>
            <Box>
                <span className="size-1_25">
                    {value.name}
                </span>
                <Box>
                    <Box width={1} mt={0.5}>
                        <Box className={classes.progress}>
                            {
                                value.ranges
                                    .sort((r1, r2) => -descendingComparator(r1, r2, 'from'))
                                    .map((range, index) => {
                                        return <div key={index} className={classes.segment} style={{
                                            width: `${range.to * multiplier - range.from * multiplier}%`,
                                            background: determineSegmentColor(range.risk)
                                        }}>
                                        </div>
                                })
                            }
                        </Box>
                        <Box width={1}>
                            {
                                <div className={classes.value}
                                     style={{marginLeft: `${(value.value ?? 0) * multiplier}%`}}>
                                    {(value.value ?? 0).toFixed(0)}
                                </div>
                            }
                        </Box>
                    </Box>
                    <Box width={1}>
                        <span className="text-medium">
                            {
                                value.value !== null
                                    ? value.comment
                                    : "No data provided. Not Calculated."
                            }
                        </span>
                    </Box>
                </Box>
            </Box>
        </>
    );
}