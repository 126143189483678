import { ReactComponent as OpenSideIcon } from "@img/icons/OpenSide.svg";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import clsx from "clsx";
import React from "react";
import { useHistory } from "react-router";
import { navigationService } from "../../../../services/navigation.service";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { MessagesPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/MessagesPlaceHolder";
import { employeeTypesNames } from "../../../employee/models/employee.enums";
import { displayShortName } from "../../helpers/messages.converter";
import { conversationTitles, ConversationType } from "../../models/conversation.models";
import { AttachmentsComponent } from "../attachmentsComponent/AttachmentsComponent";
import { ChatMembersComponent } from "../chatMembersComponent/ChatMembersComponent";
import { ConversationComponent } from "../conversationComponent/ConversationComponent";
import { MessageAvatarsComponent } from "../messageAvatarsComponent/MessageAvatarsComponent";
import { useFacade } from "./fullHealthCareChatComponent.hooks";
import { useStyles } from "./fullHealthCareChatComponent.styles";
import commonUseStyles from "../../../common/styles/common.styles";
import { profileQuery } from "../../../account/stores/profileStore";

export const FullHealthCareChatComponent: React.FC = () => {
  const classes = useStyles();
  const commonClasses = commonUseStyles();
  const history = useHistory();

  const [{ isLoading, isSideAreaOpen, conversation, author }, handleToggleSideArea] =
    useFacade();

  const theme = useTheme();
  const isMiddleScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const renderGoBack = () => {
    return (
      <Box className={classes.goBack}>
        <IconButton id="full-health-care-go-back" onClick={() => navigationService.toConversations(history)}>
          <Box display="flex" alignItems="center">
            <ArrowBackIcon className={commonClasses.colorGray1} />
            <Box ml={1} className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorGray1)}>Back</Box>
          </Box>
        </IconButton>
      </Box>
    );
  };

  const renderHeaderArea = () => {
    const defaultParticipants = [
      { image: "", initials: "HC" },
      { image: "", initials: "Pr" },
    ];

    const participantsInitials = conversation
      ? conversation.employees
        .filter((i) => i.isActive && !i.isDeleted)
        .map((i) => ({ image: profileQuery.getPhoto(i.employeeId), initials: displayShortName(i.firstName, i.lastName) }))
      : defaultParticipants;

    return (
      <Box className={classes.header}>
        <Box ml={!isSmallScreen && 3} mt={2} className={classes.titleAndAvatars}>
          <Box>
            <MessageAvatarsComponent
              participants={participantsInitials}
              size={isSmallScreen && 34}
            />
          </Box>
          <Box pl={1} className={clsx(classes.title, {
            [classes.titlePaddingTop]: participantsInitials.length == 1 && isSmallScreen,
          })}>
            {conversationTitles.get(ConversationType.HealthCare)}
          </Box>
        </Box>
        {isMiddleScreen && (
          <Box ml={2}>
            <IconButton
              id="full-health-care-side-area"
              onClick={() => handleToggleSideArea(!isSideAreaOpen)}
            >
              <OpenSideIcon className={classes.icon} />
            </IconButton>
            <Drawer
              anchor="right"
              open={isSideAreaOpen}
              onClose={() => handleToggleSideArea(!isSideAreaOpen)}
            >
              <Box className={classes.sideMobile}>{renderSideArea}</Box>
            </Drawer>
          </Box>
        )}
      </Box>
    );
  };

  const renderSideArea = (className: any) => {
    const members = conversation
      ? conversation.employees
        .filter((i) => i.isActive && !i.isDeleted)
        .map((i) => ({
          image: profileQuery.getPhoto(i.employeeId),
          firstName: i.firstName,
          lastName: i.lastName,
          position: employeeTypesNames.get(i.type),
          initials: displayShortName(i.firstName, i.lastName),
        }))
      : [
        {
          firstName: "Health",
          lastName: "Coach",
          initials: "HC",
          position: "Health Coach",
        },
        { firstName: "Provider", lastName: "", initials: "Pr", position: "Provider" },
      ];

    return (
      <Box className={className}>
        <ChatMembersComponent members={members} />

        <Divider className={classes.divider} orientation="horizontal" />

        <AttachmentsComponent />
      </Box>
    );
  };

  const renderContent = () => {
    return conversation.employees?.length ? (
      <ConversationComponent
        conversation={conversation}
        author={author}
        threadHeight="calc(100vh - 247px)"
      />
    ) : (
      <MessagesPlaceHolder message="Health Care conversation will become available when your health team has been assigned." />
    );
  };

  return (
    <Box className={classes.root}>
      {renderGoBack()}

      <div className={classes.chat}>
      {isSmallScreen && renderHeaderArea()}
        <div className={clsx("g", classes.messages, classes.messagesContent)}>
          {renderHeaderArea()}
          <Divider className={classes.divider} orientation='horizontal' />
            {!isLoading && !conversation ? (
              <MessagesPlaceHolder message="Health Care conversation will become available when your health team has been assigned." />
            ) : isLoading ? (
              <Box p={3}>
                <WildHealthLinearProgress />
              </Box>
            ) : (
              renderContent()
            )}
        </div>
        {!isMiddleScreen && (
          <div className={clsx("g", classes.side)}>
            {!isSmallScreen && (
              <Divider className={classes.divider} orientation="vertical" />
            )}
            {renderSideArea(
              clsx("flat-scroll", classes.sideContainer, classes.sideMaxHeight)
            )}
          </div>
        )}
      </div>
    </Box>
  );
};
