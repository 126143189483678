import {Box, Grid} from '@material-ui/core';
import React, {useState} from 'react';
import commonUseStyles from '../../../common/styles/common.styles';
import {MedicationsComponent} from '../../../patientSupplements/components/medicationsComponent/MedicationsComponent';
import {SupplementsComponent} from '../../../patientSupplements/components/supplementsComponent/SupplementsComponent';
import {
    MedicationsDCComponent
} from '../../../patientSupplements/components/medicationsSupplementsDCComponent/MedicationsDCComponent';
import {
    SupplementsDCComponent
} from '../../../patientSupplements/components/medicationsSupplementsDCComponent/SupplementsDCComponent';
import {MedicationItem, NotesType, RxntMedicationItemModel, SupplementItem} from '../../models/notes.models';
import {useStylesSimpleTabs} from '../../../common/components/wildHealthTabControl/wildHealthTabControl.styles';
import {WildHealthTabControl} from '../../../common/components/wildHealthTabControl/WildHealthTabControl';
import AddIcon from '@material-ui/icons/Add';
import {useStyles} from './supplementsMedicationsComponent.styles';
import {notesQuery} from '../../stores/notes';
import {FullscriptSupplementModel} from "../../../patientSupplements/models/fullscriptSupplementModels";
import {FeatureComponent} from "../../../common/components/featureFlags/FeatureComponent";
import {FeatureFlag} from "../../../common/components/featureFlags/featureFlags.models";
import {
    MedicationsRxNTComponent
} from "../../../patientSupplements/components/medicationsRxNTComponent/MedicationsRxNTComponent";
import {
    RxntMedicationsDCComponent
} from "../../../patientSupplements/components/medicationsSupplementsDCComponent/RxntMedicationsDCComponent";

export interface SupplementsMedicationsComponentProps {
    patientId: number;
    noteType: NotesType;
    medications: MedicationItem[];
    supplements: SupplementItem[];
    rxntMedications: RxntMedicationItemModel[];
    fullscriptSupplements: FullscriptSupplementModel[];
    handleAddMedications: (medication: MedicationItem) => void,
    handleAddSupplements: (supplement: SupplementItem) => void,
    handleFullscriptSupplements: (supplements: FullscriptSupplementModel[]) => void,
    handleEditMedications: (medication: MedicationItem) => void,
    handleEditSupplements: (supplement: SupplementItem) => void,
    handleRemoveMedications: (id: number) => void,
    handleRemoveSupplements: (id: number) => void,
    handleMedicationStopped: (id: number, isChecked: boolean) => void,
    handleSupplementStopped: (id: number, isChecked: boolean) => void,
}

export const SupplementsMedicationsComponent: React.FC<SupplementsMedicationsComponentProps> = (props: SupplementsMedicationsComponentProps) => {
    const {
        patientId,
        noteType,
        medications,
        supplements,
        rxntMedications,
        fullscriptSupplements,
        handleAddMedications,
        handleAddSupplements,
        handleFullscriptSupplements,
        handleEditMedications,
        handleEditSupplements,
        handleRemoveMedications,
        handleRemoveSupplements,
        handleMedicationStopped,
        handleSupplementStopped,
    } = props;
    const [selectedTab, setSelectedTab] = useState(0);

    const handleChangeTab = (tab: number) => {
        setSelectedTab(tab);
    }

    const commonClasses = commonUseStyles();
    const classes = useStyles();

    const recommendedListTabs = [
        {
            title: 'Medications',
            content: (
                <>
                    <FeatureComponent notFeatureFlag={FeatureFlag.RxntIntegration}>
                        <MedicationsComponent
                            items={medications.filter(i => !i.isInCurrent)}
                            handleAddMedications={handleAddMedications}
                            handleEditMedications={handleEditMedications}
                            handleRemoveMedications={handleRemoveMedications}
                        />
                    </FeatureComponent>
                    <FeatureComponent featureFlag={FeatureFlag.RxntIntegration}>
                        <MedicationsRxNTComponent
                            patientId={patientId}
                            items={rxntMedications?.filter(i => !i.isInCurrent) ?? []}
                        />
                    </FeatureComponent>
                </>
            ),
            keepMounted: true,
        },
        {
            title: 'Supplements',
            content: (
                <SupplementsComponent
                    patientId={patientId}
                    items={supplements.filter(i => !i.isInCurrent)}
                    fullscriptSupplements={fullscriptSupplements}
                    handleAddSupplements={handleAddSupplements}
                    handleFullscriptSupplements={handleFullscriptSupplements}
                    handleEditSupplements={handleEditSupplements}
                    handleRemoveSupplements={handleRemoveSupplements}
                />
            ),
            keepMounted: true,
        },
    ];

    const currentListTabs = [
        {
            title: 'Medications',
            content: (
                <>
                    <FeatureComponent notFeatureFlag={FeatureFlag.RxntIntegration}>
                        <MedicationsDCComponent
                            items={medications.filter(i => i.isInCurrent)}
                            handleMedicationsIsStoppedChange={(id: number, isChecked: boolean) => handleMedicationStopped(id, isChecked)}
                        />
                    </FeatureComponent>
                    <FeatureComponent featureFlag={FeatureFlag.RxntIntegration}>
                        <RxntMedicationsDCComponent items={rxntMedications}/>
                    </FeatureComponent>
                </>
            ),
            keepMounted: true,
        },
        {
            title: 'Supplements',
            content: (
                <SupplementsDCComponent
                    items={supplements.filter(i => i.isInCurrent)}
                    handleMedicationsIsStoppedChange={(id: number, isChecked: boolean) => handleSupplementStopped(id, isChecked)}
                />
            ),
            keepMounted: true,
        },
    ];

    const renderViewElement = (supplement: SupplementItem) =>
        <Box display='flex' key={supplement.id} mr={1.5} mb={1.5} className={classes.rootView}>
            <Box>
                {supplement.name}
            </Box>
            <Box
                id={`select-common-supplements-${noteType}`}
                className={classes.button}
                onClick={() => {
                    handleChangeTab(1)
                    handleAddSupplements(supplement)
                }}>
                <AddIcon fontSize='small' />
            </Box>
        </Box>

    const renderCommonSupplements = () => {
        return <Box mb={3} mt={1}>
            <Box className={commonClasses.colorGray1}>Common Supplements</Box>
            <Box mt={1.5} display='flex' flexWrap='wrap'>
                {notesQuery.getCommonSupplements().map(supplement => renderViewElement(supplement))}
            </Box>
        </Box>
    }

    return (
        <Box my={2}>
            {noteType !== NotesType.Soap && renderCommonSupplements()}
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Box>
                        <Box display='flex' justifyContent="space-between" alignItems="center">
                            <Box alignItems="center" className={commonClasses.textMedium} py={1}>
                                <span>Recommended List</span>
                            </Box>
                        </Box>
                        <Box>
                            <WildHealthTabControl items={recommendedListTabs} className={useStylesSimpleTabs()} initTab={selectedTab} onChangeCB={handleChangeTab} />
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Box>
                        <Box display='flex' justifyContent="space-between" alignItems="center">
                            <Box className={commonClasses.textMedium} py={1}>
                                <span>Current List</span>
                            </Box>
                        </Box>
                        <Box>
                            <WildHealthTabControl items={currentListTabs} className={useStylesSimpleTabs()} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}