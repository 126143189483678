import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 'fit-content',
            [theme.breakpoints.up('lg')]: {
                width: 730,
            },
        },
        divider: {
            backgroundColor: colors.separators,
            height: 1,
        },
        invoice: {
            border: `1px solid ${colors.stroke}`,
            boxSizing: 'border-box',
            boxShadow: '0px 4px 9px rgba(168, 173, 198, 0.15)',
            borderRadius: 2,
        },
        total: {
            fontFamily: 'Poppins',
            fontWeight: 600,
            fontSize: 24,
            padding: 10,
        },
    })
);
