import { useEffect, useState } from "react";
import { IErrorState } from "../../../common/validation/error-state";
import { MedicationItem } from "../../../notes/models/notes.models";
import { MedicationComponentProps } from "./medicationComponent.props";
import { medicationComponentValidator } from "./medicationComponent.validator";
import { toCurrentTimeZone } from "../../../timezones/helpers/timezone";

interface MedicationComponentState extends IErrorState {
    model: MedicationItem;
}

const initModel = () => ({
    id: 0,
    name: '',
    dosage: '',
    instructions: '',
    startDate: new Date(),
    isInCurrent: false,
    isStopped: false,
});

export function useFacade(props: MedicationComponentProps): [
    MedicationComponentState,
    (field: string, value: any) => void,
    () => void,
    () => void,
] {

    const [state, setState] = useState({
        model: Object.assign({}, initModel()),
        errors: {}
    } as MedicationComponentState);

    const handleChanges = (field: string, value: any) => {
        medicationComponentValidator.validateAndSetState(state, setState, field, value);

        const model = state.model;
        model[field] = value;

        setState({
            ...state,
            model: model
        });
    }

    const handleSubmit = () => {
        medicationComponentValidator.validateObjectAndSetState(state, setState, state.model);
        if (!medicationComponentValidator.stateIsValid(state)) {
            return;
        }

        const model = {...state.model};
        model.startDate = toCurrentTimeZone(state.model.startDate)

        props.handleSave(model);
        handleClose();
    }

    const handleClose = () => {
        props.handleClose();
    }

    useEffect(() => {
        if (!props.isOpened) {
            setState(state => ({...state, model: {...state.model, ...Object.assign({}, props.initModel)}, errors: {}}));
        }
       
        return () => {};
    }, [props.isOpened, props.initModel]);

    return [
        state,
        handleChanges,
        handleClose,
        handleSubmit
    ];
}
