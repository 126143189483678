import React from "react";
import _ from "lodash";
import CloseIcon from "@material-ui/icons/Close";
import { Box, Dialog, DialogContent, DialogTitle, Divider, IconButton, Typography } from "@material-ui/core";
import { useFacade } from "./insuranceVerificationModal.hooks";
import moment from "moment";
import { insuranceVerificationEligibilityCodeLabel, insuranceVerificationServiceTypeLabel, insuranceVerificationTimePeriodQualifierLabel } from "../../constants/insurance.constants";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";

export const InsuranceVerificationModalComponent: React.FC = () => {
    const [
        {
            isOpen,
            isLoading,
            notFound,
            details
        },
        handleClose
    ] = useFacade();


    const renderField = (title: string, value: string) => (
        <Box className="wh-tw-flex wh-tw-flex-col">
            <span className="wh-tw-text-xs">{title}</span>
            <Box className="wh-tw-shadow-inner wh-tw-p-1 wh-tw-mt-1 wh-tw-bg-slate-100">
                <span className="wh-tw-text-sm">
                    {value}
                </span>
            </Box>
        </Box>
    )

    const renderContent = () => {
        if (isLoading) {
            return (
                <Box p={5}>
                    <WildHealthLinearProgress />
                </Box>
            )
        }

        if (notFound) {
            return (
                <Box m={2} mt={0}>
                    <span>Unable to find 271 result.</span>
                </Box>
            )
        }

        return (
            <Box m={2} mt={0}>
                <DialogContent>
                    <Box className="wh-tw-flex wh-tw-flex-col wh-tw-shadow wh-tw-p-4">
                        <span>Sender</span>
                        <Box className="wh-tw-mt-2">
                            {renderField("Name", details?.sender?.organizationName)}
                        </Box>
                    </Box>
                    <Box className="wh-tw-flex wh-tw-flex-col wh-tw-shadow wh-tw-p-4 wh-tw-mt-4">
                        <span>Subscriber</span>
                        <Box className="wh-tw-mt-2 wh-tw-flex wh-tw-flex-col wh-tw-space-2">
                            {renderField("Name", `${details?.subscriber?.firstName} ${details?.subscriber?.lastName}`)}
                            {renderField("Policy Number", `${details?.subscriber?.memberId}`)}
                            {renderField("DOB", `${moment(details?.subscriber?.birthday).format('YYYY-MM-DD')}`)}
                            {details?.subscriber?.address && (
                                renderField("Address", `${details?.subscriber?.address} ${details?.subscriber?.city} ${details?.subscriber?.state} ${details?.subscriber?.zipCode}`)
                            )}
                        </Box>
                    </Box>
                    {details?.dependent && (
                        <Box className="wh-tw-flex wh-tw-flex-col wh-tw-shadow wh-tw-p-4 wh-tw-mt-4">
                        <span>Dependent</span>
                        <Box className="wh-tw-mt-2 wh-tw-flex wh-tw-flex-col wh-tw-space-2">
                            {renderField("Name", `${details?.dependent?.firstName} ${details?.dependent?.lastName}`)}
                            {renderField("Address", `${details?.dependent?.address} ${details?.dependent?.city} ${details?.dependent?.state} ${details?.dependent?.zipCode}`)}
                            {renderField("DOB", `${moment(details?.dependent?.birthday).format('YYYY-MM-DD')}`)}
                            {renderField("Insured is Subscriber", `${details?.dependent?.insuredBenefit?.insuredIsSubscriber}`)}
                            {renderField("Relationship", `${details?.dependent?.insuredBenefit?.relationshipCode}`)}
                        </Box>
                    </Box>
                    )}
                    <Box className="wh-tw-flex wh-tw-flex-col wh-tw-shadow wh-tw-p-4 wh-tw-mt-4">
                        <span>Benefits</span>
                        <Box className="wh-tw-flex wh-tw-flex-col">
                            {Object.entries(_.groupBy(details?.benefits, 'serviceTypeCode')).map(([key, value]: [string, any[]]) => (
                                <Box className="wh-tw-mt-4" key={key}>
                                    <span>Service Type: {insuranceVerificationServiceTypeLabel[key] ?? key}</span>
                                    <Box>
                                        {value.map((benefit, index) => (
                                            <Box className="wh-tw-mt-2" key={index}>
                                                {index !== 0 && <Divider className="wh-tw-my-4" />}
                                                {renderField("Type", insuranceVerificationEligibilityCodeLabel[benefit.eligibilityCode] ?? benefit.eligibilityCode)}
                                                {renderField("Coverage", benefit.coverageLevelCode)}
                                                {benefit.inNetworkIndicator && renderField("In Network", benefit.inNetworkIndicator)}
                                                {benefit.timePeriodQualifier && renderField("Time Period", insuranceVerificationTimePeriodQualifierLabel[benefit.timePeriodQualifier])}
                                                {renderField("Amount", benefit.amount)}
                                                {benefit.message && renderField("Message", benefit.message)}
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </DialogContent>
            </Box>
        );
    }

    return (
        <Dialog fullWidth open={isOpen} onClose={() => handleClose()} aria-labelledby="form-dialog-title">
            <Box mt={2} mx={3} >
                <DialogTitle className="wh-tw-flex wh-tw-justify-between wh-tw-items-center" disableTypography>
                    <Typography variant="h6">Insurance Verification Result</Typography>
                    <IconButton id="appointment-options-close" onClick={() => handleClose()}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
            </Box>
            {renderContent()}
        </Dialog>
    );
}