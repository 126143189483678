import { Box, Button } from "@material-ui/core";
import React from "react";
import { ChevronLeft } from '@material-ui/icons';
import { colors } from "../../../common/constants/colors";

interface FellowshipRegistrationBackButtonProps {
    goBack: () => void;
}

export const FellowshipRegistrationBackButton: React.FC<FellowshipRegistrationBackButtonProps> = (props: FellowshipRegistrationBackButtonProps) => {
    const {
        goBack,
    } = props;

    return (
        <Button
            id="fellowship-registration-go-back"
            onClick={() => goBack()}
            style={{
                height: 48,
                width: 100,
                color: colors.black,
                backgroundColor: colors.white,
                textTransform: "none"
            }} 
        >
            <Box display="flex" mt={0.5}>
                <Box>
                    <ChevronLeft />
                </Box>
                <Box ml={2}>
                    <span>Back</span>
                </Box>
            </Box>
        </Button>
    );
}
