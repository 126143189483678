import { Box, Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import React from 'react';
import { SelectAddOnsComponent } from '../../../addons/components/selectAddOnsComponent/selectAddOnsComponent';
import { UpdateAddressForm } from "../../../common/components/updateAddressForm/UpdateAddressForm";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { BillingSummaryComponent } from '../billingSummaryComponent/BillingSummaryComponent';
import { PaymentFormComponent } from '../paymentFormComponent/PaymentFormComponent';
import { LoginInfoComponent } from "../loginInfoComponent/LoginInfoComponent";
import { PatientInfoComponent } from "../patientInfoComponent/PatientInfoComponent";
import { useFacade } from "./joinPracticeComponent.hooks";
import { useStyles } from "./joinPracticeComponent.styles";
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from 'clsx';
import { RoutesConstants } from '../../../../constants/route.constants';
import { ImageConstants } from '../../../../constants/image.constants';
import {authQuery} from "../../../auth/stores/auth";

interface JoinPracticeComponentProps {
    practiceUrl: string
}

export const JoinPracticeComponent: React.FC<JoinPracticeComponentProps> = (props: JoinPracticeComponentProps) => {
    const classes = useStyles();

    const [
        {
            practice,
            publicKey,
            provider,
            isSubmitted,
            payer,
            addOns,
            errors,
        },
        isValid,
        isLoading,
        handleChanges,
        handleIsShippingSameAsBillingChange,
        handleSelectAddOnChange,
        validateForm,
        handleConfirmPurchase,
        recaptcha,
    ] = useFacade(props.practiceUrl);

    const commonClasses = commonUseStyles();
    const headerImage = ImageConstants.licensingWHHeaderLogoPath ;

    if (isLoading) {
        return (
            <Box p={3}>
                <WildHealthLinearProgress />
            </Box>
        )
    }

    const selectedAddOns = addOns.filter(i => i.isSelected);

    return (
        <Box p={2} className={`${classes.content} wh-form`}>
            <Grid container>
                <Grid item xs sm={2} />
                <Grid item xs>
                    <Box>
                        <img className={classes.image} src={RoutesConstants.cdnBaseLink + headerImage} alt="logo" />
                    </Box>
                    <Box m={2}>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                        >
                            <Box m={2} mb={0} className={classes.displayName}>
                                {practice.name}
                            </Box>
                            {
                                !practice.hideAddressOnSignUp && practice.address && [
                                    <Box className={classes.address}>
                                        {practice.address.streetAddress1}
                                        {practice.address.streetAddress2}
                                    </Box>,
                                    <Box className={classes.address}>
                                        {`${practice.address.city}, ${practice.address.state} ${practice.address.zipCode}`}
                                    </Box>]
                            }
                        </Grid>

                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >
                            <Box m={2} textAlign="center">
                                Unlock your secrets to health with Clarity, the world's first true precision medicine
                                algorithm. Through advanced machine learning it combines DNA analysis, biometrics,
                                microbiome, and phenotypic data to give you a blueprint for truly optimized health and
                                maximized healthspan.
                            </Box>
                        </Grid>
                        <Grid container>
                            <Grid item xs>
                                <Box my={3} mx={2}>
                                    <Box my={1} className={commonClasses.primaryTitle}>
                                        Information
                                    </Box>
                                    <Box my={2}>
                                        <PatientInfoComponent
                                            payer={payer}
                                            handleChanges={handleChanges}
                                            displayLoginInfo={false}
                                            hiddenFields={[]}
                                            readonlyFields={[]}
                                            errors={errors}
                                        />
                                    </Box>
                                </Box>
                                <Box my={3} mx={2}>
                                    <Box my={1} className={commonClasses.primaryTitle}>
                                        Billing Address
                                    </Box>
                                    <Box my={2}>
                                        <UpdateAddressForm
                                            address={payer.billingAddress}
                                            handleChanges={handleChanges}
                                            prefix={"billingAddress."}
                                            errors={errors}
                                        />
                                    </Box>
                                </Box>
                                <Box my={3} mx={2}>
                                    <Box display="flex" alignItems="center">
                                        <Box my={1} className={commonClasses.primaryTitle}>
                                            Shipping Address
                                        </Box>
                                        <Box ml={3}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={payer.isShippingSameAsBilling}
                                                        onChange={(e) => handleIsShippingSameAsBillingChange(e.target.checked)}
                                                        icon={<CheckBoxOutlineBlankIcon
                                                            className={classes.checkBoxIcon} />}
                                                        checkedIcon={<CheckBoxIcon className={classes.checkBoxIcon} />}
                                                        color="primary"
                                                    />
                                                }
                                                label="Same as billing"
                                            />
                                        </Box>
                                    </Box>
                                    <Box my={2}>
                                        <UpdateAddressForm
                                            address={payer.shippingAddress}
                                            prefix={"shippingAddress."}
                                            errors={errors}
                                            handleChanges={handleChanges}
                                            disabled={payer.isShippingSameAsBilling}
                                        />
                                    </Box>
                                </Box>
                                <Box my={3} mx={2}>
                                    <Box my={1} className={commonClasses.primaryTitle}>
                                        Portal Login Info
                                    </Box>

                                    <Box my={2}>
                                        <LoginInfoComponent handleChanges={handleChanges} errors={errors} />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs sm={1} />
                            <Grid item xs>
                                <Box my={3} px={1}>
                                    <Box className={clsx(commonClasses.primaryTitle, 'capitalize size-1_5')}>
                                        Optional Testing
                                    </Box>

                                    <SelectAddOnsComponent
                                        items={addOns}
                                        handleSelectingItem={handleSelectAddOnChange}
                                    />
                                </Box>

                                <Box my={3}>
                                    <BillingSummaryComponent selectedAddOns={selectedAddOns} />
                                </Box>

                                <Box my={3}>
                                    <PaymentFormComponent
                                        needCardData
                                        canSubmit={isValid}
                                        publicKey={publicKey}
                                        validate={validateForm}
                                        isSubmitted={isSubmitted}
                                        provider={provider}
                                        totalPrice={selectedAddOns.reduce((a, b) => a + b.price, 0)}
                                        callback={(paymentToken: string) => handleConfirmPurchase(paymentToken)}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs sm={2} />
                {recaptcha}
            </Grid>
        </Box>
    )
}
