import { Box, Grid } from "@material-ui/core";
import React from 'react';
import { PermissionType, Practice } from "../../../auth/models/auth.enums";
import { ProtectedElement } from "../../../common/components/ProtectedElement";
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { OfferPaymentPlanComponent } from "../../../payment/components/offerPaymentPlanComponent/OfferPaymentPlanComponent";
import { SubscriptionStatus } from "../../../payment/models/subscription.models";
import { ActiveMembershipCardComponent } from '../../../payment/components/activeMembershipCard/ActiveMembershipCardComponent';
import { MembershipCardComponent } from '../../../payment/components/membershipCard/MembershipCardComponent';
import { useFacade } from "./managePlanComponent.hooks";
import { CancelSubscriptionComponent } from "../cancelSubscriptionComponent/CancelSubscriptionComponent";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { ActivateSubscriptionComponent } from "../../../payment/components/activateSubscriptionComponent/ActivateSubscriptionComponent";
import { OptionalComponent } from "../../../common/components/OptionalComponent";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { PauseSubscriptionDialog } from "../pauseSubscriptionComponent/PauseSubscriptionDialog";
import { PremiumBillingComponent } from "../premiumBillingComponent/PremiumBillingComponent";

interface ManagePlanComponentProps {
    patientId: number;
    patientIntakeId: string;
}

export const ManagePlanComponent: React.FC<ManagePlanComponentProps> = (props: ManagePlanComponentProps) => {
    const {
        patientId,
    } = props;
    const [
        {
            isLoading,
            isHistoryLoading,
            isPremiumBillingLoading,
            isSuspending,
            isOfferPlanOpen,
            isCancelPlanOpen,
            isActivateSubscriptionOpen,
            isPauseSubscriptionOpen,
            suspendMonth,
            menuAnchorEl,
            activeMembership,
            paymentPlanHistories
        },
        handleToggleOfferPlanDialog,
        handleToggleCancelSubscriptionDialog,
        handleToggleActivateSubscriptionDialog,
        handleOfferPlan,
        handleActivateSubscription,
        handleCancelSubscription,
        handleViewBillingDetail,
        handleGoToOpsAdminTools,
        handleToggleAction,
        handleTogglePauseSubscriptionDialog,
        handleReactivePlan,
        handleChangeMonths,
        handleSuspend
    ] = useFacade(patientId);

    if (isLoading || isHistoryLoading || isPremiumBillingLoading) {
        return (
            <Box p={5}>
                <WildHealthLinearProgress />
            </Box>
        );
    }

    return (
        <Box p={5}>
            <Grid container spacing={3}>
                <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    alignItems="flex-start"
                >
                    <span className="size-1_5 text-medium capitalize"> MEMBERSHIPS </span>
                </Grid>

                {!!patientId && <PremiumBillingComponent patientId={patientId} />}

                <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between" px={5}>
                        <Box py={2}>
                            <span className="size-1_25 text-medium">Active Membership</span>
                        </Box>
                        <Box>
                            {
                                (!activeMembership ||
                                    activeMembership?.paymentPlan?.isTrial ||
                                    activeMembership.status !== SubscriptionStatus.Active) &&
                                    <>
                                        <OfferPaymentPlanComponent
                                            open={isOfferPlanOpen}
                                            handleSubmit={handleOfferPlan}
                                            handleClose={() => handleToggleOfferPlanDialog(false)}
                                        />

                                        <ActivateSubscriptionComponent
                                            open={isActivateSubscriptionOpen}
                                            handleSubmit={handleActivateSubscription}
                                            handleClose={() => handleToggleActivateSubscriptionDialog(false)}
                                        />

                                        <OptionalComponent
                                            practiceId={Practice.WildHealth}
                                            children={
                                                <ProtectedElement
                                                    element={
                                                        <WildHealthButton
                                                            id="manage-plan-offer"
                                                            onClick={() => handleToggleOfferPlanDialog(true)}
                                                        >
                                                            <Box px={2}>
                                                                Offer Plan
                                                            </Box>
                                                        </WildHealthButton>
                                                    }
                                                    permissions={[PermissionType.Coaching, PermissionType.ManagePatients]}
                                                />
                                            }
                                            defaultContent={
                                                <ProtectedElement
                                                    element={
                                                        <WildHealthButton
                                                            id="manage-plan-acrivate-membership"
                                                            onClick={() => handleToggleActivateSubscriptionDialog(true)}
                                                        >
                                                            <Box px={2}>
                                                                Activate Membership
                                                            </Box>
                                                        </WildHealthButton>
                                                    }
                                                    permissions={[PermissionType.Coaching, PermissionType.ManagePatients]}
                                                />
                                            }
                                        />
                                    </>
                            }
                        </Box>
                    </Box>
                    <Box px={5}>
                        <ActiveMembershipCardComponent subscription={activeMembership} paymentPlanHistories={paymentPlanHistories} menuAnchorEl={menuAnchorEl} handleToggleCancel={() => handleToggleCancelSubscriptionDialog(true)} handleGoBillingInfo={handleViewBillingDetail} handleModifyPlan={handleGoToOpsAdminTools} handleToggleAction={handleToggleAction} handleToggleSuspend={handleTogglePauseSubscriptionDialog} handleReactivePlan={handleReactivePlan} />
                        <CancelSubscriptionComponent
                            open={isCancelPlanOpen}
                            handleSubmit={handleCancelSubscription}
                            handleClose={() => handleToggleCancelSubscriptionDialog(false)}
                        />
                        <PauseSubscriptionDialog
                            open={isPauseSubscriptionOpen}
                            isSuspending={isSuspending}
                            month={suspendMonth}
                            handleChanges={handleChangeMonths}
                            handleClose={handleTogglePauseSubscriptionDialog}
                            handleSuspend={handleSuspend}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between" px={5}>
                        <Box py={2}>
                            <span className="size-1_25 text-medium">Membership History</span>
                        </Box>
                        <Box>

                        </Box>
                    </Box>
                    <Box px={5}>
                        {
                            (paymentPlanHistories && paymentPlanHistories.length > 0) ?
                                <MembershipCardComponent /> :
                                <WildHealthPlaceHolder message="No Memberships History" />
                        }
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}
