import { useState} from 'react';
import { authQuery } from '../../../../auth/stores/auth';
import { EmployeeType } from '../../../models/employee.enums';
import {RoutesConstants} from "../../../../../constants/route.constants";

interface PatientInformationComponentState {
    isHealthCoachView: boolean,
}

export function useFacade(): [
    PatientInformationComponentState,
    (patientId: number) => void,
    () => void,
] {
    const [state, setState] = useState({
        isHealthCoachView: (authQuery.getEmployeeType() === EmployeeType.HealthCoach || authQuery.getEmployeeType() === EmployeeType.Provider),
    } as PatientInformationComponentState);

    const handleLoginToRxNT = (patientId: number) => {
        window.open(RoutesConstants.loginRxNT + `?patientId=${patientId}`, "_blank")
    }

    const handleViewToggle = () => {
        setState(state => ({
            ...state, 
            isHealthCoachView: !state.isHealthCoachView,
        }));
    }


    return [state,
        handleLoginToRxNT,
        handleViewToggle,
    ]
}