import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      [theme.breakpoints.down("xs")]: {
        textAlign: "center",
        fontSize: 20,
      },
    },
    closeIcon: {
      width: 30,
      height: 30,
      color: theme.palette.grey[500],
      background: "rgba(116, 116, 128, 0.08)",
      position: "absolute",
      right: 16,
    },
  })
);
