import Axios from "axios-observable";
import { Observable } from "rxjs";
import { authHeader } from "../../common/helpers/auth-header";
import { PaginationModel } from "../../common/pagination/models/pagination-models";
import { snackService } from "../../common/snack/state";
import { HealthReportModel, HealthReportStatus, NewReviewerModel, RecommendationtSimpleModel, ReportsUnderReviewModel } from "../models/healthReport.models";
import { healthReportsStore, HealthReportsStore } from "../stores/healthReportsStore";

/**
 * Provides method for working with health report
 */
export class HealthReportServices {
    private url = `${process.env.REACT_APP_API_URL}HealthReports`;

    constructor(private healthReportsStore: HealthReportsStore) {
    }

    /**
     * Returns all active coaches
     */
    public getLast(patientId: number): Observable<HealthReportModel> {
        let url = `${this.url}/latest?patientId=${patientId}`;
        return new Observable(observer => {
            Axios.get(url, {
                headers: authHeader()
            })
                .pipe()
                .subscribe(
                    (response) => {
                        this.healthReportsStore.update({report: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.next(error);
                        observer.error();
                    }
                );
        })
    }

    public getById(reportId: number, patientId: number): void{
        let url = `${this.url}/${reportId}?patientId=${patientId}`;
        Axios.get(url, {
            headers: authHeader()
        })
            .pipe()
            .subscribe(
                (response) => {
                    this.healthReportsStore.update({report: response.data});
                },
                error => snackService.commonErrorHandler(error)
            );
    }

    public getByIdForRecommendation(reportId: number, patientId: number): void{
        let url = `${this.url}/${reportId}?patientId=${patientId}`;
        Axios.get(url, {
            headers: authHeader()
        })
            .pipe()
            .subscribe(
                (response) => {
                    this.healthReportsStore.update({reportForRecommendation: response.data});
                },
                error => snackService.commonErrorHandler(error)
            );
    }

    public getAllUnderReview( skip: number, take: number,): Observable<any> {
        let url = `${this.url}/UnderReview?` +
        `skip=${skip}&` +
        `take=${take}`;
        return new Observable(observer => {
        Axios.get<PaginationModel<ReportsUnderReviewModel>>(url, {
            headers: authHeader()
        })
            .pipe()
            .subscribe(
                (response) => {
                    this.healthReportsStore.update({
                        reportsUnderReview: response.data.data,
                        reportsTotalCount: response.data.totalCount
                    })

                    observer.next();
                    observer.complete();
                },
                error => {
                    snackService.commonErrorHandler(error);
                    observer.next();
                    observer.error();
                }
            );
        })
    }

    public getAll(patientId: number): void {
        let url = `${this.url}?patientId=${patientId}`;

        Axios.get(url, {
            headers: authHeader()
        })
            .pipe()
            .subscribe(
                (response) => {
                    this.healthReportsStore.update({reports: response.data});
                },
                error => snackService.commonErrorHandler(error)
            );
    }

    public getDefaultRecommendation(): void {
        let url = `${this.url}/recommendations`;

        Axios.get(url, {
            headers: authHeader()
        })
            .pipe()
            .subscribe(
                (response) => {
                    this.healthReportsStore.update({recommendationsDefault: response.data});
                },
                error => snackService.commonErrorHandler(error)
            );
    }

    public save(report: HealthReportModel): void {
        let url = `${this.url}`;
        Axios.put(url, report, {
            headers: authHeader()
        })
            .pipe()
            .subscribe(
                (response) => {
                    this.healthReportsStore.update({report: response.data});
                    snackService.success('Changes to health report were successfully saved!')
                },
                error => snackService.commonErrorHandler(error)
            );
    }

    public saveNewReviewer(model: NewReviewerModel): void {
        let url = `${this.url}/Reviewer`;
        Axios.put(url, model, {
            headers: authHeader()
        })
            .pipe()
            .subscribe(
                (response) => {
                    this.healthReportsStore.update({report: response.data});
                    snackService.success('Health report is passed to provider for approval!')
                },
                error => snackService.commonErrorHandler(error)
            );
    }

    public generate(patientId: number, recommendations: RecommendationtSimpleModel[]): Observable<any> {
        const data = {
            patientId: patientId,
            recommendations: recommendations,
            prefillReport: true,
        }

        return new Observable(observer => {
            Axios.post<HealthReportModel>(`${this.url}/Generate`, data, {
                headers: authHeader()
            })
                .pipe()
                .subscribe(
                    (response) => {
                        this.healthReportsStore.update({report: response.data});

                        if (response.data.status.status === HealthReportStatus.Failed) {
                            snackService.error(response.data.status.comment);
                        } else {
                            snackService.success('New report generated successfully!');
                        }

                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.next();
                        observer.error();
                    }
                );
        })
    }

    public publish(reportId: number, patientId: number): Observable<any> {
        let url = `${this.url}/${reportId}/Publish?patientId=${patientId}`;
        return new Observable(observer => {
            Axios.post<HealthReportModel>(url, {}, {
                headers: authHeader()
            })
                .pipe()
                .subscribe(
                    (response) => {
                        this.healthReportsStore.update({report: response.data});

                        if (response.data.status.status === HealthReportStatus.Failed) {
                            snackService.error(response.data.status.comment);
                        } else {
                            snackService.success('Health report is successfully published and signed!');
                        }

                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.next();
                        observer.error();
                    }
                );
        })
    }

    public regenerate(patientId: number, recommendations: RecommendationtSimpleModel[]): Observable<any> {
        const data = {
            patientId: patientId,
            recommendations: recommendations,
            prefillReport: true,
        }

        return new Observable(observer => {
            Axios.post(`${this.url}/Regenerate`, data, {
                headers: authHeader()
            })
                .pipe()
                .subscribe(
                    (response) => {
                        this.healthReportsStore.update({report: response.data});

                        if (response.data.status.status === HealthReportStatus.Failed) {
                            snackService.error(response.data.status.comment);
                        } else {
                            snackService.success('Health report was regenerated successfully!');
                        }

                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.next();
                        observer.error();
                    }
                );
        })
    }

    public getNotificationAwaitingApproval(): Observable<any> {
        return new Observable(observer => {
            Axios.get(`${this.url}/UnderReview/any`, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.healthReportsStore.update({
                            notificationAwaitingApproval: response.data,
                        });

                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);

                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }
}

export const healthReportServices = new HealthReportServices(healthReportsStore);
