import {
  Box,
  FormControl,
  TextField
} from '@material-ui/core';
import React from 'react';
import moment from 'moment';
import { useFacade } from './patientResponsibilityComponent.hooks';
import { useStyles } from "./patientResponsibilityComponent.styles";
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from 'clsx';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';

export const PatientResponsibilityComponent: React.FC = () => {
  const [
    {
      noteId,
      note,
      copay,
      coinsurance,
      deductible,
      isLoading,
      errors
    },
    handleNoteIdChange,
    handleCopayChange,
    handleCoinsuranceChange,
    handleDeductibleChange,
    handleNoteIdOnBlur,
    handleCreateInvoice
  ] = useFacade();

  const commonClasses = commonUseStyles();
  const classes = useStyles();

  return (
    <Box py={2} px={10} width={1}>
      <Box p="30px" className={commonClasses.bgWhiteMain}>
        <Box className={clsx(commonClasses.size20, commonClasses.textMedium, commonClasses.colorBlack)}>Create Patient Responsibility Invoice</Box>
        <Box display="flex" flexDirection="row">
          <Box px={10} width={0.5} mt="30px">
            <Box>
              <FormControl
                variant="outlined"
                color='primary'
                required
                size="small"
                classes={{ root: classes.root }}
                fullWidth>
                <Box className={commonClasses.inputLabel} mb={1}>Note Id</Box>
                <TextField
                  style={{ background: '#FFF' }}
                  classes={{ root: classes.root }}
                  disabled={isLoading}
                  value={noteId}
                  inputProps={{
                    name: 'note id',
                    id: 'noteid-label',
                    classes,
                  }}
                  id='noteId'
                  error={!!errors['noteId']}
                  helperText={errors['noteId']}
                  onChange={(v) => handleNoteIdChange(v.target.value)}
                  onBlur={(v) => handleNoteIdOnBlur(v.target.value)}
                />
              </FormControl>
            </Box>
            <Box mt="20px">
              <FormControl
                variant="outlined"
                color='primary'
                required
                size="small"
                classes={{ root: classes.root }}
                fullWidth>
                <Box className={commonClasses.inputLabel} mb={1}>Copay</Box>
                <TextField
                  style={{ background: '#FFF' }}
                  classes={{ root: classes.root }}
                  disabled={isLoading}
                  value={copay}
                  type='number'
                  inputProps={{
                    name: 'copay amount',
                    id: 'copay-label',
                    classes,
                  }}
                  id='copay'
                  error={!!errors['copay']}
                  helperText={errors['copay']}
                  onChange={(v) => handleCopayChange(v.target.value)}
                />
              </FormControl>
            </Box>
            <Box mt="20px">
              <FormControl
                variant="outlined"
                color='primary'
                required
                size="small"
                classes={{ root: classes.root }}
                fullWidth>
                <Box className={commonClasses.inputLabel} mb={1}>Coinsurance</Box>
                <TextField
                  style={{ background: '#FFF' }}
                  classes={{ root: classes.root }}
                  disabled={isLoading}
                  value={coinsurance}
                  type='number'
                  inputProps={{
                    name: 'coinsurance amount',
                    id: 'coinsurance-label',
                    classes,
                  }}
                  id='coinsurance'
                  error={!!errors['coinsurance']}
                  helperText={errors['coinsurance']}
                  onChange={(v) => handleCoinsuranceChange(v.target.value)}
                />
              </FormControl>
            </Box>
            <Box mt="20px">
              <FormControl
                  variant="outlined"
                  color='primary'
                  required
                  size="small"
                  classes={{ root: classes.root }}
                  fullWidth>
                  <Box className={commonClasses.inputLabel} mb={1}>Deductible</Box>
                  <TextField
                    style={{ background: '#FFF' }}
                    classes={{ root: classes.root }}
                    disabled={isLoading}
                    value={deductible}
                    type='number'
                    inputProps={{
                      name: 'deductible amount',
                      id: 'deductible-label',
                      classes,
                    }}
                    id='deductible'
                    error={!!errors['deductible']}
                    helperText={errors['deductible']}
                    onChange={(v) => handleDeductibleChange(v.target.value)}
                  />
                </FormControl>
            </Box>
            <Box mt="30px" display="flex" alignItems="center">
              <Box width="132px">
                <WildHealthButton
                  id="create-patient-responsibility-button"
                  loading={isLoading}
                  onClick={handleCreateInvoice}
                  fullWidth
                  style={{ height: 48 }}
                >
                  <Box className={clsx(commonClasses.size16, commonClasses.textMedium)}>Create</Box>
                </WildHealthButton>
              </Box>
            </Box>
          </Box>
          {note && (
            <Box px={10} width={0.5} mt="30px">
              <Box>
                <Box className={commonClasses.inputLabel} mb={1}>Patient Id</Box>
                <span>{note.patient.id}</span>
              </Box>
              <Box mt="10px">
                <Box className={commonClasses.inputLabel} mb={1}>Patient Name</Box>
                <span>{note.patient.firstName} {note.patient.lastName}</span>
              </Box>
              <Box mt="10px">
                <Box className={commonClasses.inputLabel} mb={1}>Date Of Service</Box>
                <span>{moment(note.appointment.date).format("MM/DD/YYYY")}</span>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}