import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            boxShadow: '0px 4px 9px rgba(168, 173, 198, 0.15)',
            position: 'relative',
            borderRadius: '2px',
            border: `1px solid ${colors.stroke}`,
            fontSize: 14,
            padding: 20,
            '& .MuiCardContent-root:last-child': {
                paddingBottom: 0
            },
            '& .MuiTypography-body1': {
                fontSize: 14
            },
            '& .MuiTypography-paragraph': {
                marginBottom: 12
            },
            [theme.breakpoints.down("xs")]: {
                padding: 16,
            },
        },
        cardHeader: {
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 10,
            paddingBottom: 24
        },
        cardContent: {
            padding: 0
        },
        avatar: {
            width: 50,
            height: 50,
        },
        divider: {
            backgroundColor: colors.separators,
            margin: 'auto',
            height: 1,
        },
        listItem: {
            paddingLeft: 10,
            paddingRight: 10,
            minHeight: 48,
            borderRadius: 2,
        },
        listItemSecondary: {
            paddingLeft: 10,
            paddingRight: 10,
            height: 30,
            minHeight: 30,
            borderRadius: 2,
        },
        textSecondaty: {
            position: 'relative',
            fontWeight: 500,
            fontSize: 12,
            bottom: '12px',
            "& > span": {
                paddingRight: 6,
            }

        },
        expandContent: {
            marginTop: 12
        },
        timeDivider: {
            paddingLeft: 15,
            paddingRight: 15
        },
        locationIcon: {
            marginLeft: -3
        },
        recordingConsentSection: {
            borderRadius: 4,
            backgroundColor: colors.lightGray,
            height: 70,
            display: 'flex',
            alignItems: 'center',
            padding: 15
        },
        recordingConsentIcon: {
            color: colors.gray500,
            marginRight: 30
        },
        recordingConsentText: {
            color: colors.gray500,
            fontSize: 14,
            fontWeight: 500,
            paddingLeft: 15,
            paddingRight: 15
        }
    })
);
