import { Store, StoreConfig } from '@datorama/akita';
import { HealthSummaryDataModel } from '../../../healthSummary/models/healthSummary.models';
import { PatientPharmacyInfoModel, DelinquentStatusModel } from '../../../patients/models/patient.model';
import { BusinessProfileModel } from '../../models/businessProfile.model';
import {EmployeeProfileModel, EmployeeProfilePhotoModel} from "../../models/employeeProfile.model";
import {PatientProfileModel} from "../../models/patientProfile.model";
import {PracticeInfoModel, PracticeInfoShortModel} from "../../models/practice.model";
import { PracticeProfileModel } from '../../models/practiceProfile.model';

/**
 * Represents profile state
 */
export interface ProfileState {
    practiceInfo: PracticeInfoModel;
    businessProfile: BusinessProfileModel;
    employeeProfile: EmployeeProfileModel;
    patientProfile: PatientProfileModel;
    practiceProfile: PracticeProfileModel;
    practiceShortInfo: PracticeInfoShortModel;
    patientProfileProblemList: HealthSummaryDataModel[];
    pharmacyInfo: PatientPharmacyInfoModel | null;
    employeePhotos: EmployeeProfilePhotoModel[];
    delinquentStatus: DelinquentStatusModel[];
}

export function createInitialState(): ProfileState {
    return {
        practiceInfo: null,
        businessProfile: null,
        employeeProfile: null,
        patientProfile: null,
        practiceProfile: null,
        practiceShortInfo: null,
        patientProfileProblemList: null,
        pharmacyInfo: null,
        employeePhotos: [],
        delinquentStatus: []
    };
}

@StoreConfig({name: 'profile', resettable: true })
export class ProfileStore extends Store<ProfileState> {
    constructor() {
        super(createInitialState());
    }

    public updateEmployeePhotos(photos: EmployeeProfilePhotoModel[]): void {
        const existingSource = this.getValue().employeePhotos;
        if (!existingSource.length) {
            this.update({
                employeePhotos: photos
            });
            return;
        }

        this.update({
            employeePhotos: [...existingSource, ...photos.filter(photo => existingSource.find(item => item.employeeId === photo.employeeId) === undefined)],
        });
    }
}

export const profileStore = new ProfileStore();
