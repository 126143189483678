import React from "react";
import { CommonHeaderComponent } from '../components/headers/commonHeaderComponent/CommonHeaderComponent'
import { Toolbar } from '@material-ui/core';

export class HeaderService {

    private commonHeader = CommonHeaderComponent;
    private commonToolBar = <Toolbar style={{ minHeight: 72 }} />;

    public getHeader(employerKey: boolean, hideMenu?: boolean, isLicensingSignup?:boolean, topBannerHeight?: number) {

        if(isLicensingSignup){
          return null
        }
        let header = this.commonHeader;

        return React.createElement(header, {employerKey: employerKey, hideMenu: hideMenu, isLicensingPage: isLicensingSignup, topBannerHeight: topBannerHeight});
    }

    public getHeaderToolBar(isLicensingSignup: boolean) {

        if(isLicensingSignup){
            return null;
        }
        let toolBar = this.commonToolBar;

        return toolBar;
    }
}

export const headerService = new HeaderService();