import React from 'react';
import { useFacade } from './ManageLocationsCommonComponent.hooks';
import {Box} from "@material-ui/core";


export const ManageLocationsCommonComponent: React.FC = () => {
    const [
        view,
    ] = useFacade();

    return (
        <Box>
            {
                view
            }
        </Box>
    )
}