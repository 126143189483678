import React from "react";
import { Box, Button, ButtonGroup, IconButton } from "@material-ui/core";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import { ManageEmployeesComponent } from "../../../employee/components/manageEmployeesComponent/ManageEmployeesComponent";
import { LocationModel } from "../../models/locations.models";
import EditIcon from '@material-ui/icons/Edit';
import { ProtectedElement } from "../../../common/components/ProtectedElement";
import { PermissionType } from "../../../auth/models/auth.enums";
import { UpdateLocationDialogComponent } from "../updateLocationDialogComponent/UpdateLocationDialogComponent";
import commonUseStyles from '../../../common/styles/common.styles';


interface ManageLocationUsersComponentProps {
    handleGoBack: Function;
    handleEdit: Function;
    location: LocationModel;
}

export const ManageLocationUsersComponent: React.FC<ManageLocationUsersComponentProps> = (props: ManageLocationUsersComponentProps) => {
    const { 
        handleGoBack,
        handleEdit,
        location
    } = props;
    const commonClasses = commonUseStyles();

    return (
        <Box p={3}>
            <Box display="flex " alignItems="center">
                <ButtonGroup size="large" variant="text" color="default" aria-label="large outlined primary button group">
                    <Button
                        id="manage-location-users-go-back"
                        onClick={() => handleGoBack()}
                        startIcon={<ArrowBackIosRoundedIcon/>}
                    >
                        Back To Pods
                    </Button>
                    <Button disabled style={{ fontSize: '1.6em' }}>
                        {location.name}
                    </Button>
                </ButtonGroup>
            </Box>

            <Box mt={5}>
                <Box mt={5} ml={4}>
                    <Box display="flex" alignItems="center">
                        <Box mr={3} className={commonClasses.primaryTitle}>Pod Info</Box>
                        <ProtectedElement 
                            element={
                                <Box>
                                    <IconButton
                                        id="manage-location-users-edit"
                                        onClick={() => { handleEdit() }}
                                        size="medium"
                                    >
                                        <EditIcon color="primary"/>
                                    </IconButton>
                                </Box>
                            }
                            permissions={[PermissionType.ManageLocations]}
                        />

                    </Box>
                   
                    <Box display="flex" mt={3}>
                        <Box mr={2}><b>Pod Name:</b></Box>
                        <Box>{location.name}</Box>
                    </Box>

                    <Box mt={4}><b>POD ADDRESS</b></Box>

                    <Box>
                        <Box display="flex" mt={3}>
                            <Box display="flex" mr={4}>
                                <Box mr={2}><b>Address 1:</b></Box>
                                <Box>{location.streetAddress1}</Box>
                            </Box>
                            <Box display="flex">
                                <Box mr={2}><b>State:</b></Box>
                                <Box>{location.state}</Box>
                            </Box>
                        </Box>
                        <Box display="flex" mt={3}>
                            <Box display="flex" mr={4}>
                                <Box mr={2}><b>City:</b></Box>
                                <Box>{location.city}</Box>
                            </Box>
                            <Box display="flex" >
                                <Box mr={2}><b>Zip Code:</b></Box>
                                <Box>{location.zipCode}</Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Box mt={5}>
                    <ManageEmployeesComponent 
                        maxHeight={400}
                        locationId={location.id}
                    />
                </Box>
            </Box>

            <UpdateLocationDialogComponent />
        </Box>
    )
}