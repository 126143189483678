import {createStyles, makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
    createStyles({
        container: {
          marginTop: '4.0625rem'
        },
        titleGrid: {
            marginTop: '3.75rem'
        },
        icon: {
            width: '7.8rem'
        },
        title: {
            fontSize: '1rem',
            fontWeight: 'bold',
            fontFamily:'Be Vietnam Pro',
            color: '#7C8A9D'

        },
        description: {
            fontSize: '1rem',
            fontFamily: 'Be Vietnam Pro',
            width: '27rem',
            textAlign:'center',
            color: '#7C8A9D',
            '& a': {
                textDecoration: 'underline',
                color: '#7C8A9D',
                fontWeight: 'bold',
                fontFamily:'Be Vietnam Pro',
            }
        },
        gridButton:{
            marginTop :'2.1875rem'
        },
        button:{
            color:'#1F72A2',
            fontStyle:'medium',
            fontFamily:'Be Vietnam Pro',
            fontSize:'1rem',
            textTransform: 'none'
        },
        logo: {
            width: 150,
            height: 'auto'
        }
    })
);
