import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      backgroundColor: colors.stroke,
    },
    thread: {
      flex: 1,
      minHeight: 200,
      overflow: "auto",
      outline: "none !important",
      [theme.breakpoints.down("xs")]: {
        minHeight: 0,
        maxHeight: "calc(100vh - 250px)",
      },
    },
    sendMessage: {
      flexShrink: 0,
      outline: "none !important",
    },
    closedTicket: {
      flexShrink: 0,
      width: "100%",
      display: "flex",
      justifyContent: "center",
      backgroundColor: colors.gray8,
      color: colors.black,
      fontSize: 14,
      padding: 8,
    },
    typingAvatar: {
      width: 18,
      height: 18,
      fontSize: 10,
    },
    dropContainer: {
      height: "100%",
      border: `1px solid ${colors.main}`,
      background: `${colors.lightGray2}99`,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    dropIcon: {
      color: colors.main,
      fontSize: 40,
    },
    dropTitle: {
      color: colors.main,
    },
    noneOutline: {
      outline: "none !important",
    },
  })
);

export const useDropStyles = makeStyles(() =>
  createStyles({
    dropContainer: {
      height: "100%",
      border: `1px solid ${colors.main}`,
      background: `${colors.lightGray2}99`,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    dropIcon: {
      color: colors.main,
      fontSize: 40,
    },
    dropTitle: {
      color: colors.main,
    },
  })
);
