import {authQuery} from "../../auth/stores/auth";
import {ConversationModelBase} from "../models/conversation.models";
import { profileQuery } from "../../account/stores/profileStore";

const mapParticipant = (i) => ({
    id: i.userId,
    firstName: i.firstName,
    lastName: i.lastName,
    disableRemove: i.disableRemove,
    position: ''
});

export const getConversationMembers = (conversation: ConversationModelBase, isEmployeeAssigned: boolean) => {
    let members: any[]

    if (isEmployeeAssigned) {
        const employees = conversation.employees.filter(i => i.isActive && i.userId !== authQuery.getId() && !i.isDeleted)
        const employeeAssigned = conversation.employees.filter(i => i.isActive && i.userId === authQuery.getId() && !i.isDeleted)

        members = [...conversation.patients.map(mapParticipant),
            ...employeeAssigned.map(i => ({ ...mapParticipant(i), image: profileQuery.getPhoto(i.employeeId) })),
            ...employees.map(i => ({ ...mapParticipant(i), image: profileQuery.getPhoto(i.employeeId) }))]
    } else {
        const employees = conversation.employees.filter(i => i.isActive && !i.isDeleted)

        members = [...conversation.patients.map(mapParticipant),
            ...employees.map(i => ({ ...mapParticipant(i), image: profileQuery.getPhoto(i.employeeId) }))]
    }

    return members;
}