import React from 'react';
import { useFacade } from "./createShortcutComponent.hooks";
import { Box, FormControl, FormHelperText, MenuItem, Select, TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { DialogComponentProps } from "../../../../common/dialogs/dialogProps";
import { WildHealthButton } from "../../../../common/components/wildHealthButton/WildHealthButton";

export const CreateShortcutComponent: React.FC<DialogComponentProps> = (props: DialogComponentProps) => {
    const [
        {
            groups,
            errors,
            shortcut,
            isSubmitted
        },
        handleChanges,
        handleSubmit,
        handleClose,
        isCheckedCreate
    ] = useFacade(props);

    return (
        <>
            <Dialog maxWidth="md" open={props.open} onClose={() => handleClose()} aria-labelledby="form-dialog-title">
                <form onSubmit={(e) => handleSubmit(e)}>
                    <DialogTitle id="form-dialog-title">Create shortcut</DialogTitle>
                    <DialogContent>
                        <Box style={{ width: '500px' }}>
                            <Box width={1}>
                                <Box className="wh-tw-text-xxs wh-tw-text-black wh-tw-font-medium wh-tw-uppercase" mb={1}>Label <span className="wh-tw-text-primaryV">*</span></Box>
                                <TextField
                                    id="displayName"
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{ className: 'input' }}
                                    error={!!errors['displayName']}
                                    helperText={errors['displayName']}
                                    value={shortcut?.displayName ?? ''}
                                    disabled={isSubmitted}
                                    onChange={(v) => {
                                        handleChanges(v.target.id, v.target.value)
                                    }}
                                />
                            </Box>
                            <Box width={1} mt={2}>
                                <Box className="wh-tw-text-xxs wh-tw-text-black wh-tw-font-medium wh-tw-uppercase" mb={1}>Alternative search term <span className="wh-tw-text-primaryV">*</span></Box>
                                <TextField
                                    id="name"
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{ className: 'input' }}
                                    error={!!errors['name']}
                                    helperText={errors['name']}
                                    value={shortcut?.name ?? ''}
                                    disabled={isSubmitted}
                                    onChange={(v) => {
                                        handleChanges(v.target.id, v.target.value)
                                    }}
                                />
                            </Box>
                            <Box width={1} mt={2}>
                                <Box className="wh-tw-text-xxs wh-tw-text-black wh-tw-font-medium wh-tw-uppercase" mb={1}>Group <span className="wh-tw-text-primaryV">*</span></Box>
                                <FormControl
                                    size="small"
                                    color='primary'
                                    fullWidth
                                    error={!!errors['groupId']}
                                    variant="outlined">
                                    <Select
                                        style={{ background: '#FFF' }}
                                        labelId="select-group-label"
                                        id="groupId"
                                        value={shortcut?.groupId ?? ''}
                                        disabled={isSubmitted}
                                        onChange={(v) => handleChanges('groupId', v.target.value)}>
                                        {
                                            groups.map((group) => {
                                                return <MenuItem
                                                    key={group.id}
                                                    value={group.id}>
                                                    {group.name}
                                                </MenuItem>
                                            })
                                        }
                                    </Select>
                                    <FormHelperText>{errors['groupId']}</FormHelperText>
                                </FormControl>
                            </Box>
                            <Box mt={2}>
                                <Box className="wh-tw-text-xxs wh-tw-text-black wh-tw-font-medium wh-tw-uppercase" mb={1}>Shortcut content <span className="wh-tw-text-primaryV">*</span></Box>
                                <TextField
                                    style={{ background: '#FFF' }}
                                    id="content"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={5}
                                    rowsMax={5}
                                    InputProps={{ className: 'input' }}
                                    error={!!errors['content']}
                                    helperText={errors['content']}
                                    value={shortcut?.content ?? ''}
                                    disabled={isSubmitted}
                                    onChange={(v) => {
                                        handleChanges(v.target.id, v.target.value)
                                    }}>
                                </TextField>
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions className="wh-tw-p-6">
                        <WildHealthButton
                            id="create-shortcut-cancel"
                            color='secondary'
                            size="large"
                            borderRadius={50}
                            disabled={isSubmitted}
                            onClick={() => handleClose()}
                        >
                            Cancel
                        </WildHealthButton>
                        <WildHealthButton
                            id="create-shortcut-create"
                            size="large"
                            borderRadius={50}
                            loading={isSubmitted}
                            disabled={!isCheckedCreate()}
                            onClick={(e) => handleSubmit(e)}
                        >
                            Create
                        </WildHealthButton>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}