import React from "react";
import {TabItem} from "../../../common/components/wildHealthTabControl/wildHealthTabControl.hooks";
import {SignOffNotesReviewComponent} from "../../components/SignOffNotesReviewComponent/SignOffNotesReviewComponent";
import {
    SignOffNotesRequestsComponent
} from "../../components/SignOffNotesRequestsComponent/SignOffNotesRequestsComponent";

export function useFacade(): [
    TabItem[]
] {
    const tabItems: TabItem[] = [
        {
            title: 'my review list',
            content: <SignOffNotesReviewComponent/>,
            keepMounted: false,
        },
        {
            title: 'request tracker',
            content: <SignOffNotesRequestsComponent/>,
            keepMounted: false,
        },
    ];

    return [tabItems];
}