import Axios from 'axios-observable';
import { authHeader } from "../../common/helpers/auth-header";
import { snackService } from "../../common/snack/state";
import { EmployeesStore, employeesStore } from "../stores/employeesStore";

/**
 * Provides method for working with roles
 */
export class RolesService {
    private url = `${process.env.REACT_APP_API_URL}Roles`;

    constructor(private employeesStore: EmployeesStore) {
    }

    /**
     * Returns all roles
     */
    public get(): void {
        const config = {
            headers: authHeader()
        };

        Axios.get(this.url, config)
            .pipe()
            .subscribe(
                response => this.employeesStore.update({ roles: response.data }),
                error => snackService.commonErrorHandler(error)
            );
    }
}

export const rolesService = new RolesService(employeesStore);
