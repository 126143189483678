import { PatientAppointmentModel } from '../../models/appointments.models';
import { AppointmentsState, AppointmentsStore, appointmentsStore } from './appointments.store';
import { Query } from '@datorama/akita';

/**
 * Provides method for working with appointments store
 */
export class AppointmentsQuery extends Query<AppointmentsState> {
    patientAppointments$ = this.select(state => state.patientAppointments);
    employeeAppointments$ = this.select(state => state.employeeAppointments);
    appointmentOptions$ = this.select(state => state.appointmentOptions);
    recentlyAddedAppointments$ = this.select(state=>state.recentlyAddedAppointments);
    recentlyNoUploadedLabFileAppointments$ = this.select(state=>state.recentlyNoUploadedLabFileAppointments);
    appointmentTypes$ = this.select(state => state.appointmentTypes);
    allAppointmentTypes$ = this.select(state => state.allAppointmentTypes);
    appointmentsSummary$ = this.select(state => state.appointmentsSummary);
    appointmentsStatistic$ = this.select(state => state.appointmentsStatistic);
    appointmentsIncludeTags$ = this.select(state => state.appointmentsIncludeTags);
    appointmentSequenceInfo$ = this.select(state => state.appointmentSequenceInfo);

    constructor(protected store: AppointmentsStore) {
        super(store);
    }

    /**
     * Returns appointments
     */
    public getToken(): Array<PatientAppointmentModel> {
        return this.getValue().patientAppointments;
    }
}

export const appointmentsQuery = new AppointmentsQuery(appointmentsStore);