import { Box, Divider, FormControl, Grid, MenuItem, Select, TextField } from '@material-ui/core';
import React from 'react';
import { PermissionType, Practice } from '../../../auth/models/auth.enums';
import { ProtectedElement } from '../../../common/components/ProtectedElement';
import { OptionalComponent } from '../../../common/components/OptionalComponent';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { getNumericKeys } from "../../../common/helpers/enum-mapper";
import {
    diabetesNames,
    dietNames,
    ethnicityNames,
    exerciseNames,
    optimizationNames
} from "../../constants/inputs.constants";
import {
    DiabetesType, DietType, Ethnicity, ExerciseType,
    Optimization, SmokingCategory, YesNo
} from "../../models/input.models";
import { InputsComponentProps } from "../inputsComponentProps";
import { useFacade } from "./generalInputsComponent.hooks";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import clsx from 'clsx';
import commonUseStyles from '../../../common/styles/common.styles';
import { RoutesConstants } from "../../../../constants/route.constants";
import CachedIcon from '@material-ui/icons/Cached';

export const GeneralInputsComponent: React.FC<InputsComponentProps> = (props: InputsComponentProps) => {
    const { patientId } = props;
    const [
        {
            inputs,
            errors,
            isSaving,
            isVitalSaving,
            isCalculating,
            errorMesa
        },
        handleChanges,
        handleNumericChanges,
        handleSubmit,
        handleCalculateMesa
    ] = useFacade(patientId);
    const commonClasses = commonUseStyles();

    if (!inputs) {
        return (
            <WildHealthLinearProgress />
        );
    }

    return (
        <Box p={5}>
            <Box>
                <Grid container>
                    <Grid item xs={5}>
                        <Box display="flex" alignItems="center" justifyContent="flex-end">
                            <Box pr={2}>
                                <span className="text-medium">Client Exercise Goal:</span>
                            </Box>
                            <Box width="150px">
                                <FormControl color='primary' variant="outlined" size="small" fullWidth>
                                    <Select
                                        style={{ background: '#FFF' }}
                                        id="exerciseGoal"
                                        value={inputs.exerciseGoal}
                                        onChange={(v) => handleChanges("exerciseGoal", v.target.value)}>
                                        {
                                            getNumericKeys(ExerciseType).map((exerciseType, i) => {
                                                return <MenuItem key={i} value={exerciseType}>{exerciseNames[exerciseType]}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
                            <Box pr={2}>
                                <span className="text-medium">Client Diet Choice:</span>
                            </Box>
                            <Box width="150px">
                                <FormControl color='primary' variant="outlined" size="small" fullWidth>
                                    <Select
                                        style={{ background: '#FFF' }}
                                        id="dietChoice"
                                        value={inputs.dietChoice}
                                        onChange={(v) => handleChanges("dietChoice", v.target.value)}>
                                        {
                                            getNumericKeys(DietType).map((dietType, i) => {
                                                return <MenuItem key={i} value={dietType}>{dietNames[dietType]}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
                            <Box pr={2}>
                                <span className="text-medium">Exercise Activities per week (0-7):</span>
                            </Box>
                            <Box width="150px">
                                <TextField
                                    error={!!errors["exerciseActivitiesFrequency"]}
                                    helperText={errors["exerciseActivitiesFrequency"]}
                                    type="number"
                                    InputProps={{ className: 'input' }}
                                    value={inputs.exerciseActivitiesFrequency ?? ''}
                                    onChange={(v) => handleNumericChanges("exerciseActivitiesFrequency", v.target.value)}
                                    variant="outlined"
                                    size="small"
                                />
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
                            <Box pr={2}>
                                <span className="text-medium">MindFullness Activities per week (0-7):</span>
                            </Box>
                            <Box width="150px">
                                <TextField
                                    error={!!errors["meditationFrequency"]}
                                    helperText={errors["meditationFrequency"]}
                                    type="number"
                                    InputProps={{ className: 'input' }}
                                    value={inputs.meditationFrequency ?? ''}
                                    onChange={(v) => handleNumericChanges("meditationFrequency", v.target.value)}
                                    variant="outlined"
                                    size="small"
                                />
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
                            <Box pr={2}>
                                <span className="text-medium">REM:</span>
                            </Box>
                            <Box width="150px">
                                <TextField
                                    error={!!errors["rem"]}
                                    helperText={errors["rem"]}
                                    type="number"
                                    InputProps={{ className: 'input' }}
                                    value={inputs.rem ?? ''}
                                    onChange={(v) => handleNumericChanges("rem", v.target.value)}
                                    variant="outlined"
                                    size="small"
                                />
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
                            <Box pr={2}>
                                <span className="text-medium">Ethnicity:</span>
                            </Box>
                            <Box width="150px">
                                <FormControl color='primary' variant="outlined" size="small" fullWidth>
                                    <Select
                                        style={{ background: '#FFF' }}
                                        id="ethnicity"
                                        value={inputs.ethnicity}
                                        onChange={(v) => handleChanges("ethnicity", v.target.value)}>
                                        {
                                            getNumericKeys(Ethnicity).map((eth, i) => {
                                                return <MenuItem key={i} value={eth}>{ethnicityNames[eth]}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
                            <Box pr={2}>
                                <span className="text-medium">Smoking Status:</span>
                            </Box>
                            <Box width="150px">
                                <FormControl color='primary' variant="outlined" size="small" fullWidth>
                                    <Select
                                        style={{ background: '#FFF' }}
                                        id="smokingCategory"
                                        value={inputs.smokingCategory}
                                        onChange={(v) => handleChanges("smokingCategory", v.target.value)}>
                                        {
                                            getNumericKeys(SmokingCategory).map((category, i) => {
                                                return <MenuItem key={i} value={category}>{SmokingCategory[category]}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
                            <Box pr={2}>
                                <span className="text-medium">Diabetes Status:</span>
                            </Box>
                            <Box width="150px">
                                <FormControl color='primary' variant="outlined" size="small" fullWidth>
                                    <Select
                                        style={{ background: '#FFF' }}
                                        id="diabetesType"
                                        value={inputs.diabetesType}
                                        onChange={(v) => handleChanges("diabetesType", v.target.value)}>
                                        {
                                            getNumericKeys(DiabetesType).map((type, i) => {
                                                return <MenuItem key={i} value={type}>{diabetesNames[type]}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
                            <Box pr={2}>
                                <span className="text-medium">
                                    MESA CVD Risk (%):
                                </span>
                            </Box>
                            <Box width="150px">
                                <TextField
                                    error={!!errors["mesa"]}
                                    helperText={errors["mesa"]}
                                    type="number"
                                    InputProps={{ className: 'input' }}
                                    value={inputs.mesa ?? ''}
                                    disabled={true}
                                    variant="outlined"
                                    size="small"
                                />
                                
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="flex-end">
                            <Box mr="20px">
                                <span>
                                    <a id="general-inputs-calculate-mesa" target="_blank" rel="noopener noreferrer" href={RoutesConstants.mesaRiskUrl}>
                                        (calculate online)
                                    </a>
                                </span>
                            </Box>
                            <Box width="150px" mt={1}>
                                {
                                    errorMesa &&
                                    <Box className="wh-tw-text-bad wh-tw-text-xs" mb={1}>{errorMesa}</Box>
                                }
                                <WildHealthButton
                                    id="calculate-mesa-score"
                                    disabled={isSaving || isVitalSaving}
                                    loading={isCalculating}
                                    onClick={() => handleCalculateMesa()}
                                    color="secondary"
                                    border="none"
                                    fullWidth
                                >
                                    <CachedIcon />
                                    <Box ml={1}>Calculate</Box>
                                </WildHealthButton>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={7}>
                        <Box display="flex" alignItems="center" justifyContent="flex-end">
                            <Box pr={2}>
                                <span className="text-medium">Deep Sleep (hours):</span>
                            </Box>
                            <Box width="120px">
                                <TextField
                                    error={!!errors["deepSleep"]}
                                    helperText={errors["deepSleep"]}
                                    type="number"
                                    InputProps={{ className: 'input' }}
                                    value={inputs.deepSleep ?? ''}
                                    onChange={(v) => handleNumericChanges("deepSleep", v.target.value)}
                                    variant="outlined"
                                    size="small"
                                />
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
                            <Box pr={2}>
                                <span className="text-medium">Sleep:</span>
                            </Box>
                            <Box width="120px">
                                <TextField
                                    error={!!errors["sleep"]}
                                    helperText={errors["sleep"]}
                                    type="number"
                                    InputProps={{ className: 'input' }}
                                    value={inputs.sleep ?? ''}
                                    onChange={(v) => handleNumericChanges("sleep", v.target.value)}
                                    variant="outlined"
                                    size="small"
                                />
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
                            <Box pr={2}>
                                <span className="text-medium">Chronological Age:</span>
                            </Box>
                            <Box width="120px">
                                <TextField
                                    type="number"
                                    value={inputs.chronologicalAge ?? ''}
                                    variant="outlined"
                                    size="small"
                                    disabled
                                />
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
                            <Box pr={2}>
                                <span className="text-medium">Age Bio:</span>
                            </Box>
                            <Box width="120px">
                                <TextField
                                    error={!!errors["realAge"]}
                                    helperText={errors["realAge"]}
                                    type="number"
                                    InputProps={{ className: 'input' }}
                                    value={inputs.realAge ?? ''}
                                    onChange={(v) => handleNumericChanges("realAge", v.target.value)}
                                    variant="outlined"
                                    size="small"
                                />
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="flex-end">
                            <Box mr="140px">
                                <span>
                                    <a id="general-inputs-calculate-age" target="_blank" rel="noopener noreferrer" href={RoutesConstants.ageBioUrl}>
                                        (calculate online)
                                    </a>
                                </span>
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
                            <Box pr={2}>
                                <span className="text-medium">Epigenetic Age:</span>
                            </Box>
                            <Box width="120px">
                                <TextField
                                    error={!!errors["biologicalAge"]}
                                    helperText={errors["biologicalAge"]}
                                    type="number"
                                    InputProps={{ className: 'input' }}
                                    value={inputs.biologicalAge ?? ''}
                                    onChange={(v) => handleNumericChanges("biologicalAge", v.target.value)}
                                    variant="outlined"
                                    size="small"
                                />
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
                            <Box pr={2}>
                                <span className="text-medium">Fasting Regularly:</span>
                            </Box>
                            <Box width="120px">
                                <FormControl color='primary' variant="outlined" size="small" fullWidth>
                                    <Select
                                        style={{ background: '#FFF' }}
                                        id="fastingRegularly"
                                        value={inputs.fastingRegularly}
                                        onChange={(v) => handleChanges("fastingRegularly", v.target.value)}>
                                        {
                                            getNumericKeys(YesNo).map((yn, i) => {
                                                return <MenuItem key={i} value={yn}>{YesNo[yn]}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
                            <Box pr={2}>
                                <span className="text-medium">Cancer Screening Completed:</span>
                            </Box>
                            <Box width="120px">
                                <FormControl color='primary' variant="outlined" size="small" fullWidth>
                                    <Select
                                        style={{ background: '#FFF' }}
                                        id="cancerScreeningCompleted"
                                        value={inputs.cancerScreeningCompleted}
                                        onChange={(v) => handleChanges("cancerScreeningCompleted", v.target.value)}>
                                        {
                                            getNumericKeys(YesNo).map((yn, i) => {
                                                return <MenuItem key={i} value={yn}>{YesNo[yn]}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
                            <Box pr={2}>
                                <span className="text-medium">Angina or Heart Attack in a 1st Degree Relative &lt; 60:</span>
                            </Box>
                            <Box width="120px">
                                <FormControl color='primary' variant="outlined" size="small" fullWidth>
                                    <Select
                                        style={{ background: '#FFF' }}
                                        id="familyHeartAttack"
                                        value={inputs.familyHeartAttack}
                                        onChange={(v) => handleChanges("familyHeartAttack", v.target.value)}>
                                        {
                                            getNumericKeys(YesNo).map((yn, i) => {
                                                return <MenuItem key={i} value={yn}>{YesNo[yn]}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
                            <Box pr={2}>
                                <span className="text-medium">Chronic Kidney Disease (stage 4 or 5):</span>
                            </Box>
                            <Box width="120px">
                                <FormControl color='primary' variant="outlined" size="small" fullWidth>
                                    <Select
                                        style={{ background: '#FFF' }}
                                        id="chronicKidneyDisease"
                                        value={inputs.chronicKidneyDisease}
                                        onChange={(v) => handleChanges("chronicKidneyDisease", v.target.value)}>
                                        {
                                            getNumericKeys(YesNo).map((yn, i) => {
                                                return <MenuItem key={i} value={yn}>{YesNo[yn]}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
                            <Box pr={2}>
                                <span className="text-medium">Atrial Fibrillation:</span>
                            </Box>
                            <Box width="120px">
                                <FormControl color='primary' variant="outlined" size="small" fullWidth>
                                    <Select
                                        style={{ background: '#FFF' }}
                                        id="atrialFibrillation"
                                        value={inputs.atrialFibrillation}
                                        onChange={(v) => handleChanges("atrialFibrillation", v.target.value)}>
                                        {
                                            getNumericKeys(YesNo).map((yn, i) => {
                                                return <MenuItem key={i} value={yn}>{YesNo[yn]}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
                            <Box pr={2}>
                                <span className="text-medium">On Blood Pressure Treatment:</span>
                            </Box>
                            <Box width="120px">
                                <FormControl color='primary' variant="outlined" size="small" fullWidth>
                                    <Select
                                        style={{ background: '#FFF' }}
                                        id="bloodPressureTreatment"
                                        value={inputs.bloodPressureTreatment}
                                        onChange={(v) => handleChanges("bloodPressureTreatment", v.target.value)}>
                                        {
                                            getNumericKeys(YesNo).map((yn, i) => {
                                                return <MenuItem key={i} value={yn}>{YesNo[yn]}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
                            <Box pr={2}>
                                <span className="text-medium">Rheumatoid Arthritis:</span>
                            </Box>
                            <Box width="120px">
                                <FormControl color='primary' variant="outlined" size="small" fullWidth>
                                    <Select
                                        style={{ background: '#FFF' }}
                                        id="rheumatoidArthritis"
                                        value={inputs.rheumatoidArthritis}
                                        onChange={(v) => handleChanges("rheumatoidArthritis", v.target.value)}>
                                        {
                                            getNumericKeys(YesNo).map((yn, i) => {
                                                return <MenuItem key={i} value={yn}>{YesNo[yn]}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box mt={5}>
                <Divider className="tab-divider" />
            </Box>

            <OptionalComponent practiceId={Practice.WildHealth}>
                <>
                    <Box my={2} display="flex" justifyContent="flex-start" className={clsx(commonClasses.primaryTitle, 'text-medium')}>
                        Optimization
                    </Box>

                    <Box mt={5}>
                        <Grid container>
                            <Grid item xs={5}>
                                <Box display="flex" alignItems="center" justifyContent="flex-end">
                                    <Box pr={2}>
                                        <span className="text-medium">Brain Optimization:</span>
                                    </Box>
                                    <Box width="150px">
                                        <FormControl color='primary' variant="outlined" size="small" fullWidth>
                                            <Select
                                                style={{ background: '#FFF' }}
                                                id="brainOptimization"
                                                value={inputs.brainOptimization}
                                                onChange={(v) => handleChanges("brainOptimization", v.target.value)}>
                                                {
                                                    getNumericKeys(Optimization).map((optimization, i) => {
                                                        return <MenuItem key={i} value={optimization}>{optimizationNames[optimization]}</MenuItem>
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Box>
                                <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
                                    <Box pr={2}>
                                        <span className="text-medium">Mind Optimization:</span>
                                    </Box>
                                    <Box width="150px">
                                        <FormControl color='primary' variant="outlined" size="small" fullWidth>
                                            <Select
                                                style={{ background: '#FFF' }}
                                                id="mindOptimization"
                                                value={inputs.mindOptimization}
                                                onChange={(v) => handleChanges("mindOptimization", v.target.value)}>
                                                {
                                                    getNumericKeys(Optimization).map((optimization, i) => {
                                                        return <MenuItem key={i} value={optimization}>{optimizationNames[optimization]}</MenuItem>
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Box>
                                <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
                                    <Box pr={2}>
                                        <span className="text-medium">Body Optimization:</span>
                                    </Box>
                                    <Box width="150px">
                                        <FormControl color='primary' variant="outlined" size="small" fullWidth>
                                            <Select
                                                style={{ background: '#FFF' }}
                                                id="bodyOptimization"
                                                value={inputs.bodyOptimization}
                                                onChange={(v) => handleChanges("bodyOptimization", v.target.value)}>
                                                {
                                                    getNumericKeys(Optimization).map((optimization, i) => {
                                                        return <MenuItem key={i} value={optimization}>{optimizationNames[optimization]}</MenuItem>
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={7}>
                                <Box>
                                    <Box display="flex" alignItems="center" justifyContent="flex-end">
                                        <Box pr={2}>
                                            <span className="text-medium">Gut Optimization:</span>
                                        </Box>
                                        <Box width="150px">
                                            <FormControl color='primary' variant="outlined" size="small" fullWidth>
                                                <Select
                                                    style={{ background: '#FFF' }}
                                                    id="gutOptimization"
                                                    value={inputs.gutOptimization}
                                                    onChange={(v) => handleChanges("gutOptimization", v.target.value)}>
                                                    {
                                                        getNumericKeys(Optimization).map((optimization, i) => {
                                                            return <MenuItem key={i} value={optimization}>{optimizationNames[optimization]}</MenuItem>
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Box>
                                    <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
                                        <Box pr={2}>
                                            <span className="text-medium">Longevity Optimization:</span>
                                        </Box>
                                        <Box width="150px">
                                            <FormControl color='primary' variant="outlined" size="small" fullWidth>
                                                <Select
                                                    style={{ background: '#FFF' }}
                                                    id="longevityOptimization"
                                                    value={inputs.longevityOptimization}
                                                    onChange={(v) => handleChanges("longevityOptimization", v.target.value)}>
                                                    {
                                                        getNumericKeys(Optimization).map((optimization, i) => {
                                                            return <MenuItem key={i} value={optimization}>{optimizationNames[optimization]}</MenuItem>
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Box>
                                    <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
                                        <Box pr={2}>
                                            <span className="text-medium">Bio Hacking Optimization:</span>
                                        </Box>
                                        <Box width="150px">
                                            <FormControl color='primary' variant="outlined" size="small" fullWidth>
                                                <Select
                                                    style={{ background: '#FFF' }}
                                                    id="bioHackingOptimization"
                                                    value={inputs.bioHackingOptimization}
                                                    onChange={(v) => handleChanges("bioHackingOptimization", v.target.value)}>
                                                    {
                                                        getNumericKeys(Optimization).map((optimization, i) => {
                                                            return <MenuItem key={i} value={optimization}>{optimizationNames[optimization]}</MenuItem>
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            </OptionalComponent>

            <Box my={2} display="flex" justifyContent="flex-end">
                <ProtectedElement
                    element={
                        <Box mt={5}>
                            <WildHealthButton
                                id="general-inputs-save"
                                disabled={isSaving || isVitalSaving || isCalculating}
                                loading={isSaving || isVitalSaving}
                                onClick={() => handleSubmit()}
                            >
                                Save Changes
                            </WildHealthButton>
                        </Box>}
                    permissions={[PermissionType.LabsModification]}
                />
            </Box>
        </Box>
    );
}