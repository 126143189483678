import {Store, StoreConfig} from '@datorama/akita';

export enum SuppsMeddsTabs {
    Supps = 0,
    Medds = 1,
}

/**
 * Represents coaches state
 */
export interface MeddsSuppsState {
    tab: SuppsMeddsTabs;
}

/**
 * Creates initial state
 */
export function createInitialState(): MeddsSuppsState {
    return {
        tab: 0,
    };
}

/**
 * Provides coaches states management
 */
@StoreConfig({name: 'meddsSupps', resettable: true })
export class MeddsSuppsStore extends Store<MeddsSuppsState> {
    constructor() {
        super(createInitialState());
    }

    setTab(tab: SuppsMeddsTabs) {
        this.update({tab: tab});
    }
}

export const meddsSuppsStore = new MeddsSuppsStore();