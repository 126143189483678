import React from 'react';
import { PaymentBenefitModel } from "../../models/paymentBenefit.models";
import { Box } from "@material-ui/core";
import { Check } from '@material-ui/icons';
import commonUseStyles from '../../../common/styles/common.styles';
import { colors } from '../../../common/constants/colors';

interface PaymentBenefitsComponentProps {
    benefits: PaymentBenefitModel[];
}

export const PaymentBenefitsComponent: React.FC<PaymentBenefitsComponentProps> = (props: PaymentBenefitsComponentProps) => {
    const {
        benefits
    } = props;

    const commonClasses = commonUseStyles();

    const renderBenefitText = (benefit: PaymentBenefitModel): JSX.Element => {
        const benefitTextItems = benefit.text.split("\n");

        const firstItem = benefitTextItems.shift();

        return (
            <Box>
                <Box>
                    <div dangerouslySetInnerHTML={{ __html: firstItem }} />
                </Box>
                <Box mt={1} ml={2}>
                    <ul style={{ listStyleType: 'disc', color: colors.gray1 }}>
                        {
                            benefitTextItems.map((item, index) => (
                                <li key={index}>
                                    {item}
                                </li>
                            ))
                        }
                    </ul>
                </Box>
            </Box>

        );
    }

    return (
        <>
            <Box mt={3}>
                {
                    benefits.map((benefit, index) => {
                        return (
                            <Box key={index} mt={1}>
                                <Box display="flex" alignItems="start">
                                    <Box>
                                        <Check className={commonClasses.colorMain} />
                                    </Box>
                                    <Box ml={1}>
                                        <span className={commonClasses.textSemiBold}>{benefit.title} </span>
                                        {benefit.text && renderBenefitText(benefit)}
                                    </Box>
                                </Box>
                            </Box>
                        )
                    })
                }
            </Box>
        </>
    )
};