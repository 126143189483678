import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  CircularProgress,
  Dialog,
  DialogContent
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import TrashIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit'
import { useFacade } from './commonMdmPlanEditComponent.hooks';
import { useStyles } from "./commonMdmPlanEditComponent.styles";
import { WildHealthContainer } from '../../../common/components/wildHealthContainer/WildHealthContainer';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { FeatureComponent } from "../../../common/components/featureFlags/FeatureComponent";
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';

interface CommonMdmPlanEditComponentProps {
  mdmPlanId: number;
}

export const CommonMdmPlanEditComponent: React.FC<CommonMdmPlanEditComponentProps> = (props: CommonMdmPlanEditComponentProps) => {
  const [
    {
      isLoading,
      isSubmitting,
      isOpen,
      targetMdmPlan,
      createReason,
      selectedId,
      canSubmit,
      errors,
    },
    handleChanges,
    handleChangeMdmReason,
    handleUpdateMdmPlan,
    handleSaveMdmReason,
    handleDeleteMdmReason,
    handleToggleMdmReasonModify
  ] = useFacade(props.mdmPlanId);

  const classes = useStyles();
  
  if (isLoading) {
    return <WildHealthLinearProgress />
  }

  const renderTable = () => {
    if (!isLoading && (!targetMdmPlan || !targetMdmPlan.reasons.length)) {
      return <WildHealthPlaceHolder message="No Reasons found" />
    }

    return (
      <>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <WildHealthTableCell>
                  Name
                </WildHealthTableCell>
                <WildHealthTableCell>
                  Action
                </WildHealthTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {targetMdmPlan.reasons.map(reason => (
                <WildHealthTableRow key={reason.id}>
                  <WildHealthTableCell align="left">
                      <Box className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-black">
                          {reason.name}
                      </Box>
                  </WildHealthTableCell>
                  <WildHealthTableCell style={{ width: '15%' }}>
                    <IconButton
                      id="edit-reason-btn"
                      disabled={isSubmitting}
                      onClick={() => handleToggleMdmReasonModify(reason.id)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      id="remove-reason-btn"
                      disabled={isSubmitting}
                      onClick={() => handleDeleteMdmReason(reason.id)}
                    >
                      {isSubmitting && selectedId === reason.id ? <CircularProgress size={24} className="wh-tw-text-primaryV" /> : <TrashIcon />}
                    </IconButton>
                  </WildHealthTableCell>
                </WildHealthTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }

  return (
    <Box py={2} px={10} width={1}>
      <Box my={2} display="flex" alignItems="center" justifyContent="space-between">
          <Box>
              <FeatureComponent featureFlag={FeatureFlag.Navigation}>
                  <Box className="wh-tw-font-semibold wh-tw-text-2xl wh-tw-black">Common MDM Plan Edit</Box>
              </FeatureComponent>
          </Box>
          <Box>
              <WildHealthButton
                  id="update-common-goal-button"
                  disabled={isLoading || isSubmitting}
                  onClick={handleUpdateMdmPlan}
                  fullWidth
                  style={{ height: 48 }}
              >
                  <Box className="wh-tw-text-base wh-tw-font-medium">Save</Box>
              </WildHealthButton>
          </Box>
      </Box>
      
      <Box p={3} className="wh-tw-bg-white">
          <Box mb={2}>
              <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Name*</Box>
              <TextField
                  id={`name`}
                  fullWidth
                  required
                  size="small"
                  InputProps={{ className: 'input', classes }}
                  variant="outlined"
                  value={targetMdmPlan.name}
                  error={!!errors['name']}
                  helperText={errors['name']}
                  disabled={isSubmitting}
                  onChange={(event) => handleChanges(event.target.id, event.target.value)}
              />
          </Box>
          <Box mb={2}>
              <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Description*</Box>
              <TextField
                  id={`description`}
                  fullWidth
                  required
                  multiline
                  rows="5"
                  size="small"
                  InputProps={{ className: 'input', classes }}
                  variant="outlined"
                  value={targetMdmPlan.description}
                  error={!!errors['description']}
                  helperText={errors['description']}
                  disabled={isSubmitting}
                  onChange={(event) => handleChanges(event.target.id, event.target.value)}
              />
          </Box>
      </Box>
      
      <Box my={2} display="flex" alignItems="center">
        <Box>
          <Box className="wh-tw-font-medium wh-tw-text-2xl wh-tw-black">MDM Reasons</Box>
        </Box>
        
        <Box ml={3}>
          <button id="add-mdm-reason-button" className={classes.addBtn} disabled={isSubmitting} onClick={() => handleToggleMdmReasonModify(0)}>
            <AddIcon />
          </button>
        </Box>
      </Box>
      <WildHealthContainer margin={0} minHeight={'100%'}>
        {renderTable()}
      </WildHealthContainer>
      <Dialog
          onClose={() => handleToggleMdmReasonModify(-1)}
          className={classes.root}
          open={isOpen}
      >
          <DialogContent className={classes.dialogContent}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Box>
                      <span className="wh-tw-text-2xl wh-tw-black wh-tw-font-medium">{selectedId > 0 ? 'Edit Reason' : 'New Reason'}</span>
                  </Box>
                  <Box display="flex" alignItems="center">
                      <IconButton
                          id="reason-modify-dialog-close"
                          className={classes.dialogTitleCloseAction}
                          onClick={() => handleToggleMdmReasonModify(-1)}
                      >
                          <CloseIcon />
                      </IconButton>
                  </Box>
              </Box>
              <Box mt={5}>
                  <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Name*</Box>
                  <TextField
                      id={`name`}
                      fullWidth
                      required
                      multiline
                      rows="5"
                      size="small"
                      InputProps={{ className: 'input', classes }}
                      variant="outlined"
                      value={createReason.name}
                      disabled={!isOpen || isSubmitting}
                      onChange={handleChangeMdmReason}
                  />
              </Box>
              <Box mt={5} display="flex" justifyContent="flex-end" alignItems="center">
                  <Box mx={2}>
                      <WildHealthButton
                          id="modify-reason-cancel"
                          onClick={() => handleToggleMdmReasonModify(-1)}
                          color="tertiary"
                          size='medium'
                          disabled={!isOpen || isSubmitting}
                      >
                          Cancel
                      </WildHealthButton>
                  </Box>
                  <Box>
                      <WildHealthButton
                          id="save-reason-button"
                          size='medium'
                          disabled={!canSubmit}
                          onClick={handleSaveMdmReason}
                          loading={isSubmitting}
                      >
                          Save
                      </WildHealthButton>
                  </Box>
              </Box>
          </DialogContent>
      </Dialog>
    </Box>
  );
}