import { Box, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import { useStyles } from "./patientConversationsComponent.styles";
import { useFacade } from "./patientConversationsComponent.hooks";
import { HealthCareChatComponent } from "../healthCareChatComponent/HealthCareChatComponent";
import { StaffChatComponent } from "../staffChatComponent/StaffChatComponent";
import { WildHealthTabControl } from "../../../common/components/wildHealthTabControl/WildHealthTabControl";
import { TabItem } from "../../../common/components/wildHealthTabControl/wildHealthTabControl.hooks";
import { useStylesFullWidthTabs } from "../../../common/components/wildHealthTabControl/wildHealthTabControl.styles";

interface PatientConversationsComponentProps {
  targetConversationId?: number;
}

export const PatientConversationsComponent: React.FC<
  PatientConversationsComponentProps
> = (props: PatientConversationsComponentProps) => {
  const { targetConversationId } = props;
  const classes = useStyles();

  const [
    {
      isSupportLoading,
      isHealthCareLoading,
      isSupportPhotosLoading,
      isHCPhotosLoading,
      supportConversations,
      healthCareConversation,
    },
  ] = useFacade();

  //Mobile
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const sideBarTabsStyle = useStylesFullWidthTabs();

  const renderContent = () => {
    if (isSmallScreen) {
      const tabs: TabItem[] = [
        {
          title: "Care Team",
          content: (
            <HealthCareChatComponent
              isLoading={isHealthCareLoading || isHCPhotosLoading}
              conversation={healthCareConversation}
            />
          ),
        },
        {
          title: "Support",
          content: (
            <StaffChatComponent
              isLoading={isSupportLoading || isSupportPhotosLoading}
              targetConversationId={targetConversationId}
              conversations={supportConversations}
            />
          ),
        },
      ];
      return (
        <Box width="100%">
          <WildHealthTabControl items={tabs} className={sideBarTabsStyle} />
        </Box>
      );
    } else {
      return (
        <Grid container className={classes.root}>
          <Grid item md={6} xs={12}>
            <Box p={1} className={classes.container}>
              <HealthCareChatComponent
                isLoading={isHealthCareLoading || isHCPhotosLoading}
                conversation={healthCareConversation}
              />
            </Box>
          </Grid>

          <Grid item md={6} xs={12}>
            <Box p={1} className={classes.container}>
              <StaffChatComponent
                isLoading={isSupportLoading || isSupportPhotosLoading}
                targetConversationId={targetConversationId}
                conversations={supportConversations}
              />
            </Box>
          </Grid>
        </Grid>
      );
    }
  };

  return <>{renderContent()}</>;
};
