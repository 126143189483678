import React from "react";
import { NoteRecommendation } from "../../../constants/initialConsult.constants";
import { Box, Divider, useTheme, useMediaQuery } from "@material-ui/core";
import { WildHealthPlaceHolder } from "../../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { useStyles } from '.././NoteRecommendationsComponent.styles'
import { TextMarkdownComponent } from "../../textMarkdownComponent/TextMarkdownComponent";
import clsx from 'clsx';
import commonUseStyles from "../../../../common/styles/common.styles";

interface NoteRecommendationsComponentProps {
    recommendations: NoteRecommendation[];
}

export const NoteRecommendationsComponentOld: React.FC<NoteRecommendationsComponentProps> = (props: NoteRecommendationsComponentProps) => {
    const { recommendations } = props;
    const classes = useStyles();
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const renderRecommendations = () => {
        return recommendations.map((x, i) => {
            return (
                <Box pb={3} key={i}>
                    <Box>
                        <span className={isSmallScreen ? clsx(commonClasses.size16, commonClasses.textMedium, commonClasses.colorGray1) : "size-1_25 text-medium uppercase"}>
                            {x.name}
                        </span>
                    </Box>
                    <Box mt={isSmallScreen ? "10px" : 2}>
                        <TextMarkdownComponent content={x.content} classLink={classes.renderText} />
                    </Box>
                </Box>
            )
        })
    }

    return (
        <Box>
            <Box>
                <span className={isSmallScreen ? clsx(commonClasses.size16, commonClasses.textMedium, commonClasses.colorBlack) : "size-1_5 text-medium uppercase"}>Recommendations</span>
            </Box>
            {isSmallScreen && <Box py={2}>
                <Divider className={commonClasses.renderDivider} />
            </Box>}
            <Box mt={!isSmallScreen && 3}>
                {
                    !recommendations || !recommendations.length
                        ? <WildHealthPlaceHolder message="No recommendations" />
                        : renderRecommendations()
                }
            </Box>
        </Box>
    )
}