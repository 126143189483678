import React from "react"
import { Avatar, Box, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, useTheme, useMediaQuery } from "@material-ui/core";
import { Member, useStyles } from "./chatMembersComponent.styles";
import { MemberViewModel } from "./ChatMembersComponent";
import PeopleIcon from '@material-ui/icons/People';
import CloseIcon from '@material-ui/icons/Close';
import { ReactComponent as AddIcon } from '@img/icons/Add.svg';
import clsx from "clsx";
import DeleteIcon from '@material-ui/icons/Delete'
import { displayShortName } from "../../helpers/messages.converter";

export interface ManageableMemberViewModel extends MemberViewModel {
    disableRemove?: boolean;
}

interface ManageChatMembersComponentProps {
    isDialogActiveUsers: boolean;
    members: ManageableMemberViewModel[];
    disableAddMember?: boolean;
    disableRemoveMember?: boolean;
    handleAddMember: () => void;
    handleRemoveMember: (id: number) => void;
}

export const ManageChatMembersComponent: React.FC<ManageChatMembersComponentProps> = (props: ManageChatMembersComponentProps) => {
    const { isDialogActiveUsers, members, disableAddMember, disableRemoveMember, handleAddMember, handleRemoveMember } = props;

    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <Box>
            {isDialogActiveUsers
                ? <></>
                : <Box display='flex' mx={2} mt={2} alignItems='center'>
                    <PeopleIcon className={classes.titleIcon} />
                    <Box ml={1} className={classes.title}>Active Users On Thread</Box>
                </Box>}
            <List className={clsx('flat-scroll', classes.list)}>
                {
                    members.map((item, index) =>
                        <ListItem key={index} divider={isSmallScreen}>
                            <Box className={classes.item} display='flex' width='100%'>
                                <ListItemAvatar>
                                    <Avatar className={classes.avatar} variant="circular" src={item.image}> {displayShortName(item.firstName, item.lastName)}</Avatar>
                                </ListItemAvatar>

                                <Member
                                    primary={`${item.firstName} ${item.lastName}`}
                                    secondary={item.position}
                                />

                                {
                                    (!isSmallScreen && !disableRemoveMember && !item.disableRemove) &&
                                    (isDialogActiveUsers
                                        ? <ListItemSecondaryAction>
                                            <IconButton id={`manage-chat-remove-${item.id ?? 0}`} onClick={() => handleRemoveMember(item.id ?? 0)}>
                                                <DeleteIcon className={classes.icon} />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                        : <ListItemSecondaryAction className={classes.removeUser}>
                                            <IconButton id={`manage-chat-remove-${item.id ?? 0}`} onClick={() => handleRemoveMember(item.id ?? 0)}>
                                                <CloseIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>)
                                }
                            </Box>
                        </ListItem>
                    )
                }
                <ListItem id="manage-chat-add" key={members.length + 1} button onClick={handleAddMember} disabled={disableAddMember}>
                    <Box display='flex' alignItems='center' pt={isSmallScreen && 1}>
                        <AddIcon />
                        <Box ml={1} className={classes.addUser}>
                            Add User
                        </Box>
                    </Box>
                </ListItem>
            </List>
        </Box>
    )
}
