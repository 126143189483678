import {Box, Dialog, DialogActions, DialogContent, FormControl, FormHelperText, TextField} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import { WildHealthDatePicker } from "../../../common/components/WildHealthDatePicker";
import { DialogComponentProps } from "../../../common/dialogs/dialogProps";
import { useFacade } from "./cancelSubscriptionComponent.hooks";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {CancellationReasonTypes} from "../../../payment/models/subscription.models";

export const CancelSubscriptionComponent: React.FC<DialogComponentProps> = (props: DialogComponentProps) => {
    const {
        open,
        handleSubmit,
        handleClose
    } = props;

    const [
        {
            reasonType,
            reason,
            date,
            errors
        },
        handleReasonTypeChange,
        handleReasonChange,
        handleDateChange,
        handleCancel,
        handleConfirm
    ] = useFacade(handleSubmit, handleClose);

    return (
        <Dialog fullWidth open={open} onClose={() => handleCancel()} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Cancel Membership</DialogTitle>
            <DialogContent>
                <Box>
                    <Box>
                        <Box>
                            <span className="text-medium">Cancellation Date:</span>
                        </Box>
                        <Box mt={2}>
                            <WildHealthDatePicker
                                value={date}
                                size="small"
                                label="Cancellation Date"
                                openTo="year"
                                disablePast
                                format="MM/DD/yyyy"
                                placeholder="yyyy-mm-dd"
                                inputVariant="outlined"
                                views={["year", "month", "date"]}
                                onChange={(e) => handleDateChange(new Date(e.toISOString()))}
                            />
                        </Box>
                    </Box>
                    <Box mt={3}>
                        <Box>
                            <span className="text-medium">Reason:</span>
                        </Box>
                        <FormControl
                            size="small"
                            color='primary'
                            fullWidth>
                            <Select
                                style={{ background: '#FFFFFF' }}
                                onChange={(event: React.ChangeEvent<{ value: number }>) => handleReasonTypeChange(event.target.value)}
                                value={reasonType}
                                variant="outlined">
                                {
                                    [...CancellationReasonTypes.keys()].map((key, index) => {
                                        return <MenuItem key={index} value={key}>{CancellationReasonTypes.get(key)}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Box>
                    <Box mt={3}>
                        <Box>
                            <span className="text-medium">Additional Info:</span>
                        </Box>
                        <Box mt={2}>
                            <TextField
                                fullWidth
                                rows={2}
                                multiline
                                type='text'
                                size="small"
                                label='Reason'
                                variant="outlined"
                                placeholder='Reason'
                                value={reason}
                                InputProps={{ className: 'input' }}
                                onChange={handleReasonChange}
                                error={!!errors['reason']}
                                helperText={errors['reason']}
                            />
                        </Box>
                    </Box>
                </Box>
            </DialogContent>

            <DialogActions style={{ padding: '8px 24px' }}>
                <Box>
                    <WildHealthButton
                        id="cancel-subscription" 
                        color='secondary'
                        onClick={() => handleCancel()}
                    >
                        Cancel
                    </WildHealthButton>
                </Box>
                <Box mx={2}>
                    <WildHealthButton id="save-subscription" onClick={() => handleConfirm()}>
                        Submit
                    </WildHealthButton>
                </Box>
            </DialogActions>
        </Dialog>
    );
}
