import React from "react";
import { AuthenticatedLayoutComponent } from "../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent";
import { PagesNamesService } from "../../../../constants/pagesNames.constants";
import { TitlesService } from "../../../../constants/title.constants";
import { MenuItemTypes } from "../../../../models/menu.models";
import { PatientMedicationsSupplementsTabsComponent } from "../../components/patientMedicationsSupplementsTabs/PatientMedicationsSupplementsTabsComponent";

export const PatientSupplementsPage: React.FC = () => {
    return (
        <AuthenticatedLayoutComponent
            title={TitlesService.supplementsTitle}
            pageName={PagesNamesService.supplements}
            selectedMenuItem={MenuItemTypes.Supplements}
        >
            <PatientMedicationsSupplementsTabsComponent />
        </AuthenticatedLayoutComponent>
    )
}
