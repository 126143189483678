import React from "react";
import { Avatar, Box, Divider, IconButton, Link, Menu, MenuItem, Popover, Tooltip, Typography } from "@material-ui/core";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CloseIcon from '@material-ui/icons/Close';
import LaunchIcon from '@material-ui/icons/Launch';
import { EmployeeAppointmentModel } from "../../models/appointments.models";
import { AppointmentWithType } from "../../models/appointments.enums";
import { useFacade } from "./appointmentInfoPopoverComponent.hooks";
import { useStyles } from "./AppointmentInfoPopoverComponent.styles";
import moment from 'moment';
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { SendInviteDialogComponent } from "../sendInviteDialog/SendInviteDialogComponent";
import { AppointmentPurposeType } from "../../models/appointments.enums";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { navigationService } from "../../../../services/navigation.service";
import { NotesType } from "../../../notes/models/notes.models";
import { useHistory } from "react-router";
import { FeatureComponent } from "../../../common/components/featureFlags/FeatureComponent";
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import { WildHealthLinkButton } from "../../../common/components/WildHealthLinkButton";
import AppointmentTags
    from "../../../employee/components/dashboardWidget/appointmentWidgetComponent/AppointmentTags/AppointmentTags";
import {isCoachRole, isProviderRole} from "../../../common/constants/roles";
import { profileQuery } from "../../../account/stores/profileStore";
import VideoCamOffIcon from '@material-ui/icons/VideocamOffOutlined';
import { appointmentColors } from "../../constants/appointmentColors";

interface AppointmentInfoPopoverComponentProps {
    anchorEl: HTMLElement | null;
    open: boolean;
    onClose: Function;
    appointment: EmployeeAppointmentModel;
    handleCancelAppointment: (appointmentId: number) => void;
    handleJoinAppointment: (joinLink: string) => void;
    handleOpenEditDialog: Function;
    handleRescheduleAppointment: (appointmet: EmployeeAppointmentModel) => void,
    appointmentColor: string
}

export const AppointmentInfoPopoverComponent: React.FC<AppointmentInfoPopoverComponentProps> = (props: AppointmentInfoPopoverComponentProps) => {
    const {
        anchorEl,
        open,
        onClose,
        appointment,
        handleCancelAppointment,
        handleJoinAppointment,
        handleOpenEditDialog,
        handleRescheduleAppointment,
        appointmentColor
    } = props;

    const [
        {
            menuAnchor,
            notesMenuAnchor,
            inviteDialogOpen,
            followUpFormId,
            sequenceInfo,
            tags
        },
        handleOpenMenu,
        handleOpenNotesMenu,
        handleCloseMenu,
        handleCloseNotesMenu,
        handleToggleInviteDialog,
        handleNoShowAppointment,
        goToPatientVisitPrep,
        handleViewNote
    ] = useFacade(appointment);

    const classes = useStyles();
    const history = useHistory();

    const isUpcoming = moment(appointment.startDate) > moment(new Date());

    const renderCreateNoteButton = () => {
        if (appointment.note != null) {
            return <WildHealthButton
                id="appointment-info-popover-view-note"
                onClick={() => handleViewNote(appointment.note)}
            >
                <Box>
                    <span>View Note</span>
                </Box>
            </WildHealthButton>
        }

        return <>
            <WildHealthButton
                id="appointment-info-popover-create-note"
                onClick={(e) => handleOpenNotesMenu(e)}
            >
                <Box>
                    <span>Create Note</span>
                </Box>
                <ArrowDropDownIcon />
            </WildHealthButton>
        </>
    }

    const getAppointmentName = (name: string) => {
        return name.length > 40
            ? <Tooltip placement="top" arrow title={name}>
                <span className="wh-tw-font-medium">{name.slice(0, 40) + '...'}</span>
            </Tooltip>
            : <span className="wh-tw-font-medium">{name}</span>
    }

    return (
        <Popover
            anchorEl={anchorEl}
            open={open}
            onClose={() => onClose()}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <SendInviteDialogComponent
                appointmentId={appointment.id}
                open={inviteDialogOpen}
                handleClose={() => handleToggleInviteDialog(false)}
            />
            <Box display='flex' justifyContent='space-between' alignItems='center' pl="20px" pt="20px">
                <Box>
                { 
                    !(appointment.userOptions?.meetingRecordingConsent ?? false) &&
                    <VideoCamOffIcon htmlColor={appointmentColors.orange}></VideoCamOffIcon> 
                }
                    <span className="wh-tw-text-lg wh-tw-font-medium wh-tw-break-all">{getAppointmentName(appointment.name)}</span>
                </Box>
                <Box display='flex' alignItems='center' pr={2}>
                    {
                        appointment.joinLink && !appointment.noShow &&
                        <Box textAlign='right'>
                            <WildHealthButton id="patient-popover-join" onClick={() => handleJoinAppointment(appointment.joinLink)}>Join</WildHealthButton>
                        </Box>
                    }
                    <Box>
                        <IconButton id="appointment-popover-open-menu" onClick={(e) => handleOpenMenu(e)}>
                            <MoreHorizIcon />
                        </IconButton>
                        <Menu
                            id="simple-menu"
                            anchorEl={menuAnchor}
                            keepMounted
                            open={Boolean(menuAnchor)}
                            onClose={(e) => handleCloseMenu(e)}
                        >
                            <MenuItem id="appointment-popover-edit" disabled={!isUpcoming} onClick={() => handleOpenEditDialog()}>
                                Edit
                            </MenuItem>
                            <MenuItem id="appointment-popover-cancel" onClick={() => handleCancelAppointment(appointment.id)}>
                                Cancel Appointment
                            </MenuItem>
                            <MenuItem id="appointment-popover-reschedule" disabled={!isUpcoming} onClick={() => handleRescheduleAppointment(appointment)}>
                                Reschedule
                            </MenuItem>
                        </Menu>
                    </Box>
                    <Box>
                        <IconButton id="appointment-popover-close" onClick={(e) => onClose(e)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
            <Box className={classes.root}>
                <Box className={classes.content}>
                    <Box>
                        {
                            sequenceInfo?.sequenceNumber > 0 &&
                            <Box className="wh-tw-text-gray1">
                                {sequenceInfo?.sequenceNumber}d Visit
                            </Box>
                        }
                    </Box>
                    <Box>
                        {(tags && tags.length > 0) && <AppointmentTags tags={tags} color={appointmentColor} />}
                    </Box>
                </Box>
                {
                    appointment.noShow &&
                    <Box display='flex' justifyContent='center' alignItems='center' className={classes.notShowHeader}>
                        <Box p={1}>
                            <span className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-white">Patient did not show up</span>
                        </Box>
                    </Box>
                }
                <Box className={classes.content}>
                    <Box display='flex' justifyContent='flex-start' alignItems='center' mt={2}>
                        <Box>
                            <Box>
                                <span className="wh-tw-text-xxs wh-tw-text-gray1">Date</span>
                            </Box>
                            <Box>
                                <span className="wh-tw-font-medium">{moment(appointment.startDate).format("dddd DD, MMM yyyy")}</span>
                            </Box>
                        </Box>
                        <Box ml={3}>
                            <Box>
                                <span className="wh-tw-text-xxs wh-tw-text-gray1">Time</span>
                            </Box>
                            <Box>
                                <span className="wh-tw-font-medium">
                                    {moment(appointment.startDate).format("hh:mm a")}
                                    <span className={classes.timeDivider}>|</span>
                                    {`${appointment.duration} minutes`}
                                </span>
                            </Box>
                        </Box>
                    </Box>
                    <Box mt={2}>
                        <Box>
                            <span className="wh-tw-text-xxs wh-tw-text-gray1">Attendees</span>
                        </Box>
                        {
                            appointment.patient !== null &&
                            <Box display="flex" alignItems="center" justifyContent="space-between" mt={1}>
                                <Box display="flex" alignItems="center">
                                    <Box>
                                        <Avatar className={classes.avatar}>
                                            {appointment.patient.firstName.trim()[0]}
                                        </Avatar>
                                    </Box>
                                    <Box ml={1}>
                                        <Box className="wh-tw-font-medium">
                                            {appointment.patient.firstName + ' ' + appointment.patient.lastName}
                                        </Box>
                                        <Box className="wh-tw-text-gray1">
                                            Patient
                                        </Box>
                                    </Box>
                                </Box>
                                <Box>
                                    <Link
                                        id='go-to-patient-visit-prep'
                                        className="wh-tw-text-primaryV"
                                        onClick={() => goToPatientVisitPrep(appointment.patient.id)}
                                    >
                                        <Typography display='inline' paragraph className="wh-tw-font-bold">
                                            <Box display="flex" alignItems="center" gridGap={8}>
                                                <span> Visit Prep </span>
                                                <LaunchIcon className="wh-tw-text-primaryV" />
                                            </Box>
                                        </Typography>
                                    </Link>
                                </Box>
                            </Box>
                        }
                        <Box>
                            {
                                appointment.employees.map((employee, index) => (
                                    <Box key={index} display="flex" alignItems="center" mt={1}>
                                        <Box>
                                            <Avatar src={profileQuery.getPhoto(employee.id)} className={classes.avatar}>
                                                {employee.firstName.trim()[0]}
                                            </Avatar>
                                        </Box>
                                        <Box ml={1}>
                                            <Box className="wh-tw-font-medium">
                                                {employee.firstName + ' ' + employee.lastName}
                                            </Box>
                                            <Box className="wh-tw-text-gray1">
                                                {
                                                    isProviderRole(employee.roleId) && <span>Provider</span>
                                                }
                                                {
                                                    isCoachRole(employee.roleId) && <span>Health Coach</span>
                                                }
                                            </Box>
                                        </Box>
                                    </Box>
                                ))
                            }
                        </Box>
                    </Box>
                    <Box display='flex' justifyContent='space-between'>
                        {
                            !isUpcoming && !appointment.noShow && appointment.withType !== AppointmentWithType.Other &&
                            <Box mt={2} ml="auto" textAlign='right' onClick={() => handleNoShowAppointment(appointment.id)}>
                                <span className="wh-tw-text-sm wh-tw-text-primaryV wh-tw-font-medium wh-tw-cursor-pointer">Patient No Show?</span>
                            </Box>
                        }
                    </Box>
                    {appointment.reason && (
                        <FeatureComponent featureFlag={FeatureFlag.AppointmentReasons}>
                            <Box mt={2} mb={2}>
                                <Box>
                                    <span className="wh-tw-text-xxs wh-tw-text-gray1">Reason for the appointment</span>
                                </Box>
                                <Box>
                                    <Box pr={1} className="wh-tw-break-all">{appointment.reason}</Box>
                                </Box>
                            </Box>
                        </FeatureComponent>
                    )}
                    <Box mt={2} mb={2}>
                        <Box>
                            <span className="wh-tw-text-xxs wh-tw-text-gray1">Notes</span>
                        </Box>
                        <Box display='flex' justifyContent='space-between'>
                            <Box pr={1} className="wh-tw-break-all">{appointment.comment ?? 'No note added.'}</Box>
                            {
                                appointment.withType !== AppointmentWithType.Other
                                && renderCreateNoteButton()
                            }
                            <Menu
                                id="appointment-info-popover-notes"
                                anchorEl={notesMenuAnchor}
                                keepMounted
                                open={Boolean(notesMenuAnchor)}
                                onClose={(e) => handleCloseNotesMenu(e)}
                            >
                                <MenuItem id="appointment-info-popover-initial-coaching" onClick={() => navigationService.toCreateInitialConsultNote(history, appointment.patient.id, appointment.id, null, true)}>
                                    Initial Coaching Consult
                                </MenuItem>
                                <MenuItem id="appointment-info-popover-fellow-up" onClick={() => navigationService.toCreateFollowUpNote(history, appointment.patient.id, appointment.id, null, true)}>
                                    Follow-Up
                                </MenuItem>
                                <MenuItem id="appointment-info-popover-fellow-up" onClick={() => navigationService.toCreateHistoryAndPhysicalNote(history, appointment.patient.id, appointment.id, null, true)}>
                                    Initial Medical History and Physical
                                </MenuItem>
                                <MenuItem id="appointment-info-popover-fellow-up" onClick={() => navigationService.toCreateHistoryAndPhysicalFollowUpNote(history, appointment.patient.id, appointment.id, null, true)}>
                                    Follow-Up Medical History and Physical
                                </MenuItem>
                                <MenuItem id="appointment-info-popover-blank-note" onClick={() => navigationService.toCreateSimpleNote(history, NotesType.Blank, appointment.patient.id, appointment.id, null, true)}>
                                    Blank Note
                                </MenuItem>
                            </Menu>
                        </Box>
                    </Box>
                    {
                        appointment.purpose === AppointmentPurposeType.FollowUp &&
                        <FeatureComponent featureFlag={FeatureFlag.FollowUpForms}>
                            <Box mt={2} mb={2}>
                                <Box>
                                    <span className="wh-tw-text-xxs wh-tw-text-gray1">Forms</span>
                                </Box>
                                {!!followUpFormId
                                    ? <Box>
                                        <WildHealthLinkButton
                                            onClick={() => navigationService.toPatientQuestionnaireResult(appointment.patient.id, followUpFormId)}
                                            id={'go-to-questionnaire-btn-'+followUpFormId}
                                            content='Follow up form'
                                            linkButton={true}
                                        ></WildHealthLinkButton>
                                    </Box>
                                    : <Box>
                                        <span className="wh-tw-break-all">No completed forms</span>
                                    </Box>
                                }
                            </Box>
                        </FeatureComponent>
                    }
                    <Box mt={2} mb={2}>
                        <Box mb="6px">
                            <span className="wh-tw-text-xxs wh-tw-text-gray1">Last Visits</span>
                        </Box>
                        <Box mb={0.5}>
                            <span className="wh-tw-text-sm wh-tw-text-black wh-tw-font-medium">Health Coach</span>
                        </Box>
                        <Box display="flex" justifyContent="space-between" mb={1.5}>
                            {sequenceInfo?.previousCoachAppointment
                                ? <>
                                <span className="wh-tw-text-xxs wh-tw-text-black">{`${sequenceInfo?.previousCoachAppointment.appointmentName} - ${sequenceInfo?.previousCoachAppointment.employeeName}`}</span>
                                <span className="wh-tw-text-xxs wh-tw-text-gray1 wh-tw-text-right wh-tw-w-28">{moment(sequenceInfo?.previousCoachAppointment.date).format("MMM DD, yyyy")}</span>
                                </>
                                : <span className="wh-tw-text-xxs wh-tw-text-black">No visit held</span>
                            }
                        </Box>
                        <Box mb={0.5}>
                            <span className="wh-tw-text-sm wh-tw-text-black wh-tw-font-medium">Physician</span>
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                            {sequenceInfo?.previousProviderAppointment
                                ? <>
                                <span className="wh-tw-text-xxs wh-tw-text-black">{`${sequenceInfo?.previousProviderAppointment.appointmentName} - ${sequenceInfo?.previousProviderAppointment.employeeName}`}</span>
                                <span className="wh-tw-text-xxs wh-tw-text-gray1 wh-tw-text-right wh-tw-w-28">{moment(sequenceInfo?.previousProviderAppointment.date).format("MMM DD, yyyy")}</span>
                                </>
                                : <span className="wh-tw-text-xxs wh-tw-text-black">No visit held</span>
                            }
                        </Box>
                    </Box>
                    <Divider />
                    {
                        appointment.joinLink &&
                        <Box mt={2}>
                            <Box>
                                <span className="wh-tw-text-xxs wh-tw-text-gray1">Zoom link</span>
                            </Box>
                            <Box>
                                <Link
                                    id="appointment-popover-join-link"
                                    className="wh-tw-text-primaryV"
                                    href={appointment.joinLink}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    {appointment.joinLink}
                                </Link>
                            </Box>
                        </Box>
                    }
                    {
                        appointment.joinLink &&
                        <Box id="appointment-popover-invite" mt={2} onClick={() => handleToggleInviteDialog(true)}>
                            <span className="wh-tw-text-primaryV wh-tw-font-medium wh-tw-cursor-pointer">Send Invite</span>
                        </Box>
                    }
                </Box>
            </Box>
        </Popover>
    );
}

