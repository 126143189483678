import React, { useState } from 'react';
import { SelectPMFellowshipRoleComponent } from "../../components/selectPMFellowshipRole/SelectFellowshipRoleComponent";
import { SelectFellowshipPlanComponent } from "../../components/selectFellowshipPlan/SelectFellowshipPlanComponent";
import { FellowshipCheckoutComponent } from "../../components/fellowshipCheckout/FellowshipCheckoutComponent";

export enum FellowshipRegistrationStep {
    Role = 1,
    Plan = 2,
    Checkout = 3
}

interface StepItemModel {
    key: FellowshipRegistrationStep;
    label: string;
}

const steps = [
    {
        key: FellowshipRegistrationStep.Role,
        label: "Select Role",
    },
    {
        key: FellowshipRegistrationStep.Plan,
        label: "Select Plan",
    },
    {
        key: FellowshipRegistrationStep.Checkout,
        label: "Confirm Purchase",
    },
] as StepItemModel[];

interface FellowshipGetStartedPageState {
    steps: StepItemModel[];
    currentStep: StepItemModel;
}

export function useFacade(): [
    FellowshipGetStartedPageState,
    Map<number, JSX.Element>
] {
    const [state, setState] = useState({
        steps: steps,
        currentStep: steps[0]
    } as FellowshipGetStartedPageState);

    const handleGoNext = () => {
        const currentIndex = state.steps.indexOf(state.currentStep);

        if (currentIndex + 1 === state.steps.length) {
            return;
        }

        setState({ ...state, currentStep: state.steps[currentIndex + 1] })
    }

    const handleGoBack = () => {
        const currentIndex = state.steps.indexOf(state.currentStep);

        if (currentIndex === 0) {
            return;
        }

        setState({ ...state, currentStep: state.steps[currentIndex - 1] })
    }


    const StepsContent = new Map<number, JSX.Element>([
        [FellowshipRegistrationStep.Role, <SelectPMFellowshipRoleComponent
            goNext={handleGoNext}
        />],
        [FellowshipRegistrationStep.Plan, <SelectFellowshipPlanComponent
            goNext={handleGoNext}
            goBack={handleGoBack}
        />],
        [FellowshipRegistrationStep.Checkout, <FellowshipCheckoutComponent
            goBack={handleGoBack} />]
    ]);

    return [
        state,
        StepsContent
    ];
}