import { createStyles, makeStyles } from "@material-ui/core/styles";
import { colors } from "../../../../../common/constants/colors";

export const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontSize: 16,
      lineHeight: '22px',
      fontWeight: 500,
      color: colors.black,
    },
    size14: {
      fontSize: 14,
    },
    button: {
      color: colors.main,
      fontSize: 14
    },
    buttonText: {
      marginLeft: '6px',
      textTransform: 'capitalize'
    },
    textGray: {
      color: colors.gray1,
      width: 70,
      minWidth: 70,
    },
    breakWord: {
      wordBreak: 'break-word',
    },
    mobileApp: {
      color: colors.good,
      backgroundColor: `${colors.good}14`,
      margin: '16px -30px',
      padding: '10px 30px'
    },
  })
);


