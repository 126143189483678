import { Store, StoreConfig } from '@datorama/akita';
import { BaseCourseModel, PatientCourseHistoryModel } from '../../models/courses.model';

export interface CoursesState {
  courses: BaseCourseModel[];
  patientCourseHistories: PatientCourseHistoryModel;
}

/**
 * Creates initial course state
 */
export function createInitialState(): CoursesState {
  return {
    courses: [],
    patientCourseHistories: null
  };
}

/**
 * Provides course state management
 */
@StoreConfig({ name: 'courses', resettable: true })
export class CoursesStore extends Store<CoursesState> {
  constructor() {
    super(createInitialState());
  }

  public setCourses(courses: BaseCourseModel[]): void {
    this.update({
      courses: courses
    })
  }

  public setPatientCourseHistories(courseHistories: PatientCourseHistoryModel): void {

    this.update({
      patientCourseHistories: courseHistories
    })
  }
}

export const coursesStore = new CoursesStore();
