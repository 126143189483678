import { Box, CircularProgress, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useState } from 'react';
import { useCallback } from 'react';
import debounce from 'lodash.debounce'
import commonUseStyles from '../../../common/styles/common.styles';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import moment from 'moment';
import { useStyles } from "./SearchPatientComponent.styles";

export interface UserViewModel {
    id: number;
    email: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    birthday?: Date;
}

interface SearchPatientComponentProps {
    placeholder?: string;
    selectedUser?: UserViewModel;
    users: UserViewModel[];
    isLoading: boolean;
    error?: boolean;
    helperText?: string;
    disabled?: boolean;
    handleChange: (user: number) => void;
    handleInputChange: (input: string) => void;
}

export const SearchPatientComponent: React.FC<SearchPatientComponentProps> = (props: SearchPatientComponentProps) => {
    const {
        placeholder,
        handleChange,
        handleInputChange,
        selectedUser,
        users,
        isLoading,
        error = false,
        helperText = null,
        disabled = false,
    } = props;

    const [state] = useState({ inputText: '' });

    const classes = useStyles();
    const commonClasses = commonUseStyles();

    const debouncedHandleTextChange = useCallback(debounce(function () {
        handleInputChange(state.inputText)
    }, 1500), [])

    const handleTextChange = (text: string) => {
        state.inputText = text;
        debouncedHandleTextChange();
    }

    const renderOptionLabel = (user: UserViewModel): string => {
        return `${user.firstName} ${user.lastName} ${user.email}`;
    }

    const displayName = (user: UserViewModel): string => {
        return `${user.firstName} ${user.lastName}`;
    }

    const displayEmailPhoneDOB = (user: UserViewModel): string => {
        return `${user.email}  |  ${user.phoneNumber}  |  ${user.birthday ? `${moment(user.birthday).format('MM.DD.YYYY')}` : ''}`;
    }

    const renderOption = (user) => {
        return (
            <React.Fragment>
                <Box flexDirection={'column'}>
                    <Box mb={0.5} className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorBlack)}>{displayName(user)}</Box>
                    <Box className={clsx(commonClasses.size12, commonClasses.textRegular, commonClasses.colorGray1)}>{displayEmailPhoneDOB(user)}</Box>
                </Box>
            </React.Fragment>
        )
    }

    const renderInput = (params) => {
        return (
            <TextField
                {...params}
                variant="outlined"
                placeholder={placeholder}
                error={error}
                onChange={(e) => handleTextChange(e.currentTarget.value)}
                helperText={helperText}
                InputProps={{
                    ...params.InputProps,
                    className: 'input',
                    startAdornment: (
                        <React.Fragment>
                            <SearchIcon className={classes.searchIcon} />
                        </React.Fragment>
                    ),
                    endAdornment: (
                        <React.Fragment>
                            {isLoading ? <CircularProgress className={commonClasses.colorMain} size={20} /> : null}
                        </React.Fragment>
                    ),
                }}
            />
        )
    }

    return (
        <Box className={classes.searchContent}>
            <Autocomplete
                size='small'
                fullWidth
                disableClearable
                noOptionsText={'No patient'}
                options={users}
                filterOptions={x => x}
                disabled={disabled}
                value={selectedUser}
                forcePopupIcon={false}
                getOptionLabel={user => renderOptionLabel(user)}
                onChange={(e, value) => handleChange(typeof (value) === 'object' ? value.id : +value)} //todo: figureout compiler behaviour
                renderInput={(params) => renderInput(params)}
                renderOption={(user) => renderOption(user)}
            />
        </Box>
    );
}