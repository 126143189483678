import { Box, useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { HealthRecommendationComponent } from "../components/healthRecommendationComponent/HealthRecommendationComponent";
import {
    HealthReportMode,
    HealthReportSections,
    HealthReportVersion,
    recommendationRowsCapacity,
    RecommendationTypesNumbers
} from "../models/healthReport.models";
import { HealthReportBasePage, HealthReportMobileBasePage } from "./HealthReportBasePage";
import { useStyles } from './healthReport.styles';
import { HealthReportPageProps } from "./healthReportPageProps";
import commonUseStyles from "../../common/styles/common.styles";
import clsx from "clsx";
import { WildHealthButton } from '../../common/components/wildHealthButton/WildHealthButton';
import { RecommendationSingleSelectComponent } from '../components/recommendationSingleSelectComponent/RecommendationSingleSelectComponent';

export const HealthReportDietAndNutrition6Page: React.FC<HealthReportPageProps> = (props: HealthReportPageProps) => {
    const {
        report,
        pageNumber,
        handleChanges,
        handleSaveChanges,
        isChanged,
        mode,
        practice,
        recommendations,
        handleSelected,
        handleChangesNewRecommendation,
        handleAddRecommendation,
        handleSaveChangesNew,
    } = props;
    const commonClasses = commonUseStyles();
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        props.mode !== HealthReportMode.PrintMode && isSmallScreen ? (
            <HealthReportMobileBasePage section={HealthReportSections.DietAndNutrition} pageNumber={pageNumber} practice={practice}>
                <Box>
                    <Box px={2}>
                        <Box>
                            <Box>
                                <Box>
                                    <span className={classes.reportTitle}>Kryptonite Foods</span>
                                </Box>
                                <Box mt="10px">
                                    <p className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeMediumReport, commonClasses.textMediumReport)}>Kryptonite for you</p>
                                </Box>
                                <Box mt="20px" fontSize="14px" lineHeight="22px">
                                    <p>Based on your genetics you should avoid these as much as possible:</p>
                                    <p>
                                        Sugar is inflammatory and contributes to increased insulin resistance,
                                        accelerated aging, and poor body composition.
                                    </p>
                                    <p>Wheat/gluten leads to inflammation and all the negative effects that come along with it.</p>
                                    <p>Vegetable oils and processed foods are particularly deleterious to you and your DNA.</p>
                                </Box>
                                <Box mt="20px">
                                    <Box pb="10px">
                                        <span className={classes.reportSubTitle}>Your kryptonite recommendations</span>
                                        <Box display="flex" justifyContent="flex-end" width={1}>
                                            {
                                                parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation) && HealthReportMode.EditMode === mode && <WildHealthButton
                                                    id="health-recommendation-new-save"
                                                    onClick={() => handleSaveChangesNew()}
                                                    disabled={!isChanged}>
                                                    <Box >
                                                        Save changes
                                                    </Box>
                                                </WildHealthButton>
                                            }
                                        </Box>
                                    </Box>
                                    {parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
                                        ? <Box>
                                            <RecommendationSingleSelectComponent
                                                currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.KryptoniteFoods)}
                                                recommendation={report.dietAndNutritionReport.kryptoniteFoodsRecommendation}
                                                type={RecommendationTypesNumbers.KryptoniteFoods}
                                                handleSelected={handleSelected}
                                                handleChanges={handleChangesNewRecommendation}
                                                handleAddRecommendation={handleAddRecommendation}
                                                mode={mode}
                                            />
                                        </Box>
                                        : <HealthRecommendationComponent
                                            mode={mode}
                                            rows={recommendationRowsCapacity[RecommendationTypesNumbers.KryptoniteFoods]}
                                            recommendation={report.dietAndNutritionReport.kryptoniteFoodsRecommendation}
                                            handleContentChanges={!handleChanges ? handleChanges : (value) => {
                                                handleChanges('dietAndNutritionReport.kryptoniteFoodsRecommendation.content', value)
                                            }}
                                            handleManualContentChanges={!handleChanges ? handleChanges : (value) => {
                                                handleChanges('dietAndNutritionReport.kryptoniteFoodsRecommendation.manualContent', value)
                                            }}
                                            handleSaveChanges={handleSaveChanges}
                                            isChanged={isChanged} />
                                    }
                                </Box>
                            </Box>
                            <Box mt="30px">
                                <Box>
                                    <span className={classes.reportTitle}>Super Foods</span>
                                </Box>
                                <Box mt="10px">
                                    <p className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeMediumReport, commonClasses.textMediumReport)}>Optimal for you</p>
                                </Box>
                                <Box fontSize="14px" mt="20px">
                                    <p>Based on your genetics you should focus on adding or increasing the following in your diet:</p>
                                    <p>
                                        Fish. SMASH fish. You have several snps that make sardines particularly ideal for
                                        you with their clean content of Omega 3's, collagen protein, and other key vitamins
                                        and nutrients.
                                    </p>
                                    <p>
                                        Cruciferous vegetables. Broccoli, cauliflower, brussell sprouts, and other
                                        crucifers will greatly benefit your specific genetic makeup.
                                    </p>
                                </Box>
                                <Box mt="20px">
                                    <Box pb="10px">
                                        <span className={classes.reportSubTitle}>Your super foods recommendations</span>
                                        <Box display="flex" justifyContent="flex-end" width={1}>
                                            {
                                                parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation) && HealthReportMode.EditMode === mode && <WildHealthButton
                                                    id="health-recommendation-new-save"
                                                    onClick={() => handleSaveChangesNew()}
                                                    disabled={!isChanged}>
                                                    <Box >
                                                        Save changes
                                                    </Box>
                                                </WildHealthButton>
                                            }
                                        </Box>
                                    </Box>
                                    {parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
                                        ? <Box>
                                            <RecommendationSingleSelectComponent
                                                currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.SuperFoods)}
                                                recommendation={report.dietAndNutritionReport.superFoodsRecommendation}
                                                type={RecommendationTypesNumbers.SuperFoods}
                                                handleSelected={handleSelected}
                                                handleChanges={handleChangesNewRecommendation}
                                                handleAddRecommendation={handleAddRecommendation}
                                                mode={mode}
                                            />
                                        </Box>
                                        : <HealthRecommendationComponent
                                            mode={mode}
                                            rows={recommendationRowsCapacity[RecommendationTypesNumbers.SuperFoods]}
                                            recommendation={report.dietAndNutritionReport.superFoodsRecommendation}
                                            handleContentChanges={!handleChanges ? handleChanges : (value) => {
                                                handleChanges('dietAndNutritionReport.superFoodsRecommendation.content', value)
                                            }}
                                            handleManualContentChanges={!handleChanges ? handleChanges : (value) => {
                                                handleChanges('dietAndNutritionReport.superFoodsRecommendation.manualContent', value)
                                            }}
                                            handleSaveChanges={handleSaveChanges}
                                            isChanged={isChanged} />
                                    }
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </HealthReportMobileBasePage>
        ) : (
            <HealthReportBasePage section={HealthReportSections.DietAndNutrition} pageNumber={pageNumber} practice={practice}>
                <Box>
                    <Box px={15} pt={5}>
                        <Box display="flex" justifyContent="space-between">
                            <Box width="40%">
                                <Box >
                                    <span className="report-title">Kryptonite Foods</span>
                                </Box>
                                <Box mt={1}>
                                    <p className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeMediumReport, commonClasses.textMediumReport)}>Kryptonite for you</p>
                                    <p>Based on your genetics you should avoid these as much as possible:</p>
                                    <p>
                                        Sugar is inflammatory and contributes to increased insulin resistance,
                                        accelerated aging, and poor body composition.
                                    </p>
                                    <p>Wheat/gluten leads to inflammation and all the negative effects that come along with it.</p>
                                    <p>Vegetable oils and processed foods are particularly deleterious to you and your DNA.</p>
                                </Box>
                            </Box>
                            <Box width="40%">
                                <Box>
                                    <span className="report-title">Super Foods</span>
                                </Box>
                                <Box mt={1}>
                                    <p className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeMediumReport, commonClasses.textMediumReport)}>Optimal for you</p>
                                    <p>Based on your genetics you should focus on adding or increasing the following in your diet:</p>
                                    <p>
                                        Fish. SMASH fish. You have several snps that make sardines particularly ideal for
                                        you with their clean content of Omega 3's, collagen protein, and other key vitamins
                                        and nutrients.
                                    </p>
                                    <p>
                                        Cruciferous vegetables. Broccoli, cauliflower, brussell sprouts, and other
                                        crucifers will greatly benefit your specific genetic makeup.
                                    </p>
                                </Box>
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="space-between" mt={5}>
                            <Box width="40%">
                                {parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
                                    ? <Box>
                                        {HealthReportMode.EditMode === mode && <Box display="flex" justifyContent="space-between" mb={1}>
                                            <span className="report-title"></span>
                                            <WildHealthButton
                                                id="health-recommendation-new-save"
                                                onClick={() => handleSaveChangesNew()}
                                                disabled={!isChanged}>
                                                <Box >
                                                    Save changes
                                                </Box>
                                            </WildHealthButton>
                                        </Box>}
                                        <RecommendationSingleSelectComponent
                                            currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.KryptoniteFoods)}
                                            recommendation={report.dietAndNutritionReport.kryptoniteFoodsRecommendation}
                                            type={RecommendationTypesNumbers.KryptoniteFoods}
                                            handleSelected={handleSelected}
                                            handleChanges={handleChangesNewRecommendation}
                                            handleAddRecommendation={handleAddRecommendation}
                                            mode={mode}
                                        />
                                    </Box>
                                    : <HealthRecommendationComponent
                                        mode={mode}
                                        rows={recommendationRowsCapacity[RecommendationTypesNumbers.KryptoniteFoods]}
                                        recommendation={report.dietAndNutritionReport.kryptoniteFoodsRecommendation}
                                        handleContentChanges={!handleChanges ? handleChanges : (value) => {
                                            handleChanges('dietAndNutritionReport.kryptoniteFoodsRecommendation.content', value)
                                        }}
                                        handleManualContentChanges={!handleChanges ? handleChanges : (value) => {
                                            handleChanges('dietAndNutritionReport.kryptoniteFoodsRecommendation.manualContent', value)
                                        }}
                                        handleSaveChanges={handleSaveChanges}
                                        isChanged={isChanged} />
                                }
                            </Box>
                            <Box width="40%">
                                {parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
                                    ? <Box>
                                        {HealthReportMode.EditMode === mode && <Box display="flex" justifyContent="space-between" mb={1}>
                                            <span className="report-title"></span>
                                            <WildHealthButton
                                                id="health-recommendation-new-save"
                                                onClick={() => handleSaveChangesNew()}
                                                disabled={!isChanged}>
                                                <Box >
                                                    Save changes
                                                </Box>
                                            </WildHealthButton>
                                        </Box>}
                                        <RecommendationSingleSelectComponent
                                            currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.SuperFoods)}
                                            recommendation={report.dietAndNutritionReport.superFoodsRecommendation}
                                            type={RecommendationTypesNumbers.SuperFoods}
                                            handleSelected={handleSelected}
                                            handleChanges={handleChangesNewRecommendation}
                                            handleAddRecommendation={handleAddRecommendation}
                                            mode={mode}
                                        />
                                    </Box>
                                    : <HealthRecommendationComponent
                                        mode={mode}
                                        rows={recommendationRowsCapacity[RecommendationTypesNumbers.SuperFoods]}
                                        recommendation={report.dietAndNutritionReport.superFoodsRecommendation}
                                        handleContentChanges={!handleChanges ? handleChanges : (value) => {
                                            handleChanges('dietAndNutritionReport.superFoodsRecommendation.content', value)
                                        }}
                                        handleManualContentChanges={!handleChanges ? handleChanges : (value) => {
                                            handleChanges('dietAndNutritionReport.superFoodsRecommendation.manualContent', value)
                                        }}
                                        handleSaveChanges={handleSaveChanges}
                                        isChanged={isChanged} />
                                }
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </HealthReportBasePage>
        )
    )
}
