export enum ProductType {
    Membership = 5,
    StartupFee = 10,
    AddOns = 15,
    PhysicianVisit = 20,
    SickVisit = 25,
    HealthCoachVisit = 30,
    FollowUpLabs = 35,
    FreeLabOrder = 45,
    FreeEpigeneticOrder = 50
}

export const ProductTypeNames: { [type: number]: string } = {
    [ProductType.Membership]: 'Membership',
    [ProductType.StartupFee]: 'Startup Fee',
    [ProductType.AddOns]: 'AddOns',
    [ProductType.PhysicianVisit]: 'Physician Visit',
    [ProductType.SickVisit]: 'Sick Visit',
    [ProductType.HealthCoachVisit]: 'HealthCoach Visit',
    [ProductType.FollowUpLabs]: 'FollowUp Labs',
    [ProductType.FreeLabOrder]: 'Free Lab Order',
    [ProductType.FreeEpigeneticOrder]: 'Free Epigenetic Order'
}