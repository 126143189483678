import React from 'react';
import { AuthenticatedLayoutComponent } from '../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from '../../../../models/menu.models';
import { UnmatchedLabsComponent } from '../../components/unmatchedLabsComponent/UnmatchedLabsComponent';

export const UnmatchedLabsPage: React.FC = () => {
    return (
        <AuthenticatedLayoutComponent
            hideBackground
            title={TitlesService.unmatchedLabsTitle}
            pageName={PagesNamesService.unmatchedLabs}
            selectedMenuItem={MenuItemTypes.UnmatchedLabs}
        >
            <UnmatchedLabsComponent/>
        </AuthenticatedLayoutComponent>
    )
}