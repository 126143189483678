import { stringLengthValidationRule} from '../../../common/validation/general-validation-rules'
import { GeneralValidator } from '../../../common/validation/general-validator'
import { FollowUpFormQuestionNames } from '../../static/followUpForm.static'

export const followUpFormSurveyComponentValidator = new GeneralValidator({
    //General Update:
    [FollowUpFormQuestionNames.REFLECT_LAST_VISIT]: [stringLengthValidationRule(1, 500, 'Length should be in range from 1 to 500')],
    //Goals & Interventions:
    [FollowUpFormQuestionNames.RATE_OVERALL_PROGRESS]: [stringLengthValidationRule(1, null, 'This field is required')],
    [FollowUpFormQuestionNames.CLARIFY_PREVIOUS_QUESTIONS]: [stringLengthValidationRule(1, 500, 'Length should be in range from 1 to 500')],
    //Upcoming Visit:
    [FollowUpFormQuestionNames.HOPING_UPCOMING_VISITS]: [stringLengthValidationRule(1, 500, 'Length should be in range from 1 to 500')],
    //Checking in on your goals:
    [FollowUpFormQuestionNames.RATE_SUCCESS_EXERCISE]: [stringLengthValidationRule(1, null, 'This field is required')],
    [FollowUpFormQuestionNames.RATE_SUCCESS_NUTRITION]: [stringLengthValidationRule(1, null, 'This field is required')],
    [FollowUpFormQuestionNames.RATE_SUCCESS_SLEEP]: [stringLengthValidationRule(1, null, 'This field is required')],
    [FollowUpFormQuestionNames.RATE_SUCCESS_MINDFULNESS]: [stringLengthValidationRule(1, null, 'This field is required')],
    [FollowUpFormQuestionNames.RATE_SUCCESS_SOCIAL_CONNECTION]: [stringLengthValidationRule(1, null, 'This field is required')]
    //Checking in on your biometrics (optional):
    //no validation
})