import { Box, Grid } from '@material-ui/core';
import React from 'react';
import { PermissionType } from '../../../auth/models/auth.enums';
import { ProtectedElement } from '../../../common/components/ProtectedElement';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { MicrobiomeInputNames } from "../../constants/inputs.constants";
import { InputComponent } from "../inputComponent/InputComponent";
import { InputsComponentProps } from "../inputsComponentProps";
import { useFacade } from "./microbiomeInputsComponent.hooks";
import { useStyles } from "./microbiomeInputsComponent.styles";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";

export const MicrobiomeInputsComponent: React.FC<InputsComponentProps> = (props: InputsComponentProps) => {
    const { patientId } = props;
    const classes = useStyles();

    const [
        {
            inputs,
            isLoading,
            isChanged,
            errors
        },
        handleEdit,
        handleChanges,
        handleSubmit
    ] = useFacade(patientId);

    if (isLoading || !inputs || !inputs.length) {
        return <WildHealthLinearProgress />;
    }

    const inflammation = inputs.find(x => x.name === MicrobiomeInputNames.InflammationScore);
    const diversity = inputs.find(x => x.name === MicrobiomeInputNames.DiversityScore);

    return (
        <Box p={5}>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
                className={classes.content}
            >
                <InputComponent
                    key={inflammation.id}
                    label="Inflammation Score"
                    errors={errors}
                    input={inflammation}
                    handleChanges={handleChanges}
                    handleEdit={handleEdit}
                />

                <InputComponent
                    key={diversity.id}
                    label="Diversity Score"
                    errors={errors}
                    input={diversity}
                    handleChanges={handleChanges}
                    handleEdit={handleEdit}
                />
            </Grid>

            <Grid
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
                className={classes.content}
            >
                {
                    isChanged &&
                    <Box mt={5} m={1} display="flex" justifyContent="flex-end" className={classes.action}>
                        <ProtectedElement
                            element=
                            {<WildHealthButton
                                id="microbiome-inputs-save"
                                onClick={() => handleSubmit()}
                                disabled={!isChanged}
                            >
                                Save Changes
                            </WildHealthButton>}
                            permissions={[PermissionType.LabsModification]}
                        />
                    </Box>
                }
            </Grid>
        </Box>
    );
}