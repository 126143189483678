import React from "react";
import DateFnsUtils from '@date-io/moment';
import { TimePicker, MuiPickersUtilsProvider, TimePickerProps } from '@material-ui/pickers';
import { createTheme, MuiThemeProvider } from "@material-ui/core";
import { colors } from "../constants/colors";

export interface WildHealthTimePickerProps extends TimePickerProps {

}

export const customTheme = createTheme({
    palette: {
        primary: {
            main: colors.main,
            light: colors.main,
            dark: colors.main,
        }
    },
})

export const WildHealthTimePicker: React.FC<WildHealthTimePickerProps> = (props: TimePickerProps) => {
    return (
        <MuiThemeProvider theme={customTheme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <TimePicker
                    {...props}
                />
            </MuiPickersUtilsProvider>
        </MuiThemeProvider>
    );
};