import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { colors } from "../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        login: {
            fontStretch: 'normal',
            fontStyle: 'normal',
            fontSize: '20px',
            lineHeight: '1.57',
            fontWeight: 500,
            textTransform: 'capitalize',
            letterSpacing: '1px',
        },
        exerciseReportSubTitle: {
            color: colors.main,
            fontWeight: 400,
            fontSize: '1.25rem',
            fontFamily: 'Poppins, serif',
            [theme.breakpoints.down('xs')]: {
                fontSize: '18px',
                letterSpacing: '0.04em',
                lineHeight: '25px'
            },
        },
        reportTitle: {
            color: '#626d4b',
            fontWeight: 400,
            fontSize: '28px',
            textTransform: 'uppercase',
            letterSpacing: '2.5px',
            fontFamily: 'Poppins, serif',
            [theme.breakpoints.down('xs')]: {
                fontSize: '20px',
                letterSpacing: '0.04em'
            },
        },
        reportSubTitle: {
            color: '#626d4b',
            fontWeight: 400,
            fontSize: '1.25rem',
            textTransform: 'uppercase',
            fontFamily: 'Poppins, serif',
            [theme.breakpoints.down('xs')]: {
                fontSize: '16px',
                letterSpacing: '0.04em'
            },
        },
        referenceLinkPage: {
            color: colors.main,
        },
        referenceLink: {
            color: colors.main,
            marginLeft: '5px',
            fontSize: '80%',
            marginTop: '-4px',
        }
    }),
);
