import { Query } from '@datorama/akita';
import {
    EpigeneticOrdersState,
    epigeneticOrdersStore,
    EpigeneticOrdersStore
} from "./epigeneticOrders.store";

export class EpigeneticOrdersQuery extends Query<EpigeneticOrdersState> {
    orders$ = this.select(state => state.orders);

    constructor(protected store: EpigeneticOrdersStore) {
        super(store);
    }
}

export const epigeneticOrdersQuery = new EpigeneticOrdersQuery(epigeneticOrdersStore);
