import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        banner: {
            backgroundColor: colors.gray3,
            width: '100%',
            padding: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',

            [theme.breakpoints.down("xs")]: {
                display: 'inherit'
            },
        },
        nextUp: {
            backgroundColor: colors.average2,
            padding: '5px 10px 5px 10px',
            fontSize: '14px',
            width: 'auto',
            cursor: 'pointer !important',

            [theme.breakpoints.down("xs")]: {
                width: 'fit-content',
            },
        },
        bannerMessage: {
            width: '75%',
            textAlign: 'center',

            [theme.breakpoints.down("xs")]: {
                textAlign: 'left',
                width: '100%',
                marginTop: 16
            },
        },
        noCompleted: {
            [theme.breakpoints.down("xs")]: {
                textAlign: 'center',
                marginTop: '150px',
                fontSize: 20,
                fontWeight: 600,
                paddingLeft: 48,
                paddingRight: 48,
            },
        }
    })
);
