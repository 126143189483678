export enum FellowRoleType {
    Fellow = 1,
    FellowPatient = 2,
    No = 3
}

export enum FellowRoleSubmissionType {
    RegisterAsPatient = 1,
    SubmitPracticumPatient = 2
}

export enum SortingSource {
    Enrollment = "isEnrolled",
}