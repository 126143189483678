import { Avatar, Box, Divider, IconButton, Popover, Tab, Tabs, Typography, Link } from "@material-ui/core";
import moment from "moment";
import React, { Fragment } from "react";
import { PatientAppointmentModel } from "../../../../../appointments/models/appointments.models";
import WildHealthLinearProgress from "../../../../../common/components/WildHealthLinearProgress";
import { WildHealthMenuItem } from "../../../../../common/components/wildHealthMenu/WildHealthMenu";
import { displayShortName } from "../../../../../conversations/helpers/messages.converter";
import { AppointmentsEnum, useFacade } from "./appointmentsInfoWidgetComponent.hooks";
import { useStyles } from "./appointmentsInfoWidgetComponent.styles";
import DateRangeIcon from '@material-ui/icons/DateRange';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import AppointmentTags from "../../../dashboardWidget/appointmentWidgetComponent/AppointmentTags/AppointmentTags";
import WatchLaterRoundedIcon from '@material-ui/icons/WatchLaterRounded';
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useHistory } from "react-router";
import { PatientRescheduleAppointmentDialogComponent } from "../../../../../appointments/components/patientRescheduleAppointmentDialog/PatientRescheduleAppointmentDialogComponent";
import { CancelAppointmentDialogComponent } from "../../../../../appointments/components/cancelAppointmentDialogComponent/CancelAppointmentDialogComponent";
import { navigationService } from "../../../../../../services/navigation.service";
import { WildHealthPlaceHolderBase } from "../../../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolderBase";
import emptyAppointments from "@img/placeholders/EmptyAppointments.png";
import { WildHealthStatusLabel } from "../../../../../common/components/wildHealthStatusLabel/WildHealthStatusLabel";
import { AppointmentStatus, AppointmentStatusNames } from "../../../../../appointments/models/appointments.enums";



export interface AppointmentsInfoWidgetComponentProps {
  patientId: number | null;
}

export const AppointmentsInfoWidgetComponent: React.FC<AppointmentsInfoWidgetComponentProps> = (
  props: AppointmentsInfoWidgetComponentProps
) => {

  const [
    {
      isLoading,
      appointments,
      previousAppointments,
      menuAnchorEl,
      patient,
      tabSelected
    },
    handleToggleMenu,
    handleRescheduleAppointment,
    handleCancel,
    handleTabChange,
  ] = useFacade(props.patientId);

  const classes = useStyles();
  const _history = useHistory();

  const getTitleEmployee = (appointment: PatientAppointmentModel): string => {
    return appointment.employees
      .map(e => `${e.firstName} ${e.lastName}`)
      .join(', ');
  }

  const getAvatarLetters = (): string => {
    return displayShortName(patient.firstName, patient.lastName)
  }

  const getStatus = (appointment: PatientAppointmentModel) => {
    if (tabSelected === AppointmentsEnum.Upcoming) {
      return <></>
    }
    if (appointment.noShow) {
      return <WildHealthStatusLabel color='average1'>No Show</WildHealthStatusLabel>
    }
    if (appointment.status !== AppointmentStatus.Canceled) {
      return <WildHealthStatusLabel color='good'>Completed</WildHealthStatusLabel>
    } else {
      return <WildHealthStatusLabel color='bad'>{AppointmentStatusNames.get(appointment.status)}</WildHealthStatusLabel>
    }
  }

  const renderAppointment = (
    appointment: PatientAppointmentModel,
    index: number,
  ) => {
    return [
      <Fragment key={`appointment-template-${index}`}>
        <Box my={2}>
          <Box display='flex' justifyContent='space-between'>
            <Box className={classes.appointmentName}>{appointment.name}</Box>
            <Box mr={0.5}>{getStatus(appointment)}</Box>
          </Box>
          <Box display='flex' my={1.5}>
            <Box>
              <Avatar className={classes.avatar}
                variant="rounded" src={null} >
                {getAvatarLetters()}
              </Avatar>
            </Box>
            <Box ml={1} width='100%'>
              <Box display='flex' justifyContent='space-between'>
                <Box>
                  <Box className={classes.patientBox} pb={1}>
                    <span className={classes.patientName}>{patient.firstName} {patient.lastName}</span>
                    {appointment.tags && <AppointmentTags tags={appointment.tags} />}
                  </Box>
                  <Box className={classes.employeeName} pb={1}>
                    {getTitleEmployee(appointment)}
                  </Box>
                  <Box className={classes.dateTime} pb={1}>
                    <Box>
                      <DateRangeIcon className={classes.dateIcon} />
                    </Box>
                    <Box ml={1}>
                      <Typography className={classes.date} color="textSecondary" gutterBottom>
                        {moment(appointment.startDate).format('ddd, MMMM DD')}
                      </Typography>
                    </Box>

                    <Box ml={1}>
                      <WatchLaterRoundedIcon className={classes.timeIcon} />
                    </Box>
                    <Box ml={1}>
                      <Typography className={classes.time} gutterBottom>
                        {`${moment(appointment.startDate).format('hh:mm a')} - ${moment(appointment.endDate).format('hh:mm a')}`}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  {tabSelected === AppointmentsEnum.Upcoming
                    && <IconButton
                      id="appointment-widget-more-menu"
                      onClick={(e) => handleToggleMenu(appointment.id, e.currentTarget)}
                    >
                      <MoreHorizIcon />
                    </IconButton>}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Fragment>,
      <Divider
        key={`divider-${index}`}
        className={classes.divider}
        orientation="horizontal"
      />
    ];
  };

  const renderAppointments = (selectedTab: number) => {

    switch (selectedTab) {
      case AppointmentsEnum.Upcoming:
        {
          if (appointments.length === 0) {
            return <Box mt="60px">
              <WildHealthPlaceHolderBase message={`No Upcoming Appointments`}>
                <img style={{ width: 'inherit', maxWidth: '300px' }}
                  src={emptyAppointments}
                  alt="img" />
              </WildHealthPlaceHolderBase>
            </Box>
          }
          const items = appointments
            .map((i, index) =>
              renderAppointment(i, index)).flat();
          items.pop();
          return items;
        }
      case AppointmentsEnum.Previous:
        {
          if (previousAppointments.length === 0) {
            return <Box mt="60px">
              <WildHealthPlaceHolderBase message={`No Previous Appointments`}>
                <img style={{ width: 'inherit', maxWidth: '300px' }}
                  src={emptyAppointments}
                  alt="img" />
              </WildHealthPlaceHolderBase>
            </Box>
          }
          const items = previousAppointments
            .map((i, index) =>
              renderAppointment(i, index)).flat();
          items.pop();
          return items;

        }

    }

  };

  const renderContent = () => {
    if (isLoading) {
      return <WildHealthLinearProgress />;
    }

    return <>
      <Tabs
        value={tabSelected}
        onChange={handleTabChange}
        classes={{ root: classes.tabsWrapper, indicator: classes.indicator }}>
        <Tab label={`Upcoming (${appointments.length})`}
          disableRipple
          classes={{ root: classes.tabRoot, selected: classes.selectedTab }}
        />
        <Tab label={`Previous (${previousAppointments.length})`}
          disableRipple
          classes={{ root: classes.tabRoot, selected: classes.selectedTab }}
        />
      </Tabs>
      <Box mt={3} className='flat-scroll' style={{ overflowY: 'auto', maxHeight: '500px' }}>
        {renderAppointments(tabSelected)}
      </Box>
      <Popover
        keepMounted
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={() => handleToggleMenu(0)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <WildHealthMenuItem id="appointment-widget-cancel" onClick={() => handleCancel()}>Cancel</WildHealthMenuItem>
        <WildHealthMenuItem id="appointment-widget-reschedule" onClick={() => handleRescheduleAppointment()}>Reschedule</WildHealthMenuItem>
      </Popover>

    </>
  };

  return (
    <Box width='100%'>
      <Box display='flex' justifyContent="space-between" width='100%' mb={3}>
        <Box className={classes.title}>
          Appointments
        </Box>
        <Box>
          <Link
            id='appointment-see-all'
            className={classes.historyLink}
            onClick={() => navigationService.toPatientUpcomingAppts(_history, props.patientId)}
          >
            <Typography className={classes.historyLinkText} display='inline'>
              <span> See All </span>
            </Typography>
            <ArrowForwardIcon color='inherit' fontSize='small' />
          </Link>
        </Box>
      </Box>

      <Box>
        {renderContent()}
        <PatientRescheduleAppointmentDialogComponent patientId={props.patientId} />
        <CancelAppointmentDialogComponent />
      </Box>
    </Box >
  );
};
