import { Box, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { HealthRecommendationComponent } from "../components/healthRecommendationComponent/HealthRecommendationComponent";
import {
    HealthReportMode,
    HealthReportSections,
    HealthReportVersion,
    recommendationRowsCapacity, RecommendationTypesNumbers
} from "../models/healthReport.models";
import { HealthReportBasePage, HealthReportMobileBasePage } from "./HealthReportBasePage";
import { useStyles } from './healthReport.styles';
import { HealthReportPageProps } from "./healthReportPageProps";
import { riskColorMap } from "../constants/healthReport.constants";
import { DnaTableComponent } from "../components/dnaTableComponent/DnaTableComponent";
import { WildHealthButton } from '../../common/components/wildHealthButton/WildHealthButton';
import { RecommendationSingleSelectComponent } from '../components/recommendationSingleSelectComponent/RecommendationSingleSelectComponent';

export const HealthReportDietAndNutrition5Page: React.FC<HealthReportPageProps> = (props: HealthReportPageProps) => {
    const {
        report,
        pageNumber,
        handleChanges,
        handleSaveChanges,
        isChanged,
        mode,
        practice,
        recommendations,
        handleSelected,
        handleChangesNewRecommendation,
        handleAddRecommendation,
        handleSaveChangesNew,
    } = props;

    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        props.mode !== HealthReportMode.PrintMode && isSmallScreen ? (
            <HealthReportMobileBasePage section={HealthReportSections.DietAndNutrition} pageNumber={pageNumber} practice={practice}>
                <Box>
                    <Box px={2}>
                        <Box>
                            <span className={classes.reportTitle}>
                                Metabolism and Vitamins - Vitamins and Micronutrients
                            </span>
                        </Box>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box fontSize="14px" lineHeight="22px">
                                    <Box mt="10px">
                                        <span>
                                            Genetic variation can significantly affect important vitamins and micronutrients. Some of these we
                                            can easily monitor with lab testing such as vitamin D and omega-3 levels. Some are less readily
                                            measured, but easy to increase intake based on your genetics. We’ll cover the following vitamins
                                            and micronutrients here:
                                        </span>
                                    </Box>
                                    <Box mt="10px">
                                        <span>
                                            <span className="text-medium">Omega 3s:</span> These essential fatty acids cannot be produced in the human body. Omega 3s are active and
                                            beneficial as DHA and EPA. These are most readily found in fish oils, krill oil and algae. ALA,
                                            which can be converted to the active forms, DHA and EPA, by the body, is found in nuts and seeds and
                                            vegetable forms of omega 3s. The enzyme that is responsible for this conversion is a common SNP we
                                            will monitor. We’ll also directly monitor your Omega 3 levels.
                                        </span>
                                    </Box>
                                    <Box mt="10px">
                                        <span>
                                            <span className="text-medium">Vitamin D:</span> Is an essential hormone (not really a vitamin) that the body makes using sunlight. Nearly
                                            all humans are now low or sub-optimal in vitamin D due to the nature of society. There are multiple
                                            SNPs associated with Vitamin D and we will directly monitor your levels.
                                        </span>
                                    </Box>
                                    <Box mt="10px">
                                        <span>
                                            <span className="text-medium">Vitamin A:</span> Important for eyesight and immune function. The vegetable form, beta-carotene, must be
                                            converted to the active form, retinol, by an enzyme BCMO1, which has common mutations. We’ll review
                                            your genetic disposition and make recommendations.
                                        </span>
                                    </Box>
                                    <Box mt="10px">
                                        <span>
                                            <span className="text-medium">Vitamin B12:</span> Commonly reduced absorption in the stomach. Medications such as antacids and metformin
                                            can reduce absorption as well as genetics. We’ll review your current level as well as your genetic
                                            predispositions.
                                        </span>
                                    </Box>
                                    <Box mt="10px">
                                        <span>
                                            <span className="text-medium">Vitamin E:</span> Can have either beneficial or anti-inflammatory effects depending on your genetics. We’ll
                                            review your predispositions.
                                        </span>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box mt="20px">
                                    <DnaTableComponent
                                        dna={report.dietAndNutritionReport.vitaminsAndMicronutrientsDna.dna.sort((a, b) => {
                                            if (a.key < b.key) { return -1; }
                                            if (a.key > b.key) { return 1; }
                                            return 0;
                                        })}
                                        colorMap={riskColorMap}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Box mt="20px">
                            <Box>
                                <span className={classes.reportSubTitle}>Your Vitamins and micronutrients Recommendations</span>
                                <Box display="flex" justifyContent="flex-end" width={1}>
                                    {
                                        parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation) && HealthReportMode.EditMode === mode && <WildHealthButton
                                            id="health-recommendation-new-save"
                                            onClick={() => handleSaveChangesNew()}
                                            disabled={!isChanged}>
                                            <Box >
                                                Save changes
                                            </Box>
                                        </WildHealthButton>
                                    }
                                </Box>
                            </Box>
                            <Box mt="10px">
                                {parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
                                    ? <Box>
                                        <RecommendationSingleSelectComponent
                                            currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.VitaminsAndMicronutrients)}
                                            recommendation={report.dietAndNutritionReport.vitaminsAndMicronutrientsRecommendation}
                                            type={RecommendationTypesNumbers.VitaminsAndMicronutrients}
                                            handleSelected={handleSelected}
                                            handleChanges={handleChangesNewRecommendation}
                                            handleAddRecommendation={handleAddRecommendation}
                                            mode={mode}
                                        />
                                    </Box>
                                    : <HealthRecommendationComponent
                                        mode={mode}
                                        rows={recommendationRowsCapacity[RecommendationTypesNumbers.VitaminsAndMicronutrients]}
                                        recommendation={report.dietAndNutritionReport.vitaminsAndMicronutrientsRecommendation}
                                        handleContentChanges={!handleChanges ? handleChanges : (value) => {
                                            handleChanges('dietAndNutritionReport.vitaminsAndMicronutrientsRecommendation.content', value)
                                        }}
                                        handleManualContentChanges={!handleChanges ? handleChanges : (value) => {
                                            handleChanges('dietAndNutritionReport.vitaminsAndMicronutrientsRecommendation.manualContent', value)
                                        }}
                                        handleSaveChanges={handleSaveChanges}
                                        isChanged={isChanged} />
                                }
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </HealthReportMobileBasePage>
        ) : (
            <HealthReportBasePage section={HealthReportSections.DietAndNutrition} pageNumber={pageNumber} practice={practice}>
                <Box>
                    <Box px={15} pt={5}>
                        <Box>
                            <span className="report-title">
                                Metabolism and Vitamins - Vitamins and Micronutrients
                            </span>
                        </Box>
                        <Grid container>
                            <Grid item xs={8}>
                                <Box>
                                    <Box mt={1}>
                                        <span>
                                            Genetic variation can significantly affect important vitamins and micronutrients. Some of these we
                                            can easily monitor with lab testing such as vitamin D and omega-3 levels. Some are less readily
                                            measured, but easy to increase intake based on your genetics. We’ll cover the following vitamins
                                            and micronutrients here:
                                        </span>
                                    </Box>
                                    <Box mt={2}>
                                        <span>
                                            <span className="text-medium">Omega 3s:</span> These essential fatty acids cannot be produced in the human body. Omega 3s are active and
                                            beneficial as DHA and EPA. These are most readily found in fish oils, krill oil and algae. ALA,
                                            which can be converted to the active forms, DHA and EPA, by the body, is found in nuts and seeds and
                                            vegetable forms of omega 3s. The enzyme that is responsible for this conversion is a common SNP we
                                            will monitor. We’ll also directly monitor your Omega 3 levels.
                                        </span>
                                    </Box>
                                    <Box mt={2}>
                                        <span>
                                            <span className="text-medium">Vitamin D:</span> Is an essential hormone (not really a vitamin) that the body makes using sunlight. Nearly
                                            all humans are now low or sub-optimal in vitamin D due to the nature of society. There are multiple
                                            SNPs associated with Vitamin D and we will directly monitor your levels.
                                        </span>
                                    </Box>
                                    <Box mt={2}>
                                        <span>
                                            <span className="text-medium">Vitamin A:</span> Important for eyesight and immune function. The vegetable form, beta-carotene, must be
                                            converted to the active form, retinol, by an enzyme BCMO1, which has common mutations. We’ll review
                                            your genetic disposition and make recommendations.
                                        </span>
                                    </Box>
                                    <Box mt={2}>
                                        <span>
                                            <span className="text-medium">Vitamin B12:</span> Commonly reduced absorption in the stomach. Medications such as antacids and metformin
                                            can reduce absorption as well as genetics. We’ll review your current level as well as your genetic
                                            predispositions.
                                        </span>
                                    </Box>
                                    <Box mt={2}>
                                        <span>
                                            <span className="text-medium">Vitamin E:</span> Can have either beneficial or anti-inflammatory effects depending on your genetics. We’ll
                                            review your predispositions.
                                        </span>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box pl={2} pt={3}>
                                    <DnaTableComponent
                                        dna={report.dietAndNutritionReport.vitaminsAndMicronutrientsDna.dna.sort((a, b) => {
                                            if (a.key < b.key) { return -1; }
                                            if (a.key > b.key) { return 1; }
                                            return 0;
                                        })}
                                        colorMap={riskColorMap}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Box mt={4}>
                            <Box display="flex" justifyContent="space-between">
                                <span className="report-sub-title">Your Vitamins and micronutrients Recommendations</span>
                                {
                                    parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation) && HealthReportMode.EditMode === mode && <WildHealthButton
                                        id="health-recommendation-new-save"
                                        onClick={() => handleSaveChangesNew()}
                                        disabled={!isChanged}>
                                        <Box >
                                            Save changes
                                        </Box>
                                    </WildHealthButton>
                                }
                            </Box>
                            <Box mt={1}>
                                {parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
                                    ? <Box>
                                        <RecommendationSingleSelectComponent
                                            currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.VitaminsAndMicronutrients)}
                                            recommendation={report.dietAndNutritionReport.vitaminsAndMicronutrientsRecommendation}
                                            type={RecommendationTypesNumbers.VitaminsAndMicronutrients}
                                            handleSelected={handleSelected}
                                            handleChanges={handleChangesNewRecommendation}
                                            handleAddRecommendation={handleAddRecommendation}
                                            mode={mode}
                                        />
                                    </Box>
                                    : <HealthRecommendationComponent
                                        mode={mode}
                                        rows={recommendationRowsCapacity[RecommendationTypesNumbers.VitaminsAndMicronutrients]}
                                        recommendation={report.dietAndNutritionReport.vitaminsAndMicronutrientsRecommendation}
                                        handleContentChanges={!handleChanges ? handleChanges : (value) => {
                                            handleChanges('dietAndNutritionReport.vitaminsAndMicronutrientsRecommendation.content', value)
                                        }}
                                        handleManualContentChanges={!handleChanges ? handleChanges : (value) => {
                                            handleChanges('dietAndNutritionReport.vitaminsAndMicronutrientsRecommendation.manualContent', value)
                                        }}
                                        handleSaveChanges={handleSaveChanges}
                                        isChanged={isChanged} />
                                }
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </HealthReportBasePage>
        )
    )
}
