import Axios from 'axios-observable';
import {authHeader} from '../../common/helpers/auth-header';
import {snackService} from '../../common/snack/state';
import {AppointmentsStore, appointmentsStore} from '../../appointments/stores/appointments';
import {Observable} from 'rxjs';
import {UserType} from "../../auth/models/auth.enums";
import {timezonesStore, TimezonesStore} from "../stores/timezones";
import {TimeZoneModel} from "../models/timezone.model";

export class TimezonesService {
    private url = `${process.env.REACT_APP_API_URL}Timezones`;

    constructor(
        private appointmentsStore: AppointmentsStore,
        private timezonesStore: TimezonesStore) {
    }

    public getTimezones(): Observable<TimeZoneModel[]>{
        return new Observable(observer => {
            Axios.get<TimeZoneModel[]>(`${this.url}`).pipe()
                .subscribe(
                    response => {
                        this.timezonesStore.setTimezones(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        });
    }

    public getMyTimezone(): Observable<TimeZoneModel> {
        const config = { headers: authHeader() };

        return new Observable(observer => {
            Axios.get<TimeZoneModel>(`${this.url}/My/Current`, config).pipe()
                .subscribe(
                    response => {
                        this.timezonesStore.update({ myTimezone: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        });
    }

    public getTimezone(userType: UserType, id: number): Observable<TimeZoneModel> {
        const config = { headers: authHeader() };

        return new Observable(observer => {
            Axios.get<TimeZoneModel>(`${this.url}/Current?userType=${userType}&id=${id}`, config).pipe()
                .subscribe(
                    response => {
                        this.timezonesStore.update({ myTimezone: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        });
    }
}

export const timezonesService = new TimezonesService(appointmentsStore, timezonesStore);
