import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../common/constants/colors';

export const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 12,
            fontWeight: 500,
            color: colors.black,
            width: '100%',
            height: '100%',
            backgroundImage: `linear-gradient(135deg, ${colors.bg} 33.33%, ${colors.white} 33.33%, ${colors.white} 50%, ${colors.bg} 50%, ${colors.bg} 83.33%, ${colors.white} 83.33%, ${colors.white} 100%)`,
            backgroundSize: '42.43px 42.43px'
        },
        label: {
            backgroundColor: 'rgba(124, 138, 157, 0.16)',
            color: colors.black,
            margin: '7px',
            width: '100%',
            height: 'calc(100% - 14px)',
            borderRadius: '6px'
        }
    })
);