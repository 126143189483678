import { TextField } from '@material-ui/core';
import { makeStyles, withStyles, Theme } from '@material-ui/core/styles';
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) => ({
  search: {
    backgroundColor: colors.white,
    borderColor: colors.white,
  },
  searchBase: {
    padding: '10px 14px',
    fontFamily: 'Be Vietnam Pro',
    fontSize: 16,
  },
  icon: {
    color: colors.gray2,
  },
  menuIcon: {
    [theme.breakpoints.down("xs")]: {
      "&.MuiIconButton-root": {
        padding: 0,
        color: colors.black,
      },
    },
  },
}));

export const SearchTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: colors.main,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: colors.white,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: colors.white,
      },
      '&:hover fieldset': {
        borderColor: colors.white,
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.white,
      },
    },
  },
})(TextField);
