import { Box, Divider, FormControl, FormControlLabel, RadioGroup } from '@material-ui/core';
import React from 'react';
import { WildHealthRadio } from "../../../common/components/WildHealthRadio";
import { displayMoney } from "../../../common/helpers/display-money";
import { PaymentPeriodModel } from "../../models/paymentPeriod.models";
import { PaymentPlanModel } from "../../models/paymentPlan.models";
import {PaymentPriceModel, PaymentPriceType, PaymentStrategy} from "../../models/paymentPrice.models";
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from 'clsx';
import {getCorrespondingPrice} from "../../helpers/getCorrespondingPrice";

interface PaymentSummaryComponentProps {
    selectedPlan: PaymentPlanModel;
    selectedPeriod: PaymentPeriodModel;
    selectedPrice: PaymentPriceModel;
    handlePriceSelect: Function;
    priceType?: PaymentPriceType;
}

export const PaymentSummaryComponent: React.FC<PaymentSummaryComponentProps> = (props: PaymentSummaryComponentProps) => {
    const {
        selectedPlan,
        selectedPeriod,
        selectedPrice,
        handlePriceSelect,
        priceType = PaymentPriceType.Default
    } = props;

    const commonClasses = commonUseStyles();
    const prices = selectedPeriod.prices;
    const annualPrice = getCorrespondingPrice(prices, PaymentStrategy.FullPayment, priceType);
    const monthlyPrice = getCorrespondingPrice(prices, PaymentStrategy.PartialPayment, priceType);

    const displayPaymentPrice = (price: PaymentPriceModel) => {
        if (price.isExclusive) {
            return price.strategy === PaymentStrategy.FullPayment
                ? 'Pay in Total (COUPON DISCOUNT)'
                : `${displayMoney(price.price, '$')} Monthly (COUPON DISCOUNT)`;
        }

        if (price.strategy === PaymentStrategy.PartialPayment) {
            return price.discount
                ? `${displayMoney(price.price, '$')} Monthly (SAVE ${displayMoney(price.discount, '$')})`
                : `${displayMoney(price.price, '$')} Monthly`;
        }

        if (price.strategy === PaymentStrategy.FullPayment) {
            return price.discount
                ? `Pay in Total (SAVE ${displayMoney(price.discount, '$')})`
                : `Pay in Total`;
        }

        return displayMoney(price.price, '$');
    }

    return (
        <Box className={commonClasses.backgroundColorBg}>
            <Box px="30px" pt="30px" pb="20px">
                <Box>
                    <span className={clsx(commonClasses.size20, commonClasses.textMedium)}>Summary</span>
                </Box>
                <Box mt="30px" display="flex" justifyContent="space-between">
                    <Box>
                        <span className={commonClasses.colorGray1}>Selected Plan</span>
                    </Box>
                    <Box>
                        <span className={commonClasses.textMedium}>{selectedPlan.displayName}</span>
                    </Box>
                </Box>
            </Box>
            <Divider />
            <Box px="30px" pt="20px" pb="20px">
                <Box>
                    <span className={clsx(commonClasses.colorGray1, commonClasses.size14)}>Charged Upon Purchase</span>
                </Box>
                <Box mt={2}>
                    <FormControl color='primary' component="fieldset">
                        <RadioGroup aria-label="gender" name="gender1" value={selectedPrice.id} onChange={(event) => handlePriceSelect(Number(event.target.value))}>
                            {
                                annualPrice &&
                                <FormControlLabel key={annualPrice.id} value={annualPrice.id} control={<WildHealthRadio />} label={displayPaymentPrice(annualPrice)} />
                            }
                            {
                                monthlyPrice &&
                                <FormControlLabel key={monthlyPrice.id} value={monthlyPrice.id} control={<WildHealthRadio />} label={displayPaymentPrice(monthlyPrice)} />
                            }
                        </RadioGroup>
                    </FormControl>
                </Box>
                {
                    selectedPrice.startupFee > 0 &&
                    <Box>
                        <Box>
                            <span className={commonClasses.size14}>
                                {displayMoney(selectedPrice.startupFee, '$')} Startup Fee Added During Purchase
                                </span>
                        </Box>
                    </Box>
                }
            </Box>
            <Divider />
            <Box px="30px" pt="20px" pb="30px">
                {
                    selectedPrice.isAutoRenewal &&
                    <Box>
                        <span className={clsx(commonClasses.size14, commonClasses.colorGray1, commonClasses.tertiaryFont)}>
                            *All plans auto-renew at the end of 12 months unless cancelled at least 30 days prior to renewal*
                        </span>
                    </Box>
                }
            </Box>
        </Box>
    )
};