import { HealthSummaryMapModel, HealthSummarySectionPosition, HealthSummarySectionType } from '../../models/healthSummary.models'
import { HealthSummaryBlockItemsComponent } from '../../components/blockItemsComponent/HealthSummaryBlockItemsComponent'
import { HealthSummaryListItemsComponent } from '../../components/listItemsComponent/HealthSummaryListItemsComponent'
import { HealthSummaryPlaceHolder } from '../../../common/components/wildHealthPlaceHolder/HealthSummaryPlaceHolder'
import { HealthSummaryContentComponent } from '../../components/contentComponent/HealthSummaryContentComponent'
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress'
import { WildHealthLinkButton } from '../../../common/components/WildHealthLinkButton'
import { navigationService } from '../../../../services/navigation.service'
import { useStyles } from './healthSummaryComponent.styles'
import { UserType } from '../../../auth/models/auth.enums'
import { useFacade } from './healthSummaryComponent.hooks'
import { getKey } from '../../helpers/healthSummaryHelper'
import { authQuery } from '../../../auth/stores/auth'
import { Box, Grid } from '@material-ui/core'
import { useHistory } from 'react-router'
import React from 'react'
import { BlockItemApoeComponent } from '../blockItemApoeComponent/BlockItemApoeComponent'

export interface HealthSummaryComponentProps {
    patientId?: number
}

export const HealthSummaryComponent: React.FC<HealthSummaryComponentProps> = (props: HealthSummaryComponentProps) => {
    const { patientId } = props

    const [state] = useFacade(patientId)

    const classes = useStyles()
    const history = useHistory()

    const getSectionMap = (sectionPosition: HealthSummarySectionPosition): HealthSummaryMapModel[] => {
        return state.map
            .filter(x => x.sectionPosition === sectionPosition && x.key !== "SCREENING_HEALTH_MAINTENANCE")
            .sort((a, b) => a.order - b.order)
    }

    const getSectionData = (section: HealthSummaryMapModel) => {
        var keys = [...section.items.map(x => x.key), section.key]

        return state.data.filter(x => keys.includes(getKey(x.key)))
    }

    const buildSectionComponent = (map: HealthSummaryMapModel, rowFormat?: boolean) => {
        const isEmployeeMode = !!patientId;

        const sectionType = isEmployeeMode ? map.editSectionType : map.sectionType;
        switch (sectionType) {
            case HealthSummarySectionType.Block:
                return  <HealthSummaryBlockItemsComponent
                    data={getSectionData(map)}
                    rowFormat={rowFormat}
                    patientId={patientId}
                    map={map}
                />
            case HealthSummarySectionType.List:
                return (
                    <HealthSummaryListItemsComponent
                        data={getSectionData(map)}
                        patientId={patientId}
                        map={map}
                    />
                )
            case HealthSummarySectionType.Content:
                return (
                    <HealthSummaryContentComponent
                        data={getSectionData(map)}
                        patientId={patientId}
                        map={map}
                    />
                )
        }
    }

    const renderContent = () => {
        return (
            <Grid container className={classes.root}>
                <Grid item xs={12} md={6}>
                    <Box p={1} className={classes.container}>
                        {
                            getSectionMap(HealthSummarySectionPosition.Left).map(mapItem =>
                                buildSectionComponent(mapItem)
                            )
                        }
                    </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Box p={1} className={classes.container}>
                        {
                            getSectionMap(HealthSummarySectionPosition.Right).map(mapItem =>
                                buildSectionComponent(mapItem)
                            )
                        }
                        <BlockItemApoeComponent
                            patientId={patientId}

                        />
                    </Box>
                </Grid>

                <Grid item xs={12} md={12}>
                    <Box p={1} className={classes.container}>
                        {
                            getSectionMap(HealthSummarySectionPosition.Bottom).map(mapItem =>
                                buildSectionComponent(mapItem, true)
                            )
                        }
                    </Box>
                </Grid>
            </Grid>
        )
    }

    const linkQuestionnaireButton = () => {
        const clickAction = authQuery.getType() === UserType.Employee
            ? () => navigationService.toPatientHealthForms(history, patientId)
            : () => navigationService.toMyHealthForms(history)

        return <WildHealthLinkButton
            id={'questionnaire-button'}
            content={'questionnaire'}
            onClick={clickAction}
            linkButton={true}
        />
    }


    const renderEmptyPage = () => {
        return <Box className={classes.emptyPage}>
            <HealthSummaryPlaceHolder title={'No information provided.'}>
                <Box>Complete {linkQuestionnaireButton()} to have your info displayed here.</Box>
            </HealthSummaryPlaceHolder>
        </Box>
    }

    if (state.isLoading) {
        return (
            <WildHealthLinearProgress />
        );
    }

    return (
        state.isDataPresent ? renderContent() : renderEmptyPage()
    )
}

