import { Store, StoreConfig } from '@datorama/akita';
import { toTimeZoneDate } from '../../../timezones/helpers/timezone';
import { timezonesQuery } from '../../../timezones/stores/timezones';
import { AvailabilityModel, EmployeeAvailabilityModel } from '../../models/availability.models';
import {EmployeeShortModel} from "../../../employee/models/employee.models";

export interface AvailabilityState {
    availabilities: EmployeeAvailabilityModel[];
    currentAvailability: EmployeeAvailabilityModel
}

/**
 * Creates initial appointment state
 */
export function createInitialState(): AvailabilityState {
    return {
        availabilities: [],
        currentAvailability: null
    };
}

/**
 * Provides appointment state management
 */
@StoreConfig({name: 'appointments', resettable: true })
export class AvailabilityStore extends Store<AvailabilityState> {
    constructor() {
        super(createInitialState());
    }

    public setCurrentAvailability(employeeId: number, availabilities: AvailabilityModel[]): void {
        this.update({currentAvailability: {
            employeeId: employeeId,
            availability: this.toView(availabilities),
            availabilitySource: availabilities
        } as EmployeeAvailabilityModel})
    }

    public setAvailability(employeeId: number, availabilities: AvailabilityModel[]): void {
        const employeeAvailabilities = this.getValue().availabilities;

        if(employeeAvailabilities.find(x => x.employeeId === employeeId) === undefined)
        {
            this.update({availabilities: [...this.getValue().availabilities, {
                employeeId: employeeId,
                availability: this.toView(availabilities),
                availabilitySource: availabilities
            } as EmployeeAvailabilityModel]});

            return;
        }
        this.update({availabilities: this.getValue().availabilities.map(x => x.employeeId === employeeId
            ? {
                employeeId: employeeId,
                availability: this.toView(availabilities),
                availabilitySource: availabilities
            } as EmployeeAvailabilityModel
            : x)})
    }

    public setAvailabilityByEmployeeIds(employeeIds: number[], availabilities: AvailabilityModel[]): void {
        let employeeAvailabilities = this.getValue().availabilities;

        employeeIds.forEach(employeeId => {
            if (employeeAvailabilities.find(item => item.employeeId === employeeId) === undefined) {
                employeeAvailabilities = [...employeeAvailabilities, {
                    employeeId: employeeId,
                    availability: this.toView(availabilities),
                    availabilitySource: availabilities
                } as EmployeeAvailabilityModel];
            } else {
                employeeAvailabilities = employeeAvailabilities.map(x => x.employeeId === employeeId
                    ? {
                        employeeId: employeeId,
                        availability: this.toView(availabilities),
                        availabilitySource: availabilities
                    } as EmployeeAvailabilityModel
                    : x)
            }
        })
        
        
        this.update({availabilities: employeeAvailabilities.slice()})
    }

    public setBulkAvailability(employees: EmployeeShortModel[], availabilities: any): void {
        employees.forEach((employee) => {
            const availability = availabilities[employee.id];
            this.setAvailability(employee.id, availability);
        })
    }
    
    private toView(availabilities: AvailabilityModel[]): AvailabilityModel[] {
        const timezone = timezonesQuery.getMyTimezone();

        return availabilities.map(x=> ({
            end: toTimeZoneDate(x.end, timezone),
            start: toTimeZoneDate(x.start, timezone),
            timeZone: x.timeZone
        } as AvailabilityModel));
    }
}

export const availabilityStore = new AvailabilityStore();