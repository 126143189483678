import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { confirmService } from "../../../../services/confirm.service";
import { onEmit } from "../../../common/helpers/on-emit";
import { DnaOrderBaseModel, DnaOrderModel } from "../../models/dnaOrders.models";
import { dnaOrdersService } from "../../services/dnaOrdersService";
import { dnaOrdersQuery } from "../../stores/dnaOrdersStore";

interface NewDnaOrderComponentState {
    isLoading: boolean;
    orders: DnaOrderBaseModel[];
}

export function useFacade(patientId: number): [NewDnaOrderComponentState, Array<string>, (orderId?: number) => void] {
    const [state, setState] = useState({
        isLoading: true,
        orders: [],
    } as NewDnaOrderComponentState);

    const columns = [
        'Date',
        'Results Uploaded',
        'Action'
    ];

    const handleToggleReplacement = (orderId?: number) => {
        confirmService.confirmWithReason('Reason For Kit Replacement:', 'Provide Reasons', 'Reason', 'Place Order').subscribe((reason) => handleOrderReplacement(orderId, reason));
    }

    const handleOrderReplacement = (orderId: number, reason: string) => {
        dnaOrdersService.replace({id: orderId, reason: reason}).subscribe(() => {});
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<DnaOrderModel[]>(dnaOrdersQuery.orders$, dna => {
                setState(state => ({
                    ...state,
                    orders: dna,
                    isLoading: false,
                }));
            })
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [patientId]);

    return [state, columns, handleToggleReplacement];
}