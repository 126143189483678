import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { onEmit } from "../../../common/helpers/on-emit";
import { ConversationModelBaseView, PatientConversationModel } from "../../models/conversation.models";
import { patientConversationsQuery } from "../../stores/patientConversationsStore/patientConversations.query";
import { patientConversationsStore } from "../../stores/patientConversationsStore/patientConversations.store";
import { conversationsViewService } from "../../services/conversationsView.service";
import { employeeConversationsStore } from "../../stores/employeeConversationsStore/employeeConversations.store";

export enum FullStaffChatComponentView {
    SelectedConversation
}

interface FullStaffChatComponentState {
    isSideAreaOpen: boolean;
    isLoading: boolean;
    targetConversation: PatientConversationModel;
    messagesStaff: ConversationModelBaseView[];
}

export function useFacade(): [FullStaffChatComponentState, (open: boolean) => void, (conversation: PatientConversationModel) => void] {
    const [state, setState] = useState({
        isSideAreaOpen: false,
        isLoading: false,
        author: null,
        targetConversation: null,
        messagesStaff: null,
    } as FullStaffChatComponentState);

    const handleToggleSideArea = (open: boolean) => {
        setState({ ...state, isSideAreaOpen: open });
    }

    const handleSelectConversation = (conversation: PatientConversationModel) => {
        setState(state => ({ ...state, isLoading: true, targetConversation: conversation}))
        patientConversationsStore.setTargetConversation(conversation);
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<ConversationModelBaseView[]>(patientConversationsQuery.targetStaffConversation$, messages =>
                setState(state => ({ ...state, messagesStaff: messages }))
            ),
        ];

        if (state.targetConversation && state.targetConversation.vendorExternalId) {
            conversationsViewService.getPatientAllMessagesStaff(state.targetConversation.vendorExternalId).subscribe(() => {
                employeeConversationsStore.resetAttachmentsView();  
            },
            () => {},
            () => { setState(state => ({ ...state,  isLoading: false }))}
            );
        }

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [state.targetConversation]);

    return [state, handleToggleSideArea, handleSelectConversation];
}
