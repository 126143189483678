import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { onEmit } from "../../../../common/helpers/on-emit";
import { IErrorState } from "../../../../common/validation/error-state";
import { PatientModel } from "../../../../patients/models/patient.model";
import { patientsQuery } from "../../../../patients/stores/patientsStore/patients.query";
import { authQuery } from "../../../../auth/stores/auth";

interface GeneralInfoWidgetComponentState extends IErrorState {
  isLoading: boolean;
  patient: PatientModel;
  open: boolean;
}

export function useFacade(): [
  GeneralInfoWidgetComponentState,
  () => void,
  () => void
] {
  const [state, setState] = useState({
    isLoading: true,
    patient: {},
    open: false
  } as GeneralInfoWidgetComponentState);

  const handleTooltipOpen = () => {
    setState(state => ({ ...state, open: true }));
  };

  const handleTooltipClose = () => {
    setState(state => ({ ...state, open: false }));
  };

  useEffect(() => {
    const subscriptions: Subscription[] = [
      onEmit<PatientModel>(patientsQuery.targetPatient$, targetPatient => {
        if (targetPatient?.id === Number(authQuery.getPatientId())) {
          setState(state => ({
            ...state,
            patient: targetPatient,
            isLoading: false,
          }));
        }
      }),
    ];

    return () => {
      subscriptions.map((it) => it.unsubscribe());
    };
  }, []);

  return [
    state,
    handleTooltipOpen,
    handleTooltipClose
  ];
}
