import { AppBar, ButtonGroup, Toolbar, useScrollTrigger, Box } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { MobileNavigationComponent } from '../mobileNavigationComponent/MobileNavigationComponent';
import { authQuery } from "../../modules/auth/stores/auth";
import { MenuItemTypes } from "../../models/menu.models";
import { useStyles } from "./authenticatedMobileHeaderComponent.styles";
import { logoService } from "../../services/logo.service";

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

interface AuthenticatedHeaderComponentProps {
  title: JSX.Element | string;
  selectedMenuItem: MenuItemTypes;
  topBannerHeight?: number;
}

export const AuthenticatedMobileHeaderComponent: React.FC<AuthenticatedHeaderComponentProps> = (props: AuthenticatedHeaderComponentProps) => {
  const isAuthorized = authQuery.isLoggedIn();
  const {
    selectedMenuItem,
    topBannerHeight = 0
  } = props;
  const classes = useStyles({ topBannerHeight });

  return (
    <ElevationScroll>
      <AppBar className={clsx(classes.root)}
      >
        <Toolbar>
          <Box className={classes.titleSection}>
            <img className={classes.logo} src={logoService.getLogo().primary} alt="img" />
          </Box>
          <Box>
            {
              isAuthorized &&
              <ButtonGroup
                size="small"
                variant='text'
              >

                <MobileNavigationComponent selectedMenuItem={selectedMenuItem} />

              </ButtonGroup>
            }
          </Box>
        </Toolbar>
      </AppBar>
    </ElevationScroll>
  )
}
