import { Box, Grid, useTheme, useMediaQuery } from "@material-ui/core";
import React from 'react';
import { ReactComponent as emptyRiskScores } from '@img/placeholders/EmptyRiskScores.svg';
import { ReactComponent as emptyLifestyle } from '@img/placeholders/EmptyLifestyle.svg';
import { ReactComponent as emptyLabs } from '@img/placeholders/EmptyLabs.svg';
import { ReactComponent as emptyBiomarkers } from '@img/placeholders/EmptyBiomarkers.svg';
import { ReactComponent as emptyHealthBiomarkers } from '@img/placeholders/EmptyHealthBiomarkers.svg';
import { ReactComponent as emptyMobileHealthScore } from '@img/placeholders/EmptyMobileHealthScore.svg';
import { ReactComponent as emptyMobileLabs } from '@img/placeholders/EmptyMobileLabs.svg';
import { ReactComponent as emptyMobileBiomarkers } from '@img/placeholders/EmptyMobileBiomarkers.svg';
import { ReactComponent as emptyMobileLifestyle } from '@img/placeholders/EmptyMobileLifestyle.svg';
import { ReactComponent as emptyMobileRiskScores } from '@img/placeholders/EmptyMobileRiskScores.svg';
import clsx from "clsx";
import {useFacade} from "./healthScoreComponent.hooks";
import {useStyles} from "./healthScoreComponent.style";
import commonUseStyles from "../../../common/styles/common.styles";
import {WildHealthButton} from "../../../common/components/wildHealthButton/WildHealthButton";
import {ProtectedElement} from "../../../common/components/ProtectedElement";
import {UserType} from "../../../auth/models/auth.enums";
import {BiomarkersComponent} from "../../../patients/components/BiomarkersComponent/BiomarkersComponent";
import {ColorsForHealth} from "../../models/score.model";
import {GeneralChartComponent} from "../../../patients/components/GeneralChartComponent/GeneralChartComponent";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";

interface HealthScoreComponentProps {
    patientId?: number | null
}

export const HealthScoreComponent: React.FC<HealthScoreComponentProps> = (props: HealthScoreComponentProps) => {
    const [
        {
            isLoading,
            labsScore,
            generalScore,
            filteredGeneralScore,
            isCompletedQuestionnaires,
            isResultsFromLabs,
            categories,
        },
        handleComplete
    ] = useFacade(props.patientId);
    const classes = useStyles();
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const emptyState = () => {
        return (
            <Box position='relative'>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box className={classes.emptySections} display='flex'>
                            {React.createElement(isSmallScreen ? emptyMobileHealthScore : emptyHealthBiomarkers)}
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box className={classes.emptySections} display='flex'>
                            {React.createElement(isSmallScreen ? emptyMobileLabs : emptyLabs)}
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                        <Box className={classes.emptySections} display='flex'>
                            {React.createElement(isSmallScreen ? emptyMobileBiomarkers : emptyBiomarkers)}
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                        <Box className={classes.emptySections} display='flex'>
                            {React.createElement(isSmallScreen ? emptyMobileLifestyle : emptyLifestyle)}
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                        <Box className={classes.emptySections} display='flex'>
                            {React.createElement(isSmallScreen ? emptyMobileRiskScores : emptyRiskScores)}
                        </Box>
                    </Grid>
                </Grid>
                {isSmallScreen ? (
                    <Box className={classes.emptyContainer}>
                        <Box className={clsx(classes.title, commonClasses.colorGray1)} textAlign="center">Your Health Score is<br /> in progress </Box>
                    </Box>
                ) : (
                    <Box className={classes.emptyContainer}>
                        <Box className={classes.title}>Your Health Score is in progress </Box>
                        <Box className={classes.subTitle}>{!isCompletedQuestionnaires
                            ? "Tell us more about your medical history & lifestyle"
                            : "We're awaiting the results from your labs. Once we receive those, we'll update your score"}
                        </Box>
                        {
                            !isCompletedQuestionnaires &&
                            <WildHealthButton id="health-score-complete" size='large' onClick={() => handleComplete()}>
                                Complete Questionnaire
                            </WildHealthButton>
                        }
                    </Box>
                )}
            </Box>
        )
    }

    const renderContent = () => {
        return (
            <>
                <ProtectedElement
                    key='default-3'
                    element={
                        <Box className={classes.banner}>
                            <Box id="patient-health-forms-next-up" className={classes.nextUp}>
                                <span>Beta</span>
                            </Box>
                            <Box className={clsx(classes.bannerMessage, commonClasses.size16, commonClasses.textRegular, commonClasses.colorBlack)}>
                                <Box>You have beta access to our new Health Score! If you have any concerns with this score, please reach out to your Care Team.</Box>
                            </Box>
                            <Box width={0.1} />
                        </Box>
                    }
                    permissions={[]}
                    userType={UserType.Patient}
                />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box className={classes.sections} display='flex'>
                            <BiomarkersComponent
                                title="Health Score"
                                result={generalScore}
                                colors={ColorsForHealth}
                                categories={categories}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box className={classes.sections} display='flex'>
                            {!!labsScore?.length && <GeneralChartComponent
                                title={labsScore[0].termCategory}
                                data={labsScore}
                                color={ColorsForHealth[0]}
                            />}
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    {filteredGeneralScore.map((el, index) => {
                        return (
                            <Grid key={index} item xs={12} sm={12} md={12} lg={4} xl={4}>
                                <Box className={classes.sections} display='flex'>
                                    {!!el.length
                                        ? <GeneralChartComponent
                                            title={el[0].termCategory}
                                            data={el.map(el => {
                                                return el.displayValue === 0 ? {
                                                    ...el,
                                                    displayValue: 1.5,
                                                } : el
                                            })}
                                            color={ColorsForHealth[index + 1]}
                                        />
                                        : <></>}
                                </Box>
                            </Grid>
                        )
                    })
                    }
                </Grid>
            </>
        )
    }


    return (
        <>
            {
                isLoading
                    ? <WildHealthLinearProgress />
                    : isResultsFromLabs && isCompletedQuestionnaires
                        ? renderContent()
                        : emptyState()
            }
        </>
    )
}

