import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { onEmit } from "../../../common/helpers/on-emit";
import { IErrorState } from "../../../common/validation/error-state";
import { ISortingState } from "../../../common/sorting/models/sorting-state";
import { SortingDirection } from "../../../common/sorting/models/sorting-destination";
import { handleCompare } from "../../../common/sorting/helpers/handle-compare";
import { pageSizes } from '../../../common/pagination/models/page-sizes';
import { IPaginationState } from '../../../common/pagination/models/pagination-state';
import { getAvailablePages } from "../../../common/pagination/helpers/get-evailable-pages";
import { dnaOrdersService } from "../../services/dnaOrdersService";
import { OrderStatus } from "../../models/orders.models";
import { DnaOrderModel } from "../../models/dnaOrders.models";
import { dnaOrdersQuery } from "../../stores/dnaOrdersStore";
import { updateDnaOrderValidator } from "./updateDnaOrder.validator";
import { navigationService } from "../../../../services/navigation.service";
import { confirmService } from "../../../../services/confirm.service";

export enum SortingSource {
    None,
    Name = 'name',
    Address = 'address',
    Id = 'id',
    Number = 'number',
    Barcode = 'barcode',
    PatientShippingNumber = 'patientShippingNumber',
    LaboratoryShippingNumber = 'laboratoryShippingNumber'
}

interface DNADnaOrdersState extends IErrorState, ISortingState, IPaginationState {
    isLoading: boolean;
    isUpdating: boolean;
    isOpened: boolean;
    anchorEl: HTMLElement;
    dnaOrdersView: DnaOrderModel[];
    dnaOrdersAll: DnaOrderModel[];
    selectedId: number;
    selectedOrder: DnaOrderModel;
    searchQuery: string;
    triggerView: boolean;
}

export function useFacade(): [
    DNADnaOrdersState,
    (source: string, direction: SortingDirection) => void,
    (value: string) => void,
    (page: number) => void,
    (value) => void,
    () => number[],
    (id: number, anchorEl?: HTMLElement) => void,
    () => void,
    () => void,
    (field: string, value: any) => void,
    () => void,
    () => boolean,
    (id: number) => void
] {
    const [state, setState] = useState({
        isLoading: true,
        isUpdating: false,
        isOpened: false,
        anchorEl: null,
        dnaOrdersView: [],
        dnaOrdersAll: [],
        selectedId: 0,
        selectedOrder: null,
        sortingColumns: [
            {title: 'Name', source: SortingSource.Name, direction: SortingDirection.Asc},
            {title: 'Address', source: SortingSource.Address, direction: SortingDirection.Asc},
            {title: 'First Class?', source: SortingSource.Id, direction: SortingDirection.Asc},
            {title: 'Order Id', source: SortingSource.Id, direction: SortingDirection.Asc},
            {title: 'Provider Order #', source: SortingSource.Number, direction: SortingDirection.Asc},
            {title: 'Barcode', source: SortingSource.Barcode, direction: SortingDirection.Asc},
            {title: 'Outbound Shipping #', source: SortingSource.PatientShippingNumber, direction: SortingDirection.Asc},
            {title: 'Return Shipping #', source: SortingSource.LaboratoryShippingNumber, direction: SortingDirection.Asc},
        ],
        sortingSource: SortingSource.None,
        totalCount: 0,
        selectedPage: 1,
        pageSize: pageSizes[0],
        searchQuery: '',
        triggerView: false,
        errors: {}
    } as DNADnaOrdersState);

    const handleSorting = (source: string, direction: SortingDirection) => {
        const sortingColumns = [...state.sortingColumns];

        if (state.sortingSource === source) {
            const newDirection = direction === SortingDirection.Asc
                ? SortingDirection.Desc
                : SortingDirection.Asc;

            const itemIndex = sortingColumns.findIndex(item => item.source === source);
            sortingColumns[itemIndex].direction = newDirection;
        } 

        setState(state => ({
            ...state,
            sortingSource: source,
            columns: sortingColumns,
            triggerView: true
        }));
    }

    const handleChangePage = (page: number) => {
        setState(state => ({
            ...state,
            selectedPage: page,
            triggerView: true
        }))
    }

    const handlePageSizeChange = (value) => {
        if (value === state.pageSize) {
            return;
        }

        setState(state => ({
            ...state,
            pageSize: value,
            selectedPage: 1,
            triggerView: true
        }))
    }

    const getAllAvailablePages = (): number[] => {
        return getAvailablePages(state);
    }

    const handleSearch = (value: string) => {
        setState({
            ...state,
            selectedPage: 1,
            searchQuery: value,
            triggerView: true
        });
    }

    const getDnaOrdersView = (dnaOrders: DnaOrderModel[]): [number, DnaOrderModel[]] => {
        
        const searchedOrders = state.searchQuery 
            ? dnaOrders.filter(x => x.number?.includes(state.searchQuery) ||
                x.barcode?.includes(state.searchQuery) ||
                x.patientShippingNumber?.includes(state.searchQuery) ||
                x.laboratoryShippingNumber?.includes(state.searchQuery))
            : dnaOrders;

        const totalLenghth = searchedOrders.length;

        const from = (state.selectedPage - 1) * state.pageSize;
        const to = state.selectedPage * state.pageSize;

        const direction = state.sortingColumns.find(x => x.source === state.sortingSource)?.direction;

        const dnaOrdersView = searchedOrders
            .sort((a1, a2) => handleCompare(a1, a2, direction, state.sortingSource))
            .slice(from, to);

        return [totalLenghth, dnaOrdersView];
    }

    const handleToggleActions = (id: number, anchorEl?: HTMLElement) => {
        setState(state => ({
            ...state,
            anchorEl,
            selectedId: id,
            errors: {}
        }))
    }

    const handleToggleEditOrder = () => {
        const targetOrder = state.dnaOrdersAll.find(i => i.id === state.selectedId);
        if (targetOrder) {
            setState(state => ({ ...state, anchorEl: null, selectedOrder: targetOrder, isOpened: !state.isOpened }));
        }
    }

    const handleDelete = () => {
        handleToggleActions(state.selectedId);
        confirmService.confirm(
            'Remove Order',
            'Are you sure you wish to remove this order? This action cannot be undone.',
            'Remove',
            'Cancel',
            'danger')
            .subscribe(() =>  {
                setState((state) => ({
                    ...state,
                    isUpdating: true
                }));
        
                const cb = () => {
                    setState((state) => ({
                        ...state,
                        selectedId: 0,
                        selectedOrder: null,
                        isUpdating: false,
                        triggerView: true
                    }));
                }
                dnaOrdersService.deleteDnaOrderShipping(state.selectedId).subscribe(cb, cb)
            });
    }

    const handleChanges = (field: string, value: any) => {
        updateDnaOrderValidator.validateAndSetState(state, setState, field, value);

        const selectedOrder = state.selectedOrder;

        selectedOrder[field] = value;

        setState({ ...state, selectedOrder });
    }

    const handleUpdateOrder = () => {
        updateDnaOrderValidator.validateObjectAndSetState(state, setState, state.selectedOrder);
        if (!updateDnaOrderValidator.stateIsValid(state)) {
            return;
        }

        setState((state) => ({
            ...state,
            isUpdating: true
        }));

        const cb = () => {
            setState((state) => ({
                ...state,
                errors: {},
                selectedOrder: null,
                isOpened: false,
                isUpdating: false,
                triggerView: true
            }));
        }

        const updateModel = {
            id: state.selectedOrder.id,
            number: state.selectedOrder.number,
            barcode: state.selectedOrder.barcode,
            patientShippingNumber: state.selectedOrder.patientShippingNumber,
            laboratoryShippingNumber: state.selectedOrder.laboratoryShippingNumber,
        }
        dnaOrdersService.updateDnaOrderShipping(updateModel).subscribe(cb, cb);
    }

    const isCheckedShippingDetail = () => {
        return Boolean(state.selectedOrder?.number && state.selectedOrder?.barcode && state.selectedOrder?.patientShippingNumber && state.selectedOrder?.laboratoryShippingNumber);
    }

    const handlePatientSelect = (patientId: number) => {
        navigationService.toNewTabManagePatientProfile(patientId);
    }    

    useEffect(() => {
        if (!state.triggerView) {
            return
        }

        const [totalCount, viewOrders] = getDnaOrdersView(state.dnaOrdersAll);

        setState(state => ({
            ...state,
            totalCount: totalCount,
            dnaOrdersView: viewOrders,
            triggerView: false
        }))

    }, [state.triggerView])

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<DnaOrderModel[]>(dnaOrdersQuery.orders$, orders => {
                setState(state => (
                    {
                        ...state,
                        dnaOrdersAll: orders
                    }))
            }),
        ];

        const cb = () => setState(state => ({ ...state, isLoading: false, triggerView: true }));

        dnaOrdersService.getByOrderStatus(OrderStatus.Ordered).subscribe(cb, cb)

        return (() => {
            subscriptions.map(it => it.unsubscribe())
        })
    }

    useEffect(useEffectCB, []);

    return [
        state,
        handleSorting,
        handleSearch,
        handleChangePage,
        handlePageSizeChange,
        getAllAvailablePages,
        handleToggleActions,
        handleDelete,
        handleToggleEditOrder,
        handleChanges,
        handleUpdateOrder,
        isCheckedShippingDetail,
        handlePatientSelect
    ];
}