import {PaymentState, PaymentStore, paymentStore} from './payment.store';
import {Query} from '@datorama/akita';

/**
 * Provides payment queries
 */
export class PaymentQuery extends Query<PaymentState> {

    credentials$ = this.select(state => state.credentials);

    constructor(protected store: PaymentStore) {
        super(store);
    }
    public getSecretkey(): string {
        return this.getValue().credentials.privateKey;
    }
}

export const paymentQuery = new PaymentQuery(paymentStore);
