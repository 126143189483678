import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { colors } from "../../constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
            [theme.breakpoints.down("xs")]: {
                '& .MuiDialog-paper': {
                    borderRadius: '10px 10px 0 0',
                    margin: 0,
                    marginTop: 'auto'
                },
            },
        },
        dialogCloseButton: {
            position: 'absolute',
            top: 6,
            right: 6,
        },
        content: {
            display: "flex",
            flexDirection: "column",
            alignItems: 'center',
            maxWidth: 550,
            paddingRight: 0,
            paddingLeft: 0,
            paddingTop: '0 !important',
        },
        imageSection: {
            padding: '40px 108px',
            background: 'linear-gradient(180deg, rgba(91, 174, 212, 0.12) 0%, rgba(91, 174, 212, 0) 93.9%)',
            [theme.breakpoints.down("xs")]: {
                padding: 40
            },
        },
        action: {
            display: 'flex',
            justifyContent: 'flex-end',
            color: colors.main,
            paddingRight: theme.spacing(5),
            paddingLeft: theme.spacing(5),
            paddingBottom: theme.spacing(4),
            paddingTop: 30,
            [theme.breakpoints.down("xs")]: {
                paddingTop: 70,
                paddingRight: theme.spacing(3),
                paddingLeft: theme.spacing(3),
            },
        },
    }),
);
