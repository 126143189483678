import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from './patientInfoTooltipComponent.styles';
import { useFacade } from "./patientInfoTooltipComponent.hooks";
import { displayDate, displayShortAge } from '../../../common/helpers/display-date';
import { Gender, } from "../../../common/models/user.models";
import { fullName } from '../../../common/helpers/display-name';
import { EmployeeShortModel } from '../../models/employee.models';
import { EmployeeType } from '../../models/employee.enums';
import { PatientMedicationModel } from '../../../patientSupplements/models/patientMedicationModel';
import { PatientSupplementModel } from '../../../patientSupplements/models/patientSupplementModel';
import { HealthSummaryDataModel } from '../../../healthSummary/models/healthSummary.models';

interface PatientInfoTooltipComponentProps {
    patientId: number;
    isShow?: boolean;
}

export const PatientInfoTooltipComponent: React.FC<PatientInfoTooltipComponentProps> = (props: PatientInfoTooltipComponentProps) => {
    const { patientId, isShow = false } = props;

    const [
        {
            isLoading,
            patient,
            vital,
            problemList,
            medications
        },
    ] = useFacade(patientId);
    const classes = useStyles({ isShow });

    const displayNamesList = (items: PatientSupplementModel[] | PatientMedicationModel[]) => {
        if (!items || !items.length) {
            return '-'
        }
        let res = '';
        const viewItems = items && items.length > 5 ? items.slice(0, 5) : items
        viewItems.forEach((el, i) => (i === viewItems.length - 1) ? res += `${el.name}` : res += `${el.name}, `)

        return res;
    }

    const displayProblemName = (items: HealthSummaryDataModel[]) => {
        if (!items || !items.length) {
            return '-'
        }
        let res = '';
        const viewItems = items.length > 5 ? items.slice(0, 5) : items
        viewItems.forEach((el, i) => (i === viewItems.length - 1) ? res += `${el.name}` : res += `${el.name}, `)

        return res;
    }

    const displayAssignedEmployees = (assignedEmployees: EmployeeShortModel[]) => {
        if (assignedEmployees.length) {
            return assignedEmployees.map((e) => fullName(e)).join(", ");
        }
        return '-';
    }

    const displayPatientBirthday = () => {
        return <>{displayShortAge(patient.birthday)}<span className={classes.patientSubtext}>{` (${displayDate(patient.birthday)})`}</span></>
    }

    const renderContent = () => {
        if (!patient) {
            return <></>
        }
        return (
            <>
                <Box display='flex' flexDirection='column' mr={2}>
                    <Box mt={1} display="flex">
                        <Box className={classes.infoTitle}>Age: </Box>
                        <Box className={classes.patientInfo}> {patient.birthday ? displayPatientBirthday() : '-'} </Box>
                    </Box>
                    <Box mt={1} display="flex">
                        <Box className={classes.infoTitle}>Sex: </Box>
                        <Box className={classes.patientInfo}>{patient.gender ? Gender[patient.gender] : '-'}</Box>
                    </Box>
                    <Box mt={1} display="flex" >
                        <Box className={classes.infoTitle}>BMI: </Box>
                        <Box className={classes.patientInfo}>{isLoading ? <CircularProgress size={14} className="wh-tw-text-primaryV" /> : vital.find(x => x.key === 'VITAL_VALUE_BMI')?.value ?? '-'}</Box>
                    </Box>
                    <Box mt={1} display="flex" >
                        <Box className={classes.infoTitle}>Height: </Box>
                        <Box className={classes.patientInfo}>{isLoading ? <CircularProgress size={14} className="wh-tw-text-primaryV" /> : vital.find(x => x.key === 'VITAL_VALUE_HEIGHT')?.value ?? '-'}</Box>
                    </Box>
                    <Box mt={1} display="flex" >
                        <Box className={classes.infoTitle}>Weight: </Box>
                        <Box className={classes.patientInfo}>{isLoading ? <CircularProgress size={14} className="wh-tw-text-primaryV" /> : vital.find(x => x.key === 'VITAL_VALUE_WEIGHT')?.value ?? '-'}</Box>
                    </Box>
                    <Box mt={1} display="flex" >
                        <Box className={classes.infoTitle}>Plan: </Box>
                        <Box className={classes.patientInfo}>{patient.subscription?.displayName ? patient.subscription.displayName : '-'}</Box>
                    </Box>
                </Box>
                <Box display='flex' flexDirection='column'>
                    <Box mt={1} display="flex" >
                        <Box className={classes.infoTitleSecondaty}>State: </Box>
                        <Box className={classes.patientInfo}>{patient.shippingAddress?.state ? patient.shippingAddress.state : '-'}</Box>
                    </Box>
                    <Box mt={1} display="flex" >
                        <Box className={classes.infoTitleSecondaty}>Supplements: </Box>
                        <Box className={classes.patientInfo} >{displayNamesList(patient.supplements)}</Box>
                    </Box>
                    <Box mt={1} display="flex" >
                        <Box className={classes.infoTitleSecondaty}>Medications: </Box>
                        <Box className={classes.patientInfo}>{displayProblemName(medications)}</Box>
                    </Box>
                    <Box mt={1} display="flex" >
                        <Box className={classes.infoTitleSecondaty}>Problem list: </Box>
                        <Box className={classes.patientInfo}>{isLoading ? <CircularProgress size={14} className="wh-tw-text-primaryV" /> : displayProblemName(problemList)}</Box>
                    </Box>
                    <Box mt={1} display="flex" >
                        <Box className={classes.infoTitleSecondaty}>HC: </Box>
                        <Box className={classes.patientInfo}>{displayAssignedEmployees(patient.assignedEmployees.filter(c => c.type === EmployeeType.HealthCoach))}</Box>
                    </Box>
                    <Box mt={1} display="flex" >
                        <Box className={classes.infoTitleSecondaty}>MD: </Box>
                        <Box className={classes.patientInfo}>{displayAssignedEmployees(patient.assignedEmployees.filter(c => c.type === EmployeeType.Provider))}</Box>
                    </Box>
                </Box>
            </>
        )
    }
    
    return (
        <Box className={clsx(classes.tooltip, 'tooltip-element')} >
            {renderContent()}
        </Box>
    );
}