import {Query} from '@datorama/akita';
import {HealthReportsState, healthReportsStore, HealthReportsStore} from "./healthReports.store";

/**
 * Provides Health Reports queries
 */
export class HealthReportsQuery extends Query<HealthReportsState> {
    report$ = this.select(state => state.report);
    reportForRecommendation$ = this.select(state => state.reportForRecommendation);
    reports$ = this.select(state => state.reports);
    reportsUnderReview$ = this.select(state => state.reportsUnderReview);
    reportsTotalCount$ = this.select(state => state.reportsTotalCount);
    notificationAwaitingApproval$ =  this.select(state => state.notificationAwaitingApproval);
    recommendationsDefault$ =  this.select(state => state.recommendationsDefault);
    
    recommendations$ = this.select(state => state.recommendations);
    metrics$ = this.select(state => state.metrics);
    patientReport$ = this.select(state => state.patientReport);
    addOnReports$ = this.select(state => state.addOnReports);

    constructor(protected store: HealthReportsStore) {
        super(store);
    }
}

export const healthReportsQuery = new HealthReportsQuery(healthReportsStore);
