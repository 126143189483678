import {ConversationModelBase, ConversationModelBaseView} from "../../models/conversation.models";

import {ConversationPdfDocument} from "./conversations/ConversationPdfDocument";
import {PatientAppointmentModel} from "../../../appointments/models/appointments.models";
import {AppointmentsPdfDocument} from "./appointments/AppointmentsPdfDocument";
import {PatientModel} from "../../../patients/models/patient.model";
import {
    StaffOrHealthCoachMessagesEnum
} from "../../components/patientConversationsViewComponent/patientConversationsViewComponent.hooks";

class PdfServices {

    public downloadPdfFor(conversation: ConversationModelBase,messages:ConversationModelBaseView[], conversationType: StaffOrHealthCoachMessagesEnum){

        return ConversationPdfDocument(conversation,messages, conversationType);

    }

    public downloadAppoitnmentsPdfFor(appoitments: PatientAppointmentModel[], patientName: string, patientId: number){

        return AppointmentsPdfDocument(appoitments, patientName, patientId);

    }

    public removeSpecialChars = (text) => text.replace(/[^a-zA-Z0-9-!?. ]/g, '');

}

export default new PdfServices();