import {useEffect, useState} from "react";
import {notesService} from "../../services/notes.service";
import {employeeService} from "../../../employee/services/employees.service";
import {confirmService} from "../../../../services/confirm.service";
import {authQuery} from "../../../auth/stores/auth";
import {UserType} from "../../../auth/models/auth.enums";
import {insertString} from "../../../common/helpers/insert-string";
import {selectShortcutService} from "../../../healthReport/services/selectShortcut.service";
import { NotesType } from "../../models/notes.models";
import { isProviderRole } from "../../../common/constants/roles";

export enum SignOffEmployeeType {
    Assigned = 0,
    Other = 1
}

export interface SignOffEmployeeViewModel {
    id: number,
    firstName: string,
    lastName: string
}

interface SignOffDialogComponentState {
    isOpen: boolean;
    patientId: number | null;
    callback: Function | null;
    selectedEmployeeType: SignOffEmployeeType;
    assignedEmployees: SignOffEmployeeViewModel[];
    otherEmployees: SignOffEmployeeViewModel[];
    selectedEmployeeId: number | null;
    note: string;
    noteType: NotesType;
}

let ignoreChangesEvent = false;

export function useFacade(): [
    SignOffDialogComponentState,
    (type: SignOffEmployeeType) => void,
    (employeeId: number) => void,
    (note: string) => void,
    () => void,
    () => void,
    (event: any) => void
] {
    const [state, setState] = useState({
        isOpen: false,
        patientId: null,
        selectedEmployeeType: SignOffEmployeeType.Assigned,
        assignedEmployees: [],
        otherEmployees: [],
        selectedEmployeeId: null,
        note: ''
    } as SignOffDialogComponentState);

    const close = () => {
        setState(state => ({
            ...state,
            isOpen: false,
            patientId: null,
            selectedEmployeeType: SignOffEmployeeType.Assigned,
            assignedEmployees: [],
            otherEmployees: [],
            selectedEmployee: null,
            note: ''
        }));
    }

    const handleClose = () => {
        confirmService.discard('Cancel Request', 'Are you sure you want to cancel the approval request?').subscribe(() => {
            close();
        })
    }

    const handleSelectedEmployeeType = (type: SignOffEmployeeType) => {
        setState(state => ({
            ...state,
            selectedEmployeeType: type,
            selectedEmployeeId: null
        }));
    }

    const handleEmployeeSelect = (employeeId: number) => {
        setState(state => ({
            ...state,
            selectedEmployeeId: employeeId
        }));
    }

    const handleNotesChanges = (note: string) => {
        if (ignoreChangesEvent) {
            return;
        }

        setState(state => ({
            ...state,
            note: note
        }));
    }

    const handleSubmit = () => {
        confirmService.confirm('Request Confirmation', 'Are you sure you want to send the approval request?', 'Send Request', 'Discard').subscribe(() => {
            state.callback([state.selectedEmployeeId, state.note]);
            close();
        });
    }

    const handleKeyDown = (event: any) => {
        ignoreChangesEvent = false;

        if (event.keyCode === 51 && event.altKey) {
            const userType = authQuery.getType();
            if (UserType.Employee === userType) {
                ignoreChangesEvent = true;

                setTimeout(() => {
                    selectShortcutService.select().subscribe((data) => {
                        const cursorPos = (document.getElementById('sign-off-additional-note') as any).selectionStart;

                        if (data) {
                            setState(state => ({
                                ...state,
                                note: insertString(state.note, data, cursorPos + 1)
                            }));
                        }
                    });
                }, 1)
            }
        }
    }

    useEffect(() => {
        const subscription = notesService.onSignOff.subscribe((model) => {
            const patientId = model.data.patientId;
            const noteType = model.data.noteType;

            const providerOnlyNoteTypes = [
                NotesType.HistoryAndPhysicalInitial,
                NotesType.HistoryAndPhysicalFollowUp
            ];

            const isProviderOnly = providerOnlyNoteTypes.some(x => x === noteType);

            setState(state => ({
                ...state,
                isOpen: true,
                patientId: patientId,
                callback: model.callback
            }));

            employeeService.getAssignedToById(patientId).subscribe(employees => {
                setState(state => ({
                    ...state,
                    assignedEmployees: employees.filter(x => x.id !== authQuery.getEmployeeId() && (isProviderOnly? isProviderRole(x.role.id): true))
                }));
            })

            employeeService.getActiveCoachesAndProviders().subscribe(employees => {
                setState(state => ({
                    ...state,
                    otherEmployees: employees.filter(x => x.id !== authQuery.getEmployeeId() && (isProviderOnly? isProviderRole(x.roleId): true))
                }))
            })
        });

        return () => subscription.unsubscribe();
    }, [])

    return [
        state,
        handleSelectedEmployeeType,
        handleEmployeeSelect,
        handleNotesChanges,
        handleClose,
        handleSubmit,
        handleKeyDown
    ];
}