import { useState } from "react";

interface FullHealthCareChatComponentState {
    isSideAreaOpen: boolean;
}

export function useFacade(): [FullHealthCareChatComponentState, (open: boolean) => void] {
    const [state, setState] = useState({

        isSideAreaOpen: false,
    } as FullHealthCareChatComponentState);

    const handleToggleSideArea = (open: boolean) => {
        setState({ ...state, isSideAreaOpen: open });
    }

    return [state, handleToggleSideArea];
}
