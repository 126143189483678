import { Box, Button, ButtonGroup, CircularProgress, Divider, FormControl, Grid, MenuItem, Select, Tooltip, useMediaQuery, useTheme } from '@material-ui/core';
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import GetAppIcon from '@material-ui/icons/GetApp';
import moment from "moment";
import React from 'react';
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { WildHealthExternalLinkButton } from '../../../common/components/WildHealthLinkButton';
import { WildHealthPlaceHolder } from '../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder';
import { NoteGoalsVersion, NoteModel } from "../../models/notes.models";
import { NoteSignatureComponent } from "../noteSignature/NoteSignatureComponent";
import { useFacade } from './followUpComponent.hooks';
import { useStyles } from './followUpComponent.styles';
import clsx from 'clsx';
import commonUseStyles from '../../../common/styles/common.styles';
import { TextMarkdownComponent } from '../textMarkdownComponent/TextMarkdownComponent';
import {
    GenerateAmendedChangesForFollowUp,
    renderEducation,
    renderGoals,
} from "../../helpers/noteHelpers";
import EditIcon from '@material-ui/icons/Edit';
import { confirmService } from '../../../../services/confirm.service';
import { WildHealthStatusLabel } from '../../../common/components/wildHealthStatusLabel/WildHealthStatusLabel';
import InfoIcon from '@material-ui/icons/InfoRounded';
import {SupplementSource} from "../../../patientSupplements/models/patientSupplementModel";

interface FollowUpComponentProps {
    note: NoteModel;
    patientId: number;
    handleGoToNotes: () => void;
    handleGoToEditNote: (note: NoteModel) => void;
}

export const FollowUpComponent: React.FC<FollowUpComponentProps> = (props: FollowUpComponentProps) => {
    const {
        note,
        patientId,
        handleGoToNotes,
        handleGoToEditNote
    } = props;

    const [
        {
            content,
            internalContent,
            isPdfDownloading,
            noteId,
            originalContent,
            originalInternalContent
        },
        handleDownloadPdf,
        handleChooseNote
    ] = useFacade(patientId, note);

    const classes = useStyles();
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const isLoading = !content || !note;

    if (isLoading) {
        return (<WildHealthLinearProgress />)
    }

    const renderTitle = () => {
        return <span>{note.name} - {moment(note.visitDate).format("ll")}</span>
    }

    const handleEditNote = () => {
        confirmService.confirm('Are you sure?', 'Are you sure you wish to amend this note? All changes will be logged.')
            .subscribe(() => {
                handleGoToEditNote(note);
            });
    }

    const displayEditButton = () => {

        return (
            <Button id="edit_current_note" onClick={() => handleEditNote()}>
                <Box display="flex" alignItems="center" mt={1}>
                    <Box>
                        <EditIcon />
                    </Box>
                </Box>
            </Button>
        );
    }

    const displaySelectVersion = () => {
        if (note.amendedNotes?.length === 0 || note.amendedNotes == null || note.amendedNotes.every(x => x.completedAt === null)) {
            return
        }

        return (
            <Box display="flex" alignItems="center" justifyContent="flex-start" ml={2}>
                <Box pr={2}>
                    <WildHealthStatusLabel color='normal'>Amended Note</WildHealthStatusLabel>
                </Box>
                <Box width="120px">
                    <FormControl color='primary' variant="outlined" size="small" fullWidth>
                        <Select
                            style={{ background: '#FFF' }}
                            id="choseNoteVersion"
                            value={noteId}
                            onChange={(v) => handleChooseNote(v.target.value)}>
                            <MenuItem key={0} value={note.id}>Original</MenuItem>
                            {
                                note.amendedNotes.filter(x => x.completedAt != null).map((note, i) => {
                                    return <MenuItem key={i + 1} value={note.id}>Amended - {moment(note.completedAt).format("MM/DD/YYYY")}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Box>
        );
    }

    const displayDownloadPdfButton = () => {
        if (isPdfDownloading === true) {
            return (
                <Button>
                    <Box width="70px" display="flex" justifyContent="center">
                        <CircularProgress size={24} className={classes.colorMain} />
                    </Box>
                </Button>
            );
        }

        return (
            <Button id="follow-up-download-pdf" onClick={() => handleDownloadPdf(note)}>
                <Box display="flex" alignItems="center" mt={1}>
                    <Box>
                        <GetAppIcon />
                    </Box>
                    <Box mt={-1}>
                        PDF
                    </Box>
                </Box>
            </Button>
        );
    }

    const renderInternalNote = () => {
        return (
            <Box>
                <Box my={2} className={classes.title}>
                    <span className='uppercase'>internal notes</span>
                </Box>
                <Box my={2}>
                    {
                        internalContent
                            ? <TextMarkdownComponent content={internalContent} classLink={classes.renderText} />
                            : <WildHealthPlaceHolder message="No internal notes." />
                    }
                </Box>
            </Box>
        )
    }


    const renderNextAppointmentDates = () => {
        return (
            note.nextCoachAppointmentDate
                ?
                <Box>
                    <Box display='flex' alignItems='center'>
                        <span className={commonClasses.secondaryTitle}>
                            Next Appointment
                        </span>
                        <Box className={commonClasses.colorGray2} ml={1}>
                            <Tooltip placement="top" arrow
                                title='You will get notified several days before these dates'>
                                <InfoIcon color='inherit' />
                            </Tooltip>
                        </Box>

                    </Box>
                    <Box py={2}>
                        <Divider />
                    </Box>
                    <Box display="flex">
                        <Box pr={5}>
                            {
                                note.nextCoachAppointmentDate &&
                                <Box>
                                    <span className={commonClasses.subtitle}>
                                        Coach Visit:
                                    </span>
                                </Box>
                            }
                        </Box>
                        <Box>
                            {
                                note.nextCoachAppointmentDate &&
                                <Box>
                                    <span>
                                        {
                                            moment(note.nextCoachAppointmentDate).endOf('day').fromNow()
                                        }
                                    </span>
                                </Box>
                            }
                        </Box>
                    </Box>
                </Box>
                : <></>
        )
    }

    const renderNewGoals = () => {
        return <Box mt={isSmallScreen ? "20px" : 4}>
            {
                content.plan?.dietGoals?.length > 0 &&
                <Box>
                    {
                        renderGoals('Diet', content.plan.dietGoals, commonClasses, isSmallScreen)
                    }
                </Box>
            }
            {
                content.plan.dietEducations?.length > 0 &&
                <Box>
                    {
                        renderEducation(content.plan.dietEducations, commonClasses)
                    }
                </Box>
            }
            {
                content.plan?.exerciseGoals?.length > 0 &&
                <Box mt={isSmallScreen ? "20px" : 4}>
                    {
                        renderGoals('Exercise/Recovery', content.plan.exerciseGoals, commonClasses, isSmallScreen)
                    }
                </Box>
            }
            {
                content.plan.exerciseEducations?.length > 0 &&
                <Box>
                    {
                        renderEducation(content.plan.exerciseEducations, commonClasses)
                    }
                </Box>
            }
            {
                content.plan?.sleepGoals?.length > 0 &&
                <Box mt={isSmallScreen ? "20px" : 4}>
                    {
                        renderGoals('Sleep', content.plan.sleepGoals, commonClasses, isSmallScreen)
                    }
                </Box>
            }
            {
                content.plan.sleepEducations?.length > 0 &&
                <Box>
                    {
                        renderEducation(content.plan.sleepEducations, commonClasses)
                    }
                </Box>
            }
            {
                content.plan?.neuroGoals?.length > 0 &&
                <Box mt={isSmallScreen ? "20px" : 4}>
                    {
                        renderGoals('Neuro', content.plan.neuroGoals, commonClasses, isSmallScreen)
                    }
                </Box>
            }
            {
                content.plan.neuroEducations?.length > 0 &&
                <Box>
                    {
                        renderEducation(content.plan.neuroEducations, commonClasses)
                    }
                </Box>
            }
            {
                content.plan?.longevityGoals?.length > 0 &&
                <Box mt={isSmallScreen ? "20px" : 4}>
                    {
                        renderGoals('Longevity', content.plan.longevityGoals, commonClasses, isSmallScreen)
                    }
                </Box>
            }
            {
                content.plan.longevityEducations?.length > 0 &&
                <Box>
                    {
                        renderEducation(content.plan.longevityEducations, commonClasses)
                    }
                </Box>
            }
            {
                content.plan.mindfulnessGoals?.length > 0 &&
                <Box mt={isSmallScreen ? "20px" : 4}>
                    {
                        renderGoals('Mindfulness', content.plan.mindfulnessGoals, commonClasses, isSmallScreen)
                    }
                </Box>
            }
            {
                content.plan.mindfulnessEducations?.length > 0 &&
                <Box>
                    {
                        renderEducation(content.plan.mindfulnessEducations, commonClasses)
                    }
                </Box>
            }
        </Box>
    }

    const renderNoGoalsPlaceHolder = () => {
        return (
            <Box>
                <Box>
                    <span className={isSmallScreen ? clsx(commonClasses.size16, commonClasses.textMedium, commonClasses.colorBlack) : clsx(commonClasses.secondaryTitle, commonClasses.uppercase)}>
                        Goals
                    </span>
                </Box>
                {isSmallScreen && <Box py={2}>
                    <Divider className={commonClasses.renderDivider} />
                </Box>}
                <Box>
                    <Box my={2}>
                        <WildHealthPlaceHolder message="No goals." />
                    </Box>
                </Box>
            </Box>
        )
    }

    const renderOldGoals = () => {
        return <Box>
            {
                content.goals && content.goals.map((goal, goalIndex) =>
                    <Box pb={5} key={goal.id}>
                        <Box my={2} className={classes.subTitle}>
                            <span className='uppercase'>Goal {goalIndex + 1}</span>
                        </Box>

                        <Box my={2}>
                            <TextMarkdownComponent content={goal.name} classLink={classes.renderText} />
                        </Box>

                        <Box>
                            <Grid container spacing={3}>
                                {
                                    goal.interventions.map((intervention, interventionIndex) =>
                                        <Grid key={interventionIndex} item md={4} sm={12}>
                                            <Box pb={3}>
                                                <Box py={2} className={classes.sectionTitle}>
                                                    <span>Intervention {interventionIndex + 1}</span>
                                                </Box>
                                                <Box>
                                                    <TextMarkdownComponent content={intervention.name} classLink={classes.renderText} />
                                                </Box>
                                            </Box>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Box>
                    </Box>
                )
            }
        </Box>
    }

    const renderGeneralGoals = () => {

        if (Boolean(!content?.goalsVersion) && Boolean(!content?.goals.length)) {
            return renderNoGoalsPlaceHolder();
        }

        if (content?.goalsVersion === NoteGoalsVersion.New && content?.goals.length) {
            return renderOldGoals()
        }

        if (content?.goalsVersion === NoteGoalsVersion.New
            && content.plan.dietGoals.length === 0
            && content.plan.exerciseGoals.length === 0
            && content.plan.sleepGoals.length === 0
            && content.plan.neuroGoals.length === 0
            && content.plan.longevityGoals.length === 0
            && content.plan.mindfulnessGoals.length === 0) {
            return renderNoGoalsPlaceHolder();
        }

        return (
            <Box>
                <Box className={classes.title}>
                    <span className='uppercase'>Goals</span>
                </Box>
                {content?.goalsVersion === NoteGoalsVersion.New
                    ? renderNewGoals()
                    : renderOldGoals()}
            </Box>
        )
    }

    const renderSupplements = () => {
        if (content.medicationsSupplements.fullscriptSupplements?.length <= 0 && content.medicationsSupplements.medications.filter(x => !x.isInCurrent).length <= 0 && content.medicationsSupplements.supplements.filter(x => !x.isInCurrent).length <= 0) {
            return (
                <Box>
                    <Box my={2} className={classes.title}>
                        <span className='uppercase'>Supplements/Medications added or changed</span>
                    </Box>
                    <Box my={2}>
                        <WildHealthPlaceHolder message="No changes were made during this visit." />
                    </Box>
                </Box>
            )
        }

        let globalIndex = 0;
        return (
            <Box>
                <Box my={2} className={classes.title}>
                    <span className='uppercase'>Supplements/Medications added or changed</span>
                </Box>
                <Box my={2}>
                    {
                        content.medicationsSupplements.medications.filter(i => !i.isInCurrent).map((item, index) => {
                            return (
                                <Box key={index} my={1} display="flex">
                                    <Box width={0.25}>
                                        <span className={clsx(classes.regular, classes.textWrap)}>{`${++globalIndex}. ${item.name}`}</span>
                                    </Box>
                                    <Box width={0.25}>
                                        <span className={clsx(classes.regular, classes.textWrap)}>{item.dosage}</span>
                                    </Box>
                                </Box>
                            )
                        })
                    }
                    {
                        content.medicationsSupplements.supplements.filter(i => !i.isInCurrent).map((item, index) => {
                            return (
                                <Box key={index} my={1} display="flex">
                                    <Box width={0.25}>
                                        <span className={clsx(classes.regular, classes.textWrap)}>{`${++globalIndex}. ${item.name}`}</span>
                                    </Box>
                                    <Box width={0.25}>
                                        <span className={clsx(classes.regular, classes.textWrap)}>{item.dosage}</span>
                                    </Box>
                                    <Box width={0.25}>
                                        <WildHealthExternalLinkButton id={`follow-up-link-no-stopped-supplements${item.id}`} link={item.purchaseLink} children={
                                            <span>
                                                {
                                                    item.source === SupplementSource.Fullscript
                                                        ? 'Purchase via Fullscript'
                                                        : 'Purchase here'
                                                }
                                            </span>
                                        } />
                                    </Box>
                                </Box>
                            )
                        })
                    }
                    {
                        content.medicationsSupplements.fullscriptSupplements?.map((item, index) => {
                            return (
                                <Box key={index} my={1} display="flex">
                                    <Box width={0.25}>
                                        <span className={clsx(classes.regular, classes.textWrap)}>{`${++globalIndex}. ${item.name}`}</span>
                                    </Box>
                                    <Box width={0.25}>
                                        <span className={clsx(classes.regular, classes.textWrap)}>{item.dosage}</span>
                                    </Box>
                                    <Box width={0.25}>
                                        <WildHealthExternalLinkButton id={`follow-up-link-no-stopped-supplements${item.id}`} link={item.purchaseLink} children={
                                            <span>
                                                Purchase via Fullscript
                                            </span>
                                        } />
                                    </Box>
                                </Box>
                            )
                        })
                    }
                </Box>
            </Box>
        )
    }

    const renderStoppedSupplements = () => {
        if (!content.medicationsSupplements.medications.filter(i => i.isStopped).length && !content.medicationsSupplements.supplements.filter(i => i.isStopped).length) {
            return (
                <Box>
                    <Box my={2} className={classes.title}>
                        <span className='uppercase'>Supplements/Medications stopped</span>
                    </Box>
                    <Box my={2}>
                        <WildHealthPlaceHolder message="No changes were made during this visit." />
                    </Box>
                </Box>
            )
        }

        let globalIndex = 0;
        return (
            <Box>
                <Box my={2} className={classes.title}>
                    <span className='uppercase'>Supplements/Medications stopped</span>
                </Box>
                <Box my={2}>
                    {
                        content.medicationsSupplements.medications.filter(i => i.isStopped).map((item, index) =>
                            <Box my={1} key={index}>
                                <span className={clsx(classes.regular, classes.textWrap)}>{`${++globalIndex}. ${item.name}`}</span>
                            </Box>
                        )
                    }
                    {
                        content.medicationsSupplements.supplements.filter(i => i.isStopped).map((item, index) =>
                            <Box key={index} my={1} display="flex">
                                <Box width={0.25}>
                                    <span className={clsx(classes.regular, classes.textWrap)}>{`${++globalIndex}. ${item.name}`}</span>
                                </Box>
                                <Box width={0.25}>
                                    <span className={clsx(classes.regular, classes.textWrap)}>{item.dosage}</span>
                                </Box>
                                <Box width={0.25}>
                                    <WildHealthExternalLinkButton id={`follow-up-link-stopped-supplements-${item.id}`} link={item.purchaseLink} />
                                </Box>
                            </Box>
                        )
                    }
                </Box>
            </Box>
        )
    }

    const renderTodayCoachingContent = () => {
        return (
            <Box mt={7}>
                <span className={clsx(commonClasses.secondaryTitle, "wh-tw-uppercase")}>
                    Today’s Coaching
                </span>
                <Box py={2}>
                    <Divider />
                </Box>
                <Box>
                    <TextMarkdownComponent content={content.todayCoachingContent} classLink={classes.renderText} />
                </Box>
            </Box>
        );
    }

    const renderNextSteps = () => {
        return (
            <Box>
                <Box my={2} className={classes.title}>
                    <span className='uppercase'>Next Steps</span>
                </Box>

                <Box my={2}>
                    {
                        content.steps.length ?
                            content.steps.map((item, index) =>
                                <Box my={1} display="flex" key={item.id}>
                                    <Box pt={0.3} className={classes.regular}>{`${index + 1}.`}</Box>
                                    <TextMarkdownComponent content={item.name} classLink={classes.renderText} />
                                </Box>
                            ) :
                            <Box my={1}>
                                <WildHealthPlaceHolder message="No next steps." />
                            </Box>
                    }
                </Box>
            </Box>
        )
    }

    const amendedList = GenerateAmendedChangesForFollowUp(originalContent, content, internalContent, originalInternalContent);

    const renderNote = () => {
        return (
            <>
                <Box mt={5}>
                    {renderInternalNote()}
                </Box>
                <Box mt={7}>
                    {renderGeneralGoals()}
                </Box>
                <Box mt={7}>
                    {renderSupplements()}
                </Box>
                <Box mt={5}>
                    {renderStoppedSupplements()}
                </Box>
                {content.todayCoachingContent && renderTodayCoachingContent()}
                <Box mt={7}>
                    {renderNextSteps()}
                </Box>
                <Box mt={7}>
                    {renderNextAppointmentDates()}
                </Box>
                {
                    amendedList && amendedList.length > 0 &&
                    (
                        <Box mt={3}>
                            <Box py={2}>
                                <Divider />
                            </Box>
                            {
                                amendedList.map((text, index) => {
                                    if (index == 0) {
                                        return (<Box pb={1} className={commonClasses.textBold}>{text}</Box>);
                                    }
                                    else {
                                        return (<Box>{text}</Box>)
                                    }
                                })
                            }
                        </Box>
                    )
                }
                <Box mt={7}>
                    <NoteSignatureComponent note={note} noteId={noteId} />
                </Box>
            </>
        )
    }

    return (
        <Box m={5} mt={0} p={3}>
            <Box display="flex " alignItems="center" justifyContent='space-between'>
                <ButtonGroup size="large" variant="text" color="default"
                    aria-label="large outlined primary button group">
                    <Button
                        id="follow-up-to-notes"
                        onClick={() => handleGoToNotes()}
                        startIcon={<ArrowBackIosRoundedIcon />}
                    >
                        To Notes
                    </Button>
                    <Button disabled className={classes.title}>
                        {
                            isLoading
                                ? 'Loading . . .'
                                : renderTitle()
                        }
                    </Button>
                    {
                        displaySelectVersion()
                    }
                </ButtonGroup>
                <Box display="flex " alignItems="center">
                    {
                        displayEditButton()
                    }
                    {
                        displayDownloadPdfButton()
                    }
                </Box>
            </Box>

            <Box mt={5}>
                <Box mt={5}>
                    {
                        isLoading
                            ? <WildHealthLinearProgress />
                            : renderNote()
                    }
                </Box>
            </Box>
        </Box>
    );
}
