import React, { useEffect, useRef, useState } from 'react';
import Recaptcha from 'react-google-invisible-recaptcha';
import { Subscription } from 'rxjs';
import { SITE_KEY } from "../../../../config";
import { AddOnModel, SelectAddOnModel } from "../../../addons/models/addOns.models";
import { addOnsService } from '../../../addons/services/addOns.service';
import { addOnsQuery } from '../../../addons/stores';
import { getLastObject } from "../../../common/helpers/get-last-object";
import { onEmit } from "../../../common/helpers/on-emit";
import { getInitAddress } from "../../../common/models/address.models";
import { Gender } from "../../../common/models/user.models";
import { IErrorState } from "../../../common/validation/error-state";
import { MerchantCredentialsModel, PayerInformation } from '../../models/payment.models';
import { paymentService } from '../../services/payment.service';
import { paymentQuery } from '../../stores/paymentStore';
import { joinPracticeComponentValidator } from "./joinPracticeComponent.validator";
import {PracticeInfoModel} from "../../../account/models/practice.model";
import {profileQuery} from "../../../account/stores/profileStore";
import {profileService} from "../../../account/services/profile.service";
import {useHistory} from "react-router-dom";
import {navigationService} from "../../../../services/navigation.service";
import {RegisterPatientModel} from "../../../patients/models/patient.model";
import {patientsService} from "../../../patients/services/patients.service";
import {paymentPlansService} from "../../services/paymentPlans.service";
import {PaymentPlanModel} from "../../models/paymentPlan.models";
import {PaymentPeriodModel} from "../../models/paymentPeriod.models";
import {PaymentPriceModel} from "../../models/paymentPrice.models";
import {paymentPlansQuery} from "../../stores/paymentPlansStore";
import { LeadSourceModel, PatientLeadSourceModel } from '../../../leadSources/models/leadSource.models';
import { leadSourcesQuery } from '../../../leadSources/stores/leadSources.query';
import { leadSourcesService } from '../../../leadSources/services/leadSources.service';
import { addressQuery } from '../../../account/stores/addressStore';

/**
 * Represents payment form state
 */
interface JoinPracticeComponentState extends IErrorState {
    practice: PracticeInfoModel;
    paymentPlan: PaymentPlanModel;
    paymentPeriod: PaymentPeriodModel;
    paymentPrice: PaymentPriceModel;
    publicKey: string | null;
    provider: string | null;
    payer: PayerInformation;
    paymentToken: string;
    addOns: SelectAddOnModel[];
    isSubmitted: boolean;
    leadSource: LeadSourceModel;
}

const context = {};

export function useFacade(practiceUrl: string): [
    JoinPracticeComponentState,
    boolean,
    boolean,
    (field: string, value: string) => void,
    (isChecked: boolean) => void,
    (id: number, value: boolean) => void,
    () => boolean,
    (paymentToken: string) => void,
    JSX.Element,
] {
    const history = useHistory();
    const recaptcha = useRef(null);
    const [state, setState] = useState({
        publicKey: null,
        provider: null,
        isSubmitted: false,
        isLoading: true,
        payer: {
            firstName: '',
            lastName: '',
            phoneNumber: '',
            birthday: null,
            gender: Gender.None,
            isShippingSameAsBilling: false,
            billingAddress: getInitAddress(),
            shippingAddress: getInitAddress(),
            email: '',
            password: '',
            confirmPassword: '',
        } as PayerInformation,
        practice: null,
        paymentPlan: null,
        paymentPeriod: null,
        paymentPrice: null,
        paymentToken: '',
        addOns: [],
        errors: {},
        leadSource: null
    } as JoinPracticeComponentState);

    const isLoading = (): boolean => {
        if (!state.practice) {
            return true;
        }

        if (!state.paymentPlan) {
            return true;
        }

        if (!state.paymentPeriod) {
            return true;
        }

        if (!state.paymentPrice) {
            return true;
        }

        return !state.publicKey;
    }

    const handleChanges = (field: string, value: any) => {
        updateValidationState(field, value);
        const payer = state.payer;
        const keys = field.split(".");
        const key = keys.pop();
        const lastObject = getLastObject(keys, payer);
        lastObject[key] = value;

        setState({...state, payer: Object.assign({}, payer)});

        if (field === 'gender') {
            addOnsService.getOptional(state.paymentPlan.id, value);
        }
    }

    const updateValidationState = (field: string, value: any) => {
        if (state.payer.isShippingSameAsBilling && field.includes('billingAddress')){
            const shippingAddressField = field.replace('billingAddress', 'shippingAddress');
            joinPracticeComponentValidator.validateAndSetState(state, setState, shippingAddressField, value);
        }

        joinPracticeComponentValidator.validateAndSetState(state, setState, field, value);
    }

    const handleIsShippingSameAsBillingChange = (isChecked: boolean) => {
        const shippingAddress = isChecked ? state.payer.billingAddress : getInitAddress();

        Object.entries(shippingAddress).forEach(([key, value]) => {
            joinPracticeComponentValidator.validateAndSetState(state, setState, 'shippingAddress.' + key, value)
        });

        state.payer.shippingAddress = shippingAddress;
        state.payer.isShippingSameAsBilling = isChecked;

        setState(state => ({...state}));
    }

    const handleSelectAddOnChange = (id: number, value: boolean) => {
        const addOn = state.addOns.find(x => x.id === id);
        if (addOn) {
            addOn.isSelected = value;
            setState(state => ({...state}));
        }
    }

    const scrollToErrorField = (state) => {
        const errors = Object.keys(state.errors);

        if (!errors.length) return;

        const firstInvalidField = errors[0];
        const top = document.getElementById(firstInvalidField).getBoundingClientRect().top;

        const approximateVisibleOffset = 200;

        window.scrollBy({
            top: top - approximateVisibleOffset,
            behavior: 'smooth'
        });
    }

    const executeRecaptcha = () => {
        recaptcha.current?.reset();
        recaptcha.current?.execute()
    }

    const renderRecaptcha = () => {
        context['state'] = state;
        context['paymentToken'] = state.paymentToken;

        return (
            <Recaptcha
                ref={recaptcha}
                sitekey={SITE_KEY}
                onResolved={(token) => recaptchaOnResolved(token, context)}
            />
        );
    }

    const recaptchaOnResolved = (antiSpamToken: string, context) => {
        const state = context['state'];
        const paymentToken = context['paymentToken'];

        const patient: RegisterPatientModel = {
            inviteCode: null,
            practiceId: state.practiceId,
            paymentToken: paymentToken,
            antiSpamToken: antiSpamToken,
            email: state.payer.email.trim(),
            phoneNumber: state.payer.phoneNumber,
            firstName: state.payer.firstName,
            lastName: state.payer.lastName,
            marketingSMS: state.isAgreeSMS,
            gender: Number(state.payer.gender),
            birthday: state.payer.birthday,
            password: state.payer.password,
            confirmPassword: state.payer.confirmPassword,
            paymentPeriodId: state.paymentPeriod.id,
            paymentPriceId: state.paymentPrice.id,
            billingAddress: state.payer.billingAddress,
            shippingAddress: {...state.payer.shippingAddress, state: addressQuery.findState(state.payer.shippingAddress.state)?.abbreviation},
            addOnIds: state.addOns.filter(x => x.isSelected).map(x => x.id),
            agreements: [],
            founderId: null,
            leadSource: {
                leadSourceId: state.leadSource.id,
                otherLeadSource: ""
                
            } as PatientLeadSourceModel,
            employeeId: null,
            linkedEmployeeId: null,
            employerProductKey: null,
            promoCode: state.couponCode
        }

        patientsService.register(patient).subscribe(
            patient => {
                navigationService.toJoinPracticeSuccess(history, patient, state.practice);
            },
            () => {
                setState({
                    ...state,
                    isSubmitted: false
                });
            }
        );
    }

    const validateForm = (): boolean => {
        joinPracticeComponentValidator.validateAndSetState(state, setState, "firstName", state.payer.firstName);
        joinPracticeComponentValidator.validateAndSetState(state, setState, "lastName", state.payer.lastName);
        joinPracticeComponentValidator.validateAndSetState(state, setState, "birthday", state.payer.birthday);
        joinPracticeComponentValidator.validateAndSetState(state, setState, "gender", state.payer.gender);
        joinPracticeComponentValidator.validateAndSetState(state, setState, "phoneNumber", state.payer.phoneNumber);
        joinPracticeComponentValidator.validateAndSetState(state, setState, "email", state.payer.email);
        joinPracticeComponentValidator.validateAndSetState(state, setState, "password", state.payer.password);
        joinPracticeComponentValidator.validateAndSetState(state, setState, "confirmPassword", state.payer.confirmPassword);
        joinPracticeComponentValidator.validateAndSetState(state, setState, "billingAddress.streetAddress1", state.payer.billingAddress.streetAddress1);
        joinPracticeComponentValidator.validateAndSetState(state, setState, "billingAddress.streetAddress2", state.payer.billingAddress.streetAddress2);
        joinPracticeComponentValidator.validateAndSetState(state, setState, "billingAddress.city", state.payer.billingAddress.city);
        joinPracticeComponentValidator.validateAndSetState(state, setState, "billingAddress.country", state.payer.billingAddress.country);
        joinPracticeComponentValidator.validateAndSetState(state, setState, "billingAddress.state", state.payer.billingAddress.state);
        joinPracticeComponentValidator.validateAndSetState(state, setState, "billingAddress.zipCode", state.payer.billingAddress.zipCode);
        joinPracticeComponentValidator.validateAndSetState(state, setState, "shippingAddress.streetAddress1", state.payer.shippingAddress.streetAddress1);
        joinPracticeComponentValidator.validateAndSetState(state, setState, "shippingAddress.streetAddress2", state.payer.shippingAddress.streetAddress2);
        joinPracticeComponentValidator.validateAndSetState(state, setState, "shippingAddress.city", state.payer.shippingAddress.city);
        joinPracticeComponentValidator.validateAndSetState(state, setState, "shippingAddress.country", state.payer.shippingAddress.country);
        joinPracticeComponentValidator.validateAndSetState(state, setState, "shippingAddress.state", state.payer.shippingAddress.state);
        joinPracticeComponentValidator.validateAndSetState(state, setState, "shippingAddress.zipCode", state.payer.shippingAddress.zipCode);

        const isValid = joinPracticeComponentValidator.stateIsValid(state);

        !isValid && scrollToErrorField(state);

        return isValid;
    }

    const handleConfirmPurchase = (paymentToken: string) => {
        setState({...state, isSubmitted: true, paymentToken: paymentToken});
        executeRecaptcha();
    };

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<MerchantCredentialsModel>(paymentQuery.credentials$, credentials => {
                setState(state => ({
                    ...state,
                    publicKey: credentials?.publicKey,
                    provider: credentials?.provider,
                    practiceId: credentials?.practiceId
                }));
            }),
            onEmit<PracticeInfoModel>(profileQuery.practiceInfo$, practice => {
                if (!practice)
                    return;

                const practice1: any = practice;
                const address = {
                    ...practice1.address,
                    streetAddress1: practice1.address.address1,
                    streetAddress2: practice1.address.address2,
                }
                setState(state => ({...state, practice: {...practice, address: address}}));
            }),
            onEmit<PaymentPlanModel[]>(paymentPlansQuery.plans$, plans => {
                if (!plans || !plans.length) {
                    return;
                }

                const plan = plans[0];
                const period = plan.periods[0];
                const price = period.prices[0];

                setState(state => ({
                    ...state,
                    paymentPlan: plan,
                    paymentPeriod: period,
                    paymentPrice: price
                }));

                addOnsService.getOptional(plan.id, state.payer.gender);
            }),
            onEmit<AddOnModel[]>(addOnsQuery.addOns$, addOns => {
                const items = addOns.map(i => i as SelectAddOnModel);
                items.forEach(i => i.isSelected = false);
                setState(state => ({...state, addOns: items}));
            }),
            onEmit<LeadSourceModel[]>(leadSourcesQuery.leadSources$, leadSources => {
                if(!leadSources){
                    return;
                }

                setState(state => ({
                    ...state,
                    leadSource: leadSources[0]
                }));
            }),
        ];


        profileService.getPracticeInfo(practiceUrl).subscribe(
            (practice) => {
                paymentPlansService.getActive(practice.id).subscribe();
                paymentService.getCredentials(practice.id);
                leadSourcesService.getActive(practice.id);
            },
            () => {
                navigationService.toDefault(history)
            }
        );

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, [practiceUrl]);

    return [
        state,
        joinPracticeComponentValidator.stateIsValid(state),
        isLoading(),
        handleChanges,
        handleIsShippingSameAsBillingChange,
        handleSelectAddOnChange,
        validateForm,
        handleConfirmPurchase,
        renderRecaptcha(),
    ]
}
