import Axios from 'axios-observable';
import { Observable } from "rxjs";
import { authHeader } from '../../common/helpers/auth-header';
import {BuyProductModel} from "../../products/models/buyProduct.model";
import { UpdateInsuranceStatesModel } from '../../insurance/models/insurance.models';
import { Track, PlatformName } from '../../tracking/tracker';
import { authQuery } from '../../auth/stores/auth';
import { UserType } from '../../auth/models/auth.enums';
import { ChangeSubscriptionPaymentPriceModel } from '../../payment/models/subscription.models';

/**
 * Provides method for working with response for API
 */
export class EngineeringService {
    private authUrl = `${process.env.REACT_APP_API_URL}Auth/`;
    private usersUrl = `${process.env.REACT_APP_API_URL}Users/`;
    private identitiesUrl = `${process.env.REACT_APP_API_URL}Identities/`;
    private patientsUrl = `${process.env.REACT_APP_API_URL}Patients/`;
    private subscriptionUrl = `${process.env.REACT_APP_API_URL}Subscription/`;
    private paymentPlansUrl = `${process.env.REACT_APP_API_URL}PaymentPlans/`;
    private performanceTestsUrl = `${process.env.REACT_APP_API_URL}Technical/PerformanceTests/`;

    /**
     * Login on behalf Of User
     * @param model
     */
    public loginOnBehalfOfUser(userId: number, patientId: number, employeeId: number): Observable<any> {
        const config = { headers: authHeader() };

        const model = { userId, patientId, employeeId };
        return new Observable(observer => {
        Axios.post(`${this.authUrl}Authenticate/OnBehalfOfUser`, model, config)
            .pipe()
            .subscribe(
                (response) => {
                    Track("impersonate_user", {
                        requestor_user_id: authQuery.getId(),
                        requestor_user_type: authQuery.getType() === UserType.Employee ? 'employee' : 'patient',
                        impersonated_user_id: response.data.id,
                        impersonated_user_type: response.data.type === UserType.Employee ? 'employee' : 'patient',
                        impersonation_success: true,
                        platform: PlatformName()
                    })
                    observer.next(response.data);
                    observer.complete();
                },
                error => {
                    observer.next(error?.response?.data);
                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }



    /**
     * Get All IntegrationId for Vendor and VendorId
     * @param vendor
     * @param vendorId
     */
    public getIntegrationInformation(vendor: number, vendorId: string): Observable<any> {
        const config = { headers: authHeader() };

        return new Observable<any>((observer) =>
        Axios.get(`${this.identitiesUrl}Get/${vendor}/${vendorId}`, config)
            .pipe()
            .subscribe(
            response => {
                observer.next(response.data);
                observer.complete();
            },
            error => {
                observer.next(error?.response?.data);
                observer.error(error);
                observer.complete();
            }
            )
        );
    }

    /**
     *  Change the patient to another practice id
     * @param patientId
     * @param practiceId
     */
    public changePracticeCopy(patientId:number, practiceId:number): Observable<any> {
        const config = {headers: authHeader()};
        return new Observable<any>((observer) => {
            Axios.post(`${this.patientsUrl}ChangePractice/${patientId}/Copy/${practiceId}`, {}, config)
                .pipe()
                .subscribe(
                    response => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.next(error?.response?.data);
                        observer.error(error);
                        observer.complete();
                    }
                )
        });
    }


    /**
     *  Change the Subscriprtion price
     * @param model
     */
    public changeSubscriptionPrice(model: ChangeSubscriptionPaymentPriceModel): Observable<any> {
        const config = {headers: authHeader()};

        return new Observable<any>((observer) => {
            Axios.post(`${this.subscriptionUrl}${model.subscriptionId}/ChangeSubscriptionPaymentPrice`, model, config)
                .pipe()
                .subscribe(
                    response => {
                        observer.next(!response?.data ? "Success!" : response.data);
                        observer.complete();
                    },
                    error => {
                        observer.next(error?.response?.data ?? error?.response);
                        observer.error(error);
                        observer.complete();
                    }
                )
        });
    }

    /**
      *  Buy the products by the patient
      * @param buyProductModel
      */
    public buyProducts(buyProductModel: BuyProductModel): Observable<any> {
        const config = {headers: authHeader()};
        return new Observable<any>((observer) => {
            Axios.post(`${this.patientsUrl}${buyProductModel.patientId}/BuyProducts`, buyProductModel, config)
            .pipe()
            .subscribe(
                response => {
                    observer.next(response.data);
                    observer.complete();
                },
                error => {
                    observer.next(error?.response?.data);
                    observer.error(error);
                    observer.complete();
                }
            )
        });
    }

    /**
     *  Change the Subscriprtion candidate
     * @param patientId
     */
    public changeSubscriptionCandidate(patientId: number): Observable<any> {
        const config = {headers: authHeader()};

        return new Observable<any>((observer) => {
            Axios.post(`${this.patientsUrl}${patientId}/ChangeSubscriptionCandidate`, {}, config)
                .pipe()
                .subscribe(
                    response => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.next(error?.response?.data);
                        observer.error(error);
                        observer.complete();
                    }
                )
        });
    }


    /**
     *  Get the populate payment plan recommend
     * @param recommendModel
     */
    public paymentPlanRecommend(recommendModel: any): Observable<any> {
        const config = {headers: authHeader()};

        return new Observable<any>((observer) => {
            Axios.post(`${this.paymentPlansUrl}recommend`, recommendModel, config)
                .pipe()
                .subscribe(
                    response => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.next(error?.response?.data);
                        observer.error(error);
                        observer.complete();
                    }
                )
        });
    }


    /**
     *  Migrate the payment plan
     * @param migrationModel
     */
    public migratePaymentPlan(migrationModel: any): Observable<any> {
        const config = {headers: authHeader()};

        return new Observable<any>((observer) => {
            Axios.post(`${this.paymentPlansUrl}migrate`, migrationModel, config)
                .pipe()
                .subscribe(
                    response => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.next(error?.response?.data);
                        observer.error(error);
                        observer.complete();
                    }
                )
        });
    }


    /**
     *  Add the Insurance state to the payment plan
     * @param updateInsuranceStatesModel
     */
    public addPaymentPlanInsuranceStates(updateInsuranceStatesModel: UpdateInsuranceStatesModel): Observable<any> {
        const config = {headers: authHeader()};

        return new Observable<any>((observer) => {
            Axios.post(`${this.paymentPlansUrl}AddPaymentPlanInsuranceStates`, updateInsuranceStatesModel, config)
                .pipe()
                .subscribe(
                    response => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.next(error?.response?.data);
                        observer.error(error);
                        observer.complete();
                    }
                )
        });
    }


    /**
     *  Remove the Insurance state to the payment plan
     * @param removeInsuranceStatesModel
     */
    public removePaymentPlanInsuranceStates(removeInsuranceStatesModel: UpdateInsuranceStatesModel): Observable<any> {
        const config = {headers: authHeader()};

        return new Observable<any>((observer) => {
            Axios.post(`${this.paymentPlansUrl}RemovePaymentPlanInsuranceStates`, removeInsuranceStatesModel, config)
                .pipe()
                .subscribe(
                    response => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.next(error?.response?.data);
                        observer.error(error);
                        observer.complete();
                    }
                )
        });
    }

    /**
     *  Performance test for the reset patient products
     * @param patientId
     */
    public resetPatientProducts(patientId: number): Observable<any> {
        const config = {headers: authHeader()};

        return new Observable<any>((observer) => {
            Axios.post(`${this.performanceTestsUrl}ResetPatientProductsCommand/${patientId}`, {}, config)
                .pipe()
                .subscribe(
                    response => {
                        observer.next(!response?.data ? "Success!" : response.data);
                        observer.complete();
                    },
                    error => {
                        observer.next(error?.response?.data);
                        observer.error(error);
                        observer.complete();
                    }
                )
        });
    }

    /**
     *  Performance test for the CreateDefaultPaymentPlan command
     * @param defaultPaymentPlanModel
     */
    public createDefaultPaymentPlan(defaultPaymentPlanModel: any): Observable<any> {
        const config = {headers: authHeader()};

        return new Observable<any>((observer) => {
            Axios.post(`${this.performanceTestsUrl}CreateDefaultPaymentPlanCommand`, defaultPaymentPlanModel, config)
                .pipe()
                .subscribe(
                    response => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.next(error?.response?.data);
                        observer.error(error);
                        observer.complete();
                    }
                )
        });
    }

    /**
     *  Get the Patient Products to management
     * @param patientId
     */
     public getActivePatientProducts(patientId: number): Observable<any> {
        const config = { headers: authHeader() };

        return new Observable<any>((observer) =>
        Axios.get(`${this.patientsUrl}${patientId}/GetActivePatientProducts`, config)
            .pipe()
            .subscribe(
            response => {
                observer.next(response.data);
                observer.complete();
            },
            error => {
                observer.next(error?.response?.data);
                observer.error(error);
                observer.complete();
            }
            )
        );
    }

    /**
     * delete unspecific user
     */
    public deleteUnspecifiedUser(email: string) {

        const config = { headers: authHeader() };

        return new Observable(observer => {
            Axios.delete(`${this.usersUrl}/Careful?email=${email}`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next(!response?.data ? 'User successfully deleted!' : response.data);
                        observer.complete();
                    },
                    error => {
                        observer.next(error?.response?.data);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }
}

export const engineeringService = new EngineeringService();
