import {CoverageModel} from '../../models/insurance.models';
import {CoverageStatus} from "../../enums/coverageStatus";
import {insuranceService} from "../../services/insurance.service";
import {confirmService} from "../../../../services/confirm.service";

export function useFacade(): [Function] {
    const handleActivationSwitch = (coverage: CoverageModel) => {
        if (coverage.status === CoverageStatus.Active) {
            confirmService.confirm('Mark as Inactive? ', 'Are you sure you want to mark this insurance card as inactive?', 'Mark as inactive', 'Cancel', "danger").subscribe(() => {
                insuranceService.deactivateCoverage(coverage.id).subscribe();
            });
        } else {
            insuranceService.activateCoverage(coverage.id).subscribe();
        }
    }

    return [handleActivationSwitch]
}
