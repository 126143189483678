import { Slider, createStyles, makeStyles, withStyles, Theme } from "@material-ui/core";
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 14,
            '& ::-webkit-scrollbar': {
                height: 12,
            },
            '& ::-webkit-scrollbar-track': {
                '-webkit-border-radius': '3px',
                borderRadius: '3px',
            },
            '& ::-webkit-scrollbar-thumb': {
                '-webkit-border-radius': '3px',
                borderRadius: '3px',
                background: '#dfe0dc',
                borderRight: `${colors.white} 2px solid`
            },
        },
        stickyLeft: {
            position: 'sticky',
            left: 0,
            backgroundColor: colors.white,
            zIndex: 1
        },
        tableToolbar: {
            borderBottom: '1px solid #f6f8fb'
        },
        addLabButton: {
            borderRadius: '2px !important',
            height: 34,
            backgroundColor: colors.white,
            border: '1px solid #cdcdcd !important',
            cursor: 'pointer',
            textTransform: 'none',
            fontWeight: 400
        },
        shadowRight: {
            boxShadow: '0px 9px 15px rgba(168, 173, 198, 0.20) !important'
        },
        popoverDivider: {
            color: `${colors.separators} !important`,
            background: `${colors.separators} !important`
        },
        sideMobile: {
            width: 400,
            height: '100%'
        },
        sideContainer: {
            height: '100%',
            width: '100%',
            overflow: 'auto',
            padding: 20
        },
    })
)

type Props = {
    isDanger?: boolean;
    isRevert?: boolean;
};

export const BiomarkerSlider = withStyles(() => ({
    root: {
      color: (props: Props) => props.isDanger ? colors.rose100 : colors.mint200,
      height: 18,
    },
    thumb: {
      height: '24px !important',
      width: '24px !important',
      backgroundColor: (props: Props) => props.isDanger ? colors.rose500 : colors.mint500,
      border: "2px solid #fff",
      marginTop: -2,
      marginLeft: -12,
      "&:focus, &:hover, &$active": {
        boxShadow: "inherit"
      },
    },
    active: {},
    valueLabel: {
      left: "calc(-50% + 4px)"
    },
    track: {
      height: 18,
      borderRadius: 50,
      width: '50% !important',
      right: (props: Props) => props.isRevert ? '0% !important' : 'inherit !important',
      left: (props: Props) => props.isRevert ? 'inherit !important' : '0% !important',
      display: 'block !important',
      color: (props: Props) => props.isDanger ? colors.rose100 : colors.mint200,
      borderTopRightRadius: (props: Props) => !props.isRevert ? 0 : 50,
      borderBottomRightRadius: (props: Props) => !props.isRevert ? 0 : 50,
      borderTopLeftRadius: (props: Props) => props.isRevert ? 0 : 50,
      borderBottomLeftRadius: (props: Props) => props.isRevert ? 0 : 50
    },
    rail: {
      height: 18,
      borderRadius: 50
    }
})
)(Slider);