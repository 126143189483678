import React from 'react';
import { Box, IconButton } from "@material-ui/core";
import clsx from 'clsx';
import commonUseStyles from '../../../common/styles/common.styles';
import { AnswerModel, QuestionnaireModel } from '../../models/questionnaire.models';
import { QuestionComponent } from '../questionComponent/QuestionComponent';
import { Remove } from '@material-ui/icons';
import { colors } from '../../../common/constants/colors';

export interface DeselectParentQuestionComponentProps {
    questionName: string;
    questionnaire: QuestionnaireModel;
    handleChanges: (field: string, value: string) => void;
    answers: AnswerModel[];
    errors: any,
}

export const DeselectParentQuestionComponent: React.FC<DeselectParentQuestionComponentProps> = (props: DeselectParentQuestionComponentProps) => {
    const {
        questionName,
        questionnaire,
        handleChanges,
        answers,
        errors
    } = props;

    const commonClasses = commonUseStyles();

    const question = questionnaire.questions.find(x => x.name === questionName);
    const parentQuestionName = questionnaire.questions.find(x => x.childrenNames.includes(questionName)).name;

    const handleDeselect = (questionName: string, value: string) => {
        const answer = answers.find(x => x.key === questionName);
        try {
            const data = JSON.parse(answer.value);
            data.v = data.v.filter(x => x !== value);
            handleChanges(questionName, JSON.stringify(data));
        } catch {
            return;
        }
    }

    return (
        <Box mt={5}>
            <Box display='flex' alignItems='center' mb={-2}>
                <Box>
                    <IconButton
                        id="deselect-parent-question"
                        onClick={() => handleDeselect(parentQuestionName, question.parentAnswers[0])}
                    >
                        <Remove
                            style={{
                                color: colors.main,
                                backgroundColor: colors.gray3
                            }}
                        />
                    </IconButton>
                </Box>
                <Box ml={1}>
                    <span className={clsx(commonClasses.size16, commonClasses.textMedium)}>{question.parentAnswers[0]}</span>
                </Box>
            </Box>
            <Box>
                <QuestionComponent
                    disabled={false}
                    inline={false}
                    error={errors[question.name]}
                    answer={answers?.find(x => x.key === question.name)?.value || null}
                    question={question}
                    handleChanges={(value) => handleChanges(question.name, value)}
                />
            </Box>
        </Box>
    )
}