import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textWrap: {
            wordBreak: 'break-word',
            display: 'flex',
            flexWrap: "wrap"
        },
        recommendation: {
            minHeight: 'auto',
            width: '100%',
        },
        readonly: {
          border: '2px solid #dfe0dc',
          borderRadius: '2px',
          padding: '20px',
          lineHeight: '1.9rem',
          whiteSpace: 'pre-line',
          overflow: 'auto'
        },
        printable: {
            border: '2px solid #dfe0dc',
            borderRadius: 2,
            lineHeight:' 1.9rem',
            whiteSpace: 'pre-line',
            fontFamily: 'monospace',
            overflow: 'auto',
          },
          fullwidth: {
            width: '100%',
          },
          saveChanges: {
            color: colors.white,
          }
    }),
);