export interface CouponCodeModel {
    couponCode: string;
}

export enum DiscountType
{
    Percentage = 1,
    Nominal = 2
}

export const DiscountTypeNames = new Map<DiscountType, string>([
    [DiscountType.Percentage, "%"],
    [DiscountType.Nominal, "$"],
]);

export interface CreatePromoCodeModel {
    code: string,
    description: string,
    discount: number;
    discountType: DiscountType;
    paymentPlans: number[];
    isDiscountStartupFee: boolean;
    isDiscountLabs: boolean;
    isAppliedForInsurance: boolean;
    expirationDate: Date | null;
}

export interface PromoCodeVewModel {
    id: number;
    code: string;
    discount: number;
    discountUnitOfMeasure: string;
    paymentPlans: string[];
    additional: string[];
    status: string;
    canDelete: boolean;
    canDeactivate: boolean;
    description: string;
    expirationDate: Date;
}
