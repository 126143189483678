import Axios from 'axios-observable';
import { API_URL } from "../../../config";
import { Observable } from 'rxjs';
import { authHeader } from '../../common/helpers/auth-header';
import { PreauthorizeRequestModel, CreatePreauthorizeRequestModel, UpdatePreauthorizeRequestModel } from "../models/preauthorizeRequest.model";
import { EmployeesStore, employeesStore } from '../../employee/stores/employeesStore';
import { snackService } from '../../common/snack/state';

export class PreauthorizeRequestService {
    private url = `${API_URL}PreauthorizeRequest`;

    constructor(private employeesStore: EmployeesStore) {
    }

    public getByToken(token: string): Observable<PreauthorizeRequestModel> {

        const config = { headers: authHeader() };

        return new Observable((observer) => {
            Axios.get<any>(`${this.url}/ByToken/${token}`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getRegistrationLink(id: number) {
        const config = {
            headers: authHeader()
        };

        return new Observable<string>((observer) =>
            Axios.get(`${this.url}/${id}/Link`, config)
                .pipe()
                .subscribe(
                    response => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    public getPreauthorizeRequests(paymentPlanId: number | null = null, paymentPeriodId: number | null = null, paymentPriceId: number | null = null, employerProductId: number | null = null) {
        const config = {
            headers: authHeader()
        };

        return new Observable<string>((observer) =>
            Axios.get(`${this.url}?paymentPlanId=${paymentPlanId ?? ''}&paymentPeriodId=${paymentPeriodId ?? ''}&paymentPriceId=${paymentPriceId ?? ''}&employerProductId=${employerProductId ?? ''}`, config)
                .pipe()
                .subscribe(
                    response => {
                        this.employeesStore.update({ preauthorizeRequests: response.data })
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    public createPreauthorizeRequest(model: CreatePreauthorizeRequestModel) {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.post(`${this.url}`, model, config)
                .pipe()
                .subscribe(
                    response => {
                        this.employeesStore.addRequest(response.data);
                        snackService.success('Request successfully registered');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    public createPreauthorizeRequests(models: CreatePreauthorizeRequestModel[]) {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.post(`${this.url}/Bulk`, models, config)
                .pipe()
                .subscribe(
                    response => {
                        this.employeesStore.addRequests(response.data);
                        snackService.success('Requests successfully registered');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    public updateRequest(model: UpdatePreauthorizeRequestModel) {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.put(`${this.url}`, model, config)
                .pipe()
                .subscribe(
                    response => {
                        employeesStore.updateRequest(response.data);
                        snackService.success('Request successfully updated');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    public resentEmployeeInvite(models: number[]) {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.post(`${this.url}/Notify`, models, config)
                .pipe()
                .subscribe(
                    response => {
                        snackService.success('Email successfully sended.');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    public deleteRequest(id: number) {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.delete(`${this.url}/${id}`, config)
                .pipe()
                .subscribe(
                    response => {
                        snackService.success('Request successfully deleted.');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }
}

export const preauthorizeRequestService = new PreauthorizeRequestService(employeesStore);
