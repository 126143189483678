import {EmployeeShortModel} from "../../employee/models/employee.models";
import {HealthReportOwnerModel} from "../../healthReport/models/healthReport.models";
import {DurationModel} from "../../appointments/models/times.models";
import {PatientShortModel} from "../../appointments/models/appointments.models";
import { HealthSummaryDataModel } from "../../healthSummary/models/healthSummary.models";
import {UserType} from "../../auth/models/auth.enums";
import {FullscriptSupplementModel} from "../../patientSupplements/models/fullscriptSupplementModels";
import {SupplementSource} from "../../patientSupplements/models/patientSupplementModel";
import {RxntMedicationModel} from "../../patientSupplements/models/patientMedicationModel";
import {RxntAllergyModel} from "../../patientSupplements/models/patientAllergiesModel";

export interface NoteLogModel {
    key: string;
    value: string;
}

export enum NotesType {
    Initial = 1,
    Blank = 2,
    FollowUp = 3,
    Internal = 4,
    HistoryAndPhysicalInitial = 5,
    HistoryAndPhysicalFollowUp = 6,
    HistoryAndPhysicalGroupVisit = 8,
    Soap = 7
}

export interface NotesGroupModel {
    employee: EmployeeShortModel;
    notes: NoteModel[]
}

export interface NoteAppointmentModel {
    id: number;
    date: Date;
    duration: number;
}

export interface NoteModel {
    id: number;
    name: string;
    title: string;
    type: NotesType;
    visitDate: Date;
    employee: EmployeeShortModel;
    appointment: NoteAppointmentModel | null;
    patient: PatientShortModel | null;
    patientId: number;
    isCompleted: boolean;
    completedBy: string;
    completedAt: Date | null;
    completedById: number | null;
    nextCoachAppointmentDate: Date;
    nextProviderAppointmentDate: Date;
    completedByEmployee: EmployeeShortModel;
    assignedTo: EmployeeShortModel;
    deletedBy: EmployeeShortModel;
    deletedAt: Date | null;
    deletionReason: NoteDeletionReason | null;
    assignedAt: Date | null;
    additionalNote: string;
    version: number;
    originalNoteId?: number;
    isOldIcc: boolean;
    isFutureAppointment: boolean;
    amendedNotes: NoteModel[];
}

export interface IccContent {
    internalContent: string;
    currentMedicalConditions: HealthSummaryDataModel[];
    appointmentConfiguration?: AppointmentConfigurationModel | null;
    intention: string;
    systolicBloodPressure: number;
    diastolicBloodPressure: number;
}


export interface DraftNoteModel extends NoteModel {
    owner: HealthReportOwnerModel;
    noteTypeName: string;
}

export interface NoteContentModel {
    noteId: number;
    internalContent: string;
    content: string;
    logs: NoteLogModel[];
}

export interface SaveNoteModel {
    id?: number;
    name: string;
    title: string;
    type: NotesType;
    visitDate: Date;
    patientId: number;
    internalContent: string;
    content: string;
    files?: AttachmentModel[];
    logs: NoteLogModel[];
    nextCoachAppointmentDate: Date;
    nextProviderAppointmentDate: Date;
    appointmentId: number | null;
    version: number;
    originalNoteId?: number;
}

export interface SaveNoteModelWithoutFile {
    id?: number;
    name: string;
    title: string;
    type: NotesType;
    visitDate: Date;
    patientId: number;
    internalContent: string;
    content: string;
    logs: NoteLogModel[];
    nextCoachAppointmentDate: Date;
    nextProviderAppointmentDate: Date;
    appointmentId: number | null;
    version: number;
    originalNoteId?: number;
}

export enum GoalCategory {
    Diet = 5,
    Exercise = 10,
    Sleep = 15,
    Neuro = 20,
    Longevity = 25,
    Mindfulness = 30,
    Other = 100
}

export const goalCategoryNames: { [category: number]: string; } = {
    [GoalCategory.Diet]: 'Diet',
    [GoalCategory.Exercise]: 'Exercise',
    [GoalCategory.Sleep]: 'Sleep',
    [GoalCategory.Neuro]: 'Neuro',
    [GoalCategory.Longevity]: 'Longevity',
    [GoalCategory.Mindfulness]: 'Mindfulness',
    [GoalCategory.Other]: 'Other',
}

export enum GoalsFilterState {
    TopGoals = 1,
    AllGoals = 2,
    PastGoals = 3,
}

export const goalsFiltersStateTrackNames: { [type: number]: string; } = {
    [GoalsFilterState.TopGoals]: 'top_goals',
    [GoalsFilterState.AllGoals]: 'all_goals',
    [GoalsFilterState.PastGoals]: 'past_goals',
}
export interface GoalModel {
    id: number;
    name: string;
    date: Date | null;
    category: GoalCategory;
    isCompleted: boolean | null;
    isTopGoal: boolean | null;
    completionDate: Date | null;
    interventions: InterventionModel[];
}

export interface GoalCommonModel {
    id: number;
    name: string;
    isCompleted: boolean | null;
    completionValue: number | null;
    interventions: InterventionModel[];
    category?: GoalCategory;
}

export interface InterventionModel {
    id: number;
    name: string;
    goalId?: number;
}

export interface StepModel {
    id: number;
    name: string;
}

export interface MedicationsSupplementsItem {
    id: number;
    name: string;
    dosage: string;
    instructions: string;
    source: SupplementSource | null;
    isInCurrent: boolean;
    isStopped: boolean;
}

export interface MedicationItem extends MedicationsSupplementsItem {
    startDate?: Date | null;
}

export interface SupplementItem extends MedicationsSupplementsItem {
    purchaseLink: string;
}

export interface RxntMedicationItemModel extends RxntMedicationModel{
    isInCurrent: boolean;
    isStopped: boolean;
}

export interface MedicationsSupplementsModel {
    medications: MedicationItem[];
    supplements: SupplementItem[];
    rxntMedications: RxntMedicationItemModel[];
    fullscriptSupplements: FullscriptSupplementModel[];
}

export interface FollowUpContent {
    goalsVersion?: string;
    goals?: GoalModel[];
    plan?: NotePlanModel;
    appointmentConfiguration?: AppointmentConfigurationModel;
    medicationsSupplements: MedicationsSupplementsModel;
    steps: StepModel[];
    todayCoachingContent?: string;
}

export interface AttachmentModel {
    tempId: number;
    id?: number;
    noteId: number;
    file: File;
    fileName: string;
    fileSize: number;
    ForDelete?: boolean;
}

export interface InternalNoteContent {
    attachmentsEmployee: AttachmentModel[];
}

export enum NextTimeSpan {
    Week = 1,
    Month = 2,
    Year = 3,
}

export const nextTimeSpanNames: { [id: string]: string; } = {
    [NextTimeSpan.Week]: 'Week',
    [NextTimeSpan.Month]: 'Month',
    [NextTimeSpan.Year]: 'Year',
}

export interface NextTimeModel {
    timeSpan: NextTimeSpan;
    quantity: number;
    isManual: boolean;
}

export interface PmhModel {
    allergies: string[];
    currentMedicalConditions: string[];
    famHX: string[];
    screeningHealthMaintenance: string[];
    surgicalHX: string[];
    socialLifestyleHX: string[];
}

export interface PmhModelNew {
    allergies: any[];
    rxntAllergies: RxntAllergyModel[];
    currentMedicalConditions: any[];
    famHX: any[];
    screeningHealthMaintenance: any[];
    screeningHealthMaintenanceApoe?: any;
    surgicalHX: any[];
    lifestyleHX: any[];
    socialHX: any[];
}

export class HealthSummaryNoteKeySectionNames {
    static readonly ALLERGIES: string = "ALLERGIES";
    static readonly FAMILY_HISTORY: string = "FAMILY_HISTORY";
    static readonly PROBLEMS_LIST: string = "PROBLEMS_LIST";
    static readonly SURGICAL_HISTORY: string = "SURGICAL_HISTORY";
    static readonly SCREENING_HEALTH_MAINTENANCE: string = "SCREENING_HEALTH_MAINTENANCE";
    static readonly LIFESTYLE_HISTORY: string = "LIFESTYLE_HISTORY";
    static readonly SOCIAL_HISTORY: string = "SOCIAL_HISTORY";
    static readonly HPI: string = "HPI";

}

export interface AppointmentConfigurationModel {
    visitDate: Date;
    duration: number;
    configurationId: number;
}

export interface NotePhysicalModel {
    unableToObtain: boolean;
    systolicBP: number;
    diastolicBP: number;
    heartRate: number;
    temperature: number;
    height: number;
    weight: number;
    physicalExam: string;
}

export interface RosModel {
    rosEnabled: boolean;
    rosValues: string[];
}

export interface NotePlanModel {
    planText?: string;
    labsText?: string;
    coachOtherText?: string;

    dietGoals?: GoalModel[];
    exerciseGoals?: GoalModel[];
    sleepGoals?: GoalModel[];
    neuroGoals?: GoalModel[];
    longevityGoals?: GoalModel[];
    mindfulnessGoals?: GoalModel[];

    dietEducations?: string;
    exerciseEducations?: string;
    sleepEducations?: string;
    neuroEducations?: string;
    longevityEducations?: string;
    mindfulnessEducations?: string;
}

export interface IcdCode {
    id: number;
    code: string;
    description: string;
    additionalInformation?: string;
}

export interface HistoryAndPhysicalContent {
    appointmentConfiguration?: AppointmentConfigurationModel;
    duration: DurationModel;
    pmhVersion?: string;
    chiefComplaint: string;
    HPI: string;
    PMH: PmhModel;
    PMHnew?: PmhModelNew;
    medicationsSupplements: MedicationsSupplementsModel;
    ROS: RosModel;
    physicalData: NotePhysicalModel;
    mdmData: MdmDataModel;
    diagnosis: IcdCode[];
    plan: NotePlanModel;
    specialTests: SpecialTestModel[];
}

export interface SoapContent {
    appointmentConfiguration?: AppointmentConfigurationModel;
    duration: DurationModel;
    chiefComplaint: string;
    subjective: string;
    medicationsSupplements: MedicationsSupplementsModel;
    ROS: RosModel;
    physicalData: NotePhysicalModel;
    mdmData: MdmDataModel;
    diagnosis: IcdCode[];
    plan: NotePlanModel;
    specialTests: SpecialTestModel[];
}

export enum MdmPatientType {
    New = 1,
    Established = 2
}

export enum MdmMode {
    Mdm = 1,
    TimeBased = 2
}

export interface MdmModel {
    id: number;
    code: number;
    description: string;
    categories: MdmCategoryModel[];
    riskDescription: string;
    patientType: MdmPatientType;
    minTime: number;
    maxTime: number;
    categoriesToComplete: number;
    employeeOnly: boolean;
    title: string;
}

export interface MdmCommonModel {
    id: number;
    name: string;
    description: string,
    reasons: MdmReasonModel[];
}

export interface MdmReasonModel {
    id: number;
    name: string;
    mdmPlanId?: number;
}

export interface MdmCategoryModel {
    id: number;
    name: string;
    itemsToSelectCount: number;
    items: MdmCategoryItemModel[];
}

export interface MdmCategoryItemModel {
    id: number;
    description: string;
    allowInput: boolean;
    order: number;
}

export interface SelectedMdmCategoryItemModel {
    id: number;
    inputValue: string;
}

export interface SignOffNoteModel {
    noteId: number;
    assignToId: number;
    additionalNote: string;
}

export interface MdmDataModel {
    mdmText: string;
    selectedMdmPatientType: MdmPatientType;
    selectedCodeId: number;
    showCodeDescription: boolean | null;
    selectedCategoryId: number | null;
    selectedCategoryIds: number[];
    selectedCategoryItems: SelectedMdmCategoryItemModel[];
    selectedMode: MdmMode;
    spentTime: number;
}

export interface SpecialTestModel {
    id: number;
    name: string;
    company: string;
    cost: string;
}


export enum MdmCategory {
    EstablishedPatient99203 = 1,
    EstablishedPatient99204 = 2,
    EstablishedPatient99205 = 3,
    NewPatient99203 = 4,
    NewPatient99204 = 5,
    NewPatient99205 = 6,
    EstablishedPatientG0438 = 7,
    EstablishedPatientG0439 = 8,
    NewPatientG0438 = 9,
}

export enum MdmCategoryIds {
    EstablishedPatientOne99203 = 1,
    EstablishedPatientSecond99203 = 2,
    EstablishedPatientOne99204 = 3,
    EstablishedPatientSecond99204 = 4,
    EstablishedPatientThird99204 = 5,
    EstablishedPatientOne99205 = 6,
    EstablishedPatientSecond99205 = 7,
    EstablishedPatientThird99205 = 8,
    NewPatientOne99203 = 9,
    NewPatientSecond99203 = 10,
    NewPatientOne99204 = 11,
    NewPatientSecond99204 = 12,
    NewPatientThird99204 = 13,
    NewPatientOne99205 = 14,
    NewPatientSecond99205 = 15,
    NewPatientThird99205 = 16,
}

export enum SectionType{
    Hpi = 1,
    PMH = 2,
    MedicationAndSupplements = 3,
    Ros = 4,
    Physical = 5,
    Mdm = 6,
    Diagnosis = 7,
    Plan = 8,
    NextAppointment = 9
}

export enum NoteDeletionReason {
    WrongPatient = 1,
    MissingContent = 2,
    WrongAppointment = 3,
    Other = 999
}

export const noteDeleteReasons: Map<number, string> = new Map<number, string>()
    .set(NoteDeletionReason.WrongPatient, 'The note was completed on the wrong patient')
    .set(NoteDeletionReason.MissingContent, 'The note contained incorrect content or was missing content')
    .set(NoteDeletionReason.WrongAppointment, 'The note was completed for the wrong appointment');

export const downloadNoteOptions: Map<number, string> = new Map<number, string>()
    .set(UserType.Patient, 'Patient View')
    .set(UserType.Employee, 'Provider View');

export const mdmCategoryItemsCount: { [id: number]: number; } = {
    [MdmCategoryIds.EstablishedPatientOne99203]: 2,
    [MdmCategoryIds.EstablishedPatientSecond99203]: 1,
    [MdmCategoryIds.EstablishedPatientOne99204]: 3,
    [MdmCategoryIds.EstablishedPatientSecond99204]: 1,
    [MdmCategoryIds.EstablishedPatientThird99204]: 1,
    [MdmCategoryIds.EstablishedPatientOne99205]: 3,
    [MdmCategoryIds.EstablishedPatientSecond99205]: 1,
    [MdmCategoryIds.EstablishedPatientThird99205]: 1,
    [MdmCategoryIds.NewPatientOne99203]: 2,
    [MdmCategoryIds.NewPatientSecond99203]: 1,
    [MdmCategoryIds.NewPatientOne99204]: 3,
    [MdmCategoryIds.NewPatientSecond99204]: 1,
    [MdmCategoryIds.NewPatientThird99204]: 1,
    [MdmCategoryIds.NewPatientOne99205]: 3,
    [MdmCategoryIds.NewPatientSecond99205]: 1,
    [MdmCategoryIds.NewPatientThird99205]: 1,

}

export enum NoteMarkdownTypes {
    H1 = 'h1',
    H2 = 'h2',
    H3 = 'h3',
    Bold = 'bold',
    Italic = 'italic',
    Blockquote = 'blockquote',
    List = 'list',
    Line = 'line',
    Link = 'link',
}

export enum NoteGoalsVersion {
    New = '2'
}

export enum NotePmhVersion {
    New = '2'
}
