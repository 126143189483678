import { Box, useTheme, useMediaQuery } from "@material-ui/core";
import { useStyles } from "./wildHealthContainer.styles";
import React from "react";
import clsx from "clsx";

interface WildHealthContainerProps {
  hideBackground?: boolean;
  minHeight?: any;
  minWidth?: any;
  margin?: number | string;
  display?: any;
  children: React.ReactNode;
}

export const WildHealthContainer: React.FC<WildHealthContainerProps> = (
  props: WildHealthContainerProps
) => {
  const {
    children,
    margin,
    minHeight,
    minWidth,
    hideBackground,
    display = "block",
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Box
      m={margin ?? "30px"}
      display={display}
      minWidth={minWidth}
      minHeight={
        minHeight ?? isSmallScreen ? "calc(100vh - 110px)" : "calc(100vh - 120px)"
      }
      className={clsx(classes.root, {
        [classes.rootWithBackground]: !hideBackground,
        [classes.rootMargin]: margin === undefined,
      })}
    >
      {children}
    </Box>
  );
};
