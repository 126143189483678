import {GeneralValidator} from "../../../common/validation/general-validator";
import {
    notWhitespaceValidationRule,
    stringLengthValidationRule,
} from "../../../common/validation/general-validation-rules";

export const editAppointmentDialogValidator = new GeneralValidator({
    'comment': [notWhitespaceValidationRule(), stringLengthValidationRule(0, 1000)],
    
});
