import {authHeader} from "../../common/helpers/auth-header";
import {Observable} from "rxjs";
import Axios from "axios-observable";
import moment from 'moment';
import {
    patientConversationsStore,
    PatientConversationsStore,
} from "../stores/patientConversationsStore/patientConversations.store";
import {
    AddEmployeeToConversationModel,
    ConversationAuthorModel,
    ConversationModelBase,
    ConversationModelBaseView,
    ConversationType,
    CreateConversationModel,
    CreateInternalConversationModel,
    CreateMediaUrlModel,
    EmployeeConversationModel,
    LastReadMessageUpdateModel,
    PatientConversationModel,
    RemoveEmployeeFromConversationModel,
    StartPatientSupportConversation,
    UpdateEmployeeSupportConversation,
    AcceptInteractionModel,
    RejectInteractionModel,
    DeleteMessageModel,
    ReactOnConversationMessageAlertModel,
    UpdateConversationSubject,
    StartGenericPlaygroundConversationModel,
    StartPatientPlaygroundConversationModel,
    ConversationTemplateModel,
    UpdateConversationTemplateModel,
    AddReactionModel
} from "../models/conversation.models";
import {
    employeeConversationsStore,
    EmployeeConversationsStore,
} from "../stores/employeeConversationsStore/employeeConversations.store";
import {
    AdminPanelMessageForwardingModel,
    AdminPanelMessageModel,
    CreateMessageModel,
    MessageSettingsModel,
    UpdateConversationsSettingsModel,
    AiDemoModel,
    TextCompletionResponseModel
} from "../models/message.models";
import {snackService} from "../../common/snack/state";
import {authQuery} from "../../auth/stores/auth";
import {PaginationModel} from "../../common/pagination/models/pagination-models";
import {confirmService} from "../../../services/confirm.service";
import {
    StaffOrHealthCoachMessagesEnum
} from "../components/patientConversationsViewComponent/patientConversationsViewComponent.hooks";
import pdfServices from "../helpers/pdfExporter/PdfServices";
import {patientStore} from "../../patients/stores/patientsStore";
import {KeyValueModel} from "../../healthReport/models/healthReport.models";


/**
 * Provides method for working with conversations
 */
export class ConversationsService {
    private conversationsUrl = `${process.env.REACT_APP_API_URL}Conversations`;
    private adminTools = `${process.env.REACT_APP_API_URL}AdminTools`;
    private patientUrl = `${this.conversationsUrl}/Patient`;
    private employeeUrl = `${this.conversationsUrl}/Employee`;
    private mediaUrl = `${this.conversationsUrl}/Media`;
    private templatesUrl = `${this.conversationsUrl}/Templates`;
    private aiUrl = `${process.env.REACT_APP_API_URL}Ai`;


    private getConfig = () => ({headers: authHeader()});

    constructor(
        private patientConversationsStore: PatientConversationsStore,
        private employeeConversationsStore: EmployeeConversationsStore) {
    }

    public togglePatientSmsReminders(patientId: number, isActive: boolean): Observable<KeyValueModel> {
        const url = `${this.patientUrl}/${patientId}/SmsReminders?isActive=${isActive}`;

        return new Observable<KeyValueModel>(observer => {
            Axios.put<KeyValueModel>(url, {}, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        patientStore.updateSettings(response.data)
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public selectConversation(conversation: EmployeeConversationModel): void {
        this.employeeConversationsStore.update({
            targetConversation: conversation
        });
    }

    public getEmployeeAuthor(): Observable<ConversationAuthorModel> {
        const url = `${this.employeeUrl}/Auth`;

        return new Observable<ConversationAuthorModel>(observer => {
            Axios.get<ConversationAuthorModel>(url, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        this.employeeConversationsStore.update({
                            author: response.data
                        });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getEmployeeConversations(): Observable<EmployeeConversationModel[]> {
        const url = `${this.employeeUrl}`;

        return new Observable<EmployeeConversationModel[]>(observer => {
            Axios.get<EmployeeConversationModel[]>(url, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        this.employeeConversationsStore.update({conversations: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getSupportSubmissions(): Observable<EmployeeConversationModel[]> {
        const url = `${this.employeeUrl}/SupportSubmissions`;

        return new Observable<EmployeeConversationModel[]>(observer => {
            Axios.get<EmployeeConversationModel[]>(url, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        this.employeeConversationsStore.update({supportSubmissions: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })

    }

    public getPatientAuthor(): Observable<ConversationAuthorModel> {
        const url = `${this.patientUrl}/Auth`;

        return new Observable<ConversationAuthorModel>(observer => {
            Axios.get<ConversationAuthorModel>(url, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        this.patientConversationsStore.update({
                            author: response.data
                        });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getPatientHealthCareConversation(): Observable<PatientConversationModel> {
        const url = `${this.patientUrl}/HealthCare`;

        return new Observable<PatientConversationModel>(observer => {
            Axios.get<PatientConversationModel>(url, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        this.patientConversationsStore.update({
                            heathCareConversation: response.data
                        });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getPatientStaffConversations(): Observable<PatientConversationModel[]> {
        const url = `${this.patientUrl}/Support`;

        return new Observable<PatientConversationModel[]>(observer => {
            Axios.get<PatientConversationModel[]>(url, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        this.patientConversationsStore.update({
                            supportConversations: response.data
                        });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public startPatientSupportConversations(model: StartPatientSupportConversation): Observable<PatientConversationModel> {
        const url = `${this.patientUrl}/Support`;

        return new Observable<PatientConversationModel>(observer => {
            Axios.post<PatientConversationModel>(url, model, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        this.patientConversationsStore.addSupportConversation(response.data)
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public startGenericPlaygroundConversations(model: StartGenericPlaygroundConversationModel): Observable<ConversationModelBase> {
        const url = `${this.conversationsUrl}/Create/GenericPlayground`;

        return new Observable<ConversationModelBase>(observer => {
            Axios.post<ConversationModelBase>(url, model, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        this.employeeConversationsStore.addConversation(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public startPatientPlaygroundConversations(model: StartPatientPlaygroundConversationModel): Observable<ConversationModelBase> {
        const url = `${this.conversationsUrl}/Create/PatientPlayground`;

        return new Observable<ConversationModelBase>(observer => {
            Axios.post<ConversationModelBase>(url, model, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        this.employeeConversationsStore.addConversation(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getConversationTemplates(type: ConversationType = null): Observable<ConversationTemplateModel[]> {
        const url = `${this.templatesUrl}?type=${type ?? ''}`;

        return new Observable<ConversationTemplateModel[]>(observer => {
            Axios.get<ConversationTemplateModel[]>(url, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        this.employeeConversationsStore.setTemplates(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public createConversationTemplate(model: UpdateConversationTemplateModel): Observable<ConversationTemplateModel> {
        return new Observable<ConversationTemplateModel>(observer => {
            Axios.post<ConversationTemplateModel>(`${this.templatesUrl}`, model, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        this.employeeConversationsStore.addTemplate(response.data);
                        snackService.success('Conversation Template successfully created');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }
    public updateConversationTemplate(model: UpdateConversationTemplateModel) {
        return new Observable((observer) =>
            Axios.put(`${this.templatesUrl}`, model, this.getConfig())
                .pipe()
                .subscribe(
                    response => {
                        this.employeeConversationsStore.updateTemplate(response.data);
                        snackService.success('Conversation Template successfully updated');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    public deleteConversationTemplate(id: number) {
        return new Observable((observer) =>
            Axios.delete(`${this.templatesUrl}/${id}`, this.getConfig())
                .pipe()
                .subscribe(
                    () => {
                        this.employeeConversationsStore.removeTemplate(id);
                        snackService.success('Conversation Template successfully deleted');
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    public lastReadMessageUpdate(model: LastReadMessageUpdateModel): Observable<LastReadMessageUpdateModel> {
        const url = `${this.conversationsUrl}/LastReadMessageUpdate`;

        return new Observable<LastReadMessageUpdateModel>(observer => {
            Axios.post<LastReadMessageUpdateModel>(url, model, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public setMessagesUnread(model: LastReadMessageUpdateModel): Observable<LastReadMessageUpdateModel> {

        const url = `${this.conversationsUrl}/SetUnreadMessages`;

        return new Observable<LastReadMessageUpdateModel>(observer => {
            Axios.post<LastReadMessageUpdateModel>(url, model, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public updateSupportConversationState(model: UpdateEmployeeSupportConversation): Observable<EmployeeConversationModel> {
        const url = `${this.employeeUrl}/UpdateState`;

        return new Observable<EmployeeConversationModel>(observer => {
            Axios.put<EmployeeConversationModel>(url, model, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        this.employeeConversationsStore.updateConversation(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public addEmployeeToConversationModel(model: AddEmployeeToConversationModel): Observable<EmployeeConversationModel> {
        const url = `${this.employeeUrl}/Add`;

        return new Observable<EmployeeConversationModel>(observer => {
            Axios.put<EmployeeConversationModel>(url, model, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        this.employeeConversationsStore.updateConversation(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public removeEmployeeToConversationModel(model: RemoveEmployeeFromConversationModel): Observable<EmployeeConversationModel> {
        const url = `${this.conversationsUrl}/${model.conversationId}/Employee/${model.userId}/Remove`;

        return new Observable<EmployeeConversationModel>(observer => {
            Axios.delete<EmployeeConversationModel>(url, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        this.employeeConversationsStore.resetConversation(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public createConversation(model: CreateConversationModel): Observable<EmployeeConversationModel> {
        const url = `${this.conversationsUrl}/Create`;

        return new Observable<EmployeeConversationModel>(observer => {
            Axios.post<EmployeeConversationModel>(url, model, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        if (response.data.type === ConversationType.Support) {
                            const model = {
                                conversationId: response.data.id,
                                employeeId: authQuery.getEmployeeId(),
                            };

                            conversationsService.addEmployeeToConversationModel(model).subscribe(
                                (conversation) => {
                                    this.employeeConversationsStore.addConversation(conversation);
                                    this.selectConversation(conversation);
                                    observer.next(conversation);
                                    observer.complete();
                                },
                                error => {
                                    snackService.commonErrorHandler(error);
                                    observer.error(error);
                                    observer.complete();
                                }
                            );
                        } else {
                            this.employeeConversationsStore.addConversation(response.data);
                            this.selectConversation(response.data);
                            observer.next(response.data);
                            observer.complete();
                        }
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public createInternalConversation(model: CreateInternalConversationModel): Observable<EmployeeConversationModel> {
        const url = `${this.conversationsUrl}/Create/Internal`;

        return new Observable<EmployeeConversationModel>(observer => {
            Axios.post<EmployeeConversationModel>(url, model, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        this.employeeConversationsStore.addConversation(response.data);
                        this.selectConversation(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public signOffConversation(conversationId: number): Observable<EmployeeConversationModel> {
        const url = `${this.employeeUrl}/SignOff/${conversationId}`;

        return new Observable<EmployeeConversationModel>(observer => {
            Axios.put<EmployeeConversationModel>(url, {}, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        this.employeeConversationsStore.updateConversation(response.data);
                        this.selectConversation(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getConversationsSettings(): Observable<MessageSettingsModel> {
        const url = `${this.employeeUrl}/Settings`;

        return new Observable<MessageSettingsModel>(observer => {
            Axios.get<MessageSettingsModel>(url, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        this.employeeConversationsStore.update({messageSettings: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public updateConversationsSettings(model: UpdateConversationsSettingsModel): Observable<MessageSettingsModel> {
        const url = `${this.employeeUrl}/Settings`;

        return new Observable<MessageSettingsModel>(observer => {
            Axios.put<MessageSettingsModel>(url, model, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        this.employeeConversationsStore.update({messageSettings: response.data});
                        snackService.success('Your settings have been successfully saved. However, all conversation updates are currently running in the background and make take a few minutes to complete. Thanks for your patience.');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public createMediaUrl(model: CreateMediaUrlModel) {

        const url = `${this.mediaUrl}/DownloadLink`;

        return new Observable(observer => {
            Axios.post(url, model, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        })
    }

    /**
     * Filtered patient for create message in Admin Tools
     */

    public getFilteredPatients(selectedFilter?: string, selectedOpt?: string | number): Observable<number> {
        const url = `${this.adminTools}/FilteredPatients?${selectedFilter}=${selectedOpt}`;

        return new Observable<number>(observer => {
            Axios.get<number>(url, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    /**
     * Returns all plans and tags
     */

    public getPlansAndTags(): Observable<any> {
        const url = `${this.adminTools}/GetPlansAndTags`;

        return new Observable<any>(observer => {
            Axios.get<any>(url, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    /**
     * Returns all messages for Admin Tools
     */
    getAll(
        page: number,
        pageSize: number,
        searchQuery: string = null,
    ): Observable<AdminPanelMessageModel[]> {
        const config = {headers: authHeader()};
        return new Observable<AdminPanelMessageModel[]>(observer => {

            Axios.get<PaginationModel<AdminPanelMessageModel>>(`${this.adminTools}/GetPage?` +
                `searchQuery=${searchQuery}&` +
                `pageSize=${pageSize}&` +
                `page=${page}`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.employeeConversationsStore.update({
                            messagesAdminTools: response.data.data,
                            messagesTotalCount: response.data.totalCount,
                        });
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Returns all forwarding messages for Admin Tools
     */
    getForwardingMessages(
        page: number,
        pageSize: number,
        searchQuery: string = null,
        sortingDirection: string = '',
    ): Observable<AdminPanelMessageForwardingModel[]> {
        const config = {headers: authHeader()};
        return new Observable<AdminPanelMessageForwardingModel[]>(observer => {

            Axios.get<PaginationModel<AdminPanelMessageForwardingModel>>(`${this.adminTools}/GetPageForwarding?` +
                `searchQuery=${searchQuery}&` +
                `pageSize=${pageSize}&` +
                `sortingDirection=${sortingDirection}&` +
                `page=${page}`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.employeeConversationsStore.update({
                            messagesForwardingAdminTools: response.data.data,
                            messagesTotalCount: response.data.totalCount,
                        });
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Create message in Admin Tools
     */

    public createMessage(model: CreateMessageModel): Observable<AdminPanelMessageModel> {
        const url = `${this.adminTools}/CreateMessage`;

        return new Observable<AdminPanelMessageModel>(observer => {
            Axios.post<AdminPanelMessageModel>(url, model, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        snackService.success(`Message sended!`);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public downloadAllMessages(conversation: ConversationModelBase,messages: ConversationModelBaseView[],
                               conversationType: StaffOrHealthCoachMessagesEnum) {
        const title = `Export Messages`

        const message = `Do you want to export all messages to a PDF document?`

        confirmService.confirm(
            title,
            message
        ).subscribe(
             () => this.createPdfWithAllMessages(conversation, messages, conversationType)
        );
    }

    public async createPdfWithAllMessages(conversation:ConversationModelBase,messages: ConversationModelBaseView[], conversationType:StaffOrHealthCoachMessagesEnum) {

        const pdf = pdfServices.downloadPdfFor(conversation, messages, conversationType);
        const today = moment().format("YYYY-MM-DDTHH-mm-ss")
        pdf.save(`WH_Conversation_log_${today}`)
    }

    public acceptAiMessage(model: AcceptInteractionModel) {

        const url = `${this.conversationsUrl}/Interactions/Accept`;

        return new Observable(observer => {
            Axios.post(url, model, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        })
    }

    public editAiMessage(model: AcceptInteractionModel) {

        const url = `${this.conversationsUrl}/Interactions/Edit`;

        return new Observable(observer => {
            Axios.post(url, model, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        })
    }

    public rejectAiMessage(model: RejectInteractionModel) {

        const url = `${this.conversationsUrl}/Interactions/Reject`;

        return new Observable(observer => {
            Axios.post(url, model, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        })
    }

    public removeMessage(model: DeleteMessageModel): Observable<any> {
        const url = `${this.conversationsUrl}/Messages`;

        return new Observable<EmployeeConversationModel>(observer => {
            Axios.delete(url, { headers: authHeader(), data: model }).pipe()
                .subscribe(
                    response => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public addFavoriteConversation(conversationId: number): Observable<EmployeeConversationModel> {
        const url = `${this.employeeUrl}/AddFavorite/${conversationId}`;

        return new Observable<EmployeeConversationModel>(observer => {
            Axios.put<EmployeeConversationModel>(url, {}, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        this.employeeConversationsStore.updateConversation(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public removeFavoriteConversation(conversationId: number): Observable<EmployeeConversationModel> {
        const url = `${this.employeeUrl}/RemoveFavorite/${conversationId}`;

        return new Observable<EmployeeConversationModel>(observer => {
            Axios.put<EmployeeConversationModel>(url, {}, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        this.employeeConversationsStore.updateConversation(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }
    public sendMessageToAiDemo(model: AiDemoModel): Observable<TextCompletionResponseModel> {
        const url = `${this.aiUrl}/Demo`;

        return new Observable<TextCompletionResponseModel>(observer => {
            Axios.post<TextCompletionResponseModel>(url, model, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public reactOnAlert(model: ReactOnConversationMessageAlertModel) {

        const url = `${this.conversationsUrl}/Alert/React`;

        return new Observable(observer => {
            Axios.post(url, model, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        })
    }

    public updateSubject(model: UpdateConversationSubject): Observable<ConversationModelBase> {
        const url = `${this.conversationsUrl}/Subject`;

        return new Observable(observer => {
            Axios.post(url, model, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        this.employeeConversationsStore.updateConversation(response.data)
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        })
    }

    public submitReaction(model: AddReactionModel): Observable<void> {
        const url = `${this.conversationsUrl}/Reactions`;

        return new Observable(observer => {
            Axios.post(url, model, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        })
    }

    public removeReaction(id: string, conversationId: string, messageId: string): Observable<void> {
        const url = `${this.conversationsUrl}/Reactions/${id}?conversationId=${conversationId}&messageId=${messageId}`;

        return new Observable(observer => {
            Axios.delete(url, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        })
    }
}

export const conversationsService = new ConversationsService(
    patientConversationsStore,
    employeeConversationsStore,
);