import React from 'react';
import { AuthenticatedLayoutComponent } from '../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from "../../../../models/menu.models";
import { ManageBannersComponent } from '../../components/manageBannersComponent/ManageBannersComponent';

export const ManageBannersPage: React.FC = () => {
    return (
        <AuthenticatedLayoutComponent
            hideBackground
            title={TitlesService.bannersTitle}
            pageName={PagesNamesService.banners}
            selectedMenuItem={MenuItemTypes.Banners}
        >
            <ManageBannersComponent />
        </AuthenticatedLayoutComponent>
    )
}
