import { Button, createStyles, makeStyles, Theme, withStyles } from "@material-ui/core";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minWidth: 100,
            maxWidth: 'calc(21 * 58px)',
            backgroundColor: colors.white,
            borderRadius: 3,
            margin: '0 auto',
        },
        toolContainer: {
            height: 75,
        },
        toolButton: {
            border: '0px !important',
        },
        toolActions: {
            display: "flex",
            minWidth: 'max-content',
            [theme.breakpoints.down('md')]: {
                display: 'none',
            },
        },
        toolActionsPad: {
            display: 'none',
            [theme.breakpoints.between('sm', 'md')]: {
                display: 'block',
            },
        },
        toolActionsMobile: {
            width: 170,
            display: 'none',
            [theme.breakpoints.down('xs')]: {
                display: 'block',
            },
        },
        reportSelectRoot: {
            color: colors.main,
            border: 'none',
            borderColor: colors.white,
            '& .MuiOutlinedInput-root': {
                border: 'none',
            },
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
            },
            '& .MuiSelect-icon': {
                color: colors.main,
            },
        },
        status: {
            justifyContent: "space-between",
            display: "flex",
        },
        navigationContainer: {
            height: 44,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: 'flex-start',
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        navigationBubble: {
            height: 120,
        },
        stepsContainer: {
            overflowY: 'auto',
            maxWidth: 'calc(21 * 58px - 128px)',
        },
        navigationButton: {
            maxHeight: 34,
            color: colors.main,
            borderRadius: 0,
        },
        stepButton: {
            borderRadius: 0,
            minWidth: 168,
            color: colors.main,
            width: 'max-content',
            border: 'none !important',
        },
        stepButtonSelected: {
            backgroundColor: '#eee',
        },
        reportPagePopover: {
            marginTop: 8
        },
        stepMobileButton: {
            borderRadius: 0,
            color: colors.black,
            border: 'none !important',
        },
        boxCircle: {
            width: 6,
            height: 6,
            borderRadius: '50%',
        },
        disabledBtn: {
            color: `${colors.gray2} !important`,
        },
        infoWrapper: {
            backgroundColor: colors.bg,
            paddingBottom: 20,
        },
        infoContainer: {
            backgroundColor: colors.info,
            height: 44,
            display: 'flex',
            alignItems: 'center',
            borderRadius: 2,
            paddingLeft: 20,
            paddingRight: 20,
        },
        rotateIcon: {
            transform: 'rotate(330deg)',
            width: 20,
        },
        iconSize: {
            width: 20,
            height: 20,
        },
        navigationMobileContainer: {
            height: 44,
            display: 'none',
            [theme.breakpoints.down('xs')]: {
                display: 'block',
            },
        },
        mobileDownloadSection: {
            display: 'none',
            backgroundColor: "transparent",
            width: "100%",
            [theme.breakpoints.down('xs')]: {
                display: 'block',
            },
        },
        fixedSection: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            backgroundColor: colors.black,
            borderRadius: 2
        },
        fixedCloseButton: {
            padding: 8,
            marginLeft: 4,
        },
        downloadIcon: {
            width: 20,
            height: 20,
            fill: colors.white
        }
    }),
);

export const ActionsButton = withStyles((theme: Theme) => ({
    root: {
        width: 40,
        height: 40,
        minWidth: 40,
        color: colors.main,
        backgroundColor: colors.stroke,
        borderRadius: 0,
        marginLeft: 8,

        '&:hover': {
            background: colors.stroke,
            color: colors.mainDark,
        },
        '&:disabled': {
            color: colors.gray2,
        },
    },
    
}))(Button);