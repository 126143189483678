import Axios from 'axios-observable';
import {Observable, Subject} from 'rxjs';
import {authHeader} from '../../common/helpers/auth-header';
import {snackService} from '../../common/snack/state';
import {GoalModel, GoalCommonModel, GoalCategory, InterventionModel} from '../models/notes.models';
import {NotesStore, notesStore} from '../stores/notes';
import {PaginationModel} from "../../common/pagination/models/pagination-models";
import {CallbackModel} from "../../common/models/callback.model";

/**
 * Provides method for working with notes and identity
 */
export class GoalsService {
    private url = `${process.env.REACT_APP_API_URL}Goals`;

    public onView = new Subject<CallbackModel<GoalModel>>();

    constructor(private store: NotesStore) {
    }

    private getConfig = () => ({headers: authHeader()});

    public openView(goal: GoalModel): Observable<void> {
        return new Observable(observer => {
            this.onView.next({
                data: goal,
                callback: () => {
                    observer.next();
                    observer.complete();
                }
            });
        });
    }

    public getCurrentGoalsByPatient(): Observable<GoalModel[]> {
        const url = `${this.url}/my/current`;

        return new Observable((observer) => {
            Axios.get<GoalModel[]>(url, this.getConfig())
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({currentGoals: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getPastGoalsByPatient(query: string, skip: number, take: number): Observable<PaginationModel<GoalModel>> {
        const url = `${this.url}/my/past?query=${query}&skip=${skip}&take=${take}`;

        return new Observable((observer) => {
            Axios.get<PaginationModel<GoalModel>>(url, this.getConfig())
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({pastGoals: response.data.data, pastGoalsTotal: response.data.totalCount});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getCurrentGoalsByEmployee(patientId: number): Observable<GoalModel[]> {
        const url = `${this.url}/current?patientId=${patientId}`;

        return new Observable((observer) => {
            Axios.get<GoalModel[]>(url, this.getConfig())
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({currentGoals: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getPastGoalsByEmployee(patientId: number, query: string, skip: number, take: number): Observable<PaginationModel<GoalModel>> {
        const url = `${this.url}/past?patientId=${patientId}&query=${query}&skip=${skip}&take=${take}`;

        return new Observable((observer) => {
            Axios.get<PaginationModel<GoalModel>>(url, this.getConfig())
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({pastGoals: response.data.data, pastGoalsTotal: response.data.totalCount});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getAllCommonGoals(): Observable<GoalCommonModel[]> {
        const url = `${this.url}/Common`;

        return new Observable((observer) => {
            Axios.get<GoalCommonModel[]>(url, this.getConfig())
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({commonGoals: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getCommonGoalsByCategory(category: GoalCategory): Observable<GoalCommonModel[]> {
        const url = `${this.url}/Common/${category}`;

        return new Observable((observer) => {
            Axios.get<GoalCommonModel[]>(url, this.getConfig())
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getCommonGoalById(id: number): Observable<GoalCommonModel> {
        const url = `${this.url}/Common/Goal/${id}`;
    
        return new Observable(observer => {
            Axios.get<GoalCommonModel>(url, this.getConfig())
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({ targetCommonGoal: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.error();
                    }
                );
        })
    }

    public createCommonGoal(model: GoalCommonModel): Observable<GoalCommonModel> {
        const url = `${this.url}/Common/Goal`;
    
        return new Observable(observer => {
            Axios.post<GoalCommonModel>(url, model, this.getConfig())
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.addCommonGoal(response.data);
                        snackService.success("Common Goal successfully created!")
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.error();
                    }
                );
        })
    }

    public updateCommonGoal(model: GoalCommonModel): Observable<GoalCommonModel> {
        const url = `${this.url}/Common/UpdateGoal`;
    
        return new Observable(observer => {
            Axios.put<GoalCommonModel>(url, model, this.getConfig())
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.updateCommonGoal(response.data);
                        snackService.success("Common Goal successfully updated!")
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.error();
                    }
                );
        })
    }

    public createIntervention(model: InterventionModel): Observable<InterventionModel> {
        const url = `${this.url}/Common/Intervention`;
    
        return new Observable(observer => {
            Axios.post<InterventionModel>(url, model, this.getConfig())
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.addIntervention(response.data);
                        snackService.success("Intervention successfully created!")
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.error();
                    }
                );
        })
    }

    public updateIntervention(model: InterventionModel): Observable<InterventionModel> {
        const url = `${this.url}/Common/UpdateIntervention`;
    
        return new Observable(observer => {
            Axios.post<InterventionModel>(url, model, this.getConfig())
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.updateIntervention(response.data);
                        snackService.success("Intervention successfully updated!")
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.error();
                    }
                );
        })
    }

    public deleteCommonGoal(id: number) {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.delete(`${this.url}/Common/${id}`, config)
                .pipe()
                .subscribe(
                    () => {
                        this.store.removeCommonGoal(id);
                        snackService.success('Common Goal successfully deleted');
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    public deleteIntervention(goalId: number, interventionId: number) {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.delete(`${this.url}/Common/${goalId}/Interventions/${interventionId}`, config)
                .pipe()
                .subscribe(
                    () => {
                        this.store.removeIntervention(interventionId);
                        snackService.success('Intervention successfully deleted');
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }
}

export const goalsService = new GoalsService(notesStore);
