import React from 'react';
import { Box, Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { SelectAddOnModel } from '../../models/addOns.models';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import commonUseStyles from '../../../common/styles/common.styles';
import { colors } from '../../../common/constants/colors';
import clsx from 'clsx';

interface SelectAddOnComponentProps {
    key?: number;
    addOn: SelectAddOnModel;
    handleSelectingItem: (id: number, value: boolean) => void;
}

export const SelectAddOnComponent: React.FC<SelectAddOnComponentProps> = (props: SelectAddOnComponentProps) => {
    const { key, addOn, handleSelectingItem } = props;

    const commonClasses = commonUseStyles();

    const handleChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleSelectingItem(addOn.id, event.target.checked)
    }

    const label = <Box component="div" fontWeight={400}>{addOn.name}</Box>;
    const controlIcon = <CheckBoxOutlineBlankIcon style={{fill: colors.main}} />;
    const controlCheckedIcon = <CheckBoxIcon style={{fill: colors.main}} />;
    const control = (
        <Checkbox
            icon={controlIcon}
            color="primary"
            checkedIcon={controlCheckedIcon}
            name={`AddOn${key}-CheckBox`}
            checked={addOn.isSelected}
            onChange={handleChanges}
        />
    );

    return (
        <Box key={key} mt={2}>
            <Grid
                container
                spacing={5}
                justify="space-between"
            >
                <Grid item xs={9} md={9} lg={9}>
                    <FormControlLabel control={control} label={label}/>
                </Grid>
                <Grid item xs={3} md={3} lg={3} className={commonClasses.textMedium}>
                    <Box mr={2} mt={1} textAlign="right">
                        ${addOn.price}
                    </Box>
                </Grid>
            </Grid>
            <Box className={clsx(commonClasses.colorGray1, commonClasses.size14)}>
                {addOn.description}
            </Box>
        </Box>
    );
}
