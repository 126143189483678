import React, { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { onEmit } from "../../../common/helpers/on-emit";
import { PageSliderNavigationContext } from "../../../common/models/navigation.models";
import { FinishBuySubscriptionComponent } from "../../components/finishBuySubscriptionComponent/FinishBuySubscriptionComponent";
import { SelectPaymentPeriodComponent } from "../../components/selectPaymentPeriodComponent/SelectPaymentPeriodComponent";
import { PaymentPeriodModel } from "../../models/paymentPeriod.models";
import { PaymentPlanModel } from "../../models/paymentPlan.models";
import { paymentPlansQuery, PaymentSteps } from "../../stores/paymentPlansStore";
import {paymentPlansService} from "../../services/paymentPlans.service";
import {navigationService} from "../../../../services/navigation.service";
import {authQuery} from "../../../auth/stores/auth";
import {useFlags} from "launchdarkly-react-client-sdk";
import {getValueFeatureFlag} from "../../../common/components/featureFlags/featureFlags";
import {FeatureFlag} from "../../../common/components/featureFlags/featureFlags.models";
import {SelectPaymentPlanComponent} from "../../components/selectPaymentPlanComponent/SelectPaymentPlanComponent";

interface BuyNewSubscriptionState {
    plans: PaymentPlanModel[];
    selectedPlan: PaymentPlanModel | null;
    selectedPeriod: PaymentPeriodModel | null;
    step: PaymentSteps;
    isLoading: boolean;
}

/**
 * Custom Hook to manage a view Model for BuyNewSubscription page view components
 */
export function useFacade(defaultPlanId: number | null, defaultPeriodId: number | null): [BuyNewSubscriptionState, JSX.Element] {
    const featureFlags = useFlags();
    const defaultPlan = getValueFeatureFlag(featureFlags, FeatureFlag.DefaultPlan);
    const [state, setState] = useState({
        plans: [],
        selectedPlan: null,
        selectedPeriod: null,
        step: PaymentSteps.SelectPlan,
        isLoading: true
    } as BuyNewSubscriptionState);

    const canGoBack = () => state.step > PaymentSteps.SelectPlan;

    const canGoNext = () => {
        switch (state.step) {
            case PaymentSteps.SelectPlan: return state.selectedPlan;
            case PaymentSteps.SelectPeriod: return state.selectedPeriod
        }
    }
    const handleNext = () => {
        switch (state.step) {
            case PaymentSteps.SelectPlan: return setState(state => ({...state, step: PaymentSteps.SelectPeriod}));
            case PaymentSteps.SelectPeriod: return setState(state => ({...state, step: PaymentSteps.Checkout}));
        }
    };

    const goBackFromCheckoutPage = () => {
        const step = state.selectedPlan.periods.length === 1
            ? PaymentSteps.SelectPlan
            : PaymentSteps.SelectPeriod;

        setState(state => ({
            ...state,
            step: step
        }));
    }

    const handleBack = () => {
        switch (state.step) {
            case PaymentSteps.SelectPeriod: return setState(state => ({...state, step: PaymentSteps.SelectPlan}));
            case PaymentSteps.Checkout: return goBackFromCheckoutPage();
        }
    };

    const navigationContext = {
        nextButton: "Next",
        backButton: "Back",
        canGoNext: canGoNext,
        canGoBack: canGoBack,
        goNext: handleNext,
        goBack: handleBack
    } as PageSliderNavigationContext

    const PaymentStepsContent = new Map<number, JSX.Element>([
        [PaymentSteps.SelectPlan, <SelectPaymentPlanComponent defaultPlan={defaultPlan} plans={state.plans} navigationContext={navigationContext}/>],
        [PaymentSteps.SelectPeriod, <SelectPaymentPeriodComponent navigationContext={navigationContext}/>],
        [PaymentSteps.Checkout, <FinishBuySubscriptionComponent navigationContext={navigationContext} defaultPlan={defaultPlan} plans={state.plans}/>]
    ]);

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<PaymentPlanModel[]>(paymentPlansQuery.regularPlans$, plans => {
                setState(state => ({
                    ...state,
                    plans: plans
                }));

                if (defaultPlanId) {
                    const defaultPlan = plans?.find(x => x.id === defaultPlanId);
                    if (defaultPlan) {
                        paymentPlansService.selectPlan(defaultPlan);

                        if (defaultPeriodId) {
                            const defaultPeriod = defaultPlan.periods?.find(x => x.id === defaultPeriodId);
                            if (defaultPeriod) {
                                paymentPlansService.selectPeriod(defaultPeriod);
                            }
                        }
                    }
                }
            }),
            onEmit<PaymentPlanModel>(paymentPlansQuery.selectedPlan$, selectedPlan => {
                if (selectedPlan) {
                    setState(state => ({
                        ...state,
                        selectedPlan: selectedPlan,
                        step: PaymentSteps.SelectPeriod
                    }));
                } else {
                    setState(state => ({
                        ...state, selectedPlan: selectedPlan
                    }));
                }
            }),
            onEmit<PaymentPeriodModel>(paymentPlansQuery.selectedPeriod$, selectedPeriod => {
                if (selectedPeriod) {
                    setState(state => ({
                        ...state,
                        selectedPeriod: selectedPeriod,
                        step: PaymentSteps.Checkout
                    }));
                } else {
                    setState(state => ({
                        ...state,
                        selectedPeriod: selectedPeriod
                    }));
                }
            }),
        ];

        const cb = () => setState(state => ({ ...state, isLoading: false }))

        paymentPlansService.getActive(authQuery.getCurrentPracticeId()).subscribe(cb, cb);

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [defaultPeriodId, defaultPlanId]);

    useEffect(() => {
        navigationService.goUp();
    }, [state.step])

    return [state, PaymentStepsContent.get(state.step)];
}
