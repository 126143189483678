import React from 'react';
import { onEmit } from "../../../common/helpers/on-emit";
import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import {
  PatientCourseStatus
} from '../../models/courses.enums';
import { PatientCourseHistoryModel } from '../../models/courses.model';
import { coursesQuery } from '../../stores/courses';
import { coursesService } from '../../services/courses.service';
import { authQuery } from '../../../auth/stores/auth';
import { navigationService } from '../../../../services/navigation.service';
import { PatientModel } from '../../../patients/models/patient.model';
import { patientsQuery } from '../../../patients/stores/patientsStore';
import { PatientConversationModel } from '../../../conversations/models/conversation.models';
import { patientConversationsQuery } from '../../../conversations/stores/patientConversationsStore/patientConversations.query';

interface CourseFilterStatusViewModel {
  status: number;
  title: string;
  isSelected: boolean;
}

export interface CourseHistoryFilterModel {
  notStartedStatus: CourseFilterStatusViewModel;
  inProgressStatus: CourseFilterStatusViewModel;
  completedStatus: CourseFilterStatusViewModel;
}

interface CourseHistoryFiltersState {
  isOpen: boolean;
  anchorEl: HTMLButtonElement;
  filters: CourseHistoryFilterModel;
}

interface PatientCourseHistoryComponentState {
  histories: PatientCourseHistoryModel;
  isLoading: boolean;
  filtersState: CourseHistoryFiltersState;
}

export function useFacade(patientId: number | null): [
  PatientCourseHistoryComponentState,
  (event?: React.MouseEvent<HTMLButtonElement>) => void,
  (toSelect: boolean) => void,
  (toSelect: boolean) => void,
  (toSelect: boolean) => void,
  () => void,
  () => void,
  () => void
] {
  const [state, setState] = useState({
    histories: null,
    isLoading: true,
    filtersState: {
      isOpen: false,
      anchorEl: null,
      filters: {
        notStartedStatus: {
          status: PatientCourseStatus.NotStarted,
          title: 'Not Started',
          isSelected: false,
        },
        inProgressStatus: {
          status: PatientCourseStatus.InProgress,
          title: 'In Progress',
          isSelected: false,
        },
        completedStatus: {
          status: PatientCourseStatus.Completed,
          title: 'Completed',
          isSelected: false,
        }
      },
    },
  } as PatientCourseHistoryComponentState);

  const handleGoToNorthPassAcademy = () => {
    navigationService.toNewTabNorthPassAcademy();
  }

  const handleToggleFilters = (event?: React.MouseEvent<HTMLButtonElement>) => {
    const anchorEl = event && event.currentTarget ? event.currentTarget : null;
    state.filtersState.isOpen = !state.filtersState.isOpen;
    state.filtersState.anchorEl = anchorEl;
    setState(state => ({ ...state }));
  }

  const handleOnNotStartedStatusChanges = (toSelect: boolean) => {
    const item = state.filtersState.filters.notStartedStatus;
    if (item) {
      item.isSelected = toSelect;
      setState(state => ({ ...state }));
    }
  }

  const handleOnInProgressStatusChanges = (toSelect: boolean) => {
    const item = state.filtersState.filters.inProgressStatus;
    if (item) {
      item.isSelected = toSelect;
      setState(state => ({ ...state }));
    }
  }

  const handleOnCompletedStatusChanges = (toSelect: boolean) => {
    const item = state.filtersState.filters.completedStatus;
    if (item) {
      item.isSelected = toSelect;
      setState(state => ({ ...state }));
    }
  }

  const handleClearFilters = () => {
    state.filtersState.filters.notStartedStatus.isSelected = false;
    state.filtersState.filters.inProgressStatus.isSelected = false;
    state.filtersState.filters.completedStatus.isSelected = false;
    setState(state => ({ ...state }));
  }

  const setHistories = (histories: PatientCourseHistoryModel) => {
    const anchorEl = null;
    state.filtersState.isOpen = !state.filtersState.isOpen;
    state.filtersState.anchorEl = anchorEl;
    setState(state => ({
      ...state,
      isLoading: false,
      histories: histories,
    }));
  }

  const handleApplyFilters = () => {
    const notStartedStatus = state.filtersState.filters.notStartedStatus;
    const inProgressStatus = state.filtersState.filters.inProgressStatus;
    const completedStatus = state.filtersState.filters.completedStatus;
    if (!patientId) return;
    setState(state => ({
      ...state,
      isLoading: true
    }));
    const cb = () => {
      const anchorEl = null;
      state.filtersState.isOpen = !state.filtersState.isOpen;
      state.filtersState.anchorEl = anchorEl;
      setState(state => ({
        ...state,
        isLoading: false
      }));
    }
    if (patientId) {
      coursesService.getPatientCourseHistoriesByPatientId(patientId).subscribe(cb, cb)
    } else {
      coursesService.getPatientCourseHistoriesByUserId(authQuery.getId()).subscribe(cb, cb)
    }
    

    // coursesService.getPatientCourseHistoriesById(authQuery.getId()).subscribe((histories) => {
    //   const filteredHistories = (notStartedStatus || inProgressStatus || completedStatus) ? histories?.courses.filter(
    //     (c) =>
    //       notStartedStatus && c.status === PatientCourseStatus.NotStarted
    //       || inProgressStatus && c.status === PatientCourseStatus.InProgress
    //       || completedStatus && c.status === PatientCourseStatus.Completed
    //   ) : histories;
    //   setHistories(filteredHistories)
    // }, cb)
  }

  const useEffectCB = () => {
    const cb = () => {
      setState(state => ({
        ...state,
        isLoading: false
      }));
    }
    const subscriptions: Subscription[] = [
      onEmit<PatientCourseHistoryModel>(coursesQuery.patientCourseHistories$, histories => {
        setState(state => ({ ...state, histories: histories }));
      }),
    ];
    
    if (!patientId) {
      coursesService.getPatientCourseHistoriesByUserId(authQuery.getId()).subscribe(cb, cb);
    } else {
      coursesService.getPatientCourseHistoriesByPatientId(patientId).subscribe(cb, cb);
    }

    return () => {
      subscriptions.map(it => it.unsubscribe())
    };
  }

  useEffect(useEffectCB, [patientId]);

  return [
    state,
    handleToggleFilters,
    handleOnNotStartedStatusChanges,
    handleOnInProgressStatusChanges,
    handleOnCompletedStatusChanges,
    handleClearFilters,
    handleApplyFilters,
    handleGoToNorthPassAcademy
  ];
}