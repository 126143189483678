import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { confirmService } from "../../../../services/confirm.service";
import { onEmit } from "../../../common/helpers/on-emit";
import { FileInputModel, FileInputType } from "../../models/input.models";
import { inputsService } from "../../services/inputs.service";
import { patientInputsService } from "../../services/patientInputs.service";
import { inputsQuery } from "../../stores/inputsStore";
import { TutorialStatusModel } from "../../../../models/application.models";
import { applicationQuery } from "../../../../stores/applicationStore";
import { applicationService } from "../../../../services/application.service";
import { MenuItemTypes } from "../../../../models/menu.models";
import { authQuery } from "../../../auth/stores/auth";
import { UserType } from "../../../auth/models/auth.enums";

interface UploadInputsFilesComponentState {
    isDownloading: boolean;
    isUploading: boolean;
    isActionsOpen: boolean;
    isDialogOpened: boolean;
    actionsAnchorEl: HTMLButtonElement;
    selectedFile: FileInputModel;
    files: FileInputModel[];
    tutorialStatus: TutorialStatusModel;
}

export function useFacade(patientId: number, fileType: FileInputType): [
    UploadInputsFilesComponentState,
    (fileId?: number, target?: HTMLButtonElement) => void,
    (type: FileInputType) => void,
    (fileId: number) => void,
    (fileId: number, fileName: string) => void,
    (fileId: number) => void,
    () => void
] {
    const [state, setState] = useState({
        isDownloading: false,
        isUploading: false,
        isActionsOpen: false,
        isDialogOpened: false,
        actionsAnchorEl: null,
        selectedFile: null,
        files: [],
        tutorialStatus: null
    } as UploadInputsFilesComponentState);

    const handleToggleActions = (fileId?: number, target?: HTMLButtonElement) => {
        setState(state => ({
            ...state,
            actionsAnchorEl: target,
            isActionsOpen: !state.isActionsOpen,
            selectedFile: state.files.find(i => i.id === fileId),
        }));
    }

    const handleFileUpload = (type: FileInputType) => {
        setState(state => ({
            ...state,
            isUploading: true
        }));

        const cb = () => {
            setState(state => ({
                ...state,
                isUploading: false
            }));
        }

        const action = patientId ? inputsService.upload(type, patientId) : patientInputsService.upload(type);

        action.subscribe(() => {
            cb();

            if (type === FileInputType.LabResults) {
                inputsService.getLabInputs(patientId).subscribe();
            }
        }, cb);
    }

    const handleDownload = (fileId: number) => {
        setState(state => ({
            ...state,
            isDownloading: true,
            isActionsOpen: false,
        }));

        const cb = () => {
            setState(state => ({
                ...state,
                isDownloading: false,
            }));
        }

        const input = state.files.find(i => i.id === fileId);
        if (input) {
            setState(state => ({
                ...state,
                selectedFile: input,
            }));

            const action = patientId ? inputsService.download(input, patientId) : patientInputsService.download(input);
            action.subscribe(cb, cb);
        }
    }

    const handleView = (fileId: number) => {

        const cb = () => {
            setState(state => ({
                ...state,
                isActionsOpen: false,
            }));
        }

        const input = state.files.find(i => i.id === fileId);
        if (input) {
            setState(state => ({
                ...state,
                selectedFile: input,
            }));

            const action = patientId ? inputsService.viewinline(input, patientId) : patientInputsService.viewinline(input);
            action.subscribe(cb, cb);
        }
    }

    const handleDelete = (fileId: number, fileName: string) => {
        confirmService.confirm(`Are you sure you want to delete this file: ${fileName}?`).subscribe(() => {
            inputsService.deleteUpload(fileId, patientId);
            setState(state => ({
                ...state,
                isActionsOpen: false
            }));
        })
    }

    const handleToggleDialog = () => {
        setState({
            ...state,
            isDialogOpened: !state.isDialogOpened,
        });
        if (state.tutorialStatus && !state.tutorialStatus?.isAcknowledged) {
            applicationService.closeTutorial(state.tutorialStatus.id).subscribe()
        }
    }

    /**
     * Loads all uploads
     * Manage subscriptions with auto-cleanup
     */
    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<FileInputModel[]>(inputsQuery.uploads$, uploads => {
                const files = uploads.filter(x => x.type === fileType);

                setState(state => ({
                    ...state,
                    files: files,
                }));
            }),
            onEmit<TutorialStatusModel>(applicationQuery.tutorialStatus$, tutorialStatus => {
                if (tutorialStatus?.tutorialName === `${MenuItemTypes.DataFilesInputs}-${fileType}-tutorial`) {
                    setState(state => ({ ...state, tutorialStatus, isDialogOpened: !tutorialStatus.isAcknowledged}))
                }
            })
        ];

        if (fileType === FileInputType.MicrobiomeData && authQuery.getType() === UserType.Patient) {
            applicationService.checkFirstVisit(`${MenuItemTypes.DataFilesInputs}-${fileType}-tutorial`).subscribe()
        }

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [patientId, fileType]);

    return [
        state,
        handleToggleActions,
        handleFileUpload,
        handleDownload,
        handleDelete,
        handleView,
        handleToggleDialog
    ];
}