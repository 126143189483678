import { Query } from '@datorama/akita';
import { ApplicationInformationModel } from "../../models/application.models";
import {
    ApplicationState,
    applicationStore,
    ApplicationStore
} from './application.store';

/**
 * Provides method for working with application store
 */
export class ApplicationQuery extends Query<ApplicationState> {
    information$ = this.select(state => state.information);
    isSideBarOpen$ = this.select(state => state.isSideBarOpen);
    menuItems$ = this.select(state => state.menuItems);
    banners$ = this.select(state => state.banners);
    tutorialStatus$ = this.select(state => state.tutorialStatus);
    authorizationProviders$ = this.select(state => state.authorizationProviders);

    constructor(protected store: ApplicationStore) {
        super(store);
    }

    public getInformation(): ApplicationInformationModel {
        return this.getValue().information;
    }

    public getIsSideBarOpen(): boolean {
        return this.getValue().isSideBarOpen;
    }
}

export const applicationQuery = new ApplicationQuery(applicationStore);
