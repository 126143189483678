import React from 'react';
import {Box, Table, TableBody, TableContainer} from '@material-ui/core';
import {DnaModel, DnaImportance} from "../../models/healthReport.models";
import { WildHealthTableCell } from '../../../common/components/wildHealthTable/WildHealthTableCell';
import { WildHealthTableRow } from '../../../common/components/wildHealthTable/WildHealthTableRow';
import redFlag from "@img/SNPS_RedTriangle.png";
import yellowFlag from "@img/SNPS_YellowTriangle.png";


interface DnaTableComponentProps {
    dna: DnaModel[];
    colorMap: { [id: string]: string; };
}

export const DnaTableComponent: React.FC<DnaTableComponentProps> = (props: DnaTableComponentProps) => {
    const {dna, colorMap} = props;

    const getFlag = (importance: DnaImportance) => {
        if(importance === DnaImportance.NotImportant) {
            return(<></>)
        }

        return (
            <Box ml={0.5}>
                <img src={importance === DnaImportance.HighImportance ? redFlag : yellowFlag} style={{width: "1em", height: "1.5em"}} alt="img"/>
            </Box>
        )
    }

    return(
        <>
            <Box width="100%">
                <TableContainer>
                    <Table aria-label="customized table" size="small">
                        <TableBody>
                            {dna.map((dna, i) => (
                                <WildHealthTableRow key={i} style={{height: "1.8em"}}>
                                    <WildHealthTableCell align="left" style={{padding: "0.5em", fontSize: "0.8em", paddingRight: "1em"}}>{dna.key}</WildHealthTableCell>
                                    <WildHealthTableCell style={{backgroundColor: colorMap[dna.value], width: "15%"}}></WildHealthTableCell>
                                    <WildHealthTableCell style={{padding: "0px", backgroundColor: "white", borderStyle: "none"}}>
                                        {
                                            getFlag(dna.importance)
                                        }
                                    </WildHealthTableCell>
                                </WildHealthTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </>
    );
}
