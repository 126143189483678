import {NotificationModel, NotificationType} from '../../models/notifications.models';
import { NotificationsState, NotificationsStore, notificationsStore } from './notifications.store';
import { Query } from '@datorama/akita';
import {handleCompare} from "../../../common/sorting/helpers/handle-compare";
import {SortingDirection} from "../../../common/sorting/models/sorting-destination";

/**
 * Provides method for working with notifications store
 */
export class NotificationsQuery extends Query<NotificationsState> {
    notifications$ = this.select(state => state.notifications);
    alerts$ = this.select(state => state.alerts);
    alertsHistory$ = this.select(state => state.alertsHistory);
    alertsHistoryTotalCount$ = this.select(state => state.alertsHistoryTotalCount);

    constructor(protected store: NotificationsStore) {
        super(store);
    }

    public getNotificationsByType(type: NotificationType): NotificationModel[] {
        return this.getValue().notifications.filter(x => x.type === type);
    }

    public getNotificationsByTypes(types: NotificationType[]): NotificationModel[] {
        return this.getValue().notifications.filter(x => types.includes(x.type));
    }

    public getLastNotification(): NotificationModel {
        const notifications = this.getValue().notifications.sort((a, b) => handleCompare(a, b, SortingDirection.Desc, 'id'));
        return notifications[0] ?? null;
    }
}

export const notificationsQuery = new NotificationsQuery(notificationsStore);
