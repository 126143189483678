import React from "react";
import { Box, Collapse, TextField, Paper, Table, TableBody, TableContainer, TableHead, CircularProgress } from "@material-ui/core";
import { Autocomplete } from '@material-ui/lab';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import { ReactComponent as CalendarIcon } from '@img/icons/CalendarIcon.svg';
import { isIOS } from "react-device-detect";
import moment from "moment";
import { useFacade } from "./addLabDialogComponent.hooks";
import { IOSCalender } from "../../../common/components/selectDataSetDialogComponent/IOSCalender";
import { WildHealthDatePicker } from "../../../common/components/WildHealthDatePicker";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { WildHealthTableRow } from '../../../common/components/wildHealthTable/WildHealthTableRow';
import { WildHealthTableCell } from '../../../common/components/wildHealthTable/WildHealthTableCell';
import { colors } from '../../../common/constants/colors';
import { LabInputsDatasetEditableViewModel, labInputGroupNames, LabInputValueModel, LabValidationSource, LabGroup } from "../../models/input.models";
import { UpdateLabInputRangeComponent } from "../updateLabInputRangeComponent/UpdateLabInputRangeComponent";

interface AddLabDialogComponentProps {
    open: boolean;
    title: string;
    message: string;
    actionName: string;
    disableDates: Date[];
    dataSet: LabInputsDatasetEditableViewModel;
    isEditMode: boolean;
    handleSubmit: Function;
    handleClose: Function;
    handleChange: Function;
    handleRangeChanges: Function;
    handleNameChange: Function;
    handleChangeDate: Function;
    handleAddNewMiscLab: () => void;
}

export const AddLabDialogComponent: React.FC<AddLabDialogComponentProps> = (
    props: AddLabDialogComponentProps
) => {
    const {
        open,
        title,
        message,
        actionName,
        disableDates,
        dataSet,
        isEditMode,
        handleSubmit,
        handleClose,
        handleChange,
        handleRangeChanges,
        handleNameChange,
        handleChangeDate,
        handleAddNewMiscLab
    } = props;

    const [
        { isLoading, selectedDate, canSubmit, sections, filteredItems, errors },
        isDateInDisabledRange,
        handleDateChanges,
        handleCloseDialog,
        handleSubmitForm,
        handleExpand,
        handleOnFilter
    ] = useFacade(open, isEditMode, handleSubmit, handleClose, disableDates, handleChangeDate);

    const onChanges = (inputValue: LabInputValueModel,  v: string) => {
        switch (v.length) {
            case 0: return handleChange(inputValue, null);
            default: handleChange(inputValue, Number(v));
        }
    }

    const renderInput = (params) => {
        return (
            <TextField
                {...params}
                variant="outlined"
                placeholder="Lab Name or Test Code"
                onChange={(e) => handleOnFilter(e.currentTarget.value)}
                InputProps={{
                    ...params.InputProps,
                    className: 'input',
                    endAdornment: (
                        <React.Fragment>
                            {isLoading ? <CircularProgress className="wh-tw-text-primaryV" size={20} /> : null}
                        </React.Fragment>
                    ),
                }}
            />
        )
    }

    return (
        <Box p="30px">
            <Box display='flex' justifyContent='space-between'>
                <Box className="wh-tw-text-2xl wh-tw-font-medium wh-tw-font-poppins">{isEditMode ? `Edit ${title}` : `Add ${title}`}</Box>
                <Box display='flex' justifyContent='flex-end'>
                    <Box>
                        <WildHealthButton
                            id='add-lab-cancel'
                            color='tertiary'
                            size="medium"
                            onClick={() => handleCloseDialog()}
                        >
                            Cancel
                        </WildHealthButton>
                    </Box>
                    <Box ml={2}>
                        <WildHealthButton
                            id={`add-lab-submit-${actionName}`}
                            size="medium"
                            color="primaryV"
                            onClick={(e) => handleSubmitForm(e)}
                            disabled={!canSubmit}
                        >
                            {actionName}
                        </WildHealthButton>
                    </Box>
                </Box>
            </Box>
            {!isEditMode && (
                <Box>
                    {isIOS ? (
                        <IOSCalender handleDateChanges={handleDateChanges} />
                    ) : (
                        <Box py={1}>
                            <Box>
                                <span className="wh-tw-text-xxs wh-tw-font-medium wh-tw-font-beVietnamPro">
                                    {message} <span className="wh-tw-text-primaryV">*</span>
                                </span>
                            </Box>
                            <Box mt={2} width="300px">
                                <WildHealthDatePicker
                                    placeholder='MMM DD, yyyy'
                                    openTo='year'
                                    format='MMM DD, yyyy'
                                    inputProps={{ disabled: true }}
                                    inputVariant='outlined'
                                    error={!!errors["selectedDate"]}
                                    helperText={errors["selectedDate"]}
                                    value={selectedDate}
                                    onChange={(momentDate) => handleDateChanges(momentDate?.toDate())}
                                    shouldDisableDate={(day: moment.Moment) => isDateInDisabledRange(day)}
                                    useKeyboardDatePicker
                                    name='selectedDate'
                                    keyboardIcon={<CalendarIcon fill={colors.gray500}/>}
                                    fullWidth
                                />
                            </Box>
                        </Box>
                    )}
                </Box>
            )}
            <Box>
                {
                    sections.map((section, index) => {
                        const inputValues = dataSet.draftValues.filter(x => x.group === section.group)

                        return (
                            <Box py={2} key={index}>
                                <Box display="flex" justifyContent="flex-start" alignItems="stretch" mt={1}>
                                    <Box id={`lab-inputs-expand-${section.group}`} onClick={() => handleExpand(section.group)} className="wh-tw-cursor-pointer">
                                        {section.expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                                    </Box>
                                    <Box ml={1}>
                                        <span className="wh-tw-text-lg wh-tw-font-medium wh-tw-font-beVietnamPro">{labInputGroupNames[section.group]}</span>
                                    </Box>
                                </Box>
                                <Collapse in={section.expanded} timeout="auto" unmountOnExit>
                                    <Box mt={2}>
                                        <Paper>
                                            <TableContainer>
                                                <Table>
                                                    <TableHead>
                                                        <WildHealthTableRow>
                                                            <WildHealthTableCell
                                                                style={{ minWidth: 300, width: 300 }}>
                                                                <Box className="wh-tw-text-black wh-tw-text-sm wh-tw-font-semibold wh-tw-font-beVietnamPro">Biomarker</Box>
                                                            </WildHealthTableCell>
                                                            <WildHealthTableCell>
                                                                <Box className="wh-tw-text-black wh-tw-text-sm wh-tw-font-semibold wh-tw-font-beVietnamPro">Range</Box>
                                                            </WildHealthTableCell>
                                                            <WildHealthTableCell
                                                                style={{ width: '35%' }}>
                                                                <Box className="wh-tw-text-black wh-tw-text-sm wh-tw-font-semibold wh-tw-font-beVietnamPro">Result</Box>
                                                            </WildHealthTableCell>
                                                        </WildHealthTableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            inputValues.map((inputValue, index) => {
                                                                return (
                                                                    <WildHealthTableRow key={index}>
                                                                        <WildHealthTableCell>
                                                                            {inputValue.name
                                                                                ? <span>{inputValue.name}</span>
                                                                                : <Box>
                                                                                    <Autocomplete
                                                                                        size='small'
                                                                                        fullWidth
                                                                                        disableClearable
                                                                                        noOptionsText={'No result'}
                                                                                        options={filteredItems}
                                                                                        filterOptions={x => x}
                                                                                        getOptionLabel={(option) => option.name}
                                                                                        forcePopupIcon={false}
                                                                                        onChange={(e, value) => handleNameChange(inputValue, typeof (value) === 'object' ? value.name : +value)}
                                                                                        renderInput={(params) => renderInput(params)}
                                                                                    />
                                                                                </Box>
                                                                            }
                                                                        </WildHealthTableCell>
                                                                        <WildHealthTableCell>
                                                                            <Box>
                                                                                <UpdateLabInputRangeComponent
                                                                                    input={inputValue}
                                                                                    disabled={inputValue?.range?.source === LabValidationSource.Custom}
                                                                                    handleChanges={handleRangeChanges} />
                                                                            </Box>
                                                                        </WildHealthTableCell>
                                                                        <WildHealthTableCell>
                                                                            <Box>
                                                                                <TextField
                                                                                    variant="outlined"
                                                                                    size="small"
                                                                                    type="number"
                                                                                    placeholder="Lab Result"
                                                                                    value={inputValue.value ?? ''}
                                                                                    onChange={(v) => onChanges(inputValue, v.target.value)}
                                                                                    InputProps={{inputProps: {min: 0}}}
                                                                                />
                                                                            </Box>
                                                                        </WildHealthTableCell>
                                                                    </WildHealthTableRow>
                                                                );
                                                            })
                                                        }
                                                        {section.group === LabGroup.Other && (
                                                            <WildHealthTableRow>
                                                                <WildHealthTableCell colSpan={3}>
                                                                    <Box display="flex" alignItems="center" gridGap={8} py={0.5} className="wh-tw-text-primaryV wh-tw-cursor-pointer" onClick={handleAddNewMiscLab}>
                                                                        Add Misc Lab
                                                                        <AddIcon className="wh-tw-text-primaryV" />
                                                                    </Box>
                                                                </WildHealthTableCell>
                                                            </WildHealthTableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Paper>
                                    </Box>
                                </Collapse>
                            </Box>
                        )
                    })
                }
            </Box>
            <Box display='flex' alignItems="center">
                <Box>
                    <WildHealthButton
                        id='add-lab-cancel'
                        color='tertiary'
                        size="medium"
                        onClick={() => handleCloseDialog()}
                    >
                        Cancel
                    </WildHealthButton>
                </Box>
                <Box ml={2}>
                    <WildHealthButton
                        id={`add-lab-submit-${actionName}`}
                        size="medium"
                        color="primaryV"
                        onClick={(e) => handleSubmitForm(e)}
                        disabled={!canSubmit}
                    >
                        {actionName}
                    </WildHealthButton>
                </Box>
            </Box>
        </Box>
    );
};
