import {Store, StoreConfig} from '@datorama/akita';
import { OrderTabs } from '../../models/orders.models';
import {ReferralOrderModel} from "../../models/referralOrders.models";
import { OtherOrderModel } from '../../models/otherOrders.models';

/**
 * Represents patient state
 */
export interface OrdersState {
    tab: number;
    referralOrders: ReferralOrderModel[];
    otherOrders: OtherOrderModel[];
    orderReferralForReview: ReferralOrderModel[] 
    orderOtherForReview: OtherOrderModel[];
}

/**
 * Creates initial state
 */
export function createInitialState(): OrdersState {
    return {
        tab: OrderTabs.Lab,
        referralOrders: [],
        otherOrders: [],
        orderReferralForReview: [],
        orderOtherForReview: [],
    };
}

/**
 * Provides patient states management
 */
@StoreConfig({name: 'orders', resettable: true })
export class OrdersStore extends Store<OrdersState> {
    constructor() {
        super(createInitialState());
    }

    setTab(tab: OrderTabs) {
        this.update({tab: tab});
    }

    public addReferralOrder(order: ReferralOrderModel): void {
        this.update({referralOrders: [...this.getValue().referralOrders, order]});
    }

    public updateReferralOrder(order: ReferralOrderModel): void {
        this.update({referralOrders: [...this.getValue().referralOrders.map(el => el.id === order.id ? order : el)]});
    }

    public deleteReferralOrder(order: ReferralOrderModel): void {
        const orders = this.getValue().referralOrders.filter((x) => x.id !== order.id);
        this.update({referralOrders: orders});
    }

    public addOtherOrder(order: OtherOrderModel): void {
        this.update({otherOrders: [...this.getValue().otherOrders, order]});
    }
   
    public updateOtherOrder(order: OtherOrderModel): void {
        this.update({otherOrders: [...this.getValue().otherOrders.map(el => el.id === order.id ? order : el)]});
    }

    public deleteOtherOrder(order: OtherOrderModel): void {
        const orders = this.getValue().otherOrders.filter((x) => x.id !== order.id);
        this.update({otherOrders: orders});
    }
}

export const ordersStore = new OrdersStore();