import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { AddOnProvider, AddOnModel } from "../../../addons/models/addOns.models";
import { labOrdersService } from "../../services/labOrders.service";
import { pricingService } from "../../services/pricing.service";
import {Gender} from "../../../common/models/user.models";
import { confirmService } from "../../../../services/confirm.service";
import { addOnsService } from "../../../addons/services/addOns.service";

interface LabOrderIFrameComponentState {
    isLoading: boolean;
    iframeUrl: string;
}

const defaultState: LabOrderIFrameComponentState = {
    isLoading: true,
    iframeUrl: '',
}

export function useFacade(patientId: number, gender: Gender): [LabOrderIFrameComponentState, (labProvider: AddOnProvider) => void, (labProvider: AddOnProvider) => void, (addOnItem: AddOnModel, key: string) => void, (labProvider: AddOnProvider) => void] {
    const [state, setState] = useState(defaultState);

    const handleViewPricing = (labProvider: AddOnProvider) => {
        pricingService.open(labProvider, gender).subscribe();
    }

    const handleAddNewAddOn = (labProvider: AddOnProvider) => {
        pricingService.openNew(labProvider).subscribe();
    }

    const handleModifyAddOn = (addOnItem: AddOnModel, key: string) => {
        if (key === 'delete') {
            confirmService.confirm(
                'Delete Lab',
                'Are you sure you want to delete the lab from the list?',
                'Yes',
                'No',
                'danger')
                .subscribe(() => {
                    addOnsService.deleteAddOn(addOnItem.id);
                });
        } else {
            pricingService.openUpdate(addOnItem).subscribe();
        }
    }

    const handleUploadAddOnsDocument = (labProvider: AddOnProvider) => {
        addOnsService.upload(labProvider).subscribe();
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [];

        const cb = (iframeUrl: string) => setState(state => ({...state, isLoading: false, iframeUrl}));
        labOrdersService.getOrdersIFrame(patientId).subscribe(cb, cb);

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [patientId]);

    return [state, handleViewPricing, handleAddNewAddOn, handleModifyAddOn, handleUploadAddOnsDocument];
}