export enum InsuranceServiceType {
    ProviderVisit = 0,
    HealthCoachVisit = 10
}

export enum InsuranceVerificationElibilityCode {
    ActiveCoverage = '1',
    InactiveCoverage = '6',
    Coinsurance = 'A',
    Copay = 'B',
    Deductible = 'C',
    Limitation = 'F',
    OutOfPocket = 'G',
    Noncovered = 'I',
    BenefitDisclaimer = 'P',
    Other = 'R',
    ContactEntity = 'U',
    PrimaryCareProvider = 'L'
}

export enum InsuranceVerificationTimePeriodQualifier {
    Day = '7',
    Years = '21',
    ServiceYears = '22',
    CalendarYear = '23',
    Visit = '27',
    Remaining = '29',
    Admission = '36'
}

export enum InsuranceVerificationServiceType {
    MedicalCare = '1',
    Surgical = '2',
    Consultation = '3',
    DiagnosticXRay = '4',
    DiagnosticLab = '5',
    RadiationTherapy = '6',
    Anesthesia = '7',
    SurgicalAssistance = '8',
    Blood = '10',
    DurableMedicalEquipmentUsed = '11',
    DurableMedicalEquipmentPurchased =  '12',
    RenalSupplies = '14',
    PreAdmissionTesting = '17',
    DurableMedicalEquipmentRental = '18',
    PneumoniaVaccine = '19',
    SecondSurgicalOpinion = '20',
    ThirdSurgicalOpinion = '21',
    SocialWork = '22',
    DiagnosticDental = '23',
    Periodontics = '24',
    Restorative = '25',
    Endodontics = '26',
    MaxillofacialProsthetics = '27',
    AdjunctiveDentalServices = '28',
    PlanCoverageAndGeneralBenefits = '30',
    PlanWaitingPeriod = '32',
    Chiropractic = '33',
    DentalCare = '35',
    DentalCrowns = '36',
    DentalAccident = '37',
    Orthodontics = '38',
    Prosthodontics = '39',
    OralSurgery = '40',
    PreventiveDental = '41',
    HomeHealthCare = '42',
    HomeHealthPrescriptions = '43',
    Hospice = '45',
    RespiteCare = '46',
    Hospitalization = '47',
    HospitalizationInpatient = '48',
    HospitalRoomAndBoard = '49',
    LongTermCare = '54',
    MajorMedical = '55',
    MedicallyRelatedTransportation = '56',
    PlanCoverage = '60',
    InvitroFertilization = '61',
    MRIScan = '62',
    DonorProcedures = '63',
    Acupuncture = '64',
    NewbornCare = '65',
    Pathology = '66',
    SmokingCessation = '67',
    WellBabyCare = '68',
    Maternity = '69',
    Transplants = '70',
    Audiology = '71',
    InhalationTherapy = '72',
    DiagnosticMedical = '73',
    PrivateDutyNursing = '74',
    Porsthetics = '75',
    Dialysis = '76',
    Otology = '77',
    Chemotherapy = '78',
    AllergyTesting = '79',
    Immunizations = '80',
    RoutinePhysical = '81',
    FamilyPlanning = '82',
    Infertility = '83',
    Abortion = '84',
    HivTreatment = '85',
    EmergencyServices = '86',
    CancerTreatment = '87',
    RetailPharmacy = '88',
    FreeStandingPrescriptionDrug = '89',
    MailOrderPharmacy = '90',
    BrandNamePrescriptionDrug = '91',
    GenericPrescriptionDrug = '92',
    Podiatry = '93',
    DentalTestAndExaminations = '94',
    PeriodontalSurgicalServices = '95',
    DentureRepairs = '96',
    DentalNonSurgicalExtractions = '97',
    PrescriptionDrug = '98',
    BariatricServices = '99',
    Psychiatric = 'A4',
    Psychotherapy = 'A6',
    PsychiatricInpatient = 'A7',
    PsychiatricOutpatient = 'A8',
    Rehabilitation = 'A9',
    RehabilitationInpatient = 'AB',
    RehabilitationOutpatient = 'AC',
    OccupationalTherapy = 'AD',
    PhysicalMedicine = 'AE',
    SpeechTherapy = 'AF',
    SkilledNursingCare = 'AG',
    SubstanceAbuse = 'AI',
    AlcoholismTreatment = 'AJ',
    DrugAddiction = 'AK',
    Optometry = 'AL',
    Frames = 'AM',
    Lenses = 'AO',
    RoutineEyeExam = 'AP',
    NonmedicallyNecessaryPhysical = 'AQ',
    ExperimentalDrugTherapy = 'AR',
    BurnCare = 'B1',
    IndependentMedicalEvaluation = 'BA',
    PsychiatricTreatmentPartialHospitalization = 'BB',
    DayCare = 'BC',
    CognitiveTherapy = 'BD',
    MassageTherapy = 'BE',
    PulmonaryRehabilitation = 'BF',
    CardiacRehabilitation = 'BG',
    Pediatric = 'BH',
    NurseryRoomAndBoard = 'BI',
    Orthopedic = 'BK',
    Cardiac = 'BL',
    Lymphatic = 'BM',
    Gastrointestinal = 'BN',
    Endocrine = 'BP',
    Neurology = 'BQ',
    Gynecological = 'BT',
    Obstetrical = 'BU',
    ObstetricalGynecological = 'BV',
    SickPhysicianVisit = 'BY',
    WellPhysicianVisit = 'BZ',
    CoronaryCare = 'C1',
    ScreeningXRay = 'CK',
    ScreeningLaboratory = 'CL',
    MammogramHighRisk = 'CM',
    MammogramLowRisk = 'CN',
    FluVaccination = 'CO',
    EyewearAccessories = 'CP',
    CaseManagement = 'CQ',
    Dermatology = 'DG',
    DurableMedicalEquipment = 'DM',
    DiabeticSupplies = 'DS',
    AppliedBehavioralAnalysisTherapy = 'E0',
    NonMedicalEquipment = 'E1',
    PsychiatricEmergency = 'E2',
    StepDownUnit = 'E3',
    SkilledNursingFacilityHeadLevelOfCare = 'E4',
    SkilledNursingFacilityVentilatorLevelOfCare = 'E5',
    LevelOfCare1 = 'E6',
    LevelOfCare2 = 'E7',
    LevelOfCare3 = 'E8',
    LevelOfCare4 = 'E9',
    Radiographs = 'E10',
    DiagnosticImaging = 'E11',
    FixedProsthodontics = 'E14',
    RemovableProsthodontics = 'E15',
    IntraoralImages = 'E16',
    OralEvaluation = 'E17',
    DentalProphylaxis = 'E18',
    PanoramicImages = 'E19',
    Sealants = 'E20',
    FluorideTreatments = 'E21',
    DentalImplants = 'E22',
    TemporomandibularJointDysfunction = 'E23',
    LongTermCarePharmacy = 'E25',
    ComprehensiveMedicationTherapyManagementReview = 'E26',
    TargetedMedicationTherapyManagementReview = 'E27',
    DietaryServices = 'E28',
    IntensiveCardiacRehabilitation = 'E33',
    ConvenienceCare = 'E36',
    Telemedicine = 'E37',
    PharmacistServices = 'E38',
    DiabeticEducation = 'E39',
    EarlyIntervention = 'E40',
    PreventiveServices = 'EA',
    SpecialtyPharmacy = 'EB',
    DurableMedicalEquipmentNew = 'EC',
    CATScan = 'ED',
    Ophthalmology = 'EE',
    ContactLenses = 'EF',
    FertilityPreservation = 'EG',
    MedicallyTailoredMeals = 'EH',
    IVTherapy = 'EJ',
    MedicalCoverage = 'F1',
    SocialWorkCoverage = 'F2',
    DentalCoverage = 'F3',
    HearingCoverage = 'F4',
    PrescriptionDrugCoverage = 'F5',
    VisionCoverage = 'F6',
    OrthodontiaCoverage = 'F7',
    MentalHealthCoverage = 'F8',
    GenericPrescriptionDrugFormulary = 'GF',
    GenericPrescriptionDrugNonFormulary = 'GN',
    Allergy = 'GY',
    IntesiveCare = 'IC',
    MentalHealth = 'MH',
    NeonatalIntensiveCare = 'NI',
    Oncology = 'ON',
    PositronEmissionTomographyScan = 'PE',
    PhysicalTherapy = 'PT',
    Pulmonary = 'PU',
    Renal = 'RN',
    ResidentialPsychiatricTreatment = 'RT',
    SeriousMentalHealth = 'SMH',
    TransitionalCare = 'TC',
    TransitionalNurseryCare = 'TN',
    UrgentCare = 'UC'
}