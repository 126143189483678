import React, { useRef, useState, useEffect } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { QuestionnaireShortModel, QuestionnaireSubType } from "../../models/questionnaire.models";
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import { useStyles } from './QuestionnaireCardComponent.styles';
import time from "@img/icons/time.svg";
import note from "@img/icons/note.svg";
import star from "@img/icons/star.svg";
import clsx from 'clsx';
import { WildHealthStatusLabel } from '../../../common/components/wildHealthStatusLabel/WildHealthStatusLabel';
import CachedIcon from '@material-ui/icons/Cached';

interface QuestionnaireCardComponentProps {
    questionnaire: QuestionnaireShortModel;
    completed: boolean;
    resultId?: number | null
    handleStart?: (questionnaireId: number, isDialogOpen: boolean) => void;
    handleComplete?: (questionnaireId: number) => void;
    handleView?: (questionnaireId: number) => void;
}

interface QuestionnaireCardComponentState {
    isLongText: boolean;
    isFullText: boolean;
}

export const QuestionnaireCardComponent: React.FC<QuestionnaireCardComponentProps> = (props: QuestionnaireCardComponentProps) => {
    const {
        questionnaire,
        completed,
        resultId = null,
        handleStart = () => { },
        handleComplete = () => { },
        handleView = () => { }
    } = props;

    const descriptionHeight = 78;

    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const [state, setState] = useState({
        isLongText: false,
        isFullText: false,
    } as QuestionnaireCardComponentState);

    const descriptionRef = useRef(null);

    const handleReadMore = () => {
        setState({ ...state, isFullText: !state.isFullText });
    }

    const statusLabel = completed
        ? <WildHealthStatusLabel color='good'>Completed</WildHealthStatusLabel>
        : Boolean(resultId)
            ? <WildHealthStatusLabel color='average1'>In Progress</WildHealthStatusLabel>
            : <WildHealthStatusLabel color='normal'>Up Next</WildHealthStatusLabel>

    const icons = {
        [QuestionnaireSubType.MedicalHistoryIncomplete]: note,
        [QuestionnaireSubType.GoalsIncomplete]: star,
        [QuestionnaireSubType.DetailedHistoryIncomplete]: time,
    }

    const setReadMoreBtn = () => {
        setTimeout(() => {
            setState({ ...state, isLongText: descriptionRef?.current?.scrollHeight > descriptionHeight });
        }, 500)
    }

    useEffect(() => {
        setReadMoreBtn()  
    }, []);

    return (
        <Box className={clsx(classes.cardMain)} width={1}>
            <Box className={classes.titleSection}>
                <img src={icons[questionnaire.subType] ?? time} className={classes.questionImage} alt="img" />
                {isSmallScreen ? (
                    <Box className={classes.statusSection}>
                        <Typography className={classes.cardTitle}>{questionnaire.name}</Typography>
                        {
                            statusLabel
                        }
                    </Box>
                ) : (
                    <>
                        <Typography className={classes.cardTitle} noWrap>{questionnaire.name}</Typography>
                        {
                            statusLabel
                        }
                    </>
                )}
            </Box>
            <div className={clsx(classes.description, state.isFullText ? classes.descriptionExpanded : classes.descriptionCollapsed)} ref={descriptionRef}>
                {questionnaire.description}
            </div>
            {
                state.isLongText &&
                <Box id={`questionnaire-card-read-more-${questionnaire.id}`} onClick={() => handleReadMore()} className={classes.readMore}>
                    <span>{state.isFullText ? 'read less' : 'read more'}</span>
                </Box>
            }

            <Box mt={2.5} display='flex' justifyContent='space-between'>
                <>
                    {
                        !Boolean(resultId) &&
                        <Box>
                            <WildHealthButton id={`questionnaire-card-start-${questionnaire.id}`} onClick={() => handleStart(questionnaire.id, true)} width='max-content'>
                                Start
                            </WildHealthButton>
                        </Box>
                    }
                    {
                        !completed && Boolean(resultId) &&
                        <Box>
                            <WildHealthButton id={`questionnaire-card-complete-${questionnaire.id}`} onClick={() => handleComplete(resultId)} width='max-content'>
                                Complete
                            </WildHealthButton>
                        </Box>
                    }
                    {
                        completed &&
                        <>
                            <Box>
                                <WildHealthButton id={`questionnaire-card-view-1-${questionnaire.id}`} onClick={() => handleView(resultId)} width='max-content'>
                                    View
                                </WildHealthButton>
                            </Box>
                            <Box>
                                <WildHealthButton id={`questionnaire-card-start-new-${questionnaire.id}`} color="secondary" border="none" onClick={() => handleStart(questionnaire.id, false)} width='max-content'>
                                    <Box mr={1}>Start New</Box>
                                    <CachedIcon />
                                </WildHealthButton>
                            </Box>
                        </>
                    }
                </>
            </Box>
        </Box>
    )
}