import {useEffect, useState} from "react";
import {Subscription} from "rxjs";
import {onEmit} from "../../../common/helpers/on-emit";
import {DnaFileModel} from "../../models/dnaFlies.models";
import {dnaFilesQuery} from "../../stores/dnaFiles";
import {dnaFilesService} from "../../services/dnaFiles.service";
import {ISortingState} from "../../../common/sorting/models/sorting-state";
import {SortingDirection} from "../../../common/sorting/models/sorting-destination";
import {handleCompare} from "../../../common/sorting/helpers/handle-compare";
import {notificationsQuery} from "../../../notifications/stores/notifications";
import {NotificationType} from "../../../notifications/models/notifications.models";
import {notificationsService} from "../../../notifications/services/notifications.service";
import {DnaFilesFilters} from "../../constants/dnaFiles.constants";
import { pageSizes } from '../../../common/pagination/models/page-sizes';
import { IPaginationState } from '../../../common/pagination/models/pagination-state';
import { getAvailablePages } from "../../../common/pagination/helpers/get-evailable-pages";

export enum SortingSource {
    none,
    displayName = 'displayName',
    date = 'date',
    isFileSynced = 'isFileSynced'
}

interface DNAFilesState extends ISortingState, IPaginationState {
    isLoading: boolean;
    dnaFilesView: DnaFileModel[];
    dnaFilesAll: DnaFileModel[];
    selectedFilter: string;
    searchQuery: string;
    triggerView: boolean;
}

export function useFacade(): [
    DNAFilesState,
    (source: string, direction: SortingDirection) => void,
    (value: string) => void,
    (fileName: string) => void,
    (value) => void,
    (fileName: string, isSynchronized: boolean) => void,
    (fileName: string) => void,
    (page: number) => void,
    (value) => void,
    () => number[]
] {
    const [state, setState] = useState({
        isLoading: true,
        dnaFilesView: [],
        dnaFilesAll: [],
        sortingColumns: [
            {title: 'Barcode', source: SortingSource.displayName, direction: SortingDirection.Asc},
            {title: 'Date', source: SortingSource.date, direction: SortingDirection.Asc},
            {title: 'Sync to Patient Chart', source: SortingSource.isFileSynced, direction: SortingDirection.Asc},
        ],
        sortingSource: SortingSource.none,
        selectedFilter: DnaFilesFilters.all,
        totalCount: 0,
        selectedPage: 1,
        pageSize: pageSizes[0],
        searchQuery: '',
        triggerView: false
    } as DNAFilesState);

    const handleSorting = (source: string, direction: SortingDirection) => {
        const sortingColumns = [...state.sortingColumns];

        if (state.sortingSource === source) {
            const newDirection = direction === SortingDirection.Asc
                ? SortingDirection.Desc
                : SortingDirection.Asc;

            const itemIndex = sortingColumns.findIndex(item => item.source === source);
            sortingColumns[itemIndex].direction = newDirection;
        } 

        setState(state => ({
            ...state,
            sortingSource: source,
            columns: sortingColumns,
            triggerView: true
        }));
    }

    const handleChangePage = (page: number) => {
        setState(state => ({
            ...state,
            selectedPage: page,
            triggerView: true
        }))
    }

    const handlePageSizeChange = (value) => {
        if (value === state.pageSize) {
            return;
        }

        setState(state => ({
            ...state,
            pageSize: value,
            selectedPage: 1,
            triggerView: true
        }))
    }

    const getAllAvailablePages = (): number[] => {
        return getAvailablePages(state);
    }

    const handleSearch = (value: string) => {
        setState({
            ...state,
            selectedPage: 1,
            searchQuery: value,
            triggerView: true
        });
    }

    const handleSelect = (filter: string) => {
        if (filter === DnaFilesFilters.failed) {
            const failedDnaNotifications = notificationsQuery.getNotificationsByType(NotificationType.DnaKitFailed);
            failedDnaNotifications.forEach(notification => {
                notificationsService.delete(notification.id);
            });
        }

        setState({
            ...state,
            selectedPage: 1,
            selectedFilter: filter,
            triggerView: true
        });
    }

    const filterDnaFiles = (files: DnaFileModel[], filter: string): DnaFileModel[] => {
        switch (filter) {
            case DnaFilesFilters.failed: return files.filter(c => c.isFileFailed === true)
            case DnaFilesFilters.synchronized: return files.filter(c => c.isFileSynced === true)
            case DnaFilesFilters.notSynchronized: return files.filter(c => c.isFileSynced === false)
            case DnaFilesFilters.all: return files;

            default: return files;
        }
    }

    const getDnaFilesView = (dnaFiles: DnaFileModel[]): [number, DnaFileModel[]] => {
        const filteredFiles = filterDnaFiles(dnaFiles, state.selectedFilter)
        
        const searchedFiles = state.searchQuery 
            ? filteredFiles.filter(x => x.displayName?.includes(state.searchQuery))
            : filteredFiles;

        const totalLenght = searchedFiles.length;

        const from = (state.selectedPage - 1) * state.pageSize;
        const to = state.selectedPage * state.pageSize;

        const direction = state.sortingColumns.find(x => x.source === state.sortingSource)?.direction;

        const dnaFilesView = searchedFiles
            .sort((a1, a2) => handleCompare(a1, a2, direction, state.sortingSource))
            .slice(from, to);

        return [totalLenght, dnaFilesView];
    }

    const handleSyncStatusChange = (fileName: string, isSynchronized: boolean) => {
        const file = state.dnaFilesAll.find(c => c.name === fileName);
        if (file) {
            dnaFilesService.syncStatusChange({
                fileName: file.name,
                isSynchronized: isSynchronized
            });
        }
    }

    const handleRetry = (fileName: string) => {
        const originFile = state.dnaFilesAll.find(c => c.name === fileName);
        if (originFile) {
            const files = state.dnaFilesView;
            files.find(c => c.name === fileName).retrying = true;

            setState({
                ...state,
                dnaFilesView: files
            });

            dnaFilesService.retryFile(fileName)
                .subscribe(() => {
                    files.find(c => c.name === fileName).retrying = false;
                    setState(state => ({
                        ...state,
                        dnaFilesView: files
                    }));
                });
        }
    }

    const handleDownloadClick = (fileName: string) => {
        const files = state.dnaFilesView;
        files.find(c => c.name === fileName).downloading = true;

        setState({
            ...state,
            dnaFilesView: files
        });

        dnaFilesService.getFileSize(fileName);

        dnaFilesService.downloadFile(fileName)
            .subscribe(() => {
                files.find(c => c.name === fileName).downloading = false;
                setState(state => ({
                    ...state,
                    dnaFilesView: files
                }));
            });
    }

    useEffect(() => {
        if (!state.triggerView) {
            return
        }

        const [totalCount, viewFiles] = getDnaFilesView(state.dnaFilesAll);

        setState(state => ({
            ...state,
            totalCount: totalCount,
            dnaFilesView: viewFiles,
            triggerView: false
        }))

    }, [state.triggerView])

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<DnaFileModel[]>(dnaFilesQuery.dnaFiles$, dnaFiles => {
                setState(state => (
                    {
                        ...state,
                        dnaFilesAll: dnaFiles,
                        triggerView: true,
                        isLoading: !dnaFiles.length
                    }))
            }),
        ];

        dnaFilesService.getAll();

        return (() => {
            subscriptions.map(it => it.unsubscribe())
        })
    }

    useEffect(useEffectCB, []);

    return [
        state,
        handleSorting,
        handleSearch,
        handleDownloadClick,
        handleSelect,
        handleSyncStatusChange,
        handleRetry,
        handleChangePage,
        handlePageSizeChange,
        getAllAvailablePages
    ];
}