import { Box, useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { WildHealthButton } from '../../common/components/wildHealthButton/WildHealthButton';
import { HealthRecommendationComponent } from '../components/healthRecommendationComponent/HealthRecommendationComponent';
import { RecommendationSingleSelectComponent } from '../components/recommendationSingleSelectComponent/RecommendationSingleSelectComponent';
import {
    HealthReportMode,
    HealthReportSections,
    HealthReportVersion,
    recommendationRowsCapacity,
    RecommendationTypesNumbers
} from "../models/healthReport.models";
import { HealthReportBasePage, HealthReportMobileBasePage } from "./HealthReportBasePage";
import { useStyles } from './healthReport.styles';
import { HealthReportPageProps } from "./healthReportPageProps";

export const HealthReportExerciseAndRecovery2Page: React.FC<HealthReportPageProps> = (props: HealthReportPageProps) => {
    const {
        report,
        pageNumber,
        handleChanges,
        handleSaveChanges,
        isChanged,
        mode,
        practice,
        recommendations,
        handleSelected,
        handleChangesNewRecommendation,
        handleAddRecommendation,
        handleSaveChangesNew,
    } = props;

    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        props.mode !== HealthReportMode.PrintMode && isSmallScreen ? (
            <HealthReportMobileBasePage section={HealthReportSections.ExerciseAndRecovery} pageNumber={pageNumber} practice={practice}>
                <Box px={2}>
                    <>
                        <Box>
                            <span className={classes.reportTitle}>Personalized Exercise Plan</span>
                        </Box>
                        <Box mt="10px" fontSize="14px" lineHeight="22px">
                            <span>
                                In order to determine your genetic preference of exercise, we compiled all exercise related SNPs to produce a
                                percentage of strength vs endurance preference. We also have taken into consideration multiple SNPs that can affect
                                your recovery time between intense exercise classified as slow, moderate or fast. A longevity, genetic based exercise
                                program has been developed with this in mind and is included in this document.
                            </span>
                        </Box>
                    </>

                    <Box mt="20px">
                        {parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
                            ? <Box>
                                {HealthReportMode.EditMode === mode && <Box display="flex" justifyContent="space-between" mb={1}>
                                    <span className="report-title"></span>
                                    <WildHealthButton
                                        id="health-recommendation-new-save"
                                        onClick={() => handleSaveChangesNew()}
                                        disabled={!isChanged}>
                                        <Box >
                                            Save changes
                                        </Box>
                                    </WildHealthButton>
                                </Box>}
                                <RecommendationSingleSelectComponent
                                    currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.ExerciseAndRecovery)}
                                    recommendation={report.exerciseAndRecoveryReport.recommendation}
                                    type={RecommendationTypesNumbers.ExerciseAndRecovery}
                                    handleSelected={handleSelected}
                                    handleChanges={handleChangesNewRecommendation}
                                    handleAddRecommendation={handleAddRecommendation}
                                    mode={mode}
                                />
                            </Box>
                            : <HealthRecommendationComponent
                                mode={mode}
                                rows={recommendationRowsCapacity[RecommendationTypesNumbers.ExerciseAndRecovery]}
                                recommendation={report.exerciseAndRecoveryReport.recommendation}
                                handleContentChanges={!handleChanges ? handleChanges : (value) => {
                                    handleChanges('exerciseAndRecoveryReport.recommendation.content', value)
                                }}
                                handleManualContentChanges={!handleChanges ? handleChanges : (value) => {
                                    handleChanges('exerciseAndRecoveryReport.recommendation.manualContent', value)
                                }}
                                handleSaveChanges={handleSaveChanges}
                                isChanged={isChanged} />
                        }
                    </Box>
                </Box>
            </HealthReportMobileBasePage>
        ) : (
            <HealthReportBasePage section={HealthReportSections.ExerciseAndRecovery} pageNumber={pageNumber} practice={practice}>
                <Box>
                    <Box px={15} pt={5}>
                        <>
                            <Box>
                                <span className="report-title">Personalized Exercise Plan</span>
                            </Box>
                            <Box mt={1}>
                                <span>
                                    In order to determine your genetic preference of exercise, we compiled all exercise related SNPs to produce a
                                    percentage of strength vs endurance preference. We also have taken into consideration multiple SNPs that can affect
                                    your recovery time between intense exercise classified as slow, moderate or fast. A longevity, genetic based exercise
                                    program has been developed with this in mind and is included in this document.
                                </span>
                            </Box>
                        </>
                        <Box mt={10}>
                            {parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
                                ? <Box>
                                    {HealthReportMode.EditMode === mode 
                                    ? <Box display="flex" justifyContent="space-between" mb={1}>
                                        <span className="report-title">Your exercise and recovery recommendations</span>
                                        <WildHealthButton
                                            id="health-recommendation-new-save"
                                            onClick={() => handleSaveChangesNew()}
                                            disabled={!isChanged}>
                                            <Box >
                                                Save changes
                                            </Box>
                                        </WildHealthButton>
                                    </Box>
                                    : <Box mb={1}><span className="report-title">Your exercise and recovery recommendations</span></Box>}
                                    <RecommendationSingleSelectComponent
                                        currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.ExerciseAndRecovery)}
                                        recommendation={report.exerciseAndRecoveryReport.recommendation}
                                        type={RecommendationTypesNumbers.ExerciseAndRecovery}
                                        handleSelected={handleSelected}
                                        handleChanges={handleChangesNewRecommendation}
                                        handleAddRecommendation={handleAddRecommendation}
                                        mode={mode}
                                    />
                                </Box>
                                : <HealthRecommendationComponent
                                    mode={mode}
                                    rows={recommendationRowsCapacity[RecommendationTypesNumbers.ExerciseAndRecovery]}
                                    recommendation={report.exerciseAndRecoveryReport.recommendation}
                                    handleContentChanges={!handleChanges ? handleChanges : (value) => {
                                        handleChanges('exerciseAndRecoveryReport.recommendation.content', value)
                                    }}
                                    handleManualContentChanges={!handleChanges ? handleChanges : (value) => {
                                        handleChanges('exerciseAndRecoveryReport.recommendation.manualContent', value)
                                    }}
                                    handleSaveChanges={handleSaveChanges}
                                    isChanged={isChanged} />
                            }
                        </Box>
                    </Box>
                </Box>
            </HealthReportBasePage>
        )
    )
}
