import {GeneralValidator} from "../../../common/validation/general-validator";
import {
    emailValidationRule,
    firstNameValidationRule,
    lastNameValidationRule,
    stringLengthValidationRule,
    notWhitespaceValidationRule,
    phoneNumberValidationRule,
} from "../../../common/validation/general-validation-rules";

export const waitListDialogComponentValidator = new GeneralValidator({
    'firstName': [firstNameValidationRule, notWhitespaceValidationRule()],
    'lastName': [lastNameValidationRule, notWhitespaceValidationRule()],
    'email': [emailValidationRule],
    'state': [stringLengthValidationRule(1, 50), notWhitespaceValidationRule()],
    'phoneNumber': [phoneNumberValidationRule],
})