import { Tabs } from "@material-ui/core";
import { createStyles, makeStyles, withStyles, Theme } from "@material-ui/core/styles";
import { colors } from "../../../../common/constants/colors";

export const WildHealthTabs = withStyles((theme: Theme) => ({
  root: {
    flex: 1,
    borderBottom: `1px solid ${colors.stroke}`,
    [theme.breakpoints.down('xs')]: {
        borderBottom: 'none',
        backgroundColor: '#F5F6F6',
        padding: 4
    },
  },
  indicator: {
    backgroundColor: colors.main,
    [theme.breakpoints.down('xs')]: {
        backgroundColor: 'transparent'
    },
  },
}))(Tabs);

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tabHeaderSection: {
            paddingTop: 8,
            [theme.breakpoints.down('xs')]: {
                padding: 16,
            },
        },
        tabContentSection: {
            position: 'relative',
            padding: 30,
            paddingTop: 25,
            [theme.breakpoints.down('xs')]: {
                padding: 16,
                paddingTop: 0,
            },
        },
        tab: {
            width: '50%',
            minWidth: 72,
            fontSize: 14,
            fontWeight: 400,
            padding: 12,
            fontFamily: ["Be Vietnam Pro"].join(","),
            textTransform: "capitalize",
            color: colors.gray1,
            "&:hover": {
                color: colors.main,
                opacity: 0.8,
            },
            "&:selected": {
                color: colors.main,
            },
            "&:focus": {
                color: colors.main,
            },
            [theme.breakpoints.down('xs')]: {
                fontWeight: 500,
            },
        },
        selectedTab: {
            backgroundColor: "#FFF !important",
            color: `${colors.main} !important`,
        },
        editBtnSection: {
            position: 'absolute',
            top: 16, 
            right: 30,
            [theme.breakpoints.down('xs')]: {
                position: 'relative',
                display: 'flex',
                justifyContent: 'flex-end',
                top: 0, 
                right: 0,
                marginBottom: 16
            },
        },
        button: {
            color: colors.mediumGray,
            fontSize: 14,
        },
        buttonText: {
            marginLeft: '6px',
            textTransform: 'capitalize',
            [theme.breakpoints.down('xs')]: {
                display: 'none'
            },
        },
        textGray: {
            color: colors.gray1,
            fontSize: 14,
            minWidth: 84,
        },
        breakWord: {
            wordBreak: 'break-word',
            fontSize: 14,
            color: colors.linkBlack,
            paddingRight: 8,
            paddingLeft: 8
        },
        breakGrayWord: {
            wordBreak: 'break-word',
            fontSize: 14,
            color: colors.gray1,
            paddingRight: 8,
            paddingLeft: 8
        },
        notificationToolTipText: {
            fontFamily: 'Be Vietnam Pro',
            fontSize: 12,
            fontWeight: 400,
            color: colors.white,
        },
        tooltipPlacement: {
            margin: '8px 0',
        },
        customWidth: {
            width: '307px',
            maxWidth: '307px',
            backgroundColor: colors.lightBlack,
            padding: 10,
            borderRadius: 2
        },
        arrow: {
            color: colors.lightBlack,
        },
    })
);

