import React from "react"
import { WildHealthStatusLabel } from "../../common/components/wildHealthStatusLabel/WildHealthStatusLabel"

export const displayEnrollment = (isEnrolled: boolean): JSX.Element => {
    return(
        <WildHealthStatusLabel 
            color={isEnrolled ? "good" : "bad"}
            minWidth={74}
        >
            {isEnrolled ? "Yes" : "No"}
        </WildHealthStatusLabel>
    );
}