import { DialogContent } from '@material-ui/core';
import React, { useState } from 'react';
import { DialogTitle } from '../../../common/components/dialogTitle/DialogTitle';
import { useStyles, DialogSlider } from '../attachmentsComponent/attachmentsComponent.styles';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';

interface SlidertDialogComponentProps {
    open: boolean;
    selectedNumber: number;
    group: any;
    handleClose: () => void;
}

export const SliderDialogComponent: React.FC<SlidertDialogComponentProps> = (props: SlidertDialogComponentProps) => {
    const {
        open,
        group,
        selectedNumber,
        handleClose,
    } = props;

    const [current, setCurrent] = useState(0);
    const classes = useStyles()

    const renderContent = () => {
        return (
            <AwesomeSlider
                bullets={false}
                className={classes.slider}
                infinite={false}
                organicArrows={true}
                selected={selectedNumber}
                onTransitionStart={slide => setCurrent(slide.nextIndex)}
            >
                {group.items.map(slide => (
                    <div className={classes.containerImg} key={slide.url}>
                        <img
                            src={slide.url}
                            alt={`img-${current}`}
                            className="gallery-image"
                        />
                    </div>
                ))}
            </AwesomeSlider>
        );
    }

    return (
        <>
            <DialogSlider
                fullWidth
                maxWidth='xl'
                open={open}
                onClose={handleClose}>

                <DialogTitle
                    id="customized-dialog-title-attachments"
                    classes={{ root: classes.dialogTitle }}
                    onClose={() => handleClose()}
                >
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    {renderContent()}
                </DialogContent>
            </DialogSlider>
        </>
    );
}