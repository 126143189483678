export enum FeatureFlag {
    DashboardMyJorney,
    QuestionnairesUseIntial,
    MenuHealthScoreNew,
    AppointmentsUseBrowserTimeZone,
    ConversationsEmojis,
    SignOffNotes,
    InsuranceProfile,
    HealthSummaryEdit,
    SoapNote,
    ImhNote,
    FmhNote,
    Navigation,
    DnaCanDownload,
    StickyChatMessaging,
    FollowUpForms,
    EmployeeDashboard,
    CreatePods,
    PatientProduct,
    ReportRecommendations,
    AcademyPatientChart,
    DefaultPlan,
    InsuranceOnboarding,
    MarketingSmsButton,
    OnboardingRedesignV2,
    MedicareOnboarding,
    NewPatientProfile,
    AppointmentWidgetRedesign,
    AppointmentsStatistic,
    Phq2,
    AppointmentReasons,
    EnabledRegistration,
    EnabledLicensingJoin,
    HealthPlanDashboard,
    CheckoutSocialProof,
    EnabledInsuranceAdd,
    EnabledInsuranceVerificationToggle,
    CancellationForm,
    PatientTimelineWidget,
    TranscriptionConsent,
    RecommendationsV2,
    AddOnReportPage,
    Auth2Google,
    D2CDemo,
    LlmPlayground,
    AppleAndGooglePay,
    RxntIntegration,
    FullscriptIntegration
}

export const environmentTypes = {
    WildHealth: 'wh',
    Licensing: 'lic',
}

export const environmentPriority = [
    environmentTypes.Licensing,
    environmentTypes.WildHealth
]