import { numberValidationRule, minNumberValidationRule, maxNumberValidationRule, nextAppointmentDateValidationRule } from "../../../common/validation/general-validation-rules";
import { GeneralValidator } from "../../../common/validation/general-validator";

export const premiumBillingComponentValidator = new GeneralValidator({
    'total': [minNumberValidationRule(1), numberValidationRule],
    'remainingPaidOverMonths': [minNumberValidationRule(1), numberValidationRule, maxNumberValidationRule(12, 'Duration cannot exceed 12 months')],
    'downPayment': [minNumberValidationRule(0)],
    'deposit': [minNumberValidationRule(0)],
    'selectedDate': [nextAppointmentDateValidationRule],
});
