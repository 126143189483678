import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
    createStyles({
        cell: {
            width: 300,
            minWidth: 150,
            maxWidth: 300,
            '&:last-child': {
                width: 'auto',
                minWidth: 0,
            },
        },
        title: {
            fontWeight: 500,
            fontSize: '20px',
            lineHeight: '24px', 
        },
        placeHolder: {
            display: 'flex',
            width: '100%', 
            justifyContent: 'center', 
            alignItems: 'center',
            marginTop: '18vh' 
        }
    })
);
