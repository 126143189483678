import {Store, StoreConfig} from '@datorama/akita';

/**
 * Represents invite code state
 */
export interface InviteCodeState {
    inviteCode: string;
}

/**
 * Creates initial state
 */
export function createInitialState(): InviteCodeState {
    return {
        inviteCode: null,
    };
}

/**
 * Provides invite code states management
 */
@StoreConfig({name: 'inviteCode', resettable: true })
export class InviteCodeStore extends Store<InviteCodeState> {
    constructor() {
        super(createInitialState());
    }

    public setCode(code: string): void {
        this.update({inviteCode: code});
    }
}

export const inviteCodeStore = new InviteCodeStore();
