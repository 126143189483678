import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
      borderColor: colors.mediumGray,
      borderRadius: 4,
      '& .MuiOutlinedInput-root': {
        borderColor: colors.mediumGray,
        borderRadius: 4,
      },
      "& .MuiSelect-selectMenu": {
        whiteSpace: 'break-spaces',
      }
    },
    container: {
        maxWidth: '1000px'
    },
    menuItem: {
        whiteSpace: 'break-spaces',
        borderBottom: `1px solid ${colors.lightGray1}`,
    },
    selectItem: {
        "& .MuiSelect-selectMenu": {
            whiteSpace: 'break-spaces',
        }
    },
    insuranceTitle: {
        color: colors.mainDark,
        fontSize: '22px',
        fontWeight: 500
    },
    formTitle: {
        color: colors.darkGray,
        fontSize: '18px',
        fontWeight: 500
    },
    inmagesCard: {
        backgroundColor: colors.lightBlue2,
        borderRadius: '8px',
        padding: '8px 16px 26px 16px'
    },
    optional: {
        color: colors.main,
        fontSize: '16px',
        fontWeight: 500
    },
    edit: {
        color: colors.main,
        cursor: 'pointer'
    },
    title: {
        fontSize: 40,
        fontWeight: 500,
        lineHeight: '50px',
        color: colors.navy,
        [theme.breakpoints.down('xs')]: {
            fontSize: 28,
            lineHeight: '34px',
        },
    },
    subTitle: {
        fontSize: 24,
        fontWeight: 500,
        lineHeight: '38px',
        color: colors.navy,
        [theme.breakpoints.down('xs')]: {
            fontSize: 20,
            lineHeight: '25px',
        },
    },
    description: {
        fontSize: 20,
        fontWeight: 500,
        lineHeight: '32px',
        color: colors.gray700,
        [theme.breakpoints.down('xs')]: {
            fontSize: 18,
            lineHeight: '25px',
        },
    },
    label: {
        fontSize: 14,
        fontWeight: 500,
        color: colors.gray900,
        textTransform: 'uppercase',
        [theme.breakpoints.down('xs')]: {
            fontSize: 12,
        },
    },
    inputFieldMain: {
        maxWidth: 456,
        margin: '0 auto',
    },
}));