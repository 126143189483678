import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { onEmit } from "../../modules/common/helpers/on-emit";
import { MetaTagsModel } from "../../modules/employee/models/metaTags.models";
import { metaTagsService } from "../../modules/employee/services/metaTags.service";
import { patientProfileQuery } from "../../modules/employee/stores/patientProfileStore";
import { patientsService } from "../../modules/patients/services/patients.service";
import { PatientNpsScoreModel, PatientNpsScoreTopic } from "../../modules/patients/models/patient.model";

/**
 * Represents for Side Bar Component state
 */
interface MetaTagsState {
    metaTags: MetaTagsModel,
    npsScores: PatientNpsScoreModel[],
    isLoading: boolean,
    isNpsLoading: boolean
}

/**
 * Custom Hook to manage a view Model for Side Bar component
 */
export function useFacade(patientId: number): [
    MetaTagsState
] {
    const [state, setState] = useState({
        metaTags: null,
        npsScores: [],
        isLoading: true,
        isNpsLoading: true
    } as MetaTagsState);

    const cb = () => {
        setState(state => ({ ...state, isLoading: false }));
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<MetaTagsModel>(patientProfileQuery.metaTags$, metaTags => {
                setState(state => ({ ...state, metaTags: metaTags }));
            }),
            onEmit<PatientNpsScoreModel[]>(patientProfileQuery.npsScores$, npsScores => {
                setState(state => ({ ...state, npsScores: npsScores.filter(x => x.npsTopic === PatientNpsScoreTopic.HealthCoach) }));
            }),
        ];

        const npsCb = () => {
            setState(state => ({ ...state, isNpsLoading: false }));
        }
        
        if(!!patientId){
            metaTagsService.getMetaTags(patientId).subscribe(cb,cb);
            patientsService.getNpsScore(patientId).subscribe(npsCb, npsCb);
        }

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [patientId]);

    return [state];
}