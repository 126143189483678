import { Box } from "@material-ui/core";
import React from 'react';
import { AuthenticatedLayoutComponent } from '../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { PagesNamesService } from "../../../../constants/pagesNames.constants";
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from "../../../../models/menu.models";
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { useStyles } from "./draftNotesPage.styles";
import { useFacade } from "./DraftNotesPage.hooks";
import {ManageDraftNotesComponent} from "../../components/manageDraftNotes/ManageDraftNotesComponent";

export const DraftNotesPage: React.FC = () => {
    const [
        {
            isLoading
        }
    ] = useFacade();

    const classes = useStyles();

    const renderContent = () => {
        return (
            <Box className={classes.sections}>
                <ManageDraftNotesComponent />
            </Box>
        )
    }

    return (
        <AuthenticatedLayoutComponent
            title={TitlesService.draftNotesTitle}
            pageName={PagesNamesService.draftNotes}
            selectedMenuItem={MenuItemTypes.DraftNotes}
            hideBackground
        >
            {
                isLoading
                    ? <WildHealthLinearProgress />
                    : renderContent()

            }
        </AuthenticatedLayoutComponent>
    )
}

