import { Box, Button, CircularProgress, Grid, IconButton, useTheme, useMediaQuery, Divider } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import GetAppIcon from '@material-ui/icons/GetApp';
import moment from 'moment';
import React from 'react';
import { AuthenticatedLayoutComponent } from "../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent";
import { PageTitleComponent } from '../../../../components/pageTitleComponent/PageTitleComponent';
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from "../../../../models/menu.models";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { WildHealthExternalLinkButton } from '../../../common/components/WildHealthLinkButton';
import { WildHealthPlaceHolder } from '../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder';
import { NoteSignatureComponent } from "../../components/noteSignature/NoteSignatureComponent";
import { useFacade } from './patientFollowUpPage.hooks';
import { useStyles } from './patientFollowUpPage.styles';
import { TextMarkdownComponent } from '../../components/textMarkdownComponent/TextMarkdownComponent';
import commonUseStyles from "../../../common/styles/common.styles";
import clsx from 'clsx';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import { renderEducation, renderGoals } from '../../helpers/noteHelpers';
import { NoteGoalsVersion } from '../../models/notes.models';
import {SupplementSource} from "../../../patientSupplements/models/patientSupplementModel";

export const PatientFollowUpPage: React.FC = (props: any) => {
    const noteId = Number(props.match.params.noteId);

    const [
        {
            note,
            content,
            isPdfDownloading,
        },
        handleGoBack,
        handleDownloadPdf,
    ] = useFacade(noteId);

    const classes = useStyles();
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const isLoading = !content || !note;

    const renderTitle = () => {
        return `${note.employee.firstName} - ${moment(note.visitDate).format("ll")}`;
    }

    const renderNewGoals = () => {
        return <Box mt={isSmallScreen ? "20px" : 4}>
            {
                content.plan?.dietGoals?.length > 0 &&
                <Box>
                    {
                        renderGoals('Diet', content.plan.dietGoals, commonClasses, isSmallScreen)
                    }
                </Box>
            }
            {
                content.plan.dietEducations?.length > 0 &&
                <Box>
                    {
                        renderEducation(content.plan.dietEducations,commonClasses)
                    }
                </Box>
            }
            {
                content.plan?.exerciseGoals?.length > 0 &&
                <Box mt={isSmallScreen ? "20px" : 4}>
                    {
                        renderGoals('Exercise/Recovery', content.plan.exerciseGoals, commonClasses, isSmallScreen)
                    }
                </Box>
            }
            {
                content.plan.exerciseEducations?.length > 0 &&
                <Box>
                    {
                        renderEducation(content.plan.exerciseEducations,commonClasses)
                    }
                </Box>
            }
            {
                content.plan?.sleepGoals?.length > 0 &&
                <Box mt={isSmallScreen ? "20px" : 4}>
                    {
                        renderGoals('Sleep', content.plan.sleepGoals, commonClasses, isSmallScreen)
                    }
                </Box>
            }
            {
                content.plan.sleepEducations?.length > 0 &&
                <Box>
                    {
                        renderEducation(content.plan.sleepEducations,commonClasses)
                    }
                </Box>
            }
            {
                content.plan?.neuroGoals?.length > 0 &&
                <Box mt={isSmallScreen ? "20px" : 4}>
                    {
                        renderGoals('Neuro', content.plan.neuroGoals, commonClasses, isSmallScreen)
                    }
                </Box>
            }
            {
                content.plan.neuroEducations?.length > 0 &&
                <Box>
                    {
                        renderEducation(content.plan.neuroEducations,commonClasses)
                    }
                </Box>
            }
            {
                content.plan?.longevityGoals?.length > 0 &&
                <Box mt={isSmallScreen ? "20px" : 4}>
                    {
                        renderGoals('Longevity', content.plan.longevityGoals, commonClasses, isSmallScreen)
                    }
                </Box>
            }
            {
                content.plan.longevityEducations?.length > 0 &&
                <Box>
                    {
                        renderEducation(content.plan.longevityEducations,commonClasses)
                    }
                </Box>
            }
            {
                content.plan.mindfulnessGoals?.length > 0 &&
                <Box mt={isSmallScreen ? "20px" : 4}>
                    {
                        renderGoals('Mindfulness', content.plan.mindfulnessGoals, commonClasses, isSmallScreen)
                    }
                </Box>
            }
            {
                content.plan.mindfulnessEducations?.length > 0 &&
                <Box>
                    {
                        renderEducation(content.plan.mindfulnessEducations,commonClasses)
                    }
                </Box>
            }
        </Box>
    }


    const renderNoteHeader = () => {
        return isSmallScreen ? (
            <Box mb="30px">
                <Box className={commonClasses.secondaryTitle}>{note.name}</Box>
                {
                    note.title &&
                    <Box mt={1} className={clsx(commonClasses.mediumTitle)}>{note.title}</Box>
                }
            </Box>
        ) : <></>
    }

    const renderTodayCoachingContent = () => {
        return (
            <Box mt={7}>
                <span className={clsx(commonClasses.secondaryTitle, "wh-tw-uppercase")}>
                    Today’s Coaching
                </span>
                <Box py={2}>
                    <Divider />
                </Box>
                <Box>
                    <TextMarkdownComponent content={content.todayCoachingContent} classLink={classes.renderText} />
                </Box>
            </Box>
        );
    }

    const renderNoGoalsPlaceHolder = () => {
        return (
            <Box>
                <Box>
                    <span className={isSmallScreen ? clsx(commonClasses.size16, commonClasses.textMedium, commonClasses.colorBlack) : clsx(commonClasses.secondaryTitle, commonClasses.uppercase)}>
                        Goals
                    </span>
                </Box>
                {isSmallScreen && <Box py={2}>
                    <Divider className={commonClasses.renderDivider} />
                </Box>}
                <Box>
                    <Box my={2}>
                        <WildHealthPlaceHolder message="No goals." />
                    </Box>
                </Box>
            </Box>
        )
    }

    const renderOldGoals = () => {
        return <Box pb={!isSmallScreen && 5}>
            {
                content.goals.map((goal, goalIndex) =>
                    <Box key={goal.id}>
                        <Box my={!isSmallScreen && 2}>
                            <span className={isSmallScreen ? clsx(commonClasses.size16, commonClasses.textMedium, commonClasses.colorGray1) : commonClasses.subtitle}>Goal {goalIndex + 1}:</span>
                        </Box>
                        <Box my={isSmallScreen ? 1 : 2}>
                            <TextMarkdownComponent content={goal.name} classLink={classes.renderText} />
                        </Box>

                        <Box mt="20px">
                            <Grid container>
                                {
                                    goal.interventions.map((intervention, interventionIndex) =>
                                        <Grid key={interventionIndex} item md={4} sm={12} xs={12}>
                                            <Box pb={isSmallScreen ? 2 : 3}>
                                                <Box py={!isSmallScreen && 2}>
                                                    <span className={isSmallScreen ? clsx(commonClasses.size16, commonClasses.textMedium, commonClasses.colorGray1) : commonClasses.subtitle}>Intervention {interventionIndex + 1}:</span>
                                                </Box>
                                                <Box mt={isSmallScreen && "10px"} mr={!isSmallScreen && "10px"}>
                                                    <TextMarkdownComponent content={intervention.name} classLink={classes.renderText} />
                                                </Box>
                                            </Box>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Box>
                    </Box>
                )
            }
        </Box>
    }

    const renderGeneralGoals = () => {
        if (Boolean(!content?.goalsVersion) && Boolean(!content?.goals.length)) {
            return renderNoGoalsPlaceHolder();
        }

        if (content?.goalsVersion === NoteGoalsVersion.New && content?.goals.length) {
            return renderOldGoals()
        }

        if (content?.goalsVersion === NoteGoalsVersion.New
            && content.plan.dietGoals.length === 0
            && content.plan.exerciseGoals.length === 0
            && content.plan.sleepGoals.length === 0
            && content.plan.neuroGoals.length === 0
            && content.plan.mindfulnessGoals.length === 0
            && content.plan.longevityGoals.length === 0) {
            return renderNoGoalsPlaceHolder();
        }

        return (
            <Box>
                <Box>
                    <span className={isSmallScreen ? clsx(commonClasses.size16, commonClasses.textMedium, commonClasses.colorBlack) : clsx(commonClasses.secondaryTitle, commonClasses.uppercase)}>
                        Goals
                    </span>
                </Box>
                {isSmallScreen && <Box py={2}>
                    <Divider className={commonClasses.renderDivider} />
                </Box>}
                {content?.goalsVersion === NoteGoalsVersion.New
                    ? renderNewGoals()
                    : renderOldGoals()
                }
            </Box>
        )
    }

    const renderSupplements = () => {
        if (content.medicationsSupplements.fullscriptSupplements?.length <= 0 && content.medicationsSupplements.medications.filter(x => !x.isInCurrent).length <= 0 && content.medicationsSupplements.supplements.filter(x => !x.isInCurrent).length <= 0) {
            return (
                <Box>
                    <Box my={2} className={classes.title}>
                        <span className='uppercase'>Supplements/Medications added or changed</span>
                    </Box>
                    <Box my={2}>
                        <WildHealthPlaceHolder message="No changes were made during this visit." />
                    </Box>
                </Box>
            )
        }

        let globalIndex = 0;
        return (
            <Box>
                <Box my={2} className={classes.title}>
                    <span className='uppercase'>Supplements/Medications added or changed</span>
                </Box>
                <Box my={2}>
                    {
                        content.medicationsSupplements.medications.filter(i => !i.isInCurrent).map((item, index) => {
                            return (
                                <Box key={index} my={1} display="flex">
                                    <Box width={0.25}>
                                        <span className={clsx(classes.regular, classes.textWrap)}>{`${++globalIndex}. ${item.name}`}</span>
                                    </Box>
                                    <Box width={0.25}>
                                        <span className={clsx(classes.regular, classes.textWrap)}>{item.dosage}</span>
                                    </Box>
                                </Box>
                            )
                        })
                    }
                    {
                        content.medicationsSupplements.supplements.filter(i => !i.isInCurrent).map((item, index) => {
                            return (
                                <Box key={index} my={1} display="flex">
                                    <Box width={0.25}>
                                        <span className={clsx(classes.regular, classes.textWrap)}>{`${++globalIndex}. ${item.name}`}</span>
                                    </Box>
                                    <Box width={0.25}>
                                        <span className={clsx(classes.regular, classes.textWrap)}>{item.dosage}</span>
                                    </Box>
                                    <Box width={0.25}>
                                        <WildHealthExternalLinkButton id={`follow-up-link-no-stopped-supplements${item.id}`} link={item.purchaseLink} children={
                                            <span>
                                                {
                                                    item.source === SupplementSource.Fullscript
                                                        ? 'Purchase via Fullscript'
                                                        : 'Purchase here'
                                                }
                                            </span>
                                        } />
                                    </Box>
                                </Box>
                            )
                        })
                    }
                    {
                        content.medicationsSupplements.fullscriptSupplements?.map((item, index) => {
                            return (
                                <Box key={index} my={1} display="flex">
                                    <Box width={0.25}>
                                        <span className={clsx(classes.regular, classes.textWrap)}>{`${++globalIndex}. ${item.name}`}</span>
                                    </Box>
                                    <Box width={0.25}>
                                        <span className={clsx(classes.regular, classes.textWrap)}>{item.dosage}</span>
                                    </Box>
                                    <Box width={0.25}>
                                        <WildHealthExternalLinkButton id={`follow-up-link-no-stopped-supplements${item.id}`} link={item.purchaseLink} children={
                                            <span>
                                                Purchase via Fullscript
                                            </span>
                                        } />
                                    </Box>
                                </Box>
                            )
                        })
                    }
                </Box>
            </Box>
        )
    }

    const renderStoppedSupplements = () => {
        if (!content.medicationsSupplements.medications.filter(i => i.isStopped).length && !content.medicationsSupplements.supplements.filter(i => i.isStopped).length) {
            return (
                <Box>
                    <Box>
                        <span className={isSmallScreen ? clsx(commonClasses.size16, commonClasses.textMedium, commonClasses.colorBlack) : clsx(commonClasses.secondaryTitle, commonClasses.uppercase)}>
                            Supplements/Medications stopped
                        </span>
                    </Box>
                    {isSmallScreen && <Box py={2}>
                        <Divider className={commonClasses.renderDivider} />
                    </Box>}
                    <Box my={2}>
                        <WildHealthPlaceHolder message="No Supplements/Medications stopped." />
                    </Box>
                </Box>
            )
        }

        let globalIndex = 0;
        return (
            <Box>
                <Box>
                    <span className={isSmallScreen ? clsx(commonClasses.size16, commonClasses.textMedium, commonClasses.colorBlack) : clsx(commonClasses.secondaryTitle, commonClasses.uppercase)}>
                        Supplements/Medications stopped
                    </span>
                </Box>
                {isSmallScreen && <Box py={2}>
                    <Divider className={commonClasses.renderDivider} />
                </Box>}
                <Box my={!isSmallScreen && 2}>
                    {
                        content.medicationsSupplements.medications.filter(i => i.isStopped).map((item, index) => {
                            return isSmallScreen ? (
                                <Box key={index} pb="20px">
                                    <Box mb={1} className={commonClasses.subtitle}>
                                        Medication {index + 1}:
                                    </Box>
                                    <Box mt="10px">
                                        <span className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorBlack)}>{item.name} - {item.dosage}</span>
                                    </Box>
                                </Box>
                            ) : (
                                <Box my={1} key={index}>
                                    <span className={classes.regular}>{`${++globalIndex}. ${item.name}`}</span>
                                </Box>
                            )
                        })
                    }
                    {
                        content.medicationsSupplements.supplements.filter(i => i.isStopped).map((item, index) => {
                            return isSmallScreen ? (
                                <Box key={index} pb="20px">
                                    <Box mb={1} className={commonClasses.subtitle}>
                                        Supplements {index + 1}:
                                    </Box>
                                    <Box mt="10px">
                                        <span className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorBlack)}>{item.name} - {item.dosage}</span>
                                    </Box>
                                </Box>
                            ) : (
                                <Box my={1} key={index}>
                                    <span className={classes.regular}>{`${++globalIndex}. ${item.name}`}</span>
                                </Box>
                            )
                        })
                    }
                </Box>
            </Box>
        )
    }

    const renderNextSteps = () => {
        return (
            <Box>
                <Box>
                    <span className={isSmallScreen ? clsx(commonClasses.size16, commonClasses.textMedium, commonClasses.colorBlack) : clsx(commonClasses.secondaryTitle, commonClasses.uppercase)}>
                        Next Steps
                    </span>
                </Box>
                {isSmallScreen && <Box py={2}>
                    <Divider className={commonClasses.renderDivider} />
                </Box>}
                <Box my={!isSmallScreen && 2}>
                    {
                        content.steps.length ?
                            content.steps.map((item, index) => {
                                return isSmallScreen ? (
                                    <Box key={index} pb="20px">
                                        <Box mb={1} className={commonClasses.subtitle}>
                                            Step {index + 1}:
                                        </Box>
                                        <Box mt="10px">
                                            <TextMarkdownComponent content={item.name} classLink={classes.renderText} />
                                        </Box>
                                    </Box>
                                ) : (
                                    <Box my={1} display="flex" key={item.id}>
                                        <span className={classes.regular}>{`${index + 1}.`}</span>
                                        <TextMarkdownComponent content={item.name} classLink={classes.renderText} />
                                    </Box>
                                )
                            }) :
                            <Box my={1}>
                                <WildHealthPlaceHolder message="No next steps." />
                            </Box>
                    }
                </Box>
            </Box>
        )
    }

    const displayDownloadPdfButton = () => {
        if (isPdfDownloading === true) {
            return (
                <Box width="70px" display="flex" justifyContent="center">
                    <CircularProgress size={24} className={classes.colorMain} />
                </Box>
            );
        }
        return (
            <Button id="patient-follow-up-download-pdf" onClick={() => handleDownloadPdf(note)}>
                <Box display="flex" alignItems="center" mt={1}>
                    <Box>
                        <GetAppIcon />
                    </Box>
                    <Box mt={-1}>
                        PDF
                    </Box>
                </Box>
            </Button>
        );
    }

    const renderNote = () => {
        return ([
            <Box key='smallScreenTitle'>
                {renderNoteHeader()}
            </Box>,
            <Box key='goals'>
                {renderGeneralGoals()}
            </Box>,
            <Box mt={isSmallScreen ? 2 : 7} key='supps-medds'>
                {renderSupplements()}
            </Box>,
            <Box mt={isSmallScreen ? 2 : 5} key='stopped-supps-medds'>
                {renderStoppedSupplements()}
            </Box>,
            <Box>
                {content.todayCoachingContent && renderTodayCoachingContent()}
            </Box>,
            <Box mt={isSmallScreen ? 2 : 7} key='next-steps'>
                {renderNextSteps()}
            </Box>,
            <Box py={isSmallScreen ? 2 : 7} key='signature'>
                <NoteSignatureComponent note={note} noteId={note.id} />
            </Box>
        ])
    }

    const pageName = (
        <PageTitleComponent
            startAdornment={
                <IconButton id="patient-blank-go-back" onClick={() => handleGoBack()}>
                    <ArrowBackIcon />
                </IconButton>
            }
            title={
                isLoading
                    ? 'Loading . . .'
                    : renderTitle()
            }
            endAdornment={
                displayDownloadPdfButton()
            }
        />
    );

    return (
        <AuthenticatedLayoutComponent
            title={TitlesService.noteDetailTitle}
            pageName={pageName}
            selectedMenuItem={MenuItemTypes.Notes}
        >
            <Box className={isSmallScreen && commonClasses.bgMain}>
                {isSmallScreen && (
                    <>
                        <Box py={2} display="flex" alignItems="center">
                            <IconButton id="patient-follow-up-go-back" onClick={() => handleGoBack()}>
                                <ArrowBackIcon />
                            </IconButton>
                            <Box className={clsx(commonClasses.size14, commonClasses.textRegular, commonClasses.colorGray1)}>
                                Back to Notes
                            </Box>
                        </Box>
                        <Box position="fixed" right={16} bottom={40} zIndex={1202}>
                            <WildHealthButton id="download-pdf" width="48px" loading={isPdfDownloading} style={{ borderRadius: "50%", minWidth: 48, height: 48 }} onClick={() => handleDownloadPdf(note)}>
                                <GetAppIcon className={commonClasses.colorWhite} />
                            </WildHealthButton>
                        </Box>
                    </>
                )}
                {
                    isLoading ?
                        <WildHealthLinearProgress /> :
                        <Box p={isSmallScreen ? 2 : 5} pb={4} className={isSmallScreen && commonClasses.bgWhiteMain}>
                            {renderNote()}
                        </Box>
                }
            </Box>
        </AuthenticatedLayoutComponent>
    )
};