import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        chatList: {
            width: 405,
            flexShrink: 0,
            overflowX: 'auto',
            overflowY: 'auto',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                paddingTop: 16
            },
        },
        chatRoot: {
            width: '100%',
            minHeight: `calc(100vh - 174px)`,
            display: 'flex',
            flexDirection: 'column',

            marginRight: 30,
            marginBottom: 30,
            [theme.breakpoints.down('sm')]: {
                margin: 0,
                minHeight: "calc(100vh - 156px) !important",
            },
        },
        chat: {
            height: '100%',
            background: colors.white,
            boxShadow: '0px 7px 60px rgba(168, 173, 198, 0.12)',
            borderRadius: 2,
        },
    })
);
