import { GeneralValidator } from "../../../common/validation/general-validator";
import {
  notWhitespaceValidationRule,
  requiredValidationRule
} from "../../../common/validation/general-validation-rules";

export const updateDnaOrderValidator = new GeneralValidator({
  'number': [notWhitespaceValidationRule(), requiredValidationRule()],
  'barcode': [notWhitespaceValidationRule(), requiredValidationRule()],
  'patientShippingNumber': [notWhitespaceValidationRule(), requiredValidationRule()],
  'laboratoryShippingNumber': [notWhitespaceValidationRule(), requiredValidationRule()]
});