import { Box, Divider, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { LabInputNames } from "../../inputs/constants/inputs.constants";
import { DnaTableComponent } from "../components/dnaTableComponent/DnaTableComponent";
import { LabResultComponent } from "../components/labResultComponent/LabResultComponent";
import {
    LabelPosition,
    ProgressDiagramComponent
} from "../components/progressDiagramComponent/ProgressDiagramComponent";
import { riskColorMap } from "../constants/healthReport.constants";
import { HealthReportSections, HealthReportMode } from "../models/healthReport.models";
import { HealthReportBasePage, HealthReportMobileBasePage } from "./HealthReportBasePage";
import { useStyles } from './healthReport.styles';
import { HealthReportPageProps } from "./healthReportPageProps";

export const HealthReportLongevity1Page: React.FC<HealthReportPageProps> = (props: HealthReportPageProps) => {
    const {
        report,
        pageNumber,
        practice
    } = props;
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const labValues = report.labReport.values;

    const ageData = [
        {
            label: `EPIGENETIC AGE: ${report.alphaReport.epiGeneticAge.biologicalAge}`,
            value: report.alphaReport.epiGeneticAge.biologicalAge,
            labelPosition: LabelPosition.BottomLeft
        },
        {
            label: `AGE BIO: ${report.alphaReport.realAge}`,
            value: report.alphaReport.realAge,
            labelPosition: LabelPosition.BottomRight
        },
        {
            label: `CHRONOLOGICAL AGE: ${report.alphaReport.chronologicalAge}`,
            value: report.alphaReport.chronologicalAge,
            labelPosition: LabelPosition.TopRight
        }
    ].filter(x => x.value !== 0)

    return (
        props.mode !== HealthReportMode.PrintMode && isSmallScreen ? (
            <HealthReportMobileBasePage section={HealthReportSections.Longevity} pageNumber={pageNumber} practice={practice}>
                <Box>
                    <Box px={2}>
                        <Box display="flex" justifyContent="center">
                            <Box textAlign="center">
                                <Box>
                                    {report.alphaReport.epiGeneticAge.biologicalAge === 0 && report.alphaReport.realAge === 0
                                        ? <span style={{ color: '#999999' }} className="size-1_5">Get your Biological age from 
                                            <a id="report-link-elysium" style={{ color: '#999999' }} target="_blank" rel="noopener noreferrer" href="https://www.elysiumhealth.com/en-us/index"> TruDiagnostic </a> to find out how well you are aging.</span>
                                        : <span className={classes.reportTitle}>Your Biological Age:</span>}
                                </Box>
                                <Box mt="10px">
                                    <span className="report-value" style={{ fontSize: 34, fontWeight: 400 }}>
                                        {report.alphaReport.epiGeneticAge.biologicalAge !== 0
                                            ? report.alphaReport.epiGeneticAge.biologicalAge
                                            : report.alphaReport.realAge !== 0
                                                ? report.alphaReport.realAge
                                                : ""}
                                    </span>
                                </Box>
                            </Box>
                        </Box>
                        <Box mt="20px">
                            <ProgressDiagramComponent
                                height={22}
                                colors={['#7d9259', '#5f7990']}
                                data={ageData}
                            />
                        </Box>
                    </Box>
                    <Divider className="report-divider" />
                    <Box px={2}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box>
                                    <Box textAlign="center">
                                        <span className="text-medium">Oxidative Stress</span>
                                    </Box>
                                    <Box mt={1}>
                                        <DnaTableComponent
                                            dna={report.longevityReport.oxidativeStressRiskScore.dna}
                                            colorMap={riskColorMap} />
                                    </Box>
                                </Box>
                                <Box mt="20px">
                                    <Box textAlign="center">
                                        <span className="text-medium">Telomeres</span>
                                    </Box>
                                    <Box mt={1}>
                                        <DnaTableComponent
                                            dna={report.longevityReport.telomersRiskScore.dna}
                                            colorMap={riskColorMap} />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box mt="20px">
                                    <Box textAlign="center">
                                        <span className="text-medium">Cancer Risk</span>
                                    </Box>
                                    <Box mt={1}>
                                        <DnaTableComponent
                                            dna={report.longevityReport.cancerRiskScore.dna}
                                            colorMap={riskColorMap} />
                                    </Box>
                                </Box>
                                <Box mt="20px">
                                    <Box textAlign="center">
                                        <span className="text-medium">Dementia Risk</span>
                                    </Box>
                                    <Box mt={1}>
                                        <Box display="flex" justifyContent="space-between">
                                            <Box>
                                                <span>
                                                    Apo-ε:
                                                </span>
                                            </Box>
                                            <Box>
                                                <span>
                                                    {report.dietAndNutritionReport?.apoeAccuracyScore?.apoe}
                                                </span>
                                            </Box>
                                        </Box>
                                        <Box mt={1}>
                                            <DnaTableComponent
                                                dna={report.longevityReport.dementiaRiskScore.dna}
                                                colorMap={riskColorMap} />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box mt="20px">
                                    <Box textAlign="center">
                                        <span className="text-medium">Metabolism</span>
                                    </Box>
                                    <Box mt={1}>
                                        <DnaTableComponent
                                            dna={report.longevityReport.metabolismScore.dna}
                                            colorMap={riskColorMap} />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box mt="20px">
                                    <Box>
                                        <span className="report-sub-title">Longevity Labs</span>
                                    </Box>
                                    <LabResultComponent label="DHEA" value={labValues.getLabReportValue(LabInputNames.Dhea)} />
                                    <LabResultComponent label="iGf" value={labValues.getLabReportValue(LabInputNames.Igf)} />
                                    <LabResultComponent label="TSH" value={labValues.getLabReportValue(LabInputNames.Tsh)} />
                                    <LabResultComponent label="T4" value={labValues.getLabReportValue(LabInputNames.FreeT4)} />
                                    <LabResultComponent label="Testosterone, Free" value={labValues.getLabReportValue(LabInputNames.TestosteroneFree)} />
                                    <LabResultComponent label="Hgb-A1c" value={labValues.getLabReportValue(LabInputNames.HgbA1C)} />
                                </Box>
                                <Box mt="20px">
                                    <Box textAlign="center">
                                        <span className="text-medium">Lifespan</span>
                                    </Box>
                                    <Box mt={1}>
                                        <DnaTableComponent
                                            dna={report.longevityReport.lifespanScore.dna}
                                            colorMap={riskColorMap} />
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </HealthReportMobileBasePage>
        ) : (
            <HealthReportBasePage section={HealthReportSections.Longevity} pageNumber={pageNumber} practice={practice}>
                <Box>
                    <Box px={15} pt={5} pb={3}>
                        <Box display="flex" justifyContent="center">
                            <Box display="flex" justifyContent="flex-start" alignItems="center">
                                <Box>
                                    {report.alphaReport.epiGeneticAge.biologicalAge === 0 && report.alphaReport.realAge === 0
                                        ? <span style={{ color: '#999999' }} className="size-1_5">Get your Biological age from 
                                            <a id="report-link-elysium" style={{ color: '#999999' }} target="_blank" rel="noopener noreferrer" href="https://www.elysiumhealth.com/en-us/index"> TruDiagnostic </a> to find out how well you are aging.</span>
                                        : <span className="report-title">Your Biological Age:</span>}
                                </Box>
                                <Box ml={2} mb={-1}>
                                    <span className="report-value">
                                        {report.alphaReport.epiGeneticAge.biologicalAge !== 0
                                            ? report.alphaReport.epiGeneticAge.biologicalAge
                                            : report.alphaReport.realAge !== 0
                                                ? report.alphaReport.realAge
                                                : ""}
                                    </span>
                                </Box>
                            </Box>
                        </Box>
                        <Box mt={10} mb={5}>
                            <ProgressDiagramComponent
                                height={22}
                                colors={['#7d9259', '#5f7990']}
                                data={ageData}
                            />
                        </Box>
                    </Box>
                    <Divider className="report-divider" />
                    <Box px={15} py={3}>
                        <Grid container>
                            <Grid item xs={12} md={4} lg={4}>
                                <Box px={5} mt={3}>
                                    <Box textAlign="center">
                                        <span className="text-medium">Oxidative Stress</span>
                                    </Box>
                                    <Box mt={5}>
                                        <DnaTableComponent
                                            dna={report.longevityReport.oxidativeStressRiskScore.dna}
                                            colorMap={riskColorMap} />
                                    </Box>
                                </Box>

                                <Box px={5} mt={5}>
                                    <Box textAlign="center">
                                        <span className="text-medium">Cancer Risk</span>
                                    </Box>
                                    <Box mt={5}>
                                        <DnaTableComponent
                                            dna={report.longevityReport.cancerRiskScore.dna}
                                            colorMap={riskColorMap} />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <Box px={5} mt={3}>
                                    <Box textAlign="center">
                                        <span className="text-medium">Telomeres</span>
                                    </Box>
                                    <Box mt={5}>
                                        <DnaTableComponent
                                            dna={report.longevityReport.telomersRiskScore.dna}
                                            colorMap={riskColorMap} />
                                    </Box>
                                </Box>

                                <Box px={5} mt={5}>
                                    <Box textAlign="center">
                                        <span className="text-medium">Dementia Risk</span>
                                    </Box>
                                    <Box mt={5}>
                                        <Box display="flex" justifyContent="space-between">
                                            <Box>
                                                <span>
                                                    Apo-ε:
                                                </span>
                                            </Box>
                                            <Box>
                                                <span>
                                                    {report.dietAndNutritionReport?.apoeAccuracyScore?.apoe}
                                                </span>
                                            </Box>
                                        </Box>
                                        <Box mt={1}>
                                            <DnaTableComponent
                                                dna={report.longevityReport.dementiaRiskScore.dna}
                                                colorMap={riskColorMap} />
                                        </Box>
                                    </Box>
                                </Box>

                                <Box px={5} mt={5}>
                                    <Box textAlign="center">
                                        <span className="text-medium">Metabolism</span>
                                    </Box>
                                    <Box mt={5}>
                                        <DnaTableComponent
                                            dna={report.longevityReport.metabolismScore.dna}
                                            colorMap={riskColorMap} />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <Box px={5} mt={3}>
                                    <Box>
                                        <span className="report-sub-title">Longevity Labs</span>
                                    </Box>
                                    <LabResultComponent label="DHEA" value={labValues.getLabReportValue(LabInputNames.Dhea)} />
                                    <LabResultComponent label="iGf" value={labValues.getLabReportValue(LabInputNames.Igf)} />
                                    <LabResultComponent label="TSH" value={labValues.getLabReportValue(LabInputNames.Tsh)} />
                                    <LabResultComponent label="T4" value={labValues.getLabReportValue(LabInputNames.FreeT4)} />
                                    <LabResultComponent label="Testosterone, Free" value={labValues.getLabReportValue(LabInputNames.TestosteroneFree)} />
                                    <LabResultComponent label="Hgb-A1c" value={labValues.getLabReportValue(LabInputNames.HgbA1C)} />
                                </Box>
                                <Box px={5} mt={5}>
                                    <Box textAlign="center">
                                        <span className="text-medium">Lifespan</span>
                                    </Box>
                                    <Box mt={5}>
                                        <DnaTableComponent
                                            dna={report.longevityReport.lifespanScore.dna}
                                            colorMap={riskColorMap} />
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </HealthReportBasePage>
        )
    )
}
