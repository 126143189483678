import React from "react";
import { Box, Divider, useTheme, useMediaQuery } from "@material-ui/core";
import { useStyles } from './NoteRecommendationsComponent.styles'
import { TextMarkdownComponent } from "../textMarkdownComponent/TextMarkdownComponent";
import clsx from 'clsx';
import commonUseStyles from "../../../common/styles/common.styles";

interface NoteRecommendationsComponentProps {
    recommendation: string;
}

export const NoteRecommendationsComponent: React.FC<NoteRecommendationsComponentProps> = (props: NoteRecommendationsComponentProps) => {
    const { recommendation } = props;
    const classes = useStyles();
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const renderRecommendation = () => {
            return (
                <Box pb={3} key='recommendation'>
                    <Box mt={isSmallScreen ? "10px" : 2}>
                        <TextMarkdownComponent content={recommendation} classLink={classes.renderText} />
                    </Box>
                </Box>
            )
    }

    return (
        <Box>
            <Box mt={3}>
                <span className={isSmallScreen ? clsx(commonClasses.size16, commonClasses.textMedium, commonClasses.colorBlack) : "size-1_5 text-medium uppercase"}>What are SNPs?</span>
            </Box>
            <Box mt={2}>
                <span className="wh-tw-text-gray600">A SNP (Single Nucleotide Polymorphism) is a small change in the genetic code where one base (A, T, C, or G) is swapped for another. These variations are the most common type of genetic difference between people. While most SNPs have no effect, some can influence how we look, how our bodies function, or our risk of certain diseases.</span>
            </Box>
            <Box mt={1.5}>
                <span className="wh-tw-text-gray600">Imagine a gene as a tower made of LEGO blocks. Each block (nucleotide) has a specific place in the structure. A SNP would be like swapping out one specific LEGO block for a slightly different one. Most of the time, the tower still stands as intended, but occasionally, that one block could be crucial for the stability of the entire structure. </span>
            </Box>
            <Box mt={5}>
                <span className={isSmallScreen ? clsx(commonClasses.size16, commonClasses.textMedium, commonClasses.colorBlack) : "size-1_5 text-medium uppercase"}>TODAY’S COACHING</span>
            </Box>
            {isSmallScreen && <Box py={2}>
                <Divider className={commonClasses.renderDivider} />
            </Box>}
            <Box mt={!isSmallScreen && 3}>
                {
                    recommendation && recommendation.length &&
                    renderRecommendation()
                }
            </Box>
        </Box>
    )
}