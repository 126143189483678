import {
    Box,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
} from '@material-ui/core';
import React from 'react';
import { SearchComponent } from '../../../common/components/searchComponent/SearchComponent';
import { WildHealthContainer } from '../../../common/components/wildHealthContainer/WildHealthContainer';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { WildHealthLinkButton } from '../../../common/components/WildHealthLinkButton';
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { useFacade } from './manageEmployerProductsComponent.hooks';
import { FeatureComponent } from "../../../common/components/featureFlags/FeatureComponent";
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from 'clsx';
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { pageSizes } from "../../../common/pagination/models/page-sizes";
import { PaginationComponent } from "../../../common/pagination/components/paginationComponent/PaginationComponent";
import { EmployerDetailDialogComponent } from './EmployerDetailDialogComponent/EmployerDetailDialogComponent';

export const ManageEmployerProductsComponent: React.FC = () => {
    const [
        {
            isLoading,
            employers,
            isOpenEmployerDetail,
            selectedEmployerId,
            sortingColumns,
            sortingSource,
            totalCount,
            pageSize,
            selectedPage
        },
        handleSearchSubmit,
        handleSorting,
        getAllAvailablePages,
        handlePageSizeChange,
        handlePageChange,
        handleToggleEmployerDetail
    ] = useFacade();

    const commonClasses = commonUseStyles();

    const renderTable = () => {
        if (isLoading) {
            return <WildHealthLinearProgress />
        }

        if (!isLoading && (!employers || !employers.length)) {
            return <WildHealthPlaceHolder message="No current employers found" />
        }

        return (
            <>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {
                                    sortingColumns.map((item, index) =>
                                        <WildHealthTableCell
                                            key={index}
                                        >
                                            <TableSortLabel
                                                id={`manage-employer-product-sorting-${item.title}`}
                                                active={sortingSource === item.source}
                                                direction={sortingSource === item.source ? item.direction : 'asc'}
                                                onClick={() => handleSorting(item.source, item.direction)}
                                            >
                                                {item.title}
                                            </TableSortLabel>
                                        </WildHealthTableCell>
                                    )
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {employers.slice((selectedPage - 1) * pageSize, (selectedPage - 1) * pageSize + pageSize).map((employer, index) => (
                                <WildHealthTableRow key={index}>
                                    <WildHealthTableCell align="left">
                                        <WildHealthLinkButton
                                            id={`manage-employer-product-${employer.id}-${employer.name}`}
                                            onClick={() => handleToggleEmployerDetail(!isOpenEmployerDetail, employer.id)}
                                            content={<Box className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorBlack)}>{employer.name}</Box>}
                                        />
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="left">
                                        <WildHealthLinkButton
                                            id={`manage-employer-product-${employer.id}-${employer.key}`}
                                            onClick={() => handleToggleEmployerDetail(!isOpenEmployerDetail, employer.id)}
                                            content={<Box className={clsx(commonClasses.size14, commonClasses.textRegular, commonClasses.colorBlack)}>{employer.key}</Box>}
                                        />
                                    </WildHealthTableCell>
                                </WildHealthTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box py={1} px={2}>
                    <PaginationComponent
                        pageSizes={pageSizes}
                        pageSize={pageSize}
                        selectedPage={selectedPage}
                        handlePageSizeChange={handlePageSizeChange}
                        totalCount={totalCount}
                        availablePages={getAllAvailablePages()}
                        handlePageSelect={handlePageChange}
                    />
                </Box>
            </>
        )
    }

    return (
        <Box py={2} width={1}>
            <Box my={2} display="flex" alignItems="center" justifyContent="space-between">
                <Box>
                    <FeatureComponent featureFlag={FeatureFlag.Navigation}>
                        <Box className={clsx(commonClasses.size24, commonClasses.textSemiBold, commonClasses.colorBlack)}>{PagesNamesService.employers}</Box>
                    </FeatureComponent>
                </Box>
                <Box>
                    <SearchComponent handleSearch={handleSearchSubmit} placeholder="Search by Name or Key" />
                </Box>
            </Box>

            <WildHealthContainer margin={0} minHeight={'100%'}>
                {renderTable()}
            </WildHealthContainer>
            <EmployerDetailDialogComponent
                isOpen={isOpenEmployerDetail}
                employeeId={selectedEmployerId}
                handleToggleDialog={handleToggleEmployerDetail}
            />
        </Box>
    );
}