import Axios from 'axios-observable';
import { Observable } from "rxjs";
import { authHeader } from "../../common/helpers/auth-header";
import { API_URL } from "../../../config";
import { snackService } from '../../common/snack/state';
import { RecommendationModel, CreateRecommendationModel, RecommendationTriggerModel, MetricModel, CreateRecommendationTriggerModel } from '../models/recommendations.model';
import { HealthReportsStore, healthReportsStore } from '../../healthReport/stores/healthReportsStore';
import {HealthReportRecommendationDefaultModel} from "../../healthReport/models/healthReport.models";

/**
 * Provides method for working with recommendations
 */
export class RecommendationsService {
    private url = `${API_URL}Recommendations`;

    constructor(private store: HealthReportsStore) {
    }

    public getAllRecommendations(): Observable<RecommendationModel[]> {
        const config = {
            headers: authHeader()
        };
        return new Observable<RecommendationModel[]>(observer => {
            Axios.get<RecommendationModel[]>(`${this.url}`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({ recommendations: response.data })
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getPatientRecommendations(patientId: number): Observable<HealthReportRecommendationDefaultModel[]> {
        const config = {
            headers: authHeader()
        };
        return new Observable<HealthReportRecommendationDefaultModel[]>(observer => {
            Axios.get<HealthReportRecommendationDefaultModel[]>(`${this.url}/Patient/${patientId}`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({recommendationsDefault: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.complete();
                    }
                );
        })
    }

    public getMetrics(): Observable<MetricModel[]> {
        const config = {
            headers: authHeader()
        };
        return new Observable<MetricModel[]>(observer => {
            Axios.get<MetricModel[]>(`${this.url}/metrics`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({ metrics: response.data })
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public createRecommendation(model: CreateRecommendationModel) {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.post(`${this.url}`, model, config)
                .pipe()
                .subscribe(
                    response => {
                        this.store.addRecommendation(response.data);
                        snackService.success('Recommendation successfully created');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    public updateRecommendation(model: RecommendationModel) {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.put(`${this.url}/${model.id}`, model, config)
                .pipe()
                .subscribe(
                    response => {
                        this.store.updateRecommendation(response.data);
                        snackService.success('Recommendation successfully updated');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    public deleteRecommendation(id: number) {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.delete(`${this.url}/${id}`, config)
                .pipe()
                .subscribe(
                    () => {
                        this.store.removeRecommendation(id);
                        snackService.success('Recommendation successfully deleted');
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    public createRecommendationTrigger(model: CreateRecommendationTriggerModel) {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.post(`${this.url}/${model.recommendationId}/trigger`, model, config)
                .pipe()
                .subscribe(
                    response => {
                        this.store.addRecommendationTrigger(response.data);
                        snackService.success('Recommendation successfully created');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    public updateRecommendationTrigger(model: RecommendationTriggerModel) {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.put(`${this.url}/${model.recommendationId}/trigger/${model.id}`, model, config)
                .pipe()
                .subscribe(
                    response => {
                        this.store.updateRecommendationTrigger(response.data);
                        snackService.success('Recommendation Trigger successfully updated');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    public deleteRecommendationTrigger(recommendationId: number, triggerId: number) {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.delete(`${this.url}/${recommendationId}/trigger/${triggerId}`, config)
                .pipe()
                .subscribe(
                    () => {
                        this.store.removeRecommendationTrigger(recommendationId, triggerId);
                        snackService.success('Recommendation trigger successfully deleted');
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }
}

export const recommendationsService = new RecommendationsService(healthReportsStore);
