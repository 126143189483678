import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        notificationToolTipText: {
            fontFamily: 'Be Vietnam Pro',
            fontSize: 12,
            fontWeight: 400,
            color: colors.white,
        },
        customWidth: {
            maxWidth: 'none',
            backgroundColor: colors.navy900,
            padding: 8,
        },
        arrow: {
            color: colors.navy900,
        },
        tooltipPlacement: {
            margin: '8px 0',
        },
    })
)