import {EmployeeShortModel} from "../../../employee/models/employee.models";
import React, {useState} from "react";
import {Avatar, Box} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import {colors} from "../../../common/constants/colors";
import clsx from "clsx";
import {useStyles} from "./ProviderInfoComponent.styles";
import {getPhotoSrc} from "../../../common/helpers/getPhotoSrc";
import commonUseStyles from "../../../common/styles/common.styles";

interface ProviderInfoComponentProps {
    provider: EmployeeShortModel;
}

interface ProviderInfoComponentState {
    isOpen: boolean;
}

export const ProviderInfoComponent: React.FC<ProviderInfoComponentProps> = (props: ProviderInfoComponentProps) => {
    const {
        provider
    } = props;

    const [state, setState] = useState({
        isOpen: false
    } as ProviderInfoComponentState);

    const handleClick = (show: boolean) => {
        setState(state => ({
            ...state,
            isOpen: show
        }))
    }

    const classes = useStyles();
    const commonClasses = commonUseStyles();

    return (
        <>
            <Box position='relative'>
                <Box display="flex"
                     alignItems="center"
                     className={classes.icon}
                     onMouseEnter={() => handleClick(true)}
                     onMouseLeave={() => handleClick(false)}>
                    <Box>
                        Learn more
                    </Box>
                    <Box pl={0.5} pt={1}>
                        <InfoIcon fontSize='small' style={{ fill: colors.gray2 }} />
                    </Box>
                </Box>
                {
                    state.isOpen &&
                    <Box className={clsx(classes.tooltip, 'tooltip-element')}>
                        <Box>
                            <Box display="flex" justifyContent="flex-start" alignItems="center">
                                <Box>
                                    <Avatar className={classes.avatar} src={getPhotoSrc(provider.photoId)}>{provider.firstName[0]}</Avatar>
                                </Box>
                                <Box ml={2}>
                                    <span className={commonClasses.size18}>
                                        {provider.firstName} {provider.lastName}
                                    </span>
                                </Box>
                            </Box>
                            <Box mt={1} display="flex">
                                <Box> {provider?.bio} </Box>
                            </Box>
                        </Box>
                    </Box>
                }
            </Box>
        </>
    )
}