import { Query } from '@datorama/akita';
import {
    LabOrdersState,
    labOrdersStore,
    LabOrdersStore
} from "./labOrders.store";

export class LabOrdersQuery extends Query<LabOrdersState> {
    orders$ = this.select(state => state.orders);
    unmatchedDocuments$ = this.select(state => state.unmatchedDocuments);
    isUnmatchedFile$ = this.select(state => state.isUnmatchedFile);

    constructor(protected store: LabOrdersStore) {
        super(store);
    }
}

export const labOrdersQuery = new LabOrdersQuery(labOrdersStore);
