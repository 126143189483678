export class ApoeConstants {
    static readonly Apoe11: string = "E1/E1";
    static readonly Apoe12: string = "E1/E2";
    static readonly Apoe13: string = "E1/E3";
    static readonly Apoe24: string = "E2/E4";
    static readonly Apoe14: string = "E1/E4";
    static readonly Apoe22: string = "E2/E2";
    static readonly Apoe23: string = "E2/E3";
    static readonly Apoe33: string = "E3/E3";
    static readonly Apoe34: string = "E3/E4";
    static readonly Apoe44: string = "E4/E4";
    static readonly Default: string = "Not available";
}

export class ApoeSoursConstants {
    static readonly BloodTest: string = "Manual Blood Test";
    static readonly Other: string = "Manual Other";
}

export const apoeSoursNames: {[name: string]: string} = {
    [ApoeSoursConstants.BloodTest]: "Blood Test",
    [ApoeSoursConstants.Other]: "Other",
}

export const apoeNames: {[name: string]: string} = {
    [ApoeConstants.Apoe11]: "Apo-ε1 / ε1",
    [ApoeConstants.Apoe12]: "Apo-ε1 / ε2",
    [ApoeConstants.Apoe13]: "Apo-ε1 / ε3",
    [ApoeConstants.Apoe14]: "Apo-ε1 / ε4",
    [ApoeConstants.Apoe22]: "Apo-ε2 / ε2",
    [ApoeConstants.Apoe23]: "Apo-ε2 / ε3",
    [ApoeConstants.Apoe24]: "Apo-ε2 / ε4",
    [ApoeConstants.Apoe33]: "Apo-ε3 / ε3",
    [ApoeConstants.Apoe34]: "Apo-ε3 / ε4",
    [ApoeConstants.Apoe44]: "Apo-ε4 / ε4",
    [ApoeConstants.Default]: "No Apo-ε provided in your genome",
}

export const apoes = ["E2/E2", "E2/E3", "E2/E4", "E3/E3", "E3/E4", "E4/E4"]