import {
    Box, Grid,
} from '@material-ui/core';
import React from 'react';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { useFacade } from "./documentsComponent.hooks";
import { DocumentCategoryCardComponent } from '../documentCategoryCardComponent/DocumentCategoryCardComponent';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import { InputsComponentProps } from '../../../inputs/components/inputsComponentProps';
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from 'clsx';
import { UploadDocumentsDialog } from '../../../inputs/components/uploadDocumentsDialog/UploadDocumentsDialog';
import { UploadDocumentsFilesComponent } from '../uploadDocumentsFilesComponent/UploadDocumentsFilesComponent';
import { BackTitleComponent } from '../../../../components/pageTitleComponent/PageTitleComponent';

export const DocumentsComponent: React.FC<InputsComponentProps> = (props: InputsComponentProps) => {
    const { patientId } = props;
    const [
        {

            isLoading,
            isUploading,
            documentsCategory,
            activeCategory
        },
        handleGoToDocuments,
        handleOpenDocumentsList,
        handleDocumentUpload,
    ] = useFacade(patientId);

    const commonClasses = commonUseStyles();

    const documentsFileList = (type: number) => {
        return documentsCategory.find(el => el.type === type)
    }

    const renderContent = () => {
        return !activeCategory
            ? (<>
                <Box mb={5} display="flex" justifyContent="space-between">
                    <span className={clsx(commonClasses.size20, commonClasses.textMedium)}>Documents</span>
                    <WildHealthButton id="documents-upload-new" onClick={() => { handleDocumentUpload() }} disabled={isUploading} loading={isUploading}>
                        Upload New Document
                    </WildHealthButton>
                </Box>
                <Grid container spacing={4}>
                    {documentsCategory.map((item, index) => (
                        <Grid item xs={12} sm={12} md={6} lg={4}key={index}>
                            <DocumentCategoryCardComponent
                                isEmployeePerspective={!!patientId}
                                category={item}
                                handleStart={handleOpenDocumentsList}
                            />
                        </Grid>

                    ))}
                </Grid>
                <UploadDocumentsDialog />
            </>
            )
            : <UploadDocumentsFilesComponent
                patientId={patientId}
                file={documentsFileList(activeCategory.type)}
            />
    }

    return (
        <>
            {activeCategory && <Box pb={2.5} className={commonClasses.bgMain}>
                <BackTitleComponent
                    title='Back to Documents'
                    action={() => { handleGoToDocuments() }}
                />
            </Box>}
            <Box p='30px'>
                {
                    isLoading
                        ? <WildHealthLinearProgress />
                        : renderContent()
                }
            </Box>
        </>
    )
}
