import React from "react";
import { useHistory } from 'react-router';
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { Box, IconButton, useMediaQuery, useTheme } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { WildHealthTabControl } from "../../../common/components/wildHealthTabControl/WildHealthTabControl";
import { TabItem } from "../../../common/components/wildHealthTabControl/wildHealthTabControl.hooks";
import { useFacade } from "./patientJourneyTasksComponent.hooks";
import { useStylesThreeWidthTabs } from "../../../common/components/wildHealthTabControl/wildHealthTabControl.styles";
import { PatientTodoTasksComponent } from "../PatientTodoTasksComponent/PatientTodoTasksComponent";
import { PatientCompletedTasksComponent } from "../PatientCompletedTasksComponent/PatientCompletedTasksComponent";
import { PatientRewardTasksComponent } from "../PatientRewardTasksComponent/PatientRewardTasksComponent";
import { navigationService } from "../../../../services/navigation.service";
import { BackButton } from '../../../common/components/wildHealthButton/BackButton';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';

interface PatientJourneyTasksComponentProps {
    patientId?: number | null;
    selectedTab?: number | null;
    className?: ClassNameMap<'tab' | 'selectedTab'>;
    fullWidth?: boolean;
}

export const PatientJourneyTasksComponent: React.FC<PatientJourneyTasksComponentProps> = (props: PatientJourneyTasksComponentProps) => {
    const {
        patientId,
        className,
    } = props;

    const [
        {
            isLoading,
            selectedTab,
        },
        handleChangeTab
    ] = useFacade(patientId, props.selectedTab);

    const history = useHistory();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const initialClassName = useStylesThreeWidthTabs();

    if (isLoading) {
        return <WildHealthLinearProgress />
    }

    const tabs: TabItem[] = [
        {
            title: 'To Do',
            content: <PatientTodoTasksComponent patientId={patientId} />,
            keepMounted: true,
        },
        {
            title: 'Completed',
            content: <PatientCompletedTasksComponent patientId={patientId} />,
            keepMounted: true,
        },
        {
            title: 'Rewards',
            content: <PatientRewardTasksComponent patientId={patientId} />,
            keepMounted: true,
        },
    ];

    const renderGoBack = () => {
        if (patientId) {
            return (
                <Box p={2} pb="10px">
                    <BackButton id="go-to-dashboard" onClick={() => navigationService.goBack(history)}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                        >
                            <ArrowBackIcon fontSize="small" />
                            <Box ml={1}>
                                <span>Back to Dashboard</span>
                            </Box>
                        </Box>
                    </BackButton>
                </Box>
            )
        }

        return (
            <Box p={2} pb="10px" display={isSmallScreen ? 'flex' : 'none'} alignItems='center' gridGap={8}>
                <IconButton
                    id="go-to-dashboard"
                    size="small"
                    onClick={() => navigationService.goBack(history)}
                >
                    <ChevronLeftIcon className="wh-tw-text-gray900" />
                </IconButton>
                <Box className="wh-tw-text-2xl wh-tw-font-semibold">
                    Patient Journey 
                </Box>
            </Box>
        )
    }

    return (
        <Box px={isSmallScreen && 2}>
            {renderGoBack()}
            <WildHealthTabControl
                items={tabs}
                className={className ?? initialClassName}
                initTab={selectedTab}
                onChangeCB={handleChangeTab}
            />
        </Box>
    )
}
