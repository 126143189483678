import React from 'react';
import ExitToAppTwoToneIcon from "@material-ui/icons/ExitToAppTwoTone";
import { IconProps } from './IconProps';
import { colors } from '../../modules/common/constants/colors';

const SignOutIcon: React.FC<IconProps> = (props: IconProps) => {
  const { isSelected } = props;

  return (
    <ExitToAppTwoToneIcon htmlColor={colors.gray2} fill={isSelected ? colors.main : colors.gray2} />
  )
}

export default SignOutIcon;