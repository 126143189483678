import React from 'react';
import {
    Box,
    IconButton,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    Dialog,
    DialogContent,
    TextField,
} from "@material-ui/core";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { ReactComponent as EditIcon } from '@img/icons/Edit1.svg';
import { useFacade } from "./managePreauthorizedEmployeesComponent.hooks";
import { useStyles } from "./managePreauthorizedEmployeesComponent.styles";
import { AddEmployeesButtonComponent } from "../addEmployeesButtonComponent/AddEmployeesButtonComponent";
import { SendSignUpButtonComponent } from '../sendSignUpButtonComponent/SendSignUpButtonComponent';
import WildHealthLinearProgress from "../../../../../common/components/WildHealthLinearProgress";
import { WildHealthPlaceHolder } from "../../../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { WildHealthTableCell } from "../../../../../common/components/wildHealthTable/WildHealthTableCell";
import { WildHealthTableRow } from "../../../../../common/components/wildHealthTable/WildHealthTableRow";
import { WildHealthButton } from "../../../../../common/components/wildHealthButton/WildHealthButton";

interface ManagePreauthorizedEmployeesComponentProps {
    productId: number;
}

export const ManagePreauthorizedEmployeesComponent: React.FC<ManagePreauthorizedEmployeesComponentProps> = (props: ManagePreauthorizedEmployeesComponentProps) => {
    const classes = useStyles();
    
    const [
        {
            isLoading,
            isUpdating,
            requests,
            selectedRequest,
            isEditOpen,
            errors,
        },
        handleToggleEditProfile,
        handleChanges,
        handleUpdateProfile
    ] = useFacade(props.productId);

    const renderTable = () => {
        if (isLoading) {
            return <WildHealthLinearProgress/>
        }

        if (!isLoading && (!requests || !requests.length)) {
            return <WildHealthPlaceHolder message="No current records found"/>
        }

        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <WildHealthTableRow>
                            <WildHealthTableCell>
                                <Box className="wh-tw-text-black wh-tw-text-sm wh-tw-font-semibold wh-tw-font-beVietnamPro">First Name</Box>
                            </WildHealthTableCell>
                            <WildHealthTableCell>
                                <Box className="wh-tw-text-black wh-tw-text-sm wh-tw-font-semibold wh-tw-font-beVietnamPro">Last Name</Box>
                            </WildHealthTableCell>
                            <WildHealthTableCell>
                                <Box className="wh-tw-text-black wh-tw-text-sm wh-tw-font-semibold wh-tw-font-beVietnamPro">Email</Box>
                            </WildHealthTableCell>
                            <WildHealthTableCell>
                                <Box className="wh-tw-text-black wh-tw-text-sm wh-tw-font-semibold wh-tw-font-beVietnamPro">Status</Box>
                            </WildHealthTableCell>
                            <WildHealthTableCell style={{ width: '20%' }} />
                        </WildHealthTableRow>
                    </TableHead>
                    <TableBody>
                        {
                            requests.map(item =>
                                <WildHealthTableRow key={item.id}>
                                    <WildHealthTableCell>
                                        <span className="wh-tw-font-beVietnamPro">{item.firstName}</span>
                                    </WildHealthTableCell>
                                    <WildHealthTableCell>
                                        <span className="wh-tw-font-beVietnamPro">{item.lastName}</span>
                                    </WildHealthTableCell>
                                    <WildHealthTableCell>
                                        <span className="wh-tw-font-beVietnamPro">{item.email}</span>
                                    </WildHealthTableCell>
                                    <WildHealthTableCell>
                                        {!item.paymentPlanId
                                            ? <Box px={1.5} py={1} className="wh-tw-rounded-full wh-tw-bg-gray300 wh-tw-text-gray700 wh-tw-font-medium wh-tw-font-beVietnamPro wh-tw-w-fit">Inactive</Box>
                                            : !item.isCompleted
                                                ? <Box px={1.5} py={1} className="wh-tw-rounded-full wh-tw-bg-gray300 wh-tw-text-gray700 wh-tw-font-medium wh-tw-font-beVietnamPro wh-tw-w-fit">Pending</Box>
                                                : <Box px={1.5} py={1} className="wh-tw-rounded-full wh-tw-bg-mint600 wh-tw-text-white wh-tw-font-medium wh-tw-font-beVietnamPro wh-tw-w-fit">Active</Box>
                                        }
                                    </WildHealthTableCell>
                                    <WildHealthTableCell>
                                        <Box width={1} display="flex" justifyContent="flex-end" className="wh-tw-opacity-0 hover:wh-tw-opacity-100">
                                            <Box display="flex" alignItems="center" className="wh-tw-cursor-pointer" gridGap={4} onClick={() => handleToggleEditProfile(item.id)}>
                                                <EditIcon className="wh-tw-cursor-pointer wh-tw-fill-primaryV" />
                                                <span className="wh-tw-text-sm wh-tw-text-primaryV">Edit Profile</span>
                                            </Box>
                                        </Box>
                                    </WildHealthTableCell>
                                </WildHealthTableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    return (
        <Box>
            <Box mt={3} mb={2} display="flex" alignItems="center" justifyContent="space-between">
                <Box className="wh-tw-text-xl wh-tw-font-medium">
                    List of Employees
                </Box>
                <Box display="flex" alignItems="center" gridGap={16}>
                    <Box>
                        <SendSignUpButtonComponent productId={props.productId} />
                    </Box>
                    <Box>
                        <AddEmployeesButtonComponent productId={props.productId} />
                    </Box>
                </Box>
            </Box>
            <Box className="wh-tw-bg-white" width={1}>
                {
                    renderTable()
                }
            </Box>
            <Dialog
                maxWidth="md"
                onClose={() => handleToggleEditProfile(0)}
                className={classes.root}
                open={isEditOpen}
            >
                <MuiDialogTitle
                    className={classes.dialogTitle}
                    disableTypography
                >
                    <IconButton
                        id="update-employee-edit"
                        className={classes.dialogTitleCloseAction}
                        onClick={() => handleToggleEditProfile(0)}
                    >
                        <CloseIcon />
                    </IconButton>
                </MuiDialogTitle>

                <DialogContent>
                    <Box className="wh-tw-text-xl wh-tw-font-medium" mb={3}>Edit Employee Profile</Box>
                    <Box mb={1} display="flex" gridGap={12}>
                        <Box width={190}>
                            <Box className="wh-tw-text-xxs wh-tw-text-gray600 wh-tw-uppercase" mb={1}>First name <span className="wh-tw-text-primaryV">*</span></Box>
                            <TextField
                                fullWidth
                                required
                                InputProps={{ className: 'input', classes }}
                                id="firstName"
                                variant="outlined"
                                disabled={isUpdating || !isEditOpen}
                                error={!!errors['firstName']}
                                helperText={errors['firstName']}
                                value={selectedRequest?.firstName}
                                onChange={(event) => handleChanges(event.target.id, event.target.value)}
                            />
                        </Box>
                        <Box width={190}>
                            <Box className="wh-tw-text-xxs wh-tw-text-gray600 wh-tw-uppercase" mb={1}>Last name <span className="wh-tw-text-primaryV">*</span></Box>
                            <TextField
                                fullWidth
                                required
                                InputProps={{ className: 'input', classes }}
                                id="lastName"
                                variant="outlined"
                                disabled={isUpdating || !isEditOpen}
                                error={!!errors['lastName']}
                                helperText={errors['lastName']}
                                value={selectedRequest?.lastName}
                                onChange={(event) => handleChanges(event.target.id, event.target.value)}
                            />
                        </Box>
                        <Box width={328}>
                            <Box className="wh-tw-text-xxs wh-tw-text-gray600 wh-tw-uppercase" mb={1}>Email <span className="wh-tw-text-primaryV">*</span></Box>
                            <TextField
                                fullWidth
                                required
                                InputProps={{ className: 'input', classes }}
                                id="email"
                                variant="outlined"
                                disabled={isUpdating || !isEditOpen}
                                error={!!errors['email']}
                                helperText={errors['email']}
                                value={selectedRequest?.email}
                                onChange={(event) => handleChanges(event.target.id, event.target.value)}
                            />
                        </Box>
                    </Box>
                    <Box my={3} display="flex" alignItems="center" justifyContent="flex-end" gridGap={16}>
                        <Box>
                            <WildHealthButton
                                id="update-employee-cancel"
                                onClick={() => handleToggleEditProfile(0)}
                                size="large"
                                color="secondary"
                                borderRadius={50}
                                disabled={isUpdating || !isEditOpen}
                            >
                                Cancel
                            </WildHealthButton>
                        </Box>
                        <Box>
                            <WildHealthButton
                                id="update-employee-save"
                                onClick={() => handleUpdateProfile()}
                                size="large"
                                borderRadius={50}
                                disabled={!isEditOpen || Object.keys(errors).length > 0}
                                loading={isUpdating}
                            >
                                <Box px={2}>Save</Box>
                            </WildHealthButton>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    )
}