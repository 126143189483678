import React, { useState, useEffect } from "react";
import { PaymentRequestButtonElement, useStripe } from "@stripe/react-stripe-js";
import { Box } from "@material-ui/core";
import { PaymentPriceModel } from "../../models/paymentPrice.models";
import { SelectAddOnModel } from '../../../addons/models/addOns.models';
import { paymentService } from "../../services/payment.service";
import { snackService } from "../../../common/snack/state";

interface PayButtonComponentProps {
    selectedPrice?: PaymentPriceModel;
    selectedAddOns?: SelectAddOnModel[];
    isPaid: boolean;
    handleSetPaid: () => void;
}

export const PayButtonComponent: React.FC<PayButtonComponentProps> = (props: PayButtonComponentProps) => {
    const {
        selectedPrice,
        selectedAddOns,
        isPaid,
        handleSetPaid
    } = props;

    const stripe = useStripe();
    const [paymentRequest, setPaymentRequest] = useState(null);

    const startupFee = selectedPrice?.startupFee ?? 0;
    const discount = selectedPrice?.discount ?? 0;
    const totalPrice = (selectedPrice?.originalPrice ?? 0) + startupFee - discount +
        (selectedAddOns?.reduce((sum, current) => sum + current.price, 0) ?? 0);

    useEffect(() => {
        if (stripe) {
            const pr = stripe.paymentRequest({
                country: 'US',
                currency: 'usd',
                total: {
                    label: 'Total Due Today',
                    amount: totalPrice * 100,
                },
                requestPayerName: true,
                requestPayerEmail: true,
            });
            const option = {
                amount: totalPrice * 100,
                currency: 'usd'
            }

            setPaymentRequest(null)
    
            // Check the availability of the Payment Request API.
            pr.canMakePayment().then(result => {
                if (result) {
                    pr.on('paymentmethod', async (ev) => {
                        paymentService.createStripePaymenIntent(option).subscribe(async (response) => {
                            // Confirm the PaymentIntent without handling potential next actions (yet).
                            const {paymentIntent, error: confirmError} = await stripe.confirmCardPayment(
                                response.client_secret,
                                {payment_method: ev.paymentMethod.id},
                                {handleActions: false}
                            );
                            if (confirmError) {
                                ev.complete('fail');
                            } else {
                                ev.complete('success');
                                if (paymentIntent.status === "requires_action") {
                                // Let Stripe.js handle the rest of the payment flow.
                                const {error} = await stripe.confirmCardPayment(response.client_secret);
                                    if (error) {
                                        // The payment failed -- ask your customer for a new payment method.
                                        snackService.error('Payment failed! Please use new payment method');
                                    } else {
                                        // The payment has succeeded -- show a success message to your customer.
                                        snackService.success('Payment succeeded!');
                                        handleSetPaid()
                                    }
                                } else {
                                    // The payment has succeeded -- show a success message to your customer.
                                    snackService.success('Payment succeeded!');
                                    handleSetPaid()
                                }
                            }
                        })
                    });
                    setPaymentRequest(pr);
                }
            });
        }
    }, [stripe, totalPrice, handleSetPaid]);

    if (isPaid) {
        return <Box px={1} py={1.5} className="wh-tw-text-primaryV wh-tw-bg-mint50">Payment succeeded!</Box>
    }
  
    if (paymentRequest) {
        return <PaymentRequestButtonElement options={{paymentRequest}} className="wh-tw-p-0" />
    }

    return (
        <></>
    );
}