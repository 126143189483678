import { Box, Button } from "@material-ui/core";
import LockIcon from '@material-ui/icons/Lock';
import { CardElement, Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useMemo } from "react";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { useFacade } from "./paymentFormComponent.hooks";
import './PaymentFormComponent.scss';
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from "clsx";

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: '#000',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#aab7c4'
            }
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a'
        }
    }
};

interface PaymentFormComponentProps extends PaymentFormProps {
    publicKey: string;
    provider: string;
}

export const PaymentFormComponent: React.FC<PaymentFormComponentProps> = (props: PaymentFormComponentProps) => {
    const {
        needCardData,
        totalPrice,
        callback,
        canSubmit,
        validate,
        isSubmitted,
        provider,
        confirmAgreements,
        publicKey
    } = props;

    const stripePromise = useMemo(() => loadStripe(publicKey), [publicKey]);

    return (
        <Elements stripe={stripePromise}>
            <PaymentForm
                needCardData={needCardData}
                totalPrice={totalPrice}
                callback={callback}
                canSubmit={canSubmit}
                validate={validate}
                isSubmitted={isSubmitted}
                provider={provider}
                confirmAgreements={confirmAgreements} />
        </Elements>
    )
}

interface PaymentFormProps {
    needCardData?: boolean;
    totalPrice: number;
    callback: Function;
    canSubmit: boolean;
    validate?: Function;
    isSubmitted: boolean;
    confirmAgreements?: JSX.Element;
    provider: string;
}

const PaymentForm: React.FC<PaymentFormProps> = (props: PaymentFormProps) => {
    const {
        needCardData,
        callback,
        canSubmit,
        validate,
        isSubmitted,
    } = props;

    const [
        {
            error
        },
        handleChange,
        handleSubmit
    ] = useFacade(callback, validate, needCardData);
    const commonClasses = commonUseStyles();

    const cardElement = needCardData && (
        <div className="form-row">
            <CardElement
                id="card-element"
                options={CARD_ELEMENT_OPTIONS}
                onChange={(event) => handleChange(event)}
            />
            <div className="card-errors" role="alert">{error}</div>
        </div>
    );

    return (
        <form onSubmit={(event) => handleSubmit(event)}>
            {cardElement}
            <Box mt={2}>
                {
                    !isSubmitted
                        ? <Button className={clsx("submit-button", commonClasses.whButton)} disabled={!!error || !canSubmit} type="submit">
                            Confirm Purchase
                        </Button>
                        : <WildHealthLinearProgress />
                }
                <Box display="flex">
                    <LockIcon 
                        color='disabled'
                    />
                    <p className={commonClasses.colorGray1}>Secured with 256-bit TLS encryption</p>
                </Box>
            </Box>
        </form>
    );
}
