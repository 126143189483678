import { useEffect, useState } from 'react';
import { Subscription } from 'rxjs';
import { onEmit } from '../../../../common/helpers/on-emit';
import { ShortcutGroupModel, ShortcutModel } from '../../../models/shortcut.models';
import { shortcutsQuery, shortcutsStore } from '../../../stores/shortcutsStore';

interface ShortcutTabsComponentState {
    selectedTab: number;
    targetShortcut: ShortcutModel;
    groups: ShortcutGroupModel[];
}


export function useFacade(): [
    ShortcutTabsComponentState,
    (tab: number) => void,
] {
    const [state, setState] = useState({
        selectedTab: 0,
        targetShortcut: null,
        groups: [],
    } as ShortcutTabsComponentState);

    const handleChangeTab = (tab: number) => {
        shortcutsStore.setTab(tab);
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<ShortcutGroupModel[]>(shortcutsQuery.groups$, groups => {
                setState(state => ({
                    ...state,
                    groups
                }));
            })
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe());
        };
    }, []);

    return [
        state,
        handleChangeTab,
    ];
}