import { Box, Table, TableBody, TableContainer, TableHead } from "@material-ui/core";
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import {RxntMedicationModel} from "../../models/patientMedicationModel";
import {toCurrentTimeZone} from "../../../timezones/helpers/timezone";
import moment from "moment";
import React from "react";

interface RxntMedicationsDCComponentProps {
    items: RxntMedicationModel[];
}

export const RxntMedicationsDCComponent: React.FC<RxntMedicationsDCComponentProps> = (props: RxntMedicationsDCComponentProps) => {
    const {
        items
    } = props;

    if (!items || !items.length) {
        return (
            <Box m={1}>
                <WildHealthPlaceHolder message="No data"/>
            </Box>
        );
    }

    const displayDataValue = (value: Date) => {
        return value ? moment(toCurrentTimeZone(value)).format("MM/DD/yyyy") : 'N/A';
    }

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <WildHealthTableRow>
                        <WildHealthTableCell style={{ width: '40%' }}>
                            <Box pl={2}>
                                Name
                            </Box>
                        </WildHealthTableCell>
                        <WildHealthTableCell style={{ width: '15%' }}>
                            Dosing Instruction
                        </WildHealthTableCell>
                        <WildHealthTableCell style={{ width: '15%' }}>
                            Number of refills
                        </WildHealthTableCell>
                        <WildHealthTableCell style={{ width: '15%' }}>
                            Start Date
                        </WildHealthTableCell>
                        <WildHealthTableCell style={{ width: '15%' }}>
                            Last Prescription Date
                        </WildHealthTableCell>
                    </WildHealthTableRow>
                </TableHead>
                <TableBody>
                    {
                        items.map((item, index) =>
                            <WildHealthTableRow key={index}>
                                <WildHealthTableCell>
                                    {item.name}
                                </WildHealthTableCell>
                                <WildHealthTableCell>
                                    {item.dosingInstructions}
                                </WildHealthTableCell>
                                <WildHealthTableCell>
                                    {item.numberOfRefills}
                                </WildHealthTableCell>
                                <WildHealthTableCell>
                                    {displayDataValue(item.startDate)}
                                </WildHealthTableCell>
                                <WildHealthTableCell>
                                    {displayDataValue(item.lastDatePrescribed)}
                                </WildHealthTableCell>
                            </WildHealthTableRow>
                        )
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}
