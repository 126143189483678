import { Gender } from "../../common/models/user.models";
import {
    LabGroup,
    LabInputNotificationType, LabRangeType,
    Optimization
} from "../../inputs/models/input.models";
import { ExercisePlanModel } from "./exercisePlan.models";
import { ReportType } from "./reportTemplate.models";

export enum HealthReportSections {
    RoadMap,
    Overall,
    DietAndNutrition,
    ExerciseAndRecovery,
    Sleep,
    Neurobehavioral,
    Microbiome,
    ChronicDisease,
    Longevity,
    Details,
    Labs,
    Appendix,
    GenomicGlossary,
    Recommendations,
}

export enum RecommendationTypesNumbers {
    Cardiovascular = 0,
    Dementia = 1,
    Inflammation = 2,
    InsulinResistance = 3,
    Longevity = 4,
    ExerciseAndRecovery = 5,
    Microbiome = 6,
    Neurobehavioral = 7,
    Sleep = 8,
    SuperFoods = 9,
    CompleteDiet = 10,
    KryptoniteFoods = 11,
    VitaminsAndMicronutrients = 12,
    Methylation = 13,
    Macronutrient = 14,
    Supplements = 15,
    Extra = 16,
}

export enum RecommendationTypes {
    Macronutrient,
    VitaminsAndMicronutrients,
    Methylation,
    KryptoniteFoods,
    SuperFoods,
    ExerciseAndRecovery,
    Sleep,
    Neurobehavioral,
    Microbiome,
    Cardiovascular,
    Dementia,
    InsulinResistance,
    Inflammation,
    Longevity,
    Supplements,
    Extra
}

export const recommendationRowsCapacity: { [type: number]: number } = {
    [RecommendationTypesNumbers.Macronutrient]: 16,
    [RecommendationTypesNumbers.Methylation]: 10,
    [RecommendationTypesNumbers.VitaminsAndMicronutrients]: 13,
    [RecommendationTypesNumbers.KryptoniteFoods]: 25,
    [RecommendationTypesNumbers.SuperFoods]: 25,
    [RecommendationTypesNumbers.CompleteDiet]: 10,
    [RecommendationTypesNumbers.ExerciseAndRecovery]: 32,
    [RecommendationTypesNumbers.Longevity]: 18,
    [RecommendationTypesNumbers.Microbiome]: 12,
    [RecommendationTypesNumbers.Neurobehavioral]: 20,
    [RecommendationTypesNumbers.Sleep]: 8,
    [RecommendationTypesNumbers.Cardiovascular]: 38,
    [RecommendationTypesNumbers.Dementia]: 7,
    [RecommendationTypesNumbers.InsulinResistance]: 7,
    [RecommendationTypesNumbers.Inflammation]: 8,
    [RecommendationTypesNumbers.Extra]: 39,
    [RecommendationTypesNumbers.Supplements]: 39
}


export const recommendationNameOld: { [type: number]: string } = {
    [RecommendationTypes.Macronutrient]: 'Macronutrients',
    [RecommendationTypes.Methylation]: 'Methylation',
    [RecommendationTypes.VitaminsAndMicronutrients]: 'Metabolism and Vitamins',
    [RecommendationTypes.KryptoniteFoods]: "Kryptonite Foods",
    [RecommendationTypes.SuperFoods]: "Super Foods",
    [RecommendationTypes.ExerciseAndRecovery]: "Exercise and Recovery",
    [RecommendationTypes.Longevity]: "Longevity",
    [RecommendationTypes.Microbiome]: "Microbiome",
    [RecommendationTypes.Neurobehavioral]: "Neurobehavioral",
    [RecommendationTypes.Sleep]: "Sleep",
    [RecommendationTypes.Cardiovascular]: "Сardiovascular Disease",
    [RecommendationTypes.Dementia]: "Dementia",
    [RecommendationTypes.InsulinResistance]: "Insulin Resistance",
    [RecommendationTypes.Inflammation]: "Inflammation",
    [RecommendationTypes.Supplements]: "Supplements"
}

export const recommendationName: { [type: number]: string } = {
    [RecommendationTypesNumbers.Macronutrient]: 'Macronutrients',
    [RecommendationTypesNumbers.Methylation]: 'Methylation',
    [RecommendationTypesNumbers.VitaminsAndMicronutrients]: 'Metabolism and Vitamins',
    [RecommendationTypesNumbers.KryptoniteFoods]: "Kryptonite Foods",
    [RecommendationTypesNumbers.SuperFoods]: "Super Foods",
    [RecommendationTypesNumbers.ExerciseAndRecovery]: "Exercise and Recovery",
    [RecommendationTypesNumbers.Longevity]: "Longevity",
    [RecommendationTypesNumbers.Microbiome]: "Microbiome",
    [RecommendationTypesNumbers.Neurobehavioral]: "Neurobehavioral",
    [RecommendationTypesNumbers.Sleep]: "Sleep",
    [RecommendationTypesNumbers.Cardiovascular]: "Сardiovascular Disease",
    [RecommendationTypesNumbers.Dementia]: "Dementia",
    [RecommendationTypesNumbers.InsulinResistance]: "Insulin Resistance",
    [RecommendationTypesNumbers.Inflammation]: "Inflammation",
    [RecommendationTypesNumbers.Supplements]: "Supplements"
}

export const dnaDefinitions: { [type: string]: string } = {
    "ACE(rs4646994)": "Angiotensin converting enzyme. ACE (angiotensin-converting enzyme) is the gene with the greatest evidence supporting its role in athletic performance. It affects both blood pressure and the fluid/salt balance in our blood.",
    "AGT(rs699)": "Angiotensinogen. AGT (Angiotensinogen) is associated with blood vessel constriction and blood pressure control.",
    "ACTN3(rs1815739)": "Alpha - actinin - 3. ACTN3 (Alpha-actinin-3) is an essential structural component exclusively present in fast twitch muscle fibers. ",
    "TRHR(rs16892496)": "Thyrotropin - releasing hormone receptor. TRHR (thyrotropin-releasing hormone receptor) helps to regulate metabolic rate, leading towards growth of lean body mass and release of stored fuel during exercise. ",
    "PPAR-α(rs4253778)": "Peroxisome proliferator - activated receptor alpha. PPARa is a key regulator of carbohydrate and fat metabolism, helping muscle burn fuel during endurance work. ",
    "VEGF(rs2010963)": "Vascular endothelial growth factor. Associated with: New blood vessel growth to support exercise activities. Regular exercise and progressive training programs can create a 4-fold increase in levels of VEGF. ",
    "VDR(rs731236)": "Vitamin D receptor. VDR reflects serum Vitamin D3 levels, which regulate calcium and phosphorus concentration, helping to support the immune system. ",
    "IL6(rs1800795)": "Interleukin 6. IL6 is a cytokine immune modulator that regulates the inflammatory process involved in repair from a training stimulus.",
    "ADRB2(rs1042714)": "Adrenoceptor Beta 2. ADRB2 is a regulator of epinephrine function and helps mobilize macronutrients for fuel during training. ",
    "ADRB2(rs1042713)": "Adrenoceptor Beta 2. ADRB2 is a regulator of epinephrine function and helps mobilize macronutrients for fuel during training. ",
    "BDKRB2(rs1799722)": "Bradykinin Receptor B2.BDKRB2 helps to regular blood pressure through vasodilation, and has effects on cell hydration and muscular contraction. ",
    "COL5A1(rs12722)": "Collagen 5 Alpha 1. COL5A1 is a structural component (the alpha-1 chain) of type V collagen.",
    "NRF(rs7181866)": "Nuclear Respiratory Factor 2. NRF improves respiratory capacity and cellular energy mobilization.",
    "PPARGC1A(rs8192678)": "Peroxisome Proliferator - PPARGC1A regulates energy through production of mitochondria, fat and carbohydrate metabolism and conversion from fast to slow twitch muscle fibers.",
    "CRP(rs1205)": "C - Reactive Protein. C-Reactive Protein: CRP is an acute phase reactant increased in states of inflammation. ",
    "SOD2(rs4880)": "Super Oxide Dismutase 2. Associated with: Scavenging of free radicals in the cells, especially within the mitochondria. It is therefore an antioxidant protector of cellular health.",
    "IL6R(rs4129267)": "Interleukin - 6 Receptor. The receptor for immune messenger Interleukin-6 (IL-6). IL-6 stimulates the immune response to training and is involved in the inflammatory repair process.",
    "TNF(rs1800629)": "Tumor Necrosis Factor. Tumor Necrosis Factor: A controller of immune cells and inflammation. ",
    "GDF5(rs224329)": "Growth Differentiation Factor 5. Growth Differentiation Factor 5: Expressed in the CNS and coupled to the healing and development of bones, cartilage and neurons. ",
    "COL1A1(rs1800012)": "Collagen 1 Alpha 1. Collagen 1 Alpha 1: Produces Type 1 Collagen, the fibrillar collagen found in most connective tissues and primary collagen that comprises tendons, ligaments and cartilage. ",
    "SLC30A8(rs13266634)": "Zinc transporter protein member 8. Zinc transporter involved in the accumulation of zinc in the cell and related to insulin secretion and storage. ",
    "PPAR-α(rs135549)": "Peroxisome proliferator-activated receptor alpha is a master regulator of lipid, carbohydrate and amino acid metabolism. PPAR-α is found primarily in brown adipose tissue and the liver, and, to a lesser extent, the kidneys, skeletal muscle, heart and both the small and large intestines. PPAR-α plays an essential role in the process of ketogenesis (the production of ketone bodies from the oxidation of fat, which typically occurs during carbohydrate restriction or fasting). Activation of PPAR-α promotes the uptake, utilization and catabolism of fatty acids by activating genes involved in fatty acid transport, binding, activation and oxidation. PPAR-α is activated primarily through the binding of polyunsaturated fatty acids. The G allele reduces activation and function of PPAR-a and leads to lipid abnormalities with high SFA and low PUFA intake. ",

    "PPARG(rs1801282)": "Peroxisome proliferator - activated receptor gamma. PPARG activates genes that stimulate lipid uptake and adipogenesis by fat cells. It also stimulates insulin sensitivity in muscle cells and increases gluconeogenesis in the liver. People with the G allele have increased obesity and diabetes risk with high SFA diet and low PUFA and MUFA. PUFAs directly activate the PPARG expression. ",
    "PPARG(rs3856806)": "Peroxisome proliferator - activated receptor gamma. PPARG activates genes that stimulate lipid uptake and adipogenesis by fat cells. It also stimulates insulin sensitivity in muscle cells and increases gluconeogenesis in the liver. People with the G allele have increased obesity and diabetes risk with high SFA diet and low PUFA and MUFA. PUFAs directly activate the PPARG expression. ",
    "TCF7L2(rs7903146)": "Transcription Factor 7 - like 2. A cellular transcription factor influencing several genes related to glucose metabolism and thus associated with risk of diabetes.",
    "FTO(rs9939609)": "Fat Mass and Obesity associated Protein. The A allele is associated with higher ghrelin levels and less satiety. This increases appetite and leads to obesity risk. The first meal of the day affects ghrelin levels. Eat a complete meal full of fiber and protein to help reduce ghrelin level later in the day. ",
    "FTO(rs1121980)": "Fat Mass and Obesity associated Protein. The FTO gene plays a major role in genetic risk for obesity. Multiple SNPs of the FTO gene lead to risk of obesity and insulin resistance especially in the setting of a high saturated fat diet and low PUFAs. ",
    "FTO(rs8050136)": "Fat Mass and Obesity associated Protein. The FTO gene plays a major role in genetic risk for obesity. Multiple SNPs of the FTO gene lead to risk of obesity and insulin resistance especially in the setting of a high saturated fat diet and low PUFAs. ",
    "FTO(rs17817449)": "Fat Mass and Obesity associated Protein. The FTO gene plays a major role in genetic risk for obesity. Multiple SNPs of the FTO gene lead to risk of obesity and insulin resistance especially in the setting of a high saturated fat diet and low PUFAs. ",
    "FTO(rs1558902)": "Fat Mass and Obesity associated Protein. The FTO gene plays a major role in genetic risk for obesity. Multiple SNPs of the FTO gene lead to risk of obesity and insulin resistance especially in the setting of a high saturated fat diet and low PUFAs. ",
    "FTO(rs1421085)": "Fat Mass and Obesity associated Protein. This FTO SNP is associated with reduced thermogenesis and less brown adipose tissue. The C allele is the risk allele. ",
    "ADRB3(rs4994)": "Adrenergic beta - 3 receptor. The protein encoded by this gene belongs to the family of beta adrenergic receptors, which mediate catecholamine-induced activation of adenylate cyclase through the action of G proteins. This receptor is located mainly in the adipose tissue and is involved in the regulation of lipolysis and thermogenesis. ",
    "GIPR(rs2287019)": "Gastric inhibitory polypeptide. The C allele is associated with greater insulin secretion for the same amount of food. GIPR is also involved in glucose and fat uptake by fat cells. This seems to be mitigated by a high carb, low fat diet. ",
    "IRS1(rs2943641)": "Insulin receptor substrate 1 (IRS1). ",
    "GSTP1(rs1695)": "Glutathione transferase enzyme most active. Glutathione is one of the most potent antioxidant systems that the body has and is orders of magnitude more powerful than supplemental Vitamin E (alpha tocopherol). Supplemental Vitamin E has been shown to have a negative impact on individuals with A alleles by raising the levels of pro-inflammatory cytokines in the blood. However, people that have the less active version of GSTP1, may possibly have an anti-inflammatory benefit from a low dose (75 IU) supplemental Vitamin E. ",
    "VitDBindingProtein(rs7041)": "Vitamin D Binding Protein. ",
    "CYP2R1(rs2060793)": "Vitamin D hydroxylase. ",
    "Vit D Binding Protein(rs2282679)": "Vitamin D Binding Protein. Vitamin D binding protein is important for transport of the inactive and active forms of Vitamin D to the tissues and organs. ",
    "FUT2(rs602662)": "fucosyltransferase 2. ",
    "FUT2(rs601338)": "fucosyltransferase 2. ",
    "BCMO1(rs12934922)": "Beta - carotene monooxygenase. ",
    "BCMO1(rs7501331)": "Beta - carotene monooxygenase. ",

    "SLC23A1(rs33972313)": "Vitamin C transporterintestional cells. Reduced function of primary Vitamin C transporter in intestines. Requires increased Vitamin C levels as well as focus on Glut 1 transporters which requires glucose for Vitamin C transport. Each A allele is associated with a 5 micromol/L reduction in Vitamin C serum levels. ",
    "HFE(rs1800562)": "Hemochromotosis SNP. ",
    "CYP1A2(rs762551)": "Caffeine metabolism. ",
    "PEMT(rs7946)": "Phosphatidylethanolamine  - N -  methyltransferase (PEMT). PEMT is responsible for choline production in the liver and thus important for methylation, acetylcholine production, lipid transport out of the liver and may be associated with fatty liver disease. ",
    "FADS1(rs174548)": "Fatty acid delta - 5 - desaturase. Phosphatidylcholine is important for production of cellular membranes, neurotransmitters, and plays a role in methylation, including the reduction of dopamine and generation of creatine. ",
    "MTHFD1(rs2236225)": "methylenetetrahydrofolate dehydrogenase. ",
    "MTRR(rs1801394)": "Methionine synthase reductase. MTRR activates the enzyme methionine synthase (requiring Vitamin B2) . MTR remethylates homocysteine to methionine using B12 as a cofactor. This reaction is critical to folate metabolism and methionine cycling for the methylation pathway. ",
    "MTHFR(rs1801131)": "5 - methylenetetrahydrofolate reductase. MTHFR converts homocysteine to methionene in a critical step in the methylation pathway. The MTHFR gene is well studied. 5 different SNPs alter it's function from 15%-100% effectivity. Presence of one or more variants of this enzyme may warrant Methylfolate, methy-B12, P-5-P, tri-methylglycine, or choline supplementation if there is evidence or reduced methylation which is often identified by homocysteinemia. ",
    "MTHFR(rs1801133)": "5 - methylenetetrahydrofolate reductase. MTHFR converts homocysteine to methionine in a critical step in the methylation pathway. The MTHFR gene is well studied. 5 different SNPs alter it's function from 15%-100% effectivity. Presence of one or more variants of this enzyme may warrant Methylfolate, methy-B12, P-5-P, tri-methylglycine, or choline supplementation if there is evidence or reduced methylation which is often identified by homocysteinemia. ",
    "UCP1(rs1800592)": "Uncoupling protein 1. UCP1 is a protein found in mitochondria in brown fat and involved in thermogenesis and heat production. The risk allele is associated with reduced protein and thus reduced thermogenesis, heat production and resting metabolic rate. Thermogenesis may be improved by exercise and cold exposure. ",
    "CRY2(rs11605924)": "Cryptochrome circadian regulator. ",
    "PGC-1a(rs8192678)": "Peroxisome Proliferator - PPARGC1A regulates energy through production of mitochondria, fat and carbohydrate metabolism and conversion from fast to slow twitch muscle fibers.",
    "FADS2(rs1535)": "fatty acid delta - 6 desaturase. The fatty acid delta-6 desaturase (FADS2) enzyme is responsible for elongating the polyunsaturated fatty acid alpha- linolenic acid (ALA) and converting it into the omega-3 fatty acid eicosapentaenoic acid (EPA). ",
    "ACE(rs4343)": "Angiotensin - Converting Enzyme. ",
    "FADS1(rs174550)": "Fatty acid desaturase 1. ",
    "SH2B3(rs3184504)": "SH2B adaptor protein 3. ",
    "MTNR1B(rs10830963)": "Melatonin Receptor. Melatonin receptors are found on the pancreas and may influence insulin secretion. ",
    "ADORA2A(rs5751876)": "Adenosine A2a receptor (Caffeine antagonizes). ",
    "MTNR1A(rs12506228)": "Melatonin Receptor. Melatonin receptor mediated Alzheimer's risk. ",
    "NPAS2(rs2305160)": "Circadian associated transcriptional activator. Transcriptional activator which forms a core component of the circadian clock. Risk allele is associated with alterations in metabolism especially related to the circadian rhythm. Risk seems to increased by loss of sleep and eating late meal. Patients with this risk allele may see increases in CRP and insulin resistance with late meals. The risk may be reduced by longer fasting periods at night. For example, breast cancer risk has been reduced in studies by 36% simply by fasting for 12+ hours overnight. ",
    "SLC01B1(rs4149056)": "Solute carrier organic anion transporter",
    "SLC01B1(rs4363657)": "Solute carrier organic anion transporter",
    "COQ2(rs4693596)": "Coenzyme Q10. ",
    "HMGCR(rs17238540)": "hydroxy - methylglutaryl coenzyme A reductase. ",
    "PCSk9(rs11591147)": "Proprotein convertase subtilisin/kexin type 9. PCSK9 loss of function variant allele T, 1-3% of population, results in less LDL receptor reduction and thus increased LDL clearance from the blood. ",
    "Jak2(rs12340895)": "Jak2 gene and blood cancer. ",
    "SLC23A1(rs10063949)": "Vitamin C Transporter. Risk of Crohn's, IBD. ",
    "COMT(rs4680)": "catechol - Omethyltransferase breaks down dopamine (requires methylation). AKA Worrier Vs. Warrior gene.",
    "BDNF(rs6265)": "Brain - derived neurotrophic factor. BDNF is a neurotrophin that is involved in neuronal health, growth of new neurons and proliferation of existing neurons. ",
    "AKT1(rs2494732)": "Alpha Serine/Threonine Kinase 1.  Response to Cannabis associated psychosis risk",
    "FAAH(rs324420)": "Fatty Acid Amidee Hydrolase. ",
    "TERT(rs2736100)": "telomerase reverse transcript as a part of Telomerase, an enzyme capable of lengthening telomeres. ",
    "OBFC1(rs9420907)": "OBFC1enzyme involved in telomere maintenance. ",
    "RTEL1(rs755017)": "regulator of telomerase elongation helicase 1). ",
    "ACYP2(rs11125529)": "Acylphosphatase 2. ",
    "HSP70(rs1008438)": "Heat Shock Protein HSP1A1. ",
    "HSP70(rs1043618)": "Heat Shock Protein HSP1A1. ",
    "HSP70(rs2075800)": "Heat Shock Protein HSP1AL.",
}

export enum HealthReportPages {
    StartPage,
    // RoadMap, // 21773 Remove Tree Page (probably need in future)
    // Overall, // 21772 Remove Overall Health Assessment (probably need in future)
    DietAndNutrition0,
    DietAndNutrition1,
    DietAndNutrition2,
    DietAndNutrition3,
    DietAndNutrition4,
    DietAndNutrition5,
    DietAndNutrition6,
    ExerciseAndRecovery0,
    ExerciseAndRecovery1,
    ExerciseAndRecovery2,
    ExerciseAndRecovery3,
    ExerciseAndRecovery4,
    ExerciseAndRecovery5,
    ExerciseAndRecovery6,
    ExerciseAndRecovery7,
    Sleep0,
    Sleep1,
    Neurobehavioral0,
    Neurobehavioral1,
    Microbiome0,
    Microbiome1,
    Microbiome2,
    ChronicDisease0,
    ChronicDisease1,
    ChronicDisease2,
    ChronicDisease3,
    ChronicDisease4,
    ChronicDisease5,
    Longevity0,
    Longevity1,
    Longevity2,
    Supplements,
    Details5,
    Appendix,
    Details1,
    Details2,
    Details3,
    Details4,
    Recommendations,
}

export enum HealthReportMode {
    EditMode = 0,
    ReadonlyMode = 1,
    PrintMode = 2
}

export enum HealthReportVersion {
    SetRecommendation = '3.0.0.0',
    New = '2.0.0.0',
    OldVersion = '1.8.0.0',
}

export enum HealthReportDialogName {
    New = 'Generate New Report',
    Update = 'Update Report',
}

export enum RecommendationSetType {
    Compare = 'Compare With Previous Report',
    New = 'Use New Set of Recommendations',
    Previous = 'Use Set from Selected Report',
    NewAndSkipReview = 'Use New Set of Recommendations and Skip Review'
}
export interface ApoeModel {
    status: string;
    recommendation: string;
}

export interface EpiGeneticAge {
    biologicalAge: number;
    rateOfAging: number;
    rateOfAgingPercentile: number;
    result: string;
    isCompleted: boolean;
}

export interface ReportRecommendationDefaultModel {
    content: string;
    order: number,
    id: number
}

export interface HealthReportRecommendationDefaultModel {
    recommendationType: number;
    recommendationId: number;
    baseId: number;
    recommendation: ReportRecommendationDefaultModel;
    id: number;
}

export interface RecommendationtSimpleModel {
    id?: number;
    recommendationId?: number | string;
    baseId: number;
    type: number;
    content: string;
}

export interface RecommendationDisplayModel extends RecommendationtSimpleModel {
    isSelected?: boolean;
}

export interface RecommendedMacrosModel {
    carb: number;
    fat: number;
    pro: number;
    dietName: string;
}

export interface MicrobiomeResistanceModel {
    values: MicrobiomeValueModel[];
}

export enum MicrobiomeType {
    Flora = 1,
    Tmao = 2
}

export interface ReportStatusModel {
    status: HealthReportStatus;
    date: Date;
    version: HealthReportVersion;
    comment: string;
    submittedBy: string;
}

export interface ReportStatusUnderReviewModel {
    status: HealthReportStatus;
    date: Date;
    version: string;
    comment: string;
}
export interface MicrobiomeValueModel {
    type: MicrobiomeType;
    name: string;
    value: number | null;
    risk: string | null;
    comment: string;
    ranges: MicrobiomeValueRangeModel[];
}

export interface MicrobiomeValueRangeModel {
    from: number;
    to: number;
    risk: string;
}

export interface HealthReportOwnerModel {
    id: number;
    firstName: string;
    lastName: string;
}

export enum HealthReportStatus {
    Preparing = 0,
    InProgress = 1,
    Completed = 5,
    Failed = 10,
    Submitted = 15,
    UnderReview = 12,
}

export const healthReportStatusNames = new Map<HealthReportStatus, string>([
    [HealthReportStatus.Preparing, 'Preparing'],
    [HealthReportStatus.InProgress, 'In Progress'],
    [HealthReportStatus.Completed, 'Completed'],
    [HealthReportStatus.Failed, 'Failed'],
    [HealthReportStatus.Submitted, 'Published'],
    [HealthReportStatus.UnderReview, 'Under Review'],
]);

export enum DnaImportance {
    NotImportant = 0,
    ModerateImportance = 1,
    HighImportance = 2
}

export interface KeyValueModel {
    key: string;
    value: string;
}

export interface DnaModel extends KeyValueModel {
    importance: DnaImportance;
}

export interface DnaDataSetModel {
    dna: DnaModel[];
}

export interface ScoreModel extends DnaDataSetModel {
    result: string;
    score: number;
    adjustedMaxScore: number;
    scorePercent: number;
    isCalculated: boolean;
}

export interface ReportsUnderReviewModel {
    id: number,
    createdAt: string,
    status: ReportStatusUnderReviewModel,
    owner: HealthReportOwnerModel,
}

export interface RecommendationModel {
    content: string;
    manualContent: string;
    printableContent: string;
}
export const getFullRecommendationContentNew = (recommendation: RecommendationtSimpleModel[], type?: RecommendationTypesNumbers): string => {

    if (!recommendation || !recommendation.length) {
        return ""
    }

    if (type?.toString()) {
        return recommendation.filter(el => el.type === type).reduce(
            (previousValue, currentValue) => previousValue + '\n\n'  + currentValue.content,
            '').slice(2)
    }

    return recommendation.reduce(
        (previousValue, currentValue) => previousValue + '\n\n' + currentValue.content,
        '').slice(2)
}
export const getFullRecommendationContent = (recommendation: RecommendationModel): string => {
    if (!recommendation.manualContent || !recommendation.manualContent.length) {
        return recommendation.content;
    }

    if (!recommendation.content || !recommendation.content.length) {
        return recommendation.manualContent;
    }

    const separator = recommendation.content.endsWith('\n')
        ? '\n'
        : '\n\n';

    return recommendation.content + separator + recommendation.manualContent;
}

export interface HealthReportModel {
    id: number;
    status: ReportStatusModel;
    owner: HealthReportOwnerModel;
    alphaReport: AlphaReportModel;
    overallReport: OverallReportModel
    dietAndNutritionReport: DietAndNutritionReportModel;
    exerciseAndRecoveryReport: ExerciseAndRecoveryReportModel;
    sleepReport: SleepReportModel;
    neurobehavioralReport: NeurobehavioralReportModel;
    microbiomeReport: MicrobiomeReportModel;
    longevityReport: LongevityReportModel;
    labReport: LabReportModel;
    reviewer: HealthReportReviewerModel;
    recommendations: RecommendationtSimpleModel[];
}

export interface NewReviewerModel {
    id: number;
    patientId: number;
    reviewerId: number;
}

export interface HealthReportReviewerModel {
    id: number;
    firstName: string;
    lastName: string;
}
export interface HealthReportShortModel {
    id: number;
    createdAt: Date;
    status: ReportStatusModel;
    submittedBy: string;
}

export interface AlphaReportModel {
    homir: number;
    hdlTrigRatio: number;
    chronologicalAge: number;
    realAge: number;
    gender: Gender;
    apoe: ApoeModel;
    epiGeneticAge: EpiGeneticAge;
}

export interface RoadMapModel {
    brainOptimization: Optimization;
    mindOptimization: Optimization;
    bodyOptimization: Optimization;
    gutOptimization: Optimization;
    longevityOptimization: Optimization;
    bioHackingOptimization: Optimization;
    optimizationEstimation: string;
    optimizationStartDate: Date;
}

export interface OverallReportModel {
    cancerRiskScore: ScoreModel;
    cardiovascularDiseaseRiskScore: ScoreModel;
    exerciseScore: ScoreModel;
    insulinResistanceScore: ScoreModel;
    microbiomeScore: ScoreModel;
    sleepScore: ScoreModel;
    wildHealthScore: ScoreModel;
    roadMap: RoadMapModel;
    supplementsRecommendation: RecommendationModel;
}

export interface DietAndNutritionReportModel {
    recommendedMacros: RecommendedMacrosModel;
    carbonIntoleranceScore: ScoreModel;
    fatIntoleranceScore: ScoreModel;
    satFatIntoleranceScore: ScoreModel;
    cholineRiskScore: ScoreModel;
    folateScore: ScoreModel;
    tmaoRiskScore: ScoreModel;
    methylationScore: ScoreModel;
    insulinResistanceScore: ScoreModel;

    kryptoniteFoodDna: DnaDataSetModel;
    superFoodDna: DnaDataSetModel;
    vitaminsAndMicronutrientsDna: DnaDataSetModel;

    completeDietRecommendation: RecommendationModel;
    kryptoniteFoodsRecommendation: RecommendationModel;
    macronutrientRecommendation: RecommendationModel;
    methylationRecommendation: RecommendationModel;
    superFoodsRecommendation: RecommendationModel;
    vitaminsAndMicronutrientsRecommendation: RecommendationModel;
    apoeAccuracyScore: ApoeAccuracyScoreModel;
}

export interface ApoeAccuracyScoreModel {
    message: string;
    result: string;
    score: number;
    adjustedMaxScore: number;
    scorePercent: number;
    isCalculated: boolean;
    dna: DnaModel[];
    apoe: string;
}

export interface ExerciseAndRecoveryReportModel {
    exerciseDna: ScoreModel;
    exercisePlan: ExercisePlanModel;
    intensityScore: ScoreModel;
    recoveryScore: ScoreModel;
    strengthAndEnduranceScore: ScoreModel;

    recommendation: RecommendationModel;
}

export interface SleepReportModel {
    circadianDna: DnaDataSetModel;
    recommendation: RecommendationModel;
}

export interface NeurobehavioralReportModel {
    comtScore: ScoreModel;
    faahScore: ScoreModel;
    neurobehavioralDna: DnaDataSetModel;
    recommendation: RecommendationModel;
}

export interface MicrobiomeReportModel {
    microbiomeResistance: MicrobiomeResistanceModel;
    recommendation: RecommendationModel;
}

export interface LongevityReportModel {
    cancerRiskScore: ScoreModel;
    dementiaRiskScore: ScoreModel;
    metabolismScore: ScoreModel;
    oxidativeStressRiskScore: ScoreModel;
    telomersRiskScore: ScoreModel;
    cardiovascularDiseaseRiskScore: ScoreModel;
    inflammationRiskScore: ScoreModel;
    lifespanScore: ScoreModel;
    mesaRiskScore: ScoreModel;
    cvdRiskScore: ScoreModel;

    inflammationRecommendation: RecommendationModel;
    dementiaRecommendation: RecommendationModel;
    cardiovascularRecommendation: RecommendationModel;
    insulinResistanceRecommendation: RecommendationModel;
    recommendation: RecommendationModel;
}

export interface LabReportModel {
    values: LabReportValueModel[];
}

export interface LabReportValueModel {
    name: string;
    group: LabGroup;
    value: number | null;
    date: Date | null;
    range: LabReportValueRangeModel;
    notification: LabReportValueNotificationModel;
}

export interface LabReportValueRangeModel {
    from: number | null;
    to: number | null;
    dimension: string;
    type: LabRangeType;
}

export interface LabReportValueNotificationModel {
    type: LabInputNotificationType;
    message: string;
}

export interface HealthReportDashboardModel {
    patientId: number;
    patientName: string;
    status: HealthReportStatus;
    statusDate: Date;
    statusVersion: string;
    reviewerId: number;
    reviewerName: string;
    patientHasImc: boolean;
}

export enum ReportStrategy
{
    BuiltIn = 1,
    AddOnFree = 2,
    AddOnPaid = 3
}

export enum ReportSectionKey {
    CeliacDisease = 'celiacDisease',
    GlutenSensitivity = 'glutenSensitivity',
    Symptoms = 'symptoms',
    CausalityAndDiagnosis = 'causalityAndDiagnosis',
    RiskValues = 'riskValues',
    CeliacAndGlutenSensitivityRecommendation = 'celiacAndGlutenSensitivityRecommendation',
    CeliacHaplotypes = 'celiacHaplotypes',
}

export interface ReportValueListModel extends KeyValueModel
{
    label: string;
}

export interface ContentModel
{
    title: string;
    text: string;
    reportValueList?: ReportValueListModel[];
    recommendations?: KeyValueModel;
}

export interface SectionModel
{
    key: string;
    type: string;
    content: ContentModel;
}

export interface PatientReportModel
{
    patientId: number;
    reportTemplateId: number;
    reportTemplateName: string;
    contentJson: string;
    createdAt: Date;
}

export interface PatientAddOnReportModel
{
    reportType: ReportType;
    reportTypeName: string;
    reportStrategy: string;
    reportTemplateVersion: string;
    canGeneratePatientReport: boolean;
    cannotGeneratePatientReportReason: string;
    hasDnaResults: boolean;
    createdAt: Date;
    patientReportId?: number;
}