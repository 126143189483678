import { stringLengthValidationRule, maxLengthValidationRule } from "../../../common/validation/general-validation-rules";
import { GeneralValidator } from "../../../common/validation/general-validator";

export class CreateFollowUpComponentFieldNames {
    static readonly internalNote: string = 'internalContent';
    static readonly goalName: string = 'goal.name';
    static readonly interventionName: string = 'intervention.name';
    static readonly nextStepName: string = 'nextStep.name';
    static readonly noteTitle: string = 'title';
}

export const createFollowUpComponentValidator = new GeneralValidator({
    //[CreateFollowUpComponentFieldNames.internalNote]: [stringLengthValidationRule(0, 500)],
    [CreateFollowUpComponentFieldNames.goalName]: [stringLengthValidationRule(1, 250)],
    [CreateFollowUpComponentFieldNames.interventionName]: [stringLengthValidationRule(1, 500)],
    [CreateFollowUpComponentFieldNames.nextStepName]: [stringLengthValidationRule(1, 500)],
    [CreateFollowUpComponentFieldNames.noteTitle]: [maxLengthValidationRule(40)],
});