import { useState } from "react";

interface MedicationsComponentState {
    isMenuOpened: boolean;
    isAddNewOpened: boolean;
    isEditorOpened: boolean;
    selectedId: number;
    anchorEl: any;
}

export function useFacade(): [
    MedicationsComponentState,
    (isOpen: boolean) => void,
    (isOpen: boolean) => void,
    (id?: number, anchorEl?: Element) => void
] {
    const [state, setState] = useState({
        isMenuOpened: false,
        isAddNewOpened: false,
        isEditorOpened: false,
        selectedId: 0,
        anchorEl: null,
    } as MedicationsComponentState);

    const handleAddNewToggle = (isOpen: boolean) => {
        setState(state => ({...state, isAddNewOpened: isOpen}));
    }

    const handleEditToggle = (isOpen: boolean) => {
        setState(state => ({
            ...state,
            isEditorOpened: isOpen,
            isMenuOpened: false
        }));
    }

    const handleToggleActions = (id?: number, anchorEl?: Element) => {
        setState(state => ({
            ...state,
            isMenuOpened: !state.isMenuOpened,
            selectedId: id ?? state.selectedId,
            anchorEl: anchorEl
        }))
    }

    return [
        state,
        handleAddNewToggle,
        handleEditToggle,
        handleToggleActions
    ];
}