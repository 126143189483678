import React, {FC, Fragment} from 'react';
import {Box, Collapse, FormControl, ListItem, ListItemText, TextField} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import {WildHealthButton} from "../../../../common/components/wildHealthButton/WildHealthButton";
import {useStyles} from "../manageApiResponseComponent.styles";
import commonUseStyles from "../../../../common/styles/common.styles";
import {ToolEnum} from "../manageApiResponseComponent.hooks";

type EndpointAdminProps = {
    id: string
    params: any
    handleChanges: (field: string, value: any, tool: ToolEnum) => void,
    handleOpenToggle: ()=>void
    handleClick: (any)=>void
    title: string
    errors:any
    isOpen: boolean
    isLoading: boolean
    toolMenu: ToolEnum
}

const EndpointAdminComponent: FC<EndpointAdminProps> = (props): JSX.Element => {
    const {id, isLoading, title, errors, params, handleOpenToggle, handleClick, handleChanges, isOpen, toolMenu} = props;

    const classes = useStyles();
    const commonClasses = commonUseStyles();

    const  capitalize=(str)=> {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const getParamTitle = (paramName) => {
        if(paramName.includes('Id')){
            return paramName.split('Id').map(capitalize).join(" ")+' Id *';
        } else {
            return capitalize(paramName);
        }
    }

    return (
        <Fragment>
            <ListItem id={id} className={classes.divider} button onClick={handleOpenToggle}>
                <ListItemText primary={<span className="text-medium">{title}</span>}/>
                {isOpen ? <ExpandLess className={classes.activeIcon}/> : <ExpandMore/>}
            </ListItem>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <Box p={2}>
                        {
                         Object.keys(params).map((param) => {
                          return (
                        <Box pb={2}>
                            <FormControl
                                variant="outlined"
                                color='primary'
                                required
                                error={!!errors[param]}
                                size="small"
                                disabled={!isOpen}
                                classes={{root: classes.root}}
                                fullWidth>
                                <Box className={commonClasses.label} mb={1}>{getParamTitle(param)}</Box>
                                <TextField
                                    fullWidth
                                    size="small"
                                    required
                                    InputProps={{className: 'input', classes}}
                                    id={param}
                                    type={typeof params[param]}
                                    placeholder={getParamTitle(param)}
                                    value={params[param]}
                                    variant="outlined"
                                    disabled={!isOpen}
                                    error={!!errors[param]}
                                    helperText={errors[param]}
                                    onChange={(v) => handleChanges(v.target.id, v.target.value, toolMenu)}
                                />
                            </FormControl>
                        </Box>)
                    })
                    }
                    <WildHealthButton
                        fullWidth
                        id={`${id}-button`}
                        loading={isLoading}
                        onClick={handleClick}
                    >
                        Execute
                    </WildHealthButton>
                </Box>
            </Collapse>
        </Fragment>
    )

}

export default EndpointAdminComponent;