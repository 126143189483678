import React from "react";
import {Box, Divider, Grid} from "@material-ui/core";
import {SendPlaygroundMessageComponent} from "../sendPlaygroundMessageComponent/SendPlaygroundMessageComponent";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import {useFacade} from "./startPatientPlaygroundComponent.hooks";
import {useStyles} from "./startPatientPlaygroundComponent.styls";

export interface StartPatientPlaygroundComponentProps {
    patientId: number;
    handleCreated: Function;
}

export const StartPatientPlaygroundComponent: React.FC<StartPatientPlaygroundComponentProps> = (props: StartPatientPlaygroundComponentProps) => {

    const [
        {
            isLoading,
            templates,
            selectedTemplate
        },
        handleSelectTemplate,
        handleSendMessage
    ] = useFacade(props.handleCreated, props.patientId);

   const classes = useStyles();

    return (
        <>
            <Box p={1} px={3} height={75} display="flex" justifyContent="space-between" alignItems="center">
                <Box pl={2}>
                    New Chat
                </Box>
            </Box>
            <Divider/>
            <Box className={classes.templatesListRoot}>
                <Box p={3}>
                    {
                        isLoading &&
                        <WildHealthLinearProgress/>
                    }
                    <Grid container spacing={2}>
                        {
                            templates.map((template, index) => {
                                return (
                                    <Grid key={index} item md={12} lg={6} sm={12} xs={12}>
                                        <Box className={selectedTemplate?.id === template.id? classes.selectedTemplate : classes.template} onClick={() => handleSelectTemplate(template)}>
                                            <Box className={classes.templateName}>{template.name}</Box>
                                            <Box mt={1} className={classes.templateDescription}>{template.description}</Box>
                                        </Box>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>

                </Box>
            </Box>
            <Divider/>
            <SendPlaygroundMessageComponent
                message={selectedTemplate?.text}
                handleSubmit={handleSendMessage}
            />
        </>
    )
}