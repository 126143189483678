import { Box } from '@material-ui/core';
import React from 'react';
import { useFacade } from './insurancePage.hooks';
import { AuthenticatedLayoutComponent } from '../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { PagesNamesService } from "../../../../constants/pagesNames.constants";
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from '../../../../models/menu.models';
import { FeatureComponent } from '../../../common/components/featureFlags/FeatureComponent';
import { FeatureFlag } from '../../../common/components/featureFlags/featureFlags.models';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { ConfirmInsuranceDialog } from '../../../common/dialogs/confirmDialog/ConfirmInsuranceDialog';
import { InsurancesListComponent } from '../../components/insurancesListComponent/InsurancesListComponent';
import { CreateInsuranceModalComponent } from '../../components/createInsuranceModalComponent/CreateInsuranceModalComponent';
import { UpdateInsuranceModalComponent } from '../../components/updateInsuranceModalComponent/UpdateInsuranceModalComponent';
import { WildHealthTooltip } from '../../../common/components/WildHealthTooltip';

export const InsurancePage: React.FC = () => {
    const [
        {
            coverageList,
            attachments,
            isInsurancesLoading,
            isAttachmentsLoading,
            isCoveragesLoading,
            isInsuranceAvailable,
            isAddButtonLoading
        },
        isEnabledInsuranceAdd,
        handleAddInsurance,
        handleEditInsurance
    ] = useFacade();

    const renderContent = () => {
        const isLoading =
            isCoveragesLoading ||
            isAttachmentsLoading ||
            isInsurancesLoading;

        if (isLoading) {
            return (<WildHealthLinearProgress />)
        }

        return (
            <Box p={2}>
                <Box py={2} display="flex" justifyContent="space-between" alignItems="center">
                    <Box>
                        <span className="wh-tw-text-mainDark wh-tw-text-2xl wh-tw-font-medium">Insurance Information</span>
                    </Box>
                    <Box>
                        {
                            isInsuranceAvailable &&
                            <WildHealthTooltip title={!isEnabledInsuranceAdd && 'We currently are no longer accepting new insurance patients'} placement="top">
                                <Box>
                                <WildHealthButton id="add-insurance-button" onClick={() => handleAddInsurance()} disabled={isAddButtonLoading || !isEnabledInsuranceAdd}>
                                    Add Insurance Card
                                </WildHealthButton>
                                </Box>
                            </WildHealthTooltip>
                        }
                    </Box>
                </Box>
                <Box>
                    <InsurancesListComponent
                        insurances={coverageList}
                        handleEdit={handleEditInsurance}
                        patientId={null}
                        attachments={attachments}
                    />
                </Box>
                <CreateInsuranceModalComponent />
                <UpdateInsuranceModalComponent />
                <ConfirmInsuranceDialog />
            </Box>
        )
    }

    return (
        <AuthenticatedLayoutComponent
            hideBackground
            title={TitlesService.insuranceTitle}
            pageName={PagesNamesService.insurance}
            selectedMenuItem={MenuItemTypes.Insurance}
        >
            <FeatureComponent featureFlag={FeatureFlag.InsuranceProfile}>
                {renderContent()}
            </FeatureComponent>
        </AuthenticatedLayoutComponent>
    )
}