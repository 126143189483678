import React from 'react';
import { AuthenticatedLayoutComponent } from "../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent";
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from "../../../../models/menu.models";
import { ResetPasswordComponent } from '../../components/resetPasswordComponent/ResetPasswordComponent';
import { useFacade } from './resetPasswordPage.hooks';

export const ResetPasswordPage: React.FC = () => {
    const [
        handleSubmitResetPassword,
    ] = useFacade();

    return (
        <AuthenticatedLayoutComponent
            title={TitlesService.resetPasswordTitle}
            selectedMenuItem={MenuItemTypes.None}
        >
            <ResetPasswordComponent />
        </AuthenticatedLayoutComponent>
    )
}
