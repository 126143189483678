import React from 'react';
import { useFacade } from './healthPlanComponent.hooks';
import { TabItem } from '../../../common/components/wildHealthTabControl/wildHealthTabControl.hooks';
import { Box, Card, CardContent, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { WildHealthTabControl } from '../../../common/components/wildHealthTabControl/WildHealthTabControl';
import { HealthPlanPastGoalsComponent } from '../HealthPlanPastGoals/HealthPlanPastGoalsComponent';
import { useStyles } from './HealthPlanComponent.style';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import StarIcon from '@material-ui/icons/Star';
import FilterListIcon from '@material-ui/icons/FilterList';
import { GoalModel, goalsFiltersStateTrackNames, GoalsFilterState } from '../../../notes/models/notes.models';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { HealthPlanItemComponent } from '../../../widgets/components/healthPlanWidgetComponent/healthPlanItemComponent/HealthPlanItemComponent';
import { PlatformName, Track } from '../../../tracking/tracker';

interface HealthPlanComponentProps {
    patientId?: number;
}

export const HealthPlanComponent: React.FC<HealthPlanComponentProps> = (props: HealthPlanComponentProps) => {
    const { patientId = null } = props;

    const [
        {
            isLoading,
            goals,
            topGoals,
            filterState,
        },
        handleFilterStateChanges,
        handleOpenGoal,
    ] = useFacade(patientId);

    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    if (isLoading) {
        return <WildHealthLinearProgress />
    }

    const renderGoal = (goal: GoalModel, index: number) => {
        return (
            <Grid key={index} item md={6} lg={4} sm={6} xs={12}>
                <HealthPlanItemComponent goal={goal} onClick={() => handleOpenGoal(goal)} />
            </Grid>
        )
    }

    const renderContentWeb = (goals: GoalModel[], type: GoalsFilterState) => {
        Track("health_plan_click", {
            type: goalsFiltersStateTrackNames[type],
            platform: PlatformName()
        })
        if (!goals || !goals.length) {
            return (
                <Box m={2} textAlign="center">
                    <Typography className={classes.noHealthGoalsText}>
                        {type === GoalsFilterState.TopGoals ? 'You have no top goals yet.' : 'You have no goals yet.'}
                    </Typography>
                    <Typography className={classes.noHealthGoalsSubText}>
                        {type === GoalsFilterState.TopGoals
                            ? 'Your health plan will be updated after your next physician appointment.'
                            : 'Your health plan will be available after your initial appointment with your physician.'}
                    </Typography>
                </Box>
            )

        }
        return <Grid container spacing={2}>
            {
                goals.map((goal, index) => {
                    return renderGoal(goal, index);
                })
            }
        </Grid>
    }

    const renderContentMobile = (goals: GoalModel[]) => {

        if (!goals?.length) {
            return (
                <Box mt={4} textAlign="center">
                    <Typography className={classes.noHealthGoalsText}>
                        {filterState === GoalsFilterState.TopGoals ? 'You have no top goals yet.' : 'You have no goals yet.'}
                    </Typography>
                    <Typography className={classes.noHealthGoalsSubText}>
                        {filterState === GoalsFilterState.TopGoals
                            ? 'Your health plan will be updated after your next physician appointment.'
                            : 'Your health plan will be available after your initial appointment with your physician.'}
                    </Typography>
                </Box>
            )
        }

        return (
            <Box mt={4}>
                <Grid container spacing={2}>
                    {
                        goals.map((goal, index) => {
                            return renderGoal(goal, index);
                        })
                    }
                </Grid>
            </Box>
        )
    }

    const renderTabControlMobile = () => {
        return <Box display="flex" justifyContent="space-between" mt={2}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" className={filterState === GoalsFilterState.TopGoals ? classes.selectedFilterButton : classes.filterButton} onClick={() => handleFilterStateChanges(GoalsFilterState.TopGoals)}>
                    <Box>
                        <StarIcon />
                    </Box>
                    <Box ml={1}>
                        Top Goals
                    </Box>
                </Box>
                <Box ml={2} display="flex" className={filterState === GoalsFilterState.AllGoals ? classes.selectedFilterButton : classes.filterButton} onClick={() => handleFilterStateChanges(GoalsFilterState.AllGoals)}>
                    <Box>
                        <FilterListIcon />
                    </Box>
                    <Box ml={1}>
                        Other Goals
                    </Box>
                </Box>
            </Box>
        </Box>
    }

    const items: TabItem[] = [
        {
            title: 'Top Goals',
            content: <Box mt={2}>
                {renderContentWeb(topGoals, GoalsFilterState.TopGoals)}
            </Box>,
            keepMounted: true,
        },
        {
            title: 'Other Goals',
            content: <Box mt={2}>
                {renderContentWeb(goals, GoalsFilterState.AllGoals)}
            </Box>,
            keepMounted: true,
        },
    ];

    return isSmallScreen ? <Card className={classes.root} variant="elevation" elevation={0}>
        {filterState === GoalsFilterState.PastGoals
            ? <CardContent className={classes.content}>
                <HealthPlanPastGoalsComponent
                    patientId={patientId}
                    isMobile={isSmallScreen}
                    handleOpenGoal={handleOpenGoal}
                    renderGoal={renderGoal}
                />
            </CardContent>
            : <CardContent className={classes.content}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography className={classes.title}>Health Plan</Typography>
                    <Box className={classes.link} display="flex" alignItems="center" onClick={() => handleFilterStateChanges(GoalsFilterState.PastGoals)}>
                        <Box>
                            Previous Goals
                        </Box>
                        <Box mt={1} ml={1}>
                            <KeyboardArrowRightIcon fontSize="small" />
                        </Box>
                    </Box>
                </Box>
                {renderTabControlMobile()}
                {renderContentMobile(filterState === GoalsFilterState.TopGoals ? topGoals : goals)}
            </CardContent>}
    </Card>
        : <Box>
            <WildHealthTabControl items={items} initTab={filterState === GoalsFilterState.TopGoals ? 0 : 1}/>
            <HealthPlanPastGoalsComponent
                patientId={patientId}
                handleOpenGoal={handleOpenGoal}
            />
        </Box>
}