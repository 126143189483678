import {Switch, withStyles} from "@material-ui/core";
import { colors } from '../../../modules/common/constants/colors';


export const WildHealthSwitcher = withStyles({
    switchBase: {
        color: '#bbd4e3',
        '&$checked': {
            color: colors.main,
        },
        '&$checked + $track': {
            backgroundColor: colors.main,
        },
    },
    checked: {},
    track: {},
})(Switch);