import {
    Box,
    Button,
    Divider,
    FormControl,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import React, {useRef} from "react";
import {WildHealthDatePicker} from "../../../common/components/WildHealthDatePicker";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import {EmployeeAppointmentModel} from "../../models/appointments.models";
import {TimeRangeModel} from "../../models/times.models";
import {AppointmentDayItemComponent} from "../scheduleItems/appointmentDayItemComponent/AppointmentDayItemComponent";
import {AppointmentItemComponent} from "../scheduleItems/appointmentItemComponent/AppointmentItemComponent";
import {useFacade} from "./coachAppointmentsCalendarComponent.hooks";
import {useStyles} from "./CoachAppointmentCalendarComponent.styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';
import {
    AppointmentStatus,
    AppointmentsViewMode,
    appointmentsViewModeNames,
    DayOfWeek,
    dayOfWeekNames
} from "../../models/appointments.enums";
import commonUseStyles from "../../../common/styles/common.styles";
import {BlockedItemComponent} from "../scheduleItems/blockedItemComponent/BlockedItemComponent";
import {AvailabilityModel} from "../../models/availability.models";
import {getTimeZoneName} from "../../../timezones/helpers/timezone";
import {ProtectedElement} from "../../../common/components/ProtectedElement";
import {PermissionType} from "../../../auth/models/auth.enums";
import { ReactComponent as CalendarIcon } from '@img/icons/calendar.svg';
import moment from "moment";


const calendarHourStart = 7;
const calendarHoursEnd = 22;

const getTimeLabel = (minutes: number): string => {
    const suffix = minutes >= 720 ? 'pm' : 'am';

    const targetMinutes = minutes > 720 ? minutes - 720 : minutes;
    const minutesZero = targetMinutes % 60 < 10 ? '0' : '';

    return `${targetMinutes / 12 === 60 ? 12 : targetMinutes / 60}:${minutesZero}${targetMinutes % 60} ${suffix}`
}

const getSchedulerTimes = ():  TimeRangeModel[] => {
    let result = [] as TimeRangeModel[]
    for(let i = calendarHourStart * 60; i < calendarHoursEnd * 60; i += 5) {
        result.push({
            label: getTimeLabel(i),
            startValue: i,
            endValue: i + 5,
            isHourStart: i % 60 === 0,
            isQuarterStart:  i % 15 === 0,
            isHalfStart: i % 60 !== 0 && i % 30 === 0
        } as TimeRangeModel)
    }

    return result;
}

const appointmentTimes = getSchedulerTimes();

interface CoachAppointmentsScheduleComponentProps {
    employeeId: number;
    timeZone: string;
    selectEmployee: JSX.Element,
    newAppointment: JSX.Element,
    handleToggleStatisticLoading: (status: boolean) => void
}

export const CoachAppointmentsCalendarComponent: React.FC<CoachAppointmentsScheduleComponentProps> = (props: CoachAppointmentsScheduleComponentProps) => {
    const {
        employeeId,
        timeZone,
        selectEmployee,
        newAppointment,
        handleToggleStatisticLoading
    } = props;

    const [
        {
            scheduleMode,
            selectedDate,
            availability,
            appointments,
            isAppointmentsLoading,
            isAvailabilityLoading,
            isFollowUpFormsLoading,
            isSequenceNumbersLoading,
            isAppointmentTagsLoading,
            isPhotosLoading
        },
        handleScheduleModeSelect,
        handleDateChange, 
        getDayDate,
        handleGoNext,
        handleGoPrev,
        handleGoCurrent
    ] = useFacade(employeeId, handleToggleStatisticLoading);

    const classes = useStyles();
    const commonClasses = commonUseStyles();

    const isLoading = isAppointmentsLoading || isAvailabilityLoading || isFollowUpFormsLoading || isSequenceNumbersLoading || isAppointmentTagsLoading || isPhotosLoading;

    const calendarRef = useRef(null);
    const cellHeight = 10;
    const cellTimeRange = 5;

    const cellWidth = scheduleMode === AppointmentsViewMode.Week ? 92 / 7 : 
        scheduleMode === AppointmentsViewMode.WorkWeek ? 92 / 5 : 92;

    const calendarStart = Math.min.apply(Math, appointmentTimes.map(x => x.startValue));
    const calendarEnd = Math.max.apply(Math, appointmentTimes.map(x => x.endValue));

    const getWeekDays = (): DayOfWeek[] => {
        switch (scheduleMode) {
            case AppointmentsViewMode.Day: return [(selectedDate).getDay()];
            case AppointmentsViewMode.Week: return [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday, DayOfWeek.Saturday, DayOfWeek.Sunday];
            case AppointmentsViewMode.WorkWeek: return [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday];
            default: return [];
        }
    }

    const weekDays = getWeekDays();

    const scheduleModeKeys = Object.keys(AppointmentsViewMode).filter(x => isNaN(+x));

    const getIsCurrentDate = (dayOfWeek: DayOfWeek): boolean => {
        const dateByDay = new Date(getDayDate(dayOfWeek));
        const currentDate = new Date();
        return dateByDay.getDay() === currentDate.getDay() && moment(moment(currentDate).format('YYYY-MM-DD')).isSame(moment(dateByDay).format('YYYY-MM-DD'));
    }

    const getDateMinutes = (date: Date): number => {
        const resDate = new Date(date);
        return resDate.getHours() * 60 + resDate.getMinutes();
    }

    const getDayAvailability = (dayOfWeek: DayOfWeek): AvailabilityModel[] => {
        const startDate = getDayDate(dayOfWeek);
        const endDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 1);
        return availability.filter(x => new Date(x.start) >= startDate && new Date(x.end) < endDate);
    }

    const getAppointments = (dayOfWeek: DayOfWeek): EmployeeAppointmentModel[] => {   
        const startDate = getDayDate(dayOfWeek, calendarHourStart);
        const endDate = getDayDate(dayOfWeek, calendarHoursEnd);

        return appointments
            .filter(x =>
                x.employees.some(x=> x.id === employeeId)
                && x.status !== AppointmentStatus.Canceled
                && new Date(x.startDate) >= startDate && new Date(x.endDate) < endDate);
    }

    const getAppointment = (dayOfWeek: DayOfWeek, startMinutes: number, endMinutes: number): EmployeeAppointmentModel => {
        return getAppointments(dayOfWeek)
            .find(x => getDateMinutes(x.startDate) <= startMinutes && getDateMinutes(x.endDate) >= endMinutes);
    }

    const renderTimeLine = (time: TimeRangeModel, rowSpan: number) => {
        const currMinutes = getDateMinutes(new Date())

        const minutesOffset = (cellHeight / cellTimeRange) * (currMinutes % cellTimeRange);

        if(currMinutes > time.startValue && currMinutes < time.endValue) {
            return (
                <Box className={classes.timeLine} style={{top: `${minutesOffset}px`}}>
                    <Box className={classes.timeDot}>

                    </Box>
                </Box>
            )
        }

        if(currMinutes > time.startValue && currMinutes < time.endValue + ((rowSpan - 1) * cellTimeRange)) {
            const offset = Math.floor((currMinutes - time.startValue) / cellTimeRange) * cellHeight + minutesOffset;
            return (
                <Box className={classes.timeLine} style={{top: `${offset}px`}}>
                    <Box className={classes.timeDot}>

                    </Box>
                </Box>
            )
        }

        return(<></>);
    }

    const renderAppointment = (appointment: EmployeeAppointmentModel): JSX.Element => {
        switch (scheduleMode) {
            case AppointmentsViewMode.Day: return (<AppointmentDayItemComponent appointment={appointment}/>);
            case AppointmentsViewMode.Week: return (<AppointmentItemComponent appointment={appointment}/>);
            case AppointmentsViewMode.WorkWeek: return  (<AppointmentItemComponent appointment={appointment}/>);
            default: return (<></>);
        }
    }

    const renderCalendarCell = (day: DayOfWeek, time: TimeRangeModel, key?: string): JSX.Element => {
        const isCurrentDate = getIsCurrentDate(day);
        const appointment = getAppointment(day, time.startValue, time.endValue);
        const dayAppointments = getAppointments(day);
        const dayAvailability = getDayAvailability(day);
        const isUnavailable = dayAvailability.find(x => getDateMinutes(x.start) === time.startValue) === undefined;

        if (appointment && (getDateMinutes(appointment.startDate) === time.startValue || time.startValue === calendarStart)) {
            const rowSpan = appointment.duration / cellTimeRange;
            return (
                <TableCell key={key} className={clsx(classes.cell, classes.alignTop)} rowSpan={rowSpan}>
                    {
                        isCurrentDate === true && renderTimeLine(time, rowSpan)
                    }
                    {
                        renderAppointment(appointment)
                    }
                </TableCell>
            )
        } else {
            if (appointment) {
                return <></>
            }

            if(isUnavailable) {
                const isStartOfUnavailable = dayAvailability.find(x => getDateMinutes(x.end) === time.startValue) !== undefined
                    || dayAppointments.find(x => getDateMinutes(x.endDate) === time.startValue) !== undefined
                    || time.startValue === calendarStart;

                if(isStartOfUnavailable) {
                    const nextAvailabilities = dayAvailability.filter(x=> getDateMinutes(x.start) > time.startValue);
                    const nextAppointments = dayAppointments.filter(x => getDateMinutes(x.startDate) > time.startValue);

                    let rowSpan: number;
                    if (nextAvailabilities.length || nextAppointments.length) {
                        const nextAvailability = nextAvailabilities.length
                            ? nextAvailabilities.reduce((prev, curr) => {
                                return prev.start < curr.start ? prev : curr;
                            })
                            : null;

                        const nextAppointment = nextAppointments.length
                            ? nextAppointments.reduce((prev, curr) => {
                                return new Date(prev.startDate) < new Date (curr.startDate) ? prev : curr;
                            })
                            : null;

                        const nextDate = nextAppointment && nextAvailability
                            ? new Date(nextAvailability.start) < new Date(nextAppointment.startDate) ? nextAvailability.start : nextAppointment.startDate
                            : nextAppointment ? nextAppointment.startDate : nextAvailability.start;

                        rowSpan = (getDateMinutes(nextDate) - time.startValue) / cellTimeRange;
                    }
                    else {
                        rowSpan = (calendarEnd - time.startValue) / cellTimeRange;
                    }
                        
                    return (
                        <TableCell key={`${key}-${rowSpan}`} className={clsx(classes.alignTop, classes.cell)}
                            rowSpan={rowSpan}>
                            {
                                isCurrentDate === true && renderTimeLine(time, rowSpan)
                            }
                            <BlockedItemComponent/>
                        </TableCell>
                    )
                }
            } else {
                return (
                    <TableCell id={day + '-' + time.startValue} key={`${key}-empty`} className={clsx(classes.alignTop, classes.cell,
                        {
                            [classes.dashedBorderCell]: time.isHalfStart,
                            [classes.solidBorderCell]: time.isHourStart,
                            [classes.currentCell]: isCurrentDate === true
                        })}>
                        {
                            isCurrentDate === true && renderTimeLine(time, 1)
                        }
                    </TableCell>
                )
            }
        }
        
        return (<></>);
    }

    const renderCalendar = (): JSX.Element => {
        if(isLoading) {
            return (<WildHealthLinearProgress />)
        }
        return(
            <TableContainer style={{maxHeight: 800}} id='appointments-calendar-container'>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell className={clsx(classes.headerCell, classes.timeCell, commonClasses.textMedium, classes.alignTop)} rowSpan={4}>
                            <Box className={classes.timeLabel}>
                                <Box p={1}>
                                    <span>{getTimeZoneName()}</span>
                                </Box>
                            </Box>
                        </TableCell>
                        {
                            weekDays.map((day, index) => {
                                const isCurrentDate = getIsCurrentDate(day);
                                return (
                                    (
                                        <TableCell key={index} className={clsx(classes.headerCell, {[classes.currentDateCell]: isCurrentDate})} style={{width: `${cellWidth}%`}}>
                                            <Box display="flex" justifyContent="center" alignItems="center">
                                                <Box className={isCurrentDate ? commonClasses.colorMain : commonClasses.colorGray1}>
                                                    <span>{dayOfWeekNames[day]}</span>
                                                </Box>
                                                <Box className={clsx(commonClasses.size18, commonClasses.textMedium, {[commonClasses.colorMain]: isCurrentDate})} ml={1}>
                                                    {getDayDate(day).getDate()}
                                                </Box>
                                            </Box>
                                        </TableCell>
                                    )
                                )
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                {
                    appointmentTimes.map((time, i) => {
                        return (
                            <TableRow key={i} className={classes.row}>
                                {
                                    time.isHourStart && 
                                    <TableCell className={clsx(classes.cell, classes.timeCell, commonClasses.textMedium, classes.alignTop)} rowSpan={12}>
                                        <Box className={classes.timeLabel}>
                                            <Box>
                                                <span>{time.label}</span>
                                            </Box>
                                        </Box>
                                    </TableCell>
                                }
                                
                                {
                                    weekDays.map((day, i) => (
                                        <React.Fragment key={i}>{renderCalendarCell(day, time, i.toString())}</React.Fragment>
                                    ))
                                }
                            </TableRow>
                        )
                    })
                }
                </TableBody>
            </Table>
            </TableContainer>
        );
    }

    return (
        <>
            <Divider/>
            <Box className={clsx(classes.root, commonClasses.size14)}>
                <Box display="flex" justifyContent="space-between">
                    <Box display="flex" alignItems="center">
                        <Box id="calendar-week-go-current" className={clsx(commonClasses.textMedium, commonClasses.colorMain, commonClasses.pointer)} onClick={() => handleGoCurrent()}>
                            <span>Today</span>
                        </Box>
                        <Box ml={2}>
                            <Button id="calendar-week-go-prev" className={classes.weekNavigationButton} onClick={() => handleGoPrev()}>
                                <ChevronLeftIcon />
                            </Button>
                        </Box>
                        <Box ml={2}>
                            <Button id="calendar-week-go-next" className={classes.weekNavigationButton} onClick={() => handleGoNext()}>
                                <ChevronRightIcon />
                            </Button>
                        </Box>
                        <Box ml={2}>
                            <span className={clsx(commonClasses.size14, commonClasses.colorGray1)}>Timezone: </span>
                            <span className={clsx(commonClasses.size14, commonClasses.textMedium)}>{getTimeZoneName(timeZone)}</span>
                        </Box>
                    </Box>
                    <Box display="flex" alignItems="center">
                        <WildHealthDatePicker
                            className={classes.datePicker}
                            id="selectedDate"
                            size="small"
                            placeholder="yyyy-mm-dd"
                            openTo="year"
                            format="MMMM yyyy"
                            value={selectedDate}
                            onChange={(e) => handleDateChange(new Date(e.toISOString()))}
                            views={["year", "month", "date"]}
                            useKeyboardDatePicker
                            keyboardIcon={
                                <Box>
                                    <ExpandMoreIcon />
                                </Box>
                            }
                            InputProps={{ disableUnderline: true }}
                        />
                    </Box>
                    <Box display="flex" alignItems="center">
                        <Box>
                            {selectEmployee}
                        </Box>
                        <Box ml={1}>
                            <FormControl variant='outlined' size='small' className={classes.viewModeSelect}>
                                <Select
                                    value={scheduleMode}
                                    renderValue={(value) => {
                                        return (
                                            <Box display="flex">
                                                <Box>
                                                    <CalendarIcon/>
                                                </Box>
                                                <Box ml={1}>
                                                    {appointmentsViewModeNames[Number(value)]}
                                                </Box>
                                            </Box>
                                        );
                                    }}
                                    onChange={(event) => handleScheduleModeSelect(event.target.value as AppointmentsViewMode)}>
                                    {
                                        scheduleModeKeys.map((key) => (
                                                <MenuItem key={key} value={AppointmentsViewMode[key]}>{appointmentsViewModeNames[AppointmentsViewMode[key]]}</MenuItem>
                                            )
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                        <Box display='flex' justifyContent='flex-end' flex={1}>
                            <ProtectedElement
                                element={
                                    <Box ml={1}>
                                        {newAppointment}
                                    </Box>
                                }
                                permissions={[PermissionType.Appointments]}
                            />
                        </Box>
                    </Box>
                </Box>
                <div className={classes.calendar} ref={calendarRef}>
                    {
                        renderCalendar()
                    }
                </div>

            </Box>
        </>
    );
}
