import {
    emailValidationRule, firstNameValidationRule, lastNameValidationRule, notWhitespaceValidationRule, stringLengthValidationRule,
    phoneNumberValidationRule
} from "../../../common/validation/general-validation-rules";
import { GeneralValidator } from "../../../common/validation/general-validator";

export const updateFellowComponentValidator = new GeneralValidator({
    'firstName': [firstNameValidationRule, notWhitespaceValidationRule()],
    'lastName': [lastNameValidationRule, notWhitespaceValidationRule()],
    'email': [emailValidationRule],
    'credentials': [stringLengthValidationRule(0, 100), notWhitespaceValidationRule()],
    'phoneNumber': [phoneNumberValidationRule],
});
