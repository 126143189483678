import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { Checkbox, FormControlLabel, Slider } from "@material-ui/core";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontWeight: 500,
        },
        titleInfo: {
            color: colors.gray1,
        },
        hint: {
            fontSize: 14,
            color: colors.gray1
        }
    })
);

export const CheckboxQuestionaris = withStyles((theme: Theme) => ({
    root: {
        color: colors.white,
        '&.Mui-checked': {
            color: colors.main,
        },
        '&:hover': {
            background: 'transparent',
        },

    },
}))(Checkbox);

export const FormControlLabelQuestionaris = withStyles((theme: Theme) => ({
    root: {
        border: `1px solid ${colors.stroke}`,
        boxSizing: 'border-box',
        boxShadow: '0px 4px 9px rgba(168, 173, 198, 0.15)',
        borderRadius: 2,
        height: 54,
        width: '100%',
        justifyContent: 'space-between',
        flexDirection: 'row-reverse',
        paddingLeft: 22,
        paddingRight: 4,
        marginLeft: 0,
        marginRight: 0,
        '&.checked': {
            border: `1px solid ${colors.main}`,
            boxShadow: '0px 4px 9px rgba(168, 173, 198, 0.15)',
            background: colors.lightGray,
        },
    },
    label: {
        [theme.breakpoints.down("xs")]: {
            wordBreak: "break-word !important",
        },
    }
}))(FormControlLabel);

export const FormControlLabelChecboxQuestionaris = withStyles((theme: Theme) => ({
    root: {
        display: 'revert',
    },
}))(FormControlLabel);

const iOSBoxShadow =
    '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

export const IOSSlider = withStyles({
    root: {
        color: colors.stroke,
        height: 2,
        padding: '15px 0',
        marginTop: 16,
    },
    thumb: {
        height: 28,
        width: 28,
        backgroundColor: '#fff',
        boxShadow: iOSBoxShadow,
        marginTop: -14,
        marginLeft: -14,
        '&:focus, &:hover, &$active': {
            boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                boxShadow: iOSBoxShadow,
            },
        },
    },
    active: {},
    valueLabel: {
        fontSize: 18,
        fontWeight: 500,
        color: colors.black,
        left: 'calc(-50% + 12px)',
        top: -22,
        '& *': {
            background: 'transparent',
            color: '#000',
        },
    },
    track: {
        height: 2,
    },
    rail: {
        height: 2,
        opacity: 0.5,
        backgroundColor: colors.stroke,
    },
    mark: {
        backgroundColor: '#bfbfbf',
        height: 8,
        width: 1,
        marginTop: -3,
    },
    markActive: {
        opacity: 1,
        backgroundColor: 'currentColor',
    },
})(Slider);