import { Query } from '@datorama/akita';
import { syncRecordsStore, SyncRecordsStore, SyncRecordsState } from "./syncRecordsStore";
import { SyncRecordStatus } from '../../models/syncRecordStatus.enums';

/**
 * Provides SyncRecords queries
 */
export class SyncRecordsQuery extends Query<SyncRecordsState> {
    syncRecordTypes$ = this.select(state => state.syncRecordTypes);
    syncRecordStatusCounts$ = this.select(state => state.syncRecordStatusCounts);
    targetSyncRecords$ = this.select(state => state.targetSyncRecords);
    targetSyncRecordListStatus$ = this.select(state => state.targetSyncRecordListStatus);
    totalCount$ = this.select(state => state.totalCount);
    syncRecordReconciles$ = this.select(state => state.syncRecordReconciles);
    filterDate$ = this.select(state => state.filterDate);
    searchSyncRecords$ = this.select(state => state.searchSyncRecords);
    typeName$ = this.select(state => state.typeName);
    practiceId$ = this.select(state => state.practiceId);

    constructor(protected store: SyncRecordsStore) {
        super(store);
    }

    public getTargetStatusId(): SyncRecordStatus {
        return this.getValue().targetSyncRecordListStatusId;
    }

    public getTargetStatus(): string {
        return this.getValue().targetSyncRecordListStatus;
    }

    public getTargetTotalCount(): number {
        return this.getValue().totalCount;
    }

    public getFilterDate(): string {
        return this.getValue().filterDate;
    }

    public getTargetTypeName(): string {
        return this.getValue().typeName;
    }

    public getTargetPracticeId(): number {
        return this.getValue().practiceId;
    }
}

export const syncRecordsQuery = new SyncRecordsQuery(syncRecordsStore);
