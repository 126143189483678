import React, {FC, Fragment} from 'react';
import { Box, Collapse, ListItem, ListItemText, TextField, FormControl, FormHelperText, Select, MenuItem, Checkbox } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { WildHealthButton } from "../../../../common/components/wildHealthButton/WildHealthButton";
import { useStyles } from "../manageApiResponseComponent.styles";
import commonUseStyles from "../../../../common/styles/common.styles";
import { PaymentStrategy, PaymentPriceType, PaymentPriceTypeNames } from "../../../../payment/models/paymentPrice.models";
import { ProductType, ProductTypeNames } from "../../../../products/enums/productType";
import {InclusionOption, InclusionOptionNames, PaymentPlanModel} from "../../../../payment/models/paymentPlan.models";
import { ToolEnum } from '../manageApiResponseComponent.hooks';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { colors } from '../../../../common/constants/colors';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

type EndpointAdminToolProps = {
    id: string;
    params: any;
    paymentPlans: PaymentPlanModel[];
    handleChanges: (field: string, value: any, tool: ToolEnum) => void;
    handleOpenToggle: () => void;
    handleCreateDefaultPaymentPlan: (any) => void;
    title: string;
    errors: any;
    isOpen: boolean;
    isLoading: boolean;
    toolMenu: ToolEnum;
}


const CreateDefaultPaymentPlanComponent: FC<EndpointAdminToolProps> = (props): JSX.Element => {
    const {
        id, isLoading, title, errors, params, paymentPlans, isOpen, toolMenu,
        handleOpenToggle,
        handleChanges,
        handleCreateDefaultPaymentPlan,
    } = props;

    const classes = useStyles();
    const commonClasses = commonUseStyles();

    const controlIcon = <CheckBoxOutlineBlankIcon style={{ fill: colors.mediumGray }} />;
    const controlCheckedIcon = <CheckBoxIcon style={{ fill: colors.main }} />

    const initialPrice = {
        strategy: PaymentStrategy.FullPayment,
        originalPrice: 0,
        discount: 0,
        price: 0,
        isAutoRenewal: true,
        startupFee: 0,
        discountInsurance: 0,
        type: PaymentPriceType.Default
    }

    const initialInclusion = {
        productType: ProductType.Membership,
        option: InclusionOption.NoOne,
        count: 0
    }

    const handleAddPrice = () => {
        const prices = params.prices;
        prices.push(initialPrice)
        handleChanges("prices", prices, toolMenu);
    }

    const handleRemovePrice = (index: number) => {
        const prices = params.prices;
        prices.splice(index, 1);
        handleChanges("prices", prices, toolMenu);
    }

    const handleChangesPrice = (field: string, value: any, index: number) => {
        const price = params.prices[index];
        price[field] = value;
        const prices = params.prices.map((item, i) => i === index ? price : item);
        handleChanges("prices", prices, toolMenu);
    }

    const handleAddInclusion = () => {
        const inclusions = params.inclusions;
        inclusions.push(initialInclusion);
        handleChanges("inclusions", inclusions, toolMenu);
    }

    const handleRemoveInclusion = (index: number) => {
        const inclusions = params.inclusions;
        inclusions.splice(index, 1);
        handleChanges("inclusions", inclusions, toolMenu);
    }

    const handleChangesInclusion = (field: string, value: any, index: number) => {
        const inclusion = params.inclusions[index];
        inclusion[field] = value;
        const inclusions = params.inclusions.map((item, i) => i === index ? inclusion : item);
        handleChanges("inclusions", inclusions, toolMenu);
    }

    const renderPricesContent = () => {
        return (
            <>
            <Box my={2}>
                <button id="add-price-button" className={classes.addBtn} onClick={() => handleAddPrice()}>
                    <AddIcon />
                    <Box ml={1}>
                        <span>Add price</span>
                    </Box>
                </button>
            </Box>
            {params.prices.map((price, index) => (
                <React.Fragment key={index}>
                    <Box p={2}>
                        <Box mb={2}>
                            <button id={`remove-price-${index}`} className={classes.removeBtn} onClick={() => handleRemovePrice(index)}>
                                <RemoveIcon />
                                <Box ml={1}>
                                    <span>Remove price</span>
                                </Box>
                            </button>
                        </Box>
                        <Box my={2}>
                            <FormControl
                                variant="outlined"
                                color='primary'
                                required
                                error={!!errors[`strategy`]}
                                size="small"
                                disabled={!isOpen}
                                classes={{ root: classes.root }}
                                fullWidth>
                                <Box className={commonClasses.label} mb={1}>Payment Strategy *</Box>
                                <Select
                                    style={{ background: '#FFF' }}
                                    classes={{ root: classes.root }}
                                    value={price.strategy}
                                    error={!!errors[`strategy`]}
                                    inputProps={{
                                    name: 'Payment Strategy',
                                    id: 'payment-strategy-label',
                                    classes,
                                    }}
                                    renderValue={() => {
                                        if (price.strategy === null || !price.strategy) {
                                            return <Box className={commonClasses.colorMediumGray}>Select</Box>;
                                        }

                                        return price.strategy === PaymentStrategy.FullPayment ? "Yearly" : "Monthly";
                                    }}
                                    displayEmpty
                                    onChange={(v) => handleChangesPrice("strategy", v.target.value, index)}
                                >
                                    <MenuItem value={PaymentStrategy.FullPayment}>Yearly</MenuItem>
                                    <MenuItem value={PaymentStrategy.PartialPayment}>Monthly</MenuItem>
                                </Select>
                                <FormHelperText>{errors[`strategy`]}</FormHelperText>
                            </FormControl>
                        </Box>
                        <Box my={2}>
                            <Box className={commonClasses.label} mb={1}>OriginalPrice *</Box>
                            <TextField
                                fullWidth
                                size="small"
                                required
                                type="number"
                                InputProps={{ className: 'input', classes }}
                                id="originalPrice"
                                placeholder="originalPrice"
                                value={price.originalPrice}
                                variant="outlined"
                                disabled={!isOpen}
                                error={!!errors['originalPrice']}
                                helperText={errors['originalPrice']}
                                onChange={(v) => handleChangesPrice(v.target.id, v.target.value, index)}
                            />
                        </Box>
                        <Box my={2}>
                            <Box className={commonClasses.label} mb={1}>Price *</Box>
                            <TextField
                                fullWidth
                                size="small"
                                required
                                type="number"
                                InputProps={{ className: 'input', classes }}
                                id="price"
                                placeholder="price"
                                value={price.price}
                                variant="outlined"
                                disabled={!isOpen}
                                error={!!errors['price']}
                                helperText={errors['price']}
                                onChange={(v) => handleChangesPrice(v.target.id, v.target.value, index)}
                            />
                        </Box>
                        <Box my={2}>
                            <Box className={commonClasses.label} mb={1}>Discount *</Box>
                            <TextField
                                fullWidth
                                size="small"
                                required
                                type="number"
                                InputProps={{ className: 'input', classes }}
                                id="discount"
                                placeholder="discount"
                                value={price.discount}
                                variant="outlined"
                                disabled={!isOpen}
                                error={!!errors['discount']}
                                helperText={errors['discount']}
                                onChange={(v) => handleChangesPrice(v.target.id, v.target.value, index)}
                            />
                        </Box>
                        <Box my={2}>
                            <Box className={commonClasses.label} mb={1}>StartupFee *</Box>
                            <TextField
                                fullWidth
                                size="small"
                                required
                                type="number"
                                InputProps={{ className: 'input', classes }}
                                id="startupFee"
                                placeholder="startupFee"
                                value={price.startupFee}
                                variant="outlined"
                                disabled={!isOpen}
                                error={!!errors['startupFee']}
                                helperText={errors['startupFee']}
                                onChange={(v) => handleChangesPrice(v.target.id, v.target.value, index)}
                            />
                        </Box>
                        <Box my={2}>
                            <Box className={commonClasses.label} mb={1}>DiscountInsurance *</Box>
                            <TextField
                                fullWidth
                                size="small"
                                required
                                type="number"
                                InputProps={{ className: 'input', classes }}
                                id="discountInsurance"
                                placeholder="discountInsurance"
                                value={price.discountInsurance}
                                variant="outlined"
                                disabled={!isOpen}
                                error={!!errors['discountInsurance']}
                                helperText={errors['discountInsurance']}
                                onChange={(v) => handleChangesPrice(v.target.id, v.target.value, index)}
                            />
                        </Box>
                        <Box my={2} display="flex" alignItems="center">
                            <Box className={commonClasses.label} mr={3}>IsAutoRenewal *</Box>
                            <Box display="flex" alignItems="center">
                                <Checkbox
                                    icon={controlIcon}
                                    color="primary"
                                    checkedIcon={controlCheckedIcon}
                                    checked={price.isAutoRenewal}
                                    onChange={() => handleChangesPrice("isAutoRenewal", true, index)}
                                />
                                <Box mr={2}>Yes</Box>
                                <Checkbox
                                    icon={controlIcon}
                                    color="primary"
                                    checkedIcon={controlCheckedIcon}
                                    checked={!price.isAutoRenewal}
                                    onChange={() => handleChangesPrice("isAutoRenewal", false, index)}
                                />
                                <Box>No</Box>
                            </Box>
                        </Box>
                        <Box my={2}>
                            <FormControl
                                variant="outlined"
                                color='primary'
                                required
                                error={!!errors[`type`]}
                                size="small"
                                disabled={!isOpen}
                                classes={{ root: classes.root }}
                                fullWidth>
                                <Box className={commonClasses.label} mb={1}>PaymentType *</Box>
                                <Select
                                    style={{ background: '#FFF' }}
                                    classes={{ root: classes.root }}
                                    value={price.type}
                                    error={!!errors[`type`]}
                                    inputProps={{
                                    name: 'Payment Type',
                                    id: `payment-type-${index}`,
                                    classes,
                                    }}
                                    renderValue={() => {
                                        if (price.type === null) {
                                            return <Box className={commonClasses.colorMediumGray}>Select</Box>;
                                        }

                                        return PaymentPriceTypeNames[price.type];
                                    }}
                                    displayEmpty
                                    onChange={(v) => handleChangesPrice("type", v.target.value, index)}
                                >
                                    <MenuItem value={PaymentPriceType.Default}>{PaymentPriceTypeNames[PaymentPriceType.Default]}</MenuItem>
                                    <MenuItem value={PaymentPriceType.Insurance}>{PaymentPriceTypeNames[PaymentPriceType.Insurance]}</MenuItem>
                                    <MenuItem value={PaymentPriceType.PromoCode}>{PaymentPriceTypeNames[PaymentPriceType.PromoCode]}</MenuItem>
                                    <MenuItem value={PaymentPriceType.InsurancePromoCode}>{PaymentPriceTypeNames[PaymentPriceType.InsurancePromoCode]}</MenuItem>
                                </Select>
                                <FormHelperText>{errors[`type`]}</FormHelperText>
                            </FormControl>
                        </Box>
                    </Box>
                </React.Fragment>
            ))}
            </>
        )
    }

    const renderInclusionsContent = () => {
        return (
            <>
            <Box my={2}>
                <button id="add-inclusion-option-button" className={classes.addBtn} onClick={() => handleAddInclusion()}>
                    <AddIcon />
                    <Box ml={1}>
                        <span>Add Inclusion</span>
                    </Box>
                </button>
            </Box>
            {params.inclusions.map((inclusion, index) => (
                <React.Fragment key={index}>
                    <Box p={2}>
                        <Box mb={2}>
                            <button id={`remove-inclusion-${index}`} className={classes.removeBtn} onClick={() => handleRemoveInclusion(index)}>
                                <RemoveIcon />
                                <Box ml={1}>
                                    <span>Remove inclusion</span>
                                </Box>
                            </button>
                        </Box>
                        <Box my={2}>
                            <FormControl
                                variant="outlined"
                                color='primary'
                                required
                                error={!!errors[`productType`]}
                                size="small"
                                disabled={!isOpen}
                                classes={{ root: classes.root }}
                                fullWidth>
                                <Box className={commonClasses.label} mb={1}>ProductType *</Box>
                                <Select
                                    style={{ background: '#FFF' }}
                                    classes={{ root: classes.root }}
                                    value={inclusion.productType}
                                    error={!!errors[`productType`]}
                                    inputProps={{
                                    name: 'Inclusion ProductType',
                                    id: 'inclusion-productType-label',
                                    classes,
                                    }}
                                    renderValue={() => {
                                        if (inclusion.productType === null) {
                                            return <Box className={commonClasses.colorMediumGray}>Select</Box>;
                                        }

                                        return ProductTypeNames[inclusion.productType];
                                    }}
                                    displayEmpty
                                    onChange={(v) => handleChangesInclusion("productType", v.target.value, index)}
                                >
                                    <MenuItem value={ProductType.Membership}>{ProductTypeNames[ProductType.Membership]}</MenuItem>
                                    <MenuItem value={ProductType.StartupFee}>{ProductTypeNames[ProductType.StartupFee]}</MenuItem>
                                    <MenuItem value={ProductType.AddOns}>{ProductTypeNames[ProductType.AddOns]}</MenuItem>
                                    <MenuItem value={ProductType.PhysicianVisit}>{ProductTypeNames[ProductType.PhysicianVisit]}</MenuItem>
                                    <MenuItem value={ProductType.SickVisit}>{ProductTypeNames[ProductType.SickVisit]}</MenuItem>
                                    <MenuItem value={ProductType.HealthCoachVisit}>{ProductTypeNames[ProductType.HealthCoachVisit]}</MenuItem>
                                    <MenuItem value={ProductType.FollowUpLabs}>{ProductTypeNames[ProductType.FollowUpLabs]}</MenuItem>
                                    <MenuItem value={ProductType.FreeLabOrder}>{ProductTypeNames[ProductType.FreeLabOrder]}</MenuItem>
                                </Select>
                                <FormHelperText>{errors[`productType`]}</FormHelperText>
                            </FormControl>
                        </Box>
                        <Box my={2}>
                            <FormControl
                                variant="outlined"
                                color='primary'
                                required
                                error={!!errors[`option`]}
                                size="small"
                                disabled={!isOpen}
                                classes={{ root: classes.root }}
                                fullWidth>
                                <Box className={commonClasses.label} mb={1}>Option *</Box>
                                <Select
                                    style={{ background: '#FFF' }}
                                    classes={{ root: classes.root }}
                                    value={inclusion.option}
                                    error={!!errors[`option`]}
                                    inputProps={{
                                    name: 'Inclusion Option',
                                    id: `inclusion-option-${index}`,
                                    classes,
                                    }}
                                    renderValue={() => {
                                        if (inclusion.option === null) {
                                            return <Box className={commonClasses.colorMediumGray}>Select</Box>;
                                        }

                                        return InclusionOptionNames[inclusion.option];
                                    }}
                                    displayEmpty
                                    onChange={(v) => handleChangesInclusion("option", v.target.value, index)}
                                >
                                    <MenuItem value={InclusionOption.NoOne}>{InclusionOptionNames[InclusionOption.NoOne]}</MenuItem>
                                    <MenuItem value={InclusionOption.FirstFree}>{InclusionOptionNames[InclusionOption.FirstFree]}</MenuItem>
                                    <MenuItem value={InclusionOption.FirstAnnualFree}>{InclusionOptionNames[InclusionOption.FirstAnnualFree]}</MenuItem>
                                    <MenuItem value={InclusionOption.Limited}>{InclusionOptionNames[InclusionOption.Limited]}</MenuItem>
                                    <MenuItem value={InclusionOption.Unlimited}>{InclusionOptionNames[InclusionOption.Unlimited]}</MenuItem>
                                </Select>
                                <FormHelperText>{errors[`type`]}</FormHelperText>
                            </FormControl>
                        </Box>
                        <Box my={2}>
                            <Box className={commonClasses.label} mb={1}>Count *</Box>
                            <TextField
                                fullWidth
                                size="small"
                                required
                                type="number"
                                InputProps={{ className: 'input', classes }}
                                id="count"
                                placeholder="count"
                                value={inclusion.count}
                                variant="outlined"
                                disabled={!isOpen}
                                error={!!errors['count']}
                                helperText={errors['count']}
                                onChange={(v) => handleChangesInclusion(v.target.id, v.target.value, index)}
                            />
                        </Box>
                    </Box>
                </React.Fragment>
            ))}
            </>
        )
    }

    return (
        <Fragment>
            <ListItem id={id} className={classes.divider} button onClick={handleOpenToggle}>
                <ListItemText primary={<span className="text-medium">{title}</span>}/>
                {isOpen ? <ExpandLess className={classes.activeIcon}/> : <ExpandMore/>}
            </ListItem>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <Box p={2}>
                    <Box>
                        <Box className={commonClasses.label} mb={1}>Name *</Box>
                        <TextField
                            fullWidth
                            size="small"
                            required
                            type="text"
                            InputProps={{ className: 'input', classes }}
                            id="name"
                            placeholder="Name"
                            value={params.name}
                            variant="outlined"
                            disabled={!isOpen}
                            error={!!errors['name']}
                            helperText={errors['name']}
                            onChange={(v) => handleChanges(v.target.id, v.target.value, toolMenu)}
                        />
                    </Box>
                    <Box my={2}>
                        <Box className={commonClasses.label} mb={1}>DisplayName *</Box>
                        <TextField
                            fullWidth
                            size="small"
                            required
                            type="text"
                            InputProps={{ className: 'input', classes }}
                            id="displayName"
                            placeholder="DisplayName"
                            value={params.displayName}
                            variant="outlined"
                            disabled={!isOpen}
                            error={!!errors['displayName']}
                            helperText={errors['displayName']}
                            onChange={(v) => handleChanges(v.target.id, v.target.value, toolMenu)}
                        />
                    </Box>
                    <Box my={2}>
                        <Box className={commonClasses.label} mb={1}>Title *</Box>
                        <TextField
                            fullWidth
                            size="small"
                            required
                            type="text"
                            InputProps={{ className: 'input', classes }}
                            id="title"
                            placeholder="Title"
                            value={params.title}
                            variant="outlined"
                            disabled={!isOpen}
                            error={!!errors['title']}
                            helperText={errors['title']}
                            onChange={(v) => handleChanges(v.target.id, v.target.value, toolMenu)}
                        />
                    </Box>
                    <Box my={2}>
                        <Box className={commonClasses.label} mb={1}>Plan Template *</Box>
                        <FormControl
                            size="small"
                            color='primary'
                            error={!!errors[`paymentPlanTemplateId`]}
                            fullWidth>
                            <Select style={{ background: '#FFFFFF' }}
                                    id="paymentPlanTemplateId"
                                    onChange={(event: React.ChangeEvent<{ value: number }>) => handleChanges('paymentPlanTemplateId', event.target.value, toolMenu)}
                                    value={params.paymentPlanTemplateId ?? 0}
                                    error={!!errors[`paymentPlanTemplateId`]}
                                    variant="outlined">
                                {
                                    paymentPlans.map((plan, index) => {
                                        return <MenuItem key={index} value={plan.id}>{`${plan.displayName}`}</MenuItem>
                                    })
                                }
                            </Select>
                            <FormHelperText>{errors[`selectedPlan`]}</FormHelperText>
                        </FormControl>
                    </Box>
                    <Box my={2}>
                        <Box className={commonClasses.label} mb={1}>Practice Id *</Box>
                        <TextField
                            fullWidth
                            size="small"
                            required
                            type="number"
                            InputProps={{ className: 'input', classes }}
                            id="practiceId"
                            placeholder="PracticeId"
                            value={params.practiceId}
                            variant="outlined"
                            disabled={!isOpen}
                            error={!!errors['practiceId']}
                            helperText={errors['practiceId']}
                            onChange={(v) => handleChanges(v.target.id, v.target.value, toolMenu)}
                        />
                    </Box>
                    <Box my={2}>
                        <Box className={commonClasses.label} mb={1}>PeriodInMonths *</Box>
                        <TextField
                            fullWidth
                            size="small"
                            required
                            type="number"
                            InputProps={{ className: 'input', classes }}
                            id="periodInMonths"
                            placeholder="PeriodInMonths"
                            value={params.periodInMonths}
                            variant="outlined"
                            disabled={!isOpen}
                            error={!!errors['periodInMonths']}
                            helperText={errors['periodInMonths']}
                            onChange={(v) => handleChanges(v.target.id, v.target.value, toolMenu)}
                        />
                    </Box>
                    <Box my={2}>
                        <Box className={commonClasses.label} mb={1}>StripeProductId *</Box>
                        <TextField
                            fullWidth
                            size="small"
                            required
                            type="text"
                            InputProps={{ className: 'input', classes }}
                            id="stripeProductId"
                            placeholder="StripeProductId"
                            value={params.stripeProductId}
                            variant="outlined"
                            disabled={!isOpen}
                            error={!!errors['stripeProductId']}
                            helperText={errors['stripeProductId']}
                            onChange={(v) => handleChanges(v.target.id, v.target.value, toolMenu)}
                        />
                    </Box>
                    <Box my={2}>
                        <Box className={commonClasses.label} mb={1}>Desired Id *</Box>
                        <TextField
                            fullWidth
                            size="small"
                            required
                            type="number"
                            InputProps={{ className: 'input', classes }}
                            id="desiredId"
                            placeholder="desiredId"
                            value={params.desiredId}
                            variant="outlined"
                            disabled={!isOpen}
                            error={!!errors['desiredId']}
                            helperText={errors['desiredId']}
                            onChange={(v) => handleChanges(v.target.id, v.target.value, toolMenu)}
                        />
                    </Box>
                    <Box my={2}>
                        <Box className={commonClasses.label} mb={1}>IsActive *</Box>
                        <Box display="flex" alignItems="center">
                            <Checkbox
                                icon={controlIcon}
                                color="primary"
                                checkedIcon={controlCheckedIcon}
                                checked={params.isActive}
                                onChange={() => handleChanges("isActive", true, toolMenu)}
                            />
                            <Box mr={2}>Yes</Box>
                            <Checkbox
                                icon={controlIcon}
                                color="primary"
                                checkedIcon={controlCheckedIcon}
                                checked={!params.isActive}
                                onChange={() => handleChanges("isActive", false, toolMenu)}
                            />
                            <Box>No</Box>
                        </Box>
                    </Box>
                    <Box my={2}>
                        <Box className={commonClasses.label} mb={1}>IncludeDefaultAddOns *</Box>
                        <Box display="flex" alignItems="center">
                            <Checkbox
                                icon={controlIcon}
                                color="primary"
                                checkedIcon={controlCheckedIcon}
                                checked={params.includeDefaultAddOns}
                                onChange={() => handleChanges("includeDefaultAddOns", true, toolMenu)}
                            />
                            <Box mr={2}>Yes</Box>
                            <Checkbox
                                icon={controlIcon}
                                color="primary"
                                checkedIcon={controlCheckedIcon}
                                checked={!params.includeDefaultAddOns}
                                onChange={() => handleChanges("includeDefaultAddOns", false, toolMenu)}
                            />
                            <Box>No</Box>
                        </Box>
                    </Box>
                    <Box my={2}>
                        <Box className={commonClasses.label} mb={1}>CanBeActivated *</Box>
                        <Box display="flex" alignItems="center">
                            <Checkbox
                                icon={controlIcon}
                                color="primary"
                                checkedIcon={controlCheckedIcon}
                                checked={params.canBeActivated}
                                onChange={() => handleChanges("canBeActivated", true, toolMenu)}
                            />
                            <Box mr={2}>Yes</Box>
                            <Checkbox
                                icon={controlIcon}
                                color="primary"
                                checkedIcon={controlCheckedIcon}
                                checked={!params.canBeActivated}
                                onChange={() => handleChanges("canBeActivated", false, toolMenu)}
                            />
                            <Box>No</Box>
                        </Box>
                    </Box>
                    <Box my={2}>
                        <Box className={commonClasses.label} mb={1}>IsPrecisionCarePackageFlow *</Box>
                        <Box display="flex" alignItems="center">
                            <Checkbox
                                icon={controlIcon}
                                color="primary"
                                checkedIcon={controlCheckedIcon}
                                checked={params.isPrecisionCarePackageFlow}
                                onChange={() => handleChanges("isPrecisionCarePackageFlow", true, toolMenu)}
                            />
                            <Box mr={2}>Yes</Box>
                            <Checkbox
                                icon={controlIcon}
                                color="primary"
                                checkedIcon={controlCheckedIcon}
                                checked={!params.isPrecisionCarePackageFlow}
                                onChange={() => handleChanges("isPrecisionCarePackageFlow", false, toolMenu)}
                            />
                            <Box>No</Box>
                        </Box>
                    </Box>
                    {renderPricesContent()}
                    {renderInclusionsContent()}
                    <WildHealthButton
                        fullWidth
                        id="payment-plan-execute-confirm-button"
                        loading={isLoading}
                        onClick={handleCreateDefaultPaymentPlan}
                    >
                        Execute
                    </WildHealthButton>
                </Box>
            </Collapse>
        </Fragment>
    )

}

export default CreateDefaultPaymentPlanComponent;