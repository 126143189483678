export const httpStatusCode = {
    OK: 200,
    BadRequest: 400,
    Unauthorized: 401,
    Forbidden: 403,
    NotFound: 404,
    RequestTimeout: 408,
    Conflict: 409,
    UnsupportedMediaType: 415,
    InternalServerError: 500,
    ServiceUnavailable: 503
};
