import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            color: colors.systemBlack,
            fontSize: '2rem',
            fontFamily: 'Be Vietnam Pro',
            fontWeight: 400,
            lineHeight: 1.334,
        },
        subTitle: {
            color: colors.systemBlack,
            fontSize: 16,
            fontFamily: 'Be Vietnam Pro',
            lineHeight: 1.8,
        },
        card: {
            boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.03)",
            display: "inline-block",
            borderRadius: "4px",
            width: '100%',
        },
        bgMain: {
            backgroundColor: colors.bg,
            width: '100%'
        },
        cardDivider: {
            backgroundColor: colors.bg,
            margin: 'auto',
            height: 2,
        },
        listItemText: {
            paddingRight: 40,
        },
    })
);
