import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { colors } from '../../../common/constants/colors'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rootView: {
            fontSize: 14,
            minHeight: 30,
            borderRadius: 2,
            display: 'flex',
            minWidth: 64,
            paddingRight: 14,
            color: colors.black,
            width: '100%',
        },
        container: {
            marginRight: '10px',
            marginTop: '10px',
            marginBottom: '10px',
            display: 'flex'
        },
        containerMain: {
            width: '100%',
            marginTop: '10px',
            paddingTop: "30px",
            paddingLeft: "30px",
            paddingRight: "30px",
            backgroundColor: colors.white,
            [theme.breakpoints.down("xs")]: {
                paddingTop: "16px",
                paddingLeft: "16px",
                paddingRight: "16px",
            },
        },
        title: {
            fontSize: 20,
            fontWeight: 600
        },
        titleContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        button: {
            color: colors.main,
            fontSize: 14
        },
        buttonText: {
            marginLeft: '6px',
            textTransform: 'capitalize'
        },
        empty: {
            display: 'flex',
            width: '100%'
        },
        root: {
            height: '100%',
            [theme.breakpoints.down("xs")]: {
                flex: 1,
            },
            
        },
        message: {
            fontFamily: "Poppins",
            fontWeight: 600,
            fontSize: 20,
            color: colors.gray1,
            marginBottom: 8,
        },
        valueView: {
            fontSize: 14,
            minHeight: 30,
            borderRadius: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'fit-content',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            minWidth: 64,
            paddingRight: 14,
            paddingLeft: 14,
            color: '#091D36',
            backgroundColor: '#EFF3F8'
        },
    })
)