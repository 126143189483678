import {
  Box,
  Collapse,
  Grid,
  IconButton,
  List,
  ListItem,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DescriptionIcon from "@material-ui/icons/Description";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import GetAppIcon from "@material-ui/icons/GetApp";
import React from "react";
import { AttachmentsPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/AttachmentsPlaceHolder";
import { AttachedFileModel } from "../../models/message.models";
import { Group, useFacade } from "./attachmentsComponent.hooks";
import { FileName, Title, useStyles } from "./attachmentsComponent.styles";
import { WildHealthTabControl } from "../../../common/components/wildHealthTabControl/WildHealthTabControl";
import { TabItem } from "../../../common/components/wildHealthTabControl/wildHealthTabControl.hooks";
import { useStylesFullWidthTabs } from "../../../common/components/wildHealthTabControl/wildHealthTabControl.styles";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";

export const AttachmentsComponent: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const [
    {
      groups,
      pdfGroup,
      imageGroup,
      isLoading
    },
    handleToggleSection
  ] = useFacade();
  const sideBarTabsStyle = useStylesFullWidthTabs();

  const downloadMedia = (media: AttachedFileModel) => {
    media.url
      ? window.open(media.url)
      : media.file.getContentTemporaryUrl().then(url => window.open(url));
  }

  const setUrl = (media: AttachedFileModel) => {
    media.file.getContentTemporaryUrl().then(url => {
      media.url = url;
    });
  }

  const renderImage = (media: AttachedFileModel, index: number) => {
    return (
      <Box
        key={index}
        m={1}
        className={classes.imageFile}
        style={{ backgroundImage: `url(${media.url ? media.url : setUrl(media)})` }}
      >
        <IconButton
          id="attachments-download"
          className={classes.fileButton}
          onClick={() => downloadMedia(media)}
          size="small"
        >
          <GetAppIcon className={classes.downloadIcon} />
        </IconButton>
      </Box>
    );
  };

  const renderPdf = (media: AttachedFileModel, index: number) => {
    return (
      <ListItem key={index}>
        <Box className={classes.document}>
          <IconButton
            id="attachments-download-file"
            className={classes.fileButton}
            onClick={() => downloadMedia(media)}
            size="small"
          >
            <DescriptionIcon className={classes.fileIcon} />
            <GetAppIcon className={classes.downloadIcon} />
          </IconButton>
          <Box ml={1}>
            <FileName primary={media.file.state.filename ?? media?.fileName} />
          </Box>
        </Box>
      </ListItem>
    );
  };

  const renderGroup = (group: Group, index: number) => {
    const isImageGroup = group.title.includes("Image");
    const items = group.items.map(isImageGroup ? renderImage : renderPdf);

    if (isSmallScreen) {
      return (
        <React.Fragment key={`${index}-tab-item`}>
          {isImageGroup ? <Grid container>{items} </Grid> : <List>{items}</List>}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment key={`${index}-fragment`}>
          <ListItem
            id="attachments-toggle-section"
            key={index}
            button
            onClick={() => handleToggleSection(index)}
          >
            <Title primary={group.title} />
            {group.isOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse key={`${index}-group`} in={group.isOpen} timeout="auto" unmountOnExit>
            {isImageGroup ? <Grid container>{items}</Grid> : <List>{items}</List>}
          </Collapse>
        </React.Fragment>
      );
    }
  };

  const renderContent = () => {
    if (isSmallScreen) {
      if (isLoading) {
        return <WildHealthLinearProgress />
      }
      let pdfs, images;
      if (groups && groups.length) {
        pdfs = groups.find((group) => group.title.includes("PDFs"));
        images = groups.find((group) => group.title.includes("Image"));
      }
      const tabs: TabItem[] = [
        {
          title: "PDFs",
          content: pdfGroup.length ? (
            renderGroup(pdfs, 0)
          ) : (
            <AttachmentsPlaceHolder message="No Pdfs yet." />
          ),
        },
        {
          title: "Images",
          content: imageGroup.length ? (
            renderGroup(images, 1)
          ) : (
            <AttachmentsPlaceHolder message="No Images yet." />
          ),
        },
      ];
      return (
        <Box width="100%" className={classes.content}>
          <WildHealthTabControl items={tabs} className={sideBarTabsStyle} />
        </Box>
      );
    } else {
      return (
        <List className={classes.content}>
          {groups && groups.length ? (
            groups.map((group, index) => renderGroup(group, index))
          ) : (
            <AttachmentsPlaceHolder message="No attachments." />
          )}
        </List>
      );
    }
  };

  return (
    <Box>
      <Box className={classes.titleContainer}>
        {!isSmallScreen && <AttachFileIcon className={classes.titleIcon} />}
        <Box className={classes.title}>Attachments</Box>
      </Box>
      {renderContent()}
    </Box>
  );
};
