import Axios from 'axios-observable';
import { Observable } from "rxjs";
import { snackService } from "../modules/common/snack/state";
import { authService } from "../modules/auth/services/auth.service";
import { AuthModel } from '../modules/auth/models/auth.models';
import { authHeader } from "../modules/common/helpers/auth-header";
import { AddressModel } from '../modules/common/models/address.models';
import { User } from '../modules/common/models/user.models';
import { authStore } from '../modules/auth/stores/auth';

interface CreateUserModel {
    email: string;
    password: string;
    smsMarketing:boolean;
    practiceId: number;
}

interface UpdateUserModel {
    email: string;
    firstName: string;
    lastName: string;
    gender: string;
    phoneNumber: string;
    birthDate: Date;
    smsMarketing: boolean;
    billingAddress: AddressModel;
    shippingAddress: AddressModel;
    meetingRecordingConsent: boolean;
}

/**
 * Provides method for working with users
 */
export class UsersService {
    private url = `${process.env.REACT_APP_API_URL}Users`;

    /**
     * Registers new user
     * @param model
     */
    public create(model: CreateUserModel): Observable<AuthModel> {
        return new Observable(observer => {
            Axios.post<AuthModel>(`${this.url}`, model)
                .pipe()
                .subscribe(
                    (response) => {
                        authService.authorize(response.data);
                        authStore.update({ isNewAccount: true });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * update user with provided data
     * @param model
     */
    public update(model: UpdateUserModel): Observable<UpdateUserModel>{

        const config = { headers: authHeader() };

        return new Observable(observer => {
            Axios.put<UpdateUserModel>(`${this.url}`, model, config)
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Get a user based on the provided token
     */
    public getMe(): Observable<AuthModel> {

        const config = { headers: authHeader() };

        return new Observable(observer => {
            Axios.get(`${this.url}/GetMe`, config)
                .pipe()
                .subscribe(
                    response => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public getMyUserInfo(): Observable<User> {

        const config = { headers: authHeader() };

        return new Observable(observer => {
            Axios.get(`${this.url}/GetMyUserInfo`, config)
                .pipe()
                .subscribe(
                    response => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }
}

export const usersService = new UsersService();
