import {useState} from 'react';
import {CoverageModel, InsuranceVerificationModel} from '../../models/insurance.models';
import {insuranceVerificationService} from '../../services/insuranceVerification.service';
import {CoverageStatus} from "../../enums/coverageStatus";
import {insuranceService} from "../../services/insurance.service";

interface InsuranceCardComponentState {
    isVerifyInsuranceOpen: boolean;
    selectedCoverageId: string;
    isRunningVerification: boolean;
}

export function useFacade(patientId: number): [InsuranceCardComponentState, Function, Function, Function, Function] {
    const [state, setState] = useState({
        isVerifyInsuranceOpen: false,
        selectedCoverageId: '',
    } as InsuranceCardComponentState);

    const handleToggleVerifyDialog = (toShow: boolean, coverageId = '') => {
        setState({...state, isVerifyInsuranceOpen: toShow, selectedCoverageId: coverageId});
    };

    const handleActivationSwitch = (coverage: CoverageModel) => {
        if (coverage.status === CoverageStatus.Active) {
            insuranceService.deactivateCoverage(coverage.id).subscribe();
        } else {
            insuranceService.activateCoverage(coverage.id).subscribe();
        }
    }

    const handleVerifyInsurance = (isVerified: boolean) => {
        const model = {
            patientId: patientId,
            isVerified: isVerified,
            coverageId: state.selectedCoverageId,
          } as InsuranceVerificationModel


        insuranceVerificationService.verifyInsurance(model).subscribe(() => {
            insuranceVerificationService.getInsuranceVerification(patientId).subscribe();
        });

        setState({...state, isVerifyInsuranceOpen: false});
    }

    const runInsuranceVerification = (coverageId: number) => {
        setState({ ...state, isRunningVerification: true });

        const cb = () => setState({ ...state, isRunningVerification: false });

        insuranceVerificationService.runInsuranceVerification(coverageId).subscribe(() => {
            insuranceVerificationService.getInsuranceVerification(patientId).subscribe(cb, cb);
        }, cb);
    }

    return [state, handleToggleVerifyDialog, handleActivationSwitch, handleVerifyInsurance, runInsuranceVerification]
}
