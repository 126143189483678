import React from 'react';
import { Box, IconButton, TextField, Tooltip } from "@material-ui/core";
import { useFacade } from './textFieldNoteComponent.hooks';
import { TextMarkdownComponent } from '../textMarkdownComponent/TextMarkdownComponent';
import VisibilityOutlinedIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import { colors } from '../../../common/constants/colors';

interface TextFieldNoteProps {
    rows: number;
    disabled?: boolean;
    uppercase?: boolean;
    title?: string;
    label?: string;
    content: string;
    placeholder?: string;
    className?: any;
    handleChanged: Function;
    handleKeyDown?: Function;
    error?: boolean;
    helperText?: string;
    key2?: number | string;
    key3?: number;
    id?: string;
    isFollowUp?: boolean;
}

export const TextFieldNoteComponent: React.FC<TextFieldNoteProps> = (props: TextFieldNoteProps) => {
    const {
        rows,
        disabled = false,
        uppercase,
        title,
        label,
        content,
        placeholder = '',
        className,
        handleChanged,
        handleKeyDown = () => { },
        error = false,
        helperText = '',
        key2,
        key3,
        id,
        isFollowUp = false,
    } = props;

    const [{
        isEdit
    },
        handleEdit,
    ] = useFacade();

    return (<Box width='100%'>
        <Box className={className} mb={2} display="flex" alignItems="center">
            {title && <Box mr={1} className={uppercase ? 'uppercase' : ''}>{title}</Box>}
            <IconButton
                aria-label="toggle edit visibility"
                onClick={() => handleEdit()}
                edge="end"
                size='small'
            >
                {
                    isEdit
                        ? <Tooltip placement="top" arrow title="View Markdown"><VisibilityOutlinedIcon style={{ color: colors.main, fontSize: 20 }} /></Tooltip>
                        : <Tooltip placement="top" arrow title="Edit Text"><EditIcon style={{ color: colors.main, fontSize: 20 }} /></Tooltip>
                }
            </IconButton>
        </Box>
        <Box>
            {isEdit
                ? <TextField
                    fullWidth
                    id={id}
                    rows={rows}
                    rowsMax={15}
                    multiline
                    type='text'
                    size="small"
                    label={label}
                    placeholder={placeholder}
                    variant="outlined"
                    disabled={disabled}
                    value={content}
                    InputProps={{
                        className: 'input',

                    }}
                    onKeyDown={(e) => { isFollowUp ? handleKeyDown(e, id, key2, key3) : handleKeyDown(e, handleChanged) }}
                    onChange={(e) => {
                        handleChanged(e.target.value, key2, key3);
                    }}
                    error={error}
                    helperText={helperText}

                />
                : <Box>
                    <TextMarkdownComponent content={content} />
                </Box>}
        </Box>
    </Box>
    )
}
