import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { confirmService } from "../../../../services/confirm.service";
import { onEmit } from "../../../common/helpers/on-emit";
import { EmployeeType } from "../../../employee/models/employee.enums";
import { ConversationAuthorModel, EmployeeConversationModel } from "../../models/conversation.models";
import { conversationsService } from "../../services/conversations.service";
import { employeeConversationsQuery } from "../../stores/employeeConversationsStore/employeeConversations.query";
import { ConversationState } from "../../models/conversationState.models";
import { snackService } from "../../../common/snack/state";
import { navigationService } from "../../../../services/navigation.service";
import { authQuery } from "../../../auth/stores/auth";
import { patientsService } from "../../../patients/services/patients.service";
import { ScheduledMessageModel } from "../../models/message.models";
import { scheduledMessagesQuery } from "../../stores/scheduledMessagesStore/scheduledMessages.query";
import { DataSpecificationsEnum } from "../../../common/constants/data-specifications";

interface EmployeeChatComponentState {
    isClosing: boolean;
    isLoading: boolean;
    author: ConversationAuthorModel;
    isEmployeeAssigned: boolean;
    isSideAreaOpen: boolean;
    isAddUserOpen: boolean;
    employeeType: EmployeeType;
    connectionStatus: boolean;
}

export function useFacade(resetTargetConversation: () => void, targetConversation?: EmployeeConversationModel): [EmployeeChatComponentState,
    (open: boolean) => void,
    () => void,
    (id: number) => void,
    (id: number) => void,
    () => void,
    (id: number) => void
] {

    const [state, setState] = useState({
        isClosing: false,
        isLoading: true,
        author: null,
        isEmployeeAssigned: false,
        targetConversation: null,
        isSideAreaOpen: false,
        isAddUserOpen: false,
        employeeType: authQuery.getEmployeeType(),
        connectionStatus: false,
    } as EmployeeChatComponentState);

    const handleToggleSideArea = (open: boolean) => {
        setState({ ...state, isSideAreaOpen: open });
    }

    const handleToggleAddUser = () => {
        setState(state => ({ ...state, isAddUserOpen: !state.isAddUserOpen }))
    }

    const handleAddUser = (id: number) => {
        setState(state => ({ ...state, isLoading: true }));
        const cb = () => setState(state => ({ ...state, isAddUserOpen: false, isLoading: false }));
        conversationsService.addEmployeeToConversationModel({ conversationId: targetConversation.id, employeeId: id }).subscribe(cb, cb);
    }

    const handleRemoveUserFromThread = (id: number) => {
        confirmService.confirm('Remove User', 'Are You Sure You Want to Remove this User?', 'Remove User', 'Cancel').subscribe(() => {
            setState(state => ({ ...state, isLoading: true }));
            const cb = () => setState(state => ({ ...state, isLoading: false }));
            conversationsService.removeEmployeeToConversationModel({ conversationId: targetConversation.id, userId: id }).subscribe(() => {
                if (id === authQuery.getId()) {
                    resetTargetConversation();
                }
                cb();
            }, cb);
        })
    }

    const goToPatientProfile = (id: number) => {
        navigationService.toNewTabManagePatientProfile(id);
    }

    const handleCloseTicket = () => {
        confirmService.confirm('Close Ticket', 'Are You Sure You Want to Close this Ticket?', 'Close Ticket').subscribe(() => {

            setState(state => ({
                ...state,
                isClosing: true
            }));

            conversationsService.updateSupportConversationState({ id: targetConversation.id, state: ConversationState.Closed }).subscribe(
                () => {
                    setState(state => ({
                        ...state,
                        isClosing: false
                    }));

                    snackService.success('The ticket has been closed!')
                },
                () => {
                    setState(state => ({
                        ...state,
                        isClosing: false
                    }));

                    snackService.error('Close ticket failed!')
                }
            );
        })
    }

    useEffect(() => {
        setState(state => ({
            ...state,
            isLoading: true,
        }));
        patientsService.getAll([], [], [], [], [], [], `${targetConversation?.patients[0]?.patientId}`, 0, 1).subscribe(
            (patient) => {
                patientsService.get(targetConversation?.patients[0]?.patientId, DataSpecificationsEnum.UpdatePatientSpecification);
                setState(state => ({
                    ...state,
                    isEmployeeAssigned: !!patient?.[0]?.employees?.find(el => el.userId === authQuery.getId()),
                    isLoading: false,
                }));
            },
            () => {
                setState(state => ({
                    ...state,
                    isLoading: false,
                }));
            })

    }, [targetConversation]);

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<ConversationAuthorModel>(employeeConversationsQuery.author$, author =>
                setState(state => ({ ...state, author: author }))
            ),
            onEmit<boolean>(employeeConversationsQuery.connectionStatus$, status =>
                setState(state => ({ ...state, connectionStatus: status }))
            ),
            onEmit<ScheduledMessageModel>(scheduledMessagesQuery.editingTarget$, message => {
                if (Boolean(message)) {
                    setState(state => ({ ...state, isSideAreaOpen: false }))
                }
            }),
        ];

        setState(state => ({ ...state, author: employeeConversationsQuery.getAuthor() }))
        
        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [state,
        handleToggleSideArea,
        handleToggleAddUser,
        handleAddUser,
        handleRemoveUserFromThread,
        handleCloseTicket,
        goToPatientProfile
    ];
}
