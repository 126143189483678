import {
    Box,
    Divider,
    Grid,
} from '@material-ui/core';
import { BackTitleComponent } from '../../../../components/pageTitleComponent/PageTitleComponent';
import { renderQuestionnaireName } from "../../helpers/render-questionnaire-name";
import React from 'react';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { useFacade } from "./questionnairesComponent.hooks";
import { useStyles } from "./QuestionnairesComponent.styles";
import { CompletedQuestionnaireComponent } from '../completedQuestionnaireComponent/CompletedQuestionnaireComponent';
import { renderEmptyValue } from '../../../common/helpers/empty-value';
import clsx from 'clsx';
import { QuestionnaireCardComponent } from '../questionnaireCardComponent/QuestionnaireCardComponent';
import commonUseStyles from '../../../common/styles/common.styles';
import {QuestionnaireStatusType, QuestionnaireSubType, QuestionnaireType} from '../../models/questionnaire.models';
import { InitialQuestionnaireResultComponentHealthForms } from "../initialQuestionnaireResultComponent/InitialQuestionnaireResultComponentHealthForms";
import { HealthLogQuestionnaireResultComponent } from '../healthLogQuestionnaireResultComponent/HealthLogQuestionnaireResultComponent';
import { InitialQuestionnaireResultComponent } from '../initialQuestionnaireResultComponent/InitialQuestionnaireResultComponent';
import { FollowUpFormResultComponent } from '../followUpFormResultComponent/FollowUpFormResultComponent';
import {
    AuthorizedInitialQuestionnaireSurveyComponent
} from "../../pages/initialQuestionnaireSurveyComponent/InitialQuestionnaireSurveyComponent";
import {
    HealthLogQuestionnaireSurveyComponent
} from "../healthLogQuestionnaireSurveyComponent/HealthLogQuestionnaireSurveyComponent";
import {
    MedicalHistoryQuestionnaireSurveyComponent
} from "../medicalHistoryQuestionnaireSurveyComonent/MedicalHistoryQuestionnaireSurveyComponent";
import {
    HealthGoalsQuestionnaireSurveyComponent
} from "../healthGoalsQuestionnaireSurveyComponent/HealthGoalsQuestionnaireSurveyComponent";
import {
    DetailedQuestionnaireSurveyComponent
} from "../detailedQuestionnaireSurveyComponent/DetailedQuestionnaireSurveyComponent";
import {FollowUpFormSurveyComponent} from "../followUpFormSurveyComponent/FollowUpFormSurveyComponent";

export interface QuestionnairesComponentProps {
    patientId: number;
    resultId?: number;
}

export const QuestionnairesComponent: React.FC<QuestionnairesComponentProps> = (props: QuestionnairesComponentProps) => {
    const classes = useStyles();
    const [
        {
            patient,
            isLoading,
            pendingQuestionnaires,
            availableQuestionnaires,
            completedQuestionnaires,
            targetResult,
            healthForms
        },
        handleGoToQuestionnaireResult,
        handleStartQuestionnaire,
        handleGoBackToQuestionnaires
    ] = useFacade(props.patientId, props.resultId);



    const commonClasses = commonUseStyles();

    let healthFormsSorted = []

    if (!isLoading) {
        const internalQuestionnaires = [...pendingQuestionnaires, ...availableQuestionnaires]
        healthFormsSorted = [
            ...healthForms?.filter(el => el.questionnaireStatusType === QuestionnaireStatusType.InCompleted),
            ...internalQuestionnaires,
            ...healthForms?.filter(el => el.questionnaireStatusType === QuestionnaireStatusType.New),
            ...healthForms?.filter(el => el.questionnaireStatusType === QuestionnaireStatusType.Completed),

        ]
    }

    const questionnaireComponent = (item) => {
        if (item?.type) {
            return <QuestionnaireCardComponent
                resultId={item.resultId}
                completed={item.questionnaireStatusType === QuestionnaireStatusType.Completed}
                questionnaire={item}
                handleView={handleGoToQuestionnaireResult}
                handleStart={handleStartQuestionnaire}
                handleComplete={handleGoToQuestionnaireResult}
            />
        }

        return item?.questionnaire
            ? <QuestionnaireCardComponent
                resultId={item.id}
                completed={false}
                questionnaire={item.questionnaire}
                handleView={handleGoToQuestionnaireResult}
                handleStart={handleStartQuestionnaire}
                handleComplete={handleGoToQuestionnaireResult}
            />
            : <QuestionnaireCardComponent
                completed={false}
                questionnaire={item}
                handleView={handleGoToQuestionnaireResult}
                handleStart={handleStartQuestionnaire}
                handleComplete={handleGoToQuestionnaireResult}
            />
    }

    const renderFollowing = () => {
        return (
            <Grid container spacing={4}>
                {
                    healthFormsSorted.length ?
                        <>
                            {
                                healthFormsSorted.map((item, index) => (
                                    <Grid item md={6} xs={12} key={index}>
                                        {questionnaireComponent(item)}
                                    </Grid>
                                ))
                            }
                        </> : <></>
                }
            </Grid>
        )
    }

    const renderCompleted = () => {
        return (
            <>
                <Box>
                    <span className={clsx(commonClasses.size18, commonClasses.textMedium)}>Previously completed health forms</span>
                </Box>
                {
                    completedQuestionnaires.length
                        ? <Box mt={2}>
                            <Grid container spacing={4}>
                                {
                                    completedQuestionnaires.map((item, index) =>
                                        <Grid key={index} item md={6} xs={12}>
                                            <CompletedQuestionnaireComponent
                                                questionnaireResult={item}
                                                handleView={handleGoToQuestionnaireResult}
                                            />
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Box>
                        : <Box>
                            {
                                renderEmptyValue('There`s no completed health forms yet.')
                            }
                        </Box>
                }

            </>
        )
    }

    const renderResult = (): JSX.Element => {
        switch (targetResult.questionnaire.type) {
            case QuestionnaireType.HealthForms: return <InitialQuestionnaireResultComponentHealthForms gender={patient.gender} result={targetResult} questionnaire={targetResult.questionnaire} patientId={props.patientId} />
            case QuestionnaireType.HealthLog: return <HealthLogQuestionnaireResultComponent gender={patient.gender} result={targetResult} questionnaire={targetResult.questionnaire} />
            case QuestionnaireType.Initial: return <InitialQuestionnaireResultComponent gender={patient.gender} result={targetResult} questionnaire={targetResult.questionnaire} />
            case QuestionnaireType.FollowUpCallForms: return <FollowUpFormResultComponent gender={patient.gender} result={targetResult} questionnaire={targetResult.questionnaire} />
            default: return <></>
        }
    }

    const renderQuestionnaire = (): JSX.Element => {
        switch (targetResult.questionnaire.type) {
            case QuestionnaireType.Initial: return <AuthorizedInitialQuestionnaireSurveyComponent />;
            case QuestionnaireType.HealthLog: return <HealthLogQuestionnaireSurveyComponent result={targetResult} questionnaire={targetResult.questionnaire} patientId={props.patientId} handleGoBack={handleGoBackToQuestionnaires} />;
            case QuestionnaireType.HealthForms:
                switch (targetResult.questionnaire.subType) {
                    case QuestionnaireSubType.MedicalHistoryIncomplete: return <MedicalHistoryQuestionnaireSurveyComponent gender={patient.gender} questionnaire={targetResult.questionnaire} result={targetResult} patientId={props.patientId} handleGoBack={handleGoBackToQuestionnaires} />;
                    case QuestionnaireSubType.GoalsIncomplete: return <HealthGoalsQuestionnaireSurveyComponent gender={patient.gender} questionnaire={targetResult.questionnaire} result={targetResult} patientId={props.patientId} handleGoBack={handleGoBackToQuestionnaires} />;
                    case QuestionnaireSubType.DetailedHistoryIncomplete: return <DetailedQuestionnaireSurveyComponent gender={patient.gender} questionnaire={targetResult.questionnaire} result={targetResult} patientId={props.patientId} handleGoBack={handleGoBackToQuestionnaires} />;
                    default: return <></>;
                }
            case QuestionnaireType.FollowUpCallForms: return <FollowUpFormSurveyComponent questionnaire={targetResult.questionnaire} patientId={props.patientId} result={targetResult} handleGoBack={handleGoBackToQuestionnaires} />
            default: return <></>;
        }
    }

    const renderContent = () => {
        if (targetResult) {
            return (
                <>
                    <Box display="flex" alignItems="center">
                        <Box pb={2.5} className={classes.bgMain}>
                            <BackTitleComponent
                                title='Back to Health Forms'
                                action={() => { handleGoBackToQuestionnaires() }}
                            />
                            <Box px={2} fontSize={24} fontWeight={500}>{!isLoading && renderQuestionnaireName(targetResult)} </Box>
                        </Box>
                    </Box>
                    <Box pb={2}>
                        {
                            targetResult.submittedAt
                                ? renderResult()
                                : renderQuestionnaire()
                        }
                    </Box>
                </>
            )
        }

        return (
            <Box p={4}>
                <Box mb={5}>
                    <span className={clsx(commonClasses.size20, commonClasses.textMedium)}>Health Forms</span>
                </Box>
                <Box pb={4}>
                    {
                        renderFollowing()
                    }
                </Box>
                <Divider />
                <Box mt={5}>
                    {
                        renderCompleted()
                    }
                </Box>
            </Box>
        )
    }

    return (
        isLoading ?
            <WildHealthLinearProgress /> :
            <Box>
                {renderContent()}
            </Box>
    )
}
