import React from 'react';
import { AuthenticatedLayoutComponent } from '../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from "../../../../models/menu.models";
import { PermissionType, UserType } from "../../../auth/models/auth.enums";
import { isElementEnabled } from '../../../common/components/ProtectedElement';
import { WildHealthTabControl } from "../../../common/components/wildHealthTabControl/WildHealthTabControl";
import { TabItem } from "../../../common/components/wildHealthTabControl/wildHealthTabControl.hooks";
import { ManageLocationsCommonComponent } from '../../../locations/components/manageLocationsCommonComponent/ManageLocationsCommonComponent';
import { ManageEmployeesComponent } from "../../components/manageEmployeesComponent/ManageEmployeesComponent";

export const ManageEmployeesPage: React.FC = () => {
    const tabs: TabItem[] = [];

    if (isElementEnabled([PermissionType.ManageEmployees, PermissionType.ViewOnly], UserType.Employee)) {
        tabs.push({
            title: 'Users',
            content: <ManageEmployeesComponent/>,
        });
    }

    if (isElementEnabled([PermissionType.ManageLocations, PermissionType.ViewOnly], UserType.Employee)) {
        tabs.push({
            title: 'Pods',
            content: <ManageLocationsCommonComponent/>,
        });
    }

    return (
        <AuthenticatedLayoutComponent
            title={TitlesService.employeesTitle}
            pageName={PagesNamesService.employees}
            selectedMenuItem={MenuItemTypes.Employees}
        >
            <WildHealthTabControl items={tabs}/>
        </AuthenticatedLayoutComponent>
    )
}