import React from 'react';
import { AuthenticatedLayoutComponent } from "../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent";
import { TitlesService } from "../../../../constants/title.constants";
import { Box } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { navigationService } from '../../../../services/navigation.service';
import { useHistory } from 'react-router';
import { FellowPatientsComponent } from "../../components/fellowPatients/FellowPatientsComponent";
import { commonUseStyles } from "../../../common/styles/common.styles";
import clsx from 'clsx';
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { navigationNumber } from '../../../rosters/models/roster.model';

export const FellowPatientsPage: React.FC<any> = (props: any) => {
    const fellowId = Number(props.match.params.fellowId);
    const rosterId = Number(props?.location?.state?.id);

    const history = useHistory();
    const commonClasses = commonUseStyles();
    
    return (
        <AuthenticatedLayoutComponent
            title={TitlesService.fellowshipTitle}
            pageName={`${PagesNamesService.fellowship}-${rosterId}`}
            selectedMenuItem={navigationNumber + rosterId}
        >
            <Box id="fellow-patients-go-back" py={2} display="flex" alignItems="center" className={clsx(commonClasses.colorGray1, commonClasses.backgroundColorBg, commonClasses.pointer)} onClick={() => {navigationService.toFellowship(history, rosterId)}}>
                <Box>
                    <ArrowBack fontSize="small"/>
                </Box>
                <Box ml={1}>
                    <span>Back to Roster</span>
                </Box>
            </Box>
            <Box p="30px" >
                <FellowPatientsComponent fellowId={fellowId} />
            </Box>
            
        </AuthenticatedLayoutComponent>
    )
}