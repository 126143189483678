import React from "react";
import { Box, Grid, useTheme, useMediaQuery } from "@material-ui/core";
import { useFacade } from "./patientProfilePage.hooks";
import { AuthenticatedLayoutComponent } from "../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent";
import { TitlesService } from "../../../../constants/title.constants";
import { MenuItemTypes } from "../../../../models/menu.models";
import { PagesNamesService } from "../../../../constants/pagesNames.constants";
import { useStyles } from './patientProfilePage.styles';
import commonUseStyles from '../../../common/styles/common.styles';
import { PharmacyInfoWidgetComponent } from "../../../patients/components/PatientInformationWidget/PharmacyInfoWidgetComponent/PharmacyInfoWidgetComponent";
import clsx from "clsx";
import { AddressInfoWidgetComponent } from "../../../patients/components/PatientInformationWidget/AddressInfoWidgetComponent/AddressInfoWidgetComponent";
import { GeneralInfoWidgetComponent } from "../../../patients/components/PatientInformationWidget/GeneralInfoWidgetComponent/GeneralInfoWidgetComponent";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { FeatureComponent } from "../../../common/components/featureFlags/FeatureComponent";
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import { SessionRecordingSettingWidgetComponent } from "../../../patients/components/PatientInformationWidget/SessionRecordingSettingWidgetComponent/SessionRecordingSettingWidgetComponent";


export const PatientProfilePage: React.FC = () => {
    const [
        {
            isLoading,
        },
    ] = useFacade();

    const classes = useStyles();
    const commonClasses = commonUseStyles();

    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const renderGeneralInfo = () => {
        if (isLoading) {
          return (
            <Box p={4} className={classes.widget}>
                <WildHealthLinearProgress />
            </Box>
          )
        }

        return (
            <>
                <Box p={smallScreen ? 2 : "30px"} className={classes.widget}>
                    <GeneralInfoWidgetComponent />
                </Box>
                <Box className={classes.widget}>
                    <AddressInfoWidgetComponent />
                </Box>
            </>
        )
    }

    return (
        <AuthenticatedLayoutComponent
            title={TitlesService.myAccountTitle}
            pageName={PagesNamesService.myAccount}
            selectedMenuItem={MenuItemTypes.Profile}
            hideBackground
        >
            <Box p={smallScreen ? 2 : "30px"} className={!smallScreen && commonClasses.bgWhiteMain} minHeight="calc(100vh - 300px)">
                <Box className={classes.title}><span className={clsx(commonClasses.size20, commonClasses.textSemiBold, commonClasses.colorLinkBlack)}> Personal info </span></Box>
                <Grid container justify="center" spacing={3}>
                    <Grid item xs={12} sm={12} md={10} lg={8}>
                        {renderGeneralInfo()}
                        
                        <Box p={smallScreen ? 2 : "30px"} className={classes.widget}>
                            <PharmacyInfoWidgetComponent />
                        </Box>
                        <FeatureComponent featureFlag={FeatureFlag.TranscriptionConsent}>
                            <Box p={smallScreen ? 2 : "30px"} className={classes.widget}>
                                <SessionRecordingSettingWidgetComponent />
                            </Box>
                        </FeatureComponent>
                    </Grid>
                </Grid>
            </Box>
        </AuthenticatedLayoutComponent>
    )
}