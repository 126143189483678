import Axios from 'axios-observable';
import { authHeader } from '../../common/helpers/auth-header';
import { snackService } from '../../common/snack/state';
import {
    CreateAllDayAppointmentModel,
    CreateAppointmentModel,
    EditAppointmentModel,
    EmployeeAppointmentModel,
    PatientAppointmentModel,
    SendAppointmentInviteModel,
    AppointmentSequenceNumbersModel, AppointmentTypeModel, AppointmentsSequenceInfoModel
} from '../models/appointments.models';
import { AppointmentStatus, AppointmentSignOffType } from '../models/appointments.enums';
import { AppointmentsStore, appointmentsStore } from '../stores/appointments';
import { Observable, Subject } from 'rxjs';
import moment from "moment";
import { CallbackModel } from "../../common/models/callback.model";
import {AppointmentsStatisticModel} from "../models/appointmentsStatistic.models";
import pdfServices from "../../conversations/helpers/pdfExporter/PdfServices";
import { source } from '../constants/appointment.constants';

export class AppointmentsService {
    private url = `${process.env.REACT_APP_API_URL}Appointments`;

    constructor(private appointmentsStore: AppointmentsStore) {
    }

    public appointmentScheduleOnOpen = new Subject<CallbackModel<[Date, Date | null]>>();
    public appointmentRescheduleOnOpen = new Subject<CallbackModel<[EmployeeAppointmentModel]>>();
    public patientAppointmentRescheduleOnOpen = new Subject<CallbackModel<[PatientAppointmentModel, number | null]>>();
    public cancelPatientAppointmentScheduleOnOpen = new Subject<CallbackModel<null>>();

    public openAppointmentSchedule(defaultDate: Date, defaultTime: Date | null): Observable<CreateAppointmentModel> {
        return new Observable(observer => {
            this.appointmentScheduleOnOpen.next({
                data: [
                    defaultDate,
                    defaultTime
                ],
                callback: (result: CreateAppointmentModel) => {
                    if (result) {
                        observer.next(result);
                    }
                    observer.complete();
                }
            });
        });
    }

    public openAppointmentReschedule(appointment: EmployeeAppointmentModel): Observable<CreateAppointmentModel> {
        return new Observable(observer => {
            this.appointmentRescheduleOnOpen.next({
                data: [
                    appointment
                ],
                callback: (result: CreateAppointmentModel) => {
                    if (result) {
                        observer.next(result);
                    }
                    observer.complete();
                }
            });
        });
    }

    public openPatientAppointmentReschedule(appointment: PatientAppointmentModel, patientId: number | null): Observable<CreateAppointmentModel> {
        return new Observable(observer => {
            this.patientAppointmentRescheduleOnOpen.next({
                data: [
                    appointment,
                    patientId
                ],
                callback: (result: CreateAppointmentModel) => {
                    if (result) {
                        observer.next(result);
                    }
                    observer.complete();
                }
            });
        });
    }

    public openCancelAppointmentSchedule(): Observable<boolean> {
        return new Observable(observer => {
            this.cancelPatientAppointmentScheduleOnOpen.next({
                data: null,
                callback: (result: boolean) => {
                    if (result) {
                        observer.next();
                    }
                    observer.complete();
                }
            });
        });
    }

    public getAppointmentsSummary(patientId: number): void {
        const config = { headers: authHeader() };
        Axios.get(`${this.url}/AppointmentsSummary?patientId=${patientId}`, config).pipe()
            .subscribe(
                response => {
                    this.appointmentsStore.update({ appointmentsSummary: response.data })
                },
                error => snackService.commonErrorHandler(error)
            );
    }

    public getAppointmentsStatistic(employeeId: number, asOfDateTime: Date = null): Observable<AppointmentsStatisticModel> {
        const config = { headers: authHeader() };

        let queryString = `?employeeId=${employeeId}`;

        if (asOfDateTime) {
            queryString += `&asOfDateTime=${moment(asOfDateTime).toISOString()}`
        }

        return new Observable<AppointmentsStatisticModel>(observer => {
            Axios.get<AppointmentsStatisticModel>(`${this.url}/Statistic${queryString}`, config)
                .pipe()
                .subscribe(
                    response => {
                        this.appointmentsStore.update({ appointmentsStatistic: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getAppointmentSequenceNumbers(model: AppointmentSequenceNumbersModel[]): Observable<AppointmentsSequenceInfoModel[]> {
        const config = { headers: authHeader() };
        return new Observable<AppointmentsSequenceInfoModel[]>(observer => {
            Axios.post<AppointmentsSequenceInfoModel[]>(`${this.url}/sequences`, model, config).pipe()
                .subscribe(
                    response => {
                        this.appointmentsStore.update({ appointmentSequenceInfo: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public getEmployeeAppointmentsByIds(ids: number[]): Observable<EmployeeAppointmentModel[]> {
        const config = { headers: authHeader() };

        return new Observable<EmployeeAppointmentModel[]>(observer => {
            Axios.get<EmployeeAppointmentModel[]>(`${this.url}/tags/${ids.join(',')}`, config)
                .pipe()
                .subscribe(
                    response => {
                        this.appointmentsStore.update({ appointmentsIncludeTags: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public getEmployeeAppointmentsById(employeeId: number, startDate: Date = null, endDate: Date = null, status: AppointmentStatus = AppointmentStatus.All): Observable<EmployeeAppointmentModel[]> {
        const config = { headers: authHeader() };

        let queryString = `?status=${status}`;

        if (startDate) {
            queryString += `&startDate=${moment(startDate).toISOString()}`
        }

        if (endDate) {
            queryString += `&endDate=${moment(endDate).toISOString()}`
        }

        return new Observable(observer => {
            Axios.get(`${this.url}/Employee/${employeeId}${queryString}`, config).pipe()
                .subscribe(
                    response => {
                        this.appointmentsStore.setEmployeeAppointments(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });

    }

    public getPatientAppointmentsById(patientId: number, startDate: Date = null, endDate: Date = null, status: AppointmentStatus = AppointmentStatus.All): Observable<PatientAppointmentModel[]> {
        const config = { headers: authHeader() };
        let queryString = `?status=${status}`;

        if (startDate) {
            queryString += `&startDate=${moment(startDate).toISOString()}`
        }

        if (endDate) {
            queryString += `&endDate=${moment(endDate).toISOString()}`
        }

        return new Observable(observer => {
            Axios.get(`${this.url}/Patient/${patientId}${queryString}`, config).pipe()
                .subscribe(
                    response => {
                        this.appointmentsStore.setPatientAppointments(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        });
    }

    public getPatientAppointments(startDate: Date = null, endDate: Date = null, status: AppointmentStatus = AppointmentStatus.All): Observable<PatientAppointmentModel[]> {
        const config = { headers: authHeader() };
        let queryString = `?status=${status}`;

        if (startDate) {
            queryString += `&startDate=${moment(startDate).toISOString()}`
        }

        if (endDate) {
            queryString += `&endDate=${moment(endDate).toISOString()}`
        }

        return new Observable(observer => {
            Axios.get(`${this.url}/Patient${queryString}`, config).pipe()
                .subscribe(
                    response => {
                        this.appointmentsStore.setPatientAppointments(response.data)
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        });
    }

    public getAvailableAppointmentTypes(patientId: number = null) {
        let queryString = ``;

        if (patientId) {
            queryString += `?patientId=${patientId}`
        }
        const config = { headers: authHeader() };

        return Axios.get(`${this.url}/Types${queryString}`, config)
            .pipe()
            .subscribe(
                response => this.appointmentsStore.setPatientAppointmentTypes(response.data),
                error => snackService.commonErrorHandler(error)
            );
    }

    public getAvailableAppointmentTypesAsync(patientId: number = null): Observable<AppointmentTypeModel[]> {
        let queryString = ``;

        if (patientId) {
            queryString += `?patientId=${patientId}`
        }

        const config = { headers: authHeader() };

        return new Observable(observer => {
            Axios.get<AppointmentTypeModel[]>(`${this.url}/Types${queryString}`, config).pipe()
                .subscribe(
                    response => {
                        this.appointmentsStore.setPatientAppointmentTypes(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        });
    }

    public getAllAppointmentTypes(): void {
        const config = { headers: authHeader() };
        Axios.get(`${this.url}/Types/All`, config).pipe()
            .subscribe(
                response => this.appointmentsStore.update({ allAppointmentTypes: response.data }),
                error => snackService.commonErrorHandler(error)
            );
    }

    public createAsEmployee(appointment: CreateAppointmentModel): Observable<any> {
        const config = { headers: authHeader() };
        return new Observable(observer => {
            Axios.post(`${this.url}/Employee`, appointment, config).pipe()
                .subscribe(
                    response => {
                        this.appointmentsStore.insertEmployeeAppointment(response.data);
                        this.appointmentsStore.insertPatientAppointment(response.data)
                        snackService.success("Appointment successfully created!");
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public createOther(appointment: CreateAppointmentModel): Observable<any> {
        const config = { headers: authHeader() };
        return new Observable(observer => {
            Axios.post(`${this.url}/Other`, appointment, config).pipe()
                .subscribe(
                    response => {
                        this.appointmentsStore.insertEmployeeAppointment(response.data)
                        snackService.success("Appointment successfully created!");
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public createAllDay(appointment: CreateAllDayAppointmentModel): Observable<any> {
        const config = { headers: authHeader() };
        return new Observable(observer => {
            Axios.post(`${this.url}/AllDay`, appointment, config).pipe()
                .subscribe(
                    response => {
                        response.data.forEach(appointment => {
                            this.appointmentsStore.insertEmployeeAppointment(appointment)
                        });
                        snackService.success("Appointment successfully created!");
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public createAsPatient(appointment: CreateAppointmentModel): Observable<any> {
        const config = { headers: authHeader() };
        return new Observable(observer => {
            Axios.post(`${this.url}/Patient`, appointment, config).pipe()
                .subscribe(
                    response => {
                        this.appointmentsStore.insertPatientAppointment(response.data)
                        snackService.success("Appointment successfully created!");
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public rescheduleAsEmployee(appointment: CreateAppointmentModel, id: number): Observable<any> {
        const config = { headers: authHeader() };
        return new Observable(observer => {
            Axios.put(`${this.url}/Employee/Reschedule/${id}`, appointment, config).pipe()
                .subscribe(
                    response => {
                        this.appointmentsStore.rescheduleAppointment(id, response.data);
                        this.appointmentsStore.reschedulePatientAppointment(id, response.data);

                        snackService.success("Appointment successfully created!");
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public rescheduleAsPatient(appointment: CreateAppointmentModel, id: number): Observable<any> {
        const config = { headers: authHeader() };
        return new Observable(observer => {
            Axios.put(`${this.url}/Patient/Reschedule/${id}`, appointment, config).pipe()
                .subscribe(
                    response => {
                        this.appointmentsStore.reschedulePatientAppointment(id, response.data)
                        snackService.success("Appointment successfully created!");
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public editAppointment(appointment: EditAppointmentModel): Observable<any> {
        const config = { headers: authHeader() };
        return new Observable(observer => {
            Axios.put(`${this.url}/Employee/Edit`, appointment, config).pipe()
                .subscribe(
                    response => {
                        this.appointmentsStore.editAppointment(response.data)
                        snackService.success("Appointment successfully edited!");
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public cancelAsEmployee(appointmentId: number) {
        const config = { headers: authHeader() };

        Axios.put(`${this.url}/Cancel/${appointmentId}?source=${source}`, {}, config).pipe()
            .subscribe(
                response => {
                    this.appointmentsStore.cancelEmployeeAppointment(response.data);
                    snackService.success("Appointment successfully canceled!");
                },
                error => snackService.commonErrorHandler(error)
            );
    }

    public cancelAsEmployeeDashboard(appointmentId: number) {
        const config = { headers: authHeader() };

        Axios.put(`${this.url}/Cancel/${appointmentId}?source=${source}`, {}, config).pipe()
            .subscribe(
                response => {
                    this.appointmentsStore.cancelEmployeeAppointmentForDashboard(response.data);
                    snackService.success("Appointment successfully canceled!");
                },
                error => snackService.commonErrorHandler(error)
            );
    }

    public submitAsPatient(appointmentId: number) {
        const config = { headers: authHeader() };

        Axios.put(`${this.url}/Submit/${appointmentId}`, {}, config).pipe()
            .subscribe(
                response => {
                    this.appointmentsStore.editPatientAppointment(response.data);
                    snackService.success("Appointment successfully accepted!");
                },
                error => snackService.commonErrorHandler(error)
            );
    }

    public cancelAsPatient(appointmentId: number) {
        const config = { headers: authHeader() };

        Axios.put(`${this.url}/Cancel/${appointmentId}?source=${source}`, {}, config).pipe()
            .subscribe(
                response => {
                    this.appointmentsStore.cancelPatientAppointment(response.data);
                    snackService.success("Appointment successfully canceled!");
                },
                error => snackService.commonErrorHandler(error)
            );
    }

    public sendAppointmentInvite(model: SendAppointmentInviteModel): Observable<any> {
        const config = { headers: authHeader() };

        return new Observable(observer => {
            Axios.post(`${this.url}/SendInvite`, model, config).pipe()
                .subscribe(
                    response => {
                        snackService.success('Invitation sent successfully');
                        observer.next(response);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        });
    }

    public setNoShowAppointment(appointmentId: number) {
        const config = { headers: authHeader() };

        Axios.put(`${this.url}/NoShow/${appointmentId}`, {}, config).pipe()
            .subscribe(
                response => {
                    this.appointmentsStore.editAppointment(response.data);
                    snackService.success("Appointment successfully updated!");
                },
                error => snackService.commonErrorHandler(error)
            );
    }

    downloadPastAppointments(appointments:PatientAppointmentModel[], patientName: string, patientId: number) {

        const pdf = pdfServices.downloadAppoitnmentsPdfFor(appointments, patientName, patientId)
        const today = moment().format("YYYY-MM-DDTHH-mm-ss")
        pdf.save(`WH_Appointments_log_${today}`)
    }

    public signOffAppointment(appointmentId: number, appointmentSignOffType: AppointmentSignOffType): Observable<EmployeeAppointmentModel> {
        const config = { headers: authHeader() };

        return new Observable<EmployeeAppointmentModel>(observer => {
            Axios.post<EmployeeAppointmentModel>(`${this.url}/${appointmentId}/SignOff/${appointmentSignOffType}`, {}, config).pipe()
                .subscribe(
                    response => {
                        this.appointmentsStore.signOffAppointment(appointmentId);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }
}

export const appointmentsService = new AppointmentsService(appointmentsStore);
