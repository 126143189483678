import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderColor: colors.gray4,
            borderRadius: 2,
            '& .MuiOutlinedInput-root': {
                borderColor: colors.gray4,
                borderRadius: 2,
            },
        },
        selectCard: {
            width: '100%',
            border: `1px solid ${colors.stroke}`,
            boxShadow: '0px 4px 9px 0px rgb(168, 173, 198, 0.15)',
            height: 65,
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 500
        },
        selectCardActive: {
            backgroundColor: colors.lightGray,
            border: `1px solid ${colors.accent1}`
        },
        undoDeleteBox: {
            backgroundColor: '#373d3f',
            color: colors.white,
            height: 48
        },
        undoDelete: {
            textDecoration: 'underline',
            cursor: 'pointer'
        }
    }),
);