import { Box, Divider, TextField } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useStyles } from './recommendationSelectUpdateComponent.styles';
import { RecommendationDisplayModel } from '../../models/healthReport.models';
import { RecommendationPlaceHolder } from '../../../common/components/wildHealthPlaceHolder/RecommendationPlaceHolder';
import { WildHealthRadio } from '../../../common/components/WildHealthRadio';
import { commonUseStyles } from '../../../common/styles/common.styles';
import React from 'react';
import { authQuery } from '../../../auth/stores/auth';
import { UserType } from '../../../auth/models/auth.enums';
import { selectShortcutService } from '../../services/selectShortcut.service';
import { insertString } from '../../../common/helpers/insert-string';
import EditIcon from '@material-ui/icons/Edit';
import { colors } from '../../../common/constants/colors';

export interface RecommendationSelectWithPreviousComponentProps {
    currentRecommendation: RecommendationDisplayModel[],
    newRecommendation: RecommendationDisplayModel[],
    type: number,
    handleSelectedNew: (id: number | string, isSelected: boolean) => void,
    handleSelectedPrev: (id: number | string, isSelected: boolean) => void,
    handleChangesNew: (id: number | string, value: string) => void,
    handleAddRecommendation: (id: number, index: number) => void,
    handleUpdateRecommendation: (recommendation: RecommendationDisplayModel) => void,
}

export const RecommendationSelectUpdateComponent: React.FC<RecommendationSelectWithPreviousComponentProps> = (props: RecommendationSelectWithPreviousComponentProps) => {
    const {
        currentRecommendation,
        newRecommendation,
        type,
        handleSelectedNew,
        handleSelectedPrev,
        handleChangesNew,
        handleAddRecommendation,
        handleUpdateRecommendation,
    } = props;

    const classes = useStyles();
    const commonClasses = commonUseStyles();

    const handleOnKeyDownNew = (id: string | number, event: any, idFild: string) => {
        if (event.keyCode === 51 && event.altKey) {
            const userType = authQuery.getType();
            if (UserType.Employee === userType) {
                const value = (document.getElementById(idFild) as any).value;
                const cursorPos = (document.getElementById(idFild) as any).selectionStart;
                setTimeout(() => {
                    selectShortcutService.select().subscribe((data) => {
                        data && handleChangesNew(id, insertString(value, data, cursorPos + 1))
                    })
                }, 1)
            }
        }
    }

    const renderPlaceHolder = (title: string) => {
        return <Box display="flex" maxHeight="180px" alignItems="center" justifyContent="center">
            <RecommendationPlaceHolder message={title} />
        </Box>
    }

    const renderAddRecommendation = () => {
        return <Box mt={1} display="flex" width={newRecommendation.length > 0 ? "100%" : "max-content"}>
            <Box onClick={() => handleAddRecommendation(type, newRecommendation.length)
            }>
                <Add className={classes.addIcon} />
            </Box>
            <Box className={classes.addText} ml={2}>
                <span>Add Recommendation</span>
            </Box>
        </Box>
    }

    const renderRecommendationCurrent = (recommendation: RecommendationDisplayModel) => {
        return <Box display="flex" width="100%" key={`recommendation-text-area-prev-${recommendation.type}-${recommendation.id}`}>
            <Box>
                <WildHealthRadio
                    checked={recommendation.isSelected}
                    onClick={() => handleSelectedPrev(recommendation.id, !recommendation.isSelected)}
                />
            </Box>
            <Box className={classes.recommendation}>
                <button onClick={() => handleUpdateRecommendation(recommendation)} className={classes.recommendationUpdate}>
                    <EditIcon style={{ color: colors.white, fontSize: 16 }} />
                    <Box ml={1}>Update</Box>
                </button>
                <Box pl={2.5} pt={1.5}>{recommendation.content}</Box>
            </Box>
        </Box>
    }

    const renderRecommendationNew = (recommendation: RecommendationDisplayModel) => {
        return <Box display="flex" my={1} width="100%" key={`recommendation-text-area-new-${recommendation.type}-${recommendation.id}`}>
            <Box>
                <WildHealthRadio
                    checked={recommendation.isSelected}
                    onClick={() => handleSelectedNew(recommendation.id, !recommendation.isSelected)}
                />
            </Box>
            <TextField
                id={`recommendation-text-area-new-${recommendation.type}-${recommendation.id}`}
                fullWidth
                multiline
                disabled={!recommendation.isSelected}
                className={recommendation.isSelected ? "" : classes.textFiel}
                variant="outlined"
                value={recommendation.content}
                onKeyDown={(e) => { handleOnKeyDownNew(recommendation.id, e, `recommendation-text-area-new-${recommendation.type}-${recommendation.id}`) }}
                onChange={(v) => {
                    handleChangesNew(recommendation.id, v.target.value)
                }}
            />
        </Box>
    }

    const getRecommendationForCompere = (id: number | string, content: string) => {
        const theSameRecommendation = newRecommendation.find(el => el.baseId === id)
        return theSameRecommendation ? <Box width="50%" display="flex">  {renderRecommendationNew(theSameRecommendation)}</Box> : <Box className={classes.noRecommendationNew}> no new recommendation</Box>
    }

    const renderCurrentRecommendationsBloc = (el: RecommendationDisplayModel) => {
        return <Box display="flex" mb={1.5} key={`${el.type}-${el.id}`}>
            <Box display="flex" width="50%">
                {renderRecommendationCurrent(el)}
            </Box>
            {getRecommendationForCompere(el.baseId, el.content)}
            <Divider className={commonClasses.renderDivider} />
        </Box>
    }

    const renderNewRecommendationsBloc = (el: RecommendationDisplayModel) => {
        return <Box display="flex" mb={1.5} key={`${el.type}-prev-${el.id}`}>
            <Box className={classes.noRecommendation}> no previous recommendation</Box>
            <Box display="flex" width="100%">
                {renderRecommendationNew(el)}
            </Box>
            <Divider className={commonClasses.renderDivider} />
        </Box>
    }

    return (
        <Box display="flex" flexDirection="column">
            <Box width="100%" display="flex" pl={1.5} mb={1.5} >
                <Box display="flex" width="50%" color="secondary" className={classes.textSecondary}>previous recommendations </Box>
                <Box display="flex" width="50%" className={classes.textSecondary}>new recommendations </Box>
            </Box>

            {
                currentRecommendation && newRecommendation && currentRecommendation.length && newRecommendation.length
                    ? <>
                        <Box width="100%" display="flex" flexDirection="column">{currentRecommendation.map(recommendation => renderCurrentRecommendationsBloc(recommendation))}</Box>
                        <Box width="100%" display="flex" flexDirection="column">{newRecommendation.filter(recommendation => !currentRecommendation.find(el => el.baseId == recommendation.baseId))
                            .map(recommendation => renderNewRecommendationsBloc(recommendation))}
                            <Box width="100%" display="flex">
                                <Box width="100%" display="flex"></Box>{renderAddRecommendation()}
                            </Box>
                        </Box>
                    </>
                    : <Box width="100%" display="flex">
                        <Box display="flex" width="50%" flexDirection="column" alignItems="center" justifyContent="center">
                            {
                                currentRecommendation && currentRecommendation.length
                                    ? currentRecommendation.map(recommendation => renderRecommendationCurrent(recommendation))
                                    : renderPlaceHolder("No Previous Recommendations")
                            }
                        </Box>
                        <Box display="flex" width="50%" flexDirection="column" alignItems="center" justifyContent="center">
                            {
                                newRecommendation && newRecommendation.length
                                    ? newRecommendation.map(recommendation => renderRecommendationNew(recommendation))
                                    : renderPlaceHolder("No New Recommendations")
                            }
                            {renderAddRecommendation()}
                        </Box>
                    </Box>
            }
        </Box>
    );
}