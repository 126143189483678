import { Box, Card, Link, Table, TableBody, TableContainer, TableHead } from "@material-ui/core";
import moment from 'moment';
import React from 'react';
import { useStyles } from "./insuranceCardHistory.styles";
import { toCurrentTimeZone } from "../../../timezones/helpers/timezone";
import { WildHealthTableRow } from '../../../common/components/wildHealthTable/WildHealthTableRow';
import { WildHealthTableCell } from '../../../common/components/wildHealthTable/WildHealthTableCell';
import { VerificationsHistoryModel } from "../../models/insurance.models";
import { InsuranceVerificationModalComponent } from "../insuranceVerificationModalComponent/InsuranceVerificationModalComponent";
import { useFacade } from "./insuranceCardHistoryComponent.hooks";

interface InsuranceCardHistoryComponentProps {
    insurances: VerificationsHistoryModel[];
    displayNameHistory: Function;
    displayNumberCoverageHistory: Function;
}

export const InsuranceCardHistoryComponent: React.FC<InsuranceCardHistoryComponentProps> = (props: InsuranceCardHistoryComponentProps) => {
    const [
        state,
        handleViewDetails
    ] = useFacade();

    const classes = useStyles();

    return (
        <Card className={classes.subscriptionCard} variant="outlined">
            <TableContainer>
                <Table>
                    <TableHead>
                        <WildHealthTableRow>
                            <WildHealthTableCell style={{ width: '30%' }}>
                                <Box pt={2} px={2}>Member ID</Box>
                            </WildHealthTableCell>
                            <WildHealthTableCell style={{ width: '30%' }}>
                                <Box>Carrier</Box>
                            </WildHealthTableCell>
                            <WildHealthTableCell style={{ width: '30%' }}>
                                <Box>Status</Box>
                            </WildHealthTableCell>
                            <WildHealthTableCell style={{ width: '10%' }}>
                                <Box>Details</Box>
                            </WildHealthTableCell>
                        </WildHealthTableRow>
                    </TableHead>
                    <TableBody>
                        {
                            props.insurances.map((verification, index) =>
                                <WildHealthTableRow key={index}>
                                    <WildHealthTableCell>
                                        <Box pl={2} pt={0.5}>
                                            {props.displayNumberCoverageHistory(+verification.coverageId)}
                                        </Box>
                                    </WildHealthTableCell>
                                    <WildHealthTableCell>
                                        <Box>
                                            {props.displayNameHistory(+verification.coverageId)}
                                        </Box>
                                    </WildHealthTableCell>
                                    <WildHealthTableCell>
                                        <Box>{verification.isVerified ? 'Verified' : 'Failed verification'} on {moment(toCurrentTimeZone(verification.runAt)).format('MM/DD/YYYY')}</Box>
                                    </WildHealthTableCell>
                                    <WildHealthTableCell>
                                        <Box>
                                            <Link onClick={() => handleViewDetails(verification.id)}>View</Link>
                                        </Box>
                                    </WildHealthTableCell>
                                </WildHealthTableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <InsuranceVerificationModalComponent />
        </Card>
    );
}