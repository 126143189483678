import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { onEmit } from "../../../../common/helpers/on-emit";
import { IErrorState } from "../../../../common/validation/error-state";
import { EmployerProductModel } from "../../../models/employerProduct.model";
import { employerProductsService } from "../../../services/employerProducts.service";
import { employerProductsQuery } from "../../../stores/employerProductsStore";

interface EmployerDetailDialogComponentState extends IErrorState {
    employer: EmployerProductModel;
    isLoading: boolean;
}

const initEmployerModel: EmployerProductModel = {
    id: 0,
    universalId: '',
    name: '',
    key: '',
    isDefault: false,
    description: '',
    bannerCdnUrl: '',
    supportedPaymentPricesDescription: [],
    inclusionsDescription: [],
    discountsDescription: [],
};

export function useFacade(
    employerId: number,
    isOpen: boolean
): [
    EmployerDetailDialogComponentState
] {

    const [state, setState] = useState({
        employer: initEmployerModel,
        isLoading: true
    } as EmployerDetailDialogComponentState);

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<EmployerProductModel>(employerProductsQuery.selectedEmployer$, selectedEmployer => {
                if (selectedEmployer && selectedEmployer.id === employerId) {
                    setState(state => ({
                        ...state,
                        employer: selectedEmployer,
                    }));
                }
            }),
        ];

        if (isOpen) {
            setState((state) => ({
                ...state,
                isLoading: true,
                employer: Object.assign({}, initEmployerModel),
            }));

            const cb = () => setState(state => ({ ...state, isLoading: false }));

            employerProductsService.getEmployerProductById(employerId).subscribe(cb, cb);
        }

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    };

    useEffect(useEffectCB, [isOpen]);

    return [ state ];
}