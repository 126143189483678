import { useStyles } from './BlockItemApoeComponent.styles'
import { useFacade } from './blockItemApoeComponent.hooks'
import { Box, Button, FormControl, FormControlLabel, Grid, MenuItem, RadioGroup, Select } from '@material-ui/core'
import React from 'react'
import { WildHealthCheckbox } from '../../../common/components/WildHealthCheckbox';
import { WildHealthRadio } from '../../../common/components/WildHealthRadio';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { FeatureFlag } from '../../../common/components/featureFlags/featureFlags.models';
import { FeatureComponent } from '../../../common/components/featureFlags/FeatureComponent';
import { UserType } from '../../../auth/models/auth.enums';
import { ProtectedElement } from '../../../common/components/ProtectedElement';
import EditIcon from '@material-ui/icons/Edit'
import { colors } from '../../../common/constants/colors';
import { YesNo } from '../../../inputs/models/input.models';
import { apoeNames, ApoeSoursConstants, apoeSoursNames } from '../../../healthReport/constants/apoe.constants';
import { authQuery } from '../../../auth/stores/auth';
import { ReactComponent as NoInformationIcon } from '@img/icons/HealthSectionEmptyState.svg';
import { navigationService } from '../../../../services/navigation.service';
import { WildHealthLinkButton } from '../../../common/components/WildHealthLinkButton';
import { useHistory } from 'react-router';
import { WildHealthDatePicker } from '../../../common/components/WildHealthDatePicker';
import moment from 'moment';

interface BlockItemApoeComponentProps {
    patientId?: number
}

export const BlockItemApoeComponent: React.FC<BlockItemApoeComponentProps> = (props: BlockItemApoeComponentProps) => {
    const {
        patientId
    } = props

    const [
        state,
        handleEditValue,
        handleSelectedSours,
        handleHideApoe,
        handleEditMode,
        cancelChanges,
        saveChanges,
        handleDateColonoscopyChanges,
        handleDatePsaChanges
    ] = useFacade(patientId)

    const classes = useStyles()
    const history = useHistory()
    const isEmployView = authQuery.isEmployeeUser()

    const getApoeScoreValue = () => {
        return state.hideApoe !== YesNo.Yes && state.selectedSours === ApoeSoursConstants.BloodTest && state.selectedApoe ? `(99%)` : ''
    }

    const linkQuestionnaireButton = () => {
        const clickAction = authQuery.getType() === UserType.Employee
            ? () => navigationService.toPatientHealthForms(history, patientId)
            : () => navigationService.toMyHealthForms(history)


        return <WildHealthLinkButton
            linkButton={true}
            id={'questionnaire-button'}
            content={'questionnaire'}
            onClick={clickAction}
        />
    }

    const emptyContent = () => {
        return <Box className={classes.empty}>
            <Grid
                container
                direction="column"
                alignItems="center"
                className={classes.root}
            >
                <Box display='flex' flexDirection='column' alignContent='center' justifyContent='center'>
                    <NoInformationIcon />
                    <Box className={classes.message} textAlign="center">No information provided.</Box>
                    <Box>Complete {linkQuestionnaireButton()} to have your info displayed here.</Box>
                </Box>
            </Grid>
        </Box>
    }

    const getHeader = () => {
        return isEmployView
            ? <Box display='flex' justifyContent='space-between' width='100%' alignItems='center'>
                <Box display='flex'>
                    <span style={{ fontWeight: "bold" }}>Your APOE Status</span>: Apo-{state.selectedApoe}  {getApoeScoreValue()}<Box color={colors.bad} ml={1}>*</Box>
                </Box>
                <Box style={{ fontWeight: "bold", fontSize: 18 }}>{state.hideApoe === YesNo.Yes && 'Patient Opted Out'}</Box>
            </Box>
            : <Box display='flex' justifyContent='space-between' width='100%' alignItems='center'>
                {state.hideApoe !== YesNo.Yes && <Box display='flex'>
                    <span style={{ fontWeight: "bold" }}>Your APOE Status</span>: Apo-{state.selectedApoe}  {getApoeScoreValue()}<Box color={colors.bad} ml={1}>*</Box>
                </Box>}
            </Box>
    }

    const getRecommendationText = () => {
        if (!state.selectedApoe) {
            return 'We were unable to obtain an APOE that had a higher than 50% accuracy score.'
        }
        if (state.hideApoe === YesNo.Yes) {
            return 'The patient requested to not be given their APOE result.'
        }


        return state.selectedSours === ApoeSoursConstants.BloodTest
            ? 'This result is based in a blood test confirmation.'
            : 'The APOE result was obtained through outside source and was not a blood test.  The confidence in accuracy is not known.'
    }


    const renderViewElement = () =>
        <Box className={classes.rootView} flexDirection='column'>
            <Box display='flex' alignItems='center' width='100%'>
                {getHeader()}
            </Box>
            <Box mt={2} display='flex' flexWrap='wrap' width='100%'>
                {state.colonoscopy.value && <Box pr={2} minWidth='200px' width='50%'>
                    <Box style={{ fontWeight: "bold" }}>Colonoscopy in past:</Box>
                    <Box mt={1} className={classes.valueView}>{`${moment(state.colonoscopy.value).format('MM/DD/YYYY')}`}</Box>
                </Box>}
                {state.psa.value && <Box pr={2} minWidth='200px' width='50%'>
                    <Box style={{ fontWeight: "bold" }}>PSA in the past:</Box>
                    <Box mt={1} className={classes.valueView}>{`${moment(state.psa.value).format('MM/DD/YYYY')}`}</Box>
                </Box>}
            </Box>
        </Box>


    const renderEditElement = () => {
        return <Box>
            <Box display='flex' flexWrap='wrap' width='100%' >
                <Box mr={2} minWidth='200px' width='50%'>
                    <FormControl color='primary' variant="outlined" size="small" fullWidth>
                        <Select
                            style={{ background: '#FFF' }}
                            id="apoe-name"
                            value={state.selectedApoe}
                            onChange={(v) => handleEditValue(v.target.value)}>
                            {
                                state.apoes.map(apoe => {
                                    return <MenuItem key={apoe} value={apoe}>{apoeNames[apoe]}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                    <Box pl={0.5} pt={1}>
                        <WildHealthCheckbox
                            id={`Patient-Opted-Out`}
                            color="secondary"
                            fill={colors.main}
                            label={<Box display="flex">
                                <span>Patient Opted Out</span>
                            </Box>}
                            checkStatus={state.hideApoe === YesNo.Yes}
                            handleCheck={() => handleHideApoe(state.hideApoe === YesNo.Yes ? YesNo.No : YesNo.Yes)}
                            defaultIcon
                        />
                    </Box>
                </Box>
                <Box minWidth='200px' >
                    <RadioGroup value={state.selectedSours} onChange={(event) => handleSelectedSours(String(event.target.value))}>
                        <Box display='flex'>
                            <FormControlLabel disabled={state.hideApoe === YesNo.Yes} key={ApoeSoursConstants.BloodTest} value={ApoeSoursConstants.BloodTest} control={<WildHealthRadio />} label={apoeSoursNames[ApoeSoursConstants.BloodTest]} />
                            <FormControlLabel disabled={state.hideApoe === YesNo.Yes} key={ApoeSoursConstants.Other} value={ApoeSoursConstants.Other} control={<WildHealthRadio />} label={apoeSoursNames[ApoeSoursConstants.Other]} />
                        </Box>
                    </RadioGroup>
                </Box>
            </Box>
            <Box mt={2} display='flex' flexWrap='wrap' width='100%'>
                <Box pr={2} minWidth='200px' width='50%'>
                    <Box style={{ fontWeight: "bold" }}>Colonoscopy in past:</Box>
                    <Box mt={1}>
                        <WildHealthDatePicker
                            placeholder='MMM/DD/yyyy'
                            openTo='year'
                            size='small'
                            format='MM/DD/yyyy'
                            inputProps={{ disabled: true }}
                            inputVariant='outlined'
                            error={!!state.errors["colonoscopy"]}
                            helperText={state.errors["colonoscopy"]}
                            value={state.colonoscopy?.value ?? ''}
                            onChange={(momentDate) => handleDateColonoscopyChanges(momentDate?.toDate())}
                            useKeyboardDatePicker
                            keyboardIcon={
                                <Box>
                                    <EditIcon fontSize='small' />
                                </Box>
                            }
                            name='colonoscopy'
                        />
                    </Box>
                </Box>
                <Box pr={2} minWidth='200px' width='50%'>
                    <Box style={{ fontWeight: "bold" }}>PSA in the past:</Box>
                    <Box mt={1}>
                        <WildHealthDatePicker
                            placeholder='MMM/DD/yyyy'
                            openTo='year'
                            size='small'
                            format='MM/DD/yyyy'
                            inputProps={{ disabled: true }}
                            inputVariant='outlined'
                            error={!!state.errors["psa"]}
                            helperText={state.errors["psa"]}
                            value={state.psa?.value ?? ''}
                            onChange={(momentDate) => handleDatePsaChanges(momentDate?.toDate())}
                            useKeyboardDatePicker
                            keyboardIcon={
                                <Box>
                                    <EditIcon fontSize='small' />
                                </Box>
                            }
                            name='psa'
                        />
                    </Box>
                </Box>

            </Box>
        </Box>
    }


    const renderContent = () => {
        if (state.isLoading) {
            return <WildHealthLinearProgress />
        }

        if ((!isEmployView && !state.selectedApoe && !state.colonoscopy?.value && !state.psa?.value) ||
            (!isEmployView && state.hideApoe === YesNo.Yes && !state.colonoscopy?.value && !state.psa?.value)) {
            return emptyContent()
        }

        return <Box display='flex' flexDirection='column'>
            <Box className={classes.container}>
                {state.editMode ? renderEditElement() : renderViewElement()}
            </Box>
            {(!isEmployView && state.hideApoe === YesNo.Yes) || (!isEmployView && !state.selectedApoe)
                ? <></>
                : <Box display='flex' mt={3}>
                    <Box color={colors.bad} mr={1}>*</Box>
                    <Box color={colors.gray1} fontSize={14}>
                        {getRecommendationText()}
                    </Box>
                </Box>}
        </Box>
    }

    return (
        <Box className={classes.containerMain}>
            <Box className={classes.titleContainer}>
                <Box className={classes.title}>
                    Screening/Health Maintenance
                </Box>

                <FeatureComponent featureFlag={FeatureFlag.HealthSummaryEdit}>
                    <ProtectedElement
                        element={
                            state.editMode
                                ?
                                <Box display='flex'>
                                    <Box mr={1}>
                                        <WildHealthButton
                                            id='bnt-11'
                                            onClick={() => {
                                                handleEditMode(false)
                                                cancelChanges()
                                            }}
                                            size='default'
                                            color='tertiary'>
                                            Cancel
                                        </WildHealthButton>
                                    </Box>
                                    <Box>
                                        <WildHealthButton
                                            id='btn-12'
                                            onClick={(e) => { saveChanges() }}
                                            size='default'>
                                            Save
                                        </WildHealthButton>
                                    </Box>
                                </Box>
                                :
                                <Button className={classes.button} onClick={() => { handleEditMode(true) }}>
                                    <EditIcon fontSize='small' />
                                    <Box className={classes.buttonText}>Edit</Box>
                                </Button>
                        }
                        userType={UserType.Employee}
                        permissions={[]}
                    />
                </FeatureComponent>
            </Box>
            <Box pt='20px' pb='30px'>
                {renderContent()}
            </Box>
        </Box>

    )
}