import {Box, Divider, Grid} from "@material-ui/core";
import React from 'react';
import {useFacade} from "./InsurancesListComponent.hooks";
import {CoverageModel} from "../../models/insurance.models";
import {CoverageStatus} from "../../enums/coverageStatus";
import {WildHealthPlaceHolder} from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import {InsuranceListItemComponent} from "../InsuranceListItemComponent/InsuranceListItemComponent";
import {AttachmentModel} from "../../../common/models/attachment.models";

interface InsurancesListComponentProps {
    handleEdit: Function,
    insurances: CoverageModel[];
    attachments: AttachmentModel[];
    patientId: number;
    handleOpen?: Function,
}

export const InsurancesListComponent: React.FC<InsurancesListComponentProps> = (props: InsurancesListComponentProps) => {
    const [
        handleActivationSwitch
    ] = useFacade();

    const activeCoverages = props.insurances.filter(x => x.status === CoverageStatus.Active).sort((a, b) => a.priority > b.priority ? 1 : -1);
    const inactiveCoverages = props.insurances.filter(x => x.status === CoverageStatus.NotActive).sort((a, b) => a.priority > b.priority ? 1 : -1);

    return (
        <>
            <Box>
                <span className="wh-tw-text-base wh-tw-font-medium">
                    Active
                </span>
            </Box>

            <Box py={2}>
                <Divider/>
            </Box>

            <Box>
                {
                    activeCoverages && activeCoverages.length > 0
                        ? <Grid container spacing={3}>
                            {
                                activeCoverages.map((coverage, index) => {
                                    return (
                                        <Grid item md={4} sm={12} key={index}>
                                            <InsuranceListItemComponent
                                                coverage={coverage}
                                                handleEdit={props.handleEdit}
                                                handleOpen={() => props.handleOpen && props.handleOpen(coverage)}
                                                handleActivationSwitch={handleActivationSwitch}
                                                attachments={props.attachments?.filter(x => x.description === coverage?.id?.toString())}
                                            />
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                        : <WildHealthPlaceHolder message="No Active Insurance"/>
                }
            </Box>

            <Box mt={5}>
                <span className="wh-tw-text-base wh-tw-font-medium">
                    Inactive
                </span>
            </Box>

            <Box py={2}>
                <Divider/>
            </Box>

            <Box>
                {
                    inactiveCoverages && inactiveCoverages.length > 0
                        ? <Grid container spacing={3}>
                            {
                                inactiveCoverages.map((coverage, index) => {
                                    return (
                                        <Grid item md={4} sm={12} key={index}>
                                            <InsuranceListItemComponent
                                                coverage={coverage}
                                                handleEdit={() => {}}
                                                handleOpen={() => props.handleOpen && props.handleOpen(coverage)}
                                                handleActivationSwitch={handleActivationSwitch}
                                                attachments={props.attachments?.filter(x => x.description === coverage?.id?.toString())}
                                            />
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                        : <WildHealthPlaceHolder message="No Inactive Insurance"/>
                }
            </Box>
        </>
    );
}