import {GeneralValidator} from "../../../common/validation/general-validator";
import {
    rangeValidationRule,
    stringLengthValidationRule,
    checkManyQuestionValidationRule
} from "../../../common/validation/general-validation-rules";

export const medicalHistoryQuestionnaireValidator = new GeneralValidator({
    'MEDICAL_HISTORY_CONDITIONS': [checkManyQuestionValidationRule],
    'MEDICAL_HISTORY_CONDITIONS_FAMILY': [checkManyQuestionValidationRule],
    'MEDICAL_HISTORY_DIABET': [stringLengthValidationRule(1, 500, 'This field is required')],
    'MEDICAL_HISTORY_DIABET_TYPE': [stringLengthValidationRule(1, 500, 'This field is required')],
    'MEDICAL_HISTORY_CHOLESTEROL': [stringLengthValidationRule(1, 500, 'This field is required')],
    'MEDICAL_HISTORY_BLOOD_PRESSURE': [stringLengthValidationRule(1, 500, 'This field is required')],
    'CORONARY_CALCIUM_SCORE': [stringLengthValidationRule(1, 500, 'This field is required')],
    'SCORE': [rangeValidationRule(0, 100)],
    'SMOKING_HISTORY': [stringLengthValidationRule(1, 500, 'This field is required')],
    'ALCOHOL_HISTORY': [stringLengthValidationRule(1, 500, 'This field is required')],
    'ANY_MEDICATIONS': [stringLengthValidationRule(1, 500, 'This field is required')],
    'MEDICATIONS': [],
    'SUPPLEMENT': [],
    'ANY_SUPPLEMENTS': [stringLengthValidationRule(1, 500, 'This field is required')],
    'SUPPLEMENTS': [],
    'DRUG_ALLERGIES': [stringLengthValidationRule(1, 500, 'This field is required')],
    'ALLERGIES': [],
    'SPECIFIC_KIND_OF_DIET': [stringLengthValidationRule(1, 500, 'This field is required')],
    'RESTRICTED_FOOD': [checkManyQuestionValidationRule],
    'OILY_FISH': [rangeValidationRule(0, 1000)],
    'RED_MEAT': [rangeValidationRule(0, 1000)],
    'PROCESSED_MEAT': [rangeValidationRule(0, 1000)],
    'SERVINGS_VEGETABLES': [rangeValidationRule(0, 1000)],
    'PRACTICE_FEEDING': [stringLengthValidationRule(1, 500, 'This field is required')],
    'PRIMARY_EXERCISE_GOAL': [stringLengthValidationRule(1, 500, 'This field is required')],
    'AVERAGE_LIGHT_EXERCISE_TIME': [rangeValidationRule(0, 172)],
    'AVERAGE_MODERATE_EXERCISE_TIME': [rangeValidationRule(0, 172)],
    'AVERAGE_INTENSE_EXERCISE_TIME': [rangeValidationRule(0, 172)],
    'PUSH_UPS_PERFORMING': [rangeValidationRule(0, 1000)],
    'RUN_RUN': [rangeValidationRule(0, 1000)],
    'REP_BENCH_PRESS': [rangeValidationRule(0, 1000)],
    'DEADLIFT': [rangeValidationRule(0, 1000)],
    'REP_SQUAT': [rangeValidationRule(0, 1000)],
    'AVERAGE_HOURS_OF_SLEEP': [rangeValidationRule(0, 24)],
    'SLEEP_ROUTINE': [stringLengthValidationRule(1, 500, 'This field is required')],
    'FALL_ASLEEP_NIGHT': [stringLengthValidationRule(1, 500, 'This field is required')],
    'WAKE_UP': [stringLengthValidationRule(1, 500, 'This field is required')],
    'RESTED_READY_WAKEUP': [stringLengthValidationRule(1, 500, 'This field is required')],
    'ENERGY_THROUGHOUT_THE_DAY': [stringLengthValidationRule(1, 500, 'This field is required')],
    'TYPICAL_STRESS': [stringLengthValidationRule(1, 500, 'This field is required')],
    'STRESS_OR_PRACITCE_MINDFULNESS': [checkManyQuestionValidationRule],
    'PERFORM_YOUR_ROUTINES': [stringLengthValidationRule(1, 500, 'This field is required')],
    'TV_HOURS': [rangeValidationRule(0, 24)],
    'NON_TV_SCREEN': [rangeValidationRule(0, 24)],
});