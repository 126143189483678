import { useState } from 'react';
import { DropEvent, FileRejection } from 'react-dropzone';
import { snackService } from '../../../common/snack/state';
import { DocumentCategory, documentCategorySections, DocumentsToSendModel } from '../../../documents/models/documents.models';
import { documentsService } from '../../services/documents.service';
import { patientDocumentsService } from '../../services/patientDocuments.service';

export interface UploadInputsDialogState {
    isOpen: boolean;
    editIndex: number,
    fileToCangeType: string,
    isLoading: boolean;
    patientId: number;
    documentType: DocumentCategory;
    documentTypes: DocumentCategory[];
    handleUpload: Function;
    toSendAttachments: DocumentsToSendModel[];
    viewableFileIndexs: number[];
    isSendToKB: boolean;
}

export function useFacade(): [
    UploadInputsDialogState,
    Function,
    Function,
    Function,
    (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => void,
    (index: number) => void,
    (index: number) => void,
    (index: number) => void,
    (index: number, value: string) => void,
    (index: number) => void,
    (status: boolean) => void
] {
    const [state, setState] = useState({
        isOpen: false,
        isLoading: false,
        patientId: null,
        fileToCangeType: '',
        documentType: null,
        documentTypes: [],
        toSendAttachments: [],
        viewableFileIndexs: [],
        isSendToKB: true,
    } as UploadInputsDialogState);


    const setDefaultState = () => {
        setState({
            ...state,
            editIndex: null,
            fileToCangeType: '',
            isOpen: false,
            isLoading: false,
            patientId: null,
            handleUpload: null,
            documentType: null,
            toSendAttachments: []
        });
    }

    const getAllowedExtensions = (): string => {
        return ".txt,.csv,.png,.pdf,.jpeg,.docx,.xlsx,.html,.doc,.txt,.key,.numbers";
    }

    const removeTypeFromName = (name: string) => {
        const indexPoint = name.lastIndexOf('.')
        return name.slice(0, indexPoint)
    }

    const getType = (name: string) => {
        const indexOfSlesh = name.lastIndexOf('.')
        return name.slice(indexOfSlesh + 1)
    }

    const handleEditAttachment = (index: number) => {
        if (Number.isInteger(state.editIndex)) {
            handleSaveAttachment(state.editIndex)
        } else {
            const files = state.toSendAttachments;
            const fileToCange = files[index];
            fileToCange.name = removeTypeFromName(fileToCange.name)

            setState({ ...state, toSendAttachments: [...files], editIndex: index });
        }

    }

    const handleSaveAttachment = (index: number) => {
        const files = state.toSendAttachments;
        const fileToSave = files[index];
        fileToSave.name = `${fileToSave.name}.${getType(fileToSave.file.name)}`

        setState({ ...state, toSendAttachments: [...files], editIndex: null });
    }

    const handleChanges = (index: number, value: string) => {
        const files = state.toSendAttachments;
        const fileToCange = files[index];
        fileToCange.name = value

        setState({ ...state, toSendAttachments: [...files] });
    }

    const handleFileUpload = (event) => {
        event.preventDefault();
        const file = event.target.files[0];

        if (!getAllowedExtensions().includes(getType(file.name))) {
            snackService.error("This file types is not allowed.")
            return
        }

        setState(state => ({
            ...state,
            toSendAttachments: [
                ...state.toSendAttachments,
                {
                    file: file,
                    name: file.name,
                }
            ]
        }));

        event.target.value = '';
    }

    const handleDataCategoriesSelect = (documentType: DocumentCategory) => {
        setState({ ...state, documentType });
    }

    const handleDropAttachment = (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {

        //The only dropped file might be rejected, so we should check this first.

        if (fileRejections && fileRejections.length) {
            snackService.error(`This file type is not allowed. The file must be one of: ${getAllowedExtensions().replaceAll(',', ', ')}`)
        }


        if (acceptedFiles && acceptedFiles.length) {
            setState(state => ({
                ...state,
                toSendAttachments: [
                    ...state.toSendAttachments,
                    ...acceptedFiles.map(file => {
                        return {
                            file: file,
                            name: file.name
                        };
                    })
                ]
            }));
        }


    }

    const handleRemoveAttachment = (index: number) => {
        var editIndex = null;
        if (Number.isInteger(state.editIndex)) {
            editIndex = state.editIndex > index ? state.editIndex - 1 : state.editIndex < index ? state.editIndex : null;
        }
        if (state.viewableFileIndexs.includes(index)) {
            state.viewableFileIndexs.splice(state.viewableFileIndexs.indexOf(index), 1)
        }
        const newViewableFileIndexs = state.viewableFileIndexs.map(item => item > index ? item - 1 : item)
        setState(state => ({
            ...state,
            toSendAttachments: state.toSendAttachments.filter((i, itemIndex) => index !== itemIndex),
            viewableFileIndexs: newViewableFileIndexs,
            editIndex
        }));
    }

    const handleSetViewable = (index: number) => {
        if (state.viewableFileIndexs.includes(index)) {
            state.viewableFileIndexs.splice(state.viewableFileIndexs.indexOf(index), 1)
            setState({ ...state })
        } else {
            setState(state => ({
                ...state,
                viewableFileIndexs: [...state.viewableFileIndexs, index]
            }))
        }
    }

    const handleToogleSend = (status: boolean) => {
        setState(state => ({ ...state, isSendToKB: status }))
    }

    const handleSubmit = () => {
        setState({ ...state, isLoading: true });

        if (state.toSendAttachments.length > 0) {
            state.handleUpload(state.toSendAttachments, state.documentType, state.viewableFileIndexs, state.isSendToKB);

            setState(state => ({
                ...state,
                toSendAttachments: [],
                viewableFileIndexs: [],
                isSendToKB: true,
                isLoading: false,
                isOpen: false
            }));
        }
        setDefaultState();
    }

    const handleClose = () => {
        state.handleUpload(null, null, null);
        setDefaultState();
    }

    patientDocumentsService.onUpload.subscribe((callbackModel) => {
        const categories = documentCategorySections;
        setState({
            ...state,
            isOpen: true,
            handleUpload: callbackModel.callback,
            documentTypes: categories,
            documentType: null
        });
    })

    documentsService.onUpload.subscribe((callbackModel) => {
        const categories = documentCategorySections;
        setState({
            ...state,
            isOpen: true,
            patientId: callbackModel.data,
            handleUpload: callbackModel.callback,
            documentTypes: categories,
            documentType: null
        });
    })

    return [
        state, 
        handleDataCategoriesSelect, 
        handleSubmit, 
        handleClose, 
        handleDropAttachment,
        handleRemoveAttachment, 
        handleEditAttachment, 
        handleSaveAttachment,
        handleChanges,
        handleSetViewable,
        handleToogleSend
    ];
}