import React from "react";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import {
    Box,
    Dialog, DialogActions,
    DialogContent, FormControl,
    IconButton, MenuItem, Select,
    Typography
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { useFacade } from "./appointmentOptionsDialogComponent.hooks";
import { useStyles } from "./appointmentOptionsDialogComponent.styles";
import { SecondaryButton } from "../../../common/components/wildHealthButton/SecondaryButton";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/moment";
import commonUseStyles from "../../../common/styles/common.styles";
import { appointmentWithTypeNames } from "../../models/appointments.enums";
import moment from "moment";
import {Alert} from "@material-ui/lab";
import {getAppointmentTypesByWithType, getAvailableWithTypes} from "../../helpers/appointmentHelper";

interface AppointmentOptionsDialogComponentProps {
    patientId: number;
}

export const AppointmentOptionsDialogComponent: React.FC<AppointmentOptionsDialogComponentProps> = (props: AppointmentOptionsDialogComponentProps) => {
    const [
        {
            isOpen,
            isSaving,
            hasChanges,
            isOverwriting,
            overwriteDate,
            selectedWithType,
            selectedPurpose,
            appointmentTypes,
            target,
            errors
        },
        handleWithTypeSelect,
        handlePurposeSelect,
        handleChanges,
        handleSave,
        handleOpen,
        handleClose,
    ] = useFacade(props.patientId);

    const classes = useStyles();
    const commonClasses = commonUseStyles();

    const maxDate = moment(new Date()).add(1, 'year').toDate();

    const getWithTypes = () => {
        const withTypes = getAvailableWithTypes(appointmentTypes);
        return Array.from(appointmentWithTypeNames).filter(x => withTypes.includes(x[0]));
    }

    const renderDialog = () => {
        return (
            <Dialog
                onClose={() => handleClose()}
                className={classes.root}
                open={isOpen}
            >
                <MuiDialogTitle
                    className={classes.dialogTitle}
                    disableTypography>

                    <Typography variant="h6">Appointment Options</Typography>

                    <IconButton id="appointment-options-close" className={classes.dialogTitleCloseAction} onClick={() => handleClose()}>
                        <CloseIcon />
                    </IconButton>
                </MuiDialogTitle>

                <DialogContent className={classes.dialogContent}>
                    <Box>
                        <Box>
                            <FormControl
                                variant="outlined"
                                color='primary'
                                required
                                size="small"
                                fullWidth>
                                <Box className={commonClasses.subtitle}>Schedule With</Box>
                                <Select
                                    style={{ background: '#FFF' }}
                                    value={selectedWithType}
                                    onChange={(v) => handleWithTypeSelect(Number(v.target.value))}
                                >
                                    {
                                        getWithTypes().map((value, index) => {
                                            return <MenuItem key={index} value={value[0]}>{value[1]}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                        <Box mt={2}>
                            <FormControl
                                variant="outlined"
                                color='primary'
                                required
                                size="small"
                                fullWidth>
                                <Box className={commonClasses.subtitle}>Appointment Type</Box>
                                <Select
                                    style={{ background: '#FFF' }}
                                    id="select-purpose"
                                    value={selectedPurpose}
                                    onChange={(v) => handlePurposeSelect(Number(v.target.value))}
                                >
                                    {
                                        getAppointmentTypesByWithType(appointmentTypes, selectedWithType).map((value, index) => {
                                            return <MenuItem key={index} value={value.purpose}>{value.name}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                        <Box mt={2}>
                            <Box className={commonClasses.subtitle}>Earliest Date to Schedule</Box>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    id={`nextAppointmentDate`}
                                    fullWidth
                                    size="small"
                                    required
                                    placeholder="mm/dd/yyyy"
                                    format="MM/DD/YYYY"
                                    openTo="month"
                                    value={target.nextAppointmentDate}
                                    inputVariant="outlined"
                                    views={["month", "date"]}
                                    onChange={(momentDate) => handleChanges(`nextAppointmentDate`, momentDate === null ?
                                        "" : momentDate.format("YYYY-MM-DD"))}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date"
                                    }}
                                    minDate={new Date()}
                                    maxDate={maxDate}
                                />
                            </MuiPickersUtilsProvider>
                        </Box>
                        {
                            isOverwriting &&
                            <Box mt={1}>
                                <Alert severity="warning">By selecting this, you are overriding the previously set date of {moment(overwriteDate).format('L')}</Alert>
                            </Box>
                        }
                    </Box>
                </DialogContent>
                {
                    hasChanges &&
                    <DialogActions className={classes.dialogActions}>
                        <WildHealthButton id="appointment-options-discard" onClick={() => handleClose()} color="tertiary">
                            Discard
                        </WildHealthButton>
                        <WildHealthButton
                            id="appointment-options-save-changes"
                            onClick={() => handleSave()}
                            loading={isSaving}
                            disabled={Object.keys(errors).length > 0}
                            autoFocus>
                            Save Changes
                        </WildHealthButton>
                    </DialogActions>
                }
            </Dialog>
        )
    }
    return (
        <>
            <Box display="flex" justifyContent="center">
                <SecondaryButton id="appointment-options-see-options" onClick={() => handleOpen()}>
                    See Appointment Options
                </SecondaryButton>
            </Box>

            {
                target && renderDialog()
            }
        </>
    );
}