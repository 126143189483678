import {
    ConversationModelBase,
    ConversationModelBaseView,
    PatientConversationParticipantModel
} from "../../../models/conversation.models";
import jsPDF from "jspdf"
import moment from 'moment';
import {
    StaffOrHealthCoachMessagesEnum
} from "../../../components/patientConversationsViewComponent/patientConversationsViewComponent.hooks";
import pdfServices from "../PdfServices";

const header = (pdf, subject, type = StaffOrHealthCoachMessagesEnum.HEALTH_COACH, patient: PatientConversationParticipantModel) => {

    const subjectType = type === StaffOrHealthCoachMessagesEnum.HEALTH_COACH ? 'Health Care Conversation for ' : 'Staff Conversation for'

    const conversationSubject = `Conversation Log: ${subjectType} ${patient.firstName} ${patient.lastName} - id: ${patient.patientId}`

    const splitText = pdf.splitTextToSize(conversationSubject, 180)

    let yPosition = 10;

    pdf.setFontSize(16);
    pdf.text(splitText, pdf.internal.pageSize.width /2,yPosition,{align:"center"})
    for(var i = 0, length = splitText.length; i < length; i++) {
        yPosition+=3;
    }
    pdf.setLineWidth(0.5);
    yPosition+=2;
    pdf.line(1, yPosition, 220, yPosition);
    return yPosition;
}

enum SideEnum {
    LEFT='LEFT',
    RIGHT='RIGHT'
}
const isLeftSide = (side: SideEnum):boolean => side === SideEnum.LEFT;

const addTextAndCarriageReturn = (pdf, message, author, yPosition, side, isAttachment = false) => {

    yPosition += 15;

    const xPosition = isLeftSide(side) ? 10 : 100;

    pdf.setFontType('normal');
    pdf.setFontSize(11);
    pdf.text(xPosition, yPosition, `${moment(message.dateCreated).format('lll')} - ${author?.firstName} ${author?.lastName}  wrote:`)

    yPosition += 10;

    //const wrapToWidth = isLeftSide(side) ? 190 : 90;

    pdf.setFontSize(10);

    const splitText = pdf.splitTextToSize(pdfServices.removeSpecialChars(isAttachment ? `Attachment - ${message.media[0].filename ?? 'File name missing'}`: message.body), 90)

    pdf.text(xPosition, yPosition, splitText)

    for (var i = 0, length = splitText.length; i < length; i++) {
        yPosition +=4;
    }
    yPosition += 3;

    return yPosition;
}

const addFooterAndNextHeader = (pdf, yPosition, subject, conversationType, patient) => {

    if ( yPosition > 250) {
        pdf.addPage();
        yPosition = header(pdf,subject, conversationType,patient);
    }
    return yPosition;
}

const addFooters = pdf => {

    const pageCount = pdf.internal.getNumberOfPages()

    pdf.setFont('helvetica', 'italic')
    pdf.setFontSize(8)
    for (var i = 1; i <= pageCount; i++) {
        pdf.setPage(i)
        pdf.text(`Page ${String(i)} of  ${String(pageCount)} -- created ${moment().format('LLL')}`, pdf.internal.pageSize.width / 2, 287, {
            align: 'center'
        })
    }
}

export const ConversationPdfDocument = (conversation: ConversationModelBase, messages: ConversationModelBaseView[], conversationType:StaffOrHealthCoachMessagesEnum) => {

    const pdf = new jsPDF("p", "mm", "A4");

    let yPosition = 10;

    yPosition = header(pdf, conversation.subject, conversationType, conversation.patients[0]);

    let author;

    messages.map((message,index)=> {
        author = conversation.employees.find(emp => emp.vendorExternalIdentity === message.author);
        if(message.body) {

            if (author) {

                yPosition = addTextAndCarriageReturn(pdf, message, author, yPosition, 'LEFT');

            } else {
                author = conversation.patients.find(pat => pat.vendorExternalIdentity === message.author);
                yPosition = addTextAndCarriageReturn(pdf, message, author, yPosition,'RIGHT');
            }

        }
        else
        { //probably an attachment.

            if(author){
                yPosition =  addTextAndCarriageReturn(pdf, message, author, yPosition, 'LEFT', true )
            }
            else {
                author = conversation.patients.find(pat => pat.vendorExternalIdentity === message.author);
                yPosition = addTextAndCarriageReturn(pdf, message, author, yPosition, 'RIGHT', true )
            }
        }
        yPosition = addFooterAndNextHeader(pdf, yPosition, conversation.subject, conversationType, conversation.patients[0])
    })

    addFooters(pdf)

    return pdf;
};