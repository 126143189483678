import { useState, useEffect } from "react";
import { ScoreTermsModel } from '../../../healthScore/models/score.model';

interface ScoreTermsViewModel extends ScoreTermsModel {
  isOpenedTitle: boolean;
  isOpenedGraph: boolean;
}

interface GeneralChartComponentState {
  data: ScoreTermsViewModel[];
  barSize: number;
}

/**
 * Custom Hook to manage a view Model for GeneralChart component
 */
export function useFacade(data: ScoreTermsModel[], ref: any): [
  GeneralChartComponentState,
  (name: string, status: boolean, type: string) => void,
] {

  const [state, setState] = useState({
    data: [],
  } as GeneralChartComponentState);

  const handleChartTooltipToggle = (name: string, status: boolean, type: string) => {
    state.data.forEach(item => {
      if (item.name === name && type === "title") {
        item.isOpenedTitle = status;
      } else if (item.name === name && type === "score") {
        item.isOpenedGraph = status;
      }
    })
    setState(state => ({ ...state }));
  }

  useEffect(() => {
    setState(state => ({ ...state, data: data.map(i => { return { ...i, isOpenedTitle: false, isOpenedGraph: false } as ScoreTermsViewModel }) }));

    function renderBars(){
      setState(state => ({
        ...state,
        barSize:ref.current.offsetWidth - 200
      }));
    }

    window.addEventListener('resize',renderBars);

    if(!state.barSize)
    {
      setState(state => ({
        ...state,
        barSize:ref.current.offsetWidth - 200
      }));
    }

    return ()=>{
      window.removeEventListener('resize', renderBars);
    }
  }, []);

  return [
    state,
    handleChartTooltipToggle,
  ];
}
