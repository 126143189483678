import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { onEmit } from "../../../common/helpers/on-emit";
import { DocumentCategoryModel, documentCategoryNames, documentCategorySections, DocumentModel, } from "../../models/documents.models";
import { patientDocumentsService } from "../../../inputs/services/patientDocuments.service";
import { documentsService } from "../../../inputs/services/documents.service";
import { documentsQuery } from "../../../inputs/stores/documentsStore/documents.query";

interface DocumentsPageState {
    isLoading: boolean;
    isUploading: boolean;
    documentsCategory: DocumentCategoryModel[];
    activeCategory: DocumentCategoryModel;

}

export function useFacade(patientId: number): [
    DocumentsPageState,
    () => void,
    (category: DocumentCategoryModel) => void,
    () => void,
] {
    const [state, setState] = useState({
        isLoading: true,
        isUploading: false,
        documentsCategory: [],
        activeCategory: null,

    } as DocumentsPageState);

    const handleGoToDocuments = () => {
        setState(state => ({
            ...state,
            activeCategory: null,
        }));
    }

    const handleOpenDocumentsList = (category: DocumentCategoryModel) => {
        if (!category.documents.length) {
            return
        }
        setState(state => ({
            ...state,
            activeCategory: category,
        }));
    }


    const handleDocumentUpload = () => {
        setState(state => ({
            ...state,
            isUploading: true
        }));

        const cb = () => {
            setState(state => ({
                ...state,
                isUploading: false
            }));
        }

        const action = patientId ? documentsService.upload(patientId) : patientDocumentsService.upload();

        action.subscribe(() => {
            cb();
            const action = patientId ? documentsService.getDocuments(patientId) : patientDocumentsService.getDocuments()
            action.subscribe(cb, cb)
        }, cb);
    }

    const useEffectCB = () => {

        const subscriptions: Subscription[] = [
            onEmit<DocumentModel[]>(documentsQuery.documentList$, documents => {
                const createDocumentsCategory = documentCategorySections.map(el => {
                    return {
                        name: documentCategoryNames[Number(el)],
                        id: Number(el),
                        type: Number(el),
                        documents: documents.length ? documents.filter(doc => doc.type === Number(el)) : [],
                    }
                })
                setState(state => ({
                    ...state,
                    documentsCategory: createDocumentsCategory
                }));

            }),

        ];
        const cb = () => {
            setState(state => ({
                ...state,
                isLoading: false
            }));
        }

        const action = patientId ? documentsService.getDocuments(patientId) : patientDocumentsService.getDocuments()
        action.subscribe(cb, cb)

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, [patientId]);

    return [state, handleGoToDocuments, handleOpenDocumentsList, handleDocumentUpload];
}