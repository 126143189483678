import { Box, Dialog, DialogContent, IconButton, TextField, Button } from '@material-ui/core';
import React from 'react';
import { useFacade } from './addNewLabPricingComponent.hooks';
import { useStyles } from './addNewLabPricingComponent.styles';
import CloseIcon from '@material-ui/icons/Close';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from 'clsx';
import { ChevronLeft } from '@material-ui/icons';
import { AddOnProvider } from '../../../addons/models/addOns.models';

interface AddNewLabPricingDialogComponentProps {
  handleGoBack: (labProvider: AddOnProvider) => void;
}

export const AddNewLabPricingDialogComponent: React.FC<AddNewLabPricingDialogComponentProps> = (props: AddNewLabPricingDialogComponentProps) => {
  const [
    {
      isOpen,
      isLoading,
      errors,
      addOn,
    },
    handleChanges,
    handleCreate,
    handleClose,
    handleBack
  ] = useFacade(props.handleGoBack);

  const commonClasses = commonUseStyles();
  const classes = useStyles();

  return (
    <Dialog
      onClose={() => handleClose()}
      className={classes.root}
      open={isOpen}
    >
      <DialogContent className={classes.dialogContent}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <span className={clsx(commonClasses.size24, commonClasses.textMedium)}>Add New Lab</span>
          </Box>
          <Box display="flex " alignItems="center">
            <IconButton
              id="add-new-addOn-close"
              className={classes.dialogTitleCloseAction}
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box mt={5}>
          <Box className={commonClasses.inputLabel} mb={1}>Test Code</Box>
          <TextField
            id={`integrationId`}
            type="text"
            fullWidth
            required
            size="small"
            InputProps={{ className: 'input', classes }}
            variant="outlined"
            value={addOn.integrationId}
            error={!!errors['integrationId']}
            helperText={errors['integrationId']}
            onChange={(event) => handleChanges(event.target.id, event.target.value)}
          />
        </Box>
        <Box mt={2}>
          <Box className={commonClasses.inputLabel} mb={1}>Test Name</Box>
          <TextField
            id={`name`}
            type="text"
            fullWidth
            required
            size="small"
            InputProps={{ className: 'input', classes }}
            variant="outlined"
            value={addOn.name}
            error={!!errors['name']}
            helperText={errors['name']}
            onChange={(event) => handleChanges(event.target.id, event.target.value)}
          />
        </Box>
        <Box mt={2}>
          <Box className={commonClasses.inputLabel} mb={1}>Price</Box>
          <TextField
            id={`price`}
            type="number"
            fullWidth
            required
            size="small"
            InputProps={{ className: 'input', classes }}
            variant="outlined"
            value={addOn.price}
            error={!!errors['price']}
            helperText={errors['price']}
            onChange={(event) => handleChanges(event.target.id, event.target.value)}
          />
        </Box>
        <Box mt={5} display="flex" alignItems="center">
          <Box>
            <Button
              id="previous-step-button"
              onClick={handleBack}
              className={commonClasses.backBtn}
            >
              <Box display="flex" alignItems="center">
                <ChevronLeft />
                <Box ml={0.5} className={clsx(commonClasses.size16, commonClasses.textMedium, commonClasses.colorBlack)}>
                  Previous Step
                </Box>
              </Box>
            </Button>
          </Box>
          <Box flex={1} display="flex" alignItems="center" justifyContent="flex-end" >
            <Box mx={2}>
              <WildHealthButton
                id="new-addon-cancel"
                onClick={() => handleClose()}
                color="tertiary"
                size='medium'
                disabled={isLoading}
              >
                Cancel
              </WildHealthButton>
            </Box>
            <Box>
              <WildHealthButton
                id="add-new-addon"
                size='medium'
                onClick={handleCreate}
                autoFocus
                loading={isLoading}
              >
                Add New Lab
              </WildHealthButton>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
