import {
    Box,
    Button,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    TextField
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import clsx from 'clsx';
import React, {useEffect, useState} from "react";
import {snackService} from "../../../common/snack/state";
import commonUseStyles from '../../../common/styles/common.styles';
import {IErrorState} from "../../../common/validation/error-state";
import {AttachmentModel, InternalNoteContent, NoteLogModel, NoteModel, NotesType} from "../../models/notes.models";
import {notesService} from "../../services/notes.service";
import {CreateNoteState, useFacade as useBaseFacade} from "../createNote/createNote.hooks";
import {createNoteValidator} from "../createNote/createNote.validator";
import {patientsQuery} from "../../../patients/stores/patientsStore";

interface CreateSimpleComponentState extends IErrorState {
    content: InternalNoteContent;
    logs: NoteLogModel[];
    title: string;
    originalNote?: NoteModel;
}

export function useFacade(patientId: number, note: NoteModel | null, type: NotesType, classes: any, handleGoToNotes: Function, originalNote: NoteModel | null): [
    CreateSimpleComponentState & CreateNoteState,
    JSX.Element,
    JSX.Element,
    JSX.Element,
    string,
    JSX.Element,
] {
    const noteName = `${NotesType[type]} Note`;

    const [state, setState] = useState({
        title: note?.title || '',
        content: {
            attachmentsEmployee: [],
        },
        logs: [],
        errors: {},
        originalNote: originalNote
    } as CreateSimpleComponentState);

    const commonClasses = commonUseStyles();

    const fileTypes = type === NotesType.Internal
        ? [
            "application/pdf",
            'image/jpeg',
            'image/png',
        ]
        : [
            'image/jpeg',
            'image/png',
        ]

    const getData = (state: CreateSimpleComponentState) => {
        return {
            name: noteName,
            title: state.title,
            logs: state.logs,
            type: type,
            files: state.content.attachmentsEmployee,
            version: note?.version,
            originalNoteId: state.originalNote?.id
        }
    }

    const getValidateResult = () => {
        createNoteValidator.validateObjectAndSetState(state, setState, state);
        return createNoteValidator.stateIsValid(state);
    }

    const [
        baseState,
        baseSetState,
        noteTitle,
        endOfTemplate,
        baseGetData,
    ] = useBaseFacade({ patientId, note, handleGoToNotes, type, getData: (data) => ({ ...data, ...getData(state) }), getValidateResult, originalNote});

    const { inProgress } = baseState;

    const handleNoteTitleChange = (field: string, value: string) => {
        createNoteValidator.validateAndSetState(state, setState, field, value);
        setState(state => ({
            ...state,
            isChanged: true,
            title: value
        }));
    }

    const titleTextBox = (
        <TextField
            label='Enter title'
            type='text'
            size="small"
            variant="outlined"
            id='title'
            value={state.title}
            helperText={state.errors['title']}
            error={!!state.errors['title']}
            disabled={inProgress}
            InputProps={{ className: 'input' }}
            onChange={(e) => {
                handleNoteTitleChange(e.target.id, e.target.value);
            }}
        />
    )

    const validateFileType = (file: File) => {
        const isValid = fileTypes.includes(file.type);
        if (!isValid) {
            snackService.warning(`File ${file.name} have wrong format.`);
        }
        return isValid;
    }

    const validateFileSize = (file: File) => {
        const isValid = file.size / 1048576 <= 5; // is less then 5 MB
        if (!isValid) {
            snackService.warning(`File ${file.name} is too large (limit 5 MB).`);
        }
        return isValid;
    }

    const handleCreateAttachment = (event?:any) => {
        event.preventDefault();

        const items = Array.from(event.target.files).filter(file => validateFileType(file as File) && validateFileSize(file as File)).map((item, index)=>{
            const file = item as File;
            const attachmentModel = {
                tempId: Date.now() + index,
                file: file,
                ForDelete: false,
                noteId: baseState?.note?.id,
                fileName: file.name,
                fileSize: file.size
            } as AttachmentModel;

            return attachmentModel;
        });

        if(items === null || items === undefined || items.length <= 0){
            return;
        }

        state.content.attachmentsEmployee = [...state.content.attachmentsEmployee, ...items];
        setState(state => ({ ...state, isChanged: true }));

        event.target.value = '';
    }

    const handleRemoveAttachment = (item: AttachmentModel) => {
        if(!(item?.id)){
            state.content.attachmentsEmployee = state.content.attachmentsEmployee.filter(i => i.tempId !== item.tempId);
            setState(state => ({ ...state, isChanged: true }));
        }
        else{
            const result = state.content.attachmentsEmployee.find(i => i.id === item.id);
            result.ForDelete = true;
            setState(state => ({ ...state, isChanged: true }));
        } 
    }

    const getFileSize = (size: number) => {
        if (size < 1024) {
            return size + ' bytes';
        } else if (size > 1024 && size < 1048576) {
            return (size / 1024).toFixed(1) + ' KB';
        } else if (size > 1048576) {
            return (size / 1048576).toFixed(1) + ' MB';
        }
    }

    const attachment = (
        <Box mt={3}>
            <Box className={classes.title} mb={2}>
                <span className='uppercase'>Attachments:</span>
            </Box>
            <Box display='flex'>
                <Button
                    className={clsx(classes.addButton, commonClasses.whButton)}
                    disabled={inProgress}
                >
                    <label htmlFor='upload-photo'>
                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                            className={classes.addAttachment}
                        >
                            <AddIcon />
                            Add Attachment
                        </Grid>
                    </label>
                </Button>

                <Box m={1}>
                    <span>Note: File should have size less then 5 MB and file type png/jpeg {type === NotesType.Internal && 'or pdf'} </span>
                </Box>

                <input
                    multiple
                    type="file"
                    name="file"
                    id="upload-photo"
                    className={classes.fileInput}
                    accept={fileTypes.join(', ')}
                    onChange={handleCreateAttachment}
                />
            </Box>
            <List>
                {
                    state.content.attachmentsEmployee?.map((item, index) =>
                    !item.ForDelete &&
                        <ListItem key={index}>
                            <ListItemAvatar>
                                <PermMediaIcon />
                            </ListItemAvatar>
                            { <ListItemText
                                primary={decodeURIComponent(item.fileName)}
                                secondary={getFileSize(item.fileSize)}
                            /> }
                            { <ListItemSecondaryAction>
                                <IconButton
                                    id={`create-simple-remove-attachment-${item.fileSize}`}
                                    edge="end"
                                    onClick={() => handleRemoveAttachment(item)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </ListItemSecondaryAction> }
                        </ListItem>
                    )
                }
            </List>
        </Box>
    )

    const useEffectCB = () => {
        const errorCB = () => baseSetState(baseState => ({ ...baseState, isLoading: false }));

        if(originalNote && !note){
            notesService.getContentByEmployee(originalNote.id, patientId).subscribe(result => {
                baseSetState(baseState => ({
                    ...baseState,
                    isLoading: false,
                    isNew: false,
                    internalContent: result.internalContent,
                    
                }));

                let attachments = result?.attachmentsEmployee?.map((item,index)=>{
                    const file = item as File;

                    return {
                        tempId: Date.now() + index,
                        id: item.attachmentId,
                        file: file,
                        ForDelete: false,
                        noteId: originalNote.id,
                        fileName: item.fileName,
                        fileSize: item.fileSize
                    } as AttachmentModel;
                });

                setState(state => ({ ...state, 
                    content: {
                        attachmentsEmployee:attachments
                    },
                    title: originalNote.title
                }));

                let content = baseGetData({ ...baseState })

                content.id = null;

                notesService.saveAsDraft(content, patientsQuery.getTargetPatientIsPremium()).subscribe(
                    (note) => {
                        baseSetState(baseState => ({
                            ...baseState,
                            note: note,
                            isLoading: false
                        }));
                    },
                    () => {
                        snackService.error('Note already exists for this appointment.');
                    }
                );
            });
        }
        else if (note) {
            notesService.getContentByEmployee(note.id, patientId).subscribe(result => {
                baseSetState(baseState => ({
                    ...baseState,
                    isLoading: false,
                    isNew: false,
                    internalContent: result.internalContent,
                }));

                let attachments = result?.attachmentsEmployee?.map((item,index)=>{

                    const file = item as File;
                    return {
                        tempId: Date.now() + index,
                        id: item.attachmentId,
                        file: file,
                        ForDelete: false,
                        noteId: note.id,
                        fileName: item.fileName,
                        fileSize: item.fileSize
                    } as AttachmentModel;
                });

                setState(state => ({ ...state, 
                    content: {
                        attachmentsEmployee:attachments
                    } 
                }));
            }, errorCB);
        } else {
            notesService.saveAsDraft(baseGetData({ ...baseState }), patientsQuery.getTargetPatientIsPremium()).subscribe((note) => {
                baseSetState(baseState => ({
                    ...baseState,
                    note: note,
                    isLoading: false
                }));
            }, errorCB);
        }

        return () => { };
    }

    useEffect(useEffectCB, [patientId, note]);

    return [
        { ...state, ...baseState },
        noteTitle,
        attachment,
        endOfTemplate,
        noteName,
        titleTextBox
    ];
}
