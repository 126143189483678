import React from 'react';
import { LayoutComponent } from '../../../../components/layoutComponent/LayoutComponent';
import { TitlesService } from '../../../../constants/title.constants';
import { FeatureComponent } from '../../../common/components/featureFlags/FeatureComponent';
import { FeatureFlag } from '../../../common/components/featureFlags/featureFlags.models';
import { JoinPracticeComponent } from '../../components/joinPracticeComponent/JoinPracticeComponent';

export const JoinPracticePage: React.FC = (props: any) => {
    const practiceUrl = props.match.params.practiceUrl;

    return (
            <LayoutComponent title={TitlesService.joinPracticeTitle} hideMenu isLicensingSignup={true}>
                <FeatureComponent featureFlag={FeatureFlag.EnabledLicensingJoin}>
                    <JoinPracticeComponent practiceUrl={practiceUrl} />
                </FeatureComponent>
            </LayoutComponent>
    )
}
