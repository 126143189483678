import { Box, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, Popover, } from "@material-ui/core";
import React from 'react';
import { useStyles } from "./ChronologicalSummaryComponent.style";
import commonUseStyles from '../../../common/styles/common.styles';
import { useFacade } from './chronologicalSummaryComponent.hooks';
import clsx from "clsx";
import { ReactComponent as OpenSideIcon } from '@img/icons/OpenSide.svg';
import { ReactComponent as CloseSideIcon } from '@img/icons/CloseSide.svg';
import { ReactComponent as FilterIcon } from '@img/icons/Filter.svg';
import { colors } from "../../../common/constants/colors";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { WildHealthLinkButton } from "../../../common/components/WildHealthLinkButton";
import CloseIcon from '@material-ui/icons/Close';
import { ChronologicalEventModel, ChronologicalEventType, ChronologicalEventTypeNames } from "../../models/patient.model";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import moment from "moment";
import { toCurrentTimeZone } from "../../../timezones/helpers/timezone";
import { navigationService } from "../../../../services/navigation.service";
import { useHistory } from "react-router";
import { ReactComponent as Results } from '@img/icons/Results.svg';
import { ReactComponent as SupplementMeds } from '@img/icons/SupplementMeds.svg';
import { ReactComponent as NoteIcon } from '@img/icons/ArticleDraft.svg';
import { ReactComponent as AppointmentsIcon } from '@img/icons/recentAppts.svg';
import { ReactComponent as EllipseIcon } from '@img/icons/Ellipse.svg';
import { ReactComponent as MessageIcon } from '@img/icons/Message.svg';
import { MessageEventComponent } from "../MessageEventComponent/MessageEventComponent";

interface ChronologicalSummaryComponentProps {
    patientId: number | null;
    filterTypes: ChronologicalEventType[];
}

const filtersPopoverProps: any = {
    id: "chronologicalSummaryPopover",
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
};

export const ChronologicalSummaryComponent: React.FC<ChronologicalSummaryComponentProps> = (props: ChronologicalSummaryComponentProps) => {
    const {
        patientId,
        filterTypes
    } = props;

    const [
        {
            chronologicalEvents,
            chronologicalEventsView,
            isTimelineOpen,
            isLoading,
            filtersState,
        },
        handleToggleFilters,
        handleToggleSideArea,
        handleClearFilters,
        handleApplyFilters,
        handleAddFilterType,
    ] = useFacade(patientId);

    const classes = useStyles();
    const commonClasses = commonUseStyles();
    const history = useHistory();
    const closeChronologicalTimeline = document.getElementById('chronological-summary-close-wrapper');
    const height = closeChronologicalTimeline?.offsetHeight ? closeChronologicalTimeline.offsetHeight - 100 : 0;

    const renderIcon = (type: ChronologicalEventType) => {
        switch (type) {
            case ChronologicalEventType.LabsOrdered:
                return <Box className={classes.iconContainer}><Box className={classes.iconWrapper}> <Results fill={colors.accent1} /></Box></Box>;
            case ChronologicalEventType.VisitCompleted:
                return <Box className={classes.iconContainer}><Box className={classes.iconWrapper}><AppointmentsIcon fill={colors.accent1} /></Box></Box>;
            case ChronologicalEventType.NotePublished:
                return <Box className={classes.iconContainer}><Box className={classes.iconWrapper}><NoteIcon width='14px' height='14px' fill={colors.accent1} /></Box></Box>;
            case ChronologicalEventType.SupplementUpdated:
                return <Box className={classes.iconContainer}><Box className={classes.iconWrapper}> <SupplementMeds fill={colors.accent1} /></Box></Box>;
            case ChronologicalEventType.InternalMessageSent:
                return <Box className={classes.iconContainer}><Box className={classes.iconWrapper}> <MessageIcon fill={colors.accent1} /></Box></Box>;
            default: return <Box className={classes.iconContainerCommon}> <EllipseIcon width='12px' height='12px' /> </Box>;
        }
    }

    const renderActions = (item: ChronologicalEventModel) => {
        switch (item.type) {
            case ChronologicalEventType.LabsOrdered:
                return <WildHealthLinkButton
                    onClick={() => navigationService.toPatientOrders(history, patientId, item.payload?.orderId)}
                    content={
                        <Box display="flex" alignItems='center'>
                            <span className={classes.action}> See Lab Order </span>
                        </Box>
                    }
                    id={`chronological-action-${ChronologicalEventType.LabsOrdered}-${item.payload?.[0]}`}
                />;
            case ChronologicalEventType.VisitCompleted:
                return <WildHealthLinkButton
                    onClick={() => navigationService.toPatientProfilePastAppointments(history, patientId)}
                    content={
                        <Box display="flex" alignItems='center'>
                            <span className={classes.action}>  See Visit </span>
                        </Box>
                    }
                    id={`chronological-action-${ChronologicalEventType.LabsOrdered}-${item.payload?.[0]}`}
                />;
            case ChronologicalEventType.NotePublished:
                return <WildHealthLinkButton
                    onClick={() => navigationService.toPatientProfileNote(history, patientId, item.payload?.noteId)}
                    content={
                        <Box display="flex" alignItems='center'>
                            <span className={classes.action}>  See Note </span>
                        </Box>
                    }
                    id={`chronological-action-${ChronologicalEventType.LabsOrdered}-${item.payload?.[0]}`}
                />;
            case ChronologicalEventType.SupplementUpdated:
                return <WildHealthLinkButton
                    onClick={() => navigationService.toPatientsProfileSupplements(history, patientId)}
                    content={
                        <Box display="flex" alignItems='center'>
                            <span className={classes.action}>  See Supplement List </span>
                        </Box>
                    }
                    id={`chronological-action-${ChronologicalEventType.LabsOrdered}-${item.payload?.[0]}`}
                />;
            default: return <></>;
        }
    }

    const renderSideAreaContent = () => {
        return (
            <Box maxHeight={`${height}px`} className={clsx(classes.content, "flat-scroll")}>
                {chronologicalEventsView.map(item => {
                    return <Box mt={1.5} display='flex'>
                        <Box minWidth="76px" className={clsx(commonClasses.size12, commonClasses.colorGray1)}>
                            <Box className={commonClasses.textBold}>{moment(toCurrentTimeZone(item.createdAt)).format('MMM Do')}</Box>
                            <Box>{moment(toCurrentTimeZone(item.createdAt)).format('LT')}</Box>

                        </Box>
                        <Box className={classes.eventMainContainer}>
                            {renderIcon(item.type)}
                            <Box className={commonClasses.textMedium}> {item.title}</Box>
                            {
                                item.type === ChronologicalEventType.InternalMessageSent
                                    ? <MessageEventComponent item={item} />
                                    : <>
                                        {item.body && <Box className={commonClasses.size14}>{item.body}</Box>}
                                        <Box pb={1}>{renderActions(item)}</Box>
                                    </>
                            }

                        </Box>
                    </Box>
                }
                )}
            </Box>
        )
    }

    const renderSideAreaFilter = () => {
        return (
            <Box display='flex' alignItems='center'>
                <Box className={commonClasses.size14}>{`${chronologicalEvents.length} EVENTS `}</Box>
                <IconButton
                    size="small"
                    disabled={chronologicalEvents.length < 2}
                    id='filter-chronological-timeline'
                    onClick={(event) => handleToggleFilters(event)}
                >
                    <FilterIcon fill={!!chronologicalEvents.length ? colors.main : colors.gray2} />
                </IconButton>
                <Popover
                    id={filtersPopoverProps.id}
                    anchorEl={filtersState.anchorEl}
                    onClose={() => handleToggleFilters()}
                    open={filtersState.isOpen}
                    anchorOrigin={filtersPopoverProps.anchorOrigin}
                    transformOrigin={filtersPopoverProps.transformOrigin}
                >
                    <Box width="300px">
                        <Box pt={2} pl={2} className={clsx(commonClasses.size12, commonClasses.colorGray1, commonClasses.uppercase)}>filter by event type</Box>
                        <List>
                            {
                                filterTypes.map(type =>
                                    <ListItem
                                        id={`add-type-filter-${type}`}
                                        key={type}
                                        button
                                        onClick={() => handleAddFilterType(type)}
                                    >
                                        <ListItemIcon>
                                            {filtersState.filters.includes(type) ? <CheckBoxIcon className={classes.active} /> : <CheckBoxOutlineBlankIcon />}
                                        </ListItemIcon>
                                        <ListItemText primary={ChronologicalEventTypeNames.get(type)} />
                                    </ListItem>
                                )
                            }
                        </List>
                        <Divider />

                        <Box p={2} display="flex" justifyContent="space-between" alignItems='center'>
                            <WildHealthLinkButton
                                onClick={() => handleClearFilters()}
                                content={
                                    <Box display="flex" alignItems='center'>
                                        <CloseIcon className={classes.closeIcon} />
                                        <span className={classes.active}> Clear All </span></Box>
                                }
                                id="chronological-events-clear-all"
                            />

                            <WildHealthButton
                                id="chronological-events-apply-filter"
                                onClick={() => handleApplyFilters()}
                            >
                                Apply filter
                            </WildHealthButton>
                        </Box>
                    </Box>
                </Popover>
            </Box>
        )
    }

    return (
        <Box className={classes.container} id='chronological-summary-close-wrapper'>
            <Box className={classes.closeWrapper}>
                <IconButton id='chronological-summary-side-area' onClick={() => handleToggleSideArea(!isTimelineOpen)}>
                    <OpenSideIcon className={classes.icon} />
                </IconButton>
                <Box className={classes.closeTitle}>
                    Chronological Summary
                </Box>
            </Box>
            <Box className={isTimelineOpen ? classes.openTimeline : classes.closeTimeline}>
                {isTimelineOpen
                    && <Box>
                        <Box display='flex' justifyContent='space-between' alignItems='center'>
                            <Box className={classes.openTitle}>
                                Chronological Summary
                            </Box>
                            <IconButton id='chronological-summary-side-area' onClick={() => handleToggleSideArea(!isTimelineOpen)}>
                                <CloseSideIcon className={classes.icon} />
                            </IconButton>
                        </Box>
                        {isLoading && <Box p={4}>
                            <WildHealthLinearProgress />
                        </Box>}
                        {!isLoading && renderSideAreaFilter()}
                        {!isLoading && renderSideAreaContent()}
                    </Box>
                }
            </Box>
        </Box>

    )
}

