import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rootView: {
            fontSize: 14,
            minHeight: 28,
            borderRadius: 2,
            display: 'flex',
            alignItems: 'center',
            width: 'fit-content',
            whiteSpace: 'nowrap',
            paddingRight: 10,
            paddingLeft: 10,
            backgroundColor: colors.separators,
        },
        button: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: '8px',
            color: colors.main,
            fontSize: 14
        },
    })
);
