import { Box, MenuItem, Popover, useTheme, useMediaQuery } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { OptionsButton } from "../wildHealthButton/OptionsButton";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { useStyles as useCheckboxDropdownStyles } from "../checkBoxDropdown/checkboxDropdown.styles";
import { useStyles } from "./selectRangeComponent.styles";

export interface ViewMode {
  id: number;
  title: string;
}

interface SelectRangeComponentProps {
  selectedId: number;
  options: ViewMode[];
  disabled?: boolean;
  handleChanges: Function;
}

export const SelectRangeComponent: React.FC<SelectRangeComponentProps> = (
  props: SelectRangeComponentProps
) => {
  const { selectedId, options, disabled, handleChanges } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const checkboxDropdownClasses = useCheckboxDropdownStyles();
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderItemContent = (view: ViewMode) => {
    return (
      <Box className={classes.itemContent}>
        <Box ml={1} my={0.5}>
          <span>{view.title}</span>
        </Box>
      </Box>
    );
  };

  const renderButtonContent = (view: ViewMode) => {
    const [DownArrowIcon, UpArrowIcon] = isSmallScreen
      ? [KeyboardArrowDownIcon, KeyboardArrowUpIcon]
      : [ArrowDropDownIcon, ArrowDropUpIcon];

    return (
      <Box display='flex' alignItems='center'>
        <Box className={classes.buttonTitle} my={0.5}>
          <span>{view.title}</span>
        </Box>
        <Box height={24}>{Boolean(anchorEl) ? <UpArrowIcon /> : <DownArrowIcon />}</Box>
      </Box>
    );
  };

  const handleOnClick = (id: number) => {
    handleClose();
    handleChanges(id);
  };

  return (
    <Box>
      <OptionsButton
        id='select-view-mode'
        className={clsx(checkboxDropdownClasses.placeHolder, {
          [checkboxDropdownClasses.placeHolderOpen]: Boolean(anchorEl),
        })}
        disabled={disabled}
        variant='outlined'
        onClick={handleClick}
        fullWidth
      >
        {renderButtonContent(options.find((i) => i.id === selectedId))}
      </OptionsButton>

      <Popover
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {options.map((item, index) => (
          <MenuItem
            id={`select-view-mode-item-${item.id}`}
            key={index}
            value={item.id}
            onClick={() => handleOnClick(item.id)}
          >
            {renderItemContent(item)}
          </MenuItem>
        ))}
      </Popover>
    </Box>
  );
};
