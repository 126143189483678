import { useState } from "react";
import { useHistory } from "react-router";
import { navigationService } from "../../../../services/navigation.service";
import { NoteModel, NotesType } from "../../models/notes.models";
import { notesService } from "../../services/notes.service";
import {GetMaximumVersionOfNote, handleDownloadNoteByPatientPdfBd} from "../../helpers/noteHelpers";
import {useFlags} from "launchdarkly-react-client-sdk";

export interface NoteCardComponentState {
    isDownloading: boolean;
}

export function useFacade(): [NoteCardComponentState, (note: NoteModel) => void, (note: NoteModel) => void] {
    const history = useHistory();
    const [state, setState] = useState({
        isDownloading: false
    } as NoteCardComponentState);

    const flags = useFlags();

    const handleViewDetails = (note: NoteModel) => {

        switch (note.type) {
            case NotesType.Initial: return navigationService.toPatientInitialConsult(history, note.id);
            case NotesType.FollowUp: return navigationService.toPatientFollowUp(history, note.id);
            case NotesType.Blank: return navigationService.toPatientBlank(history, note.id);
            case NotesType.HistoryAndPhysicalInitial: return navigationService.toPatientHistoryAndPhysical(history, note.id);
            case NotesType.HistoryAndPhysicalFollowUp: return navigationService.toPatientHistoryAndPhysicalFollowup(history, note.id);
            case NotesType.HistoryAndPhysicalGroupVisit: return navigationService.toPatientHistoryAndPhysicalGroupVisit(history, note.id);
            case NotesType.Soap: return navigationService.toPatientSoap(history, note.id);
            default: return;
        }
    }

    const handleDownloadPdf = (note: NoteModel) => {
        setState({ ...state, isDownloading: true });
        const cb = () => setState({ ...state, isDownloading: false });
        var n = GetMaximumVersionOfNote(note);
        notesService.downloadNote(n.id, n.name, false).subscribe(
            cb,
            () => {
                handleDownloadNoteByPatientPdfBd(n, flags).subscribe(cb, cb);
            }
        )
    }


    return [state, handleViewDetails, handleDownloadPdf];
}