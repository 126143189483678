import {
    DetailedQuestionNames
} from '../static/detailedQuestionnaire.static';
import { MedicalHistoryAndLifestyleQuestionNames } from '../static/medicalHistroryQuestionnaire.static';
import { HealthGoalsQuestionNames } from '../static/healthGoalsQuestionnaire.static';
import {QuestionnaireModel, AnswerModel, QuestionnaireResultModel} from '../models/questionnaire.models';
import { QuestionnaireMedicationsComponent } from '../components/medicalHistoryQuestionnaireSurveyComonent/QuestionnaireMedicationsComponent';
import { QuestionnaireSupplementsComponent } from '../components/medicalHistoryQuestionnaireSurveyComonent/QuestionnaireSupplementsComponent';
import { QuestionnaireAllergiesComponent } from '../components/medicalHistoryQuestionnaireSurveyComonent/QuestionnaireAllergiesComponent';
import { DeselectParentQuestionComponent } from '../components/detailedQuestionnaireSurveyComponent/DeselectParentQuestionComponent';
import { HideCommentQuestionComponent } from '../components/healthGoalsQuestionnaireSurveyComponent/HideCommentQuestionComponent';
import React from 'react';
import {Phq8SectionComponent} from "../components/phq8SectionComponent/Phq8SectionComponent";

export const getSpecificQuestions = (
    handleChanges: (field: string, value: string) => void,
    questionnaire: QuestionnaireModel,
    questionnaireResult: QuestionnaireResultModel,
    answers: AnswerModel[],
    errors: any,
    patientId?: number,
) => {
    return {
        [MedicalHistoryAndLifestyleQuestionNames.MEDICATIONS]: <QuestionnaireMedicationsComponent
            question={questionnaire.questions.find(x => x.name === MedicalHistoryAndLifestyleQuestionNames.MEDICATIONS)}
            handleChanges={handleChanges}
            patientId={patientId}
        />,
        [MedicalHistoryAndLifestyleQuestionNames.SUPPLEMENTS]: <QuestionnaireSupplementsComponent
            question={questionnaire.questions.find(x => x.name === MedicalHistoryAndLifestyleQuestionNames.SUPPLEMENTS)}
            handleChanges={handleChanges}
            patientId={patientId}
        />,
        [MedicalHistoryAndLifestyleQuestionNames.ALLERGIES]: <QuestionnaireAllergiesComponent
            question={questionnaire.questions.find(x => x.name === MedicalHistoryAndLifestyleQuestionNames.ALLERGIES)}
            handleChanges={handleChanges}
            patientId={patientId}
        />,
        [DetailedQuestionNames.HIGH_BLOOD_PRESSURE_YEAR]: <DeselectParentQuestionComponent
            questionName={DetailedQuestionNames.HIGH_BLOOD_PRESSURE_YEAR}
            questionnaire={questionnaire}
            handleChanges={handleChanges}
            answers={answers}
            errors={errors}
        />,
        [DetailedQuestionNames.PULMONARY_EMBOLISM_YEAR]: <DeselectParentQuestionComponent
            questionName={DetailedQuestionNames.PULMONARY_EMBOLISM_YEAR}
            questionnaire={questionnaire}
            handleChanges={handleChanges}
            answers={answers}
            errors={errors}
        />,
        [DetailedQuestionNames.DEEP_VIEN_THROMBOSIS_YEAR]: <DeselectParentQuestionComponent
            questionName={DetailedQuestionNames.DEEP_VIEN_THROMBOSIS_YEAR}
            questionnaire={questionnaire}
            handleChanges={handleChanges}
            answers={answers}
            errors={errors}
        />,
        [DetailedQuestionNames.CAROTID_STENOSIS_YEAR]: <DeselectParentQuestionComponent
            questionName={DetailedQuestionNames.CAROTID_STENOSIS_YEAR}
            questionnaire={questionnaire}
            handleChanges={handleChanges}
            answers={answers}
            errors={errors}
        />,
        [DetailedQuestionNames.CORONARY_ARTERY_DISEASE_YEAR]: <DeselectParentQuestionComponent
            questionName={DetailedQuestionNames.CORONARY_ARTERY_DISEASE_YEAR}
            questionnaire={questionnaire}
            handleChanges={handleChanges}
            answers={answers}
            errors={errors}
        />,
        [DetailedQuestionNames.HEART_ATTACK_YEAR]: <DeselectParentQuestionComponent
            questionName={DetailedQuestionNames.HEART_ATTACK_YEAR}
            questionnaire={questionnaire}
            handleChanges={handleChanges}
            answers={answers}
            errors={errors}
        />,
        [DetailedQuestionNames.CONGESTIVE_HEART_FAILURE_YEAR]: <DeselectParentQuestionComponent
            questionName={DetailedQuestionNames.CONGESTIVE_HEART_FAILURE_YEAR}
            questionnaire={questionnaire}
            handleChanges={handleChanges}
            answers={answers}
            errors={errors}
        />,
        [DetailedQuestionNames.HIGH_CHOLESTEROL_YEAR]: <DeselectParentQuestionComponent
            questionName={DetailedQuestionNames.HIGH_CHOLESTEROL_YEAR}
            questionnaire={questionnaire}
            handleChanges={handleChanges}
            answers={answers}
            errors={errors}
        />,
        [DetailedQuestionNames.BLOOD_CLOTTING_DISORDER_YEAR]: <DeselectParentQuestionComponent
            questionName={DetailedQuestionNames.BLOOD_CLOTTING_DISORDER_YEAR}
            questionnaire={questionnaire}
            handleChanges={handleChanges}
            answers={answers}
            errors={errors}
        />,
        [DetailedQuestionNames.ANEURYSM_YEAR]: <DeselectParentQuestionComponent
            questionName={DetailedQuestionNames.ANEURYSM_YEAR}
            questionnaire={questionnaire}
            handleChanges={handleChanges}
            answers={answers}
            errors={errors}
        />,
        [DetailedQuestionNames.CARDIAC_VALVE_PROBLEM_YEAR]: <DeselectParentQuestionComponent
            questionName={DetailedQuestionNames.CARDIAC_VALVE_PROBLEM_YEAR}
            questionnaire={questionnaire}
            handleChanges={handleChanges}
            answers={answers}
            errors={errors}
        />,
        [DetailedQuestionNames.AFIB_YEAR]: <DeselectParentQuestionComponent
            questionName={DetailedQuestionNames.AFIB_YEAR}
            questionnaire={questionnaire}
            handleChanges={handleChanges}
            answers={answers}
            errors={errors}
        />,
        [DetailedQuestionNames.BREAST_CANCER_YEAR]: <DeselectParentQuestionComponent
            questionName={DetailedQuestionNames.BREAST_CANCER_YEAR}
            questionnaire={questionnaire}
            handleChanges={handleChanges}
            answers={answers}
            errors={errors}
        />,
        [DetailedQuestionNames.PROSTATE_CANCER_YEAR]: <DeselectParentQuestionComponent
            questionName={DetailedQuestionNames.PROSTATE_CANCER_YEAR}
            questionnaire={questionnaire}
            handleChanges={handleChanges}
            answers={answers}
            errors={errors}
        />,
        [DetailedQuestionNames.LUNG_CANCER_YEAR]: <DeselectParentQuestionComponent
            questionName={DetailedQuestionNames.LUNG_CANCER_YEAR}
            questionnaire={questionnaire}
            handleChanges={handleChanges}
            answers={answers}
            errors={errors}
        />,
        [DetailedQuestionNames.COLON_CANCER_YEAR]: <DeselectParentQuestionComponent
            questionName={DetailedQuestionNames.COLON_CANCER_YEAR}
            questionnaire={questionnaire}
            handleChanges={handleChanges}
            answers={answers}
            errors={errors}
        />,
        [DetailedQuestionNames.BONE_CANCER_YEAR]: <DeselectParentQuestionComponent
            questionName={DetailedQuestionNames.BONE_CANCER_YEAR}
            questionnaire={questionnaire}
            handleChanges={handleChanges}
            answers={answers}
            errors={errors}
        />,
        [DetailedQuestionNames.LIVER_CANCER_YEAR]: <DeselectParentQuestionComponent
            questionName={DetailedQuestionNames.LIVER_CANCER_YEAR}
            questionnaire={questionnaire}
            handleChanges={handleChanges}
            answers={answers}
            errors={errors}
        />,
        [DetailedQuestionNames.KIDNEY_CANCER_YEAR]: <DeselectParentQuestionComponent
            questionName={DetailedQuestionNames.KIDNEY_CANCER_YEAR}
            questionnaire={questionnaire}
            handleChanges={handleChanges}
            answers={answers}
            errors={errors}
        />,
        [DetailedQuestionNames.BAIN_CANCER_YEAR]: <DeselectParentQuestionComponent
            questionName={DetailedQuestionNames.BAIN_CANCER_YEAR}
            questionnaire={questionnaire}
            handleChanges={handleChanges}
            answers={answers}
            errors={errors}
        />,
        [DetailedQuestionNames.SKIN_CANCER_YEAR]: <DeselectParentQuestionComponent
            questionName={DetailedQuestionNames.SKIN_CANCER_YEAR}
            questionnaire={questionnaire}
            handleChanges={handleChanges}
            answers={answers}
            errors={errors}
        />,
        //
        [DetailedQuestionNames.APPENDECTOMY_SURGERY_YEAR]: <DeselectParentQuestionComponent
            questionName={DetailedQuestionNames.APPENDECTOMY_SURGERY_YEAR}
            questionnaire={questionnaire}
            handleChanges={handleChanges}
            answers={answers}
            errors={errors}
        />,
        [DetailedQuestionNames.CORONARY_ARTERY_BYPASS_SURGERY_YEAR]: <DeselectParentQuestionComponent
            questionName={DetailedQuestionNames.CORONARY_ARTERY_BYPASS_SURGERY_YEAR}
            questionnaire={questionnaire}
            handleChanges={handleChanges}
            answers={answers}
            errors={errors}
        />,
        [DetailedQuestionNames.CAROTID_ENDARTERECTOMY_SURGERY_YEAR]: <DeselectParentQuestionComponent
            questionName={DetailedQuestionNames.CAROTID_ENDARTERECTOMY_SURGERY_YEAR}
            questionnaire={questionnaire}
            handleChanges={handleChanges}
            answers={answers}
            errors={errors}
        />,
        [DetailedQuestionNames.CHOLECYSTECTOMY_SURGERY_YEAR]: <DeselectParentQuestionComponent
            questionName={DetailedQuestionNames.CHOLECYSTECTOMY_SURGERY_YEAR}
            questionnaire={questionnaire}
            handleChanges={handleChanges}
            answers={answers}
            errors={errors}
        />,
        [DetailedQuestionNames.BARIATRIC_SURGERY_YEAR]: <DeselectParentQuestionComponent
            questionName={DetailedQuestionNames.BARIATRIC_SURGERY_YEAR}
            questionnaire={questionnaire}
            handleChanges={handleChanges}
            answers={answers}
            errors={errors}
        />,
        [DetailedQuestionNames.HYSTERECTOMY_SURGERY_YEAR]: <DeselectParentQuestionComponent
            questionName={DetailedQuestionNames.HYSTERECTOMY_SURGERY_YEAR}
            questionnaire={questionnaire}
            handleChanges={handleChanges}
            answers={answers}
            errors={errors}
        />,
        [DetailedQuestionNames.NEPHRECTOMY_SURGERY_YEAR]: <DeselectParentQuestionComponent
            questionName={DetailedQuestionNames.NEPHRECTOMY_SURGERY_YEAR}
            questionnaire={questionnaire}
            handleChanges={handleChanges}
            answers={answers}
            errors={errors}
        />,
        [DetailedQuestionNames.SPLENECTOMY_SURGERY_YEAR]: <DeselectParentQuestionComponent
            questionName={DetailedQuestionNames.SPLENECTOMY_SURGERY_YEAR}
            questionnaire={questionnaire}
            handleChanges={handleChanges}
            answers={answers}
            errors={errors}
        />,
        [DetailedQuestionNames.TONSILLECTOMY_SURGERY_YEAR]: <DeselectParentQuestionComponent
            questionName={DetailedQuestionNames.TONSILLECTOMY_SURGERY_YEAR}
            questionnaire={questionnaire}
            handleChanges={handleChanges}
            answers={answers}
            errors={errors}
        />,
        [DetailedQuestionNames.PHQ8_1]: <Phq8SectionComponent
            questions={[
                DetailedQuestionNames.PHQ8_1,
                DetailedQuestionNames.PHQ8_2,
                DetailedQuestionNames.PHQ8_3,
                DetailedQuestionNames.PHQ8_4,
                DetailedQuestionNames.PHQ8_5,
                DetailedQuestionNames.PHQ8_6,
                DetailedQuestionNames.PHQ8_7,
                DetailedQuestionNames.PHQ8_8
            ]}
            questionnaire={questionnaire}
            questionnaireResult={questionnaireResult}
            handleChanges={handleChanges}
            errors={errors}
        />,
        [HealthGoalsQuestionNames.TAKE_TIME_TO_WALK_OUTSIDE_COMMENT]: <HideCommentQuestionComponent
            questionName={HealthGoalsQuestionNames.TAKE_TIME_TO_WALK_OUTSIDE_COMMENT}
            questionnaire={questionnaire}
            handleChanges={handleChanges}
            answers={answers}
            errors={errors}
        />,
    }
}