import {useEffect, useState} from 'react';
import {PatientModel, UpdatePatientModel} from "../../../patients/models/patient.model";
import {patientInformationComponentValidator} from "./patientInformationComponent.validator";
import {getLastObject} from "../../../common/helpers/get-last-object";
import {patientsService} from "../../../patients/services/patients.service";
import {employeeService} from "../../services/employees.service";
import {IErrorState} from '../../../common/validation/error-state';
import {Subscription} from 'recompose';
import {patientsQuery} from '../../../patients/stores/patientsStore';
import {onEmit} from '../../../common/helpers/on-emit';
import {employeesQuery} from "../../stores/employeesStore";
import { EmployeeShortModel } from '../../models/employee.models';
import { LeadSourceModel } from '../../../leadSources/models/leadSource.models';
import { leadSourcesQuery } from '../../../leadSources/stores/leadSources.query';
import { leadSourcesService } from '../../../leadSources/services/leadSources.service';
import {handleCompare} from "../../../common/sorting/helpers/handle-compare";
import {SortingDirection} from "../../../common/sorting/models/sorting-destination";

interface PatientInformationComponentState extends IErrorState {
    isLoading: boolean;
    isEmployeesLoading: boolean;
    patient: PatientModel,
    patientDraft: PatientModel,
    employees: EmployeeShortModel[],
    editMode: boolean,
    leadSources: LeadSourceModel[]
}

const pageFields = [
    'firstName',
    'lastName',
    'birthday',
    'gender',
    'email',
    'phoneNumber',
    'billingAddress.streetAddress1',
    'billingAddress.streetAddress2',
    'billingAddress.city',
    'billingAddress.state',
    'billingAddress.zipCode',
    'billingAddress.country',
    'shippingAddress.streetAddress1',
    'shippingAddress.streetAddress2',
    'shippingAddress.city',
    'shippingAddress.state',
    'shippingAddress.zipCode',
    'shippingAddress.country',
    'assignedEmployeeIds',
    'leadSource.otherLeadSource'
];

export function useFacade(patientId: number): [
    PatientInformationComponentState,
    (id: number) => void,
    (field: string, value: string) => void,
    (field: string, value: boolean) => void,
    () => void,
    () => void,
    () => void,
    (field: string, value: any) => void] {

    const [state, setState] = useState({
        isLoading: true,
        isEmployeesLoading: true,
        patient: null,
        patientDraft: {},
        employees: [],
        errors: {},
        editMode: false,
        leadSources: []
    } as PatientInformationComponentState);

    const handleEmployeeSelect = (id: number) => {
        const draftPatient = state.patientDraft;

        if (draftPatient.assignedEmployees.map(el => el.id).includes(id)) {
            draftPatient.assignedEmployees = draftPatient.assignedEmployees.filter(x => x.id !== id);
        } else {
            draftPatient.assignedEmployees = [...draftPatient.assignedEmployees, state.employees.find(el => el.id === id)];
        }
        
        setState({
            ...state,
            patientDraft: {...draftPatient}
        });

        patientInformationComponentValidator.validateAndSetState(state, setState, 'assignedEmployeeIds', draftPatient.assignedEmployees.map(el => el.id));
    }

    const handleOptionsChanges = (field: string, value: boolean) => {
        const draftPatient = state.patientDraft;
        draftPatient.options[field] = value;
        setState({
            ...state,
            patientDraft: {...draftPatient}
        });
    }

    const handleLeadSourceChanges = (field: string, value: any) => {
        patientInformationComponentValidator.validateAndSetState(state, setState, field, value)
        if(state.patientDraft.leadSource === null)
            state.patientDraft.leadSource = {leadSourceId: 0, otherLeadSource: null, podcastSource: null};

        const patientDraft = state.patientDraft;
        const keys = field.split(".");
        const key = keys.pop();
        const lastObject = getLastObject(keys, patientDraft);
        lastObject[key] = key === "leadSourceId" ? +value : value;

        setState({...state, patientDraft: patientDraft});
    }

    const shouldValidateLeadSource = (): boolean => {
        if(state.patientDraft.leadSource === null) {
            return false;
        }

        const leadSource = state.leadSources.find(x => x.id === state.patientDraft.leadSource.leadSourceId);

        if(leadSource === undefined) {
            return false;
        }


        return leadSource.isOther;
    }

    /**
     * Validates patient profile form
     */
    const validateForm = () => {
        pageFields.forEach(field => {
            if(field === 'leadSource.otherLeadSource' && !shouldValidateLeadSource())
                return;

            const keys = field.split(".");
            const key = keys.pop();
            const lastObject = getLastObject(keys, state.patientDraft);
            const value = lastObject[key];
            patientInformationComponentValidator.validateAndSetState(state, setState, field, value);
        });
    }

    /**
     * Handles changes. Additionally does field validation
     * @param field
     * @param value
     */
    const handleChanges = (field: string, value: string) => {
        patientInformationComponentValidator.validateAndSetState(state, setState, field, value);

        const patientDraft = state.patientDraft;
        const keys = field.split(".");
        const key = keys.pop();
        const lastObject = getLastObject(keys, patientDraft);
        lastObject[key] = value;

        setState({...state, patientDraft: {...patientDraft}});
    }

    const handleSubmit = () => {
        validateForm();
        if (!patientInformationComponentValidator.stateIsValid(state)) {
            return;
        }

        setState(state => ({ ...state, isLoading: true }));

        const model: UpdatePatientModel = {
            id: state.patientDraft.id,
            firstName: state.patientDraft.firstName,
            lastName: state.patientDraft.lastName,
            email: state.patientDraft.email,
            phoneNumber: state.patientDraft.phoneNumber,
            birthday: state.patientDraft.birthday,
            gender: state.patientDraft.gender,
            billingAddress: state.patientDraft.billingAddress,
            shippingAddress: state.patientDraft.shippingAddress,
            employeeIds: state.patientDraft.assignedEmployees.map(el => el.id),
            options: state.patientDraft.options,
            leadSource: state.patientDraft.leadSource?.leadSourceId === 0 ? null : state.patientDraft.leadSource,
            locationId: state.patient.locationId
        };

        patientsService.update(model).subscribe(
            () => handleEditModeToggle(),
            () => handleEditModeToggle()
        );
    }

    const handleEditModeToggle = () => {
        setState(state => ({
            ...state, 
            editMode: !state.editMode,
            isLoading: false
        }));
    }

    const handleDiscardChanges = () => {
        setState({
            ...state,
            errors: {},
            patientDraft: {
                ...state.patient,
                billingAddress: {...state.patient.billingAddress},
                shippingAddress: {...state.patient.shippingAddress},
                options: {...state.patient.options}
            },
            editMode: false,
        });
    }

    /**
     * Load all ongoing cares
     * Manage subscriptions with auto-cleanup
     */
    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<PatientModel>(patientsQuery.targetPatient$, targetPatient => {
                if (targetPatient?.id === Number(patientId)) {
                    setState(state => ({
                        ...state,
                        isLoading: false,
                        patient: targetPatient,
                        patientDraft: {
                            ...targetPatient,
                            billingAddress: {...targetPatient?.billingAddress},
                            shippingAddress: {...targetPatient?.shippingAddress},
                            options: {...targetPatient.options},
                            leadSource: targetPatient?.leadSource == null ? null : {...targetPatient?.leadSource} 
                        }
                    }));

                    const cb = () => setState(state => ({...state, isEmployeesLoading: false}));

                    employeeService.getActiveCoachesAndProviders(targetPatient.locationId).subscribe(cb, cb);
                }
            }),
            onEmit<EmployeeShortModel[]>(employeesQuery.couchesAndProviders$, employees => {
                setState(state => ({
                    ...state, employees
                }));
            }),
            onEmit<LeadSourceModel[]>(leadSourcesQuery.leadSources$, leadSources => {
                setState(state => ({
                    ...state,
                    leadSources:leadSources.sort((l1, l2,) => handleCompare(l1, l2, SortingDirection.Desc, 'isOther'))
                }))
            }),
        ];

        leadSourcesService.getEmployeeActive();

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [patientId]);

    return [state,
        handleEmployeeSelect,
        handleChanges,
        handleOptionsChanges,
        handleSubmit,
        handleEditModeToggle,
        handleDiscardChanges,
        handleLeadSourceChanges
    ]
}