import React, { useState } from 'react';
import { Box, Divider, IconButton, List, ListItem, useTheme, useMediaQuery } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import GetAppIcon from '@material-ui/icons/GetApp';
import moment from 'moment';
import { MessagesPlaceHolder } from '../../../common/components/wildHealthPlaceHolder/MessagesPlaceHolder';
import { useStyles } from "../internalThreadComponent/internalThreadComponent.styles";
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { ConversationAuthorModel, ConversationModelBaseView } from '../../models/conversation.models';
import { employeeTypesNames } from '../../../employee/models/employee.enums';
import { toCurrentTimeZone } from '../../../timezones/helpers/timezone';
import { conversationsService } from '../../services/conversations.service';

interface InternalThreadViewComponentProps {
    isLoadingMessages: boolean;
    messages: ConversationModelBaseView[];
    scrollToIndex?: number;
    participants: any[];
    author: ConversationAuthorModel;
}

export const InternalThreadViewComponent: React.FC<InternalThreadViewComponentProps> = (props: InternalThreadViewComponentProps) => {
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const {
        isLoadingMessages,
        messages,
        scrollToIndex = -1,
        participants,
    } = props;

    const [scrollTo, setScrollTo] = useState(null);


    const handleDownloadAttachment = (sid: string) => {
        const model = { mediaSid: sid }
        conversationsService.createMediaUrl(model).subscribe((data: string) => {
            window.open(data)
        })
    }

    if (scrollTo && scrollToIndex >= 1) {
        scrollTo.scrollIntoView();
    }

    const ref = (element) => setScrollTo(element);

    const renderMessages = (messages: any) => {
        if (messages && messages.length) {
            const items = messages.map(message => {
                const author = participants.find(x => x.vendorExternalIdentity === message.author);
                const messageIndex = message.index;
    
                return (
                    [
                        <>
                            <div ref={messageIndex === scrollToIndex ? ref : null} />
                            <ListItem key={messageIndex} id={`message-${messageIndex}`}>
                                <Box id={`message-${messageIndex}`} px={!isSmallScreen && 4} width='100%' justifyContent='space-between' className={isSmallScreen ? "wh-tw-flex" : "wh-tw-block"}>
                                    <Box mt={2}>
                                        <Box className={classes.author}>
                                            <Box>
                                                {author ? `${author.firstName} ${author.lastName}` : 'The author is not specified.'}
                                            </Box>
                                            {isSmallScreen ?
                                                <Box display='flex' ml={2}>
                                                    <Box className={classes.messageDate}>
                                                        {`${moment(toCurrentTimeZone(message.dateCreated)).format('MMM D, yyyy')} ${moment(message.dateCreated).format('hh:mm a')}`}
                                                    </Box>
                                                </Box>
                                                :
                                                <Box ml={1} className={classes.position}>
                                                    {author && `- ${employeeTypesNames.get(author.type)}`}
                                                </Box>
                                            }
                                        </Box>
                                        <Box className={classes.message}>
                                            {
                                                message?.type === 'media'
                                                    ? <Box display='flex' alignItems='center' className={classes.breakText}>
                                                        <IconButton id="message-download-media" className={classes.fileButton}
                                                            onClick={() => handleDownloadAttachment(message.media[0].sid)} size='small'>
                                                            <DescriptionIcon className={classes.fileIcon} />
                                                            <GetAppIcon className={classes.downloadIcon} />
                                                        </IconButton>
    
                                                        <Box ml={1}>{message.media ? message.media[0].filename : message.body}</Box>
                                                    </Box>
                                                    : `${message.body}`
                                            }
                                        </Box>
                                    </Box>
                                    {!isSmallScreen &&
                                        <Box display='flex' >
                                            <Box className={classes.messageDate}>
                                                {`${moment(toCurrentTimeZone(message.dateCreated)).format('MMM D, yyyy')} ${moment(message.dateCreated).format('hh:mm a')}`}
                                            </Box>
                                        </Box>
                                    }
                                </Box>
                            </ListItem>
                        </>,
                        <Box mx={4}>
                            <Divider className={classes.messageDivider} />
                        </Box>
                    ]
                )
            }).flat();
    
            items.pop();
    
            return items;
        } else {
            return (
                <Box height='100%' width="50vh" margin="0 auto">
                    <MessagesPlaceHolder message="No messages yet." />
                </Box>
            )
        }
    }

    return (
        <List id='thread-list' className={classes.root}>
            <Box p={3} px={4} height='4px'>
                {isLoadingMessages && <WildHealthLinearProgress />}
            </Box>
            {!isLoadingMessages && renderMessages(messages)}
        </List>
    )
};
