import Axios from 'axios-observable';
import { authHeader } from "../../common/helpers/auth-header";
import { snackService } from "../../common/snack/state";
import { inputsStore, InputsStore } from "../stores/inputsStore";
import {
    ApoeModel,
    FileInputDataProvider,
    FileInputModel,
    FileInputType,
    GeneralInputsModel, LabInputModel,
    MesaModel,
    MicrobiomeInputModel,
    UpdateLabInputsModel,
    UploadFileInputModel
} from "../models/input.models";
import { Observable, Subject } from "rxjs";
import { CallbackModel } from "../../common/models/callback.model";
const FileDownload = require('js-file-download');

/**
 * Provides method for working with inputs
 */
export class InputsService {
    private urlInputs = `${process.env.REACT_APP_API_URL}Inputs`;
    private urlUploads = `${this.urlInputs}/Uploads`;
    private urlGeneral = `${this.urlInputs}/General`;
    private urlLab = `${this.urlInputs}/Lab`;
    private urlMicrobiome = `${this.urlInputs}/microbiome`;
    private urlApoe = `${this.urlInputs}/apoe`;
    private urlMesa = `${this.urlInputs}/mesa`;

    constructor(private inputsStore: InputsStore) {
    }

    public onUpload = new Subject<CallbackModel<FileInputType>>();

    /**
     * Returns all patients uploads
     */
    public getUploads(patientId: number) {
        const config = { headers: authHeader() };

        return new Observable(observer => {
            Axios.get(`${this.urlUploads}?patientId=${patientId}`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.inputsStore.update({ fileInputs: response.data });
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Downloads uploaded file
     */
    public download(input: FileInputModel, patientId: number): Observable<any> {
        return new Observable(observer => {
            Axios.get(`${this.urlUploads}/${input.id}/download?patientId=${patientId}`, {
                headers: authHeader(),
                responseType: 'blob',
            })
                .pipe()
                .subscribe(
                    (response) => {
                        FileDownload(response.data, `${input.file.name}`);
                        snackService.success('File successfully downloaded!');
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Views uploaded file
     */
    public viewinline(input: FileInputModel, patientId: number): Observable<any> {
        return new Observable(observer => {
            Axios.get(`${this.urlUploads}/${input.id}/viewinline?patientId=${patientId}`, {
                headers: authHeader(),
                responseType: 'blob',
            })
                .pipe()
                .subscribe(
                    (response) => {
                        const file = new Blob([response.data], { type: input.file.mediaType });

                        const fileURL = URL.createObjectURL(file);

                        window.open(fileURL, '_blank');

                        snackService.success('File successfully opened!');
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Returns all patients uploads
     */
    public upload(type: FileInputType, patientId: number): Observable<FileInputModel> {
        return new Observable(observer => {
            this.onUpload.next({
                data: type,
                callback: (file: any, dataProvider: FileInputDataProvider) => {
                    if (!file) {
                        observer.error();
                        observer.complete();
                        return;
                    }

                    const model: UploadFileInputModel = {
                        file: file,
                        type: type,
                        dataProvider: dataProvider,
                    };

                    const formData = new FormData();
                    formData.append('file', model.file);
                    formData.append('type', model.type.toString());
                    formData.append('dataProvider', model.dataProvider.toString());

                    Axios.post(`${this.urlUploads}?patientId=${patientId}`, formData, { headers: authHeader() })
                        .pipe()
                        .subscribe(
                            (response) => {
                                this.inputsStore.addFileInput(response.data);
                                snackService.success('File successfully uploaded!');
                                observer.next(response.data);
                                observer.complete();
                            },
                            error => {
                                snackService.commonErrorHandler(error);
                                observer.error(error);
                                observer.complete();
                            }
                        );
                }
            });
        });
    }

    /**
     * Deletes upload
     */
    public deleteUpload(id: number, patientId: number): void {
        Axios.delete(`${this.urlUploads}/${id}/?patientId=${patientId}`, { headers: authHeader() })
            .pipe()
            .subscribe(
                (response) => {
                    this.inputsStore.deleteFileInput(response.data);
                    snackService.success('File successfully deleted!');
                },
                error => snackService.commonErrorHandler(error)
            );
    }


    public getGeneralInputs(patientId: number): void {
        Axios.get(`${this.urlGeneral}?patientId=${patientId}`, { headers: authHeader() })
            .pipe()
            .subscribe(
                (response) => {
                    this.inputsStore.update({ generalInputs: response.data });
                },
                error => snackService.commonErrorHandler(error)
            );
    }

    public updateGeneralInputs(inputs: GeneralInputsModel, patientId: number): Observable<any> {
        const config = { headers: authHeader() };
        return new Observable(observer => {
            Axios.put(`${this.urlGeneral}?patientId=${patientId}`, inputs, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.inputsStore.update({ generalInputs: response.data });
                        snackService.success('Inputs successfully saved!');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Returns lab inputs
     */
    public getLabInputs(patientId: number): Observable<any> {

        return new Observable(observer => {
            Axios.get(`${this.urlLab}?patientId=${patientId}`, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.inputsStore.update({ labInputs: response.data });
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        })
    }

    /**
     * Updates patient inputs
     */
    public updateLabInputs(model: UpdateLabInputsModel, patientId: number): Observable<LabInputModel[]> {
        return new Observable(observer => {
            Axios.put<LabInputModel[]>(`${this.urlLab}?patientId=${patientId}`, model, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        inputsStore.setLabIntputs(response.data);
                        snackService.success('Inputs successfully saved!');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    /**
     * Delete patient inputs
     */
    public deleteLabInputs(datasetId: string, patientId: number): Observable<LabInputModel[]> {
        return new Observable(observer => {
            Axios.delete<LabInputModel[]>(`${this.urlLab}/bulk?patientId=${patientId}&datasetId=${datasetId}`, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        inputsStore.setLabIntputs(response.data);
                        snackService.success('Inputs successfully deleted!');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    /**
     * Updates patient input highlights
     */
    public UpdateLabInputHighlights(id: number, patientId: number, isActive: boolean): Observable<LabInputModel> {
        return new Observable(observer => {
            Axios.put<LabInputModel>(`${this.urlLab}/${id}/highlights/${isActive}?patientId=${patientId}`, null, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        inputsStore.updateLabIntputs(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    /**
     * Returns microbiome inputs
     */
    public getMicrobiomeInputs(patientId: number): Observable<void> {
        return new Observable(observer => {
            Axios.get(`${this.urlMicrobiome}?patientId=${patientId}`, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.inputsStore.update({ microbiomeInputs: response.data });
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Updates microbiome inputs
     */
    public updateMicrobiomeInputs(models: MicrobiomeInputModel[], patientId: number): Observable<void> {
        const changedInputs = models.filter(x => x.isChanged);

        return new Observable(observer => {
            Axios.put(`${this.urlMicrobiome}?patientId=${patientId}`, changedInputs, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.inputsStore.update({ microbiomeInputs: response.data });
                        observer.next();
                        observer.complete();
                        snackService.success('Inputs successfully saved!');
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public getMesaScore(patientId: number): Observable<MesaModel> {
        return new Observable(observer => {
            Axios.get(`${this.urlMesa}?patientId=${patientId}`, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.inputsStore.update({ mesaScore: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.error(error);
                        observer.complete();
                    }
                    
                );
            }
        );
    }

    
    /**
     * APOE info
     */
    public getApoe(patientId: number): Observable<ApoeModel> {
        return new Observable(observer => {
            Axios.get<ApoeModel>(`${this.urlApoe}?patientId=${patientId}`, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.inputsStore.update({ apoeInfo: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

     /**
    * Update APOE
    */
     public updateApoeInfo(patientId: number, model: ApoeModel): Observable<ApoeModel> {
        var body = model ?? {}

        return new Observable(observer => {
            Axios.post(`${this.urlApoe}?patientId=${patientId}`, body, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.inputsStore.update({ apoeInfo: response.data });
                        observer.next();
                        observer.complete()
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete()
                    }
                );
        })

    }

}

export const inputsService = new InputsService(inputsStore);
