import React from 'react';
import {Box, Tooltip} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import warning from "@img/icons/warning.svg";
import clsx from 'clsx';
import { useStyles } from "./LabInputValueComponent.styles";
import {LabInputNotificationType, LabInputValueModel} from "../../models/input.models";
import {renderEmptyValue} from "../../../common/helpers/empty-value";

interface LabInputValueComponentProps {
    inputId: number;
    value: LabInputValueModel;
    onMouseOverValue?: (event: React.MouseEvent<HTMLElement, MouseEvent>, id: number) => void;
    onMouseOutValue?: (id: number) => void;
}

export const LabInputValueComponent: React.FC<LabInputValueComponentProps> = (props: LabInputValueComponentProps) => {
    const {
        value,
    } = props;

    const classes = useStyles();

    const customTooltipClasses = {
        tooltip: classes.customWidth,
        arrow: classes.arrow,
        tooltipPlacementBottom: classes.tooltipPlacement,
        tooltipPlacementTop: classes.tooltipPlacement,
    };

    const outOfNorm = value.notification?.notificationType === LabInputNotificationType.Error;

    const renderNotificationImage = (notificationType: LabInputNotificationType) => {
        switch (notificationType) {
            case LabInputNotificationType.Empty: return <></>;
            case LabInputNotificationType.Error: return <img src={warning} alt="img"/>;
            case LabInputNotificationType.Warning: return <WarningIcon className="wh-tw-text-average1" />;
        }
    }

    return (
        <Box height="100%">
            {
                value.isInitialized
                ? <Box display="flex" justifyContent="center">
                    <Tooltip placement="top" arrow classes={customTooltipClasses} title={outOfNorm ? 'Out of Range' : 'In Range'}>
                        <Box display="flex" alignItems="center" justifyContent="center" className={clsx(outOfNorm && "wh-tw-w-fit wh-tw-rounded-full wh-tw-border wh-tw-border-error wh-tw-border-solid wh-tw-px-2 wh-tw-py-0.5")}>
                            <Box>
                                <span className={clsx(outOfNorm && "wh-tw-text-bad")}>
                                    {
                                        value.value
                                    }
                                </span>
                            </Box>
                            {
                                outOfNorm &&
                                <Box ml={1}>
                                    {
                                        renderNotificationImage(value.notification?.notificationType)
                                    }
                                </Box>
                            }
                        </Box>
                    </Tooltip>
                </Box>
                : <Tooltip placement="top" arrow classes={customTooltipClasses} title="No Result">
                    <Box>
                        {renderEmptyValue("-")}
                    </Box>
                </Tooltip>
            }
        </Box>
    )
}