import React from 'react';
import { PatientCourseHistoryComponent } from '../../components/patientCourseHistory/PatientCourseHistoryComponent';
import { AuthenticatedLayoutComponent } from '../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from '../../../../models/menu.models';
import { withRouter } from 'react-router';

export const PatientNorthPassAcademyPage: React.FC = () => {
  return (
    <>
      <AuthenticatedLayoutComponent
        title={TitlesService.academyTitle}
        pageName={PagesNamesService.academy}
        selectedMenuItem={MenuItemTypes.CourseHistory}
        hideBackground
      >
        <PatientCourseHistoryComponent />
      </AuthenticatedLayoutComponent>
    </>
  );
}

export default withRouter(PatientNorthPassAcademyPage);
