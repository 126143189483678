import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            color: colors.systemBlack,
            fontSize: '2rem',
            fontFamily: 'Be Vietnam Pro',
            fontWeight: 400,
            lineHeight: 1.334,
        },
        card: {
            boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.03)",
            display: "inline-block",
            borderRadius: "4px",
            width: '100%',
            [theme.breakpoints.down("xs")]: {
                padding: 20,
                marginBottom: 10
            },
        },
        cardDivider: {
            backgroundColor: colors.bg,
            margin: 'auto',
            height: 2,
            [theme.breakpoints.down("xs")]: {
                backgroundColor: colors.separators,
                marginBottom: 20,
            },
        },
        bgWhiteMain: {
            backgroundColor: colors.white,
            [theme.breakpoints.down("xs")]: {
                backgroundColor: colors.bg,
            },
        }
    })
);
