import { Box } from '@material-ui/core';
import React from 'react';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { MessagesPlaceHolder } from '../../../common/components/wildHealthPlaceHolder/MessagesPlaceHolder';
import { employeeTypesNames } from '../../../employee/models/employee.enums';
import { ConversationModelBaseView, conversationTitles, ConversationType, PatientConversationModel } from "../../models/conversation.models";
import { MessageAvatarsComponent } from '../messageAvatarsComponent/MessageAvatarsComponent';
import { useStyles } from '../healthCareChatComponent/healthCareChatComponent.styles';
import { SecondaryButton } from '../../../common/components/wildHealthButton/SecondaryButton';
import { ConversationViewComponent } from '../conversationViewComponent/ConversationViewComponent';
import { displayShortName } from '../../helpers/messages.converter';
import GetAppIcon  from '@material-ui/icons/GetApp';
import {colors} from "../../../common/constants/colors";
import DownloadButton from "../../../common/components/wildHealthButton/downloadButton/DownloadButton";
import {
    StaffOrHealthCoachMessagesEnum
} from "../patientConversationsViewComponent/patientConversationsViewComponent.hooks";
import { profileQuery } from '../../../account/stores/profileStore';

interface HealthCareChatComponentProps {
    messages: ConversationModelBaseView[];
    isLoading: boolean;
    conversation: PatientConversationModel;
    openFullChat: () => void;
    downloadMessages: (StaffOrHealthCoachMessagesEnum)=> void;
}

export const HealthCareChatViewComponent: React.FC<HealthCareChatComponentProps> = (props: HealthCareChatComponentProps) => {
    const { isLoading, conversation, messages, downloadMessages } = props;

    const classes = useStyles();

    const maxParticipantsCount = 2;

    const renderHeaderArea = () => {
        const defaultParticipantsInitials = [{ image: '', initials: 'HC' }, { image: '', initials: 'Pr' }];
        const defaultParticipants = [{ image: '', firstName: 'Health', lastName: 'Coach', position: '' }, { image: '', firstName: 'Provider', lastName: '', position: '' }];

        const participantsInitials = conversation ?
            conversation.employees.filter(i => i.isActive && !i.isDeleted).map(i => ({ image: profileQuery.getPhoto(i.employeeId), initials: displayShortName(i.firstName, i.lastName) })) : defaultParticipantsInitials;

        const participants = conversation ?
            conversation.employees.filter(i => i.isActive && !i.isDeleted).slice(0, maxParticipantsCount).map(i => ({ image: profileQuery.getPhoto(i.employeeId), firstName: i.firstName, lastName: i.lastName, position: employeeTypesNames.get(i.type) })) : defaultParticipants;

        return (
            <Box p={2} px={3}>
                <Box display='flex' justifyContent='space-between'>
                    <Box display='flex'>
                        <Box>
                            <MessageAvatarsComponent participants={participantsInitials} />
                        </Box>
                        <Box ml={2} className={classes.title}>
                            {conversationTitles.get(ConversationType.HealthCare)}
                        </Box>
                    </Box>

                    <Box  width = {150} alignItems = 'center' justifyContent = 'space-between' display = 'flex'>
                        <SecondaryButton id="health-care-chat-view-open" onClick={() => props.openFullChat()} disabled={isLoading}>
                            Expand
                        </SecondaryButton>
                        <DownloadButton
                            id="download-button"
                            onClick={() => downloadMessages(StaffOrHealthCoachMessagesEnum.HEALTH_COACH)}
                            disabled={isLoading && !messages?.length}
                            style = {{
                                background: colors.stroke
                            }}
                            width = {40}
                            square ={true}
                        >
                            <GetAppIcon/>
                        </DownloadButton>
                    </Box>
                </Box>
                <Box display='flex' mt={1}>
                    {
                        participants.map((item, index) =>
                            <Box key={index} mr={2}>
                                <Box className={classes.employeeType}>
                                    {item.position}
                                </Box>
                                <Box className={classes.employeeNames}>
                                    {`${item.firstName} ${item.lastName}`}
                                </Box>
                            </Box>
                        )
                    }
                </Box>
            </Box>
        );
    }

    const renderContent = () => {

        return conversation.employees?.length
            ? <ConversationViewComponent conversation={conversation} messages={props.messages} />
            : <MessagesPlaceHolder message="Health Care conversation will become available when your health team has been assigned." />
    }

    return (
        <Box className={classes.root}>
            {renderHeaderArea()}
            {
                !isLoading && !conversation ?
                    <MessagesPlaceHolder message="Health Care conversation will become available when your health team has been assigned." /> :
                    isLoading
                        ? <Box p={3}><WildHealthLinearProgress /></Box>
                        : renderContent()
            }
        </Box>
    )
};
