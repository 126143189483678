import React from "react";
import { WildHealthPlaceHolderBase } from "./WildHealthPlaceHolderBase";
import { useStyles } from "./commonPlaceHolder.styles";
import emptyRecommendation from "@img/placeholders/EmptyRecommendations.png";

export interface RecommendationPlaceHolderProps {
    message: string;
}

export const RecommendationPlaceHolder: React.FC<RecommendationPlaceHolderProps> = (props: RecommendationPlaceHolderProps) => {
    const classes = useStyles();

    return (
        <WildHealthPlaceHolderBase message={props.message}>
                <img
                    src={emptyRecommendation}
                    alt="img" className={classes.imageSmall} />
        </WildHealthPlaceHolderBase>
    );
}
