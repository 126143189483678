
import { patientAllergiesService } from '../../patientSupplements/services/patientAllergiesService'
import { PatientAllergyModel } from '../../patientSupplements/models/patientAllergiesModel'
import { allergiesService } from '../../patientSupplements/services/allergies.service'
import { HealthSummaryDataModel } from '../models/healthSummary.models'
import { healthSummaryStore, HealthSummaryStore } from "../stores"
import { Observable } from 'rxjs'
import Axios from "axios-observable";
import {snackService} from "../../common/snack/state";

export class HealthSummaryAllergiesService {
    public mapKey = 'ALLERGIES'

    constructor(private healthSummaryStore: HealthSummaryStore) {
    }

    /**
     * Returns patients health summary model
     */
    public get(patientId?: number): void {
        const method = patientId ? allergiesService.get(patientId) : patientAllergiesService.get()

        method.subscribe((allergies) => {
            const allergiesData = allergies.map(x => ({ 
                key: `${this.mapKey}-${x.id}`, 
                name: x.name, 
                value: x.reaction}
            ))

            this.healthSummaryStore.sectionLoaded()
            this.healthSummaryStore.clearSection(this.mapKey)
            this.healthSummaryStore.setData(allergiesData)      
        });
    }

    /**
     * Returns patients health summary model
     */
    public create(patientId: number, model: HealthSummaryDataModel): Observable<any> {
        const payload = {name: model.name, reaction: model.value} as PatientAllergyModel

        return allergiesService.create(payload, patientId)
    }

    /**
     * Returns patients health summary model
     */
     public update(patientId: number, model: HealthSummaryDataModel): Observable<any> {
        const id = +model.key.replace(`${this.mapKey}-`, '')

        const payload = {
            id: id,
            name: model.name, 
            reaction: model.value
        } as PatientAllergyModel

        return allergiesService.edit(payload)
    }

    public delete(key: string): void{
        const id = +key.replace(`${this.mapKey}-`, '')
        
        allergiesService.delete(id).subscribe(() => {
            this.healthSummaryStore.delete(key)
        })
    }
}

export const healthSummaryAllergiesService = new HealthSummaryAllergiesService(healthSummaryStore)