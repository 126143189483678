import {useState} from 'react';
import { patientInputsService } from '../../services/patientInputs.service';
import {FileInputDataProvider, FileInputType} from "../../models/input.models";
import {inputsService} from "../../services/inputs.service";

export interface UploadInputsDialogState {
    isOpen: boolean;
    file: any;
    type: FileInputType;
    dataProvider: FileInputDataProvider;
    dataProviders: FileInputDataProvider[];
    handleUpload: Function;
}

export function useFacade(): [UploadInputsDialogState, Function, Function, Function, Function]{
    const [state, setState] = useState({
        isOpen: false,
        type: null,
        file: null,
        dataProvider: FileInputDataProvider.LabCorpElation,
        dataProviders: []
    } as UploadInputsDialogState);

    const selectDataProviders = (type: FileInputType) => {
        switch (type) {
            case FileInputType.DNAReport: return [FileInputDataProvider.Andme, FileInputDataProvider.LabCorpElation, FileInputDataProvider.Ancestry];
            case FileInputType.LabResults: return [FileInputDataProvider.LabCorpElation, FileInputDataProvider.LabCorpOriginal, FileInputDataProvider.Quest];
            case FileInputType.MicrobiomeData: return [FileInputDataProvider.Gutbio];
        }
    }

    const setDefaultState = () => {
        setState({
            ...state,
            isOpen: false,
            type: null,
            file: null,
            handleUpload: null,
            dataProvider: FileInputDataProvider.LabCorpElation
        });
    }

    const handleFileUpload = (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        setState({...state, file});
    }

    const handleDataProviderSelect = (dataProvider: FileInputDataProvider) => {
        setState({...state, dataProvider});
    }

    const handleSubmit = () => {
        setState({...state, isOpen: false});
        state.handleUpload(state.file, state.dataProvider);
        setDefaultState();
    }

    const handleClose = () => {
        state.handleUpload(null, null);
        setDefaultState();
    }

    patientInputsService.onUpload.subscribe((callbackModel) => {
        const providers = selectDataProviders(callbackModel.data);
        setState({
            ...state,
            isOpen: true,
            type: callbackModel.data,
            handleUpload: callbackModel.callback,
            dataProviders: providers,
            dataProvider: providers[0]
        });
    })

    inputsService.onUpload.subscribe((callbackModel) => {
        const providers = selectDataProviders(callbackModel.data);
        setState({
            ...state,
            isOpen: true,
            type: callbackModel.data,
            handleUpload: callbackModel.callback,
            dataProviders: providers,
            dataProvider: providers[0]
        });
    })

    return [state, handleFileUpload, handleDataProviderSelect, handleSubmit, handleClose];
}