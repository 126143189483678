import React, { useState } from "react";
import Recaptcha from 'react-google-invisible-recaptcha';
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { SITE_KEY } from "../../../../config";
import { navigationService } from "../../../../services/navigation.service";
import { IErrorState } from "../../../common/validation/error-state";
import { ForgotPasswordModel } from "../../models/auth.models";
import { authService } from "../../services/auth.service";
import { authQuery } from "../../stores/auth";
import { loginPageValidator } from "../loginPage/loginPage.validator";
import { forgotPasswordPageValidator } from "./forgotPasswordPage.validator";
import { RoutesConstants } from '../../../../constants/route.constants';

/**
 * Represents Create Shortcuts Component state
 */
interface ForgotPasswordPageState extends IErrorState {
    model: ForgotPasswordModel;
    isLoading: boolean;
}

/**
 * Custom Hook to manage a view Model for Create shortcuts component
 */
export function useFacade(): [ForgotPasswordPageState, Boolean, Function, Function, Function, Function, Function] {
    const _history = useHistory();
    const _siteKey = SITE_KEY;
    let _recaptcha = undefined;

    if (authQuery.isLoggedIn()) {
        navigationService.toDashboard(_history);
    }

    const [state, setState] = useState({
        model: {
            email: "",
            practiceId: authQuery.getCurrentPracticeId()
        },
        errors: {},
        isLoading: false,
    } as ForgotPasswordPageState);

    const { pathname } = useLocation();
    const mobileForgotPasswordPath = pathname.toLowerCase().includes(RoutesConstants.mobileForgotPassword);

    const handleChanges = (field: string, value: string) => {
        state.model[field] = value;
        setState({
            ...state,
            errors: {},
            model: { ...state.model }
        });

        forgotPasswordPageValidator.validateAndSetState(state, setState, field, value);
    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            handleSubmit(event);
        }
    }

    const renderRecaptcha = () => {
        return <Recaptcha
            ref={ref => _recaptcha = ref}
            sitekey={_siteKey}
            onResolved={(token) => {
                state.model.antiSpamToken = token;
                authService.forgotPassword(state.model).subscribe(() => {
                    state.model.email = "";
                    setState({ ...state, isLoading: false, model: state.model, errors: {} });
                }, () => {
                    const notFoundEmail = {
                        email: 'We couldn’t find that email. Please try again.'
                    }
                    setState(state => ({ ...state, errors: notFoundEmail, isLoading: false }));
                })
            }} />
    }

    const handleSubmit = (event: Event) => {
        event.preventDefault();
        loginPageValidator.validateObjectAndSetState(state, setState, state.model);
        if (!loginPageValidator.stateIsValid(state)) {
            return;
        }

        const recaptcha = _recaptcha;
        setState(state => ({ ...state, isLoading: true }));
        recaptcha.execute();
    }

    const handleReturn = () => {
        navigationService.toDefault(_history);
    }

    return [state, mobileForgotPasswordPath, renderRecaptcha, handleChanges, handleSubmit, handleReturn, handleKeyDown];
}
