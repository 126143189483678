import {useState} from 'react';
import {confirmService} from "../../../../services/confirm.service";

export interface ConfirmDialogState {
    isOpen: boolean;
    title: string;
    message: string;
    image: string;
    acceptTitle: string,
    variant: 'default' | 'danger',
    handleAnswer: Function;
}

export function useFacade(): [ConfirmDialogState, Function]{

    const [state, setState] = useState({
        isOpen: false,
        title: null,
        message: null,
        image: '',
        acceptTitle: '',
        variant: 'default'
    } as ConfirmDialogState);

    const handleClose = (result: boolean) => {
        setState({...state, isOpen: false, title: null, message: null, image: '', acceptTitle: '', variant: 'default'});
        state.handleAnswer(result);
        setState({...state, isOpen: false, title: null, message: null, image: '', acceptTitle: '', variant: 'default'});
    }

    confirmService.onQuestionInfo.subscribe((question) => {
        setState({...state, 
            isOpen: true, 
            title: question?.data?.title,
            message: question.data.message,
            image: question?.data?.image,
            acceptTitle: question?.data?.acceptTitle ?? state.acceptTitle,
            variant: question?.data?.variant ?? state.variant,
            handleAnswer: question.callback});
    })

    return [state, handleClose];
}