import React from 'react';
import { Box, Grid, List, ListItem, ListItemText } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { WildHealthContainer } from '../../../common/components/wildHealthContainer/WildHealthContainer';
import { useFacade, ToolEnum } from './opsAdminToolsCompnent.hooks';
import { FeatureComponent } from "../../../common/components/featureFlags/FeatureComponent";
import clsx from 'clsx';
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import ChangeSubscriptionComponent from './components/ChangeSubscriptionComponent';
import BuyProductsComponent from './components/BuyProductsComponent';

export interface OpsAdminToolsComponentProps {
    patientId: number | null;
}

export const OpsAdminToolsComponent: React.FC<OpsAdminToolsComponentProps> = (props: OpsAdminToolsComponentProps) => {
    const [
        {
            isLoading,
            steps,
            currentStep,
            currentToolEnum,
            menuParams,
            eligiblePaymentPlans,
            eligiblePaymentCoupons,
            errors
        },
        handleGoBack,
        handleToggle,
        handleSubmit,
        handleChanges
    ] = useFacade(props.patientId);

    const renderContent = () => {
        switch (currentToolEnum) {
            case ToolEnum.CHANGE_SUBSCRIPTION_CANDIDATE:
                return <ChangeSubscriptionComponent
                    isLoading={isLoading}
                    steps={steps}
                    currentStep={currentStep}
                    menuParams={menuParams}
                    errors={errors}
                    eligiblePaymentPlans={eligiblePaymentPlans}
                    eligiblePaymentCoupons={eligiblePaymentCoupons}
                    handleChanges={handleChanges}
                    handleSubmit={handleSubmit}
                    handleGoBack={handleGoBack}
                />;
            case ToolEnum.BUY_PRODUCTS:
                return <BuyProductsComponent
                    id="buy-products"
                    params={menuParams[ToolEnum.BUY_PRODUCTS]}
                    errors={errors}
                    isLoading={isLoading}
                    handleChanges={handleChanges}
                    handleClick={handleSubmit[ToolEnum.BUY_PRODUCTS]}
                    toolMenu={ToolEnum.BUY_PRODUCTS}
                />;
            default:
                return <></>
        }
    }

    return (
        <Box py={2} px={10} width={1}>
            <Box my={2} display="flex" alignItems="center" justifyContent="space-between">
                <Box>
                    <FeatureComponent featureFlag={FeatureFlag.Navigation}>
                        <Box className="wh-tw-font-semibold wh-tw-text-2xl wh-tw-black">{PagesNamesService.opsAdminTools}</Box>
                    </FeatureComponent>
                </Box>
            </Box>

            <WildHealthContainer margin={0} minHeight={'100%'}>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <Box p={1}>
                            <List className={clsx('flat-scroll')}>
                                <ListItem button onClick={() => handleToggle(ToolEnum.CHANGE_SUBSCRIPTION_CANDIDATE)}>
                                    <ListItemText primary={<span className={clsx(currentToolEnum === ToolEnum.CHANGE_SUBSCRIPTION_CANDIDATE && "wh-tw-text-primaryV", "wh-tw-font-medium")}>Change Subscription</span>}/>
                                    <ChevronRightIcon className={currentToolEnum === ToolEnum.CHANGE_SUBSCRIPTION_CANDIDATE && "wh-tw-text-primaryV"} />
                                </ListItem>
                                <ListItem button onClick={() => handleToggle(ToolEnum.BUY_PRODUCTS)}>
                                    <ListItemText primary={<span className={clsx(currentToolEnum === ToolEnum.BUY_PRODUCTS && "wh-tw-text-primaryV", "wh-tw-font-medium")}>Buy Products</span>}/>
                                    <ChevronRightIcon className={currentToolEnum === ToolEnum.BUY_PRODUCTS && "wh-tw-text-primaryV"} />
                                </ListItem>
                            </List>
                        </Box>
                    </Grid>
                    <Grid item md={9}>
                        {renderContent()}
                    </Grid>
                </Grid>
            </WildHealthContainer>
        </Box>
    );
}