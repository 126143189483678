import { Box } from '@material-ui/core';
import React from 'react';
import { logoService } from '../../../services/logo.service';
import { HealthReportSections } from "../models/healthReport.models";
import { authQuery } from "../../auth/stores/auth";
import { colors } from '../../common/constants/colors';
import { PracticeProfileModel } from '../../account/models/practiceProfile.model';
import { healthReportsStore } from '../stores/healthReportsStore/healthReports.store';

export interface HealthReportBasePageProps {
    section: HealthReportSections;
    children: React.ReactNode;
    pageNumber: number;
    practice: PracticeProfileModel;
    firstPage?: boolean;
}

interface ReportPageSettings {
    title: string;
    color: string;
}

const reportSettings: { [name: string]: ReportPageSettings } = {
    [HealthReportSections.RoadMap]: { title: "", color: colors.white },
    [HealthReportSections.Overall]: { title: "Overall Health Assessment", color: "#406b82" },
    [HealthReportSections.DietAndNutrition]: { title: "Diet & nutrition", color: "#626d4b" },
    [HealthReportSections.ExerciseAndRecovery]: { title: "Exercise & Recovery", color: '#537b98' },
    [HealthReportSections.Sleep]: { title: "Sleep", color: "#1a4663" },
    [HealthReportSections.Neurobehavioral]: { title: "Neurobehavioral", color: "#aab498" },
    [HealthReportSections.Microbiome]: { title: "Microbiome", color: "#8da1af" },
    [HealthReportSections.ChronicDisease]: { title: "Chronic Disease", color: "#949f87" },
    [HealthReportSections.Longevity]: { title: "Longevity", color: "#aab498" },
    [HealthReportSections.Details]: { title: "Health Report Summary", color: "#8ba551" },
    [HealthReportSections.Labs]: { title: "Labs", color: "#8ba551" },
    [HealthReportSections.Appendix]: { title: "Appendix", color: "#c7bfe6" },
    [HealthReportSections.GenomicGlossary]: { title: "Genomic Glossary", color: "#8ba551" },
    [HealthReportSections.Recommendations]: { title: "", color: colors.white },
}

export const HealthReportBasePage: React.FC<HealthReportBasePageProps> = (props: HealthReportBasePageProps) => {
    const {
        section,
        children,
        pageNumber,
        practice,
    } = props;

    const settings = reportSettings[section];


    const displayName = (): string => {
        const user = healthReportsStore.getCurrentReportOwner()
        return `${user?.firstName} ${user?.lastName}`;
    }

    const renderHeader = () => {
        const hasTitle = settings?.title && settings.title.length;

        return (
            <>
                {!authQuery.isLicensingPractice() &&
                    <Box
                        height={hasTitle ? '60%' : '100%'}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        className="report-header width-report">
                        <Box className="logo">
                            <img src={logoService.getLogo().healthReport} alt="img" />
                        </Box>
                    </Box>
                }
                {
                    hasTitle &&
                    <Box height={authQuery.isLicensingPractice() ? "100%" : "40%"} minHeight={authQuery.isLicensingPractice() ? "100%" : "40%"} display="flex" justifyContent="center" alignItems="center"
                        className="content-header width-report" style={{ background: settings.color, color: colors.white }}>
                        <span className="text-medium" style={{ textTransform: "uppercase", color: colors.white, fontSize: "2rem" }}>{settings.title}</span>
                    </Box>
                }
            </>
        )
    };

    const renderFooter = () => {
        return (
            <footer>
                {
                    authQuery.isLicensingPractice()
                        ? <Box display="flex" justifyContent="center">
                            <span>{`${displayName()}, ${healthReportsStore.getCurrentReportOwner()?.id}`} </span>
                        </Box>
                        : <></>
                }
                <Box className="footer-content width-report" display="flex" justifyContent="space-between">
                    <Box />
                    <Box display="flex" justifyContent="center">
                        <ul>
                            <li className="start-item"><span>p: {practice.phoneNumber ? practice.phoneNumber : '800.975.1562'}</span></li>
                            <li><span>e: {practice.email ? practice.email : 'info@wildhealth.com'}</span></li>
                            <li>
                                {
                                    authQuery.isLicensingPractice()
                                        ? <span>a: {practice.address.streetAddress1 ? practice.address.streetAddress1 : '535 Wellington Way'} # {practice.address.city ? practice.address.city : '330 Lexington'} {practice.address.state ? practice.address.state : 'KY'} {practice.address.zipCode ? practice.address.zipCode : '40503'}</span>
                                        : <span>{`${displayName()}, ${healthReportsStore.getCurrentReportOwner()?.id}`}</span>
                                }
                            </li>
                        </ul>
                    </Box>
                    <Box px={5}>
                        {pageNumber}
                    </Box>
                </Box>
            </footer>
        )
    };

    return section === HealthReportSections.Recommendations
        ?
        <div className="width-a4 flat-scroll">
            <Box height="100%"  >
                {children}
            </Box>
        </div>
        :
        <div className="page-a4 flat-scroll">
            <Box height="10%" className='width-report'>
                {renderHeader()}
            </Box>
            <Box height={authQuery.isLicensingPractice() ? '85%' : '87%'} className='width-report'>
                {children}
            </Box>
            <Box height={authQuery.isLicensingPractice() ? '5%' : '3%'} className='width-report'>
                {renderFooter()}
            </Box>
        </div>

}

export const HealthReportMobileBasePage: React.FC<HealthReportBasePageProps> = (props: HealthReportBasePageProps) => {
    const {
        section,
        children,
        pageNumber,
        practice,
        firstPage = false
    } = props;

    const settings = reportSettings[section];


    const displayName = (): string => {
        const user = healthReportsStore.getCurrentReportOwner()
        return `${user?.firstName} ${user?.lastName}`;
    }

    const renderHeader = () => {
        const hasTitle = settings?.title && settings.title.length;

        return (
            <>
                {!authQuery.isLicensingPractice() &&
                    <Box height="71px">
                        <Box display="flex" height={'100%'} justifyContent="center" alignItems="center"
                            className="report-header">
                            <Box className="mobile-logo">
                                <img src={logoService.getLogo().healthReport} alt="img" />
                            </Box>
                        </Box>
                    </Box>
                }
                {
                    hasTitle &&
                    <Box height="37px" minHeight={authQuery.isLicensingPractice() ? "100%" : "40%"} display="flex" justifyContent="center" alignItems="center"
                        className="content-header" style={{ background: settings.color, color: colors.white }}>
                        <span className="text-light-medium" style={{ textTransform: "uppercase", color: colors.white, fontSize: "18px", letterSpacing: '0.04em' }}>{settings.title}</span>
                    </Box>
                }
            </>
        )
    };

    const renderFooter = () => {
        return (
            <footer>
                {
                    authQuery.isLicensingPractice()
                        ? <Box display="flex" justifyContent="center">
                            <span>{`${displayName()}, ${healthReportsStore.getCurrentReportOwner()?.id}`} </span>
                        </Box>
                        : <></>
                }
                <Box width={1} className="footer-content footer-divider">
                    <Box />
                    <Box textAlign="center">
                        <ul>
                            <li className="start-item"><span>p: {practice.phoneNumber ? practice.phoneNumber : '800.975.1562'}</span></li>
                            <li><span>e: {practice.email ? practice.email : 'info@wildhealth.com'}</span></li>
                        </ul>
                        {
                            authQuery.isLicensingPractice()
                                ? <span>a: {practice.address.streetAddress1 ? practice.address.streetAddress1 : '535 Wellington Way'} # {practice.address.city ? practice.address.city : '330 Lexington'} {practice.address.state ? practice.address.state : 'KY'} {practice.address.zipCode ? practice.address.zipCode : '40503'}</span>
                                : <span>{`${displayName()}, ${healthReportsStore.getCurrentReportOwner()?.id}`}</span>
                        }
                    </Box>
                    <Box px={2} textAlign="right">
                        {pageNumber}
                    </Box>
                </Box>
            </footer>
        )
    };

    return section === HealthReportSections.Recommendations
        ?
        <Box className="page-mobile flat-scroll">
            <Box height="100%" width={1}>
                {children}
            </Box>
        </Box>
        :
        <Box className="page-mobile flat-scroll">
            {firstPage && (
                <Box width={1}>
                    {renderHeader()}
                </Box>
            )}
            <Box py="30px" width={1}>
                {children}
            </Box>
            <Box width={1}>
                {renderFooter()}
            </Box>
        </Box>

}
