import { Box } from '@material-ui/core';
import React from 'react';
import { useFacade } from './labComponent.hooks';
import {Gender} from "../../../common/models/user.models";

interface LabComponentProps {
    patientId?: number;
    gender?: Gender;
}

export const LabComponent: React.FC<LabComponentProps> = (props: LabComponentProps) => {
    const { patientId, gender } = props;

    const [
        content,
    ] = useFacade(patientId, gender);

    return (
        <Box>
            {content}
        </Box>
    );
}
