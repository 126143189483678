import {
    checkManyQuestionValidationRule, rangeValidationRule, stringLengthValidationRule
} from "../../../common/validation/general-validation-rules";
import { GeneralValidator } from "../../../common/validation/general-validator";
import {HealthLogQuestionNames} from '../../models/questionnaire.models';

export const healthLogQuestionnaireSurveyComponentValidator = new GeneralValidator({
    // Nutrition
    [HealthLogQuestionNames.FIRST_MEAL_TIME]: [stringLengthValidationRule(0, null, 'This field is required')],
    [HealthLogQuestionNames.FIRST_MEAL]: [stringLengthValidationRule(0, 500, 'Length should be in range from 0 to 500')],
    [HealthLogQuestionNames.SECOND_MEAL_TIME]: [stringLengthValidationRule(0, null, 'This field is required')],
    [HealthLogQuestionNames.SECOND_MEAL]: [stringLengthValidationRule(0, 500, 'Length should be in range from 0 to 500')],
    [HealthLogQuestionNames.LAST_MEAL_TIME]: [stringLengthValidationRule(0, null, 'This field is required')],
    [HealthLogQuestionNames.LAST_MEAL]: [stringLengthValidationRule(0, 500, 'Length should be in range from 0 to 500')],
    [HealthLogQuestionNames.LIST_OF_SNACKS]: [stringLengthValidationRule(0, 500, 'Length should be in range from 0 to 500')],
    [HealthLogQuestionNames.REGULAR_PRACTICE]: [stringLengthValidationRule(0, null, 'This field is required')],

    // Exercise
    [HealthLogQuestionNames.WEEKS_EXERCISE]: [stringLengthValidationRule(0, null, 'This field is required')],
    [HealthLogQuestionNames.TYPES_OF_EXERCISE]: [checkManyQuestionValidationRule],
    
    // Sleep
    [HealthLogQuestionNames.ACTIVITIES_LIST]: [stringLengthValidationRule(0, 500, 'Length should be in range from 0 to 500')],
    [HealthLogQuestionNames.CONSUME_BEFORE_NIGHT]: [checkManyQuestionValidationRule],
    [HealthLogQuestionNames.FALL_ASLEEP_TIME]: [stringLengthValidationRule(0, null, 'This field is required')],
    [HealthLogQuestionNames.WAKE_UP_TIME]: [stringLengthValidationRule(0, null, 'This field is required')],
    [HealthLogQuestionNames.WAKE_UP_WITH_ALARM]: [stringLengthValidationRule(0, null, 'This field is required')],
    [HealthLogQuestionNames.FEEL_IN_THE_MORNING]: [rangeValidationRule(0, 10, 'Value is out of range')],
    [HealthLogQuestionNames.ENERGY_THROUGHOUT_THE_DAY]: [rangeValidationRule(0, 10, 'Value is out of range')],

    // Mindfulness
    [HealthLogQuestionNames.PRACTICES_A_WEEK]: [stringLengthValidationRule(0, null, 'This field is required')],
});