import React from 'react';
import {Box, Grid} from '@material-ui/core';
import {PageSliderComponent} from "../../../../common/components/PageSliderComponent";
import {CardioTimerComponent} from "../../../components/cardioTimerComponent/CardioTimerComponent";
import {QuestionnaireSurveyProps} from "./questionnaireSurvey.props";
import {InitialQuestionNames} from "../../../models/questionnaire.models";
import {QuestionnairesListComponent} from "../../../components/questionnairesListComponent/QuestionnairesListComponent";
import commonUseStyles from '../../../../common/styles/common.styles';

const cardioRespiratory = [
    InitialQuestionNames.HEIGHT,
    InitialQuestionNames.WEIGHT,
    InitialQuestionNames.WAIST,
    InitialQuestionNames.ETHNICITY,
    InitialQuestionNames.SBP
]

const performTests = [
    InitialQuestionNames.PULSE_REST,
    InitialQuestionNames.PULSE_AFTER_TEST,
    InitialQuestionNames.PULSE_1_MIN_AFTER_TEST
]

export const CardioRespiratoryQuestionnairePageFields = cardioRespiratory.concat(performTests);

const isSmallScreen = window.innerWidth <= 768;

export const CardioRespiratorySurvey: React.FC<QuestionnaireSurveyProps> = (props: QuestionnaireSurveyProps) => {
    const {
        errors,
        result,
        questionnaire,
        handleChanges,
        navigationContext
    } = props;
    const commonClasses = commonUseStyles();

    return (
        <>
            <Box>
                <QuestionnairesListComponent
                    questionnaire={questionnaire}
                    errors={errors}
                    handleChanges={handleChanges}
                    navigationContext={navigationContext}
                    questions={cardioRespiratory}
                    result={result}
                    title={"Cardio Respiratory Fitness Predictor:"}
                />
            </Box>
            <Box pt={7} className={commonClasses.primaryTitle}>Please perform the following tests:
            </Box>
            <Box py={2}>
                <span className="secondary-font size-1_25"> Ruffier Squat test: perform 30 squats in 45 seconds </span>
            </Box>
            <Grid container direction={isSmallScreen ? "column-reverse" : "row"}>
                <Grid xs={12} md={6} lg={6} item={true}>
                    <QuestionnairesListComponent
                        questionnaire={questionnaire}
                        errors={errors}
                        handleChanges={handleChanges}
                        navigationContext={navigationContext}
                        questions={performTests}
                        result={result}
                    />
                </Grid>
                <Grid xs={12} md={6} lg={6} item={true}>
                    <Box pt={5}>
                        <CardioTimerComponent/>
                    </Box>
                </Grid>
            </Grid>
            <Box mt={5}>
                <PageSliderComponent context={navigationContext}/>
            </Box>
        </>
    )
}
