import React from 'react';
import {Box, LinearProgress, withStyles} from '@material-ui/core';
import {QuestionnairePages} from "../../models/questionnaire.models";
import { colors } from "../../../common/constants/colors";

interface QuestionnaireProgressBarComponentProps {
    currentPage: QuestionnairePages;
}

const QuestionnaireProgress = withStyles(() => ({
    colorPrimary: {
        backgroundColor: colors.gray3,
        borderRadius: 10,
        height: 11,
    },
    barColorPrimary: {
        backgroundColor: colors.main,
    },
    
  }))(LinearProgress);

export const QuestionnaireProgressBarComponent: React.FC<QuestionnaireProgressBarComponentProps> = (props: QuestionnaireProgressBarComponentProps) => {
    const {currentPage} = props;

    const getProgressValue = () => {
        switch(currentPage){
            case QuestionnairePages.General:
                return 100/7;
            case QuestionnairePages.Medical:
                return 100/7*2;
            case QuestionnairePages.Nutrition:
                return 100/7*3;
            case QuestionnairePages.Exercise:
                return 100/7*4;
            case QuestionnairePages.CardioRespiratory:
                return 100/7*5;
            case QuestionnairePages.Sleep:
                return 100/7*6;
            case QuestionnairePages.Stress:
                return 100;
        }
    }
    return(
        <>
            <Box>
                <Box fontSize={18} fontWeight={500} color={colors.main}>{Math.round(getProgressValue())}%</Box>
                <QuestionnaireProgress variant="determinate"  value={getProgressValue()} />
            </Box>  
        </>
    );
}