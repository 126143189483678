import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { Box, Button, Divider, Tooltip, IconButton, Drawer, useTheme, useMediaQuery } from '@material-ui/core';
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ReactComponent as AddIcon } from '@img/icons/Add.svg';
import { ReactComponent as ForwardIcon } from '@img/icons/ForwardIcon.svg';
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { employeeService } from '../../../employee/services/employees.service';
import { EmployeeConversationModel } from '../../models/conversation.models';
import { ConversationState } from '../../models/conversationState.models';
import { InternalConversationComponent } from "../internalConversationComponent/InternalConversationComponent";
import { MessageAvatarsComponent } from '../messageAvatarsComponent/MessageAvatarsComponent';
import { SelectUserDialogComponent } from '../selectUserDialogComponent/SelectUserDialogComponent';
import { useFacade } from './internalChatComponent.hooks';
import { useStyles } from './internalChatComponent.styles';
import { Gender } from '../../../common/models/user.models';
import { displayShortName } from '../../helpers/messages.converter';
import { InternalConversationViewComponent } from '../internalConversationViewComponent/InternalConversationViewComponent';
import { fullName } from '../../../common/helpers/display-name';
import { profileQuery } from '../../../account/stores/profileStore';
import { employeeTypesNames } from '../../../employee/models/employee.enums';
import { ChatMembersComponent } from '../chatMembersComponent/ChatMembersComponent';

interface InternalChatComponentProps {
    targetConversation?: EmployeeConversationModel;
    resetTargetConversation?: () => void;
    handleGoBack: () => void;
}

export const InternalChatComponent: React.FC<InternalChatComponentProps> = (props: InternalChatComponentProps) => {
    const { targetConversation, handleGoBack } = props;
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const customTooltipClasses = {
        tooltip: classes.customWidth,
        arrow: classes.arrow,
        tooltipPlacementBottom: classes.tooltipPlacement,
        tooltipPlacementTop: classes.tooltipPlacement,
    };

    const [
        {
            author,
            patient,
            isAddUserOpen,
            isSideAreaOpen,
            employees
        },
        signedBy,
        me,
        handleNavigateToPatient,
        handleToggleAddUser,
        handleAddUser,
        handleSignOff,
        handleToggleSideArea
    ] = useFacade(targetConversation);

    if (!targetConversation) {
        return <></>
    }
    const participants = targetConversation ? targetConversation.employees.filter(el => el.isActive && !el.isDeleted) : [];
    const forwardEnabledEmployees = participants.filter((i) => i.settings?.forwardEmployeeEnabled)

    const renderMobileSideArea = (className: string) => {
        const members = targetConversation
            ? participants
                .map((i) => ({
                    image: profileQuery.getPhoto(i.employeeId),
                    firstName: i.firstName,
                    lastName: i.lastName,
                    position: employeeTypesNames.get(i.type),
                    initials: displayShortName(i.firstName, i.lastName),
                }))
            : [
                { firstName: "Health", lastName: "Coach", initials: "HC", position: "Health Coach" },
                { firstName: "Provider", lastName: "", initials: "Pr", position: "Provider" },
            ];
        const patient = targetConversation
            ? targetConversation.patients
                .map((i) => ({
                    image: '',
                    firstName: i.firstName,
                    lastName: i.lastName,
                    initials: displayShortName(i.firstName, i.lastName),
                }))
            : [
                { initials: "P", image: "", firstName: "Patient", lastName: "" },
            ];

        return (
            <Box className={className}>
                <Box className={classes.goBack}>
                    <IconButton id="close-side-bar" onClick={() => handleToggleSideArea(!isSideAreaOpen)}>
                        <Box display="flex" alignItems="center">
                            <ArrowBackIcon className="wh-tw-text-gray1" />
                            <Box ml={1} className="wh-tw-text-gray1 wh-tw-text-base">Back</Box>
                        </Box>
                    </IconButton>
                </Box>
                <Box className={classes.header}>
                    <Box mt={2} className={classes.titleAndAvatars}>
                        <Box>
                            <MessageAvatarsComponent
                                participants={patient}
                                size={isSmallScreen && 34}
                            />
                        </Box>
                        <Box pl={1} className="wh-tw-font-medium wh-tw-text-lg wh-tw-pt-2.5">
                            {`${patient[0].firstName} ${patient[0].lastName}`}
                        </Box>
                    </Box>
                </Box>
                <Box px={2} width={1}>
                    <ChatMembersComponent members={members} title="Active Users On Thread" />

                    {!signedBy && 
                        <>
                            <Divider className="wh-tw-bg-stroke" />
                            <Box p={1} className="wh-tw-bg-white">
                                <Button id="internal-chat-add-user" onClick={handleToggleAddUser}>
                                    <Box display='flex' alignItems='center'>
                                        <AddIcon />
                                        <Box ml={1} className={classes.addUser}>
                                            Add User
                                        </Box>
                                    </Box>
                                </Button>
                            </Box>
                        </>
                    }
                </Box>
            </Box>
        )
    }

    const renderHeaderArea = () => {
        const initials = participants.map(i => ({ image: profileQuery.getPhoto(i.employeeId), initials: displayShortName(i.firstName, i.lastName) }));
        const names = participants.map(i => `${i.firstName} ${i.lastName}`).join(', ');

        if (isSmallScreen) {
            return (
                <Box p={2} className={classes.header}>
                    <Box
                        display='flex'
                        alignItems='flex-start'
                        justifyContent='space-between'
                    >
                        <Box display='flex' ml={1}>
                            <Box>
                                <IconButton
                                    id="chat-list-go-back"
                                    onClick={() => handleGoBack()}
                                >
                                    <ChevronLeftIcon />
                                </IconButton>
                            </Box>
                            <Box>
                                <MessageAvatarsComponent participants={initials} size={32} />
                            </Box>
                            <Box ml={2}>
                                <Box className={clsx('flat-scroll', classes.title)} title={names} mr={2}>
                                    {names}
                                </Box>
                                {patient &&
                                    <Box display='flex' className={classes.patientInfo}>
                                        <Box mr={1}>
                                            Patient:
                                        </Box>
                                        <Box id="internal-chat-to-patient" className={classes.patientName} onClick={() => handleNavigateToPatient(patient.id)}>
                                            {`${patient.firstName} ${patient.lastName}`}
                                        </Box>
                                    </Box>
                                }
                            </Box>
                            <Box>
                                <IconButton
                                    id="attachment-views-open"
                                    onClick={() => handleToggleSideArea(!isSideAreaOpen)}
                                    disabled={!targetConversation}
                                >
                                    <MoreHorizIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>

                    <Box mt={2} width={1}>
                        {
                            targetConversation.state !== ConversationState.Closed && !me?.isSigned &&
                                <WildHealthButton id="internal-chat-sign-off" fullWidth color={signedBy && !me?.isSigned ? 'secondary' : 'primary'} onClick={() => handleSignOff()}>
                                    {signedBy && !me?.isSigned ? 'Acknowledge' : 'Sign Off'}
                                </WildHealthButton>
                        }
                    </Box>
                    
                    <Drawer
                        id="internal-chat-side-area-drawer"
                        anchor='right'
                        open={isSideAreaOpen}
                        onClose={() => handleToggleSideArea(!isSideAreaOpen)}
                    >
                        <Box className="wh-tw-bg-gray8 wh-tw-w-screen wh-tw-h-full">
                            {renderMobileSideArea(clsx('flat-scroll', classes.sideContainer))}
                        </Box>
                    </Drawer>
                </Box>
            )
        }

        return (
            <Box p={2} className={classes.header}>
                <Box
                    display='flex'
                    alignItems='flex-start'
                    justifyContent='space-between'
                >
                    <Box display='flex' ml={1}>
                        <Box>
                            <MessageAvatarsComponent participants={initials} />
                        </Box>
                        <Box ml={2} display='flex' justifyContent='center'>
                            <Box className={clsx('flat-scroll', classes.title)} title={names} mr={2}>
                                {names}
                            </Box>
                            {forwardEnabledEmployees.length > 0 &&
                                <Tooltip
                                    placement="top"
                                    arrow
                                    title={
                                        <Box>
                                            {forwardEnabledEmployees.map(function callback(item, index) {
                                                const forwardEmployee = employees.find(e => e.id === item.settings.forwardEmployeeId)
                                                if (item.settings.forwardEmployeeEnabled && forwardEmployee) {
                                                    return (
                                                        <p className={classes.notificationToolTipText} key={index}>All messages to {fullName(item)} are being forwarded to {fullName(forwardEmployee)}</p>
                                                    );
                                                }
                                            })}
                                        </Box>
                                    }
                                    classes={customTooltipClasses}
                                >
                                    <ForwardIcon />
                                </Tooltip>
                            }
                        </Box>
                    </Box>

                    <Box display='flex' justifyContent='flex-end' minWidth={212}>
                        {!signedBy && <Button id="internal-chat-add-user" onClick={handleToggleAddUser}>
                            <Box display='flex' alignItems='center'>
                                <AddIcon />
                                <Box ml={1} className={classes.addUser}>
                                    Add User
                                </Box>
                            </Box>
                        </Button>}

                        {
                            targetConversation.state !== ConversationState.Closed && !me?.isSigned &&
                            <Box ml={1}>
                                <WildHealthButton id="internal-chat-sign-off" onClick={() => handleSignOff()}>
                                    {signedBy && !me?.isSigned ? 'Acknowledge' : 'Sign Off'}
                                </WildHealthButton>
                            </Box>
                        }
                    </Box>
                </Box>

                {
                    patient &&
                    <Box display='flex' className={classes.patientInfo}>
                        <Box mr={1}>
                            Patient:
                        </Box>
                        <Box id="internal-chat-to-patient" className={classes.patientName} onClick={() => handleNavigateToPatient(patient.id)}>
                            {`${patient.firstName} ${patient.lastName}`}
                        </Box>
                        <Box mx={1}>
                            {`(${Gender[patient.gender].slice(0, 1)})`}
                        </Box>
                        <Box mr={1}>
                            {`D.O.B.: ${moment(patient.birthday).format('MM/DD/yyyy')}`}
                        </Box>
                        <Box>
                            {`Phone: ${patient.phoneNumber}`}
                        </Box>
                    </Box>
                }
            </Box>
        )
    }

    const renderForwardingBanner = () => {
        if (!forwardEnabledEmployees.length) return <></>

        return (
            <Box position="relative" width={1}>
                <Box position="absolute" top={0} width={1} className="wh-tw-bg-average2Bg wh-tw-px-5 wh-tw-py-2.5 wh-tw-rounded-sm wh-tw-z-50">
                    {forwardEnabledEmployees.map(function callback(item, index) {
                        const forwardEmployee = employees.find(e => e.id === item.settings.forwardEmployeeId)
                        if (item.settings.forwardEmployeeEnabled && forwardEmployee) {
                            return (
                                <Box className="wh-tw-text-average1 wh-tw-text-sm wh-tw-ml-3.5" key={index}>All messages to {fullName(item)} are being forwarded to {fullName(forwardEmployee)}</Box>
                            );
                        }
                    })}
                </Box>
            </Box>
        )
    }

    return (
        <div className={classes.chat}>
            <div className={clsx('g', classes.messages)}>
                {renderHeaderArea()}

                <Divider className={classes.divider} orientation='horizontal' />
                {renderForwardingBanner()}
                {targetConversation.state !== ConversationState.Closed
                    ? <InternalConversationComponent
                        conversation={targetConversation}
                        signedBy={signedBy}
                        author={author}
                    />
                    : <InternalConversationViewComponent
                        conversation={targetConversation}
                        signedBy={signedBy}
                        author={author}
                    />}

            </div>

            <SelectUserDialogComponent
                isOpen={isAddUserOpen}
                title='Add User'
                message='Select User:'
                actionName='Add User'
                handleGetUsers={(search) => employeeService.getActive(search)}
                handleCancel={() => handleToggleAddUser()}
                handleSubmit={(id) => handleAddUser(id)}
            />
        </div>
    )
};
