import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    Box,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Grid,
    FormControl,
    Select,
    MenuItem
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import SearchIcon from "@material-ui/icons/Search";
import { useStyles, SearchTextField } from "./selectShortcutComponent.styles";
import { useFacade } from "./selectShortcutComponent.hooks";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { getNumericKeys } from '../../../common/helpers/enum-mapper';
import { ShortcutGroupTypeNames } from '../../../employee/models/shortcut.models';

export const SelectShortcutComponent: React.FC<any> = () => {
    const classes = useStyles();
    const [
        {
            isLoading,
            isOpen,
            selectedId,
            groups,
            filteredGroups,
            shortcuts,
            filteredShortcuts,
            typeId,
            groupId
        },
        handleSelect,
        handleFilter,
        handleClose,
        handleApply,
        handleType,
        handleGroup
    ] = useFacade();

    return (
        <Box>
            <Dialog
                fullWidth
                open={isOpen}
                onClose={() => handleClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="form-dialog-title">Apply Shortcut</DialogTitle>
                <DialogContent>
                    <DialogContentText id="select-shortcut-description">
                        <Box pb={3}>
                            <SearchTextField
                                fullWidth
                                variant="outlined"
                                className={classes.search}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    handleFilter(e.target.value)
                                }
                                placeholder="Search"
                                InputProps={{
                                    inputProps: {
                                        className: classes.searchBase,
                                    },
                                    startAdornment: <SearchIcon className={classes.icon} />,
                                }}
                            />
                        </Box>
                        {
                            isLoading
                                ? <WildHealthLinearProgress />
                                : <>
                                    <Box mb={3}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={6}>
                                                <Box>
                                                    <FormControl
                                                        size="small"
                                                        color='primary'
                                                        fullWidth
                                                        variant="outlined">
                                                        <Select
                                                            style={{ background: '#FFF' }}
                                                            id="typeId"
                                                            renderValue={() => {
                                                                if (typeId === null) {
                                                                    return <Box>Shortcut Type</Box>;
                                                                }
                
                                                                return ShortcutGroupTypeNames[typeId];
                                                            }}
                                                            displayEmpty
                                                            onChange={(v) => handleType((v.target.value) as string)}>
                                                            {
                                                                getNumericKeys(ShortcutGroupTypeNames).map((value, i) => {
                                                                    return <MenuItem key={i} value={value}>
                                                                        <Box display="flex" alignItems="center" width={1} gridGap={8}>
                                                                            {value === typeId ? <CheckCircleIcon className="wh-tw-text-primaryV" /> : <RadioButtonUncheckedIcon className="wh-tw-text-primaryV" />}
                                                                            <Box>{ShortcutGroupTypeNames[value]}</Box>
                                                                        </Box>
                                                                    </MenuItem>
                                                                })
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Box>
                                                    <FormControl
                                                        size="small"
                                                        color='primary'
                                                        fullWidth
                                                        variant="outlined">
                                                        <Select
                                                            style={{ background: '#FFF' }}
                                                            id="groupId"
                                                            renderValue={() => {
                                                                if (groupId === null) {
                                                                    return <Box>Group</Box>;
                                                                }
                
                                                                return groups.find(g => g.id === groupId)?.name ?? 'View All Groups';
                                                            }}
                                                            displayEmpty
                                                            onChange={(v) => handleGroup((v.target.value) as string)}>
                                                            <MenuItem value="-1">
                                                                <Box display="flex" alignItems="center" width={1} gridGap={8}>
                                                                    {groupId < 0 ? <CheckCircleIcon className="wh-tw-text-primaryV" /> : <RadioButtonUncheckedIcon className="wh-tw-text-primaryV" />}
                                                                    <Box>View All Groups</Box>
                                                                </Box>
                                                            </MenuItem>
                                                            {filteredGroups.map(g => (
                                                                <MenuItem key={g.id} value={g.id}>
                                                                    <Box display="flex" alignItems="center" width={1} gridGap={8}>
                                                                        {g.id === groupId ? <CheckCircleIcon className="wh-tw-text-primaryV" /> : <RadioButtonUncheckedIcon className="wh-tw-text-primaryV" />}
                                                                        <Box>{g.name}</Box>
                                                                    </Box>
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <List disablePadding className={classes.list}>
                                        {shortcuts
                                            ? filteredShortcuts.map((shortcut) => {
                                                return <ListItem
                                                        id={`select-shortcut-${shortcut.id}`}
                                                        key={`shortcut-${shortcut.id}`}
                                                        button
                                                        onClick={() => handleSelect(shortcut.id)}
                                                    >
                                                    <ListItemText primary={shortcut.displayName} />
                                                    <ListItemSecondaryAction>
                                                        {selectedId === shortcut.id ? <CheckCircleIcon className="wh-tw-text-primaryV" /> : <RadioButtonUncheckedIcon className="wh-tw-text-primaryV" />}
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            })
                                            : <ListItem>
                                                <ListItemText primary={'You have no shortcuts yet'} />
                                            </ListItem>
                                        }
                                    </List>
                                </>
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="wh-tw-p-6">
                    <WildHealthButton
                        id="select-shortcut-cancel"
                        color='secondary'
                        size="large"
                        borderRadius={50}
                        onClick={() => handleClose()}
                    >
                        Cancel
                    </WildHealthButton>
                    <WildHealthButton
                        id="create-shortcut-apply"
                        size="large"
                        borderRadius={50}
                        disabled={!selectedId}
                        onClick={() => handleApply()}
                    >
                        Apply
                    </WildHealthButton>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
