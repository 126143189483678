import {
  Box, 
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  IconButton,
  Popover
} from '@material-ui/core';
import React from 'react';
import moment from "moment";
import { WildHealthContainer } from '../../../common/components/wildHealthContainer/WildHealthContainer';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { useFacade } from './managePromoCodesComponent.hooks';
import { FeatureComponent } from "../../../common/components/featureFlags/FeatureComponent";
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from 'clsx';
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { useStyles, SearchTextField } from "./promoCodesComponent.styles";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { WildHealthMenuItem } from '../../../common/components/wildHealthMenu/WildHealthMenu';
import { WildHealthStatusLabel } from "../../../common/components/wildHealthStatusLabel/WildHealthStatusLabel";
import { pageSizes } from "../../../common/pagination/models/page-sizes";
import { PaginationComponent } from "../../../common/pagination/components/paginationComponent/PaginationComponent";
import { toCurrentTimeZone } from '../../../timezones/helpers/timezone';
import { CreatePromoCodeComponent } from '../createPromoCodeComponent/CreatePromoCodeComponent';
import SearchIcon from '@material-ui/icons/Search';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

export const ManagePromoCodesComponent: React.FC = () => {
  const [
    {
      isLoading,
      promoCodes,
      searchKey,
      menuAnchorEl,
      sortingColumns,
      sortingSource,
      totalCount,
      pageSize,
      selectedPage,
      targetCode,
    },
    handleOnFilter,
    getAllAvailablePages,
    handlePageSizeChange,
    handlePageChange,
    handleSorting,
    handleToggleMenu,
    handleDeactivate,
    handleDelete
  ] = useFacade();

  const classes = useStyles();
  const commonClasses = commonUseStyles();

  const renderTable = () => {
    if (isLoading) {
      return <WildHealthLinearProgress />
    }

    if (!isLoading && (!promoCodes || !promoCodes.length)) {
      return <WildHealthPlaceHolder message="No Coupons found" />
    }

    return (
      <>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {
                  sortingColumns.map((item, index) =>
                    <WildHealthTableCell key={index}>
                      {item.source
                        ? <TableSortLabel
                            id={`sorting-promo-codes-${item.title}`}
                            active={sortingSource === item.source}
                            direction={sortingSource === item.source ? item.direction : 'asc'}
                            onClick={() => handleSorting(item.source, item.direction)}
                        >
                            {item.title}
                        </TableSortLabel>
                        : item.title
                      }
                    </WildHealthTableCell>
                  )
                }
                <WildHealthTableCell style={{ width: '5%' }}>Actions</WildHealthTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {promoCodes.slice((selectedPage - 1) * pageSize, (selectedPage - 1) * pageSize + pageSize).map((promoCode, index) => (
                <WildHealthTableRow key={index}>
                  <WildHealthTableCell width="15%" align="left">
                    <Box className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorBlack)}>
                      {promoCode.code}
                    </Box>
                    <Box mt={Boolean(promoCode.description) && 0.5} className={clsx(commonClasses.size12, commonClasses.textRegular, commonClasses.colorGray1)}>{promoCode.description ?? ''}</Box>
                  </WildHealthTableCell>
                  <WildHealthTableCell align="left">
                    <Box className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorBlack)}>
                      {promoCode.discount}{promoCode.discountUnitOfMeasure}
                    </Box>
                  </WildHealthTableCell>
                  <WildHealthTableCell width="15%" align="left">
                    {promoCode.paymentPlans.map((plan, index) => <Box key={index} className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorBlack)}>{plan}</Box>)}
                  </WildHealthTableCell>
                  <WildHealthTableCell align="left">
                    <Box className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorBlack)}>
                      {promoCode.expirationDate ? moment(toCurrentTimeZone(new Date(promoCode.expirationDate))).format('MM/DD/YYYY') : '∞'}
                    </Box>
                  </WildHealthTableCell>
                  <WildHealthTableCell align="left">
                    {promoCode.additional.length > 0 ? promoCode.additional.map((item, index) => <Box key={index} className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorBlack)}>{item}</Box>) : <Box className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorBlack)}>-</Box>}
                  </WildHealthTableCell>
                  <WildHealthTableCell align="left">
                    <WildHealthStatusLabel 
                      color={promoCode?.status === "Active" ? "good" : "bad"}
                      minWidth={78}
                    >
                      {promoCode?.status === "Active" ? "Active" : "Inactive"}
                    </WildHealthStatusLabel>
                  </WildHealthTableCell>
                  <WildHealthTableCell width="5%" align="right">
                    <IconButton id="promo-code-more-menu" onClick={(e) => handleToggleMenu(promoCode.id, e.currentTarget)} className={classes.menuIcon}>
                      <MoreHorizIcon />
                    </IconButton>
                  </WildHealthTableCell>
                </WildHealthTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box py={1} px={2}>
          <PaginationComponent
            pageSizes={pageSizes}
            pageSize={pageSize}
            selectedPage={selectedPage}
            handlePageSizeChange={handlePageSizeChange}
            totalCount={totalCount}
            availablePages={getAllAvailablePages()}
            handlePageSelect={handlePageChange}
          />
        </Box>
      </>
    )
  }

  return (
    <Box py={2} px={10} width={1}>
      <Box my={2} display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <FeatureComponent featureFlag={FeatureFlag.Navigation}>
            <Box className={clsx(commonClasses.size24, commonClasses.textSemiBold, commonClasses.colorBlack)}>{PagesNamesService.promoCodes}</Box>
          </FeatureComponent>
        </Box>
      </Box>
      <CreatePromoCodeComponent />
      <Box mt={5} mb={2} display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Box className={clsx(commonClasses.size20, commonClasses.textMedium, commonClasses.colorBlack)}>All coupons</Box>
        </Box>
        <Box width="310px">
          <SearchTextField
            fullWidth
            variant="outlined"
            className={classes.search}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnFilter(e.target.value)}
            placeholder='Search by Name'
            InputProps={{
              inputProps: {
                className: classes.searchBase,
              },
              startAdornment: <SearchIcon className={classes.icon} />,
            }}
            value={searchKey}
          />
        </Box>
      </Box>

      <WildHealthContainer margin={0} minHeight={'100%'}>
        {renderTable()}
      </WildHealthContainer>
      {targetCode && (
        <Popover
          keepMounted
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={() => handleToggleMenu(0)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <WildHealthMenuItem id="promo-code-deactivate" disabled={!targetCode.canDeactivate} onClick={handleDeactivate}>Deactivate</WildHealthMenuItem>
          <WildHealthMenuItem id="promo-code-delete" disabled={!targetCode.canDelete} onClick={handleDelete}>Delete</WildHealthMenuItem>
        </Popover>
      )}
    </Box>
  );
}