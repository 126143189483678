import {Store, StoreConfig} from '@datorama/akita';
import {SubscriptionModel, ManualPaymentModel} from "../../models/subscription.models";

/**
 * Represents subscription state
 */
export interface SubscriptionState {
    activeSubscription: SubscriptionModel | null;
    mySubscriptions: SubscriptionModel[];
    patientSubscriptions: SubscriptionModel[];
    manualPayment: ManualPaymentModel;
}

/**
 * Creates initial state
 */
export function createInitialState(): SubscriptionState {
    return {
        activeSubscription: null,
        mySubscriptions: [],
        patientSubscriptions: [],
        manualPayment: null
    };
}

/**
 * Provides payment states management
 */
@StoreConfig({name: 'subscription', resettable: true })
export class SubscriptionStore extends Store<SubscriptionState> {
    constructor() {
        super(createInitialState());
    }

    public addPatientSubscription(subscription: SubscriptionModel) {
        this.update({
            patientSubscriptions: [...this.getValue().patientSubscriptions, subscription]
        });
    }

    public updatePatientSubscription(subscription: SubscriptionModel) {
        const patientSubscriptions = this.getValue().patientSubscriptions;
        if (patientSubscriptions.find(x => x.id === subscription.id)) {
            this.update({ patientSubscriptions: patientSubscriptions.map(x => x.id === subscription.id ? subscription : x) });
        } else {
            this.update({
                patientSubscriptions: [...patientSubscriptions, subscription]
            });
        }
    }
}

export const subscriptionStore = new SubscriptionStore();
