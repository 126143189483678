import {
  Box,
  ListItem,
  Step,
  StepIconProps,
  StepLabel,
  Stepper,
  StepContent,
  Collapse
} from "@material-ui/core";
import React, { useMemo } from "react";
import { PatientConversationModel } from "../../../models/conversation.models";
import { useStyles, UnreadMessages } from "./mobileCollapseHistoryComponent.styles";
import moment from "moment";
import { ConversationState } from "../../../models/conversationState.models";
import { WildHealthStatusLabel } from "../../../../common/components/wildHealthStatusLabel/WildHealthStatusLabel";
import { handleCompare } from "../../../../common/sorting/helpers/handle-compare";
import { SortingDirection } from "../../../../common/sorting/models/sorting-destination";
import clsx from "clsx";
import CheckIcon from "@material-ui/icons/Check";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { ReactComponent as Active } from "@img/stepperIcons/stepActive.svg";
import { toCurrentTimeZone } from "../../../../timezones/helpers/timezone";

interface CollapseHistoryComponentProps {
  conversations: PatientConversationModel[];
  targetConversation?: PatientConversationModel;
  height?: string;
  handleSelect: (PatientConversationModel) => void;
  isOpenCollapse?: boolean;
  isClosed?: boolean;
}

export const MobileCollapseComponent: React.FC<
  CollapseHistoryComponentProps
> = (props: CollapseHistoryComponentProps) => {
  const classes = useStyles();
  const { conversations, targetConversation, handleSelect, isOpenCollapse, isClosed } = props;

  const filteredConversations = useMemo(() => {
    if (!conversations?.length) {
      return [];
    } else {
      const conversationsSorting = !isClosed 
        ? conversations.filter(x => x.state !== ConversationState.Closed).sort((a, b) => 
            handleCompare(a, b, SortingDirection.Asc, "startDate")
          )
        : conversations.filter(x => x.state === ConversationState.Closed).sort((a, b) => 
            handleCompare(a, b, SortingDirection.Asc, "startDate")
          );
      return conversationsSorting;
    }
  }, [conversations]);

  const renderStepIcon = (isActive: boolean) => {
    return (props: StepIconProps) =>
      isActive ? <Active className={classes.activeIcon} /> : <CheckCircleIcon className={classes.checkIcon} />;
  };

  return (
    <Collapse in={isOpenCollapse} timeout="auto" unmountOnExit>
      <Stepper orientation="vertical" className={classes.stepper}>
        {filteredConversations.map((conversation, index) => {
          const isActive = conversation.state !== ConversationState.Closed;
          const selected = conversation.id === targetConversation?.id;
          const isLast = index !== filteredConversations.length - 1;

          return (
            <Step key={index} active={true} onClick={() => handleSelect(conversation)}>
              <StepLabel StepIconComponent={renderStepIcon(isActive)}>
                <Box display="flex" justifyContent="space-between" width={1}>
                  <Box>
                    <UnreadMessages badgeContent={conversation.unreadMessages}>
                      <WildHealthStatusLabel
                        color={selected ? "selected" : isActive ? "good" : "neutral"}
                      >
                        {`Subject: ${conversation.subject}`}
                      </WildHealthStatusLabel>
                    </UnreadMessages>
                  </Box>
                </Box>
              </StepLabel>
              <StepContent className={classes.step}>
                <ListItem
                  id={`staff-conversations-history-select-${conversation.id}`}
                  className={clsx(classes.item, {
                    [classes.stepSelected]: selected,
                    [classes.stepBorder]: isLast,
                  })}
                  button
                >
                  <Box className={classes.statusDateContainer}>
                    <span className={classes.statusDate}>
                      {`Started: (${moment(
                        toCurrentTimeZone(conversation.startDate)
                      ).format("MMMM DD, yyyy")})`}
                    </span>
                    {!isActive && (
                      <span className={classes.statusDate}>
                        {`Closed: (${moment(
                            toCurrentTimeZone(conversation.stateChangeDate)
                          ).format("MMMM DD, yyyy")})`}
                      </span>
                    )}
                  </Box>
                  <Box display="flex" alignItems="center">
                    {selected && (
                      <CheckIcon
                        fontSize="small"
                        className={classes.checkIcon}
                        data-testid="selected"
                      />
                    )}
                  </Box>
                </ListItem>
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
    </Collapse>
  );
};
