import Axios from "axios-observable";
import { Observable, Subject } from "rxjs";
import { authHeader } from "../../common/helpers/auth-header";
import { snackService } from "../../common/snack/state";
import { InsuranceVerificationDetailsModel, InsuranceVerificationModel, VerificationsHistoryModel} from "../models/insurance.models";
import { insuranceStore, InsuranceStore } from "../stores/insurance.store";
import { CallbackModel } from "../../common/models/callback.model";

/**
 * Provides method for working with Insurance
 */
export class InsuranceVerificationService {
    private urlVerification = `${process.env.REACT_APP_API_URL}InsuranceVerification`;

    constructor(private insuranceStore: InsuranceStore) {
    }

    public onInsuranceVerificationDetials = new Subject<CallbackModel<number>>();

    public requestInsuranceVerificationDetails = (insuranceVerificationId: number): Observable<InsuranceVerificationDetailsModel> => {
        return new Observable(observer => {
            this.onInsuranceVerificationDetials.next({
                data: insuranceVerificationId,
                callback: (result: InsuranceVerificationDetailsModel) => {
                    if (result) {
                        observer.next(result);
                    }
                    observer.complete();
                }
            });
        });
    }

    public verifyInsurance(model: InsuranceVerificationModel): Observable<InsuranceVerificationModel> {
        let url = `${this.urlVerification}/InsuranceVerification`;
        const config = { headers: authHeader() }

        return new Observable<InsuranceVerificationModel>(observer => {
            Axios.post<InsuranceVerificationModel>(url, model, config)
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next();
                        observer.complete();
                        return response.data;
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.next(error);
                        observer.error();
                    }
                );
        })
    }

    /**
* Get insurance verification history
*/

    public getInsuranceVerification(patientId: number): Observable<VerificationsHistoryModel[]> {
        let url = `${this.urlVerification}/InsuranceVerificationsPatient?patientId=${patientId}`;
        const config = { headers: authHeader() }

        return new Observable(observer => {
            Axios.get<VerificationsHistoryModel[]>(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.insuranceStore.update({ verificationList: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.next(error);
                        observer.error();
                    }
                );
        })
    }

    public runInsuranceVerification(coverageId: number): Observable<VerificationsHistoryModel> {
        let url = `${this.urlVerification}/RunVerification/Coverage/${coverageId}`;
        const config = { headers: authHeader() }

        return new Observable(observer => {
            Axios.get<VerificationsHistoryModel>(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next(response.data);
                        observer.complete();
                        return response.data;
                    },
                    error => {
                        observer.next(error);
                        observer.error();
                    }
                );
        })
    }

    public getInsuranceVerificationDetails(insuranceVerificationId: number): Observable<InsuranceVerificationDetailsModel> {
        let url = `${this.urlVerification}/${insuranceVerificationId}`;
        const config = { headers: authHeader() }

        return new Observable(observer => {
            Axios.get<InsuranceVerificationDetailsModel>(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next(response.data);
                        observer.complete();
                        return response.data;
                    },
                    error => {
                        observer.next(error);
                        observer.error();
                    }
                );
        })
    }
}

export const insuranceVerificationService = new InsuranceVerificationService(insuranceStore);
