import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { onEmit } from "../../../common/helpers/on-emit";
import { NotificationModel, NotificationType } from "../../../notifications/models/notifications.models";
import { notificationsQuery } from "../../../notifications/stores/notifications";
import { patientsService } from "../../../patients/services/patients.service";
import { authQuery } from "../../../auth/stores/auth";
import { patientsQuery } from "../../../patients/stores/patientsStore";

interface NotificationsComponentState {
    isLoading: boolean;
    notifications: NotificationModel[];
    anchorEl: HTMLButtonElement;
    showTimeline: boolean;
}

const invisibleTypes = [
    NotificationType.UnreadMessagesCount,
    NotificationType.NewSupportTicket,
    NotificationType.NewMessage,
    NotificationType.UnreadMessagesManual
];


export function useFacade(patientId: number | null): [
  NotificationsComponentState,
  (status: boolean) => void
] {
    const [state, setState] = useState({
        isLoading: true,
        notifications: [],
        anchorEl: null,
        showTimeline: true
    } as NotificationsComponentState);

    const handleToggle = (status: boolean) => {
        setState(state => ({ ...state, showTimeline: status }));
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<NotificationModel[]>(notificationsQuery.notifications$, notifications => {
                const visibleNotifications = notifications?.filter(x => !invisibleTypes.includes(x.type)) ?? [];
                setState(state => ({
                    ...state,
                    notifications: visibleNotifications,
                }));
            })
        ];

        const cb = () => setState(state => ({...state, isLoading: false}))

        const isPatientPremium = patientId ? patientsQuery.getTargetPatientIsPremium() : authQuery.getIsPremium();
        const hasActiveStandardPlan = patientId ? patientsQuery.getTargetPatientSubscription()?.isActive && patientsQuery.getTargetPatientSubscription()?.paymentPlan?.displayName === "Standard" : authQuery.isSubscriptionStatusActive() && authQuery.getPlan() === "Standard";

        if (patientId) {
            if (!isPatientPremium && hasActiveStandardPlan) {
                patientsService.getTodoPatientJourneyTasksByEmployee(patientId).subscribe(cb, cb)
            } else {
                setState(state => ({
                    ...state,
                    showTimeline: false,
                }));
            }
        } else {
            if (!isPatientPremium && hasActiveStandardPlan) {
                patientsService.getTodoPatientJourneyTasks().subscribe(cb, cb)
            } else {
                setState(state => ({
                    ...state,
                    showTimeline: false,
                }));
            }
        }

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [state, handleToggle];
}