import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { onEmit } from "../../../common/helpers/on-emit";
import { notificationsQuery } from "../../../notifications/stores/notifications";
import { NotificationModel, NotificationType } from "../../../notifications/models/notifications.models";
import { ConversationType, EmployeeConversationModel, PatientConversationModel } from "../../models/conversation.models";
import { employeeConversationsQuery } from "../../stores/employeeConversationsStore/employeeConversations.query";
import { patientConversationsQuery } from "../../stores/patientConversationsStore/patientConversations.query";
import { authQuery } from "../../../auth/stores/auth";
import { UserType } from "../../../auth/models/auth.enums";
import { ContactsOutlined } from "@material-ui/icons";
import { Console } from "console";

const interestedTypes = [
    NotificationType.NewMessage,
    NotificationType.UnreadMessagesManual
];

interface UnreadMessagesBadgeComponentState {
    conversations: EmployeeConversationModel[] | PatientConversationModel[];
    conversationHealthCare: PatientConversationModel[],
    conversationSupport: PatientConversationModel[],
}

let context: UnreadMessagesBadgeComponentState = null;

export function useFacade(): [boolean, ConversationType[]] {
    const [newMessages, setNewMessages] = useState(false);
    const [conversationTypes, setConversationTypes] = useState([] as ConversationType[]);
    const [state, setState] = useState({
        conversations: [],
        conversationHealthCare: [],
        conversationSupport: [],
    } as UnreadMessagesBadgeComponentState);

    context = state

    const hasNewMessages = (conversations: EmployeeConversationModel[] | PatientConversationModel[]): boolean => {
        return conversations.filter(c => c.unreadMessages > 0).length > 0
    }

    const getConversationTypes = (conversations: EmployeeConversationModel[] | PatientConversationModel[]): ConversationType[] => {
        const conversationTypes = conversations.filter(c => c.unreadMessages > 0).map(c => c.type);

        // Map support and healthcare to the same type. They both render a red badge. If health care and support are both present,  
        // then two red badges will be rendered when we only want one
        const filteredConversationTypes = conversationTypes.map(c => c === ConversationType.Support ? ConversationType.HealthCare : c);

        return [...new Set(filteredConversationTypes)];
    }

    const setUnreadMessages = (conversations: EmployeeConversationModel[] | PatientConversationModel[]) => {

        if (conversations && conversations.length) {
            setNewMessages(() => (hasNewMessages(conversations)));
            setConversationTypes(() => getConversationTypes(conversations));
        } else {
            setNewMessages(() => false);
        }
    }

    useEffect(() => {
        if (state.conversationHealthCare.length || state.conversationSupport.length) {
            setUnreadMessages([...state.conversationHealthCare, ...state.conversationSupport])
        }
    }, [state.conversationHealthCare, state.conversationSupport]);

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<EmployeeConversationModel[]>(employeeConversationsQuery.conversations$, conversations => {
                if (authQuery.getType() === UserType.Employee) {
                    setState(state => ({
                        ...state,
                        conversations: [
                            ...conversations,
                        ]
                    }));
                    setUnreadMessages(conversations);
                }
            }),
            onEmit<PatientConversationModel>(patientConversationsQuery.heathCareConversation$, conversation => {
                if (conversation && authQuery.getType() === UserType.Patient) {
                    setState(state => ({
                        ...state,
                        conversationHealthCare: [conversation]
                    }));
                }
            }),
            onEmit<PatientConversationModel[]>(patientConversationsQuery.supportConversations$, conversations => {
                if (authQuery.getType() === UserType.Patient) {
                    setState(state => ({
                        ...state,
                        conversationSupport: conversations
                    }));
                }
            }),
        ];

        return (() => {
            subscriptions.map(it => it.unsubscribe())
        })
    }, []);

    return [newMessages, conversationTypes];
}