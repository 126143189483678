import {useState} from 'react';
import {confirmService} from "../../../../services/confirm.service";

export interface ConfirmInsuranceDialogState {
    isOpen: boolean;
    handleAnswer: Function;
}

export function useFacade(): [ConfirmInsuranceDialogState, Function]{

    const [state, setState] = useState({
        isOpen: false,
    } as ConfirmInsuranceDialogState);

    const handleAnswer = (result: boolean) => {
        setState({...state, isOpen: false});
        state.handleAnswer(result);
        setState({...state, isOpen: false});
    }

    confirmService.onQuestionInsurance.subscribe((question) => {
        setState({...state, 
            isOpen: true, 
            handleAnswer: question.callback});
    })

    return [state, handleAnswer];
}