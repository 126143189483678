import React from 'react';
import {useFacade} from "./initialQuestionnaireResultComponent.hooks";
import {Box} from "@material-ui/core";
import {QuestionnaireProgressBarComponent} from "../questionnaireProgressBarComponent/questionnaireProgressBarComponent";
import {GeneralSurveyResult} from "./pages/GeneralSurveyResult";
import {MedicalSurveyResult} from "./pages/MedicalSurveyResult";
import {NutritionSurveyResult} from "./pages/NutritionSurveyResult";
import {ExerciseSurveyResult} from "./pages/ExerciseSurveyResult";
import {CardioRespiratorySurveyResult} from "./pages/CardioRespiratorySurveyResult";
import {SleepSurveyResult} from "./pages/SleepSurveyResult";
import {StressSurveyResult} from "./pages/StressSurveyResult";
import {QuestionnaireModel, QuestionnairePages, QuestionnaireResultModel} from "../../models/questionnaire.models";
import {Gender} from "../../../common/models/user.models";

export interface InitialQuestionnaireResultComponentProps {
    result: QuestionnaireResultModel;
    questionnaire: QuestionnaireModel;
    gender: Gender;
}

export const InitialQuestionnaireResultComponent: React.FC<InitialQuestionnaireResultComponentProps> = (props: InitialQuestionnaireResultComponentProps) => {
    const [
        {
            currentPage,
            questionnaire,
            result,
        },
        getNavigationContext,
    ] = useFacade(props.result, props.questionnaire);

    return <InitialQuestionnaireResultBaseComponent
        gender={props.gender}
        result={result}
        currentPage={currentPage}
        questionnaire={questionnaire}
        getNavigationContext={getNavigationContext}/>
}

export interface InitialQuestionnaireResultBaseComponentProps extends InitialQuestionnaireResultComponentProps {
    getNavigationContext: Function;
    currentPage: QuestionnairePages;
    gender: Gender;
}

export const InitialQuestionnaireResultBaseComponent: React.FC<InitialQuestionnaireResultBaseComponentProps> = (props: InitialQuestionnaireResultBaseComponentProps) => {
    const {
        currentPage,
        questionnaire,
        getNavigationContext,
        result,
        gender
    } = props;

    const pages = [
        <GeneralSurveyResult
            gender={gender}
            result={result}
            questionnaire={questionnaire}
            navigationContext={getNavigationContext()}
        />,
        <MedicalSurveyResult
            gender={gender}
            result={result}
            questionnaire={questionnaire}
            navigationContext={getNavigationContext()}
        />,
        <NutritionSurveyResult
            gender={gender}
            result={result}
            questionnaire={questionnaire}
            navigationContext={getNavigationContext()}
        />,
        <ExerciseSurveyResult
            gender={gender}
            result={result}
            questionnaire={questionnaire}
            navigationContext={getNavigationContext()}
        />,
        <CardioRespiratorySurveyResult
            gender={gender}
            result={result}
            questionnaire={questionnaire}
            navigationContext={getNavigationContext()}
        />,
        <SleepSurveyResult
            gender={gender}
            result={result}
            questionnaire={questionnaire}
            navigationContext={getNavigationContext()}
        />,
        <StressSurveyResult
            gender={gender}
            result={result}
            questionnaire={questionnaire}
            navigationContext={getNavigationContext()}
        />
    ]

    return (
        <Box p={4}>
            <Box mb={5}>
                <QuestionnaireProgressBarComponent currentPage={currentPage}/>
            </Box>
            {
                pages[currentPage]
            }
        </Box>
    )
}