import {
    Box,
    Container,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    MenuItem,
    Select,
    TextField,
    useMediaQuery,
    useTheme,
    Link,
    Typography,
    Breadcrumbs
} from '@material-ui/core';
import React, { useMemo } from 'react';
import { UpdateAddressForm } from "../../../common/components/updateAddressTestForm/UpdateAddressForm";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { PageSliderNavigationContext } from "../../../common/models/navigation.models";
import { BillingSummaryComponent } from "../billingSummaryTestComponent/BillingSummaryComponent";
import { WelcomePatientInfoComponent } from '../welcomePatientInfoComponent/WelcomePatientInfoComponent';
import { PatientInfoComponent } from '../patientInfoRedesignComponent/PatientInfoRedesignComponent';
import { ApplyPaymentCouponComponent } from '../paymentCouponTestComponent/ApplyPaymentCouponComponent';
import { PaymentCouponAppliedComponent } from '../paymentCouponTestComponent/PaymentCouponAppliedComponent';
import { PaymentSummaryComponent } from "../paymentSummaryTestComponent/PaymentSummaryComponentGetStarted";
import { GetStartedStep, useFacade, HealthConcernStatus } from "./FinishCheckoutComponent.hooks";
import { useStyles } from "./finishCheckoutComponent.styles";
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from 'clsx';
import { RegistrationBackButton } from '../registrationButton/RegistrationBackButton';
import { RegistrationNextButton } from '../registrationButton/RegistrationNextTestButton';
import { PageSliderComponent } from "../../../common/components/PageSliderComponent";
import { PaymentCardComponent } from "../paymentCardTestComponent/PaymentCardComponent";
import { PayButtonComponent } from "../payButtonComponent/PayButtonComponent";
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { ReactComponent as VisaCardLogo } from '@img/getStarted/VisaCardLogo.svg';
import { ReactComponent as MastercardLogo} from '@img/getStarted/mastercardLogo.svg';
import { ReactComponent as AMEXCardLogo } from '@img/getStarted/AMEXCardLogo.svg';
import { ReactComponent as DiscoverCardLogo } from '@img/getStarted/DiscoverCardLogo.svg';
import { ReactComponent as DinersCardLogo } from '@img/getStarted/DinersCardLogo.svg';
import { ReactComponent as JCBCardLogo } from '@img/getStarted/JCBCardLogo.svg';
import { ReactComponent as UnionPayCardLogo } from '@img/getStarted/UnionPayCardLogo.svg';
import { ReactComponent as ProvenResultsDesktop } from '@img/getStarted/ProvenResultsDesktop.svg';
import { ReactComponent as ProvenResultsMobile } from '@img/getStarted/ProvenResultsMobile.svg';
import { ReactComponent as InsuranceIcon } from '@img/icons/InsuranceIcon.svg';
import { ReactComponent as GridLockIcon } from '@img/icons/GridLock.svg';
import { ReactComponent as StarIcon } from '@img/icons/StarIcon.svg';
import WorkingTogether from '@img/getStarted/Together.png';
import SandraAvatar from '@img/getStarted/SandraAvatar.png';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { navigationService } from "../../../../services/navigation.service";
import {
    InsuranceCheckoutRedesignComponent
} from "../../../insurance/components/insuranceComponent/InsuranceCheckoutComponent";
import { RegistrationSkipRedesignButton } from "../registrationButton/RegistrationSkipButton";
import { PaymentPlanModel } from '../../models/paymentPlan.models';
import { getPlanForAutoSelect } from "../../helpers/getPlanForAutoSelect";
import { WildHealthCheckbox } from '../../../common/components/WildHealthCheckbox';
import { SelectAddOnComponent } from '../../../addons/components/selectAddOnTestComponent/selectAddOnComponent';
import { colors } from '../../../common/constants/colors';
import {WildHealthButton} from "../../../common/components/wildHealthButton/WildHealthButton";
import {ExternalAuthProvider} from "../../../auth/models/auth.enums";
import {FeatureFlag} from "../../../common/components/featureFlags/featureFlags.models";
import {FeatureComponent} from "../../../common/components/featureFlags/FeatureComponent";

interface FinishCheckoutComponentProps {
    plans: PaymentPlanModel[];
    specifiedPlan?: string;
    defaultPlan?: string;
    navigationContext: PageSliderNavigationContext;
    employerKey?: string;
    topBannerHeight?: number;
    handleSetProgress: (progress: number) => void;
}

export const FinishCheckoutExperimentComponent: React.FC<FinishCheckoutComponentProps> = (props: FinishCheckoutComponentProps) => {
    const {
        plans,
        specifiedPlan = null,
        defaultPlan = null,
        navigationContext,
        employerKey = "",
        topBannerHeight = 0,
        handleSetProgress
    } = props;

    const [
        {
            paymentPlan,
            paymentPeriod,
            paymentPrice,
            publicKey,
            addOns,
            couponCode,
            coupon,
            isCouponApplied,
            paymentPriceType,
            payer,
            errors,
            sameAsBillingAddress,
            leadSources,
            selectedLeadSource,
            step,
            isSubmitted,
            isCardExist,
            cardSaved,
            isAgreeSMS,
            isAgreeContinue,
            paymentCardInfo,
            isLoading,
            isEmailSent,
            cardName,
            healthConcernStatus,
            showBottom,
            enterDiscountCode,
            isGetUserInfo,
            isPaidByAppleOrGoogle
        },
        isMarketingSMSButton,
        isConsultationGetStartedPath,
        handleHasInsurance,
        handleTokenChange,
        handlePriceSelect,
        handleChanges,
        renderRecaptcha,
        handleToggleSelectingAddOn,
        getSelectedAddOns,
        handleCouponChanges,
        applyCoupon,
        resetCoupon,
        handleSameAsBillingAddress,
        handleLeadSourceChanges,
        handleSubmitNextStep,
        handleSubmitBackStep,
        handleAgree,
        isCheckedWelcomePatient,
        isCheckedNameInfo,
        isCheckedPersonalInfo,
        goToLoginPage,
        goToResetPasswordStep,
        goToHaveAccountStep,
        isCheckedShippingDetail,
        isCheckedPaymentCardDetail,
        handleChangeHealthConcernStatus,
        handleEnterDiscountCode,
        isMedicareFlow,
        prevScrollY,
        handleOnBlurValidate,
        goToAuth2LoginPage,
        handleSetPaid
    ] = useFacade(employerKey, handleSetProgress);

    const classes = useStyles({ topBannerHeight });
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const stripePromise = useMemo(() => loadStripe(`${publicKey}`), [publicKey]);
    const isPlanAutoSelect = Boolean(getPlanForAutoSelect(plans, specifiedPlan, defaultPlan));

    if (!publicKey || isGetUserInfo) {
        return <WildHealthLinearProgress />
    }
    
    const renderHealthConcernStep = () => (
        <Box className={classes.concernMain}>
            <Box textAlign="center">
                <span className={classes.title}>What’s your main Goal?</span>
            </Box>
            <Box mt={isSmallScreen ? 2 : 4.5} p={2} className={clsx({
                [classes.concernBorderActive]: healthConcernStatus === HealthConcernStatus.MaximizeHealthSpanLongevity,
                [classes.concernBorder]: healthConcernStatus !== HealthConcernStatus.MaximizeHealthSpanLongevity
            })}>
                <WildHealthCheckbox
                    id="maximize-health-span-concern"
                    fill={colors.lightMint}
                    label={<span className={clsx(commonClasses.colorCharcoal, {
                        [commonClasses.size18]: !isSmallScreen,
                    })}>Maximize health span &amp; longevity</span>}
                    checkStatus={healthConcernStatus === HealthConcernStatus.MaximizeHealthSpanLongevity}
                    handleCheck={() => handleChangeHealthConcernStatus(HealthConcernStatus.MaximizeHealthSpanLongevity)}
                />
            </Box>
            <Box mt={isSmallScreen ? 2 : 3} p={2} className={clsx({
                [classes.concernBorderActive]: healthConcernStatus === HealthConcernStatus.TreatNewChronicConditions,
                [classes.concernBorder]: healthConcernStatus !== HealthConcernStatus.TreatNewChronicConditions
            })}>
                <WildHealthCheckbox
                    id="treat-new-chronic-conditions"
                    fill={colors.lightMint}
                    label={<span className={clsx(commonClasses.colorCharcoal, {
                        [commonClasses.size18]: !isSmallScreen,
                    })}>Treat new or chronic conditions</span>}
                    checkStatus={healthConcernStatus === HealthConcernStatus.TreatNewChronicConditions}
                    handleCheck={() => handleChangeHealthConcernStatus(HealthConcernStatus.TreatNewChronicConditions)}
                />
            </Box>
            <Box mt={isSmallScreen ? 2 : 3} p={2} className={clsx({
                [classes.concernBorderActive]: healthConcernStatus === HealthConcernStatus.OptimizeMyOverallHealth,
                [classes.concernBorder]: healthConcernStatus !== HealthConcernStatus.OptimizeMyOverallHealth
            })}>
                <WildHealthCheckbox
                    id="optimize-my-overall-health"
                    fill={colors.lightMint}
                    label={<span className={clsx(commonClasses.colorCharcoal, {
                        [commonClasses.size18]: !isSmallScreen,
                    })}>Optimize my overall health</span>}
                    checkStatus={healthConcernStatus === HealthConcernStatus.OptimizeMyOverallHealth}
                    handleCheck={() => handleChangeHealthConcernStatus(HealthConcernStatus.OptimizeMyOverallHealth)}
                />
            </Box>
            <Box mt={isSmallScreen ? 2 : 3} p={2} className={clsx({
                [classes.concernBorderActive]: healthConcernStatus === HealthConcernStatus.ElevateMyPerformance,
                [classes.concernBorder]: healthConcernStatus !== HealthConcernStatus.ElevateMyPerformance,
            })}>
                <WildHealthCheckbox
                    id="elevate-my-performance"
                    fill={colors.lightMint}
                    label={<span className={clsx(commonClasses.colorCharcoal, {
                        [commonClasses.size18]: !isSmallScreen,
                    })}>Elevate my performance</span>}
                    checkStatus={healthConcernStatus === HealthConcernStatus.ElevateMyPerformance}
                    handleCheck={() => handleChangeHealthConcernStatus(HealthConcernStatus.ElevateMyPerformance)}
                />
            </Box>
        </Box>
    )
    
    const renderClinicallyProvenResultsStep = () => (
        <Box className={classes.clinicallyProvenResultsMain}>
            <Box textAlign="center">
                <span className={classes.title}>Clinically Proven Results</span>
            </Box>
            <Box display="flex" justifyContent="center" mt={isSmallScreen ? 3 : 5}>
                {isSmallScreen ? <ProvenResultsMobile width="100%" /> : <ProvenResultsDesktop width="100%" />}
            </Box>
            <Box mt={2.5} textAlign="center">
                {!isSmallScreen && (
                    <Box textAlign="center" pt={3} pb={2}>
                        <span className={clsx(commonClasses.size24, commonClasses.textMedium, commonClasses.colorNavy)}>Clinically Proven Results</span>
                    </Box>
                )}
                <Grid container justify="center">
                    <Grid item justify="center" xs={4} md={4} lg={4}>
                        <Box display="flex" alignItems="center" justifyContent="center">
                            <ArrowDownwardIcon className={classes.downIcon} />
                            <span className={clsx(commonClasses.size34, commonClasses.textMedium, commonClasses.colorNavy)}>69</span>
                            <span className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorGray700,
                                isSmallScreen && '-wh-tw-mt-3')}>%</span>
                        </Box>
                        <Box px={2} className={clsx(commonClasses.textRegular, commonClasses.colorGray700, {
                            [commonClasses.size14]: !isSmallScreen,
                            [commonClasses.size12]: isSmallScreen,
                        })}>{isSmallScreen ? `Reduction in Inflammation` : `Reduction in inflammation`}</Box>
                    </Grid>
                    <Grid item justify="center" xs={4} md={4} lg={4}>
                        <Box display="flex" alignItems="center" justifyContent="center">
                            <ArrowUpwardIcon className={classes.downIcon} />
                            <span className={clsx(commonClasses.size34, commonClasses.textMedium, commonClasses.colorNavy)}>47</span>
                            <span className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorGray700,
                                isSmallScreen && '-wh-tw-mt-3')}>%</span>
                        </Box>
                        <Box className={clsx(commonClasses.textRegular, commonClasses.colorGray700, {
                            [commonClasses.size14]: !isSmallScreen,
                            [commonClasses.size12]: isSmallScreen,
                        })}>{isSmallScreen ? `Improvement of Diabetic Markers` : `Improvement of diabetic markers`}</Box>
                    </Grid>
                    <Grid item justify="center" xs={4} md={4} lg={4}>
                        <Box display="flex" alignItems="center" justifyContent="center">
                            <ArrowDownwardIcon className={classes.downIcon} />
                            <span className={clsx(commonClasses.size34, commonClasses.textMedium, commonClasses.colorNavy)}>58</span>
                            <span className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorGray700,
                                isSmallScreen && '-wh-tw-mt-3')}>%</span>
                        </Box>
                        <Box className={clsx(commonClasses.textRegular, commonClasses.colorGray700, {
                            [commonClasses.size14]: !isSmallScreen,
                            [commonClasses.size12]: isSmallScreen,
                        })}>{isSmallScreen ? `Reduced Risk of Cardiovascular Disease` : `Reduced cardiovascular disease`}</Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
    
    const renderWorkingTogetherStep = () => (
        <Box className={classes.clinicallyProvenResultsMain}>
            <Box display="flex" justifyContent="center">
                <img src={WorkingTogether} className={classes.workingTogetherImage} />
            </Box>
            <Box textAlign="center" px={4} mt={3.5}>
                <span className={classes.title}>Working Together to Deliver the Health Results You Deserve</span>
            </Box>
            <Box mt={isSmallScreen ? "14px" : 5} px={isSmallScreen && 2} textAlign="center">
                <span className={clsx(commonClasses.colorGray700, commonClasses.textRegular, {
                    [commonClasses.size14]: isSmallScreen,
                })}>Comprehensive and collaborative care is what makes our unparalleled patient outcomes possible. Beyond their individual expertise, your providers work together to execute on a personalized and highly effective approach to transform your health for good.</span>
            </Box>
        </Box>
    )

    const renderCreateAccountStep = () => (
        <>
            <Box textAlign="center" px={isSmallScreen ? 3 : 10}>
                <span className={classes.title}>Create an Account to Start Your Personal Health Journey</span>
            </Box>
            <Box className={classes.inputFieldMain}>
                <Box p={2} mt={3} display="flex" className={classes.securityBlock}>
                    <Box mr={1.25}>
                        <GridLockIcon />
                    </Box>
                    <Box className={clsx(commonClasses.colorWhite, {
                        [commonClasses.size14]: !isSmallScreen,
                        [commonClasses.size12]: isSmallScreen,
                    })}>Patient privacy is our highest priority. We encrypt all confidential data, and never share or sell your genetic information with anyone.</Box>
                </Box>
                <WelcomePatientInfoComponent
                    payer={payer}
                    handleChanges={handleChanges}
                    hiddenFields={[]}
                    readonlyFields={[]}
                    errors={errors}
                />
                <Box mt={2} display="flex" alignItems="center">
                    <WildHealthCheckbox
                        id="Agree-Continue-CheckBox"
                        label={''}
                        fill={colors.lightMint}
                        checkStatus={isAgreeContinue}
                        handleCheck={(event) => handleAgree(event, 'isAgreeContinue')}
                    />
                    <span className={clsx(commonClasses.size12, commonClasses.colorCharcoal)}>
                        By checking this box, you acknowledge and agree to the
                        <span onClick={() => navigationService.toTermsOfUse()} className={classes.termsPolicyLink}>Terms of Use</span>
                        <span>, </span>
                        <span onClick={() => navigationService.toTermsOfService()} className={classes.termsPolicyLink}>Terms of Service</span>
                        <span>, </span>
                        <span onClick={() => navigationService.toPrivacyPolicy()} className={classes.termsPolicyLink}>Privacy Policy</span>
                        <span>, and</span>
                        <span onClick={() => navigationService.toNoticeOfPrivacy()} className={classes.termsPolicyLink}>Notice of Privacy Practices</span>.
                    </span>
                </Box>

                <FeatureComponent featureFlag={FeatureFlag.Auth2Google}>
                    <Box mt={3} display="flex" justifyContent="center" alignItems="center" color={colors.charcoal}>
                        <WildHealthButton
                            size="large"
                            id="login-with-google"
                            onClick={(e) => goToAuth2LoginPage(ExternalAuthProvider.Google)}
                            fullWidth
                            borderRadius={50}
                            disabled={!isAgreeContinue}
                        >
                            Log In With Google
                        </WildHealthButton>
                    </Box>
                </FeatureComponent>
            </Box>
        </>
    )

    const renderHaveAccountStep = () => (
        <Box className={classes.inputFieldMain}>
            <Box textAlign="center">
                <span className={classes.title}>{isSmallScreen ? `You already have an account` : `Already a user?`}</span>
            </Box>
            <Box mt={3} textAlign="center" className={commonClasses.colorGray700}>
                <span>{isSmallScreen ? `There is already an account associated with that email. Login or reset your password.` : `This email is already associated with an account. Please login or reset your password.`}</span>
            </Box>
            <WelcomePatientInfoComponent
                payer={payer}
                handleChanges={handleChanges}
                hiddenFields={[]}
                readonlyFields={['email']}
                errors={errors}
            />
            {
                renderRecaptcha()
            }
        </Box>
    )

    const renderResetPasswordStep = () => (
        <Box className={classes.inputFieldMain}>
            <Box textAlign="center">
                <span className={classes.title}>Reset Password</span>
            </Box>
            <Box mt={3} px={2} display="flex" textAlign="center" flexDirection="column" className={commonClasses.colorGray700}>
                <span>Forgot your password? No Problem.</span>
                <span>Enter the email address assocaited with your account.</span>
            </Box>
            <WelcomePatientInfoComponent
                payer={payer}
                handleChanges={handleChanges}
                hiddenFields={['password']}
                readonlyFields={['email']}
                errors={errors}
            />
            {
                renderRecaptcha()
            }
        </Box>
    )
    
    const renderCongratulationsStep = () => (
        <Box className={classes.clinicallyProvenResultsMain}>
            <Box textAlign="center" mt={3}>
                <span className={classes.title}>Congratulations!</span>
            </Box>
            <Box textAlign="center" px={3} mt={3}>
                <span className={clsx(commonClasses.size20, commonClasses.textMedium, commonClasses.colorNavy)}>You made a commitment to yourself, and joined a community of thousands who are improving their health and wellbeing.</span>
            </Box>
            <Box mt={5} textAlign="center">
                <Box pt={1.5} pb={2.5} display="flex" alignItems="center" justifyContent="center">
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                </Box>
                <Box px={isSmallScreen ? 2 : 2.5}>
                    <span className={clsx(commonClasses.textRegular, commonClasses.colorNavy, {
                        [commonClasses.size14]: isSmallScreen,
                    })}>“It took only a few weeks to feel the effects of implementing the diet and supplement recommendations from my care team. I can’t wait to see my progress over time.”</span>
                </Box>
            </Box>
            <Box display="flex" justifyContent="center" mt={2.5}>
                <img src={SandraAvatar} className={classes.sandraAvatar} />
            </Box>
            <Box textAlign="center" mt={2.5}>
                <span className={clsx(commonClasses.size14, commonClasses.textSemiBold, commonClasses.colorNavy)}>Sandra M.</span>
            </Box>
        </Box>
    )

    const renderNameInterstitialStep = () => (
        <Box className={classes.inputFieldMain}>
            <Box textAlign="center">
                <span className={classes.title}>Let’s get acquainted.</span>
            </Box>
            <PatientInfoComponent
                payer={payer}
                handleChanges={handleChanges}
                hiddenFields={['phoneNumber', 'state']}
                readonlyFields={[]}
                errors={errors}
                handleValidate={handleOnBlurValidate}
            />
        </Box>
    )

    const displayOtherLeadSource = () => {
        const leadSource = leadSources.find(x => x.id === selectedLeadSource.leadSourceId);
        if (leadSource === undefined || !leadSource.isOther)
            return <></>

        return <Box mt={3}>
            <TextField
                fullWidth
                required
                size="small"
                type="text"
                label="Other Source"
                InputProps={{ className: 'input', classes }}
                id="selectedLeadSource.otherLeadSource"
                variant="outlined"
                helperText={errors['selectedLeadSource.otherLeadSource']}
                error={!!errors['selectedLeadSource.otherLeadSource']}
                value={selectedLeadSource.otherLeadSource}
                onChange={(v) => {
                    handleLeadSourceChanges(v.target.id, v.target.value)
                }}
            />
        </Box>
    }

    const displayPodcastSource = () => {
        const leadSource = leadSources.find(x => x.id === selectedLeadSource.leadSourceId && x.name === 'Podcast');
        if (leadSource === undefined)
            return <></>

        return <Box mt={3}>
            <TextField
                fullWidth
                size="small"
                type="text"
                label="Which podcast?"
                InputProps={{ className: 'input', classes }}
                id="selectedLeadSource.podcastSource"
                variant="outlined"
                value={selectedLeadSource.podcastSource}
                onChange={(v) => {
                    handleLeadSourceChanges(v.target.id, v.target.value)
                }}
            />
        </Box>
    }

    const renderAgreeSmstMarketing = () => (
        <Box mt={3}>
            <WildHealthCheckbox
                id="Agree-SMS"
                fill={colors.lightMint}
                label={<Box ml={1} component="div" display="flex" className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorCharcoal)}>
                    <span>
                        I agree to receive occasional SMS marketing communications
                    </span>
                </Box>}
                checkStatus={isAgreeSMS}
                handleCheck={(event) => handleAgree(event, 'isAgreeSMS')}
            />
        </Box>
    )

    const renderPersonalInfoStep = () => (
        <Box className={classes.inputFieldMain}>
            <Box textAlign="center">
                <span className={classes.title}>Hi, {payer.firstName}!</span>
            </Box>
            <Box textAlign="center" px={2} mt={4.5}>
                <span>Now, add a few more details.</span>
            </Box>
            <PatientInfoComponent
                payer={payer}
                handleChanges={handleChanges}
                hiddenFields={['firstName', 'lastName', 'birthday', 'gender']}
                readonlyFields={[]}
                errors={errors}
                handleValidate={handleOnBlurValidate}
            />
            {
                !paymentPlan?.isFounder &&
                <Box pt={1}>
                    <Box mb={1}>
                        <span className={classes.label}>How did you hear about us <span className={commonClasses.colorMint}>*</span></span>
                    </Box>
                    <Box>
                        <FormControl
                            variant="outlined"
                            classes={{ root: classes.root }}
                            color='primary'
                            required
                            error={!!errors[`selectedLeadSource`]}
                            size="small" fullWidth>
                            <Select
                                style={{ background: '#FFF' }}
                                id="selectedLeadSource.leadSourceId"
                                value={selectedLeadSource.leadSourceId === 0 ? '' : selectedLeadSource.leadSourceId}
                                error={!!errors[`selectedLeadSource.leadSourceId`]}
                                renderValue={() => {
                                    if (selectedLeadSource.leadSourceId === 0) {
                                        return <Box className={commonClasses.colorMediumGray}>Select</Box>;
                                    }

                                    return leadSources.find(source => source.id === selectedLeadSource.leadSourceId)?.name
                                }}
                                displayEmpty
                                onChange={(v) => handleLeadSourceChanges("selectedLeadSource.leadSourceId", v.target.value.toString())}
                            >
                                {
                                    leadSources.map((source, i) => {
                                        return <MenuItem key={i} value={source.id}>{source.name}</MenuItem>
                                    })
                                }
                            </Select>
                            <FormHelperText>{errors[`selectedLeadSource.leadSourceId`]}</FormHelperText>
                        </FormControl>
                    </Box>
                    {
                        displayOtherLeadSource()
                    }
                    {
                        displayPodcastSource()
                    }
                </Box>
            }
            {
                isMarketingSMSButton && renderAgreeSmstMarketing()
            }
        </Box>
    )

    const renderMarketingSMSSubCopy = () => (
        <Box pt={4} className={classes.inputFieldMain}>
            <span className={clsx(commonClasses.size12, commonClasses.textRegular, commonClasses.colorGray700)}>*By checking this box, you acknowledge and agree that Wild Health and Wellington Provider Group may use your contact information to send communications by email and text message, including automatically generated text messages, on Wild Health's products and other related educational content. I understand that my consent to receive these communications is not a condition of receiving any good or service. I also understand that message and data charges may apply.</span>
        </Box>
    )

    const renderAskInsuranceStep = () => (
        <>
            <Box display="flex" justifyContent="center">
                <InsuranceIcon />
            </Box>
            <Box mt={3} textAlign="center">
                <span className={classes.title}>Do you have insurance</span>
            </Box>
            <Box mt={2} px={!isSmallScreen && 11} textAlign="center">
                <span className={clsx(commonClasses.textMedium, commonClasses.colorGray700, {
                    [commonClasses.size18]: isSmallScreen,
                    [commonClasses.size20]: !isSmallScreen,
                })}>
                    We offer plans regardless of your insurance status, but it will help us determine the right plan for you.
                </span>
            </Box>
        </>
    )

    const renderAskMedicareStep = () => (
        <>
            <Box display="flex" justifyContent="center">
                <InsuranceIcon />
            </Box>
            <Box mt={3} textAlign="center">
                <span className={classes.title}>Do you have Medicare?</span>
            </Box>
            <Box mt={2} px={!isSmallScreen && 11} textAlign="center">
                <span className={clsx(commonClasses.textMedium, commonClasses.colorGray700, {
                    [commonClasses.size18]: isSmallScreen,
                    [commonClasses.size20]: !isSmallScreen,
                })}>
                    We offer plans regardless of your insurance status, but it will help us determine the right plan for you.
                </span>
            </Box>
        </>
    )


    const renderCaptureInsuranceStep = () => (
        <>
            <InsuranceCheckoutRedesignComponent
                user={payer}
                handleSubmit={() => handleSubmitNextStep()}
                handleCancel={() => handleHasInsurance(false)}
                isMedicareFlow={isMedicareFlow()}
            />
        </>
    )

    const renderOnnInsuranceStep = () => (
        <>
            <Box display="flex" justifyContent="center">
                <InsuranceIcon />
            </Box>
            <Box mt={3} textAlign="center">
                <span className={classes.title}>Not in network yet</span>
            </Box>
            <Box mt={2} px={isSmallScreen ? 1 : 11} pb={5} textAlign="center">
                <span className={clsx(commonClasses.textMedium, commonClasses.colorGray700, {
                    [commonClasses.size16]: isSmallScreen,
                    [commonClasses.size20]: !isSmallScreen,
                })}>
                    We are not in network yet with your insurance. However, we offer discounted cash pricing for out-of-network patients.
                </span>
            </Box>
        </>
    )

    const renderShippingStep = () => (
        <Box className={classes.inputFieldMain}>
            <Box textAlign="center">
                <span className={classes.title}>Shipping</span>
            </Box>
            <Box mt={2} display="flex" justifyContent="center">
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                    <Box className={clsx(commonClasses.textMedium, commonClasses.colorMain, {
                        [commonClasses.size12]: isSmallScreen,
                        [commonClasses.size14]: !isSmallScreen,
                    })}>
                        Shipping
                    </Box>
                    <Box className={clsx(commonClasses.textMedium, commonClasses.colorDarkGray, {
                        [commonClasses.size12]: isSmallScreen,
                        [commonClasses.size14]: !isSmallScreen,
                    })}>
                        Billing Info
                    </Box>
                    <Box className={clsx(commonClasses.textMedium, commonClasses.colorDarkGray, {
                        [commonClasses.size12]: isSmallScreen,
                        [commonClasses.size14]: !isSmallScreen,
                    })}>
                        Review
                    </Box>
                </Breadcrumbs>
            </Box>
            <Box mt={4.5}>
                <Box textAlign="center" px={2} mb={3}>
                    <span>Tell us where to send your Wild Health Genetic Test Kit — and any other surprises along the way.</span>
                </Box>
                <UpdateAddressForm
                    address={payer.shippingAddress}
                    prefix={"shippingAddress."}
                    errors={errors}
                    handleChanges={handleChanges}
                    handleValidate={handleOnBlurValidate}
                />
            </Box>
        </Box>
    )

    const renderSelectMembershipStep = () => (
        <Box className={classes.orderSummeryMain}>
            <Box textAlign="center">
                <span className={classes.title}>Select Your Membership Plan</span>
            </Box>
            <Box>
                <Box mt={3}>
                    <PaymentSummaryComponent
                        priceType={paymentPriceType}
                        selectedPeriod={paymentPeriod}
                        selectedPrice={paymentPrice}
                        handlePriceSelect={handlePriceSelect}
                        selectedPlan={paymentPlan}
                        isCouponApplied={isCouponApplied} />
                </Box>
                <Box>
                {
                    !paymentPlan.isTrial &&
                    <Box mt={2}>
                        <Box>
                        {
                            addOns.map((item) => {
                                return (
                                    <Box key={item.id} >
                                        <SelectAddOnComponent addOn={item} handleSelectingItem={handleToggleSelectingAddOn} />
                                    </Box>
                                )
                            })
                        }
                        </Box>
                    </Box>
                }
                </Box>
                <Box my={2}>
                    <Divider />
                </Box>
                <Box>
                    <BillingSummaryComponent
                        selectedPlan={paymentPlan}
                        selectedPeriod={paymentPeriod}
                        selectedPrice={paymentPrice}
                        selectedAddOns={getSelectedAddOns()}
                        hasDiscount
                    />
                </Box>
                <Box pt={2}>
                    {!enterDiscountCode ? (
                        <button id="add-promo-code-button" className={classes.btnLink} onClick={() => handleEnterDiscountCode()}>
                            <Box mr={1}>
                                <span>Add Promo Code</span>
                            </Box>
                            <AddIcon className={classes.addIcon} />
                        </button>
                    ) : (
                        <>
                            <Box mb={1}>
                                <span className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorCharcoal, commonClasses.uppercase)}>Promo code</span>
                            </Box>
                            <ApplyPaymentCouponComponent
                                handleCouponChanges={handleCouponChanges}
                                applyCoupon={applyCoupon}
                                couponCode={couponCode}
                            />
                        </>
                    )}
                    {
                        isCouponApplied &&
                        <PaymentCouponAppliedComponent paymentCoupon={coupon.code}
                            couponDiscount={coupon.detail}
                            handleRemove={resetCoupon} />
                    }
                </Box>
                <Box mt={3}>
                    <Elements stripe={stripePromise}>
                        <PayButtonComponent
                            selectedPrice={paymentPrice}
                            selectedAddOns={getSelectedAddOns()}
                            isPaid={isPaidByAppleOrGoogle}
                            handleSetPaid={handleSetPaid}
                        />
                    </Elements>
                </Box>
            </Box>
            {renderDefaultNextButton()}
        </Box>
    )

    const renderBillingStep = () => (
        <>
        <Box className={classes.inputFieldMain}>
            <Box textAlign="center">
                <span className={classes.title}>Billing</span>
            </Box>
            <Box mt={2} display="flex" justifyContent="center">
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                    <Box className={clsx(commonClasses.textMedium, commonClasses.colorDarkGray, {
                        [commonClasses.size12]: isSmallScreen,
                        [commonClasses.size14]: !isSmallScreen,
                    })}>
                        Shipping
                    </Box>
                    <Box className={clsx(commonClasses.textMedium, commonClasses.colorMain, {
                        [commonClasses.size12]: isSmallScreen,
                        [commonClasses.size14]: !isSmallScreen,
                    })}>
                        Billing Info
                    </Box>
                    <Box className={clsx(commonClasses.textMedium, commonClasses.colorDarkGray, {
                        [commonClasses.size12]: isSmallScreen,
                        [commonClasses.size14]: !isSmallScreen,
                    })}>
                        Review
                    </Box>
                </Breadcrumbs>
            </Box>
            <Box mt={4.5}>
                <Box textAlign="center" mb={3}>
                    <span>Input your payment information in order to complete your purchase. You will not be charged until you review your order.</span>
                </Box>
                <Box mb={1}>
                    <span className={classes.label}>Cardholder Name <span className={commonClasses.colorMint}>*</span></span>
                </Box>
                <TextField
                    fullWidth
                    size="small"
                    required
                    InputProps={{ className: 'input', classes }}
                    id="cardName"
                    placeholder="Name on Card"
                    value={cardName}
                    variant="outlined"
                    helperText={errors['cardName']}
                    error={!!errors['cardName']}
                    onChange={(v) => handleChanges(v.target.id, v.target.value)}
                    onBlur={() => handleOnBlurValidate(`cardName`)}
                />
            </Box>
            <Box mt={3}>
                <Elements stripe={stripePromise}>
                    <PaymentCardComponent
                        handleTokenChange={handleTokenChange}
                        isCardExist={isCardExist}
                        cardSaved={cardSaved}
                    />
                </Elements>
            </Box>
            <Box mt={sameAsBillingAddress ? 1.5 : 3}>
                {sameAsBillingAddress ? (
                    <WildHealthCheckbox
                        id="use-shipping-address"
                        fill={colors.lightMint}
                        label={<Box ml={1} component="div" display="flex" className={clsx(commonClasses.textMedium, commonClasses.colorNavy, {
                            [commonClasses.size14]: isSmallScreen,
                        })}>
                            <span>Use Shipping Address</span>
                        </Box>}
                        checkStatus={!sameAsBillingAddress}
                        handleCheck={handleSameAsBillingAddress}
                    />
                ) : (
                    <button id="finish-checkout-as-billing-address" className={classes.btnLink} onClick={() => handleSameAsBillingAddress()}>
                        <Box mr={1}>
                            <span>Add Billing Address</span>
                        </Box>
                        <AddIcon className={classes.addIcon} />
                    </button>
                )}
            </Box>
            {
                sameAsBillingAddress &&

                <Box>
                    <Box my={3} className={classes.smallTitle}>
                        Billing Address
                    </Box>
                    <UpdateAddressForm
                        address={payer.billingAddress}
                        prefix={"billingAddress."}
                        errors={errors}
                        handleChanges={handleChanges}
                        handleValidate={handleOnBlurValidate}
                    />
                </Box>
            }
        </Box>
        <div ref={prevScrollY}>
            {renderDefaultNextButton()}
        </div>
        </>
    )

    const renderCard = () => {
        switch (paymentCardInfo.paymentMethod.card.brand) {
            case 'visa':
                return <VisaCardLogo />;
            case 'mastercard':
                return <MastercardLogo />;
            case 'amex':
                return <AMEXCardLogo />;
            case 'discover':
                return <DiscoverCardLogo />;
            case 'diners':
                return <DinersCardLogo />;
            case 'jcb':
                return <JCBCardLogo />;
            case 'unionpay':
                return <UnionPayCardLogo />;
            default: return <VisaCardLogo />;
        }
    }

    const renderCheckoutStep = () => (
        <Box className={classes.orderSummeryMain}>
            <Box textAlign="center">
                <span className={classes.title}>Order Summary</span>
            </Box>
            <Box mt={2} display="flex" justifyContent="center">
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                    <Box className={clsx(commonClasses.textMedium, commonClasses.colorDarkGray, {
                        [commonClasses.size12]: isSmallScreen,
                        [commonClasses.size14]: !isSmallScreen,
                    })}>
                        Shipping
                    </Box>
                    <Box className={clsx(commonClasses.textMedium, commonClasses.colorDarkGray, {
                        [commonClasses.size12]: isSmallScreen,
                        [commonClasses.size14]: !isSmallScreen,
                    })}>
                        Billing Info
                    </Box>
                    <Box className={clsx(commonClasses.textMedium, commonClasses.colorMain, {
                        [commonClasses.size12]: isSmallScreen,
                        [commonClasses.size14]: !isSmallScreen,
                    })}>
                        Review
                    </Box>
                </Breadcrumbs>
            </Box>
            <Box>
                <Box mt={3}>
                    <PaymentSummaryComponent
                        priceType={paymentPriceType}
                        selectedPeriod={paymentPeriod}
                        selectedPrice={paymentPrice}
                        handlePriceSelect={handlePriceSelect}
                        selectedPlan={paymentPlan}
                        isCouponApplied={isCouponApplied} />
                </Box>
                <Box>
                {
                    !paymentPlan.isTrial &&
                    <Box mt={2}>
                        <Box>
                        {
                            addOns.map((item) => {
                                return (
                                    <Box key={item.id} >
                                        <SelectAddOnComponent addOn={item} handleSelectingItem={handleToggleSelectingAddOn} />
                                    </Box>
                                )
                            })
                        }
                        </Box>
                    </Box>
                }
                </Box>
                <Box my={2}>
                    <Divider />
                </Box>
                <Box>
                    <BillingSummaryComponent
                        selectedPlan={paymentPlan}
                        selectedPeriod={paymentPeriod}
                        selectedPrice={paymentPrice}
                        selectedAddOns={getSelectedAddOns()}
                        hasDiscount
                    />
                </Box>
                <Box pt={2}>
                    {!enterDiscountCode ? (
                        <button id="add-promo-code-button" className={classes.btnLink} onClick={() => handleEnterDiscountCode()}>
                            <Box mr={1}>
                                <span>Add Promo Code</span>
                            </Box>
                            <AddIcon className={classes.addIcon} />
                        </button>
                    ) : (
                        <>
                            <Box mb={1}>
                                <span className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorCharcoal, commonClasses.uppercase)}>Promo code</span>
                            </Box>
                            <ApplyPaymentCouponComponent
                                handleCouponChanges={handleCouponChanges}
                                applyCoupon={applyCoupon}
                                couponCode={couponCode}
                            />
                        </>
                    )}
                    {
                        isCouponApplied &&
                        <PaymentCouponAppliedComponent paymentCoupon={coupon.code}
                            couponDiscount={coupon.detail}
                            handleRemove={resetCoupon} />
                    }
                </Box>
                <Box mt={3}>
                    <Box className={classes.paymentMethodBlock}>
                        <Box className={clsx(commonClasses.size12, commonClasses.textMedium, commonClasses.colorGray700, commonClasses.uppercase)}>
                            Payment details
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="space-between" mt={1}>
                            <Box display="flex" alignItems="center">
                                {paymentCardInfo && paymentCardInfo.paymentMethod.card && renderCard()}
                                <Box ml={1.5} className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorLinkBlack)}>
                                    <span>•••• {paymentCardInfo && paymentCardInfo.paymentMethod.card ? paymentCardInfo.paymentMethod.card.last4 : ''}</span>
                                </Box>
                            </Box>
                            <Box
                                className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorDarkGray)}>
                                <span>{paymentCardInfo && paymentCardInfo.paymentMethod.card ? paymentCardInfo.paymentMethod.card.exp_month : ''}/{paymentCardInfo && paymentCardInfo.paymentMethod.card ? paymentCardInfo.paymentMethod.card.exp_year.toLocaleString().substr(-2) : ''}</span>
                            </Box>
                        </Box>
                    </Box>
                    <Box mt={2} className={classes.paymentMethodBlock}>
                        <Box className={clsx(commonClasses.size12, commonClasses.textMedium, commonClasses.colorGray700, commonClasses.uppercase)}>
                            Shipping address
                        </Box>
                        <Box mt={1.5}>
                            <Box className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorLinkBlack)}>
                                <span>{payer.firstName} {payer.lastName}</span>
                            </Box>
                            <Box className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorLinkBlack)}>
                                <span>{payer.shippingAddress.streetAddress1}</span>
                            </Box>
                            <Box className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorLinkBlack)}>
                                <span>{payer.shippingAddress.city}, {payer.shippingAddress.country} {payer.shippingAddress.zipCode}</span>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {
                renderRecaptcha()
            }
            <div ref={prevScrollY}>
            {renderDefaultNextButton()}
            </div>
        </Box>
    )

    const btnName = () => {
        switch (step) {
            case GetStartedStep.CreateAccount:
                return "Get Healthy";
            case GetStartedStep.HaveAccount:
                return "Login";
            case GetStartedStep.ResetPassword:
                return "Reset Password";
            case GetStartedStep.AskInsurance:
                return "YES";
            case GetStartedStep.AskMedicare:
                return "YES";
            case GetStartedStep.AppleGooglePay:
                return "Continue to Payment";
            case GetStartedStep.Billing:
                return "Continue to Payment";
            case GetStartedStep.Review:
                return "Purchase Membership";
            default: return "Continue";
        }
    }

    const disabledStatus = () => {
        switch (step) {
            case GetStartedStep.HealthConcern: 
                return healthConcernStatus === null;
            case GetStartedStep.CreateAccount:
                return !isCheckedWelcomePatient();
            case GetStartedStep.NameInterstitial:
                return !isCheckedNameInfo();
            case GetStartedStep.PersonalInfo:
                return !isCheckedPersonalInfo();
            case GetStartedStep.AskInsurance:
                return false;
            case GetStartedStep.CaptureInsurance:
                return false;
            case GetStartedStep.OnnInsurance:
                return false;
            case GetStartedStep.Shipping:
                return !isCheckedShippingDetail();
            case GetStartedStep.AppleGooglePay:
                return false;
            case GetStartedStep.Billing:
                return !isCheckedPaymentCardDetail();
            case GetStartedStep.Review:
                return false;
        }
    }

    const renderBackButton = () => {
        if (step !== GetStartedStep.HealthConcern && step !== GetStartedStep.NameInterstitial && step !== GetStartedStep.Congratulations) {
            return <RegistrationBackButton goBack={handleSubmitBackStep} label="" />;
        } else {
            if (!isPlanAutoSelect && !isConsultationGetStartedPath && step !== GetStartedStep.NameInterstitial && step !== GetStartedStep.Congratulations) {
                return <PageSliderComponent context={{ ...navigationContext, backButton: "" }} />;
            }
        }
        return <></>;
    }

    const renderDefaultNextButton = () => {
        return (
            <Box mt={5}>
                <Grid container justify="center">
                    <Grid item xs={12} md={step === GetStartedStep.Review || step === GetStartedStep.AppleGooglePay ? 12 : step === GetStartedStep.Shipping || step === GetStartedStep.Billing ? 4 : 3}>
                        <RegistrationNextButton
                            goNext={handleSubmitNextStep}
                            label={btnName()}
                            isLoading={isLoading || isSubmitted}
                            disabled={disabledStatus()}
                        />
                    </Grid>
                </Grid>
                {step === GetStartedStep.CreateAccount && (
                    <Box mt={4} display="flex" justifyContent="center" alignItems="center" className={commonClasses.colorNavy}>
                        <span className={classes.helperText}>Have an account?</span>
                        <Link id="sign-in-link" className={classes.link} onClick={() => goToLoginPage()}>
                            <Typography
                                className={classes.linkText}
                                display="inline"
                            >
                                Sign In
                            </Typography>
                        </Link>
                    </Box>
                )}
                {step === GetStartedStep.HaveAccount && (
                    <Box mt={4} textAlign="center">
                        <Link id="forgot-password-link" className={classes.link} onClick={() => goToResetPasswordStep()}>
                            <Typography
                                className={classes.linkText}
                                display="inline"
                            >
                                Forgot Password?
                            </Typography>
                        </Link>
                    </Box>
                )}
                {step === GetStartedStep.ResetPassword && (
                    <Box mt={4} textAlign="center">
                        <Link id="forgot-password-link" className={classes.link} onClick={() => goToHaveAccountStep()}>
                            <Typography
                                className={classes.linkText}
                                display="inline"
                            >
                                Log In
                            </Typography>
                        </Link>
                    </Box>
                )}
            </Box>
        )
    }

    const renderAskInsuranceNextButton = () => {
        return (
            <>
                <Box mt={8}>
                    <Grid container justify="center" spacing={3}>
                        <Grid item xs={12} md={4} lg={3}>
                            <RegistrationNextButton
                                goNext={handleSubmitNextStep}
                                label={btnName()}
                                isLoading={isLoading || isSubmitted}
                                disabled={disabledStatus()}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <RegistrationSkipRedesignButton
                                skip={() => handleHasInsurance(false)}
                                label="NO"
                                disabled={false}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </>
        )
    }

    const renderNextButton = (step: GetStartedStep): JSX.Element => {
        switch (step) {
            case GetStartedStep.AskInsurance: return renderAskInsuranceNextButton();
            case GetStartedStep.AskMedicare: return renderAskInsuranceNextButton();
            case GetStartedStep.CaptureInsurance: return <></>;
            case GetStartedStep.AppleGooglePay: return <></>;
            case GetStartedStep.Billing: return <></>;
            case GetStartedStep.Review: return <></>;
            default: return renderDefaultNextButton();
        }
    }

    return (
        <>
            {isEmailSent && step === GetStartedStep.ResetPassword && (
                <Box className={classes.setPasswordSection}>
                    <Box className={classes.linkEmailSent}>
                        <DoneIcon className={classes.doneIcon} />
                        <Box ml={2}>A link was sent to your email</Box>
                    </Box>
                </Box>
            )}
            <Box className={classes.backBtnSection}>
                {!isSubmitted && renderBackButton()}
            </Box>
            <Container maxWidth="lg" className={classes.container}>
                <Box className={classes.main}>
                    {step === GetStartedStep.HealthConcern && renderHealthConcernStep()}
                    {step === GetStartedStep.ClinicallyProvenResults && renderClinicallyProvenResultsStep()}
                    {step === GetStartedStep.WorkingTogether && renderWorkingTogetherStep()}
                    {step === GetStartedStep.CreateAccount && renderCreateAccountStep()}
                    {step === GetStartedStep.HaveAccount && renderHaveAccountStep()}
                    {step === GetStartedStep.ResetPassword && renderResetPasswordStep()}
                    {step === GetStartedStep.Congratulations && renderCongratulationsStep()}
                    {step === GetStartedStep.NameInterstitial && renderNameInterstitialStep()}
                    {step === GetStartedStep.PersonalInfo && renderPersonalInfoStep()}
                    {step === GetStartedStep.AskInsurance && renderAskInsuranceStep()}
                    {step === GetStartedStep.AskMedicare && renderAskMedicareStep()}
                    {step === GetStartedStep.CaptureInsurance && renderCaptureInsuranceStep()}
                    {step === GetStartedStep.OnnInsurance && renderOnnInsuranceStep()}
                    {step === GetStartedStep.Shipping && renderShippingStep()}
                    {step === GetStartedStep.AppleGooglePay && renderSelectMembershipStep()}
                    {step === GetStartedStep.Billing && renderBillingStep()}
                    {step === GetStartedStep.Review && renderCheckoutStep()}
                    {
                        renderNextButton(step)
                    }
                    
                    {step === GetStartedStep.PersonalInfo && isMarketingSMSButton && isAgreeSMS && renderMarketingSMSSubCopy()}
                </Box>
            </Container>
            {isSmallScreen && step === GetStartedStep.Review && showBottom && (
                <Box className={classes.stickyReviewBtnSection}>
                    <RegistrationNextButton
                        goNext={handleSubmitNextStep}
                        label={btnName()}
                        isLoading={isLoading || isSubmitted}
                        disabled={disabledStatus()}
                    />
                </Box>
            )}
            {isSmallScreen && step === GetStartedStep.Billing && sameAsBillingAddress && showBottom && (
                <Box className={classes.stickyReviewBtnSection}>
                    <RegistrationNextButton
                        goNext={handleSubmitNextStep}
                        label={btnName()}
                        isLoading={isLoading || isSubmitted}
                        disabled={disabledStatus()}
                    />
                </Box>
            )}
        </>
    )
}
