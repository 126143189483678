import React from "react";
import {useFacade} from "./uncompletedMedicalHistoryBadgeComponent.hooks";
import {Badge} from "@material-ui/core";

export const UncompletedMedicalHistoryBadgeComponent: React.FC = () => {
    const [notCompleted] = useFacade();

    return (
        <>
            {
                notCompleted
                    ? <Badge badgeContent={'...'} color="secondary"/>
                    : <></>
            }
        </>
    );
}