import { Box, Button, CircularProgress } from "@material-ui/core";
import { CardElement, Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useMemo } from "react";
import { useFacade } from "./paymentFormComponent.hooks";
import './PaymentFormComponent.scss';
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from "clsx";
import { FellowshipRegistrationBackButton } from "../../../fellowship/components/fellowshipRegistrationButton/FellowshipRegistrationBackButton";
import { colors } from "../../../common/constants/colors";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#000',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
};

interface PaymentFormComponentProps extends PaymentFormProps {
  publicKey: string;
  provider: string;
  goBack: Function;
}

export const PaymentFormComponent: React.FC<PaymentFormComponentProps> = (props: PaymentFormComponentProps) => {
  const {
    needCardData,
    totalPrice,
    callback,
    canSubmit,
    validate,
    isSubmitted,
    provider,
    confirmAgreements,
    publicKey,
    goBack
  } = props;

  const stripePromise = useMemo(() => loadStripe(publicKey), [publicKey]);

  return (
    <Elements stripe={stripePromise}>
      <PaymentForm
        needCardData={needCardData}
        totalPrice={totalPrice}
        callback={callback}
        canSubmit={canSubmit}
        validate={validate}
        isSubmitted={isSubmitted}
        provider={provider}
        confirmAgreements={confirmAgreements}
        goBack={goBack}
      />
    </Elements>
  )
}

interface PaymentFormProps {
  needCardData?: boolean;
  totalPrice: number;
  callback: Function;
  canSubmit: boolean;
  validate?: Function;
  isSubmitted: boolean;
  confirmAgreements?: JSX.Element;
  provider: string;
  goBack: Function;
}

const PaymentForm: React.FC<PaymentFormProps> = (props: PaymentFormProps) => {
  const {
    needCardData,
    callback,
    canSubmit,
    validate,
    isSubmitted,
    goBack
  } = props;

  const [
    {
      error
    },
    handleChange,
    handleSubmit
  ] = useFacade(callback, validate, needCardData);
  const commonClasses = commonUseStyles();

  const cardElement = needCardData && (
    <div className="form-row">
      <CardElement
        id="card-element"
        options={CARD_ELEMENT_OPTIONS}
        onChange={(event) => handleChange(event)}
      />
      <div className="card-errors" role="alert">{error}</div>
    </div>
  );

  return (
    <>
      <form onSubmit={(event) => handleSubmit(event)}>
        {cardElement}
        <Box mt="60px" display="flex" alignItems="center">
          <Box mr="45px">
            <FellowshipRegistrationBackButton goBack={() => goBack()} />
          </Box>
          <Button className={clsx("submit-button", commonClasses.whButton)} disabled={!!error || !canSubmit} type="submit">
            { isSubmitted ? <CircularProgress size={24} style={{ color: colors.white }} /> : 'Confirm Purchase'}
          </Button>
        </Box>
      </form>
    </>
  );
}
