import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontFamily: 'Be Vietnam Pro',
            fontSize: '1.6rem',
            fontWeight: 500,
            lineHeight: 1.18,
            letterSpacing: 0.17,
            color: '#292929 !important'
        },
        addButton: {
            padding: 0,
        },
        addAttachment: {
            background: '#00000000',
            margin: '6px 8px',
            cursor: 'pointer',
        },
        fileInput: {
            opacity: 0,
            position: 'absolute',
            zIndex: -1,
        },
    })
);
