import React from "react";
import { Box, Grid, useTheme, useMediaQuery } from "@material-ui/core";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { ActiveMembershipCardComponent } from "../../../payment/components/activeMembershipCard/ActiveMembershipCardComponent";
import { MembershipCardComponent } from "../../../payment/components/membershipCard/MembershipCardComponent";
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { useFacade } from "./PatientMembershipPage.hooks";
import { AuthenticatedLayoutComponent } from "../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent";
import { TitlesService } from "../../../../constants/title.constants";
import { MenuItemTypes } from "../../../../models/menu.models";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { PagesNamesService } from "../../../../constants/pagesNames.constants";
import commonUseStyles from '../../../common/styles/common.styles';


export const PatientMembershipPage: React.FC = () => {
    const [
        {
            isLoading,
            isHistoryLoading,
            isCallToActionsLoading,
            activeMembership,
            paymentPlanHistories,
            callToActions
        },
        handleGoEditBillingInfo,
        handleGoToBuySubscription,
        handleGoCancellationForm
    ] = useFacade();

    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <AuthenticatedLayoutComponent
            title={TitlesService.myAccountTitle}
            pageName={PagesNamesService.myAccount}
            selectedMenuItem={MenuItemTypes.Membership}
            hideBackground
        >
            <Box p={smallScreen ? 2 : 5} className={!smallScreen && commonClasses.bgWhiteMain} minHeight="calc(100vh - 300px)">
                <Grid container spacing={3}>
                    <Grid
                        item
                        xs={12}
                        container
                        direction="row"
                        justify="center"
                        alignItems="flex-start"
                    >
                        <span className="size-1_5 text-medium capitalize"> MEMBERSHIPS </span>
                    </Grid>

                    {
                        isLoading || isHistoryLoading || isCallToActionsLoading ?
                            <WildHealthLinearProgress /> :
                            [
                                <Grid item xs={12} key='active-membership'>
                                    <Box display="flex" justifyContent="space-between" px={smallScreen ? 2 : 5}>
                                        <Box py={2}>
                                            <Box className="size-1_25 text-medium">Active Membership</Box>
                                        </Box>
                                        <Box>
                                            {
                                                ((!activeMembership || activeMembership.canBeReplaced) && !isLoading) &&
                                                <WildHealthButton
                                                    id="patient-account-renew"
                                                    onClick={() => handleGoToBuySubscription()}
                                                >
                                                    <Box px={2}>
                                                        Renew
                                                    </Box>
                                                </WildHealthButton>
                                            }
                                        </Box>
                                    </Box>
                                    <Box px={smallScreen ? 2 : 5}>
                                        <ActiveMembershipCardComponent subscription={activeMembership} paymentPlanHistories={paymentPlanHistories} standardUpSellActions={callToActions} handleGoBillingInfo={() => handleGoEditBillingInfo()} handleGoCancellationForm={() => handleGoCancellationForm()} />
                                    </Box>
                                </Grid>,
                                <Grid item xs={12} key='memberships-history'>
                                    <Box display="flex" justifyContent="space-between" px={smallScreen ? 2 : 5}>
                                        <Box py={2}>
                                            <span className="size-1_25 text-medium">Membership History</span>
                                        </Box>
                                    </Box>
                                    <Box px={smallScreen ? 2 : 5}>
                                        {
                                            paymentPlanHistories && paymentPlanHistories.length > 0 ?
                                                <MembershipCardComponent /> :
                                                <WildHealthPlaceHolder message="No Memberships" />
                                        }
                                    </Box>
                                </Grid>,
                            ]
                    }
                </Grid>
            </Box>
        </AuthenticatedLayoutComponent>
    )
}