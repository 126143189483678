import {LabRangeModel, LabRangeType} from "../models/input.models";
import { LabReportValueRangeModel } from "../../healthReport/models/healthReport.models";

const getLabInputRange = (range: LabRangeModel | LabReportValueRangeModel): string => {
    switch (range.type)
    {
        case LabRangeType.FromTo:
            return `${range.from} - ${range.to}`;
        case LabRangeType.LessThen:
            return `< ${range.to}`;
        case LabRangeType.MoreThen:
            return `> ${range.from}`;
        case LabRangeType.LessThanOrEqual:
            return `<= ${range.to}`
        case LabRangeType.MoreThanOrEqual:
            return `>= ${range.from}`
        case LabRangeType.None:
            return '';
        default:
            return '';
    }
}

export const renderLabInputRange = (range: LabRangeModel | LabReportValueRangeModel, wrapBrackets = true): string => {
    if (range.type === LabRangeType.None) {
        return '';
    }

    let rangeString = getLabInputRange(range);
    const dimension = range.dimension ? ` ${range.dimension}` : '';

    return wrapBrackets
        ? `(${rangeString}${dimension})`
        : `${rangeString}${dimension}`;
}

export const getMinValueByRange = (range: LabRangeModel) => {
    switch (range.type)
    {
        case LabRangeType.FromTo:
            return range.from;
        case LabRangeType.LessThen:
            return 0;
        case LabRangeType.LessThanOrEqual:
            return 0;
        case LabRangeType.MoreThen:
            return range.from;
        case LabRangeType.MoreThanOrEqual:
            return range.from;
        case LabRangeType.None:
            return 0;
        default:
            return 0;
    }
}

export const getMaxValueByRange = (range: LabRangeModel) => {
    switch (range.type)
    {
        case LabRangeType.FromTo:
            return range.to;
        case LabRangeType.LessThen:
            return range.to;
        case LabRangeType.LessThanOrEqual:
            return range.to;
        case LabRangeType.MoreThen:
            return range.from;
        case LabRangeType.MoreThanOrEqual:
            return range.from;
        case LabRangeType.None:
            return 0;
        default:
            return 0;
    }
}
