import React, {useEffect, useState} from "react";
import {NotesType} from "../../models/notes.models";
import {notesService} from "../../services/notes.service";
import {PatientFollowUpPage} from "../patientFollowUp/PatientFollowUpPage";
import {
    PatientHistoryAndPhysicalNotePage
} from "../PatientHistoryAndPhysicalNotePage/PatientHistoryAndPhysicalNotePage";
import {
    PatientHistoryAndPhysicalFollowUpNotePage
} from "../patientHistoryAndPhysicalFollowUpNotePage/PatientHistoryAndPhysicalFollowUpNotePage";
import {PatientSoapNotePage} from "../patientSoapNotePage/PatientSoapNotePage";
import {PatientBlankPage} from "../patientBlankPage/PatientBlankPage";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import {navigationService} from "../../../../services/navigation.service";
import {useHistory} from "react-router";
import {
    PatientHistoryAndPhysicalGroupVisitNotePage
} from "../PatientHistoryAndPhysicalGroupVisitNotePage/PatientHistoryAndPhysicalGroupVisitNotePage";
import { PatientInitialConsultBasePage } from "../patientInitialConsult/PatientInitialConsultPageBasic";

export const PatientNotePage: React.FC = (props: any) => {
    const [isLoading, setIsLoading] = useState(true);
    const params = new URLSearchParams(props.location.search);
    const noteId = Number(props.match.params.noteId);
    const noteType = NotesType[params.get("noteType")];
    const history = useHistory();

    const renderNote = (noteType: NotesType) => {
        switch (noteType) {
            case NotesType.Initial: return <PatientInitialConsultBasePage {...props}/>;
            case NotesType.FollowUp: return <PatientFollowUpPage {...props}/>;
            case NotesType.HistoryAndPhysicalInitial: return <PatientHistoryAndPhysicalNotePage {...props}/>;
            case NotesType.HistoryAndPhysicalFollowUp: return <PatientHistoryAndPhysicalFollowUpNotePage {...props}/>;
            case NotesType.HistoryAndPhysicalGroupVisit: return <PatientHistoryAndPhysicalGroupVisitNotePage {...props}/>;
            case NotesType.Soap: return <PatientSoapNotePage {...props}/>;
            case NotesType.Blank: return <PatientBlankPage {...props}/>;
        }
    }

    useEffect(() => {
        notesService.getByIdForPatient(noteId).subscribe(
            () => setIsLoading(false),
            () => navigationService.toDefault(history)
        );

        return () => {}
    }, []);

    if (isLoading) {
        return (
            <WildHealthLinearProgress/>
        )
    }

    return (
        <>
            {
                renderNote(noteType)
            }
        </>
    );
}