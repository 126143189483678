import { useState } from "react";

interface supplementsComponentState {
    isMenuOpened: boolean;
    isAddNewOpened: boolean;
    isRecommendationOpened: boolean;
    isEditorOpened: boolean;
    selectedId: number;
    anchorEl: any;
}

export function useFacade(): [
    supplementsComponentState,
    (isOpen: boolean) => void,
    (isOpen: boolean) => void,
    (isOpen: boolean) => void,
    (id?: number, anchorEl?: Element) => void
] {
    const [state, setState] = useState({
        isMenuOpened: false,
        isAddNewOpened: false,
        isRecommendationOpened: false,
        isEditorOpened: false,
        selectedId: 0,
        anchorEl: null,
    } as supplementsComponentState);

    const handleAddNewToggle = (isOpen: boolean) => {
        setState(state => ({...state, isAddNewOpened: isOpen}));
    }

    const toggleRecommendation = (isOpen: boolean) => {
        setState(state => ({...state, isRecommendationOpened: isOpen}));
    }

    const handleEditToggle = (isOpen: boolean) => {
        setState(state => ({
            ...state,
            isEditorOpened: isOpen,
            isMenuOpened: false
        }));
    }

    const handleToggleActions = (id?: number, anchorEl?: Element) => {
        setState(state => ({
            ...state,
            isMenuOpened: !state.isMenuOpened,
            selectedId: id ?? state.selectedId,
            anchorEl: anchorEl
        }))
    }

    return [
        state,
        handleAddNewToggle,
        toggleRecommendation,
        handleEditToggle,
        handleToggleActions
    ];
}