import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            padding: 5,
            [theme.breakpoints.up('sm')]: {
                padding: 30,
            },
            [theme.breakpoints.down('xs')]: {
                padding: 16,
                minHeight: 'calc(100vh - 225px)',
            },
        },
        statusDate: {
            fontWeight: 400,
            marginLeft: 5,
        },
        dialog: {
            [theme.breakpoints.down("xs")]: {
                paddingTop: '300px !important',
                paddingLeft: '0px !important',
                paddingRight: '0px !important',
                paddingBottom: '0px !important',
                '& .MuiDialog-paperFullScreen': {
                    borderRadius: '20px 20px 0 0',
                },
            },
        },
        dialogContent: {
            [theme.breakpoints.down("xs")]: {
                padding: 0,
                position: 'relative',
            },
        },
        closeIcon: {
            width: 30,
            height: 30,
        }
    })
);
