import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {colors} from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        messageContainer: {
            display: 'flex',
            alignItems: "flex-end",
        },
        myMessageContainer: {
            justifyContent: 'flex-end'
        },
        messageBlock: {
            maxWidth: '65%',
            [theme.breakpoints.down("xs")]: {
                minWidth: '305px',
            }
        },
        message: {
            whiteSpace: 'pre-line',
            display: 'inline-block',
            wordBreak: 'break-word',
            padding: 10
        },

        myMessage: {
            borderRadius: 6,
            background: colors.mint100,
            color: colors.black
        },

        date: {
            padding: 10,
            fontSize: 12,
            color: colors.gray
        },

        reacted: {
            background: colors.mint100
        }
    }
));




