import {
    Checkbox,
    FormControlLabel,
    makeStyles,
} from '@material-ui/core';
import React from 'react';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { ReactComponent as CheckedIcon } from '@img/icons/CheckedIcon.svg';
import { ReactComponent as CheckedIconDefault } from '@img/icons/CheckedIconDefault.svg';
import { colors } from '../constants/colors';

const useStyles = makeStyles({
    root: {
        marginRight: 4,
    },
});

interface CheckboxProps {
    id: string,
    label: JSX.Element | string,
    checkStatus: boolean;
    handleCheck: (event: React.ChangeEvent<HTMLInputElement>) => void,
    color?: "primary" | "secondary" | "default",
    fill?: string;
    disabled?: boolean;
    defaultIcon?: boolean;
}

export const WildHealthCheckbox: React.FC<CheckboxProps> = (props: CheckboxProps) => {
    const { id, label, checkStatus, handleCheck, color = "primary", disabled = false, defaultIcon = false, fill = colors.lightNavy,} = props;
    const classes = useStyles();

    const controlIcon = <CheckBoxOutlineBlankIcon style={{ fill: colors.mediumGray, width: 25, height: 25 }} />;
    const controlCheckedIcon = defaultIcon 
    ? <CheckedIconDefault style={{ width: 25, height: 25, fill: fill }} />
    : <CheckedIcon style={{ width: 25, height: 25, fill: fill }} />;
    const control = (
        <Checkbox
            icon={controlIcon}
            color={color}
            checkedIcon={controlCheckedIcon}
            name={`${id}-CheckBox`}
            checked={checkStatus}
            disabled={disabled}
            onChange={handleCheck}
        />
    );

  return (
    <FormControlLabel control={control} label={label} className={classes.root} />
  );
};