import React from 'react';
import { Box, Collapse, Paper, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import AddIcon from "@material-ui/icons/Add";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import moment from 'moment';
import { useStyles, ShowUntestedSwitcher } from "./labInputsComponent.styles";
import { useFacade } from "./labInputsComponent.hooks";
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { labInputGroupNames } from '../../models/input.models';
import { LabInputsSectionComponent } from "../labInputsSectionComponent/LabInputsSectionComponent";
import { CommonPlaceHolder } from '../../../common/components/wildHealthPlaceHolder/CommonPlaceHolder';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import { toCurrentTimeZone } from '../../../timezones/helpers/timezone';
import { AddLabDialogComponent } from '../addLabDialogComponent/AddLabDialogComponent';
import { ManageLabInputDatesComponent } from '../manageLabInputDatesComponent/ManageLabInputDatesComponent';
import { DialogTitle } from "../../../common/components/dialogTitle/DialogTitle";

interface LabInputsComponentProps {
    patientId: number;
}

export const LabInputsComponent: React.FC<LabInputsComponentProps> = (props: LabInputsComponentProps) => {
    const { patientId } = props;

    const [
        {
            isLoading,
            isEditMode,
            isSaving,
            isDeleting,
            isHighlightExpand,
            showUntestedValue,
            labInputs,
            sections,
            dataSets,
            isAddLabsDialogOpen,
            hiddenDataSets,
            isActionsOpen,
            selectedDataSetId,
            selectedDataSet
        },
        handleExpand,
        handleCancel,
        handleEditMode,
        handleSaveChanges,
        handleValueChange,
        handleRangeChanges,
        handleNameChange,
        getCurrentDataSet,
        handleAddLabDialogToggle,
        handleDateChanges,
        getDropDownItems,
        handleColumnCheck,
        handleToggleActions,
        handleHighlightExpand,
        handleHighlight,
        handleSelect,
        handleDelete,
        handleToggleShowUntested,
        handleAddNewMiscLab
    ] = useFacade(patientId);

    const classes = useStyles();

    const dropDownItems = getDropDownItems();

    const renderButtons = () => {
        return <>
            <Box display="flex" justifyContent="flex-end">
                <Box display="flex" alignItems="center" justifyContent="center">
                    <Box className="wh-tw-text-sm wh-tw-font-medium wh-tw-font-beVietnamPro">
                        Show Untested Labs
                    </Box>
                    <Box mx={2}>
                        <ShowUntestedSwitcher
                            checked={showUntestedValue}
                            onChange={() => handleToggleShowUntested()}
                            name="selectStragyToggle"
                        />
                    </Box>
                </Box>
                {
                    dropDownItems.length > 0 &&
                    <Box px={1}>
                        <ManageLabInputDatesComponent items={dropDownItems} handleChanges={handleColumnCheck} />
                    </Box>
                }
                <Box px={1}>
                    <WildHealthButton id="upload-inputs-file-view" color="secondary" size="large" loading={isDeleting} disabled={isDeleting || !dataSets || !dataSets.length} onClick={() => handleToggleActions()}>
                        Delete Lab
                    </WildHealthButton>
                </Box>
                <Box px={1}>
                    <WildHealthButton
                        id="lab-inputs-add-new"
                        color="primaryV"
                        size="large"
                        loading={isSaving}
                        disabled={isLoading || isSaving}
                        onClick={() => handleAddLabDialogToggle(true)}>
                        <Box my={0.3} display="flex" alignItems="center">
                            <Box ml={1}>
                                <span>Add Lab</span>
                            </Box>
                            <Box ml={1} display="flex" alignItems="flex-end">
                                <AddIcon />
                            </Box>
                        </Box>
                    </WildHealthButton>
                </Box>
            </Box>
        </>
    }

    const renderContent = () => {
        if (isLoading || isSaving || isDeleting) {
            return <WildHealthLinearProgress />
        }

        if (!labInputs || !labInputs.length) {
            return (<CommonPlaceHolder message="No available data." />)
        }

        const highlightedLabs = labInputs.filter(x => x.isHighlighted);

        return (
            <Box>
                {
                    highlightedLabs && highlightedLabs.length > 0 && (
                        <Box py={2}>
                            <Box display="flex" justifyContent="flex-start" alignItems="stretch" mt={1}>
                                <Box id={`highlighted-lab-inputs-expand`} onClick={() => handleHighlightExpand()} className="wh-tw-cursor-pointer">
                                    {isHighlightExpand ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                                </Box>
                                <Box ml={1}>
                                    <span className="wh-tw-text-lg wh-tw-font-medium wh-tw-font-beVietnamPro">Highlights</span>
                                </Box>
                            </Box>
                            <Collapse in={isHighlightExpand} timeout="auto" unmountOnExit>
                                <Box mt={2}>
                                    <LabInputsSectionComponent
                                        patientId={patientId}
                                        isHightlighted
                                        inputs={highlightedLabs}
                                        dataSets={dataSets}
                                        getCurrentDataSet={getCurrentDataSet}
                                        hiddenDataSets={hiddenDataSets}
                                        handleEditLab={handleEditMode}
                                        handleHighlightLab={handleHighlight}
                                    />
                                </Box>
                            </Collapse>
                        </Box>
                    )
                }
                {
                    sections.map((section, index) => {
                        const inputs = labInputs.filter(x => x.group === section.group);

                        return (
                            <Box py={2} key={index}>
                                <Box display="flex" justifyContent="flex-start" alignItems="stretch" mt={1}>
                                    <Box id={`lab-inputs-expand-${section.group}`} onClick={() => handleExpand(section.group)} className="wh-tw-cursor-pointer">
                                        {section.expanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                                    </Box>
                                    <Box ml={1}>
                                        <span className="wh-tw-text-lg wh-tw-font-medium wh-tw-font-beVietnamPro">{labInputGroupNames[section.group]}</span>
                                    </Box>
                                </Box>
                                <Collapse in={section.expanded} timeout="auto" unmountOnExit>
                                    <Box mt={2}>
                                        <LabInputsSectionComponent
                                            showUntestedValue={showUntestedValue}
                                            patientId={patientId}
                                            inputs={inputs}
                                            dataSets={dataSets}
                                            getCurrentDataSet={getCurrentDataSet}
                                            hiddenDataSets={hiddenDataSets}
                                            handleEditLab={handleEditMode}
                                            handleHighlightLab={handleHighlight}
                                        />
                                    </Box>
                                </Collapse>
                            </Box>
                        )
                    })
                }
            </Box>
        )
    }

    return (
        <Paper elevation={0}>
            {isAddLabsDialogOpen 
                ? <AddLabDialogComponent
                    title='Lab Panel'
                    message='LAB COLLECTION DATE'
                    actionName={'Save Changes'}
                    open={isAddLabsDialogOpen}
                    isEditMode={isEditMode}
                    handleClose={() => handleCancel()}
                    handleSubmit={handleSaveChanges}
                    disableDates={dataSets && dataSets.length > 0 ? dataSets.filter(x => !x.new).map(ds => ds.date) : []}
                    dataSet={selectedDataSet}
                    handleRangeChanges={handleRangeChanges}
                    handleNameChange={handleNameChange}
                    handleChange={handleValueChange}
                    handleChangeDate={handleDateChanges}
                    handleAddNewMiscLab={handleAddNewMiscLab}
                />
                : <Box p="30px">
                    <Box display="flex" justifyContent="space-between">
                        <span className="wh-tw-text-2xl wh-tw-font-medium wh-tw-font-poppins">Labs</span>
                        {
                            renderButtons()
                        }
                    </Box>
                    <Box pt={3}>
                        {
                            renderContent()
                        }
                    </Box>
                </Box>
            }
            <Dialog
                open={isActionsOpen}
                onClose={() => handleToggleActions()}
                className={classes.dialog}
            >
                <DialogTitle
                    id='dialog-title'
                    onClose={() => handleToggleActions()}
                >
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Box mb={2} px={1.5} className="wh-tw-text-xl wh-tw-font-medium wh-tw-font-poppins">Select which lab panel you want to delete</Box>
                    <Box mb={1.5} px={1.5} className="wh-tw-text-gray600 wh-tw-font-beVietnamPro">This will delete the entire lab panel, and cannot be undone.</Box>
                    <Box width={1} px={1.5}>
                    {
                        dataSets && dataSets.length > 0 && dataSets.map((item) => 
                            <Box display="flex" alignItems="center" mb={0.5} className="wh-tw-cursor-pointer" key={item.id} onClick={() => handleSelect(item.id)}>
                                {
                                    selectedDataSetId === item.id ? <CheckCircleIcon className="wh-tw-text-primaryV" /> : <RadioButtonUncheckedIcon className="wh-tw-text-primaryV" />
                                }
                                <Box ml={0.5} className="wh-tw-font-medium wh-tw-font-beVietnamPro">{moment(toCurrentTimeZone(new Date(item.date))).format("MM/DD/yyyy")}</Box>
                            </Box>
                        )
                    }
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Box mb={4}>
                        <WildHealthButton id={`delete-lab-button`} color="secondary" border="none" borderRadius={50} size="large" disabled={!selectedDataSetId} onClick={() => handleDelete()}>
                            Delete Lab Panel
                        </WildHealthButton>
                    </Box>
                    <Box mb={4} px={1.5}>
                        <WildHealthButton id={`cancel-dialog`} borderRadius={50} size="large" onClick={() => handleToggleActions()}>
                            Cancel
                        </WildHealthButton>
                    </Box>
                </DialogActions>
            </Dialog>
        </Paper>
    );
}