import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderColor: colors.mediumGray,
    borderRadius: 4,
    '& .MuiOutlinedInput-root': {
      borderColor: colors.mediumGray,
      borderRadius: 4,
    },
  },
  label: {
      fontSize: 14,
      fontWeight: 500,
      color: colors.gray900,
      textTransform: 'uppercase',
      [theme.breakpoints.down('xs')]: {
          fontSize: 12,
      },
  },
}));