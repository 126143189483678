import React from 'react';
import { withRouter } from 'react-router';
import { AuthenticatedLayoutComponent } from '../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from "../../../../models/menu.models";
import { ManageEmployerProductsComponent } from '../../components/manageEmployerProductsComponent/ManageEmployerProductsComponent';

class EmployerAdminPage extends React.Component<any> {
    render() {
        return (
            <AuthenticatedLayoutComponent
                hideBackground
                title={TitlesService.employerAdminTitle}
                pageName={PagesNamesService.employers}
                selectedMenuItem={MenuItemTypes.EmployerAdmin}
            >
                <ManageEmployerProductsComponent />
            </AuthenticatedLayoutComponent>
        )
    }
}

export default withRouter(EmployerAdminPage);
