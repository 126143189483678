import React from 'react';
import { Box, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Drawer, useTheme, useMediaQuery } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { ReactComponent as DownloadIcon } from '@img/icons/DownloadPrimary.svg';
import clsx from 'clsx';
import { useFacade } from "./patientRewardTasksComponent.hooks";
import { useStyles } from "./PatientRewardTasksComponent.styles";
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';

interface PatientRewardTasksComponentProps {
    patientId: number | null;
}

export const PatientRewardTasksComponent: React.FC<PatientRewardTasksComponentProps> = (props: PatientRewardTasksComponentProps) => {
    const [
        {
            isDownloading,
            rewardTasks,
            isSideAreaOpen,
            selectedTask,
            selectedTaskId
        },
        handleToggleSideArea,
        handleDownload
    ] = useFacade();

    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const renderSideArea = () => {
        if (selectedTask) {
            return (
                <Box className={clsx('flat-scroll', classes.sideContainer)} display="flex" flexDirection="column">
                    {!isSmallScreen && 
                        <Box display="flex" alignItems="center" justifyContent="space-between" pb={2}>
                            <Box className="wh-tw-font-medium wh-tw-text-xl wh-tw-text-gray900">Reward </Box>
                            <IconButton
                                id={`task-${selectedTask.id}`}
                                size="small"
                                onClick={() => handleToggleSideArea(null, false)}
                            >
                                <CloseIcon className="wh-tw-text-gray900" />
                            </IconButton>
                        </Box>
                    }
                    <Box pt={3}>
                        <Box className="wh-tw-font-medium wh-tw-text-lg wh-tw-text-center md:wh-tw-text-left">{selectedTask.title}</Box>
                        <Box mt={1.5} className="wh-tw-text-sm wh-tw-text-gray900">{selectedTask.description}</Box>
                    </Box>
                    {selectedTask.taskCTA &&
                        <Box flex={1} display="flex" alignItems="flex-end" justifyContent="center" pt={2}>
                            <WildHealthButton
                                id="mark-complete-button"
                                fullWidth
                                size="large"
                                borderRadius={50}
                                loading={selectedTaskId === selectedTask.id}
                                disabled={isDownloading || !!props.patientId}
                                onClick={() => handleDownload(selectedTask)}
                            >
                                {selectedTask.taskCTA.title}
                            </WildHealthButton>
                        </Box>
                    }
                </Box>
            )
        }

        return <></>
    }

    const renderContent = () => {
        return !!rewardTasks.length
            ? <Box mt={2}>
                  <List className={classes.list}>
                      {rewardTasks.map((task) =>
                          <ListItem className={classes.listItem} key={task.id}>
                              <ListItemText
                                  primary={task.title}
                              />
                              <ListItemSecondaryAction className="wh-tw-flex wh-tw-items-center wh-tw-gap-3">
                                  <WildHealthButton id={`download-cta-${task.id}`} size="small" color="white" loading={selectedTaskId === task.id} disabled={isDownloading || !!props.patientId} onClick={() => handleDownload(task)}>
                                      <Box mr={1} className="wh-tw-text-primaryV">Download</Box>
                                      <DownloadIcon />
                                  </WildHealthButton>
                                  <IconButton
                                      id={`task-${task.id}-side-area-drawer`}
                                      size="small"
                                      onClick={() => handleToggleSideArea(task, true)}
                                  >
                                      <KeyboardArrowRightIcon className="wh-tw-text-gray600" />
                                  </IconButton>
                              </ListItemSecondaryAction>
                          </ListItem>
                      )}
                  </List>
              </Box>
            : <Box mt={2} className={classes.empty}>Rewards are unlocked as you progress on your journey.</Box>
    }

    return (
        <Box width={1} pt={1} pb={3}>
            {renderContent()}
            <Drawer
                id="side-area-drawer"
                anchor='right'
                classes={{ paper: classes.papper }}
                open={isSideAreaOpen}
                onClose={() => handleToggleSideArea(null, false)}
            >
                <Box className={classes.sideMobile}>
                    {renderSideArea()}
                </Box>
            </Drawer>
        </Box>
    );
}