import { Box, Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles(() =>
    createStyles({
        navigation: {
            background: colors.gray8,
        },
        title: {
            color: colors.gray1,
            fontWeight: 500,
            fontSize: 14,
        },
        group: {
            maxWidth: 586,
            maxHeight: 300,
            overflow: 'auto',
        },
        emojiGroup: {
            color: colors.gray1,
        },
        emojiGroupSelected: {
            color: colors.main,
        },
        filledIcon: {
            fill: 'currentColor',
            color: 'currentColor',
        },
        strokedIcon: {
            stroke: 'currentColor',
        },
    })
);

export const EmojiButton = withStyles((theme: Theme) => ({
    root: {
        fontSize: 23,
        width: 64,
        height: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(Box);

export const EmojiNavButton = withStyles((theme: Theme) => ({
    root: {
        borderRadius: 0,
        fontSize: 20,
        height: 50,
    },
}))(Button);
