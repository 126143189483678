import React from "react";
import { WildHealthPlaceHolderBase } from "./WildHealthPlaceHolderBase";
import { useStyles } from "./commonPlaceHolder.styles";
import { RoutesConstants } from '../../../../constants/route.constants';
import { ImageConstants } from '../../../../constants/image.constants';

export interface CommonPlaceHolderProps {
    message: string;
}

export const CommonPlaceHolder: React.FC<CommonPlaceHolderProps> = (props: CommonPlaceHolderProps) => {
    const classes = useStyles();

    return (
        <WildHealthPlaceHolderBase message={props.message}>
            <img src={RoutesConstants.cdnBaseLink + ImageConstants.commonPlaceHolderPath}
                alt="img" className={classes.image} />
        </WildHealthPlaceHolderBase>
    );
}
