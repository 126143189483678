import { QuestionnairesResultListComponent } from '../questionnairesResultListComponent/QuestionnairesResultListComponent'
import { QuestionnaireModel, QuestionnaireResultModel } from '../../models/questionnaire.models'
import { FollowUpFormQuestionNames } from '../../static/followUpForm.static'
import { Gender } from '../../../common/models/user.models'
import { Box } from '@material-ui/core'
import React from 'react'

export interface FollowUpFormResultComponentProps {
    questionnaire: QuestionnaireModel
    result: QuestionnaireResultModel
    gender: Gender
}

export const FollowUpFormResultComponent: React.FC<FollowUpFormResultComponentProps> = (props: FollowUpFormResultComponentProps) => {
    const {
        questionnaire,
        result,
        gender
    } = props

    return (
        <Box p={5}>
            <Box mt={5}>
                <QuestionnairesResultListComponent
                    questions={[
                        FollowUpFormQuestionNames.REFLECT_LAST_VISIT
                    ]}
                    questionnaire={questionnaire}
                    title='General Update:'
                    result={result}
                    gender={gender}
                />
            </Box>
            <Box mt={5}>
                <QuestionnairesResultListComponent
                    questions={[
                        FollowUpFormQuestionNames.RATE_OVERALL_PROGRESS,
                        FollowUpFormQuestionNames.CLARIFY_PREVIOUS_QUESTIONS
                    ]}
                    questionnaire={questionnaire}
                    title='Goals & Interventions:'
                    result={result}
                    gender={gender}
                />
            </Box>
            <Box mt={5}>
                <QuestionnairesResultListComponent
                    questions={[
                        FollowUpFormQuestionNames.HOPING_UPCOMING_VISITS
                    ]}
                    questionnaire={questionnaire}
                    title='Upcoming Visit:'
                    result={result}
                    gender={gender}
                />
            </Box>
            <Box mt={5}>
                <QuestionnairesResultListComponent
                    questions={[
                        FollowUpFormQuestionNames.RATE_SUCCESS_EXERCISE,
                        FollowUpFormQuestionNames.RATE_SUCCESS_NUTRITION,
                        FollowUpFormQuestionNames.RATE_SUCCESS_SLEEP,
                        FollowUpFormQuestionNames.RATE_SUCCESS_MINDFULNESS,
                        FollowUpFormQuestionNames.RATE_SUCCESS_SOCIAL_CONNECTION,
                    ]}
                    questionnaire={questionnaire}
                    title='Checking in on your goals:'
                    result={result}   
                    gender={gender}
                />
            </Box>
            <Box mt={5}>
                <QuestionnairesResultListComponent
                    questions={[
                        FollowUpFormQuestionNames.YOUR_WEIGHT_BIOMETRICS,
                        FollowUpFormQuestionNames.YOUR_WAIST_CIRCUMFERENCE_BIOMETRICS,
                        FollowUpFormQuestionNames.CHECKING_BIOMETRICS_SYSTOLIC_BP,
                        FollowUpFormQuestionNames.CHECKING_BIOMETRICS_DIASTOLIC_BP
                    ]}
                    questionnaire={questionnaire}
                    title='Checking in on your biometrics (optional):'
                    result={result}
                    gender={gender}             
                />
            </Box>
        </Box>
    )
}