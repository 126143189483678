import React from "react";
import { WildHealthPlaceHolderBase } from "./WildHealthPlaceHolderBase";
import emptyChats from '@img/placeholders/EmptyChats.png';
import { useStyles } from "./commonPlaceHolder.styles";
import { CommonPlaceHolderProps } from "./CommonPlaceHolder";

interface ChatsPlaceHolderProps extends CommonPlaceHolderProps {
}

export const ChatsPlaceHolder: React.FC<ChatsPlaceHolderProps> = (props: ChatsPlaceHolderProps) => {
    const classes = useStyles();

    return (
        <WildHealthPlaceHolderBase message={props.message}>
            <img
                src={emptyChats}
                alt="img" className={classes.image}
            />
        </WildHealthPlaceHolderBase>
    );
}
