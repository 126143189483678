import brainIcon from "@img/HealthReport_Pg20_Brain.png";
import insulinIcon from "@img/HealthReport_Pg20_Insulin.png";
import { Box, Divider, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { LabInputNames } from "../../inputs/constants/inputs.constants";
import { DnaTableComponent } from "../components/dnaTableComponent/DnaTableComponent";
import { HealthRecommendationComponent } from '../components/healthRecommendationComponent/HealthRecommendationComponent';
import { LabResultComponent } from "../components/labResultComponent/LabResultComponent";
import { LabelPosition, ProgressDiagramComponent } from "../components/progressDiagramComponent/ProgressDiagramComponent";
import { riskColorMap } from "../constants/healthReport.constants";
import { HealthReportMode, HealthReportSections, HealthReportVersion, recommendationRowsCapacity, RecommendationTypesNumbers } from '../models/healthReport.models';
import { HealthReportBasePage, HealthReportMobileBasePage } from "./HealthReportBasePage";
import { useStyles } from './healthReport.styles';
import { HealthReportPageProps } from "./healthReportPageProps";
import commonUseStyles from "../../common/styles/common.styles";
import clsx from "clsx";
import { RecommendationSingleSelectComponent } from "../components/recommendationSingleSelectComponent/RecommendationSingleSelectComponent";
import { WildHealthButton } from "../../common/components/wildHealthButton/WildHealthButton";
import { ReactComponent as SaveIcon } from '@img/icons/Save.svg';

export const HealthReportChronicDisease3Page: React.FC<HealthReportPageProps> = (props: HealthReportPageProps) => {
    const {
        report,
        handleChanges,
        handleSaveChanges,
        isChanged,
        mode,
        pageNumber,
        practice,
        recommendations,
        handleSelected,
        handleChangesNewRecommendation,
        handleAddRecommendation,
        handleSaveChangesNew,
    } = props;
    const commonClasses = commonUseStyles();
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const labValues = report.labReport.values;

    const apoeAccuracyScore = report.dietAndNutritionReport?.apoeAccuracyScore;

    return (
        props.mode !== HealthReportMode.PrintMode && isSmallScreen ? (
        <HealthReportMobileBasePage section={HealthReportSections.ChronicDisease} pageNumber={pageNumber} practice={practice}>
            <>
                <Box px={2}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box>
                                <span className={classes.reportTitle}>Dementia</span>
                            </Box>
                            <Box mt="10px">
                                <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeMediumReport, commonClasses.textRegularReport)}>Your genetics and Labs</span>
                            </Box>
                            <Box mt="12px" fontSize="14px" lineHeight="22px">
                                <span>There are many, many causes of Alzheimers. However, some of the most common causes are easily managed. First, we need to assess your risk. That is best assessed by looking both at genetics, such as Apo-ε status, as well as TNF and SIRT1. But we also have to evaluate your current health, as metabolism and inflammation are common contributors as well. This summary will help assess your risk, and identify specific interventions you can follow to help mitigate that.</span>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box>
                                <Box mt="10px" display="flex" justifyContent="center">
                                    <img src={insulinIcon} style={{ width: '166px', height: 'auto' }} alt="img" />
                                </Box>
                                <Box display="flex" justifyContent="center" textAlign="center" mt={2}>
                                    <span className="text-medium">Insulin Resistance Score: {report.dietAndNutritionReport.insulinResistanceScore == null
                                        ? "Unavailable"
                                        : Math.round(report.dietAndNutritionReport.insulinResistanceScore?.scorePercent) + "%"}</span>
                                </Box>
                                <Box px={5}>
                                    <ProgressDiagramComponent
                                        colors={['#626d4b', '#e10003']}
                                        data={[
                                            {
                                                label: '',
                                                value: report.dietAndNutritionReport.insulinResistanceScore?.scorePercent,
                                                labelPosition: LabelPosition.Bottom
                                            }
                                        ]}
                                        height={15}
                                        unavailable={report.dietAndNutritionReport.insulinResistanceScore == null}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Divider className="report-divider" />
                <Box px={2}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box className="text-light-medium">
                                <Box>
                                    <span className={classes.reportSubTitle}>Trophic Support</span>
                                </Box>
                                <Box mt={1}>
                                    <LabResultComponent label="Homocysteine" value={labValues.getLabReportValue(LabInputNames.Homocysteine)} />
                                    <LabResultComponent label="Testosterone" value={labValues.getLabReportValue(LabInputNames.TestosteroneTotal)} />
                                    <LabResultComponent label="Estrogen" value={labValues.getLabReportValue(LabInputNames.Estradio)} />
                                    <LabResultComponent label="Progesterone" value={labValues.getLabReportValue(LabInputNames.Progesterone)} />
                                    <LabResultComponent label="TSH" value={labValues.getLabReportValue(LabInputNames.Tsh)} />
                                    <LabResultComponent label="T4 Free" value={labValues.getLabReportValue(LabInputNames.FreeT4)} />
                                    <LabResultComponent label="Vitamin D" value={labValues.getLabReportValue(LabInputNames.VitaminD)} />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box className="text-light-medium" mt="20px">
                                <Box>
                                    <span className={classes.reportSubTitle}>Inflammation</span>
                                </Box>
                                <Box mt={1}>
                                    <LabResultComponent label="CRP" value={labValues.getLabReportValue(LabInputNames.Crp)} />
                                    <LabResultComponent label="A/G ratio" value={labValues.getLabReportValue(LabInputNames.AgRatio)} />
                                    <LabResultComponent label="Omega 3" value={labValues.getLabReportValue(LabInputNames.Omega3)} />
                                    <LabResultComponent label="Co q10" value={labValues.getLabReportValue(LabInputNames.CoQ10)} />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box mt="20px" width={1}>
                                <DnaTableComponent
                                    dna={report.longevityReport.dementiaRiskScore.dna}
                                    colorMap={riskColorMap}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Divider className="report-divider" />
                <Box px={2}>
                    <Box>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box>
                                    <span className={classes.reportTitle}>Your APOE status: </span>
                                    <span className={classes.reportTitle}>Apo-{apoeAccuracyScore?.apoe}</span>
                                    <span className={clsx(commonClasses.textRegular, commonClasses.colorBlack)}> ({apoeAccuracyScore.scorePercent}%)<span className={commonClasses.colorBad}>*</span></span>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box mt="20px" display="flex" justifyContent="center">
                                    <img src={brainIcon} style={{ width: '141px', height: 'auto' }} alt="img" />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box>
                        <Box mt="20px">
                            <span className={classes.reportSubTitle}>Dementia Risk</span>
                        </Box>
                        <Box mt="14px">
                            <ProgressDiagramComponent
                                height={22}
                                colors={['#3c7d9f', '#e10003']}
                                data={[
                                    {
                                        label: `Apo-${apoeAccuracyScore?.apoe}`,
                                        value: report.longevityReport.dementiaRiskScore.scorePercent,
                                        labelPosition: LabelPosition.Bottom
                                    }
                                ]}
                                unavailable={!report.longevityReport.dementiaRiskScore?.isCalculated}
                            />
                        </Box>
                    </Box>
                    <Box mt="30px">
                        {parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
                            ? <Box>
                                {HealthReportMode.EditMode === mode && <Box display="flex" justifyContent="space-between" mb={1}>
                                    <span className="report-title"></span>
                                    <WildHealthButton
                                        id="health-recommendation-new-save"
                                        onClick={() => handleSaveChangesNew()}
                                        disabled={!isChanged}>
                                        <Box >
                                            Save changes
                                        </Box>
                                    </WildHealthButton>
                                </Box>}
                                <RecommendationSingleSelectComponent
                                    currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.Dementia)}
                                    recommendation={report.longevityReport.dementiaRecommendation}
                                    type={RecommendationTypesNumbers.Dementia}
                                    handleSelected={handleSelected}
                                    handleChanges={handleChangesNewRecommendation}
                                    handleAddRecommendation={handleAddRecommendation}
                                    mode={mode}
                                />
                            </Box>

                            : <HealthRecommendationComponent
                                mode={mode}
                                rows={recommendationRowsCapacity[RecommendationTypesNumbers.Dementia]}
                                recommendation={report.longevityReport.dementiaRecommendation}
                                handleContentChanges={!handleChanges ? handleChanges : (value) => {
                                    handleChanges('longevityReport.dementiaRecommendation.content', value)
                                }}
                                handleManualContentChanges={!handleChanges ? handleChanges : (value) => {
                                    handleChanges('longevityReport.dementiaRecommendation.manualContent', value)
                                }}
                                handleSaveChanges={handleSaveChanges}
                                isChanged={isChanged} />
                        }
                    </Box>
                    <Box pt={2} lineHeight="16px" display="flex"><Box mr={0.5} className={commonClasses.colorBad}>* </Box><span className={clsx(commonClasses.size14, commonClasses.textRegular)}><span className={clsx(commonClasses.textMedium)}>{apoeAccuracyScore?.message}</span> Apo-ε is a challenging gene to test because it resides on a very dense section of your genome. We take nearly 100 separate readings to help translate the results and have developed a proprietary algorithm to generate the highest possible accuracy. We also report the accuracy of this gene in order to better understand the confidence of this test. More accurate laboratory testing is available if necessary or desired.</span></Box>
                </Box>
            </>
        </HealthReportMobileBasePage>
        ) : (
            <HealthReportBasePage section={HealthReportSections.ChronicDisease} pageNumber={pageNumber} practice={practice}>
                <>
                    <Box px={15} pt={5}>
                        <Grid container>
                            <Grid item xs={8}>
                                <Box>
                                    <span className="report-title">Dementia</span>
                                </Box>
                                <Box mt={1}>
                                    <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeMediumReport, commonClasses.textMediumReport)}>Your genetics and Labs</span>
                                </Box>
                                <Box>
                                    <span>There are many, many causes of Alzheimers. However, some of the most common causes are easily managed. First, we need to assess your risk. That is best assessed by looking both at genetics, such as Apo-ε status, as well as TNF and SIRT1. But we also have to evaluate your current health, as metabolism and inflammation are common contributors as well. This summary will help assess your risk, and identify specific interventions you can follow to help mitigate that.</span>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box>
                                    <Box pt={2} pr={10} pl={10}>
                                        <img src={insulinIcon} style={{ width: '100%' }} alt="img" />
                                    </Box>
                                    <Box display="flex" justifyContent="center" mt={1}>
                                        <span className="text-medium">Insulin Resistance Score: {report.dietAndNutritionReport.insulinResistanceScore == null
                                            ? "Unavailable"
                                            : Math.round(report.dietAndNutritionReport.insulinResistanceScore?.scorePercent) + "%"}</span>
                                    </Box>
                                    <Box mt={2} px={6}>
                                        <ProgressDiagramComponent
                                            colors={['#626d4b', '#e10003']}
                                            data={[
                                                {
                                                    label: '',
                                                    value: report.dietAndNutritionReport.insulinResistanceScore?.scorePercent,
                                                    labelPosition: LabelPosition.Bottom
                                                }
                                            ]}
                                            height={15}
                                            unavailable={report.dietAndNutritionReport.insulinResistanceScore == null}
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider className="report-divider" />
                    <Box px={15}>
                        <Grid container>
                            <Grid item xs={4}>
                                <Box className="text-light-medium" pr={5}>
                                    <Box>
                                        <span className="report-sub-title">Trophic Support</span>
                                    </Box>
                                    <Box>
                                        <LabResultComponent label="Homocysteine" value={labValues.getLabReportValue(LabInputNames.Homocysteine)} />
                                        <LabResultComponent label="Testosterone" value={labValues.getLabReportValue(LabInputNames.TestosteroneTotal)} />
                                        <LabResultComponent label="Estrogen" value={labValues.getLabReportValue(LabInputNames.Estradio)} />
                                        <LabResultComponent label="Progesterone" value={labValues.getLabReportValue(LabInputNames.Progesterone)} />
                                        <LabResultComponent label="TSH" value={labValues.getLabReportValue(LabInputNames.Tsh)} />
                                        <LabResultComponent label="T4 Free" value={labValues.getLabReportValue(LabInputNames.FreeT4)} />
                                        <LabResultComponent label="Vitamin D" value={labValues.getLabReportValue(LabInputNames.VitaminD)} />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box className="text-light-medium" pl={5}>
                                    <Box>
                                        <span className="report-sub-title">Inflammation</span>
                                    </Box>
                                    <Box>
                                        <LabResultComponent label="CRP" value={labValues.getLabReportValue(LabInputNames.Crp)} />
                                        <LabResultComponent label="A/G ratio" value={labValues.getLabReportValue(LabInputNames.AgRatio)} />
                                        <LabResultComponent label="Omega 3" value={labValues.getLabReportValue(LabInputNames.Omega3)} />
                                        <LabResultComponent label="Co q10" value={labValues.getLabReportValue(LabInputNames.CoQ10)} />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box mt={4} height="100%" pl={10}>
                                    <DnaTableComponent
                                        dna={report.longevityReport.dementiaRiskScore.dna}
                                        colorMap={riskColorMap}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider className="report-divider" />
                    <Box px={15}>
                        <Box>
                            <Grid container>
                                <Grid item xs={9}>
                                    <Box>
                                        <span className="report-title">Your APOE status: </span>
                                        <span className="report-title-lowercase">Apo-{apoeAccuracyScore?.apoe}</span>
                                        <span className={clsx(commonClasses.size28, commonClasses.textRegular, commonClasses.colorBlack)}> ({apoeAccuracyScore.scorePercent}%)<span className={commonClasses.colorBad}>*</span></span>
                                    </Box>
                                </Grid>
                                <Grid item xs={3}>
                                    <Box px={5}>
                                        <img src={brainIcon} style={{ width: '100%' }} alt="img" />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box>
                            <Box>
                                <span className="report-sub-title">Dementia Risk</span>
                            </Box>
                            <Box mt={2}>
                                <ProgressDiagramComponent
                                    height={22}
                                    colors={['#3c7d9f', '#e10003']}
                                    data={[
                                        {
                                            label: `Apo-${apoeAccuracyScore?.apoe}`,
                                            value: report.longevityReport.dementiaRiskScore.scorePercent,
                                            labelPosition: LabelPosition.Bottom
                                        }
                                    ]}
                                    unavailable={!report.longevityReport.dementiaRiskScore.isCalculated}
                                />
                            </Box>
                        </Box>
                        <Box mt={4}>
                            {parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
                                ? <Box>
                                    {HealthReportMode.EditMode === mode && <Box display="flex" justifyContent="space-between" mb={1}>
                                        <span className="report-title"></span>
                                        <WildHealthButton
                                            id="health-recommendation-new-save"
                                            onClick={() => handleSaveChangesNew()}
                                            disabled={!isChanged}>
                                            <Box>
                                                <SaveIcon />
                                            </Box>
                                            <Box ml={2} className={commonClasses.colorWhite}>
                                                Save changes
                                            </Box>
                                        </WildHealthButton>
                                    </Box>}
                                    <RecommendationSingleSelectComponent
                                        currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.Dementia)}
                                        recommendation={report.longevityReport.dementiaRecommendation}
                                        type={RecommendationTypesNumbers.Dementia}
                                        handleSelected={handleSelected}
                                        handleChanges={handleChangesNewRecommendation}
                                        handleAddRecommendation={handleAddRecommendation}
                                        mode={mode}
                                    />
                                </Box>

                                : <HealthRecommendationComponent
                                    mode={mode}
                                    rows={recommendationRowsCapacity[RecommendationTypesNumbers.Dementia]}
                                    recommendation={report.longevityReport.dementiaRecommendation}
                                    handleContentChanges={!handleChanges ? handleChanges : (value) => {
                                        handleChanges('longevityReport.dementiaRecommendation.content', value)
                                    }}
                                    handleManualContentChanges={!handleChanges ? handleChanges : (value) => {
                                        handleChanges('longevityReport.dementiaRecommendation.manualContent', value)
                                    }}
                                    handleSaveChanges={handleSaveChanges}
                                    isChanged={isChanged} />
                            }
                        </Box>
                        <Box pt={1} lineHeight="16px" display="flex">
                            <Box mr={0.5} className={commonClasses.colorBad}>*</Box>
                            <span className={clsx(commonClasses.size14, commonClasses.textRegular)}>
                                <span className={clsx(commonClasses.textMedium)}>{apoeAccuracyScore?.message} </span>
                                Apo-ε is a challenging gene to test because it resides on a very dense section of your
                                genome. We take nearly 100 separate readings to help translate the results and have
                                developed a proprietary algorithm to generate the highest possible accuracy.
                                We also report the accuracy of this gene in order to better understand the confidence
                                of this test. More accurate laboratory testing is available if necessary or desired.</span>
                        </Box>
                    </Box>
                </>
            </HealthReportBasePage>
        )
    )
}
