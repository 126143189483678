import Axios from 'axios-observable';
import {Observable} from "rxjs";
import { authHeader } from "../../common/helpers/auth-header";
import { API_URL } from "../../../config";
import { snackService } from '../../common/snack/state';
import { syncRecordsStore, SyncRecordsStore } from "../stores/syncRecordsStore";
import { SyncRecordStatusCount, SyncRecordListModel, SyncRecordReconcileModel, SyncRecordTypeModel } from '../models/syncRecords.model';

/**
 * Provides methods for working with syncRecords
 */
export class SyncRecordsService {
    private url = `${API_URL}SyncRecords`;

    constructor(private syncRecordsStore: SyncRecordsStore) {
    }


    public getSyncRecords(): Observable<SyncRecordTypeModel[]> {
        const config = {
            headers: authHeader()
        };
        return new Observable<SyncRecordTypeModel[]>(observer => {
            Axios.get<SyncRecordTypeModel[]>(`${this.url}`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.syncRecordsStore.update({ syncRecordTypes: response.data })
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getSyncRecordType(typeName: string, practiceId: number): Observable<SyncRecordStatusCount[]> {
        const config = {
            headers: authHeader()
        };
        return new Observable<SyncRecordStatusCount[]>(observer => {
            Axios.get<SyncRecordStatusCount[]>(`${this.url}/Type/${typeName}/${practiceId}`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.syncRecordsStore.update({ syncRecordStatusCounts: response.data })
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }


    public getSyncRecordsSearch(typeName: string, practiceId: number, query: string, pageNumber?: number | null): Observable<SyncRecordListModel[]> {
        const config = {
            headers: authHeader()
        };
        const searchQuery = query ? `searchQuery=${query}&` : '';
        const pageNumberQuery = pageNumber ? `page=${pageNumber}` : '';
        return new Observable<SyncRecordListModel[]>(observer => {
            Axios.get<SyncRecordListModel[]>(`${this.url}/${typeName}/${practiceId}/search?` +
            `${searchQuery}` +
            `${pageNumberQuery}`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.syncRecordsStore.update({ searchSyncRecords: response.data })
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getSyncRecordsStatus(typeName: string, practiceId: number, statusId: number, pageNumber?: number | null, createdAt?: string | null): Observable<SyncRecordListModel> {
        const config = {
            headers: authHeader()
        };
        const pageNumberQuery = pageNumber ? `page=${pageNumber}&` : '';
        const createdAtQuery = createdAt ? `createdAt=${createdAt}` : '';
        return new Observable<SyncRecordListModel>(observer => {
            Axios.get<SyncRecordListModel>(`${this.url}/${typeName}/${practiceId}/Status/${statusId}?` +
            `${pageNumberQuery}` +
            `${createdAtQuery}`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.syncRecordsStore.update({
                            targetSyncRecords: response.data.syncRecords,
                            targetSyncRecordListStatus: response.data.status,
                            totalCount: response.data.total
                        })
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    
    public getSyncRecordsReconcile(syncRecordId: number): Observable<SyncRecordReconcileModel[]> {
        const config = {
            headers: authHeader()
        };
        return new Observable<SyncRecordReconcileModel[]>(observer => {
            Axios.get<SyncRecordReconcileModel[]>(`${this.url}/${syncRecordId}/Reconcile`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.syncRecordsStore.update({ syncRecordReconciles: response.data })
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    
    public reconcileSyncRecord(syncRecordId: number, model: SyncRecordReconcileModel[]) {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.post(`${this.url}/${syncRecordId}/Reconcile`, model, config)
                .pipe()
                .subscribe(
                    response => {
                        snackService.success('SyncRecord successfully reconciled');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    public performSyncRecordAction(syncRecordId: number, action: number) {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.post<SyncRecordReconcileModel>(`${this.url}/${syncRecordId}/Action/${action}`, null, config)
                .pipe()
                .subscribe(
                    response => {
                        snackService.success('SyncRecord action performed successfully');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }
}

export const syncRecordsService = new SyncRecordsService(syncRecordsStore);
