import React, { useEffect, useState } from 'react';
import { navigationService } from "../../../../services/navigation.service";
import { PageSliderNavigationContext } from '../../../common/models/navigation.models';
import { IErrorState } from "../../../common/validation/error-state";
import {
    AnswerModel,
    QuestionnaireModel,
    QuestionnaireResultModel,
    SaveQuestionnaireResultModel,
    QuestionType,
    BloodPressure
} from "../../models/questionnaire.models";
import { questionnaireService } from "../../services/questionnaire.service";
import { QuestionnaireSectionComponent } from "../questionnaireSectionComponent/QestionnaireSectionComponent";
import { hasAnswer } from '../../helpers/has-answer';
import { useHistory } from 'react-router';
import { GeneralValidator } from '../../../common/validation/general-validator';
import { getSpecificQuestions } from '../../helpers/specific-questions-provider';
import { Gender } from "../../../common/models/user.models";
import { isGenderMatch } from "../../helpers/is-gender-match";
import {isQuestionnaireSectionAvailable} from "../../helpers/sections-availability-provider";
import {useFlags} from "launchdarkly-react-client-sdk";
import { MedicalHistoryAndLifestyleSectionNames, MedicalHistoryAndLifestyleQuestionNames } from '../../static/medicalHistroryQuestionnaire.static';

interface QuestionnaireComponentState extends IErrorState {
    result: QuestionnaireResultModel;
    newAnswers: AnswerModel[];
    questionnaire: QuestionnaireModel;
    currentSection: string;
    navigationContext: PageSliderNavigationContext;
    isLoading: boolean;
    sectionForSkipping: string[];
    hideNext: boolean;
    hasBloodPressure: boolean;
}

export function useFacade(
    result: QuestionnaireResultModel,
    questionnaire: QuestionnaireModel,
    sections: { [key: string]: string[] },
    validator: GeneralValidator,
    gender: Gender,
    patientId: number | null,
    handleGoBack: Function | null
): [
        QuestionnaireComponentState,
        JSX.Element,
        () => number,
        (status: boolean) => void,
    ] {

    const history = useHistory();

    const featureFlags = useFlags();

    const getSectionForSkipping = (questionnaireResult?: QuestionnaireResultModel) => {
        const sectionForSkipping = [];

        Object.keys(sections)
            .forEach(sectionName => {
                const questions = sections[sectionName];
                const section = questionnaire.questions.filter(x => questions.includes(x.name) && !isGenderMatch(x, gender));
                if (section && section.length) {
                    sectionForSkipping.push(...section.map(el => el.name));
                }

                if (!isQuestionnaireSectionAvailable(sectionName, questionnaireResult ?? result, featureFlags)) {
                    sectionForSkipping.push(sectionName);
                }
            });

        return sectionForSkipping;
    }

    const canSkipSection = (questions: string[], sectionName: string, sectionForSkipping: string[]) => {
        if (sectionForSkipping.includes(sectionName)) {
            return true;
        }

        const relatedQuestions = questionnaire.questions.filter(x => questions.includes(x.name) && isGenderMatch(x, gender))
        return !relatedQuestions || !relatedQuestions.length;
    }

    const sectionNames = Object.keys(sections);

    const getCurrentSection = (): string => {
        let section = Object.keys(sections)
            .filter(x => !canSkipSection(sections[x], x, getSectionForSkipping()))
            .find(s => !sections[s].some(q => hasAnswer(result.answers, q)));

        if (!section) {
            section = sectionNames[sectionNames.length - 1];
        }

        return section;
    }

    const [state, setState] = useState({
        questionnaire: questionnaire,
        result: result,
        newAnswers: [],
        currentSection: getCurrentSection(),
        sectionForSkipping: [],
        navigationContext: {
            nextButton: 'Next',
            backButton: 'Back',
            canGoBack: () => false,
            goBack: () => { },
            canGoNext: () => false,
            goNext: () => { },
        } as PageSliderNavigationContext,

        medications: [],
        allergies: [],
        medicationDialogOpened: false,
        allergiesDialogOpened: false,
        isLoading: false,
        errors: {},
        hideNext: false,
        hasBloodPressure: false
    } as QuestionnaireComponentState);

    const setSkipSectionName = () => {

        state.sectionForSkipping = [];

        state.sectionForSkipping.push(...getSectionForSkipping(state.result));

        setState(state => ({ ...state }));
    }

    const getNextSection = (currentSection: string) => {
        if (currentSection === sectionNames[sectionNames.length - 1]) {
            return null;
        }

        const currentIndex = sectionNames.indexOf(currentSection);
        const nextSection = sectionNames[currentIndex + 1];

        if (canSkipSection(sections[nextSection], nextSection, state.sectionForSkipping)) {
            return getNextSection(nextSection);
        }

        return nextSection;
    }

    const getPreviousSection = (currentSection: string) => {
        if (currentSection === sectionNames[0]) {
            return currentSection;
        }

        const currentIndex = sectionNames.indexOf(currentSection);
        const previousSection = sectionNames[currentIndex - 1];

        if (canSkipSection(sections[previousSection], previousSection, state.sectionForSkipping)) {
            return getPreviousSection(previousSection);
        }

        return previousSection;
    }

    const getQuestionsRequirements = (): { [id: string]: () => boolean } => {
        const parentQuestions = questionnaire.questions.filter(x => x.childrenNames.length);
        const childQuestions = questionnaire.questions.filter(x => x.parentAnswers.length);

        return Object.assign({}, ...childQuestions.map((x) => ({
            [x.name]: () => {
                const parentQuestion = parentQuestions.find(pq => pq.childrenNames.includes(x.name));
                const root = parentQuestions.find(pq => pq.childrenNames.includes(parentQuestion.name))
                return (!root || parentQuestion.parentAnswers.some(pc => hasAnswer(state.result.answers, root.name, pc))) && x.parentAnswers.some(pc => hasAnswer(state.result.answers, parentQuestion.name, pc))
            }
        })));
    }

    const questionsRequirements = getQuestionsRequirements();

    const handleChanges = (field: string, value: string) => {
        validator.validateAndSetState(state, setState, field, value);

        const result = state.result;
        const newAnswers = state.newAnswers;
        const answer = result.answers.find(x => x.key === field);
        if (answer) {
            answer.value = value;
            const updateAnswer = newAnswers.find(x => x.key === field)
            if (updateAnswer) {
                updateAnswer.value = value;
            } else {
                newAnswers.push({
                    key: field,
                    value: value
                });
            }
        } else {
            result.answers.push({
                key: field,
                value: value
            });
            newAnswers.push({
                key: field,
                value: value
            });
        }

        result.answers = [...result.answers];
        setState({ ...state, result: result, newAnswers: newAnswers });
        if (state.hideNext) {
            goNext();
        }
    }

    const handleToggleBloodPressure = (status: boolean) => {
        const result = state.result;
        const newAnswers = state.newAnswers;
        if (status) {
            const topPressureAnswer = result.answers.find(x => x.key === MedicalHistoryAndLifestyleQuestionNames.DEMOGRAPHICS_AND_BIOMETRICS_SBP_TOP);
            const bottomPressureAnswer = result.answers.find(x => x.key === MedicalHistoryAndLifestyleQuestionNames.DEMOGRAPHICS_AND_BIOMETRICS_SBP_BOTTOM);
            if (topPressureAnswer) {
                topPressureAnswer.value = BloodPressure.top;
                const updateAnswer = newAnswers.find(x => x.key === MedicalHistoryAndLifestyleQuestionNames.DEMOGRAPHICS_AND_BIOMETRICS_SBP_TOP)
                if (updateAnswer) {
                    updateAnswer.value = BloodPressure.top;
                } else {
                    newAnswers.push({
                        key: MedicalHistoryAndLifestyleQuestionNames.DEMOGRAPHICS_AND_BIOMETRICS_SBP_TOP,
                        value: BloodPressure.top
                    });
                }
            } else {
                result.answers.push({
                    key: MedicalHistoryAndLifestyleQuestionNames.DEMOGRAPHICS_AND_BIOMETRICS_SBP_TOP,
                    value: BloodPressure.top
                });
                newAnswers.push({
                    key: MedicalHistoryAndLifestyleQuestionNames.DEMOGRAPHICS_AND_BIOMETRICS_SBP_TOP,
                    value: BloodPressure.top
                });
            }
            if (bottomPressureAnswer) {
                bottomPressureAnswer.value = BloodPressure.bottom;
                const updateAnswer = newAnswers.find(x => x.key === MedicalHistoryAndLifestyleQuestionNames.DEMOGRAPHICS_AND_BIOMETRICS_SBP_BOTTOM)
                if (updateAnswer) {
                    updateAnswer.value = BloodPressure.bottom;
                } else {
                    newAnswers.push({
                        key: MedicalHistoryAndLifestyleQuestionNames.DEMOGRAPHICS_AND_BIOMETRICS_SBP_BOTTOM,
                        value: BloodPressure.bottom
                    });
                }
            } else {
                result.answers.push({
                    key: MedicalHistoryAndLifestyleQuestionNames.DEMOGRAPHICS_AND_BIOMETRICS_SBP_BOTTOM,
                    value: BloodPressure.bottom
                });
                newAnswers.push({
                    key: MedicalHistoryAndLifestyleQuestionNames.DEMOGRAPHICS_AND_BIOMETRICS_SBP_BOTTOM,
                    value: BloodPressure.bottom
                });
            }
        }

        result.answers = [...result.answers];
        setState({ ...state, result: result, newAnswers: newAnswers, hasBloodPressure: status });
    }

    const questionComponents = getSpecificQuestions(handleChanges, state.questionnaire, state.result, state.result.answers, state.errors, patientId);

    const currentSection = (<QuestionnaireSectionComponent
        questionnaire={state.questionnaire}
        result={state.result}
        questions={sections[state.currentSection]}
        handleChanges={handleChanges}
        errors={state.errors}
        questionRequirements={questionsRequirements}
        questionComponents={questionComponents}
        gender={gender}
        disabled={state.currentSection === MedicalHistoryAndLifestyleSectionNames.DEMOGRAPHICS_AND_BIOMETRICS_BLOOD_PRESSURE ? state.hasBloodPressure : false}
    />)

    const getSaveModel = (result: QuestionnaireResultModel): SaveQuestionnaireResultModel => {
        return {
            answers: state.newAnswers,
            questionnaireResultId: result.id,
            patientId: patientId
        };
    }

    const submit = () => {
        if (patientId) {
            questionnaireService.submitAsEmployee(getSaveModel(state.result)).subscribe(() => {
                if (handleGoBack) {
                    handleGoBack();
                }
            });
        } else {
            questionnaireService.submitAsPatient(getSaveModel(state.result)).subscribe(() => {
                questionnaireService.getNotificationAvailable().subscribe();
                navigationService.toMyHealthForms(history);
            });
        }
    }

    const goNext = () => {
        setState(state => ({ ...state, isLoading: true }));

        setSkipSectionName();

        let nextSection = getNextSection(state.currentSection);
        if (state.sectionForSkipping.includes(nextSection)) {
            nextSection = getNextSection(nextSection)
        }

        if (!nextSection) {
            return submit();
        }

        const callBack = () => {
            const hideNext = state
                .questionnaire
                .questions
                .find(x => sections[nextSection].some(q => q === x.name) && sections[nextSection].length === 1 && !x.optional && !x.childrenNames.length && x.type === QuestionType.SelectOne) && nextSection !== sectionNames[0];

            setState({
                ...state,
                newAnswers: [],
                isLoading: false,
                currentSection: nextSection,
                hideNext: hideNext
            });
        }

        if (patientId) {
            questionnaireService.saveAsEmployee(getSaveModel(state.result)).subscribe(() => {
                callBack();
            });
        } else {
            questionnaireService.save(getSaveModel(state.result)).subscribe(() => {
                callBack();
            });
        }
    }

    const goBack = () => {
        setSkipSectionName();

        let previousSection = getPreviousSection(state.currentSection);
        if (state.sectionForSkipping.includes(previousSection)) {
            previousSection = getPreviousSection(previousSection)
        }

        const hideNext = state.questionnaire.questions.find(x => sections[previousSection].some(q => q === x.name) && sections[previousSection].length === 1  && !x.optional && !x.childrenNames.length && x.type === QuestionType.SelectOne) && previousSection !== sectionNames[0];

        setState({ ...state, currentSection: previousSection, hideNext: hideNext });
    }

    const getNavigationContext = (): PageSliderNavigationContext => {
        const canGoBack = state.currentSection !== sectionNames[0];
        const nextButton = state.currentSection === sectionNames[sectionNames.length - 1] ? 'Complete' : 'Next Step';

        const requiredQuestions = state
            .questionnaire
            .questions
            .filter(x => sections[state.currentSection].some(q => q === x.name) && !x.optional);

        const canGoNext = requiredQuestions.every(x => hasAnswer(state.result.answers, x.name) || (questionsRequirements[x.name] !== undefined && questionsRequirements[x.name]() === false)) && validator.stateIsValid(state)

        return {
            nextButton: nextButton,
            backButton: 'Previous Step',
            canGoBack: () => (canGoBack),
            goBack: goBack,
            canGoNext: () => (canGoNext),
            goNext: goNext,
        } as PageSliderNavigationContext
    }

    const getProgress = (): number => {
        const currentIndex = sectionNames.indexOf(state.currentSection);

        return 100 / sectionNames.length * currentIndex;
    }

    useEffect(() => {
        setSkipSectionName();
    }, []);



    useEffect(() => {
        const currentQuestionary = state.questionnaire.questions.find(x => sections[state.currentSection].some(q => q === x.name) && sections[state.currentSection].length === 1 && !x.optional && !x.childrenNames.length && x.type === QuestionType.SelectOne);
        const hideNext = currentQuestionary && state.currentSection !== sectionNames[0] && !state.result.answers.find(el => el.key === currentQuestionary.name);
        setState({ ...state, navigationContext: getNavigationContext(), hideNext: hideNext || false })
    }, [state.currentSection, state.result.answers]);

    return [state, currentSection, getProgress, handleToggleBloodPressure]
}
