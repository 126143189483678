import { AppointmentPurposeType, AppointmentPurposeTypeNames, AppointmentWithType } from "../../../models/appointments.enums";
import { AppointmentInfoPopoverComponent } from "../../appointmentInfoPopover/AppointmentInfoPopoverComponent";
import { EditAppointmentDialogComponent } from "../../editAppointmentDialog/EditAppointmentDialogComponent";
import { WildHealthButton } from "../../../../common/components/wildHealthButton/WildHealthButton";
import { EmployeeAppointmentModel } from "../../../models/appointments.models";
import commonUseStyles from "../../../../common/styles/common.styles";
import { useStyles } from "./AppointmentItemComponent.styles";
import { useFacade } from "./appointmentItemComponent.hooks";
import { Box, Tooltip } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import { WildHealthLinkButton } from "../../../../common/components/WildHealthLinkButton";
import {appointmentColors} from "../../../constants/appointmentColors";
import VideoCamOffIcon from '@material-ui/icons/VideocamOffOutlined';


interface BlockedItemComponentProps {
    appointment: EmployeeAppointmentModel
}


export const AppointmentItemComponent: React.FC<BlockedItemComponentProps> = (props: BlockedItemComponentProps) => {
    const { appointment } = props;
    const [
        {
            infoAnchor,
            editDialogOpen
        },
        handleOpenInfoPopover,
        handleCloseInfoPopover,
        handleCancelAppointment,
        handleJoinAppointment,
        handleToggleEditDialog,
        handleRescheduleAppointment,
        goToPatientProfile
    ] = useFacade();

    const classes = useStyles();
    const commonClasses = commonUseStyles();

    const getAppointmentName = (appointment: EmployeeAppointmentModel) => {
        const appointmentName = appointment.purpose === AppointmentPurposeType.Other
            ? appointment.name
            : `${appointment.patient?.firstName} ${appointment.patient?.lastName}`
        return appointmentName.length > 20
            ? <Tooltip placement="top" arrow title={appointmentName}>
                <span className={classes.userName}>{appointmentName.slice(0, 20) + '...'}</span>
            </Tooltip>
            : <span className={classes.userName}>{appointmentName}</span>
    }

    const getAppointmentColor = (appointment: EmployeeAppointmentModel): string => {
        switch (appointment.purpose) {
            case AppointmentPurposeType.Welcome: return appointmentColors.purple;
            case AppointmentPurposeType.Consult: return (appointment.withType === AppointmentWithType.HealthCoachAndProvider || appointment.withType === AppointmentWithType.Provider)
                ? appointmentColors.strongBlue
                : appointmentColors.green;
            case AppointmentPurposeType.FollowUp: return (appointment.withType === AppointmentWithType.HealthCoachAndProvider || appointment.withType === AppointmentWithType.Provider)
                ? appointmentColors.blue
                : appointmentColors.orange;
            case AppointmentPurposeType.Other: return appointmentColors.red;
        }

        return appointmentColors.orange;
    }

    return (
        <Box className={clsx(classes.root, {
            [classes.wc]: appointment.purpose === AppointmentPurposeType.Welcome,
            [classes.fmc]: appointment.purpose === AppointmentPurposeType.FollowUp && (appointment.withType === AppointmentWithType.HealthCoachAndProvider || appointment.withType === AppointmentWithType.Provider),
            [classes.imc]: appointment.purpose === AppointmentPurposeType.Consult && (appointment.withType === AppointmentWithType.HealthCoachAndProvider || appointment.withType === AppointmentWithType.Provider),
            [classes.fcc]: appointment.purpose === AppointmentPurposeType.FollowUp && appointment.withType === AppointmentWithType.HealthCoach,
            [classes.icc]: appointment.purpose === AppointmentPurposeType.Consult && appointment.withType === AppointmentWithType.HealthCoach,
            [classes.other]: appointment.purpose === AppointmentPurposeType.Other,
        })} >
            <Box className={classes.info} onClick={(e) => handleOpenInfoPopover(e)}>
                <Box display='grid' className={clsx(commonClasses.pointer)} >
                    <WildHealthLinkButton
                        id="appointment-item-link-patient-profile"
                        onClick={() => goToPatientProfile(appointment.patient.id)}
                        content={getAppointmentName(appointment)}
                    />
                    <span>{AppointmentPurposeTypeNames.get(appointment.purpose)}</span>
                </Box>
                <Box>
                    { 
                        !(appointment.userOptions?.meetingRecordingConsent ?? false) && 
                        <VideoCamOffIcon className={classes.recordingIcon}></VideoCamOffIcon> 
                    }
                    {  
                        appointment.joinLink &&
                        <WildHealthButton
                            id="appointment-item-join"
                            border='none'
                            onClick={() => handleJoinAppointment(appointment.joinLink)}
                        >
                            Join
                        </WildHealthButton>
                    }
                </Box>         
            </Box>
            <AppointmentInfoPopoverComponent
                anchorEl={infoAnchor}
                open={Boolean(infoAnchor)}
                onClose={() => handleCloseInfoPopover()}
                appointment={appointment}
                handleCancelAppointment={handleCancelAppointment}
                handleJoinAppointment={handleJoinAppointment}
                handleOpenEditDialog={() => handleToggleEditDialog(true)}
                handleRescheduleAppointment={handleRescheduleAppointment}
                appointmentColor={getAppointmentColor(appointment)}
            />
            <EditAppointmentDialogComponent
                open={editDialogOpen}
                appointment={appointment}
                handleClose={() => handleToggleEditDialog(false)}
            />
        </Box>
    );
}
