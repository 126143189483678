import React from "react";
import { Box } from "@material-ui/core";
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { useStyles } from './noteInternalRecommendation.styles';
import { TextMarkdownComponent } from "../textMarkdownComponent/TextMarkdownComponent";

interface NoteInternalRecommendationProps {
    internalContent: string;
}

export const NoteInternalRecommendationComponent: React.FC<NoteInternalRecommendationProps> = (props: NoteInternalRecommendationProps) => {
    const { internalContent } = props;
    const classes = useStyles();

    return (
        <Box>
            <Box>
                <span className="size-1_5 text-medium">INTERNAL NOTES</span>
            </Box>
            <Box mt={3}>
                {internalContent ?
                    <TextMarkdownComponent content={internalContent} classLink={classes.renderText} />
                    : <WildHealthPlaceHolder message="Internal notes are empty" />

                }
            </Box>
        </Box>
    )
}