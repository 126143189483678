import React from 'react';
import {QuestionnaireSurveyProps} from "./questionnaireSurvey.props";
import {InitialQuestionNames} from "../../../models/questionnaire.models";
import {QuestionnairesListComponent} from '../../../components/questionnairesListComponent/QuestionnairesListComponent';
import { Box } from '@material-ui/core';
import { PageSliderComponent } from '../../../../common/components/PageSliderComponent';

export const ExerciseQuestionnairePageFields = [
    InitialQuestionNames.PRIMARY_EXERCISES,
    InitialQuestionNames.EXERCISE_TYPE,
    InitialQuestionNames.EXERCISE_FREQUENCY,
    InitialQuestionNames.EXERCISE_PROGRAM_FOR,
    InitialQuestionNames.EXERCISE_LIMITATIONS,
    InitialQuestionNames.MAX_PUSH_UPS,
    InitialQuestionNames.MAX_BUR_PRESS_1_MIN
];

export const ExerciseSurvey: React.FC<QuestionnaireSurveyProps> = (props: QuestionnaireSurveyProps) => {
    const {
        errors,
        result,
        questionnaire,
        handleChanges,
        navigationContext
    } = props;

    return (
        <>
            <QuestionnairesListComponent
                questionnaire={questionnaire}
                errors={errors}
                handleChanges={handleChanges}
                navigationContext={navigationContext}
                questions={ExerciseQuestionnairePageFields}
                result={result}
                title={"Exercise:"}
            />
            <Box mt={5}>
                <PageSliderComponent context={navigationContext}/>
            </Box>
        </>
    )
}
