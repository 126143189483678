import { Box, useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { WildHealthButton } from '../../common/components/wildHealthButton/WildHealthButton';
import { WildHealthPlaceHolder } from "../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { HealthRecommendationComponent } from "../components/healthRecommendationComponent/HealthRecommendationComponent";
import { MicrobiomeItemComponent, MicrobiomeItemMobileComponent } from "../components/microbiomeItemComponent/MicrobiomeItemComponent";
import { RecommendationSingleSelectComponent } from '../components/recommendationSingleSelectComponent/RecommendationSingleSelectComponent';
import {
    HealthReportMode,
    HealthReportSections, HealthReportVersion, MicrobiomeType,
    recommendationRowsCapacity,
    RecommendationTypesNumbers
} from "../models/healthReport.models";
import { HealthReportBasePage, HealthReportMobileBasePage } from "./HealthReportBasePage";
import { useStyles } from './healthReport.styles';
import { HealthReportPageProps } from "./healthReportPageProps";

export const HealthReportMicrobiome2Page: React.FC<HealthReportPageProps> = (props: HealthReportPageProps) => {
    const {
        report,
        handleChanges,
        handleSaveChanges,
        pageNumber,
        isChanged,
        mode,
        practice,
        recommendations,
        handleSelected,
        handleChangesNewRecommendation,
        handleAddRecommendation,
        handleSaveChangesNew,
    } = props;
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const microbiome = report.microbiomeReport.microbiomeResistance.values.filter(x => x.type === MicrobiomeType.Tmao);

    const renderOtherMicrobiome = () => {
        if (!microbiome || !microbiome.length) {
            return (
                <Box mt={3}>
                    <WildHealthPlaceHolder height="750px" message="No data" />
                </Box>
            )
        }

        return microbiome.map((value, index) => {
            return <MicrobiomeItemComponent key={index} value={value} />
        });
    }

    return (
        props.mode !== HealthReportMode.PrintMode && isSmallScreen ? (
            <HealthReportMobileBasePage section={HealthReportSections.Microbiome} pageNumber={pageNumber} practice={practice}>
                <Box>
                    <Box px={2}>
                        <Box>
                            <span className={classes.reportTitle}>TMAO-producing bacteria</span>
                        </Box>

                        <Box>
                            {
                                microbiome.map((value, index) => (
                                    <Box key={index} mt="20px"><MicrobiomeItemMobileComponent value={value} /></Box>
                                ))
                            }
                        </Box>

                        <Box mt="30px">
                            {parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
                                ? <Box>
                                    {HealthReportMode.EditMode === mode && <Box display="flex" justifyContent="space-between" mb={1}>
                                        <span className="report-title"></span>
                                        <WildHealthButton
                                            id="health-recommendation-new-save"
                                            onClick={() => handleSaveChangesNew()}
                                            disabled={!isChanged}>
                                            <Box >
                                                Save changes
                                            </Box>
                                        </WildHealthButton>
                                    </Box>}
                                    <RecommendationSingleSelectComponent
                                        currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.Microbiome)}
                                        recommendation={report.microbiomeReport.recommendation}
                                        type={RecommendationTypesNumbers.Microbiome}
                                        handleSelected={handleSelected}
                                        handleChanges={handleChangesNewRecommendation}
                                        handleAddRecommendation={handleAddRecommendation}
                                        mode={mode}
                                    />
                                </Box>
                                : <HealthRecommendationComponent
                                    mode={mode}
                                    rows={recommendationRowsCapacity[RecommendationTypesNumbers.Microbiome]}
                                    recommendation={report.microbiomeReport.recommendation}
                                    handleContentChanges={!handleChanges ? handleChanges : (value) => {
                                        handleChanges('microbiomeReport.recommendation.content', value)
                                    }}
                                    handleManualContentChanges={!handleChanges ? handleChanges : (value) => {
                                        handleChanges('microbiomeReport.recommendation.manualContent', value)
                                    }}
                                    handleSaveChanges={handleSaveChanges}
                                    isChanged={isChanged} />
                            }
                        </Box>
                    </Box>
                </Box>
            </HealthReportMobileBasePage>
        ) : (
            <HealthReportBasePage section={HealthReportSections.Microbiome} pageNumber={pageNumber} practice={practice}>
                <Box>
                    <Box px={15} pt={5}>
                        <Box>
                            <span className="report-title">TMAO-producing bacteria</span>
                        </Box>

                        <Box mt={2} height="800px">
                            {renderOtherMicrobiome()}
                        </Box>

                        <Box mt={2}>
                            {parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
                                ? <Box>
                                    {HealthReportMode.EditMode === mode ? <Box display="flex" justifyContent="space-between" mb={1}>
                                        <span className="report-title">Your microbiome recommendations</span>
                                        <WildHealthButton
                                            id="health-recommendation-new-save"
                                            onClick={() => handleSaveChangesNew()}
                                            disabled={!isChanged}>
                                            <Box >
                                                Save changes
                                            </Box>
                                        </WildHealthButton>
                                    </Box>
                                    : <Box mb={1}><span className="report-title">Your microbiome recommendations</span></Box>}
                                    <RecommendationSingleSelectComponent
                                        currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.Microbiome)}
                                        recommendation={report.microbiomeReport.recommendation}
                                        type={RecommendationTypesNumbers.Microbiome}
                                        handleSelected={handleSelected}
                                        handleChanges={handleChangesNewRecommendation}
                                        handleAddRecommendation={handleAddRecommendation}
                                        mode={mode}
                                    />
                                </Box>
                                : <HealthRecommendationComponent
                                    mode={mode}
                                    rows={recommendationRowsCapacity[RecommendationTypesNumbers.Microbiome]}
                                    recommendation={report.microbiomeReport.recommendation}
                                    handleContentChanges={!handleChanges ? handleChanges : (value) => {
                                        handleChanges('microbiomeReport.recommendation.content', value)
                                    }}
                                    handleManualContentChanges={!handleChanges ? handleChanges : (value) => {
                                        handleChanges('microbiomeReport.recommendation.manualContent', value)
                                    }}
                                    handleSaveChanges={handleSaveChanges}
                                    isChanged={isChanged} />
                            }
                        </Box>
                    </Box>
                </Box>
            </HealthReportBasePage>
        )
    )
}
