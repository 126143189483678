import React, { useState } from 'react';
import './QuestionComponent.scss';
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, TextField } from "@material-ui/core";
import { QuestionComponentProps } from "./QuestionComponentProps";
import { CheckAnswer, emptyCheckAnswer } from "../../models/questionnaire.models";
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from 'clsx';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import { FormControlLabelChecboxQuestionaris } from './questionnaire.styles';

export const QuestionCheckComponent: React.FC<QuestionComponentProps> = (props: QuestionComponentProps) => {
    const {
        question,
        answer,
        handleChanges,
        error
    } = props;

    const [other, setOther] = useState('');

    const commonClasses = commonUseStyles();

    let checkAnswer: CheckAnswer;

    try {
        checkAnswer = JSON.parse(answer);
        if (!checkAnswer) {
            checkAnswer = emptyCheckAnswer();
        }
    } catch (e) {
        checkAnswer = emptyCheckAnswer();
    }

    const isChecked = (variant: string) => {
        return checkAnswer.v.includes(variant) || checkAnswer.o.includes(variant);
    }

    const isVariantChecked = (variant: string) => {
        return checkAnswer.v.includes(variant);
    }

    const isOtherChecked = (variant: string) => {
        return checkAnswer.o.includes(variant);
    }

    const wrapResult = () => {
        return JSON.stringify(checkAnswer);
    }

    const handleOtherChanges = (value: string) => {
        setOther(value);
    }

    const handleAddOther = (value: string) => {
        if (other === '') {
            return;
        }

        checkAnswer.o.push(value);
        setOther('');
        handleChanges(wrapResult());
    }

    const handleCheck = (variant: string) => {
        isVariantChecked(variant)
            ? checkAnswer.v.splice(checkAnswer.v.indexOf(variant), 1)
            : isOtherChecked(variant)
                ? checkAnswer.o.splice(checkAnswer.o.indexOf(variant), 1)
                : checkAnswer.v.push(variant)

        handleChanges(wrapResult())
    }

    return (
        <>
            <Box className={"check-question"}>
                <FormControl
                    fullWidth
                    error={!!error}
                    color='primary'
                    component="fieldset">
                    <FormHelperText>{error}</FormHelperText>
                    <FormGroup>
                        <Grid container>
                            {
                                [...question.variants, ...checkAnswer.o].map((variant, i) => {
                                    return <Grid item md={12} xs={12}>
                                        <FormControlLabelChecboxQuestionaris
                                            key={i}
                                            control={
                                                <Checkbox
                                                    className="check-box"
                                                    checked={isChecked(variant)}
                                                    size="small"
                                                    name={variant}
                                                    key={variant}
                                                    onChange={(v) => handleCheck(v.target.name)}
                                                />
                                            }
                                            label={variant}
                                        />
                                    </Grid>
                                })
                            }
                        </Grid>

                        {
                            question.allowOther &&
                            <Box mt={1}>
                                <Box>
                                    <span className={clsx(commonClasses.colorGray1, commonClasses.size14)}>Other</span>
                                </Box>
                                <Box>
                                    <FormControlLabel
                                        style={{ width: '100%', marginLeft: '0px' }}
                                        control={
                                            <Box display="flex" justifyContent="flex-start" width={1}>
                                                <TextField
                                                    fullWidth
                                                    value={other}
                                                    size="small"
                                                    InputProps={{ className: 'input' }}
                                                    placeholder="Type here…"
                                                    id={`otherInput-${question.name}`}
                                                    variant="outlined"
                                                    error={!!error}
                                                    helperText={error}
                                                    onChange={(v) => handleOtherChanges((v.target.value))}
                                                />
                                            </Box>
                                        }
                                        label=""
                                    />
                                </Box>
                                <Box mt={2}>
                                    <WildHealthButton id="add-other-button" color='secondary' onClick={(e) => handleAddOther(other)} disabled={!other}>
                                        Add
                                    </WildHealthButton>
                                </Box>
                            </Box>
                        }
                    </FormGroup>
                </FormControl>
            </Box>
        </>
    )
}