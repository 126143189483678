import React from "react";
import { Box, Button } from "@material-ui/core";
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { useFacade } from "./sessionRecordingSettingWidgetComponent.hooks";
import WildHealthLinearProgress from "../../../../common/components/WildHealthLinearProgress";
import { WildHealthButton } from "../../../../common/components/wildHealthButton/WildHealthButton";
import { colors } from "../../../../common/constants/colors";
import { WildHealthCheckbox } from '../../../../common/components/WildHealthCheckbox';


export const SessionRecordingSettingWidgetComponent: React.FC<any> = () => {

  const [
    {
      isLoading,
      edit,
      isSaveChanges,
      meetingRecordingConsent,
      meetingRecordingConsentDraft,
    },
    handleChanges,
    handleEditToggle,
    handleReset,
    handleSave,
  ] = useFacade();

  if (isLoading) {
    return <WildHealthLinearProgress />;
  }

  const renderEditContent = () => {
    return <>
      <Box width="100%">
        <Box pb={2} display="flex">
          <WildHealthCheckbox
            id="maximize-health-span-concern"
            fill={colors.main}
            defaultIcon
            label={<span className="">Enable Session Recording</span>}
            checkStatus={meetingRecordingConsentDraft}
            handleCheck={() => handleChanges()}
          />
        </Box>
        <Box display='flex'>
          <Box>
            <WildHealthButton
              id="save-changes-settings"
              width='140px'
              disabled={isSaveChanges}
              loading={isSaveChanges}
              onClick={() => handleSave()}
            >
              Save changes
            </WildHealthButton>
          </Box>
          <Box mx={2}>
            <WildHealthButton
              id="reset-changes-settings"
              color='secondary'
              disabled={isSaveChanges}
              width='140px'
              onClick={() => handleReset()}
            >
              Reset
            </WildHealthButton>
          </Box>
        </Box>
      </Box>
    </>
  }

  const renderContent = () => {
    return (
      <Box py={2} display="flex" alignItems="center" justifyContent="space-between">
        <Box className="wh-tw-text-gray1 wh-tw-text-sm wh-tw-min-w-[80px]">Session Recording:</Box>
        <Box className="wh-tw-text-sm wh-tw-text-linkBlack">{meetingRecordingConsent ? 'Enabled' : 'Disabled'}</Box>
      </Box>
    );

  };

  return (
    <Box>
      <Box display='flex' justifyContent="space-between" mb={2}>
        <Box className="wh-tw-text-black wh-tw-font-medium">
          Settings
        </Box>
        <Box>
          {!edit && <Button
            id='edit-setting-info'
            onClick={() => { handleEditToggle() }}
          >
            <BorderColorIcon fontSize='small' className="wh-tw-text-primaryV" />
            <Box ml="6px" className="wh-tw-text-primaryV wh-tw-text-sm wh-tw-capitalize">Edit</Box>
          </Button>}
        </Box>
      </Box>
      <Box>
        {edit ? renderEditContent() : renderContent()}
      </Box>
    </Box >
  )
};

