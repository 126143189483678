import Axios from 'axios-observable';
import { Observable } from "rxjs";
import { API_URL } from "../../../config";
import { OnboardingConfigurationModel } from "../models/onboardingConfiguration.model";
import { snackService } from '../../common/snack/state';
import { onboardingConfigurationStore, OnboardingConfigurationStore } from "../stores/onboardingConfigurationStore";

/**
 * Provides methods for working with onboarding configuration
 */
export class OnboardingService {

    constructor(private onboardingConfigurationStore: OnboardingConfigurationStore) {
    }

    public getConfiguration(practiceId: number): Observable<OnboardingConfigurationModel> {
        let url = `${API_URL}Onboarding/${practiceId}`;
        return new Observable<OnboardingConfigurationModel>(observer => {
            Axios.get<OnboardingConfigurationModel>(url)
                .pipe()
                .subscribe(
                    (response) => {
                        this.onboardingConfigurationStore.updateConfiguration(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }
}

export const onboardingService = new OnboardingService(onboardingConfigurationStore);
