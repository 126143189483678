import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles(() =>
    createStyles({
        root: {
            paddingTop: 20
        },
        viewModeSelect: {
            width: 150,
            '&:hover': {
                background: colors.white,
            },
            '&:focus': {
                background: colors.white
            }
        },
        calendar: {
            paddingTop: 20
        },
        currentDateCell: {
            background: colors.stroke
        },
        cell: {
            borderRight: `1px solid ${colors.stroke}`,
            borderLeft: `1px solid ${colors.stroke}`,
            borderTop: 'none',
            borderBottom: 'none',
            minHeight: 10,
            padding: 0,
            height: 10,
        },
        solidBorderCell: {
            borderTop: `1px solid ${colors.stroke}`
        },
        dashedBorderCell: {
            borderTop: `1px dashed ${colors.stroke}`
        },
        headerCell: {
            border: `1px solid ${colors.stroke}`,
            minHeight: 52.5,
            padding: 0,
            height: 52.5,
        },
        currentCell: {
            backgroundColor: 'rgba(31, 114, 162, 0.02)',
        },
        alignTop: {
            verticalAlign: 'top'
        },
        row: {
            minHeight: 10,
            height: 10
        },
        timeCell: {
            border: `1px solid ${colors.stroke}`,
            width: '8%',
        },
        weekNavigationButton: {
            height: 34,
            minHeight: 34,
            width: 32,
            minWidth: 32,
            borderRadius: 1,
            border: '1px solid',
            color: colors.black,
            backgroundColor: colors.white,
            borderColor: colors.stroke,

            '&:hover': {
                color: colors.main,
                backgroundColor: colors.lightGray,
                borderColor: colors.stroke,
            }
        },
        timeLine: {
            width: '100%',
            height: 2,
            position: 'relative',
            left: 0,
            borderTop: `2px solid ${colors.error}`
        },
        timeDot: {
            width: 10,
            height: 10,
            borderRadius: 5,
            background: colors.error,
            marginTop: -6,
            marginLeft: -6
        },
        timeLabel: {
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        datePicker: {
            width: '180px'
        }
    })
);