import React from 'react';
import {Box} from '@material-ui/core';
import {PageSliderComponent} from "../../../../common/components/PageSliderComponent";
import {QuestionnaireSurveyResultProps} from "./questionnaireSurveyResultProps";
import {InitialQuestionNames} from "../../../models/questionnaire.models";
import {QuestionnairesResultListComponent} from "../../questionnairesResultListComponent/QuestionnairesResultListComponent";

export const GeneralSurveyResult: React.FC<QuestionnaireSurveyResultProps> = (props: QuestionnaireSurveyResultProps) => {
    const {
        result,
        gender,
        questionnaire,
        navigationContext
    } = props;

    return (
        <>
            <Box pt={5}>
                <QuestionnairesResultListComponent
                    gender={gender}
                    questionnaire={questionnaire}
                    result={result}
                    questions={[
                        InitialQuestionNames.HEALTH_PRIORITY,
                        InitialQuestionNames.EXCITED_TO_LEARN
                    ]}
                    title="General Information:"
                />
            </Box>
            <Box pt={5}>
                <PageSliderComponent context={navigationContext}/>
            </Box>
        </>
    )
}
