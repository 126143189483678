import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderColor: colors.gray4,
            borderRadius: 2,
            '& label.Mui-focused': {
                color: colors.main,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: colors.main,
                },
                borderColor: colors.gray4,
                borderRadius: 2,
            },
        },
        dialogTitle: {
            paddingTop: '30px !important',
            paddingLeft: '40px !important',
            paddingRight: '40px !important'
        },
        dialogContent: {
            minWidth: 548,
            padding: "30px 40px",
            paddingTop: 16,
            [theme.breakpoints.down("xs")]: {
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: 0,
            },
        },
    })
);
