import React from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { AuthenticatedLayoutComponent } from "../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent";
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from "../../../../models/menu.models";
import { EmployeeConversationsComponent } from "../../components/employeeConversationsComponent/EmployeeConversationsComponent";
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import { isFeatureFlag } from "../../../common/components/featureFlags/featureFlags";
import { useFlags } from 'launchdarkly-react-client-sdk';

export const EmployeeConversationsPage: React.FC = (props: any) => {
    const conversationId = Number(props?.location?.state?.conversationId);
    const featureFlags = useFlags();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <AuthenticatedLayoutComponent
            hideBackground
            contentMargin={isSmallScreen ? '0px' : isFeatureFlag(featureFlags, FeatureFlag.Navigation) ? '30px 0 0 30px' : '30px 0 0 0'}
            displayChildren='flex'
            title={TitlesService.messagingTitle}
            pageName={PagesNamesService.messaging}
            selectedMenuItem={MenuItemTypes.Messaging}
        >
            <EmployeeConversationsComponent targetConversationId={conversationId} />
        </AuthenticatedLayoutComponent>
    )
};
