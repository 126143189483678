import { AppointmentLocationType, AppointmentLocationTypeNames } from '../../models/appointments.enums';
import { WildHealthRadio } from '../../../common/components/WildHealthRadio';
import { LocationModel } from '../../../locations/models/locations.models';
import commonUseStyles from '../../../common/styles/common.styles';
import { authQuery } from '../../../auth/stores/auth';
import { Box } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';

interface SelectLocationComponentProps {
    selectedLocationType: AppointmentLocationType;
    location: LocationModel;
    handleChangeLocationType: (type: AppointmentLocationType) => void;
    phoneNumber: string;
    disabled?: boolean;
}

export const SelectLocationComponent: React.FC<SelectLocationComponentProps> = (props: SelectLocationComponentProps) => {
    const {
        selectedLocationType,
        location,
        handleChangeLocationType,
        phoneNumber,
        disabled = false
    } = props;

    const commonClasses = commonUseStyles();
    const locationTypeKeys = Object.keys(AppointmentLocationType).filter(x => isNaN(+x));

    if (authQuery.isDefaultPractice()) {
        return (<></>);
    }

    return (
        <Box>
            <Box>
                <span className={clsx(commonClasses.size14, commonClasses.colorGray1)}>Set Location</span>
            </Box>
            <Box display="flex" justifyContent="flex-start" mt={1}>
                {
                    locationTypeKeys.map((key, i) => (
                        <Box key={i} display="flex" justifyContent="flex-start" alignItems="center" mr={2}>
                            <Box>
                                <WildHealthRadio
                                    disabled={disabled}
                                    checked={selectedLocationType === AppointmentLocationType[key]}
                                    onChange={() => handleChangeLocationType(AppointmentLocationType[key])}
                                    value={selectedLocationType}
                                    data-testid={selectedLocationType === AppointmentLocationType[key] ? `checked_${AppointmentLocationType[key]}` : `unchecked_${AppointmentLocationType[key]}`}
                                />
                            </Box>
                            <Box>
                                {AppointmentLocationTypeNames.get(AppointmentLocationType[key])}
                            </Box>
                        </Box>
                    ))
                }
            </Box>
            {
                selectedLocationType === AppointmentLocationType.InPerson &&
                <Box my={2}>
                    <Box>
                        <span className={clsx(commonClasses.size14, commonClasses.colorGray1)}>Practice Address</span>
                    </Box>
                    <Box mt={1}>
                        <span>{location.streetAddress1}</span>
                    </Box>
                </Box>
            }
            {
                selectedLocationType === AppointmentLocationType.Phone && phoneNumber &&
                <Box my={2}>
                    <Box>
                        <span className={clsx(commonClasses.size14, commonClasses.colorGray1)}>Phone Number of a Patient</span>
                    </Box>
                    <Box mt={1}>
                        <span>{phoneNumber}</span>
                    </Box>
                </Box>
            }
        </Box>
    );
}
