import React from 'react';
import { Box, IconButton, Button, FormControl, MenuItem, Select, Tooltip, TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { CloudUploadOutlined, Delete } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Dropzone from 'react-dropzone';
import clsx from 'clsx';
import { useStyles } from "./uploadDocumentsDialog.styles";
import { useFacade } from "./uploadDocumentsDialog.hooks";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { WildHealthCheckbox } from '../../../common/components/WildHealthCheckbox';
import commonUseStyles from '../../../common/styles/common.styles';
import { colors } from '../../../common/constants/colors';
import { documentCategoryNames } from '../../../documents/models/documents.models';
import { ProtectedElement } from '../../../common/components/ProtectedElement';

export const UploadDocumentsDialog: React.FC = () => {
    const [
        {
            isOpen,
            documentType,
            documentTypes,
            toSendAttachments,
            editIndex,
            patientId,
            viewableFileIndexs,
            isSendToKB
        },
        handleDataCategoriesSelect,
        handleSubmit,
        handleClose,
        handleDropAttachment,
        handleRemoveAttachment,
        handleEditAttachment,
        handleSaveAttachment,
        handleChanges,
        handleSetViewable,
        handleToogleSend
    ] = useFacade();
    const commonClasses = commonUseStyles();
    const classes = useStyles();

    const tooltipContent = '  File types that can be uploaded:\n' +
        'PDFs,\n' +
        'JPEGs,\n' +
        'PNGs,\n' +
        'CSV,\n' +
        'Google Docs,\n' +
        'Excel/Google Sheets,\n' +
        'Word Docs,\n' +
        'TXT Files,\n' +
        'Keynote files,\n' +
        'Number Files,\n' +
        'HTMLs'

    const fileTypes = [
        "application/pdf",
        'image/jpeg',
        'image/png',
        'text/csv',
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'text/html',
        'application/msword',
        'text/plain'
    ]

    const customTooltipClasses = {
        tooltip: classes.customWidth,
        arrow: classes.arrow,
        tooltipPlacementBottom: classes.tooltipPlacement,
        tooltipPlacementTop: classes.tooltipPlacement,
    };
    const accessIcon = (isViewable: boolean) => !isViewable ? <VisibilityOffIcon fontSize='small' /> : <VisibilityIcon className={commonClasses.colorMain} fontSize='small' />;

    const getFileSize = (size: number) => {
        if (size < 1024) {
            return size + ' bytes';
        } else if (size > 1024 && size < 1048576) {
            return (size / 1024).toFixed(1) + ' KB';
        } else if (size > 1048576) {
            return (size / 1048576).toFixed(1) + ' MB';
        }
    }

    const renderTitle = (item, index) => {

        return editIndex === index
            ?
            <TextField
                type='text'
                size="small"
                variant="outlined"
                value={item.name}
                InputProps={{
                    className: 'input',
                    endAdornment: (
                        <Tooltip title="Save" placement="top" arrow>
                            <IconButton
                                id={`input-${index}`}
                                size="small"
                                onClick={() => { handleSaveAttachment(index) }}
                            >
                                <EditIcon color="primary" fontSize='small' />
                            </IconButton>
                        </Tooltip>
                    ),
                }}
                onChange={(v) => handleChanges(index, v.target.value)}
            />
            : <Box key={index} className={classes.attachment} mb={1} px={1}>
                <Box mr={1} className={classes.attachmentTitle} ml={1}>{item.name}</Box>
                <Tooltip title="Edit" placement="top" arrow>
                    <IconButton
                        id={`input-${index}`}
                        size="small"
                        onClick={() => { handleEditAttachment(index) }}
                    >
                        <EditIcon color="primary" fontSize='small' />
                    </IconButton>
                </Tooltip>
            </Box>
    }

    return (
        <Dialog maxWidth="md" open={isOpen} onClose={() => handleClose()} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Upload New Document</DialogTitle>
            <DialogContent>
                <Box style={{ width: '700px' }}>
                    <Box>
                        <Box mb={1} className={clsx(commonClasses.size14, commonClasses.colorGray1)}>
                            Choose the best category location for your file
                        </Box>
                        <Box>
                            <FormControl color='primary' fullWidth size="medium" variant="outlined" className="drop-down">
                                <Select
                                    value={documentType ? documentType : 'Choose category'}
                                    renderValue={() => {
                                        if (!documentType) {
                                            return <Box className={commonClasses.colorMediumGray}>Choose category</Box>;
                                        }

                                        return documentCategoryNames[Number(documentType)]
                                    }}
                                    displayEmpty
                                    onChange={(v) => handleDataCategoriesSelect(v.target.value)}>
                                    {
                                        documentTypes.map((documentType) => {
                                            return <MenuItem
                                                key={documentType}
                                                value={documentType}>
                                                {documentCategoryNames[Number(documentType)]}
                                            </MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                    <Box display="flex" mt={3} mb={2}>
                        <Dropzone
                            onDrop={handleDropAttachment}
                            accept={fileTypes}
                        >
                            {
                                ({ getRootProps, getInputProps, open }) => {
                                    const rootProps = getRootProps();
                                    delete rootProps['onClick']

                                    return (
                                        <div {...rootProps} className={classes.dropContainer}>
                                            <CloudUploadOutlined className={classes.dropIcon} />
                                            <span className={classes.dropTitle}>Drag and Drop your file or </span>
                                            <span className={classes.dropTitle}>
                                                <input {...getInputProps()} />
                                                <Button disabled={!documentType} className={classes.label} component="label" onClick={open}>
                                                    Browse
                                                </Button>

                                            </span>
                                            <span className={classes.dropTitle}>
                                                <Tooltip title={documentType ? tooltipContent : 'Select category location first'} placement="top" arrow>
                                                    <InfoIcon style={{ fill: colors.gray1, width: '24px' }} />
                                                </Tooltip>
                                            </span>
                                        </div>)
                                }
                            }
                        </Dropzone>
                    </Box>
                    { 
                        toSendAttachments && toSendAttachments.length ? 
                            toSendAttachments.map((item, index) =>
                                <Box mt={1} display='flex' justifyContent='space-between'>
                                    <Box display='flex' alignItems='center' gridGap={5}>
                                        {renderTitle(item, index)}
                                        {patientId && (
                                            <Tooltip placement="top" arrow
                                                title={<p className={classes.notificationToolTipText}>{viewableFileIndexs.includes(index) ? 'This document is viewable for the patient. Click to change access.' : 'This document is not viewable for the patient. Click to change access.'}</p>}
                                                classes={customTooltipClasses}>
                                                <IconButton
                                                    id="set-viewable-by-patient"
                                                    onClick={() => handleSetViewable(index)}
                                                >
                                                    {accessIcon(viewableFileIndexs.includes(index))}
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </Box>

                                    <Box mt={1} display='flex'>
                                        <span className={commonClasses.colorGray2}>{getFileSize(item.file.size)}</span>
                                        <span className={classes.deleteIcon}><Delete onClick={() => handleRemoveAttachment(index)} /></span>
                                    </Box>
                                </Box>
                            )
                            : <></>
                    }
                    <ProtectedElement
                        element={<Box mt={1}>
                            <WildHealthCheckbox
                                id="send-to-kb-CheckBox"
                                label={'Upload to Generative AI Knowledge Base'}
                                fill={colors.lightMint}
                                checkStatus={isSendToKB}
                                handleCheck={(event) => handleToogleSend(event.target.checked)}
                            />
                        </Box>}
                        permissions={[]}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Box mb={4}>
                    <WildHealthButton
                        id="upload-inputs-cancel"
                        color="secondary"
                        onClick={() => handleClose()}
                    >
                        Cancel
                    </WildHealthButton>
                </Box>
                <Box pr={2} mb={4}>
                    <WildHealthButton
                        id="upload-inputs-upload"
                        onClick={() => handleSubmit()}
                        disabled={!toSendAttachments.length || Number.isInteger(editIndex) ||!documentType}
                    >
                        Upload
                    </WildHealthButton>
                </Box>
            </DialogActions>
        </Dialog>
    )
}