import { makeStyles } from "@material-ui/core";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme) => ({
  root: {
    borderColor: colors.mediumGray,
    borderRadius: 8,
    '& .MuiOutlinedInput-root': {
      borderColor: colors.mediumGray,
      borderRadius: 8,
    },
  },
  selectInput: {
    textOverflow: "ellipsis",
    overflow: "hidden"
  },
  responseSection: {
    fontSize: 12,
    margin: 0,
    padding: 10,
    whiteSpace: 'pre-wrap',
    wordBreak: "break-word",
    hyphens: "auto",
    borderRadius: 4,
    backgroundColor: colors.black,
    overflowWrap: "break-word",
    fontWeight: 600,
    color: colors.white
  },
  filters: {
    maxHeight: 'calc(100vh - 130px)',
    overflowY: 'auto',
    minWidth: 350,
    marginBottom: 16,
  },
  activeIcon: {
    color: colors.main,
  },
  divider: {
    borderTop: `2px solid ${colors.separators}`,
  },
  chip: {
    display: 'flex',
    gap: 6,
    alignItems: 'center',
    height: 27,
    margin: 2,
    lineHeight: '18px',
    backgroundColor: colors.separators,
    borderRadius: 2,
    padding: '4px 6px',
    overflow: 'hidden',
    fontSize: 16,
    fontWeight: 400,
    color: colors.black,
    '& svg': {
      fontSize: 18,
      cursor: 'pointer',
      fill: colors.gray1,
      margin: 0,
    },
    '& .MuiChip-label': {
      padding: 0,
    },
  },
  stateItem: {
    display: 'flex',
    gap: 6,
    alignItems: 'center',
    padding: '4px 6px',
    margin: 6,
    marginRight: 8,
    fontSize: 16,
    fontWeight: 400,
    color: colors.black,
    borderRadius: 2,
    backgroundColor: colors.separators,
  },
  removeIcon: {
    width: 18,
    height: 18,
    fill: colors.gray1,
    cursor: 'pointer'
  },
  addBtn: {
    border: 'none',
    backgroundColor: colors.white,
    color: colors.linkBlue,
    textAlign: 'start',
    fontSize: 14,
    fontWeight: 500,
    padding: 0,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  removeBtn: {
    border: 'none',
    backgroundColor: colors.white,
    color: colors.linkBlue,
    textAlign: 'start',
    fontSize: 14,
    fontWeight: 500,
    padding: 0,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto'
  }
}));
