import { Box, Container, Divider, Grid } from '@material-ui/core';
import React from 'react';
import { PageSliderComponent } from "../../../common/components/PageSliderComponent";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { PageSliderNavigationContext } from "../../../common/models/navigation.models";
import { BillingSummaryComponent } from "../billingSummaryComponent/BillingSummaryComponent";
import { ApplyPaymentCouponComponent } from '../paymentCouponComponent/ApplyPaymentCouponComponent';
import { PaymentCouponAppliedComponent } from '../paymentCouponComponent/PaymentCouponAppliedComponent';
import { PaymentSummaryComponent } from "../paymentSummaryComponent/PaymentSummaryComponent";
import { useFacade } from "./FinishBuySubscriptionComponent.hooks";
import { SelectAddOnsCardComponent } from "../../../addons/components/SelectAddOnsCardComponent/SelectAddOnsCardComponent";
import commonUseStyles from '../../../common/styles/common.styles';
import { getPlanForAutoSelect } from "../../helpers/getPlanForAutoSelect";
import { PaymentPlanModel } from '../../models/paymentPlan.models';

interface FinishBuySubscriptionComponentProps {
    navigationContext: PageSliderNavigationContext;
    plans: PaymentPlanModel[];
    specifiedPlan?: string;
    defaultPlan?: string;
}

export const FinishBuySubscriptionComponent: React.FC<FinishBuySubscriptionComponentProps> = (props: FinishBuySubscriptionComponentProps) => {
    const [
        {
            addOns,
            paymentPlan,
            paymentPeriod,
            paymentPrice,
            publicKey,
            couponCode,
            isCouponApplied,
            isPaymentPlanApplied
        },
        handlePriceSelect,
        renderPaymentForm,
        handleSelectingAddOn,
        getSelectedAddOns,
        handleCouponChanges,
        applyCoupon,
        applyPaymentPlan
    ] = useFacade();
    const commonClasses = commonUseStyles();

    if (!publicKey) {
        return <WildHealthLinearProgress/>
    }

    if(isCouponApplied && !isPaymentPlanApplied){
        applyPaymentPlan();
    }
    
    const {
        navigationContext,
        plans,
        specifiedPlan = null,
        defaultPlan = null
    } = props;

    const isPlanAutoSelect = Boolean(getPlanForAutoSelect(plans, specifiedPlan, defaultPlan));

    return (
        <>
            <Container maxWidth="lg" className="content">

                {!isPlanAutoSelect && <PageSliderComponent context={navigationContext}/>}

                <Box>
                    <Grid container
                          spacing={5}
                          justify="space-between">
                        <Grid item xs={12} md={5} lg={5}>
                            <Box mt={3}>
                                <Box className={commonClasses.primaryTitle}>Summary</Box>
                                <Box mt={3}>
                                    <PaymentSummaryComponent
                                        selectedPlan={paymentPlan}
                                        selectedPeriod={paymentPeriod}
                                        selectedPrice={paymentPrice}
                                        handlePriceSelect={handlePriceSelect}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={7} lg={7}>
                            {
                                !paymentPlan.isTrial &&
                                <Box mt={10}>
                                    <SelectAddOnsCardComponent items={addOns} handleSelectingItem={handleSelectingAddOn}/>
                                </Box>
                            }
                            <Box mt={3} className={commonClasses.primaryTitle}>
                                Promotional code
                            </Box>
                            <Box mt={3}>
                            {
                                !isCouponApplied &&
                                <ApplyPaymentCouponComponent
                                    handleCouponChanges={handleCouponChanges}
                                    applyCoupon={applyCoupon}
                                    couponCode={couponCode}
                                />
                            }
                            {
                                isCouponApplied &&
                                <PaymentCouponAppliedComponent paymentCoupon ={couponCode}/>
                            }
                            </Box>

                            <Divider className="divider"/>

                            <Box mt={3} className={commonClasses.primaryTitle}>
                                Billing Summary
                            </Box>
                            <Box mt={3}>
                                <BillingSummaryComponent
                                    selectedPlan={paymentPlan}
                                    selectedPeriod={paymentPeriod}
                                    selectedPrice={paymentPrice}
                                    selectedAddOns={getSelectedAddOns()}
                                    hasContainer
                                    hasDiscount
                                />
                            </Box>

                            <Divider className="divider"/>

                            <Box mt={3}>
                                {renderPaymentForm()}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </>
    )
}
