import {User} from "../../common/models/user.models";
import {PaymentPriceType} from "./paymentPrice.models";

export interface MerchantCredentialsModel {
    practiceId: number;
    publicKey: string;
    privateKey: string;
    provider: string;
}

export interface PaymentIntentModel {
    amount: number;
    currency: string;
}

export interface ApplyCouponModel {
    paymentPeriodId: number;
    code: string;
    paymentPriceType: PaymentPriceType;
}

export interface PayerInformation extends User{
    isShippingSameAsBilling: boolean;
    password: string;
    confirmPassword: string;
}

export enum IntegrationVendor {
    OpenPm = 1,
    Hint = 5,
    Stripe = 10,
    Twilio = 15,
    Elation = 20,
    None = 99,
}

export enum PaymentStatus {
    PendingCopay = 1,
    PendingInsurancePayment = 10,
    PendingOutstandingPayment = 20,
    Paid = 100,
    NotPayable = 999,
}

export const paymentStatusNames : { [el: number]: string; } = {
    [PaymentStatus.PendingCopay]: 'Pending',
    [PaymentStatus.PendingInsurancePayment]: 'Claim Processing',
    [PaymentStatus.PendingOutstandingPayment]: 'Claim Pending Patient Payment',
    [PaymentStatus.Paid]: 'Completed',
    [PaymentStatus.NotPayable]: 'Completed',
}
