import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    chat: {
      width: "100%",
      height: "100%",
      display: "grid",
      boxShadow: "0px 7px 60px rgba(168, 173, 198, 0.12)",
      background: colors.white,
      borderRadius: 2,

      gridTemplateAreas: '"messages side"',
      gridTemplateColumns: "calc(100% - 325px) 325px",
      [theme.breakpoints.between("sm", "md")]: {
        gridTemplateColumns: "100%",
        gridTemplateAreas: '"messages"',
      },
      [theme.breakpoints.down("xs")]: {
        gridTemplateAreas: `"messages"
                            "side"`,
        gridTemplateColumns: "100%",
        background: "transparent",
        padding: "0 16px",
      },
    },
    header: {
      height: 70,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      [theme.breakpoints.down("xs")]: {
        height: "100%",
        justifyContent: "center",
      },
    },
    titleAndAvatars: {
      display: "flex",
      gap: 16,
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        alignItems: "center",
        gap: 0,
      },
    },
    side: {
      gridArea: "side",
      height: "100%",
      display: "flex",
    },
    sideMaxHeight: {
      maxHeight: "calc(100vh - 226px)",
    },
    sideContainer: {
      height: "100%",
      width: "100%",
      overflow: "auto",
    },
    messages: {
      gridArea: "messages",
      display: "flex",
      flexDirection: "column",
      maxHeight: "calc(100vh - 226px) !important",
      minHeight: 565,
      [theme.breakpoints.down("xs")]: {
        minHeight: 0,
      },
    },
    messagesContent: {
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    thread: {
      flex: 1,
      minHeight: 200,
      height: "calc(100vh - 398px)",
      maxHeight: "calc(100vh - 398px)",
      overflow: "auto",
    },
    title: {
      display: "flex",
      alignItems: "center",
      color: colors.black,
      fontWeight: 500,
      fontSize: 20,
      [theme.breakpoints.down("xs")]: {
        fontSize: 18,
        paddingTop: 25,
      },
    },
    titlePaddingTop: {
      [theme.breakpoints.down("xs")]: {
        paddingTop: 10,
      },
    },
    divider: {
      backgroundColor: colors.stroke,
    },
    sendMessage: {
      flexShrink: 0,
    },
    sideMobile: {
      width: 325,
    },
    icon: {
      stroke: colors.main,
      height: 26,
      width: 26,
    },
    goBack: {
      [theme.breakpoints.down("xs")]: {
        background: colors.white,
        borderBottom: "1px solid",
        borderBottomColor: colors.stroke,
      },
    },
  })
);
