import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles(() =>
    createStyles({
        question: {
            color: colors.gray1,
            fontWeight: 500,
            fontSize: 16,
        },
        description: {
            color: colors.gray2,
            fontWeight: 400,
            fontSize: 16,
        },
        answer: {
            color: colors.black,
            fontWeight: 400,
            fontSize: 16,
        },
        withDots: {
            listStyleType: 'disc',
            marginLeft: 24,
        },
        subQuestion: {
            fontWeight: 400
        },
        subAnswer: {
            fontWeight: 500
        }
    })
);
