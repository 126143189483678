import { Store, StoreConfig } from '@datorama/akita';
import { BannerModel, HealthFormModel, QuestionnaireResultModel, QuestionnaireShortModel } from "../../models/questionnaire.models";

/**
 * Represents Questionnaire state
 */
export interface QuestionnaireState {
    healthForms: HealthFormModel[];
    availableQuestionnaires: QuestionnaireShortModel[];
    questionnaireResults: QuestionnaireResultModel[];
    questionnaireResultsByEmployee:  QuestionnaireResultModel[];
    targetResult: QuestionnaireResultModel;
    questionnaireBanner: BannerModel;
    notificationMedicalHistory: boolean;
    questionnaireResultsByAppointments: QuestionnaireResultModel[];
}

/**
 * Creates initial state
 */
export function createInitialState(): QuestionnaireState {
    return {
        healthForms: [],
        availableQuestionnaires: [],
        questionnaireResults: [],
        questionnaireResultsByEmployee: [],
        targetResult: null,
        questionnaireBanner: null,
        notificationMedicalHistory: false,
        questionnaireResultsByAppointments: []
    };
}

/**
 * Provides Health Questionnaire state management
 */
@StoreConfig({name: 'questionnaire', resettable: true })
export class QuestionnaireStore extends Store<QuestionnaireState> {
    constructor() {
        super(createInitialState());
    }

    public updateQuestionnaireResult(result: QuestionnaireResultModel) {
        const results = this.getValue().questionnaireResults;
        const targetResult = this.getValue().targetResult;

        this.update({
            questionnaireResults: results.map(x => x.id === result.id ? result : x),
            targetResult: targetResult && targetResult.id === result.id ? result : targetResult
        });
    }
}

export const questionnaireStore = new QuestionnaireStore();
