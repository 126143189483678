import {ScoreModel} from "../models/healthReport.models";
import {detailedRiskData, unavailableRiskData} from "../constants/healthReport.constants";
import {ScaleChartComponent} from "../components/scaleChartComponent/ScaleChartComponent";
import React from "react";

export const renderScaleChartComponent = (score: ScoreModel) => {
    const colorData = score.isCalculated
        ? detailedRiskData
        : unavailableRiskData;

    return <ScaleChartComponent data={colorData} result={score.result} />
}