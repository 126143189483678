import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import React from 'react';
import { Box } from '@material-ui/core';
import { TabItem, useFacade } from './wildHealthTabControl.hooks';
import { useStyles, WildHealthTabs } from './wildHealthTabControl.styles';

export interface WildHealthTabControlProps {
    items: TabItem[];
    className?: ClassNameMap<'tab' | 'selectedTab'>;
    initTab?: number;
    onChangeCB?: (tab: number) => void;
    otherContent?: JSX.Element;
}

export const WildHealthTabControl: React.FC<WildHealthTabControlProps> = (props: WildHealthTabControlProps) => {
    const {
        items,
        className,
        initTab = 0,
        onChangeCB,
        otherContent,
    } = props;

    const classes = className ?? useStyles();

    const [
        selectedTab,
        handleChange,
        tabs,
        tabPanels,
        menus,
    ] = useFacade(items, classes, initTab, onChangeCB);

    return (
        <div>
            <Box display="flex" alignItems="center">
                <WildHealthTabs
                    value={selectedTab}
                    onChange={handleChange}
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    {tabs}
                </WildHealthTabs>
                {otherContent}
            </Box>
            {tabPanels}
            {menus}
        </div>
    );
}
