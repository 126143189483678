import React from "react";
import {Box} from "@material-ui/core";
import './WildHealthAgreement.scss';

export const AgreementHeaderComponent: React.FC = () => {
    return (
        <Box className="header" textAlign="center">
            <Box>
                <span className="logo">wild health.</span>
            </Box>
            <Box className="contact-info">
                <Box>
                    <span>535 Wellington Way, Suite #330</span>
                </Box>
                <Box>
                    <span>Lexington, KY 40503</span>
                </Box>
                <Box>
                    <span>800 975-1562 </span>
                </Box>
            </Box>
        </Box>
    )
}