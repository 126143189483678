import { useState } from "react";
import { RecommendationTypes } from "../models/healthReport.models";


interface RecommendationsComponentState {
    sections: { [id: number]: boolean };
}

export function useFacade(
): [
        RecommendationsComponentState,
        (section: RecommendationTypes) => void,
    ] {
    const [state, setState] = useState(
        {
            sections: {
                [RecommendationTypes.Macronutrient]: false,
                [RecommendationTypes.Methylation]: false,
                [RecommendationTypes.VitaminsAndMicronutrients]: false,
                [RecommendationTypes.KryptoniteFoods]: false,
                [RecommendationTypes.SuperFoods]: false,
                [RecommendationTypes.ExerciseAndRecovery]: false,
                [RecommendationTypes.Longevity]: false,
                [RecommendationTypes.Microbiome]: false,
                [RecommendationTypes.Neurobehavioral]: false,
                [RecommendationTypes.Sleep]: false,
                [RecommendationTypes.Cardiovascular]: false,
                [RecommendationTypes.Dementia]: false,
                [RecommendationTypes.InsulinResistance]: false,
                [RecommendationTypes.Inflammation]: false,
                [RecommendationTypes.Supplements]: false,
            },
        } as RecommendationsComponentState
    );

    const handleExpandSection = (section: RecommendationTypes) => {
        const sections = state.sections;
        sections[section] = !sections[section];
        setState({ ...state, sections: sections });
    }
 
    return [
        state,
        handleExpandSection,
    ];
}
