import {ShortcutGroupTabs, shortcutsQuery} from "../../../stores/shortcutsStore";
import {CreateShortcutModel, ShortcutGroupModel} from "../../../models/shortcut.models";
import {shortcutsService} from "../../../services/shortcuts.service";
import {useEffect, useState} from "react";
import {onEmit} from "../../../../common/helpers/on-emit";
import {Subscription} from "rxjs";
import {IErrorState} from "../../../../common/validation/error-state";
import {createShortcutComponentValidator} from "./createShortcutComponent.validator";
import {DialogComponentProps} from "../../../../common/dialogs/dialogProps";

/**
 * Represents Create Shortcuts Component state
 */
interface CreateShortcutComponentState extends IErrorState {
    shortcut: CreateShortcutModel;
    groups: ShortcutGroupModel[];
    isSubmitted: boolean;
}

/**
 * Custom Hook to manage a view Model for Create shortcuts component
 */
export function useFacade(props: DialogComponentProps): [CreateShortcutComponentState, Function, Function, Function, () => boolean] {
    const [state, setState] = useState({
        shortcut: {
            name: null,
            displayName: null,
            groupId: null,
            content: null
        },
        groups: shortcutsQuery.getGroups(shortcutsQuery.getTab() === ShortcutGroupTabs.Personal),
        errors: {},
        isSubmitted: false
    } as CreateShortcutComponentState);

    const handleChanges = (key: string, value: string) => {
        state.shortcut[key] = value;
        setState({
            ...state,
            shortcut: Object.assign({}, state.shortcut)
        });

        createShortcutComponentValidator.validateAndSetState(state, setState, key, value);
    }

    const isCheckedCreate = () => {
        return Boolean(state.shortcut.name && state.shortcut.displayName && state.shortcut.groupId && state.shortcut.content);
    }

    const handleSubmit = (event: Event) => {
        event.preventDefault();
        
        if(!state.isSubmitted){
            createShortcutComponentValidator.validateObjectAndSetState(state, setState, state.shortcut);
            if (!createShortcutComponentValidator.stateIsValid(state)) {
                return;
            }
            setState(state => ({...state, isSubmitted: true }))
            shortcutsService.createShortcut(state.shortcut).subscribe(
                () => { handleClose() },
                () => { setInitialState() }
            );
        }
    }

    const handleClose = () => {       
        setInitialState();
        props.handleClose();
    }

    const setInitialState = () => {
        setState({
            ...state,
            errors: {},
            shortcut: {
                name: null,
                displayName: null,
                groupId: null,
                content: null
            },
            isSubmitted: false
        });
    }

    /**
     * Load all ongoing cares
     * Manage subscriptions with auto-cleanup
     */
    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<ShortcutGroupModel[]>(shortcutsQuery.groups$, groups => {
                setState(state => ({
                    ...state,
                    groups: groups.filter(x => x.isPersonal === (shortcutsQuery.getTab() === ShortcutGroupTabs.Personal))
                }));
            })
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [props.open]);

    return [state, handleChanges, handleSubmit, handleClose, isCheckedCreate];
}