import {
    HealthLogQuestionNames,
    InitialQuestionNames,
    QuestionnaireSection,
    QuestionType
} from '../../questionnaire/models/questionnaire.models';
import {Gender} from "../../common/models/user.models";

export class InitialConsultNames {
    static readonly TOP_3_HEALTH_PRIORITIES: string = 'TOP_3_HEALTH_PRIORITIES';
    static readonly CURRENT_MEDICAL_CONDITIONS: string = 'CURRENT_MEDICAL_CONDITIONS';
    static readonly HEALTH_FEARS: string = 'HEALTH_FEARS';
    static readonly SPECIFIC_DIET: string = 'SPECIFIC_DIET';

    static readonly MEAL_CONSUMING_TIME_1: string = 'MEAL_CONSUMING_TIME_1';
    static readonly MEAL_CONSUMING_TYPE_1: string = 'MEAL_CONSUMING_TYPE_1';
    static readonly MEAL_CONSUMING_TIME_2: string = 'MEAL_CONSUMING_TIME_2';
    static readonly MEAL_CONSUMING_TYPE_2: string = 'MEAL_CONSUMING_TYPE_2';
    static readonly MEAL_CONSUMING_TIME_3: string = 'MEAL_CONSUMING_TIME_3';
    static readonly MEAL_CONSUMING_TYPE_3: string = 'MEAL_CONSUMING_TYPE_3';
    static readonly LIST_OF_SNACKS: string = 'LIST_OF_SNACKS';
    static readonly STOP_CONSUMING_FOOD: string = 'STOP_CONSUMING_FOOD';

    static readonly EXERCISE_FREQUENCY: string = 'EXERCISE_FREQUENCY';
    static readonly AVAILABLE_EQUIPMENT: string = 'AVAILABLE_EQUIPMENT';
    static readonly EXERCISE_TYPES: string = 'EXERCISE_TYPES';

    static readonly ACTIVITIES_BEFORE_SLEEP: string = 'ACTIVITIES_BEFORE_SLEEP';
    static readonly FALL_ASLEEP_TIME: string = 'FALL_ASLEEP_TIME';
    static readonly WAKE_UP_TIME: string = 'WAKE_UP_TIME';
    static readonly USE_ALARM_CLOCK: string = 'USE_ALARM_CLOCK';
    static readonly FEEL_RESTED: string = 'FEEL_RESTED';
}

export interface NoteRecommendation {
    type: NoteRecommendationTypes;
    isSelected: boolean;
    name: string;
    content: string;
}

export enum NoteRecommendationTypes {
    Supplements,
    Nutrition,
    Exercise,
    SleepOrMindfulnessOrBreathwork
}

export const CheckManyToTextField = [
    InitialConsultNames.CURRENT_MEDICAL_CONDITIONS,
    InitialConsultNames.AVAILABLE_EQUIPMENT,
    InitialConsultNames.EXERCISE_TYPES,
]

export interface KeyMap {
    Key1: string;
    Key2: string;
}

export const InitialConsultToInitialQuestionNamesMap: KeyMap[] = [
    { Key1: InitialQuestionNames.HEALTH_PRIORITY, Key2: InitialConsultNames.TOP_3_HEALTH_PRIORITIES},
    { Key1: InitialQuestionNames.MEDICAL_CONDITIONS, Key2: InitialConsultNames.CURRENT_MEDICAL_CONDITIONS},
    { Key1: InitialQuestionNames.HEALTH_FEARS, Key2: InitialConsultNames.HEALTH_FEARS},
    { Key1: InitialQuestionNames.SPECIFIC_DIET, Key2: InitialConsultNames.SPECIFIC_DIET},
    { Key1: InitialQuestionNames.EXERCISE_PROGRAM_FOR, Key2: InitialConsultNames.AVAILABLE_EQUIPMENT},
    { Key1: InitialQuestionNames.EXERCISE_TYPE, Key2: InitialConsultNames.EXERCISE_TYPES },
    { Key1: InitialQuestionNames.STOP_CONSUMING_FOOD, Key2: InitialConsultNames.STOP_CONSUMING_FOOD },
];

export const InitialConsultToHealthLogQuestionNamesMap: KeyMap[] = [
    { Key1: HealthLogQuestionNames.FIRST_MEAL, Key2: InitialConsultNames.MEAL_CONSUMING_TYPE_1 },
    { Key1: HealthLogQuestionNames.FIRST_MEAL_TIME, Key2: InitialConsultNames.MEAL_CONSUMING_TIME_1 },
    { Key1: HealthLogQuestionNames.SECOND_MEAL, Key2: InitialConsultNames.MEAL_CONSUMING_TYPE_2 },
    { Key1: HealthLogQuestionNames.SECOND_MEAL_TIME, Key2: InitialConsultNames.MEAL_CONSUMING_TIME_2 },
    { Key1: HealthLogQuestionNames.LAST_MEAL, Key2: InitialConsultNames.MEAL_CONSUMING_TYPE_3 },
    { Key1: HealthLogQuestionNames.LAST_MEAL_TIME, Key2: InitialConsultNames.MEAL_CONSUMING_TIME_3 },
    { Key1: HealthLogQuestionNames.LIST_OF_SNACKS, Key2: InitialConsultNames.LIST_OF_SNACKS },
    { Key1: HealthLogQuestionNames.WEEKS_EXERCISE, Key2: InitialConsultNames.EXERCISE_FREQUENCY },
    { Key1: HealthLogQuestionNames.ACTIVITIES_LIST, Key2: InitialConsultNames.ACTIVITIES_BEFORE_SLEEP },
    { Key1: HealthLogQuestionNames.FALL_ASLEEP_TIME, Key2: InitialConsultNames.FALL_ASLEEP_TIME },
    { Key1: HealthLogQuestionNames.WAKE_UP_TIME, Key2: InitialConsultNames.WAKE_UP_TIME },
    { Key1: HealthLogQuestionNames.WAKE_UP_WITH_ALARM, Key2: InitialConsultNames.USE_ALARM_CLOCK },
    { Key1: HealthLogQuestionNames.FEEL_IN_THE_MORNING, Key2: InitialConsultNames.FEEL_RESTED },
];

export function getInitQuestionnaires(): QuestionnaireSection[] {
    return [
        {
            name: 'General',
            questions: [
                {
                    name: InitialConsultNames.TOP_3_HEALTH_PRIORITIES,
                    questionSubject: '',
                    displayName: 'What are your top 3 health priorities?',
                    description: '',
                    type: QuestionType.CheckMany,
                    allowOther: true,
                    variants: [
                        'Health Optimization',
                        'Longevity and Healthy Aging' ,
                        'Disease Treatment and Prevention',
                        'Mental Cognition and Brain Health',
                        'Sport and Athletic Performance',
                        'Diet and Nutrition Optimization',
                        'Improving Sleep and Proper Recovery',
                        'Specific Medical Concern',
                    ],
                    default: '',
                    childrenNames: [],
                    parentAnswers: [],
                    gender: Gender.None
                },
                {
                    name: InitialConsultNames.CURRENT_MEDICAL_CONDITIONS,
                    questionSubject: '',
                    displayName: 'Do you have any current medical conditions?',
                    description: '',
                    type: QuestionType.TextInput,
                    default: '',
                    childrenNames: [],
                    parentAnswers: [],
                    gender: Gender.None
                },
                {
                    name: InitialConsultNames.HEALTH_FEARS,
                    questionSubject: '',
                    displayName: 'Do you have any fears or specific concerns about your health?',
                    description: '',
                    type: QuestionType.CheckMany,
                    allowOther: true,
                    variants: [
                        'Heart Health',
                        'Brain Health',
                        'Cancer',
                        'No',
                    ],
                    default: '',
                    childrenNames: [],
                    parentAnswers: [],
                    gender: Gender.None
                },
                {
                    name: InitialConsultNames.SPECIFIC_DIET,
                    questionSubject: '',
                    displayName: 'Are you on a specific kind of diet?',
                    description: '',
                    type: QuestionType.SelectOne,
                    variants: [
                        'None',
                        'No Preference',
                        'Vegan',
                        'Vegetarian',
                        'Pescatarian',
                        'Mediterranean',
                        'Whole Food Plant Based',
                        'Gluten Free',
                        'Paleo',
                        'Keto',
                    ],
                    default: 'None',
                    childrenNames: [],
                    parentAnswers: [],
                    gender: Gender.None
                },
            ]
        },
        {
            name: 'Nutrition',
            questions: [
                {
                    name: InitialConsultNames.MEAL_CONSUMING_TIME_1,
                    questionSubject: '',
                    displayName: 'When did you eat your first meal?',
                    description: '',
                    type: QuestionType.TimeInput,
                    default: '',
                    childrenNames: [],
                    parentAnswers: [],
                    gender: Gender.None
                },
                {
                    name: InitialConsultNames.MEAL_CONSUMING_TYPE_1,
                    questionSubject: '',
                    displayName: 'What did you eat?',
                    description: '',
                    type: QuestionType.TextInput,
                    default: '',
                    childrenNames: [],
                    parentAnswers: [],
                    gender: Gender.None
                },
                {
                    name: InitialConsultNames.MEAL_CONSUMING_TIME_2,
                    questionSubject: '',
                    displayName: 'When did you eat your next meal?',
                    description: '',
                    type: QuestionType.TimeInput,
                    default: '',
                    childrenNames: [],
                    parentAnswers: [],
                    gender: Gender.None
                },
                {
                    name: InitialConsultNames.MEAL_CONSUMING_TYPE_2,
                    questionSubject: '',
                    displayName: 'What did you eat?',
                    description: '',
                    type: QuestionType.TextInput,
                    default: '',
                    childrenNames: [],
                    parentAnswers: [],
                    gender: Gender.None
                },
                {
                    name: InitialConsultNames.MEAL_CONSUMING_TIME_3,
                    questionSubject: '',
                    displayName: 'When did you eat your next meal?',
                    description: '',
                    type: QuestionType.TimeInput,
                    default: '',
                    childrenNames: [],
                    parentAnswers: [],
                    gender: Gender.None
                },
                {
                    name: InitialConsultNames.MEAL_CONSUMING_TYPE_3,
                    questionSubject: '',
                    displayName: 'What did you eat?',
                    description: '',
                    type: QuestionType.TextInput,
                    default:'',
                    childrenNames: [],
                    parentAnswers: [],
                    gender: Gender.None
                },
                {
                    name: InitialConsultNames.LIST_OF_SNACKS,
                    questionSubject: '',
                    displayName: 'List any snacks you typically have throughout the day:',
                    description: '',
                    type: QuestionType.TextInput,
                    default: '',
                    childrenNames: [],
                    parentAnswers: [],
                    gender: Gender.None
                },
                {
                    name: InitialConsultNames.STOP_CONSUMING_FOOD,
                    questionSubject: '',
                    displayName: 'What time do you usually stop consuming any food for the day?',
                    description: '',
                    type: QuestionType.TimeInput,
                    default: '',
                    childrenNames: [],
                    parentAnswers: [],
                    gender: Gender.None
                },
            ]
        },
        {
            name: 'Exercise',
            questions: [
                {
                    name: InitialConsultNames.EXERCISE_FREQUENCY,
                    questionSubject: '',
                    displayName: 'On average, how many days per week are you sticking to your current exercise plan?',
                    description: '',
                    variants: ['0', '1', '2', '3', '4', '5', '6', '7'],
                    type: QuestionType.SelectOne,
                    default: '0',
                    childrenNames: [],
                    parentAnswers: [],
                    gender: Gender.None
                },
                {
                    name: InitialConsultNames.AVAILABLE_EQUIPMENT,
                    questionSubject: '',
                    displayName: 'What type of equipment do you have available?',
                    description: '',
                    type: QuestionType.TextInput,
                    default: '',
                    childrenNames: [],
                    parentAnswers: [],
                    gender: Gender.None
                },
                {
                    name: InitialConsultNames.EXERCISE_TYPES,
                    questionSubject: '',
                    displayName: 'What type of exercises are you familiar with?',
                    description: '',
                    type: QuestionType.TextInput,
                    default: '',
                    childrenNames: [],
                    parentAnswers: [],
                    gender: Gender.None
                },
            ]
        },
        {
            name: 'Sleep',
            questions: [
                {
                    name: InitialConsultNames.ACTIVITIES_BEFORE_SLEEP,
                    questionSubject: '',
                    displayName: 'List the activities you perform during the last hour before falling asleep for the night.',
                    description: '',
                    type: QuestionType.TextInput,
                    default: '',
                    childrenNames: [],
                    parentAnswers: [],
                    gender: Gender.None
                },
                {
                    name: InitialConsultNames.FALL_ASLEEP_TIME,
                    questionSubject: '',
                    displayName: 'What time are you typically in bed trying to fall asleep for the night?',
                    description: '',
                    type: QuestionType.TimeInput,
                    default: '',
                    childrenNames: [],
                    parentAnswers: [],
                    gender: Gender.None
                },
                {
                    name: InitialConsultNames.WAKE_UP_TIME,
                    questionSubject: '',
                    displayName: 'What time do you usually wake up?',
                    description: '',
                    type: QuestionType.TimeInput,
                    default: '',
                    childrenNames: [],
                    parentAnswers: [],
                    gender: Gender.None
                },
                {
                    name: InitialConsultNames.USE_ALARM_CLOCK,
                    questionSubject: '',
                    displayName: 'Do you typically wake up with an alarm clock or not?',
                    description: '',
                    variants: ['Yes', 'No'],
                    type: QuestionType.SelectOne,
                    default: '',
                    childrenNames: [],
                    parentAnswers: [],
                    gender: Gender.None
                },
                {
                    name: InitialConsultNames.FEEL_RESTED,
                    questionSubject: '',
                    displayName: 'On a scale of 0-10, how rested/ready to take on the day do you feel when you wake up?',
                    description: '',
                    type: QuestionType.SelectOne,
                    default: '0',
                    variants: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
                    childrenNames: [],
                    parentAnswers: [],
                    gender: Gender.None
                }
            ]
        },
    ];
}

export const initRecommendations: NoteRecommendation[] = [
    {
        type: NoteRecommendationTypes.Supplements,
        isSelected: false,
        name: 'Supplements',
        content: "We could guess on the best supplements/diet/etc., but then we would be practicing just like the broken medical system based on statistics and epidemiology.  We don't want to tell you what is best for most people.  We want to tell you what is best for YOU.  We'll be able to do that as soon as we have your DNA and labs back and are able to put them in our machine learning based algorithms.",
    },
    {
        type: NoteRecommendationTypes.Nutrition,
        isSelected: false,
        name: 'Nutrition',
        content: "A great place to start is Michael Pollan's recommendation in The Omnivore's Dilemma:  Eat Food, mostly plants, not too much.  And you can probably take out the 'mostly plants' part until we have your DNA/labs.  For now, eat food, not too much.  And we should define food.  If your great-great grandmother wouldn't have recognized it as food 100 years ago, then it's not food. \n\nThere are important genetic and lab markers that determine how sensitive you are to things like saturated fat and how well you absorb vitamins and minerals. Some individuals tolerate a better high fat diet while others are predisposed to better health with a higher carbohydrate diet. Our genetic algorithm uses polygenic risk scores to help us design a diet plan just for you! Once we have your DNA/labs back then we're going to be able to get very specific with you about exact foods and categories of food.  We'll even identify superfoods and kryptonite foods for you.  We'll also talk about timing and when you should eat based on your DNA/Labs/Lifestyle.\n\nFor now, choose healthy snack replacements. Examples include raw veggies/hummus, raw almonds, low glycemic fruit (berries).",
    },
    {
        type: NoteRecommendationTypes.Exercise,
        isSelected: false,
        name: 'Exercise',
        content: "Exercise is very important for our physical and psychological well-being. Moving frequently throughout the day is just as important as intense exercise. By evaluating your genetic profile we are able to use polygenic scores to determine whether you will respond better to strength or endurance.  We can also evaluate your rate of recovery and personalized nutrients that may aid in recovery.  If you're not exercising at all right now, then start by walking.  We'll get much more specific with you once we have your data, but for now just move often, track your exercise, and pay attention to what hurts and what feels good so we can discuss and create a truly personalized plan.",
    },
    {
        type: NoteRecommendationTypes.SleepOrMindfulnessOrBreathwork,
        isSelected: false,
        name: 'Sleep/Mindfulness/Breathwork',
        content: "Book recommendation: Why We Sleep by Matthew Walker [LINK](https://www.amazon.com/Why-We-Sleep-Unlocking-Dreams/dp/1501144324/ref=asc_df_1501144324/?tag=hyprod-20&linkCode=df0&hvadid=312049124368&hvpos=&hvnetw=g&hvrand=15779005785335573933&hvpone=&hvptwo=&hvqmt=&hvdev=c&hvdvcmdl=&hvlocint=&hvlocphy=9014313&hvtargid=pla-542231888375&psc=1&tag=&ref=&adgrpid=61851652213&hvpone=&hvptwo=&hvadid=312049124368&hvpos=&hvnetw=g&hvrand=15779005785335573933&hvqmt=&hvdev=c&hvdvcmdl=&hvlocint=&hvlocphy=9014313&hvtargid=pla-542231888375).\n\nDevice: Oura ring, we can use the Oura cloud service to remotely track your sleep. Using N of 1 experiments we are able to make improvements in your sleep quality.\n\nMeditation Apps: Calm, Headspace.\n\nBreath Work Apps: Oak, Breathe.\n\nBreath work practice: 4-7-8 breathing 4 rounds 3 times per day.",
    },
];