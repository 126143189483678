import React from "react";
import { AuthenticatedLayoutComponent } from "../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent";
import { PagesNamesService } from "../../../../constants/pagesNames.constants";
import { TitlesService } from "../../../../constants/title.constants";
import { MenuItemTypes } from "../../../../models/menu.models";
import { PatientMicrobiomeInputsComponent } from "../../components/patientMicrobiomeInputsComponent/PatientMicrobiomeInputsComponent";

export const PatientMicrobiomePage: React.FC = () => {
    return (
        <AuthenticatedLayoutComponent
            title={TitlesService.microbiomeInputsTitle}
            pageName={PagesNamesService.results}
            selectedMenuItem={MenuItemTypes.MicrobiomeInputs}
        >
            <PatientMicrobiomeInputsComponent/>
        </AuthenticatedLayoutComponent>
    )
}
