import { Box, CircularProgress, IconButton, Popover, Table, TableBody, TableContainer, TableHead, TableRow, Typography, Tooltip } from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import React from "react";
import { WildHealthMenuItem } from "../../../common/components/wildHealthMenu/WildHealthMenu";
import { CommonPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/CommonPlaceHolder";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { useFacade } from "./uploadDocumentsFilesComponent.hooks";
import { useStyles } from "./uploadDocumentsFilesComponent.styles";
import moment from 'moment';
import commonUseStyles from "../../../common/styles/common.styles";
import { toCurrentTimeZone } from '../../../timezones/helpers/timezone';
import { DocumentCategoryModel } from "../../models/documents.models";
import { InputsComponentProps } from "../../../inputs/components/inputsComponentProps";

interface UploadInputsFilesComponentProps extends InputsComponentProps {
    file: DocumentCategoryModel;
}

export const UploadDocumentsFilesComponent: React.FC<UploadInputsFilesComponentProps> = (props: UploadInputsFilesComponentProps) => {
    const { patientId, file } = props;

    const [
        {
            isDownloading,
            isUploading,
            isActionsOpen,
            actionsAnchorEl,
            selectedFile,
            newDocumentsExist,
        },
        handleToggleActions,
        handleTransfer,
        handleDownload,
        handleDelete,
        handleSetViewable
    ] = useFacade(patientId, file);

    const commonClasses = commonUseStyles();
    const classes = useStyles();

    const customTooltipClasses = {
        tooltip: classes.customWidth,
        arrow: classes.arrow,
        tooltipPlacementBottom: classes.tooltipPlacement,
        tooltipPlacementTop: classes.tooltipPlacement,
    };

    const getAction = (id: number) => patientId ? (event: React.MouseEvent<HTMLButtonElement>) => handleToggleActions(id, event.currentTarget) : () => handleDownload(id);
    const actionsIcon = patientId ? <MoreHorizIcon /> : <GetAppIcon color="primary" fontSize='small' />;
    const accessIcon = (isViewable: boolean) => !isViewable ? <VisibilityOffIcon fontSize='small' /> : <VisibilityIcon className={commonClasses.colorMain} fontSize='small' />;

    const renderTable = () => {
        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <WildHealthTableCell align="left">
                                File Name
                            </WildHealthTableCell>
                            <WildHealthTableCell align="left">
                                Date
                            </WildHealthTableCell>
                            {patientId && (
                                <WildHealthTableCell align="left">
                                    Patient Access
                                </WildHealthTableCell>
                            )}
                            <WildHealthTableCell style={{ width: 120 }} align="left">
                                Action
                            </WildHealthTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            file.documents.map((item, index) =>
                                <WildHealthTableRow key={index} className={patientId && !!newDocumentsExist.length && !item.isViewed && commonClasses.backgroundColorLightGray}>
                                    <WildHealthTableCell align="left">
                                        <Box id="view-documents-files" className={commonClasses.pointer} onClick={() => handleDownload(item.id)}>{decodeURIComponent(item.name)}</Box>
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="left">
                                        {moment(toCurrentTimeZone(new Date(item.dateUploaded))).format("MM/DD/yyyy")}
                                    </WildHealthTableCell>
                                    {patientId && (
                                        <WildHealthTableCell align="left">
                                            <Tooltip placement="top" arrow
                                                title={<p className={classes.notificationToolTipText}>{item.isViewableByPatient ? 'This document is viewable for the patient. Click to change access.' : 'This document is not viewable for the patient. Click to change access.'}</p>}
                                                classes={customTooltipClasses}>
                                                <IconButton
                                                    id="set-viewable-by-patient"
                                                    disabled={isDownloading}
                                                    onClick={() => handleSetViewable(item.id, !item.isViewableByPatient)}
                                                >
                                                    {isDownloading && item.id === selectedFile.id ? <CircularProgress color="primary" size={24} className={commonClasses.colorMain} /> : accessIcon(item.isViewableByPatient)}
                                                </IconButton>
                                            </Tooltip>
                                        </WildHealthTableCell>
                                    )}
                                    <WildHealthTableCell align="left">
                                        <IconButton
                                            id="upload-documents-files"
                                            disabled={isDownloading}
                                            onClick={getAction(item.id)}
                                        >
                                            {isDownloading && item.id === selectedFile.id ? <CircularProgress color="primary" size={24} className={commonClasses.colorMain} /> : actionsIcon}
                                        </IconButton>
                                    </WildHealthTableCell>
                                </WildHealthTableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    return (
        <Box p={4}>
            <Box display='flex' justifyContent='space-between'>
                <Box fontSize={18} fontWeight={500}>
                    {file.name}
                </Box>
            </Box>

            <Box mt={3}>
                {
                    file && file.documents.length ? renderTable() :
                        <CommonPlaceHolder message={`No documents yet.`} />
                }
            </Box>

            {
                patientId &&
                <Popover
                    id="upload-documents-files-actions"
                    keepMounted
                    anchorEl={actionsAnchorEl}
                    open={isActionsOpen}
                    onClose={() => handleToggleActions()}
                >
                    <WildHealthMenuItem
                        id="upload-documents-files-transfer"
                        onClick={() => handleTransfer(selectedFile)}
                        disabled={!selectedFile || isUploading}
                    >
                        <Typography variant="inherit" noWrap>
                            Transfer
                        </Typography>
                    </WildHealthMenuItem>
                    <WildHealthMenuItem
                        id="upload-documents-files-download"
                        onClick={() => handleDownload(selectedFile.id)}
                        disabled={!selectedFile || isUploading || isDownloading}
                    >
                        <Typography variant="inherit" noWrap>
                            Download
                        </Typography>
                    </WildHealthMenuItem>
                    <WildHealthMenuItem
                        id="upload-documents-files-delete"
                        onClick={() => handleDelete(selectedFile.id)}
                        disabled={!selectedFile || isUploading}
                    >
                        <Typography variant="inherit" noWrap>
                            Delete
                        </Typography>
                    </WildHealthMenuItem>
                </Popover>
            }
        </Box>
    )
};