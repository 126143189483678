import { Box } from '@material-ui/core';
import React from 'react';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import {
    HealthLogQuestionNames,
    QuestionnaireModel,
    QuestionnaireResultModel
} from '../../models/questionnaire.models';
import { QuestionnairesListComponent } from '../questionnairesListComponent/QuestionnairesListComponent';
import { useFacade } from "./healthLogQuestionnaireSurveyComponent.hooks";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";

export interface HealthLogQuestionnaireSurveyComponentProps {
    questionnaire: QuestionnaireModel;
    result: QuestionnaireResultModel;
    patientId?: number;
    handleGoBack?: Function;
}

export const HealthLogQuestionnaireSurveyComponent: React.FC<HealthLogQuestionnaireSurveyComponentProps> = (props: HealthLogQuestionnaireSurveyComponentProps) => {
    const [
        {
            isLoading,
            questionnaire,
            result,
            errors,
        },
        handleChanges,
        handleSubmit
    ] = useFacade(props.result, props.questionnaire, props.patientId, props.handleGoBack);

    return isLoading
        ? <WildHealthLinearProgress />
        : <Box p={5}>
            <Box>
                <span>
                    Please complete based on what a typical day looked like for you this week.
                </span>
            </Box>
            <Box mt={5}>
                <QuestionnairesListComponent
                    questionnaire={questionnaire}
                    errors={errors}
                    handleChanges={handleChanges}
                    questions={[
                        HealthLogQuestionNames.FIRST_MEAL_TIME,
                        HealthLogQuestionNames.FIRST_MEAL,
                        HealthLogQuestionNames.SECOND_MEAL_TIME,
                        HealthLogQuestionNames.SECOND_MEAL,
                        HealthLogQuestionNames.LAST_MEAL_TIME,
                        HealthLogQuestionNames.LAST_MEAL,
                        HealthLogQuestionNames.LIST_OF_SNACKS,
                        HealthLogQuestionNames.REGULAR_PRACTICE,
                    ]}
                    result={result}
                    title='NUTRITION:'
                />
            </Box>
            <Box pt={5}>
                <QuestionnairesListComponent
                    questionnaire={questionnaire}
                    errors={errors}
                    handleChanges={handleChanges}
                    questions={[
                        HealthLogQuestionNames.WEEKS_EXERCISE,
                        HealthLogQuestionNames.TYPES_OF_EXERCISE,
                    ]}
                    result={result}
                    title="EXERCISE:"
                />
            </Box>
            <Box pt={5}>
                <QuestionnairesListComponent
                    questionnaire={questionnaire}
                    errors={errors}
                    handleChanges={handleChanges}
                    questions={[
                        HealthLogQuestionNames.ACTIVITIES_LIST,
                        HealthLogQuestionNames.CONSUME_BEFORE_NIGHT,
                        HealthLogQuestionNames.FALL_ASLEEP_TIME,
                        HealthLogQuestionNames.WAKE_UP_TIME,
                        HealthLogQuestionNames.WAKE_UP_WITH_ALARM,
                        HealthLogQuestionNames.FEEL_IN_THE_MORNING,
                        HealthLogQuestionNames.ENERGY_THROUGHOUT_THE_DAY,
                    ]}
                    result={result}
                    title="SLEEP:"
                />
            </Box>
            <Box pt={5}>
                <QuestionnairesListComponent
                    questionnaire={questionnaire}
                    errors={errors}
                    handleChanges={handleChanges}
                    questions={[
                        HealthLogQuestionNames.PRACTICES_A_WEEK,
                    ]}
                    result={result}
                    title="MINDFULNESS:"
                />
            </Box>

            <Box pt={5} display="flex" justifyContent="flex-end">
                <Box>
                    <WildHealthButton id="health-log-survey-submit" onClick={() => handleSubmit()}>
                        Submit
                    </WildHealthButton>
                </Box>

            </Box>
        </Box>
}
