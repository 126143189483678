import React from 'react';
import { ReactComponent as Shortcuts } from '@img/icons/Shortcuts.svg';
import { IconProps } from './IconProps';
import { colors } from '../../modules/common/constants/colors';

const ShortcutsIcon: React.FC<IconProps> = (props: IconProps) => {
    const { isSelected } = props;

    return (
        <Shortcuts fill={isSelected ? colors.main : colors.gray2} />
    )
}

export default ShortcutsIcon;