import React, { useState } from "react";
import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { PatientConversationModel } from "../../models/conversation.models";
import { useStyles } from "./staffConversationsHistoryComponent.styles";
import clsx from "clsx";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { CollapseHistoryComponent } from "./components/CollapseHistoryComponent";
import { MobileCollapseComponent } from "./components/MobileCollapseHistoryComponent";

interface StaffConversationsHistoryComponentProps {
  conversations: PatientConversationModel[];
  targetConversation?: PatientConversationModel;
  height?: string;
  handleSelect: (PatientConversationModel) => void;
}

interface StaffChatComponentState {
  activeTicketsIsOpen: boolean;
  closedTicketsIsOpen: boolean;
}

export const StaffConversationsHistoryComponent: React.FC<
  StaffConversationsHistoryComponentProps
> = (props: StaffConversationsHistoryComponentProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const { conversations, targetConversation, height, handleSelect } = props;

  const [state, setState] = useState({
      activeTicketsIsOpen: true,
      closedTicketsIsOpen: true,
  } as StaffChatComponentState);
  
  const handleToggleActiveTickets = () => {
    setState(state => ({ ...state, activeTicketsIsOpen: !state.activeTicketsIsOpen }))
  }
  
  const handleToggleClosedTickets = () => {
    setState(state => ({ ...state, closedTicketsIsOpen: !state.closedTicketsIsOpen }))
  }

  return (
    <Box className={clsx(classes.root, "flat-scroll")} height={height} maxHeight={height} pb={isSmallScreen && 2}>
      <Box display="flex" alignItems="center" className={classes.historyButton} onClick={() => handleToggleActiveTickets()}>
        <Box className={classes.historyTitle}>Open Tickets</Box>
        {state.activeTicketsIsOpen ? <ExpandLess /> : <ExpandMore />}
      </Box>
      <Box mb={2}>
        {isSmallScreen ? (
          <MobileCollapseComponent
            conversations={conversations}
            targetConversation={targetConversation}
            handleSelect={handleSelect}
            isOpenCollapse={state.activeTicketsIsOpen}
            isClosed={false}
          />
        ) : (
          <CollapseHistoryComponent
            conversations={conversations}
            targetConversation={targetConversation}
            handleSelect={handleSelect}
            isOpenCollapse={state.activeTicketsIsOpen}
            isClosed={false}
          />
        )}
      </Box>
      <Box display="flex" alignItems="center" className={classes.historyButton} onClick={() => handleToggleClosedTickets()}>
        <Box className={classes.historyTitle}>Message History</Box>
        {state.closedTicketsIsOpen ? <ExpandLess /> : <ExpandMore />}
      </Box>
      <Box>
        {isSmallScreen ? (
          <MobileCollapseComponent
            conversations={conversations}
            targetConversation={targetConversation}
            handleSelect={handleSelect}
            isOpenCollapse={state.closedTicketsIsOpen}
            isClosed={true}
          />
        ) : (
          <CollapseHistoryComponent
            conversations={conversations}
            targetConversation={targetConversation}
            handleSelect={handleSelect}
            isOpenCollapse={state.closedTicketsIsOpen}
            isClosed={true}
          />
        )}
      </Box>
    </Box>
  );
};
