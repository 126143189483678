import React from 'react';
import {Box} from '@material-ui/core';
import "./ColorDescriptionComponent.scss";

interface ColorDescriptionComponentProps {
    color: string;
    description: string;
}

export const ColorDescriptionComponent: React.FC<ColorDescriptionComponentProps> = (props: ColorDescriptionComponentProps) => {
    const {
        color,
        description
    } = props;

    return (
        <Box display="flex" justifyContent="flex-start" alignItems="center" mt={1}>
            <Box className="color-squad" style={{backgroundColor: color}} mr={1}/>
            <Box textAlign="center">
                <span className="text-light-medium">{description}</span>
            </Box>
        </Box>
    );
}