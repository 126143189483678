import React from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  useTheme,
  useMediaQuery,
  Typography,
  Button
} from "@material-ui/core";
import clsx from 'clsx';
import { Autocomplete } from '@material-ui/lab';
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { useFacade } from "./assignCourseButtonComponent.hooks";
import { useStyles } from "./assignCourseButtonComponent.styles";
import commonUseStyles from '../../../common/styles/common.styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Cancel from "@img/icons/cancel.svg";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CheckIcon from '@material-ui/icons/Check';
import { BaseCourseModel } from '../../models/courses.model';

interface AssignCourseButtonComponentProps {
  patientId: number;
}

export const AssignCourseButtonComponent: React.FC<AssignCourseButtonComponentProps> = (props: AssignCourseButtonComponentProps) => {
  const [
    {
      isLoading,
      isOpen,
      courses,
      selectedCourses,
    },
    handleCoursesSelect,
    handleAssign,
    handleToggle,
  ] = useFacade(props.patientId);

  const commonClasses = commonUseStyles();
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const renderOptionLabel = (course: BaseCourseModel): string => {
    return `${course.name}`;
  }

  const renderOption = (course) => {
    return (
      <React.Fragment>
        <Box display="flex" alignItems="center" justifyContent="space-between" width={1}>
          <Box className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorBlack)}>{course.name}</Box>
          {selectedCourses.find(c => c.id === course.id) && <CheckIcon className={classes.icon} />}
        </Box>
      </React.Fragment>
    )
  }

  const renderInput = (params) => {
    return (
      <TextField
        {...params}
        variant="outlined"
        placeholder={'Select name of the course'}
        InputProps={{
          ...params.InputProps, type: 'search'
        }}
      />
    )
  }

  return (
    <>
      {
        !isSmallScreen ? (
          <WildHealthButton
            id="assign-course-button"
            size='medium'
            onClick={() => handleToggle(!isOpen)}
          >
            Assign Course
          </WildHealthButton>
        ) : (
            <Box position="fixed" right={0} bottom="55px" zIndex={1202}>
              <Box display="flex" flexDirection="row">
                <IconButton aria-label={`assign-course-button`} onClick={() => handleToggle(!isOpen)}>
                  <AddCircleIcon className={classes.fixedButton} />
                </IconButton>
              </Box>
            </Box>
          )
      }
      <Dialog
        onClose={() => handleToggle(!isOpen)}
        fullScreen={isSmallScreen}
        className={classes.dialog}
        open={isOpen}
      >
        {
          !isSmallScreen &&
          <MuiDialogTitle
            className={classes.dialogTitle}
            disableTypography
          >
            <Typography variant="h6">Assign Course</Typography>
            <Box pl={1} pr={1}>
              <IconButton id="assign-course-close" onClick={() => {
                handleToggle(!isOpen)
              }} >
                <img src={Cancel} alt='Cancel' />
              </IconButton>
            </Box>

          </MuiDialogTitle>
        }
        <DialogContent className={classes.dialogContent}>
          {
            isSmallScreen &&
            <Box py={1} display="flex" justifyContent="space-between">
              <Button onClick={() => handleToggle(!isOpen)}>
                <span className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorGray1, commonClasses.capitalize)}>
                  Cancel
                </span>
              </Button>
              <Box>
                <span className={clsx(commonClasses.size20, commonClasses.textMedium, commonClasses.colorBlack)}>
                  Assign Course
                </span>
              </Box>
              <Button onClick={() => handleAssign()} disabled={!selectedCourses.length}>
                <span className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorMain, commonClasses.capitalize)}>
                  Assign
                </span>
              </Button>
            </Box>
          }
          <Box>
            <Box className={commonClasses.inputLabel} mb={1}>Name of the course</Box>
            <Autocomplete
              size='small'
              fullWidth
              multiple
              disableClearable
              noOptionsText={'No course'}
              options={courses}
              value={selectedCourses}
              getOptionLabel={course => renderOptionLabel(course)}
              onChange={(e, values) => handleCoursesSelect(values)}
              renderInput={(params) => renderInput(params)}
              renderOption={(course) => renderOption(course)}
            />
          </Box>
        </DialogContent>
        {
          !isSmallScreen &&
          <DialogActions className={classes.action}>
            <WildHealthButton
              id="patient-supplements-update-cancel"
              onClick={() => handleToggle(!isOpen)}
              color="tertiary"
              size='large'
              disabled={isLoading}
            >
              Cancel
              </WildHealthButton>
            <Box pl={0.5}>
              <WildHealthButton
                id="patient-supplements-update-save"
                onClick={() => { handleAssign() }}
                loading={isLoading}
                disabled={!selectedCourses.length}
                autoFocus
                size='large'
              >
                Assign Course(s)
                </WildHealthButton>
            </Box>
          </DialogActions>
        }
      </Dialog>
    </>
  );
}
