import { Box, CircularProgress, IconButton, ListItemIcon, Popover, Table, TableBody, TableContainer, TableHead, TableRow, Typography, useTheme } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React from "react";
import { WildHealthMenuItem } from "../../../common/components/wildHealthMenu/WildHealthMenu";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { useFacade } from "./documentSourcesComponent.hooks";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { FeatureComponent } from "../../../common/components/featureFlags/FeatureComponent";
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import { PagesNamesService } from "../../../../constants/pagesNames.constants";
import { WildHealthContainer } from "../../../common/components/wildHealthContainer/WildHealthContainer";
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { HealthCategoryTagNames } from "../../models/documentSource.model";

export const DocumentSourcesComponent: React.FC = () => {
    const [
        {
            isLoading,
            isTypeLoading,
            isDeleting,
            isUploading,
            isUpdating,
            isActionsOpen,
            actionsAnchorEl,
            selectedSource,
            documentSources,
        },
        handleToggleActions,
        handleFileUpload,
        handleDownload,
        handleDelete
    ] = useFacade();

    const renderTable = () => {
        if (isLoading) {
            return <WildHealthLinearProgress />
        }
    
        if (!isLoading && (!documentSources || !documentSources.length)) {
            return <WildHealthPlaceHolder message="No Document Sources found" />
        }

        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <WildHealthTableCell align="left">
                                Name
                            </WildHealthTableCell>
                            <WildHealthTableCell align="left">
                                URL
                            </WildHealthTableCell>
                            <WildHealthTableCell align="left">
                                Tags
                            </WildHealthTableCell>
                            <WildHealthTableCell style={{ width: 120 }} align="left">
                                Action
                            </WildHealthTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            documentSources.map(item =>
                                <WildHealthTableRow key={item.id}>
                                    <WildHealthTableCell align="left">
                                        {item.name}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="left">
                                        {item.url}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="left">
                                        {item.tags.map(x => (HealthCategoryTagNames[x.tag])).join(', ')}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="left">
                                        <IconButton
                                            id={`upload-document-source-${item.id}`}
                                            disabled={isDeleting || isUpdating}
                                            onClick={(event) => handleToggleActions(item.id, event.currentTarget)}
                                        >
                                            {(isDeleting || isUpdating) && item.id === selectedSource.id ? <CircularProgress size={24} className="wh-tw-text-primaryV" /> : <MoreVertIcon />}
                                        </IconButton>

                                    </WildHealthTableCell>
                                </WildHealthTableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    return (
        <Box py={2} px={10} width={1}>
            <Box my={2} display="flex" alignItems="center" justifyContent="space-between">
                <Box>
                    <FeatureComponent featureFlag={FeatureFlag.Navigation}>
                        <Box className="wh-tw-font-semibold wh-tw-text-2xl wh-tw-black">{PagesNamesService.kbDocuments}</Box>
                    </FeatureComponent>
                </Box>
                <Box ml={2}>
                <WildHealthButton
                    id="upload-document-source"
                    size="medium"
                    onClick={() => handleFileUpload()}
                    loading={isUploading}
                    disabled={isTypeLoading || isUploading}
                >
                    Upload
                </WildHealthButton>
                </Box>
            </Box>

            <WildHealthContainer margin={0} minHeight={'100%'}>
                {renderTable()}
            </WildHealthContainer>

            <Popover
                keepMounted
                anchorEl={actionsAnchorEl}
                open={isActionsOpen}
                onClose={() => handleToggleActions()}
            >
                <WildHealthMenuItem
                    id="upload-document-source-edit"
                    onClick={() => handleDownload(selectedSource.id)}
                    disabled={!selectedSource || isUploading || isDeleting}
                >
                    <ListItemIcon>
                        <EditIcon />
                    </ListItemIcon>
                    <Typography variant="inherit" noWrap>
                        Edit
                    </Typography>
                </WildHealthMenuItem>
                <WildHealthMenuItem
                    id="upload-document-source-delete"
                    onClick={() => handleDelete()}
                    disabled={!selectedSource || isUploading}
                >
                    <ListItemIcon>
                        <DeleteIcon />
                    </ListItemIcon>
                    <Typography variant="inherit" noWrap>
                        Delete
                    </Typography>
                </WildHealthMenuItem>
            </Popover>
        </Box>
    )
};