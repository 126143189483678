import React from "react";
import { PermissionType } from "../../auth/models/auth.enums";
import { authQuery } from "../../auth/stores/auth";
import { ProtectedRoute, RedirectPermissions } from "./ProtectedRoute";

const validatePermissions = (permissions: PermissionType[], allPermissions: PermissionType[]) => {
    if (permissions.includes(PermissionType.None)) {
        return true;
    }

    if (permissions.filter(x => allPermissions.includes(x)).length) {
        return true;
    }

    return false;
}

export const AuthorizedRoute = ({component: Component, userType, permissions, ...rest}) => {
    let redirectPermissions = RedirectPermissions.Normal;

    const authorized = authQuery.isLoggedIn() === true
        && authQuery.getType() === userType
        && validatePermissions(permissions, authQuery.getPermissions());

    if (!authorized) {
        redirectPermissions = RedirectPermissions.Unauthorized;
    }
    else if (authQuery.isVerified() === false) {
        redirectPermissions = RedirectPermissions.NotVerified;
    }
    else if (authQuery.isAgreementsConfirmed() === false) {
        redirectPermissions = RedirectPermissions.NoConfirmedAgreements;
    }

    return (
        <ProtectedRoute
            redirectPermission={redirectPermissions}
            component={Component}
            {...rest}
        />
    )
}
