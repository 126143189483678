import { useEffect, useState } from "react";
import { selectShortcutService } from "../../../healthReport/services/selectShortcut.service";
import { insertString } from "../../../common/helpers/insert-string";
import { UserType } from "../../../auth/models/auth.enums";
import { authQuery } from "../../../auth/stores/auth";
import { employeeConversationsQuery } from "../../stores/employeeConversationsStore/employeeConversations.query";

interface SendMessageComponentState {
    message: string;
    messageSelectionPosition: number;
    emojiAnchorElement: HTMLElement;
}

const defaultState = {
    message: '',
    messageSelectionPosition: 0,
    emojiAnchorElement: null,
} as SendMessageComponentState

let ignoreChangesEvent = false;

export function useFacade(handleTyping: () => void, handleSubmit: (message: any) => void, schedule?: (message: string) => void, emptyMessage?: boolean): [
    SendMessageComponentState,
    (event: any, message: string) => void,
    (value: string) => void,
    (anchor?: HTMLElement) => void,
    (reaction: string) => void,
    () => void,
    () => void] {

    const [state, setState] = useState({ ...Object.assign({}, defaultState) });

    const handleKeyDown = (event: any, message: string) => {
        ignoreChangesEvent = false;

        if (event.keyCode === 13 && !event.shiftKey) {
            ignoreChangesEvent = true;
            handleSendMessage()
        }
        
        if (event.keyCode === 51 && event.altKey) {
            const userType = authQuery.getType();
            if (UserType.Employee === userType) {
                ignoreChangesEvent = true;

                setTimeout(() => {
                    handleOnTextMessageChanged((document.getElementById('type-message') as any).value, (document.getElementById('type-message') as any).selectionStart);
                }, 1)
            }
        }
    }

    const handleChangeMessage = (value: string) => {
        if (ignoreChangesEvent) {
            return;
        }

        setState(state => ({ ...state, message: value }));
        handleTyping();
    }

    const handleToggleReaction = (anchor?: HTMLElement) => {
        setState(state => ({ ...state, emojiAnchorElement: anchor }));
    }

    const handleInsertReaction = (reaction: string) => {
        const position = (document.getElementById('type-message') as any).selectionStart;
        const textBefore = state.message.substring(0, position);
        const textAfter = state.message.substring(position, state.message.length);

        setState(state => ({
            ...state,
            message: `${textBefore}${reaction}${textAfter}`,
            messageSelectionPosition: position + reaction.length
        }))
    }

    const handleSchedule = () => {
        schedule(state.message.trim());
    }

    const handleSendMessage = () => {
        if (!employeeConversationsQuery.getConnectionStatus()) return;
        const result = state.message.trim();

        handleSubmit(result);
        setState(() => ({ ...Object.assign({}, defaultState) }));
    }

    const handleTextMessageChanged = (value: string) => {
        setState(state => ({
            ...state,
            message: value
        }));
    }

    const handleOnTextMessageChanged = (value: string, cursorPos: number) => {
        const cb = (data) => {
            value = state.message;
            if (data) {
                handleTextMessageChanged(insertString(value, data, cursorPos + 1));
            }
        }

        selectShortcutService.select().subscribe(cb);
    }

    useEffect(() => {
        document.getElementById('type-message')['selectionStart'] = state.messageSelectionPosition;
    }, [state.messageSelectionPosition]);

    const useEffectCB = () => {
        setState(() => ({ ...Object.assign({}, defaultState) }));
    };

    useEffect(useEffectCB, [emptyMessage]);

    return [
        state,
        handleKeyDown,
        handleChangeMessage,
        handleToggleReaction,
        handleInsertReaction,
        handleSchedule,
        handleSendMessage
    ];
}
