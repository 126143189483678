import React from 'react';
import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { useFacade } from "./updateLocationDialogComponent.hooks";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";

export const UpdateLocationDialogComponent: React.FC = () => {
    const [
        {
            model,
            careCoordinators,
            errors,
            isOpen,
        },
        handleSelectedCareCoordinatorChange,
        handleChanges,
        handleSubmit,
        handleClose
    ] = useFacade();

    return (
        <>
            <Dialog maxWidth="lg" open={isOpen} onClose={() => handleClose()} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Edit Pod</DialogTitle>
                <DialogContent>
                    <Box width="700px" pb={1}>
                        <Box display="flex" justifyContent="between" mt={2}>
                            <Box width={1}>
                                <Box height="75px">
                                    <TextField
                                        fullWidth
                                        label="Pod Name"
                                        variant="outlined"
                                        placeholder="Pod Name"
                                        size="small"
                                        InputProps={{ className: 'input' }}
                                        id="name"
                                        name="name"
                                        value={model.name}
                                        error={!!errors['name']}
                                        helperText={errors['name']}
                                        onChange={(event) => handleChanges(event.target.id, event.target.value)} />
                                </Box>
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="between" mt={1}>
                            <Box width={1}>
                                <Box height="75px">
                                    <TextField
                                        fullWidth
                                        label="Pod Address 1"
                                        variant="outlined"
                                        placeholder="Pod Address 1"
                                        size="small"
                                        InputProps={{ className: 'input' }}
                                        id="streetAddress1"
                                        name="streetAddress1"
                                        value={model.streetAddress1}
                                        error={!!errors['streetAddress1']}
                                        helperText={errors['streetAddress1']}
                                        onChange={(event) => handleChanges(event.target.id, event.target.value)} />
                                </Box>
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="between" mt={1}>
                            <Box width={1}>
                                <Box height="75px">
                                    <TextField
                                        fullWidth
                                        label="Pod Address 2"
                                        variant="outlined"
                                        placeholder="Pod Address 2"
                                        size="small"
                                        InputProps={{ className: 'input' }}
                                        id="streetAddress2"
                                        name="streetAddress2"
                                        value={model.streetAddress2}
                                        error={!!errors['streetAddress2']}
                                        helperText={errors['streetAddress2']}
                                        onChange={(event) => handleChanges(event.target.id, event.target.value)} />
                                </Box>
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="between" mt={1}>
                            <Box width={0.5}>
                                <Box mr={1} height="75px">
                                    <TextField
                                        fullWidth
                                        label="State"
                                        variant="outlined"
                                        placeholder="State"
                                        size="small"
                                        InputProps={{ className: 'input' }}
                                        id="state"
                                        name="state"
                                        value={model.state}
                                        error={!!errors['state']}
                                        helperText={errors['state']}
                                        onChange={(event) => handleChanges(event.target.id, event.target.value)} />
                                </Box>
                            </Box>
                            <Box width={0.5}>
                                <Box mr={1} height="75px">
                                    <TextField
                                        fullWidth
                                        label="City"
                                        variant="outlined"
                                        placeholder="City"
                                        size="small"
                                        InputProps={{ className: 'input' }}
                                        id="city"
                                        name="city"
                                        value={model.city}
                                        error={!!errors['city']}
                                        helperText={errors['city']}
                                        onChange={(event) => handleChanges(event.target.id, event.target.value)} />
                                </Box>
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="between" mt={1}>
                            <Box width={0.5}>
                                <Box mr={1} height="75px">
                                    <TextField
                                        fullWidth
                                        label="Zip Code"
                                        variant="outlined"
                                        placeholder="Zip Code"
                                        size="small"
                                        InputProps={{ className: 'input' }}
                                        id="zipCode"
                                        name="zipCode"
                                        value={model.zipCode}
                                        error={!!errors['zipCode']}
                                        helperText={errors['zipCode']}
                                        onChange={(event) => handleChanges(event.target.id, event.target.value)} />
                                </Box>
                            </Box>
                            <Box width={0.5}>
                                <Box mr={1} height="75px">
                                    <TextField
                                        fullWidth
                                        label="Country"
                                        variant="outlined"
                                        placeholder="Country"
                                        size="small"
                                        InputProps={{ className: 'input' }}
                                        id="country"
                                        name="country"
                                        value={model.country}
                                        error={!!errors['country']}
                                        helperText={errors['country']}
                                        onChange={(event) => handleChanges(event.target.id, event.target.value)} />
                                </Box>
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="between" mt={1}>
                            <Box width={1}>
                                <Box height="75px">
                                    <FormControl variant="outlined" size="small" color='primary' fullWidth>
                                        <InputLabel htmlFor="care-coordinator-label">Care Coordinator</InputLabel>
                                        <Select
                                            value={model.careCoordinatorId ?? 'null'}
                                            onChange={(event) => handleSelectedCareCoordinatorChange(Number(event.target.value))}
                                            label="Care Coordinator"
                                        >
                                            <MenuItem value={'null'}>{`Not Selected`}</MenuItem>
                                            {
                                                careCoordinators.map((item, index) =>
                                                    <MenuItem key={index} value={item.id}>{`${item.firstName} ${item.lastName}`}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Box m={2}>
                        <WildHealthButton
                            id="update-location-cancel"
                            color="secondary"
                            onClick={() => handleClose()}
                        >
                            Cancel
                        </WildHealthButton>
                    </Box>
                    <Box m={2}>
                        <WildHealthButton
                            id="update-location-save"
                            onClick={() => handleSubmit()}
                        >
                            Save
                        </WildHealthButton>
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    )
}