import {
    Box,
    Button,
    Popover,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
    Grid,
    FormControl,
    Select,
    MenuItem,
    IconButton,
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import moment from "moment";
import React from 'react';
import { SearchComponent } from '../../../common/components/searchComponent/SearchComponent';
import { WildHealthContainer } from '../../../common/components/wildHealthContainer/WildHealthContainer';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { WildHealthLinkButton } from '../../../common/components/WildHealthLinkButton';
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { EmployeeCoachProviderShortModel, PatientTagModel, TagSentiment } from '../../../patients/models/patient.model';
import { SortingObject, useFacade } from './manageMyPatientsComponent.hooks';
import { useStyles } from './manageMyPatientsComponent.styles';
import { FeatureComponent } from "../../../common/components/featureFlags/FeatureComponent";
import commonUseStyles from '../../../common/styles/common.styles';
import { toCurrentTimeZone } from '../../../timezones/helpers/timezone';
import { colors } from '../../../common/constants/colors';
import { fullName } from '../../../common/helpers/display-name';
import clsx from 'clsx';
import { PatientAppointmentShortModel } from '../../../appointments/models/appointments.models';
import { PatientLocationModel } from '../../../locations/models/locations.models';
import { PatientSubscriptionModel } from '../../../payment/models/subscription.models';
import { PatientAddOnsModel } from '../../../addons/models/patientAddOn.models';
import { LastConversationModel } from '../../../conversations/models/conversation.models';
import { AddOnLabels, AddOnNames } from '../../../addons/constants/patientAddOn.constants';
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { pageSizes } from "../../../common/pagination/models/page-sizes";
import { PaginationComponent } from "../../../common/pagination/components/paginationComponent/PaginationComponent";
import { navigationService } from '../../../../services/navigation.service';
import { WildHealthStatusLabel } from '../../../common/components/wildHealthStatusLabel/WildHealthStatusLabel';

const filtersPopoverProps: any = {
    id: "notificationsPopover",
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'right',
    },
};

export const ManageMyPatientsComponent: React.FC = () => {
    const [
        {
            isLoading,
            patients,
            filtersState,
            filteredTitle,
            sortingColumns,
            activeSource,
            totalCount,
            pageSize,
            selectedPage,
            selectedFilter,
            options,
            selectedOption,
            selectedFilterId,
            selectedOptionId,
        },
        handleToggleFilters,
        handleClearFilters,
        handleSearchSubmit,
        handleSorting,
        getAllAvailablePages,
        handlePageSizeChange,
        handlePageChange,
        handleFilterSelect,
        handleOptionSelect,
    ] = useFacade();

    const classes = useStyles();
    const commonClasses = commonUseStyles();

    const emptyAvaPlaceholder = () => {
        return <Box className={classes.avatarEmpty}>-</Box>
    }

    const renderPod = (pod: PatientLocationModel | null) => {
        if (pod.hoverText !== '-') {
            return <Tooltip placement="top-start" arrow title={pod.hoverText} classes={{ tooltip: classes.customTooltip, arrow: classes.arrow }}>
                <Box className={classes.avatarEmpty}>Pd</Box>
            </Tooltip>
        }

        return emptyAvaPlaceholder();
    }

    const displayHealthCoach = (healthCoach: EmployeeCoachProviderShortModel) => {
        if (healthCoach.hoverText !== '-') {
            return <Tooltip placement="top-start" arrow title={healthCoach.hoverText} classes={{ tooltip: classes.customTooltip, arrow: classes.arrow }}>
                <Box className={classes.avatarEmpty}>HC</Box>
            </Tooltip>
        }
        return emptyAvaPlaceholder();
    }

    const displayProvider = (provider: EmployeeCoachProviderShortModel) => {
        if (provider.hoverText !== '-') {
            return <Tooltip placement="top-start" arrow title={provider.hoverText} classes={{ tooltip: classes.customTooltip, arrow: classes.arrow }}>
                <Box className={classes.avatarEmpty}>Pr</Box>
            </Tooltip>
        }
        return emptyAvaPlaceholder();
    }

    const renderCanceledPlan = () => {
        return <Box color={colors.bad}>Canceled</Box>
    }

    const renderSubscription = (activePlan: PatientSubscriptionModel | null) => {
        if (activePlan.status === "Active") {
            const isRenewable = moment(toCurrentTimeZone(new Date(activePlan.renewalDate))) < moment(new Date()).add(1, 'month');
            return <>
                <Box className={clsx(commonClasses.size14, commonClasses.textRegular, commonClasses.colorBlack)}>
                    {activePlan.name}
                </Box>
                <Box mt={1} className={clsx(commonClasses.size14, commonClasses.textRegular, isRenewable ? commonClasses.colorAccent3 : commonClasses.colorGray1)}>
                    {`Renew: ${moment(toCurrentTimeZone(new Date(activePlan.renewalDate))).format('MM/DD/YYYY')}`}
                </Box>
            </>
        }
        return renderCanceledPlan();
    }

    const getStatusColor = (status) => {
        
        switch (status) {
            case 'N/A':
                return classes.avatarNA;
            case 'Preparing':
            case 'Placed':
            case 'Ordered':
            case 'Shipping':
                return classes.avatarPreparing;
            case 'In Progress':
                return classes.avatarOrange;
            case 'Failed':
                return classes.avatarDanger;
            default: return classes.avatarGood;
        }
    }

    const renderAddOns = (addOns: PatientAddOnsModel[]) => {
        if (addOns.length) {
            const initialLab = addOns.find(x => x.name === AddOnNames.InitialLabWork)
            const followUpLab = addOns.find(x => x.name === AddOnNames.FollowUpLabWork)
            const dna = addOns.find(x => x.name === AddOnNames.Dna)
            return <>
                {initialLab && (
                    <Box display="flex" alignItems="center">
                        <Box className={clsx(commonClasses.size12, commonClasses.textRegular, commonClasses.colorGray1)}>{AddOnLabels[initialLab.name]}:</Box>
                        <Box ml={1} className={getStatusColor(initialLab.status)} />
                        <Box ml={0.5} className={clsx(commonClasses.size14, commonClasses.textRegular, commonClasses.colorBlack)}>
                            {initialLab.status}
                        </Box>
                    </Box>
                )}
                {dna && (
                    <Box display="flex" alignItems="center">
                        <Box className={clsx(commonClasses.size12, commonClasses.textRegular, commonClasses.colorGray1)}>{AddOnLabels[dna.name]}:</Box>
                        <Box ml={1} className={getStatusColor(dna.status)} />
                        <Box ml={0.5} className={clsx(commonClasses.size14, commonClasses.textRegular, commonClasses.colorBlack)}>
                            {dna.status}
                        </Box>
                    </Box>
                )}
                {followUpLab && (
                    <Box display="flex" alignItems="center">
                        <Box className={clsx(commonClasses.size12, commonClasses.textRegular, commonClasses.colorGray1)}>{AddOnLabels[followUpLab.name]}:</Box>
                        <Box ml={1} className={getStatusColor(followUpLab.status)} />
                        <Box ml={0.5} className={clsx(commonClasses.size14, commonClasses.textRegular, commonClasses.colorBlack)}>
                            {followUpLab.status}
                        </Box>
                    </Box>
                )}

            </>
        }
        return '-';
    }

    const renderLastMessage = (lastMessage: LastConversationModel) => {
        if (lastMessage.sentDate) {
            return <>
                <Box className={clsx(commonClasses.size14, commonClasses.textRegular, commonClasses.colorBlack)}>
                    {moment(toCurrentTimeZone(lastMessage.sentDate)).fromNow()}
                </Box>
                <Box display="flex" alignItems="center" mt={1}>
                    <Box className={clsx(commonClasses.size12, commonClasses.textRegular, lastMessage.sentByRole === 'From Patient' ? commonClasses.colorAccent3 : commonClasses.colorGray1)}>{lastMessage.sentByRole}</Box>
                </Box>
            </>
        }
        return '-';
    }

    const renderPastAppointment = (appointment: PatientAppointmentShortModel) => {
        return <>
            <Box className={clsx(commonClasses.size14, commonClasses.textRegular, commonClasses.colorBlack)}>
                {moment(toCurrentTimeZone(appointment.startDate)).fromNow()}
            </Box>
            <Box display="flex" alignItems="center" mt={1}>
                <Tooltip placement="top-start" arrow title={`This visit was ${appointment.status}`} classes={{ tooltip: classes.customTooltip, arrow: classes.arrow }}>
                    <Box className={appointment.status === 'No Show' ? classes.avatarOrange : appointment.status === 'Cancelled' ? classes.avatarDanger : classes.avatarGood} />
                </Tooltip>
                <Box ml={1} className={clsx(commonClasses.size14, commonClasses.textRegular, commonClasses.colorGray1)}>{appointment.name}</Box>
            </Box>
        </>
    }

    const renderUpcomingAppointment = (appointment: PatientAppointmentShortModel) => {
        return <>
            <Box className={clsx(commonClasses.size14, commonClasses.textRegular, commonClasses.colorBlack)}>
                {moment(toCurrentTimeZone(new Date(appointment.startDate))).format('MM/DD/YYYY')}
            </Box>
            <Box display="flex" alignItems="center" mt={1}>
                <Box className={clsx(commonClasses.size14, commonClasses.textRegular, commonClasses.colorGray1)}>{appointment.name}</Box>
            </Box>
        </>
    }

    const renderTagColor = (tag: PatientTagModel) => {
        if (tag.name.toLowerCase().includes('due')) {
            return "bad";
        }
        switch (tag.sentiment) {
            case TagSentiment.Danger: return "bad";
            case TagSentiment.Success: return "good";
            case TagSentiment.Warning: return "average1";
            case TagSentiment.Normal:
            default: return "normal";
        }
    }

    const renderTable = () => {
        if (isLoading) {
            return <WildHealthLinearProgress />
        }

        if (!isLoading && (!patients || !patients.length)) {
            return <WildHealthPlaceHolder message="No current records found" />
        }

        const patientNameSorting = sortingColumns.find(x => x.object === SortingObject.FirstName.toString());
        const startDateSorting = sortingColumns.find(x => x.object === SortingObject.StartDate.toString());
        const activePlanSorting = sortingColumns.find(x => x.object === SortingObject.ActivePlan.toString());
        const lastMessageSorting = sortingColumns.find(x => x.object === SortingObject.LastMessage.toString());
        const lastVisitSorting = sortingColumns.find(x => x.object === SortingObject.LastAppointment.toString());
        const upcomingVisitSorting = sortingColumns.find(x => x.object === SortingObject.NextAppointment.toString());

        return (
            <>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <WildHealthTableCell style={{ width: '10%' }} align="left">
                                    <TableSortLabel
                                        id="manage-my-patients-sort-first-name"
                                        active={activeSource === patientNameSorting.object}
                                        direction={activeSource === patientNameSorting.object ? patientNameSorting.direction : 'asc'}
                                        onClick={() => handleSorting(patientNameSorting.object, patientNameSorting.source, patientNameSorting.direction)}
                                    >
                                        Patient Info
                                    </TableSortLabel>
                                </WildHealthTableCell>
                                <WildHealthTableCell style={{ width: '10%' }} align="left">
                                    <TableSortLabel
                                        id="manage-my-patients-sort-plan-name"
                                        active={activeSource === activePlanSorting.object}
                                        direction={activeSource === activePlanSorting.object ? activePlanSorting.direction : 'asc'}
                                        onClick={() => handleSorting(activePlanSorting.object, activePlanSorting.source, activePlanSorting.direction, activePlanSorting.object)}
                                    >
                                        Plan Name
                                    </TableSortLabel>
                                </WildHealthTableCell>
                                <WildHealthTableCell style={{ width: '15%' }} align="left">
                                    Add-ons
                                </WildHealthTableCell>
                                <WildHealthTableCell style={{ width: '10%' }} align="left">
                                    <TableSortLabel
                                        id="manage-my-patients-sort-joined"
                                        active={activeSource === startDateSorting.object}
                                        direction={activeSource === startDateSorting.object ? startDateSorting.direction : 'asc'}
                                        onClick={() => handleSorting(startDateSorting.object, startDateSorting.source, startDateSorting.direction)}
                                    >
                                        Start Date
                                    </TableSortLabel>
                                </WildHealthTableCell>
                                <WildHealthTableCell style={{ width: '10%' }} align="left">
                                    <TableSortLabel
                                        id="manage-my-patients-sort-last-message"
                                        active={activeSource === lastMessageSorting.object}
                                        direction={activeSource === lastMessageSorting.object ? lastMessageSorting.direction : 'asc'}
                                        onClick={() => handleSorting(lastMessageSorting.object, lastMessageSorting.source, lastMessageSorting.direction, lastMessageSorting.object)}
                                    >
                                        Last message
                                    </TableSortLabel>
                                </WildHealthTableCell>
                                <WildHealthTableCell style={{ width: '15%' }} align="left">
                                    <TableSortLabel
                                        id="manage-my-patients-sort-last-visit"
                                        active={activeSource === lastVisitSorting.object}
                                        direction={activeSource === lastVisitSorting.object ? lastVisitSorting.direction : 'asc'}
                                        onClick={() => handleSorting(lastVisitSorting.object, lastVisitSorting.source, lastVisitSorting.direction, lastVisitSorting.object)}
                                    >
                                        Last visit
                                    </TableSortLabel>
                                </WildHealthTableCell>
                                <WildHealthTableCell style={{ width: '15%' }} align="left">
                                    <TableSortLabel
                                        id="manage-my-patients-sort-upcoming-visit"
                                        active={activeSource === upcomingVisitSorting.object}
                                        direction={activeSource === upcomingVisitSorting.object ? upcomingVisitSorting.direction : 'asc'}
                                        onClick={() => handleSorting(upcomingVisitSorting.object, upcomingVisitSorting.source, upcomingVisitSorting.direction, upcomingVisitSorting.object)}
                                    >
                                        Upcoming visit
                                    </TableSortLabel>
                                </WildHealthTableCell>
                                <WildHealthTableCell style={{ width: '15%' }} align="left">
                                    Tags
                                </WildHealthTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {patients.slice((selectedPage - 1) * pageSize, (selectedPage - 1) * pageSize + pageSize).map((patient, index) => (
                                <WildHealthTableRow key={index}>
                                    <WildHealthTableCell align="left">
                                        <Box>
                                            <Box className={clsx(commonClasses.colorBlack, commonClasses.size14, commonClasses.textMedium)}>
                                                <WildHealthLinkButton
                                                    id={`manage-my-patients-${patient.patientId}`}
                                                    onClick={() => navigationService.toNewTabManagePatientProfile(patient.patientId)}
                                                    content={<Box className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorBlack, classes.link)}>{fullName(patient)}</Box>}
                                                />
                                            </Box>
                                            <Box display="flex" alignItems="center" mt={1}>
                                                {renderPod(patient.pod)}
                                                {displayHealthCoach(patient.healthCoach)}
                                                {displayProvider(patient.provider)}
                                            </Box>
                                        </Box>
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="left">
                                        {renderSubscription(patient.activePlan)}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="left">
                                        {renderAddOns(patient.addOns)}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="left">
                                        {moment(toCurrentTimeZone(new Date(patient.startDate))).format('MM/DD/YYYY')}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="left">
                                        {renderLastMessage(patient.lastMessage)}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="left">
                                        {patient.lastAppointment.startDate ? renderPastAppointment(patient.lastAppointment) : '-'}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="left">
                                        {patient.nextAppointment.startDate ? renderUpcomingAppointment(patient.nextAppointment) : '-'}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="left">
                                        {patient.tags.length > 0 ? (
                                            <Grid container spacing={2}>
                                                {patient.tags.map((tag, index) => (
                                                    <Grid item key={`patient-tag-${index}`}>
                                                        <WildHealthStatusLabel color={renderTagColor(tag)}>{tag.name}</WildHealthStatusLabel>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        ) : '-'}
                                    </WildHealthTableCell>
                                </WildHealthTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box py={1} px={2}>
                    <PaginationComponent
                        pageSizes={pageSizes}
                        pageSize={pageSize}
                        selectedPage={selectedPage}
                        handlePageSizeChange={handlePageSizeChange}
                        totalCount={totalCount}
                        availablePages={getAllAvailablePages()}
                        handlePageSelect={handlePageChange}
                    />
                </Box>
            </>
        )
    }

    return (
        <Box py={2} width={1}>
            <Box my={2} display="flex" alignItems="center" justifyContent="space-between">
                <Box>
                    <FeatureComponent featureFlag={FeatureFlag.Navigation}>
                        <Box className={clsx(commonClasses.size24, commonClasses.textSemiBold, commonClasses.colorBlack)}>{PagesNamesService.myPatients}</Box>
                    </FeatureComponent>
                </Box>
                <Box display="flex" alignItems="center">
                    <Box mr={2}>
                        <SearchComponent handleSearch={handleSearchSubmit} placeholder="Search by Name, ID or Email" />
                    </Box>
                    <Button
                        id="manage-my-patients-filter"
                        aria-describedby={filtersPopoverProps.id}
                        onClick={(event) => handleToggleFilters(event)}
                        startIcon={<FilterListIcon />}
                        className={commonClasses.whiteButton}
                    >
                        {!filteredTitle ? 'Filter' : filteredTitle}
                    </Button>
                </Box>
            </Box>

            <WildHealthContainer margin={0} minHeight={'100%'}>
                {renderTable()}
            </WildHealthContainer>

            <Popover
                id={filtersPopoverProps.id}
                anchorEl={filtersState.anchorEl}
                onClose={handleToggleFilters}
                open={filtersState.isOpen}
                anchorOrigin={filtersPopoverProps.anchorOrigin}
                transformOrigin={filtersPopoverProps.transformOrigin}
            >
                <Box py={2} pl={2} display="flex" alignItems="center">
                    <Box mr={2}>
                        <FormControl
                            color='primary'
                            variant="outlined"
                            size="small"
                            fullWidth
                            classes={{ root: classes.root }}
                        >
                            <Select
                                classes={{ root: classes.root }}
                                value={selectedFilterId}
                                inputProps={{
                                    classes,
                                }}
                                renderValue={() => {
                                    if (selectedFilterId === null) {
                                        return "-";
                                    }

                                    return <Box className={classes.selectInput}>{selectedFilter.title}</Box>;
                                }}
                                displayEmpty
                                onChange={(event) => handleFilterSelect(event.target.value as string)}
                            >
                                {
                                    filtersState.filters.map((filter, index) =>
                                        <MenuItem key={index} value={filter.id}>
                                            <Box display="flex" alignItems="center" justifyContent="space-between" width={1}>
                                                <Box className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorBlack)}>{filter.title}</Box>
                                                {selectedFilterId === filter.id && <CheckIcon className={classes.icon} />}
                                            </Box>
                                        </MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Box>
                    <Box ml={1}>
                        <FormControl
                            color='primary'
                            variant="outlined"
                            size="small"
                            fullWidth
                            classes={{ root: classes.root }}
                        >
                            <Select
                                classes={{ root: classes.root }}
                                value={selectedOptionId}
                                inputProps={{
                                    classes,
                                }}
                                renderValue={() => {
                                    if (selectedOptionId === null) {
                                        return "-";
                                    }

                                    return <Box>{selectedOption.title}</Box>;
                                }}
                                displayEmpty
                                disabled={!options.length}
                                onChange={(event) => handleOptionSelect(event.target.value as number)}
                            >
                                {
                                    options.map((option, index) =>
                                        <MenuItem key={index} value={option.optionId}>
                                            <Box display="flex" alignItems="center" justifyContent="space-between" width={1}>
                                                <Box className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorBlack)}>{option.title}</Box>
                                                {selectedOptionId === option.optionId && <CheckIcon className={classes.icon} />}
                                            </Box>
                                        </MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Box>
                    <IconButton id="clear-button" onClick={() => handleClearFilters()}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Popover>
        </Box>
    );
}