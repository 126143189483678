import React from "react";
import { EmployeeModel, EmployeeShortModel } from "../../../employee/models/employee.models";
import {
    Box,
    Button,
    List,
    ListItem,
    MenuItem,
    Popover
} from "@material-ui/core";
import { SearchTextField } from "../../../conversations/components/chatsListComponent/chatsListComponent.styles";
import { useStyles } from './selectEmployeeWithSearch.styles';
import SearchIcon from "@material-ui/icons/Search";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { ReactComponent as CoachIcon } from '@img/icons/coach.svg';

interface SelectEmployeeWithSearchComponentProps {
    selectedEmployee: EmployeeShortModel | EmployeeModel,
    employees: EmployeeShortModel[] | EmployeeModel[],
    onEmployeeChange: (employeeId: any) => void,
    me?: EmployeeShortModel | EmployeeModel | null,
    isOpen: boolean,
    handleToggle: Function,
    anchorEl: HTMLButtonElement;
    handleFiltering: Function;
}

const searchPopoverProps: any = {
    id: "profilePopover",
    anchorOrigin: {
        vertical: 'bottom',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
    }
};

export const SelectEmployeeWithSearchComponent: React.FC<SelectEmployeeWithSearchComponentProps> = (props: SelectEmployeeWithSearchComponentProps) => {
    const {
        me = null,
        selectedEmployee,
        employees,
        onEmployeeChange,
        isOpen = false,
        handleToggle = () => { },
        anchorEl,
        handleFiltering,
    } = props;

    const classes = useStyles();
    const menuItem = (employee: EmployeeShortModel | EmployeeModel, renderIcon = false) => {
        return (
            <Box display="flex" alignItems="center">
                {
                    renderIcon &&
                    <Box display="flex" alignItems="center" height="100%" mr={1}>
                        <CoachIcon/>
                    </Box>

                }
                <Box>
                    {me?.id === employee.id ? 'Me' : `${employee.firstName} ${employee.lastName}`}
                </Box>
            </Box>
        )
    }

    return (
        <>
            <Box display="flex" alignItems="center" width={175}>
                <Button
                    className={classes.button}
                    id="profile-menu-toggle-username"
                    aria-describedby={searchPopoverProps.id}
                    onClick={(event) => handleToggle(event)}
                    variant="outlined"
                    fullWidth
                    size="large">
                    <Box width="100%" display="flex" justifyContent="space-between">
                        {menuItem(selectedEmployee, true)}
                        {isOpen ? <ArrowDropUpIcon className={classes.icon} /> : <ArrowDropDownIcon className={classes.icon} />}
                    </Box>
                </Button>
            </Box>

            <Popover
                id={`profile-menu-toggle-${searchPopoverProps.id}`}
                anchorEl={anchorEl}
                onClose={(event) => handleToggle(event)}
                open={isOpen}
                anchorOrigin={searchPopoverProps.anchorOrigin}
                transformOrigin={searchPopoverProps.transformOrigin}
            >
                <Box width="350px">
                    <List component="nav" aria-label="main mailbox folders">
                        {me && <ListItem key={me.id} id="nav-my-membership" button onClick={() => onEmployeeChange(me.id)}>
                            <MenuItem value={me.id}> Me </MenuItem>
                        </ListItem>}
                        <ListItem key='search' id="nav-my-membership">
                            <SearchTextField
                                fullWidth
                                variant="outlined"
                                className={classes.search}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    handleFiltering(e.target.value)
                                }
                                placeholder="Search"
                                InputProps={{
                                    inputProps: {
                                        className: classes.searchBase,
                                    },
                                    startAdornment: <SearchIcon className={classes.icon} />,
                                }}
                            />
                        </ListItem>
                        <Box key='list' maxHeight='350px' overflow='auto' className="flat-scroll">
                            {
                                (employees as Array<EmployeeShortModel | EmployeeModel>).map((employee) => (
                                    <ListItem key={employee.id} id="nav-my-membership" button onClick={() => onEmployeeChange(employee.id)}>
                                        {
                                            menuItem(employee)
                                        }
                                    </ListItem>
                                )
                                )
                            }
                        </Box>
                    </List>
                </Box>
            </Popover>
        </>
    );
}

