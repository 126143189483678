import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { onEmit } from "../../../../../common/helpers/on-emit";
import { handleCompare } from "../../../../../common/sorting/helpers/handle-compare";
import { SortingDirection } from "../../../../../common/sorting/models/sorting-destination";
import { LeadSourceModel } from "../../../../../leadSources/models/leadSource.models";
import { leadSourcesService } from "../../../../../leadSources/services/leadSources.service";
import { leadSourcesQuery } from "../../../../../leadSources/stores/leadSources.query";
import { PatientModel, UpdatePatientModel } from "../../../../../patients/models/patient.model";
import { patientsService } from "../../../../../patients/services/patients.service";
import { patientsQuery } from "../../../../../patients/stores/patientsStore/patients.query";

interface MembershipInfoWidgetComponentState {
  isLoading: boolean;
  patient: PatientModel;
  isChanged: boolean;
}

export function useFacade(patientId: number | null): [
  MembershipInfoWidgetComponentState,
  (isFellow:boolean) => void,
  () => void
] {
  const [state, setState] = useState({
    isLoading: true,
    isChanged: false
} as MembershipInfoWidgetComponentState);

  const handleIsFellowChange = (isFellow:boolean) => {
    setState(state => ({
      ...state,
      isChanged:!state.isChanged,
      patient :{
        ...state.patient,
        options:{
          isFellow: isFellow
        }        
      }
    }));
  }

  const handleSubmit = () => {
    setState(state => ({ ...state, isLoading: true }));

    const model: UpdatePatientModel = {
        id: state.patient.id,
        firstName: state.patient.firstName,
        lastName: state.patient.lastName,
        email: state.patient.email,
        phoneNumber: state.patient.phoneNumber,
        birthday: state.patient.birthday,
        gender: state.patient.gender,
        billingAddress: state.patient.billingAddress,
        shippingAddress: state.patient.shippingAddress,
        employeeIds: state.patient.assignedEmployees.map(el => el.id),
        options: state.patient.options,
        leadSource: state.patient.leadSource?.leadSourceId === 0 ? null : state.patient.leadSource,
        locationId: state.patient.locationId
    };

    patientsService.update(model).subscribe(
        () => setState(state => ({
          ...state,
          isChanged:false,
          isLoading:false
        })),
        () => setState(state => ({
          ...state,
          isChanged:false,
          isLoading:false
        }))
    );
}


  useEffect(() => {
    const subscriptions: Subscription[] = [
      onEmit<PatientModel>(patientsQuery.targetPatient$, targetPatient => {
        if (targetPatient?.id === Number(patientId)) {
            setState(state => ({
                ...state,
                isLoading: false,
                patient: targetPatient
            }));
        }
    }),
    onEmit<LeadSourceModel[]>(leadSourcesQuery.leadSources$, leadSources => {
        setState(state => ({
            ...state,
            leadSources:leadSources.sort((l1, l2,) => handleCompare(l1, l2, SortingDirection.Desc, 'isOther')),
            isLoading:false
            
        }))
    }),
    ];

    leadSourcesService.getEmployeeActive();

    return () => {
      subscriptions.map((it) => it.unsubscribe());
    };
  }, [patientId]);

  return [
    state,
    handleIsFellowChange,
    handleSubmit
  ];
}
