import {
    ConversationTemplateModel, ConversationType,
    StartPatientPlaygroundConversationModel
} from "../../models/conversation.models";
import {useEffect, useState} from "react";
import {conversationsService} from "../../services/conversations.service";
import {Subscription} from "recompose";
import {onEmit} from "../../../common/helpers/on-emit";
import {employeeConversationsQuery} from "../../stores/employeeConversationsStore/employeeConversations.query";

interface StartPatientPlaygroundComponentState {
    isLoading: boolean;
    templates: ConversationTemplateModel[];
    selectedTemplate: ConversationTemplateModel;
}

export function useFacade(handleCreated: Function, patientId: number): [
    StartPatientPlaygroundComponentState,
    (template: ConversationTemplateModel) => void,
    (message: string) => void,
] {
    const [state, setState] = useState({
        isLoading: false,
        templates: [],
        selectedTemplate: null
    } as StartPatientPlaygroundComponentState);

    const handleSelectTemplate = (template: ConversationTemplateModel) => {
        setState(state => ({
            ...state,
            selectedTemplate: template
        }));
    }

    const handleSendMessage = (message: string) => {
        const model: StartPatientPlaygroundConversationModel = {
            patientId: patientId,
            prompt: message
        };

        setState(state => ({
            ...state,
            isLoading: true
        }));

        conversationsService.startPatientPlaygroundConversations(model)
            .subscribe(
                (data) => {
                    handleCreated(data);
                }, () => {
                    setState(state => ({
                        ...state,
                        isLoading: false
                    }));
                }
            );
    };

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<ConversationTemplateModel[]>(employeeConversationsQuery.templates$, templates =>
                {
                    setState(state => ({
                        ...state,
                        templates: templates
                    }))
                }
            )
        ];

        conversationsService.getConversationTemplates(ConversationType.PatientPlayground).subscribe(
            () => {
                setState(state => ({
                    ...state,
                    isLoading: false
                }))
            }, () => {
                setState(state => ({
                    ...state,
                    isLoading: false
                }))
            }
        )

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [patientId]);

    return [
        state,
        handleSelectTemplate,
        handleSendMessage
    ];
}