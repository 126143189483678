import { Box, Container, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { PaymentPlanModel } from '../../models/paymentPlan.models';
import { useStyles } from './reselectPaymentPlanComponent.styles';
import { ReselectPaymentPlanCardComponent } from '../reselectPaymentPlanCardComponent/ReselectPaymentPlanCardComponent';
import { paymentPlansService } from '../../services/paymentPlans.service';
import { Track, PlatformName } from "../../../tracking/tracker";

interface ReselectPaymentPlanComponentProps {
    plans: PaymentPlanModel[];
}

export const ReselectPaymentPlanComponent: React.FC<ReselectPaymentPlanComponentProps> = (props: ReselectPaymentPlanComponentProps) => {
    const {
        plans,
    } = props;

    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const handlePlanSelect = (plan: PaymentPlanModel) => {  
        paymentPlansService.selectPlan(plan);      

        Track("Button Click", {
            type: 'select_plan',
            plan_type: plan.displayName,
            acValue: plan.displayName,
            platform: PlatformName()
        })
    };

    const renderPlans = () => {
        return (
            <Box className={classes.main}>
                <Grid container alignItems="stretch" justify='center' spacing={2}>
                    {
                        plans.map((plan: PaymentPlanModel) => {
                            return (
                                <Grid key={plan.id} item md={6} sm={12} xs={12}>
                                    <Box height={1} width={1} display="flex" flexDirection="column">
                                        <Box height={1} width={1} display="flex" alignItems="stretch">
                                            <ReselectPaymentPlanCardComponent
                                                plan={plan}
                                                handlePlanSelect={handlePlanSelect} />
                                        </Box>
                                    </Box>
                                </Grid>
                            )
                        })}
                </Grid>
            </Box>
        )
    }

    return (
        <>
            <Container className={classes.container}>
                <Box mt={isSmallScreen ? 2 : 6} pb={isSmallScreen ? 0 : 5}>
                    <Box>
                        {
                            renderPlans()
                        }
                    </Box>
                </Box>
            </Container>
        </>
    )
}
