import { TextField } from '@material-ui/core';
import { makeStyles, withStyles, Theme } from '@material-ui/core/styles';
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: colors.white,
    maxWidth: 465,
    minHeight: 'calc(100vh - 220px)'
  },
  statusPointer: {
      marginTop: 10,
      borderRadius: 5,
      border: 'none',
      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: colors.lightGray,
      }
  },
  search: {
    backgroundColor: colors.white,
    borderColor: colors.white,
  },
  searchBase: {
    padding: '10px 14px',
    fontFamily: 'Be Vietnam Pro',
    fontSize: 16,
  },
  icon: {
    color: colors.gray2,
  },
  menuIcon: {
    [theme.breakpoints.down("xs")]: {
      "&.MuiIconButton-root": {
        padding: 0,
        color: colors.black,
      },
    },
  },
}));

export const SearchTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: colors.main,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: colors.white,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: colors.white,
      },
      '&:hover fieldset': {
        borderColor: colors.white,
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.white,
      },
    },
  },
})(TextField);
