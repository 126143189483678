import { colors } from "../../../common/constants/colors";
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        borderColor: colors.gray4,
        borderRadius: 2,
        '& label.Mui-focused': {
            color: colors.main,
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: colors.main,
            },
            borderColor: colors.gray4,
            borderRadius: 2,
        },
    },
}));
