import { Gender } from "../../../common/models/user.models";
import { addressValidationRules, birthdayValidationRule, emailValidationRule, firstNameValidationRule, functionValidationRule,lastNameValidationRule, notWhitespaceValidationRule, phoneNumberValidationRule, stringLengthValidationRule } from "../../../common/validation/general-validation-rules";
import { GeneralValidator } from "../../../common/validation/general-validator";

export const patientInformationComponentValidator = new GeneralValidator({
    // User form
    'firstName': [firstNameValidationRule, notWhitespaceValidationRule()],
    'lastName': [lastNameValidationRule, notWhitespaceValidationRule()],
    'email': [emailValidationRule],
    'birthday': [birthdayValidationRule],
    'phoneNumber': [phoneNumberValidationRule],
    'gender': [functionValidationRule((value) => {
        if (value === Gender.Female) return true;
        return value === Gender.Male;
    }, "Gender should be selected.")],

    // billingAddress form
    'billingAddress.streetAddress1': [addressValidationRules.streetAddress1, notWhitespaceValidationRule()],
    'billingAddress.streetAddress2': [addressValidationRules.streetAddress2, notWhitespaceValidationRule()],
    'billingAddress.city': [addressValidationRules.city, notWhitespaceValidationRule()],
    'billingAddress.state': [addressValidationRules.state, notWhitespaceValidationRule()],
    'billingAddress.country': [addressValidationRules.country, notWhitespaceValidationRule()],
    'billingAddress.zipCode': [addressValidationRules.zipCode, notWhitespaceValidationRule()],

    // shippingAddress form
    'shippingAddress.streetAddress1': [addressValidationRules.streetAddress1, notWhitespaceValidationRule()],
    'shippingAddress.streetAddress2': [addressValidationRules.streetAddress2, notWhitespaceValidationRule()],
    'shippingAddress.city': [addressValidationRules.city, notWhitespaceValidationRule()],
    'shippingAddress.state': [addressValidationRules.state, notWhitespaceValidationRule()],
    'shippingAddress.country': [addressValidationRules.country, notWhitespaceValidationRule()],
    'shippingAddress.zipCode': [addressValidationRules.zipCode, notWhitespaceValidationRule()],

    // lead source form
    'leadSource.otherLeadSource': [stringLengthValidationRule(1, 50)],
});
