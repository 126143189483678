import { Query } from '@datorama/akita';
import { MetaTagsModel } from '../../models/metaTags.models';
import { PatientProfileState, patientProfileStore, PatientProfileStore } from "./patientProfileStore";

/**
 * Provides patientProfile queries
 */
export class PatientProfileQuery extends Query<PatientProfileState> {
    selectedTab$ = this.select(state => state.selectedTab);
    pathHistory$ = this.select(state => state.pathHistory);
    metaTags$ = this.select(state => state.metaTagsModel);
    npsScores$ = this.select(state => state.npsScores);
    paymentPlanHistories$ = this.select(state => state.paymentPlanHistories);

    constructor(protected store: PatientProfileStore) {
        super(store);
    }

    public getTab(): string {
        return this.getValue().selectedTab;
    }

    public getPath(): string {
        return this.getValue().pathHistory;
    }

    public getMetaTags(): MetaTagsModel {
        return this.getValue().metaTagsModel;
    }
}

export const patientProfileQuery = new PatientProfileQuery(patientProfileStore);
