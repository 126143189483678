import {
  Box,
  Link,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import React from 'react';
import { SearchComponent } from '../../../common/components/searchComponent/SearchComponent';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { useFacade } from './alertsHistoryComponent.hooks';
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from 'clsx';
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { pageSizes } from "../../../common/pagination/models/page-sizes";
import { PaginationComponent } from "../../../common/pagination/components/paginationComponent/PaginationComponent";
import moment from 'moment';
import { colors } from '../../../common/constants/colors';
import { navigationService } from '../../../../services/navigation.service';
import { WildHealthPlaceHolderBase } from '../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolderBase';
import emptyAppointments from "@img/placeholders/EmptyAppointments.png";


export const AlertsHistoryComponent: React.FC = () => {
  const [
    {
      isLoading,
      alerts,
      totalCount,
      pageSize,
      selectedPage,
    },
    handleSearchSubmit,
    getAllAvailablePages,
    handlePageSizeChange,
    handlePageChange,
  ] = useFacade();
  const commonClasses = commonUseStyles();

  const displayName = (firstName = '', lastName = ''): string => {
    return `${firstName} ${lastName}`
  }

  const renderTable = () => {
    if (isLoading) {
      return <WildHealthLinearProgress />
    }

    return (
      <>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <WildHealthTableCell style={{ width: '15%' }} align="left">
                  Alert title
                </WildHealthTableCell>
                <WildHealthTableCell style={{ width: '55%' }} align="left">
                  Description
                </WildHealthTableCell>
                <WildHealthTableCell style={{ width: '15%' }} align="left">
                  Patient name
                </WildHealthTableCell>
                <WildHealthTableCell style={{ width: '15%' }} align="left" >
                  Resolved by
                </WildHealthTableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {(!alerts || !alerts.length)
                ? <TableRow hover={false} selected={false} key='empty-state'>
                  <WildHealthTableCell colSpan={4}>
                    <Box width='100%' height='60vh'>
                      <WildHealthPlaceHolderBase message='No  Alerts'>
                        <img style={{ width: '300px' }}
                          src={emptyAppointments}
                          alt="img" />
                      </WildHealthPlaceHolderBase>
                    </Box>
                  </WildHealthTableCell>
                </TableRow>
                : alerts.map((alert, index) => (
                  <WildHealthTableRow key={index}>
                    <WildHealthTableCell align="left">
                      <Box fontSize='14px' >{alert.title}</Box>
                    </WildHealthTableCell>
                    <WildHealthTableCell align="left">
                      <Tooltip placement="top" arrow title={`${alert.description}`}>
                        <Box fontWeight='400' color={colors.gray1} className='text-ellipsis--2'>{alert.description}</Box>
                      </Tooltip>
                    </WildHealthTableCell>
                    <WildHealthTableCell align="left">
                      <Box fontWeight='400' fontSize='14px'>
                        <Link id="alerts-history-navigate-to-user" color={colors.black} onClick={() => navigationService.toNewTabManagePatientProfile(alert.patientId)}>
                          <Box className={commonClasses.underline}>{displayName(alert.patient.firstName, alert.patient.lastName)}</Box>
                        </Link>
                        <Box color={colors.gray1}>{moment(alert.createdAt).format('MM/DD/YYYY')}</Box>
                      </Box>
                    </WildHealthTableCell>
                    <WildHealthTableCell align="left">
                      <Box fontWeight='400' fontSize='14px'>
                        <Box>{displayName(alert.resolvedBy.firstName, alert.resolvedBy.lastName)}</Box>
                        <Box color={colors.gray1} >{moment(alert.resolvedAt).format('MM/DD/YYYY')}</Box>
                      </Box>
                    </WildHealthTableCell>
                  </WildHealthTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {(alerts && alerts.length)
          ? <Box py={1} px={2}>
            <PaginationComponent
              pageSizes={pageSizes}
              pageSize={pageSize}
              selectedPage={selectedPage}
              handlePageSizeChange={handlePageSizeChange}
              totalCount={totalCount}
              availablePages={getAllAvailablePages()}
              handlePageSelect={handlePageChange}
            />
          </Box>
          : <Box></Box>}
      </>
    )
  }

  return (
    <Box py={2} px={10} width={1}>
      <Box mt={5} mb={2} display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Box className={clsx(commonClasses.size24, commonClasses.textMedium, commonClasses.colorBlack)}>{PagesNamesService.alertsHistory}</Box>
        </Box>
        <Box width="310px">
          <SearchComponent handleSearch={handleSearchSubmit} placeholder="Search by Name" />
        </Box>
      </Box>
      <Box className={commonClasses.bgWhiteMain}>
        {renderTable()}
      </Box>
    </Box>
  );
}
