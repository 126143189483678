import {useEffect, useState} from "react";
import {Subscription} from "rxjs";
import {onEmit} from "../../../common/helpers/on-emit";
import {ISortingState} from "../../../common/sorting/models/sorting-state";
import {pageSizes} from '../../../common/pagination/models/page-sizes';
import {IPaginationState} from '../../../common/pagination/models/pagination-state';
import {getAvailablePages} from "../../../common/pagination/helpers/get-evailable-pages";
import {UnmatchedDocumentModel} from "../../models/unmatchedLabs.models";
import {snackService} from "../../../common/snack/state";
import {labOrdersService} from "../../../orders/services/labOrders.service";
import {labOrdersQuery} from "../../../orders/stores/labOrdersStore";

interface UnmatchedDocumentState extends ISortingState, IPaginationState {
    isLoading: boolean;
    assignFile: UnmatchedDocumentModel;
    deleteFile: UnmatchedDocumentModel;
    isAssignToPatientOpen: boolean;
    isViewFileOpen: boolean;
    labFilesAll: UnmatchedDocumentModel[];
    labFilesView: UnmatchedDocumentModel[];
}

export function useFacade(): [
    UnmatchedDocumentState,
    (page: number) => void,
    (value) => void,
    () => number[],
    () => void,
    (file: UnmatchedDocumentModel) => void,
    (file: UnmatchedDocumentModel) => void,
    (patientId: number) => void,
    (file: UnmatchedDocumentModel) => void,
    () => void,
] {
    const [state, setState] = useState({
        isLoading: true,
        isAssignToPatientOpen: false,
        isViewFileOpen: false,
        labFilesAll: [],
        labFilesView: [],
        assignFile: null,
        deleteFile: null,
        totalCount: 0,
        selectedPage: 1,
        pageSize: pageSizes[0],
    } as UnmatchedDocumentState);

    const handleCancelAssign = () => {
        setState(state => ({
            ...state,
            isAssignToPatientOpen: false,
            assignFile: null,
        }))
    }

    const handleCloseView = () => {
        setState(state => ({
            ...state,
            isViewFileOpen: false,
        }))
    }

    const handleOpenAssignDialog = (file: UnmatchedDocumentModel) => {
        setState(state => ({
            ...state,
            isAssignToPatientOpen: true,
            assignFile: file,
        }))
    }

    const handleDelete = (file: UnmatchedDocumentModel) => {
        setState(state => ({
            ...state,
            deleteFile: file
        }));

        labOrdersService.deleteUnmatchedFile(file.labResultId).subscribe(
            () => {
                setState(state => ({
                    ...state,
                    deleteFile: null
                }));
            },
            () => {
                setState(state => ({
                    ...state,
                    deleteFile: null
                }));
            }
        )
    }

    const getFiles = (files: UnmatchedDocumentModel[], start: number, end: number) => {
        return files.slice(start, end)
    }

    const handleOpenViewFileDialog = (file: UnmatchedDocumentModel) => {
        labOrdersService.viewinline(file.id, file.mimeType).subscribe(
            (fileURL) => {

                setState(state => ({
                    ...state,
                    isViewFileOpen: true,
                    assignFile: {
                        ...file,
                        path: fileURL,
                    },
                }))
            },
        )

    }

    const updateDocuments = (documentId: number) => {
        const filteredFile = state.labFilesAll.filter(file => file.id !== documentId);
        setState(state => ({
            ...state,
            labFilesAll: filteredFile,
            selectedPage: 1,
            labFilesView: getFiles(filteredFile, 0, state.pageSize),
            totalCount: filteredFile.length,
        }))
    }

    const handleAssignToPatient = (patientId: number) => {
        const labResultId = state.assignFile.labResultId
        const documentId = state.assignFile.id

        labOrdersService.assignUnmatchedFile(patientId, labResultId).subscribe(
            (res) => {
                if (res) {
                    if (state.labFilesAll.length === 1) {
                        labOrdersService.getAnyUnmatchedFiles().subscribe()
                    }
                    updateDocuments(documentId)
                    setState(state => ({
                        ...state,
                        isAssignToPatientOpen: false
                    }))
                    snackService.success("File was successfully assigned to patient!");
                } else {
                    snackService.error("File Not assigned!");
                }
            },

            () => {
                snackService.error("File Not assigned!");
            }
        )


    }

    const handleChangePage = (page: number) => {
        const start = page === 1 ? 0 : ((page - 1) * state.pageSize)
        setState(state => ({
            ...state,
            selectedPage: page,
            labFilesView: getFiles(state.labFilesAll, start, page * state.pageSize)
        }))
    }

    const handlePageSizeChange = (value) => {
        if (value === state.pageSize) {
            return;
        }

        setState(state => ({
            ...state,
            pageSize: value,
            selectedPage: 1,
            labFilesView: getFiles(state.labFilesAll, 0, value)
        }))

    }

    const getAllAvailablePages = (): number[] => {
        return getAvailablePages(state);
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<UnmatchedDocumentModel[]>(labOrdersQuery.unmatchedDocuments$, unmatchedDocuments => {
                setState(state => (
                    {
                        ...state,
                        labFilesAll: unmatchedDocuments,
                        labFilesView: getFiles(unmatchedDocuments, 0, pageSizes[0]),
                        totalCount: unmatchedDocuments.length,
                    }))
            }),
        ];

        const cb = () => setState(state => ({...state, isLoading: false}))

        labOrdersService.getUnmatchedLabs().subscribe(cb, cb);

        return (() => {
            subscriptions.map(it => it.unsubscribe())
        })
    }

    useEffect(useEffectCB, []);

    return [
        state,
        handleChangePage,
        handlePageSizeChange,
        getAllAvailablePages,
        handleCancelAssign,
        handleOpenAssignDialog,
        handleDelete,
        handleAssignToPatient,
        handleOpenViewFileDialog,
        handleCloseView,
    ];
}