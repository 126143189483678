export interface NotificationModel {
    id: number;
    type: NotificationType;
    createdAt: Date;
    subject: string;
    text: string;
    linkData: string;
}

export interface DeleteNotificationsModel {
    ids: number[];
}

export enum NotificationType {
    PatientAssignment = 1,
    LabResults = 2,
    AppointmentRequest = 3,
    ChangeHealthPlan = 4,
    NewEnrollment = 5,
    HealthLogReminder = 6,
    DnaKitFailed = 7,
    PatientTransferred = 8,
    AppointmentCancelled = 9,
    FellowEnrolled = 10,
    FellowshipPatientEnrolled = 11,
    PracticumPatientAdded = 12,
    UnreadMessagesCount = 13,
    NewMessage = 14,
    HealthReportReview = 15,
    NewSupportTicket = 16,
    HealthReportReviewed = 17,
    NewHealthReport = 18,
    AppointmentConfirmed = 19,
    TimekitAccountIssue = 20,
    ScheduledSmsNotification = 21,
    UnreadMessagesManual = 22,
    NewDocuments = 23,
    NoteSignOff = 25,
    NewNote = 26,
    MessageAdminToolEmail = 27,
    MessageAdminToolClarity = 28,
    MessageAdminToolSMS = 29,
    NewAlert = 30,
    PatientJourneyRewardUnlocked = 38,
    UpcomingLabs = 39
}
