import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { onEmit } from "../../../common/helpers/on-emit";
import { PatientJourneyModel, PatientJourneyRewardModel } from "../../models/patient.model";
import { patientsQuery } from "../../stores/patientsStore";
import { handleCta } from '../../helpers/taskCtaHandler';

interface PatientRewardTasksComponentState {
    isDownloading: boolean;
    rewardTasks: PatientJourneyRewardModel[];
    isSideAreaOpen: boolean;
    selectedTask: PatientJourneyRewardModel;
    selectedTaskId: number;
}

export function useFacade(): [
    PatientRewardTasksComponentState,
    (task: PatientJourneyRewardModel | null, open: boolean) => void,
    (task: PatientJourneyRewardModel) => void
] {
    const [state, setState] = useState({
        isDownloading: false,
        rewardTasks: [],
        isSideAreaOpen: false,
        selectedTask: null,
        selectedTaskId: 0
    } as PatientRewardTasksComponentState);

    const handleToggleSideArea = (task: PatientJourneyRewardModel | null, open: boolean) => {
        setState(state => ({ ...state, isSideAreaOpen: open, selectedTask: task }))
    }

    const handleDownload = (task: PatientJourneyRewardModel) => {
        setState(state => ({ ...state, selectedTaskId: task.id }))
        handleCta(task);
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<PatientJourneyModel>(patientsQuery.patientJourneyTasks$, patientJourneyTasks => {
                setState(state => ({
                    ...state,
                    rewardTasks: patientJourneyTasks.rewards
                }));
            }),
            onEmit<boolean>(patientsQuery.isDownloadingTask$, isDownloading => {
                setState(state => ({
                    ...state,
                    isDownloading,
                    selectedTaskId: !isDownloading ? 0 : state.selectedTaskId
                }));
            }),
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [
        state,
        handleToggleSideArea,
        handleDownload
    ];
}