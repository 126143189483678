import Axios from 'axios-observable';
import {Observable} from "rxjs";
import { API_URL } from '../../../config';
import { authHeader } from "../../common/helpers/auth-header";
import { snackService } from '../../common/snack/state';
import { patientProfileStore, PatientProfileStore } from '../stores/patientProfileStore';

/**
 * Provides methods for working with meta tags
 */
export class MetaTagsService {
    private url = `${API_URL}MetaTags`;

    constructor(private metaTagsStore: PatientProfileStore) {
    }

    /**
     * Returns all meta tags for patient
     */
    public getMetaTags(patientId: number): Observable<any> {
        const config = {
            headers: authHeader()
        };
        return new Observable<any>(observer => {
            Axios.get<any>(`${this.url}?patientId=${patientId}`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.metaTagsStore.update({ metaTagsModel: response.data })
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }
}

export const metaTagsService = new MetaTagsService(patientProfileStore);
