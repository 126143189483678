import { useState, useEffect, useCallback } from 'react'
import { groupBy, mapValues } from 'lodash';
import { onEmit } from '../../../common/helpers/on-emit'
import { VisitPrepModel } from '../../models/visitPrep.model'
import { patientStore, patientsQuery } from '../../stores/patientsStore'
import { visitPrepService } from '../../services/visitPrep.service'
import { documentsService } from '../../../inputs/services/documents.service'
import { LabInputModel, LabInputsDatasetViewModel } from '../../../inputs/models/input.models';
import { sortLabInputDataSets } from '../../../inputs/helpers/sort-lab-input-data-sets';

export enum SectionEnum {
    PLAN = 'PLAN',
    MDM = 'MDM',
    GOALS = 'GOALS',
    NEW_LABS = 'NEW_LABS',
    MEDICATIONS_AND_SUPPLEMENTS = 'MEDICATIONS_AND_SUPPLEMENTS',
    RECENT_DOCUMENTS = 'RECENT_DOCUMENTS'
}

type SectionExtendState = {
    [key in SectionEnum]: boolean
}

export interface VisitPrepComponentState {
    isLoading: boolean;
    visitPrep: VisitPrepModel;
    sectionExpandStates: SectionExtendState;
    selectedPlanTab: number;
}

export function useFacade(patientId: number | null): [
    VisitPrepComponentState,
    (key: SectionEnum) => void,
    (fileId: number) => void,
    Function,
    (tab: number) => void
] {
    const [state, setState] = useState({
        isLoading: true,
        visitPrep: null,
        sectionExpandStates: {
            PLAN: true,
            MDM: true,
            GOALS: true,
            NEW_LABS: true,
            MEDICATIONS_AND_SUPPLEMENTS: true,
            RECENT_DOCUMENTS: true
        },
        selectedPlanTab: 0
    } as VisitPrepComponentState)

    const getDataSets = useCallback((inputs: LabInputModel[]): LabInputsDatasetViewModel[] => {
        const allValues = inputs.flatMap(x => x.values);
  
        const dataSets = groupBy(allValues, 'dataSetId');

        const result = mapValues(dataSets, values => ({
            id: values[0].dataSetId,
            date: values[0].date,
            selected: true,
            values
        } as LabInputsDatasetViewModel));
    
        return Object.values(result).sort(sortLabInputDataSets) as LabInputsDatasetViewModel[];
    }, []);

    const handleExpand = (key: SectionEnum) => {
        setState(state => ({ ...state, sectionExpandStates: {...state.sectionExpandStates, [key]: !state.sectionExpandStates[key]} }));
    }

    const handleDownload = (fileId: number) => {
        const input = state.visitPrep.recentDocuments.find(i => i.id === fileId);
        if (input) {
            documentsService.download(fileId, input.name).subscribe()
        }
    }

    const handleChangePlanTab = (tab: number) => {
        setState(state => ({
            ...state,
            selectedPlanTab: tab,
        }));
    }

    useEffect(() => {
        const subscriptions = [
            onEmit<VisitPrepModel>(patientsQuery.visitPrep$, visitPrep => {
                setState(state => ({
                    ...state,
                    visitPrep,
                    selectedPlanTab: 0
                }))
            }),
        ]

        patientStore.clearVisitPrep();
        const cb = () => setState(state => ({ ...state, isLoading: false }))

        if (patientId) {
            visitPrepService.getVisitPrepByEmployee(patientId).subscribe(cb, cb);
        } else {
            visitPrepService.getVisitPrep().subscribe(cb, cb);
        }

        return () => {
            subscriptions.map(it => it.unsubscribe());
            patientStore.clearVisitPrep();
        }

    }, [patientId])

    return [
        state,
        handleExpand,
        handleDownload,
        getDataSets,
        handleChangePlanTab
    ]
}