import {
    downloadNoteOptions,
    HistoryAndPhysicalContent,
    MdmModel,
    NoteContentModel,
    NoteModel
} from "../../models/notes.models";
import { useEffect, useState } from "react";
import { historyAndPhysicalPdfService } from "../../services/notes-pdf.service";
import { Subscription } from "recompose";
import { onEmit } from "../../../common/helpers/on-emit";
import { notesQuery, notesStore } from "../../stores/notes";
import { notesService } from "../../services/notes.service";
import { mdmCodesService } from "../../services/mdmCodes.service";
import { GenerateAmendedChangesForHistoryAndPhysical, GetMaximumVersionOfNote } from "../../helpers/noteHelpers";
import { confirmService } from "../../../../services/confirm.service";
import { UserType } from "../../../auth/models/auth.enums";

interface HistoryAndPhysicalFollowUpComponentState {
    note: NoteModel;
    mdmCodes: MdmModel[];
    content: NoteContentModel;
    internalContent: HistoryAndPhysicalContent;
    isPdfDownloading: boolean;
    isContentLoading: boolean;
    originalContent: HistoryAndPhysicalContent;
    noteId: number;
}

export function useFacade(patientId: number, note: NoteModel): [HistoryAndPhysicalFollowUpComponentState, Function, Function] {
    const [state, setState] = useState({
        note: note,
        mdmCodes: [],
        content: null,
        internalContent: null,
        isPdfDownloading: false,
        isContentLoading: true,
        noteId: GetMaximumVersionOfNote(note)?.id,
        originalContent: null
    });

    const handleDownloadPdf = (note: NoteModel, content: NoteContentModel) => {
        const cb = () => setState({ ...state, isPdfDownloading: false });
        confirmService.confirmAndSelectedNoteViewType(
            'Select the view type of the note',
            downloadNoteOptions,
        ).subscribe(
            (type) => {
                const isEmployeeView = type === UserType.Employee
                setState({
                    ...state,
                    isPdfDownloading: true
                });

                notesService.downloadNote(state.noteId, note.name, isEmployeeView).subscribe(
                    cb,
                    () => {
                        historyAndPhysicalPdfService
                        .downloadNotePdf(
                            note, 
                            JSON.parse(content.content),
                            isEmployeeView, 
                            state.noteId,
                            GenerateAmendedChangesForHistoryAndPhysical(state.originalContent, state.internalContent)
                            ).subscribe(cb, cb);
                    }
                )

            },
            () => { }
        );  
    }

    const handleChooseNote = (noteIdNew: number) => {
        setState(state => ({
            ...state,
            noteId: noteIdNew,
            isContentLoading: true
        }));
        
        notesService.getContentByEmployee(noteIdNew, patientId).subscribe(
            () => {
                setState(state => ({
                    ...state,
                    isContentLoading: false
                }))
            }
        );
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<NoteContentModel>(notesQuery.targetContent$, targetContent => {
                if (targetContent) {
                    if(targetContent.noteId == state.note?.id){
                        setState(state => ({
                            ...state,
                            content: targetContent,
                            originalContent: JSON.parse(targetContent.content),
                            internalContent: JSON.parse(targetContent.content),
                            isContentLoading: false,
                        }));
                    } else {
                        setState(state => ({
                            ...state,
                            content: targetContent,
                            internalContent: JSON.parse(targetContent.content),
                            isContentLoading: false,
                        }));
                    }
                }
            })
        ];

        notesService.getContentByEmployee(GetMaximumVersionOfNote(note).id, patientId).subscribe(
            () => {
                if (note?.id !== GetMaximumVersionOfNote(note).id) {
                    notesService.getContentByEmployee(note.id, patientId, true).subscribe(
                        (originalNoteContent) => {
                            setState(state => ({
                                ...state,
                                originalContent: JSON.parse(originalNoteContent.content),
                            }))
                        }
                    );
                }
            }
        );

        mdmCodesService.get(note.type).subscribe(codes => {
            setState(state => ({ ...state, mdmCodes: codes }))
        })

        return () => {
            notesStore.resetTargetContent()
            subscriptions.map(it => it.unsubscribe())
        };
    }, [note, patientId]);

    return [state, handleDownloadPdf, handleChooseNote];
}