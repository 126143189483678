import React from 'react';
import { Box, Card, CardContent, Divider, Grid, Link, Typography, Popover, IconButton, Tooltip } from "@material-ui/core";
import LaunchIcon from '@material-ui/icons/Launch';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import moment from 'moment';
import { ReactComponent as BellIcon } from '@img/icons/BellIcon.svg';
import { useStyles } from "./activeMembershipCardComponent.styles";
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { SubscriptionModel, SubscriptionStatus, SubscriptionPauseStatus } from '../../models/subscription.models';
import { WildHealthMenuItem } from '../../../common/components/wildHealthMenu/WildHealthMenu';
import { toCurrentTimeZone } from "../../../timezones/helpers/timezone";
import { FeatureComponent } from "../../../common/components/featureFlags/FeatureComponent";
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import { UserType, PermissionType } from "../../../auth/models/auth.enums";
import { ProtectedElement } from "../../../common/components/ProtectedElement";
import { isStaffRole } from "../../../common/constants/roles";
import { authQuery } from "../../../auth/stores/auth";
import { PaymentPlanHistoryModel, CallToActionModel, ActionStatus, ActionType } from "../../../patients/models/patient.model";
import { patientStore } from '../../../patients/stores/patientsStore';

interface ActiveMembershipCardComponentProps {
    subscription: SubscriptionModel;
    paymentPlanHistories: PaymentPlanHistoryModel[];
    menuAnchorEl?: HTMLButtonElement | null;
    standardUpSellActions?: CallToActionModel[];
    renew?: (subscription: SubscriptionModel) => void;
    cancel?: (subscription: SubscriptionModel) => void;
    handleToggleCancel?: () => void;
    handleGoBillingInfo?: () => void;
    handleGoCancellationForm?: () => void;
    handleModifyPlan?: () => void;
    handleToggleAction?: (menuAnchorEl: HTMLButtonElement) => void;
    handleToggleSuspend?: () => void;
    handleReactivePlan?: () => void;
}

export const ActiveMembershipCardComponent: React.FC<ActiveMembershipCardComponentProps> = (props: ActiveMembershipCardComponentProps) => {
    const { subscription, paymentPlanHistories, menuAnchorEl = null, standardUpSellActions = [], handleToggleCancel, handleGoBillingInfo, handleGoCancellationForm, handleModifyPlan, handleToggleAction, handleToggleSuspend, handleReactivePlan } = props;

    const classes = useStyles();

    const customTooltipClasses = {
        tooltip: classes.customWidth,
        arrow: classes.arrow,
        tooltipPlacementBottom: classes.tooltipPlacement,
        tooltipPlacementTop: classes.tooltipPlacement,
    };

    const isPrecisionCarePackagePatient = subscription?.paymentPlan?.name === 'PRECISION_CARE_PACKAGE';

    const sortingHistories = paymentPlanHistories.sort((h1, h2) => {
        return +new Date(h2.startDate) - +new Date(h1.startDate);
    })

    const paymentPriceDetailDisplay = () => {
        if (subscription?.paymentPrice?.paymentCoupon) {
            const detail = subscription.paymentPrice.paymentCoupon.detail ? ` (${subscription.paymentPrice.paymentCoupon.detail.replace('off', 'discount')})` : '';
            return detail;
        }
        return '';
    }

    const renderTitle = (subscription: SubscriptionModel) => {
        const isPausedSubscription = subscription.pauses && subscription.pauses.length > 0;
        if (isPausedSubscription) {
            const activePause = subscription.pauses.find(x => x.status === SubscriptionPauseStatus.Active)
            if (activePause) {
                return <Box className="wh-tw-text-xs wh-tw-text-bad wh-tw-uppercase">{`Currently Suspended Until ${moment(toCurrentTimeZone(activePause.endDate)).format('ll')}`}</Box>
            }

            const pendingPause = subscription.pauses.find(x => x.status === SubscriptionPauseStatus.Pending)
            if (pendingPause) {
                const duration = moment(toCurrentTimeZone(pendingPause.endDate)).diff(moment(toCurrentTimeZone(pendingPause.startDate)), 'months');
                return <Box className="wh-tw-text-xs wh-tw-text-bad wh-tw-uppercase">{`This membership will be paused for ${duration} ${duration > 1 ? 'months' : 'month'} on ${moment(toCurrentTimeZone(pendingPause.startDate)).format('ll')}.`}</Box>
            }
        }
        return <Box className="wh-tw-text-xs wh-tw-text-gray1 wh-tw-uppercase">Current Plan</Box>
    }

    const renderUpgradeSection = () => {
        if (standardUpSellActions.length > 0) {
            if (standardUpSellActions[0].status === ActionStatus.Completed) {
                return (
                    <Box mt={3} p="5px" className="wh-tw-bg-gray100 wh-tw-rounded wh-tw-w-fit">
                        <span className="wh-tw-text-xxs wh-tw-text-black"><span className="wh-tw-font-medium">Upgrade Requested.</span> A care coordinator will be in touch to assist with your upgrade.</span>
                    </Box>
                )
            }
            return (
                <Box mt={3}>
                    <Box display="flex" alignItems="center" gridGap={4} p="5px" className="wh-tw-bg-mint100 wh-tw-rounded wh-tw-w-fit">
                        <BellIcon />
                        <Box className="wh-tw-text-xs wh-tw-text-black wh-tw-font-medium">Limited Time Only, 50% Off</Box>
                    </Box>
                    
                    <Box mt="14px" width="fit-content">
                        <Link
                            id='upgrade-plan'
                            className="wh-tw-text-primaryV"
                            onClick={() => patientStore.toggleRequestDialog(true)}   
                        >
                            <Typography display='inline' paragraph className="wh-tw-font-bold">
                                <Box display="flex" alignItems="center">
                                    <span> Upgrade Plan </span>
                                </Box>
                            </Typography>
                        </Link>
                    </Box>
                </Box>
            )
        }
        return null;
    }

    return (
        (!subscription || moment(toCurrentTimeZone(sortingHistories && sortingHistories.length > 0 ? sortingHistories?.[0].startDate : subscription.startDate)).isAfter(moment())) ?
            <WildHealthPlaceHolder message="No Active Membership" /> :
            <>
            <Card className={classes.subscriptionCard} variant="outlined">
                <Box p={2}>
                    <CardContent>
                        <Box display="flex" justifyContent="space-between" mb="30px">
                            <Box>
                                {renderTitle(subscription)}
                                <Box mt="14px" className="wh-tw-text-2xl wh-tw-font-medium">{subscription.displayName}</Box>
                                <Box mt={1}>
                                    <span className="wh-tw-text-lg wh-tw-font-medium">$
                                        {subscription.price}
                                        <span className="wh-tw-text-base wh-tw-font-normal wh-tw-text-gray1">{paymentPriceDetailDisplay()}</span>
                                    </span>
                                </Box>
                            </Box>
                            <ProtectedElement
                                element={
                                    <Box display="flex" alignItems="center">
                                        <IconButton
                                            id={`action-membership`}
                                            onClick={(e) => handleToggleAction(e.currentTarget)}
                                        >
                                            <MoreHorizIcon />
                                        </IconButton>
                                    </Box>
                                }
                                permissions={[]}
                                userType={UserType.Employee}
                            />
                        </Box>
                        <Grid container>
                            <Grid item xs={12} md={3}>
                                <Box>
                                    <span className="wh-tw-text-xs wh-tw-text-gray1 wh-tw-uppercase">Start Date</span>
                                </Box>
                                <Box className={classes.dateContainer}>
                                    <span>{moment(toCurrentTimeZone(sortingHistories && sortingHistories.length > 0 ? sortingHistories?.[0].startDate : subscription.startDate)).format('ll')} </span>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <Box>
                                    <span className="wh-tw-text-xs wh-tw-text-gray1 wh-tw-uppercase">{subscription.cancellationRequest ? 'Cancellation' : isPrecisionCarePackagePatient ? 'Expiration' :  'Renewal'} Date</span>
                                </Box>
                                <Box className={classes.dateContainer}>
                                    <span className={classes.endDatePadding}>{moment(toCurrentTimeZone(subscription.cancellationRequest ? subscription.cancellationRequest?.date : subscription.endDate)).format('ll')}</span>
                                    <span className="wh-tw-text-xs wh-tw-text-gray1">{subscription.cancellationRequest ? `Your membership is scheduled to cancel on this date.` : isPrecisionCarePackagePatient ? `You have until this date to use your provider visit.` :  `Membership is scheduled to auto renew on this date.` }</span>
                                </Box>
                            </Grid>
                        </Grid>
                        {sortingHistories.length && sortingHistories?.[0].events.length > 0 && (
                            <>
                                <Divider className={classes.cardDivider} />
                                <Box width={1}>
                                    <Box mb={2.5} className="wh-tw-text-gray1 wh-tw-text-xs wh-tw-uppercase">Plan History</Box>
                                    <Grid container spacing={3}>
                                        {sortingHistories?.[0].events.map((event, index) => (
                                            <Grid item xs={12} sm={6} md={4} key={`history-event-${index}`}>
                                                <Box display="flex" justifyContent="space-between">
                                                    <Box>
                                                        <Box className="wh-tw-text-xs wh-tw-font-medium">{event.title}</Box>
                                                        <Box className="wh-tw-text-[10px] wh-tw-text-gray1">{moment(toCurrentTimeZone(event.createdAt)).format('ll')}</Box>
                                                    </Box>
                                                    <Box mx={2}><ChevronRightIcon className="wh-tw-text-gray2" /></Box>
                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                            </>
                        )}
                        {renderUpgradeSection()}
                        <Divider className={classes.cardDivider} />
                        <Box>
                            <Box className="wh-tw-text-xs wh-tw-text-gray1 wh-tw-uppercase">Billing info</Box>
                            <Box mt="14px" width="fit-content">
                                <Link
                                    id='view-billing-detail'
                                    className="wh-tw-text-primaryV"
                                    onClick={() => handleGoBillingInfo && handleGoBillingInfo()}   
                                >
                                    <Typography display='inline' paragraph className="wh-tw-font-bold">
                                        <Box display="flex" alignItems="center">
                                            <span> View your billing details </span>
                                            <LaunchIcon className={classes.icon} />
                                        </Box>
                                    </Typography>
                                </Link>
                            </Box>
                        </Box>
                        <Divider className={classes.cardDivider} />
                        <Box>
                            <FeatureComponent featureFlag={FeatureFlag.CancellationForm}>
                                <span className="wh-tw-text-xs wh-tw-text-gray1">If you wish to cancel your membership, please complete this <span className="wh-tw-text-xs wh-tw-text-primaryV wh-tw-font-medium wh-tw-cursor-pointer hover:wh-tw-underline" onClick={() => handleGoCancellationForm && handleGoCancellationForm()}>form</span></span>
                            </FeatureComponent>
                            <FeatureComponent notFeatureFlag={FeatureFlag.CancellationForm}>
                                <span className="wh-tw-text-xs wh-tw-text-gray1">If you wish to cancel your plan, please email us at <span className="wh-tw-text-xs wh-tw-text-primaryV wh-tw-font-medium">support@wildhealth.com</span></span>
                            </FeatureComponent>
                        </Box>
                    </CardContent>
                </Box>
            </Card>
            <Popover
                id="membershipActionPopover"
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={() => handleToggleAction(null)}
            >
                <ProtectedElement
                    element={
                        isStaffRole(authQuery.getRoleId()) && (
                            <WildHealthMenuItem
                                id="modify-plan"
                                onClick={() => handleModifyPlan && handleModifyPlan()}
                            >
                                <Typography variant="inherit" noWrap>
                                    Modify Plan
                                </Typography>
                            </WildHealthMenuItem>
                        )
                    }
                    permissions={[PermissionType.OpsAdminTools]}
                    userType={UserType.Employee}
                />
                {
                    handleToggleSuspend && subscription.isActive &&
                    <WildHealthMenuItem
                        id="pause-membership"
                        onClick={handleToggleSuspend}
                    >
                        <Typography variant="inherit" noWrap>
                            Suspend Plan
                        </Typography>
                        <Tooltip placement="top" arrow
                            title={<Box className={classes.notificationToolTipText}>This allows the plan to be suspended until a date the patient is ready to restart</Box>}
                            classes={customTooltipClasses}>
                            <Box className="wh-tw-ml-2.5"><InfoRoundedIcon className="wh-tw-text-gray2" /></Box>
                        </Tooltip>
                    </WildHealthMenuItem>
                }
                {
                    handleReactivePlan && subscription.status === SubscriptionStatus.Paused &&
                    <WildHealthMenuItem
                        id="reactive-membership"
                        onClick={handleReactivePlan}
                    >
                        <Typography variant="inherit" noWrap>
                            Reactive Plan
                        </Typography>
                    </WildHealthMenuItem>
                }
                {
                    handleToggleCancel && (subscription.isActive || subscription.status === SubscriptionStatus.Paused) &&
                    <WildHealthMenuItem
                        id="active-membership-cancel"
                        onClick={handleToggleCancel}
                    >
                        <Typography variant="inherit" noWrap>
                            Cancel Plan
                        </Typography>
                    </WildHealthMenuItem>
                }
            </Popover>
            </>
    );
}