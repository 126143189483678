import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
    createStyles({
        dialogTitle: {
            paddingTop: '30px !important',
            paddingLeft: '40px !important',
            paddingRight: '40px !important'
        },
        dialogContent: {
            paddingLeft: 32,
            paddingRight: 32,
            paddingBottom: 24,
        },
    })
);
