import { Box } from "@material-ui/core";
import React from "react";
import {useStyles} from "./BlockedItemComponent.styles";

export const BlockedItemComponent: React.FC = () => {
    const classes = useStyles();

    return(
        <Box className={classes.root} display="flex" alignItems='stretch'>
            <Box display="flex" justifyContent="center" alignItems="center" className={classes.label}>
                <Box style={{opacity: 1}}>
                    Unavailable
                </Box>
            </Box>
        </Box>
    );
}
