import React from 'react';
import { Box, IconButton, List, ListItem, Popover, useTheme, useMediaQuery } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import DescriptionIcon from '@material-ui/icons/Description';
import moment from 'moment';
import { CommonPlaceHolder } from '../../../common/components/wildHealthPlaceHolder/CommonPlaceHolder';
import { ScheduledMessage, FileName, useStyles } from "./scheduledMessagesComponent.styles";
import { useFacade } from "./scheduledMessagesComponent.hooks";
import { WildHealthMenuItem } from "../../../common/components/wildHealthMenu/WildHealthMenu";
import { ScheduledMessageModel, UploadedAttachmentModel } from "../../models/message.models";
import { toCurrentTimeZone } from '../../../timezones/helpers/timezone';


export const ScheduledMessagesComponent: React.FC = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const [
        {
            editingTarget,
            messages,
            menuAnchorEl,
        },
        handleToggleActions,
        handleEdit,
        handleDelete,
    ] = useFacade();

    const isEditing = Boolean(editingTarget);

    const renderAttachment = (attachment: UploadedAttachmentModel, index: number) => {
        return (
            <ListItem key={index} disableGutters classes={{ root: classes.root }}>
                <Box className={classes.document}>
                    <DescriptionIcon className={classes.fileIcon} />
                    <Box ml={1}>
                        <FileName primary={attachment.name} />
                    </Box>
                </Box>
            </ListItem>
        )
    }

    const renderMessage = (item: ScheduledMessageModel, index: number) => {
        const uploadedAttachs = item.uploadedAttachments ? item.uploadedAttachments.map(renderAttachment) : '';
        return (
            <ListItem key={index}>
                {!isSmallScreen &&
                    <IconButton
                        id="scheduled-messages-more-actions"
                        onClick={(e) => handleToggleActions(e.currentTarget, item.id)}
                        disabled={isEditing}
                        size='small'
                    >
                        <MoreHorizIcon />
                    </IconButton>
                }

                <Box ml={1} width='100%'>
                    <ScheduledMessage
                        primary={<>
                            <Box>{item.message}</Box>
                            <List classes={{ root: classes.root }}>{uploadedAttachs}</List>
                        </>}
                        secondary={`${moment(toCurrentTimeZone(item.timeToSend)).format('MMM DD, yyyy')} ${moment(toCurrentTimeZone(item.timeToSend)).format('HH:mm a')}`}
                        primaryTypographyProps={{ className: 'flat-scroll MuiListItemText-primary' }}
                    />
                </Box>
                {isSmallScreen &&
                    <IconButton
                        id="scheduled-messages-more-actions"
                        onClick={(e) => handleToggleActions(e.currentTarget, item.id)}
                        disabled={isEditing}
                        size='small'
                    >
                        <MoreHorizIcon />
                    </IconButton>
                }
            </ListItem>
        )
    }

    return (
        <Box>
            <Box display='flex' mx={2} mt={2} alignItems='center'>
                <DateRangeIcon className={classes.titleIcon} />
                <Box ml={1} className={classes.title}>{`Scheduled Messages (${messages?.length ?? 0})`}</Box>
            </Box>
            <List className="wh-tw-bg-white">
                {
                    messages && messages.length ? messages.map((group, index) => renderMessage(group, index)) :
                        <CommonPlaceHolder message='No scheduled messages.' />
                }
            </List>

            <Popover
                keepMounted
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={() => handleToggleActions()}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <WildHealthMenuItem id="scheduled-messages-edit" onClick={() => handleEdit()}>Edit</WildHealthMenuItem>
                <WildHealthMenuItem id="scheduled-messages-delete" onClick={() => handleDelete()}>Delete</WildHealthMenuItem>
            </Popover>
        </Box>
    )
}