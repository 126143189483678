import {
    HealthLogQuestionNames,
    InitialQuestionNames,
    QuestionnaireSection,
    QuestionType
} from '../../questionnaire/models/questionnaire.models';
import {Gender} from "../../common/models/user.models";

export class InitialConsultNames {
    static readonly TOP_3_HEALTH_PRIORITIES: string = 'TOP_3_HEALTH_PRIORITIES';
    static readonly CURRENT_MEDICAL_CONDITIONS: string = 'CURRENT_MEDICAL_CONDITIONS';
    static readonly HEALTH_FEARS: string = 'HEALTH_FEARS';
    static readonly SPECIFIC_DIET: string = 'SPECIFIC_DIET';

    static readonly MEAL_CONSUMING_TIME_1: string = 'MEAL_CONSUMING_TIME_1';
    static readonly MEAL_CONSUMING_TYPE_1: string = 'MEAL_CONSUMING_TYPE_1';
    static readonly MEAL_CONSUMING_TIME_2: string = 'MEAL_CONSUMING_TIME_2';
    static readonly MEAL_CONSUMING_TYPE_2: string = 'MEAL_CONSUMING_TYPE_2';
    static readonly MEAL_CONSUMING_TIME_3: string = 'MEAL_CONSUMING_TIME_3';
    static readonly MEAL_CONSUMING_TYPE_3: string = 'MEAL_CONSUMING_TYPE_3';
    static readonly LIST_OF_SNACKS: string = 'LIST_OF_SNACKS';
    static readonly STOP_CONSUMING_FOOD: string = 'STOP_CONSUMING_FOOD';

    static readonly EXERCISE_FREQUENCY: string = 'EXERCISE_FREQUENCY';
    static readonly AVAILABLE_EQUIPMENT: string = 'AVAILABLE_EQUIPMENT';
    static readonly EXERCISE_TYPES: string = 'EXERCISE_TYPES';

    static readonly ACTIVITIES_BEFORE_SLEEP: string = 'ACTIVITIES_BEFORE_SLEEP';
    static readonly FALL_ASLEEP_TIME: string = 'FALL_ASLEEP_TIME';
    static readonly WAKE_UP_TIME: string = 'WAKE_UP_TIME';
    static readonly USE_ALARM_CLOCK: string = 'USE_ALARM_CLOCK';
    static readonly FEEL_RESTED: string = 'FEEL_RESTED';
}

export interface NoteRecommendation {
    type: NoteRecommendationTypes;
    isSelected: boolean;
    name: string;
    content: string;
}

export enum NoteRecommendationTypes {
    Supplements,
    Nutrition,
    Exercise,
    SleepOrMindfulnessOrBreathwork
}

export const CheckManyToTextField = [
    InitialConsultNames.CURRENT_MEDICAL_CONDITIONS,
    InitialConsultNames.AVAILABLE_EQUIPMENT,
    InitialConsultNames.EXERCISE_TYPES,
]

export interface KeyMap {
    Key1: string;
    Key2: string;
}

export const InitialConsultToInitialQuestionNamesMap: KeyMap[] = [
    { Key1: InitialQuestionNames.HEALTH_PRIORITY, Key2: InitialConsultNames.TOP_3_HEALTH_PRIORITIES},
    { Key1: InitialQuestionNames.MEDICAL_CONDITIONS, Key2: InitialConsultNames.CURRENT_MEDICAL_CONDITIONS},
    { Key1: InitialQuestionNames.HEALTH_FEARS, Key2: InitialConsultNames.HEALTH_FEARS},
    { Key1: InitialQuestionNames.SPECIFIC_DIET, Key2: InitialConsultNames.SPECIFIC_DIET},
    { Key1: InitialQuestionNames.EXERCISE_PROGRAM_FOR, Key2: InitialConsultNames.AVAILABLE_EQUIPMENT},
    { Key1: InitialQuestionNames.EXERCISE_TYPE, Key2: InitialConsultNames.EXERCISE_TYPES },
    { Key1: InitialQuestionNames.STOP_CONSUMING_FOOD, Key2: InitialConsultNames.STOP_CONSUMING_FOOD },
];

export const InitialConsultToHealthLogQuestionNamesMap: KeyMap[] = [
    { Key1: HealthLogQuestionNames.FIRST_MEAL, Key2: InitialConsultNames.MEAL_CONSUMING_TYPE_1 },
    { Key1: HealthLogQuestionNames.FIRST_MEAL_TIME, Key2: InitialConsultNames.MEAL_CONSUMING_TIME_1 },
    { Key1: HealthLogQuestionNames.SECOND_MEAL, Key2: InitialConsultNames.MEAL_CONSUMING_TYPE_2 },
    { Key1: HealthLogQuestionNames.SECOND_MEAL_TIME, Key2: InitialConsultNames.MEAL_CONSUMING_TIME_2 },
    { Key1: HealthLogQuestionNames.LAST_MEAL, Key2: InitialConsultNames.MEAL_CONSUMING_TYPE_3 },
    { Key1: HealthLogQuestionNames.LAST_MEAL_TIME, Key2: InitialConsultNames.MEAL_CONSUMING_TIME_3 },
    { Key1: HealthLogQuestionNames.LIST_OF_SNACKS, Key2: InitialConsultNames.LIST_OF_SNACKS },
    { Key1: HealthLogQuestionNames.WEEKS_EXERCISE, Key2: InitialConsultNames.EXERCISE_FREQUENCY },
    { Key1: HealthLogQuestionNames.ACTIVITIES_LIST, Key2: InitialConsultNames.ACTIVITIES_BEFORE_SLEEP },
    { Key1: HealthLogQuestionNames.FALL_ASLEEP_TIME, Key2: InitialConsultNames.FALL_ASLEEP_TIME },
    { Key1: HealthLogQuestionNames.WAKE_UP_TIME, Key2: InitialConsultNames.WAKE_UP_TIME },
    { Key1: HealthLogQuestionNames.WAKE_UP_WITH_ALARM, Key2: InitialConsultNames.USE_ALARM_CLOCK },
    { Key1: HealthLogQuestionNames.FEEL_IN_THE_MORNING, Key2: InitialConsultNames.FEEL_RESTED },
];

export function getInitQuestionnaires(): QuestionnaireSection[] {
    return [
        {
            name: '',
            questions: [
                {
                    name: InitialConsultNames.TOP_3_HEALTH_PRIORITIES,
                    questionSubject: '',
                    displayName: 'What are your top 3 health priorities?',
                    description: '',
                    type: QuestionType.CheckMany,
                    allowOther: true,
                    variants: [
                        'Health Optimization',
                        'Longevity and Healthy Aging' ,
                        'Disease Treatment and Prevention',
                        'Mental Cognition and Brain Health',
                        'Sport and Athletic Performance',
                        'Diet and Nutrition Optimization',
                        'Improving Sleep and Proper Recovery',
                        'Specific Medical Concern',
                    ],
                    default: '',
                    childrenNames: [],
                    parentAnswers: [],
                    gender: Gender.None
                },
                {
                    name: InitialConsultNames.SPECIFIC_DIET,
                    questionSubject: '',
                    displayName: 'Are you on a specific kind of diet?',
                    description: '',
                    type: QuestionType.SelectOne,
                    variants: [
                        'None',
                        'Vegan',
                        'Vegetarian',
                        'Pescatarian',
                        'Mediterranean',
                        'Whole Food Plant Based',
                        'Gluten Free',
                        'Paleo',
                        'Keto',
                    ],
                    default: 'None',
                    childrenNames: [],
                    parentAnswers: [],
                    gender: Gender.None
                },
            ]
        },
        {
            name: 'Nutrition',
            questions: [
                {
                    name: InitialConsultNames.MEAL_CONSUMING_TIME_1,
                    questionSubject: '',
                    displayName: 'When did you eat your first meal?',
                    description: '',
                    type: QuestionType.TimeInput,
                    default: '',
                    childrenNames: [],
                    parentAnswers: [],
                    gender: Gender.None
                },
                {
                    name: InitialConsultNames.MEAL_CONSUMING_TYPE_1,
                    questionSubject: '',
                    displayName: 'What did you eat?',
                    description: '',
                    type: QuestionType.TextInput,
                    default: '',
                    childrenNames: [],
                    parentAnswers: [],
                    gender: Gender.None
                },
                {
                    name: InitialConsultNames.MEAL_CONSUMING_TIME_2,
                    questionSubject: '',
                    displayName: 'When did you eat your next meal?',
                    description: '',
                    type: QuestionType.TimeInput,
                    default: '',
                    childrenNames: [],
                    parentAnswers: [],
                    gender: Gender.None
                },
                {
                    name: InitialConsultNames.MEAL_CONSUMING_TYPE_2,
                    questionSubject: '',
                    displayName: 'What did you eat?',
                    description: '',
                    type: QuestionType.TextInput,
                    default: '',
                    childrenNames: [],
                    parentAnswers: [],
                    gender: Gender.None
                },
                {
                    name: InitialConsultNames.MEAL_CONSUMING_TIME_3,
                    questionSubject: '',
                    displayName: 'When did you eat your next meal?',
                    description: '',
                    type: QuestionType.TimeInput,
                    default: '',
                    childrenNames: [],
                    parentAnswers: [],
                    gender: Gender.None
                },
                {
                    name: InitialConsultNames.MEAL_CONSUMING_TYPE_3,
                    questionSubject: '',
                    displayName: 'What did you eat?',
                    description: '',
                    type: QuestionType.TextInput,
                    default:'',
                    childrenNames: [],
                    parentAnswers: [],
                    gender: Gender.None
                },
                {
                    name: InitialConsultNames.LIST_OF_SNACKS,
                    questionSubject: '',
                    displayName: 'List any snacks you typically have throughout the day:',
                    description: '',
                    type: QuestionType.TextInput,
                    default: '',
                    childrenNames: [],
                    parentAnswers: [],
                    gender: Gender.None
                },
                {
                    name: InitialConsultNames.STOP_CONSUMING_FOOD,
                    questionSubject: '',
                    displayName: 'What time do you usually stop consuming any food for the day?',
                    description: '',
                    type: QuestionType.TimeInput,
                    default: '',
                    childrenNames: [],
                    parentAnswers: [],
                    gender: Gender.None
                },
            ]
        },
        {
            name: 'Exercise',
            questions: [
                {
                    name: InitialConsultNames.EXERCISE_FREQUENCY,
                    questionSubject: '',
                    displayName: 'On average, how many days per week are you sticking to your current exercise plan?',
                    description: '',
                    variants: ['0', '1', '2', '3', '4', '5', '6', '7'],
                    type: QuestionType.SelectOne,
                    default: '0',
                    childrenNames: [],
                    parentAnswers: [],
                    gender: Gender.None
                }
            ]
        },
        {
            name: 'Sleep',
            questions: [
                {
                    name: InitialConsultNames.ACTIVITIES_BEFORE_SLEEP,
                    questionSubject: '',
                    displayName: 'List the activities you perform during the last hour before falling asleep for the night.',
                    description: '',
                    type: QuestionType.TextInput,
                    default: '',
                    childrenNames: [],
                    parentAnswers: [],
                    gender: Gender.None
                },
                {
                    name: InitialConsultNames.FALL_ASLEEP_TIME,
                    questionSubject: '',
                    displayName: 'What time are you typically in bed trying to fall asleep for the night?',
                    description: '',
                    type: QuestionType.TimeInput,
                    default: '',
                    childrenNames: [],
                    parentAnswers: [],
                    gender: Gender.None
                },
                {
                    name: InitialConsultNames.WAKE_UP_TIME,
                    questionSubject: '',
                    displayName: 'What time do you usually wake up?',
                    description: '',
                    type: QuestionType.TimeInput,
                    default: '',
                    childrenNames: [],
                    parentAnswers: [],
                    gender: Gender.None
                },
                {
                    name: InitialConsultNames.USE_ALARM_CLOCK,
                    questionSubject: '',
                    displayName: 'Do you typically wake up with an alarm clock or not?',
                    description: '',
                    variants: ['Yes', 'No'],
                    type: QuestionType.SelectOne,
                    default: '',
                    childrenNames: [],
                    parentAnswers: [],
                    gender: Gender.None
                },
                {
                    name: InitialConsultNames.FEEL_RESTED,
                    questionSubject: '',
                    displayName: 'On a scale of 0-10, how rested/ready to take on the day do you feel when you wake up?',
                    description: '',
                    type: QuestionType.SelectOne,
                    default: '0',
                    variants: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
                    childrenNames: [],
                    parentAnswers: [],
                    gender: Gender.None
                }
            ]
        },
    ];
}