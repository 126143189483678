import { TextField } from "@material-ui/core";
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontWeight: 500
        },
        subtitle: {
            fontSize: 12,
            color: colors.gray1,
            textTransform: 'uppercase'
        },
        item: {
            padding: '6.5px 12px 6.5px 12px',
            backgroundColor: colors.separators,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRadius: '2px'
        },
        editIcon: {
            color: colors.main,
            cursor: 'pointer',
            marginLeft: '2rem'
        },
        addIcon: {
            backgroundColor: colors.separators,
            color: colors.main,
            cursor: 'pointer',
        },
        addIcon2: {
            color: colors.main,
            cursor: 'pointer',
        },
        deleteIcon: {
            color: colors.bad,
            cursor: 'pointer',
        },
        addText: {
            color: colors.main
        },
        searchBase: {
            padding: '9px 7px 9px 10px',
            fontFamily: 'Be Vietnam Pro',
            fontSize: 14,
        },
        iconCheck: {
            fill: colors.good,
        },
        connectedLine: {
            position: 'absolute',
            height: 30,
            width: 30,
            marginLeft: '12px',
            borderLeft: `1px solid ${colors.gray2}`,
            borderBottom: `1px solid ${colors.gray2}`,
            borderRadius: '6px',
            borderTopLeftRadius: 0,
        }
    })
);

export const SearchTextField = withStyles({
    root: {
        '& label.Mui-focused': {
        },

        '& .MuiInput-underline:after': {
            borderBottomColor: colors.gray4,
        },
        '& .MuiOutlinedInput-adornedStart': {
            height: '34px'
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: colors.gray4,
            },
            '&:hover fieldset': {
                borderColor: colors.gray4,
            },
            '&.Mui-focused fieldset': {
                borderColor: colors.gray4,
            },
        },
    },
})(TextField);