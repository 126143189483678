import React from 'react';
import { AuthenticatedLayoutComponent } from "../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent";
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from "../../../../models/menu.models";
import { FullHealthCareChatComponent } from "../../components/fullHealthCareChatComponent/FullHealthCareChatComponent";
import { FullStaffChatComponent } from "../../components/fullStaffChatComponent/FullStaffChatComponent";
import {ConversationType} from "../../models/conversation.models";

export const PatientChatPage: React.FC = (props: any) => {
    const conversationType = Number(props.match.params.threadId);

    const chats = new Map<ConversationType, React.FC>([
        [ConversationType.HealthCare, FullHealthCareChatComponent],
        [ConversationType.Support, FullStaffChatComponent]
    ]);

    const chat = chats.get(conversationType) ?? FullHealthCareChatComponent;

    return (
        <AuthenticatedLayoutComponent
            hideBackground
            displayChildren='flex'
            title={TitlesService.chatTitle}
            pageName={PagesNamesService.messaging}
            selectedMenuItem={MenuItemTypes.Messaging}
        >
            {React.createElement(chat)}
        </AuthenticatedLayoutComponent>
    )
};
