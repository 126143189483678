import {Query} from '@datorama/akita';
import {
    PatientConversationsState, patientConversationsStore,
    PatientConversationsStore
} from "./patientConversations.store";
import { ConversationAuthorModel } from "../../models/conversation.models";

/**
 * Provides patient messages queries
 */
export class PatientConversationsQuery extends Query<PatientConversationsState> {

    author$ = this.select(state => state.author);

    supportConversations$ = this.select(state => state.supportConversations);

    heathCareConversation$ = this.select(state => state.heathCareConversation);

    targetConversation$ = this.select(state => state.targetConversation);

    targetStaffConversation$ = this.select(state => state.targetStaffConversation);

    targetHealthcareConversation$ = this.select(state => state.targetHealthcareConversation);

    targetInternalConversation$ = this.select(state => state.targetInternalConversation);

    isLoading$ = this.select(state => state.isLoading);

    constructor(protected store: PatientConversationsStore) {
        super(store);
    }

    public getAuthor(): ConversationAuthorModel {
        return this.getValue().author;
    }

    public getSendNewMessageStatus(): boolean {
        return this.getValue().isSendForwardMessage;
    }
}

export const patientConversationsQuery = new PatientConversationsQuery(patientConversationsStore);