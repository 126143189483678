import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Subscription } from "recompose";
import { NoteModel } from '../../models/notes.models';
import { notesService } from '../../services/notes.service';
import { onEmit } from '../../../common/helpers/on-emit';
import { notesQuery } from '../../stores/notes';


interface PatientInitialConsultPageState {
    isLoading: boolean;
    note: NoteModel;
}

export function useFacade(noteId: number): [PatientInitialConsultPageState] {
    const [state, setState] = useState({
        isLoading: true,
        note: notesQuery.getNote(noteId)
    } as PatientInitialConsultPageState);

    const history = useHistory();

    /**
     * Load all ongoing cares
     * Manage subscriptions with auto-cleanup
     */
    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<NoteModel[]>(notesQuery.notes$, () => {
                const note = notesQuery.getNote(noteId);
                if (note) {
                    setState(state => ({...state, isLoading: false, note: note}));
                }
            }),
        ];

        if (!state.note) {
            notesService.getByPatient().subscribe();
        }

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, [noteId]);

    return [state];
}