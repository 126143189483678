import {VitalModel} from "../models/vital.model";

export const getUniqDates = (items: VitalModel[]) => {
    const matrix: Array<Array<number>> = [];
    const dates: Array<number> = [];

    if (items.length) {
        items.forEach(item => {
            const vitalDates: Array<number> = [];
            item.values.forEach(x => vitalDates.push(new Date(x.date).getTime()));
            matrix.push(vitalDates);
        });

        matrix.forEach(arr => {
            arr.forEach(d => {
                if (!dates.includes(d)) {
                    dates.push(d);
                } else {
                    const duplicateCount = arr.filter(x => x === d).length;
                    const actualCount = dates.filter(x => x === d).length;

                    for(let n = 0; n < duplicateCount - actualCount; n++) {
                        dates.push(d);
                    }
                }
            })
        })

        return dates.sort((a, b) => a - b).reverse();
    }

    return dates;
}