import { Box, IconButton, Grid } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { AttachFileModel, UploadedAttachmentModel } from '../../models/message.models';
import { useFacade } from './editScheduledMessage.hooks';
import { ConfirmButton, DiscardButton, InputField, useStyles } from './editScheduledMessage.styles';
import DescriptionIcon from '@material-ui/icons/Description';
import clsx from 'clsx';

interface EditScheduledMessageProps {
    message: string;
    attachments: UploadedAttachmentModel[];
    attachmentsNew: AttachFileModel[];
    handleConfirm: (message: string, removeAttachmentIds: number[], attachments: UploadedAttachmentModel[]) => void;
    handleDiscard: () => void;
    handleRemoveAttachmentNew: (index: number) => void,
}

export const EditScheduledMessage: React.FC<EditScheduledMessageProps> = (props: EditScheduledMessageProps) => {
    const {
        message: initMessage,
        attachments: initAttachments,
        attachmentsNew,
        handleConfirm,
        handleDiscard,
        handleRemoveAttachmentNew,
    } = props;

    const classes = useStyles();

    const [
        {
            message,
            attachments,
            removeAttachments,
        },
        handleKeyDown,
        handleChangeMessage,
        handleRemoveAttachment,
    ] = useFacade(initMessage, initAttachments, handleConfirm);

    return (
        <div className={classes.root}>
            <Box className={classes.message}>
                <InputField
                    fullWidth
                    multiline
                    rowsMax={8}
                    value={message}
                    variant="outlined"
                    inputProps={{ className: 'flat-scroll outline-none' }}
                    placeholder='Type message here...'
                    onKeyDown={(e) => handleKeyDown(e, message)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeMessage(e.target.value)}
                />
                <Grid container className={clsx(classes.containerAttachments, "flat-scroll")} >
                    {
                        attachmentsNew && attachmentsNew.length ?
                            attachmentsNew.map((item, index) =>
                                <Box key={index} className={classes.attachment} ml={1} mb={1} px={1}>
                                    <DescriptionIcon className={classes.attachmentIcon} />
                                    <Box className={classes.attachmentTitle} ml={1}>{item.file.name}</Box>
                                    <IconButton id={`send-message-remove-attach-${index}`} className={classes.removeAttachment} size='small' onClick={() => handleRemoveAttachmentNew(index)}>
                                        <CloseIcon style={{ fontSize: 14 }} />
                                    </IconButton>
                                </Box>
                            ) : <></>
                    }
                    {
                        attachments && attachments.length ?
                            attachments.map((item, index) =>
                                <Box key={index} className={classes.attachment} ml={1} mb={1} px={1}>
                                    <DescriptionIcon className={classes.attachmentIcon} />
                                    <Box className={classes.attachmentTitle} ml={1}>{item.name}</Box>
                                    <IconButton id={`send-message-remove-attach-${index}`} className={classes.removeAttachment} size='small' onClick={() => handleRemoveAttachment(item.id, item.name)}>
                                        <CloseIcon style={{ fontSize: 14 }} />
                                    </IconButton>
                                </Box>
                            ) : <></>
                    }
                </Grid>
            </Box>

            <Box
                m={1}
                display='flex'
                alignItems='center'
            >
                <Box m={1}>
                    <DiscardButton id="edit-scheduled-close" onClick={() => handleDiscard()}>
                        <CloseIcon className={classes.icon} />
                    </DiscardButton>
                </Box>
                <Box m={1}>
                    <ConfirmButton
                        id="edit-scheduled-confirm"
                        onClick={() => handleConfirm(message.trim(), removeAttachments, attachments)}
                        disabled={message.trim().length < 1}
                    >
                        <CheckIcon className={classes.icon} />
                    </ConfirmButton>
                </Box>
            </Box>
        </div>
    )
};
