import {
    CoachRescheduleAppointmentDialogComponent
} from "../../components/coachRescheduleAppointmentDialog/CoachRescheduleAppointmentDialogComponent";
import {
    CoachCreateAppointmentDialogComponent
} from "../../components/coachCreateAppoinmentDialog/CoachCreateAppoinmentDialogComponent";
import {
    CoachAppointmentsCalendarComponent
} from "../../components/coachAppointmentsCalendar/CoachAppointmentsCalendarComponent";
import {
    AuthenticatedLayoutComponent
} from "../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent";
import {WildHealthButton} from "../../../common/components/wildHealthButton/WildHealthButton";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import {PagesNamesService} from "../../../../constants/pagesNames.constants";
import {TitlesService} from "../../../../constants/title.constants";
import {MenuItemTypes} from "../../../../models/menu.models";
import {useStyles} from "./CoachAppointmentsPage.styles";
import {useFacade} from "./coachAppointmentPage.hooks";
import {Box} from "@material-ui/core";
import {withRouter} from "react-router";
import React from "react";

import {SelectEmployeeWithSearchComponent} from "../../components/selectEmployeeWithSearch/selectEmployeeWithSearch";
import {
    AppointmentsStatisticComponent
} from "../../components/appointmentsStatisticComponent/AppointmentsStatisticComponent";
import {FeatureComponent} from "../../../common/components/featureFlags/FeatureComponent";
import {FeatureFlag} from "../../../common/components/featureFlags/featureFlags.models";

export const CoachAppointmentsPage: React.FC = () => {
    const [
        {
            employeesFiltered,
            employees,
            selectedEmployee,
            me,
            timeZone,
            isOpen,
            anchorEl,
            isStatisticLoading
        },
        handleEmployeeChange,
        handleOpenCreateDialog,
        handleFiltering,
        handleToggle,
        handleToggleStatisticLoading
    ] = useFacade();

    const classes = useStyles();

    const renderContent = () => {
        if (!employees || !selectedEmployee) {
            return <WildHealthLinearProgress />
        }

        return (
            <Box className={classes.root}>
                <CoachCreateAppointmentDialogComponent />
                <CoachRescheduleAppointmentDialogComponent />
                <FeatureComponent featureFlag={FeatureFlag.AppointmentsStatistic}>
                    <Box pb={2}>
                        <AppointmentsStatisticComponent employeeId={selectedEmployee.id} isLoading={isStatisticLoading} />
                    </Box>
                </FeatureComponent>
                <Box>
                    <CoachAppointmentsCalendarComponent
                        employeeId={selectedEmployee.id}
                        timeZone={timeZone}
                        selectEmployee={
                            <SelectEmployeeWithSearchComponent
                                me={me}
                                isOpen={isOpen}
                                anchorEl={anchorEl}
                                selectedEmployee={selectedEmployee}
                                employees={employeesFiltered}
                                handleToggle={handleToggle}
                                onEmployeeChange={handleEmployeeChange}
                                handleFiltering={handleFiltering}

                            />
                        }
                        newAppointment={
                            <WildHealthButton
                                id="coach-appointments-new"
                                size="medium"
                                onClick={() => handleOpenCreateDialog()}>
                                New Appointment
                            </WildHealthButton>
                        }
                        handleToggleStatisticLoading={handleToggleStatisticLoading}
                    />
                </Box>
            </Box>
        )
    }

    return (
        <>
            <AuthenticatedLayoutComponent
                title={TitlesService.appointmentsTitle}
                pageName={PagesNamesService.appointments}
                selectedMenuItem={MenuItemTypes.Appointments}
            >
                {renderContent()}
            </AuthenticatedLayoutComponent>
        </>
    );
}

export default withRouter(CoachAppointmentsPage);