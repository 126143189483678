import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../../constants/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontWeight: 500
        },
        container: {
            marginRight: '10px',
            marginTop: '40px',
            marginBottom: '10px',
            display: 'flex'
        },
        subtitle: {
            fontSize: 12,
            color: colors.gray1,
            textTransform: 'uppercase'
        },
        item: {
            padding: '6.5px 12px 6.5px 12px',
            backgroundColor: colors.separators,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRadius: '2px'
        },
        editIcon: {
            color: colors.main,
            cursor: 'pointer',
            marginLeft: '2rem'
        },
        addIcon: {
            backgroundColor: colors.separators,
            color: colors.main,
            cursor: 'pointer',
        },
        deleteIcon: {
            color: colors.bad,
            cursor: 'pointer',
        },
        addText: {
            color: colors.main
        }
    })
);
