import React from 'react';
import { Box, FormControl, MenuItem, Select, TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { useFacade } from "./updateDocumentSourceDialog.hooks";
import CheckIcon from '@material-ui/icons/Check';
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { useStyles } from "../uploadDocumentSourceDialog/uploadDocumentSourceDialog.styles";
import { DescriptionComponentForTag } from "../uploadDocumentSourceDialog/DescriptionComponentForTag";
import clsx from 'clsx';
import { HealthCategoryTagNames } from '../../models/documentSource.model';
import { getNumericKeys } from '../../../common/helpers/enum-mapper';

export const UpdateDocumentSourceDialog: React.FC = () => {
    const [
        {
            isOpen,
            updateDocumentSource,
            errors
        },
        handleSubmit,
        handleClose,
        handleChanges
    ] = useFacade();

    const classes = useStyles();

    return (
        <>
            <Dialog maxWidth="md" open={isOpen} onClose={() => handleClose()} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    <span className="wh-tw-text-2xl wh-tw-font-medium">Update Document Source</span>
                </DialogTitle>
                <DialogContent>
                    <Box pb={1} width="700px">
                        <Box>
                            <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Name *</Box>
                            <TextField
                                id={`name`}
                                fullWidth
                                required
                                size="small"
                                InputProps={{ className: 'input', classes }}
                                variant="outlined"
                                value={updateDocumentSource.name}
                                error={!!errors['name']}
                                helperText={errors['name']}
                                disabled={!isOpen}
                                onChange={(event) => handleChanges(event.target.id, event.target.value)}
                            />
                        </Box>
                        <Box mt={2}>
                            <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Tags</Box>
                            <FormControl
                                variant="outlined"
                                color='primary'
                                required
                                error={!!errors[`tags`]}
                                size="small"
                                disabled={!isOpen}
                                classes={{ root: classes.root }}
                                fullWidth
                            >
                                <Select
                                    style={{ background: '#FFF' }}
                                    id="tags"
                                    classes={{ root: classes.root }}
                                    value={updateDocumentSource.tags}
                                    inputProps={{
                                        id: 'type-label',
                                        classes,
                                    }}
                                    renderValue={() => {
                                    if (!updateDocumentSource.tags || !updateDocumentSource.tags.length) {
                                        return <Box className="wh-tw-text-mediumGray">Please select tag</Box>;
                                    }

                                    return updateDocumentSource.tags.length === 1 ? `${updateDocumentSource.tags.length} tag selected` : `${updateDocumentSource.tags.length} tags selected`
                                    }}
                                    displayEmpty
                                    onChange={(v) => handleChanges("tags", v.target.value)}>
                                    {
                                        getNumericKeys(HealthCategoryTagNames).map((value, i) => {
                                            return <MenuItem key={i} value={value}>
                                                <Box display="flex" alignItems="center" justifyContent="space-between" width={1}>
                                                <Box className={clsx("wh-tw-text-sm wh-tw-font-medium", updateDocumentSource.tags.includes(value) ? "wh-tw-text-primaryV" : "wh-tw-text-black")}>{HealthCategoryTagNames[value]}</Box>
                                                {updateDocumentSource.tags.includes(value) && <CheckIcon className="wh-tw-text-primaryV" />}
                                            </Box>
                                            </MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                            <DescriptionComponentForTag />
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions className="wh-tw-pb-4">
                    <WildHealthButton
                        id="update-document-source-cancel"
                        color="secondary"
                        size="medium"
                        onClick={() => handleClose()}
                    >
                        Cancel
                    </WildHealthButton>
                    <Box pr={2}>
                        <WildHealthButton
                            id="update-document-source-submit"
                            size="medium"
                            onClick={() => handleSubmit()}
                        >
                            Upload
                        </WildHealthButton>
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    )
}