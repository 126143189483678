import {NotificationModel, NotificationType} from "../../../notifications/models/notifications.models";
import {useEffect, useState} from "react";
import {Subscription} from "rxjs";
import {onEmit} from "../../../common/helpers/on-emit";
import {notificationsQuery} from "../../../notifications/stores/notifications";
import { EmployeeConversationModel } from "../../models/conversation.models";
import { employeeConversationsQuery } from "../../stores/employeeConversationsStore/employeeConversations.query";

const interestedTypes = [
    NotificationType.NewSupportTicket,
];

interface ConversationsBadgeComponentState {
    notifications: NotificationModel[];
    conversations: EmployeeConversationModel[];
}

let context: ConversationsBadgeComponentState = null;

export function useFacade(): [boolean] {
    const [newMessagesAndTickets, setNewMessagesAndTickets] = useState(false);
    const [state, setState] = useState({
        notifications: [],
        conversations: []
    } as ConversationsBadgeComponentState);

    context = state;

    const hasNewMessagesAndTickets = (notifications: NotificationModel[], conversations: EmployeeConversationModel[]): boolean => {
        return hasNewMessages(conversations) || hasNewTickets(notifications);
    }

    const hasNewTickets = (notifications: NotificationModel[]): boolean => {
        notifications = notifications.filter(x => interestedTypes.includes(x.type));

        return notifications.length > 0;
    }

    const hasNewMessages = (conversations: EmployeeConversationModel[]): boolean => {
        conversations = conversations.filter(x => x.unreadMessages > 0);

        return conversations.length > 0;
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<NotificationModel[]>(notificationsQuery.notifications$, notifications => {
                setState(state => ({
                    ...state,
                    notifications: notifications
                }));

                setNewMessagesAndTickets(() => (hasNewMessagesAndTickets(notifications, context.conversations)));
            }),
            onEmit<EmployeeConversationModel[]>(employeeConversationsQuery.conversations$, conversations => {
                setState(state => ({
                    ...state,
                    conversations: conversations
                }));

                setNewMessagesAndTickets(() => (hasNewMessagesAndTickets(context.notifications, conversations)));
            })
        ];

        return (() => {
            subscriptions.map(it => it.unsubscribe())
        })
    }, []);

    return [newMessagesAndTickets];
}