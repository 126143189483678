import React from 'react';
import { useLocation } from "react-router-dom";
import { CssBaseline, Box } from '@material-ui/core';
import { useStyles } from './layoutComponent.styles';
import { FooterComponent, FooterRedesignComponent } from "../footerComponent/FooterComponent";
import { headerService } from '../../services/header.service';
import { RoutesConstants } from '../../constants/route.constants';

   interface LayoutComponentProps {
    title: string;
    hideMenu?: boolean;
    isLicensingSignup?:boolean;
    employerKey?: boolean;
    children: React.ReactNode;
    redesign?: boolean;
    topBannerHeight?: number;
}

export const LayoutComponent: React.FC<LayoutComponentProps> = (props: LayoutComponentProps) => {
    const { title, hideMenu, children, isLicensingSignup, employerKey, redesign = false, topBannerHeight = 0 } = props;
    const classes = useStyles();

    const { pathname } = useLocation();
    const mobilePath = pathname.toLowerCase().includes(RoutesConstants.mobileGetStarted) || pathname.toLowerCase().includes(RoutesConstants.mobilePaymentSuccess);

    return (
        <Box position="relative">
            <div key={title} className={classes.root}>
                {<title>{title}</title>}
                {!mobilePath && headerService.getHeader(employerKey, hideMenu, isLicensingSignup, topBannerHeight)}
                <CssBaseline />
                <main className={classes.content}>
                    {!mobilePath && headerService.getHeaderToolBar(isLicensingSignup)}
                    <div className={classes.children}>
                        {children}
                    </div>
                </main>
            </div>
            {redesign ? <FooterRedesignComponent position="static" /> : <FooterComponent position="static" />}
        </Box>
    );
}
