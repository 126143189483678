import React from "react";
import { Box, Divider, TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { useFacade } from "./coachRescheduleAppointmentDialogComponent.hooks";
import { AppointmentPurposeType, AppointmentTargetType, AppointmentWithType } from '../../models/appointments.enums';
import clsx from 'clsx';
import { SelectLocationComponent } from "../selectLocation/SelectLocationComponent";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { SelectDateTimeComponent } from "../selectDateTime/SelectDateTimeComponent";
import commonUseStyles from "../../../common/styles/common.styles";
import { SelectAppointmentWithTypeComponent } from "../selectAppointmentWithType/SelectAppointmentWithTypeComponent";
import { isCoachRole, isProviderRole, roles } from "../../../common/constants/roles";
import { SelectEmployeeComponent } from "../selectEmployee/SelectEmployeeComponent";
import { useStyles } from "./CoachRescheduleAppointmentDialogComponent.styles";
import { DialogTitle } from "../../../common/components/dialogTitle/DialogTitle";
import { getTimeZoneName } from "../../../timezones/helpers/timezone";
import { FeatureComponent } from "../../../common/components/featureFlags/FeatureComponent";
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import { AppointmentReasonComponent } from "../appointmentReason/AppointmentReasonComponent";

export const CoachRescheduleAppointmentDialogComponent: React.FC = () => {
    const [
        state,
        handleChangeDate,
        handleChangeTime,
        handleSubmit,
        handleClose,
        getAvailability,
        handleChangeReason,
        handleSelectReason
    ] = useFacade();

    const commonClasses = commonUseStyles();
    const classes = useStyles();

    if (!state.appointment) {
        return (<></>);
    }

    const targetType = state.appointment.purpose === AppointmentPurposeType.Other && state.appointment.withType === AppointmentWithType.Other ? AppointmentTargetType.Other : AppointmentTargetType.Patient;

    const selectedCoach = state.appointment.employees.find(x => isCoachRole(x.roleId));
    const selectedProvider = state.appointment.employees.find(x => isProviderRole(x.roleId));
    const selectedStaff = state.appointment.employees.find(x => x.roleId === roles.staff);

    return (
        <>
            <Dialog maxWidth="md" open={state.open} onClose={() => handleClose()}>
                <DialogTitle
                    id="customized-dialog-title"
                    classes={{ root: classes.dialogTitle }}
                    onClose={() => handleClose()}
                >
                    <span className={clsx(commonClasses.size24, commonClasses.textMedium)}>
                        Rescheduling Appointment
                    </span>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Box width='548px'>

                        <Box my={2}>
                            <Box>
                                <span className={clsx(commonClasses.size14, commonClasses.colorGray1)}>{state.appointment.purpose === AppointmentPurposeType.Other ? 'Appointment Name' : 'Appointment Type'}</span>
                            </Box>
                            <Box mt={1}>
                                <TextField
                                    disabled
                                    id='name'
                                    required
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    value={state.appointment.name}

                                />
                            </Box>
                        </Box>

                        {
                            targetType === AppointmentTargetType.Patient &&
                            <Box my={2}>
                                <SelectLocationComponent
                                    location={state.appointment.location}
                                    selectedLocationType={state.appointment.locationType}
                                    handleChangeLocationType={() => { }}
                                    phoneNumber={state.appointment.patient.phone}
                                    disabled={true}
                                />
                            </Box>
                        }
                        
                        {state.appointmentType?.requiresReasonType && (
                            <FeatureComponent featureFlag={FeatureFlag.AppointmentReasons}>
                                <Divider />
                                <Box my={2}>
                                    <AppointmentReasonComponent
                                        reason={state.appointment.reason}
                                        selectedReasonType={state.selectedReasonType}
                                        errors={state.errors}
                                        handleChangeReason={handleChangeReason}
                                        handleSelectReason={handleSelectReason}
                                    />
                                </Box>
                            </FeatureComponent>
                        )}
                        {
                            targetType === AppointmentTargetType.Patient &&
                            <>
                                <Divider />

                                <Box my={2}>
                                    <SelectAppointmentWithTypeComponent
                                        handleChange={() => { }}
                                        availableTypes={[state.appointment.withType]}
                                        value={state.appointment.withType}
                                        disabled={true}
                                    />
                                </Box>
                                <Box display='flex' my={2} whiteSpace>
                                    {
                                        (state.appointment.withType === AppointmentWithType.HealthCoach ||
                                            state.appointment.withType === AppointmentWithType.HealthCoachAndProvider) &&
                                        <Box width={1}>
                                            <Box>
                                                <span className={clsx(commonClasses.size14, commonClasses.colorGray1)}>Select Health Coach</span>
                                            </Box>
                                            <Box mt={1} width={1}>
                                                <SelectEmployeeComponent
                                                    selectedEmployee={selectedCoach}
                                                    employees={state.appointment.employees}
                                                    onEmployeeChange={() => { }}
                                                    disabled={true}
                                                />
                                            </Box>
                                        </Box>
                                    }
                                    {
                                        state.appointment.withType === AppointmentWithType.HealthCoachAndProvider && <Box width='20px' />
                                    }
                                    {
                                        (state.appointment.withType === AppointmentWithType.Provider ||
                                            state.appointment.withType === AppointmentWithType.HealthCoachAndProvider) &&
                                        <Box width={1}>
                                            <Box>
                                                <span className={clsx(commonClasses.size14, commonClasses.colorGray1)}>Select Provider</span>
                                            </Box>
                                            <Box mt={1} width={1}>
                                                <SelectEmployeeComponent
                                                    selectedEmployee={selectedProvider}
                                                    employees={state.appointment.employees}
                                                    onEmployeeChange={() => { }}
                                                    disabled={true}
                                                />
                                            </Box>
                                        </Box>
                                    }
                                    {
                                        state.appointment.withType === AppointmentWithType.Staff &&
                                        <Box width={1}>
                                            <Box>
                                                <span className={clsx(commonClasses.size14, commonClasses.colorGray1)}>Select Staff</span>
                                            </Box>
                                            <Box mt={1} width={1}>
                                                <SelectEmployeeComponent
                                                    selectedEmployee={selectedStaff}
                                                    employees={state.appointment.employees}
                                                    onEmployeeChange={() => { }}
                                                    disabled={true}
                                                />
                                            </Box>
                                        </Box>
                                    }
                                </Box>
                            </>
                        }
                        {
                            targetType === AppointmentTargetType.Other &&
                            <Box my={2}>
                                <Box>
                                    <span className={clsx(commonClasses.size14, commonClasses.colorGray1)}>Appointment Attendee(s)</span>
                                </Box>
                                <Box mt={1}>
                                    <span className={commonClasses.colorGray1}>{state.appointment.employees.map(x => (`${x.firstName} ${x.lastName}`)).join(', ')}</span>
                                </Box>
                            </Box>
                        }

                        <Divider />

                        <Box my={2}>
                            <SelectDateTimeComponent
                                selectedDate={state.selectedDate}
                                selectedTime={state.selectedTime}
                                handleChangeDate={handleChangeDate}
                                handleChangeTime={handleChangeTime}
                                availability={getAvailability()}
                                minDate={state.minDate}
                                isLoading={state.isAvailabilityLoading}
                                error={!!state.errors['selectedTime']}
                                errorMessage={state.errors['selectedTime']}
                                patientTimezone={`Timezone: ${getTimeZoneName()}`}
                            />
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions classes={{ root: classes.dialogActions }}>
                    <WildHealthButton
                        id="coach-reschedule-appointment-cancel"
                        onClick={() => handleClose()}
                        width={120}
                        size='large'
                        color='tertiary'
                        disabled={state.isLoading}>
                        Cancel
                    </WildHealthButton>

                    <WildHealthButton
                        id="coach-reschedule-appointment"
                        onClick={(e) => handleSubmit(e)}
                        width={260}
                        size='large'
                        loading={state.isLoading}>
                        Reschedule Appointment
                    </WildHealthButton>
                </DialogActions>
            </Dialog>
        </>
    )
}