import { createStyles, makeStyles } from '@material-ui/core/styles'
import { colors } from '../../../common/constants/colors'

export const useStyles = makeStyles(() =>
    createStyles({
        root: {
            flexGrow: 1,
            display: 'flex',
            minHeight: 'calc(100vh - 200px)',
            backgroundColor: '#fff'
        },
        container: {
            width: '100%',
            height: '100%',
        },
        emptyPage: {
            width: '100%',
            minHeight: 'calc(100vh - 200px)',
            backgroundColor: '#fff'
        },
        notificationToolTipText: {
            fontFamily: 'Be Vietnam Pro',
            fontSize: 12,
            fontWeight: 400,
            color: colors.white,
        },
        arrow: {
            color: colors.gray900,
        },
        customWidth: {
            maxWidth: 'none',
            backgroundColor: colors.gray900,
            padding: 10,
        },
        tooltipPlacement: {
            margin: '8px 0',
        },
    })
)
