import React from 'react';
import { Box, Checkbox, FormControlLabel, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { SelectAddOnModel } from '../../models/addOns.models';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import commonUseStyles from '../../../common/styles/common.styles';
import { useStyles } from "./selectAddonComponent.styles";
import { colors } from '../../../common/constants/colors';
import clsx from 'clsx';
import { ReactComponent as InfoCircleIcon } from '@img/icons/InfoCircleIcon.svg';
import { WildHealthClickableTooltip } from '../../../common/components/WildHealthClickableTooltip/WildHealthClickableTooltip';
import { WildHealthCheckbox } from '../../../common/components/WildHealthCheckbox';

interface SelectAddOnComponentProps {
    key?: number;
    addOn: SelectAddOnModel;
    handleSelectingItem: (id: number, value: boolean) => void;
}

export const SelectAddOnTestedComponent: React.FC<SelectAddOnComponentProps> = (props: SelectAddOnComponentProps) => {
    const { key, addOn, handleSelectingItem } = props;

    const classes = useStyles();
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const customTooltipClasses = {
        tooltip: classes.customWidth,
        arrow: classes.arrow,
        tooltipPlacementBottom: classes.tooltipPlacement,
        tooltipPlacementTop: classes.tooltipPlacement,
    };

    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const handleChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleSelectingItem(addOn.id, event.target.checked)
    }

    const tooltipContent = (
        <p className={classes.notificationToolTipText}>
            {addOn.description}
        </p>
    )

    const label = <Box component="div" display="flex" className={clsx(isSmallScreen ? commonClasses.size14 : commonClasses.size16, commonClasses.textRegular, commonClasses.colorLinkBlack)}>
        <span>{addOn.name}</span>
    </Box>;
    const controlIcon = <CheckBoxOutlineBlankIcon style={{ fill: colors.mediumGray }} />;
    const controlCheckedIcon = <CheckBoxIcon style={{ fill: colors.accent1 }} />;
    const control = (
        <Checkbox
            icon={controlIcon}
            color="primary"
            checkedIcon={controlCheckedIcon}
            name={`AddOn${key}-CheckBox`}
            checked={addOn.isSelected}
            onChange={handleChanges}
        />
    );

    return (
        <Box key={key}>
            <Grid
                container
                spacing={1}
                justify="space-between"
            >
                <Grid item xs={9} md={9} lg={9}>
                    <Box display="flex" alignItems="center">
                        <FormControlLabel control={control} label={label} />
                        <WildHealthClickableTooltip
                            tooltipContent={tooltipContent}
                            isOpened={open}
                            handleClose={handleTooltipClose}
                            customClasses={customTooltipClasses}
                        >
                            <span>
                                <Box className={commonClasses.pointer} onClick={handleTooltipOpen}>
                                    <InfoCircleIcon />
                                </Box>
                            </span>
                        </WildHealthClickableTooltip>
                    </Box>
                </Grid>
                <Grid item xs={3} md={3} lg={3} className={commonClasses.textMedium}>
                    <Box mt={1} textAlign="right" className={clsx(commonClasses.size18, commonClasses.textMedium, commonClasses.colorLinkBlack)}>
                        ${addOn.price}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export const SelectAddOnComponent: React.FC<SelectAddOnComponentProps> = (props: SelectAddOnComponentProps) => {
    const { key, addOn, handleSelectingItem } = props;

    const classes = useStyles();
    const commonClasses = commonUseStyles();

    const customTooltipClasses = {
        tooltip: classes.newDesignWidth,
        arrow: classes.newDesignArrow,
        tooltipPlacementBottom: classes.tooltipPlacement,
        tooltipPlacementTop: classes.tooltipPlacement,
    };

    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const handleChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleSelectingItem(addOn.id, event.target.checked)
    }

    const tooltipContent = (
        <p className={classes.notificationToolTipText}>
            {addOn.description}
        </p>
    )

    return (
        <Box key={key}>
            <Grid
                container
                spacing={1}
                justify="space-between"
            >
                <Grid item xs={9} md={9} lg={9}>
                    <Box display="flex" alignItems="center">
                        <WildHealthCheckbox
                            id={`AddOn${key}-CheckBox`}
                            fill={colors.lightMint}
                            label={<Box component="div" display="flex" className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorGray700)}>
                                <span>{addOn.name}</span>
                            </Box>}
                            checkStatus={addOn.isSelected}
                            handleCheck={handleChanges}
                        />
                        <WildHealthClickableTooltip
                            tooltipContent={tooltipContent}
                            isOpened={open}
                            handleClose={handleTooltipClose}
                            customClasses={customTooltipClasses}
                        >
                            <span>
                                <Box className={commonClasses.pointer} onClick={handleTooltipOpen}>
                                    <InfoCircleIcon />
                                </Box>
                            </span>
                        </WildHealthClickableTooltip>
                    </Box>
                </Grid>
                <Grid item xs={3} md={3} lg={3}>
                    <Box mt={1} textAlign="right" className={clsx(commonClasses.colorLinkBlack)}>
                        ${addOn.price}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
