import { ListItemText } from "@material-ui/core";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles(() =>
  createStyles({
    fileIcon: {
      color: colors.main,
      display: "inline-block",
    },
    file: {
      padding: "0 8px",
      color: colors.main,
      borderRadius: 2,
      display: "flex",
      alignItems: "center",
      width: "100%",
      height: 44,
      cursor: 'pointer',
      '&:hover': {
        color: colors.main,
        textDecoration: 'underline'
    },
    },
  })
);

export const FileName = withStyles({
  root: {
    "& .MuiListItemText-primary": {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      color:  colors.main,
      maxWidth: 225,
    },
  },
})(ListItemText);

