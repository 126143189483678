import { makeStyles } from "@material-ui/core";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme) => ({
    root: {
        width: 260,
        borderColor: colors.gray4,
        borderRadius: 2,
        '& .MuiOutlinedInput-root': {
            borderColor: colors.gray4,
            borderRadius: 2,
        },
    },
    avatar: {
        minWidth: 24,
        width: 24,
        height: 24,
        backgroundColor: colors.gray4,
        color: colors.gray1,
        fontSize: 12,
    },
    avatarPreparing: {
        minWidth: 6,
        width: 6,
        height: 6,
        backgroundColor: colors.gray1,
        borderRadius: '50%',
    },
    avatarGood: {
        minWidth: 6,
        width: 6,
        height: 6,
        backgroundColor: colors.good,
        borderRadius: '50%',
    },
    avatarOrange: {
        minWidth: 6,
        width: 6,
        height: 6,
        backgroundColor: colors.average1,
        borderRadius: '50%',
    },
    avatarDanger: {
        minWidth: 6,
        width: 6,
        height: 6,
        backgroundColor: colors.bad,
        borderRadius: '50%',
    },
    avatarNA: {
        minWidth: 6,
        width: 6,
        height: 6,
        borderRadius: '50%',
        border: `1px solid ${colors.mediumGray}`,
    },
    avatarEmpty: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: 24,
        width: 24,
        height: 24,
        fontSize: 11,
        borderRadius: '50%',
        background: '#F6F8FB',
        color: colors.gray1,
        marginRight: 12
    },
    customTooltip: {
        backgroundColor: colors.lightBlack,
        padding: 12,
        fontSize: 14,
    },
    arrow: {
        color: colors.lightBlack,
    },
    icon: {
        fill: colors.main,
    },
    selectInput: {
        textOverflow: "ellipsis",
        overflow: "hidden"
    },
    link: {
        textDecoration: 'none',
        "&:hover": {
            color: colors.main,
            cursor: 'pointer',
        }
    },
}));
