import { authHeader } from "../../common/helpers/auth-header";
import { Observable } from "rxjs";
import Axios from "axios-observable";
import {
  patientConversationsStore,
  PatientConversationsStore,
} from "../stores/patientConversationsStore/patientConversations.store";
import {
    ConversationModelBaseView,
  EmployeeConversationModel,
  PatientConversationModel,
} from "../models/conversation.models";
import {
  employeeConversationsStore,
  EmployeeConversationsStore,
} from "../stores/employeeConversationsStore/employeeConversations.store";



interface SeverData {
    data: ConversationModelBaseView[]
}

/**
 * Provides method for working with conversations
 */
export class ConversationsService {
    private conversationsUrl = `${process.env.REACT_APP_API_URL}Conversations`;
    private employeeUrl = `${this.conversationsUrl}/Employee`;
    private patientUrl = `${this.conversationsUrl}/Patient`;

    private getConfig = () => ({headers:authHeader()});


    constructor(
        private patientConversationsStore: PatientConversationsStore,
        private employeeConversationsStore: EmployeeConversationsStore) {
    }

    public selectConversation(conversation: EmployeeConversationModel): void {
        this.employeeConversationsStore.update({
            targetConversation: conversation
        });
    }

    public getPatientHealthCareConversation(patientId: number): Observable<PatientConversationModel> {
        const url = `${this.employeeUrl}/PatientHealthCare/?patientId=${patientId}`;

        return new Observable<PatientConversationModel>(observer => {
            Axios.get<PatientConversationModel>(url, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        this.patientConversationsStore.update({
                            heathCareConversation: response.data,
                        });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getPatientStaffConversations(patientId: number): Observable<PatientConversationModel[]> {
        const url = `${this.employeeUrl}/PatientSupport/?patientId=${patientId}`;

        return new Observable<PatientConversationModel[]>(observer => {
            Axios.get<PatientConversationModel[]>(url, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        this.patientConversationsStore.update({
                            supportConversations: response.data
                        });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getPatientAllMessagesStaff(vendorExternalId: string): Observable<ConversationModelBaseView> {
        const url = `${this.employeeUrl}/AllMessages/?vendorExternalId=${vendorExternalId}`;

        return new Observable<ConversationModelBaseView>(observer => {
            Axios.get<ConversationModelBaseView[]>(url,
                {...this.getConfig(),
                    transformResponse: [r => (JSON.parse(r).messages)]}).pipe()
                .subscribe(
                    response => {
                        this.patientConversationsStore.update({
                            targetStaffConversation: response.data
                        });
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getPatientAllMessagesHealthCare(vendorExternalId: string): Observable<ConversationModelBaseView[]> {

        const url = `${this.employeeUrl}/AllMessages/?vendorExternalId=${vendorExternalId}`;

        return new Observable<ConversationModelBaseView[]>(observer => {
            Axios.get<ConversationModelBaseView[]>(url,
                    {...this.getConfig(),
                    transformResponse: [r => (JSON.parse(r).messages)]}).pipe()
                .subscribe(response  => {
                    this.patientConversationsStore.update({
                            targetHealthcareConversation: response.data
                        });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getAllMessagesInternal(vendorExternalId: string): Observable<ConversationModelBaseView[]> {
        const url = `${this.employeeUrl}/AllMessages/?vendorExternalId=${vendorExternalId}`;

        return new Observable<ConversationModelBaseView[]>(observer => {
            Axios.get<ConversationModelBaseView[]>(url, 
                    {...this.getConfig(),
                    transformResponse: [r => (JSON.parse(r).messages)]}).pipe()
                .subscribe(
                    response => {
                        this.patientConversationsStore.update({
                            targetInternalConversation: response.data
                        });
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getAllMessagesSupport(vendorExternalId: string): Observable<ConversationModelBaseView> {
        const url = `${this.patientUrl}/AllMessages/?vendorExternalId=${vendorExternalId}`;

        return new Observable<ConversationModelBaseView>(observer => {
            Axios.get<ConversationModelBaseView[]>(url,
                    {...this.getConfig(),
                    transformResponse: [r => (JSON.parse(r).messages)]}).pipe()
                .subscribe(
                    response => {
                        this.patientConversationsStore.update({
                            targetStaffConversation: response.data
                        });
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }
}

export const conversationsViewService = new ConversationsService(
  patientConversationsStore,
  employeeConversationsStore
);
