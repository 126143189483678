import { Box } from '@material-ui/core';
import React from 'react';
import {HealthLogQuestionNames, QuestionnaireModel, QuestionnaireResultModel} from '../../models/questionnaire.models';
import { QuestionnairesResultListComponent } from '../questionnairesResultListComponent/QuestionnairesResultListComponent';
import {Gender} from "../../../common/models/user.models";

export interface HealthLogQuestionnaireResultComponentProps {
    questionnaire: QuestionnaireModel;
    result: QuestionnaireResultModel;
    gender: Gender;
}

export const HealthLogQuestionnaireResultComponent: React.FC<HealthLogQuestionnaireResultComponentProps> = (props: HealthLogQuestionnaireResultComponentProps) => {
    const {
        questionnaire,
        result,
        gender
    } = props;

    return (
        <Box p={5}>
            <Box>
                <QuestionnairesResultListComponent
                    gender={gender}
                    questionnaire={questionnaire}
                    result={result}
                    questions={[
                        HealthLogQuestionNames.FIRST_MEAL_TIME,
                        HealthLogQuestionNames.FIRST_MEAL,
                        HealthLogQuestionNames.SECOND_MEAL_TIME,
                        HealthLogQuestionNames.SECOND_MEAL,
                        HealthLogQuestionNames.LAST_MEAL_TIME,
                        HealthLogQuestionNames.LAST_MEAL,
                        HealthLogQuestionNames.LIST_OF_SNACKS,
                        HealthLogQuestionNames.REGULAR_PRACTICE,
                    ]}
                    title='NUTRITION:'
                />
            </Box>

            <Box pt={5}>
                <QuestionnairesResultListComponent
                    gender={gender}
                    questionnaire={questionnaire}
                    result={result}
                    questions={[
                        HealthLogQuestionNames.WEEKS_EXERCISE,
                        HealthLogQuestionNames.TYPES_OF_EXERCISE,
                    ]}
                    title="EXERCISE:"
                />
            </Box>

           <Box pt={5}>
               <QuestionnairesResultListComponent
                   gender={gender}
                   questionnaire={questionnaire}
                   result={result}
                   questions={[
                        HealthLogQuestionNames.ACTIVITIES_LIST,
                        HealthLogQuestionNames.CONSUME_BEFORE_NIGHT,
                        HealthLogQuestionNames.FALL_ASLEEP_TIME,
                        HealthLogQuestionNames.WAKE_UP_TIME,
                        HealthLogQuestionNames.WAKE_UP_WITH_ALARM,
                        HealthLogQuestionNames.FEEL_IN_THE_MORNING,
                        HealthLogQuestionNames.ENERGY_THROUGHOUT_THE_DAY,
                   ]}
                   title="SLEEP:"
               />
           </Box>

            <Box pt={5}>
                <QuestionnairesResultListComponent
                    gender={gender}
                    questionnaire={questionnaire}
                    result={result}
                    questions={[
                        HealthLogQuestionNames.PRACTICES_A_WEEK
                    ]}
                    title="MINDFULNESS:"
                />
            </Box>
        </Box>
    );
}