import { useEffect, useState, ChangeEventHandler } from "react";
import { Subscription } from "recompose";
import { onEmit } from "../../../common/helpers/on-emit";
import { IErrorState } from "../../../common/validation/error-state";
import { GoalCommonModel, InterventionModel } from "../../../notes/models/notes.models";
import { goalsService } from "../../../notes/services/goals.service";
import { notesQuery } from "../../../notes/stores/notes";
import { confirmService } from "../../../../services/confirm.service";
import { createCommonGoalValidator } from "../manageCommonGoalsComponent/createCommonGoal.validator";

interface CommonGoalEditComponentState extends IErrorState {
  isLoading: boolean;
  isSubmitting: boolean;
  isOpen: boolean;
  targetGoal: GoalCommonModel;
  createIntervention: InterventionModel;
  selectedId: number;
  canSubmit: boolean;
}

const initialParmas: InterventionModel = {
  id: 0,
  name: '',
  goalId: 0,
}

export function useFacade(goalId: number): [
  CommonGoalEditComponentState,
  (field: string, value: any) => void,
  ChangeEventHandler,
  () => void,
  () => void,
  (id: number) => void,
  (id: number) => void
] {
  const [state, setState] = useState({
    isLoading: true,
    isSubmitting: false,
    isOpen: false,
    canSubmit: false,
    targetGoal: null,
    createIntervention: initialParmas,
    selectedId: 0,
    errors: {}
  } as CommonGoalEditComponentState);

  const handleChanges = (field: string, value: any) => {
    createCommonGoalValidator.validateAndSetState(state, setState, field, value);

    const params = state.targetGoal;
    
    params[field] = value;

    setState({...state, targetGoal: Object.assign({}, params)});
  }

  const handleChangeIntervention = (e: any) => {
    const canSubmit = e.target.value.length > 0;
    const params = state.createIntervention;
    params.name = e.target.value;
    setState({
      ...state,
      createIntervention: Object.assign({}, params),
      canSubmit: canSubmit,
    })
  }

  const handleUpdateGoal = () => {
    createCommonGoalValidator.validateObjectAndSetState(state, setState, state.targetGoal);
    if (!createCommonGoalValidator.stateIsValid(state)) {
      return;
    }

    setState({...state, isSubmitting: true })

    const cb = () => setState(state => ({ ...state, isSubmitting: false,  }));
    
    goalsService.updateCommonGoal(state.targetGoal).subscribe(cb, cb)
  }
  
  const handleSaveIntervention = () => {
      if (!state.canSubmit) {
          return;
      }
      setState({...state, isSubmitting: true })

      const action = state.selectedId > 0 ? goalsService.updateIntervention(state.createIntervention) : goalsService.createIntervention(state.createIntervention);

      const cb = () => setState(state => ({ ...state, isSubmitting: false, isOpen: false, selectedId: -1, canSubmit: false, createIntervention: Object.assign({}, initialParmas) }));

      action.subscribe(cb, cb);
  }
  
  const handleDeleteIntervention = (id: number) => {
    const item = state.targetGoal.interventions.find(x => x.id === id);
    if (!item) {
        return;
    }
    confirmService.confirm(
      'Delete Intervention',
      'Are you sure you want to delete this intervention?',
      'Yes',
      'Cancel',
      'danger')
      .subscribe(() => {
        setState(state => ({
          ...state,
          isSubmitting: true,
          selectedId: id
        }));

        const cb = () => setState(state => ({ ...state, isSubmitting: false, selectedId: 0 }));
        goalsService.deleteIntervention(item.goalId, item.id).subscribe(cb, cb);
      });
  }

  const handleToggleInterventionModify = (id: number) => {
      const intervention = state.targetGoal.interventions.find(t => t.id === id)
      setState((state) => ({
          ...state,
          isOpen: !state.isOpen,
          selectedId: id,
          canSubmit: Boolean(intervention),
          createIntervention: Object.assign({}, intervention ? intervention : { ...initialParmas, goalId }),
          errors: {}
      }))
  }

  const useEffectCB = () => {
    const subscriptions: Subscription[] = [
      onEmit<GoalCommonModel>(notesQuery.targetCommonGoal$, targetCommonGoal => {
        setState(state => ({
          ...state,
          targetGoal: targetCommonGoal,
        }));
      }),
    ];

    const cb = () => setState(state => ({ ...state, isLoading: false }));

    goalsService.getCommonGoalById(goalId).subscribe(cb, cb);

    return () => {
      subscriptions.map(it => it.unsubscribe())
    };
  }

  useEffect(useEffectCB, []);

  return [
    state,
    handleChanges,
    handleChangeIntervention,
    handleUpdateGoal,
    handleSaveIntervention,
    handleDeleteIntervention,
    handleToggleInterventionModify
  ];
}