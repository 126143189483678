import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { profileService } from "../../../../../account/services/profile.service";
import { profileQuery } from "../../../../../account/stores/profileStore";
import { getLastObject } from "../../../../../common/helpers/get-last-object";
import { onEmit } from "../../../../../common/helpers/on-emit";
import { IErrorState } from "../../../../../common/validation/error-state";
import { PatientPharmacyInfoModel, getInitPharmacyInfo } from "../../../../../patients/models/patient.model";
import { patientPharmacyInfomationValidator } from "../../patientInformationComponentValidator/patientPharmacyInformation.validator";

interface PharmacyInfoWidgetState extends IErrorState {
  isLoading: boolean;
  isSaveChanges: boolean;
  edit: boolean;
  pharmacyInfo: PatientPharmacyInfoModel;
  pharmacyInfoDraft: PatientPharmacyInfoModel;
}

const pageFields = [
  'streetAddress ',
  'name',
  'phone',
  'country',
  'city',
  'state',
  'zipCode'
];

export function useFacade(patientId: number | null): [
  PharmacyInfoWidgetState,
  Function,
  () => void,
  () => void,
  () => void,
] {
  const [state, setState] = useState({
    isLoading: true,
    isSaveChanges: false,
    edit: false,
    pharmacyInfo: {},
    pharmacyInfoDraft: {},
    errors: {},
  } as PharmacyInfoWidgetState);

  const handleEditToggle = () => {
    setState(state => ({
      ...state,
      edit: !state.edit,
      pharmacyInfoDraft: {
        ...state.pharmacyInfo
      }
    }));
  }

  const handleReset = () => {
    setState(state => ({
      ...state,
      edit: !state.edit,
      pharmacyInfoDraft: {
        ...state.pharmacyInfo,
      }
    }));

  }

  const handleSave = () => {
    validateForm();
    if (!patientPharmacyInfomationValidator.stateIsValid(state)) {
      return;
    }

    setState(state => ({ ...state, isSaveChanges: true }));

    const model: PatientPharmacyInfoModel = {
      phone: state.pharmacyInfoDraft.phone,
      name: state.pharmacyInfoDraft.name,
      streetAddress: state.pharmacyInfoDraft.streetAddress,
      city: state.pharmacyInfoDraft.city,
      state: state.pharmacyInfoDraft.state,
      zipCode: state.pharmacyInfoDraft.zipCode,
      country: state.pharmacyInfoDraft.country
    };

    profileService.updatePatientPharmacyInfo(patientId, model).subscribe(
      () => {
        setState(state => ({ ...state, isSaveChanges: false, edit: !state.edit, }));
      },
      () => {
        setState(state => ({ ...state, isSaveChanges: false }));
      }
    );
  }

  /**
 * Validates patient profile form
 */
  const validateForm = () => {
    pageFields.forEach(field => {
      const keys = field.split(".");
      const key = keys.pop();
      const lastObject = getLastObject(keys, state.pharmacyInfoDraft);
      const value = lastObject[key];
      patientPharmacyInfomationValidator.validateAndSetState(state, setState, field, value);
    });
  }

  /**
   * Handles changes. Additionally does field validation
   * @param field
   * @param value
   */
  const handleChanges = (field: string, value: string) => {
    patientPharmacyInfomationValidator.validateAndSetState(state, setState, field, value);

    const pharmacyInfoDraft = state.pharmacyInfoDraft;
    const keys = field.split(".");
    const key = keys.pop();
    const lastObject = getLastObject(keys, pharmacyInfoDraft);
    lastObject[key] = value;

    setState({ ...state, pharmacyInfoDraft: { ...pharmacyInfoDraft } });
  }

  const useEffectCB = () => {
    const subscriptions: Subscription[] = [
      onEmit<PatientPharmacyInfoModel>(profileQuery.pharmacyInfo$, pharmacyInfo =>
        setState(state => ({ ...state, pharmacyInfo: pharmacyInfo ?? getInitPharmacyInfo() }))
      ),
    ];

    const cb = () => setState(state => ({ ...state, isLoading: false }));

    profileService.getPatientPharmacyInfo(patientId).subscribe(cb, cb)

    return () => {
      subscriptions.map(it => it.unsubscribe())
    };
  }


  useEffect(useEffectCB, [patientId]);

  return [
    state,
    handleChanges,
    handleEditToggle,
    handleReset,
    handleSave,
  ];
}
