import {useEffect, useState} from 'react';
import {AppointmentTypeModel} from "../../../appointments/models/appointments.models";
import { appointmentsService } from "../../../appointments/services/appointments.service";
import { onEmit } from "../../../common/helpers/on-emit";
import { Subscription } from "recompose";
import { appointmentsQuery } from "../../../appointments/stores/appointments";
import { PatientModel } from '../../../patients/models/patient.model';
import { patientsQuery } from '../../../patients/stores/patientsStore';
import {AppointmentConfigurationModel} from "../../models/notes.models";
import {DurationModel, durations} from "../../../appointments/models/times.models";

export interface ConfigureNoteAppointmentDialogState {
    isOpen: boolean;
    appointmentTypes: AppointmentTypeModel[];
    appointmentType: AppointmentTypeModel;
    appointmentDate: Date;
    appointmentDuration: DurationModel;
    patient: PatientModel;
}

export function useFacade(patientId: number): [
    ConfigureNoteAppointmentDialogState,
    (value: AppointmentTypeModel) => void,
    (value: any) => void,
    (value: string) => void,
    (value: number) => void,
    () => boolean,
    () => AppointmentConfigurationModel
] {
    const [state, setState] = useState({
        isOpen: false,
        appointmentTypes:[],
        appointmentType: null,
        appointmentDate: new Date(),
        appointmentDuration: durations.filter(x => x.patientMode)[0],
        patient: null,
    } as ConfigureNoteAppointmentDialogState);

    const handleAppointmentType = (type: AppointmentTypeModel) => {
        setState(state => ({...state, appointmentType: type}));
    }

    const handleAppointmentDate = (date: Date) => {
        setState(state => ({...state, appointmentDate: date}));
    }

    const handleAppointmentTime = (time: string) => {
        const hoursMinutes = time.split(":");
        const dateResult = new Date(state.appointmentDate);
        dateResult.setHours(+hoursMinutes[0], +hoursMinutes[1]);
        handleAppointmentDate(new Date(dateResult));
    }

    const handleAppointmentDuration = (value: number) => {
        const duration =  durations.filter(x => x.patientMode).find(x => x.value === value);
        setState(state => ({...state, appointmentDuration: duration}));
    }

    const canConfirm = () => {
        return state.appointmentDuration != null && state.appointmentType != null
    }

    const toConfiguration = () => {
        return {
            configurationId: state.appointmentType?.configurations[0]?.id,
            visitDate: state.appointmentDate,
            duration: state.appointmentDuration.value
        } as AppointmentConfigurationModel
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<PatientModel>(patientsQuery.targetPatient$, targetPatient => {
                if (targetPatient?.id === patientId) {
                    setState(state => ({...state, patient: targetPatient}));
                }
            }),
            onEmit<AppointmentTypeModel[]>(appointmentsQuery.allAppointmentTypes$, appointmentTypes => {
                setState(state => ({
                    ...state,
                    appointmentTypes: appointmentTypes,
                    appointmentType: state.appointmentType ?? appointmentTypes[0]
                }));
            })
        ];

        appointmentsService.getAllAppointmentTypes();

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    };


    useEffect(useEffectCB, []);

    return [
        state,
        handleAppointmentType,
        handleAppointmentDate,
        handleAppointmentTime,
        handleAppointmentDuration,
        canConfirm,
        toConfiguration
    ];
}