import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  CircularProgress
} from '@material-ui/core';
import React from 'react';
import { WildHealthContainer } from '../../../common/components/wildHealthContainer/WildHealthContainer';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { useFacade } from './insuranceConfigurationsComponent.hooks';
import { useStyles, SearchTextField } from "./insuranceConfigurationsComponent.styles";
import { FeatureComponent } from "../../../common/components/featureFlags/FeatureComponent";
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from 'clsx';
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { pageSizes } from "../../../common/pagination/models/page-sizes";
import { PaginationComponent } from "../../../common/pagination/components/paginationComponent/PaginationComponent";
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit'
import { ReactComponent as TrashIcon } from "@img/icons/Trash.svg";
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import moment from "moment";

export const InsuranceConfigurationsComponent: React.FC = () => {
  const [
    {
      isLoading,
      isConfigLoading,
      isSubmitted,
      isAddingConfiguration,
      insuranceConfigurations,
      insuranceCarriers,
      selectedCarrierId,
      searchKey,
      removingConfigurationId,
      totalCount,
      pageSize,
      selectedPage,
      sortingColumns
    },
    handleChanges,
    handleCreateInsuranceConfiguration,
    handleRemoveConfiguration,
    handleOnFilter,
    getAllAvailablePages,
    handlePageSizeChange,
    handlePageChange,
    handleGoToEdit
  ] = useFacade();

  const commonClasses = commonUseStyles();
  const classes = useStyles();

  const renderTable = () => {
    if (isConfigLoading) {
      return <WildHealthLinearProgress />
    }

    if (!isConfigLoading && (!insuranceConfigurations || !insuranceConfigurations.length)) {
      return <WildHealthPlaceHolder message="No available configurations found" />
    }

    return (
      <>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {
                  sortingColumns.map((item, index) =>
                    <WildHealthTableCell key={index}>
                      {item.title}
                    </WildHealthTableCell>
                  )
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {insuranceConfigurations.slice((selectedPage - 1) * pageSize, (selectedPage - 1) * pageSize + pageSize).map((configuration, index) => (
                <WildHealthTableRow key={index}>
                  <WildHealthTableCell align="left" style={{ width: '30%' }}><Box className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorBlack)}>{configuration?.insurance?.name}</Box></WildHealthTableCell>
                  <WildHealthTableCell align="left">
                    <Box display="flex" alignItems="center" flexWrap="wrap">
                      {configuration?.insurancePlans && configuration.insurancePlans.map(plan => (
                        <Box key={plan.name} className={classes.stateItem}>
                          <span>{plan.name}</span>
                        </Box>
                      ))}
                    </Box>
                  </WildHealthTableCell>
                  <WildHealthTableCell align="left">
                    <Box display="flex" alignItems="center" flexWrap="wrap">
                      { moment(new Date(configuration.startDate)).format('MM/DD/YYYY') }
                    </Box>
                  </WildHealthTableCell>
                  <WildHealthTableCell align="left">
                    <Box display="flex" alignItems="center" flexWrap="wrap">
                      { moment(new Date(configuration.endDate)).format('MM/DD/YYYY') }
                    </Box>
                  </WildHealthTableCell>
                  <WildHealthTableCell style={{ width: '15%' }}>
                    <IconButton
                      id="edit-configuration-btn"
                      aria-describedby={configuration.insurance.name}
                      disabled={isSubmitted}
                      onClick={() => handleGoToEdit(configuration.id)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      id="remove-configuration-btn"
                      aria-describedby={configuration.insurance.name}
                      disabled={isSubmitted}
                      onClick={() => handleRemoveConfiguration(configuration.id)}
                    >
                      {isSubmitted && removingConfigurationId === configuration.id ? <CircularProgress size={24} className={commonClasses.colorMain} /> : <TrashIcon />}
                    </IconButton>
                  </WildHealthTableCell>
                </WildHealthTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box py={1} px={2}>
          <PaginationComponent
            pageSizes={pageSizes}
            pageSize={pageSize}
            selectedPage={selectedPage}
            handlePageSizeChange={handlePageSizeChange}
            totalCount={totalCount}
            availablePages={getAllAvailablePages()}
            handlePageSelect={handlePageChange}
          />
        </Box>
      </>
    )
  }

  const renderAddInsurance = () => {
    if (isConfigLoading) {
      return <WildHealthLinearProgress />
    }

    return (
      <Box p="30px" className={commonClasses.bgWhiteMain}>
        <Box className={clsx(commonClasses.size20, commonClasses.textMedium, commonClasses.colorBlack)}>Add Insurance Configuration</Box>
        <Box px={20} width={1} mt="30px">
          <Box>
            <FormControl
              variant="outlined"
              color='primary'
              required
              size="small"
              disabled={isConfigLoading || isSubmitted}
              classes={{ root: classes.root }}
              fullWidth>
              <Box className={commonClasses.inputLabel} mb={1}>Insurance Carrier</Box>
              <Select
                style={{ background: '#FFF' }}
                classes={{ root: classes.root }}
                value={selectedCarrierId}
                inputProps={{
                  name: 'insurance id',
                  id: 'insurance-label',
                  classes,
                }}
                renderValue={() => {
                  if (selectedCarrierId === null || !selectedCarrierId) {
                    return <Box className={commonClasses.colorMediumGray}>Select insurance</Box>;
                  }

                  return insuranceCarriers?.find(i => i.id === selectedCarrierId).name;
                }}
                displayEmpty
                onChange={(v) => handleChanges(v.target.value)}
              >
                {
                  insuranceCarriers.map((insurance, index) => (
                    <MenuItem value={insurance.id} key={`${insurance.id}_${index}`}>{insurance.name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Box>
          <Box mt="30px" display="flex" alignItems="center">
            <Box width="132px">
              <WildHealthButton
                id="create-configuration-button"
                disabled={isSubmitted}
                loading={isLoading || isAddingConfiguration}
                onClick={handleCreateInsuranceConfiguration}
                fullWidth
                style={{ height: 48 }}
              >
                <Box className={clsx(commonClasses.size16, commonClasses.textMedium)}>Add</Box>
              </WildHealthButton>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box py={2} px={10} width={1}>
      <Box my={2} display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <FeatureComponent featureFlag={FeatureFlag.Navigation}>
            <Box className={clsx(commonClasses.size24, commonClasses.textSemiBold, commonClasses.colorBlack)}>{PagesNamesService.insuranceConfigurations}</Box>
          </FeatureComponent>
        </Box>
      </Box>
      {renderAddInsurance()}
      <Box mt={5} mb={2} display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Box className={clsx(commonClasses.size20, commonClasses.textMedium, commonClasses.colorBlack)}>Available Configurations</Box>
        </Box>
        <Box width="310px">
          <SearchTextField
            fullWidth
            variant="outlined"
            className={classes.search}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnFilter(e.target.value)}
            placeholder='Search by Insurance'
            InputProps={{
              inputProps: {
                className: classes.searchBase,
              },
              startAdornment: <SearchIcon className={classes.icon} />,
            }}
            value={searchKey}
          />
        </Box>
      </Box>

      <WildHealthContainer margin={0} minHeight={'100%'}>
        {renderTable()}
      </WildHealthContainer>
    </Box>
  );
}