import {
    DiabetesType,
    DietType,
    Ethnicity,
    ExerciseType, FileInputDataProvider,
    FileInputType,
    Optimization
} from "../models/input.models";

export class LabInputNames {
    static readonly TotalCholesterol: string = "TotalCholesterol";
    static readonly HdlCholesterol: string = "HdlCholesterol";
    static readonly LdlCholesterol: string = "LdlCholesterol";
    static readonly Triglycerides: string = "Triglycerides";
    static readonly LdlP: string = "LdlP";
    static readonly SmallLdlP: string = "SmallLdlP";
    static readonly LpA: string = "LpA";
    static readonly ApolipoproteinB: string = "ApolipoproteinB";
    static readonly Desmosterol: string = "Desmosterol";
    static readonly Lathosterol: string = "Lathosterol";
    static readonly Campsterol: string = "Campsterol";
    static readonly Sitosterol: string = "Sitosterol";
    static readonly Cholestanol: string = "Cholestanol";


    static readonly Homocysteine: string = "Homocysteine";
    static readonly Ast: string = "Ast";
    static readonly Alt: string = "Alt";
    static readonly B12: string = "B12";
    static readonly Folate: string = "Folate";
    static readonly FolateRbc: string = "FolateRbc";
    static readonly Tmao: string = "Tmao";
    static readonly UricAcid: string = "UricAcid";

    static readonly Ferritin: string = "Ferritin";
    static readonly Omega3: string = "Omega3";
    static readonly VitaminD: string = "VitaminD";
    static readonly CoQ10: string = "CoQ10";
    static readonly LinoleicAcid: string = "LinoleicAcid";

    static readonly FastingGlucose: string = "FastingGlucose";
    static readonly FastingInsulin: string = "FastingInsulin";
    static readonly HgbA1C: string = "HgbA1C";

    static readonly Crp: string = "Crp";
    static readonly AgRatio: string = "AgRatio";
    static readonly LpPla2: string = "LpPla2";
    static readonly OxLdl: string = "OxLdl";

    static readonly Dhea: string = "Dhea";
    static readonly Igf: string = "Igf";
    static readonly TestosteroneTotal: string = "TestosteroneTotal";
    static readonly TestosteroneFree: string = "TestosteroneFree";
    static readonly Shbg: string = "Shbg";
    static readonly Estradio: string = "Estradio";
    static readonly Progesterone: string = "Progesterone";
    static readonly Lh: string = "Lh";
    static readonly Fsh: string = "Fsh";
    static readonly Tsh: string = "Tsh";
    static readonly FreeT4: string = "FreeT4";
    static readonly FreeT3: string = "FreeT3";
    static readonly Cortisol: string = "Cortisol";

    static readonly Homir: string = "Homir";
    static readonly TrigHdl: string = "TrigHdl";
}

export class MicrobiomeInputNames {
    static readonly InflammationScore: string = "Inflammation Score";
    static readonly DiversityScore: string = "Diversity score";
}

export const LabInputLabels: { [name: string]: string } = {
    [LabInputNames.TotalCholesterol]: "Total Cholesterol",
    [LabInputNames.HdlCholesterol]: "HDL Cholesterol",
    [LabInputNames.LdlCholesterol]: "LDL Cholesterol",
    [LabInputNames.Triglycerides]: "Triglycerides",
    [LabInputNames.LdlP]: "LDL- P",
    [LabInputNames.SmallLdlP]: "Small LDL-p",
    [LabInputNames.LpA]: "Lp(a)",
    [LabInputNames.Dhea]: "DHEA",
    [LabInputNames.Igf]: "iGF",
    [LabInputNames.TestosteroneTotal]: "Testosterone Total",
    [LabInputNames.TestosteroneFree]: "Testosterone, Free",
    [LabInputNames.Shbg]: "SHBG",
    [LabInputNames.Estradio]: "Estradiol",
    [LabInputNames.Progesterone]: "Progesterone",
    [LabInputNames.Lh]: "LH",
    [LabInputNames.Fsh]: "FSH",
    [LabInputNames.Tsh]: "TSH",
    [LabInputNames.FreeT4]: "Free T4",
    [LabInputNames.FreeT3]: "Free T3",
    [LabInputNames.Cortisol]: "Cortisol",
    [LabInputNames.Homocysteine]: "Homocysteine",
    [LabInputNames.Ast]: "AST",
    [LabInputNames.Alt]: "ALT",
    [LabInputNames.B12]: "B12",
    [LabInputNames.Folate]: "Folate",
    [LabInputNames.FolateRbc]: "Folate RBC",
    [LabInputNames.Tmao]: "TMAO",
    [LabInputNames.UricAcid]: "Uric Acid",
    [LabInputNames.Crp]: "CRP",
    [LabInputNames.AgRatio]: "A:G ratio",
    [LabInputNames.LpPla2]: "LpPLA2",
    [LabInputNames.OxLdl]: "OxLDL",
    [LabInputNames.Ferritin]: "Ferritin (Iron)",
    [LabInputNames.Omega3]: "Omega 3",
    [LabInputNames.VitaminD]: "Vitamin D",
    [LabInputNames.CoQ10]: "CoQ10",
    [LabInputNames.FastingGlucose]: "Fasting Glucose",
    [LabInputNames.FastingInsulin]: "Fasting Insulin",
    [LabInputNames.HgbA1C]: "Hgb-A1c",
    [LabInputNames.ApolipoproteinB]: "Apolipoprotein B",
    [LabInputNames.Desmosterol]: "Desmosterol",
    [LabInputNames.Lathosterol]: "Lathosterol",
    [LabInputNames.Campsterol]: "Campsterol",
    [LabInputNames.Sitosterol]: "Sitosterol",
    [LabInputNames.Cholestanol]: "Cholestanol",
    [LabInputNames.LinoleicAcid]: "Linoleic Acid",
};

export const allowedFileExtensions: { [name: string]: string } = {
    [`${FileInputType.DNAReport}:${FileInputDataProvider.Andme}`]: ".txt,.csv",
    [`${FileInputType.DNAReport}:${FileInputDataProvider.LabCorpElation}`]: ".txt,.csv",
    [`${FileInputType.DNAReport}:${FileInputDataProvider.Ancestry}`]: ".txt,.csv",
    [`${FileInputType.LabResults}:${FileInputDataProvider.LabCorpElation}`]: ".pdf",
    [`${FileInputType.LabResults}:${FileInputDataProvider.LabCorpOriginal}`]: ".pdf",
    [`${FileInputType.LabResults}:${FileInputDataProvider.Quest}`]: ".pdf",
    [`${FileInputType.MicrobiomeData}:${FileInputDataProvider.Gutbio}`]: ".txt,.csv"
}

export const dietNames = {
    [DietType.None]: "None",
    [DietType.Vegan]: "Vegan",
    [DietType.Veggie]: "Vegetarian",
    [DietType.Pescatarian]: "Pescatarian",
    [DietType.Mediterranean]: "Mediterranean",
    [DietType.Whole]: "Whole Food Plant Based",
    [DietType.GlutenFree]: "Gluten Free",
    [DietType.Paleo]: "Paleo",
    [DietType.Keto]: "Keto"
};

export const exerciseNames = {
    [ExerciseType.None]: "None",
    [ExerciseType.NoPreference]: "No preference",
    [ExerciseType.Endurance]: "Increase endurance",
    [ExerciseType.Strength]: "Increase strength"
};

export const optimizationNames = {
    [Optimization.Incomplete]: "Incomplete",
    [Optimization.InProgress]: "In Progress",
    [Optimization.Completed]: "Completed"
}

export const ethnicityNames = {
    [Ethnicity.American]: "Native American",
    [Ethnicity.White]: "White",
    [Ethnicity.Indian]: "Indian",
    [Ethnicity.Pakistani]: "Pakistani",
    [Ethnicity.Bangladeshi]: "Bangladeshi",
    [Ethnicity.OtherAsian]: "Other Asian",
    [Ethnicity.BlackCaribbean]: "Black Caribbean",
    [Ethnicity.BlackAfrican]: "Black African",
    [Ethnicity.AfricanAmerican]: "African American",
    [Ethnicity.Chinese]: "Chinese",
    [Ethnicity.Hispanic]: "Hispanic",
    [Ethnicity.Other]: "Other ethnic group",
    [Ethnicity.Arab]: "Arab",
    [Ethnicity.Korean]: "Korean",
    [Ethnicity.Japanese]: "Japanese"
}

export const diabetesNames = {
    [DiabetesType.No]: "No",
    [DiabetesType.Type1]: "Type 1",
    [DiabetesType.Type2]: "Type 2",
}