import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { employeeConversationsQuery } from '../../stores/employeeConversationsStore/employeeConversations.query';
import { onEmit } from '../../../common/helpers/on-emit';
import { AttachedFileModel } from "../../models/message.models";
import { conversationsService } from "../../services/conversations.service";

export interface Group {
    title: string;
    items: AttachedFileModel[];
    isOpen: boolean;
}

interface AttachmentsComponentState {
    isLoading: boolean;
    groups: Group[];
    pdfGroup: AttachedFileModel[];
    imageGroup: AttachedFileModel[];
}

export function useFacade(): [AttachmentsComponentState, (index: number) => void, (sid: string) => void] {

    const [state, setState] = useState({
        isLoading: true,
        groups: [{
            title: 'PDFs',
            items: [],
            isOpen: false
        },{
            title: 'Images',
            items: [],
            isOpen: false
        }],
    } as AttachmentsComponentState);

    const handleToggleSection = (index: number) => {
        if (state.groups.length > index) {
            state.groups[index].isOpen = !state.groups[index].isOpen;
            setState(state => ({...state}));
        }
    }

    const handleGroup = (attachments: AttachedFileModel[]) => {
        const pdfs = state.groups[0];
        const images = state.groups[1];

        pdfs.items = [];
        images.items = [];

        attachments.forEach(media => {
            if (media.file[0].contentType === 'application/pdf') {
                
                pdfs.items.push(media);
            } else {
                images.items.push(media);
            }
        });

        const result = [];

        if (pdfs.items.length) {
            pdfs.title = `PDFs (${pdfs.items.length})`;
            result.push(pdfs);
        }

        if (images.items.length) {
            images.title = `Images (${images.items.length})`;
            result.push(images);
        }

        setState(state => ({ ...state, groups: result, pdfGroup: pdfs.items, imageGroup: images.items, isLoading: false }));
    }

    const handleDownloadAttachment = (sid) => {
        const model = { mediaSid: sid }
        conversationsService.createMediaUrl(model).subscribe((data: string) => {
            window.open(data)
        })
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<AttachedFileModel[]>(employeeConversationsQuery.targetAttachmentsView$, attachments => {
                handleGroup(attachments)
            }),
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, []);

    return [state, handleToggleSection, handleDownloadAttachment];
}

