import React from 'react';
import {Box} from '@material-ui/core';
import {navigationService} from "../../../../services/navigation.service";
import {useStyles} from "./FellowshipSubmissionReceivedPage.styles";
import {useHistory} from 'react-router';
import success from "@img/success.svg";
import commonUseStyles from '../../../common/styles/common.styles';
import {WildHealthButton} from '../../../common/components/wildHealthButton/WildHealthButton';
import clsx from 'clsx';
import {logoService} from '../../../../services/logo.service';

export const FellowshipSubmissionReceivedPage: React.FC = () => {
    const classes = useStyles();
    const commonClasses = commonUseStyles();

    const history = useHistory();

    return (
        <div className={classes.root}>
            <Box pt="20px" pl="80px">
                <img className={classes.logo} src={logoService.getLogo().primary}
                     alt="img"/>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center" pt="20vh">
                <Box alignItems="center">
                    <Box display="flex" justifyContent="center">
                        <img src={success} alt="img"/>
                    </Box>
                    <Box mt={5} textAlign="center">
                        <span className={clsx(commonClasses.size24, commonClasses.textSemiBold)}>Success!</span>
                    </Box>
                    <Box mt={2} textAlign="center">
                        <span className={commonClasses.colorGray1}>Your submission has been received.</span>
                    </Box>
                    <Box mt={5} display="flex" justifyContent="space-between">
                        <Box mr={1}>
                            <WildHealthButton
                                id="fellowship-submission-visit-wild-health"
                                color="secondary"
                                size="large"
                                onClick={() => navigationService.toWildHealth()}
                            >
                                Visit Wild Health Website
                            </WildHealthButton>
                        </Box>
                        <Box ml={1}>
                            <WildHealthButton
                                id="fellowship-submission-login-clarity-portal"
                                size="large"
                                onClick={() => navigationService.toLogin(history)}
                            >
                                Login to Clarity Portal
                            </WildHealthButton>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </div>
    );
}