import {
    Box,
    Dialog,
    DialogContent,
    IconButton,
    TextField
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import React from 'react';
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { useFacade } from "./addFellowButtonComponent.hooks";
import { useStyles } from "./addFellowButtonComponent.styles";
import { phoneNumberInput } from '../../../common/components/PhoneNumberInput';
import commonUseStyles from '../../../common/styles/common.styles';

interface AddFellowButtonComponentProps {
    locationId?: number;
    rosterId: number;
}

export const AddFellowButtonComponent: React.FC<AddFellowButtonComponentProps> = (props: AddFellowButtonComponentProps) => {
    const [
        {
            isLoading,
            isOpen,
            fellow,
            errors,
        },
        handleChanges,
        handleCreate,
        handleOpen,
        handleClose,
    ] = useFacade(props.rosterId);

    const commonClasses = commonUseStyles();
    const classes = useStyles();

    return (
        <>
            <WildHealthButton
                id="add-fellow-roster"
                size='medium'
                onClick={() => handleOpen()}
            >
                Add Fellow
            </WildHealthButton>

            <Dialog
                onClose={() => handleClose()}
                className={classes.root}
                open={isOpen}
            >
                <DialogContent className={classes.dialogContent}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box>
                            <span className={clsx(commonClasses.size24, commonClasses.textMedium)}>Add Fellow</span>
                        </Box>
                        <Box display="flex " alignItems="center">
                            <IconButton
                                id="add-fellow-close"
                                className={classes.dialogTitleCloseAction}
                                onClick={() => handleClose()}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box mt={5}>
                        <Box className={commonClasses.inputLabel} mb={1}>First Name</Box>
                        <TextField
                            id={`firstName`}
                            fullWidth
                            required
                            size="small"
                            InputProps={{ className: 'input', classes }}
                            variant="outlined"
                            value={fellow.firstName}
                            error={!!errors['firstName']}
                            helperText={errors['firstName']}
                            onChange={(event) => handleChanges('firstName', event.target.value)}
                        />
                    </Box>
                    <Box mt={2}>
                        <Box className={commonClasses.inputLabel} mb={1}>Last Name</Box>
                        <TextField
                            id={`lastName`}
                            fullWidth
                            required
                            size="small"
                            InputProps={{ className: 'input', classes }}
                            variant="outlined"
                            value={fellow.lastName}
                            error={!!errors['lastName']}
                            helperText={errors['lastName']}
                            onChange={(event) => handleChanges('lastName', event.target.value)}
                        />
                    </Box>
                    <Box mt={2}>
                        <Box className={commonClasses.inputLabel} mb={1}>Credentials</Box>
                        <TextField
                            id={`credentials`}
                            fullWidth
                            size="small"
                            InputProps={{ className: 'input', classes }}
                            variant="outlined"
                            value={fellow.credentials}
                            error={!!errors['credentials']}
                            helperText={errors['credentials']}
                            onChange={(event) => handleChanges('credentials', event.target.value)}
                        />
                    </Box>
                    <Box mt={2}>
                        <Box className={commonClasses.inputLabel} mb={1}>Fellow’s Email</Box>
                        <TextField
                            id={`email`}
                            required
                            fullWidth
                            size="small"
                            InputProps={{ className: 'input', classes }}
                            variant="outlined"
                            value={fellow.email}
                            error={!!errors['email']}
                            helperText={errors['email']}
                            onChange={(event) => handleChanges('email', event.target.value)}
                        />
                    </Box>
                    <Box mt={2}>
                        <Box className={commonClasses.inputLabel} mb={1}>Phone Number</Box>
                        <TextField
                            id={`phoneNumber`}
                            fullWidth
                            size="small"
                            placeholder="(000) 000-0000"
                            variant="outlined"
                            error={!!errors['phoneNumber']}
                            helperText={errors['phoneNumber']}
                            value={fellow.phoneNumber ?? ""}
                            InputProps={{
                                className: 'input',
                                inputComponent: phoneNumberInput,
                                classes,
                            }}
                            onChange={(event) => handleChanges('phoneNumber', event.target.value)}
                        />
                    </Box>
                    <Box mt={5} display="flex" justifyContent="flex-end" alignItems="center">
                        <Box mx={2}>
                            <WildHealthButton
                                id="create-fellow-cancel"
                                onClick={() => handleClose()}
                                color="tertiary"
                                size='medium'
                                disabled={isLoading}
                            >
                                Cancel
                            </WildHealthButton>
                        </Box>
                        <Box>
                            <WildHealthButton
                                id="create-fellow"
                                size='medium'
                                onClick={handleCreate}
                                autoFocus
                                disabled={isLoading}
                                loading={isLoading}
                            >
                                Add Fellow
                            </WildHealthButton>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
}
