import { Store, StoreConfig } from '@datorama/akita';
import { ChronologicalEventModel, EngagementPatientModel, MyPatientModel, PatientModel, PatientShortModel, PatientTaskModel, PracticumPatientModel, TodoPatientJourneyTaskModel, CompletedPatientJourneyTaskModel, PatientJourneyModel, JourneyTasksTabs, CallToActionModel, HealthCoachEngagementTaskModel } from '../../models/patient.model';
import { TimeZoneModel } from "../../../timezones/models/timezone.model";
import {KeyValueModel} from "../../../healthReport/models/healthReport.models";
import { VisitPrepModel } from '../../models/visitPrep.model';

/**
 * Represents patient state
 */
export interface PatientUnionState {
    patients: PatientShortModel[];
    patientsTotalCount: number;
    coachPatients: PatientModel[];
    targetPatient: PatientModel;
    practicumPatients: PracticumPatientModel[];
    patientTimeZone: TimeZoneModel;
    myPatients: MyPatientModel[];
    chronologicalEvents: ChronologicalEventModel[];
    patientTasks: PatientTaskModel[];
    patientsEngagement: EngagementPatientModel[];
    patientJourneyTasks: PatientJourneyModel;
    todoPatientJourneyTasks: TodoPatientJourneyTaskModel[];
    completedJourneyTasks: CompletedPatientJourneyTaskModel[];
    callToActions: CallToActionModel[];
    isRequestOpen: boolean;
    isReceivedOpen: boolean;
    healthCoachEngagements: HealthCoachEngagementTaskModel[];
    tab: JourneyTasksTabs;
    visitPrep: VisitPrepModel;
    isDownloadingTask: boolean;
}

/**
 *  Creates initial state
 */
export function createInitialState(): PatientUnionState {
    return {
        targetPatient: null,
        patients: [],
        patientsTotalCount: 0,
        coachPatients: [],
        practicumPatients: [],
        patientTimeZone: null,
        myPatients: [],
        chronologicalEvents: [],
        patientTasks: [],
        patientsEngagement: [],
        patientJourneyTasks: null,
        todoPatientJourneyTasks: [],
        completedJourneyTasks: [],
        callToActions: [],
        isRequestOpen: false,
        isReceivedOpen: false,
        healthCoachEngagements: [],
        tab: JourneyTasksTabs.Todo,
        visitPrep: null,
        isDownloadingTask: false,
    };
}

/**
 * Provide patient state store and management
 */
@StoreConfig({ name: 'patient', resettable: true })
export class PatientStore extends Store<PatientUnionState> {
    constructor() {
        super(createInitialState());
    }

    public updateShortPatientModel(patient: PatientShortModel): void {
        this.update({ patients: this.getValue().patients.map(x => x.id === patient.id ? patient : x) });
    }

    public updateCoachPatients(patient: PatientModel): void {
        if (patient.id && this.getValue().coachPatients.find(x => x.id === patient.id)) {
            this.update({
                coachPatients: this.getValue().coachPatients.map(x => x.id === patient.id ? patient : x)
            });
        } else {
            this.update({
                coachPatients: [...this.getValue().coachPatients, patient]
            });
        }
    }

    public updatePatientStatuses(patient: PatientModel): void {
        this.update({
            coachPatients: this.getValue().coachPatients.map(x => {
                if (x.id === patient.id) {
                    x.journeyStatus = patient.journeyStatus;
                    x.dnaStatus = patient.dnaStatus;
                    x.labsStatus = patient.labsStatus;
                }

                return x;
            })
        });
    }

    public updateCompletedJourneyTasks(task: CompletedPatientJourneyTaskModel) {
        if (task.id && this.getValue().completedJourneyTasks.find(x => x.id === task.id)) {
            this.update({
                completedJourneyTasks: this.getValue().completedJourneyTasks.map(x => x.id === task.id ? task : x)
            });
        } else {
            this.update({
                completedJourneyTasks: [...this.getValue().completedJourneyTasks, task]
            });
        }
    }

    public updateCallToActions(action: CallToActionModel) {
        this.update({
            callToActions: this.getValue().callToActions.map(x => x.id === action.id ? action : x)
        });
    }

    public toggleRequestDialog(status: boolean): void {
        this.update({ isRequestOpen: status });
    }

    public toggleRequestReceivedDialog(status: boolean): void {
        this.update({ isReceivedOpen: status });
    }

    public removeCompletedEngagementTask(id: number) {
        this.update({
            healthCoachEngagements: this.getValue().healthCoachEngagements.filter(x => x.engagementId !== id)
        });
    }

    public setTab(tab: JourneyTasksTabs) {
        this.update({tab: tab});
    }

    public updateSettings(newSetting: KeyValueModel) {
        const targetPatient = this.getValue().targetPatient;

        if (!targetPatient) return;

        targetPatient.settings = targetPatient.settings.map(x => x.key === newSetting.key ? newSetting : x);

        this.update({
            targetPatient: targetPatient
        })
    }

    public clearVisitPrep() {
        this.update({ visitPrep: null })
    }
    
    public toggleDownloadRewardTask(status: boolean): void {
        this.update({ isDownloadingTask: status });
    }
}

export const patientStore = new PatientStore();
