import React, { useState } from 'react';
import { Box, Checkbox, Grid, IconButton, TextField, Tooltip } from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import { UserType } from '../../../auth/models/auth.enums';
import { authQuery } from '../../../auth/stores/auth';
import { insertString } from '../../../common/helpers/insert-string';
import { selectShortcutService } from '../../../healthReport/services/selectShortcut.service';
import { GoalCategory, GoalCommonModel, GoalModel } from '../../models/notes.models';
import { useStyles } from './GoalsComponent.styles';
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import {colors} from "../../../common/constants/colors";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import {WildHealthButton} from "../../../common/components/wildHealthButton/WildHealthButton";
import {GoalsCompletionDateComponent} from "../goalCompletionDateComponent/GoalCompletionDateComponent";
import InfoIcon from '@material-ui/icons/Info';
import moment from 'moment';
import { ReactComponent as StartOutlined } from '@img/icons/StarOutlined.svg'
import { ReactComponent as StarChecked } from '@img/icons/starChecked.svg'
import commonUseStyles from '../../../common/styles/common.styles';

export interface GoalsComponentProps {
    commonGoals: GoalCommonModel[];
    visitDate: Date;
    dataKey: string;
    goals: GoalModel[];
    handleGoalsChanges: (key: string, values: GoalModel[]) => void;
    handleValidateGoals: (goalName: string, id:number) => boolean;
}

enum DataType {
    Goal = 1,
    Intervention = 2
}

interface GoalsComponentState {
    enteringData: DataType;
    valueToAdd: string;
    targetId: number | null;
}

export const GoalsComponent: React.FC<GoalsComponentProps> = (props: GoalsComponentProps) => {
    let {
        commonGoals = null,
        visitDate,
        dataKey,
        goals,
        handleGoalsChanges,
        handleValidateGoals
    } = props;

    const [state, setState] = useState({
        enteringData: DataType.Goal,
        valueToAdd: null,
        targetId: null
    } as GoalsComponentState)

    const [focus, setFocus] = useState(true)
    const [isSubmitted, setSubmitted] = useState(true)

    const classes = useStyles();
    const commonClasses = commonUseStyles();

    const customTooltipClasses = {
        tooltip: classes.customWidth,
        arrow: classes.arrow,
        tooltipPlacementBottom: classes.tooltipPlacement,
        tooltipPlacementTop: classes.tooltipPlacement,
    };

    const controlIcon = <CheckBoxOutlineBlankIcon style={{ fill: colors.mediumGray }} />;
    const controlCheckedIcon = <CheckBoxIcon style={{ fill: colors.main }} />;
    const starIcon = <StartOutlined></StartOutlined>;
    const statIconChecked = <StarChecked style={{ fill: colors.main }}></StarChecked>;

    const handleAddCommonGoals = (goal: GoalCommonModel) => {
        const goalId = Date.now() + Math.floor(Math.random() * 100)
      
        goals.push({
            ...goal,
            id: goalId,
            date: null,
            isTopGoal: false,
            category: GoalCategory.Other,
            completionDate: goal.completionValue ? moment(visitDate).add(goal.completionValue, 'months').toDate() : null,
            interventions: goal.interventions.map((el, i )=> {
                return {
                    ...el,
                    id: goalId + i,
                }
            })
        });
        handleGoalsChanges(dataKey, goals);
    }

    const handleCompleteGoal = (goalId: number, isCompleted: boolean) => {
        const goal = goals.find(x => x.id === goalId);

        if (!goal) {
            return;
        }

        goal.isCompleted = isCompleted;

        handleGoalsChanges(dataKey, goals);
    }

    const handleTopGoal = (goalId: number, isCompleted: boolean) => {
        const goal = goals.find(x => x.id === goalId);

        if (!goal) {
            return;
        }

        goal.isTopGoal = isCompleted;

        handleGoalsChanges(dataKey, goals);
    }

    const handleDeleteGoal = (id: number) => {
        goals = goals.filter(x => x.id !== id);

        handleGoalsChanges(dataKey, goals);
    }

    const handleDeleteIntervention = (goalId: number, id: number) => {
        const goal = goals.find(x => x.id === goalId);

        goal.interventions = goal.interventions.filter(x => x.id !== id);

        handleGoalsChanges(dataKey, goals);
    }

    const handleEnableAdd = (dataType: DataType, goalId: number) => {
        setState(state => ({
            ...state,
            valueToAdd: '',
            enteringData: dataType,
            targetId: goalId
        }));
        setSubmitted(false);
    }

    const handleAddChanges = (value: string) => {
        setState(state => ({ ...state, valueToAdd: value }));
    }

    const handleEditGoal = (id: number, value: string) => {
        const goal = goals.find(x => x.id === id);

        if (!goal) {
            return;
        }

        goal.name = value;

        handleGoalsChanges(dataKey, goals);
    }

    const handleCompletionDateChanges = (id: number, completionDate: Date) => {
        const goal = goals.find(x => x.id === id);

        if (!goal) {
            return;
        }

        goal.completionDate = completionDate;

        handleGoalsChanges(dataKey, goals);
    }

    const handleEditIntervention = (id: number, value: string) => {
        const interventions = goals.map(x => x.interventions).flat();
        const intervention = interventions.find(x => x.id === id);

        if (!intervention) {
            return;
        }

        intervention.name = value;

        handleGoalsChanges(dataKey, goals);
    }

    const handleAddSubmit = () => {
        if (isSubmitted) {
            return;
        }

        if (state.valueToAdd !== null && state.valueToAdd !== '') {
            if (state.enteringData === DataType.Goal) {
                goals.push({
                    id: Date.now(),
                    date: null,
                    isCompleted: false,
                    isTopGoal: false,
                    category: GoalCategory.Other,
                    completionDate: null,
                    name: state.valueToAdd,
                    interventions: []
                });
            }
            else {
                const goal = goals.find(x => x.id === state.targetId);
                goal.interventions.push({
                    id: Date.now(),
                    name: state.valueToAdd,
                })
            }

            handleGoalsChanges(dataKey, goals);
        }

        setState(state => ({
            ...state,
            valueToAdd: null,
            targetId: null
        }));

        setSubmitted(true);
    }

    const handleOnAddKeyDown = (event: any, id: string) => {
        if (event.keyCode === 51 && event.altKey) {
            const userType = authQuery.getType();
            if (UserType.Employee === userType) {
                const value = (document.getElementById(id) as any).value;
                const cursorPos = (document.getElementById(id) as any).selectionStart;
                setFocus(false);
                setTimeout(() => {
                    selectShortcutService.select().subscribe((data) => {
                        data && handleAddChanges(insertString(value, data, cursorPos + 1));
                        setFocus(true);
                    })
                }, 1)
            }
        }

        if (event.keyCode === 13 && !event.shiftKey) {
            handleAddSubmit()
        }
    }

    const handleOnEditKeyDown = (event: any, id: number, dataType: DataType) => {
        if (event.keyCode === 51 && event.altKey) {
            const userType = authQuery.getType();
            if (UserType.Employee === userType) {
                const value = (document.getElementById(event.target.id) as any).value;
                const cursorPos = (document.getElementById(event.target.id) as any).selectionStart;
                setFocus(false);
                setTimeout(() => {
                    selectShortcutService.select().subscribe((data) => {
                        if (data) {
                            const modifiedValue = insertString(value, data, cursorPos + 1);
                            switch (dataType) {
                                case DataType.Goal: handleEditGoal(id, modifiedValue); break;
                                case DataType.Intervention: handleEditIntervention(id, modifiedValue); break;
                            }
                        }
                    })
                }, 1)
            }
        }
    }


    const renderViewElement = (goal: GoalCommonModel) =>
        <Box display='flex' key={goal.id} mr={1.5} mb={1.5} className={classes.rootView}>
            {goal.interventions.length
                ?
                <Box width='28px' minWidth='28px' display='flex' alignItems='center' mr={1} justifyContent='flex-end'>
                    <Box position='relative' pt={0.5}>
                        <Tooltip placement="bottom" arrow
                            title={<Box className={classes.notificationToolTipText}>
                                <Box>
                                <Box>Interventions ({goal.interventions.length}):</Box>
                                {goal.interventions.map(intervention => {
                                    return <Box key={intervention.id}>
                                        <span className={classes.interventionDot}>&#x2022;</span>
                                        <span className={classes.interventionName}>{intervention.name}</span>
                                    </Box>
                                })}
                            </Box>
                            </Box>}
                            classes={customTooltipClasses}
                        >
                            <InfoIcon className={classes.iconInfo} fontSize='small' style={{ fill: colors.gray2 }} />
                        </Tooltip>
                    </Box>
                </Box>
                : <></>}
            <Box>
                {goal.name}
            </Box>
            <Box
                className={classes.button}
                onClick={() => handleAddCommonGoals(goal)}>
                <AddIcon fontSize='small' />
            </Box>
        </Box>

    const renderCommonGoals = () => {
        return <Box mb={3} mt={1}>
            <Box color={colors.gray1}>Most Common Goals</Box>
            <Box mt={1.5} display='flex' flexWrap='wrap'>
                {commonGoals.map(goal => renderViewElement(goal))}
            </Box>
        </Box>
    }
    
    return (
        <Box mt={2}>
            <Grid container>
                {commonGoals && renderCommonGoals()}
                {
                    goals.map((goal, i) => (
                        <Box display="flex" width={1}>
                            <Box className={classes.goalSideBar} my={3} display="flex" alignItems="center">
                                <Box className={classes.goalTitle}>
                                    Goal {i + 1}
                                </Box>
                            </Box>
                            <Box mb={3} py={1} className={classes.goal} flexGrow={1}>
                                <Grid md={12} sm={12}>
                                    <Box display='flex' justifyContent='space-between' px={2}>
                                        <Box display="flex">
                                            <Box display="flex" alignItems="center">
                                                <Box>
                                                    <Checkbox
                                                        icon={controlIcon}
                                                        color="primary"
                                                        checkedIcon={controlCheckedIcon}
                                                        checked={goal.isCompleted ?? false}
                                                        onChange={() => handleCompleteGoal(goal.id, !(goal.isCompleted ?? false))}
                                                    />
                                                </Box>
                                                <Box>
                                                    <span>
                                                        Mark goal as completed
                                                    </span>
                                                </Box>
                                            </Box>
                                            <Box display="flex" alignItems="center" ml={3}>
                                                <Box>
                                                    <Checkbox
                                                        icon={starIcon}
                                                        color="primary"
                                                        checkedIcon={statIconChecked}
                                                        checked={goal.isTopGoal ?? false}
                                                        onChange={() => handleTopGoal(goal.id, !(goal.isTopGoal ?? false))}
                                                    />
                                                </Box>
                                                <Box>
                                                    <span>
                                                        Mark as top goal
                                                    </span>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <IconButton className={classes.deleteGoalIcon} onClick={() => handleDeleteGoal(goal.id)}>
                                                <Delete />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid md={12} xs={12}>
                                    <Grid container>
                                        <Grid md={7} xs={12}>
                                            <Box p={2}>
                                                <span className={classes.subtitle}>{`Goal ${i + 1}`}</span>
                                            </Box>
                                            <Box px={2}>
                                                <Box width={0.9}>
                                                {
                                                    handleValidateGoals(goal.name, goal.id) &&
                                                    <Box>
                                                        <span className={commonClasses.error}>Goal name mush be lower than 255 symbols.</span>
                                                    </Box>
                                                }
                                                    <TextField
                                                        id={`${dataKey}-goal-edit-${goal.id}`}
                                                        onKeyDown={(e) => handleOnEditKeyDown(e, goal.id, DataType.Goal)}
                                                        fullWidth
                                                        minRows={1}
                                                        maxRows={15}
                                                        multiline
                                                        type='text'
                                                        size="small"
                                                        variant="outlined"
                                                        value={goal.name}
                                                        InputProps={{ className: 'input' }}
                                                        onChange={(e) => handleEditGoal(goal.id, e.target.value)}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box p={2}>
                                                <span className={classes.subtitle}>Interventions</span>
                                            </Box>
                                            {
                                                goal.interventions.map((intervention) => (
                                                    <>
                                                        <Box mb={2} px={2} display='flex' justifyContent="space-between" width={1} alignItems="center">
                                                            <Box width={0.9}>
                                                                <TextField
                                                                    id={`${dataKey}-intervention-edit-${intervention.id}`}
                                                                    onKeyDown={(e) => handleOnEditKeyDown(e, intervention.id, DataType.Intervention)}
                                                                    fullWidth
                                                                    multiline
                                                                    minRows={1}
                                                                    maxRows={15}
                                                                    type='text'
                                                                    size="small"
                                                                    variant="outlined"
                                                                    value={intervention.name}
                                                                    InputProps={{ className: 'input' }}
                                                                    onChange={(e) => handleEditIntervention(intervention.id, e.target.value)}
                                                                />
                                                            </Box>
                                                            <Box width={0.1}>
                                                                <IconButton className={classes.deleteInterventionIcon} onClick={() => handleDeleteIntervention(goal.id, intervention.id)}>
                                                                    <RemoveIcon />
                                                                </IconButton>
                                                            </Box>
                                                        </Box>
                                                    </>
                                                ))
                                            }
                                            {
                                                state.valueToAdd !== null && state.enteringData === DataType.Intervention && state.targetId === goal.id
                                                    ? <Box px={2} width={0.9}>
                                                        <TextField
                                                            autoFocus
                                                            id={`${dataKey}-intervention-add-${goal.id}`}
                                                            onBlur={() => {
                                                                focus && handleAddSubmit()
                                                            }}
                                                            multiline
                                                            minRows={1}
                                                            maxRows={15}
                                                            onKeyDown={(e) => handleOnAddKeyDown(e, `${dataKey}-intervention-add-${goal.id}`)}
                                                            label='Add Intervention'
                                                            fullWidth
                                                            type='text'
                                                            size="small"
                                                            variant="outlined"
                                                            value={state.valueToAdd}
                                                            InputProps={{ className: 'input' }}
                                                            onChange={(e) => handleAddChanges(e.target.value)}
                                                        />
                                                    </Box>
                                                    : <Box px={2} display='flex' alignItems='stretch'>
                                                        <Box onClick={() => handleEnableAdd(DataType.Intervention, goal.id)}>
                                                            <Add className={classes.addIcon} />
                                                        </Box>
                                                        <Box className={classes.addText} ml={2}>
                                                            <span>{'Add Intervention'}</span>
                                                        </Box>
                                                    </Box>
                                            }
                                        </Grid>
                                        <Grid md={5} xs={12}>
                                            <Box p={2}>
                                                <Box>
                                                    <span className={classes.subtitle}>completion date</span>
                                                </Box>
                                                <Box>
                                                    <GoalsCompletionDateComponent
                                                        visitDate={visitDate}
                                                        completionDate={goal.completionDate}
                                                        handleChanges={(date) => handleCompletionDateChanges(goal.id, date)}
                                                    />
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    ))
                }
            </Grid>
            <Grid md={6} xs={12}>
                <Box>
                    {
                        state.valueToAdd !== null && state.enteringData === DataType.Goal
                            ? <Box>
                                <TextField
                                    id={`${dataKey}-goal-add`}
                                    onBlur={() => {
                                        focus && handleAddSubmit()
                                    }}
                                    autoFocus
                                    onKeyDown={(e) => handleOnAddKeyDown(e, `${dataKey}-goal-add`)}
                                    label='Add New Goal'
                                    multiline
                                    minRows={1}
                                    maxRows={15}
                                    fullWidth
                                    type='text'
                                    size="small"
                                    variant="outlined"
                                    value={state.valueToAdd}
                                    InputProps={{ className: 'input' }}
                                    onChange={(e) => handleAddChanges(e.target.value)}
                                />
                            </Box>
                            : <Box>
                                <WildHealthButton
                                    size="medium"
                                    width={215}
                                    style={{ whiteSpace: 'nowrap' }}
                                    id='add-new-goal'
                                    onClick={() => { handleEnableAdd(DataType.Goal, null) }}
                                    color="secondary"
                                >
                                    <Box display="flex" alignItems="center">
                                        <Box>
                                            Add new goal
                                        </Box>
                                        <Box ml={2} mt={1}>
                                            <AddIcon />
                                        </Box>
                                    </Box>
                                </WildHealthButton>
                            </Box>
                    }
                </Box>
            </Grid>
            <Grid md={6} xs={12} />
        </Box>
    );
}