import React from 'react';
import { Box, useTheme, useMediaQuery } from "@material-ui/core";
import moment from "moment";
import clsx from "clsx";
import { ConversationAuthorModel, ConversationModelBase, EmployeeConversationParticipantModel } from "../../models/conversation.models";
import { useFacade } from "./InternalConversationViewComponent.hooks";
import { useStyles } from "../conversationComponent/conversationComponent.styles";
import { toCurrentTimeZone } from "../../../timezones/helpers/timezone";
import { InternalThreadViewComponent } from "../internalThreadViewComponent/InternalThreadViewComponent";


interface InternalConversationComponentProps {
    conversation: ConversationModelBase;
    author: ConversationAuthorModel;
    threadHeight?: string;
    signedBy: EmployeeConversationParticipantModel;
}

export const InternalConversationViewComponent: React.FC<InternalConversationComponentProps> = (props: InternalConversationComponentProps) => {
    const {
        conversation,
        author,
        threadHeight,
        signedBy,
    } = props;

    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const [
        {
            isLoadingMessages,
            scrollToIndex,
            messages,
        },

    ] = useFacade(conversation);

    const displayName = (employee: EmployeeConversationParticipantModel): string => {
        return `${employee.firstName} ${employee.lastName}`;
    }

    const displayDate = (date: Date): string => {
        return date ? `on ${moment(toCurrentTimeZone(date)).format("MMM D, YYYY")}` : '';
    }

    const displayDateTime = (date: Date): string => {
        return date ? `${moment(toCurrentTimeZone(date)).format("MMM D, YYYY h:mm a")}` : '';
    }

    return (
        <>
            <Box
                id='thread'
                className={clsx(classes.thread, 'flat-scroll')}
                height={threadHeight}
                maxHeight={threadHeight}
            >
                <InternalThreadViewComponent
                    isLoadingMessages={isLoadingMessages}
                    messages={ messages }
                    scrollToIndex={scrollToIndex}
                    participants={[...conversation.employees.filter(i => !i.isDeleted), ...conversation.patients]}
                    author={author}
                />
            </Box>
            {isSmallScreen ?
                <Box py={1} className="wh-tw-text-center wh-tw-bg-gray3">
                    <Box className="wh-tw-text-sm">{`Signed off by ${displayName(signedBy)}.`}</Box>
                    <Box className="wh-tw-text-xs">{displayDateTime(signedBy.signDate)}</Box>
                </Box>
                :
                <Box className={classes.closedTicket}>
                    {` This conversation has been signed off by ${displayName(signedBy)} ${displayDate(signedBy.signDate)}.`}
                </Box>
            }
        </>

    )
}