import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { onEmit } from "../../../common/helpers/on-emit";
import { handleCompare } from "../../../common/sorting/helpers/handle-compare";
import { SortingDirection } from "../../../common/sorting/models/sorting-destination";
import { ISortingState } from "../../../common/sorting/models/sorting-state";
import { IErrorState } from "../../../common/validation/error-state";
import { confirmService } from "../../../../services/confirm.service";
import { UpdateConversationTemplateModel, ConversationType, ConversationTemplateModel } from "../../models/conversation.models";
import { employeeConversationsQuery } from "../../stores/employeeConversationsStore/employeeConversations.query";
import { conversationsService } from "../../services/conversations.service";
import { modifyConversationTemplateValidator } from "./modifyConversationTemplate.validator";

export enum SortingSource {
    None,
    Name = "name",
    Order = "order",
    Type = "type",
}

interface ConversationTemplatesState extends ISortingState, IErrorState {
    isLoading: boolean;
    isSubmitting: boolean;
    isOpen: boolean;
    menuAnchorEl: HTMLButtonElement | null;
    conversationTemplates: ConversationTemplateModel[];
    createTemplate: UpdateConversationTemplateModel;
    selectedId: number;
}

const initialParmas: UpdateConversationTemplateModel = {
    id: 0,
    name: '',
    description: '',
    text: '',
    order: 0,
    type: ConversationType.PatientPlayground
}

export function useFacade(): [
    ConversationTemplatesState,
    (source: string, direction: SortingDirection) => void,
    (id: number) => void,
    (id: number, menuAnchorEl: HTMLButtonElement) => void,
    () => void,
    () => void,
    (field: string, value: any) => void
] {

    const [state, setState] = useState({
        isLoading: true,
        conversationTemplates: [],
        selectedId: -1,
        createTemplate: initialParmas,
        sortingColumns: [
            { title: 'Name', source: SortingSource.Name, direction: SortingDirection.Asc },
            { title: 'Description' },
            { title: 'Text' },
            { title: 'Order', source: SortingSource.Order, direction: SortingDirection.Asc },
            { title: 'Type', source: SortingSource.Type, direction: SortingDirection.Asc },
        ],
        sortingSource: SortingSource.None,
        errors: {},
    } as ConversationTemplatesState);

    const setDirection = (source: string, direction: SortingDirection) => {
        const itemIndex = state.sortingColumns.findIndex(item => item.source === source);
        state.sortingColumns[itemIndex].direction = direction;
        setState(state => ({ ...state, columns: state.sortingColumns }));
    }

    const handleSorting = (source: string, direction: SortingDirection) => {
        if (state.sortingSource === source) {
            direction = direction === SortingDirection.Asc
                ? SortingDirection.Desc
                : SortingDirection.Asc;

            setDirection(source, direction);
        }
        setState(state => ({
            ...state,
            sortingSource: source,
            conversationTemplates: state.conversationTemplates.sort((p1, p2) => handleCompare(p1, p2, direction, source))
        }));
    }

    const handleToggleTemplateModify = (id: number) => {
        const template = state.conversationTemplates.find(t => t.id === id)
        setState((state) => ({
            ...state,
            isOpen: !state.isOpen,
            menuAnchorEl: null,
            selectedId: id,
            createTemplate: Object.assign({}, template ? template : initialParmas),
            errors: {}
        }))
    }

    const handleToggleTemplateActions = (id: number, menuAnchorEl: HTMLButtonElement) => {
        setState((state) => ({
            ...state,
            selectedId: id,
            menuAnchorEl: menuAnchorEl,
            errors: {}
        }))
    }
  
    const handleDeleteTemplate = () => {
        setState(state => ({
            ...state,
            menuAnchorEl: null
        }));
  
        confirmService.confirm(
            'Delete Conversation Template',
            'Are you sure you want to delete this template?',
            'Yes',
            'Cancel',
            'danger')
            .subscribe(() => {
                setState(state => ({
                    ...state,
                    isSubmitting: true
                }));
        
                const cb = () => setState(state => ({ ...state, isSubmitting: false, selectedId: -1 }));
                conversationsService.deleteConversationTemplate(state.selectedId).subscribe(cb, cb);
            });
    }

    const handleChanges = (field: string, value: any) => {
        modifyConversationTemplateValidator.validateAndSetState(state, setState, field, value);

        const params = state.createTemplate;
        
        params[field] = value;

        setState({...state, createTemplate: Object.assign({}, params)});
    }
  
    const handleSaveTemplate = () => {
        modifyConversationTemplateValidator.validateObjectAndSetState(state, setState, state.createTemplate);
        if (!modifyConversationTemplateValidator.stateIsValid(state)) {
            return;
        }
  
        setState({...state, isSubmitting: true })

        const action = state.selectedId > 0 ? conversationsService.updateConversationTemplate(state.createTemplate) : conversationsService.createConversationTemplate(state.createTemplate);
  
        const cb = () => setState(state => ({ ...state, isSubmitting: false, isOpen: false, selectedId: -1, createTemplate: Object.assign({}, initialParmas) }));
  
        action.subscribe(cb, cb);
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<ConversationTemplateModel[]>(employeeConversationsQuery.templates$, templates => {
                setState(state => ({
                    ...state,
                    conversationTemplates: templates
                }));
            }),
        ];

        const cb = () => setState(state => ({ ...state, isLoading: false }));

        conversationsService.getConversationTemplates(ConversationType.PatientPlayground).subscribe(cb, cb);

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [])

    return [
        state,
        handleSorting,
        handleToggleTemplateModify,
        handleToggleTemplateActions,
        handleSaveTemplate,
        handleDeleteTemplate,
        handleChanges
    ];
}