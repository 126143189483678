import { Box } from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';
import { colors } from '../../../common/constants/colors';
import clsx from "clsx";
import React from "react";
import { PageTitleComponent } from "../../../../components/pageTitleComponent/PageTitleComponent";
import { Gender } from "../../../common/models/user.models";
import { EmployeeType } from "../../../employee/models/employee.enums";
import { useFacade } from "./patientTitleComponent.hooks";
import { useStyles } from "./PatientTitleComponent.style";
import { fullName } from "../../../common/helpers/display-name";
import { EmployeeShortModel } from "../../../employee/models/employee.models";
import { HealthSummaryDataModel } from "../../../healthSummary/models/healthSummary.models";
import { PatientMedicationModel } from "../../../patientSupplements/models/patientMedicationModel";
import { PatientSupplementModel } from "../../../patientSupplements/models/patientSupplementModel";
import { displayDate, displayShortAge } from '../../../common/helpers/display-date';

interface PatientTitleComponentProps {
    patientId: number,
}

export const PatientTitleComponent: React.FC<PatientTitleComponentProps> = (props: PatientTitleComponentProps) => {
    const {
        patientId
    } = props;
    const classes = useStyles();

    const displayNamesList = (items: PatientMedicationModel[] | PatientSupplementModel[]) => {
        if (!items || !items.length) {
            return '-'
        }
        let res = '';
        const viewItems = items && items.length > 5 ? items.slice(0, 5) : items
        viewItems.forEach((el, i) => (i === viewItems.length - 1) ? res += `${el.name}` : res += `${el.name}, `)

        return res;
    }

    const displayProblemName = (items: HealthSummaryDataModel[]) => {
        if (!items || !items.length) {
            return '-'
        }
        let res = '';
        const viewItems = items.length > 5 ? items.slice(0, 5) : items
        viewItems.forEach((el, i) => (i === viewItems.length - 1) ? res += `${el.name}` : res += `${el.name}, `)

        return res;
    }

    const displayAssignedEmployees = (assignedEmployees: EmployeeShortModel[]) => {
        if (assignedEmployees.length) {
            return assignedEmployees.map((e) => fullName(e)).join(", ");
        }
        return '-';
    }

    const [
        {
            patient,
            vital,
            problemList
        },
        displayPatientName,
    ] = useFacade(patientId);

    const displayPatientBirthday = (date: Date) => {
       return <>{displayShortAge(patient.birthday)}<span className={classes.patientSubtext} >{` (${displayDate(patient.birthday)})`}</span></>
    } 

    return (
        <Box className={classes.patientTitle} ml={6}>
            <PageTitleComponent
                patientId={patientId}
                title={displayPatientName(patient)}
                endAdornment={
                    patient ?
                        <Box position='relative'>
                            <InfoIcon className={classes.icon} fontSize='small' style={{ fill: colors.gray2 }} />
                            <Box className={clsx(classes.tooltip, 'tooltip-element')} >
                                <Box display='flex' flexDirection='column' mr={2}>
                                    <Box mt={1} display="flex">
                                        <Box className={classes.infoTitle}>Age: </Box>
                                        <Box className={classes.patientInfo}> {patient.birthday ? displayPatientBirthday(patient.birthday) : '-'} </Box>
                                    </Box>
                                    <Box mt={1} display="flex">
                                        <Box className={classes.infoTitle}>Sex: </Box>
                                        <Box className={classes.patientInfo}>{patient.gender ? Gender[patient.gender] : '-'}</Box>
                                    </Box>
                                    <Box mt={1} display="flex" >
                                        <Box className={classes.infoTitle}>BMI: </Box>
                                        <Box className={classes.patientInfo}>{vital.find(x => x.key === 'VITAL_VALUE_BMI')?.value ?? '-'}</Box>
                                    </Box>
                                    <Box mt={1} display="flex" >
                                        <Box className={classes.infoTitle}>Height: </Box>
                                        <Box className={classes.patientInfo}>{vital.find(x => x.key === 'VITAL_VALUE_HEIGHT')?.value ?? '-'}</Box>
                                    </Box>
                                    <Box mt={1} display="flex" >
                                        <Box className={classes.infoTitle}>Weight: </Box>
                                        <Box className={classes.patientInfo}>{vital.find(x => x.key === 'VITAL_VALUE_WEIGHT')?.value ?? '-'}</Box>
                                    </Box>
                                    <Box mt={1} display="flex" >
                                        <Box className={classes.infoTitle}>Plan: </Box>
                                        <Box className={classes.patientInfo}>{patient.subscription?.displayName ? patient.subscription.displayName : '-'}</Box>
                                    </Box>
                                </Box>
                                <Box display='flex' flexDirection='column'>
                                    <Box mt={1} display="flex" >
                                        <Box className={classes.infoTitleSecondaty}>State: </Box>
                                        <Box className={classes.patientInfo}>{patient.shippingAddress?.state ? patient.shippingAddress.state : '-'}</Box>
                                    </Box>
                                    <Box mt={1} display="flex" >
                                        <Box className={classes.infoTitleSecondaty}>Supplements: </Box>
                                        <Box className={classes.patientInfo} >{displayNamesList(patient.supplements)}</Box>
                                    </Box>
                                    <Box mt={1} display="flex" >
                                        <Box className={classes.infoTitleSecondaty}>Medications: </Box>
                                        <Box className={classes.patientInfo}>{displayNamesList(patient.medications)}</Box>
                                    </Box>
                                    <Box mt={1} display="flex" >
                                        <Box className={classes.infoTitleSecondaty}>Problem list: </Box>
                                        <Box className={classes.patientInfo}>{displayProblemName(problemList)}</Box>
                                    </Box>
                                    <Box mt={1} display="flex" >
                                        <Box className={classes.infoTitleSecondaty}>HC: </Box>
                                        <Box className={classes.patientInfo}>{displayAssignedEmployees(patient.assignedEmployees.filter(c => c.type === EmployeeType.HealthCoach))}</Box>
                                    </Box>
                                    <Box mt={1} display="flex" >
                                        <Box className={classes.infoTitleSecondaty}>MD: </Box>
                                        <Box className={classes.patientInfo}>{displayAssignedEmployees(patient.assignedEmployees.filter(c => c.type === EmployeeType.Provider))}</Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        : <></>
                }
            />
        </Box>
    )
}

