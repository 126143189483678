import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { onEmit } from "../../../common/helpers/on-emit";
import { syncRecordsService } from "../../services/syncRecords.service";
import { syncRecordsQuery } from "../../stores/syncRecordsStore";
import { SyncRecordReconcileModel } from "../../models/syncRecords.model";
import { syncRecordStatusNames } from "../../models/syncRecordStatus.enums";

interface SyncRecordListState {
    isLoading: boolean;
    isSubmitted: boolean;
    syncRecordReconciles: SyncRecordReconcileModel[];
}

export function useFacade(recordId: number): [
    SyncRecordListState,
    (field: string, value: any, recordId: number) => void,
    (field: string, value: any, recordId: number) => void,
    () => void,
    (action: number) => void
] {

    const [state, setState] = useState({
        isLoading: true,
        isSubmitted: false,
        syncRecordReconciles: []
    } as SyncRecordListState);

    const handleChanges = (field: string, value: any, recordId: number) => {
        const syncRecordReconciles = state.syncRecordReconciles.map(record => 
            record.id === recordId ? {
                ...record,
                [field]: value,
                status: field === "statusId" ? syncRecordStatusNames[value] : record.status,
            } : record
        );

        setState({...state, syncRecordReconciles });
    }

    const handleChangeDatums = (field: string, value: any, recordId: number) => {
        const syncRecordReconciles = state.syncRecordReconciles.map(record => 
            record.id === recordId ? {
                ...record,
                datum: record.datum.map(data => 
                    data.key === field ? {
                        ...data,
                        value
                    } : data
                )
            } : record
        );

        setState({...state, syncRecordReconciles });
    }

    const handleReconcile = () => {
        setState({...state, isSubmitted: true })

        const cb = () => setState(state => ({ ...state, isSubmitted: false }));
        syncRecordsService.reconcileSyncRecord(recordId, state.syncRecordReconciles).subscribe(cb, cb)
    }

    const handleAction = (action: number) => {
        setState({...state, isSubmitted: true });

        const cb = (record: SyncRecordReconcileModel) => {
            var reconciles = state.syncRecordReconciles;
            var index = reconciles.findIndex(r => r.id === record.id);

            if (index !== -1) {
                reconciles[index] = { ...reconciles[index], ...record };
            }

            setState(state => ({ ...state, syncRecordReconciles: reconciles, isSubmitted: false }));
        };

        syncRecordsService.performSyncRecordAction(recordId, action).subscribe(cb, cb)
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<SyncRecordReconcileModel[]>(syncRecordsQuery.syncRecordReconciles$, syncRecordReconciles => {
                setState({
                    ...state,
                    syncRecordReconciles,
                });
            }),
        ];

        const cb = () => setState(state => ({ ...state, isLoading: false }));

        syncRecordsService.getSyncRecordsReconcile(recordId).subscribe(cb, cb);

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, []);

    return [
        state,
        handleChanges,
        handleChangeDatums,
        handleReconcile,
        handleAction
    ];
}