import React from "react";
import { AuthenticatedLayoutComponent } from "../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent";
import { PagesNamesService } from "../../../../constants/pagesNames.constants";
import { TitlesService } from "../../../../constants/title.constants";
import { MenuItemTypes } from "../../../../models/menu.models";
import { PatientGeneralInputsComponent } from "../../components/patientGeneralInputsComponent/PatientGeneralInputsComponent";

export const PatientGeneralPage: React.FC = () => {
    return (
        <AuthenticatedLayoutComponent
            title={TitlesService.generalInputsTitle}
            pageName={PagesNamesService.medicalHistory}
            selectedMenuItem={MenuItemTypes.GeneralInputs}
        >
            <PatientGeneralInputsComponent/>
        </AuthenticatedLayoutComponent>
    )
}
