import Axios from 'axios-observable';
import {applicationStore, ApplicationStore} from "../stores/applicationStore";
import { Observable } from 'rxjs';
import { authHeader } from '../modules/common/helpers/auth-header';
import { BannerModel } from '../models/banner.model';
import { TutorialStatusModel } from '../models/application.models';

export class ApplicationService {
    private url = `${process.env.REACT_APP_API_URL}Application`;
    private bannerUrl = `${process.env.REACT_APP_API_URL}Banners`;
    private tutorialUrl = `${process.env.REACT_APP_API_URL}Tutorials`;
    constructor(private applicationStore: ApplicationStore) {}

    public getInfo() {
        Axios.get(`${this.url}/Info`).pipe()
            .subscribe(
                response => this.applicationStore.update({information: response.data})
            );
    }

    /**
     *  Get the Banners
     */
     public getBanners(): Observable<BannerModel[]> {
        const config = { headers: authHeader() };

        return new Observable<BannerModel[]>((observer) =>
        Axios.get(`${this.bannerUrl}`, config)
            .pipe()
            .subscribe(
            response => {
                this.applicationStore.update({ banners: response.data })
                observer.next(response.data);
                observer.complete();
            },
            error => {
                observer.error(error);
                observer.complete();
            }
            )
        );
    }

    public closeBanner(id: number): Observable<BannerModel> {
        const url = `${this.bannerUrl}/${id}/Acknowledge`;
        const config = { headers: authHeader() };

        return new Observable<BannerModel>(observer => {
            Axios.post<BannerModel>(url, {}, config).pipe()
                .subscribe(
                    response => {
                        this.applicationStore.removeBanner(id);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public checkFirstVisit(tutorialName: string): Observable<TutorialStatusModel> {
        const url = `${this.tutorialUrl}/CheckFirstView?tutorialName=${tutorialName}`;
        const config = { headers: authHeader() };

        return new Observable<TutorialStatusModel>(observer => {
            Axios.get<TutorialStatusModel>(url, config).pipe()
                .subscribe(
                    response => {
                        this.applicationStore.update({ tutorialStatus: response.data })
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public closeTutorial(id: number): Observable<TutorialStatusModel> {
        const url = `${this.tutorialUrl}/${id}/Acknowledge`;
        const config = { headers: authHeader() };

        return new Observable<TutorialStatusModel>(observer => {
            Axios.post<TutorialStatusModel>(url, {}, config).pipe()
                .subscribe(
                    response => {
                        this.applicationStore.update({ tutorialStatus: response.data })
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }
}

export const applicationService = new ApplicationService(applicationStore);
