import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            height: 23,
            margin: '11px 83px 26px 0',
            fontFamily: 'JosefinSans',
            fontSize: 23,
            fontWeight: 600,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            textAlign: 'left',
            color: '#71759a',
        },
        requestButton: {
            width: 261,
            height: 52,
            borderRadius: 4,
            margin: '15px 0px',
            padding: '16px 30px 17px',
            color: "white !important",
            backgroundColor: "#537b98 !important",
        },
        cancelButton: {
            width: 129,
            height: 52,
            opacity: 0.8,
            borderRadius: 4,
            margin: '15px 0px',
            padding: '16px 47px 17px 46px',
            backgroundColor: 'rgba(83, 123, 152, 0.1)'
        },
        descriptionCollapsed: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical"
        },
    })
);
