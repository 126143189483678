import { Box, useMediaQuery, useTheme, Container } from '@material-ui/core';
import { navigationService } from "../../services/navigation.service";
import React, { useEffect, useState } from 'react';
import { useStyles } from './footerComponent.styles';
import { applicationQuery } from '../../stores/applicationStore';
import { ApplicationInformationModel } from '../../models/application.models';
import { onEmit } from '../../modules/common/helpers/on-emit';
import { Subscription } from 'recompose';
import { ProtectedElement } from '../../modules/common/components/ProtectedElement';
import { UserType } from '../../modules/auth/models/auth.enums';
import { logoService } from "../../services/logo.service";

export interface FooterComponentProps {
    position: 'absolute' | 'static'
}

export interface SideBarComponentState {
    applicationInformation: ApplicationInformationModel;
}

export const FooterComponent: React.FC<FooterComponentProps> = (props: FooterComponentProps) => {
    const [state, setState] = useState({
        applicationInformation: null,
    } as SideBarComponentState);

    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<ApplicationInformationModel>(applicationQuery.information$, applicationInformation => {
                setState(state => ({ ...state, applicationInformation }));
            })
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    const defaultItems = [
        <Box key='default-1'>
            <span id="footer-link-privacy-policy" className={isSmallScreen ? classes.smLinkWrapper : classes.linkWrapper} onClick={() => navigationService.toPrivacyPolicy()}>
                <Box pl={isSmallScreen ? "14px" : "67px"}>
                    Privacy Policy
                </Box>
            </span>
        </Box>,
        <Box key='default-2'>
            <span className={isSmallScreen ? classes.smLinkWrapper : classes.linkWrapper} id="footer-link-terms" onClick={() => navigationService.toTermsOfService()}>
                <Box pl={isSmallScreen ? "14px" : "67px"}>
                    Terms of Use
                </Box>
            </span>
        </Box>,
        <ProtectedElement
            key='default-3'
            element={
                <Box>
                    <span className="text-accent">
                        <Box pl={isSmallScreen ? "34px" : "67px"}>
                            v{state.applicationInformation?.applicationVersion}
                        </Box>
                    </span>
                </Box>
            }
            permissions={[]}
            userType={UserType.Employee}
        />,
    ];

    return (
        <Box className={classes.footer} position={props.position}>
            <Box className={classes.footerContainer}>
                <Box>
                    <img className={classes.logo1} src={logoService.getLogo().primary} alt="img" />
                </Box>
                <Box className={classes.vertical}>
                    {defaultItems}
                </Box>
            </Box>
        </Box>
    )
}

export const FooterRedesignComponent: React.FC<FooterComponentProps> = (props: FooterComponentProps) => {
    const classes = useStyles();

    const defaultItems = [
        <Box key='default-1' pr={5}>
            <span id="footer-link-privacy-policy" className={classes.linkRedesignWrapper} onClick={() => navigationService.toPrivacyPolicy()}>
                <Box>
                    Privacy Policy
                </Box>
            </span>
        </Box>,
        <Box key='default-2'>
            <span className={classes.linkRedesignWrapper} id="footer-link-terms" onClick={() => navigationService.toTermsOfService()}>
                <Box>
                    Terms of Use
                </Box>
            </span>
        </Box>,
    ];

    return (
        <Box className={classes.footerRedesign} position={props.position}>
            <Container maxWidth="lg" className={classes.container}>
                <Box className={classes.footerRedesignContainer}>
                    <Box className={classes.vertical}>
                        {defaultItems}
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}
