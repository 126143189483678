import { Box, IconButton, Tooltip } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import React from "react";
import { WildHealthRadio } from "../../../common/components/WildHealthRadio";
import commonUseStyles from "../../../common/styles/common.styles";
import { MdmCommonModel, MdmMode, MdmModel, MdmPatientType, SelectedMdmCategoryItemModel } from "../../models/notes.models";
import { MdmComponent } from "../mdmComponent/MdmComponent";
import { handleShortcutInsert } from "../../../common/helpers/handle-shortcut-insert";
import { TextFieldNoteComponent } from "../textFieldNoteComponent/TextFieldNoteComponent";
import { authQuery } from "../../../auth/stores/auth";
import { colors } from "../../../common/constants/colors";
import InfoIcon from '@material-ui/icons/Info';
import AddIcon from '@material-ui/icons/Add';
import { useStyles } from './noteMdmComponent.styles';
import { notesQuery } from "../../stores/notes";

export interface NoteMdmComponentProps {
    error: string;
    mdms: MdmModel[];
    mdmText: string;
    mdmPatientTypes: Map<MdmPatientType, string>;
    selectedMdmPatientType: MdmPatientType;
    selectedCodeId: number;
    showCodeDescription: boolean | null;
    selectedCategoryIds: number[];
    selectedCategoryItems: SelectedMdmCategoryItemModel[];
    selectedMode: MdmMode;
    spentTime: number;
    appointmentDuration: number;
    handleMdmTextChange: (value: string) => void;
    handleMdmPatientTypeChange: (value: MdmPatientType) => void;
    handleCodeChange: (id: number, showCodeDescription: boolean) => void;
    handleCategoryChange: (ids: number[]) => void;
    handleCategoryItemChange: (id: number, inputValue: string) => void;
    handleModeChange: (mode: MdmMode) => void;
    handleSpentTimeChanges: (time: number) => void;
}

export const NoteMdmComponent: React.FC<NoteMdmComponentProps> = (props: NoteMdmComponentProps) => {
    const {
        error,
        mdms,
        mdmText,
        mdmPatientTypes,
        selectedMdmPatientType,
        selectedCodeId,
        showCodeDescription,
        selectedCategoryIds,
        selectedCategoryItems,
        selectedMode,
        spentTime,
        appointmentDuration,
        handleMdmTextChange,
        handleMdmPatientTypeChange,
        handleCodeChange,
        handleCategoryChange,
        handleCategoryItemChange,
        handleModeChange,
        handleSpentTimeChanges
    } = props;

    const commonClasses = commonUseStyles();
    const classes = useStyles();

    const customTooltipClasses = {
        tooltip: classes.customWidth,
        arrow: classes.arrow,
        tooltipPlacementBottom: classes.tooltipPlacement,
        tooltipPlacementTop: classes.tooltipPlacement,
    };

    const getLabel = () => {
        if (authQuery.isLicensingPractice()) {
            return false
        }

        return mdms.find(el => el.employeeOnly && selectedMdmPatientType === el.patientType)
    }

    const handleAddCommonMdm = (mdm: MdmCommonModel) => {
        const addCommonMdm = mdmText + mdm.description + mdm?.reasons.reduce((reason1, reason2) =>  reason1 + ' ' + reason2.name, '') + '\n'
        handleMdmTextChange(addCommonMdm)
    }

    const renderViewElement = (mdm: MdmCommonModel) =>
    <Box display='flex' key={mdm.id} mr={1.5} mb={1.5} className={classes.rootView}>
            <Box width='28px' minWidth='28px' display='flex' alignItems='center' mr={1} justifyContent='flex-end'>
                <Box position='relative' pt={0.5}>
                    <Tooltip placement="bottom" arrow
                        title={<Box className={classes.notificationToolTipText}>
                            <Box>
                                <Box style={{ whiteSpace: 'pre-line' }}>{mdm.description}</Box>
                                {mdm.reasons.map(reason => {
                                    return <Box style={{ whiteSpace: 'pre-line' }} key={reason.id}>
                                        <span className={classes.reasonDot}>&#x2022;</span>
                                        <span className={classes.reasonName}>{reason.name}</span>
                                    </Box>
                                })}
                            </Box>
                        </Box>}
                        classes={customTooltipClasses}
                    >
                        <InfoIcon className={classes.iconInfo} fontSize='small' style={{ fill: colors.gray2 }} />
                    </Tooltip>
                </Box>
            </Box>
        <Box>
            {mdm.name}
        </Box>
        <Box
            className={classes.button}
            onClick={() => handleAddCommonMdm(mdm)}>
            <AddIcon fontSize='small' />
        </Box>
    </Box>

const renderCommonMdm = () => {
    return <Box mb={3} mt={1}>
        <Box color={colors.gray1}>Most Common MDMs</Box>
        <Box mt={1.5} display='flex' flexWrap='wrap'>
            {notesQuery.getCommonMdms().map(mdm => renderViewElement(mdm ))}
        </Box>
    </Box>
}

    return (
        <Box>
            {renderCommonMdm()}
            <Box>
                <TextFieldNoteComponent
                    rows={3}
                    title=''
                    id='mdm-text-input'
                    content={mdmText}
                    handleChanged={handleMdmTextChange}
                    handleKeyDown={handleShortcutInsert}
                />
            </Box>
            <Box mt={2} fontWeight={500} display='flex' alignItems='center'>
                <Box>
                    <span>Coding</span>
                </Box>
                <Box>
                    <Tooltip title="Choose new or established patient and then choose MDM or time-based billing. You should select the highest most appropriate code">
                        <IconButton>
                            <Info className={commonClasses.colorGray2} />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            {
                error &&
                <Box mt={2}>
                    <span className={commonClasses.error}>{error}</span>
                </Box>
            }
            <Box mt={2} display='flex' alignItems='center'>
                {
                    Array.from(mdmPatientTypes.keys()).map((type, index) => {
                        return (
                            <Box key={index} display='flex' alignItems='center' ml={index > 0 ? 2 : 0}>
                                <Box>
                                    <WildHealthRadio
                                        checked={selectedMdmPatientType === type}
                                        onChange={() => handleMdmPatientTypeChange(type)}
                                        value={selectedMdmPatientType}
                                    />
                                </Box>
                                <Box>
                                    <span>{mdmPatientTypes.get(type)}</span>
                                </Box>
                            </Box>
                        )
                    })
                }
            </Box>
            <Box> {authQuery.isLicensingPractice()
                ? mdms.filter(x => x.patientType === selectedMdmPatientType && !x.employeeOnly).map((mdm) => (
                    <Box mt={2}>
                        <MdmComponent
                            mdm={mdm}
                            selectedCodeId={selectedCodeId}
                            showCodeDescription={showCodeDescription}
                            selectedCategoryIds={selectedCategoryIds}
                            selectedCategoryItems={selectedCategoryItems}
                            selectedMode={selectedMode}
                            spentTime={spentTime}
                            appointmentDuration={appointmentDuration}
                            handleCodeChange={handleCodeChange}
                            handleCategoryChange={handleCategoryChange}
                            handleCategoryItemChange={handleCategoryItemChange}
                            handleModeChange={handleModeChange}
                            handleSpentTimeChanges={handleSpentTimeChanges}
                        />
                    </Box>
                ))
                : mdms.filter(x => x.patientType === selectedMdmPatientType).map((mdm) => (
                    <Box mt={2}>
                        <MdmComponent
                            mdm={mdm}
                            selectedCodeId={selectedCodeId}
                            showCodeDescription={showCodeDescription}
                            selectedCategoryIds={selectedCategoryIds}
                            selectedCategoryItems={selectedCategoryItems}
                            selectedMode={selectedMode}
                            spentTime={spentTime}
                            appointmentDuration={appointmentDuration}
                            handleCodeChange={handleCodeChange}
                            handleCategoryChange={handleCategoryChange}
                            handleCategoryItemChange={handleCategoryItemChange}
                            handleModeChange={handleModeChange}
                            handleSpentTimeChanges={handleSpentTimeChanges}
                        />
                    </Box>
                ))}
                {getLabel() && <Box pl={6} mt={2} display='flex' className={commonClasses.colorBad}>
                    <Box display='flex' mr={1}>*</Box>
                    <Box>For employee use only</Box>
                </Box>}
            </Box>
        </Box>
    );
}
