import {Box, CircularProgress, Grid, TextField} from "@material-ui/core";
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";
import React, {useEffect, useMemo, useState} from "react";
import {Subscription} from "recompose";
import {interval, Observable} from 'rxjs';
import {confirmService} from "../../../../services/confirm.service";
import {PermissionType, UserType} from "../../../auth/models/auth.enums";
import {OptionalComponent} from '../../../common/components/OptionalComponent';
import {isElementEnabled} from "../../../common/components/ProtectedElement";
import {WildHealthDatePicker} from "../../../common/components/WildHealthDatePicker";
import {insertString} from "../../../common/helpers/insert-string";
import {onEmit} from '../../../common/helpers/on-emit';
import {IErrorState} from "../../../common/validation/error-state";
import {selectShortcutService} from "../../../healthReport/services/selectShortcut.service";
import {PatientModel} from "../../../patients/models/patient.model";
import {patientsService} from "../../../patients/services/patients.service";
import {patientsQuery} from "../../../patients/stores/patientsStore";
import {
    PatientMedicationsSupplementsTabsComponent
} from "../../../patientSupplements/components/patientMedicationsSupplementsTabs/PatientMedicationsSupplementsTabsComponent";
import {
    CheckAnswer,
    HealthGoalsQuestionNames,
    QuestionModel,
    QuestionnaireResultModel,
    QuestionnaireSection,
    QuestionnaireSubType,
    QuestionnaireType
} from "../../../questionnaire/models/questionnaire.models";
import {questionnaireService} from "../../../questionnaire/services/questionnaire.service";
import {
    CheckManyToTextField,
    getInitQuestionnaires,
    InitialConsultToHealthLogQuestionNamesMap,
    InitialConsultToInitialQuestionNamesMap,
    KeyMap,
} from "../../constants/initialConsult.constants";
import {
    AppointmentConfigurationModel,
    HealthSummaryNoteKeySectionNames,
    IccContent,
    NoteLogModel,
    NoteModel,
    NotesType,
    SaveNoteModel,
    SignOffNoteModel
} from "../../models/notes.models";
import {notesService, SignOffModel} from "../../services/notes.service";
import {createInitialConsultComponentValidator} from "./createInitialConsultComponent.validator";
import {colors} from "../../../common/constants/colors";
import {authQuery} from "../../../auth/stores/auth";
import {DataSpecificationsEnum} from "../../../common/constants/data-specifications";
import {useHistory} from 'react-router';
import {snackService} from "../../../common/snack/state";
import {SelectAppointmentToNotesDialog} from "../SelectAppointmentToNotesComponent/SelectAppointmentToNotesDialog";
import {TextFieldNoteComponent} from "../textFieldNoteComponent/TextFieldNoteComponent";
import {ReactComponent as CalendarIcon} from "@img/icons/CalendarIcon.svg";
import {WildHealthButton} from "../../../common/components/wildHealthButton/WildHealthButton";
import moment from "moment";
import {SignOffDialogComponent} from "../signOffDIalogComponent/SignOffDialogComponent";
import {FeatureFlag} from "../../../common/components/featureFlags/featureFlags.models";
import {FeatureComponent} from "../../../common/components/featureFlags/FeatureComponent";
import {canDiscardNote, isFutureAppointment} from "../../helpers/noteHelpers";
import {HealthSummaryDataModel, HealthSummaryMapModel} from "../../../healthSummary/models/healthSummary.models";
import {healthSummaryQuery} from "../../../healthSummary/stores";
import {healthSummaryService} from "../../../healthSummary/services/healthSummary.service";
import {buildKey, getKey} from "../../../healthSummary/helpers/healthSummaryHelper";
import {
    HealthSummaryListItemsNoteComponent
} from "../../../healthSummary/components/listItemsComponent/HealthSummaryListItemsNoteComponent";
import {icdCodesService} from "../../services/icdCodes.service";
import {durations} from "../../../appointments/models/times.models";
import {SelectDurationComponent} from "../../../appointments/components/selectDuration/SelectDurationComonent";
import {SupplementSource} from "../../../patientSupplements/models/patientSupplementModel";
import {isFeatureFlag} from "../../../common/components/featureFlags/featureFlags";
import {useFlags} from "launchdarkly-react-client-sdk";

let stateContext: CreateInitialConsultComponentState = null;

interface CreateInitialConsultComponentState extends IErrorState {
    isLoading: boolean;
    isChanged: boolean;
    isAutoSaving: boolean;
    isProcessing: boolean;
    isNew: boolean;
    note: NoteModel;
    title: string;
    recommendations: string;
    questionnaires: QuestionnaireSection[];
    logs: NoteLogModel[];
    internalContent: IccContent;
    visitDate: Date;
    isFirstSaveOriginalNote: boolean;
    originalNote: NoteModel;
    errorBloodPressure: string;
    map: HealthSummaryMapModel[];
}


export interface HealthSummaryListItemsNoteComponentState {
    isEditorOpened: boolean
    isMenuOpened: boolean
    editRowValue: string
    selectedKey: string
    editRowName: string
    addNewRow: boolean
    anchorEl: any
    editSection: string
    currentMap: HealthSummaryMapModel
    draft: any[]
}

let ignoreChangesEvent = false;

export function useFacade(patientId: number, note: NoteModel | null, classes: any, handleGoToNotes: Function, appointmentId: number | null, appointmentConfiguration: AppointmentConfigurationModel | null, originalNote: NoteModel): [
    CreateInitialConsultComponentState,
    JSX.Element,
    JSX.Element,
    JSX.Element,
    JSX.Element,
    JSX.Element,
    JSX.Element,
    string,
    JSX.Element,
    JSX.Element,
    JSX.Element,
    JSX.Element
] {
    const noteName = "Initial Coach Consult";
    const history = useHistory();
    const flags = useFlags();
    const autoSaveTimer = useMemo(() => interval(5000), []);
    const [isOpen, setIsOpen] = useState(false);
    const [state, setState] = useState({
        isLoading: true,
        isChanged: false,
        isAutoSaving: false,
        isProcessing: false,
        isNew: true,
        note: note,
        recommendations: null,
        questionnaires: getInitQuestionnaires(),
        logs: [],
        title: note?.title || '',
        internalContent: {
            currentMedicalConditions:[],
            appointmentConfiguration: appointmentConfiguration,
            internalContent: "",
            diastolicBloodPressure: 0,
            intention: "",
            systolicBloodPressure: 0
        },
        intention: '',
        visitDate: note?.visitDate ?? appointmentConfiguration?.visitDate ?? new Date(),
        isOrderedInitialLabsCollected: false,
        isFreeTrial: false,
        errors: {},
        isFirstSaveOriginalNote: originalNote ? true : false,
        originalNote: originalNote,
        systolicBloodPressure: 0,
        diastolicBloodPressure: 0,
        errorBloodPressure: '',
        currentMedicalConditions: [],
        map: null,
    } as CreateInitialConsultComponentState);

    const [editPmhState, setEditPmhState] = useState({
        isEditorOpened: false,
        isMenuOpened: false,
        selectedKey: null,
        addNewRow: false,
        anchorEl: null,
        editSection: '',
        currentMap: null,
        draft: [],
    } as HealthSummaryListItemsNoteComponentState)

    const setInitialState = () => {
        setEditPmhState({
            ...editPmhState,
            isEditorOpened: false,
            selectedKey: null,
            addNewRow: false,
            editRowValue: '',
            editRowName: '',
            editSection: '',
            currentMap: null,
            draft: []
        })
    }

    stateContext = state;

    const handleSaveAsDraft = () => {
        if (!createInitialConsultComponentValidator.stateIsValid(state)) {
            return;
        }

        if (state.isAutoSaving) {
            setTimeout(() => {
                setState(state => ({
                    ...state,
                    isChanged: false,
                    isProcessing: true,
                }));

                notesService.saveAsDraft(getData(state), patientsQuery.getTargetPatientIsPremium()).subscribe(
                    () => {
                        handleGoBack(history)
                    },
                    (error) => {
                        snackService.commonErrorHandler(error);
                        setState(state => ({
                            ...state,
                            isProcessing: true
                        }));
                    }
                );
            }, 2000)
            return;
        }

        setState(state => ({
            ...state,
            isChanged: false,
            isProcessing: true,
        }));

        notesService.saveAsDraft(getData(state), patientsQuery.getTargetPatientIsPremium()).subscribe(
            () => {
                handleGoBack(history)
            },
            (error) => {
                snackService.commonErrorHandler(error);
                setState(state => ({
                    ...state,
                    isProcessing: true
                }));
            }
        );
    }

    const isCanCompleteNote = () => {
        if (!!originalNote) {
            return originalNote.completedById === authQuery.getEmployeeId();
        }

        if (stateContext?.note?.assignedTo) {
            return stateContext.note.assignedTo.id === authQuery.getEmployeeId()
        }

        if (stateContext?.note?.completedById) {
            return stateContext.note.completedById === authQuery.getEmployeeId()
        }

        return true;
    }

    const handleChangeSystolic = (value: string) => {
        let parsedValue = parseInt(value);
        
        if(isNaN(parsedValue)){

            setState(state => ({
                ...state,
                isChanged: true,
                internalContent:
                {
                    ...state.internalContent,
                    systolicBloodPressure: 0
                }
            }));

            return;
        }

        setState(state => ({
            ...state,
            isChanged: true,
            internalContent:{
                ...state.internalContent,
                systolicBloodPressure: parsedValue
            }
        }));
    }

    const handleValidateSystolic = () => {
        if(isNaN(state.internalContent.systolicBloodPressure)){
            setState(state => ({
                ...state,
                isChanged: true,
                errorBloodPressure: "Please enter number. In field systolic."
            }));
        }
         else if(state.internalContent.systolicBloodPressure < state.internalContent.diastolicBloodPressure){
            setState(state => ({
                ...state,
                isChanged: true,
                errorBloodPressure: "Diastolic can not be bigger than systolic."
            }));
        }
        else{
            setState(state => ({
                ...state,
                isChanged: true,
                errorBloodPressure: ""
            }));
        }
    }

    const handleValidateDiastolic = () => {
        if(isNaN(state.internalContent.diastolicBloodPressure)){
            setState(state => ({
                ...state,
                isChanged: true,
                errorBloodPressure: "Please enter number. In field Diastolic."
            }));
        }
        else if(state.internalContent.systolicBloodPressure < state.internalContent.diastolicBloodPressure){
            setState(state => ({
                ...state,
                isChanged: true,
                errorBloodPressure: "Diastolic can not be bigger than systolic."
            }));
        }
        else{
            setState(state => ({
                ...state,
                isChanged: true,
                errorBloodPressure: ""
            }));
        }
    }

    const handleChangeDiastolic = (value: string) => {
        let parsedValue = parseInt(value);

        if(isNaN(parsedValue)){
            setState(state => ({
                ...state,
                isChanged: true,
                internalContent:
                {
                    ...state.internalContent,
                    diastolicBloodPressure: 0
                }
            }));

            return;
        }

        setState(state => ({
            ...state,
            isChanged: true,
            internalContent:
            {
                ...state.internalContent,
                diastolicBloodPressure: parsedValue
            }
        }));
    }

    const handleSignOff = () => {
        notesService.openSignOff({
            patientId: state.note.patientId,
            noteType: state.note.type
        } as SignOffModel).subscribe(([employeeId, additionalNote]) => {
            const model: SignOffNoteModel = {
                noteId: stateContext.note.id,
                assignToId: employeeId,
                additionalNote: additionalNote
            };

            setState(state => ({
                ...state,
                isProcessing: true
            }));

            notesService.signOff(model).subscribe(
                () => {
                    handleSaveAsDraft();
                    handleGoBack(history);
                },
                () => {
                    setState(state => ({
                        ...state,
                        isProcessing: false
                    }))
                }
            );
        });
    }

    const validateNote = () => {
        createInitialConsultComponentValidator.validateAndSetState(state, setState, 'recommendations', state.recommendations);
    }

    const handleSaveAndComplete = () => {
        validateNote();

        if (!createInitialConsultComponentValidator.stateIsValid(state)) {
            return;
        }

        confirmService.confirm('Are you sure? This action can not  be undone').subscribe(() => {
            setState(state => ({
                ...state,
                isChanged: false,
                isProcessing: true,
            }));

            notesService.saveAsCompleted(getData(state)).subscribe(
                () => handleGoToNotes(),
                () => {
                    setState(state => ({
                        ...state,
                        isProcessing: false
                    }));
                });
        });
    }

    const handleAnswerChanges = (question: QuestionModel, answer: string) => {
        createInitialConsultComponentValidator.validateAndSetState(state, setState, question.name, answer);
        const log = state.logs.find(x => x.key === question.name);
        if (!log) {
            setState({
                ...state,
                isChanged: true,
                logs: [
                    ...state.logs, {
                        key: question.name,
                        value: answer
                    }
                ]
            })
        } else {
            log.value = answer;
            setState({
                ...state,
                isChanged: true,
                logs: [...state.logs]
            })
        }
    }

    const getAnswer = (question: QuestionModel): string => {
        return state.logs.find(x => x.key === question.name)?.value ?? question.default
    }

    const handleDiscard = () => {
        confirmService.confirm('Are you sure? This action can not be undone').subscribe(() => {
            discard();
        });
    }

    const handleGoBack = (history) => {
        handleGoToNotes();
    }

    const discard = () => {
        if (stateContext.isAutoSaving) {
            setTimeout(() => discard(), 50);
            return;
        }

        setState(state => ({
            ...state,
            isChanged: false,
            isProcessing: true,
        }));

        notesService.delete(state.note).subscribe(() => handleGoBack(history));
    }

    const handleInternalNotesChanged = (value: string) => {
        if (ignoreChangesEvent) {
            return;
        }

        setState({
            ...state,
            isChanged: true,
            internalContent:{
            ...state.internalContent,
                internalContent: value
            } 
        });
    }

    const handleUpdateIntention = (value:string) => {
        setState({
            ...state,
            isChanged: true,
            internalContent:{
                ...state.internalContent,
                intention: value
            }
        });
    }

    const handleTextMessageChanged = (value: string) => {
        setState(state => ({
            ...state,
            isChanged: true,
            internalContent:{
                ...state.internalContent,
                    internalContent: value
                } 
        }));
    }

    const handleKeyDown = (event: any) => {
        ignoreChangesEvent = false;
        if (event.keyCode === 51 && event.altKey) {
            const userType = authQuery.getType();
            if (UserType.Employee === userType) {
                ignoreChangesEvent = true;
                const value = (document.getElementById('internal-notes') as any).data;
                const cursorPos = (document.getElementById('internal-notes') as any).selectionStart;
                setTimeout(() => {
                    handleOnInternalNotesChanged(value, cursorPos);
                }, 1)
            }
        }
    }

    const handleOnInternalNotesChanged = (value: string, cursorPos: number) => {
        const cb = (data) => {
            value = state.internalContent.internalContent;
            if (data) {
                handleTextMessageChanged(insertString(value, data, cursorPos + 1));
            }
        }

        selectShortcutService.select().subscribe(cb);
    }

    const getData = (state: CreateInitialConsultComponentState): SaveNoteModel => {
        return {
            id: state.note?.id,
            content: getContent(state),
            internalContent: getInternalContent(state),
            name: noteName,
            title: state.title,
            type: NotesType.Initial,
            visitDate: state.visitDate,
            patientId: patientId,
            logs: state.logs,
            nextCoachAppointmentDate: null,
            nextProviderAppointmentDate: null,
            appointmentId: state.note ? state.note.appointment?.id : appointmentId,
            version: state.note?.version,
            originalNoteId: state.originalNote?.id
        }
    }

    const getSaveModelFromOriginalNote = (content): SaveNoteModel => {
        return {
            id: null,
            name: noteName,
            title: state.originalNote.title,
            type: NotesType.Initial,
            visitDate: state.visitDate,
            patientId: patientId,
            internalContent: getInternalContent(state),
            content: JSON.stringify(content),
            logs: [],
            nextCoachAppointmentDate: null,
            nextProviderAppointmentDate: null,
            version: state.originalNote?.version,
            originalNoteId: state.originalNote?.id
        } as SaveNoteModel;
    }

    const handleNoteTitleChange = (field: string, value: string) => {
        createInitialConsultComponentValidator.validateAndSetState(state, setState, field, value);
        setState(state => ({
            ...state,
            isChanged: true,
            title: value
        }));
    }

    const titleTextBox = (
        <TextField
            label='Enter title'
            type='text'
            size="small"
            variant="outlined"
            id='title'
            value={state.title}
            helperText={state.errors['title']}
            error={!!state.errors['title']}
            disabled={state.isProcessing}
            InputProps={{ className: 'input' }}
            onChange={(e) => {
                handleNoteTitleChange(e.target.id, e.target.value);
            }}
        />
    )

    const getContent = (state: CreateInitialConsultComponentState) => {
        return JSON.stringify(state.recommendations);
    }

    const getInternalContent = (state: CreateInitialConsultComponentState) => {
        return JSON.stringify(state.internalContent);
    }

    const handleVisitDateChanges = (momentDate: MaterialUiPickersDate) => {
        setState({
            ...state,
            isChanged: true,
            visitDate: momentDate?.toDate()
        });
    }

    const handleAppointmentChanges = (appointmentId: number) => {
        if (state.note?.appointment?.id === appointmentId) {
            return;
        }

        const model = getData(state);

        model.appointmentId = appointmentId;

        notesService.saveAsDraft(model, patientsQuery.getTargetPatientIsPremium()).subscribe(
            (note) => {
                setState(state => ({
                    ...state,
                    note: note
                }))
            },
            () => {
                snackService.error('Note already exists for this appointment.');
            }
        );
    }

    const handleSelectAppointmentSubmit = (appointmentId: number) => {
        if (!appointmentId) {
            if (!patientsQuery.getTargetPatient()?.isPremium) {
                snackService.error("There was an error while attaching a note.");
            }
        } else {
            handleAppointmentChanges(appointmentId);
        }

        setIsOpen(false);
    }

    const finalVisitDate = state.note?.appointment
        ? state.note.appointment.date
        : state.note?.visitDate
            ? state.note.visitDate
            : state.visitDate;

    const getTime = () => {
        return moment(finalVisitDate).format('HH:mm');
    }

    const handleTimeChanges = (time: string) => {
        const hoursMinutes = time.split(":");
        const dateResult = new Date(finalVisitDate);
        dateResult.setHours(+hoursMinutes[0], +hoursMinutes[1]);

        setState({
            ...state,
            isChanged: true,
            visitDate: new Date(dateResult),
            note: {
                ...state.note,
                visitDate: new Date(dateResult)
            }
        });
    }

    const handleChangeDuration = (durationValue: number) => {
        const selectedDuration = durations.find(x => x.value === durationValue);
        const content = state.internalContent;
        content.appointmentConfiguration.duration = selectedDuration.value;
        setState(state => ({ ...state, internalContent: content, isChanged: true }));
    }

    const internalNotes = (
        <>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} mt={2}>
                <Grid container spacing={2}>
                    <Grid item lg={2} md={12}>
                        <Box>
                            <Box mr={1} mt={5}>
                                <span className={classes.sectionTitle}>Notes</span>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item lg={10} md={12}>
                        <Box display="flex" alignItems="center">
                            {
                                state.note?.appointment
                                    ? <>
                                        <Box mr={1}>Appointment Date:</Box>
                                        <WildHealthButton
                                            width={256}
                                            size="medium"
                                            style={{ whiteSpace: 'nowrap' }}
                                            color="datePicker"
                                            id='sing-physical-history'
                                            onClick={() => setIsOpen(true)}
                                        >
                                            <span>{moment(finalVisitDate).format("MM/DD/yyyy")}</span>
                                            <CalendarIcon />
                                        </WildHealthButton>
                                    </>
                                    : <Grid container spacing={2}>
                                        <Grid item lg={4} md={12} sm={12} xs={12}>
                                            <Box pb={1}>
                                                <span className="wh-tw-text-gray1 wh-tw-text-sm">Appointment Date</span>
                                            </Box>
                                            <WildHealthDatePicker
                                                required
                                                size="small"
                                                openTo="year"
                                                fullWidth
                                                format="MM/DD/yyyy"
                                                id='AppointmentDate'
                                                placeholder="mm-dd-yyyy"
                                                useKeyboardDatePicker
                                                value={finalVisitDate}
                                                inputVariant="outlined"
                                                views={["year", "month", "date"]}
                                                onChange={(momentDate) => handleVisitDateChanges(momentDate)}
                                            />
                                        </Grid>
                                        <Grid item lg={4} md={12} sm={12} xs={12}>
                                            <Box pb={1}>
                                                <span className="wh-tw-text-gray1 wh-tw-text-sm">Time</span>
                                            </Box>
                                            <TextField
                                                id="time"
                                                size="small"
                                                className={classes.timePicker}
                                                type="time"
                                                fullWidth
                                                variant="outlined"
                                                value={getTime()}
                                                onChange={(e)=>{ handleTimeChanges(e.target.value)}}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    step: 300, // 5 min
                                                }}
                                            />
                                        </Grid>
                                        <Grid item lg={4} md={12} sm={12} xs={12}>
                                            <SelectDurationComponent
                                                durations={durations.filter(x => x.patientMode)}
                                                selected={durations.find(x => x.value === state.internalContent?.appointmentConfiguration?.duration)}
                                                handleChange={(v) => handleChangeDuration(v)}
                                            />
                                        </Grid>
                                    </Grid>
                            }
                        </Box>
                    </Grid>
                </Grid>

            </Box>
            <Box>
                <TextFieldNoteComponent
                    rows={10}
                    title='Internal Notes'
                    id="internal-notes"
                    content={state.internalContent?.internalContent}
                    handleChanged={handleInternalNotesChanged}
                    handleKeyDown={handleKeyDown}
                />
            </Box>
            <OptionalComponent>
                <SelectAppointmentToNotesDialog
                    handleSubmitSelectAppointment={(appointmentId) => handleSelectAppointmentSubmit(appointmentId)}
                    handleChangeSelectAppointment={() => { }}
                    handleSelectAppointmentDialog={(isOpen) => setIsOpen(isOpen)}
                    isOpen={isOpen}
                    patientId={patientId} />
            </OptionalComponent>
        </>
    );

    const bloodPressure = (
        <Box mt={2} width={220}>
            <Box> What is your blood pressure?</Box>
                <Box display="flex" flexDirection="column" mt={1}>
                        <Box  display="flex">
                            <TextField
                                fullWidth
                                required
                                size="small"
                                id={`systolic_blood_pressure`}
                                variant="outlined"
                                value={state.internalContent.systolicBloodPressure}
                                onChange={(v) => {
                                    handleChangeSystolic(v.target.value)
                                }}
                                onBlur={() => handleValidateSystolic()}
                            />
                            <Box m={1} fontSize={20}>/</Box>
                            <TextField
                                fullWidth
                                required
                                size="small"
                                id={`diastolic_blood_pressure`}
                                variant="outlined"
                                value={state.internalContent.diastolicBloodPressure}
                                onChange={(v) => {
                                    handleChangeDiastolic(v.target.value)
                                }}
                                onBlur={() => handleValidateDiastolic()}
                            />
                        </Box>
                        <Box>
                            {
                                state.errorBloodPressure != null && state.errorBloodPressure !== "" &&
                                <Box color={colors.error}>{state.errorBloodPressure}</Box>
                            }
                        </Box>
                </Box>
        </Box>
    )

    const yourIntention = (
        <Box mt={3}>
                <Box>
                    <Box mr={1}>
                        <span className={classes.sectionTitle}>General</span>
                    </Box>
                    <TextFieldNoteComponent
                        rows={3}
                        title='What is your intention for the health journey?'
                        id="intention-notes"
                        content={state.internalContent.intention}
                        handleChanged={handleUpdateIntention}
                    />
                </Box>
        </Box>
    );

    const getSectionData = (section: HealthSummaryMapModel, data: HealthSummaryDataModel[]) => {
        const keys = [...section.items.map(x => x.key), section.key]

        return data.filter(x => keys.includes(getKey(x.key)))
    }

    const getIcdCodesAutoComplete = (searchQuery: string): Observable<string[]> => {
        return new Observable<string[]>((observer) => {
            icdCodesService.get(searchQuery).subscribe(
                (data) => {
                    observer.next(data.map((x) => x.code + ' ' + x.description));
                    observer.complete();
                },
                () => {
                    observer.next([]);
                    observer.complete();
                }
            )
        })
    }

    const getPmhAndHpiData = (map: HealthSummaryMapModel[]) => {
        healthSummaryService.getData(patientId).subscribe(data => {
            if (data.length) {
                setState((state) => ({
                    ...state,
                    internalContent:{
                        ...state.internalContent,
                        currentMedicalConditions: getSectionData(map.find(el => el.key === HealthSummaryNoteKeySectionNames.PROBLEMS_LIST), data)
                    }
                }))
            }
        })
    }

    const handleToggleActions = (key: string, anchorEl: Element, section: string,) => {
        setEditPmhState(editPmhState => ({
            ...editPmhState,
            isMenuOpened: !editPmhState.isMenuOpened,
            anchorEl: anchorEl,
            selectedKey: key,
            editSection: section,
        }))
    }

    const handleEditRowValue = (value: string) => {

        setEditPmhState(editPmhState => ({
            ...editPmhState,
            editRowValue: value
        }))
    }

    const handleEditRowName = (value: string) => {

        setEditPmhState({
            ...editPmhState,
            editRowName: value
        })
    }

    const handleAddNewRow = (section: string, map: HealthSummaryMapModel) => {
        setEditPmhState({
            ...editPmhState,
            addNewRow: true,
            editSection: section,
            currentMap: map,
        })
    }

    const handleDelete = () => {
        const newData = state.internalContent.currentMedicalConditions.filter(el => el.key !== editPmhState.selectedKey)
        state.internalContent.currentMedicalConditions = newData

        setState((state) => ({
            ...state,
            isChanged: true,
        }))

        setEditPmhState({
            ...editPmhState,
            isMenuOpened: false,
            selectedKey: null,
            anchorEl: null,
            editSection: ''
        })
    }

    const handleEdit = () => {
        const dataItem = state.internalContent.currentMedicalConditions.find(el => el.key === editPmhState.selectedKey)

        setEditPmhState({
            ...editPmhState,
            editRowName: dataItem.name,
            editRowValue: dataItem.value,
            isEditorOpened: true,
            isMenuOpened: false
        })
    }

    const handleBlur = (e, value) => {
        if (value) {
            setEditPmhState(editPmhState => ({
                ...editPmhState,
                editRowValue: value
            }))
        }

        if (e?.currentTarget?.id.includes('list-edit') && e?.relatedTarget) {
            return
        }

        if (editPmhState.editRowName && editPmhState.editRowValue) {
            saveChanges({name: editPmhState.editRowName, value: editPmhState.editRowValue})
            return
        }

        if (editPmhState.addNewRow && !editPmhState.editRowName && !editPmhState.editRowValue) {
            setInitialState()
            return
        }
    }

    const saveChanges = ({name, value}) => {

        if (!name || !value) {
            setInitialState()
            return
        }

        const isNew = !editPmhState.selectedKey

        const model = {
            key: isNew ? buildKey(editPmhState.currentMap.key) : editPmhState.selectedKey,
            name: name ?? editPmhState.editRowName,
            value: value ?? editPmhState.editRowValue,
        } as HealthSummaryDataModel

        if (isNew) {
            const emptyModel = {
                isDeleted: false,
                patientId: patientId,
                isNew: false,
                id: Math.floor(Math.random() * 10000),
                tooltip: ''
            }
            const newData = [...state.internalContent.currentMedicalConditions, {...emptyModel, ...model}]
            state.internalContent.currentMedicalConditions = newData
            setState((state) => ({
                ...state,
                isChanged: true,
            }))

        } else {
            const updateItems = state.internalContent.currentMedicalConditions.map(el => {
                if (el.key === editPmhState.selectedKey) {
                    return {
                        ...el,
                        ...model,
                    }
                }
                return el
            })
            state.internalContent.currentMedicalConditions = updateItems
            setState((state) => ({
                ...state,
                isChanged: true,
            }))
        }
        setInitialState()
    }

    const parseValue = (value: string) => {
        if (!value) {
            return ''
        }

        const arr = value.split(' ')

        if (!arr || !arr.length) {
            return ''
        }

        return arr;
    }

    const saveValue = (value: string) => {

        const parsedValue = parseValue(value)

        if (!parsedValue) {
            if (!editPmhState.isEditorOpened) {
                setInitialState()
            }
            return
        }

        const editValue = parsedValue.length > 1 ? parsedValue[0] : ''
        const editName = parsedValue.length > 1 ? value.replace(editValue, '').trim() : value

        setEditPmhState(editPmhState => ({
            ...editPmhState,
            editRowValue: editValue,
            editRowName: editName
        }))

        const result = {name: editName, value: editValue}

        saveChanges(result)
    }

    const problemList = () => {
        const map = state.map?.find(el => el.key === HealthSummaryNoteKeySectionNames.PROBLEMS_LIST);

        if (!map) {
            return <></>
        }

        return (
            <Box mt={3} ml={-6.25}>
                <Box ml={7}><strong>Problem List</strong></Box>
                <Box px={3}>
                    <HealthSummaryListItemsNoteComponent
                        data={state.internalContent.currentMedicalConditions}
                        map={map}
                        getIcdCodesAutoComplete={getIcdCodesAutoComplete}
                        handleToggleActions={handleToggleActions}
                        handleEditRowValue={handleEditRowValue}
                        handleEditRowName={handleEditRowName}
                        handleAddNewRow={handleAddNewRow}
                        handleDelete={handleDelete}
                        handleEdit={handleEdit}
                        handleBlur={handleBlur}
                        saveValue={saveValue}
                        state={editPmhState}
                        patientId={patientId}
                        section={'currentMedicalConditions'}
                        rxnt={isFeatureFlag(flags, FeatureFlag.RxntIntegration)}
                    />
                </Box>
            </Box>
        )
    }

    const medicationSupplements = (
        <Box width={1} mt={5}>
            <PatientMedicationsSupplementsTabsComponent supplementSource={SupplementSource.Notes} hideTips patientId={patientId} fullWidth />
        </Box>
    );

    const handleOnRecommendationAddChange = (value: string) => {
        createInitialConsultComponentValidator.validateAndSetState(state, setState, 'recommendations', value);

        if (ignoreChangesEvent) {
            return;
        }

        setState({
            ...state,
            isChanged: true,
            recommendations: value
        });
    }

    const handleOnRecommendationChange = (event: any, id: string) => {
        ignoreChangesEvent = false;
        if (event.keyCode === 51 && event.altKey) {
            const userType = authQuery.getType();
            if (UserType.Employee === userType) {
                ignoreChangesEvent = true;
                const value = (document.getElementById(id) as any).value;
                const cursorPos = (document.getElementById(id) as any).selectionStart;
                setTimeout(() => {
                    selectShortcutService.select().subscribe((data) => {
                        if(data){
                            setState({
                                ...state,
                                isChanged: true,
                                recommendations: insertString(value, data, cursorPos + 1)
                            });
                        }
                    })
                }, 1)
            }
        }
    }

    const recommendationsHeader = (
        <>
            <Grid item xs={7}>
                <Box mt={3}>
                    <span className={classes.sectionTitle}>What are SNPs?</span>
                </Box>
                <Box mt={2}>
                    <span className="wh-tw-text-gray600">A SNP (Single Nucleotide Polymorphism) is a small change in the genetic code where one base (A, T, C, or G) is swapped for another. These variations are the most common type of genetic difference between people. While most SNPs have no effect, some can influence how we look, how our bodies function, or our risk of certain diseases.</span>
                </Box>
                <Box mt={1.5}>
                    <span className="wh-tw-text-gray600">Imagine a gene as a tower made of LEGO blocks. Each block (nucleotide) has a specific place in the structure. A SNP would be like swapping out one specific LEGO block for a slightly different one. Most of the time, the tower still stands as intended, but occasionally, that one block could be crucial for the stability of the entire structure. </span>
                </Box>
                <Box mt={5}>
                    <span className={classes.sectionTitle}>
                        {'Today’s Coaching'}
                    </span>
                    <span>(10 minutes)</span>
                </Box>
            </Grid>
        </>
    );

    const recommendations =  
        (
            <Grid key='recommendation' item xs={7}>
                <Box>
                    <TextFieldNoteComponent
                        isFollowUp
                        rows={10}
                        title=''
                        id={`recommendation-text-area`}
                        content={state.recommendations}
                        error={!!state.errors['recommendations']}
                        helperText={state.errors['recommendations']}
                        handleChanged={handleOnRecommendationAddChange}
                        handleKeyDown={handleOnRecommendationChange}
                    />
                </Box>
            </Grid>
        );

    const scriptsGoal = (
        <>
            <Box mt={2}>
                <span className={classes.sectionTitle}>Goals of this visit are</span>
            </Box>
            <Box pl={2} mt={2}>
                <ol className="wh-tw-list-decimal">
                    <li className="wh-tw-mb-3">Welcome and Build Rapport
                        <Box pl={2} mt={1}>
                            <ul className="wh-tw-list-disc">
                                <li>Tell them about yourself.</li>
                                <li>What brought them to Wild Health + Intention Statement</li>
                            </ul>
                        </Box>
                    </li>
                    <li className="wh-tw-mb-3">Genetics Explanation and education for what to expect in DNA review:
                        <Box pl={2} mt={1}>
                            <ul className="wh-tw-list-disc">
                                <li>A SNP (Single Nucleotide Polymorphism) is a small change in the genetic code where one base (A, T, C, or G) is swapped for another. These variations are the most common type of genetic difference between people. While most SNPs have no effect, some can influence how we look, how our bodies function, or our risk of certain diseases. SNPs are a key part of what makes each person genetically unique.</li>
                            </ul>
                        </Box>
                    </li>
                </ol>
            </Box>
            <Box mt={2}>
                <Box mt={1}>
                    <span>
                        <span className="wh-tw-text-error">*</span> Document Pharmacy and BP when possible
                    </span>
                </Box>
                <Box mt={1}>
                    <span>
                        <span className="wh-tw-text-error">*</span> Rebook patient OR educate them on self-scheduling
                    </span>
                </Box>
                <Box mt={1}>
                    <span>
                        <span className="wh-tw-text-error">*</span> Complete text in the free text box. Publish note within 24 hours 
                    </span>
                </Box>
            </Box>
        </>
    )

    const endOfTemplate = (
        <>
            <Box py={10}>
                {
                    !state.isProcessing &&
                    <Box mt={5} pb={5} display='flex' alignItems='center' justifyContent="space-between">
                        <Box display="flex">
                            <Box>
                                <WildHealthButton
                                    width={195}
                                    size="large"
                                    style={{ whiteSpace: 'nowrap' }}
                                    disabled={isFutureAppointment(state.note) || state.note?.isCompleted || state.isProcessing || !isCanCompleteNote()}
                                    id='sing-physical-history'
                                    onClick={() => handleSaveAndComplete()}
                                >
                                    <span>Sign and Complete</span>
                                </WildHealthButton>
                            </Box>
                            <Box ml={2}>
                                <WildHealthButton
                                    width={151}
                                    style={{ whiteSpace: 'nowrap' }}
                                    size="large"
                                    disabled={state.note?.isCompleted || state.isProcessing}
                                    id='sing-physical-history'
                                    color="secondary"
                                    onClick={() => handleSaveAsDraft()}
                                >
                                    <span>Save as Draft</span>
                                </WildHealthButton>
                            </Box>
                            <FeatureComponent featureFlag={FeatureFlag.SignOffNotes}>
                                <Box ml={2}>
                                    <WildHealthButton
                                        width={151}
                                        style={{ whiteSpace: 'nowrap' }}
                                        size="large"
                                        disabled={state.note?.isCompleted || state.isProcessing || !state.note}
                                        id='sing-physical-history'
                                        color="secondary"
                                        onClick={() => handleSignOff()}
                                    >
                                        <span>Send for Approval</span>
                                    </WildHealthButton>
                                </Box>
                            </FeatureComponent>
                        </Box>
                        <Box>
                            <WildHealthButton
                                size="large"
                                width={111}
                                disabled={state.isProcessing || !canDiscardNote(stateContext.note)}
                                id='cancel-physical-history'
                                onClick={() => { handleDiscard() }}
                                color='tertiary'
                            >
                                Discard
                            </WildHealthButton>
                        </Box>
                    </Box>
                }
                {
                    state.isProcessing &&
                    <Box display="flex" justifyContent="center">
                        <Box>
                            <CircularProgress size={24} style={{ color: colors.main }} />
                        </Box>
                    </Box>
                }
                <SignOffDialogComponent />
            </Box>
        </>
    );


    const fillArrayWithNoteLogs = (array: NoteLogModel[], questionnaire: QuestionnaireResultModel, namesMap: KeyMap[]) => {
        namesMap.forEach(name => {
            const question = questionnaire.answers.find(a => a.key === name.Key1);
            if (question) {
                if (CheckManyToTextField.find(i => i === name.Key2)) {
                    const answer: CheckAnswer = JSON.parse(question.value);
                    if (answer) {
                        let value = answer.v.join('\n');
                        if (answer.o) {
                            value += `\n${answer.o}`;
                        }
                        array.push({ key: name.Key2, value: value });
                    }
                } else {
                    array.push({ key: name.Key2, value: question.value });
                }
            }
        });
    }

    const autoSave = () => {
        if (stateContext.isChanged && !stateContext.isAutoSaving && !state.isFirstSaveOriginalNote && isElementEnabled([PermissionType.Coaching, PermissionType.ManagePatients], UserType.Employee)) {
            if (!createInitialConsultComponentValidator.stateIsValid(stateContext)) {
                return;
            }

            setState(state => ({
                ...state,
                isAutoSaving: true
            }));

            notesService.saveAsDraft(getData(stateContext), patientsQuery.getTargetPatientIsPremium()).subscribe(note => {
                setState(state => ({
                    ...state,
                    note: {
                        ...state.note,
                        id: state?.note?.id ?? note.id,
                        title: note.title,
                        visitDate: note.visitDate,
                    },
                    isChanged: false,
                    isAutoSaving: false,
                }));
            })
        }
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            autoSaveTimer.subscribe(() => {
                autoSave()
            }),
            onEmit<PatientModel>(patientsQuery.targetPatient$, targetPatient => {
                if (targetPatient?.id === Number(patientId)) {
                    setState(state => ({
                        ...state,
                        isFreeTrial: targetPatient.subscription?.paymentPlan?.isTrial ?? false
                    }));
                }
            }),
            onEmit<HealthSummaryMapModel[]>(healthSummaryQuery.map$, map => {
                setState(state => ({
                    ...state,
                    map: map
                }))

                if (map && map.length && (!note && !originalNote)) {
                    getPmhAndHpiData(map)
                }
            })
        ];

        if (note && !originalNote) {
            notesService.getContentByEmployee(note.id, patientId).subscribe(result =>
                setState(state => ({
                    ...state,
                    isLoading: false,
                    isNew: false,
                    recommendations: JSON.parse(result.content),
                    internalContent: JSON.parse(result.internalContent),
                    logs: result.logs
                })));
        } else {

            setState(state => ({
                ...state,
                isAutoSaving: true
            }));

            if (originalNote) {
                notesService.getContentByEmployee(originalNote.id, patientId).subscribe(result => {
                    const content = JSON.parse(result.content);
                    const internalContent = JSON.parse(result.internalContent);
                    setState(state => ({
                        ...state,
                        isNoteContentLoading: false,
                        recommendations: content,
                        internalContent: internalContent,
                        isNew: false,
                        logs: result.logs,
                        title: originalNote.title
                    }));

                    setState(state => ({
                        ...state,
                        isLoading: false
                    }));

                    notesService.saveAsDraft(getSaveModelFromOriginalNote(content), patientsQuery.getTargetPatientIsPremium()).subscribe(
                        (note) => {
                            setState(state => ({
                                ...state,
                                note: note,
                                isAutoSaving: false,
                                isFirstSaveOriginalNote: false
                            }))
                        },
                        () => {
                            snackService.error('Note already exists for this appointment.');
                        }
                    );
                });
            }
            else {
                notesService.saveAsDraft(getData(state), patientsQuery.getTargetPatientIsPremium()).subscribe(
                    (note) => {
                        setState(state => ({
                            ...state,
                            note: note,
                            isAutoSaving: false
                        }))
                    },
                    () => {
                        snackService.error('Note already exists for this appointment.');
                    }
                );

                questionnaireService.getLatestResults(patientId, [QuestionnaireType.HealthLog, QuestionnaireType.Initial, QuestionnaireType.HealthForms]).subscribe(questionnaireResults => {
                    const answers: NoteLogModel[] = [];

                    if (questionnaireResults.length) {
                        const results = questionnaireResults
                            .filter(x => x.submittedAt)
                            .sort((r1, r2) => {
                                return new Date(r1.submittedAt).getTime() - new Date(r2.submittedAt).getTime();
                            });

                        const initial = results?.filter(q => q.questionnaire.type === QuestionnaireType.Initial)?.pop();
                        const healthLog = results?.filter(q => q.questionnaire.type === QuestionnaireType.HealthLog)?.pop();

                        if (initial) {
                            fillArrayWithNoteLogs(answers, initial, InitialConsultToInitialQuestionNamesMap);
                        }
                        if (healthLog) {
                            fillArrayWithNoteLogs(answers, healthLog, InitialConsultToHealthLogQuestionNamesMap);
                        }
                    }

                    questionnaireService.getLatestHealthFormsResults(patientId).subscribe(questionnaireResults => {
                        if (questionnaireResults.length) {
                            const results = questionnaireResults
                                .filter(x => x.submittedAt)
                                .sort((r1, r2) => {
                                    return new Date(r1.submittedAt).getTime() - new Date(r2.submittedAt).getTime();
                                });

                            const goalsQuestionnaire = results?.filter(q => q.questionnaire.subType === QuestionnaireSubType.GoalsIncomplete)?.pop();

                            if (goalsQuestionnaire) {
                                const intention = goalsQuestionnaire.answers.find(x => x.key === HealthGoalsQuestionNames.YOUR_INTENTION);
                                setState(state => ({
                                    ...state,
                                    internalContent: {
                                        ...state.internalContent,
                                        intention: intention?.value ?? '',
                                    }
                                }));
                            }
                        }

                        setState(state => ({
                            ...state,
                            isLoading: false
                        }));
                    });
                });
            }
        }

        healthSummaryService.getMap();
        patientsService.get(patientId, DataSpecificationsEnum.PatientInitialConsultSpecification);

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, [patientId, note]);

    return [
        state,
        internalNotes,
        medicationSupplements,
        scriptsGoal,
        recommendationsHeader,
        recommendations,
        endOfTemplate,
        noteName,
        titleTextBox,
        yourIntention,
        bloodPressure,
        problemList()
    ];
}
