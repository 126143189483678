
import React, { useMemo } from 'react';
import {Box, Button, CircularProgress, Divider, Tooltip, useMediaQuery, useTheme, Grid} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import clsx from 'clsx';
import {useFacade} from './CreatePatientAppointmentFormComponent.hooks';
import {useStyles} from './CreatePatientAppointmentFormComponent.styles';
import {SelectAppointmentWithTypeComponent} from '../selectAppointmentWithType/SelectAppointmentWithTypeComponent';
import {SelectAppointmentPurposeComponent} from '../selectAppointmentPurpose/SelectAppointmentPurposeComponent';
import {WildHealthButton} from '../../../common/components/wildHealthButton/WildHealthButton';
import {SelectTimezoneComponent} from '../selectTimezone/SelectTimezoneComponent';
import {SelectDateTimeComponent} from '../selectDateTime/SelectDateTimeComponent';
import {isCoachRole, isProviderRole} from '../../../common/constants/roles';
import {LocationModel} from '../../../locations/models/locations.models';
import {EmployeeModel} from "../../../employee/models/employee.models";
import {AppointmentTypeModel} from '../../models/appointments.models';
import {AppointmentWithType} from '../../models/appointments.enums';
import {authQuery} from '../../../auth/stores/auth';
import {TimeZoneModel} from "../../../timezones/models/timezone.model";
import {SelectLocationComponent} from '../selectLocation/SelectLocationComponent';
import {ReactComponent as TimeCircleIcon} from "@img/icons/timeCircle.svg";
import {ReactComponent as MonthlyCalendarIcon} from "@img/icons/monthlyCalendar.svg";
import {SelectProviderComponent} from "../selectProvider/SelectProviderComponent";
import {SubscriptionStatus, SubscriptionType} from '../../../payment/models/subscription.models';
import { FeatureComponent } from '../../../common/components/featureFlags/FeatureComponent';
import { FeatureFlag } from '../../../common/components/featureFlags/featureFlags.models';
import {
    getAppointmentMinDate,
    getAvailableWithTypes
} from "../../helpers/appointmentHelper";
import { AppointmentReasonComponent } from '../appointmentReason/AppointmentReasonComponent';
import { availabilityQuery } from '../../stores/availability/availability.query';

interface ScheduleAppointmentFormComponentProps {
    assignedEmployees: EmployeeModel[];
    isContinueDialog: boolean;
    toggleDialog: Function;
    handleContinueDialog: Function;
    appointmentWithType: AppointmentWithType;
    appointmentTypes: AppointmentTypeModel[];
    locations: LocationModel[];
    handleCloseDialog: Function;
    timeZone: TimeZoneModel;
    patientId?: number | null;
}

export const CreatePatientAppointmentFormComponent: React.FC<ScheduleAppointmentFormComponentProps> = (props: ScheduleAppointmentFormComponentProps) => {
    const {
        assignedEmployees,
        isContinueDialog,
        toggleDialog,
        appointmentWithType,
        appointmentTypes,
        locations,
        handleCloseDialog,
        handleContinueDialog,
        timeZone,
        patientId = null
    } = props;

    const [
        state,
        selectEmployee,
        handleChangeAppointmentPurpose,
        handleChangeTimezone,
        handleDate,
        handleTime,
        handleRequestAppointment,
        handleChangeCoachType,
        handleChangeLocationType,
        handleProviderSelect,
        canChargePatient,
        canContinue,
        handleChangeReason,
        handleSelectReason,
        handleReadMore,
        handleGoToAccountSetting
    ] = useFacade(toggleDialog, assignedEmployees, appointmentWithType, locations, appointmentTypes, timeZone, patientId);

    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const isDefaultPractice = authQuery.isDefaultPractice();

    const getWithTypes = (appointmentTypes: AppointmentTypeModel[], assignedEmployees: EmployeeModel[]) => {
        const withTypes = getAvailableWithTypes(appointmentTypes.filter(x => x.isCreateAvailable));
        const uniqueTypes = withTypes.filter((v, i, a) => a.indexOf(v) === i);

        let availableWithTypes: Array<AppointmentWithType> = [];

        const hasCoach = assignedEmployees.some(x => isCoachRole(x.role.id));
        if (hasCoach) {
            availableWithTypes = availableWithTypes.concat(uniqueTypes.filter(x => x === AppointmentWithType.HealthCoach));
        }

        const hasProvider = assignedEmployees.some(x => isProviderRole(x.role.id));
        if (hasProvider) {
            availableWithTypes = availableWithTypes.concat(uniqueTypes.filter(x => x === AppointmentWithType.Provider));
        }

        if (hasCoach && hasProvider) {
            availableWithTypes = availableWithTypes.concat(uniqueTypes.filter(x => x === AppointmentWithType.HealthCoachAndProvider));
        }

        return availableWithTypes;
    }


    const minDate = useMemo(() => {
        return availabilityQuery.getEarliestAvailability();
    }, [state.appointment, state.selectedAppointmentConfiguration]);

    const renderSummary = () => {
        const appointmentType = state.selectedAppointmentType;

        if (!appointmentType.requiredProductType) {
            return <></>;
        }

        const product = state.products.find(x => x.type === appointmentType.requiredProductType);
        if (!product) {
            return <></>;
        }

        return (
            <>
                {
                    state.subscription?.type === SubscriptionType.Regular &&
                    <Box p={3} pt={3} className="wh-tw-border wh-tw-border-solid wh-tw-border-separators wh-tw-rounded-sm" mt={5} >
                        <Box className="wh-tw-text-xl wh-tw-font-medium">Summary</Box>
                        <Box pt={2.5} pb={2.5} display="flex" justifyContent="space-between" className="wh-tw-text-sm wh-tw-text-gray1">
                            <span >Provider Visit</span>
                            <span >{`$${product.price}`}</span>
                        </Box>
                        <Divider />
                        <Box pt={2.5} pb={2.5} display="flex" justifyContent="space-between" className="wh-tw-text-xl wh-tw-font-medium">
                            <span >Total</span>
                            <span >{`$${product.price}`}</span>
                        </Box>
                    </Box>
                }
                <Box mt={2} mb={7} py="10px" px="15px" display="flex" justifyContent="space-between" alignItems="center" className="wh-tw-bg-gray8 wh-tw-text-gray1">
                        {
                            state.subscription?.type === SubscriptionType.Regular &&
                            <Box>We'll charge your card on file for this amount.</Box>
                        }
                        {
                            state.subscription?.type === SubscriptionType.Insurance && state.subscription?.status === SubscriptionStatus.Active &&
                            <Box>We'll use your insurance card on file for this appointment.</Box>
                        }
                    <Box className="wh-tw-text-gray2">
                        {
                            state.subscription?.type === SubscriptionType.Regular &&
                            <Tooltip title="You will be charged upon scheduling. If you cancel your appointment for any reason, you'll be able to redeem your visit at any time as long as you have an active membership. If you have any questions, please reach out to support@wildhealth.com" >
                                <InfoIcon fontSize='small' color='inherit' />
                            </Tooltip>
                        }
                        {
                            state.subscription?.type === SubscriptionType.Insurance && state.subscription?.status === SubscriptionStatus.Active &&
                            <Tooltip title="You will be receiving an appointment confirmation email the day before your visit, which will inform you of any applicable copay. Once your visit is completed you might be charged for any deductible or co-insurance depending on your insurance. If you have any questions, please reach out to support@wildhealth.com" >
                                <InfoIcon fontSize='small' color='inherit' />
                            </Tooltip>
                        }
                    </Box>
                </Box>
            </>
        )
    }

    return (
        <Box>
            {
                isSmallScreen &&
                <Box py={1} display="flex" justifyContent="space-between">
                    <Button disabled={state.isLoading} onClick={() => handleCloseDialog()}>
                        <span className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-gray1 wh-tw-capitalize">
                            Cancel
                        </span>
                    </Button>
                    <Box>
                        <span className="wh-tw-text-xl wh-tw-font-medium wh-tw-text-black">
                            {isContinueDialog ? "Appt Confirmation" : "Scheduling Appt"}
                        </span>
                    </Box>
                    <Button disabled={state.isLoading || !state.appointment.startDate} onClick={() => isContinueDialog || authQuery.isLicensingPractice() || !canChargePatient() ? handleRequestAppointment() : handleContinueDialog()}>
                        {state.isLoading ? <CircularProgress size={24} className="wh-tw-text-primaryV" /> : <span className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-primaryV wh-tw-capitalize">
                            Schedule
                        </span>}
                    </Button>
                </Box>
            }
            {!isDefaultPractice || state.selectedWithType === AppointmentWithType.HealthCoachAndProvider ? (
                <>
                <Box py={3}>
                    <SelectAppointmentWithTypeComponent
                        availableTypes={getWithTypes(appointmentTypes, assignedEmployees)}
                        value={state.selectedWithType}
                        handleChange={handleChangeCoachType}
                        isReadonly={isDefaultPractice}
                    />
                </Box>
                <Divider />
                <Box py={3}>
                    <SelectAppointmentPurposeComponent
                        value={state.selectedPurpose}
                        handleChange={handleChangeAppointmentPurpose}
                        isReadonly={isDefaultPractice}
                        availableTypes={appointmentTypes}
                        withType={state.selectedWithType}
                    />
                </Box>
                </>
            ) : (
                <>
                <Box py={3}>
                    <Grid container>
                        <Grid item xs={12} md={6} lg={6}>
                            <SelectAppointmentWithTypeComponent
                                availableTypes={getWithTypes(appointmentTypes, assignedEmployees)}
                                value={state.selectedWithType}
                                handleChange={handleChangeCoachType}
                                isReadonly={isDefaultPractice}
                            />
                        </Grid>
                        {!isSmallScreen && 
                            <Grid item xs={12} md={6} lg={6}>
                                <SelectAppointmentPurposeComponent
                                    value={state.selectedPurpose}
                                    handleChange={handleChangeAppointmentPurpose}
                                    isReadonly={isDefaultPractice}
                                    availableTypes={appointmentTypes}
                                    withType={state.selectedWithType}
                                />
                            </Grid>
                        }
                    </Grid>
                </Box>
                {isSmallScreen && (
                    <>
                    <Divider />
                    <Box py={3}>
                        <SelectAppointmentPurposeComponent
                            value={state.selectedPurpose}
                            handleChange={handleChangeAppointmentPurpose}
                            isReadonly={isDefaultPractice}
                            availableTypes={appointmentTypes}
                            withType={state.selectedWithType}
                        />
                    </Box>
                    </>
                )}
                </>
            )}
            {state.selectedAppointmentType?.requiresReasonType && (
                <FeatureComponent featureFlag={FeatureFlag.AppointmentReasons}>
                    <Divider />
                    <Box my={3}>
                        <AppointmentReasonComponent
                            reason={state.appointment.reason}
                            selectedReasonType={state.selectedReasonType}
                            errors={state.errors}
                            handleChangeReason={handleChangeReason}
                            handleSelectReason={handleSelectReason}
                        />
                    </Box>
                </FeatureComponent>
            )}
            <Divider />
            {isContinueDialog
                ? <>
                    <Box py={3}>
                        <Box>
                            <span className="wh-tw-text-sm wh-tw-text-gray1">Date and time</span>
                        </Box>
                        <Box display="flex" mt={1}>
                            <Box mr={2} display="flex" minWidth="max-content">
                                <MonthlyCalendarIcon />
                                <Box pl={1} className="wh-tw-font-medium">{moment(state.appointment?.startDate).format("dddd DD, MMMM yyyy")}</Box>
                            </Box>
                            <Box display="flex" minWidth="max-content">
                                <TimeCircleIcon />
                                <Box pl={1} className="wh-tw-font-medium">{moment(state.appointment?.startDate).format("hh:mm a")} </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Divider />
                    {
                        renderSummary()
                    }
                </>
                : <>
                    <Box py={3}>
                        <SelectTimezoneComponent
                            timeZones={state.timeZones}
                            value={state.selectedTimeZoneId}
                        />
                    </Box>
                    {state.meetingRecordingConsent && (
                        <FeatureComponent featureFlag={FeatureFlag.TranscriptionConsent}>
                            <Divider />
                            <Box my={3}>
                                <Box display="flex" alignItems="center" gridGap={4}>
                                    <Box><ReportProblemOutlinedIcon className="wh-tw-text-average3" /></Box>
                                    <Box className="wh-tw-text-sm wh-tw-text-gray1">Terms of service & Notice of Privacy Practices</Box>
                                </Box>
                                <Box className={clsx("wh-tw-text-xs wh-tw-text-gray1 wh-tw-mt-0.5", isSmallScreen && !state.isFullText && classes.descriptionCollapsed)}>By participating in a session with your provider, you understand and agree that the session will be recorded for transcription purposes. You may opt-out at any time by adjusting your Account Settings. Click <span className="wh-tw-text-primaryV wh-tw-cursor-pointer" onClick={handleGoToAccountSetting}>here</span> to go to Account Setting. Transcriptions are used to document medical records and improve the service provided to you.</Box>
                                
                                {isSmallScreen && <Box display="flex" alignItems="center" justifyContent="flex-end" mt={1} onClick={() => handleReadMore()}>
                                    <span className="wh-tw-text-primaryV wh-tw-text-sm wh-tw-font-medium wh-tw-capitalize">{state.isFullText ? 'read less' : 'read more'}</span>
                                    <Box ml={0.5}>{state.isFullText ? <ExpandLessIcon /> : <ExpandMoreIcon />}</Box>
                                </Box>}
                            </Box>
                        </FeatureComponent>
                    )}
                    <Divider />
                    <Box my={2}>
                        <SelectLocationComponent
                            location={state.selectedLocation}
                            selectedLocationType={state.selectedLocationType}
                            handleChangeLocationType={handleChangeLocationType}
                            phoneNumber={authQuery.getPhoneNumber()}
                        />
                    </Box>
                    <Box py={3}>
                        <SelectDateTimeComponent
                            selectedTime={state.appointment.startDate}
                            selectedDate={new Date(state.selectedDate)}
                            minDate={minDate}
                            handleChangeTime={handleTime}
                            handleChangeDate={handleDate}
                            availability={state.availability}
                            isLoading={state.isLoading}
                            error={!!state.errors['startDate']}
                            errorMessage={state.errors['startDate']}
                        />
                    </Box>
                    {
                        selectEmployee && state.timeSlotSelected &&
                        <Box py={3}>
                            <SelectProviderComponent
                                isLoading={state.isLoading}
                                error={state.errors['selectedProvider']}
                                recommendedProvider={state.recommendedProvider}
                                providers={state.potentialProviders}
                                selectedProvider={state.selectedProvider}
                                onProviderSelect={handleProviderSelect}
                            />
                        </Box>
                    }
                </>
            }
            {
                !isSmallScreen &&
                <Box display="flex" justifyContent="flex-end">
                    <Box>
                        <WildHealthButton
                            id="create-patient-appointment-cancel"
                            disabled={state.isLoading}
                            width={120}
                            onClick={() => { handleCloseDialog() }}
                            color='tertiary'
                            size='large'
                        >
                            Cancel
                        </WildHealthButton>
                    </Box>
                    <Box ml={1}>
                        {isContinueDialog || authQuery.isLicensingPractice() || !canChargePatient()
                            ? <WildHealthButton
                                id="create-patient-appointment-schedule"
                                loading={state.isLoading}
                                width={240}
                                onClick={handleRequestAppointment}
                                size='large'
                            >
                                Schedule Appointment
                            </WildHealthButton>
                            : <WildHealthButton
                                id="create-patient-appointment-schedule"
                                disabled={!state.appointment.startDate}
                                width={240}
                                onClick={() => {
                                    if (canContinue()) {
                                        handleContinueDialog()
                                    }
                                }}
                                size='large'
                            >
                                Continue
                            </WildHealthButton>}
                    </Box>
                </Box>
            }
        </Box >
    );
}
