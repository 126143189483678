import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { onEmit } from "../../../common/helpers/on-emit";
import { SortingDirection } from "../../../common/sorting/models/sorting-destination";
import { ISortingState } from "../../../common/sorting/models/sorting-state";
import { pageSizes } from "../../../common/pagination/models/page-sizes";
import { IPaginationState } from "../../../common/pagination/models/pagination-state";
import { handleCompare } from "../../../common/sorting/helpers/handle-compare";
import { getAvailablePages } from "../../../common/pagination/helpers/get-evailable-pages";
import { paymentPlansQuery } from "../../../payment/stores/paymentPlansStore";
import { paymentPlansService } from "../../../payment/services/paymentPlans.service";
import { PromoCodeVewModel } from "../../../payment/models/paymentCoupon.model";

export enum SortingSource {
    None,
    Discount = "discount",
    ExpirationDate = "expirationDate",
    Plans = "paymentPlans",
    Additional = "additional",
    Status = "status"
}

interface PromoCodesState extends IPaginationState, ISortingState {
    isLoading: boolean;
    promoCodes: PromoCodeVewModel[];
    originalPromoCodes: PromoCodeVewModel[];
    menuAnchorEl: HTMLButtonElement;
    searchKey: string;
    selectedCodeId: number;
    targetCode: PromoCodeVewModel;
}

let stateContext: PromoCodesState;

export function useFacade(): [
    PromoCodesState,
    (query: string) => void,
    () => number[],
    (value: any) => void,
    (page: number) => void,
    (source: string, direction: SortingDirection) => void,
    (promoCodeId: number, menuAnchorEl?: HTMLButtonElement) => void,
    () => void,
    () => void
] {
    const [state, setState] = useState({
        isLoading: true,
        promoCodes: [],
        originalPromoCodes: [],
        menuAnchorEl: null,
        searchKey: '',
        selectedCodeId: null,
        targetCode: null,
        sortingColumns: [
            {title: 'Code name'},
            {title: 'Discount', direction: SortingDirection.Asc, source: SortingSource.Discount},
            {title: 'Plans', direction: SortingDirection.Asc, source: SortingSource.Plans},
            {title: 'Expiration date', direction: SortingDirection.Asc, source: SortingSource.ExpirationDate},
            {title: 'Additional', direction: SortingDirection.Asc, source: SortingSource.Additional},
            {title: 'Status', direction: SortingDirection.Asc, source: SortingSource.Status}
        ],
        sortingSource: SortingSource.None,
        totalCount: 0,
        selectedPage: 1,
        pageSize: pageSizes[0]
    } as PromoCodesState);

    stateContext = state;

    const handleOnFilter = (search: string) => {
        setState(state => ({...state, searchKey: search}));
    }

    const handleSearchSubmit = (query: string) => {
        const searchQuery = query ?? '';

        const trimedSearch = decodeURIComponent(searchQuery).trim().toLowerCase();
        const arrayTrimSearchKey = trimedSearch.split(' ');

        const filteredPromoCodes = state.originalPromoCodes?.filter(
        (e) =>
            arrayTrimSearchKey.find((item) =>
                e.code?.toLowerCase().includes(item)
            ) !== undefined
        );
      
        setState(state => ({
            ...state,
            isLoading: false,
            selectedPage: 1,
            promoCodes: searchQuery ? filteredPromoCodes : state.originalPromoCodes,
            totalCount:  searchQuery ? filteredPromoCodes.length : state.originalPromoCodes.length,
        }));
    }

    const setDirection = (source: string, direction: SortingDirection) => {
        const itemIndex = state.sortingColumns.findIndex(item => item.source === source);
        state.sortingColumns[itemIndex].direction = direction;
        setState(state => ({ ...state, columns: state.sortingColumns }));
    }

    const handleSorting = (source: string, direction: SortingDirection) => {
        if (state.sortingSource === source) {
            direction = direction === SortingDirection.Asc
                ? SortingDirection.Desc
                : SortingDirection.Asc;

            setDirection(source, direction);
        }
        setState(state => ({
            ...state,
            sortingSource: source,
            promoCodes: state.promoCodes.sort((p1, p2) => handleCompare(p1, p2, direction, source))
        }));
    }

    const getAllAvailablePages = () => {
        return getAvailablePages(state);
    }

    const handlePageSizeChange = (value: any) => {
        if (value === state.pageSize) {
            return;
        }

        setState(state => ({
            ...state,
            pageSize: value,
            selectedPage: 1
        }));
    }

    const handlePageChange = (page: number) => {
        if (page === state.selectedPage) {
            return;
        }

        setState(state => ({
            ...state,
            selectedPage: page
        }));
    }

    const handleToggleMenu = (promoCodeId: number, menuAnchorEl?: HTMLButtonElement) => {
        setState(state => ({ 
            ...state, 
            menuAnchorEl,
            selectedCodeId: promoCodeId,
            targetCode: promoCodeId > 0 ? state.promoCodes.find(pc => pc.id === promoCodeId) : null
        }))
    }

    const handleDeactivate = () => {
        paymentPlansService.deactivatePromoCode({...state.targetCode, status: "Inactive"}).subscribe();
        handleToggleMenu(0);
    }

    const handleDelete = () => {
        paymentPlansService.deletePromoCode(state.selectedCodeId).subscribe();
        handleToggleMenu(0);
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<PromoCodeVewModel[]>(paymentPlansQuery.promoCodes$, promoCodes => {
                setState(state => ({
                    ...state,
                    promoCodes: promoCodes,
                    originalPromoCodes: promoCodes,
                    totalCount: promoCodes.length
                }));
            }),
        ];

        const cb = () => setState(state => ({ ...state, isLoading: false }));

        paymentPlansService.getPromoCodes().subscribe(cb, cb);

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, []);

    useEffect(() => {
      const delayDebounceFn = setTimeout(() => {
        if (!stateContext.isLoading) {
          handleSearchSubmit(stateContext.searchKey);
        }
      }, 1000);

      return () => clearTimeout(delayDebounceFn)
    }, [stateContext.searchKey]);

    return [
        state,
        handleOnFilter,
        getAllAvailablePages,
        handlePageSizeChange,
        handlePageChange,
        handleSorting,
        handleToggleMenu,
        handleDeactivate,
        handleDelete
    ];
}