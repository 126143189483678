import { Box, Divider, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { WildHealthPlaceHolder } from "../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { CircleDiagramComponent } from "../components/circleDiagramComponent/CircleDiagramComponent";
import { MicrobiomeItemComponent, MicrobiomeItemMobileComponent } from "../components/microbiomeItemComponent/MicrobiomeItemComponent";
import { HealthReportSections, MicrobiomeType, HealthReportMode } from "../models/healthReport.models";
import { HealthReportBasePage, HealthReportMobileBasePage } from "./HealthReportBasePage";
import { useStyles } from './healthReport.styles';
import { HealthReportPageProps } from "./healthReportPageProps";

export const HealthReportMicrobiome1Page: React.FC<HealthReportPageProps> = (props: HealthReportPageProps) => {
    const {
        report,
        pageNumber,
        practice
    } = props;
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const inflammationName = "Inflammation Score";
    const diversityName = "Diversity score";
    const inflammationScore = report.microbiomeReport.microbiomeResistance.values.find(x => x.name === inflammationName)
    const diversityScore = report.microbiomeReport.microbiomeResistance.values.find(x => x.name === diversityName)
    const microbiome = report.microbiomeReport.microbiomeResistance.values.filter(x => x.type === MicrobiomeType.Flora);

    const renderInflammationScore = () => {
        if (!inflammationScore || inflammationScore.value === null) {
            return (
                <Box pr={2}>
                    <WildHealthPlaceHolder message="No data" />
                </Box>
            )
        }

        return (
            <Box>
                <Box display="flex" justifyContent="center">
                    <CircleDiagramComponent radius={80} data={[
                        { name: 'Inflammation', value: inflammationScore?.value ?? 0, color: '#97c4d9' },
                        { name: 'Inflammation', value: 100 - inflammationScore?.value ?? 0, color: '#7293a7' }
                    ]} />
                </Box>
                <Box textAlign="center">
                    <span className="text-medium">Inflammation Score: {inflammationScore?.value}% </span>
                </Box>
            </Box>
        )
    };

    const renderDiversityScore = () => {
        if (!diversityScore || diversityScore.value === null) {
            return (
                <Box pl={2}>
                    <WildHealthPlaceHolder message="No data" />
                </Box>
            )
        }

        return (
            <Box pl={2}>
                <Box display="flex" justifyContent="center">
                    <CircleDiagramComponent radius={80} data={[
                        { name: 'Diversity', value: diversityScore?.value ?? 0, color: '#89bd24' },
                        { name: 'Diversity', value: 100 - diversityScore?.value ?? 0, color: '#bbc6ab' }
                    ]} />
                </Box>
                <Box textAlign="center">
                    <span className="text-medium">Diversity Score: {diversityScore?.value ?? 0}% </span>
                </Box>
            </Box>
        )
    };

    const renderOtherMicrobiome = () => {
        return microbiome
            .filter(x => x.name !== inflammationName && x.name !== diversityName)
            .map((value, index) => {
                return <MicrobiomeItemComponent key={index} value={value} />
            })
    }

    return (
        props.mode !== HealthReportMode.PrintMode && isSmallScreen ? (
            <HealthReportMobileBasePage section={HealthReportSections.Microbiome} pageNumber={pageNumber} practice={practice}>
                <Box>
                    <Box px={2}>
                        <Box textAlign="center">
                            <span className={classes.reportTitle}>Inflammation and Diversity</span>
                        </Box>
                        <Box>
                            <Box mt="18px">
                                <Box>
                                    <Box display="flex" justifyContent="center">
                                        <CircleDiagramComponent radius={80} data={[
                                            { name: 'Inflammation', value: inflammationScore?.value ?? 0, color: '#97c4d9' },
                                            { name: 'Inflammation', value: 100 - inflammationScore?.value ?? 0, color: '#7293a7' }
                                        ]} />
                                    </Box>
                                    <Box textAlign="center">
                                        <span className="text-medium">Inflammation Score: {inflammationScore?.value ?? 0}% </span>
                                    </Box>
                                </Box>
                            </Box>
                            <Box mt="30px">
                                <Box pl={!isSmallScreen && 2}>
                                    <Box display="flex" justifyContent="center">
                                        <CircleDiagramComponent radius={80} data={[
                                            { name: 'Diversity', value: diversityScore?.value ?? 0, color: '#89bd24' },
                                            { name: 'Diversity', value: 100 - diversityScore?.value ?? 0, color: '#bbc6ab' }
                                        ]} />
                                    </Box>
                                    <Box textAlign="center">
                                        <span className="text-medium">Diversity Score: {diversityScore?.value ?? 0}% </span>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box mt="30px">
                            {inflammationScore && <Box mt="20px"><MicrobiomeItemMobileComponent value={inflammationScore} /></Box>}
                            {diversityScore && <Box mt="20px"><MicrobiomeItemMobileComponent value={diversityScore} /></Box>}
                        </Box>
                    </Box>
                    <Divider className="report-divider" />
                    <Box px={2}>
                        <Box>
                            <span className={classes.reportTitle}>important flora</span>
                        </Box>
                        <Box>
                            {
                                microbiome.filter(x => x.name !== inflammationName && x.name !== diversityName).map((value, index) => (
                                    <Box mt="20px" key={index}><MicrobiomeItemMobileComponent value={value} /></Box>
                                ))
                            }
                        </Box>
                    </Box>
                </Box>
            </HealthReportMobileBasePage>
        ) : (
            <HealthReportBasePage section={HealthReportSections.Microbiome} pageNumber={pageNumber} practice={practice}>
                <Box>
                    <Box px={15} pt={5}>
                        <Box textAlign="center">
                            <span className="report-title">Inflammation and Diversity</span>
                        </Box>
                        <Box mt={3}>
                            <Grid container spacing={5}>
                                <Grid item xs={3}>
                                </Grid>
                                <Grid item xs={3}>
                                    {renderInflammationScore()}
                                </Grid>
                                <Grid item xs={3}>
                                    {renderDiversityScore()}
                                </Grid>
                                <Grid item xs={3}>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box pt={5}>
                            {inflammationScore && <MicrobiomeItemComponent value={inflammationScore} />}
                            {diversityScore && <MicrobiomeItemComponent value={diversityScore} />}
                        </Box>
                    </Box>
                    <Divider className="report-divider" />
                    <Box px={15}>
                        <Box>
                            <span className="report-title">important flora</span>
                        </Box>
                        <Box>
                            {renderOtherMicrobiome()}
                        </Box>
                    </Box>
                </Box>
            </HealthReportBasePage>
        )
    )
}
