import {
    patientMedicationsStore,
    PatientMedicationsStore
} from "../stores/patientMedicationsStore/patientMedications.store";
import {Observable} from "rxjs";
import {RxntMedicationModel} from "../models/patientMedicationModel";
import {authHeader} from "../../common/helpers/auth-header";
import Axios from "axios-observable";
import {snackService} from "../../common/snack/state";
import {RxntAllergyModel} from "../models/patientAllergiesModel";
import {patientAllergiesStore, PatientAllergiesStore} from "../stores/patientAllergyStore/patientAllergies.store";

export class PrescriptionService {
    constructor(
        private medicationsStore: PatientMedicationsStore,
        private allergiesStore: PatientAllergiesStore) {
    }

    public getPatientActiveMeds(patientId: number): Observable<RxntMedicationModel[]> {
        const url = `${process.env.REACT_APP_API_URL}Prescriptions/${patientId}/activeMeds`;
        const config = { headers: authHeader() };

        return new Observable<RxntMedicationModel[]>((observer) => {
            Axios.get<RxntMedicationModel[]>(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.medicationsStore.update({rxntMedications: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        })
    }

    public getMyActiveMeds(): Observable<RxntMedicationModel[]> {
        const url = `${process.env.REACT_APP_API_URL}Prescriptions/activeMeds`;
        const config = { headers: authHeader() };

        return new Observable<RxntMedicationModel[]>((observer) => {
            Axios.get<RxntMedicationModel[]>(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.medicationsStore.update({rxntMedications: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        })
    }

    public getPatientAllergies(patientId: number): Observable<RxntAllergyModel[]> {
        const url = `${process.env.REACT_APP_API_URL}Prescriptions/${patientId}/allergies`;
        const config = { headers: authHeader() };

        return new Observable<RxntAllergyModel[]>((observer) => {
            Axios.get<RxntAllergyModel[]>(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.allergiesStore.update({rxntAllergies: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        })
    }

    public getMyAllergies(): Observable<RxntAllergyModel[]> {
        const url = `${process.env.REACT_APP_API_URL}Prescriptions/allergies`;
        const config = { headers: authHeader() };

        return new Observable<RxntAllergyModel[]>((observer) => {
            Axios.get<RxntAllergyModel[]>(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.allergiesStore.update({rxntAllergies: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        })
    }
}

export const prescriptionService = new PrescriptionService(
    patientMedicationsStore,
    patientAllergiesStore
);