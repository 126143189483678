import {
  LabInputNotificationType,
  LabRangeModel,
  ValueInputModel,
} from "../../models/input.models";
import { VitalModel } from "../../../vital/models/vital.model";
import React from "react";
import {
  Box,
  Divider,
  Grid,
  Popover,
  Tooltip,
  useTheme,
  useMediaQuery,
  IconButton,
} from "@material-ui/core";
import moment from "moment";
import warning from "@img/icons/warning.svg";
import { sortLabInputValues } from "../../helpers/sort-lab-input-values";
import { renderLabValuesGraph } from "../../helpers/render-lab-values-graph";
import commonUseStyles from "../../../common/styles/common.styles";
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";
import { useStyles } from "./ValuesDetails.style";
import { isIOS } from "react-device-detect";

interface ValuesDetailsComponentProps {
  isOpen: boolean;
  id: number;
  title?: string;
  anchorEl: any;
  values: ValueInputModel[];
  vitalValues: VitalModel[];
  range: LabRangeModel;
  handleClose: (id: number) => void;
}

export const ValuesDetailsComponent: React.FC<ValuesDetailsComponentProps> = (
  props: ValuesDetailsComponentProps
) => {
  const { isOpen, id, title, anchorEl, values, range, handleClose } = props;

  const commonClasses = commonUseStyles();
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const initializedValues = values
    .filter((x) => x.isInitialized)
    .sort(sortLabInputValues);

  if (!initializedValues.length) {
    return <></>;
  }

  const outOfNorm = (value: ValueInputModel) => {
    return value.notification?.notificationType === LabInputNotificationType.Error;
  };
  return (
    <Popover
      style={{ pointerEvents: "none" }}
      PaperProps={{
        style: isSmallScreen
          ? {
            width: "100vw",
            maxWidth: "100vw",
            borderRadius: isIOS ? "10px 10px 0 0" : "0",
            height: "50vh",
          }
          : { width: "500px" },
      }}
      marginThreshold={isSmallScreen ? 0 : 16}
      id='mouse-over-popover'
      open={isOpen}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      onClose={() => handleClose(id)}
      disableRestoreFocus
      BackdropProps={{ invisible: isSmallScreen ? false : true }}
    >
      <Box p={2}>
        <Box className={classes.title}>
          <span className={commonClasses.textMedium}>{title}</span>
          {isSmallScreen ? (
            <IconButton
              aria-label='close'
              onClick={() => handleClose(id)}
              className={classes.closeIcon}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </Box>
        <Box my={2} className={commonClasses.size14}>
          <Grid container justify="space-between">
            {initializedValues.map((value, index) => {
              return (
                <Grid item md={6} sm={12} key={index}>
                  <Grid container>
                    <Grid item md={6} sm={12}>
                      <span className={commonClasses.colorGray1}>
                        {moment(value.date).format("MM/DD/YYYY")}:
                      </span>
                    </Grid>
                    <Grid item md={6} sm={12}>
                      <Box display='flex' alignItems='center' justifyContent='flex-start'>
                        <Box className={commonClasses.textMedium}>
                          <span
                            className={clsx({
                              [commonClasses.colorBad]: outOfNorm(value),
                            })}
                          >
                            {value.value}
                          </span>
                        </Box>
                        {outOfNorm(value) && (
                          <Box ml={1} mt='1.5px'>
                            <Tooltip title={value.notification?.message}>
                              {value.notification?.notificationType ===
                                LabInputNotificationType.Error ? (
                                <img src={warning} alt='img' />
                              ) : (
                                <></>
                              )}
                            </Tooltip>
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Box>
        <Divider className={commonClasses.colorSeparators} />
        <Box>
          <Box mt={2}>
            <span className={commonClasses.textMedium}>Full Graph View</span>
          </Box>
          <Box justifyContent='center' mt={2}>
            {renderLabValuesGraph(values, range, 100, 450, true)}
          </Box>
        </Box>
      </Box>
    </Popover>
  );
};
