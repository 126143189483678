import { HealthSummaryDraftModel, HealthSummaryMapModel } from '../../models/healthSummary.models'
import { healthSummaryService } from '../../services/healthSummary.service'
import { buildKey } from '../../helpers/healthSummaryHelper'
import { healthSummaryStore } from '../../stores'
import { useState } from 'react'

export interface HealthSummaryBlockItemsComponentState {
    draft: HealthSummaryDraftModel[]
    isLoading: boolean
    editMode: boolean
}

export function useFacade(map: HealthSummaryMapModel, patientId: number): [
    Function,
    Function,
    Function,
    Function,
    Function,
    Function,
    HealthSummaryBlockItemsComponentState
] {
    const [state, setState] = useState({
        isLoading: false,
        editMode: false,
        draft: []
    } as HealthSummaryBlockItemsComponentState)

    const handleEditMode = (editMode: boolean) => {
        setState({
            ...state,
            editMode: editMode
        })
    }

    const handleEditNewItem = (value: string) => {
        const draft = state.draft
        const item = state.draft.find(x => x.isNew)

        draft[draft.indexOf(draft.find(x=> x.key === item.key))].value = value

        setState({
            ...state,
            draft: draft
        })
    }

    const handleAddInformation = (subSection: string) => {
        const newDataItem = {
            name: '',
            value: '',
            key: buildKey(map.key, subSection),
            section: subSection,
            isNew: true,
            patientId: patientId
        } as HealthSummaryDraftModel

        setState({
            ...state,
            draft: [...state.draft, newDataItem]
        })
    }

    const handleSaveNewItem = () => {
        const draft = state.draft
        const item = state.draft.find(x => x.isNew)

        if (item.value) {
            healthSummaryStore.add(item)
        }

        setState({
            ...state,
            draft: draft.filter(x=> x.key !== item.key)
        })
    }

    const saveChanges = () => {     
        setState(state => ({...state, isLoading: true}))

        const data = healthSummaryStore.getValue().data
        const originData = healthSummaryStore.getValue().originData

        let editedValues = []
        data.forEach(dataItem => {
            const orign = originData.find(x => x.key === dataItem.key)

            if (orign && orign.value !== dataItem.value) {
                editedValues.push(dataItem)
            }
        })

        editedValues = editedValues.concat(data.filter(x=> (x.isNew || x.isDeleted) && !editedValues.find(el => el.id === x.id)))

        healthSummaryService.batchUpdate(editedValues).subscribe(() => {
            healthSummaryStore.updateDataAfterSave()
            setState(state => ({
                ...state, 
                isLoading: false,
                editMode: false
            }))
        })
    }

    const cancelChanges = () => {
        setState({
            ...state,
            editMode: false
        })

        healthSummaryStore.refreshData()
    }

    return [
        handleAddInformation,
        handleEditNewItem,
        handleSaveNewItem,
        handleEditMode,
        cancelChanges,
        saveChanges,
        state
    ]
}