import {
    Box,
    FormControl,
    FormHelperText,
    Grid,
    MenuItem,
    Select,
    TextField,
    IconButton,
    InputAdornment,
    OutlinedInput,
} from '@material-ui/core';
import moment from "moment";
import React from 'react';
import { phoneNumberInput } from '../../../common/components/PhoneNumberInput';
import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Gender, User } from '../../../common/models/user.models';
import { IErrorState } from "../../../common/validation/error-state";
import commonUseStyles from '../../../common/styles/common.styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useStyles } from "./patientInfoComponent.styles";

interface PatientInfoComponentProps extends IErrorState {
    payer: User;
    handleChanges: (field: string, value: any) => void;
    displayLoginInfo?: boolean,
    readonlyFields: string[],
    hiddenFields: string[]
}

export const PatientInfoComponent: React.FC<PatientInfoComponentProps> = (props: PatientInfoComponentProps) => {
    const {
        payer,
        handleChanges,
        errors,
        hiddenFields,
        readonlyFields,
        displayLoginInfo = true
    } = props;

    const [state, setState] = React.useState({
        showPassword: false,
    });
    const commonClasses = commonUseStyles();
    const classes = useStyles();

    const getBirthday = () => {
        return payer.birthday
            ? moment(payer.birthday).format('YYYY-MM-DD')
            : null
    }

    const handleClickShowPassword = () => {
        setState({ ...state, showPassword: !state.showPassword });
    };

    return (
        <form autoComplete="off">
            {
                displayLoginInfo &&
                <>
                    <Box mt={2} width={1} hidden={hiddenFields.includes('email')}>
                        <Box className={commonClasses.subtitle}>Email</Box>
                        <TextField
                            fullWidth
                            size="small"
                            required
                            id="email"
                            disabled={readonlyFields.includes('email')}
                            value={payer.email}
                            InputProps={{ className: 'input', classes }}
                            variant="outlined"
                            helperText={errors['email']}
                            error={!!errors['email']}
                            onChange={(v) => handleChanges(v.target.id, v.target.value)}
                        />
                    </Box>
                    <Box mt={2} width={1} hidden={hiddenFields.includes('password')}>
                        <FormControl
                            variant="outlined"
                            color='primary'
                            required
                            error={!!errors[`password`]}
                            size="small"
                            fullWidth
                            classes={{ root: classes.root }}
                        >
                            <Box className={commonClasses.subtitle}>Password</Box>
                            <OutlinedInput
                                id="password"
                                type={state.showPassword ? 'text' : 'password'}
                                value={payer.password}
                                error={!!errors['password']}
                                disabled={readonlyFields.includes('password')}
                                onChange={(v) => handleChanges(v.target.id, v.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            disabled={readonlyFields.includes('password')}
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                        >
                                            {state.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText>{errors[`password`]}</FormHelperText>
                        </FormControl>
                    </Box>
                </>
            }
            <Box mt={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6} hidden={hiddenFields.includes('firstName')}>
                        <Box className={commonClasses.subtitle}>First Name</Box>
                        <TextField
                            fullWidth
                            size="small"
                            required
                            InputProps={{ className: 'input', classes }}
                            id="firstName"
                            value={payer.firstName}
                            variant="outlined"
                            disabled={readonlyFields.includes('firstName')}
                            helperText={errors['firstName']}
                            error={!!errors['firstName']}
                            onChange={(v) => handleChanges(v.target.id, v.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} hidden={hiddenFields.includes('lastName')}>
                        <Box className={commonClasses.subtitle}>Last Name</Box>
                        <TextField
                            fullWidth
                            size="small"
                            required
                            InputProps={{ className: 'input', classes }}
                            id="lastName"
                            value={payer.lastName}
                            variant="outlined"
                            disabled={readonlyFields.includes('lastName')}
                            helperText={errors['lastName']}
                            error={!!errors['lastName']}
                            onChange={(v) => handleChanges(v.target.id, v.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} hidden={hiddenFields.includes('birthday')}>
                        <Box className={commonClasses.subtitle}>Date of Birth</Box>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                id={`birthday`}
                                disableFuture
                                fullWidth
                                size="small"
                                required
                                placeholder="mm/dd/yyyy"
                                format="MM/DD/YYYY"
                                openTo="year"
                                value={getBirthday()}
                                helperText={null}
                                error={!!errors[`birthday`]}
                                inputVariant="outlined"
                                disabled={readonlyFields.includes('birthday')}
                                views={["year", "month", "date"]}
                                onClick={(e) => e.stopPropagation()}
                                onChange={(momentDate) => handleChanges(`birthday`, momentDate === null ?
                                    "" : momentDate.format("YYYY-MM-DD"))}
                                KeyboardButtonProps={{
                                    "aria-label": "change date"
                                }}
                            />
                            <FormHelperText error>{errors[`birthday`]}</FormHelperText>
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} hidden={hiddenFields.includes('gender')}>
                        <FormControl
                            variant="outlined"
                            color='primary'
                            required
                            error={!!errors[`gender`]}
                            size="small"
                            disabled={readonlyFields.includes('gender')}
                            classes={{ root: classes.root }}
                            fullWidth>
                            <Box className={commonClasses.subtitle}>Biological Gender</Box>
                            <Select
                                style={{ background: '#FFF' }}
                                id="gender"
                                classes={{ root: classes.root }}
                                value={payer.gender === Gender.None ? '' : payer.gender}
                                error={!!errors[`gender`]}
                                inputProps={{
                                    name: 'Biological Gender',
                                    id: 'gender-label',
                                    classes,
                                }}
                                onChange={(v) => handleChanges("gender", v.target.value.toString())}
                            >
                                <MenuItem value={Gender.Male}>{Gender[Gender.Male]}</MenuItem>
                                <MenuItem value={Gender.Female}>{Gender[Gender.Female]}</MenuItem>
                            </Select>
                            <FormHelperText>{errors[`gender`]}</FormHelperText>
                        </FormControl>
                    </Grid>

                </Grid>
            </Box>
            <Box width={1} mt={2} hidden={hiddenFields.includes('phoneNumber')}>
                <Box className={commonClasses.subtitle}>Phone number</Box>
                <TextField
                    fullWidth
                    size="small"
                    required
                    id="phoneNumber"
                    variant="outlined"
                    disabled={readonlyFields.includes('phoneNumber')}
                    helperText={errors['phoneNumber']}
                    error={!!errors['phoneNumber']}
                    value={payer.phoneNumber}
                    InputProps={{
                        className: 'input',
                        inputComponent: phoneNumberInput,
                        classes,
                    }}
                    onChange={(v) => handleChanges(v.target.id, v.target.value)}
                />
            </Box>
        </form>
    )
}
