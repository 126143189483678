import { AddressModel, getInitAddress } from "../../common/models/address.models";

export interface BusinessProfileModel {
    id: number;
    name: string;
    address: AddressModel;
    billingAddress: AddressModel;
    phoneNumber: string;
    taxIdNumber: string;
}

export const getDefaultBusinessProfileModel = () => {
    return {
        id: 0,
        name: '',
        address: getInitAddress(),
        billingAddress: getInitAddress(),
        phoneNumber: '',
        taxIdNumber: '',
        paymentToken: ''
    };
}
