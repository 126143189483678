import {useEffect, useState} from "react";
import {onEmit} from "../../../common/helpers/on-emit";
import {PatientModel} from "../../../patients/models/patient.model";
import {patientsQuery} from "../../../patients/stores/patientsStore";
import {
    HealthSummaryNoteKeySectionNames,
    IcdCode,
    NotesType
} from "../../../notes/models/notes.models";
import {icdCodesService} from "../../../notes/services/icdCodes.service";
import {Observable, Subscription} from "rxjs";
import {SignOffModel, notesService} from "../../../notes/services/notes.service";
import {AddOnModel} from "../../../addons/models/addOns.models";
import {addOnsQuery} from "../../../addons/stores";
import {authQuery} from "../../../auth/stores/auth";
import {CoverageModel} from "../../../insurance/models/insurance.models";
import {insuranceQuery} from "../../../insurance/stores/insurance.query";
import {insuranceService} from "../../../insurance/services/insurance.service";
import {CoverageStatus} from "../../../insurance/enums/coverageStatus";
import {EmployeeType} from "../../../employee/models/employee.enums";
import {otherOrdersService} from "../../services/otherOrders.service";
import {CreateOtherOrderItemModel, OtherOrderModel} from "../../models/otherOrders.models";
import {EmployeeShortModel} from "../../../employee/models/employee.models";
import {employeeService} from "../../../employee/services/employees.service";
import {isProviderRole} from "../../../common/constants/roles";
import {snackService} from "../../../common/snack/state";
import {OrderType} from "../../models/orders.models";
import {addOnsService} from "../../../addons/services/addOns.service";
import {Gender} from "../../../common/models/user.models";
import {confirmService} from "../../../../services/confirm.service";
import {HealthSummaryMapModel} from "../../../healthSummary/models/healthSummary.models";
import {healthSummaryService} from "../../../healthSummary/services/healthSummary.service";
import {getKey} from "../../../healthSummary/helpers/healthSummaryHelper";
import {healthSummaryQuery} from "../../../healthSummary/stores";
import {getOrderItemDescription} from "../../helpers/get-order-item-description";

interface CreateOtherOrderContent {
    patientName: string;
    dateOfBirth: Date | string;
    patientAddress: string;
    orderingProvider: string;
    insuranceProvider: string;
    insuranceId: string;
    urgencyTimeToAppointment: string;
    diagnosis: IcdCode[];
    primaryDiagnosesId: number | null;
    reasonForOrder: string;
    preferredLocation: string;
}

interface TestOrderGroupModel {
    name: string;
    isOpen: boolean;
    items: AddOnModel[];
}

interface OrdersComponentState {
    isLoading: boolean;
    isLoadingIcd: boolean;
    isProcessing: boolean;
    orderId: number;
    employeeId: number;
    content: CreateOtherOrderContent;
    searchQuery: string;
    diagnosis: IcdCode[];
    testOrderGroup: TestOrderGroupModel[];
    addOns: AddOnModel[];
    item: CreateOtherOrderItemModel;
    otherEmployees: EmployeeShortModel[];
}

const defaultInitialContent: CreateOtherOrderContent =
    {
        patientName: '',
        dateOfBirth: '',
        insuranceProvider: '',
        insuranceId: '',
        patientAddress: '',
        orderingProvider: '',
        urgencyTimeToAppointment: '',
        diagnosis: [],
        primaryDiagnosesId: null,
        preferredLocation: '',
        reasonForOrder: ''
    }

export function useFacade(patientId: number, targetOrder: OtherOrderModel | null, handleGoBack: Function): [
    OrdersComponentState,
    () => void,
    () => void,
    () => void,
    () => void,
    (value) => void,
    (value: number | null) => void,
    (value: string, id: number) => void,
    (employee: EmployeeShortModel) => void,
    (value: string) => void,
    (value: string) => void,
    (value: string) => void,
    (value: string) => void,
    (value: string) => void,
    () => boolean,
    () => boolean,
    (name: string, val: boolean) => void,
    (searchQuery: string) => Observable<IcdCode[]>
] {
    const [state, setState] = useState({
        isLoading: true,
        isLoadingIcd: false,
        isProcessing: false,
        employeeId: authQuery.getEmployeeId(),
        searchQuery: '',
        diagnosis: [],
        content: defaultInitialContent,
        testOrderGroup: [],
        addOns: [],
        item: null,
        orderId: targetOrder?.id,
        otherEmployees: [],
    } as OrdersComponentState);

    const canConfirm = (): boolean => {
        const selectedAddOn = state.addOns.find(x => x.id === state.item?.addOnId);
        return state.item && !selectedAddOn?.children?.length
            && (state.item.description || !selectedAddOn?.needSpecification)
            && state.content.insuranceId
            && state.content.diagnosis
            && state.content.diagnosis.find(x => x.id === state.content.primaryDiagnosesId)
            && state.content.orderingProvider
            && state.content.insuranceProvider
            && state.content.urgencyTimeToAppointment
            && !state.isProcessing
            && authQuery.getEmployeeType() === EmployeeType.Provider
    }

    const canSendForApproval = (): boolean => {
        const selectedAddOn = state.addOns.find(x => x.id === state.item?.addOnId);
        return state.item && !selectedAddOn?.children?.length
            && (state.item.description || !selectedAddOn?.needSpecification)
            && state.content.insuranceId
            && state.content.orderingProvider
            && state.content.diagnosis
            && state.content.diagnosis.find(x => x.id === state.content.primaryDiagnosesId)
            && state.content.insuranceProvider
            && state.content.urgencyTimeToAppointment
            && !state.isProcessing
    }

    const handleOpenGroup = (name: string, val: boolean) => {
        state.testOrderGroup.find(group => group.name === name).isOpen = val
        setState(state => ({...state}))
    }

    const handleSendForApproval = () => {

        notesService.openSignOff({
            patientId: patientId,
            noteType: NotesType.HistoryAndPhysicalFollowUp
        } as SignOffModel).subscribe(([employeeId, _]) => {
            setState(state => ({...state, isProcessing: true}))
            const cb = () => setState(state => ({...state, isProcessing: false}));

            const modelUpdate = {
                id: state.orderId,
                employeeId: employeeId,
                items: [state.item],
                data: {
                    ...state.content,
                    diagnosisCode: JSON.stringify(state.content.diagnosis),
                    primaryDiagnosesId: state.content.primaryDiagnosesId?.toString() ?? ''
                },
                sendForReview: true,
                isCompleted: false,
            }
            const modelCreate = {
                patientId: patientId,
                employeeId: employeeId,
                items: [state.item],
                data: {
                    ...state.content,
                    diagnosisCode: JSON.stringify(state.content.diagnosis),
                    primaryDiagnosesId: state.content.primaryDiagnosesId?.toString() ?? ''
                },
                sendForReview: true,
                isCompleted: false,
            }

            !state.orderId
                ? otherOrdersService.create(modelCreate).subscribe(
                    () => {
                        cb();
                        snackService.success('New order is sent for approval!')
                        handleGoBack();
                    },
                    cb)
                : otherOrdersService.update(modelUpdate).subscribe(
                    () => {
                        cb();
                        handleGoBack();
                    },
                    cb)
        })
    }

    const handleSaveAsDraft = () => {
        setState(state => ({...state, isProcessing: true}))
        const cb = () => setState(state => ({...state, isProcessing: false}));
        const modelCreate = {
            patientId: patientId,
            employeeId: state.employeeId,
            items: state.item ? [state.item] : [],
            data: {
                ...state.content,
                diagnosisCode: JSON.stringify(state.content.diagnosis),
                primaryDiagnosesId: state.content.primaryDiagnosesId?.toString() ?? ''
            },
            sendForReview: false,
            isCompleted: false,
        }

        const modelUpdate = {
            id: state.orderId,
            employeeId: state.employeeId,
            items: state.item ? [state.item] : [],
            data: {
                ...state.content,
                diagnosisCode: JSON.stringify(state.content.diagnosis),
                primaryDiagnosesId: state.content.primaryDiagnosesId?.toString() ?? ''
            },
            sendForReview: false,
            isCompleted: false,
        }

        !state.orderId
            ? otherOrdersService.create(modelCreate).subscribe(
                () => {
                    cb();
                    handleGoBack();
                },
                cb)
            : otherOrdersService.update(modelUpdate).subscribe(
                () => {
                    cb();
                    handleGoBack();
                },
                cb)
    }

    const handleDiscard = () => {
        setState(state => ({...state, isProcessing: true}));

        const cb = () => {
            setState(state => ({...state, isProcessing: false}));
            handleGoBack();
        }

        confirmService.confirm(
            'Discard Order',
            'Are you sure you want to quit without saving the order?',
            'Yes',
            'No',
            'danger')
            .subscribe(
                () => {
                    if (state.orderId) {
                        setState(state => ({...state, isProcessing: true}))
                        otherOrdersService.delete(state.orderId).subscribe(cb, cb);
                    } else {
                        handleGoBack();
                    }
                },
            );
    }

    const handleSaveAndComplete = () => {
        setState(state => ({...state, isProcessing: true}))
        const cb = () => setState(state => ({...state, isProcessing: false}));
        const modelCreate = {
            patientId: patientId,
            employeeId: state.employeeId,
            items: state.item ? [state.item] : [],
            data: {
                ...state.content,
                diagnosisCode: JSON.stringify(state.content.diagnosis),
                primaryDiagnosesId: state.content.primaryDiagnosesId?.toString() ?? ''
            },
            sendForReview: false,
            isCompleted: true,
        }

        const modelUpdate = {
            id: state.orderId,
            employeeId: state.employeeId,
            items: state.item ? [state.item] : [],
            data: {
                ...state.content,
                diagnosisCode: JSON.stringify(state.content.diagnosis),
                primaryDiagnosesId: state.content.primaryDiagnosesId?.toString() ?? ''
            },
            sendForReview: false,
            isCompleted: true,
        };

        !state.orderId
            ? otherOrdersService.create(modelCreate).subscribe(
                () => {
                    cb();
                    handleGoBack();
                },
                cb)
            : otherOrdersService.update(modelUpdate).subscribe(
                () => {
                    cb();
                    handleGoBack();
                },
                cb)
    }

    const handleDiagnosisChange = (value) => {
        if (value) {
            setState(state => ({
                ...state,
                content: {
                    ...state.content,
                    diagnosis: value
                }
            }));
        }
    }

    const handlePrimaryDiagnosisChange = (primaryDiagnosisId: number | null) => {
        setState(state => ({
            ...state,
            content: {
                ...state.content,
                primaryDiagnosesId: state.content.primaryDiagnosesId === primaryDiagnosisId ? null : primaryDiagnosisId
            }
        }));
    }

    const handleAdditionalInformationChanges = (value: string, id: number) => {
        const diagnoses = state.content.diagnosis.find(el => el.id === id)
        diagnoses.additionalInformation = value;
        setState(state => ({
            ...state,
            content: {
                ...state.content,
                diagnosis: state.content.diagnosis.map(x => x.id === id ? diagnoses : x).slice()
            }
        }));
    }

    const handleAddOnSelect = (value: string) => {
        setState(state => ({
            ...state,
            item: {
                addOnId: Number(value),
                description: '',
            }
        }));
    }

    const handleAddonDescriptionChanges = (value: string) => {
        setState(state => ({
            ...state,
            item: {
                ...state.item,
                description: value,
            }
        }));
    }

    const handleEmployeeSelect = (employee: EmployeeShortModel) => {
        setState(state => ({
            ...state,
            content: {
                ...state.content,
                orderingProvider: `${employee.firstName} ${employee.lastName}`
            }
        }));
    }

    const handleUrgencyChanges = (value: string) => {
        setState(state => ({
            ...state,
            content: {
                ...state.content,
                urgencyTimeToAppointment: value
            }
        }));
    }

    const handlePreferenceChanges = (value: string) => {
        setState(state => ({
            ...state,
            content: {
                ...state.content,
                preferredLocation: value
            }
        }));
    }

    const handleOrderReasonChanges = (value: string) => {
        setState(state => ({
            ...state,
            content: {
                ...state.content,
                reasonForOrder: value
            }
        }));
    }

    const handleAutoComplete = (searchQuery: string): Observable<IcdCode[]> => {
        return new Observable<IcdCode[]>((observer) => {
            icdCodesService.get(searchQuery).subscribe(
                (data) => {
                    observer.next(data);
                    observer.complete();
                },
                () => {
                    observer.next([]);
                    observer.complete();
                }
            )
        })
    }

    const createGroup = (group: string[], addOns: AddOnModel[]): TestOrderGroupModel[] => {
        return group.map(el => {
            return {
                name: el,
                isOpen: false,
                items: addOns.filter(addOn => addOn.description === el)
            }
        })
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (handleAutoComplete && state.searchQuery) {
                setState(state => ({
                    ...state,
                    isLoadingIcd: true
                }));
                handleAutoComplete(state.searchQuery).subscribe(
                    (diagnosis) => {
                        setState(state => ({
                            ...state,
                            diagnosis: diagnosis,
                            isLoadingIcd: false
                        }));
                    },
                    () => {
                        setState(state => ({
                            ...state,
                            diagnosis: [],
                            isLoadingIcd: false
                        }));
                    })
            }
        }, 1500);

        return () => clearTimeout(delayDebounceFn)
    }, [state.searchQuery]);

    const getDiagnosis = (map: HealthSummaryMapModel[]) => {
        healthSummaryService.getData(patientId).subscribe(data => {
            if (data && data.length) {
                const section = map.find(el => el.key === HealthSummaryNoteKeySectionNames.PROBLEMS_LIST);

                if (!section) {
                    return;
                }

                const keys = [...section.items.map(x => x.key), section.key]
                const values = data.filter(x => keys.includes(getKey(x.key)));

                const diagnosis = values.map(x => {
                    return {
                        id: +x.key.match(/\d+/g).join(''),
                        code: x.value,
                        description: x.name,
                        additionalInformation: x.tooltip
                    } as IcdCode
                }).filter(x => x.code?.length > 0);

                setState((state) => ({
                    ...state,
                    content: {
                        ...state.content,
                        diagnosis: diagnosis
                    }
                }))
            }
        })
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<CoverageModel[]>(insuranceQuery.coverages$, coverages => {
                if (coverages !== null && coverages.length) {
                    const coverage = coverages
                        .filter(x => x.status === CoverageStatus.Active)
                        .sort((a, b) => a.priority - b.priority)
                        .find(x => x.status === CoverageStatus.Active);

                    if (coverage) {
                        setState(state => ({
                            ...state,
                            content: {
                                ...state.content,
                                insuranceId: coverage?.memberId,
                                insuranceProvider: coverage?.insurance?.name
                            }
                        }));
                    } else {
                        setState(state => ({
                            ...state,
                            content: {
                                ...state.content,
                                insuranceId: 'Cash only',
                                insuranceProvider: 'Cash only',
                            }
                        }));
                    }
                } else {
                    setState(state => ({
                        ...state,
                        content: {
                            ...state.content,
                            insuranceId: 'Cash only',
                            insuranceProvider: 'Cash only',
                        }
                    }));
                }
            }),
            onEmit<PatientModel>(patientsQuery.targetPatient$, patient => {
                if (patient && patient.id === patientId) {
                    const provider = patient.assignedEmployees.find(x => isProviderRole(x.roleId));
                    setState(state => ({
                        ...state,
                        content: {
                            ...state.content,
                            orderingProvider: provider ? `${provider.firstName} ${provider.lastName}` : '-',
                            patientName: `${patient.firstName} ${patient.lastName}`,
                            dateOfBirth: patient.birthday,
                            patientAddress: `${patient.shippingAddress.streetAddress1}, ${patient.shippingAddress.city}, ${patient.shippingAddress.state},  ${patient.shippingAddress.zipCode}`
                        }
                    }));
                }
            }),
            onEmit<HealthSummaryMapModel[]>(healthSummaryQuery.map$, map => {
                if (targetOrder == null && map && map.length) {
                    getDiagnosis(map)
                }
            })
        ];

        if (targetOrder !== null) {
            setState(state => ({
                ...state,
                orderId: targetOrder.id,
                item: !!targetOrder.items.length
                    ? {
                        addOnId: targetOrder.items[0].addOnId,
                        description: getOrderItemDescription(targetOrder.items[0])
                    }
                    : null
                ,
                employeeId: targetOrder.signedBy?.id
                    ? targetOrder.signedBy.id
                    : authQuery.getEmployeeId(),
                content: {
                    patientName: targetOrder.data.patientName,
                    dateOfBirth: targetOrder.data.dateOfBirth,
                    insuranceProvider: targetOrder.data.insuranceProvider,
                    insuranceId: targetOrder.data.insuranceId,
                    urgencyTimeToAppointment: targetOrder.data.urgencyTimeToAppointment,
                    diagnosis: JSON.parse(targetOrder.data.diagnosisCode),
                    primaryDiagnosesId: +targetOrder.data.primaryDiagnosesId,
                    reasonForOrder: targetOrder.data.reasonForOrder,
                    preferredLocation: targetOrder.data.preferredLocation,
                    patientAddress: targetOrder.data.patientAddress,
                    orderingProvider: targetOrder.data.orderingProvider,
                }
            }));
        } else {
            insuranceService.getCoveragesByEmploy(patientId).subscribe()
            healthSummaryService.getMap();
        }

        employeeService.getActiveCoachesAndProviders().subscribe(employees => {
            setState(state => ({
                ...state,
                otherEmployees: employees.filter(x => isProviderRole(x.roleId))
            }))
        });

        addOnsService.getOrdering(authQuery.getPracticeId(), Gender.None, true).subscribe(() => {
            const addOnsOtherOrder = addOnsQuery.getByType(OrderType.Other);
            const addOnsGroupNames = [...new Set(addOnsOtherOrder.map(item => item.description))]
            setState(state => ({
                ...state,
                isLoading: false,
                addOns: [...addOnsOtherOrder, ...addOnsOtherOrder.map(x => x.children).flat()],
                testOrderGroup: createGroup(addOnsGroupNames, addOnsOtherOrder),
            }))
        });

        return () => {
            subscriptions.map(it => it.unsubscribe());
        };
    }, [patientId]);

    return [
        state,
        handleSendForApproval,
        handleSaveAsDraft,
        handleDiscard,
        handleSaveAndComplete,
        handleDiagnosisChange,
        handlePrimaryDiagnosisChange,
        handleAdditionalInformationChanges,
        handleEmployeeSelect,
        handleUrgencyChanges,
        handlePreferenceChanges,
        handleOrderReasonChanges,
        handleAddOnSelect,
        handleAddonDescriptionChanges,
        canConfirm,
        canSendForApproval,
        handleOpenGroup,
        handleAutoComplete
    ];
}