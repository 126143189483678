import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
  },
  avatar: {
    minWidth: 40,
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
      maxWidth: 24,
      height: 24,
      fontSize: 16,
    },
  },
})
);
