import React from 'react';
import { ReactComponent as SupplementMeds } from '@img/icons/SupplementMeds.svg';
import { IconProps } from './IconProps';
import { colors } from '../../modules/common/constants/colors';

const SupplementMedsIcon: React.FC<IconProps> = (props: IconProps) => {
    const { isSelected } = props;

    return (
        <SupplementMeds fill={isSelected ? colors.main : colors.gray2} />
    )
}

export default SupplementMedsIcon;