import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogTitle: {
            margin: 0,
            padding: theme.spacing(2),
        },
        field: {
            width: 250,
            margin: 5,
        },
    }),
);
