import {useEffect, useState} from "react";
import {Subscription} from "recompose";
import {onEmit} from "../../../common/helpers/on-emit";
import {ConversationModelBaseView, PatientConversationModel} from "../../models/conversation.models";
import {conversationsViewService} from "../../services/conversationsView.service";
import {patientConversationsQuery} from "../../stores/patientConversationsStore/patientConversations.query";
import { employeeConversationsStore } from "../../stores/employeeConversationsStore/employeeConversations.store";
import {conversationsService} from "../../services/conversations.service";
import { profileService } from "../../../account/services/profile.service";

interface PatientConversationsComponentState {
    isSupportLoading: boolean;
    isHealthCareLoading: boolean;
    isSupportPhotosLoading: boolean;
    isHCPhotosLoading: boolean;
    isFullStaffChatOpen: boolean,
    isFullHealthCareChatOpen: boolean,
    supportConversations: PatientConversationModel[];
    healthCareConversation: PatientConversationModel;
    messagesHealthcare: ConversationModelBaseView[];
    targetConversation: PatientConversationModel;
}

export enum StaffOrHealthCoachMessagesEnum {
    STAFF,
    HEALTH_COACH
}

export function useFacade(patientId: number): [
    PatientConversationsComponentState,
    () => void,
    () => void,
    () => void,
    () => void,
    (StaffOrHealthCoachMessagesEnum) => void
] {

    const [state, setState] = useState({
        isSupportLoading: true,
        isFullStaffChatOpen: false,
        isFullHealthCareChatOpen: false,
        isHealthCareLoading: true,
        isSupportPhotosLoading: true,
        isHCPhotosLoading: true,
        supportConversations: [],
        healthCareConversation: null,
        messagesHealthcare: [],
        targetConversation: null,
    } as PatientConversationsComponentState);

    const handleOpenStaffChat = () => {
        employeeConversationsStore.resetAttachmentsView()
        setState(state => ({...state, isFullStaffChatOpen: true}))
    }

    const handleOpenHealthCareChat = () => {
        employeeConversationsStore.resetAttachmentsView()
        setState(state => ({...state, isFullHealthCareChatOpen: true}))
    }

    const handleCloseStaffChat = () => {
        setState(state => ({...state, isFullStaffChatOpen: false}))
    }

    const handleCloseHealthCareChat = () => {
        setState(state => ({...state, isFullHealthCareChatOpen: false}))
    }

    const handleDownloadMessages = (staffOrHealth: StaffOrHealthCoachMessagesEnum) => {
        if (staffOrHealth === StaffOrHealthCoachMessagesEnum.HEALTH_COACH) {
            conversationsService.downloadAllMessages(state.healthCareConversation,state.messagesHealthcare,
                StaffOrHealthCoachMessagesEnum.HEALTH_COACH);
        }

    }

    useEffect(() => {
        const supportPhotosCB = () => setState(state => ({...state, isSupportPhotosLoading: false}))
        const hcPhotosCB = () => setState(state => ({...state, isHCPhotosLoading: false}))
        const subscriptions: Subscription[] = [
            onEmit<PatientConversationModel[]>(patientConversationsQuery.supportConversations$, conversations => {
                setState(state => ({...state, supportConversations: conversations}))
                if (conversations && conversations.length) {
                    setState(state => ({...state, isSupportPhotosLoading: true}))
                    const employeeIds = conversations.map(r => r.employees).reduce((e1: number[], e2) => {
                        for (const item of e2)
                        {
                            if(!e1.includes(item.employeeId)) {
                                e1.push(item.employeeId)
                            }
                        }
                        return e1;
                    }, [])
                    if (employeeIds.length) {
                        profileService.getEmployeeProfilePhotos(employeeIds.join(',')).subscribe(supportPhotosCB, supportPhotosCB)
                    } else {
                        supportPhotosCB()
                    }
                } else {
                    supportPhotosCB()
                }
            }),
            onEmit<PatientConversationModel>(patientConversationsQuery.heathCareConversation$, conversation => {
                setState(state => ({...state, healthCareConversation: conversation}))
                if (conversation && conversation.employees.length) {
                    setState(state => ({...state, isHCPhotosLoading: true}))
                    const employeeIds = conversation.employees.map(e => e.employeeId).join(',')
                    profileService.getEmployeeProfilePhotos(employeeIds).subscribe(hcPhotosCB, hcPhotosCB)
                } else {
                    hcPhotosCB()
                }
            }),
            onEmit<ConversationModelBaseView[]>(patientConversationsQuery.targetHealthcareConversation$, (messages:ConversationModelBaseView[]) => {
                    setState(state => ({...state, messagesHealthcare: messages}))
                }
            ),
            onEmit<PatientConversationModel>(patientConversationsQuery.targetConversation$, conversation => {
                if (conversation) {
                    setState(state => ({...state, targetConversation: conversation}))
                }
            })
        ];

        if(patientId){
            conversationsViewService.getPatientHealthCareConversation(patientId).subscribe(
                (res) => {
                    if (res && res.vendorExternalId) {
                        conversationsViewService.getPatientAllMessagesHealthCare(res.vendorExternalId).subscribe(
                            () => {
                            },
                            () => {
                                setState(state => ({...state, isHealthCareLoading: false}))
                            },
                            () => {
                                setState(state => ({...state, isHealthCareLoading: false}))
                            }
                        )
                    } else {
                        setState(state => ({...state, isHealthCareLoading: false}))
                    }
                },
                () => {
                    setState(state => ({...state, isHealthCareLoading: false}))
                });
            conversationsViewService.getPatientStaffConversations(patientId).subscribe(() => {
                setState(state => ({...state, isSupportLoading: false}))
            });
        }

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [patientId]);


    return [
        state,
        handleOpenStaffChat,
        handleOpenHealthCareChat,
        handleCloseStaffChat,
        handleCloseHealthCareChat,
        handleDownloadMessages
    ];
}
