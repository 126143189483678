import React from 'react';
import {QuestionnaireSurveyProps} from "./questionnaireSurvey.props";
import {InitialQuestionNames} from "../../../models/questionnaire.models";
import { QuestionnairesListComponent } from '../../../components/questionnairesListComponent/QuestionnairesListComponent';
import { Box } from '@material-ui/core';
import { PageSliderComponent } from '../../../../common/components/PageSliderComponent';

export const SleepQuestionnairePageFields = [
    InitialQuestionNames.SLEEP_QUALITY,
    InitialQuestionNames.ROUTINE_BEFORE_SLEEP,
    InitialQuestionNames.START_SLEEP,
    InitialQuestionNames.STOP_SLEEP,
    InitialQuestionNames.ALARM_CLOCK,
    InitialQuestionNames.RESTED_AFTER_SLEEP,
    InitialQuestionNames.ENERGY_AFTER_SLEEP
];

export const SleepSurvey: React.FC<QuestionnaireSurveyProps> = (props: QuestionnaireSurveyProps) => {
    const {
        errors,
        result,
        questionnaire,
        handleChanges,
        navigationContext
    } = props;

    return (
        <>
            <QuestionnairesListComponent
                questionnaire={questionnaire}
                errors={errors}
                handleChanges={handleChanges}
                navigationContext={navigationContext}
                questions={SleepQuestionnairePageFields}
                result={result}
                title={"SLEEP:"}
            />
            <Box mt={5}>
                <PageSliderComponent context={navigationContext}/>
            </Box>
        </>
    )
}
