import { Box, Tooltip, useTheme } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { renderEmptyValue } from "../../../common/helpers/empty-value";
import { renderBiometricValuesGraph } from "../../../inputs/helpers/render-biometric-values-graph";
import {
  LabInputNotificationType,
} from "../../../inputs/models/input.models";
import { VitalModel, VitalValueModel } from "../../models/vital.model";
import { VitalsHistoryView } from "../../stores";
import { useStyles } from "./biometricRowComponent.styles";
import WarningIcon from "@material-ui/icons/Warning";
import warning from "@img/icons/warning.svg";
import { vitalsNormalValues } from "../../validators/vital.validator";


interface BiometricRowComponentProps {
  avg: number;
  rowWidth: number;
  selectedPage: number;
  index: number;
  vitals: VitalModel[];
  view: VitalsHistoryView;
}

export const BiometricRowComponent: React.FC<BiometricRowComponentProps> = (
  props: BiometricRowComponentProps
) => {
  const { avg, rowWidth, index, vitals, view, selectedPage } =
    props;

  const classes = useStyles();
  if (!vitals.length) {
    return <></>;
  }

  const vital = vitals[0];

  const nameCell = (
    <WildHealthTableCell key={`name-${index}`} className={classes.cellName}>
      <Box display='flex' alignItems='flex-end'>
        <Box width='max-content' >{vital.displayName}</Box>
        <Box className={classes.dimension}>
          <span>
            &nbsp;
            {vital.dimension && `(${vital.dimension === '"' ? "in" : vital.dimension})`}
          </span>
        </Box>
      </Box>
      <Box className={classes.dimension}>Avg: {avg}</Box>
    </WildHealthTableCell>
  );

  const renderNotificationImage = (notificationType: LabInputNotificationType) => {
    switch (notificationType) {
      case LabInputNotificationType.Empty:
        return <></>;
      case LabInputNotificationType.Error:
        return <img src={warning} alt='img' />;
      case LabInputNotificationType.Warning:
        return <WarningIcon className='warning' />;
    }
  };

  const renderWarning = (value: VitalValueModel) => {
    return (
      <Box mt='1.5px' ml={1}>
        <Tooltip
          title={value.notification.message}
          classes={{
            tooltip: classes.notificationToolTipPopper,
            arrow: classes.notificationToolTipArrow,
          }}
          arrow
        >
          {renderNotificationImage(value.notification.notificationType)}
        </Tooltip>
      </Box>
    );
  };

  const renderGraph = () => {
    const numStart = (selectedPage - 1) * rowWidth
    const numEnd = selectedPage * rowWidth
    const valuesView = vital.values.slice(numStart, numEnd)
    return [
      nameCell,
      <WildHealthTableCell key={`trends-${index}`}>
        {
          <Box
            className={classes.graphContainer}
          >
            {valuesView.filter((x) => !!x.value).length < 2
              ? renderEmptyValue("Insufficient data for trending")
              : vitals.map((item) =>
                renderBiometricValuesGraph(
                  item.values.slice(numStart, numEnd),
                  vitalsNormalValues.get(item.name),
                  200,
                  rowWidth < 2 ? 100 : rowWidth * 70
                )
              )}
          </Box>
        }
      </WildHealthTableCell>,
    ];
  };

  const renderViewValues = () => {
    let cells = [nameCell];

    const getWarning = (index: number) => {
      const hasError = vitals.find(
        (i) =>
          i.values[index].notification?.notificationType ===
          LabInputNotificationType.Error
      );
      if (hasError) {
        const value = hasError.values[index];
        if (value && value.notification) {
          return renderWarning(value);
        }
      }

      return <></>;
    };

    const getValue = (value: VitalValueModel) => {
      const outOfNorm =
        value.notification?.notificationType === LabInputNotificationType.Error;

      return <Box key={`${value.id}-${value.name}`} display='flex' alignItems='center'>
        <Box className={clsx({ [classes.danger]: outOfNorm })}>{value.value ? value.value : '-'}</Box>
      </Box>
    };

    const numStart = (selectedPage - 1) * rowWidth

    const numEnd = selectedPage * rowWidth
    const valuesView = vital.values.slice(numStart, numEnd)


    valuesView.forEach((item, index) => {
      const warning = getWarning(index + numStart);
      cells.push(
        <WildHealthTableCell key={`${item.id}-${item.name}`}>
          <Box display='flex' alignItems='center'>
            {vitals
              .map((x) => getValue(x.values[index + numStart]))
              .reduce(
                (a, c) => (!a.length ? [c] : [...a, <span key={c.key}> / </span>, c]),
                []
              )}
            {warning}
          </Box>
        </WildHealthTableCell>
      );
    });

    cells.push(<WildHealthTableCell />);

    return cells;
  };

  const views = new Map<VitalsHistoryView, JSX.Element[]>([
    [VitalsHistoryView.Graph, renderGraph()],
    [VitalsHistoryView.Values, renderViewValues()],
  ]);

  return <WildHealthTableRow key={index}>{views.get(view)}</WildHealthTableRow>;
};
