import React from 'react';
import { useFacade } from "./fellowPatientsComponent.hooks"
import { Box, Table, TableBody, TableContainer, TableHead } from '@material-ui/core';
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { displayEnrollment } from "../../helpers/displayEnrollment";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import moment from "moment";
import { renderEmptyValue } from '../../../common/helpers/empty-value';
import {WildHealhTableSortLabel} from "../../../common/components/wildHealthTableSortLabel/WildHealthTableSortLabel";
import { SortingDirection } from '../../../common/sorting/models/sorting-destination';
import { SortingSource } from "../../models/fellowship.enums";
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from 'clsx';

interface FellowPatientsComponentProps {
    fellowId: number;
}

export const FellowPatientsComponent: React.FC<FellowPatientsComponentProps> = (props: FellowPatientsComponentProps) => {
    const { fellowId } = props;

    const [
        {
            patients,
            fellow,
            isLoading,
            sortingColumns,
            sortingSource
        },
        handleSorting
    ] = useFacade(fellowId);

    const commonClasses = commonUseStyles();

    const enrolledColumn = sortingColumns.find(x => x.source === SortingSource.Enrollment);

    const renderContent = (): JSX.Element => {
        if(isLoading || fellow == null) {
            return (<WildHealthLinearProgress />)
        }

        if(!patients.length) {
            return (<WildHealthPlaceHolder message="No data."/>)
        }

        return (
            <Box>
                <Box>
                    <span className={clsx(commonClasses.size20, commonClasses.textMedium)}>{`Patient List Assigned To: ${fellow.firstName} ${fellow.lastName}`}</span>
                </Box>
                <Box mt={2}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <WildHealthTableRow>
                                    <WildHealthTableCell>Patient Name</WildHealthTableCell>
                                    <WildHealthTableCell>Date of Birth</WildHealthTableCell>
                                    <WildHealthTableCell>Phone Number</WildHealthTableCell>
                                    <WildHealthTableCell>Email</WildHealthTableCell>
                                    <WildHealthTableCell>
                                        <WildHealhTableSortLabel
                                            id={`fellow-patients-${enrolledColumn.title}`}
                                            isActive={sortingSource === SortingSource.Enrollment}
                                            sortingDirection={sortingSource === enrolledColumn.source ? enrolledColumn.direction : SortingDirection.Asc}
                                            onClick={() => handleSorting(enrolledColumn.source, enrolledColumn.direction)}
                                            title={enrolledColumn.title}
                                        />
                                    </WildHealthTableCell>
                                </WildHealthTableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    patients.map((patient, index) =>
                                        <WildHealthTableRow key={index}>
                                            <WildHealthTableCell>{`${patient.firstName} ${patient.lastName}`}</WildHealthTableCell>
                                            <WildHealthTableCell>{moment(patient.birthday).format("MM/DD/yyyy")}</WildHealthTableCell>
                                            <WildHealthTableCell>{patient.phoneNumber && patient.phoneNumber.length ? patient.phoneNumber : renderEmptyValue("empty")}</WildHealthTableCell>
                                            <WildHealthTableCell>{patient.email}</WildHealthTableCell>
                                            <WildHealthTableCell>
                                                {displayEnrollment(patient.isEnrolled)}
                                            </WildHealthTableCell>
                                        </WildHealthTableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        )
    }

    return (
        <Box p="30px">
            {
                renderContent()
            }
        </Box>
    )
}