import img from "@img/HealthReport_Pg13_Image1.png";
import imgGraph from "@img/HealthReport_Pg13_graph.png";
import { Box, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { DnaTableComponent } from "../components/dnaTableComponent/DnaTableComponent";
import { HealthRecommendationComponent } from "../components/healthRecommendationComponent/HealthRecommendationComponent";
import { riskColorMap } from "../constants/healthReport.constants";
import {
    HealthReportMode,
    HealthReportSections,
    HealthReportVersion,
    recommendationRowsCapacity, RecommendationTypesNumbers
} from "../models/healthReport.models";
import { HealthReportBasePage, HealthReportMobileBasePage } from "./HealthReportBasePage";
import { useStyles } from './healthReport.styles';
import { HealthReportPageProps } from "./healthReportPageProps";
import { RoutesConstants } from '../../../constants/route.constants';
import { ImageConstants } from '../../../constants/image.constants';
import { WildHealthButton } from "../../common/components/wildHealthButton/WildHealthButton";
import { RecommendationSingleSelectComponent } from "../components/recommendationSingleSelectComponent/RecommendationSingleSelectComponent";

export const HealthReportSleepPage: React.FC<HealthReportPageProps> = (props: HealthReportPageProps) => {
    const {
        report,
        handleChanges,
        handleSaveChanges,
        isChanged,
        mode,
        pageNumber,
        practice,
        recommendations,
        handleSelected,
        handleChangesNewRecommendation,
        handleAddRecommendation,
        handleSaveChangesNew,
    } = props;
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        props.mode !== HealthReportMode.PrintMode && isSmallScreen ? (
            <HealthReportMobileBasePage section={HealthReportSections.Sleep} pageNumber={pageNumber} practice={practice}>
                <Box px={2}>
                    <Box>
                        <img src={RoutesConstants.cdnBaseLink + ImageConstants.healthReportPg13ImagePath} style={{ width: '100%', height: 'auto' }} alt="img" />
                    </Box>
                    <Box mt="30px">
                        <Box>
                            <span className={classes.reportTitle}>Your Circadian Genetics</span>
                        </Box>
                        <Box mt="10px">
                            <DnaTableComponent
                                dna={report.sleepReport.circadianDna.dna}
                                colorMap={riskColorMap} />
                        </Box>
                        <Box mt="30px">
                            <img src={RoutesConstants.cdnBaseLink + ImageConstants.healthReportPg13GraphPath} style={{ width: '100%', height: 'auto' }} alt="img" />
                        </Box>
                        <Box mt="30px">
                            {parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
                                ? <Box>
                                    {HealthReportMode.EditMode === mode && <Box display="flex" justifyContent="space-between" mb={1}>
                                        <span className="report-title"></span>
                                        <WildHealthButton
                                            id="health-recommendation-new-save"
                                            onClick={() => handleSaveChangesNew()}
                                            disabled={!isChanged}>
                                            <Box >
                                                Save changes
                                            </Box>
                                        </WildHealthButton>
                                    </Box>}
                                    <RecommendationSingleSelectComponent
                                        currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.Sleep)}
                                        recommendation={report.sleepReport.recommendation}
                                        type={RecommendationTypesNumbers.Sleep}
                                        handleSelected={handleSelected}
                                        handleChanges={handleChangesNewRecommendation}
                                        handleAddRecommendation={handleAddRecommendation}
                                        mode={mode}
                                    />
                                </Box>
                                : <HealthRecommendationComponent
                                    mode={mode}
                                    rows={recommendationRowsCapacity[RecommendationTypesNumbers.Sleep]}
                                    recommendation={report.sleepReport.recommendation}
                                    handleContentChanges={!handleChanges ? handleChanges : (value) => {
                                        handleChanges('sleepReport.recommendation.content', value)
                                    }}
                                    handleManualContentChanges={!handleChanges ? handleChanges : (value) => {
                                        handleChanges('sleepReport.recommendation.manualContent', value)
                                    }}
                                    handleSaveChanges={handleSaveChanges}
                                    isChanged={isChanged} />
                            }
                        </Box>
                    </Box>
                </Box>
            </HealthReportMobileBasePage>
        ) : (
            <HealthReportBasePage section={HealthReportSections.Sleep} pageNumber={pageNumber} practice={practice}>
                <Box>
                    <Box>
                        <Box pr={15} pl={15} pt={10} pb={5} className="grey-background">
                            {
                                props.mode === HealthReportMode.PrintMode
                                    ? <img src={img} style={{ width: '100%' }} alt="img" />
                                    : <img src={RoutesConstants.cdnBaseLink + ImageConstants.healthReportPg13ImagePath} style={{ width: '100%' }} alt="img" />
                            }
                        </Box>
                    </Box>
                    <Box pr={15} pl={15} pt={5}>
                        <Box>
                            <span className="report-title">Your Circadian Genetics</span>
                        </Box>
                        <Box>
                            <Grid container>
                                <Grid item xs={4}>
                                    <Box width="40%" height="100%" display="flex" alignItems="center">
                                        <DnaTableComponent
                                            dna={report.sleepReport.circadianDna.dna}
                                            colorMap={riskColorMap} />
                                    </Box>
                                </Grid>
                                <Grid item xs={8}>
                                    <Box pt={3} pb={3}>
                                        {
                                            props.mode === HealthReportMode.PrintMode
                                                ? <img src={imgGraph} style={{ width: '90%' }} alt="img" />
                                                : <img src={RoutesConstants.cdnBaseLink + ImageConstants.healthReportPg13GraphPath} style={{ width: '90%' }} alt="img" />
                                        }
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box mt={5}>
                            {parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
                                ? <Box>
                                    {HealthReportMode.EditMode === mode
                                        ? <Box display="flex" justifyContent="space-between" mb={1}>
                                            <span className="report-title">Your sleep recommendations</span>
                                            <WildHealthButton
                                                id="health-recommendation-new-save"
                                                onClick={() => handleSaveChangesNew()}
                                                disabled={!isChanged}>
                                                <Box >
                                                    Save changes
                                                </Box>
                                            </WildHealthButton>
                                        </Box>
                                        : <Box mb={1}><span className="report-title">Your sleep recommendations</span></Box>}
                                    <RecommendationSingleSelectComponent
                                        currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.Sleep)}
                                        recommendation={report.sleepReport.recommendation}
                                        type={RecommendationTypesNumbers.Sleep}
                                        handleSelected={handleSelected}
                                        handleChanges={handleChangesNewRecommendation}
                                        handleAddRecommendation={handleAddRecommendation}
                                        mode={mode}
                                    />
                                </Box>
                                : <HealthRecommendationComponent
                                    mode={mode}
                                    rows={recommendationRowsCapacity[RecommendationTypesNumbers.Sleep]}
                                    recommendation={report.sleepReport.recommendation}
                                    handleContentChanges={!handleChanges ? handleChanges : (value) => {
                                        handleChanges('sleepReport.recommendation.content', value)
                                    }}
                                    handleManualContentChanges={!handleChanges ? handleChanges : (value) => {
                                        handleChanges('sleepReport.recommendation.manualContent', value)
                                    }}
                                    handleSaveChanges={handleSaveChanges}
                                    isChanged={isChanged} />
                            }
                        </Box>
                    </Box>
                </Box>
            </HealthReportBasePage>
        )
    )
}
