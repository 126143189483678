import React from "react";
import { AuthenticatedLayoutComponent } from "../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent";
import { PagesNamesService } from "../../../../constants/pagesNames.constants";
import { TitlesService } from "../../../../constants/title.constants";
import { MenuItemTypes } from "../../../../models/menu.models";
import { AddOnReportsComponent } from "../../components/addOnReportsComponent/AddOnReportsComponent";

export const PatientAddOnReportsPage: React.FC = () => {
    return (
        <AuthenticatedLayoutComponent
            hideBackground
            title={TitlesService.addOnReportsTitle}
            pageName={PagesNamesService.healthReport}
            selectedMenuItem={MenuItemTypes.AddOnReports}
        >
            <AddOnReportsComponent />
        </AuthenticatedLayoutComponent>
    )
}
