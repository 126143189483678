import React from 'react';
import { Box, useTheme, useMediaQuery } from '@material-ui/core';
import { AuthenticatedLayoutComponent } from "../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent";
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from "../../../../models/menu.models";
import { TabItem } from "../../../common/components/wildHealthTabControl/wildHealthTabControl.hooks";
import { useFacade } from "./patientOrdersPage.hooks";
import { WildHealthTabControl } from "../../../common/components/wildHealthTabControl/WildHealthTabControl";
import { DnaOrdersComponent } from "../../components/dnaOrdersComponent/DnaOrdersComponent";
import { LabComponent } from "../../components/labComponent/LabComponent";
import { EpigeneticComponent } from '../../components/epigeneticComponent/EpigeneticComponent';
import commonUseStyles from "../../../common/styles/common.styles";
import { useStylesThreeWidthTabs, useStyles } from "../../../common/components/wildHealthTabControl/wildHealthTabControl.styles";
import clsx from 'clsx';
import { AppointmentsOrderComponent } from '../../components/appointmentsOrderComponent/AppointmentsOrderComponent';
import { authQuery } from '../../../auth/stores/auth/auth.query';

export const PatientOrdersPage: React.FC = () => {

    const [
        {
            selectedTab,
        },
        handleChangeTab,
    ] = useFacade();

    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const tabsStyle = useStylesThreeWidthTabs();
    const defaultStyle = useStyles();

    const tabs: TabItem[] = [
        {
            title: 'Lab Orders',
            content: <LabComponent />,
            keepMounted: true,
        },
        {
            title: 'DNA Orders',
            content: <DnaOrdersComponent />,
            keepMounted: true,
        },
        {
            title: 'Epigenetic Orders',
            content: <EpigeneticComponent />,
            keepMounted: true,
        }
    ];

    if (!authQuery.isLicensingPractice())
    {
        tabs.push({
            title: 'Appointments',
            content: <AppointmentsOrderComponent />,
            keepMounted: true,
        });
    }

    return (
        <AuthenticatedLayoutComponent
            title={TitlesService.ordersTitle}
            pageName={PagesNamesService.orders}
            selectedMenuItem={MenuItemTypes.Orders}
        >
            <Box className={isSmallScreen && commonClasses.bgMain}>
                {isSmallScreen && (
                    <Box pt="20px" pb="10px" px={2}>
                        <Box className={clsx(commonClasses.size24, commonClasses.textSemiBold, commonClasses.colorBlack)}>Orders</Box>
                    </Box>
                )}
                <WildHealthTabControl
                    items={tabs}
                    initTab={selectedTab}
                    onChangeCB={handleChangeTab}
                    className={isSmallScreen ? tabsStyle : defaultStyle}
                />
            </Box>

        </AuthenticatedLayoutComponent>
    )
};