import { useEffect, useState } from "react";
import { onEmit } from "../../../common/helpers/on-emit";
import { HealthReportRecommendationDefaultModel, RecommendationDisplayModel, RecommendationTypes } from "../../models/healthReport.models";
import { healthReportsQuery } from "../../stores/healthReportsStore/healthReports.query";
import { healthReportsStore } from "../../stores/healthReportsStore/healthReports.store";
import { Subscription } from "rxjs/internal/Subscription";
import moment from "moment";


interface RecommendationsComponentState {
    sections: { [id: number]: boolean };
    sectionsIsChanged: { [id: number]: boolean };
    isChanged: boolean;
    recommendations: RecommendationDisplayModel[],
    isSelectedAll: boolean,
}

export function useFacade(
    patientId: number,
    isPrepereRecommendation: boolean,
): [
        RecommendationsComponentState,
        (section: RecommendationTypes) => void,
        (id: number | string, isSelected: boolean) => void,
        (id: number | string, value: string) => void,
        (type: number) => RecommendationDisplayModel[],
        (type: number, index: number) => void,
        () => void,
    ] {
    const [state, setState] = useState(
        {
            sections: {
                [RecommendationTypes.Macronutrient]: false,
                [RecommendationTypes.Methylation]: false,
                [RecommendationTypes.VitaminsAndMicronutrients]: false,
                [RecommendationTypes.KryptoniteFoods]: false,
                [RecommendationTypes.SuperFoods]: false,
                [RecommendationTypes.ExerciseAndRecovery]: false,
                [RecommendationTypes.Longevity]: false,
                [RecommendationTypes.Microbiome]: false,
                [RecommendationTypes.Neurobehavioral]: false,
                [RecommendationTypes.Sleep]: false,
                [RecommendationTypes.Cardiovascular]: false,
                [RecommendationTypes.Dementia]: false,
                [RecommendationTypes.InsulinResistance]: false,
                [RecommendationTypes.Inflammation]: false,
                [RecommendationTypes.Supplements]: false,
            },
            sectionsIsChanged: {
                [RecommendationTypes.Macronutrient]: false,
                [RecommendationTypes.Methylation]: false,
                [RecommendationTypes.VitaminsAndMicronutrients]: false,
                [RecommendationTypes.KryptoniteFoods]: false,
                [RecommendationTypes.SuperFoods]: false,
                [RecommendationTypes.ExerciseAndRecovery]: false,
                [RecommendationTypes.Longevity]: false,
                [RecommendationTypes.Microbiome]: false,
                [RecommendationTypes.Neurobehavioral]: false,
                [RecommendationTypes.Sleep]: false,
                [RecommendationTypes.Cardiovascular]: false,
                [RecommendationTypes.Dementia]: false,
                [RecommendationTypes.InsulinResistance]: false,
                [RecommendationTypes.Inflammation]: false,
                [RecommendationTypes.Supplements]: false,
            },
            isChanged: false,
            recommendations: [],
            isSelectedAll: true,
        } as RecommendationsComponentState
    );

    const handleExpandSection = (section: RecommendationTypes) => {
        const sections = state.sections;
        sections[section] = !sections[section];
        setState({ ...state, sections: sections });
    }

    const checkSelectedAll = () => {
        const selectedAll = !state.recommendations.find(el => el.isSelected === false)
        setState({ ...state, isSelectedAll: selectedAll });
    }

    const handleSelected = (id: number | string, isSelected: boolean) => {
        const recommendationForIsSelected = state.recommendations.find(el => el.id === id)
        recommendationForIsSelected.isSelected = isSelected
        setState({ ...state })
        checkSelectedAll()
    }

    const handleChanges = (id: number | string, value: string) => {
        const recommendationForIsSelected = state.recommendations.find(el => el.id === id)
        recommendationForIsSelected.content = value
        setState({ ...state })
    }

    const getRecommendation = (type: number): RecommendationDisplayModel[] => {
        return state.recommendations.filter(el => el.type === type)
    }

    const handleAddRecommendation = (type: number, index: number) => {
        const newRecommendation = {
            recommendationId: `xxx${index}`,
            id: moment().unix() + index,
            baseId: moment().unix() + index,
            type: type,
            content: '',
            isSelected: true,
        };
        setState({
            ...state,
            recommendations: [
                ...state.recommendations,
                newRecommendation,
            ]
        })
    }

    const prepereRecommendationToSend = () => {
        const selectedRecommendations = state.recommendations.filter(el => el.isSelected)
        const recommendationsToSend = selectedRecommendations.map(el => {
            return `${el.recommendationId}`.includes('xxx')
                ? {
                    type: el.type,
                    baseId: el.baseId,
                    content: el.content,
                }
                : {
                    recommendationId: el.recommendationId,
                    baseId: el.baseId,
                    type: el.type,
                    content: el.content,
                }
        })
        healthReportsStore.putRecommendationToSend(recommendationsToSend)
    }

    const handleSelectedAll = () => {
        state.recommendations.forEach(el => el.isSelected = !state.isSelectedAll)
        setState({ ...state, isSelectedAll: !state.isSelectedAll })
    }

    useEffect(() => {
        prepereRecommendationToSend()
    }, [isPrepereRecommendation]);

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<HealthReportRecommendationDefaultModel[]>(healthReportsQuery.recommendationsDefault$, recommendationsDefault => {
                setState(state => ({
                    ...state,
                    recommendations: recommendationsDefault.map(el => {
                        return {
                            recommendationId: el.recommendationId,
                            baseId: el.recommendationId,
                            type: el.recommendationType,
                            content: el.recommendation.content,
                            id: el.id,
                            isSelected: true,
                        }
                    })
                }));
            }),
        ]

        return () => {
            subscriptions.map(i => i.unsubscribe())
        };
    }, [patientId]);

    return [
        state,
        handleExpandSection,
        handleSelected,
        handleChanges,
        getRecommendation,
        handleAddRecommendation,
        handleSelectedAll,
    ];
}
