import React from "react";
import {
    Box, Grid, TableContainer, Table, TableFooter, TableHead, TableBody, Divider,
    useMediaQuery, useTheme, Tooltip,
} from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';
import emptyMedications from "@img/placeholders/EmptyReport.png";
import clsx from "clsx";
import moment from "moment";
import { useFacade } from "./patientMedicationsRxNTComponent.hooks";
import { ProtectedElement } from "../../../common/components/ProtectedElement";
import { PermissionType } from "../../../auth/models/auth.enums";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { SupplementsPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/SupplementsPlaceHolder";
import { useStyles } from "./patientMedicationsRxNTComponent.styles";
import { PaginationComponent } from "../../../common/pagination/components/paginationComponent/PaginationComponent";
import { pageSizes } from "../../../common/pagination/models/page-sizes";
import commonUseStyles from '../../../common/styles/common.styles';
import { WildHealthPlaceHolderBase } from '../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolderBase';
import { toCurrentTimeZone } from "../../../timezones/helpers/timezone";
import {ManageRxntButtonComponent} from "../manageRxntButton/ManageRxntButtonComponent";

interface PatientMedicationsRxNTComponentProps {
    patientId?: number;
    fullWidth?: boolean;
    handleToggleDialog: () => void;
}

export const PatientMedicationsRxNTComponent: React.FC<PatientMedicationsRxNTComponentProps> = (props: PatientMedicationsRxNTComponentProps) => {
    const {
        patientId,
        handleToggleDialog
    } = props;

    const [
        state,
        handlePageSizeChange,
        handlePageChange,
        getAllAvailablePages,
    ] = useFacade(patientId);

    const classes = useStyles();
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const customTooltipClasses = {
        tooltip: classes.customWidth,
        arrow: classes.arrow,
        tooltipPlacementBottom: classes.tooltipPlacement,
        tooltipPlacementTop: classes.tooltipPlacement,
    };

    const displayDataValue = (value: Date) => {
        return value ? moment(toCurrentTimeZone(value)).format("MM/DD/yyyy") : 'N/A';
    }

    const renderTable = () => {
        if (!state.isLoading && (!state.medications || !state.medications.length)) {
            return isSmallScreen ? (
                <Box minHeight="calc(100vh - 303px)" height={1}>
                    <WildHealthPlaceHolderBase message="You have no medication listed at the moment.">
                        <img className={classes.placeholderImage}
                            src={emptyMedications}
                            alt="img" />
                    </WildHealthPlaceHolderBase>
                </Box>
            ) : (
                <SupplementsPlaceHolder message={`You have no medication listed at the moment.`} />
            );
        }

        return (
            <Box minHeight="300px">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <WildHealthTableRow>
                                <WildHealthTableCell style={{ width: '40%' }}>
                                    <Box pl={2}>
                                        Name
                                    </Box>
                                </WildHealthTableCell>
                                <WildHealthTableCell style={{ width: '15%' }}>
                                    Dosing Instruction
                                </WildHealthTableCell>
                                <WildHealthTableCell style={{ width: '15%' }}>
                                    Number of refills
                                </WildHealthTableCell>
                                <WildHealthTableCell style={{ width: '15%' }}>
                                    Start Date
                                </WildHealthTableCell>
                                <WildHealthTableCell style={{ width: '15%' }}>
                                    Last Prescription Date
                                </WildHealthTableCell>
                            </WildHealthTableRow>
                        </TableHead>
                        <TableBody>
                            {
                                state.medicationsPart.map((item, index) =>
                                    <WildHealthTableRow key={index}>
                                        <WildHealthTableCell>
                                            <Box pl={2}>
                                                {
                                                    item.name
                                                }
                                            </Box>
                                        </WildHealthTableCell>
                                        <WildHealthTableCell>
                                            <Box display="flex" flexWrap="wrap">
                                                {item.dosingInstructions}
                                            </Box>
                                        </WildHealthTableCell>
                                        <WildHealthTableCell>
                                            <Box display="flex" flexWrap="wrap">
                                                {item.numberOfRefills}
                                            </Box>
                                        </WildHealthTableCell>
                                        <WildHealthTableCell>
                                            <Box display="flex" flexWrap="wrap">
                                                {displayDataValue(item.startDate)}
                                            </Box>
                                        </WildHealthTableCell>
                                        <WildHealthTableCell>
                                            <Box display="flex" flexWrap="wrap">
                                                {displayDataValue(item.lastDatePrescribed)}
                                            </Box>
                                        </WildHealthTableCell>
                                    </WildHealthTableRow>
                                )
                            }
                        </TableBody>
                        {!isSmallScreen &&
                            <TableFooter>
                                <WildHealthTableRow className={classes.row}>
                                    <WildHealthTableCell colSpan={6}>
                                        <PaginationComponent
                                            pageSizes={pageSizes}
                                            pageSize={state.pageSize}
                                            selectedPage={state.selectedPage}
                                            handlePageSizeChange={handlePageSizeChange}
                                            totalCount={state.totalCount}
                                            availablePages={getAllAvailablePages()}
                                            handlePageSelect={handlePageChange} />
                                    </WildHealthTableCell>
                                </WildHealthTableRow>
                            </TableFooter>
                        }
                    </Table>
                </TableContainer>
            </Box>
        )
    }

    return (
        state.isLoading ?
            <WildHealthLinearProgress /> :
            <>
                <Box className={clsx(isSmallScreen && classes.smallRoot, "flat-scroll")}>
                    {!isSmallScreen && (
                        <Box p={4} pb={0} display='flex' justifyContent='space-between'>
                            <Box fontSize={20} fontWeight={500}>List of Medications</Box>
                            <Box display='flex' alignItems='center' gridGap={24}>
                                <Box display='flex' alignItems='center' gridGap={8} className="wh-tw-cursor-pointer" onClick={handleToggleDialog}>
                                    <InfoIcon className="wh-tw-w-5 wh-tw-h-5 wh-tw-text-primaryV" />
                                    <Box className="wh-tw-text-primaryV wh-tw-text-sm wh-tw-font-medium">Do you need help?</Box>
                                </Box>
                                <ProtectedElement
                                    element={<Box display='flex' alignItems='center' gridGap={8} className="wh-tw-cursor-pointer" >
                                        <Tooltip placement="bottom" arrow
                                            title={<Box className={classes.notificationToolTipText}>
                                                <Box>
                                                    {state.allergies.length
                                                        ? state.allergies.map((allergy, index) => {
                                                            return <Box key={index}>
                                                                <span className={classes.itemDot}>&#x2022;</span>
                                                                <span className={classes.itemName}>{allergy.name}</span>
                                                                <span className={classes.itemReaction}>{`- ${allergy.description}`}</span>
                                                            </Box>
                                                        })
                                                        : <span className={classes.itemName}>No known drug allergies</span>}
                                                </Box>
                                            </Box>}
                                            classes={customTooltipClasses}
                                        >
                                            <InfoIcon className="wh-tw-w-5 wh-tw-h-5 wh-tw-text-primaryV" />
                                        </Tooltip>

                                        <Box className="wh-tw-text-primaryV wh-tw-text-sm wh-tw-font-medium">See Allergy List</Box>
                                    </Box>}
                                    permissions={[PermissionType.Coaching, PermissionType.ManagePatients]}
                                />
                                <Box>
                                    <ProtectedElement
                                        element={<ManageRxntButtonComponent patientId={patientId}/> }
                                        permissions={[PermissionType.Coaching, PermissionType.ManagePatients]}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    )}

                    <Box mt={3}>
                        <Grid container>
                            <Grid item sm={12} md={12} xs={12}>
                                {
                                    renderTable()
                                }
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                {isSmallScreen && (
                    <>
                        {state.medications && state.medications.length > 0 && <Divider className={commonClasses.renderDivider} orientation="horizontal" />}
                        <Box className={commonClasses.bgWhiteMain}>
                            <Box display="flex" flexDirection="row" justifyContent="flex-end">
                                {state.medications && state.medications.length > 0 && (
                                    <Box flex={1} margin="auto" pl={2}>
                                        <PaginationComponent
                                            pageSizes={pageSizes}
                                            pageSize={state.pageSize}
                                            selectedPage={state.selectedPage}
                                            handlePageSizeChange={handlePageSizeChange}
                                            totalCount={state.totalCount}
                                            availablePages={getAllAvailablePages()}
                                            handlePageSelect={handlePageChange} />
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </>
                )}
            </>
    )
}
