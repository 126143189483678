import { createStyles, makeStyles } from "@material-ui/core";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles(() =>
    createStyles({
        root: {
            width: "100%",
            maxWidth: "90vw",
            overflowX: "auto"
        },
        row: {
            backgroundColor: colors.white,
        },
        actions: {
            width: '30%',
            paddingTop: '6px',
            paddingBottom: '6px',
        },
        fixedButton: {
            fill: colors.main,
            width: 58,
            height: 58
        },
        placeholderImage: {
            width: "100%",
            maxWidth: 300,
        },
        smallRoot: {
            flex: 1,
            overflow: "auto",
            width: '100%',
            minHeight: "calc(100vh - 303px)",
            maxHeight: "calc(100vh - 303px)",
            backgroundColor: colors.white,
        },
        notificationToolTipText: {
            lineHeight: 1.5,
            fontFamily: 'Be Vietnam Pro',
            fontWeight: 400,
            fontSize: 14,
            color: colors.white,
        },
        arrow: {
            color: colors.lightBlack,
        },
        customWidth: {
            width: '25vw',
            maxWidth: '25vw',
            backgroundColor: colors.lightBlack,
            padding: 16,
            boxShadow: ` 0px 4px 9px rgba(168, 173, 198, 0.15)`,
        },
        tooltipPlacement: {
            margin: '8px 0',
        },
        itemDot: {
            paddingRight: '6px',
            paddingLeft: '6px',
        },
        itemName: {
            whiteSpace: 'pre-line',
        },
        itemReaction: {
            fontWeight: 200,
        }
    }),
);
