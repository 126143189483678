import { ListItemText } from '@material-ui/core';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        list: {
            width: '100%',
            maxWidth: 1070,
        },
        title: {
            fontFamily: 'Be Vietnam Pro',
            fontSize: '1.6rem',
            fontWeight: 500,
            lineHeight: 1.18,
            letterSpacing: 0.17,
            color: '#292929 !important'
        },
        subTitle: {
            fontFamily: 'Be Vietnam Pro',
            fontSize: 16,
            fontWeight: 500,
            lineHeight: 1.38,
            letterSpacing: 0.8,
        },
        sectionTitle: {
            fontFamily: 'Be Vietnam Pro',
            fontSize: 16,
            fontWeight: 500,
            lineHeight: 1.38,
            letterSpacing: 0.8,
            color: 'rgba(0, 0, 0, 0.56)',
        },
        regular: {
            fontFamily: 'Be Vietnam Pro',
            fontSize: 15,
            fontWeight: 300,
            lineHeight: 1.33,
            letterSpacing: 0.15,
        },
        divider: {
            backgroundColor: "#f9f9fb",
            margin: 'auto',
            height: 2,
        },
        internalNote: {
            // wordBreak: 'break-all',
            display: 'flex',
            flexWrap: 'wrap',
        },
        attachments: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            backgroundColor: theme.palette.background.paper,
        },
        icon: {
            color: 'white',
        },
        titleIcon: {
            fill: colors.gray1,
            transform: 'rotate(45deg)',
        },
        fileButton: {
            width: 100,
            height: 100,
            "&:hover": {
                "& $fileIcon": {
                    display: "none",
                },
                "& $downloadIcon": {
                    display: "inline-block",
                },
            },
        },
        fileIcon: {
            width: 80,
            height: 80,
            color: colors.gray2,
            display: 'inline-block',
        },
        downloadIcon: {
            width: 80,
            height: 80,
            color: colors.gray2,
            display: 'none',
        },
        document: {
            padding: '0 8px',
            borderRadius: 2,
            display: 'flex',
            alignItems: 'center',
            background: colors.gray8,
            width: '100%',
            height: 44,
        },
        imageFile: {
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 2,
            backgroundSize: 60,
            height: 60,
            minWidth: 60,

            "&:hover": {
                "& $downloadIcon": {
                    display: "inline-block",
                },
            },
        },
        renderText: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        colorMain: {
            color: colors.main
        }
    })
);

export const FileName = withStyles({
    root: {
        "& .MuiListItemText-primary": {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            maxWidth: 225,
        },
    }
})(ListItemText);
