import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { profileService } from "../../../account/services/profile.service";
import { profileQuery } from "../../../account/stores/profileStore";
import { DataSpecificationsEnum } from "../../../common/constants/data-specifications";
import { onEmit } from "../../../common/helpers/on-emit";
import { metaTagsService } from "../../../employee/services/metaTags.service";
import { HealthSummaryDataModel } from "../../../healthSummary/models/healthSummary.models";
import { PatientModel } from "../../models/patient.model";
import { patientsService } from "../../services/patients.service";
import { patientsQuery } from "../../stores/patientsStore";
import { healthSummaryVitalsService } from "../../../healthSummary/services/healthSummaryVitals.service";

interface PatientTitleComponentState {
    vital: HealthSummaryDataModel[];
    isLoading: boolean;
    patient: PatientModel;
    problemList: HealthSummaryDataModel[];
}

export function useFacade(patientId: number): [
    PatientTitleComponentState,
    (patient: PatientModel) => string] {

    const [state, setState] = useState({
        vital: [],
        isLoading: true,
        patient: null,
        problemList: null,
    } as PatientTitleComponentState);

    const displayPatientName = (patient: PatientModel) => {
        return patient
            ? `${patient.firstName} ${patient.lastName}`
            : 'Loading...';
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<PatientModel>(patientsQuery.targetPatient$, targetPatient => {
                if (targetPatient?.id === patientId) {
                    setState(state => ({ ...state, patient: targetPatient, isLoading: false }));
                }
            }),
            onEmit<HealthSummaryDataModel[]>(profileQuery.patientProfileProblemList$, problemList => {
                setState(state => ({ ...state, problemList: problemList }));
            })
        ];

        patientsService.get(patientId, DataSpecificationsEnum.UpdatePatientSpecification);
        healthSummaryVitalsService.getVitals(patientId).subscribe((response) => setState(state => ({...state, vital: response})));
        profileService.getPatientProblemList(patientId).subscribe();
        metaTagsService.getMetaTags(patientId).subscribe();

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, [patientId]);

    return [
        state,
        displayPatientName
    ];
}