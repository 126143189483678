import { Box, Divider } from '@material-ui/core';
import React from 'react';
import { useStyles } from './wildHealthHeaderComponent.styles';

interface WildHealthHeaderComponentProps {
    title: JSX.Element | string;
    children: any;
}

export const WildHealthHeaderComponent: React.FC<WildHealthHeaderComponentProps> = (props: WildHealthHeaderComponentProps) => {
    const { title, children } = props;
    const classes = useStyles();

    return (
        <Box>
            <Box className={classes.content}>
                <div className={classes.title}>{title}</div>
            </Box>

            <Divider className={classes.divider} />

            <Box>
                {children}
            </Box>
        </Box>
    );
}