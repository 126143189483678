import React from 'react';
import { Box } from "@material-ui/core";
import { useStyles } from './documentCategoryCardComponent.styles';
import clsx from 'clsx';
import { DocumentCategory, DocumentCategoryModel, DocumentModel } from '../../models/documents.models';
import { ReactComponent as ConsentsIcon } from '@img/icons/consents.svg';
import { ReactComponent as ConsultIcon } from '@img/icons/consult.svg';
import { ReactComponent as EsgIcon } from '@img/icons/ecg.svg';
import { ReactComponent as OtherIcon } from '@img/icons/other.svg';
import { ReactComponent as OldRecordsIcon } from '@img/icons/oldRecords.svg';
import { ReactComponent as MedicalFormsIcon } from '@img/icons/medicalForms.svg';
import { ReactComponent as LabReportsIcon } from '@img/icons/LabReports.svg';
import { ReactComponent as HospitalRecordIcon } from '@img/icons/hospitalRecord.svg';
import { ReactComponent as ImagingReportsIcon } from '@img/icons/ImagingReports.svg';
import { ReactComponent as PrescriptionsIcon } from '@img/icons/Prescriptions.svg';
import { colors } from '../../../common/constants/colors';

interface DocumentCategoryCardComponentProps {
    isEmployeePerspective: boolean;
    category: any;
    handleStart?: (category: DocumentCategoryModel) => void;
}

export const DocumentCategoryCardComponent: React.FC<DocumentCategoryCardComponentProps> = (props: DocumentCategoryCardComponentProps) => {
    const {
        isEmployeePerspective,
        category,
        handleStart = () => { },
    } = props;

    const categoryId = category.name.replace(' ', '')

    const renderIcon = () => {
        switch (category.type) {
            case DocumentCategory.LabReports: return <LabReportsIcon id={`${categoryId}-icon`} fill={category.documents.length ? colors.main : colors.gray2} />;
            case DocumentCategory.Consents: return <ConsentsIcon id={`${categoryId}-icon`} fill={category.documents.length ? colors.main : colors.gray2} />;
            case DocumentCategory.Consults: return <ConsultIcon id={`${categoryId}-icon`} fill={category.documents.length ? colors.main : colors.gray2} />;
            case DocumentCategory.ECGs: return <EsgIcon id={`${categoryId}-icon`} fill={category.documents.length ? colors.main : colors.gray2} />;
            case DocumentCategory.Miscellaneous: return <OtherIcon id={`${categoryId}-icon`} fill={category.documents.length ? colors.main : colors.gray2} />;
            case DocumentCategory.OldRecords: return <OldRecordsIcon id={`${categoryId}-icon`} fill={category.documents.length ? colors.main : colors.gray2} />;
            case DocumentCategory.MedicalForms: return <MedicalFormsIcon id={`${categoryId}-icon`} fill={category.documents.length ? colors.main : colors.gray2} />;
            case DocumentCategory.HospitalReports: return <HospitalRecordIcon id={`${categoryId}-icon`} fill={category.documents.length ? colors.main : colors.gray2} />;
            case DocumentCategory.ImagingReports: return <ImagingReportsIcon id={`${categoryId}-icon`} fill={category.documents.length ? colors.main : colors.gray2} />;
            case DocumentCategory.Prescriptions: return <PrescriptionsIcon id={`${categoryId}-icon`} fill={category.documents.length ? colors.main : colors.gray2} />;
            default: return <></>;
        }
    }

    const classes = useStyles();

    const getNewDocument = (documents: DocumentModel[]): number => {
        return documents.filter(el => !el.isViewed).length
    }

    return (
        <Box id={categoryId} className={clsx(classes.cardMain, category.documents.length && classes.cardActive)} width={1} onClick={() => { handleStart(category) }}> 
                <Box display='flex' alignItems='center' >
                    {renderIcon()}
                    <span className={classes.cardTitle} >{category.name}</span>
                    <span id={`${categoryId}-counter`} className={classes.cardDocumentCoun}>{category.documents.length}</span>
                </Box>
                {isEmployeePerspective && !!getNewDocument(category.documents) && <Box py={0.5} px={1} mx={2} my={0.5} id={`${categoryId}-new`} className={classes.cardUnreadLabele}>
                    {`${getNewDocument(category.documents)} ${getNewDocument(category.documents) > 1 ? 'new documents' : 'new document'}`}
                </Box>}
        </Box>
    )
}