import { useState } from "react";

export interface Group {
    title: string;
    icon: JSX.Element;
    items: string;
}

interface EmojiComponentState {
    selectedGroupIndex: number;
}

export function useFacade(): [EmojiComponentState, (index: number) => void] {

    const [state, setState] = useState({
        selectedGroupIndex: 0,
    } as EmojiComponentState);

    const handleSelectGroup = (index: number) => {
        setState(state => ({...state, selectedGroupIndex: index}));
    }

    return [state, handleSelectGroup];
}

