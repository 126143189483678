import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogContent: {
            padding: '30px 40px',
            width: 540,
        },
        dialogTitleCloseAction: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        dropContainer: {
            height: 60,
            width: '100%',
            border: `1px dashed ${colors.gray4}`,
            background: colors.gray8,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        dropIcon: {
            color: `${colors.gray2}66`,
            fontSize: 40,
            marginRight: 8,
        },
        dropTitle: {
            color: colors.gray1,
            fontSize: 16,
            paddingRight: 2,
        },
        attachment: {
            border: `1px solid ${colors.stroke}`,
            borderRadius: 2,
            background: colors.gray8,
            display: 'flex',
            alignItems: 'center',
            maxWidth: 500,
            height: 32,
        },
        attachmentIcon: {
            color: colors.gray2,
        },
        attachmentTitle: {
            fontSize: 14,
            fontWeight: 500,
            color: colors.black,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },

        label: {
            textTransform: `none`,
            color: colors.gray2,
            fontSize: 16,
            textDecoration: 'underline',
        },
        progress: {
            color: colors.main
        },
        deleteIcon: {
            color: colors.bad,
            cursor: 'pointer',
        },
    })
);
