import { Box } from '@material-ui/core';
import React from 'react';
import { SelectAddOnModel } from '../../models/addOns.models';
import { SelectAddOnComponent } from '../selectAddOnComponent/selectAddOnComponent';

interface SelectAddOnsComponentProps {
    items: SelectAddOnModel[];
    handleSelectingItem: (id: number, value: boolean) => void;
}

export const SelectAddOnsComponent: React.FC<SelectAddOnsComponentProps> = (props: SelectAddOnsComponentProps) => {
    const { items, handleSelectingItem } = props;

    return (
        
        <Box>
        {
            items.map((item) => {
                return (
                    <Box key={item.id} >
                        <SelectAddOnComponent addOn={item} handleSelectingItem={handleSelectingItem}/>
                    </Box>
                )
            })
        }
        </Box>
    );
}
