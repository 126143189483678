import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { ConversationAuthorModel, PatientConversationModel } from "../../models/conversation.models";
import { onEmit } from "../../../common/helpers/on-emit";
import { patientConversationsQuery } from "../../stores/patientConversationsStore/patientConversations.query";
import { profileService } from "../../../account/services/profile.service";
import { employeeConversationsStore } from "../../stores/employeeConversationsStore/employeeConversations.store";

interface FullHealthCareChatComponentState {
    isLoading: boolean;
    isSideAreaOpen: boolean;
    conversationProxies: any[];
    conversation: PatientConversationModel;
    author: ConversationAuthorModel;
}

let stateContext: FullHealthCareChatComponentState = null;

export function useFacade(): [FullHealthCareChatComponentState, (open: boolean) => void] {
    const [state, setState] = useState({
        isLoading: true,
        isSideAreaOpen: false,
        conversationProxies: [],
        conversation: null,
        author: null,
    } as FullHealthCareChatComponentState);

    stateContext = state;

    const handleToggleSideArea = (open: boolean) => {
        setState({ ...state, isSideAreaOpen: open });
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<PatientConversationModel>(patientConversationsQuery.heathCareConversation$, conversation => {
                if (conversation) {
                    if (!conversation.proxy) {
                        conversation.proxy = stateContext.conversationProxies.find(proxy => proxy.sid === conversation.vendorExternalId);
                    }
                    setState(state => ({...state, conversation: conversation}));
                    const employeeIds = conversation.employees.map(e => e.employeeId).join(',')
                    profileService.getEmployeeProfilePhotos(employeeIds).subscribe()
                }
            }),
            onEmit<ConversationAuthorModel>(patientConversationsQuery.author$, author =>
                setState(state => ({...state,author: author}))
            ),
            onEmit<boolean>(patientConversationsQuery.isLoading$, isLoading => 
                setState(state => ({ ...state, isLoading: isLoading }))    
            ),
        ];
        employeeConversationsStore.resetAttachments();
        employeeConversationsStore.resetAttachmentsView();

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [state, handleToggleSideArea];
}
