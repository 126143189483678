import { useEffect, useState } from 'react';
import { Subscription } from 'rxjs';
import { onEmit } from '../../../common/helpers/on-emit';
import { patientProfileQuery } from '../../stores/patientProfileStore';
import { patientProfileStore } from '../../stores/patientProfileStore';

interface ManagePatientProfileComponentState {
    selectedTab: string;
}

export function useFacade(patientId: number): [
    ManagePatientProfileComponentState,
    (tab: string) => void,
] {
    const [state, setState] = useState({
        selectedTab: patientProfileQuery.getTab()
    } as ManagePatientProfileComponentState);

    const handleChangeTab = (tab: string) => {
        patientProfileStore.setTab(tab);
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<string>(patientProfileQuery.selectedTab$, selectedTab => {
                if (patientId === patientProfileStore.getValue().patientId) {
                    setState(state => ({ ...state, selectedTab: selectedTab }))
                }
            })
        ];

        patientProfileStore.setPatientId(patientId);

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [patientId]);

    return [
        state,
        handleChangeTab
    ];
}