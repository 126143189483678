import { useEffect, useState } from "react";
import { UploadedAttachmentModel } from "../../models/message.models";

interface EditScheduledMessageState {
    message: string;
    attachments: UploadedAttachmentModel[];
    removeAttachments: number[];
}

export function useFacade(
    initMessage: string,
    initAttachments: UploadedAttachmentModel[],
    handleConfirm: (message: string, removeAttachmentIds: number[], attachments: UploadedAttachmentModel[]) => void
): [
        EditScheduledMessageState,
        (event: any, message: string) => void,
        (value: string) => void,
        (id: number, name: string) => void,
    ] {

    const [state, setState] = useState({
        message: initMessage,
        attachments: initAttachments,
        removeAttachments: []
    } as EditScheduledMessageState);

    const handleChangeMessage = (value: string) => {
        setState(state => ({ ...state, message: value }));
    }

    const handleRemoveAttachment = (attachmentId: number) => {
        const removeAttachments = [...state.removeAttachments, attachmentId]
        setState(state => ({ ...state, removeAttachments: removeAttachments, attachments: state.attachments.filter((i) => i.id !== attachmentId) }));
    }

    const handleKeyDown = (event: any, message: string) => {
        if (event.keyCode === 13 && !event.shiftKey) {
            handleConfirm(message.trim(), state.removeAttachments, state.attachments)
        }
    }

    useEffect(() => {
        setState(state => ({ ...state, message: initMessage, attachments: initAttachments, removeAttachments: [] }));
    }, [initMessage, initAttachments]);

    return [
        state,
        handleKeyDown,
        handleChangeMessage,
        handleRemoveAttachment,
    ];
}
