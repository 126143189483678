import { createStyles, makeStyles, withStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        moreVert: {
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
        },
        markUnreadMenu: {
            textAlign: "center",
            width: '7.5rem',
            height: '1.5rem',
            fontSize: '15px'
        },
        deleteMessageMenu: {
            width: '7.5rem',
            height: '3rem',
            fontSize: '15px'
        },
        popover: {
            pointerEvents: "none",
        },
        popoverContent: {
            pointerEvents: "auto",
        }
    })
);