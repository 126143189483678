import {Box, Grid} from '@material-ui/core';
import React from 'react';
import {Observable} from 'rxjs';
import {ItemsListApoeComponent} from '../../../common/components/itemsListComponent/ItemsListApoeComponent';
import {ItemsListComponent} from '../../../common/components/itemsListComponent/ItemsListComponent';
import {WildHealthTabControl} from '../../../common/components/wildHealthTabControl/WildHealthTabControl';
import {useStylesSimpleTabs} from '../../../common/components/wildHealthTabControl/wildHealthTabControl.styles';
import {
    HealthSummaryBlockItemsNoteComponent
} from '../../../healthSummary/components/blockItemsComponent/HealthSummaryBlockItemsNoteComponent';
import {
    HealthSummaryListItemsNoteComponent
} from '../../../healthSummary/components/listItemsComponent/HealthSummaryListItemsNoteComponent';
import {HealthSummaryMapModel, HealthSummarySectionType} from '../../../healthSummary/models/healthSummary.models';
import {HealthSummaryNoteKeySectionNames, PmhModel, PmhModelNew} from '../../models/notes.models';
import {
    HealthSummaryListItemsNoteComponentState
} from '../createHistoryAndPhysicalComponent/createHistoryAndPhysicalNoteComponent.hooks';
import {FeatureComponent} from "../../../common/components/featureFlags/FeatureComponent";
import {FeatureFlag} from "../../../common/components/featureFlags/featureFlags.models";

export interface PmhComponentProps {
    data: PmhModel,
    patientId: number,
    dataNew: PmhModelNew,
    map?: HealthSummaryMapModel[],
    newVersion: boolean;
    handleChangesOldPmh: (key: string, values: string[]) => void,

    getIcdCodesAutoComplete: (searchQuery: string) => Observable<string[]>,
    handleToggleActions: Function,
    handleEditRowValue: Function,
    handleEditRowName: Function,
    handleAddNewRow: Function,
    handleDelete: Function,
    handleEdit: Function,
    handleBlur: Function,
    saveValue: Function,
    handleAddInformation: Function,
    handleEditNewItem: Function,
    handleSaveNewItem: Function,
    handleEditItem: Function,
    state: HealthSummaryListItemsNoteComponentState,
    handleHideApoe:  Function,
    handleSelectedSoursApoe: Function, 
    handleEditApoe: Function,
    handleDateColonoscopyChanges: Function,
    handleDatePsaChanges: Function,
}

export const PmhComponent: React.FC<PmhComponentProps> = (props: PmhComponentProps) => {
    const {
        data,
        patientId,
        dataNew,
        map = [],
        newVersion = false,
        handleChangesOldPmh,

        getIcdCodesAutoComplete,
        handleToggleActions,
        handleEditRowValue,
        handleEditRowName,
        handleAddNewRow,
        handleDelete,
        handleEdit,
        handleBlur,
        saveValue,
        handleAddInformation,
        handleEditNewItem,
        handleSaveNewItem,
        handleEditItem,
        state,
        handleHideApoe,
        handleSelectedSoursApoe, 
        handleEditApoe,
        handleDateColonoscopyChanges,
        handleDatePsaChanges
    } = props;

    const styleTabs = useStylesSimpleTabs()

    const buildSectionComponent = (section: string, data, map: HealthSummaryMapModel, rowFormat?: boolean, rxnt: boolean = false) => {
        const sectionType = map.editSectionType;

        switch (sectionType) {
            case HealthSummarySectionType.Block:
                return (
                    section === 'screeningHealthMaintenanceApoe'
                        ? <ItemsListApoeComponent
                            data={data}
                            handleHideApoe={handleHideApoe}
                            handleSelectedSours={handleSelectedSoursApoe}
                            handleEditValue={handleEditApoe}
                            handleDatePsaChanges={handleDatePsaChanges}
                            handleDateColonoscopyChanges={handleDateColonoscopyChanges}
                        />
                        : <HealthSummaryBlockItemsNoteComponent
                            data={data}
                            rowFormat={rowFormat}
                            map={map}
                            handleAddInformation={handleAddInformation}
                            handleEditNewItem={handleEditNewItem}
                            handleEditItem={handleEditItem}
                            handleSaveNewItem={handleSaveNewItem}
                            state={state}
                            sectionOfNote={section}
                        />
                )
            case HealthSummarySectionType.List:
                return (
                    <HealthSummaryListItemsNoteComponent
                        data={data}
                        map={map}
                        getIcdCodesAutoComplete={getIcdCodesAutoComplete}
                        handleToggleActions={handleToggleActions}
                        handleEditRowValue={handleEditRowValue}
                        handleEditRowName={handleEditRowName}
                        handleAddNewRow={handleAddNewRow}
                        handleDelete={handleDelete}
                        handleEdit={handleEdit}
                        handleBlur={handleBlur}
                        saveValue={saveValue}
                        patientId={patientId}
                        state={state}
                        section={section}
                        rxnt={rxnt}
                    />
                )
        }
    }

    const pmhListTabs = [
        {
            title: 'Allergies',
            content: (
                <>
                    <FeatureComponent notFeatureFlag={FeatureFlag.RxntIntegration}>
                        {
                            buildSectionComponent('allergies', dataNew?.allergies, map.find(el => el.key === HealthSummaryNoteKeySectionNames.ALLERGIES))
                        }
                    </FeatureComponent>
                    <FeatureComponent featureFlag={FeatureFlag.RxntIntegration}>
                        {
                            buildSectionComponent('allergies', dataNew?.rxntAllergies?.map((x, index) => ({
                                key: `'allergies'-${index}`,
                                name: x.name,
                                value: x.description,
                                isDeleted: false,
                                patientId: patientId,
                                isNew: false,
                                id: index,
                                tooltip: ''
                            })), map.find(el => el.key === HealthSummaryNoteKeySectionNames.ALLERGIES), false, true)
                        }
                    </FeatureComponent>
                </>
            ),
            keepMounted: true,
        },
        {
            title: 'Problem List',
            content: (
                <>
                    <FeatureComponent notFeatureFlag={FeatureFlag.RxntIntegration}>
                        {
                            buildSectionComponent('currentMedicalConditions', dataNew?.currentMedicalConditions, map.find(el => el.key === HealthSummaryNoteKeySectionNames.PROBLEMS_LIST))
                        }
                    </FeatureComponent>
                    <FeatureComponent featureFlag={FeatureFlag.RxntIntegration}>
                        {
                            buildSectionComponent('currentMedicalConditions', dataNew?.currentMedicalConditions, map.find(el => el.key === HealthSummaryNoteKeySectionNames.PROBLEMS_LIST), false, true)
                        }
                    </FeatureComponent>
                </>
            ),
            keepMounted: true,
        },
        {
            title: 'Fam HX',
            content: buildSectionComponent('famHX', dataNew?.famHX, map.find(el => el.key === HealthSummaryNoteKeySectionNames.FAMILY_HISTORY)),
            keepMounted: true,
        },
        {
            title: 'Social HX',
            content: buildSectionComponent('socialHX', dataNew?.socialHX, map.find(el => el.key === HealthSummaryNoteKeySectionNames.SOCIAL_HISTORY)),
            keepMounted: true,
        },
        {
            title: 'Lifestyle HX',
            content: buildSectionComponent('lifestyleHX', dataNew?.lifestyleHX, map.find(el => el.key === HealthSummaryNoteKeySectionNames.LIFESTYLE_HISTORY)),
            keepMounted: true,
        },
        {
            title: 'Screening/Health Maintenance',
            content: buildSectionComponent('screeningHealthMaintenanceApoe', dataNew?.screeningHealthMaintenanceApoe, map.find(el => el.key === HealthSummaryNoteKeySectionNames.SCREENING_HEALTH_MAINTENANCE)),
            keepMounted: true,
        },
        {
            title: 'Surgical HX',
            content: buildSectionComponent('surgicalHX', dataNew?.surgicalHX, map.find(el => el.key === HealthSummaryNoteKeySectionNames.SURGICAL_HISTORY)),
            keepMounted: true,
        },
    ];


    return (
        newVersion
            ? <Box><WildHealthTabControl items={pmhListTabs} className={styleTabs} /></Box>
            : <Box>
                <Grid container spacing={2}>
                    <Grid md={4} xs={12}>
                        <Box mt={5} px={3}>
                            <ItemsListComponent
                                title='Allergies'
                                addText='Add Medication'
                                dataKey='allergies'
                                items={data.allergies}
                                grid={12}
                                getAutoComplete={null}
                                handleChanges={handleChangesOldPmh}
                            />
                        </Box>
                    </Grid>
                    <Grid md={4} xs={12}>
                        <Box mt={5} px={3}>
                            <ItemsListComponent
                                title='Problem List'
                                addText='Add Medical Condition'
                                dataKey='currentMedicalConditions'
                                items={data.currentMedicalConditions}
                                getAutoComplete={null}
                                grid={12}
                                handleChanges={handleChangesOldPmh}
                            />
                        </Box>
                    </Grid>
                    <Grid md={4} xs={12}>
                        <Box mt={5} px={3}>
                            <ItemsListComponent
                                title='Fam HX'
                                addText='Add Family Condition'
                                dataKey='famHX'
                                items={data.famHX}
                                getAutoComplete={null}
                                grid={12}
                                handleChanges={handleChangesOldPmh}
                            />
                        </Box>
                    </Grid>
                    <Grid md={4} xs={12}>
                        <Box mt={5} px={3}>
                            <ItemsListComponent
                                title='Screening/Health Maintenance'
                                addText='Add Condition'
                                dataKey='screeningHealthMaintenance'
                                items={data.screeningHealthMaintenance}
                                getAutoComplete={null}
                                grid={12}
                                handleChanges={handleChangesOldPmh}
                            />
                        </Box>
                    </Grid>
                    <Grid md={4} xs={12}>
                        <Box mt={5} px={3}>
                            <ItemsListComponent
                                title='Surgical HX'
                                addText='Add Surgical Condition'
                                dataKey='surgicalHX'
                                items={data.surgicalHX}
                                getAutoComplete={null}
                                grid={12}
                                handleChanges={handleChangesOldPmh}
                            />
                        </Box>
                    </Grid>
                    <Grid md={4} xs={12}>
                        <Box mt={5} px={3}>
                            <ItemsListComponent
                                title='Social/Lifestyle HX'
                                addText='Add Condition'
                                dataKey='socialLifestyleHX'
                                items={data.socialLifestyleHX}
                                getAutoComplete={null}
                                grid={12}
                                handleChanges={handleChangesOldPmh}
                            />
                        </Box>

                    </Grid>
                </Grid>
            </Box>
    );
}