export enum HealthSummarySectionType {
    None = 1,
    List = 5,
    Block = 10,
    Content = 15
}

export enum HealthSummarySectionPosition {
    Left = 5,
    Right = 10,
    Bottom = 15
}

export interface HealthSummaryMapItemModel {
    viewLabelCondition: string
    dateFormat: string
    subSection: string 
    name: string
    key: string
    value: string
    tooltip?: string
}

export interface HealthSummaryMapModel {
    sectionPosition: HealthSummarySectionPosition
    editSectionType: HealthSummarySectionType
    sectionType: HealthSummarySectionType
    items: HealthSummaryMapItemModel[]
    editRestricted: boolean
    useNameValue: boolean
    useJsonData: boolean
    valueColumn: string
    nameColumn: string
    section: string
    order: number
    key: string
}

export interface HealthSummaryDataModel {
    isDeleted: boolean
    patientId: number
    isNew: boolean
    value: string
    name: string
    key: string
    id: number
    tooltip: string
}

export interface HealthSummaryDraftModel extends HealthSummaryDataModel { 
    section: string
}

export interface HealthSummaryListDataModel {
    value: string
    name: string
    key: string
    tooltip?: string
}