import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Subscription } from "rxjs";
import { navigationService } from "../../../../../../services/navigation.service";
import { onEmit } from "../../../../../common/helpers/on-emit";
import { DnaOrderModel } from "../../../../../orders/models/dnaOrders.models";
import { EpigeneticOrderModel } from "../../../../../orders/models/epigeneticOrders.models";
import { LabOrderModel } from "../../../../../orders/models/labOrders.models";
import { orderStatusNames } from "../../../../../orders/models/orders.models";
import { dnaOrdersService } from "../../../../../orders/services/dnaOrdersService";
import { epigeneticOrdersService } from "../../../../../orders/services/epigeneticOrders.service";
import { labOrdersService } from "../../../../../orders/services/labOrders.service";
import { dnaOrdersQuery } from "../../../../../orders/stores/dnaOrdersStore";
import { epigeneticOrdersQuery } from "../../../../../orders/stores/epigeneticOrdersStore";
import { labOrdersQuery } from "../../../../../orders/stores/labOrdersStore";
import { paymentStatusNames } from "../../../../../payment/models/payment.models";
import { ProductSubType } from "../../../../../products/enums/productSubType";
import { ProductType } from "../../../../../products/enums/productType";
import { PatientProductModel } from "../../../../../products/models/patientProducts.model";
import { patientProductsService } from "../../../../../products/services/patientProducts.service";
import { productsQuery } from "../../../../../products/stores/productsStore";

interface OrdersInfoWidgetComponentState {
  isLoading: boolean;
  isLoadingEpi: boolean;
  isLoadingDna: boolean;
  isLoadingLab: boolean;
  isLoadingApp: boolean;
  labOrders: OrderModel[];
  epigeneticOrders: OrderModel[];
  dnaOrders: OrderModel[];
  appointmentOrders: OrderModel[];
}

interface OrderModel {
  date: Date,
  name: string,
  status: string
}

export function useFacade(patientId: number | null): [
  OrdersInfoWidgetComponentState,
  OrderModel[],
  () => void
] {
  const [state, setState] = useState({
    isLoading: true,
    isLoadingApp: true,
    isLoadingDna: true,
    isLoadingEpi: true,
    isLoadingLab: true,
    dnaOrders: [],
    appointmentOrders: [],
    epigeneticOrders: [],
    labOrders: []
  } as OrdersInfoWidgetComponentState);
  const history = useHistory();

  const handleGoToOrders = () => {
    navigationService.toPatientOrders(history, patientId);
  }

  useEffect(() => {
    if (!state.isLoadingApp && !state.isLoadingDna && !state.isLoadingEpi && !state.isLoadingLab) {
      setState((state) => ({ ...state, isLoading: false }));
    }
  }, [state.isLoadingApp, state.isLoadingDna, state.isLoadingEpi, state.isLoadingLab]);

  useEffect(() => {
    const subscriptions: Subscription[] = [
      onEmit<LabOrderModel[]>(labOrdersQuery.orders$, orders => {
        setState(state => ({
          ...state,
          labOrders: orders.map(i => {
            return {
              date: new Date(i.orderedAt),
              name: "Labwork",
              status: orderStatusNames.get(i.status)
            } as OrderModel
          })
        }));
      }),
      onEmit<DnaOrderModel[]>(dnaOrdersQuery.orders$, orders => {
        setState(state => ({
          ...state,
          dnaOrders: orders.map(i => {
            return {
              date: new Date(i.orderedAt),
              name: "DNA",
              status: orderStatusNames.get(i.status)
            } as OrderModel
          })
        }));
      }),
      onEmit<EpigeneticOrderModel[]>(epigeneticOrdersQuery.orders$, orders => {
        setState(state => ({
          ...state,
          epigeneticOrders: orders.map(i => {
            return {
              date: new Date(i.orderedAt),
              name: "Epigenetic",
              status: orderStatusNames.get(i.status)
            } as OrderModel
          })
        }));
      }),
      onEmit<PatientProductModel[]>(productsQuery.patientProducts$, products => {
        if (products) {
          const additionalProducts = products.filter(el => el.productSubType === ProductSubType.Additional && el.productType === ProductType.PhysicianVisit)
          setState(state => ({ ...state, appointmentOrders: additionalProducts.map(i => {
            return {
              date: new Date(i.createdAt),
              name: "Additional Provider Visit",
              status: paymentStatusNames[i.paymentStatus]
            } as OrderModel
          }) }));
        }
      }),
    ];

    const cbEpi = () => setState((state) => ({ ...state, isLoadingEpi: false }));
    const cbDna = () => setState((state) => ({ ...state, isLoadingDna: false }));
    const cbLab = () => setState((state) => ({ ...state, isLoadingLab: false }));
    const cbApp = () => setState((state) => ({ ...state, isLoadingApp: false }));


    epigeneticOrdersService.get(patientId).subscribe(cbEpi, cbEpi);
    labOrdersService.get(patientId).subscribe(cbLab, cbLab);
    patientProductsService.getPatientProducts(patientId).subscribe(cbApp, cbApp);
    dnaOrdersService.get(patientId).subscribe(cbDna, cbDna);


    return () => {
      subscriptions.map((it) => it.unsubscribe());
    };
  }, [patientId]);

  return [
    state,
    [...state.appointmentOrders, ...state.epigeneticOrders, ...state.dnaOrders, ...state.labOrders].sort((x, y) => (new Date(y.date)).getTime() - (new Date(x.date)).getTime()),
    handleGoToOrders
  ];
}
