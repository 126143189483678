import React from 'react';
import { Box, Button, FormControl, FormHelperText, MenuItem, Select, TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { useFacade } from "./uploadDocumentSourceDialog.hooks";
import BackupIcon from '@material-ui/icons/Backup';
import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx';
import { useStyles } from "./uploadDocumentSourceDialog.styles";
import { DescriptionComponentForTag } from './DescriptionComponentForTag';
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import commonUseStyles from '../../../common/styles/common.styles';
import { HealthCategoryTagNames, AssetType } from '../../models/documentSource.model';
import { getNumericKeys } from '../../../common/helpers/enum-mapper';

export const UploadDocumentSourceDialog: React.FC = () => {
    const [
        {
            isOpen,
            types,
            uploadDocumentSource,
            errors
        },
        handleFileUpload,
        handleSubmit,
        handleClose,
        handleChanges
    ] = useFacade();

    const classes = useStyles();
    const commonClasses = commonUseStyles();

    const displayFileName = (file) => {
        if (file) {
            return <div> {file.name} </div>
        }

        return <div className={commonClasses.tertiaryFontReport}>No File Chosen</div>
    }

    return (
        <>
            <Dialog maxWidth="md" open={isOpen} onClose={() => handleClose()} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    <span className="wh-tw-text-2xl wh-tw-font-medium">Upload Document Source</span>
                </DialogTitle>
                <DialogContent>
                    <Box pb={1} width="700px">
                        <Box>
                            <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Name *</Box>
                            <TextField
                                id={`name`}
                                fullWidth
                                required
                                size="small"
                                InputProps={{ className: 'input', classes }}
                                variant="outlined"
                                value={uploadDocumentSource.name}
                                error={!!errors['name']}
                                helperText={errors['name']}
                                disabled={!isOpen}
                                onChange={(event) => handleChanges(event.target.id, event.target.value)}
                            />
                        </Box>
                        <Box mt={2}>
                            <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Document Source Type *</Box>
                            <FormControl
                                variant="outlined"
                                color='primary'
                                required
                                error={!!errors[`documentSourceTypeId`]}
                                size="small"
                                disabled={!isOpen}
                                classes={{ root: classes.root }}
                                fullWidth
                            >
                                <Select
                                    style={{ background: '#FFF' }}
                                    id="documentSourceTypeId"
                                    classes={{ root: classes.root }}
                                    value={uploadDocumentSource.documentSourceTypeId}
                                    inputProps={{
                                        id: 'type-label',
                                        classes,
                                    }}
                                    renderValue={() => {
                                        if (!uploadDocumentSource.documentSourceTypeId) {
                                            return <Box className="wh-tw-text-mediumGray">Please select Document Source Type</Box>;
                                        }

                                        return types.find(x => x.id === uploadDocumentSource.documentSourceTypeId)?.name
                                    }}
                                    displayEmpty
                                    onChange={(v) => handleChanges("documentSourceTypeId", v.target.value)}
                                >
                                    {types.map(item => (
                                        <MenuItem value={item.id} key={item.id}>
                                            <Box className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-black">{item.name}</Box>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        {types.find(x => x.id === uploadDocumentSource.documentSourceTypeId)?.assetType === AssetType.File && (
                            <Box mt={2} width={1}>
                                <Box display="flex" justifyContent="between" mb={1}>
                                    <Box width={0.2}>
                                        <Box>
                                            File:
                                        </Box>
                                    </Box>
                                    <Box width={0.8} display="flex" justifyContent="space-between">
                                        <Box className="compact-text">
                                            {displayFileName(uploadDocumentSource.file)}
                                        </Box>
                                        <Box display="flex" justifyContent="flex-end">
                                            <Button size="small"  className={commonClasses.whButton} component="label" 
                                                disabled={types.find(x => x.id === uploadDocumentSource.documentSourceTypeId)?.assetType !== AssetType.File}>
                                                <BackupIcon />
                                                <input type="file" style={{ display: "none" }}
                                                    disabled={types.find(x => x.id === uploadDocumentSource.documentSourceTypeId)?.assetType !== AssetType.File}
                                                    onChange={(e) => handleFileUpload(e)}
                                                    accept=".pdf" />
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box px={2}>{!!errors['file'] && <FormHelperText error >{errors[`file`]}</FormHelperText>}</Box>
                            </Box>
                        )}
                        {types.find(x => x.id === uploadDocumentSource.documentSourceTypeId)?.assetType === AssetType.Url && (
                            <Box mt={2}>
                                <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>URL</Box>
                                <TextField
                                    id={`url`}
                                    fullWidth
                                    required
                                    size="small"
                                    InputProps={{ className: 'input', classes }}
                                    variant="outlined"
                                    value={uploadDocumentSource.url}
                                    error={!!errors['url']}
                                    helperText={errors['url']}
                                    disabled={types.find(x => x.id === uploadDocumentSource.documentSourceTypeId)?.assetType !== AssetType.Url}
                                    onChange={(event) => handleChanges(event.target.id, event.target.value)}
                                />
                            </Box>
                        )}
                        <Box mt={2}>
                            <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Tags</Box>
                            <FormControl
                                variant="outlined"
                                color='primary'
                                required
                                error={!!errors[`tags`]}
                                size="small"
                                disabled={!isOpen}
                                classes={{ root: classes.root }}
                                fullWidth
                            >
                                <Select
                                    style={{ background: '#FFF' }}
                                    id="tags"
                                    classes={{ root: classes.root }}
                                    value={uploadDocumentSource.tags}
                                    inputProps={{
                                        id: 'type-label',
                                        classes,
                                    }}
                                    renderValue={() => {
                                        if (!uploadDocumentSource.tags || !uploadDocumentSource.tags.length) {
                                            return <Box className="wh-tw-text-mediumGray">Please select tag</Box>;
                                        }

                                        return uploadDocumentSource.tags.length === 1 ? `${uploadDocumentSource.tags.length} tag selected` : `${uploadDocumentSource.tags.length} tags selected`
                                    }}
                                    displayEmpty
                                    onChange={(v) => handleChanges("tags", v.target.value)}
                                >
                                    {
                                        getNumericKeys(HealthCategoryTagNames).map((value, i) => {
                                            return <MenuItem key={i} value={value}>
                                                <Box display="flex" alignItems="center" justifyContent="space-between" width={1}>
                                                <Box className={clsx("wh-tw-text-sm wh-tw-font-medium", uploadDocumentSource.tags.includes(value) ? "wh-tw-text-primaryV" : "wh-tw-text-black")}>{HealthCategoryTagNames[value]}</Box>
                                                {uploadDocumentSource.tags.includes(value) && <CheckIcon className="wh-tw-text-primaryV" />}
                                            </Box>
                                            </MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                            <DescriptionComponentForTag />
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions className="wh-tw-pb-4">
                    <WildHealthButton
                        id="upload-document-source-cancel"
                        color="secondary"
                        size="medium"
                        onClick={() => handleClose()}
                    >
                        Cancel
                    </WildHealthButton>
                    <Box pr={2}>
                        <WildHealthButton
                            id="update-document-source-submit"
                            size="medium"
                            onClick={() => handleSubmit()}
                        >
                            Upload
                        </WildHealthButton>
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    )
}