import React from 'react';
import { ReactComponent as Labs } from "@img/icons/LabsIcon.svg";
import { IconProps } from './IconProps';
import { colors } from '../../modules/common/constants/colors';

const LabsIcon: React.FC<IconProps> = (props: IconProps) => {
    const { isSelected } = props;

    return (
        <Labs height={20} stroke={isSelected ? colors.main : colors.gray2} />
    )
}

export default LabsIcon;