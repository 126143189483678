import { Box, Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import React from 'react';
import { NextTimeSpan, nextTimeSpanNames, NoteModel } from '../../models/notes.models';
import monthlyCalendar from "@img/icons/monthlyCalendar.svg";
import timeCircle from "@img/icons/timeCircle.svg";
import moment from "moment";
import { PatientAppointmentModel } from '../../../appointments/models/appointments.models';
import { SelectIntervalComponent } from '../selectIntervalComponent/SelectIntervalComponent';
import { AppointmentPurposeType } from '../../../appointments/models/appointments.enums';
import commonUseStyles from '../../../common/styles/common.styles';
import { isCoachRole, isProviderRole } from '../../../common/constants/roles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { colors } from '../../../common/constants/colors';
import clsx from 'clsx';
import { WildHealthRadio } from '../../../common/components/WildHealthRadio';
import { useFacade } from './nextAppointmentDateComponent.hooks';
import { Roles } from '../../../auth/models/auth.enums';
import { coachNextAppointmentTimes, manualTimeSpans, providerNextAppointmentTimes } from './nextAppointmentDateComponent.static';
import { toCurrentTimeZone } from '../../../timezones/helpers/timezone';

export interface NextAppointmentDateComponentProps {
    note?: NoteModel;
    noteType: number;
    patientId: number;
    appointments: PatientAppointmentModel[];
    handleChanges: (date: Date, role: Roles) => void
}

export const NextAppointmentDateComponent: React.FC<NextAppointmentDateComponentProps> = (props: NextAppointmentDateComponentProps) => {
    const {
        note = null,
        appointments,
        noteType,
        patientId,
        handleChanges
    } = props;

    const [
        state,
        handleCoachNextAppointmentChange,
        handleCoachNextTimeModeChange,
        handleCoachNextTimeSpanChange,
        handleCoachNextTimeQuantityChange,
        handleProviderNextAppointmentChange,
        handleProviderNextTimeModeChange,
        handleProviderNextTimeSpanChange,
        handleProviderNextTimeQuantityChange
    ] = useFacade(handleChanges, patientId, noteType);

    const commonClasses = commonUseStyles();

    const controlIcon = <CheckBoxOutlineBlankIcon style={{ fill: colors.main }} />;
    const controlCheckedIcon = <CheckBoxIcon style={{ fill: colors.main }} />;

    const coachAppointment = appointments.find(x => x.employees.find(c => isCoachRole(c.roleId)) !== undefined && x.purpose === AppointmentPurposeType.FollowUp);
    const providerAppointment = appointments.find(x => x.employees.find(c => isProviderRole(c.roleId)) !== undefined && x.purpose === AppointmentPurposeType.FollowUp);

    const getTimeLabel = (quantity: number, timeSpan: NextTimeSpan, isManual: boolean, displayQuantity: boolean): string => {
        return isManual
            ? 'Manual'
            : `${displayQuantity ? `${quantity} ` : ''} ${nextTimeSpanNames[timeSpan]}${quantity > 1 ? 's' : ''}`;
    }

    const displayAppointmentDate = (appointment: PatientAppointmentModel) => {
        return (
            <Box display="flex" alignItems="center" mt={2}>
                <Box>
                    <img alt="Monthly calendar" src={monthlyCalendar} />
                </Box>
                <Box ml={1}>
                    <span>{moment(appointment.startDate).format('dddd DD, MMMM yyyy')}</span>
                </Box>
                <Box ml={2}>
                    <img alt="Time circle" src={timeCircle} />
                </Box>
                <Box ml={1}>
                    <span>{moment(appointment.startDate).format('HH:mm')}</span>
                </Box>
            </Box>
        )
    }

    const renderPreviousNoteAppointmentTimes = () => {
        const noteForView = state.prewNote
        return (
            noteForView.isCompleted || (noteForView.nextCoachAppointmentDate && moment(noteForView.nextCoachAppointmentDate) > moment(new Date()))
                || ((noteForView.nextProviderAppointmentDate) && moment(noteForView.nextProviderAppointmentDate) > moment(new Date()))
                ?
                <Box mb={4}>
                    <Box mb={2}>
                        <span className={clsx(commonClasses.textMedium, commonClasses.size16, commonClasses.colorGray1)}>
                            {`${note && note.isCompleted ? '' : 'Previous'} Note Appointment Times`}
                        </span>
                    </Box>
                    {state.patientSubscription && <Box display='flex' justifyContent='space-between' width={0.25}>
                        <Box>
                            <span>Plan start date:</span>
                        </Box>
                        <Box>
                            <span>{moment(toCurrentTimeZone(state.patientSubscription.startDate)).format('MM-DD-YYYY')}</span>
                        </Box>
                    </Box>}
                    {noteForView.nextCoachAppointmentDate &&
                        <Box display='flex' justifyContent='space-between' width={0.25}>
                            <Box>
                                <span>Coach Visit:</span>
                            </Box>
                            <Box>
                                <span>{moment(toCurrentTimeZone(noteForView.nextCoachAppointmentDate)).format('MM-DD-YYYY')}</span>
                            </Box>
                        </Box>
                    }
                    {noteForView.nextProviderAppointmentDate &&
                        <Box display='flex' justifyContent='space-between' width={0.25}>
                            <Box>
                                <span>Provider Visit:</span>
                            </Box>
                            <Box>
                                <span>{moment(toCurrentTimeZone(noteForView.nextProviderAppointmentDate)).format('MM-DD-YYYY')}</span>
                            </Box>
                        </Box>
                    }
                </Box>
                : <></>
        )
    }

    const renderNextAppointmentDates = () => {
        return (
            <>
                <Box>
                    <Box display="flex" alignItems="center">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    icon={controlIcon}
                                    color="primary"
                                    checkedIcon={controlCheckedIcon}
                                    checked={state.enableCoachNextAppointment}
                                    onChange={(e) => handleCoachNextAppointmentChange(e.target.checked)}
                                />
                            }
                            label={<Box component="div" fontWeight={400}>Coach Visit</Box>}
                        />
                    </Box>
                    {
                        Boolean(coachAppointment)
                            ? <>
                                {
                                    displayAppointmentDate(coachAppointment)
                                }
                            </>
                            : <>
                                <Box display="flex" justifyContent="flex-start" alignItems="center" mt={1}>
                                    {
                                        coachNextAppointmentTimes.map((time, index) => (
                                            <Box key={index} display="flex" alignItems="center" mr={2}>
                                                <Box>
                                                    <WildHealthRadio
                                                        checked={(time.timeSpan === state.selectedCoachNextTimeMode.timeSpan && time.quantity === state.selectedCoachNextTimeMode.quantity) || (time.isManual && state.selectedCoachNextTimeMode.isManual)}
                                                        onChange={() => handleCoachNextTimeModeChange(time)}
                                                        disabled={!state.enableCoachNextAppointment}
                                                    />
                                                </Box>
                                                <Box>
                                                    <span>{getTimeLabel(time.quantity, time.timeSpan, time.isManual, true)}</span>
                                                </Box>
                                            </Box>
                                        ))
                                    }
                                </Box>
                                {
                                    state.selectedCoachNextTimeMode.isManual &&
                                    <Box ml={1} mt={1} display="flex" alignItems="center">
                                        <Box width={150}>
                                            <TextField
                                                InputProps={{ inputProps: { min: 1 } }}
                                                value={state.selectedCoachNextTimeQuantity}
                                                onChange={(v) => handleCoachNextTimeQuantityChange(+v.target.value as number)}
                                                variant="outlined"
                                                size="small"
                                                disabled={!state.enableCoachNextAppointment}
                                            />
                                        </Box>
                                        <Box ml={1} width={150}>
                                            <SelectIntervalComponent
                                                handleChange={handleCoachNextTimeSpanChange}
                                                disabled={!state.enableCoachNextAppointment}
                                                items={manualTimeSpans}
                                                selected={state.selectedCoachNextTimeSpan}
                                            />
                                        </Box>
                                    </Box>
                                }
                            </>
                    }

                </Box>
                <Box mt={2}>
                    <Box display="flex" alignItems="center">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    icon={controlIcon}
                                    color="primary"
                                    checkedIcon={controlCheckedIcon}
                                    checked={state.enableProviderNextAppointment}
                                    onChange={(e) => handleProviderNextAppointmentChange(e.target.checked)}
                                />
                            }
                            label={<Box component="div" fontWeight={400}>Provider Visit</Box>}
                        />
                    </Box>
                    {
                        Boolean(providerAppointment)
                            ? <>
                                {
                                    displayAppointmentDate(providerAppointment)
                                }
                            </>
                            : <>
                                <Box display="flex" justifyContent="flex-start" alignItems="center" mt={1}>
                                    {
                                        providerNextAppointmentTimes.map((time, index) => (
                                            <Box key={index} display="flex" alignItems="center" mr={2}>
                                                <Box>
                                                    <WildHealthRadio
                                                        checked={(time.timeSpan === state.selectedProviderNextTimeMode.timeSpan && time.quantity === state.selectedProviderNextTimeMode.quantity) || (time.isManual && state.selectedProviderNextTimeMode.isManual)}
                                                        onChange={() => handleProviderNextTimeModeChange(time)}
                                                        disabled={!state.enableProviderNextAppointment}
                                                    />
                                                </Box>
                                                <Box>
                                                    <span>{getTimeLabel(time.quantity, time.timeSpan, time.isManual, true)}</span>
                                                </Box>
                                            </Box>
                                        ))
                                    }
                                </Box>
                                {
                                    state.selectedProviderNextTimeMode.isManual &&
                                    <Box ml={1} mt={1} display="flex" alignItems="center">
                                        <Box width={150}>
                                            <TextField
                                                InputProps={{ inputProps: { min: 0 } }}
                                                value={state.selectedProviderNextTimeQuantity}
                                                onChange={(v) => handleProviderNextTimeQuantityChange(+v.target.value as number)}
                                                variant="outlined"
                                                size="small"
                                                disabled={!state.enableProviderNextAppointment}
                                            />
                                        </Box>
                                        <Box ml={1} width={150}>
                                            <SelectIntervalComponent
                                                handleChange={handleProviderNextTimeSpanChange}
                                                disabled={!state.enableProviderNextAppointment}
                                                items={manualTimeSpans}
                                                selected={state.selectedProviderNextTimeSpan}
                                            />
                                        </Box>
                                    </Box>
                                }
                            </>
                    }
                </Box>
            </>
        )
    }

    return (
        <Box my={2}>
            {renderPreviousNoteAppointmentTimes()}
            {note && note.isCompleted ? <></> : renderNextAppointmentDates()}
        </Box>
    );
}