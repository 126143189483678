import { makeStyles } from '@material-ui/core/styles';
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme) => ({
  root: {
    borderColor: colors.mediumGray,
    borderRadius: 4,
    '& .MuiOutlinedInput-root': {
      borderColor: colors.mediumGray,
      borderRadius: 4,
    },
  },
  photo: {
    height: '72px',
    width: '72px',
    marginRight: '8px',
    marginTop: '8px',
    marginBottom: '8px',
  },
  photoBorder: {
    borderWidth: '1px',
    borderColor: colors.mediumGray,
    borderStyle: 'solid'
  },
  photoItem: {
    height: '72px',
    width: '72px',
  },
  photoButton: {
    margin: '8px'
  },
  photoUploadButton: {
    height: '34px',
    width: '83px'
  },
  addBtn: {
    border: `1px solid ${colors.main}`,
    backgroundColor: colors.white,
    color: colors.linkBlue,
    textAlign: 'start',
    fontSize: 14,
    fontWeight: 500,
    padding: 0,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  stateItem: {
    display: 'flex',
    gap: 6,
    alignItems: 'center',
    padding: '4px 6px',
    margin: 6,
    marginRight: 8,
    fontSize: 16,
    fontWeight: 400,
    color: colors.black,
    borderRadius: 2,
    backgroundColor: colors.separators,
  },
  icon: {
    color: colors.gray2,
  },
  chip: {
    display: 'flex',
    gap: 6,
    alignItems: 'center',
    height: 27,
    margin: 2,
    lineHeight: '18px',
    backgroundColor: colors.separators,
    borderRadius: 2,
    padding: '4px 6px',
    overflow: 'hidden',
    fontSize: 16,
    fontWeight: 400,
    color: colors.black,
    '& svg': {
      fontSize: 18,
      cursor: 'pointer',
      fill: colors.gray1,
      margin: 0,
    },
    '& .MuiChip-label': {
      padding: 0,
    },
  },
  divider: {
    backgroundColor: colors.gray2,
    height: 2,
  },
}));

