import React from 'react';
import {Box, CircularProgress, FormHelperText, Grid, TextField, useMediaQuery, useTheme} from '@material-ui/core';
import { AddressModel } from "../../models/address.models";
import commonUseStyles from '../../../common/styles/common.styles';
import { addressesKeys, useFacade } from './updateAddressForm.hooks';
import { Autocomplete } from '@material-ui/lab';
import { colors } from '../../constants/colors';
import { useStyles } from "./updateAddressForm.styles";
import {addressQuery} from "../../../account/stores/addressStore";

interface UpdateAddressFormProps {
    address: AddressModel,
    prefix: string;
    errors: { [id: string]: string; };
    handleChanges: Function,
    disabled?: boolean
    handleValidate: (field: string) => void
}

export const UpdateAddressTestedForm: React.FC<UpdateAddressFormProps> = (props: UpdateAddressFormProps) => {
    const {
        address,
        prefix,
        errors,
        handleChanges,
        handleValidate
    } = props;

    const [
        state,
        selectAddress,
        handleAddressChange,
    ] = useFacade(prefix, address.streetAddress1, handleChanges);
    const classes = useStyles();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const renderInput = (isLoading: boolean, params: any, error: boolean, onChange: (value: string) => void, helperText?: string, placeholder?: string) => {
        return (
            <TextField
                {...params}
                variant="outlined"
                error={error}
                placeholder={placeholder}
                onChange={(e) => onChange(e.currentTarget.value)}
                helperText={helperText}
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <React.Fragment>
                            {!isLoading ? <CircularProgress style={{ color: colors.main }} size={20} /> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                    classes,
                }}
            />
        )
    }

    const commonClasses = commonUseStyles();

    if (!handleChanges) {
        return (
            <Box height="235px">
                <Box width={1} display="flex">
                    <Box display="inline" width={'100px'} className="text-bold">Address 1: </Box>
                    <Box display="inline">{address.streetAddress1}</Box>
                </Box>
                <Box mt={2} width={1} display="flex">
                    <Box display="inline" width={'100px'} className="text-bold">Address 2: </Box>
                    <Box display="inline">{address.streetAddress2}</Box>
                </Box>
                <Box mt={2} display="flex" justifyContent="space-between">
                    <Box pr={1} width={0.5} display="flex">
                        <Box display="inline" width={'100px'} className="text-bold">City: </Box>
                        <Box display="inline">{address.city}</Box>
                    </Box>
                    <Box pl={1} width={0.5} display="flex">
                        <Box display="inline" width={'100px'} className="text-bold">State: </Box>
                        <Box display="inline">{address.state}</Box>
                    </Box>
                </Box>
                <Box mt={2} display="flex" justifyContent="space-between">

                    <Box pr={1} width={0.5} display="flex">
                        <Box display="inline" width={'100px'} className="text-bold">Zip Code: </Box>
                        <Box display="inline">{address.zipCode}</Box>

                    </Box>
                    <Box pl={1} width={0.5} display="flex">
                        <Box display="inline" width={'100px'} className="text-bold">Country: </Box>
                        <Box display="inline">{address.country}</Box>

                    </Box>
                </Box>
            </Box>
        )
    }

    return (
        <form autoComplete="off" style={{ width: '100%' }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                    <Box className={commonClasses.subtitle} mb={1}>Address*</Box>
                    <Autocomplete
                        size='small'
                        disableClearable
                        value={address.streetAddress1}
                        options={state.suggestions.data.map(i => (`${i.structured_formatting?.main_text}, ${i.structured_formatting?.secondary_text}`))}
                        onChange={(e, value) => selectAddress(value)}
                        renderInput={(params) => renderInput(
                            state.ready,
                            params,
                            !!errors[`${prefix}${addressesKeys.streetAddress1}`],
                            handleAddressChange,
                            errors[`${prefix}${addressesKeys.streetAddress1}`],
                            'Address'
                        )}
                        onBlur={() => handleValidate(`${prefix}${addressesKeys.streetAddress1}`)}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <Box className={commonClasses.subtitle} mb={1}>Address 2</Box>
                    <TextField
                        fullWidth
                        size='small'
                        value={address.streetAddress2}
                        variant="outlined"
                        InputProps={{ className: 'input', classes }}
                        placeholder='Apartment, suite, etc.'
                        error={!!errors[`${prefix}${addressesKeys.streetAddress2}`]}
                        onChange={(e) => handleChanges(`${prefix}${addressesKeys.streetAddress2}`, e.currentTarget.value)}
                        helperText={errors[`${prefix}${addressesKeys.streetAddress2}`]}
                    />
                </Grid>
            </Grid>
            <Box mt={isSmallScreen ? 1 : 2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6}>
                        <Box className={commonClasses.subtitle} mb={1}>City*</Box>
                        <TextField
                            fullWidth
                            required
                            size="small"
                            id={`${prefix}${addressesKeys.city}`}
                            placeholder="City"
                            variant="outlined"
                            InputProps={{ className: 'input', classes }}
                            error={!!errors[`${prefix}city`]}
                            helperText={errors[`${prefix}city`]}
                            value={address.city ?? ""}
                            onChange={(v) => {
                                handleChanges(v.target.id, v.target.value)
                            }}
                            onBlur={() => handleValidate(`${prefix}city`)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Box className={commonClasses.subtitle} mb={1}>State*</Box>
                        <Autocomplete
                            size='small'
                            disableClearable
                            placeholder="State"
                            classes={{ root: classes.root }}
                            id={`${prefix}${addressesKeys.state}`}
                            options={addressQuery.getStates().filter(x => address.state !== x.name).map(x => x.name)}
                            onChange={(e, value) => handleChanges(`${prefix}${addressesKeys.state}`, value)}
                            value={address.state ?? ""}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    autoComplete='off'
                                    variant="outlined"
                                    placeholder="Select"
                                    error={!!errors[`${prefix}${addressesKeys.state}`]}
                                    InputProps={{ ...params.InputProps, type: 'search' }}
                                />
                            )}
                            onBlur={() => handleValidate(`${prefix}${addressesKeys.state}`)}
                        />
                        <FormHelperText error>{errors[`${prefix}${addressesKeys.state}`]}</FormHelperText>
                    </Grid>
                </Grid>
            </Box>
            <Box mt={isSmallScreen ? 1 : 2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6}>
                        <Box className={commonClasses.subtitle} mb={1}>Zip Code*</Box>
                        <TextField
                            fullWidth
                            required
                            size="small"
                            id={`${prefix}${addressesKeys.zipCode}`}
                            placeholder="Postal/Zip Code"
                            variant="outlined"
                            InputProps={{ className: 'input', classes }}
                            error={!!errors[`${prefix}${addressesKeys.zipCode}`]}
                            helperText={errors[`${prefix}${addressesKeys.zipCode}`]}
                            value={address.zipCode ?? ""}
                            onChange={(v) => {
                                handleChanges(v.target.id, v.target.value)
                            }}
                            onBlur={() => handleValidate(`${prefix}${addressesKeys.zipCode}`)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Box className={commonClasses.subtitle} mb={1}>Country*</Box>
                        <TextField
                            fullWidth
                            required
                            size="small"
                            id={`${prefix}${addressesKeys.country}`}
                            placeholder="Country"
                            variant="outlined"
                            InputProps={{ className: 'input', classes }}
                            error={!!errors[`${prefix}${addressesKeys.country}`]}
                            helperText={errors[`${prefix}${addressesKeys.country}`]}
                            value={address.country ?? ""}
                            onChange={(v) => {
                                handleChanges(v.target.id, v.target.value)
                            }}
                            onBlur={() => handleValidate(`${prefix}${addressesKeys.country}`)}
                        />
                    </Grid>
                </Grid>
            </Box>
        </form>
    )
}

export const UpdateAddressForm: React.FC<UpdateAddressFormProps> = (props: UpdateAddressFormProps) => {
    const {
        address,
        prefix,
        errors,
        handleChanges,
        handleValidate
    } = props;

    const [
        state,
        selectAddress,
        handleAddressChange,
    ] = useFacade(prefix, address.streetAddress1, handleChanges);
    const classes = useStyles();

    const renderInput = (isLoading: boolean, params: any, error: boolean, onChange: (value: string) => void, helperText?: string, placeholder?: string) => {
        return (
            <TextField
                {...params}
                variant="outlined"
                error={error}
                placeholder={placeholder}
                onChange={(e) => onChange(e.currentTarget.value)}
                helperText={helperText}
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <React.Fragment>
                            {!isLoading ? <CircularProgress style={{ color: colors.main }} size={20} /> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                    classes,
                }}
            />
        )
    }

    const commonClasses = commonUseStyles();

    if (!handleChanges) {
        return (
            <Box height="235px">
                <Box width={1} display="flex">
                    <Box display="inline" width={'100px'} className="text-bold">Address 1: </Box>
                    <Box display="inline">{address.streetAddress1}</Box>
                </Box>
                <Box mt={2} width={1} display="flex">
                    <Box display="inline" width={'100px'} className="text-bold">Address 2: </Box>
                    <Box display="inline">{address.streetAddress2}</Box>
                </Box>
                <Box mt={2} display="flex" justifyContent="space-between">
                    <Box pr={1} width={0.5} display="flex">
                        <Box display="inline" width={'100px'} className="text-bold">City: </Box>
                        <Box display="inline">{address.city}</Box>
                    </Box>
                    <Box pl={1} width={0.5} display="flex">
                        <Box display="inline" width={'100px'} className="text-bold">State: </Box>
                        <Box display="inline">{address.state}</Box>
                    </Box>
                </Box>
                <Box mt={2} display="flex" justifyContent="space-between">

                    <Box pr={1} width={0.5} display="flex">
                        <Box display="inline" width={'100px'} className="text-bold">Zip Code: </Box>
                        <Box display="inline">{address.zipCode}</Box>

                    </Box>
                    <Box pl={1} width={0.5} display="flex">
                        <Box display="inline" width={'100px'} className="text-bold">Country: </Box>
                        <Box display="inline">{address.country}</Box>

                    </Box>
                </Box>
            </Box>
        )
    }

    return (
        <form autoComplete="off" style={{ width: '100%' }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                    <Box mb={1}>
                        <span className={classes.label}>Address <span className={commonClasses.colorMint}>*</span></span>
                    </Box>
                    <Autocomplete
                        size='small'
                        disableClearable
                        value={address.streetAddress1}
                        options={state.suggestions.data.map(i => (`${i.structured_formatting?.main_text}, ${i.structured_formatting?.secondary_text}`))}
                        onChange={(e, value) => selectAddress(value)}
                        renderInput={(params) => renderInput(
                            state.ready,
                            params,
                            !!errors[`${prefix}${addressesKeys.streetAddress1}`],
                            handleAddressChange,
                            errors[`${prefix}${addressesKeys.streetAddress1}`],
                            'Address'
                        )}
                        onBlur={() => handleValidate(`${prefix}${addressesKeys.streetAddress1}`)}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Box mb={1}>
                        <span className={classes.label}>Address 2</span>
                    </Box>
                    <TextField
                        fullWidth
                        size='small'
                        value={address.streetAddress2}
                        variant="outlined"
                        InputProps={{ className: 'input', classes }}
                        placeholder='Apt, Suite, etc (optional)'
                        error={!!errors[`${prefix}${addressesKeys.streetAddress2}`]}
                        onChange={(e) => handleChanges(`${prefix}${addressesKeys.streetAddress2}`, e.currentTarget.value)}
                        helperText={errors[`${prefix}${addressesKeys.streetAddress2}`]}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Box mb={1}>
                        <span className={classes.label}>City <span className={commonClasses.colorMint}>*</span></span>
                    </Box>
                    <TextField
                        fullWidth
                        required
                        size="small"
                        id={`${prefix}${addressesKeys.city}`}
                        placeholder="City"
                        variant="outlined"
                        InputProps={{ className: 'input', classes }}
                        error={!!errors[`${prefix}city`]}
                        helperText={errors[`${prefix}city`]}
                        value={address.city ?? ""}
                        onChange={(v) => {
                            handleChanges(v.target.id, v.target.value)
                        }}
                        onBlur={() => handleValidate(`${prefix}city`)}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <Box mb={1}>
                        <span className={classes.label}>State <span className={commonClasses.colorMint}>*</span></span>
                    </Box>
                    <Autocomplete
                        size='small'
                        disableClearable
                        placeholder="State"
                        classes={{ root: classes.root }}
                        id={`${prefix}${addressesKeys.state}`}
                        options={addressQuery.getStates().filter(x => address.state !== x.name).map(x => x.name)}
                        onChange={(e, value) => handleChanges(`${prefix}${addressesKeys.state}`, value)}
                        value={address.state ?? ""}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                autoComplete='off'
                                variant="outlined"
                                placeholder="State"
                                error={!!errors[`${prefix}${addressesKeys.state}`]}
                                InputProps={{ ...params.InputProps, type: 'search' }}
                            />
                        )}
                        onBlur={() => handleValidate(`${prefix}${addressesKeys.state}`)}
                    />
                    <FormHelperText error>{errors[`${prefix}${addressesKeys.state}`]}</FormHelperText>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <Box mb={1}>
                        <span className={classes.label}>Zip Code <span className={commonClasses.colorMint}>*</span></span>
                    </Box>
                    <TextField
                        fullWidth
                        required
                        size="small"
                        id={`${prefix}${addressesKeys.zipCode}`}
                        placeholder="Zip Code"
                        variant="outlined"
                        InputProps={{ className: 'input', classes }}
                        error={!!errors[`${prefix}${addressesKeys.zipCode}`]}
                        helperText={errors[`${prefix}${addressesKeys.zipCode}`]}
                        value={address.zipCode ?? ""}
                        onChange={(v) => {
                            handleChanges(v.target.id, v.target.value)
                        }}
                        onBlur={() => handleValidate(`${prefix}${addressesKeys.zipCode}`)}
                    />
                </Grid>
            </Grid>
        </form>
    )
}
