import { useEffect, useState } from "react";
import { useHistory } from 'react-router';
import { Subscription } from "recompose";
import { onEmit } from "../../../common/helpers/on-emit";
import { SortingDirection } from "../../../common/sorting/models/sorting-destination";
import { ISortingState } from "../../../common/sorting/models/sorting-state";
import { handleCompare } from "../../../common/sorting/helpers/handle-compare";
import { navigationService } from "../../../../services/navigation.service";
import { IErrorState } from "../../../common/validation/error-state";
import { GoalCommonModel } from "../../../notes/models/notes.models";
import { goalsService } from "../../../notes/services/goals.service";
import { notesQuery } from "../../../notes/stores/notes";
import { createCommonGoalValidator } from "./createCommonGoal.validator";
import { confirmService } from "../../../../services/confirm.service";

export enum SortingSource {
  None,
  Name = "name",
  Category = "category",
  Completion = "completionValue",
  Completed = "isCompleted"
}

interface ManageCommonGoalsComponentState extends ISortingState, IErrorState {
  isLoading: boolean;
  isSubmitting: boolean;
  isOpen: boolean;
  commonGoals: GoalCommonModel[];
  createCommonGoal: GoalCommonModel;
  removingGoalId: number;
}

const initialParmas: GoalCommonModel = {
  id: 0,
  name: '',
  isCompleted: false,
  completionValue: 0,
  interventions: [],
  category: null
}

export function useFacade(): [
  ManageCommonGoalsComponentState,
  (field: string, value: any) => void,
  (source: string, direction: SortingDirection) => void,
  () => void,
  (id: number) => void,
  (id: number) => void,
  () => void
] {
  const history = useHistory();
  const [state, setState] = useState({
    isLoading: true,
    isSubmitting: false,
    isOpen: false,
    commonGoals: [],
    createCommonGoal: initialParmas,
    removingGoalId: 0,
    sortingColumns: [
      { title: 'Name', source: SortingSource.Name, direction: SortingDirection.Asc },
      { title: 'Category', source: SortingSource.Category, direction: SortingDirection.Asc },
      { title: 'Completion Value', source: SortingSource.Completion, direction: SortingDirection.Asc },
      { title: 'Completed', source: SortingSource.Completed, direction: SortingDirection.Desc },
      { title: 'Action', source: null, direction: null },
    ],
    sortingSource: SortingSource.None,
    errors: {}
  } as ManageCommonGoalsComponentState);

  const setDirection = (source: string, direction: SortingDirection) => {
    const itemIndex = state.sortingColumns.findIndex(item => item.source === source);
    state.sortingColumns[itemIndex].direction = direction;
    setState(state => ({ ...state, columns: state.sortingColumns }));
  }

  const handleSorting = (source: string, direction: SortingDirection) => {
    if (state.sortingSource === source) {
      direction = direction === SortingDirection.Asc
        ? SortingDirection.Desc
        : SortingDirection.Asc;

      setDirection(source, direction);
    }
    setState(state => ({
      ...state,
      sortingSource: source,
      commonGoals: state.commonGoals.sort((p1, p2) => handleCompare(p1, p2, direction, source))
    }));
  }

  const handleGoToEdit = (goalId: number) => {
    navigationService.toCommonGoalEdit(history, goalId);
    return;
  }

  const handleChanges = (field: string, value: any) => {
    createCommonGoalValidator.validateAndSetState(state, setState, field, value);

    const params = state.createCommonGoal;
    
    params[field] = value;

    setState({...state, createCommonGoal: Object.assign({}, params)});
  }

  const handleCreateGoal = () => {
    createCommonGoalValidator.validateObjectAndSetState(state, setState, state.createCommonGoal);
    if (!createCommonGoalValidator.stateIsValid(state)) {
      return;
    }

    setState({...state, isSubmitting: true })

    const cb = () => setState(state => ({ ...state, isSubmitting: false, isOpen: false, createCommonGoal: Object.assign({}, initialParmas) }));
    
    goalsService.createCommonGoal(state.createCommonGoal).subscribe(cb, cb)
  }
  
  const handleDeleteGoal = (id: number) => {
    confirmService.confirm(
      'Delete Common Goal',
      'Are you sure you want to delete this goal?',
      'Yes',
      'Cancel',
      'danger')
      .subscribe(() => {
        setState(state => ({
          ...state,
          isSubmitting: true,
          removingGoalId: id
        }));

        const cb = () => setState(state => ({ ...state, isSubmitting: false, removingGoalId: 0 }));
        goalsService.deleteCommonGoal(id).subscribe(cb, cb);
      });
  }

  const handleToggleNewGoal = () => {
    setState((state) => ({
      ...state,
      isOpen: !state.isOpen,
      createCommonGoal: Object.assign({}, initialParmas),
      errors: {}
    }))
  }

  const useEffectCB = () => {
    const subscriptions: Subscription[] = [
      onEmit<GoalCommonModel[]>(notesQuery.commonGoals$, commonGoals => {
        setState(state => ({
          ...state,
          commonGoals,
        }));
      }),
    ];

    const cb = () => setState(state => ({ ...state, isLoading: false }));

    goalsService.getAllCommonGoals().subscribe(cb, cb);

    return () => {
      subscriptions.map(it => it.unsubscribe())
    };
  }

  useEffect(useEffectCB, []);

  return [
    state,
    handleChanges,
    handleSorting,
    handleCreateGoal,
    handleDeleteGoal,
    handleGoToEdit,
    handleToggleNewGoal
  ];
}