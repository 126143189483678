import {
    StartGenericPlaygroundConversationModel
} from "../../models/conversation.models";
import {useState} from "react";
import {conversationsService} from "../../services/conversations.service";

interface StartGenericPlaygroundComponentState {
    isLoading: boolean;
}

export function useFacade(handleCreated: Function): [
    StartGenericPlaygroundComponentState,
    (message: string) => void,
] {
    const [state, setState] = useState({
        isLoading: false
    } as StartGenericPlaygroundComponentState);

    const handleSendMessage = (message: string) => {
        const model: StartGenericPlaygroundConversationModel = {
            prompt: message
        };

        setState(state => ({
            ...state,
            isLoading: true
        }));

        conversationsService.startGenericPlaygroundConversations(model)
            .subscribe(
                (data) => {
                    handleCreated(data);
                }, () => {
                    setState(state => ({
                        ...state,
                        isLoading: false
                    }));
                }
            );
    };

    return [
        state,
        handleSendMessage
    ];
}