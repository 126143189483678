import React from 'react';
import {
    Box,
    Table,
    TableBody,
    TableContainer,
    TableFooter,
    TableHead,
    TableSortLabel,
    Dialog,
    DialogContent,
    IconButton,
    TextField,
    Menu,
    MenuItem
} from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CloseIcon from '@material-ui/icons/Close';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useFacade } from './dnaOrderDropshipComponent.hooks';
import { useStyles } from './dnaOrderDropshipComponent.styles';
import { SearchComponent } from '../../../common/components/searchComponent/SearchComponent';
import { WildHealthContainer } from '../../../common/components/wildHealthContainer/WildHealthContainer';
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { PaginationComponent } from '../../../common/pagination/components/paginationComponent/PaginationComponent';
import { pageSizes } from '../../../common/pagination/models/page-sizes';
import { FeatureComponent } from '../../../common/components/featureFlags/FeatureComponent';
import { FeatureFlag } from '../../../common/components/featureFlags/featureFlags.models';
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import { OrderStatus } from '../../models/orders.models';
import { PatientProfileModel } from '../../../account/models/patientProfile.model';

export const DnaOrderDropshipComponent: React.FC = () => {
    const [
        {
            isLoading,
            isUpdating,
            isOpened,
            anchorEl,
            dnaOrdersView,
            selectedOrder,
            sortingColumns,
            sortingSource,
            pageSize,
            totalCount,
            selectedPage,
            errors
        },
        handleSorting,
        handleSearch,
        handlePageChange,
        handlePageSizeChange,
        getAllAvailablePages,
        handleToggleActions,
        handleDelete,
        handleToggleEditOrder,
        handleChanges,
        handleUpdateOrder,
        isCheckedShippingDetail,
        handlePatientSelect
    ] = useFacade();

    const classes = useStyles();

    const emptyPlaceholder = () => {
        return <span className="text-accent-secondary">(empty)</span>
    }

    const displayValue = (value: string | number) => {
        return value ?? emptyPlaceholder();
    }

    const displayBool = (value: boolean) => {
        if(value) {
            return <span>Yes</span>
        }
        
        return <span></span>
    }

    const renderName = (value: PatientProfileModel) => {
        return value.firstName + " " + value.lastName
    }

    const renderAddress = (value: PatientProfileModel) => {
        return (
            <>
                <div>{value.shippingAddress.streetAddress1}</div>
                <div>{value.shippingAddress.streetAddress2}</div>
                <div>{value.shippingAddress.city}, {value.shippingAddress.state} {value.shippingAddress.zipCode}</div>
            </>
        );
    }

    const renderTable = () => {
        if (isLoading) {
            return <WildHealthLinearProgress/>
        }

        if (!isLoading && (!dnaOrdersView || !dnaOrdersView.length)) {
            return <WildHealthPlaceHolder message="No current orders found"/>
        }

        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <WildHealthTableRow>
                            {
                                sortingColumns.map((item, index) =>
                                    <WildHealthTableCell key={index}>
                                        {item.source
                                            ? <TableSortLabel
                                                id={`dna-orders-sorting-${item.title}`}
                                                active={sortingSource === item.source}
                                                direction={sortingSource === item.source ? item.direction : 'asc'}
                                                onClick={() => handleSorting(item.source, item.direction)}
                                            >
                                                {item.title}
                                            </TableSortLabel>
                                            : item.title
                                        }
                                    </WildHealthTableCell>
                                )
                            }
                            <WildHealthTableCell style={{width: '10%'}}>
                                Action
                            </WildHealthTableCell>
                        </WildHealthTableRow>
                    </TableHead>
                    <TableBody>
                        {
                            dnaOrdersView.map(order =>
                                <WildHealthTableRow key={`dna-order-${order.id}`}>
                                    <WildHealthTableCell> 
                                        <IconButton
                                            id="update-dna-order-dialog-toggle"
                                            disabled={isUpdating || order.status !== OrderStatus.Ordered}
                                            onClick={() => handlePatientSelect(order.patientId)}
                                        >
                                            <OpenInNewIcon />
                                        </IconButton>
                                        {renderName(order.patient)} 
                                        </WildHealthTableCell>
                                    <WildHealthTableCell> {renderAddress(order.patient)} </WildHealthTableCell>
                                    <WildHealthTableCell> {displayBool(order.shouldShipFirstClass)} </WildHealthTableCell>
                                    <WildHealthTableCell> {order.id} </WildHealthTableCell>
                                    <WildHealthTableCell> {displayValue(order.number)} </WildHealthTableCell>
                                    <WildHealthTableCell> {displayValue(order.barcode)} </WildHealthTableCell>
                                    <WildHealthTableCell> {displayValue(order.patientShippingNumber)} </WildHealthTableCell>
                                    <WildHealthTableCell> {displayValue(order.laboratoryShippingNumber)} </WildHealthTableCell>
                                    <WildHealthTableCell>
                                        <IconButton id={`more-actions-${order.id}`} disabled={isUpdating} onClick={(e) => {
                                            handleToggleActions(order.id, e.currentTarget)
                                        }}>
                                            <MoreHorizIcon />
                                        </IconButton>
                                    </WildHealthTableCell>
                                </WildHealthTableRow>
                            )
                        }
                    </TableBody>
                    <TableFooter>
                        <WildHealthTableRow style={{ backgroundColor: "white" }}>
                            <WildHealthTableCell colSpan={7}>
                                <PaginationComponent
                                    pageSizes={pageSizes}
                                    pageSize={pageSize}
                                    selectedPage={selectedPage}
                                    handlePageSizeChange={handlePageSizeChange}
                                    totalCount={totalCount}
                                    availablePages={getAllAvailablePages()}
                                    handlePageSelect={handlePageChange} />
                            </WildHealthTableCell>
                        </WildHealthTableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        )
    }

    return (
        <Box py={2} width={1}>
            <Box my={2} display="flex" alignItems="center" justifyContent="space-between">
                <Box>
                    <FeatureComponent featureFlag={FeatureFlag.Navigation}>
                        <Box className="wh-tw-font-semibold wh-tw-text-2xl wh-tw-black">{PagesNamesService.dnaOrderDropship}</Box>
                    </FeatureComponent>
                </Box>
                <Box>
                    <SearchComponent handleSearch={handleSearch} placeholder="Search by Number, Barcode, Outbound Shipping or Return Shipping"/>
                </Box>
            </Box>
            <WildHealthContainer margin={0} minHeight={'100%'}>
                {renderTable()}
            </WildHealthContainer>
            
            <Menu
                keepMounted
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => {
                    handleToggleActions(0)
                }}
            >
                <MenuItem id="dna-order-edit" onClick={() => {
                    handleToggleEditOrder()
                }}>
                    Edit
                </MenuItem>
                <MenuItem id="dna-order-delete" onClick={() => {
                    handleDelete()
                }}>
                    Delete
                </MenuItem>
            </Menu>
            {selectedOrder && (
                <Dialog
                    onClose={() => handleToggleEditOrder()}
                    className={classes.root}
                    open={isOpened}
                >
                    <DialogContent className={classes.dialogContent}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Box>
                                <span className="wh-tw-text-2xl wh-tw-black wh-tw-font-medium">Edit Order</span>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <IconButton
                                    id="update-dna-order-dialog-close"
                                    className={classes.dialogTitleCloseAction}
                                    onClick={() => handleToggleEditOrder()}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        </Box>
                        <Box mt={5}>
                            <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Provider Order Number*</Box>
                            <TextField
                                id={`number`}
                                fullWidth
                                required
                                size="small"
                                InputProps={{ className: 'input', classes }}
                                variant="outlined"
                                value={selectedOrder.number}
                                error={!!errors['number']}
                                helperText={errors['number']}
                                disabled={!isOpened || isUpdating}
                                onChange={(event) => handleChanges(event.target.id, event.target.value)}
                            />
                        </Box>
                        <Box mt={2}>
                            <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Barcode*</Box>
                            <TextField
                                id={`barcode`}
                                fullWidth
                                required
                                size="small"
                                InputProps={{ className: 'input', classes }}
                                variant="outlined"
                                value={selectedOrder.barcode}
                                error={!!errors['barcode']}
                                helperText={errors['barcode']}
                                disabled={!isOpened || isUpdating}
                                onChange={(event) => handleChanges(event.target.id, event.target.value)}
                            />
                        </Box>
                        <Box mt={2}>
                            <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Outbound Shipping Number*</Box>
                            <TextField
                                id={`patientShippingNumber`}
                                fullWidth
                                required
                                size="small"
                                InputProps={{ className: 'input', classes }}
                                variant="outlined"
                                value={selectedOrder.patientShippingNumber}
                                error={!!errors['patientShippingNumber']}
                                helperText={errors['patientShippingNumber']}
                                disabled={!isOpened || isUpdating}
                                onChange={(event) => handleChanges(event.target.id, event.target.value)}
                            />
                        </Box>
                        <Box mt={2}>
                            <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Return Shipping Number*</Box>
                            <TextField
                                id={`laboratoryShippingNumber`}
                                fullWidth
                                required
                                size="small"
                                InputProps={{ className: 'input', classes }}
                                variant="outlined"
                                value={selectedOrder.laboratoryShippingNumber}
                                error={!!errors['laboratoryShippingNumber']}
                                helperText={errors['laboratoryShippingNumber']}
                                disabled={!isOpened || isUpdating}
                                onChange={(event) => handleChanges(event.target.id, event.target.value)}
                            />
                        </Box>
                        <Box mt={5} display="flex" justifyContent="flex-end" alignItems="center">
                            <Box mx={2}>
                                <WildHealthButton
                                    id="update-dna-order-cancel"
                                    onClick={() => handleToggleEditOrder()}
                                    color="tertiary"
                                    size='medium'
                                    disabled={isUpdating}
                                >
                                    Cancel
                                </WildHealthButton>
                            </Box>
                            <Box>
                                <WildHealthButton
                                    id="update-dna-order-button"
                                    size='medium'
                                    onClick={handleUpdateOrder}
                                    autoFocus
                                    loading={isUpdating}
                                >
                                    {isCheckedShippingDetail() ? 'Ship' : 'Update Order'}
                                </WildHealthButton>
                            </Box>
                        </Box>
                    </DialogContent>
                </Dialog>
            )}
        </Box>
    );
}