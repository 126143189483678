import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontSize: 20,
            fontWeight: 500
        },
        subTitle: {
            fontSize: 18,
            fontWeight: 500
        },
        inputLabel: {
            fontSize: 14,
            color: colors.gray1
        },
        bgMain: {
            backgroundColor: colors.bg,
            width: '100%'
        },
    })
);
