import { GeneralValidator } from "../../../common/validation/general-validator";
import {
  notWhitespaceValidationRule,
  requiredValidationRule,
  numberValidationRule,
} from "../../../common/validation/general-validation-rules";

export const opsAdminToolsComponentValidator = new GeneralValidator({
  'patientId': [numberValidationRule, notWhitespaceValidationRule(),requiredValidationRule()],
  'subscriptionId':[notWhitespaceValidationRule(),requiredValidationRule()],
  'productType': [requiredValidationRule()],
});