import { Box, Grid } from '@material-ui/core';
import React from 'react';
import {AppointmentConfigurationModel, NoteModel} from "../../../models/notes.models";
import { CreateNote } from '../../createNote/CreateNote';
import { useFacade } from './createInitialConsultComponentOld.hooks';
import { useStyles } from '.././createInitialConsultComponent.styles';

export interface InitialConsultComponentProps {
    note?: NoteModel,
    patientId: number;
    appointmentId?: number | null;
    appointmentConfiguration: AppointmentConfigurationModel | null;
    handleGoBack: (boolean?) => void;
    goBackTitle: string;
    originalNote?: NoteModel;
}

export const CreateInitialConsultComponentOld: React.FC<InitialConsultComponentProps> = (props: InitialConsultComponentProps) => {
    const {
        note,
        patientId,
        appointmentId,
        handleGoBack,
        goBackTitle,
        originalNote
    } = props;

    const classes = useStyles();
    const [
        {
            isLoading,
        },
        internalNotes,
        questionnaire,
        medicationSupplements,
        scriptsGoal,
        recommendationsHeader,
        recommendations,
        endOfTemplate,
        noteName,
        titleTextBox,
    ] = useFacade(patientId, note, classes, handleGoBack, appointmentId, originalNote);

    return (
        <Box m={5} mt={0} pt={3}>
            <CreateNote
                isLoading={isLoading}
                noteName={noteName}
                handleGoBack={handleGoBack}
                goBackTitle={goBackTitle}
                titleTextBox={titleTextBox}
            >
                <Box mt={5}>
                    <Grid
                        container
                        spacing={5}
                        direction='row'
                        justify='center'
                        alignItems='flex-start'
                    >
                        <Grid item xs={7}>
                            {internalNotes}
                            {questionnaire}
                            {medicationSupplements}
                        </Grid>

                        <Grid item xs={5}>
                            {
                                scriptsGoal
                            }
                        </Grid>

                        {recommendationsHeader}
                        {recommendations}

                        <Grid item xs={12}>
                            {endOfTemplate}
                        </Grid>
                    </Grid>
                </Box>
            </CreateNote>
        </Box>
    );
}
