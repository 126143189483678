import React from 'react';
import {
    Box,
    FormControl,
    FormHelperText,
    Grid,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import placeholderImg from "@img/placeholders/EmptyReport.png";
import { useStyles } from './manageShortcutsComponent.styles';
import { useFacade } from "./manageShortcutsComponent.hooks";
import { PermissionType } from "../../../../auth/models/auth.enums";
import { ProtectedElement } from "../../../../common/components/ProtectedElement";
import WildHealthLinearProgress from '../../../../common/components/WildHealthLinearProgress';
import { CreateShortcutComponent } from "../../../components/shortcuts/createShortcutComponent/CreateShortcutComponent";
import { CreateShortcutGroupComponent } from "../../../components/shortcuts/createShortcutGroupComponent/CreateShortcutGroupComponent";
import { ShortcutAuditInformationComponent } from "../../../components/shortcuts/shortcutAuditInformationComponent/ShortcutAuditInformationComponent";
import { WildHealthButton } from "../../../../common/components/wildHealthButton/WildHealthButton";
import { WildHealthPlaceHolderBase } from '../../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolderBase';
import { ShortcutTabsComponent } from '../ShortcutTabsComponent/ShortcutTabsComponent';

export const ManageShortcutsComponent: React.FC = () => {
    const [
        {
            isLoading,
            groups,
            errors,
            targetShortcut,
            createShortcut,
            createShortcutGroup,
            isSaving,
            isDeleting
        },
        handleShortcutCreate,
        handleShortcutCreated,
        handleShortcutGroupCreate,
        handleShortcutGroupCreated,
        handleChanges,
        handleSaveChanges,
        handleShortcutDelete
    ] = useFacade();

    const classes = useStyles();

    if (isLoading) {
        return <WildHealthLinearProgress />
    }

    const renderGroupsList = () => {
        return (
            <Box className={classes.groupList} pt={2}>
                <ShortcutTabsComponent />
            </Box>
        )
    }

    return (
        <Box display="flex" width={1} className="wh-tw-bg-white">
            {renderGroupsList()}
            <Box flex={1} px={4} py={2.5}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box className="wh-tw-text-xl wh-tw-font-medium">{targetShortcut && targetShortcut.displayName}</Box>
                    <ProtectedElement
                        element={
                            <Box display="flex" alignItems="center" gridGap={12}>
                                <WildHealthButton
                                    id="manage-shortcuts-create-group"
                                    color="secondary"
                                    borderRadius={4}
                                    disabled={isSaving || isDeleting}
                                    size="large"
                                    onClick={() => handleShortcutGroupCreate()}
                                >
                                    <span> Create Shortcut Group </span>
                                </WildHealthButton>
                                <WildHealthButton
                                    id="manage-shortcuts-create"
                                    size="large"
                                    borderRadius={4}
                                    disabled={isSaving || isDeleting}
                                    onClick={() => handleShortcutCreate()}
                                >
                                    <span> Create Shortcut </span>
                                </WildHealthButton>
                            </Box>}
                        permissions={[PermissionType.Shortcuts]}
                    />
                </Box>
                {
                    targetShortcut 
                        ? <Box pt={4}>
                            <ProtectedElement
                                element={
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6}>
                                            <Box>
                                                <Box className="wh-tw-text-xxs wh-tw-text-black wh-tw-font-medium wh-tw-uppercase" mb={1.5}>Label</Box>
                                                <Box>
                                                    <TextField
                                                        id="displayName"
                                                        size="small"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputProps={{ className: 'input' }}
                                                        error={!!errors['displayName']}
                                                        helperText={errors['displayName']}
                                                        value={targetShortcut?.displayName ?? ''}
                                                        disabled={isSaving || isDeleting}
                                                        onChange={(v) => {
                                                            handleChanges(v.target.id, v.target.value)
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Box>
                                                <Box className="wh-tw-text-xxs wh-tw-text-black wh-tw-font-medium wh-tw-uppercase" mb={1.5}>Search term</Box>
                                                <Box>
                                                    <TextField
                                                        id="name"
                                                        size="small"
                                                        variant="outlined"
                                                        fullWidth
                                                        InputProps={{ className: 'input' }}
                                                        error={!!errors['name']}
                                                        helperText={errors['name']}
                                                        value={targetShortcut?.name ?? ''}
                                                        disabled={isSaving || isDeleting}
                                                        onChange={(v) => {
                                                            handleChanges(v.target.id, v.target.value)
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Box>
                                                <Box className="wh-tw-text-xxs wh-tw-text-black wh-tw-font-medium wh-tw-uppercase" mb={1.5}>Group</Box>
                                                <Box>
                                                    <FormControl
                                                        size="small"
                                                        color='primary'
                                                        fullWidth
                                                        error={!!errors['groupId']}
                                                        variant="outlined">
                                                        <Select
                                                            style={{ background: '#FFF' }}
                                                            labelId="select-group-label"
                                                            id="groupId"
                                                            value={targetShortcut?.groupId ?? ''}
                                                            disabled={isSaving || isDeleting}
                                                            onChange={(v) => handleChanges('groupId', (v.target.value) as string)}>
                                                            {
                                                                groups.map((group) => {
                                                                    return <MenuItem
                                                                        key={group.id}
                                                                        value={group.id}>
                                                                        {group.name}
                                                                    </MenuItem>
                                                                })
                                                            }
                                                        </Select>
                                                        <FormHelperText>{errors['groupId']}</FormHelperText>
                                                    </FormControl>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6}></Grid>
                                        <Grid item xs={12} md={6}>
                                            <Box>
                                                <Box className="wh-tw-text-xxs wh-tw-text-black wh-tw-font-medium wh-tw-uppercase" mb={1.5}>Shortcut content</Box>
                                                <Box>
                                                    <TextField
                                                        style={{ background: '#FFF' }}
                                                        id="content"
                                                        variant="outlined"
                                                        fullWidth
                                                        multiline
                                                        rows={10}
                                                        rowsMax={10}
                                                        InputProps={{ className: 'input' }}
                                                        error={!!errors['content']}
                                                        helperText={errors['content']}
                                                        value={targetShortcut?.content ?? ''}
                                                        disabled={isSaving || isDeleting}
                                                        onChange={(v) => {
                                                            handleChanges(v.target.id, v.target.value)
                                                        }}>
                                                    </TextField>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6}></Grid>
                                        <Grid item xs={12} md={12}>
                                            <Box display="flex" alignItems="center" gridGap={12}>
                                                <WildHealthButton
                                                    id="manage-shortcuts-save"
                                                    size="large"
                                                    borderRadius={4}
                                                    loading={isSaving}
                                                    disabled={isDeleting}
                                                    onClick={() => handleSaveChanges()}
                                                >
                                                    <span className="select-button"> Save </span>
                                                </WildHealthButton>
                                                <WildHealthButton
                                                    id="manage-shortcuts-delete"
                                                    size="large"
                                                    color="danger"
                                                    borderRadius={4}
                                                    loading={isDeleting}
                                                    disabled={isSaving}
                                                    onClick={() => handleShortcutDelete()}
                                                >
                                                    <span> Delete </span>
                                                </WildHealthButton>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                }
                                defaultContent={
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={5}>
                                            <Box>
                                                <Box className="wh-tw-text-xxs wh-tw-text-gray400 wh-tw-font-medium wh-tw-uppercase" mb={1.5}>LABEL</Box>
                                                <Box className="wh-tw-text-lg wh-tw-text-black">
                                                    {targetShortcut?.displayName ?? ''}
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={7}>
                                            <Box>
                                                <Box className="wh-tw-text-xxs wh-tw-text-gray400 wh-tw-font-medium wh-tw-uppercase" mb={1.5}>Search term</Box>
                                                <Box className="wh-tw-text-lg wh-tw-text-black">
                                                    {targetShortcut?.name ?? ''}
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Box>
                                                <Box className="wh-tw-text-xxs wh-tw-text-gray400 wh-tw-font-medium wh-tw-uppercase" mb={1.5}>Group</Box>
                                                <Box className="wh-tw-text-lg wh-tw-text-black">
                                                    {groups.find(g => g.id === targetShortcut?.groupId)?.name ?? ''}
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Box>
                                                <Box className="wh-tw-text-xxs wh-tw-text-gray400 wh-tw-font-medium wh-tw-uppercase" mb={1.5}>Shortcut content</Box>
                                                <Box className="wh-tw-text-lg wh-tw-text-black">
                                                    {targetShortcut?.content ?? ''}
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                }
                                permissions={[PermissionType.Shortcuts]}
                            />
                            <Box display="flex" justifyContent="flex-end" mt={7}>
                                <ShortcutAuditInformationComponent shortcut={targetShortcut} />
                            </Box>
                        </Box>
                        : <Box className={classes.groups} display="flex" justifyContent="center" flex={1}>
                            <WildHealthPlaceHolderBase message="Please select a shortcut first to display it here.">
                                <img
                                    src={placeholderImg}
                                    alt="img"
                                    className={classes.image} />
                            </WildHealthPlaceHolderBase>
                        </Box>
                }
            </Box>
            <CreateShortcutComponent
                open={createShortcut}
                handleClose={() => handleShortcutCreated()}
                handleSubmit={() => handleShortcutCreated()} />

            <CreateShortcutGroupComponent
                open={createShortcutGroup}
                handleClose={() => handleShortcutGroupCreated()}
                handleSubmit={() => handleShortcutGroupCreated()}
            />
        </Box>
    )
};
