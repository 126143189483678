import React from 'react';
import { AuthenticatedLayoutComponent } from '../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from "../../../../models/menu.models";
import { ManagePromoCodesComponent } from '../../components/managePromoCodesComponent/ManagePromoCodesComponent';

export const ManagePromoCodesPage: React.FC = () => {
    return (
        <AuthenticatedLayoutComponent
            hideBackground
            title={TitlesService.promoCodesTitle}
            pageName={PagesNamesService.promoCodes}
            selectedMenuItem={MenuItemTypes.PromoCodes}
        >
            <ManagePromoCodesComponent />
        </AuthenticatedLayoutComponent>
    )
}
