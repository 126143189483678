import moment from "moment";
import pdfMake from 'pdfmake/build/pdfmake';
import {Margins, PageSize} from 'pdfmake/interfaces';
import {logoService} from "../../../services/logo.service";
import {authQuery} from "../../auth/stores/auth";
import {toTimeZoneDate} from "../../timezones/helpers/timezone";
import {ReferralOrderModel} from "../models/referralOrders.models";
import {IcdCode} from "../../notes/models/notes.models";
import {OtherOrderModel} from "../models/otherOrders.models";
import {OrderStatus} from "../models/orders.models";
import {timezonesQuery} from "../../timezones/stores/timezones";
import {EmployeeShortModel} from "../../employee/models/employee.models";
import {pinyonFont} from "../../../styles/Pinyon-Script";

const pdfMakeX = require('pdfmake/build/pdfmake.js');
const pdfFontsX = require('pdfmake-unicode/dist/pdfmake-unicode.js');

pdfMakeX.vfs = pdfFontsX.pdfMake.vfs;
window.pdfMake.vfs["Pinyon-Script.ttf"] = pinyonFont;

pdfMake.fonts = {
    Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-Italic.ttf'
    },
    PinyonScript: {
        normal: 'Pinyon-Script.ttf',
        bold: 'Pinyon-Script.ttf',
        italics: 'Pinyon-Script.ttf',
        bolditalics: 'Pinyon-Script.ttf'
    }
}

export abstract class OrdersPdfService {
    protected pdfDocument = {
        pageSize: "LETTER" as PageSize,
        pageMargins: [60, 40, 60, 50] as Margins,
        header: null,
        footer: function (currentPage, pageCount) {
            if (currentPage === pageCount) {
                const result = {
                    table: {
                        widths: ['*'],
                        body: []
                    },
                    layout: 'noBorders'
                }

                if (authQuery.isDefaultPractice()) {
                    result.table.body.push([
                        {
                            text: 'p: 800.975.1562 | e: info@wildhealth.com | a: 535 Wellington Way # 330 Lexington KY 40503',
                            alignment: 'center',
                            style: 'footerText',
                        }

                    ]);
                }

                result.table.body.push([{
                    text: [
                        {
                            text: 'Privacy Policy',
                            style: 'footerText',
                            link: 'https://www.wildhealth.com/privacy-policy'
                        },
                        {
                            text: ' | ',
                            style: 'footerText'
                        },
                        {
                            text: 'Terms of Use',
                            style: 'footerText',
                            link: 'https://www.wildhealth.com/terms-of-use'
                        },
                        {
                            text: ' | ',
                            style: 'footerText'
                        },
                        {
                            text: 'Copyright ' + new Date().getFullYear(),
                            style: 'footerText'
                        },

                    ],
                    alignment: 'center',
                    style: 'footerText'
                }]);

                return result;
            }
        },
        content: [],
        styles: {
            invoiceTitle: {
                fontFamily: 'Roboto',
                fontWeight: 'bold',
                fontSize: 24,
            },
            invoiceSubTitle: {
                fontFamily: 'Open Sans',
                fontWeight: 600,
                fontSize: 18,
                color: '#7C8A9D',
            },
            companyTitle: {
                color: '#7C8A9D',
                fontFamily: 'Roboto',
                fontWeight: 400,
                fontSize: 14,
            },
            companySubTitle: {
                fontFamily: 'Roboto',
                fontWeight: 500,
                fontSize: 16,
            },
            tableHeader: {
                fontFamily: 'Roboto',
                fontWeight: 500,
                fontSize: 14,
                color: '#AFBACA',
            },
            tableValue: {
                fontFamily: 'Roboto',
                fontWeight: 500,
                fontSize: 14,
                color: '##091D36',
            },
            total: {
                fontFamily: 'Open Sans',
                fontWeight: 600,
                fontSize: 24,
                padding: '10px 0px',
            },
            signedByText: {
                font: 'PinyonScript',
                fontWeight: 'normal',
                fontSize: 24,
            },
        },
    }

    constructor() {
        const self = this;
        var image = new Image();
        image.src = logoService.getLogo().clarityHeader;
        image.onload = function () {
            var canvas, ctx, dataURL, base64;
            canvas = document.createElement("canvas");
            ctx = canvas.getContext("2d");
            canvas.width = image.width;
            canvas.height = image.height;
            ctx.drawImage(image, 0, 0);
            dataURL = canvas.toDataURL("image/png");
            base64 = dataURL.replace(/^data:image\/png;base64,/, "");

            self.pdfDocument.header = function (currentPage) {
                if (currentPage === 1)
                    return [
                        {
                            image: `data:image/jpeg;base64,${base64}`,
                            fit: [615, 100],
                        }
                    ]
            }
        };
    }

    protected clear() {
        this.pdfDocument.content = [];
    }

    protected renderSignature(pdfDocument, signedBy: EmployeeShortModel, signetAt: Date) {
        const timezone = timezonesQuery.getMyTimezone();
        const completedAt = moment(toTimeZoneDate(signetAt, timezone));
        const completedBy = signedBy.firstName + ' ' + signedBy.lastName;

        pdfDocument.content.push({
            text: `${completedBy}`,
            alignment: "right",
            style: "signedByText",
            margin: [0, 36, 0, 0]
        });

        pdfDocument.content.push({
            text: `Electronically signed by ${completedBy}`,
            alignment: "right",
            style: "text",
            margin: [0, 5, 0, 0]
        });

        pdfDocument.content.push({
            text: "On " + completedAt.format('ll') + ", at " + completedAt.format('LT'),
            alignment: "right",
            style: "text",
            margin: [0, 5, 0, 0]
        });
    }
}

export class ReferralOrderPdfService extends OrdersPdfService {

    public download(order: ReferralOrderModel) {
        this.buildPdfContent(order)
        pdfMake.createPdf(this.pdfDocument).download(`Referral-Request-${order.id}`);
    }

    private buildPdfContent(order: ReferralOrderModel) {
        this.clear();

        const signedAt = order.statusLogs.find(x => x.status === OrderStatus.Signed).date;
        const allDiagnoses = JSON.parse(order.data.diagnosis) as IcdCode[];
        const primaryDiagnosis = allDiagnoses.find(x => x.id === +order.data.primaryDiagnosesId);
        const otherDiagnoses = allDiagnoses.filter(x => x.id !== +order.data.primaryDiagnosesId);

        let primaryDiagnosisInline = primaryDiagnosis
            ? `${primaryDiagnosis.code} ${primaryDiagnosis.description};`
            : null;

        let otherDiagnosesInline = '';
        otherDiagnoses.forEach(x => {
            otherDiagnosesInline += `${x.code} ${x.description}; `
        })

        let allDiagnosesCodes = '';
        allDiagnoses.forEach(x => {
            allDiagnosesCodes += `${x.code} ${x.description}; `
        })

        const body = [
            [
                [
                    {
                        margin: [0, 70, 0, 0],
                        text: 'Referral Form',
                        style: 'invoiceTitle',
                    },

                    {
                        table: {
                            widths: ['*', '*'],
                            body: [
                                [
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: 'Patient Name:',
                                            style: 'companyTitle',
                                        }
                                    ],
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: order.data.patientName,
                                            style: 'companySubTitle',
                                        },
                                    ]
                                ],
                                [
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: 'Date of Birth:',
                                            style: 'companyTitle',
                                        }
                                    ],
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: moment(order.data.dateOfBirth).format('LL'),
                                            style: 'companySubTitle',
                                        },
                                    ]
                                ],
                                [
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: 'Insurance Provider:',
                                            style: 'companyTitle',
                                        }
                                    ],
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: order.data.insuranceProvider,
                                            style: 'companySubTitle',
                                        },
                                    ]
                                ],
                                [
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: 'Insurance ID Number:',
                                            style: 'companyTitle',
                                        }
                                    ],
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: order.data.insuranceId,
                                            style: 'companySubTitle',
                                        },
                                    ]
                                ],
                                [
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: 'Referral Requested:',
                                            style: 'companyTitle',
                                        }
                                    ],
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: order.items[0].description,
                                            style: 'companySubTitle',
                                        },
                                    ]
                                ],
                                [
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: 'Clinical Reason for Referral:',
                                            style: 'companyTitle',
                                        }
                                    ],
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: order.data.clinicalReasonForReferral,
                                            style: 'companySubTitle',
                                        },
                                    ]
                                ],
                                primaryDiagnosis ? [
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: 'Primary Diagnosis:',
                                            style: 'companyTitle',
                                        }
                                    ],
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: primaryDiagnosisInline,
                                            style: 'companySubTitle',
                                        },
                                    ]
                                ] : [
                                    [
                                        {
                                            margin: [0, 0, 0, 0],
                                            text:  null,
                                            style: 'companyTitle',
                                        }
                                    ],
                                    [
                                        {
                                            margin: [0, 0, 0, 0],
                                            text:  null,
                                            style: 'companySubTitle',
                                        },
                                    ]
                                ],
                                primaryDiagnosis ?
                                    [
                                        [
                                            {
                                                margin: [0, 10, 0, 0],
                                                text: 'Additional Diagnoses:',
                                                style: 'companyTitle',
                                            }
                                        ],
                                        [
                                            {
                                                margin: [0, 10, 0, 0],
                                                text: otherDiagnosesInline,
                                                style: 'companySubTitle',
                                            },
                                        ]
                                    ] : [
                                        [
                                            {
                                                margin: [0, 10, 0, 0],
                                                text: 'Diagnosis Code:',
                                                style: 'companyTitle',
                                            }
                                        ],
                                        [
                                            {
                                                margin: [0, 10, 0, 0],
                                                text: allDiagnosesCodes,
                                                style: 'companySubTitle',
                                            },
                                        ]
                                    ],
                                [
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: 'Urgency (Time to appt):',
                                            style: 'companyTitle',
                                        }
                                    ],
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: order.data.urgencyTimeToAppointment,
                                            style: 'companySubTitle',
                                        },
                                    ]
                                ],
                                [
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: 'Patient Preference:',
                                            style: 'companyTitle',
                                        }
                                    ],
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: order.data.patientPreferences,
                                            style: 'companySubTitle',
                                        },
                                    ]
                                ],
                            ],
                        },
                        layout: 'noBorders'
                    },
                ]
            ],
        ];

        this.pdfDocument.content.push({
            table: {
                margin: [0, 30, 0, 0],
                widths: ['*', '150'],
                body: body,
            },
            layout: 'noBorders'
        });

        this.renderSignature(this.pdfDocument, order.signedBy, signedAt);
    }
}


export const referralOrderPdfService = new ReferralOrderPdfService();


export class OtherOrderPdfService extends OrdersPdfService {

    public download(order: OtherOrderModel) {
        this.buildPdfContent(order)
        pdfMake.createPdf(this.pdfDocument).download(`Diagnostic-Order-${order.id}`);
    }

    private buildPdfContent(order: OtherOrderModel) {
        this.clear();

        const signedAt = order.statusLogs.find(x => x.status === OrderStatus.Signed).date;
        const allDiagnoses = JSON.parse(order.data.diagnosisCode) as IcdCode[];
        const primaryDiagnosis = allDiagnoses.find(x => x.id === +order.data.primaryDiagnosesId);
        const otherDiagnoses = allDiagnoses.filter(x => x.id !== +order.data.primaryDiagnosesId);

        let primaryDiagnosisInline = primaryDiagnosis
            ? `${primaryDiagnosis.code} ${primaryDiagnosis.description};`
            : null;

        let otherDiagnosesInline = '';
        otherDiagnoses.forEach(x => {
            otherDiagnosesInline += `${x.code} ${x.description}; `
        })

        let allDiagnosesCodes = '';
        allDiagnoses.forEach(x => {
            allDiagnosesCodes += `${x.code} ${x.description}; `
        })

        const body = [
            [
                [
                    {
                        margin: [0, 70, 0, 0],
                        text: 'Provider Order Form',
                        style: 'invoiceTitle',
                    },

                    {
                        table: {
                            widths: ['*', '*'],
                            body: [
                                [
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: 'Patient Name:',
                                            style: 'companyTitle',
                                        }
                                    ],
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: order.data.patientName,
                                            style: 'companySubTitle',
                                        },
                                    ]
                                ],
                                [
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: 'Date of Birth:',
                                            style: 'companyTitle',
                                        }
                                    ],
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: moment(order.data.dateOfBirth).format('LL'),
                                            style: 'companySubTitle',
                                        },
                                    ]
                                ],
                                [
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: 'Patient Address:',
                                            style: 'companyTitle',
                                        }
                                    ],
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: order.data.patientAddress,
                                            style: 'companySubTitle',
                                        },
                                    ]
                                ],
                                [
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: 'Insurance Carrier:',
                                            style: 'companyTitle',
                                        }
                                    ],
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: order.data.insuranceProvider,
                                            style: 'companySubTitle',
                                        },
                                    ]
                                ],
                                [
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: 'Insurance ID:',
                                            style: 'companyTitle',
                                        }
                                    ],
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: order.data.insuranceId,
                                            style: 'companySubTitle',
                                        },
                                    ]
                                ],
                                [
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: 'Ordering Provider:',
                                            style: 'companyTitle',
                                        }
                                    ],
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: order.data.orderingProvider,
                                            style: 'companySubTitle',
                                        },
                                    ]
                                ],
                                [
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: 'Test being ordered:',
                                            style: 'companyTitle',
                                        }
                                    ],
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: order.items[0].description,
                                            style: 'companySubTitle',
                                        },
                                    ]
                                ],
                                primaryDiagnosis ? [
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: 'Primary Diagnosis:',
                                            style: 'companyTitle',
                                        }
                                    ],
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: primaryDiagnosisInline,
                                            style: 'companySubTitle',
                                        },
                                    ]
                                ] : [
                                    [
                                        {
                                            margin: [0, 0, 0, 0],
                                            text:  null,
                                            style: 'companyTitle',
                                        }
                                    ],
                                    [
                                        {
                                            margin: [0, 0, 0, 0],
                                            text:  null,
                                            style: 'companySubTitle',
                                        },
                                    ]
                                ],
                                primaryDiagnosis ?
                                    [
                                        [
                                            {
                                                margin: [0, 10, 0, 0],
                                                text: 'Additional Diagnoses:',
                                                style: 'companyTitle',
                                            }
                                        ],
                                        [
                                            {
                                                margin: [0, 10, 0, 0],
                                                text: otherDiagnosesInline,
                                                style: 'companySubTitle',
                                            },
                                        ]
                                    ] : [
                                        [
                                            {
                                                margin: [0, 10, 0, 0],
                                                text: 'Diagnosis Code:',
                                                style: 'companyTitle',
                                            }
                                        ],
                                        [
                                            {
                                                margin: [0, 10, 0, 0],
                                                text: allDiagnosesCodes,
                                                style: 'companySubTitle',
                                            },
                                        ]
                                    ],
                                [
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: 'Reason for Order:',
                                            style: 'companyTitle',
                                        }
                                    ],
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: order.data.reasonForOrder,
                                            style: 'companySubTitle',
                                        },
                                    ]
                                ],
                                [
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: 'Urgency (Time toAppointment):',
                                            style: 'companyTitle',
                                        }
                                    ],
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: order.data.urgencyTimeToAppointment,
                                            style: 'companySubTitle',
                                        },
                                    ]
                                ],
                                [
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: 'Preferred Location:',
                                            style: 'companyTitle',
                                        }
                                    ],
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: order.data.preferredLocation,
                                            style: 'companySubTitle',
                                        },
                                    ]
                                ]
                            ],
                        },
                        layout: 'noBorders'
                    },
                ]
            ],
        ];

        this.pdfDocument.content.push({
            table: {
                margin: [0, 30, 0, 0],
                widths: ['*', '150'],
                body: body,
            },
            layout: 'noBorders'
        });

        this.renderSignature(this.pdfDocument, order.signedBy, signedAt);
    }
}


export const otherOrderPdfService = new OtherOrderPdfService();