import { addressValidationRules, notWhitespaceValidationRule, phoneNumberValidationRule, stringLengthValidationRule } from "../../../../common/validation/general-validation-rules";
import { GeneralValidator } from "../../../../common/validation/general-validator";

export const patientPharmacyInfomationValidator = new GeneralValidator({
    'name': [notWhitespaceValidationRule(), stringLengthValidationRule(1, 50)],
    'phone': [phoneNumberValidationRule],
    'streetAddress': [addressValidationRules.streetAddress1, notWhitespaceValidationRule()],
    'city': [addressValidationRules.city, notWhitespaceValidationRule()],
    'state': [addressValidationRules.state, notWhitespaceValidationRule()],
    'country': [addressValidationRules.country, notWhitespaceValidationRule()],
    'zipCode': [addressValidationRules.zipCode, notWhitespaceValidationRule()]
});
