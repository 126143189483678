import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { onEmit } from "../../../common/helpers/on-emit";
import { FellowshipNoteModel, PracticumPatientModel } from "../../../patients/models/patient.model";
import { patientsQuery } from "../../../patients/stores/patientsStore/patients.query";
import { patientsService } from "../../../patients/services/patients.service";
import { ISortingState } from "../../../common/sorting/models/sorting-state";
import { SortingSource } from "../../models/fellowship.enums";
import { SortingDirection } from "../../../common/sorting/models/sorting-destination";
import { handleCompare } from "../../../common/sorting/helpers/handle-compare";
import { confirmService } from "../../../../services/confirm.service";

export interface PracticumPatientsComponentState extends ISortingState {
    practicumPatients: PracticumPatientModel[];
    originalPracticumPatients: PracticumPatientModel[];
    isLoading: boolean;
    hideTestPatients: boolean;
}

export function useFacade(rosterId: number): [
    PracticumPatientsComponentState,
    (id: number, text: string) => void,
    (source: string, direction: SortingDirection) => void,
    (status: boolean) => void
] {
    const [state, setState] = useState({
        practicumPatients: [],
        originalPracticumPatients: [],
        isLoading: false,
        sortingColumns: [
            { title: 'Enrolled', source: SortingSource.Enrollment, direction: SortingDirection.Asc },
        ],
        sortingSource: null,
        hideTestPatients: false,
    } as PracticumPatientsComponentState);

    const setDirection = (source: string, direction: SortingDirection) => {
        const itemIndex = state.sortingColumns.findIndex(item => item.source === source);
        state.sortingColumns[itemIndex].direction = direction;
        setState(state => ({ ...state, columns: state.sortingColumns }));
    }

    const handleOpenNote = (id: number, text: string) => {
        confirmService.confirmWithReason('Note', '', '', 'Save', `${text ? text : ''}`).subscribe((text) => handleNewNote(id, text));
    }

    const handleNewNote = (id: number, text: string) => {
        const patient = state.practicumPatients.find(p => p.id === id)
        patient.note = text
        
        setState(state => ({ ...state }));
        const model = {
            patientId: id,
            note: text
        } as FellowshipNoteModel

        patientsService.updateFellowshipNote(model);
    }

    const handleSorting = (source: string, direction: SortingDirection) => {
        if (state.sortingSource === source) {
            direction = direction === SortingDirection.Asc
                ? SortingDirection.Desc
                : SortingDirection.Asc;

            setDirection(source, direction);
        }

        setState(state => ({
            ...state,
            sortingSource: source,
            patients: state.practicumPatients.sort((p1, p2) => handleCompare(p1, p2, direction, source))
        }));
    }

    const handleToggleTestPatients = (status: boolean) => {
        setState({
            ...state,
            hideTestPatients: status,
            practicumPatients: status ? state.practicumPatients.filter(patient => patient.phoneNumber !== '(111) 111-1111') : state.originalPracticumPatients
        });
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<PracticumPatientModel[]>(patientsQuery.practicumPatients$, patients => {
                setState(state => ({
                    ...state,
                    practicumPatients: patients,
                    originalPracticumPatients: patients,
                    isLoading: false
                }));
            }),
        ];

        patientsService.getPracticumPatientsByRoster(rosterId);
        setState(state => ({ ...state, isLoading: true }));

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [rosterId])

    return [state, handleOpenNote, handleSorting, handleToggleTestPatients]
}