import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {colors} from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            [theme.breakpoints.down('sm')]: {
                padding: 0
            },
        },
        templatesListRoot: {
            minHeight: 550,
            maxHeight: 550,
            overflowY: "auto",
        },
        template: {
            padding: 12,
            borderRadius: 6,
            cursor: 'pointer',
            border: `1px solid ${colors.dividers}`,
            '&:hover': {
                background: colors.mint50,
                border: `1px solid ${colors.mint600}`,
                boxShadow: '0px 0px 10px 1px rgba(105,168,153,0.75)'
            },
        },
        selectedTemplate: {
            padding: 12,
            borderRadius: 6,
            cursor: 'pointer',
            background: colors.mint50,
            border: `1px solid ${colors.mint600}`,
            '&:hover': {
                boxShadow: '0px 0px 10px 1px rgba(105,168,153,0.75)'
            },
        },
        templateName: {
            fontSize: 18,
            fontWeight: 500
        },
        templateDescription: {
            fontSize: 14,
            fontWeight: 400,
            color: colors.gray600
        }
    })
);


