import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Subscription } from "recompose";
import { onEmit } from "../../../common/helpers/on-emit";
import { IErrorState } from "../../../common/validation/error-state";
import {
    AppointmentTypeConfigurationModel,
    AppointmentTypeModel,
    CreateAppointmentModel,
    PatientAppointmentModel
} from "../../models/appointments.models";
import { AvailabilityModel, EmployeeAvailabilityModel } from "../../models/availability.models";
import { appointmentsService } from "../../services/appointments.service";
import { availabilityService } from "../../services/availability.service";
import { availabilityQuery } from "../../stores/availability/availability.query";
import { coachAppointmentValidator } from "../coachCreateAppoinmentDialog/coachAppointment.validator";
import { authQuery } from "../../../auth/stores/auth";
import { UserType } from "../../../auth/models/auth.enums";
import { timezonesQuery } from "../../../timezones/stores/timezones";
import { timezonesService } from "../../../timezones/services/timezones.service";
import { TimeZoneModel } from "../../../timezones/models/timezone.model";
import { getCurrentTimezone } from "../../../timezones/helpers/timezone";
import { appointmentsQuery } from "../../stores/appointments";
import {
    getAvailabilityEndDate,
    getAvailabilityStartDate
} from "../../helpers/appointmentHelper";
import { patientsQuery } from "../../../patients/stores/patientsStore";
import {availabilityStore} from "../../stores/availability/availability.store";
import { AppointmentReasonType, AppointmentReasonTypeNames } from "../../models/appointments.enums";
import { GeneralValidator } from "../../../common/validation/general-validator";
import { source } from "../../constants/appointment.constants";

interface RescheduleAppointmentComponentState extends IErrorState {
    open: boolean;

    appointment: PatientAppointmentModel;
    appointmentType: AppointmentTypeModel;
    appointmentConfiguration: AppointmentTypeConfigurationModel;

    patientId: number;
    availabilities: EmployeeAvailabilityModel[];
    availability: AvailabilityModel[];
    timeZones: TimeZoneModel[];

    selectedDate: Date;
    selectedTime: Date;
    timeZoneId: string;

    isAvailabilityLoading: boolean;
    isLoading: boolean;
    selectedReasonType: AppointmentReasonType;
}

let stateContext: RescheduleAppointmentComponentState;

const initialState: RescheduleAppointmentComponentState = {
    open: false,
    appointment: null,
    appointmentType: null,
    appointmentConfiguration: null,
    availabilities: [],
    availability: [],
    selectedDate: availabilityQuery.getEarliestAvailability(),
    selectedTime: null,
    isAvailabilityLoading: false,
    isLoading: false,
    errors: {},
    selectedReasonType: null
} as RescheduleAppointmentComponentState;

/**
 * Custom Hook to manage a view Model for Create shortcuts component
 */
export function useFacade(patientId: number | null): [
    RescheduleAppointmentComponentState,
    (date: Date) => void,
    (time: Date) => void,
    (event: any) => void,
    () => void,
    (reason: string) => void,
    (value: any) => void
] {
    const [state, setState] = useState(initialState as RescheduleAppointmentComponentState);

    stateContext = state;

    const handleChangeDate = (date: Date) => {
        setState({ ...state, selectedDate: date, selectedTime: null });
    }

    const handleChangeTime = (time: Date) => {
        coachAppointmentValidator.validateAndSetState(state, setState, 'selectedTime', time);
        setState({ ...state, selectedTime: time });
    }

    const handleChangeReason = (reason: string) => {
        state.appointment.reason = reason;
        setState({...state, appointment: state.appointment});
    }

    const handleSelectReason = (value: any) => {
        setState(state => ({
            ...state,
            selectedReasonType: value,
            errors: GeneralValidator.removeError({ errors: GeneralValidator.removeError(state, 'otherReason')}, 'selectedReason')
        }));
    }

    const reasonIsSelected = (): boolean => {
        if (!state.appointmentType?.requiresReasonType) {
            return true;
        }

        if (!state.selectedReasonType) {
            setState(state => ({
                ...state,
                errors: GeneralValidator.addError(state, 'selectedReason', 'Please, select a reason.')
            }));
    
            return false;
        }

        if (state.selectedReasonType !== AppointmentReasonType.Other && state.selectedReasonType !== AppointmentReasonType.OtherTestFollowUp) {
            setState(state => ({
                ...state,
                errors: GeneralValidator.removeError(state, 'selectedReason')
            }));

            return true;
        }

        if (state.appointment.reason) {
            setState(state => ({
                ...state,
                errors: GeneralValidator.removeError(state, 'otherReason')
            }));

            return true;
        }

        setState(state => ({
            ...state,
            errors: GeneralValidator.addError(state, 'otherReason', 'Please, enter a specific reason.')
        }));

        return false;
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();

        coachAppointmentValidator.validateAndSetState(state, setState, 'selectedTime', state.selectedTime);

        if (!reasonIsSelected()) {
            return;
        }

        if (!coachAppointmentValidator.stateIsValid(state)) {
            return;
        }

        setState(state => ({ ...state, isLoading: true }));

        const createModel = {
            patientId: state.appointment.patient?.id ?? state.patientId,
            employeeIds: state.appointment.employees.map(x => x.id),
            startDate: moment(state.selectedTime).toDate(),
            endDate: moment(state.selectedTime).add(state.appointment.duration, 'm').toDate(),
            locationType: state.appointment.locationType,
            appointmentTypeId: state.appointmentType?.id,
            appointmentTypeConfigurationId: state.appointmentConfiguration?.id,
            comment: state.appointment.comment,
            name: state.appointment.name,
            locationId: state.appointment.location.id,
            timeZoneId: state.timeZoneId,
            reason: state.selectedReasonType && state.selectedReasonType !== AppointmentReasonType.Other && state.selectedReasonType !== AppointmentReasonType.OtherTestFollowUp ? AppointmentReasonTypeNames[state.selectedReasonType] : state.appointment.reason,
            reasonType: state.selectedReasonType,
            source: source
        } as CreateAppointmentModel

        if (authQuery.getType() === UserType.Patient) {
            appointmentsService.rescheduleAsPatient(createModel, state.appointment.id).subscribe(() => {
                setState(state => ({...initialState, errors: {}}));
            })
        } else {
            appointmentsService.rescheduleAsEmployee(createModel, state.appointment.id).subscribe(() => {
                setState(state => ({...initialState, errors: {}}));
            })
        }
    }

    const handleClose = () => {
        setState(state => ({...initialState, errors: {}}));
    }

    const addAvailability = (current: AvailabilityModel[], toAdd: AvailabilityModel[]): AvailabilityModel[] => {
        return current.filter(x => toAdd.some(y => moment(x.start).isSame(moment(y.start))));
    }

    const setAvailability = () => {
        let avalability = [];
        state.availabilities.forEach(x => {
            avalability = avalability.length
                ? addAvailability(avalability, x.availability)
                : x.availability
        })

        setState(state => ({
            ...state,
            availability: avalability,
            selectedDate: availabilityQuery.getEarliestAvailability()
        }))
    }

    const queryAvailability = useCallback((isEmployee: boolean) => {
        if (!state.appointment) {
            return [];
        }

        setState(state => ({ ...state, isAvailabilityLoading: true }));


        if (state.appointment.employees.length) {
            const employeeIds = state.appointment.employees.map(employee => employee.id);
            availabilityService.getByEmployeeIds(employeeIds, availabilityQuery.getEarliestAvailability(), getAvailabilityEndDate(), state.appointment.configurationId, isEmployee).subscribe();
        }
    }, [state.appointment]);

    useEffect(() => {
        setAvailability()
    }, [state.availabilities])

    useEffect(() => {
        if (!state.appointment) {
            return
        }

        const subscriptions: Subscription[] = [
            onEmit(availabilityQuery.availabilities$, availabilities => {
                if (state.appointment) {
                    const isLoading = !state.appointment.employees.map(x => x.id).every(x => availabilities.map(a => a.employeeId).includes(x));
                    setState(state => ({
                        ...state,
                        availabilities: availabilities.map(a => ({
                            employeeId: a.employeeId,
                            availability: a.availability.filter(x => moment(x.start).minutes() % 15 === 0)
                        }) as EmployeeAvailabilityModel),
                        isAvailabilityLoading: isLoading
                    }))
                }
            })
        ];

        queryAvailability(authQuery.isEmployeeUser())

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [state.appointment])

    useEffect(() => {
        availabilityStore.reset();

        const subscriptions: Subscription[] = [

            onEmit<TimeZoneModel>(patientsQuery.patientTimeZone$, timeZone => {
                if (timeZone) {
                    setState(state => ({ ...state, timeZoneId: timeZone.id }));
                }
            }),
            onEmit<TimeZoneModel[]>(timezonesQuery.timezones$, timeZones => {
                const timeZone = getCurrentTimezone();

                setState((state) => ({
                    ...state,
                    timeZones: timeZones,
                    timeZoneId: state.timeZoneId || timeZone?.id
                }));
            }),
            onEmit(appointmentsQuery.allAppointmentTypes$, types => {
                if (stateContext.appointment) {
                    const type = types.find(x => x.configurations.some(t => t.id === stateContext.appointment.configurationId));
                    const configuration = type?.configurations.find(x => x.id === stateContext.appointment.configurationId);

                    setState(state => ({
                        ...state,
                        appointmentType: type,
                        selectedDate: availabilityQuery.getEarliestAvailability(),
                        appointmentConfiguration: configuration
                    }));
                }
            }),
            appointmentsService.patientAppointmentRescheduleOnOpen.subscribe((model) => {
                const [
                    appointment,
                    patientId
                ] = model.data;

                setState(state => ({
                    ...state,
                    open: true,
                    appointment: appointment,
                    patientId: patientId,
                    selectedReasonType: appointment.reasonType,
                }));

                appointmentsService.getAllAppointmentTypes();
            }),
        ];

        timezonesService.getTimezones().subscribe();

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [state.open]);

    return [
        state,
        handleChangeDate,
        handleChangeTime,
        handleSubmit,
        handleClose,
        handleChangeReason,
        handleSelectReason
    ]
}