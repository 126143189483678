import {
    Box,
    IconButton,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableSortLabel
} from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React from 'react';
import { PermissionType } from "../../../auth/models/auth.enums";
import { ProtectedElement } from "../../../common/components/ProtectedElement";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { useFacade } from "./manageLocationsComponent.hooks";
import { Add } from "@material-ui/icons";
import { CreateLocationDialogComponent } from "../createLocationDialogComponent/CreateLocationDialogComponent";
import { WildHealthLinkButton } from "../../../common/components/WildHealthLinkButton";
import { UpdateLocationDialogComponent } from "../updateLocationDialogComponent/UpdateLocationDialogComponent";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { FeatureFlag } from '../../../common/components/featureFlags/featureFlags.models';
import { FeatureComponent } from '../../../common/components/featureFlags/FeatureComponent';

interface ManageLocationsComponentProps {
    handleGoToManageLocation: Function;
}

export const ManageLocationsComponent: React.FC<ManageLocationsComponentProps> = (props: ManageLocationsComponentProps) => {
    const [
        {
            isLoading,
            locations,
            sortingColumns,
            sortingSource,
            isActionsOpen,
            actionsAnchorEl,
            isCreating
        },
        handleToggleActions,
        handleSorting,
        handleCreate,
        handleEdit
    ] = useFacade();

    const {
        handleGoToManageLocation
    } = props;

    const renderTable = () => {
        if (isLoading) {
            return <WildHealthLinearProgress />
        }

        if (!isLoading && (!locations || !locations.length)) {
            return <WildHealthPlaceHolder message="No pods found" />
        }

        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <WildHealthTableRow>
                            {
                                sortingColumns.map((item, index) =>
                                    <WildHealthTableCell
                                        key={index}
                                    >
                                        <TableSortLabel
                                            id={`manage-locations-${item.title}`}
                                            active={sortingSource === item.source}
                                            direction={sortingSource === item.source ? item.direction : 'asc'}
                                            onClick={() => handleSorting(item.source, item.direction)}
                                        >
                                            {item.title}
                                        </TableSortLabel>
                                    </WildHealthTableCell>
                                )
                            }
                            <ProtectedElement
                                element={
                                    <WildHealthTableCell>
                                        Actions
                                    </WildHealthTableCell>}
                                permissions={[PermissionType.ManageLocations]}
                            />

                        </WildHealthTableRow>
                    </TableHead>
                    <TableBody>
                        {
                            locations.map((item, index) =>
                                <WildHealthTableRow key={index}>
                                    <WildHealthTableCell>
                                        <WildHealthLinkButton
                                            id={`manege-locations-link-${item.name}`}
                                            content={item.name}
                                            onClick={() => handleGoToManageLocation(item)}
                                        />
                                    </WildHealthTableCell>
                                    <WildHealthTableCell>
                                        {item.city}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell>
                                        {item.state}
                                    </WildHealthTableCell>
                                    <ProtectedElement
                                        element={
                                            <WildHealthTableCell>
                                                <IconButton id={`manege-locations-actions-${item.name}`} onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                                                    handleToggleActions(!isActionsOpen, item, event.currentTarget)}>
                                                    <MoreVertIcon />
                                                </IconButton>
                                            </WildHealthTableCell>}
                                        permissions={[PermissionType.ManageLocations]}
                                    />
                                </WildHealthTableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    return (
        <Box p={3}>
            <Box>

                <FeatureComponent featureFlag={FeatureFlag.CreatePods}>
                    <ProtectedElement
                        element={
                            <WildHealthButton
                                id="manage-location-create-pod"
                                size="medium"
                                disabled={isCreating}
                                onClick={() => handleCreate()}
                            >
                                <Box display="flex" alignItems="center">
                                    <Add />
                                    <span>Create Pod</span>
                                </Box>
                            </WildHealthButton>}
                        permissions={[PermissionType.ManageLocations]}
                    />
                </FeatureComponent>
            </Box>
            <Box pt={3}>
                {
                    renderTable()
                }
            </Box>
            <ProtectedElement
                element={
                    <Menu
                        keepMounted
                        anchorEl={actionsAnchorEl}
                        open={isActionsOpen}
                        onClose={() => handleToggleActions(false, null, null)}
                    >
                        <MenuItem id="manage-locations-edit" onClick={() => handleEdit()}>
                            Edit
                        </MenuItem>
                        <MenuItem id="manage-locations-delete" disabled={true}>
                            Delete
                        </MenuItem>
                    </Menu>}
                permissions={[PermissionType.ManageLocations]}
            />
            <CreateLocationDialogComponent />
            <UpdateLocationDialogComponent />
        </Box>
    )
}