import {
    Box,
    Grid,
    IconButton,
    Tooltip,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import React, { MouseEventHandler } from "react";
import { useStyles, InputField, ActionButton } from "./sendMessageComponent.styles";
import { useFacade } from "./sendMessageComponent.hooks";
import { EmojiComponent } from "../emojiComponent/EmojiComponent";
import { FeatureComponent } from "../../../common/components/featureFlags/FeatureComponent";
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import { ReactComponent as SendIcon } from "@img/icons/Send.svg";
import { ReactComponent as AttachIcon } from "@img/icons/Attach.svg";
import { ReactComponent as ReactionIcon } from "@img/icons/Reaction.svg";
import { ReactComponent as ScheduleIcon } from "@img/icons/Schedule.svg";
import DescriptionIcon from "@material-ui/icons/Description";
import CloseIcon from "@material-ui/icons/Close";
import { AttachFileModel } from "../../models/message.models";
import { authQuery } from "../../../auth/stores/auth";

interface SendMessageComponentProps {
    canAttach?: boolean;
    canSchedule?: boolean;
    inputProps?: any;
    placeholder?: string;
    handleAttach?: MouseEventHandler;
    attachments?: AttachFileModel[];
    emptyMessage?: boolean;
    handleRemoveAttachment?: (index: number) => void;
    handleSubmit: (message: any) => void;
    handleTyping: () => void;
    handleSchedule?: (message: string) => void;
}

export const SendMessageComponent: React.FC<SendMessageComponentProps> = (props: SendMessageComponentProps) => {
    const {
        handleSubmit,
        handleSchedule: schedule,
        handleTyping,
        handleRemoveAttachment,
        canAttach,
        canSchedule,
        inputProps,
        handleAttach,
        placeholder = 'Type message here...',
        attachments = [],
        emptyMessage,
    } = props;

    const classes = useStyles();

    const [
        {
            message,
            emojiAnchorElement
        },
        handleKeyDown,
        handleChangeMessage,
        handleToggleReaction,
        handleInsertReaction,
        handleSchedule,
        handleSendMessage
    ] = useFacade(handleTyping, handleSubmit, schedule, emptyMessage);

    const theme = useTheme();
    const isMiddleScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

    return (
        <div className={classes.root}>
            {!authQuery.isSubscriptionStatusActive() && (
                <Tooltip
                    placement="top"
                    arrow
                    title="You have no currently active membership. You can restart your membership at any time by going to your account and selecting the plan that's right for you. Feel free to contact us at support@wildhealth.com if you have any questions."
                >
                    <Box className={classes.disabledMessage}></Box>
                </Tooltip>
            )}

            <Box className={classes.message}>
                {inputProps && <input id="files" {...inputProps} />}

                <InputField
                    fullWidth
                    multiline
                    rowsMax={isMiddleScreen ? 4 : 6}
                    value={message}
                    id="type-message"
                    variant="outlined"
                    InputProps={{ className: "flat-scroll" }}
                    placeholder={placeholder}
                    onKeyDown={(e) => handleKeyDown(e, message)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChangeMessage(e.target.value)
                    }
                />

                <Grid container>
                    {canAttach && (
                        <Box ml={1} mb={1}>
                            <ActionButton id="send-message-attach" size="medium" onClick={handleAttach}>
                                <AttachIcon className={classes.icon} />
                            </ActionButton>
                        </Box>
                    )}
                    <FeatureComponent featureFlag={FeatureFlag.ConversationsEmojis}>
                        <Box ml={1} mb={1}>
                            <ActionButton
                                id="send-message-reaction"
                                size="medium"
                                onClick={(e) => handleToggleReaction(e.currentTarget)}
                            >
                                <ReactionIcon className={classes.icon} />
                            </ActionButton>
                        </Box>
                    </FeatureComponent>
                    {canSchedule && (
                        <Box ml={1} mb={1}>
                            <ActionButton
                                id="send-message-schedule"
                                size="medium"
                                onClick={() => handleSchedule()}
                                disabled={message.trim().length < 1}
                            >
                                <ScheduleIcon className={classes.icon} />
                            </ActionButton>
                        </Box>
                    )}
                    {attachments && attachments.length ? (
                        attachments.map((item, index) =>
                            item.file.type.includes("image") ? (
                                <Box
                                    key={index}
                                    className={classes.imageAttachment}
                                    style={{ backgroundImage: `url(${item.url})` }}
                                >
                                    <IconButton
                                        id={`send-message-remove-attach-img-${index}`}
                                        className={classes.removeAttachment}
                                        onClick={() => handleRemoveAttachment(index)}
                                    >
                                        <CloseIcon style={{ fontSize: 20 }} />
                                    </IconButton>
                                </Box>
                            ) : (
                                <Box key={index} className={classes.attachment} ml={1} mb={1} px={1}>
                                    <DescriptionIcon className={classes.attachmentIcon} />
                                    <Box className={classes.attachmentTitle} ml={1}>
                                        {item.file.name}
                                    </Box>
                                    <IconButton
                                        id={`send-message-remove-attach-${index}`}
                                        className={classes.removeAttachment}
                                        size="small"
                                        onClick={() => handleRemoveAttachment(index)}
                                    >
                                        <CloseIcon style={{ fontSize: 14 }} />
                                    </IconButton>
                                </Box>
                            )
                        )
                    ) : (
                        <></>
                    )}
                    <FeatureComponent featureFlag={FeatureFlag.ConversationsEmojis}>
                        {Boolean(emojiAnchorElement) && (
                            <EmojiComponent
                                anchorEl={emojiAnchorElement}
                                handleClose={() => handleToggleReaction()}
                                handleSelectEmoji={handleInsertReaction}
                            />
                        )}
                    </FeatureComponent>
                </Grid>
            </Box>

            <Box m={2}>
                <ActionButton
                    id="send-message"
                    onClick={() => handleSendMessage()}
                    disabled={message.trim().length < 1 && !(attachments && attachments.length)}
                >
                    <SendIcon className={classes.icon} />
                </ActionButton>
            </Box>
        </div>
    );
};
