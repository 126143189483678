import { Practice } from "../../auth/models/auth.enums";
import { authQuery } from "../../auth/stores/auth";

export interface Colors {
    // Update Color
    primary: string;
    primaryV: string;
    secondary: string;
    black: string;
    white: string;
    bg: string;
    dividers: string;
    error: string;

    mint50: string;
    mint100: string;
    mint200: string;
    mint300: string;
    mint400: string;
    mint500: string;
    mint600: string;
    mint700: string;
    mint800: string;
    mint900: string;

    navy50: string;
    navy100: string;
    navy200: string;
    navy300: string;
    navy400: string;
    navy500: string;
    navy600: string;
    navy700: string;
    navy800: string;
    navy900: string;

    taupe50: string;
    taupe100: string;
    taupe200: string;
    taupe300: string;
    taupe400: string;
    taupe500: string;
    taupe600: string;
    taupe700: string;
    taupe800: string;
    taupe900: string;

    gray50: string,
    gray100: string,
    gray200: string,
    gray300: string,
    gray400: string,
    gray500: string,
    gray600: string,
    gray700: string,
    gray800: string,
    gray900: string,

    rose50: string,
    rose100: string,
    rose200: string,
    rose300: string,
    rose400: string,
    rose500: string,
    rose600: string,
    rose700: string,
    rose800: string,
    rose900: string,

    apricot50: string,
    apricot100: string,
    apricot200: string,
    apricot300: string,
    apricot400: string,
    apricot500: string,
    apricot600: string,
    apricot700: string,
    apricot800: string,
    apricot900: string,

    amber50: string,
    amber100: string,
    amber200: string,
    amber300: string,
    amber400: string,
    amber500: string,
    amber600: string,
    amber700: string,
    amber800: string,
    amber900: string,

    honey50: string,
    honey100: string,
    honey200: string,
    honey300: string,
    honey400: string,
    honey500: string,
    honey600: string,
    honey700: string,
    honey800: string,
    honey900: string,

    lime50: string,
    lime100: string,
    lime200: string,
    lime300: string,
    lime400: string,
    lime500: string,
    lime600: string,
    lime700: string,
    lime800: string,
    lime900: string,

    olive50: string,
    olive100: string,
    olive200: string,
    olive300: string,
    olive400: string,
    olive500: string,
    olive600: string,
    olive700: string,
    olive800: string,
    olive900: string,

    cyan50: string,
    cyan100: string,
    cyan200: string,
    cyan300: string,
    cyan400: string,
    cyan500: string,
    cyan600: string,
    cyan700: string,
    cyan800: string,
    cyan900: string,

    indigo50: string,
    indigo100: string,
    indigo200: string,
    indigo300: string,
    indigo400: string,
    indigo500: string,
    indigo600: string,
    indigo700: string,
    indigo800: string,
    indigo900: string,

    lavendar50: string,
    lavendar100: string,
    lavendar200: string,
    lavendar300: string,
    lavendar400: string,
    lavendar500: string,
    lavendar600: string,
    lavendar700: string,
    lavendar800: string,
    lavendar900: string,

    pink50: string,
    pink100: string,
    pink200: string,
    pink300: string,
    pink400: string,
    pink500: string,
    pink600: string,
    pink700: string,
    pink800: string,
    pink900: string,

    // Old Colors
    //Primary
    main: string;

    //Secondary
    accent1: string;
    accent2: string;

    //Grayscale
    gray: string;
    gray1: string;
    gray2: string;
    gray3: string;
    gray4: string;
    gray6: string;
    gray8: string;
    stroke: string;
    separators: string;
    lightGray: string;
    lightGray1: string;
    lightGray2: string;
    darkGray: string;
    mediumGray: string;

    //Indicators
    bad: string;
    average1: string;
    average2: string;
    good: string;
    disabled: string;

    //Other
    mainDark: string;
    systemBlack: string;
    linkBlue: string;
    linkBlack: string;
    lightBlue: string;
    lightBlue2: string;

    // Notifications
    internalMessageNotification: string;
    healthcareMessageNotification: string;
    darkBlue: string;

    // Status
    badBg: string;
    goodBg: string;
    average1Bg: string;

    // Navy
    navy: string;
    lightNavy: string;

    // Mint
    mint: string;
    darkMint: string;
    lightMint: string;
    lightMint50: string;

    // Lime
    lime: string;

    // Charcoal
    charcoal: string;

    // Dark purple
    darkPurple: string;
}

const wildHealthColors = {
    primary: '#B2D7C7',
    primaryV: '#388174',
    secondary: '#0B2849',
    black: '#111827',
    white: '#FEFEFE',
    bg: '#F9F9FB',
    dividers: '#E5E7EB',
    error: '#E11D40',
    disabled: '#E5E7EB',
    darkMint: '#437E77',

    mint50: '#F1F8F5',
    mint100: '#DDEEE5',
    mint200: '#B2D7C7',
    mint300: '#91C4B1',
    mint400: '#69A899',
    mint500: '#388174',
    mint600: '#2B6B63',
    mint700: '#36625E',
    mint800: '#1B4340',
    mint900: '#113532',

    navy50: '#F8FAFC',
    navy100: '#F1F5F9',
    navy200: '#E2E8F0',
    navy300: '#CBD5E1',
    navy400: '#94A3B8',
    navy500: '#586D8C',
    navy600: '#384F6F',
    navy700: '#243B5B',
    navy800: '#18314D',
    navy900: '#0B2849',

    taupe50: '#F9F8F7',
    taupe100: '#F3EFED',
    taupe200: '#EAE2DE',
    taupe300: '#DACDC8',
    taupe400: '#C3AFA7',
    taupe500: '#B19A90',
    taupe600: '#947A6F',
    taupe700: '#7B655B',
    taupe800: '#67554E',
    taupe900: '#584A45',

    gray50: '#F9FAFB',
    gray100: '#F3F4F6',
    gray200: '#E5E7EB',
    gray300: '#D1D5DB',
    gray400: '#9CA3AF',
    gray500: '#6B7280',
    gray600: '#4C5563',
    gray700: '#384151',
    gray800: '#1F2937',
    gray900: '#111827',

    rose50: '#FEF2F2',
    rose100: '#FEE2E3',
    rose200: '#FDCACB',
    rose300: '#FAA6A7',
    rose400: '#F57375',
    rose500: '#ED4F52',
    rose600: '#E11D40',
    rose700: '#BE123C',
    rose800: '#9F1234',
    rose900: '#940326',

    apricot50: '#FDF6F0',
    apricot100: '#FBE9DA',
    apricot200: '#F4D0B4',
    apricot300: '#EEB086',
    apricot400: '#E58756',
    apricot500: '#DF6737',
    apricot600: '#D1512D',
    apricot700: '#AD3D26',
    apricot800: '#8B3324',
    apricot900: '#702C20',

    amber50: '#FBF6EC',
    amber100: '#FBECCC',
    amber200: '#F5D795',
    amber300: '#F3BD5D',
    amber400: '#EDA53F',
    amber500: '#E5862C',
    amber600: '#CA6423',
    amber700: '#A9451D',
    amber800: '#89371C',
    amber900: '#712E19',

    honey50: '#FCFAEB',
    honey100: '#F5EFB5',
    honey200: '#F2E79B',
    honey300: '#E9D364',
    honey400: '#E2BF3E',
    honey500: '#D2A832',
    honey600: '#B58427',
    honey700: '#906020',
    honey800: '#784D20',
    honey900: '#664120',

    lime50: '#FBFDE8',
    lime100: '#F3FACD',
    lime200: '#E7F5A7',
    lime300: '#D1E381',
    lime400: '#B6CF52',
    lime500: '#A0B93B',
    lime600: '#849C23',
    lime700: '#667D11',
    lime800: '#4C6014',
    lime900: '#314507',

    olive50: '#F5F7EF',
    olive100: '#E9ECDC',
    olive200: '#D4DCBC',
    olive300: '#C2CCA2',
    olive400: '#9EAD70',
    olive500: '#819154',
    olive600: '#65733F',
    olive700: '#4D5A33',
    olive800: '#40492D',
    olive900: '#373F29',

    cyan50: '#F3F8FC',
    cyan100: '#E7F0F8',
    cyan200: '#C9E0EE',
    cyan300: '#9AC7E0',
    cyan400: '#64A9CD',
    cyan500: '#418EB8',
    cyan600: '#2D6B92',
    cyan700: '#285C7E',
    cyan800: '#244F69',
    cyan900: '#234258',

    indigo50: '#F2F5FB',
    indigo100: '#E7ECF8',
    indigo200: '#D4DCF1',
    indigo300: '#BAC5E7',
    indigo400: '#9DA7DB',
    indigo500: '#848CCF',
    indigo600: '#636CB7',
    indigo700: '#5A5BA7',
    indigo800: '#4B4C87',
    indigo900: '#41446D',

    lavendar50: '#FBF6FE',
    lavendar100: '#F5EBFD',
    lavendar200: '#EDDAFA',
    lavendar300: '#DAB4F3',
    lavendar400: '#C994ED',
    lavendar500: '#B56BE3',
    lavendar600: '#A14DD4',
    lavendar700: '#8B3BBA',
    lavendar800: '#753498',
    lavendar900: '#5F2B7B',

    pink50: '#FCF3F8',
    pink100: '#FBE8F4',
    pink200: '#F7D3E9',
    pink300: '#F4B3D9',
    pink400: '#EA7CBA',
    pink500: '#E1559F',
    pink600: '#CF367E',
    pink700: '#B22765',
    pink800: '#942254',
    pink900: '#7C2048',
    
    main: '#388174',
    lightBlack: '#373D3F',

    accent1: '#5BAED4',
    accent2: '#B2DEE4',
    accent3: '#AB5DBA',
    accent4: '#E0FCD6',

    gray: '#6D6C6C',
    gray1: '#7C8A9D',
    gray2: '#AFBACA',
    gray3: '#EFF1F4',
    gray4: '#D9DFE7',
    gray6: '#F6F6F6',
    gray8: '#F9F9FB',
    stroke: '#EAEFF5',
    separators: '#EFF3F8',
    lightGray: '#F7FAFC',
    lightGray1: '#F2F2F2',
    lightGray2: '#F2F6F8',
    darkGray: '#6D6C6C',
    mediumGray: '#B9B9B9',
    grayBorder: '#E9EDEF',

    bad: '#EB4B4F',
    average1: '#FFA125',
    average2: '#FDE166',
    good: '#8ABD7D',

    mainDark: '#36625E',
    systemBlack: '#000000',
    colorFormBg: ' #ffffffcc',
    info: '#5BAED5',
    linkBlue: '#075DC6',
    linkBlack: '#1A1A1A',
    lightBlue: '#509FFF',
    lightBlue2: '#E7F2F3',

    internalMessageNotification: '#5baed5',
    healthcareMessageNotification: '#ec4b4f',
    darkBlue: '#0E577E',

    average1Bg: '#FFA12514',
    goodBg: '#8ABD7D14',
    badBg: '#EB4B4F14',

    navy: '#03293B',
    lightNavy: '#03293B80',

    mint: '#88BBAA',
    lightMint: '#B2D7C7',
    lightMint20: '#B2D7C733',
    lightMint50: '#B2D7C780',

    lime: '#8FBCA2',

    charcoal: '#3F3E49',

    darkPurple: '#7C8198'

} as Colors;

const practiceColors = {
    [Practice.WildHealth]: wildHealthColors
}

export const colors = practiceColors[authQuery.getCurrentPracticeId()] ?? wildHealthColors


