import { FileModel } from "../../common/models/file.models";
import { HealthSummaryDataModel } from "../../healthSummary/models/healthSummary.models";
import { VitalModel } from "../../vital/models/vital.model";

export enum FileInputType {
    DNAReport = 1,
    LabResults = 2,
    MicrobiomeData = 3
}

export enum Optimization {
    Incomplete = 0,
    InProgress = 5,
    Completed = 10
}

export enum FileInputDataProvider {
    Andme = 0,
    LabCorpElation = 5,
    Quest = 6,
    Manual = 7,
    LabCorpOriginal = 8,
    Ancestry = 10,
    Gutbio = 15,
}

export enum LabGroup {
    Lipids = 1,
    Hormones = 2,
    Methylation = 3,
    Inflammation = 4,
    VitaminsAndMicronutrients = 5,
    InsulinResistanceAndMetabolism = 6,
    CBC = 7,
    Metabolic = 8,
    Other = 99
}

export enum LabInputNotificationType {
    Empty = 1,
    Warning = 5,
    Error = 10
}

export enum InputNotificationClassification {
    High = 1,
    Normal = 5,
    Low = 10
}

export enum VitalValueSourceType {
    None = 0,
    HealthForms = 1,
    VitalsWidget = 5,
    GeneralInputs = 10,
    FollowUpCoachingForm = 15,
    HistoryAndPhysicalNote = 20,
    MobileApplication = 25
}

export interface FileInputModel {
    id: number;
    uploadedAt: Date;
    observationDate: Date;
    type: FileInputType;
    file: FileModel;
}
export interface UploadFileInputModel {
    type: FileInputType;
    dataProvider: FileInputDataProvider;
    file: any;
}
export interface InputNotificationModel {
    notificationType: LabInputNotificationType;
    message: string;
    notificationClassification?: InputNotificationClassification
}

export interface InputModel {
    id: number;
    name: string;
    date?: Date;
    value: number | null;
    isChanged: boolean;
    isChangedInThisSession: boolean;
    isInitialized: boolean;
    editable: boolean;
    notification: InputNotificationModel;
    range: LabRangeModel;
    group?: LabGroup;
    isNewMisc?: boolean;
}

export interface ValueInputModel extends InputModel {
    date: Date;
    vital: VitalModel;
    sourceType: VitalValueSourceType;
}

export interface LabInputValueModel extends ValueInputModel {
    dataSetId: string;
    isPresent: boolean;
}

export interface LabInputsDatasetViewModel {
    id: string;
    date: Date;
    selected: boolean;
    values: LabInputValueModel[];
    new: boolean;
}

export interface LabInputsDatasetEditableViewModel extends LabInputsDatasetViewModel {
    draftValues: LabInputValueModel[];
}

export enum LabInputsViewMode {
    Graph = 1,
    Values = 2
}

export interface LabInputModel {
    id: number;
    name: string;
    displayName: string;
    description: string;
    group: LabGroup;
    isHighlighted: boolean;
    values: LabInputValueModel[];
    vitalValues: VitalModel[];
}

export interface LabRangeModel {
    from: number | null;
    to: number | null;
    dimension: string;
    type: LabRangeType;
    source: LabValidationSource;
}

export enum LabRangeType {
    None = 0,
    LessThen = 1,
    MoreThen = 2,
    FromTo = 3,
    LessThanOrEqual = 10,
    MoreThanOrEqual = 20
}

export enum LabValidationSource {
    Custom = 1,
    ValueScope = 5,
    InputScope = 10
}

export const labRangeTypesNames = {
      [LabRangeType.None]: "None",
      [LabRangeType.LessThen]: "Less than",
      [LabRangeType.MoreThen]: "More than",
      [LabRangeType.FromTo]: "From - To",
      [LabRangeType.LessThanOrEqual]: "Less than or equal",
      [LabRangeType.MoreThanOrEqual]: "More than or equal"
};

export interface UpdateLbInputRangeModel {
    id: number;
    from: number | null;
    to: number | null;
}

export interface CreateLabInputModel {
    name: string;
    isHighlighted: boolean;
    range: LabRangeModel;
}

export interface UpdateLabInputModel {
    id: number;
    name: string;
}

export interface CreateLabInputValueModel {
    date: Date;
    name: string;
    value: number;
    labInputRange: LabRangeModel;
}

export interface UpdateLabInputValueModel {
    id: number;
    value: number;
    labInputRange: LabRangeModel;
}

export interface CorrectLabInputValueModel {
    id: number;
    value: number;
    note: string;
}

export interface AppendLabInputValueModel {
    dataSetId: string;
    name: string;
    value: number;
    labInputRange: LabRangeModel;
}

export interface UpdateLabInputsModel {
    ranges: UpdateLbInputRangeModel[];
    toCreate: CreateLabInputValueModel[];
    toUpdate: UpdateLabInputValueModel[];
    toCorrect: CorrectLabInputValueModel[];
    toAppend: AppendLabInputValueModel[];
    toDelete: number[];
    labInputMutations: LabInputModel[];
    newLabInputs: CreateLabInputModel[];
    updatedLabInputs: UpdateLabInputModel[];
}

export const labInputGroupNames: { [id: number]: string } = {
    [LabGroup.Lipids]: "Lipids",
    [LabGroup.Hormones]: "Hormones",
    [LabGroup.Methylation]: "Methylation",
    [LabGroup.Inflammation]: "Inflammation",
    [LabGroup.VitaminsAndMicronutrients]: "Vitamins and Micronutrients",
    [LabGroup.InsulinResistanceAndMetabolism]: "Insulin resistance / metabolism",
    [LabGroup.CBC]: "CBC",
    [LabGroup.Metabolic]: "Metabolic Panel",
    [LabGroup.Other]: "Misc Lab"
}

export interface MicrobiomeInputModel extends InputModel {
}

export interface GeneralInputsModel {
    exerciseActivitiesFrequency: number | null;
    meditationFrequency: number | null;
    rem: number | null;
    deepSleep: number | null;
    sleep: number | null;
    chronologicalAge: number | null;
    realAge: number | null;
    biologicalAge: number | null;
    sbp: number | null;
    brainOptimization: Optimization;
    mindOptimization: Optimization;
    bodyOptimization: Optimization;
    gutOptimization: Optimization;
    longevityOptimization: Optimization;
    bioHackingOptimization: Optimization;
    ethnicity: Ethnicity;
    smokingCategory: SmokingCategory;
    diabetesType: DiabetesType;
    exerciseGoal: ExerciseType;
    dietChoice: DietType;
    familyHeartAttack: YesNo;
    chronicKidneyDisease: YesNo;
    atrialFibrillation: YesNo;
    bloodPressureTreatment: YesNo;
    rheumatoidArthritis: YesNo;
    fastingRegularly: YesNo;
    cancerScreeningCompleted: YesNo;
    mesa: number;
}

export enum DietType {
    None = 0,
    GlutenFree = 2,
    Paleo = 3,
    Pescatarian = 4,
    Vegan = 5,
    Veggie = 6,
    Mediterranean = 7,
    Whole = 8,
    Keto = 9
}

export enum ExerciseType {
    None = 0,
    NoPreference = 1,
    Endurance = 2,
    Strength = 3
}

export enum Ethnicity {
    White = 1,
    American = 2,
    Indian = 3,
    Pakistani = 4,
    Bangladeshi = 5,
    OtherAsian = 6,
    BlackCaribbean = 7,
    BlackAfrican = 8,
    Chinese = 9,
    Hispanic = 10,
    Other = 11,
    AfricanAmerican = 12,
    Arab = 13,
    Korean = 14,
    Japanese = 15
}

export enum SmokingCategory {
    Non = 1,
    Ex = 2,
    Light = 3,
    Moderate = 4,
    Heavy = 5
}

export enum DiabetesType {
    No = 1,
    Type1 = 2,
    Type2 = 3
}

export enum YesNo {
    None = 1,
    Yes = 5,
    No = 10
}

export interface ApoeModel {
    hideApoe: YesNo
    score: string
    source:string
    colonoscopy?: HealthSummaryDataModel
    psa?: HealthSummaryDataModel
}

export interface MesaModel {
    result: string;
    statusCode: any;
}