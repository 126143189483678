import Axios from "axios-observable";
import {Observable, Subject} from "rxjs";
import {authHeader} from "../../common/helpers/auth-header";
import {snackService} from "../../common/snack/state";
import {
    CoverageModel,
    CreateCoverageModel,
    CreateCoverageOnBehalfModel,
    InsuranceAvailabilityModel,
    InsuranceModel,
    UpdateCoverageModel, UpdateCoverageOnBehalfModel
} from "../models/insurance.models";
import {insuranceStore, InsuranceStore} from "../stores/insurance.store";
import {AttachmentModel, AttachmentType} from "../../common/models/attachment.models";
import {CallbackModel} from "../../common/models/callback.model";

/**
 * Provides method for working with Insurance
 */
export class InsuranceService {
    private url = `${process.env.REACT_APP_API_URL}InsuranceRegistration`;

    constructor(private insuranceStore: InsuranceStore) {
    }

    public onCreateInsurance = new Subject<CallbackModel<number>>();

    public onUpdateInsurance = new Subject<CallbackModel<{patientId: number, coverage: CoverageModel}>>();

    public requestCreateInsurance = (patientId: number): Observable<CreateCoverageOnBehalfModel> => {
        return new Observable(observer => {
            this.onCreateInsurance.next({
                data: patientId,
                callback: (result: CreateCoverageOnBehalfModel) => {
                    if (result) {
                        observer.next(result);
                    }
                    observer.complete();
                }
            });
        });
    }

    public requestUpdateInsurance = (coverage: CoverageModel, patientId: number): Observable<UpdateCoverageOnBehalfModel> => {
        return new Observable(observer => {
            this.onUpdateInsurance.next({
                data: {
                    patientId: patientId,
                    coverage: coverage
                },
                callback: (result: UpdateCoverageOnBehalfModel) => {
                    if (result) {
                        observer.next(result);
                    }
                    observer.complete();
                }
            });
        });
    }

    /**
     * Returns all insurances
     */
    public getInsurances(stateId?: number, age?: number): Observable<InsuranceModel[]> {
        let url = `${this.url}/organizations`;
        if (stateId) {
            url += `?stateId=${stateId}`;
        }

        if (age) {
            const separator = url.includes('?') ? '&' : '?'
            url += `${separator}age=${age}`;
        }

        const config = {headers: authHeader()}

        return new Observable(observer => {
            Axios.get(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.insuranceStore.update({insurances: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.error();
                    }
                );
        })
    }

    /**
     * Returns available insurances
     */
    public getAvailableInsurances(): Observable<InsuranceModel[]> {
        let url = `${this.url}/organizations/available`;

        const config = {headers: authHeader()}

        return new Observable(observer => {
            Axios.get(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.insuranceStore.update({insurances: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.error();
                    }
                );
        })
    }

    /**
     * Returns available insurances by PatientId
     */
    public getAvailableInsurancesByEmployee(patientId: number): Observable<InsuranceModel[]> {
        let url = `${this.url}/organizations/byEmployee?patientId=${patientId}`;

        const config = {headers: authHeader()}

        return new Observable(observer => {
            Axios.get(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.insuranceStore.update({insurances: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.error();
                    }
                );
        })
    }

    /**
     * Creates coverage
     */
    public createCoverage(model: CreateCoverageModel): Observable<CoverageModel> {
        let url = `${this.url}/coverage`;
        const config = {headers: authHeader()}

        const formData = new FormData();
        formData.append('insuranceId', model.insuranceId.toString());
        formData.append('memberId', model.memberId.toString());
        formData.append('isPrimary', model.isPrimary.toString());

        if (model.frontImage) {
            formData.append('frontImage', model.frontImage as any);
        } else {
            formData.append('frontImage', 'null');
        }

        if (model.backImage) {
            formData.append('backImage', model.backImage as any);
        } else {
            formData.append('backImage', 'null');
        }

        if (model.policyHolder) {
            formData.append('policyHolder[firstName]', model.policyHolder.firstName);
            formData.append('policyHolder[lastName]', model.policyHolder.lastName);
            formData.append('policyHolder[relationship]', model.policyHolder.relationship);
            formData.append('policyHolder[dateOfBirth]', model.policyHolder.dateOfBirth);
        } else {
            formData.append('policyHolder', 'null');
        }

        return new Observable(observer => {
            Axios.post<CoverageModel>(url, formData, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.insuranceStore.addCoverage(response.data);
                        snackService.success("Insurance was created successfully!")
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.error();
                    }
                );
        })
    }

    /**
     * Creates coverage on behalf
     */
    public createCoverageOnBehalf(model: CreateCoverageOnBehalfModel): Observable<CoverageModel> {
        let url = `${this.url}/coverage/onBehalf`;
        const config = {headers: authHeader()}

        const formData = new FormData();
        formData.append('insuranceId', model.insuranceId.toString());
        formData.append('patientId', model.patientId.toString());
        formData.append('memberId', model.memberId.toString());
        formData.append('isPrimary', model.isPrimary.toString());

        if (model.frontImage) {
            formData.append('frontImage', model.frontImage as any);
        } else {
            formData.append('frontImage', 'null');
        }

        if (model.backImage) {
            formData.append('backImage', model.backImage as any);
        } else {
            formData.append('backImage', 'null');
        }

        if (model.policyHolder) {
            formData.append('policyHolder[firstName]', model.policyHolder.firstName);
            formData.append('policyHolder[lastName]', model.policyHolder.lastName);
            formData.append('policyHolder[relationship]', model.policyHolder.relationship);
            formData.append('policyHolder[dateOfBirth]', model.policyHolder.dateOfBirth);
        } else {
            formData.append('policyHolder', 'null');
        }

        return new Observable(observer => {
            Axios.post<CoverageModel>(url, formData, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.insuranceStore.addCoverage(response.data);
                        snackService.success("Insurance was created successfully!")
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.error();
                    }
                );
        })
    }

    /**
     * Turn on insurance
     */
    public turnOnInsurance(patientId: number) {
        let url = `${this.url}/TurnOn?patientId=${patientId}`;
        const config = {headers: authHeader()}

        return new Observable(observer => {
            Axios.post(url, {}, config)
                .pipe()
                .subscribe(
                    () => {
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.error();
                    }
                );
        })
    }

    /**
     * Turn off insurance
     */
    public turnOffInsurance(patientId: number) {
        let url = `${this.url}/TurnOff?patientId=${patientId}`;
        const config = {headers: authHeader()}

        return new Observable(observer => {
            Axios.post(url, {}, config)
                .pipe()
                .subscribe(
                    () => {
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.error();
                    }
                );
        })
    }

    /**
     * Returns coverage
     */
    public getCoverages(): Observable<CoverageModel[]> {
        let url = `${this.url}/coverages`;
        const config = {headers: authHeader()}

        return new Observable(observer => {
            Axios.get<CoverageModel[]>(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.insuranceStore.update({coverages: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.error();
                    }
                );
        })
    }

    /**
     * Returns coverages by employ
     */
    public getCoveragesByEmploy(patientId: number): Observable<CoverageModel[]> {
        let url = `${this.url}/Coverages/byEmployee?patientId=${patientId}`;
        const config = {headers: authHeader()}

        return new Observable(observer => {
            Axios.get<CoverageModel[]>(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.insuranceStore.update({coverages: response.data});
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.error();
                    }
                );
        })
    }

    /**
     * Updates coverage
     */
    public updateCoverage(model: UpdateCoverageModel): Observable<CoverageModel> {
        let url = `${this.url}/coverage`;
        const config = {headers: authHeader()}

        const formData = new FormData();
        formData.append('id', model.id.toString());
        formData.append('insuranceId', model.insuranceId.toString());
        formData.append('memberId', model.memberId.toString());
        formData.append('isPrimary', model.isPrimary.toString());

        if (model.frontImage) {
            formData.append('frontImage', model.frontImage as any);
        } else {
            formData.append('frontImage', 'null');
        }

        if (model.backImage) {
            formData.append('backImage', model.backImage as any);
        } else {
            formData.append('backImage', 'null');
        }

        if (model.policyHolder) {
            formData.append('policyHolder[firstName]', model.policyHolder.firstName);
            formData.append('policyHolder[lastName]', model.policyHolder.lastName);
            formData.append('policyHolder[relationship]', model.policyHolder.relationship);
            formData.append('policyHolder[dateOfBirth]', model.policyHolder.dateOfBirth);
        } else {
            formData.append('policyHolder', 'null');
        }

        return new Observable(observer => {
            Axios.put<CoverageModel>(url, formData, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.insuranceStore.addCoverage(response.data);
                        snackService.success("Insurance was updated successfully!")
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.error();
                    }
                );
        })
    }

    /**
     * Updates coverage
     */
    public updateCoverageOnBehalf(model: UpdateCoverageOnBehalfModel): Observable<CoverageModel> {
        let url = `${this.url}/coverage/OnBehalf`;
        const config = {headers: authHeader()}

        const formData = new FormData();
        formData.append('id', model.id.toString());
        formData.append('patientId', model.patientId.toString());
        formData.append('insuranceId', model.insuranceId.toString());
        formData.append('memberId', model.memberId.toString());
        formData.append('isPrimary', model.isPrimary.toString());

        if (model.frontImage) {
            formData.append('frontImage', model.frontImage as any);
        } else {
            formData.append('frontImage', 'null');
        }

        if (model.backImage) {
            formData.append('backImage', model.backImage as any);
        } else {
            formData.append('backImage', 'null');
        }

        if (model.policyHolder) {
            formData.append('policyHolder[firstName]', model.policyHolder.firstName);
            formData.append('policyHolder[lastName]', model.policyHolder.lastName);
            formData.append('policyHolder[relationship]', model.policyHolder.relationship);
            formData.append('policyHolder[dateOfBirth]', model.policyHolder.dateOfBirth);
        } else {
            formData.append('policyHolder', 'null');
        }

        return new Observable(observer => {
            Axios.put<CoverageModel>(url, formData, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.insuranceStore.addCoverage(response.data);
                        snackService.success("Insurance was updated successfully!")
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.error();
                    }
                );
        })
    }

    /**
     * Activates coverage
     */
    public activateCoverage(id: number): Observable<CoverageModel> {
        let url = `${this.url}/coverage/${id}/activate`;
        const config = {headers: authHeader()}

        return new Observable(observer => {
            Axios.put<CoverageModel>(url, null, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.insuranceStore.addCoverage(response.data);
                        snackService.success("Insurance was activated successfully!")
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.error();
                    }
                );
        })
    }

    /**
     * Activates coverage
     */
    public deactivateCoverage(id: number): Observable<CoverageModel> {
        let url = `${this.url}/coverage/${id}/deactivate`;
        const config = {headers: authHeader()}

        return new Observable(observer => {
            Axios.put<CoverageModel>(url, null, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.insuranceStore.addCoverage(response.data);
                        snackService.success("Insurance was deactivated successfully!")
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.error();
                    }
                );
        })
    }

    public uploadInsurance(file: any, type: AttachmentType, coverageId: string): Observable<AttachmentModel[]> {
        let url = `${this.url}/Insurance/Attachments/Upload?coverageId=${coverageId}`;
        const config = {headers: authHeader()}

        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', type.toString());
        formData.append('name', file.name);
        formData.append('description', '');

        return new Observable(observer => {
            Axios.post<AttachmentModel[]>(url, formData, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.insuranceStore.addAttachment(response.data);
                        snackService.success("Insurance photo was updated successfully uploaded!")
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.error();
                    }
                );
        })
    }

    public uploadInsuranceOnBehalf(file: any, type: AttachmentType, patientId: number, coverageId: string): Observable<AttachmentModel[]> {
        let url = `${this.url}/Insurance/Attachments/Upload/OnBehalf?patientId=${patientId}&coverageId=${coverageId}`;
        const config = {headers: authHeader()}

        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', type.toString());
        formData.append('name', file.name);
        formData.append('description', '');

        return new Observable(observer => {
            Axios.post<AttachmentModel[]>(url, formData, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.insuranceStore.addAttachment(response.data);
                        snackService.success("Insurance photo was updated successfully uploaded!")
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.error();
                    }
                );
        })
    }

    public getInsuranceUploads(): Observable<AttachmentModel[]> {
        let url = `${this.url}/Insurance/Attachments`;
        const config = {headers: authHeader()}

        return new Observable(observer => {
            Axios.get<AttachmentModel[]>(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.insuranceStore.update({attachments: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error();
                    }
                );
        })
    }

    public getInsuranceUploadsOnBehalf(patientId: number): Observable<AttachmentModel[]> {
        let url = `${this.url}/Insurance/Attachments/OnBehalf?patientId=${patientId}`;
        const config = {headers: authHeader()}

        return new Observable(observer => {
            Axios.get<AttachmentModel[]>(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.insuranceStore.update({attachments: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error();
                    }
                );
        })
    }

    public getInsuranceAvailability(): Observable<boolean> {
        let url = `${this.url}/Insurance/Availability`;
        const config = {headers: authHeader()}

        return new Observable(observer => {
            Axios.get<boolean>(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.insuranceStore.update({insuranceAvailability: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error();
                    }
                );
        })
    }

    public getInsuranceAvailabilityOnBehalf(patientId: number): Observable<InsuranceAvailabilityModel> {
        let url = `${this.url}/Insurance/Availability/OnBehalf?patientId=${patientId}`;
        const config = {headers: authHeader()}

        return new Observable(observer => {
            Axios.get<InsuranceAvailabilityModel>(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.insuranceStore.update({insuranceAvailability: response.data.isAvailable});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error();
                    }
                );
        })
    }

    public GetInsuranceImage(attachmentId: number): Observable<any> {
        const config = { headers: authHeader() };

        return new Observable((observer) => {
            Axios.get<any>(`${this.url}/Insurance/Attachments/${attachmentId}`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }
}

export const insuranceService = new InsuranceService(insuranceStore);
