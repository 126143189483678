import React from 'react';
import { Box } from "@material-ui/core";
import { QuestionnaireResultModel } from "../../models/questionnaire.models";
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import commonUseStyles from '../../../common/styles/common.styles';
import checked from "@img/icons/checked.svg";
import clsx from 'clsx';
import moment from 'moment';
import { toCurrentTimeZone } from '../../../timezones/helpers/timezone';

interface CompletedQuestionnaireComponentProps {
    questionnaireResult: QuestionnaireResultModel;
    handleView: (resultId: number) => void;
}

export const CompletedQuestionnaireComponent: React.FC<CompletedQuestionnaireComponentProps> = (props: CompletedQuestionnaireComponentProps) => {
    const {
        questionnaireResult,
        handleView
    } = props;

    const commonClasses = commonUseStyles();

    const renderQuestionnaireName = (result: QuestionnaireResultModel) => {
        return result.sequenceNumber
            ? result.questionnaire.name + ' ' + result.sequenceNumber
            : result.questionnaire.name;
    }
    return (
        <Box className={commonClasses.backgroundGray3} display='flex' alignItems='center' p='10px'>
            <Box>
                <img src={checked} style={{ verticalAlign: 'middle' }} alt='img' />
            </Box>
            <Box ml={2}>
                <Box>
                    <span>{renderQuestionnaireName(questionnaireResult)}</span>
                </Box>
                <Box className={clsx(commonClasses.size12, commonClasses.colorGray1)}>
                    <span>{moment(toCurrentTimeZone(questionnaireResult.submittedAt)).format('MM/DD/yyyy')}</span>
                </Box>
            </Box>
            <Box ml='auto'>
                <WildHealthButton id="completed-questionnaire-view" color='secondary' onClick={() => handleView(questionnaireResult.id)}>
                    View
                </WildHealthButton>
            </Box>
        </Box>
    )
}