import { useEffect, useState, ChangeEventHandler } from "react";
import { Subscription } from "rxjs";
import { onEmit } from "../../../common/helpers/on-emit";
import { commentsService } from "../../services/comments.service";
import { insuranceQuery } from "../../../insurance/stores/insurance.query";
import { CommentModel } from "../../models/comment.models";

interface CreateInsuranceModalComponentState {
    isLoading: boolean;
    isSaving: boolean;
    isNewComment: boolean;
    comments: CommentModel[];
    initialComment: string;
    selectCommentId: number;
    selectedComment: string;
    canSubmit: boolean;
    canUpdate: boolean;
}

export function useFacade(universalId: string): [
    CreateInsuranceModalComponentState,
    ChangeEventHandler,
    ChangeEventHandler,
    () => void,
    (id: number) => void,
    () => void,
    () => void,
    () => void,
    (id: number) => void
] {
    const [state, setState] = useState({
        isLoading: true,
        isSaving: false,
        isNewComment: false,
        comments: [],
        initialComment: '',
        selectCommentId: 0,
        selectedComment: '',
        canSubmit: false,
        canUpdate: false,
    } as CreateInsuranceModalComponentState);

    const handleEdit = (id: number) => {
        const item = state.comments.find(x => x.id === id);
        if (item) {
            setState(state => ({
                ...state,
                isNewComment: false,
                canUpdate: true,
                initialComment: '',
                selectCommentId: id,
                selectedComment: item.description
            }))
        }
    }

    const handleCancel = () => {
        setState(state => ({
            ...state,
            isSaving: false,
            isNewComment: false,
            selectCommentId: 0,
            selectedComment: '',
            initialComment: '',
        }))
    }

    const handleUpdateComment = (e: any) => {
        const comment = e.target.value;
        const canUpdate = e.target.value.length > 0;
        setState({
            ...state,
            canUpdate,
            selectedComment: comment,
        })
    }

    const handleChangeComment = (e: any) => {
        const comment = e.target.value;
        const canSubmit = e.target.value.length > 0;
        setState({
            ...state,
            canSubmit,
            initialComment: comment,
        })
    }

    const handleNewAdd = () => {
        setState(state => ({
            ...state,
            isNewComment: true,
            selectCommentId: 0,
            selectedComment: ''
        }))
    }

    const handleSubmit = () => {
        if (!state.canSubmit || !universalId) return;
        const model = {
            description: state.initialComment,
            commentableUniversalId: universalId
        }
        setState(state => ({ ...state, isSaving: true }))
        const cb = () => setState(state => ({ ...state, isSaving: false  }))
        commentsService.saveComment(model).subscribe(() => { handleCancel() }, cb)
    }

    const handleUpdate = () => {
        if (!state.canUpdate || !universalId) return;
        const model = {
            description: state.selectedComment,
            commentableUniversalId: universalId
        }
        setState(state => ({ ...state, isSaving: true }))
        const cb = () => setState(state => ({ ...state, isSaving: false  }))
        commentsService.updateComment(state.selectCommentId, model).subscribe(() => { handleCancel() }, cb)
    }

    const handleDelete = (id: number) => {
        setState(state => ({ ...state, isSaving: true }))
        const cb = () => setState(state => ({ ...state, isSaving: false  }))
        commentsService.deleteComment(id).subscribe(cb, cb)
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<CommentModel[]>(insuranceQuery.comments$, comments => {
                setState(state => ({
                    ...state,
                    comments: comments,
                }))
            })
        ];
        setState(state => ({ ...state, isLoading: true }))
        
        if (universalId) {
            const cb = () => setState(state => ({ ...state, isLoading: false }))
            commentsService.getComments(universalId).subscribe(cb, cb)
        }

        return () => {
            subscriptions.map(it => it.unsubscribe())
        }
    }, [universalId])

    return [
        state,
        handleChangeComment,
        handleUpdateComment,
        handleNewAdd,
        handleEdit,
        handleCancel,
        handleSubmit,
        handleUpdate,
        handleDelete
    ];
}