import React from 'react';
import { Box } from "@material-ui/core";

export const DescriptionComponentForTag: React.FC = () => {

    return (
        <Box p={1} className="wh-tw-text-xxs wh-tw-text-gray1">
            If you provide tags, these will be associated with every chunk that is pulled from the document.  If you omit tags, then the AI tool will evaluate unique tags for each chunk within the document dynamically.
        </Box>
    )
}