import React, {FC, Fragment, useState} from 'react';
import { Box, Collapse, ListItem, ListItemText, TextField, FormControl, FormHelperText, Select, MenuItem, Chip } from "@material-ui/core";
import { Autocomplete, ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { WildHealthButton } from "../../../../common/components/wildHealthButton/WildHealthButton";
import { useStyles } from "../manageApiResponseComponent.styles";
import commonUseStyles from "../../../../common/styles/common.styles";
import { addressQuery } from '../../../../account/stores/addressStore';
import { StateModel } from '../../../../account/models/address.model';
import CloseIcon from '@material-ui/icons/Close';
import { PaymentPlanModel } from '../../../../payment/models/paymentPlan.models';

type EndpointAdminToolProps = {
    id: string
    params: any
    handleChanges: (field: string, value: any) => void,
    handleOpenToggle: () => void
    handleAddClick: (any) => void
    handleRemoveClick: (any) => void
    title: string
    errors: any
    isOpen: boolean
    isLoading: boolean
    activePaymentPlans: PaymentPlanModel[]
}


const ChangePaymentPlanInsuranceStatesComponent: FC<EndpointAdminToolProps> = (props): JSX.Element => {
    const {id, isLoading, title, errors, params:state, activePaymentPlans, handleOpenToggle, handleAddClick, handleRemoveClick, handleChanges, isOpen} = props;

    const classes = useStyles();
    const commonClasses = commonUseStyles();
    const [mode, setMode] = useState('add');

    const handleToggle=(event: React.MouseEvent<HTMLElement>, newMode: string | null) => {
        setMode(newMode)
    }

    return (
        <Fragment>
            <ListItem id={id} className={classes.divider} button onClick={handleOpenToggle}>
                <ListItemText primary={<span className="text-medium">{title}</span>}/>
                {isOpen ? <ExpandLess className={classes.activeIcon}/> : <ExpandMore/>}
            </ListItem>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <Box p={2}>
                    <ToggleButtonGroup
                        value={mode}
                        exclusive
                        onChange={handleToggle}
                    >
                        <ToggleButton value="add">
                            Add
                        </ToggleButton>
                        <ToggleButton value="remove">
                            Remove
                        </ToggleButton>
                    </ToggleButtonGroup>
                    
                    <Box mt={2}>
                        <FormControl
                            variant="outlined"
                            color='primary'
                            required
                            error={!!errors[`paymentPlan`]}
                            size="small"
                            disabled={!isOpen || isLoading}
                            classes={{ root: classes.root }}
                            fullWidth>
                            <Box className={commonClasses.label} mb={1}>Plan Name *</Box>
                            <Select
                                style={{ background: '#FFF' }}
                                classes={{ root: classes.root }}
                                value={state.paymentPlanId}
                                error={!!errors[`paymentPlan`]}
                                inputProps={{
                                    name: 'Integration vendor',
                                    id: 'vendor-label',
                                    classes,
                                }}
                                renderValue={() => {
                                    if (state.paymentPlanId === null) {
                                        return <Box className={commonClasses.colorMediumGray}>Select</Box>;
                                    }

                                    return activePaymentPlans?.find(plan => plan.id === state.paymentPlanId)?.displayName;
                                }}
                                displayEmpty
                                onChange={(v) => handleChanges("paymentPlanId", v.target.value)}
                            >
                                {activePaymentPlans?.map(plan => (
                                    <MenuItem key={`${plan.id}-${plan.name}`} value={plan.id}>{plan.displayName}</MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{errors[`paymentPlan`]}</FormHelperText>
                        </FormControl>
                    </Box>
                    <Box my={2}>
                        <Box className={commonClasses.label} mb={1}>Eligible States *</Box>
                        {mode === "add" ? 
                            <Autocomplete
                                size='small'
                                disableClearable
                                multiple
                                classes={{ root: classes.root }}
                                disabled={isLoading || !isOpen}
                                options={addressQuery.getStates().filter(x => !state.selectedStates.some(t => t.id === x.id))}
                                getOptionLabel={x => x.name}
                                onChange={(e, values) => handleChanges("selectedStates", values)}
                                value={state.selectedStates}
                                renderTags={(value: StateModel[], getTagProps) =>
                                    value.map((option: StateModel, index: number) => (
                                        <Chip label={option.name} classes={{root: classes.chip}} deleteIcon={<CloseIcon />} {...getTagProps({ index })} />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        InputProps={{ ...params.InputProps, type: 'search' }}
                                        placeholder={state.selectedStates.length ? '' : 'Select states'}
                                    />
                                )}
                            /> : 
                            <Box display="flex" alignItems="center" flexWrap="wrap">
                                {state.paymentPlanId && activePaymentPlans?.find(plan => plan.id === state.paymentPlanId)?.insuranceStates?.length ? activePaymentPlans?.find(plan => plan.id === state.paymentPlanId).insuranceStates.map(state => (
                                    <Box key={state.id} className={classes.stateItem}>
                                        <span>{state.name}</span>
                                        <CloseIcon className={classes.removeIcon} onClick={() => handleRemoveClick(state.id)} />
                                    </Box>
                                )) : <Box>Empty Insurance states</Box>}
                            </Box>
                        }
                    </Box>
                    {mode === "add" && (
                        <WildHealthButton
                            fullWidth
                            id="add-insurance-states-to-paymentplan-execute"
                            loading={isLoading}
                            onClick={handleAddClick}
                        >
                            Execute
                        </WildHealthButton>
                    )}
                </Box>
            </Collapse>
        </Fragment>
    )

}

export default ChangePaymentPlanInsuranceStatesComponent;