import { makeStyles } from "@material-ui/core";
import { colors } from "../../../../common/constants/colors";

export const useStyles = makeStyles(() => ({
    widget: {
        border: `1px solid ${colors.stroke}`,
        boxShadow: '0px 4px 10px rgba(168, 173, 198, 0.12)',
        borderRadius: '2px',
        marginBottom: 24,
    },
    switcherReverse: {
        transform: 'rotate(180deg)',
    },
    subtitle: {
        fontWeight: 500,
    },
    active: {
        color: colors.main,
        fontWeight: 500,
    }
}));
