import { Store, StoreConfig } from '@datorama/akita';
import { ReportTemplateModel } from '../../models/reportTemplate.models';

/**
 * Represents health report templates state
 */
export interface HealthReportTemplatesState {
    reportTemplates: ReportTemplateModel[];
}

/**
 * Creates initial state
 */
export function createInitialState(): HealthReportTemplatesState {
    return {
        reportTemplates: []
    };
}

/**
 * Provides health report templates states management
 */
@StoreConfig({ name: 'healthReportTemplates', resettable: true })
export class HealthReportTemplatesStore extends Store<HealthReportTemplatesState> {
    constructor() {
        super(createInitialState());
    }

    public addTemplate(newTemplate: ReportTemplateModel) {
        this.update({ reportTemplates: [newTemplate, ...this.getValue().reportTemplates] });
    }

    public updateTemplate(template: ReportTemplateModel) {
        const templates = this.getValue().reportTemplates;
        this.update({ reportTemplates: templates.map(x => x.id === template.id ? template : x) });
    }

    public removeTemplate(id: number) {
        this.update({ reportTemplates: this.getValue().reportTemplates.filter(i => i.id !== id) });
    }

}

export const healthReportTemplatesStore = new HealthReportTemplatesStore();
