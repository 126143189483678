import img from "@img/HealthReport_Pg5_Diagram.png";
import { Box, Divider, useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { HealthReportSections, HealthReportMode } from "../models/healthReport.models";
import { HealthReportBasePage, HealthReportMobileBasePage } from "./HealthReportBasePage";
import { useStyles } from './healthReport.styles';
import { HealthReportPageProps } from "./healthReportPageProps";

export const HealthReportDietAndNutrition3Page: React.FC<HealthReportPageProps> = (props: HealthReportPageProps) => {
    const { pageNumber, practice } = props;

    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        props.mode !== HealthReportMode.PrintMode && isSmallScreen ? (
            <HealthReportMobileBasePage section={HealthReportSections.DietAndNutrition} pageNumber={pageNumber} practice={practice}>
                <Box>
                    <Box px={2}>
                        <Box>
                            <span className={classes.reportTitle}>Metabolism and Vitamins - Methylation</span>
                        </Box>
                        <Box mt="10px" fontSize="14px" lineHeight="22px">
                            <Box>
                                <p className="text-medium">
                                    <span className="text-bold">Methylation</span>: Methylation is the process of “1 carbon metabolism,” representing the body’s
                                    ability to carry out the process of adding carbon atoms or methyl groups to compounds. The body
                                    has to do this to package DNA, regenerate muscle, make enzymes, degrade neurotransmitters and
                                    harmful chemicals, and much, much more. Methylation is performed via two pathways: the FOLATE
                                    pathway and the CHOLINE pathway. Both pathways must be optimized to promote adequate methylation
                                    and perform all of these processes at an appropriate level.
                                </p>
                            </Box>
                            <Box>
                                <p className="text-medium">
                                    Folate (Vitamin B9) and other B vitamins are involved in the methylation pathway, most
                                    specifically Folate, B12, and P-5-P. There are multiple mutations in enzymes involved in the
                                    pathway, such as MTHFR, which can affect your ability to methylate using the B vitamins. B
                                    vitamins are found in numerous quantities in green leafy vegetables.
                                </p>
                            </Box>
                            <Box>
                                <p className="text-medium">
                                    Choline is an essential nutrient important for multiple bodily functions including methylation,
                                    neurotransmitter development, fat packaging and excretion from the liver, and creatine
                                    production. Choline is found in greatest quantity in eggs, fish, dairy, meat, and to a lesser
                                    but notable amount in vegetables. Multiple SNPs are involved in the choline side of the
                                    methylation pathway and are noted below.
                                </p>
                            </Box>
                            <Box>
                                <p className="text-medium">
                                    We can assess your methylation status by measuring your homocysteine levels. Homocysteine builds
                                    up when methylation is stressed. High homocysteine levels have been associated with
                                    cardiovascular disease and other chronic diseases. Elevated homocysteine levels appear to
                                    irritate the arterial walls. We will also test your B vitamin levels and your liver function
                                    levels as elevated liver function markers can indicate choline deficiencies.
                                </p>
                            </Box>
                            <Box>
                                <p className="text-medium">
                                    TMAO: Unfortunately, when you are low in choline the answer isn’t always as simple as take more
                                    choline. Some people are at risk of higher cardiovascular disease when they ingest choline and
                                    carnitine due to a compound made by some gut bacteria called TMAO. We can tell your risk for
                                    this response based on the function of a specific enzyme and your gut microbiome composition.
                                    The TMAO risk score calculates your risk, and if it is high we can supplement the choline system
                                    in different ways.
                                </p>
                            </Box>
                        </Box>
                    </Box>
                    <Box px={2} mt="20px">
                        <Box display="flex" justifyContent="center">
                            <img src={img} style={{ width: '100%', height: 'auto' }} alt="img" />
                        </Box>
                    </Box>
                </Box>
            </HealthReportMobileBasePage>
        ) : (
            <HealthReportBasePage section={HealthReportSections.DietAndNutrition} pageNumber={pageNumber} practice={practice}>
                <Box>
                    <Box px={15} pt={5}>
                        <Box>
                            <span className="report-title">Metabolism and Vitamins - Methylation</span>
                        </Box>
                        <Box mt={2}>
                            <Box>
                                <p className="text-medium">
                                    <span className="text-bold">Methylation</span>: Methylation is the process of “1 carbon metabolism,” representing the body’s
                                    ability to carry out the process of adding carbon atoms or methyl groups to compounds. The body
                                    has to do this to package DNA, regenerate muscle, make enzymes, degrade neurotransmitters and
                                    harmful chemicals, and much, much more. Methylation is performed via two pathways: the FOLATE
                                    pathway and the CHOLINE pathway. Both pathways must be optimized to promote adequate methylation
                                    and perform all of these processes at an appropriate level.
                                </p>
                            </Box>
                            <Box>
                                <p className="text-medium">
                                    Folate (Vitamin B9) and other B vitamins are involved in the methylation pathway, most
                                    specifically Folate, B12, and P-5-P. There are multiple mutations in enzymes involved in the
                                    pathway, such as MTHFR, which can affect your ability to methylate using the B vitamins. B
                                    vitamins are found in numerous quantities in green leafy vegetables.
                                </p>
                            </Box>
                            <Box>
                                <p className="text-medium">
                                    Choline is an essential nutrient important for multiple bodily functions including methylation,
                                    neurotransmitter development, fat packaging and excretion from the liver, and creatine
                                    production. Choline is found in greatest quantity in eggs, fish, dairy, meat, and to a lesser
                                    but notable amount in vegetables. Multiple SNPs are involved in the choline side of the
                                    methylation pathway and are noted below.
                                </p>
                            </Box>
                            <Box>
                                <p className="text-medium">
                                    We can assess your methylation status by measuring your homocysteine levels. Homocysteine builds
                                    up when methylation is stressed. High homocysteine levels have been associated with
                                    cardiovascular disease and other chronic diseases. Elevated homocysteine levels appear to
                                    irritate the arterial walls. We will also test your B vitamin levels and your liver function
                                    levels as elevated liver function markers can indicate choline deficiencies.
                                </p>
                            </Box>
                            <Box>
                                <p className="text-medium">
                                    TMAO: Unfortunately, when you are low in choline the answer isn’t always as simple as take more
                                    choline. Some people are at risk of higher cardiovascular disease when they ingest choline and
                                    carnitine due to a compound made by some gut bacteria called TMAO. We can tell your risk for
                                    this response based on the function of a specific enzyme and your gut microbiome composition.
                                    The TMAO risk score calculates your risk, and if it is high we can supplement the choline system
                                    in different ways.
                                </p>
                            </Box>
                        </Box>
                    </Box>
                    <Divider className="report-divider" />
                    <Box px={15}>
                        <Box px={10} display="flex" justifyContent="center">
                            <img src={img} style={{ width: '70%', height: '510px' }} alt="img" />
                        </Box>
                    </Box>
                </Box>
            </HealthReportBasePage>
        )
    )
}
