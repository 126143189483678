import React from 'react';
import {useFacade} from "./failedDnaBadgeComponent.hooks";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";

export const FailedDnaBadgeComponent: React.FC = () => {
    const [isFailed] = useFacade();

    return (
        <>
            {
                isFailed
                    ? <PriorityHighIcon style={{color: 'red'}}/>
                    : <></>
            }
        </>
    );
}