import { ListItemText } from '@material-ui/core';
import { createStyles, makeStyles, withStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
        },
        header: {
            fontFamily: "Be Vietnam Pro",
            fontSize: 14,
            lineHeight: '14px',
            background: colors.gray4,
            color: colors.gray1,
            padding: '5px 10px',
            borderRadius: 17,
            fontWeight: 'normal',
        },
        divider: {
            background: colors.main,
            margin: '0 32px',
        },
        author: {
            display: 'flex',
            alignItems: 'flex-end',
            color: colors.black,
            fontWeight: 500,
            fontSize: 16,
        },
        position: {
            color: colors.gray1,
            fontWeight: 400,
            fontSize: 16,
        },
        message: {
            color: colors.black,
            fontWeight: 400,
            fontSize: 14,
            whiteSpace: 'pre-line',
            wordBreak: 'break-word',
            [theme.breakpoints.down('xs')]: {
                marginTop: 14
            },
        },
        messageDivider: {
            background: colors.stroke,
        },
        messageDate: {
            alignItems: 'flex-end',
            display: 'flex',
            color: colors.gray1,
            fontWeight: 400,
            fontSize: 14,
        },
        fileButton: {
            "&:hover": {
                "& $fileIcon": {
                    display: "none",
                },
                "& $downloadIcon": {
                    display: "inline-block",
                },
            },
        },
        fileIcon: {
            display: 'inline-block',
        },
        downloadIcon: {
            display: 'none',
        },
        breakText: {
            wordBreak: 'break-all',
        },
    })
);

export const UnreadMessages = withStyles({
    root: {
        display: 'flex',
        margin: '0 16px',

        "& .MuiListItemText-primary": {
            color: colors.main,
        },
    }
})(ListItemText);
    