import { Box, useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { HealthReportMode, HealthReportSections } from "../models/healthReport.models";
import { HealthReportBasePage, HealthReportMobileBasePage } from './HealthReportBasePage';
import { HealthReportPageProps } from "./healthReportPageProps";
import commonUseStyles from "../../common/styles/common.styles";
import clsx from "clsx";
import { RoutesConstants } from '../../../constants/route.constants';
import { ImageConstants } from '../../../constants/image.constants';
import img from "@img/HealthReport_Pg18_Image.png";

export const HealthReportChronicDisease0Page: React.FC<HealthReportPageProps> = (props: HealthReportPageProps) => {
    const {
        pageNumber,
        practice
    } = props;
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        props.mode !== HealthReportMode.PrintMode && isSmallScreen ? (
            <HealthReportMobileBasePage section={HealthReportSections.ChronicDisease} pageNumber={pageNumber} practice={practice} firstPage>
                <Box px={2}>
                    <Box>
                        <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeBigReport, commonClasses.textRegularReport)}>Cardiovascular Disease</span>
                        <br />
                        <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeBigReport, commonClasses.textRegularReport)}>Dementia</span>
                        <br />
                        <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeBigReport, commonClasses.textRegularReport)}>Insulin Resistance</span>
                        <br />
                        <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeBigReport, commonClasses.textRegularReport)}>Inflammation</span>
                    </Box>
                    <Box mt="30px">
                        <img src={RoutesConstants.cdnBaseLink + ImageConstants.healthReportPg18ImagePath} style={{ width: '100%', height: 'auto' }} alt="img" />
                    </Box>
                </Box>
            </HealthReportMobileBasePage>
        ) : (
            <HealthReportBasePage section={HealthReportSections.ChronicDisease} pageNumber={pageNumber} practice={practice}>
                <Box px={15} pt={5}>
                    <Box>
                        <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeBigReport, commonClasses.textMediumReport)}>Cardiovascular Disease</span>
                        <br />
                        <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeBigReport, commonClasses.textMediumReport)}>Dementia</span>
                        <br />
                        <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeBigReport, commonClasses.textMediumReport)}>Insulin Resistance</span>
                        <br />
                        <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeBigReport, commonClasses.textMediumReport)}>Inflammation</span>
                    </Box>
                    <Box mt={15}>
                        {
                            props.mode === HealthReportMode.PrintMode
                                ? <img src={img} style={{ width: '100%', height: '950px' }} alt="img" />
                                : <img src={RoutesConstants.cdnBaseLink + ImageConstants.healthReportPg18ImagePath} style={{ width: '100%', height: '950px' }} alt="img" />
                        }
                    </Box>
                </Box>
            </HealthReportBasePage>
        )
    )
}

