import { Box, CircularProgress, IconButton, ListItemIcon, Popover, Table, TableBody, TableContainer, TableHead, TableRow, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import InfoIcon from '@material-ui/icons/Info';
import React from "react";
import { WildHealthMenuItem } from "../../../common/components/wildHealthMenu/WildHealthMenu";
import { CommonPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/CommonPlaceHolder";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { FileInputType } from "../../models/input.models";
import { InputsComponentProps } from "../inputsComponentProps";
import { useFacade } from "./uploadInputsFilesComponent.hooks";
import moment from 'moment';
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import commonUseStyles from "../../../common/styles/common.styles";
import { toCurrentTimeZone } from '../../../timezones/helpers/timezone';
import { ReactComponent as Microbiome } from "@img/Microbiome.svg";
import { TutorialModel } from "../../../common/dialogs/tutorialDialog/tutorialDialog.hooks";
import { TutorialDialog } from "../../../common/dialogs/tutorialDialog/TutorialDialog";

interface UploadInputsFilesComponentProps extends InputsComponentProps {
    title: string;
    fileType: FileInputType;
    canDownload: boolean;
    canUpload?: boolean;
}

export const UploadInputsFilesComponent: React.FC<UploadInputsFilesComponentProps> = (props: UploadInputsFilesComponentProps) => {
    const { patientId, title, fileType, canDownload, canUpload } = props;

    const [
        {
            isDownloading,
            isUploading,
            isActionsOpen,
            isDialogOpened,
            actionsAnchorEl,
            selectedFile,
            files,
        },
        handleToggleActions,
        handleFileUpload,
        handleDownload,
        handleDelete,
        handleView,
        handleToggleDialog
    ] = useFacade(patientId, fileType);

    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const getAction = (id: number) => patientId ? (event: React.MouseEvent<HTMLButtonElement>) => handleToggleActions(id, event.currentTarget) : () => handleDownload(id);
    const actionsIcon = patientId ? <MoreVertIcon /> : <GetAppIcon />;

    const tutorialContent: TutorialModel[] = [
        {
            id: 1,
            featuredImage: <Microbiome />,
            title: 'All microbiome results on one page',
            content: 'Upload all your microbiome results documents and store them in one place. Get started by using the “Upload File” button and select the test you want to add from your computer.'
        },
    ]

    const renderTable = () => {
        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <WildHealthTableCell align="left">
                                File Name
                            </WildHealthTableCell>
                            <WildHealthTableCell align="left">
                                Date Of Upload
                            </WildHealthTableCell>
                            {
                                canDownload &&
                                <WildHealthTableCell style={{ width: 120 }} align="left">
                                    Action
                                </WildHealthTableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            files.map((item, index) =>
                                <WildHealthTableRow key={index}>
                                    <WildHealthTableCell align="left">
                                        {fileType === FileInputType.LabResults ? <Box className={commonClasses.pointer} onClick={() => handleView(item.id)}>{item.file.name}</Box> : item.file.name}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="left">
                                        {moment(toCurrentTimeZone(new Date(fileType === FileInputType.LabResults ? item.uploadedAt ?? item.observationDate : item.uploadedAt))).format("MM/DD/yyyy")}
                                    </WildHealthTableCell>
                                    {
                                        canDownload &&
                                        <WildHealthTableCell align="left">
                                            <IconButton
                                                id={`upload-inputs-files-${item.id}`}
                                                disabled={isDownloading}
                                                onClick={getAction(item.id)}
                                            >
                                                {isDownloading && item.id === selectedFile.id ? <CircularProgress size={24} className={commonClasses.colorMain} /> : actionsIcon}
                                            </IconButton>

                                        </WildHealthTableCell>
                                    }
                                </WildHealthTableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const displayUploadFileButton = () => {
        if (isUploading) {
            return (
                <Box width="70px" display="flex" justifyContent="center">
                    <CircularProgress size={24} className={commonClasses.colorMain} />
                </Box>
            )
        }
        return (
            <WildHealthButton
                id="upload-inputs-files-upload"
                size="large"
                onClick={() => handleFileUpload(fileType)}
            >
                Upload File
            </WildHealthButton>
        )
    }

    return (
        <Box p={4}>
            <Box display='flex' justifyContent='space-between'>
                <Box fontSize={18} fontWeight={500}>
                    {title}
                </Box>

                <Box display='flex' alignItems='center' gridGap={24}>
                    {fileType === FileInputType.MicrobiomeData && (
                        <Box display='flex' alignItems='center' gridGap={8} className="wh-tw-cursor-pointer" onClick={handleToggleDialog}>
                            <InfoIcon className="wh-tw-w-5 wh-tw-h-5 wh-tw-text-primaryV" />
                            <Box className="wh-tw-text-primaryV wh-tw-text-sm wh-tw-font-medium">{isSmallScreen ? 'Help' : 'Do you need help?'}</Box>
                        </Box>
                    )}
                    {canUpload && displayUploadFileButton()}
                </Box>
            </Box>

            <Box mt={3}>
                {
                    files && files.length ? renderTable() :
                        <CommonPlaceHolder message={`No ${title} yet.`} />
                }
            </Box>

            {
                patientId &&
                <Popover
                    keepMounted
                    anchorEl={actionsAnchorEl}
                    open={isActionsOpen}
                    onClose={() => handleToggleActions()}
                >
                    <WildHealthMenuItem
                        id="upload-inputs-files-download"
                        onClick={() => handleDownload(selectedFile.id)}
                        disabled={!selectedFile || !selectedFile.file || isUploading || isDownloading}
                    >
                        <ListItemIcon>
                            {isDownloading ? <CircularProgress size={24} className={commonClasses.colorMain} /> : <GetAppIcon />}
                        </ListItemIcon>
                        <Typography variant="inherit" noWrap>
                            Download
                        </Typography>
                    </WildHealthMenuItem>
                    <WildHealthMenuItem
                        id="upload-inputs-files-delete"
                        onClick={() => handleDelete(selectedFile.id, selectedFile.file.name)}
                        disabled={!selectedFile || !selectedFile.file || isUploading}
                    >
                        <ListItemIcon>
                            <DeleteIcon />
                        </ListItemIcon>
                        <Typography variant="inherit" noWrap>
                            Delete
                        </Typography>
                    </WildHealthMenuItem>
                </Popover>
            }
            <TutorialDialog
                opened={isDialogOpened}
                tutorialContent={tutorialContent}
                handleClose={handleToggleDialog}
            />
        </Box>
    )
};