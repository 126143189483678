import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import Resizer from "react-image-file-resizer";
import { onEmit } from "../../../common/helpers/on-emit";
import { InsuranceConfiguration, RealTimeEligibilityConfiguration, ClaimAutomationConfiguration, UpdateInsuranceConfigurationModel, CreateServiceConfigurationModel, getInitServiceConfiguration, UpdateServiceConfigurationModel, InsurancePlanModel } from "../../models/insurance.models";
import { insuranceQuery } from "../../stores/insurance.query";
import { insuranceManagementService } from "../../services/insuranceManagement.service";
import { authQuery } from "../../../auth/stores/auth";
import { addressService } from "../../../account/services/address.service";
import { getInitAddress } from "../../../common/models/address.models";

interface CollapseGroup {
  basicConfig: boolean;
  rteConfig: boolean;
  claimsAutoConfig: boolean;
}

interface CollapsableServiceConfig extends CreateServiceConfigurationModel {
  isOpen: boolean;
}

interface ConfigurationEditState {
  isLoading: boolean;
  isStateLoading: boolean;
  isUpdating: boolean;
  isServiceConfigUpdating: boolean;
  insuranceConfiguration: InsuranceConfiguration;
  rteConfiguration: RealTimeEligibilityConfiguration;
  claimAutomationConfig: ClaimAutomationConfiguration;
  serviceConfigurations: CollapsableServiceConfig[];
  mainConfigCollapse: CollapseGroup;
  logo: File;
  logoUrl: string;
  allInsurancePlans: InsurancePlanModel[];
}

const initialRTEConifg = {
  failureManagementType: 0,
  npi: '',
  tradingPartnerId: '',
  requireGroupNumber: false,
  includeCptCode: false,
  memberIdValidationRegex: ''
}

const initialClaimAutomationConfig = {
  useSubscriberName: false,
  addressOverride: getInitAddress()
}

export function useFacade(configurationId: number): [
  ConfigurationEditState,
  (field: string, value: any) => void,
  (field: string, value: any) => void,
  (field: string, value: any) => void,
  (field: string, value: any) => void,
  (field: string, value: any, index: number) => void,
  (field: string, value: any, key: string, index: number) => void,
  (value, index, key: string) => void,
  () => void,
  () => void,
  (index: number) => void,
  (index: number) => void,
  (field: string, value: Boolean) => void,
  (event) => void,
  () => void
] {
    const [state, setState] = useState({
      isLoading: true,
      isStateLoading: true,
      isUpdating: false,
      isServiceConfigUpdating: false,
      insuranceConfiguration: null,
      rteConfiguration: null,
      claimAutomationConfig: null,
      serviceConfigurations: [],
      mainConfigCollapse: {
        basicConfig: true,
        rteConfig: true,
        claimsAutoConfig: true
      },
      logo: null,
      logoUrl: '',
      allInsurancePlans: []
    } as ConfigurationEditState);

    const handleChanges = (field: string, value: any) => {
      const insuranceConfiguration = state.insuranceConfiguration;
      insuranceConfiguration[field] = value;

      setState(state => ({
        ...state,
        insuranceConfiguration: insuranceConfiguration
      }));
    }

    const handleChangeRTEConfigs = (field: string, value: any) => {
      const rteConfiguration = state.rteConfiguration;
      rteConfiguration[field] = value;

      setState(state => ({
        ...state,
        insuranceConfiguration: {
          ...state.insuranceConfiguration,
          rteConfig: rteConfiguration
        },
        rteConfiguration
      }));
    }

    const handleChangeClaimAutomationConfigs = (field: string, value: any) => {
      const claimAutomationConfig = state.claimAutomationConfig;
      claimAutomationConfig[field] = value;

      setState(state => ({
        ...state,
        insuranceConfiguration: {
          ...state.insuranceConfiguration,
          claimAutomationConfig: claimAutomationConfig
        },
        claimAutomationConfig
      }));
    }

    const handleChangeAddressOverrides = (field: string, value: any) => {
      const addressOverride = state.claimAutomationConfig.addressOverride;
      addressOverride[field] = value;

      setState(state => ({
        ...state,
        claimAutomationConfig: {
          ...state.claimAutomationConfig,
          addressOverride
        }
      }));
    }

    const handleChangeBasicServiceConfigs = (field: string, value: any, index: number) => {
      const serviceConfiguration = state.serviceConfigurations[index];
      serviceConfiguration[field] = value;

      setState(state => ({
        ...state,
        serviceConfigurations: state.serviceConfigurations.map((x,i) => i === index ? serviceConfiguration : x )
      }));
    }

    const handleChangeSubServiceConfigs = (field: string, value: any, key: string, index: number) => {
      const serviceConfiguration = state.serviceConfigurations[index];
      serviceConfiguration[key][field] = value;

      setState(state => ({
        ...state,
        serviceConfigurations: state.serviceConfigurations.map((x,i) => i === index ? serviceConfiguration : x )
      }));
    }

    const handleServiceConfigMultiSelectChange = (value, index, key) => {
      const serviceConfiguration = state.serviceConfigurations[index];
      serviceConfiguration[key] = value.map(x => x.id)
      setState(state => ({
        ...state,
        serviceConfigurations: state.serviceConfigurations.map((x,i) => i === index ? serviceConfiguration : x )
      }));
    }

    const handleMainConfigToggle = (field: string, value: Boolean) => {
      const mainConfigCollapse = state.mainConfigCollapse;
      mainConfigCollapse[field] = value;
      setState(state => ({
        ...state,
        mainConfigCollapse
      }));
    }

    const handleUpdateInsuranceConfiguration = () => {
      setState({...state, isUpdating: true })

      const cb = () => setState(state => ({ ...state, isUpdating: false }));

      const updateConfiguration : UpdateInsuranceConfigurationModel = {
        ...state.insuranceConfiguration,
        insurancePlanIds: [],
        insuranceId: state.insuranceConfiguration.insurance.id,
        practiceId: authQuery.getCurrentPracticeId()
      }

      if (state.logo) {
        const formData = new FormData();
        formData.append('file', state.logo);
        insuranceManagementService.uploadConfigurationLogo(updateConfiguration.id, formData).subscribe((url) => {
          updateConfiguration.payerLogo = url;
          insuranceManagementService.updateInsuranceConfiguration(updateConfiguration).subscribe(cb, cb);
        }, cb)
      } else {
        insuranceManagementService.updateInsuranceConfiguration(updateConfiguration).subscribe(cb, cb);
      }
    }

    const handleAddServiceConfig = () => {
      const createServiceConfiguration : CollapsableServiceConfig = getInitServiceConfiguration();
      createServiceConfiguration.insuranceConfigId = configurationId;
      setState(state => ({
        ...state,
        serviceConfigurations: [...state.serviceConfigurations, createServiceConfiguration]
      }))
    }

    const handleUpdateServiceConfiguration = (index: number) => {
      setState({...state, isServiceConfigUpdating: true })
      const createServiceConfiguration = state.serviceConfigurations[index];

      const cb = () => setState(state => ({ ...state, isServiceConfigUpdating: false }));
      const action = !createServiceConfiguration?.id ? insuranceManagementService.createServiceConfiguration(createServiceConfiguration) : insuranceManagementService.updateServiceConfiguration(createServiceConfiguration as UpdateServiceConfigurationModel);
      action.subscribe((serviceConfiguration) => {
        setState(state => ({ ...state, isServiceConfigUpdating: false, serviceConfigurations: state.serviceConfigurations.map((x,i) => i === index ? {...serviceConfiguration, stateIds: [], isOpen: true} : x ) }));
      }, cb);
    }

    const handleRemoveServiceconfig = (index: number) => {
      const serviceConfiguration = state.serviceConfigurations[index];
      if (!serviceConfiguration?.id) {
        state.serviceConfigurations.splice(index, 1)
        setState(state => ({
          ...state,
        }))
        return;
      }
      setState({...state, isServiceConfigUpdating: true })
      const cb = () => setState(state => ({ ...state, isServiceConfigUpdating: false }));
      insuranceManagementService.deleteServiceConfiguration(configurationId, serviceConfiguration.id).subscribe(() => {
        state.serviceConfigurations.splice(index, 1)
        setState(state => ({ ...state, isServiceConfigUpdating: false }));
      }, cb)
    }

    const resizeFile = (file) : Promise<any> =>
      new Promise((resolve) => {
        Resizer.imageFileResizer(
          file,
          100,
          100,
          "PNG",
          50,
          0,
          (file) => {
            resolve(file);
          },
          "file"
        );
      });

    const handleLogoUpload = (event) => {
      event.preventDefault();
      const file = event.target.files[0];

      resizeFile(file).then((result) => {
        setState(state => ({
          ...state,
          logo: result,
          logoUrl: URL.createObjectURL(result)
        }));
      });

      event.target.value = '';
    }

    const handleDeleteLogo = () => {
      setState(state => ({
        ...state,
        logo: null,
        logoUrl: ''
      }));
    }

    const useEffectCB = () => {
      const subscriptions: Subscription[] = [
        onEmit<InsuranceConfiguration>(insuranceQuery.targetInsuranceConfiguration$, targetInsuranceConfiguration => {
          setState(state => ({
            ...state,
            insuranceConfiguration: targetInsuranceConfiguration,
            rteConfiguration: targetInsuranceConfiguration?.rteConfig ? targetInsuranceConfiguration.rteConfig : Object.assign({}, initialRTEConifg),
            claimAutomationConfig: targetInsuranceConfiguration?.claimAutomationConfig ? targetInsuranceConfiguration.claimAutomationConfig : Object.assign({}, initialClaimAutomationConfig),
            serviceConfigurations: targetInsuranceConfiguration?.serviceConfigurations ? targetInsuranceConfiguration.serviceConfigurations.map(i => i as CollapsableServiceConfig) : []
          }));
        })
      ];

      const cb = () => setState(state => ({ ...state, isLoading: false }));
      const getStateCB = () => setState(state => ({ ...state, isStateLoading: false }))
      const getInsurancePlansCB = (plans) => setState(state => ({ ...state, allInsurancePlans: plans, isStateLoading: false }))


      insuranceManagementService.getInsuranceConfigurationById(configurationId).subscribe(cb, cb);
      insuranceManagementService.getAvailableInsurancePlans(configurationId).subscribe(getInsurancePlansCB, getInsurancePlansCB);
      addressService.getStates().subscribe(getStateCB, getStateCB);

      return () => {
        subscriptions.map(it => it.unsubscribe())
      };
    }

    useEffect(useEffectCB, []);

    return [
        state,
        handleChanges,
        handleChangeRTEConfigs,
        handleChangeClaimAutomationConfigs,
        handleChangeAddressOverrides,
        handleChangeBasicServiceConfigs,
        handleChangeSubServiceConfigs,
        handleServiceConfigMultiSelectChange,
        handleUpdateInsuranceConfiguration,
        handleAddServiceConfig,
        handleUpdateServiceConfiguration,
        handleRemoveServiceconfig,
        handleMainConfigToggle,
        handleLogoUpload,
        handleDeleteLogo
    ];
}