import { Box, Collapse, Grid, IconButton, List, ListItem, useMediaQuery, useTheme } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DescriptionIcon from '@material-ui/icons/Description';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React from 'react';
import { AttachmentsPlaceHolder } from '../../../common/components/wildHealthPlaceHolder/AttachmentsPlaceHolder';
import { AttachedFileModel } from '../../models/message.models';
import { Group, useFacade } from "./attachmentsViewComponent.hooks";
import { FileName, Title, useStyles } from "../attachmentsComponent/attachmentsComponent.styles";
import GetAppIcon from '@material-ui/icons/GetApp';
import InsertPhoto from '@material-ui/icons/InsertPhoto';
import { WildHealthTabControl } from "../../../common/components/wildHealthTabControl/WildHealthTabControl";
import { TabItem } from "../../../common/components/wildHealthTabControl/wildHealthTabControl.hooks";
import { useStylesFullWidthTabs } from "../../../common/components/wildHealthTabControl/wildHealthTabControl.styles";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";

export const AttachmentsViewComponent: React.FC = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const sideBarTabsStyle = useStylesFullWidthTabs();

    const [
        {
            groups,
            pdfGroup,
            imageGroup,
            isLoading
        },
        handleToggleSection,
        handleDownloadAttachment,
    ] = useFacade();

    const renderImage = (media: AttachedFileModel, index: number) => {
        return (

            <ListItem key={index}>
                <Box className={classes.document}>
                    <IconButton id="attachments-download-file" className={classes.fileButton} onClick={() => handleDownloadAttachment(media.file[0].sid)} size='small'>
                        <InsertPhoto className={classes.fileIcon} />
                        <GetAppIcon className={classes.downloadIcon} />
                    </IconButton>
                    <Box ml={1}>
                        <FileName primary={media.file[0].filename} />
                    </Box>
                </Box>
            </ListItem>
        )
    }

    const renderPdf = (media: AttachedFileModel, index: number) => {
        return (
            <ListItem key={index}>
                <Box className={classes.document}>
                    <IconButton id="attachments-download-file" className={classes.fileButton} onClick={() => handleDownloadAttachment(media.file[0].sid)} size='small'>
                        <DescriptionIcon className={classes.fileIcon} />
                        <GetAppIcon className={classes.downloadIcon} />
                    </IconButton>
                    <Box ml={1}>
                        <FileName primary={media.file[0].filename} />
                    </Box>
                </Box>
            </ListItem>
        )
    }

    const renderGroup = (group: Group, index: number) => {
        const isImageGroup = group.title.includes("Image");
        const items = group.items.map(isImageGroup ? renderImage : renderPdf);
  
        if (isSmallScreen) {
            return (
            <React.Fragment key={`${index}-tab-item`}>
                {isImageGroup ? <Grid container>{items} </Grid> : <List>{items}</List>}
            </React.Fragment>
            );
        } else {
            return (
                <React.Fragment key={`${index}-fragment`}>
                    <ListItem
                        id="attachments-toggle-section"
                        key={index}
                        button
                        onClick={() => handleToggleSection(index)}
                    >
                        <Title primary={group.title} />
                        {group.isOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse key={`${index}-group`} in={group.isOpen} timeout="auto" unmountOnExit>
                        {isImageGroup ? <Grid container>{items}</Grid> : <List>{items}</List>}
                    </Collapse>
                </React.Fragment>
            );
        }
    };

    const renderContent = () => {
        if (isSmallScreen) {
            if (isLoading) {
                return <WildHealthLinearProgress />
            }
            let pdfs, images;
            if (groups && groups.length) {
                pdfs = groups.find((group) => group.title.includes("PDFs"));
                images = groups.find((group) => group.title.includes("Image"));
            }
            const tabs: TabItem[] = [
                {
                    title: "PDFs",
                    content: pdfGroup.length ? (
                        renderGroup(pdfs, 0)
                    ) : (
                        <AttachmentsPlaceHolder message="No Pdfs yet." />
                    ),
                },
                {
                    title: "Images",
                    content: imageGroup.length ? (
                        renderGroup(images, 1)
                    ) : (
                        <AttachmentsPlaceHolder message="No Images yet." />
                    ),
                },
            ];
            return (
                <Box width="100%" className={classes.content}>
                    <WildHealthTabControl items={tabs} className={sideBarTabsStyle} />
                </Box>
            );
        } else {
            return (
                <List className={classes.content}>
                    {groups && groups.length ? (
                        groups.map((group, index) => renderGroup(group, index))
                    ) : (
                        <AttachmentsPlaceHolder message="No attachments." />
                    )}
                </List>
            );
        }
    };

    return (
        <Box>
            <Box className={classes.titleContainer}>
                {!isSmallScreen && <AttachFileIcon className={classes.titleIcon} />}
                <Box className={classes.title}>Attachments</Box>
            </Box>
            {renderContent()}
        </Box>
    )
}