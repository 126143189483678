import moment from "moment";
import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { onEmit } from '../../../common/helpers/on-emit';
import { employeeConversationsStore } from "../../stores/employeeConversationsStore/employeeConversations.store";
import { MessageGroupModel } from "../../models/message.models";
import { authQuery } from "../../../auth/stores/auth";
import {
    AcceptInteractionModel,
    RejectInteractionModel,
    TargetInteractionModel,
    DeleteMessageModel,
    EmployeeConversationModel,
    ReactOnConversationMessageAlertModel,
    MessageAlertActionType,
    llmConversationTypes
} from "../../models/conversation.models";
import { employeeConversationsQuery } from "../../stores/employeeConversationsStore/employeeConversations.query";
import { conversationsService } from "../../services/conversations.service";
import { snackService } from "../../../common/snack/state";
import { patientConversationsQuery } from "../../stores/patientConversationsStore/patientConversations.query";
import { patientConversationsStore } from "../../stores/patientConversationsStore/patientConversations.store";


interface MessageComponentState {
    menuAnchor: HTMLElement | null,
    editingTarget: any,
    acceptingLoading: boolean,
    isDeleteOpen: boolean,
    isForwardOpen: boolean,
    isDeleting: boolean,
    isForwarding: boolean,
    deleteMessageRequest: DeleteMessageModel,
    reactOnAlertMessageRequest: ReactOnConversationMessageAlertModel,
    targetConversation: EmployeeConversationModel
}

const initialRequest: DeleteMessageModel = {
    conversationId: '',
    messageId: '',
    reason: 0
}

const initialAlertRequest: ReactOnConversationMessageAlertModel = {
    alertId: '',
    conversationId: '',
    messageId: '',
    actionType: 0,
    details: ''
}

export function useFacade(messageGroup: MessageGroupModel, isLastMessage?: boolean, handleUpdateInteraction?: Function): [
    Function, Function,
    MessageComponentState,
    (event: React.MouseEvent<HTMLButtonElement>) => void,
    (event: any) => void,
    (targetAiMessage: TargetInteractionModel) => void,
    (targetAiMessage: TargetInteractionModel) => void,
    (targetAiMessage: TargetInteractionModel) => void,
    (conversationId: string, messageId: string) => void,
    () => void,
    (value: any) => void,
    () => void,
    () => void,
    (actionType: MessageAlertActionType) => void
] {
    const [state, setState] = useState({
        menuAnchor: null,
        editingTarget: null,
        acceptingLoading: false,
        isDeleting: false,
        isForwarding: false,
        isDeleteOpen: false,
        isForwardOpen: false,
        deleteMessageRequest: initialRequest,
        reactOnAlertMessageRequest: initialAlertRequest,
        targetConversation: null
    } as MessageComponentState);

    const formatDate = (date: Date) => {
        return moment(date).format('HH:mm a');
    }

    const downloadMedia = (media: any) => {
        media.getContentTemporaryUrl().then(url => window.open(url));
    }

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setState({ ...state, menuAnchor: event.currentTarget });
    };

    const handleCloseMenu = (event: any) => {
        event.stopPropagation();
        setState({ ...state, menuAnchor: null });
    };

    const handleOpenDeleteMessageDialog = (conversationId: string, messageId: string) => {
        setState(state => ({ ...state, deleteMessageRequest: {...state.deleteMessageRequest, conversationId, messageId}, isDeleteOpen: true }))
    };

    const handleCloseDeleteMessageDialog = () => {
        setState(state => ({ ...state, deleteMessageRequest: Object.assign({}, initialRequest), isDeleteOpen: false }))
    };

    const handleChangeDeleteReason = (value: any) => {
        setState(state => ({ ...state, deleteMessageRequest: {...state.deleteMessageRequest, reason: value} }))
    };

    const handleDelete = () => {
        setState(state => ({ ...state, isDeleting: true }))
        
        const cb = () => {
            setState((state) => ({
                ...state,
                isDeleting: false,
                isDeleteOpen: false,
                deleteMessageRequest: Object.assign({}, initialRequest),
            }))
        }

        conversationsService.removeMessage(state.deleteMessageRequest).subscribe(() => {
            cb();
            handleUpdateInteraction('')
        }, () => setState(state => ({ ...state, isDeleting: false })))
    };

    const handleAcceptInteraction = (targetAiMessage: TargetInteractionModel) => {
        const participantExternalVendorId = employeeConversationsQuery.getAuthor().conversationIdentity;
        const acceptInteraction: AcceptInteractionModel = {
            conversationId: targetAiMessage.conversationId,
            messageId: targetAiMessage.messageSid,
            referenceId: targetAiMessage.interaction.ReferenceId,
            participantId: participantExternalVendorId,
            detail: targetAiMessage.interaction.Detail
        }
        employeeConversationsStore.aiMessageAccept();
        conversationsService.acceptAiMessage(acceptInteraction).subscribe(() => {
            employeeConversationsStore.aiMessageAccept();
            handleUpdateInteraction(targetAiMessage.interaction.Detail)
        }, () => { handleUpdateInteraction('') })
    }

    const handleRejectInteraction = (targetAiMessage: TargetInteractionModel) => {
        const participantExternalVendorId = employeeConversationsQuery.getAuthor().conversationIdentity;
        const rejectInteraction: RejectInteractionModel = {
            conversationId: targetAiMessage.conversationId,
            messageId: targetAiMessage.messageSid,
            referenceId: targetAiMessage.interaction.ReferenceId,
            participantId: participantExternalVendorId,
        }
        const cb = () => {
            setState(state => ({
                ...state,
                menuAnchor: null
            }));
            handleUpdateInteraction('');
        }
        conversationsService.rejectAiMessage(rejectInteraction).subscribe(cb, cb)
    }

    const handleEditInteraction = (targetAiMessage: TargetInteractionModel) => {
        setState(state => ({
            ...state,
            menuAnchor: null
        }));
        employeeConversationsStore.startAiEditing(targetAiMessage);
    }

    const handleCloseForward = () => {
        setState(state => ({ ...state, isForwardOpen: false }))
    }

    const handleSubmitForward = (actionType: MessageAlertActionType) => {
        setState(state => ({ ...state, isForwarding: true, isForwardOpen: false }))
        patientConversationsStore.updateSendMessageStatus();
        const cb = () => setState(state => ({...state, isForwarding: false}))
        conversationsService.reactOnAlert({...state.reactOnAlertMessageRequest, actionType: MessageAlertActionType.Accepted}).subscribe(() => {
            if (actionType === MessageAlertActionType.Accepted) {
                snackService.success('Your message was forwarded!');
            }
            cb()
        }, cb)
    }

    useEffect(() => {
        if (authQuery.isLoggedIn() && employeeConversationsQuery.getAttachmentAdding()) {
            messageGroup.messages.forEach(message => {
                if (message.originalMessage?.state?.type === 'media') {
                    employeeConversationsStore.addTargetAttachments([{ file: message.originalMessage.media, url: '', fileName: message.originalMessage.state.media.state?.filename ?? message.originalMessage.state.body }]);
                }
            })
        } else {
            employeeConversationsStore.resetAttachments();
        }
    }, [messageGroup, authQuery]);

    useEffect(() => {
        if (authQuery.isLoggedIn() && authQuery.isEmployeeUser() && !patientConversationsQuery.getSendNewMessageStatus() && !state.isForwardOpen && isLastMessage) {
            messageGroup.messages.forEach(message => {
                let formattedMessage: any = message.originalMessage;
                if (!!formattedMessage?.state?.attributes?.alerts) {
                    const alerts = formattedMessage?.state?.attributes?.alerts;
                    if (alerts.length > 0) {
                        patientConversationsStore.updateSendMessageStatus();
                        setState(state => ({
                            ...state,
                            isForwardOpen: true,
                            reactOnAlertMessageRequest: {
                                ...state.reactOnAlertMessageRequest,
                                alertId: alerts[alerts.length - 1]?.id,
                                conversationId: formattedMessage?.conversation?.sid,
                                messageId: formattedMessage?.state?.sid,
                            }
                        }))
                    }
                }
            })
        }
    }, [messageGroup, authQuery, isLastMessage]);

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<any>(employeeConversationsQuery.editingTargetAiMessage$, target => 
                setState(state => ({ ...state, editingTarget: target }))
            ),
            onEmit<boolean>(employeeConversationsQuery.aiMessageAcceptLoading$, loading => 
                setState(state => ({ ...state, acceptingLoading: loading }))
            ),
            onEmit<EmployeeConversationModel>(employeeConversationsQuery.targetConversation$, targetConversation =>
                {
                    // prevent default conversation components from loading llm conversations
                    if (targetConversation && llmConversationTypes.includes(targetConversation.type)) {
                        return;
                    }

                    setState(state => ({ ...state, targetConversation: targetConversation }))
                }
            ),
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, []);

    return [
        formatDate,
        downloadMedia,
        state,
        handleOpenMenu,
        handleCloseMenu,
        handleAcceptInteraction,
        handleRejectInteraction,
        handleEditInteraction,
        handleOpenDeleteMessageDialog,
        handleCloseDeleteMessageDialog,
        handleChangeDeleteReason,
        handleDelete,
        handleCloseForward,
        handleSubmitForward
    ];
}