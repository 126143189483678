export class PagesNamesService {
    public static dashboard = `Dashboard`;
    public static login = `Login`;
    public static loginRxNT = `Login RxNT`;
    public static getStarted = `Sign-Up Plans`;
    public static buyNewSubscription = `Buy New Subscription`;
    public static paymentSuccess = `Payment Success`;
    public static healthQuestionnaire = `Questionnaire`;
    public static healthQuestionnaireCompleted = `Questionnaire Completed`;
    public static startQuestionnaire = `Questionnaire Starting`;

    public static restorePassword = `Restore Password`;
    public static forgotPassword = `Forgot Password`;
    public static activateProfile = `Activate Profile`;
    public static confirmAgreements = `Confirm Agreements`;
    public static termsOfUse = `Terms of Use`
    public static privacyPolicy = `Privacy Policy`

    public static myAccount = `My Account`;
    public static resetPassword = `Reset Password`;
    public static myPatients = `My Patients`;
    public static allPatients = `All Patients`;
    public static patientDashboard = `Health Score`;
    public static intakePatients = `Submission Received`;

    public static results = `Results`;
    public static generalInputs = `General Inputs`;
    public static documents = `Documents`;
    public static labsInputs = `Labs Inputs`;
    public static microbiomeInputs = `Microbiome Inputs`;
    public static dataFilesInputs = `Data Files`;
    public static medicalHistory = `Medical History`;
    public static appointments = `Appointments`;
    public static pendingNotes = `Pending Notes`;
    public static draftNotes = `Draft Notes`;
    public static signOffNotes = `Sign Off Notes`;
    public static awaitingApproval = `Awaiting Approval`;
    public static questionnaire = `Health Forms`;
    public static healthReport = `Health Report`;
    public static addOnReports = `Add-On Reports`;
    public static healthPlan = `Health Plan`;
    public static healthScore = `Health Score`;
    public static healthSummary = `Health Summary`;
    public static supplements = `Supplements/Meds`;
    public static coachShortcuts = `Shortcuts`;
    public static patientProfile = `Patient Profile`;
    public static manageDeliveryOrders = `Add-On Orders`;
    public static submitPatient = `Patient Submission`;
    public static healthQuestionnaireResult = `Questionnaire`;

    public static notes = `Visit Notes`;
    public static orders = `Orders`;
    public static otherOrder = `Other Order`;
    public static referralOrder = `Referral Order`;
    public static dnaOrderDropship = `DNA Order Dropship`;
    public static noteDetail = `Note Detail's`;
    public static scheduling = `Scheduling`;
    public static employees = `Users & Pods`;
    public static dnaFiles = `DNA Files`;
    public static unmatchedLabs = `Unmatched Labs`;

    public static leadSources = `Analytics`;
    public static createInitialConsult = `Create Initial Consult`;
    public static joinPractice = `Join Practice`;
    public static joinPracticeSuccess = `Join Practice Success`;

    public static fellowship = `Fellowship`;
    public static pmFellowship = `Precision Medicine Fellowship`;

    public static vitalsHistory = `Vitals History`;
    public static messaging = `Messaging`;
    public static patientsSubmissions = `Patients Submissions`;
    public static chat = `Chat`;
    public static messageSettings = `Message Settings`;
    public static genericPlayground = 'Generic Playground';

    public static referFriend = `Refer a Friend`;
    public static insurance = `Insurance`;

    public static academy = `Academy`;
    public static courseHistory = `Course History`;
    public static engineeringAdminTool = `Engineering Admin Tool`;
    public static employers = `Employers`;
    public static accountMembershipActivation = `Membership`;
    
    public static syncRecords = `Sync Records`;
    public static eligibleInsurances = `Eligible Insurances`;
    public static insuranceConfigurations = `Insurance Configurations`;
    public static patientResponsibility = `Patient Responsibility`;
    
    public static promoCodes = `Promo Codes`;
    public static messagingAdminPanel = `Messaging Admin Panel`;
    public static messageForwarding = `Message Forwarding`;
    public static alertsHistory = `Alerts History`; 
    public static banners = `Banners`; 
    public static healthReportTemplates = `Health Report Templates`;

    public static commonGoals = `Common Goals`;
    public static commonMdms = `Common Mdms`;
    public static commonSupplements = `Common Supplements`;
    public static commonOrders = `Common Orders`;
    public static recommendations = `Recommendations`;
    public static opsAdminTools = `OPS Admin Tools`;

    public static myTasks = `My Roadmap`;
    public static kbDocuments = `Knowledge Base Documents`;
    public static conversationTemplates = `Conversation Templates`;
    public static employersProfiles = `Employers Profiles`;
}
