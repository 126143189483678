import React from "react";
import {useFacade} from "./newDocumentsBadgeComponent.hooks";
import {Badge} from "@material-ui/core";

interface NewDocumentsComponentProps {
    patientId: number;
}

export const NewDocumentsComponent: React.FC<NewDocumentsComponentProps> = (props:NewDocumentsComponentProps) => {
    const{
        patientId
    } = props;

    const [newDocuments] = useFacade(patientId);

    return (
        <>
            {
                newDocuments
                    ? <Badge variant="dot" color="secondary"/>
                    : <></>
            }
        </>
    );
}