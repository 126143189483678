import { makeStyles, Theme, createStyles } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { colors } from '../constants/colors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: colors.systemBlack,
        },
        link: {
            textDecoration: 'none',
            "&:hover": {
                color: colors.main,
                cursor: 'pointer',
            }
        },
        linkButton: {
            textDecoration: 'none',
            color: colors.main,
            cursor: 'pointer',
        }
    }),
);

export interface WildHealthLinkProps {
    href: string;
    content: any;
    id: string;
}

export const WildHealthLink = (props: WildHealthLinkProps) => {
    const { href, content, id } = props;
    const classes = useStyles();

    return (
        <Link id={id} to={href} className={classes.root}>
            <p className={classes.link}>{content}</p>
        </Link>
    )
}

export interface WildHealthLinkButtonProps {
    onClick: Function
    linkButton?: boolean
    content: any
    id: string
}

export const WildHealthLinkButton = (props: WildHealthLinkButtonProps) => {
    const classes = useStyles()

    return (<span 
        id={props.id} 
        className={props.linkButton ? classes.linkButton : 'pointer-link'} 
        onClick={() => props.onClick()}>
        {props.content}
    </span>);
}

interface WildHealthExternalLinkButtonProps {
    link: string;
    children?: JSX.Element;
    id: string;
}

export const WildHealthExternalLinkButton: React.FC<WildHealthExternalLinkButtonProps> = (props: WildHealthExternalLinkButtonProps) => {
    const { link, children, id } = props;
    const url = link ? link.startsWith('http://') || link.startsWith('https://') ? link : `http://${link}` : '';

    return (
        url ?
            <WildHealthLinkButton id={id} onClick={() => window.open(url)} content={children ?? 'Link to Purchase'} /> :
            <></>
    )
}
