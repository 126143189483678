import { useState } from "react";
import { Observable } from "rxjs";
import { IPersonModel } from "../../../common/models/user.models";

export interface SelectUserDialogComponentState {
    isUsersLoading: boolean;
    canSubmit: boolean;
    users: IPersonModel[];
    selectedUser: IPersonModel;
}

const defaultState: SelectUserDialogComponentState = {
    isUsersLoading: false,
    canSubmit: false,
    users: [],
    selectedUser: null,
}

export function useFacade(handleGetUsers: (searchQuery: string) => Observable<any>, handleSubmit: (userId: number) => void, handleCancel: () => void, initialState = defaultState): [SelectUserDialogComponentState, (id: number) => void, (searchQuery: string) => void, () => void, () => void] {
    const [state, setState] = useState(initialState);

    const handleUserSelect = (id: number) => {
        const user = state.users.find(i => i.id === id);
        if (user) {
            setState({ ...state, selectedUser: user, canSubmit: true });
        }
    }

    const handleUserSearch = (searchQuery: string) => {
        setState(state => ({ ...state, isUsersLoading: true }));

        const successCB = (employees) => setState(state => ({ ...state, isUsersLoading: false, users: employees.filter(x => !x.isDelegated) }));
        const errorCB = () => setState(state => ({ ...state, isUsersLoading: false }));

        handleGetUsers(searchQuery).subscribe(successCB, errorCB);
    }

    const handleCloseDialog = () => {
        setState({ ...defaultState });
        handleCancel();
    }

    const handleApprove = () => {
        handleSubmit(state.selectedUser.id);
        handleCloseDialog();
    }

    return [state, handleUserSelect, handleUserSearch, handleCloseDialog, handleApprove];
}
