import {Store, StoreConfig} from '@datorama/akita';
import {PaymentPeriodModel} from "../../models/paymentPeriod.models";
import {PaymentPlanId, PaymentPlanModel, PaymentPlanType} from "../../models/paymentPlan.models";
import {PaymentCouponModel, PaymentPriceModel} from "../../models/paymentPrice.models";
import {EmployerProductModel} from "../../models/employerProduct.model";
import { PromoCodeVewModel } from '../../models/paymentCoupon.model';

export enum PaymentSteps {
    SelectFounder = 0,
    SelectPlan = 1,
    SelectPeriod = 2,
    Checkout = 3
}

/**
 * Represents payment plans state
 */
export interface PaymentPlansState {
    plans: PaymentPlanModel[];
    trialPlan: PaymentPlanModel;
    regularPlans: PaymentPlanModel[];
    activationPlans: PaymentPlanModel[];
    founderPlans: PaymentPlanModel[];
    proposedPlans: PaymentPlanModel[];
    upsellPlans: PaymentPlanModel[];
    activePaymentPlans: PaymentPlanModel[];
    selectedPlan: PaymentPlanModel | null;
    selectedPeriod: PaymentPeriodModel | null;
    selectedPrice: PaymentPriceModel | null;
    isCouponApplied: boolean;
    couponCode: string;
    coupon:PaymentCouponModel;
    employerProduct: EmployerProductModel;
    promoCodes: PromoCodeVewModel[];
}

/**
 * Creates initial state
 */
export function createInitialState(): PaymentPlansState {
    return {
        plans: [],
        trialPlan: null,
        regularPlans: [],
        activationPlans: null,
        founderPlans: [],
        proposedPlans: [],
        upsellPlans: [],
        activePaymentPlans: [],
        selectedPlan: null,
        selectedPeriod: null,
        selectedPrice: null,
        isCouponApplied: false,
        couponCode: '',
        coupon:null,
        employerProduct: null,
        promoCodes: []
    };
}

/**
 * Provides payment plans state management
 */
@StoreConfig({name: 'paymentPlans', resettable: true })
export class PaymentPlansStore extends Store<PaymentPlansState> {
    constructor() {
        super(createInitialState());
    }

    public updatePlans(plans: PaymentPlanModel[]): void {
        const trialPlan = plans.find(x => x.isTrial);
        const regularPlans = plans.filter(x => !x.isTrial && !x.isFounder && x.type === PaymentPlanType.Default);
        const activationPlans = plans.filter(el => el.id === PaymentPlanId.HealthCoachingTier || el.id === PaymentPlanId.WildHealthLight)
        const proposedPlans = plans.filter(x=> !x.isTrial && !x.isFounder && !x.isLimited && x.type === PaymentPlanType.Default);
        const founderPlans = plans.filter(x => !x.isTrial && x.isFounder && x.type === PaymentPlanType.Default);
        const upsellPlans = plans.filter(x => x.type === PaymentPlanType.Upsell);
        const activePaymentPlans = plans.filter(x => !x.isTrial && !x.isFounder && !x.isLimited)

        this.update({
            plans: plans,
            trialPlan: trialPlan,
            regularPlans: regularPlans,
            activationPlans: activationPlans,
            founderPlans: founderPlans,
            proposedPlans: proposedPlans,
            upsellPlans: upsellPlans,
            activePaymentPlans: activePaymentPlans
        });
    }

    public resetCoupon(): void {
        this.update({isCouponApplied: false, couponCode: '',coupon: null});
    }

    public resetPeriodPrices() : void {
        const selectedPeriod = this.getValue().selectedPeriod;

        if(!selectedPeriod) return;

        selectedPeriod.prices = selectedPeriod.prices.filter(c=> !c.isExclusive);

        this.update({selectedPeriod});
    }

    public setCouponApplied(coupon: PaymentCouponModel) : void{
        if(!coupon){
            return;
        }
        const {code} = coupon;
        this.update({isCouponApplied: true, couponCode: code, coupon});
    }

    public addPromoCode(model: PromoCodeVewModel) {
        this.update({ promoCodes: [...this.getValue().promoCodes, model] });
    }

    public editPromoCode(model: PromoCodeVewModel) {
        const promoCodes = this.getValue().promoCodes.map(p => p.id === model.id ? model : p);;
        this.update({ promoCodes: promoCodes });
    }

    public deletePromoCode(promoCodeId: number) {
        const promoCodes = this.getValue().promoCodes.filter(x => x.id !== promoCodeId);
        this.update({ promoCodes: promoCodes });
    }
}

export const paymentPlansStore = new PaymentPlansStore();
