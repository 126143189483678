import React from 'react';
import {
    Box,
    FormControl,
    MenuItem,
    Select,
} from '@material-ui/core';
import { PatientModel } from "../../../patients/models/patient.model";
import { useFacade } from "./transferPatientToLocationComponent.hooks";
import { displayLocation } from "../../helpers/displayLocation";
import { ProtectedElement } from "../../../common/components/ProtectedElement";
import { PermissionType, UserType } from "../../../auth/models/auth.enums";
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";

interface TransferPatientToLocationComponentProps {
    patient: PatientModel;
    classStyle?: any;
}

export const TransferPatientToLocationComponent: React.FC<TransferPatientToLocationComponentProps> = (props: TransferPatientToLocationComponentProps) => {
    const { patient, classStyle = '' } = props;

    const [
        {
            locations,
            currentLocation
        },
        handleChangeLocation,
        handleTransfer
    ] = useFacade(patient);

    const canTransfer = () => {
        if (!currentLocation) {
            return false;
        }

        return patient.locationId !== currentLocation.id;
    }

    return (
        <>
            <Box display="flex" alignItems="center">
                <Box width={0.4} className={classStyle}>
                    <h4>
                        POD:
                    </h4>
                </Box>
                <Box width={0.6} display="flex">
                    <Box width={0.8}>
                        <FormControl
                            color='primary'
                            fullWidth
                            variant="outlined"
                            size="small">
                            <ProtectedElement
                                userType={UserType.Employee}
                                element={
                                    <Select
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                        }}
                                        value={currentLocation?.id ?? 0}
                                        onChange={(e) => handleChangeLocation(Number(e.target.value))}>
                                        {
                                            locations
                                                .map((location, index) =>
                                                    <MenuItem key={index} value={location.id}>
                                                        {
                                                            displayLocation(location)
                                                        }
                                                    </MenuItem>
                                                )
                                        }
                                    </Select>
                                }
                                defaultContent={
                                    <Select value={patient?.locationId ?? []} disabled={true}>
                                        {
                                            locations
                                                .map((location, index) =>
                                                    <MenuItem key={index} value={location.id}>
                                                        {
                                                            displayLocation(location)
                                                        }
                                                    </MenuItem>
                                                )
                                        }
                                    </Select>
                                }
                                permissions={[PermissionType.MigrationBetweenLocations]} />
                        </FormControl>
                    </Box>
                    <Box width={0.2}>
                        <Box pl={1}>
                            {
                                canTransfer() &&
                                <WildHealthButton
                                    id="transfer-patient-to-location"
                                    fullWidth
                                    size="large"
                                    onClick={() => handleTransfer()}
                                >
                                    <TransferWithinAStationIcon />
                                </WildHealthButton>
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
};