import { useEffect, useState } from "react";
import { useHistory } from 'react-router';
import { Subscription } from "recompose";
import { onEmit } from "../../../common/helpers/on-emit";
import { SortingDirection } from "../../../common/sorting/models/sorting-destination";
import { ISortingState } from "../../../common/sorting/models/sorting-state";
import { handleCompare } from "../../../common/sorting/helpers/handle-compare";
import { navigationService } from "../../../../services/navigation.service";
import { IErrorState } from "../../../common/validation/error-state";
import { MdmCommonModel } from "../../../notes/models/notes.models";
import { notesQuery } from "../../../notes/stores/notes";
import { confirmService } from "../../../../services/confirm.service";
import { mdmPlansService } from "../../../notes/services/mdmPlans.service";
import { createCommonMdmValidator } from "./createCommonMdm.validator";

export enum SortingSource {
  None,
  Name = "name",
}

interface ManageCommonMdmsComponentState extends ISortingState, IErrorState {
  isLoading: boolean;
  isSubmitting: boolean;
  isOpen: boolean;
  commonMdms: MdmCommonModel[];
  createCommonMdm: MdmCommonModel;
  removingMdmId: number;
}

const initialParmas: MdmCommonModel = {
  id: 0,
  name: '',
  description: '',
  reasons: [],
}

export function useFacade(): [
  ManageCommonMdmsComponentState,
  (field: string, value: any) => void,
  (source: string, direction: SortingDirection) => void,
  () => void,
  (id: number) => void,
  (id: number) => void,
  () => void
] {
  const history = useHistory();
  const [state, setState] = useState({
    isLoading: true,
    isSubmitting: false,
    isOpen: false,
    commonMdms: [],
    createCommonMdm: initialParmas,
    removingMdmId: 0,
    sortingColumns: [
      { title: 'Name', source: SortingSource.Name, direction: SortingDirection.Asc },
      { title: 'Description', source: null, direction: null },
      { title: 'Action', source: null, direction: null },
    ],
    sortingSource: SortingSource.None,
    errors: {}
  } as ManageCommonMdmsComponentState);

  const setDirection = (source: string, direction: SortingDirection) => {
    const itemIndex = state.sortingColumns.findIndex(item => item.source === source);
    state.sortingColumns[itemIndex].direction = direction;
    setState(state => ({ ...state, columns: state.sortingColumns }));
  }

  const handleSorting = (source: string, direction: SortingDirection) => {
    if (state.sortingSource === source) {
      direction = direction === SortingDirection.Asc
        ? SortingDirection.Desc
        : SortingDirection.Asc;

      setDirection(source, direction);
    }
    setState(state => ({
      ...state,
      sortingSource: source,
      commonMdms: state.commonMdms.sort((p1, p2) => handleCompare(p1, p2, direction, source))
    }));
  }

  const handleGoToEdit = (mdmId: number) => {
    navigationService.toCommonMdmEdit(history, mdmId);
    return;
  }

  const handleChanges = (field: string, value: any) => {
    createCommonMdmValidator.validateAndSetState(state, setState, field, value);

    const params = state.createCommonMdm;
    
    params[field] = value;

    setState({...state, createCommonMdm: Object.assign({}, params)});
  }

  const handleCreateMdmPlan = () => {
    createCommonMdmValidator.validateObjectAndSetState(state, setState, state.createCommonMdm);
    if (!createCommonMdmValidator.stateIsValid(state)) {
      return;
    }

    setState({...state, isSubmitting: true })

    const cb = () => setState(state => ({ ...state, isSubmitting: false, isOpen: false, createCommonMdm: Object.assign({}, initialParmas)  }));
    
    mdmPlansService.createCommonMdmPlan(state.createCommonMdm).subscribe(cb, cb)
  }
  
  const handleDeleteMdmPlan = (id: number) => {
    confirmService.confirm(
      'Delete Common Mdm',
      'Are you sure you want to delete this MDM Plan?',
      'Yes',
      'Cancel',
      'danger')
      .subscribe(() => {
        setState(state => ({
          ...state,
          isSubmitting: true,
          removingMdmId: id
        }));

        const cb = () => setState(state => ({ ...state, isSubmitting: false, removingMdmId: 0 }));
        mdmPlansService.deleteCommonMdmPlan(id).subscribe(cb, cb);
      });
  }

  const handleToggleNewMdm = () => {
    setState((state) => ({
      ...state,
      isOpen: !state.isOpen,
      createCommonMdm: Object.assign({}, initialParmas),
      errors: {}
    }))
  }

  const useEffectCB = () => {
    const subscriptions: Subscription[] = [
      onEmit<MdmCommonModel[]>(notesQuery.commonMdms$, commonMdms => {
        setState(state => ({
          ...state,
          commonMdms,
        }));
      }),
    ];

    const cb = () => setState(state => ({ ...state, isLoading: false }));
    
    mdmPlansService.getAllCommonMdms().subscribe(cb, cb);

    return () => {
      subscriptions.map(it => it.unsubscribe())
    };
  }

  useEffect(useEffectCB, []);

  return [
    state,
    handleChanges,
    handleSorting,
    handleCreateMdmPlan,
    handleDeleteMdmPlan,
    handleGoToEdit,
    handleToggleNewMdm
  ];
}