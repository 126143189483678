import { IconButton, TextField } from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const InputField = withStyles({
    root: {
        '&:focus': {
            outline: 'none !important',
        },
        '& label.Mui-focused': {
            outline: 'none !important',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'transparent',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'transparent',
                outline: 'none !important',
            },
        },
    },
})(TextField);

export const DiscardButton = withStyles((theme: Theme) => ({
    root: {
        fill: 'currentColor',
        color: colors.gray1,
        background: colors.gray4,
        width: 42,
        height: 42,
        '&:hover': {
            color: `${colors.gray1}CC`,
            background: `${colors.gray4}CC`,
        },
        '&:disabled': {
            color: `${colors.gray1}99`,
            background: colors.disabled,
        },
    },
}))(IconButton);

export const ConfirmButton = withStyles((theme: Theme) => ({
    root: {
        fill: 'currentColor',
        color: colors.white,
        background: colors.main,
        width: 42,
        height: 42,
        '&:hover': {
            color: `${colors.white}CC`,
            background: `${colors.main}CC`,
        },
        '&:disabled': {
            color: `${colors.gray1}99`,
            background: colors.disabled,
        },
    },
}))(IconButton);