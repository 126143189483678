import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles(() =>
    createStyles({
        backButton: {
            backgroundColor: colors.bg
        },
        cell: {
            width: 300,
            minWidth: 150,
            maxWidth: 300,
            '&:last-child': {
                width: 'auto',
                minWidth: 0,
            },
        },
    })
);
