import { useState, useEffect } from "react";
import { PlatformName, Track } from "../../../tracking/tracker";
import { RoutesConstants } from '../../../../constants/route.constants';
import { mobileModel } from "react-device-detect";
import { authQuery } from "../../../auth/stores/auth";

export interface ReferralLandingPageState {
  referralCode: string
}

export function useFacade(): [
  ReferralLandingPageState,
  (type: string) => void
] {
  const [state, setState] = useState({
    referralCode: "null",
  } as ReferralLandingPageState);

  const sendSocialNetwork = (type: string) => {
    switch (type) {
      case "facebook":
        Track("referral_button", {
          type: 'Facebook',
          acValue: "Facebook",
          platform: PlatformName()
        })
        return;
      case "twitter":
        Track("referral_button", {
          type: 'Twitter',
          acValue: "Twitter",
          platform: PlatformName()
        })
        window.open(`${RoutesConstants.twitterReferralLink}`, '_blank');
        return;
      case "sms":
        if (mobileModel === 'none' || !mobileModel) return;
        Track("referral_button", {
          type: 'SMS',
          acValue: "SMS",
          platform: PlatformName()
        })
        const patientMobileNumber = authQuery.getPhoneNumber();
        window.location.href = mobileModel === 'iPhone' ? `sms:${encodeURIComponent(patientMobileNumber)}&${RoutesConstants.smsReferralLink}` : `sms:${encodeURIComponent(patientMobileNumber)}?${RoutesConstants.smsReferralLink}`;
        return;
    }
  }

  useEffect(() => {
    setState(state => ({
      ...state,
      referralCode: "WILD18725",
    }));
  }, []);

  return [state, sendSocialNetwork];
}