import { Store, StoreConfig } from '@datorama/akita';
import { SyncRecordStatusCount, SyncRecordReconcileModel, SyncRecordDetail, SyncRecordTypeModel, SyncRecordListModel } from '../../models/syncRecords.model';
import { SyncRecordStatus } from '../../models/syncRecordStatus.enums';
import { authQuery } from '../../../auth/stores/auth';

/**
 * Represents SyncRecords state
 */
export interface SyncRecordsState {
    syncRecordTypes: SyncRecordTypeModel[];
    syncRecordStatusCounts: SyncRecordStatusCount[];
    targetSyncRecordListStatusId: SyncRecordStatus;
    targetSyncRecordListStatus: string;
    totalCount: number;
    targetSyncRecords: SyncRecordDetail[];
    syncRecordReconciles: SyncRecordReconcileModel[];
    filterDate: string;
    searchSyncRecords: SyncRecordListModel[];
    typeName: string;
    practiceId: number;
}

/**
 * Creates initial state
 */
export function createInitialState(): SyncRecordsState {
    return {
        syncRecordTypes: [],
        syncRecordStatusCounts: [],
        targetSyncRecordListStatusId: null,
        targetSyncRecordListStatus: '',
        totalCount: 0,
        targetSyncRecords: [],
        syncRecordReconciles: [],
        filterDate: '',
        searchSyncRecords: [],
        typeName: 'Default',
        practiceId: authQuery.getCurrentPracticeId()
    };
}

/**
 * Provides syncRecords state management
 */
@StoreConfig({ name: 'syncRecords', resettable: true })
export class SyncRecordsStore extends Store<SyncRecordsState> {
    constructor() {
        super(createInitialState());
    }

    public setTargetSyncRecordCount(syncRecordStatusCount: SyncRecordStatusCount, typeName: string, practiceId: number): void {
        this.update({
            targetSyncRecordListStatusId: syncRecordStatusCount.statusId,
            targetSyncRecordListStatus: syncRecordStatusCount.status,
            totalCount: syncRecordStatusCount.count,
            typeName: typeName,
            practiceId: practiceId
        });
    }

    public updateTargetSyncRecordList(searchResult: SyncRecordListModel, typeName: string, practiceId: number): void {
        this.update({
            targetSyncRecordListStatusId: searchResult.statusId,
            targetSyncRecordListStatus: searchResult.status,
            totalCount: searchResult.total,
            targetSyncRecords: searchResult.syncRecords,
            typeName: typeName,
            practiceId: practiceId
        });
    }

    public setFilterDate(filterDate: string): void {
        this.update({ filterDate })
    }
}

export const syncRecordsStore = new SyncRecordsStore();
