import { akitaDevtools, persistState } from '@datorama/akita';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import AxiosInstance from 'axios'
import { useFlags, withLDProvider } from 'launchdarkly-react-client-sdk';
import LogRocket from 'logrocket';
import React, { useCallback, useEffect, useState } from 'react';
import { Route, useHistory } from "react-router";
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { Subscription } from "rxjs";
import { appInsights, reactPlugin } from '../appInsights';
import { LOGROCKET_KEY } from "./config";
import { RoutesConstants } from './constants/route.constants';
import { PermissionType, UserType } from "./modules/auth/models/auth.enums";
import { authQuery } from './modules/auth/stores/auth';
import { CommonConfirmDialog } from "./modules/common/dialogs/confirmDialog/ConfirmDialog";
import { CommonConfirmWithReasonDialog } from "./modules/common/dialogs/confirmWithReasonDialog/ConfirmWithReasonDialog";
import { CommonInfoDialog } from "./modules/common/dialogs/infoDialog/InfoDialog";
import { onEmit } from "./modules/common/helpers/on-emit";
import { CommonSnack } from './modules/common/snack/CommonSnack';
import { notificationsService } from './modules/notifications/services/notifications.service';
import HistoryTracking from './modules/tracking/historyTracking';
import { mainMenuService } from './services/mainMenu.service';
import { commonRoutes, DashboardPreload, flows, practice } from "./services/route.service";
import { applicationStore } from './stores/applicationStore';
import { patientsService } from "./modules/patients/services/patients.service";
import moment from "moment-timezone";
import ErrorBoundaryPage from "./pages/common/errorBoundaryPage/ErrorBoundaryPage";
import { questionnaireService } from './modules/questionnaire/services/questionnaire.service';
import { CommonConfirmAndSelectedDialog } from './modules/common/dialogs/confirmAndSelectedDialog/ConfirmAndSelectedDialog';
import { healthReportServices } from './modules/healthReport/services/healthReport.services';
import { StickyChatContainerComponent } from "./modules/conversations/components/stickyChatContainerComponent/StickyChatContainerComponent";
import { ProtectedElement } from "./modules/common/components/ProtectedElement";
import { FeatureComponent } from "./modules/common/components/featureFlags/FeatureComponent";
import { FeatureFlag } from "./modules/common/components/featureFlags/featureFlags.models";
import { LoadConversationStoreComponent } from './modules/conversations/components/loadConversationStoreComponent/LoadConversationStoreComponent';

import { insuranceService } from './modules/insurance/services/insurance.service';
import { insuranceQuery } from './modules/insurance/stores/insurance.query';
import { applicationService } from './services/application.service';
import { rostersQuery } from './modules/rosters/stores/rosters';
import { rostersService } from './modules/rosters/services/rosters.service';
import { RosterModel } from './modules/rosters/models/roster.model';
import { notesService } from "./modules/notes/services/notes.service";
import { agreementsService } from './modules/payment/services/agreements.service';
import { HealthGoalDialogComponent } from './modules/widgets/components/healthPlanWidgetComponent/healthGoalDialogComponent/HealthGoalDialogComponent';
import { CommonConfirmAndSelectedOneDialog } from './modules/common/dialogs/confirmAndSelectedOneDialog/ConfirmAndSelectedOneDialog';
import { CommonAlertDialog } from './modules/common/dialogs/alertDialog/AlertDialog';
import { authService } from "./modules/auth/services/auth.service";
import { StandardUpsellRequestDialog } from './modules/payment/components/standardUpsellDialog/StandardUpsellRequestDialog';
import { StandardUpsellRequestReceivedDialog } from './modules/payment/components/standardUpsellDialog/StandardUpsellRequestReceivedDialog';

import { setupTokenExpireLogout } from './modules/auth/helpers/refreshHelper';

// Removing refresh from the application, instead we are going with a strategy of the login expiring at 
// 3am local time for the user, each morning they will be required to log back in
// initializeRefresh(AxiosInstance);
setupTokenExpireLogout(AxiosInstance);

akitaDevtools();

//What should be saved across browser tabs
persistState({
    include: ['session']
});

const Dashboard = DashboardPreload();

LOGROCKET_KEY && LogRocket.init(LOGROCKET_KEY, {
    dom: {
        inputSanitizer: true,
        textSanitizer: true
    }
})

interface AppState {
    isGetHealthReportNotificationLoading: boolean;
    isGetNoteNotificationLoading: boolean;
    isGetRosterLoading: boolean;
    isGetChangedAgreementLoading: boolean;
    isGetInsuranceAvailabilityLoading: boolean;
    isRefreshPatientTimezoneLoading: boolean;
    isGetQuestionnaireNotificationLoading: boolean;
    rosters: RosterModel[];
}

export const App: React.FC = () => {
    const history = useHistory();
    const featureFlags = useFlags();
    const practiceRoutes = practice.get(authQuery.getCurrentPracticeId());
    const [flowRoutes, setFlowRoutes] = useState(() => (
        authQuery.isRegistrationCompleted()
            ? flows.get(authQuery.getType())
            : () => []
    ));
    const [state, setState] = useState({
        isGetHealthReportNotificationLoading: false,
        isGetNoteNotificationLoading: false,
        isGetRosterLoading: false,
        isGetChangedAgreementLoading: false,
        isGetInsuranceAvailabilityLoading: false,
        isRefreshPatientTimezoneLoading: false,
        isGetQuestionnaireNotificationLoading: false,
        rosters: []
    } as AppState);

    const getMenuItems = useCallback(() => {
        const menuItems = mainMenuService.getMenuItems(
            featureFlags,
            authQuery.getType(),
            authQuery.getPermissions(),
            authQuery.getRoleId(),
            rostersQuery.getRosters(),
        );

        applicationStore.update({ menuItems: menuItems });

    }, [featureFlags, state.rosters]);

    const initApp = (userType: UserType) => {
        authService.getExternalAuthorizationProviders().subscribe();

        if (authQuery.isRegistrationCompleted()) {
            getMenuItems();
        }

        const isEmployeeLoading = state.isGetHealthReportNotificationLoading || state.isGetNoteNotificationLoading || state.isGetRosterLoading;
        const isPatientLoading = state.isGetChangedAgreementLoading || state.isGetInsuranceAvailabilityLoading || state.isRefreshPatientTimezoneLoading || state.isGetQuestionnaireNotificationLoading;

        if (authQuery.isLoggedIn() && userType === UserType.Employee && !isEmployeeLoading) {
            setState(state => ({
                ...state,
                isGetHealthReportNotificationLoading: true,
                isGetNoteNotificationLoading: true,
                isGetRosterLoading: true,
            }))
            const cbHRNotification = () => setState(state => ({ ...state, isGetHealthReportNotificationLoading: false }))
            const cbNoteNotification = () => setState(state => ({ ...state, isGetNoteNotificationLoading: false }))
            const cbRoster = (data) => setState(state => ({ ...state, isGetRosterLoading: false, rosters: data}))
            healthReportServices.getNotificationAwaitingApproval().subscribe(cbHRNotification, cbHRNotification);
            notesService.getNotificationAwaitingApproval().subscribe(cbNoteNotification, cbNoteNotification);
            rostersService.getAll().subscribe(cbRoster, cbRoster);
        }

        if (authQuery.isLoggedIn() && userType === UserType.Patient && !isPatientLoading) {
            setState(state => ({
                ...state,
                isGetChangedAgreementLoading: true,
                isGetInsuranceAvailabilityLoading: true,
                isRefreshPatientTimezoneLoading: true,
                isGetQuestionnaireNotificationLoading: true
            }))
            const cbChangedAgreement = () => setState(state => ({ ...state, isGetChangedAgreementLoading: false }))
            const cbInsuranceAvailability = () => setState(state => ({ ...state, isGetInsuranceAvailabilityLoading: false }))
            const cbRefreshPatientTimezone = () => setState(state => ({ ...state, isRefreshPatientTimezoneLoading: false }))
            const cbQuestionnaireNotification = () => setState(state => ({ ...state, isGetQuestionnaireNotificationLoading: false }))
            if (authQuery.getPatientAgreementsChanged()) {
                agreementsService.getChangedAgreements().subscribe(cbChangedAgreement, cbChangedAgreement);
            } else {
                setState(state => ({ ...state, isGetChangedAgreementLoading: false }))
            }
            insuranceService.getInsuranceAvailability().subscribe(cbInsuranceAvailability, cbInsuranceAvailability);
            patientsService.refreshPatientsTimezone(moment.tz.guess()).subscribe(cbRefreshPatientTimezone, cbRefreshPatientTimezone);
            questionnaireService.getNotificationAvailable().subscribe(cbQuestionnaireNotification, cbQuestionnaireNotification);
        }
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<boolean>(authQuery.isRegistrationCompleted$, isRegistrationCompleted => {
                initApp(authQuery.getType());

                const flowRoutes = isRegistrationCompleted
                    ? flows.get(authQuery.getType())
                    : () => [];

                setFlowRoutes(() => (flowRoutes));
            }),
            onEmit<RosterModel[]>(rostersQuery.rosters$, rosters => {
                if (rosters && rosters.length) {
                    getMenuItems()
                }
            }),
            onEmit<boolean>(insuranceQuery.insuranceAvailability$, insuranceAvailability => {
                if (insuranceAvailability) {
                    getMenuItems()
                }
            }),
            onEmit<number>(authQuery.userId$, userId => {
                if (userId && authQuery.isRegistrationCompleted()) {
                    getMenuItems()
                }
            }),
        ];

        if (authQuery.isLoggedIn() && authQuery.isRegistrationCompleted()) {
            notificationsService.connect();
        }

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [history, featureFlags, getMenuItems]);

    useEffect(() => {
        applicationService.getInfo();
    }, []);

    return (
        <ErrorBoundaryPage history={history}>
            <Router>
                <>
                    <Switch>

                        <Route
                            key={'dashboard'}
                            path={RoutesConstants.dashboard}
                            component={Dashboard}
                        />
                        {flowRoutes && flowRoutes()}
                        {practiceRoutes && practiceRoutes()}
                        {commonRoutes()}

                    </Switch>
                    <CommonSnack />
                    <FeatureComponent featureFlag={FeatureFlag.StickyChatMessaging}>
                        <ProtectedElement
                            userType={UserType.Employee}
                            permissions={[PermissionType.Conversations]}
                            element={
                                <StickyChatContainerComponent />
                            }
                        />
                    </FeatureComponent>
                    <LoadConversationStoreComponent />
                    <CommonInfoDialog />
                    <CommonAlertDialog />
                    <CommonConfirmDialog />
                    <CommonConfirmWithReasonDialog />
                    <CommonConfirmAndSelectedDialog />
                    <CommonConfirmAndSelectedOneDialog />
                    <StandardUpsellRequestDialog />
                    <StandardUpsellRequestReceivedDialog />
                    <HistoryTracking />
                    <FeatureComponent featureFlag={FeatureFlag.HealthPlanDashboard}>
                        <HealthGoalDialogComponent />
                    </FeatureComponent>
                </>
            </Router>
        </ErrorBoundaryPage>
    );
}

appInsights.setAuthenticatedUserContext(authQuery.getId().toString());

const appWithLDProvider = withLDProvider({ clientSideID: process.env.REACT_APP_LD_CLIENT_ID })(App);
export default withAITracking(reactPlugin, appWithLDProvider);
