import { Box, Button, Grid, TextField, useMediaQuery, useTheme } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import Calendar from 'react-calendar';
import { useFacade } from './SelectDateTimeComponent.hook';
import { useStyles } from './SelectDateTimeComponent.styles';
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { AvailabilityModel } from '../../models/availability.models';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import commonUseStyles from '../../../common/styles/common.styles';
import arrowLeft from "@img/icons/arrowLeft.svg";
import arrowRight from "@img/icons/arrowRight.svg";
import clsx from 'clsx';
import './SelectDateTime.scss';

interface SelectDateTimeComponentProps {
    selectedTime: Date;
    selectedDate: Date;
    minDate: Date;
    handleChangeDate: (value: Date) => void;
    handleChangeTime: (time: Date) => void;
    availability: AvailabilityModel[];
    isLoading?: boolean;
    showEarliestDate?: boolean,
    error?: boolean,
    errorMessage?: string
    patientTimezone?: string;
    disabledTile?: boolean;
    hideTime?: boolean;
    reason?: string;
    skipFilter?:boolean;
    isSchedulerReminder?:boolean;
    handleChangeTimeCustom?: (time: any) => void;
}

export const SelectDateTimeComponent: React.FC<SelectDateTimeComponentProps> = (props: SelectDateTimeComponentProps) => {
    const {
        selectedTime,
        selectedDate,
        minDate,
        handleChangeDate,
        handleChangeTime,
        availability,
        isLoading,
        error = false,
        errorMessage = null,
        patientTimezone,
        disabledTile = true,
        hideTime = false,
        skipFilter,
        isSchedulerReminder,
        handleChangeTimeCustom
    } = props;

    const [
        state,
        handleShowPreviousMonthView,
        handleShowNextMonthView
    ] = useFacade(minDate);

    const classes = useStyles();
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const datesAreOnSameDay = function (externalParameter, element) {
        const first = new Date(element.start);
        return first.getDate() === externalParameter.getDate() &&
            first.getMonth() === externalParameter.getMonth() &&
            first.getFullYear() === externalParameter.getFullYear();
    }

    const filterUnavailableDate = (date) => !availability.some(datesAreOnSameDay.bind(null, date))

    const tileDisabled = (
        ({ date, _ }) => filterUnavailableDate(date)
    );

    const selectedDay = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 0, 0, 0)

    let filteredAvailability: any[];

    if(skipFilter !== null && skipFilter !== undefined && skipFilter)
    {
        filteredAvailability = availability
        .filter(x => new Date(x.start) < new Date(selectedDay.getTime() + 86400000) && new Date(x.start) >= new Date(selectedDay.getTime()))
        .map(x => {
            return {
                start: new Date(x.start),
                end: new Date(x.end),
                timeZone: x.timeZone
            } as AvailabilityModel
        });
    }
    else{
        filteredAvailability = availability
        .filter(x => new Date(x.start) < new Date(selectedDay.getTime() + 86400000) && new Date(x.start) >= new Date(selectedDate.getTime()))
        .map(x => {
            return {
                start: new Date(x.start),
                end: new Date(x.end),
                timeZone: x.timeZone
            } as AvailabilityModel
        });
    }

    return (
        <Box className={commonClasses.size14} maxWidth='700px'>
            <Grid container spacing={3}>
                <Grid item md={7} xs={12}>
                    <span className={commonClasses.colorGray1}>Date</span>
                    <Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Box>
                                <span className={commonClasses.textMedium}>
                                    {moment(state.activeStartDate).format("MMMM yyyy")}
                                </span>
                            </Box>
                            <Box>
                                <Button
                                    id="select-date-show-previous-month"
                                    onClick={handleShowPreviousMonthView}
                                    className={classes.navigateButton}
                                    disabled={!state.canMoveBack}
                                >
                                    <img src={arrowLeft} alt="Prev" />
                                </Button>
                                <Button
                                    id="select-date-show-next-month"
                                    className={classes.navigateButton}
                                    onClick={handleShowNextMonthView}
                                >
                                    <img src={arrowRight} alt="Next" />
                                </Button>
                            </Box>
                        </Box>
                        <Calendar
                            calendarType="US"
                            activeStartDate={state.activeStartDate}
                            onChange={handleChangeDate}
                            value={selectedDate}
                            minDate={minDate}
                            showNavigation={false}
                            tileDisabled={disabledTile && tileDisabled}
                        />
                    </Box>
                </Grid>

                <Grid item md={5} xs={12}>
                    <Box>
                        { 
                            isSchedulerReminder&&
                            <TextField
                                id="time"
                                className={classes.timePicker}
                                label="Time"
                                type="time"
                                fullWidth
                                variant="outlined"
                                defaultValue="09:00"
                                onChange={(e)=>{handleChangeTimeCustom(e.target.value)}}
                                InputLabelProps={{
                                shrink: true,
                                }}
                                inputProps={{
                                step: 300, // 5 min
                                }}
                            />
                        }
                    </Box>
                    <Box mt={2} className={commonClasses.textMedium}>{isSmallScreen ? "Time" : moment(selectedDate).format("dddd, DD MMMM, yyyy")}</Box>
                    {
                        !isSmallScreen && patientTimezone &&
                        <Box className={clsx(commonClasses.textRegular, commonClasses.colorGray1)}>{patientTimezone}</Box>
                    }

                    {!hideTime &&
                        <Box className={`${classes.timeSection} scrollable`}>
                            {
                                isLoading &&
                                <WildHealthLinearProgress />
                            }
                            {
                                !isLoading && !filteredAvailability.length &&
                                <WildHealthPlaceHolder message="Not available" height="100%" />
                            }
                            <Grid container>
                                {
                                    !isLoading && filteredAvailability.length > 0 && filteredAvailability.map((time, index) =>
                                        <Grid key={index} item md={6} xs={6}>
                                            <Box p={0.5}>
                                                <Button
                                                    id="select-date-change-time"
                                                    fullWidth
                                                    onClick={() => handleChangeTime(time.start)}
                                                    className={clsx(classes.timeButton, { [classes.timeButtonSelected]: selectedTime?.getTime() === time.start?.getTime() })}>
                                                    {moment(time.start).format("h:mm a")}
                                                </Button>
                                            </Box>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Box>
                    }
                </Grid>

                <Grid item md={12} xs={12}>
                    {
                        error &&
                        <Box>
                            <span className={clsx(commonClasses.colorBad, commonClasses.size14)}>{errorMessage}</span>
                        </Box>
                    }
                </Grid>
            </Grid>
        </Box>
    );
}