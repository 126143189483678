import { Box, Grid, FormControl, TextField, Select, MenuItem, Card, CardContent, Tooltip } from '@material-ui/core';
import React from 'react';
import { useFacade } from "./syncRecordReconcileComponent.hooks";
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import clsx from 'clsx';
import commonUseStyles from '../../../common/styles/common.styles';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import { useStyles } from "./syncRecordReconcileComponent.styles";
import { SyncDatumBooleanKeys, syncRecordActionNames, syncRecordStatusNames } from '../../models/syncRecordStatus.enums';

interface SyncRecordReconcileComponentProps {
  recordId: number;
}

export const SyncRecordReconcileComponent: React.FC<SyncRecordReconcileComponentProps> = (props: SyncRecordReconcileComponentProps) => {
  const [
    {
      isLoading,
      isSubmitted,
      syncRecordReconciles,
    },
    handleChanges,
    handleChangeDatums,
    handleReconcile,
    handleAction
  ] = useFacade(props.recordId);

  const classes = useStyles();
  const commonClasses = commonUseStyles();

  const isBooleanField = (key: string) => {
    switch (key) {
        case SyncDatumBooleanKeys.SmsMarketingOptIn:
        case SyncDatumBooleanKeys.SmsOptIn:
        case SyncDatumBooleanKeys.SubscriptionOptIn:
            return true;
        default:
            return false;
    }
}

  const renderContent = (): JSX.Element => {

    if (isLoading) {
      return (<WildHealthLinearProgress />)
    }

    if (!isLoading && !syncRecordReconciles.length) {
      return (
        <Box pt={8}>
          <WildHealthPlaceHolder message="No Sync Records" />
        </Box>
      );
    }

    return (
      <Box mt={2}>
        <Grid container spacing={2}>
            {syncRecordReconciles.map(record => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={record.id}>
                    <Card className={classes.subscriptionCard} variant="outlined">
                        <Box p={1}>
                            <CardContent>
                                <Box mb={2}>
                                    <FormControl
                                        variant="outlined"
                                        color='primary'
                                        required
                                        size="small"
                                        disabled={isSubmitted || isLoading}
                                        classes={{ root: classes.root }}
                                        fullWidth>
                                        <Box className={commonClasses.label} mb={1}>Status</Box>
                                        <Select
                                            style={{ background: '#FFF' }}
                                            classes={{ root: classes.root }}
                                            value={record.statusId}
                                            inputProps={{
                                            name: 'Sync Record Status',
                                            id: 'sync-record-status-label',
                                            classes,
                                            }}
                                            renderValue={() => {
                                                if (record.statusId === null || !record.statusId) {
                                                    return <Box className={commonClasses.colorMediumGray}>Select</Box>;
                                                }

                                                return syncRecordStatusNames[record.statusId];
                                            }}
                                            displayEmpty
                                            onChange={(v) => handleChanges("statusId", v.target.value, record.id)}
                                        >
                                            {
                                                Object.keys(syncRecordStatusNames).map((status, index) => (
                                                    <MenuItem value={status} key={`${status}_${index}`}>{syncRecordStatusNames[status]}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box mb={2}>
                                    <FormControl
                                        variant="outlined"
                                        color='primary'
                                        required
                                        size="small"
                                        classes={{root: classes.root}}
                                        disabled
                                        fullWidth>
                                        <Box className={commonClasses.label} mb={1}>Id</Box>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            required
                                            InputProps={{className: 'input', classes}}
                                            id={'id'}
                                            type="text"
                                            placeholder="Id"
                                            value={record.id}
                                            variant="outlined"
                                            disabled
                                            onChange={(v) => handleChanges(v.target.id, v.target.value, record.id)}
                                        />
                                    </FormControl>
                                </Box>
                                {record.datum.map(data => (
                                    <Box key={data.id} mb={2}>
                                        <FormControl
                                            variant="outlined"
                                            color='primary'
                                            required
                                            size="small"
                                            classes={{root: classes.root}}
                                            disabled={isSubmitted || isLoading}
                                            fullWidth>
                                            <Box className={commonClasses.label} mb={1}>{data.key}</Box>
                                            {isBooleanField(data.key) 
                                            ? (
                                              <Select
                                                style={{ background: '#FFF' }}
                                                classes={{ root: classes.root }}
                                                value={data.value}
                                                inputProps={{ classes }}
                                                onChange={(v) => handleChangeDatums(data.key, v.target.value, record.id)}
                                              >
                                                <MenuItem value="False" key={`${data.key}_false`}>False</MenuItem>
                                                <MenuItem value="True" key={`${data.key}_true`}>True</MenuItem>
                                              </Select>
                                            ) : (
                                              <TextField
                                                fullWidth
                                                size="small"
                                                required
                                                InputProps={{className: 'input', classes}}
                                                id={data.key}
                                                type="text"
                                                placeholder={data.key}
                                                value={data.value}
                                                variant="outlined"
                                                disabled={isSubmitted || isLoading}
                                                onChange={(v) => handleChangeDatums(v.target.id, v.target.value, record.id)}
                                              />
                                            )}
                                            
                                        </FormControl>
                                    </Box>
                                ))}
                                {record.actions.map(action => (
                                   <Tooltip disableHoverListener={!action.disabled} placement="top" arrow title={action.disabledReason}>
                                    <Box
                                      style={{ marginBottom: 8 }}
                                      key={action.action}>
                                        <WildHealthButton
                                          id={`sync-record-action-${action.action}-button`}
                                          fullWidth
                                          loading={isSubmitted || isLoading}
                                          disabled={action.disabled}
                                          onClick={() => handleAction(action.action)}>
                                          {syncRecordActionNames[action.action]}
                                        </WildHealthButton>
                                    </Box> 
                                  </Tooltip>
                                ))}
                            </CardContent>
                        </Box>
                    </Card>
                </Grid>
            ))}
        </Grid>
      </Box>
    )
  }

  return (
    <Box p="30px">
      <Box mb={2} display="flex" alignItems="center" justifyContent="space-between">
        <Box>
            <span className={clsx(commonClasses.size20, commonClasses.textMedium)}>Reconcile</span>
        </Box>
        <Box>
            <WildHealthButton
                id="reconcile-save-button"
                loading={isLoading || isSubmitted}
                onClick={() => handleReconcile()}
            >
                Save
            </WildHealthButton>
        </Box>
      </Box>
      {
        renderContent()
      }
    </Box>
  )
}