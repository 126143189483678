import React from 'react';
import { ReactComponent as MedicalHistory } from '@img/icons/MedicalHistory.svg';
import { IconProps } from './IconProps';
import { colors } from '../../modules/common/constants/colors';

const MedicalHistoryIcon: React.FC<IconProps> = (props: IconProps) => {
    const { isSelected } = props;

    return (
        <MedicalHistory fill={isSelected ? colors.main : colors.gray2} />
    )
}

export default MedicalHistoryIcon;