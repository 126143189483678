import { useState } from 'react';
import { newDnaOrderDialogValidator } from './newDnaOrderDialog.validator';
import { IErrorState } from "../../../common/validation/error-state";
import { getLastObject } from "../../../common/helpers/get-last-object";
import { CreateOrderModel } from '../../models/orders.models';
import { AddOnModel } from '../../../addons/models/addOns.models';

export enum CreateDnaOrderStep {
    SelectShipOption,
    ShippedKit,
    ManualFlow
}

interface RequestParamState {
    barcode: string;
    outboundShippingCode: string;
    returnShippingCode: string;
}

export interface NewDnaOrderDialogState extends IErrorState {
    isOpen: boolean;
    handleAnswer: Function;
    step: CreateDnaOrderStep;
    isManual: boolean;
    params: RequestParamState;
}

const defaultParams = {
    barcode: '',
    outboundShippingCode: '',
    returnShippingCode: ''
}

export function useFacade(patientId: number, addOns: AddOnModel[], handleSubmit: Function, handleClose: Function): [
    NewDnaOrderDialogState,
    (field: string, value: any) => void,
    (status: boolean) => void,
    () => void,
    () => void,
    () => void,
    () => void
]{
    const params = Object.assign({}, defaultParams)

    const [state, setState] = useState({
        isOpen: false,
        step: CreateDnaOrderStep.SelectShipOption,
        isManual: false,
        params: params,
        errors: {}
    } as NewDnaOrderDialogState);

    const handleChanges = (field: string, value: any) => {

        newDnaOrderDialogValidator.validateAndSetState(state, setState, field, value);

        const params = state.params;
        const keys = field.split(".");
        const key = keys.pop();
        const lastObject = getLastObject(keys, params);
        lastObject[key] = value;
        setState({...state, params: Object.assign({}, params)});
    }

    const handleToggleManual = (status: boolean) => {
        setState({ ...state, isManual: status})
    }

    const handleGoBack = () => {
        setState({ ...state, step: CreateDnaOrderStep.SelectShipOption })
    }

    const handleNextStep = () => {
        setState({ ...state, step: state.isManual ? CreateDnaOrderStep.ManualFlow : CreateDnaOrderStep.ShippedKit })
    }

    const handleCancel = () => {
        setState(state => ({...state, step: CreateDnaOrderStep.SelectShipOption, isManual: false, params: Object.assign({}, defaultParams), errors: {}}));
        handleClose();
    }

    const handleConfirm = () => {
        const model: CreateOrderModel = {
            patientId: patientId,
            addOnIds: [addOns[0].id],
            isManual: state.isManual,
            barCode: state.params.barcode,
            outboundShippingCode: state.params.outboundShippingCode,
            returnShippingCode: state.params.returnShippingCode
        };
        if (state.isManual) {
            
            newDnaOrderDialogValidator.validateObjectAndSetState(state, setState, state);

            if (newDnaOrderDialogValidator.stateIsValid(state)) {
                handleSubmit(model);
            } else {
                return;
            }
        } else {
            handleSubmit(model);
        }
        
        setState(state => ({...state, step: CreateDnaOrderStep.SelectShipOption, isManual: false, params: Object.assign({}, defaultParams), errors: {}}));
    }

    return [state, handleChanges, handleToggleManual, handleGoBack, handleNextStep, handleCancel, handleConfirm];
}