import React from 'react';
import {Box} from '@material-ui/core';
import {PageSliderComponent} from "../../../../common/components/PageSliderComponent";
import {QuestionnaireSurveyProps} from "./questionnaireSurvey.props";
import {InitialQuestionNames} from "../../../models/questionnaire.models";
import { QuestionnairesListComponent } from '../../../components/questionnairesListComponent/QuestionnairesListComponent';

export const StressQuestionnairePageFields = [
    InitialQuestionNames.STRESS_LEVEL,
    InitialQuestionNames.DEAL_WITH_STRESS,
    InitialQuestionNames.MEDITATION_FREQUENCY
];

export const StressSurvey: React.FC<QuestionnaireSurveyProps> = (props: QuestionnaireSurveyProps) => {
    const {
        errors,
        result,
        questionnaire,
        handleChanges,
        navigationContext
    } = props;

    return (
        <>
            <QuestionnairesListComponent
                questionnaire={questionnaire}
                errors={errors}
                handleChanges={handleChanges}
                navigationContext={navigationContext}
                questions={StressQuestionnairePageFields}
                result={result}
                title={"STRESS:"}
            />
            <Box mt={5}>
                <PageSliderComponent context={navigationContext}/>
            </Box>
        </>
    )
}
