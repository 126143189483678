import { useEffect, useState } from "react";
import { insuranceVerificationService } from "../../services/insuranceVerification.service";
import { Subscription } from "rxjs";
import { InsuranceVerificationDetailsModel } from "../../models/insurance.models";

interface InsuranceVerificationModalState {
    isOpen: boolean;
    isLoading: boolean;
    notFound: boolean;
    details: InsuranceVerificationDetailsModel;
    callback?: Function;
}

export function useFacade(): [InsuranceVerificationModalState, Function] {
    const [state, setState] = useState({
        isOpen: false,
        isLoading: false,
        notFound: false,
        details: null,
        callback: null
    } as InsuranceVerificationModalState);

    const handleClose = () => {
        state.callback?.();
        setState({
            ...state,
            isOpen: false
        });
    }

    const fetchInsuranceVerificationDetails = (id: number) => {
        insuranceVerificationService
            .getInsuranceVerificationDetails(id)
            .subscribe(
                (data) => {
                    setState({
                        ...state,
                        details: data,
                        isOpen: true,
                        isLoading: false
                    })
                },
                (data) => {
                    setState({
                        ...state,
                        details: data,
                        isLoading: false,
                        notFound: true,
                        isOpen: true
                    })
                }
            );
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            insuranceVerificationService.onInsuranceVerificationDetials.subscribe((data) => {
                setState(state => ({
                    ...state,
                    isOpen: true,
                    callback: data.callback,
                    isLoading: true
                }));

                fetchInsuranceVerificationDetails(data.data)
            })
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        }
    }, [])

    return [
        state,
        handleClose
    ];
}