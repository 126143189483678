import { Paper } from '@material-ui/core';
import React, {useState} from 'react';
import { LayoutComponent } from '../../../../components/layoutComponent/LayoutComponent';
import { TitlesService } from "../../../../constants/title.constants";
import { useFacade } from "./consultationPage.hooks";
import { useStyles } from './ConsultationPage.styles';
import { WaitListDialogComponent } from "../../components/waitListDialogComponent/WaitListDialogComponent";
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from 'clsx';
import HeaderCouponApplied from "../../components/finishCheckoutRedesignComponent/headerCouponApplied/HeaderCouponApplied";
import HeaderProgressBar from "../../components/finishCheckoutRedesignComponent/headerProgressBar/HeaderProgressBar";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { anyFlags } from '../../../common/components/featureFlags/featureFlags';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const ConsultationPage: React.FC = () => {

  const [
    page,
    { coupon, progress, progressBarOpened },
    isOnboardingRedesign
  ] = useFacade();

  const featureFlags = useFlags();
  const classes = useStyles();
  const commonClasses = commonUseStyles();

  const [bannerOpened, setBannerOpened] = useState(true) ;

  const handleClose = ()=>{
    setBannerOpened(!bannerOpened)
  }
    
  if (!anyFlags(featureFlags)) {
    return <WildHealthLinearProgress />
  }

  return (
    <>
      <HeaderCouponApplied coupon={coupon} handleClose={handleClose} bannerOpened={bannerOpened} />
      <HeaderProgressBar progress={progress} isOpened={progressBarOpened} />
      <LayoutComponent title={TitlesService.getStartedTitle} redesign={isOnboardingRedesign}>
        <Paper
          className={clsx(classes.content, commonClasses.whWhiteForm)}
          variant="elevation"
          square
        >
          {page}
        </Paper>
        <WaitListDialogComponent />
      </LayoutComponent>
    </>
  )
}
