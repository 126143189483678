import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
    createStyles({
        title: {
            fontSize: '1.6rem',
        },
        card: {
            boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.03)",
            display: "inline-block",
            position: "relative",
            borderRadius: "4px",
            width: '100%',
        },
        cardDivider: {
            backgroundColor: "#f9f9fb",
            margin: 'auto',
            height: 2,
        },
    })
);
