import { Store, StoreConfig } from '@datorama/akita';
import { DnaOrderModel } from '../../models/dnaOrders.models';

/**
 * Represents dna orders state
 */
export interface DnaOrdersState {
    orders: DnaOrderModel[];
}

/**
 * Creates initial state
 */
export function createInitialState(): DnaOrdersState {
    return {
        orders: []
    };
}

@StoreConfig({name: 'dnaOrders', resettable: true })
export class DnaOrdersStore extends Store<DnaOrdersState> {
    constructor() {
        super(createInitialState());
    }

    public addOrder(order: DnaOrderModel): void {
        this.update({orders: [...this.getValue().orders, order]});
    }

    public updateOrder(order: DnaOrderModel): void {
        const orders = this.getValue().orders.map((x) => (x.id === order.id) ? order : x);
        this.update({orders: orders});
    }

    public replaceOrder(replacedOrder: DnaOrderModel, newOrder: DnaOrderModel): void {
        const orders = this.getValue().orders.map((x) => (x.id === replacedOrder.id) ? replacedOrder : x);
        this.update({orders: [...orders, newOrder]});
    }

    public deleteOrder(id: number): void {
        const orders = this.getValue().orders.filter(x => x.id !== id);
        this.update({orders: orders});
    }
}

export const dnaOrdersStore = new DnaOrdersStore();
