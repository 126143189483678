import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { onEmit } from "../../../common/helpers/on-emit";
import { SortingDirection } from "../../../common/sorting/models/sorting-destination";
import { ISortingState } from "../../../common/sorting/models/sorting-state";
import { pageSizes } from "../../../common/pagination/models/page-sizes";
import { IPaginationState } from "../../../common/pagination/models/pagination-state";
import { syncRecordsService } from "../../services/syncRecords.service";
import { syncRecordsQuery, syncRecordsStore } from "../../stores/syncRecordsStore";
import { SyncRecordDetail } from "../../models/syncRecords.model";
import { SyncRecordStatus } from "../../models/syncRecordStatus.enums";
import { useHistory } from 'react-router';
import { navigationService } from '../../../../services/navigation.service';
import { handleCompare } from "../../../common/sorting/helpers/handle-compare";

export enum SortingSource {
    None,
    Id = "id",
    CreatedAt = "createdAt"
}

interface SyncRecordListState extends IPaginationState, ISortingState {
    isLoading: boolean;
    syncRecords: SyncRecordDetail[];
    targetSyncRecordListStatus: string;
    filterDate: string;
    isOpen: boolean;
    anchorEl: HTMLButtonElement;
}

export function useFacade(statusId: SyncRecordStatus, showSearchResult: boolean): [
    SyncRecordListState,
    (page: number) => void,
    (syncRecordId: number) => void,
    (source: string, direction: SortingDirection) => void,
    (event?: React.MouseEvent<HTMLButtonElement>) => void,
    (value: any) => void
] {
    const typeName = syncRecordsQuery.getTargetTypeName();
    const practiceId = syncRecordsQuery.getTargetPracticeId();
    const title = syncRecordsQuery.getTargetStatus();
    const targetRecordsTotal = syncRecordsQuery.getTargetTotalCount();
    const filterDate = syncRecordsQuery.getFilterDate();
    const history = useHistory();

    const [state, setState] = useState({
        isLoading: true,
        syncRecords: [],
        targetSyncRecordListStatus: title,
        sortingColumns: [
            {title: 'Id'},
            {title: 'Date of Create', direction: SortingDirection.Asc},
            {title: 'Detail'},
        ],
        sortingSource: SortingSource.None,
        totalCount: targetRecordsTotal,
        selectedPage: 1,
        pageSize: pageSizes[3],
        filterDate: filterDate,
        isOpen: false,
        anchorEl: null,
    } as SyncRecordListState);

    const handlePageChange = (page: number) => {
        if (page === state.selectedPage) {
            return;
        }

        setState(state => ({
            ...state,
            isLoading: true,
        }));

        const cb = () => setState(state => ({ ...state, isLoading: false }));

        syncRecordsService.getSyncRecordsStatus(typeName, practiceId, statusId, page - 1, state.filterDate).subscribe((response) => {
            setState({
                ...state,
                selectedPage: page,
                targetSyncRecordListStatus: response.status,
                totalCount: response.total,
                syncRecords: response.syncRecords
            })
        }, cb);
    }

    const handleApplyFilter = (filterDate: string) => {

        setState(state => ({
            ...state,
            isLoading: true,
        }));

        const cb = () => setState(state => ({ ...state, isLoading: false }));

        syncRecordsService.getSyncRecordsStatus(typeName, practiceId, statusId, 0, filterDate).subscribe((response) => {
            setState({
                ...state,
                selectedPage: 1,
                targetSyncRecordListStatus: response.status,
                totalCount: response.total,
                syncRecords: response.syncRecords,
                filterDate
            })
            syncRecordsStore.setFilterDate(filterDate);
        }, cb);
    }

    const handleGotoRecordReconcile = (syncRecordId: number) => {
        navigationService.toSyncRecordReconcile(history, syncRecordId);
        return;
    }

    const setDirection = (source: string, direction: SortingDirection) => {
        const itemIndex = state.sortingColumns.findIndex(item => item.source === source);
        state.sortingColumns[itemIndex].direction = direction;
        setState(state => ({ ...state, columns: state.sortingColumns }));
    }

    const handleSorting = (source: string, direction: SortingDirection) => {
        if (state.sortingSource === source) {
            direction = direction === SortingDirection.Asc
                ? SortingDirection.Desc
                : SortingDirection.Asc;

            setDirection(source, direction);
        }
        setState(state => ({
            ...state,
            sortingSource: source,
            syncRecords: state.syncRecords.sort((p1, p2) => handleCompare(p1, p2, direction, source))
        }));
    }

    const handleToggleFilter = (event?: React.MouseEvent<HTMLButtonElement>) => {
        const anchorEl = event && event.currentTarget ? event.currentTarget : null;
        state.isOpen = !state.isOpen;
        state.anchorEl = anchorEl;
        setState(state => ({ ...state }));
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<SyncRecordDetail[]>(syncRecordsQuery.targetSyncRecords$, targetSyncRecords => {
                setState({
                    ...state,
                    syncRecords: targetSyncRecords
                });
            }),
            onEmit<string>(syncRecordsQuery.targetSyncRecordListStatus$, targetSyncRecordListStatus => {
                setState(state => ({
                    ...state,
                    targetSyncRecordListStatus: targetSyncRecordListStatus ?? state.targetSyncRecordListStatus
                }))
            }),
            onEmit<number>(syncRecordsQuery.totalCount$, totalCount => {
                setState(state => ({
                    ...state,
                    totalCount: totalCount ?? state.totalCount
                }))
            }),
        ];

        const cb = () => setState(state => ({ ...state, isLoading: false }));

        if (!showSearchResult) {
            syncRecordsService.getSyncRecordsStatus(typeName, practiceId, statusId, 0, filterDate).subscribe(cb, cb);
        } else {
            cb();
        }


        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, []);

    return [
        state,
        handlePageChange,
        handleGotoRecordReconcile,
        handleSorting,
        handleToggleFilter,
        handleApplyFilter
    ];
}