import {Button, makeStyles, Theme, withStyles} from "@material-ui/core";
import {colors} from "../../../modules/common/constants/colors";

type Props = {
    topBannerHeight?: number;
};

export const useStyles = makeStyles<Theme, Props>((theme: Theme) => (
    {
        root: {
            height: 72,
            backgroundColor: colors.white,
            color: colors.systemBlack,
            zIndex: theme.zIndex.drawer,
            boxShadow: 'none',
            marginTop: 0,
            minHeight: 72,
            top: ({ topBannerHeight }) => topBannerHeight
        },
        grow: {
            flexGrow: 1,
        },
        logoSection: {
            display: 'flex',
            alignItems: 'center',
        },
        redesignLogo: {
            width: 156,
            height: 'auto',
            [theme.breakpoints.down('xs')]: {
                width: 110
            },
        },
        employerLogo: {
            height: 40,
            [theme.breakpoints.down('xs')]: {
                width: 100,
                height: 'auto'
            },
        },
        toolbar: {
            minHeight: 72,
        },
        titleSection: {
            display: 'flex',
            marginTop: 'auto',
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingBottom: 16
        },
        menuItem: {
            padding: '5px 15px',
            border: 'none !important',
            fontFamily: 'Palatino Linotype',
        },
        sectionDesktop: {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
            },
        },
        mobileMenu: {
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        mobileMenuButton: {
            display: 'flex',
            padding: '5px 15px',
            border: 'none !important',
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        mobileMenuItem: {
            fontFamily: 'Palatino Linotype !important',
        },
        mobileChildrenMenuItem: {
            paddingLeft: theme.spacing(4),
        },
        defaultCursor: {
            cursor: 'default !important',
        },
        plusStyle: {
            color: colors.darkGray,
            fontSize: 24,
            marginLeft: 24,
            marginRight: 24,
            [theme.breakpoints.down('xs')]: {
                fontSize: 16,
                marginLeft: 8,
                marginRight: 0
            },
        },
        userAccountBtnSection: {
            position: 'absolute',
            right: 120,
            bottom: 5,
            [theme.breakpoints.down('sm')]: {
                right: 18,
                bottom: 6
            },
        },
        logoutText: {
            textTransform: 'capitalize',
            color: colors.navy,
            fontSize: 14,
            fontWeight: 500,
            [theme.breakpoints.down('xs')]: {
                fontSize: 12
            },
        }
    }),
);

export const OptionButton = withStyles((theme: Theme) => ({
    root: {
        color: 'white',
        backgroundColor: '#37607c',
        borderRadius: 8,
        '&:hover': {
            color: '#37607c',
        },
        '&:disabled': {
            color: '#7c8a9dCC',
            border: '1px solid #00000000',
        },
    },
}))(Button);
