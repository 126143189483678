import ReactDOM from "react-dom";
import React from "react";
import {
    getFullRecommendationContent,
    getFullRecommendationContentNew,
    HealthReportMode,
    HealthReportModel,
    HealthReportPages,
    HealthReportSections,
    HealthReportVersion,
    recommendationRowsCapacity,
    RecommendationTypesNumbers
} from "../models/healthReport.models";
import {
    HealthReportAppendixPage,
    HealthReportChronicDisease0Page,
    HealthReportChronicDisease1Page,
    HealthReportChronicDisease2Page,
    HealthReportChronicDisease3Page,
    HealthReportChronicDisease4Page,
    HealthReportChronicDisease5Page,
    HealthReportDetails1Page,
    HealthReportDetails2Page,
    HealthReportDetails3Page,
    HealthReportDetails4Page,
    HealthReportDetails5Page,
    HealthReportDietAndNutrition0Page,
    HealthReportDietAndNutrition1Page,
    HealthReportDietAndNutrition2Page,
    HealthReportDietAndNutrition3Page,
    HealthReportDietAndNutrition4Page,
    HealthReportDietAndNutrition5Page,
    HealthReportDietAndNutrition6Page,
    HealthReportExerciseAndRecovery0Page,
    HealthReportExerciseAndRecovery1Page,
    HealthReportExerciseAndRecovery2Page,
    HealthReportExerciseAndRecovery3Page,
    HealthReportExerciseAndRecovery4Page,
    HealthReportExerciseAndRecovery5Page,
    HealthReportExerciseAndRecovery6Page,
    HealthReportExerciseAndRecovery7Page,
    HealthReportLongevity0Page,
    HealthReportLongevity1Page,
    HealthReportLongevity2Page,
    HealthReportMicrobiome0Page,
    HealthReportMicrobiome1Page,
    HealthReportMicrobiome2Page,
    HealthReportNeurobehavioral0Page,
    HealthReportNeurobehavioralPage,
    HealthReportSleep0Page,
    HealthReportSleepPage,
    HealthReportStartPage,
    HealthReportSupplementsPage
} from "../a4Pages";
import { HealthReportPageProps } from "../a4Pages/healthReportPageProps";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {
    HealthReportRecommendationPage,
    HealthReportRecommendationPageProps
} from "../a4Pages/HealthReportRecommendationPage";
import { getNumericKeys } from "../../common/helpers/enum-mapper";
import moment from "moment";
import { toCurrentTimeZone } from "../../timezones/helpers/timezone";
import { PracticeProfileModel } from "../../account/models/practiceProfile.model";

const calculatePartSize = (part: string, maxRows: number, maxLine: number) => {
    const paragraphs = part.split('\n');
    let partSize = 0;
    for (const paragraph of paragraphs) {
        if (!paragraph.length) {
            partSize += 1;
            continue;
        }

        partSize += Math.ceil(paragraph.length / maxLine);
    }

    return partSize;
}

const isOverflow = (recommendation: string, rowsMax: number, lineMax: number): boolean => {
    if (!recommendation || !recommendation.length) return false;
    const paragraphs = recommendation.split('\n');
    let rowsCount = 0;

    for (const paragraph of paragraphs) {
        const linesCount = paragraph.length > 0 ? paragraph.length / lineMax : 1;
        rowsCount += Math.ceil(linesCount);
    }

    return rowsCount > rowsMax;
}

const splitOnParts = (recommendation: string, mainPageMaxRows: number, mainPageMaxLine: number, extraPageMaxRows: number, extraPageMaxLine: number): string[] => {
    let parts: string[] = [];
    const paragraphs = recommendation.split('\n');
    let part = '';
    let partSize = 0;
    let maxRows = mainPageMaxRows;
    let maxLine = mainPageMaxLine;
    for (const paragraph of paragraphs) {
        if (!paragraph.length) {
            part += '\n';
            partSize += 1;
            continue;
        }

        const linesCount = Math.ceil(paragraph.length / maxLine);
        if (partSize + linesCount + 3 >= maxRows) {
            const paragraphBuffer = part.split('\n\n');
            const paragraphLastPart = paragraphBuffer.pop();

            parts.push(paragraphBuffer.join('\n\n').concat('\n'));
            part = paragraphLastPart;
            partSize = calculatePartSize(paragraphLastPart, maxRows, maxLine);
            maxRows = extraPageMaxRows;
            maxLine = extraPageMaxLine;
        }

        part += `${paragraph}\n`;
        partSize += linesCount;
    }

    if (part.length) {
        parts.push(part);
    }

    return parts;
}

const breakDietAndNutrition2Pages = (props: HealthReportPageProps): Function[] => {
    const pages: Function[] = [HealthReportDietAndNutrition2Page];
    const fullRecommendation = parseFloat(props.report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
        ? getFullRecommendationContentNew(props.recommendations, RecommendationTypesNumbers.Macronutrient)
        : getFullRecommendationContent(props.report.dietAndNutritionReport.macronutrientRecommendation);

    if (isOverflow(fullRecommendation, recommendationRowsCapacity[RecommendationTypesNumbers.Macronutrient], 107)) {
        const recommendationParts = splitOnParts(
            fullRecommendation,
            recommendationRowsCapacity[RecommendationTypesNumbers.Macronutrient],
            107,
            recommendationRowsCapacity[RecommendationTypesNumbers.Extra],
            107);

        props.report.dietAndNutritionReport.macronutrientRecommendation.printableContent = recommendationParts.shift();

        for (const recommendationPart of recommendationParts) {
            const recommendationPageProps: HealthReportRecommendationPageProps = {
                section: HealthReportSections.DietAndNutrition,
                pageNumber: props.pageNumber,
                title: "YOUR MACRONUTRIENT RECOMMENDATIONS",
                recommendation: recommendationPart,
                practice: props.practice
            }

            const extraPage = (): [Function, HealthReportRecommendationPageProps] => {
                return [HealthReportRecommendationPage, recommendationPageProps];
            }

            pages.push(extraPage);
        }
    } else {
        props.report.dietAndNutritionReport.macronutrientRecommendation.printableContent = fullRecommendation;
    }

    return pages;
}

const breakDietAndNutrition4Pages = (props: HealthReportPageProps): Function[] => {
    const pages: Function[] = [HealthReportDietAndNutrition4Page];
    const fullRecommendation = parseFloat(props.report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
        ? getFullRecommendationContentNew(props.recommendations, RecommendationTypesNumbers.Methylation)
        : getFullRecommendationContent(props.report.dietAndNutritionReport.methylationRecommendation);

    if (isOverflow(fullRecommendation, recommendationRowsCapacity[RecommendationTypesNumbers.Methylation], 107)) {
        const recommendationParts = splitOnParts(
            fullRecommendation,
            recommendationRowsCapacity[RecommendationTypesNumbers.Methylation],
            107,
            recommendationRowsCapacity[RecommendationTypesNumbers.Extra],
            107);

        props.report.dietAndNutritionReport.methylationRecommendation.printableContent = recommendationParts.shift();

        for (const recommendationPart of recommendationParts) {
            const recommendationPageProps: HealthReportRecommendationPageProps = {
                section: HealthReportSections.DietAndNutrition,
                pageNumber: props.pageNumber,
                title: "YOUR METHYLATION RECOMMENDATIONS",
                recommendation: recommendationPart,
                practice: props.practice
            }

            const extraPage = (): [Function, HealthReportRecommendationPageProps] => {
                return [HealthReportRecommendationPage, recommendationPageProps];
            }

            pages.push(extraPage);
        }
    } else {
        props.report.dietAndNutritionReport.methylationRecommendation.printableContent = fullRecommendation;
    }

    return pages;
}

const breakDietAndNutrition5Pages = (props: HealthReportPageProps): Function[] => {
    const pages: Function[] = [HealthReportDietAndNutrition5Page];
    const fullRecommendation = parseFloat(props.report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
        ? getFullRecommendationContentNew(props.recommendations, RecommendationTypesNumbers.VitaminsAndMicronutrients)
        : getFullRecommendationContent(props.report.dietAndNutritionReport.vitaminsAndMicronutrientsRecommendation);

    if (isOverflow(fullRecommendation, recommendationRowsCapacity[RecommendationTypesNumbers.VitaminsAndMicronutrients], 107)) {
        const recommendationParts = splitOnParts(
            fullRecommendation,
            recommendationRowsCapacity[RecommendationTypesNumbers.VitaminsAndMicronutrients],
            107,
            recommendationRowsCapacity[RecommendationTypesNumbers.Extra],
            107);

        props.report.dietAndNutritionReport.vitaminsAndMicronutrientsRecommendation.printableContent = recommendationParts.shift();

        for (const recommendationPart of recommendationParts) {
            const recommendationPageProps: HealthReportRecommendationPageProps = {
                section: HealthReportSections.DietAndNutrition,
                pageNumber: props.pageNumber,
                title: "YOUR VITAMINS AND MICRONUTRIENTS RECOMMENDATIONS",
                recommendation: recommendationPart,
                practice: props.practice
            }

            const extraPage = (): [Function, HealthReportRecommendationPageProps] => {
                return [HealthReportRecommendationPage, recommendationPageProps];
            }

            pages.push(extraPage);
        }
    } else {
        props.report.dietAndNutritionReport.vitaminsAndMicronutrientsRecommendation.printableContent = fullRecommendation;
    }

    return pages;
}

const breakDietAndNutrition6Pages = (props: HealthReportPageProps): Function[] => {
    const pages: Function[] = [HealthReportDietAndNutrition6Page];
    {
        const fullRecommendation = parseFloat(props.report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
            ? getFullRecommendationContentNew(props.recommendations, RecommendationTypesNumbers.CompleteDiet)
            : getFullRecommendationContent(props.report.dietAndNutritionReport.completeDietRecommendation);

        if (isOverflow(fullRecommendation, recommendationRowsCapacity[RecommendationTypesNumbers.CompleteDiet], 107)) {
            const recommendationParts = splitOnParts(
                fullRecommendation,
                recommendationRowsCapacity[RecommendationTypesNumbers.CompleteDiet],
                107,
                recommendationRowsCapacity[RecommendationTypesNumbers.Extra],
                107);

            props.report.dietAndNutritionReport.completeDietRecommendation.printableContent = recommendationParts.shift();

            for (const recommendationPart of recommendationParts) {
                const recommendationPageProps: HealthReportRecommendationPageProps = {
                    section: HealthReportSections.DietAndNutrition,
                    pageNumber: props.pageNumber,
                    title: "YOUR COMPLETE DIET RECOMMENDATIONS",
                    recommendation: recommendationPart,
                    practice: props.practice
                }

                const extraPage = (): [Function, HealthReportRecommendationPageProps] => {
                    return [HealthReportRecommendationPage, recommendationPageProps];
                }

                pages.push(extraPage);
            }
        } else {
            props.report.dietAndNutritionReport.completeDietRecommendation.printableContent = fullRecommendation;
        }
    }
    {
        const fullRecommendation = parseFloat(props.report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
            ? getFullRecommendationContentNew(props.recommendations, RecommendationTypesNumbers.SuperFoods)
            : getFullRecommendationContent(props.report.dietAndNutritionReport.superFoodsRecommendation);

        if (isOverflow(fullRecommendation, recommendationRowsCapacity[RecommendationTypesNumbers.SuperFoods], 40)) {
            const recommendationParts = splitOnParts(
                fullRecommendation,
                recommendationRowsCapacity[RecommendationTypesNumbers.SuperFoods],
                40,
                recommendationRowsCapacity[RecommendationTypesNumbers.Extra],
                107);

            props.report.dietAndNutritionReport.superFoodsRecommendation.printableContent = recommendationParts.shift();

            for (const recommendationPart of recommendationParts) {
                const recommendationPageProps: HealthReportRecommendationPageProps = {
                    section: HealthReportSections.DietAndNutrition,
                    pageNumber: props.pageNumber,
                    title: "YOUR SUPER FOODS RECOMMENDATIONS",
                    recommendation: recommendationPart,
                    practice: props.practice
                }

                const extraPage = (): [Function, HealthReportRecommendationPageProps] => {
                    return [HealthReportRecommendationPage, recommendationPageProps];
                }

                pages.push(extraPage);
            }
        } else {
            props.report.dietAndNutritionReport.superFoodsRecommendation.printableContent = fullRecommendation;
        }
    }
    {
        const fullRecommendation = parseFloat(props.report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
            ? getFullRecommendationContentNew(props.recommendations, RecommendationTypesNumbers.KryptoniteFoods)
            : getFullRecommendationContent(props.report.dietAndNutritionReport.kryptoniteFoodsRecommendation);

        if (isOverflow(fullRecommendation, recommendationRowsCapacity[RecommendationTypesNumbers.KryptoniteFoods], 40)) {
            const recommendationParts = splitOnParts(
                fullRecommendation,
                recommendationRowsCapacity[RecommendationTypesNumbers.KryptoniteFoods],
                40,
                recommendationRowsCapacity[RecommendationTypesNumbers.Extra],
                107);

            props.report.dietAndNutritionReport.kryptoniteFoodsRecommendation.printableContent = recommendationParts.shift();

            for (const recommendationPart of recommendationParts) {
                const recommendationPageProps: HealthReportRecommendationPageProps = {
                    section: HealthReportSections.DietAndNutrition,
                    pageNumber: props.pageNumber,
                    title: "YOUR KRYPTONITE RECOMMENDATIONS",
                    recommendation: recommendationPart,
                    practice: props.practice
                }

                const extraPage = (): [Function, HealthReportRecommendationPageProps] => {
                    return [HealthReportRecommendationPage, recommendationPageProps];
                }

                pages.push(extraPage);
            }
        } else {
            props.report.dietAndNutritionReport.kryptoniteFoodsRecommendation.printableContent = fullRecommendation;
        }
    }

    return pages;
}

const breakExerciseAndRecoveryPages = (props: HealthReportPageProps): Function[] => {
    const pages: Function[] = [HealthReportExerciseAndRecovery2Page];
    const fullRecommendation = parseFloat(props.report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
        ? getFullRecommendationContentNew(props.recommendations, RecommendationTypesNumbers.ExerciseAndRecovery)
        : getFullRecommendationContent(props.report.exerciseAndRecoveryReport.recommendation);

    if (isOverflow(fullRecommendation, recommendationRowsCapacity[RecommendationTypesNumbers.ExerciseAndRecovery], 107)) {
        const recommendationParts = splitOnParts(
            fullRecommendation,
            recommendationRowsCapacity[RecommendationTypesNumbers.ExerciseAndRecovery],
            107,
            recommendationRowsCapacity[RecommendationTypesNumbers.Extra],
            107);

        props.report.exerciseAndRecoveryReport.recommendation.printableContent = recommendationParts.shift();

        for (const recommendationPart of recommendationParts) {
            const recommendationPageProps: HealthReportRecommendationPageProps = {
                section: HealthReportSections.ExerciseAndRecovery,
                pageNumber: props.pageNumber,
                title: "YOUR Exercise And Recovery RECOMMENDATIONS",
                recommendation: recommendationPart,
                practice: props.practice
            }

            const extraPage = (): [Function, HealthReportRecommendationPageProps] => {
                return [HealthReportRecommendationPage, recommendationPageProps];
            }

            pages.push(extraPage);
        }
    } else {
        props.report.exerciseAndRecoveryReport.recommendation.printableContent = fullRecommendation;
    }

    return pages;
}

const breakSleepPages = (props: HealthReportPageProps): Function[] => {
    const pages: Function[] = [HealthReportSleepPage];
    const fullRecommendation = parseFloat(props.report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
        ? getFullRecommendationContentNew(props.recommendations, RecommendationTypesNumbers.Sleep)
        : getFullRecommendationContent(props.report.sleepReport.recommendation);

    if (isOverflow(fullRecommendation, recommendationRowsCapacity[RecommendationTypesNumbers.Sleep], 107)) {
        const recommendationParts = splitOnParts(
            fullRecommendation,
            recommendationRowsCapacity[RecommendationTypesNumbers.Sleep],
            107,
            recommendationRowsCapacity[RecommendationTypesNumbers.Extra],
            107);

        props.report.sleepReport.recommendation.printableContent = recommendationParts.shift();

        for (const recommendationPart of recommendationParts) {
            const recommendationPageProps: HealthReportRecommendationPageProps = {
                section: HealthReportSections.Sleep,
                pageNumber: props.pageNumber,
                title: "YOUR SLEEP RECOMMENDATIONS",
                recommendation: recommendationPart,
                practice: props.practice
            }

            const extraPage = (): [Function, HealthReportRecommendationPageProps] => {
                return [HealthReportRecommendationPage, recommendationPageProps];
            }

            pages.push(extraPage);
        }
    } else {
        props.report.sleepReport.recommendation.printableContent = fullRecommendation;
    }

    return pages;
}

const breakNeurobehavioralPages = (props: HealthReportPageProps): Function[] => {
    const pages: Function[] = [HealthReportNeurobehavioralPage];
    const fullRecommendation = parseFloat(props.report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
        ? getFullRecommendationContentNew(props.recommendations, RecommendationTypesNumbers.Neurobehavioral)
        : getFullRecommendationContent(props.report.neurobehavioralReport.recommendation);

    if (isOverflow(fullRecommendation, recommendationRowsCapacity[RecommendationTypesNumbers.Neurobehavioral], 107)) {
        const recommendationParts = splitOnParts(
            fullRecommendation,
            recommendationRowsCapacity[RecommendationTypesNumbers.Neurobehavioral],
            107,
            recommendationRowsCapacity[RecommendationTypesNumbers.Extra],
            107);

        props.report.neurobehavioralReport.recommendation.printableContent = recommendationParts.shift();

        for (const recommendationPart of recommendationParts) {
            const recommendationPageProps: HealthReportRecommendationPageProps = {
                section: HealthReportSections.Neurobehavioral,
                pageNumber: props.pageNumber,
                title: "YOUR Neurobehavioral RECOMMENDATIONS",
                recommendation: recommendationPart,
                practice: props.practice
            }

            const extraPage = (): [Function, HealthReportRecommendationPageProps] => {
                return [HealthReportRecommendationPage, recommendationPageProps];
            }

            pages.push(extraPage);
        }
    } else {
        props.report.neurobehavioralReport.recommendation.printableContent = fullRecommendation;
    }

    return pages;
}

const breakMicrobiomePages = (props: HealthReportPageProps): Function[] => {
    const pages: Function[] = [HealthReportMicrobiome2Page];
    const fullRecommendation = parseFloat(props.report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
        ? getFullRecommendationContentNew(props.recommendations, RecommendationTypesNumbers.Microbiome)
        : getFullRecommendationContent(props.report.microbiomeReport.recommendation);

    if (isOverflow(fullRecommendation, recommendationRowsCapacity[RecommendationTypesNumbers.Microbiome], 107)) {
        const recommendationParts = splitOnParts(
            fullRecommendation,
            recommendationRowsCapacity[RecommendationTypesNumbers.Microbiome],
            107,
            recommendationRowsCapacity[RecommendationTypesNumbers.Extra],
            107);

        props.report.microbiomeReport.recommendation.printableContent = recommendationParts.shift();

        for (const recommendationPart of recommendationParts) {
            const recommendationPageProps: HealthReportRecommendationPageProps = {
                section: HealthReportSections.Microbiome,
                pageNumber: props.pageNumber,
                title: "YOUR MICROBIOME RECOMMENDATIONS",
                recommendation: recommendationPart,
                practice: props.practice
            }

            const extraPage = (): [Function, HealthReportRecommendationPageProps] => {
                return [HealthReportRecommendationPage, recommendationPageProps];
            }

            pages.push(extraPage);
        }
    } else {
        props.report.microbiomeReport.recommendation.printableContent = fullRecommendation;
    }

    return pages;
}

const breakChronicDisease2Pages = (props: HealthReportPageProps): Function[] => {
    const pages: Function[] = [HealthReportChronicDisease2Page];
    const fullRecommendation = parseFloat(props.report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
        ? getFullRecommendationContentNew(props.recommendations, RecommendationTypesNumbers.Cardiovascular)
        : getFullRecommendationContent(props.report.longevityReport.cardiovascularRecommendation);

    if (isOverflow(fullRecommendation, recommendationRowsCapacity[RecommendationTypesNumbers.Cardiovascular], 107)) {
        const recommendationParts = splitOnParts(
            fullRecommendation,
            recommendationRowsCapacity[RecommendationTypesNumbers.Cardiovascular],
            107,
            recommendationRowsCapacity[RecommendationTypesNumbers.Extra],
            107);

        props.report.longevityReport.cardiovascularRecommendation.printableContent = recommendationParts.shift();

        for (const recommendationPart of recommendationParts) {
            const recommendationPageProps: HealthReportRecommendationPageProps = {
                section: HealthReportSections.ChronicDisease,
                pageNumber: props.pageNumber,
                title: "YOUR CARDIOVASCULAR DISEASE RECOMMENDATIONS",
                recommendation: recommendationPart,
                practice: props.practice
            }

            const extraPage = (): [Function, HealthReportRecommendationPageProps] => {
                return [HealthReportRecommendationPage, recommendationPageProps];
            }

            pages.push(extraPage);
        }
    } else {
        props.report.longevityReport.cardiovascularRecommendation.printableContent = fullRecommendation;
    }

    return pages;
}

const breakChronicDisease3Pages = (props: HealthReportPageProps): Function[] => {
    const pages: Function[] = [HealthReportChronicDisease3Page];
    const fullRecommendation = parseFloat(props.report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
        ? getFullRecommendationContentNew(props.recommendations, RecommendationTypesNumbers.Dementia)
        : getFullRecommendationContent(props.report.longevityReport.dementiaRecommendation);

    if (isOverflow(fullRecommendation, recommendationRowsCapacity[RecommendationTypesNumbers.Dementia], 107)) {
        const recommendationParts = splitOnParts(
            fullRecommendation,
            recommendationRowsCapacity[RecommendationTypesNumbers.Dementia],
            107,
            recommendationRowsCapacity[RecommendationTypesNumbers.Extra],
            107);

        props.report.longevityReport.dementiaRecommendation.printableContent = recommendationParts.shift();

        for (const recommendationPart of recommendationParts) {
            const recommendationPageProps: HealthReportRecommendationPageProps = {
                section: HealthReportSections.ChronicDisease,
                pageNumber: props.pageNumber,
                title: "YOUR DEMENTIA DISEASE RECOMMENDATIONS",
                recommendation: recommendationPart,
                practice: props.practice
            }

            const extraPage = (): [Function, HealthReportRecommendationPageProps] => {
                return [HealthReportRecommendationPage, recommendationPageProps];
            }

            pages.push(extraPage);
        }
    } else {
        props.report.longevityReport.dementiaRecommendation.printableContent = fullRecommendation;
    }

    return pages;
}

const breakChronicDisease4Pages = (props: HealthReportPageProps): Function[] => {
    const pages: Function[] = [HealthReportChronicDisease4Page];
    const fullRecommendation = parseFloat(props.report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
        ? getFullRecommendationContentNew(props.recommendations, RecommendationTypesNumbers.InsulinResistance)
        : getFullRecommendationContent(props.report.longevityReport.insulinResistanceRecommendation);

    if (isOverflow(fullRecommendation, recommendationRowsCapacity[RecommendationTypesNumbers.InsulinResistance], 107)) {
        const recommendationParts = splitOnParts(
            fullRecommendation,
            recommendationRowsCapacity[RecommendationTypesNumbers.InsulinResistance],
            107,
            recommendationRowsCapacity[RecommendationTypesNumbers.Extra],
            107);

        props.report.longevityReport.insulinResistanceRecommendation.printableContent = recommendationParts.shift();

        for (const recommendationPart of recommendationParts) {
            const recommendationPageProps: HealthReportRecommendationPageProps = {
                section: HealthReportSections.ChronicDisease,
                pageNumber: props.pageNumber,
                title: "YOUR INSULIN Resistance RECOMMENDATIONS",
                recommendation: recommendationPart,
                practice: props.practice
            }

            const extraPage = (): [Function, HealthReportRecommendationPageProps] => {
                return [HealthReportRecommendationPage, recommendationPageProps];
            }

            pages.push(extraPage);
        }
    } else {
        props.report.longevityReport.insulinResistanceRecommendation.printableContent = fullRecommendation;
    }

    return pages;
}

const breakChronicDisease5Pages = (props: HealthReportPageProps): Function[] => {
    const pages: Function[] = [HealthReportChronicDisease5Page];
    const fullRecommendation = parseFloat(props.report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
        ? getFullRecommendationContentNew(props.recommendations, RecommendationTypesNumbers.Inflammation)
        : getFullRecommendationContent(props.report.longevityReport.inflammationRecommendation);

    if (isOverflow(fullRecommendation, recommendationRowsCapacity[RecommendationTypesNumbers.Inflammation], 107)) {
        const recommendationParts = splitOnParts(
            fullRecommendation,
            recommendationRowsCapacity[RecommendationTypesNumbers.Inflammation],
            107,
            recommendationRowsCapacity[RecommendationTypesNumbers.Extra],
            107);

        props.report.longevityReport.inflammationRecommendation.printableContent = recommendationParts.shift();

        for (const recommendationPart of recommendationParts) {
            const recommendationPageProps: HealthReportRecommendationPageProps = {
                section: HealthReportSections.ChronicDisease,
                pageNumber: props.pageNumber,
                title: "YOUR Inflammation RECOMMENDATIONS",
                recommendation: recommendationPart,
                practice: props.practice
            }

            const extraPage = (): [Function, HealthReportRecommendationPageProps] => {
                return [HealthReportRecommendationPage, recommendationPageProps];
            }

            pages.push(extraPage);
        }
    } else {
        props.report.longevityReport.inflammationRecommendation.printableContent = fullRecommendation;
    }

    return pages;
}

const breakLongevityPages = (props: HealthReportPageProps): Function[] => {
    const pages: Function[] = [HealthReportLongevity2Page];
    const fullRecommendation = parseFloat(props.report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
        ? getFullRecommendationContentNew(props.recommendations, RecommendationTypesNumbers.Longevity)
        : getFullRecommendationContent(props.report.longevityReport.recommendation);

    if (isOverflow(fullRecommendation, recommendationRowsCapacity[RecommendationTypesNumbers.Longevity], 107)) {
        const recommendationParts = splitOnParts(
            fullRecommendation,
            recommendationRowsCapacity[RecommendationTypesNumbers.Longevity],
            107,
            recommendationRowsCapacity[RecommendationTypesNumbers.Extra],
            107);

        props.report.longevityReport.recommendation.printableContent = recommendationParts.shift();

        for (const recommendationPart of recommendationParts) {
            const recommendationPageProps: HealthReportRecommendationPageProps = {
                section: HealthReportSections.Longevity,
                pageNumber: props.pageNumber,
                title: "YOUR LONGEVITY LIFESTYLE RECOMMENDATIONS",
                recommendation: recommendationPart,
                practice: props.practice
            }

            const extraPage = (): [Function, HealthReportRecommendationPageProps] => {
                return [HealthReportRecommendationPage, recommendationPageProps];
            }

            pages.push(extraPage);
        }
    } else {
        props.report.longevityReport.recommendation.printableContent = fullRecommendation;
    }

    return pages;
}

const breakSupplementsPages = (props: HealthReportPageProps): Function[] => {
    const pages: Function[] = [HealthReportSupplementsPage];
    const fullRecommendation = parseFloat(props.report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
        ? getFullRecommendationContentNew(props.recommendations, RecommendationTypesNumbers.Supplements)
        : getFullRecommendationContent(props.report.overallReport.supplementsRecommendation);

    if (isOverflow(fullRecommendation, recommendationRowsCapacity[RecommendationTypesNumbers.Supplements], 107)) {
        const recommendationParts = splitOnParts(
            fullRecommendation,
            recommendationRowsCapacity[RecommendationTypesNumbers.Supplements],
            107,
            recommendationRowsCapacity[RecommendationTypesNumbers.Extra],
            107);

        props.report.overallReport.supplementsRecommendation.printableContent = recommendationParts.shift();

        for (const recommendationPart of recommendationParts) {
            const recommendationPageProps: HealthReportRecommendationPageProps = {
                section: HealthReportSections.Details,
                pageNumber: props.pageNumber,
                title: "RECOMMENDED SUPPLEMENTS SUMMARY",
                recommendation: recommendationPart,
                practice: props.practice
            }

            const extraPage = (): [Function, HealthReportRecommendationPageProps] => {
                return [HealthReportRecommendationPage, recommendationPageProps];
            }

            pages.push(extraPage);
        }
    } else {
        props.report.overallReport.supplementsRecommendation.printableContent = fullRecommendation;
    }

    return pages;
}

/**
 * Provides method for working with health report
 */
export class HealthReportPdfServices {
    private _snapshotFormat: string = "JPEG";
    private _pages: { [type: number]: Function } = {
        [HealthReportPages.StartPage]: HealthReportStartPage,
        [HealthReportPages.DietAndNutrition0]: HealthReportDietAndNutrition0Page,
        [HealthReportPages.DietAndNutrition1]: HealthReportDietAndNutrition1Page,
        [HealthReportPages.DietAndNutrition2]: HealthReportDietAndNutrition2Page,
        [HealthReportPages.DietAndNutrition3]: HealthReportDietAndNutrition3Page,
        [HealthReportPages.DietAndNutrition4]: HealthReportDietAndNutrition4Page,
        [HealthReportPages.DietAndNutrition5]: HealthReportDietAndNutrition5Page,
        [HealthReportPages.DietAndNutrition6]: HealthReportDietAndNutrition6Page,
        [HealthReportPages.ExerciseAndRecovery0]: HealthReportExerciseAndRecovery0Page,
        [HealthReportPages.ExerciseAndRecovery1]: HealthReportExerciseAndRecovery1Page,
        [HealthReportPages.ExerciseAndRecovery2]: HealthReportExerciseAndRecovery2Page,
        [HealthReportPages.ExerciseAndRecovery3]: HealthReportExerciseAndRecovery3Page,
        [HealthReportPages.ExerciseAndRecovery4]: HealthReportExerciseAndRecovery4Page,
        [HealthReportPages.ExerciseAndRecovery5]: HealthReportExerciseAndRecovery5Page,
        [HealthReportPages.ExerciseAndRecovery6]: HealthReportExerciseAndRecovery6Page,
        [HealthReportPages.ExerciseAndRecovery7]: HealthReportExerciseAndRecovery7Page,
        [HealthReportPages.Sleep0]: HealthReportSleep0Page,
        [HealthReportPages.Sleep1]: HealthReportSleepPage,
        [HealthReportPages.Neurobehavioral0]: HealthReportNeurobehavioral0Page,
        [HealthReportPages.Neurobehavioral1]: HealthReportNeurobehavioralPage,
        [HealthReportPages.Microbiome0]: HealthReportMicrobiome0Page,
        [HealthReportPages.Microbiome1]: HealthReportMicrobiome1Page,
        [HealthReportPages.Microbiome2]: HealthReportMicrobiome2Page,
        [HealthReportPages.ChronicDisease0]: HealthReportChronicDisease0Page,
        [HealthReportPages.ChronicDisease1]: HealthReportChronicDisease1Page,
        [HealthReportPages.ChronicDisease2]: HealthReportChronicDisease2Page,
        [HealthReportPages.ChronicDisease3]: HealthReportChronicDisease3Page,
        [HealthReportPages.ChronicDisease4]: HealthReportChronicDisease4Page,
        [HealthReportPages.ChronicDisease5]: HealthReportChronicDisease5Page,
        [HealthReportPages.Longevity0]: HealthReportLongevity0Page,
        [HealthReportPages.Longevity1]: HealthReportLongevity1Page,
        [HealthReportPages.Longevity2]: HealthReportLongevity2Page,
        [HealthReportPages.Supplements]: HealthReportSupplementsPage,
        [HealthReportPages.Details1]: HealthReportDetails1Page,
        [HealthReportPages.Appendix]: HealthReportAppendixPage,
        [HealthReportPages.Details2]: HealthReportDetails2Page,
        [HealthReportPages.Details3]: HealthReportDetails3Page,
        [HealthReportPages.Details4]: HealthReportDetails4Page,
        [HealthReportPages.Details5]: HealthReportDetails5Page,
        [HealthReportPages.Recommendations]: HealthReportRecommendationPage,
    };

    private breakablePages: { [page: number]: Function } = {
        [HealthReportPages.DietAndNutrition2]: breakDietAndNutrition2Pages,
        [HealthReportPages.DietAndNutrition4]: breakDietAndNutrition4Pages,
        [HealthReportPages.DietAndNutrition5]: breakDietAndNutrition5Pages,
        [HealthReportPages.DietAndNutrition6]: breakDietAndNutrition6Pages,
        [HealthReportPages.ExerciseAndRecovery2]: breakExerciseAndRecoveryPages,
        [HealthReportPages.Sleep1]: breakSleepPages,
        [HealthReportPages.Neurobehavioral1]: breakNeurobehavioralPages,
        [HealthReportPages.Microbiome2]: breakMicrobiomePages,
        [HealthReportPages.ChronicDisease2]: breakChronicDisease2Pages,
        [HealthReportPages.ChronicDisease3]: breakChronicDisease3Pages,
        [HealthReportPages.ChronicDisease4]: breakChronicDisease4Pages,
        [HealthReportPages.ChronicDisease5]: breakChronicDisease5Pages,
        [HealthReportPages.Longevity2]: breakLongevityPages,
        [HealthReportPages.Supplements]: breakSupplementsPages,
    }

    private _ignorePages: Map<number, HealthReportPages[]> = new Map<number, HealthReportPages[]>([
        [
            parseFloat(HealthReportVersion.OldVersion), [
                HealthReportPages.Supplements,
                HealthReportPages.Recommendations
            ]
        ],
        [
            parseFloat(HealthReportVersion.New), [
                HealthReportPages.ExerciseAndRecovery3,
                HealthReportPages.ExerciseAndRecovery4,
                HealthReportPages.ExerciseAndRecovery5,
                HealthReportPages.ExerciseAndRecovery6,
                HealthReportPages.Supplements,
                HealthReportPages.Recommendations,
            ]
        ],
        [
            parseFloat(HealthReportVersion.SetRecommendation), [
                HealthReportPages.ExerciseAndRecovery3,
                HealthReportPages.ExerciseAndRecovery4,
                HealthReportPages.ExerciseAndRecovery5,
                HealthReportPages.ExerciseAndRecovery6,
                HealthReportPages.Supplements,
                HealthReportPages.Recommendations]
        ]
    ]);

    public async generate(report: HealthReportModel, date: Date, practice: PracticeProfileModel) {
        const rootContainer = HealthReportPdfServices._createRootContainer();
        const mainPageProps: HealthReportPageProps = {
            report: JSON.parse(JSON.stringify(report)),
            recommendations: report.recommendations,
            pageNumber: 0,
            mode: HealthReportMode.PrintMode,
            handleChanges: null,
            practice: practice
        };

        const pdf = new jsPDF("p", "mm", "letter");

        const pageTypes = getNumericKeys(HealthReportPages).filter(x => !this._ignorePages.get(parseFloat(report.status.version))?.includes(x));

        for (const pageType of pageTypes) {
            if (pageType !== HealthReportPages.StartPage) {
                pdf.addPage("letter");
            }

            const breakablePage = this.breakablePages[pageType];
            if (!breakablePage) {
                await this._generatePage(pdf, this._pages[pageType], mainPageProps, rootContainer);
            } else {
                const pages = breakablePage(mainPageProps);
                await this._generatePage(pdf, pages.shift(), mainPageProps, rootContainer);

                if (pages.length) {
                    for (const pageFunction of pages) {
                        const [page, extraPageProps] = pageFunction();
                        pdf.addPage("letter");
                        await this._generateExtraPage(pdf, page, mainPageProps, extraPageProps, rootContainer)
                    }
                }
            }
        }

        pdf.save(`WH_PersonalizedHealthReport_${report.owner.firstName}_${report.owner.lastName}_${moment(toCurrentTimeZone(date)).format("YYYYMMDD")}.pdf`);

        HealthReportPdfServices._dispose(rootContainer);
    }

    private async _generatePage(pdf: jsPDF, page: Function, props: HealthReportPageProps, container: HTMLElement) {
        const snapshot = await this._getSnapshot<HealthReportPageProps>(page, props, container);
        const [width, height] = HealthReportPdfServices._getSize(snapshot, pdf);
        await pdf.addImage(HealthReportPdfServices._toImage(snapshot), this._snapshotFormat, 0, 0, width, height);
        props.pageNumber += 1;
    }

    private async _generateExtraPage(pdf: jsPDF, page: Function, mainPageProps: HealthReportPageProps, extraPageProps: HealthReportRecommendationPageProps, container: HTMLElement) {
        extraPageProps.pageNumber = mainPageProps.pageNumber;
        const snapshot = await this._getSnapshot<HealthReportRecommendationPageProps>(page, extraPageProps, container);
        const [width, height] = HealthReportPdfServices._getSize(snapshot, pdf);
        await pdf.addImage(HealthReportPdfServices._toImage(snapshot), this._snapshotFormat, 0, 0, width, height);
        mainPageProps.pageNumber += 1;
    }

    private async _getSnapshot<TProps>(page: Function, props: TProps, rootContainer: HTMLElement): Promise<HTMLCanvasElement> {
        const element = await this._renderPage(page, props, rootContainer);
        return await html2canvas(element);
    }

    private static _toImage(snapshot: HTMLCanvasElement): string {
        return snapshot.toDataURL("image/jpeg", 0.5);
    }

    private static _getSize(snapshot: HTMLCanvasElement, pdf: any): [number, number] {
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const imageWidth = snapshot.width;
        const imageHeight = snapshot.height;
        const ratio = imageWidth / imageHeight >= pageWidth / pageHeight ? pageWidth / imageWidth : pageHeight / imageHeight;

        return [imageWidth * ratio, imageHeight * ratio];
    }

    private async _renderPage<TProps>(page: Function, props: TProps, rootContainer: HTMLElement): Promise<HTMLElement> {
        return new Promise(function (resolve) {
            // @ts-ignore
            const element = React.createElement(page, props);
            //const container = document.createElement("div");
            ReactDOM.render(element, rootContainer.children[0]);
            //rootContainer.appendChild(container);
            setTimeout(() => {
                // @ts-ignore
                resolve(rootContainer.children[0]);
            }, 1)
        })
    }

    private static _dispose(page: HTMLElement): void {
        page.parentNode.removeChild(page);
    }

    private static _createRootContainer(): HTMLElement {
        const container = document.createElement("div");

        container.style.position = "absolute";
        container.style.top = "-16384px";
        container.style.left = "500px";
        container.style.background = "#FFFFFF";

        container.appendChild(document.createElement("div"));

        document.getElementsByTagName('BODY')[0].append(container);

        return container;
    }
}

export const healthReportPdfServices = new HealthReportPdfServices();