import React, {FC, Fragment} from 'react';
import { Box, Collapse, ListItem, ListItemText, TextField, FormControl, FormHelperText, Select, MenuItem, Checkbox } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { WildHealthButton } from "../../../../common/components/wildHealthButton/WildHealthButton";
import { useStyles } from "../manageApiResponseComponent.styles";
import commonUseStyles from "../../../../common/styles/common.styles";
import { ToolEnum } from '../manageApiResponseComponent.hooks';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { colors } from '../../../../common/constants/colors';
import {ProductType} from "../../../../products/enums/productType";

type EndpointAdminToolProps = {
    id: string;
    params: any;
    handleChanges: (field: string, value: any, tool: ToolEnum) => void;
    handleOpenToggle: () => void;
    handleClick: (any) => void;
    title: string;
    errors: any;
    isOpen: boolean;
    isLoading: boolean;
    toolMenu: ToolEnum;
}

const BuyProductsComponent: FC<EndpointAdminToolProps> = (props): JSX.Element => {
    const {
        id, isLoading, title, errors, params, isOpen, toolMenu,
        handleOpenToggle,
        handleChanges,
        handleClick,
    } = props;

    const classes = useStyles();
    const commonClasses = commonUseStyles();

    const renderProductTypeName = (type: ProductType) => {
        switch (type) {
            case ProductType.PhysicianVisit:
                return "Physician Visit";
            case ProductType.FreeLabOrder:
                return "Free Lab Order";
            case ProductType.FreeEpigeneticOrder:
                return "Free Epigenetic Order";
        }
    }

    const controlIcon = <CheckBoxOutlineBlankIcon style={{ fill: colors.mediumGray }} />;
    const controlCheckedIcon = <CheckBoxIcon style={{ fill: colors.main }} />

    return (
        <Fragment>
            <ListItem id={id} className={classes.divider} button onClick={handleOpenToggle}>
                <ListItemText primary={<span className="text-medium">{title}</span>}/>
                {isOpen ? <ExpandLess className={classes.activeIcon}/> : <ExpandMore/>}
            </ListItem>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <Box p={2}>
                    <Box>
                        <Box className={commonClasses.label} mb={1}>Patient Id *</Box>
                        <TextField
                            fullWidth
                            size="small"
                            required
                            type="number"
                            InputProps={{ className: 'input', classes }}
                            id="patientId"
                            placeholder="PatientId"
                            value={params.patientId}
                            variant="outlined"
                            disabled={!isOpen}
                            error={!!errors['patientId']}
                            helperText={errors['patientId']}
                            onChange={(v) => handleChanges(v.target.id, v.target.value, toolMenu)}
                        />
                    </Box>
                    <Box my={2}>
                        <FormControl
                            variant="outlined"
                            color='primary'
                            required
                            error={!!errors[`productType`]}
                            size="small"
                            disabled={!isOpen}
                            classes={{ root: classes.root }}
                            fullWidth>
                            <Box className={commonClasses.label} mb={1}>ProductType *</Box>
                            <Select
                                style={{ background: '#FFF' }}
                                classes={{ root: classes.root }}
                                value={params.productType}
                                error={!!errors[`productType`]}
                                inputProps={{
                                name: 'Product Type',
                                id: 'product-type-label',
                                classes,
                                }}
                                renderValue={() => {
                                    if (params.productType === null || !params.productType) {
                                        return <Box className={commonClasses.colorMediumGray}>Select</Box>;
                                    }

                                    return renderProductTypeName(params.productType);
                                }}
                                displayEmpty
                                onChange={(v) => handleChanges("productType", v.target.value, toolMenu)}
                            >
                                <MenuItem value={ProductType.PhysicianVisit}>{renderProductTypeName(ProductType.PhysicianVisit)}</MenuItem>
                                <MenuItem value={ProductType.FreeLabOrder}>{renderProductTypeName(ProductType.FreeLabOrder)}</MenuItem>
                                <MenuItem value={ProductType.FreeEpigeneticOrder}>{renderProductTypeName(ProductType.FreeEpigeneticOrder)}</MenuItem>
                            </Select>
                            <FormHelperText>{errors[`planName`]}</FormHelperText>
                        </FormControl>
                    </Box>
                    <Box my={2}>
                        <Box className={commonClasses.label} mb={1}>Quantity *</Box>
                        <TextField
                            fullWidth
                            size="small"
                            required
                            type="number"
                            InputProps={{ className: 'input', classes }}
                            id="quantity"
                            placeholder="Quantity"
                            value={params.quantity}
                            variant="outlined"
                            disabled={!isOpen}
                            error={!!errors['quantity']}
                            helperText={errors['quantity']}
                            onChange={(v) => handleChanges(v.target.id, v.target.value, toolMenu)}
                        />
                    </Box>
                    <Box my={2}>
                        <Box className={commonClasses.label} mb={1}>IsPaidForByDefaultEmployer *</Box>
                        <Box display="flex" alignItems="center">
                            <Checkbox
                                icon={controlIcon}
                                color="primary"
                                checkedIcon={controlCheckedIcon}
                                checked={params.isPaidForByDefaultEmployer}
                                onChange={() => handleChanges("isPaidForByDefaultEmployer", true, toolMenu)}
                            />
                            <Box mr={2}>Yes</Box>
                            <Checkbox
                                icon={controlIcon}
                                color="primary"
                                checkedIcon={controlCheckedIcon}
                                checked={!params.isPaidForByDefaultEmployer}
                                onChange={() => handleChanges("isPaidForByDefaultEmployer", false, toolMenu)}
                            />
                            <Box>No</Box>
                        </Box>
                    </Box>
                    <WildHealthButton
                        fullWidth
                        id={`${id}-button`}
                        loading={isLoading}
                        onClick={handleClick}
                    >
                        Execute
                    </WildHealthButton>
                </Box>
            </Collapse>
        </Fragment>
    )
}

export default BuyProductsComponent;