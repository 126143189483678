import {Box, IconButton, Link, Table, TableBody, TableContainer, TableHead,} from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import {WildHealthButton} from '../../../common/components/wildHealthButton/WildHealthButton';
import {
    WildHealthHeaderComponent
} from '../../../common/components/wildHealthHeaderComponent/WildHealthHeaderComponent';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import {CommonPlaceHolder} from '../../../common/components/wildHealthPlaceHolder/CommonPlaceHolder';
import {WildHealthTableCell} from '../../../common/components/wildHealthTable/WildHealthTableCell';
import {WildHealthTableRow} from '../../../common/components/wildHealthTable/WildHealthTableRow';
import {orderCreateStatusNames, OrderStatus} from '../../models/orders.models';
import {OtherOrdersViewState, useFacade} from './otherDiagnosticComponent.hooks';
import {useStyles} from './otherDiagnosticComponent.styles';
import {ProtectedElement} from "../../../common/components/ProtectedElement";
import {PermissionType} from "../../../auth/models/auth.enums";
import {toCurrentTimeZone} from '../../../timezones/helpers/timezone';
import {colors} from '../../../common/constants/colors';
import {WildHealthStatusLabel} from '../../../common/components/wildHealthStatusLabel/WildHealthStatusLabel';
import {Delete} from '@material-ui/icons';
import GetAppIcon from "@material-ui/icons/GetApp";
import {OtherOrderModel} from '../../models/otherOrders.models';
import {NewOtherOrderComponent} from "../newOtherOrderComponent/NewOtherOrderComponent";

interface OtherDiagnosticComponentProps {
    patientId?: number;
}

export const OtherDiagnosticComponent: React.FC<OtherDiagnosticComponentProps> = (props: OtherDiagnosticComponentProps) => {
    const { patientId } = props;

    const [
        {
            isLoading,
            isProcessing,
            orders,
            targetOrder,
            state
        },
        columns,
        handleDeleteOrder,
        handleDownloadOrder,
        handleOpenOrder,
        handleNewOrder,
        handleGoBack
    ] = useFacade(patientId);

    const classes = useStyles();
    const title = 'Other Diagnostic Orders';

    const emptyPlaceHolder = () => {
        return <span className="text-accent-secondary">(empty)</span>
    }

    const displayValue = (value: Date) => {
        return value ? moment(toCurrentTimeZone(value)).format("MM/DD/yyyy") : emptyPlaceHolder();
    }

    const displayName = (order: OtherOrderModel) => {
        const data = order.status === OrderStatus.Completed 
        ? displayValue(order.statusLogs.find(el => el.status === OrderStatus.Completed).date)
        : displayValue(order.orderedAt)
        if (order.items.length && order.items[0]?.description) {
            return `${order.items[0]?.description} ${data}`
        }

        return data
    }

    const displayStatus = (status: OrderStatus) => {
        switch (status) {
            case OrderStatus.Draft:
                return <WildHealthStatusLabel color='normal'>{orderCreateStatusNames.get(status)}</WildHealthStatusLabel>;
            case OrderStatus.Signed:
                return <WildHealthStatusLabel color='good'>{orderCreateStatusNames.get(status)}</WildHealthStatusLabel>
            case OrderStatus.UnderReview:
                return <WildHealthStatusLabel color='average1'>{orderCreateStatusNames.get(status)}</WildHealthStatusLabel>
        }
    }

    const displayAction = (status: OrderStatus, order: OtherOrderModel) => {
        if (status === OrderStatus.Signed) {
            return <IconButton onClick={() => handleDownloadOrder(order)} disabled={isProcessing}>
                <GetAppIcon className={classes.downloadIcon} />
            </IconButton>
        }

        return <IconButton onClick={() => handleDeleteOrder(order.id)} disabled={isProcessing}>
            <Delete className={classes.deleteIcon} />
        </IconButton>
    }

    const renderContent = () => {
        if (isLoading) {
            return (
                <WildHealthLinearProgress />
            )
        }

        if (state === OtherOrdersViewState.UpdateOrder) {
            return <NewOtherOrderComponent patientId={patientId} targetOrder={targetOrder} handleGoBack={handleGoBack}/>
        }

        if (state === OtherOrdersViewState.NewOrder) {
            return <NewOtherOrderComponent patientId={patientId} targetOrder={null} handleGoBack={handleGoBack}/>
        }

        if (!orders.length) {
            return <CommonPlaceHolder message={`No ${title} yet.`} />
        }

        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <WildHealthTableRow>
                            {
                                columns.map((item, index) =>
                                    <WildHealthTableCell key={`diagnostic-order-cell-${index}`} align="left">
                                        {item}
                                    </WildHealthTableCell>
                                )
                            }
                        </WildHealthTableRow>
                    </TableHead>
                    <TableBody>
                        {
                            orders.map((item, index) => {
                                return <WildHealthTableRow key={`diagnostic-order-row-${index}`}>
                                    <WildHealthTableCell align="left">
                                        <Link
                                            id='link-to-diagnostic-order'
                                            color={colors.main}
                                            onClick={() => handleOpenOrder(item)}
                                        >
                                            <Box pr={0.5} color={colors.main} fontWeight="500">{displayName(item)}</Box>
                                        </Link>
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="left">
                                        {displayValue(item.orderedAt)}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="left">
                                        {displayStatus(item.status)}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="left">
                                        {displayAction(item.status, item)}
                                    </WildHealthTableCell>
                                </WildHealthTableRow>
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const header = (
        <Box display='flex' justifyContent='space-between'>
            <span>
                {title}
            </span>
            <ProtectedElement
                element={
                    <Box>
                        <WildHealthButton id="diagnostic-order-new" onClick={() => handleNewOrder()}>
                            New Order
                        </WildHealthButton>
                    </Box>
                }
                permissions={[PermissionType.Coaching, PermissionType.ManagePatients]}
            />
        </Box>
    )

    return (
        <WildHealthHeaderComponent title={header}>
            <Box className={classes.content}>
                {renderContent()}
            </Box>
        </WildHealthHeaderComponent>
    );
}