import { Store, StoreConfig } from '@datorama/akita';
import { UserType } from '../../models/auth.enums';
import {AuthModel, ExternalAuthModel} from '../../models/auth.models';
import { Gender } from '../../../common/models/user.models';

export interface AuthState {
    auth: AuthModel;
    externalAuth: ExternalAuthModel[];
    isNewAccount: boolean;
}

/**
 * Creates initial auth state
 */
export function createInitialState(): AuthState {
    return {
        auth: {
            token: '',
            id: 0,
            employeeId: 0,
            patientId: 0,
            practiceId: 0,
            email: '',
            phoneNumber: '',
            plan: '',
            firstName: '',
            lastName: '',
            type: UserType.Unspecified,
            roleId: null,
            isVerified: false,
            isAgreementsChanged: false,
            isAgreementsConfirmed: true,
            registrationCompleted: false,
            expires: 0,
            isPremium: false,
            permissions: [],
            targetLocationId: 0,
            availableLocationIds: [],
            subscriptionStatus: null,
            isOnBehalf: false,
            externalAuthentication: null,
            dob: '',
            gender: Gender.None,
            identifyPayload: null,
            refreshTokenModel: null,
            meetingRecordingConsent: false,
        },
        externalAuth: [],
        isNewAccount: false,
    };
}

/**
 * Provides auth state management
 */
@StoreConfig({ name: 'session', resettable: true })
export class AuthStore extends Store<AuthState> {
    constructor() {
        super(createInitialState());
    }

    public addExternalAuth(externalAuth: ExternalAuthModel): void {
        const externalAuthList = this.getValue().externalAuth;

        if (externalAuthList.find(x => x.id === externalAuth.id)) {
            this.update({externalAuth: externalAuthList.map(x => x.id === externalAuth.id ? externalAuth : x)})
        } else {
            this.update({externalAuth: [...externalAuthList, externalAuth]})
        }
    }
}

export const authStore = new AuthStore();
