import { Button, Theme, withStyles } from "@material-ui/core";

export const BackButton = withStyles((theme: Theme) => ({
    root: {
        color: '#7C8A9D',
        textTransform: 'none',
        borderRadius: 2,
        '&:hover': {
        },
        '&:disabled': {
            color: '#7C8A9D99',
        },
    },
}))(Button);

export const BackButtonDialog = withStyles((theme: Theme) => ({
    root: {
        textTransform: 'none',
        borderRadius: 2,
        '&:hover': {
        },
        '&:disabled': {
            color: '#7C8A9D99',
        },
    },
}))(Button);
