import { TextField } from '@material-ui/core';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { colors } from '../../../../common/constants/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        list: {
            backgroundColor: theme.palette.background.paper,
            borderRadius: '5px',
            overflow: 'auto',
            width: '100%',
        },
        search: {
            backgroundColor: colors.gray100,
            borderRadius: 50,
        },
        searchBase: {
            padding: 16,
            fontFamily: 'Be Vietnam Pro',
            fontSize: 14,
        },
        icon: {
            color: colors.black,
        },
        image: {
            maxWidth: 300,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                maxWidth: 444,
            },
        },
        groups: {
            minHeight: 300,
            height: 'calc(100vh - 268px)',
            maxHeight: 'calc(100vh - 204px)',
            overflow: 'auto',
        },
    }),
);

export const SearchTextField = withStyles({
    root: {
        '& label.Mui-focused': {
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'transparent',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': { 
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'transparent',
            },
        },
    },
})(TextField);
