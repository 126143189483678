import {
    Box,
    CircularProgress,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText, Tooltip
} from '@material-ui/core';
import DeleteIcon from "@material-ui/icons/Delete";
import GetAppIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import moment from 'moment';
import React, {useState} from 'react';
import {confirmService} from '../../../../services/confirm.service';
import {PermissionType} from '../../../auth/models/auth.enums';
import {ProtectedElement} from '../../../common/components/ProtectedElement';
import {noteDeleteReasons, NoteDeletionReason, NoteModel} from '../../models/notes.models';
import {notesService} from '../../services/notes.service';
import {
    GetCurrentDraft,
    GetMaximumVersionOfNote
} from '../../helpers/noteHelpers';
import InfoIcon from "@material-ui/icons/Info";
import {useStyles} from "./manageNoteCardComponent.styles";

interface ManageNoteCardComponentProps {
    patientId: number;
    keyIndex?: string;
    note: NoteModel;
    handleGoToNote: (note: NoteModel) => void;
    handleDownloadNotePdf: (note: NoteModel) => void;
    downloadingNoteId: Number | null;
    handleGoToEditNote: (note: NoteModel) => void;
}

export const ManageNoteCardComponent: React.FC<ManageNoteCardComponentProps> = (props: ManageNoteCardComponentProps) => {
    const {
        patientId,
        keyIndex,
        note,
        handleGoToNote,
        handleDownloadNotePdf,
        downloadingNoteId,
        handleGoToEditNote
    } = props;

    const [isDeleting, setIsDeleting] = useState(false);

    const classes = useStyles();

    const customTooltipClasses = {
        tooltip: classes.customWidth,
        arrow: classes.arrow,
        tooltipPlacementBottom: classes.tooltipPlacement,
        tooltipPlacementTop: classes.tooltipPlacement,
    };

    const handleNoteDelete = (note: NoteModel) => {
        if (!note.isCompleted) {
            confirmService.confirm('Are you sure? This action can not be undone').subscribe(() => {
                setIsDeleting(() => true);
                notesService.delete(note, NoteDeletionReason.MissingContent).subscribe(
                    () => {
                        notesService.getByEmployee(patientId).subscribe(() => {
                            setIsDeleting(() => false);
                        });
                    },
                    () => {
                        setIsDeleting(() => false);
                    });
            });
        } else {
            confirmService.confirmAndSelectedNoteViewType(
                'Delete Note',
                noteDeleteReasons,
                'Are you sure you want to delete this note? This action cannot be undone.',
                'Delete'
            ).subscribe(
                (type) => {
                    setIsDeleting(() => true);
                    notesService.delete(note, type).subscribe(
                        () => {
                            notesService.getByEmployee(patientId).subscribe(() => {
                                setIsDeleting(() => false);
                            });
                        },
                        () => {
                            setIsDeleting(() => false);
                        });
                },
                () => { }
            );
        }
    }

    const handleEditNote = (note: NoteModel) => {
        confirmService.confirm('Are you sure?', 'Are you sure you wish to amend this note? All changes will be logged.')
            .subscribe(() => {
                handleGoToEditNote(note);
            });
    }

    const renderSpinner = () => {
        return (
            <Box display="flex" justifyContent="flex-end" ml={1.5}>
                <CircularProgress size={24} className="wh-tw-text-main"/>
            </Box>
        )
    }

    const showDownloadButton = () => {
        if (downloadingNoteId === note.id) {
            return renderSpinner();
        }

        return (
            <>
                <IconButton edge="end" onClick={() => handleEditNote(note)}>
                    <EditIcon/>
                </IconButton>
                <IconButton edge="end" onClick={() => {
                    handleDownloadNotePdf(note)
                }}>
                    <GetAppIcon/>
                </IconButton>
            </>
        );
    }

    let title = GetMaximumVersionOfNote(note)?.title;

    const currentDraftNote = GetCurrentDraft(note);

    const renderActionButtons = () => {
        return (
            <Box display="flex" alignItems="center" gridGap={5}>
                {
                    note.isCompleted && showDownloadButton()
                }
                <ProtectedElement
                    element={
                        isDeleting
                            ? renderSpinner()
                            : <IconButton id="managed-notes-card-delete" edge="end" onClick={() => handleNoteDelete(note)}>
                                <DeleteIcon/>
                            </IconButton>
                    }
                    permissions={[PermissionType.VisitNoteCreation]}
                />
            </Box>
        )
    }

    const renderSecondaryText = () => {
        if (currentDraftNote.assignedAt && currentDraftNote.completedAt === null && !!currentDraftNote.assignedTo) {
            return <Box pr='50px'>
                {moment(note.visitDate).format("ll")}
                <Box maxWidth='max-content' className={classes.reviewedBy}>
                    Under Review by {currentDraftNote.assignedTo?.firstName} {currentDraftNote.assignedTo?.lastName}
                </Box>
            </Box>
        }

        if (note.deletedBy) {
            return <Box maxWidth='max-content' display="flex" alignItems="center">
                <Box>
                    Deleted by {currentDraftNote.deletedBy?.firstName} {currentDraftNote.deletedBy?.lastName} on {moment(note.deletedAt).format('ll')} at {moment(note.deletedAt).format('LT')}
                </Box>
                <Box ml={1}>
                    <Tooltip placement="bottom" arrow title={<Box>{noteDeleteReasons.get(note.deletionReason)}</Box>}
                             classes={customTooltipClasses}
                    >
                        <InfoIcon className="wh-tw-w-5 wh-tw-h-5 wh-tw-text-gray2"/>
                    </Tooltip>
                </Box>
            </Box>
        }

        return <Box>{moment(note.visitDate).format("ll")}</Box>
    }

    return (
        <ListItem
            id="managed-notes-card-to-note"
            button
            key={keyIndex}
            onClick={() => handleGoToNote(note)}
        >
            <ListItemIcon>
                <SpeakerNotesIcon/>
            </ListItemIcon>
            <ListItemText
                primary={<Box pr='50px'>{`${note.name} ${note.isCompleted ? '' : '(draft)'} ${title ? ' - ' + title : ''}`}</Box>}
                secondary={renderSecondaryText()}
            />
            <ListItemSecondaryAction>
                {
                    !note.deletedBy && renderActionButtons()
                }
            </ListItemSecondaryAction>
        </ListItem>
    );
}
