import { Store, StoreConfig } from '@datorama/akita';
import { ScheduledMessageModel, UploadedAttachmentModel } from '../../models/message.models';

/**
 * Represents Scheduled Messages state
 */
export interface ScheduledMessagesState {
    editingTarget: ScheduledMessageModel;
    scheduledMessages: ScheduledMessageModel[];
    uploadedAttachments: UploadedAttachmentModel[];
}

/**
 * Creates initial state
 */
export function createInitialState(): ScheduledMessagesState {
    return {
        editingTarget: null,
        scheduledMessages: [],
        uploadedAttachments: [],
    };
}

/**
 * Provides Scheduled Messages states management
 */
@StoreConfig({ name: 'scheduledMessages', resettable: true })
export class ScheduledMessagesStore extends Store<ScheduledMessagesState> {
    constructor() {
        super(createInitialState());
    }

    startEditing(message: ScheduledMessageModel) {
        this.update({ editingTarget: message, uploadedAttachments: message.uploadedAttachments });
    }

    endEditing() {
        this.update({ editingTarget: null, uploadedAttachments: [] });
    }

    setScheduledMessages(message: ScheduledMessageModel[]) {
        this.update({ scheduledMessages: message });
    }

    addScheduledMessage(message: ScheduledMessageModel) {
        const scheduledMessages = this.getValue().scheduledMessages;
        this.update({ scheduledMessages: [...scheduledMessages, message] });
    }

    updateScheduledMessage(message: ScheduledMessageModel, newAttachments: UploadedAttachmentModel[]) {
        const messageToSchedule = message;
        if (newAttachments && newAttachments.length) {
            messageToSchedule.uploadedAttachments = [...messageToSchedule.uploadedAttachments, ...newAttachments]
        }
        const scheduledMessages = this.getValue().scheduledMessages.map(i => i.id === message.id ? messageToSchedule : i);     
        this.update({ scheduledMessages: scheduledMessages });
    }

    deleteScheduledMessage(id: number) {
        const scheduledMessages = this.getValue().scheduledMessages.filter(i => i.id !== id);
        this.update({ scheduledMessages: scheduledMessages });
    }

    setUploadedAttachments(attachments: UploadedAttachmentModel[]) {
        this.update({ uploadedAttachments: attachments });
    }
}

export const scheduledMessagesStore = new ScheduledMessagesStore();