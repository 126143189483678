import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { colors } from "../../../common/constants/colors";

const stepperBackground = '#fdfdfd';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: colors.white,
            display: 'flex',
            minHeight: '100vh',
        },
        logo: {
            width: 160,
            maxHeight: 50,
        },
        sidebar: {
            height: '100vh',
            position: 'sticky',
            top: 0,
            width: '20%',
            backgroundColor: stepperBackground,
            borderRight: `1px solid ${colors.gray3}`
        },
        stepper: {
            backgroundColor: stepperBackground,
        },
        content: {
            width: '100%',
            fontSize: 16,
            fontWeight: 400,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.57,
            letterSpacing: 0.53
        }
    }),
);