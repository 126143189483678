import { useEffect, useState } from 'react';
import { Subscription } from 'recompose';
import { onEmit } from '../../../common/helpers/on-emit';
import { handleCompare } from "../../../common/sorting/helpers/handle-compare";
import { SortingDirection } from "../../../common/sorting/models/sorting-destination";
import { ISortingState } from "../../../common/sorting/models/sorting-state";
import { LocationModel } from '../../models/locations.models';
import { locationsService } from '../../services/locations.service';
import { locationsQuery } from '../../stores';
import { authQuery } from "../../../auth/stores/auth";

export enum SortingSource {
    none,
    name = 'name',
    city = 'city',
    state = 'state',
}

interface ManageLocationsComponentState extends ISortingState {
    isLoading: boolean;
    locations: LocationModel[],
    targetLocation: LocationModel;
    isActionsOpen: boolean;
    actionsAnchorEl: null | HTMLElement;
    isCreating: boolean;
}

export function useFacade(): [
    ManageLocationsComponentState,
    (value: boolean, location: LocationModel, target: HTMLButtonElement) => void,
    (source: string, direction: SortingDirection) => void,
    () => void,
    () => void
] {

    const [state, setState] = useState({
        isLoading: true,
        locations: [],
        targetLocation: null,
        isActionsOpen: false,
        actionsAnchorEl: null,
        sortingColumns: [
            { title: 'Pod Name', source: SortingSource.name, direction: SortingDirection.Asc },
            { title: 'City', source: SortingSource.city, direction: SortingDirection.Asc },
            { title: 'State', source: SortingSource.state, direction: SortingDirection.Asc },
        ],
        sortingSource: SortingSource.none,
        isCreating: false
    } as ManageLocationsComponentState);

    const handleToggleActions = (value: boolean, location: LocationModel, target: HTMLButtonElement) => {
        setState(state => ({
            ...state,
            isActionsOpen: value,
            targetLocation: location,
            actionsAnchorEl: target
        }))
    }

    const handleCreate = () => {
        setState(state => ({ ...state, isCreating: true }));

        const cb = () => {
            setState(state => ({
                ...state,
                isCreating: false,
            }));
        }
        locationsService.createLocation().subscribe(model => {
            locationsService.create(model).subscribe(cb, cb);
        }, cb)
    }

    const handleEdit = () => {
        locationsService.updateLocation(state.targetLocation).subscribe(model => {
            locationsService.update(model).subscribe();
        });

        setState(state => ({
            ...state,
            isActionsOpen: false,
            targetLocation: null,
            actionsAnchorEl: null
        }));
    }

    const setDirection = (source: string, direction: SortingDirection) => {
        let itemIndex = state.sortingColumns.findIndex(item => item.source === source);
        state.sortingColumns[itemIndex].direction = direction;
        setState(state => ({ ...state, columns: state.sortingColumns }));
    }

    const handleSorting = (source: string, direction: SortingDirection) => {
        if (state.sortingSource === source) {
            direction = direction === SortingDirection.Asc
                ? SortingDirection.Desc
                : SortingDirection.Asc;

            setDirection(source, direction);
        }

        setState(state => ({
            ...state,
            sortingSource: source,
            locations: state.locations.sort((l1, l2) => handleCompare(l1, l2, direction, source))
        }));
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<LocationModel[]>(locationsQuery.availableLocations$, locations => {
                setState(state => ({
                    ...state,
                    locations: locations
                }))
            }),
        ];

        locationsService.getAvailable(authQuery.getCurrentPracticeId())
            .subscribe(() => setState(state => ({
                ...state,
                isLoading: false
            })));

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [
        state,
        handleToggleActions,
        handleSorting,
        handleCreate,
        handleEdit
    ];
}