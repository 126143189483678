import { Box, Paper, TextField } from "@material-ui/core";
import React from 'react';
import { accountService } from '../../../../services/account.service';
import { useFacade } from "./verifyProfileComponent.hooks";
import { logoService } from "../../../../services/logo.service";
import { useStyles } from '../../pages/loginPage/loginPage.styles';
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";

interface VerifyProfileComponentProps {
    handleVerificationSubmit: () => void
}

export const VerifyProfileComponent: React.FC<VerifyProfileComponentProps> = (props: VerifyProfileComponentProps) => {
    const [
        {
            model,
            inProgress,
            errors
        },
        renderRecaptcha,
        handleChanges,
        handleSubmit,
    ] = useFacade(props.handleVerificationSubmit);
    const classes = useStyles();

    return (
        <Paper variant="outlined" square className={classes.loginForm}>
            <Box display="flex" justifyContent="center">
                <Box height="75px">
                    <img className={classes.logo} src={logoService.getLogo().primary}
                        alt="logo" />
                </Box>
            </Box>
            <Box mt={2} textAlign="center">
                <span
                    className={classes.text}>A verification code has been sent via text to the number you provided.</span>
                <br />
                <span className={classes.text}>Please enter that code below.</span> <br />
            </Box>
            <Box mt={3}>
                <form>
                    <TextField
                        onChange={(e) => handleChanges(e.target.id, e.target.value)}
                        disabled={inProgress}
                        helperText={errors['code']}
                        error={!!errors['code']}
                        value={model.code}
                        variant="outlined"
                        label="SMS code"
                        InputProps={{ className: 'input' }}
                        margin="normal"
                        size="small"
                        type="text"
                        id='code'
                        fullWidth
                        required
                    />
                    <Box display="flex" mt={5}>
                        <Box width={0.5} mr={1}>
                            <WildHealthButton
                                id="verify-profile"
                                fullWidth
                                loading={inProgress}
                                onClick={(e) => handleSubmit(e)}
                            >
                                Verify
                            </WildHealthButton>
                        </Box>
                        <Box width={0.5} ml={1}>
                            <WildHealthButton
                                id="verify-profile-back"
                                fullWidth
                                disabled={inProgress}
                                onClick={() => accountService.logout()}
                            >
                                Back
                            </WildHealthButton>
                        </Box>
                    </Box>
                    {renderRecaptcha()}
                </form>
            </Box>
        </Paper>
    );
}
