import React, { useRef } from 'react';
import moment from 'moment';
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    Drawer,
    IconButton,
    Divider
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { ReactComponent as EditIcon } from '@img/icons/Edit1.svg';
import { useStyles, BiomarkerSlider } from "./LabInputsSectionComponent.styles";
import { useFacade } from "./labInputsSectionComponent.hooks";
import {
    LabInputModel,
    LabInputsDatasetEditableViewModel,
    LabInputValueModel,
    LabInputNotificationType
} from "../../models/input.models";
import { WildHealthTableRow } from '../../../common/components/wildHealthTable/WildHealthTableRow';
import { WildHealthTableCell } from '../../../common/components/wildHealthTable/WildHealthTableCell';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { LabInputValueComponent } from "../labInputValueComponent/LabInputValueComponent";
import { CommonPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/CommonPlaceHolder";
import { renderLabInputRange, getMaxValueByRange } from "../../helpers/render-lab-input-range";
import clsx from "clsx";
import { renderLabResultValuesGraph } from '../../helpers/render-lab-result-values-graph';
import { LabInputRangeComponent } from '../LabInputRangeComponent/LabInputRangeComponent';
import { getDataSetByLabLatestValue, getRange } from '../../helpers/labInputs.extensions';

interface LabInputsSectionComponentProps {
    isHightlighted?: boolean;
    showUntestedValue?: boolean;
    inputs: LabInputModel[];
    patientId: number;
    dataSets: LabInputsDatasetEditableViewModel[];
    hiddenDataSets: string[];
    getCurrentDataSet: Function;
    handleEditLab: Function;
    handleHighlightLab: (id: number, status: boolean) => void;
}

export const LabInputsSectionComponent: React.FC<LabInputsSectionComponentProps> = (props: LabInputsSectionComponentProps) => {
    const {
        isHightlighted = false,
        showUntestedValue = true,
        inputs,
        dataSets,
        hiddenDataSets,
        handleEditLab,
        handleHighlightLab
    } = props;

    const [
        {
            isSideAreaOpen,
            selectedLabInputId
        },
        handleToggleDescription
    ] = useFacade(inputs);

    const classes = useStyles();

    const paperContainerRef = useRef(null);


    if (!dataSets) {
        return (<WildHealthLinearProgress />);
    }

    const getDataSetValue = (dataSet: LabInputsDatasetEditableViewModel, inputName: string): LabInputValueModel => {
        return dataSet.values.find(x => x.name === inputName)
    }

    const getRecentValue = (input: LabInputModel, dataSetId: string) => {
        const inputValue = input.values.find(x => x.dataSetId === dataSetId);
        if (inputValue === undefined || !inputValue.isInitialized) {
            return { isError: false, value: 0 };
        }

        const notification = inputValue.notification;

        if (notification?.notificationType === LabInputNotificationType.Error) {
            return { isError: true, value: inputValue.value };
        }
        return { isError: false, value: inputValue.value };
    }

    const renderValue = (input: LabInputModel, dataset: LabInputsDatasetEditableViewModel): JSX.Element => {
        const inputValue = getDataSetValue(dataset, input.name);

        return (
            <LabInputValueComponent
                inputId={inputValue.id}
                value={inputValue}
            />
        );
    }

    const renderSideArea = () => {
        const targetLabInput = inputs.find(x => x.id === selectedLabInputId);
        if (targetLabInput) {
            const dataSetByLatestValue = getDataSetByLabLatestValue(dataSets, targetLabInput);
            const range = getRange(targetLabInput);
            return (
                <Box className={clsx('flat-scroll', classes.sideContainer)} display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="space-between" pb={2}>
                        <Box display="flex" alignItems="center" gridGap={8} className="wh-tw-cursor-pointer" onClick={() => handleToggleDescription(0, false)}>
                            <ChevronLeftIcon className="wh-tw-text-gray900" />
                            <Box className="wh-tw-font-medium wh-tw-text-xl wh-tw-text-gray600 wh-tw-font-poppins">Labs</Box>
                        </Box>
                        <IconButton
                            id={`task-${targetLabInput.id}`}
                            size="small"
                            onClick={() => handleToggleDescription(0, false)}
                        >
                            <CloseIcon className="wh-tw-text-gray900" />
                        </IconButton>
                    </Box>
                    <Box pt={3}>
                        <Box className="wh-tw-font-medium wh-tw-text-lg wh-tw-font-beVietnamPro">{targetLabInput.displayName}</Box>
                        {targetLabInput.description && <Box mt={1.5} className="wh-tw-text-sm wh-tw-text-gray600 wh-tw-font-beVietnamPro">{targetLabInput.description}</Box>}
                        {range && <Box mt={1.5} className="wh-tw-text-sm wh-tw-text-gray600 wh-tw-font-medium wh-tw-font-beVietnamPro">In Range {renderLabInputRange(range)}</Box>}
                    </Box>
                    <Box pt={3}>
                        <Box className="wh-tw-font-medium wh-tw-text-lg wh-tw-font-beVietnamPro">Most Recent Value</Box>
                        {range && (
                            <Box mt={1.5}>
                                <BiomarkerSlider
                                    aria-label="biomarker slider"
                                    defaultValue={getRecentValue(targetLabInput, dataSetByLatestValue.id).value}
                                    track={false}
                                    disabled
                                    isDanger={getRecentValue(targetLabInput, dataSetByLatestValue.id).isError}
                                    min={0}
                                    max={getMaxValueByRange(range) * 2}
                                    isRevert={getRecentValue(targetLabInput, dataSetByLatestValue.id).value > getMaxValueByRange(range)}
                                    // isRevert={getRecentValue(targetLabInput, dataSetByLatestValue.id).value > getMaxValueByRange(range) || (getRecentValue(targetLabInput, dataSetByLatestValue.id).value >= getMinValueByRange(range) && getRecentValue(targetLabInput, dataSetByLatestValue.id).value <= getMaxValueByRange(range))}
                                />
                            </Box>
                        )}
                        <Box display="flex" justifyContent="space-between" mt={1.5}>
                            <Box>Range {range ? renderLabInputRange(range) : ''}</Box>
                            <Box className="wh-tw-text-sm wh-tw-text-gray900 wh-tw-font-beVietnamPro">{renderValue(targetLabInput, dataSetByLatestValue)}</Box>
                        </Box>
                    </Box>
                    {targetLabInput.values.length > 1 && (
                        <Box pt={3}>
                            <Box className="wh-tw-font-medium wh-tw-text-lg wh-tw-font-beVietnamPro">Past Values</Box>
                            <Box mt={1.5} display="flex" justifyContent="center">
                                {
                                    renderLabResultValuesGraph(targetLabInput.values)
                                }
                            </Box>
                            {
                                dataSets.map((dataSet) => {
                                    const inputValue = targetLabInput.values.find(x => x.dataSetId === dataSet.id);
                                    if (inputValue === undefined || !inputValue.isInitialized) {
                                        return;
                                    }
                                    return (
                                        <React.Fragment  key={dataSet.id}>
                                            <Box py={1.5} key={dataSet.id} display="flex" justifyContent="space-between">
                                                <Box className="wh-tw-text-black wh-tw-text-sm wh-tw-font-beVietnamPro">{moment(dataSet.date).format("MM/DD/YYYY")}</Box>
                                                <Box className="wh-tw-text-sm wh-tw-text-gray900 wh-tw-font-beVietnamPro">{renderValue(targetLabInput, dataSet)}</Box>
                                            </Box>
                                            <Divider />
                                        </React.Fragment>
                                    );
                                })
                            }
                        </Box>
                    )}
                    <Box pt={3}>
                        <Box mt={1.5} className="wh-tw-text-sm wh-tw-text-gray900 wh-tw-font-beVietnamPro">If you have any questions about these values, schedule a call with your provider to discuss them!</Box>
                    </Box>
                </Box>
            )
        }

        return <></>
    }

    const pxString = (value: number): string => `${value}px`;

    const renderValuesView = () => {
        if (!dataSets.length) {
            return (
                <Box p={2}>
                    <CommonPlaceHolder message="No results" />
                </Box>
            );
        }

        const dataLength = dataSets.filter(x => x.selected).length;
        const tableContainerWidth = paperContainerRef.current ? paperContainerRef.current.offsetWidth : 0;
        const nameWidth = 200;
        const highlightWidth = 124;
        const rangeWidth = 200;
        const valueWidth = 120;
        const tableWidth = dataLength * valueWidth + nameWidth + highlightWidth + rangeWidth;

        const isScrollable = tableWidth > tableContainerWidth;

        if (isHightlighted) {
            return (
                <TableContainer className={classes.root}>
                    <Table style={{ minWidth: pxString(valueWidth + nameWidth * 2 + highlightWidth + rangeWidth) }}>
                        <TableHead>
                            <WildHealthTableRow>
                                <WildHealthTableCell
                                    style={{ minWidth: pxString(nameWidth) }}
                                    className={clsx(classes.stickyLeft, { [classes.shadowRight]: isScrollable })}>
                                    <Box className="wh-tw-text-black wh-tw-text-sm wh-tw-font-semibold wh-tw-font-beVietnamPro">Biomarker</Box>
                                </WildHealthTableCell>
                                <WildHealthTableCell
                                    style={{ minWidth: pxString(highlightWidth) }}>
                                    <Box className="wh-tw-text-black wh-tw-text-sm wh-tw-font-semibold wh-tw-font-beVietnamPro">Highlight</Box>
                                </WildHealthTableCell>
                                <WildHealthTableCell
                                    style={{ minWidth: pxString(rangeWidth) }}>
                                    <Box className="wh-tw-text-black wh-tw-text-sm wh-tw-font-semibold wh-tw-font-beVietnamPro">Range</Box>
                                </WildHealthTableCell>
                                <WildHealthTableCell style={{ minWidth: pxString(nameWidth) }} align="center">
                                    <Box display="flex" alignItems="center" justifyContent="center" className="wh-tw-text-black wh-tw-text-sm wh-tw-font-semibold wh-tw-font-beVietnamPro"> Recent Result </Box>
                                </WildHealthTableCell>
                                <WildHealthTableCell style={{ minWidth: pxString(nameWidth) }} />
                                <WildHealthTableCell style={{ width: '100%' }}>
                                    <Box className="wh-tw-text-black wh-tw-text-sm wh-tw-font-semibold wh-tw-font-beVietnamPro">Date</Box>
                                </WildHealthTableCell>
                            </WildHealthTableRow>
                        </TableHead>
                        <TableBody>
                            {
                                inputs.map((input, index) => {
                                    const dataSetByLatestValue = getDataSetByLabLatestValue(dataSets, input);
                                    const range = getRange(input);
                                    return (
                                        <WildHealthTableRow key={index}>
                                            <WildHealthTableCell className={clsx(classes.stickyLeft, "wh-tw-cursor-pointer", { [classes.shadowRight]: isScrollable })} onClick={() => handleToggleDescription(input.id, true)}>
                                                <span>{input.displayName}</span>
                                            </WildHealthTableCell>
                                            <WildHealthTableCell>
                                                <IconButton id="lab-input-highlight" onClick={() => handleHighlightLab(input.id, !input.isHighlighted)}>
                                                    {
                                                        input.isHighlighted ? <CheckCircleIcon className="wh-tw-text-primaryV" /> : <RadioButtonUncheckedIcon className="wh-tw-text-primaryV" />
                                                    }
                                                </IconButton>
                                            </WildHealthTableCell>
                                            <WildHealthTableCell>
                                                {
                                                    range ? <LabInputRangeComponent range={range} /> : ''
                                                }
                                            </WildHealthTableCell>
                                            <WildHealthTableCell align="center">
                                                {renderValue(input, dataSetByLatestValue)}
                                            </WildHealthTableCell>
                                            <WildHealthTableCell />
                                            <WildHealthTableCell>
                                                {
                                                    moment(dataSetByLatestValue.date).format("MM/DD/YYYY")
                                                }
                                            </WildHealthTableCell>
                                        </WildHealthTableRow>
                                    );
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            )
        }

        return (<>
            <TableContainer className={classes.root}>
                <Table style={{ minWidth: pxString(tableWidth) }}>
                    <TableHead>
                        <WildHealthTableRow>
                            <WildHealthTableCell
                                style={{ minWidth: pxString(nameWidth), width: pxString(nameWidth) }}
                                className={clsx(classes.stickyLeft, { [classes.shadowRight]: isScrollable })}>
                                <Box className="wh-tw-text-black wh-tw-text-sm wh-tw-font-semibold wh-tw-font-beVietnamPro">Biomarker</Box>
                            </WildHealthTableCell>
                            <WildHealthTableCell
                                style={{ minWidth: pxString(highlightWidth), width: pxString(highlightWidth) }}>
                                <Box className="wh-tw-text-black wh-tw-text-sm wh-tw-font-semibold wh-tw-font-beVietnamPro">Highlight</Box>
                            </WildHealthTableCell>
                            <WildHealthTableCell
                                style={{ minWidth: pxString(rangeWidth), width: pxString(rangeWidth) }}>
                                <Box className="wh-tw-text-black wh-tw-text-sm wh-tw-font-semibold wh-tw-font-beVietnamPro">Range</Box>
                            </WildHealthTableCell>
                            {
                                dataSets.filter(x => !hiddenDataSets.includes(x.id)).map((dataset, index) => {
                                    return (
                                        <WildHealthTableCell key={index} style={{ minWidth: pxString(valueWidth) }} align="center">
                                            <Box display="flex" alignItems="center" justifyContent="center" className="wh-tw-text-black wh-tw-text-sm wh-tw-font-semibold wh-tw-font-beVietnamPro">
                                                {
                                                    moment(dataset.date).format("MM/DD/YYYY")
                                                }
                                                <Box ml={1} onClick={() => inputs.length > 0 && handleEditLab(dataset.date, inputs[0].group)}>
                                                    <EditIcon className="wh-tw-cursor-pointer wh-tw-fill-gray600" fontSize='small' />
                                                </Box>
                                            </Box>
                                        </WildHealthTableCell>
                                    )
                                })
                            }
                        </WildHealthTableRow>
                    </TableHead>
                    <TableBody>
                        {
                            inputs.map((input, index) => {
                                if (!showUntestedValue && !input.values.length) {
                                    return <></>
                                }
                                const range = getRange(input);
                                return (
                                    <WildHealthTableRow key={index}>
                                        <WildHealthTableCell className={clsx(classes.stickyLeft, "wh-tw-cursor-pointer", { [classes.shadowRight]: isScrollable })} onClick={() => handleToggleDescription(input.id, true)}>
                                            <span>{input.displayName}</span>
                                        </WildHealthTableCell>
                                        <WildHealthTableCell>
                                            <IconButton id="lab-input-highlight" onClick={() => handleHighlightLab(input.id, !input.isHighlighted)}>
                                                {
                                                    input.isHighlighted ? <CheckCircleIcon className="wh-tw-text-primaryV" /> : <RadioButtonUncheckedIcon className="wh-tw-text-primaryV" />
                                                }
                                            </IconButton>
                                        </WildHealthTableCell>
                                        <WildHealthTableCell>
                                            {
                                                range ? <LabInputRangeComponent range={range} /> : ''
                                            }
                                        </WildHealthTableCell>
                                        {
                                            dataSets.filter(x => !hiddenDataSets.includes(x.id)).map((dataset, index) => {
                                                return (
                                                    <WildHealthTableCell key={index} align="center">
                                                        {renderValue(input, dataset)}
                                                    </WildHealthTableCell>
                                                );
                                            })
                                        }
                                    </WildHealthTableRow>
                                );
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>);
    }

    return (<>
        <Paper ref={paperContainerRef}>
            {
                renderValuesView()
            }
        </Paper>
        <Drawer
            id="side-area-drawer"
            anchor='right'
            open={isSideAreaOpen}
            onClose={() => handleToggleDescription(0, false)}
        >
            <Box className={classes.sideMobile}>
                {renderSideArea()}
            </Box>
        </Drawer>
    </>)
}