import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        selectedUser: {
            background: colors.stroke,
            display: 'flex',
            alignItems: 'center',
            margin: 5,
            padding: 5,
            maxWidth: 130,
            height: 30,

            "&:hover": {
                "& $removeSelectedUser": {
                    visibility: "visible",
                },
            },
        },
        selectedUserTitle: {
            fontSize: 16,
            fontWeight: 400,
            color: colors.black,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        removeSelectedUser: {
            visibility: 'hidden',
        },
        checkIcon: {
            color: colors.main,
        },
    })
);