import React from 'react';
import {Box, Table, TableBody, TableContainer, TableHead, TableRow} from '@material-ui/core';
import {ExercisePlanModel} from "../../models/exercisePlan.models";
import { ExerciseReportTableCell } from '../exerciseReportTable/ExerciseReportTableCell';

interface ExercisePlanComponentProps {
    plan: ExercisePlanModel;
}

export const ExercisePlanScheduleComponent: React.FC<ExercisePlanComponentProps> = (props: ExercisePlanComponentProps) => {
    const {plan} = props;

    return(
        <>
            <Box width="100%">
                <TableContainer>
                    <Table size="small" style={{border:"2px solid #A9A9A9"}}>
                        <TableHead>
                            <TableRow>
                                <ExerciseReportTableCell>Week</ExerciseReportTableCell>
                                <ExerciseReportTableCell>Workout</ExerciseReportTableCell>
                                <ExerciseReportTableCell>Monday</ExerciseReportTableCell>
                                <ExerciseReportTableCell>Tuesday</ExerciseReportTableCell>
                                <ExerciseReportTableCell>Wednesday</ExerciseReportTableCell>
                                <ExerciseReportTableCell>Thursday</ExerciseReportTableCell>
                                <ExerciseReportTableCell>Friday</ExerciseReportTableCell>
                                <ExerciseReportTableCell>Saturday</ExerciseReportTableCell>
                                <ExerciseReportTableCell>Sunday</ExerciseReportTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {plan.schedule.map((week, x) => {
                                return week.exercises.map((weekExercise, y) => {
                                    return <TableRow key={`${x}${y}`}> 
                                        {y % 2 === 0 && <ExerciseReportTableCell rowSpan={2} align="center" style={x % 2 === 1 ? {width: '4%', backgroundColor:"#f3f7fa"}:{width: '4%'}}>{week.number}</ExerciseReportTableCell>}
                                        <ExerciseReportTableCell style={x % 2 === 1 ? {backgroundColor:"#f3f7fa", width: '12%'}:{width: '12%'}}>{weekExercise.exercise}</ExerciseReportTableCell>
                                        <ExerciseReportTableCell style={x % 2 === 1 ? {backgroundColor:"#f3f7fa", width: '12%'}:{width: '12%'}}>{weekExercise.monday}</ExerciseReportTableCell>
                                        <ExerciseReportTableCell style={x % 2 === 1 ? {backgroundColor:"#f3f7fa", width: '12%'}:{width: '12%'}}>{weekExercise.tuesday}</ExerciseReportTableCell>
                                        <ExerciseReportTableCell style={x % 2 === 1 ? {backgroundColor:"#f3f7fa", width: '12%'}:{width: '12%'}}>{weekExercise.wednesday}</ExerciseReportTableCell>
                                        <ExerciseReportTableCell style={x % 2 === 1 ? {backgroundColor:"#f3f7fa", width: '12%'}:{width: '12%'}}>{weekExercise.thursday}</ExerciseReportTableCell>
                                        <ExerciseReportTableCell style={x % 2 === 1 ? {backgroundColor:"#f3f7fa", width: '12%'}:{width: '12%'}}>{weekExercise.friday}</ExerciseReportTableCell>
                                        <ExerciseReportTableCell style={x % 2 === 1 ? {backgroundColor:"#f3f7fa", width: '12%'}:{width: '12%'}}>{weekExercise.saturday}</ExerciseReportTableCell>
                                        <ExerciseReportTableCell style={x % 2 === 1 ? {backgroundColor:"#f3f7fa", width: '12%'}:{width: '12%'}}>{weekExercise.sunday}</ExerciseReportTableCell>
                                    </TableRow>
                                })
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <span style={{float: "right", fontSize: "14px", fontWeight: 450, color: 'gray'}}>{plan.name}</span>
            </Box>
        </>
    );
}

export const ExercisePlanScheduleMobileComponent: React.FC<ExercisePlanComponentProps> = (props: ExercisePlanComponentProps) => {
    const {plan} = props;

    return(
        <>
            <Box width="100%" style={{ borderTop: "2px solid #A9A9A9",  borderBottom: "2px solid #A9A9A9" }}>
                {plan.schedule.map((week, x) => {
                    return week.exercises.map((weekExercise, y) => {
                        return <Box key={`${x}${y}`} style={y % 2 === 1 ? {borderTop: "1px solid #A9A9A9"} : {}}>
                            <Box width={1} px={2} pt={2} display="flex" className="size-0_875 normal-line-height" style={x % 2 === 1 ? {backgroundColor:"#f3f7fa"} : {}}>
                                <Box width={0.4} className="text-medium">Week</Box>
                                <Box width={0.6} className="text-light-medium">{week.number}</Box>
                            </Box>
                            <Box width={1} px={2} pt="10px" display="flex" className="size-0_875 normal-line-height" style={x % 2 === 1 ? {backgroundColor:"#f3f7fa"} : {}}>
                                <Box width={0.4} className="text-medium">Workout</Box>
                                <Box width={0.6} className="text-light-medium">{weekExercise.exercise}</Box>
                            </Box>
                            <Box width={1} px={2} pt="10px" display="flex" className="size-0_875 normal-line-height" style={x % 2 === 1 ? {backgroundColor:"#f3f7fa"} : {}}>
                                <Box width={0.4} className="text-medium">Monday</Box>
                                <Box width={0.6} className="text-light-medium">{weekExercise.monday}</Box>
                            </Box>
                            <Box width={1} px={2} pt="10px" display="flex" className="size-0_875 normal-line-height" style={x % 2 === 1 ? {backgroundColor:"#f3f7fa"} : {}}>
                                <Box width={0.4} className="text-medium">Tuesday</Box>
                                <Box width={0.6} className="text-light-medium">{weekExercise.tuesday}</Box>
                            </Box>
                            <Box width={1} px={2} pt="10px" display="flex" className="size-0_875 normal-line-height" style={x % 2 === 1 ? {backgroundColor:"#f3f7fa"} : {}}>
                                <Box width={0.4} className="text-medium">Wednesday</Box>
                                <Box width={0.6} className="text-light-medium">{weekExercise.wednesday}</Box>
                            </Box>
                            <Box width={1} px={2} pt="10px" display="flex" className="size-0_875 normal-line-height" style={x % 2 === 1 ? {backgroundColor:"#f3f7fa"} : {}}>
                                <Box width={0.4} className="text-medium">Thursday</Box>
                                <Box width={0.6} className="text-light-medium">{weekExercise.thursday}</Box>
                            </Box>
                            <Box width={1} px={2} pt="10px" display="flex" className="size-0_875 normal-line-height" style={x % 2 === 1 ? {backgroundColor:"#f3f7fa"} : {}}>
                                <Box width={0.4} className="text-medium">Friday</Box>
                                <Box width={0.6} className="text-light-medium">{weekExercise.friday}</Box>
                            </Box>
                            <Box width={1} px={2} pt="10px" display="flex" className="size-0_875 normal-line-height" style={x % 2 === 1 ? {backgroundColor:"#f3f7fa"} : {}}>
                                <Box width={0.4} className="text-medium">Saturday</Box>
                                <Box width={0.6} className="text-light-medium">{weekExercise.saturday}</Box>
                            </Box>
                            <Box width={1} px={2} pt="10px" pb={2} display="flex" className="size-0_875 normal-line-height" style={x % 2 === 1 ? {backgroundColor:"#f3f7fa"} : {}}>
                                <Box width={0.4} className="text-medium">Sunday</Box>
                                <Box width={0.6} className="text-light-medium">{weekExercise.sunday}</Box>
                            </Box>
                        </Box>
                    })
                })}
            </Box>
        </>
    );
}
