import {useEffect, useState} from "react";
import {AddOnModel, AddOnProvider} from "../../../addons/models/addOns.models";
import {ColumnViewModel} from "../../../common/components/wildHealthTable/WildHealthTableCell";
import {pricingService} from "../../services/pricing.service";
import {IPaginationState} from "../../../common/pagination/models/pagination-state";
import {getAvailablePages} from "../../../common/pagination/helpers/get-evailable-pages";
import {addOnsService} from "../../../addons/services/addOns.service";
import {authQuery} from "../../../auth/stores/auth";
import {Gender} from "../../../common/models/user.models";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

interface LabOrderPlacedComponentState extends IPaginationState {
    isOpen: boolean;
    isLoading: boolean;
    provider: AddOnProvider,
    gender: Gender,
    search: string,
    filteredItems: AddOnModel[];
    selectedItems: AddOnModel[];
    totalPrice: number;
    menuAnchorEl: HTMLElement;
    selectedAddOn: AddOnModel;
}

let stateContext: LabOrderPlacedComponentState;
const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

export function useFacade(handleNewPricing: Function, handleModifyLabPricing: Function, handleUpdatePricingByDocument: Function): [
    LabOrderPlacedComponentState,
    Array<ColumnViewModel>,
    (value: string) => void,
    () => void, (value: number) => void,
    (size: number) => void,
    (page: number) => void,
    () => number[],
    (id: number, menuAnchorEl?: HTMLElement) => void,
    () => void,
    () => void,
    () => void,
    () => void,
    () => void
] {
    const [state, setState] = useState({
        isOpen: false,
        isLoading: false,
        provider: AddOnProvider.ImageMark,
        search: '',
        filteredItems: [],
        selectedItems: [],
        totalPrice: 0,
        pageSize: 10,
        selectedPage: 1,
        totalCount: 0,
        menuAnchorEl: null,
        selectedAddOn: null
    } as LabOrderPlacedComponentState);

    stateContext = state;

    const selectAddOns = (
        gender?: Gender,
        provider?: AddOnProvider,
        selectedPage?: number,
        pageSize?: number,
        search?: string) => {
        setState(state => ({
            ...state,
            isLoading: true
        }));

        addOnsService.selectOrdering(
            authQuery.getCurrentPracticeId(),
            gender ?? state.gender,
            provider ?? state.provider,
            ((selectedPage ?? state.selectedPage) - 1) * (pageSize ?? state.pageSize),
            pageSize ?? state.pageSize,
            search ?? state.search
        ).subscribe(
            response => {
                setState(state => ({
                    ...state,
                    filteredItems: response.data,
                    totalCount: response.totalCount,
                    isLoading: false
                }));
            },
            () => {
                setState(state => ({
                    ...state,
                    filteredItems: [],
                    isLoading: false
                }));
            }
        );
    }

    const columns: ColumnViewModel[] = [
        {title: '', alignment: 'center'},
        {title: 'Test Code', alignment: 'left'},
        {title: 'Test Name', alignment: 'left'},
        {title: 'Price', alignment: 'right'}
    ];

    const handleClose = () => {
        setState(state => ({
            ...state,
            isOpen: false,
            isLoading: false,
            filteredItems: [],
            selectedItems: [],
            totalPrice: 0,
            pageSize: 10,
            selectedPage: 1,
            totalCount: 0,
            menuAnchorEl: null,
            search: ''
        }));
    }

    const handleOnFilter = (search: string) => {
        setState(state => ({...state, search: search}));
    }

    const handleChanges = (id: number) => {
        const selectedItems = state.selectedItems;
        const index = selectedItems.findIndex(x => x.id === id);
        if (index > -1) {
            selectedItems.splice(index, 1);
        } else {
            const item = state.filteredItems.find(x => x.id === id);
            selectedItems.push(item);
        }

        const total = selectedItems?.reduce((sum, item) => sum + item.price, 0);
        setState(state => ({...state, selectedItems: selectedItems, totalPrice: total}));
    }

    const handlePageSizeChange = (size: any) => {
        if (size === state.pageSize) {
            return;
        }

        setState(state => ({
            ...state,
            pageSize: size,
            selectedPage: 1
        }));

        selectAddOns(
            state.gender,
            state.provider,
            state.selectedPage,
            size,
            state.search
        );
    }

    const handlePageChange = (page: number) => {
        if (page === state.selectedPage) {
            return;
        }

        setState(state => ({
            ...state,
            selectedPage: page
        }));

        selectAddOns(
            state.gender,
            state.provider,
            page,
            state.pageSize,
            state.search
        );
    }

    const getAllAvailablePages = (): number[] => {
        return getAvailablePages(state);
    }

    const handleToggleMenu = (pricingId: number, menuAnchorEl?: HTMLElement) => {
        setState(state => ({ 
            ...state, 
            menuAnchorEl,
            selectedAddOn: state.filteredItems.find(addOn => addOn.id === pricingId),
        }))
    }

    const handleAddNewLab = () => {
        handleNewPricing(state.provider);
        handleClose();
    }

    const handleUploadDocument = () => {
        handleUpdatePricingByDocument(state.provider);
        handleClose();
    }

    const handleEdit = () => {
        handleModifyLabPricing(state.selectedAddOn, 'edit');
        handleClose();
    }

    const handleDelete = () => {
        handleModifyLabPricing(state.selectedAddOn, 'delete');
        handleClose();
    }

    const handleExportDocument = () => {
        if (!state.filteredItems.length) return;
        const exportData:any[] = state.filteredItems.map(addOn => {
            return {
                "service code": addOn.integrationId,
                "service name": addOn.description,
                "test price": addOn.price
            }
        })
        const ws = XLSX.utils.json_to_sheet(exportData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, `${AddOnProvider[state.provider]}_Pricing` + fileExtension);
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (stateContext.isOpen) {
                setState({
                    ...state,
                    selectedPage: 1,
                })
                selectAddOns(
                    stateContext.gender,
                    stateContext.provider,
                    1,
                    stateContext.pageSize,
                    stateContext.search
                );
            }
        }, 1000);

        return () => clearTimeout(delayDebounceFn)
    }, [stateContext.search]);

    useEffect(() => {
        const subscription = pricingService.onView.subscribe((model) => {
            const [
                provider,
                gender
            ] = model.data;

            selectAddOns(
                gender,
                provider,
                state.selectedPage,
                state.pageSize,
                state.search
            );

            setState(state => ({
                ...state,
                isOpen: true,
                provider: provider,
                gender: gender
            }));
        });

        return () => subscription.unsubscribe();
    }, [])

    return [
        state,
        columns,
        handleOnFilter,
        handleClose,
        handleChanges,
        handlePageSizeChange,
        handlePageChange,
        getAllAvailablePages,
        handleToggleMenu,
        handleAddNewLab,
        handleEdit,
        handleDelete,
        handleExportDocument,
        handleUploadDocument
    ];
}