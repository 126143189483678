import {AppBar, Box, ButtonGroup, Toolbar, useScrollTrigger} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import {useHistory} from 'react-router';
import {MenuItemTypes} from '../../models/menu.models';
import {ProfileComponent} from '../../modules/account/components/profileRedesignComponent/ProfileComponent';
import {SearchPatientComponent} from '../../modules/patients/components/SearchPatientComponent/SearchPatientComponent';
import {
    NotificationsButtonComponent
} from '../../modules/notifications/components/notificationComponent/NotificationsButtonComponent';
import {useStyles} from "./authenticatedHeaderNavbarComponent.styles";
import {WildHealthLinkButton} from "../../modules/common/components/WildHealthLinkButton";
import commonUseStyles from '../../modules/common/styles/common.styles';
import {logoService} from '../../services/logo.service';
import {navigationService} from '../../services/navigation.service';
import {useFacade} from './authenticatedHeaderNavbarComponent.hooks';
import {PlaygroundButton} from "../../modules/conversations/components/playgroundButton/PlaygroundButton";
import {PermissionType} from "../../modules/auth/models/auth.enums";
import {ProtectedElement} from "../../modules/common/components/ProtectedElement";
import {FeatureComponent} from "../../modules/common/components/featureFlags/FeatureComponent";
import {FeatureFlag} from "../../modules/common/components/featureFlags/featureFlags.models";

function ElevationScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

interface AuthenticatedHeaderComponentProps {
    title: JSX.Element | string;
    selectedMenuItem: MenuItemTypes;
    topBannerHeight?: number;
}

export const AuthenticatedHeaderNavbarComponent: React.FC<AuthenticatedHeaderComponentProps> = (props: AuthenticatedHeaderComponentProps) => {
    const history = useHistory();
    const {
        selectedMenuItem,
        topBannerHeight = 0
    } = props;
    
    const classes = useStyles({ topBannerHeight });
    const commonClasses = commonUseStyles();

    const [
        {
            isUsersLoading,
            users,
            selectedUser,
        },
        renderMenuItems,
        handleUserSelect,
        handleUserSearch,
    ] = useFacade(selectedMenuItem, classes, commonClasses);

    return (
        <ElevationScroll>
            <AppBar className={classes.root}
            >
                <Toolbar className={classes.toolbar}>
                    <WildHealthLinkButton
                        id="header-navbar-link-dashboard"
                        onClick={() => navigationService.toDashboard(history)}
                        content={<img className={classes.logo}
                            src={logoService.getLogo().primary}
                            alt="img" />}
                    />
                    <Box display='flex' alignItems='center' mx={4}>
                        {renderMenuItems()}
                    </Box>

                    <div className={classes.grow} />
                    <SearchPatientComponent
                        placeholder={'Search patient'}
                        selectedUser={selectedUser}
                        users={users}
                        handleChange={(user) => handleUserSelect(user)}
                        handleInputChange={handleUserSearch}
                        isLoading={isUsersLoading}
                    />

                    <ButtonGroup
                        size="small"
                        variant='text'
                    >
                        <FeatureComponent featureFlag={FeatureFlag.LlmPlayground}>
                            <ProtectedElement
                                element={
                                    <PlaygroundButton/>
                                }
                                permissions={[PermissionType.Conversations]}
                            />
                        </FeatureComponent>

                        <NotificationsButtonComponent />

                        <ProfileComponent />

                    </ButtonGroup>
                </Toolbar>
            </AppBar>
        </ElevationScroll>
    )
}
