import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { AppointmentWithType } from "../../../../appointments/models/appointments.enums";
import { AppointmentTypeModel } from "../../../../appointments/models/appointments.models";
import { appointmentsQuery } from "../../../../appointments/stores/appointments";
import { onEmit } from "../../../../common/helpers/on-emit";
import { EmployeeModel } from "../../../../employee/models/employee.models";
import { employeesQuery } from "../../../../employee/stores/employeesStore";
import { LocationModel } from "../../../../locations/models/locations.models";
import { locationsQuery } from "../../../../locations/stores";
import { PatientTaskModel } from "../../../../patients/models/patient.model";
import { patientsQuery } from "../../../../patients/stores/patientsStore";
import { TimeZoneModel } from "../../../../timezones/models/timezone.model";
import { PlatformName, Track } from "../../../../tracking/tracker";
import { EngagementCriteriaType } from "../constants/myTaskImages";

interface CreatePatientAppointmentDialogComponentState {
    toShowDialog: boolean;
    toContinueDialog: boolean;

    showDialogSchedulingInfo: boolean;
    titleScheduler: string;
    bodyScheduler: string;

    assignedEmployees: EmployeeModel[];
    appointmentWithType: AppointmentWithType;
    appointmentTypes: AppointmentTypeModel[];
    locations: LocationModel[];
    timeZone: TimeZoneModel;
}

/**
 * Custom Hook to manage a view Model for Appointments component
 */
export function useFacade(task: PatientTaskModel, patientId?: number,): [CreatePatientAppointmentDialogComponentState, () => void, () => void, (isOpen: boolean, isOpenScheduling: boolean) => void] {
    const [state, setState] = useState({
        toShowDialog: false,
        toContinueDialog: false,
        showDialogSchedulingInfo: false,

        appointmentWithType: AppointmentWithType.HealthCoach,
        assignedEmployees: [],
        appointmentTypes: [],
        locations: [],
        timeZone: null,
    } as CreatePatientAppointmentDialogComponentState);

    const handleContinueDialog = () => {
        setState(state => ({
            ...state,
            toContinueDialog: !state.toContinueDialog
        }));
    }

    const handleToggleDialog = () => {
        if (state.toShowDialog) {
            return setState(state => ({
                ...state,
                toShowDialog: !state.toShowDialog,
                toContinueDialog: false
            }));
        }

        if (task.analyticsEvent) {
            Track("engagement_primary_cta", {
                type: task.analyticsEvent,
                platform: PlatformName()
            })
        }

        setState(state => ({
            ...state,
            toShowDialog: !state.toShowDialog
        }));
    }

    const handleOpenSchedulingInfo = (isOpen: boolean, isOpenScheduling: boolean) => {

        if (isOpen && task.analyticsEvent) {
            Track("engagement_detail_cta", {
                type: task.analyticsEvent,
                platform: PlatformName()
            })
        }

        setState(state => ({
            ...state,
            showDialogSchedulingInfo: isOpen
        }));

        if (isOpenScheduling) {
            handleToggleDialog();
        }
    }

    useEffect(() => {
        if (task.type === EngagementCriteriaType.NoICCForMoreThan1DayAfterHCAssigned
            || task.type === EngagementCriteriaType.NoICCForMoreThan2WeeksAfterCheckout
            || task.type === EngagementCriteriaType.NoIMCForMoreThan6MonthsAfterCheckout
            || task.type === EngagementCriteriaType.NoIMCForMoreThan2MonthsAfterCheckout
            || task.type === EngagementCriteriaType.NoHCVisitForMoreThanMonthSinceLastHC
            || task.type === EngagementCriteriaType.IMCCompleted1DayAgo) {
            setState(state => ({ ...state, appointmentWithType: AppointmentWithType.HealthCoach }))
        } else {
            setState(state => ({ ...state, appointmentWithType: AppointmentWithType.Provider }))
        }
        const subscriptions: Subscription[] = [
            onEmit<AppointmentTypeModel[]>(appointmentsQuery.appointmentTypes$, types => {
                setState(state => ({ ...state, appointmentTypes: types }))
            }),
            onEmit<EmployeeModel[]>(employeesQuery.patientEmployees$, employees => {
                setState(state => ({ ...state, assignedEmployees: employees }));
            }),
            onEmit<TimeZoneModel>(patientsQuery.patientTimeZone$, timeZone => {
                setState(state => ({ ...state, timeZone: timeZone }));
            }),
            onEmit<LocationModel[]>(locationsQuery.availableLocations$, locations => {
                setState(state => ({ ...state, locations: locations }));
            }),
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [patientId]);

    return [state, handleToggleDialog, handleContinueDialog, handleOpenSchedulingInfo];
}