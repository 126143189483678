import React, { ReactNode } from "react";
import DateFnsUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider, DatePickerProps, KeyboardDatePicker } from '@material-ui/pickers';
import { MuiThemeProvider } from "@material-ui/core";
import { colors } from "../constants/colors";
import { createTheme, createStyles, makeStyles } from "@material-ui/core/styles";

export interface WildHealthDatePickerProps extends DatePickerProps {
  useKeyboardDatePicker?: boolean;
  keyboardIcon?: ReactNode
}

export const customTheme = createTheme({
  palette: {
    primary: {
      main: colors.main,
      light: colors.main,
      dark: colors.main,
    }
  }
})

export const useStyles = makeStyles(() =>
    createStyles({
      customTextField: {
        "& input::placeholder": {
          fontWeight: 500
        },
        "& .MuiInputAdornment-positionEnd": {
          marginLeft: 0
        }
      }
    })
);

export const WildHealthDatePicker: React.FC<WildHealthDatePickerProps> = (props: WildHealthDatePickerProps) => {
  const classes = useStyles();

  return (
    <MuiThemeProvider theme={customTheme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {props.useKeyboardDatePicker ? (
          <KeyboardDatePicker
            className={classes.customTextField}
            keyboardIcon={props.keyboardIcon}
            onClick={(e) => e.stopPropagation()}
            KeyboardButtonProps={{
              "aria-label": "change date",
              "edge": "end",
              "size": "small"
            }}
            {...props}
          />
        ) : (
          <DatePicker {...props} />
        )}
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
};