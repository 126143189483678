import React from 'react';
import {
    Box, IconButton, Dialog, DialogActions, DialogContent, TextField, Typography,
    Button, useMediaQuery, useTheme,
} from "@material-ui/core";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import clsx from 'clsx';
import moment from "moment";
import Cancel from "@img/icons/cancel.svg";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { useFacade } from "./patientMedicationUpdateComponent.hooks";
import { useStyles } from "./patientMedicationUpdateComponent.styles";
import { PatientMedicationModel } from "../../models/patientMedicationModel";
import { toCurrentTimeZone } from '../../../timezones/helpers/timezone';
import { WildHealthDatePicker } from '../../../common/components/WildHealthDatePicker';

export interface PatientMedicationUpdateProps {
    opened: boolean;
    handleClose: () => void;
    patientId?: number;
    model?: PatientMedicationModel;
}

export const PatientMedicationUpdateComponent: React.FC<PatientMedicationUpdateProps> = (props: PatientMedicationUpdateProps) => {
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const [
        {
            model,
            errors,
        },
        handleClose,
        handleChanges,
        handleSave
    ] = useFacade(props);

    const getDate = (date) => {
        return date
            ? moment(toCurrentTimeZone(date)).format('YYYY-MM-DD')
            : null
    }

    return (
        <>
            <Dialog
                fullScreen={isSmallScreen}
                className={classes.dialog}
                onClose={() => handleClose()}
                open={props.opened}
            >
                {
                    !isSmallScreen &&
                    <MuiDialogTitle
                        className={classes.dialogTitle}
                        disableTypography
                    >
                        <Typography variant="h6">{model.id > 0 ? 'Edit Medication' : 'Add Medication'}</Typography>
                        <Box pl={1} pr={1}>
                            <IconButton id="patient-medication-update-close" onClick={() => {
                                handleClose()
                            }} >
                                <img src={Cancel} alt='cancel' />
                            </IconButton>
                        </Box>
                    </MuiDialogTitle>
                }
                <DialogContent className={classes.dialogContent}>
                    {
                        isSmallScreen &&
                        <Box py={1} display="flex" justifyContent="space-between">
                            <Button onClick={() => handleClose()}>
                                <span className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-gray1 wh-tw-capitalize">
                                    Cancel
                                </span>
                            </Button>
                            <Box>
                                <span className="wh-tw-text-xl wh-tw-font-medium wh-tw-text-black">
                                    {model.id > 0 ? 'Edit Medication' : 'Add Medication'}
                                </span>
                            </Box>
                            <Button onClick={() => handleSave(props.patientId)}>
                                <span className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-primaryV wh-tw-capitalize">
                                    {model.id > 0 ? 'Save' : 'Add'}
                                </span>
                            </Button>
                        </Box>
                    }
                    <Box p={!isSmallScreen && 1.5} pt={0.5} display="flex" flexDirection="column">
                        <Box display="flex" pl={0.5} className="wh-tw-text-sm wh-tw-text-gray1">
                            Name of Medication
                            <Box pl={0.5} className="wh-tw-text-bad">*</Box>
                        </Box>
                        <TextField
                            required
                            fullWidth
                            id="name"
                            size="small"
                            variant="outlined"
                            value={model.name}
                            className={clsx(classes.field, 'input')}
                            error={!!errors['name']}
                            helperText={errors['name']}
                            onChange={(event) => handleChanges(event.target.id, event.target.value)}
                        />
                        <Box pl={0.5} pt={2.5} className="wh-tw-text-sm wh-tw-text-gray1">Strength</Box>
                        <TextField
                            fullWidth
                            size="small"
                            id="dosage"
                            variant="outlined"
                            value={model.dosage}
                            className={clsx(classes.field, 'input')}
                            error={!!errors['dosage']}
                            helperText={errors['dosage']}
                            onChange={(event) => handleChanges(event.target.id, event.target.value)}
                        />
                        <Box pl={0.5} pt={2.5} className="wh-tw-text-sm wh-tw-text-gray1">Dosing Instructions</Box>
                        <TextField
                            fullWidth
                            size="small"
                            id="instructions"
                            variant="outlined"
                            value={model.instructions}
                            className={clsx(classes.field, 'input')}
                            error={!!errors['instructions']}
                            helperText={errors['instructions']}
                            onChange={(event) => handleChanges(event.target.id, event.target.value)}
                        />
                        <Box pl={0.5} pt={2.5} className="wh-tw-text-sm wh-tw-text-gray1">Start Date</Box>
                        <Box width={1} p={0.5}>
                            <WildHealthDatePicker
                                id={`start-date`}
                                fullWidth
                                size="small"
                                placeholder="MM/DD/YYYY"
                                openTo="date"
                                format="MM/DD/yyyy"
                                value={getDate(model.startDate)}
                                error={!!errors[`startDate`]}
                                helperText={errors[`startDate`]}
                                inputVariant="outlined"
                                views={["year", "month", "date"]}
                                onChange={(momentDate) =>
                                    handleChanges(`startDate`, momentDate
                                )}
                            />
                        </Box>
                    </Box>
                </DialogContent>
                {
                    !isSmallScreen &&
                    <DialogActions className={classes.action}>
                        <WildHealthButton
                            id="patient-medication-update-cancel"
                            onClick={() => {
                                handleClose()
                            }}
                            color="tertiary"
                            size='large'
                        >
                            Cancel
                        </WildHealthButton>
                        <Box pl={0.5}>
                            <WildHealthButton
                                id="patient-medication-update-save"
                                onClick={() => {
                                    handleSave(props.patientId)
                                }}
                                autoFocus
                                size='large'
                            >
                                {model.id > 0 ? 'Save Changes' : 'Add Medication'}
                            </WildHealthButton>
                        </Box>
                    </DialogActions>
                }
            </Dialog>
        </>
    );
}
