import React, { useEffect, useState } from 'react';
import { Box, IconButton, Table, TableBody, TableContainer, TableHead } from "@material-ui/core";
import { Add } from '@material-ui/icons';
import { colors } from '../../../common/constants/colors';
import { PatientMedicationModel } from '../../../patientSupplements/models/patientMedicationModel';
import { PatientMedicationUpdateComponent } from '../../../patientSupplements/components/patientMedicationUpdateComponent/PatientMedicationUpdateComponent';
import { WildHealthTableCell } from '../../../common/components/wildHealthTable/WildHealthTableCell';
import { WildHealthTableRow } from '../../../common/components/wildHealthTable/WildHealthTableRow';
import { SecondaryButton } from '../../../common/components/wildHealthButton/SecondaryButton';
import clsx from 'clsx';
import commonUseStyles from '../../../common/styles/common.styles';
import { onEmit } from '../../../common/helpers/on-emit';
import { Subscription } from 'rxjs';
import { patientMedicationsQuery } from '../../../patientSupplements/stores/patientMedicationsStore/patientMedications.query';
import { QuestionModel, QuestionType } from '../../models/questionnaire.models';
import { medicationsService } from '../../../patientSupplements/services/medications.service';
import { patientsMedicationsService } from '../../../patientSupplements/services/patientMedications.service';
import { MedicalHistoryAndLifestyleQuestionNames } from '../../static/medicalHistroryQuestionnaire.static';

export interface QuestionnaireMedicationsComponentProps {
    patientId?: number | null;
    question: QuestionModel;
    handleChanges: (field: string, value: string) => void
}

interface QuestionnaireMedicationsComponentState {
    medications: PatientMedicationModel[];
    dialogOpened: boolean;
}

export const QuestionnaireMedicationsComponent: React.FC<QuestionnaireMedicationsComponentProps> = (props: QuestionnaireMedicationsComponentProps) => {
    const {
        patientId = null,
        question,
        handleChanges
    } = props;

    const commonClasses = commonUseStyles();

    const [state, setState] = useState({
        medications: [],
        dialogOpened: false
    } as QuestionnaireMedicationsComponentState)

    const handleOpenDialog = (open: boolean) => {
        setState({ ...state, dialogOpened: open })
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<PatientMedicationModel[]>(patientMedicationsQuery.patientMedications$, medications => {
                if (medications && medications.length) {
                    setState(state => ({ ...state, medications: medications }))
                    handleChanges(MedicalHistoryAndLifestyleQuestionNames.MEDICATIONS, medications.map(x => x.id).join(','));
                }
            })
        ];

        if (patientId) {
            medicationsService.get(patientId).subscribe()
        }
        else {
            patientsMedicationsService.get().subscribe()
        }

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    const renderContent = (): JSX.Element => {
        if (state.medications.length) {
            return (
                <Box>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <WildHealthTableRow>
                                    <WildHealthTableCell>Name</WildHealthTableCell>
                                    <WildHealthTableCell>Dosage</WildHealthTableCell>
                                </WildHealthTableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    state.medications.map((medication, index) => (
                                        <WildHealthTableRow key={index}>
                                            <WildHealthTableCell>{medication.name}</WildHealthTableCell>
                                            <WildHealthTableCell>{`${medication.dosage}, ${medication.instructions}`}</WildHealthTableCell>
                                        </WildHealthTableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box display="flex" justifyContent="flex-start" alignItems="center" mt={1}>
                        <Box>
                            <IconButton id="questionnaire-medications-open" data-testid="questionnaireMedicationsOpenBtn" onClick={() => handleOpenDialog(true)}>
                                <Add style={{ color: colors.main, backgroundColor: colors.gray3 }} />
                            </IconButton>
                        </Box>
                        <Box>
                            <span className={clsx(commonClasses.colorMain, commonClasses.textMedium, commonClasses.size14)}>Add Medication</span>
                        </Box>
                    </Box>
                </Box>
            )
        }

        return (
            <Box textAlign='center'>
                <Box mt={5}>
                    <span className={clsx(commonClasses.colorGray1, commonClasses.textSemiBold, commonClasses.size20, commonClasses.secondaryFont)}>No data yet</span>
                </Box>
                <Box mt={3}>
                    <span className={commonClasses.colorGray1}>Add medications by clicking the button below.</span>
                </Box>
                <Box mt={2}>
                    <SecondaryButton id="questionnaire-medications-add" data-testid="questionnaireMedicationsAddBtn" onClick={() => handleOpenDialog(true)}>Add Medication</SecondaryButton>
                </Box>
            </Box>
        )
    }

    return (
        <>
            <PatientMedicationUpdateComponent
                opened={state.dialogOpened}
                patientId={patientId}
                handleClose={() => handleOpenDialog(false)}
            />
            <Box>
                <span className={commonClasses.textMedium}>{question.displayName}</span>
                {
                    (question.type === QuestionType.CheckMany || question.type === QuestionType.SelectMany) &&
                    <span className={commonClasses.colorGray1}> (Check all that apply.)</span>
                }
                {
                    question.optional &&
                    <span className={commonClasses.colorGray1}> (Optional)</span>
                }
            </Box>
            {
                renderContent()
            }
        </>
    )
}