import React from "react";
import {Box, IconButton, useMediaQuery, useTheme} from "@material-ui/core";
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import CloseIcon from '@material-ui/icons/Close';
import {PlaygroundChatListComponent} from "../playgroundChatListComponent/PlaygroundChatListComponent";
import {ConversationType} from "../../models/conversation.models";
import {PlaygroundChaComponent} from "../playgroundChatComponent/PlaygroundChatComponent";
import {useStyles} from "./patientPlaygroundComponent.styles";
import {PatientPlaygroundViewState, useFacade} from "./patientPlaygroundComponent.hooks";
import {StartPatientPlaygroundComponent} from "../startPatientPlaygroundComponent/StartPatientPlaygroundComponent";

export interface PatientPlaygroundComponentProps {
    patientId: number;
}

export const PatientPlaygroundComponent: React.FC<PatientPlaygroundComponentProps> = (props: PatientPlaygroundComponentProps) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMiddleScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [
        {
            chatListHidden,
            targetConversation,
            viewState
        },
        handleChatListToggle,
        handleCreateConversation,
        handleConversationCreated,
        handleSelectConversation
    ] = useFacade();

    const renderChatsList = () => {
        return (
            <Box className={classes.chatListRoot} hidden={isMiddleScreen && chatListHidden}>
                <Box className={classes.chatList}>
                    <PlaygroundChatListComponent
                        patientId={props.patientId}
                        type={ConversationType.PatientPlayground}
                        targetConversation={targetConversation}
                        handleSelect={handleSelectConversation}
                        handleCreate={handleCreateConversation}
                    />
                </Box>
            </Box>
        )
    }

    const renderChatView = () => {
        switch(viewState) {
            case PatientPlaygroundViewState.TargetConversation:
                return <PlaygroundChaComponent targetConversation={targetConversation} type={ConversationType.PatientPlayground}/>
            case PatientPlaygroundViewState.NewConversation:
                return <StartPatientPlaygroundComponent handleCreated={handleConversationCreated} patientId={props.patientId}/>
        }
    }

    const renderChat = () => {
        return (
            <Box className={classes.chatRoot} hidden={isMiddleScreen && !chatListHidden}>
                <Box className={classes.chat}>
                    {
                        renderChatView()
                    }
                </Box>
            </Box>
        )
    }

    const renderChatListToggleButton = () => {
        return (
            <Box hidden={!isMiddleScreen} className={classes.toggleButton}>
                <IconButton className="wh-tw-text-primaryV" onClick={() => handleChatListToggle()}>
                    {
                        chatListHidden
                            ? <FormatListBulletedIcon/>
                            : <CloseIcon/>
                    }
                </IconButton>
            </Box>
        )
    }

    return (
        <Box display='flex' className={classes.root}>
            {renderChat()}
            {renderChatsList()}
            {renderChatListToggleButton()}
        </Box>
    )
}