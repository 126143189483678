import {
    ConnectionStatuses,
    ConversationAuthorModel,
    ConversationModelBase,
    ConversationType
} from "../../models/conversation.models";
import {useEffect, useState} from "react";
import {conversationsService} from "../../services/conversations.service";
import {employeeConversationsQuery} from "../../stores/employeeConversationsStore/employeeConversations.query";
import {Subscription} from "recompose";
import {onEmit} from "../../../common/helpers/on-emit";
import { getConversationAttributes } from "../../helpers/getAttributes";

interface PlaygroundChatComponentState {
    targetConversation: ConversationModelBase;
    author: ConversationAuthorModel;
    editSubject: boolean;
    newSubject: string;
    messages: any[];
    isLoadingMessages: boolean;
}

export function useFacade(targetConversation: ConversationModelBase, type: ConversationType): [
    PlaygroundChatComponentState,
    (message: string) => void,
    // subject handlers
    () => void,
    (value: string) => void,
    () => void
] {
    const [state, setState] = useState({
        targetConversation: targetConversation,
        author: null,
        editSubject: false,
        newSubject: targetConversation.subject
    } as PlaygroundChatComponentState);

    const toggleEditSubject = () => {
        setState(state => ({
            ...state,
            editSubject: !state.editSubject,
            newSubject: !state.editSubject ? state.targetConversation.subject : state.newSubject
        }))
    }

    const handleSubjectChanges = (value: string) => {
        setState(state => ({
            ...state,
            newSubject: value
        }))
    }

    const submitNewSubject = () => {
        conversationsService.updateSubject({
            id: targetConversation.id,
            newSubject: state.newSubject
        }).subscribe(
            () => {
                setState(state => ({
                    ...state,
                    editSubject: false
                }))
            }
        )
    }

    const handleSendMessage = (message: string): boolean => {
        if (!employeeConversationsQuery.getConnectionStatus()) return false;
        if (message.length && targetConversation.proxy?.status === ConnectionStatuses.joined) {
            const attributes = getConversationAttributes(targetConversation);
            targetConversation.proxy.sendMessage(message, attributes);
            return true;
        }

        return false;
    };

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<ConversationModelBase>(employeeConversationsQuery.targetConversation$, targetConversation =>
                {
                    // Prevent loading default conversations into playground chat
                    // But we still allow to zero out target conversation in order to close LLM
                    if (targetConversation && targetConversation.type !== type) {
                        return;
                    }

                    setState(state => ({
                        ...state,
                        targetConversation: targetConversation
                    }))
                }
            ),
            onEmit<ConversationAuthorModel>(employeeConversationsQuery.author$, author =>
                setState(state => ({ ...state, author: author }))
            ),
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [type]);

    return [
        state,
        handleSendMessage,
        toggleEditSubject,
        handleSubjectChanges,
        submitNewSubject
    ];
}