import React from 'react';
import {
    Box,
    Divider,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    Tooltip,
    useMediaQuery,
    useTheme
} from '@material-ui/core';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import commonUseStyles from '../../../common/styles/common.styles';
import {useFacade} from './patientPastAppointmentsComponent.hooks';
import {WildHealthPlaceHolderBase} from '../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolderBase';
import emptyAppointments from "@img/placeholders/EmptyAppointments.png";
import clsx from 'clsx';
import {WildHealthTableRow} from '../../../common/components/wildHealthTable/WildHealthTableRow';
import {WildHealthTableCell} from '../../../common/components/wildHealthTable/WildHealthTableCell';
import moment from 'moment';
import {PatientAppointmentModel} from '../../models/appointments.models';
import {displayTimeZone} from '../../../timezones/helpers/timezone';
import {AppointmentStatus, AppointmentStatusNames} from '../../models/appointments.enums';
import {colors} from '../../../common/constants/colors';
import {ReactComponent as Person} from '@img/icons/Person.svg';
import DownloadButton from "../../../common/components/wildHealthButton/downloadButton/DownloadButton";
import GetAppIcon from "@material-ui/icons/GetApp";
import {useStyles} from "./patientPastAppointmentsComponent.styles";

interface PatientPastAppointmentsComponentProps {
    patientId?: number;
}

export const PatientPastAppointmentsComponent: React.FC<PatientPastAppointmentsComponentProps> = (props: PatientPastAppointmentsComponentProps) => {
    const { patientId } = props;

    const [
        {
            appointments,
            employees,
            isLoading,
            selectedNote,
            timeZone
        },
        handleNoteViews,
        handleDownloadPastAppts,
        view
    ] = useFacade(patientId);

    const classes = useStyles();
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const customTooltipClasses = {
        tooltip: classes.customWidth,
        arrow: classes.arrow,
        tooltipPlacementBottom: classes.tooltipPlacement,
        tooltipPlacementTop: classes.tooltipPlacement,
    };

    const getHost = (appointment: PatientAppointmentModel): string => {
        return appointment.employees.map(x => `${x.firstName} ${x.lastName}`).join(' & ')
    }

    const getStatus = (appointment: PatientAppointmentModel) => {
        if (appointment.noShow) {
            return <span className={commonClasses.colorAverage1}>No Show</span>;
        }
        if (appointment.status !== AppointmentStatus.Canceled) {
            return <span className={commonClasses.colorGood}>Completed</span>;
        } else {
            if (patientId && appointment.status === AppointmentStatus.Canceled) {
                const cancelledBy = employees.find(x => x.userId === appointment.cancelledBy);
                return <Tooltip placement="bottom" arrow onOpen={() => console.log("HELLO")} onClose={() => console.log("BYE BYE")}
                    title={<Box className={classes.notificationToolTipText}>
                        {
                            cancelledBy &&
                            <Box>
                                Cancelled by {cancelledBy.firstName} {cancelledBy.lastName}
                                {
                                    appointment.cancelledAt &&
                                    <span>
                                         at {moment(appointment.startDate).format('MM/DD/YYYY hh:mm A')}
                                    </span>
                                }
                            </Box>
                        }
                    </Box>}
                    classes={customTooltipClasses}
                >
                    <span className={commonClasses.colorBad}>{AppointmentStatusNames.get(appointment.status)}</span>
                </Tooltip>
            }
            return <span className={commonClasses.colorBad}>{AppointmentStatusNames.get(appointment.status)}</span>;
        }
    }

    const getNotes = (appointment: PatientAppointmentModel) => {
        if (appointment.note) {
            return <span className={commonClasses.btnLinkToNotes}>View</span>;
        }
    }

    const getStatusForMobile = (appointment: PatientAppointmentModel) => {
        if (appointment.noShow) {
            return <Box px={2} py={1} className={commonClasses.backgroundAverage1}><span className={commonClasses.colorAverage1}>No Show</span></Box>;
        }
        if (appointment.status !== AppointmentStatus.Canceled) {
            return <Box px={2} py={1} className={commonClasses.backgroundGood}><span className={commonClasses.colorGood}>Completed</span></Box>;
        } else {
            return <Box px={2} py={1} className={commonClasses.backgroundBad}><span className={commonClasses.colorBad}>{AppointmentStatusNames.get(appointment.status)}</span></Box>;
        }
    }

    const renderAppointments = (): JSX.Element => {
        if (isLoading) {
            return (
                <Box mt="30px">
                    <WildHealthLinearProgress />
                </Box>);
        }

        if (!appointments || !appointments.length) {
            return (
                <Box mt="30px">
                    <WildHealthPlaceHolderBase message="No appointments.">
                        <img
                            src={emptyAppointments}
                            alt="img" />
                    </WildHealthPlaceHolderBase>
                </Box>
            );
        }

        if (selectedNote) {
            return view
        }
        if (isSmallScreen) {
            return (
                <Box mt="20px">
                    {
                        appointments.map((appointment, index) =>
                            <Box mb="10px" className={commonClasses.bgWhiteMain} key={index}>
                                <Box pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
                                    <Box>
                                        <Box className={clsx(commonClasses.textMedium, commonClasses.size14, commonClasses.colorBlack)}>
                                            {moment(appointment.startDate).format('MM/DD/YYYY')}
                                        </Box>
                                        <Box className={clsx(commonClasses.size12, commonClasses.colorGray1, commonClasses.textRegular)}>
                                            {moment(appointment.startDate).format('hh:mm A')}
                                        </Box>
                                    </Box>
                                    <Box>
                                        {getStatusForMobile(appointment)}
                                    </Box>
                                </Box>
                                <Box px={2} py={1.5}>
                                    <span className={clsx(commonClasses.textRegular, commonClasses.size14, commonClasses.colorBlack)}>{appointment.name}</span>
                                </Box>
                                <Divider />
                                <Box px={2} pb={2} mt={1.5} display="flex" alignItems="center">
                                    <Person fill={colors.gray2} />
                                    <Box ml={1}>
                                        <span className={clsx(commonClasses.textRegular, commonClasses.size14, commonClasses.colorBlack)}>{getHost(appointment)}</span>
                                    </Box>
                                </Box>
                            </Box>
                        )
                    }
                </Box>
            );
        } else {
            return (
                <Box mt="30px">
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <WildHealthTableRow>
                                    <WildHealthTableCell>Date & Time</WildHealthTableCell>
                                    <WildHealthTableCell>Appointment Type</WildHealthTableCell>
                                    <WildHealthTableCell align={'center'}>Notes</WildHealthTableCell>
                                    <WildHealthTableCell>Appointment Status</WildHealthTableCell>
                                    <WildHealthTableCell>Host</WildHealthTableCell>
                                </WildHealthTableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    appointments.map((appointment, index) =>
                                        <WildHealthTableRow key={index}>
                                            <WildHealthTableCell style={{ display: 'grid' }}>
                                                <span
                                                    className={commonClasses.textMedium}>{moment(appointment.startDate).format('MM/DD/YYYY')}</span>
                                                <span
                                                    className={clsx(commonClasses.size12, commonClasses.colorGray1)}>{moment(appointment.startDate).format('hh:mm A')}</span>
                                            </WildHealthTableCell>
                                            <WildHealthTableCell>{appointment.name}</WildHealthTableCell>
                                            <WildHealthTableCell align={'center'} onClick={() => handleNoteViews(appointment)} >{getNotes(appointment)}</WildHealthTableCell>
                                            <WildHealthTableCell>{getStatus(appointment)}</WildHealthTableCell>
                                            <WildHealthTableCell>{getHost(appointment)}</WildHealthTableCell>
                                        </WildHealthTableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            );
        }
    }

    return (
        <Box p={isSmallScreen ? 2 : "30px"} className={clsx(commonClasses.size14, !isSmallScreen && commonClasses.bgWhiteMain)}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box>
                    <span className={clsx(commonClasses.size24, commonClasses.textSemiBold)}>Past Appointments</span>
                </Box>

                {!isSmallScreen && <Box>
                    <span className={commonClasses.colorGray1}>Timezone:&nbsp;</span>
                    <span className={commonClasses.textMedium}>{displayTimeZone(timeZone)}</span>
                </Box>}
                <Box>
                    <DownloadButton id={'download-appts'}
                                    style = {{
                                        background: colors.stroke
                                    }}
                                    width = {40}
                                    square={true}
                                    disabled = {isLoading}
                                    onClick={()=>handleDownloadPastAppts()}>
                        <GetAppIcon/>
                    </DownloadButton>
                </Box>
            </Box>
            <Box>
                {
                    renderAppointments()
                }
            </Box>
        </Box>
    )
}