import React from "react";
import {Badge} from "@material-ui/core";
import {useFacade} from "./conversationsBadgeComponent.hooks";

export const ConversationsBadgeComponent: React.FC = () => {
    const [newMessagesAndTickets] = useFacade();

    return (
        <>
            {
                newMessagesAndTickets
                    ? <Badge variant="standard" badgeContent={'...'} color="secondary"/>
                    : <></>
            }
        </>
    );
}