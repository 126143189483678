import { createStyles, makeStyles } from '@material-ui/core/styles'
import { colors } from '../../../common/constants/colors'

export const useStyles = makeStyles(() =>
    createStyles({
        rootView: {
            fontSize: 14,
            minHeight: 30,
            borderRadius: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'fit-content',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            minWidth: 64,
            paddingRight: 14,
            paddingLeft: 14,
            color: '#091D36',
            backgroundColor: '#EFF3F8'
        },
        input:{
            height: '10px'
        },
        button: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: '8px',
            color: colors.main,
            fontSize: 14
        },
        deleteButton: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: '8px',
            color: colors.bad,
            fontSize: 14
        },
        buttonText: {
            marginLeft: '6px',
            textTransform: 'capitalize'
        }
    })
)