import React from "react";
import {useFacade} from "./loginRxNTPage.hooks";
import {TitlesService} from "../../../../constants/title.constants";
import {
    Box,
    Container
} from "@material-ui/core";
import { ReactComponent as WHClarityLogo } from '@img/logos/WHClarityLogo.svg';
import {useStyles} from "../loginPage/loginPage.styles";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";

export const LoginRxNTPage: React.FC<any> = (props) => {
    const patientId = props?.location?.state?.patientId ?? new URLSearchParams(props.location.search).get('patientId');
    const from = props?.location?.state?.from;
    const [
        {
            inProgress,
            redirectTo,
            token
        },
        formRef,
        handleSubmit
    ] = useFacade(patientId, from);

    const classes = useStyles();

    return (
        <>
            <title>{TitlesService.loginRxNTTitle}</title>
            <div className="wh-tw-w-full wh-tw-h-full wh-tw-min-h-screen wh-tw-bg-white">
                <Box display="flex" justifyContent="center" alignItems="center" height="102px">
                    <WHClarityLogo />
                </Box>
                <Box className={classes.contentBox}>
                    <Container maxWidth="sm">
                        <Box className="wh-tw-text-3xl md:wh-tw-text-[40px] wh-tw-font-medium wh-tw-text-secondary wh-tw-font-poppins" textAlign="center">
                            Log to RxNT
                        </Box>
                        <Box mt={5}>
                            <WildHealthLinearProgress />
                        </Box>
                        <Box className="wh-tw-flex wh-tw-justify-center">
                            <Box maxWidth={456} display='flex' flexDirection='column' mt={3}>

                                <form onSubmit={(e) => handleSubmit(e)}>
                                    <form ref={formRef} id="RXNTLoginForm" method="post" action="https://app2.rxnt.com/ehrv8/SingleSignOnProcessor.ashx?OTO=1">

                                        <input type="hidden" name="ExternalOrderId" value={btoa(new Date().toString())} />

                                        <input type="hidden" name="data" value={token} />

                                        <input type="hidden" name="returnURL" value={redirectTo} />

                                    </form>
                                </form>
                            </Box>
                        </Box>
                    </Container>
                </Box>
            </div>
        </>
    )
}