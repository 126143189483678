import { StatusChangeLogModel } from "../../common/models/statusChangeLog.model";
import { AddOnModel } from './addOns.models';

export enum PatientAddonStatus {
    Preparing = 0,
    InProgress = 5,
    Completed = 10,
    Failed = 15
}

export interface PatientAddOnModel {
    id: number;
    date: Date;
    status: PatientAddonStatus;
    addOn: AddOnModel;
    bill: AddOnBillModel;
    orderId: number;
    orderNumber: string;
    statusLogs: StatusChangeLogModel<PatientAddonStatus>[];
}

export interface AddOnBillModel {
    isPaid: boolean;
    paymentDate: Date | null;
}

export interface ChangePatientAddOnStatusModel {
    id: number;
    patientId: number;
    status: PatientAddonStatus;
}

export interface BuyPatientAddOnModel {
    patientId: number;
    addOnId: number;
}

export interface PatientAddOnsModel {
    name: string;
    status: string;
}