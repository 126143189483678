import {useEffect, useState} from "react";
import {IErrorState} from "../../../common/validation/error-state";
import {EmployeeProfileModel} from "../../models/employeeProfile.model";
import {onEmit} from "../../../common/helpers/on-emit";
import {profileQuery} from "../../stores/profileStore";
import {profileService} from "../../services/profile.service";
import {employeeAccountComponentValidator} from "./employeeAccountComponent.validator";
import {authService} from "../../../auth/services/auth.service";
import {authQuery} from "../../../auth/stores/auth";
import Resizer from "react-image-file-resizer";
import {addressService} from '../../services/address.service'
import moment from "moment";

interface EmployeeAccountComponentState extends IErrorState {
    isLoading: boolean;
    isPhotoUrlLoading: boolean;
    isStateLoading: boolean;
    isSubmitted: boolean;
    profile: EmployeeProfileModel;
    photo: File;
    photoUrl: string
}

export function useFacade(): [
    EmployeeAccountComponentState,
    Function,
    Function,
    Function,
    Function,
    Function
] {
    const [state, setState] = useState({
        profile: {
            stateLicenses: [],
            credentials: '',
            bio: '',
            states: '',
            billingAddress: {},
            shippingAddress: {}
        },
        photo: null,
        photoUrl: '',
        isLoading: true,
        isPhotoUrlLoading: true,
        isStateLoading: true,
        isSubmitted: false,
        errors: {}
    } as EmployeeAccountComponentState);

    const handleChanges = (field: string, value: string) => {
        employeeAccountComponentValidator.validateAndSetState(state, setState, field, value);

        const profile = state.profile;
        profile[field] = value;

        setState(state => ({
            ...state,
            profile: profile
        }));
    }

    const handleSubmit = (event: Event) => {
        event.preventDefault();

        employeeAccountComponentValidator.validateObjectAndSetState(state, setState, state.profile);
        if (!employeeAccountComponentValidator.stateIsValid(state)) {
            return;
        }
        if (state.profile.birthday && moment(state.profile.birthday).isAfter(moment(new Date()).add(-18, 'years'))) {
            setState({
                ...state,
                errors: {
                    birthday: 'Invalid birthdate.'
                }
            })
            return;
        }

        setState(state => ({
            ...state,
            isSubmitted: true
        }));

        const formData = new FormData();
        formData.append('photo', state.photo);

        state.profile.email = state.profile.email.trim();

        const profile = Object.entries(state.profile);

        profile.forEach(([key, value]) => {
            if(value != null) {
                formData.append(key, value)
            }
        });

        profileService.updateEmployeeProfile(formData).subscribe(
            () => {
                authService.reLogin(authQuery.getTargetLocationId()).subscribe();

                setState(state => ({
                    ...state,
                    isSubmitted: false
                }));
            },
            () => {
                setState(state => ({
                    ...state,
                    isSubmitted: false
                }));
            }
        );
    }

    const resizeFile = (file) : Promise<any> =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                100,
                100,
                "PNG",
                50,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64"
            );
        });

    const handlePhotoUpload = (event) => {
        event.preventDefault();
        const file = event.target.files[0];

        resizeFile(file).then((result) => {
            setState(state => ({
                ...state,
                photo: file,
                photoUrl: result
            }));
        });

        event.target.value = '';
    }

    const deletePhoto = () => {
        setState(state => ({
            ...state,
            photo: null,
            photoUrl: ''
        }));
    }

    const handleStatesChange = (value) => {
        const profile = state.profile;
        profile.stateLicenses = value;
        profile.states = value.map(x=> x.id).join(',')

        setState(state => ({
            ...state,
            profile: profile
        }));
    }

    useEffect(() => {
        const subscriptions = [
            onEmit<EmployeeProfileModel>(profileQuery.employeeProfile$, profile => {
                setState(state => ({
                    ...state,
                    profile: profile
                }));
            })
        ];
        const getProfileCB = () => setState(state => ({ ...state, isLoading: false }))
        const getProfilePhotoCB = () => setState(state => ({ ...state, isPhotoUrlLoading: false }))
        const getStateCB = () => setState(state => ({ ...state, isStateLoading: false }))

        profileService.getEmployeeProfile().subscribe(getProfileCB, getProfileCB);

        profileService.getProfilePhoto().subscribe(photo => {
            if (photo) {
                setState(state => ({
                    ...state,
                    photoUrl: "data:image/png;base64, " + photo
                }));
            }
            getProfilePhotoCB();
        }, getProfilePhotoCB)

        addressService.getStates().subscribe(getStateCB, getStateCB);

        return (() => {
            subscriptions.map(it => it.unsubscribe())
        });
    }, []);

    return [
        state, 
        handleStatesChange, 
        handlePhotoUpload,
        handleChanges, 
        handleSubmit,
        deletePhoto
    ];
}
