import React, {useCallback} from 'react';
import {Box} from "@material-ui/core";
import { patientsService } from "../../../patients/services/patients.service";
import { PatientAppointmentModel } from '../../models/appointments.models';
import { appointmentsService } from "../../services/appointments.service";
import { patientsQuery } from "../../../patients/stores/patientsStore";
import { appointmentsQuery } from "../../stores/appointments";
import { onEmit } from "../../../common/helpers/on-emit";
import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import {TimeZoneModel} from "../../../timezones/models/timezone.model";
import {NoteModel, NotesType} from "../../../notes/models/notes.models";
import {navigationService} from "../../../../services/navigation.service";
import {useHistory} from "react-router";
import {InitialConsultComponent} from "../../../notes/components/initialConsult/InitialConsultComponent";
import {FollowUpComponent} from "../../../notes/components/followUp/FollowUpComponent";
import {PatientBlankPage} from "../../../notes/pages/patientBlankPage/PatientBlankPage";
import {SoapNoteComponent} from "../../../notes/components/soapNoteComponent/SoapNoteComponent";
import {
    HistoryAndPhysicalNoteComponent
} from "../../../notes/components/historyAndPhysicalComponent/HistoryAndPhysicalComponent";
import {
    HistoryAndPhysicalFollowUpNoteComponent
} from "../../../notes/components/historyAndPhysicalFollowUpComponent/HistoryAndPhysicalFollowUpComponent";
import {authQuery} from "../../../auth/stores/auth";
import {confirmService} from "../../../../services/confirm.service";
import {EmployeeShortModel} from "../../../employee/models/employee.models";
import {employeesQuery} from "../../../employee/stores/employeesStore";
import {employeeService} from "../../../employee/services/employees.service";

interface PatientPastAppointmentsComponentState {
    appointments: PatientAppointmentModel[];
    employees: EmployeeShortModel[];
    timeZone: TimeZoneModel;
    isLoading: boolean;
    selectedNote: NoteModel;
}

export function useFacade(patientId: number | null): [ PatientPastAppointmentsComponentState, (NoteModel)=> void, ()=>void, JSX.Element ]{
    const history = useHistory();
    const [state, setState] = useState({
        appointments: [],
        employees: [],
        selectedNote:null,
        timeZone: null,
        isLoading: true
    } as PatientPastAppointmentsComponentState);

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<PatientAppointmentModel[]>(appointmentsQuery.patientAppointments$, appointments => {
                setState(state => ({...state, appointments: appointments}));
            }),
            onEmit<TimeZoneModel>(patientsQuery.patientTimeZone$, timeZone => {
                setState(state => ({...state, timeZone: timeZone }));
            }),
            onEmit<EmployeeShortModel[]>(employeesQuery.employees$, employees => {
                setState(state => ({...state, employees: employees}));
            })
        ];

        if(Boolean(patientId)) {
            employeeService.getAllPracticeEmployees();
            patientsService.getPatientTimeZone(patientId);
            
            appointmentsService.getPatientAppointmentsById(patientId, null, new Date()).subscribe(() => {
                setState(state => ({...state, isLoading: false}));
            })
        }
        else {
            patientsService.getPatientTimeZone();

            appointmentsService.getPatientAppointments(null, new Date()).subscribe(() => {
                setState(state => ({...state, isLoading: false, selectedNote:null}));
            });
        }

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    const handleViewNotes = (appointment: PatientAppointmentModel) => {
        const {note} = appointment;

        setState({...state, selectedNote : note})
    }

    const handleDownloadPastAppts = () => {
        const patientFullName = !!authQuery.getEmployeeType() ? `${patientsQuery.getTargetPatient().firstName} ${patientsQuery.getTargetPatient().lastName}` : authQuery.getName();
        const patientId = !!authQuery.getEmployeeType() ? patientsQuery.getTargetPatient().id : authQuery.getPatientId();

        const title = `Export Appointments`

        const message = `Do you want to export past appointments to a PDF document?`

        confirmService.confirm(
            title,
            message
        ).subscribe(
            () => {
                appointmentsService.downloadPastAppointments(state.appointments, patientFullName, patientId)
            }
        );

    }

    const handleGoBack = useCallback(() =>{
        setState({...state,selectedNote:null})
    },[state.selectedNote])

    useEffect(useEffectCB, []);

    const renderNote = useCallback(() => {

        let viewComponent;
        let props = {};

        if(!!authQuery.getEmployeeType()) {
            const patientId = patientsQuery.getTargetPatient().id;
            switch (state.selectedNote?.type) {
                case NotesType.Initial:
                    viewComponent = InitialConsultComponent;
                    props = {
                        note: state.selectedNote,
                        patientId: patientId,
                        handleGoToNotes: handleGoBack,
                    }
                    break;
                case NotesType.FollowUp:
                    viewComponent = FollowUpComponent;
                    props = {
                        note: state.selectedNote,
                        patientId: patientId,
                        handleGoToNotes: handleGoBack,
                    }
                    break;
                case NotesType.Blank:
                    viewComponent = PatientBlankPage;
                    props = {
                        noteId: state.selectedNote.id
                    }
                    break;
                case NotesType.Soap:
                    viewComponent = SoapNoteComponent;
                    props = {
                        patientId: patientId,
                        handleGoBack: handleGoBack,
                        goBackTitle: 'Back to appointments',
                        note: state.selectedNote
                    }
                    break;
                case NotesType.HistoryAndPhysicalInitial:
                    viewComponent = HistoryAndPhysicalNoteComponent;
                    props = {
                        patientId: patientId,
                        handleGoBack: handleGoBack,
                        goBackTitle: 'Back to appointments',
                        note: state.selectedNote
                    }
                    break;
                case NotesType.HistoryAndPhysicalFollowUp:
                    viewComponent = HistoryAndPhysicalFollowUpNoteComponent;
                    props = {
                        patientId: patientId,
                        handleGoBack: handleGoBack,
                        goBackTitle: 'Back to appointments',
                        note: state.selectedNote
                    }
                    break;
                default:
                    return;
            }
            return React.createElement(viewComponent, props);
        }
        else {
            switch (state.selectedNote?.type) {
                case NotesType.Initial:
                   navigationService.toPatientInitialConsult(history,state.selectedNote.id)
                    break;
                case NotesType.FollowUp:
                    navigationService.toPatientFollowUp(history,state.selectedNote.id)
                    break;
                case NotesType.Blank:
                    navigationService.toPatientBlank(history,state.selectedNote.id)
                    break;
                case NotesType.Soap:
                    navigationService.toPatientSoap(history,state.selectedNote.id)
                    break;
                case NotesType.HistoryAndPhysicalInitial:
                    navigationService.toPatientHistoryAndPhysical(history,state.selectedNote.id)
                    break;
                case NotesType.HistoryAndPhysicalFollowUp:
                    navigationService.toPatientHistoryAndPhysicalFollowup(history,state.selectedNote.id)
                    break;
                default:
                    return;
            }
        }
    }, [state.selectedNote])

    return [state, handleViewNotes, handleDownloadPastAppts, <Box> {renderNote()}</Box>];
}