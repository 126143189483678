import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Subscription } from "recompose";
import { navigationService } from "../../../../services/navigation.service";
import { onEmit } from "../../../common/helpers/on-emit";
import {
    ConversationAuthorModel,
    ConversationType
} from "../../models/conversation.models";
import { patientConversationsQuery } from "../../stores/patientConversationsStore/patientConversations.query";

interface StaffChatComponentState {
    author: ConversationAuthorModel;
}


export function useFacade(targetConversationId?: number): [
    StaffChatComponentState,
    () => void,
] {
    const history = useHistory();
    const [state, setState] = useState({
        author: null,
    } as StaffChatComponentState);

    const handleNavigateToChat = () => {
        navigationService.toChat(history, ConversationType.HealthCare)
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<ConversationAuthorModel>(patientConversationsQuery.author$, author =>
                setState(state => ({...state, author: author}))
            ),
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [targetConversationId]);

    return [
        state,
        handleNavigateToChat,
    ];
}
