import { RegisterPatientModel } from "../../patients/models/patient.model";

export function getFBTraits(patientInfo: RegisterPatientModel, universalId = null) {
  var traits = {
    address: {
      city: patientInfo.shippingAddress.city,
      postalCode: patientInfo.shippingAddress.zipCode,
      state: patientInfo.shippingAddress.state
    },
    birthday: patientInfo.birthday,
    email: patientInfo.email,
    firstName: patientInfo.firstName,
    lastName: patientInfo.lastName,
    phone: patientInfo.phoneNumber
  }
  if (universalId) {
    traits['userId'] = universalId;
  }
  return traits;
}