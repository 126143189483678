import {useEffect, useState} from "react";
import {Subscription} from "rxjs";
import {onEmit} from "../../../common/helpers/on-emit";
import {useMediaQuery, useTheme} from "@material-ui/core";
import { patientsService } from "../../../patients/services/patients.service";
import { PatientTaskModel } from "../../../patients/models/patient.model";
import { patientsQuery } from "../../../patients/stores/patientsStore";

interface MyTasksWidgetComponentState {
    isLoading: boolean;
    tasks: PatientTaskModel[];
    currentPage: number;
    pages: number;
}

export function useFacade(
    patientId: number | null
): [
    boolean,
    number,
    number,
    PatientTaskModel[],
    (page: number) => void,
] {
    const [state, setState] = useState({
        isLoading: true,
        tasks: [],
        currentPage: 1,
        pages: 1,
    } as MyTasksWidgetComponentState);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const pageSize = 1;

    const calculatePages = (goals: PatientTaskModel[]) => {
        let pages = Math.trunc(goals.length / pageSize);
        if (goals.length % pageSize !== 0) {
            pages++;
        }

        return pages;
    }

    const getCurrentTasks = () => {
        if (isSmallScreen) {
            return state.tasks;
        }

        return state.tasks?.slice(
            (state.currentPage - 1) * pageSize,
            state.currentPage * pageSize
        );
    };

    const handleNavigate = (page: number) => {
        setState((state) => ({ ...state, currentPage: page }));
    };

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<PatientTaskModel[]>(
                patientsQuery.patientTasks$,
                (tasks) => {
                    setState((state) => ({
                        ...state,
                        tasks: tasks,
                        pages: calculatePages(tasks),
                        currentPage: 1
                    }));
                }
            ),
        ];

        const cb = () => setState((state) => ({ ...state, isLoading: false }));

        if (patientId) {
            patientsService.getPatientTasksByEmployee(patientId).subscribe(cb, cb);
        } else {
            patientsService.getPatientTasks().subscribe(cb, cb);;
        }

        return () => {
            subscriptions.map((it) => it.unsubscribe());
        };
    }, [patientId]);

    return [
        state.isLoading,
        state.currentPage,
        state.pages,
        getCurrentTasks(),
        handleNavigate,
    ];
}