import { GeneralValidator } from "../../../common/validation/general-validator";
import {
    addressValidationRules,
    newBirthdayValidationRule,
    adultValidationRule,
    emailValidationRule,
    firstNameValidationRule,
    functionValidationRule,
    lastNameValidationRule,
    notWhitespaceValidationRule,
    phoneNumberValidationRule,
    regexpValidationRule,
    stringLengthValidationRule
} from "../../../common/validation/general-validation-rules";
import { Gender } from "../../../common/models/user.models";

export const finishCheckoutPreauthorizeComponentValidator = new GeneralValidator({
    'firstName': [firstNameValidationRule, notWhitespaceValidationRule()],
    'lastName': [lastNameValidationRule, notWhitespaceValidationRule()],
    'email': [emailValidationRule],
    'birthday': [adultValidationRule, newBirthdayValidationRule],
    'gender': [functionValidationRule((value) => {
        if (+value === Gender.Female) return true;
        if (+value === Gender.Male) return true;
        return false;
    }, "Gender should be selected.")],
    'state': [functionValidationRule((value) => {
        return value;
    }, "State should be selected.")],
    'phoneNumber': [phoneNumberValidationRule],
    'password': [
        functionValidationRule((p: string) => { return !p.includes(" ") }, "Password should not contain spaces."),
        regexpValidationRule(new RegExp("(?=.*[0-9])"), "Password should contain at least one digit."),
        regexpValidationRule(new RegExp("(?=.*[a-z])"), "Password should contain at least one lower case."),
        regexpValidationRule(new RegExp("(?=.*[A-Z])"), "Password should contain at least one upper case."),
        stringLengthValidationRule(8, null, 'Password should contain at least 8 characters.'),
        notWhitespaceValidationRule()
    ],
    'confirmPassword': [stringLengthValidationRule(1, null, 'Confirm password is required.'), notWhitespaceValidationRule()],

    // billingAddress form
    'billingAddress.streetAddress1': [addressValidationRules.streetAddress1, notWhitespaceValidationRule()],
    'billingAddress.streetAddress2': [addressValidationRules.streetAddress2, notWhitespaceValidationRule()],
    'billingAddress.city': [addressValidationRules.city, notWhitespaceValidationRule()],
    'billingAddress.state': [addressValidationRules.state, notWhitespaceValidationRule()],
    'billingAddress.country': [addressValidationRules.country, notWhitespaceValidationRule()],
    'billingAddress.zipCode': [addressValidationRules.zipCode, notWhitespaceValidationRule()],

    // shippingAddress form
    'shippingAddress.streetAddress1': [addressValidationRules.streetAddress1, notWhitespaceValidationRule()],
    'shippingAddress.streetAddress2': [addressValidationRules.streetAddress2, notWhitespaceValidationRule()],
    'shippingAddress.city': [addressValidationRules.city, notWhitespaceValidationRule()],
    'shippingAddress.state': [addressValidationRules.state, notWhitespaceValidationRule()],
    'shippingAddress.country': [addressValidationRules.country, notWhitespaceValidationRule()],
    'shippingAddress.zipCode': [addressValidationRules.zipCode, notWhitespaceValidationRule()]
})