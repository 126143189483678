import React from "react";
import { AuthenticatedLayoutComponent } from "../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent";
import { PagesNamesService } from "../../../../constants/pagesNames.constants";
import { TitlesService } from "../../../../constants/title.constants";
import { MenuItemTypes } from "../../../../models/menu.models";
import { DataFilesComponent } from "../../components/dataFilesComponent/DataFilesComponent";

export const PatientDataFilesPage: React.FC = () => {
    return (
        <AuthenticatedLayoutComponent
            title={TitlesService.dataFilesInputsTitle}
            pageName={PagesNamesService.results}
            selectedMenuItem={MenuItemTypes.DataFilesInputs}
        >
            <DataFilesComponent/>
        </AuthenticatedLayoutComponent>
    )
}
