import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
            paddingTop: '30px !important',
            paddingLeft: '40px !important',
            paddingRight: '40px !important',
            paddingBottom: '30px !important',
            [theme.breakpoints.down("xs")]: {
                paddingLeft: '0px !important',
                paddingRight: '0px !important',
                paddingBottom: '0px !important',
                '& .MuiDialog-paperFullScreen': {
                    borderRadius: '20px 20px 0 0',
                },
            },
        },
        dialogContent: {
            paddingLeft: 40,
            paddingRight: 40,
            paddingBottom: 30,
            [theme.breakpoints.down("xs")]: {
                padding: 16,
            },
        },
        dialogTitle: {
            padding: 24,
        },
        fixedButton: {
            fill: colors.main,
            width: 58,
            height: 58
        },
    })
);
