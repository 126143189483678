import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from "../../constants/colors";

const drawerWidth = 170;
const drawerCloseWidth = 44;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            overflow: 'hidden',
            minHeight: 300,
            marginRight: 30,
        },
        drawerPaper: {
            border: "none",
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            width: drawerCloseWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        logo: {
            maxHeight: 35,
        },
        menuList: {
            overflowX: 'hidden',
            overflowY: 'auto',
            height: '100%',
            width: '100%',
        },
        menuIconSize: {
            width: 20,
            height: 20,
        },
        menuIcon: {
            minWidth: 0,
        },
        badgeIcon: {
            minWidth: 0,
            paddingLeft: 6,
            '& > span': {
                marginTop: -12,
            }
        },
        menuItemText: {
            fontWeight: 400,
        },
        menuItemTextSelected: {
            fontWeight: 500,
        },
        menuItem: {
            borderRadius: 4,
            margin: '5px 0px',
            padding: '5px 10px',
            color: colors.gray1,
            fontSize: 16,
            lineHeight: 19,
            fontFamily: [
                'Be Vietnam Pro',
            ].join(','),
            '&:hover': {
                color: colors.main,
                opacity: 0.8,
            },
            '&:selected': {
                color: colors.main,
            },
            '&:focus': {
                color: colors.main,
            },
        },
        menuItemSelected: {
            background: `${colors.separators} !important`,
            color: colors.main,
        },
        content: {
            overflow: 'hidden',
            maxWidth: '100%',
            flexGrow: 1,
        },
        nested: {
            paddingLeft: theme.spacing(2),
        },
        title: {
            color: colors.black,
            fontSize: 24,
            fontWeight: 600,
        }
    })
);
