import React from "react";
import {
    Avatar,
    Box,
    Dialog,
    FormControl,
    TextField
} from "@material-ui/core";
import moment from 'moment';
import { useFacade } from "./commentsDialogComponent.hooks";
import { DialogTitle } from "../../../common/components/dialogTitle/DialogTitle";
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { toCurrentTimeZone } from "../../../timezones/helpers/timezone";
import { authQuery } from "../../../auth/stores/auth";
import { CommentModel, LeftByType } from "../../models/comment.models";

interface CommentsDialogComponentProps {
    isOpen: boolean;
    universalId: string;
    handleClose: () => void;
}

export const CommentsDialogComponent: React.FC<CommentsDialogComponentProps> = (props: CommentsDialogComponentProps) => {

    const {
        isOpen,
        universalId,
        handleClose
    } = props;

    const [
        {
            isLoading,
            isSaving,
            isNewComment,
            comments,
            initialComment,
            selectCommentId,
            selectedComment,
            canSubmit,
            canUpdate
        },
        handleChangeComment,
        handleUpdateComment,
        handleNewAdd,
        handleEdit,
        handleCancel,
        handleSubmit,
        handleUpdate,
        handleDelete
    ] = useFacade(universalId);

    const renderName = (comment: CommentModel) => {
        switch (comment.leftByType) {
            case LeftByType.Clarity:
                return 'Clarity';
            default:
                return comment.leftBy ?? 'Unknown';
        }
    }

    const renderContent = () => {
        if (isLoading) {
            return (
                <Box p={5}>
                    <WildHealthLinearProgress />
                </Box>
            );
        }

        return (
            <Box mt={2}>
                {
                    comments && comments.length > 0
                        ? <Box>
                            {
                                comments.map(comment => {
                                    return (
                                        <Box key={comment.id} mb={2}>
                                            <Box display="flex" alignItems="center" mb={1}>
                                                <Avatar className="wh-tw-w-8 wh-tw-h-8">
                                                    {renderName(comment).trim()[0]}
                                                </Avatar>
                                                <Box ml={2} className="wh-tw-font-semibold">{renderName(comment)}</Box>
                                                <Box ml={2}>{comment.modifiedAt ? moment(toCurrentTimeZone(comment.modifiedAt)).format("MMMM DD, yyyy  h:mm a") : moment(toCurrentTimeZone(comment.createdAt)).format("MMMM DD, yyyy  h:mm a")}</Box>
                                                {comment.modifiedAt && <Box ml={2}>(Edited)</Box>}
                                            </Box>
                                            {selectCommentId === comment.id ? (
                                                <>
                                                    <FormControl color='primary' fullWidth size="small" variant="outlined">
                                                        <TextField
                                                            id="comment"
                                                            multiline
                                                            rows={2}
                                                            placeholder="Add a comment..."
                                                            variant="outlined"
                                                            value={selectedComment}
                                                            onChange={handleUpdateComment}
                                                        >
                                                        </TextField>
                                                    </FormControl>
                                                    <Box mt={2} display='flex' justifyContent='flex-end' alignItems='center'>
                                                        <Box mx={2}>
                                                            <WildHealthButton
                                                                id="selected-comment-cancel"
                                                                color="tertiary"
                                                                size="small"
                                                                onClick={() => handleCancel()}
                                                            >
                                                                Cancel
                                                            </WildHealthButton>
                                                        </Box>
                                                        <Box>
                                                            <WildHealthButton
                                                                id="update-comment"
                                                                size="small"
                                                                onClick={() => handleUpdate()}
                                                                disabled={!canUpdate}
                                                                loading={isSaving}
                                                            >
                                                                Save
                                                            </WildHealthButton>
                                                        </Box>
                                                    </Box>
                                                </>
                                            ) : (
                                                <Box ml={6}>
                                                    <Box mb={1} className="">{comment.description}</Box>
                                                    {comment.createdBy === authQuery.getId() && (
                                                        <Box display="flex" gridGap={5}>
                                                            <Box className="wh-tw-cursor-pointer" onClick={() => handleEdit(comment.id)}>Edit</Box>
                                                            <Box>{'●'}</Box>
                                                            <Box className="wh-tw-cursor-pointer" onClick={() => handleDelete(comment.id)}>Delete</Box>
                                                        </Box>
                                                    )}
                                                </Box>
                                            )}
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                        : <WildHealthPlaceHolder message="No Comments"/>
                }
            </Box>
        )
    }

    return (
        <Dialog fullWidth maxWidth="md" open={isOpen} onClose={() => handleClose()} aria-labelledby="form-dialog-title">
            <Box mt={2} mx={3} >
                <DialogTitle onClose={() => handleClose()} id='dialog-title'>Comments</DialogTitle>
            </Box>
            <Box width={1} p={3} pt={0}>
                {isNewComment ? (
                    <Box>
                        <FormControl color='primary' fullWidth size="small" variant="outlined">
                            <TextField
                                id="comment"
                                multiline
                                rows={2}
                                placeholder="Add a comment..."
                                variant="outlined"
                                value={initialComment}
                                onChange={handleChangeComment}
                            >
                            </TextField>
                        </FormControl>
                        <Box mt={2} display='flex' justifyContent='flex-end' alignItems='center'>
                            <Box mx={2}>
                                <WildHealthButton
                                    id="new-comment-cancel"
                                    color="tertiary"
                                    size="small"
                                    onClick={() => handleCancel()}
                                >
                                    Cancel
                                </WildHealthButton>
                            </Box>
                            <Box>
                                <WildHealthButton
                                    id="save-comment"
                                    size="small"
                                    onClick={() => handleSubmit()}
                                    disabled={!canSubmit}
                                    loading={isSaving}
                                >
                                    Save
                                </WildHealthButton>
                            </Box>
                        </Box>
                    </Box>
                ) : (
                    <Box display='flex' justifyContent='flex-end' alignItems='center'>
                        <Box>
                            <WildHealthButton
                                id="add-new-comment"
                                size="small"
                                onClick={() => handleNewAdd()}
                                disabled={isNewComment || isLoading || isSaving}
                            >
                                Add Comment
                            </WildHealthButton>
                        </Box>
                    </Box>
                )}
                
                {renderContent()}
            </Box>
        </Dialog>
    );
}
