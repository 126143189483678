import { Box, Button, Container, Grid, Paper } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import React from 'react';
import { TitlesService } from '../../../../constants/title.constants';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { useFacade } from "./confirmAgreementsPage.hooks";
import { AgreementsComponent } from "../../components/AgreementsComponent/AgreementsComponent";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";

export const ConfirmAgreementsPage: React.FC = () => {
    const [
        {
            isLoading,
            currentAgreement,
            currentAgreementPage
        },
        handleConfirm,
        handleDismiss
    ] = useFacade();

    const renderAgreements = () => {
        return (
            <Container maxWidth='lg'>
                <Box py={3}>
                    <CssBaseline />
                    <Paper square elevation={3}>
                        <Box pb={3}>
                            <Box>
                                <AgreementsComponent
                                    agreement={currentAgreement?.agreement}
                                    paymentPlan={currentAgreement?.paymentPlan}
                                    paymentPeriod={currentAgreement?.paymentPeriod}
                                    paymentPrice={currentAgreement?.paymentPrice}
                                    currentAgreementPage={currentAgreementPage}
                                />
                            </Box>
                            <Box
                                pt={3} px={3}
                                display="flex"
                                justifyContent="flex-end"
                            >
                                <Grid md={2} xs={6} sm={6}>
                                    <Box pr={1}>
                                        <Button
                                            id="confirm-agreements-dismiss"
                                            fullWidth
                                            onClick={() => handleDismiss()}
                                            variant="outlined">
                                            Dismiss
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid md={2} xs={6} sm={6}>
                                    <Box pl={1}>
                                        <WildHealthButton
                                            id="confirm-agreements-agree"
                                            onClick={() => handleConfirm()}
                                            fullWidth
                                        >
                                            I Agree
                                        </WildHealthButton>
                                    </Box>
                                </Grid>
                            </Box>
                        </Box>
                    </Paper>
                </Box>
            </Container>
        )
    }

    return (
        <>
            <title>{TitlesService.confirmAgreementsTitle}</title>
            {
                isLoading
                    ? <WildHealthLinearProgress />
                    : renderAgreements()
            }
        </>
    )
}
