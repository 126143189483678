import { QuestionnaireModel, QuestionnaireResultModel, SaveQuestionnaireResultModel } from '../../models/questionnaire.models'
import { followUpFormSurveyComponentValidator } from './followUpFormSurvey.validator'
import { FollowUpFormQuestionNames } from '../../static/followUpForm.static'
import { questionnaireService } from '../../services/questionnaire.service'
import { navigationService } from '../../../../services/navigation.service'
import { IErrorState } from '../../../common/validation/error-state'
import { goToError } from '../../../common/helpers/go-to-error'
import { useHistory } from 'react-router'
import { useState } from 'react'

interface QuestionnaireSurveyPageState extends IErrorState {
    questionnaire: QuestionnaireModel
    result: QuestionnaireResultModel
    isLoading: boolean
}

export function useFacade(
    result: QuestionnaireResultModel,
    questionnaire: QuestionnaireModel,
    patientId: number | null,
    handleGoBack: Function | null): [
    QuestionnaireSurveyPageState,
    (field: string, value: string) => void,
    () => void
] {
    const [state, setState] = useState({
        questionnaire: questionnaire,
        isLoading: false,
        result: result,
        errors: {}
    } as QuestionnaireSurveyPageState);

    /**
     * Validates questionnaire page
     */
    const validate = () => {
        Object.values(FollowUpFormQuestionNames).forEach(field => {
            const value = state.result.answers.find(x => x.key === field)?.value ?? '';
            followUpFormSurveyComponentValidator.validateAndSetState(state, setState, field, value);
        });
    }

    /**
     * Handles changes. Additionally does field validation
     * @param field
     * @param value
     */
    const handleChanges = (field: string, value: string) => {
        followUpFormSurveyComponentValidator.validateAndSetState(state, setState, field, value);

        const result = state.result;
        const answer = result.answers.find(x => x.key === field);
        if (answer) {
            answer.value = value;
        } else {
            result.answers.push({
                key: field,
                value: value
            });
        }

        result.answers = [...result.answers];
        setState({...state, result});
    }

    const history = useHistory();

    const handleSubmit = () => {
        validate();

        if (!followUpFormSurveyComponentValidator.stateIsValid(state)) {
            goToError(state);
            return;
        }
        setState({...state, isLoading: true});

        if (patientId) {
            questionnaireService.submitAsEmployee(getSaveModel(state.result)).subscribe(() => {
                if (handleGoBack) {
                    handleGoBack();
                }
            })
        } else {
            questionnaireService.submitAsPatient(getSaveModel(state.result)).subscribe(() => {
                questionnaireService.getNotificationAvailable().subscribe();
                navigationService.toMyHealthForms(history);
            })
        }
    }

    const getSaveModel = (result: QuestionnaireResultModel): SaveQuestionnaireResultModel => {
        return Object.assign(result, {
            answers: result.answers,
            questionnaireResultId: result.id,
            patientId: patientId
        });
    }

    return [
        state, 
        handleChanges, 
        handleSubmit
    ]
}
