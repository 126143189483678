import React from 'react';
import {WildHealthTabMenuControl} from "../../../common/components/wildHealthTabMenuControl/WildHealthTabMenuControl";
import {TabItem} from '../../../common/components/wildHealthTabMenuControl/wildHealthTabMenuControl.hooks';
import {OrdersComponent} from '../../../orders/components/ordersComponent/OrdersComponent';
import {HealthReportComponent} from '../../../healthReport/components/healthReportComponent/HealthReportComponent';
import {DataFilesComponent} from "../../../inputs/components/dataFilesComponent/DataFilesComponent";
import {GeneralInputsComponent} from "../../../inputs/components/generalInputsComponent/GeneralInputsComponent";
import {LabInputsComponent} from "../../../inputs/components/labInputsComponent/labInputsComponent";
import {
    MicrobiomeInputsComponent
} from "../../../inputs/components/microbiomeInputsComponent/MicrobiomeInputsComponent";
import {ManageNotesComponent} from '../../../notes/components/manageNotes/ManageNotesComponent';
import {
    PatientMedicationsSupplementsTabsComponent
} from '../../../patientSupplements/components/patientMedicationsSupplementsTabs/PatientMedicationsSupplementsTabsComponent';
import {
    QuestionnairesComponent
} from "../../../questionnaire/components/questionnairesComponent/QuestionnairesComponent";
import {PatientProfileComponent} from '../patientProfileComponent/PatientProfileComponent';
import {useFacade} from './managePatientProfileComponent.hooks';
import {
    PatientPastAppointmentsComponent
} from '../../../appointments/components/patientPastAppointmentsComponent/PatientPastAppointmentsComponent';
import {
    PatientUpcommingAppointmentsComponent
} from '../../../appointments/components/patientUpcommingAppointmentsComponent/PatientUpcommingAppointmentsComponent';
import {WidgetContainerComponent} from "../../../widgets/components/widgetContainerComponent/WidgetContainerComponent";
import {WildHealthContainer} from '../../../common/components/wildHealthContainer/WildHealthContainer';
import {
    PatientConversationsViewComponent
} from '../../../conversations/components/patientConversationsViewComponent/PatientConversationsViewComponent';
import {HealthSummaryComponent} from '../../../healthSummary/components/healthSummaryComponent/HealthSummaryComponent';
import {DocumentsComponent} from '../../../documents/components/documentsComponent/DocumentsComponent';
import PersonIcon from "../../../../components/iconComponent/PersonIcon";
import DashboardIcon from "../../../../components/iconComponent/DashboardIcon";
import HealthScoreIcon from "../../../../components/iconComponent/HealthScoreIcon";
import ConversationsIcon from "../../../../components/iconComponent/ConversationIcon";
import MedicalHistoryIcon from "../../../../components/iconComponent/MedicalHistoryIcon";
import AppointmentsIcon from "../../../../components/iconComponent/AppointmentsIcon";
import SupplementMedsIcon from "../../../../components/iconComponent/SupplementsMedsIcon";
import ResultsIcon from "../../../../components/iconComponent/ResultsIcon";
import HealthReportIcon from "../../../../components/iconComponent/HealthReportIcon";
import NotesIcon from "../../../../components/iconComponent/NotesIcon";
import DNAIcon from "../../../../components/iconComponent/DNAIcon";
import AcademyIcon from "../../../../components/iconComponent/AcademyIcon";
import {NewDocumentsComponent} from '../../../documents/components/newDocumentsBadgeComponent/NewDocumentsComponent';
import {
    PatientCourseHistoryComponent
} from '../../../northPassAcademy/components/patientCourseHistory/PatientCourseHistoryComponent';
import {HealthScoreComponent} from "../../../healthScore/components/healthScoreComponent/HealthScoreComponent";
import {BiometricComponent} from '../../../vital/components/biometricComponent/BiometricComponent';
import HealthPlanIcon from '../../../../components/iconComponent/HealthPlan';
import {HealthPlanComponent} from '../../../healthPlan/components/HealthPlan/HealthPlanComponent';
import {MenuItemTypes} from '../../../../models/menu.models';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {FeatureFlag} from '../../../common/components/featureFlags/featureFlags.models';
import {isFeatureFlag} from '../../../common/components/featureFlags/featureFlags';
import {AddOnReportsComponent} from '../../../healthReport/components/addOnReportsComponent/AddOnReportsComponent';
import {VisitPrepComponent} from '../../../patients/components/VisitPrepComponent/VisitPrepComponent';
import {
    PatientPlaygroundComponent
} from "../../../conversations/components/patientPlaygroundComponent/PatientPlaygroundComponent";
import PlaygroundIcon from "../../../../components/iconComponent/PlaygroundIcon";

interface ManagePatientProfileComponentProps {
    patientId: number;
    patientIntakeId: string;
    resultId?: number;
    pageName: JSX.Element
}

export const ManagePatientProfileComponent: React.FC<ManagePatientProfileComponentProps> = (props: ManagePatientProfileComponentProps) => {
    const {
        patientId,
        patientIntakeId,
        resultId = null,
        pageName
    } = props;

    const [
        {
            selectedTab
        },
        handleChangeTab
    ] = useFacade(patientId);

    const featureFlags = useFlags();
    const isHealthPlan = isFeatureFlag(featureFlags, FeatureFlag.HealthPlanDashboard);
    const isLlmPlayground = isFeatureFlag(featureFlags, FeatureFlag.LlmPlayground);

    const featureMap = new Map<MenuItemTypes, boolean>()
        .set(MenuItemTypes.HealthPlan, isHealthPlan)
        .set(MenuItemTypes.GenericPlayground, isLlmPlayground)

    const container = {
        component: WildHealthContainer,
        props: { margin: 0 },
    };

    const items: TabItem[] = [
        {
            title: 'Profile',
            type: MenuItemTypes.Profile,
            icon: (isSelected: boolean) => <PersonIcon isSelected={isSelected} />,
            content: <PatientProfileComponent patientId={patientId} patientIntakeId={patientIntakeId} />,
            container,
        },
        {
            title: 'AI Chat',
            type: MenuItemTypes.GenericPlayground,
            icon: (isSelected: boolean) => <PlaygroundIcon isSelected={isSelected} />,
            content: <PatientPlaygroundComponent patientId={patientId} />
        },
        {
            title: 'Dashboard',
            type: MenuItemTypes.Dashboard,
            icon: (isSelected: boolean) => <DashboardIcon isSelected={isSelected} />,
            content: <WidgetContainerComponent patientId={patientId} />,
        },
        {
            title: 'Health Score',
            type: MenuItemTypes.HealthScore,
            icon: (isSelected: boolean) => <HealthScoreIcon isSelected={isSelected} />,
            content: <HealthScoreComponent patientId={patientId} />,
            disabled: false,
        },
        {
            title: 'Messaging',
            type: MenuItemTypes.Messaging,
            icon: (isSelected: boolean) => <ConversationsIcon isSelected={isSelected} />,
            content: <PatientConversationsViewComponent patientId={patientId} />,
        },
        {
            title: 'Medical History',
            type: MenuItemTypes.Questionnaire,
            icon: (isSelected: boolean) => <MedicalHistoryIcon isSelected={isSelected} />,
            children: [
                {
                    title: 'Visit Prep',
                    type: MenuItemTypes.VisitPrep,
                    content: <VisitPrepComponent patientId={patientId} />
                },
                {
                    title: 'Health Summary',
                    type: MenuItemTypes.HealthSummary,
                    content: <HealthSummaryComponent patientId={patientId} />
                },
                {
                    title: 'Health Forms',
                    type: MenuItemTypes.HealthForms,
                    content: <QuestionnairesComponent patientId={patientId} resultId={resultId} />,
                    container,
                },
                {
                    title: 'General Inputs',
                    type: MenuItemTypes.GeneralInputs,
                    content: <GeneralInputsComponent patientId={patientId} />,
                    container,
                },
                {
                    title: 'Documents',
                    type: MenuItemTypes.Documents,
                    badge: <NewDocumentsComponent patientId={patientId} />,
                    content: <DocumentsComponent patientId={patientId} />,
                    container,
                },
            ]
        },
        {
            title: 'Appointments',
            type: MenuItemTypes.Appointments,
            icon: (isSelected: boolean) => <AppointmentsIcon isSelected={isSelected} />,
            children: [
                {
                    title: 'Upcoming Appts',
                    type: MenuItemTypes.UpcommingAppointments,
                    content: <PatientUpcommingAppointmentsComponent patientId={patientId} />,
                    container,
                },
                {
                    title: 'Past Appts',
                    type: MenuItemTypes.PastAppointments,
                    content: <PatientPastAppointmentsComponent patientId={patientId} />,
                    container,
                },
            ]
        },
        {
            title: 'Supplements/Meds',
            type: MenuItemTypes.Supplements,
            icon: (isSelected: boolean) => <SupplementMedsIcon isSelected={isSelected} />,
            content: <PatientMedicationsSupplementsTabsComponent patientId={patientId} />,
            container,
        },
        {
            title: 'Results',
            type: MenuItemTypes.Results,
            icon: (isSelected: boolean) => <ResultsIcon isSelected={isSelected} />,
            children: [
                {
                    title: 'Labs',
                    type: MenuItemTypes.LabsInputs,
                    content: <LabInputsComponent patientId={patientId} />,
                    container,
                },
                {
                    title: 'Microbiome',
                    type: MenuItemTypes.MicrobiomeInputs,
                    content: <MicrobiomeInputsComponent patientId={patientId} />,
                    container,
                },
                {
                    title: 'Data Files',
                    type: MenuItemTypes.DataFilesInputs,
                    content: <DataFilesComponent patientId={patientId} />,
                    container,
                },
                {
                    title: 'Biometrics',
                    type: MenuItemTypes.BioData,
                    content: <BiometricComponent patientId={patientId} />,
                    container,
                },
            ],
        },
        {
            title: 'Health Report',
            type: MenuItemTypes.HealthReport,
            icon: (isSelected: boolean) => <HealthReportIcon isSelected={isSelected} />,
            children: [
                {
                    title: 'My Health Report',
                    type: MenuItemTypes.MyHealthReport,
                    content: <HealthReportComponent patientId={patientId} />,
                    container,
                },
                isFeatureFlag(featureFlags, FeatureFlag.AddOnReportPage) && {
                    title: 'Add-On Reports',
                    type: MenuItemTypes.AddOnReports,
                    content: <AddOnReportsComponent patientId={patientId} />,
                    container,
                },
            ].filter(x => x),
        },
        {
            title: 'Health Plan',
            type: MenuItemTypes.HealthPlan,
            icon: (isSelected: boolean) => <HealthPlanIcon isSelected={isSelected} />,
            content: <HealthPlanComponent patientId={patientId} />,
        },
        {
            title: 'Notes',
            type: MenuItemTypes.Notes,
            icon: (isSelected: boolean) => <NotesIcon isSelected={isSelected} />,
            content: <ManageNotesComponent patientId={patientId} />,
            container,
        },
        {
            title: 'Orders',
            type: MenuItemTypes.Orders,
            icon: (isSelected: boolean) => <DNAIcon isSelected={isSelected} />,
            content: <OrdersComponent patientId={patientId} />,
            container,
        },
        {
            title: 'Academy',
            type: MenuItemTypes.CourseHistory,
            icon: (isSelected: boolean) => <AcademyIcon isSelected={isSelected} />,
            content: <PatientCourseHistoryComponent patientId={patientId} />,
            container,
        },
    ];

    const filteredItems = items.filter(x => {
        if (featureMap.has(x.type)) {
            return featureMap.get(x.type);
        }

        return true;
    })

    return (
        <WildHealthTabMenuControl items={filteredItems} initTab={selectedTab} onChangeCB={handleChangeTab} pageName={pageName} />
    );
}