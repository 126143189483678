import { Query } from '@datorama/akita';
import {ProductsState, productsStore, ProductsStore} from "./products.store";

/**
 * Provides products queries
 */
export class ProductsQuery extends Query<ProductsState> {
    products$ = this.select(state => state.products)
    patientProducts$ = this.select(state => state.patientProducts)

    constructor(protected store: ProductsStore) {
        super(store);
    }
}

export const productsQuery = new ProductsQuery(productsStore);
