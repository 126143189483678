import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles(() =>
    createStyles({
        title: {
            fontSize: 20,
            fontWeight: 500
        },
        subTitle: {
            color: colors.gray1,
            fontSize: 14,
            fontWeight: 400,
            paddingBottom: 10,
        },
        infoBox: {
            display: 'flex',
            width: '100%',
            height: 48,
            border: `1px solid ${colors.gray4}`,
            backgroundColor: colors.disabled,
            marginBottom: 16,
            color: colors.gray2,
            alignItems: 'center',
            paddingLeft: 20,
        },
        bgMain: {
            backgroundColor: colors.bg,
            width: '100%'
        },
        chip: {
            display: 'flex',
            gap: 6,
            alignItems: 'center',
            height: 27,
            margin: 2,
            lineHeight: '18px',
            backgroundColor: colors.separators,
            borderRadius: 2,
            padding: '4px 6px',
            overflow: 'hidden',
            fontSize: 16,
            fontWeight: 400,
            color: colors.black,
            '& svg': {
              fontSize: 18,
              cursor: 'pointer',
              fill: colors.gray1,
              margin: 0,
            },
            '& .MuiChip-label': {
              padding: 0,
            },
          },
    })
);
