import {
    Box,
    Checkbox,
    CircularProgress, Collapse,
    Dialog,
    DialogActions,
    DialogContent, Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@material-ui/core";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import React from 'react';
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { displayLocation } from "../../../locations/helpers/displayLocation";
import { useFacade } from "./createEmployeeButtonComponent.hooks";
import { useStyles } from "./createEmployeeButtonComponent.styles";
import { ProtectedElement } from "../../../common/components/ProtectedElement";
import { PermissionType, UserType } from "../../../auth/models/auth.enums";
import { Add } from "@material-ui/icons";
import { isProviderRole } from "../../../common/constants/roles";
import { Gender } from "../../../common/models/user.models";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

interface CreateEmployeeButtonComponentProps {
    locationId?: number;
}

export const CreateEmployeeButtonComponent: React.FC<CreateEmployeeButtonComponentProps> = (props: CreateEmployeeButtonComponentProps) => {
    const classes = useStyles();
    const [
        {
            isLoading,
            isOpen,
            rxntExpanded,
            employee,
            permissions,
            locations,
            roles,
            errors,
        },
        handleSelectLocation,
        handleSelectRole,
        handlePermissionChange,
        handleChanges,
        handleAddCalendar,
        handleCreate,
        handleOpen,
        handleClose,
        toggleRxNT
    ] = useFacade();

    const renderSelectedLocations = (selected: number[]) => {
        return selected.map(id => displayLocation(locations.find(x => x.id === id))).join(', ')
    }

    return (
        <>
            <WildHealthButton
                id="create-employee-create-user"
                size='medium'
                onClick={() => handleOpen(props.locationId)}
            >
                <Box display="flex" alignItems="center">
                    <Add />
                    <span>Create User</span>
                </Box>
            </WildHealthButton>

            <Dialog
                onClose={() => handleClose()}
                className={classes.root}
                open={isOpen}
            >
                <MuiDialogTitle
                    className={classes.dialogTitle}
                    disableTypography
                >
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <Typography variant="h6">New User</Typography>
                    </Grid>
                    <IconButton
                        id="create-employee-close"
                        className={classes.dialogTitleCloseAction}
                        onClick={() => handleClose()}
                    >
                        <CloseIcon />
                    </IconButton>
                </MuiDialogTitle>

                <DialogContent dividers>
                    <Box mb={1}>
                        <TextField
                            required
                            fullWidth
                            size="small"
                            variant="outlined"
                            value={employee.firstName}
                            label="First Name"
                            className={clsx(classes.field, 'input')}
                            error={!!errors['firstName']}
                            helperText={errors['firstName']}
                            onChange={(event) => handleChanges('firstName', event.target.value)}
                        />
                        <TextField
                            required
                            fullWidth
                            size="small"
                            variant="outlined"
                            value={employee.lastName}
                            label="Last Name"
                            className={clsx(classes.field, 'input')}
                            error={!!errors['lastName']}
                            helperText={errors['lastName']}
                            onChange={(event) => handleChanges('lastName', event.target.value)}
                        />
                        <TextField
                            required
                            fullWidth
                            size="small"
                            variant="outlined"
                            value={employee.email}
                            label="Email"
                            className={clsx(classes.field, 'input')}
                            error={!!errors['email']}
                            helperText={errors['email']}
                            onChange={(event) => handleChanges('email', event.target.value)}
                        />

                        <FormControl
                            error={!!errors['locationIds']}
                            className={classes.field}
                            color='primary'
                            variant="outlined"
                            size="small"
                            >
                            <InputLabel>
                                Pod
                            </InputLabel>
                            <ProtectedElement
                                userType={UserType.Employee}
                                element={
                                    <Select
                                        multiple
                                        label="Location"
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                        }}
                                        value={employee?.locationIds ?? []}
                                        onChange={handleSelectLocation}
                                        error={!!errors['locationIds']}
                                        renderValue={renderSelectedLocations}>
                                        {
                                            locations
                                                .map((location, index) =>
                                                    <MenuItem key={index} value={location.id}>
                                                        <Checkbox
                                                            className={classes.permissionCheck}
                                                            checked={employee?.locationIds?.includes(location.id)}
                                                            color="default"
                                                        />
                                                        {
                                                            displayLocation(location)
                                                        }
                                                    </MenuItem>
                                                )
                                        }
                                    </Select>
                                }
                                defaultContent={
                                    <Select  label="Pod" value={employee?.locationIds ?? []} disabled={true}>
                                        {
                                            locations
                                                .map((location, index) =>
                                                    <MenuItem key={index} value={location.id}>
                                                        {
                                                            displayLocation(location)
                                                        }
                                                    </MenuItem>
                                                )
                                        }
                                    </Select>
                                }
                                permissions={[PermissionType.MigrationBetweenLocations]} />
                            <FormHelperText>{errors[`locationIds`]}</FormHelperText>
                        </FormControl>
                        <FormControl
                            className={classes.field}
                            color='primary'
                            variant="outlined"
                            size="small"
                        >
                            <InputLabel>
                                Role
                            </InputLabel>
                            <Select
                                label="Role"
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                                value={employee?.roleId}
                                onChange={handleSelectRole}
                            >
                                {
                                    roles &&
                                    roles
                                        .map((item, index) =>
                                            <MenuItem key={index} value={item.id}>
                                                {item.displayName}
                                            </MenuItem>
                                        )
                                }
                            </Select>
                        </FormControl>
                        <TextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            value={employee.credentials}
                            label="Credentials"
                            className={clsx(classes.field, 'input')}
                            error={!!errors['credentials']}
                            helperText={errors['credentials']}
                            onChange={(event) => handleChanges('credentials', event.target.value)}
                        />
                        {isProviderRole(employee.roleId) && (
                            <TextField
                                fullWidth
                                size="small"
                                variant="outlined"
                                value={employee.npi}
                                label="NPI"
                                className={clsx(classes.field, 'input')}
                                error={!!errors['npi']}
                                helperText={errors['npi']}
                                onChange={(event) => handleChanges('npi', event.target.value)}
                            />
                        )}
                        <FormControlLabel
                            className={classes.field}
                            control={<Checkbox
                                className={classes.permissionCheck}
                                checked={employee.registerInSchedulerSystem}
                                onChange={() => handleAddCalendar(!employee.registerInSchedulerSystem)}
                                color="default"
                            />}
                            value={employee.registerInSchedulerSystem}
                            label="Add Calendar"
                        />
                    </Box>
                    <Box mb={1}>
                        <FormControl
                            className={classes.field}
                            color='primary'
                            variant="outlined"
                            size="small"
                        >
                            <InputLabel>
                                Gender
                            </InputLabel>
                            <Select
                                label="Gender"
                                value={employee.gender}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                                onChange={(event) => handleChanges('gender', event.target.value)}
                            >
                                <MenuItem value={Gender.Male}>{Gender[Gender.Male]}</MenuItem>
                                <MenuItem value={Gender.Female}>{Gender[Gender.Female]}</MenuItem>
                                <MenuItem value={Gender.None}>{Gender[Gender.None]}</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    
                    <Divider/>
                    <Box display="flex" justifyContent="flex-start" alignItems="center" py={2}>
                        <Box id={`rxnt-credentials-section-expand`} onClick={() => toggleRxNT()} className="wh-tw-cursor-pointer">
                            {rxntExpanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                        </Box>
                        <Box ml={1}>
                            <span className={classes.sectionTitle}>RxNT credentials</span>
                        </Box>
                    </Box>
                    <Collapse in={rxntExpanded} timeout="auto" unmountOnExit>
                        <Box mb={1}>
                            <TextField
                                required
                                fullWidth
                                size="small"
                                variant="outlined"
                                autoComplete="false"
                                value={employee.rxntUserName}
                                label="RxNT Login"
                                className={clsx(classes.field, 'input')}
                                error={!!errors['rxntUserName']}
                                helperText={errors['rxntUserName']}
                                onChange={(event) => handleChanges('rxntUserName', event.target.value)}
                            />
                            <TextField
                                required
                                fullWidth
                                size="small"
                                variant="outlined"
                                type="password"
                                autoComplete="false"
                                value={employee.rxntPassword}
                                label="RxNT Password"
                                className={clsx(classes.field, 'input')}
                                error={!!errors['rxntPassword']}
                                helperText={errors['rxntPassword']}
                                onChange={(event) => handleChanges('rxntPassword', event.target.value)}
                            />
                        </Box>
                    </Collapse>
                    <Divider/>

                    <Box ml={1} mr={1} mb={5} pt={2}>
                        <Box>
                            <span className={classes.sectionTitle}>Permissions</span>
                        </Box>
                        {
                            permissions &&
                            permissions
                                .map((item, index) => {
                                    const control = (
                                        <Checkbox
                                            className={classes.permissionCheck}
                                            checked={employee.permissions.includes(item.id)}
                                            onChange={handlePermissionChange}
                                            color="default"
                                        />
                                    );

                                    return (
                                        <FormControlLabel
                                            className={classes.permission}
                                            control={control}
                                            value={item.id}
                                            label={item.displayName}
                                            key={index}
                                        />
                                    );
                                })
                        }
                    </Box>
                </DialogContent>

                <DialogActions>
                    <WildHealthButton
                        id="create-employee-cancel"
                        onClick={() => handleClose()}
                        color="tertiary"
                        disabled={isLoading}
                    >
                        Cancel
                    </WildHealthButton>
                    <WildHealthButton
                        id="create-employee-send-invite"
                        onClick={handleCreate}
                        autoFocus
                        disabled={isLoading}
                    >
                        {
                            isLoading
                                ? <CircularProgress size={24} className={classes.progress} />
                                : ' Send Invite'
                        }
                    </WildHealthButton>
                </DialogActions>
            </Dialog>
        </>
    );
}
