import React from "react";
import { WildHealthPlaceHolderBase } from "./WildHealthPlaceHolderBase";
import emptyMessages from "@img/placeholders/EmptyMessages.png";
import { useStyles } from "./commonPlaceHolder.styles";
import { CommonPlaceHolderProps } from "./CommonPlaceHolder";

interface MessagesPlaceHolderProps extends CommonPlaceHolderProps {
}

export const MessagesPlaceHolder: React.FC<MessagesPlaceHolderProps> = (props: MessagesPlaceHolderProps) => {
    const classes = useStyles();

    return (
        <WildHealthPlaceHolderBase message={props.message}>
            <img
                src={emptyMessages}
                alt="img" className={classes.image} />
        </WildHealthPlaceHolderBase>
    );
}
