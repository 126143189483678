import Axios from "axios-observable";
import { Observable } from "rxjs";
import { authHeader } from "../../common/helpers/auth-header";
import { snackService } from "../../common/snack/state";
import { EpigeneticOrderModel } from "../models/epigeneticOrders.models";
import { CreateOrderModel } from "../models/orders.models";
import { EpigeneticOrdersStore, epigeneticOrdersStore } from "../stores/epigeneticOrdersStore";

export class EpigeneticOrdersService {
    private url = `${process.env.REACT_APP_API_URL}Orders/Epigenetic`;

    constructor(private store: EpigeneticOrdersStore) {
    }

    public get(patientId: number): Observable<EpigeneticOrderModel[]> {
        return new Observable<EpigeneticOrderModel[]>(observer => {
            Axios.get<EpigeneticOrderModel[]>(`${this.url}?patientId=${patientId}`, { headers: authHeader() })
            .pipe()
            .subscribe(
                (response) => {
                    this.store.update({orders: response.data});
                    observer.next(response.data);
                    observer.complete();
                },
                error => {
                    snackService.commonErrorHandler(error);
                    observer.error(error);
                    observer.complete();
                },
            );
        });
    }

    public getMyOrders(): Observable<EpigeneticOrderModel[]> {
        return new Observable<EpigeneticOrderModel[]>(observer => {
            Axios.get<EpigeneticOrderModel[]>(`${this.url}/GetPatientEpigeneticOrders`, { headers: authHeader() })
            .pipe()
            .subscribe(
                (response) => {
                    this.store.update({orders: response.data});
                    observer.next(response.data);
                    observer.complete();
                },
                error => {
                    snackService.commonErrorHandler(error);
                    observer.error(error);
                    observer.complete();
                },
            );
        });
    }

    public create(model: CreateOrderModel): Observable<EpigeneticOrderModel> {
        return new Observable<EpigeneticOrderModel>(observer => {
            Axios.post<EpigeneticOrderModel>(`${this.url}`, model, { headers: authHeader() })
            .pipe()
            .subscribe(
                (response) => {
                    this.store.addOrder(response.data);
                    observer.next(response.data);
                    observer.complete();
                },
                error => {
                    snackService.commonErrorHandler(error);
                    observer.error(error);
                    observer.complete();
                },
            );
        });
    }
}

export const epigeneticOrdersService = new EpigeneticOrdersService(epigeneticOrdersStore);