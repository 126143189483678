import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderColor: colors.gray2,
      borderRadius: 2,
      '& .MuiOutlinedInput-root': {
        borderColor: colors.gray2,
        borderRadius: 2,
      },
    },
    searchIcon: {
      fill: colors.gray1,
      marginRight: 16
    },
    searchContent: {
      width: 300,
      marginRight: 24,
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      },
    },
  }),
);