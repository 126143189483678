import React from "react";
import MaskedInput from 'react-text-mask';

export const phoneNumberInput = (props) => {
    const {inputRef, ...other} = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            guide={false}
            keepCharPositions={true}
            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar="_"
            showMask
        />
    );
};