import React from 'react';
import {
    PieChart, Pie, Cell,
} from 'recharts';

interface CircleDiagramData {
    name: string;
    value: number;
    color: string;
}
interface CircleDiagramComponentProps {
    data: CircleDiagramData[];
    radius: number;
    percentColor?: string;
}

export const CircleDiagramComponent: React.FC<CircleDiagramComponentProps> = (props: CircleDiagramComponentProps) => {
    CircleDiagramComponent.defaultProps = {
        percentColor: "white"
    }

    const {
        data,
        radius,
        percentColor
    } = props;

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent}) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill={percentColor} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    return (
        <PieChart width={radius * 2 + 20} height={radius * 2 + 20}>
            <Pie
                data={data}
                cx={radius}
                cy={radius}
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={radius}
                fill="#8884d8"
                dataKey="value"
                isAnimationActive={false}
            >
                {
                    data.map((x, index) => <Cell key={`cell-${index}`} fill={x.color} />)
                }
            </Pie>
        </PieChart>
    );
}
