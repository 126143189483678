import { PagesNamesService } from './pagesNames.constants';

export class TitlesService {
    public static simpleBaseTitle = 'Wild Health';
    public static supplementsEmail = 'supplements@wildhealth.com';
    public static baseTitle = `${TitlesService.simpleBaseTitle} Clarity`;
    public static dashboard = `${TitlesService.baseTitle} - ${PagesNamesService.dashboard}`;

    public static loginTitle = `${TitlesService.baseTitle} - ${PagesNamesService.login}`;
    public static loginRxNTTitle = `${TitlesService.baseTitle} - ${PagesNamesService.loginRxNT}`;
    public static getStartedTitle = `${TitlesService.simpleBaseTitle} ${PagesNamesService.getStarted}`;
    public static buyNewSubscriptionTitle = `${TitlesService.baseTitle} - ${PagesNamesService.buyNewSubscription}`;
    public static accountMembershipActivationTitle = `${TitlesService.baseTitle} - ${PagesNamesService.accountMembershipActivation}`;
    public static paymentSuccessTitle = `${TitlesService.baseTitle} - ${PagesNamesService.paymentSuccess}`;
    public static healthQuestionnaireTitle = `${TitlesService.baseTitle} - ${PagesNamesService.healthQuestionnaire}`;
    public static healthQuestionnaireCompletedTitle = `${TitlesService.baseTitle} - ${PagesNamesService.healthQuestionnaireCompleted}`;
    public static startQuestionnaireTitle = `${TitlesService.baseTitle} - ${PagesNamesService.startQuestionnaire}`;

    public static restorePasswordTitle = `${TitlesService.baseTitle} - ${PagesNamesService.restorePassword}`;
    public static forgotPasswordTitle = `${TitlesService.baseTitle} - ${PagesNamesService.forgotPassword}`;
    public static activateProfileTitle = `${TitlesService.baseTitle} - ${PagesNamesService.activateProfile}`;
    public static confirmAgreementsTitle = `${TitlesService.baseTitle} - ${PagesNamesService.confirmAgreements}`;
    public static termsOfUseTitle = `${TitlesService.baseTitle} - ${PagesNamesService.termsOfUse}`;
    public static privacyPolicyTitle = `${TitlesService.baseTitle} - ${PagesNamesService.privacyPolicy}`

    public static myAccountTitle = `${TitlesService.baseTitle} - ${PagesNamesService.myAccount}`;
    public static resetPasswordTitle = `${TitlesService.baseTitle} - ${PagesNamesService.resetPassword}`;
    public static myPatientsTitle = `${TitlesService.baseTitle} - ${PagesNamesService.myPatients}`;
    public static pendingNotesTitle = `${TitlesService.baseTitle} - ${PagesNamesService.pendingNotes}`;
    public static draftNotesTitle = `${TitlesService.baseTitle} - ${PagesNamesService.draftNotes}`;
    public static signOffNotesTitle = `${TitlesService.baseTitle} - ${PagesNamesService.signOffNotes}`;
    public static awaitingApprovalTitle = `${TitlesService.baseTitle} - ${PagesNamesService.awaitingApproval}`;
    public static allPatientsTitle = `${TitlesService.baseTitle} - ${PagesNamesService.allPatients}`;
    public static patientDashboardTitle = `${TitlesService.baseTitle} - ${PagesNamesService.patientDashboard}`;
    public static intakePatientsTitle = `${TitlesService.baseTitle} - ${PagesNamesService.intakePatients}`;

    public static resultsTitle = `${TitlesService.baseTitle} - ${PagesNamesService.results}`;
    public static generalInputsTitle = `${TitlesService.baseTitle} - ${PagesNamesService.generalInputs}`;
    public static documentsTitle = `${TitlesService.baseTitle} - ${PagesNamesService.documents}`;
    public static labsInputsTitle = `${TitlesService.baseTitle} - ${PagesNamesService.labsInputs}`;
    public static microbiomeInputsTitle = `${TitlesService.baseTitle} - ${PagesNamesService.microbiomeInputs}`;
    public static dataFilesInputsTitle = `${TitlesService.baseTitle} - ${PagesNamesService.dataFilesInputs}`;
    public static medicalHistoryTitle = `${TitlesService.baseTitle} - ${PagesNamesService.medicalHistory}`;
    public static appointmentsTitle = `${TitlesService.baseTitle} - ${PagesNamesService.appointments}`;
    public static questionnaireTitle = `${TitlesService.baseTitle} - ${PagesNamesService.questionnaire}`;
    public static healthReportTitle = `${TitlesService.baseTitle} - ${PagesNamesService.healthReport}`;
    public static addOnReportsTitle = `${TitlesService.baseTitle} - ${PagesNamesService.addOnReports}`;
    public static healthPlanTitle = `${TitlesService.baseTitle} - ${PagesNamesService.healthPlan}`;
    public static healthSummaryTitle = `${TitlesService.baseTitle} - ${PagesNamesService.healthSummary}`;
    public static supplementsTitle = `${TitlesService.baseTitle} - ${PagesNamesService.supplements}`;
    public static coachShortcutsTitle = `${TitlesService.baseTitle} - ${PagesNamesService.coachShortcuts}`;
    public static patientProfileTitle = `${TitlesService.baseTitle} - ${PagesNamesService.patientProfile}`;
    public static manageDeliveryOrdersTitle = `${TitlesService.baseTitle} - ${PagesNamesService.manageDeliveryOrders}`;
    public static submitPatientTitle = `${TitlesService.baseTitle} - ${PagesNamesService.submitPatient}`;
    public static healthQuestionnaireResultTitle = `${TitlesService.baseTitle} - ${PagesNamesService.healthQuestionnaireResult}`;

    public static notesTitle = `${TitlesService.baseTitle} - ${PagesNamesService.notes}`;
    public static ordersTitle = `${TitlesService.baseTitle} - ${PagesNamesService.orders}`;
    public static otherOrderTitle = `${TitlesService.baseTitle} - ${PagesNamesService.otherOrder}`;
    public static referralOrderTitle = `${TitlesService.baseTitle} - ${PagesNamesService.referralOrder}`;
    public static dnaOrderDropshipTitle = `${TitlesService.baseTitle} - ${PagesNamesService.dnaOrderDropship}`;
    public static noteDetailTitle = `${TitlesService.baseTitle} - ${PagesNamesService.noteDetail}`;
    public static schedulingTitle = `${TitlesService.baseTitle} - ${PagesNamesService.scheduling}`;
    public static employeesTitle = `${TitlesService.baseTitle} - ${PagesNamesService.employees}`;
    public static dnaFilesTitle = `${TitlesService.baseTitle} - ${PagesNamesService.dnaFiles}`;
    public static unmatchedLabsTitle = `${TitlesService.baseTitle} - ${PagesNamesService.unmatchedLabs}`;

    public static leadSourcesTitle = `${TitlesService.baseTitle} - ${PagesNamesService.leadSources}`;
    public static createInitialConsultTitle = `${TitlesService.baseTitle} - ${PagesNamesService.createInitialConsult}`;
    public static joinPracticeTitle = `${TitlesService.baseTitle} - ${PagesNamesService.joinPractice}`;
    public static joinPracticeSuccessTitle = `${TitlesService.baseTitle} - ${PagesNamesService.joinPracticeSuccess}`;

    public static fellowshipTitle = `${TitlesService.baseTitle} - ${PagesNamesService.fellowship}`;

    public static vitalsHistoryTitle = `${TitlesService.baseTitle} - ${PagesNamesService.vitalsHistory}`;
    public static messagingTitle = `${TitlesService.baseTitle} - ${PagesNamesService.messaging}`;
    public static patientsSubmissionsTitle = `${TitlesService.baseTitle} - ${PagesNamesService.patientsSubmissions}`;
    public static chatTitle = `${TitlesService.baseTitle} - ${PagesNamesService.chat}`;
    public static messageSettingsTitle = `${TitlesService.baseTitle} - ${PagesNamesService.messageSettings}`;
    public static genericPlaygroundTitle = `${TitlesService.baseTitle} - ${PagesNamesService.genericPlayground}`;

    public static referralLandingTitle = `${TitlesService.baseTitle} - ${PagesNamesService.referFriend}`;
    public static insuranceTitle = `${TitlesService.baseTitle} - ${PagesNamesService.insurance}`;
    public static patientResponsibilityTitle = `${TitlesService.baseTitle} - ${PagesNamesService.patientResponsibility}`;

    public static academyTitle = `${TitlesService.baseTitle} - ${PagesNamesService.academy}`;
    public static engineeringAdminTitle = `${TitlesService.baseTitle} - ${PagesNamesService.engineeringAdminTool}`;
    public static employerAdminTitle = `${TitlesService.baseTitle} - ${PagesNamesService.employers}`;
    public static syncRecordsTitle = `${TitlesService.baseTitle} - ${PagesNamesService.syncRecords}`;
    public static eligibleInsurancesTitle = `${TitlesService.baseTitle} - ${PagesNamesService.eligibleInsurances}`;
    public static insuranceConfigurationsTitle = `${TitlesService.baseTitle} - ${PagesNamesService.insuranceConfigurations}`;
    public static promoCodesTitle = `${TitlesService.baseTitle} - ${PagesNamesService.promoCodes}`;
    public static messagingAdminPanelTitle = `${TitlesService.baseTitle} - ${PagesNamesService.messagingAdminPanel}`;
    public static messageForwardingTitle = `${TitlesService.baseTitle} - ${PagesNamesService.messageForwarding}`;
    public static alertsHistoryTitle = `${TitlesService.baseTitle} - ${PagesNamesService.alertsHistory}`;
    public static bannersTitle = `${TitlesService.baseTitle} - ${PagesNamesService.banners}`;
    public static healthReportTemplatesTitle = `${TitlesService.baseTitle} - ${PagesNamesService.healthReportTemplates}`;

    public static commonGoalsTitle = `${TitlesService.baseTitle} - ${PagesNamesService.commonGoals}`;
    public static commonMdmsTitle = `${TitlesService.baseTitle} - ${PagesNamesService.commonMdms}`;
    public static commonSupplementsTitle = `${TitlesService.baseTitle} - ${PagesNamesService.commonSupplements}`;
    public static commonOrdersTitle = `${TitlesService.baseTitle} - ${PagesNamesService.commonOrders}`;
    public static recommendationsTitle = `${TitlesService.baseTitle} - ${PagesNamesService.recommendations}`;
    public static opsAdminToolsTitle = `${TitlesService.baseTitle} - ${PagesNamesService.opsAdminTools}`;

    public static myTasksTitle = `${TitlesService.baseTitle} - ${PagesNamesService.myTasks}`;
    public static kbDocumentsTitle = `${TitlesService.baseTitle} - ${PagesNamesService.kbDocuments}`;
    public static conversationTemplatesTitle = `${TitlesService.baseTitle} - ${PagesNamesService.conversationTemplates}`;
    public static employersProfilesTitle = `${TitlesService.baseTitle} - ${PagesNamesService.employersProfiles}`;
}
