import React from 'react';
import { AuthenticatedLayoutComponent } from '../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from "../../../../models/menu.models";
import { ManageRecommendationsComponent } from '../../components/manageRecommendationsComponent/ManageRecommendationsComponent';

export const ManageRecommendationsPage: React.FC = () => {
    return (
        <AuthenticatedLayoutComponent
            hideBackground
            title={TitlesService.recommendationsTitle}
            pageName={PagesNamesService.recommendations}
            selectedMenuItem={MenuItemTypes.Recommendations}
        >
            <ManageRecommendationsComponent />
        </AuthenticatedLayoutComponent>
    )
}
