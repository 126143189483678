export const pageSizes = [
    10,
    20,
    50,
    100
];

export const pageSizesWidget = [
    2
];

export const shortPageSizes = [
    2,
    5,
    10
];

export const mediumPageSizes = [
    7,
    14,
    30,
];

export const graphPageSizes = [
    2,
    5,
    10,
    20,
];