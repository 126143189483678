import { Box, useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { HealthReportMode, HealthReportSections } from "../models/healthReport.models";
import { HealthReportBasePage, HealthReportMobileBasePage } from "./HealthReportBasePage";
import { HealthReportPageProps } from "./healthReportPageProps";
import commonUseStyles from "../../common/styles/common.styles";
import clsx from "clsx";
import { RoutesConstants } from '../../../constants/route.constants';
import { ImageConstants } from '../../../constants/image.constants';
import img from "@img/HealthReport_Pg12_Image.png";

export const HealthReportSleep0Page: React.FC<HealthReportPageProps> = (props: HealthReportPageProps) => {
    const { pageNumber, practice } = props;
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
    return (
        props.mode !== HealthReportMode.PrintMode && isSmallScreen ? (
            <HealthReportMobileBasePage section={HealthReportSections.Sleep} pageNumber={pageNumber} practice={practice} firstPage>
                <Box px={2}>
                    <Box>
                        <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeBigReport, commonClasses.textRegularReport)}>Personalized Sleep Plan</span>
                    </Box>
                    <Box mt={1.5} fontSize="14px" lineHeight="22px">
                        <p>
                            If your sleep suffers, your performance suffers. We place such an emphasis on
                            sleep because great sleep means a great mood, energy levels and mental clarity.
                        </p>
                        <Box my="10px">
                            <p className={clsx(commonClasses.tertiaryFontReport, commonClasses.textRegularReport)}>
                                A couple of easy tweaks can be made and you’ll see a tremendous positive impact:
                            </p>
                        </Box>
                        <p>
                            1) The Oura ring offers a closer look at the architecture of your sleep.  To utilize this data with your Health Coach, set up a coaching call and plan to screen share your data. Prior to the meeting, login to your account by going to: <a id="oura-link" target="_black" href="https://cloud.ouraring.com/user/sign-in?next=%2F">https://cloud.ouraring.com/user/sign-in?next=%2F</a>
                        </p>
                        <p>
                            2) The foundation of good sleep is proper sleep hygiene. This includes reducing exposure to
                            blue light after dark, think LED lights, screens and devices. When exposure to blue light is
                            unavoidable we recommend using blue light blocking glasses. Reduced exposure to blue light
                            after dark supports natural melatonin production and parasympathetic nervous system activation.
                        </p>
                        <p>
                            3) In our opinion these are the two best books that provide evidence based insight into why
                            sleep is so important and how to improve yours! By the way, reading before bed is a great way
                            to reduce screen time and blue light exposure.
                        </p>
                        <p>-The Power of When</p>
                        <p>-Why We Sleep by Matthew Walker</p>
                    </Box>
                    <Box mt="30px">
                        <img src={RoutesConstants.cdnBaseLink + ImageConstants.healthReportPg12ImagePath} style={{ width: '100%', height: 'auto' }} alt="img" />
                    </Box>
                </Box>
            </HealthReportMobileBasePage>
        ) : (
            <HealthReportBasePage section={HealthReportSections.Sleep} pageNumber={pageNumber} practice={practice}>
                <Box px={15} pt={5}>
                    <Box>
                        <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeBigReport, commonClasses.textMediumReport)}>Personalized Sleep Plan</span>
                    </Box>
                    <Box mt={1}>
                        <p>
                            If your sleep suffers, your performance suffers. We place such an emphasis on
                            sleep because great sleep means a great mood, energy levels and mental clarity.
                        </p>
                        <p className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeMediumReport, commonClasses.textMediumReport)}>
                            A couple of easy tweaks can be made and you’ll see a tremendous positive impact:
                        </p>
                        <p>
                            1) The Oura ring offers a closer look at the architecture of your sleep.  To utilize this data with your Health Coach, set up a coaching call and plan to screen share your data. Prior to the meeting, login to your account by going to: <a id="oura-link" target="_black" href="https://cloud.ouraring.com/user/sign-in?next=%2F">https://cloud.ouraring.com/user/sign-in?next=%2F</a>
                        </p>
                        <p>
                            2) The foundation of good sleep is proper sleep hygiene. This includes reducing exposure to
                            blue light after dark, think LED lights, screens and devices. When exposure to blue light is
                            unavoidable we recommend using blue light blocking glasses. Reduced exposure to blue light
                            after dark supports natural melatonin production and parasympathetic nervous system activation.
                        </p>
                        <p>
                            3) In our opinion these are the two best books that provide evidence based insight into why
                            sleep is so important and how to improve yours! By the way, reading before bed is a great way
                            to reduce screen time and blue light exposure.
                        </p>
                        <p>-The Power of When</p>
                        <p>-Why We Sleep by Matthew Walker</p>
                    </Box>
                    <Box mt={10}>
                        {
                            props.mode === HealthReportMode.PrintMode
                                ? <img src={img} style={{ width: '100%' }} alt="img" />
                                : <img src={RoutesConstants.cdnBaseLink + ImageConstants.healthReportPg12ImagePath} style={{ width: '100%' }} alt="img" />
                        }
                    </Box>
                </Box>
            </HealthReportBasePage>
        )
    )
}
