import {
  OnboardingConfigurationState,
  OnboardingConfigurationStore,
  onboardingConfigurationStore
} from './onboardingConfiguration.store';
import { Query } from '@datorama/akita';

/**
* Provides onboarding configuration queries
*/
export class OnboardingConfigurationQuery extends Query<OnboardingConfigurationState> {

  consultationFormId$ = this.select(state => state.consultationFormId);
  practiceId$ = this.select(state => state.practiceId);

  constructor(protected store: OnboardingConfigurationStore) {
    super(store);
  }

  /**
   * Returns consultation type_form id
   */
  getConsultationFormId(): string {
    return this.getValue().consultationFormId;
  }
}

export const onboardingConfigurationQuery = new OnboardingConfigurationQuery(onboardingConfigurationStore);