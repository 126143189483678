import { Box, Dialog, DialogContent, FormControlLabel, Checkbox, TextField, Button } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import commonUseStyles from "../../../common/styles/common.styles";
import { useFacade, CreateDnaOrderStep } from "./newDnaOrderDialogComponent.hooks";
import { useStyles } from "./newDnaOrderDialogComponent.styles";
import { AddOnModel } from '../../../addons/models/addOns.models';
import { DialogTitle } from '../../../common/components/dialogTitle/DialogTitle';
import { colors } from '../../../common/constants/colors';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { ChevronLeft } from '@material-ui/icons';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { displayMoney } from '../../../common/helpers/display-money';

export interface NewDnaOrderDialogComponentProps {
    open: boolean;
    patientId: number;
    addOns: AddOnModel[];
    handleClose: Function;
    handleSubmit: Function;
}

export const NewDnaOrderDialog: React.FC<NewDnaOrderDialogComponentProps> = (props: NewDnaOrderDialogComponentProps) => {
    const {
        open,
        patientId,
        addOns,
        handleClose,
        handleSubmit
    } = props;

    const [
        {
            step,
            isManual,
            params,
            errors
        },
        handleChanges,
        handleToggleManual,
        handleGoBack,
        handleNextStep,
        handleCancel,
        handleConfirm
    ] = useFacade(patientId, addOns, handleSubmit, handleClose);

    const classes = useStyles();
    const commonClasses = commonUseStyles();
    

    const renderTitle = () => {
        switch (step) {
            case CreateDnaOrderStep.SelectShipOption:
                return "Select shipment option";
            case CreateDnaOrderStep.ShippedKit:
                return "Shipped Kit";
            case CreateDnaOrderStep.ManualFlow:
                return "Manual Flow";
        }
    }

    const controlIcon = <RadioButtonUncheckedIcon style={{ fill: colors.mediumGray }} />;
    const controlCheckedIcon = <RadioButtonCheckedIcon style={{ fill: colors.main }} />;
    const shippedLabel = <Box ml={0.5} component="div" display="flex" className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorBlack)}>Shipped</Box>;
    const ManualLabel = <Box ml={0.5} component="div" display="flex" className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorBlack)}>Manual</Box>;
    const shippedControl = (
        <Checkbox
            icon={controlIcon}
            checkedIcon={controlCheckedIcon}
            name={`Shipped-Radio`}
            checked={!isManual}
            onChange={() => handleToggleManual(false)}
        />
    );
    const ManualControl = (
        <Checkbox
            icon={controlIcon}
            checkedIcon={controlCheckedIcon}
            name={`Manual-Radio`}
            checked={isManual}
            onChange={() => handleToggleManual(true)}
        />
    );

    const renderSelectShipOptionStep = () => (
        <>
            <Box>
                <span className={clsx(commonClasses.size16, commonClasses.textMedium, commonClasses.colorBlack)}>Does this kit need to be shipped from Wild Health or is it being distributed from a manual flow?</span>
            </Box>
            <Box mt={"30px"} display="flex" alignItems="center">
                <FormControlLabel control={shippedControl} label={shippedLabel} />
                <Box mx="20px" />
                <FormControlLabel control={ManualControl} label={ManualLabel} />
            </Box>
        </>
    )

    const renderShippedKitStep = () => (
        <>
            <Box>
                <span className={clsx(commonClasses.size16, commonClasses.textMedium, commonClasses.colorBlack)}>
                    This will charge the patient's payment source {displayMoney(addOns[0].price, '$')}.
                </span>
            </Box>
            <Box>
                <span className={clsx(commonClasses.size16, commonClasses.textMedium, commonClasses.colorBlack)}>
                    Would you like to proceed?
                </span>
            </Box>
        </>
    )

    const renderManualFlowStep = () => (
        <>
            <Box>
                <Box className={commonClasses.inputLabel} mb={1}>Barcode Number <span className={commonClasses.colorBad}>*</span></Box>
                <TextField
                    id={`barcode`}
                    fullWidth
                    required
                    size="small"
                    InputProps={{ className: 'input', classes }}
                    variant="outlined"
                    value={params.barcode}
                    error={!!errors['barcode']}
                    helperText={errors['barcode']}
                    onChange={(event) => handleChanges(event.target.id, event.target.value)}
                />
            </Box>
            <Box mt={2}>
                <Box className={commonClasses.inputLabel} mb={1}>Outbound Shipping</Box>
                <TextField
                    id={`outboundShippingCode`}
                    fullWidth
                    required
                    size="small"
                    InputProps={{ className: 'input', classes }}
                    variant="outlined"
                    value={params.outboundShippingCode}
                    onChange={(event) => handleChanges(event.target.id, event.target.value)}
                />
            </Box>
            <Box mt={2}>
                <Box className={commonClasses.inputLabel} mb={1}>Return Shipping</Box>
                <TextField
                    id={`returnShippingCode`}
                    fullWidth
                    required
                    size="small"
                    InputProps={{ className: 'input', classes }}
                    variant="outlined"
                    value={params.returnShippingCode}
                    onChange={(event) => handleChanges(event.target.id, event.target.value)}
                />
            </Box>
        </>
    )

    const btnName = () => {
        switch (step) {
            case CreateDnaOrderStep.SelectShipOption:
                return "Proceed";
            case CreateDnaOrderStep.ShippedKit:
                return "Yes";
            case CreateDnaOrderStep.ManualFlow:
                return "Place Order";
        }
    }

    const disabledStatus = () => {
        switch (step) {
            case CreateDnaOrderStep.SelectShipOption:
                return false;
            case CreateDnaOrderStep.ShippedKit:
                return false;
            case CreateDnaOrderStep.ManualFlow:
                return !!errors['barcode'] || !params.barcode;
        }
    }

    const renderNextStepButton = () => {
        return (
            <WildHealthButton id={`next-step-button`} size='large' color="primary" onClick={() => handleNextStep()}>
                {btnName()}
            </WildHealthButton>
        )
    }

    const renderProcessButton = () => {
        return (
            <WildHealthButton id={`process-button`} size='large' style={{ minWidth: 116 }} color="primary" disabled={disabledStatus()} onClick={() => handleConfirm()}>
                {btnName()}
            </WildHealthButton>
        )
    }

    const renderNextButton = (step: CreateDnaOrderStep): JSX.Element => {
        switch (step) {
            case CreateDnaOrderStep.SelectShipOption: return renderNextStepButton();
            case CreateDnaOrderStep.ShippedKit: return renderProcessButton();
            case CreateDnaOrderStep.ManualFlow: return renderProcessButton();
        }
    }

    return (
        <Dialog
            open={open}
            onClose={() => handleCancel()}
            aria-labelledby="new-order-dialog-title"
            aria-describedby="new-order-dialog-description"
        >
            <DialogTitle id="dialog-title" classes={{ root: classes.dialogTitle }} onClose={() => handleCancel()}>{renderTitle()}</DialogTitle>
            <DialogContent classes={{ root: classes.dialogContent }}>
                <Box mb="10px" className={clsx(commonClasses.uppercase, commonClasses.size12, commonClasses.textMedium, commonClasses.colorGray1)}>
                    Step {step === CreateDnaOrderStep.SelectShipOption ? 1 : 2} of 2
                </Box>
                {step === CreateDnaOrderStep.SelectShipOption && renderSelectShipOptionStep()}
                {step === CreateDnaOrderStep.ShippedKit && renderShippedKitStep()}
                {step === CreateDnaOrderStep.ManualFlow && renderManualFlowStep()}
                <Box mt="40px" display="flex" alignItems="center" >
                    {step !== CreateDnaOrderStep.SelectShipOption && (
                        <Box>
                            <Button
                                id="previous-step-button"
                                onClick={handleGoBack}
                                className={commonClasses.backBtn}
                            >
                                <Box display="flex" alignItems="center">
                                    <ChevronLeft />
                                    <Box ml={0.5} className={clsx(commonClasses.size16, commonClasses.textMedium, commonClasses.colorBlack)}>
                                        Previous Step
                                    </Box>
                                </Box>
                            </Button>
                        </Box>
                    )}
                    <Box flex={1} display="flex" alignItems="center" justifyContent="flex-end" >
                        <Box mx={2}>
                            <WildHealthButton id={`confirm-dialog-cancel`} size='large' color='tertiary' onClick={() => handleCancel()}>
                                Cancel
                            </WildHealthButton>
                        </Box>
                        {
                            renderNextButton(step)
                        }
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
}
