import { Box, createStyles, Dialog, DialogContent, IconButton, Table, TableBody, TableContainer, TableHead, Theme, Typography, WithStyles, withStyles, Checkbox, Tooltip, Popover } from '@material-ui/core';
import React from 'react';
import { WildHealthTableCell } from '../../../common/components/wildHealthTable/WildHealthTableCell';
import { WildHealthTableRow } from '../../../common/components/wildHealthTable/WildHealthTableRow';
import { useFacade } from './pricingDialogComponent.hooks';
import { useStyles, SearchTextField, UploadButton } from './pricingDialogComponent.styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { ReactComponent as NotificationIcon } from '@img/icons/NotificationIcon.svg';
import { AddOnProvider, AddOnModel } from '../../../addons/models/addOns.models';
import { CommonPlaceHolder } from '../../../common/components/wildHealthPlaceHolder/CommonPlaceHolder';
import { colors } from "../../../common/constants/colors";
import { displayMoney } from "../../../common/helpers/display-money";
import {PaginationComponent} from "../../../common/pagination/components/paginationComponent/PaginationComponent";
import {pageSizes} from "../../../common/pagination/models/page-sizes";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { ProtectedElement } from '../../../common/components/ProtectedElement';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import { PermissionType } from '../../../auth/models/auth.enums';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { WildHealthMenuItem } from '../../../common/components/wildHealthMenu/WildHealthMenu';
import BackupIcon from '@material-ui/icons/Backup';
import GetAppIcon from '@material-ui/icons/GetApp';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
            boxShadow: '0px 2px 13px rgba(168, 173, 198, 0.25)',
            borderRadius: '2px 2px 0px 0px',
        },
        title: {
            color: colors.systemBlack,
            textTransform: 'none',
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    }
    );

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose?: () => void;
}

export const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;

    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6" className={classes.title}>{children}</Typography>
            {
                onClose &&
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            }
        </MuiDialogTitle>
    );
});

interface PricingDialogComponentProps {
    handleNewPricing: (labProvider: AddOnProvider) => void;
    handleModifyLabPricing: (addOnItem: AddOnModel, key: string) => void;
    handleUpdatePricingByDocument: (labProvider: AddOnProvider) => void;
}

export const PricingDialogComponent: React.FC<PricingDialogComponentProps> = (props: PricingDialogComponentProps) => {
    const { handleNewPricing, handleModifyLabPricing, handleUpdatePricingByDocument } = props;
    const classes = useStyles();

    const [
        {
            isOpen,
            isLoading,
            search,
            provider,
            filteredItems,
            selectedItems,
            totalPrice,
            totalCount,
            selectedPage,
            pageSize,
            menuAnchorEl
        },
        columns,
        handleOnFilter,
        handleClose,
        handleChanges,
        handlePageSizeChange,
        handlePageChange,
        getAllAvailablePages,
        handleToggleMenu,
        handleAddNewLab,
        handleEdit,
        handleDelete,
        handleExportDocument,
        handleUploadDocument
    ] = useFacade(handleNewPricing, handleModifyLabPricing, handleUpdatePricingByDocument);

    const controlIcon = <CheckBoxOutlineBlankIcon />;
    const controlCheckedIcon = <CheckBoxIcon style={{ fill: colors.main }} />;

    const renderTable = () => {

        if (!filteredItems || !filteredItems.length) {
            return <CommonPlaceHolder message='No data found.' />
        }

        return (
            <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <WildHealthTableRow>
                            {
                                columns.map((item, index) =>
                                    <WildHealthTableCell key={index} align={item.alignment}>
                                        {item.title}
                                    </WildHealthTableCell>
                                )
                            }
                            <ProtectedElement
                                element={
                                    <WildHealthTableCell align="right">
                                        Actions
                                    </WildHealthTableCell>}
                                permissions={[PermissionType.ManageLabPricing]}
                            />
                        </WildHealthTableRow>
                    </TableHead>
                    <TableBody>
                        {
                            filteredItems.map((item, index) =>
                                <WildHealthTableRow key={index}>
                                    <WildHealthTableCell width="5%" align="center">
                                        <Checkbox
                                            icon={controlIcon}
                                            color="primary"
                                            checkedIcon={controlCheckedIcon}
                                            checked={selectedItems.map(x => x.id).includes(item.id)}
                                            onChange={() => handleChanges(item.id)}
                                        />
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="left">
                                        {item.integrationId}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell width="50%" align="left">
                                        {item.description}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="right">
                                        ${item.price}
                                    </WildHealthTableCell>
                                    <ProtectedElement
                                        element={
                                            <WildHealthTableCell width="10%" align="right">
                                                <IconButton id="pricing-more-menu" onClick={(e) => handleToggleMenu(item.id, e.currentTarget)} className={classes.menuIcon}>
                                                    <MoreHorizIcon />
                                                </IconButton>
                                            </WildHealthTableCell>
                                        }
                                        permissions={[PermissionType.ManageLabPricing]}
                                    />
                                </WildHealthTableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            
            <Box py={1} px={2}>
                <PaginationComponent
                    pageSizes={pageSizes}
                    pageSize={pageSize}
                    selectedPage={selectedPage}
                    handlePageSizeChange={handlePageSizeChange}
                    totalCount={totalCount}
                    availablePages={getAllAvailablePages()}
                    handlePageSelect={handlePageChange}
                />
            </Box>
            </>
        )
    }

    const renderContent = () => {
        if (isLoading) {
            return <WildHealthLinearProgress/>
        }

        return (
            <>
                <Box className={classes.headerBox}>
                    <Box m={2} className={classes.searchBox}>
                        <SearchTextField
                            fullWidth
                            variant="outlined"
                            className={classes.search}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnFilter(e.target.value)}
                            placeholder='Search by Test Code or Test Name'
                            InputProps={{
                                inputProps: {
                                    className: classes.searchBase,
                                },
                                startAdornment: <SearchIcon className={classes.icon} />,
                            }}
                            value={search}
                        />
                    </Box>
                    <ProtectedElement
                        element={
                            <Box display="flex" alignItems="center">
                                <Box>
                                    <UploadButton
                                        id="export-excel-document-button"
                                        onClick={handleExportDocument}
                                    >
                                        <GetAppIcon className={classes.dropIcon} />
                                    </UploadButton>
                                </Box>
                                <Box mx={2}>
                                    <UploadButton
                                        id="upload-excel-document-button"
                                        onClick={handleUploadDocument}
                                    >
                                        <BackupIcon className={classes.dropIcon} />
                                    </UploadButton>
                                </Box>
                                <WildHealthButton
                                    id="add-lab-pricing"
                                    onClick={handleAddNewLab}
                                >
                                    <Box px={1}>
                                        Add New Lab
                                    </Box>
                                </WildHealthButton>
                            </Box>
                        }
                        permissions={[PermissionType.ManageLabPricing]}
                    />
                </Box>

                {renderTable()}
            </>
        )
    }

    const tooltipContent = (
        <p className={classes.notificationToolTipText}>
            Blood Group Test, Blood Sugar Test, Sodium Test, CD4 Test, Glibulin Test, Alberts Stain, Urine Routine.
        </p>
    )

    return (
        <Dialog
            scroll='paper'
            fullWidth
            maxWidth='md'
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="dialog-title" onClose={handleClose}>{`${AddOnProvider[provider]} Pricing`}</DialogTitle>

            <DialogContent>
                <Box className={classes.content}>
                    {renderContent()}
                </Box>
            </DialogContent>
            {totalPrice > 0 && (
                <Box className={classes.totalPriceContent}>
                    <Box className={classes.tooltipContent}>
                        <span className={classes.selectedText}>{selectedItems.length} labs selected</span>
                        <Tooltip title={tooltipContent} placement="top-start" arrow classes={{ tooltip: classes.customWidth, arrow: classes.arrow }}>
                            <span className={classes.notificationIcon}>
                                <NotificationIcon />
                            </span>
                        </Tooltip>
                    </Box>
                    <Box className={classes.totalPriceText}>
                        <span className={classes.totalContext}>Total:</span>
                        <span>{displayMoney(totalPrice, '$')}</span>
                    </Box>
                </Box>
            )}
            
            <Popover
                keepMounted
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={() => handleToggleMenu(0)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <WildHealthMenuItem id="lab-pricing-edit" onClick={handleEdit}>Edit</WildHealthMenuItem>
                <WildHealthMenuItem id="lab-pricing-delete" onClick={handleDelete}>Delete</WildHealthMenuItem>
            </Popover>
        </Dialog>
    );
}
