import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { colors } from "../../constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: '100%',
            [theme.breakpoints.down("xs")]: {
                flex: 1,
            },
        },
        message: {
            fontFamily: "Poppins",
            fontWeight: 600,
            fontSize: 20,
            color: colors.gray1,
            margin: 16,
            [theme.breakpoints.up('sm')]: {
                margin: 25,
            },
        },
        children: {
            margin: 16,
            [theme.breakpoints.up('sm')]: {
                margin: 25,
            },
        },
    })
)