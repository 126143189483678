import WHBlackLogo from "@img/logos/WHBlackLogo.svg";
import WHLogo from "@img/logos/WHLogo.svg";
import WHClarityLogo from "@img/logos/WHClarityLogo.svg";
import WHHealthReportLogo from "@img/logos/WHClarityLogo.png";
import WHClarityPDFLogo from "@img/logos/Clarity_pdf_header.png";

export class LogoService {

    private commonLogo = {
        default: WHBlackLogo,
        primary: WHLogo,
        clarity: WHClarityLogo,
        clarityHeader: WHClarityPDFLogo,
        healthReport: WHHealthReportLogo
    };

    public getLogo() {

        let logo = this.commonLogo;

        return logo;
    }
}

export const logoService = new LogoService();