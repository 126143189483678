import { Box, Dialog, DialogActions, DialogContent, TextField } from "@material-ui/core";
import React from "react";
import { DialogComponentProps } from "../../../common/dialogs/dialogProps";
import { useFacade } from "./resetPatientPasswordDialogComponent.hooks";
import DialogTitle from "@material-ui/core/DialogTitle";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";

export const ResetPatientPasswordDialogComponent: React.FC<DialogComponentProps> = (props: DialogComponentProps) => {
    const {
        open,
        handleSubmit,
        handleClose
    } = props;

    const [
        state,
        handleChanges,
        handleSubmitForm,
        handleCloseDialog,
    ] = useFacade(handleSubmit, handleClose);

    return (
        <Dialog fullWidth open={open} onClose={() => handleCloseDialog()} aria-labelledby="form-dialog-title">
            <form onSubmit={(e) => handleSubmitForm(e)}>
                <DialogContent>
                    <DialogTitle id="form-dialog-title">Reset Password</DialogTitle>
                    <Box p={3}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Box>
                                <span className="text-medium">New Password:</span>
                            </Box>
                            <Box ml={3} width="310px">
                                <TextField
                                    fullWidth
                                    error={!!state.errors["newPassword"]}
                                    helperText={state.errors["newPassword"]}
                                    type="password"
                                    size="small"
                                    InputProps={{ className: 'input' }}
                                    variant="outlined"
                                    value={state.newPassword}
                                    onChange={(v) => handleChanges("newPassword", v.target.value)}
                                />
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                            <Box>
                                <span className="text-medium">Confirm Password:</span>
                            </Box>
                            <Box ml={3} width="310px">
                                <TextField
                                    fullWidth
                                    error={!!state.errors["confirmPassword"]}
                                    helperText={state.errors["confirmPassword"]}
                                    type="password"
                                    size="small"
                                    InputProps={{ className: 'input' }}
                                    variant="outlined"
                                    value={state.confirmPassword}
                                    onChange={(v) => handleChanges("confirmPassword", v.target.value)}
                                />
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Box display="flex" justifyContent="space-between" width="100%" px={3} pb={2}>
                        <Box>
                            <WildHealthButton
                                id="reset-password-cancel"
                                color='secondary'
                                onClick={() => handleCloseDialog()}
                            >
                                Cancel
                            </WildHealthButton>
                        </Box>
                        <Box>
                            <WildHealthButton
                                id="reset-password"
                                onClick={(e) => handleSubmitForm(e)}
                            >
                                Reset
                            </WildHealthButton>
                        </Box>
                    </Box>
                </DialogActions>
            </form>
        </Dialog>
    );
}
