import React from "react";
import { Box, Grid } from "@material-ui/core";
import moment from "moment";
import clsx from "clsx";
import { ReactComponent as CeliacAndGluten } from '@img/CeliacAndGluten.svg';
import { ReportSectionKey, SectionModel, PatientReportModel } from "../../models/healthReport.models";
import { authQuery } from "../../../auth/stores/auth";
import { toCurrentTimeZone } from "../../../timezones/helpers/timezone";

export interface AddOnReportPrintComponentProps {
    patientId?: number | null;
    patientReport: PatientReportModel;
}

export const AddOnReportPrintComponent: React.FC<AddOnReportPrintComponentProps> = (props: AddOnReportPrintComponentProps) => {
    const { patientId, patientReport } = props;


    const renderGenericContent = (sections: SectionModel[], key: ReportSectionKey) => {
        const content = sections.find(x => x.key === key)?.content
        if (content) {
            return (
                <>
                    <Box className="wh-tw-text-sm wh-tw-font-medium">{content.title}</Box>
                    <Box className="wh-tw-text-sm">{content.text}</Box>
                </>
            )
        }
        return <></>
    }

    const renderRecommendationsContent = (sections: SectionModel[], key: ReportSectionKey) => {
        const content = sections.find(x => x.key === key)?.content
        if (content) {
            return <Box className="wh-tw-text-sm">{content?.recommendations?.value}</Box>
        }
        return <></>
    }

    const renderRiskValues = (sections: SectionModel[], key: ReportSectionKey) => {
        const content = sections.find(x => x.key === key)?.content
        if (content) {
            const CeliacDiseaseRisk = content.reportValueList.find(x => x.key === 'CeliacDiseaseRisk.classification')
            const RelativeRisk = content.reportValueList.find(x => x.key === 'GlutenSensitivity.value')
            const RelativeRiskPercentile = parseInt(RelativeRisk?.value) * 10

            return (
                <>
                    <Box display="flex" alignItems="center" gridGap={16}>
                        <Box pb={1.5} className="wh-tw-text-sm wh-tw-font-medium">{CeliacDiseaseRisk?.label}: </Box>
                        <Box px={1} pb={1.5} className="wh-tw-bg-bad wh-tw-rounded wh-tw-text-white wh-tw-text-sm">{CeliacDiseaseRisk?.value}</Box>
                    </Box>
                    <Box display="flex" alignItems="center" gridGap={16} mt={1}>
                        <Box pb={1.5} className="wh-tw-text-sm wh-tw-font-medium">{RelativeRisk?.label}: </Box>
                        <Box px={1} pb={1.5} className="wh-tw-bg-low wh-tw-text-white wh-tw-text-sm wh-tw-rounded">{RelativeRiskPercentile}th Percentile</Box>
                    </Box>
                </>
            )
        }
        return <></>
    }

    const renderCeliacPresent = (sections: SectionModel[], key: ReportSectionKey) => {
        const content = sections.find(x => x.key === key)?.content
        if (content) {
            return (
                <>
                    <Box className="wh-tw-text-sm wh-tw-font-medium">{content.title}</Box>
                    <Box mt={1.5} className="wh-tw-bg-white wh-tw-rounded-lg">
                        <Box display="flex" alignItems="center" className="wh-tw-border wh-tw-border-solid wh-tw-border-gray100">
                            <Box flex={1} py={2} pl={3} className="wh-tw-text-gray1 wh-tw-text-sm wh-tw-font-medium wh-tw-border-r wh-tw-border-solid wh-tw-border-gray100">Celiac Haplotype</Box>
                            <Box flex={1} textAlign="center" className="wh-tw-text-gray1 wh-tw-text-sm wh-tw-font-medium">Result</Box>
                        </Box>
                        {content.reportValueList.map((item, index) => (
                            <Box key={index} display="flex" alignItems="center" className="wh-tw-border wh-tw-border-solid wh-tw-border-gray100">
                                <Box flex={1} py={2} pl={3} className="wh-tw-text-sm wh-tw-border-r wh-tw-border-solid wh-tw-border-gray100">{item.label}</Box>
                                <Box flex={1} display="flex" justifyContent="center">
                                    <Box px={1} pb={1.5} className={clsx("wh-tw-text-sm", item.value == 'True' && "wh-tw-bg-bad wh-tw-text-white wh-tw-rounded")}>
                                        {item.value == 'True' ? 'Yes' : 'No'}
                                    </Box>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </>
            )
        }
        return <></>
    }

    const renderHeader = () => {

        return (
            <Box p={10}>
                <Box display="flex" alignItems="center" gridGap={8}>
                    <Box className="wh-tw-text-sm wh-tw-font-semibold">Patient ID</Box>
                    <Box className="wh-tw-text-sm">{patientId ?? authQuery.getPatientId()}</Box>
                </Box>
                <Box display="flex" alignItems="center" gridGap={8}>
                    <Box className="wh-tw-text-sm wh-tw-font-semibold">Date </Box>
                    <Box className="wh-tw-text-sm">{moment(toCurrentTimeZone(patientReport.createdAt)).format('ll')}</Box>
                </Box>
            </Box>
        )
    };

    const renderFooter = () => {
        return (
            <footer>
                <Box className="footer-content width-report" display="flex" justifyContent="space-between">
                    <Box />
                    <Box px={10}>
                        1
                    </Box>
                </Box>
            </footer>
        )
    };

    const content = JSON.parse(patientReport.contentJson)

    return (
        <div className="page-a4 flat-scroll">
            <Box height={'97%'} className='width-report'>
                {renderHeader()}
                <Box width={1} py={1} px={10}>
                    <Box>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Box>
                                <Box className="wh-tw-text-sm wh-tw-font-medium">Genetic Risk Review</Box>
                                <Box className="wh-tw-text-xl wh-tw-font-semibold">Celiac Disease & Gluten Sensitivity</Box>
                            </Box>
                        </Box>
                        <Box py={2.5} className="wh-tw-bg-white wh-tw-rounded-lg">
                            <Box>
                                {renderGenericContent(content?.chapters[0]?.pages[0]?.sections, ReportSectionKey.CeliacDisease)}
                            </Box>
                            <Box mt={2}>
                                {renderGenericContent(content?.chapters[0]?.pages[0]?.sections, ReportSectionKey.GlutenSensitivity)}
                            </Box>
                            <Box mt={2}>
                                {renderGenericContent(content?.chapters[0]?.pages[0]?.sections, ReportSectionKey.Symptoms)}
                            </Box>
                            <Box mt={2}>
                                {renderGenericContent(content?.chapters[0]?.pages[0]?.sections, ReportSectionKey.CausalityAndDiagnosis)}
                            </Box>
                            <Box mt={2}>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} sm={7} md={7}>
                                        {renderRiskValues(content?.chapters[0]?.pages[0]?.sections, ReportSectionKey.RiskValues)}
                                    </Grid>
                                    <Grid item xs={12} sm={5} md={5}>
                                        <Box display="flex" justifyContent="center" alignItems="center" my={3}><CeliacAndGluten /></Box>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box mt={2}>
                                {renderRecommendationsContent(content?.chapters[0]?.pages[0]?.sections, ReportSectionKey.CeliacAndGlutenSensitivityRecommendation)}
                            </Box>
                        </Box>
                        <Box mt={5}>
                            {renderCeliacPresent(content?.chapters[0]?.pages[0]?.sections, ReportSectionKey.CeliacHaplotypes)}
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box height={'3%'} className='width-report'>
                {renderFooter()}
            </Box>
        </div>
        
    )
}