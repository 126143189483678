import img from "@img/HealthReport_Pg15_Image.png";
import { Box, useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { WildHealthButton } from "../../common/components/wildHealthButton/WildHealthButton";
import { DnaTableComponent } from '../components/dnaTableComponent/DnaTableComponent';
import { HealthRecommendationComponent } from "../components/healthRecommendationComponent/HealthRecommendationComponent";
import { RecommendationSingleSelectComponent } from "../components/recommendationSingleSelectComponent/RecommendationSingleSelectComponent";
import { riskColorMap } from "../constants/healthReport.constants";
import {
    HealthReportMode,
    HealthReportSections,
    HealthReportVersion,
    recommendationRowsCapacity, RecommendationTypesNumbers
} from "../models/healthReport.models";
import { HealthReportBasePage, HealthReportMobileBasePage } from "./HealthReportBasePage";
import { useStyles } from './healthReport.styles';
import { HealthReportPageProps } from "./healthReportPageProps";

export const HealthReportNeurobehavioralPage: React.FC<HealthReportPageProps> = (props: HealthReportPageProps) => {
    const {
        report,
        handleChanges,
        handleSaveChanges,
        pageNumber,
        isChanged,
        mode,
        practice,
        recommendations,
        handleSelected,
        handleChangesNewRecommendation,
        handleAddRecommendation,
        handleSaveChangesNew,
    } = props;
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        props.mode !== HealthReportMode.PrintMode && isSmallScreen ? (
            <HealthReportMobileBasePage section={HealthReportSections.Neurobehavioral} pageNumber={pageNumber} practice={practice}>
                <Box px={2}>
                    <Box>
                        <Box>
                            <span className={classes.reportTitle}>Your Neurobehavioral Genetics</span>
                        </Box>
                        <Box mt="10px">
                            <Box>
                                <DnaTableComponent
                                    dna={report.neurobehavioralReport.neurobehavioralDna.dna}
                                    colorMap={riskColorMap} />
                            </Box>
                            <Box mt="30px">
                                <img src={img} style={{ width: '100%', height: 'auto' }} alt="img" />
                            </Box>
                        </Box>
                        <Box mt="30px">
                            {parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
                                ? <Box>
                                    {HealthReportMode.EditMode === mode && <Box display="flex" justifyContent="space-between" mb={1}>
                                        <span className="report-title"></span>
                                        <WildHealthButton
                                            id="health-recommendation-new-save"
                                            onClick={() => handleSaveChangesNew()}
                                            disabled={!isChanged}>
                                            <Box >
                                                Save changes
                                            </Box>
                                        </WildHealthButton>
                                    </Box>}
                                    <RecommendationSingleSelectComponent
                                        currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.Neurobehavioral)}
                                        recommendation={report.neurobehavioralReport.recommendation}
                                        type={RecommendationTypesNumbers.Neurobehavioral}
                                        handleSelected={handleSelected}
                                        handleChanges={handleChangesNewRecommendation}
                                        handleAddRecommendation={handleAddRecommendation}
                                        mode={mode}
                                    />
                                </Box>
                                : <HealthRecommendationComponent
                                    mode={mode}
                                    rows={recommendationRowsCapacity[RecommendationTypesNumbers.Neurobehavioral]}
                                    recommendation={report.neurobehavioralReport.recommendation}
                                    handleContentChanges={!handleChanges ? handleChanges : (value) => {
                                        handleChanges('neurobehavioralReport.recommendation.content', value)
                                    }}
                                    handleManualContentChanges={!handleChanges ? handleChanges : (value) => {
                                        handleChanges('neurobehavioralReport.recommendation.manualContent', value)
                                    }}
                                    handleSaveChanges={handleSaveChanges}
                                    isChanged={isChanged} />
                            }
                        </Box>
                    </Box>
                </Box>
            </HealthReportMobileBasePage>
        ) : (
            <HealthReportBasePage section={HealthReportSections.Neurobehavioral} pageNumber={pageNumber} practice={practice}>
                <Box px={15} pt={5}>
                    <Box>
                        <Box>
                            <span className="report-title">Your Neurobehavioral Genetics</span>
                        </Box>
                        <Box display="flex">
                            <Box display="flex" alignItems="center" justifyContent="center" width="40%">
                                <Box width="70%">
                                    <DnaTableComponent
                                        dna={report.neurobehavioralReport.neurobehavioralDna.dna}
                                        colorMap={riskColorMap} />
                                </Box>
                            </Box>
                            <Box p={15}>
                                <img src={img} style={{ width: '100%' }} alt="img" />
                            </Box>
                        </Box>
                        <Box>
                            {parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
                                ? <Box>
                                    {HealthReportMode.EditMode === mode ? <Box display="flex" justifyContent="space-between" mb={1}>
                                        <span className="report-title">Your neurobehavioral recommendations</span>
                                        <WildHealthButton
                                            id="health-recommendation-new-save"
                                            onClick={() => handleSaveChangesNew()}
                                            disabled={!isChanged}>
                                            <Box >
                                                Save changes
                                            </Box>
                                        </WildHealthButton>
                                    </Box>
                                    : <Box mb={1}><span className="report-title">Your neurobehavioral recommendations</span></Box>}
                                    <RecommendationSingleSelectComponent
                                        currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.Neurobehavioral)}
                                        recommendation={report.neurobehavioralReport.recommendation}
                                        type={RecommendationTypesNumbers.Neurobehavioral}
                                        handleSelected={handleSelected}
                                        handleChanges={handleChangesNewRecommendation}
                                        handleAddRecommendation={handleAddRecommendation}
                                        mode={mode}
                                    />
                                </Box>
                                : <HealthRecommendationComponent
                                    mode={mode}
                                    rows={recommendationRowsCapacity[RecommendationTypesNumbers.Neurobehavioral]}
                                    recommendation={report.neurobehavioralReport.recommendation}
                                    handleContentChanges={!handleChanges ? handleChanges : (value) => {
                                        handleChanges('neurobehavioralReport.recommendation.content', value)
                                    }}
                                    handleManualContentChanges={!handleChanges ? handleChanges : (value) => {
                                        handleChanges('neurobehavioralReport.recommendation.manualContent', value)
                                    }}
                                    handleSaveChanges={handleSaveChanges}
                                    isChanged={isChanged} />
                            }
                        </Box>
                    </Box>
                </Box>
            </HealthReportBasePage>
        )
    )
}
