import {
  Box,
  Divider,
  Drawer,
  useMediaQuery,
  useTheme,
  IconButton,
} from "@material-ui/core";
import React from "react";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { navigationService } from "../../../../services/navigation.service";
import { useStyles } from "./fullStaffChatComponent.styles";
import { FullStaffChatComponentView, useFacade } from "./fullStaffChatComponent.hooks";
import { useHistory } from "react-router";
import { ReactComponent as OpenSideIcon } from "@img/icons/OpenSide.svg";
import clsx from "clsx";
import { conversationTitles, ConversationType } from "../../models/conversation.models";
import { MessageAvatarsComponent } from "../messageAvatarsComponent/MessageAvatarsComponent";
import { SecondaryButton } from "../../../common/components/wildHealthButton/SecondaryButton";
import { MessageComponent } from "../messageComponent/MessageComponent";
import { SelectConversationSubjectComponent } from "../selectConversationSubjectComponent/SelectConversationSubjectComponent";
import { supportConversationSubjects } from "../../models/conversationSubject.models";
import { ConversationComponent } from "../conversationComponent/ConversationComponent";
import { StaffConversationsHistoryComponent } from "../staffConversationsHistoryComponent/StaffConversationsHistoryComponent";
import { WildHealthTabControl } from "../../../common/components/wildHealthTabControl/WildHealthTabControl";
import { TabItem } from "../../../common/components/wildHealthTabControl/wildHealthTabControl.hooks";
import { useStylesSideBarTabs } from "../../../common/components/wildHealthTabControl/wildHealthTabControl.styles";
import { AttachmentsComponent } from "../attachmentsComponent/AttachmentsComponent";
import { AttachmentsViewComponent } from "../attachmentsViewComponent/AttachmentsViewComponent";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { ChatsPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/ChatsPlaceHolder";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { ReactComponent as TimeCircle } from "@img/icons/timeCircle.svg";
import commonUseStyles from "../../../common/styles/common.styles";
import emptyChats from "@img/placeholders/EmptyReport.png";
import { WildHealthPlaceHolderBase } from '../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolderBase';
import { ConversationState } from "../../models/conversationState.models";

export const FullStaffChatComponent: React.FC = () => {
  const classes = useStyles();
  const commonClasses = commonUseStyles();
  const history = useHistory();
  const sideBarTabsStyle = useStylesSideBarTabs();

  const [
    {
      isLoading,
      targetConversation,
      isSideAreaOpen,
      creatingTicket,
      conversations,
      author,
      view,
      showHistoryComponent,
    },
    handleToggleSideArea,
    handleCreateNewTicket,
    handleStartConversation,
    handleSelectConversation,
    handleShowHistoryComponent,
  ] = useFacade();

  const theme = useTheme();
  const isMiddleScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const renderGoBack = () => {
    return (
      <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.goBack}>
        <Box flex={1}>
          <IconButton id="full-staff-chat-go-back" onClick={() =>
            showHistoryComponent
              ? handleShowHistoryComponent(!showHistoryComponent)
              : navigationService.toConversations(history)}>
            <Box display="flex" alignItems="center">
              <ArrowBackIcon className={commonClasses.colorGray1} />
              <Box ml={1} className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorGray1)}>Back</Box>
            </Box>
          </IconButton>
        </Box>
        {showHistoryComponent && (
          <>
            <Box className={classes.historyGoBackTitle} flex={1}>Message History</Box>
            <Box flex={1} />
          </>
        )}
        {view === FullStaffChatComponentView.SelectedConversation && isSmallScreen && (
          <Box className={classes.subjectSection}>
            Subject: <span className={classes.subjectTitle}>{targetConversation.subject}</span>
          </Box>
        )}
      </Box>
    );
  };

  const renderHeaderArea = () => {
    return (
      <Box p={2} className={classes.header}>
        <Box className={classes.titleAndAvatars}>
          <Box pr={1.5}>
            <MessageAvatarsComponent
              participants={[{ image: '', initials: "S" }]}
              size={isSmallScreen && 35}
            />
          </Box>
          <Box  display="flex" flexDirection="column">
            <Box className={classes.title}>
              {conversationTitles.get(ConversationType.Support)}
            </Box>
            {!isSmallScreen && (
              <Box className={classes.subject}>
                {view === FullStaffChatComponentView.SelectedConversation
                  ? targetConversation?.subject
                  : "New Message"}
              </Box>
            )}
          </Box>
        </Box>
        <Box display="flex">
          {!isSmallScreen && (
            <SecondaryButton
              id="full-staff-chat-new-message"
              onClick={() => handleCreateNewTicket()}
              disabled={view === FullStaffChatComponentView.NewTicket}
            >
              New Message
            </SecondaryButton>
          )}
          {isMiddleScreen && (
            <Box ml={2}>
              <IconButton
                id="full-staff-chat-side-area"
                onClick={() => handleToggleSideArea(!isSideAreaOpen)}
              >
                <OpenSideIcon className={classes.icon} />
              </IconButton>
              <Drawer
                anchor="right"
                open={isSideAreaOpen}
                onClose={() => handleToggleSideArea(!isSideAreaOpen)}
              >
                <Box className={classes.sideMobile}>{renderSideArea("100%")}</Box>
              </Drawer>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const renderSideArea = (height: string) => {
    if (isSmallScreen) {
      if (showHistoryComponent) {
        return isLoading ? (
          <Box p={3}>
            <WildHealthLinearProgress />
          </Box>
        ) : !conversations.length ? (
          <WildHealthPlaceHolderBase message="No messages history.">
            <img className={classes.placeholderImage}
              src={emptyChats}
              alt="img" />
          </WildHealthPlaceHolderBase>
        ) : view === FullStaffChatComponentView.SelectedConversation ? (
          <ConversationComponent
            conversation={targetConversation}
            author={author}
            threadHeight={height}
          />
        ) : (
          <Box p={2} className={commonClasses.bgWhiteMain}>
            <StaffConversationsHistoryComponent
              height={height}
              conversations={conversations}
              targetConversation={targetConversation}
              handleSelect={(conversation) => handleSelectConversation(conversation)}
            />
          </Box>
        )
      }
      return (
        <>
          <Box>
            <Box ml={2} className={classes.historyTitle}>
              Message history
            </Box>
            <Box className={classes.historyItem} mt={1}>
              <Box className={classes.historyItemContent}>
                <TimeCircle />
                <Box className={classes.historyContext}>Show message history</Box>
              </Box>
              <IconButton id="full-staff-chat-side-area-history-button"
                onClick={() => handleShowHistoryComponent(!showHistoryComponent)}>
                <ChevronRightIcon
                  fontSize="small"
                />
              </IconButton>
            </Box>
          </Box>
          {targetConversation && !targetConversation.proxy && targetConversation.state === ConversationState.Closed ? <AttachmentsViewComponent /> : <AttachmentsComponent />}
        </>
      );
    } else {
      const tabs: TabItem[] = [
        {
          title: "Message History",
          content: isLoading ? (
            <Box p={3}>
              <WildHealthLinearProgress />
            </Box>
          ) : !conversations.length ? (
            <ChatsPlaceHolder message="No messages yet." />
          ) : (
            <StaffConversationsHistoryComponent
              height={height}
              conversations={conversations}
              targetConversation={targetConversation}
              handleSelect={(conversation) => handleSelectConversation(conversation)}
            />
          ),
          keepMounted: true,
        },
        {
          title: "Attachments",
          content: isLoading ? (
            <Box p={3}>
              <WildHealthLinearProgress />
            </Box>
          ) : (
            <Box
              className={clsx("flat-scroll", classes.sideContainer)}
              height={height}
              maxHeight={height}
            >
              {targetConversation && !targetConversation.proxy && targetConversation.state === ConversationState.Closed ? <AttachmentsViewComponent /> : <AttachmentsComponent />}
            </Box>
          ),
          keepMounted: true,
        },
      ];

      return (
        <Box width="100%">
          <WildHealthTabControl items={tabs} className={sideBarTabsStyle} />
        </Box>
      );
    }
  };

  const renderSelectedConversation = () => {
    return (
      <ConversationComponent
        conversation={targetConversation}
        author={author}
        threadHeight="calc(100vh - 247px)"
      />
    );
  };

  const renderCreateNewTicket = () => {
    const messages = [
      {
        originalMessage: "Hi there! Welcome to Wild Health.",
        date: new Date(),
      },
      {
        originalMessage: "What brought you in today?",
        date: new Date(),
      },
      {
        originalMessage: (
          <SelectConversationSubjectComponent
            subjects={supportConversationSubjects}
            disabled={creatingTicket}
            handleSelect={(subject) => handleStartConversation(subject)}
          />
        ),
        date: new Date(),
      },
    ];

    if (creatingTicket) {
      messages.push({
        originalMessage: "Creating ticket . . .",
        date: new Date(),
      });
    }

    const messageGroup = {
      authorFirstName: "Staff",
      authorLastName: " ",
      isMyMessage: false,
      messages: messages,
    };

    return (
      <Box className={clsx(classes.newMessage, "flat-scroll")}>
        <MessageComponent messageGroup={messageGroup} />
      </Box>
    );
  };

  const views = new Map<FullStaffChatComponentView, () => void>([
    [FullStaffChatComponentView.NewTicket, renderCreateNewTicket],
    [FullStaffChatComponentView.SelectedConversation, renderSelectedConversation],
  ]);

  const renderSmallComponent = () => {
    return (
      <Box flex={1} px={showHistoryComponent ? 0 : 2}>
        <div className={clsx("g", classes.messages)}>
          {!showHistoryComponent && renderHeaderArea()}
        </div>
        <div className={clsx("g", classes.side)}>
          {renderSideArea("calc(100vh - 225px)")}
        </div>
      </Box>
    )
  }

  return (
    <Box className={classes.root}>
      {renderGoBack()}
      {isSmallScreen ? renderSmallComponent() : (
        <Box className={classes.chat} px={2}>
          <div className={clsx("g", classes.messages)}>
            {!showHistoryComponent && renderHeaderArea()}
              <Divider className={classes.divider} orientation="horizontal" />
              {views.get(view)()}
          </div>
          {!isMiddleScreen && (
            <div className={clsx("g", classes.side)}>
              <Divider className={classes.divider} orientation="vertical" />
              {renderSideArea("calc(100vh - 200px)")}
            </div>
          )}
        </Box>
      )}
    </Box>
  );
};
