import { Box, Grid, Link } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import React from 'react';
import { LayoutComponent } from "../../../../components/layoutComponent/LayoutComponent";
import { TitlesService } from '../../../../constants/title.constants';
import { PracticeProfileModel } from '../../../account/models/practiceProfile.model';
import { WildHealthLinkButton } from '../../../common/components/WildHealthLinkButton';
import { CreatedPatientModel } from "../../../patients/models/patient.model";
import { useFacade } from "./joinPracticeSucceededPage.hooks";
import { useStyles } from "./joinPracticeSucceededPage.styles";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { RoutesConstants } from '../../../../constants/route.constants';
import { ImageConstants } from '../../../../constants/image.constants';

export const JoinPracticeSucceededPage: React.FC = (props: any) => {
    const patient = props?.location?.state?.patient ?? {} as CreatedPatientModel;
    const practice = props?.location?.state?.practice ?? {} as PracticeProfileModel;
    const { intakeId } = patient;

    const classes = useStyles();

    const [
        handleGoToDashboard
    ] = useFacade();

    if (!intakeId) {
        handleGoToDashboard();
        return <></>;
    }

    return (
        <LayoutComponent title={TitlesService.joinPracticeSuccessTitle}>
            <Box m={2}>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <Grid item>
                        <Box maxWidth='1317px'>
                            <Box>
                                <img className={classes.image} src={RoutesConstants.cdnBaseLink + ImageConstants.clarityPdfHeaderPath} alt="logo" />
                            </Box>

                            <Box m={2}>
                                <Box my={5} textAlign="center">
                                    <Box height="120px" fontSize="100px">
                                        <CheckCircleOutlineIcon fontSize="inherit" className="text-accent" />
                                    </Box>
                                    <Box mt={1} className={classes.title}>
                                        <span className="secondary-font size-2">
                                            Your new Wild Health Clarity Account has been created.
                                        </span>
                                    </Box>
                                </Box>

                                <Box textAlign="center">
                                    <WildHealthButton
                                        id="join-practice-clarity-portal"
                                        size="large"
                                        onClick={() => handleGoToDashboard()}
                                    >
                                        Login to Clarity Portal
                                    </WildHealthButton>
                                </Box>

                                <Box my={4}>
                                    <span>
                                        You can also access the portal with this URL - <WildHealthLinkButton id="join-practice-link-dashboard" onClick={() => handleGoToDashboard()} content='app.wildhealth.com/login' />. Please use the email and password you provided during sign-up.
                                    </span>
                                </Box>

                                <Box my={4}>
                                    <span className='text-bold'>DNA Sequencing</span> - If you ordered DNA sequencing you will be receiving a DNA collection kit in the mail. Please follow the instructions provided & drop it off at a UPS location as quickly as possible.
                                </Box>

                                <Box my={4}>
                                    <span className='text-bold'>Epigenetic Age</span> - If you ordered the Epigenetic Age test you will be receiving a kit in the mail. Please follow the instructions provided.
                                </Box>

                                <Box my={4}>
                                    <span>
                                        In the meantime, if you have any questions, please don’t hesitate to reach out to us at <Link id="join-practices-link-email" href={`mailto:${practice.email}`}>{practice.email}</Link> or at {practice.phoneNumber}
                                    </span>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </LayoutComponent>
    )
}
