import {
  IconButton,
  Box,
} from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import { useHistory } from "react-router";
import { useStyles } from "./mobileNavigationComponent.styles";
import ReferralIcon from "../iconComponent/ReferralLandingIcon";
import { OptionalComponent } from "../../modules/common/components/OptionalComponent";
import { Practice } from "../../modules/auth/models/auth.enums";
import { navigationService } from "../../services/navigation.service";

export const ReferralNavigationComponent: React.FC = () => {
  const history = useHistory();

  const classes = useStyles();

  const handleGoToReferral = () => {
    navigationService.toReferralLanding(history);
  }

  return (
    <OptionalComponent practiceId={Practice.WildHealth}>
      <Box display="flex" alignItems="center">
        <IconButton
          id="mobile-referral-btn"
          aria-describedby="referral-icon-btn"
          onClick={handleGoToReferral}
          className={clsx(classes.referralIcon)}
        >
          <ReferralIcon isSelected={true} />
          <span className={classes.referralTitle}>Refer a Friend</span>
        </IconButton>
      </Box>
    </OptionalComponent>
  );
};
