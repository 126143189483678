import { useState } from "react";
import { IErrorState } from "../../../common/validation/error-state";
import { employeeService } from "../../../employee/services/employees.service";
import { addFellowButtonComponentValidator } from "./addFellowButtonComponent.validator";
import { CreateFellowModel } from "../../../employee/models/employee.models";
import { navigationService } from "../../../../services/navigation.service";
import { useHistory } from "react-router";

interface AddFellowButtonComponentState extends IErrorState {
    isLoading: boolean;
    isOpen: boolean;
    fellow: CreateFellowModel;
}

const initModel: CreateFellowModel = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    credentials: '',
    rosterId: 0
};

/**
 * Custom Hook to manage a view Model for Notifications component
 */
export function useFacade(rosterId: number): [
    AddFellowButtonComponentState,
    (field: string, value: any) => void,
    () => void,
    () => void,
    () => void
] {

    const [state, setState] = useState({
        isLoading: false,
        isOpen: false,
        fellow: Object.assign({}, initModel),
        errors: {}
    } as AddFellowButtonComponentState);
    const history = useHistory();

    const handleChanges = (field: string, value: any) => {
        addFellowButtonComponentValidator.validateAndSetState(state, setState, field, value);

        const fellow = state.fellow;

        fellow[field] = value;

        setState({
            ...state,
            fellow: fellow
        });
    }

    const handleOpen = () => {
        setState(state => ({
            ...state,
            isOpen: true,
            errors: {},
            fellow: {
                ...state.fellow,
            }
        }));
    }

    const handleUpdateDateHistory = () => {
        navigationService.toFellowship(history, rosterId)
    }

    const handleCreate = () => {
        addFellowButtonComponentValidator.validateObjectAndSetState(state, setState, state.fellow);
        if (!addFellowButtonComponentValidator.stateIsValid(state)) {
            return;
        }
        setState(state => ({
            ...state,
            isLoading: true,
        }));
        
        employeeService.createFellow({...state.fellow, rosterId: rosterId}).subscribe(() => {
            setState(state => ({
                ...state,
                errors: {},
                isOpen: false,
                isLoading: false,
                fellow: Object.assign({}, initModel)
            }));
            handleUpdateDateHistory();
        },
            () => {
                setState(state => ({
                    ...state,
                    isLoading: false,
                }));
            })
    }

    const handleClose = () => {
        setState(state => ({
            ...state,
            errors: {},
            isOpen: false,
            fellow: Object.assign({}, initModel)
        }));
    }

    return [
        state,
        handleChanges,
        handleCreate,
        handleOpen,
        handleClose
    ];
}