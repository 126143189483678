import { Box, TextField} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import clsx from 'clsx';
import React from 'react';
import commonUseStyles from '../../../common/styles/common.styles';
import { EmployeeShortModel } from '../../../employee/models/employee.models';

interface AppointmentAttendeesComponentProps {
    handleChange: (employees: (string | EmployeeShortModel)[]) => void,
    employees: EmployeeShortModel[],
    error?: boolean,
    helperText?: string,
    id?: string,
}

export const AppointmentAttendeesComponent: React.FC<AppointmentAttendeesComponentProps> = (props: AppointmentAttendeesComponentProps) => {
    const {
        handleChange,
        employees,
        error = false,
        helperText = null,
        id = null,
    } = props;

    const commonClasses = commonUseStyles();

    const displayName = (employee: EmployeeShortModel): string => {
        return `${employee.firstName} ${employee.lastName}`;
    }

    return (
        <Box id={id}>
            <Box>
                <span className={clsx(commonClasses.size14, commonClasses.colorGray1)}>Appointment Attendee(s)</span>
            </Box>
            <Box>
                <Autocomplete
                    size='small'
                    disableClearable
                    multiple
                    options={employees}
                    getOptionLabel={employee => displayName(employee)}
                    onChange={(e, values) => handleChange(values)}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        error={error}
                        helperText={helperText}
                        margin="normal"
                        variant="outlined"
                        InputProps={{ ...params.InputProps, type: 'search' }}
                    />
                    )}
                />
            </Box>
        </Box>
    );
}