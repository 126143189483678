import { Box, useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { HealthReportMode, HealthReportSections } from "../models/healthReport.models";
import { HealthReportBasePage, HealthReportMobileBasePage } from "./HealthReportBasePage";
import { HealthReportPageProps } from "./healthReportPageProps";
import commonUseStyles from "../../common/styles/common.styles";
import clsx from "clsx";
import { RoutesConstants } from '../../../constants/route.constants';
import { ImageConstants } from '../../../constants/image.constants';
import img from "@img/HealthReport_Pg16_Image.png";

export const HealthReportMicrobiome0Page: React.FC<HealthReportPageProps> = (props: HealthReportPageProps) => {
    const { pageNumber, practice } = props;
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        props.mode !== HealthReportMode.PrintMode && isSmallScreen ? (
            <HealthReportMobileBasePage section={HealthReportSections.Microbiome} pageNumber={pageNumber} practice={practice} firstPage>
                <Box>
                    <Box px={2}>
                        <Box>
                            <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeBigReport, commonClasses.textRegularReport)}>Inflammation and Diversity</span>
                        </Box>
                        <Box mt={1.5} fontSize="14px" lineHeight="22px">
                            <p>
                                Your microbiome is part of you. The bacteria in your gut affect your risk of chronic
                                disease like heart disease and dementia. They make B vitamins and other nutrients
                                our bodies use to function. They make neurotransmitters like serotonin and have been
                                linked to depression and mental health. They even buffer and use lactic acid
                                and can make us better athletes.
                            </p>
                            <p>
                                We use your microbiome data to compile an inflammation score which may alter your risk
                                of chronic disease as well as a diversity score, which has been linked to longevity.
                            </p>
                            <p>
                                Finally, we’ll review some specific bacteria and how they may be important to
                                optimize and identify some actions to improve them.
                            </p>
                        </Box>
                        <Box mt="30px">
                            <img src={RoutesConstants.cdnBaseLink + ImageConstants.healthReportPg16ImagePath} style={{ width: '100%', height: 'auto' }} alt="img" />
                        </Box>
                    </Box>
                </Box>
            </HealthReportMobileBasePage>
        ) : (
            <HealthReportBasePage section={HealthReportSections.Microbiome} pageNumber={pageNumber} practice={practice}>
                <Box>
                    <Box px={15} pt={5}>
                        <Box>
                            <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeBigReport, commonClasses.textMediumReport)}>Inflammation and Diversity</span>
                        </Box>
                        <Box mt={1}>
                            <p>
                                Your microbiome is part of you. The bacteria in your gut affect your risk of chronic
                                disease like heart disease and dementia. They make B vitamins and other nutrients
                                our bodies use to function. They make neurotransmitters like serotonin and have been
                                linked to depression and mental health. They even buffer and use lactic acid
                                and can make us better athletes.
                            </p>
                            <p>
                                We use your microbiome data to compile an inflammation score which may alter your risk
                                of chronic disease as well as a diversity score, which has been linked to longevity.
                            </p>
                            <p>
                                Finally, we’ll review some specific bacteria and how they may be important to
                                optimize and identify some actions to improve them.
                            </p>
                        </Box>
                        <Box mt={10}>
                            {
                                props.mode === HealthReportMode.PrintMode
                                    ? <img src={img} style={{ width: '100%', height: '900px' }} alt="img" />
                                    : <img src={RoutesConstants.cdnBaseLink + ImageConstants.healthReportPg16ImagePath} style={{ width: '100%', height: '900px' }} alt="img" />
                            }
                        </Box>
                    </Box>
                </Box>
            </HealthReportBasePage>
        )
    )
}
