import { useState } from "react";
import { useHistory } from "react-router";
import { navigationService } from "../../../../services/navigation.service";
import { IErrorState } from "../../../common/validation/error-state";
import { authService } from "../../services/auth.service";
import { authQuery } from "../../stores/auth";
import { setUpPasswordPageValidator } from "./setUpPasswordPage.validator";
import {SetPasswordModel} from "../../../common/models/user.models";

/**
 * Represents Create Shortcuts Component state
 */
interface SetUpPasswordPageState extends IErrorState {
    model: SetPasswordModel;
    showPassword: boolean;
    showConfirmPassword: boolean;
    isLoading: boolean;
}

/**
 * Custom Hook to manage a view Model for Create shortcuts component
 */
export function useFacade(code: string): [SetUpPasswordPageState, Function, Function, () => void, () => void] {
    const history = useHistory();
    if (authQuery.isLoggedIn()) {
        navigationService.toDashboard(history);
    }

    const [state, setState] = useState({
        model: {
            newPassword: null,
            confirmPassword: null,
            code: code,
            practiceId: authQuery.getCurrentPracticeId()
        },
        errors: {},
        showPassword: false,
        showConfirmPassword: false,
        isLoading: false,
    } as SetUpPasswordPageState);

    const handleClickShowPassword = () => {
        setState({ ...state, showPassword: !state.showPassword });
    };

    const handleClickShowConfirmPassword = () => {
        setState({ ...state, showConfirmPassword: !state.showConfirmPassword });
    };

    const handleChanges = (field: string, value: string) => {
        state.model[field] = value;
        setState({
            ...state,
            model: { ...state.model }
        });

        setUpPasswordPageValidator.validateAndSetState(state, setState, field, value);
    }

    const handleSubmit = (event: Event) => {

        event.preventDefault();
        setUpPasswordPageValidator.validateObjectAndSetState(state, setState, state.model);
        if (!setUpPasswordPageValidator.stateIsValid(state)) {
            return;
        }

        setState({ ...state, isLoading: true });
        authService.setUpPassword(state.model).subscribe(
            () => {
                navigationService.toDashboard(history);
            },
            () => {
                setState({
                    ...state,
                    isLoading: false,
                })
             },
        )}

    return [state, handleChanges, handleSubmit, handleClickShowPassword, handleClickShowConfirmPassword];
}
