import { useEffect, useState } from "react";
import { RemitModel } from "../../models/insurance.models";
import { insuranceManagementService } from "../../services/insuranceManagement.service";
import { IErrorState } from "../../../common/validation/error-state";
import { patientResponsibilityComponentValidator } from "./patientResponsibilityComponent.validator";
import { notesService } from "../../../notes/services/notes.service";
import { NoteModel } from "../../../notes/models/notes.models";

interface PatientResponsibilityState extends IErrorState {
  noteId: number;
  note?: NoteModel;
  copay: number;
  coinsurance: number;
  deductible: number;
  isLoading: boolean;
}

export function useFacade(): [
  PatientResponsibilityState,
  (value: any) => void,
  (value: any) => void,
  (value: any) => void,
  (value: any) => void,
  (value: any) => void,
  (value: any) => void
] {
    const [state, setState] = useState({
      noteId: 0,
      note: null,
      copay: 0,
      coinsurance: 0,
      deductible: 0,
      isLoading: false,
      errors: {}
    } as PatientResponsibilityState);

    const handleNoteIdChange = (value: any) => {
      patientResponsibilityComponentValidator.validateAndSetState(state, setState, 'noteId', value);

      setState({ ...state, noteId: value })
    }

    const handleNoteIdOnBlur = (value: any) => {
      setState({ ...state, isLoading: true })

      const noteSuccess = (note: NoteModel) => setState({
        ...state,
        note
      });
      

      const noteFailure = () => setState({ 
        ...state, 
        isLoading: false,
        noteId: 0,
        copay: 0,
        coinsurance: 0,
        deductible: 0
       })

      notesService.getById(value).subscribe(noteSuccess, noteFailure);
    }

    useEffect(() => {
      if (state.note) {
        setState({ ...state, isLoading: true })

        const success = (response: RemitModel[]) => {
          setState({
            ...state,
            isLoading: false,
            deductible: getDetailValue(response, "PR1"),
            coinsurance: getDetailValue(response, "PR2"),
            copay: getDetailValue(response, "PR3")
          })
        };
  
        const cb = () => setState({ 
          ...state, 
          isLoading: false,
          noteId: 0,
          copay: 0,
          coinsurance: 0,
          deductible: 0
         })
  
        insuranceManagementService.getNoteRemit(state.noteId).subscribe(success, cb);
      }
    }, [state.note])

    const getDetailValue = (remits: RemitModel[], code: string): number => {
      for (const remit of remits) {
        if (remit?.details) {
          for (const detail of remit.details) {
            if (detail.type?.codings[0]?.code === code) {
              return detail.amount.value;
            }
          }
        }
        
      }

      return 0;
    }

    const handleCopayChange = (value: any) => {
      patientResponsibilityComponentValidator.validateAndSetState(state, setState, 'copay', value);

      setState({ ...state, copay: value })
    }

    const handleCoinsuranceChange = (value: any) => {
      patientResponsibilityComponentValidator.validateAndSetState(state, setState, 'coinsurance', value);

      setState({ ...state, coinsurance: value })
    }

    const handleDeductibleChange = (value: any) => {
      patientResponsibilityComponentValidator.validateAndSetState(state, setState, 'deductible', value);

      setState({ ...state, deductible: value })
    }

    const handleCreateInvoice = () => {
      setState({ ...state, isLoading: true })

      const cb = () => setState({ 
        ...state, 
        isLoading: false,
        noteId: 0,
        copay: 0,
        coinsurance: 0,
        deductible: 0
       })

      insuranceManagementService
        .createPatientResponsibilityInvoice(state.noteId, state.copay, state.coinsurance, state.deductible)
        .subscribe(cb, cb);
    }
     

    return [
        state,
        handleNoteIdChange,
        handleCopayChange,
        handleCoinsuranceChange,
        handleDeductibleChange,
        handleNoteIdOnBlur,
        handleCreateInvoice
    ];
}