import { useState } from "react";

interface CreatePatientAppointmentDialogComponentState {
    toShowDialog: boolean;
    toContinueDialog: boolean;
}

/**
 * Custom Hook to manage a view Model for Appointments component
 */
export function useFacade(): [CreatePatientAppointmentDialogComponentState, () => void, () => void] {
    const [state, setState] = useState({
        toShowDialog: false,
        toContinueDialog: false,
    } as CreatePatientAppointmentDialogComponentState);

    const handleContinueDialog = () => {
        setState(state => ({...state, toContinueDialog: !state.toContinueDialog}));
    }

    const handleToggleDialog = () => {
        if (state.toShowDialog) {
           return setState(state => ({...state, toShowDialog: !state.toShowDialog, toContinueDialog: false}));
        }
        setState(state => ({...state, toShowDialog: !state.toShowDialog}));
    }



    return [state, handleToggleDialog, handleContinueDialog];
}