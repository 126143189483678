import Axios from 'axios-observable';
import { authHeader } from "../../common/helpers/auth-header";
import { snackService } from "../../common/snack/state";
import { Observable, Subject } from "rxjs";
import { CallbackModel } from "../../common/models/callback.model";
import { DocumentsStore, documentsStore } from '../../inputs/stores/documentsStore';
import { DocumentSourceModel, DocumentSourceUploadModel, UpdateDocumentSourceModel, DocumentSourceTypeModel } from '../models/documentSource.model';


export class KbDocumentsService {
    private url = `${process.env.REACT_APP_API_URL}KbDocuments`;

    constructor(private documentsStore: DocumentsStore) {
    }

    public onUpload = new Subject<CallbackModel<null>>();

    public onUpdate = new Subject<CallbackModel<DocumentSourceModel>>();

    public getKbDocuments(): Observable<DocumentSourceModel[]> {

        const config = { headers: authHeader() };

        return new Observable((observer) => {
            Axios.get<DocumentSourceModel[]>(`${this.url}`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.documentsStore.update({ kbDocuments: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getDocumentSourceTypes(): Observable<DocumentSourceTypeModel[]> {

        const config = { headers: authHeader() };

        return new Observable((observer) => {
            Axios.get<DocumentSourceTypeModel[]>(`${this.url}/Types`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.documentsStore.update({ documentSourceTypes: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public uploadKbDocumentSource(): Observable<any> {
        return new Observable(observer => {
            this.onUpload.next({
                data: null,
                callback: (model: DocumentSourceUploadModel) => {
                    if ((!model?.file && !model?.url) || !model) {
                        observer.error();
                        observer.complete();
                        return;
                    }

                    const formData = new FormData();
                    formData.append('name', model.name);
                    formData.append('documentSourceTypeId', model.documentSourceTypeId.toString());
                    if (model.file) {
                        formData.append('file', model.file);
                    }
                    if (model.url) {
                        formData.append('url', model.url);
                    }
                    if (model.tags.length) {
                        model.tags.forEach(item => {
                            formData.append('tags[]', item.toString());
                        });
                    }
                    if (model.personaIds.length) {
                        model.personaIds.forEach(item => {
                            formData.append('personaIds[]', item.toString());
                        });
                    }

                    Axios.post(`${this.url}/UploadDocumentSource`, formData, { headers: authHeader() })
                        .pipe()
                        .subscribe(
                            (response) => {
                                snackService.success(`Document Source successfully uploaded!`);
                                observer.next(response.data);
                                observer.complete();
                            },
                            error => {
                                snackService.commonErrorHandler(error);
                                observer.error(error);
                                observer.complete();
                            }
                        );
                }
            });
        });
    }

    public updateKbDocumentSource(source: DocumentSourceModel): Observable<any> {
        return new Observable(observer => {
            this.onUpdate.next({
                data: source,
                callback: (model: UpdateDocumentSourceModel) => {
                    if (!model) {
                        observer.error();
                        observer.complete();
                        return;
                    }
                    Axios.put(`${this.url}/${model.id}`, model, { headers: authHeader() })
                        .pipe()
                        .subscribe(
                            (response) => {
                                observer.next(response.data);
                                this.documentsStore.updateKbDocument(response.data);
                                observer.complete();
                            },
                            error => {
                                snackService.commonErrorHandler(error);
                                observer.error(error);
                                observer.complete();
                            },
                        );
                }
            });
        });
    }

    public updateKbDocument(model: UpdateDocumentSourceModel): Observable<any> {
        return new Observable(observer => {
            Axios.put(`${this.url}/${model.id}`, model, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next(response.data);
                        this.documentsStore.updateKbDocument(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    },
                );
        });
    }

    public deleteKbDocument(id: number): Observable<any> {
        return new Observable(observer => {
            Axios.delete(`${this.url}/${id}`, { headers: authHeader() })
                .pipe()
                .subscribe(
                    () => {
                        snackService.success('Document Source successfully deleted!');
                        this.documentsStore.deleteKbDocument(id);
                        observer.next();
                        observer.complete();
                    },
                    error => snackService.commonErrorHandler(error)
                );
        }
        )
    }
}

export const kbDocumentsService = new KbDocumentsService(documentsStore);
