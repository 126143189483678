import TaskBgFirstHC from '@img/TaskBgFirstHC.png';
import TaskBgRegularHC from '@img/TaskBgRegularHC.png';
import TaskBgFirstPV from '@img/TaskBgFirstPV.png';
import TaskBgRegularPV from '@img/TaskBgRegularPV.png';
import TaskEmpty from '@img/TaskEmpty.png';

export enum EngagementCriteriaType {
    NoIMCForMoreThan2MonthsAfterCheckout = 10,
    NoIMCForMoreThan6MonthsAfterCheckout = 11,
    NoICCForMoreThan2WeeksAfterCheckout = 12,
    NoDnaReviewForMoreThan2MonthsAfterCheckout = 13,
    NoDnaReviewForMoreThan6MonthsAfterCheckout = 14,
    InsuranceNoMDVisitForMoreThan3MonthsSinceLastMDVisit = 20,
    CashNoMDVisitForMoreThan3MonthsSinceLastMDVisit = 30,
    NoHCVisitForMoreThanMonthSinceLastHC = 40,
    NoIMCForMoreThan1DayAfterDNAAndLabsReturned = 50,
    NoIMCForMoreThan2WeeksAfterDNAAndLabsReturned = 51,
    NoDnaReviewForMoreThan1DayAfterDNAAndLabsReturned = 52,
    NoDnaReviewForMoreThan2WeeksAfterDNAAndLabsReturned = 53,
    NoICCForMoreThan1DayAfterHCAssigned = 60,
    IMCCompleted1DayAgo = 70,
    PatientBirthdayIsToday = 1000,
    Empty = 1,
}

export const myTaskFullImages = new Map<EngagementCriteriaType, any>()
    .set(EngagementCriteriaType.NoIMCForMoreThan2MonthsAfterCheckout, TaskBgFirstHC)
    .set(EngagementCriteriaType.NoIMCForMoreThan6MonthsAfterCheckout, TaskBgFirstPV)
    .set(EngagementCriteriaType.NoICCForMoreThan2WeeksAfterCheckout, TaskBgRegularHC)
    .set(EngagementCriteriaType.NoDnaReviewForMoreThan2MonthsAfterCheckout, TaskBgRegularPV)
    .set(EngagementCriteriaType.NoDnaReviewForMoreThan6MonthsAfterCheckout, TaskBgRegularHC)
    .set(EngagementCriteriaType.InsuranceNoMDVisitForMoreThan3MonthsSinceLastMDVisit, TaskBgRegularPV)
    .set(EngagementCriteriaType.CashNoMDVisitForMoreThan3MonthsSinceLastMDVisit, TaskBgFirstHC)
    .set(EngagementCriteriaType.NoHCVisitForMoreThanMonthSinceLastHC, TaskBgFirstPV)
    .set(EngagementCriteriaType.NoIMCForMoreThan1DayAfterDNAAndLabsReturned, TaskBgFirstHC)
    .set(EngagementCriteriaType.NoIMCForMoreThan2WeeksAfterDNAAndLabsReturned, TaskBgRegularPV)
    .set(EngagementCriteriaType.NoDnaReviewForMoreThan1DayAfterDNAAndLabsReturned, TaskBgRegularHC)
    .set(EngagementCriteriaType.NoDnaReviewForMoreThan2WeeksAfterDNAAndLabsReturned, TaskBgRegularPV)
    .set(EngagementCriteriaType.NoICCForMoreThan1DayAfterHCAssigned, TaskBgRegularHC)
    .set(EngagementCriteriaType.PatientBirthdayIsToday, TaskBgFirstPV)
    .set(EngagementCriteriaType.IMCCompleted1DayAgo, TaskBgRegularHC)
    .set(EngagementCriteriaType.Empty, TaskEmpty)


export const EngagementCriteriaTypeTitle: { [type: number]: string } = {
    [EngagementCriteriaType.NoIMCForMoreThan2MonthsAfterCheckout]: '',
    [EngagementCriteriaType.InsuranceNoMDVisitForMoreThan3MonthsSinceLastMDVisit]: 'Why Schedule a Physician Visit?',
    [EngagementCriteriaType.CashNoMDVisitForMoreThan3MonthsSinceLastMDVisit]: 'Why Schedule a Physician Visit?',
    [EngagementCriteriaType.NoHCVisitForMoreThanMonthSinceLastHC]: 'Why Schedule a Health Coaching Session?',
    [EngagementCriteriaType.NoICCForMoreThan2WeeksAfterCheckout]: 'Why Schedule Your ICC?',
    [EngagementCriteriaType.NoIMCForMoreThan1DayAfterDNAAndLabsReturned]: 'Why Schedule Your IMC?',
    [EngagementCriteriaType.NoIMCForMoreThan2WeeksAfterDNAAndLabsReturned]: 'Why Schedule Your IMC?',
    [EngagementCriteriaType.NoIMCForMoreThan6MonthsAfterCheckout]: '',
    [EngagementCriteriaType.NoICCForMoreThan1DayAfterHCAssigned]: 'Why Schedule Your ICC?',
    [EngagementCriteriaType.IMCCompleted1DayAgo]: 'Why Schedule a Health Coaching Session?',
    [EngagementCriteriaType.NoDnaReviewForMoreThan2MonthsAfterCheckout]: 'Why Schedule Your DNA Review?',
    [EngagementCriteriaType.NoDnaReviewForMoreThan6MonthsAfterCheckout]: 'Why Schedule Your DNA Review?',
    [EngagementCriteriaType.NoDnaReviewForMoreThan1DayAfterDNAAndLabsReturned]: 'Why Schedule Your DNA Review?',
    [EngagementCriteriaType.NoDnaReviewForMoreThan2WeeksAfterDNAAndLabsReturned]: 'Why Schedule Your DNA Review?',
}

export const EngagementCriteriaTypeBody: { [type: number]: string } = {
    [EngagementCriteriaType.NoIMCForMoreThan2MonthsAfterCheckout]: '',
    [EngagementCriteriaType.InsuranceNoMDVisitForMoreThan3MonthsSinceLastMDVisit]: 'Data shows, patients who meet with their physician more frequently experience more significant outcomes and improvements to their health. We recommend quarterly Physician Visits for maximum impact. Plus, it\'s covered by insurance - so you can save up to $175! ',
    [EngagementCriteriaType.CashNoMDVisitForMoreThan3MonthsSinceLastMDVisit]: 'Data shows, patients who meet with their physician more frequently experience more significant outcomes and improvements to their health. We recommend quarterly Physician Visits for maximum impact.',
    [EngagementCriteriaType.NoHCVisitForMoreThanMonthSinceLastHC]: 'Data shows, meeting with your health coach more regularly leads to more significant progress and outcomes. Take advantage of unlimited health coaching to achieve your goals. Our coaches have specialized training in behavioral change and Precision Medicine – giving them a unique ability to equip you with tips, guidance, support, and solutions to challenges at every stage of your journey. We recommend monthly Health Coaching Sessions for maximum impact.',
    [EngagementCriteriaType.NoICCForMoreThan2WeeksAfterCheckout]: 'Your Initial Coaching Call (ICC) is your first opportunity to meet your coach (and accountability buddy!), outline your primary health goals, address questions or concerns, and leave with actionable next steps to kickstart your health journey. As agents of change, our coaches will determine a regular cadence for your meetings to ensure you’re on track and building habits and behaviors that support your goals.',
    [EngagementCriteriaType.NoIMCForMoreThan1DayAfterDNAAndLabsReturned]: 'During your Initial Medical Consult (IMC), a board-certified Precision Medicine Physician will break down the details of your Personal Health Report. You’ll talk through the implications of your genetics across all key pillars of health: nutrition, exercise, sleep, neurobehavioral, chronic disease risk, and more – alongside recommendations for achieving optimal health. Your physician will leave you with a clear path forward with top interventions to help you achieve your individual health goals.',
    [EngagementCriteriaType.NoIMCForMoreThan2WeeksAfterDNAAndLabsReturned]: 'During your Initial Medical Consult (IMC), a board-certified Precision Medicine Physician will break down the details of your Personal Health Report. You’ll talk through the implications of your genetics across all key pillars of health: nutrition, exercise, sleep, neurobehavioral, chronic disease risk, and more – alongside recommendations for achieving optimal health. Your physician will leave you with a clear path forward with top interventions to help you achieve your individual health goals.',
    [EngagementCriteriaType.NoIMCForMoreThan6MonthsAfterCheckout]: '',
    [EngagementCriteriaType.NoICCForMoreThan1DayAfterHCAssigned]: 'Your Initial Coaching Call (ICC) is your first opportunity to meet your coach (and accountability buddy!), outline your primary health goals, address questions or concerns, and leave with actionable next steps to kickstart your health journey. As agents of change, our coaches will determine a regular cadence for your meetings to ensure you’re on track and building habits and behaviors that support your goals.',
    [EngagementCriteriaType.IMCCompleted1DayAgo]: 'Data shows, meeting with your health coach more regularly leads to more significant progress and outcomes. Take advantage of unlimited health coaching to achieve your goals. Our coaches have specialized training in behavioral change and Precision Medicine – giving them a unique ability to equip you with tips, guidance, support, and solutions to challenges at every stage of your journey. We recommend monthly Health Coaching Sessions for maximum impact.',
    [EngagementCriteriaType.NoDnaReviewForMoreThan2MonthsAfterCheckout]: 'During your DNA Review, a board-certified Precision Medicine Physician will break down the details of your Personal Health Report. You’ll talk through the implications of your genetics across all key pillars of health: nutrition, exercise, sleep, neurobehavioral, chronic disease risk, and more – alongside recommendations for achieving optimal health. Your physician will leave you with a clear path forward with top interventions to help you achieve your individual health goals.',
    [EngagementCriteriaType.NoDnaReviewForMoreThan6MonthsAfterCheckout]: 'During your DNA Review, a board-certified Precision Medicine Physician will break down the details of your Personal Health Report. You’ll talk through the implications of your genetics across all key pillars of health: nutrition, exercise, sleep, neurobehavioral, chronic disease risk, and more – alongside recommendations for achieving optimal health. Your physician will leave you with a clear path forward with top interventions to help you achieve your individual health goals.',
    [EngagementCriteriaType.NoDnaReviewForMoreThan1DayAfterDNAAndLabsReturned]: 'During your DNA Review, a board-certified Precision Medicine Physician will break down the details of your Personal Health Report. You’ll talk through the implications of your genetics across all key pillars of health: nutrition, exercise, sleep, neurobehavioral, chronic disease risk, and more – alongside recommendations for achieving optimal health. Your physician will leave you with a clear path forward with top interventions to help you achieve your individual health goals.',
    [EngagementCriteriaType.NoDnaReviewForMoreThan2WeeksAfterDNAAndLabsReturned]: 'During your DNA Review, a board-certified Precision Medicine Physician will break down the details of your Personal Health Report. You’ll talk through the implications of your genetics across all key pillars of health: nutrition, exercise, sleep, neurobehavioral, chronic disease risk, and more – alongside recommendations for achieving optimal health. Your physician will leave you with a clear path forward with top interventions to help you achieve your individual health goals.',
}