import { Box, FormControl, MenuItem, Select } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import commonUseStyles from '../../../common/styles/common.styles';
import { AppointmentTypeModel } from '../../models/appointments.models';

interface SelectAppointmentTypeComponentProps {
    handleChange: (value: AppointmentTypeModel) => void,
    value: AppointmentTypeModel,
    types: AppointmentTypeModel[],
    disabled?: boolean
}

export const SelectAppointmentTypeComponent: React.FC<SelectAppointmentTypeComponentProps> = (props: SelectAppointmentTypeComponentProps) => {
    const {
        handleChange,
        value,
        types,
        disabled = false
    } = props;

    const commonClasses = commonUseStyles();

    return (
        <Box>
            <Box>
                <span className={clsx(commonClasses.size14, commonClasses.colorGray1)}>Appointment Type</span>
            </Box>
            <Box mt={1}>
                <FormControl
                    variant="outlined"
                    color='primary'
                    size="small"
                    fullWidth
                    disabled={disabled}>
                    <Select
                        value={value?.id}
                        onChange={(event) => handleChange(types.find(x => x.id === event.target.value))}>
                        {
                            types.map((type, i) => (
                                <MenuItem key={i} value={type.id}>{type.name}</MenuItem>
                                )
                            )
                        }
                    </Select>
                </FormControl>
            </Box>
        </Box>
    );
}