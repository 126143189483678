import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            paddingTop: 12,
            position: 'relative',
            background: colors.bg,
            width: 70,
            marginLeft: 22,
            height: '100%'
        },
        closeWrapper: {
            position: 'relative',
            textAlign: 'center',
        },
        closeTimeline: {
            position: 'absolute',
            width: 0,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        content: {
            overflowY: 'auto',
        },
        closeTitle: {
            width: '200px',
            marginTop: 250,
            transform: ' rotate(-90deg) translate(0px, -68px)',
            position: 'absolute',
        },
        openTitle: {
            fontSize: 24,
            fontWeight: 500,
        },
        openTimeline: {
            paddingTop: 12,
            marginRight: 8,
            paddingLeft: 30,
            background: colors.bg,
            height: '100%',
            position: 'absolute',
            right: 0,
            top: 0,
            width: 528,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            zIndex: 11,
        },
        icon: {
            stroke: colors.main,
            height: 26,
            width: 26,
        },
        active: {
            color: colors.main,
        },
        closeIcon: {
            color: colors.main,
            marginRight: 8,
            width: 24,
            height: 24,

        },
        action: {
            color: colors.main,
            fontSize: 14,
            fontWeight: 500,
        },
        eventMainContainer: {
            position: 'relative',
            padding: '0px 24px',
            borderLeft: `0.5px solid ${colors.gray2}`,
        },
        iconContainer: {
            position: 'absolute',
            background: colors.bg,
            padding: 5,
            top: -10,
            left: -18,
        },
        iconContainerCommon: {
            position: 'absolute',
            background: colors.bg,
            padding: 5,
            top: -7,
            left: -12,
        },
        iconWrapper: {
            width: 26,
            height: 26,
            maxWidth: 26,
            minWidth: 26,
            padding: 6,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            background: `${colors.accent1}14`
        },
    })
);