import { Box, Checkbox, Divider } from '@material-ui/core';
import React from 'react';
import { ExpandableComponent } from '../../../common/components/expandableComponent/ExpandableComponent';
import { useFacade } from './recommendationsUpdatePreviousComponent.hooks';
import { commonUseStyles } from '../../../common/styles/common.styles';
import { recommendationNameOld, RecommendationTypes, RecommendationTypesNumbers } from '../../models/healthReport.models';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { colors } from '../../../common/constants/colors';
import { RecommendationSelectUpdateComponent } from '../recommendationSelectUpdateComponent/RecommendationSelectUpdateComponent';

export interface RecommendationsUpdatePreviousComponentProps {
    patientId?: number;
    isPrepareRecommendation: boolean;
}

export const RecommendationsUpdatePreviousComponent: React.FC<RecommendationsUpdatePreviousComponentProps> = (props: RecommendationsUpdatePreviousComponentProps) => {
    const {
        patientId,
        isPrepareRecommendation,
    } = props;

    const [
        state,
        handleExpandSection,
        handleSelectedNew,
        handleSelectedPrev,
        handleChangesNew,
        getRecommendationNew,
        getRecommendationCurrent,
        handleAddRecommendation,
        handleSelectedAllNew,
        handleSelectedAllPrevious,
        handleUpdateRecommendation,
    ] = useFacade(patientId, isPrepareRecommendation);


    const controlIcon = <CheckBoxOutlineBlankIcon style={{ fill: colors.mediumGray }} />;
    const controlCheckedIcon = <CheckBoxIcon style={{ fill: colors.main }} />
    const commonClasses = commonUseStyles();

    const sectionsContent = {
        [RecommendationTypes.Macronutrient]:
            <RecommendationSelectUpdateComponent
                currentRecommendation={getRecommendationCurrent(RecommendationTypesNumbers.Macronutrient)}
                newRecommendation={getRecommendationNew(RecommendationTypesNumbers.Macronutrient)}
                type={RecommendationTypesNumbers.Macronutrient}
                handleSelectedNew={handleSelectedNew}
                handleSelectedPrev={handleSelectedPrev}
                handleChangesNew={handleChangesNew}
                handleAddRecommendation={handleAddRecommendation}
                handleUpdateRecommendation={handleUpdateRecommendation}
                key={`recommendation-section-${RecommendationTypesNumbers.Macronutrient}`}
            />,
        [RecommendationTypes.VitaminsAndMicronutrients]:
            <RecommendationSelectUpdateComponent
                currentRecommendation={getRecommendationCurrent(RecommendationTypesNumbers.VitaminsAndMicronutrients)}
                newRecommendation={getRecommendationNew(RecommendationTypesNumbers.VitaminsAndMicronutrients)}
                type={RecommendationTypesNumbers.VitaminsAndMicronutrients}
                handleSelectedNew={handleSelectedNew}
                handleSelectedPrev={handleSelectedPrev}
                handleChangesNew={handleChangesNew}
                handleAddRecommendation={handleAddRecommendation}
                handleUpdateRecommendation={handleUpdateRecommendation}
                key={`recommendation-section-${RecommendationTypesNumbers.VitaminsAndMicronutrients}`}
            />,
        [RecommendationTypes.Methylation]:
            <RecommendationSelectUpdateComponent
                currentRecommendation={getRecommendationCurrent(RecommendationTypesNumbers.Methylation)}
                newRecommendation={getRecommendationNew(RecommendationTypesNumbers.Methylation)}
                type={RecommendationTypesNumbers.Methylation}
                handleSelectedNew={handleSelectedNew}
                handleSelectedPrev={handleSelectedPrev}
                handleChangesNew={handleChangesNew}
                handleAddRecommendation={handleAddRecommendation}
                handleUpdateRecommendation={handleUpdateRecommendation}
                key={`recommendation-section-${RecommendationTypesNumbers.Methylation}`}
            />,
        [RecommendationTypes.KryptoniteFoods]:
            <RecommendationSelectUpdateComponent
                currentRecommendation={getRecommendationCurrent(RecommendationTypesNumbers.KryptoniteFoods)}
                newRecommendation={getRecommendationNew(RecommendationTypesNumbers.KryptoniteFoods)}
                type={RecommendationTypesNumbers.KryptoniteFoods}
                handleSelectedNew={handleSelectedNew}
                handleSelectedPrev={handleSelectedPrev}
                handleChangesNew={handleChangesNew}
                handleAddRecommendation={handleAddRecommendation}
                handleUpdateRecommendation={handleUpdateRecommendation}
                key={`recommendation-section-${RecommendationTypesNumbers.KryptoniteFoods}`}
            />,
        [RecommendationTypes.SuperFoods]:
            <RecommendationSelectUpdateComponent
                currentRecommendation={getRecommendationCurrent(RecommendationTypesNumbers.SuperFoods)}
                newRecommendation={getRecommendationNew(RecommendationTypesNumbers.SuperFoods)}
                type={RecommendationTypesNumbers.SuperFoods}
                handleSelectedNew={handleSelectedNew}
                handleSelectedPrev={handleSelectedPrev}
                handleChangesNew={handleChangesNew}
                handleAddRecommendation={handleAddRecommendation}
                handleUpdateRecommendation={handleUpdateRecommendation}
                key={`recommendation-section-${RecommendationTypesNumbers.SuperFoods}`}
            />,
        [RecommendationTypes.ExerciseAndRecovery]:
            <RecommendationSelectUpdateComponent
                currentRecommendation={getRecommendationCurrent(RecommendationTypesNumbers.ExerciseAndRecovery)}
                newRecommendation={getRecommendationNew(RecommendationTypesNumbers.ExerciseAndRecovery)}
                type={RecommendationTypesNumbers.ExerciseAndRecovery}
                handleSelectedNew={handleSelectedNew}
                handleSelectedPrev={handleSelectedPrev}
                handleChangesNew={handleChangesNew}
                handleAddRecommendation={handleAddRecommendation}
                handleUpdateRecommendation={handleUpdateRecommendation}
                key={`recommendation-section-${RecommendationTypesNumbers.ExerciseAndRecovery}`}
            />,
        [RecommendationTypes.Sleep]:
            <RecommendationSelectUpdateComponent
                currentRecommendation={getRecommendationCurrent(RecommendationTypesNumbers.Sleep)}
                newRecommendation={getRecommendationNew(RecommendationTypesNumbers.Sleep)}
                type={RecommendationTypesNumbers.Sleep}
                handleSelectedNew={handleSelectedNew}
                handleSelectedPrev={handleSelectedPrev}
                handleChangesNew={handleChangesNew}
                handleAddRecommendation={handleAddRecommendation}
                handleUpdateRecommendation={handleUpdateRecommendation}
                key={`recommendation-section-${RecommendationTypesNumbers.Sleep}`}
            />,
        [RecommendationTypes.Neurobehavioral]:
            <RecommendationSelectUpdateComponent
                currentRecommendation={getRecommendationCurrent(RecommendationTypesNumbers.Neurobehavioral)}
                newRecommendation={getRecommendationNew(RecommendationTypesNumbers.Neurobehavioral)}
                type={RecommendationTypesNumbers.Neurobehavioral}
                handleSelectedNew={handleSelectedNew}
                handleSelectedPrev={handleSelectedPrev}
                handleChangesNew={handleChangesNew}
                handleAddRecommendation={handleAddRecommendation}
                handleUpdateRecommendation={handleUpdateRecommendation}
                key={`recommendation-section-${RecommendationTypesNumbers.Neurobehavioral}`}
            />,
        [RecommendationTypes.Microbiome]:
            <RecommendationSelectUpdateComponent
                currentRecommendation={getRecommendationCurrent(RecommendationTypesNumbers.Microbiome)}
                newRecommendation={getRecommendationNew(RecommendationTypesNumbers.Microbiome)}
                type={RecommendationTypesNumbers.Microbiome}
                handleSelectedNew={handleSelectedNew}
                handleSelectedPrev={handleSelectedPrev}
                handleChangesNew={handleChangesNew}
                handleAddRecommendation={handleAddRecommendation}
                handleUpdateRecommendation={handleUpdateRecommendation}
                key={`recommendation-section-${RecommendationTypesNumbers.Microbiome}`}
            />,
        [RecommendationTypes.Cardiovascular]:
            <RecommendationSelectUpdateComponent
                currentRecommendation={getRecommendationCurrent(RecommendationTypesNumbers.Cardiovascular)}
                newRecommendation={getRecommendationNew(RecommendationTypesNumbers.Cardiovascular)}
                type={RecommendationTypesNumbers.Cardiovascular}
                handleSelectedNew={handleSelectedNew}
                handleSelectedPrev={handleSelectedPrev}
                handleChangesNew={handleChangesNew}
                handleAddRecommendation={handleAddRecommendation}
                handleUpdateRecommendation={handleUpdateRecommendation}
                key={`recommendation-section-${RecommendationTypesNumbers.Cardiovascular}`}
            />,
        [RecommendationTypes.Dementia]:
            <RecommendationSelectUpdateComponent
                currentRecommendation={getRecommendationCurrent(RecommendationTypesNumbers.Dementia)}
                newRecommendation={getRecommendationNew(RecommendationTypesNumbers.Dementia)}
                type={RecommendationTypesNumbers.Dementia}
                handleSelectedNew={handleSelectedNew}
                handleSelectedPrev={handleSelectedPrev}
                handleChangesNew={handleChangesNew}
                handleAddRecommendation={handleAddRecommendation}
                handleUpdateRecommendation={handleUpdateRecommendation}
                key={`recommendation-section-${RecommendationTypesNumbers.Dementia}`}
            />,
        [RecommendationTypes.InsulinResistance]:
            <RecommendationSelectUpdateComponent
                currentRecommendation={getRecommendationCurrent(RecommendationTypesNumbers.InsulinResistance)}
                newRecommendation={getRecommendationNew(RecommendationTypesNumbers.InsulinResistance)}
                type={RecommendationTypesNumbers.InsulinResistance}
                handleSelectedNew={handleSelectedNew}
                handleSelectedPrev={handleSelectedPrev}
                handleChangesNew={handleChangesNew}
                handleAddRecommendation={handleAddRecommendation}
                handleUpdateRecommendation={handleUpdateRecommendation}
                key={`recommendation-section-${RecommendationTypesNumbers.InsulinResistance}`}
            />,
        [RecommendationTypes.Inflammation]:
            <RecommendationSelectUpdateComponent
                currentRecommendation={getRecommendationCurrent(RecommendationTypesNumbers.Inflammation)}
                newRecommendation={getRecommendationNew(RecommendationTypesNumbers.Inflammation)}
                type={RecommendationTypesNumbers.Inflammation}
                handleSelectedNew={handleSelectedNew}
                handleSelectedPrev={handleSelectedPrev}
                handleChangesNew={handleChangesNew}
                handleAddRecommendation={handleAddRecommendation}
                handleUpdateRecommendation={handleUpdateRecommendation}
                key={`recommendation-section-${RecommendationTypesNumbers.Inflammation}`}
            />,
        [RecommendationTypes.Longevity]:
            <RecommendationSelectUpdateComponent
                currentRecommendation={getRecommendationCurrent(RecommendationTypesNumbers.Longevity)}
                newRecommendation={getRecommendationNew(RecommendationTypesNumbers.Longevity)}
                type={RecommendationTypesNumbers.Longevity}
                handleSelectedNew={handleSelectedNew}
                handleSelectedPrev={handleSelectedPrev}
                handleChangesNew={handleChangesNew}
                handleAddRecommendation={handleAddRecommendation}
                handleUpdateRecommendation={handleUpdateRecommendation}
                key={`recommendation-section-${RecommendationTypesNumbers.Longevity}`}

            />,
        [RecommendationTypes.Supplements]:
            <RecommendationSelectUpdateComponent
                currentRecommendation={getRecommendationCurrent(RecommendationTypesNumbers.Supplements)}
                newRecommendation={getRecommendationNew(RecommendationTypesNumbers.Supplements)}
                type={RecommendationTypesNumbers.Supplements}
                handleSelectedNew={handleSelectedNew}
                handleSelectedPrev={handleSelectedPrev}
                handleChangesNew={handleChangesNew}
                handleAddRecommendation={handleAddRecommendation}
                handleUpdateRecommendation={handleUpdateRecommendation}
                key={`recommendation-section-${RecommendationTypesNumbers.Supplements}`}
            />,
    } as { [id: number]: JSX.Element }

    const renderSections = (): JSX.Element => {
        return (
            <>
                {
                    Object.keys(sectionsContent).map(key => (
                        <Box id={`recommendation-component-${key}`} mt={3}>
                            <ExpandableComponent
                                divider={false}
                                title={recommendationNameOld[key]}
                                content={sectionsContent[key]}
                                expanded={state.sections[key]}
                                refCurrent={null}
                                handleExpand={() => handleExpandSection(+key)}
                            />
                            <Box mt={2}>
                                <Divider className={commonClasses.renderDivider} />
                            </Box>
                        </Box>
                    ))
                }
            </>
        );
    }

    return (
        <Box>
            <Box ml={-1.5} mt={2} display="flex" alignItems="center">
                <Checkbox
                    icon={controlIcon}
                    color="primary"
                    checkedIcon={controlCheckedIcon}
                    checked={state.isSelectedAllNew}
                    onChange={() => handleSelectedAllNew()}
                />
                <Box mr={3}>Select All New Recommendations</Box>
                <Checkbox
                    icon={controlIcon}
                    color="primary"
                    checkedIcon={controlCheckedIcon}
                    checked={state.isSelectedAllPrev}
                    onChange={() => handleSelectedAllPrevious()}
                />
                <Box>Select All Previous Recommendations</Box>
            </Box>
            {
                renderSections()
            }
        </Box>
    );
}
