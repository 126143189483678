import React from 'react';
import clsx from 'clsx';
import { Box, CircularProgress, Divider, Drawer, IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useStyles } from './employeeChatComponent.styles';
import { useFacade } from './employeeChatComponent.hooks';
import { SecondaryButton } from '../../../common/components/wildHealthButton/SecondaryButton';
import { ReactComponent as OpenSideIcon } from '@img/icons/OpenSide.svg';
import { ConversationComponent } from "../conversationComponent/ConversationComponent";
import { MessageAvatarsComponent } from '../messageAvatarsComponent/MessageAvatarsComponent';
import { ManageChatMembersComponent } from '../chatMembersComponent/ManageChatMembersComponent';
import { AttachmentsComponent } from '../attachmentsComponent/AttachmentsComponent';
import { ScheduledMessagesComponent } from '../scheduledMessagesComponent/ScheduledMessagesComponent';
import { SelectUserDialogComponent } from '../selectUserDialogComponent/SelectUserDialogComponent';
import { employeeService } from '../../../employee/services/employees.service';
import { EmployeeType, employeeTypesNames } from '../../../employee/models/employee.enums';
import { ConversationState } from '../../models/conversationState.models';
import { ConversationType, EmployeeConversationModel, EmployeeConversationParticipantModel } from '../../models/conversation.models';
import { WildHealthLinkButton } from '../../../common/components/WildHealthLinkButton';
import { displayShortName } from '../../helpers/messages.converter';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import {getConversationMembers} from "../../helpers/getMembers";
import { AttachmentsViewComponent } from '../attachmentsViewComponent/AttachmentsViewComponent';
import { profileQuery } from '../../../account/stores/profileStore';
import { PatientInfoTooltipComponent } from '../../../employee/components/patientInfoTooltipComponent/PatientInfoTooltipComponent';

interface EmployeeChatComponentProps {
    targetConversation?: EmployeeConversationModel;
    resetTargetConversation: () => void;
    handleGoBack: () => void;
}

export const EmployeeChatComponent: React.FC<EmployeeChatComponentProps> = (props: EmployeeChatComponentProps) => {
    const { targetConversation, handleGoBack } = props;
    const classes = useStyles();

    const [
        {
            isClosing,
            isLoading,
            author,
            isSideAreaOpen,
            isEmployeeAssigned,
            isAddUserOpen,
            employeeType,
            connectionStatus
        },
        handleToggleSideArea,
        handleToggleAddUser,
        handleAddUser,
        handleRemoveUserFromThread,
        handleCloseTicket,
        goToPatientProfile
    ] = useFacade(handleGoBack, targetConversation);

    const theme = useTheme();
    const isMiddleScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    if (!targetConversation) {
        return <></>
    }

    const renderMobileSideArea = (className: string) => {
        const members = targetConversation
            ? targetConversation.employees
                .filter((i) => i.isActive && !i.isDeleted)
                .map((i) => ({
                    image: profileQuery.getPhoto(i.employeeId),
                    firstName: i.firstName,
                    lastName: i.lastName,
                    position: employeeTypesNames.get(i.type),
                    initials: displayShortName(i.firstName, i.lastName),
                }))
            : [
                { firstName: "Health", lastName: "Coach", initials: "HC", position: "Health Coach" },
                { firstName: "Provider", lastName: "", initials: "Pr", position: "Provider" },
            ];
        const patient = targetConversation
            ? targetConversation.patients
                .map((i) => ({
                    image: '',
                    firstName: i.firstName,
                    lastName: i.lastName,
                    initials: displayShortName(i.firstName, i.lastName),
                }))
            : [
                { initials: "P", image: "", firstName: "Patient", lastName: "" },
            ];

        return (
            <Box className={className}>
                <Box className={classes.goBack}>
                    <IconButton id="close-side-bar" onClick={() => handleToggleSideArea(!isSideAreaOpen)}>
                        <Box display="flex" alignItems="center">
                            <ArrowBackIcon className="wh-tw-text-gray1" />
                            <Box ml={1} className="wh-tw-text-gray1 wh-tw-text-base">Back</Box>
                        </Box>
                    </IconButton>
                </Box>
                <Box className={classes.header}>
                    <Box mt={2} className={classes.titleAndAvatars}>
                        <Box>
                            <MessageAvatarsComponent
                                participants={patient}
                                size={isSmallScreen && 34}
                            />
                        </Box>
                        <Box pl={1} className="wh-tw-font-medium wh-tw-text-lg wh-tw-pt-2.5">
                            {`${patient[0].firstName} ${patient[0].lastName}`}
                        </Box>
                    </Box>
                </Box>
                <Box px={2} width={1}>
                    {isLoading
                        ? <WildHealthLinearProgress />
                        : <ManageChatMembersComponent
                            isDialogActiveUsers={false}
                            members={members}
                            disableAddMember={targetConversation.state === ConversationState.Closed}
                            disableRemoveMember={targetConversation.state === ConversationState.Closed}
                            handleAddMember={() => handleToggleAddUser()}
                            handleRemoveMember={(id) => handleRemoveUserFromThread(id)}
                        />}

                    <Divider className={classes.divider} orientation='horizontal' />

                    <ScheduledMessagesComponent />

                    <Divider className={classes.divider} orientation="horizontal" />

                    {targetConversation && !targetConversation.proxy && targetConversation.state === ConversationState.Closed ? <AttachmentsViewComponent /> : <AttachmentsComponent />}
                </Box>
            </Box>
        )
    }

    const renderHeaderArea = () => {
        const participants = [...targetConversation.patients, ...targetConversation.employees.filter(i => i.isActive && !i.isDeleted)];
        const initials = participants.map((i: EmployeeConversationParticipantModel) => ({ image: profileQuery.getPhoto(i?.employeeId ?? 0), initials: displayShortName(i.firstName, i.lastName) }));
        const patient = [...targetConversation.patients][0];

        if (isSmallScreen) {
            return (
                <Box p={2} className={classes.header}>
                    <Box display='flex'>
                        <Box>
                            <IconButton
                                id="chat-list-go-back"
                                onClick={() => handleGoBack()}
                            >
                                <ChevronLeftIcon />
                            </IconButton>
                        </Box>
                        <Box>
                            <MessageAvatarsComponent participants={initials} size={32} />
                        </Box>
                        <Box ml={2} display='flex' flexDirection='column' justifyContent='center' flex={1}>
                            <WildHealthLinkButton
                                id="employee-chat-link-patient-profile"
                                onClick={() => goToPatientProfile(patient.patientId)}
                                content={<Box className={classes.title}>{patient.firstName} {patient.lastName}</Box>}
                            />
                            {
                                targetConversation.subject &&
                                <Box className={classes.subject}>
                                    {targetConversation.type === ConversationType.Support ? targetConversation.subject : 'Healthcare'}
                                </Box>
                            }
                        </Box>
                        <Box>
                            <IconButton
                                id="attachment-views-open"
                                onClick={() => handleToggleSideArea(!isSideAreaOpen)}
                                disabled={isLoading}
                            >
                                <MoreHorizIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box mt={2} width={1}>
                        {
                            (employeeType === EmployeeType.Staff && targetConversation.type === ConversationType.Support && targetConversation.state !== ConversationState.Closed) &&
                                <SecondaryButton id="employee-chat-close-ticket" fullWidth disabled={isClosing} onClick={() => handleCloseTicket()}>
                                    {
                                        isClosing
                                            ? <CircularProgress size={24} className="wh-tw-text-primaryV" />
                                            : <span>Close Ticket</span>
                                    }
                                </SecondaryButton>
                        }
                        <Drawer
                            id="employee-chat-side-area-drawer"
                            anchor='right'
                            open={isSideAreaOpen}
                            onClose={() => handleToggleSideArea(!isSideAreaOpen)}
                        >
                            <Box className="wh-tw-bg-gray8 wh-tw-w-screen">
                                {renderMobileSideArea(clsx('flat-scroll', classes.sideContainer))}
                            </Box>
                        </Drawer>
                    </Box>
                </Box>
            )
        }
        
        return (
            <Box p={2} className={classes.header}>
                <Box display='flex'>
                    <Box>
                        <MessageAvatarsComponent participants={initials} />
                    </Box>
                    <Box ml={2} display='flex' flexDirection='column' justifyContent='center'>
                        <WildHealthLinkButton
                            id="employee-chat-link-patient-profile"
                            onClick={() => goToPatientProfile(patient.patientId)}
                            content={<Box className={classes.title}>{patient.firstName} {patient.lastName}</Box>}
                        />
                        {
                            targetConversation.subject &&
                            <Box className={classes.subject}>
                                {targetConversation.type === ConversationType.Support ? targetConversation.subject : 'Healthcare'}
                            </Box>
                        }
                    </Box>
                    <Box position='relative' pt={0.5} pl={1.5}>
                        <InfoIcon className={clsx(classes.iconInfo, "wh-tw-text-gray2")} fontSize='small' />
                        <PatientInfoTooltipComponent patientId={patient.patientId} />
                    </Box>
                </Box>

                <Box display='flex'>
                    {
                        (employeeType === EmployeeType.Staff && targetConversation.type === ConversationType.Support && targetConversation.state !== ConversationState.Closed) &&
                        <Box width={120}>
                            <SecondaryButton id="employee-chat-close-ticket" fullWidth disabled={isClosing} onClick={() => handleCloseTicket()}>
                                {
                                    isClosing
                                        ? <CircularProgress size={24} className="wh-tw-text-primaryV" />
                                        : <span>Close Ticket</span>
                                }
                            </SecondaryButton>
                        </Box>
                    }
                    {
                        isMiddleScreen &&
                        <Box ml={2}>
                            <IconButton id="employee-chat-side-area-icon" onClick={() => handleToggleSideArea(!isSideAreaOpen)}>
                                <OpenSideIcon className={classes.icon} />
                            </IconButton>
                            <Drawer
                                id="employee-chat-side-area-drawer"
                                anchor='right'
                                open={isSideAreaOpen}
                                onClose={() => handleToggleSideArea(!isSideAreaOpen)}
                            >
                                <Box className={classes.sideMobile}>
                                    {renderSideArea(clsx('flat-scroll', classes.sideContainer))}
                                </Box>
                            </Drawer>
                        </Box>
                    }
                </Box>
            </Box>
        )
    }

    const renderSideArea = (className: string) => {
        const members = getConversationMembers(targetConversation, isEmployeeAssigned);

        return (
            <Box className={className}>
                {isLoading
                    ? <WildHealthLinearProgress />
                    : <ManageChatMembersComponent
                        isDialogActiveUsers={false}
                        members={members}
                        disableAddMember={targetConversation.state === ConversationState.Closed}
                        disableRemoveMember={targetConversation.state === ConversationState.Closed}
                        handleAddMember={() => handleToggleAddUser()}
                        handleRemoveMember={(id) => handleRemoveUserFromThread(id)}
                    />}

                <Divider className={classes.divider} orientation='horizontal' />

                {targetConversation && !targetConversation.proxy && targetConversation.state === ConversationState.Closed ? <AttachmentsViewComponent /> : <AttachmentsComponent />}

                <Divider className={classes.divider} orientation='horizontal' />

                <ScheduledMessagesComponent />
            </Box>
        )
    }

    return (
        <div className={classes.chat}>
            <div className={clsx('g', classes.messages)}>
                {renderHeaderArea()}

                <Divider className={classes.divider} orientation='horizontal' />
                {!connectionStatus &&
                    <Box position="relative" width={1}>
                        <Box position="absolute" top={0} width={1} display="flex" alignItems="center" justifyContent="center" className="wh-tw-bg-average1 wh-tw-px-5 wh-tw-py-2.5 wh-tw-mb-3.5 wh-tw-rounded-sm wh-tw-z-50">
                            <Box display="flex" alignItems="center" justifyContent="center" className="wh-tw-bg-white wh-tw-rounded-full wh-tw-p-0.5" width="26px" height="26px">
                                <WarningRoundedIcon className="wh-tw-text-average1 -wh-tw-mt-1" />
                            </Box>
                            <Box className="wh-tw-text-white wh-tw-font-medium wh-tw-ml-3.5">Trying to reconnect. Please wait.</Box>
                        </Box>
                    </Box>
                }
                <ConversationComponent
                    conversation={targetConversation}
                    author={author}
                    canSchedule
                />
            </div>
            {
                !isMiddleScreen &&
                <div className={clsx('g', classes.side)}>
                    <Divider className={classes.divider} orientation='vertical' />

                    {renderSideArea(clsx('flat-scroll', classes.sideContainer, classes.sideMaxHeight))}
                </div>
            }
            <SelectUserDialogComponent
                isOpen={isAddUserOpen}
                title='Add User'
                message='Select User:'
                actionName='Add User'
                handleGetUsers={(search) => employeeService.getActive(search)}
                handleCancel={() => handleToggleAddUser()}
                handleSubmit={(id) => handleAddUser(id)}
            />
        </div>
    )
};
