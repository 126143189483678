import { makeStyles, Theme } from "@material-ui/core";
import { colors } from "../../modules/common/constants/colors";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiPopover-paper': {
      top: '0 !important',
      left: '0 !important',
      maxWidth: '100%',
      borderRadius: '0 0 20px 20px',
    },
  },
  content: {
    overflowX: 'hidden',
    overflowY: 'auto',
    height: '100%',
    width: '100vw',
  },
  menuList: {
    padding: 14,
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  menuIconSize: {
    width: 20,
    height: 20,
  },
  menuIcon: {
    minWidth: 0,
    color: colors.gray2,
  },
  referralIcon: {
    minWidth: 0,
    fill: colors.main,
    padding: 8,
  },
  referralTitle: {
    color: colors.main,
    fontWeight: 400,
    fontSize: 16,
    paddingLeft: 12,
    paddingRight: 18,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    },
  },
  menuTitle: {
    marginLeft: 20,
    fontFamily: 'Be Vietnam Pro',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 19,
  },
  menuItem: {
    borderRadius: 2,
    height: 40,
    margin: '4px 0',
    paddingLeft: 20,
    paddingRight: 20,
    color: colors.gray1,
  },
  menuItemSelected: {
    background: `${colors.main}0F !important`,
    color: colors.main,
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
}));