import {Store, StoreConfig} from '@datorama/akita';
import {MerchantCredentialsModel} from "../../models/payment.models";

/**
 * Represents payment state
 */
export interface PaymentState {
    credentials: MerchantCredentialsModel | null;
}

/**
 * Creates initial state
 */
export function createInitialState(): PaymentState {
    return {
        credentials: null
    };
}

/**
 * Provides payment states management
 */
@StoreConfig({name: 'payment', resettable: true })
export class PaymentStore extends Store<PaymentState> {
    constructor() {
        super(createInitialState());
    }
}

export const paymentStore = new PaymentStore();
