import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { colors } from '../../common/constants/colors';


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        linkBtn: {
            color: colors.main,
            display: 'flex',
        },
    }),
);