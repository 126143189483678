import { createStyles, makeStyles } from "@material-ui/core/styles";
import {colors} from "../../../../common/constants/colors";

export const useStyles = makeStyles(() =>
    createStyles({
        root: {
            height: 94,
            borderRadius: 12,
            border: '1px solid ' + colors.grayBorder,
            cursor: 'pointer',
            background: colors.white,
        },
        title: {
            fontSize: 14,
            fontWeight: 500,
            fontFamily: "Be Vietnam Pro",
            color: colors.black,
        },
        imageContainer: {
            minWidth: 92,
            minHeight: 92,
            borderRadius: '11px 0px 0px 11px',
            background: colors.lightGray2,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover'
        },
        container: {
            padding: 10,
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
        category: {
            background: colors.lightGray2,
            color: colors.gray1,
            borderRadius: 6,
            fontWeight: 600,
            fontSize: 12,
            height: 28,
            padding: 4,
            paddingTop: 6
        },
        date: {
            fontSize: 12,
            color: colors.gray1,
            fontWeight: 500
        }
    })
);
