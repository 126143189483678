import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { onEmit } from "../../../common/helpers/on-emit";
import { PatientSupplementModel } from "../../models/patientSupplementModel";
import { patientSupplementsQuery } from "../../stores/patientSupplementsStore/patientSupplements.query";
import { patientsSupplementsService } from "../../services/patientSupplements.service";
import { IErrorState } from "../../../common/validation/error-state";
import { supplementsService } from "../../services/supplements.service";
import { patientSupplementsStore } from "../../stores/patientSupplementsStore/patientSupplements.store";
import { pageSizes } from "../../../common/pagination/models/page-sizes";
import { getAvailablePages } from "../../../common/pagination/helpers/get-evailable-pages";
import { PatientAllergyModel } from "../../models/patientAllergiesModel";
import { patientAllergiesQuery } from "../../stores/patientAllergyStore/patientAllergies.query";

interface PatientSupplementsState extends IErrorState {
    isLoading: boolean;
    totalCount: number;
    selectedPage: number;
    pageSize: number;
    isEditorOpened: boolean;
    isMenuOpened: boolean;
    selectedId: number;
    supplements: PatientSupplementModel[];
    supplementsPart: PatientSupplementModel[];
    anchorEl: any;
    isFullText: boolean;
    allergies: PatientAllergyModel[];
}

export function useFacade(patientId: number | null): [
    PatientSupplementsState,
    Function,
    Function,
    Function,
    (value: any) => void,
    (page: number) => void,
    () => number[],
    Function,
    () => void,
] {
    const [state, setState] = useState({
        isLoading: true,
        totalCount: 0,
        selectedPage: 1,
        pageSize: pageSizes[2],
        isMenuOpened: false,
        isEditorOpened: false,
        selectedId: 0,
        supplements: [],
        supplementsPart: [],
        anchorEl: null,
        errors: {},
        isFullText: false,
        allergies: [],
    } as PatientSupplementsState);

    const handlePageSizeChange = (value: any) => {
        if (value === state.pageSize) {
            return;
        }

        const start = 0;
        const end = 1 * value > state.totalCount ? state.totalCount : state.selectedPage * value;
        const supplementsPartView = state.supplements.slice(start, end)

        setState(state => ({
            ...state,
            pageSize: value,
            selectedPage: 1,
            supplementsPart: supplementsPartView
        }));
    }

    const handlePageChange = (page: number) => {
        if (page === state.selectedPage) {
            return;
        }

        const start = (page - 1) * state.pageSize;
        const end = page * state.pageSize > state.totalCount ? state.totalCount : page * state.pageSize;
        const supplementsPartView = state.supplements.slice(start, end)

        setState(state => ({
            ...state,
            selectedPage: page,
            supplementsPart: supplementsPartView
        }));
    }

    const getAllAvailablePages = () => {
        return getAvailablePages(state);
    }

    const handleEdit = () => {
        setState({
            ...state,
            isEditorOpened: true,
            isMenuOpened: false
        });

        patientSupplementsStore.setEditModel(state.supplements.find(c => c.id === state.selectedId));
    }

    const handleDelete = () => {
        const service = patientId ? supplementsService : patientsSupplementsService;
        service.delete(state.selectedId).subscribe();

        handleToggleActions(state.selectedId, state.anchorEl);
    }

    const handleCloseDialog = () => {
        setState({
            ...state,
            isEditorOpened: false,
            selectedId: 0
        });

        patientSupplementsStore.setEditModel();
    }

    const handleToggleActions = (id: number, anchorEl: Element) => {
        setState(state => ({
            ...state,
            isMenuOpened: !state.isMenuOpened,
            selectedId: id,
            anchorEl: anchorEl
        }))
    }

    const handleReadMore = () => {
        setState({ ...state, isFullText: !state.isFullText });
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<PatientSupplementModel[]>(patientSupplementsQuery.patientSupplements$, supplements => {
                const supplementsPartView = supplements.slice(0, pageSizes[2])

                setState(state => ({
                    ...state,
                    supplements: supplements,
                    totalCount: supplements.length,
                    selectedPage: 1,
                    pageSize: pageSizes[2],
                    supplementsPart: supplementsPartView,
                    anchorElements: supplements.map(() => null)
                }));
            }),
            onEmit<PatientAllergyModel[]>(patientAllergiesQuery.patientAllergies$, allergies => {
                if (allergies) {
                    setState(state => ({
                        ...state,
                        allergies
                    }));
                }
            })
        ];

        patientId
            ? supplementsService.get(patientId).subscribe(() => setState((state) => ({ ...state, isLoading: false })))
            : patientsSupplementsService.get().subscribe(() => setState((state) => ({ ...state, isLoading: false })));

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [patientId]);

    return [
        state,
        handleEdit,
        handleDelete,
        handleCloseDialog,
        handlePageSizeChange,
        handlePageChange,
        getAllAvailablePages,
        handleToggleActions,
        handleReadMore,
    ];
}