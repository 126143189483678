import React from "react";
import { AuthenticatedLayoutComponent } from "../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent";
import { PagesNamesService } from "../../../../constants/pagesNames.constants";
import { TitlesService } from "../../../../constants/title.constants";
import { MenuItemTypes } from "../../../../models/menu.models";
import { PatientJourneyTasksComponent } from "../../components/PatientJourneyTasksComponent/PatientJourneyTasksComponent";

export const PatientMyTasksPage: React.FC = (props: any) => {
    const patientId = props?.location?.state?.patientId as number | null;
    const selectedTab = props?.location?.state?.selectedTab as number | null;

    return (
        <AuthenticatedLayoutComponent
            title={TitlesService.myTasksTitle}
            pageName={PagesNamesService.myTasks}
            selectedMenuItem={MenuItemTypes.None}
            hideBackground
        >
            <PatientJourneyTasksComponent patientId={patientId} selectedTab={selectedTab}/>
        </AuthenticatedLayoutComponent>
    )
}
