import React from 'react';
import { useFacade } from "./newReferralOrderComponent.hooks";
import { Box, FormControl, FormControlLabel, RadioGroup, TextField } from '@material-ui/core';
import { BackTitleComponent } from '../../../../components/pageTitleComponent/PageTitleComponent';
import { useStyles } from './newReferralOrderComponent.styles';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import moment from 'moment';
import { WildHealthRadio } from '../../../common/components/WildHealthRadio';
import { SignOffDialogComponent } from '../../../notes/components/signOffDIalogComponent/SignOffDialogComponent';
import {ReferralOrderModel} from "../../models/referralOrders.models";
import {OrderDiagnosisComponent} from "../orderDiagnosisComponent/OrderDiagnosisComponent";

interface NewReferralOrderPageProps {
    patientId?: number;
    targetOrder?: ReferralOrderModel | null;
    handleGoBack: Function;
}

export const NewReferralOrderComponent: React.FC<NewReferralOrderPageProps> = (props: NewReferralOrderPageProps) => {
    const { patientId, targetOrder, handleGoBack } = props;
    const classes = useStyles();

    const [
        {
            isLoading,
            isProcessing,
            content,
            addOns,
            item,
        },
        handleSendForApproval,
        handleSaveAsDraft,
        handleDiscard,
        handleSaveAndComplete,
        handleDiagnosisChange,
        handlePrimaryDiagnosisChange,
        handleAdditionalInformationChanges,
        handleReasonChanges,
        handleUrgencyChanges,
        handlePreferenceChanges,
        handleAddOnSelect,
        handleAddonDescriptionChanges,
        canConfirm,
        canSendForApproval,
        handleAutoComplete
    ] = useFacade(patientId, targetOrder, handleGoBack);

    const renderContent = () => {
        if (isLoading) {
            return <WildHealthLinearProgress />
        }

        return (
            <Box m={5}>
                <Box className={classes.title}>
                    New Referral
                </Box>

                <Box mt={5}>
                    <Box display='flex' flexWrap='wrap'>
                        <Box width='50%' minWidth='200px' pr={3}>
                            <Box className={classes.subTitle}>Patient Name</Box>
                            <Box className={classes.infoBox}> {content.patientName}</Box>
                        </Box>
                        <Box minWidth='200px' width='50%' pr={3} >
                            <Box className={classes.subTitle}>Date of Birth</Box>
                            <Box className={classes.infoBox}>{moment(content.dateOfBirth).format('MM/DD/YYYY')}</Box>
                        </Box>
                    </Box>
                    <Box display='flex' flexWrap='wrap'>
                        <Box width='50%' minWidth='200px' pr={3}>
                            <Box className={classes.subTitle}> Insurance Provider</Box>
                            <Box className={classes.infoBox}>{!!content.insuranceProvider ? content.insuranceProvider : '-'}</Box>
                        </Box>
                        <Box minWidth='200px' width='50%' pr={3}>
                            <Box className={classes.subTitle}> Insurance ID</Box>
                            <Box className={classes.infoBox}>{!!content.insuranceId ? content.insuranceId : '-'}</Box>
                        </Box>
                    </Box>
                </Box>

                <Box mt={5}>
                    <Box className={classes.subTitle}>Referral Requested</Box>
                    <Box>
                        <FormControl color='primary' component="fieldset">
                            <RadioGroup aria-label="gender" name="gender1" value={String(item?.addOnId)} onChange={(event) => handleAddOnSelect(event.target.value)}>
                                {
                                    addOns.map((addOn) => {
                                        return <Box key={addOn.id}>
                                            <FormControlLabel value={String(addOn.id)} control={<WildHealthRadio />} label={addOn.name} />
                                            {item?.addOnId === addOn.id && addOn.needSpecification && <Box pl={2}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    id={`add-on-${addOn.name}`}
                                                    variant="outlined"
                                                    value={item.description}
                                                    InputProps={{ className: 'input' }}
                                                    onChange={(v) => {
                                                        handleAddonDescriptionChanges(v.target.value)
                                                    }}
                                                />
                                            </Box>}
                                        </Box>
                                    })
                                }
                            </RadioGroup>
                        </FormControl>
                    </Box>
                </Box>
                <Box mt={5}>
                    <Box className={classes.subTitle}>Clinical Reason for Referral</Box>
                    <Box mb={2}>
                        <TextField
                            fullWidth
                            size="small"
                            id='reason-for-referral'
                            variant="outlined"
                            value={content.clinicalReasonForReferral}
                            InputProps={{ className: 'input' }}
                            onChange={(v) => {
                                handleReasonChanges(v.target.value)
                            }}
                        />
                    </Box>
                    <Box className={classes.subTitle}>Urgency (Time to Appointment)</Box>
                    <Box mb={2}>
                        <TextField
                            fullWidth
                            size="small"
                            id='urgency-referral'
                            variant="outlined"
                            value={content.urgencyTimeToAppointment}
                            InputProps={{ className: 'input' }}
                            onChange={(v) => {
                                handleUrgencyChanges(v.target.value)
                            }}
                        />
                    </Box>
                    <Box className={classes.subTitle}>All Diagnoses</Box>
                    <Box className={classes.subTitle}>
                        (Please select a primary diagnosis by clicking the star icon)
                    </Box>
                    <Box mb={2}>
                        <OrderDiagnosisComponent
                            selectedDiagnosis={content.diagnosis}
                            getAutoComplete={handleAutoComplete}
                            handleChanges={handleDiagnosisChange}
                            handlePrimaryChange={handlePrimaryDiagnosisChange}
                            primaryDiagnosisId={content.primaryDiagnosesId}
                            handleTextInfoChange={handleAdditionalInformationChanges}
                            grid={6}
                        />
                    </Box>
                    <Box className={classes.subTitle}>Patient Preference</Box>
                    <Box mb={2}>
                        <TextField
                            fullWidth
                            size="small"
                            id='preference-referral'
                            variant="outlined"
                            value={content.patientPreferences}
                            InputProps={{ className: 'input' }}
                            onChange={(v) => {
                                handlePreferenceChanges(v.target.value)
                            }}
                        />
                    </Box>
                </Box>
                <Box mt={5} pb={5} display='flex' alignItems='center' justifyContent="space-between">
                    <Box display="flex">
                        <Box>
                            <WildHealthButton
                                width={151}
                                style={{ whiteSpace: 'nowrap' }}
                                size="large"
                                disabled={!canSendForApproval()}
                                id='sing-physical-history'
                                color="secondary"
                                onClick={() => handleSendForApproval()}
                            >
                                <span>Send for Approval</span>
                            </WildHealthButton>
                        </Box>
                        <Box ml={2}>
                            <WildHealthButton
                                width={151}
                                style={{ whiteSpace: 'nowrap' }}
                                size="large"
                                disabled={isProcessing}
                                id='sing-physical-history'
                                color="secondary"
                                onClick={() => handleSaveAsDraft()}
                            >
                                <span>Save as Draft</span>
                            </WildHealthButton>
                        </Box>
                        <Box ml={2}>
                            <WildHealthButton
                                width={195}
                                size="large"
                                style={{ whiteSpace: 'nowrap' }}
                                disabled={!canConfirm()}
                                id='sing-physical-history'
                                onClick={() => handleSaveAndComplete()}
                            >
                                <span>Sign and Complete</span>
                            </WildHealthButton>
                        </Box>
                    </Box>
                    <Box>
                        <WildHealthButton
                            size="large"
                            width={111}
                            disabled={isProcessing}
                            id='cancel-physical-history'
                            onClick={() => handleDiscard()}
                            color='tertiary'
                        >
                            Discard
                        </WildHealthButton>
                    </Box>
                </Box>
            </Box>
        )
    }

    return (
        <Box>
            <Box className={classes.bgMain}>
                <BackTitleComponent
                    title='Back to Orders'
                    action={() => handleGoBack()}
                />
            </Box>
            {
                renderContent()
            }
            <SignOffDialogComponent isOrderCreate={true} />
        </Box>
    )
}