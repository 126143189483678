import React from 'react';
import { Box } from '@material-ui/core';
import { PaymentPlanModel } from '../../models/paymentPlan.models';
import { useStyles } from './SelectPaymentPlanCardComponent.styles';
import { displayMoney } from "../../../common/helpers/display-money";
import { getPeriodDiscount } from "../../helpers/getPeriodDiscount";
import { handleCompare } from "../../../common/sorting/helpers/handle-compare";
import { SortingDirection } from "../../../common/sorting/models/sorting-destination";
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import { colors } from '../../../common/constants/colors';
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from 'clsx';

interface SelectPaymentPlanCardComponentProps {
    plan: PaymentPlanModel;
    isSelected: boolean;
    handlePlanSelect: Function
    hideLimited?: boolean;
}

export const SelectPaymentPlanCardTestedComponent: React.FC<SelectPaymentPlanCardComponentProps> = (props: SelectPaymentPlanCardComponentProps) => {
    const {
        plan,
        isSelected,
        handlePlanSelect,
        hideLimited,
    } = props;

    const commonClasses = commonUseStyles();
    const classes = useStyles();

    const renderPrice = (): JSX.Element => {
        return (
            <Box display="flex" justifyContent="center">
                <Box mt={1.25}>
                    <h2>$</h2>
                </Box>
                <Box ml={0.5}>
                    <h1 className={clsx(commonClasses.primaryFont, commonClasses.textRegular, classes.startPrice)}>{plan.startPrice}</h1>
                </Box>
            </Box>
        );
    }

    const renderSelectButton = (): JSX.Element => {
        return (
            <WildHealthButton
                id="select-payment-plan"
                size="large"
                width="159"
                onClick={() => handlePlanSelect(plan)}
                color={(!isSelected || (plan.isLimited && !hideLimited)) && plan.mostPopular ? "primary" : "secondary"}
                style={{
                    borderRadius: 8,
                }}
            >
                {plan.isLimited ? " Join waitlist!" : "Join Now!"}
            </WildHealthButton>
        );
    }

    const renderHeaderSection = () => {
        const isOnePeriodPrice = plan.periods.length === 1;
        const period = isOnePeriodPrice
            ? plan.periods[0]
            : plan.periods.sort((p1, p2) => {
                return handleCompare(p1, p2, SortingDirection.Asc, 'periodInMonths')
            }).slice(-1).pop();

        return (
            <Box className={classes.planPricing}>
                <Box>
                    <span className={classes.planName}>{plan.displayName}</span>
                </Box>

                <Box mt={3}>
                    <span className={clsx(commonClasses.size14, commonClasses.textMedium, colors.linkBlack)}>{plan.title}</span>
                </Box>
                <Box mt={3}>
                    {
                        renderPrice()
                    }
                </Box>

                <Box>
                    <span className={clsx(commonClasses.size14, commonClasses.textRegular, commonClasses.colorDarkGray)}>
                        {
                            '/ per month '
                        }
                        {
                            plan.startStartupFee > 0 &&
                            <span>
                                plus {displayMoney(plan.startStartupFee ?? 0, '$')} startup fee
                            </span>
                        }
                    </span>
                </Box>
                <Box mt={2}>
                    <span className={clsx(commonClasses.textMedium, commonClasses.size14, classes.spacingZero)}>
                        <span className={clsx(commonClasses.textBold, commonClasses.size16, classes.saveMoneyStyle)}>Save {displayMoney(getPeriodDiscount(period) ?? 0, '$')}</span> by paying your {period.periodInMonths}-month membership upfront
                    </span>
                </Box>
            </Box>
        );
    }

    const renderContentSection = (): JSX.Element => {
        return (
            <Box mt={3} textAlign="center">
                {
                    renderSelectButton()
                }
            </Box>
        );
    }

    return (
        <Box className={clsx(plan.mostPopular ? classes.planCard1 : classes.planCard2, commonClasses.size14)}>
            {
                renderHeaderSection()
            }
            {
                renderContentSection()
            }
        </Box>
    )
}


