import Axios from 'axios-observable';
import { Observable } from 'rxjs';
import { authHeader } from "../../common/helpers/auth-header";
import { snackService } from "../../common/snack/state";
import { profileStore, ProfileStore } from "../stores/profileStore";
import { EmployeeProfileModel, EmployeeProfilePhotoModel } from "../models/employeeProfile.model";
import { PatientProfileModel } from "../models/patientProfile.model";
import { PracticeProfileModel } from "../models/practiceProfile.model";
import { PracticeInfoModel, PracticeInfoShortModel } from "../models/practice.model";
import { BusinessProfileModel } from '../models/businessProfile.model';
import { HealthSummaryDataModel } from '../../healthSummary/models/healthSummary.models';
import { PatientPharmacyInfoModel, DelinquentStatusModel } from '../../patients/models/patient.model';

/**
 * Provides method for working profile
 */
export class ProfileService {
    private url = `${process.env.REACT_APP_API_URL}`;
    private licensingUrl = `${process.env.REACT_APP_LICENSING_API_URL}`;

    constructor(private store: ProfileStore) {
    }

    /**
     * Fetches employee profile
     */
    public getEmployeeProfile(): Observable<EmployeeProfileModel> {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.get<EmployeeProfileModel>(`${this.url}Employees/Profile`, config)
                .pipe()
                .subscribe(
                    response => {
                        this.store.update({ employeeProfile: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    public updateEmployeeProfile(model: FormData): Observable<EmployeeProfileModel> {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.put<EmployeeProfileModel>(`${this.url}Employees/Profile`, model, config)
                .pipe()
                .subscribe(
                    response => {
                        snackService.success("Profile successfully updated!");
                        this.store.update({ employeeProfile: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    public updateBusinessProfile(model: BusinessProfileModel): Observable<BusinessProfileModel> {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.put<BusinessProfileModel>(`${this.url}Businesses`, model, config)
                .pipe()
                .subscribe(
                    response => {
                        snackService.success("Business successfully updated!");
                        this.store.update({ businessProfile: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    public updatePracticeProfile(model: PracticeProfileModel): Observable<PracticeProfileModel> {
        const config = {
            headers: authHeader()
        };

        model.email = model.email.trim();

        return new Observable((observer) =>
            Axios.put<PracticeProfileModel>(`${this.url}Practices`, model, config)
                .pipe()
                .subscribe(
                    response => {
                        snackService.success("Business successfully updated!");
                        this.store.update({ practiceProfile: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    /**
     * Fetches patient profile
     */
    public getPatientProfile(): Observable<PatientProfileModel> {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.get<PatientProfileModel>(`${this.url}Patients/Profile`, config)
                .pipe()
                .subscribe(
                    response => {
                        this.store.update({ patientProfile: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    /**
    * Fetches patient pharmacy patient profile by intake id
    */
    public getPatientPharmacyInfo(patientId: number): Observable<PatientPharmacyInfoModel> {
        const config = { headers: authHeader() };

        return new Observable((observer) =>
            Axios.get<PatientPharmacyInfoModel>(`${this.url}PatientPharmacyInfo/${patientId}`, config)
                .pipe()
                .subscribe(
                    response => {
                        this.store.update({ pharmacyInfo: response.data });
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    /**
    * Get the My pharmacy Info
    */
    public getMyPharmacyInfo(): Observable<PatientPharmacyInfoModel> {
        const config = { headers: authHeader() };

        return new Observable((observer) =>
            Axios.get<PatientPharmacyInfoModel>(`${this.url}PatientPharmacyInfo/GetPharmacyInfo`, config)
                .pipe()
                .subscribe(
                    response => {
                        this.store.update({ pharmacyInfo: response.data });
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    /**
    * Update patient pharmacy patient profile by employee
    */
    public updatePatientPharmacyInfo(patientId: number, model: PatientPharmacyInfoModel): Observable<PatientPharmacyInfoModel> {
        return new Observable(observer => {
            Axios.post(`${this.url}PatientPharmacyInfo/${patientId}`, model, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({ pharmacyInfo: response.data })
                        snackService.success("Pharmacy Info successfully updated!");
                        observer.next();
                        observer.complete()
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete()
                    }
                );
        })

    }

    /**
    * Update My pharmacy Info
    */
    public updateMyPharmacyInfo(model: PatientPharmacyInfoModel): Observable<PatientPharmacyInfoModel> {
        return new Observable(observer => {
            Axios.post(`${this.url}PatientPharmacyInfo/UpdatePharmacyInfo`, model, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({ pharmacyInfo: response.data })
                        snackService.success("Pharmacy Info successfully updated!");
                        observer.next();
                        observer.complete()
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete()
                    }
                );
        })

    }

    /**
     * Fetches problem list patient profile by intake id
     */
    public getPatientProblemList(patientId: number): Observable<HealthSummaryDataModel[]> {
        const config = { headers: authHeader() };

        return new Observable((observer) =>
            Axios.get<HealthSummaryDataModel[]>(`${this.url}Patients/ProblemsList/${patientId}`, config)
                .pipe()
                .subscribe(
                    response => {
                        this.store.update({ patientProfileProblemList: response.data });
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    /**
     * Fetches patient profile by intake id
     */
    public getPatientProfileByIntakeId(intakeId: string): Observable<PatientProfileModel> {
        return new Observable((observer) =>
            Axios.get<PatientProfileModel>(`${this.url}Patients/${intakeId}/Profile`)
                .pipe()
                .subscribe(
                    response => {
                        this.store.update({ patientProfile: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    public getBusinessProfile(): Observable<BusinessProfileModel> {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.get<BusinessProfileModel>(`${this.url}Businesses`, config)
                .pipe()
                .subscribe(
                    response => {
                        this.store.update({ businessProfile: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    () => {
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    public updatePatientProfile(model: PatientProfileModel): Observable<PatientProfileModel> {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.put<PatientProfileModel>(`${this.url}Patients/Profile`, model, config)
                .pipe()
                .subscribe(
                    response => {
                        this.store.update({ patientProfile: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    public updatePatientProfileByIntakeId(intakeId: string, model: PatientProfileModel): Observable<PatientProfileModel> {
        return new Observable((observer) =>
            Axios.put<PatientProfileModel>(`${this.url}Patients/${intakeId}/Profile`, model)
                .pipe()
                .subscribe(
                    response => {
                        this.store.update({ patientProfile: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    public getPracticeInfo(preferredUrl: string): Observable<PracticeInfoModel> {
        const config = { headers: authHeader() };

        return new Observable((observer) => {
            Axios.get<PracticeInfoModel>(`${this.licensingUrl}Practices/Info?preferredUrl=${preferredUrl}`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({ practiceInfo: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error.message.includes('404') ? { response: { data: { messages: ['Practice name not found.'] } } } : error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getPracticeProfile(): Observable<PracticeProfileModel> {
        const config = { headers: authHeader() };

        return new Observable((observer) => {
            Axios.get<PracticeProfileModel>(`${this.url}Practices`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({ practiceProfile: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getShortPracticeInfo(): Observable<PracticeInfoShortModel> {
        const config = { headers: authHeader() };

        return new Observable((observer) => {
            Axios.get<PracticeInfoShortModel>(`${this.url}Practices/info`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({ practiceShortInfo: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getProfilePhoto(): Observable<any> {
        const config = { headers: authHeader() };

        return new Observable((observer) => {
            Axios.get<any>(`${this.url}Attachment/profile-photo`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getPhotoUrl(id: number): string {
        return `${this.url}Attachment/${id}`;
    }

    /**
 * Fetches patient delinquent
 */
    public isPatientDelinquent(): Observable<DelinquentStatusModel[]> {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.get<DelinquentStatusModel[]>(`${this.url}Patients/Delinquent`, config)
                .pipe()
                .subscribe(
                    response => {
                        this.store.update({ delinquentStatus: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    public getEmployeeProfilePhotos(employeeIds: string): Observable<EmployeeProfilePhotoModel[]> {
        const config = { headers: authHeader() };

        return new Observable((observer) => {
            Axios.get<EmployeeProfilePhotoModel[]>(`${this.url}Attachment/get-photos/${employeeIds}`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.updateEmployeePhotos(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }
}

export const profileService = new ProfileService(profileStore);