import React from 'react';
import {Box} from '@material-ui/core';
import {QuestionnaireSurveyProps} from "./questionnaireSurvey.props";
import {InitialQuestionNames} from "../../../models/questionnaire.models";
import {PageSliderComponent} from "../../../../common/components/PageSliderComponent";
import { QuestionnairesListComponent } from '../../../components/questionnairesListComponent/QuestionnairesListComponent';

export const MedicalQuestionnairePageFields = [
    InitialQuestionNames.MEDICAL_CONDITIONS,
    InitialQuestionNames.FAMILY_MEDICAL_CONDITIONS,
    InitialQuestionNames.SMOKING_STATUS,
    InitialQuestionNames.DIABETES_STATUS,
    InitialQuestionNames.CANCER_SCREENING,
    InitialQuestionNames.HEALTH_FEARS,
    InitialQuestionNames.CURRENT_MEDICATIONS,
    InitialQuestionNames.CURRENT_MEDICATIONS_SUPPLEMENT
];

export const MedicalSurvey: React.FC<QuestionnaireSurveyProps> = (props: QuestionnaireSurveyProps) => {
    const {
        errors,
        result,
        questionnaire,
        handleChanges,
        navigationContext
    } = props;

    return (
        <>
            <QuestionnairesListComponent
                questionnaire={questionnaire}
                errors={errors}
                handleChanges={handleChanges}
                navigationContext={navigationContext}
                questions={MedicalQuestionnairePageFields}
                result={result}
                title={"Medical Information:"}
            />
            <Box mt={5}>
                <PageSliderComponent context={navigationContext}/>
            </Box>
        </>
    )
}
