import { useEffect, useState } from 'react';
import { Subscription } from 'recompose';
import { profileService } from '../../../account/services/profile.service';
import { profileQuery } from '../../../account/stores/profileStore';
import { DataSpecificationsEnum } from '../../../common/constants/data-specifications';
import { onEmit } from '../../../common/helpers/on-emit';
import { HealthSummaryDataModel } from '../../../healthSummary/models/healthSummary.models';
import { PatientModel } from "../../../patients/models/patient.model";
import { patientsService } from "../../../patients/services/patients.service";
import { patientsQuery } from '../../../patients/stores/patientsStore';
import { healthSummaryVitalsService } from '../../../healthSummary/services/healthSummaryVitals.service';
import {healthSummaryQuery} from "../../../healthSummary/stores";
import {healthSummaryAllergiesService} from "../../../healthSummary/services/healthSummaryAllergies.service";
import {healthSummaryMedicationsService} from "../../../healthSummary/services/healthSummaryMedications.service";
import {healthSummaryService} from "../../../healthSummary/services/healthSummary.service";
import {useFlags} from "launchdarkly-react-client-sdk";

interface PatientInfoTooltipComponentState {
    isLoading: boolean;
    patient: PatientModel | null;
    vital: HealthSummaryDataModel[];
    problemList: HealthSummaryDataModel[];
    medications: HealthSummaryDataModel[];
}

export function useFacade(patientId: number): [PatientInfoTooltipComponentState] {
    const [state, setState] = useState({
        isLoading: true,
        patient: null,
        vital: [],
        problemList: null
    } as PatientInfoTooltipComponentState);

    const flags = useFlags();

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<PatientModel[]>(patientsQuery.coachPatients$, coachPatients => {
                const targetPatient = coachPatients.find(x => x.id === patientId)
                if (targetPatient) {
                    setState(state => ({ ...state, patient: targetPatient }));
                }
            }),
            onEmit<PatientModel>(patientsQuery.targetPatient$, targetPatient => {
                if (targetPatient?.id === patientId) {
                    setState(state => ({ ...state, patient: targetPatient }));
                }
            }),
            onEmit<HealthSummaryDataModel[]>(profileQuery.patientProfileProblemList$, problemList => {
                setState(state => ({ ...state, problemList: problemList }));
            }),
            onEmit<HealthSummaryDataModel[]>(healthSummaryQuery.data$, data => {
                const medications = data.filter(x => x.key.includes(healthSummaryMedicationsService.mapKey));
                const vitals = data.filter(x => x.key.includes(healthSummaryVitalsService.mapKey));

                setState(state => ({
                    ...state,
                    vitals: vitals,
                    medications: medications
                }));
            })
        ];

        const problemCB = () => setState(state => ({ ...state, isLoading: false }))

        if (!state.patient) {
            patientsService.get(patientId, DataSpecificationsEnum.UpdatePatientSpecification);
        }

        healthSummaryService.getJsonDataService(healthSummaryMedicationsService.mapKey, flags).get(patientId);
        healthSummaryService.getJsonDataService(healthSummaryVitalsService.mapKey, flags).get(patientId);

        profileService.getPatientProblemList(patientId).subscribe(problemCB, problemCB);

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, [patientId]);

    return [
        state,
    ];
}