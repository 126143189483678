import { Box } from '@material-ui/core';
import React from 'react';
import { AuthenticatedLayoutComponent } from '../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { PagesNamesService } from '../../../constants/pagesNames.constants';
import { TitlesService } from '../../../constants/title.constants';
import { MenuItemTypes } from '../../../models/menu.models';
import { WildHealthPlaceHolder } from "../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { WildHealthTabControl } from '../../common/components/wildHealthTabControl/WildHealthTabControl';
import { TabItem } from '../../common/components/wildHealthTabControl/wildHealthTabControl.hooks';
import { ManageLeadSourcesComponent } from "../components/manageLeadSourcesComponent/ManageLeadSourcesComponent";

export const LeadSourcesPage: React.FC = () => {
    const tabs: TabItem[] = [
        {
            title: 'Lead Sources',
            content: <ManageLeadSourcesComponent/>,
            keepMounted: true,
        },
        {
            title: 'Lead Sources Report',
            content: <Box p={3}><WildHealthPlaceHolder message="No reports"/></Box>,
            keepMounted: true,
        },
    ];

    return (
        <AuthenticatedLayoutComponent
            title={TitlesService.leadSourcesTitle}
            pageName={PagesNamesService.leadSources}
            selectedMenuItem={MenuItemTypes.Analytics}
        >
            <WildHealthTabControl items={tabs} />
        </AuthenticatedLayoutComponent>
    )
}