import { AppBar, ButtonGroup, Toolbar, useScrollTrigger } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { ProfileComponent } from '../../modules/account/components/profileComponent/ProfileComponent';
import { authQuery } from "../../modules/auth/stores/auth";
import { NotificationsButtonComponent } from '../../modules/notifications/components/notificationComponent/NotificationsButtonComponent';
import { useStyles } from "./authenticatedHeaderComponent.styles";
import { ReferralNavigationComponent } from '../mobileNavigationComponent/ReferralNavigationComponent';

function ElevationScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

interface AuthenticatedHeaderComponentProps {
    title: JSX.Element | string;
    isSideBarOpen: boolean;
    topBannerHeight?: number
}

export const AuthenticatedHeaderComponent: React.FC<AuthenticatedHeaderComponentProps> = (props: AuthenticatedHeaderComponentProps) => {
    const {
        title,
        isSideBarOpen,
        topBannerHeight = 0,
    } = props;
    const isAuthorized = authQuery.isLoggedIn();
    const classes = useStyles({ topBannerHeight });

    return (
        <ElevationScroll>
            <AppBar className={clsx(classes.root, {
                [classes.drawerOpen]: isSideBarOpen,
                [classes.drawerClose]: !isSideBarOpen,
            })}
            >
                <Toolbar className={classes.toolbar}>
                    <div className={classes.title}>{title}</div>

                    <div className={classes.grow} />

                    {
                        isAuthorized &&
                        <ButtonGroup
                            size="small"
                            variant='text'
                        >

                            <ReferralNavigationComponent />

                            <NotificationsButtonComponent />

                            <ProfileComponent />

                        </ButtonGroup>
                    }
                </Toolbar>
            </AppBar>
        </ElevationScroll>
    )
}
