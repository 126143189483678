import { Store, StoreConfig } from '@datorama/akita';
import { AddOnModel } from '../models/addOns.models';

export interface AddOnsState {
    addOns: AddOnModel[];
}

/**
 * Creates initial addOn state
 */
export function createInitialState(): AddOnsState {
    return {
        addOns: []
    };
}

/**
 * Provides addOn state management
 */
@StoreConfig({name: 'addOns', resettable: true })
export class AddOnsStore extends Store<AddOnsState> {
    constructor() {
        super(createInitialState());
    }
}

export const addOnsStore = new AddOnsStore();
