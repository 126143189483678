import React, { useState, useRef } from "react";
import Recaptcha from 'react-google-invisible-recaptcha';
import { useHistory } from "react-router";
import { SITE_KEY } from "../../../../config";
import { navigationService } from "../../../../services/navigation.service";
import { IErrorState } from "../../../common/validation/error-state";
import { LoginModel } from "../../models/auth.models";
import { authService } from "../../services/auth.service";
import { authQuery } from "../../stores/auth";
import { loginPageValidator } from "./loginPage.validator";
import { SubscriptionStatus } from "../../../payment/models/subscription.models";
import { confirmService } from "../../../../services/confirm.service";
import { profileService } from "../../../account/services/profile.service";
import {ExternalAuthProvider} from "../../models/auth.enums";

/**
 * Represents Create Shortcuts Component state
 */
interface LoginPageComponentState extends IErrorState {
    inProgress: boolean;
    showPassword: boolean;
    model: LoginModel;
}

/**
 * Custom Hook to manage a view Model for Create shortcuts component
 */
export function useFacade(redirectTo: string | null): [LoginPageComponentState, () => void, Function, Function, Function, Function, Function, Function, Function] {
    const _history = useHistory();
    const _siteKey = SITE_KEY;
    const _recaptcha = useRef(null);

    if (authQuery.isLoggedIn()) {
        if (!authQuery.isRegistrationCompleted()) {
            navigationService.toGetStarted(_history);
        } else {
            navigationService.toDashboard(_history);
        }
    }

    const [state, setState] = useState({
        inProgress: false,
        showPassword: false,
        model: {
            email: "",
            password: "",
            practiceId: authQuery.getCurrentPracticeId()
        },
        errors: {}
    } as LoginPageComponentState);

    const handleClickShowPassword = () => {
        setState({ ...state, showPassword: !state.showPassword });
    };

    const handleChanges = (field: string, value: string) => {
        state.model[field] = value;
        setState({
            ...state,
            model: { ...state.model }
        });

        loginPageValidator.validateAndSetState(state, setState, field, value);
    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            handleSubmit(event);
        }
    }

    const renderRecaptcha = () => {
        return (
            <Recaptcha
                ref={_recaptcha}
                sitekey={_siteKey}
                onResolved={(token) => {
                    state.model.antiSpamToken = token;
                    const cb = () => setState(state => ({ ...state, inProgress: false }));

                    authService.login(state.model).subscribe((res) => {
                        if (!authQuery.isRegistrationCompleted()) {
                            navigationService.toGetStarted(_history);
                        } else {
                            if (res.subscriptionStatus !== SubscriptionStatus.Active && res.subscriptionStatus !== SubscriptionStatus.Paused) {
                                if (authQuery.isLicensingPractice()) {
                                    profileService.getPracticeProfile().subscribe((practice) => {
                                        confirmService.confirmInfo(
                                            `Welcome Back, ${res.firstName}`,
                                            `Currently you are listed as having an inactive membership.  Please contact your practice ${practice.email} to reconcile this.`,
                                            '',
                                            '')
                                            .subscribe();
                                    });
                                } else {
                                    if (res.subscriptionStatus === SubscriptionStatus.Deactivated) {
                                        confirmService.confirmInfo(
                                            `Welcome Back, ${res.firstName}`,
                                            `You have completed your Precision Care Package. Select one of our Plans in order to continue your care.
                                            You still have access to all your health data but won’t be able to schedule appointemnts or reach out to your care team. Feel free to contact us at support@wildhealth.com if you have any questions.`,
                                            '',
                                            'See Plans')
                                            .subscribe(
                                                () => navigationService.toAccountMembershipActivation(_history)
                                            );
                                    }
                                    else if (res.subscriptionStatus === SubscriptionStatus.Paused) {
                                        // Do nothing
                                    } else {
                                        confirmService.confirmInfo(
                                            `Welcome Back, ${res.firstName}`,
                                            `You have no currently active membership. You can restart your membership at any time by going to your account and selecting the plan that's right for you. Feel free to contact us at support@wildhealth.com if you have any questions.`,
                                            '',
                                            'Renew Membership')
                                            .subscribe(
                                                () => navigationService.toBuyNewSubscription(_history)
                                            );
                                    }
                                }
                            }
                            if (redirectTo) {
                                _history.push(redirectTo);
                            } else {
                                navigationService.toDashboard(_history);
                            }
                        }
                        cb();
                    }, cb)
                }}
            />
        )
    }

    const handleSubmit = (event: Event) => {
        event.preventDefault();
        loginPageValidator.validateObjectAndSetState(state, setState, state.model);
        if (!loginPageValidator.stateIsValid(state)) {
            return;
        }
        setState(state => ({ ...state, inProgress: true }));

        if (_recaptcha.current)
            _recaptcha.current.execute();
    }

    const handleForgotPassword = () => {
        navigationService.toForgotPassword(_history);
    }

    const goToGetStartedPage = () => {
        navigationService.toGetStarted(_history)
    }

    const goToAuth2LoginPage = (provider: ExternalAuthProvider) => {
        navigationService.toAuth2LoginPage(_history, provider);
    }

    return [
        state,
        handleClickShowPassword,
        renderRecaptcha,
        handleChanges,
        handleSubmit,
        handleForgotPassword,
        goToGetStartedPage,
        handleKeyDown,
        goToAuth2LoginPage
    ];
}
