import React from 'react';
import {QuestionnaireModel, QuestionnaireResultModel} from '../../models/questionnaire.models';
import {QuestionnaireComponent} from '../questionnaireComponent/QuestionnaireComponent';
import {healthGoalsQuestionnaireValidator} from './healthGoalsQuestionnaireSurveyComponent.validator';
import {HealthGoalsQuestionnaireSections} from '../../static/healthGoalsQuestionnaire.static';
import {Gender} from "../../../common/models/user.models";

export interface MedicalHistoryQuestionnaireSurveyComponentProps {
    questionnaire: QuestionnaireModel;
    result: QuestionnaireResultModel;
    gender: Gender;
    patientId?: number;
    handleGoBack?: Function;
}

export const HealthGoalsQuestionnaireSurveyComponent: React.FC<MedicalHistoryQuestionnaireSurveyComponentProps> = (props: MedicalHistoryQuestionnaireSurveyComponentProps) => {
    const {questionnaire, result, gender, patientId, handleGoBack} = props;

    return (
        <QuestionnaireComponent
            questionnaire={questionnaire}
            result={result}
            sections={HealthGoalsQuestionnaireSections}
            validator={healthGoalsQuestionnaireValidator}
            gender={gender}
            patientId={patientId}
            handleGoBack={handleGoBack}
        />
    )
}