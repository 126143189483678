import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            maWidth: 600,
            minWidth: 200,
            [theme.breakpoints.down('sm')]: {
                maWidth: 300,
            },
        },
        action: {
            width: 585,
            [theme.breakpoints.down('sm')]: {
                width: 250,
            },
        },
    })
);
