import React, {useState} from 'react';
import {
    Box,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Tooltip,
    useMediaQuery,
    useTheme
} from "@material-ui/core";
import moment from 'moment';
import {NotificationModel, NotificationType} from "../../models/notifications.models";
import {useFacade} from "./NotificationsButtonComponent.hooks";
import {useStyles} from "./notificationsWidgetComponent.styles";
import {toCurrentTimeZone} from "../../../timezones/helpers/timezone";
import {ReactComponent as LabsIcon} from "@img/icons/LabsIcon.svg";
import {ReactComponent as UpcomingLabsIcon} from "@img/icons/upcomingLabs.svg";
import {ReactComponent as MessageNotificationIcon} from "@img/icons/MessageNotificationIcon.svg";
import {ReactComponent as NoteIcon} from "@img/icons/stickynoteIcon.svg";
import {ReactComponent as MedicalHistory} from "@img/icons/MedicalHistory.svg";
import {ReactComponent as HealthReport} from "@img/icons/HealthReport.svg";
import {colors} from "../../../common/constants/colors";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from "clsx";
import {
    WildHealthSaveClickableElement
} from "../../../common/components/wildHealthSaveClickableElement/WildHealthSaveClickableElement";
import { ReactComponent as TrashIcon } from "@img/icons/Trash.svg";
import LaunchIcon from '@material-ui/icons/Launch';
import commonUseStyles from "../../../common/styles/common.styles";


const messageLength = 150;

interface NotificationsWidgetComponentProps {
    patientId: number;
}

interface NotificationsWidgetComponentState {
    isOpen: boolean,
}

export const NotificationsWidgetComponent: React.FC<NotificationsWidgetComponentProps> = (props: NotificationsWidgetComponentProps) => {
    const [
        { notifications },
        handleNavigateTo,
        handleDelete,
        handleDeleteAll,
        handleToggleNotifications
    ] = useFacade(props.patientId);

    const [state, setState] = useState({
        isOpen: false,
    } as NotificationsWidgetComponentState);

    const theme = useTheme();
    const classes = useStyles();
    const commonClasses = commonUseStyles();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const customTooltipClasses = {
        tooltip: classes.customWidth,
        tooltipPlacementBottom: classes.tooltipPlacement,
        tooltipPlacementTop: classes.tooltipPlacement,
    };

    const displayDate = (date: Date) => {
        return moment(toCurrentTimeZone(date)).format("MMM D - hh:mm a");
    }

    const renderTitle = (notification: NotificationModel) => {
        return `${notification.subject} (${displayDate(notification.createdAt)})`;
    }

    const renderMessage = (notification: NotificationModel) => {
        return notification.text.length < messageLength ? notification.text : `${notification.text.substring(0, messageLength)} . . .`;
    }

    const getIcon = (type: NotificationType) => {
        switch (type) {
            case NotificationType.HealthLogReminder: 
                return <Box bgcolor={colors.cyan100} className={classes.iconType}><MedicalHistory stroke={colors.cyan500} /></Box>;
            case NotificationType.NewMessage: 
            case NotificationType.UnreadMessagesCount: 
            case NotificationType.UnreadMessagesManual: 
            case NotificationType.MessageAdminToolEmail: 
            case NotificationType.MessageAdminToolSMS:
            case NotificationType.MessageAdminToolClarity: 
                return <Box bgcolor={colors.pink50} className={classes.iconType}><MessageNotificationIcon stroke={colors.pink600} /></Box>;
            case NotificationType.NewHealthReport: 
                return <Box bgcolor={colors.mint100} className={classes.iconType}><HealthReport fill={colors.mint500} /></Box>;
            case NotificationType.LabResults: 
                return <Box bgcolor={colors.lavendar100} className={classes.iconType}><LabsIcon stroke={colors.lavendar600} /></Box>;
            case NotificationType.UpcomingLabs:
                return <Box bgcolor={colors.cyan100} className={classes.iconType}> <UpcomingLabsIcon /></Box>;
            case NotificationType.NewNote: 
                return <Box bgcolor={colors.cyan100} className={classes.iconType}> <NoteIcon stroke={colors.cyan500} /></Box>;
            default: 
                return <Box bgcolor={colors.cyan100} className={classes.iconType}><NoteIcon stroke={colors.cyan500} /></Box>;
        }
    }

    const getSecondaryText = (type: NotificationType) => {
        switch (type) {
            case NotificationType.UpcomingLabs: return 'View requisition';
            default: return 'View'
        }
    }

    const renderCta = (notification: NotificationModel) => {
        return <a onClick={() => handleNavigateTo(notification)} className={commonClasses.btnLink}>
            <Box display="flex" alignItems="center">
                <Box>
                    {getSecondaryText(notification.type)}
                </Box>
                <Box ml={1}>
                    <LaunchIcon/>
                </Box>
            </Box>
        </a>
    }

    const renderDeleteAction = (notification: NotificationModel) => {
        return <ListItemSecondaryAction className={clsx(classes.dismissAction, "group-item", isSmallScreen && "wh-tw-visible")}>
            <WildHealthSaveClickableElement
                id={`notifications-btn-delete-${notification.id}`}
                onClick={() => handleDelete(notification.id)}
                component={IconButton}
                aria-label="delete"
                timeout={500}
                edge="end"
            >
                <Tooltip placement="top"
                         title={<p className={classes.notificationToolTipText}>Dismiss</p>}
                         classes={customTooltipClasses}>
                    <TrashIcon />
                </Tooltip>
            </WildHealthSaveClickableElement>
        </ListItemSecondaryAction>
    }

    const getSecondaryAction = (notification: NotificationModel) => {
        switch (notification.type) {
            case NotificationType.UpcomingLabs: return renderCta(notification)
            default: return renderDeleteAction(notification)
        }
    }

    const handleViewMore = () => {
        setState(state => ({ ...state, isOpen: !state.isOpen }));
    }

    const notificationToSow = () => {
        return state.isOpen ? notifications : notifications.slice(0, 5)
    }

    const renderContent = () => {
        return !!notifications.length
            ? <List className={classes.list}>
                {notificationToSow().map((item, index) =>
                    <ListItem className={classes.listItem} button key={index}>
                        {getIcon(item.type)}
                        <ListItemText
                            primary={renderTitle(item)}
                            secondary={renderMessage(item)}
                            onClick={() => handleNavigateTo(item)}
                        />
                        {
                            getSecondaryAction(item)
                        }
                    </ListItem>
                )}
                {notifications.length > 5 && <ListItem className={classes.viewMoreItem} onClick={() => handleViewMore()} button key={-1}>
                    <Box display='flex' alignItems='center'>
                        <Box mr={1} className={classes.dismissAll}>
                            {state.isOpen ? 'Hide More' : 'View More'}
                        </Box>
                        {state.isOpen ? <ExpandLessIcon className={classes.expandIcon} /> : <ExpandMoreIcon className={classes.expandIcon} />}
                    </Box>

                </ListItem>}
            </List>
            : <Box className={classes.empty}>Nothing to see here!</Box>
    }

    return (
        <>
            {renderContent()}
        </>
    );
}
