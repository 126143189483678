import Axios from 'axios-observable';
import {Observable} from "rxjs";
import { authHeader } from "../../common/helpers/auth-header";
import { API_URL } from "../../../config";
import { snackService } from '../../common/snack/state';
import { employerProductsStore, EmployerProductsStore } from "../stores/employerProductsStore";
import { EmployerProductModel, CreateEmployerProductModel } from '../models/employerProduct.model';

/**
 * Provides methods for working with employer products
 */
export class EmployerProductsService {
    private url = `${API_URL}EmployerProducts`;

    constructor(private employerProductsStore: EmployerProductsStore) {
    }

    /**
     * Returns all Employers
     */
    public getAllEmployerProducts(): Observable<EmployerProductModel[]> {
        const config = {
            headers: authHeader()
        };
        return new Observable<EmployerProductModel[]>(observer => {
            Axios.get<EmployerProductModel[]>(`${this.url}`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.employerProductsStore.update({ employerProducts: response.data })
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    /**
     * Returns the Employer by id
     */
    public getEmployerProductById(id: number): Observable<EmployerProductModel> {
        const config = {
            headers: authHeader()
        };
        return new Observable<EmployerProductModel>(observer => {
            Axios.get<EmployerProductModel>(`${this.url}/${id}/employer`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.employerProductsStore.update({ selectedEmployer: response.data })
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    /**
     * Returns the Employer by key
     */
    public getEmployerProductByKey(employerKey: string): Observable<EmployerProductModel> {
        return new Observable<EmployerProductModel>(observer => {
            Axios.get<EmployerProductModel>(`${this.url}/${employerKey}`)
                .pipe()
                .subscribe(
                    (response) => {
                        this.employerProductsStore.update({ selectedEmployer: response.data })
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    /**
     * Create Employer Product
     * @param model
     */
    public createEmployerProduct(model: CreateEmployerProductModel) {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.post(`${this.url}/AllPractice`, model, config)
                .pipe()
                .subscribe(
                    response => {
                        this.employerProductsStore.addEmployer(response.data);
                        snackService.success('EmployerProduct successfully created');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }
}

export const employerProductsService = new EmployerProductsService(employerProductsStore);
