import { authQuery } from "../../auth/stores/auth";

export interface PatientMedicationModel {
    id: number;
    name: string;
    dosage: string;
    instructions: string;
    startDate?: Date | null;
}

export interface RxntMedicationModel {
    name: string;
    dosingInstructions: string;
    numberOfRefills: number;
    startDate: Date;
    lastDatePrescribed: Date;
}

export const newPatientMedicationModel = () : PatientMedicationModel => ({
    id: 0,
    name: '',
    dosage: '',
    instructions: '',
    startDate: authQuery.isPatientUser() ? null : new Date()
})