import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { onEmit } from "../../../common/helpers/on-emit";
import { CallToActionModel, ReactOnCallToActionModel, ActionReactionType, ActionStatus } from "../../../patients/models/patient.model";
import { patientsService } from "../../../patients/services/patients.service";
import { patientsQuery, patientStore } from "../../../patients/stores/patientsStore";

interface StandardUpsellWidgetComponentState {
    isLoading: boolean;
    isSubmitting: boolean;
    callToActions: CallToActionModel[];
}

export function useFacade(): [
    StandardUpsellWidgetComponentState,
    () => void,
] {
    const [state, setState] = useState({
        isLoading: true,
        isSubmitting: false,
        callToActions: [],
    } as StandardUpsellWidgetComponentState);

    const handleDecline = () => {
        setState(state => ({ ...state, isSubmitting: true }))
        const cb = () => setState(state => ({ ...state, isSubmitting: false }));
        const request = {
            id: state.callToActions[0].id,
            reactionType: ActionReactionType.Decline,
            details: 'Decline'
        } as ReactOnCallToActionModel;
        patientsService.requestStandardUpsell(request).subscribe(() => {
            patientStore.toggleRequestDialog(false)
            cb()
        }, cb);
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<CallToActionModel[]>(patientsQuery.callToActions$, callToActions => {
                setState(state => ({
                    ...state,
                    callToActions: callToActions.filter(x => x.status === ActionStatus.Active),
                }));
            }),
        ];

        const cb = () => setState(state => ({...state, isLoading: false}));

        patientsService.getActiveCallToActions().subscribe(cb, cb);

        return () => {
            subscriptions.map((it) => it.unsubscribe());
        };
    }, []);

    return [
        state,
        handleDecline,
    ];
}