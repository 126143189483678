import { WildHealthAutocompleteComponent } from '../../../common/components/wildhealthAutocomplete/WildHealthAutocompleteComponent'
import { HealthSummaryDataModel, HealthSummaryListDataModel, HealthSummaryMapModel } from '../../models/healthSummary.models'
import { Box, IconButton, Menu, MenuItem, Table, TableBody, TableContainer, TableHead, TextField, Tooltip } from '@material-ui/core'
import { WildHealthTableCell } from '../../../common/components/wildHealthTable/WildHealthTableCell'
import { WildHealthTableRow } from '../../../common/components/wildHealthTable/WildHealthTableRow'
import { HealthSummaryWrapperComponent } from '../wrapperComponent/HealthSummaryWrapperComponent'
import { FeatureComponent } from '../../../common/components/featureFlags/FeatureComponent'
import { FeatureFlag } from '../../../common/components/featureFlags/featureFlags.models'
import { ProtectedElement } from '../../../common/components/ProtectedElement'
import { useStyles } from './healthSummaryListItemsComponent.styles'
import { useFacade } from './healthSummaryListItemsComponent.hooks'
import { PermissionType } from '../../../auth/models/auth.enums'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import React from 'react'
import InfoIcon from '@material-ui/icons/InfoRounded';
import { colors } from '../../../common/constants/colors'

export interface HealthSummaryListItemsComponentProps {
    data: HealthSummaryDataModel[]
    map: HealthSummaryMapModel
    patientId?: number
}

export const HealthSummaryListItemsComponent: React.FC<HealthSummaryListItemsComponentProps> = (props: HealthSummaryListItemsComponentProps) => {
    const {
        patientId,
        data,
        map
    } = props

    const [
        getIcdCodesAutoComplete,
        handleToggleActions,
        handleEditRowValue,
        handleEditRowName,
        handleAddNewRow,
        handleDelete,
        handleEdit,
        handleBlur,
        saveValue,
        cancelChanges,
        handleEditMode,
        saveNewChanges,
        state,
        isRxnt
    ] = useFacade(map, patientId)

    const classes = useStyles()

    const getName = (key: string): string => map.items.find(x => x.key === key)?.name;

    const getData = () => {
        return data?.map(x => ({
            name: x.name || getName(x.key),
            key: x.key,
            value: x.value,
            tooltip: x.tooltip ? x.tooltip : ''
        }) as HealthSummaryListDataModel) ?? [];
    }

    const renderToggleButtonCell = (key: string) => (
        <IconButton onClick={(e) => { handleToggleActions(key, e.currentTarget) }}>
            <MoreHorizIcon />
        </IconButton>
    )

    const renderMenuItems = () => (
        <Menu
            keepMounted
            anchorEl={state.anchorEl}
            open={state.isMenuOpened}
            onClose={() => { handleToggleActions(0) }}
        >
            <MenuItem disabled={map.editRestricted || (map.key === 'PROBLEMS_LIST' && isRxnt)} onClick={() => { handleEdit() }}>
                Edit
            </MenuItem>
            <MenuItem disabled={map.editRestricted} onClick={() => { handleDelete() }}>
                Delete
            </MenuItem>
        </Menu>
    )

    const renderItemRow = (item: HealthSummaryListDataModel) => {
        return <WildHealthTableRow key={item.key}>
            <WildHealthTableCell>
                <Box ml={-1.5} display='flex'>
                    <Box width='30px' minWidth='30px'>
                        {item.tooltip && <Tooltip placement="top" arrow
                            title={item.tooltip}>
                            <InfoIcon fontSize='small' style={{ fill: colors.gray2 }} />
                        </Tooltip>}
                    </Box>
                    {
                        item.name
                    }
                </Box>
            </WildHealthTableCell>
            <WildHealthTableCell>
                <Box display="flex" flexWrap="wrap">
                    {item.value ?? '--'}
                </Box>
            </WildHealthTableCell>
            {!state.addNewRow && !map.editRestricted &&
                <FeatureComponent featureFlag={FeatureFlag.HealthSummaryEdit}>
                    <WildHealthTableCell className={classes.actions}>
                        <Box display='flex' alignItems='center'>
                            <ProtectedElement
                                permissions={[PermissionType.Coaching, PermissionType.ManagePatients]}
                                element={renderToggleButtonCell(item.key)}
                            />
                        </Box>
                    </WildHealthTableCell>
                </FeatureComponent>
            }
        </WildHealthTableRow>
    }

    const getEditCell = (type: string, value: string, handleEdit: Function) => {
        return <TextField
            onChange={(e) => { handleEdit(e.target.value) }}
            onBlur={(e) => { handleBlur(e) }}
            autoFocus={type === 'name'}
            placeholder="Type here..."
            className={classes.input}
            id={`list-edit-${type}`}
            variant="outlined"
            value={value}
            size="small"
            fullWidth
            required
        />
    }

    const renderRowEditor = () => {
        if (map.key === 'PROBLEMS_LIST') {
            return <WildHealthTableRow >
                <WildHealthTableCell>
                    <WildHealthAutocompleteComponent
                        getAutoComplete={getIcdCodesAutoComplete}
                        saveChanges={(e, value) => { saveValue(value) }}
                        id={'auto-complete-value'}
                        value={state.editRowValue}
                    />
                </WildHealthTableCell>
            </WildHealthTableRow>
        }

        return (<WildHealthTableRow >
            <WildHealthTableCell>
                {getEditCell('name', state.editRowName, handleEditRowName)}
            </WildHealthTableCell>
            <WildHealthTableCell>
                {getEditCell('value', state.editRowValue, handleEditRowValue)}
            </WildHealthTableCell>
        </WildHealthTableRow>)
    }

    return <HealthSummaryWrapperComponent
        isLoading={state.isLoading}
        patientId={patientId}
        isEmpty={!data.length}
        title={map.section}
        handleCancel={cancelChanges}
        handleEdit={!data.length && handleEditMode}
        handleSave={saveNewChanges}
        editMode={state.editMode}
        handleAdd={() => handleAddNewRow()}
        disableUpdateButton={map.editRestricted || !!state.selectedKey}
        mapKey={map.key}
    >
        <Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <WildHealthTableRow>
                            <WildHealthTableCell>
                                <Box ml={2}>
                                    {map.nameColumn}
                                </Box>
                            </WildHealthTableCell>
                            <WildHealthTableCell>
                                {map.valueColumn}
                            </WildHealthTableCell>
                            {!state.addNewRow &&
                                <FeatureComponent featureFlag={FeatureFlag.HealthSummaryEdit}>
                                    <WildHealthTableCell>
                                    </WildHealthTableCell>
                                </FeatureComponent>
                            }
                        </WildHealthTableRow>
                    </TableHead>
                    <TableBody>
                        {
                            getData().map((item) =>
                                state.isEditorOpened && state.selectedKey === item.key
                                    ? renderRowEditor()
                                    : renderItemRow(item))
                        }
                        {
                            state.addNewRow && renderRowEditor()
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <ProtectedElement
                element={renderMenuItems()}
                permissions={[PermissionType.Coaching, PermissionType.ManagePatients]}
            />
        </Box>
    </HealthSummaryWrapperComponent>
}