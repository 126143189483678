import Axios from 'axios-observable';
import {Observable} from "rxjs";
import { authHeader } from "../../common/helpers/auth-header";
import { API_URL } from "../../../config";
import { snackService } from '../../common/snack/state';
import { BannersStore, bannersStore } from "../stores/bannersStore";
import { BannerModel } from '../../../models/banner.model';


export class BannersService {
    private url = `${API_URL}Banners`;

    constructor(private bannersStore: BannersStore) {
    }

    /**
     * Returns all Banners
     */
    public getAllBanners(): Observable<BannerModel[]> {
        const config = {
            headers: authHeader()
        };
        return new Observable<BannerModel[]>(observer => {
            Axios.get<BannerModel[]>(`${this.url}/all`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.bannersStore.update({ banners: response.data })
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    /**
     * Create Banner
     * @param model
     */
    public createBanner(model: BannerModel) {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.post(`${this.url}`, model, config)
                .pipe()
                .subscribe(
                    response => {
                        this.bannersStore.addBanner(response.data);
                        snackService.success('Banner successfully created');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    /**
     * Update Banner by ID
     * @param model
     */
    public updateBanner(model: BannerModel) {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.put(`${this.url}`, model, config)
                .pipe()
                .subscribe(
                    response => {
                        this.bannersStore.updateBanner(response.data);
                        snackService.success('Banner successfully updated');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    public deleteBanner(id: number) {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.delete(`${this.url}/${id}`, config)
                .pipe()
                .subscribe(
                    () => {
                        this.bannersStore.removeBanner(id);
                        snackService.success('Banner successfully deleted');
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }
}

export const bannersService = new BannersService(bannersStore);
