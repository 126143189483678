import React from 'react';
import {Box} from '@material-ui/core';
import {PaymentPeriodModel} from "../../models/paymentPeriod.models";
import {PaymentPriceModel, PaymentPriceType, PaymentStrategy} from "../../models/paymentPrice.models";
import commonUseStyles from '../../../common/styles/common.styles';
import {ReactComponent as RadioCheckedIcon} from '@img/icons/RadioChecked.svg';
import {ReactComponent as RadioUncheckedIcon} from '@img/icons/RadioUnchecked.svg';
import {displayMoney} from "../../../common/helpers/display-money";
import clsx from 'clsx';
import {useStyles, WildHealthSwitcher} from "./paymentSummaryComponent.styles";
import {getCorrespondingPrice} from "../../helpers/getCorrespondingPrice";
import {PaymentPlanModel} from "../../models/paymentPlan.models";

interface PaymentSummaryComponentProps {
    priceType: PaymentPriceType;
    selectedPeriod: PaymentPeriodModel;
    selectedPrice: PaymentPriceModel;
    handlePriceSelect: Function;
    selectedPlan?: PaymentPlanModel;
    isCouponApplied?: boolean;
}

export const PaymentSummaryTestedComponent: React.FC<PaymentSummaryComponentProps> = (props: PaymentSummaryComponentProps) => {
    const {
        priceType,
        selectedPeriod,
        selectedPrice,
        handlePriceSelect,
        isCouponApplied
    } = props;

    const prices = selectedPeriod.prices;
    const commonClasses = commonUseStyles();
    const classes = useStyles();

    const annualPrice = getCorrespondingPrice(prices, PaymentStrategy.FullPayment, priceType, isCouponApplied);
    const monthlyPrice = getCorrespondingPrice(prices, PaymentStrategy.PartialPayment, priceType, isCouponApplied);

    return (
        <>
            {
                annualPrice && monthlyPrice &&
                <>
                    <Box p={2} display="flex" alignItems="center" onClick={() => handlePriceSelect(annualPrice)} className={selectedPrice.strategy === PaymentStrategy.FullPayment && classes.selectedPlan}>
                        {selectedPrice.strategy === PaymentStrategy.FullPayment ? <RadioCheckedIcon /> : <RadioUncheckedIcon />}
                        <Box ml={2} flexDirection="column">
                            <Box className={clsx(commonClasses.size18, commonClasses.textRegular, commonClasses.colorLinkBlack)}>Yearly Payment</Box>
                            <Box display="flex" alignItems="center" className={clsx(commonClasses.size14, commonClasses.textRegular, commonClasses.colorDarkGray)}>
                                <span>Pay upfront and save an additional <span className={classes.yearlyPaymentContext}>{displayMoney(annualPrice.discount, '$')}</span>/year.</span>
                            </Box>
                        </Box>
                    </Box>
                    <Box py={1} px={2} display="flex" alignItems="center" onClick={() => handlePriceSelect(monthlyPrice)} className={selectedPrice.strategy === PaymentStrategy.PartialPayment && classes.selectedPlan}>
                        {selectedPrice.strategy === PaymentStrategy.PartialPayment ? <RadioCheckedIcon /> : <RadioUncheckedIcon />}
                        <Box ml={2} flexDirection="column">
                            <Box className={clsx(commonClasses.size18, commonClasses.textRegular, commonClasses.colorLinkBlack)}>Monthly Payment</Box>
                        </Box>
                    </Box>
                </>
            }
        </>
    )
};

export const PaymentSummaryComponent: React.FC<PaymentSummaryComponentProps> = (props: PaymentSummaryComponentProps) => {
    const {
        priceType,
        selectedPeriod,
        selectedPrice,
        handlePriceSelect,
        selectedPlan,
        isCouponApplied
    } = props;

    const prices = selectedPeriod.prices;
    const commonClasses = commonUseStyles();
    const classes = useStyles();

    const annualPrice = getCorrespondingPrice(prices, PaymentStrategy.FullPayment, priceType, isCouponApplied);
    const monthlyPrice = getCorrespondingPrice(prices, PaymentStrategy.PartialPayment, priceType, isCouponApplied);

    const paymentStrategyName = () => {
        switch (selectedPrice.strategy) {
            case PaymentStrategy.FullPayment:
                return `annual`;
            case PaymentStrategy.PartialPayment:
                return `monthly`;
            default: return '';
        }
    }

    const paymentAnnotation = () => {
        switch (selectedPrice.strategy) {
            case PaymentStrategy.FullPayment:
                return `This plan will auto-renew annually.`;
            case PaymentStrategy.PartialPayment:
                return `Your card will automatically be charged monthly.`;
            default: return '';
        }
    }

    const renderTip = () => {
        switch (selectedPrice.strategy) {
            case PaymentStrategy.FullPayment:
                return `Best Value - Save ${displayMoney(annualPrice.discount, '$')}!`;
            case PaymentStrategy.PartialPayment:
                return `Go Annual to Save ${displayMoney(annualPrice.discount, '$')}!`;
            default: return '';
        }
    }

    const displayPostfix = (paymentPlan: PaymentPlanModel, paymentPrice: PaymentPriceModel) => {
        return paymentPlan.name === 'PRECISION_CARE_PACKAGE' ? '' : paymentPrice.strategy === PaymentStrategy.FullPayment ? '' : '/mo'
    };

    return (
        <>
            {
                annualPrice && monthlyPrice ?
                <>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <Box className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorGray700, {
                            [commonClasses.colorNavy]: selectedPrice.strategy === PaymentStrategy.PartialPayment
                        })}>
                            Monthly
                        </Box>
                        <Box mx={2}>
                            <WildHealthSwitcher
                                checked={selectedPrice.strategy === PaymentStrategy.FullPayment}
                                onChange={() => handlePriceSelect(selectedPrice.strategy === PaymentStrategy.FullPayment ? monthlyPrice : annualPrice)}
                                name="selectStragyToggle"
                            />
                        </Box>
                        <Box className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorGray700, {
                            [commonClasses.colorNavy]: selectedPrice.strategy === PaymentStrategy.FullPayment
                        })}>
                            Annual
                        </Box>
                    </Box>
                    <Box mt={3.5} p={2} className={classes.activePlan}>
                        <Box className={classes.tipCard}>{renderTip()}</Box>
                        <Box mt={1} display="flex" alignItems="center" justifyContent="space-between">
                            <Box className={classes.strategyContext}>{paymentStrategyName()} Plan</Box>
                            <Box className={clsx(commonClasses.size18, commonClasses.textMedium, commonClasses.colorNavy)}>{displayMoney(selectedPrice.price, '$')}<span className={commonClasses.size12}>{displayPostfix(selectedPlan, selectedPrice)}</span></Box>
                        </Box>
                        <Box mt={1} className={clsx(commonClasses.size12, commonClasses.colorGray700)}>
                            {paymentAnnotation()}
                        </Box>
                    </Box>
                </>
                :
                <>
                    <Box mt={1.5} p={2} className={classes.activePlan}>
                        {
                            selectedPrice.discount > 0 && <Box className={classes.tipCard} mb={1}>Save {displayMoney(selectedPrice.discount, '$')} with Plan</Box>
                        }
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Box className={classes.strategyContext}>{selectedPlan.displayName}</Box>
                            <Box className={clsx(commonClasses.size18, commonClasses.textMedium, commonClasses.colorNavy)}>{displayMoney(selectedPrice.price, '$')}</Box>
                        </Box>
                    </Box>
                </>
            }
        </>
    )
};