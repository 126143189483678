import { Box, Dialog, DialogActions, DialogContent, DialogContentText, useMediaQuery, useTheme, Button } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { DialogTitle } from '../../../common/components/dialogTitle/DialogTitle';
import { WildHealthButton } from '../../components/wildHealthButton/WildHealthButton';
import commonUseStyles from '../../styles/common.styles';
import { useFacade } from "./confirmDialog.hooks";
import { useStyles } from "./ConfirmDialog.styles";
import { isIOS } from "react-device-detect";

export const CommonConfirmDialog: React.FC = () => {
    const [state, handleAnswer] = useFacade();

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleAnswer(true);
        }
    }

    const classes = useStyles();
    const commonClasses = commonUseStyles();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box onKeyPress={(e) => handleKeyPress(e)}>
            <Dialog
                fullWidth
                open={state.isOpen}
                onClose={() => handleAnswer(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {!fullScreen && (
                    <DialogTitle
                        id="dialog-title"
                        onClose={() => handleAnswer(false)}
                        classes={{ root: classes.dialogTitle }}
                    >
                        <span className={clsx(commonClasses.size24, commonClasses.textMedium)}>{state.title}</span>
                    </DialogTitle>
                )}

                <DialogContent classes={{ root: classes.dialogContent }}>
                    {
                        fullScreen ? (
                            <>
                                <Box px={isIOS ? 4 : 3}>
                                    <Box pb={isIOS ? 0.5 : "20px"} textAlign={isIOS && "center"}>
                                        <span className={clsx(commonClasses.size20, commonClasses.textSemiBold, commonClasses.colorBlack)}>
                                            {state.title}
                                        </span>
                                    </Box>
                                    <Box pb={isIOS ? "20px" : "40px"} textAlign={isIOS && "center"}>
                                        <span className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorBlack)}>
                                            {state.message}
                                        </span>
                                    </Box>
                                </Box>
                                <Box display="flex" alignItems="center" justifyContent={!isIOS && "flex-end"} className={isIOS && classes.actionSection}>
                                    <Box flex={isIOS && 1} py="10px" textAlign="center">
                                        <Button onClick={() => handleAnswer(false)}>
                                            <span className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorGray1)}>
                                                {state.cancelTitle}
                                            </span>
                                        </Button>
                                    </Box>
                                    <Box flex={isIOS && 1} py="10px" px={!isIOS && 2} className={isIOS && classes.acceptBtn}>
                                        <Button onClick={() => handleAnswer(true)}>
                                            <span className={clsx(commonClasses.size14, commonClasses.textMedium, state.variant === 'danger' ? commonClasses.colorBad : commonClasses.colorMain)}>
                                                {state.acceptTitle}
                                            </span>
                                        </Button>
                                    </Box>
                                </Box>
                            </>
                        ) : (
                                <DialogContentText>
                                    <span className={commonClasses.colorBlack}>{state.message}</span>
                                </DialogContentText>
                            )
                    }
                </DialogContent>
                {!fullScreen && (
                    <DialogActions classes={{ root: classes.dialogActions }}>
                        <WildHealthButton id={`confirm-dialog-${state.cancelTitle}-${state.title}`} size='large' color='tertiary' onClick={() => handleAnswer(false)}>
                            {state.cancelTitle}
                        </WildHealthButton>
                        <WildHealthButton id={`confirm-dialog-${state.acceptTitle}-${state.title}`} size='large' color={state.variant === 'danger' ? 'danger' : 'primary'} onClick={() => handleAnswer(true)}>
                            {state.acceptTitle}
                        </WildHealthButton>
                    </DialogActions>
                )}
            </Dialog>
        </Box>
    );
}
