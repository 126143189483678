import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles(() =>
    createStyles({
        dropContainer: {
            height: 60,
            width: '100%',
            border: `1px dashed ${colors.gray1}`,
            background: `${colors.lightGray2}99`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        dropIcon: {
            color: colors.gray1,
            fontSize: 40,
            marginRight: 4,

        },
        dropTitle: {
            color: colors.gray1,
            fontSize: 16,
        },
        attachment: {
            border: `1px solid ${colors.stroke}`,
            borderRadius: 2,
            background: colors.gray8,
            display: 'flex',
            alignItems: 'center',
            maxWidth: 500,
            height: 32,
        },
        attachmentIcon: {
            color: colors.gray2,
        },
        attachmentTitle: {
            fontSize: 14,
            fontWeight: 500,
            color: colors.black,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },

        label: {
            textTransform: `none`,
            color: colors.main,
            fontSize: 16,
            textDecoration: 'underline',
        },
        progress: {
            color: colors.main
        },
        deleteIcon: {
            color: colors.bad,
            cursor: 'pointer',
        },
        notificationToolTipText: {
            fontFamily: 'Be Vietnam Pro',
            fontSize: 12,
            fontWeight: 400,
            color: colors.white,
        },
        arrow: {
            color: colors.lightBlack,
        },
        customWidth: {
            width: '278px',
            maxWidth: '278px',
            backgroundColor: colors.lightBlack,
            padding: 10,
        },
        tooltipPlacement: {
            margin: '8px 0',
        },
    })
);
