import React from 'react';
import {Box} from '@material-ui/core';
import {QuestionnairesResultListComponent} from "../../questionnairesResultListComponent/QuestionnairesResultListComponent";
import {
    QuestionnaireModel,
    QuestionnaireResultModel,
    GroupSubjectFromServer,
    GroupSubjectTitle
} from "../../../models/questionnaire.models";
import {useStyles} from './GeneralResultComponent.styles';
import {isGenderMatch} from "../../../helpers/is-gender-match";

export interface GeneralResultComponentHealthFormsProps {
    result: QuestionnaireResultModel;
    questionnaire: QuestionnaireModel;
    questionView: any;
    groupWithTitle?: boolean;
    patientId?: number | null;
    gender
}

export const GeneralResultComponentHealthForms: React.FC<GeneralResultComponentHealthFormsProps> = (props: GeneralResultComponentHealthFormsProps) => {
    const {
        result,
        questionnaire,
        questionView,
        groupWithTitle,
        patientId,
        gender
    } = props;

    const classes = useStyles();

    const getTitle = (groupItem: string) => {
        const groupSubject = questionnaire.questions.find(el => el.name === groupItem).questionSubject
        switch (groupSubject) {
            case GroupSubjectFromServer.SetYourConnectionGoals:
                return GroupSubjectTitle.SetYourConnectionGoals;
            case GroupSubjectFromServer.SetYourExerciseGoals:
                return GroupSubjectTitle.SetYourExerciseGoals;
            case GroupSubjectFromServer.SetYourMindfulnessGoals:
                return GroupSubjectTitle.SetYourMindfulnessGoals;
            case GroupSubjectFromServer.SetYourNutritionGoals:
                return GroupSubjectTitle.SetYourNutritionGoals;
            case GroupSubjectFromServer.SetYourSleepGoals:
                return GroupSubjectTitle.SetYourSleepGoals;
            default:
                return "";
        }
    }

    return (
        <>
            <Box pt={5} pb={5}>
                {
                    questionView.map((item, index) => {
                            const relatedQuestions = questionnaire
                                .questions
                                .filter(x => item.includes(x.name) && isGenderMatch(x, gender) )
                           
                            if (!relatedQuestions || !relatedQuestions.length) {
                                return <React.Fragment key={`empty-gender-${index}`}/>;
                            }

                            return (
                                <Box key={index} className={classes.wrappQuestionnaires}>
                                    <QuestionnairesResultListComponent
                                        gender={gender}
                                        questionnaire={questionnaire}
                                        result={result}
                                        questions={item}
                                        title={groupWithTitle ? getTitle(item[0]) : ''}
                                        patientId={patientId}
                                    />
                                </Box>
                            )
                        }
                    )
                }
            </Box>
        </>
    )
}
