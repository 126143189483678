import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
            [theme.breakpoints.down("xs")]: {
                '& .MuiDialog-paper': {
                    borderRadius: '10px 10px 0 0',
                    margin: 0,
                    marginTop: 'auto'
                },
            },
        },
        dialogCloseButton: {
            [theme.breakpoints.down("sm")]: {
                position: 'absolute',
                top: 0,
                right: 0,
            },
        },
        dialogTitle: {
            paddingTop: '30px !important',
            paddingLeft: '40px !important',
            paddingRight: '40px !important',
            paddingBottom: '0 !important',
        },
        dialogActions: {
            display: 'flex',
            justifyContent: 'center',
            paddingRight: theme.spacing(5),
            paddingLeft: theme.spacing(5),
            paddingBottom: theme.spacing(4),
            paddingTop: theme.spacing(2.5),
            [theme.breakpoints.down("xs")]: {
                paddingRight: theme.spacing(3),
                paddingLeft: theme.spacing(3),
            },
        },
        content: {
            display: "flex",
            flexDirection: "column",
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0 50px 20px',
            [theme.breakpoints.down("xs")]: {
                marginRight: 0,
                marginLeft: 0,
            },
        },
        title: {
            fontSize: 24,
            fontWeight: 500,
        }
    })
);
