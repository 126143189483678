import Axios from "axios-observable";
import { Observable, Subject } from "rxjs";
import { authHeader } from "../../common/helpers/auth-header";
import { CallbackModel } from "../../common/models/callback.model";
import { snackService } from "../../common/snack/state";
import { UnmatchedDocumentModel } from "../../unmatchedLabs/models/unmatchedLabs.models";
import { LabOrderModel } from "../models/labOrders.models";
import { CancelOrderModel, CreateOrderModel, OverrideOrderModel } from "../models/orders.models";
import { labOrdersStore, LabOrdersStore } from "../stores/labOrdersStore";

export class LabOrdersService {
    private url = `${process.env.REACT_APP_API_URL}Orders/Lab`;
    private urlUnmatchedLab = `${process.env.REACT_APP_API_URL}Lab/Unmatched`;

    constructor(private store: LabOrdersStore) {
    }

    public onCancel = new Subject<CallbackModel<number>>();
    public onMarkAsPaid = new Subject<CallbackModel<number>>();

    public get(patientId: number): Observable<LabOrderModel[]> {
        return new Observable<LabOrderModel[]>(observer => {
            Axios.get<LabOrderModel[]>(`${this.url}?patientId=${patientId}`, { headers: authHeader() })
            .pipe()
            .subscribe(
                (response) => {
                    this.store.update({orders: response.data});
                    observer.next(response.data);
                    observer.complete();
                },
                error => {
                    snackService.commonErrorHandler(error);
                    observer.error(error);
                    observer.complete();
                },
            );
        });
    }

    public getMyOrders(): Observable<LabOrderModel[]> {
        return new Observable<LabOrderModel[]>(observer => {
            Axios.get<LabOrderModel[]>(`${this.url}/GetPatientLabOrders`, { headers: authHeader() })
            .pipe()
            .subscribe(
                (response) => {
                    this.store.update({orders: response.data});
                    observer.next(response.data);
                    observer.complete();
                },
                error => {
                    snackService.commonErrorHandler(error);
                    observer.error(error);
                    observer.complete();
                },
            );
        });
    }

    public create(model: CreateOrderModel): Observable<LabOrderModel> {
        return new Observable<LabOrderModel>(observer => {
            Axios.post<LabOrderModel>(`${this.url}`, model, { headers: authHeader() })
            .pipe()
            .subscribe(
                (response) => {
                    this.store.addOrder(response.data);
                    observer.next(response.data);
                    observer.complete();
                },
                error => {
                    snackService.commonErrorHandler(error);
                    observer.error(error);
                    observer.complete();
                },
            );
        });
    }

    public pay(orderId: number): Observable<LabOrderModel> {
        return new Observable<LabOrderModel>(observer => {
            Axios.post<LabOrderModel>(`${this.url}/${orderId}/Pay`, {}, { headers: authHeader() })
            .pipe()
            .subscribe(
                (response) => {
                    this.store.updateOrder(response.data);
                    observer.next(response.data);
                    observer.complete();
                },
                error => {
                    snackService.commonErrorHandler(error);
                    observer.error(error);
                    observer.complete();
                },
            );
        });
    }

    public getRequisition(orderId: number, fileName: string): Observable<any> {
        return new Observable<any>(observer => {
            Axios.get(`${this.url}/${orderId}/Requisition`, {
                headers: authHeader(),
                responseType: "blob"
            })
                .pipe()
                .subscribe(
                    (response) => {
                        //FileDownload(response.data, `${fileName}${getFileExtension(response.headers)}`);
                        snackService.success('File successfully downloaded!');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public getMyRequisition(orderId: number): Observable<any> {
        return new Observable<any>(observer => {
            Axios.get(`${this.url}/${orderId}/MyRequisition`, {
                headers: authHeader(),
                responseType: "blob"
            })
                .pipe()
                .subscribe(
                    (response) => {
                        snackService.success('File successfully downloaded!');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public getRequisitionByPatient(): Observable<any> {
        return new Observable<any>(observer => {
            Axios.get(`${this.url}/requisition`, {
                headers: authHeader(),
                responseType: "blob"
            })
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public getOrdersIFrame(patientId: number): Observable<string> {
        return new Observable<string>(observer => {
            Axios.get<string>(`${this.url}/Iframe?patientId=${patientId}`, { headers: authHeader() })
            .pipe()
            .subscribe(
                (response) => {
                    observer.next(response.data);
                    observer.complete();
                },
                error => {
                    snackService.commonErrorHandler(error);
                    observer.error(error);
                    observer.complete();
                },
            );
        });
    }

    public cancelOrder(labOrderId: number): Observable<LabOrderModel> {
        return new Observable<LabOrderModel>(observer => {
            this.onCancel.next({
                data: labOrderId,
                callback: (labOrderId: number, cancellationReason: string) => {
                    const model: CancelOrderModel = {
                        id: labOrderId,
                        cancellationReason: cancellationReason
                    }
                    Axios.post<LabOrderModel>(`${this.url}/${labOrderId}/Cancel`, model, {headers: authHeader()})
                    .pipe()
                    .subscribe(
                        (response) => {
                            this.store.updateOrder(response.data);
                            observer.next(response.data);
                            observer.complete();
                        },
                        error => {
                            snackService.commonErrorHandler(error);
                            observer.error(error);
                            observer.complete();
                        }
                    )
                }
            })
        })
    }

    public markAsPaidOrder(labOrderId: number): Observable<LabOrderModel> {
        return new Observable<LabOrderModel>(observer => {
            this.onMarkAsPaid.next({
                data: labOrderId,
                callback: (labOrderId: number, reason: string) => {
                    const model: OverrideOrderModel = {
                        id: labOrderId,
                        reason,
                    }
                    Axios.post<LabOrderModel>(`${this.url}/MarkAsPaid`, model, {headers: authHeader()})
                    .pipe()
                    .subscribe(
                        (response) => {
                            this.store.updateOrder(response.data);
                            observer.next(response.data);
                            observer.complete();
                        },
                        error => {
                            snackService.commonErrorHandler(error);
                            observer.error(error);
                            observer.complete();
                        }
                    )
                }
            })
        })
    }

        // /**
    //  * Get All Labs results with documents
    //  */

    public getUnmatchedLabs(): Observable<any> {
        return new Observable<LabOrderModel[]>(observer => {
            Axios.get<any>(`${this.urlUnmatchedLab}`, { headers: authHeader() })
            .pipe()
            .subscribe(
                (response) => {
                    this.store.getDocumentsFromResults(response.data.results);
                    observer.next(response.data);
                    observer.complete();
                },
                error => {
                    snackService.commonErrorHandler(error);
                    observer.error(error);
                    observer.complete();
                },
            );
        });
    }

            // /**
    //  * Get All Labs results with documents
    //  */

    public getAnyUnmatchedFiles(): Observable<boolean> {
        return new Observable<boolean>(observer => {
            Axios.get<any>(`${this.urlUnmatchedLab}/AnyUnmatchedFiles`, { headers: authHeader() })
            .pipe()
            .subscribe(
                (response) => {
                    this.store.update({isUnmatchedFile: response.data});
                    observer.next(response.data);
                    observer.complete();
                },
                error => {
                    snackService.commonErrorHandler(error);
                    observer.error(error);
                    observer.complete();
                },
            );
        });
    }

            // /**
    //  * Match file to patient
    //  */

    public assignUnmatchedFile(patientId: number, labResultId: number): Observable<UnmatchedDocumentModel> {
        let url = `${this.urlUnmatchedLab}/SaveFileForPatient?patientId=${patientId}&labResultId=${labResultId}`;
        const config = {headers: authHeader()};

        return new Observable<UnmatchedDocumentModel>(observer => {
            Axios.get<UnmatchedDocumentModel>(url, config)
            .pipe()
            .subscribe(
                (response) => {
                    observer.next(response.data);
                    observer.complete();
                },
                error => {
                    snackService.commonErrorHandler(error);
                    observer.error(error);
                    observer.complete();
                },
            );
        });
    }

    public deleteUnmatchedFile(id: number): Observable<void> {
        let url = `${this.urlUnmatchedLab}/${id}`;
        const config = {headers: authHeader()};

        return new Observable(observer => {
            Axios.delete<UnmatchedDocumentModel>(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.deleteDocument(id);
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    },
                );
        });
    }
    
    // /**
    //  * Views unmatched file
    //  */
    public viewinline(documentId: number, mimeType: string): Observable<any> {
        return new Observable(observer => {
            Axios.get(`${this.urlUnmatchedLab}/viewInline/document?documentId=${documentId}&mimeType=${mimeType}`, {
                headers: authHeader(),
                responseType: 'blob',
            })
                .pipe()
                .subscribe(
                    (response) => {
                        const file = new Blob([response.data], { type: mimeType });
                        const fileURL = URL.createObjectURL(file);
                        observer.next(fileURL);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }
}

export const labOrdersService = new LabOrdersService(labOrdersStore);