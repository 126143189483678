import { Box, Collapse, Divider } from '@material-ui/core';
import React, { MutableRefObject, useEffect } from 'react';
import expandIcon from "@img/icons/expand.svg";
import collapseIcon from "@img/icons/collapse.svg";
import commonUseStyles from '../../styles/common.styles';
import clsx from 'clsx';

export interface ExpandableComponentProps {
    title: string,
    content: JSX.Element,
    expanded: boolean,
    handleExpand: Function,
    divider?: boolean,
    refCurrent: MutableRefObject<any>
}

export const ExpandableComponent: React.FC<ExpandableComponentProps> = (props: ExpandableComponentProps) => {
    const {
        title,
        content,
        expanded,
        handleExpand,
        divider = true,
        refCurrent
    } = props;

    const commonClasses = commonUseStyles();

    return (
        <Box width={1}>
            <Box display='flex' alignItems='center'>
                <Box onClick={() => handleExpand()} display='flex' alignItems='center' className={commonClasses.pointer}>
                    <img src={expanded ? collapseIcon : expandIcon} alt='expanded_logo' />
                </Box>
                <Box ml={2}>
                    <span ref={refCurrent} className={clsx(commonClasses.size18, commonClasses.textMedium)}>{title}</span>
                </Box>
            </Box>

            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <>
                    {divider && <Box my={1}>
                        <Divider />
                    </Box>}
                    <Box mt={3}>
                        {
                            content
                        }
                    </Box>
                </>
            </Collapse>
        </Box>
    );
}