import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontFamily: 'Be Vietnam Pro',
            fontSize: '1.6rem',
            fontWeight: 500,
            lineHeight: 1.18,
            letterSpacing: 0.17,
            color: '#292929 !important'
        },
        subTitle: {
            fontFamily: 'Be Vietnam Pro',
            fontSize: 16,
            fontWeight: 500,
            lineHeight: 1.38,
            letterSpacing: 0.8,
        },
        sectionTitle: {
            fontFamily: 'Be Vietnam Pro',
            fontSize: 16,
            fontWeight: 500,
            lineHeight: 1.38,
            letterSpacing: 0.8,
            color: 'rgba(0, 0, 0, 0.56)',
        },
        regular: {
            fontFamily: 'Be Vietnam Pro',
            fontSize: 15,
            fontWeight: 300,
            lineHeight: 1.33,
            letterSpacing: 0.15,
        },
        divider: {
            backgroundColor: "#f9f9fb",
            margin: 'auto',
            height: 2,
        },
        textWrap: {
            wordBreak: 'break-all',
            display: 'flex',
            flexWrap: "wrap"
        },
        colorMain: {
            color: colors.main
        },
        renderText: {
            fontFamily: 'Be Vietnam Pro',
            fontSize: 16,
            fontWeight: 400,
            lineHeight: 1.33,
            letterSpacing: 0.15,
            color: colors.black,
        },
    })
);
