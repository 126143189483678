import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { colors } from '../../constants/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderRadius: 2,
            textTransform: 'none',
            boxSizing: 'border-box'
        },
        default: {
            fontWeight: 500,
            fontSize: 14,
            lineHeight: '16.41px',
            height: 34,
            minWidth: 83,
            paddingRight: 16,
            paddingLeft: 16,

        },
        large: {
            fontWeight: 500,
            fontSize: 16,
            lineHeight: '19px',
            height: 48,
            paddingRight: 18,
            paddingLeft: 18
        },
        medium: {
            fontWeight: 500,
            fontSize: 16,
            lineHeight: '19px',
            height: 40,
            paddingRight: 12,
            paddingLeft: 12
        },
        small: {
            fontWeight: 500,
            fontSize: 14,
            lineHeight: '16px',
            height: 28,
            paddingRight: 8,
            paddingLeft: 8,
        },
        datePicker: {
            border: `1px solid ${colors.gray1}`,
            borderRadius: '4px',
            display: 'flex',
            justifyContent: 'space-between',
            background: colors.white,
            color: colors.gray1,
            fontWeight: 400,
            '&:hover': {
                background: colors.white,
            },
            '& svg': {
                fill: colors.white,
            },
        },
        primary: {
            border: `1px solid ${colors.primary}`,
            background: colors.primary,
            color: colors.black,
            '& svg': {
                fill: colors.black,
            },
            '&:hover': {
                border: `1px solid ${colors.primaryV}`,
                background: colors.primaryV,
                color: colors.white,
                '& svg': {
                    fill: colors.white,
                },
            },
            '&:disabled': {
                color: colors.gray400,
                backgroundColor: colors.disabled,
                cursor: 'not-allowed',
                border: 'none',
                '& svg': {
                    fill: colors.gray400,
                },
            },
        },
        secondary: {
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: colors.primaryV,
            color: colors.primaryV,
            background: colors.white,
            '& svg': {
                fill: colors.primaryV,
            },
            '&:hover': {
                background: colors.primaryV,
                color: colors.white,
                '& svg': {
                    fill: colors.white,
                }
            },
            '&:disabled': {
                color: colors.gray400,
                borderColor: colors.disabled,
                backgroundColor: colors.disabled,
                cursor: 'not-allowed',
                '& svg': {
                    fill: colors.gray400,
                },
            },
        },
        tertiary: {
            border: 'none',
            color: colors.gray1,
            backgroundColor: colors.gray3,
            '& svg': {
                fill: colors.gray1,
            },
            '&:hover': {
                backgroundColor: colors.stroke,
                '&:disabled': {
                    backgroundColor: colors.disabled,
                },
            },
            '&:disabled': {
                color: colors.gray2,
                backgroundColor: colors.disabled,
                cursor: 'not-allowed',
                '& svg': {
                    fill: colors.gray2,
                },
            },
        },
        danger: {
            border: 'none',
            color: colors.white,
            backgroundColor: colors.bad,
            '& svg': {
                fill: colors.white,
            },
            '&:hover': {
                backgroundColor: colors.bad,
                '&:disabled': {
                    backgroundColor: colors.disabled,
                },
            },
            '&:disabled': {
                color: colors.gray2,
                backgroundColor: colors.disabled,
                cursor: 'not-allowed',
                '& svg': {
                    fill: colors.gray2,
                },
            },
        },
        referral: {
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: colors.main,
            color: colors.main,
            background: colors.white,
            '& svg': {
                fill: colors.main,
            },
            '&:hover': {
                background: colors.accent1,
                color: colors.white,
                '& svg': {
                    fill: colors.white,
                }
            },
            '&:disabled': {
                color: colors.gray2,
                borderColor: colors.stroke,
                backgroundColor: colors.white,
                cursor: 'not-allowed',
                '& svg': {
                    fill: colors.gray2,
                },
            },
        },
        none: {
            border: 'none',
        },
        mint: {
            border: `1px solid ${colors.lightMint}`,
            background: colors.lightMint,
            color: colors.navy,
            '& svg': {
                fill: colors.navy,
            },
            '&:hover': {
                background: colors.darkMint,
                '&:disabled': {
                    backgroundColor: colors.lightMint50,
                },
            },
            '&:disabled': {
                color: colors.lightNavy,
                backgroundColor: colors.lightMint50,
                cursor: 'not-allowed',
                border: 'none',
                '& svg': {
                    fill: colors.lightNavy,
                },
            },
        },
        white: {
            border: `1px solid ${colors.white}`,
            background: colors.white,
            color: colors.black,
            '&:hover': {
                border: `1px solid ${colors.mint100}`,
                background: colors.mint100,
                color: colors.black,
            },
            '&:disabled': {
                color: colors.gray400,
                backgroundColor: colors.disabled,
                cursor: 'not-allowed',
                border: 'none',
            },
        },
        primaryV: {
            border: `1px solid ${colors.primaryV}`,
            background: colors.primaryV,
            color: colors.white,
            '&:hover': {
                border: `1px solid ${colors.mint900}`,
                background: colors.mint900,
                color: colors.white,
            },
            '&:disabled': {
                color: colors.gray400,
                backgroundColor: colors.disabled,
                cursor: 'not-allowed',
                border: 'none',
            },
        },
    })
)
