import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { onEmit } from '../../../common/helpers/on-emit';
import { ScheduledMessageModel } from "../../models/message.models";
import { scheduleMessageService } from "../../services/scheduleMessage.service";
import { scheduledMessagesQuery } from '../../stores/scheduledMessagesStore/scheduledMessages.query';
import { scheduledMessagesStore } from '../../stores/scheduledMessagesStore/scheduledMessages.store';

interface ScheduledMessagesComponentState {
    editingTarget: ScheduledMessageModel;
    messages: ScheduledMessageModel[];
    targetMessageId: number;
    menuAnchorEl: HTMLElement;
}

export function useFacade(handleClose?: () => void,): [ScheduledMessagesComponentState, (menuAnchorEl?: HTMLElement, messageId?: number) => void, () => void, () => void] {

    const [state, setState] = useState({
        editingTarget: null,
        messages: [],
        targetMessageId: 0,
        menuAnchorEl: null,
    } as ScheduledMessagesComponentState);

    const handleToggleActions = (menuAnchorEl?: HTMLElement, messageId?: number) => {
        setState(state => ({ ...state, menuAnchorEl, targetMessageId: messageId }));
    }

    const handleEdit = () => {
        const target = state.messages.find(i => i.id === state.targetMessageId);
        if (target) {
            scheduledMessagesStore.startEditing(target);
            handleClose && handleClose();
            handleToggleActions();
        }
    }

    const handleDelete = () => {
        scheduleMessageService.delete(state.targetMessageId).subscribe();
        handleToggleActions();
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<ScheduledMessageModel>(scheduledMessagesQuery.editingTarget$, message =>
                setState(state => ({ ...state, editingTarget: message }))
            ),
            onEmit<ScheduledMessageModel[]>(scheduledMessagesQuery.scheduledMessages$, scheduledMessages =>
                setState(state => ({ ...state, messages: scheduledMessages }))
            ),
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, []);

    return [state, handleToggleActions, handleEdit, handleDelete];
}

