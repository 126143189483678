import {useState} from "react";

export interface DraftNotePageState {
    isLoading: boolean
}

export function useFacade(): [
    DraftNotePageState
] {
    const [state] = useState({
        isLoading: false,
    } as DraftNotePageState);

    return [state];
}