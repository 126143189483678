import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 400,
            [theme.breakpoints.down("xs")]: {
                height: "fit-content",
            },
        },
        content: {
            background: colors.bg,
            padding: 30,
            [theme.breakpoints.down("sm")]: {
                padding: 20,
            },
            [theme.breakpoints.down("xs")]: {
                padding: 16,
            },
        },
        title: {
            fontSize: 20,
            fontWeight: 600,
            fontFamily: "Poppins",
            color: colors.black,
        },
        link: {
            fontSize: 14,
            fontWeight: 500,
            color: colors.main,
            cursor: 'pointer'
        },
        filterButton: {
            color: colors.gray1,
            cursor: 'pointer',
            fontWeight: 500,
            fontSize: 15,
            height: 40,
            padding: 10,
            paddingLeft: 15,
            paddingRight: 15
        },
        selectedFilterButton: {
            cursor: 'pointer',
            fontWeight: 500,
            fontSize: 15,
            background: colors.lightGray2,
            color: colors.main,
            borderRadius: 20,
            height: 40,
            padding: 10,
            paddingLeft: 15,
            paddingRight: 15
        },
        noHealthGoalsText: {
            fontSize: 15,
            fontWeight: 600,
            color: colors.main,
        },
        noHealthGoalsSubText: {
            fontSize: 15,
            fontWeight: 500,
            color: colors.gray1,
        }
    })
);
