import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        image: {
            maxWidth: 300,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                maxWidth: 444,
            },
        },
        imageSmall: {
            maxWidth: 200,
        }
    })
)