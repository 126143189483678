import React from 'react';
import {Box, TextField} from "@material-ui/core";
import {useFacade} from "./applyPaymentCouponComponent.hooks";
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';

interface PaymentCouponComponentProps {
    handleCouponChanges: Function;
    applyCoupon: Function;
    couponCode: string;
}

export const ApplyPaymentCouponComponent: React.FC<PaymentCouponComponentProps> = (props: PaymentCouponComponentProps) => {
    const [
        {
            model,
            errors
        },
        handleCouponChanges,
        handleCouponApply
    ] = useFacade(props.couponCode, props.applyCoupon);

    return (
        <>
            <Box display="flex" justifyContent="space-between" mt={2}>
                <Box pr={1} width={0.75} display="flex" alignItems="center">
                    <TextField
                        id={`couponCode`}
                        fullWidth
                        size="small"
                        placeholder="Enter Promo Code"
                        variant="outlined"
                        InputProps={{className: 'input'}}
                        value={model.couponCode}
                        label="Enter Promo Сode"
                        error={!!errors['couponCode']}
                        helperText={errors['couponCode']}
                        onChange={(event) => handleCouponChanges(event.target.value)}
                    />
                </Box>

                <Box pl={1} width={0.25} height={1} display="flex">
                    <WildHealthButton
                        id="apply-payment"
                        size="large"
                        fullWidth
                        onClick={() => handleCouponApply()} >
                        Apply
                    </WildHealthButton>
                </Box>
            </Box>
        </>
    )
};