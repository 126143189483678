import {Query} from '@datorama/akita';
import { InsuranceState, insuranceStore, InsuranceStore } from './insurance.store';
import {AttachmentModel} from "../../common/models/attachment.models";

/**
 * Provides Insurance queries
 */
export class InsuranceQuery extends Query<InsuranceState> {
    insurances$ = this.select(state => state.insurances);
    coverages$ = this.select(state => state.coverages);
    attachments$ = this.select(state => state.attachments);
    verificationList$ = this.select(state => state.verificationList);
    insuranceAvailability$ = this.select(state => state.insuranceAvailability);
    insuranceConfigurations$ = this.select(state => state.insuranceConfigurations);
    targetInsuranceConfiguration$ = this.select(state => state.targetInsuranceConfiguration);
    comments$ = this.select(state => state.comments);

    constructor(protected store: InsuranceStore) {
        super(store);
    }

    public getAttachments(): AttachmentModel[] {
        return this.getValue().attachments;
    }

    public getInsuranceAvailability(): boolean {
        return this.getValue().insuranceAvailability;
    }
}

export const insuranceQuery = new InsuranceQuery(insuranceStore);