import { GeneralValidator } from "../../../common/validation/general-validator";
import {
  notWhitespaceValidationRule,
  requiredValidationRule,
  linkValidationRule,
} from "../../../common/validation/general-validation-rules";

export const createCommonSupplementValidator = new GeneralValidator({
  'name': [notWhitespaceValidationRule(), requiredValidationRule()],
  'instructions': [notWhitespaceValidationRule(), requiredValidationRule()],
  'purchaseLink': [linkValidationRule, requiredValidationRule()],
});