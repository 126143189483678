export class MedicalHistoryAndLifestyleQuestionNames {
    static readonly DEMOGRAPHICS_AND_BIOMETRICS_ETHNICITY = "DEMOGRAPHICS_AND_BIOMETRICS_ETHNICITY"
    static readonly DEMOGRAPHICS_AND_BIOMETRICS_HEIGHT = "DEMOGRAPHICS_AND_BIOMETRICS_HEIGHT"
    static readonly DEMOGRAPHICS_AND_BIOMETRICS_WEIGHT = "DEMOGRAPHICS_AND_BIOMETRICS_WEIGHT"
    static readonly DEMOGRAPHICS_AND_BIOMETRICS_WAIST = "DEMOGRAPHICS_AND_BIOMETRICS_WAIST"
    static readonly DEMOGRAPHICS_AND_BIOMETRICS_SBP_TOP = "DEMOGRAPHICS_AND_BIOMETRICS_SBP_TOP"
    static readonly DEMOGRAPHICS_AND_BIOMETRICS_SBP_BOTTOM = "DEMOGRAPHICS_AND_BIOMETRICS_SBP_BOTTOM"
    static readonly MEDICAL_HISTORY_CONDITIONS = "MEDICAL_HISTORY_CONDITIONS";
    static readonly MEDICAL_HISTORY_CONDITIONS_COMMENT = "MEDICAL_HISTORY_CONDITIONS_COMMENT";
    static readonly MEDICAL_HISTORY_CONDITIONS_FAMILY = "MEDICAL_HISTORY_CONDITIONS_FAMILY";
    static readonly MEDICAL_HISTORY_CONDITIONS_FAMILY_COMMENT = "MEDICAL_HISTORY_CONDITIONS_FAMILY_COMMENT";
    static readonly MEDICAL_HISTORY_DIABET = "MEDICAL_HISTORY_DIABET";
    static readonly MEDICAL_HISTORY_DIABET_TYPE = "MEDICAL_HISTORY_DIABET_TYPE";
    static readonly MEDICAL_HISTORY_CHOLESTEROL = "MEDICAL_HISTORY_CHOLESTEROL";
    static readonly MEDICAL_HISTORY_BLOOD_PRESSURE = "MEDICAL_HISTORY_BLOOD_PRESSURE";
    static readonly CORONARY_CALCIUM_SCORE = "CORONARY_CALCIUM_SCORE";
    static readonly SCORE = "SCORE";
    static readonly SMOKING_HISTORY = "SMOKING_HISTORY";
    static readonly ALCOHOL_HISTORY = "ALCOHOL_HISTORY";
    static readonly ANY_MEDICATIONS = "ANY_MEDICATIONS";
    static readonly MEDICATIONS = "MEDICATIONS";
    static readonly SUPPLEMENT = "SUPPLEMENT";
    static readonly ANY_SUPPLEMENTS = "ANY_SUPPLEMENTS";
    static readonly SUPPLEMENTS = "SUPPLEMENTS";
    static readonly DRUG_ALLERGIES = "DRUG_ALLERGIES";
    static readonly ALLERGIES = "ALLERGIES";
    static readonly SPECIFIC_KIND_OF_DIET = "SPECIFIC_KIND_OF_DIET";
    static readonly RESTRICTED_FOOD = "RESTRICTED_FOOD";
    static readonly RESTRICTED_FOOD_COMMENT = "RESTRICTED_FOOD_COMMENT";
    static readonly OILY_FISH = "OILY_FISH";
    static readonly RED_MEAT = "RED_MEAT";
    static readonly PROCESSED_MEAT = "PROCESSED_MEAT";
    static readonly SERVINGS_VEGETABLES = "SERVINGS_VEGETABLES";
    static readonly SERVINGS_CARBOHYDRATES = "SERVINGS_CARBOHYDRATES";
    static readonly SERVINGS_LEGUMES = "SERVINGS_LEGUMES";
    static readonly PRACTICE_FEEDING = "PRACTICE_FEEDING";
    static readonly PRACTICE_FEEDING_COMMENT = "PRACTICE_FEEDING_COMMENT";
    static readonly PRIMARY_EXERCISE_GOAL = "PRIMARY_EXERCISE_GOAL";
    static readonly PRIMARY_EXERCISE_GOAL_COMMENT = "PRIMARY_EXERCISE_GOAL_COMMENT";
    static readonly AVERAGE_LIGHT_EXERCISE_TIME = "AVERAGE_LIGHT_EXERCISE_TIME";
    static readonly AVERAGE_MODERATE_EXERCISE_TIME = "AVERAGE_MODERATE_EXERCISE_TIME";
    static readonly AVERAGE_INTENSE_EXERCISE_TIME = "AVERAGE_INTENSE_EXERCISE_TIME";
    static readonly PUSH_UPS_PERFORMING = "PUSH_UPS_PERFORMING";
    static readonly RUN_RUN = "RUN_RUN";
    static readonly REP_BENCH_PRESS = "REP_BENCH_PRESS";
    static readonly DEADLIFT = "DEADLIFT";
    static readonly REP_SQUAT = "REP_SQUAT";
    static readonly AVERAGE_HOURS_OF_SLEEP = "AVERAGE_HOURS_OF_SLEEP";
    static readonly AVERAGE_HOURS_OF_SLEEP_COMMENT = "AVERAGE_HOURS_OF_SLEEP_COMMENT";
    static readonly SLEEP_ROUTINE = "SLEEP_ROUTINE";
    static readonly SLEEP_ROUTINE_COMMENT = "SLEEP_ROUTINE_COMMENT";
    static readonly FALL_ASLEEP_NIGHT = "FALL_ASLEEP_NIGHT";
    static readonly WAKE_UP = "WAKE_UP";
    static readonly RESTED_READY_WAKEUP = "RESTED_READY_WAKEUP";
    static readonly ENERGY_THROUGHOUT_THE_DAY = "ENERGY_THROUGHOUT_THE_DAY";
    static readonly TYPICAL_STRESS = "TYPICAL_STRESS";
    static readonly STRESS_OR_PRACTICE_MINDFULNESS = "STRESS_OR_PRACTICE_MINDFULNESS";
    static readonly STRESS_OR_PRACTICE_MINDFULNESS_COMMENT = "STRESS_OR_PRACTICE_MINDFULNESS_COMMENT";
    static readonly PERFORM_YOUR_ROUTINES = "PERFORM_YOUR_ROUTINES";
    static readonly PERFORM_YOUR_ROUTINES_COMMENT = "PERFORM_YOUR_ROUTINES_COMMENT";
    static readonly TV_HOURS = "TV_HOURS";
    static readonly non_TV_SCREEN = "non_TV_SCREEN";
    static readonly PREFERRED_PHARMACY_NAME = "PREFERRED_PHARMACY_NAME";
    static readonly PREFERRED_PHARMACY_PHONE = "PREFERRED_PHARMACY_PHONE";
    static readonly PREFERRED_PHARMACY_ADDRESS = "PREFERRED_PHARMACY_ADDRESS";
    static readonly PREFERRED_PHARMACY_CITY = "PREFERRED_PHARMACY_CITY";
    static readonly PREFERRED_PHARMACY_ZIPCODE = "PREFERRED_PHARMACY_ZIPCODE";
    static readonly PREFERRED_PHARMACY_STATE = "PREFERRED_PHARMACY_STATE";
    static readonly PREFERRED_PHARMACY_COUNTRY = "PREFERRED_PHARMACY_COUNTRY";
}

export class MedicalHistoryAndLifestyleSectionNames {
    static readonly DEMOGRAPHICS_AND_BIOMETRICS_ETHNICITY = "DEMOGRAPHICS_AND_BIOMETRICS_ETHNICITY"
    static readonly DEMOGRAPHICS_AND_BIOMETRICS_BODY_PARAMETERS = "DEMOGRAPHICS_AND_BIOMETRICS_BODY_PARAMETERS"
    static readonly DEMOGRAPHICS_AND_BIOMETRICS_BLOOD_PRESSURE = "DEMOGRAPHICS_AND_BIOMETRICS_BLOOD_PRESSURE"
    static readonly CURRENT_MEDICAL_CONDITIONS = "CURRENT_MEDICAL_CONDITIONS";
    static readonly FAMILY_MEDICAL_CONDITIONS = "FAMILY_MEDICAL_CONDITIONS";
    static readonly DIABETES = "DIABETES";
    static readonly CHOLESTEROL_MEDICATIONS = "CHOLESTEROL_MEDICATIONS";
    static readonly BLOOD_PRESSURE_MEDICATIONS = "BLOOD_PRESSURE_MEDICATIONS";
    static readonly CORONARIUM_CALCIUM_SCORE = "CORONARIUM_CALCIUM_SCORE";
    static readonly SMOKING = "SMOKING";
    static readonly ALCOHOL = "ALCOHOL";
    static readonly MEDICATIONS = "MEDICATIONS";
    static readonly SUPPLEMENTS = "SUPPLEMENTS";
    static readonly ALLERGIES = "ALLERGIES";
    static readonly DIET = "DIET";
    static readonly FOOD_LIMIT = "FOOD_LIMIT";
    static readonly FOOD_FREQUENCY = "FOOD_FREQUENCY";
    static readonly FASTING = "FASTING";
    static readonly EXERCISE_GOAL = "EXERCISE_GOAL";
    static readonly EXERCISE_FREQUENCY = "EXERCISE_FREQUENCY";
    static readonly EXERCISE_PERFORMANCE = "EXERCISE_PERFORMANCE";
    static readonly SLEEP_LENGTH = "SLEEP_LENGTH";
    static readonly SLEEP_PRECONDITIONS = "SLEEP_PRECONDITIONS";
    static readonly SLEEP_TIME = "SLEEP_TIME";
    static readonly SLEEP_REST_SCORE = "SLEEP_REST_SCORE";
    static readonly DAY_ENERGY_SCORE = "DAY_ENERGY_SCORE";
    static readonly STRESS_LEVEL_SCORE = "STRESS_LEVEL_SCORE";
    static readonly STRESS_HELPERS = "STRESS_HELPERS";
    static readonly MINDFULNESS_FREQUENCY = "MINDFULNESS_FREQUENCY";
    static readonly TV = "TV";
    static readonly PREFERRED_PHARMACY = "PREFERRED_PHARMACY";
}

export const MedicalHistoryAndLifestyleSections = {
    [MedicalHistoryAndLifestyleSectionNames.DEMOGRAPHICS_AND_BIOMETRICS_BLOOD_PRESSURE]: [
        MedicalHistoryAndLifestyleQuestionNames.DEMOGRAPHICS_AND_BIOMETRICS_SBP_TOP,
        MedicalHistoryAndLifestyleQuestionNames.DEMOGRAPHICS_AND_BIOMETRICS_SBP_BOTTOM],
    [MedicalHistoryAndLifestyleSectionNames.CURRENT_MEDICAL_CONDITIONS]: [
        MedicalHistoryAndLifestyleQuestionNames.MEDICAL_HISTORY_CONDITIONS,
        MedicalHistoryAndLifestyleQuestionNames.MEDICAL_HISTORY_CONDITIONS_COMMENT],
    [MedicalHistoryAndLifestyleSectionNames.FAMILY_MEDICAL_CONDITIONS]: [
        MedicalHistoryAndLifestyleQuestionNames.MEDICAL_HISTORY_CONDITIONS_FAMILY,
        MedicalHistoryAndLifestyleQuestionNames.MEDICAL_HISTORY_CONDITIONS_FAMILY_COMMENT],
    [MedicalHistoryAndLifestyleSectionNames.DIABETES]: [
        MedicalHistoryAndLifestyleQuestionNames.MEDICAL_HISTORY_DIABET,
        MedicalHistoryAndLifestyleQuestionNames.MEDICAL_HISTORY_DIABET_TYPE],
    [MedicalHistoryAndLifestyleSectionNames.CHOLESTEROL_MEDICATIONS]: [
        MedicalHistoryAndLifestyleQuestionNames.MEDICAL_HISTORY_CHOLESTEROL,
        MedicalHistoryAndLifestyleQuestionNames.MEDICAL_HISTORY_BLOOD_PRESSURE,
        MedicalHistoryAndLifestyleQuestionNames.CORONARY_CALCIUM_SCORE,
        MedicalHistoryAndLifestyleQuestionNames.SCORE],
    [MedicalHistoryAndLifestyleSectionNames.SMOKING]: [MedicalHistoryAndLifestyleQuestionNames.SMOKING_HISTORY],
    [MedicalHistoryAndLifestyleSectionNames.ALCOHOL]: [MedicalHistoryAndLifestyleQuestionNames.ALCOHOL_HISTORY],
    [MedicalHistoryAndLifestyleSectionNames.MEDICATIONS]: [
        MedicalHistoryAndLifestyleQuestionNames.ANY_MEDICATIONS,
        MedicalHistoryAndLifestyleQuestionNames.MEDICATIONS
    ],
    [MedicalHistoryAndLifestyleSectionNames.SUPPLEMENTS]: [
        MedicalHistoryAndLifestyleQuestionNames.ANY_SUPPLEMENTS,
        MedicalHistoryAndLifestyleQuestionNames.SUPPLEMENTS],
    [MedicalHistoryAndLifestyleSectionNames.ALLERGIES]: [
        MedicalHistoryAndLifestyleQuestionNames.DRUG_ALLERGIES,
        MedicalHistoryAndLifestyleQuestionNames.ALLERGIES,],
    [MedicalHistoryAndLifestyleSectionNames.DIET]: [MedicalHistoryAndLifestyleQuestionNames.SPECIFIC_KIND_OF_DIET],
    [MedicalHistoryAndLifestyleSectionNames.FOOD_LIMIT]: [
        MedicalHistoryAndLifestyleQuestionNames.RESTRICTED_FOOD,
        MedicalHistoryAndLifestyleQuestionNames.RESTRICTED_FOOD_COMMENT],
    [MedicalHistoryAndLifestyleSectionNames.FOOD_FREQUENCY]: [
        MedicalHistoryAndLifestyleQuestionNames.OILY_FISH,
        MedicalHistoryAndLifestyleQuestionNames.RED_MEAT,
        MedicalHistoryAndLifestyleQuestionNames.PROCESSED_MEAT,
        MedicalHistoryAndLifestyleQuestionNames.SERVINGS_VEGETABLES,
        MedicalHistoryAndLifestyleQuestionNames.SERVINGS_CARBOHYDRATES,
        MedicalHistoryAndLifestyleQuestionNames.SERVINGS_LEGUMES],
    [MedicalHistoryAndLifestyleSectionNames.FASTING]: [
        MedicalHistoryAndLifestyleQuestionNames.PRACTICE_FEEDING,
        MedicalHistoryAndLifestyleQuestionNames.PRACTICE_FEEDING_COMMENT],
    [MedicalHistoryAndLifestyleSectionNames.EXERCISE_GOAL]: [
        MedicalHistoryAndLifestyleQuestionNames.PRIMARY_EXERCISE_GOAL,
        MedicalHistoryAndLifestyleQuestionNames.PRIMARY_EXERCISE_GOAL_COMMENT],
    [MedicalHistoryAndLifestyleSectionNames.EXERCISE_FREQUENCY]: [
        MedicalHistoryAndLifestyleQuestionNames.AVERAGE_LIGHT_EXERCISE_TIME,
        MedicalHistoryAndLifestyleQuestionNames.AVERAGE_MODERATE_EXERCISE_TIME,
        MedicalHistoryAndLifestyleQuestionNames.AVERAGE_INTENSE_EXERCISE_TIME],
    [MedicalHistoryAndLifestyleSectionNames.SLEEP_LENGTH]: [
        MedicalHistoryAndLifestyleQuestionNames.AVERAGE_HOURS_OF_SLEEP,
        MedicalHistoryAndLifestyleQuestionNames.AVERAGE_HOURS_OF_SLEEP_COMMENT],
    [MedicalHistoryAndLifestyleSectionNames.SLEEP_PRECONDITIONS]: [
        MedicalHistoryAndLifestyleQuestionNames.SLEEP_ROUTINE,
        MedicalHistoryAndLifestyleQuestionNames.SLEEP_ROUTINE_COMMENT],
    [MedicalHistoryAndLifestyleSectionNames.SLEEP_TIME]: [
        MedicalHistoryAndLifestyleQuestionNames.FALL_ASLEEP_NIGHT,
        MedicalHistoryAndLifestyleQuestionNames.WAKE_UP],
    [MedicalHistoryAndLifestyleSectionNames.SLEEP_REST_SCORE]: [
        MedicalHistoryAndLifestyleQuestionNames.RESTED_READY_WAKEUP,
        MedicalHistoryAndLifestyleQuestionNames.ENERGY_THROUGHOUT_THE_DAY],
    [MedicalHistoryAndLifestyleSectionNames.STRESS_LEVEL_SCORE]: [MedicalHistoryAndLifestyleQuestionNames.TYPICAL_STRESS],
    [MedicalHistoryAndLifestyleSectionNames.STRESS_HELPERS]: [
        MedicalHistoryAndLifestyleQuestionNames.STRESS_OR_PRACTICE_MINDFULNESS,
        MedicalHistoryAndLifestyleQuestionNames.STRESS_OR_PRACTICE_MINDFULNESS_COMMENT,
        MedicalHistoryAndLifestyleQuestionNames.PERFORM_YOUR_ROUTINES,
        MedicalHistoryAndLifestyleQuestionNames.PERFORM_YOUR_ROUTINES_COMMENT],
    [MedicalHistoryAndLifestyleSectionNames.TV]: [
        MedicalHistoryAndLifestyleQuestionNames.TV_HOURS,
        MedicalHistoryAndLifestyleQuestionNames.non_TV_SCREEN]
} as {[id: string]: string[]}