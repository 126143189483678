import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Checkbox,
  IconButton,
  CircularProgress,
  Dialog,
  DialogContent
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import TrashIcon from '@material-ui/icons/Delete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import EditIcon from '@material-ui/icons/Edit'
import { useFacade } from './manageCommonSupplementsComponent.hooks';
import { useStyles } from "./manageCommonSupplementsComponent.styles";
import { WildHealthContainer } from '../../../common/components/wildHealthContainer/WildHealthContainer';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { FeatureComponent } from "../../../common/components/featureFlags/FeatureComponent";
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';

export const ManageCommonSupplementsComponent: React.FC = () => {
  const [
    {
      isLoading,
      isSubmitting,
      isOpen,
      commonSupplements,
      createCommonSupplement,
      removingId,
      errors,
      sortingColumns,
      sortingSource,
    },
    handleChanges,
    handleSorting,
    handleSave,
    handleDelete,
    handleToggle,
  ] = useFacade();

  const classes = useStyles();

  const controlIcon = <CheckBoxOutlineBlankIcon className="wh-tw-text-mediumGray" />;
  const controlCheckedIcon = <CheckBoxIcon className="wh-tw-text-primaryV" />

  const renderTable = () => {
    if (isLoading) {
      return <WildHealthLinearProgress />
    }

    if (!isLoading && (!commonSupplements || !commonSupplements.length)) {
      return <WildHealthPlaceHolder message="No available common supplements found" />
    }

    return (
      <>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {
                    sortingColumns.map((item, index) =>
                        <WildHealthTableCell key={index}>
                            {item.source
                                ? <TableSortLabel
                                    id={`sorting-supplements-${item.title}`}
                                    active={sortingSource === item.source}
                                    direction={sortingSource === item.source ? item.direction : 'asc'}
                                    onClick={() => handleSorting(item.source, item.direction)}
                                >
                                    {item.title}
                                </TableSortLabel>
                                : item.title
                            }
                        </WildHealthTableCell>
                    )
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {commonSupplements.map(supplement => (
                <WildHealthTableRow key={supplement.id}>
                  <WildHealthTableCell align="left">
                      <Box className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-black">
                          {supplement.name}
                      </Box>
                  </WildHealthTableCell>
                  <WildHealthTableCell align="left">
                      <Box className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-black">
                          {supplement.dosage}
                      </Box>
                  </WildHealthTableCell>
                  <WildHealthTableCell align="left">
                      <Box className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-black">
                          {supplement.instructions}
                      </Box>
                  </WildHealthTableCell>
                  <WildHealthTableCell align="left" style={{ width: '30%' }}>
                      <Box className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-black">
                          {supplement.purchaseLink}
                      </Box>
                  </WildHealthTableCell>
                  <WildHealthTableCell style={{ width: '15%' }}>
                    <IconButton
                      id="edit-supplement-btn"
                      aria-describedby={supplement.name}
                      disabled={isSubmitting}
                      onClick={() => handleToggle(supplement.id)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      id="remove-supplement-btn"
                      aria-describedby={supplement.name}
                      disabled={isSubmitting}
                      onClick={() => handleDelete(supplement.id)}
                    >
                      {isSubmitting && removingId === supplement.id ? <CircularProgress size={24} className="wh-tw-text-primaryV" /> : <TrashIcon />}
                    </IconButton>
                  </WildHealthTableCell>
                </WildHealthTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }

  return (
    <Box py={2} px={10} width={1}>
      <Box my={2} display="flex" alignItems="center" justifyContent="space-between">
          <Box>
              <FeatureComponent featureFlag={FeatureFlag.Navigation}>
                  <Box className="wh-tw-font-semibold wh-tw-text-2xl wh-tw-black">{PagesNamesService.commonSupplements}</Box>
              </FeatureComponent>
          </Box>
          <Box>
              <WildHealthButton
                  id="create-common-supplement-button"
                  disabled={isLoading}
                  loading={isSubmitting}
                  onClick={() => handleToggle(0)}
                  fullWidth
                  style={{ height: 48 }}
              >
                  <Box className="wh-tw-text-base wh-tw-font-medium">New Common Supplement</Box>
              </WildHealthButton>
          </Box>
      </Box>

      <WildHealthContainer margin={0} minHeight={'100%'}>
        {renderTable()}
      </WildHealthContainer>
      <Dialog
          onClose={() => handleToggle(-1)}
          className={classes.root}
          open={isOpen}
      >
          <DialogContent className={classes.dialogContent}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Box>
                      <span className="wh-tw-text-2xl wh-tw-black wh-tw-font-medium">
                        {createCommonSupplement.id > 0 ? 'Update Common Supplement' : 'New Common Supplement'}
                      </span>
                  </Box>
                  <Box display="flex" alignItems="center">
                      <IconButton
                          id="new-common-supplement-dialog-close"
                          className={classes.dialogTitleCloseAction}
                          onClick={() => handleToggle(-1)}
                      >
                          <CloseIcon />
                      </IconButton>
                  </Box>
              </Box>
              <Box mt={5}>
                  <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Name*</Box>
                  <TextField
                      id={`name`}
                      fullWidth
                      required
                      size="small"
                      InputProps={{ className: 'input', classes }}
                      variant="outlined"
                      value={createCommonSupplement.name}
                      error={!!errors['name']}
                      helperText={errors['name']}
                      disabled={!isOpen || isSubmitting}
                      onChange={(event) => handleChanges(event.target.id, event.target.value)}
                  />
              </Box>
              <Box mt={2}>
                  <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Dosage</Box>
                  <TextField
                      id={`dosage`}
                      fullWidth
                      required
                      size="small"
                      InputProps={{ className: 'input', classes }}
                      variant="outlined"
                      value={createCommonSupplement.dosage}
                      error={!!errors['dosage']}
                      helperText={errors['dosage']}
                      disabled={!isOpen || isSubmitting}
                      onChange={(event) => handleChanges(event.target.id, event.target.value)}
                  />
              </Box>
              <Box mt={2}>
                  <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Instructions*</Box>
                  <TextField
                      id={`instructions`}
                      fullWidth
                      required
                      size="small"
                      InputProps={{ className: 'input', classes }}
                      variant="outlined"
                      value={createCommonSupplement.instructions}
                      error={!!errors['instructions']}
                      helperText={errors['instructions']}
                      disabled={!isOpen || isSubmitting}
                      onChange={(event) => handleChanges(event.target.id, event.target.value)}
                  />
              </Box>
              <Box mt={2}>
                  <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>PurchaseLink*</Box>
                  <TextField
                      id={`purchaseLink`}
                      fullWidth
                      required
                      size="small"
                      InputProps={{ className: 'input', classes }}
                      variant="outlined"
                      value={createCommonSupplement.purchaseLink}
                      error={!!errors['purchaseLink']}
                      helperText={errors['purchaseLink']}
                      disabled={!isOpen || isSubmitting}
                      onChange={(event) => handleChanges(event.target.id, event.target.value)}
                  />
              </Box>
              <Box mt={2}>
                  <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>IsInCurrent*</Box>
                  <Box className="wh-tw-text-xs wh-tw-text-gray1" mb={1}>When Yes is selected, this will populate in the Current List column. When No is selected, this will populate in the Recommended List column.</Box>
                  <Box display="flex" alignItems="center">
                      <Checkbox
                          icon={controlIcon}
                          color="primary"
                          checkedIcon={controlCheckedIcon}
                          checked={createCommonSupplement.isInCurrent}
                          onChange={() => handleChanges("isInCurrent", true)}
                      />
                      <Box mr={2}>Yes</Box>
                      <Checkbox
                          icon={controlIcon}
                          color="primary"
                          checkedIcon={controlCheckedIcon}
                          checked={!createCommonSupplement.isInCurrent}
                          onChange={() => handleChanges("isInCurrent", false)}
                      />
                      <Box>No</Box>
                  </Box>
              </Box>
              <Box mt={5} display="flex" justifyContent="flex-end" alignItems="center">
                  <Box mx={2}>
                      <WildHealthButton
                          id="new-supplement-cancel"
                          onClick={() => handleToggle(-1)}
                          color="tertiary"
                          size='medium'
                          disabled={!isOpen || isSubmitting}
                      >
                          Cancel
                      </WildHealthButton>
                  </Box>
                  <Box>
                      <WildHealthButton
                          id="save-supplement-button"
                          size='medium'
                          onClick={handleSave}
                          loading={isSubmitting}
                      >
                          {createCommonSupplement.id > 0 ? 'Update' : 'Save'}
                      </WildHealthButton>
                  </Box>
              </Box>
          </DialogContent>
      </Dialog>
    </Box>
  );
}