import { Box, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { LabInputLabels, LabInputNames } from "../../inputs/constants/inputs.constants";
import { LabGroup } from '../../inputs/models/input.models';
import { LabResultComponent } from "../components/labResultComponent/LabResultComponent";
import { HealthReportSections, HealthReportMode } from "../models/healthReport.models";
import { HealthReportBasePage, HealthReportMobileBasePage } from "./HealthReportBasePage";
import { useStyles } from './healthReport.styles';
import { HealthReportPageProps } from "./healthReportPageProps";
import { renderLabInputRange } from "../../inputs/helpers/render-lab-input-range";

export const HealthReportDetails5Page: React.FC<HealthReportPageProps> = (props: HealthReportPageProps) => {
    const {
        pageNumber,
        report,
        practice
    } = props;
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const labValues = report.labReport.values.filter((x) => !!LabInputLabels[x.name] && x.value);
    const lipidsValues = labValues.filter(x => x.group === LabGroup.Lipids);
    const hormonesValues = labValues.filter(x => x.group === LabGroup.Hormones);
    const methylationValues = labValues.filter(x => x.group === LabGroup.Methylation);
    const inflammationValues = labValues.filter(x => x.group === LabGroup.Inflammation);
    const vitaminsAndMicronutrientsValues = labValues.filter(x => x.group === LabGroup.VitaminsAndMicronutrients);
    const insulinResistanceAndMetabolismValues = labValues.filter(x => x.group === LabGroup.InsulinResistanceAndMetabolism);

    return (
        props.mode !== HealthReportMode.PrintMode && isSmallScreen ? (
            <HealthReportMobileBasePage section={HealthReportSections.Labs} pageNumber={pageNumber} practice={practice} firstPage>
                <Box>
                    <Box px={2}>
                        <Box>
                            <span className={classes.reportTitle}>Laboratory results</span>
                        </Box>
                        <Box>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Box mt="20px">
                                        <Box>
                                            <span className={classes.reportSubTitle}>Lipids</span>
                                        </Box>
                                        <Box mt={1}>
                                            {
                                                lipidsValues.map((value, index) => {
                                                    return <LabResultComponent
                                                        key={index}
                                                        label={`${LabInputLabels[value.name]} ${renderLabInputRange(value.range)}`}
                                                        value={value}
                                                    />
                                                })
                                            }
                                        </Box>
                                    </Box>
                                    <Box mt="20px">
                                        <Box>
                                            <span className={classes.reportSubTitle}>Methylation</span>
                                        </Box>
                                        <Box mt={1}>
                                            {
                                                methylationValues.map((value, index) => {
                                                    return <LabResultComponent
                                                        key={index}
                                                        label={`${LabInputLabels[value.name]} ${renderLabInputRange(value.range)}`}
                                                        value={value}
                                                    />
                                                })
                                            }
                                        </Box>
                                    </Box>
                                    <Box mt="20px">
                                        <Box>
                                            <span className={classes.reportSubTitle}>Vitamins and Micronutrients</span>
                                        </Box>
                                        <Box mt={1}>
                                            {
                                                vitaminsAndMicronutrientsValues.map((value, index) => {
                                                    return <LabResultComponent
                                                        key={index}
                                                        label={`${LabInputLabels[value.name]} ${renderLabInputRange(value.range)}`}
                                                        value={value}
                                                    />
                                                })
                                            }
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box mt="20px">
                                        <Box>
                                            <span className={classes.reportSubTitle}>Hormones</span>
                                        </Box>
                                        <Box mt={1}>
                                            {
                                                hormonesValues.map((value, index) => {
                                                    return <LabResultComponent
                                                        key={index}
                                                        label={`${LabInputLabels[value.name]} ${renderLabInputRange(value.range)}`}
                                                        value={value}
                                                    />
                                                })
                                            }
                                        </Box>
                                    </Box>
                                    <Box mt="20px">
                                        <Box>
                                            <span className={classes.reportSubTitle}>Inflammation</span>
                                        </Box>
                                        <Box mt={1}>
                                            {
                                                inflammationValues.map((value, index) => {
                                                    return <LabResultComponent
                                                        key={index}
                                                        label={`${LabInputLabels[value.name]} ${renderLabInputRange(value.range)}`}
                                                        value={value}
                                                    />
                                                })
                                            }
                                        </Box>
                                    </Box>
                                    <Box mt="20px">
                                        <Box>
                                            <span className={classes.reportSubTitle}>Insulin resistance / Metabolism</span>
                                        </Box>
                                        <Box mt={1}>
                                            {
                                                insulinResistanceAndMetabolismValues.map((value, index) => {
                                                    return <LabResultComponent
                                                        key={index}
                                                        label={`${LabInputLabels[value.name]} ${renderLabInputRange(value.range)}`}
                                                        value={value}
                                                    />
                                                })
                                            }
                                            <LabResultComponent
                                                label="HOMA-IR"
                                                value={{
                                                    name: LabInputNames.Homir,
                                                    group: LabGroup.Lipids,
                                                    date: new Date(),
                                                    value: report.alphaReport.homir,
                                                    notification: null,
                                                    range: null
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </HealthReportMobileBasePage>
        ) : (
            <HealthReportBasePage section={HealthReportSections.Details} pageNumber={pageNumber} practice={practice}>
                <Box>
                    <Box px={15} pt={5}>
                        <Box>
                            <span className="report-title">Laboratory results</span>
                        </Box>
                        <Box mt={5}>
                            <Grid container
                                spacing={2}
                                justify="space-between">
                                <Grid item xs={5}>
                                    <Box pr={5}>
                                        <Box mb={3}>
                                            <span className="report-sub-title">Lipids</span>
                                        </Box>
                                        <Box mb={5}>
                                            {
                                                lipidsValues.map((value, index) => {
                                                    return <LabResultComponent
                                                        key={index}
                                                        label={`${LabInputLabels[value.name]} ${renderLabInputRange(value.range)}`}
                                                        value={value}
                                                    />
                                                })
                                            }
                                        </Box>
                                    </Box>
                                    <Box mt={6} pr={5}>
                                        <Box mb={3}>
                                            <span className="report-sub-title">Methylation</span>
                                        </Box>
                                        <Box mb={5}>
                                            {
                                                methylationValues.map((value, index) => {
                                                    return <LabResultComponent
                                                        key={index}
                                                        label={`${LabInputLabels[value.name]} ${renderLabInputRange(value.range)}`}
                                                        value={value}
                                                    />
                                                })
                                            }
                                        </Box>
                                    </Box>
                                    <Box mt={6} pr={5}>
                                        <Box mb={3}>
                                            <span className="report-sub-title">Vitamins and Micronutrients</span>
                                        </Box>
                                        <Box mb={5}>
                                            {
                                                vitaminsAndMicronutrientsValues.map((value, index) => {
                                                    return <LabResultComponent
                                                        key={index}
                                                        label={`${LabInputLabels[value.name]} ${renderLabInputRange(value.range)}`}
                                                        value={value}
                                                    />
                                                })
                                            }
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={7}>
                                    <Box pl={5}>
                                        <Box mb={3}>
                                            <span className="report-sub-title">Hormones</span>
                                        </Box>
                                        <Box mb={5}>
                                            {
                                                hormonesValues.map((value, index) => {
                                                    return <LabResultComponent
                                                        key={index}
                                                        label={`${LabInputLabels[value.name]} ${renderLabInputRange(value.range)}`}
                                                        value={value}
                                                    />
                                                })
                                            }
                                        </Box>
                                    </Box>
                                    <Box mt={6} pl={5}>
                                        <Box mb={3}>
                                            <span className="report-sub-title">Inflammation</span>
                                        </Box>
                                        <Box mb={5}>
                                            {
                                                inflammationValues.map((value, index) => {
                                                    return <LabResultComponent
                                                        key={index}
                                                        label={`${LabInputLabels[value.name]} ${renderLabInputRange(value.range)}`}
                                                        value={value}
                                                    />
                                                })
                                            }
                                        </Box>
                                    </Box>
                                    <Box mt={6} pl={5}>
                                        <Box mb={3}>
                                            <span className="report-sub-title">Insulin resistance / Metabolism</span>
                                        </Box>
                                        <Box>
                                            {
                                                insulinResistanceAndMetabolismValues.map((value, index) => {
                                                    return <LabResultComponent
                                                        key={index}
                                                        label={`${LabInputLabels[value.name]} ${renderLabInputRange(value.range)}`}
                                                        value={value}
                                                    />
                                                })
                                            }
                                            <LabResultComponent
                                                label="HOMA-IR"
                                                value={{
                                                    name: LabInputNames.Homir,
                                                    group: LabGroup.Lipids,
                                                    date: new Date(),
                                                    value: report.alphaReport.homir,
                                                    notification: null,
                                                    range: null
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </HealthReportBasePage>
        )
    )
}

