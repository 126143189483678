import { GeneralValidator } from "../../../common/validation/general-validator";
import {
  notWhitespaceValidationRule,
  requiredValidationRule,
} from "../../../common/validation/general-validation-rules";

export const modifyReportTemplateValidator = new GeneralValidator({
  'version': [notWhitespaceValidationRule(), requiredValidationRule()],
  'comment': [notWhitespaceValidationRule(), requiredValidationRule()],
  'reportType': [requiredValidationRule()],
  'templateString': [requiredValidationRule(), requiredValidationRule()],
});