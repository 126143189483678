export class RoutesConstants {
    public static default = '/';
    public static getStarted = '/getstarted';
    public static employerGetStarted = '/getstarted/employer';
    public static preauthorizeSignUp = '/preauthorize';
    public static consultationGetStarted = '/getstarted/consultation';
    public static mobileGetStarted = '/m/getstarted';
    public static mobileEmployerGetStarted = '/m/getstarted/employer';
    public static fellowshipGetStarted = '/fellowship-get-started';
    public static pmFellowshipGetStarted = '/pmprogram';
    public static login = '/login';
    public static loginOnBehalf = '/login-on-behalf';
    public static loginRxNT = '/login-RxNT';
    public static dashboard = '/dashboard';
    public static alertsHistory = '/alerts-history';
    public static verifyProfile = '/verify-profile';
    public static confirmAgreements = '/confirm-agreements';
    public static healthScore = '/health-score';
    public static habitScore = '/habit-score';
    public static myPatients = '/my-patients';
    public static managePatients = '/manage-patients';
    public static intakePatients = '/intake-patients';
    public static shortcuts = '/shortcuts';
    public static generalInputs = '/general-inputs';
    public static documents = '/documents';
    public static labsInputs = '/labs-inputs';
    public static microbiomeInputs = '/microbiome-inputs';
    public static dataFilesInputs = '/data-files-inputs';
    public static patientUpcommingAppointments = '/patient-upcoming-appointments';
    public static patientPastAppointments = '/patient-past-appointments';
    public static patientNotes = '/patient-notes';
    public static patientNote = '/notes';
    public static patientOrders = '/patient-orders';
    public static otherOrder = '/other-order';
    public static referralOrder = '/referral-order';
    public static patientInitialConsult = '/patient-initial-consult';
    public static patientFollowUp = '/patient-follow-up';
    public static patientBlank = '/patient-blank';
    public static patientHistoryAndPhysical = '/patient-history-and-physical-note';
    public static patientHistoryAndPhysicalFollowup = '/patient-history-and-physical-followup-note';
    public static patientHistoryAndPhysicalGroupVisit = '/patient-history-and-physical-group-visit-note';
    public static patientSoap = '/patient-soap';
    public static employeeNotes = '/employee-notes';
    public static signOffNote = '/notes/sign-off';
    public static buyNewSubscription = '/buy-new-subscription';
    public static buySubscriptionSuccess = '/buy-subscription-success';
    public static mobileBuySubscriptionSuccess = '/m/buy-subscription-success';
    public static paymentSuccess = '/payment-success';
    public static mobilePaymentSuccess = '/m/payment-success';
    public static joinPractice = '/join';
    public static joinPracticeSuccess = '/join-practice-success';
    public static healthQuestionnaireCompleted = '/health-questionnaire-completed';
    public static healthQuestionnaire = '/health-questionnaire';
    public static startQuestionnaire = '/start-questionnaire';
    public static questionnaire = '/questionnaire';
    public static questionnaires = '/questionnaires';
    public static healthForms = '/health-forms';
    public static healthSummary = '/health-summary';
    public static healthReport = '/health-report';
    public static addOnReports = '/add-on-reports';
    public static healthReportTemplates = '/health-report-templates';
    public static healthPlan = '/health-plan';
    public static healthQuestionnaireResult = '/health-questionnaire-result';
    public static forgotPassword = '/forgot-password';
    public static auth2Code = '/auth2/:provider/code';
    public static auth2Login = '/external-login/:provider';
    public static thirdPartyIntegrationCode = '/integration/auth2/:provider/code';
    public static thirdPartyIntegrationLogin = '/integration/login/:provider';
    public static mobileForgotPassword = '/m/forgot-password';
    public static submitPatient = '/submit-patient';
    public static managePatientProfile = '/manage-patient-profile';
    public static supplements = '/supplements';
    public static coachAppointments = '/coach-appointments';
    public static manageEmployees = '/manage-employees';
    public static restorePassword = '/restore-password';
    public static setUpPassword = '/set-up-password';
    public static resetPassword = '/reset-password';
    public static accountProfile = '/account/profile';
    public static accountMembership = '/account/membership';
    public static accountMembershipActivation = '/account/membership/activation';
    public static createInitialConsultNote = '/create-initial-consult-note';
    public static createFollowUpNote = '/create-followup-note';
    public static createSoapNote = '/create-soap-note';
    public static createHistoryAndPhysicalNote = '/create-history-and-physical-note';
    public static createHistoryAndPhysicalFollowUpNote = '/create-history-and-physical-followup-note';
    public static createHistoryAndPhysicalGroupVisitNote = '/create-history-and-physical-group-visit-note';
    public static createSimpleNote = '/create-internal-note';
    public static dnaOrderDropship = '/dna-order-dropship';
    public static dnafilesMonitor = '/dnafiles-monitor';
    public static unmatchedLabs = '/unmatched-labs';
    public static privacyPolicy = '/privacy-policy';
    public static termsOfService = '/terms-of-service';
    public static leadSources = '/lead-sources';
    public static fellowshipSubmissionReceived = '/fellowship-submission-received';
    public static vitalsHistory = '/vitals-history';
    public static fellowship = '/fellowship';
    public static fellowPatients = '/fellow-patients';
    public static conversations = '/messaging';
    public static employeeConversations = '/employee-messaging';
    public static patientsSubmissions = '/patients-submissions';
    public static chat = '/chat';
    public static messageSettings = '/message-settings';
    public static genericPlayground = '/playground';
    public static referralLanding = '/refer-a-friend';
    public static draftNotes = '/draft-notes';
    public static signOffNotes = '/signOff-notes';
    public static editDraftNote = 'draft-node-edit'
    public static awaitingApproval = '/awaiting-approval';
    public static accountInsurance = '/account/insurance';
    public static patientCourseHistory = '/academy';
    public static engineeringAdminTool = '/engineering';
    public static employerAdmin = '/manage-employers';
    public static syncRecords = '/sync-records';
    public static syncRecordType = '/sync-record';
    public static syncRecordsList = '/sync-records-list';
    public static syncRecordsReconcile = '/sync-records-reconcile';
    public static manageInsurances = '/manage-insurances';
    public static insuranceConfigurations = '/insurance-configurations';
    public static managePromoCodes = '/manage-promo-codes';
    public static manageMessaging = '/manage-messaging';
    public static messageForwarding = '/message-forwarding';
    public static manageBanners = '/manage-banners';
    public static manageCommonMdms = '/manage-common-mdms';
    public static manageCommonGoals = '/manage-common-goals';
    public static manageCommonSupplements = '/manage-common-supplements';
    public static manageCommonOrders = '/manage-common-orders';
    public static manageRecommendations = '/manage-recommendations';
    public static patientResponsibility = '/patient-responsibility';
    public static opsAdminTools = '/ops-admin-tools';
    public static patientJourneyTasks = '/patient-journey-tasks';
    public static kbDocuments = '/kb-documents';
    public static manageConversationTemplates = '/manage-conversation-templates';
    public static manageEmployersProfiles = '/manage-employers-profiles';
    public static northPassAcademy = 'https://academy.wildhealth.com';
    public static cancellationForm = 'https://4jnhzg70v42.typeform.com/to/akyd5ecV';
    public static physicianPairingQuizForm = 'https://4jnhzg70v42.typeform.com/to/h364HiVB';
    public static wildHealthDomain = 'https://www.wildhealth.com';
    public static wildHealthPrivacyPolicy = `${RoutesConstants.wildHealthDomain}/privacy-policy`;
    public static wildHealthTermsOfUse = `${RoutesConstants.wildHealthDomain}/terms-of-use`;
    public static wildHealthTermsOfService = `${RoutesConstants.wildHealthDomain}/terms-of-service`;
    public static wildHealthNoticeOfPrivacy = `${RoutesConstants.wildHealthDomain}/notice-of-privacy-practices`;
    public static cdnBaseLink = 'https://wh-cdn-develop.azureedge.net';
    public static twitterReferralLink = `https://twitter.com/intent/tweet?text=${encodeURIComponent(`I’m loving my experience with Wild Health - I got a fully custom health report using my DNA & data and am learning a ton. Check it out with my code WILD18725 for 25% off! https://bit.ly/33bzUEP`)}`;
    public static smsReferralLink = `body=${encodeURIComponent(`Hey! Check out Wild Health and get 25% off a year of their preventative, precision healthcare services with my code WILD18725 - https://bit.ly/3oEl1SY.`)}`;
    public static googleMapSearch = 'https://www.google.com/maps/search/?api=1&query=';
    public static mesaRiskUrl = 'https://www.mesa-nhlbi.org/MESACHDRisk/MesaRiskScore/RiskScore.aspx';
    public static ageBioUrl = 'https://www.thorne.com/products/dp/biological-age';
    public static whBlobCdnBaseLink = 'https://whcdnstoragedevelop.blob.core.windows.net';
    public static stripeApiUrl = 'https://api.stripe.com/v1';
}
