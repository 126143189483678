import { colors } from '../../../common/constants/colors'
import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: '100%',
        marginTop: '10px',
        paddingTop: "30px",
        paddingLeft: "30px",
        paddingRight: "30px",
        backgroundColor: colors.white,
        [theme.breakpoints.down("xs")]: {
            paddingTop: "16px",
            paddingLeft: "16px",
            paddingRight: "16px",
        },
    },
    item: {
        paddingBottom: '30px'
    },
    empty: {
        display: 'flex',
        width: '100%'
    },
    title: {
        fontSize: 20,
        fontWeight: 600
    },
    titleConteiner: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    button: {
        color: colors.main,
        fontSize: 14
    },
    buttonText: {
        marginLeft: '6px',
        textTransform: 'capitalize'
    },
    root: {
        height: '100%',
        [theme.breakpoints.down("xs")]: {
            flex: 1,
        },
    },
    image: {
        maxWidth: 241,
        width: '100%',
    },
    message: {
        fontFamily: "Poppins",
        fontWeight: 600,
        fontSize: 20,
        color: colors.gray1,
        marginBottom: 8,
    },
}));