import { Query } from '@datorama/akita';
import {
    DnaOrdersState,
    dnaOrdersStore,
    DnaOrdersStore
} from "./dnaOrders.store";

export class DnaOrdersQuery extends Query<DnaOrdersState> {
    orders$ = this.select(state => state.orders);

    constructor(protected store: DnaOrdersStore) {
        super(store);
    }
}

export const dnaOrdersQuery = new DnaOrdersQuery(dnaOrdersStore);
