import { Box, Checkbox, Divider } from '@material-ui/core';
import React from 'react';
import { ExpandableComponent } from '../../../common/components/expandableComponent/ExpandableComponent';
import { useFacade } from './recommendationsComponent.hooks';
import { commonUseStyles } from '../../../common/styles/common.styles';
import { RecommendationSelectComponent } from '../recommendationSelectComponent/RecommendationSelectComponent';
import { recommendationNameOld, RecommendationTypes, RecommendationTypesNumbers } from '../../models/healthReport.models';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { colors } from '../../../common/constants/colors';

export interface RecommendationsComponentProps {
    patientId?: number;
    isPrepereRecommendation: boolean;

}

export const RecommendationsComponent: React.FC<RecommendationsComponentProps> = (props: RecommendationsComponentProps) => {
    const {
        patientId,
        isPrepereRecommendation,
    } = props;

    const [
        state,
        handleExpandSection,
        handleSelected,
        handleChanges,
        getRecommendation,
        handleAddRecommendation,
        handleSelectedAll,
        
    ] = useFacade(patientId, isPrepereRecommendation);


    const controlIcon = <CheckBoxOutlineBlankIcon style={{ fill: colors.mediumGray }} />;
    const controlCheckedIcon = <CheckBoxIcon style={{ fill: colors.main }} />
    const commonClasses = commonUseStyles();

    const sectionsContent = {
        [RecommendationTypes.Macronutrient]:
            state.recommendations ? <RecommendationSelectComponent
                currentRecomemendation={getRecommendation(RecommendationTypesNumbers.Macronutrient)}
                type={RecommendationTypesNumbers.Macronutrient}
                handleSelected={handleSelected}
                handleChanges={handleChanges}
                handleAddRecommendation={handleAddRecommendation}
            />
                : <></>,
        [RecommendationTypes.VitaminsAndMicronutrients]:
            state.recommendations ? <RecommendationSelectComponent
                currentRecomemendation={getRecommendation(RecommendationTypesNumbers.VitaminsAndMicronutrients)}
                type={RecommendationTypesNumbers.VitaminsAndMicronutrients}
                handleSelected={handleSelected}
                handleChanges={handleChanges}
                handleAddRecommendation={handleAddRecommendation}
            />
                : <></>,
        [RecommendationTypes.Methylation]:
            state.recommendations ? <RecommendationSelectComponent
                    currentRecomemendation={getRecommendation(RecommendationTypesNumbers.Methylation)}
                    type={RecommendationTypesNumbers.Methylation}
                    handleSelected={handleSelected}
                    handleChanges={handleChanges}
                    handleAddRecommendation={handleAddRecommendation}
                />
                : <></>,
        [RecommendationTypes.KryptoniteFoods]:
            state.recommendations ? <RecommendationSelectComponent
                currentRecomemendation={getRecommendation(RecommendationTypesNumbers.KryptoniteFoods)}
                type={RecommendationTypesNumbers.KryptoniteFoods}
                handleSelected={handleSelected}
                handleChanges={handleChanges}
                handleAddRecommendation={handleAddRecommendation}
            />
                : <></>,
        [RecommendationTypes.SuperFoods]:
            state.recommendations ? <RecommendationSelectComponent
                currentRecomemendation={getRecommendation(RecommendationTypesNumbers.SuperFoods)}
                type={RecommendationTypesNumbers.SuperFoods}
                handleSelected={handleSelected}
                handleChanges={handleChanges}
                handleAddRecommendation={handleAddRecommendation}
            />
                : <></>,
        [RecommendationTypes.ExerciseAndRecovery]:
            state.recommendations ? <RecommendationSelectComponent
                currentRecomemendation={getRecommendation(RecommendationTypesNumbers.ExerciseAndRecovery)}
                type={RecommendationTypesNumbers.ExerciseAndRecovery}
                handleSelected={handleSelected}
                handleChanges={handleChanges}
                handleAddRecommendation={handleAddRecommendation}
            />
                : <></>,
        [RecommendationTypes.Sleep]:
            state.recommendations ? <RecommendationSelectComponent
                currentRecomemendation={getRecommendation(RecommendationTypesNumbers.Sleep)}
                type={RecommendationTypesNumbers.Sleep}
                handleSelected={handleSelected}
                handleChanges={handleChanges}
                handleAddRecommendation={handleAddRecommendation}
            />
                : <></>,
        [RecommendationTypes.Neurobehavioral]:
            state.recommendations ? <RecommendationSelectComponent
                currentRecomemendation={getRecommendation(RecommendationTypesNumbers.Neurobehavioral)}
                type={RecommendationTypesNumbers.Neurobehavioral}
                handleSelected={handleSelected}
                handleChanges={handleChanges}
                handleAddRecommendation={handleAddRecommendation}
            />
                : <></>,
        [RecommendationTypes.Microbiome]:
            state.recommendations ? <RecommendationSelectComponent
                currentRecomemendation={getRecommendation(RecommendationTypesNumbers.Microbiome)}
                type={RecommendationTypesNumbers.Microbiome}
                handleSelected={handleSelected}
                handleChanges={handleChanges}
                handleAddRecommendation={handleAddRecommendation}
            />
                : <></>,
        [RecommendationTypes.Cardiovascular]:
            state.recommendations ? <RecommendationSelectComponent
                currentRecomemendation={getRecommendation(RecommendationTypesNumbers.Cardiovascular)}
                type={RecommendationTypesNumbers.Cardiovascular}
                handleSelected={handleSelected}
                handleChanges={handleChanges}
                handleAddRecommendation={handleAddRecommendation}
            />
                : <></>,
        [RecommendationTypes.Dementia]:
            state.recommendations ? <RecommendationSelectComponent
                currentRecomemendation={getRecommendation(RecommendationTypesNumbers.Dementia)}
                type={RecommendationTypesNumbers.Dementia}
                handleSelected={handleSelected}
                handleChanges={handleChanges}
                handleAddRecommendation={handleAddRecommendation}
            />
                : <></>,
        [RecommendationTypes.InsulinResistance]:
            state.recommendations ? <RecommendationSelectComponent
                currentRecomemendation={getRecommendation(RecommendationTypesNumbers.InsulinResistance)}
                type={RecommendationTypesNumbers.InsulinResistance}
                handleSelected={handleSelected}
                handleChanges={handleChanges}
                handleAddRecommendation={handleAddRecommendation}
            />
                : <></>,
        [RecommendationTypes.Inflammation]:
            state.recommendations ? <RecommendationSelectComponent
                currentRecomemendation={getRecommendation(RecommendationTypesNumbers.Inflammation)}
                type={RecommendationTypesNumbers.Inflammation}
                handleSelected={handleSelected}
                handleChanges={handleChanges}
                handleAddRecommendation={handleAddRecommendation}
            />
                : <></>,
        [RecommendationTypes.Longevity]:
            state.recommendations ? <RecommendationSelectComponent
                currentRecomemendation={getRecommendation(RecommendationTypesNumbers.Longevity)}
                type={RecommendationTypesNumbers.Longevity}
                handleSelected={handleSelected}
                handleChanges={handleChanges}
                handleAddRecommendation={handleAddRecommendation}
            />
                : <></>,
        [RecommendationTypes.Supplements]:
            state.recommendations ? <RecommendationSelectComponent
                currentRecomemendation={getRecommendation(RecommendationTypesNumbers.Supplements)}
                type={RecommendationTypesNumbers.Supplements}
                handleSelected={handleSelected}
                handleChanges={handleChanges}
                handleAddRecommendation={handleAddRecommendation}
            />
                : <></>,
    } as { [id: number]: JSX.Element }

    const renderSections = (): JSX.Element => {
        return (
            <>
                {
                    Object.keys(sectionsContent).map(key => (
                        <Box id={`recommendation-component-${key}`} mt={3}>
                            <ExpandableComponent
                                divider={false}
                                title={recommendationNameOld[key]}
                                content={sectionsContent[key]}
                                expanded={state.sections[key]}
                                refCurrent={null}
                                handleExpand={() => handleExpandSection(+key)}
                            />
                            <Box mt={2}>
                                <Divider className={commonClasses.renderDivider} />
                            </Box>
                        </Box>
                    ))
                }
            </>
        );
    }

    return (
        <Box>
            <Box ml={-1.5} mt={2} display="flex" alignItems="center">
                <Checkbox
                    icon={controlIcon}
                    color="primary"
                    checkedIcon={controlCheckedIcon}
                    checked={state.isSelectedAll}
                    onChange={() => handleSelectedAll()}
                />
                <Box>Select All</Box>
            </Box>
            {
                renderSections()
            }
    
        </Box>
    );
}
