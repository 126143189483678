import { Store, StoreConfig } from '@datorama/akita';
import { LocationModel } from '../models/locations.models';

export interface LocationsState {
    availableLocations: LocationModel[];
}

/**
 * Creates initial location state
 */
export function createInitialState(): LocationsState {
    return {
        availableLocations: []
    };
}

/**
 * Provides location state management
 */
@StoreConfig({name: 'locations', resettable: true })
export class LocationsStore extends Store<LocationsState> {
    constructor() {
        super(createInitialState());
    }

    public add(location: LocationModel): void {
        this.update({availableLocations: [...this.getValue().availableLocations, location]});
    }

    public edit(location: LocationModel): void {
        if (!this.getValue().availableLocations.some(x => x.id === location.id)) {
            return this.add(location);
        }

        this.update({
            availableLocations: this.getValue().availableLocations.map(x => x.id === location.id ? location : x)
        });
    }
}

export const locationsStore = new LocationsStore();
