import { PatientModel } from "../../../patients/models/patient.model";
import { Box, Link } from '@material-ui/core';
import moment from "moment";
import React from "react";
import { toCurrentTimeZone } from "../../../timezones/helpers/timezone";
import { displayPercent } from "../../../common/helpers/display-percent";
import NextIcon from '@material-ui/icons/NavigateNext';
import { colors } from "../../constants/colors";

export interface PaymentPlanComponentProps {
    patient: PatientModel;
    classStyle?: any;
}

export const PaymentPlanDisplayComponent: React.FC<PaymentPlanComponentProps> = (props: PaymentPlanComponentProps) => {
    const {
        patient,
        classStyle = '',
    } = props;

    const promoCodeRender = () => {
        const promoCode = patient.subscription?.paymentPrice?.paymentCoupon || patient.subscription?.paymentCoupon;

        if (promoCode) {
            const detail = promoCode.detail ?? displayPercent(patient.subscription.paymentPrice.discount, patient.subscription.paymentPrice.originalPrice);
            return `${promoCode.code} (${detail})`;
        }
        return 'N/A';
    }

    return (
        <Box>
            <Box display="flex">
                <Box width={0.4} className={classStyle}>
                    <h4>Plan:</h4>
                </Box>
                <Box width={0.6} display="flex" alignItems="center">
                    <Box width={patient?.linkToPaymentVendor ? 0.6 : 1}>
                        <h4>{patient?.subscription?.displayName}</h4>
                    </Box>
                    {patient?.linkToPaymentVendor &&
                        <Box width={0.4}>
                            <Link
                                id='link-to-stripe'
                                color={colors.main}
                                target={"_blank"} href={patient.linkToPaymentVendor}
                            >
                                <Box ml={1} display='flex'>
                                    <Box pr={0.5} color={colors.main} fontWeight="500">Stripe</Box>
                                    <NextIcon style={{ fill: colors.main, paddingTop: '3px' }} fontSize='small' />
                                </Box>

                            </Link>
                        </Box>
                    }
                </Box>
            </Box>
            <Box mt={3} display="flex">
                <Box width={0.4} className={classStyle}>
                    <h4>Promo Code:</h4>
                </Box>
                <Box width={0.6}>
                    <h4>{promoCodeRender()}</h4>
                </Box>
            </Box>
            <Box mt={3} display="flex" >
                <Box width={0.4} className={classStyle}>
                    <h4>Joined:</h4>
                </Box>
                <Box width={0.6}>
                    <h4>{moment(toCurrentTimeZone(patient.createdAt)).format("MMM D, YYYY")}</h4>
                </Box>
            </Box>
        </Box>
    );
};