import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { onEmit } from "../../../common/helpers/on-emit";
import { handleCompare } from "../../../common/sorting/helpers/handle-compare";
import { SortingDirection } from "../../../common/sorting/models/sorting-destination";
import { ISortingState } from "../../../common/sorting/models/sorting-state";
import { ReportTemplateModel, ReportType } from "../../models/reportTemplate.models";
import { healthReportTemplatesService } from "../../services/healthReportTemplates.service";
import { IErrorState } from "../../../common/validation/error-state";
import { healthReportTemplatesQuery } from "../../stores/healthReportTemplatesStore";
import { confirmService } from "../../../../services/confirm.service";
import { modifyReportTemplateValidator } from "./modifyReportTemplate.validator";

export enum SortingSource {
    None,
    Version = "version",
    Comment = "comment",
    CreatedDate = "createdAt",
}

interface HealthReportTemplatesState extends ISortingState, IErrorState {
    isLoading: boolean;
    isSubmitting: boolean;
    isOpen: boolean;
    menuAnchorEl: HTMLButtonElement | null;
    reportTemplates: ReportTemplateModel[];
    createTemplate: ReportTemplateModel;
    selectedId: number;
}

const initialParmas: ReportTemplateModel = {
    id: 0,
    createdAt: new Date(),
    version: '',
    comment: '',
    templateString: '',
    reportType: ReportType.Health
}

export function useFacade(): [
    HealthReportTemplatesState,
    (source: string, direction: SortingDirection) => void,
    (id: number) => void,
    (id: number, menuAnchorEl: HTMLButtonElement) => void,
    () => void,
    () => void,
    () => void,
    (field: string, value: any) => void
] {

    const [state, setState] = useState({
        isLoading: true,
        reportTemplates: [],
        selectedId: -1,
        createTemplate: initialParmas,
        sortingColumns: [
            { title: 'Version', source: SortingSource.Version, direction: SortingDirection.Asc },
            { title: 'Comment', source: SortingSource.Comment, direction: SortingDirection.Asc },
            { title: 'Created Date', source: SortingSource.CreatedDate, direction: SortingDirection.Desc },
        ],
        sortingSource: SortingSource.None,
        errors: {},
    } as HealthReportTemplatesState);

    const setDirection = (source: string, direction: SortingDirection) => {
        const itemIndex = state.sortingColumns.findIndex(item => item.source === source);
        state.sortingColumns[itemIndex].direction = direction;
        setState(state => ({ ...state, columns: state.sortingColumns }));
    }

    const handleSorting = (source: string, direction: SortingDirection) => {
        if (state.sortingSource === source) {
            direction = direction === SortingDirection.Asc
                ? SortingDirection.Desc
                : SortingDirection.Asc;

            setDirection(source, direction);
        }
        setState(state => ({
            ...state,
            sortingSource: source,
            reportTemplates: state.reportTemplates.sort((p1, p2) => handleCompare(p1, p2, direction, source))
        }));
    }

    const handleToggleTemplateModify = (id: number) => {
        const template = state.reportTemplates.find(t => t.id === id)
        setState((state) => ({
            ...state,
            isOpen: !state.isOpen,
            menuAnchorEl: null,
            selectedId: id,
            createTemplate: Object.assign({}, template ? template : initialParmas),
            errors: {}
        }))
    }

    const handleToggleTemplateActions = (id: number, menuAnchorEl: HTMLButtonElement) => {
        setState((state) => ({
            ...state,
            selectedId: id,
            menuAnchorEl: menuAnchorEl,
            errors: {}
        }))
    }

    const handleCloneTemplate = () => {
        setState(state => ({
            ...state,
            menuAnchorEl: null,
            isSubmitting: true
        }));

        const cb = () => setState(state => ({ ...state, isSubmitting: false, selectedId: -1 }));
        healthReportTemplatesService.cloneReportTemplate(state.selectedId).subscribe(cb, cb);
    }
  
    const handleDeleteTemplate = () => {
        setState(state => ({
            ...state,
            menuAnchorEl: null
        }));
  
        confirmService.confirm(
            'Delete Health Report Template',
            'Are you sure you want to delete this template?',
            'Yes',
            'Cancel',
            'danger')
            .subscribe(() => {
                setState(state => ({
                    ...state,
                    isSubmitting: true
                }));
        
                const cb = () => setState(state => ({ ...state, isSubmitting: false, selectedId: -1 }));
                healthReportTemplatesService.deleteReportTemplate(state.selectedId).subscribe(cb, cb);
            });
    }

    const handleChanges = (field: string, value: any) => {
        modifyReportTemplateValidator.validateAndSetState(state, setState, field, value);

        const params = state.createTemplate;
        
        params[field] = value;

        setState({...state, createTemplate: Object.assign({}, params)});
    }
  
    const handleSaveTemplate = () => {
        modifyReportTemplateValidator.validateObjectAndSetState(state, setState, state.createTemplate);
        if (!modifyReportTemplateValidator.stateIsValid(state)) {
            return;
        }
  
        setState({...state, isSubmitting: true })

        const action = state.selectedId > 0 ? healthReportTemplatesService.updateReportTemplate(state.createTemplate) : healthReportTemplatesService.createReportTemplate(state.createTemplate);
  
        const cb = () => setState(state => ({ ...state, isSubmitting: false, isOpen: false, selectedId: -1, createTemplate: Object.assign({}, initialParmas) }));
  
        action.subscribe(cb, cb);
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<ReportTemplateModel[]>(healthReportTemplatesQuery.reportTemplates$, templates => {
                setState(state => ({
                    ...state,
                    reportTemplates: templates
                }));
            }),
        ];

        const cb = () => setState(state => ({ ...state, isLoading: false }));

        healthReportTemplatesService.getHealthReportTemplates(ReportType.Health).subscribe(cb, cb);

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [])

    return [
        state,
        handleSorting,
        handleToggleTemplateModify,
        handleToggleTemplateActions,
        handleSaveTemplate,
        handleCloneTemplate,
        handleDeleteTemplate,
        handleChanges
    ];
}