import {Box, Card, FormControlLabel, Table, TableBody, TableContainer, TableHead} from "@material-ui/core";
import React from 'react';
import {useStyles} from "./insuranceCard.styles";
import {WildHealthTableRow} from '../../../common/components/wildHealthTable/WildHealthTableRow';
import {WildHealthTableCell} from '../../../common/components/wildHealthTable/WildHealthTableCell';
import {WildHealthButton} from "../../../common/components/wildHealthButton/WildHealthButton";
import {useFacade} from "./insuranceCardComponent.hooks";
import {VerifyInsuranceComponent} from "../verifyInsuranceComponent/VerifyInsuranceComponent";
import {CoverageModel} from "../../models/insurance.models";
import {WildHealthSwitcher} from "../../../common/components/WIldHealthSwitcher";
import {CoverageStatus} from "../../enums/coverageStatus";

interface InsuranceCardComponentProps {
    insurances: CoverageModel[];
    patientId: number;
    displayName: Function;
    isPayByCashLoading: boolean;
}

export const InsuranceCardComponent: React.FC<InsuranceCardComponentProps> = (props: InsuranceCardComponentProps) => {
    const [
        {
            isVerifyInsuranceOpen,
            isRunningVerification
        },
        handleToggleVerifyDialog,
        handleActivationSwitch,
        handleVerifyInsurance,
        runInsuranceVerification
    ] = useFacade(props.patientId);
    const classes = useStyles();

    return (
        <>
            <Card className={classes.subscriptionCard} variant="outlined">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <WildHealthTableRow>
                                <WildHealthTableCell style={{ width: '30%' }} >
                                    <Box pt={2} px={2} >Member ID</Box>
                                </WildHealthTableCell>
                                <WildHealthTableCell style={{ width: '30%' }} >
                                    <Box>Carrier</Box>
                                </WildHealthTableCell>
                                <WildHealthTableCell style={{ width: '20%' }} >
                                    <Box>Verification</Box>
                                </WildHealthTableCell>
                                <WildHealthTableCell style={{ width: '20%' }} >
                                    <Box>Activation</Box>
                                </WildHealthTableCell>
                            </WildHealthTableRow>
                        </TableHead>
                        <TableBody>
                            {
                                props.insurances.map((insurance, index) =>
                                    <WildHealthTableRow key={index}>
                                        <WildHealthTableCell>
                                            <Box pl={2} pt={0.5}>
                                                {insurance.memberId}
                                            </Box>
                                        </WildHealthTableCell>
                                        <WildHealthTableCell>
                                            <Box>
                                                {insurance.insurance.name}
                                            </Box>
                                        </WildHealthTableCell>
                                        <WildHealthTableCell className="wh-tw-flex">
                                            <Box>
                                                <WildHealthButton
                                                    id="verify"
                                                    onClick={() => handleToggleVerifyDialog(true, insurance.id)}
                                                    disabled={props.isPayByCashLoading}
                                                >
                                                    <Box px={2}>
                                                        Manually Verify
                                                    </Box>
                                                </WildHealthButton>
                                            </Box>
                                            <Box marginLeft={2}>
                                                <WildHealthButton
                                                    id="run_verification"
                                                    onClick={() => runInsuranceVerification(insurance.id)}
                                                    disabled={props.isPayByCashLoading || isRunningVerification}
                                                >
                                                    <Box px={2}>
                                                        Run Verification
                                                    </Box>
                                                </WildHealthButton>
                                            </Box>
                                        </WildHealthTableCell>
                                        <WildHealthTableCell>
                                            <Box>
                                                <FormControlLabel
                                                    label=""
                                                    control={
                                                        <WildHealthSwitcher
                                                            checked={insurance.status === CoverageStatus.Active}
                                                            onChange={() => handleActivationSwitch(insurance)}
                                                            name="activateCoverageSwitcher"
                                                            disabled={props.isPayByCashLoading} />
                                                    }
                                                />
                                            </Box>
                                        </WildHealthTableCell>
                                    </WildHealthTableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
            <VerifyInsuranceComponent
                open={isVerifyInsuranceOpen}
                handleSubmit={handleVerifyInsurance}
                handleClose={() => handleToggleVerifyDialog(false)}
            />
        </>
    );
}