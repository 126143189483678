import {useEffect, useState} from "react";
import { Subscription } from "rxjs";
import { onEmit } from "../../../common/helpers/on-emit";
import moment from "moment";
import { IErrorState } from "../../../common/validation/error-state";
import { GeneralValidator } from "../../../common/validation/general-validator";
import { LabGroup } from "../../models/input.models";
import { Gender } from "../../../common/models/user.models";
import { patientsQuery } from "../../../patients/stores/patientsStore";
import { PatientModel } from "../../../patients/models/patient.model";
import { AddOnProvider, AddOnModel } from "../../../addons/models/addOns.models";
import { addOnsService } from "../../../addons/services/addOns.service";
import { authQuery } from "../../../auth/stores/auth";

export interface LabInputSectionViewModel {
    group: LabGroup;
    expanded: boolean;
}

interface SelectDataDialogComponentState extends IErrorState {
    isLoading: boolean;
    canSubmit: boolean;
    selectedDate: Date;
    sections: LabInputSectionViewModel[];
    gender: Gender;
    search: string;
    filteredItems: AddOnModel[];
}

let stateContext: SelectDataDialogComponentState;

export const useFacade = (open: boolean, isEditMode: boolean, handleSubmit: Function, handleClose: Function, disableDates: Date[], handleChangeDate: Function): [
    SelectDataDialogComponentState,
    Function,
    Function,
    Function,
    Function,
    Function,
    (search: string) => void
] => {
    const [state, setState] = useState({
        isLoading: false,
        canSubmit: false,
        selectedDate: null,
        sections: [
            {
                group: LabGroup.Lipids,
                expanded: false
            },
            {
                group: LabGroup.Methylation,
                expanded: false
            },
            {
                group: LabGroup.VitaminsAndMicronutrients,
                expanded: false
            },
            {
                group: LabGroup.InsulinResistanceAndMetabolism,
                expanded: false
            },
            {
                group: LabGroup.Hormones,
                expanded: false
            },
            {
                group: LabGroup.Inflammation,
                expanded: false
            },
            {
                group: LabGroup.CBC,
                expanded: false
            },
            {
                group: LabGroup.Metabolic,
                expanded: false
            },
            {
                group: LabGroup.Other,
                expanded: false
            }
        ],
        errors: {},
        gender: Gender.None,
        search: '',
        filteredItems: [],
    } as SelectDataDialogComponentState);

    stateContext = state;

    const dateFormat = 'YYYY-MM-DD';

    const toStringDate = (momentDate: moment.Moment): string => momentDate.format(dateFormat);

    const getDisableDates = (dates: Date[]): string[] => dates?.map(date => toStringDate(moment(date))) ?? [];

    const isDateInDisabledRange = (day: moment.Moment): boolean => getDisableDates(disableDates).includes(toStringDate(day));

    const dateAvailable = () => {
        GeneralValidator.removeError(state, 'selectedDate');
        setState(state => ({ ...state, canSubmit: true}));
    }

    const dateUnavailable = (error: string) => {
        GeneralValidator.addError(state, 'selectedDate', error);
        setState(state => ({ ...state, canSubmit: false}));
    }

    const handleDateChanges = async (date: Date) => {
        const now = new Date();
        const formatted = new Date(date.getFullYear(), date.getMonth(), date.getDate(), now.getHours(), now.getMinutes(), now.getSeconds());
        setState(state => ({...state, selectedDate: formatted, canSubmit: false}));

        isDateInDisabledRange(moment(date))
            ? dateUnavailable('Selected date is currently in use.')
            : dateAvailable();

        setState(state => ({ ...state, canSubmit: true}));
        handleChangeDate(formatted)
    }

    const handleExpand = (group: LabGroup) => {
        const section = state.sections.find(x => x.group === group);
        section.expanded = !section.expanded;
        setState({ ...state, sections: state.sections });
    }

    const handleCloseDialog = () => {
        setState(state => ({
            ...state,
            selectedDate: new Date()
        }))

        handleClose();
    }

    const handleSubmitForm = (event: any) => {
        event.preventDefault();

        if (!GeneralValidator.isValidState(state)) {
            return;
        }

        handleSubmit();
        handleCloseDialog();
    }

    const handleOnFilter = (search: string) => {
        setState(state => ({...state, search: search}));
    }

    const selectAddOns = (gender?: Gender, search?: string) => {
        setState(state => ({
            ...state,
            isLoading: true
        }));

        addOnsService.selectOrdering(
            authQuery.getCurrentPracticeId(),
            gender ?? state.gender,
            AddOnProvider.LabCorp,
            null,
            null,
            search ?? state.search
        ).subscribe(
            response => {
                setState(state => ({
                    ...state,
                    filteredItems: response.data,
                    isLoading: false
                }));
            },
            () => {
                setState(state => ({
                    ...state,
                    filteredItems: [],
                    isLoading: false
                }));
            }
        );
    }

    const useEffectCB = () => {
        if (open) {
            if (isEditMode) {
                dateAvailable();
            } else {
                handleDateChanges(new Date());
            }
            const sections = state.sections;
            sections.forEach((section) => section.expanded = true);
    
            setState(state => ({
                ...state,
                sections: sections
            }));
        }

        return () => {
        };
    };

    useEffect(useEffectCB, [open, isEditMode]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (open) {
                selectAddOns(stateContext.gender, stateContext.search);
            }
        }, 1000);

        return () => clearTimeout(delayDebounceFn)
    }, [stateContext.search]);

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<PatientModel>(patientsQuery.targetPatient$, patient => {
                if (patient) {
                    setState(state => ({
                        ...state,
                        gender: patient.gender
                    }));

                    selectAddOns(patient.gender);
                }
            })
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [
        state,
        isDateInDisabledRange,
        handleDateChanges,
        handleCloseDialog,
        handleSubmitForm,
        handleExpand,
        handleOnFilter
    ]
}