import React from "react";
import {Box, List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import {ConversationModelBase, ConversationType} from "../../models/conversation.models";
import {useFacade} from "./playgroundChatListComponent.hooks";
import AddIcon from '@material-ui/icons/Add';
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import {useStyles} from "./playgroundChatListComponent.styles";

export interface PlaygroundChatListComponentProps {
    type: ConversationType;
    patientId?: number;
    targetConversation: ConversationModelBase | null;
    handleSelect: (conversation: ConversationModelBase) => void;
    handleCreate: () => void;
}

export const PlaygroundChatListComponent: React.FC<PlaygroundChatListComponentProps> = (props: PlaygroundChatListComponentProps) => {
    const {
        type,
        patientId,
        targetConversation,
        handleSelect,
        handleCreate
    } = props;

    const [
        {
            isLoading,
            conversations
        }
    ] = useFacade(type, patientId);

    const classes = useStyles();

    if (isLoading) {
        return <Box p={2}>
            <WildHealthLinearProgress/>
        </Box>
    }

    return (
        <>
            <Box>
                <List>
                    <ListItem button onClick={() => handleCreate()}>
                        <ListItemIcon className="wh-tw-text-primaryV">
                            <AddIcon/>
                        </ListItemIcon>
                        <ListItemText className="wh-tw-text-primaryV" primary="New Chat" />
                    </ListItem>
                </List>
            </Box>
            <Box className={classes.root}>
                <List>
                    {
                        conversations.map(x => <ListItem selected={x.id === targetConversation?.id} button onClick={() => handleSelect(x)}>
                            <ListItemText primary={x.subject} />
                        </ListItem>)
                    }
                </List>
            </Box>
        </>
    )
}