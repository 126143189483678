import React from 'react';
import { Box, Divider, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import { FacebookShareButton } from "react-share";
import { AuthenticatedLayoutComponent } from '../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { PagesNamesService } from "../../../../constants/pagesNames.constants";
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from "../../../../models/menu.models";
import { useStyles } from "./referralLandingPage.styles";
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from 'clsx';
import information from "@img/referralLanding/share_gift.png";
import { ReactComponent as FacebookIcon } from '@img/icons/FacebookIcon.svg';
import { ReactComponent as TwitterIcon } from '@img/icons/TwitterIcon.svg';
import { ReactComponent as PhoneIcon } from '@img/icons/PhoneIcon.svg';
import { ReactComponent as ClipboardIcon } from '@img/icons/ClipboardIcon.svg';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import { useFacade } from "./referralLandingPage.hooks";

export const ReferralLandingPage: React.FC = () => {
    const [
        {
            referralCode,
        },
        sendSocialNetwork
    ] = useFacade();

    const commonClasses = commonUseStyles();
    const classes = useStyles();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <AuthenticatedLayoutComponent
            hideBackground
            title={TitlesService.referralLandingTitle}
            pageName={PagesNamesService.referFriend}
            selectedMenuItem={MenuItemTypes.ReferralLanding}
        >
            <Box pt={5}>
                {isSmallScreen ? (
                    <>
                        <Box width={1}>
                            <img src={information} alt="img" className={classes.image} />
                        </Box>
                        <Box mt={4} textAlign="center">
                            <Box className={classes.headerTextSM}>
                                <span>Get Your Friends Involved</span>
                            </Box>
                            <Box className={clsx(commonClasses.colorLinkBlack, commonClasses.textRegular, commonClasses.size18)} mt={4} px={3} >
                                <span>
                                    Share your code with family and friends to give them <span className={clsx(commonClasses.colorMain, commonClasses.textMedium)}>25% off</span> a Wild Health membership – and the gift of good health. Let’s crush our health goals together.
                                </span>
                            </Box>
                        </Box>
                        <Box mt={4} mb={4} display="flex" justifyContent="center">
                            <Box className={classes.shareContent} textAlign="center">
                                <Box className={clsx(commonClasses.colorDarkGray, commonClasses.textRegular, commonClasses.size16)}>
                                    <span>Share this code</span>
                                </Box>
                                <Box mt={1} width={1}>
                                    <WildHealthButton
                                        id="share-code"
                                        size="large"
                                        color={"referral"}
                                        onClick={() => { navigator.clipboard.writeText(referralCode) }}
                                        border={"none"}
                                        fullWidth
                                        style={{
                                            borderRadius: 8,
                                            height: 56,
                                            padding: 0,
                                            paddingInline: 24,
                                        }}
                                    >
                                        <Box display="flex" alignItems="center" justifyContent="space-between">
                                            <Box flex={1} mr={2} className={clsx(commonClasses.colorLinkBlack, commonClasses.textMedium, commonClasses.size18, classes.codeContent)}>
                                                <span>{referralCode}</span>
                                            </Box>
                                            <ClipboardIcon />
                                        </Box>
                                    </WildHealthButton>
                                </Box>
                                <Box mt={1} display="flex" alignItems="center" justifyContent="space-between" className={classes.orContent}>
                                    <Divider className={classes.orDivider} />
                                    <Box px={3} className={clsx(commonClasses.colorDarkGray, commonClasses.textRegular, commonClasses.size16)}>
                                        <span>or</span>
                                    </Box>
                                    <Divider className={classes.orDivider} />
                                </Box>
                                <Box mt={1} className={clsx(commonClasses.colorDarkGray, commonClasses.textRegular, commonClasses.size16)}>
                                    <span>Send by social networks</span>
                                </Box>
                                <Box mt={1} display="flex" alignItems="center" justifyContent="space-between">
                                    <FacebookShareButton
                                        url={`https://bit.ly/3BewmOJ`}
                                        quote={`I’m loving my experience with Wild Health - I got a fully customized health report using my DNA & data and am learning a ton about my health from my health team. Check it out with my code WILD18725 for 25% off!
                                        https://bit.ly/3BewmOJ`}
                                    >
                                        <WildHealthButton
                                            id="share-facebook"
                                            size="large"
                                            onClick={() => sendSocialNetwork("facebook")}
                                            color={"referral"}
                                            border={"none"}
                                            width={'56px'}
                                            style={{
                                                minWidth: 56,
                                                borderRadius: 8,
                                                height: 56,
                                                padding: 0,
                                            }}
                                        >
                                            <FacebookIcon />
                                        </WildHealthButton>
                                    </FacebookShareButton>
                                    <WildHealthButton
                                        id="social-twitter"
                                        size="large"
                                        onClick={() => sendSocialNetwork("twitter")}
                                        color={"referral"}
                                        border={"none"}
                                        width={'56px'}
                                        style={{
                                            minWidth: 56,
                                            borderRadius: 8,
                                            height: 56,
                                            padding: 0
                                        }}
                                    >
                                        <TwitterIcon />
                                    </WildHealthButton>
                                    <WildHealthButton
                                        id="social-sms"
                                        size="large"
                                        onClick={() => sendSocialNetwork("sms")}
                                        color={"referral"}
                                        border={"none"}
                                        width={'56px'}
                                        style={{
                                            minWidth: 56,
                                            borderRadius: 8,
                                            height: 56,
                                            padding: 0
                                        }}
                                    >
                                        <PhoneIcon />
                                    </WildHealthButton>
                                </Box>
                            </Box>
                        </Box>
                    </>
                ) : (
                        <Grid container>
                            <Grid item xs={12} md={6} lg={6}>
                                <Box pl={1.5} pr={3} className={classes.root}>
                                    <Box className={classes.headerText}>
                                        <span>Get Your Friends Involved</span>
                                    </Box>
                                    <Box className={clsx(commonClasses.colorLinkBlack, commonClasses.textRegular, commonClasses.size24)} mt={5} >
                                        <span>Share your code with family and friends to give them <span className={clsx(commonClasses.colorMain, commonClasses.textMedium)}>25% off</span> a Wild Health membership – and the gift of good health.<br /> Let’s crush our health goals together.</span>
                                    </Box>
                                    <Box mt={5} display="flex" alignItems="center">
                                        <Box className={classes.shareContent} textAlign="center">
                                            <Box className={clsx(commonClasses.colorDarkGray, commonClasses.textRegular, commonClasses.size16)}>
                                                <span>Send by social networks</span>
                                            </Box>
                                            <Box mt={1} display="flex" alignItems="center" justifyContent="space-between">
                                                <FacebookShareButton
                                                    url={`https://bit.ly/3BewmOJ`}
                                                    quote={`I’m loving my experience with Wild Health - I got a fully customized health report using my DNA & data and am learning a ton about my health from my health team. Check it out with my code WILD18725 for 25% off!
                                                https://bit.ly/3BewmOJ`}
                                                >
                                                    <WildHealthButton
                                                        id="share-facebook"
                                                        size="large"
                                                        onClick={() => sendSocialNetwork("facebook")}
                                                        color={"referral"}
                                                        border={"none"}
                                                        width={'56px'}
                                                        style={{
                                                            minWidth: 56,
                                                            borderRadius: 8,
                                                            height: 56,
                                                            padding: 0,
                                                        }}
                                                    >
                                                        <FacebookIcon />
                                                    </WildHealthButton>
                                                </FacebookShareButton>
                                                <WildHealthButton
                                                    id="social-twitter"
                                                    size="large"
                                                    onClick={() => sendSocialNetwork("twitter")}
                                                    color={"referral"}
                                                    border={"none"}
                                                    width={'56px'}
                                                    style={{
                                                        minWidth: 56,
                                                        borderRadius: 8,
                                                        height: 56,
                                                        padding: 0
                                                    }}
                                                >
                                                    <TwitterIcon />
                                                </WildHealthButton>
                                                <WildHealthButton
                                                    id="social-sms"
                                                    size="large"
                                                    onClick={() => sendSocialNetwork("sms")}
                                                    color={"referral"}
                                                    border={"none"}
                                                    width={'56px'}
                                                    style={{
                                                        minWidth: 56,
                                                        borderRadius: 8,
                                                        height: 56,
                                                        padding: 0
                                                    }}
                                                >
                                                    <PhoneIcon />
                                                </WildHealthButton>
                                            </Box>
                                        </Box>
                                        <Box mx={4} mt={3} className={clsx(commonClasses.colorDarkGray, commonClasses.textRegular, commonClasses.size16)}>
                                            <span>or</span>
                                        </Box>
                                        <Box className={classes.shareContent} textAlign="center">
                                            <Box className={clsx(commonClasses.colorDarkGray, commonClasses.textRegular, commonClasses.size16)}>
                                                <span>Share this code</span>
                                            </Box>
                                            <Box mt={1} width={1}>
                                                <WildHealthButton
                                                    id="share-code"
                                                    size="large"
                                                    color={"referral"}
                                                    onClick={() => { navigator.clipboard.writeText(referralCode) }}
                                                    border={"none"}
                                                    fullWidth
                                                    style={{
                                                        borderRadius: 8,
                                                        height: 56,
                                                        padding: 0,
                                                        paddingInline: 24,
                                                    }}
                                                >
                                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                                        <Box flex={1} mr={2} className={clsx(commonClasses.colorLinkBlack, commonClasses.textMedium, commonClasses.size18, classes.codeContent)}>
                                                            <span>{referralCode}</span>
                                                        </Box>
                                                        <ClipboardIcon />
                                                    </Box>
                                                </WildHealthButton>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <Box width={1}>
                                    <img src={information} alt="img" className={classes.image} />
                                </Box>
                            </Grid>
                        </Grid>
                    )}

            </Box>
        </AuthenticatedLayoutComponent>
    )
}
