import React from 'react';
import { Box, Container, TextField, Link, Typography, InputAdornment } from "@material-ui/core";
import WarningIcon from '@material-ui/icons/Warning';
import { ReactComponent as WHClarityLogo } from '@img/logos/WHClarityLogo.svg';
import { useFacade } from "./forgotPasswordPage.hooks";
import { useStyles } from '../loginPage/loginPage.styles';
import { TitlesService } from '../../../../constants/title.constants';
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";

export const ForgotPasswordPage: React.FC = () => {
    const [
        {
            model,
            errors,
            isLoading,
        },
        mobileForgotPasswordPath,
        renderRecaptcha,
        handleChanges,
        handleSubmit,
        handleReturn,
        handleKeyDown
    ] = useFacade();
    const classes = useStyles();

    return (
        <>
            <title>{TitlesService.forgotPasswordTitle}</title>
            <div className="wh-tw-w-full wh-tw-h-full wh-tw-min-h-screen wh-tw-bg-white">
                <Box display="flex" justifyContent="center" alignItems="center" height="102px">
                    <WHClarityLogo />
                </Box>

                <Box className={classes.contentBox}>
                    <Container maxWidth="sm">
                        <Box className="wh-tw-text-3xl md:wh-tw-text-4xl wh-tw-font-medium wh-tw-text-secondary" textAlign="center">
                            Reset Password
                        </Box>
                        <Box mt={2} display="flex" flexDirection="column" textAlign="center" px={3} >
                            <span className="wh-tw-text-lg wh-tw-text-gray600">Forgot your password? No problem.</span>
                            <span className="wh-tw-text-lg wh-tw-text-gray600">Enter the email address associated with your account.</span>
                        </Box>

                        <Box display='flex' justifyContent="center">
                            <Box maxWidth={456} width={1} display='flex' flexDirection='column' mt={4}>
                                <form onSubmit={(v) => handleSubmit(v)}>
                                    <Box className="wh-tw-text-xxs wh-tw-font-medium wh-tw-text-gray600" mb={1}>Email <span className="wh-tw-text-primaryV">*</span></Box>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        placeholder="Email address"
                                        required
                                        id="email"
                                        value={model.email}
                                        classes={{ root: classes.root }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {!!errors['email'] && <WarningIcon fontSize="small" className="wh-tw-text-error" />}
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                        helperText={errors['email']}
                                        error={!!errors['email']}
                                        type="email"
                                        onChange={(v) => handleChanges(v.target.id, v.target.value)}
                                        onKeyPress={(e) => handleKeyDown(e)}
                                    />
                                    
                                    
                                    <Box display="flex" justifyContent="center" width={1} mt={9}>
                                        <Box className={classes.resetPasswordBtnSection}>
                                            <WildHealthButton
                                                size="large"
                                                id="forgot-password-send-reset"
                                                onClick={(e) => handleSubmit(e)}
                                                fullWidth
                                                borderRadius={50}
                                                loading={isLoading}
                                                disabled={!!errors['email'] || !model.email}
                                            >
                                                Reset Password
                                            </WildHealthButton>
                                            {!mobileForgotPasswordPath && (
                                                <Box display="flex" justifyContent="center" mt={4}>
                                                    <Link id="forgot-password-back" onClick={(e) => handleReturn(e)}>
                                                        <Typography
                                                            className="wh-tw-text-primaryV wh-tw-font-medium"
                                                            display="inline"
                                                        >
                                                            Log In
                                                        </Typography>
                                                    </Link>
                                                </Box>

                                            )}
                                        </Box>
                                    </Box>
                                    {renderRecaptcha()}
                                </form>
                            </Box>
                        </Box>
                    </Container>
                </Box>
            </div>
        </>
    );
}