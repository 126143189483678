import React, { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { onEmit } from "../../../common/helpers/on-emit";
import { IPersonModel } from "../../../common/models/user.models";
import { employeeService } from '../../../employee/services/employees.service';
import { MessageSettingsModel } from "../../models/message.models";
import { conversationsService } from "../../services/conversations.service";
import { employeeConversationsQuery } from "../../stores/employeeConversationsStore/employeeConversations.query";
import { employeeConversationsStore } from "../../stores/employeeConversationsStore/employeeConversations.store";
import { awayMessageTemplatesService } from "../../../employee/services/awayMessageTemplates.service";
import { ConversationAwayMessageTemplateModel } from "../../models/conversation.models";
import moment from "moment";
import { toCurrentTimeZone } from "../../../timezones/helpers/timezone";

interface MessageSettingsComponentState {
    isLoading: boolean;
    templatesLoading: boolean;
    isChanged: boolean;
    isSubmitting: boolean;
    isUsersLoading: boolean;
    canSubmit: boolean;
    users: IPersonModel[];
    settings: MessageSettingsModel;
    templates: ConversationAwayMessageTemplateModel[];
}

const defaultState: MessageSettingsComponentState = {
    isLoading: true,
    templatesLoading: true,
    isChanged: false,
    isSubmitting: false,
    isUsersLoading: false,
    canSubmit: false,
    users: [],
    settings: {
        awayMessageEnabled: false,
        forwardEmployeeEnabled: false,
        forwardEmployeeId: 0,
        awayMessageEnabledFrom: null,
        awayMessageEnabledTo: null,
        awayMessageTemplateId: 0,
        message: '',
    },
    templates: [],
}

export function useFacade(): [
    MessageSettingsComponentState,
    (field: string, value: any) => void,
    (id: number) => void,
    (searchQuery: string) => void,
    (event: React.ChangeEvent<HTMLInputElement>) => void,
    () => void,
    () => void
] {

    const [state, setState] = useState(defaultState);

    const handleChanges = (settings: MessageSettingsModel) => {
        const initSettings = employeeConversationsStore.getValue().messageSettings;

        for (const [key, value] of Object.entries(settings)) {
            if (value !== initSettings[key]) {
                return true;
            }
        }

        return false;
    }

    const handleChangeSectionState = (event: React.ChangeEvent<HTMLInputElement>) => {
        const settings = { ...state.settings, [event.target.name]: event.target.checked };

        setState(state => ({
            ...state,
            canSubmit: canSubmit(settings),
            settings,
            isChanged: handleChanges(settings)
        }));
    };

    const canSubmit = (settings: MessageSettingsModel) => {
        return (settings.awayMessageEnabled && !!settings.awayMessageTemplateId && !!settings.awayMessageEnabledFrom && !!settings.awayMessageEnabledTo)
        || (settings.forwardEmployeeEnabled && settings.forwardEmployeeId > 0)
        || !settings.forwardEmployeeEnabled
    }

    const handleUserSelect = (id: number) => {
        const settings = { ...state.settings, forwardEmployeeId: id };

        setState(state => ({
            ...state,
            canSubmit: canSubmit(settings),
            settings,
            isChanged: handleChanges(settings)
        }));
    }

    const handleUserSearch = (searchQuery: string) => {
        const settings = { ...state.settings, forwardEmployeeId: 0};
        setState(state => ({ ...state, canSubmit: canSubmit(settings), settings: settings, isUsersLoading: true}));
        
        const successCB = (employees) => setState(state => ({ ...state, isUsersLoading: false, users: employees }));
        const errorCB = () => setState(state => ({ ...state, isUsersLoading: false }));

        employeeService.getActive(searchQuery).subscribe(successCB, errorCB);
    }

    const handleCancel = () => {
        const settings = employeeConversationsStore.getValue().messageSettings;
        setState(state => ({ ...state, isChanged: false, settings }));
    }

    const handleParamChanges = (field: string, value: any) => {
        const settings = { ...state.settings, [field]: value };

        setState(state => ({
            ...state,
            canSubmit: canSubmit(settings),
            settings,
            isChanged: handleChanges(settings)
        }));
    }

    const handleSaveChanges = () => {
        setState(state => ({ ...state, isSubmitting: true }));
        const cb = () => setState(state => ({ ...state, isSubmitting: false, isChanged: false }));
        const errorCB = () => setState(state => ({ ...state, isSubmitting: false }));

        const updateSettingsModel = {
            awayMessageEnabled: state.settings.awayMessageEnabled,
            awayMessageEnabledFrom: moment(toCurrentTimeZone(state.settings.awayMessageEnabledFrom)).toDate(),
            awayMessageEnabledTo: moment(toCurrentTimeZone(state.settings.awayMessageEnabledTo)).toDate(),
            awayMessageTemplateId: state.settings.awayMessageTemplateId,
            forwardEmployeeEnabled: state.settings.forwardEmployeeEnabled,
            forwardEmployeeId: state.settings.forwardEmployeeId,
        }

        conversationsService.updateConversationsSettings(updateSettingsModel).subscribe(cb, errorCB);
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<MessageSettingsModel>(employeeConversationsQuery.messageSettings$, settings =>
                setState(state => ({ ...state, settings }))
            ),
            onEmit<ConversationAwayMessageTemplateModel[]>(employeeConversationsQuery.awayMessageTemplates$, templates =>
                setState(state => ({ ...state, templates }))
            )
        ];

        const cb = () => setState(state => ({ ...state, isLoading: false }));
        const templatesCb = () => setState(state => ({ ...state, templatesLoading: false }));

        employeeService.getActive().subscribe((employees) => {
            setState(state => ({ ...state, users: employees }));
            conversationsService.getConversationsSettings().subscribe(cb, cb);
            awayMessageTemplatesService.getAllTemplates().subscribe(templatesCb, templatesCb);
        }, cb);

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [state,
        handleParamChanges,
        handleUserSelect,
        handleUserSearch,
        handleChangeSectionState,
        handleCancel,
        handleSaveChanges
    ];
}
