import {useEffect, useState} from "react";
import {Subscription} from "rxjs";
import {onEmit} from "../../../common/helpers/on-emit";
import {notificationsQuery} from "../../../notifications/stores/notifications";
import {NotificationModel, NotificationType} from "../../../notifications/models/notifications.models";

const interestedTypes = [
    NotificationType.NewDocuments
];

export function useFacade(patientId:number): [boolean] {
    const [newDocuments, setNewDocumnts] = useState(false);

    const hasNewDocuments = (notifications: NotificationModel[]): boolean => {
        notifications = notifications.filter(x => interestedTypes.includes(x.type));

        notifications = notifications.filter(x => x.linkData.includes(patientId.toString()));

        return notifications.length > 0;
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<NotificationModel[]>(notificationsQuery.notifications$, notifications => {
                if (notifications && notifications.length) {
                    setNewDocumnts(() => (hasNewDocuments(notifications)));
                } else {
                    setNewDocumnts(() => false);
                }
            }),
        ];

        return (() => {
            subscriptions.map(it => it.unsubscribe())
        })
    }, []);

    return [newDocuments];
}