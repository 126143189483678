import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { onEmit } from "../../../common/helpers/on-emit";
import {
    ReportStrategy,
    PatientReportModel,
    PatientAddOnReportModel
} from "../../models/healthReport.models";
import { healthReportsQuery } from "../../stores/healthReportsStore";
import { addOnReportsServices } from "../../services/addOnReports.service";
import { ReportType } from "../../models/reportTemplate.models";
import { authQuery } from "../../../auth/stores/auth";
import { patientsService } from "../../../patients/services/patients.service";
import { locationsService } from "../../../locations/services/locations.service";
import { appointmentsService } from "../../../appointments/services/appointments.service";
import { employeeService } from "../../../employee/services/employees.service";
import { appointmentsQuery } from "../../../appointments/stores/appointments";
import { employeesQuery } from "../../../employee/stores/employeesStore";
import { patientsQuery } from "../../../patients/stores/patientsStore";
import { locationsQuery } from "../../../locations/stores";
import { PatientAppointmentModel, AppointmentTypeModel } from "../../../appointments/models/appointments.models";
import { EmployeeModel } from "../../../employee/models/employee.models";
import { TimeZoneModel } from "../../../timezones/models/timezone.model";
import { LocationModel } from "../../../locations/models/locations.models";
import { addOnReportPdfServices } from "../../services/addOnReportPdf.service";


interface AddOnReportsComponentState {
    isLoading: boolean;
    isPatientReportLoading: boolean;
    isAppointmentsLoading: boolean;
    isAppointmentTypesLoading: boolean;
    isGenerating: boolean;
    isDownloading: boolean;
    isShowReport: boolean;
    availableAddOnReports: PatientAddOnReportModel[];
    patientReport: PatientReportModel;
    assignedEmployees: EmployeeModel[];
    appointmentTypes: AppointmentTypeModel[];
    appointments: PatientAppointmentModel[];
    locations: LocationModel[];
    timeZone: TimeZoneModel;
}


export function useFacade(patientId: number | null): [
    AddOnReportsComponentState,
    () => void,
    () => void,
    (type: ReportType) => void
] {

    const [state, setState] = useState({
        isLoading: true,
        isPatientReportLoading: true,
        isAppointmentsLoading: true,
        isAppointmentTypesLoading: true,
        isGenerating: false,
        isDownloading: false,
        isShowReport: false,
        availableAddOnReports: [],
        patientReport: null,
        assignedEmployees: [],
        appointmentTypes: [],
        appointments: [],
        locations: [],
        timeZone: null,
    } as AddOnReportsComponentState);

    const handleDownloadReport = () => {
        setState(state => ({ ...state, isDownloading: true }))
        addOnReportPdfServices.generate(state.patientReport, patientId).then(() => {
            setState(state => ({
                ...state,
                isDownloading: false
            }));
        })
    }

    const handleGenerateReport = (type: ReportType) => {
        setState(state => ({ ...state, isGenerating: true }))

        const cb = () => setState(state => ({ ...state, isGenerating: false }));

        if (patientId) {
            addOnReportsServices.CreatePatientReportByEmployee(type, patientId).subscribe(() => setState(state => ({ ...state, isGenerating: false, isShowReport: true })), cb);
        } else {
            addOnReportsServices.CreatePatientReport(type).subscribe(() => setState(state => ({ ...state, isGenerating: false, isShowReport: true })), cb);
        }
    }

    const handleViewReport = () => {
        setState(state => ({ ...state, isShowReport: !state.isShowReport }))
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<PatientReportModel>(healthReportsQuery.patientReport$, report => {
                setState(state => ({
                    ...state,
                    patientReport: report
                }));
            }),
            onEmit<PatientAddOnReportModel[]>(healthReportsQuery.addOnReports$, addOnReports => {
                setState(state => ({
                    ...state,
                    availableAddOnReports: addOnReports
                }));
            }),
            onEmit<PatientAppointmentModel[]>(appointmentsQuery.patientAppointments$, appointments => {
                setState(state => ({ ...state, appointments: appointments }));
            }),
            onEmit<AppointmentTypeModel[]>(appointmentsQuery.appointmentTypes$, types => {
                setState(state => ({ ...state, appointmentTypes: types }))
            }),
            onEmit<EmployeeModel[]>(employeesQuery.patientEmployees$, employees => {
                setState(state => ({ ...state, assignedEmployees: employees }));
            }),
            onEmit<TimeZoneModel>(patientsQuery.patientTimeZone$, timeZone => {
                setState(state => ({ ...state, timeZone: timeZone }));
            }),
            onEmit<LocationModel[]>(locationsQuery.availableLocations$, locations => {
                setState(state => ({ ...state, locations: locations }));
            }),
        ];

        const cb = () => setState(state => ({ ...state, isLoading: false }));
        const patientReportCB = () => setState(state => ({ ...state, isPatientReportLoading: false }));
        const appointmentsCB = () => setState(state => ({ ...state, isAppointmentsLoading: false }));
        const appointmentTypeCB = () => setState(state => ({ ...state, isAppointmentTypesLoading: false }));

        patientsService.getPatientTimeZone(patientId);
        locationsService.getAvailable(authQuery.getCurrentPracticeId()).subscribe();
        appointmentsService.getAvailableAppointmentTypesAsync(patientId).subscribe(appointmentTypeCB, appointmentTypeCB);

        if (patientId) {
            addOnReportsServices.GetAvailableReportsByEmployee(ReportStrategy.AddOnFree, patientId).subscribe(cb, cb);
            addOnReportsServices.GetPatientReportByEmployee(ReportType.Celiac, patientId).subscribe(patientReportCB, () => setState(state => ({ ...state, isPatientReportLoading: false, patientReport: null })));
            employeeService.getAssignedToById(patientId).subscribe();
            appointmentsService.getPatientAppointmentsById(patientId, new Date()).subscribe(appointmentsCB, appointmentsCB);
        } else {
            addOnReportsServices.GetAvailableReports(ReportStrategy.AddOnFree).subscribe(cb, cb);
            addOnReportsServices.GetPatientReport(ReportType.Celiac).subscribe(patientReportCB, () => setState(state => ({ ...state, isPatientReportLoading: false, patientReport: null })));
            employeeService.getAssignedTo().subscribe();
            appointmentsService.getPatientAppointments(new Date()).subscribe(appointmentsCB, appointmentsCB);
        }

        return () => {
            subscriptions.map(it => it.unsubscribe());
        };
    }, []);

    return [
        state,
        handleDownloadReport,
        handleViewReport,
        handleGenerateReport
    ];
}