import { navigationService } from './navigation.service';
import { authService } from "../modules/auth/services/auth.service";
import {LocationModel} from "../modules/locations/models/locations.models";
import {authQuery} from "../modules/auth/stores/auth";

export class AccountService {
    public logout = () => {
        authService.logout();
    }

    public loginToLicenceApp = () => {
        const url = `${process.env.REACT_APP_API_URL}`;
        window.open(`${url}Integrations/Redirect/LicensingApp?auth_query=${authQuery.getToken()}`);
    }

    public switchLocation(location: LocationModel, history: any) {
        authService.reLogin(location.id).subscribe(() => {
            navigationService.toDashboard(history);
            window.location.reload();
        });
    }
}

export const accountService = new AccountService();
