import { NoteModel, SectionType } from "../../models/notes.models";
import React from "react";
import { Box, Button, ButtonGroup, CircularProgress, Divider, useTheme, useMediaQuery, Select, FormControl, MenuItem } from "@material-ui/core";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import moment from "moment";
import { useFacade } from "./historyAndPhysical.hooks";
import GetAppIcon from "@material-ui/icons/GetApp";
import commonUseStyles from "../../../common/styles/common.styles";
import {
    getApoeInfo,
    IsSectionAmendHistoryAndPhysical,
    itemWithDescription,
    renderApoeSection,
    renderEducation,
    renderEmptyMedicationsAndSupplementsToStart,
    renderEmptyMedicationsAndSupplementsToStop,
    renderGoals,
    renderItemsSection, renderMdm,
    renderMedicationsAndSupplementsToStart,
    renderMedicationsAndSupplementsToStop,
    renderNextAppointmentDate,
    renderTextSection,
    renderVitals
} from "../../helpers/noteHelpers";
import { TextMarkdownComponent } from "../textMarkdownComponent/TextMarkdownComponent";
import { NoteSignatureComponent } from "../noteSignature/NoteSignatureComponent";
import EditIcon from '@material-ui/icons/Edit';
import { confirmService } from "../../../../services/confirm.service";
import { WildHealthStatusLabel } from "../../../common/components/wildHealthStatusLabel/WildHealthStatusLabel";
import {FeatureComponent} from "../../../common/components/featureFlags/FeatureComponent";
import {FeatureFlag} from "../../../common/components/featureFlags/featureFlags.models";
import {useFlags} from "launchdarkly-react-client-sdk";

export interface HistoryAndPhysicalNoteComponentProps {
    patientId: number;
    handleGoBack: () => void;
    goBackTitle: string;
    note: NoteModel;
    handleGoToEditNote: (note: NoteModel) => void;
}

export const HistoryAndPhysicalNoteComponent: React.FC<HistoryAndPhysicalNoteComponentProps> = (props: HistoryAndPhysicalNoteComponentProps) => {
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const flags = useFlags();

    const {
        patientId,
        handleGoBack,
        goBackTitle,
        note,
        handleGoToEditNote
    } = props;

    const [
        {
            content,
            mdmCodes,
            internalContent,
            isPdfDownloading,
            isContentLoading,
            noteId,
            originalContent
        },
        handleDownloadPdf,
        handleChooseNote
    ] = useFacade(patientId, note);

    const renderTitle = () => {
        return <span>{note.name} - {moment(note.visitDate).format("ll")}</span>
    }

    const handleEditNote = () => {
        confirmService.confirm('Are you sure?', 'Are you sure you wish to amend this note? All changes will be logged.')
            .subscribe(() => {
                handleGoToEditNote(note);
            });
    }

    const displayEditButton = () => {

        return (
            <Button id="edit_current_note" onClick={() => handleEditNote()}>
                <Box display="flex" alignItems="center" mt={1}>
                    <Box>
                        <EditIcon />
                    </Box>
                </Box>
            </Button>
        );
    }

    const displaySelectVersion = () => {
        if (note.amendedNotes?.length === 0 || note.amendedNotes == null || note.amendedNotes.every(x => x.completedAt === null)) {
            return
        }

        return (
            <Box display="flex" alignItems="center" justifyContent="flex-start" ml={2}>
                <Box pr={2}>
                    <WildHealthStatusLabel color='normal'>Amended Note</WildHealthStatusLabel>
                </Box>
                <Box width="120px">
                    <FormControl color='primary' variant="outlined" size="small" fullWidth>
                        <Select
                            style={{ background: '#FFF' }}
                            id="choseNoteVersion"
                            value={noteId}
                            onChange={(v) => handleChooseNote(v.target.value)}>
                            <MenuItem key={0} value={note.id}>Original</MenuItem>
                            {
                                note.amendedNotes.filter(x => x.completedAt != null).map((note, i) => {
                                    return <MenuItem key={i + 1} value={note.id}>Amended - {moment(note.completedAt).format("MM/DD/YYYY")}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Box>
        );
    }

    const displayDownloadPdfButton = () => {
        if (isPdfDownloading === true) {
            return (
                <Button>
                    <Box width="70px" display="flex" justifyContent="center">
                        <CircularProgress size={24} className={commonClasses.colorMain} />
                    </Box>
                </Button >
            )
        }
        return (
            <Button id="initial-consult-download-pdf" onClick={() => {
                handleDownloadPdf(note, content);
            }}>
                <Box display="flex" alignItems="center" mt={1}>
                    <Box>
                        <GetAppIcon />
                    </Box>
                    <Box mt={-1}>
                        PDF
                    </Box>
                </Box>
            </Button>);
    }

    const renderPlan = () => {
        return (
            <Box>
                <span className={isSmallScreen ? commonClasses.mediumTitle : commonClasses.secondaryTitle}>
                    Plan
                </span>
                <Box py={2}>
                    <Divider />
                </Box>
                <span style={{ whiteSpace: 'pre-line' }} className={isSmallScreen ? commonClasses.smallTitle : commonClasses.subtitle}>
                    {internalContent.plan?.planText
                        ? <TextMarkdownComponent content={internalContent.plan?.planText} />

                        : 'These are our main recommendations from this visit. These should be your primary focus.'
                    }
                </span>
            </Box>
        )
    }

    const isSomeSectionWasUpdated = () => {
        const sectionForChecking = [
            SectionType.Hpi,
            SectionType.PMH,
            SectionType.Diagnosis,
            SectionType.Physical,
            SectionType.Plan,
            SectionType.MedicationAndSupplements,
            SectionType.Mdm,
            SectionType.Ros,
        ];

        return sectionForChecking.some(el => IsSectionAmendHistoryAndPhysical(originalContent, internalContent, el))
    }

    const renderNoteContent = (): JSX.Element => {
        return (
            <Box p={!isSmallScreen && 1.8}>
                <Box>
                    <Box>
                        {
                            renderPlan()
                        }
                    </Box>
                    <Box mt={isSmallScreen ? "20px" : 4}>
                        {
                            internalContent.plan.dietGoals?.length > 0 &&
                            <Box>
                                {
                                    renderGoals('Diet', internalContent.plan.dietGoals, commonClasses, isSmallScreen)
                                }
                            </Box>
                        }
                        {
                            internalContent.plan.dietEducations?.length > 0 &&
                            <Box>
                                {
                                    renderEducation(internalContent.plan.dietEducations, commonClasses)
                                }
                            </Box>
                        }
                        {
                            internalContent.plan.exerciseGoals?.length > 0 &&
                            <Box mt={isSmallScreen ? "20px" : 4}>
                                {
                                    renderGoals('Exercise/Recovery', internalContent.plan.exerciseGoals, commonClasses, isSmallScreen)
                                }
                            </Box>
                        }
                        {
                            internalContent.plan.exerciseEducations?.length > 0 &&
                            <Box>
                                {
                                    renderEducation(internalContent.plan.exerciseEducations, commonClasses)
                                }
                            </Box>
                        }
                        {
                            internalContent.plan.sleepGoals?.length > 0 &&
                            <Box mt={isSmallScreen ? "20px" : 4}>
                                {
                                    renderGoals('Sleep', internalContent.plan.sleepGoals, commonClasses, isSmallScreen)
                                }
                            </Box>
                        }
                        {
                            internalContent.plan.sleepEducations?.length > 0 &&
                            <Box>
                                {
                                    renderEducation(internalContent.plan.sleepEducations, commonClasses)
                                }
                            </Box>
                        }
                        {
                            internalContent.plan.neuroGoals?.length > 0 &&
                            <Box mt={isSmallScreen ? "20px" : 4}>
                                {
                                    renderGoals('Neuro', internalContent.plan.neuroGoals, commonClasses, isSmallScreen)
                                }
                            </Box>
                        }
                        {
                            internalContent.plan.neuroEducations?.length > 0 &&
                            <Box>
                                {
                                    renderEducation(internalContent.plan.neuroEducations, commonClasses)
                                }
                            </Box>
                        }
                        {
                            internalContent.plan.longevityGoals?.length > 0 &&
                            <Box mt={isSmallScreen ? "20px" : 4}>
                                {
                                    renderGoals('Longevity', internalContent.plan.longevityGoals, commonClasses, isSmallScreen)
                                }
                            </Box>
                        }
                        {
                            internalContent.plan.longevityEducations?.length > 0 &&
                            <Box>
                                {
                                    renderEducation(internalContent.plan.longevityEducations, commonClasses)
                                }
                            </Box>
                        }
                        {
                            internalContent.plan.mindfulnessGoals?.length > 0 &&
                            <Box mt={isSmallScreen ? "20px" : 4}>
                                {
                                    renderGoals('Mindfulness', internalContent.plan.mindfulnessGoals, commonClasses, isSmallScreen)
                                }
                            </Box>
                        }
                        {
                            internalContent.plan.mindfulnessEducations?.length > 0 &&
                            <Box>
                                {
                                    renderEducation(internalContent.plan.mindfulnessEducations, commonClasses)
                                }
                            </Box>
                        }
                        {
                            internalContent.plan.coachOtherText?.length > 0 &&
                            <Box mt={isSmallScreen ? "20px" : 4}>
                                {
                                    renderTextSection('Other', internalContent.plan.coachOtherText, commonClasses, false)
                                }
                            </Box>
                        }
                    </Box>
                    {
                        internalContent.plan.labsText?.length > 0 &&
                        <Box mt={isSmallScreen ? "20px" : 4}>
                            {
                                renderTextSection('Labs', internalContent.plan.labsText, commonClasses, false)
                            }
                        </Box>
                    }
                    {
                        internalContent.diagnosis?.length > 0 &&
                        <Box mt={4}>
                            {
                                renderItemsSection('Diagnosis', internalContent.diagnosis.map(x => {
                                    return x.additionalInformation
                                        ? x.code + " " + x.description + ' - ' + x.additionalInformation
                                        : x.code + " " + x.description
                                }), commonClasses, isSmallScreen, true, '', true)
                            }
                        </Box>
                    }
                    {
                        internalContent.chiefComplaint?.length > 0 &&
                        <Box mt={4}>
                            {
                                renderTextSection('Chief Complaint', internalContent.chiefComplaint, commonClasses, isSmallScreen)
                            }
                        </Box>
                    }
                    {
                        internalContent.HPI?.length > 0 &&
                        <Box mt={4}>
                            {
                                renderTextSection('HPI', internalContent.HPI, commonClasses, isSmallScreen)
                            }
                        </Box>
                    }
                    <Box mt={4}>
                        <Box>
                            <span className={commonClasses.secondaryTitle}>
                                PMH
                            </span>
                            <Box py={2}>
                                <Divider />
                            </Box>
                            {internalContent?.pmhVersion
                                ? <>
                                    <FeatureComponent notFeatureFlag={FeatureFlag.RxntIntegration}>
                                        {
                                            internalContent.PMHnew?.allergies?.length > 0 &&
                                            renderItemsSection('Allergies', internalContent.PMHnew.allergies.map(el => itemWithDescription(el)), commonClasses, isSmallScreen, false, '', false,)
                                        }
                                    </FeatureComponent>
                                    <FeatureComponent featureFlag={FeatureFlag.RxntIntegration}>
                                        {
                                            internalContent.PMHnew?.rxntAllergies?.length > 0 &&
                                            renderItemsSection('Allergies', internalContent.PMHnew.rxntAllergies?.map(el => el.description.length ? `${el.name} - ${el.description}` : el.name) ?? [], commonClasses, isSmallScreen, false, '', false,)
                                        }
                                    </FeatureComponent>
                                    {
                                        internalContent.PMHnew?.currentMedicalConditions?.length > 0 &&
                                        <Box mt={isSmallScreen ? "20px" : 4}>
                                            {
                                                renderItemsSection('Problem List', internalContent.PMHnew.currentMedicalConditions.map(el => itemWithDescription(el)), commonClasses, isSmallScreen, false, '', false,)
                                            }
                                        </Box>
                                    }
                                    {
                                        internalContent.PMHnew?.famHX?.length > 0 &&
                                        <Box mt={isSmallScreen ? "20px" : 4}>
                                            {
                                                renderItemsSection('Fam HX', internalContent.PMHnew.famHX.map(el => itemWithDescription(el)), commonClasses, isSmallScreen, false, '', false,)
                                            }
                                        </Box>
                                    }
                                    {
                                        internalContent.PMHnew?.screeningHealthMaintenanceApoe ?
                                            <Box mt={isSmallScreen ? "20px" : 4}>
                                                {
                                                    renderApoeSection(internalContent.PMHnew.screeningHealthMaintenanceApoe)
                                                }
                                            </Box>
                                            : internalContent.PMHnew?.screeningHealthMaintenance?.length > 0 &&
                                            <Box mt={isSmallScreen ? "20px" : 4}>
                                                {
                                                    renderItemsSection('Screening/Health Maintenance', internalContent.PMHnew.screeningHealthMaintenance.filter(el => !el.key.includes('APOE')).map(el => itemWithDescription(el)), commonClasses, isSmallScreen, false, '', false, getApoeInfo(internalContent.PMHnew.screeningHealthMaintenance))
                                                }
                                            </Box>
                                    }
                                    {
                                        internalContent.PMHnew?.surgicalHX?.length > 0 &&
                                        <Box mt={isSmallScreen ? "20px" : 4}>
                                            {
                                                renderItemsSection('Surgical HX', internalContent.PMHnew.surgicalHX.map(el => itemWithDescription(el)), commonClasses, isSmallScreen, false, '', false,)
                                            }
                                        </Box>
                                    }
                                    {
                                        [...internalContent.PMHnew?.socialHX, ...internalContent.PMHnew.lifestyleHX]?.length > 0 &&
                                        <Box mt={isSmallScreen ? "20px" : 4}>
                                            {
                                                renderItemsSection('Social/Lifestyle HX', [...internalContent.PMHnew.socialHX, ...internalContent.PMHnew.lifestyleHX].map(el => itemWithDescription(el)), commonClasses, isSmallScreen, false, '', false,)
                                            }
                                        </Box>
                                    }
                                </>
                                : <>
                                    {
                                        internalContent.PMH?.allergies?.length > 0 &&
                                        <Box>
                                            {
                                                renderItemsSection('Allergies', internalContent.PMH.allergies, commonClasses, isSmallScreen, false)
                                            }
                                        </Box>
                                    }
                                    {
                                        internalContent.PMH?.currentMedicalConditions?.length > 0 &&
                                        <Box mt={isSmallScreen ? "20px" : 4}>
                                            {
                                                renderItemsSection('Problem List', internalContent.PMH.currentMedicalConditions, commonClasses, isSmallScreen, false)
                                            }
                                        </Box>
                                    }
                                    {
                                        internalContent.PMH?.famHX?.length > 0 &&
                                        <Box mt={isSmallScreen ? "20px" : 4}>
                                            {
                                                renderItemsSection('Fam HX', internalContent.PMH.famHX, commonClasses, isSmallScreen, false)
                                            }
                                        </Box>
                                    }
                                    {
                                        internalContent.PMH?.screeningHealthMaintenance?.length > 0 &&
                                        <Box mt={isSmallScreen ? "20px" : 4}>
                                            {
                                                renderItemsSection('Screening/Health Maintenance', internalContent.PMH.screeningHealthMaintenance, commonClasses, isSmallScreen, false)
                                            }
                                        </Box>
                                    }
                                    {
                                        internalContent.PMH?.surgicalHX?.length > 0 &&
                                        <Box mt={isSmallScreen ? "20px" : 4}>
                                            {
                                                renderItemsSection('Surgical HX', internalContent.PMH.surgicalHX, commonClasses, isSmallScreen, false)
                                            }
                                        </Box>
                                    }
                                    {
                                        internalContent.PMH?.socialLifestyleHX?.length > 0 &&
                                        <Box mt={isSmallScreen ? "20px" : 4}>
                                            {
                                                renderItemsSection('Social/Lifestyle HX', internalContent.PMH.socialLifestyleHX, commonClasses, isSmallScreen, false)
                                            }
                                        </Box>
                                    }
                                </>
                            }
                        </Box>
                    </Box>
                    {
                        internalContent?.ROS?.rosEnabled &&
                        <Box mt={4}>
                            {
                                renderItemsSection('ROS', internalContent.ROS.rosValues, commonClasses, isSmallScreen, true, internalContent.ROS.rosValues.length > 0 ? 'All systems reviewed and negative except:' : "All systems reviewed and negative")
                            }
                        </Box>
                    }
                    {
                        (internalContent.medicationsSupplements.supplements.filter(x => !x.isInCurrent).length > 0 ||
                            internalContent.medicationsSupplements.medications.filter(x => !x.isInCurrent).length > 0 ||
                            internalContent.medicationsSupplements.rxntMedications?.filter(x => !x.isInCurrent).length > 0 ||
                            internalContent.medicationsSupplements.fullscriptSupplements?.length > 0) &&
                        <Box mt={4}>
                            {
                                renderMedicationsAndSupplementsToStart(internalContent.medicationsSupplements, commonClasses, flags)
                            }
                        </Box>
                    }
                    {
                        (internalContent.medicationsSupplements.supplements.filter(x => !x.isInCurrent).length <= 0 &&
                            internalContent.medicationsSupplements.medications.filter(x => !x.isInCurrent).length <= 0 &&
                            internalContent.medicationsSupplements.rxntMedications?.filter(x => !x.isInCurrent).length <= 0 &&
                            internalContent.medicationsSupplements.fullscriptSupplements?.length <= 0) &&
                        <Box mt={4}>
                            {
                                renderEmptyMedicationsAndSupplementsToStart(commonClasses)
                            }
                        </Box>
                    }
                    {
                        (internalContent.medicationsSupplements.supplements.filter(x => x.isStopped).length > 0 ||
                            internalContent.medicationsSupplements.rxntMedications?.filter(x => x.isStopped).length > 0 ||
                            internalContent.medicationsSupplements.medications.filter(x => x.isStopped).length > 0) &&
                        <Box mt={4}>
                            {
                                renderMedicationsAndSupplementsToStop(internalContent.medicationsSupplements, commonClasses, flags)
                            }
                        </Box>
                    }
                    {
                        (internalContent.medicationsSupplements.supplements.filter(x => x.isStopped).length <= 0 &&
                            internalContent.medicationsSupplements.rxntMedications?.filter(x => x.isStopped).length <= 0 &&
                            internalContent.medicationsSupplements.medications.filter(x => x.isStopped).length <= 0) &&
                        <Box mt={4}>
                            {
                                renderEmptyMedicationsAndSupplementsToStop(commonClasses)
                            }
                        </Box>
                    }
                    <Box mt={4}>
                        {
                            renderVitals(internalContent.physicalData, commonClasses, isSmallScreen)
                        }
                    </Box>
                    {
                        internalContent.mdmData.selectedCodeId &&
                        <Box mt={4}>
                            {
                                renderMdm(internalContent.mdmData, mdmCodes, commonClasses, internalContent.duration?.value)
                            }
                        </Box>
                    }
                    {
                        note.nextCoachAppointmentDate && note.nextProviderAppointmentDate &&
                        <Box pt={4}>
                            {
                                renderNextAppointmentDate(note.nextCoachAppointmentDate, note.nextProviderAppointmentDate, commonClasses, isSmallScreen)
                            }
                        </Box>
                    }
                    {
                        originalContent && internalContent &&
                        (
                            <Box mt={3}>
                                <Box py={2}>
                                    <Divider />
                                </Box>
                                {
                                    isSomeSectionWasUpdated() &&
                                    <Box pb={1} className={commonClasses.textBold}>Amended areas in this note:</Box>
                                }
                                {
                                    IsSectionAmendHistoryAndPhysical(originalContent, internalContent, SectionType.Hpi) &&
                                    <Box>HPI was updated.</Box>
                                }
                                {
                                    IsSectionAmendHistoryAndPhysical(originalContent, internalContent, SectionType.PMH) &&
                                    <Box>PMH was updated.</Box>
                                }
                                {
                                    IsSectionAmendHistoryAndPhysical(originalContent, internalContent, SectionType.Diagnosis) &&
                                    <Box>Diagnosis was updated.</Box>
                                }
                                {
                                    IsSectionAmendHistoryAndPhysical(originalContent, internalContent, SectionType.Physical) &&
                                    <Box>Physical was updated.</Box>
                                }
                                {
                                    IsSectionAmendHistoryAndPhysical(originalContent, internalContent, SectionType.Plan) &&
                                    <Box>Plan was updated.</Box>
                                }
                                {
                                    IsSectionAmendHistoryAndPhysical(originalContent, internalContent, SectionType.MedicationAndSupplements) &&
                                    <Box>Medication And Supplements were updated.</Box>
                                }
                                {
                                    IsSectionAmendHistoryAndPhysical(originalContent, internalContent, SectionType.Mdm) &&
                                    <Box>Mdm data was updated.</Box>
                                }
                                {
                                    IsSectionAmendHistoryAndPhysical(originalContent, internalContent, SectionType.Ros) &&
                                    <Box>ROS was updated.</Box>
                                }
                            </Box>
                        )
                    }
                    <Box mt={4}>
                        <NoteSignatureComponent note={note} noteId={noteId} />
                    </Box>
                </Box>
            </Box>
        )
    }

    return (
        <Box m={5} mt={0} pt={3}>
            <Box display="flex " alignItems="center" justifyContent='space-between'>
                <ButtonGroup size="large" variant="text" color="default" aria-label="large outlined primary button group">
                    <Button
                        id="initial-consult-to-notes"
                        onClick={() => handleGoBack()}
                        startIcon={<ArrowBackIosRoundedIcon />}
                    >
                        {goBackTitle}
                    </Button>
                    <Button disabled>
                        {
                            isContentLoading
                                ? 'Loading . . .'
                                : renderTitle()
                        }
                    </Button>
                    {
                        displaySelectVersion()
                    }
                </ButtonGroup>
                <Box display="flex " alignItems="center">
                    {
                        displayEditButton()
                    }
                    {
                        displayDownloadPdfButton()
                    }
                </Box>
            </Box>

            <Box mt={5}>
                <Box mt={5}>
                    {
                        (isContentLoading || !internalContent?.plan)
                            ? <WildHealthLinearProgress />
                            : renderNoteContent()
                    }
                </Box>
            </Box>
        </Box>
    );
}