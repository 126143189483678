import {Query} from "@datorama/akita";
import {AgreementsState, agreementsStore, AgreementsStore} from "./agreements.store";

/**
 * Provides agreements queries
 */
export class AgreementsQuery extends Query<AgreementsState> {

    confirmations$ = this.select(state => state.confirmations);
    notConfirmedAgreements$ = this.select(state => state.notConfirmedAgreements);
    agreementsChanges$ = this.select(state => state.agreementsChanges);

    constructor(protected store: AgreementsStore) {
        super(store);
    }
}

export const agreementsQuery = new AgreementsQuery(agreementsStore);
