import React from 'react';
import {Box} from '@material-ui/core';
import {PageSliderComponent} from "../../../../common/components/PageSliderComponent";
import {QuestionnaireSurveyResultProps} from "./questionnaireSurveyResultProps";
import {InitialQuestionNames} from "../../../models/questionnaire.models";
import {QuestionnairesResultListComponent} from "../../questionnairesResultListComponent/QuestionnairesResultListComponent";

export const SleepSurveyResult: React.FC<QuestionnaireSurveyResultProps> = (props: QuestionnaireSurveyResultProps) => {
    const {
        result,
        gender,
        questionnaire,
        navigationContext
    } = props;

    return (
        <>
            <QuestionnairesResultListComponent
                gender={gender}
                questionnaire={questionnaire}
                result={result}
                questions={[
                    InitialQuestionNames.SLEEP_QUALITY,
                    InitialQuestionNames.ROUTINE_BEFORE_SLEEP,
                    InitialQuestionNames.START_SLEEP,
                    InitialQuestionNames.STOP_SLEEP,
                    InitialQuestionNames.ALARM_CLOCK,
                    InitialQuestionNames.RESTED_AFTER_SLEEP,
                    InitialQuestionNames.ENERGY_AFTER_SLEEP
                ]}
                title="Sleep:"
            />
            <Box pt={5}>
                <PageSliderComponent context={navigationContext}/>
            </Box>
        </>
    )
}
