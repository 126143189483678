import { patientsMedicationsService } from '../../patientSupplements/services/patientMedications.service'
import { PatientMedicationModel } from '../../patientSupplements/models/patientMedicationModel'
import { medicationsService } from '../../patientSupplements/services/medications.service'
import { HealthSummaryDataModel } from '../models/healthSummary.models'
import { healthSummaryStore, HealthSummaryStore } from '../stores'
import { Observable } from 'rxjs'

export class HealthSummaryMedicationsService {
    public mapKey = 'MEDICATIONS'

    constructor(private healthSummaryStore: HealthSummaryStore) {
    }

    /**
     * Returns patients health summary model
     */
    public get(patientId?: number): void {
        const method = patientId ? medicationsService.get(patientId) : patientsMedicationsService.get()

        method.subscribe((medications) => {
            const medicationsData = medications.map(x => ({ 
                key: `${this.mapKey}-${x.id}`, 
                name: x.name, 
                value: x.dosage}
            ))

            this.healthSummaryStore.sectionLoaded()
            this.healthSummaryStore.clearSection(this.mapKey)
            this.healthSummaryStore.setData(medicationsData)  
        })
    }

    /**
     * Returns patients health summary model
     */
    public create(patientId: number, model: HealthSummaryDataModel): Observable<any> {
        const payload = {name: model.name, dosage: model.value} as PatientMedicationModel

        return medicationsService.create(payload, patientId)
    }

    /**
     * Returns patients health summary model
     */
     public update(patientId: number, model: HealthSummaryDataModel): Observable<any> {
        const id = +model.key.replace(`${this.mapKey}-`, '')

        const payload = {
            id: id,
            name: model.name, 
            dosage: model.value
        } as PatientMedicationModel

        return medicationsService.edit(payload)
    }

    public delete(key: string): void{
        const id = +key.replace(`${this.mapKey}-`, '')
        
        medicationsService.delete(id).subscribe(() => {
            this.healthSummaryStore.delete(key)
        })
    }
}

export const healthSummaryMedicationsService = new HealthSummaryMedicationsService(healthSummaryStore)