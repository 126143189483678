import {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {navigationService} from "../../../../services/navigation.service";
import {AttachmentModel, InternalNoteContent, NoteModel} from '../../models/notes.models';
import {notesService} from '../../services/notes.service';
import {notesQuery} from '../../stores/notes';
import { internalNotePdfService } from '../../services/notes-pdf.service';
import { GetMaximumVersionOfNote } from '../../helpers/noteHelpers';

interface PatientBlankPageState {
    note: NoteModel;
    content: AttachmentModel[];
    internalContent: string;
    isPdfDownloading: boolean;
    noteId: number;
}

export function useFacade(noteId: number): [PatientBlankPageState, () => void, (note: NoteModel) => void] {
    const [state, setState] = useState({
        note: notesQuery.getNote(noteId),
        internalContent: '',
        content: null,
        isPdfDownloading: false,
        noteId: GetMaximumVersionOfNote(notesQuery.getNote(noteId))?.id
    } as PatientBlankPageState);

    const history = useHistory();

    const handleGoBack = () => {
        navigationService.toPatientNotes(history);
    }

    const handleDownloadPdf = (note: NoteModel) => {
        setState(state => ({...state, isPdfDownloading: true}));

        const cb = () => setState({ ...state, isPdfDownloading: false });
        const noteForDownload = GetMaximumVersionOfNote(note);
        notesService.downloadNote(noteForDownload.id, noteForDownload.name, false).subscribe(
            cb,
            () => {
                internalNotePdfService.downloadNotePdf(noteForDownload, state.internalContent, state.content, false, noteForDownload.id, false, false).subscribe(cb, cb);

            }
        )
    }

    /**
     * Load all ongoing cares
     * Manage subscriptions with auto-cleanup
     */
    const useEffectCB = () => {
        notesService.getContentByPatient(state.noteId).subscribe(result =>
            {
                let attachments = result?.attachmentsEmployee?.map((item,index)=>{
                    const file = item as File;
                    return {
                        tempId: Date.now() + index,
                        id: item.attachmentId,
                        file: file,
                        ForDelete: false,
                        noteId: noteId,
                        fileName: item.fileName,
                        fileSize: item.fileSize
                    } as AttachmentModel;
                });
                
                setState(state => ({
                    ...state,
                    content: attachments,
                    internalContent: result.internalContent,
                }))
            }
        );

        if (!state.note) {
            notesService.getByPatient().subscribe();
        }

        return () => {};
    }

    useEffect(useEffectCB, [noteId]);

    return [state, handleGoBack, handleDownloadPdf];
}