import Axios from 'axios-observable';
import { Observable } from 'rxjs';
import {authHeader} from "../../common/helpers/auth-header";
import {snackService} from "../../common/snack/state";
import { PatientMedicationModel } from '../models/patientMedicationModel';
import { patientMedicationsStore, PatientMedicationsStore } from '../stores/patientMedicationsStore/patientMedications.store';

/**
 * Provides method for working with patient supplements
 */
export class MedicationsService {
    constructor(private patientMedicationsStore: PatientMedicationsStore) {
    }

    public get(patientId: number): Observable<PatientMedicationModel[]> {
        const url = `${process.env.REACT_APP_API_URL}Medications?patientId=${patientId}`;
        const config = { headers: authHeader() };

        return new Observable((observer) => {
            Axios.get<PatientMedicationModel[]>(url, config)
                .pipe()
                .subscribe(
                    response => {
                        observer.next(response.data);
                        observer.complete();
                        this.patientMedicationsStore.update({patientMedications: response.data});
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        })
        
    }

    public create(model: PatientMedicationModel, patientId: number, isFromNotes = false): Observable<PatientMedicationModel> {
        const url = `${process.env.REACT_APP_API_URL}Medications?patientId=${patientId}`;
        const config = { headers: authHeader() };

        return new Observable((observer) =>
            Axios.post(url, model, config)
            .pipe()
            .subscribe(
                response => {
                    patientMedicationsStore.create(response.data);
                    !isFromNotes && snackService.success('New Medication Added!');
                    observer.next(response.data);
                    observer.complete();
                },
                error => {
                    snackService.commonErrorHandler(error);
                    observer.error();
                    observer.complete();
                }
            )
        );
    }

    public edit(model: PatientMedicationModel): Observable<PatientMedicationModel> {
        const url = `${process.env.REACT_APP_API_URL}Medications`;
        const config = { headers: authHeader() };

        return new Observable((observer) =>
            Axios.put(url, model, config)
                .pipe()
                .subscribe(
                    response => {
                        snackService.success('Changes Saved!');
                        patientMedicationsStore.edit(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        )
    }

    public delete(id: number, isFromNotes = false): Observable<any> {
        const url = `${process.env.REACT_APP_API_URL}Medications/${id}`;
        const config = { headers: authHeader() };


        return new Observable((observer) =>
            Axios.delete(url, config)
                .pipe()
                .subscribe(
                    response => {
                        !isFromNotes && snackService.deleteSuccess('Medication Was Deleted!');
                        patientMedicationsStore.delete(response.data.id);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        )
    }
}

export const medicationsService = new MedicationsService(patientMedicationsStore);