import {PaymentPriceModel, PaymentPriceType, PaymentStrategy} from "../models/paymentPrice.models";
import { paymentPlansQuery } from "../stores/paymentPlansStore";

export const getCorrespondingPrice = (prices: PaymentPriceModel[], strategy: PaymentStrategy, type: PaymentPriceType, isCouponApplied = false): PaymentPriceModel => {
    const couponCode = paymentPlansQuery.getCouponCode();
    const price = prices
        .find(x =>  x.type === type && x.strategy === strategy && (couponCode && isCouponApplied ? x.paymentCoupon?.code === couponCode : x));

    if (!price && type !== PaymentPriceType.Default) {
        return getCorrespondingPrice(prices, strategy, PaymentPriceType.Default);
    }

    return price;
}