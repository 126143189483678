import React from 'react';
import Linkify from "linkify-react";
import { navigationService } from "../../../services/navigation.service";

const onTapLink = (event: any) => {
  event.preventDefault();
  navigationService.toNewTabFromLinkUrl(event.target.href);
};

export const displayTextWithLink = (text: string, classLink: any) => {
  let formattedMessage: any = text.length && text.match(/[^\r\n]+/g) ? text.match(/[^\r\n]+/g).map((i, index) => <span className={classLink} key={index}>{i}<br /></span>) : text;
  formattedMessage = (
    <Linkify options={{
      attributes: {
        onClick: onTapLink,
      },
      className: classLink,
    }}>
      {formattedMessage}
    </Linkify>
  )
  return formattedMessage;
};

export const displayMessageTextWithLink = (text: string, classLink: any) => {
  let formattedMessage: any = text.length ? <span>{text}</span> : text;
  formattedMessage = (
    <Linkify options={{
      attributes: {
        onClick: onTapLink,
      },
      className: classLink,
    }}>
      {formattedMessage}
    </Linkify>
  )
  return formattedMessage;
};