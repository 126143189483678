import { selectShortcutService } from '../../../healthReport/services/selectShortcut.service'
import { insertString } from '../../helpers/insert-string'
import { UserType } from '../../../auth/models/auth.enums'
import { authQuery } from '../../../auth/stores/auth'
import { useEffect, useState } from 'react'
import { Observable } from 'rxjs'


interface WildHealthAutocompleteComponentState {
    options: string[]
    value: string
}

export function useFacade(
    getAutoComplete: (searchQuery: string) => Observable<string[]>,
    saveChanges: (e: any, value: string) => void,
    initialValue: string):[
        Function,
        Function,
        Function,
        WildHealthAutocompleteComponentState
    ] {

    const [state, setState] = useState({
        value: '',
        options: []
    } as WildHealthAutocompleteComponentState)

    const handleChanges = (value: string) => {
        setState(state => ({ ...state, value: value }))
    }

    const handleKeyDown = (event: any, id: string) => {
        if (event.keyCode === 51 && event.altKey) {
            const userType = authQuery.getType()
            if (UserType.Employee === userType) {
                const value = (document.getElementById(id) as any).value
                const cursorPos = (document.getElementById(id) as any).selectionStart
                setTimeout(() => {
                    selectShortcutService.select().subscribe((data) => {
                        data && handleChanges(insertString(value, data, cursorPos + 1))
                    })
                }, 1)
            }
        }
        if (event.keyCode === 13) {
            handleSubmit(event)
        }

        event.stopPropagation()
    }

    const handleSubmit = (e) => {
        saveChanges(e, state.value)

        setState(state => ({
            ...state,
            value: null,
            options: []
        }))
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (getAutoComplete && state.value) {
                getAutoComplete(state.value).subscribe((options) => {
                    setState(state => ({
                        ...state,
                        options: options
                    }))
                })
            }
        }, 2000)

        return () => clearTimeout(delayDebounceFn)
    }, [state.value, getAutoComplete])

    useEffect(() => {
        if (initialValue) {
            setState(state => ({ ...state, value: initialValue }))
        }
    }, [initialValue])

    return [
        handleKeyDown,
        handleChanges,
        handleSubmit,
        state
    ]
}