import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        list: {
            fontSize: 14,
            borderRadius: 8,
            width: '100%',
            backgroundColor: theme.palette.background.paper,
        },
        iconButton: {
            minWidth: 24,
        },
        title: {
            fontSize: 20,
            fontWeight: 600,
            fontFamily: "Poppins",
            color: colors.black,
        },
        dismissAll: {
            fontSize: 14,
            color: colors.primaryV,
            fontWeight: 500,
            textTransform: 'capitalize',
        },
        expandIcon: {
            fill: colors.primaryV,
            width: 18,
        },
        viewMoreItem: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        listItem: {
            borderBottom: `1px solid ${colors.stroke}`,
            '&:hover + .group-item': {
                visibility: 'visible'
            }
        },
        empty: {
            borderRadius: 8,
            width: '100%',
            background: colors.white,
            padding: 14,
            fontSize: 14
        },
        iconType: {
            padding: 4,
            marginRight: 8,
            width: 30,
            maxWidth: 30,
            height: 30,
            maxHeight: 30,
            borderRadius: 4,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center', 
        },
        dismissAction: {
            visibility: 'hidden',
            '&:hover': {
                visibility: 'visible'
            }
        },
        notificationToolTipText: {
            fontFamily: 'Be Vietnam Pro',
            fontSize: 12,
            fontWeight: 400,
            color: colors.white,
        },
        customWidth: {
            maxWidth: 'none',
            backgroundColor: colors.navy,
            padding: 8,
        },
        tooltipPlacement: {
            margin: '8px 0',
        },
    }),
);
