import { PatientJourneyRewardModel, TodoPatientJourneyTaskModel, CompletedPatientJourneyTaskModel } from "../models/patient.model";
import { patientsService } from "../services/patients.service";
import { navigationService } from "../../../services/navigation.service";
import { PlatformName, Track } from "../../tracking/tracker";
import { labOrdersService } from "../../orders/services/labOrders.service";
import { patientStore } from "../stores/patientsStore";

const cb = (title: string) => {
    Track("reward_downloaded", {
        reward_name: title,
        platform: PlatformName(),
    });
    patientStore.toggleDownloadRewardTask(false);
}

const requistionCtaHandler = (title: string, fileName: string) => {
    labOrdersService.getRequisitionByPatient().subscribe(
        (blob) => {
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', fileName);
            link.click();
            cb(title);
        },
        () => patientStore.toggleDownloadRewardTask(false)
    );
}

const basicCtaHandler = (id: number, title: string, fileName: string) => {
    patientsService.downloadFileForTask(id, fileName).subscribe(() => {
        cb(title);
    }, () => patientStore.toggleDownloadRewardTask(false));
}

export const handleCta = (task: PatientJourneyRewardModel | TodoPatientJourneyTaskModel | CompletedPatientJourneyTaskModel) => {
    if (!task.taskCTA) return;
    switch (task.taskCTA.action) {
        case 'follow': return navigationService.toNewTabFromLinkUrl(task.taskCTA.link);
        case 'download':
            let fileName = `${task.taskCTA.title}.pdf`;
            if (task.taskCTA.filePath) {
                const filePathArray = task.taskCTA?.filePath.split('/');
                fileName = filePathArray && filePathArray.length > 0 && filePathArray[filePathArray.length - 1];
            }
            patientStore.toggleDownloadRewardTask(true);
            if (task.taskCTA.link.includes('Requisition')) {
                requistionCtaHandler(task.title, fileName);
            } else {
                basicCtaHandler(task.id, task.title, fileName);
            }
            return;
        default: return;
    }
}