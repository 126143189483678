import {Query} from '@datorama/akita';
import {MeddsSuppsState, meddsSuppsStore, MeddsSuppsStore} from "./meddsSupps.store";

/**
 * Provides coaches queries
 */
export class MeddsSuppsQuery extends Query<MeddsSuppsState> {
    tab$ = this.select(state => state.tab);

    constructor(protected store: MeddsSuppsStore) {
        super(store);
    }
}

export const meddsSuppsQuery = new MeddsSuppsQuery(meddsSuppsStore);