import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            display: 'flex',
            maxWidth: '1444px',
            marginInline: 'auto',
        },
        container: {
            width: '100%',
            height: '100%',
        },
        headContent: {
            marginBottom: '70px',
            maxWidth: 736,
        },
        headerText: {
            fontSize: '36px',
            fontWeight: 500,
            textTransform: 'uppercase',
            color: colors.main,
        },
        videoTextContent: {
            paddingInline: 0,
        },
        watchVideoText: {
            fontSize: '22px',
            fontWeight: 500,
            color: colors.main,
        },
        guideVideo: {
            position: 'relative',
            aspectRatio: '16 / 9',
            maxWidth: '552px',
            marginTop: '20px',
        },
        previewVideo: {
            position: 'relative',
            aspectRatio: '16 / 9',
            width: '100%',
        },
        image: {
            width: "100%",
            objectFit: "fill",
        },
        videoTag: {
            width: '100%'
        },
        videoPlayIcon: {
            display: "flex",
            position: "absolute",
            width: "78px",
            height: "78px",
            transform: "translateX(-50%)",
            left: "50%",
            top: "35%",
            '&:hover': {
                width: "82px",
                height: "82px",
            }
        },
        rightContent: {
            paddingInline: '16px'
        },
        icon: {
            fill: 'currentColor',
            marginRight: '1rem',
        },
        dotIcon: {
            marginRight: '1rem',
            fontSize: '12px',
        },
        videoIcon: {
            fill: colors.main,
        },
        iconBox: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '56px',
            height: '56px',
            background: colors.accent1,
            borderRadius: '40px',
            marginRight: '46px'
        },
        listText: {
            color: '#1A1A1A',
            fontSize: '18px',
            fontWeight: 400,
        },
        emailText: {
            color: '#075dc6',
            fontSize: '18px',
            fontWeight: 400,
        },
        iconBoxDivider: {
            marginLeft: '28px',
            marginTop: '25px',
            marginBottom: '25px',
            paddingLeft: '1px',
            backgroundColor: colors.accent1,
            height: '114px',
        },
        listItem1: {
            marginTop: '74px',
        },
        listItem2: {
            marginTop: '72px'
        },
        listItem3: {
            marginTop: '32px',
        },
        desktopDownloadContent: {
            display: 'block',
        },
        mobileDownloadContent: {
            display: 'none',
        },
        downloadText: {
            fontSize: '22px',
            fontWeight: 500,
            color: colors.darkGray,
        },
        dialogTitle: {
            paddingTop: '5px !important',
            paddingLeft: '10px !important',
            paddingRight: '10px !important',
            paddingBottom: '5px !important'
        },
        dialogContent: {
            paddingLeft: 10,
            paddingRight: 10,
            paddingBottom: 5
        },
        '@media (min-width: 1444px)': {
            root: {
                paddingInline: '9.75rem'
            },
        },
        '@media (min-width: 1280px)': {
            rightContent: {
                paddingLeft: '80px'
            },
        },
        '@media (max-width: 1443px)': {
            listItem3: {
                marginTop: '72px',
            },
        },
        '@media (max-width: 1319px)': {
            listItem3: {
                marginTop: '54px',
            },
        },
        '@media (max-width: 1280px)': {
            listItem3: {
                marginTop: '60px',
            },
        },
        '@media (max-width: 1130px)': {
            listItem3: {
                marginTop: '42px',
            },
        },
        '@media (max-width: 959px)': {
            guideVideo: {
                maxWidth: '100%',
                marginTop: '8px'
            },
            listItem1: {
                marginTop: '64px',
            },
            listItem2: {
                marginTop: '38px'
            },
            listItem3: {
                marginTop: '52px',
            },
            iconBoxDivider: {
                height: '66px',
            },
            rightContent: {
                marginTop: '74px'
            },
            desktopDownloadContent: {
                display: 'none',
            },
            mobileDownloadContent: {
                display: 'block',
            },
        },
        '@media (max-width: 785px)': {
            listItem3: {
                marginTop: '32px',
            },
        },
        '@media (max-width: 599px)': {
            headContent: {
                marginBottom: '32px',
            },
            headerText: {
                fontSize: '22px',
            },
            videoTextContent: {
                paddingInline: '16px',
            },
            watchVideoText: {
                fontSize: '18px',
            },
            videoPlayIcon: {
                width: "53px",
                height: "53px",
                '&:hover': {
                    width: "58px",
                    height: "58px",
                }
            },
            listText: {
                fontSize: 14,
            },
            iconBox: {
                marginRight: '34px',
            },
            iconBoxDivider: {
                height: '99px',
            },
            downloadText: {
                fontSize: '18px',
            },
            listItem3: {
                marginTop: '72px',
            },
        },
        '@media (max-width: 499px)': {
            listItem3: {
                marginTop: '42px',
            },
        },
        '@media (max-width: 414px)': {
            listItem3: {
                marginTop: '24px',
            },
        },
        whForm: {
            position: 'relative',
            paddingTop: 36,
            paddingBottom: 36,
            fontSize: 16,
            fontWeight: 400,
            minHeight: 'calc(100vh - 147px)',
        },
        congratulationSection: {
            marginBottom: '70px',
            maxWidth: 597,
        },
        main: {
            maxWidth: 985,
            width: 'auto',
            margin: '0 auto',
            display: 'flex',
            [theme.breakpoints.down("sm")]: {
                display: 'block',
                width: '100%',
                paddingLeft: 24,
                paddingRight: 24,
            }
        },
        stepContainer: {
            flex: 1,
            paddingRight: 75,
            [theme.breakpoints.down("sm")]: {
                display: 'block',
                paddingRight: 0,
            }
        },
        stepContent: {
            maxWidth: 560,
            minHeight: 210,
            backgroundColor: colors.gray6,
            borderRadius: 24,
            padding: 32,
            display: 'flex',
            [theme.breakpoints.down("sm")]: {
                maxWidth: '100%',
                display: 'block'
            }
        },
        videoSection: {
            width: 350,
            [theme.breakpoints.down("sm")]: {
                width: '100%'
            }
        },
        desktopVideoSection: {
            width: 315,
            [theme.breakpoints.down("sm")]: {
                display: 'none'
            }
        },
        mobileVideoSection: {
            display: 'none',
            [theme.breakpoints.down("sm")]: {
                display: 'block',
                width: '100%',
                textAlign: 'center',
            }
        },
        clarityVideo: {
            position: 'relative',
            aspectRatio: '16 / 9',
            maxWidth: 315,
            borderRadius: 12,
            [theme.breakpoints.down("sm")]: {
                maxWidth: '100%',
            }
        },
        fillOutFormBtn: {
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: colors.navy,
            color: colors.navy,
            background: 'transparent',
            borderRadius: '100px',
            padding: '16px 32px',
            textTransform: 'capitalize',
            '&:hover': {
                background: colors.navy,
                color: colors.white,
            },
        },
        setIconSection: {
            display: 'flex',
            alignItems: 'center',
            marginRight: 40,
            [theme.breakpoints.down("sm")]: {
                justifyContent: 'center',
                marginRight: 0,
                marginBottom: 40,
            }
        },
        circleBg1: {
            width: 70,
            height: 70,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: `${colors.lightMint}80`,
            borderRadius: 100
        },
        circleBg2: {
            width: 60,
            height: 60,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: colors.lightMint,
            borderRadius: 100
        },
        stepIcon: {
            width: 40,
            height: 40
        },
        signSection: {
            textAlign: 'center',
            width: '100%',
            padding: 24,
            position: 'fixed',
            bottom: 50,
            backgroundColor: colors.white,
            boxShadow: '0px -2px 13px rgba(177, 189, 196, 0.86)'
        },
        playIcon: {
            fill: colors.mint,
            display: "flex",
            position: "absolute",
            width: "80px",
            height: "80px",
            transform: "translateX(-50%)",
            left: "50%",
            top: "35%",
            cursor: 'pointer'
        },
    })
);
