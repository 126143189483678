import React from "react";
import { LabRangeModel, InputModel } from "../models/input.models";
import { LabInputsGraphComponent } from "../components/labInputsGraphComponent/LabInputsGraphComponent";
import { renderEmptyValue } from "../../common/helpers/empty-value";
import { sortLabInputValues } from "./sort-lab-input-values";

export const renderLabValuesGraph = (
  values: InputModel[],
  range?: LabRangeModel,
  height: number = 50,
  width: number = 165,
  renderRange = false
) => {
  const initializedValues = values
    .filter((x) => x.isInitialized)
    .sort(sortLabInputValues);

  if (initializedValues.length < 2) {
    return renderEmptyValue("Insufficient data for trending");
  }

  return (
    <LabInputsGraphComponent
      data={initializedValues.map((x) => x.value)}
      renderRange={renderRange}
      minValue={range?.from}
      maxValue={range?.to}
      height={height}
      width={width}
    />
  );
};
