import Axios from "axios-observable";
import { Observable } from "rxjs";
import { authHeader } from "../../common/helpers/auth-header";
import { snackService } from "../../common/snack/state";
import { HealthReportTemplatesStore, healthReportTemplatesStore } from "../stores/healthReportTemplatesStore";
import { ReportTemplateModel, ReportType } from "../models/reportTemplate.models";

export class HealthReportTemplatesServices {
    private url = `${process.env.REACT_APP_API_URL}ReportTemplates`;

    constructor(private healthReportTemplatesStore: HealthReportTemplatesStore) {
    }

    /**
     * Returns All health report templates
     */
    public getHealthReportTemplates(type: ReportType): Observable<ReportTemplateModel[]> {
        const config = {
            headers: authHeader()
        };
        return new Observable<ReportTemplateModel[]>(observer => {
            Axios.get<ReportTemplateModel[]>(`${this.url}/${type}`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.healthReportTemplatesStore.update({ reportTemplates: response.data })
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getById(templateId: number) {
        const config = {
            headers: authHeader()
        };
        return new Observable<ReportTemplateModel>(observer => {
            Axios.get<ReportTemplateModel>(`${this.url}/template/${templateId}`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.healthReportTemplatesStore.updateTemplate(response.data)
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    /**
     * Create Template
     * @param model
     */
    public createReportTemplate(model: ReportTemplateModel) {
        const config = {
            headers: authHeader()
        };

        return new Observable<ReportTemplateModel>((observer) =>
            Axios.post<ReportTemplateModel>(`${this.url}`, model, config)
                .pipe()
                .subscribe(
                    response => {
                        this.healthReportTemplatesStore.addTemplate(response.data);
                        snackService.success('Report Template successfully created');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    /**
     * Clone Report Template by Id
     * @param model
     */
    public cloneReportTemplate(id: number) {
        const config = {
            headers: authHeader()
        };

        return new Observable<ReportTemplateModel>((observer) =>
            Axios.post<ReportTemplateModel>(`${this.url}/CloneTemplate/${id}`, {}, config)
                .pipe()
                .subscribe(
                    response => {
                        this.healthReportTemplatesStore.addTemplate(response.data);
                        snackService.success('Report Template successfully cloned');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    /**
     * Update Template
     * @param model
     */
    public updateReportTemplate(model: ReportTemplateModel) {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.put(`${this.url}`, model, config)
                .pipe()
                .subscribe(
                    response => {
                        this.healthReportTemplatesStore.updateTemplate(response.data);
                        snackService.success('Report Template successfully updated');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    public deleteReportTemplate(id: number) {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.delete(`${this.url}/${id}`, config)
                .pipe()
                .subscribe(
                    () => {
                        this.healthReportTemplatesStore.removeTemplate(id);
                        snackService.success('Report Template successfully deleted');
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }
}

export const healthReportTemplatesService = new HealthReportTemplatesServices(healthReportTemplatesStore);
