import { MessageGroupModel } from "../models/message.models";
import {
    ConversationAuthorModel, ConversationModelBaseView, ConversationModelBaseViewForAiDemo,
    EmployeeConversationParticipantModel,
    PatientConversationParticipantModel
} from "../models/conversation.models";
import { employeeTypesNames } from "../../employee/models/employee.enums";
import { authQuery } from "../../auth/stores/auth";

const getAuthor = (
    sid: string,
    patients: PatientConversationParticipantModel[],
    employees: EmployeeConversationParticipantModel[]
): [string, string, number?, string?] => {
    const patient = patients.find(x => x.vendorExternalIds?.includes(sid));

    if (patient) {
        return [patient.firstName, patient.lastName]
    }

    const employee = employees.find(x => x.vendorExternalIds?.includes(sid));

    if (employee) {
        return [employee.firstName, employee.lastName, employee.employeeId, employeeTypesNames.get(employee.type)]
    }

    return ['Unknown', 'Unknown']
}

export const convertMessagesToGroups = (
    messages: any[],
    author: ConversationAuthorModel,
    patients: PatientConversationParticipantModel[],
    employees: EmployeeConversationParticipantModel[]): MessageGroupModel[] => {

    const uniqueMessages = messages && messages.length ? messages.filter(function (m, pos) {
        return messages.findIndex(x => x.state.sid === m.state.sid) === pos;
    }) : [];

    return uniqueMessages.map((message) => {
        const [firstName, lastName, employeeId, employeeType] = getAuthor(message.participantSid, patients, employees);
        return {
            authorFirstName: firstName,
            authorLastName: lastName,
            employeeId: employeeId,
            employeeType: employeeType,
            isMyMessage: author && message.author === author?.conversationIdentity,
            messages: [
                {
                    originalMessage: message,
                    date: message.dateCreated
                }
            ]
        };
    });
}

export const convertMessagesToGroupsForView = (
    messages: ConversationModelBaseView[],
    authorId: string,
    patients: PatientConversationParticipantModel[],
    employees: EmployeeConversationParticipantModel[]): MessageGroupModel[] => {

    const uniqueMessages = messages && messages.length ? messages.filter(function (m, pos) {
        return messages.findIndex(x => x.sid === m.sid) === pos;
    }) : [];

    return uniqueMessages.map((message) => {
        const [firstName, lastName, employeeId, employeeType] = getAuthor(message.participantSid, patients, employees);
        return {
            authorFirstName: firstName,
            authorLastName: lastName,
            employeeId: employeeId,
            employeeType: employeeType,
            isMyMessage: authorId && message.author === authorId,
            messages: [
                {
                    originalMessage: message,
                    date: message.dateCreated
                }
            ]
        };
    });
}

export const convertMessagesToGroupsForAiDemo = (messages: ConversationModelBaseViewForAiDemo[]): MessageGroupModel[] => {

    const uniqueMessages = messages && messages.length ? messages : [];

    return uniqueMessages.map((message) => {
        return {
            authorFirstName: message.isAi ? 'AI' : 'Human',
            authorLastName: ' ',
            employeeId: authQuery.getEmployeeId(),
            employeeType: '',
            isMyMessage: !message.isAi,
            messages: [
                {
                    originalMessage: message,
                    date: message.dateCreated
                }
            ]
        };
    });
}

export const displayShortName = (firstName?: string, lastName?: string) => {
    return [(firstName || "").trim().slice(0, 1), (lastName || "").trim().slice(0, 1)]
        .join("");
}