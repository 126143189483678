import React, {FC, Fragment} from 'react';
import { Box, Collapse, ListItem, ListItemText, TextField, FormControl, FormHelperText, Select, MenuItem } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { WildHealthButton } from "../../../../common/components/wildHealthButton/WildHealthButton";
import { useStyles } from "../manageApiResponseComponent.styles";
import commonUseStyles from "../../../../common/styles/common.styles";
import { IntegrationVendor, integrationVendorNames } from '../../../models/integrationVendor.enums';

type EndpointAdminToolProps = {
    id: string
    params: any
    handleChanges: (field: string, value: any) => void,
    handleOpenToggle: () => void
    handleClick: (any) => void
    title: string
    errors: any
    isOpen: boolean
    isLoading: boolean
}


const ViewAllIntegrationIdsComponent: FC<EndpointAdminToolProps> = (props): JSX.Element => {
    const {id, isLoading, title, errors, params, handleOpenToggle, handleClick, handleChanges, isOpen} = props;

    const classes = useStyles();
    const commonClasses = commonUseStyles();

    return (
        <Fragment>
            <ListItem id={id} className={classes.divider} button onClick={handleOpenToggle}>
                <ListItemText primary={<span className="text-medium">{title}</span>}/>
                {isOpen ? <ExpandLess className={classes.activeIcon}/> : <ExpandMore/>}
            </ListItem>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <Box p={2}>
                    <Box>
                        <FormControl
                            variant="outlined"
                            color='primary'
                            required
                            error={!!errors[`vendor`]}
                            size="small"
                            disabled={!isOpen}
                            classes={{ root: classes.root }}
                            fullWidth>
                            <Box className={commonClasses.label} mb={1}>Vendor *</Box>
                            <Select
                                style={{ background: '#FFF' }}
                                classes={{ root: classes.root }}
                                value={params.vendor}
                                error={!!errors[`vendor`]}
                                inputProps={{
                                name: 'Integration vendor',
                                id: 'vendor-label',
                                classes,
                                }}
                                renderValue={() => {
                                    if (params.vendor === null) {
                                        return <Box className={commonClasses.colorMediumGray}>Select</Box>;
                                    }

                                    return integrationVendorNames[params.vendor];
                                }}
                                displayEmpty
                                onChange={(v) => handleChanges("vendor", v.target.value)}
                            >
                                <MenuItem value={IntegrationVendor.OpenPm}>{integrationVendorNames[IntegrationVendor.OpenPm]}</MenuItem>
                                <MenuItem value={IntegrationVendor.Hint}>{integrationVendorNames[IntegrationVendor.Hint]}</MenuItem>
                                <MenuItem value={IntegrationVendor.Stripe}>{integrationVendorNames[IntegrationVendor.Stripe]}</MenuItem>
                                <MenuItem value={IntegrationVendor.Twilio}>{integrationVendorNames[IntegrationVendor.Twilio]}</MenuItem>
                                <MenuItem value={IntegrationVendor.Elation}>{integrationVendorNames[IntegrationVendor.Elation]}</MenuItem>
                                <MenuItem value={IntegrationVendor.Northpass}>{integrationVendorNames[IntegrationVendor.Northpass]}</MenuItem>
                                <MenuItem value={IntegrationVendor.Clarity}>{integrationVendorNames[IntegrationVendor.Clarity]}</MenuItem>
                                <MenuItem value={IntegrationVendor.None}>{integrationVendorNames[IntegrationVendor.None]}</MenuItem>
                            </Select>
                            <FormHelperText>{errors[`vendor`]}</FormHelperText>
                        </FormControl>
                    </Box>
                    <Box my={2}>
                        <Box className={commonClasses.label} mb={1}>VendorId *</Box>
                        <TextField
                            fullWidth
                            size="small"
                            required
                            InputProps={{className: 'input', classes}}
                            id="vendorId"
                            placeholder="Vendor Id"
                            value={params.vendorId}
                            variant="outlined"
                            disabled={!isOpen}
                            error={!!errors['vendorId']}
                            helperText={errors['vendorId']}
                            onChange={(v) => handleChanges(v.target.id, v.target.value)}
                        />
                    </Box>
                    <WildHealthButton
                        fullWidth
                        id="get-all-integration-ids-execute"
                        loading={isLoading}
                        onClick={handleClick}
                    >
                        Execute
                    </WildHealthButton>
                </Box>
            </Collapse>
        </Fragment>
    )

}

export default ViewAllIntegrationIdsComponent;