import {
    Box,
    FormControl,
    FormHelperText,
    Grid, MenuItem,
    Select,
    TextField
} from '@material-ui/core';
import React from 'react';
import { SelectAddOnsCardComponent } from "../../../addons/components/SelectAddOnsCardComponent/SelectAddOnsCardComponent";
import { UpdateAddressForm } from "../../../common/components/updateAddressForm/UpdateAddressForm";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { useFacade } from "./fellowshipCheckoutComponent.hooks";
import { useStyles } from './FellowshipCheckoutComponent.styles';
import { BillingSummaryComponent } from "../../../payment/components/billingSummaryComponent/BillingSummaryComponent";
import { AgreementDialog } from "../../../payment/components/viewAgreementsDialog/ViewAgreementsDialog";
import { PaymentSummaryComponent } from "../../../payment/components/paymentSummaryComponent/PaymentSummaryComponent";
import { ApplyPaymentCouponComponent } from "../../../payment/components/paymentCouponComponent/ApplyPaymentCouponComponent";
import { PaymentCouponAppliedComponent } from "../../../payment/components/paymentCouponComponent/PaymentCouponAppliedComponent";
import { PatientInfoComponent } from "../../../payment/components/patientInfoComponent/PatientInfoComponent";
import { LoginInfoComponent } from "../../../payment/components/loginInfoComponent/LoginInfoComponent";

interface FellowshipCheckoutComponentProps {
    goBack: () => void
}

export const FellowshipCheckoutComponent: React.FC<FellowshipCheckoutComponentProps> = (props: FellowshipCheckoutComponentProps) => {
    const { goBack } = props;
    const [
        {
            paymentPlan,
            paymentPeriod,
            paymentPrice,
            publicKey,
            addOns,
            couponCode,
            isCouponApplied,
            payer,
            errors,
            leadSources,
            selectedLeadSource
        },
        handlePriceSelect,
        handleChanges,
        renderPaymentForm,
        renderRecaptcha,
        handleToggleSelectingAddOn,
        getSelectedAddOns,
        handleCouponChanges,
        applyCoupon,
        handleLeadSourceChanges
    ] = useFacade(goBack);

    const classes = useStyles();

    const displayOtherLeadSource = () => {
        const leadSource = leadSources.find(x => x.id === selectedLeadSource.leadSourceId);
        if (leadSource === undefined || !leadSource.isOther)
            return <></>

        return <Box mt={3}>
            <TextField
                fullWidth
                required
                size="small"
                type="text"
                label="Other Source"
                InputProps={{ className: 'input' }}
                id="selectedLeadSource.otherLeadSource"
                variant="outlined"
                helperText={errors['selectedLeadSource.otherLeadSource']}
                error={!!errors['selectedLeadSource.otherLeadSource']}
                onChange={(v) => {
                    handleLeadSourceChanges(v.target.id, v.target.value)
                }}
            />
        </Box>
    }

    const renderContent = (): JSX.Element => {
        if (!publicKey) {
            return (
                <Box mt={5}>
                    <WildHealthLinearProgress />
                </Box>
            );
        }

        return (
            <Box>
                <Box>
                    <Grid container
                        spacing={5}
                        justify="space-between">

                        <Grid item xs={12} md={7} lg={7}>
                            <Box>
                                <Box display="flex" alignItems="baseline" mt={3}>
                                    <Box>
                                        <h2>Patient Information</h2>
                                    </Box>
                                </Box>
                                <Box mt={3}>
                                    <PatientInfoComponent
                                        payer={payer}
                                        handleChanges={handleChanges}
                                        displayLoginInfo={false}
                                        hiddenFields={[]}
                                        readonlyFields={[]}
                                        errors={errors}
                                    />
                                </Box>
                            </Box>

                            <Box>
                                <Box mr={2} mt={5}>
                                    <h2>Portal Login Information</h2>
                                </Box>
                                <Box>
                                    <LoginInfoComponent handleChanges={handleChanges} errors={errors} />
                                </Box>
                            </Box>

                            <Box>
                                <Box mt={5}>
                                    <h2>Billing Address</h2>
                                </Box>
                                <Box mt={3}>
                                    <UpdateAddressForm
                                        address={payer.billingAddress}
                                        prefix={"billingAddress."}
                                        errors={errors}
                                        handleChanges={handleChanges} />
                                </Box>
                            </Box>

                            {
                                !paymentPlan?.isFounder &&
                                <Box>
                                    <Box mt={5}>
                                        <Box display="flex">
                                            <h2>How did you hear about us?</h2>
                                        </Box>
                                        <Box mt={"30px"}>
                                            <FormControl
                                                variant="outlined"
                                                color='primary'
                                                required
                                                error={!!errors[`selectedLeadSource`]}
                                                size="small" fullWidth>
                                                <Select
                                                    style={{ background: '#FFF' }}
                                                    id="selectedLeadSource.leadSourceId"
                                                    value={selectedLeadSource.leadSourceId}
                                                    error={!!errors[`selectedLeadSource.leadSourceId`]}
                                                    onChange={(v) => handleLeadSourceChanges("selectedLeadSource.leadSourceId", v.target.value.toString())}>
                                                    {
                                                        leadSources.map((source, i) => {
                                                            return <MenuItem key={i} value={source.id}>{source.name}</MenuItem>
                                                        })
                                                    }
                                                </Select>
                                                <FormHelperText>{errors[`selectedLeadSource.leadSourceId`]}</FormHelperText>
                                            </FormControl>
                                        </Box>
                                        {
                                            displayOtherLeadSource()
                                        }
                                    </Box>
                                </Box>
                            }

                            <Box>
                                <Box mt={5}>
                                    <h2>Promotional code</h2>
                                </Box>
                                <Box mt={"30px"}>
                                    {
                                        !isCouponApplied &&
                                        <ApplyPaymentCouponComponent
                                            handleCouponChanges={handleCouponChanges}
                                            applyCoupon={applyCoupon}
                                            couponCode={couponCode} />
                                    }
                                    {
                                        isCouponApplied &&
                                        <PaymentCouponAppliedComponent paymentCoupon={couponCode} />
                                    }
                                </Box>
                            </Box>

                            <Box>
                                <Box mt={5}>
                                    <h2>Billing Summary</h2>
                                </Box>
                                <Box mt={"30px"}>
                                    <BillingSummaryComponent
                                        selectedPlan={paymentPlan}
                                        selectedPeriod={paymentPeriod}
                                        selectedPrice={paymentPrice}
                                        selectedAddOns={getSelectedAddOns()}
                                        hasContainer
                                        hasDiscount
                                    />
                                </Box>
                            </Box>

                            <Box>
                                <Box mt={5}>
                                    <h2>Payment Information</h2>
                                </Box>
                                <Box mt={"30px"}>
                                    {renderPaymentForm()}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={5} lg={5}>
                            <Box mt={3}>
                                <PaymentSummaryComponent
                                    selectedPlan={paymentPlan}
                                    selectedPeriod={paymentPeriod}
                                    selectedPrice={paymentPrice}
                                    handlePriceSelect={handlePriceSelect} />
                            </Box>
                            <Box mt={3}>
                                {
                                    !paymentPlan.isTrial &&
                                    <SelectAddOnsCardComponent
                                        items={addOns}
                                        handleSelectingItem={handleToggleSelectingAddOn}
                                    />
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                {renderRecaptcha()}

                <AgreementDialog />
            </Box>
        );
    }

    return (
        <Box className={classes.container}>
            {renderContent()}
        </Box>
    )
}