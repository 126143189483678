import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { onEmit } from "../../../common/helpers/on-emit";
import {
  ConversationAuthorModel,
  PatientConversationModel,
  StartPatientSupportConversation,
} from "../../models/conversation.models";
import {
  ConversationSubject,
  supportConversationSubjects,
} from "../../models/conversationSubject.models";
import { conversationsService } from "../../services/conversations.service";
import { patientConversationsQuery } from "../../stores/patientConversationsStore/patientConversations.query";
import { patientConversationsStore } from "../../stores/patientConversationsStore/patientConversations.store";
import { employeeConversationsStore } from "../../stores/employeeConversationsStore/employeeConversations.store";

export enum FullStaffChatComponentView {
  NewTicket,
  SelectedConversation,
}

interface FullStaffChatComponentState {
  isSideAreaOpen: boolean;
  isLoading: boolean;
  creatingTicket: boolean;
  isUpdateMessageHistory: boolean;
  view: FullStaffChatComponentView;
  author: ConversationAuthorModel;
  conversationProxies: any[];
  conversations: PatientConversationModel[];
  targetConversation: PatientConversationModel;
  showHistoryComponent: boolean;
}

let stateContext: FullStaffChatComponentState = null;

export function useFacade(): [
  FullStaffChatComponentState,
  (open: boolean) => void,
  () => void,
  (subject: ConversationSubject) => void,
  (conversation: PatientConversationModel) => void,
  (status: boolean) => void
] {
  const [state, setState] = useState({
    isSideAreaOpen: false,
    isLoading: true,
    creatingTicket: false,
    isUpdateMessageHistory: false,
    view: FullStaffChatComponentView.NewTicket,
    author: null,
    targetConversation: null,
    conversationProxies: [],
    conversations: [],
    showHistoryComponent: false,
  } as FullStaffChatComponentState);

  stateContext = state;

  const handleToggleSideArea = (open: boolean) => {
    setState({ ...state, isSideAreaOpen: open });
  };

  const handleShowHistoryComponent = (status: boolean) => {
    setState({
      ...state,
      showHistoryComponent: status,
      view: FullStaffChatComponentView.NewTicket,
    });
  };

  const handleStartConversation = (subject: ConversationSubject) => {
    const model: StartPatientSupportConversation = {
      subject: supportConversationSubjects.get(subject),
    };

    setState((state) => ({ ...state, creatingTicket: true }));
    employeeConversationsStore.resetAttachments();
    employeeConversationsStore.resetAttachmentsView();
    conversationsService
      .startPatientSupportConversations(model)
      .subscribe((conversation) => {
        patientConversationsStore.setTargetConversation(conversation);

        setState((state) => ({
          ...state,
          view: FullStaffChatComponentView.SelectedConversation,
          creatingTicket: false,
          isUpdateMessageHistory: !state.isUpdateMessageHistory,
        }));
      });
  };

  const handleCreateNewTicket = () => {
    patientConversationsStore.setTargetConversation(null);
  };

  const handleSelectConversation = (conversation: PatientConversationModel) => {
    patientConversationsStore.setTargetConversation(conversation);
    setTimeout(() => {
      employeeConversationsStore.resetAttachments();
      employeeConversationsStore.resetAttachmentsView();
    }, 1);

    setState(state => ({
      ...state,
      view: FullStaffChatComponentView.SelectedConversation,
    }));
  };

  useEffect(() => {
    const subscriptions: Subscription[] = [
      onEmit<PatientConversationModel>(patientConversationsQuery.targetConversation$, conversation => {
        if (conversation) {
          if (!conversation.proxy) {
            conversation.proxy = stateContext.conversationProxies.find(proxy => proxy.sid === conversation.vendorExternalId);
          }
          setState(state => ({ ...state, targetConversation: conversation, view: FullStaffChatComponentView.SelectedConversation }));
        } else {
          setState(state => ({ ...state, view: FullStaffChatComponentView.NewTicket }));
        }
      }),
      onEmit<ConversationAuthorModel>(patientConversationsQuery.author$, author =>
        setState(state => ({ ...state, author: author }))
      ),
      onEmit<PatientConversationModel[]>(patientConversationsQuery.supportConversations$, conversations =>
        setState(state => ({ ...state, conversations }))
      ),
      onEmit<boolean>(patientConversationsQuery.isLoading$, isLoading => {
        setState(state => ({... state, isLoading: isLoading}))
      })
    ];
    employeeConversationsStore.resetAttachments();
    employeeConversationsStore.resetAttachmentsView();

    return () => {
      subscriptions.map(it => it.unsubscribe())
    };
  }, [state.isUpdateMessageHistory]);

  return [state, handleToggleSideArea, handleCreateNewTicket, handleStartConversation, handleSelectConversation, handleShowHistoryComponent];
}