import React from "react";
import { Box, Divider, CircularProgress } from "@material-ui/core";
import { ConversationAuthorModel, EmployeeConversationModel } from "../../models/conversation.models";
import { useFacade } from "./stickyChatComponent.hooks";
import { SendMessageCompactComponent } from "../sendMessageCompactComponent/SendMessageCompactComponent";
import Dropzone from "react-dropzone";
import { ConversationState } from "../../models/conversationState.models";
import { EditScheduledMessage } from "../editScheduledMessage/EditScheduledMessage";
import { ScheduleMessageDialogComponent } from "../scheduleMessageDialogComponent/ScheduleMessageDialogComponent";
import { CloudUploadOutlined } from "@material-ui/icons";
import clsx from "clsx";
import { useStyles } from "./stickyChatComponent.style";
import { useDropStyles } from "../conversationComponent/conversationComponent.styles";
import { convertMessagesToGroups } from "../../helpers/messages.converter";
import { ConversationViewComponent } from "../conversationViewComponent/ConversationViewComponent";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import commonUseStyles from "../../../common/styles/common.styles";
import { colors } from '../../../common/constants/colors';
import { ThreadComponent } from "../threadComponent/ThreadComponent";
import { ActionButton } from "../sendMessageComponentMobile/sendMessageComponentMobile.styles";
import { ReactComponent as AttachIcon } from '@img/icons/Attach.svg';
import { EditAiMessage } from "../editAiMessage/EditAiMessage";
import { authQuery } from "../../../auth/stores/auth";
import { isProviderRole } from "../../../common/constants/roles";

interface StickyChatComponentProps {
    conversation: EmployeeConversationModel;
    author: ConversationAuthorModel;
}

export const StickyChatComponent: React.FC<StickyChatComponentProps> = (props: StickyChatComponentProps) => {
    const {
        conversation,
        author
    } = props;

    const [
        {
            isLoadingMessages,
            isScheduleDialogOpen,
            scheduledMessageForEditing,
            scheduledAttachmentsForEditing,
            toSendAttachments,
            messages,
            messagesView,
            messagesViewLoading,
            emptyMessage,
            scrollToIndex,
            lastReadMessageIndex,
            aiMessageForEditing,
            detailForAiMessage,
            isJoining
        },
        handleSendMessage,
        handleDropAttachment,
        handleRemoveAttachment,
        handleOpenScheduleDialog,
        handleToggleScheduleDialog,
        handleScheduleMessage,
        handleEndEditScheduledMessage,
        handleSetMessageUnread,
        handleUpdateInteraction,
        handleEndEditAiMessage,
        handleEditInteraction,
        handleSendMessageForAiDemo,
        handleJoinThread
    ] = useFacade(conversation, author);

    const classes = useStyles();
    const dropClasses = useDropStyles();
    const commonClasses = commonUseStyles();
    const isScheduledMessageEditing = Boolean(scheduledMessageForEditing);
    const isAiMessageForEditing = Boolean(aiMessageForEditing);

    const messageGroups = conversation
        ? convertMessagesToGroups(messages, author, conversation.patients, conversation.employees)
        : [];

    const fileTypes = [
        "application/pdf",
        'image/jpeg',
        'image/png',
    ];

    if (!conversation?.proxy) {
        return (
            <>
                <Box>
                    {
                        messagesViewLoading
                            ? (
                                <Box height={395} style={{ background: colors.bg }}>
                                    <WildHealthLinearProgress />
                                </Box>
                            ) : (
                                <Box>
                                    <Box className={classes.container}>
                                        <ConversationViewComponent
                                            messages={messagesView}
                                            conversation={conversation}
                                            background={colors.bg}
                                            isCompact={true}
                                        />
                                    </Box>
                                    <Box className={classes.viewOnlyMessage}>
                                        <Box px={3} textAlign="center">
                                            <span className={commonClasses.colorGray1}>
                                                You are not able to respond, as you are not a member of this thread.
                                            </span>
                                        </Box>
                                        {isProviderRole(authQuery.getRoleId()) && (
                                            <Box px={3} mt={0.5} display="flex" alignItems="center" justifyContent="center" onClick={handleJoinThread}>
                                                {isJoining ? <CircularProgress size={24} className="wh-tw-text-primaryV" /> : <span className="wh-tw-text-primaryV wh-tw-cursor-pointer">
                                                    Join this thread
                                                </span>}
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            )
                    }
                </Box>
            </>
        )
    }

    return (
        <Dropzone
            onDrop={handleDropAttachment}
            accept={fileTypes}
        >
            {
                ({ getRootProps, getInputProps, isDragActive }) => {
                    const rootProps = getRootProps();
                    const handleAttach = rootProps['onClick'];
                    delete rootProps['onClick']

                    const sendMessageSection = (
                        conversation?.state !== ConversationState.Closed ?
                            <>
                                <Divider className={classes.divider} orientation='horizontal' />

                                <Box className={classes.sendMessage} {...rootProps}>
                                    <Box className={classes.noneOutline} display={isScheduledMessageEditing ? 'block' : 'none'}>
                                        <EditScheduledMessage
                                            message={
                                                isScheduledMessageEditing ? scheduledMessageForEditing.message : ""
                                            }
                                            attachments={isScheduledMessageEditing ? scheduledAttachmentsForEditing : []}
                                            attachmentsNew={toSendAttachments}
                                            handleConfirm={(message, removeAttachmentIds, attachments) => handleOpenScheduleDialog(message, removeAttachmentIds, attachments)}
                                            handleDiscard={() => handleEndEditScheduledMessage()}
                                            handleRemoveAttachmentNew={handleRemoveAttachment}
                                        />
                                        <Box p={1}>
                                            <ActionButton id="send-message-attach" size='medium' onClick={handleAttach}>
                                                <AttachIcon />
                                            </ActionButton>
                                        </Box>
                                    </Box>
                                    <Box className={classes.noneOutline} display={isScheduledMessageEditing || isAiMessageForEditing ? 'none' : 'block'}>
                                        <SendMessageCompactComponent
                                            handleSubmit={(message) => handleSendMessage(message)}
                                            handleRemoveAttachment={handleRemoveAttachment}
                                            handleSchedule={(message) => handleOpenScheduleDialog(message)}
                                            inputProps={getInputProps()}
                                            handleAttach={handleAttach}
                                            attachments={toSendAttachments}
                                            emptyMessage={emptyMessage}
                                            handleTyping={() => { }}
                                            canSchedule
                                            canAttach
                                        />
                                    </Box>
                                    <Box
                                        className={classes.noneOutline}
                                        display={isAiMessageForEditing ? "block" : "none"}
                                    >
                                        <EditAiMessage
                                            message={
                                                isAiMessageForEditing ? detailForAiMessage : ""
                                            }
                                            handleConfirm={(message) => handleEditInteraction(message)}
                                            handleDiscard={() => handleEndEditAiMessage()}
                                        />
                                    </Box>
                                    <ScheduleMessageDialogComponent
                                        isOpen={isScheduleDialogOpen}
                                        handleToggle={() => handleToggleScheduleDialog()}
                                        handleSubmit={(date, time) => handleScheduleMessage(date, time)}
                                    />
                                </Box>
                            </> :
                            <Box className={classes.closedTicket}>
                                This ticket has been closed.
                            </Box>
                    )

                    return (
                        isDragActive ?
                            <div {...rootProps} className={dropClasses.dropContainer}>
                                <CloudUploadOutlined className={dropClasses.dropIcon} />
                                <span className={dropClasses.dropTitle}>Release to Upload</span>
                            </div> :
                            <>
                                <Box
                                    id='thread'
                                    className={clsx(classes.thread, 'flat-scroll')}
                                    height={340}
                                    {...rootProps}
                                >
                                    <ThreadComponent
                                        isLoadingMessages={isLoadingMessages}
                                        messageGroups={messageGroups}
                                        threadStartId={`thread-start-${conversation?.id}`}
                                        background={colors.bg}
                                        isCompact={true}
                                        handleSetMessageUnread = {handleSetMessageUnread}
                                        lastReadMessageIndex={lastReadMessageIndex}
                                        scrollToIndex={scrollToIndex}
                                        handleUpdateInteraction={handleUpdateInteraction}
                                    />
                                </Box>
                                {sendMessageSection}
                            </>
                    )
                }
            }
        </Dropzone>
    )
}