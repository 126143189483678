import {
  Box,
  Button,
  Popover,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  TextField,
  Chip,
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { SearchComponent } from '../../../common/components/searchComponent/SearchComponent';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import {  useFacade } from './manageMessagingComponent.hooks';
import { useStyles } from './manageMessagingComponent.styles';
import { FeatureComponent } from "../../../common/components/featureFlags/FeatureComponent";
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from 'clsx';
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { pageSizes } from "../../../common/pagination/models/page-sizes";
import { PaginationComponent } from "../../../common/pagination/components/paginationComponent/PaginationComponent";
import { WildHealthStatusLabel } from '../../../common/components/wildHealthStatusLabel/WildHealthStatusLabel';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import information from "@img/icons/information.svg";
import { audienceTypeNames, MessageStatus, MessageTypeDeliveryModel, nameMessageStatus, nameMessageType } from '../../models/message.models';
import { EmployeeShortModel } from '../../../employee/models/employee.models';

const filtersPopoverProps: any = {
  id: "notificationsPopover",
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
};

export const ManageMessagingComponent: React.FC = () => {
  const [
    {
      isLoading,
      isSendingMessage,
      messages,
      filtersState,
      filteredTitle,
      totalCount,
      pageSize,
      selectedPage,
      selectedFilter,
      options,
      selectedOption,
      selectedFilterId,
      selectedOptionId,
      title,
      description,
      methodsOfDelivery,
      selectedMethods,
      eligiblePatients,
    },
    handleToggleFilters,
    handleClearFilters,
    handleSearchSubmit,
    getAllAvailablePages,
    handlePageSizeChange,
    handlePageChange,
    handleFilterSelect,
    handleOptionSelect,
    handleTitleChange,
    handleDescriptionChange,
    handleCancel,
    handleSendMessage,
    handleMethodsChange,
  ] = useFacade();

  const classes = useStyles();
  const commonClasses = commonUseStyles();

  const displayName = (employee: EmployeeShortModel): string => {
    return `${employee.firstName ? employee.firstName : ''} ${employee.lastName ? employee.lastName : ''}`;
  }

  const getStatus = (status: MessageStatus) => {
    if (status === MessageStatus.Pending) {
      return <WildHealthStatusLabel color='average1'>{nameMessageStatus.get(status)}</WildHealthStatusLabel>
    }

    if (status === MessageStatus.Delivered) {
      return <WildHealthStatusLabel color='good'>{nameMessageStatus.get(status)}</WildHealthStatusLabel>
    } 

    if (status === MessageStatus.Failed) {
      return <WildHealthStatusLabel color='bad'>{nameMessageStatus.get(status)}</WildHealthStatusLabel>
    }

    return <></>
  }

  const renderTable = () => {
    if (isLoading) {
      return <WildHealthLinearProgress />
    }

    if (!isLoading && (!messages || !messages.length)) {
      return <WildHealthPlaceHolder message="No current records found" />
    }

    return (
      <>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <WildHealthTableCell style={{ width: '40%' }} align="left">
                  Message
                </WildHealthTableCell>
                <WildHealthTableCell style={{ width: '15%' }} align="left">
                  Method of Delivery
                </WildHealthTableCell>
                <WildHealthTableCell style={{ width: '25%' }} align="left">
                  Patient Filter
                </WildHealthTableCell>
                <WildHealthTableCell style={{ width: '10%' }} align="left">
                  Status
                </WildHealthTableCell>
                <WildHealthTableCell style={{ width: '10%' }} align="left">
                  Sender
                </WildHealthTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {messages.map((message, index) => (
                <WildHealthTableRow key={index}>
                  <WildHealthTableCell align="left">
                    <Box className={commonClasses.textBold}>{message?.subject}</Box>
                    <Box className={clsx(commonClasses.size12, commonClasses.colorGray1)} >{message?.body}</Box>
                  </WildHealthTableCell>
                  <WildHealthTableCell align="left">
                    {nameMessageType.get(message.type)}
                  </WildHealthTableCell>
                  <WildHealthTableCell align="left">
                    {audienceTypeNames.get(message.audienceType)}
                  </WildHealthTableCell>
                  <WildHealthTableCell align="left">
                    {getStatus(message.status)}
                  </WildHealthTableCell>
                  <WildHealthTableCell align="left">
                    {displayName(message.employee)}
                  </WildHealthTableCell>
                </WildHealthTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box py={1} px={2}>
          <PaginationComponent
            pageSizes={pageSizes}
            pageSize={pageSize}
            selectedPage={selectedPage}
            handlePageSizeChange={handlePageSizeChange}
            totalCount={totalCount}
            availablePages={getAllAvailablePages()}
            handlePageSelect={handlePageChange}
          />
        </Box>
      </>
    )
  }

  const renderCreateMessage = () => {
    return (
      <Box p="30px" className={commonClasses.bgWhiteMain}>
        <Box className={clsx(commonClasses.size20, commonClasses.textMedium, commonClasses.colorBlack)}>Create a message</Box>
        <Box mt="20px" px={19} width={1} display='flex' alignItems='top'>
          <Box mx={1} width={0.5}>
            <Box mb={1}>
              <span className={commonClasses.inputLabel}>Method of Delivery</span>
            </Box>
            <Autocomplete
              size='small'
              disableClearable
              multiple
              options={methodsOfDelivery.filter(x => !selectedMethods.some(t => t.id === x.id))}
              getOptionLabel={x => x.name}
              onChange={(e, values) => handleMethodsChange(values)}
              value={selectedMethods}
              renderTags={(value: MessageTypeDeliveryModel[], getTagProps) =>
                value.map((option: MessageTypeDeliveryModel, index: number) => (
                  <Chip label={option.name} classes={{ root: classes.chip }} deleteIcon={<CloseIcon />} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  InputProps={{ ...params.InputProps, type: 'search' }}
                  placeholder={selectedMethods.length ? '' : 'Select method'}
                />
              )}
            />
          </Box>
          <Box mx={1} width={0.5}>
            <Box mb={1}>
              <span className={commonClasses.inputLabel}>Patient Filter</span>
            </Box>
            <Button
              id="manage-my-patients-filter"
              aria-describedby={filtersPopoverProps.id}
              onClick={(event) => handleToggleFilters(event)}
              startIcon={<FilterListIcon />}
              className={classes.whiteButton}
            >
              {!filteredTitle ? 'Filter' : filteredTitle}
            </Button>
            <Box mt={1} height='20px'>{eligiblePatients !== null && <span className={commonClasses.inputLabel}> {eligiblePatients} eligible patients</span>}</Box>
            <Popover
              id={filtersPopoverProps.id}
              anchorEl={filtersState.anchorEl}
              onClose={handleToggleFilters}
              open={filtersState.isOpen}
              anchorOrigin={filtersPopoverProps.anchorOrigin}
              transformOrigin={filtersPopoverProps.transformOrigin}
            >
              <Box py={2} pl={2} display="flex" alignItems="center">
                <Box mr={2}>
                  <FormControl
                    color='primary'
                    variant="outlined"
                    size="small"
                    fullWidth
                    classes={{ root: classes.root }}
                  >
                    <Select
                      classes={{ root: classes.root }}
                      value={selectedFilterId}
                      inputProps={{
                        classes,
                      }}
                      renderValue={() => {
                        if (selectedFilterId === null) {
                          return "-";
                        }

                        return <Box className={classes.selectInput}>{selectedFilter.title}</Box>;
                      }}
                      displayEmpty
                      onChange={(event) => handleFilterSelect(event.target.value as string)}
                    >
                      {
                        filtersState.filters.map((filter, index) =>
                          <MenuItem key={index} value={filter.id}>
                            <Box display="flex" alignItems="center" justifyContent="space-between" width={1}>
                              <Box className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorBlack)}>{filter.title}</Box>
                              {selectedFilterId === filter.id && <CheckIcon className={classes.icon} />}
                            </Box>
                          </MenuItem>
                        )
                      }
                    </Select>
                  </FormControl>
                </Box>
                <Box ml={1}>
                  <FormControl
                    color='primary'
                    variant="outlined"
                    size="small"
                    fullWidth
                    classes={{ root: classes.root }}
                  >
                    <Select
                      classes={{ root: classes.root }}
                      value={selectedOptionId}
                      inputProps={{
                        classes,
                      }}
                      renderValue={() => {
                        if (selectedOptionId === null) {
                          return "-";
                        }

                        return <Box>{selectedOption.title}</Box>;
                      }}
                      displayEmpty
                      disabled={!options.length}
                      onChange={(event) => handleOptionSelect(event.target.value as number)}
                    >
                      {
                        options.map((option, index) =>
                          <MenuItem key={index} value={option.optionId}>
                            <Box display="flex" alignItems="center" justifyContent="space-between" width={1}>
                              <Box className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorBlack)}>{option.title}</Box>
                              {selectedOptionId === option.optionId && <CheckIcon className={classes.icon} />}
                            </Box>
                          </MenuItem>
                        )
                      }
                    </Select>
                  </FormControl>
                </Box>
                <IconButton id="clear-button" onClick={() => handleClearFilters()}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Popover>
          </Box>
        </Box>
        <Box px={20} mt={1}>
          <Box mb={1} display='flex' alignItems='center'>
            <span className={commonClasses.inputLabel}>Title</span>
            <Tooltip placement="top" arrow title={'Only applicable to mobile notification.'}>
              <Box display='flex' ml={1}><img src={information} alt="img" /></Box>
            </Tooltip>
          </Box>
          <TextField
            fullWidth
            rows={1}
            multiline
            type='text'
            size="medium"
            label=''
            variant="outlined"
            placeholder='Title'
            value={title}
            InputProps={{ className: 'input' }}
            onChange={handleTitleChange}
          />
          <Box mt={1}>
            <span className={title.length === 39 ? commonClasses.colorBad : commonClasses.inputLabel}>{39 - title.length} characters remaining</span>
          </Box>
        </Box>
        <Box px={20} mt={2}>
          <Box mt={1}>
            <span className={commonClasses.inputLabel}>Description </span>
          </Box>
          <TextField
            fullWidth
            rows={4}
            maxRows={10}
            multiline
            type='text'
            size="medium"
            label=''
            variant="outlined"
            placeholder='Description'
            value={description}
            InputProps={{ className: 'input' }}
            onChange={handleDescriptionChange}
          />
          <Box mt={1}>
            <span className={description.length === 160 ? commonClasses.colorBad : commonClasses.inputLabel}>{160 - description.length} characters remaining</span>
          </Box>
        </Box>

        <Box mt="30px" display="flex" px={20} alignItems="center" justifyContent="flex-end">
          <Box width="132px" mr="20px">
            <WildHealthButton
              id="cancel-messaging-send-button"
              onClick={() => handleCancel()}
              disabled={isSendingMessage}
              color="secondary"
              fullWidth
              style={{ height: 48 }}
            >
              <Box className={clsx(commonClasses.size16, commonClasses.textMedium)}>Cancel</Box>
            </WildHealthButton>
          </Box>
          <Box width="132px">
            <WildHealthButton
              id="messaging-send-button"
              disabled={!title.length || !description.length || !selectedMethods.length}
              loading={isSendingMessage}
              onClick={() => handleSendMessage()}
              fullWidth
              style={{ height: 48 }}
            >
              <Box className={clsx(commonClasses.size16, commonClasses.textMedium)}>Send</Box>
            </WildHealthButton>
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box py={2} px={10} width={1}>
      <Box my={2} display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <FeatureComponent featureFlag={FeatureFlag.Navigation}>
            <Box className={clsx(commonClasses.size24, commonClasses.textSemiBold, commonClasses.colorBlack)}>{PagesNamesService.messagingAdminPanel}</Box>
          </FeatureComponent>
        </Box>
      </Box>
      {renderCreateMessage()}
      <Box mt={5} mb={2} display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Box className={clsx(commonClasses.size20, commonClasses.textMedium, commonClasses.colorBlack)}>All messages</Box>
        </Box>
        <Box width="310px">
          <SearchComponent handleSearch={handleSearchSubmit} placeholder="Search by Name" />
        </Box>
      </Box>
      <Box className={commonClasses.bgWhiteMain}>
        {renderTable()}
      </Box>
    </Box>
  );
}
