import { makeStyles } from "@material-ui/core";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme) => ({
  root: {
    borderColor: colors.mediumGray,
    borderRadius: 8,
    '& .MuiOutlinedInput-root': {
      borderColor: colors.mediumGray,
      borderRadius: 8,
    },
  },
  subscriptionCard: {
      marginTop: 10,
      borderRadius: 5,
      border: 'none',
      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  },
}));
