import { Box, CircularProgress, Collapse, IconButton, Step, StepLabel, Stepper, Table, TableBody, TableContainer, TableHead, TableRow, useMediaQuery, useTheme, Dialog, DialogContent, } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import moment from 'moment';
import React from 'react';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import { WildHealthHeaderComponent } from '../../../common/components/wildHealthHeaderComponent/WildHealthHeaderComponent';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { CommonPlaceHolder } from '../../../common/components/wildHealthPlaceHolder/CommonPlaceHolder';
import { WildHealthStepIcon } from '../../../common/components/wildHealthStepper/WildHealthStepIcon';
import { WildHealthHorizontalStepperConnector, WildHealthVerticalStepperConnector } from '../../../common/components/wildHealthStepper/WildHealthStepperConnector';
import { WildHealthTableCell } from '../../../common/components/wildHealthTable/WildHealthTableCell';
import { WildHealthTableRow } from '../../../common/components/wildHealthTable/WildHealthTableRow';
import { orderStatusIndexes, orderStatusNames, OrderStatus, shortOrderStatusNames } from '../../models/orders.models';
import { useFacade } from './epigeneticComponent.hooks';
import { useStyles } from './epigeneticComponent.styles';
import { ProtectedElement } from "../../../common/components/ProtectedElement";
import { PermissionType } from "../../../auth/models/auth.enums";
import { toCurrentTimeZone } from '../../../timezones/helpers/timezone';
import commonUseStyles from "../../../common/styles/common.styles";
import Cancel from "@img/icons/cancel.svg";
import clsx from 'clsx';

interface EpigeneticComponentProps {
    patientId?: number;
}

export const EpigeneticComponent: React.FC<EpigeneticComponentProps> = (props: EpigeneticComponentProps) => {
    const { patientId } = props;
    const classes = useStyles();
    const commonClasses = commonUseStyles();

    const [
        {
            isLoadingNewKit,
            isLoading,
            orders,
            selectedOrder,
            isOpened,
        },
        columns,
        handleToggleOrderDetails,
        handleOrderKit,
    ] = useFacade(patientId);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const title = 'Epigenetic Orders';

    const emptyPlaceHolder = () => {
        return <span className="text-accent-secondary">(empty)</span>
    }

    const displayValue = (value: Date) => {
        return value ? moment(toCurrentTimeZone(value)).format("MM/DD/yyyy") : emptyPlaceHolder();
    }

    const mapOrders = () => {
        const rows = [];

        orders.forEach((item, index) => {
            rows.push(
                <WildHealthTableRow key={`epigenetic-row-${index}`}>
                    <WildHealthTableCell align="left">
                        <Box display='flex' alignItems='center'>
                            <IconButton id={`epigenetic-details-${item.id}`} onClick={() => handleToggleOrderDetails(item.id)}>
                                {item.isOpen ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                            <Box ml={1}>
                                {displayValue(item.orderedAt)}
                            </Box>
                        </Box>
                    </WildHealthTableCell>
                    <WildHealthTableCell align="left">
                        <Box px={3} py={1} className={classes.status}>
                            {orderStatusNames.get(item.status)}
                        </Box>
                    </WildHealthTableCell>
                    <WildHealthTableCell align="left">
                        {item.number ?? emptyPlaceHolder()}
                    </WildHealthTableCell>
                    <WildHealthTableCell align="left">
                        {displayValue(item.paidAt)}
                    </WildHealthTableCell>
                </WildHealthTableRow>
            );

            let names = Array.from(shortOrderStatusNames);
            names.push(item.status === OrderStatus.Failed ? [OrderStatus.Failed, 'Failed'] : [OrderStatus.Completed, 'Completed']);

            if (item.isOpen) {
                rows.push(
                    <TableRow key={`epigenetic-row-${index}-1`}>
                        <WildHealthTableCell colSpan={6}>
                            <Collapse in={item.isOpen} timeout="auto" unmountOnExit>
                                <Stepper
                                    alternativeLabel={!isMobile}
                                    connector={isMobile ? <WildHealthVerticalStepperConnector /> : <WildHealthHorizontalStepperConnector />}
                                    activeStep={orderStatusIndexes.get(item.status)}
                                    orientation={isMobile ? "vertical" : 'horizontal'}
                                >
                                    {
                                        names.map((step, index) => {
                                            const date = item.statusLogs.find(i => i.status === step[0])?.date;

                                            return (
                                                <Step key={`epigenetic-step-${index}`}>
                                                    <StepLabel StepIconComponent={WildHealthStepIcon}>
                                                        {step[1]}
                                                        {
                                                            date && <span className={classes.statusDate}>{`(${moment(date).format("MM/DD/yyyy")})`}</span>
                                                        }
                                                    </StepLabel>
                                                </Step>
                                            )
                                        })
                                    }
                                </Stepper>
                            </Collapse>
                        </WildHealthTableCell>
                    </TableRow>
                );
            }
        });
        return rows;
    }

    const renderSmallContent = () => {
        if (isLoading) {
            return (
                <WildHealthLinearProgress />
            )
        }

        if (!orders.length) {
            return <Box height="calc(100vh - 225px)" className={commonClasses.bgWhiteMain}>
                <CommonPlaceHolder message={`No epigenetic orders orders yet.`} />
            </Box>
        }

        let names = Array.from(shortOrderStatusNames);
        names.push(selectedOrder ? selectedOrder.status === OrderStatus.Failed ? [OrderStatus.Failed, 'Failed'] : [OrderStatus.Completed, 'Completed'] : [OrderStatus.Ordered, 'Ordered']);

        return (
            <Box className={classes.content}>
                {
                    orders.map((item, index) =>
                        <Box mb="10px" p={2} className={commonClasses.bgWhiteMain} key={index}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Box display="flex" alignItems="center">
                                    <Box className={clsx(commonClasses.textMedium, commonClasses.size14, commonClasses.colorBlack)} mr={0.5}>
                                        {displayValue(item.orderedAt)}
                                    </Box>
                                    <IconButton id={`epigenetic-details-${item.id}`} onClick={() => handleToggleOrderDetails(item.id)}>
                                        <ChevronRightIcon className={commonClasses.colorBlack} />
                                    </IconButton>
                                </Box>
                                <Box px={2} py={1} className={commonClasses.renderDivider}>
                                    <span className={commonClasses.colorGray1}>{orderStatusNames.get(item.status)}</span>
                                </Box>
                            </Box>
                            <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                                <span className={clsx(commonClasses.textMedium, commonClasses.size14, commonClasses.colorGray2)}>{'Paid Date'}</span>
                                <Box className={clsx(commonClasses.textRegular, commonClasses.size14, commonClasses.colorBlack)}>
                                    {displayValue(item.paidAt)}
                                </Box>
                            </Box>
                        </Box>
                    )
                }

                {selectedOrder && (
                    <Dialog
                        fullScreen={isSmallScreen}
                        className={classes.dialog}
                        onClose={() => handleToggleOrderDetails(selectedOrder.id)}
                        open={isOpened}
                    >
                        <DialogContent className={classes.dialogContent}>
                            <Box position="absolute" right={0} top={0} p={0.5}>
                                <IconButton id="dna-orders-details-close" onClick={() => handleToggleOrderDetails(selectedOrder.id)} >
                                    <img src={Cancel} className={classes.closeIcon} alt='Cancel' />
                                </IconButton>
                            </Box>

                            <Box textAlign="center">
                                <span className={clsx(commonClasses.size20, commonClasses.textMedium, commonClasses.colorBlack)}>
                                    {'Status History'}
                                </span>
                            </Box>
                            <Stepper
                                alternativeLabel={!isMobile}
                                connector={isMobile ? <WildHealthVerticalStepperConnector /> : <WildHealthHorizontalStepperConnector />}
                                activeStep={orderStatusIndexes.get(selectedOrder.status)}
                                orientation={isMobile ? "vertical" : 'horizontal'}
                            >
                                {
                                    names.map((step, index) => {
                                        const date = selectedOrder.statusLogs.find(i => i.status === step[0])?.date;

                                        return (
                                            <Step key={`epigenetic-step-${index}`}>
                                                <StepLabel StepIconComponent={WildHealthStepIcon}>
                                                    {step[1]}
                                                    {
                                                        date && <span className={classes.statusDate}>{`(${moment(date).format("MM/DD/yyyy")})`}</span>
                                                    }
                                                </StepLabel>
                                            </Step>
                                        )
                                    })
                                }
                            </Stepper>
                        </DialogContent>
                    </Dialog>
                )}
            </Box>
        )
    }

    const renderContent = () => {
        if (isLoading) {
            return (
                <WildHealthLinearProgress />
            )
        }

        if (!orders.length) {
            return <CommonPlaceHolder message={`No ${title} yet.`} />
        }

        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <WildHealthTableRow>
                            {
                                columns.map((item, index) =>
                                    <WildHealthTableCell key={`epigenetic-cell-${index}`} align="left">
                                        {item}
                                    </WildHealthTableCell>
                                )
                            }
                        </WildHealthTableRow>
                    </TableHead>
                    <TableBody>
                        {mapOrders()}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const header = (
        <Box display='flex' justifyContent='space-between'>
            <span>
                {title}
            </span>
            <ProtectedElement
                element={
                    <Box>
                        <WildHealthButton id="epigenetic-new-kit" onClick={() => handleOrderKit()} disabled={isLoadingNewKit}>
                            {
                                isLoadingNewKit
                                    ? <CircularProgress size={24} className={commonClasses.colorMain} />
                                    : 'Order New Kit'
                            }
                        </WildHealthButton>
                    </Box>
                }
                permissions={[PermissionType.Coaching, PermissionType.ManagePatients]}
            />
        </Box>
    )

    return isSmallScreen ? renderSmallContent() : (
        <WildHealthHeaderComponent title={header}>
            <Box className={classes.content}>
                {renderContent()}
            </Box>
        </WildHealthHeaderComponent>
    );
}