import { Box } from '@material-ui/core';
import React from 'react';
import { CreateNote } from '../createNote/CreateNote';
import { NoteModel, NotesType } from "../../models/notes.models";
import { useFacade } from './createSimpleComponent.hooks';
import { useStyles } from './createSimpleComponent.styles';

export interface CreateSimpleComponentProps {
    note?: NoteModel,
    patientId: number;
    appointmentId?: number | null;
    type: NotesType;
    handleGoBack: (boolean?) => void;
    goBackTitle: string;
    originalNote?: NoteModel;
}

export const CreateSimpleComponent: React.FC<CreateSimpleComponentProps> = (props: CreateSimpleComponentProps) => {
    const {
        note,
        patientId,
        type,
        handleGoBack,
        goBackTitle,
        originalNote
    } = props;

    const classes = useStyles();

    const [
        {
            isLoading
        },
        noteTitle,
        attachment,
        endOfTemplate,
        noteName,
        titleTextBox,
    ] = useFacade(patientId, note, type, classes, handleGoBack, originalNote);

    return (
        <Box m={5} mt={0} pt={3}>
            <CreateNote
                isLoading={isLoading}
                noteName={noteName}
                handleGoBack={handleGoBack}
                goBackTitle={goBackTitle}
                titleTextBox={titleTextBox}
            >

                <Box mt={5}>
                    {noteTitle}
                    {attachment}
                </Box>
                <Box mt={7}>
                    {endOfTemplate}
                </Box>

            </CreateNote>
        </Box>
    );
}
