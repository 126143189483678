import { Box, Dialog, DialogActions, DialogContent, TextField } from '@material-ui/core';
import React from 'react';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from 'clsx';
import { useStyles } from './SendInviteDialogComponent.styles';
import { useFacade } from './sendInviteDialogComponent.hooks';
import { DialogTitle } from '../../../common/components/dialogTitle/DialogTitle';

interface EditAppointmentDialogComponentProps {
    open: boolean;
    handleClose: () => void;
    appointmentId: number;
}

export const SendInviteDialogComponent: React.FC<EditAppointmentDialogComponentProps> = (props: EditAppointmentDialogComponentProps) => {
    const {
        open,
        handleClose,
        appointmentId
    } = props;

    const [
        state,
        handleEditEmail,
        handleSubmit
    ] = useFacade(appointmentId, handleClose);

    const commonClasses = commonUseStyles();
    const classes = useStyles()

    return (
        <>
            <Dialog
                fullWidth
                maxWidth='sm'
                open={open}
                onClose={handleClose}>

                <DialogTitle
                    id="customized-dialog-title"
                    classes={{ root: classes.dialogTitle }}
                    onClose={() => handleClose()}
                >
                    <span className={clsx(commonClasses.size24, commonClasses.textMedium)}>
                        Send Invite
                    </span>
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    <Box>
                        <Box>
                            <span className={clsx(commonClasses.size14, commonClasses.colorGray1)}>Enter email address invite should be sent to</span>
                        </Box>
                        <Box mt={1}>
                            <TextField
                                size='small'
                                required
                                fullWidth
                                variant="outlined"
                                value={state.email}
                                error={!!state.errors['email']}
                                helperText={state.errors['email']}
                                onChange={(v) => handleEditEmail(v.target.value)}
                            />
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions classes={{ root: classes.dialogActions }}>
                    <WildHealthButton
                        id="send-invite-cancel"
                        size='large'
                        color='tertiary'
                        disabled={state.isLoading}
                        onClick={() => handleClose()}
                    >
                        Cancel
                    </WildHealthButton>
                    <WildHealthButton
                        id="send-invite"
                        size='large'
                        loading={state.isLoading}
                        onClick={() => handleSubmit()}
                    >
                        Send Invite
                    </WildHealthButton>
                </DialogActions>
            </Dialog>
        </>
    );
}