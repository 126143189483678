import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
            [theme.breakpoints.down("xs")]: {
                paddingTop: '55px !important',
                paddingLeft: '0px !important',
                paddingRight: '0px !important',
                paddingBottom: '0px !important',
                '& .MuiDialog-paperFullScreen': {
                    borderRadius: '20px 20px 0 0',
                },
            },
        },
        dialogContent: {
            [theme.breakpoints.down("xs")]: {
                padding: 16,
            },
        },
        root: {
            '& label.Mui-focused': {
                color: colors.main,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: colors.main,
                },
            },
        },
        dialogTitle: {
            display: 'flex',
            justifyContent: "space-between",
            alignItems: "center",
            margin: 0,
            paddingRight: theme.spacing(1),
            paddingLeft: theme.spacing(5),
            paddingTop: theme.spacing(4),
        },
        dialogTitleCloseAction: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        action: {
            color: colors.main,
            paddingRight: theme.spacing(5),
            paddingLeft: theme.spacing(5),
            paddingBottom: theme.spacing(4),
            paddingTop: theme.spacing(2.5),
        },
        select: {
            width: 510,
            margin: 5,
        },
        field: {
            margin: 5,
            [theme.breakpoints.down("sm")]: {
                width: "100%",
            },
        },
        role: {
            width: 140,
        },
        permission: {
            width: 250,
        },
        permissionCheck: {
            color: colors.main,
        },
        sectionTitle: {
            fontFamily: 'Be Vietnam Pro',
            fontSize: 16,
        },
        icon: {
            borderRadius: '50%',
            width: 24,
            height: 24,
            boxShadow: 'inset 0 0 0 1px rgba(24,22,26,.2), inset 0 -1px 0 rgba(24,22,26,.1)',
            backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
            '$root.Mui-focusVisible &': {
                outline: '2px auto rgba(19,124,189,.6)',
                outlineOffset: 2,
            },
            'input:hover ~ &': {
                backgroundColor: '#ebf1f5',
            },
            'input:disabled ~ &': {
                boxShadow: 'none',
                background: 'rgba(206,217,224,.5)',
            },
        },
        checkedIcon: {
            backgroundColor: '#537b98',
            backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
            '&:before': {
                display: 'block',
                width: 24,
                height: 24,
                backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
                content: '""',
            },
            'input:hover ~ &': {
                backgroundColor: colors.main,
            },
        },
    }),
);
