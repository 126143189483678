import { useEffect, useState } from "react";
import { onEmit } from "../../../common/helpers/on-emit";
import { HealthReportModel, RecommendationDisplayModel, RecommendationTypes } from "../../models/healthReport.models";
import { healthReportsQuery } from "../../stores/healthReportsStore";
import { healthReportsStore } from "../../stores/healthReportsStore";
import { Subscription } from "rxjs/internal/Subscription";
import moment from "moment";

interface RecommendationsComponentState {
    sections: { [id: number]: boolean };
    sectionsIsChanged: { [id: number]: boolean };
    isChanged: boolean;
    recommendations: RecommendationDisplayModel[],
    recommendationsPrevious: RecommendationDisplayModel[],
    isSelectedAllNew: boolean,
    isSelectedAllPrev: boolean,
}

export function useFacade(
    patientId: number,
    isPrepareRecommendation: boolean,
): [
        RecommendationsComponentState,
        (section: RecommendationTypes) => void,
        (id: number | string, isSelected: boolean) => void,
        (id: number | string, isSelected: boolean) => void,
        (id: number | string, value: string) => void,
        (type: number) => RecommendationDisplayModel[],
        (type: number) => RecommendationDisplayModel[],
        (type: number, index: number) => void,
        () => void,
        () => void,
        (recommendation: RecommendationDisplayModel) => void,
    ] {
    const [state, setState] = useState(
        {
            sections: {
                [RecommendationTypes.Macronutrient]: false,
                [RecommendationTypes.Methylation]: false,
                [RecommendationTypes.VitaminsAndMicronutrients]: false,
                [RecommendationTypes.KryptoniteFoods]: false,
                [RecommendationTypes.SuperFoods]: false,
                [RecommendationTypes.ExerciseAndRecovery]: false,
                [RecommendationTypes.Longevity]: false,
                [RecommendationTypes.Microbiome]: false,
                [RecommendationTypes.Neurobehavioral]: false,
                [RecommendationTypes.Sleep]: false,
                [RecommendationTypes.Cardiovascular]: false,
                [RecommendationTypes.Dementia]: false,
                [RecommendationTypes.InsulinResistance]: false,
                [RecommendationTypes.Inflammation]: false,
                [RecommendationTypes.Supplements]: false,
            },
            sectionsIsChanged: {
                [RecommendationTypes.Macronutrient]: false,
                [RecommendationTypes.Methylation]: false,
                [RecommendationTypes.VitaminsAndMicronutrients]: false,
                [RecommendationTypes.KryptoniteFoods]: false,
                [RecommendationTypes.SuperFoods]: false,
                [RecommendationTypes.ExerciseAndRecovery]: false,
                [RecommendationTypes.Longevity]: false,
                [RecommendationTypes.Microbiome]: false,
                [RecommendationTypes.Neurobehavioral]: false,
                [RecommendationTypes.Sleep]: false,
                [RecommendationTypes.Cardiovascular]: false,
                [RecommendationTypes.Dementia]: false,
                [RecommendationTypes.InsulinResistance]: false,
                [RecommendationTypes.Inflammation]: false,
                [RecommendationTypes.Supplements]: false,
            },
            isChanged: false,
            recommendations: [],
            recommendationsPrevious: [],
            isSelectedAllNew: true,
            isSelectedAllPrev: false,
        } as RecommendationsComponentState
    );

    const handleExpandSection = (section: RecommendationTypes) => {
        const sections = state.sections;
        sections[section] = !sections[section];
        setState({ ...state, sections: sections });
    }

    const checkSelectedAll = () => {
        const selectedAllNew = !state.recommendations.find(el => el.isSelected === false)
        const selectedAllPrev = !state.recommendationsPrevious.find(el => el.isSelected === false)
        setState({ ...state, isSelectedAllNew: selectedAllNew, isSelectedAllPrev: selectedAllPrev });
    }

    const handleSelectedNew = (id: number | string, isSelected: boolean) => {
        const recommendationForIsSelected = state.recommendations.find(el => el.id === id)

        if(state.recommendationsPrevious.find(el => el.recommendationId === recommendationForIsSelected.recommendationId)) {
            const recommendationPreviousForIsSelected = state.recommendationsPrevious.find(el => el.recommendationId === recommendationForIsSelected.recommendationId)
            recommendationPreviousForIsSelected.isSelected = !isSelected
        }

        recommendationForIsSelected.isSelected = isSelected
        checkSelectedAll()
    }

    const handleSelectedPrevious = (id: number | string, isSelected: boolean) => {
        const recommendationPreviousForIsSelected = state.recommendationsPrevious.find(el => el.id === id)
        if(state.recommendations.find(el => el.recommendationId === recommendationPreviousForIsSelected.recommendationId)) {
            const recommendationForIsSelected = state.recommendations.find(el => el.recommendationId === recommendationPreviousForIsSelected.recommendationId)
            recommendationForIsSelected.isSelected = !isSelected
        }
        recommendationPreviousForIsSelected.isSelected = isSelected
        checkSelectedAll()
    }

    const handleChangesNew = (id: number | string, value: string) => {
        const recommendationForIsSelected = state.recommendations.find(el => el.id === id)
        recommendationForIsSelected.content = value
        setState({ ...state })
    }

    const getRecommendationNew = (type: number): RecommendationDisplayModel[] => {
        return state.recommendations?.filter(el => el.type === type)
    }

    const getRecommendationCurrent = (type: number): RecommendationDisplayModel[] => {
        return state.recommendationsPrevious?.filter(el => el.type === type)
    }

    const handleAddRecommendation = (type: number, index: number) => {
        const newRecommendation = {
            recommendationId: `xxx${index}`,
            baseId: moment().unix() + index,
            id: moment().unix() + index,
            type: type,
            content: '',
            isSelected: true,
        };
        setState({
            ...state,
            recommendations: [
                ...state.recommendations,
                newRecommendation,
            ]
        })
    }

    const handleUpdateRecommendation = (recommendation: RecommendationDisplayModel) => {
        const recommendationForUpdated = state.recommendations.find(el => el.baseId === recommendation.baseId)
        if (recommendationForUpdated) {
            recommendationForUpdated.content = recommendation.content
            setState({ ...state })
        } else {
            const updatedRecommendation = [{...recommendation, isSelected: true}, ...state.recommendations ]
            setState({ ...state, recommendations:updatedRecommendation })
        }
    }

    const prepereRecommendationToSend = () => {
        const selectedRecommendations = state.recommendationsPrevious.filter(el => el.isSelected)
        const selectedRecommendationsPrevious = state.recommendations.filter(el => el.isSelected)

        const recommendationsToSend = [...selectedRecommendations, ...selectedRecommendationsPrevious].map(el => {
            return `${el.recommendationId}`.includes('xxx')
                ? {
                    type: el.type,
                    baseId: el.baseId,
                    content: el.content,
                }
                : {
                    recommendationId: el.recommendationId,
                    baseId: el.baseId,
                    type: el.type,
                    content: el.content,
                }
        })
        healthReportsStore.putRecommendationToSend(recommendationsToSend)
    }

    const handleSelectedAllNew = () => {
        if (!state.isSelectedAllNew) {
            state.recommendations.forEach(el => el.isSelected = !state.isSelectedAllNew)
            state.recommendationsPrevious.forEach(el => el.isSelected = state.isSelectedAllNew)
            setState({ ...state, isSelectedAllNew: true, isSelectedAllPrev: false})
        } else {
            setState({ ...state, isSelectedAllPrev: false, isSelectedAllNew: false })
        }
    }

    const handleSelectedAllPrevious = () => {
        if (!state.isSelectedAllPrev) {
            state.recommendationsPrevious.forEach(el => el.isSelected = !state.isSelectedAllPrev)
            state.recommendations.forEach(el => el.isSelected = state.isSelectedAllPrev)
            setState({ ...state, isSelectedAllPrev: true, isSelectedAllNew: false })
        } else {
            setState({ ...state, isSelectedAllPrev: false, isSelectedAllNew: false })
        }
    }

    useEffect(() => {
        prepereRecommendationToSend()
    }, [isPrepareRecommendation]);


    useEffect(() => {
        const subscriptions: Subscription[] = [

            onEmit<HealthReportModel>(healthReportsQuery.report$, report => {
                setState(state => ({
                    ...state,
                    recommendations: report?.recommendations.map(el => {
                        return {
                            recommendationId: el.recommendationId,
                            baseId: el.baseId,
                            id: el.id,
                            type: el.type,
                            content: el.content,
                            isSelected: true,
                        }
                    }),
                }));
            }),
            onEmit<HealthReportModel>(healthReportsQuery.reportForRecommendation$, report => {
                setState(state => ({
                    ...state,
                    recommendationsPrevious: report?.recommendations.map(el => {
                        return {
                            recommendationId: el.recommendationId,
                            baseId: el.baseId,
                            id: el.id,
                            type: el.type,
                            content: el.content,
                            isSelected: false,
                        }
                    }),
                }));
            }),
        ]
        
        return () => {
            subscriptions.map(i => i.unsubscribe())
        };
    }, [patientId]);

    return [
        state,
        handleExpandSection,
        handleSelectedNew,
        handleSelectedPrevious,
        handleChangesNew,
        getRecommendationNew,
        getRecommendationCurrent,
        handleAddRecommendation,
        handleSelectedAllNew,
        handleSelectedAllPrevious,
        handleUpdateRecommendation
    ];
}
