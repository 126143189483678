import {
    Box,
    Button,
    Grid,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TextField
} from '@material-ui/core';
import { Add, MoreVert } from "@material-ui/icons";
import React from "react";
import { PermissionType, UserType } from "../../../auth/models/auth.enums";
import { isElementEnabled, ProtectedElement } from "../../../common/components/ProtectedElement";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { WildHealthSwitcher } from "../../../common/components/WIldHealthSwitcher";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { useFacade } from "./manageLeadSourcesComponent.hooks";
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";

export const ManageLeadSourcesComponent: React.FC = () => {
    const [
        {
            isLoading,
            leadSources,
            anchorElements,
            creationName,
            errors,
            isCreating
        },
        handleCreate,
        handleDelete,
        handleActivityChange,
        handleCreateChanges,
        handleOpenOptionsMenu,
        handleCloseOptionsMenu
    ] = useFacade();

    if (isLoading) {
        return (
            <Box p={3}>
                <WildHealthLinearProgress />
            </Box>
        )
    }

    const renderTable = () => {
        if (!leadSources || !leadSources.length) {
            return <WildHealthPlaceHolder message="No lead sources" />
        }

        return (
            <>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <WildHealthTableRow>
                                <WildHealthTableCell style={{ width: '35%' }}>
                                    Name
                                </WildHealthTableCell>
                                <WildHealthTableCell style={{ width: '35%' }} align="center">
                                    Show on sign-up page
                                </WildHealthTableCell>
                                <WildHealthTableCell style={{ width: '20%' }} align="center">
                                    Patient Count
                                </WildHealthTableCell>
                                <WildHealthTableCell style={{ width: '10%' }}>
                                    Action
                                </WildHealthTableCell>
                            </WildHealthTableRow>
                        </TableHead>
                        <TableBody>
                            {
                                leadSources.map((source, index) =>
                                    <WildHealthTableRow key={index}>
                                        <WildHealthTableCell>{source.name}</WildHealthTableCell>
                                        <WildHealthTableCell align="center">
                                            {
                                                !source.isOther &&
                                                <Box display="flex" justifyContent="center" alignItems="center">
                                                    <Box>
                                                        <span>OFF</span>
                                                    </Box>
                                                    <Box>
                                                        <WildHealthSwitcher
                                                            name="activityChange"
                                                            checked={source.isActive}
                                                            onChange={() => handleActivityChange(source.id)}
                                                            disabled={!isElementEnabled([PermissionType.PatientAnalytics], UserType.Employee)}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <span>ON</span>
                                                    </Box>
                                                </Box>
                                            }
                                        </WildHealthTableCell>
                                        <WildHealthTableCell align="center">{source.count}</WildHealthTableCell>
                                        <WildHealthTableCell>
                                            <ProtectedElement
                                                element={
                                                    <Button
                                                        id="manage-lead-sources-open-options"
                                                        aria-controls="simple-menu"
                                                        aria-haspopup="true"
                                                        onClick={(e) => handleOpenOptionsMenu(e, index)}
                                                    >
                                                        <MoreVert />
                                                    </Button>
                                                }
                                                permissions={[PermissionType.PatientAnalytics]}
                                                userType={UserType.Employee}
                                            />
                                            <Menu
                                                id="simple-menu"
                                                anchorEl={anchorElements[index]}
                                                open={Boolean(anchorElements[index])}
                                                onClose={() => handleCloseOptionsMenu(index)}
                                            >
                                                <MenuItem id="manage-lead-sources-delete" disabled={source.isOther}
                                                    onClick={() => handleDelete(source.id)}>Delete</MenuItem>
                                            </Menu>
                                        </WildHealthTableCell>

                                    </WildHealthTableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        )
    }

    return (
        <Box p={3}>
            {
                renderTable()
            }
            <Box>
                <ProtectedElement
                    element={
                        <Grid container>
                            <Grid container item xs={6}>
                                <Grid item xs={8}>
                                    <Box m={1} mt={3}>
                                        <TextField
                                            required
                                            fullWidth
                                            type="text"
                                            size="small"
                                            label="Name"
                                            id="creationName"
                                            variant="outlined"
                                            value={creationName}
                                            error={!!errors['creationName']}
                                            InputProps={{ className: 'input' }}
                                            helperText={errors['creationName']}
                                            onChange={(v) => handleCreateChanges(v.target.id, v.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box m={1} mt={3}>
                                        <WildHealthButton id="manage-lead-sources-add" onClick={() => handleCreate()} disabled={isCreating} style={{ height: "40px" }}>
                                            <Box display="flex" alignItems="center">
                                                <Add />
                                                <span>Add Lead Source</span>
                                            </Box>
                                        </WildHealthButton>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    permissions={[PermissionType.PatientAnalytics]}
                    userType={UserType.Employee}
                />
            </Box>
        </Box>
    );
}