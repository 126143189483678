import createAuthRefreshInterceptor, {AxiosAuthRefreshOptions, AxiosAuthRefreshRequestConfig} from 'axios-auth-refresh';
import {authService} from "../../auth/services/auth.service";
import {authQuery} from "../../auth/stores/auth";
import {snackService} from "../../common/snack/state";
import * as Sentry from "@sentry/browser";
import {AxiosError, AxiosInstance, AxiosRequestConfig} from "axios";
import {Severity} from "@sentry/browser";


export function setupTokenExpireLogout(axios: AxiosInstance) 
{
    // see: https://axios-http.com/docs/interceptors
    // The first argument to use() is for 200 range responses.
    // The second argument to use() is for all others.

    // Tokens expire at 3AM EST.
    // If the user sends a request at 8AM, having not logged in again, we need to log them out.

    axios.interceptors.response.use(
        (response) => response,
        async (error) => 
        {
            if(error.response && error.response.status == 401) 
            {
                const authFailedReasonHeader = "x-authfailedreason";

                //We can receive a 401 even if the token is valid.
                //So we don't want to force logout in that scenario.
                const tokenIsExpired = error.response.headers[authFailedReasonHeader] && 
                                       error.response.headers[authFailedReasonHeader] == "expired";

                if(tokenIsExpired) 
                {
                    authService.logout();
                    return Promise.resolve();
                }
            }
            return Promise.reject(error);
        }
    );
}
