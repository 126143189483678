import { Box, Typography, Tooltip, Grid, useTheme, useMediaQuery } from "@material-ui/core";
import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import { HealthScoreCategoriesDisplaytModel } from '../../../healthScore/models/score.model';
import information from "@img/icons/information.svg";
import { useStyles } from "./BiomarkersComponent.style";
import commonUseStyles from '../../../common/styles/common.styles';
import { useFacade } from './biomarkersComponent.hooks';
import clsx from "clsx";
import { WildHealthClickableTooltip } from "../../../common/components/WildHealthClickableTooltip/WildHealthClickableTooltip";

interface BiomarkersComponentProps {
    title: string,
    colors: string[],
    result: number,
    categories: HealthScoreCategoriesDisplaytModel[],
}

export const BiomarkersComponent: React.FC<BiomarkersComponentProps> = (props: BiomarkersComponentProps) => {
    const {
        title,
        result,
        colors,
    } = props;

    const [
        state,
        handleCategoryTooltipToggle,
        handleTooltipToggle,
    ] = useFacade(props.categories);

    const classes = useStyles();
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const customTooltipClasses = {
        tooltip: classes.tooltip,
        arrow: classes.arrow,
        tooltipPlacementTop: classes.tooltipPlacement,
        tooltipPlacementBottom: classes.tooltipPlacement,
    };

    const scores = (scoreVal: number) => {
        const emptyPartScore = 100 - Number(scoreVal)
        return [{
            name: 'score',
            score: Number(scoreVal)
        },
        {
            name: 'empty',
            score: emptyPartScore,
        },]
    }

    return (
        <Box width='100%'>
            <Box display='flex' alignItems='center'>
                <Typography className={classes.title}>
                    {title}
                </Typography>
                {isSmallScreen ? (
                    <Box display="flex" alignItems="center">
                        <WildHealthClickableTooltip
                            tooltipContent={'Health Score updates are not instantaneous, but made at regular intervals.'}
                            isOpened={state.isOpen}
                            handleClose={() => handleTooltipToggle(false)}
                            customClasses={customTooltipClasses}
                        >
                            <img onClick={() => handleTooltipToggle(true)} src={information} alt="img" />
                        </WildHealthClickableTooltip>
                    </Box>
                ) : (
                        <Tooltip placement="top" arrow title={'Health Score updates are not instantaneous, but made at regular intervals.'}>
                            <img src={information} alt="img" />
                        </Tooltip>
                    )}
            </Box>
            <Box mt={isSmallScreen && "30px"} display={isSmallScreen ? 'inherit' : 'flex'} alignItems='center' justifyContent='space-between'>
                {isSmallScreen ? (
                    <Grid container spacing={2}>
                        {
                            state.categories.map((item, index) => {
                                return (
                                    <Grid item xs={6} key={index}>
                                        <Box display='flex' alignItems='center'>
                                            <Box fontWeight='500' className={commonClasses.capitalize}>{item.name}</Box>
                                            <Box ml={1} display='flex' alignItems='center'>
                                                <WildHealthClickableTooltip
                                                    tooltipContent={item.hoverText}
                                                    isOpened={item.isOpened}
                                                    handleClose={() => handleCategoryTooltipToggle(index, false)}
                                                    customClasses={customTooltipClasses}
                                                >
                                                    <img src={information} alt="img" onClick={() => handleCategoryTooltipToggle(index, true)} />
                                                </WildHealthClickableTooltip>
                                            </Box>
                                        </Box>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                ) : (
                        <Box>
                            {
                                state.categories.map((item, index) => {
                                    return (
                                        <Box key={index} display='flex' alignItems='center' pt={1} pb={1}>
                                            <Box ml={6} fontWeight='500' pl={2} className={commonClasses.capitalize}>{item.name}</Box>
                                            <Box ml={1} display='flex' alignItems='center'>
                                                <Tooltip placement="top" arrow title={item.hoverText}>
                                                    <img src={information} alt="img" />
                                                </Tooltip>
                                            </Box>
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                    )}
                <Box mt={isSmallScreen && 3} display="flex" justifyContent="center" position="relative">
                    <PieChart width={284} height={284}>
                        <Pie
                            data={scores(result)}
                            startAngle={90}
                            endAngle={-270}
                            innerRadius={103}
                            outerRadius={130}
                            dataKey="score"
                        >
                            {scores(result).map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                            ))}
                        </Pie>
                    </PieChart>
                    <Box className={classes.scoreSection}>
                        <Box className={clsx(classes.scoreTitle, commonClasses.colorGray1)}>Score</Box>
                        <Box className={clsx(classes.scoreNumber, commonClasses.colorBlack)}>{Number(result)}</Box>
                    </Box>
                </Box>
            </Box>
        </Box>

    )
}

