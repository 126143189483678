import {createStyles, makeStyles} from "@material-ui/core/styles";
import {colors} from "../../../common/constants/colors";

export const useStyles = makeStyles(() =>
    createStyles({
            primary: {
                background: 'rgba(91, 174, 212, 0.14)',
                color: colors.main,
                fontSize: 12,
                fontWeight: 500,
                width: 80,
                height: 22,
                textAlign: 'center',
                borderRadius: 2,
                paddingTop: 3
            },
            secondary: {
                background: 'rgba(255, 161, 37, 0.08)',
                color: colors.average1,
                fontSize: 12,
                fontWeight: 500,
                width: 100,
                height: 22,
                textAlign: 'center',
                borderRadius: 2,
                paddingTop: 3
            },
            image: {
                width: 230,
                height: 120,
                background: 'rgba(0, 0, 0, 0.7)',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
            }
        }
    ));
