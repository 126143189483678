import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  FormHelperText
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import clsx from 'clsx';
import React from 'react';
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { useFacade } from "./updateBannerComponent.hooks";
import { useStyles } from "./updateBannerComponent.styles";
import commonUseStyles from "../../../common/styles/common.styles";
import { BannerModel, bannerTypesNames, BannerDisplayType } from "../../../../models/banner.model";
import { colors } from "../../../common/constants/colors";

export interface UpdateBannerComponentProps {
  selectedBanner: BannerModel;
  isOpen: boolean;
  handleToggleDialog: (value: boolean) => void;
}

export const UpdateBannerComponent: React.FC<UpdateBannerComponentProps> = (props: UpdateBannerComponentProps) => {
  const { selectedBanner, isOpen, handleToggleDialog } = props;
  const [
      {
          banner,
          errors,
          isLoading
      },
      handleChanges,
      handleUpdate
  ] = useFacade(selectedBanner, isOpen, handleToggleDialog);

  const commonClasses = commonUseStyles();
  const classes = useStyles();

  const controlIcon = <CheckBoxOutlineBlankIcon style={{ fill: colors.mediumGray }} />;
  const controlCheckedIcon = <CheckBoxIcon style={{ fill: colors.main }} />

  return (
      <>
          <Dialog
              onClose={() => handleToggleDialog(false)}
              className={classes.root}
              open={isOpen}
          >
              <DialogContent className={classes.dialogContent}>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Box>
                          <span className={clsx(commonClasses.size24, commonClasses.textMedium)}>Edit Banner</span>
                      </Box>
                      <Box display="flex " alignItems="center">
                          <IconButton
                              id="update-banner-dialog-close"
                              className={classes.dialogTitleCloseAction}
                              onClick={() => handleToggleDialog(false)}
                          >
                              <CloseIcon />
                          </IconButton>
                      </Box>
                  </Box>
                  <Box mt={5}>
                      <Box className={commonClasses.inputLabel} mb={1}>Name*</Box>
                      <TextField
                          id={`name`}
                          fullWidth
                          required
                          size="small"
                          InputProps={{ className: 'input', classes }}
                          variant="outlined"
                          value={banner.name}
                          error={!!errors['name']}
                          helperText={errors['name']}
                          disabled={!isOpen || isLoading}
                          onChange={(event) => handleChanges(event.target.id, event.target.value)}
                      />
                  </Box>
                  <Box mt={2}>
                      <Box className={commonClasses.inputLabel} mb={1}>Copy*</Box>
                      <TextField
                          id={`copy`}
                          fullWidth
                          required
                          multiline
                          rows={5}
                          size="small"
                          InputProps={{ className: 'input', classes }}
                          variant="outlined"
                          value={banner.copy}
                          error={!!errors['copy']}
                          helperText={errors['copy']}
                          disabled={!isOpen || isLoading}
                          onChange={(event) => handleChanges(event.target.id, event.target.value)}
                      />
                  </Box>
                  <Box mt={2}>
                      <FormControl
                          variant="outlined"
                          color='primary'
                          required
                          error={!!errors[`type`]}
                          size="small"
                          disabled={!isOpen || isLoading}
                          classes={{ root: classes.root }}
                          fullWidth>
                          <Box className={commonClasses.inputLabel} mb={1}>Type*</Box>
                          <Select
                              style={{ background: '#FFF' }}
                              classes={{ root: classes.root }}
                              value={banner.type}
                              error={!!errors[`type`]}
                              inputProps={{
                              name: 'Banner Display Type',
                              id: 'type-label',
                              classes,
                              }}
                              renderValue={() => {
                                  if (banner.type === null) {
                                      return <Box className={commonClasses.colorMediumGray}>Select</Box>;
                                  }

                                  return bannerTypesNames.get(banner.type);
                              }}
                              displayEmpty
                              onChange={(v) => handleChanges("type", v.target.value)}
                          >
                              <MenuItem value={BannerDisplayType.All}>All</MenuItem>
                              <MenuItem value={BannerDisplayType.Patient}>Patient</MenuItem>
                              <MenuItem value={BannerDisplayType.Employee}>Employee</MenuItem>
                          </Select>
                          <FormHelperText>{errors[`type`]}</FormHelperText>
                      </FormControl>
                  </Box>
                  <Box mt={2}>
                      <Box className={commonClasses.inputLabel} mb={1}>Priority*</Box>
                      <TextField
                          id={`priority`}
                          required
                          fullWidth
                          size="small"
                          type="number"
                          InputProps={{ className: 'input', classes }}
                          variant="outlined"
                          value={banner.priority}
                          error={!!errors['priority']}
                          helperText={errors['priority']}
                          disabled={!isOpen || isLoading}
                          onChange={(event) => handleChanges(event.target.id, event.target.value)}
                      />
                  </Box>
                  <Box mt={2}>
                      <Box className={commonClasses.inputLabel} mb={1}>Status*</Box>
                      <Box display="flex" alignItems="center">
                          <Checkbox
                              icon={controlIcon}
                              color="primary"
                              checkedIcon={controlCheckedIcon}
                              checked={banner.isActive}
                              onChange={() => handleChanges("isActive", true)}
                          />
                          <Box mr={2}>Active</Box>
                          <Checkbox
                              icon={controlIcon}
                              color="primary"
                              checkedIcon={controlCheckedIcon}
                              checked={!banner.isActive}
                              onChange={() => handleChanges("isActive", false)}
                          />
                          <Box>Inactive</Box>
                      </Box>
                  </Box>
                  <Box mt={5} display="flex" justifyContent="flex-end" alignItems="center">
                      <Box mx={2}>
                          <WildHealthButton
                              id="update-banner-cancel"
                              onClick={() => handleToggleDialog(false)}
                              color="tertiary"
                              size='medium'
                              disabled={isLoading}
                          >
                              Cancel
                          </WildHealthButton>
                      </Box>
                      <Box>
                          <WildHealthButton
                              id="update-banner-button"
                              size='medium'
                              onClick={handleUpdate}
                              autoFocus
                              loading={isLoading}
                          >
                              Update Banner
                          </WildHealthButton>
                      </Box>
                  </Box>
              </DialogContent>
          </Dialog>
      </>
  );
}
