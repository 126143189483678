
import { useEffect, useState } from "react";
import { onEmit } from "../../../../common/helpers/on-emit";
import { Subscription } from "rxjs";
import { shortcutsQuery, shortcutsStore } from "../../../stores/shortcutsStore";
import { ShortcutGroupModel, ShortcutModel, UpdateShortcutModel } from "../../../models/shortcut.models";
import { shortcutsService } from "../../../services/shortcuts.service";
import { IErrorState } from "../../../../common/validation/error-state";
import { manageShortcutsComponentValidator } from "./manageShortcutsComponent.validator";
import { confirmService } from "../../../../../services/confirm.service";

/**
 * Represents Manage Shortcuts Page state
 */
interface ManageShortcutsPageState extends IErrorState {
    isLoading: boolean;
    groups: ShortcutGroupModel[];
    targetGroup: ShortcutGroupModel | null;
    targetShortcut: ShortcutModel | null;
    createShortcut: boolean;
    createShortcutGroup: boolean;
    isSaving: boolean;
    isDeleting: boolean;
}

/**
 * Custom Hook to manage a view Model for Manage shortcuts page
 */
export function useFacade(): [
    ManageShortcutsPageState,
    () => void,
    () => void,
    () => void,
    () => void,
    (key: string, value: string) => void,
    () => void,
    () => void] {

    const [state, setState] = useState({
        isLoading: true,
        groups: [],
        errors: {},
        targetGroup: null,
        targetShortcut: null,
        createShortcut: false,
        createShortcutGroup: false,
        isSaving: false,
        isDeleting: false
    } as ManageShortcutsPageState);

    const handleShortcutCreate = () => {
        setState({
            ...state,
            createShortcut: true
        });
    }

    const handleShortcutCreated = () => {
        setState(state => ({
            ...state,
            createShortcut: false
        }));
    }

    const handleShortcutGroupCreate = () => {
        setState({
            ...state,
            createShortcutGroup: true
        });
    }

    const handleShortcutGroupCreated = () => {
        setState(state => ({
            ...state,
            createShortcutGroup: false
        }));
    }

    const handleChanges = (key: string, value: string) => {
        if (!state.targetShortcut) {
            return;
        }

        state.targetShortcut[key] = value;
        setState({
            ...state,
            targetShortcut: Object.assign({}, state.targetShortcut)
        });

        manageShortcutsComponentValidator.validateAndSetState(state, setState, key, value);
    }



    const handleSaveChanges = () => {
        if (!manageShortcutsComponentValidator.stateIsValid(state)) {
            return;
        }

        if (!state.targetShortcut) {
            return;
        }

        setState(state => ({ ...state, isSaving: true }));
        const cb = () => setState(state => ({ ...state, isSaving: false }));

        const model: UpdateShortcutModel = {
            id: state.targetShortcut.id,
            groupId: state.targetShortcut.groupId,
            name: state.targetShortcut.name,
            content: state.targetShortcut.content,
            displayName: state.targetShortcut.displayName
        }

        shortcutsService.updateShortcut(model).subscribe((shortcut: ShortcutModel) => {
            cb();
            updateTargetShortcut(shortcut);
        }, cb)
    }

    const handleShortcutDelete = () => {
        if (!state.targetShortcut) {
            return;
        }

        confirmService.confirm(`Are you sure you want to delete ${state.targetShortcut.name} shortcut?`).subscribe(() => {
            setState(state => ({...state, isDeleting: true }))
            const cb = () => setState(state => ({...state, isDeleting: false }))
            shortcutsService.deleteShortcut(state.targetShortcut.id).subscribe((shortcut: ShortcutModel) => {
                if (state.targetShortcut && state.targetShortcut.id === shortcut.id) {
                    cb();
                    shortcutsStore.resetTargetShortcut();
                }
            }, cb);
        })
    }
    const updateTargetShortcut = (shortcut: ShortcutModel) => {
        if (state.targetShortcut.id && state.targetShortcut.id === shortcut.id) {
            setState(state => ({
                ...state,
                targetShortcut: shortcut
            }));
        }
    }

    /**
     * Load all ongoing cares
     * Manage subscriptions with auto-cleanup
     */
    useEffect(() => {
        shortcutsStore.reset();
        const subscriptions: Subscription[] = [
            onEmit<ShortcutGroupModel[]>(shortcutsQuery.groups$, groups => {
                setState(state => ({
                    ...state,
                    groups
                }));
            }),
            onEmit<ShortcutModel>(shortcutsQuery.targetShortcut$, targetShortcut => {
                setState(state => ({
                    ...state,
                    targetShortcut
                }));
            })
        ];

        const cb = () => setState(state => ({ ...state, isLoading: false }));

        shortcutsService.get().subscribe(cb, cb);

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [state, handleShortcutCreate, handleShortcutCreated, handleShortcutGroupCreate, handleShortcutGroupCreated, handleChanges, handleSaveChanges, handleShortcutDelete];
}