import { Box, Button, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useState } from "react";
import { useStyles } from "./paginationComponent.style";
import { SelectPageComponent } from "../../../../common/pagination/components/selectPageComponent/SelectPageComponent";
import Pagination from "@material-ui/lab/Pagination";

interface PaginationComponentProps {
  selectedPage: number;
  pageSize: number;
  pageSizes: number[];
  totalCount: number;
  availablePages: number[];
  handlePageSelect: (pageNumber: number) => void;
  handlePageSizeChange: (value: any) => void;
}

export const PaginationComponent: React.FC<PaginationComponentProps> = (
  props: PaginationComponentProps
) => {
  const {
    selectedPage,
    pageSizes,
    pageSize,
    totalCount,
    availablePages,
    handlePageSelect,
    handlePageSizeChange,
  } = props;

  const style = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [page, setPage] = useState(selectedPage);

  const handleChange = (event, value) => {
    setPage(value);
    handlePageSelect(value);
  };

  const renderPagePagination = () => {
    if (isSmallScreen) {
      return (
        <Pagination
          count={Math.ceil(totalCount / pageSize)}
          page={page}
          onChange={handleChange}
          variant='outlined'
          shape='rounded'
          className={style.pagePagination}
        />
      );
    }
    return availablePages.map((pageNumber, index) => {
      return (
        <Box key={index} display='flex' alignItems='center' justifyContent='flex-end'>
          <Box px={0.5}>
            <Button
              id={`pagination-${pageNumber}`}
              onClick={() => handlePageSelect(pageNumber)}
              variant='outlined'
              className={
                selectedPage === pageNumber ? style.selectedPage : style.regularPage
              }
            >
              {pageNumber}
            </Button>
          </Box>
        </Box>
      );
    });
  };

  return (
    <Box display='flex' justifyContent='space-between' width='100%'>
      {!isSmallScreen && (
        <Box display='flex' alignItems='center'>
          <Box color='gray2' fontSize={14}>
            Rows per page:
          </Box>
          <Box ml={1} mt={-0.5}>
            <SelectPageComponent
              pageSize={pageSize}
              pageSizes={pageSizes}
              handlePageSizeSelect={handlePageSizeChange}
            />
          </Box>
        </Box>
      )}
      <Box className={style.pagesContainer}>
        <Box className={style.title} mr={1}>
          {`Showing ${(selectedPage - 1) * pageSize + 1} to ${
            selectedPage * pageSize > totalCount ? totalCount : selectedPage * pageSize
          } of ${totalCount}`}
        </Box>
        {renderPagePagination()}
      </Box>
    </Box>
  );
};
