import jsPDF from "jspdf"
import moment from 'moment';
import {PatientAppointmentModel} from "../../../../appointments/models/appointments.models";
import {PatientModel} from "../../../../patients/models/patient.model";
import {
    AppointmentPurposeTypeNames,
    AppointmentStatus,
    AppointmentStatusNames
} from "../../../../appointments/models/appointments.enums";
import {
    StaffOrHealthCoachMessagesEnum
} from "../../../components/patientConversationsViewComponent/patientConversationsViewComponent.hooks";
import pdfServices from "../PdfServices";

const addFooters = pdf => {
    const pageCount = pdf.internal.getNumberOfPages()

    pdf.setFont('helvetica', 'italic')
    pdf.setFontSize(8)
    for (var i = 1; i <= pageCount; i++) {
        pdf.setPage(i)
        pdf.text(`Page ${String(i)} of  ${String(pageCount)} -- created ${moment().format('LLL')}`, pdf.internal.pageSize.width / 2, 287, {
            align: 'center'
        })
    }
}

const header = (pdf, text) => {

    pdf.setFontSize(16);
    pdf.text(text,pdf.internal.pageSize.width / 2, 10, {align:'center'});
    pdf.setLineWidth(0.5);
    pdf.line(1, 11, 220, 11);
    pdf.setFontSize(11);
    pdf.text(5,16,'Appt. Date:');
    pdf.text(50,16,'Appt. Type:');
    pdf.text(100,16,'Appt. Status:')
    pdf.text(150,16,'Appt. Hosts:')
}

const addFooterAndNextHeader = (pdf, yPosition, text) => {

    if ( yPosition > 250) {
        pdf.addPage();
        header(pdf,text)
        yPosition = 10
    }
    return yPosition;
}

export const AppointmentsPdfDocument = (appointments: PatientAppointmentModel[], patientName: string, patientId: number) => {

    const pdf = new jsPDF("p", "mm", "A4");

    let yPosition = 10;

    header(pdf, `Appointments History for Patient ${patientName} - id: ${patientId}`);

    appointments.map((appt, index) => {
        pdf.setFontSize(10);
        yPosition = yPosition + 15;
        pdf.text(5, yPosition, `${moment(appt.startDate).format('lll')}`)
        pdf.text(50, yPosition, `${AppointmentPurposeTypeNames.get(appt.purpose)}`)

        if(appt.status !== AppointmentStatus.Canceled)
        {
            pdf.text(100, yPosition, `Completed`)
        }
        else {
            pdf.text(100, yPosition, `${AppointmentStatusNames.get(appt.status)}`)
        }


        const employeesText = appt.employees.map(emp => `${emp.firstName} ${emp.lastName}`).join(', ');
        const splitText = pdf.splitTextToSize(employeesText, 40)
        pdf.text(150,yPosition, splitText)

        yPosition = addFooterAndNextHeader(pdf,yPosition,`Appointments History for Patient ${patientName}`)
    })

    addFooters(pdf)

    return pdf;
}