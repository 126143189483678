import { Box, Container } from "@material-ui/core";
import React from 'react';
import { TitlesService } from '../../../../constants/title.constants';
import { useFacade } from "./verifyProfilePage.hooks";
import {VerifyProfileComponent} from "../../components/verifyProfileComponent/VerifyProfileComponent";
import {SendProfileVerificationComponent} from "../../components/sendProfileVerificationComponent/SendProfileVerificationComponent";
import { useStyles } from '../loginPage/loginPage.styles';

export const VerifyProfilePage: React.FC = () => {
    const [
        {
            verificationSent
        },
        handleVerificationSent,
        handleVerificationSubmit
    ] = useFacade();
    const classes = useStyles();

    return (
        <>
            <title>{TitlesService.activateProfileTitle}</title>
            <div className={classes.loginPage}>
                <Box className={classes.contentBox}>
                    <Container maxWidth="sm" className="content">
                        {
                            verificationSent
                                ? <VerifyProfileComponent handleVerificationSubmit={handleVerificationSubmit}/>
                                : <SendProfileVerificationComponent handleVerificationSent={handleVerificationSent} />
                        }
                    </Container>
                </Box>
            </div>
        </>
    );
}
