import { Box, CircularProgress, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useCallback } from 'react';
import commonUseStyles from '../../styles/common.styles';
import debounce from 'lodash.debounce'
import { colors } from '../../constants/colors';
import moment from 'moment';

export interface UserViewModel {
    id: number;
    firstName: string;
    lastName: string;
    birthday?: Date;
}

interface SelectPatientComponentProps {
    title?: string;
    placeholder?: string;
    selectedUser?: UserViewModel;
    users: UserViewModel[];
    isLoading: boolean;
    error?: boolean;
    helperText?: string;
    id?: string;
    disabled?: boolean;
    handleChange: (user: number) => void;
    handleInputChange: (input: string) => void;
}

export const SelectUserComponent: React.FC<SelectPatientComponentProps> = (props: SelectPatientComponentProps) => {
    const {
        title,
        placeholder,
        handleChange,
        handleInputChange,
        selectedUser,
        users,
        isLoading,
        error = false,
        helperText = null,
        disabled = false,
        id = null
    } = props;

    const [state] = useState({ inputText: '' });

    const commonClasses = commonUseStyles();

    const debouncedHandleTextChange = useCallback(debounce(function () {
        handleInputChange(state.inputText)
    }, 1500), [])

    const handleTextChange = (text: string) => {
        state.inputText = text;
        if (text.trim() === "") {
            return handleInputChange(state.inputText)
        }

        if (text.includes('@')) {
            return
        }
        debouncedHandleTextChange();
    }

    const displayName = (user: UserViewModel): string => {
        return `${user.firstName} ${user.lastName}${user.birthday ? ` (${moment(user.birthday).format('MM/DD/YYYY')})` : ''}`;
    }

    const renderInput = (params) => {
        return (
            <TextField
                {...params}
                margin="normal"
                variant="outlined"
                placeholder={placeholder}
                error={error}
                onChange={(e) => handleTextChange(e.currentTarget.value)}
                helperText={helperText}
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <React.Fragment>
                            {isLoading ? <CircularProgress style={{ color: colors.main }} size={20} /> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                }}
            />
        )
    }

    return (
        <Box id={id}>
            <Box mb={-1}>
                {
                    title &&
                    <span className={clsx(commonClasses.size14, commonClasses.colorGray1)}>{title}</span>
                }
            </Box>
            <Box>
                <Autocomplete
                    size='small'
                    disableClearable
                    options={state.inputText.trim() === "" ? [] : users}
                    disabled={disabled}
                    value={selectedUser}
                    getOptionLabel={user => displayName(user)}
                    onChange={(e, value) => handleChange(typeof (value) === 'object' ? value.id : +value)} //todo: figureout compiler behaviour
                    renderInput={(params) => renderInput(params)}
                />
            </Box>
        </Box>
    );
}