import React from 'react';
import { Box, Tooltip } from '@material-ui/core';
import { useStyles } from "./LabInputRangeComponent.styles";
import { LabValidationSource, LabRangeModel } from "../../models/input.models";
import { renderLabInputRange } from "../../helpers/render-lab-input-range";

interface LabInputRangeComponentProps {
  range: LabRangeModel;
}

export const LabInputRangeComponent: React.FC<LabInputRangeComponentProps> = (props: LabInputRangeComponentProps) => {
  const {
    range,
  } = props;

  const classes = useStyles();

  const customTooltipClasses = {
      tooltip: classes.customWidth,
      arrow: classes.arrow,
      tooltipPlacementBottom: classes.tooltipPlacement,
      tooltipPlacementTop: classes.tooltipPlacement,
  };

  return (
    <Tooltip placement="top" arrow classes={customTooltipClasses} title={range.source === LabValidationSource.Custom ? 'Wild Health Optimal Range' : 'Lab Provider Range'}>
      <Box className="wh-tw-w-fit wh-tw-text-gray600 md:wh-tw-text-gray900">
        {renderLabInputRange(range)}
      </Box>
    </Tooltip>
  )
}