import React from 'react';
import { useHistory } from 'react-router';
import { Box } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { AuthenticatedLayoutComponent } from '../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from "../../../../models/menu.models";
import { navigationService } from '../../../../services/navigation.service';
import { CommonMdmPlanEditComponent } from '../../components/commonMdmPlanEditComponent/CommonMdmPlanEditComponent';

export const ManageCommonMdmEditPage: React.FC<any> = (props: any) => {
  const mdmId = Number(props.match.params.mdmId);

  const history = useHistory();

  return (
    <AuthenticatedLayoutComponent
      hideBackground
      title={TitlesService.commonMdmsTitle}
      pageName={PagesNamesService.commonMdms}
      selectedMenuItem={MenuItemTypes.ManageCommonMdms}
    >
      <Box p="30px">
        <Box id="common-mdms-list-go-back" py={2} display="flex" alignItems="center" className="wh-tw-cursor-pointer wh-tw-text-gray1 wh-tw-bg-gray8" onClick={() => navigationService.toCommonMdms(history)}>
          <Box>
            <ArrowBack fontSize="small"/>
          </Box>
          <Box ml={1}>
            <span>Back</span>
          </Box>
        </Box>
        <CommonMdmPlanEditComponent mdmPlanId={mdmId} />
      </Box>
    </AuthenticatedLayoutComponent>
  )
}
