import React, { useRef } from 'react';
import moment from 'moment';
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    Tooltip, Drawer, IconButton, Divider,
    useTheme, useMediaQuery
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import warning from "@img/icons/warning.svg";
import clsx from 'clsx';
import { useStyles } from "./PatientLabInputsSectionComponent.styles";
import { useFacade } from "./patientLabInputsSectionComponent.hooks";
import { LabInputModel, LabInputNotificationType, LabInputsViewMode, LabInputsDatasetViewModel } from "../../models/input.models";
import { WildHealthTableRow } from '../../../common/components/wildHealthTable/WildHealthTableRow';
import { WildHealthTableCell } from '../../../common/components/wildHealthTable/WildHealthTableCell';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { renderEmptyValue } from "../../../common/helpers/empty-value";
import { CommonPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/CommonPlaceHolder";
import { renderLabResultValuesGraph } from '../../helpers/render-lab-result-values-graph';
import { renderLabInputRange, getMaxValueByRange } from "../../helpers/render-lab-input-range";
import { BiomarkerSlider } from '../labInputsSectionComponent/LabInputsSectionComponent.styles';
import { LabInputRangeComponent } from '../LabInputRangeComponent/LabInputRangeComponent';
import { getDataSetByLabLatestValue, getRange } from '../../helpers/labInputs.extensions';

interface PatientLabInputsSectionComponentProps {
    isHightlighted?: boolean;
    inputs: LabInputModel[];
    sectionIndex: number;
    dataSets: LabInputsDatasetViewModel[];
    hiddenDataSets: string[];
    showDataSet?: string;
}

export const PatientLabInputsSectionComponent: React.FC<PatientLabInputsSectionComponentProps> = (props: PatientLabInputsSectionComponentProps) => {
    const { isHightlighted = false, inputs, sectionIndex, dataSets, hiddenDataSets, showDataSet = '' } = props;
    const [
        {
            isSideAreaOpen,
            selectedLabInputId
        },
        handleToggleDescription
    ] = useFacade(inputs);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const classes = useStyles();

    const customTooltipClasses = {
        tooltip: classes.customWidth,
        arrow: classes.arrow,
        tooltipPlacementBottom: classes.tooltipPlacement,
        tooltipPlacementTop: classes.tooltipPlacement,
    };

    const paperContainerRef = useRef(null);

    if (!dataSets) {
        return (<WildHealthLinearProgress />)
    }

    const getRecentValue = (input: LabInputModel, dataSetId: string) => {
        const inputValue = input.values.find(x => x.dataSetId === dataSetId);
        if (inputValue === undefined || !inputValue.isInitialized) {
            return { isError: false, value: 0 };
        }

        const notification = inputValue.notification;

        if (notification?.notificationType === LabInputNotificationType.Error) {
            return { isError: true, value: inputValue.value };
        }
        return { isError: false, value: inputValue.value };
    }

    const renderValue = (input: LabInputModel, dataSetId: string): JSX.Element => {
        const inputValue = input.values.find(x => x.dataSetId === dataSetId);
        if (inputValue === undefined || !inputValue.isInitialized) {
            return (<Tooltip placement="top" arrow classes={customTooltipClasses} title="No Result">
                {renderEmptyValue("-")}
            </Tooltip>
            );
        }

        const notification = inputValue.notification;

        return (<Box display="flex" className={clsx(!isSmallScreen && "wh-tw-justify-center")}>
            <Tooltip placement="top" arrow classes={customTooltipClasses} title={notification?.notificationType === LabInputNotificationType.Error ? 'Out of Range' : 'In Range'}>
                <Box display="flex" alignItems="center" justifyContent="center" className={clsx(notification?.notificationType === LabInputNotificationType.Error && "wh-tw-w-fit wh-tw-rounded-full wh-tw-border wh-tw-border-error wh-tw-border-solid wh-tw-px-2 wh-tw-py-0.5")}>
                    <Box>
                        <span
                            className={clsx(notification?.notificationType === LabInputNotificationType.Error && "wh-tw-text-bad")}>{inputValue.value}</span>
                    </Box>
                    {
                        notification?.notificationType === LabInputNotificationType.Error &&
                        <Box ml={1}>
                            <img alt="img" src={warning} />
                        </Box>
                    }
                </Box>
            </Tooltip>
        </Box>);
    }

    const renderSideArea = () => {
        const targetLabInput = inputs.find(x => x.id === selectedLabInputId);
        if (targetLabInput) {
            const dataSetByLatestValue = getDataSetByLabLatestValue(dataSets, targetLabInput);
            const range = getRange(targetLabInput);
            return (
                <Box className={clsx('flat-scroll', classes.sideContainer)} display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" justifyContent="space-between" pb={2}>
                        <Box display="flex" alignItems="center" gridGap={8} className="wh-tw-cursor-pointer" onClick={() => handleToggleDescription(0, false)}>
                            <ChevronLeftIcon className="wh-tw-text-gray900" />
                            <Box className="wh-tw-font-medium wh-tw-text-xl wh-tw-text-gray600 wh-tw-font-poppins">Labs</Box>
                        </Box>
                        <IconButton
                            id={`task-${targetLabInput.id}`}
                            size="small"
                            onClick={() => handleToggleDescription(0, false)}
                        >
                            <CloseIcon className="wh-tw-text-gray900" />
                        </IconButton>
                    </Box>
                    <Box pt={3}>
                        <Box className="wh-tw-font-medium wh-tw-text-lg wh-tw-font-beVietnamPro">{targetLabInput.displayName}</Box>
                        {targetLabInput.description && <Box mt={1.5} className="wh-tw-text-sm wh-tw-text-gray600 wh-tw-font-beVietnamPro">{targetLabInput.description}</Box>}
                        {range && <Box mt={1.5} className="wh-tw-text-sm wh-tw-text-gray600 wh-tw-font-medium wh-tw-font-beVietnamPro">In Range {renderLabInputRange(range)}</Box>}
                    </Box>
                    <Box pt={3}>
                        <Box className="wh-tw-font-medium wh-tw-text-lg wh-tw-font-beVietnamPro">Most Recent Value</Box>
                        {range && (
                            <Box mt={1.5}>
                                <BiomarkerSlider
                                    aria-label="biomarker slider"
                                    defaultValue={getRecentValue(targetLabInput, dataSetByLatestValue.id).value}
                                    track={false}
                                    disabled
                                    isDanger={getRecentValue(targetLabInput, dataSetByLatestValue.id).isError}
                                    min={0}
                                    max={getMaxValueByRange(range) * 2}
                                    isRevert={getRecentValue(targetLabInput, dataSetByLatestValue.id).value > getMaxValueByRange(range)}
                                />
                            </Box>
                        )}
                        <Box display="flex" justifyContent="space-between" mt={1.5}>
                            <Box>Range {range ? renderLabInputRange(range) : ''}</Box>
                            <Box className="wh-tw-text-sm wh-tw-text-gray900 wh-tw-font-beVietnamPro">{renderValue(targetLabInput, dataSetByLatestValue.id)}</Box>
                        </Box>
                    </Box>
                    {targetLabInput.values.length > 1 && (
                        <Box pt={3}>
                            <Box className="wh-tw-font-medium wh-tw-text-lg wh-tw-font-beVietnamPro">Past Values</Box>
                            <Box mt={1.5} display="flex" justifyContent="center">
                                {
                                    renderLabResultValuesGraph(targetLabInput.values)
                                }
                            </Box>
                            {
                                dataSets.map((dataSet) => {
                                    const inputValue = targetLabInput.values.find(x => x.dataSetId === dataSet.id);
                                    if (inputValue === undefined || !inputValue.isInitialized) {
                                        return;
                                    }
                                    return (
                                        <React.Fragment  key={dataSet.id}>
                                            <Box py={1.5} display="flex" justifyContent="space-between">
                                                <Box className="wh-tw-text-black wh-tw-text-sm wh-tw-font-beVietnamPro">{moment(dataSet.date).format("MM/DD/YYYY")}</Box>
                                                <Box className="wh-tw-text-sm wh-tw-text-gray900 wh-tw-font-beVietnamPro">{renderValue(targetLabInput, dataSet.id)}</Box>
                                            </Box>
                                            <Divider />
                                        </React.Fragment>
                                    );
                                })
                            }
                        </Box>
                    )}
                    <Box pt={3}>
                        <Box mt={1.5} className="wh-tw-text-sm wh-tw-text-gray900 wh-tw-font-beVietnamPro">If you have any questions about these values, schedule a call with your provider to discuss them!</Box>
                    </Box>
                </Box>
            )
        }

        return <></>
    }

    const pxString = (value: number): string => `${value}px`;

    const renderValuesView = (id: string) => {
        if (!dataSets.length) {
            return (
                <Box p={2}>
                    <CommonPlaceHolder message="No results" />
                </Box>
            );
        }
        
        const dataLength = dataSets.filter(x => x.selected).length;
        const tableContainerWidth = paperContainerRef.current ? paperContainerRef.current.offsetWidth : 0;
        const nameWidth = 200;
        const rangeWidth = 200;
        const valueWidth = 140;
        const tableWidth = dataLength * valueWidth + nameWidth + rangeWidth;
        const isScrollable = tableWidth > tableContainerWidth;

        if (isSmallScreen) {
            return (
                <TableContainer id={id} className={classes.root}>
                    <Table>
                        <TableHead>
                            <WildHealthTableRow>
                                <WildHealthTableCell style={{ minWidth: pxString(nameWidth) }}>
                                    <Box className="wh-tw-text-black wh-tw-text-sm wh-tw-font-semibold wh-tw-font-beVietnamPro">Biomarker</Box>
                                </WildHealthTableCell>
                                <WildHealthTableCell>
                                    <Box className="wh-tw-text-black wh-tw-text-sm wh-tw-font-semibold wh-tw-font-beVietnamPro">Recent Result</Box>
                                </WildHealthTableCell>
                            </WildHealthTableRow>
                        </TableHead>
                        <TableBody>
                            {
                                inputs.map((input, index) => {
                                    const dataSetByLatestValue = getDataSetByLabLatestValue(dataSets, input);
                                    const range = getRange(input);
                                    return (
                                        <WildHealthTableRow key={index} onClick={() => handleToggleDescription(input.id, true)}>
                                            <WildHealthTableCell>
                                                <Box><span className="wh-tw-cursor-pointer">{input.displayName}</span></Box>
                                                {range ? <LabInputRangeComponent range={range} /> : ''}
                                            </WildHealthTableCell>
                                            <WildHealthTableCell>
                                                {
                                                    renderValue(input, isHightlighted ? dataSetByLatestValue.id : showDataSet)
                                                }
                                            </WildHealthTableCell>
                                        </WildHealthTableRow>
                                    );
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            )
        }

        if (isHightlighted) {
            return (
                <TableContainer id={id} className={classes.root}>
                    <Table style={{ minWidth: pxString(valueWidth + nameWidth * 2 + rangeWidth) }}>
                        <TableHead>
                            <WildHealthTableRow>
                                <WildHealthTableCell
                                    style={{ minWidth: pxString(nameWidth) }}
                                    className={clsx(classes.stickyLeft, { [classes.shadowRight]: isScrollable })}>
                                    <Box className="wh-tw-text-black wh-tw-text-sm wh-tw-font-semibold wh-tw-font-beVietnamPro">Biomarker</Box>
                                </WildHealthTableCell>
                                <WildHealthTableCell
                                    style={{ minWidth: pxString(rangeWidth) }}>
                                    <Box className="wh-tw-text-black wh-tw-text-sm wh-tw-font-semibold wh-tw-font-beVietnamPro">Range</Box>
                                </WildHealthTableCell>
                                <WildHealthTableCell style={{ minWidth: pxString(nameWidth) }} align="center">
                                    <Box display="flex" alignItems="center" justifyContent="center" className="wh-tw-text-black wh-tw-text-sm wh-tw-font-semibold wh-tw-font-beVietnamPro"> Recent Result </Box>
                                </WildHealthTableCell>
                                <WildHealthTableCell style={{ minWidth: pxString(nameWidth) }} />
                                <WildHealthTableCell style={{ width: '100%' }}>
                                    <Box className="wh-tw-text-black wh-tw-text-sm wh-tw-font-semibold wh-tw-font-beVietnamPro">Date</Box>
                                </WildHealthTableCell>
                            </WildHealthTableRow>
                        </TableHead>
                        <TableBody>
                            {
                                inputs.map((input, index) => {
                                    const dataSetByLatestValue = getDataSetByLabLatestValue(dataSets, input);
                                    const range = getRange(input);
                                    return (
                                        <WildHealthTableRow key={index} onClick={() => handleToggleDescription(input.id, true)}>
                                            <WildHealthTableCell className={clsx(classes.stickyLeft, { [classes.shadowRight]: isScrollable })}>
                                                <span className="wh-tw-cursor-pointer">{input.displayName}</span>
                                            </WildHealthTableCell>
                                            <WildHealthTableCell>{range ? <LabInputRangeComponent range={range} /> : ''}</WildHealthTableCell>
                                            <WildHealthTableCell align="center">
                                                {
                                                    renderValue(input, dataSetByLatestValue.id)
                                                }
                                            </WildHealthTableCell>
                                            <WildHealthTableCell />
                                            <WildHealthTableCell>
                                                {
                                                    moment(dataSetByLatestValue.values[0].date).format("MM/DD/YYYY")
                                                }
                                            </WildHealthTableCell>
                                        </WildHealthTableRow>
                                    );
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            )
        }

        return (<>
            <TableContainer id={id} className={classes.root}>
                <Table style={{ minWidth: pxString(tableWidth) }}>
                    <TableHead>
                        <WildHealthTableRow>
                            <WildHealthTableCell
                                style={{ minWidth: pxString(nameWidth), width: pxString(nameWidth) }}
                                className={clsx(classes.stickyLeft, { [classes.shadowRight]: isScrollable })}>
                                <Box className="wh-tw-text-black wh-tw-text-sm wh-tw-font-semibold wh-tw-font-beVietnamPro">Biomarker</Box>
                            </WildHealthTableCell>
                            <WildHealthTableCell
                                style={{ minWidth: pxString(rangeWidth), width: pxString(rangeWidth) }}>
                                <Box className="wh-tw-text-black wh-tw-text-sm wh-tw-font-semibold wh-tw-font-beVietnamPro">Range</Box>
                            </WildHealthTableCell>
                            {
                                dataSets.filter(x => !hiddenDataSets.includes(x.id)).map((dataSet, index) => {
                                    return (
                                        <WildHealthTableCell key={index} style={{ minWidth: pxString(valueWidth) }} align="center">
                                            <Box className="wh-tw-text-black wh-tw-text-sm wh-tw-font-semibold wh-tw-font-beVietnamPro">{moment(dataSet.values[0]?.date).format("MM/DD/YYYY")}</Box>
                                        </WildHealthTableCell>
                                    )
                                })
                            }
                        </WildHealthTableRow>
                    </TableHead>
                    <TableBody>
                        {
                            inputs.map((input, index) => {
                                const range = getRange(input);
                                return (
                                    <WildHealthTableRow key={index} onClick={() => handleToggleDescription(input.id, true)}>
                                        <WildHealthTableCell className={clsx(classes.stickyLeft, { [classes.shadowRight]: isScrollable })}>
                                            <span className="wh-tw-cursor-pointer">{input.displayName}</span>
                                        </WildHealthTableCell>
                                        <WildHealthTableCell>{range ? <LabInputRangeComponent range={range} /> : ''}</WildHealthTableCell>
                                        {
                                            dataSets.filter(x => !hiddenDataSets.includes(x.id)).map((dataSet, index) => {
                                                return (
                                                    <WildHealthTableCell key={index} align="center">
                                                        {
                                                            renderValue(input, dataSet.id)
                                                        }
                                                    </WildHealthTableCell>
                                                );
                                            })
                                        }
                                    </WildHealthTableRow>
                                );
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>);
    }

    return (<>
        <Paper ref={paperContainerRef}>
            {
                renderValuesView(`divToPrint${sectionIndex}-${LabInputsViewMode.Values}`)
            }
        </Paper>
        <Drawer
            id="side-area-drawer"
            anchor='right'
            open={isSideAreaOpen}
            onClose={() => handleToggleDescription(0, false)}
        >
            <Box className={classes.sideMobile}>
                {renderSideArea()}
            </Box>
        </Drawer>
    </>)
}