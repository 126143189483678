import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    chat: {
      width: "100%",
      height: "100%",
      display: "grid",
      boxShadow: "0px 7px 60px rgba(168, 173, 198, 0.12)",
      background: colors.white,
      borderRadius: 2,

      gridTemplateAreas: '"messages side"',
      gridTemplateColumns: "calc(100% - 325px) 325px",
      [theme.breakpoints.between("sm", "md")]: {
        gridTemplateColumns: "100%",
        gridTemplateAreas: '"messages"',
      },
    },
    header: {
      height: 70,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      [theme.breakpoints.down("xs")]: {
        height: "100%",
        justifyContent: "center",
      },
    },
    titleAndAvatars: {
      display: "flex",
      gap: 16,
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        alignItems: "center",
        gap: 10,
      },
    },
    side: {
      gridArea: "side",
      height: "100%",
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
    },
    sideContainer: {
      height: "100%",
      width: "100%",
      overflow: "auto",
    },
    messages: {
      gridArea: "messages",
      display: "flex",
      flexDirection: "column",
      maxHeight: "calc(100vh - 226px) !important",
      minHeight: 565,
      [theme.breakpoints.down("xs")]: {
        minHeight: 0,
      },
    },
    messageContent: {
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    thread: {
      flex: 1,
      minHeight: 200,
      height: "calc(100vh - 398px)",
      maxHeight: "calc(100vh - 398px)",
      overflow: "auto",
    },
    title: {
      color: colors.black,
      fontWeight: 500,
      fontSize: 20,
      [theme.breakpoints.down("xs")]: {
        fontSize: 18,
      },
    },
    subject: {
      fontSize: 12,
      color: colors.gray2,
    },
    newMessage: {
      maxHeight: "calc(100vh - 260px)",
      minHeight: 490,
      overflow: "auto",
    },
    divider: {
      backgroundColor: colors.stroke,
    },
    sendMessage: {
      flexShrink: 0,
    },
    sideMobile: {
      width: 325,
    },
    icon: {
      stroke: colors.main,
      height: 26,
      width: 26,
    },

    historyTitle: {
      color: colors.gray1,
      fontFamily: "Be Vietnam Pro",
      fontWeight: 500,
      fontSize: 12,
      textTransform: "uppercase",
    },
    historyItem: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      background: colors.white,
      borderRadius: 2,
      boxShadow: "0px 7px 60px rgba(168, 173, 198, 0.12)",
      padding: "10px 18px",
    },
    historyItemContent: {
      display: "flex",
      alignItems: "center",
    },
    historyContext: {
      paddingLeft: 12,
      color: colors.black,
      fontSize: 16,
      fontWeight: 400,
    },
    historyGoBackTitle: {
      color: colors.black,
      fontWeight: 500,
      fontSize: 16,
    },
    goBack: {
      [theme.breakpoints.down("xs")]: {
        background: colors.white,
        borderBottom: "1px solid",
        borderBottomColor: colors.stroke,
        position: "relative",
      },
    },
    placeholderImage: {
      width: "100%",
      maxWidth: 300,
    },
    subjectSection: {
      zIndex: 1000,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      width: "100%",
      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.25)',
      backgroundColor: colors.white,
      fontSize: 14,
      fontWeight: 400,
      color: colors.black,
      padding: 12,
      bottom: -45,
    },
    subjectTitle: {
      fontSize: 14,
      fontWeight: 500,
      color: colors.black,
      marginLeft: 4,
    }
  })
);
