import { Box, useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { HealthReportMode, HealthReportSections } from "../models/healthReport.models";
import { HealthReportBasePage, HealthReportMobileBasePage } from "./HealthReportBasePage";
import { HealthReportPageProps } from "./healthReportPageProps";
import commonUseStyles from "../../common/styles/common.styles";
import clsx from "clsx";
import { RoutesConstants } from '../../../constants/route.constants';
import { ImageConstants } from '../../../constants/image.constants';
import img from "@img/HealthReport_Pg23_Image.png";

export const HealthReportLongevity0Page: React.FC<HealthReportPageProps> = (props: HealthReportPageProps) => {
    const { pageNumber, practice } = props;
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        props.mode !== HealthReportMode.PrintMode && isSmallScreen ? (
            <HealthReportMobileBasePage section={HealthReportSections.Longevity} pageNumber={pageNumber} practice={practice} firstPage>
                <Box>
                    <Box px={2}>
                        <Box>
                            <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeBigReport, commonClasses.textRegularReport)}>Your Personalized Longevity Report</span>
                        </Box>
                        <Box mt={1.5} fontSize="14px" lineHeight="22px">
                            <span>
                                There is no single secret to longevity. Longevity comes both from genetics and how you live your life. Every aspect of
                                this entire health report is aimed at longevity. Sleep, exercise, diet, fasting, medications, supplements, reversing
                                chronic disease, all of these matter. But there are some specifics we can focus on here.
                                First, it helps to know your baseline. In this case, your baseline is your biological age and how it compares to your
                                chronological, or birth age. You biological age is a measure of how your DNA is packaged. As we age, our cells make
                                mistakes methylating DNA and repackaging it. These errors confuse cells and make them start behaving like different
                                types of cells. This is part of what begins to increase our biologic age. Your DNA methylation test will estimate your
                                biological age to the year. If your biological age is older, we have a lot of work to do. If your biological age is lower,
                                we’ve got a good start. Your aggressiveness with this protocol can be directed and to some degree dictated by your
                                biological age.
                                The following protocol is broken into lifestyle and supplement interventions, as well as possible peptide interventions.
                                What you choose to apply to your routine is up to you, but we recommend discussing these decisions with your
                                precision medicine physician.
                            </span>
                        </Box>
                        <Box mt="30px">
                            <img src={RoutesConstants.cdnBaseLink + ImageConstants.healthReportPg23ImagePath} style={{ width: '100%', height: 'auto' }} alt="img" />
                        </Box>
                    </Box>
                </Box>
            </HealthReportMobileBasePage>
        ) : (
            <HealthReportBasePage section={HealthReportSections.Longevity} pageNumber={pageNumber} practice={practice}>
                <Box>
                    <Box px={15} pt={5} pb={3}>
                        <Box>
                            <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeBigReport, commonClasses.textMediumReport)}>Your Personalized Longevity Report</span>
                        </Box>
                        <Box mt={3}>
                            <span>
                                There is no single secret to longevity. Longevity comes both from genetics and how you live your life. Every aspect of
                                this entire health report is aimed at longevity. Sleep, exercise, diet, fasting, medications, supplements, reversing
                                chronic disease, all of these matter. But there are some specifics we can focus on here.
                                First, it helps to know your baseline. In this case, your baseline is your biological age and how it compares to your
                                chronological, or birth age. You biological age is a measure of how your DNA is packaged. As we age, our cells make
                                mistakes methylating DNA and repackaging it. These errors confuse cells and make them start behaving like different
                                types of cells. This is part of what begins to increase our biologic age. Your DNA methylation test will estimate your
                                biological age to the year. If your biological age is older, we have a lot of work to do. If your biological age is lower,
                                we’ve got a good start. Your aggressiveness with this protocol can be directed and to some degree dictated by your
                                biological age.
                                The following protocol is broken into lifestyle and supplement interventions, as well as possible peptide interventions.
                                What you choose to apply to your routine is up to you, but we recommend discussing these decisions with your
                                precision medicine physician.
                            </span>
                        </Box>
                        <Box mt={5}>
                            {
                                props.mode === HealthReportMode.PrintMode
                                    ? <img src={img} style={{ width: '100%' }} alt="img" />
                                    : <img src={RoutesConstants.cdnBaseLink + ImageConstants.healthReportPg23ImagePath} style={{ width: '100%' }} alt="img" />
                            }
                        </Box>
                    </Box>
                </Box>
            </HealthReportBasePage>
        )
    )
}
