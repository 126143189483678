import {useCallback, useEffect, useState} from "react";
import {Subscription} from "rxjs";
import {onEmit} from "../../../common/helpers/on-emit";
import {PracticumPatientModel} from "../../../patients/models/patient.model";
import {patientsQuery} from "../../../patients/stores/patientsStore";
import {patientsService} from "../../../patients/services/patients.service";
import { EmployeeModel, FellowModel } from "../../../employee/models/employee.models";
import { employeesQuery, employeesStore } from "../../../employee/stores/employeesStore";
import { employeeService } from "../../../employee/services/employees.service";
import { SortingSource } from "../../models/fellowship.enums";
import { SortingDirection } from "../../../common/sorting/models/sorting-destination";
import { ISortingState } from "../../../common/sorting/models/sorting-state";
import { handleCompare } from "../../../common/sorting/helpers/handle-compare";

export interface FellowPatientsComponentState extends ISortingState{
    patients: PracticumPatientModel[];
    fellow: FellowModel;
    isLoading: boolean;
}

export function useFacade(fellowId: number): [
    FellowPatientsComponentState,
    (source: string, direction: SortingDirection) => void
] {
    const [state, setState] = useState({
        patients: [],
        sortingColumns: [
            { title: 'Enrolled', source: SortingSource.Enrollment, direction: SortingDirection.Asc},
        ],
        sortingSource: null,
        fellow: null,
        isLoading: false
    } as FellowPatientsComponentState);

    const getFellow = useCallback(() => {
        const fellow = employeesStore.getValue().fellows.find(x => x.id === fellowId);
        if(fellow) {
            setState(state => ({...state, fellow: fellow}));
            return;
        }

        employeeService.getActiveBy(fellowId);
    }, [fellowId])

    const setDirection = (source: string, direction: SortingDirection) => {
        const itemIndex = state.sortingColumns.findIndex(item => item.source === source);
        state.sortingColumns[itemIndex].direction = direction;
        setState(state => ({...state, columns: state.sortingColumns}));
    }

    const handleSorting = (source: string, direction: SortingDirection) => {
        if (state.sortingSource === source) {
            direction = direction === SortingDirection.Asc
                ? SortingDirection.Desc
                : SortingDirection.Asc;

            setDirection(source, direction);
        }

        setState(state => ({
            ...state,
            sortingSource: source,
            patients: state.patients.sort((p1, p2) => handleCompare(p1, p2, direction, source))
        }));
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<PracticumPatientModel[]>(patientsQuery.practicumPatients$, patients => {
                setState(state => ({
                    ...state,
                    patients: patients,
                    isLoading: false
                }));
            }),
            onEmit<EmployeeModel>(employeesQuery.employee$, employee => {
                if(!employee) {
                    return;
                }
                setState(state => ({
                    ...state,
                    fellow: {
                        id: employee.id,
                        firstName: employee.firstName,
                        lastName: employee.lastName,
                        email: null,
                        phoneNumber: null,
                        createdAt: null
                    } as FellowModel
                }));
            })
        ];

        getFellow();
        patientsService.getEmployeePracticumPatients(fellowId);
        setState(state => ({...state, isLoading: true}))

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [getFellow, fellowId])

    return [state, handleSorting];
}