import {GeneralValidator} from "../../../common/validation/general-validator";
import {
    addressValidationRules,
    birthdayValidationRule,
    emailValidationRule,
    firstNameValidationRule,
    functionValidationRule,
    lastNameValidationRule,
    phoneNumberValidationRule, regexpValidationRule, stringLengthValidationRule, notWhitespaceValidationRule
} from "../../../common/validation/general-validation-rules";
import {Gender} from "../../../common/models/user.models";

export const joinPracticeComponentValidator = new GeneralValidator({
    'firstName': [notWhitespaceValidationRule(), firstNameValidationRule],
    'lastName': [notWhitespaceValidationRule(), lastNameValidationRule],
    'email': [notWhitespaceValidationRule(), emailValidationRule],
    'birthday': [notWhitespaceValidationRule(), birthdayValidationRule],
    'gender': [functionValidationRule((value) => {
        if (+value === Gender.Female) return true;
        if (+value === Gender.Male) return true;
        return false;
    }, "Gender should be selected.")],
    'phoneNumber': [phoneNumberValidationRule],
    'password': [
        functionValidationRule((p: string) => {return !p.includes(" ")}, "Password should not contain spaces"),
        regexpValidationRule(new RegExp("(?=.*[0-9])"), "Password should contain at least one digit"),
        regexpValidationRule(new RegExp("(?=.*[a-z])"), "Password should contain at least one lower case"),
        regexpValidationRule(new RegExp("(?=.*[A-Z])"), "Password should contain at least one upper case"),
        stringLengthValidationRule(8, null, 'Password should contain at least 8 characters'),
        notWhitespaceValidationRule()
    ],
    'confirmPassword': [stringLengthValidationRule(1, null, 'Confirm password is required.')],

    // billingAddress form
    'billingAddress.streetAddress1': [notWhitespaceValidationRule(), addressValidationRules.streetAddress1],
    'billingAddress.streetAddress2': [notWhitespaceValidationRule(), addressValidationRules.streetAddress2],
    'billingAddress.city': [notWhitespaceValidationRule(), addressValidationRules.city],
    'billingAddress.state': [notWhitespaceValidationRule(), addressValidationRules.state],
    'billingAddress.country': [notWhitespaceValidationRule(), addressValidationRules.country],
    'billingAddress.zipCode': [notWhitespaceValidationRule(), addressValidationRules.zipCode],

    // shippingAddress form
    'shippingAddress.streetAddress1': [notWhitespaceValidationRule(), addressValidationRules.streetAddress1],
    'shippingAddress.streetAddress2': [notWhitespaceValidationRule(), addressValidationRules.streetAddress2],
    'shippingAddress.city': [notWhitespaceValidationRule(), addressValidationRules.city],
    'shippingAddress.state': [notWhitespaceValidationRule(), addressValidationRules.state],
    'shippingAddress.country': [notWhitespaceValidationRule(), addressValidationRules.country],
    'shippingAddress.zipCode': [notWhitespaceValidationRule(), addressValidationRules.zipCode],
})