import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { useStyles } from './employeeConversationsComponent.styles';
import { useFacade } from './employeeConversationsComponent.hooks';
import { EmployeeMessagesTabsComponent } from '../employeeConversationsTabsComponent/EmployeeConversationsTabsComponent';
import { EmployeeChatComponent } from '../employeeChatComponent/EmployeeChatComponent';
import { InternalChatComponent } from '../internalChatComponent/InternalChatComponent';
import { MessagesPlaceHolder } from '../../../common/components/wildHealthPlaceHolder/MessagesPlaceHolder';
import { ConversationType } from '../../models/conversation.models';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

interface EmployeeConversationsComponentProps {
    targetConversationId?: number;
}

export const EmployeeConversationsComponent: React.FC<EmployeeConversationsComponentProps> = (props: EmployeeConversationsComponentProps) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMiddleScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [
        {
            targetConversation,
            forwardTurnOn,
        },
        handleGoBack,
        resetTargetConversation
    ] = useFacade();

    const renderChatsList = () => {
        return (
            <Box className={classes.chatList} hidden={isMiddleScreen && targetConversation !== null}>
                <EmployeeMessagesTabsComponent />
            </Box>
        )
    }

    const renderChat = () => {
        return (
            <Box className={classes.chatRoot}>
                {!isMiddleScreen && forwardTurnOn && 
                    <Box display="flex" alignItems="center" className="wh-tw-bg-average1 wh-tw-px-5 wh-tw-py-2.5 wh-tw-mb-3.5 wh-tw-w-fit wh-tw-rounded-sm">
                        <Box display="flex" alignItems="center" justifyContent="center" className="wh-tw-bg-white wh-tw-rounded-full wh-tw-p-0.5" width="26px" height="26px"><WarningRoundedIcon className="wh-tw-text-average1 -wh-tw-mt-1" /></Box>
                        <Box className="wh-tw-text-white wh-tw-font-medium wh-tw-ml-3.5">Your account has “message forwarding” turned on. Please go to message settings if you want to turn it off. </Box>
                    </Box>
                }
                <Box className={classes.chat}>
                    {
                        targetConversation
                            ? React.createElement(targetConversation.type === ConversationType.Internal ? InternalChatComponent : EmployeeChatComponent, { targetConversation: targetConversation, resetTargetConversation: resetTargetConversation, handleGoBack: handleGoBack })
                            : <MessagesPlaceHolder message="Please select thread or start messaging." />
                    }
                </Box>
            </Box>
        )
    }

    return (
        <Box display='flex' className={classes.root}>
            {renderChatsList()}
            {isMiddleScreen ? targetConversation && renderChat() : renderChat()}
        </Box>
    )
};
