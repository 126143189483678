import React from 'react';
import {Dialog, DialogContent, DialogContentText, Box} from '@material-ui/core';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';

interface PdfLoaderComponentProps {
    isOpen: boolean;
}
export const PdfLoaderComponent : React.FC<PdfLoaderComponentProps> = (props: PdfLoaderComponentProps) => {
    const {isOpen} = props;
    return (
        <Box>
            <Dialog
                fullWidth
                open={isOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Box p={3} display="flex" justifyContent="flex-start">
                            <span className="size-1_25 text-medium text-accent">PDF is being generated, please wait...</span>
                        </Box>
                        <Box mt={2}>
                            <WildHealthLinearProgress/>
                        </Box>
                    </DialogContentText>
                </DialogContent> 
            </Dialog>
        </Box>
    );
}