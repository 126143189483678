import {DetailedQuestionnaireSectionNames, DetailedQuestionNames} from "../static/detailedQuestionnaire.static";
import {QuestionnaireResultModel} from "../models/questionnaire.models";
import {isFeatureFlag} from "../../common/components/featureFlags/featureFlags";
import {FeatureFlag} from "../../common/components/featureFlags/featureFlags.models";

const phqQuestions = [
    DetailedQuestionNames.PHQ2_1,
    DetailedQuestionNames.PHQ2_2,
];

const phqScores = new Map<string, number>()
    .set('', 0)
    .set('Not at all', 0)
    .set('Several Days', 1)
    .set('Nearly Every Day', 2);

export const isQuestionnaireSectionAvailable = (sectionName: string, result: QuestionnaireResultModel, featureFlags: any) => {
    if (sectionName === DetailedQuestionnaireSectionNames.PHQ2) {
        if (!isFeatureFlag(featureFlags, FeatureFlag.Phq2)) {
            return false;
        }
    }

    if (sectionName === DetailedQuestionnaireSectionNames.PHQ8) {
        if (!isFeatureFlag(featureFlags, FeatureFlag.Phq2)) {
            return false;
        }

        const scores = phqQuestions.map(key =>
        {
            const answer = result.answers.find(x => x.key === key)?.value ?? '';

            return phqScores.get(answer);
        });

        const totalScore =  scores.reduce<number>((accumulator, current) => {
            return accumulator + current;
        }, 0);

        return totalScore >= 2;
    }

    if (sectionName === DetailedQuestionnaireSectionNames.PREGNANCY_COMPLICATIONS) {
        const pregnantAnswer = result.answers.find(x => x.key === DetailedQuestionNames.PREGNANT)?.value ?? '';
        if (pregnantAnswer != 'Yes') {
            return false;
        }
    }

    return true;
}