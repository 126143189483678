import React from 'react';
import { useFacade } from "./newOtherOrderComponent.hooks";
import { Box, Divider, FormControl, FormControlLabel, RadioGroup, TextField } from '@material-ui/core';
import { BackTitleComponent } from '../../../../components/pageTitleComponent/PageTitleComponent';
import { useStyles } from './newOtherOrderComponent.styles';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import moment from 'moment';
import { Autocomplete } from '@material-ui/lab';
import { WildHealthRadio } from '../../../common/components/WildHealthRadio';
import { SignOffDialogComponent } from '../../../notes/components/signOffDIalogComponent/SignOffDialogComponent';
import ArrowDropDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { EmployeeShortModel } from '../../../employee/models/employee.models';
import {OtherOrderModel} from "../../models/otherOrders.models";
import {AddOnModel} from "../../../addons/models/addOns.models";
import {OrderDiagnosisComponent} from "../orderDiagnosisComponent/OrderDiagnosisComponent";

interface NewOtherOrderPageProps {
    patientId?: number;
    targetOrder?: OtherOrderModel | null;
    handleGoBack: Function;
}

export const NewOtherOrderComponent: React.FC<NewOtherOrderPageProps> = (props: NewOtherOrderPageProps) => {
    const { patientId, targetOrder, handleGoBack } = props;
    const classes = useStyles();

    const [
        {
            isLoading,
            testOrderGroup,
            otherEmployees,
            isProcessing,
            content,
            item,
        },
        handleSendForApproval,
        handleSaveAsDraft,
        handleDiscard,
        handleSaveAndComplete,
        handleDiagnosisChange,
        handlePrimaryDiagnosisChange,
        handleAdditionalInformationChanges,
        handleEmployeeSelect,
        handleUrgencyChanges,
        handlePreferenceChanges,
        handleOrderReasonChanges,
        handleAddOnSelect,
        handleAddonDescriptionChanges,
        canConfirm,
        canSendForApproval,
        handleOpenGroup,
        handleAutoComplete
    ] = useFacade(patientId, targetOrder, handleGoBack);

    const renderAddOn = (addOn: AddOnModel) => {
        return (
            <Box key={addOn.id}>
                <FormControlLabel value={String(addOn.id)} control={<WildHealthRadio />} label={addOn.name + (addOn.needSpecification ? " (specify)" : "")} />
                {item?.addOnId === addOn.id && addOn.needSpecification && <Box pl={2}>
                    <TextField
                        fullWidth
                        size="small"
                        id={`add-on-${addOn.name}`}
                        variant="outlined"
                        value={item.description}
                        InputProps={{ className: 'input' }}
                        onChange={(v) => {
                            handleAddonDescriptionChanges(v.target.value)
                        }}
                    />
                </Box>}
            </Box>
        )
    }

    const renderContent = () => {
        if (isLoading) {
            return <WildHealthLinearProgress />
        }

        return (
            <Box m={5}>
                <Box className={classes.title}>
                    New Order
                </Box>

                <Box mt={5}>
                    <Box display='flex' flexWrap='wrap'>
                        <Box width='50%' minWidth='200px' pr={3}>
                            <Box className={classes.subTitle}>Patient Name</Box>
                            <Box className={classes.infoBox}> {content.patientName}</Box>
                        </Box>
                        <Box minWidth='200px' width='50%' pr={3} >
                            <Box className={classes.subTitle}>Date of Birth</Box>
                            <Box className={classes.infoBox}>{moment(content.dateOfBirth).format('MM/DD/YYYY')}</Box>
                        </Box>
                    </Box>
                    <Box display='flex' flexWrap='wrap'>
                        <Box width='50%' minWidth='200px' pr={3}>
                            <Box className={classes.subTitle}>Patient Shipping Address</Box>
                            <Box className={classes.infoBox}>{!!content.patientAddress ? content.patientAddress : '-'}</Box>
                        </Box>
                        <Box minWidth='200px' width='50%' pr={3}>
                            <Box className={classes.subTitle}> Ordering Provider</Box>
                            <Box className=''>
                                <Autocomplete
                                    size="medium"
                                    fullWidth
                                    disableClearable
                                    placeholder={content.orderingProvider}
                                    options={otherEmployees}
                                    onChange={(event, employee: EmployeeShortModel) => handleEmployeeSelect(employee)}
                                    disabled={!otherEmployees.length}
                                    getOptionLabel={(employee) => employee.firstName + ' ' + employee.lastName}
                                    renderInput={(params) => <TextField placeholder={content.orderingProvider} size='small' variant="outlined" {...params} />}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box display='flex' flexWrap='wrap'>
                        <Box width='50%' minWidth='200px' pr={3}>
                            <Box className={classes.subTitle}> Insurance Provider</Box>
                            <Box className={classes.infoBox}>{!!content.insuranceProvider ? content.insuranceProvider : '-'}</Box>
                        </Box>
                        <Box minWidth='200px' width='50%' pr={3}>
                            <Box className={classes.subTitle}> Insurance ID</Box>
                            <Box className={classes.infoBox}>{!!content.insuranceId ? content.insuranceId : '-'}</Box>
                        </Box>
                    </Box>
                </Box>

                <Box mt={5}>
                    <Box className={classes.subTitle}>Test Being Ordered</Box>
                    <Box>
                        <FormControl color='primary' component="fieldset">
                            <RadioGroup aria-label="gender" name="gender1" value={String(item?.addOnId)} onChange={(event) => handleAddOnSelect(event.target.value)}>
                                {testOrderGroup.map(group => {
                                    return !!group.name
                                        ? <Box mt={2} mb={1} key={group.name}>
                                            <Box display='flex' alignContent='center' onClick={() => handleOpenGroup(group.name, !group.isOpen)} >
                                                <Box mr={0.5}>
                                                    {group.isOpen ? <ArrowDropDownIcon /> : <ArrowRight />}
                                                </Box>
                                                <Box className={classes.groupTitle}>{group.name}</Box>
                                            </Box>
                                            <Box ml={2} mb={2}>
                                                {
                                                    group.isOpen && group.items.map((addOn) => {
                                                        const isGroup = addOn.children?.length > 0;

                                                        if (isGroup) {
                                                            return <Box key={addOn.id}>
                                                                <FormControlLabel value={String(addOn.id)} control={<WildHealthRadio />} label={addOn.name + (addOn.needSpecification ? " (specify)" : "")} />
                                                                {
                                                                    (item?.addOnId === addOn.id || addOn.children.map(x => x.id).includes(item?.addOnId)) &&
                                                                    <Box ml={5}>
                                                                        <RadioGroup aria-label="gender" name="sub-group" value={String(item?.addOnId)} onChange={(event) => handleAddOnSelect(event.target.value)}>
                                                                            {
                                                                                addOn.children.map(child => {
                                                                                    return renderAddOn(child);
                                                                                })
                                                                            }
                                                                        </RadioGroup>
                                                                    </Box>
                                                                }
                                                            </Box>
                                                        }

                                                        return renderAddOn(addOn);
                                                    })
                                                }
                                            </Box>
                                            <Divider />
                                        </Box>
                                        : group.items.map((addOn) => {
                                            const isGroup = addOn.children?.length > 0;

                                            if (isGroup) {
                                                return <Box key={addOn.id}>
                                                    <FormControlLabel value={String(addOn.id)} control={<WildHealthRadio />} label={addOn.name + (addOn.needSpecification ? " (specify)" : "")} />
                                                    {
                                                        (item?.addOnId === addOn.id || addOn.children.map(x => x.id).includes(item?.addOnId)) &&
                                                        <Box ml={5}>
                                                            <RadioGroup aria-label="gender" name="sub-group" value={String(item?.addOnId)} onChange={(event) => handleAddOnSelect(event.target.value)}>
                                                                {
                                                                    addOn.children.map(child => {
                                                                        return renderAddOn(child);
                                                                    })
                                                                }
                                                            </RadioGroup>
                                                        </Box>
                                                    }
                                                </Box>
                                            }

                                            return renderAddOn(addOn);
                                        })
                                })}
                            </RadioGroup>
                        </FormControl>
                    </Box>
                </Box>

                <Box mt={5}>
                    <Box className={classes.subTitle}>Urgency (Time to Appointment)</Box>
                    <Box mb={2}>
                        <TextField
                            fullWidth
                            size="small"
                            id='urgency-referral'
                            variant="outlined"
                            value={content.urgencyTimeToAppointment}
                            InputProps={{ className: 'input' }}
                            onChange={(v) => {
                                handleUrgencyChanges(v.target.value)
                            }}
                        />
                    </Box>
                    <Box className={classes.subTitle}>All Diagnoses</Box>
                    <Box className={classes.subTitle}>
                        (Please select a primary diagnosis by clicking the star icon)
                    </Box>
                    <Box mb={2}>
                        <OrderDiagnosisComponent
                            selectedDiagnosis={content.diagnosis}
                            getAutoComplete={handleAutoComplete}
                            handleChanges={handleDiagnosisChange}
                            handlePrimaryChange={handlePrimaryDiagnosisChange}
                            primaryDiagnosisId={content.primaryDiagnosesId}
                            handleTextInfoChange={handleAdditionalInformationChanges}
                            grid={6}
                        />
                    </Box>
                    <Box className={classes.subTitle}>Reason for Order</Box>
                    <Box mb={2}>
                        <TextField
                            fullWidth
                            size="small"
                            id='order-reason'
                            variant="outlined"
                            value={content.reasonForOrder}
                            InputProps={{ className: 'input' }}
                            onChange={(v) => {
                                handleOrderReasonChanges(v.target.value)
                            }}
                        />
                    </Box>
                    <Box className={classes.subTitle}>Preferred Location</Box>
                    <Box mb={2}>
                        <TextField
                            fullWidth
                            size="small"
                            id='preference-referral'
                            variant="outlined"
                            value={content.preferredLocation}
                            InputProps={{ className: 'input' }}
                            onChange={(v) => {
                                handlePreferenceChanges(v.target.value)
                            }}
                        />
                    </Box>
                </Box>
                <Box mt={5} pb={5} display='flex' alignItems='center' justifyContent="space-between">
                    <Box display="flex">
                        <Box>
                            <WildHealthButton
                                width={151}
                                style={{ whiteSpace: 'nowrap' }}
                                size="large"
                                disabled={!canSendForApproval()}
                                id='sing-physical-history'
                                color="secondary"
                                onClick={() => handleSendForApproval()}
                            >
                                <span>Send for Approval</span>
                            </WildHealthButton>
                        </Box>
                        <Box ml={2}>
                            <WildHealthButton
                                width={151}
                                style={{ whiteSpace: 'nowrap' }}
                                size="large"
                                disabled={isProcessing}
                                id='sing-physical-history'
                                color="secondary"
                                onClick={() => handleSaveAsDraft()}
                            >
                                <span>Save as Draft</span>
                            </WildHealthButton>
                        </Box>
                        <Box ml={2}>
                            <WildHealthButton
                                width={195}
                                size="large"
                                style={{ whiteSpace: 'nowrap' }}
                                disabled={!canConfirm()}
                                id='sing-physical-history'
                                onClick={() => handleSaveAndComplete()}
                            >
                                <span>Sign and Complete</span>
                            </WildHealthButton>
                        </Box>
                    </Box>
                    <Box>
                        <WildHealthButton
                            size="large"
                            width={111}
                            disabled={isProcessing}
                            id='cancel-physical-history'
                            onClick={() => handleDiscard()}
                            color='tertiary'
                        >
                            Discard
                        </WildHealthButton>
                    </Box>
                </Box>
            </Box>
        )
    }

    return (
        <Box>
            <Box className={classes.bgMain}>
                <BackTitleComponent
                    title='Back to Orders'
                    action={() => handleGoBack()}
                />
            </Box>
            {
                renderContent()
            }
            <SignOffDialogComponent isOrderCreate={true} />
        </Box>
    )
}