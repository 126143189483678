import React from 'react';
import {
    Box,
    FormControl,
    MenuItem,
    Select,
} from '@material-ui/core';
import {displayLocation} from "../../helpers/displayLocation";
import {ProtectedElement} from "../../../common/components/ProtectedElement";
import {PermissionType, UserType} from "../../../auth/models/auth.enums";
import {LocationModel} from "../../models/locations.models"

interface SelectLocationComponentProps {
    locationId: number;
    locations: LocationModel[];
    handleChanges: Function,
}

export const SelectLocationComponent: React.FC<SelectLocationComponentProps> = (props: SelectLocationComponentProps) => {
    const { 
        locationId,
        locations,
        handleChanges,
    } = props;

    return (
        <>
            <Box display="flex" alignItems="center">
                <Box width={0.4}>
                    <h4>
                        POD:
                    </h4>
                </Box>
                <Box width={0.6} display="flex">
                    <Box width={0.8}>
                        <FormControl
                            fullWidth
                            color='primary'
                            variant="outlined"
                            size="small">
                            <ProtectedElement
                                userType={UserType.Employee}
                                element={
                                    <Select
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                        }}
                                        value={locationId ?? 0}
                                        onChange={(e) => handleChanges(e.target.value)}>
                                        {
                                            locations
                                                .map((location, index) =>
                                                    <MenuItem key={index} value={location.id}>
                                                        {
                                                            displayLocation(location)
                                                        }
                                                    </MenuItem>
                                                )
                                        }
                                    </Select>
                                }
                                permissions={[PermissionType.MigrationBetweenLocations]}/>
                        </FormControl>
                    </Box>
                </Box>
            </Box>
        </>
    )
};