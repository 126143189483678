import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { colors } from "../../../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        borderColor: colors.gray4,
        borderRadius: 2,
        '& label.Mui-focused': {
            color: colors.main,
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: colors.main,
            },
            borderColor: colors.gray4,
            borderRadius: 2,
        },
    },
    dialog: {
      '& .MuiDialog-paper': {
        borderRadius: 20,
      },
      [theme.breakpoints.down("xs")]: {
        '& .MuiDialog-paper': {
          borderRadius: '28px 28px 0 0',
          margin: 0,
          marginTop: 'auto',
          width: '100%'
        },
      },
    },
    dialogContent: {
      display: "flex",
      flexDirection: "column",
      width: 525,
      paddingTop: 30,
      [theme.breakpoints.down("xs")]: {
        marginRight: 0,
        marginLeft: 0,
        width: '100%',
      },
    },
  })
);


