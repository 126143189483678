import { Box, useTheme, useMediaQuery } from "@material-ui/core";
import React from 'react';
import { AuthenticatedLayoutComponent } from '../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { PagesNamesService } from "../../../constants/pagesNames.constants";
import { TitlesService } from '../../../constants/title.constants';
import { MenuItemTypes } from "../../../models/menu.models";
import commonUseStyles from "../../common/styles/common.styles";
import {HealthScoreComponent} from "../components/healthScoreComponent/HealthScoreComponent";
import clsx from "clsx";

export const HealthScorePage: React.FC = () => {
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <AuthenticatedLayoutComponent
            title={TitlesService.patientDashboardTitle}
            pageName={PagesNamesService.healthScore}
            selectedMenuItem={MenuItemTypes.HealthScore}
            hideBackground
        >
            <Box mt={isSmallScreen && 0.5} p={isSmallScreen && 2}>
                {isSmallScreen && (
                    <Box py={2} className={clsx(commonClasses.size24, commonClasses.textSemiBold, commonClasses.colorBlack)}>
                        {PagesNamesService.healthScore}
                    </Box>
                )}
                {
                    <HealthScoreComponent/>
                }
            </Box>
        </AuthenticatedLayoutComponent>
    )
}

