import React from "react";
import { Box, useTheme, useMediaQuery } from "@material-ui/core";
import FilterListIcon from '@material-ui/icons/FilterList';
import { CheckboxDropdown } from "../../../common/components/checkBoxDropdown/CheckboxDropdown";
import { CheckboxDropdownItem } from "../../../common/models/checkboxDropdown.models";

interface ManageLabInputDatesComponentProps {
    items: CheckboxDropdownItem[];
    handleChanges: Function;
}

export const ManageLabInputDatesComponent: React.FC<ManageLabInputDatesComponentProps> = (props: ManageLabInputDatesComponentProps) => {
    const {
        items,
        handleChanges
    } = props;
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <CheckboxDropdown
            placeHolder={isSmallScreen ? <FilterListIcon className="wh-tw-text-primaryV" /> :
                <Box display="flex" alignItems="center" justifyContent="space-between" className='wh-tw-w-48'>
                    <Box className="wh-tw-text-black">
                        <span>Filter Labs</span>
                    </Box>
                    <FilterListIcon className="wh-tw-text-black" />
                </Box>
            }
            handleCheck={(key, value) => handleChanges(key, value)}
            items={items}
            isMobile={isSmallScreen}
            width={200}
            height={48}
        />
    )
}
