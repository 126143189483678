import { Box } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { useFacade } from './editAiMessage.hooks';
import { ConfirmButton, DiscardButton, InputField } from './editAiMessage.styles';

interface EditAiMessageProps {
    message: string;
    handleConfirm: (message: string) => void;
    handleDiscard: () => void;
}

export const EditAiMessage: React.FC<EditAiMessageProps> = (props: EditAiMessageProps) => {
    const {
        message: initMessage,
        handleConfirm,
        handleDiscard,
    } = props;

    const [
        {
            message,
            acceptingLoading
        },
        handleKeyDown,
        handleChangeMessage
    ] = useFacade(initMessage, handleConfirm);

    return (
        <div className="wh-tw-flex">
            <Box flex={1}>
                <InputField
                    fullWidth
                    multiline
                    rowsMax={8}
                    value={message}
                    id="editing-ai-message"
                    variant="outlined"
                    inputProps={{ className: 'flat-scroll outline-none' }}
                    placeholder='Type message here...'
                    onKeyDown={(e) => handleKeyDown(e, message)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeMessage(e.target.value)}
                />
            </Box>

            <Box
                m={1}
                display='flex'
                alignItems='center'
            >
                <Box m={1}>
                    <DiscardButton id="edit-ai-message-close" onClick={() => handleDiscard()}>
                        <CloseIcon className="wh-tw-fill-current" />
                    </DiscardButton>
                </Box>
                <Box m={1}>
                    <ConfirmButton
                        id="edit-ai-message-confirm"
                        onClick={() => handleConfirm(message)}
                        disabled={message.trim().length < 1 || acceptingLoading}
                    >
                        <CheckIcon className="wh-tw-fill-current" />
                    </ConfirmButton>
                </Box>
            </Box>
        </div>
    )
};
