import { Box, FormControl, FormGroup, FormHelperText, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import React from 'react';
import { QuestionComponentProps } from "./QuestionComponentProps";
import { CheckboxQuestionaris, FormControlLabelQuestionaris } from "./questionnaire.styles";

export const QuestionSelectCustomComponent: React.FC<QuestionComponentProps> = (props: QuestionComponentProps) => {
    const {
        question,
        answer,
        handleChanges,
        error
    } = props;
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const isChecked = (variant: string) => {
        return answer === variant;
    }

    const handleCheck = (variant: string) => {
        handleChanges(variant)
    }

    return (
        <>
            <Box className={"check-question"}>
                <FormControl
                    color='primary'
                    fullWidth
                    error={!!error}
                    component="fieldset">
                    <FormHelperText>{error}</FormHelperText>
                    <FormGroup>
                        <Grid container spacing={isSmallScreen ? 1 : 3}>

                            {
                                question.variants.map((variant, i) => {
                                    return <Grid item md={6} sm={12} xs={12} key={i}>
                                        <FormControlLabelQuestionaris
                                            className={isChecked(variant) ? 'checked' : null}
                                            key={i}
                                            control={
                                                <CheckboxQuestionaris
                                                    className="check-box"
                                                    checked={isChecked(variant)}
                                                    icon={<CheckIcon style={{ color: 'transparent' }} />}
                                                    checkedIcon={<CheckIcon />}
                                                    size="small"
                                                    name={variant}
                                                    key={variant}
                                                    onChange={(v) => handleCheck(v.target.name)}
                                                />
                                            }
                                            label={variant}
                                        />
                                    </Grid>
                                })
                            }
                        </Grid>
                    </FormGroup>
                </FormControl>
            </Box>
        </>
    )
}