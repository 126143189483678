import Axios from 'axios-observable';
import {
    patientSupplementsStore,
    PatientSupplementsStore
} from "../stores/patientSupplementsStore/patientSupplements.store";
import {authHeader} from "../../common/helpers/auth-header";
import {snackService} from "../../common/snack/state";
import {PatientSupplementModel, SupplementSource} from '../models/patientSupplementModel';
import {Observable} from 'rxjs';

/**
 * Provides method for working with patient supplements
 */
export class PatientsSupplementsService {
    constructor(private patientSupplementsStore: PatientSupplementsStore) {
    }

    public get(): Observable<any> {
        const url = `${process.env.REACT_APP_API_URL}PatientSupplements`;
        const config = { headers: authHeader() };

        return new Observable((observer) => {
            Axios.get(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.patientSupplementsStore.update({patientSupplements: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        })
    }

    public create(model: PatientSupplementModel): Observable<any> {
        const url = `${process.env.REACT_APP_API_URL}PatientSupplements`;
        const config = { headers: authHeader() };

        return new Observable((observer) => {
            Axios.post(url, model, config)
                .pipe()
                .subscribe(
                    (response) => {
                        snackService.success('New Supplement Added!');
                        patientSupplementsStore.create(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        })
    }

    public edit(model: PatientSupplementModel): Observable<any> {
        const url = `${process.env.REACT_APP_API_URL}PatientSupplements`;
        const config = { headers: authHeader() };

        return new Observable((observer) => {
            Axios.put(url, model, config)
                .pipe()
                .subscribe(
                    (response) => {
                        snackService.success('Changes Saved!');
                        patientSupplementsStore.edit(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        })
    }

    public delete(id: number): Observable<any> {
        const url = `${process.env.REACT_APP_API_URL}PatientSupplements/${id}`;
        const config = { headers: authHeader() };

        return new Observable((observer) => {
            Axios.delete(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        snackService.deleteSuccess('Supplement Was Deleted!');
                        patientSupplementsStore.delete(response.data.id)
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        })
    }
}

export const patientsSupplementsService = new PatientsSupplementsService(patientSupplementsStore);
