import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { Page } from "./tracker"

function HistoryTracking(props) {
    const location = useLocation()

    useEffect(() => {
        Page()
    },[location])

    return (
        <></>
    );
}

export default HistoryTracking