import React, { useState, useEffect, useCallback } from "react";
import { onEmit } from "../../../../common/helpers/on-emit";
import { Subscription } from "rxjs";
import { ReactComponent as FolderOpenIcon } from '@img/icons/FolderOpen.svg';
import { ReactComponent as EllipseIcon } from '@img/icons/Ellipse1.svg';
import { ShortcutGroupModel, ShortcutModel } from "../../../models/shortcut.models";
import { shortcutsQuery, shortcutsStore } from "../../../stores/shortcutsStore";
import { colors } from "../../../../common/constants/colors";

interface ShortcutGroupsListComponentState {
    expanded: number[];
    selectedShortcut: ShortcutModel;
    search: string;
    filteredGroup: ShortcutGroupModel[];
}

export function useFacade(groups: ShortcutGroupModel[]): [
    ShortcutGroupsListComponentState,
    (group: ShortcutGroupModel) => void,
    (shortcut: ShortcutModel) => void,
    (group: ShortcutGroupModel) => JSX.Element,
    (search: string) => void] {

    const [state, setState] = useState({
        expanded: [],
        selectedShortcut: null,
        search: '',
        filteredGroup: []
    } as ShortcutGroupsListComponentState);

    const handleGroupClick = (group: ShortcutGroupModel) => {
        const indexOf = state.expanded.indexOf(group.id);
        if (indexOf >= 0) {
            const expanded = state.expanded.slice();
            expanded.splice(indexOf, 1);
            setState({
                ...state,
                expanded: expanded
            });
        } else {
            const expanded = state.expanded.slice();
            expanded.push(group.id);
            setState({
                ...state,
                expanded
            });
        }
    }

    const handleShortcutClick = (shortcut: ShortcutModel) => {
        shortcutsStore.setTargetShortcut(shortcut);
    }

    const renderGroupIcon = (group: ShortcutGroupModel) => {
        if (!group.shortcuts.length) {
            return <EllipseIcon />
        }
        if (state.selectedShortcut && state.expanded.includes(group.id) && group.id === state.selectedShortcut.groupId) {
            return <FolderOpenIcon fill={colors.primaryV} />
        }

        return <FolderOpenIcon fill={colors.gray400} />
    }

    const handleFiltering = useCallback((search: string) => {
        const trimedSearch = decodeURIComponent(search).trim().toLowerCase();
        const arrayTrimSearchKey = trimedSearch.split(' ');

        let filtered = groups?.filter(g => arrayTrimSearchKey.find(item => g.name?.toLowerCase().includes(item)) !== undefined);

        setState(state => ({ ...state, search: search, filteredGroup: filtered }));
    }, [groups]);

    useEffect(() => {
        handleFiltering(state.search);

        return () => { };
    }, [handleFiltering]);

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<ShortcutModel>(shortcutsQuery.targetShortcut$, targetShortcut => {
                setState(state => ({
                    ...state,
                    selectedShortcut: targetShortcut
                }));
            })
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [state, handleGroupClick, handleShortcutClick, renderGroupIcon, handleFiltering];
}