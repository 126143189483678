import React from "react";
import { WildHealthTabControl } from "../../../common/components/wildHealthTabControl/WildHealthTabControl";
import { FileInputType } from "../../models/input.models";
import { InputsComponentProps } from "../inputsComponentProps";
import { UploadInputsDialog } from "../uploadInputsDialog/UploadInputsDialog";
import { useFacade } from "./dataFilesComponent.hooks";
import { UploadInputsFilesComponent } from "../uploadInputsFilesComponent/UploadInputsFilesComponent";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { isElementEnabled } from "../../../common/components/ProtectedElement";
import { isFeatureFlag } from '../../../common/components/featureFlags/featureFlags';
import { FeatureFlag } from '../../../common/components/featureFlags/featureFlags.models';
import { PermissionType, UserType } from "../../../auth/models/auth.enums";
import { useFlags } from 'launchdarkly-react-client-sdk';

export const DataFilesComponent: React.FC<InputsComponentProps> = (props: InputsComponentProps) => {
    const { patientId } = props;

    const [
        {
            isLoading,
        },
    ] = useFacade(patientId);

    const featureFlags = useFlags();
    const canEmployeeUpload = isElementEnabled([PermissionType.LabsModification], UserType.Employee);
    const canPatientUpload = isElementEnabled([], UserType.Patient);
    const canDownloadLabs = true;
    const canDownloadMicrobiome = true;
    const canDownloadDna = isFeatureFlag(featureFlags, FeatureFlag.DnaCanDownload);

    const tabs = [
        {
            title: 'Lab',
            content: <UploadInputsFilesComponent patientId={patientId} title="Lab Files" fileType={FileInputType.LabResults} canDownload={canDownloadLabs} canUpload={canEmployeeUpload} />,
        },
        {
            title: 'DNA',
            content: <UploadInputsFilesComponent patientId={patientId} title="DNA Files" fileType={FileInputType.DNAReport} canDownload={canDownloadDna} canUpload={canEmployeeUpload || canPatientUpload} />,
        },
        {
            title: 'Microbiome',
            content: <UploadInputsFilesComponent patientId={patientId} title="Microbiome Files" fileType={FileInputType.MicrobiomeData} canDownload={canDownloadMicrobiome}  canUpload={canEmployeeUpload || canPatientUpload} />,
        },
    ];

    return (
        isLoading ?
            <WildHealthLinearProgress/> :
            <>
                <WildHealthTabControl items={tabs} />
                {
                    (canEmployeeUpload || canPatientUpload) &&
                    <UploadInputsDialog />
                }
            </>
    );
}