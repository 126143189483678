import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Subscription } from "recompose";
import { navigationService } from "../../../../../services/navigation.service";
import { onEmit } from "../../../../common/helpers/on-emit";
import { PatientMedicationModel } from '../../../../patientSupplements/models/patientMedicationModel';
import { PatientSupplementModel } from '../../../../patientSupplements/models/patientSupplementModel';
import { patientsMedicationsService } from '../../../../patientSupplements/services/patientMedications.service';
import { patientsSupplementsService } from '../../../../patientSupplements/services/patientSupplements.service';
import { patientMedicationsQuery } from '../../../../patientSupplements/stores/patientMedicationsStore/patientMedications.query';
import { patientSupplementsQuery } from '../../../../patientSupplements/stores/patientSupplementsStore/patientSupplements.query';
import { GetMaximumVersionOfNote } from '../../../helpers/noteHelpers';
import { NoteContentModel, NoteModel } from '../../../models/notes.models';
import { initialConsultNotePdfService } from '../../../services/notes-pdf.service';
import { notesService } from '../../../services/notes.service';
import { notesQuery } from '../../../stores/notes';

interface PatientInitialConsultPageState {
    note: NoteModel;
    content: NoteContentModel;
    medications: PatientMedicationModel[];
    supplements: PatientSupplementModel[];
    isPdfDownloading: boolean;
}

export function useFacade(noteId: number): [PatientInitialConsultPageState,
    () => void,
    (note: NoteModel, content: NoteContentModel, medications: PatientMedicationModel[], supplements: PatientSupplementModel[]) => void
    ] {
    const [state, setState] = useState({
        note: notesQuery.getNote(noteId),
        content: null,
        medications: [],
        supplements: [],
        isPdfDownloading: false
    } as PatientInitialConsultPageState);

    const history = useHistory();

    const handleGoBack = () => {
        navigationService.toPatientNotes(history);
    }

    const handleDownloadPdf = (note: NoteModel,
        content: NoteContentModel,
        medications: PatientMedicationModel[],
        supplements: PatientSupplementModel[]) => {
        setState({
            ...state,
            isPdfDownloading: true
        });

        const cb = () => setState({ ...state, isPdfDownloading: false });

        const noteForDownload = GetMaximumVersionOfNote(note);
        notesService.downloadNote(noteForDownload.id, noteForDownload.name, false).subscribe(
            cb,
            () => {
                initialConsultNotePdfService.downloadNotePdf(noteForDownload, content, medications, supplements, false, noteForDownload.id).subscribe(cb, cb)
            }
        )
    }

    /**
     * Load all ongoing cares
     * Manage subscriptions with auto-cleanup
     */
    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<NoteContentModel>(notesQuery.targetContent$, content => {
                setState(state => ({...state, content: content}));
            }),
            onEmit<NoteModel[]>(notesQuery.notes$, () => {
                const note = notesQuery.getNote(noteId);
                setState(state => ({...state, note: note}));
                if (note){
                    notesService.getContentByPatient(GetMaximumVersionOfNote(note).id).subscribe(result =>
                        setState(state => ({
                            ...state,
                            content: result,
                        }))
                    )
                }
            }),
            onEmit<PatientMedicationModel[]>(patientMedicationsQuery.patientMedications$, medications => {
                setState(state => ({...state, medications: medications}));
            }),
            onEmit<PatientSupplementModel[]>(patientSupplementsQuery.patientSupplements$, supplements => {
                setState(state => ({...state, supplements: supplements}));
            })
        ];

        patientsSupplementsService.get().subscribe();
        patientsMedicationsService.get().subscribe();

        if (!state.note) {
            notesService.getByPatient().subscribe();
        }

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, [noteId]);

    return [state, handleGoBack, handleDownloadPdf];
}