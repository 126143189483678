import { useState } from 'react';

export interface SchedulingInformationDialogState{
    dialogMobileRef: any;
    isDrag: boolean;
    initialPosition: number;
    initialBottom: number;
    finalPosition: number;
    isRemove: boolean;
    threshold: number;
}

export function useFacade(dialogMobileRef: any, handleOpenSchedulingInfo: Function): [
    SchedulingInformationDialogState, 
    Function,
    Function,
    Function
] {

    const [state, setState] = useState({
        initialPosition: 0,
        finalPosition: 0,
        isDrag: false,
        threshold: 100,
        isRemove: false,
        initialBottom: dialogMobileRef?.current?.style?.bottom,
        dialogMobileRef: dialogMobileRef
    } as SchedulingInformationDialogState);

    const handleReset = (event) => {
        event.persist();

        if(!state.dialogMobileRef?.current){
            return;
        }

        state.dialogMobileRef.current.style.transform = 'none';
        state.dialogMobileRef.current.style.opacity = 1;

        setState(state => ({
            ...state,
            isDrag: false,
            initialPosition: 0,
            finalPosition: 0,
        }));

        if(state.isRemove){
            handleOpenSchedulingInfo(false, false);
        }
    }

    const handleInit = (event) => {
        event.persist();

        setState(state => ({
            ...state,
            isDrag: true,
            initialPosition: event.clientY || event.pageY,
            isRemove: false,
            threshold: dialogMobileRef?.current?.clientHeight / 3
        }));
    }

    const handleUpdate = (event) => {
        event.persist();

        if(state.isDrag && state.dialogMobileRef.current && state.initialPosition != null){
            const element = state.dialogMobileRef.current;
            const finalPos = event.clientY || event.pageY;
            const moved = finalPos - state.initialPosition;
            element.style.transform = `translateY(${moved}px)`;
            element.style.opacity = 1 - Math.pow((Math.abs(moved)/ state.threshold), 5);

            setState(state => ({
                ...state,
                finalPosition: finalPos,
                isRemove: false
            }));

            if(Math.abs(moved) >= state.threshold){
                setState(state => ({
                    ...state,
                    isRemove: true
                }));
            }
        }
    }

    return [
        state,
        handleInit,
        handleUpdate,
        handleReset
    ];
}