import {
    HealthSummaryDataModel,
    HealthSummaryMapItemModel,
    HealthSummaryMapModel
} from '../../models/healthSummary.models'
import { FeatureComponent } from '../../../common/components/featureFlags/FeatureComponent'
import { FeatureFlag } from '../../../common/components/featureFlags/featureFlags.models'
import { BlockItemComponent } from '../blockItemComponent/BlockItemComponent'
import { getKey, onlyUnique } from '../../helpers/healthSummaryHelper'
import { useStyles } from './HealthSummaryBlockItemsComponent.styles'
import { Box, Button, TextField } from '@material-ui/core'
import { colors } from '../../../common/constants/colors'
import AddIcon from '@material-ui/icons/Add'
import moment from 'moment'
import React from 'react'
import { HealthSummaryListItemsNoteComponentState } from '../../../notes/components/createHistoryAndPhysicalComponent/createHistoryAndPhysicalNoteComponent.hooks'

export interface HealthSummaryBlockItemsNoteComponentProps {
    data: HealthSummaryDataModel[]
    map: HealthSummaryMapModel
    rowFormat: boolean
    handleAddInformation: Function
    handleEditNewItem: Function
    handleSaveNewItem: Function
    handleEditItem: Function
    state: HealthSummaryListItemsNoteComponentState
    sectionOfNote: string
}

export const HealthSummaryBlockItemsNoteComponent: React.FC<HealthSummaryBlockItemsNoteComponentProps> = (props: HealthSummaryBlockItemsNoteComponentProps) => {
    const {
        data,
        map,
        rowFormat,
        handleAddInformation,
        handleEditNewItem,
        handleSaveNewItem,
        handleEditItem,
        state,
        sectionOfNote,
    } = props

    const classes = useStyles()

    const buildItems = (item: HealthSummaryMapItemModel) => {
        const newItem = (key: string, val: string, name: string = item.name, tooltip: string = '') => ({ key: key, name: name, value: val, tooltip: tooltip })

        const valueItems = data.filter(x => getKey(x.key) === item.key)

        const result = valueItems.map(valueItem => {
            if (!valueItem) {
                return newItem(item.key, '', item.name, '')
            }

            //use name as value if it is needed
            const value = map.useNameValue ? valueItem.name : valueItem.value
            //using name to view label
            if (item.viewLabelCondition === value) {
                return newItem(valueItem.key, item.name, '', valueItem.tooltip)
            }

            //apply date format
            if (item.dateFormat) {
                const date = moment(value)

                const formatedValue = date.isValid() ? date.format(item.dateFormat) : value

                return newItem(valueItem.key, formatedValue)
            }

            return newItem(valueItem.key, value, item.name, valueItem.tooltip)
        })

        return result
    }

    const getFooter = (items?: HealthSummaryMapItemModel[]) => {
        switch (map.key) {
            case 'SCREENING_HEALTH_MAINTENANCE': {

                const recommendation = items.find(it => it.key === 'APOE_RECOMMENDATION');
                const scorePercentage = items.find(it => it.key === 'APOE_ACCURACY_SCORE_VALUE');

                if (recommendation) {
                    return <Box className={classes.rowFormat} mt={3}>
                        <Box color={colors.bad} mr={1}>*</Box>
                        <Box color={colors.gray1} fontSize={14}>
                            <span style={{ fontWeight: "bold" }}>{recommendation.value}</span> Apo-ε is a challenging gene to test because it resides
                            on a very dense section of your genome. We take nearly 100 separate readings to help
                            translate the results and have developed a proprietary algorithm to generate the highest
                            possible accuracy. We also report the accuracy of this gene in order to better understand
                            the confidence of this test. More accurate laboratory testing is available if necessary or
                            desired.
                        </Box>
                    </Box>
                } else {
                    return <Box className={classes.rowFormat} mt={3}>
                        <Box color={colors.bad} mr={1}>*</Box>
                        <Box color={colors.gray1} fontSize={14}>Apo-ε is a challenging gene to test because it resides
                            on a very dense section of your genome. We take nearly 100 separate readings to help
                            translate the results and have developed a proprietary algorithm to generate the highest
                            possible accuracy. We also report the accuracy of this gene in order to better understand
                            the confidence of this test. More accurate laboratory testing is available if necessary or
                            desired.</Box>
                    </Box>
                }
            }
            default:
                return ''
        }
    }

    const getItemsByMapKey = (items, section) => {

        switch (map.key) {
            case 'SCREENING_HEALTH_MAINTENANCE':
                return items.filter(it => !it.key.includes('APOE')).map((item) =>
                    item.value &&
                    <Box className={classes.container}>
                        <BlockItemComponent
                            editMode={!newItem(section)}
                            value={item.value}
                            itemKey={item.key}
                            name={map.useNameValue ? '' : item.name}
                            sectionOfNote={sectionOfNote}
                            handleEditItem={handleEditItem} 
                        />
                    </Box>)
            case 'PROBLEMS_LIST':
                return items.map((item) =>
                    item.value &&
                    <Box className={classes.container}>
                        <BlockItemComponent
                            editMode={!newItem(section)}
                            value={item.value}
                            itemKey={item.key}
                            name={map.useNameValue ? '' : item.name}
                            tooltip={item.tooltip}
                            sectionOfNote={sectionOfNote}
                            handleEditItem={handleEditItem}
                        />
                    </Box>)
            default:
                return items.map((item) =>
                    item.value &&
                    <Box className={classes.container}>
                        <BlockItemComponent
                            editMode={!newItem(section)}
                            value={item.value}
                            itemKey={item.key}
                            name={map.useNameValue ? '' : item.name}
                            sectionOfNote={sectionOfNote}
                            handleEditItem={handleEditItem}
                        />
                    </Box>)
        }
    }

    const getHeader = (items) => {

        switch (map.key) {
            case "SCREENING_HEALTH_MAINTENANCE": {
                const apoeStatus = items.find(it => it.key === 'APOE_STATUS_VALUE');
                const apoeLabel = items.find(it => it.key === 'APOE_STATUS_LABEL');
                const apoeScore = items.find(it => it.key === 'APOE_ACCURACY_SCORE_VALUE');
                return !!apoeStatus ?
                    <>
                        <Box><span style={{ fontWeight: "bold" }}>{apoeLabel?.value}</span>: Apo-{apoeStatus?.value}  ({parseFloat(apoeScore.value)}%)</Box><Box color={colors.bad} mr={1}>*</Box>
                    </> : null
            }
            default:
                return null

        }

    }

    const getItems = (subSection: string) => {
        const newItem = (item) => ({
            key: item.key,
            name: map.useNameValue ? map.useNameValue : item.name,
            value: map.useNameValue ? item.name : item.value,
            tooltip: item?.tooltip
        })

        const subSectionItems = data
            .filter(x => x.key.includes(map.key) && x.key.includes(subSection ?? ''))
            .map(x => newItem(x))

        return map.items
            .filter(x => x.subSection === subSection)
            .flatMap(x => buildItems(x))
            .concat(subSectionItems)
    }

    const subSubsectionItem = (section: string, items) => ({
        section: section,
        items: items,
        hasItems: !!items.length
    })

    const sections = map.items
        .map(x => x.subSection)
        .filter(onlyUnique)
        .map(section => {
            return subSubsectionItem(section, getItems(section))
        })

    const newItem = (section: string) => state?.draft.find(x => x.section === section && x.isNew)

    const AddInformationItem = (section: string) =>
        <FeatureComponent featureFlag={FeatureFlag.HealthSummaryEdit}>
            {
                !newItem(section) &&
                <Button className={classes.button} onClick={() => {
                    handleAddInformation(section, sectionOfNote, map)
                }}>
                    <AddIcon fontSize='small' />
                    <Box className={classes.buttonText}>Add Condition</Box>
                </Button>
            }
            {
                newItem(section) &&
                <Box display='flex' alignItems='center'>
                    <TextField
                        onChange={(e) => {
                            handleEditNewItem(e.target.value)
                        }}
                        onBlur={() => {
                            handleSaveNewItem(sectionOfNote)
                        }}
                        value={newItem(section).value}
                        placeholder="Type here..."
                        variant="outlined"
                        size="small"
                        autoFocus
                        required
                    />
                </Box>
            }
        </FeatureComponent>
    return  <Box className={classes.columnFormat} mt={2}>
            <Box className={rowFormat ? classes.rowFormat : classes.columnFormat}>
                {
                    sections.map(({ section, items }) => {
                        switch (map.key) {
                            case "SCREENING_HEALTH_MAINTENANCE":
                                return <Box>
                                        <Box className={classes.title}>{section}</Box>
                                        <Box className={classes.itemsBlock}>
                                            {
                                                getHeader(items)
                                            }
                                        </Box>
                                        <Box>
                                            {
                                                getItemsByMapKey(items, section)
                                            }
                                        </Box>
                                        {AddInformationItem(section)}
                                        {getFooter(items)}
                                    </Box>
                            default: {
                                return <Box>
                                        <Box className={classes.title}>{section}</Box>
                                        <Box className={classes.itemsBlock}>
                                            {
                                                getItemsByMapKey(items, section)
                                            }
                                        </Box>
                                        {AddInformationItem(section)}
                                    </Box>
                            }
                        }
                    }
                    )
                }
            </Box>
        </Box>
}