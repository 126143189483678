import {
    Box,
    FormControl,
    FormHelperText,
    Grid,
    TextField,
    IconButton,
    InputAdornment,
    OutlinedInput,
} from '@material-ui/core';
import React from 'react';
import { User } from '../../../common/models/user.models';
import { IErrorState } from "../../../common/validation/error-state";
import commonUseStyles from '../../../common/styles/common.styles';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { ReactComponent as VisibilityOffOutlinedIcon } from '@img/icons/VisibilityOff.svg';
import { colors } from '../../../common/constants/colors';
import { useStyles } from "./welcomePatientInfoComponent.styles";

interface PatientInfoComponentProps extends IErrorState {
    payer: User;
    handleChanges: (field: string, value: any) => void;
    readonlyFields: string[],
    hiddenFields: string[]
}

export const WelcomePatientInfoComponent: React.FC<PatientInfoComponentProps> = (props: PatientInfoComponentProps) => {
    const {
        payer,
        handleChanges,
        errors,
        hiddenFields,
        readonlyFields,
    } = props;

    const [state, setState] = React.useState({
        showPassword: false,
    });
    const commonClasses = commonUseStyles();
    const classes = useStyles();

    const handleClickShowPassword = () => {
        setState({ ...state, showPassword: !state.showPassword });
    };

    return (
        <form autoComplete="off">
            <Box pt={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12} hidden={hiddenFields.includes('email')}>
                        <Box mb={1}>
                            <span className={commonClasses.label}>Email <span className={commonClasses.colorMint}>*</span></span>
                        </Box>
                        <TextField
                            fullWidth
                            size="small"
                            required
                            id="email"
                            placeholder="email@company.com"
                            disabled={readonlyFields.includes('email')}
                            value={payer.email}
                            InputProps={{ className: 'input', classes }}
                            variant="outlined"
                            error={!!errors['email']}
                            helperText={errors['email']}
                            onChange={(v) => handleChanges(v.target.id, v.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} hidden={hiddenFields.includes('password')}>
                        <FormControl
                            variant="outlined"
                            color='primary'
                            required
                            error={!!errors[`password`]}
                            size="small"
                            fullWidth
                            classes={{ root: classes.root }}
                        >
                            <Box mb={1}>
                                <span className={commonClasses.label}>Password <span className={commonClasses.colorMint}>*</span></span>
                            </Box>
                            <OutlinedInput
                                id="password"
                                placeholder="*********"
                                type={state.showPassword ? 'text' : 'password'}
                                value={payer.password}
                                error={!!errors['password']}
                                disabled={readonlyFields.includes('password')}
                                onChange={(v) => handleChanges(v.target.id, v.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            disabled={readonlyFields.includes('password')}
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            edge="end"
                                        >
                                            {state.showPassword ? <VisibilityOutlinedIcon htmlColor={colors.gray700} /> : <VisibilityOffOutlinedIcon fill={colors.gray700} />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText>{errors[`password`]}</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        </form>
    )
}
