import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { onEmit } from "../../../common/helpers/on-emit";
import { pageSizes } from "../../../common/pagination/models/page-sizes";
import { IPaginationState } from "../../../common/pagination/models/pagination-state";
import { getAvailablePages } from "../../../common/pagination/helpers/get-evailable-pages";
import { InsuranceModel, UpdateInsuranceStatesModel, AddInsuranceModel } from "../../models/insurance.models";
import { insuranceQuery } from "../../stores/insurance.query";
import { insuranceManagementService } from "../../services/insuranceManagement.service";
import { StateModel } from "../../../account/models/address.model";
import { addressService } from "../../../account/services/address.service";
import { SortingDirection } from "../../../common/sorting/models/sorting-destination";
import { ISortingState } from "../../../common/sorting/models/sorting-state";
import { handleCompare } from "../../../common/sorting/helpers/handle-compare";

export enum SortingSource {
    None,
    Name = "name"
}

interface ManageInsurancesState extends IPaginationState, ISortingState {
  isLoading: boolean;
  isStateLoading: boolean;
  isSubmitted: boolean;
  isAddingInsurance: boolean;
  insuranceCarriers: InsuranceModel[];
  insurances: InsuranceModel[];
  originalInsurances: InsuranceModel[];
  selectedCarrierId: number;
  selectedStates: StateModel[];
  selectedInsuranceId: number;
  selectedState: StateModel;
  oldStates: StateModel[];
  searchKey: string;
  menuAnchorEl: HTMLElement;
  removingInsuranceId: number;
  page: number;
  rowsPerPage: number;
}

let stateContext: ManageInsurancesState;

export function useFacade(): [
  ManageInsurancesState,
  (value: any) => void,
  (value: any) => void,
  () => void,
  () => void,
  (states: any) => void,
  (insuranceId: number, stateId: number) => void,
  (insuranceId: number) => void,
  (id: number, menuAnchorEl?: HTMLElement) => void,
  (query: string) => void,
  () => number[],
  (value: any) => void,
  (page: number) => void,
  (source: string, direction: SortingDirection) => void
] {
    const [state, setState] = useState({
      isLoading: true,
      isOrganiztionLoading: true,
      isStateLoading: true,
      isSubmitted: false,
      isAddingInsurance: false,
      insuranceCarriers: [],
      insurances: [],
      originalInsurances: [],
      selectedCarrierId: 0,
      selectedStates: [],
      selectedInsuranceId: 0,
      selectedState: null,
      oldStates: [],
      searchKey: '',
      menuAnchorEl: null,
      removingInsuranceId: 0,
      page: 0,
      rowsPerPage: pageSizes[0],
      totalCount: 0,
      selectedPage: 1,
      pageSize: pageSizes[1],
      sortingColumns: [
          {title: 'Insurance Carrier', direction: SortingDirection.Asc, source: SortingSource.Name},
          {title: 'States'},
          {title: 'Actions'},
      ],
      sortingSource: SortingSource.None,
    } as ManageInsurancesState);

    stateContext = state;

    const handleToggleStates = (insuranceId: number, menuAnchorEl?: HTMLElement) => {
      setState(state => ({ 
        ...state, 
        menuAnchorEl,
        selectedInsuranceId: insuranceId,
        oldStates: state.insurances.find(i => i.id === insuranceId)?.states,
      }))
    }

    const handleOnFilter = (search: string) => {
      setState(state => ({...state, searchKey: search}));
    }

    const handleSearchSubmit = (query: string) => {
      const searchQuery = query ?? '';

      const trimedSearch = decodeURIComponent(searchQuery).trim().toLowerCase();
      const arrayTrimSearchKey = trimedSearch.split(' ');

      const filteredInsurances = state.originalInsurances?.filter(
        (e) =>
          arrayTrimSearchKey.find((item) =>
            e.name?.toLowerCase().includes(item)
          ) !== undefined ||
          arrayTrimSearchKey.find((item) =>
            e.states.find(state => state.name?.toLowerCase().includes(item)) !== undefined
          ) !== undefined
      );
      
      setState(state => ({
        ...state,
        isLoading: false,
        selectedPage: 1,
        insurances: searchQuery ? filteredInsurances : state.originalInsurances,
        totalCount:  searchQuery ? filteredInsurances.length : state.originalInsurances.length,
      }));
    }

    const getAllAvailablePages = () => {
      return getAvailablePages(state);
    }

    const handlePageSizeChange = (value: any) => {
      if (value === state.pageSize) {
        return;
      }

      setState(state => ({
        ...state,
        pageSize: value,
        selectedPage: 1
      }));
    }

    const handlePageChange = (page: number) => {
      if (page === state.selectedPage) {
        return;
      }

      setState(state => ({
        ...state,
        selectedPage: page
      }));
    }

    const handleStatesChange = (value) => {
      setState(state => ({
        ...state,
        selectedStates: value
      }));
    }

    const handleChanges = (value: any) => {
      setState({...state, selectedCarrierId: value });
    }

    const handleAddInsurance = () => {
      setState({...state, isAddingInsurance: true })

      const cb = () => setState(state => ({ ...state, isAddingInsurance: false }));

      const addInsurance:AddInsuranceModel = {
        insurance: state.insuranceCarriers.find(o => o.id === state.selectedCarrierId),
        states: state.selectedStates
      }
      insuranceManagementService.addInsurance(addInsurance).subscribe(() => {
        setState(state => ({
          ...state,
          selectedCarrierId: 0,
          selectedStates: [],
          searchKey: '',
          isAddingInsurance: false
        }))
      }, cb);
    }

    const handleAddState = (states: any) => {
      setState({...state, menuAnchorEl: null, isSubmitted: true })

      const cb = () => setState(state => ({ ...state, isSubmitted: false }));
      const updateInsuranceStates:UpdateInsuranceStatesModel = {
        id: state.selectedInsuranceId,
        states: [states]
      };
      insuranceManagementService.addInsuranceStates(updateInsuranceStates).subscribe((response) => {
        const insurances = state.insurances.map(i => i.id === response.id ? response : i);
        setState(state => ({
          ...state,
          insurances: insurances,
          totalCount: insurances.length,
          isSubmitted: false
        }))
      }, cb);
    }

    const handleCancel = () => {
      setState(state => ({
        ...state,
        selectedCarrierId: 0,
        selectedStates: [],
      }))
    }

    const handleRemove = (insuranceId: number, stateId: number) => {
      setState({...state, isSubmitted: true })

      const cb = () => setState(state => ({ ...state, isSubmitted: false }));
      const targetInsurance = state.insurances.find(i => i.id === insuranceId);
      const updateInsuranceStates:UpdateInsuranceStatesModel = {
        id: insuranceId,
        states: targetInsurance.states.filter(s => s.id === stateId)
      };

      insuranceManagementService.removeInsuranceStates(updateInsuranceStates).subscribe((response) => {
        if (response.states.length > 0) {
          const insurances = state.insurances.map(i => i.id === response.id ? response : i);
          setState(state => ({
            ...state,
            insurances: insurances,
            totalCount: insurances.length,
            isSubmitted: false
          }))
        } else {
          const insurances = state.insurances.filter(i => i.id !== response.id);
          setState(state => ({
            ...state,
            insurances: insurances,
            totalCount: insurances.length,
            isSubmitted: false
          }))
        }
      }, cb);
    }

    const handleRemoveAllStates = (insuranceId: number) => {
      const targetInsurance = state.insurances.find(i => i.id === insuranceId);
      if (!targetInsurance.states.length) return;
      setState({...state, removingInsuranceId: insuranceId, isSubmitted: true })
      const cb = () => setState(state => ({ ...state, removingInsuranceId: 0, isSubmitted: false }));
      const updateInsuranceStates:UpdateInsuranceStatesModel = {
        id: insuranceId,
        states: targetInsurance.states
      };

      insuranceManagementService.removeInsuranceStates(updateInsuranceStates).subscribe((response) => {
        const insurances = state.insurances.filter(i => i.id !== response.id);
        setState(state => ({
          ...state,
          insurances: insurances,
          totalCount: insurances.length,
          removingInsuranceId: 0,
          isSubmitted: false
        }))
      }, cb);
    }

    const setDirection = (source: string, direction: SortingDirection) => {
      const itemIndex = state.sortingColumns.findIndex(item => item.source === source);
      state.sortingColumns[itemIndex].direction = direction;
      setState(state => ({ ...state, columns: state.sortingColumns }));
    }

    const handleSorting = (source: string, direction: SortingDirection) => {
      if (state.sortingSource === source) {
        direction = direction === SortingDirection.Asc
          ? SortingDirection.Desc
          : SortingDirection.Asc;

        setDirection(source, direction);
      }
      setState(state => ({
        ...state,
        sortingSource: source,
        insurances: state.insurances.sort((p1, p2) => handleCompare(p1, p2, direction, source))
      }));
    }

    const useEffectCB = () => {
      const subscriptions: Subscription[] = [
        onEmit<InsuranceModel[]>(insuranceQuery.insurances$, insurances => {
          const sortedInsurances = insurances.sort((p1, p2) => handleCompare(p1, p2, SortingDirection.Desc, SortingSource.Name));
          const hasStatesInsurances = sortedInsurances.filter(i => i.states.length);
          setState(state => ({
            ...state,
            insuranceCarriers: sortedInsurances,
            insurances: hasStatesInsurances,
            originalInsurances: hasStatesInsurances,
            totalCount: hasStatesInsurances.length
          }));
        })
      ];

      const cb = () => setState(state => ({ ...state, isLoading: false }));
      const getStateCB = () => setState(state => ({ ...state, isStateLoading: false }))

      addressService.getStates().subscribe(getStateCB, getStateCB);
      insuranceManagementService.getInsurances().subscribe(cb, cb);

      return () => {
        subscriptions.map(it => it.unsubscribe())
      };
    }

    useEffect(useEffectCB, []);

    useEffect(() => {
      const delayDebounceFn = setTimeout(() => {
        if (!stateContext.isSubmitted && !stateContext.isLoading) {
          handleSearchSubmit(stateContext.searchKey);
        }
      }, 1000);

      return () => clearTimeout(delayDebounceFn)
    }, [stateContext.searchKey]);

    return [
        state,
        handleChanges,
        handleStatesChange,
        handleAddInsurance,
        handleCancel,
        handleAddState,
        handleRemove,
        handleRemoveAllStates,
        handleToggleStates,
        handleOnFilter,
        getAllAvailablePages,
        handlePageSizeChange,
        handlePageChange,
        handleSorting,
    ];
}