import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import React from "react";
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import { colors } from "../../../common/constants/colors";

interface FellowshipRegistrationNextButtonProps {
    isLoading?: boolean; 
    canGoNext: boolean;
    goNext: () => void;
    label?: string;
}

export const FellowshipRegistrationNextButton: React.FC<FellowshipRegistrationNextButtonProps> = (props: FellowshipRegistrationNextButtonProps) => {
    const {
        isLoading = false,
        canGoNext,
        goNext,
        label
    } = props;

    return (
        <WildHealthButton
            id="fellowship-registration-next"
            size="large"
            width={198}
            disabled={!canGoNext}
            onClick={() => goNext()}
        >
            { isLoading ? <CircularProgress size={24} style={{ color: colors.white }} /> : label ?? 'Next Step'}
        </WildHealthButton>
    );
}