import {Query} from '@datorama/akita';
import {PatientSupplementsState, patientSupplementsStore, PatientSupplementsStore} from "./patientSupplements.store";

/**
 * Provides coaches queries
 */
export class PatientSupplementsQuery extends Query<PatientSupplementsState> {

    patientSupplements$ = this.select(state => state.patientSupplements);
    editModel$ = this.select(state => state.editModel);

    constructor(protected store: PatientSupplementsStore) {
        super(store);
    }
}

export const patientSupplementsQuery = new PatientSupplementsQuery(patientSupplementsStore);