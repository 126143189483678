import React from 'react';
import { authQuery } from '../../../auth/stores/auth';
import { WildHealthTabControl } from '../../../common/components/wildHealthTabControl/WildHealthTabControl';
import { AppointmentsOrderComponent } from '../appointmentsOrderComponent/AppointmentsOrderComponent';
import { DnaComponent } from '../dnaComponent/DnaComponent';
import { EpigeneticComponent } from '../epigeneticComponent/EpigeneticComponent';
import { LabComponent } from '../labComponent/LabComponent';
import { useFacade } from "./ordersComponent.hooks";
import { TabItem } from '../../../common/components/wildHealthTabControl/wildHealthTabControl.hooks';
import { OtherDiagnosticComponent } from '../otherDiagnosticComponent/OtherDiagnosticComponent';
import { ReferralsOrdersComponent } from '../referralsOrdersComponent/ReferralsOrdersComponent';

interface OrderHistoryComponentProps {
    patientId: number;
}

export const OrdersComponent: React.FC<OrderHistoryComponentProps> = (props: OrderHistoryComponentProps) => {
    const { patientId } = props;

    const [
        {
            isLoading,
            gender
        },
    ] = useFacade(patientId);

    const items: TabItem[] = [
        {
            title: 'Lab orders',
            content: <LabComponent patientId={patientId} gender={gender} />,
            keepMounted: true,
        },
        {
            title: 'DNA orders',
            content: <DnaComponent patientId={patientId} addOnsLoading={isLoading} />,
            keepMounted: true,
        },
        {
            title: 'Epigenetic orders',
            content: <EpigeneticComponent patientId={patientId} />,
            keepMounted: true,
        },
        {
            title: 'Other Diagnostic Orders',
            content: <OtherDiagnosticComponent patientId={patientId}/>,
            keepMounted: true,
        },
        {
            title: 'Referrals Orders',
            content: <ReferralsOrdersComponent patientId={patientId} />,
            keepMounted: true,
        }
    ];

    if (!authQuery.isLicensingPractice()) {
        items.push({
            title: 'Appointments',
            content: <AppointmentsOrderComponent patientId={patientId} />,
            keepMounted: true,
        });
    }

    return <WildHealthTabControl items={items} />
}