export interface ShortcutModel {
    id: number;
    groupId: number;
    name: string;
    displayName: string;
    content: string;
    createdAt: Date;
    modifiedAt: Date;
    createdBy: string;
    modifiedBy: string;
}

export class CreateShortcutModel {
    name: string;
    displayName: string;
    content: string;
    groupId: number;
}

export class UpdateShortcutModel {
    id: number;
    groupId: number;
    name: string;
    displayName: string;
    content: string
}

export interface ShortcutGroupModel {
    id: number;
    name: string;
    isPersonal: boolean;
    shortcuts: ShortcutModel[]
}

export class CreateShortcutGroupModel {
    name: string;
    displayName: string;
    isPersonal: boolean;
}

export class UpdateShortcutGroupModel {
    id: number;
    name: string;
    displayName: string;
    isPersonal: boolean;
}

export enum ShortcutGroupType {
    All = 1,
    Global = 2,
    Personal = 3,
}

export const ShortcutGroupTypeNames = {
  [ShortcutGroupType.All]: "View All Types",
  [ShortcutGroupType.Global]: "Global Shortcuts",
  [ShortcutGroupType.Personal]: "Personal Shortcuts"
};