import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: 5,
            [theme.breakpoints.up('sm')]: {
                padding: 30,
            },
        },
        content: {
            maxWidth: 300,
            minWidth: 150,
            [theme.breakpoints.down('sm')]: {
                maxWidth: 600,
            },
        },
        label: {
            color: '#7C8A9D',
            minHeight: 38,
        },
    })
);
