import {useEffect, useState} from "react";
import {Subscription} from "rxjs";
import { useFlags } from 'launchdarkly-react-client-sdk';
import {onEmit} from "../../../common/helpers/on-emit";
import {CoverageModel, InsuranceModel} from "../../models/insurance.models";
import {insuranceService} from "../../services/insurance.service";
import {insuranceQuery} from "../../stores/insurance.query";
import {SubscriptionModel, SubscriptionStatus, SubscriptionType} from "../../../payment/models/subscription.models";
import {subscriptionService} from "../../../payment/services/subscription.service";
import {subscriptionQuery} from "../../../payment/stores/subscriptionStore";
import {patientsQuery} from "../../../patients/stores/patientsStore";
import {AttachmentModel} from "../../../common/models/attachment.models";
import { insuranceStore } from "../../stores/insurance.store";
import { isFeatureFlag } from "../../../common/components/featureFlags/featureFlags";
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";

interface InsuranceInformationComponentState {
    subscriptionType: SubscriptionType;
    isInsuranceLoading: boolean;
    isSubscriptionLoading: boolean;
    isAddButtonLoading: boolean;
    isInsuranceAvailable: boolean;
    insuranceUnavailibilityReason: string;
    patientInsurances: Array<CoverageModel>;
    insurances: InsuranceModel[];
    attachments: AttachmentModel[];
    selectedUniversalId: string;
    isOpenComments: boolean;
}

export function useFacade(patientId: number): [
    InsuranceInformationComponentState,
    boolean,
    () => void,
    (coverage: CoverageModel) => void,
    (coverage: CoverageModel) => void,
    () => void
] {
    const featureFlags = useFlags();
    const isEnabledInsuranceAdd = isFeatureFlag(featureFlags, FeatureFlag.EnabledInsuranceAdd);

    const [state, setState] = useState({
        isInsuranceLoading: true,
        isSubscriptionLoading: true,
        insurances: [],
        isAddButtonLoading: false,
        isInsuranceAvailable: false,
        insuranceUnavailibilityReason: '',
        patientInsurances: [],
        attachments: [],
        selectedUniversalId: '',
        isOpenComments: false
    } as InsuranceInformationComponentState);

    const reload = () => {
        if (patientsQuery.getTargetPatientSubscription()) {
            subscriptionService.getPatientCurrent(patientId).subscribe(
                () => {
                    setState((state) => ({...state, isSubscriptionLoading: false}))
                },
                () => {
                    setState((state) => ({...state, isSubscriptionLoading: false}))
                }
            )
        } else {
            setState((state) => ({...state, isSubscriptionLoading: false}))
        }

        insuranceService.getInsuranceAvailabilityOnBehalf(patientId).subscribe(
            (available) => {
                setState(state => ({
                    ...state,
                    isInsuranceAvailable: available.isAvailable,
                    insuranceUnavailibilityReason: available.unavailabilityReason
                }));
            },
            () => {
                setState(state => ({
                    ...state,
                    isInsuranceAvailable: false
                }));
            }
        );

        insuranceService.getInsuranceUploadsOnBehalf(patientId).subscribe(
            () => {
                setState((state) => ({...state, isAttachmentsLoading: false}))
            },
            () => {
                setState((state) => ({...state, isAttachmentsLoading: false}))
            }
        );

        insuranceService.getAvailableInsurancesByEmployee(patientId).subscribe(() => {
                insuranceService.getCoveragesByEmploy(patientId).subscribe(() => {
                    setState((state) => ({...state, isInsuranceLoading: false}))
                }, () => {
                    setState((state) => ({...state, isInsuranceLoading: false}))
                })
            },
            () => {
                setState((state) => ({...state, isInsuranceLoading: false}))
            }
        )
    }

    const handleAddInsurance = () => {
        if (state.isAddButtonLoading) {
            return;
        }

        insuranceService.requestCreateInsurance(patientId).subscribe(
            (model) => {
                setState(state => ({
                    ...state,
                    isAddButtonLoading: true
                }));
                insuranceService.createCoverageOnBehalf(model).subscribe(
                    () => {
                        setState(state => ({
                            ...state,
                            isAddButtonLoading: false
                        }));

                        reload();
                    },
                    () => {
                        setState(state => ({
                            ...state,
                            isAddButtonLoading: false
                        }));
                    }
                );
            }
        )
    }

    const handleEditInsurance = (coverage: CoverageModel) => {
        insuranceService.requestUpdateInsurance(coverage, patientId).subscribe(
            (model) => {

                insuranceService.updateCoverageOnBehalf(model).subscribe(
                    () => {
                        reload();
                    },
                    () => {}
                );
            }
        )
    }

    const handleOpenComments = (coverage: CoverageModel) => {
        setState(state => ({
            ...state,
            isOpenComments: true,
            selectedUniversalId: coverage.universalId
        }))
    }

    const handleCloseComments = () => {
        insuranceStore.resetComments();
        setState(state => ({
            ...state,
            isOpenComments: false,
            selectedUniversalId: ''
        }))
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<CoverageModel[]>(insuranceQuery.coverages$, coverages => {
                setState(state => ({
                    ...state,
                    patientInsurances: coverages,
                }));
            }),
            onEmit<InsuranceModel[]>(insuranceQuery.insurances$, insurances => {
                setState(state => ({
                    ...state,
                    insurances: insurances,
                }))
            }),
            onEmit<SubscriptionModel>(subscriptionQuery.subscription$, subscription => {
                if (subscription?.type === SubscriptionType.Insurance && subscription?.status === SubscriptionStatus.Active) {
                    setState((state) => ({...state, isPayByCash: false}))
                }
            }),
            onEmit<AttachmentModel[]>(insuranceQuery.attachments$, attachments => {
                setState(state => ({
                    ...state,
                    attachments: attachments,
                }))
            }),
        ];

        reload();

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, []);

    return [
        state,
        isEnabledInsuranceAdd,
        handleAddInsurance,
        handleEditInsurance,
        handleOpenComments,
        handleCloseComments
    ];
}