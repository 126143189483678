import {useEffect, useState} from "react";
import {AttachmentModel, AttachmentType} from "../../../common/models/attachment.models";
import {insuranceService} from "../../services/insurance.service";

interface InsuranceListItemState {
    primaryAttachment: AttachmentModel;
    isLoading: boolean;
    imageUrl: string;
}

let stateContext: InsuranceListItemState;

export function useFacade(attachments: AttachmentModel[]): [InsuranceListItemState
] {
    const [state, setState] = useState({
        primaryAttachment: null,
        isLoading: false,
        imageUrl: ''
    } as InsuranceListItemState);

    stateContext = state;

    const getAttachment = () => {
        if (attachments.some(x => x.type === AttachmentType.InsuranceCardFront)) {
            return attachments.find(x => x.type === AttachmentType.InsuranceCardFront);
        }

        return attachments.length
            ? attachments[0]
            : null
    }

    const useEffectCB = () => {
        const primaryAttachment = getAttachment();

        if (stateContext.primaryAttachment?.id !== primaryAttachment?.id) {
            setState(state => ({
                ...state,
                primaryAttachment: primaryAttachment,
                isLoading: true
            }));

            if (primaryAttachment) {
                insuranceService.GetInsuranceImage(primaryAttachment.id).subscribe(
                    data => {
                        if (data) {
                            setState(state => ({
                                ...state,
                                isLoading: false,
                                imageUrl: "data:image/png;base64, " + data
                            }));
                        }
                    },
                    error => {
                        setState(state => ({
                            ...state,
                            isLoading: false,
                            imageUrl: null
                        }));
                    }
                )
            }
        }

        return () => {
        }
    }

    useEffect(useEffectCB, [attachments]);

    return [state];
}