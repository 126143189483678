import { HealthSummaryDataModel, HealthSummaryMapModel } from '../../models/healthSummary.models'
import { HealthSummaryWrapperComponent } from '../wrapperComponent/HealthSummaryWrapperComponent'
import { useFacade } from './healthSummaryContentComponent.hooks'
import { Box, TextField } from '@material-ui/core'
import React from 'react'

export interface HealthSummaryContentComponentProps {
    data: HealthSummaryDataModel[]
    map: HealthSummaryMapModel
    patientId?: number
}

export const HealthSummaryContentComponent: React.FC<HealthSummaryContentComponentProps> = (props: HealthSummaryContentComponentProps) => {
    const {
        data,
        map,
        patientId
    } = props

    const [
        handleEditValue,
        handleEditMode,
        cancelChanges,
        saveChanges,
        state
    ] = useFacade(map, patientId)

    const getValue = () => data?.find(x => x.key === map.key)?.value

    return <HealthSummaryWrapperComponent
        disableUpdateButton={map.editRestricted}
        patientId={patientId}
        handleCancel={cancelChanges}
        isLoading={state.isLoading}
        handleEdit={handleEditMode}
        editMode={state.editMode}
        handleSave={saveChanges}
        title={map.section}
        isEmpty={false}
        mapKey={map.key}
    >
        <Box minHeight={80}>
            {
                state.editMode
                    ? <TextField
                        onChange={(e) => { handleEditValue(e.target.value) }}
                        placeholder="Type here..."
                        value={state.value}
                        variant="outlined"
                        inputProps={{ maxLength: 1500 }}
                        rows={8}
                        multiline
                        fullWidth
                    />
                    : <Box>
                        {getValue()}
                    </Box>
            }
        </Box>
    </HealthSummaryWrapperComponent>
}