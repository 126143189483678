import { ResetPasswordModel } from "../../../common/models/user.models";
import {authService} from "../../../auth/services/auth.service";

export function useFacade(): [(newPassword: string, confirmPassword: string) => void] {
    const handleSubmitResetPassword = (newPassword: string, confirmPassword: string) => {
        const model = {
            newPassword: newPassword,
            confirmPassword: confirmPassword
        } as ResetPasswordModel

        authService.resetPassword(model).subscribe();
    }

    return [handleSubmitResetPassword];
}
