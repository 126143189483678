import Axios from "axios-observable";
import { Observable } from "rxjs";
import { IcdCode } from "../models/notes.models";

/**
 * Provides method for working with ICD codes
 */
export class IcdCodesService {
    private url = `${process.env.REACT_APP_API_URL}IcdCodes`;

    public get(searchQuery: string): Observable<IcdCode[]> {
        const url = `${this.url}/search?searchQuery=${searchQuery}`;

        return new Observable<IcdCode[]>(observer => {
            Axios.get(url).pipe()
                .subscribe(
                    response => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }
}

export const icdCodesService = new IcdCodesService();

