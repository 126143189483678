import {useState} from 'react';
import { UpdateDocumentSourceModel } from '../../models/documentSource.model';
import { kbDocumentsService } from '../../services/kbDocuments.service';
import { IErrorState } from '../../../common/validation/error-state';
import { uploadDocumentSourceValidator } from "../uploadDocumentSourceDialog/uploadDocumentSource.validator";

export interface UpdateDocumentSourceDialogState extends IErrorState  {
    isOpen: boolean;
    updateDocumentSource: UpdateDocumentSourceModel;
    handleUpdate: Function;
}

const initParam = {
    id: 0,
    name: '',
    tags: [],
    personaIds: []
}

export function useFacade(): [
    UpdateDocumentSourceDialogState,
    Function,
    Function,
    (field: string, value: any) => void
] {
    const [state, setState] = useState({
        isOpen: false,
        updateDocumentSource: initParam,
        errors: {}
    } as UpdateDocumentSourceDialogState);

    const setDefaultState = () => {
        setState(state => ({
            ...state,
            isOpen: false,
            updateDocumentSource: Object.assign({}, initParam),
            handleUpdate: null,
            errors: {}
        }))
    }

    const handleChanges = (field: string, value: any) => {
        uploadDocumentSourceValidator.validateAndSetState(state, setState, field, value);
  
        const params = state.updateDocumentSource;
        
        if (field === 'tags') {
            const index = params.tags.findIndex(x => x === value);
            index > -1 ? params.tags.splice(index, 1) : params.tags.push(value);
        } else {
            params[field] = value;
        }
    
        setState(state => ({...state, updateDocumentSource: Object.assign({}, params)}))
    }

    const handleSubmit = () => {
        uploadDocumentSourceValidator.validateObjectAndSetState(state, setState, state.updateDocumentSource);
        if (!uploadDocumentSourceValidator.stateIsValid(state)) {
            return;
        }
        setState(state => ({...state, isOpen: false}))
        state.handleUpdate?.(state.updateDocumentSource);
        setDefaultState();
    }

    const handleClose = () => {
        state.handleUpdate?.(null);
        setDefaultState();
    }

    kbDocumentsService.onUpdate.subscribe((callbackModel) => {
        setState(state => ({
            ...state,
            isOpen: true,
            updateDocumentSource: {...state.updateDocumentSource, id: callbackModel.data.id, name: callbackModel.data.name, tags: callbackModel.data.tags.map(x => x.tag), personaIds: callbackModel.data.documentSourcePersonas.map(x => x.id)},
            handleUpdate: callbackModel.callback
        }))
    })

    return [
        state,
        handleSubmit,
        handleClose,
        handleChanges
    ];
}