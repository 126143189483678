export enum OrderTabs {
    Lab = 0,
    Dni = 1,
    Epi = 2,
}

export enum DeliveryProviders {
    ImageMark = 1,
    Elysium = 2,
    LabCorp = 3
}

export enum OrderStatus {
    Draft = 0,
    Ordered = 1,
    UnderReview = 2,
    Placed = 5,
    ManualFlow = 9,
    Shipping = 12,
    Completed = 15,
    Failed = 20,
    Cancelled = 25,
    Signed = 30,
    OutForDelivery = 100,
    Arrived = 105,
    ReturnProcessing = 110,
    ReturnArrived = 115
}

export const orderCreateStatusNames = new Map<OrderStatus, string>([
    [OrderStatus.Signed, 'Signed'],
    [OrderStatus.UnderReview, 'Waiting for Approval'],
    [OrderStatus.Draft, 'Draft'],
]);

export const shortOrderStatusNames = new Map<OrderStatus, string>([
    [OrderStatus.Ordered, 'Ordered'],
    [OrderStatus.Placed, 'Preparing'],
    [OrderStatus.Shipping, 'Shipping'],
    [OrderStatus.OutForDelivery, 'Out For Delivery'],
    [OrderStatus.Arrived, 'Arrived'],
    [OrderStatus.ReturnProcessing, 'Return Processing'],
    [OrderStatus.ReturnArrived, 'Return Arrived'],
]);

export const orderStatusNames = new Map<OrderStatus, string>([
    [OrderStatus.Ordered, 'Ordered'],
    [OrderStatus.Placed, 'Preparing'],
    [OrderStatus.Shipping, 'Shipping'],
    [OrderStatus.Completed, 'Completed'],
    [OrderStatus.Failed, 'Resulted or Failed'],
    [OrderStatus.ManualFlow, 'Manual Flow'],
    [OrderStatus.OutForDelivery, 'Out For Delivery'],
    [OrderStatus.Arrived, 'Arrived'],
    [OrderStatus.ReturnProcessing, 'Return Processing'],
    [OrderStatus.ReturnArrived, 'Return Arrived'],
]);

export const orderStatusIndexes = new Map<OrderStatus, number>([
    [OrderStatus.Ordered, 0],
    [OrderStatus.Placed, 1],
    [OrderStatus.Shipping, 2],
    [OrderStatus.OutForDelivery, 3],
    [OrderStatus.Arrived, 4],
    [OrderStatus.ReturnProcessing, 5],
    [OrderStatus.ReturnArrived, 6],
    [OrderStatus.Completed, 7],
    [OrderStatus.Failed, 7],
]);

export enum OrderType {
    Default = 0,
    Dna = 1,
    Lab = 2,
    Epigenetic = 3,
    Referral = 4,
    Other = 5,
}

export const orderTypeNames = new Map<number, string>([
    [OrderType.Dna, 'DNA Kit'],
    [OrderType.Lab, 'Bloodwork'],
    [OrderType.Epigenetic, 'Epigenetic Age Kit']
]);

export interface OrderItemModel {
    id: number;
    sku: string;
    description: string;
    price: number;
    quantity: number;
    addOnId: number;
}

export interface CreateOrderModel {
    patientId: number;
    addOnIds: number[];
    isManual?: boolean;
    barCode?: string;
    outboundShippingCode?: string;
    returnShippingCode?: string;
}

export interface ReplaceOrderResponseModel<T> {
    replacedOrder: T;
    newOrder: T;
}

export interface CancelOrderModel {
    id: number;
    cancellationReason: string;
}

export interface OverrideOrderModel {
    id: number;
    reason: string;
}

export interface OrderDataModel {
    patientName: string;
    dateOfBirth: Date | string;
    insuranceProvider: string;
    insuranceId: string;
    clinicalReasonForReferral: string;
    urgencyTimeToAppointment: string;
    diagnosis: string;
    patientPreferences: string;
}
export interface ItemAddonsModel {
    addOnId: number,
    description: string,
}
export interface CreateReferralOrderModel {
    patientId: number;
    employeeId: number;
    items: ItemAddonsModel[];
    data: OrderDataModel;
    sendForReview: boolean;
    isCompleted: boolean;
}

export interface OrderModel {
    id: number;
    patientId: number;
    status: OrderStatus;
    orderedAt: Date;
}