import React, { useState } from "react";
import { NoteModel } from "../../models/notes.models";
import { DurationModel, durations } from "../../../appointments/models/times.models";
import { AppointmentTargetType } from "../../../appointments/models/appointments.enums";
import { Box, TextField } from "@material-ui/core";
import { WildHealthDatePicker } from "../../../common/components/WildHealthDatePicker";
import { SelectDurationComponent } from "../../../appointments/components/selectDuration/SelectDurationComonent";
import { OptionalComponent } from "../../../common/components/OptionalComponent";
import { SelectAppointmentToNotesDialog } from "../SelectAppointmentToNotesComponent/SelectAppointmentToNotesDialog";
import { snackService } from "../../../common/snack/state";
import { ReactComponent as CalendarIcon } from "@img/icons/CalendarIcon.svg";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import moment from 'moment';
import {patientsQuery} from "../../../patients/stores/patientsStore";

interface NoteGeneralInfoComponentProps {
    name: string;
    patientId: number,
    note: NoteModel,
    duration: DurationModel,
    visitDate: Date,
    title: string,
    selectedTargetType: AppointmentTargetType,
    handleTitleChanges: Function,
    handleAppointmentDateChanges: Function,
    handleAppointmentChanges: Function,
    handleChangeDuration: Function,
    classes
}

export const NoteGeneralInfoComponent: React.FC<NoteGeneralInfoComponentProps> = (props): JSX.Element => {
    const {
        name,
        patientId,
        note,
        duration,
        visitDate,
        title,
        selectedTargetType,
        handleTitleChanges,
        handleAppointmentDateChanges,
        handleAppointmentChanges,
        handleChangeDuration,
        classes
    } = props;

    const [isOpen, setIsOpen] = useState(false);

    const finalDuration = note?.appointment
        ? durations.find(x => x.value === note.appointment.duration)
        : duration;

    const finalVisitDate = note?.appointment
        ? note.appointment.date
        : visitDate;

    const handleSelectAppointmentSubmit = (appointmentId: number) => {
        if (!appointmentId) {
            if (!patientsQuery.getTargetPatient()?.isPremium) {
                snackService.error("There was an error while attaching a note.");
            }
        } else {
            handleAppointmentChanges(appointmentId);
        }

        setIsOpen(false);
    }

    if (!note) {
        return <></>
    }

    const handleTimeChanges = (time: string) => {
        const hoursMinutes = time.split(":");
        const dateResult = new Date(visitDate);
        dateResult.setHours(+hoursMinutes[0], +hoursMinutes[1]);
        handleAppointmentDateChanges(new Date(dateResult));
    }

    const getTime = () => {
        return moment(visitDate).format('HH:mm');
    }

    return (
        <Box>
            <Box display='flex' alignItems='center'>
                <Box>
                    <span className={classes.title}>{name}</span>
                </Box>
                <Box ml={3}>
                    <TextField
                        label='Enter title'
                        fullWidth
                        type='text'
                        size="small"
                        variant="outlined"
                        value={title}
                        InputProps={{ className: 'input' }}
                        onChange={(e) => handleTitleChanges(e.target.value)}
                    />
                </Box>
            </Box>
            <Box display='flex' mt={3}>
                <Box>
                    <Box pb={1}>
                        <span className={classes.inputLabel}>Appointment Date</span>
                    </Box>
                    {
                        note?.appointment
                            ? <WildHealthButton
                                width={256}
                                size="medium"
                                style={{ whiteSpace: 'nowrap' }}
                                color="datePicker"
                                id='sing-physical-history'
                                onClick={() => setIsOpen(true)}
                            >
                                <span>{moment(finalVisitDate).format("MM/DD/yyyy")}</span>
                                <CalendarIcon />
                            </WildHealthButton>
                            : <Box display="flex">
                                <WildHealthDatePicker
                                    required
                                    size="small"
                                    openTo="year"
                                    format="MM/DD/yyyy"
                                    id='AppointmentDate'
                                    placeholder="mm-dd-yyyy"
                                    value={finalVisitDate}
                                    inputVariant="outlined"
                                    views={["year", "month", "date"]}
                                    useKeyboardDatePicker
                                    onChange={(momentDate) => handleAppointmentDateChanges(momentDate)}
                                />
                                <Box ml={1}>
                                    <TextField
                                        id="time"
                                        size="small"
                                        className={classes.timePicker}
                                        label="Time"
                                        type="time"
                                        fullWidth
                                        variant="outlined"
                                        value={getTime()}
                                        onChange={(e)=>{ handleTimeChanges(e.target.value)}}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                    />
                                </Box>
                            </Box>
                    }
                </Box>
                <Box ml={3}>
                    <SelectDurationComponent
                        durations={note?.appointment !== null ? durations : durations.filter(x => x.patientMode)}
                        selected={finalDuration}
                        disabled={note?.appointment !== null}
                        handleChange={(v) => handleChangeDuration(v, selectedTargetType)}
                    />
                </Box>
            </Box>
            <OptionalComponent>
                <SelectAppointmentToNotesDialog
                    handleSubmitSelectAppointment={(appointmentId) => handleSelectAppointmentSubmit(appointmentId)}
                    handleChangeSelectAppointment={() => { }}
                    handleSelectAppointmentDialog={(isOpen) => setIsOpen(isOpen)}
                    isOpen={isOpen}
                    patientId={patientId} />
            </OptionalComponent>
        </Box>
    )
}