import { Box, useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { dnaDefinitions, HealthReportSections, HealthReportMode } from '../models/healthReport.models';
import { HealthReportBasePage, HealthReportMobileBasePage } from "./HealthReportBasePage";
import { HealthReportPageProps } from "./healthReportPageProps";

export const HealthReportDetails4Page: React.FC<HealthReportPageProps> = (props: HealthReportPageProps) => {
    const {
        pageNumber,
        practice
    } = props;
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const definitions = Object.keys(dnaDefinitions)
        .sort((a, b) => a.localeCompare(b))
        .slice(61);

    return (
        props.mode !== HealthReportMode.PrintMode && isSmallScreen ? (
            <HealthReportMobileBasePage section={HealthReportSections.GenomicGlossary} pageNumber={pageNumber} practice={practice}>
                <Box>
                    <Box px={2}>
                        <Box fontSize="14px" lineHeight="22px">
                            {definitions.map((definition, index) => {
                                return <Box key={index}><span><span className="text-medium">{definition}:</span> {dnaDefinitions[definition]}</span><br /></Box>
                            })}
                        </Box>
                    </Box>
                </Box>
            </HealthReportMobileBasePage>
        ) : (
            <HealthReportBasePage section={HealthReportSections.Details} pageNumber={pageNumber} practice={practice}>
                <Box>
                    <Box px={15} pt={5}>
                        <Box>
                            <span className="report-title"> Genomic Glossary </span>
                        </Box>
                        <Box mt={3}>
                            {definitions.map((definition, index) => {
                                return <Box key={index}><span><span className="text-medium">{definition}:</span> {dnaDefinitions[definition]}</span><br /></Box>
                            })}
                        </Box>
                    </Box>
                </Box>
            </HealthReportBasePage>
        )
    )
}