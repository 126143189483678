import React from "react";
import {Box, TextField, FormControl, Select, MenuItem} from "@material-ui/core";
import { LabRangeType, LabInputValueModel, labRangeTypesNames, LabValidationSource } from "../../models/input.models";
import { renderLabInputRange } from "../../helpers/render-lab-input-range";
import { getNumericKeys } from "../../../common/helpers/enum-mapper";

interface UpdateLabInputRangeComponentProps {
    input: LabInputValueModel;
    handleChanges: Function;
    disabled: boolean;
}

export const UpdateLabInputRangeComponent: React.FC<UpdateLabInputRangeComponentProps> = (props: UpdateLabInputRangeComponentProps) => {
    const {
        input,
        handleChanges,
        disabled
    } = props;

    const range = input.range ?? {
        from: null,
        to: null,
        dimension: '',
        type: LabRangeType.None,
        source: LabValidationSource.ValueScope
    };

    if (disabled) {
        return (
            <Box width="175px" height="100%" display="flex" justifyContent="space-between" alignItems="center" className="wh-tw-text-sm wh-tw-font-beVietnamPro">
                <span>
                    {
                        renderLabInputRange(range)
                    }
                </span>
            </Box>
        )
    }

    const renderRangeInput = () => {
        if (range.type === LabRangeType.LessThen || range.type === LabRangeType.LessThanOrEqual) {
            return (
                <Box width={0.4} px={1}>
                    <TextField
                        size="small"
                        id="to"
                        placeholder="Min"
                        variant="outlined"
                        value={range.to ?? ''}
                        onChange={(e) => handleChanges(input, 'to', e.target.value)}
                    />
                </Box>
            )
        }
        if (range.type === LabRangeType.MoreThen || range.type === LabRangeType.MoreThanOrEqual) {
            return (
                <Box width={0.4} px={1}>
                    <TextField
                        size="small"
                        id="from"
                        placeholder="Max"
                        variant="outlined"
                        value={range.from ?? ''}
                        onChange={(e) => handleChanges(input, 'from', e.target.value)}
                    />
                </Box>
            )
        }
        return (
            <Box width={0.4} px={1} display="flex" alignItems="center" gridGap={12}>
                <Box width={0.5}>
                    <TextField
                        size="small"
                        id="from"
                        placeholder="Min"
                        variant="outlined"
                        value={range.from ?? ''}
                        onChange={(e) => handleChanges(input, 'from', e.target.value)}
                    />
                </Box>
                <Box width={0.5}>
                    <TextField
                        size="small"
                        id="to"
                        placeholder="Max"
                        variant="outlined"
                        value={range.to ?? ''}
                        onChange={(e) => handleChanges(input, 'to', e.target.value)}
                    />
                </Box>
            </Box>
        )
    }

    return (
        <Box height="100%" display="flex" alignItems="center">
            <Box width={0.4} pr={1}>
                <FormControl
                    variant="outlined"
                    color='primary'
                    required
                    size="small"
                    fullWidth>
                    <Select
                        id="range-type"
                        value={range.type}
                        renderValue={() => {
                            if (range.type === LabRangeType.None) {
                                return <Box className="wh-tw-text-mediumGray">Range Type</Box>;
                            }

                            return <Box className="wh-tw-text-black">{labRangeTypesNames[range.type]}</Box>
                        }}
                        displayEmpty
                        onChange={(e) => handleChanges(input, 'type', e.target.value)}
                    >
                        {
                            getNumericKeys(labRangeTypesNames).map((value, i) => {
                                return <MenuItem key={i} value={value}>{labRangeTypesNames[value]}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            </Box>
            {renderRangeInput()}
            <Box width={0.2} pl={1} display="flex" alignContent="center">
                <TextField
                    size="small"
                    id="dimension"
                    placeholder="Unit"
                    variant="outlined"
                    value={range.dimension ?? ''}
                    onChange={(e) => handleChanges(input, 'dimension', e.target.value)}
                />
            </Box>
        </Box>
    );
}
