import { Box, Button, IconButton, Menu, MenuItem, Tooltip } from "@material-ui/core";
import clsx from "clsx";
import moment from "moment";
import React from "react";
import commonUseStyles from "../../../../common/styles/common.styles";
import { PatientJourneyStatusNames } from "../../../../patients/models/patient.model";
import { AppointmentLocationTypeNames, AppointmentPurposeType, AppointmentWithType } from "../../../models/appointments.enums";
import { EmployeeAppointmentModel } from "../../../models/appointments.models";
import { EditAppointmentDialogComponent } from "../../editAppointmentDialog/EditAppointmentDialogComponent";
import { navigationService } from "../../../../../services/navigation.service";
import { useHistory } from "react-router";
import { useFacade } from "./appointmentDayItemComponent.hooks";
import { useStyles } from "./AppointmentDayItemComponent.styles";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { authQuery } from "../../../../auth/stores/auth";
import { NotesType } from "../../../../notes/models/notes.models";
import { WildHealthLinkButton } from "../../../../common/components/WildHealthLinkButton";


interface AppointmentDayItemComponentProps {
    appointment: EmployeeAppointmentModel,
}

export const AppointmentDayItemComponent: React.FC<AppointmentDayItemComponentProps> = (props: AppointmentDayItemComponentProps) => {
    const { appointment } = props;
    const [
        {
            menuAnchor,
            notesMenuAnchor,
            editDialogOpen
        },
        handleCancelAppointment,
        handleJoinAppointment,
        handleOpenMenu,
        handleCloseMenu,
        handleOpenNotesMenu,
        handleCloseNotesMenu,
        handleToggleEditDialog,
        handleRescheduleAppointment,
        goToPatientProfile
    ] = useFacade();

    const classes = useStyles();
    const commonClasses = commonUseStyles();
    const history = useHistory();

    const isUpcoming = moment(appointment.startDate) > moment(new Date());

    const getAppointmentName = (name: string) => {
        return name.length > 40
            ? <Tooltip placement="top" arrow title={name}>
                <span className={commonClasses.textMedium}>{name.slice(0, 40) + '...'}</span>
            </Tooltip>
            : <span className={commonClasses.textMedium}>{name}</span>
    }

    const isOther = appointment.purpose === AppointmentPurposeType.Other;

    return (
        <Box className={clsx(classes.root, {
            [classes.wc]: appointment.purpose === AppointmentPurposeType.Welcome,
            [classes.fmc]: appointment.purpose === AppointmentPurposeType.FollowUp && (appointment.withType === AppointmentWithType.HealthCoachAndProvider || appointment.withType === AppointmentWithType.Provider),
            [classes.imc]: appointment.purpose === AppointmentPurposeType.Consult && (appointment.withType === AppointmentWithType.HealthCoachAndProvider || appointment.withType === AppointmentWithType.Provider),
            [classes.fcc]: appointment.purpose === AppointmentPurposeType.FollowUp && appointment.withType === AppointmentWithType.HealthCoach,
            [classes.icc]: appointment.purpose === AppointmentPurposeType.Consult && appointment.withType === AppointmentWithType.HealthCoach,
            [classes.other]: appointment.purpose === AppointmentPurposeType.Other,
        })}>
            <Box className={classes.info}>
                <Box display='flex'>
                    {isOther && getAppointmentName(appointment.name)}
                    {
                        !isOther &&
                        <>
                            <Box>
                                <WildHealthLinkButton
                                    id="appointment-day-link-patient-profile"
                                    onClick={() => goToPatientProfile(appointment.patient.id)}
                                    content={<span className={commonClasses.textMedium}>{appointment.patient.firstName} {appointment.patient.lastName}</span>}
                                />
                            </Box>
                            <Box display='grid' ml={4}>
                                <span>Date of Birth: <span className={commonClasses.textMedium}>{moment(appointment.patient.birthday).format('DD/MM/yyyy')}</span></span>
                                {
                                    authQuery.isDefaultPractice() &&
                                    <span>Journey Status: <span className={commonClasses.textMedium}>{PatientJourneyStatusNames.get(appointment.patient.journeyStatus)}</span></span>
                                }
                            </Box>
                        </>
                    }
                    <Box display='grid' ml={4}>
                        <span>Appt Type: <span className={commonClasses.textMedium}>{getAppointmentName(appointment.name)}</span></span>
                        <span>Pod: <span className={commonClasses.textMedium}>{appointment.location.name}</span></span>
                    </Box>
                    <Box ml={4}>
                        <span>Location: <span className={commonClasses.textMedium}>{AppointmentLocationTypeNames.get(appointment.locationType)}</span></span>
                    </Box>
                </Box>
                <Box display='flex'>
                    {
                        appointment.joinLink &&
                        <Button id="appointment-day-join" className='join-button' onClick={() => handleJoinAppointment(appointment.joinLink)}>
                            Join
                        </Button>
                    }
                    {appointment.withType !== AppointmentWithType.Other &&
                        <Button id="appointment-day-create-note" className='note-button' onClick={(e) => handleOpenNotesMenu(e)}>
                            <Box>
                                <span>Create Note</span>
                            </Box>
                            <Box mt='5px'>
                                <ArrowDropDownIcon />
                            </Box>
                        </Button>
                    }
                    <Menu
                        id="simple-menu-notes"
                        anchorEl={notesMenuAnchor}
                        keepMounted
                        open={Boolean(notesMenuAnchor)}
                        onClose={(e) => handleCloseNotesMenu(e)}
                    >
                        <MenuItem id="appointment-info-popover-initial-coaching" onClick={() => navigationService.toCreateInitialConsultNote(history, appointment.patient.id, appointment.id, null, true)}>
                            Initial Coaching Consult
                        </MenuItem>
                        <MenuItem id="appointment-info-popover-fellow-up" onClick={() => navigationService.toCreateFollowUpNote(history, appointment.patient.id, appointment.id, null, true)}>
                            Follow-Up
                        </MenuItem>
                        <MenuItem id="appointment-info-popover-blank-note" onClick={() => navigationService.toCreateSimpleNote(history, NotesType.Blank, appointment.patient.id, appointment.id, null, true)}>
                            Blank Note
                        </MenuItem>
                    </Menu>
                    <IconButton id="appointment-day-open-menu" className={classes.menuButton} onClick={(e) => handleOpenMenu(e)}>
                        <MoreHorizIcon />
                    </IconButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={menuAnchor}
                        keepMounted
                        open={Boolean(menuAnchor)}
                        onClose={(e) => handleCloseMenu(e)}
                    >
                        <MenuItem id="appointment-day-toggle-edit" disabled={!isUpcoming} onClick={() => handleToggleEditDialog(true)}>
                            Edit
                        </MenuItem>
                        <MenuItem id="appointment-day-cancel-appointment" onClick={() => handleCancelAppointment(appointment.id)}>
                            Cancel
                        </MenuItem>
                        <MenuItem id="appointment-day-reschedule-appointment" disabled={!isUpcoming} onClick={() => handleRescheduleAppointment(appointment)}>
                            Reschedule
                        </MenuItem>
                    </Menu>
                </Box>
            </Box>
            <EditAppointmentDialogComponent
                open={editDialogOpen}
                appointment={appointment}
                handleClose={() => handleToggleEditDialog(false)}
            />
        </Box>
    );
}
