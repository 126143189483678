import React from "react";
import {PatientModel} from "../../../patients/models/patient.model";
import {Box, FormControlLabel} from "@material-ui/core";
import {useFacade} from "./smsReminderToggleComponent.hooks";
import {WildHealthSwitcher} from "../../../common/components/WIldHealthSwitcher";

interface SmsReminderToggleComponentProps {
    patient: PatientModel;
}

export const SmsReminderToggleComponent: React.FC<SmsReminderToggleComponentProps> = (props: SmsReminderToggleComponentProps) => {
    const {
        patient
    } = props;

    const [
        state,
        handleToggle
    ] = useFacade(patient);
    return (
        <>
            <Box>
                <Box display="flex" alignItems='center'>
                    <Box width={0.4} className="wh-tw-text-gray1" >
                       SMS Reminders:
                    </Box>

                    <Box display="flex" >
                        <Box p={1} pl={0}>
                                <span>
                                    Off
                                </span>
                        </Box>
                        <Box ml={3}>
                            <FormControlLabel
                                control={<WildHealthSwitcher checked={state.isActive} onChange={() => handleToggle(!state.isActive)} />}
                                label=""
                            />
                        </Box>
                        <Box p={1}>
                                <span>
                                    On
                                </span>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}