import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: colors.white,
        paddingTop: "50px"
    },
    containerSmall: {
        backgroundColor: colors.white,
        paddingTop: "10px"
    },
    main: {
        maxWidth: 1128,
        margin: '0 auto',
    },
    trialPlan: {
        background: colors.black,
        '& h3': {
            color: '#FFF !important',
            fontSize: 25,
            [theme.breakpoints.down("xs")]: {
                fontSize: 15,
            },
            [theme.breakpoints.down("sm")]: {
                fontSize: 20,
            }
        }
    },
    choosePlan: {
        fontWeight: 500,
        fontSize: '36px',
        lineHeight: '54px',
        color: colors.darkGray,
    },
    choosePlanSM: {
        fontWeight: 500,
        fontSize: '22px',
        lineHeight: '34px',
        color: colors.darkGray,
        width: '214px',
        display: 'flex',
        justifyContent: 'center',
        marginInline: 'auto',
    },
    link: {
        color: colors.main,
        paddingLeft: '4px',
        paddingRight: '4px',
    },
    textWithLink: {
        display: 'flex',
        flexWrap: 'nowrap',
        width: 'max-content',
    },
    paymentPlan: {
        background: '#FFF !important',
        '& .plan-name': {
            fontSize: '25px !important',
            color: colors.main,
        },
        '& .select-button': {
            fontSize: '17px !important',
        },
        '& .plan-title': {
            fontFamily: 'Poppins',
            fontSize: 16,
            fontWeight: 600,
            lineHeight: 1.42,
            letterSpacing: 0.1,
            textAlign: 'center',
        },
    },
    mostPopularRibbon: {
        background: colors.accent1,
        display: 'flex',
        alignItems: 'center',
        height: '38px',
        width: '203px',
        clipPath: 'polygon(100% 0, 90% 50%, 100% 100%, 0% 100%, 0% 0%, 0% 0%)',
        zIndex: 10,
    },
    mostPopularText: {
        paddingLeft: '12px',
        color: 'white',
        fontSize: '14px',
        textTransform: 'uppercase',
        fontWeight: 600,
        lineHeight: '22px',
    },
    ribbonRelation: {
        marginTop: '-19px',
    },
    notMostPopular: {
        marginTop: '19px',
    },
    centerSection: {
        background: colors.accent2,
        border: 'none',
        borderRadius: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '88px',
        width: '100%',
    },
    centerSectionSM: {
        background: colors.accent2,
        border: 'none',
        display: 'flex',
        alignItems: 'center',
        height: '132px',
        width: '100%',
    },
    centerContext: {
        fontSize: 18,
        fontWeight: 500,
        lineHeight: '28px',
        color: colors.main,
        paddingInline: '16px',
    },
    cancelContext: {
        marginLeft: '16px',
        marginRight: '150px',
        color: colors.linkBlack,
    },
    saveContext: {
        marginLeft: '16px',
        color: colors.linkBlack,
    },
    detailFeatureContext: {
        fontSize: 22,
        fontWeight: 500,
        lineHeight: '34px',
        color: colors.linkBlack,
    },
    mostPlanCard: {
        border: `2px solid`,
        borderColor: colors.accent1,
        borderRadius: '8px 8px 0 0',
        textAlign: 'center',
    },
    planCard: {
        border: `2px solid ${colors.lightGray1}`,
        borderRadius: '8px 8px 0 0',
        textAlign: 'center',
    },
    mostPlanName: {
        fontSize: 18,
        fontWeight: 500,
        lineHeight: '28px',
        color: colors.main,
    },
    planName: {
        fontSize: 18,
        fontWeight: 500,
        lineHeight: '28px',
        color: colors.linkBlack,
    },
    startPrice: {
        fontSize: 22,
        fontWeight: 500,
        lineHeight: '34px',
        color: colors.linkBlack,
    },
    featuresTopBorder: {
        borderTop: `1px solid ${colors.lightGray1}`,
    },
    featuresBothBorder: {
        borderTop: `1px solid ${colors.lightGray1}`,
        borderBottom: `1px solid ${colors.lightGray1}`,
    },
    featuresContext: {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '24px',
        color: colors.linkBlack,
    },
    featuresBoldContext: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '24px',
        color: colors.main,
    },
    mostPlanFeatureCard: {
        borderRightColor: colors.accent1,
        borderLeftColor: colors.accent1,
        borderRight: `2px solid`,
        borderLeft: `2px solid`,
        textAlign: 'center',
        height: '80px',
        zIndex: 10,
    },
    planFeatureCard: {
        borderRightColor: colors.lightGray1,
        borderLeftColor: colors.lightGray1,
        borderRight: `2px solid`,
        borderLeft: `2px solid`,
        height: '80px',
    },
    sameFeatureBorder: {
        borderBottom: `1px solid`,
        borderBottomColor: colors.accent2,
    },
    stickyFooter: {
        position: "fixed",
        bottom: 0,
        backgroundColor: colors.white,
        filter: "drop-shadow(0px 0px 10px rgba(53, 129, 173, 0.2))"
    }
}));