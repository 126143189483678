import { PatientAddonStatus } from "../models/patientAddOn.models";

export const patientAddOnsStatuses: { [status: number]: string } = {
    [PatientAddonStatus.Preparing]: 'Preparing',
    [PatientAddonStatus.InProgress]: 'In Progress',
    [PatientAddonStatus.Completed]: 'Completed',
    [PatientAddonStatus.Failed]: 'Failed',
}

export class AddOnNames {
    static readonly InitialLabWork: string = "InitialLabWork";
    static readonly FollowUpLabWork: string = "FollowUpLabWork";
    static readonly Dna: string = "Dna";
}

export const AddOnLabels: { [name: string]: string } = {
    [AddOnNames.InitialLabWork]: "Initial Labwork",
    [AddOnNames.FollowUpLabWork]: "Follow-up Labs",
    [AddOnNames.Dna]: "DNA",
};