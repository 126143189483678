import { Box, TextField, Grid, IconButton, TableContainer, Table, TableBody, TableHead } from '@material-ui/core';
import { Add, Delete, Edit } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { Autocomplete } from "@material-ui/lab";
import { Observable } from "rxjs";
import commonUseStyles from "../../../common/styles/common.styles";
import { IcdCode } from "../../models/notes.models";
import { useStyles, SearchTextField } from "./diagnosisComponent.style";
import { isFocusInCurrentTarget } from "../../../common/helpers/is-focus-in-current-target";
import SearchIcon from "@material-ui/icons/Search";
import { WildHealthTableRow } from '../../../common/components/wildHealthTable/WildHealthTableRow';
import { WildHealthTableCell } from '../../../common/components/wildHealthTable/WildHealthTableCell';
import { commonDiagnoses } from '../../helpers/noteHelpers';
import CheckIcon from '@material-ui/icons/Check';
import { colors } from '../../../common/constants/colors';

export interface DiagnosisComponentProps {
    error: string;
    title?: string;
    addText?: string;
    subTitle?: string;
    grid?: 6 | 12;
    dataKey: string;
    selectedDiagnosis: IcdCode[];
    getAutoComplete: (searchQuery: string) => Observable<IcdCode[]>;
    handleChanges: (key: string, values: IcdCode[]) => void
    handleTextInfoChange: (values: string, id: number) => void 
}

interface DiagnosisComponentState {
    isLoading: boolean;
    searchQuery: string;
    enableAdd: boolean;
    valueToEdit: IcdCode;
    indexToEdit: number | null;
    diagnosis: IcdCode[];
    diagnosisCommon: IcdCode[];
}

let stateContext: DiagnosisComponentState = null;

export const DiagnosisComponent: React.FC<DiagnosisComponentProps> = (props: DiagnosisComponentProps) => {
    const {
        error = null,
        title = null,
        addText = null,
        subTitle = null,
        grid = 12,
        dataKey,
        selectedDiagnosis,
        getAutoComplete,
        handleChanges,
        handleTextInfoChange,
    } = props;

    const [state, setState] = useState({
        isLoading: false,
        searchQuery: '',
        enableAdd: false,
        valueToEdit: null,
        indexToEdit: null,
        diagnosis: [],
        diagnosisCommon: commonDiagnoses.sort((a,b) => {
            if (a.description < b.description) {
                return -1;
            }
            if (a.description > b.description) {
                return 1;
            }
            return 0;
        }),
    } as DiagnosisComponentState);

    stateContext = state;

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (getAutoComplete && stateContext.searchQuery) {
                setState(state => ({
                    ...state,
                    isLoading: true
                }));
                getAutoComplete(stateContext.searchQuery).subscribe(
                    (diagnosis) => {
                        const selectedCodes = selectedDiagnosis.map(k => k.code);
                        setState(state => ({
                            ...state,
                            diagnosis: diagnosis.filter(x => !selectedCodes.includes(x.code)),
                            isLoading: false
                        }));
                    },
                    () => {
                        setState(state => ({
                            ...state,
                            diagnosis: [],
                            isLoading: false
                        }));
                    })
            }
        }, 1500);

        return () => clearTimeout(delayDebounceFn)
    }, [state.searchQuery, getAutoComplete]);

    const classes = useStyles();
    const commonClasses = commonUseStyles();

    const handleDeleteItem = (index: number) => {
        selectedDiagnosis.splice(index, 1);

        setState(state => ({
            ...state,
            searchQuery: '',
            valueToEdit: null,
            indexToEdit: null,
            diagnosis: []
        }))

        handleChanges(dataKey, selectedDiagnosis);
    }

    const handleEnableAdd = () => {
        setState(state => ({
            ...state,
            enableAdd: true,
            searchQuery: '',
            indexToEdit: null,
            valueToEdit: null,
            diagnosis: [],
        }))
    }

    const handleAddChanges = (value: string) => {
        setState(state => ({ ...state, searchQuery: value }));
    }

    const handleAddSubmit = (diagnosis: IcdCode) => {
        if (diagnosis && diagnosis?.id) {
            diagnosis.additionalInformation = '';
            selectedDiagnosis.push(diagnosis);
            handleChanges(dataKey, selectedDiagnosis);
        }

        setState(state => ({
            ...state,
            enableAdd: false,
            searchQuery: '',
            diagnosis: [],
        }))
    }

    const handleEnableEdit = (index: number) => {
        const diagnosis = selectedDiagnosis[index];
        setState(state => ({
            ...state,
            searchQuery: diagnosis.code + ' ' + diagnosis.description,
            indexToEdit: index,
            enableAdd: false,
            valueToEdit: diagnosis,
            diagnosis: []
        }))
    }

    const handleEditChanges = (value: string) => {
        setState(state => ({ ...state, searchQuery: value }))
    }

    const handleSubmitEdit = (diagnosis: IcdCode) => {
        if (diagnosis && diagnosis.id) {
            diagnosis.additionalInformation = '';
            selectedDiagnosis[state.indexToEdit] = diagnosis;
            handleChanges(dataKey, selectedDiagnosis);
        }

        setState(state => ({
            ...state,
            searchQuery: '',
            enableAdd: false,
            valueToEdit: null,
            indexToEdit: null,
            diagnosis: []
        }))
    }

    const handleFiltering = (val) => {
        setState(state => ({
            ...state,
            diagnosisCommon: val == ''
                ? commonDiagnoses
                : commonDiagnoses.filter(el => el.code.toLocaleLowerCase().includes(val.toLocaleLowerCase())
                    || el.description.toLocaleLowerCase().includes(val.toLocaleLowerCase()))
        }))
    }

    return (
        <Box>
            {
                title &&
                <Box>
                    <span className={classes.title}>{title}</span>
                </Box>
            }
            {
                subTitle &&
                <Box pt={2}>
                    <span className={classes.subtitle}>{subTitle}</span>
                </Box>
            }
            {
                error &&
                <Box>
                    <span className={commonClasses.error}>{error}</span>
                </Box>
            }
            <Box>
                <Grid container spacing={2}>
                    <Grid item md={grid} sm={12}>
                        {
                            selectedDiagnosis.map((item, i) => state.indexToEdit === i
                                ? (

                                    <Box mt={2.5} display='flex' alignItems='center' justifyContent="space-between" width={1} onBlur={(e) => {
                                        setTimeout(() => {
                                            if (!isFocusInCurrentTarget(e)) {
                                                handleSubmitEdit(null)
                                            }
                                        }, 250)
                                    }}>
                                        <Box width={0.9}>
                                            <Autocomplete
                                                id={`${dataKey}-item-list-edit`}
                                                size="small"
                                                freeSolo
                                                disableClearable
                                                fullWidth
                                                options={state.diagnosis}
                                                value={state.valueToEdit}
                                                getOptionLabel={option => option.code + ' ' + option.description}
                                                onChange={(e, value: IcdCode) => handleSubmitEdit(value)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        variant='outlined'
                                                        value={state.valueToEdit}
                                                        autoFocus
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            className: 'input',
                                                            type: 'search'
                                                        }}
                                                        onChange={(e) => handleEditChanges(e.target.value)}
                                                        onBlur={() => handleAddSubmit(null)}
                                                    />
                                                )}
                                            />
                                        </Box>
                                        <Box ml={2}>
                                            <IconButton className={classes.deleteIcon} onClick={() => handleDeleteItem(i)}>
                                                <Delete />
                                            </IconButton>
                                        </Box>
                                    </Box>

                                )
                                :
                                <Box mt={2.5} position='relative'>
                                    <Box  id={`pmh=${dataKey}-${i}`} className={classes.item}>
                                        <Box>
                                            <span className={commonClasses.breakWord}>{item.code + ' ' + item.description}</span>
                                        </Box>
                                        <Box className={classes.editIcon} onClick={() => handleEnableEdit(i)} ml={2}>
                                            <Edit />
                                        </Box>
                                    </Box>
                                    <Box className={classes.connectedLine}></Box>
                                    <Box mt={1} ml={4}>
                                        <TextField
                                            fullWidth
                                            rows={1}
                                            maxRows={3}
                                            multiline
                                            type='text'
                                            size="small"
                                            label=''
                                            variant="outlined"
                                            placeholder={`${item.description} Diagnosis Details Here`}
                                            value={item.additionalInformation}
                                            InputProps={{ className: 'input' }}
                                            onChange={(e) => handleTextInfoChange(e.target.value, item.id) }
                                        />
                                    </Box>
                                </Box>
                            )
                        }

                        {
                            state.enableAdd
                                ?
                                <Box mt={2.5}>
                                    <Autocomplete
                                        id={`${dataKey}-item-list-add`}
                                        freeSolo
                                        loading={state.isLoading}
                                        size="small"
                                        disableClearable
                                        options={state.diagnosis}
                                        getOptionLabel={option => option.code + ' ' + option.description}
                                        onChange={(e, value: IcdCode) => handleAddSubmit(value)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={addText}
                                                variant='outlined'
                                                autoFocus
                                                InputProps={{
                                                    ...params.InputProps,
                                                    className: 'input',
                                                    type: 'search'
                                                }}
                                                onChange={(e) => handleAddChanges(e.target.value)}
                                                onBlur={() => handleAddSubmit(null)}
                                            />
                                        )}
                                    />
                                </Box>
                                :
                                <Box mt={2.5} display='flex' alignItems='stretch'>
                                    <Box onClick={() => handleEnableAdd()}>
                                        <Add className={classes.addIcon} />
                                    </Box>
                                    <Box className={classes.addText} ml={2}>
                                        <span>{addText ?? 'Add'}</span>
                                    </Box>
                                </Box>
                        }
                    </Grid>
                    <Grid item md={grid} sm={12}>
                        <Box mt={2} style={{ backgroundColor: colors.bg }} p={3}>
                            <Box className={commonClasses.textMedium}>Commonly Used Diagnoses:</Box>
                            <Box mt={2} mb={1}>
                                <SearchTextField
                                    fullWidth
                                    variant="outlined"
                                    className={commonClasses.bgWhiteMain}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        handleFiltering(e.target.value)
                                    }
                                    placeholder="Search"
                                    InputProps={{
                                        inputProps: {
                                            className: classes.searchBase,
                                        },
                                        startAdornment: <SearchIcon className={commonClasses.colorGray1} />,
                                    }}
                                />
                            </Box>
                            <TableContainer style={{ maxHeight: '356px' }} className="flat-scroll">
                                <Table stickyHeader>
                                    <TableHead>
                                        <WildHealthTableRow style={{ backgroundColor: colors.bg }}>
                                            <WildHealthTableCell style={{ width: '60%', height: '44px', padding: '0 16px', backgroundColor: colors.bg }} >
                                                Condition
                                            </WildHealthTableCell>
                                            <WildHealthTableCell style={{ width: '40%', height: '44px', padding: '0 16px', backgroundColor: colors.bg }} >
                                                ICD 10 Code
                                            </WildHealthTableCell>
                                        </WildHealthTableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            state.diagnosisCommon.map((diagnose, index) =>
                                                <WildHealthTableRow key={index}>
                                                    <WildHealthTableCell style={{ height: '44px', padding: '0 16px', fontSize: '14px' }}>
                                                        {diagnose.description}
                                                    </WildHealthTableCell>
                                                    <WildHealthTableCell style={{ height: '44px', padding: '0 16px', fontSize: '14px' }}>
                                                        <Box display='flex' alignItems='center' justifyContent="space-between">
                                                            <Box>{diagnose.code}</Box>
                                                            {selectedDiagnosis.find(el => el.id === diagnose.id)
                                                                ? <Box mr={1}><CheckIcon className={classes.iconCheck} /></Box>
                                                                : <IconButton
                                                                    size='small'
                                                                    id="add-diagnosis-common"
                                                                    onClick={() => { handleAddSubmit(diagnose) }}
                                                                >
                                                                    <Add className={classes.addIcon2} />
                                                                </IconButton>
                                                            }
                                                        </Box>
                                                    </WildHealthTableCell>
                                                </WildHealthTableRow>
                                            )
                                        }
                                        {!state.diagnosisCommon.length &&
                                            <WildHealthTableRow key='empty' style={{ height: '44px', padding: '0 16px' }}>
                                                <WildHealthTableCell style={{ height: '44px', padding: '0 16px', fontSize: '14px' }}>
                                                    No current records found
                                                </WildHealthTableCell>
                                            </WildHealthTableRow>}
                                    </TableBody>

                                </Table>
                            </TableContainer>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}