import { Query } from '@datorama/akita';
import { EmployeeAvailabilityModel } from '../../models/availability.models';
import { AvailabilityState, availabilityStore, AvailabilityStore } from './availability.store';

/**
 * Provides method for working with appointments store
 */
export class AvailabilityQuery extends Query<AvailabilityState> {
    availabilities$ = this.select(state => state.availabilities);
    currentAvailability$ = this.select(state => state.currentAvailability)
    constructor(protected store: AvailabilityStore) {
        super(store);
    }

    /**
     * Returns appointments
     */
    public getToken(): Array<EmployeeAvailabilityModel> {
        return this.getValue().availabilities;
    }

    public getEarliestAvailability(): Date {
        const availabilities = this.getToken();
        if (availabilities.length > 0 && availabilities[0]?.availability?.length > 0) {
            return availabilities[0].availability[0].start;
        }
        return new Date();
    }
}

export const availabilityQuery = new AvailabilityQuery(availabilityStore);