import {Query} from '@datorama/akita';
import {
    EmployeeConversationsState,
    employeeConversationsStore,
    EmployeeConversationsStore,
    EmployeeConversationsTabs
} from "./employeeConversations.store";
import {EmployeeConversationModel, ConversationAuthorModel} from "../../models/conversation.models";

/**
 * Provides coaches queries
 */
export class EmployeeConversationsQuery extends Query<EmployeeConversationsState> {
    tab$ = this.select(state => state.tab);
    author$ = this.select(state => state.author);
    patientsChatsTab$ = this.select(state => state.patientsChatsTab);
    internalChatsTab$ = this.select(state => state.internalChatsTab);
    targetConversation$ = this.select(state => state.targetConversation);
    conversations$ = this.select(state => state.conversations);
    templates$ = this.select(state => state.templates);
    supportSubmissions$ = this.select(state => state.supportSubmissions);
    targetAttachments$ = this.select(state => state.targetAttachments);
    targetAttachmentsView$ = this.select(state => state.targetAttachmentsView);
    messageSettings$ = this.select(state => state.messageSettings);
    orphanedProxies$ = this.select(state => state.orphanedProxies);
    messagesAdminTools$ = this.select(state => state.messagesAdminTools);
    messagesTotalCount$ = this.select(state => state.messagesTotalCount);
    messagesForwardingAdminTools$ = this.select(state => state.messagesForwardingAdminTools);
    editingTargetAiMessage$ = this.select(state => state.editingTargetAiMessage);
    aiMessageAcceptLoading$ = this.select(state => state.aiMessageAcceptLoading);
    connectionStatus$ = this.select(state => state.connectionStatus);
    awayMessageTemplates$ = this.select(state => state.awayMessageTemplates);
    attachmentAddingAvailable$ = this.select(state => state.attachmentAddingAvailable);
    isGetPhotosLoading$ = this.select(state => state.isGetPhotosLoading);
    patientIdForDashboard$ = this.select(state => state.patientIdForDashboard);
    isLoadingForDashboardStickyChat$ = this.select(state => state.isLoadingForDashboardStickyChat);

    constructor(protected store: EmployeeConversationsStore) {
        super(store);
    }

    public getTab(): EmployeeConversationsTabs {
        return this.getValue().tab;
    }

    public getConversations(): EmployeeConversationModel[] {
        return this.getValue().conversations;
    }

    public getTargetConversation(): EmployeeConversationModel {
        return this.getValue().targetConversation;
    }

    public getAuthor(): ConversationAuthorModel {
        return this.getValue().author;
    }

    public isReady(): boolean {
        return this.getValue().isExpectedConversationsLoaded && 
            (this.getValue().expectedConversations.length === 0 || this.getValue().conversations.length > 0);
    }

    public getConnectionStatus(): boolean {
        return this.getValue().connectionStatus;
    }
    
    public getAttachmentAdding(): boolean {
        return this.getValue().attachmentAddingAvailable;
    }

    public getPhotosLoading(): boolean {
        return this.getValue().isGetPhotosLoading;
    }
}

export const employeeConversationsQuery = new EmployeeConversationsQuery(employeeConversationsStore);