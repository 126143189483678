import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderColor: colors.gray400,
            borderRadius: 8,
            '& .MuiOutlinedInput-root': {
                borderColor: colors.gray400,
                borderRadius: 8,
                height: '48px'
            }
        },
        loginPage: {
            width: '100%',
            height: '100%',
            minHeight: '100vh',
            backgroundColor: colors.white,
        },
        contentBox: {
            display: "flex",
            alignItems: "center",
            marginTop: 80,
            marginBottom: 80,
            [theme.breakpoints.down('sm')]: {
                marginTop: 12,
                marginBottom: 12,
            },
        },
        loginForm: {
            padding: '65px 44px 44px 44px',
        },
        loadingPage: {
            padding: '65px 44px 44px 44px',
            fontSize: '24px',
        },
        logo: {
            height: 30,
            width: 'auto',
        },
        text: {
            fontSize: '0.75rem !important',
            fontFamily: 'Poppins',
            lineHeight: '1.79',
            fontWeight: 'bold',
        },
        login: {
            fontStretch: 'normal',
            fontStyle: 'normal',
            fontSize: '20px',
            lineHeight: '1.57',
            fontWeight: 500,
            textTransform: 'capitalize',
            letterSpacing: '1px',
        },
        title: {
            fontSize: 40,
            fontWeight: 500,
        },
        linkText: {
            fontWeight: 500,
        },
        link: {
            color: colors.darkMint,
        },
        helperText: {
            paddingRight: 4,
            lineHeight: '24px',
        },
        subTitle: {
            fontSize: 24,
            fontWeight: 600,
        },
        loginBtnSection: {
            width: 217,
            [theme.breakpoints.down('sm')]: {
                width: '100%'
            },
        },
        resetPasswordBtnSection: {
            width: 165,
            [theme.breakpoints.down('sm')]: {
                width: '100%'
            },
        },
        setPasswordSection: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            paddingTop: 24,
            paddingBottom: 24,
            [theme.breakpoints.down('sm')]: {
                paddingTop: 16,
                paddingBottom: 16,
            },
        },
        doneIcon: {
            fill: colors.mint,
        },
        linkEmailSent: {
            width: 'fit-content',
            border: `1px solid ${colors.mint}`,
            borderRadius: 8,
            backgroundColor: '#F7FBF9',
            display: 'flex',
            alignItems: 'center',
            padding: '12px 16px',
            paddingRight: 24,
            marginRight: 'auto',
            marginLeft: 'auto',
        }
    }),
);

