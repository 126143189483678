import React from 'react';
import { AuthenticatedLayoutComponent } from '../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { TitlesService } from "../../../../constants/title.constants";
import { MenuItemTypes } from "../../../../models/menu.models";
import { useFacade } from "./BuyNewSubscriptionPage.hooks";
import {WaitListDialogComponent} from "../../components/waitListDialogComponent/WaitListDialogComponent";
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";

export const BuyNewSubscriptionPage: React.FC = () => {
    const paymentPlanId = new URLSearchParams(window.location.search).get('paymentPlanId');
    const paymentPeriodId = new URLSearchParams(window.location.search).get('paymentPeriodId');
    const [
        state,
        page
    ] = useFacade(
        paymentPlanId ? Number(paymentPlanId) : null,
        paymentPeriodId ? Number(paymentPeriodId) : null);

    return (
        <AuthenticatedLayoutComponent
            title={TitlesService.buyNewSubscriptionTitle}
            pageName={PagesNamesService.buyNewSubscription}
            selectedMenuItem={MenuItemTypes.None}
        >
            { state.isLoading ? <WildHealthLinearProgress/> : page }
            <WaitListDialogComponent />
        </AuthenticatedLayoutComponent>
    )
}
