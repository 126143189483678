import React from 'react';
import { useHistory } from 'react-router';
import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { useFacade } from "./employerProfileDetailPage.hooks";
import { AuthenticatedLayoutComponent } from '../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from "../../../../models/menu.models";
import { WildHealthTabControl } from "../../../common/components/wildHealthTabControl/WildHealthTabControl";
import { TabItem } from "../../../common/components/wildHealthTabControl/wildHealthTabControl.hooks";
import { useStylesThreeWidthTabs } from '../../../common/components/wildHealthTabControl/wildHealthTabControl.styles';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { WildHealthPlaceHolder } from '../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder';
import { navigationService } from '../../../../services/navigation.service';
import { ManagePreauthorizedEmployeesComponent } from '../../components/manageEmployersProfileComponent/components/managePreauthorizedEmployeesComponent/ManagePreauthorizedEmployeesComponent';

export const EmployerProfileDetailPage: React.FC<any> = (props: any) => {
    const productId = Number(props.match.params.productId);
    const history = useHistory();
    const [
        { employer, isLoading }
    ] = useFacade(productId);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const initialClassName = useStylesThreeWidthTabs();

    const tabs: TabItem[] = [
        {
            title: 'Employees',
            content: <ManagePreauthorizedEmployeesComponent productId={productId} />,
        },
        {
            title: 'Billing',
            content: <></>,
        }
    ];

    const renderContent = () => {
      
        if (isLoading) {
            return <Box mt={2} p={2} className="wh-tw-bg-white" width={1}>
                <WildHealthLinearProgress />
            </Box>
        }

        if (!isLoading && !employer) {
            return <Box mt={2} p={2} className="wh-tw-bg-white" width={1}>
                <WildHealthPlaceHolder message="No employer data" />
            </Box>
        }

        return (
            <Box px={isSmallScreen && 2}>
                <Box p={2} display="flex" alignItems="center" gridGap={8}>
                    <Box className="wh-tw-text-sm wh-tw-text-gray600 wh-tw-font-medium wh-tw-cursor-pointer" onClick={() => navigationService.toManageEmployersProfiles(history)}>
                        Employers Profiles 
                    </Box>
                    <Box className="wh-tw-text-sm wh-tw-font-medium">
                        /
                    </Box>
                    <Box className="wh-tw-text-sm wh-tw-font-medium">
                        {employer.name}
                    </Box>
                </Box>
                <WildHealthTabControl
                    items={tabs}
                    className={initialClassName}
                />
            </Box>
        )
    }

    return (
        <AuthenticatedLayoutComponent
            title={TitlesService.employersProfilesTitle}
            pageName={PagesNamesService.employersProfiles}
            selectedMenuItem={MenuItemTypes.ManageEmployersProfiles}
            hideBackground
        >
            {renderContent()}
        </AuthenticatedLayoutComponent>
    )
}