import { useState, useEffect } from "react";
import { HealthScoreCategoriesDisplaytModel } from '../../../healthScore/models/score.model';

interface HealthScoreCategoriesViewModel extends HealthScoreCategoriesDisplaytModel {
  isOpened: boolean;
}

interface BiomarkersComponentState {
  isOpen: boolean;
  categories: HealthScoreCategoriesViewModel[];
}

/**
 * Custom Hook to manage a view Model for Biomarkers component
 */
export function useFacade(categories: HealthScoreCategoriesDisplaytModel[]): [
  BiomarkersComponentState,
  (index: number, status: boolean) => void,
  (status: boolean) => void,
] {

  const [state, setState] = useState({
    categories: [],
    isOpen: false,
  } as BiomarkersComponentState);

  const handleCategoryTooltipToggle = (index: number, status: boolean) => {
    state.categories.forEach((c, idx) => {
      if (idx === index) {
        c.isOpened = status;
      }
    })
    setState(state => ({ ...state }));
  }

  const handleTooltipToggle = (status: boolean) => {
    setState({ ...state, isOpen: status });
  }

  useEffect(() => {
    setState(state => ({ ...state, categories: categories.map(i => { return { ...i, isOpened: false } as HealthScoreCategoriesViewModel }) }));
  }, []);

  return [
    state,
    handleCategoryTooltipToggle,
    handleTooltipToggle,
  ];
}
