import { createStyles, makeStyles } from "@material-ui/core/styles";
import { colors } from "../../../../../common/constants/colors";

export const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontSize: 16,
      lineHeight: '22px',
      fontWeight: 500,
      color: colors.black,
    },
    cardDivider: {
      backgroundColor: "#EFF3F8",
      margin: '10px auto',
      height: 1,
      width: '100%'
    },
    historyLinkText: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "16px",
      marginRight: 11,
    },
    historyLink: {
      color: colors.main,
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
    },
    avatarPreparing: {
      minWidth: 6,
      width: 6,
      height: 6,
      backgroundColor: colors.gray1,
      borderRadius: '50%',
    },
    avatarGood: {
      minWidth: 6,
      width: 6,
      height: 6,
      backgroundColor: colors.good,
      borderRadius: '50%',
    },
    avatarOrange: {
      minWidth: 6,
      width: 6,
      height: 6,
      backgroundColor: colors.average1,
      borderRadius: '50%',
    },
    avatarDanger: {
      minWidth: 6,
      width: 6,
      height: 6,
      backgroundColor: colors.bad,
      borderRadius: '50%',
    },
    avatarNA: {
      minWidth: 6,
      width: 6,
      height: 6,
      borderRadius: '50%',
      border: `1px solid ${colors.mediumGray}`,
    },
  })
);


