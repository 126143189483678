import { StringValueToken } from "html2canvas/dist/types/css/syntax/tokenizer";
import { StateModel } from "../../account/models/address.model";
import {CoverageStatus} from "../enums/coverageStatus";
import { AddressModel } from "../../common/models/address.models";

export interface PolicyHolderModel {
    firstName: string,
    lastName: string,
    relationship: string,
    dateOfBirth: string
}

export interface CreateCoverageModel {
    insuranceId: number;
    memberId: string;
    isPrimary: boolean;
    frontImage: File;
    backImage: File;
    policyHolder: PolicyHolderModel;
}

export interface CreateCoverageOnBehalfModel extends CreateCoverageModel {
    patientId: number;
}

export interface UpdateCoverageModel {
    id: number;
    insuranceId: number;
    memberId: string;
    isPrimary: boolean;
    frontImage: File;
    backImage: File;
    policyHolder: PolicyHolderModel;
}

export interface UpdateCoverageOnBehalfModel extends UpdateCoverageModel{
    patientId: number;
}

export interface InsuranceVerificationModel {
    id: number;
    patientId: number,
    isVerified: boolean,
    coverageId: string,
  }

export interface InsuranceVerificationDetailsModel {
    benefits: BenefitModel[];
    sender: SenderModel;
    subscriber: SubscriberModel;
    dependent: SubscriberModel;B
}

interface BenefitModel {
    amount: number;
    coverageLevelCode: string;
    coveragePlanDescription: string;
    dateTimePeriod: string;
    dateTimePeriodFormat: string;
    eligibilityCode: string;
    inNetworkIndicator: string;
    insuranceTypeCode: string;
    message: string;
    quantityQualifier: string;
    serviceTypeCode: string;
    timePeriodQualifier: string;
}

interface SenderModel {
    entityIdentifierCode: string;
    entityTypeQualifier: string;
    identificationCodeQualifier: string;
    identifier: string;
    organizationName: string;
}

interface SubscriberModel {
    address: string;
    birthday: string;
    city: string;
    entityIdentifierCode: string;
    entityTypeQualifier: string;
    firstName: string;  
    gender: string;
    identificationCodeQualifier: string;
    insuredBenefit: InsuredBenefitModel;
    lastName: string;
    memberId: string;
    middleInitial: string;
    state: string;
    validation: ValidationModel;
    zipCode: string;
}

interface InsuredBenefitModel {
    insuredIsSubscriber: string;
    maintenanceReasonCode: string;
    maintenanceTypeCode: string;
    relationshipCode: string;
}

interface ValidationModel {
    responseCode: string;
    reasonCode: string;
    actionCode: string;
}

export interface VerificationsHistoryModel {
    id: number;
    patientId: number,
    isVerified: boolean,
    coverageId: number,
    runAt: Date,
}

export enum CoveragePriority {
    Primary = 10,
    Secondary = 20,
    Tertiary = 30
}

export interface CoverageModel {
    id: number;
    memberId: string;
    status: CoverageStatus;
    priority: CoveragePriority;
    policyHolder: PolicyHolderModel;
    insurance: InsuranceModel;
    verifications: InsuranceVerificationModel[];
    universalId: string;
}

export const Relationships = {
    'SELF': 'Self',
    'CHILD': 'Child',
    'SPS': 'Spouse',
    'E': 'Employer',
    'U': 'Unknown',
    'OTHER': 'Other'
}

export const RelationshipNames: Map<string, string> = new Map<string, string>()
    .set('SELF', 'Self')
    .set('CHILD', 'Child')
    .set('SPS', 'Spouse')
    .set('E', 'Employer')
    .set('U', 'Unknown')
    .set('OTHER', 'Other');

export interface InsuranceModel {
    id: number;
    name: string;
    states: StateModel[];
}

export interface AddInsuranceModel {
    insurance: InsuranceModel;
    states: StateModel[];
}

export interface UpdateInsuranceStatesModel {
    id: number;
    states: StateModel[];
}

export interface RemitModel {
    id: string;
    createdDate: string;
    details: RemitDetailModel[];
    meta: MetaModel;
    organization: IdentifierModel;
    total: CostModel;
}

interface RemitDetailModel {
    payee: IdentifierModel;
    amount: CostModel;
    date: string;
    claim: IdentifierModel;
    type: {
        codings: CodingModel[];
    }
}

interface CodingModel {
    code: string;
    display: StringValueToken;
    system: string;
}

interface MetaModel {
    lastUpdated: string;
}

interface IdentifierModel {
    identifier: string;
}

interface CostModel {
    unit: string;
    value: number;
}

interface InsurancePlan {
    insurance: InsuranceModel;
    name: string;
}

export interface RealTimeEligibilityConfiguration {
    failureManagementType: number;
    npi: string;
    tradingPartnerId: string;
    requireGroupNumber: boolean;
    includeCptCode: boolean;
    memberIdValidationRegex: string;
}

export interface ClaimAutomationConfiguration {
    useSubscriberName: boolean;
    addressOverride: AddressModel;
}

interface ClaimSubmissionConfiguration {
    claimsPartnerId: string;
    cptCode: string;
    modifier: string;
    lockCodeEntries: boolean;
    placeOfService: string;
    includeRenderingProviderInformation: boolean;
}

interface RealTimeSubmissionConfiguration {
    serviceTypeCode: string;
    payerOverride: string;
}

interface RealTimeResponseConfiguration {
    use271Price: boolean;
    preDeductibleSubsidy: number;
    postDeductibleSubsidy: number;
    useOutOfPocketMaximum: boolean;
    preDeductibleOrganizationSubsidy: number;
    postDeductibleOrganizationSubsidy: number;
    nonCoveredExclusions: boolean;
    includeEligibilityMessageRegex: string;
    excludeEligibilityMessageRegex: string;
    eligibleGroupNumbers: string;
    groupIdRegex: string;
    rejectIfNoServiceTypeBenefit: boolean;
    blockPlanDescriptions: string;
    blockInsurancePlans: string;
    allowZeroDollarDeductible: boolean;
    allowZeroDollarCopay: boolean;
    allowZeroPercentCoinsurance: boolean;
    responseServiceTypes: string;
    copayPreDeductibleRegex: string;
    copayPostDeductibleRegex: string;
    determineDeductibleRegex: string;
    determineCopayRegex: string;
    determineCoinsuranceRegex: string;
    alwaysApplyCopayAfterDeductible: boolean;
}

export interface ServiceConfiguration {
    id?: number;
    label: string;
    type: number;
    fee: number;
    maximumVisitLength: number;
    minimumVisitLength: number;
    newPatientsOnly: boolean;
    supportsRte: boolean;
    supportsClaims: boolean;
    insuranceConfigId: number;
    claimSubmissionConfig: ClaimSubmissionConfiguration;
    rteSubmissionConfig: RealTimeSubmissionConfiguration;
    rteResponseConfig: RealTimeResponseConfiguration;
    stateIds: number[];
    isOpen: boolean;
    insurancePlanIds: number[];
}

const emptyClaimSubmissionConfiguration = {
    claimsPartnerId: '',
    cptCode: '',
    modifier: '',
    lockCodeEntries: false,
    placeOfService: '',
    includeRenderingProviderInformation: false
}

const emptyRealTimeSubmissionConfiguration = {
    serviceTypeCode: '',
    payerOverride: ''
}

const emptyRealTimeResponseConfiguration = {
    use271Price: false,
    preDeductibleSubsidy: 0,
    postDeductibleSubsidy: 0,
    useOutOfPocketMaximum: false,
    preDeductibleOrganizationSubsidy: 0,
    postDeductibleOrganizationSubsidy: 0,
    nonCoveredExclusions: false,
    includeEligibilityMessageRegex: '',
    excludeEligibilityMessageRegex: '',
    eligibleGroupNumbers: '',
    groupIdRegex: '',
    rejectIfNoServiceTypeBenefit: false,
    blockPlanDescriptions: '',
    blockInsurancePlans: '',
    allowZeroDollarDeductible: false,
    allowZeroDollarCopay: false,
    allowZeroPercentCoinsurance: false,
    responseServiceTypes: '',
    copayPreDeductibleRegex: '',
    copayPostDeductibleRegex: '',
    determineDeductibleRegex: '',
    determineCopayRegex: '',
    determineCoinsuranceRegex: '',
    alwaysApplyCopayAfterDeductible: false
}

const emptyServiceConfiguration: ServiceConfiguration = {
    id: 0,
    label: '',
    type: 0,
    fee: 0,
    maximumVisitLength: 0,
    minimumVisitLength: 0,
    newPatientsOnly: false,
    supportsRte: false,
    supportsClaims: false,
    insuranceConfigId: 0,
    claimSubmissionConfig: Object.assign({}, emptyClaimSubmissionConfiguration),
    rteSubmissionConfig: Object.assign({}, emptyRealTimeSubmissionConfiguration),
    rteResponseConfig: Object.assign({}, emptyRealTimeResponseConfiguration),
    isOpen: true,
    stateIds: [],
    insurancePlanIds: []
}

export const getInitServiceConfiguration = () => Object.assign({}, emptyServiceConfiguration);

export interface InsuranceConfiguration {
    id: number;
    insurance: InsuranceModel;
    payerNameAlias: string;
    payerLogo: string;
    groupIdDisplayName: string;
    memberIdDisplayName: string;
    supportsEligibilityLookup: boolean;
    providerInstructions: string;
    patientMessaging: string;
    startDate: Date;
    endDate: Date;
    rteConfig: RealTimeEligibilityConfiguration;
    claimAutomationConfig: ClaimAutomationConfiguration;
    serviceConfigurations: ServiceConfiguration[];
    insurancePlans: InsurancePlan[];
}

export interface CreateInsuranceConfigurationModel {
    payerNameAlias: string;
    payerLogo: string;
    groupIdDisplayName: string;
    memberIdDisplayName: string;
    supportsEligibilityLookup: boolean;
    providerInstructions: string;
    patientMessaging: string;
    startDate: Date;
    endDate: Date;
    insuranceId: number;
    practiceId: number;
    rteConfig: RealTimeEligibilityConfiguration;
    claimAutomationConfig: ClaimAutomationConfiguration;
}

export interface UpdateInsuranceConfigurationModel extends CreateInsuranceConfigurationModel {
    id: number;
    insurancePlanIds: number[];
}

export interface CreateServiceConfigurationModel extends ServiceConfiguration {
    stateIds: number[];
}

export interface UpdateServiceConfigurationModel extends CreateServiceConfigurationModel {
    id: number;
}

export interface InsurancePlanModel {
    id: number;
    name: string;
    insuranceId: number;
    identifier: string;
}

export interface InsuranceAvailabilityModel {
    isAvailable: boolean;
    unavailabilityReason: string;
}