export class DetailedQuestionNames {
    static readonly CARDIOVASCULAR: string = "CARDIOVASCULAR";
    static readonly CARDIOVASCULAR_VARIANTS: string = "CARDIOVASCULAR_VARIANTS";
    static readonly HIGH_BLOOD_PRESSURE_YEAR: string = "HIGH_BLOOD_PRESSURE_YEAR";
    static readonly HIGH_BLOOD_PRESSURE_TREATMENT: string = "HIGH_BLOOD_PRESSURE_TREATMENT";
    static readonly HIGH_BLOOD_PRESSURE_ACTIVE: string = "HIGH_BLOOD_PRESSURE_ACTIVE";
    static readonly PULMONARY_EMBOLISM_YEAR : string = "PULMONARY_EMBOLISM_YEAR";
    static readonly PULMONARY_EMBOLISM_TREATMENT : string = "PULMONARY_EMBOLISM_TREATMENT";
    static readonly PULMONARY_EMBOLISM_ACTIVE : string = "PULMONARY_EMBOLISM_ACTIVE";
    static readonly DEEP_VIEN_THROMBOSIS_YEAR : string = "DEEP_VIEN_THROMBOSIS_YEAR";
    static readonly DEEP_VIEN_THROMBOSIS_TREATMENT : string = "DEEP_VIEN_THROMBOSIS_TREATMENT";
    static readonly DEEP_VIEN_THROMBOSIS_ACTIVE : string = "DEEP_VIEN_THROMBOSIS_ACTIVE";
    static readonly CAROTID_STENOSIS_YEAR : string = "CAROTID_STENOSIS_YEAR";
    static readonly CAROTID_STENOSIS_TREATMENT : string = "CAROTID_STENOSIS_TREATMENT";
    static readonly CAROTID_STENOSIS_ACTIVE : string = "CAROTID_STENOSIS_ACTIVE";
    static readonly DIAGNOSED_EATING_DISORDER_ISSUES : string = "DIAGNOSED_EATING_DISORDER_ISSUES";
    static readonly DIAGNOSED_EATING_DISORDER_ISSUES_VARIANTS : string = "DIAGNOSED_EATING_DISORDER_ISSUES_VARIANTS";
    static readonly CORONARY_ARTERY_DISEASE_YEAR: string = "CORONARY_ARTERY_DISEASE_YEAR";
    static readonly CORONARY_ARTERY_DISEASE_TREATMENT: string = "CORONARY_ARTERY_DISEASE_TREATMENT";
    static readonly CORONARY_ARTERY_DISEASE_ACTIVE: string = "CORONARY_ARTERY_DISEASE_ACTIVE";
    static readonly HEART_ATTACK_YEAR: string = "HEART_ATTACK_YEAR";
    static readonly HEART_ATTACK_TREATMENT: string = "HEART_ATTACK_TREATMENT";
    static readonly HEART_ATTACK_ACTIVE: string = "HEART_ATTACK_ACTIVE";
    static readonly CONGESTIVE_HEART_FAILURE_YEAR: string = "CONGESTIVE_HEART_FAILURE_YEAR";
    static readonly CONGESTIVE_HEART_FAILURE_TREATMENT: string = "CONGESTIVE_HEART_FAILURE_TREATMENT";
    static readonly CONGESTIVE_HEART_FAILURE_ACTIVE: string = "CONGESTIVE_HEART_FAILURE_ACTIVE";
    static readonly HIGH_CHOLESTEROL_YEAR: string = "HIGH_CHOLESTEROL_YEAR";
    static readonly HIGH_CHOLESTEROL_TREATMENT: string = "HIGH_CHOLESTEROL_TREATMENT";
    static readonly HIGH_CHOLESTEROL_ACTIVE: string = "HIGH_CHOLESTEROL_ACTIVE";
    static readonly BLOOD_CLOTTING_DISORDER_YEAR: string = "BLOOD_CLOTTING_DISORDER_YEAR";
    static readonly BLOOD_CLOTTING_DISORDER_TREATMENT: string = "BLOOD_CLOTTING_DISORDER_TREATMENT";
    static readonly BLOOD_CLOTTING_DISORDER_ACTIVE: string = "BLOOD_CLOTTING_DISORDER_ACTIVE";
    static readonly ANEURYSM_YEAR: string = "ANEURYSM_YEAR";
    static readonly ANEURYSM_TREATMENT: string = "ANEURYSM_TREATMENT";
    static readonly ANEURYSM_ACTIVE: string = "ANEURYSM_ACTIVE";
    static readonly CARDIAC_VALVE_PROBLEM_YEAR: string = "CARDIAC_VALVE_PROBLEM_YEAR";
    static readonly CARDIAC_VALVE_PROBLEM_TREATMENT: string = "CARDIAC_VALVE_PROBLEM_TREATMENT";
    static readonly CARDIAC_VALVE_PROBLEM_ACTIVE: string = "CARDIAC_VALVE_PROBLEM_ACTIVE";
    static readonly AFIB_YEAR: string = "AFIB_YEAR";
    static readonly AFIB_TREATMENT: string = "AFIB_TREATMENT";
    static readonly AFIB_ACTIVE: string = "AFIB_ACTIVE";
    static readonly GASTROINTESTINAL_ISSUES: string = "GASTROINTESTINAL_ISSUES";
    static readonly GASTROINTESTINAL_ISSUES_VARIANTS: string = "GASTROINTESTINAL_ISSUES_VARIANTS";
    static readonly SKIN_ISSUES: string = "SKIN_ISSUES";
    static readonly SKIN_ISSUES_VARIANTS: string = "SKIN_ISSUES_VARIANTS";
    static readonly URINARY_ISSUES: string = "URINARY_ISSUES";
    static readonly URINARY_ISSUES_VARIANTS: string = "URINARY_ISSUES_VARIANTS";
    static readonly KIDNEY_ISSUES: string = "KIDNEY_ISSUES";
    static readonly KIDNEY_ISSUES_VARIANTS: string = "KIDNEY_ISSUES_VARIANTS";
    static readonly LUNG_ISSUES: string = "LUNG_ISSUES";
    static readonly LUNG_ISSUES_VARIANTS: string = "LUNG_ISSUES_VARIANTS";
    static readonly NEUROLOGICAL_ISSUES: string = "NEUROLOGICAL_ISSUES";
    static readonly NEUROLOGICAL_ISSUES_VARIANTS: string = "NEUROLOGICAL_ISSUES_VARIANTS";
    static readonly ENDOCRINE_HORMONAL_ISSUES: string = "ENDOCRINE_HORMONAL_ISSUES";
    static readonly ENDOCRINE_HORMONAL_ISSUES_VARIANTS: string = "ENDOCRINE_HORMONAL_ISSUES_VARIANTS";
    static readonly MENTAL_HEALTH_ISSUES: string = "MENTAL_HEALTH_ISSUES";
    static readonly MENTAL_HEALTH_ISSUES_VARIANTS: string = "MENTAL_HEALTH_ISSUES_VARIANTS";
    static readonly BLOOD_ISSUES: string = "BLOOD_ISSUES";
    static readonly BLOOD_ISSUES_VARIANTS: string = "BLOOD_ISSUES_VARIANTS";
    static readonly MUSCULOSKELETAL_ISSUES: string = "MUSCULOSKELETAL_ISSUES";
    static readonly MUSCULOSKELETAL_ISSUES_VARIANTS: string = "MUSCULOSKELETAL_ISSUES_VARIANTS";
    static readonly CANCER_ISSUES: string = "CANCER_ISSUES";
    static readonly CANCER_ISSUES_COMMENT: string = "CANCER_ISSUES_COMMENT";
    static readonly CANCER_ISSUES_VARIANTS: string = "CANCER_ISSUES_VARIANTS";
    static readonly BREAST_CANCER_YEAR: string = "BREAST_CANCER_YEAR";
    static readonly BREAST_CANCER_TREATMENT: string = "BREAST_CANCER_TREATMENT";
    static readonly BREAST_CANCER_ACTIVE: string = "BREAST_CANCER_ACTIVE";
    static readonly PROSTATE_CANCER_YEAR: string = "PROSTATE_CANCER_YEAR";
    static readonly PROSTATE_CANCER_TREATMENT: string = "PROSTATE_CANCER_TREATMENT";
    static readonly PROSTATE_CANCER_ACTIVE: string = "PROSTATE_CANCER_ACTIVE";
    static readonly LUNG_CANCER_YEAR: string = "LUNG_CANCER_YEAR";
    static readonly LUNG_CANCER_TREATMENT: string = "LUNG_CANCER_TREATMENT";
    static readonly LUNG_CANCER_ACTIVE: string = "LUNG_CANCER_ACTIVE";
    static readonly COLON_CANCER_YEAR: string = "COLON_CANCER_YEAR";
    static readonly COLON_CANCER_TREATMENT: string = "COLON_CANCER_TREATMENT";
    static readonly COLON_CANCER_ACTIVE: string = "COLON_CANCER_ACTIVE";
    static readonly BONE_CANCER_YEAR: string = "BONE_CANCER_YEAR";
    static readonly BONE_CANCER_TREATMENT: string = "BONE_CANCER_TREATMENT";
    static readonly BONE_CANCER_ACTIVE: string = "BONE_CANCER_ACTIVE";
    static readonly LIVER_CANCER_YEAR: string = "LIVER_CANCER_YEAR";
    static readonly LIVER_CANCER_TREATMENT: string = "LIVER_CANCER_TREATMENT";
    static readonly LIVER_CANCER_ACTIVE: string = "LIVER_CANCER_ACTIVE";
    static readonly KIDNEY_CANCER_YEAR: string = "KIDNEY_CANCER_YEAR";
    static readonly KIDNEY_CANCER_TREATMENT: string = "KIDNEY_CANCER_TREATMENT";
    static readonly KIDNEY_CANCER_ACTIVE: string = "KIDNEY_CANCER_ACTIVE";
    static readonly BAIN_CANCER_YEAR: string = "BAIN_CANCER_YEAR";
    static readonly BAIN_CANCER_TREATMENT: string = "BAIN_CANCER_TREATMENT";
    static readonly BAIN_CANCER_ACTIVE: string = "BAIN_CANCER_ACTIVE";
    static readonly SKIN_CANCER_YEAR: string = "SKIN_CANCER_YEAR";
    static readonly SKIN_CANCER_TREATMENT: string = "SKIN_CANCER_TREATMENT";
    static readonly SKIN_CANCER_ACTIVE: string = "SKIN_CANCER_ACTIVE";
    static readonly SURGERY_ISSUES: string = "SURGERY_ISSUES";
    static readonly SURGERY_ISSUES_VARIANTS: string = "SURGERY_ISSUES_VARIANTS";
    static readonly APPENDECTOMY_SURGERY_YEAR: string = "APPENDECTOMY_SURGERY_YEAR";
    static readonly CORONARY_ARTERY_BYPASS_SURGERY_YEAR: string = "CORONARY_ARTERY_BYPASS_SURGERY_YEAR";
    static readonly CAROTID_ENDARTERECTOMY_SURGERY_YEAR: string = "CAROTID_ENDARTERECTOMY_SURGERY_YEAR";
    static readonly CHOLECYSTECTOMY_SURGERY_YEAR: string = "CHOLECYSTECTOMY_SURGERY_YEAR";
    static readonly BARIATRIC_SURGERY_YEAR: string = "BARIATRIC_SURGERY_YEAR";
    static readonly HYSTERECTOMY_SURGERY_YEAR: string = "HYSTERECTOMY_SURGERY_YEAR";
    static readonly NEPHRECTOMY_SURGERY_YEAR: string = "NEPHRECTOMY_SURGERY_YEAR";
    static readonly SPLENECTOMY_SURGERY_YEAR: string = "SPLENECTOMY_SURGERY_YEAR";
    static readonly TONSILLECTOMY_SURGERY_YEAR: string = "TONSILLECTOMY_SURGERY_YEAR";
    static readonly CORONARY_ARTERY_BYPASS: string = "CORONARY_ARTERY_BYPASS";
    static readonly SMOKING: string = "SMOKING";
    static readonly YEARS_SMOKING: string = "YEARS_SMOKING";
    static readonly CIGARETTES_DAY: string = "CIGARETTES_DAY";
    static readonly DRINK_ISSUES: string = "DRINK_ISSUES";
    static readonly AVERAGE_DRINK_ISSUES: string = "AVERAGE_DRINK_ISSUES";
    static readonly REDUCE_DRINK_ISSUES: string = "REDUCE_DRINK_ISSUES";
    static readonly CRITICIZING_ISSUES: string = "CRITICIZING_ISSUES";
    static readonly FELT_GUILTY_ISSUES: string = "FELT_GUILTY_ISSUES";
    static readonly EYE_OPENER_ISSUES: string = "EYE_OPENER_ISSUES";
    static readonly CONSUME_ALCOHOL: string = "CONSUME_ALCOHOL";
    static readonly CONSUME_CIGARETTES: string = "CONSUME_CIGARETTES";
    static readonly SMOKE_MARIJUANA: string = "SMOKE_MARIJUANA";
    static readonly DRUGS_ISSUES: string = "DRUGS_ISSUES";
    static readonly DRUGS_ISSUES_VARIANTS: string = "DRUGS_ISSUES_VARIANTS";
    static readonly DRUGS_ISSUES_COMMENT: string = "DRUGS_ISSUES_COMMENT";
    static readonly PARTNERSHIP_STATUS: string = "PARTNERSHIP_STATUS";
    static readonly RELATIONSHIP_STATUS: string = "RELATIONSHIP_STATUS";
    static readonly HAVE_CHILDREN: string = "HAVE_CHILDREN";
    static readonly CHILDREN_NAME: string = "CHILDREN_NAME";
    static readonly CHILDREN_AGE: string = "CHILDREN_AGE";
    static readonly LIVE_ALONE: string = "LIVE_ALONE";
    static readonly SEXUALLY_ACTIVE: string = "SEXUALLY_ACTIVE";
    static readonly CONTRACEPTION_PROTECTION: string = "CONTRACEPTION_PROTECTION";
    static readonly LEARNING_CONTRACEPTION_PROTECTION: string = "LEARNING_CONTRACEPTION_PROTECTION";
    static readonly MENSTRUAL_CYCLE: string = "MENSTRUAL_CYCLE";
    static readonly MONTHLY_PERIOD: string = "MONTHLY_PERIOD";
    static readonly MENOPAUSE: string = "MENOPAUSE";
    static readonly MENOPAUSE_AGE: string = "MENOPAUSE_AGE";
    static readonly PREGNANT: string = "PREGNANT";
    static readonly PREGNANT_COMMENT: string = "PREGNANT_COMMENT";
    static readonly TOTAL_PREGNANCIES: string = "TOTAL_PREGNANCIES";
    static readonly FULL_TERM_BIRTHS: string = "FULL_TERM_BIRTHS";
    static readonly PREMATURE: string = "PREMATURE";
    static readonly MISCARRIAGES: string = "MISCARRIAGES";
    static readonly ABORTIONS: string = "ABORTIONS";
    static readonly PREGNANCY_COMPLICATIONS: string = "PREGNANCY_COMPLICATIONS";
    static readonly PREGNANCY_COMPLICATIONS_VARIANTS: string = "PREGNANCY_COMPLICATIONS_VARIANTS";
    static readonly BIOLOGICAL_MOTHER_LIVING: string = "BIOLOGICAL_MOTHER_LIVING";
    static readonly BIOLOGICAL_MOTHER_MEDICAL_PROBLEMS: string = "BIOLOGICAL_MOTHER_MEDICAL_PROBLEMS";
    static readonly BIOLOGICAL_FATHER_LIVING: string = "BIOLOGICAL_FATHER_LIVING";
    static readonly BIOLOGICAL_FATHER_MEDICAL_PROBLEMS: string = "BIOLOGICAL_FATHER_MEDICAL_PROBLEMS";
    static readonly BIOLOGICAL_SIBLING: string = "BIOLOGICAL_SIBLING";
    static readonly BIOLOGICAL_SIBLING_LIVING: string = "BIOLOGICAL_SIBLING_LIVING";
    static readonly BIOLOGICAL_SIBLING_MEDICAL_PROBLEMS: string = "BIOLOGICAL_SIBLING_MEDICAL_PROBLEMS";
    static readonly YOUR_GENE_MUTATIONS: string = "YOUR_GENE_MUTATIONS";
    static readonly FAMILY_GENE_MUTATIONS: string = "FAMILY_GENE_MUTATIONS";
    static readonly MUTATIONS_DIAGNOSED: string = "MUTATIONS_DIAGNOSED";
    static readonly CANCER_DIAGNOSED: string = "CANCER_DIAGNOSED";
    static readonly BRCA_FAMILY_GENE_MUTATIONS: string = "BRCA_FAMILY_GENE_MUTATIONS";
    static readonly MAMMOGRAM: string = "MAMMOGRAM";
    static readonly DATE_OF_LAST_MAMMOGRAM: string = "DATE_OF_LAST_MAMMOGRAM";
    static readonly MAMMOGRAM_ABNORMALITIES: string = "MAMMOGRAM_ABNORMALITIES";
    static readonly PAP_SMEAR: string = "PAP_SMEAR";
    static readonly DATE_OF_LAST_PAP_SMEAR: string = "DATE_OF_LAST_PAP_SMEAR";
    static readonly PAP_SMEAR_ABNORMALITIES: string = "PAP_SMEAR_ABNORMALITIES";
    static readonly COLON_CANCER: string = "COLON_CANCER";
    static readonly COLON_CANCER_DIAGNOSED: string = "COLON_CANCER_DIAGNOSED";
    static readonly COLON_CANCER_DIAGNOSED_AGE: string = "COLON_CANCER_DIAGNOSED_AGE";
    static readonly COLONOSCOPY: string = "COLONOSCOPY";
    static readonly SPECIFY_SCREENING: string = "SPECIFY_SCREENING";
    static readonly DATE_OF_LAST_SCREENING: string = "DATE_OF_LAST_SCREENING";
    static readonly COLONOSCOPY_ABNORMALITIES: string = "COLONOSCOPY_ABNORMALITIES";
    static readonly PSA_OR_PROSTATE: string = "PSA_OR_PROSTATE";
    static readonly DATE_OF_PSA_OR_PROSTATE_EXAM: string = "DATE_OF_PSA_OR_PROSTATE_EXAM";
    static readonly PSA_ABNORMALITIES: string = "PSA_ABNORMALITIES";
    static readonly LUNG_CANCER_SCREENINGS: string = "LUNG_CANCER_SCREENINGS";
    static readonly DATE_OF_LUNG_CANCER: string = "DATE_OF_LUNG_CANCER";
    static readonly MORE_60_OSTEOPOROSIS: string = "MORE_60_OSTEOPOROSIS";
    static readonly MORE_65_OSTEOPOROSIS: string = "MORE_65_OSTEOPOROSIS";
    static readonly MORE_65_ABNORMALITIES: string = "MORE_65_ABNORMALITIES";
    static readonly TETANUS_VACCINE: string = "TETANUS_VACCINE";
    static readonly DATE_OF_LAST_TETANUS_VACCINE: string = "DATE_OF_LAST_TETANUS_VACCINE";
    static readonly PNEUMONIA_VACCINE: string = "PNEUMONIA_VACCINE";
    static readonly DATE_OF_LAST_PNEUMONIA_VACCINE: string = "DATE_OF_LAST_PNEUMONIA_VACCINE";
    static readonly FLU_VACCINE_SEASON: string = "FLU_VACCINE_SEASON";
    static readonly DATE_OF_LAST_FLU_VACCINE: string = "DATE_OF_LAST_FLU_VACCINE";
    static readonly COVID_VACCINE: string = "COVID_VACCINE";
    static readonly DATE_OF_LAST_COVID_VACCINE: string = "DATE_OF_LAST_COVID_VACCINE";
    static readonly HEALTH_WELLNESS_REVIEW: string = "HEALTH_WELLNESS_REVIEW";
    static readonly PHQ2_1: string = "PHQ_2_1";
    static readonly PHQ2_2: string = "PHQ_2_2";
    static readonly PHQ8_1: string = "PHQ_8_1";
    static readonly PHQ8_2: string = "PHQ_8_2";
    static readonly PHQ8_3: string = "PHQ_8_3";
    static readonly PHQ8_4: string = "PHQ_8_4";
    static readonly PHQ8_5: string = "PHQ_8_5";
    static readonly PHQ8_6: string = "PHQ_8_6";
    static readonly PHQ8_7: string = "PHQ_8_7";
    static readonly PHQ8_8: string = "PHQ_8_8";
}

export class DetailedQuestionnaireSectionNames {
    static readonly CARDIOVASCULAR: string = "CARDIOVASCULAR";
    static readonly GASTROINTESTINAL_ISSUES: string = "GASTROINTESTINAL_ISSUES";
    static readonly SKIN_ISSUES: string = "SKIN_ISSUES";
    static readonly URINARY_ISSUES: string = "URINARY_ISSUES";
    static readonly KIDNEY_ISSUES: string = "KIDNEY_ISSUES";
    static readonly LUNG_ISSUES: string = "LUNG_ISSUES";
    static readonly NEUROLOGICAL_ISSUES: string = "NEUROLOGICAL_ISSUES";
    static readonly ENDOCRINE_HORMONAL_ISSUES: string = "ENDOCRINE_HORMONAL_ISSUES";
    static readonly MENTAL_HEALTH_ISSUES: string = "MENTAL_HEALTH_ISSUES";
    static readonly BLOOD_ISSUES: string = "BLOOD_ISSUES";
    static readonly MUSCULOSKELETAL_ISSUES: string = "MUSCULOSKELETAL_ISSUES";
    static readonly CANCER_ISSUES: string = "CANCER_ISSUES";
    static readonly DIAGNOSED_EATING_DISORDER_ISSUES: string = "DIAGNOSED_EATING_DISORDER_ISSUES";
    static readonly SURGERY_ISSUES: string = "SURGERY_ISSUES";
    static readonly SMOKING: string = "SMOKING";
    static readonly DRINK_ISSUES: string = "DRINK_ISSUES";
    static readonly DRUGS_ISSUES: string = "DRUGS_ISSUES";
    static readonly PARTNERSHIP_STATUS: string = "PARTNERSHIP_STATUS";
    static readonly RELATIONSHIP_STATUS: string = "RELATIONSHIP_STATUS";
    static readonly HAVE_CHILDREN: string = "HAVE_CHILDREN";
    static readonly LIVE_ALONE: string = "LIVE_ALONE";
    static readonly SEXUALLY_ACTIVE: string = "SEXUALLY_ACTIVE";
    static readonly MENSTRUAL_CYCLE: string = "MENSTRUAL_CYCLE";
    static readonly MENOPAUSE: string = "MENOPAUSE";
    static readonly PREGNANT: string = "PREGNANT";
    static readonly PREGNANCY_COMPLICATIONS: string = "PREGNANCY_COMPLICATIONS";
    static readonly BIOLOGICAL_MOTHER: string = "BIOLOGICAL_MOTHER";
    static readonly BIOLOGICAL_FATHER: string = "BIOLOGICAL_FATHER";
    static readonly BIOLOGICAL_SIBLING: string = "BIOLOGICAL_SIBLING";
    static readonly YOUR_GENE_MUTATIONS: string = "YOUR_GENE_MUTATIONS";
    static readonly FAMILY_GENE_MUTATIONS: string = "FAMILY_GENE_MUTATIONS";
    static readonly MAMMOGRAM: string = "MAMMOGRAM";
    static readonly PAP_SMEAR: string = "PAP_SMEAR";
    static readonly COLON_CANCER: string = "COLON_CANCER";
    static readonly COLONOSCOPY: string = "COLONOSCOPY";
    static readonly PSA_OR_PROSTATE: string = "PSA_OR_PROSTATE";
    static readonly LUNG_CANCER_SCREENINGS: string = "LUNG_CANCER_SCREENINGS";
    static readonly DATE_OF_LUNG_CANCER: string = "DATE_OF_LUNG_CANCER";
    static readonly MORE_60_OSTEOPOROSIS: string = "MORE_60_OSTEOPOROSIS";
    static readonly MORE_65_OSTEOPOROSIS: string = "MORE_65_OSTEOPOROSIS";
    static readonly TETANUS_VACCINE: string = "TETANUS_VACCINE";
    static readonly PNEUMONIA_VACCINE: string = "PNEUMONIA_VACCINE";
    static readonly FLU_VACCINE_SEASON: string = "FLU_VACCINE_SEASON";
    static readonly COVID_VACCINE: string = "COVID_VACCINE";
    static readonly HEALTH_WELLNESS_REVIEW: string = "HEALTH_WELLNESS_REVIEW";
    static readonly PHQ2: string = "PHQ2";
    static readonly PHQ8: string = "PHQ8";
}

export const detailedQuestionnaireSections = {
    [DetailedQuestionnaireSectionNames.CARDIOVASCULAR]: [
        DetailedQuestionNames.CARDIOVASCULAR,
        DetailedQuestionNames.CARDIOVASCULAR_VARIANTS,
        DetailedQuestionNames.HIGH_BLOOD_PRESSURE_YEAR,
        DetailedQuestionNames.HIGH_BLOOD_PRESSURE_TREATMENT,
        DetailedQuestionNames.HIGH_BLOOD_PRESSURE_ACTIVE,
        DetailedQuestionNames.CORONARY_ARTERY_DISEASE_YEAR,
        DetailedQuestionNames.CORONARY_ARTERY_DISEASE_TREATMENT,
        DetailedQuestionNames.CORONARY_ARTERY_DISEASE_ACTIVE,
        DetailedQuestionNames.HEART_ATTACK_YEAR,
        DetailedQuestionNames.HEART_ATTACK_TREATMENT,
        DetailedQuestionNames.HEART_ATTACK_ACTIVE,
        DetailedQuestionNames.AFIB_YEAR,
        DetailedQuestionNames.AFIB_TREATMENT,
        DetailedQuestionNames.AFIB_ACTIVE,
        DetailedQuestionNames.HIGH_CHOLESTEROL_YEAR,
        DetailedQuestionNames.HIGH_CHOLESTEROL_TREATMENT,
        DetailedQuestionNames.HIGH_CHOLESTEROL_ACTIVE,
        DetailedQuestionNames.BLOOD_CLOTTING_DISORDER_YEAR,
        DetailedQuestionNames.BLOOD_CLOTTING_DISORDER_TREATMENT,
        DetailedQuestionNames.BLOOD_CLOTTING_DISORDER_ACTIVE,
        DetailedQuestionNames.ANEURYSM_YEAR,
        DetailedQuestionNames.ANEURYSM_TREATMENT,
        DetailedQuestionNames.ANEURYSM_ACTIVE,
        DetailedQuestionNames.CARDIAC_VALVE_PROBLEM_YEAR,
        DetailedQuestionNames.CARDIAC_VALVE_PROBLEM_TREATMENT,
        DetailedQuestionNames.CARDIAC_VALVE_PROBLEM_ACTIVE
    ],
    [DetailedQuestionnaireSectionNames.GASTROINTESTINAL_ISSUES]: [
        DetailedQuestionNames.GASTROINTESTINAL_ISSUES,
        DetailedQuestionNames.GASTROINTESTINAL_ISSUES_VARIANTS
    ],
    [DetailedQuestionnaireSectionNames.SKIN_ISSUES]: [
        DetailedQuestionNames.SKIN_ISSUES,
        DetailedQuestionNames.SKIN_ISSUES_VARIANTS
    ],
    [DetailedQuestionnaireSectionNames.URINARY_ISSUES]: [
        DetailedQuestionNames.URINARY_ISSUES,
        DetailedQuestionNames.URINARY_ISSUES_VARIANTS
    ],
    [DetailedQuestionnaireSectionNames.KIDNEY_ISSUES]: [
        DetailedQuestionNames.KIDNEY_ISSUES,
        DetailedQuestionNames.KIDNEY_ISSUES_VARIANTS
    ],
    [DetailedQuestionnaireSectionNames.LUNG_ISSUES]: [
        DetailedQuestionNames.LUNG_ISSUES,
        DetailedQuestionNames.LUNG_ISSUES_VARIANTS
    ],
    [DetailedQuestionnaireSectionNames.NEUROLOGICAL_ISSUES]: [
        DetailedQuestionNames.NEUROLOGICAL_ISSUES,
        DetailedQuestionNames.NEUROLOGICAL_ISSUES_VARIANTS
    ],
    [DetailedQuestionnaireSectionNames.ENDOCRINE_HORMONAL_ISSUES]: [
        DetailedQuestionNames.ENDOCRINE_HORMONAL_ISSUES,
        DetailedQuestionNames.ENDOCRINE_HORMONAL_ISSUES_VARIANTS
    ],
    [DetailedQuestionnaireSectionNames.MENTAL_HEALTH_ISSUES]: [
        DetailedQuestionNames.MENTAL_HEALTH_ISSUES,
        DetailedQuestionNames.MENTAL_HEALTH_ISSUES_VARIANTS
    ],
    [DetailedQuestionnaireSectionNames.BLOOD_ISSUES]: [
        DetailedQuestionNames.BLOOD_ISSUES,
        DetailedQuestionNames.BLOOD_ISSUES_VARIANTS
    ],
    [DetailedQuestionnaireSectionNames.MUSCULOSKELETAL_ISSUES]: [
        DetailedQuestionNames.MUSCULOSKELETAL_ISSUES,
        DetailedQuestionNames.MUSCULOSKELETAL_ISSUES_VARIANTS
    ],
    [DetailedQuestionnaireSectionNames.CANCER_ISSUES]: [
        DetailedQuestionNames.CANCER_ISSUES,
        DetailedQuestionNames.CANCER_ISSUES_VARIANTS,
        DetailedQuestionNames.BREAST_CANCER_YEAR,
        DetailedQuestionNames.BREAST_CANCER_TREATMENT,
        DetailedQuestionNames.BREAST_CANCER_ACTIVE,
        DetailedQuestionNames.PROSTATE_CANCER_YEAR,
        DetailedQuestionNames.PROSTATE_CANCER_TREATMENT,
        DetailedQuestionNames.PROSTATE_CANCER_ACTIVE,
        DetailedQuestionNames.LUNG_CANCER_YEAR,
        DetailedQuestionNames.LUNG_CANCER_TREATMENT,
        DetailedQuestionNames.LUNG_CANCER_ACTIVE,
        DetailedQuestionNames.COLON_CANCER_YEAR,
        DetailedQuestionNames.COLON_CANCER_TREATMENT,
        DetailedQuestionNames.COLON_CANCER_ACTIVE,
        DetailedQuestionNames.SKIN_CANCER_YEAR,
        DetailedQuestionNames.SKIN_CANCER_TREATMENT,
        DetailedQuestionNames.SKIN_CANCER_ACTIVE,
        DetailedQuestionNames.BONE_CANCER_YEAR,
        DetailedQuestionNames.BONE_CANCER_TREATMENT,
        DetailedQuestionNames.BONE_CANCER_ACTIVE,
        DetailedQuestionNames.LIVER_CANCER_YEAR,
        DetailedQuestionNames.LIVER_CANCER_TREATMENT,
        DetailedQuestionNames.LIVER_CANCER_ACTIVE,
        DetailedQuestionNames.KIDNEY_CANCER_YEAR,
        DetailedQuestionNames.KIDNEY_CANCER_TREATMENT,
        DetailedQuestionNames.KIDNEY_CANCER_ACTIVE,
        DetailedQuestionNames.BAIN_CANCER_YEAR,
        DetailedQuestionNames.BAIN_CANCER_TREATMENT,
        DetailedQuestionNames.BAIN_CANCER_ACTIVE,
        DetailedQuestionNames.CANCER_ISSUES_COMMENT,
    ],
    [DetailedQuestionnaireSectionNames.SURGERY_ISSUES]: [
        DetailedQuestionNames.SURGERY_ISSUES,
        DetailedQuestionNames.SURGERY_ISSUES_VARIANTS,
        DetailedQuestionNames.APPENDECTOMY_SURGERY_YEAR,
        DetailedQuestionNames.CORONARY_ARTERY_BYPASS_SURGERY_YEAR,
        DetailedQuestionNames.CAROTID_ENDARTERECTOMY_SURGERY_YEAR,
        DetailedQuestionNames.CHOLECYSTECTOMY_SURGERY_YEAR,
        DetailedQuestionNames.BARIATRIC_SURGERY_YEAR,
        DetailedQuestionNames.HYSTERECTOMY_SURGERY_YEAR,
        DetailedQuestionNames.NEPHRECTOMY_SURGERY_YEAR,
        DetailedQuestionNames.SPLENECTOMY_SURGERY_YEAR,
        DetailedQuestionNames.TONSILLECTOMY_SURGERY_YEAR
    ],
    [DetailedQuestionnaireSectionNames.DRINK_ISSUES]: [
        DetailedQuestionNames.DRINK_ISSUES,
        DetailedQuestionNames.AVERAGE_DRINK_ISSUES,
        DetailedQuestionNames.REDUCE_DRINK_ISSUES,
        DetailedQuestionNames.CRITICIZING_ISSUES,
        DetailedQuestionNames.FELT_GUILTY_ISSUES,
        DetailedQuestionNames.EYE_OPENER_ISSUES
    ],
    [DetailedQuestionnaireSectionNames.DRUGS_ISSUES]: [
        DetailedQuestionNames.CONSUME_ALCOHOL,
        DetailedQuestionNames.CONSUME_CIGARETTES,
        DetailedQuestionNames.SMOKE_MARIJUANA,
        DetailedQuestionNames.DRUGS_ISSUES,
        DetailedQuestionNames.DRUGS_ISSUES_COMMENT,
    ],
    [DetailedQuestionnaireSectionNames.RELATIONSHIP_STATUS]: [
        DetailedQuestionNames.RELATIONSHIP_STATUS
    ],
    [DetailedQuestionnaireSectionNames.HAVE_CHILDREN]: [
        DetailedQuestionNames.HAVE_CHILDREN,
        DetailedQuestionNames.CHILDREN_NAME,
        DetailedQuestionNames.CHILDREN_AGE
    ],
    [DetailedQuestionnaireSectionNames.LIVE_ALONE]: [
        DetailedQuestionNames.LIVE_ALONE
    ],
    [DetailedQuestionnaireSectionNames.SEXUALLY_ACTIVE]: [
        DetailedQuestionNames.SEXUALLY_ACTIVE,
        DetailedQuestionNames.CONTRACEPTION_PROTECTION
    ],
    [DetailedQuestionnaireSectionNames.MENSTRUAL_CYCLE]: [
        DetailedQuestionNames.MENSTRUAL_CYCLE,
        DetailedQuestionNames.MONTHLY_PERIOD,
        DetailedQuestionNames.MENOPAUSE,
        DetailedQuestionNames.MENOPAUSE_AGE
    ],
    [DetailedQuestionnaireSectionNames.PREGNANT]: [
        DetailedQuestionNames.PREGNANT,
        DetailedQuestionNames.TOTAL_PREGNANCIES,
        DetailedQuestionNames.FULL_TERM_BIRTHS,
        DetailedQuestionNames.PREMATURE,
        DetailedQuestionNames.MISCARRIAGES,
        DetailedQuestionNames.ABORTIONS,
        DetailedQuestionNames.PREGNANT_COMMENT,
    ],
    [DetailedQuestionnaireSectionNames.PREGNANCY_COMPLICATIONS]: [
        DetailedQuestionNames.PREGNANCY_COMPLICATIONS,
        DetailedQuestionNames.PREGNANCY_COMPLICATIONS_VARIANTS
    ],
    [DetailedQuestionnaireSectionNames.BIOLOGICAL_MOTHER]: [
        DetailedQuestionNames.BIOLOGICAL_MOTHER_LIVING,
        DetailedQuestionNames.BIOLOGICAL_MOTHER_MEDICAL_PROBLEMS,
        DetailedQuestionNames.BIOLOGICAL_FATHER_LIVING,
        DetailedQuestionNames.BIOLOGICAL_FATHER_MEDICAL_PROBLEMS,
        DetailedQuestionNames.BIOLOGICAL_SIBLING,
        DetailedQuestionNames.BIOLOGICAL_SIBLING_LIVING,
        DetailedQuestionNames.BIOLOGICAL_SIBLING_MEDICAL_PROBLEMS
    ],
    [DetailedQuestionnaireSectionNames.FAMILY_GENE_MUTATIONS]: [
        DetailedQuestionNames.FAMILY_GENE_MUTATIONS,
        DetailedQuestionNames.MUTATIONS_DIAGNOSED,
        DetailedQuestionNames.CANCER_DIAGNOSED,
        DetailedQuestionNames.BRCA_FAMILY_GENE_MUTATIONS
    ],
    [DetailedQuestionnaireSectionNames.MAMMOGRAM]: [
        DetailedQuestionNames.MAMMOGRAM,
        DetailedQuestionNames.DATE_OF_LAST_MAMMOGRAM,
        DetailedQuestionNames.MAMMOGRAM_ABNORMALITIES
    ],
    [DetailedQuestionnaireSectionNames.PAP_SMEAR]: [
        DetailedQuestionNames.PAP_SMEAR,
        DetailedQuestionNames.DATE_OF_LAST_PAP_SMEAR,
        DetailedQuestionNames.PAP_SMEAR_ABNORMALITIES
    ],
    [DetailedQuestionnaireSectionNames.COLONOSCOPY]: [
        DetailedQuestionNames.COLONOSCOPY,
        DetailedQuestionNames.DATE_OF_LAST_SCREENING,
        DetailedQuestionNames.COLONOSCOPY_ABNORMALITIES
    ],
    [DetailedQuestionnaireSectionNames.PSA_OR_PROSTATE]: [
        DetailedQuestionNames.PSA_OR_PROSTATE,
        DetailedQuestionNames.DATE_OF_PSA_OR_PROSTATE_EXAM,
        DetailedQuestionNames.PSA_ABNORMALITIES
    ],
    [DetailedQuestionnaireSectionNames.LUNG_CANCER_SCREENINGS]: [
        DetailedQuestionNames.LUNG_CANCER_SCREENINGS,
        DetailedQuestionNames.DATE_OF_LUNG_CANCER
    ],
    [DetailedQuestionnaireSectionNames.MORE_65_OSTEOPOROSIS]: [
        DetailedQuestionNames.MORE_65_OSTEOPOROSIS,
        DetailedQuestionNames.MORE_65_ABNORMALITIES
    ],
    [DetailedQuestionnaireSectionNames.PHQ2]: [
        DetailedQuestionNames.PHQ2_1,
        DetailedQuestionNames.PHQ2_2
    ],
    [DetailedQuestionnaireSectionNames.PHQ8]: [
        DetailedQuestionNames.PHQ8_1
    ],
    [DetailedQuestionnaireSectionNames.TETANUS_VACCINE]: [
        DetailedQuestionNames.TETANUS_VACCINE,
        DetailedQuestionNames.DATE_OF_LAST_TETANUS_VACCINE,
        DetailedQuestionNames.PNEUMONIA_VACCINE,
        DetailedQuestionNames.DATE_OF_LAST_PNEUMONIA_VACCINE,
        DetailedQuestionNames.FLU_VACCINE_SEASON,
        DetailedQuestionNames.DATE_OF_LAST_FLU_VACCINE,
        DetailedQuestionNames.COVID_VACCINE,
        DetailedQuestionNames.DATE_OF_LAST_COVID_VACCINE
    ],
    [DetailedQuestionnaireSectionNames.HEALTH_WELLNESS_REVIEW]: [
        DetailedQuestionNames.HEALTH_WELLNESS_REVIEW
    ],
}

export const groupAnswerList = [
    DetailedQuestionNames.SURGERY_ISSUES_VARIANTS,
    DetailedQuestionNames.CANCER_ISSUES_VARIANTS,
    DetailedQuestionNames.CARDIOVASCULAR_VARIANTS,
    DetailedQuestionNames.DIAGNOSED_EATING_DISORDER_ISSUES_VARIANTS,
]

export const subQuestionList = [
    DetailedQuestionNames.HIGH_BLOOD_PRESSURE_YEAR,
    DetailedQuestionNames.HIGH_BLOOD_PRESSURE_TREATMENT,
    DetailedQuestionNames.HIGH_BLOOD_PRESSURE_ACTIVE,
    DetailedQuestionNames.CORONARY_ARTERY_DISEASE_YEAR,
    DetailedQuestionNames.CORONARY_ARTERY_DISEASE_TREATMENT,
    DetailedQuestionNames.CORONARY_ARTERY_DISEASE_ACTIVE,
    DetailedQuestionNames.HEART_ATTACK_YEAR,
    DetailedQuestionNames.HEART_ATTACK_TREATMENT,
    DetailedQuestionNames.HEART_ATTACK_ACTIVE,
    DetailedQuestionNames.CONGESTIVE_HEART_FAILURE_YEAR,
    DetailedQuestionNames.CONGESTIVE_HEART_FAILURE_TREATMENT,
    DetailedQuestionNames.CONGESTIVE_HEART_FAILURE_ACTIVE,
    DetailedQuestionNames.HIGH_CHOLESTEROL_YEAR,
    DetailedQuestionNames.HIGH_CHOLESTEROL_TREATMENT,
    DetailedQuestionNames.HIGH_CHOLESTEROL_ACTIVE,
    DetailedQuestionNames.BLOOD_CLOTTING_DISORDER_YEAR,
    DetailedQuestionNames.BLOOD_CLOTTING_DISORDER_TREATMENT,
    DetailedQuestionNames.BLOOD_CLOTTING_DISORDER_ACTIVE,
    DetailedQuestionNames.ANEURYSM_YEAR,
    DetailedQuestionNames.ANEURYSM_TREATMENT,
    DetailedQuestionNames.ANEURYSM_ACTIVE,
    DetailedQuestionNames.CARDIAC_VALVE_PROBLEM_YEAR,
    DetailedQuestionNames.CARDIAC_VALVE_PROBLEM_TREATMENT,
    DetailedQuestionNames.CARDIAC_VALVE_PROBLEM_ACTIVE,
    DetailedQuestionNames.AFIB_YEAR,
    DetailedQuestionNames.AFIB_TREATMENT,
    DetailedQuestionNames.AFIB_ACTIVE,
    DetailedQuestionNames.APPENDECTOMY_SURGERY_YEAR,
    DetailedQuestionNames.CORONARY_ARTERY_BYPASS_SURGERY_YEAR,
    DetailedQuestionNames.CAROTID_ENDARTERECTOMY_SURGERY_YEAR,
    DetailedQuestionNames.CHOLECYSTECTOMY_SURGERY_YEAR,
    DetailedQuestionNames.BARIATRIC_SURGERY_YEAR,
    DetailedQuestionNames.HYSTERECTOMY_SURGERY_YEAR,
    DetailedQuestionNames.NEPHRECTOMY_SURGERY_YEAR,
    DetailedQuestionNames.SPLENECTOMY_SURGERY_YEAR,
    DetailedQuestionNames.TONSILLECTOMY_SURGERY_YEAR,
    DetailedQuestionNames.BREAST_CANCER_YEAR,
    DetailedQuestionNames.BREAST_CANCER_TREATMENT,
    DetailedQuestionNames.BREAST_CANCER_ACTIVE,
    DetailedQuestionNames.PROSTATE_CANCER_YEAR,
    DetailedQuestionNames.PROSTATE_CANCER_TREATMENT,
    DetailedQuestionNames.PROSTATE_CANCER_ACTIVE,
    DetailedQuestionNames.LUNG_CANCER_YEAR,
    DetailedQuestionNames.LUNG_CANCER_TREATMENT,
    DetailedQuestionNames.LUNG_CANCER_ACTIVE,
    DetailedQuestionNames.COLON_CANCER_YEAR,
    DetailedQuestionNames.COLON_CANCER_TREATMENT,
    DetailedQuestionNames.COLON_CANCER_ACTIVE,
    DetailedQuestionNames.BONE_CANCER_YEAR,
    DetailedQuestionNames.BONE_CANCER_TREATMENT,
    DetailedQuestionNames.BONE_CANCER_ACTIVE,
    DetailedQuestionNames.LIVER_CANCER_YEAR,
    DetailedQuestionNames.LIVER_CANCER_TREATMENT,
    DetailedQuestionNames.LIVER_CANCER_ACTIVE,
    DetailedQuestionNames.KIDNEY_CANCER_YEAR,
    DetailedQuestionNames.KIDNEY_CANCER_TREATMENT,
    DetailedQuestionNames.KIDNEY_CANCER_ACTIVE,
    DetailedQuestionNames.BAIN_CANCER_YEAR,
    DetailedQuestionNames.BAIN_CANCER_TREATMENT,
    DetailedQuestionNames.BAIN_CANCER_ACTIVE,
    DetailedQuestionNames.SKIN_CANCER_YEAR,
    DetailedQuestionNames.SKIN_CANCER_TREATMENT,
    DetailedQuestionNames.SKIN_CANCER_ACTIVE,
    DetailedQuestionNames.PULMONARY_EMBOLISM_YEAR ,
    DetailedQuestionNames.PULMONARY_EMBOLISM_TREATMENT ,
    DetailedQuestionNames.PULMONARY_EMBOLISM_ACTIVE ,
    DetailedQuestionNames.DEEP_VIEN_THROMBOSIS_YEAR ,
    DetailedQuestionNames.DEEP_VIEN_THROMBOSIS_TREATMENT ,
    DetailedQuestionNames.DEEP_VIEN_THROMBOSIS_ACTIVE ,
    DetailedQuestionNames.CAROTID_STENOSIS_YEAR ,
    DetailedQuestionNames.CAROTID_STENOSIS_TREATMENT ,
    DetailedQuestionNames.CAROTID_STENOSIS_ACTIVE ,
    DetailedQuestionNames.SPECIFY_SCREENING
]