import React from 'react';
import { Box, LinearProgress, withStyles } from "@material-ui/core";
import { WatchLater } from '@material-ui/icons';
import { colors } from '../../../common/constants/colors';
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from 'clsx';


export interface QuestionnaireProgressComponentProps {
    progress: number;
    timeLabel: string;
}

export const QuestionnaireProgressComponent: React.FC<QuestionnaireProgressComponentProps> = (props: QuestionnaireProgressComponentProps) => {
    const {progress, timeLabel} = props;

    const commonClasses = commonUseStyles();

    const QuestionnaireProgress = withStyles(() => ({
        colorPrimary: {
            backgroundColor: colors.gray3,
            borderRadius: 10,
            height: 11,
        },
        barColorPrimary: {
            backgroundColor: colors.main,
        }
    }))(LinearProgress);

    return (
        <Box>
            <Box mb={1} display='flex' justifyContent='space-between' alignItems='center'>
                <Box fontSize={18} fontWeight={500} color={colors.main}>{
                    Math.round(progress)}%
                </Box>
                <Box display='flex' alignItems='center' className={clsx(commonClasses.colorGray2)}>
                    <Box mt='6px'>
                        <WatchLater />
                    </Box>
                    <Box ml={1}>
                        <span className={clsx(commonClasses.size12, commonClasses.textBold)}>{timeLabel}</span>
                    </Box>
                </Box>
            </Box>
            <QuestionnaireProgress variant="determinate"  value={progress} />
        </Box>
    )
}