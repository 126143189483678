import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    container: {
        backgroundColor: "white",
        paddingTop: "50px",
        paddingLeft: "50px",
        paddingRight: "76px"
    },
    howDidYouHeardAboutUsSelect: {
        backgroundColor: '#ffffff',
        width: 345,
    },
    textSecondary: {
        color: '#7C8A9D'
    },
    backButton: {
        height: 48,
        width: 100,
        color: "black",
        backgroundColor: "white",
        textTransform: "none",
    }
});