import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { onEmit } from "../../../common/helpers/on-emit";
import { OrderStatus } from "../../models/orders.models";
import { otherOrderPdfService } from "../../services/orders-pdf.service";
import { ordersQuery } from "../../stores/ordersStore/orders.query";
import { otherOrdersService } from "../../services/otherOrders.service";
import { OtherOrderModel } from "../../models/otherOrders.models";

export enum OtherOrdersViewState {
    AllOrders = 0,
    NewOrder = 1,
    UpdateOrder = 2
}

interface OtherDiagnosticComponentState {
    isLoading: boolean;
    isProcessing: boolean;
    orders: OtherOrderModel[];
    state: OtherOrdersViewState;
    targetOrder: OtherOrderModel;
}

export function useFacade(patientId: number): [
    OtherDiagnosticComponentState,
    Array<string>,
    (orderId: number) => void,
    (order: OtherOrderModel) => void,
    (order: OtherOrderModel) => void,
    () => void,
    () => void
] {
    const [state, setState] = useState({
        isLoading: true,
        isProcessing: false,
        orders: [],
        state: OtherOrdersViewState.AllOrders,
        targetOrder: null
    } as OtherDiagnosticComponentState);

    const columns = [
        'Test Ordered',
        'Date Ordered',
        'Status',
        'Action',
    ];

    const handleDownloadOrder = (order: OtherOrderModel) => {
        otherOrderPdfService.download(order)
    }

    const handleDeleteOrder = (orderId: number) => {
        setState(state => ({
            ...state,
            isProcessing: true
        }));

        const cb = () => setState(state => ({ ...state, isProcessing: false }));

        otherOrdersService.delete(orderId).subscribe(cb, cb);
    }

    const handleOpenOrder = (order: OtherOrderModel) => {
        if (order.status !== OrderStatus.Signed ) {
            setState(state => ({
                ...state,
                targetOrder: order,
                state: OtherOrdersViewState.UpdateOrder
            }));
        } else {
            handleDownloadOrder(order);
        }
    }

    const handleNewOrder = () => {
        setState(state => ({
            ...state,
            targetOrder: null,
            state: OtherOrdersViewState.NewOrder
        }));
    }

    const handleGoBack = () => {
        setState(state => ({
            ...state,
            targetOrder: null,
            state: OtherOrdersViewState.AllOrders
        }));
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<OtherOrderModel[]>(ordersQuery.otherOrders$, orders => {
                setState(state => ({
                    ...state,
                    orders
                }));
            }),
        ];

        const cb = () => setState(state => ({ ...state, isLoading: false }));

        if (patientId) {
            otherOrdersService.get(patientId).subscribe(cb, cb);
        } else {
            otherOrdersService.getMyOrders().subscribe(cb, cb);
        }

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [patientId]);


    return [state, columns, handleDeleteOrder, handleDownloadOrder, handleOpenOrder, handleNewOrder, handleGoBack];
}