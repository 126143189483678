import React, { useEffect, useState } from 'react';
import { Box } from "@material-ui/core";
import { onEmit } from "../../../../common/helpers/on-emit";
import commonUseStyles from '../../../../common/styles/common.styles';
import WildHealthLinearProgress from "../../../../common/components/WildHealthLinearProgress";
import { PatientAllergyModel } from '../../../../patientSupplements/models/patientAllergiesModel';
import { patientAllergiesQuery } from '../../../../patientSupplements/stores/patientAllergyStore/patientAllergies.query';
import { patientAllergiesService } from '../../../../patientSupplements/services/patientAllergiesService';
import { Subscription } from 'rxjs';
import {useStyles} from '../AnswerComponent.styles'

export interface AllergiesAnswerComponentProps {
    patientId?: number | null;
    question: string;
    answer: string | null;
}

export interface AllergiesAnswerComponentState {
    allergies: PatientAllergyModel[],
    isLoading: boolean
}

export const AllergiesAnswerComponent: React.FC<AllergiesAnswerComponentProps> = (props: AllergiesAnswerComponentProps) => {
    const {
        patientId,
        question,
        answer
    } = props;

    const allergiesIds = answer.length ? answer.split(',').map(x => +x) : [];

    const [state, setState] = useState({
        allergies: [],
        isLoading: false
    } as AllergiesAnswerComponentState)

    const commonClasses = commonUseStyles();
    const classes = useStyles();

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<PatientAllergyModel[]>(patientAllergiesQuery.patientAllergies$, allergies => {
                setState(state => ({
                    ...state,
                    allergies: allergies.filter(x => allergiesIds.find(id => id === x.id) !== undefined)
                }));
            })
        ];


        if(allergiesIds.length) {
            setState(state => ({...state, isLoading: true}))

            if(patientId) {
                patientAllergiesService.getByPatientId(patientId).subscribe(() => {
                    setState(state => ({...state, isLoading: false}))
                })
            } else {
                patientAllergiesService.get().subscribe(() => {
                    setState(state => ({...state, isLoading: false}))
                })
            }
        }

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [])

    const renderAnswer = () => {
        if(!state.allergies.length) {
            return (
                <Box my={2}>
                    <span>(no answer)</span>
                </Box>
            )
        }

        return (
            <>
                <Box my={2}>
                    <ul itemType="disc">
                        {state.allergies.map((allergy, i) => (
                            <li className={classes.withDots} key={i}>
                                <Box my={1} display="flex">
                                    <span>{`${allergy.name}: ${allergy.reaction}`}</span>
                                </Box>
                            </li>
                        ))}
                    </ul>
                </Box>
            </>
        )
    }

    const renderContent = (): JSX.Element => {
        if(state.isLoading) {
            return (<WildHealthLinearProgress />)
        }

        return (
            <>
                <Box className={commonClasses.colorGray1}>
                    <span>{question}</span>
                </Box>
                {
                    renderAnswer()
                }
            </>
        )
    }

    return (
        <>
            {
                renderContent()
            }
        </>
    )
}