import Axios from 'axios-observable';
import { snackService } from '../../common/snack/state';
import { addOnsStore } from '../stores';
import {Gender} from "../../common/models/user.models";
import { Observable, Subject } from "rxjs";
import {AddOnModel, AddOnProvider, CreateAddOnModel, UpdateAddOnModel} from '../models/addOns.models';
import {PaginationModel} from "../../common/pagination/models/pagination-models";
import { authHeader } from "../../common/helpers/auth-header";
import { authQuery } from '../../auth/stores/auth';
import { CallbackModel } from "../../common/models/callback.model";
import {OrderType} from "../../orders/models/orders.models";

/**
 * Provides method for working with AddOns and identity
 */
export class AddOnsService {
    private url = `${process.env.REACT_APP_API_URL}AddOns`;
    constructor(private AddOnsStore) {}

    public onUpload = new Subject<CallbackModel<[AddOnProvider]>>();

    public getOptional(paymentPlanId : number, gender: Gender, employerKey: string | null = null) {
        if (gender === 0) {
            gender = Gender.None;
        }

        Axios.get(`${this.url}/optional/?paymentPlanId=${paymentPlanId}&gender=${gender}&employerKey=${employerKey ?? ''}`).pipe()
            .subscribe(
                response => this.AddOnsStore.update({addOns: response.data}),
                error => snackService.commonErrorHandler(error)
            );
    }

    public getOrdering(practiceId : number, gender: Gender, grouping: boolean = false, employerKey: string | null = null): Observable<AddOnModel[]> {
        return new Observable<AddOnModel[]>(observer => {
            Axios.get<AddOnModel[]>(`${this.url}/Ordering/?practiceId=${practiceId}&gender=${gender}&employerKey=${employerKey ?? ''}&grouping=${grouping}`)
                .pipe()
                .subscribe(
                    (response) => {
                        this.AddOnsStore.update({ addOns: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    },
                );
        });
    }

    public getEpigeneticAddOns(practiceId : number, employerKey: string | null = null): Observable<AddOnModel[]> {
        return new Observable<AddOnModel[]>(observer => {
            Axios.get<AddOnModel[]>(`${this.url}/Ordering/?practiceId=${practiceId}&gender=${Gender.None}&orderType=${OrderType.Epigenetic}&employerKey=${employerKey ?? ''}&grouping=${true}`)
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    },
                );
        });
    }

    public selectOrdering(
        practiceId : number,
        gender: Gender,
        provider: AddOnProvider,
        skip: number | null,
        take: number | null,
        searchQuery: string | null,
        employerKey: string | null = null): Observable<PaginationModel<AddOnModel>> {

        let query = `${this.url}/Select/?practiceId=${practiceId}&gender=${gender}&provider=${provider}&employerKey=${employerKey ?? ''}`;

        if (skip) {
            query += `&skip=${skip}`;
        }

        if (take) {
            query += `&take=${take}`;
        }

        if (searchQuery) {
            query += `&searchQuery=${searchQuery}`;
        }

        return new Observable<PaginationModel<AddOnModel>>(observer => {
            Axios.get<PaginationModel<AddOnModel>>(query)
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    },
                );
        });
    }

    public createAddOn(model: CreateAddOnModel) {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.post(`${this.url}?practiceId=${authQuery.getCurrentPracticeId()}`, model, config)
                .pipe()
                .subscribe(
                    response => {
                        snackService.success('Lab Pricing successfully added');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    public updateAddOn(model: UpdateAddOnModel) {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.put(`${this.url}`, model, config)
                .pipe()
                .subscribe(
                    response => {
                        snackService.success('Lab Pricing successfully updated');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    public deleteAddOn(id: number) {
        const config = {
            headers: authHeader()
        };

        return Axios.delete(`${this.url}/${id}`, config)
            .pipe()
            .subscribe(
                () => {
                    snackService.success('Lab Pricing successfully deleted.');
                },
                error => snackService.commonErrorHandler(error)
            );
    }

    public upload(labProvider: AddOnProvider): Observable<any> {
        return new Observable(observer => {
            this.onUpload.next({
                data: [labProvider],
                callback: (files: any) => {
                    if (!files) {
                        observer.error();
                        observer.complete();
                        return;
                    }

                    const formData = new FormData();
                    formData.append('provider', labProvider.toString());
                    files.forEach((item) => {
                        formData.append('file', item.file);
                    });

                    Axios.post(`${this.url}/UpdateFromFile`, formData, { headers: authHeader() })
                        .pipe()
                        .subscribe(
                            (response) => {
                                snackService.success(`Lab Pricing successfully updated by Excel file!`);
                                observer.next(response.data);
                                observer.complete();
                            },
                            error => {
                                snackService.commonErrorHandler(error);
                                observer.error(error);
                                observer.complete();
                            }
                        );
                }
            });
        });
    }
}

export const addOnsService = new AddOnsService(addOnsStore);
