import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { confirmService } from "../../../../services/confirm.service";
import { onEmit } from "../../../common/helpers/on-emit";
import { DocumentSourceModel } from "../../models/documentSource.model";
import { documentsQuery } from "../../../inputs/stores/documentsStore";
import { kbDocumentsService } from "../../services/kbDocuments.service";

interface DocumentSourcesComponentState {
    isLoading: boolean;
    isTypeLoading: boolean;
    isDeleting: boolean;
    isUploading: boolean;
    isUpdating: boolean;
    isActionsOpen: boolean;
    actionsAnchorEl: HTMLButtonElement;
    selectedSource: DocumentSourceModel;
    documentSources: DocumentSourceModel[];
}

export function useFacade(): [
    DocumentSourcesComponentState,
    (fileId?: number, target?: HTMLButtonElement) => void,
    () => void,
    (fileId: number) => void,
    () => void
] {
    const [state, setState] = useState({
        isLoading: true,
        isTypeLoading: true,
        isDeleting: false,
        isUploading: false,
        isUpdating: false,
        isActionsOpen: false,
        actionsAnchorEl: null,
        selectedSource: null,
        documentSources: []
    } as DocumentSourcesComponentState);

    const handleToggleActions = (sourceId?: number, target?: HTMLButtonElement) => {
        setState(state => ({
            ...state,
            actionsAnchorEl: target,
            isActionsOpen: !state.isActionsOpen,
            selectedSource: state.documentSources.find(i => i.id === sourceId),
        }));
    }

    const handleUpload = () => {
        setState(state => ({
            ...state,
            isUploading: true
        }));

        const cb = () => {
            setState(state => ({
                ...state,
                isUploading: false
            }));
        }

        kbDocumentsService.uploadKbDocumentSource().subscribe(() => {
            cb()
            kbDocumentsService.getKbDocuments().subscribe()
        }, cb);
    }

    const handleEdit = () => {
        setState(state => ({
            ...state,
            isUpdating: true,
            isActionsOpen: false,
        }));

        const cb = () => {
            setState(state => ({
                ...state,
                isUpdating: false,
            }));
        }
        kbDocumentsService.updateKbDocumentSource(state.selectedSource).subscribe(cb, cb);
    }

    const handleDelete = () => {
        setState(state => ({
            ...state,
            isActionsOpen: false,
        }));
        confirmService.confirm(
            'Are you sure you want to delete this Source?',
            '',
            'Yes',
            'Cancel',
            'danger'
            ).subscribe(() => {
            setState(state => ({
                ...state,
                isDeleting: true,
            }));
    
            const cb = () => {
                setState(state => ({
                    ...state,
                    isDeleting: false,
                }));
            }
            kbDocumentsService.deleteKbDocument(state.selectedSource.id).subscribe(cb, cb);
        })
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<DocumentSourceModel[]>(documentsQuery.kbDocuments$, documentSources => {
                setState(state => ({
                    ...state,
                    documentSources,
                }));
            }),
        ];
        const cb = () => setState(state => ({...state, isLoading: false}))
        const getTypesCB = () => setState(state => ({...state, isTypeLoading: false}))

        kbDocumentsService.getKbDocuments().subscribe(cb, cb)
        kbDocumentsService.getDocumentSourceTypes().subscribe(getTypesCB, getTypesCB)

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [
        state,
        handleToggleActions,
        handleUpload,
        handleEdit,
        handleDelete
    ];
}