import React from "react";
import {CoverageModel, CoveragePriority} from "../../models/insurance.models";
import {Box, Card, CardContent, IconButton} from "@material-ui/core";
import {useStyles} from "./insuranceListItemComponent.styles";
import {CoverageStatus} from "../../enums/coverageStatus";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import CommentIcon from '@material-ui/icons/Comment';
import {AttachmentModel} from "../../../common/models/attachment.models";
import {useFacade} from "./insuranceListItem.hooks";
import EditIcon from "@material-ui/icons/Edit";
import { ProtectedElement } from "../../../common/components/ProtectedElement";
import { UserType } from "../../../auth/models/auth.enums";

interface InsuranceListItemComponentProps {
    coverage: CoverageModel;
    handleEdit: Function;
    handleActivationSwitch: Function;
    attachments: AttachmentModel[];
    handleOpen: Function;
}

export const InsuranceListItemComponent: React.FC<InsuranceListItemComponentProps> = (props: InsuranceListItemComponentProps) => {
    const {
        coverage,
        handleEdit,
        handleOpen,
        handleActivationSwitch,
        attachments
    } = props;

    const [
        {
            isLoading,
            imageUrl
        }
    ] = useFacade(attachments);

    const classes = useStyles();

    const renderPriority = (priority: CoveragePriority) => {
        if (priority === CoveragePriority.Primary)
        {
            return <Box className={classes.primary}>
                PRIMARY
            </Box>
        }

        return <Box className={classes.secondary}>
            SECONDARY
        </Box>
    }

    return (
        <>
            <Card>
                <CardContent>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box>
                            {
                                renderPriority(coverage.priority)
                            }
                        </Box>
                        <Box display="flex">
                            <ProtectedElement
                                element={
                                    <Box mr={1}>
                                        <IconButton size="small" onClick={() => handleOpen(coverage)}>
                                            <CommentIcon className="wh-tw-text-primaryV" />
                                        </IconButton>
                                    </Box>
                                }
                                permissions={[]}
                                userType={UserType.Employee}
                            />
                            <Box>
                                <IconButton size="small" onClick={() => handleActivationSwitch(coverage)}>
                                {
                                    coverage.status === CoverageStatus.Active
                                        ? <VisibilityOffOutlinedIcon className="wh-tw-text-primaryV"/>
                                        : <VisibilityOutlinedIcon className="wh-tw-text-primaryV"/>
                                }
                                </IconButton>
                            </Box>
                            {
                                coverage.status === CoverageStatus.Active &&
                                <Box ml={1}>
                                    <IconButton size="small" onClick={() => handleEdit(coverage)}>
                                        <EditIcon className="wh-tw-text-primaryV"/>
                                    </IconButton>
                                </Box>
                            }
                        </Box>
                    </Box>
                    <Box mt={2}>
                        <span className="wh-tw-text-base wh-tw-font-medium">{coverage.insurance.name}</span>
                    </Box>
                    <Box mt={2}>
                        {
                            isLoading || !imageUrl
                                ? <Box className={classes.image}/>
                                : <img className={classes.image} src={imageUrl} alt="coverage"/>

                        }
                    </Box>
                </CardContent>
            </Card>
        </>
    )
}