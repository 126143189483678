import { useEffect, useState } from "react";
import moment from "moment";
import { handleCompare } from "../../../common/sorting/helpers/handle-compare";
import { SortingDirection } from "../../../common/sorting/models/sorting-destination";
import { ISortingState } from "../../../common/sorting/models/sorting-state";
import { MyPatientModel, MyPatientsFilterModel } from "../../../patients/models/patient.model";
import { patientsService } from "../../../patients/services/patients.service";
import { authQuery } from "../../../auth/stores/auth";
import { pageSizes } from "../../../common/pagination/models/page-sizes";
import { IPaginationState } from "../../../common/pagination/models/pagination-state";
import { getAvailablePages } from "../../../common/pagination/helpers/get-evailable-pages";
import { PlatformName, Track } from "../../../tracking/tracker";

export enum SortingSource {
    None,
    patientName = "firstName",
    Name = "name",
    StartDate = "startDate",
    RenewalDate = "renewalDate",
    SendDate = "sentDate"
}

export enum SortingObject {
    None,
    FirstName = "firstName",
    StartDate = "startDate",
    ActivePlan = "activePlan",
    LastMessage = "lastMessage",
    LastUnansweredMessage = "lastMessage",
    LastAppointment = "lastAppointment",
    NextAppointment = "nextAppointment",
}

interface FilterOptionsViewModel {
    optionId: number;
    title: string;
    status: string | number;
    description?: string;
    value: number;
    valueString: string;
}

interface PatientFilterStatusViewModel {
    id: string;
    objectName: string;
    title: string;
    description?: string;
    options: FilterOptionsViewModel[];
}

interface MyPatientsFiltersState {
    isOpen: boolean;
    anchorEl: HTMLButtonElement;
    filters: PatientFilterStatusViewModel[];
}

interface ManageMyPatientsState extends IPaginationState, ISortingState {
    isLoading: boolean;
    patients: MyPatientModel[],
    originalPatients: MyPatientModel[],
    filtersState: MyPatientsFiltersState;
    filteredTitle: string;
    activeSource: string;
    page: number;
    rowsPerPage: number;
    options: FilterOptionsViewModel[];
    selectedFilterId: string;
    selectedOptionId: number;
    selectedFilter: PatientFilterStatusViewModel;
    selectedOption: FilterOptionsViewModel;
}

export function useFacade(): [
    ManageMyPatientsState,
    (event?: React.MouseEvent<HTMLButtonElement>) => void,
    () => void,
    (query: string) => void,
    (active: string, source: string, direction: SortingDirection, object?: string | null) => void,
    () => number[],
    (value: any) => void,
    (page: number) => void,
    (filterId: string) => void,
    (optionId: number) => void,
] {

    const [state, setState] = useState({
        isLoading: true,
        originalPatients: [],
        patients: [],
        filtersState: {
            isOpen: false,
            anchorEl: null,
            filters: [
                {
                    id: "lastMessage_filter",
                    objectName: "lastMessage",
                    title: "Last Message Sent",
                    description: "since last message sent",
                    options: [
                        {
                            optionId: 1,
                            title: "> 10 days ago",
                            description: "> 10 days",
                            status: moment(new Date()).add(-10, 'day').format('MM/DD/YYYY'),
                            value: 10
                        },
                        {
                            optionId: 2,
                            title: "> 20 days ago",
                            description: "> 20 days",
                            status: moment(new Date()).add(-20, 'day').format('MM/DD/YYYY'),
                            value: 20
                        },
                        {
                            optionId: 3,
                            title: "> 30 days ago",
                            description: "> 30 days",
                            status: moment(new Date()).add(-30, 'day').format('MM/DD/YYYY'),
                            value: 30
                        },
                        {
                            optionId: 4,
                            title: "> 60 days ago",
                            description: "> 60 days",
                            status: moment(new Date()).add(-60, 'day').format('MM/DD/YYYY'),
                            value: 60
                        }
                    ]
                },
                {
                    id: "lastUnansweredMessage_filter",
                    objectName: "lastMessage",
                    title: "Last unanswered Message",
                    description: "since last message sent",
                    options: [
                        {
                            optionId: 1,
                            title: "> 10 days ago",
                            description: "> 10 days",
                            status: moment(new Date()).add(-10, 'day').format('MM/DD/YYYY'),
                            value: 10
                        },
                        {
                            optionId: 2,
                            title: "> 20 days ago",
                            description: "> 20 days",
                            status: moment(new Date()).add(-20, 'day').format('MM/DD/YYYY'),
                            value: 20
                        },
                        {
                            optionId: 3,
                            title: "> 30 days ago",
                            description: "> 30 days",
                            status: moment(new Date()).add(-30, 'day').format('MM/DD/YYYY'),
                            value: 30
                        },
                        {
                            optionId: 4,
                            title: "> 60 days ago",
                            description: "> 60 days",
                            status: moment(new Date()).add(-60, 'day').format('MM/DD/YYYY'),
                            value: 60
                        }
                    ]
                },
                {
                    id: "lastCoaching_filter",
                    objectName: "lastAppointmentCompletedHealthCoachDate",
                    title: "Last Coaching Visit",
                    description: "since last Coaching Visit (of any kind)",
                    options: [
                        {
                            optionId: 1,
                            title: "> 10 days ago",
                            description: "> 10 days",
                            status: moment(new Date()).add(-10, 'day').format('MM/DD/YYYY'),
                            value: 10
                        },
                        {
                            optionId: 2,
                            title: "> 20 days ago",
                            description: "> 20 days",
                            status: moment(new Date()).add(-20, 'day').format('MM/DD/YYYY'),
                            value: 20
                        },
                        {
                            optionId: 3,
                            title: "> 30 days ago",
                            description: "> 30 days",
                            status: moment(new Date()).add(-30, 'day').format('MM/DD/YYYY'),
                            value: 30
                        },
                        {
                            optionId: 4,
                            title: "> 60 days ago",
                            description: "> 60 days",
                            status: moment(new Date()).add(-60, 'day').format('MM/DD/YYYY'),
                            value: 60
                        }
                    ]
                },
                {
                    id: "lastAppointment_filter",
                    objectName: "lastAppointment",
                    title: "Last Appointment (any type)",
                    description: "since last appointment (of any type)",
                    options: [
                        {
                            optionId: 1,
                            title: "> 10 days ago",
                            description: "> 10 days",
                            status: moment(new Date()).add(-10, 'day').format('MM/DD/YYYY'),
                            value: 10
                        },
                        {
                            optionId: 2,
                            title: "> 20 days ago",
                            description: "> 20 days",
                            status: moment(new Date()).add(-20, 'day').format('MM/DD/YYYY'),
                            value: 20
                        },
                        {
                            optionId: 3,
                            title: "> 30 days ago",
                            description: "> 30 days",
                            status: moment(new Date()).add(-30, 'day').format('MM/DD/YYYY'),
                            value: 30
                        },
                        {
                            optionId: 4,
                            title: "> 60 days ago",
                            description: "> 60 days",
                            status: moment(new Date()).add(-60, 'day').format('MM/DD/YYYY'),
                            value: 60
                        }
                    ]
                },
                {
                    id: "hasFUMDScheduled_filter",
                    objectName: "hasFollowUpMedicalScheduled",
                    title: "FUMD visit scheduled",
                    description: "",
                    options: [
                        {
                            optionId: 1,
                            title: "Has a FUMD visit scheduled",
                            description: "Any patients with a FUMD visit scheduled",
                            status: 1,
                            value: 1
                        },
                    ]
                },
                {
                    id: "renewal_filter",
                    objectName: "activePlan",
                    title: "Plan Renewal Date",
                    description: "until patients plan is due a renewal",
                    options: [
                        {
                            optionId: 1,
                            title: "< 30 days from today",
                            description: "< 30 days",
                            status: moment(new Date()).add(30, 'day').format('MM/DD/YYYY'),
                            value: 30
                        },
                        {
                            optionId: 2,
                            title: "< 60 days from today",
                            description: "< 60 days",
                            status: moment(new Date()).add(60, 'day').format('MM/DD/YYYY'),
                            value: 60
                        }
                    ]
                },
                {
                    id: "noImcScheduled_filter",
                    objectName: "daysSinceHealthCoachAndNoImcScheduled",
                    title: "Days since ICC without IMC Scheduled",
                    description: "since ICC without an IMC scheduled",
                    options: [
                        {
                            optionId: 1,
                            title: "> 30 days",
                            description: "> 30 days",
                            status: 30,
                            value: 30
                        },
                        {
                            optionId: 2,
                            title: "> 45 days",
                            description: "> 45 days",
                            status: 45,
                            value: 45
                        },
                        {
                            optionId: 3,
                            title: "> 60 days",
                            description: "> 60 days",
                            status: 60,
                            value: 60
                        }
                    ]
                },
                {
                    id: "noIccScheduled_filter",
                    objectName: "daysSinceSignUpAndNoIccScheduled",
                    title: "Days since Sign Up without ICC scheduled",
                    description: "since signup without an ICC schedule",
                    options: [
                        {
                            optionId: 1,
                            title: "> 5 days",
                            description: "> 5 days",
                            status: 5,
                            value: 5
                        },
                        {
                            optionId: 2,
                            title: "> 10 days",
                            description: "> 10 days",
                            status: 10,
                            value: 10
                        },
                        {
                            optionId: 3,
                            title: "> 20 days",
                            description: "> 20 days",
                            status: 20,
                            value: 20
                        },
                        {
                            optionId: 4,
                            title: "> 30 days",
                            description: "> 30 days",
                            status: 30,
                            value: 30
                        }
                    ]
                },
                {
                    id: "tags_filter",
                    objectName: "tags",
                    title: "Tags",
                    description: "tag",
                    options: []
                },
                {
                    id: "planName_filter",
                    objectName: "activePlan",
                    title: "Plans",
                    description: "Plan",
                    options: []
                }
            ]
        },
        filteredTitle: '',
        sortingColumns: [
            {
                title: 'Patient Info',
                source: SortingSource.patientName,
                direction: SortingDirection.Asc,
                object: SortingObject.FirstName,
            },
            {
                title: 'Start Date',
                source: SortingSource.StartDate,
                direction: SortingDirection.Desc,
                object: SortingObject.StartDate,
            },
            {
                title: 'Plan Name',
                source: SortingSource.Name,
                direction: SortingDirection.Asc,
                object: SortingObject.ActivePlan
            },
            {
                title: 'Last message',
                source: SortingSource.SendDate,
                direction: SortingDirection.Desc,
                object: SortingObject.LastMessage
            },
            {
                title: 'Last visit',
                source: SortingSource.StartDate,
                direction: SortingDirection.Desc,
                object: SortingObject.LastAppointment
            },
            {
                title: 'Upcoming visit',
                source: SortingSource.StartDate,
                direction: SortingDirection.Desc,
                object: SortingObject.NextAppointment
            }
        ],
        sortingSource: SortingSource.None,
        activeSource: SortingSource.None,
        page: 0,
        rowsPerPage: pageSizes[0],
        totalCount: 0,
        selectedPage: 1,
        pageSize: pageSizes[0],
        options: [],
        selectedFilterId: null,
        selectedOptionId: null,
        selectedFilter: null,
        selectedOption: null
    } as ManageMyPatientsState);

    const setPatients = (patients: MyPatientModel[]) => {
        const tempPatients = state.selectedFilter?.id === "lastUnansweredMessage_filter" ? patients.filter(patient => patient.lastMessage.sentByRole === "From Patient") : patients;
        setState((state) => {
            return {
                ...state,
                isLoading: false,
                patients: tempPatients,
                totalCount: tempPatients.length,
            }
        });
    }

    const handleToggleFilters = (event?: React.MouseEvent<HTMLButtonElement>) => {
        const anchorEl = event && event.currentTarget ? event.currentTarget : null;
        state.filtersState.isOpen = !state.filtersState.isOpen;
        state.filtersState.anchorEl = anchorEl;
        setState(state => ({ ...state }));
    }

    const updateMyPatientsFromFilter = () => {
        const cb = () => setState(state => ({ ...state, isLoading: false }));

        patientsService.getMyPatients(getCurrentFilterModel()).subscribe((patients) => setPatients(patients), cb);
    }

    const getCurrentFilterModel = () => {
        const filter: MyPatientsFilterModel = {
            employeeId: authQuery.getEmployeeId(),
            lastAppointmentGreaterThanDaysAgo: null,
            lastCoachingVisitGreaterThanDaysAgo: null,
            lastMessageSentGreaterThanDaysAgo: null,
            daysSinceIccWithoutImcScheduledFromToday: null,
            daysSinceSignUpWithoutIccScheduledFromToday: null,
            planRenewalDateLessThanDaysFromToday: null,
            includesTags: [],
            planName: null,
            hasFollowUpMedicalScheduled: null
        };

        if (state.selectedFilter?.id === "lastMessage_filter" || state.selectedFilter?.id === "lastUnansweredMessage_filter") {
            filter.lastMessageSentGreaterThanDaysAgo = state.selectedOption.value;
        }

        if (state.selectedFilter?.id === "lastAppointment_filter") {
            filter.lastAppointmentGreaterThanDaysAgo = state.selectedOption.value;
        }

        if (state.selectedFilter?.id === "renewal_filter") {
            filter.planRenewalDateLessThanDaysFromToday = state.selectedOption.value;
        }

        if (state.selectedFilter?.id === "tags_filter") {
            filter.includesTags = [state.selectedOption.valueString];
        }

        if (state.selectedFilter?.id === "lastCoaching_filter") {
            filter.lastCoachingVisitGreaterThanDaysAgo = state.selectedOption.value;
        }

        if (state.selectedFilter?.id === "noImcScheduled_filter") {
            filter.daysSinceIccWithoutImcScheduledFromToday = state.selectedOption.value;
        }

        if (state.selectedFilter?.id === "noIccScheduled_filter") {
            filter.daysSinceSignUpWithoutIccScheduledFromToday = state.selectedOption.value;
        }

        if (state.selectedFilter?.id === "hasFUMDScheduled_filter") {
            filter.hasFollowUpMedicalScheduled = !!state.selectedOption.value;
        }

        if (state.selectedFilter?.id === "planName_filter") {
            filter.planName = state.selectedOption.valueString;
        }

        return filter;
    };

    const handleFilterSelect = (filterId: string) => {
        const selectedFilter = state.filtersState.filters.find(filter => filter.id === filterId);
        if (selectedFilter) {
            setState({
                ...state,
                selectedFilterId: filterId,
                selectedFilter: selectedFilter,
                options: selectedFilter.options,
                selectedOptionId: null,
                selectedOption: null,
            })
        }
    }

    const handleApplyFilters = () => {
        setState(state => ({
            ...state,
            isLoading: true
        }));

        updateMyPatientsFromFilter();
        handleToggleFilters();
    }

    const handleOptionSelect = (optionId: number) => {
        const selectedOption = state.options.find(o => o.optionId === optionId);
        if (selectedOption) {
            state.filteredTitle = [selectedOption.description, state.selectedFilter.description].join(" ").trim();
            setState({
                ...state,
                selectedPage: 1,
                selectedOptionId: optionId,
                selectedOption: selectedOption,
            })
        }
    }

    const handleClearFilters = () => {
        setState(state => ({
            ...state,
            filteredTitle: '',
            selectedPage: 1,
            selectedFilter: null,
            selectedFilterId: null,
            selectedOption: null,
            selectedOptionId: null,
        }));
        setPatients(state.originalPatients)
    }

    const handleSearchSubmit = (query: string) => {
        const searchQuery = query ?? '';
        handleClearFilters();
        setState(state => ({
            ...state,
            isLoading: true
        }));

        const trimedSearch = decodeURIComponent(searchQuery).trim().toLowerCase();
        const arrayTrimSearchKey = trimedSearch.split(' ');

        const filteredPatients = state.originalPatients?.filter(
            (p) =>
                arrayTrimSearchKey.find((item) =>
                    p.firstName?.toLowerCase().includes(item)
                ) !== undefined ||
                arrayTrimSearchKey.find((item) =>
                    p.lastName?.toLowerCase().includes(item)
                ) !== undefined ||
                arrayTrimSearchKey.find((item) =>
                    p.email?.toLowerCase().includes(item)
                ) !== undefined ||
                arrayTrimSearchKey.find((item) =>
                    p.patientId?.toString().toLowerCase().includes(item)
                ) !== undefined
        );
        setPatients(filteredPatients)
    }

    const getAllAvailablePages = () => {
        return getAvailablePages(state);
    }

    const handlePageSizeChange = (value: any) => {
        if (value === state.pageSize) {
            return;
        }

        setState(state => ({
            ...state,
            pageSize: value,
            selectedPage: 1
        }));
    }

    const handlePageChange = (page: number) => {
        if (page === state.selectedPage) {
            return;
        }

        setState(state => ({
            ...state,
            selectedPage: page
        }));
    }

    const setDirection = (active: string, direction: SortingDirection) => {
        const itemIndex = state.sortingColumns.findIndex(item => item.object === active);
        state.sortingColumns[itemIndex].direction = direction;
        setState(state => ({
            ...state,
            columns: state.sortingColumns
        }));
    }

    const handleSorting = (active: string, source: string, direction: SortingDirection, object?: string | null) => {
        if (state.sortingSource === source) {
            direction = direction === SortingDirection.Asc
                ? SortingDirection.Desc
                : SortingDirection.Asc;

            setDirection(active, direction);
        }

        setState(state => ({
            ...state,
            sortingSource: source,
            activeSource: active,
            patients: state.patients.sort((p1, p2) => handleCompare(p1, p2, direction, source, object))
        }));
    }

    useEffect(() => {
        if (state.selectedOption) {
            handleApplyFilters();
            
            Track("my_patients_filter_applied", {
                filter_type: state.selectedFilter.title,
                filter: state.selectedOption.description,
                platform: PlatformName()
            })
        }
    }, [state.selectedOption])

    const useEffectCB = () => {

        const cb = () => setState(state => ({ ...state, isLoading: false }));

        patientsService.getMyPatients(getCurrentFilterModel()).subscribe((patients) => {
            const tags = patients.reduce((prev, next) => {
                const tagsToAdd = next.tags.filter(item => !prev.includes(item.name)).map(item => item.name)
                return [...prev, ...tagsToAdd]
            }, []);
            const planNames = patients.reduce((prev, next) => {
                const planNameToAdd = next.activePlan.name;
                return prev.includes(planNameToAdd) ? prev : [planNameToAdd, ...prev]
            }, []);
            if (tags.length) {
                const options = tags.map((tag, index) => {
                    const option = {} as FilterOptionsViewModel;
                    option['optionId'] = index + 1;
                    option['status'] = option['title'] = option['description'] = option['value'] = option['valueString'] = tag;
                    return option;
                })
                state.filtersState.filters.forEach(filter => {
                    if (filter.id === "tags_filter") {
                        filter.options = options;
                    }
                });
            }
            if (planNames.length) {
                const options = planNames.map((planName, index) => {
                    const option = {} as FilterOptionsViewModel;
                    option['optionId'] = index + 1;
                    option['status'] = option['title'] = option['description'] = option['value'] = option['valueString'] = planName;
                    return option;
                })
                state.filtersState.filters.forEach(filter => {
                    if (filter.id === "planName_filter") {
                        filter.options = options;
                    }
                });
            }
            setState({
                ...state,
                isLoading: false,
                patients: patients,
                originalPatients: patients,
                totalCount: patients.length
            });
        }, cb);
    }

    useEffect(useEffectCB, []);

    return [
        state,
        handleToggleFilters,
        handleClearFilters,
        handleSearchSubmit,
        handleSorting,
        getAllAvailablePages,
        handlePageSizeChange,
        handlePageChange,
        handleFilterSelect,
        handleOptionSelect,
    ];
}