import Axios from "axios-observable";
import { Observable } from "rxjs";
import { authHeader } from "../../common/helpers/auth-header";
import { snackService } from "../../common/snack/state";
import { RescheduleMessageModel, ScheduledMessageModel, ScheduleMessageModel, UploadedAttachmentModel } from "../models/message.models";
import { scheduledMessagesStore, ScheduledMessagesStore } from "../stores/scheduledMessagesStore/scheduledMessages.store";

/**
 * Provides method for working with scheduled messages
 */
export class ScheduleMessageService {
    private conversationsUrl = `${process.env.REACT_APP_API_URL}Conversations`;

    private getConfig = () => ({ headers: authHeader() });

    constructor(private scheduledMessagesStore: ScheduledMessagesStore) { }

    public get(conversationId: number): Observable<ScheduledMessageModel[]> {
        const url = `${this.conversationsUrl}/Employee/ScheduledMessages/${conversationId}`;

        return new Observable<ScheduledMessageModel[]>(observer => {
            Axios.get<ScheduledMessageModel[]>(url, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        this.scheduledMessagesStore.setScheduledMessages(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getAttachments(scheduledMessageId: number): Observable<UploadedAttachmentModel[]> {
        const url = `${this.conversationsUrl}/Employee/ScheduledMessage/${scheduledMessageId}/Attachments`;

        return new Observable<UploadedAttachmentModel[]>(observer => {
            Axios.get<UploadedAttachmentModel[]>(url, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        this.scheduledMessagesStore.setUploadedAttachments(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public schedule(model: ScheduleMessageModel): Observable<ScheduledMessageModel> {
        const url = `${this.conversationsUrl}/Employee/ScheduleMessage`;


        const formData = new FormData();
        formData.append('message', model.message);
        formData.append('conversationId', model.conversationId.toString());
        formData.append('participantId', model.participantId.toString());
        formData.append('timeToSendStr', model.timeToSendStr);
        formData.append('timeZoneName', model.timeZoneName);
        if (model.attachments.length) {
            model.attachments.forEach(item => {
                formData.append('attachments', item.file);
            });
        }

        return new Observable<ScheduledMessageModel>(observer => {
            Axios.post<ScheduledMessageModel>(url, formData, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        this.scheduledMessagesStore.addScheduledMessage(response.data);
                        snackService.success('Message successfully scheduled!')
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.error('Failed to schedule message!');
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public reschedule(model: RescheduleMessageModel): Observable<ScheduledMessageModel> {      
        const url = `${this.conversationsUrl}/Employee/EditScheduledMessage`;     
        
        const formData = new FormData();
        formData.append('message', model.message);
        formData.append('conversationId', model.conversationId.toString());
        formData.append('participantId', model.participantId.toString());
        formData.append('timeToSendStr', model.timeToSendStr);
        formData.append('timeZoneName', model.timeZoneName);
        if (model.attachments.length) {
            model.attachments.forEach(item => {
                formData.append('attachments', item.file);
            });
        }

        formData.append('id', model.id.toString());
        if (model.removeAttachments.length) {
            model.removeAttachments.forEach(item => {
                formData.append('removeAttachments[]', item.toString());
            });
        }

        return new Observable<ScheduledMessageModel>(observer => {
            Axios.put<ScheduledMessageModel>(url, formData, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        this.scheduledMessagesStore.updateScheduledMessage(model, response.data.uploadedAttachments);
                        snackService.success('Message successfully edited!')
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.error('Failed to edit message!');
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public delete(id: number): Observable<ScheduledMessageModel> {
        const url = `${this.conversationsUrl}/Employee/DeleteScheduledMessage/${id}`;

        return new Observable<ScheduledMessageModel>(observer => {
            Axios.delete<ScheduledMessageModel>(url, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        this.scheduledMessagesStore.deleteScheduledMessage(id);
                        snackService.success('Message successfully deleted!')
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.error('Failed to delete message!');
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }
}

export const scheduleMessageService = new ScheduleMessageService(scheduledMessagesStore);
