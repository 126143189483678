import {Box} from "@material-ui/core";
import CheckBoxOutlineBlankOutlinedIcon from "@material-ui/icons/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import React from "react";
import {AgreementBasePage} from "./AgreementBasePage";
import {AgreementHeaderComponent} from "./AgreementHeaderComponent";
import {AgreementPageProps} from "./agreementPageProps";
import './WildHealthAgreement.scss';

export const PatientFoundersAgreementPage: React.FC<AgreementPageProps> = (props: AgreementPageProps) => {
    const {
        data
    } = props;

    const renderPaymentPlanCheckbox = (planName: string, periodInMonths: number) => {
        const selectedPlanName = data['planName'];
        const selectedPeriodInMonths = parseInt(data['periodInMonths'] ?? '0');

        return (
            <p style={{display: 'inline-block', marginBottom: '-5px', verticalAlign: 'bottom'}}>
                {
                    planName === selectedPlanName && periodInMonths === selectedPeriodInMonths
                        ? <CheckBoxOutlinedIcon/>
                        : <CheckBoxOutlineBlankOutlinedIcon/>
                }
            </p>
        )
    }

    const renderMemberships = () => {
        return (
            <>
                <p className="paragraph"><span
                    className="paragraph-number">1.</span> <span
                    className="text-bold"> Employee Sponsored Membership:</span> Per
                    employer agreement.</p>
                <p className="paragraph"><span
                    className="paragraph-number">2.</span> <span
                    className="text-bold"> Advanced:</span></p>
                <Box ml="20px">
                    <p>
                        {renderPaymentPlanCheckbox('ADVANCED', 12)} 12 Month Plan:
                        12 health coaching visits and 4 provider visits at $2,388.00
                        or $199.00 per month. Startup fee of $195.00 applies.
                    </p>
                </Box>
                <p className="paragraph"><span
                    className="paragraph-number">3.</span> <span
                    className="text-bold"> Optimization:</span></p>
                <Box ml="20px">
                    <p>
                        {renderPaymentPlanCheckbox('OPTIMIZATION', 12)} 12 Month
                        Plan: 12 health coaching visits and 4 provider visits at
                        $3,588.00 or $299.00 per month. Startup fee of $195.00
                        applies.
                    </p>
                </Box>
                <p className="paragraph"><span
                    className="paragraph-number">4.</span> <span
                    className="text-bold"> Core:</span></p>
                <Box ml="20px">
                    <p>
                        {renderPaymentPlanCheckbox('CORE', 12)} 12 Month Plan: 4
                        health coaching visits and 1 provider visit at $1188.00 or
                        $99.00 per month. Startup fee of $195.00 applies.
                    </p>
                </Box>
                <p className="paragraph"><span
                    className="paragraph-number">5.</span> <span
                    className="text-bold"> Founders Advanced:</span></p>
                <Box ml="20px" pb={3}>
                    <p>
                        {renderPaymentPlanCheckbox('FOUNDERS_ADVANCED', 12)} 12
                        Month Plan: 12 health coaching visits and 4 provider visits at
                        $3,588.00 or $299.00 per month.
                    </p>
                </Box>
                <p className="paragraph"><span
                    className="paragraph-number">6.</span> <span
                    className="text-bold"> Founders Optimization:</span></p>
                <Box ml="20px" pb={3}>
                    <p>
                        {renderPaymentPlanCheckbox('FOUNDERS_OPTIMIZATION', 12)} 12
                        Month Plan: 12 health coaching visits and 4 provider visits at
                        $5,988.00 or $499.00 per month.
                    </p>
                </Box>
                <p className="paragraph"><span
                    className="paragraph-number">7.</span> <span
                    className="text-bold"> Free Trial:</span></p>
                <Box ml="20px" pb={3}>
                    <p>
                        {renderPaymentPlanCheckbox('FREE_TRIAL', 1)} 30-day free
                        trial: One Wild Health staff visit and one Health Coach
                        visit.
                    </p>
                </Box>
            </>
        )
    }

    return (
        <AgreementBasePage>
            <Box py={7} className="agreement">
                <AgreementHeaderComponent/>
                <Box className="text-light-medium">
                    <Box mt={2} textAlign="center">
                        <span className="agreement-title">PATIENT AGREEMENT</span>
                    </Box>
                    <Box mt={2}>
                        <p className="indent">This is an Agreement between Wild Health Inc, a Delaware Corporation
                            located at 535 Wellington Way, Suite #330, Lexington, Kentucky 40503 and 212 South 2nd
                            Street, Danville, Kentucky, 40422 (<span className="text-bold">“Wild Health”</span>), and
                            health coach or clinician (<span className="text-bold">“Provider”</span>) in their capacity
                            as agents of Wild Health, and you, (<span className="text-bold">“Client” or “You”</span>).
                        </p>
                    </Box>
                    <Box mt={2}>
                        <p>
                            NOT HEALTH INSURANCE. THIS AGREEMENT IS NOT HEALTH INSURANCE AND DOES NOT MEET ANY
                            INDIVIDUAL HEALTH INSURANCE MANDATE THAT MAY BE REQUIRED BY FEDERAL LAW, INCLUDING THE
                            FEDERAL PATIENT PROTECTION AND AFFORDABLE CARE ACT AND COVERS ONLY LIMITED ROUTINE HEALTH
                            CARE SERVICES AS DESIGNATED IN THIS AGREEMENT. THIS AGREEMENT IS NOT WORKERS’ COMPENSATION
                            INSURANCE AND DOES NOT REPLACE AN EMPLOYER’S OBLIGATIONS UNDER APPLICABLE FEDERAL OR STATE
                            LAW.
                        </p>
                    </Box>
                    <Box mt={2}>
                        <p className="text-bold">Background</p>
                    </Box>
                    <Box mt={2}>
                        <p className="indent">
                            Wild Health services begin with a comprehensive wellness screening assessment and medically
                            supervised health coaching. Following the Wild Health Evaluation, You may elect to establish
                            medical care via an in-person visit or asynchronous video telemedicine visit should You wish
                            to receive medical advice, prescriptive treatment recommendations, prognostic
                            recommendations, and/or prescriptions.
                        </p>
                    </Box>
                    <Box mt={2} textAlign="center">
                        <span className="text-bold">Services and Payment Terms</span>
                    </Box>
                    <Box mt={2}>
                        <p className="text-bold">1. Services</p>
                        <Box className="paragraphs-section">
                            <p className="paragraph"><span className="paragraph-number">1.1. </span><span
                                className="underline">Service Memberships</span>.
                                Client may elect to utilize services by selecting a Precision Care, Precision Health
                                Coaching, Optimization, Founder’s Precision, or Founder’s Optimization memberships.
                            </p>
                            <p className="paragraph"><span className="paragraph-number">1.2. </span><span
                                className="underline">Wellness Evaluation</span>.
                                Client shall also be entitled to an initial “wellness evaluation,” which shall be
                                performed by the health coach, and may include a health risk assessment, diet and
                                exercise evaluation, and other health coaching services.
                            </p>
                            <p className="paragraph"><span className="paragraph-number">1.3. </span><span
                                className="underline">Provider Medical Services</span>.
                                Depending on the membership selected Client may be entitled to Provider examinations and
                                medical services provided by the clinician tailored to the Clients specific needs. As
                                used in this Agreement, the term Medical Services shall mean those medical services that
                                the Provider is permitted to perform under the laws of the state of licensure and that
                                are consistent with his training and experience as a medical provider, as the case may
                                be.
                            </p>
                            <p className="paragraph"><span className="paragraph-number">1.4. </span><span
                                className="underline">Problem Visits</span>.
                                Depending on the membership selected Client may be entitled to additional provider
                                visits for evaluation and management of unexpected illnesses or issues. Problem visits
                                do not include emergent or urgent care matters and Provider will use their best efforts
                                to schedule problem visits within 48 hours of Client’s request.
                            </p>
                            <p className="paragraph"><span className="paragraph-number">1.5. </span><span
                                className="underline">Non-Medical, Personalized Services</span>.
                                Wild Health may also provide Client with the following non-medical services (<span
                                    className="text-bold">“Non-Medical Services”</span>):
                                <Box ml="20px">
                                    <p className="paragraph"><span className="paragraph-number">a.</span>
                                        <span className="italic"> Health Coaching: </span>
                                        As more fully described in the fee schedule below, to the extent Client receives
                                        Health Coaching, the applicable terms of Wild Health’s Coaching Client Agreement
                                        are incorporated herein by reference as if fully set forth.
                                    </p>
                                    <p className="paragraph"><span className="paragraph-number">b.</span>
                                        <span className="italic"> Premium Access: </span>
                                        Client shall have access to the Provider via secure messaging or video. Client
                                        shall be given a phone number where Client may reach the Provider directly.
                                        Provider does not guarantee immediate response. Provider will make reasonable
                                        efforts to respond in a timely manner. Client agrees to call 911 in case of
                                        medical emergency.
                                    </p>
                                    <p className="paragraph"><span className="paragraph-number">c.</span>
                                        <span className="italic"> E-Mail Access: </span>
                                        Client shall be given a Provider’s e-mail address for non-urgent communications.
                                        The Provider or staff member of Wild Health shall deal with such communications
                                        in a timely manner. Client understands and agrees that email and the internet
                                        should never be used to access medical care in the event of an emergency, or any
                                        situation that Client could reasonably expect may develop into an emergency.
                                        Client agrees that in such situations, when a Client cannot speak to Provider
                                        immediately in-person or by telephone, that Client shall call 911 or the nearest
                                        emergency medical services provider and follow the directions of emergency
                                        medical personnel.
                                    </p>
                                    <p className="paragraph"><span className="paragraph-number">d.</span>
                                        <span className="italic">Minimal wait Appointments: </span>
                                        Effort shall be made to assure that Client is seen by the Provider immediately
                                        upon arriving for a scheduled office visit or after only a minimal wait.
                                    </p>
                                    <p className="paragraph"><span className="paragraph-number">e.</span>
                                        <span className="italic">Appointments: </span>
                                        When Client calls or emails the Provider prior to noon on a normal office day
                                        (Monday through Friday) to schedule an appointment, every reasonable effort
                                        shall be made to schedule an appointment with the Provider within 1 business
                                        day. If the Client calls or e-mails the Provider after noon on a normal office
                                        day (Monday through Friday) to schedule an appointment, every reasonable effort
                                        shall be made to schedule Client’s appointment with the Provider within 2
                                        business days. Client understands and acknowledges that it is Client’s
                                        responsibility to schedule visits, if Client fails to schedule a visit during a
                                        30-day period, Wild Health reserves the right to reduce the overall available
                                        visits under a plan by a pro-rata amount to reflect the missed visit(s) as
                                        permitted under state or federal law.
                                    </p>
                                    <p className="paragraph"><span className="paragraph-number">f.</span>
                                        <span className="italic"> Specialists: </span>
                                        A Wild Health Provider shall coordinate with medical specialists to whom Client
                                        is referred to assist Client in obtaining specialty care. Client understands
                                        that fees paid under this Agreement do not include and do not cover such
                                        specialists’ fees or fees due to any medical professional other than the Wild
                                        Health Provider.
                                    </p>
                                </Box>
                            </p>
                        </Box>
                    </Box>
                    <Box mt={2}>
                        <p className="text-bold">2. Payment</p>
                        <Box className="paragraphs-section">
                            <p className="paragraph"><span className="paragraph-number">2.1. </span><span
                                className="underline">Fees Generally</span>.
                                In exchange for the services described herein, Client or Guarantor
                                agrees to pay Wild Health, the amount as set forth in Section 2.2 below. Fees are
                                payable prior to performance of any services. If Wild Health cancels this Agreement
                                before the agreement termination date, then Wild Health shall refund the Client’s
                                pro-rated share of any payment remaining after deducting individual charges for services
                                rendered to Client up to cancellation. Wild Health may amend the Membership Fee Schedule
                                at any time, as it may determine in its sole discretion, upon providing Client at least
                                sixty (60) days’ advance written notice. For employer sponsored memberships, individual
                                membership fees will only apply if the employee/member elects to continue services after
                                termination or employee sponsorship ends or elects services above and beyond that which
                                the employer has agreed to cover.
                            </p>
                            <p className="paragraph"><span className="paragraph-number">2.2. </span><span
                                className="underline">Membership Fee Schedule</span>.
                                The fee schedule for services provided by Wild Health shall be priced as follows:
                                <Box ml="20px">
                                    <p className="paragraph"><span className="paragraph-number">a.</span>
                                        <span className="italic"> Subscription Fee:</span> The Subscription fee shall be
                                        due in the amount(s) referenced below.
                                        {
                                            renderMemberships()
                                        }
                                    </p>
                                    <p className="paragraph"><span className="paragraph-number">b.</span>
                                        <span className="italic"> Additional Fees: </span>
                                        The following fees will be assessed and charged to Client’s account and will be
                                        due at the same time as the Monthly Subscription Fee:
                                        <p className="paragraph"><span
                                            className="paragraph-number">1.</span> <span className="italic"> Prescription Medications and Supplements: </span>
                                            The cost of medications and supplements is fluctuant and will be remitted to
                                            Client via invoice at time of dispensation.
                                        </p>
                                        <p className="paragraph"><span
                                            className="paragraph-number">2.</span> <span className="italic"> Shipping Charges: </span>
                                            Any shipping fees incurred for shipment from Wild Health to Client will be
                                            charged to the Client’s account and will be due at time of shipping.
                                        </p>
                                        <p className="paragraph"><span
                                            className="paragraph-number">3.</span> <span className="italic"> Laboratory Fees: </span>
                                            Wild Health contracts with third-party clinical and specialty labs to
                                            provide competitively priced laboratory analysis to Clients. All laboratory
                                            fees will be the responsibility of the Client and will be charged to the
                                            Client’s account and due at time of service.
                                        </p>
                                        <p className="paragraph"><span
                                            className="paragraph-number">4.</span> <span className="italic"> Medical Devices: </span>
                                            In the event that Client is in need of any medical device, this device may
                                            be ordered by Wild Health. The full cost of the device and any additional
                                            shipping or service fees will be the responsibility of the Client and will
                                            be charged to the Client’s account and due at time of service.
                                        </p>
                                        <p className="paragraph"><span
                                            className="paragraph-number">5.</span> <span className="italic"> Loaned Devices: </span>
                                            Client will be responsible for replacing any lost, damaged, stolen or
                                            unreturned devises loaned from Wild Health.
                                        </p>
                                        <p className="paragraph"><span
                                            className="paragraph-number">6.</span> <span
                                            className="italic"> Other:</span> Other fees may be applicable to the
                                            Client’s account and will be
                                            expressed to the Client verbally before service is rendered or ordered and
                                            every effort will be made to express the fees in writing as well. These fees
                                            will be charged to the Client’s account and due at time of service.
                                        </p>
                                    </p>
                                </Box>
                            </p>
                            <p className="paragraph"><span className="paragraph-number">2.3. </span><span
                                className="underline">Discounts</span>. Previous fees and promotions will be honored by
                                Wild Health as agreed upon in writing.
                            </p>
                            <p className="paragraph"><span className="paragraph-number">2.4. </span><span
                                className="underline">Invoice</span>. A monthly invoice will be provided via email
                                (Preferred). Or, in the case that Client does not have access to email, Client will
                                receive a monthly invoice via mail.
                            </p>
                            <p className="paragraph"><span className="paragraph-number">2.5. </span><span
                                className="underline">Payment</span>. Payment may be remitted to Wild Health via
                                credit/debit card, cash or check.
                                Cash and check payments will only be accepted on site at the Wild Health office (address
                                listed above). An Automatic Credit/Debit Authorization Form at Exhibit A may be executed
                                to authorize automatic payment of invoices. Payment will be considered late fifteen (15)
                                days after the invoice date and a $15 late fee applied to amounts owed. Late payment may
                                result in termination of the Client’s subscription solely at the discretion of Wild
                                Health.
                            </p>
                            <p className="paragraph"><span className="paragraph-number">2.6. </span><span
                                className="underline">Credit Card Authorization</span>.
                                If an invoice remains unpaid for sixty (60) days after invoice date, Client authorizes
                                Wild Health to charge the credit card on file for the Client in the amount of the
                                past-due invoice, plus any late charges as set forth in the Automatic Credit/Debit
                                Authorization Form, attached hereto at Exhibit A.
                            </p>
                            <p className="paragraph"><span className="paragraph-number">2.7. </span><span
                                className="underline">Non-Participation in Insurance</span>.
                                Client acknowledges that neither Wild Health, nor the Provider participate in any health
                                insurance or HMO plans. Neither of the above make any representations whatsoever that
                                any fees paid under this Agreement are covered by your health insurance or other
                                third-party payment plans applicable to the Client. The Client shall retain full and
                                complete responsibility for any such determination. Wild Health will not bill your
                                health insurance for services provided under this Agreement. Payments made by Client
                                under this Agreement may not count towards health insurance deductibles or maximum
                                out-of-pocket expenses. This agreement acknowledges your understanding that Medicare,
                                Medicaid, or any other federal health care program cannot be billed for any services
                                performed for you by the Physician. You agree not to attempt to obtain reimbursement for
                                any such services from any federal health care program.
                            </p>
                        </Box>
                    </Box>
                    <Box mt={2}>
                        <p className="text-bold">3. General Terms</p>
                        <Box className="paragraphs-section">
                            <p className="paragraph"><span className="paragraph-number">3.1. </span><span
                                className="underline">Term; Termination</span>.
                                This Agreement will commence on the date first written above and will extend for the
                                period of the membership plan selected above automatically renewing thereafter except in
                                the case of the free trial which will terminate after 30 days. Notwithstanding the
                                above, both Client and Wild Health shall have the absolute and unconditional right to
                                terminate the Agreement, without the showing of any cause for termination, upon giving
                                thirty (30) days prior written notice to the other party. Unless previously terminated
                                as set forth above, at the expiration of the initial term (and each succeeding term),
                                the Agreement will automatically renew for successive terms. In addition, this agreement
                                may be terminated immediately should the Client fail to provide payment for a sixty (60)
                                day period.
                            </p>
                            <p className="paragraph"><span className="paragraph-number">3.2. </span><span
                                className="underline">Early Termination Fee</span>.
                                To the extent permissible by state and federal law, Wild Health may apply an early
                                termination fee should client terminate this Agreement without cause prior to its
                                expiration, such early termination fee will not exceed 3 months of the applicable
                                subscription monthly fee amount.
                            </p>
                            <p className="paragraph"><span className="paragraph-number">3.3. </span><span
                                className="underline">Insurance or Other Medical Coverage</span>.
                                Client acknowledges and understands that this Agreement is not an insurance plan, and
                                not a substitute for health insurance or other health plan coverage (such as membership
                                in an HMO). It will not cover hospital services, or any services not personally provided
                                by Wild Health, or its Providers. Client acknowledges that Wild Health has advised that
                                Client obtain or keep in full force such health insurance policy(ies) or plans that will
                                cover Client for general healthcare costs. Client acknowledges that this Agreement is
                                not a contract that provides health insurance, and this Agreement is not intended to
                                replace any existing or future health insurance or health plan coverage that Client may
                                carry.
                            </p>
                            <p className="paragraph"><span className="paragraph-number">3.4. </span><span
                                className="underline">Communications</span>.
                                You acknowledge that communications using email, facsimile, video chat, instant
                                messaging, and cell phone are not guaranteed to be secure or confidential methods of
                                communications. As such, you expressly waive the providers obligation to guarantee
                                confidentiality with respect to correspondence using such means of communication. You
                                acknowledge that all such communications may become a part of your medical records. By
                                providing your email address, Client authorizes the Wild Health, and its Providers to
                                communicate with Client by email regarding Client’s “protected health information” (PHI)
                                (as that term is defined in the Health Insurance Portability and Accountability Act
                                (HIPAA) of 1996 and its implementing regulations.
                                Client further acknowledges that:
                                <p className="paragraph"><span className="paragraph-number">a. </span>
                                    E-mail is not necessarily a secure medium for sending or receiving PHI and, there is
                                    always a possibility that a third party may gain access;
                                </p>
                                <p className="paragraph"><span
                                    className="paragraph-number">b. </span>
                                    Although the Provider will make all
                                    reasonable efforts to keep e-mail communications confidential and secure, neither
                                    Wild Health, nor the Provider can assure or guarantee the absolute confidentiality
                                    of e-mail communications;
                                </p>
                                <p className="paragraph"><span className="paragraph-number">c. </span>
                                    In the discretion of the Provider, e-mail communications may be made a part of
                                    Client’s permanent medical record;
                                </p>
                                <p className="paragraph"><span className="paragraph-number">d. </span>
                                    Client understands and agrees that E-mail is not an appropriate means of
                                    communication regarding emergency or other time-sensitive issues or for inquiries
                                    regarding sensitive information. In the event of an emergency, or a situation in
                                    which the member could reasonably expect to develop into an emergency, Client shall
                                    call 911 or the nearest Emergency room, and follow the directions of emergency
                                    personnel.
                                </p>
                                <p className="paragraph"><span className="paragraph-number">e. </span>
                                    If Client does not receive a response to an e-mail message within one day, Client
                                    agrees to use another means of communication to contact the Provider. Neither Wild
                                    Health, nor the Provider will be liable to Client for any loss, cost, injury, or
                                    expense caused by, or resulting from, a delay in responding to Client as a result of
                                    technical failures, including, but not limited to, (i) technical failures
                                    attributable to any internet service provider, (ii) power outages, failure of any
                                    electronic messaging software, or failure to properly address e-mail messages, (iii)
                                    failure of the Practice’s computers or computer network, or faulty telephone or
                                    cable data transmission, (iv) any interception of e-mail communications by a third
                                    party; or (v) your failure to comply with the guidelines regarding use of e-mail
                                    communications set forth in this paragraph.
                                </p>
                            </p>
                            <p className="paragraph"><span className="paragraph-number">3.5. </span><span
                                className="underline">Severability</span>.
                                If for any reason any provision of this Agreement shall be deemed, by a court of
                                competent jurisdiction, to be legally invalid or unenforceable in any jurisdiction to
                                which it applies, the validity of the remainder of the Agreement shall not be affected,
                                and that provision shall be deemed modified to the minimum extent necessary to make that
                                provision consistent with applicable law and in its modified form, and that provision
                                shall then be enforceable.
                            </p>
                            <p className="paragraph"><span className="paragraph-number">3.6. </span><span
                                className="underline">Amendment</span>.
                                No amendment of this Agreement shall be binding on a party unless it is made in writing
                                and signed by all the parties. Notwithstanding the foregoing, Wild Health may
                                unilaterally amend this Agreement to the extent required by federal, state, or local law
                                or regulation (“Applicable Law”) by sending Client thirty (30) days’ advance written
                                notice of any such change. Any such changes are incorporated by reference into this
                                Agreement without the need for signature by the parties and are effective as of the date
                                established by Wild Health. Moreover, if Applicable Law requires this Agreement to
                                contain provisions that are not expressly set forth in this Agreement, then, to the
                                extent necessary, such provisions shall be incorporated by reference into this Agreement
                                and shall be deemed a part of this Agreement as though they had been expressly set forth
                                in this Agreement.
                            </p>
                            <p className="paragraph"><span className="paragraph-number">3.7. </span><span
                                className="underline">Assignment</span>.
                                This Agreement, and any rights Client may have under it, may not be assigned or
                                transferred by Client to another person(s).
                            </p>
                            <p className="paragraph"><span className="paragraph-number">3.8. </span><span
                                className="underline">Relationship of Parties</span>.
                                Client and Wild Health intend and agree that Wild Health, in performing his duties under
                                this Agreement, may be an independent contractor, as defined by the guidelines
                                promulgated by the United States Internal Revenue Service and/or the United States
                                Department of Labor, and the independent contractor shall have exclusive control of his
                                work and the manner in which it is performed.
                            </p>
                            <p className="paragraph"><span className="paragraph-number">3.9. </span><span
                                className="underline">Legal Significance</span>.
                                Client acknowledges that this Agreement is a legal document and creates certain rights
                                and responsibilities. Client also acknowledges having had a reasonable time to seek
                                legal advice regarding the Agreement and has either chosen not to do so or has done so
                                and is satisfied with the terms and conditions of the Agreement.
                            </p>
                            <p className="paragraph"><span className="paragraph-number">3.10. </span><span
                                className="underline">Entire Agreement</span>.
                                This Agreement contains the entire agreement between the parties and supersedes all
                                prior oral and written understandings and agreements regarding the subject matter of
                                this Agreement.
                            </p>
                            <p className="paragraph"><span className="paragraph-number">3.11. </span><span
                                className="underline">Jurisdiction and Venue</span>.
                                This Agreement shall be governed by and construed in accordance with the internal laws
                                of the Commonwealth of Kentucky without giving effect to any choice or conflict of law
                                provision or rule. Each party irrevocably submits to the exclusive jurisdiction and
                                venue of the federal and state courts located in the following county in any legal suit,
                                action, or proceeding arising out of or based upon this Agreement or the Services
                                provided hereunder: Fayette County, Kentucky.
                            </p>
                            <p className="paragraph"><span className="paragraph-number">3.12. </span><span
                                className="underline">Service</span>.
                                All written notices are deemed served if sent to the address of the party written above
                                or appearing in Exhibit A by first class U.S. mail.
                            </p>
                            <p className="paragraph"><span className="paragraph-number">3.13. </span><span
                                className="underline"> Data Aggregation for Research</span>.
                                By agreeing to this form, you release Wild Health to utilize deidentified demographic,
                                results, or survey data for research purposes.
                            </p>
                        </Box>
                    </Box>
                    <Box mt={2}>
                        <p className="indent">
                            By agreeing I am entering into this Agreement with Wild Health on the date indicated after
                            my signature (“Effective Date”). I acknowledge and agree that all my questions have been
                            answered and I understand the terms outlined in the Agreement and any attachment hereto.
                        </p>
                    </Box>
                    <Box mt={5} textAlign="center">
                        <span className="agreement-title">EXHIBIT A</span>
                    </Box>
                    <Box textAlign="center">
                        <span
                            className="agreement-title">AUTOMATIC DEBIT/ CREDIT CARD BILLING AUTHORIZATION
                        </span>
                    </Box>
                    <Box mt={2}>
                        <p className="indent">
                            By agreeing you authorize Wild Health to automatically bill amounts owed pursuant to the fee
                            schedule for services provided to You by Wild Health, either monthly as owed or after sixty
                            (60) days following non-payment by another form, in Wild Health’s sole discretion and
                            pursuant to the terms of the Agreement. When automatically billed, a receipt for each
                            payment will be provided to you and a charge will appear on your credit card statement.
                        </p>
                    </Box>
                    <Box mt={2}>
                        <p className="text-bold">Payment Information</p>
                        Start billing on: Effective Date of Agreement; reoccurring monthly.
                    </Box>
                    <Box mt={2}>
                        <p className="indent">
                            I understand that this authorization will remain in effect until I cancel it in writing, and
                            agree to notify Wild Health in writing of any changes in my account information or
                            termination of this authorization within fifteen (15) days prior to the next billing date. I
                            understand that payments may be executed on the next business day. I acknowledge that the
                            origination of credit card transactions to my account must comply with provisions of
                            federal, state, and local laws. I certify that I am an authorized user of this credit card
                            and will not dispute these scheduled transactions; so long as the transactions correspond to
                            the terms indicated in the Agreement and this Authorization Form.
                        </p>
                    </Box>
                </Box>
            </Box>
        </AgreementBasePage>
    );
}