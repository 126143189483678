import { Button, Theme, withStyles } from "@material-ui/core";
import { colors } from "../../constants/colors";

export const OptionsButton = withStyles((theme: Theme) => ({
  root: {
    height: 34,
    color: colors.gray1,
    backgroundColor: "transparent",
    border: `1px solid ${colors.gray400}`,
    textTransform: "none",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: `${colors.main}11`,
    },
    "&:disabled": {
      color: colors.gray2,
      backgroundColor: colors.disabled,
      border: `1px solid ${colors.gray1}44`,
    },
    [theme.breakpoints.down("xs")]: {
      height: 24,
      color: colors.black,
      border: 0,
    },
  },
}))(Button);
