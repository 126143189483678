import {
    Box,
    IconButton,
    Popover,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    Tooltip,
    useMediaQuery,
    useTheme
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import moment from 'moment';
import React from 'react';
import {addOnProvidersNames} from '../../../addons/models/addOns.models';
import {WildHealthButton} from '../../../common/components/wildHealthButton/WildHealthButton';
import {
    WildHealthHeaderComponent
} from '../../../common/components/wildHealthHeaderComponent/WildHealthHeaderComponent';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import {CommonPlaceHolder} from '../../../common/components/wildHealthPlaceHolder/CommonPlaceHolder';
import {WildHealthTableCell} from '../../../common/components/wildHealthTable/WildHealthTableCell';
import {WildHealthTableRow} from '../../../common/components/wildHealthTable/WildHealthTableRow';
import {useFacade} from './labOrdersComponent.hooks';
import {useStyles} from './labOrdersComponent.styles';
import {ProtectedElement} from "../../../common/components/ProtectedElement";
import {PermissionType} from "../../../auth/models/auth.enums";
import {toCurrentTimeZone} from '../../../timezones/helpers/timezone';
import commonUseStyles from "../../../common/styles/common.styles";
import clsx from 'clsx';
import {useHistory, useLocation} from 'react-router';
import {RoutesConstants} from '../../../../constants/route.constants';
import {WildHealthMenuItem} from '../../../common/components/wildHealthMenu/WildHealthMenu';
import {WildHealthWarningMenuItem} from '../../../common/components/wildHealthMenu/WildHealthWarningMenuItem';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import {CancelLabOrderDialogue} from '../cancelLabOrderDialogue/CancelLabOrderDialogue';
import {OrderStatus} from '../../models/orders.models';
import {colors} from '../../../common/constants/colors';
import {MarkLabOrderAsPaidDialog} from '../markLabOrderAsPaidDialog/MarkLabOrderAsPaidDialog';

interface LabOrdersComponentProps {
    patientId?: number;
    orderId: number;
    handleNavigateToOrderNew?: () => void;
    handleNavigateToInvoice?: (orderId: number) => void;
}

export const LabOrdersComponent: React.FC<LabOrdersComponentProps> = (props: LabOrdersComponentProps) => {
    const { patientId, orderId, handleNavigateToOrderNew, handleNavigateToInvoice } = props;
    const classes = useStyles();
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const [
        {
            isLoading,
            orders,
            menuAnchorEl,
            targetOrder
        },
        columns,
        handleViewRequisition,
        handleBuyNewLabOrder,
        canBuyNewLabOrder,
        handleToggleMenu,
        handleNavigateToInvoiceFromState,
        handleCancelOrder,
        handleMarkOrderAsPaid
    ] = useFacade(patientId, orderId, handleNavigateToInvoice);

    const location = useLocation();
    const _history = useHistory();
    const locationSearch = location?.search;

    if (locationSearch) {
        const skip = locationSearch.includes("tab");
        if(!skip){
            const getOrderId = +locationSearch.replace(/[^0-9]/g, '')
            handleNavigateToInvoice(getOrderId)
        }
        
        _history.push({
            pathname: `${RoutesConstants.managePatientProfile}/${patientId}`,
            search: '',
        });
    }

    const title = 'Lab Orders';

    const emptyPlaceHolder = () => {
        return <span className="text-accent-secondary">(empty)</span>
    }

    const displayDataValue = (value: Date) => {
        return value ? moment(toCurrentTimeZone(value)).format("MM/DD/yyyy") : emptyPlaceHolder();
    }

    const displayUtcDateValue = (value: Date) => {
        return value ? moment(value).format("MM/DD/yyyy") : 'N/A';
    }

    const getPaidStatus = (status: boolean) => {
        if (status) {
            return <Box px={2} py={1} className={commonClasses.backgroundGood}><span className={commonClasses.colorGood}>Paid</span></Box>;
        } else {
            return <Box px={2} py={1} className={commonClasses.backgroundBad}><span className={commonClasses.colorBad}>Unpaid</span></Box>;
        }
    }

    const renderSmallContent = () => {

        if (isLoading) {
            return (
                <WildHealthLinearProgress />
            )
        }

        if (!orders.length) {
            return <Box height="calc(100vh - 225px)" className={commonClasses.bgWhiteMain}>
                <CommonPlaceHolder message={`No lab orders yet.`} />
            </Box>
        }

        return (
            <Box className={classes.content}>
                {
                    orders.map((item, index) =>
                        <Box mb="10px" p={2} className={commonClasses.bgWhiteMain} key={index}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Box className={clsx(commonClasses.textMedium, commonClasses.size14, commonClasses.colorBlack)}>
                                    {displayDataValue(item.orderedAt)}
                                </Box>
                                <Box>
                                    {getPaidStatus(item.isPaid)}
                                </Box>
                            </Box>
                            <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                                <span className={clsx(commonClasses.textMedium, commonClasses.size14, commonClasses.colorGray2)}>{'Expected Collection Date'}</span>
                                <Box className={clsx(commonClasses.textRegular, commonClasses.size14, commonClasses.colorBlack)}>
                                    {displayUtcDateValue(item.expectedCollectionDate)}
                                </Box>
                            </Box>
                            <Box mt={1.5} display="flex" justifyContent="space-between" alignItems="center">
                                <span className={clsx(commonClasses.textMedium, commonClasses.size14, commonClasses.colorGray2)}>{'Lab Provider'}</span>
                                <Box className={clsx(commonClasses.textRegular, commonClasses.size14, commonClasses.colorBlack)}>
                                    {addOnProvidersNames.get(item.provider)}
                                </Box>
                            </Box>
                            <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                                <Box>
                                    <IconButton id="lab-order-more-menu" onClick={(e) => handleToggleMenu(item.id, e.currentTarget)} className={classes.menuIcon}>
                                        <MoreHorizIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Box>
                    )
                }
                {targetOrder && (
                    <Popover
                    keepMounted
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl)}
                    onClose={() => handleToggleMenu(0)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    >
                        <WildHealthMenuItem id="lab-order-requisition" disabled={false} onClick={handleViewRequisition}>View Requisition</WildHealthMenuItem>
                        <WildHealthMenuItem id="lab-order-invoice" disabled={false} onClick={handleNavigateToInvoiceFromState}>View Invoice</WildHealthMenuItem>
                    </Popover>
                )}
                <CancelLabOrderDialogue />
            </Box>

        )
    }

    const renderContent = () => {

        if (isLoading) {
            return (
                <WildHealthLinearProgress />
            )
        }

        if (!orders.length) {
            return <CommonPlaceHolder message={`No ${title} yet.`} />
        }

        return (
            <Box>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <WildHealthTableRow>
                                {
                                    columns.map((item, index) =>
                                        <WildHealthTableCell key={`lab-cell-${index}`} align="left">
                                            {item}
                                        </WildHealthTableCell>
                                    )
                                }
                            </WildHealthTableRow>
                        </TableHead>
                        <TableBody>
                            {
                                orders.map((item, index) =>
                                    {      
                                        const isCancelled = item.status == OrderStatus.Cancelled;
                                        const statusClass = isCancelled ? classes.circleRed : item.isPaid ? classes.circleGreen : classes.circleGray;
                                        const statusText = isCancelled ? `Cancelled by ${item.cancelledBy}` : item.isPaid ? 'Paid' : 'Unpaid';
                                        const cancelledInfoTip = isCancelled ? `Cancelled on ${displayUtcDateValue(item.cancelledAt)} due to ${item.cancellationReason}`: '';
                                        return (
                                        <WildHealthTableRow key={`lab-row-${index}`}>
                                                <WildHealthTableCell align="left">
                                                    {displayDataValue(item.orderedAt)}
                                                </WildHealthTableCell>
                                                <WildHealthTableCell align="left">
                                                    {displayUtcDateValue(item.expectedCollectionDate)}
                                                </WildHealthTableCell>
                                                <WildHealthTableCell align="left">
                                                    {addOnProvidersNames.get(item.provider)}
                                                </WildHealthTableCell>
                                                <WildHealthTableCell align="left">
                                                    <Box display="flex" justifyContent="flex-start" alignItems="center">
                                                    <Box display="flex">
                                                        <Box>
                                                            {
                                                                <Box className={statusClass}></Box>
                                                            }
                                                            </Box>
                                                            <Box ml={2}>
                                                                {statusText}
                                                            </Box>
                                                            {isCancelled && (<Box ml={2}>
                                                                <Tooltip placement="top" arrow
                                                                    title={<Box className={classes.notificationToolTipText}>
                                                                        <Box display='flex' flexDirection='column' mr={2}>
                                                                            <Box mt={1} display="flex">
                                                                                {cancelledInfoTip}
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>}                                                            >
                                                                    <InfoIcon className={classes.iconInfo} fontSize='small' style={{ fill: colors.gray2 }} />
                                                                </Tooltip>
                                                            </Box>)}
                                                        </Box>
                                                    </Box>
                                                </WildHealthTableCell>
                                                <WildHealthTableCell>
                                                    <Box>
                                                        <IconButton id="lab-order-more-menu" onClick={(e) => handleToggleMenu(item.id, e.currentTarget)} className={classes.menuIcon}>
                                                            <MoreHorizIcon />
                                                        </IconButton>
                                                    </Box>
                                                </WildHealthTableCell>
                                            </WildHealthTableRow>
                                        )
                                    }
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                {targetOrder && (
                    <Popover
                        keepMounted
                        anchorEl={menuAnchorEl}
                        open={Boolean(menuAnchorEl)}
                        onClose={() => handleToggleMenu(0)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <WildHealthMenuItem id="lab-order-requisition" disabled={false} onClick={handleViewRequisition}>View Requisition</WildHealthMenuItem>
                        <WildHealthMenuItem id="lab-order-invoice" disabled={false} onClick={handleNavigateToInvoiceFromState}>View Invoice</WildHealthMenuItem>
                        {!targetOrder.isPaid && targetOrder.status !== OrderStatus.Cancelled && (
                            <ProtectedElement
                                element={
                                    <WildHealthMenuItem id="lab-order-mark-as-paid" disabled={false} onClick={handleMarkOrderAsPaid}>Mark as Paid</WildHealthMenuItem>
                                }
                                permissions={[PermissionType.InvoiceManagement]}
                            />
                        )}
                        <WildHealthWarningMenuItem id="lab-order-cancel" disabled={targetOrder.status === OrderStatus.Cancelled} onClick={handleCancelOrder}>Cancel Order</WildHealthWarningMenuItem>
                    </Popover>
                )}
                <CancelLabOrderDialogue />
                <MarkLabOrderAsPaidDialog />
            </Box>
            )
    }

    const header = (
        <Box display='flex' justifyContent='space-between'>
            <span>
                {title}
            </span>
            <ProtectedElement
                element={
                    <Box>
                        <WildHealthButton id="lab-orders-link-new-lab" onClick={() => handleNavigateToOrderNew()}>
                            New Lab Order
                        </WildHealthButton>
                    </Box>
                }
                permissions={[PermissionType.Coaching, PermissionType.ManagePatients]}
            />
            <ProtectedElement
                element={
                    <>
                        {
                            canBuyNewLabOrder() &&
                            <Box>
                                <WildHealthButton id="lab-orders-buy-new-lab" onClick={() => handleBuyNewLabOrder()}>
                                    New Lab Order
                                </WildHealthButton>
                            </Box>
                        }
                    </>
                }
                permissions={[PermissionType.Coaching, PermissionType.ManagePatients]}
            />
        </Box>
    )

    return isSmallScreen ? renderSmallContent() : (
        <WildHealthHeaderComponent title={header}>
            <Box className={classes.content}>
                {renderContent()}
            </Box>
        </WildHealthHeaderComponent>
    );
}