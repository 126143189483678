import React from 'react';
import { AuthenticatedLayoutComponent } from '../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from '../../../../models/menu.models';
import { DnaOrderDropshipComponent } from "../../components/dnaOrderDropshipComponent/DnaOrderDropshipComponent";

export const DNAOrderDropshipPage: React.FC = () => {
    return (
        <AuthenticatedLayoutComponent
            hideBackground
            title={TitlesService.dnaOrderDropshipTitle}
            pageName={PagesNamesService.dnaOrderDropship}
            selectedMenuItem={MenuItemTypes.DNAOrderDropship}
        >
            <DnaOrderDropshipComponent />
        </AuthenticatedLayoutComponent>
    )
}