import React from "react";
import { Box, Grid, TextField, Dialog, DialogContent, DialogActions, FormControl, Card, IconButton, Collapse } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from "moment";
import { ReactComponent as CalendarIcon } from '@img/icons/CalendarIcon.svg';
import { useFacade } from "./premiumBillingComponent.hooks";
import { useStyles } from "./premiumBillingComponent.styles";
import commonUseStyles from '../../../common/styles/common.styles';
import { DialogTitle } from "../../../common/components/dialogTitle/DialogTitle";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { ProtectedElement } from "../../../common/components/ProtectedElement";
import { colors } from '../../../common/constants/colors';
import { PermissionType } from '../../../auth/models/auth.enums';
import { patientsQuery } from "../../../patients/stores/patientsStore";

export interface PremiumBillingComponentProps {
    patientId: number;
}

export const PremiumBillingComponent: React.FC<PremiumBillingComponentProps> = (props: PremiumBillingComponentProps) => {
    const { 
        patientId,
    } = props;

    const classes = useStyles();
    const commonClasses = commonUseStyles();

    const [
        {
            isAdding,
            isOpenReview,
            isCollapse,
            manualPayment,
            activeMembership,
            paymentPlanHistories,
            selectedDate,
            errors
        },
        handleToggle,
        handleOpenReview,
        handleCloseReview,
        handleChanges,
        handleChangeDownPayment,
        handleChangeItemAmount,
        handleChangeDate,
        handleOnBlurValidate,
        handleSubmitForm,
        handleExpand
    ] = useFacade(patientId);

    const isPatientPremium = !!activeMembership ? patientsQuery.getTargetPatientIsPremium() : paymentPlanHistories?.[0]?.paymentPlanName === "Premium" 

    const renderContent = () => {
        return (
            <>
                {manualPayment?.id > 0 ? <>
                    <Box py={2} display="flex" alignItems="center">
                        <span className="size-1_25 text-medium">Manual Payment Schedule</span>
                        
                        <Box ml={2}>
                            <IconButton
                                id={`expand-button`}
                                size="small"
                                onClick={() => handleExpand()}
                            >
                                {!isCollapse ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                            </IconButton>
                        </Box>
                    </Box>
                    <Collapse in={isCollapse} timeout="auto" unmountOnExit>
                        <Box>
                            <Card className={classes.subscriptionCard} variant="outlined">
                                <Grid container spacing={3}>
                                    <Grid item md={6} sm={12}>
                                        <Box>
                                            <Box className={commonClasses.label} mb={1}>Total Amount due ($)</Box>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                required
                                                type="number"
                                                InputProps={{ className: 'input', classes }}
                                                id="total"
                                                placeholder="Total Amount due"
                                                value={manualPayment?.total}
                                                variant="outlined"
                                                disabled
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item md={6} sm={12}>
                                        <Box>
                                            <Box className={commonClasses.label} mb={1}>Billing Duration  (1-12)</Box>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                required
                                                type="number"
                                                InputProps={{ className: 'input', classes }}
                                                id="remainingPaidOverMonths"
                                                placeholder="Billing Duration"
                                                value={manualPayment?.remainingPaidOverMonths}
                                                variant="outlined"
                                                disabled
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item md={6} sm={12}>
                                        <Box>
                                            <Box className={commonClasses.label} mb={1}>Initial Payment ($)</Box>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                type="number"
                                                InputProps={{ className: 'input', classes }}
                                                id="downPayment"
                                                placeholder="Initial Payment"
                                                value={manualPayment?.downPayment}
                                                variant="outlined"
                                                disabled
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item md={6} sm={12}>
                                        <Box>
                                            <Box className={commonClasses.label} mb={1}>Consult Deposit</Box>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                required
                                                type="number"
                                                InputProps={{ className: 'input', classes }}
                                                id="deposit"
                                                placeholder="Consult Deposit"
                                                value={manualPayment?.deposit}
                                                variant="outlined"
                                                disabled
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item md={6} sm={12}>
                                        <Box>
                                            <FormControl
                                                variant="outlined"
                                                color='primary'
                                                required
                                                error={!!errors[`date`]}
                                                size="small"
                                                disabled={isAdding || !isPatientPremium}
                                                classes={{ root: classes.root }}
                                                fullWidth>
                                                <Box mb={1}>
                                                    <span className={commonClasses.label}>Start Date</span>
                                                </Box>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        id={`selectedDate`}
                                                        fullWidth
                                                        size="small"
                                                        placeholder="MM/DD/YYYY"
                                                        format="MM/DD/YYYY"
                                                        openTo="year"
                                                        value={selectedDate}
                                                        helperText={null}
                                                        error={!!errors[`selectedDate`]}
                                                        inputVariant="outlined"
                                                        disabled
                                                        views={["year", "month", "date"]}
                                                        onClick={(e) => e.stopPropagation()}
                                                        onChange={(momentDate) => handleChangeDate(momentDate === null ?
                                                            "" : momentDate.toDate())}
                                                        KeyboardButtonProps={{
                                                            "aria-label": "change date",
                                                            "edge": "end",
                                                        }}
                                                        keyboardIcon={<CalendarIcon fill={colors.gray500}/>}
                                                        minDate={new Date()}
                                                        onBlur={() => handleOnBlurValidate('selectedDate')}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    <Grid item md={12}>
                                        <Box display="flex" justifyContent="flex-end">
                                            <WildHealthButton
                                                id="review-for-manual-payment"
                                                color="primaryV"
                                                disabled={isAdding || Object.keys(errors).length > 0}
                                                onClick={() => handleOpenReview()}
                                            >
                                                Review
                                            </WildHealthButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Box>
                    </Collapse>
                </> : <>
                    <Box py={2}>
                        <span className="size-1_25 text-medium">Premium Billing</span>
                    </Box>
                    <Box>
                        <Grid container spacing={3}>
                            <Grid item md={6} sm={12}>
                                <Box>
                                    <Box className={commonClasses.label} mb={1}>Total Amount due ($)</Box>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        required
                                        type="number"
                                        InputProps={{ className: 'input', classes }}
                                        id="total"
                                        placeholder="Total Amount due"
                                        value={manualPayment?.total}
                                        variant="outlined"
                                        disabled={isAdding || !isPatientPremium}
                                        error={!!errors['total']}
                                        helperText={errors['total']}
                                        onChange={(v) => handleChanges(v.target.id, v.target.value)}
                                    />
                                </Box>
                            </Grid>
                            <Grid item md={6} sm={12}>
                                <Box>
                                    <Box className={commonClasses.label} mb={1}>Billing Duration  (1-12)</Box>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        required
                                        type="number"
                                        InputProps={{ className: 'input', classes }}
                                        id="remainingPaidOverMonths"
                                        placeholder="Billing Duration"
                                        value={manualPayment?.remainingPaidOverMonths}
                                        variant="outlined"
                                        disabled={isAdding || !isPatientPremium}
                                        error={!!errors['remainingPaidOverMonths']}
                                        helperText={errors['remainingPaidOverMonths']}
                                        onChange={(v) => handleChanges(v.target.id, v.target.value)}
                                    />
                                </Box>
                            </Grid>
                            <Grid item md={6} sm={12}>
                                <Box>
                                    <Box className={commonClasses.label} mb={1}>Initial Payment ($)</Box>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        type="number"
                                        InputProps={{ className: 'input', classes }}
                                        id="downPayment"
                                        placeholder="Initial Payment"
                                        value={manualPayment?.downPayment}
                                        variant="outlined"
                                        disabled={isAdding || !isPatientPremium}
                                        error={!!errors['downPayment']}
                                        helperText={errors['downPayment']}
                                        onChange={(v) => handleChangeDownPayment(v.target.value)}
                                    />
                                </Box>
                            </Grid>
                            <Grid item md={6} sm={12}>
                                <Box>
                                    <Box className={commonClasses.label} mb={1}>Consult Deposit</Box>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        required
                                        type="number"
                                        InputProps={{ className: 'input', classes }}
                                        id="deposit"
                                        placeholder="Consult Deposit"
                                        value={manualPayment?.deposit}
                                        variant="outlined"
                                        disabled={isAdding || !isPatientPremium}
                                        error={!!errors['deposit']}
                                        helperText={errors['deposit']}
                                        onChange={(v) => handleChanges(v.target.id, v.target.value)}
                                    />
                                </Box>
                            </Grid>
                            <Grid item md={6} sm={12}>
                                <Box>
                                    <FormControl
                                        variant="outlined"
                                        color='primary'
                                        required
                                        error={!!errors[`date`]}
                                        size="small"
                                        disabled={isAdding || !isPatientPremium}
                                        classes={{ root: classes.root }}
                                        fullWidth>
                                        <Box mb={1}>
                                            <span className={commonClasses.label}>Start Date</span>
                                        </Box>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                id={`selectedDate`}
                                                fullWidth
                                                size="small"
                                                placeholder="MM/DD/YYYY"
                                                format="MM/DD/YYYY"
                                                openTo="year"
                                                value={selectedDate}
                                                helperText={null}
                                                error={!!errors[`selectedDate`]}
                                                inputVariant="outlined"
                                                disabled={isAdding || !isPatientPremium}
                                                views={["year", "month", "date"]}
                                                onClick={(e) => e.stopPropagation()}
                                                onChange={(momentDate) => handleChangeDate(momentDate === null ?
                                                    "" : momentDate.toDate())}
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date",
                                                    "edge": "end",
                                                }}
                                                keyboardIcon={<CalendarIcon fill={colors.gray500}/>}
                                                minDate={new Date()}
                                                onBlur={() => handleOnBlurValidate('selectedDate')}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item md={12}>
                                <Box display="flex" justifyContent="flex-end" mb={3}>
                                    <WildHealthButton
                                        id="review-for-manual-payment"
                                        color="primaryV"
                                        disabled={isAdding || Object.keys(errors).length > 0}
                                        onClick={() => handleOpenReview()}
                                    >
                                        Review
                                    </WildHealthButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </>}
                <Dialog
                    open={isOpenReview}
                    onClose={() => handleCloseReview()}
                    className={classes.dialog}
                >
                    <DialogTitle
                        id='dialog-title'
                        onClose={() => handleCloseReview()}
                    >
                        <Box px={2} className="wh-tw-text-2xl wh-tw-font-medium wh-tw-font-poppins">Premium Billing Review</Box>
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <Box mb={2} px={1.5} className="wh-tw-text-xl wh-tw-font-medium wh-tw-font-poppins">Schedule Items</Box>
                        <Box width={1} px={1.5}>
                            <Grid container spacing={2}>
                                <Grid item md={6} sm={12}>
                                    <Box>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            type="number"
                                            InputProps={{ className: 'input', classes }}
                                            id="downPayment"
                                            placeholder="Initial Payment"
                                            value={manualPayment?.downPayment}
                                            variant="outlined"
                                            disabled={isAdding || !isPatientPremium || manualPayment?.id > 0}
                                            error={!!errors['downPayment']}
                                            helperText={errors['downPayment']}
                                            onChange={(v) => handleChangeDownPayment(v.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item md={6} sm={12}>
                                    <Box>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            InputProps={{ className: 'input', classes }}
                                            inputProps={{
                                                style: { textAlign: 'right' }
                                            }}
                                            id="date"
                                            placeholder="MM/DD/YYYY"
                                            value={moment(new Date()).format('MM/DD/YYYY')}
                                            variant="outlined"
                                            disabled
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            {manualPayment.scheduleItems.map((item, index) => (
                                <Grid container spacing={2} key={index}>
                                    <Grid item md={6} sm={12}>
                                        <Box>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                required
                                                type="number"
                                                InputProps={{ className: 'input', classes }}
                                                id="amount"
                                                placeholder="Schedule Item Amount"
                                                value={item.amount}
                                                variant="outlined"
                                                disabled={isAdding || !isPatientPremium || manualPayment?.id > 0}
                                                onChange={(v) => handleChangeItemAmount(v.target.value)}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item md={6} sm={12}>
                                        <Box>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                InputProps={{ className: 'input', classes }}
                                                inputProps={{
                                                    style: { textAlign: 'right' }
                                                }}
                                                id="date"
                                                placeholder="MM/DD/YYYY"
                                                value={moment(item.dueDate).format('MM/DD/YYYY')}
                                                variant="outlined"
                                                disabled
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            ))}
                            {manualPayment?.deposit > 0 && (
                                <Grid container spacing={2}>
                                    <Grid item md={6} sm={12}>
                                        <Box>
                                            <TextField
                                                fullWidth
                                                size="small"
                                                type="number"
                                                InputProps={{ className: 'input', classes }}
                                                id="deposit"
                                                placeholder="Consult Deposit"
                                                value={manualPayment?.deposit}
                                                variant="outlined"
                                                disabled
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item md={6} sm={12}>
                                        <Box p={1} className="wh-tw-text-lg wh-tw-font-medium wh-tw-font-poppins">Consult Deposit</Box>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid container spacing={2}>
                                <Grid item md={6} sm={12}>
                                    <Box>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            type="number"
                                            InputProps={{ className: 'input', classes }}
                                            id="total"
                                            placeholder="Total Amount due"
                                            value={manualPayment?.total}
                                            variant="outlined"
                                            disabled
                                        />
                                    </Box>
                                </Grid>
                                <Grid item md={6} sm={12}>
                                    <Box p={1} className="wh-tw-text-lg wh-tw-font-medium wh-tw-font-poppins">Total Amount ($)</Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                    <DialogActions className={classes.dialogAction}>
                        <Box my={2}>
                            <WildHealthButton id={`cancel-dialog`} borderRadius={50} color="secondary" size="large" onClick={() => handleCloseReview()}>
                                {manualPayment?.id > 0 ? 'Close' : 'Cancel'}
                            </WildHealthButton>
                        </Box>
                        {!manualPayment?.id && <ProtectedElement
                            element={
                                <Box my={2} px={1.5}>
                                    <WildHealthButton id={`manual-payment-submit`} borderRadius={50} size="large" disabled={isAdding || Object.keys(errors).length > 0} onClick={() => handleSubmitForm()}>
                                        Submit
                                    </WildHealthButton>
                                </Box>
                            }
                            permissions={[PermissionType.PremiumBilling]}
                        />}
                    </DialogActions>
                </Dialog>
            </>
        )
    }

    if (!isPatientPremium) {
        return <></>
    }

    return (
        <Grid item xs={12}>
            <Box px={5}>
                {
                    manualPayment ? renderContent() : (
                        <Box py={2}>
                            <ProtectedElement
                                element={
                                    <WildHealthButton
                                        id="add-premium-billing"
                                        onClick={() => handleToggle()}
                                    >
                                        Add Premium Billing
                                    </WildHealthButton>
                                }
                                permissions={[PermissionType.PremiumBilling]}
                            />
                        </Box>
                    )
                }
            </Box>
        </Grid>
    );
}
