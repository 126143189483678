import React from "react";
import { Box, IconButton, Popover, Table, TableBody, TableContainer, TableFooter, TableHead } from "@material-ui/core";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Add } from "@material-ui/icons";
import clsx from "clsx";
import moment from "moment";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { useFacade } from './medicationsComponent.hooks';
import { MedicationAddNewDialogComponent } from '../MedicationsEditDialogComponent/MedicationAddNewDialogComponent';
import { MedicationEditDialogComponent } from '../MedicationsEditDialogComponent/MedicationEditDialogComponent';
import { MedicationItem } from "../../../notes/models/notes.models";
import { WildHealthMenuItem } from "../../../common/components/wildHealthMenu/WildHealthMenu";
import commonUseStyles from "../../../common/styles/common.styles";
import { toCurrentTimeZone } from "../../../timezones/helpers/timezone";

interface MedicationsComponentProps {
    items: MedicationItem[];
    handleAddMedications: (medication: MedicationItem) => void;
    handleEditMedications: (medication: MedicationItem) => void;
    handleRemoveMedications: (id: number) => void;
}

export const MedicationsComponent: React.FC<MedicationsComponentProps> = (props: MedicationsComponentProps) => {
    const {
        items,
        handleAddMedications,
        handleEditMedications,
        handleRemoveMedications,
    } = props;

    const [
        {
            isMenuOpened,
            isAddNewOpened,
            isEditorOpened,
            anchorEl,
            selectedId,
        },
        handleAddNewToggle,
        handleEditToggle,
        handleToggleActions,
    ] = useFacade();

    const classes = commonUseStyles();

    const selectedItem = items.find(i => i.id === selectedId);

    const displayDataValue = (value: Date) => {
        return value ? moment(toCurrentTimeZone(value)).format("MM/DD/yyyy") : 'N/A';
    }

    return (
        <Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <WildHealthTableRow>
                            <WildHealthTableCell>
                                Name
                            </WildHealthTableCell>
                            <WildHealthTableCell>
                                Strength
                            </WildHealthTableCell>
                            <WildHealthTableCell>
                                Dosing Instruction
                            </WildHealthTableCell>
                            <WildHealthTableCell>
                                Start Date
                            </WildHealthTableCell>
                            <WildHealthTableCell style={{ width: '20%' }}></WildHealthTableCell>
                        </WildHealthTableRow>
                    </TableHead>
                    <TableBody>
                        {
                            items.map((item, index) =>
                                <WildHealthTableRow key={index}>
                                    <WildHealthTableCell>
                                        {item.isStopped ? <s>{item.name}</s> : item.name}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell>
                                        {item.dosage}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell>
                                        {item.instructions}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell>
                                        {displayDataValue(item.startDate)}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell>
                                        <IconButton
                                            id="medications-more-actions"
                                            size="small"
                                            onClick={(e) => handleToggleActions(item.id, e.currentTarget)}
                                        >
                                            <MoreHorizIcon />
                                        </IconButton>
                                    </WildHealthTableCell>
                                </WildHealthTableRow>
                            )
                        }
                    </TableBody>
                    <TableFooter>
                        <Box display='flex' alignItems='stretch' py={1}>
                            <Box onClick={() => handleAddNewToggle(true)}>
                                <Add className={clsx(classes.colorMain, classes.backgroundGray3, classes.pointer)} />
                            </Box>
                            <Box className={classes.colorMain} ml={2}>
                                <span>Add Medication</span>
                            </Box>
                        </Box>
                    </TableFooter>
                </Table>
            </TableContainer>

            <Popover
                keepMounted
                anchorEl={anchorEl}
                open={isMenuOpened}
                onClose={() => handleToggleActions()}
                getContentAnchorEl={null}
            >
                <WildHealthMenuItem id="medications-edit" onClick={() => handleEditToggle(true)}>
                    Edit
                </WildHealthMenuItem>
                <WildHealthMenuItem id="medications-delete" onClick={() => { handleRemoveMedications(selectedId); handleToggleActions(0); }}>
                    Delete
                </WildHealthMenuItem>
            </Popover>

            <MedicationAddNewDialogComponent
                isOpened={isAddNewOpened}
                initModel={{
                    id: 0,
                    name: '',
                    dosage: '',
                    instructions: '',
                    startDate: new Date(),
                    isInCurrent: false,
                    isStopped: false,
                    source: null
                }}
                handleSave={handleAddMedications}
                handleClose={() => handleAddNewToggle(false)}
            />
            <MedicationEditDialogComponent
                isOpened={isEditorOpened}
                initModel={selectedItem}
                handleSave={handleEditMedications}
                handleClose={() => handleEditToggle(false)}
            />
        </Box>
    )
}
