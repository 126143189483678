import { PatientRescheduleAppointmentDialogComponent } from '../patientRescheduleAppointmentDialog/PatientRescheduleAppointmentDialogComponent';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { AppointmentCardsComponent } from '../appointmentCards/AppointmentCardsComponent';
import { useFacade } from './patientUpcomminAppointmentsComponent.hooks';
import commonUseStyles from '../../../common/styles/common.styles';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { MobileAppointmentCardsComponent } from '../appointmentCards/MobileAppointmentCardsComponent';
import { WildHealthTabControl } from "../../../common/components/wildHealthTabControl/WildHealthTabControl";
import { TabItem } from "../../../common/components/wildHealthTabControl/wildHealthTabControl.hooks";
import { useStylesFullWidthTabs } from "../../../common/components/wildHealthTabControl/wildHealthTabControl.styles";
import { AppointmentWithType } from '../../models/appointments.enums';
import { CancelAppointmentDialogComponent } from '../cancelAppointmentDialogComponent/CancelAppointmentDialogComponent';
import { EmployeeType } from '../../../employee/models/employee.enums';
import { StandardUpsellWidgetComponent } from '../../../widgets/components/standardUpsellWidgetCompoent/StandardUpsellWidgetComponent';

interface PatientUpcommingAppointmentsComponent {
    patientId?: number;
}

export const PatientUpcommingAppointmentsComponent: React.FC<PatientUpcommingAppointmentsComponent> = (props: PatientUpcommingAppointmentsComponent) => {
    const { patientId } = props;
    const [
        {
            appointments,
            assignedEmployees,
            availableTypes,
            locations,
            isLoading,
            isPhotosLoading,
            timeZone,
            isBuyProductsLink,
            subscription,
            appointmentsSummary,
        },
        handleAddAppointmentToCalendar,
        handleCancelAppointment,
        handleEditAppointment,
        handleJoinAppointment,
        handleBuyProducts,
    ] = useFacade(patientId);


    const commonClasses = commonUseStyles(patientId);
    const tabsStyle = useStylesFullWidthTabs();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const coachSectionWithTypes: Array<AppointmentWithType> = [AppointmentWithType.HealthCoach];
    const providerSectionWithTypes: Array<AppointmentWithType> = [AppointmentWithType.HealthCoachAndProvider, AppointmentWithType.Provider];

    const renderAppointments = (): JSX.Element => {
        if (isLoading || isPhotosLoading || !appointments) {
            return (<WildHealthLinearProgress />);
        }

        return (
            <AppointmentCardsComponent
                appointments={appointments}
                subscription={subscription}
                appointmentsSummary={appointmentsSummary}
                assignedEmployees={assignedEmployees}
                appointmentTypes={availableTypes}
                locations={locations}
                handleAddAppointmentToCalendar={handleAddAppointmentToCalendar}
                handleCancelAppointment={handleCancelAppointment}
                handleEditAppointment={handleEditAppointment}
                handleJoinAppointment={handleJoinAppointment}
                handleBuyProducts={handleBuyProducts}
                timeZone={timeZone}
                patientId={patientId}
                isBuyProductsLink={isBuyProductsLink}
            />
        );
    }

    const renderContent = () => {
        if (isSmallScreen) {
            const tabs: TabItem[] = [
                {
                    title: "Health Coach",
                    content: (
                        <MobileAppointmentCardsComponent
                            isLoading={isLoading || isPhotosLoading}
                            subscription={subscription}
                            appointmentsSummary={appointmentsSummary}
                            personType={EmployeeType.HealthCoach}
                            withTypes={coachSectionWithTypes}
                            appointments={appointments}
                            assignedEmployees={assignedEmployees}
                            appointmentTypes={availableTypes}
                            locations={locations}
                            handleAddAppointmentToCalendar={handleAddAppointmentToCalendar}
                            handleCancelAppointment={handleCancelAppointment}
                            handleEditAppointment={handleEditAppointment}
                            handleJoinAppointment={handleJoinAppointment}
                            handleBuyProducts={handleBuyProducts}
                            timeZone={timeZone}
                            patientId={patientId}
                            isBuyProductsLink={isBuyProductsLink}
                        />
                    ),
                },
                {
                    title: "Provider",
                    content: (
                        <MobileAppointmentCardsComponent
                            isLoading={isLoading || isPhotosLoading}
                            subscription={subscription}
                            appointmentsSummary={appointmentsSummary}
                            personType={EmployeeType.Provider}
                            withTypes={providerSectionWithTypes}
                            appointments={appointments}
                            assignedEmployees={assignedEmployees}
                            appointmentTypes={availableTypes}
                            locations={locations}
                            handleAddAppointmentToCalendar={handleAddAppointmentToCalendar}
                            handleCancelAppointment={handleCancelAppointment}
                            handleEditAppointment={handleEditAppointment}
                            handleJoinAppointment={handleJoinAppointment}
                            handleBuyProducts={handleBuyProducts}
                            timeZone={timeZone}
                            patientId={patientId}
                            isBuyProductsLink={isBuyProductsLink}
                        />
                    ),
                },
            ];
            return (
                <Box width="100%" mt={2}>
                    <WildHealthTabControl items={tabs} className={tabsStyle} />
                </Box>
            );
        } else {
            return (
                <Box pt="30px">
                    {
                        renderAppointments()
                    }
                </Box>
            );
        }
    };

    return (
        <Box p={isSmallScreen ? 2 : "30px"} className={!isSmallScreen && commonClasses.bgWhiteMain}>
            <PatientRescheduleAppointmentDialogComponent patientId={patientId} />
            <CancelAppointmentDialogComponent />
            {!patientId && <StandardUpsellWidgetComponent allowInline />}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box>
                    <span className={clsx(commonClasses.size24, commonClasses.textSemiBold)}>Upcoming Appointments</span>
                </Box>
            </Box>
            {
                renderContent()
            }
        </Box>
    );
}