import React from 'react';
import { PatientPastAppointmentsComponent } from '../../components/patientPastAppointmentsComponent/PatientPastAppointmentsComponent';
import { AuthenticatedLayoutComponent } from '../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from '../../../../models/menu.models';
import { withRouter } from 'react-router';

export const PatientPastAppointmentsPage: React.FC = () => {
    return (
        <>
            <AuthenticatedLayoutComponent
                title={TitlesService.appointmentsTitle}
                pageName={PagesNamesService.appointments}
                selectedMenuItem={MenuItemTypes.PastAppointments}
                hideBackground
            >
                <PatientPastAppointmentsComponent />
            </AuthenticatedLayoutComponent>
        </>
    );
}

export default withRouter(PatientPastAppointmentsPage);
