import {
  notWhitespaceValidationRule,
  stringLengthValidationRule,
  requiredValidationRule,
  notNegativeValidationRule
} from "../../../common/validation/general-validation-rules";
import { GeneralValidator } from "../../../common/validation/general-validator";

export const addNewLabPricingComponentValidator = new GeneralValidator({
  'integrationId': [requiredValidationRule(), stringLengthValidationRule(0, 100), notWhitespaceValidationRule()],
  'name': [requiredValidationRule(), notWhitespaceValidationRule()],
  'price': [notNegativeValidationRule(), requiredValidationRule()],
});
