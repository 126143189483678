import React, { useEffect, useRef, useState } from 'react';
import Recaptcha from 'react-google-invisible-recaptcha';
import { useHistory } from 'react-router';
import { Subscription } from 'rxjs';
import { navigationService } from "../../../../services/navigation.service";
import { AddOnModel, SelectAddOnModel } from "../../../addons/models/addOns.models";
import { addOnsService } from "../../../addons/services/addOns.service";
import { addOnsQuery } from "../../../addons/stores";
import { getLastObject } from "../../../common/helpers/get-last-object";
import { onEmit } from "../../../common/helpers/on-emit";
import { handleCompare } from "../../../common/sorting/helpers/handle-compare";
import { SortingDirection } from "../../../common/sorting/models/sorting-destination";
import { IErrorState } from "../../../common/validation/error-state";
import { LeadSourceModel, PatientLeadSourceModel } from "../../../leadSources/models/leadSource.models";
import { leadSourcesService } from "../../../leadSources/services/leadSources.service";
import { leadSourcesQuery } from "../../../leadSources/stores/leadSources.query";
import { RegisterPatientModel } from "../../../patients/models/patient.model";
import { patientsService } from "../../../patients/services/patients.service";
import { AgreementModel } from '../../../payment/models/agreement.model';
import { PaymentPeriodModel } from '../../../payment/models/paymentPeriod.models';
import { PaymentPlanModel } from '../../../payment/models/paymentPlan.models';
import { PaymentPriceModel, PaymentStrategy } from '../../../payment/models/paymentPrice.models';
import { ApplyCouponModel, MerchantCredentialsModel, PayerInformation } from "../../../payment/models/payment.models"
import { paymentPlansQuery, paymentPlansStore } from "../../../payment/stores/paymentPlansStore";
import { paymentPlansService } from "../../../payment/services/paymentPlans.service";
import { fellowshipCheckoutComponentValidator } from './fellowshipCheckoutComponentValidator';
import { paymentQuery } from "../../../payment/stores/paymentStore";
import { paymentService } from "../../../payment/services/payment.service";
import { PaymentFormComponent } from "../../../payment/components/paymentFormComponent/PaymentFormUpdatedComponent";
import { ConfirmAgreementsCheckBoxComponent } from "../../../payment/components/confirmAgreementsCheckBoxComponent/ConfirmAgreementsCheckBoxComponent";
import { employeesStore } from '../../../employee/stores/employeesStore';
import { authQuery } from "../../../auth/stores/auth";
import { addressQuery } from '../../../account/stores/addressStore';
import { SITE_KEY } from "../../../../config";
import { OrderType } from "../../../orders/models/orders.models";

const context = {};

export const howDidYouHeardAboutUs = [
    'Ben Greenfield',
    'Online Search',
    'Online Ad',
    'Word Of Mouth',
];

/**
 * Represents payment form state
 */
interface FellowshipCheckoutComponentState extends IErrorState {
    practiceId: number | null;
    publicKey: string | null;
    provider: string | null;
    paymentPlan: PaymentPlanModel;
    paymentPeriod: PaymentPeriodModel;
    paymentPrice: PaymentPriceModel;
    isSubmitted: boolean;
    payer: PayerInformation;
    addOns: SelectAddOnModel[];
    couponCode: string;
    isCouponApplied: boolean;
    agreements: AgreementModel[];
    selectedLeadSource: PatientLeadSourceModel;
    leadSources: LeadSourceModel[];
    exclusivePrices: PaymentPriceModel[];
}

const getDefaultPaymentPrice = (period: PaymentPeriodModel): PaymentPriceModel => {
    const exclusivePrice = period.prices.find(x => x.isExclusive);
    if (exclusivePrice) {
        return exclusivePrice;
    }

    const annualPrice = period.prices.find(x => x.strategy === PaymentStrategy.FullPayment);
    return annualPrice
        ? annualPrice
        : period.prices[0];
}

/**
 * Custom Hook to manage a view Model for SelectPlan page view components
 */
export function useFacade(goBack: Function): [
    FellowshipCheckoutComponentState,
    (id: number) => void,
    (field: string, value: string) => void,
    () => JSX.Element,
    () => JSX.Element,
    (id: number, value: boolean) => void,
    () => SelectAddOnModel[],
    (value: string) => void,
    (code: string) => void,
    (field: string, value: string) => void] {

    const emptyAddress = {
        country: '',
        city: '',
        state: '',
        zipCode: '',
        streetAddress1: '',
        streetAddress2: '',
    }

    const history = useHistory();
    const paymentPlan = paymentPlansQuery.getSelectedPlan();
    const paymentPeriod = paymentPlansQuery.getSelectedPeriod();
    const paymentPrice = getDefaultPaymentPrice(paymentPeriod);
    const couponCode = paymentPlansQuery.getCouponCode();
    const isCouponApplied = paymentPlansQuery.isPaymentCouponApplied();
    const [paymentToken, setPaymentToken] = useState('');
    const recaptcha = useRef(null);
    const [state, setState] = useState({
        practiceId: null,
        publicKey: null,
        payer: {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            birthday: null,
            gender: 99,
            password: '',
            confirmPassword: '',
            billingAddress: Object.assign({}, emptyAddress),
            shippingAddress: Object.assign({}, emptyAddress)
        },
        isSubmitted: false,
        errors: {},
        paymentPlan,
        paymentPeriod,
        paymentPrice,
        addOns: [],
        couponCode: couponCode,
        isCouponApplied: isCouponApplied,
        agreements: paymentPeriod.agreements,
        selectedLeadSource: {
            leadSourceId: 0,
            otherLeadSource: ""
        },
        leadSources: [],
        exclusivePrices: []
    } as FellowshipCheckoutComponentState);

    const handleSelectingAddOn = (id: number, value: boolean) => {
        const addOns = state.addOns;
        const addOn = addOns.find(x => x.id === id);
        addOn.isSelected = value;

        setState({ ...state, addOns });
    }

    const handlePriceSelect = (id: number) => {
        const paymentPrice = paymentPeriod.prices.find(x => x.id === id);
        setState({ ...state, paymentPrice });
    }

    const getSelectedAddOns = () => {
        return state.addOns.filter(i => i.isSelected);
    }

    const getSelectedAddOnsPrice = () => {
        return getSelectedAddOns().reduce((a, b) => a + b.price, 0);
    }

    const handleCouponChanges = (value: string) => {
        setState({ ...state, couponCode: value });
    }

    const applyCoupon = (code: string) => {
        const model: ApplyCouponModel = {
            paymentPeriodId: state.paymentPeriod.id,
            paymentPriceType: state.paymentPrice.type,
            code: code
        };

        const action = paymentPlansService.newPromoCodeApply(model);

        action.subscribe((exclusivePrices: PaymentPriceModel[]) => {
            if (exclusivePrices && exclusivePrices.length) {
                const adjustedPrices = paymentPeriod.prices.map(price => {
                    const exclusivePrice = exclusivePrices.find(x => x.strategy === price.strategy);
                    return exclusivePrice ?? price;
                });
                // we need the complete coupon model
                const retrievedCoupon = exclusivePrices[0].paymentCoupon;
    
                const newSelectedPeriod = Object.assign(paymentPeriod, {
                    prices: adjustedPrices
                });
    
                const addOns = state.addOns;
    
                const freeAddOnsPrice = adjustedPrices.find(x => x.isAddOnsForFree);
                if (freeAddOnsPrice) {
                    addOns.forEach(x => {
                        if (freeAddOnsPrice.freeAddOnsTypes.includes(x.orderType)) {
                            x.price = 0;
                        }
                    });
                }
    
                const paymentPrice = newSelectedPeriod
                    .prices
                    .find(x => x.paymentCoupon?.code === retrievedCoupon.code);
    
                if (paymentPrice) {
                    setState(state => ({
                        ...state,
                        couponCode: code,
                        paymentPeriod: newSelectedPeriod,
                        paymentPrice: paymentPrice,
                        addOns: addOns,
                        isCouponApplied: true,
                        exclusivePrices: exclusivePrices
                    }));
        
                    paymentPlansStore.setCouponApplied(retrievedCoupon);
                    paymentPlansService.updateSelectedPeriod(newSelectedPeriod);
                }
            }
        }, () => {
        });
    }

    const handleChanges = (field: string, value: any) => {
        updateValidationState(field, value);
        const payer = state.payer;
        const keys = field.split(".");
        const key = keys.pop();
        const lastObject = getLastObject(keys, payer);
        lastObject[key] = value;

        setState({ ...state, payer: Object.assign({}, payer) });

        if (field === 'gender') {
            addOnsService.getOptional(paymentPlan.id, value);
        }
    }

    const handleLeadSourceChanges = (field: string, value: any) => {
        updateValidationState(field, value);
        const keys = field.split(".");
        const key = keys.pop();
        const lastObject = getLastObject(keys, state);
        lastObject[key] = key === "leadSourceId" ? +value : value;

        setState({ ...state, selectedLeadSource: state.selectedLeadSource });
    }

    const updateValidationState = (field: string, value: any) => {
        fellowshipCheckoutComponentValidator.validateAndSetState(state, setState, field, value);
    }

    const executeRecaptcha = () => {
        recaptcha.current?.reset();
        recaptcha.current?.execute()
    }

    const renderRecaptcha = () => {
        context['state'] = state;
        context['paymentToken'] = paymentToken;

        return <Recaptcha
            ref={recaptcha}
            sitekey={SITE_KEY}
            onResolved={(token) => recaptchaOnResolved(token, context)}
        />
    }

    const scrollToErrorField = (state) => {
        const errors = Object.keys(state.errors);

        if (!errors.length) return;

        const firstInvalidField = errors[0];
        const top = document.getElementById(firstInvalidField).getBoundingClientRect().top;

        const approximateVisibleOffset = 200;

        window.scrollBy({
            top: top - approximateVisibleOffset,
            behavior: 'smooth'
        });
    }

    const recaptchaOnResolved = (antiSpamToken: string, context) => {
        const state = context['state'];
        const paymentToken = context['paymentToken'];
        const fellow = employeesStore.getValue().selectedFellow;
        const linkedFellow = employeesStore.getValue().linkedFellow;

        const patient: RegisterPatientModel = {
            inviteCode: null,
            practiceId: state.practiceId,
            paymentToken: paymentToken,
            antiSpamToken: antiSpamToken,
            email: state.payer.email.trim(),
            phoneNumber: state.payer.phoneNumber,
            firstName: state.payer.firstName,
            lastName: state.payer.lastName,
            gender: Number(state.payer.gender),
            birthday: state.payer.birthday,
            password: state.payer.password,
            confirmPassword: state.payer.confirmPassword,
            paymentPeriodId: state.paymentPeriod.id,
            paymentPriceId: state.paymentPrice.id,
            agreements: state.paymentPeriod.agreements.map(x => ({ agreementId: x.id, isConfirmed: true })),
            billingAddress: state.payer.billingAddress,
            shippingAddress: {...state.payer.billingAddress, state: addressQuery.findState(state.payer.billingAddress?.state)?.abbreviation},
            addOnIds: state.addOns.filter(x => x.isSelected).map(x => x.id),
            founderId: null,
            leadSource: state.selectedLeadSource.leadSourceId !== 0 ? state.selectedLeadSource : null,
            employeeId: fellow?.id,
            linkedEmployeeId: linkedFellow?.id,
            // TODO: ask how Product want to handle that
            marketingSMS: false,
            employerProductKey: null
        }

        patientsService.register(patient).subscribe(
            patient => {
                navigationService.toPaymentSuccess(history, patient);
            },
            () => {
                setState({
                    ...state,
                    isSubmitted: false
                });
            }
        );
    }

    const validateForm = (): boolean => {
        fellowshipCheckoutComponentValidator.validateAndSetState(state, setState, "firstName", state.payer.firstName);
        fellowshipCheckoutComponentValidator.validateAndSetState(state, setState, "lastName", state.payer.lastName);
        fellowshipCheckoutComponentValidator.validateAndSetState(state, setState, "birthday", state.payer.birthday);
        fellowshipCheckoutComponentValidator.validateAndSetState(state, setState, "gender", state.payer.gender);
        fellowshipCheckoutComponentValidator.validateAndSetState(state, setState, "phoneNumber", state.payer.phoneNumber);
        fellowshipCheckoutComponentValidator.validateAndSetState(state, setState, "email", state.payer.email);
        fellowshipCheckoutComponentValidator.validateAndSetState(state, setState, "password", state.payer.password);
        fellowshipCheckoutComponentValidator.validateAndSetState(state, setState, "confirmPassword", state.payer.confirmPassword);
        fellowshipCheckoutComponentValidator.validateAndSetState(state, setState, "billingAddress.streetAddress1", state.payer.billingAddress.streetAddress1);
        fellowshipCheckoutComponentValidator.validateAndSetState(state, setState, "billingAddress.streetAddress2", state.payer.billingAddress.streetAddress2);
        fellowshipCheckoutComponentValidator.validateAndSetState(state, setState, "billingAddress.city", state.payer.billingAddress.city);
        fellowshipCheckoutComponentValidator.validateAndSetState(state, setState, "billingAddress.country", state.payer.billingAddress.country);
        fellowshipCheckoutComponentValidator.validateAndSetState(state, setState, "billingAddress.state", state.payer.billingAddress.state);
        fellowshipCheckoutComponentValidator.validateAndSetState(state, setState, "billingAddress.zipCode", state.payer.billingAddress.zipCode);


        const leadSource = state.leadSources.find(x => x.id === state.selectedLeadSource.leadSourceId);
        if (leadSource !== undefined && leadSource.isOther)
            fellowshipCheckoutComponentValidator.validateAndSetState(state, setState, "selectedLeadSource.otherLeadSource", state.selectedLeadSource.otherLeadSource);

        const isValid = fellowshipCheckoutComponentValidator.stateIsValid(state);

        !isValid && scrollToErrorField(state);

        return isValid;
    }

    const renderPaymentForm = () => {
        const price = state.paymentPrice.price + state.paymentPrice.startupFee + getSelectedAddOnsPrice();

        return (
            <PaymentFormComponent
                needCardData={true}
                canSubmit={fellowshipCheckoutComponentValidator.stateIsValid(state)}
                validate={validateForm}
                isSubmitted={state.isSubmitted}
                totalPrice={price}
                publicKey={state.publicKey}
                provider={state.provider}
                confirmAgreements={<ConfirmAgreementsCheckBoxComponent
                    paymentPlan={state.paymentPlan}
                    paymentPeriod={state.paymentPeriod}
                    paymentPrice={state.paymentPrice}
                />}
                callback={(paymentToken: string) => {
                    handlePaymentSubmit(paymentToken);
                }}
                goBack={goBack}
            />
        )
    }

    /**
     * Handles payment submit
     * @param paymentToken
     */
    const handlePaymentSubmit = (paymentToken: string) => {
        setState({ ...state, isSubmitted: true });
        setPaymentToken(paymentToken);
        executeRecaptcha();
    };

    /**
     * Load all payment plans
     * Manage subscriptions with auto-cleanup
     */
    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<MerchantCredentialsModel>(paymentQuery.credentials$, credentials => {
                setState(state => ({
                    ...state,
                    publicKey: credentials?.publicKey,
                    provider: credentials?.provider,
                    practiceId: credentials?.practiceId
                }));
            }
            ),
            onEmit<PaymentPlanModel>(paymentPlansQuery.selectedPlan$, selectedPlan => {
                setState(state => ({ ...state, selectedPlan }));
            }),
            onEmit<AddOnModel[]>(addOnsQuery.addOns$, addOns => {
                const items = addOns.map(i => {
                    return {
                        ...i,
                        isSelected: i.orderType === OrderType.Lab
                    }
                });

                let isCouponApplied=false;
                if(state.isCouponApplied && !!state.exclusivePrices){
                    const addOns = items;
                    const freeAddOnsPrice = state.exclusivePrices.find(x => x.isAddOnsForFree);
                    if (freeAddOnsPrice) {
                        addOns.forEach(x => {
                            if (freeAddOnsPrice.freeAddOnsTypes.includes(x.orderType)) {
                                x.price = 0;
                            }
                        });
                    }
                    isCouponApplied = true;
                }
                setState(state => ({ ...state, addOns: items, isCouponApplied: isCouponApplied }));
            }),
            onEmit<LeadSourceModel[]>(leadSourcesQuery.leadSources$, leadSources => {
                setState(state => ({
                    ...state,
                    leadSources: leadSources.sort((l1, l2,) => handleCompare(l1, l2, SortingDirection.Desc, 'isOther'))
                }));
            }),
        ];

        leadSourcesService.getActive(authQuery.getCurrentPracticeId());
        paymentService.getCredentials(authQuery.getCurrentPracticeId());
        addOnsService.getOptional(paymentPlan.id, state.payer.gender);

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [paymentPlan, state.payer.gender]);

    return [
        state,
        handlePriceSelect,
        handleChanges,
        renderPaymentForm,
        renderRecaptcha,
        handleSelectingAddOn,
        getSelectedAddOns,
        handleCouponChanges,
        applyCoupon,
        handleLeadSourceChanges
    ]
}
