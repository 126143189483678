import {QuestionnaireModel, QuestionnaireResultModel} from "../../models/questionnaire.models";
import React from "react";
import commonUseStyles from "../../../common/styles/common.styles";
import {QuestionnaireSectionComponent} from "../questionnaireSectionComponent/QestionnaireSectionComponent";
import {Gender} from "../../../common/models/user.models";
import {Box} from "@material-ui/core";
import clsx from "clsx";

export interface Phq8SectionComponentProps {
    questions: string[]
    questionnaire: QuestionnaireModel;
    questionnaireResult: QuestionnaireResultModel;
    handleChanges: (field: string, value: string) => void;
    errors: any,
}


export const Phq8SectionComponent: React.FC<Phq8SectionComponentProps> = (props: Phq8SectionComponentProps) => {
    const {
        questions,
        questionnaire,
        questionnaireResult,
        handleChanges,
        errors
    } = props;

    const commonClasses = commonUseStyles();

    return (
        <>
            <Box>
                <span className="wh-tw-text-base wh-tw-font-medium">
                    In the last two weeks, have you been bothered by any of the following problems:
                </span>
            </Box>
            <Box mt={1.25}>
                <span className={clsx(commonClasses.italic, commonClasses.size14)}>
                    If you are experiencing an emergency, please call 911. We are unable to support emergency situations via telemedicine. Additionally, the following resources are available to you at any time:
                </span>
            </Box>
            <Box ml={4}>
                <ul style={{listStyleType: 'disc'}}>
                    <li><span className={clsx(commonClasses.italic, commonClasses.size14)}>988 Suicide & Crisis Lifeline: Dial 988 or 1-800-273-8255</span></li>
                    <li><span className={clsx(commonClasses.italic, commonClasses.size14)}>Crisis Text Line: Text ‘Home’ to 741-741</span></li>
                    <li><span className={clsx(commonClasses.italic, commonClasses.size14)}>Access help confidentially by dialing 211</span></li>
                    <li><span className={clsx(commonClasses.italic, commonClasses.size14)}>SAMHSA National Helpline: 1-800-662-HELP (4357)</span></li>
                    <li><span className={clsx(commonClasses.italic, commonClasses.size14)}>www.samhsa.gov</span></li>
                </ul>
            </Box>
            <QuestionnaireSectionComponent
                questionnaire={questionnaire}
                result={questionnaireResult}
                questions={questions}
                handleChanges={handleChanges}
                errors={errors}
                gender={Gender.None}
                hideSubject
            />
        </>
    )
}
