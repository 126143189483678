import React from 'react';
import { useFacade } from './selectAppointmentToNotesDialog.hooks';
import {Box, Dialog, DialogActions, DialogContent, DialogContentText, Grid, useMediaQuery, useTheme} from "@material-ui/core";
import commonUseStyles from '../../../common/styles/common.styles';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import clsx from 'clsx';
import { WildHealthRadio } from '../../../common/components/WildHealthRadio';
import { useStyles } from './selectAppointmentToNotesDialog.styles';
import { AppointmentPurposeType } from '../../../appointments/models/appointments.enums';
import { DialogTitle } from '../../../common/components/dialogTitle/DialogTitle';
import {ConfigureNoteAppointmentDialog} from "../configureNoteAppointmentComponent/ConfigureNoteAppointmentDialog";
import {patientsQuery} from "../../../patients/stores/patientsStore";
import moment from "moment";

interface SelectAppointmentToNotesProps {
    patientId: number;
    isOpen: boolean;
    handleSelectAppointmentDialog: Function;
    handleChangeSelectAppointment: Function;
    handleSubmitSelectAppointment: Function;
    handleConfigureAppointment?: Function;
}

export const SelectAppointmentToNotesDialog: React.FC<SelectAppointmentToNotesProps> = (props: SelectAppointmentToNotesProps) =>{
    const [state, handleAppointment, handleNoAppointment] = useFacade(props.patientId);

    const commonClasses = commonUseStyles();

    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <>
            <Dialog
                fullWidth
                fullScreen={fullScreen}
                maxWidth="md"
                open={props.isOpen}
                onClose={() => props.handleSelectAppointmentDialog(false)}
            >
                <DialogTitle
                    id="dialog-title"
                    classes={{ root: classes.dialogTitle }}
                    onClose={() => props.handleSelectAppointmentDialog(false)}
                >
                    <span className={clsx(commonClasses.size24, commonClasses.textMedium)}>Select Appointment</span>
                    <br />
                    <span className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorGray1)}>Please choose the appointment below to attach the note to. </span>
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    <DialogContentText>
                        {
                            props.isOpen && 
                            <Grid container spacing={4}>
                                {
                                state.appointments.map((appointment, i) => (
                                        <Grid item md={6} xs={12} key={i}>
                                            <Box pb={2} display="flex" onClick={() => {props.handleChangeSelectAppointment(appointment.id); handleAppointment(appointment.id)}} className={classes.appointmentCard}>
                                                <Box mr={2} mt={1}>
                                                    <WildHealthRadio 
                                                        checked={appointment.id === state.appointmentId}
                                                        value={appointment.id}
                                                    />
                                                </Box>
                                                <Box display="flex" flexDirection="column" pt={2}>
                                                    <div className={clsx(commonClasses.size18, commonClasses.textMedium, commonClasses.colorBlack)}>
                                                        {moment(appointment.startDate).format('dddd DD MMMM yyyy') }
                                                    </div>
                                                    <br />
                                                    <div className={clsx(commonClasses.textMedium, commonClasses.colorBlack)}>{state.patient.firstName} {state.patient.lastName}</div>
                                                    <div>{AppointmentPurposeType[appointment.purpose]} | {appointment.location.name}</div>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    ))
                                }
                                {
                                    props.handleConfigureAppointment != null && patientsQuery.getTargetPatient()?.isPremium && <Grid md={12} xs={12} item>
                                        <Box>
                                            <a className={commonClasses.colorAccent1} onClick={() => handleNoAppointment(true)}>No appointment</a>
                                        </Box>
                                    </Grid>
                                }
                            </Grid>
                        }

                    </DialogContentText>
                </DialogContent>

                <DialogActions classes={{ root: classes.dialogActions }}>
                        <WildHealthButton id="cancel-select" size='large' color='tertiary' onClick={() => {props.handleSelectAppointmentDialog(false); handleAppointment(null) }}>
                            Cancel
                        </WildHealthButton>
                        <WildHealthButton id="confirm-select" size='large' disabled={!state.appointmentId} onClick={() => props.handleSubmitSelectAppointment(state.appointmentId)}>
                            Confirm
                        </WildHealthButton>
                </DialogActions>
            </Dialog>
            <ConfigureNoteAppointmentDialog
                patientId={props.patientId}
                isOpen={state.appointmentConfigurationOpen}
                handleCloseDialog={() => handleNoAppointment(false)}
                handleConfigureAppointment={props.handleConfigureAppointment}
            />
        </>
    )
}
