import { Avatar, Box, Button, CircularProgress, FormControl, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { phoneNumberInput } from "../../../../../common/components/PhoneNumberInput";
import { WildHealthDatePicker } from "../../../../../common/components/WildHealthDatePicker";
import WildHealthLinearProgress from "../../../../../common/components/WildHealthLinearProgress";
import { displayAge, displayDate } from "../../../../../common/helpers/display-date";
import { Gender } from "../../../../../common/models/user.models";
import { useFacade } from "./generalInfoWidgetComponent.hooks";
import { useStyles } from "./generalInfoWidgetComponent.styles";
import EditIcon from '@material-ui/icons/Edit'
import { WildHealthButton } from "../../../../../common/components/wildHealthButton/WildHealthButton";
import commonUseStyles from "../../../../../common/styles/common.styles";
import { EmployeeType } from "../../../../models/employee.enums";
import { EmployeeShortModel } from "../../../../models/employee.models";
import { fullName } from "../../../../../common/helpers/display-name";
import { AssignEmployeesComponent } from "../../../assignEmployeesComponent/AssignEmployeesComponent";
import { SubscriptionModel, SubscriptionStatus } from "../../../../../payment/models/subscription.models";
import { WildHealthStatusLabel } from "../../../../../common/components/wildHealthStatusLabel/WildHealthStatusLabel";
import { PatientTagModel, TagSentiment } from "../../../../../patients/models/patient.model";


export interface GeneralInfoWidgetComponentProps {
  patientId: number | null;
}

export const GeneralInfoWidgetComponent: React.FC<GeneralInfoWidgetComponentProps> = (
  props: GeneralInfoWidgetComponentProps
) => {

  const [
    {
      edit,
      isLoading,
      isSaveChanges,
      patient,
      patientDraft,
      errors,
      employees,
    },
    handleChanges,
    handleEditToggle,
    handleReset,
    handleSave,
    handleEmployeeSelect,
  ] = useFacade(props.patientId);

  const classes = useStyles();
  const commonClasses = commonUseStyles();

  if (isLoading) {
    return <WildHealthLinearProgress />;
  }

  const getBirthday = () => {
    return patientDraft.birthday
      ? moment(patientDraft.birthday).format('YYYY-MM-DD')
      : null
  }

  const displayRenewalDate = (subscription: SubscriptionModel) => {
    if (subscription && subscription.status === SubscriptionStatus.Active && !subscription.canBeActivated) {
      const oneDay = 24 * 60 * 60 * 1000;
      const nowDate = new Date();
      const subscriptionEnd = new Date(subscription.endDate);

      const diffDays = Math.round(Math.abs((subscriptionEnd.getTime() - nowDate.getTime()) / oneDay));
      return <><span className={classes.space}>&#x2022;</span><span className={classes.space}> {` ${diffDays} days until renewal `}</span> </>
    }
  }

  const renderTagColor = (tag: PatientTagModel) => {
    if (tag.name.toLowerCase().includes('due')) {
      return "bad";
    }
    switch (tag.sentiment) {
      case TagSentiment.Danger: return "bad";
      case TagSentiment.Success: return "good";
      case TagSentiment.Warning: return "average1";
      case TagSentiment.Normal:
      default: return "normal";
    }
  }

  const displayAssignedEmployees = (employees: EmployeeShortModel[], assignedEmployeeIds: number[]) => {
    const assignedEmployees = employees.filter((e) => assignedEmployeeIds.includes(e.id));
    if (assignedEmployees.length) {
      return assignedEmployees.map((e) => fullName(e)).join(", ");
    }
    return 'N/A';
  }

  const renderEditContent = () => {
    return <>
      <Box width="100%">
        <form autoComplete="off">
          <Box display="flex" width="100%">
            <Avatar className={classes.avatar} variant="rounded" src='' ></Avatar>
            <Box width='100%'>
              <Box ml={2} display="flex" justifyContent="space-between" >
                <Box pr={1} width={0.5} >
                  <TextField
                    fullWidth
                    size="small"
                    required
                    disabled={!handleChanges}
                    id={`firstName`}
                    variant="outlined"
                    value={patientDraft.firstName}
                    label="First Name"
                    InputProps={{ className: 'input' }}
                    error={!!errors[`firstName`]}
                    helperText={errors[`firstName`]}
                    onChange={(v) => {
                      handleChanges(v.target.id, v.target.value)
                    }}
                  />
                </Box>
                <Box pl={1} width={0.5}>
                  <TextField
                    required
                    fullWidth
                    size="small"
                    disabled={!handleChanges}
                    id={`lastName`}
                    variant="outlined"
                    value={patientDraft.lastName}
                    label="Last Name"
                    InputProps={{ className: 'input' }}
                    error={!!errors[`lastName`]}
                    helperText={errors[`lastName`]}
                    onChange={(v) => {
                      handleChanges(v.target.id, v.target.value)
                    }}
                  />
                </Box>
              </Box>
              <Box className={commonClasses.colorGray1} ml={2} >
                <span className={classes.space}>{`${displayAge(patient.birthday)} `}</span>
                <span>{displayRenewalDate(patient.subscription)}</span>
                {patient.billingAddress
                  && <><span className={classes.space}>&#x2022;</span><span className={classes.space}> {` ${patient.billingAddress?.city}`}</span></>}
              </Box>
            </Box>
          </Box>
          {patient.tags.length > 0
            ? <Box mt={2}>
              <Grid container spacing={2}>
                {patient?.tags.map((tag, index) => (
                  <Grid item key={`patient-tag-${index}`}>
                    <WildHealthStatusLabel color={renderTagColor(tag)}>{tag.name}</WildHealthStatusLabel>
                  </Grid>
                ))}
              </Grid>
            </Box>
            : <></>
          }
          {patient.isMobileAppInstalled
            ? <Box className={classes.mobileApp}>Mobile app: Installed</Box>
            : <Box className={classes.noMobileApp}>Mobile app: Not Installed</Box>
          }

          <Box mt={2} width={1} display="flex" justifyContent="space-between">
            <Box pr={1} width={0.5}>
              <TextField
                fullWidth
                required
                size="small"
                disabled={!handleChanges}
                id={`phoneNumber`}
                variant="outlined"
                label="Phone number"
                value={patientDraft.phoneNumber}
                error={!!errors[`phoneNumber`]}
                helperText={errors[`phoneNumber`]}
                InputProps={{
                  className: 'input',
                  inputComponent: phoneNumberInput,
                }}
                onChange={(v) => {
                  handleChanges(v.target.id, v.target.value)
                }}
              />
            </Box>
            <Box pl={1} width={0.5}>
              <TextField
                required
                fullWidth
                size="small"
                id={`email`}
                variant="outlined"
                value={patientDraft.email}
                label="Email"
                InputProps={{ className: 'input' }}
                error={!!errors[`email`]}
                helperText={errors[`email`]}
                onChange={(v) => {
                  handleChanges(v.target.id, v.target.value)
                }}
              />
            </Box>
          </Box>
          <Box width={1} mt={2} display="flex" justifyContent="space-between">
            <Box pr={1} width={0.5}>
              <WildHealthDatePicker
                id={`birthday`}
                disableFuture
                fullWidth
                size="small"
                disabled={!handleChanges}
                label="Date of Birth"
                placeholder="yyyy-mm-dd"
                openTo="year"
                format="MM/DD/yyyy"
                value={getBirthday()}
                error={!!errors[`birthday`]}
                helperText={errors[`birthday`]}
                inputVariant="outlined"
                views={["year", "month", "date"]}
                onChange={(momentDate) =>
                  handleChanges(`birthday`, momentDate.format("YYYY-MM-DD")
                  )}
              />

            </Box>
            <Box pl={1} width={0.5}>
              <FormControl
                variant="outlined"
                error={!!errors[`gender`]}
                color='primary'
                size="small" fullWidth>
                <InputLabel htmlFor="gender-label">Biological Gender</InputLabel>
                <Select
                  style={{ background: '#FFF' }}
                  id={`gender`}
                  value={patientDraft.gender}
                  label="Biological Gender"
                  error={!!errors[`gender`]}
                  inputProps={{
                    name: 'Biological Gender',
                    id: 'gender-label',
                  }}
                  onChange={(v) => handleChanges(`gender`, v.target.value)}>
                  <MenuItem value={Gender.Male}>{Gender[Gender.Male]}</MenuItem>
                  <MenuItem value={Gender.Female}>{Gender[Gender.Female]}</MenuItem>
                </Select>
                <FormHelperText>{errors[`gender`]}</FormHelperText>
              </FormControl>
            </Box>
          </Box>
        </form>
        <Box mt={3} key='coaches'>
          <Box mt={2}>
            Assigned Coaches:
          </Box>
          <Box mt={2} display="flex">
            <Box mr={3}>
              Assigned:
            </Box>
            <Box>
              {displayAssignedEmployees(employees.filter(c => c.type === EmployeeType.HealthCoach), patient.assignedEmployees.map(el => el.id))}
            </Box>
          </Box>
          <Box pt={1}>
            <AssignEmployeesComponent
              selectedEmployeesIds={patientDraft.assignedEmployees.map(el => el.id)}
              handleEmployeeSelect={handleEmployeeSelect}
              disabled={false}
              employees={employees.filter(c => c.type === EmployeeType.HealthCoach)}
            />
          </Box>
        </Box>
        <Box mt={3} key='providers'>
          <Box my={2}>
            Assigned Providers:
          </Box>
          <Box mt={2} display="flex">
            <Box mr={3}>
              Assigned:
            </Box>
            <Box>
              {displayAssignedEmployees(employees.filter(c => c.type === EmployeeType.Provider), patient.assignedEmployees.map(el => el.id))}
            </Box>
          </Box>
          <Box pt={1}>
            <AssignEmployeesComponent
              selectedEmployeesIds={patientDraft.assignedEmployees.map(el => el.id)}
              handleEmployeeSelect={handleEmployeeSelect}
              disabled={false}
              employees={employees.filter(c => c.type === EmployeeType.Provider)}
            />
          </Box>
        </Box>
        <Box display='flex' mt={2}>
          <Box>
            <WildHealthButton
              id="save-changes-general-info"
              width='140px'
              disabled={isSaveChanges}
              onClick={() => handleSave()}>
              {isSaveChanges ? <CircularProgress size={24} /> : 'Save changes'}
            </WildHealthButton>
          </Box>
          <Box mx={2}>

            <WildHealthButton
              id="reset-changes-general-info"
              color='secondary'
              disabled={isSaveChanges}
              width='140px'
              onClick={() => handleReset()}
            >
              Reset
            </WildHealthButton>
          </Box>
        </Box>
      </Box>
    </>
  }

  const renderContent = () => {
    return (
      <Box width='100%'>
        <Box display='flex' justifyContent='space-between'>
          <Box>
            <Box display="flex">
              <Avatar variant="rounded" className={classes.avatar} src='' ></Avatar>
              <Box>
                <Box className={commonClasses.textBold} ml={2}>{`${patient.firstName} ${patient.lastName}`}</Box>
                <Box className={commonClasses.colorGray1} ml={2}>
                  <span className={classes.space}>{`${displayAge(patient.birthday)} `}</span>
                  <span>{displayRenewalDate(patient.subscription)}</span>
                  {patient.billingAddress
                    && <><span className={classes.space}>&#x2022;</span><span className={classes.space}> {` ${patient.billingAddress?.city}, ${patient.billingAddress?.state}`}</span></>}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <Button className={classes.button} onClick={() => { handleEditToggle() }}>
              <EditIcon fontSize='small' />
              <Box className={classes.buttonText}>Edit</Box>
            </Button>
          </Box>

        </Box>

        {patient.tags.length > 0
          ? <Box mt={2}>
            <Grid container spacing={2}>
              {patient?.tags.map((tag, index) => (
                <Grid item key={`patient-tag-${index}`}>
                  <WildHealthStatusLabel color={renderTagColor(tag)}>{tag.name}</WildHealthStatusLabel>
                </Grid>
              ))}
            </Grid>
          </Box>

          : <></>
        }
        {patient.isMobileAppInstalled
          ? <Box className={classes.mobileApp}>Mobile app: Installed</Box>
          : <Box className={classes.noMobileApp}>Mobile app: Not Installed</Box>
        }
        <Box display='flex'>
          <Box pr={1} display='flex' width='50%' flexDirection='column'>
            <Box mt={2} display="flex" >
              <Box width={0.4} minWidth='76px' className={classes.textGray} >Phone:</Box>
              <Box className={classes.breakWord}>{patient.phoneNumber}</Box>
            </Box>
            <Box mt={2} display="flex">
              <Box width={0.4} minWidth='76px' className={classes.textGray} >Date of birth: </Box>
              <Box className={classes.breakWord}> {displayDate(patient.birthday)} </Box>
            </Box>
            <Box mt={2} display="flex">
              <Box width={0.4} minWidth='76px' className={classes.textGray} >Provider: </Box>
              <Box className={classes.breakWord}>
                {displayAssignedEmployees(employees.filter(c => c.type === EmployeeType.Provider), patient.assignedEmployees.map(el => el.id))}
              </Box>
            </Box>
          </Box>

          <Box display='flex' width='50%' flexDirection='column'>
            <Box mt={2} display="flex" >
              <Box width={0.3} minWidth='78px' className={classes.textGray} >Email: </Box>
              <Box className={classes.breakWord}>{patient.email}</Box>
            </Box>
            <Box mt={2} display="flex">
              <Box width={0.3} minWidth='78px' className={classes.textGray} >Gender: </Box>
              <Box className={classes.breakWord}>{Gender[patient.gender]}</Box>
            </Box>
            <Box mt={2} display="flex">
              <Box width={0.3} minWidth='78px' className={classes.textGray} >H.Coach: </Box>
              <Box className={classes.breakWord}>
                {displayAssignedEmployees(employees.filter(c => c.type === EmployeeType.HealthCoach), patient.assignedEmployees.map(el => el.id))}
              </Box>
            </Box>
          </Box>

        </Box>
      </Box>
    );

  };

  return edit ? renderEditContent() : renderContent();
};
