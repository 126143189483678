import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& label.Mui-focused': {
                color: colors.main,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: colors.main,
                },
            },
        },
        dialogTitle: {
            padding: 30,
            paddingLeft: 40,
            paddingRight: 40,
        },
        dialogTitleCloseAction: {
            position: 'absolute',
            right: 20,
            top: 20,
            color: theme.palette.grey[500],
        },
        dialogContent : {
            width: 548,
            paddingTop: 0,
            paddingBottom: 30,
            paddingLeft: 40,
            paddingRight: 40,
        },
        dialogActions: {
            paddingTop: 0,
            paddingBottom: 30,
            paddingLeft: 40,
            paddingRight: 40,
        },
        action: {
            color: colors.main,
        }
    }),
);
