import { makeStyles } from '@material-ui/core/styles'
import { colors } from '../../../common/constants/colors'

export const useStyles = makeStyles({
    actions: {
        width: '30%',
        paddingTop: '6px',
        paddingBottom: '6px',
    },
    input:{
        height: '35px'
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '8px',
        color: colors.main,
        fontSize: 14
    },
    buttonText: {
        marginLeft: '6px',
        textTransform: 'capitalize'
    }
})