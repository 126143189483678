import { Box, Divider } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import commonUseStyles from '../../../common/styles/common.styles';
import { SelectAddOnModel } from '../../models/addOns.models';
import { SelectAddOnsComponent } from '../selectAddOnsComponent/selectAddOnsComponent';

interface SelectAddOnsCardComponentProps {
    items: SelectAddOnModel[];
    handleSelectingItem: (id: number, value: boolean) => void;
}

export const SelectAddOnsCardComponent: React.FC<SelectAddOnsCardComponentProps> = (props: SelectAddOnsCardComponentProps) => {
    const { items, handleSelectingItem } = props;

    const commonClasses = commonUseStyles();

    return (
        <Box className={commonClasses.backgroundColorBg}>
            <Box px="30px" pt="30px" pb="20px">
                <Box>
                    <span className={clsx(commonClasses.size20, commonClasses.textMedium)}>Get A Head Start On Your Personalized Care</span>
                </Box>
                <Box mt={3}>
                    <SelectAddOnsComponent items={items} handleSelectingItem={handleSelectingItem} />
                </Box>
            </Box>
            <Divider />
            <Box px="30px" pt="20px" pb="30px">
                <span className={clsx(commonClasses.size14, commonClasses.colorGray1, commonClasses.tertiaryFont)}>
                    *This testing can be added at any time if you would like to discuss these options with your health coach or doctor first.
                </span>
            </Box>
        </Box>
    );
}
