import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from "../../../common/constants/colors";


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            padding: 5,
            [theme.breakpoints.up('sm')]: {
                padding: 30,
            },
            [theme.breakpoints.down('xs')]: {
                padding: 16,
                minHeight: 'calc(100vh - 225px)',
            },
        },
        colorMain: {
            color: colors.main
        },
        invoicePaid: {
            color: colors.good
        },
        invoiceUnpaid: {
            color: colors.darkGray
        },
        invoiceCancelled: {
            color: colors.bad
        },
        icon: {
            cursor: 'pointer',
            '&:hover ~ .tooltip-element': {
                display: 'flex',
            },
        },
        tooltip: {
            position: 'absolute',
            background: colors.lightBlack,
            color: colors.white,
            boxShadow: ` 0px 4px 9px rgba(168, 173, 198, 0.15)`,
            borderRadius: 2,
            padding: 16,
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 14,
            display: 'none',
            width: 'max-content',
            maxWidth: '35vw',
            marginLeft: '-40px',
            zIndex: 1,
        },
        notificationToolTipText: {
            lineHeight: 1.5,
            fontFamily: 'Be Vietnam Pro',
            fontWeight: 400,
            fontSize: 14,
            color: colors.white,
        },
        iconInfo: {
            cursor: 'pointer',
            '&:hover ~ .tooltip-element': {
                display: 'flex',
            },
        },
        menuIcon: {
            [theme.breakpoints.down("xs")]: {
              "&.MuiIconButton-root": {
                padding: 0,
                color: colors.black,
              },
            },
        },
        circleGreen:{
            borderRadius:"50%",
            width:6,
            height:6,
            backgroundColor:"#8ABD7C",
            display:'inline-block',
            position:'relative',
            bottom:3
        },
        circleRed:{
            borderRadius:"50%",
            width:6,
            height:6,
            backgroundColor:colors.bad,
            display:'inline-block',
            position:'relative',
            bottom:3
        },
        circleGray:{
            borderRadius:"50%",
            width:6,
            height:6,
            backgroundColor: colors.gray1,
            display:'inline-block',
            position:'relative',
            bottom:3
        },
    })
);
