import { Box, useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { ExerciseBodyCircuitComponent, ExerciseBodyCircuitMobileComponent } from "../components/exerciseBodyCircuitComponent/ExerciseBodyCircuitComponent";
import { HealthReportSections, HealthReportMode } from "../models/healthReport.models";
import { HealthReportBasePage, HealthReportMobileBasePage } from "./HealthReportBasePage";
import { HealthReportPageProps } from "./healthReportPageProps";

export const HealthReportExerciseAndRecovery6Page: React.FC<HealthReportPageProps> = (props: HealthReportPageProps) => {
    const {
        pageNumber,
        report,
        practice
    } = props;

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        props.mode !== HealthReportMode.PrintMode && isSmallScreen ? (
            <HealthReportMobileBasePage section={HealthReportSections.ExerciseAndRecovery} pageNumber={pageNumber} practice={practice}>
                <Box>
                    {
                        report.exerciseAndRecoveryReport.exercisePlan.bodyCircuits.map((bodyCircuit, index) => {
                            return <Box key={index} mt={index !== 0 && "30px"}>
                                <ExerciseBodyCircuitMobileComponent bodyCircuit={bodyCircuit} />
                            </Box>
                        })
                    }
                </Box>
            </HealthReportMobileBasePage>
        ) : (
            <HealthReportBasePage section={HealthReportSections.ExerciseAndRecovery} pageNumber={pageNumber} practice={practice}>
                <Box px={10} pt={5}>
                    {
                        report.exerciseAndRecoveryReport.exercisePlan.bodyCircuits.length <= 2 &&
                        report.exerciseAndRecoveryReport.exercisePlan.bodyCircuits.map((bodyCircuit, index) => {
                            return <Box key={index} mb={3}>
                                <ExerciseBodyCircuitComponent bodyCircuit={bodyCircuit} />
                            </Box>
                        })
                    }
                    {
                        report.exerciseAndRecoveryReport.exercisePlan.bodyCircuits.length === 3 &&
                        <Box>
                            <Box display="flex" justifyContent="space-between">
                                <Box width="48%">
                                    <ExerciseBodyCircuitComponent key={0} bodyCircuit={report.exerciseAndRecoveryReport.exercisePlan.bodyCircuits[0]} />
                                </Box>
                                <Box width="48%">
                                    <ExerciseBodyCircuitComponent key={0} bodyCircuit={report.exerciseAndRecoveryReport.exercisePlan.bodyCircuits[1]} />
                                </Box>
                            </Box>
                            <Box mt={3} width="48%">
                                <ExerciseBodyCircuitComponent key={0} bodyCircuit={report.exerciseAndRecoveryReport.exercisePlan.bodyCircuits[2]} />
                            </Box>
                        </Box>
                    }
                </Box>
            </HealthReportBasePage>
        )
    )
}
