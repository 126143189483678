import { Box, Collapse, List, ListItem, ListItemText, ListItemIcon } from "@material-ui/core";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import React, { useEffect, useState } from "react";
import { WildHealthTabPanel } from "../wildHealthTabPanel/WildHealthTabPanel";
import { useStyles } from "./wildHealthTabMenuControl.styles";
import { WildHealthTooltip } from '../WildHealthTooltip';
import { MenuItemTypes } from "../../../../models/menu.models";

export interface Container {
    component: React.FC;
    props: any;
}

export interface TabItem {
    title: string | JSX.Element;
    type: MenuItemTypes;
    content?: JSX.Element;
    children?: TabItem[],
    container?: Container;
    keepMounted?: boolean;
    disabled?: boolean;
    badge?: JSX.Element;
    icon?: (isSelected?: boolean) => JSX.Element;
    action?: () => void,
}

interface CollapsibleMenuItem extends TabItem {
    isOpened: boolean;
}

interface WildHealthTabMenuControlState {
    isOpen: boolean;
    items: CollapsibleMenuItem[];
    selectedTab: string,
    selectedContent: string,
    anchorEl: HTMLButtonElement;
}

export function useFacade(items: TabItem[], initTab?: string, onChangeCB?: (tab: string) => void): [
    WildHealthTabMenuControlState,
    JSX.Element[],
    JSX.Element[],
    () => void,
] {

    const [state, setState] = useState({
        isOpen: true,
        items: items.map(i => i as CollapsibleMenuItem),
        selectedTab: initTab,
        selectedContent: `tab-content-${initTab}`,
        anchorEl: null,
    } as WildHealthTabMenuControlState);

    const classes = useStyles();

    const handleChangeTab = (value: string) => {
        setState(state => ({ ...state, selectedTab: value, selectedContent: `tab-content-${value}` }));
        if (onChangeCB) {
            onChangeCB(value);
        }
    };

    const handleMenuItemToggle = (item: CollapsibleMenuItem, items: CollapsibleMenuItem[]) => {
        items.forEach(el => {
            el.title === item.title ? el.isOpened = !item.isOpened : el.isOpened = false
        })
        setState(state => ({ ...state }));
    }

    const handleToggle = () => {
        const isOpen = !state.isOpen;

        if (!isOpen) {
            state.items.forEach(i => i.isOpened = false);
        }

        setState(state => ({ ...state, isOpen: isOpen }));
    }

    const tabs: JSX.Element[] = [];
    const tabPanels: JSX.Element[] = [];

    const setTabPanels = (tabPanels: JSX.Element[], item: TabItem, index: string, initialKey: string) => {
        const key = `${initialKey}-${index}`;

        if (item.content) {
            const Container = item.container?.component ?? Box;
            const content = (
                <WildHealthTabPanel key={key} selected={state.selectedContent} index={key} keepMounted={item.keepMounted}>
                    {React.createElement(Container, item.container?.props, item.content)}
                </WildHealthTabPanel>
            )

            tabPanels.push(content);
        }

        if (item.children?.length) {
            item.children.forEach(child=> setTabPanels(tabPanels, child, `${child.type}`, key));
        }
    }

    items.forEach(item => setTabPanels(tabPanels, item, `${item.type}-parent`, 'tab-content'));

    const renderChildMenuItems = (item: CollapsibleMenuItem, itemIndex: string) => {
        return (
            <React.Fragment key={itemIndex}>
                <Collapse in={item.isOpened} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {
                            item.children.map(child => {
                                const isSelected = state.selectedContent.startsWith(`tab-content-${itemIndex}-${child.type}`);

                                return (
                                    <ListItem
                                        id={`tab-menu-${child.title}`}
                                        button
                                        key={child.type}
                                        disabled={child.disabled}
                                        className={clsx(classes.menuItem, {
                                            [classes.menuItemSelected]: isSelected && !child.disabled,
                                        })}
                                        onClick={() => handleChangeTab(`${itemIndex}-${child.type}`)}
                                    >
                                        <ListItemText
                                            primaryTypographyProps={{
                                                className: clsx(classes.menuItemText, classes.nested, {
                                                    [classes.menuItemTextSelected]: isSelected && !item.disabled,
                                                })
                                            }}
                                            primary={
                                                <>
                                                    {child.title}
                                                    <ListItemIcon className={classes.badgeIcon}>
                                                        {child.badge}
                                                    </ListItemIcon>
                                                </>}
                                        />
                                    </ListItem>
                                )
                            })
                        }
                    </List>
                </Collapse>
            </React.Fragment>
        )
    }

    const renderMenuItems = () => {
        state.items.forEach(item => {
            let indexUnique = `${item.type}-parent`
            let isSelected = state.selectedContent.startsWith(`tab-content-${indexUnique}`);
            const hasChildren = item.children?.length > 0;
            const action = hasChildren ?
                () => {
                    handleMenuItemToggle(item, state.items)
                    if (!state.isOpen)
                        handleToggle();
                } :
                () => handleChangeTab(indexUnique);

            if (hasChildren && !isSelected) {
                isSelected = item.children.find(item => state.selectedContent.startsWith(`tab-content-${indexUnique}-${item.type}`)) != null;
            }

            const listItem = (
                <ListItem
                    id={`tab-menu-list-${item.title}`}
                    className={clsx(classes.menuItem, {
                        [classes.menuItemSelected]: isSelected && !item.disabled,
                    })}
                    selected={isSelected}
                    disabled={item.disabled}
                    onClick={() => action()}
                    key={`list-${indexUnique}`}
                    button
                >
                    {!state.isOpen && (
                        <ListItemIcon
                            className={clsx(classes.menuIcon, classes.menuIconSize)}
                        >
                            {item.icon(isSelected)}
                        </ListItemIcon>
                    )}
                    <ListItemText
                        primaryTypographyProps={{
                            className: clsx(classes.menuItemText, {
                                [classes.menuItemTextSelected]: isSelected && !item.disabled,
                            })
                        }}
                        primary={item.title}
                        hidden={!state.isOpen}
                    />
                    {state.isOpen && hasChildren ? item.isOpened && !item.disabled ? <ExpandLessIcon /> : <ExpandMoreIcon /> : <></>}
                </ListItem>
            );

            tabs.push(state.isOpen ? listItem : <WildHealthTooltip key={`${item.type}-tooltip`} title={item.title} placement="right">{listItem}</WildHealthTooltip>);

            if (hasChildren) {
                tabs.push(renderChildMenuItems(item, indexUnique));
            }
        });

        return tabs;
    }

    renderMenuItems();

    useEffect(() => {

        const initTabUnique = initTab.includes('parent') ? `${initTab}` : `${initTab}-parent`

        setState(state => ({
            ...state,
            selectedTab: initTabUnique,
            selectedContent: `tab-content-${initTabUnique}`
        }));

        return () => { };
    }, [initTab]);


    return [
        state,
        tabs,
        tabPanels,
        handleToggle,
    ];
}