import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chat: {
            width: '100%',
            height: '100%',
            display: 'grid',
            boxShadow: '0px 7px 60px rgba(168, 173, 198, 0.12)',
            background: colors.white,
            borderRadius: 2,

            gridTemplateAreas: '"messages side"',
            gridTemplateColumns: 'calc(100% - 325px) 325px',
            [theme.breakpoints.down('md')]: {
                gridTemplateColumns: '100%',
                gridTemplateAreas: '"messages"',
            },
        },
        header: {
            height: 70,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            [theme.breakpoints.down('sm')]: {
                height: 'auto',
                display: 'block',
            },
        },
        side: {
            gridArea: 'side',
            height: '100%',
            display: 'flex',
        },
        sideMaxHeight: {
            maxHeight: 'calc(100vh - 174px)',
        },
        sideContainer: {
            height: '100%',
            width: '100%',
            overflow: 'auto',
        },
        messages: {
            gridArea: 'messages',
            display: 'flex',
            flexDirection: 'column',
            maxHeight: 'calc(100vh - 174px) !important',
            minHeight: 565,
            [theme.breakpoints.down('sm')]: {
                minHeight: "calc(100vh - 114px) !important",
            },
        },
        title: {
            color: colors.black,
            fontWeight: 500,
            fontSize: 20,
            '&:hover': {
                color: colors.main,
            },
        },
        subject: {
            fontSize: 12,
            color: colors.gray2,
        },
        divider: {
            backgroundColor: colors.stroke,
        },
        sideMobile: {
            width: 325,
        },
        icon: {
            stroke: colors.main,
            height: 26,
            width: 26,
        },
        settingsIcon: {
            fill: colors.main,
            height: 26,
            width: 26,
        },
        iconInfo: {
            cursor: 'pointer',
            '&:hover ~ .tooltip-element': {
                display: 'flex',
            },
        },
        goBack: {
          [theme.breakpoints.down("xs")]: {
            background: colors.white,
            borderBottom: "1px solid",
            borderBottomColor: colors.stroke,
          },
        },
        titleAndAvatars: {
          display: "flex",
          [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
            alignItems: "center",
            gap: 0,
          },
        },
    })
);
