import { Box, Dialog, DialogActions, DialogContent, DialogContentText, TextField } from '@material-ui/core';
import React from 'react';
import { DialogTitle } from '../../components/dialogTitle/DialogTitle';
import { WildHealthButton } from '../../components/wildHealthButton/WildHealthButton';
import { useFacade } from "./confirmWithReasonDialog.hooks";
import { useStyles } from "./confirmWithReasonDialog.styles";

export const CommonConfirmWithReasonDialog : React.FC = () => {
    const [
        {
            isOpen,
            title,
            message,
            placeholder,
            acceptTitle,
            reason,
            errors,
        },
        handleReasonChange,
        handleCancel,
        handleConfirm,
    ] = useFacade();
    const classes = useStyles();

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleConfirm();
        }
    }

    return (
        <Box onKeyPress={(e) => handleKeyPress(e)}>
            <Dialog
                fullWidth
                open={isOpen}
                onClose={() => handleCancel()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="dialog-title" onClose={() => handleCancel()} classes={{ root: classes.dialogTitle }}>{title}</DialogTitle>

                <DialogContent classes={{ root: classes.dialogContent }}>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                    <Box mt={2}>
                            <TextField
                                fullWidth
                                required
                                rows={3}
                                multiline
                                type='text'
                                size="small"
                                variant="outlined"
                                label={placeholder}
                                placeholder={placeholder}
                                value={reason}
                                InputProps={{className: 'input'}}
                                onChange={handleReasonChange}
                                error={!!errors['reason']}
                                helperText={errors['reason']}
                            />
                        </Box>
                </DialogContent>

                <DialogActions classes={{ root: classes.dialogActions }}>
                    <Box mx={2}>
                        <WildHealthButton id={`confirm-with-reason-cancel-${title}`} color="tertiary" onClick={() => handleCancel()}>
                            Cancel
                        </WildHealthButton>
                    </Box>
                    <Box>
                        <WildHealthButton id={`confirm-with-reason-${acceptTitle}-${title}`} onClick={() => handleConfirm()}>
                            {acceptTitle}
                        </WildHealthButton>
                    </Box>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
