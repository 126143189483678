export interface RosterModel {
    id: number;
    name: string;
    isActive: boolean
}

export enum NoPmRoster {
    January22 = 3,
    July21 = 2,
    January21 = 1,
}

export const navigationNumber = 100