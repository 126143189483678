import React, { FC, Fragment } from "react";
import { Button, Grid } from '@material-ui/core';
import { useStyles } from "./ErrorBoundaryComponent.styles";
import { logoService } from "../../../../services/logo.service";

type ErrorComponentType = {
    onClick: () => void
}
export const ErrorBoundaryComponent: FC<ErrorComponentType> = (props: ErrorComponentType) => {

    const onClick = props.onClick;

    const classes = useStyles();
    const email = 'support@wildhealth.com'
    return <Fragment>
        <Grid className={classes.container} container direction='column' justify="center"
            alignItems="center" wrap="nowrap" spacing={2}>
            <Grid>
                <img src={logoService.getLogo().primary} alt="logo" className={classes.logo} />
            </Grid>
            <Grid className={classes.titleGrid}>
                <div className={classes.title}>We’re having connection issues.</div>
            </Grid>
            <Grid>
                <div className={classes.description}>We’re looking into it right now. Please try again later.
                    You can contact support at <a id="error-boundary-support-email" href={`mailto:${email}`}>{email}</a>
                </div>
            </Grid>
            <Grid className={classes.gridButton}>
                <Button className={classes.button} variant='text' id={'back-button'} onClick={onClick}>Back to
                    Dashboard</Button>
            </Grid>
        </Grid>


    </Fragment>
}
