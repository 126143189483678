import { Store, StoreConfig } from "@datorama/akita";
import { HealthScoreModel } from "../models/score.model";

/**
 * Represents Health core state
 */
export interface HealthScoreState {
    healthScore: HealthScoreModel;
    isHealthScoreAvailable: boolean;
}

/**
 * Creates initial state
 */
export function createInitialState(): HealthScoreState {

    return {
        healthScore: null,
        isHealthScoreAvailable: false,
    };
}

/**
 * Provides Health Score states management
 */
@StoreConfig({name: 'Health Score', resettable: true })
export class HealthScoreStore extends Store<HealthScoreState> {
    constructor() {
        super(createInitialState());
    }

}

export const healthScoreStore = new HealthScoreStore();
