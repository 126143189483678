import moment from "moment";
import React from "react";
import { useEffect, useState } from "react";
import { Observable, Subscription } from 'rxjs';
import { navigationService } from "../../../../services/navigation.service";
import { onEmit } from '../../../common/helpers/on-emit';
import { HealthReportDialogName, HealthReportModel, HealthReportShortModel, HealthReportStatus } from "../../models/healthReport.models";
import { healthReportServices } from "../../services/healthReport.services";
import { healthReportPdfServices } from "../../services/healthReportPdf.service";
import { patientHealthReportService } from '../../services/patientHealthReport.service';
import { healthReportsQuery, healthReportsStore } from '../../stores/healthReportsStore';
import { toCurrentTimeZone } from '../../../timezones/helpers/timezone';
import { colors } from "../../../common/constants/colors";
import { confirmService } from "../../../../services/confirm.service";
import EditIcon from '@material-ui/icons/Edit';
import { Box, Button } from '@material-ui/core';
import { authQuery } from "../../../auth/stores/auth";
import { EmployeeType } from "../../../employee/models/employee.enums";
import { PracticeProfileModel } from "../../../account/models/practiceProfile.model";
import { PageSliderNavigationContext } from '../../../common/models/navigation.models';
import { NavigationStep } from '../healthReportComponent/healthReportComponent.hooks';
import {recommendationsService} from "../../../recommendations/services/recommendations.service";
import {getValueFeatureFlag} from "../../../common/components/featureFlags/featureFlags";
import {FeatureFlag} from "../../../common/components/featureFlags/featureFlags.models";
import {useFlags} from "launchdarkly-react-client-sdk";


interface HealthReportToolbarComponentState {
    isBannerExist: boolean;
    isOpen: boolean;
    isGenerating: boolean;
    isRegenerating: boolean;
    isPublishing: boolean;
    lastReport: HealthReportShortModel | null;
    reports: HealthReportShortModel[];
    actionsMenuAnchor?: HTMLButtonElement;
    isChangeReviewerOpen: boolean;
    isSelectRecommendationSetOpen: boolean;
    dialogName: string;
    actionSelectReportAnchor?: HTMLButtonElement;
    selectedReportName: string;
    fixedSectionShow: boolean;
}

/**
 * Custom Hook to manage a view Model for Create shortcuts component
 */
export function useFacade(patientId: number | null, report: HealthReportModel, practice: PracticeProfileModel, isNewRecommendations: boolean, navigationContext: PageSliderNavigationContext): [
    HealthReportToolbarComponentState,
    () => void,
    () => string | JSX.Element,
    () => string,
    () => string,
    (reportId: number) => void,
    () => boolean,
    () => void,
    () => boolean,
    () => void,
    () => void,
    (status: HealthReportStatus) => boolean,
    () => void,
    (actionsMenuAnchor?: HTMLButtonElement) => void,
    () => void,
    () => boolean,
    () => void,
    (title: HealthReportDialogName) => void,
    (actionSelectReportAnchor?: HTMLButtonElement) => void,
    (step: NavigationStep) => void,
    () => void
] {
    const featureFlags = useFlags();
    const recommendationsV2 = getValueFeatureFlag(featureFlags, FeatureFlag.RecommendationsV2);
    const [state, setState] = useState({
        isBannerExist: false,
        isOpen: false,
        isGenerating: false,
        isPublishing: false,
        isRegenerating: false,
        lastReport: null,
        reports: [],
        actionsMenuAnchor: null,
        isChangeReviewerOpen: false,
        isSelectRecommendationSetOpen: false,
        dialogName: '',
        actionSelectReportAnchor: null,
        selectedReportName: '',
        fixedSectionShow: true
    } as HealthReportToolbarComponentState);

    const onCloseBanner = () => {
        setState(state => ({ ...state, isBannerExist: false }));
    }

    const handleEditReviewer = () => {
        setState(state => ({ ...state, isChangeReviewerOpen: true }));
    }

    const handleOpenSelectRecommendationSet = (title: HealthReportDialogName) => {
        if (!canGenerateReport() && title === HealthReportDialogName.New) {
            return
        }

        if (!canRegenerateReport() && title === HealthReportDialogName.Update) {
            return
        }
        setState(state => ({ ...state, isSelectRecommendationSetOpen: true, dialogName: title }));
    }

    const handleCloseSelectRecommendationSet = () => {
        setState(state => ({ ...state, isSelectRecommendationSetOpen: false }));
    }

    const getReports = () => {
        if (patientId) {
            if (isNewRecommendations) {
                if (recommendationsV2) {
                    recommendationsService.getPatientRecommendations(patientId).subscribe();
                } else {
                    healthReportServices.getDefaultRecommendation();
                }
            }
            healthReportServices.getAll(patientId);
        }
        else
            patientHealthReportService.getAll();
    }

    const handleReportChange = (reportId: number) => {
        if (patientId)
            healthReportServices.getById(reportId, patientId);
        else
            patientHealthReportService.getById(reportId);
    }

    const canGenerateReport = () => {
        const status = state.lastReport?.status?.status;

        return patientId
            && !state.isGenerating
            && !state.isRegenerating
            && (status === HealthReportStatus.Preparing || report?.status?.status === HealthReportStatus.Submitted)
    }

    const canPublishReport = () => {
        const status = state.lastReport?.status?.status;

        return patientId
            && !state.isGenerating
            && !state.isRegenerating
            && (status === HealthReportStatus.Completed
                || (status === HealthReportStatus.UnderReview
                    && (+EmployeeType.Provider === +authQuery.getEmployeeType())
                ))
    }

    const handleReportGenerate = () => {
        if (!canGenerateReport()) {
            return
        }
        setState(state => ({
            ...state,
            isGenerating: true,
        }));

        let observable: Observable<any> = patientHealthReportService.generate();
        if (patientId) {
            observable = healthReportServices.generate(patientId, healthReportsStore.getRecommendationToSend())      
        }

        observable.subscribe(() => {
            getReports();
            setState(state => ({ ...state, isGenerating: false }))
        })
    }

    const handlePublishReport = () => {
        if (!canPublishReport()) {
            return
        }

        if (+EmployeeType.Provider === +authQuery.getEmployeeType()) {
            confirmService.confirm(
                'Are you sure?',
                'This will sign the report and make it visible to the patient. Published reports cannot be updated.')
                .subscribe(
                    () => {
                        setState(state => ({ ...state, isPublishing: true }))
                        healthReportServices.publish(state.lastReport.id, patientId).subscribe(() => {
                            getReports();
                            setState(state => ({ ...state, isPublishing: false }))
                        })
                    }
                );
        } else {
            setState(state => ({ ...state, isPublishing: true }))
            healthReportServices.publish(state.lastReport.id, patientId).subscribe(() => {
                getReports();
                setState(state => ({ ...state, isPublishing: false }))
            })
        }

    }

    const canRegenerateReport = () => {
        return patientId
            && !state.isGenerating
            && !state.isRegenerating
            && report?.status?.status !== HealthReportStatus.Submitted
            && report?.status?.status !== HealthReportStatus.Preparing
    }

    const handleReportRegenerate = () => {
        setState(state => ({
            ...state,
            isRegenerating: true,
        }));

        healthReportServices.regenerate(patientId, healthReportsStore.getRecommendationToSend()).subscribe(() => {
            getReports();
            setState(state => ({ ...state, isRegenerating: false }))
        })
    }

    const handleToggleCloseChangeReviewer = () => {
        setState(state => ({ ...state, isChangeReviewerOpen: !state.isChangeReviewerOpen }));
    }

    const canReportDownload = (status: HealthReportStatus) =>
        status === HealthReportStatus.Completed || status === HealthReportStatus.Submitted;

    const handleReportDownload = () => {
        if (!canReportDownload(report.status.status)) {
            return
        }
        navigationService.goUp();
        setState(state => ({
            ...state,
            isOpen: true
        }));

        const action = () => {
            healthReportPdfServices.generate(report, report.status.date, practice).then(() => {
                setState(state => ({
                    ...state,
                    isOpen: false
                }));
            });
        };

        var nAgt = navigator.userAgent;
        var browserName = "";

        // In Opera, the true version is after "Opera" or after "Version"
        if ((nAgt.indexOf("Opera")) != -1) {
            browserName = "Opera";
        }
        // In MSIE, the true version is after "MSIE" in userAgent
        else if ((nAgt.indexOf("MSIE")) != -1) {
            browserName = "Microsoft Internet Explorer";
        }
        // In Chrome, the true version is after "Chrome" 
        else if ((nAgt.indexOf("Chrome")) != -1) {
            browserName = "Chrome";
        }
        // In Safari, the true version is after "Safari" or after "Version" 
        else if ((nAgt.indexOf("Safari")) != -1) {
            browserName = "Safari";
        }
        // In Firefox, the true version is after "Firefox" 
        else if ((nAgt.indexOf("Firefox")) != -1) {
            browserName = "Firefox";
        }
        if (browserName === "Safari") {
            window.setTimeout(() => {
                action();
            }, 50000);
        } else {
            action();
        }
    }

    const handleToggleActions = (actionsMenuAnchor?: HTMLButtonElement) => {
        setState(state => ({ ...state, actionsMenuAnchor: actionsMenuAnchor }));
    }

    const handleSelectReportToggle = (actionSelectReportAnchor?: HTMLButtonElement) => {
        setState(state => ({ ...state, actionSelectReportAnchor: actionSelectReportAnchor }));
    }

    const handleGoToReportPage = (step: NavigationStep) => {
        navigationContext.goTo(step.from);
        setState(state => ({ ...state, actionSelectReportAnchor: null, selectedReportName: step.name }));
    }

    const handleFixedSectionToggle = () => {
        setState(state => ({ ...state, fixedSectionShow: !state.fixedSectionShow }));
    }

    const displayStatus = () => {
        if (report.status.status === HealthReportStatus.Failed && patientId) {
            return <span>{HealthReportStatus[report.status.status]} ({report.status.comment})</span>
        }

        if (report.status.status === HealthReportStatus.UnderReview && patientId) {
            return <Box display='flex'>
                <Box>Under Review by {report.reviewer.firstName} {report.reviewer.lastName}</Box>
                <Box mt={-1}>
                    <Button id="health-report-toolbar-edit-reviewer" onClick={() => handleEditReviewer()}>
                        <EditIcon
                            style={{ color: colors.main, fontSize: 20 }}
                        />
                    </Button>
                </Box>
            </Box>
        }

        if (report.status.status === HealthReportStatus.Submitted && patientId) {
            return <span>Published</span>
        }

        return HealthReportStatus[report.status.status];
    }

    const getStatusColor = () => {
        switch (report.status.status) {
            case HealthReportStatus.InProgress:
                return 'inherit';
            case HealthReportStatus.Preparing:
                return colors.accent3;
            case HealthReportStatus.Completed:
                return colors.accent3;
            case HealthReportStatus.Failed:
                return colors.bad;
            case HealthReportStatus.Submitted:
                return colors.good;
            case HealthReportStatus.UnderReview:
                return colors.average1;
            default:
                return colors.bad;
        }
    }

    const displayDate = (date: Date) => moment(toCurrentTimeZone(date)).format("MMMM DD, YYYY HH:mm");

    const getStatusDateLabel = (): string => {
        if (patientId) {
            switch (report.status.status) {
                case HealthReportStatus.InProgress:
                    return `Started at: ${displayDate(report.status.date)} (${report.status.version})`;
                case HealthReportStatus.Completed:
                    return `Generated at: ${displayDate(report.status.date)} (${report.status.version})`;
                case HealthReportStatus.Failed:
                    return `Failed at: ${displayDate(report.status.date)} (${report.status.version})`;
                case HealthReportStatus.Submitted:
                    return `Generated at: ${displayDate(report.status.date)} (${report.status.version})`;
                case HealthReportStatus.UnderReview:
                    return `Under Review at: ${displayDate(report.status.date)} (${report.status.version})`;
                default:
                    return "";
            }
        }
        return "";
    }

    /**
     * Load all ongoing cares
     * Manage subscriptions with auto-cleanup
     */
    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<HealthReportShortModel[]>(healthReportsQuery.reports$, reports => {
                setState(state => ({
                    ...state,
                    reports,
                    lastReport: reports[(reports?.length ?? 1) - 1] ?? null,
                }));
            }),
        ];

        getReports();
        setState(state => ({
            ...state,
            isBannerExist: report.status.status === HealthReportStatus.UnderReview
        }));

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, [report]);

    return [
        state,
        onCloseBanner,
        displayStatus,
        getStatusDateLabel,
        getStatusColor,
        handleReportChange,
        canGenerateReport,
        handleReportGenerate,
        canRegenerateReport,
        handleReportRegenerate,
        handleToggleCloseChangeReviewer,
        canReportDownload,
        handleReportDownload,
        handleToggleActions,
        handlePublishReport,
        canPublishReport,
        handleCloseSelectRecommendationSet,
        handleOpenSelectRecommendationSet,
        handleSelectReportToggle,
        handleGoToReportPage,
        handleFixedSectionToggle
    ];
}