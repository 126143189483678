import Axios from 'axios-observable';
import {Observable, Subject} from "rxjs";
import {API_URL} from "../../../config";
import {authHeader} from "../../common/helpers/auth-header";
import {CallbackModel} from "../../common/models/callback.model";
import {snackService} from '../../common/snack/state';
import {ApplyCouponModel} from "../models/payment.models";
import {PaymentPeriodModel} from "../models/paymentPeriod.models";
import {OfferPaymentPlanModel, PaymentPlanModel} from "../models/paymentPlan.models";
import {PaymentPriceModel} from "../models/paymentPrice.models";
import {paymentPlansStore, PaymentPlansStore} from "../stores/paymentPlansStore";
import { EmployerProductModel } from '../models/employerProduct.model';
import { PromoCodeVewModel, CreatePromoCodeModel } from '../models/paymentCoupon.model';

/**
 * Provides methods for working with payment plans and promo codes
 */
export class PaymentPlansService {

    public trialOrderOnShow = new Subject<CallbackModel<PaymentPlanModel>>();

    public limitedPlanOnSelect = new Subject<CallbackModel<PaymentPlanModel>>();

    constructor(private paymentPlansStore: PaymentPlansStore) {
    }

    /**
     * Returns all payment plans
     */
    public getById(id: number, practiceId: number,  employerKey = "" ): Observable<PaymentPlanModel> {
        let url = `${API_URL}paymentPlans?practiceId=${practiceId}&employerKey=${employerKey ?? ''}`;
        return new Observable<PaymentPlanModel>(observer => {
            Axios.get<PaymentPlanModel[]>(url)
                .pipe()
                .subscribe(
                    (response) => {
                        this.paymentPlansStore.updatePlans(response.data);
                        observer.next(response.data.find(x => x.id === id));
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Returns all payment plans
     */
    public get(practiceId: number, employerKey = "" ): Observable<PaymentPlanModel[]> {
        let url = `${API_URL}paymentPlans?practiceId=${practiceId}&employerKey=${employerKey ?? ''}`;
        return new Observable<PaymentPlanModel[]>(observer => {
            Axios.get(url)
                .pipe()
                .subscribe(
                    (response) => {
                        this.paymentPlansStore.updatePlans(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Returns all active payment plans
     */
    public getActive(practiceId: number, employerKey: string | null = null): Observable<PaymentPlanModel[]> {
        let url = `${API_URL}paymentPlans/active?practiceId=${practiceId}&employerKey=${employerKey ?? ''}`;
        return new Observable<PaymentPlanModel[]>(observer => {
            Axios.get<PaymentPlanModel[]>(url)
                .pipe()
                .subscribe(
                    (response) => {
                        this.paymentPlansStore.updatePlans(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    /**
     * Selects payment plan
     * @param plan
     */
    public selectPlan(plan: PaymentPlanModel | null, asNotLimited?: boolean): void {
        if (plan) {
            if (plan.isLimited && !asNotLimited) {
                return this.limitedPlanOnSelect.next({
                    data: plan,
                    callback: () => {
                    }
                });
            }

            if (this.paymentPlansStore.getValue().selectedPlan?.id !== plan.id) {
                this.paymentPlansStore.resetCoupon();
                this.paymentPlansStore.resetPeriodPrices();
            }

            this.paymentPlansStore.update({
                selectedPlan: Object.assign({}, plan),
                selectedPeriod: null
            });
        }
    }

    /**
     * Selects payment period
     * @param period
     */
    public selectPeriod(period: PaymentPeriodModel): void {
        if (this.paymentPlansStore.getValue().selectedPeriod?.id !== period.id) {
            this.paymentPlansStore.resetCoupon();
            this.paymentPlansStore.update({selectedPeriod: Object.assign({}, period)});
        }

        this.paymentPlansStore.update({
            selectedPeriod: Object.assign({}, period)
        });
    }

    public updateSelectedPeriod(period: PaymentPeriodModel): void {
        this.paymentPlansStore.update({
            selectedPeriod: Object.assign({}, period)
        });
    }

    public showTrialPlan(plan: PaymentPlanModel): Observable<PaymentPlanModel> {
        return new Observable(observer => {
            this.trialOrderOnShow.next({
                data: plan,
                callback: (result: PaymentPlanModel) => {
                    if (result) {
                        observer.next(plan);
                    }
                    observer.complete();
                }
            });
        });
    }

    public applyCoupon(model: ApplyCouponModel): Observable<PaymentPriceModel[]> {
        const url = `${API_URL}paymentPlans/coupon/apply`;
        return new Observable(observer => {
            Axios.post(url, model)
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public newPromoCodeApply(model: ApplyCouponModel): Observable<PaymentPriceModel[]> {
        const url = `${API_URL}PromoCodes/apply`;

        const config = {
            headers: authHeader()
        };

        return new Observable(observer => {
            Axios.post(url, model, config)
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public resetCoupon(){
        paymentPlansStore.resetCoupon();
    }

    public offerPaymentPlan(model: OfferPaymentPlanModel): void {
        const url = `${API_URL}paymentPlans/offer`;
        Axios.post(url, model, {headers: authHeader()})
            .pipe()
            .subscribe(
                () => {
                    snackService.success('The offered plan has been successfully sent!');
                },
                error => {
                    snackService.commonErrorHandler(error);
                }
            );
    }

    public getEmployerProduct(employerKey: string): Observable<EmployerProductModel> {
        let url = `${API_URL}EmployerProducts/${employerKey}`;
        return new Observable<EmployerProductModel>(observer => {
            Axios.get<EmployerProductModel>(url)
                .pipe()
                .subscribe(
                    (response) => {
                        this.paymentPlansStore.update({ employerProduct: response.data })
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getEmployerProductById(id: number): Observable<EmployerProductModel> {
        let url = `${API_URL}EmployerProducts/ById/${id}`;
        return new Observable<EmployerProductModel>(observer => {
            Axios.get<EmployerProductModel>(url)
                .pipe()
                .subscribe(
                    (response) => {
                        this.paymentPlansStore.update({ employerProduct: response.data })
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    /**
     * Returns all promo codes
     */
    public getPromoCodes(): Observable<PromoCodeVewModel[]> {
        const config = {
            headers: authHeader()
        };
        return new Observable<PromoCodeVewModel[]>(observer => {
            Axios.get(`${API_URL}PromoCodes`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.paymentPlansStore.update({ promoCodes: response.data })
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }
    
    public createPromoCode(model: CreatePromoCodeModel) {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.post(`${API_URL}PromoCodes`, model, config)
                .pipe()
                .subscribe(
                    response => {
                        this.paymentPlansStore.addPromoCode(response.data)
                        snackService.success('PromoCode successfully created');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    public deletePromoCode(id: number): Observable<any> {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.delete(`${API_URL}PromoCodes/${id}`, config)
                .pipe()
                .subscribe(
                    response => {
                        snackService.deleteSuccess('PromoCode Was Deleted!');
                        this.paymentPlansStore.deletePromoCode(id);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        )
    }

    public deactivatePromoCode(promoCodeModel: PromoCodeVewModel): Observable<any> {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.delete(`${API_URL}PromoCodes/deactivate/${promoCodeModel.id}`, config)
                .pipe()
                .subscribe(
                    response => {
                        snackService.cancelledSuccess('PromoCode Was Deactivated!');
                        this.paymentPlansStore.editPromoCode(promoCodeModel);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        )
    }
}

export const paymentPlansService = new PaymentPlansService(paymentPlansStore);
