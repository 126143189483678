import {
    Box, CircularProgress,
    IconButton,
    Table,
    TableBody,
    TableContainer,
    TableFooter,
    TableHead,
} from '@material-ui/core';
import moment from "moment";
import React from 'react';
import { WildHealthContainer } from '../../../common/components/wildHealthContainer/WildHealthContainer';
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { PaginationComponent } from '../../../common/pagination/components/paginationComponent/PaginationComponent';
import { pageSizes } from '../../../common/pagination/models/page-sizes';
import { useFacade } from './unmatchedLabsComponent.hooks';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import { FileName, useStyles } from './unmatchedLabsComponent.styles';
import DescriptionIcon from '@material-ui/icons/Description';
import { AssignLabsDialogComponent } from '../assignLabsDialogComponent/AssignLabsDialogComponent';
import { LabFileViewDialogComponent } from '../labFileViewDialogComponent/LabFileViewDialogComponent';
import TrashIcon from "@material-ui/icons/Delete";

export const UnmatchedLabsComponent: React.FC = () => {
    const [
        {
            isLoading,
            assignFile,
            deleteFile,
            isAssignToPatientOpen,
            isViewFileOpen,
            labFilesView,
            labFilesAll,
            pageSize,
            totalCount,
            selectedPage
        },
        handlePageChange,
        handlePageSizeChange,
        getAllAvailablePages,
        handleCancelAssign,
        handleOpenAssignDialog,
        handleDelete,
        handleAssignToPatient,
        handleOpenViewFileDialog,
        handleCloseView
    ] = useFacade();

    const classes = useStyles();

    const openAssignPatientDialog = () => {
        handleCloseView()
        handleOpenAssignDialog(assignFile)
    }

    if (isLoading) {
        return <WildHealthLinearProgress />
    }

    if (!isLoading && (!labFilesAll || !labFilesAll.length)) {
        return <WildHealthPlaceHolder message="No current records found" />
    }

    return (
        <>
            <WildHealthContainer margin={0}>
                <Box mt={4} pt={2}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <WildHealthTableRow>
                                    <WildHealthTableCell style={{ width: '30%' }} >
                                        File Name
                                    </WildHealthTableCell>
                                    <WildHealthTableCell style={{ width: '20%' }} >
                                        Date Received
                                    </WildHealthTableCell>
                                    <WildHealthTableCell style={{ width: '50%' }}>
                                        Action
                                    </WildHealthTableCell>
                                </WildHealthTableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    labFilesView.map(file =>
                                        <WildHealthTableRow key={file.id}>
                                            <WildHealthTableCell>
                                                <Box className={classes.file}>
                                                    <IconButton id="attachments-download-file" onClick={() => handleOpenViewFileDialog(file)} size='small'>
                                                        <DescriptionIcon className={classes.fileIcon} />
                                                        <Box ml={1}><FileName primary={file.name} /></Box>
                                                    </IconButton>
                                                </Box>
                                            </WildHealthTableCell>
                                            <WildHealthTableCell> {moment(file?.updatedAt).format("L")} </WildHealthTableCell>
                                            <WildHealthTableCell>
                                                <Box display="flex">
                                                    <WildHealthButton
                                                        id="assign-lab-to-patient"
                                                        onClick={() => handleOpenAssignDialog(file)}
                                                        size='medium'
                                                    >
                                                        Assign to Patient
                                                    </WildHealthButton>
                                                    <Box ml={2}>
                                                        <IconButton
                                                            id="remove-intervention-btn"
                                                            onClick={() => handleDelete(file)}
                                                        >
                                                            {deleteFile && deleteFile.id === file.id ? <CircularProgress size={24} className="wh-tw-text-primaryV" /> : <TrashIcon />}
                                                        </IconButton>
                                                    </Box>
                                                </Box>
                                            </WildHealthTableCell>
                                        </WildHealthTableRow>
                                    )
                                }
                            </TableBody>
                            <TableFooter>
                                <WildHealthTableRow style={{ backgroundColor: "white" }}>
                                    <WildHealthTableCell colSpan={7}>
                                        <PaginationComponent
                                            pageSizes={pageSizes}
                                            pageSize={pageSize}
                                            selectedPage={selectedPage}
                                            handlePageSizeChange={handlePageSizeChange}
                                            totalCount={totalCount}
                                            availablePages={getAllAvailablePages()}
                                            handlePageSelect={handlePageChange} />
                                    </WildHealthTableCell>
                                </WildHealthTableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Box>
            </WildHealthContainer>
            <AssignLabsDialogComponent
                isOpen={isAssignToPatientOpen}
                handleCancel={handleCancelAssign}
                handleSubmit={handleAssignToPatient}
            />
            <LabFileViewDialogComponent
                isOpen={isViewFileOpen}
                file={assignFile}
                handleCancel={handleCloseView}
                handleSubmit={openAssignPatientDialog}
            />
        </>

    );
}