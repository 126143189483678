import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { useHistory } from 'react-router';
import { onEmit } from "../../../common/helpers/on-emit";
import { syncRecordsService } from "../../services/syncRecords.service";
import { SyncRecordStatusCount, SyncRecordListModel } from "../../models/syncRecords.model";
import { syncRecordsQuery, syncRecordsStore } from "../../stores/syncRecordsStore";
import { navigationService } from '../../../../services/navigation.service';

interface SyncRecordsState {
    isLoading: boolean;
    syncRecordStatusCounts: any;
    searchQuery: string;
    searchSyncRecords: SyncRecordListModel[];
}

export function useFacade(typeName: string, practiceId: number): [
    SyncRecordsState,
    (syncRecordList: SyncRecordStatusCount | SyncRecordListModel) => void,
    (query: string) => void
] {

    const history = useHistory();

    const [state, setState] = useState({
        isLoading: true,
        syncRecordStatusCounts: null,
        searchQuery: '',
        searchSyncRecords: []
    } as SyncRecordsState);

    const handleGoTargetSyncRecordList = (syncRecordList: SyncRecordStatusCount | SyncRecordListModel) => {
        if (state.searchQuery) {
            syncRecordsStore.updateTargetSyncRecordList(syncRecordList as SyncRecordListModel, typeName, practiceId);
            navigationService.toTargetSyncRecordList(history, syncRecordList.statusId, true);
        } else {
            syncRecordsStore.setTargetSyncRecordCount(syncRecordList as SyncRecordStatusCount, typeName, practiceId);
            navigationService.toTargetSyncRecordList(history, syncRecordList.statusId);
        }
        return;
    }

    const handleSearchSubmit = (query: string) => {
        state.searchQuery = query ?? '';

        setState(state => ({
            ...state,
            isLoading: true,
        }));

        const cb = () => {
            setState(state => ({
                ...state,
                isLoading: false,
            }));
        }

        if (query) {
            syncRecordsService.getSyncRecordsSearch(typeName, practiceId, query, 0).subscribe(cb, cb);
        } else {
            syncRecordsService.getSyncRecordType(typeName, practiceId).subscribe(cb, cb);
        }
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<SyncRecordStatusCount[]>(syncRecordsQuery.syncRecordStatusCounts$, syncRecordStatusCounts => {
                const groupByCategory = syncRecordStatusCounts.reduce((group, syncRecord) => {
                    const { category } = syncRecord;
                    group[category] = group[category] ?? [];
                    group[category].push(syncRecord);
                    return group;
                }, {});
                setState({
                    ...state,
                    syncRecordStatusCounts: groupByCategory
                });
            }),
            onEmit<SyncRecordListModel[]>(syncRecordsQuery.searchSyncRecords$, searchSyncRecords => {
                setState(state => ({
                    ...state,
                    searchSyncRecords: searchSyncRecords
                }));

            }),
        ];

        const cb = () => setState(state => ({ ...state, isLoading: false }));

        syncRecordsService.getSyncRecordType(typeName, practiceId).subscribe(cb, cb);

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, []);

    return [
        state,
        handleGoTargetSyncRecordList,
        handleSearchSubmit
    ];
}