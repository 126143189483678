import { useEffect, useState } from "react";
import { pageSizes } from "../../../common/pagination/models/page-sizes";
import { IPaginationState } from "../../../common/pagination/models/pagination-state";
import { getAvailablePages } from "../../../common/pagination/helpers/get-evailable-pages";
import { AdminPanelMessageForwardingModel } from "../../models/message.models";
import { conversationsService } from "../../services/conversations.service";
import { Subscription } from "rxjs/internal/Subscription";
import { onEmit } from "../../../common/helpers/on-emit";
import { employeeConversationsQuery } from "../../stores/employeeConversationsStore/employeeConversations.query";
import { SortingDirection } from "../../../common/sorting/models/sorting-destination";

interface MessagingForwardingToolsState extends IPaginationState {
  isLoading: boolean;
  messages: AdminPanelMessageForwardingModel[];
  page: number;
  rowsPerPage: number;
  searchQuery: string;
  sortingDirection: SortingDirection;
}

export function useFacade(): [
  MessagingForwardingToolsState,
  (query: string) => void,
  () => number[],
  (value: any) => void,
  (page: number) => void,
  (direction: SortingDirection) => void,
] {

  const [state, setState] = useState({
    isLoading: true,
    messages: [],
    sortingDirection: SortingDirection.Desc,
    page: 0,
    rowsPerPage: pageSizes[0],
    totalCount: 0,
    selectedPage: 1,
    pageSize: pageSizes[0],
    searchQuery: '',
  } as MessagingForwardingToolsState);

  const getAllAvailablePages = () => {
    return getAvailablePages(state);
  }

  const handlePageSizeChange = (value: any) => {
    if (value === state.pageSize) {
      return;
    }
    const cb = () => setState(state => ({ ...state, isLoading: false }));
    setState(state => ({
      ...state,
      pageSize: value,
      selectedPage: 1,
      isLoading: true,
    }));
    conversationsService.getForwardingMessages(0, value, state.searchQuery, state.sortingDirection).subscribe(cb, cb)
  }

  const handlePageChange = (page: number) => {
    if (page === state.selectedPage) {
      return;
    }
    const cb = () => setState(state => ({ ...state, isLoading: false }));
    setState(state => ({
      ...state,
      selectedPage: page,
      isLoading: true
    }));
    conversationsService.getForwardingMessages(page - 1, state.pageSize, state.searchQuery, state.sortingDirection).subscribe(cb, cb)
  }

  const handleSearchSubmit = (query = '') => {

    setState(state => ({ ...state, isLoading: true, searchQuery: query }));
    const cb = () => setState(state => ({ ...state, isLoading: false }));

    conversationsService.getForwardingMessages(0, state.pageSize, query, state.sortingDirection).subscribe(cb, cb)
  }

  const handleSorting = (direction: SortingDirection) => {
    const newDirection = direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc
    const cb = () => setState(state => ({ ...state, isLoading: false }));
    setState(state => ({
      ...state,
      sortingDirection: newDirection,
      isLoading: true
    }));
    conversationsService.getForwardingMessages(state.selectedPage - 1, state.pageSize, state.searchQuery, newDirection).subscribe(cb, cb)
  }

  const useEffectCB = () => {
    const subscriptions: Subscription[] = [
      onEmit<AdminPanelMessageForwardingModel[]>(employeeConversationsQuery.messagesForwardingAdminTools$, messagesForwardingAdminTools => {
        setState(state => ({
          ...state,
          messages: messagesForwardingAdminTools,
        }));
      }),
      onEmit<number>(employeeConversationsQuery.messagesTotalCount$, messagesTotalCount => {
        setState(state => ({
          ...state,
          totalCount: messagesTotalCount,
        }));
      }),
    ];

    const cb = () => setState(state => ({ ...state, isLoading: false }));

    conversationsService.getForwardingMessages(state.selectedPage - 1, state.pageSize, '', state.sortingDirection).subscribe(cb, cb)
    return () => {
      subscriptions.map(it => it.unsubscribe())
    };

  }

  useEffect(useEffectCB, []);

  return [
    state,
    handleSearchSubmit,
    getAllAvailablePages,
    handlePageSizeChange,
    handlePageChange,
    handleSorting,
  ];
}