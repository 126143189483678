import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../modules/common/constants/colors';

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
        body: {
            fontFamily: 'Be Vietnam Pro',
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
            fontStyle: 'normal',
            color: colors.black,
            fontSize: 16,
            backgroundColor: colors.bg,
            fontWeight: 400
            
        },
        h1: {
            fontFamily: "Poppins",
            fontWeight: 700,
            fontSize: 46
        },
        h2: {
            fontFamily: "Poppins",
            fontWeight: 600,
            fontSize: 24
        }
    }
  })
);

const GlobalStyles = () => {
    useStyles();
  
    return null;
  };
  
  export default GlobalStyles;