import React, { useState } from 'react';
import { Box, Divider, IconButton, List, ListItem, useTheme, useMediaQuery } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import GetAppIcon from '@material-ui/icons/GetApp';
import moment from 'moment';
import { MessagesPlaceHolder } from '../../../common/components/wildHealthPlaceHolder/MessagesPlaceHolder';
import { useStyles, UnreadMessages } from "./internalThreadComponent.styles";
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { ConversationAuthorModel } from '../../models/conversation.models';
import { employeeTypesNames } from '../../../employee/models/employee.enums';
import { toCurrentTimeZone } from '../../../timezones/helpers/timezone';

interface InternalThreadComponentProps {
    isLoadingMessages?: boolean;
    messages: any[];
    lastReadMessageIndex?: number;
    scrollToIndex?: number;
    threadStartId?: string;
    participants: any[];
    author: ConversationAuthorModel;
}

export const InternalThreadComponent: React.FC<InternalThreadComponentProps> = (props: InternalThreadComponentProps) => {
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const {
        isLoadingMessages = false,
        messages,
        lastReadMessageIndex = -1,
        scrollToIndex = -1,
        threadStartId = 'thread-start',
        participants,
    } = props;

    const [scrollTo, setScrollTo] = useState(null);


    const downloadMedia = (media: any) => {
        media.getContentTemporaryUrl().then(url => window.open(url));
    }

    if (!messages.length && !isLoadingMessages) {
        return (
            <Box height='100%' width="50vh" margin="0 auto">
                <MessagesPlaceHolder message="No messages yet." />
            </Box>
        )
    }

    if (scrollTo && scrollToIndex >= 1) {
        scrollTo.scrollIntoView();
    }

    const renderUnreadMessages = (messageIndex: number, lastReadMessageIndex: number) => {
        return messageIndex === lastReadMessageIndex + 1 ? (
            <Box key={`unread-messages-${messageIndex}`}>
                <ListItem id='unread-messages' key='unread-messages'>
                    <UnreadMessages primary='Unread Messages' />
                </ListItem>
                <Divider className={classes.divider} />
            </Box>
        ) : <></>
    }

    const ref = (element) => setScrollTo(element);

    const renderMessages = (messages: any[]) => {
        const items = messages.map(message => {
            const author = participants.find(x => x.vendorExternalIdentity === message.author);
            const messageIndex = message.state.index;

            return (
                [
                    <>
                        <div ref={messageIndex === scrollToIndex ? ref : null} />

                        {renderUnreadMessages(messageIndex, lastReadMessageIndex)}

                        <ListItem key={messageIndex} id={`message-${messageIndex}`}>
                            <Box id={`message-${messageIndex}`} px={!isSmallScreen && 4} width='100%' justifyContent='space-between' className={isSmallScreen ? "wh-tw-flex" : "wh-tw-block"}>
                                <Box mt={2}>
                                    <Box className={classes.author}>
                                        <Box>
                                            {author ? `${author.firstName} ${author.lastName}` : 'The author is not specified.'}
                                        </Box>
                                        {isSmallScreen ?
                                            <Box display='flex' ml={2}>
                                                <Box className={classes.messageDate}>
                                                    {`${moment(toCurrentTimeZone(message.dateCreated)).format('MMM D, yyyy')} ${moment(message.dateCreated).format('hh:mm a')}`}
                                                </Box>
                                            </Box>
                                            :
                                            <Box ml={1} className={classes.position}>
                                                {author && `- ${employeeTypesNames.get(author.type)}`}
                                            </Box>
                                        }
                                    </Box>
                                    <Box className={classes.message}>
                                        {
                                            message?.state?.type === 'media'
                                                ? <Box display='flex' alignItems='center' className={classes.breakText}>
                                                    <IconButton id="message-download-media" className={classes.fileButton}
                                                        onClick={() => downloadMedia(message?.state?.media)} size='small'>
                                                        <DescriptionIcon className={classes.fileIcon} />
                                                        <GetAppIcon className={classes.downloadIcon} />
                                                    </IconButton>

                                                    <Box ml={1}>{message.state?.media?.state?.filename ?? message.state.body}</Box>
                                                </Box>
                                                : `${message.body}`
                                        }
                                    </Box>
                                </Box>
                                {!isSmallScreen &&
                                    <Box display='flex' >
                                        <Box className={classes.messageDate}>
                                            {`${moment(toCurrentTimeZone(message.dateCreated)).format('MMM D, yyyy')} ${moment(message.dateCreated).format('hh:mm a')}`}
                                        </Box>
                                    </Box>
                                }
                            </Box>
                        </ListItem>
                    </>,
                    <Box mx={4}>
                        <Divider className={classes.messageDivider} />
                    </Box>
                ]
            )
        }).flat();

        items.pop();

        return items;
    }

    return (
        <List id='thread-list' className={classes.root}>
            <Box id={threadStartId} p={3} px={4} height='4px'>
                {isLoadingMessages && <WildHealthLinearProgress />}
            </Box>
            {renderMessages(messages)}
        </List>
    )
};
