import { Box, Dialog, DialogActions, DialogContent, DialogContentText, FormControl, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { documentCategoryNames, documentCategorySections } from '../../../documents/models/documents.models';
import { DialogTitle } from '../../components/dialogTitle/DialogTitle';
import { WildHealthButton } from '../../components/wildHealthButton/WildHealthButton';
import { useFacade } from "./confirmAndSelectedDialog.hooks";
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from 'clsx';

export const CommonConfirmAndSelectedDialog: React.FC = () => {
    const [
        {
            isOpen,
            title,
            message,
            acceptTitle,
            newChoise,
        },
        handleCancel,
        handleConfirm,
        handleDataCategoriesSelect,
    ] = useFacade();

    const commonClasses = commonUseStyles();

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleConfirm();
        }
    }

    return (
        <Box onKeyPress={(e) => handleKeyPress(e)}>
            <Dialog
                fullWidth
                open={isOpen}
                onClose={() => handleCancel()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="dialog-title" onClose={() => handleCancel()}>
                    <Box p={2} pb={0}>
                        {title}
                    </Box>
                </DialogTitle>

                <DialogContent>
                    <Box px={2}>
                        <DialogContentText id="alert-dialog-description">
                            {message}
                        </DialogContentText>
                        <Box mb={1} className={clsx(commonClasses.size14, commonClasses.colorGray1)}>
                            Select category
                        </Box>
                        <FormControl color='primary' fullWidth size="medium" variant="outlined" className="drop-down">
                            <Select
                                value={newChoise}
                                renderValue={() => {
                                    return documentCategoryNames[Number(newChoise)]
                                }}
                                displayEmpty
                                onChange={(v) => handleDataCategoriesSelect(v.target.value)}>
                                {
                                    documentCategorySections.map((documentType) => {
                                        return <MenuItem
                                            key={documentType}
                                            value={documentType}>
                                            {documentCategoryNames[Number(documentType)]}
                                        </MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Box mx={2} mb={4}>
                        <WildHealthButton id={`confirm-with-reason-cancel-${title}`} color="tertiary" onClick={() => handleCancel()}>
                            Cancel
                        </WildHealthButton>
                    </Box>
                    <Box mr={4} mb={4}>
                        <WildHealthButton id={`confirm-with-reason-${acceptTitle}-${title}`} onClick={() => handleConfirm()}>
                            {acceptTitle}
                        </WildHealthButton>
                    </Box>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
