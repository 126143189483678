import { Box, IconButton, Table, TableBody, TableContainer, TableHead, Tooltip } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import moment from "moment";
import React from 'react';
import { WildHealthHeaderComponent } from '../../../common/components/wildHealthHeaderComponent/WildHealthHeaderComponent';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { CommonPlaceHolder } from '../../../common/components/wildHealthPlaceHolder/CommonPlaceHolder';
import { WildHealthTableCell } from '../../../common/components/wildHealthTable/WildHealthTableCell';
import { WildHealthTableRow } from '../../../common/components/wildHealthTable/WildHealthTableRow';
import { DnaOrderResultsComponent } from '../dnaOrderResultsComponent/DnaOrderResultsComponent';
import { useFacade } from './dnaOrderReplacementComponent.hooks';
import { useStyles } from './dnaOrderReplacementComponent.styles';
import Zoom from '@material-ui/core/Zoom';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import { toCurrentTimeZone } from '../../../timezones/helpers/timezone';
import { AddOnModel } from '../../../addons/models/addOns.models';
import { CreateOrderModel } from '../../models/orders.models';
import { NewDnaOrderDialog } from '../newDnaOrderDialogComponent/NewDnaOrderDialogComponent';

interface NewDnaOrderComponentProps {
    isLoadingNewKit: boolean;
    isNewOrderDialogOpen: boolean;
    patientId: number;
    addOns: AddOnModel[];
    handleNavigateToOrders: () => void;
    handleOrderKit: (model: CreateOrderModel) => void;
    handleToggleNewOrderDialog: Function;
}

export const DnaOrderReplacementComponent: React.FC<NewDnaOrderComponentProps> = (props: NewDnaOrderComponentProps) => {
    const { patientId, addOns, handleNavigateToOrders, handleOrderKit, handleToggleNewOrderDialog } = props;
    const classes = useStyles();

    const [
        {
            isLoading,
            orders,
        },
        columns,
        handleToggleReplacement,
    ] = useFacade(patientId);

    const renderContent = () => {

        if (isLoading) {
            return (
                <WildHealthLinearProgress/>
            )
        }

        if (!orders.length) {
            return <CommonPlaceHolder message='No Order Replacement Kit yet.'/>
        }

        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <WildHealthTableRow>
                        {
                            columns.map((item, index) =>
                                <WildHealthTableCell key={index} align="left">
                                    {item}
                                </WildHealthTableCell>
                            )
                        }
                        </WildHealthTableRow>
                    </TableHead>
                    <TableBody>
                    {
                        orders.map((item, index) => {
                            const replaceButton = (
                                <WildHealthButton
                                    id="dna-order-replacement"
                                    onClick={() => handleToggleReplacement(item.id)}
                                    disabled={!item.canReplace}
                                >
                                    Order Replacement
                                </WildHealthButton>
                            )

                            return (
                                <WildHealthTableRow key={index}>
                                    <WildHealthTableCell align="left">
                                        {moment(toCurrentTimeZone(item.orderedAt)).format("MM/DD/yyyy")}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="left">
                                        <DnaOrderResultsComponent isResulted={item.isResulted} isReplaced={item.isReplaced} />
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="left">
                                    {
                                        item.canReplace ? replaceButton :
                                        <Tooltip
                                            title={<Box textAlign='center'>A replacement can only be ordered for kits that have not resulted.</Box>}
                                            TransitionComponent={Zoom}
                                            classes={{ tooltip: classes.tooltip }}
                                            placement="top"
                                            leaveDelay={500}
                                            arrow
                                        >
                                            <span>
                                                {replaceButton}
                                            </span>
                                        </Tooltip>
                                    }
                                    </WildHealthTableCell>
                                </WildHealthTableRow>
                            )
                        })
                    }
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const title = (
        <Box display='flex' justifyContent='space-between'>
            <Box display='flex' alignItems='center'>
                <Box mr={2}>
                    <IconButton id="dna-order-replacement-kit" onClick={() => handleNavigateToOrders()} size='small'>
                        <ArrowBackIcon />
                    </IconButton>
                </Box>

                <Box>
                    Order Replacement Kit
                </Box>
            </Box>
            <Box>
                <WildHealthButton id="dna-order-kit" loading={props.isLoadingNewKit} disabled={!addOns.length} onClick={() => handleToggleNewOrderDialog()}>
                    Order Kit
                </WildHealthButton>
            </Box>
        </Box>
    )

    return (
        <WildHealthHeaderComponent title={title}>
            <Box className={classes.content}>
                {renderContent()}
            </Box>
            <NewDnaOrderDialog
                open={props.isNewOrderDialogOpen}
                patientId={patientId}
                addOns={addOns}
                handleClose={() => handleToggleNewOrderDialog()}
                handleSubmit={handleOrderKit}
            />
        </WildHealthHeaderComponent>
    );
}