import { useCallback, useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { onEmit } from "../../../common/helpers/on-emit";
import { LabGroup, LabInputModel, LabInputsDatasetEditableViewModel, LabInputsViewMode, LabInputValueModel, UpdateLabInputsModel, FileInputModel, FileInputType, LabRangeModel, LabRangeType, LabValidationSource } from "../../models/input.models";
import { inputsService } from "../../services/inputs.service";
import { inputsQuery } from "../../stores/inputsStore";
import { sortLabInputDataSets } from "../../helpers/sort-lab-input-data-sets";
import moment from "moment";
import { CheckboxDropdownItem } from "../../../common/models/checkboxDropdown.models";
import { WhOptimalRanges } from "./labInputsComponent.validator";
import { PlatformName, Track } from "../../../tracking/tracker";
import { getRange } from "../../helpers/labInputs.extensions";

interface LabInputSectionViewModel {
    group: LabGroup;
    expanded: boolean;
}

interface PatientLabInputsComponentState {
    isLoading: boolean;
    isEditMode: boolean;
    isSaving: boolean;
    isDeleting: boolean;
    isHighlightExpand: boolean;
    showUntestedValue: boolean;
    labInputs: LabInputModel[];
    sections: LabInputSectionViewModel[];
    dataSets: LabInputsDatasetEditableViewModel[];
    isAddLabsDialogOpen: boolean;
    viewMode: LabInputsViewMode;
    displayRange: boolean;
    hiddenDataSets: string[];
    files: FileInputModel[];
    isActionsOpen: boolean;
    selectedDataSetId: string;
    selectedDataSet: LabInputsDatasetEditableViewModel;
}

export function useFacade(patientId: number): [
    PatientLabInputsComponentState,
    (group: LabGroup) => void,
    Function,
    Function,
    Function,
    Function,
    Function,
    Function,
    Function,
    Function,
    Function,
    Function,
    Function,
    () => void,
    () => void,
    (id: number, status: boolean) => void,
    (dataSetId: string) => void,
    () => void,
    () => void,
    () => void
] {
    const [state, setState] = useState({
        viewMode: LabInputsViewMode.Graph,
        isLoading: true,
        isEditMode: false,
        isSaving: false,
        isDeleting: false,
        isHighlightExpand: true,
        showUntestedValue: false,
        labInputs: [],
        isAddLabsDialogOpen: false,
        hiddenDataSets: [],
        displayRange: true,
        sections: [
            {
                group: LabGroup.Lipids,
                expanded: true
            },
            {
                group: LabGroup.Methylation,
                expanded: true
            },
            {
                group: LabGroup.VitaminsAndMicronutrients,
                expanded: true
            },
            {
                group: LabGroup.InsulinResistanceAndMetabolism,
                expanded: true
            },
            {
                group: LabGroup.Hormones,
                expanded: true
            },
            {
                group: LabGroup.Inflammation,
                expanded: true
            },
            {
                group: LabGroup.CBC,
                expanded: true
            },
            {
                group: LabGroup.Metabolic,
                expanded: true
            },
            {
                group: LabGroup.Other,
                expanded: true
            }
        ],
        files: [],
        isActionsOpen: false,
        dataSets: [],
        selectedDataSetId: null,
        selectedDataSet: null
    } as PatientLabInputsComponentState);

    const AllKey = "ALL";
    const RangeKey = "RANGE";

    const createDefaultValue = (name: string, date: Date, dataSetId: string, range: LabRangeModel, group: LabGroup, isNewMisc: boolean): LabInputValueModel => ({
        id: null,
        name: name,
        value: null,
        isChanged: false,
        isChangedInThisSession: false,
        isInitialized: false,
        editable: true,
        notification: null,
        date: date,
        dataSetId: dataSetId,
        isPresent: false,
        range: range,
        group: group,
        isNewMisc: isNewMisc
    } as LabInputValueModel)

    const getDataSets = useCallback((inputs: LabInputModel[]): LabInputsDatasetEditableViewModel[] => {
        const allDataSets = inputs.map(x => x.values.map(t => {
            return {
                id: t.dataSetId,
                date:  t.date,
                selected: true,
                values: [],
                draftValues: []
            } as LabInputsDatasetEditableViewModel
        })).flat();

        if (!allDataSets.length) {
            return [];
        }

        const dataSets = Array.from(new Set(allDataSets.map(x => x.id)))
            .map(id => {
                return {
                    id: id,
                    date: allDataSets.find(x => x.id === id).date,
                    selected: true,
                    values: [],
                    new: false,
                    draftValues: []
                } as LabInputsDatasetEditableViewModel
            }).sort(sortLabInputDataSets);

        dataSets.forEach(dataSet => {
            inputs.forEach(input => {
                let value = input.values.find(x => x.dataSetId === dataSet.id);
                if (!value) {
                    value = createDefaultValue(input.name, dataSet.date, dataSet.id, getRange(input), input.group, false);
                } else {
                    value.isPresent = true;
                    value.group = input.group;
                    value.isNewMisc = false;
                }

                dataSet.values.push(value);
            })

            dataSet.draftValues = dataSet.values.map(value => Object.assign({}, value))
        });

        return dataSets.sort(sortLabInputDataSets);
    }, []);

    const getDropDownItems = (): CheckboxDropdownItem[] => {
        let dataSets = state.dataSets;

        if (!dataSets.length) {
            return [];
        }

        dataSets.forEach(c => c.selected = !state.hiddenDataSets.includes(c.id));

        dataSets = dataSets.sort(sortLabInputDataSets);

        return [
            {
                key: AllKey,
                name: "View All Labs",
                selected: state.dataSets.every(x => x.selected === true)
            },
        ].concat(state.dataSets.map((dataSet) => {
            return {
                key: dataSet.id,
                name: moment(dataSet.date).format("MM/DD/YYYY"),
                selected: dataSet.selected
            } as CheckboxDropdownItem
        }))
    }

    const handleColumnCheck = (dataSetId: string, value: boolean) => {
        switch (dataSetId) {
            case RangeKey:
                setState({ ...state, displayRange: value });
                return;
            case AllKey:
                setState({
                    ...state,
                    displayRange: true,
                    hiddenDataSets: []
                });
                return;
            default:
                const hiddenDataSets = state.hiddenDataSets.some(id => id === dataSetId)
                    ? state.hiddenDataSets.filter(id => id !== dataSetId)
                    : [...state.hiddenDataSets, dataSetId]
                setState({
                    ...state,
                    hiddenDataSets: hiddenDataSets
                });
                return;
        }
    }

    const getCurrentDataSet = () => {
        return state.dataSets[0];
    }

    const handleValueChange = (inputValue: LabInputValueModel, value: number | null) => {
        inputValue.value = value;

        if (value === null) {
            inputValue.isInitialized = false;
        }

        if (value !== null && !inputValue.isInitialized) {
            inputValue.isInitialized = true;
        }

        inputValue.isChanged = true;
        inputValue.isChangedInThisSession = true;

        inputValue.value = value;

        setState({ ...state, selectedDataSet: state.selectedDataSet });
    }

    const handleRangeChanges = (inputValue: LabInputValueModel, key: string, value: string) => {
        inputValue.range = {
            ...inputValue.range,
            [key]: value
        };

        inputValue.isChanged = true;
        inputValue.isChangedInThisSession = true;

        setState({ ...state, selectedDataSet: state.selectedDataSet });
    }

    const handleNameChange = (inputValue: LabInputValueModel, value: string) => {
        inputValue.name = value;

        setState({ ...state, selectedDataSet: state.selectedDataSet });
    }

    const handleExpand = (group: LabGroup) => {
        const section = state.sections.find(x => x.group === group);
        section.expanded = !section.expanded;
        setState({ ...state, sections: state.sections });
    }

    const handleHighlightExpand = () => {
        setState(state => ({ ...state, isHighlightExpand: !state.isHighlightExpand }));
    }

    const handleHighlight = (id: number, status: boolean) => {
        const input = state.labInputs.find(x => x.id === id);
        if (status) {
            Track("lab_marker_highlighted", {
                lab_name: input?.name,
                platform: PlatformName(),
            });
        } else {
            Track("lab_marker_unhighlighted", {
                lab_name: input?.name,
                platform: PlatformName(),
            });
        }

        inputsService.UpdateLabInputHighlights(id, patientId, status).subscribe();
    }

    const handleSelect = (dataSetId: string) => {
        setState({
            ...state,
            selectedDataSetId: state.selectedDataSetId === dataSetId ? null : dataSetId
        });
    }

    const handleDelete = () => {
        setState(state => ({ ...state, isDeleting: true, isActionsOpen: false }));
        const cb = () => setState(state => ({ ...state, isDeleting: false }))
        inputsService.deleteLabInputs(state.selectedDataSetId, patientId).subscribe(cb, cb);
    }

    const handleEditMode = (date: Date, group: LabGroup) => {
        setState(state => ({
            ...state,
            isEditMode: true,
            isAddLabsDialogOpen: true,
            selectedDataSet: state.dataSets.find(x => !x.new && x.date === date)
        }));
    }

    const handleAddLabDialogToggle = (open: boolean) => {
        const formattedDate = moment().startOf('day').toDate();
        const dataSetId = new Date().getTime().toString();

        const range = {
            from: null,
            to: null,
            dimension: '',
            type: LabRangeType.None,
            source: LabValidationSource.ValueScope
        };
        const values = state.labInputs.map(x => createDefaultValue(x.name, formattedDate, null, getRange(x)?.source === LabValidationSource.Custom ? getRange(x) : WhOptimalRanges.get(x.name) ?? range, x.group, false));
        const draftValues = values.map(value => Object.assign({}, value));

        const newDataSet = {
            id: dataSetId,
            date: formattedDate,
            selected: true,
            values: values,
            new: true,
            draftValues: draftValues
        };

        setState(state => ({
            ...state,
            isAddLabsDialogOpen: open,
            selectedDataSet: newDataSet,
        }));
    }

    const handleAddNewMiscLab = () => {
        const selectedDataSet = state.selectedDataSet;
        const date = selectedDataSet.values[0]?.date ?? selectedDataSet.date;
        const existingDataSetId = selectedDataSet.values[0]?.dataSetId ?? null;

        const value = createDefaultValue('', date, existingDataSetId, null, LabGroup.Other, true);

        setState(state => ({
            ...state,
            selectedDataSet: {
                ...selectedDataSet,
                values: [...selectedDataSet.values, value],
                draftValues: [...selectedDataSet.draftValues, value]
            },
        }));
    }

    const handleDateChanges = (date: Date) => {
        const formattedDate = moment(date).startOf('day').toDate();
        const dataSetId = date.getTime().toString();

        setState(state => ({
            ...state,
            selectedDataSet: {
                ...state.selectedDataSet,
                id: dataSetId,
                date: formattedDate,
                draftValues: state.selectedDataSet.draftValues.map(d => ({...d, date: formattedDate})),
                values: state.selectedDataSet.values.map(v => ({...v, date: formattedDate}))
            },
        }));
    }

    const handleSaveChanges = () => {
        setState(state => ({ ...state, isSaving: true }))
        const cb = () => {
            setState(state => ({
                ...state,
                isSaving: false,
            }));
        }
        const allValues = state.selectedDataSet.draftValues.flat();
        const createdValues = allValues.filter(x => !x.isPresent && !x.dataSetId && x.isInitialized);
        const newOtherValues = allValues.filter(x => !x.isPresent && x.isInitialized && x.isNewMisc);
        const appendedValues = allValues.filter(x => !x.isPresent && x.dataSetId && x.isInitialized);
        const updatedValues = allValues.filter(x => x.isPresent && x.isChanged && x.isInitialized && x.isChangedInThisSession && !x.isNewMisc);
        const deletedValues = allValues.filter(x => x.isPresent && !x.isInitialized && !x.isNewMisc);

        if (newOtherValues.length > 0) {
            newOtherValues.forEach(x => {
                Track("other_lab_marker_added", {
                    lab_name: x.name,
                    platform: PlatformName(),
                });
            })
        }

        const model = {
            ranges: [],
            toCreate: createdValues.map(x => {
                return {
                    date: x.date,
                    name: x.name,
                    value: x.value,
                    labInputRange: x.range
                }
            }),
            toUpdate: updatedValues.map(x => {
                return {
                    id: x.id,
                    value: x.value,
                    labInputRange: x.range
                }
            }),
            newLabInputs: newOtherValues.map(x => {
                return {
                    name: x.name,
                    range: x.range,
                    isHighlighted: false
                }
            }),
            updatedLabInputs: [],
            toAppend: appendedValues.map(x => {
                return {
                    dataSetId: x.dataSetId,
                    name: x.name,
                    value: x.value,
                    labInputRange: x.range
                }
            }),
            toDelete: deletedValues.map(x => x.id),
            toCorrect: [],
            labInputMutations: []
        } as UpdateLabInputsModel;

        inputsService.updateLabInputs(model, patientId).subscribe(cb, cb);
    }

    const handleCancel = () => {
        setState(state => ({
            ...state,
            isAddLabsDialogOpen: false,
            isEditMode: false,
            selectedDataSet: null
        }));
    }

    const handleToggleActions = () => {
        setState(state => ({
            ...state,
            isActionsOpen: !state.isActionsOpen,
            deleteDataSets: []
        }));
    }

    const handleToggleShowUntested = () => {
        setState(state => ({
            ...state,
            showUntestedValue: !state.showUntestedValue,
        }));
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<LabInputModel[]>(inputsQuery.labInputs$, labInputs => {
                setState(state => ({
                    ...state,
                    labInputs: labInputs,
                    dataSets: labInputs && labInputs.length ? getDataSets(labInputs) : []
                }));
            }),
            onEmit<FileInputModel[]>(inputsQuery.uploads$, files => {
                setState(state => ({
                    ...state,
                    files: files.filter(x => x.type === FileInputType.LabResults)
                }));
            })
        ];
        setState(state => ({ ...state, isLoading: true }))

        inputsService.getUploads(patientId).subscribe();
        inputsService.getLabInputs(patientId).subscribe(
            () => setState(state => ({ ...state, isLoading: false })),
            () => setState(state => ({ ...state, isLoading: false }))
        );

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [patientId]);

    return [
        state,
        handleExpand,
        handleCancel,
        handleEditMode,
        handleSaveChanges,
        handleValueChange,
        handleRangeChanges,
        handleNameChange,
        getCurrentDataSet,
        handleAddLabDialogToggle,
        handleDateChanges,
        getDropDownItems,
        handleColumnCheck,
        handleToggleActions,
        handleHighlightExpand,
        handleHighlight,
        handleSelect,
        handleDelete,
        handleToggleShowUntested,
        handleAddNewMiscLab
    ];
}
