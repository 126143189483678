import { createStyles, makeStyles, Theme, } from '@material-ui/core/styles';
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        notification: {
            fontSize: 14,
            padding: '8px 12px',
            color: colors.good,
            border: '1px solid rgba(138, 189, 125, 0.16)',
            background: 'rgba(138, 189, 125, 0.08)',
            marginBottom: 24,
            width: 'max-content',
        },
        label: {
            fontSize: 14,
            fontWeight: 500,
            color: colors.gray900,
            textTransform: 'uppercase',
            [theme.breakpoints.down('xs')]: {
                fontSize: 12,
            },
        },
        textField: {
            borderColor: colors.mediumGray,
            borderRadius: 4
        },
    })
);