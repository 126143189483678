import { makeStyles } from "@material-ui/core";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme) => ({
    filters: {
        maxHeight: 'calc(100vh - 130px)',
        overflowY: 'auto',
        minWidth: 350,
        marginBottom: 16,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    avatarProvider: {
        width: 32,
        height: 32,
        backgroundColor: colors.accent2,
        color: colors.black,
        fontSize: 12,
        textTransform: 'uppercase',
    },
    avatarCoaches: {
        width: 32,
        height: 32,
        backgroundColor: colors.accent4,
        color: colors.black,
        fontSize: 12,
        textTransform: 'uppercase',
    },
    avatarEmpty: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 32,
        height: 32,
        borderRadius: '50%',
        border: `2px solid ${colors.gray4}`,
        color: colors.gray4,
    },
    activeIcon: {
        color: colors.main,
    },
    closeIcon: {
        color: colors.main,
        marginRight: 8,
        width: 24,
        height: 24,

    },
    divider: {
        borderTop: `2px solid ${colors.separators}`,
    }
}));
