import {Store, StoreConfig} from '@datorama/akita';
import {
    ConnectionStatuses,
    ConversationAuthorModel,
    ConversationAwayMessageTemplateModel,
    ConversationTemplateModel,
    EmployeeConversationModel,
    TargetInteractionModel
} from "../../models/conversation.models";
import {
    AdminPanelMessageForwardingModel,
    AdminPanelMessageModel,
    AttachedFileModel,
    MessageSettingsModel
} from '../../models/message.models';

export enum EmployeeConversationsTabs {
    Patients = 0,
    Internal = 1,
}

export enum PatientsChatsTabs {
    Active = 0,
    Closed = 1,
}

export enum InternalChatsTabs {
    Active = 0,
    SignedOff = 1,
}

/**
 * Represents Employee Conversations state
 */
export interface EmployeeConversationsState {
    isExpectedConversationsLoaded: boolean;
    isGetPhotosLoading: boolean;
    expectedConversations: EmployeeConversationModel[];
    tab: EmployeeConversationsTabs;
    author: ConversationAuthorModel;
    patientsChatsTab: PatientsChatsTabs;
    internalChatsTab: InternalChatsTabs;
    targetConversation: EmployeeConversationModel;
    conversations: EmployeeConversationModel[];
    templates: ConversationTemplateModel[];
    orphanedProxies: any[];
    supportSubmissions: EmployeeConversationModel[];
    targetAttachments: any[];
    targetAttachmentsView: any[];
    messageSettings: MessageSettingsModel;
    messagesAdminTools: AdminPanelMessageModel[];
    messagesForwardingAdminTools: AdminPanelMessageForwardingModel[];
    messagesTotalCount: number;
    editingTargetAiMessage: TargetInteractionModel;
    aiMessageAcceptLoading: boolean;
    connectionStatus: boolean;
    awayMessageTemplates: ConversationAwayMessageTemplateModel[];
    attachmentAddingAvailable: boolean;
    patientIdForDashboard: number;
    isLoadingForDashboardStickyChat: boolean;
}

/**
 * Creates initial state
 */
export function createInitialState(): EmployeeConversationsState {
    return {
        isExpectedConversationsLoaded: false,
        isGetPhotosLoading: false,
        expectedConversations: [],
        tab: 0,
        author: null,
        patientsChatsTab: 0,
        internalChatsTab: 0,
        targetConversation: null,
        conversations: [],
        templates: [],
        orphanedProxies: [],
        supportSubmissions: [],
        targetAttachments: [],
        targetAttachmentsView: [],
        messageSettings: {
            awayMessageEnabled: false,
            awayMessageEnabledFrom: null,
            awayMessageEnabledTo: null,
            forwardEmployeeEnabled: false,
            forwardEmployeeId: 0,
            awayMessageTemplateId: 0,
            message: '',
        },
        messagesAdminTools: [],
        messagesForwardingAdminTools: [],
        messagesTotalCount: 0,
        editingTargetAiMessage: null,
        aiMessageAcceptLoading: false,
        connectionStatus: false,
        awayMessageTemplates: [],
        attachmentAddingAvailable: true,
        patientIdForDashboard: null,
        isLoadingForDashboardStickyChat: false
    };
}

/**
 * Provides Employee Conversations states management
 */
@StoreConfig({ name: 'employeeConversations', resettable: true })
export class EmployeeConversationsStore extends Store<EmployeeConversationsState> {
    constructor() {
        super(createInitialState());
    }

    public setExpectedConversations(expectedConversations: EmployeeConversationModel[]): void {
        this.update({expectedConversations: expectedConversations, isExpectedConversationsLoaded: true})
    }

    public setTab(tab: EmployeeConversationsTabs): void {
        this.update({ tab: tab });
    }

    public setPatientsChatsTab(patientsChatsTab: PatientsChatsTabs): void {
        this.update({ patientsChatsTab: patientsChatsTab });
    }

    public setInternalChatsTab(internalChatsTab: InternalChatsTabs): void {
        this.update({ internalChatsTab: internalChatsTab });
    }

    public addOrphanedProxy(proxy: any): void {
        const orphanedProxies = this.getValue().orphanedProxies;

        const doubleProxy = orphanedProxies.find(p => p.sid === proxy.sid);
        if (!doubleProxy) {
            this.update({ orphanedProxies: [proxy, ...orphanedProxies] });
        }

        const conversations = this.getValue().conversations;
        const matchedConversation = conversations.find(c => c && c.vendorExternalId === proxy.sid);

        if (matchedConversation) {
            matchedConversation.proxy = proxy;
            this.updateConversation(matchedConversation);
        }
    }

    public addConversation(conversation: EmployeeConversationModel): void {

        if (!conversation.proxy) {

            // See if there's an orphaned proxy
            const orphanedProxies = this.getValue().orphanedProxies;
            conversation.proxy = orphanedProxies.find(i => i.sid === conversation.vendorExternalId);
        }

        this.updateConversation(conversation);
    }

    public setTemplates(templates: ConversationTemplateModel[]): void {
        this.update({ templates: templates });
    }

    public addTemplate(newTemplate: ConversationTemplateModel) {
        this.update({ templates: [...this.getValue().templates, newTemplate] });
    }

    public updateTemplate(template: ConversationTemplateModel) {
        const templates = this.getValue().templates;
        this.update({ templates: templates.map(x => x.id === template.id ? template : x) });
    }

    public removeTemplate(id: number) {
        this.update({ templates: this.getValue().templates.filter(i => i.id !== id) });
    }

    public updateConversation(conversation: EmployeeConversationModel): void {
        const state = this.getValue();

        // There's a chance we are sending in a conversation that does not have the proxy set (i.e. came from server)
        // If that's the case, then we DO NOT want to override the conversation in the state with this conversation that does not have a proxy
        // So try and get the proxy here and assign if the incoming conversation does not have a proxy
        const proxy = state.conversations.find(i => i.id === conversation.id)?.proxy;
        conversation.proxy = conversation.proxy ?? proxy;

        let conversations = []
        if (state.conversations.find(i => i.id === conversation.id)) {
            conversations = state.conversations.map(i => i.id === conversation.id ? conversation : i);
        } else {
            conversations = [...state.conversations, conversation].map(i => i.id === conversation.id ? conversation : i)
        }

        const targetConversation = conversation.id === state.targetConversation?.id ? conversation : state.targetConversation;

        this.update({ conversations: conversations, targetConversation: targetConversation });
    }

    public setTargetConversation(conversation: EmployeeConversationModel): void {
        const state = this.getValue();

        const targetConversation = state.conversations.find(i => i.id === conversation.id);

        this.update({ targetConversation: targetConversation, attachmentAddingAvailable: false });
    }

    public setConversationsJoined(conversationsJoined: EmployeeConversationModel[]): void {
        const state = this.getValue();

        // TODO - performance opportunity - why not just pass convesationsJoined to the state update?
        const conversations = state.conversations.map(i => conversationsJoined.find(a => a.id === i.id) ?? i);

        this.update({ conversations: conversations, targetConversation: state.targetConversation });
    }

    public resetConversations(): void {
        this.update({
            conversations: [],
            targetConversation: null
        });
    }

    public resetAttachments(): void {
        this.update({ targetAttachments: [] });
    }

    public resetAttachmentsView(): void {
        this.update({ targetAttachmentsView: [] });
    }

    public addTargetAttachments(attachments: AttachedFileModel[]): void {

        const targetAttachments = this.getValue().targetAttachments;

        attachments.forEach((attachment => {
            if (targetAttachments.find(x => x.file.sid === attachment.file.sid)) {
                return;
            }

            targetAttachments.push(attachment);
        }));

        this.update({ targetAttachments: targetAttachments.slice() });
    }

    public addTargetAttachmentsView(attachments: AttachedFileModel[]): void {
        let targetAttachments = this.getValue().targetAttachmentsView;

        attachments.forEach((attachment => {
            if (targetAttachments.find(x => x.file[0].sid === attachment.file[0].sid)) {
                return;
            }

            targetAttachments = [...targetAttachments, ...attachments];
        }));

        this.update({ targetAttachmentsView: targetAttachments });
    }

    startAiEditing(targetAiMessage: TargetInteractionModel) {
        this.update({ editingTargetAiMessage: targetAiMessage });
    }

    endAiEditing() {
        this.update({ editingTargetAiMessage: null, aiMessageAcceptLoading: false });
    }

    aiMessageAccept() {
        this.update({ aiMessageAcceptLoading: !this.getValue().aiMessageAcceptLoading });
    }

    updateConnectionStatus(status: string) {
        this.update({ connectionStatus: status === ConnectionStatuses.connected });
    }

    enableToAttachmentAdding() {
        this.update({ attachmentAddingAvailable: true });
    }

    startGetPhotos(): void {
        this.update({ isGetPhotosLoading: true });
    }

    endGetPhotos(): void {
        this.update({ isGetPhotosLoading: false });
    }

    updatePatientIdForDashboard(patientId: number | null): void {
        this.update({ patientIdForDashboard: patientId });
    }

    updateStickyChatLoadingForDashboard(status: boolean): void {
        this.update({ isLoadingForDashboardStickyChat: status });
    }

    public resetConversation(conversation: EmployeeConversationModel): void {
        const state = this.getValue();

        let conversations = []
        if (state.conversations.find(i => i.id === conversation.id)) {
            conversations = state.conversations.map(i => i.id === conversation.id ? conversation : i);
        } else {
            conversations = [...state.conversations, conversation].map(i => i.id === conversation.id ? conversation : i)
        }

        this.update({ conversations: conversations });
    }
}

export const employeeConversationsStore = new EmployeeConversationsStore();