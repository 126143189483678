export interface PatientAllergyModel {
    id: number;
    name: string;
    reaction: string;
}

export interface RxntAllergyModel {
    name: string;
    description: string;
}

export const newPatientAllergyModel = () : PatientAllergyModel => ({
    id: 0,
    name: '',
    reaction: '',
})