import {useEffect, useState} from "react";
import {Subscription} from "rxjs";
import {onEmit} from "../../../common/helpers/on-emit";
import {MicrobiomeInputModel} from "../../models/input.models";
import {patientInputsService} from "../../services/patientInputs.service";
import {inputsQuery} from "../../stores/inputsStore";
import {MicrobiomeInputNames} from "../../constants/inputs.constants";

interface PatientMicrobiomeInputsComponentState {
    isLoading: boolean;
    inputs: MicrobiomeInputModel[];
}

export function useFacade(): [PatientMicrobiomeInputsComponentState] {
    const [state, setState] = useState({
        isLoading: true,
        inputs: [],
    } as PatientMicrobiomeInputsComponentState);

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<MicrobiomeInputModel[]>(inputsQuery.microbiomeInputs$, inputs => {
                const inflammation = inputs.find(x => x.name === MicrobiomeInputNames.InflammationScore);
                const diversity = inputs.find(x => x.name === MicrobiomeInputNames.DiversityScore);

                if (inflammation?.isInitialized || diversity?.isInitialized) {
                    setState(state => ({...state, inputs: inputs}));
                }
            })
        ];

        patientInputsService.getMicrobiomeInputs().subscribe(
            () => setState(state => ({...state, isLoading: false})),
            () => setState(state => ({...state, isLoading: false}))
        );

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [state];
}
