export function displayMoney(amount: number, currency: string, isNegative: boolean = false, noCommas: boolean = false) {
    const amountString = amount % 1 === 0
        ? amount.toString()
        : amount.toFixed(2).toString();

    const operator = isNegative && amount !== 0 ? '- ' : ''

    if (noCommas) {
        return `${operator}${currency}${amountString}`;
    }

    return `${operator}${currency}${amountString.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
}