import React from 'react';
import { AuthenticatedLayoutComponent } from '../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from "../../../../models/menu.models";
import { DocumentSourcesComponent } from '../../components/documentSources/DocumentSourcesComponent';
import { UploadDocumentSourceDialog } from '../../components/uploadDocumentSourceDialog/UploadDocumentSourceDialog';
import { UpdateDocumentSourceDialog } from '../../components/updateDocumentSourceDialog/UpdateDocumentSourceDialog';

export const ManageKbDocumentsPage: React.FC = () => {
    return (
        <AuthenticatedLayoutComponent
            hideBackground
            title={TitlesService.kbDocumentsTitle}
            pageName={PagesNamesService.kbDocuments}
            selectedMenuItem={MenuItemTypes.KBDocuments}
        >
            <DocumentSourcesComponent />
            <UploadDocumentSourceDialog />
            <UpdateDocumentSourceDialog />
        </AuthenticatedLayoutComponent>
    )
}
