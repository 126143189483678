import React from 'react';
import { AuthenticatedLayoutComponent } from '../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from '../../../../models/menu.models';
import { DNAFilesMonitorComponent } from "../../components/dnaFilesMonitorComponent/DNAFilesMonitorComponent";

export const DNAFilesMonitorPage: React.FC = () => {
    return (
        <AuthenticatedLayoutComponent
            hideBackground
            title={TitlesService.dnaFilesTitle}
            pageName={PagesNamesService.dnaFiles}
            selectedMenuItem={MenuItemTypes.DNAFiles}
        >
            <DNAFilesMonitorComponent/>
        </AuthenticatedLayoutComponent>
    )
}