import { Box, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField } from "@material-ui/core";
import React from "react";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import commonUseStyles from '../../../common/styles/common.styles';
import { useFacade } from "./cancelLabOrderDialogue.hooks";
import { useStyles } from './cancelLabOrderDialogue.styles';


export const CancelLabOrderDialogue: React.FC = () => {
    const classes = useStyles();

    const [
        {
            isOpen,
            orderId,
            reason,
            canCancel,
            handleCancel
        },
        handleUpdateReason,
        handleClose,
        handleSubmitCancel
    ] = useFacade();
    const commonClasses = commonUseStyles();
    
    return (
        <>
            <Dialog maxWidth="md" open={isOpen} onClose={() => handleClose()} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Cancel Order</DialogTitle>
                <DialogContent>
                    <Box className={classes.dialogWidth} py={1}>
                        <Box display="flex">
                            <Box pt={1}>
                                Please add a reason for the cancellation below.
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="between" mt={3}>
                            <FormControl color='primary' fullWidth size="small" variant="outlined" className="drop-down">
                                <TextField
                                    id="lab-order-cancellation-reason"
                                    multiline
                                    rows={4}
                                    label="Enter reason"
                                    variant="outlined"
                                    onChange={handleUpdateReason}
                                >

                                </TextField>
                            </FormControl>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <WildHealthButton
                        id="lab-order-close"
                        color="secondary"
                        onClick={() => handleClose()}
                    >
                        Close
                    </WildHealthButton>
                    <Box pr={2}>
                        <WildHealthButton
                            id="lab-order-cancel"
                            color="danger"
                            onClick={() => handleSubmitCancel()}
                            disabled={!canCancel}
                        >
                            Cancel
                        </WildHealthButton>
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    )
}