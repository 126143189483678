import { colors } from "../../../common/constants/colors";
import { TextField } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
      borderColor: colors.gray4,
      borderRadius: 2,
      '& label.Mui-focused': {
          color: colors.main,
      },
      '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
              borderColor: colors.main,
          },
          borderColor: colors.gray4,
          borderRadius: 2,
      },
  },
  dialogContent: {
      padding: '30px 40px',
      width: 540,
  },
  dialogTitleCloseAction: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
  },
  search: {
    backgroundColor: colors.white,
    borderColor: colors.white,
  },
  searchBase: {
    padding: '10px 14px',
    fontFamily: 'Be Vietnam Pro',
    fontSize: 16,
  },
  menuIcon: {
    [theme.breakpoints.down("xs")]: {
      "&.MuiIconButton-root": {
        padding: 0,
        color: colors.black,
      },
    },
  },
}));

export const SearchTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: colors.main,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: colors.white,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: colors.white,
      },
      '&:hover fieldset': {
        borderColor: colors.white,
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.white,
      },
    },
  },
})(TextField);
