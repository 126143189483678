import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles((theme: Theme) => ({
    subscriptionCard: {
        marginTop: 10,
        borderRadius: 5,
        border: 'none',
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    },
    cardDivider: {
        backgroundColor: "#EFF3F8",
        margin: '20px auto',
        height: 1,
    },
    subscriptionItem: {
        marginTop: 20,
        fontFamily: 'OpenSans',
        fontSize: 18,
        lineHeight: 0.92,
        '& span': {
            fontWeight: 'bold',
        },
    },
    subscriptionItemSubtext: {
        marginTop: 5,
        fontFamily: 'OpenSans',
        fontSize: 15,
    },
    rightCardColumn: {
        paddingLeft: 20,
        borderLeft: '3px solid #f1f1f1',
        borderRadius: 1,
    },
    subscriptionAction: {
        margin: 10,
        paddingRight: 10,
        paddingLeft: 10,
        backgroundColor: '#537b98',
        color: '#ffffff',
    },
    dateContainer: {
        marginTop: 14,
        [theme.breakpoints.down('sm')]: {
            marginTop: 8,
            marginBottom: 14,
        },
    },
    endDatePadding: {
        paddingRight: 22,
    },
    icon: {
        width: 20,
        height: 20,
        color: colors.main,
        marginLeft: 8
    },
    notificationToolTipText: {
        lineHeight: 1.5,
        fontFamily: 'Be Vietnam Pro',
        fontWeight: 400,
        fontSize: 12,
        color: colors.white
    },
    arrow: {
        color: colors.lightBlack,
    },
    customWidth: {
        width: 320,
        maxWidth: 320,
        backgroundColor: colors.lightBlack,
        padding: 10,
        boxShadow: ` 0px 4px 9px rgba(168, 173, 198, 0.15)`,
    },
    tooltipPlacement: {
        margin: '8px 0',
    }
}));