import React from "react";
import {InputField} from "../sendMessageComponent/sendMessageComponent.styles";
import {Box, IconButton} from "@material-ui/core";
import { ReactComponent as SendIcon } from "@img/icons/Send.svg";
import {useFacade} from "./sendPlaygroundMessageComponent.hooks";

export interface SendPlaygroundMessageComponentProps {
    message: string;
    handleSubmit: Function;
}

export const SendPlaygroundMessageComponent: React.FC<SendPlaygroundMessageComponentProps> = (props: SendPlaygroundMessageComponentProps) => {
    const [
        {
            message
        },
        handleKeyDown,
        handleMessageChanged,
        handleSendMessage
    ] = useFacade(props.handleSubmit, props.message)

    return (
        <Box display="flex" justifyContent="space-between">
            <InputField
                fullWidth
                multiline
                rowsMax={4}
                value={message}
                id="type-message"
                variant="outlined"
                InputProps={{ className: "flat-scroll" }}
                placeholder="Type message here..."
                onKeyDown={(e) => handleKeyDown(e)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleMessageChanged(e.target.value)
                }
            />
            <Box p={2}>
                <IconButton onClick={() => handleSendMessage()} className="wh-tw-text-primaryV">
                    <SendIcon className="wh-tw-fill-primaryV"/>
                </IconButton>
            </Box>
        </Box>
    )
}