import {useEffect, useState} from "react";
import {Subscription} from "recompose";
import {useHistory} from "react-router-dom";
import {patientsQuery} from "../../../patients/stores/patientsStore";
import {onEmit} from "../../../common/helpers/on-emit";
import {PatientModel} from "../../../patients/models/patient.model";
import {RoutesConstants} from "../../../../constants/route.constants";
import {authQuery} from "../../../auth/stores/auth";
import {conversationsService} from "../../services/conversations.service";
import {
    ConversationAuthorModel,
    ConversationType,
    EmployeeConversationModel
} from "../../models/conversation.models";
import {employeeConversationsQuery} from "../../stores/employeeConversationsStore/employeeConversations.query";
import {Observable} from "rxjs";
import {conversationsViewService} from "../../services/conversationsView.service";
import {navigationService} from "../../../../services/navigation.service";
import {confirmService} from "../../../../services/confirm.service";
import {ManageableMemberViewModel} from "../chatMembersComponent/ManageChatMembersComponent";
import {employeeService} from "../../../employee/services/employees.service";
import {patientsService} from "../../../patients/services/patients.service";
import {getConversationMembers} from "../../helpers/getMembers";
import { roles } from "../../../common/constants/roles";
import { ConversationState } from "../../models/conversationState.models";
import { employeeConversationsStore } from "../../stores/employeeConversationsStore/employeeConversations.store";

interface MenuButton {
    anchorEl: HTMLElement;
    isOpen: boolean;
}

interface StickyChatContainerComponentState {
    menu: MenuButton;
    isLoading: boolean;
    isVisible: boolean;
    isAttachmentsDialogOpen: boolean;
    isScheduledDialogOpen: boolean;
    isActiveUsersDialogOpen: boolean;
    isCollapsed: boolean;
    isCollapsedForAiDemo: boolean;
    isDashboard: boolean;
    isRendering: boolean;
    patientId: number | null;
    patient: PatientModel;
    author: ConversationAuthorModel;
    conversations: EmployeeConversationModel[];
    targetConversation: EmployeeConversationModel;
    isAddUserOpen: boolean;
    members: ManageableMemberViewModel[];
}

let context: StickyChatContainerComponentState = null;

export function useFacade(): [
    StickyChatContainerComponentState,
    Function,
    Function,
    Function,
    Function,
    Function,
    Function,
    () => void,
    (id: number) => void,
    () => boolean,
    (id: number) => void] {
    const history = useHistory();
    const [state, setState] = useState({
        menu: {},
        isLoading: true,
        isVisible: false,
        isAttachmentsDialogOpen: false,
        isScheduledDialogOpen: false,
        isActiveUsersDialogOpen: false,
        isCollapsed: true,
        isCollapsedForAiDemo: true,
        isDashboard: false,
        isRendering: false,
        patient: null,
        author: null,
        conversations: [],
        targetConversation: null,
        isAddUserOpen: false,
        members: []
    } as StickyChatContainerComponentState);

    context = state;

    const getMembers = (isEmployeeAssigned = false) => {
        const members = getConversationMembers(state.targetConversation, isEmployeeAssigned);

        setState(state => ({
            ...state,
            members: members
        }))
    }

    const handleCollapse = (isAiDemo = false) => {
        if (state.targetConversation) {
            setState(state => ({
                ...state,
                isCollapsed: !state.isCollapsed,
                targetConversation: null,
                isCollapsedForAiDemo: isAiDemo ? !state.isCollapsedForAiDemo : true,
            }));
            employeeConversationsStore.updatePatientIdForDashboard(null);
        } else {
            getTargetConversation(context.conversations, state.patientId).subscribe((targetConversation) => {
                if (targetConversation) {
                    if (!state.conversations.find(x=> x.id === targetConversation.id)) {
                        setState(state => ({
                            ...state,
                            conversations: [...state.conversations, targetConversation]
                        }));
                    }
                    setState(state => ({
                        ...state,
                        targetConversation: targetConversation,
                        isCollapsed: !state.isCollapsed,
                        isCollapsedForAiDemo: isAiDemo ? !state.isCollapsedForAiDemo : true
                    }));
                    employeeConversationsStore.updateStickyChatLoadingForDashboard(false)
                }
            });
        }
        setState(state => ({ ...state, isRendering: false }));
    }

    const handleToggleAddUser = () => {
        setState(state => ({ ...state, isAddUserOpen: !state.isAddUserOpen }))
    }

    const canAddUser = (): boolean => {
        if (!state.targetConversation) {
            return false;
        }

        if(authQuery.getRoleId() == roles.provider){
            return true;
        }

        if (!state.targetConversation.proxy) {
            if (!state.patient.assignedEmployees.find(x => x.userId === authQuery.getId())) {
                return false
            }
        }

        return true;
    }

    const handleAddUser = (id: number) => {
        const cb = () => setState(state => ({ ...state, isAddUserOpen: false }));
        conversationsService.addEmployeeToConversationModel({ conversationId: state.targetConversation.id, employeeId: id }).subscribe(() => {
            employeeService.getActive('').subscribe((users) => {
                const newMembers = users.filter(el => el.id === id)
                setState(state => ({
                    ...state,
                    members: [...state.members, { id: newMembers[0].userId, firstName: newMembers[0].firstName, lastName: newMembers[0].lastName, position: '', disableRemove: false }]
                }))
                cb();
            },
                cb)
        },
            cb);
    }

    const handleRemoveUserFromThread = (id: number) => {
        confirmService.confirm('Remove User', 'Are You Sure You Want to Remove this User?', 'Remove User', 'Cancel').subscribe(() => {
            conversationsService.removeEmployeeToConversationModel({ conversationId: state.targetConversation.id, userId: id }).subscribe(() => {
                const newMembersList = state.members.filter(el => el.id !== id)
                setState(state => ({
                    ...state,
                    members: newMembersList
                }))
            });
        })
    }

    const handleMenuToggle = (toShow: boolean, event?: React.MouseEvent<HTMLButtonElement>) => {
        const anchorEl = event != null && event.currentTarget != null ? event.currentTarget : null;
        setState({ ...state, menu: { anchorEl: anchorEl, isOpen: toShow } });
    }

    const handleToggleAttachmentsDialog = (toShow: boolean) => {
        setState({ ...state, isAttachmentsDialogOpen: toShow, menu: { anchorEl: null, isOpen: false } });
    }

    const handleToggleScheduledDialog = (toShow: boolean) => {
        setState({ ...state, isScheduledDialogOpen: toShow, menu: { anchorEl: null, isOpen: false } });
    }

    const handleToggleActiveUsersDialog = (toShow: boolean) => {
        setState({ ...state, isActiveUsersDialogOpen: toShow, menu: { anchorEl: null, isOpen: false } });
    }

    const handleGoToConversation = (conversation: EmployeeConversationModel) => {
        if (conversation?.proxy) {
            handleMenuToggle(false, null)
            navigationService.toConversations(history, conversation?.id);
            setTimeout(() => {
                conversationsService.selectConversation(conversation);
            }, 1000);
        }
    }

    const getTargetConversation = (conversations: EmployeeConversationModel[], patientId: number | null): Observable<EmployeeConversationModel> => {
        return new Observable<EmployeeConversationModel>(observer => {
            if (!patientId) {
                observer.next(null);
                observer.complete();
            }

            const existingConversation = conversations
                .find(x => x.type === ConversationType.HealthCare && x.patients.map(x => x.patientId).includes(patientId));

            if (existingConversation) {
                observer.next(existingConversation);
            } else {
                if (patientId) {
                    conversationsViewService.getPatientHealthCareConversation(patientId).subscribe(
                        (result) => {
                            observer.next(result);
                            observer.complete();
                        },
                        () => {
                            observer.next(null);
                            observer.complete();
                        });
                }
            }
        })
    }

    const handleManageProfileNavigation = (location, id: number = null) => {
        if (location.pathname.includes(RoutesConstants.managePatientProfile)) {
            const path = location.pathname;
            const patientId = Number(path.substring(path.lastIndexOf('/') + 1));
            const patient = patientsQuery.getTargetPatient();

            setState(state => ({
                ...state,
                patientId: patientId,
                isVisible: patient?.id === patientId,
                patient: patient?.id === patientId
                    ? patient
                    : null,
                isCollapsed: true,
                targetConversation: null,
                conversations: [],
                isDashboard: false,
            }));
        } else if (location.pathname.includes(RoutesConstants.dashboard) && id) {
            const patient = patientsQuery.getTargetPatient();

            setState(state => ({
                ...state,
                patientId: id,
                isVisible: patient?.id === id,
                patient: patient?.id === id
                    ? patient
                    : null,
                isCollapsed: true,
                isDashboard: true,
                isRendering: true,
                targetConversation: null,
                conversations: []
            }));
        } else {
            setState(state => ({
                ...state,
                patientId: null,
                isVisible: false,
                isCollapsed: true,
                targetConversation: null,
            }));
        }
    }

    useEffect(() => {
        if (state.isRendering) {
            handleCollapse()
        }

    }, [state.isRendering]);

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<PatientModel>(patientsQuery.targetPatient$, patient => {
                if (patient && patient.id === context.patientId) {

                    setState(state => ({
                        ...state,
                        patient: patient,
                        isVisible: true
                    }))
                }
            }),
            onEmit<ConversationAuthorModel>(employeeConversationsQuery.author$, author => {
                if (author) {
                    setState(state => ({
                        ...state,
                        author: author
                    }))
                }
            }),
            onEmit<EmployeeConversationModel[]>(employeeConversationsQuery.conversations$, () => {
                setState(state => ({
                    ...state,
                    isLoading: !employeeConversationsQuery.isReady(),
                }))
            }),
            onEmit<number>(employeeConversationsQuery.patientIdForDashboard$, patientIdForDashboard => {
                if (patientIdForDashboard) {
                    handleManageProfileNavigation(history.location, patientIdForDashboard)
                }
            })
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe());
        };
    }, []);

    useEffect(() => {
        history.listen((e) => {
            handleManageProfileNavigation(e);
        });

        handleManageProfileNavigation(history.location);

    }, [history]);

    useEffect(() => {
        if (!state.isLoading && !state.conversations.length && state.patientId) {
            const employeeConversations = context.conversations.length > 0 ? context.conversations : employeeConversationsQuery.getConversations()?.filter(i => (i.proxy &&
                i.type !== ConversationType.Internal && i.patients.length > 0) ||
                (i.state === ConversationState.Closed && i.type !== ConversationType.Internal && i.patients.length > 0));
            patientsService.getAll([], [], [], [], [], [], `${state.patientId}`, 0, 1).subscribe(
                (patient) => {
                    const isEmployeeAssigned = Boolean(patient?.[0]?.employees?.find(el => el.userId === authQuery.getId()));
                    if (!isEmployeeAssigned) {
                        getTargetConversation(employeeConversations, state.patientId).subscribe((conversation) => {
                            if (conversation) {
                                setState(state => ({
                                    ...state,
                                    conversations: [...state.conversations, conversation]
                                }));
                            }
                        });
                    } else {
                        setState(state => ({
                            ...state,
                            conversations: [...employeeConversations]
                        }));
                    }
                },
                () => {
                    getTargetConversation(employeeConversations, state.patientId).subscribe((conversation) => {
                        if (conversation) {
                            setState(state => ({
                                ...state,
                                conversations: [...state.conversations, conversation]
                            }));
                        }
                    });
                })
        }
    }, [state.isLoading, state.conversations, state.patientId]);

    useEffect(() => {
        if (state.targetConversation) {
            patientsService.getAll([], [], [], [], [], [], `${state.targetConversation?.patients[0]?.patientId}`, 0, 1).subscribe(
                (patient) => {
                    const isEmployeeAssigned = Boolean(patient?.[0]?.employees?.find(el => el.userId === authQuery.getId()));
                    getMembers(isEmployeeAssigned)

                },
                () => {
                    getMembers()
                })
        }

    }, [state.targetConversation]);

    return [
        state,
        handleCollapse,
        handleMenuToggle,
        handleToggleAttachmentsDialog,
        handleGoToConversation,
        handleToggleScheduledDialog,
        handleToggleActiveUsersDialog,
        handleToggleAddUser,
        handleAddUser,
        canAddUser,
        handleRemoveUserFromThread
    ];
}