import { Box, IconButton, Tooltip } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import clsx from 'clsx';
import React from "react";
import { IErrorState } from "../../../common/validation/error-state";
import { InputModel, LabInputNotificationType } from "../../models/input.models";
import { useStyles, WildHealthTextField } from "./inputComponent.styles";

interface InputComponentProps extends IErrorState {
    input: InputModel;
    label: string;
    handleChanges: Function;
    handleEdit: Function;
}

export const InputComponent: React.FC<InputComponentProps> = (props: InputComponentProps) => {
    const {
        input,
        label,
        errors,
        handleChanges,
        handleEdit
    } = props;

    const classes = useStyles();

    const isDisabled = (input: InputModel): boolean => {
        return !input.isInitialized ? false : !input.editable;
    }

    const getValue = (input: InputModel) => (!input.isInitialized) ? '' : input.value;

    const processValue = (value: string): number | null => value.length ? Number(value) : null;

    const textFieldClasses = clsx(
        input.isChanged ? 'modified' : input.isInitialized ? 'initialized' : '',
        errors[input.name] ? 'error' : ''
    );

    return (
        <Box m={1}>
            <Box
                display="flex"
                alignItems="flex-top"
                justifyContent="space-between"
            >
                <Box className={classes.label}>
                    {label}
                </Box>
                <Box mb={1}>
                {
                    input.isInitialized &&
                    <IconButton
                        id={`input-${input.id}`}
                        size="small"
                        className="icon-button"
                        onClick={() => handleEdit(input)}
                        disabled={!input.isInitialized}
                    >
                        <EditIcon/>
                    </IconButton>
                }
                </Box>
            </Box>
            <WildHealthTextField
                fullWidth
                type="number"
                id={input.name}
                value={getValue(input)}
                error={!!errors[input.name]}
                disabled={isDisabled(input)}
                className={textFieldClasses}
                onChange={(v) => handleChanges(input, processValue(v.target.value))}
            />
            <Box display="flex" alignItems="flex-top" mt={1}>
                <Box>
                    <Tooltip title={input.notification ? input.notification.message : ''}>
                    {
                        input.notification?.notificationType === LabInputNotificationType.Error ?
                        <ErrorIcon style={{color: "red"}}/> :
                        input.notification?.notificationType === LabInputNotificationType.Warning ?
                        <WarningIcon style={{color: "#ffc02b"}}/> : <></>
                    }
                    </Tooltip>
                </Box>
                <Box className={clsx(classes.message, classes.errorMessage)}>
                    {errors[input.name] ?? ' '}
                </Box>
            </Box>
        </Box>
    );
}
