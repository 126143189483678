import {useEffect, useState} from "react";
import { Subscription } from "recompose";
import {
    HealthFormModel,
    QuestionnaireResultModel,
    QuestionnaireShortModel,
    QuestionnaireType,
} from "../../models/questionnaire.models";
import { onEmit } from "../../../common/helpers/on-emit";
import { questionnaireService } from "../../services/questionnaire.service";
import { questionnaireQuery } from "../../stores/questionnaireStore";
import {navigationService} from "../../../../services/navigation.service";
import {PatientModel} from "../../../patients/models/patient.model";
import {patientsQuery} from "../../../patients/stores/patientsStore";
import {confirmService} from "../../../../services/confirm.service";
import Welcome from "@img/icons/welcome.svg";

interface QuestionnaireComponentState {
    isLoading: boolean;
    isGoBack: boolean;
    pendingQuestionnaires: QuestionnaireResultModel[];
    availableQuestionnaires: QuestionnaireShortModel[];
    completedQuestionnaires: QuestionnaireResultModel[];
    patient: PatientModel;
    healthForms: HealthFormModel[];
    targetResult: QuestionnaireResultModel;
}

export function useFacade(patientId: number, resultId: number): [
    QuestionnaireComponentState,
    (resultId: number) => void,
    (questionnaireId: number, isDialogOpen: false) => void,
    () => void
] {
    const [state, setState] = useState({
        isLoading: true,
        patient: patientsQuery.getTargetPatient(),
        pendingQuestionnaires: [],
        completedQuestionnaires: [],
    } as QuestionnaireComponentState);

    const loadQuestionnairesAndResults = () => {
        questionnaireService.getHealthFormsByPatientId(patientId).subscribe();

        questionnaireService.getResults(patientId).subscribe(() => {
            questionnaireService.getAvailable(patientId).subscribe(() => {
                setState(state => ({
                    ...state,
                    isLoading: false
                }));

                if (resultId) {
                    handleGoToQuestionnaireResult(resultId)
                }
            });
        });
    }

    const handleGoToQuestionnaireResult = (resultId: number) => {
        setState(state => ({
            ...state,
            isLoading: true
        }));

        const subscriptions: Subscription[] = [
            onEmit<QuestionnaireResultModel[]>(questionnaireQuery.questionnaireResultsByEmployee$, results => {
                setState(state => ({
                    ...state,
                    targetResult: results[0],
                }));
            })
        ];

        questionnaireService.getResultsByEmployee(patientId, resultId).subscribe(() => {
                setState(state => ({
                    ...state,
                    isLoading: false
                }));
                navigationService.goUp();
        });

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    const handleGoBackToQuestionnaires = () => {
        setState({
            ...state,
            targetResult: null,
            isLoading: true
        });

        navigationService.goUp();

        loadQuestionnairesAndResults();
    }

    const handleStartQuestionnaire = (questionnaireId: number, isDialogOpen = false) => {
        const questionnaire = state.availableQuestionnaires.find(x => x.id === questionnaireId)

        setState(state => ({...state, isLoading: true}));

        navigationService.goUp();

        questionnaireService.startByIdOnBehalf(questionnaireId, patientId, questionnaire?.appointmentId).subscribe((result) => {
            if (isDialogOpen) {
                confirmService.confirmInfo(
                    `Before we start`,
                    "Please note that not all questions may be relevant to your specific health situation. If you can’t answer a specific question or if it’s not applicable, feel free to put in 0 or N/A. We'll discuss it during your initial visit and can adjust and personalize your responses before providing you with your full health report.",
                    Welcome,
                    'Sounds good')
                    .subscribe();
            }

            setState(state => ({...state, isLoading: false}));

            handleGoToQuestionnaireResult(result.id);
        });
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<QuestionnaireResultModel[]>(questionnaireQuery.questionnaireResults$, results => {
                setState(state => ({
                    ...state,
                    pendingQuestionnaires: results.filter(q => !q.submittedAt && q.questionnaire.type !== QuestionnaireType.HealthForms),
                    completedQuestionnaires: results.filter(q => q.submittedAt)
                }));
            }),
            onEmit<QuestionnaireShortModel[]>(questionnaireQuery.availableQuestionnaires$, available => {
                setState(state => ({
                    ...state,
                    availableQuestionnaires: available.filter(x => x.type !== QuestionnaireType.HealthForms)
                }));
            }),
            onEmit<HealthFormModel[]>(questionnaireQuery.healthForms$, healthForms => {
                setState(state => ({...state,
                    healthForms: healthForms,
                }));
            }),
            onEmit<PatientModel>(patientsQuery.targetPatient$, patient => {
                if (patient?.id === Number(patientId)) {
                    setState(state => ({
                        ...state,
                        patient: patient
                    }))
                }
            })
        ];

        loadQuestionnairesAndResults();

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, [patientId]);

    return [
        state,
        handleGoToQuestionnaireResult,
        handleStartQuestionnaire,
        handleGoBackToQuestionnaires
    ];
}