import {
    Box,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    MenuItem,
    Select,
    TextField
} from '@material-ui/core';
import React from 'react';
import { PermissionType, Practice } from '../../../auth/models/auth.enums';
import { OptionalComponent } from '../../../common/components/OptionalComponent';
import { PaymentPlanDisplayComponent } from '../../../common/components/paymentPlanDisplayComponent/PaymentPlanDisplayComponent';
import { ProtectedElement } from '../../../common/components/ProtectedElement';
import { UpdateAddressForm } from '../../../common/components/updateAddressForm/UpdateAddressForm';
import { UpdateUserForm } from '../../../common/components/UpdateUserForm';
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { WildHealthSwitcher } from "../../../common/components/WIldHealthSwitcher";
import { TransferPatientToLocationComponent } from "../../../locations/components/transferPatientToLocationComponent/TransferPatientToLocationComponent";
import { EmployeeType } from '../../models/employee.enums';
import { AssignEmployeesComponent } from '../assignEmployeesComponent/AssignEmployeesComponent';
import { PatientOptionsComponent } from "../patientOptionsComponent/PatientOptionsComponent";
import { useFacade } from "./patientInformationComponent.hooks";
import commonUseStyles from '../../../common/styles/common.styles';
import { PatientJourneyStatusNames } from '../../../patients/models/patient.model';
import { EmployeeShortModel } from '../../models/employee.models';
import { fullName } from '../../../common/helpers/display-name';

interface PatientInformationComponentProps {
    patientId: number;
}

export const PatientInformationComponent: React.FC<PatientInformationComponentProps> = (props: PatientInformationComponentProps) => {
    const { patientId } = props;
    const [
        {
            isLoading,
            isEmployeesLoading,
            patient,
            patientDraft,
            employees,
            errors,
            editMode,
            leadSources
        },
        handleEmployeeSelect,
        handleChanges,
        handleOptionsChanges,
        handleSubmit,
        handleEditModeToggle,
        handleDiscardChanges,
        handleLeadSourceChanges
    ] = useFacade(patientId);
    const commonClasses = commonUseStyles();

    if (isLoading) {
        return (
            <Box p={5}>
                <WildHealthLinearProgress />
            </Box>
        )
    }

    const displayOtherLeadSource = () => {
        const leadSource = editMode
            ? leadSources.find(x => x.id === patientDraft.leadSource?.leadSourceId)
            : leadSources.find(x => x.id === patient.leadSource?.leadSourceId);

        if (leadSource === undefined || !leadSource.isOther) {
            return <></>
        }

        return <>
            <Box mt={3}>
                <TextField
                    fullWidth
                    disabled={!editMode}
                    required
                    size="small"
                    type="text"
                    label="Podcast"
                    InputProps={{ className: 'input' }}
                    id="leadSource.otherLeadSource"
                    variant="outlined"
                    helperText={errors['leadSource.otherLeadSource']}
                    error={!!errors['leadSource.otherLeadSource']}
                    value={editMode ? patientDraft.leadSource?.otherLeadSource : patient.leadSource?.otherLeadSource}
                    onChange={(v) => {
                        handleLeadSourceChanges(v.target.id, v.target.value)
                    }}
                />
            </Box>
            <Box mt={2}>
                <TextField
                    fullWidth
                    disabled={!editMode}
                    size="small"
                    type="text"
                    label="Which podcast?"
                    InputProps={{ className: 'input' }}
                    id="leadSource.podcastSource"
                    variant="outlined"
                    value={editMode ? patientDraft.leadSource?.podcastSource : patient.leadSource?.podcastSource}
                    onChange={(v) => {
                        handleLeadSourceChanges(v.target.id, v.target.value)
                    }}
                />
            </Box>
        </>
    }

    const displayAssignedEmployees = (employees: EmployeeShortModel[], assignedEmployeeIds: number[]) => {
        const assignedEmployees = employees.filter((e) => assignedEmployeeIds.includes(e.id));
        if (assignedEmployees.length) {
            return assignedEmployees.map((e) => fullName(e)).join(", ");
        }
        return 'N/A';
    }

    return (
        <Box p={5}>
            <Box display="flex" pb={2}>
                <ProtectedElement
                    element={
                        <FormControlLabel
                            label="Edit"
                            control={
                                <WildHealthSwitcher
                                    checked={editMode}
                                    onChange={() => handleEditModeToggle()}
                                    name="editModeToggle" />
                            }
                        />}
                    permissions={[PermissionType.Coaching, PermissionType.ManagePatients]}
                />
            </Box>
            <Grid container spacing={3} justify="space-between">
                <Grid item xs={12} md={4} lg={4}>
                    <Box my={2} className={commonClasses.primaryTitle}>
                        Information:
                    </Box>
                    <UpdateUserForm
                        user={editMode ? patientDraft : patient}
                        prefix={""}
                        emailIsReadonly={false}
                        errors={errors}
                        handleChanges={editMode ? handleChanges : null}
                    />
                </Grid>
                <Grid item xs={12} md={2} lg={2}>

                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <OptionalComponent>
                        <Box display="flex" alignItems="center">
                            <Box width={0.4}>Journey Status:</Box>
                            <Box>{PatientJourneyStatusNames.get(patient.journeyStatus)}</Box>
                        </Box>
                    </OptionalComponent>
                    <Box mt={2}>
                        <TransferPatientToLocationComponent patient={patient} />
                    </Box>
                    <Box mt={2}>
                        <PaymentPlanDisplayComponent patient={patient} />
                    </Box>
                    <Box>
                        <ProtectedElement element={
                            <Box mt={2}>
                                <Box display="flex" alignItems="center">
                                    <Box width={0.4}>
                                        Lead source:
                                    </Box>
                                    <Box width={0.6}>
                                        <Box width={0.8}>
                                            <FormControl
                                                disabled={!editMode}
                                                color='primary'
                                                variant="outlined"
                                                required
                                                error={!!errors[`selectedLeadSource`]}
                                                size="small" fullWidth>
                                                <Select
                                                    style={{ background: '#FFF' }}
                                                    id="leadSource.leadSourceId"
                                                    value={editMode
                                                        ? patientDraft.leadSource?.leadSourceId ?? 0
                                                        : patient.leadSource?.leadSourceId ?? 0
                                                    }
                                                    error={!!errors[`leadSource.leadSourceId`]}
                                                    onChange={(v) => handleLeadSourceChanges("leadSource.leadSourceId", v.target.value.toString())}>
                                                    {
                                                        leadSources.map((source, index) => {
                                                            return <MenuItem key={index} value={source.id}>{source.name}</MenuItem>
                                                        })
                                                    }
                                                </Select>
                                                <FormHelperText>{errors[`selectedLeadSource.leadSourceId`]}</FormHelperText>
                                            </FormControl>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box display="flex" alignItems="center">
                                    <Box width={0.4}>

                                    </Box>
                                    <Box width={0.6}>
                                        <Box width={0.8}>
                                            {
                                                displayOtherLeadSource()
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        } permissions={[PermissionType.PatientAnalytics]}>

                        </ProtectedElement>
                        <OptionalComponent practiceId={Practice.WildHealth}>
                            <Box>
                                <Box pt={1.5}>
                                    <PatientOptionsComponent
                                        disabled={!editMode}
                                        options={editMode ? patientDraft.options : patient.options}
                                        optionName='isFellow'
                                        title='Fellowship Patient:'
                                        handleChanges={handleOptionsChanges}
                                    />
                                </Box>
                            </Box>
                        </OptionalComponent>
                    </Box>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <Box>
                        <Box my={2} className={commonClasses.primaryTitle}>
                            Billing address:
                        </Box>
                        <UpdateAddressForm
                            address={editMode ? patientDraft.billingAddress : patient.billingAddress}
                            prefix={"billingAddress."}
                            errors={errors}
                            handleChanges={editMode ? handleChanges : null} />
                    </Box>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <Box>
                        <Box my={2} className={commonClasses.primaryTitle}>
                            Shipping address:
                        </Box>
                        <UpdateAddressForm
                            address={editMode ? patientDraft.shippingAddress : patient.shippingAddress}
                            prefix={"shippingAddress."}
                            errors={errors}
                            handleChanges={editMode ? handleChanges : null} />
                    </Box>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                    {
                        isEmployeesLoading ?
                            <Box p={5}>
                                <WildHealthLinearProgress />
                            </Box> : [
                                <Box mt={3} key='coaches'>
                                    <Box my={2} className={commonClasses.primaryTitle}>
                                        ASSIGNED COACHES:
                                    </Box>
                                    <Box mt={2} display="flex">
                                        <Box mr={3}>
                                            <h4>Assigned:</h4>
                                        </Box>
                                        <Box>
                                            <h4>{displayAssignedEmployees(employees.filter(c => c.type === EmployeeType.HealthCoach), patient.assignedEmployees.map(el => el.id))}</h4>
                                        </Box>
                                    </Box>
                                    <Box pt={1}>
                                        <AssignEmployeesComponent
                                            selectedEmployeesIds={editMode ? patientDraft.assignedEmployees.map(el => el.id) : patient.assignedEmployees.map(el => el.id)}
                                            handleEmployeeSelect={handleEmployeeSelect}
                                            disabled={!editMode}
                                            employees={employees.filter(c => c.type === EmployeeType.HealthCoach)}
                                        />
                                    </Box>
                                </Box>,
                                <Box mt={3} key='providers'>
                                    <Box my={2} className={commonClasses.primaryTitle}>
                                        ASSIGNED PROVIDERS:
                                    </Box>
                                    <Box mt={2} display="flex">
                                        <Box mr={3}>
                                            <h4>Assigned:</h4>
                                        </Box>
                                        <Box>
                                            <h4>{displayAssignedEmployees(employees.filter(c => c.type === EmployeeType.Provider), patient.assignedEmployees.map(el => el.id))}</h4>
                                        </Box>
                                    </Box>
                                    <Box pt={1}>
                                        <AssignEmployeesComponent
                                            selectedEmployeesIds={editMode ? patientDraft.assignedEmployees.map(el => el.id) : patient.assignedEmployees.map(el => el.id)}
                                            handleEmployeeSelect={handleEmployeeSelect}
                                            disabled={!editMode}
                                            employees={employees.filter(c => c.type === EmployeeType.Provider)}
                                        />
                                    </Box>
                                </Box>
                            ]
                    }
                    <Box>
                        {
                            editMode &&
                            <Box display="flex" pt={5}>
                                <Box>
                                    <WildHealthButton id="patient-information-discard" color="tertiary" onClick={() => handleDiscardChanges()}>
                                        Discard
                                    </WildHealthButton>
                                </Box>
                                <Box ml={2}>
                                    <WildHealthButton id="patient-information-save" onClick={() => handleSubmit()}>
                                        Save Changes
                                    </WildHealthButton>
                                </Box>
                            </Box>
                        }
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}