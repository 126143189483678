export class DnaFilesFilters {
    public static all = "ALL";
    public static synchronized = "SYNCHRONIZED";
    public static notSynchronized = "NOT_SYNCHRONIZED";
    public static failed = "FAILED";
}

export const DnaFilesSynchronizationFilters = new Map<string, string>([
    ["All", DnaFilesFilters.all],
    ['Synchronized files', DnaFilesFilters.synchronized],
    ['Not Synchronized files', DnaFilesFilters.notSynchronized],
    ['Failed', DnaFilesFilters.failed]
]);

export const DnaFileSynchronizationStatuses = new Map<string, any>([
    ['Yes', true],
    ['No', false]
]);