import { notWhitespaceValidationRule, stringLengthValidationRule, linkValidationRule } from "../../../common/validation/general-validation-rules";
import { GeneralValidator } from "../../../common/validation/general-validator";

export class SupplementComponentFieldNames {
    static readonly itemName: string = 'name';
    static readonly dosage: string = 'dosage';
    static readonly instructions: string = 'instructions';
    static readonly purchaseLink: string = 'purchaseLink';
}

export const supplementComponentValidator = new GeneralValidator({
    [SupplementComponentFieldNames.itemName]: [stringLengthValidationRule(1, 250), notWhitespaceValidationRule()],
    [SupplementComponentFieldNames.dosage]: [stringLengthValidationRule(0, 250), notWhitespaceValidationRule()],
    [SupplementComponentFieldNames.instructions]: [stringLengthValidationRule(0, 250), notWhitespaceValidationRule()],
    [SupplementComponentFieldNames.purchaseLink]: [stringLengthValidationRule(0, 1000), notWhitespaceValidationRule(), linkValidationRule]
});
