import {
    Box,
    Divider,
    Grid,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import React from 'react';
import { AuthenticatedLayoutComponent } from '../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from "../../../../models/menu.models";
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { useFacade } from "./patientHealthFormsPage.hooks";
import { useStyles } from "./patientHealthFormsPage.styles";
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { HealthFormWelcome } from '../../../common/dialogs/healthFormModal/HealthFormWelcome'
import { QuestionnaireCardComponent } from '../../components/questionnaireCardComponent/QuestionnaireCardComponent';
import { CompletedQuestionnaireComponent } from '../../components/completedQuestionnaireComponent/CompletedQuestionnaireComponent';
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from 'clsx';
import { renderEmptyValue } from '../../../common/helpers/empty-value';
import { QuestionnaireStatusType, QuestionnaireType } from '../../models/questionnaire.models';
import { FeatureFlag } from '../../../common/components/featureFlags/featureFlags.models';
import { WildHealthTabControl } from "../../../common/components/wildHealthTabControl/WildHealthTabControl";
import { TabItem } from "../../../common/components/wildHealthTabControl/wildHealthTabControl.hooks";
import { useStylesMobileWidthTabs } from "../../../common/components/wildHealthTabControl/wildHealthTabControl.styles";
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isFeatureFlag } from '../../../common/components/featureFlags/featureFlags';

export const PatientHealthFormsPage: React.FC = () => {
    const classes = useStyles();
    const [
        {
            healthForms,
            isDialogOpened,
            followingQuestionnaires,
            availableQuestionnaires,
            completedQuestionnaires,
            banner,
            isAvailableLoading,
            isResultsLoading,
            isHealthFormsLoading,
        },
        handleGoToQuestionnaire,
        handleFinishQuestionnaire,
        handleStartQuestionnaire,
        handleCloseDialog,
        handleGoToBannerQuestionnaire
    ] = useFacade();

    const commonClasses = commonUseStyles();
    const tabsStyle = useStylesMobileWidthTabs();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const featureFlags = useFlags();
    const isQuestionnairesUseInitial = isFeatureFlag(featureFlags, FeatureFlag.QuestionnairesUseIntial);

    const isLoading = isAvailableLoading && isResultsLoading && isHealthFormsLoading;

    const internalQuestionnaires = isQuestionnairesUseInitial ? [...followingQuestionnaires, ...availableQuestionnaires] : []
    const healthFormsSorted = [
        ...healthForms?.filter(el => el.questionnaireStatusType === QuestionnaireStatusType.InCompleted),
        ...internalQuestionnaires,
        ...healthForms?.filter(el => el.questionnaireStatusType === QuestionnaireStatusType.New),
        ...healthForms?.filter(el => el.questionnaireStatusType === QuestionnaireStatusType.Completed),

    ]

    const questionnaireComponent = (item) => {
        switch (item.type) {
            case QuestionnaireType.HealthForms:
                return <QuestionnaireCardComponent
                    resultId={item.resultId}
                    completed={item.questionnaireStatusType === QuestionnaireStatusType.Completed}
                    questionnaire={item}
                    handleStart={handleStartQuestionnaire}
                    handleComplete={handleFinishQuestionnaire}
                    handleView={handleGoToQuestionnaire}
                />;
            case QuestionnaireType.HealthLog:
            case QuestionnaireType.Initial:
            case QuestionnaireType.FollowUpCallForms:
                return <QuestionnaireCardComponent
                    completed={false}
                    questionnaire={item}
                    handleStart={handleStartQuestionnaire}
                    handleComplete={handleFinishQuestionnaire}
                    handleView={handleGoToQuestionnaire}
                />;
            default:
                return <QuestionnaireCardComponent
                    resultId={item.id}
                    completed={false}
                    questionnaire={item.questionnaire}
                    handleStart={handleStartQuestionnaire}
                    handleComplete={handleFinishQuestionnaire}
                    handleView={handleGoToQuestionnaire}
                />;
        }
    }

    const renderFollowing = () => {
        return (
            <Grid container spacing={4}>
                {
                    healthFormsSorted.length ?
                        <>
                            {
                                healthFormsSorted.map((item, index) => (
                                    <Grid item md={6} xs={12} key={index}>
                                        {questionnaireComponent(item)}
                                    </Grid>
                                ))
                            }
                        </> : <></>
                }
            </Grid>
        )
    }

    const renderCompleted = () => {
        return (
            <>
                {!isSmallScreen && <Box>
                    <span className={clsx(commonClasses.size18, commonClasses.textMedium)}>Previously completed health forms</span>
                </Box>}
                {
                    completedQuestionnaires.length
                        ? <Box mt={2}>
                            <Grid container spacing={4}>
                                {
                                    completedQuestionnaires.map((item, index) =>
                                        <Grid item md={6} xs={12} key={index}>
                                            <CompletedQuestionnaireComponent
                                                questionnaireResult={item}
                                                handleView={handleGoToQuestionnaire}
                                            />
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Box>
                        : <Box className={classes.noCompleted}>
                            {
                                renderEmptyValue('There`s no completed health forms yet.')
                            }
                        </Box>
                }

            </>
        )
    }

    const bannerComponent: JSX.Element = (
        <Box className={classes.banner}>
            <Box id="patient-health-forms-next-up" className={classes.nextUp} onClick={() => handleGoToBannerQuestionnaire()}>
                Next Up
            </Box>
            <Box className={clsx(classes.bannerMessage, commonClasses.size16, commonClasses.textRegular, commonClasses.colorBlack)}>
                <Box>{banner?.message}</Box>
            </Box>
            <Box width={0.1} />
        </Box>
    )

    const renderContent = () => {
        if (isSmallScreen) {
            const tabs: TabItem[] = [
                {
                    title: "All",
                    content: (
                        <Box mt="20px">
                            {
                                banner && bannerComponent
                            }
                            <Box mt="20px">
                                {
                                    renderFollowing()
                                }
                            </Box>
                        </Box>
                    ),
                },
                {
                    title: "Previously Completed",
                    content: (
                        <Box>
                            {
                                renderCompleted()
                            }
                        </Box>
                    ),
                },
            ];
            return (
                <Box width="100%" mb="20px">
                    <WildHealthTabControl items={tabs} className={tabsStyle} />
                </Box>
            );
        } else {
            return (
                <Box>
                    {
                        banner && bannerComponent
                    }
                    <Box mb={5} mt={5}>
                        {
                            renderFollowing()
                        }
                    </Box>
                    <Divider />
                    <Box mt={5}>
                        {
                            renderCompleted()
                        }
                    </Box>
                </Box>
            );
        }
    };

    return (
        <AuthenticatedLayoutComponent
            hideBackground
            title={TitlesService.questionnaireTitle}
            pageName={PagesNamesService.medicalHistory}
            selectedMenuItem={MenuItemTypes.HealthForms}
        >
            <Box p={isSmallScreen ? 2 : '30px'} className="wh-tw-bg-white">
                <Box mb={isSmallScreen ? "20px" : 5}>
                    <span className={clsx(commonClasses.size24, commonClasses.textSemiBold, commonClasses.colorBlack)}>Health Forms</span>
                </Box>
                {
                    isLoading
                        ? <WildHealthLinearProgress />
                        : renderContent()
                }
            </Box>
            <HealthFormWelcome
                opened={isDialogOpened}
                handleClose={() => handleCloseDialog()}
            />
        </AuthenticatedLayoutComponent>
    )
}
