import { IconButton } from "@material-ui/core";
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import { colors } from "../../constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkboxDropdownWrapper: {
      cursor: "pointer",
    },
    placeHolder: {
      display: "flex",
      alignItems: "center",
      border: `solid 1px ${colors.gray400}`,
      borderRadius: 4,
      fontFamily: "Be Vietnam Pro",
      fontSize: 16,
      height: `48px !important`
    },
    placeHolderOpen: {
      borderColor: colors.main,
    },
    menuPaper: {
      boxShadow: "0px 4px 9px rgba(168, 173, 198, 0.15) !important",
    },
    [theme.breakpoints.down("xs")]: {
      placeHolder: {
        border: 0,
        fontSize: 16,
      },
      placeHolderOpen: {
        borderColor: "none",
      },
    },
  })
);

export const FilterButton = withStyles((theme: Theme) => ({
  root: {
    border: `solid 1px ${colors.main}`,
    borderRadius: 50,
    "&:hover": {
      backgroundColor: `${colors.main}11`,
    },
    "&:disabled": {
      color: colors.gray2,
      backgroundColor: colors.disabled,
      border: `1px solid ${colors.gray1}44`,
    },
  },
}))(IconButton);