import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        progress: {
            width: '100%',
            height: 'auto',
            display: 'flex',
        },      
        segment: {
              height: 22,
              paddingLeft: 5,
              opacity: 0.75,
              marginLeft: 2,
        },
        value: {
            position: 'relative',
            width: 27,
            height: 27,
            borderRadius: '50%',
            border: `1px solid ${colors.main}`,
            background: colors.white,
            top: '-24px',
            textAlign: 'center',
          }
    }),
);