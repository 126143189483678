import {HistoryAndPhysicalContent, MdmModel, NoteContentModel, NoteModel} from "../../models/notes.models";
import {useEffect, useState} from "react";
import {notesQuery} from "../../stores/notes";
import {useHistory} from "react-router";
import {navigationService} from "../../../../services/navigation.service";
import {historyAndPhysicalPdfService} from "../../services/notes-pdf.service";
import {Subscription} from "recompose";
import {onEmit} from "../../../common/helpers/on-emit";
import {notesService} from "../../services/notes.service";
import {patientsSupplementsService} from "../../../patientSupplements/services/patientSupplements.service";
import {patientsMedicationsService} from "../../../patientSupplements/services/patientMedications.service";
import { GetMaximumVersionOfNote } from "../../helpers/noteHelpers";
import { mdmCodesService } from "../../services/mdmCodes.service";
import {useFlags} from "launchdarkly-react-client-sdk";

interface PatientHistoryAndPhysicalNotePageState {
    note: NoteModel;
    mdmCodes: MdmModel[];
    content: NoteContentModel;
    internalContent: HistoryAndPhysicalContent;
    isPdfDownloading: boolean;
}

export function useFacade(noteId: number): [
    PatientHistoryAndPhysicalNotePageState,
    () => void,
    (note: NoteModel, content: NoteContentModel) => void
] {
    const [state, setState] = useState({
        note: notesQuery.getNote(noteId),
        content: null,
        internalContent: null,
        mdmCodes: [],
        isPdfDownloading: false
    } as PatientHistoryAndPhysicalNotePageState);

    const flags = useFlags();

    const history = useHistory();

    const handleGoBack = () => {
        navigationService.toPatientNotes(history);
    }

    const handleDownloadPdf = (note: NoteModel, content: NoteContentModel) => {
        setState({
            ...state,
            isPdfDownloading: true
        });

        const cb = () => setState({ ...state, isPdfDownloading: false });

        const noteForDownload = GetMaximumVersionOfNote(note);
        notesService.downloadNote(noteForDownload.id, noteForDownload.name, false).subscribe(
            cb,
            () => {
                historyAndPhysicalPdfService.downloadNotePdf(noteForDownload, JSON.parse(content.content), false, noteForDownload.id, flags).subscribe(cb, cb);
            }
        )
    }

    /**
     * Load all ongoing cares
     * Manage subscriptions with auto-cleanup
     */
    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<NoteContentModel>(notesQuery.targetContent$, content => {
                if (content && content.noteId === noteId) {
                    setState(state => ({
                        ...state,
                        content: content,
                        internalContent: JSON.parse(content.content)
                    }));
                }
            }),
            onEmit<NoteModel[]>(notesQuery.notes$, () => {
                const note = notesQuery.getNote(noteId);
                setState(state => ({...state, note: note}));
                if (note){
                    notesService.getContentByPatient(GetMaximumVersionOfNote(note).id).subscribe(result =>
                        setState(state => ({
                            ...state,
                            content: result,
                            internalContent: JSON.parse(result.content)
                        }))
                    );

                    mdmCodesService.get(note.type).subscribe(codes => {
                        setState(state => ({ ...state, mdmCodes: codes }))
                    })
                }
            })
        ];

        patientsSupplementsService.get().subscribe();
        patientsMedicationsService.get().subscribe();

        if (!state.note) {
            notesService.getByPatient().subscribe();
        }

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, [noteId]);

    return [state, handleGoBack, handleDownloadPdf];
}