import React from 'react';
import { QuestionnaireModel, QuestionnaireResultModel } from '../../models/questionnaire.models';
import { QuestionnaireComponent } from '../questionnaireComponent/QuestionnaireComponent';
import { detailedQuestionnaireValidator } from './detailedQuestionnaireSurveyComponent.validator';
import { detailedQuestionnaireSections } from '../../static/detailedQuestionnaire.static';
import { Gender } from "../../../common/models/user.models";

export interface DetailedQuestionnaireSurveyComponent {
    questionnaire: QuestionnaireModel;
    result: QuestionnaireResultModel;
    gender: Gender;
    patientId?: number;
    handleGoBack?: Function;
}

export const DetailedQuestionnaireSurveyComponent: React.FC<DetailedQuestionnaireSurveyComponent> = (props: DetailedQuestionnaireSurveyComponent) => {
    const {questionnaire, result, gender, patientId, handleGoBack} = props;

    return (
        <QuestionnaireComponent
            questionnaire={questionnaire}
            result={result}
            sections={detailedQuestionnaireSections}
            validator={detailedQuestionnaireValidator}
            gender={gender}
            patientId={patientId}
            handleGoBack={handleGoBack}
        />
    )
}