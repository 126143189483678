import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { colors } from "../../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: colors.mediumGray,
      fontSize: 14
    },
    buttonText: {
      marginLeft: '6px',
      textTransform: 'capitalize',
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      },
    },
    textGray: {
      color: colors.gray1,
      fontSize: 14,
      minWidth: 118,
    },
    breakWord: {
      wordBreak: 'break-word',
      fontSize: 14,
      color: colors.linkBlack,
      paddingRight: 8,
      paddingLeft: 8
    },
    breakGrayWord: {
      wordBreak: 'break-word',
      fontSize: 14,
      color: colors.gray1,
      paddingRight: 8,
      paddingLeft: 8
    },
    notificationToolTipText: {
      fontFamily: 'Be Vietnam Pro',
      fontSize: 12,
      fontWeight: 400,
      color: colors.white,
    },
    tooltipPlacement: {
      margin: '8px 0',
    },
    customWidth: {
      width: '307px',
      maxWidth: '307px',
      backgroundColor: colors.lightBlack,
      padding: 10,
      borderRadius: 2
    },
    arrow: {
      color: colors.lightBlack,
    },
    dividerSection: {
      marginTop: 22,
      marginBottom: 25,
      [theme.breakpoints.down('xs')]: {
        marginTop: 16
      },
    },
    renderDivider: {
      backgroundColor: colors.stroke,
    },
  })
);


