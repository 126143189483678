import { Box, useTheme, useMediaQuery } from '@material-ui/core';
import moment from "moment";
import React from 'react';
import { logoService } from "../../../services/logo.service";
import { HealthReportMode, HealthReportStatus } from "../models/healthReport.models";
import { HealthReportPageProps } from "./healthReportPageProps";
import { authQuery } from "../../auth/stores/auth";
import commonUseStyles from "../../common/styles/common.styles";
import clsx from "clsx";
import { toCurrentTimeZone } from "../../timezones/helpers/timezone";
import { colors } from "../../common/constants/colors";
import { RoutesConstants } from '../../../constants/route.constants';
import { ImageConstants } from '../../../constants/image.constants';
import img from "@img/WH_Cover_Image.jpg";

export const HealthReportStartPage: React.FC<HealthReportPageProps> = (props: HealthReportPageProps) => {
    const { report } = props;
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const displayDate = () => {
        return report.status.status === HealthReportStatus.Preparing ? "" : moment(toCurrentTimeZone(report.status.date)).format("MMMM DD, yyyy");
    }

    const displayTime = () => {
        return moment(toCurrentTimeZone(report.status.date)).format("h:mm a");
    }

    const displayName = () => {
        return `${report.owner.firstName} ${report.owner.lastName}`;
    }

    const displayReviewerName = () => {
        return `${report.reviewer.firstName} ${report.reviewer.lastName}`;
    }

    const displayReportInfo = () => {
        if (!!report.reviewer && report.status.status === HealthReportStatus.Submitted) {
            return <Box display="flex">
                <Box width="70%"></Box>
                <Box borderLeft='4px solid' borderColor={colors.main}>
                    <Box pl={2} className="text-medium" color={colors.main}>{displayReviewerName()}</Box>
                    <Box pl={2} color={colors.gray1}>{displayDate()}</Box>
                    <Box pl={2} color={colors.gray1}>{displayTime()}</Box>
                </Box>
            </Box>
        }
        return <></>
    }

    return (
        props.mode !== HealthReportMode.PrintMode && isSmallScreen ? (
            <div className="flat-scroll">
                {!authQuery.isLicensingPractice() &&
                    <Box height="71px">
                        <Box display="flex" height={'100%'} justifyContent="center" alignItems="center"
                            className="report-header">
                            <Box className="mobile-logo">
                                <img src={logoService.getLogo().healthReport} alt="img" />
                            </Box>
                        </Box>
                    </Box>
                }
                <Box display='flex' flexDirection="column" justifyContent="space-between">
                    <Box>
                        <Box>
                            <img src={RoutesConstants.cdnBaseLink + ImageConstants.whCoverImagePath} style={{ width: '100%' }} alt="img" />
                        </Box>
                        <Box mt={4} mb={6}>
                            <Box width={1} textAlign="center">
                                <span className="text-medium"
                                    style={{ color: '#1F5463', fontSize: '18px' }}> PERSONALIZED HEALTH REPORT </span>
                            </Box>
                            <Box mt={4} textAlign="center">
                                <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeMediumReport)}> Created Exclusively For </span>
                            </Box>
                            <Box mt={1.2} textAlign="center">
                                <span className="text-medium size-1_25">{displayName()}</span>
                            </Box>
                            <Box mt={1.2} textAlign="center">
                                <span className="text-medium size-1_25">{displayDate()}</span>
                            </Box>
                        </Box>
                    </Box>
                    {displayReportInfo()}
                </Box>
            </div>
        ) : (
            <div className="page-a4 flat-scroll">
                {!authQuery.isLicensingPractice() &&
                    <Box height="11%">
                        <Box display="flex" height={'100%'} justifyContent="center" alignItems="center"
                            className="report-header">
                            <Box className="logo">
                                <img src={logoService.getLogo().healthReport} alt="img" />
                            </Box>
                        </Box>
                    </Box>
                }
                <Box height={authQuery.isLicensingPractice() ? "97%" : "86%"} display='flex' flexDirection="column" justifyContent="space-between">
                    <Box>
                        <Box>
                            {
                                props.mode === HealthReportMode.PrintMode
                                    ? <img src={img} style={{ width: '100%' }} alt="img" />
                                    : <img src={RoutesConstants.cdnBaseLink + ImageConstants.whCoverImagePath} style={{ width: '100%' }} alt="img" />
                            }
                        </Box>
                        <Box height="300px" mt={7}>
                            <Box width={1} textAlign="center">
                                <span className="size-1_5 text-light-medium"
                                    style={{ color: '#1f5463', fontSize: '39px', letterSpacing: '6.45px' }}> PERSONALIZED HEALTH REPORT </span>
                            </Box>
                            <Box mt={9} textAlign="center">
                                <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeMediumReport)}> Created Exclusively For </span>
                            </Box>
                            <Box mt={1.2} textAlign="center">
                                <span className="text-medium size-1_25">{displayName()}</span>
                            </Box>
                            <Box mt={1.2} textAlign="center">
                                <span className="text-medium size-1_25">{displayDate()}</span>
                            </Box>
                        </Box>
                    </Box>
                    {displayReportInfo()}
                </Box>
                <Box height="3%"> </Box>
            </div>
        )
    )
}
