
export enum PatientCourseStatus {
  NotStarted = 0,
  InProgress = 5,
  Completed = 10,
}

export const courseStatuses: { [status: number]: string } = {
  [PatientCourseStatus.NotStarted]: 'Not Started',
  [PatientCourseStatus.InProgress]: 'In Progress',
  [PatientCourseStatus.Completed]: 'Completed',
}