import {useEffect, useState} from "react";
import {Subscription} from "rxjs";
import {onEmit} from "../../../common/helpers/on-emit";
import {notificationsQuery} from "../../../notifications/stores/notifications";
import {NotificationModel, NotificationType} from "../../../notifications/models/notifications.models";

export function useFacade(): [boolean] {
    const [isFailed, setIsFailed] = useState(false);

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<NotificationModel[]>(notificationsQuery.notifications$, notifications => {
                if (notifications && notifications.length) {
                    const anyFailedDnaKits = notifications.filter(x => x.type === NotificationType.DnaKitFailed).length > 0;
                    setIsFailed(() => (anyFailedDnaKits));
                }
            }),
        ];

        return (() => {
            subscriptions.map(it => it.unsubscribe())
        })
    }, []);

    return [isFailed];
}