import { useState } from 'react';
import { useHistory } from "react-router";
import { navigationService } from "../../../../services/navigation.service";
import { goToError } from "../../../common/helpers/go-to-error";
import { IErrorState } from "../../../common/validation/error-state";
import {
    InitialQuestionNames, QuestionnaireModel,
    QuestionnaireResultModel,
    SaveQuestionnaireResultModel
} from "../../models/questionnaire.models";
import { questionnaireService } from "../../services/questionnaire.service";
import { healthLogQuestionnaireSurveyComponentValidator } from "./healthLogQuestionnaireSurveyComponent.validator";

export const fields = [
    InitialQuestionNames.EAT_FIRST_TIME,
    InitialQuestionNames.EAT_SECOND_TIME,
    InitialQuestionNames.EAT_THIRD_TIME,
    InitialQuestionNames.WHAT_EAT_FIRST_TIME,
    InitialQuestionNames.WHAT_EAT_SECOND_TIME,
    InitialQuestionNames.WHAT_EAT_THIRD_TIME,
    InitialQuestionNames.EAT_THROUGHOUT_DAY,
    InitialQuestionNames.RESTRICTED_FEEDING_DURING_DAY,
    InitialQuestionNames.EXERCISING_DURING_DAY,
    InitialQuestionNames.EXERCISE_TYPE,
    InitialQuestionNames.CONSUME_BEFORE_SLEEP,
    InitialQuestionNames.FALL_ASLEEP,
    InitialQuestionNames.WAKE_UP,
    InitialQuestionNames.WOKE_UP_FEEL,
    InitialQuestionNames.WOKE_UP_ENERGY,
    InitialQuestionNames.MEDITATION_DURING_WEEK,
];

interface QuestionnaireSurveyPageState extends IErrorState {
    isLoading: boolean;
    result: QuestionnaireResultModel;
    questionnaire: QuestionnaireModel;
}

export function useFacade(
    result: QuestionnaireResultModel,
    questionnaire: QuestionnaireModel,
    patientId: number | null,
    handleGoBack: Function | null): [
    QuestionnaireSurveyPageState,
    (field: string, value: string) => void,
    () => void
] {
    const [state, setState] = useState({
        isLoading: false,
        questionnaire: questionnaire,
        result: result,
        errors: {}
    } as QuestionnaireSurveyPageState);

    /**
     * Validates questionnaire page
     */
    const validate = () => {
        fields.forEach(field => {
            const value = state.result.answers.find(x => x.key === field)?.value ?? '';
            healthLogQuestionnaireSurveyComponentValidator.validateAndSetState(state, setState, field, value);
        });
    }

    /**
     * Handles changes. Additionally does field validation
     * @param field
     * @param value
     */
    const handleChanges = (field: string, value: string) => {
        healthLogQuestionnaireSurveyComponentValidator.validateAndSetState(state, setState, field, value);

        const result = state.result;
        const answer = result.answers.find(x => x.key === field);
        if (answer) {
            answer.value = value;
        } else {
            result.answers.push({
                key: field,
                value: value
            });
        }

        result.answers = [...result.answers];
        setState({...state, result});
    }

    const history = useHistory();

    const handleSubmit = () => {
        validate();

        if (!healthLogQuestionnaireSurveyComponentValidator.stateIsValid(state)) {
            goToError(state);
            return;
        }

        setState({...state, isLoading: true});

        if (patientId) {
            questionnaireService.submitAsEmployee(getSaveModel(state.result)).subscribe(() => {
                if (handleGoBack) {
                    handleGoBack();
                }
            })
        } else {
            questionnaireService.submitAsPatient(getSaveModel(state.result)).subscribe(() => {
                questionnaireService.getNotificationAvailable().subscribe();
                navigationService.toMyHealthForms(history);
            });
        }
    }

    const getSaveModel = (result: QuestionnaireResultModel): SaveQuestionnaireResultModel => {
        return Object.assign(result, {
            answers: result.answers,
            questionnaireResultId: result.id,
            patientId: patientId
        });
    }

    return [state, handleChanges, handleSubmit]
}
