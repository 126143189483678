import React, { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { onEmit } from "../../../common/helpers/on-emit";
import { PaymentPlanModel } from "../../models/paymentPlan.models";
import { paymentPlansQuery } from "../../stores/paymentPlansStore";
import {paymentPlansService} from "../../services/paymentPlans.service";
import {navigationService} from "../../../../services/navigation.service";
import {authQuery} from "../../../auth/stores/auth";
import { ReselectPaymentPlanComponent } from "../../components/reselectPaymentPlanComponent/ReselectPaymentPlanComponent";
import { BuyNewSubscriptionModel } from "../../models/subscription.models";
import { subscriptionService } from "../../services/subscription.service";
import { authService } from "../../../auth/services/auth.service";
import { useHistory } from "react-router";

interface AccountMembershipActivationState {
    plans: PaymentPlanModel[];
    selectedPlan: PaymentPlanModel | null;
    isLoading: boolean;
}


export function useFacade(): [AccountMembershipActivationState, JSX.Element] {
    const [state, setState] = useState({
        plans: [],
        selectedPlan: null,
        isLoading: true
    } as AccountMembershipActivationState);
    const history = useHistory();

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<PaymentPlanModel[]>(paymentPlansQuery.activationPlans$,  plans => {
                if (plans && plans.length) {
                    setState(state => ({
                        ...state,
                        plans: plans,
                    })); 
                }

            }),
            onEmit<PaymentPlanModel>(paymentPlansQuery.selectedPlan$, selectedPlan => {
                if (selectedPlan) {
                    setState({
                        ...state,
                        isLoading: true
                    });
                    paymentPlansService.selectPeriod(selectedPlan.periods[0])
                    const buyNewSubscriptionModel: BuyNewSubscriptionModel = {
                        paymentPeriodId: selectedPlan.periods[0].id,
                        paymentPriceId: selectedPlan.periods[0].prices[0].id,
                        addOnIds: [],
                        founderId: null,
                        agreements: selectedPlan.periods[0].agreements.map(x => ({ agreementId: x.id, isConfirmed: true })),
                    }
                    subscriptionService.Replace(buyNewSubscriptionModel).subscribe(
                        () => {  
                                authService.reLogin(authQuery.getTargetLocationId()).subscribe(
                                    () => {
                                        navigationService.toMyMembership(history);
                                        window.location.reload();
                                    }
                                );            
                        },
                        () => {
                            setState({
                                ...state,
                                isLoading: false
                            });
                        }
                    );
                    setState(state => ({
                        ...state,
                        selectedPlan: selectedPlan,
                    }));
                } else {
                    setState(state => ({
                        ...state, selectedPlan: selectedPlan
                    }));
                }
            }),

        ];

        const cb = () => setState(state => ({ ...state, isLoading: false }))

        paymentPlansService.getActive(authQuery.getCurrentPracticeId()).subscribe(cb, cb);

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [state, <ReselectPaymentPlanComponent  plans={state.plans}/>];
}
