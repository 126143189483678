import {Query} from "@datorama/akita";
import {
    QuestionnaireState, questionnaireStore,
    QuestionnaireStore
} from "./questionnaire.store";

/**
 * Provides Questionnaire queries
 */
export class QuestionnaireQuery extends Query<QuestionnaireState> {
    healthForms$ = this.select(state => state.healthForms);
    questionnaireResults$ = this.select(state => state.questionnaireResults);
    questionnaireResultsByEmployee$ = this.select(state => state.questionnaireResultsByEmployee);
    availableQuestionnaires$ = this.select(state => state.availableQuestionnaires);
    targetResult$ = this.select(state => state.targetResult);
    questionnaireBanner$ = this.select(state => state.questionnaireBanner);
    notificationMedicalHistory$ =  this.select(state => state.notificationMedicalHistory);
    questionnaireResultsByAppointments$ = this.select(state => state.questionnaireResultsByAppointments);

    constructor(protected store: QuestionnaireStore) {
        super(store);
    }
}

export const questionnaireQuery = new QuestionnaireQuery(questionnaireStore);