import React, { useEffect, useRef, useState } from 'react';
import Recaptcha from 'react-google-invisible-recaptcha';
import { useHistory } from 'react-router';
import { useLocation } from "react-router-dom";
import { Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { SITE_KEY } from "../../../../config";
import { navigationService } from "../../../../services/navigation.service";
import { AddOnModel, SelectAddOnModel } from "../../../addons/models/addOns.models";
import { addOnsService } from "../../../addons/services/addOns.service";
import { addOnsQuery } from "../../../addons/stores";
import { getLastObject } from "../../../common/helpers/get-last-object";
import { onEmit } from "../../../common/helpers/on-emit";
import { handleCompare } from "../../../common/sorting/helpers/handle-compare";
import { SortingDirection } from "../../../common/sorting/models/sorting-destination";
import { IErrorState } from "../../../common/validation/error-state";
import { LeadSourceModel, PatientLeadSourceModel } from "../../../leadSources/models/leadSource.models";
import { leadSourcesService } from "../../../leadSources/services/leadSources.service";
import { leadSourcesQuery } from "../../../leadSources/stores/leadSources.query";
import { RegisterPatientModel } from "../../../patients/models/patient.model";
import { patientsService } from "../../../patients/services/patients.service";
import { AgreementModel } from "../../models/agreement.model";
import { ApplyCouponModel, MerchantCredentialsModel, PayerInformation } from "../../models/payment.models";
import { PaymentPeriodModel } from "../../models/paymentPeriod.models";
import { PaymentPlanModel } from "../../models/paymentPlan.models";
import {
    PaymentCouponModel,
    PaymentPriceModel,
    PaymentPriceType,
    PaymentStrategy
} from "../../models/paymentPrice.models";
import { paymentService } from "../../services/payment.service";
import { paymentPlansService } from "../../services/paymentPlans.service";
import { paymentPlansQuery, paymentPlansStore } from "../../stores/paymentPlansStore";
import { paymentQuery } from "../../stores/paymentStore";
import { finishCheckoutComponentValidator } from "./FinishCheckoutComponentValidator";
import { authService } from '../../../auth/services/auth.service';
import { inviteCodeQuery } from "../../stores/inviteCodeStore/inviteCode.query";
import { PlatformName, Track } from "../../../tracking/tracker";
import { authQuery } from '../../../auth/stores/auth';
import { AuthConstants } from '../../../auth/constants/auth.constants';
import { RoutesConstants } from '../../../../constants/route.constants';
import { OrderType } from "../../../orders/models/orders.models";
import { usersService } from "../../../../services/users.service";
import { getFBTraits } from "../../../common/helpers/get-fb-traits";
import { getCorrespondingPrice } from "../../helpers/getCorrespondingPrice";
import { useFlags } from "launchdarkly-react-client-sdk";
import { isFeatureFlag } from "../../../common/components/featureFlags/featureFlags";
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import { insuranceService } from "../../../insurance/services/insurance.service";
import { LoginModel, ForgotPasswordModel } from '../../../auth/models/auth.models';
import moment from "moment";
import {getAge} from "../../../common/helpers/get-age";
import {InsuranceModel} from "../../../insurance/models/insurance.models";
import { addressQuery } from '../../../account/stores/addressStore';

export enum GetStartedStep {
    HealthConcern = 0,
    HealthConcernInterstitial = 1,
    CreateAccount = 2,
    NameInterstitial = 3,
    PersonalInfo = 4,
    AskInsurance = 5,
    AskMedicare = 6,
    CaptureInsurance = 7,
    OnnInsurance = 8,
    Shipping = 9,
    Payment = 10,
    Review = 11,
    HaveAccount = 12,
    ResetPassword = 13
}

export enum HealthConcernStatus {
    MaximizeHealthSpanLongevity,
    TreatNewChronicConditions,
    OptimizeMyOverallHealth,
    ElevateMyPerformance
}

const context = {};

interface ScrollElement {
    current: HTMLDivElement
}

/**
 * Represents payment form state
 */
interface FinishCheckoutComponentState extends IErrorState {
    practiceId: number | null;
    publicKey: string | null;
    provider: string | null;
    paymentPlan: PaymentPlanModel;
    paymentPeriod: PaymentPeriodModel;
    paymentPrice: PaymentPriceModel;
    isCardExist: boolean;
    isSubmitted: boolean;
    insurances: InsuranceModel[];
    hasInsurance: boolean;
    payer: PayerInformation;
    addOns: SelectAddOnModel[];
    couponCode: string;
    coupon: PaymentCouponModel;
    isCouponApplied: boolean;
    paymentPriceType: PaymentPriceType;
    sameAsBillingAddress: boolean;
    agreements: AgreementModel[];
    selectedLeadSource: PatientLeadSourceModel;
    leadSources: LeadSourceModel[];
    step: GetStartedStep;
    paymentError: string;
    cardSaved: boolean;
    isAgreeContinue: boolean;
    isAgreeSMS: boolean;
    paymentCardInfo: any | null;
    isLoading: boolean;
    isEmailSent: boolean;
    cardName: string;
    healthConcernStatus: HealthConcernStatus;
    exclusivePrices: PaymentPriceModel[];
    showBottom: boolean;
    enterDiscountCode: boolean;
    isGetUserInfo: boolean;
}

const getDefaultPaymentPrice = (period: PaymentPeriodModel): PaymentPriceModel => {
    const promoCodePricePrice = period.prices.find(x => x.type === PaymentPriceType.PromoCode);
    if (promoCodePricePrice) {
        return promoCodePricePrice;
    }

    return getCorrespondingPrice(period.prices, PaymentStrategy.FullPayment, PaymentPriceType.Default) ?? period.prices[0];
}

/**
 * Custom Hook to manage a view Model for SelectPlan page view components
 */
export function useFacade(employerKey: string, handleSetProgress: Function): [
    FinishCheckoutComponentState,
    boolean,
    boolean,
    (hasInsurance: boolean) => void,
    (paymentMethod: any) => void,
    (paymentPrice: PaymentPriceModel) => void,
    (field: string, value: string) => void,
    () => JSX.Element,
    (id: number, value: boolean) => void,
    () => SelectAddOnModel[],
    (value: string) => void,
    (code: string) => void,
    () => void,
    () => void,
    (field: string, value: string) => void,
    () => void,
    () => void,
    (event: React.ChangeEvent<HTMLInputElement>, field: string) => void,
    () => boolean,
    () => boolean,
    () => boolean,
    () => void,
    () => void,
    () => void,
    () => boolean,
    () => boolean,
    (status: HealthConcernStatus) => void,
    () => void,
    () => boolean,
    ScrollElement,
    (field: string) => void
] {

    const prevScrollY = useRef<HTMLHeadingElement>(null);

    const emptyAddress = {
        country: 'USA',
        city: '',
        state: '',
        zipCode: '',
        streetAddress1: '',
        streetAddress2: '',
    }

    const history = useHistory();
    const featureFlags = useFlags();
    const { pathname } = useLocation();
    const insuranceOnboarding = isFeatureFlag(featureFlags, FeatureFlag.InsuranceOnboarding);
    const medicareOnboarding = isFeatureFlag(featureFlags, FeatureFlag.MedicareOnboarding);
    const isMarketingSMSButton = isFeatureFlag(featureFlags, FeatureFlag.MarketingSmsButton);
    const mobileGetStartedPath = pathname.toLowerCase().includes(RoutesConstants.mobileGetStarted);
    const isConsultationGetStartedPath = pathname.toLowerCase().includes(RoutesConstants.consultationGetStarted);
    const paymentPlan = paymentPlansQuery.getSelectedPlan();
    const paymentPeriod = paymentPlansQuery.getSelectedPeriod();
    const paymentPrice = getDefaultPaymentPrice(paymentPeriod);
    const couponCode = paymentPlansQuery.getCouponCode();
    const isCouponApplied = paymentPlansQuery.isPaymentCouponApplied();
    const [paymentToken, setPaymentToken] = useState(null);
    const recaptcha = useRef(null);
    const payer = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        birthday: null,
        gender: 99,
        password: '',
        confirmPassword: '',
        billingAddress: Object.assign({}, emptyAddress),
        shippingAddress: Object.assign({}, emptyAddress)
    }
    const [state, setState] = useState({
        practiceId: null,
        publicKey: null,
        payer: payer,
        isSubmitted: false,
        isCardExist: true,
        insurances: [],
        hasInsurance: false,
        errors: {},
        paymentPlan,
        paymentPeriod,
        paymentPrice,
        addOns: [],
        couponCode: couponCode,
        isCouponApplied: isCouponApplied,
        paymentPriceType: paymentPrice.type,
        sameAsBillingAddress: true,
        agreements: paymentPeriod.agreements,
        selectedLeadSource: {
            leadSourceId: 0,
            otherLeadSource: "",
            podcastSource: ""
        },
        leadSources: [],
        step: GetStartedStep.HealthConcern,
        paymentError: null,
        cardSaved: false,
        isAgreeContinue: false,
        isAgreeSMS: false,
        paymentCardInfo: null,
        isLoading: false,
        isEmailSent: false,
        cardName: '',
        healthConcernStatus: null,
        exclusivePrices:[],
        showBottom: true,
        enterDiscountCode: false,
        isGetUserInfo: true
    } as FinishCheckoutComponentState);

    const handleHasInsurance = (hasInsurance: boolean) => {
        var nextStep = hasInsurance
            ? GetStartedStep.CaptureInsurance
            : GetStartedStep.Shipping;

        if (hasInsurance) {
            Track("onboarding_insurance_information", {
                group: 'test',
                platform: PlatformName(),
            })
        } else {
            if (state.step === GetStartedStep.AskInsurance) {
                Track("onboarding_billing_info", {
                    group: 'test',
                    platform: PlatformName(),
                })
            } if (state.step === GetStartedStep.AskMedicare) {
                Track("onboarding_billing_info", {
                    group: 'test',
                    platform: PlatformName(),
                })
            } else {
                Track("onboarding_oon_notice", {
                    group: 'test',
                    platform: PlatformName(),
                });
                nextStep = GetStartedStep.OnnInsurance;
            }
        }

        const paymentPriceType = hasInsurance
            ? PaymentPriceType.Insurance
            : PaymentPriceType.Default;

        const paymentPrice = getCorrespondingPrice(paymentPeriod.prices, state.paymentPrice.strategy, paymentPriceType);

        setState(state => ({
            ...state,
            hasInsurance: hasInsurance,
            paymentPrice: paymentPrice,
            paymentPriceType: paymentPriceType,
            step: nextStep
        }));
            
        handleSetProgress(getProgress(nextStep));

        return navigationService.goUp();
    }

    const handleSubmitNextStep = () => {
        const nextStep = getNextStep();
        switch (state.step) {
            case GetStartedStep.HealthConcern:
                Track("onboarding_health_concern_confirmation", {
                    group: 'test',
                    platform: PlatformName(),
                })
                setState({
                    ...state,
                    step: nextStep,
                });

                return navigationService.goUp();
            case GetStartedStep.HealthConcernInterstitial:
                Track("button_click", {
                    type: 'onboarding_health_concern_confirmation',
                    acValue: 'health_concern',
                    platform: PlatformName()
                })
                Track("onboarding_sign_up", {
                    group: 'test',
                    platform: PlatformName(),
                })
                setState({
                    ...state,
                    step: nextStep,
                });
                handleSetProgress(getProgress(nextStep));
                return navigationService.goUp();
            case GetStartedStep.CreateAccount:
                return validateStepWelcome() && validateEmail();
            case GetStartedStep.HaveAccount:
                if (validateStepWelcome()) {
                    setState({ ...state, isSubmitted: true });
                    executeRecaptcha();
                }
                return;
            case GetStartedStep.ResetPassword:
                if (validateStepWelcome()) {
                    setState({ ...state, isSubmitted: true });
                    executeRecaptcha();
                }
                return;
            case GetStartedStep.NameInterstitial:
                if (validateNameInterStep()) {
                    Track("onboarding_personal_info", {
                        group: 'test',
                        platform: PlatformName(),
                    })
                    setState({
                        ...state,
                        step: nextStep,
                    });
                    handleSetProgress(getProgress(nextStep));
    
                    return navigationService.goUp();
                }
                return;
            case GetStartedStep.PersonalInfo:
                if (validatePersonalInfoStep()) {
                    Track("button_click", {
                        type: 'billing_details',
                        acValue: 'billing_details',
                        platform: PlatformName()
                    })

                    updateUserStep2();
                }
                return;
            case GetStartedStep.AskInsurance:
                return handleHasInsurance(true);
            case GetStartedStep.AskMedicare:
                Track("onboarding_medicare", {
                    group: 'test',
                    platform: PlatformName(),
                });
                return handleHasInsurance(true);
            case GetStartedStep.CaptureInsurance:
                Track("onboarding_billing_info", {
                    group: 'test',
                    platform: PlatformName(),
                })
                setState({
                    ...state,
                    step: nextStep,
                    sameAsBillingAddress: false,
                });
            
                handleSetProgress(getProgress(nextStep));

                return navigationService.goUp();
            case GetStartedStep.OnnInsurance:
                Track("onboarding_billing_info", {
                    group: 'test',
                    platform: PlatformName(),
                })
                setState({
                    ...state,
                    step: nextStep,
                    sameAsBillingAddress: false,
                });
            
                handleSetProgress(getProgress(nextStep));

                return navigationService.goUp();
            case GetStartedStep.Shipping:
                if (validateShippingStep()) {
                    updateUserAddress();
                }
                return;
            case GetStartedStep.Payment:
                if (validatePaymentStep()) {
                    Track("Button Click", {
                        type: 'checkout_order_summary',
                        platform: PlatformName()
                    })
                    Track("onboarding_checkout", {
                        group: 'test',
                        platform: PlatformName(),
                    });
            
                    handleSetProgress(getProgress(nextStep));

                    setState({ ...state, step: nextStep, cardSaved: true });

                    return navigationService.goUp();
                }
                return;
            case GetStartedStep.Review:
                handleSubmitRegistration()
                return;
        }
    }

    const emailUsed = () => {
        setState({
            ...state,
            step: GetStartedStep.HaveAccount
        });
    }

    const emailDoNotUsed = () => {
        Track("patient_sign_up", {
            acValue: state.paymentPlan.displayName,
            plan: state.paymentPlan.displayName,
            platform: PlatformName()
        })

        const password = !state.payer.password
            ? uuidv4() + AuthConstants.uuidPasswordInterpolation
            : state.payer.password;

        const user = {
            practiceId: state.practiceId,
            email: state.payer.email,
            password: password,
            smsMarketing: state.isAgreeSMS
        }

        setState({
            ...state,
            isLoading: true
        });

        usersService.create(user).subscribe(() => {
            const nextStep = state.step + 1;
            const payer = state.payer;
            payer.shippingAddress = state.payer.billingAddress;

            setState({
                ...state,
                payer: payer,
                step: nextStep,
                sameAsBillingAddress: false,
                isLoading: false
            });
            Track("onboarding_first_name", {
                group: 'test',
                platform: PlatformName(),
            })
            handleSetProgress(getProgress(nextStep));

            navigationService.goUp();
        }, () => {
            setState({
                ...state,
                isLoading: false
            });
        });
    }

    const updateUserStep2 = () => {
        const user = {
            email: state.payer.email,
            firstName: state.payer.firstName,
            lastName: state.payer.lastName,
            gender: state.payer.gender.toString(),
            birthDate: state.payer.birthday,
            phoneNumber: state.payer.phoneNumber,
            smsMarketing: state.isAgreeSMS,
            shippingAddress: {...state.payer.shippingAddress, state: state.payer.state.abbreviation},
            billingAddress: {...state.payer.billingAddress, state: state.payer.state.name},
            meetingRecordingConsent: true
        };

        setState({
            ...state,
            isLoading: true
        });

        usersService.update(user).subscribe(() => {
            const payer = state.payer;
            payer.shippingAddress = state.payer.billingAddress;

            if (!payer.billingAddress.state) {
                payer.billingAddress.state = payer.state.name
            }

            if (insuranceOnboarding && state.paymentPlan.insuranceStates.some(x => x.id === state.payer.state.id) && state.insurances.length) {
                Track("onboarding_insurance", {
                    group: 'test',
                    platform: PlatformName(),
                })
            } else {
                Track("onboarding_billing_info", {
                    group: 'test',
                    platform: PlatformName(),
                })
            }
            const nextStep = getNextStep();
            
            handleSetProgress(getProgress(nextStep));

            setState({
                ...state,
                payer: payer,
                step: nextStep,
                sameAsBillingAddress: false,
                isLoading: false
            });

            navigationService.goUp();
        }, () => {
            setState({
                ...state,
                isLoading: false
            });
        });
    }

    const updateUserAddress = () => {
        const user = {
            email: state.payer.email,
            firstName: state.payer.firstName,
            lastName: state.payer.lastName,
            gender: state.payer.gender.toString(),
            birthDate: state.payer.birthday,
            phoneNumber: state.payer.phoneNumber,
            smsMarketing: state.isAgreeSMS,
            shippingAddress: {...state.payer.shippingAddress, state: addressQuery.findState(state.payer.shippingAddress?.state)?.abbreviation},
            billingAddress: state.payer.billingAddress,
            meetingRecordingConsent: true
        };

        setState({
            ...state,
            isLoading: true
        });

        usersService.update(user).subscribe(() => {
            Track("onboarding_billing_info", {
                group: 'test',
                platform: PlatformName(),
            })

            const nextStep = getNextStep();
            handleSetProgress(getProgress(nextStep));

            setState({
                ...state,
                step: nextStep,
                isLoading: false
            });

            return navigationService.goUp();
        }, () => {
            setState({
                ...state,
                isLoading: false
            });
        });
    }

    const validateEmail = () => {
        authService.isEmailExistForPatient(state.payer.email).subscribe(result => {
            result ? emailUsed() : emailDoNotUsed()
        })
    }

    const getInviteCode = () => {
        return inviteCodeQuery.getInviteCode();
    }

    const handleTokenChange = (paymentMethod: any) => {
        if (paymentMethod) {
            setPaymentToken(paymentMethod.paymentMethod.id);
            setState({ ...state, paymentCardInfo: paymentMethod, isCardExist: true });
        } else {
            setPaymentToken(null);
            setState({ ...state, paymentCardInfo: null });
        }
    }

    const getProgress = (step?: GetStartedStep): GetStartedStep => {
        switch (step ?? state.step) {
            case GetStartedStep.HealthConcern: return 14;
            case GetStartedStep.HealthConcernInterstitial: return 14;
            case GetStartedStep.CreateAccount: return 28;
            case GetStartedStep.NameInterstitial: return 43;
            case GetStartedStep.PersonalInfo: return 58;
            case GetStartedStep.AskInsurance: return 58;
            case GetStartedStep.AskMedicare: return 58;
            case GetStartedStep.CaptureInsurance: return 58;
            case GetStartedStep.OnnInsurance: return 58;
            case GetStartedStep.Shipping: return 72;
            case GetStartedStep.Payment: return 72;
            case GetStartedStep.Review: return 72;
        }
    }

    const getNextStep = (step?: GetStartedStep): GetStartedStep => {
        switch (step ?? state.step) {
            case GetStartedStep.HealthConcern: return GetStartedStep.HealthConcernInterstitial;
            case GetStartedStep.HealthConcernInterstitial: return GetStartedStep.CreateAccount;
            case GetStartedStep.CreateAccount: return GetStartedStep.NameInterstitial;
            case GetStartedStep.HaveAccount: return GetStartedStep.NameInterstitial;
            case GetStartedStep.NameInterstitial: return GetStartedStep.PersonalInfo;
            case GetStartedStep.PersonalInfo: return insuranceOnboarding && state.paymentPlan.insuranceStates.some(x => x.id === state.payer.state.id) && state.insurances.length && !employerKey
                ? GetStartedStep.AskInsurance
                : medicareOnboarding && getAge(state.payer.birthday) >= 65 && !employerKey
                    ? GetStartedStep.AskMedicare
                    : GetStartedStep.Shipping;
            case GetStartedStep.AskInsurance: return GetStartedStep.CaptureInsurance;
            case GetStartedStep.AskMedicare: return GetStartedStep.CaptureInsurance;
            case GetStartedStep.CaptureInsurance: return GetStartedStep.Shipping
            case GetStartedStep.OnnInsurance: return GetStartedStep.Shipping;
            case GetStartedStep.Shipping: return GetStartedStep.Payment;
            case GetStartedStep.Payment: return GetStartedStep.Review;
        }
    }

    const getPreviousStep = (step?: GetStartedStep): GetStartedStep => {
        switch (step ?? state.step) {
            case GetStartedStep.HealthConcernInterstitial: return GetStartedStep.HealthConcern;
            case GetStartedStep.CreateAccount: return GetStartedStep.HealthConcernInterstitial;
            case GetStartedStep.PersonalInfo: return GetStartedStep.NameInterstitial;
            case GetStartedStep.AskInsurance: return GetStartedStep.PersonalInfo;
            case GetStartedStep.AskMedicare: return GetStartedStep.PersonalInfo;
            case GetStartedStep.CaptureInsurance: return state.paymentPlan.insuranceStates.some(x => x.id === state.payer.state.id)
                ? GetStartedStep.AskInsurance
                : GetStartedStep.AskMedicare;
            case GetStartedStep.OnnInsurance: return GetStartedStep.CaptureInsurance;
            case GetStartedStep.Shipping: return state.hasInsurance
                ? GetStartedStep.CaptureInsurance
                : insuranceOnboarding && state.paymentPlan.insuranceStates.some(x => x.id === state.payer.state.id)  && state.insurances.length && !employerKey
                    ? GetStartedStep.AskInsurance
                    : medicareOnboarding && getAge(state.payer.birthday) >= 65 && !employerKey
                        ? GetStartedStep.AskMedicare
                        : GetStartedStep.PersonalInfo;
            case GetStartedStep.Payment: return GetStartedStep.Shipping;
            case GetStartedStep.Review: return GetStartedStep.Payment;
            case GetStartedStep.HaveAccount: return GetStartedStep.CreateAccount;
            case GetStartedStep.ResetPassword: return GetStartedStep.CreateAccount;
        }
    }

    const handleSubmitBackStep = () => {
        const previousStep = getPreviousStep();
        
        handleSetProgress(getProgress(previousStep));

        setState({ ...state, step: previousStep, errors: {}, isEmailSent: false });
    }

    const handleSelectingAddOn = (id: number, value: boolean) => {
        const addOns = state.addOns;
        const addOn = addOns.find(x => x.id === id);
        addOn.isSelected = value;

        setState({
            ...state,
            addOns,
        });
    }

    const handlePriceSelect = (paymentPrice: PaymentPriceModel) => {
        setState({ ...state, paymentPrice });
    }

    const getSelectedAddOns = () => {
        return state.addOns.filter(i => i.isSelected);
    }

    const handleCouponChanges = (value: string) => {
        setState({ ...state, couponCode: value });
    }

    const selectPaymentPriceType = () => {
        switch (state.hasInsurance) {
            case true:
                return PaymentPriceType.Insurance;
            case false:
                return PaymentPriceType.Default;
            default: return PaymentPriceType.Default;
        }
    }

    const applyCoupon = (code: string) => {
        const paymentPriceType = selectPaymentPriceType();

        const model: ApplyCouponModel = {
            paymentPeriodId: state.paymentPeriod.id,
            paymentPriceType: paymentPriceType,
            code: code
        };

        const action = paymentPlansService.newPromoCodeApply(model);

        action.subscribe((exclusivePrices: PaymentPriceModel[]) => {
            if (exclusivePrices && exclusivePrices.length) {
                // we need the complete coupon model
                const retrievedCoupon = exclusivePrices[0].paymentCoupon;

                const newSelectedPeriod = Object.assign(paymentPeriod, {
                    prices: [...paymentPeriod.prices, ...exclusivePrices]
                });

                const addOns = state.addOns;

                const freeAddOnsPrice = exclusivePrices.find(x => x.isAddOnsForFree);
                if (freeAddOnsPrice) {
                    addOns.forEach(x => {
                        if (freeAddOnsPrice.freeAddOnsTypes.includes(x.orderType)) {
                            x.price = 0;
                        }
                    });
                }

                const paymentPrice = newSelectedPeriod
                    .prices
                    .find(x => x.type === paymentPriceType && x?.paymentCoupon?.code === retrievedCoupon.code && x.strategy === state.paymentPrice.strategy);

                if (paymentPrice) {
                    setState(state => ({
                        ...state,
                        couponCode: code,
                        paymentPriceType: paymentPriceType,
                        coupon: retrievedCoupon,
                        paymentPeriod: newSelectedPeriod,
                        paymentPrice: paymentPrice,
                        addOns: addOns,
                        isCouponApplied: true,
                        exclusivePrices: exclusivePrices
                    }));
    
                    paymentPlansStore.setCouponApplied(retrievedCoupon);
                    paymentPlansService.updateSelectedPeriod(newSelectedPeriod);
                }
            }
        }, () => {
        });
    }

    const resetCoupon = () => {
        paymentPlansService.resetCoupon();

        const newSelectedPeriod = Object.assign(paymentPeriod, paymentPlan.periods[0]);

        const paymentPriceType = state.hasInsurance
            ? PaymentPriceType.Insurance
            : PaymentPriceType.Default;

        const paymentPrice = getCorrespondingPrice(newSelectedPeriod.prices, state.paymentPrice.strategy, paymentPriceType);

        setState(state => ({
            ...state,
            paymentPeriod: newSelectedPeriod,
            paymentPrice: paymentPrice,
            paymentPriceType: paymentPriceType,
            isCouponApplied: false
        }));

        paymentPlansService.updateSelectedPeriod(newSelectedPeriod);
    }

    const handleChanges = (field: string, value: any) => {
        updateValidationState(field, value);
        if (field === 'cardName') {
            setState({ ...state, cardName: value });
            return;
        }
        if (field === 'gender') {
            addOnsService.getOptional(state.paymentPlan.id, value, employerKey);
        }
        const payer = state.payer;
        const keys = field.split(".");
        const key = keys.pop();
        const lastObject = getLastObject(keys, payer);
        lastObject[key] = value;

        setState(state => ({ ...state, payer: Object.assign({}, payer) }));
    }

    const handleAgree = (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
        state[field] = event.target.checked;
        setState({ ...state });
    }

    const handleChangeHealthConcernStatus = (status: HealthConcernStatus) => {
        setState({
            ...state,
            healthConcernStatus: status
        })
    }

    const isCheckedWelcomePatient = () => {
        return Boolean(state.payer.email && state.payer.password && state.isAgreeContinue);
    }

    const isCheckedNameInfo = () => {
        return Boolean(state.payer.firstName && state.payer.lastName && moment(state.payer.birthday).format('MM/DD/YYYY') !== 'Invalid date' && state.payer.gender !== 99);
    }

    const isCheckedPersonalInfo = () => {
        return Boolean(state.payer.firstName && state.payer.lastName && moment(state.payer.birthday).format('MM/DD/YYYY') !== 'Invalid date' && state.payer.phoneNumber && state.payer.gender !== 99 && state.payer.state);
    }

    const isCheckedShippingDetail = () => {
        return Boolean(state.payer.shippingAddress.streetAddress1 && state.payer.shippingAddress.city && state.payer.shippingAddress.state && state.payer.shippingAddress.zipCode);
    }

    const isCheckedPaymentCardDetail = () => {
        return Boolean(paymentToken && state.payer.billingAddress.streetAddress1 && state.payer.billingAddress.city && state.payer.billingAddress.state && state.payer.billingAddress.zipCode);
    }

    const isCheckedProperStep = (payer: PayerInformation) => {
        if (Boolean(payer.firstName && payer.lastName && moment(payer.birthday).format('MM/DD/YYYY') !== 'Invalid date' && payer.gender !== 99)) {
            if (Boolean(payer.firstName && payer.lastName && moment(payer.birthday).format('MM/DD/YYYY') !== 'Invalid date' && payer.gender !== 99 && state.payer.phoneNumber && payer.state)) {
                if (Boolean(payer.shippingAddress.streetAddress1 && payer.shippingAddress.city && payer.shippingAddress.state && payer.shippingAddress.zipCode)) {
                    return GetStartedStep.Payment;
                } else {
                    return GetStartedStep.Shipping;
                }
            } else {
                Track("onboarding_personal_info", {
                    group: 'test',
                    platform: PlatformName(),
                })
                return GetStartedStep.PersonalInfo;
            }
        }
        Track("onboarding_first_name", {
            group: 'test',
            platform: PlatformName(),
        })
        return GetStartedStep.NameInterstitial;
    }

    const handleLeadSourceChanges = (field: string, value: any) => {
        updateValidationState(field, value);
        const keys = field.split(".");
        const key = keys.pop();
        const lastObject = getLastObject(keys, state);
        lastObject[key] = key === "leadSourceId" ? +value : value;

        setState({ ...state, selectedLeadSource: state.selectedLeadSource });
    }

    const updateValidationState = (field: string, value: any) => {
        if (state.sameAsBillingAddress && field.includes('billingAddress')) {
            const shippingAddressField = field.replace('billingAddress', 'shippingAddress');
            finishCheckoutComponentValidator.validateAndSetState(state, setState, shippingAddressField, value);
        }

        finishCheckoutComponentValidator.validateAndSetState(state, setState, field, value);
    }

    const executeRecaptcha = () => {
        recaptcha.current?.reset();
        recaptcha.current?.execute()
    }

    const renderRecaptcha = () => {
        context['state'] = state;
        context['paymentToken'] = paymentToken;

        return <Recaptcha
            ref={recaptcha}
            sitekey={SITE_KEY}
            onResolved={(token) => recaptchaOnResolved(token, context)}
            style={{ display: 'none' }}
        />
    }

    const handleSameAsBillingAddress = () => {
        const payer = state.payer;
        payer.billingAddress = state.sameAsBillingAddress ? state.payer.shippingAddress : emptyAddress;

        setState({
            ...state,
            payer: payer,
            sameAsBillingAddress: !state.sameAsBillingAddress
        });
    }

    const handleUserInfoCheck = () => {
        const payer = state.payer;
        payer.shippingAddress = state.payer.billingAddress;
        payer.email = authQuery.getEmail();
        payer.password = uuidv4() + AuthConstants.uuidPasswordInterpolation;
        const cb = () => {
            setState(state => ({
                ...state,
                payer: payer,
                step: GetStartedStep.NameInterstitial,
                isSubmitted: false,
                isGetUserInfo: false
            }));
            Track("onboarding_first_name", {
                group: 'test',
                platform: PlatformName(),
            })
            handleSetProgress(getProgress(GetStartedStep.NameInterstitial));
        }
        usersService.getMyUserInfo().subscribe((response) => {
            payer.firstName = response.firstName;
            payer.lastName = response.lastName;
            payer.birthday = response.birthday;
            payer.gender = response.gender;
            payer.phoneNumber = response.phoneNumber;
            payer.shippingAddress = {
                ...response.shippingAddress,
                country: 'USA'
            };
            payer.billingAddress = {
                ...response.shippingAddress,
                country: 'USA'
            };
            if (response.shippingAddress.state) {
                payer.state = addressQuery.findState(payer.shippingAddress?.state);
            }
            const step = isCheckedProperStep(payer);
            setState(state => ({
                ...state,
                payer: payer,
                step: step,
                sameAsBillingAddress: step === GetStartedStep.Payment || step === GetStartedStep.Shipping ? false : true,
                isSubmitted: false,
                isGetUserInfo: false
            }));
            handleSetProgress(getProgress(step));
        }, cb)
    }

    const recaptchaOnResolved = (antiSpamToken: string, context) => {
        const state = context['state'];
        const paymentToken = context['paymentToken'];

        const password = !state.payer.password
            ? uuidv4() + AuthConstants.uuidPasswordInterpolation
            : state.payer.password;

        const patient: RegisterPatientModel = {
            inviteCode: getInviteCode(),
            practiceId: state.practiceId,
            paymentToken: paymentToken,
            antiSpamToken: antiSpamToken,
            email: state.payer.email.trim(),
            phoneNumber: state.payer.phoneNumber,
            firstName: state.payer.firstName,
            lastName: state.payer.lastName,
            marketingSMS: state.isAgreeSMS,
            gender: Number(state.payer.gender),
            birthday: state.payer.birthday,
            password: password,
            confirmPassword: password,
            paymentPeriodId: state.paymentPeriod.id,
            paymentPriceId: state.paymentPrice.id,
            agreements: state.paymentPeriod.agreements.map(x => ({ agreementId: x.id, isConfirmed: true })),
            billingAddress: state.payer.billingAddress,
            shippingAddress: {...state.payer.shippingAddress, state: addressQuery.findState(state.payer.shippingAddress?.state)?.abbreviation},
            addOnIds: state.addOns.filter(x => x.isSelected).map(x => x.id),
            founderId: null,
            leadSource: state.selectedLeadSource.leadSourceId !== 0 ? state.selectedLeadSource : null,
            employeeId: null,
            linkedEmployeeId: null,
            employerProductKey: employerKey,
            promoCode: state.isCouponApplied ? state.couponCode : ''
        }

        const loginModel: LoginModel = {
            email: state.payer.email,
            password: state.payer.password,
            practiceId: authQuery.getCurrentPracticeId(),
            antiSpamToken: antiSpamToken,
        }

        const forgotPasswordModel: ForgotPasswordModel = {
            email: state.payer.email,
            practiceId: authQuery.getCurrentPracticeId(),
            antiSpamToken: antiSpamToken,
        }
        
        const cb = () => setState(state => ({ ...state, isSubmitted: false }));
        
        const registerCB = (error: any) => {
            const messages = error?.response?.data?.messages ?? [];

            if (messages[0]?.toLowerCase()?.includes('already has an active membership')) {
                authService.refreshToken(authQuery.getRefreshToken()).subscribe(
                    () => {
                        const traits = getFBTraits(state.payer);
                        Track(
                            "checkout_completed",
                            {
                                platform: PlatformName()
                            },
                            traits
                        );

                        const createdPatient: any = {
                            ...state.payer,
                            intakeId: authQuery.getPatientId()
                        }
        
                        if (mobileGetStartedPath) {
                            navigationService.toMobilePaymentSuccess(history, createdPatient);
                        } else {
                            navigationService.toPaymentSuccess(history, createdPatient);
                        }
                    }, cb
                )
            } else if (messages[0]?.toLowerCase()?.includes('your card was declined. please contact your financial institution.')) {
                setState(state => ({
                    ...state,
                    isSubmitted: false,
                    step: GetStartedStep.Payment,
                    cardSaved: false
                }));
            } else {
                setState(state => ({ ...state, isSubmitted: false }));
            }
        }

        if (state.step === GetStartedStep.HaveAccount) {
            authService.login(loginModel).subscribe((res) => {
                if (!authQuery.isRegistrationCompleted()) {
                    handleUserInfoCheck();
                } else {
                    navigationService.toDashboard(history);
                }
            }, cb)
        } else if (state.step === GetStartedStep.ResetPassword) {
            authService.forgotPassword(forgotPasswordModel).subscribe(() => {
                setState({ ...state, isSubmitted: false, isEmailSent: true });
            }, cb)
        } else {
            patientsService.register(patient).subscribe(
                patient => {
                    const traits = getFBTraits(state.payer, patient.universalId);
                    Track(
                        "checkout_completed",
                        {
                            platform: PlatformName()
                        },
                        traits
                    );
    
                    if (mobileGetStartedPath) {
                        navigationService.toMobilePaymentSuccess(history, patient);
                    } else {
                        navigationService.toPaymentSuccess(history, patient);
                    }
                }, (error) => registerCB(error)
            );
        }
    }

    const validateStepWelcome = (): boolean => {
        finishCheckoutComponentValidator.validateAndSetState(state, setState, "email", state.payer.email);
        finishCheckoutComponentValidator.validateAndSetState(state, setState, "password", state.payer.password);

        return finishCheckoutComponentValidator.stateIsValid(state);
    }

    const handleOnBlurValidate = (field: string): boolean => {
        switch (field) {
            case 'firstName':
                finishCheckoutComponentValidator.validateAndSetState(state, setState, "firstName", state.payer.firstName);

                return finishCheckoutComponentValidator.stateIsValid(state);
            case 'lastName':
                finishCheckoutComponentValidator.validateAndSetState(state, setState, "lastName", state.payer.lastName);

                return finishCheckoutComponentValidator.stateIsValid(state);
            case 'birthday':
                finishCheckoutComponentValidator.validateAndSetState(state, setState, "birthday", state.payer.birthday);

                return finishCheckoutComponentValidator.stateIsValid(state);
            case 'phoneNumber':
                finishCheckoutComponentValidator.validateAndSetState(state, setState, "phoneNumber", state.payer.phoneNumber);

                return finishCheckoutComponentValidator.stateIsValid(state);
            case 'state':
                finishCheckoutComponentValidator.validateAndSetState(state, setState, "state", state.payer.state);

                return finishCheckoutComponentValidator.stateIsValid(state);
            case 'shippingAddress.streetAddress1':
                finishCheckoutComponentValidator.validateAndSetState(state, setState, "shippingAddress.streetAddress1", state.payer.shippingAddress.streetAddress1);

                return finishCheckoutComponentValidator.stateIsValid(state);
            case 'shippingAddress.city':
                finishCheckoutComponentValidator.validateAndSetState(state, setState, "shippingAddress.city", state.payer.shippingAddress.city);

                return finishCheckoutComponentValidator.stateIsValid(state);
            case 'shippingAddress.state':
                finishCheckoutComponentValidator.validateAndSetState(state, setState, "shippingAddress.state", state.payer.shippingAddress.state);

                return finishCheckoutComponentValidator.stateIsValid(state);
            case 'shippingAddress.zipCode':
                finishCheckoutComponentValidator.validateAndSetState(state, setState, "shippingAddress.zipCode", state.payer.shippingAddress.zipCode);

                return finishCheckoutComponentValidator.stateIsValid(state);
            case 'billingAddress.streetAddress1':
                finishCheckoutComponentValidator.validateAndSetState(state, setState, "billingAddress.streetAddress1", state.payer.billingAddress.streetAddress1);

                return finishCheckoutComponentValidator.stateIsValid(state);
            case 'billingAddress.city':
                finishCheckoutComponentValidator.validateAndSetState(state, setState, "billingAddress.city", state.payer.billingAddress.city);

                return finishCheckoutComponentValidator.stateIsValid(state);
            case 'billingAddress.state':
                finishCheckoutComponentValidator.validateAndSetState(state, setState, "billingAddress.state", state.payer.billingAddress.state);

                return finishCheckoutComponentValidator.stateIsValid(state);
            case 'billingAddress.zipCode':
                finishCheckoutComponentValidator.validateAndSetState(state, setState, "billingAddress.zipCode", state.payer.billingAddress.zipCode);

                return finishCheckoutComponentValidator.stateIsValid(state);
            case 'cardName':
                finishCheckoutComponentValidator.validateAndSetState(state, setState, "cardName", state.cardName);

                return finishCheckoutComponentValidator.stateIsValid(state);
        }
    }

    const validateNameInterStep = (): boolean => {
        finishCheckoutComponentValidator.validateAndSetState(state, setState, "firstName", state.payer.firstName);
        finishCheckoutComponentValidator.validateAndSetState(state, setState, "lastName", state.payer.lastName);
        finishCheckoutComponentValidator.validateAndSetState(state, setState, "birthday", state.payer.birthday);
        finishCheckoutComponentValidator.validateAndSetState(state, setState, "gender", state.payer.gender);

        return finishCheckoutComponentValidator.stateIsValid(state);
    }

    const validatePersonalInfoStep = (): boolean => {
        finishCheckoutComponentValidator.validateAndSetState(state, setState, "firstName", state.payer.firstName);
        finishCheckoutComponentValidator.validateAndSetState(state, setState, "lastName", state.payer.lastName);
        finishCheckoutComponentValidator.validateAndSetState(state, setState, "birthday", state.payer.birthday);
        finishCheckoutComponentValidator.validateAndSetState(state, setState, "gender", state.payer.gender);
        finishCheckoutComponentValidator.validateAndSetState(state, setState, "phoneNumber", state.payer.phoneNumber);
        finishCheckoutComponentValidator.validateAndSetState(state, setState, "state", state.payer.state);

        const leadSource = state.leadSources.find(x => x.id === state.selectedLeadSource.leadSourceId);
        if (leadSource !== undefined && leadSource.isOther)
            finishCheckoutComponentValidator.validateAndSetState(state, setState, "selectedLeadSource.otherLeadSource", state.selectedLeadSource.otherLeadSource);

        return finishCheckoutComponentValidator.stateIsValid(state);
    }

    const validateShippingStep = (): boolean => {
        finishCheckoutComponentValidator.validateAndSetState(state, setState, "shippingAddress.streetAddress1", state.payer.shippingAddress.streetAddress1);
        finishCheckoutComponentValidator.validateAndSetState(state, setState, "shippingAddress.streetAddress2", state.payer.shippingAddress.streetAddress2);
        finishCheckoutComponentValidator.validateAndSetState(state, setState, "shippingAddress.city", state.payer.shippingAddress.city);
        finishCheckoutComponentValidator.validateAndSetState(state, setState, "shippingAddress.state", state.payer.shippingAddress.state);
        finishCheckoutComponentValidator.validateAndSetState(state, setState, "shippingAddress.zipCode", state.payer.shippingAddress.zipCode);

        return finishCheckoutComponentValidator.stateIsValid(state);
    }

    const validatePaymentStep = (): boolean => {
        finishCheckoutComponentValidator.validateAndSetState(state, setState, "billingAddress.streetAddress1", state.payer.billingAddress.streetAddress1);
        finishCheckoutComponentValidator.validateAndSetState(state, setState, "billingAddress.streetAddress2", state.payer.billingAddress.streetAddress2);
        finishCheckoutComponentValidator.validateAndSetState(state, setState, "billingAddress.city", state.payer.billingAddress.city);
        finishCheckoutComponentValidator.validateAndSetState(state, setState, "billingAddress.state", state.payer.billingAddress.state);
        finishCheckoutComponentValidator.validateAndSetState(state, setState, "billingAddress.zipCode", state.payer.billingAddress.zipCode);
        finishCheckoutComponentValidator.validateAndSetState(state, setState, "shippingAddress.streetAddress1", state.payer.shippingAddress.streetAddress1);
        finishCheckoutComponentValidator.validateAndSetState(state, setState, "shippingAddress.streetAddress2", state.payer.shippingAddress.streetAddress2);
        finishCheckoutComponentValidator.validateAndSetState(state, setState, "shippingAddress.city", state.payer.shippingAddress.city);
        finishCheckoutComponentValidator.validateAndSetState(state, setState, "shippingAddress.state", state.payer.shippingAddress.state);
        finishCheckoutComponentValidator.validateAndSetState(state, setState, "shippingAddress.zipCode", state.payer.shippingAddress.zipCode);
        finishCheckoutComponentValidator.validateAndSetState(state, setState, "cardName", state.cardName);

        const isValid = finishCheckoutComponentValidator.stateIsValid(state) && Boolean(paymentToken);

        setState({ ...state, isCardExist: Boolean(paymentToken) })

        return isValid;
    }

    /**
     * Handles payment submit
     */
    const handleSubmitRegistration = () => {
        Track("Button Click", {
            type: 'checkout_started',
            platform: PlatformName()
        })
        Track("checkout_started", {
            platform: PlatformName()
        })
        setState({ ...state, isSubmitted: true });
        executeRecaptcha();
    };

    const goToLoginPage = () => {
        navigationService.toLogin(history);
    }

    const goToHaveAccountStep = () => {
        setState({ ...state, step: GetStartedStep.HaveAccount, isEmailSent: false });
    }

    const goToResetPasswordStep = () => {
        setState({ ...state, step: GetStartedStep.ResetPassword });
    }

    const handleEnterDiscountCode = () => {
        setState({
            ...state,
            enterDiscountCode: !state.enterDiscountCode
        })
    }

    const isMedicareFlow = () => {
        return insuranceOnboarding && state.paymentPlan.insuranceStates.some(x => x.id === state.payer.state.id) && state.insurances.length
            ? false
            : medicareOnboarding && getAge(state.payer.birthday) >= 65;
    }

    /**
     * Load all payment plans
     * Manage subscriptions with auto-cleanup
     */
    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<MerchantCredentialsModel>(paymentQuery.credentials$, credentials => {
                setState(state => ({
                    ...state,
                    publicKey: credentials?.publicKey,
                    provider: credentials?.provider,
                    practiceId: credentials?.practiceId
                }));
            }
            ),
            onEmit<PaymentPlanModel>(paymentPlansQuery.selectedPlan$, selectedPlan => {
                setState(state => ({ ...state, selectedPlan }));
            }),
            onEmit<boolean>(paymentPlansQuery.isCouponApplied$, (value) => {
                setState(state => ({ ...state, isCouponApplied: value, }));
            }),
            onEmit<AddOnModel[]>(addOnsQuery.addOns$, addOns => {
                const items = addOns.map(i => {
                    return {
                        ...i,
                        isSelected: i.orderType === OrderType.Lab
                    }
                });

                let isCouponApplied=false;
                if(state.isCouponApplied && !!state.exclusivePrices){
                    const addOns = items;
                    const freeAddOnsPrice = state.exclusivePrices.find(x => x.isAddOnsForFree);
                    if (freeAddOnsPrice) {
                        addOns.forEach(x => {
                            if (freeAddOnsPrice.freeAddOnsTypes.includes(x.orderType)) {
                                x.price = 0;
                            }
                        });
                    }
                    isCouponApplied = true;
                }
                setState(state => ({ ...state, addOns: items, isCouponApplied: isCouponApplied }));
            }),
            onEmit<LeadSourceModel[]>(leadSourcesQuery.leadSources$, leadSources => {
                setState(state => ({
                    ...state,
                    leadSources: leadSources.sort((l1, l2,) => handleCompare(l1, l2, SortingDirection.Desc, 'isOther'))
                }));
            }),
        ];

        leadSourcesService.getActive(authQuery.getCurrentPracticeId());
        paymentService.getCredentials(authQuery.getCurrentPracticeId());
        addOnsService.getOptional(paymentPlan.id, state.payer.gender, employerKey);

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [paymentPlan, state.payer.gender, couponCode]);

    useEffect(() => {
        if (authQuery.isLoggedIn()) {
            handleUserInfoCheck();
        } else {
            Track("onboarding_health_concern", {
                group: 'test',
                platform: PlatformName(),
            })
            setState(state => ({
                ...state,
                isGetUserInfo: false
            }));
            handleSetProgress(getProgress());
        }
    }, [])
    
    useEffect(() => {
        if (state.payer.state) {
            insuranceService.getInsurances(state.payer.state.id).subscribe((insurances) => {
                setState((state) => ({...state, insurances }))
            }, () => {
                setState((state) => ({...state, insurances: [] }))
            });
        }
    }, [state.payer.state]);

    useEffect(() => {

        function scrollEvent() {
            if (prevScrollY) {

                if (prevScrollY.current) {
                    let rect = prevScrollY.current.getBoundingClientRect()

                    if (rect.top > window.innerHeight + 48) {
                        setState((state) => ({...state, showBottom: true }))
                    } else {
                        setState((state) => ({...state, showBottom: false }))
                    }
                }
            }
        }

        document.addEventListener('scroll', scrollEvent)

        return () => {
            document.removeEventListener('scroll', scrollEvent)
        }
    }, []);


    return [
        state,
        isMarketingSMSButton,
        isConsultationGetStartedPath,
        handleHasInsurance,
        handleTokenChange,
        handlePriceSelect,
        handleChanges,
        renderRecaptcha,
        handleSelectingAddOn,
        getSelectedAddOns,
        handleCouponChanges,
        applyCoupon,
        resetCoupon,
        handleSameAsBillingAddress,
        handleLeadSourceChanges,
        handleSubmitNextStep,
        handleSubmitBackStep,
        handleAgree,
        isCheckedWelcomePatient,
        isCheckedNameInfo,
        isCheckedPersonalInfo,
        goToLoginPage,
        goToResetPasswordStep,
        goToHaveAccountStep,
        isCheckedShippingDetail,
        isCheckedPaymentCardDetail,
        handleChangeHealthConcernStatus,
        handleEnterDiscountCode,
        isMedicareFlow,
        prevScrollY,
        handleOnBlurValidate
    ]
}
