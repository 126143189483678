import React from "react";
import { Box, IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { ReactComponent as BellIcon } from '@img/icons/BellIcon.svg';
import { useStyles } from "./standardUpsellWidgetComponent.styles";
import { useFacade } from "./standardUpsellWidgetComponent.hooks";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { patientStore } from "../../../patients/stores/patientsStore";
import clsx from "clsx";

export interface StandardUpsellWidgetComponentProps {
    allowInline?: boolean;
}

export const StandardUpsellWidgetComponent: React.FC<StandardUpsellWidgetComponentProps> = (props: StandardUpsellWidgetComponentProps) => {
    const { allowInline = false } = props;
    const [
        {
            isLoading,
            isSubmitting,
            callToActions
        },
        handleDecline,
    ] = useFacade();

    const classes = useStyles();

    if (isLoading) {
        return <WildHealthLinearProgress />
    }

    if (!isLoading && callToActions.length > 0) {
        return (
            <Box mb={3} className={clsx("wh-tw-p-6 wh-tw-bg-primaryV wh-tw-rounded-xl wh-tw-relative", allowInline && "xl:wh-tw-p-8")}>
                <IconButton id="dialog-title-close" aria-label="close" disabled={isSubmitting} className={classes.closeButton} onClick={handleDecline}>
                    <CloseIcon className="wh-tw-text-white" />
                </IconButton>
                <Box className={allowInline && "xl:wh-tw-flex xl:wh-tw-gap-6 xl:wh-tw-px-4"}>
                    <Box display="flex" alignItems="center" gridGap={4} p="5px" className={clsx("wh-tw-bg-mint100 wh-tw-rounded wh-tw-w-fit wh-tw-h-6", allowInline && "xl:wh-tw-mt-2")}>
                        <BellIcon />
                        <Box className="wh-tw-text-xs wh-tw-text-black wh-tw-font-medium">Limited Time Only, 50% Off</Box>
                    </Box>
                    <Box flex={1} className={clsx("wh-tw-mt-2 md:wh-tw-flex", allowInline && "xl:wh-tw-mt-0")}>
                        <Box flex={1}>
                            <Box className="wh-tw-text-xl wh-tw-font-medium wh-tw-text-white">Upgrade to NEW Standard Membership at <span className="wh-tw-font-semibold">50% Off</span></Box>
                            <Box className={clsx("wh-tw-text-sm wh-tw-text-white wh-tw-mt-2", allowInline && "xl:wh-tw-mt-0")}>Upgrade for more physician visits and lab tests per year.</Box>
                        </Box>
                        <Box className="wh-tw-mt-6 md:wh-tw-mt-0" display="flex" alignItems="flex-end">
                            <WildHealthButton
                                id="standard-up-sell-request"
                                color="white"
                                size="medium"
                                fullWidth
                                style={{ borderRadius: 4 }}
                                onClick={() => patientStore.toggleRequestDialog(true)}
                            >
                                Learn More
                            </WildHealthButton>
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    }

    return null;
}