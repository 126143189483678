import { Box, FormControlLabel, Tooltip, FormControl, Select, MenuItem } from '@material-ui/core';
import React from 'react';
import { BackButton } from '../../../common/components/wildHealthButton/BackButton';
import { ArrowBack } from '@material-ui/icons';
import { navigationService } from '../../../../services/navigation.service';
import { useStyles } from './messageSettingsComponent.styles';
import { useFacade } from './messageSettingsComponent.hooks';
import { useHistory } from 'react-router';
import { SelectUserComponent } from '../../../common/components/selectUserComponent/SelectUserComponent';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import { WildHealthSwitcher } from '../../../common/components/WIldHealthSwitcher';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import information from "@img/icons/information.svg";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from "moment";
import DateFnsUtils from '@date-io/moment';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { toCurrentTimeZone } from '../../../timezones/helpers/timezone';

export const MessageSettingsComponent: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();

    const [
        {
            isLoading,
            templatesLoading,
            isChanged,
            isSubmitting,
            isUsersLoading,
            users,
            canSubmit,
            settings: {
                awayMessageEnabled,
                forwardEmployeeEnabled,
                forwardEmployeeId,
                awayMessageEnabledFrom,
                awayMessageEnabledTo,
                awayMessageTemplateId,
                message,
            },
            templates,
        },
        handleParamChanges,
        handleUserSelect,
        handleUserSearch,
        handleChangeSectionState,
        handleCancel,
        handleSaveChanges,
    ] = useFacade();

    const loading = isLoading || templatesLoading;

    const customTooltipClasses = {
        tooltip: classes.customWidth,
        arrow: classes.arrow,
        tooltipPlacementBottom: classes.tooltipPlacement,
        tooltipPlacementTop: classes.tooltipPlacement,
    };

    const getDate = (date) => {
        return date
            ? moment(toCurrentTimeZone(date)).format('YYYY-MM-DD')
            : null
    }

    const renderGoBack = () => {
        return (
            <Box
                className={classes.backButton}
                pb={2}
            >
                <BackButton
                    id="message-settings-back"
                    onClick={() => navigationService.toConversations(history)}
                >
                    <Box
                        display="flex"
                        alignItems="center"
                    >
                        <ArrowBack fontSize="small" />
                        <Box ml={1}>
                            <span>Back to Chat</span>
                        </Box>
                    </Box>
                </BackButton>
            </Box>
        )
    }

    const renderContent = () => {
        return (
            <Box className={classes.content}>
                <Box className={classes.contentItems}>
                    <Box>
                        <Box>
                            <Box display='flex' justifyContent='space-between'>
                                <Box display='flex' alignItems='center'>
                                    <Box className={classes.sectionTitle}>
                                        Away Notification
                                    </Box>
                                    <Tooltip placement="top" arrow title={'This will place a banner with your message for all of your patients to see.'}>
                                        <img src={information} alt="img" />
                                    </Tooltip>
                                </Box>
                                <FormControlLabel
                                    control={<WildHealthSwitcher
                                        disabled={isSubmitting}
                                        checked={awayMessageEnabled}
                                        onChange={handleChangeSectionState}
                                        name='awayMessageEnabled'
                                    />}
                                    label=''
                                />
                            </Box>
                            <Box>
                                {
                                    awayMessageEnabled &&
                                    <Box mt={4}>
                                        <Box mt={1.5}>
                                            <FormControl
                                                variant="outlined"
                                                color='primary'
                                                required
                                                size="small"
                                                disabled={isSubmitting || templatesLoading}
                                                classes={{ root: classes.inputRoot }}
                                                fullWidth>
                                                <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Select Message Template</Box>
                                                <Select
                                                    style={{ background: '#FFF' }}
                                                    classes={{ root: classes.inputRoot }}
                                                    value={awayMessageTemplateId}
                                                    inputProps={{
                                                        name: 'Away Message Template',
                                                        id: 'template-title',
                                                        classes,
                                                    }}
                                                    renderValue={() => {
                                                        if (awayMessageTemplateId === null || !awayMessageTemplateId) {
                                                            return <Box className="wh-tw-text-sm wh-tw-text-gray1">Select Template</Box>;
                                                        }
        
                                                        return templates.find(x => x.id === awayMessageTemplateId)?.title
                                                    }}
                                                    displayEmpty
                                                    onChange={(v) => handleParamChanges("awayMessageTemplateId", v.target.value)}
                                                >
                                                    {templates.map(template => (
                                                        <MenuItem value={template.id} key={template.id}>{template.title}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <Box mt={2} display="flex" alignItems="center" gridGap={16}>
                                            <Box flex={1}>
                                                <FormControl
                                                    variant="outlined"
                                                    color='primary'
                                                    required
                                                    size="small"
                                                    disabled={isLoading}
                                                    classes={{ root: classes.root }}
                                                    fullWidth>
                                                    <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Start Date</Box>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            id={`awayMessageEnabledFrom`}
                                                            fullWidth
                                                            size="small"
                                                            required
                                                            placeholder="Select date"
                                                            format="MMMM DD"
                                                            openTo="month"
                                                            value={getDate(awayMessageEnabledFrom)}
                                                            helperText={null}
                                                            inputVariant="outlined"
                                                            disabled={isLoading}
                                                            views={["year", "month", "date"]}
                                                            onChange={(momentDate) => handleParamChanges(`awayMessageEnabledFrom`, momentDate === null ? "" : momentDate.startOf('day'))}
                                                            KeyboardButtonProps={{
                                                                "aria-label": "change date",
                                                                "edge": "end",
                                                            }}
                                                            keyboardIcon={<DateRangeIcon className="wh-tw-text-mediumGray" />}
                                                            minDate={new Date()}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </FormControl>
                                            </Box>
                                            <Box flex={1}>
                                                <FormControl
                                                    variant="outlined"
                                                    color='primary'
                                                    required
                                                    size="small"
                                                    disabled={isLoading}
                                                    classes={{ root: classes.root }}
                                                    fullWidth>
                                                    <Box mb={1} display="flex" alignItems="center">
                                                        <Box className="wh-tw-text-sm wh-tw-text-gray1">Return Date</Box>
                                                        <Tooltip placement="top" arrow title={<p className={classes.notificationToolTipText}>Away message will no longer be active on this date</p>} classes={customTooltipClasses}>
                                                            <img src={information} alt="img" />
                                                        </Tooltip>
                                                    </Box>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            id={`awayMessageEnabledTo`}
                                                            fullWidth
                                                            size="small"
                                                            required
                                                            placeholder="Select date"
                                                            format="MMMM DD"
                                                            openTo="month"
                                                            value={getDate(awayMessageEnabledTo)}
                                                            helperText={null}
                                                            inputVariant="outlined"
                                                            disabled={isLoading}
                                                            views={["year", "month", "date"]}
                                                            onChange={(momentDate) => handleParamChanges(`awayMessageEnabledTo`, momentDate === null ? "" : momentDate.endOf('day'))}
                                                            KeyboardButtonProps={{
                                                                "aria-label": "change date",
                                                                "edge": "end",
                                                            }}
                                                            keyboardIcon={<DateRangeIcon className="wh-tw-text-mediumGray" />}
                                                            minDate={new Date(awayMessageEnabledFrom)}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </FormControl>
                                            </Box>
                                        </Box>
                                        {(message || awayMessageTemplateId) && (
                                            <Box mt={4}>
                                                <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Message Preview</Box>
                                                <Box className="wh-tw-bg-gray8" py={1.5} px={2}>
                                                    <Box className="wh-tw-text-black wh-tw-font-medium">{templates.find(x => x.id === awayMessageTemplateId)?.title}</Box>
                                                    <Box className="wh-tw-text-sm wh-tw-text-gray1 wh-tw-mt-1.5">{awayMessageTemplateId ? templates.find(x => x.id === awayMessageTemplateId)?.body : message} Return Date - {awayMessageEnabledTo ? moment(awayMessageEnabledTo).format('M/D/YY') : 'M/D/YY'}. Away message will no longer be active on this date.</Box>
                                                </Box>
                                            </Box>
                                        )}
                                    </Box>
                                }
                            </Box>
                        </Box>
                        <Box mt={4}>
                            <Box display='flex' justifyContent='space-between'>
                                <Box display='flex' alignItems='center'>
                                    <Box className={classes.sectionTitle}>
                                        Message Forwarding
                                    </Box>
                                    <Tooltip placement="top" arrow title={'This will forward all new message notifications to the selected user.'}>
                                        <img src={information} alt="img" />
                                    </Tooltip>
                                </Box>
                                <FormControlLabel
                                    control={<WildHealthSwitcher
                                        disabled={isSubmitting}
                                        checked={forwardEmployeeEnabled}
                                        onChange={handleChangeSectionState}
                                        name='forwardEmployeeEnabled'
                                    />}
                                    label=''
                                />
                            </Box>
                            <Box>
                                {
                                    forwardEmployeeEnabled &&
                                    <SelectUserComponent
                                        placeholder='Select User'
                                        selectedUser={users.find(i => i.id === forwardEmployeeId)}
                                        users={users}
                                        disabled={!forwardEmployeeEnabled || isSubmitting}
                                        handleChange={(user) => handleUserSelect(user)}
                                        handleInputChange={handleUserSearch}
                                        isLoading={isUsersLoading}
                                    />
                                }
                            </Box>
                        </Box>

                        {
                            isChanged &&
                            <Box display='flex' justifyContent="flex-end" mt={4}>
                                <Box mr={1}>
                                    <WildHealthButton
                                        id="message-settings-cancel"
                                        onClick={() => handleCancel()}
                                        disabled={isSubmitting}
                                        color="tertiary"
                                        size="medium"
                                    >
                                        Cancel
                                    </WildHealthButton>
                                </Box>
                                <Box>
                                    <WildHealthButton
                                        id="message-settings-save"
                                        onClick={() => handleSaveChanges()}
                                        loading={isSubmitting}
                                        disabled={isSubmitting || !canSubmit}
                                        size="medium"
                                    >
                                        Save Changes
                                    </WildHealthButton>
                                </Box>
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
        )
    }

    return (
        <Box className={classes.root}>
            {renderGoBack()}
            {loading ? <Box p={3}><WildHealthLinearProgress /></Box> : renderContent()}
        </Box>
    )
};
