import { Store, StoreConfig } from '@datorama/akita';
import { AlertsHistoryModel, AlertsModel } from '../../models/alerts.models';
import { NotificationModel } from '../../models/notifications.models';

export interface NotificationsState {
    notifications: NotificationModel[];
    alerts: AlertsModel[];
    alertsHistory: AlertsHistoryModel[];
    alertsHistoryTotalCount: number;
}

/**
 * Creates initial notifications state
 */
export function createInitialState(): NotificationsState {
    return {
        notifications: [],
        alerts: [],
        alertsHistory: [],
        alertsHistoryTotalCount: 0,
    };
}

/**
 * Provides notifications state management
 */
@StoreConfig({name: 'notifications', resettable: true })
export class NotificationsStore extends Store<NotificationsState> {
    constructor() {
        super(createInitialState());
    }

    public append(newNotifications: NotificationModel[]): void {
        const existingNotifications = this.getValue().notifications;
        const allNotifications = [
            ...existingNotifications,
            ...newNotifications.filter(x => !existingNotifications.find(t => t.id === x.id))
        ];

        this.update({
            notifications: allNotifications
        });
    }

    public deleteNotification(id: number) {
        const notifications = this.getValue().notifications.filter(x => x.id !== id);
        this.update({notifications: notifications});
    }

    public deleteAlert(id: number) {
        const alerts = this.getValue().alerts.filter(x => x.id !== id);
        this.update({alerts: alerts});
    }

    public deleteNotifications(notifications: NotificationModel[]) {
        const notificationIds = notifications.map(x => x.id);

        this.update({
            notifications: this.getValue().notifications.filter(x => !notificationIds.includes(x.id))
        });
    }
}

export const notificationsStore = new NotificationsStore();
