import { ReactComponent as ActivityAndSportsIcon } from '@img/icons/ActivityAndSports.svg';
import { ReactComponent as AnimalsNatureIcon } from '@img/icons/AnimalsNature.svg';
import { ReactComponent as FoodDrinkIcon } from '@img/icons/FoodDrink.svg';
import { ReactComponent as SmileysEmotionIcon } from '@img/icons/Reaction.svg';
import { ReactComponent as SymbolsIcon } from '@img/icons/Symbols.svg';
import { ReactComponent as TravelPlacesIcon } from '@img/icons/TravelPlaces.svg';
import { Box, Grid, Popover } from '@material-ui/core';
import BeachAccessOutlinedIcon from '@material-ui/icons/BeachAccessOutlined';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import ThumbsUpDownOutlinedIcon from '@material-ui/icons/ThumbsUpDownOutlined';
import clsx from 'clsx';
import React from 'react';
import { Group, useFacade } from "./emojiComponent.hooks";
import { EmojiButton, EmojiNavButton, useStyles } from "./emojiComponent.styles";

interface EmojiComponentProps {
    anchorEl: HTMLElement;
    handleClose: () => void;
    handleSelectEmoji: (emoji: string) => void;
}

export const EmojiComponent: React.FC<EmojiComponentProps> = (props: EmojiComponentProps) => {
    const { anchorEl, handleClose, handleSelectEmoji } = props;

    const classes = useStyles();

    const [
        {
            selectedGroupIndex,
        },
        handleSelectGroup,
    ] = useFacade();

    const isEmojiSupported = (emoji: string): boolean => {
        const ctx = document.createElement("canvas").getContext("2d");
        ctx.canvas.width = ctx.canvas.height = 1;
        ctx.fillText(emoji, -4, 4);
        return ctx.getImageData(0, 0, 1, 1).data[3] > 0;
    }

    const emojiGroups = [
        {
            title: 'Smileys&Emotion',
            icon: <SmileysEmotionIcon className={classes.filledIcon} />,
            items: '😀😃😄😁😆😅😂🤣😊😇🙂🙃😉😌😍🥰😘😗😙😚😋😛😝😜🤪🤨🧐🤓😎🤩🥳😏😒😞😔😟😕🙁☹️😣😖😫😩🥺😢😭😤😠😡🤬🤯😳🥵🥶😱😨😰😥😓🤗🤔🤭🤫🤥😶😐😑😬🙄😯😦😧😮😲🥱😴🤤😪😵🤐🥴🤢🤮🤧😷🤒🤕🤑🤠😈👿👹👺🤡💩👻💀👽👾🤖🎃😺😸😹😻😼😽🙀😿😾',
        },
        {
            title: 'Gesturesa & Body Parts',
            icon: <ThumbsUpDownOutlinedIcon className={classes.filledIcon} />,
            items: '👋🤚🖐✋🖖👌🤏✌️🤞🤟🤘🤙👈👉👆🖕👇☝️👍👎✊👊🤛🤜👏🙌👐🤲🤝🙏✍️💅🤳💪🦾🦵🦿🦶👣👂🦻👃🧠🦷🦴👀👅👄💋🩸',
        },
        {
            title: 'Animals & Nature',
            icon: <AnimalsNatureIcon className={classes.filledIcon} />,
            items: '🐶🐱🐭🐹🐰🦊🐻🐼🐻‍❄️🐨🐯🦁🐮🐷🐽🐸🐵🙈🙉🙊🐒🐔🐧🐦🐤🐣🐥🦆🦅🦉🦇🐺🐗🐴🦄🐝🐛🦋🐌🐞🐜🦟🦗🕷🕸🦂🐢🐍🦎🦖🦕🐙🦑🦐🦞🦀🐡🐠🐟🐬🐳🐋🦈🐊🐅🐆🦓🦍🦧🐘🦛🦏🐪🐫🦒🦘🐃🐂🐄🐎🐖🐏🐑🦙🐐🦌🐕🐩🦮🐕‍🦺🐈🐈‍⬛🐓🦃🦚🦜🦢🦩🐇🦝🦨🦡🦦🦥🐁🐀🦔🐾🐉🐲🌵🎄🌲🌳🌴🌱🌿🍀🎍🎋🍃🍂🍁🍄🐚🌾💐🌷🌹🥀🌺🌸🌼🌻🌞🌝🌛🌜🌚🌕🌖🌗🌘🌑🌒🌓🌔🌙🌎🌍🌏🪐💫⭐️🌟✨⚡️☄️💥🔥🌈⛅️☁️⛄️💨💧💦☔️🌊',
        },
        {
            title: 'Food & Drink',
            icon: <FoodDrinkIcon className={classes.strokedIcon} />,
            items: '🍏🍎🍐🍊🍋🍌🍉🍇🍓🍈🍒🍑🥭🍍🥥🥝🍅🍆🥑🥦🥬🥒🥣🥡🥢🧂',
        },
        {
            title: 'Travel & Places',
            icon: <TravelPlacesIcon className={classes.strokedIcon} />,
            items: '🚗🚕🚙🚌🚎🚓🚑🚒🚐🚚🚛🚜🦯🦽🦼🛴🚲🛵🛺🚨🚔🚍🚘🚖🚡🚠🚟🚃🚋🚞🚝🚄🚅🚈🚂🚆🚇🚊🚉✈️🛫🛬💺🚀🛸🚁🛶⛵️🚤🚢⚓️🪝⛽️🚧🚦🚥🚏🗿🗽🗼🏰🏯🎡🎢🎠⛲️🌋🗻⛺️🏠🏡🏭🏢🏬🏣🏤🏥🏦🏨🏪🏫🏩💒⛪️🕌🕍🛕🕋🗾🎑🌅🌄🌠🎇🎆🌇🌆🌃🌌🌉🌁',
        },
        {
            title: 'Activity & Sports',
            icon: <ActivityAndSportsIcon className={classes.filledIcon} />,
            items: '⚽️🏀🏈⚾️🥎🎾🏐🏉🥏🎱🪀🏓🏸🏒🏑🥍🥅⛳️🪁🏹🎣🤿🥊🥋🎽🛹🛷🥌🎿🏂🪂🏋️‍♀️🏋️🏋️🤼‍♀️🤼🤼🤸‍♀️🤸🤸⛹️‍♀️⛹️⛹️🤺🤾‍♀️🤾🤾🏌️‍♀️🏌️🏌️🏇🧘‍♀️🧘🧘🏄‍♀️🏄🏄🏊‍♀️🏊🏊🤽‍♀️🤽🤽🚣‍♀️🚣🚣🧗‍♀️🧗🧗🚵‍♀️🚵🚵🚴‍♀️🚴🚴🏆🥇🥈🥉🏅🎫🎪🤹🤹🤹🎭🩰🎨🎬🎤🎧🎼🎹🥁🪘🎷🎺🪗🎸🪕🎻🎲🎯🎳🎮🎰🧩',
        },
        {
            title: 'Objects',
            icon: <EmojiObjectsOutlinedIcon className={classes.filledIcon} />,
            items: '⌚️📱📲💻⌨️💽💾💿📀📼📷📸📹🎥📞☎️📟📠📺📻🧭⏱⏲⏰⌛️⏳📡🔋🔌💡🔦🪔🧯💸💵💴💶💷💰💳💎⚖️🧰🔧🔨🧲🔫💣🧨🪓🔪🚬🏺🔮📿🧿💈🔭🔬🩹🩺💊💉🩸🧬🦠🧫🧪🧹🧺🧻🚽🚰🚿🛁🛀🧼🪒🧽🧴🔑🛌🧸🛒🎁🎈🎏🎀🎊🎉🎎🏮🎐🧧✉️📩📨📧💌📥📤📦📜📑🧾📊📈📉📆📅📇📋📁📂📰📓📔📒📕📗📘📙📚📖🔖🧷🔗📎📐📏🧮📌📍📝🔍🔎🔏🔐🔒🔓',
        },
        {
            title: 'Symbols',
            icon: <SymbolsIcon className={classes.strokedIcon} />,
            items: '🧡💛💚💙💜🖤🤍🤎💔❣️💕💞💓💗💖💘💝💟🕎☦️🛐⛎♈️♉️♊️♋️♌️♍️♎️♏️♐️♑️♒️♓️🆔🉑📴📳🈶🈚️🈸🈺🈷️🆚💮🉐㊙️㊗️🈴🈵🈹🈲🅰️🅱️🆎🆑🅾️🆘❌⭕️🛑⛔️📛🚫💯💢🚷🚯🚳🚱🔞📵🚭❗️❕❓❔‼️⁉️🔅🔆〽️🚸🔱🔰♻️✅🈯️💹❎🌐💠Ⓜ️🌀💤🏧🚾♿️🅿️🈳🈂️🛂🛃🛄🛅🚹🚺🚼🚻🚮🎦📶🈁🔣ℹ️🔤🔡🔠🆖🆗🆙🆒🆕🆓⏸⏯⏹⏺⏭⏮⏩⏪⏫⏬🔼🔽🔀🔁🔂🔄🔃🎵🎶➕➖➗✖️♾💲💱〰️➰➿🔚🔙🔛🔝🔜✔️☑️🔘🔴🟠🟡🟢🔵🟣⚫️⚪️🟤🔺🔻🔸🔹🔶🔷🔳🔲◾️◽️◼️◻️🟥🟧🟨🟩🟦🟪⬛️⬜️🟫🔈🔇🔉🔊🔔🔕📣📢👁💬💭🃏🎴🀄️🕐🕑🕒🕓🕔🕕🕖🕗🕘🕙🕚🕛🕜🕝🕞🕟🕠🕡🕢🕣🕤🕥🕦🕧',
        },
        {
            title: 'Clothing & Accessories',
            icon: <BeachAccessOutlinedIcon className={classes.filledIcon} />,
            items: '🧳🌂🧵🧶👓🥽🥼🦺👔👕👖🧣🧤🧥🧦👗👘🥻🩱🩲🩳👙👚👛👜👝🎒👞👟🥾🥿👠👡🩰👢👑👒🎩🎓🧢',
        },
    ]

    const renderNavigation = (groups: Group[]) => {
        return (
            <Box className={classes.navigation}>
                {
                    groups.map((iem, index) =>
                        <EmojiNavButton
                            id={`emoji-${index}`}
                            key={index}
                            className={clsx(classes.emojiGroup, { [classes.emojiGroupSelected]: index === selectedGroupIndex })}
                            onClick={() => handleSelectGroup(index)}
                        >
                            {iem.icon}
                        </EmojiNavButton>
                    )
                }
            </Box>
        )
    }

    const renderGroup = (group: Group) => {
        return (
            <Box className={clsx(classes.group, 'flat-scroll')}>
                <Box mx={2} my={1} className={classes.title}>
                    {group.title}
                </Box>
                <Grid container>
                    {
                        Array.from(group.items).filter(x => isEmojiSupported(x)).map((item, index) =>
                            <EmojiButton id={`emoji-${group.title}`} key={index} onClick={() => handleSelectEmoji(item)}>
                                {item}
                            </EmojiButton>
                        )
                    }
                </Grid>
            </Box>
        )
    }

    return (
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            onClose={handleClose}
        >
            <Box>
                {renderGroup(emojiGroups[selectedGroupIndex])}
                {renderNavigation(emojiGroups)}
            </Box>
        </Popover>
    )
}