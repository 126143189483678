import React from "react";
import {
  Box,
  IconButton,
  Tooltip,
  useTheme,
  useMediaQuery,
  Button,
  Divider,
} from "@material-ui/core";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import InfoIcon from "@material-ui/icons/Info";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import clsx from "clsx";
import { StaffChatState, useFacade } from "./staffChatComponent.hooks";
import { useStyles } from "./staffChatComponent.styles";
import { MessagesPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/MessagesPlaceHolder";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { MessageComponent } from "../messageComponent/MessageComponent";
import { SelectConversationSubjectComponent } from "../selectConversationSubjectComponent/SelectConversationSubjectComponent";
import { supportConversationSubjects } from "../../models/conversationSubject.models";
import { ConversationComponent } from "../conversationComponent/ConversationComponent";
import { StaffConversationsHistoryComponent } from "../staffConversationsHistoryComponent/StaffConversationsHistoryComponent";
import {
  conversationTitles,
  ConversationType,
  PatientConversationModel,
} from "../../models/conversation.models";
import { MessageAvatarsComponent } from "../messageAvatarsComponent/MessageAvatarsComponent";
import { WildHealthClickableTooltip } from "../../../common/components/WildHealthClickableTooltip/WildHealthClickableTooltip";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";

interface StaffChatComponentProps {
  isLoading: boolean;
  targetConversationId?: number;
  conversations: PatientConversationModel[];
}

export const StaffChatComponent: React.FC<StaffChatComponentProps> = (
  props: StaffChatComponentProps
) => {
  const { isLoading, targetConversationId, conversations } = props;

  const [
    { author, creatingTicket, targetConversation, view },
    handleNavigateToChat,
    handleOpenConversation,
    handleNewConversationClick,
    handleStartConversation,
    handleNavigateToMessageHistory,
  ] = useFacade(targetConversationId);

  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const customTooltipClasses = {
    tooltip: classes.tooltip,
    arrow: classes.arrow,
    tooltipPlacementTop: classes.tooltipPlacement,
    tooltipPlacementBottom: classes.tooltipPlacement,
  };

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const tooltipContent = (
    <p className={classes.notificationToolTip}>
      <span className={classes.notificationToolTipText}>Topics for this thread:</span>
      <ul className={classes.notificationList}>
        {Array.from(supportConversationSubjects).map(([subject, title], index) => {
          return (
            <li key={index} className={classes.notificationToolTipText}>
              {title}
            </li>
          );
        })}
      </ul>
    </p>
  );

  const renderHeaderArea = () => {
    return (
      <Box className={classes.header}>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Box>
              {view !== StaffChatState.History && (
                <IconButton
                  id="staff-chat-go-back"
                  onClick={() => handleNavigateToMessageHistory()}
                >
                  {isSmallScreen ? <ChevronLeftIcon /> : <ArrowBackIcon />}
                </IconButton>
              )}
            </Box>
            <Box mr={1.5} display="flex" flexDirection="column">
              <Box className={classes.title}>
                {conversationTitles.get(ConversationType.Support)}
              </Box>
            </Box>
            <Box>
              <MessageAvatarsComponent
                participants={[{ image: '', initials: "S" }]}
                size={isSmallScreen && 35}
              />
            </Box>
          </Box>
          {isSmallScreen ? (
            <Box display="flex" alignItems="center">
              <WildHealthClickableTooltip
                tooltipContent={tooltipContent}
                isOpened={open}
                handleClose={handleTooltipClose}
                customClasses={customTooltipClasses}
              >
                <InfoIcon className={classes.notificationIcon} onClick={handleTooltipOpen} />
              </WildHealthClickableTooltip>

              <Box>
                <Button
                  id="health-care-chat-open"
                  onClick={() => handleNavigateToChat()}
                  disabled={isLoading}
                >
                  <MoreHorizIcon />
                </Button>
              </Box>
            </Box>
          ) : (
              <Box display="flex">
                <Box>
                  <WildHealthButton
                    id="staff-chat-open"
                    color="secondary"
                    size="medium"
                    onClick={() => handleNavigateToChat()}
                  >
                    Expand
                </WildHealthButton>
                </Box>
                <Box ml={2}>
                  <WildHealthButton
                    id="staff-chat-new-message"
                    size="medium"
                    onClick={() => handleNewConversationClick()}
                    disabled={view === StaffChatState.New}
                  >
                    New Message
                </WildHealthButton>
                </Box>
              </Box>
            )}
        </Box>
        {view === StaffChatState.Selected && isSmallScreen && (
          <Box className={classes.subjectSection}>
            Subject: <span className={classes.subjectTitle}>{targetConversation.subject}</span>
          </Box>
        )}
      </Box>
    );
  };

  const renderNotificationArea = () => {
    return (
      <Box mx={2} p={1.5} className={classes.notification}>
        <Tooltip title={tooltipContent} placement="top" arrow>
          <InfoOutlinedIcon className="wh-tw-text-secondary wh-tw-cursor-pointer wh-tw-mr-2.5" />
        </Tooltip>
        <span>
          Use this thread if you have questions about topics that do not concern your
          health
        </span>
      </Box>
    );
  };

  const renderConversationsHistory = () => {
    if (!conversations.length) {
      return <>
        <MessagesPlaceHolder message="No messages yet." />
        {isSmallScreen && (
          <Box position={'sticky'} bottom={20} width={1}>
            <Box p={2}>
              <WildHealthButton
                id="staff-chat-new-message"
                size="medium"
                onClick={() => handleNewConversationClick()}
                disabled={view === StaffChatState.New}
                fullWidth
              >
                New Message
              </WildHealthButton>
            </Box>
          </Box>
        )}
      </>;
    }

    return (
      <Box p={2}>
        {isSmallScreen ? (
          <>
            <StaffConversationsHistoryComponent
              height="calc(100vh - 325px)"
              conversations={conversations}
              targetConversation={targetConversation}
              handleSelect={(conversation) => handleOpenConversation(conversation)}
            />
            <Divider className={classes.divider} orientation="horizontal" />
            <Box mt={1}>
              <WildHealthButton
                id="staff-chat-new-message"
                size="medium"
                onClick={() => handleNewConversationClick()}
                disabled={view === StaffChatState.New}
                fullWidth
              >
                New Message
              </WildHealthButton>
            </Box>
          </>
        ) : (
            <StaffConversationsHistoryComponent
              height="calc(100vh - 410px)"
              conversations={conversations}
              targetConversation={targetConversation}
              handleSelect={(conversation) => handleOpenConversation(conversation)}
            />
          )}
      </Box>
    );
  };

  const renderCreateNewConversation = () => {
    const messages = [
      {
        originalMessage: "Hi there! Welcome to Wild Health.",
        date: new Date(),
      },
      {
        originalMessage: "What brought you in today?",
        date: new Date(),
      },
      {
        originalMessage: (
          <SelectConversationSubjectComponent
            subjects={supportConversationSubjects}
            disabled={creatingTicket}
            handleSelect={(subject) => handleStartConversation(subject)}
          />
        ),
        date: new Date(),
      },
    ];

    if (isSmallScreen) messages.pop();

    if (creatingTicket) {
      messages.push({
        originalMessage: "Creating ticket . . .",
        date: new Date(),
      });
    }

    return (
      <Box pt={5} className={clsx(classes.newMessage, "flat-scroll")}>
        <MessageComponent
          messageGroup={{
            authorFirstName: "Staff",
            authorLastName: " ",
            isMyMessage: false,
            messages: messages,
          }}
        />
        {isSmallScreen && (
          <SelectConversationSubjectComponent
            subjects={supportConversationSubjects}
            disabled={creatingTicket}
            handleSelect={(subject) => handleStartConversation(subject)}
          />
        )}
      </Box>
    );
  };

  const renderSelectedConversation = () => {
    return <ConversationComponent conversation={targetConversation} author={author} />;
  };

  const renderContent = () => {
    switch (view) {
      case StaffChatState.History:
        return renderConversationsHistory();
      case StaffChatState.New:
        return renderCreateNewConversation();
      case StaffChatState.Selected:
        return renderSelectedConversation();
    }
  };

  return (
    <Box className={classes.root}>
      {renderHeaderArea()}
      {!isSmallScreen && renderNotificationArea()}
      {isLoading ? (
        <Box p={3}>
          <WildHealthLinearProgress />
        </Box>
      ) : (
          renderContent()
        )}
    </Box>
  );
};
