export class HealthGoalsQuestionNames {
    static readonly YOUR_INTENTION = "YOUR_INTENTION";
    static readonly YOUR_EXERCISE_GOALS = "YOUR_EXERCISE_GOALS";
    static readonly PERSONAL_EXERCISE_GOAL = "PERSONAL_EXERCISE_GOAL";
    static readonly EXERCISE_ROUTINE_LOOK_LIKE = "EXERCISE_ROUTINE_LOOK_LIKE";
    static readonly YOUR_NUTRITION_GOALS = "YOUR_NUTRITION_GOALS";
    static readonly PERSONAL_NUTRITION_GOAL = "PERSONAL_NUTRITION_GOAL";
    static readonly FUTURE_NUTRITION_PLAN = "FUTURE_NUTRITION_PLAN";
    static readonly TYPE_OF_FOOD_DO_YOU_EAT = "TYPE_OF_FOOD_DO_YOU_EAT";
    static readonly YOUR_SLEEP_GOALS = "YOUR_SLEEP_GOALS";
    static readonly PERSONAL_SLEEP_GOAL = "PERSONAL_SLEEP_GOAL";
    static readonly GOOD_AND_BAD_SLEEP_ROUTINE = "GOOD_AND_BAD_SLEEP_ROUTINE";
    static readonly YOUR_MINDFULNESS_GOALS = "YOUR_MINDFULNESS_GOALS";
    static readonly PERSONAL_MINDFULNESS_GOAL = "PERSONAL_MINDFULNESS_GOAL";
    static readonly MINDFULNESS_PRACTICE_LOOK = "MINDFULNESS_PRACTICE_LOOK";
    static readonly YOUR_SOCIAL_CONNECTIONS = "YOUR_SOCIAL_CONNECTIONS";
    static readonly PERSONAL_SOCIAL_CONNECTION_GOAL = "PERSONAL_SOCIAL_CONNECTION_GOAL";
    static readonly DESCRIBE_SOCIAL_CONNECTIONS = "DESCRIBE_SOCIAL_CONNECTIONS";
    static readonly TAKE_TIME_TO_WALK_OUTSIDE = "TAKE_TIME_TO_WALK_OUTSIDE";
    static readonly TAKE_TIME_TO_WALK_OUTSIDE_COMMENT = "TAKE_TIME_TO_WALK_OUTSIDE_COMMENT";
    static readonly HEALS_HAPPINESS_PERFORMANCE = "HEALS_HAPPINESS_PERFORMANCE";
    static readonly HEALTH_FEELING = "HEALTH_FEELING";
    static readonly HEALS_COMMENTS = "HEALS_COMMENTS";
    static readonly OVERALL_PHYSICAL_HEALTH = "OVERALL_PHYSICAL_HEALTH";
    static readonly FREE_CHRONIC_PAIN = "FREE_CHRONIC_PAIN";
    static readonly FREE_CHRONIC_PAIN_COMMENTS = "FREE_CHRONIC_PAIN_COMMENTS";
    static readonly GET_SICK = "GET_SICK";
    static readonly SICK_COMMENTS = "SICK_COMMENTS";
    static readonly HEALTH_PHYSICAL_FEELING = "HEALTH_PHYSICAL_FEELING";
    static readonly HEALS_PHYSICAL_COMMENTS = "HEALS_PHYSICAL_COMMENTS";
    static readonly HEALTH_MORE_OR_LESS = "HEALTH_MORE_OR_LESS";
    static readonly HEALS_MORE_OR_LESS_COMMENTS = "HEALS_MORE_OR_LESS_COMMENTS";
    static readonly HAPPINESS_ENJOY_LIFE = "HAPPINESS_ENJOY_LIFE";
    static readonly HAPPINESS_ENJOY_LIFE_COMMENT = "HAPPINESS_ENJOY_LIFE_COMMENT";
    static readonly HAPPINESS_CONNECTIONS = "HAPPINESS_CONNECTIONS";
    static readonly HAPPINESS_CONNECTIONS_COMMENT = "HAPPINESS_CONNECTIONS_COMMENT";
    static readonly HAPPINESS_OPTIMISTIC_PESSIMISTIC = "HAPPINESS_OPTIMISTIC_PESSIMISTIC";
    static readonly HAPPINESS_OPTIMISTIC_PESSIMISTIC_COMMENT = "HAPPINESS_OPTIMISTIC_PESSIMISTIC_COMMENT";
    static readonly HAPPINESS_COACH_THERAPIST = "HAPPINESS_COACH_THERAPIST";
    static readonly HAPPINESS_COACH_THERAPIST_COMMENT = "HAPPINESS_COACH_THERAPIST_COMMENT";
    static readonly DEPRESSION_BORDERED = "DEPRESSION_BORDERED";
    static readonly DEPRESSION_BORDERED_COMMENT = "DEPRESSION_BORDERED_COMMENT";
    static readonly HAPPINESS_INTO_MY_80 = "HAPPINESS_INTO_MY_80";
    static readonly HAPPINESS_INTO_MY_80_COMMENT = "HAPPINESS_INTO_MY_80_COMMENT";
    static readonly HAPPINESS_OVERALL = "HAPPINESS_OVERALL";
    static readonly HAPPINESS_OVERALL_COMMENT = "HAPPINESS_OVERALL_COMMENT";
    static readonly PERFORMANCE_METRICS = "PERFORMANCE_METRICS";
    static readonly PERFORMANCE_METRICS_EXPLAIN = "PERFORMANCE_METRICS_EXPLAIN";
    static readonly PERFORMANCE_ACTIVITY_IMPORTANT = "PERFORMANCE_ACTIVITY_IMPORTANT";
    static readonly PERFORMANCE_ACTIVITY_IMPORTANT_COMMENT = "PERFORMANCE_ACTIVITY_IMPORTANT_COMMENT";
    static readonly PERFORMANCE_IMPROVEMENT = "PERFORMANCE_IMPROVEMENT";
    static readonly PERFORMANCE_IMPROVEMENT_COMMENT = "PERFORMANCE_IMPROVEMENT_COMMENT";
    static readonly PERFORMANCE_CONFIDENT_80S = "PERFORMANCE_CONFIDENT_80S";
    static readonly PERFORMANCE_CONFIDENT_80S_COMMENT = "PERFORMANCE_CONFIDENT_80S_COMMENT";
    static readonly PERFORMANCE_OVERALL = "PERFORMANCE_OVERALL";
    static readonly PERFORMANCE_OVERALL_COMMENT = "PERFORMANCE_OVERALL_COMMENT";    
}

export class HealthGoalsSectionNames {
    static readonly INTENTION = "INTENTION";
    static readonly EXERCISE_GOALS = "EXERCISE_GOALS";
    static readonly NUTRITION_GOALS = "NUTRITION_GOALS";
    static readonly SLEEP_GOALS = "SLEEP_GOALS";
    static readonly MINDFULNESS_GOALS = "MINDFULNESS_GOALS";
    static readonly CONNECTION_GOALS = "CONNECTION_GOALS";
    static readonly FEEL_GOOD = "FEEL_GOOD";
    static readonly OVERALL_PHSICAL_HEALTH = "OVERALL_PHSICAL_HEALTH";
    static readonly CHRONIC_PAIN = "CHRONIC_PAIN";
    static readonly HOW_OFTEN_SICK = "HOW_OFTEN_SICK";
    static readonly PHYSICAL_80S = "PHYSICAL_80S";
    static readonly YEAR_AGO = "YEAR_AGO";
    static readonly ENJOY_LIFE = "ENJOY_LIFE";
    static readonly GOOD_FELLINGS = "GOOD_FELLINGS";
    static readonly OPTIMISTIC_PESSIMISTIC = "OPTIMISTIC_PESSIMISTIC";
    static readonly HAVE_COACH = "HAVE_COACH";
    static readonly DEPRESSION_BORDERED = "DEPRESSION_BORDERED";
    static readonly HAPPY_80S = "HAPPY_80S";
    static readonly MENTAL_HEALTH = "MENTAL_HEALTH";
    static readonly METRICS_PERFORM = "METRICS_PERFORM";
    static readonly METRICS_PERFORM_COMMENT = "METRICS_PERFORM_COMMENT";
    static readonly ACTIVITIES_PERFORM = "ACTIVITIES_PERFORM";
    static readonly LAST_YEAR_IMPROVEMENTS = "LAST_YEAR_IMPROVEMENTS";
    static readonly PERFORMING_80S = "PERFORMING_80S";
    static readonly OVERALL_PERFORMANCE = "OVERALL_PERFORMANCE";
}

export const HealthGoalsQuestionnaireSections = {
    [HealthGoalsSectionNames.INTENTION]: [
        HealthGoalsQuestionNames.YOUR_INTENTION],
    [HealthGoalsSectionNames.EXERCISE_GOALS]: [
        HealthGoalsQuestionNames.YOUR_EXERCISE_GOALS,
        HealthGoalsQuestionNames.PERSONAL_EXERCISE_GOAL,
        HealthGoalsQuestionNames.EXERCISE_ROUTINE_LOOK_LIKE],
    [HealthGoalsSectionNames.NUTRITION_GOALS]: [
        HealthGoalsQuestionNames.YOUR_NUTRITION_GOALS,
        HealthGoalsQuestionNames.PERSONAL_NUTRITION_GOAL,
        HealthGoalsQuestionNames.FUTURE_NUTRITION_PLAN],
    [HealthGoalsSectionNames.SLEEP_GOALS]: [
        HealthGoalsQuestionNames.YOUR_SLEEP_GOALS,
        HealthGoalsQuestionNames.PERSONAL_SLEEP_GOAL,
        HealthGoalsQuestionNames.GOOD_AND_BAD_SLEEP_ROUTINE],
    [HealthGoalsSectionNames.MINDFULNESS_GOALS]: [
        HealthGoalsQuestionNames.YOUR_MINDFULNESS_GOALS,
        HealthGoalsQuestionNames.PERSONAL_MINDFULNESS_GOAL,
        HealthGoalsQuestionNames.MINDFULNESS_PRACTICE_LOOK],
    [HealthGoalsSectionNames.CONNECTION_GOALS]: [
        HealthGoalsQuestionNames.YOUR_SOCIAL_CONNECTIONS,
        HealthGoalsQuestionNames.PERSONAL_SOCIAL_CONNECTION_GOAL,
        HealthGoalsQuestionNames.DESCRIBE_SOCIAL_CONNECTIONS,
        HealthGoalsQuestionNames.TAKE_TIME_TO_WALK_OUTSIDE,
        HealthGoalsQuestionNames.TAKE_TIME_TO_WALK_OUTSIDE_COMMENT],
    [HealthGoalsSectionNames.FEEL_GOOD]: [
        HealthGoalsQuestionNames.HEALTH_FEELING,
        HealthGoalsQuestionNames.OVERALL_PHYSICAL_HEALTH,
        HealthGoalsQuestionNames.HEALS_HAPPINESS_PERFORMANCE,
        HealthGoalsQuestionNames.HEALS_COMMENTS],
    [HealthGoalsSectionNames.HOW_OFTEN_SICK]: [
        HealthGoalsQuestionNames.GET_SICK,
        HealthGoalsQuestionNames.SICK_COMMENTS,
        HealthGoalsQuestionNames.FREE_CHRONIC_PAIN,
        HealthGoalsQuestionNames.FREE_CHRONIC_PAIN_COMMENTS],
    [HealthGoalsSectionNames.PHYSICAL_80S]: [
        HealthGoalsQuestionNames.HEALTH_PHYSICAL_FEELING,
        HealthGoalsQuestionNames.HEALS_PHYSICAL_COMMENTS,
        HealthGoalsQuestionNames.HEALTH_MORE_OR_LESS,
        HealthGoalsQuestionNames.HEALS_MORE_OR_LESS_COMMENTS],
    [HealthGoalsSectionNames.ENJOY_LIFE]: [
        HealthGoalsQuestionNames.HAPPINESS_ENJOY_LIFE,
        HealthGoalsQuestionNames.HAPPINESS_ENJOY_LIFE_COMMENT,
        HealthGoalsQuestionNames.HAPPINESS_CONNECTIONS,
        HealthGoalsQuestionNames.HAPPINESS_CONNECTIONS_COMMENT,
        HealthGoalsQuestionNames.HAPPINESS_OPTIMISTIC_PESSIMISTIC,
        HealthGoalsQuestionNames.HAPPINESS_OPTIMISTIC_PESSIMISTIC_COMMENT],
    [HealthGoalsSectionNames.HAVE_COACH]: [
        HealthGoalsQuestionNames.HAPPINESS_COACH_THERAPIST,
        HealthGoalsQuestionNames.HAPPINESS_COACH_THERAPIST_COMMENT,
        HealthGoalsQuestionNames.DEPRESSION_BORDERED,
        HealthGoalsQuestionNames.DEPRESSION_BORDERED_COMMENT,
        HealthGoalsQuestionNames.HAPPINESS_OVERALL,
        HealthGoalsQuestionNames.HAPPINESS_OVERALL_COMMENT],
    [HealthGoalsSectionNames.METRICS_PERFORM]: [
        HealthGoalsQuestionNames.PERFORMANCE_METRICS,
        HealthGoalsQuestionNames.PERFORMANCE_METRICS_EXPLAIN],
    [HealthGoalsSectionNames.LAST_YEAR_IMPROVEMENTS]: [
        HealthGoalsQuestionNames.PERFORMANCE_IMPROVEMENT,
        HealthGoalsQuestionNames.PERFORMANCE_IMPROVEMENT_COMMENT],
    [HealthGoalsSectionNames.OVERALL_PERFORMANCE]: [
        HealthGoalsQuestionNames.PERFORMANCE_OVERALL,
        HealthGoalsQuestionNames.PERFORMANCE_OVERALL_COMMENT,
        HealthGoalsQuestionNames.PERFORMANCE_CONFIDENT_80S,
        HealthGoalsQuestionNames.PERFORMANCE_CONFIDENT_80S_COMMENT],
} as { [id: string]: string[] }