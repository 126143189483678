import { Box, Button, ButtonGroup, Divider, FormControl, GridList, GridListTile, GridListTileBar, IconButton, MenuItem, Select, CircularProgress } from '@material-ui/core';
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import React from 'react';
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { WildHealthPlaceHolder } from '../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder';
import { AttachmentModel, NoteModel, NotesType } from "../../models/notes.models";
import { NoteSignatureComponent } from "../noteSignature/NoteSignatureComponent";
import { useFacade } from './simpleComponent.hooks';
import { useStyles } from './simpleComponent.styles';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DescriptionIcon from '@material-ui/icons/Description';
import GetAppIcon from '@material-ui/icons/GetApp';
import { TextMarkdownComponent } from '../textMarkdownComponent/TextMarkdownComponent';
import { notesService } from '../../services/notes.service';
import { confirmService } from '../../../../services/confirm.service';
import EditIcon from '@material-ui/icons/Edit';
import { WildHealthStatusLabel } from '../../../common/components/wildHealthStatusLabel/WildHealthStatusLabel';
import moment from 'moment';


interface SimpleComponentProps {
    note: NoteModel;
    patientId: number;
    handleGoToNotes: () => void;
    handleGoToEditNote: (note: NoteModel) => void;
    isFilesDifferent: () => boolean;
}

export const SimpleComponent: React.FC<SimpleComponentProps> = (props: SimpleComponentProps) => {
    const {
        note,
        patientId,
        handleGoToNotes,
        handleGoToEditNote
    } = props;

    const [
        {
            content,
            internalContent,
            noteId,
            originalContent,
            originalInternalContent,
            isPdfDownloading
        },
        handleDownloadPdf,
        handleChooseNote,
        isFilesDifferent
    ] = useFacade(patientId, note);

    const classes = useStyles();

    const isLoading = !content || !note;

    if (isLoading) {
        return (<WildHealthLinearProgress />)
    }

    const getFileSize = (size: number) => {
        if (size < 1024) {
            return size + ' bytes';
        } else if (size > 1024 && size < 1048576) {
            return (size / 1024).toFixed(1) + ' KB';
        } else if (size > 1048576) {
            return (size / 1048576).toFixed(1) + ' MB';
        }
    }

    const renderTitle = () => {
        return <span>{note.name}</span>
    }

    const openAttachment = (item) => {
        notesService.download(item.id, item.fileName).subscribe();
    }

    const renderFile = (item: AttachmentModel, index: number) => {
        return (
            <GridListTile key={index}>
                <Box display='flex' alignItems='center' justifyContent='center' height="80%">
                    <IconButton id="simple-download-file" className={classes.fileButton} size='small' onClick={() => openAttachment(item)}>
                        <DescriptionIcon className={classes.fileIcon} />
                        <GetAppIcon className={classes.downloadIcon} />
                    </IconButton>
                </Box>
                <GridListTileBar
                    title={decodeURIComponent(item.fileName)}
                    subtitle={getFileSize(item.fileSize)}
                    actionIcon={<Box m={1} className={classes.icon}><AttachFileIcon /></Box>}
                />
            </GridListTile>
        )
    }

    const renderInternalNote = () => {
        return (
            <Box>
                <Box my={2} className={classes.title}>
                    <span className='uppercase'>Notes</span>
                </Box>
                <Box my={2}>
                    {
                        internalContent ?
                            <TextMarkdownComponent content={internalContent} classLink={classes.renderText} /> :
                            <WildHealthPlaceHolder message="No Notes." />
                    }
                </Box>
            </Box>
        )
    }

    const handleEditNote = () => { 
        confirmService.confirm('Are you sure?','Are you sure you wish to amend this note? All changes will be logged.')
        .subscribe(()=>{
            handleGoToEditNote(note);
        });
    }

    const displayEditButton = () => {

        return(
            <Button id="edit_current_note" onClick={() => handleEditNote()}>
                <Box display="flex" alignItems="center" mt={1}>
                    <Box>
                    <EditIcon />
                    </Box>
                </Box>
            </Button>
        );
    }

    const displaySelectVersion = () => {
        if(note.amendedNotes?.length === 0 || note.amendedNotes == null || note.amendedNotes.every(x => x.completedAt === null)){
            return
        }

        return(
            <Box display="flex" alignItems="center" justifyContent="flex-start" ml={2}>
                            <Box pr={2}>
                            <WildHealthStatusLabel color='normal'>Amended Note</WildHealthStatusLabel>
                            </Box>
                            <Box width="120px">
                                <FormControl color='primary' variant="outlined" size="small" fullWidth>
                                    <Select
                                        style={{ background: '#FFF' }}
                                        id="choseNoteVersion"
                                        value={noteId}
                                        onChange={(v) => handleChooseNote(v.target.value)}>
                                        <MenuItem key={0} value={note.id}>Original</MenuItem>
                                        {
                                            note.amendedNotes.filter(x => x.completedAt != null).map((note, i) => {
                                                return <MenuItem key={i+1} value={note.id}>Amended - {moment(note.completedAt).format("MM/DD/YYYY")}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
        );
    }

    const displayDownloadPdfButton = () => {
        if (isPdfDownloading === true) {
            return (
                <Button>
                    <Box width="70px" display="flex" justifyContent="center">
                        <CircularProgress size={24} className={classes.colorMain} />
                    </Box>
                </Button>
            );
        }

        return (
            <Button id="blank-note-download-pdf" onClick={() => handleDownloadPdf(note)}>
                <Box display="flex" alignItems="center" mt={1}>
                    <Box>
                        <GetAppIcon />
                    </Box>
                    <Box mt={-1}>
                        PDF
                    </Box>
                </Box>
            </Button>
        );
    }

    const renderAttachments = () => {
        return (
            <Box>
                <Box my={2} className={classes.title}>
                    <span className='uppercase'>Attachments:</span>
                </Box>
                <Box my={2}>
                    {
                        content?.length ?
                            <div className={classes.attachments}>
                                <GridList className={classes.list} cellHeight={400}>
                                    {
                                        content?.map((item, index) => { return renderFile(item, index) })
                                    }
                                </GridList>
                            </div> :
                            <WildHealthPlaceHolder message="No attachments." />
                    }
                </Box>
            </Box>
        )
    }

    const renderNote = () => {
        return (
            <>
                <Box mt={5}>
                    {renderInternalNote()}
                    {renderAttachments()}
                </Box>
                <Box>
                {
                        (originalInternalContent != internalContent || isFilesDifferent(originalContent, content)) &&
                        (
                            <Box mt={3}>
                                <Box py={2}>
                                    <Divider />
                                </Box>
                                {
                                    originalInternalContent != internalContent &&
                                    <Box>Internal Content was updated.</Box>
                                }
                                {
                                    isFilesDifferent(originalContent, content) &&
                                    <Box>Files was updated.</Box>
                                }
                            </Box>
                        )
                    }
                </Box>
                <Box mt={7}>
                    <NoteSignatureComponent note={note} noteId={noteId} />
                </Box>
            </>
        )
    }

    return (
        <Box m={5} mt={0} pt={3}>
            <Box display="flex " alignItems="center">
                <ButtonGroup size="large" variant="text" color="default"
                    aria-label="large outlined primary button group">
                    <Button
                        id="simple-go-to-notes"
                        onClick={() => handleGoToNotes()}
                        startIcon={<ArrowBackIosRoundedIcon />}
                    >
                        To Notes
                    </Button>
                    <Button disabled className={classes.title}>
                        {
                            isLoading
                                ? 'Loading . . .'
                                : renderTitle()
                        }
                    </Button>
                    {
                        displayEditButton()
                    }
                    {
                        displaySelectVersion()
                    }
                    {
                        displayDownloadPdfButton()
                    }
                </ButtonGroup>
            </Box>

            <Box mt={5}>
                <Box mt={5}>
                    {
                        isLoading
                            ? <WildHealthLinearProgress />
                            : renderNote()
                    }
                </Box>
            </Box>
        </Box>
    );
}
