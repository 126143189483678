import { Box, useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { WildHealthButton } from '../../common/components/wildHealthButton/WildHealthButton';
import { HealthRecommendationComponent } from '../components/healthRecommendationComponent/HealthRecommendationComponent';
import { RecommendationSingleSelectComponent } from '../components/recommendationSingleSelectComponent/RecommendationSingleSelectComponent';
import { HealthReportMode, HealthReportSections, HealthReportVersion, recommendationRowsCapacity, RecommendationTypesNumbers } from '../models/healthReport.models';
import { HealthReportBasePage, HealthReportMobileBasePage } from "./HealthReportBasePage";
import { useStyles } from './healthReport.styles';
import { HealthReportPageProps } from "./healthReportPageProps";

export const HealthReportChronicDisease2Page: React.FC<HealthReportPageProps> = (props: HealthReportPageProps) => {
    const {
        report,
        pageNumber,
        handleChanges,
        handleSaveChanges,
        isChanged,
        mode,
        practice,
        recommendations,
        handleSelected,
        handleChangesNewRecommendation,
        handleAddRecommendation,
        handleSaveChangesNew,
    } = props;
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        props.mode !== HealthReportMode.PrintMode && isSmallScreen ? (
            <HealthReportMobileBasePage section={HealthReportSections.ChronicDisease} pageNumber={pageNumber} practice={practice}>
                <Box px={2}>
                    <Box>
                        <Box>
                            <span className={classes.reportTitle}>Your Cardiovascular Disease Recommendations</span>
                            <Box display="flex" justifyContent="flex-end" width={1}>
                                {
                                    parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation) && HealthReportMode.EditMode === mode && <WildHealthButton
                                        id="health-recommendation-new-save"
                                        onClick={() => handleSaveChangesNew()}
                                        disabled={!isChanged}>
                                        <Box >
                                            Save changes
                                        </Box>
                                    </WildHealthButton>
                                }
                            </Box>
                        </Box>
                        <Box mt="10px">
                            {parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
                                ? <Box>
                                    <RecommendationSingleSelectComponent
                                    currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.Cardiovascular)}
                                    recommendation={report.longevityReport.cardiovascularRecommendation}
                                        type={RecommendationTypesNumbers.Cardiovascular}
                                        handleSelected={handleSelected}
                                        handleChanges={handleChangesNewRecommendation}
                                        handleAddRecommendation={handleAddRecommendation}
                                        mode={mode}
                                    />
                                </Box>
                                : <Box>
                                    <HealthRecommendationComponent
                                        mode={mode}
                                        rows={recommendationRowsCapacity[RecommendationTypesNumbers.Cardiovascular]}
                                        recommendation={report.longevityReport.cardiovascularRecommendation}
                                        handleContentChanges={!handleChanges ? handleChanges : (value) => {
                                            handleChanges('longevityReport.cardiovascularRecommendation.content', value)
                                        }}
                                        handleManualContentChanges={!handleChanges ? handleChanges : (value) => {
                                            handleChanges('longevityReport.cardiovascularRecommendation.manualContent', value)
                                        }}
                                        handleSaveChanges={handleSaveChanges}
                                        isChanged={isChanged} />
                                </Box>
                            }
                        </Box>
                    </Box>
                </Box>
            </HealthReportMobileBasePage>
        ) : (
            <HealthReportBasePage section={HealthReportSections.ChronicDisease} pageNumber={pageNumber} practice={practice}>
                <Box px={15} pt={5}>
                    <Box>
                        <Box display="flex" justifyContent="space-between">
                            <span className="report-title">Your Cardiovascular Disease Recommendations</span>
                            {
                                parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation) && HealthReportMode.EditMode === mode && <WildHealthButton
                                    id="health-recommendation-new-save"
                                    onClick={() => handleSaveChangesNew()}
                                    disabled={!isChanged}>
                                    <Box >
                                        Save changes
                                    </Box>
                                </WildHealthButton>
                            }
                        </Box>
                        <Box mt={1}>
                            {parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
                                ? <Box>
                                    <RecommendationSingleSelectComponent
                                    currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.Cardiovascular)}
                                    recommendation={report.longevityReport.cardiovascularRecommendation}
                                        type={RecommendationTypesNumbers.Cardiovascular}
                                        handleSelected={handleSelected}
                                        handleChanges={handleChangesNewRecommendation}
                                        handleAddRecommendation={handleAddRecommendation}
                                        mode={mode}
                                    />
                                </Box>
                                : <Box>
                                    <HealthRecommendationComponent
                                        mode={mode}
                                        rows={recommendationRowsCapacity[RecommendationTypesNumbers.Cardiovascular]}
                                        recommendation={report.longevityReport.cardiovascularRecommendation}
                                        handleContentChanges={!handleChanges ? handleChanges : (value) => {
                                            handleChanges('longevityReport.cardiovascularRecommendation.content', value)
                                        }}
                                        handleManualContentChanges={!handleChanges ? handleChanges : (value) => {
                                            handleChanges('longevityReport.cardiovascularRecommendation.manualContent', value)
                                        }}
                                        handleSaveChanges={handleSaveChanges}
                                        isChanged={isChanged} />
                                </Box>
                            }


                        </Box>
                    </Box>
                </Box>
            </HealthReportBasePage>
        )
    )
}
