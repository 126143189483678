import { useEffect, useState } from "react";
import { IErrorState } from "../../../common/validation/error-state";
import { SupplementItem } from "../../../notes/models/notes.models";
import { SupplementComponentProps } from "./supplementComponent.props";
import { supplementComponentValidator } from "./supplementComponent.validator";
import {authQuery} from "../../../auth/stores/auth";
import {defaultDosage} from "../../constants/medicationsAndSupplementsContants";

interface SupplementComponentState extends IErrorState {
    model: SupplementItem;
}

const initModel = () => ({
    id: 0,
    name: '',
    dosage: '',
    instructions: authQuery.isEmployeeUser() ? defaultDosage : '',
    purchaseLink: '',
    isInCurrent: false,
    isStopped: false,
});

export function useFacade(props: SupplementComponentProps): [
    SupplementComponentState,
    (field: string, value: string) => void,
    () => void,
    () => void,
] {

    const [state, setState] = useState({
        model: Object.assign({}, initModel()),
        errors: {}
    } as SupplementComponentState);

    const handleChange = (field: string, value: string) => {
        supplementComponentValidator.validateAndSetState(state, setState, field, value);

        const model = state.model;
        model[field] = value;

        setState({
            ...state,
            model: model
        });
    }

    const handleSubmit = () => {
        supplementComponentValidator.validateObjectAndSetState(state, setState, state.model);
        if (!supplementComponentValidator.stateIsValid(state)) {
            return;
        }

        props.handleSave(state.model);
        handleClose();
    }

    const handleClose = () => {
        props.handleClose();
    }

    useEffect(() => {
        if (!props.isOpened) {
            setState(state => ({...state, model: {...state.model, ...Object.assign({}, props.initModel)}, errors: {}}));
        }

        return () => {};
    }, [props.isOpened, props.initModel]);

    return [
        state,
        handleChange,
        handleClose,
        handleSubmit
    ];
}
