import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    subscriptionCard: {
        marginTop: 10,
        borderRadius: 5,
        border: 'none',
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
        fontSize: 14,
        lineHeight: 16,
    },
});