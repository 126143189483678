import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        addIcon: {
            backgroundColor: colors.separators,
            color: colors.main,
            cursor: 'pointer',
        },
        addText: {
            color: colors.main
        },
        textFiel: {
            "& > div": {
                background: colors.disabled,
            }  
        },
        textSecondary: {
            textTransform: "uppercase",
            color: colors.gray1,
            fontSize: 12,
        },
        noRecommendation: {
            display: "flex",
            color: colors.gray2,
            paddingLeft: 20,
            paddingTop: 12,
            marginTop: 8,
            marginBottom: 8,
            marginLeft: 42,
            width: 'calc(100% - 100px)',
            background: colors.disabled,
            border: `1px solid ${colors.gray4}`,
            borderRadius: '2px',
        },
        recommendationUpdate: {
            display: 'none',
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: `linear-gradient(90deg, rgba(0, 0, 0, 0.7) 0.01%, rgba(0, 0, 0, 0) 83%)`,
            alignItems: 'center',
            border: 'none',
            cursor: 'pointer',
            color: colors.white,
            fontWeight: 500,
            paddingLeft: 20,
            fontSize: 14,

        },
        recommendation: {
            position: 'relative',
            display: "flex",
            color: colors.gray2,
            marginTop: 8,
            marginBottom: 8,
            width: '100%',
            background: colors.disabled,
            border: `1px solid ${colors.gray4}`,
            borderRadius: '2px',
            fontSize: 16,
            fontWeight: 400,

            '&:hover > :first-child': {
                display: 'flex'
            }
        },

        noRecommendationNew: {
            display: "flex",
            color: colors.gray2,
            paddingLeft: 20,
            paddingTop: 12,
            marginTop: 8,
            marginBottom: 8,
            marginLeft: 42,
            width: 'calc(50% - 44px)',
            background: colors.disabled,
            border: `1px solid ${colors.gray4}`,
            borderRadius: '2px',
        }
    })
);
