import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { onEmit } from "../../../common/helpers/on-emit";
import { CallbackModel } from "../../../common/models/callback.model";
import { IErrorState } from "../../../common/validation/error-state";
import { CreateLocationModel, LocationModel } from "../../models/locations.models";
import { locationsService } from "../../services/locations.service";
import { createLocationDialogComponentValidator } from "./createLocationDialogComponent.validator";

interface CreateLocationDialogComponentState extends IErrorState {
    model: CreateLocationModel;
    isOpen: boolean;
    callback: Function;
}

const defaultState: CreateLocationDialogComponentState = {
    model: {
        name: '',
        description: '',
        country: 'US',
        city: '',
        state: '',
        zipCode: '',
        streetAddress1: '',
        streetAddress2: ''
    },
    isOpen: false,
    callback: null,
    errors: {}
}

export function useFacade(): [CreateLocationDialogComponentState, Function, Function, Function] {
    const [state, setState] = useState(defaultState);

    const handleChanges = (key: string, value: string) => {
        createLocationDialogComponentValidator.validateAndSetState(state, setState, key, value);
        const model = state.model;
        model[key] = value;
        setState({...state, model});
    }

    const handleSubmit = () => {
        createLocationDialogComponentValidator.validateObjectAndSetState(state, setState, state.model);
        if (!createLocationDialogComponentValidator.stateIsValid(state)) {
            return;
        }

        state.callback(state.model);
        setState(defaultState);
    }

    const handleClose = () => {
        state.callback(null);
        setState(defaultState);
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<CallbackModel<LocationModel>>(locationsService.locationOnCreate, model => {
                setState(state => ({
                    ...state,
                    isOpen: true,
                    errors: {},
                    model: {
                        name: '',
                        description: '',
                        country: 'US',
                        city: '',
                        state: '',
                        zipCode: '',
                        streetAddress1: '',
                        streetAddress2: ''
                    },
                    callback: model.callback
                }));
            }),
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [state, handleChanges, handleSubmit, handleClose];
}