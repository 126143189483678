import { Box, Button } from "@material-ui/core";
import React from "react";
import { ChevronLeft } from '@material-ui/icons';
import { commonUseStyles } from "../../../common/styles/common.styles";
import clsx from "clsx";

interface RegistrationBackButtonProps {
    goBack: () => void;
    icon?: boolean;
    label?: string;
}

export const RegistrationBackButton: React.FC<RegistrationBackButtonProps> = (props: RegistrationBackButtonProps) => {
    const {
        goBack,
        icon = true,
        label
    } = props;

    const commonClasses = commonUseStyles();

    return (
        <Box mb={2}>
            <Button
                id="registration-back-button"
                onClick={() => goBack()}
                className={commonClasses.backBtn}
            >
                <Box display="flex" alignItems="center">
                    {icon && <ChevronLeft className={commonClasses.colorNavy} />}
                    <Box ml={0.5} className={clsx(commonClasses.size16, commonClasses.colorNavy)}>
                        {label ?? 'Back'}
                    </Box>
                </Box>
            </Button>
        </Box>
    );
}
