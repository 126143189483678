import { SortingDirection } from "../../../common/sorting/models/sorting-destination";
import {NoteModel, NotesType, SignOffNoteModel} from "../../models/notes.models";
import { IPaginationState } from "../../../common/pagination/models/pagination-state";
import { ISortingState } from "../../../common/sorting/models/sorting-state";
import { notesService, SignOffModel } from "../../services/notes.service";
import { getAvailablePages } from "../../../common/pagination/helpers/get-evailable-pages";
import { useEffect, useState } from "react";
import { pageSizes } from "../../../common/pagination/models/page-sizes";
import { navigationService } from "../../../../services/navigation.service";
import { useHistory } from "react-router";
import {confirmService} from "../../../../services/confirm.service";

enum SortingSource {
    none,
    date = "assignedAt"
}

interface SignOffNotesReviewComponentState extends IPaginationState, ISortingState {
    isLoading: boolean;
    notes: NoteModel[];
    noteTypes: NotesType[];
    isMenuOpened: boolean;
    targetNoteId: number | null;
    menuAnchorEl: any;
    isFilterOpened: boolean;
    filterAnchorEl: any;
}

let stateContext: SignOffNotesReviewComponentState;

export function useFacade(): [
    SignOffNotesReviewComponentState,
    (source: string, direction: SortingDirection) => void,
    (value: any) => void,
    () => number[],
    (page: number) => void,
    (note: NoteModel) => void,
    (note: NoteModel) => void,
    (note: NoteModel) => void,
    () => void,
    () => void,
    (id?: number, anchorEl?: Element) => void,
    (anchorEl?: Element) => void,
    (noteType: NotesType) => void
] {
    const history = useHistory();
    const [state, setState] = useState({
        isLoading: false,
        notes: [],
        noteTypes: null,
        sortingSource: SortingSource.date,
        sortingColumns: [
            { title: 'Date Requested', source: SortingSource.date, direction: SortingDirection.Desc }
        ],
        totalCount: 0,
        selectedPage: 1,
        pageSize: pageSizes[0],
        targetNoteContent: null,
        targetNoteMedications: null,
        targetNoteSupplements: null,
        downloadingNoteId: null,
        isMenuOpened: false,
        targetNoteId: null,
        menuAnchorEl: null,
        isFilterOpened: false,
        filterAnchorEl: null
    } as SignOffNotesReviewComponentState);

    stateContext = state;

    const selectNotes = (size?: number, page?: number, source?: string, direction?: string) => {
        setState(state => ({
            ...state,
            isLoading: true
        }));

        const sortingColumn = state.sortingColumns.find(i => i.source === state.sortingSource);

        notesService.getNotesForReview(
            size ?? state.pageSize,
            ((page ?? state.selectedPage) - 1) * (page ?? state.selectedPage),
            state.noteTypes,
            source ?? state.sortingSource,
            direction ?? sortingColumn.direction
        ).subscribe((data) => setState(state => ({
            ...state,
            isLoading: false,
            notes: data.data,
            totalCount: data.totalCount
        })));
    }

    const setDirection = (source: string, direction: SortingDirection) => {
        const column = state.sortingColumns.find(item => item.source === source);

        if (column) {
            column.direction = direction;
            setState(state => ({ ...state, columns: state.sortingColumns }));
        }
    }

    const handlePageChange = (page: number) => {
        if (page === state.selectedPage) {
            return;
        }

        setState(state => ({
            ...state,
            selectedPage: page
        }));

        selectNotes(
            null,
            page,
            null,
            null
        );
    }

    const handlePageSizeChange = (pageSize: any) => {
        if (pageSize === state.pageSize) {
            return;
        }

        setState(state => ({
            ...state,
            pageSize: pageSize,
        }));

        selectNotes(
            pageSize,
            null,
            null,
            null
        );
    }

    const getAllAvailablePages = () => {
        return getAvailablePages(state);
    }

    const handleSorting = (source: string, direction: SortingDirection) => {
        if (state.sortingSource === source) {
            direction = direction === SortingDirection.Asc
                ? SortingDirection.Desc
                : SortingDirection.Asc;
            setDirection(source, direction);
        } else {
            setState(state => ({
                ...state,
                sortingSource: source,
            }));
        }

        selectNotes(
            null,
            null,
            source,
            direction
        );
    }

    const handleViewPatient = (note: NoteModel) => {
        navigationService.toManagePatientProfile(history, note.patientId);
    }

    const handleViewNote = (note: NoteModel) => {
        switch (note.type) {
            case NotesType.Initial: return navigationService.toCreateInitialConsultNote(history, note.patientId, null, note, true);
            case NotesType.FollowUp: return navigationService.toCreateFollowUpNote(history, note.patientId, null, note, true);
            case NotesType.Blank:
            case NotesType.Internal: return navigationService.toCreateSimpleNote(history, note.type, note.patientId, null, note, true);
            case NotesType.Soap: return navigationService.toCreateSoapNote(history, note.patientId, null, note, true);
            case NotesType.HistoryAndPhysicalInitial: return navigationService.toCreateHistoryAndPhysicalNote(history, note.patientId, null, note, true);
            case NotesType.HistoryAndPhysicalFollowUp: return navigationService.toCreateHistoryAndPhysicalFollowUpNote(history, note.patientId, null, note, true);
            case NotesType.HistoryAndPhysicalGroupVisit: return navigationService.toCreateHistoryAndPhysicalGroupVisitNote(history, note.patientId, null, note, true);
            default: return;
        }
    }

    const handleSignOffAndComplete = (note: NoteModel) => {
        confirmService.confirm('Are you sure? This action can not be undone').subscribe(() => {
            notesService.complete(note.id).subscribe(
                () => {selectNotes()},
                () => {}
            )
        });
    }

    const handleSignOff = () => {
        const note = state.notes.find(x => x.id === state.targetNoteId);

        if (!note) {
            return;
        }

        notesService.openSignOff({
            patientId: note.patientId,
            noteType: note.type
        } as SignOffModel).subscribe(([employeeId, additionalNote]) => {
            const model: SignOffNoteModel = {
                noteId: note.id,
                assignToId: employeeId,
                additionalNote: additionalNote
            };

            handleToggleActions(0, null);

            notesService.signOff(model).subscribe(
                () => {selectNotes()},
                () => {}
            );
        });
    }

    const cancelSignOff = () => {
        const note = state.notes.find(x => x.id === state.targetNoteId);

        if (!note) {
            return;
        }

        handleToggleActions(0, null);

        notesService.cancelSignOff(note.id).subscribe(
            () => {selectNotes()},
            () => {}
        );
    }

    const handleToggleActions = (id?: number, anchorEl?: Element) => {
        setState(state => ({
            ...state,
            isMenuOpened: !state.isMenuOpened,
            targetNoteId: id ?? state.targetNoteId,
            menuAnchorEl: anchorEl
        }))
    }

    const handleToggleFilters = (anchorEl?: Element) => {
        setState(state => ({
            ...state,
            isFilterOpened: anchorEl ? !state.isFilterOpened : false,
            filterAnchorEl: anchorEl
        }))
    }

    const handleFilterChanges = (noteType: NotesType) => {
        let noteTypes = state.noteTypes ?? [];
        if (noteTypes.includes(noteType)) {
            noteTypes = noteTypes.filter(x => x !== noteType);
        } else {
            noteTypes.push(noteType);
        }

        setState(state => ({
            ...state,
            noteTypes: noteTypes.slice()
        }));
    }

    useEffect(() => {
        selectNotes();
    }, []);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (stateContext.noteTypes != null) {
                handleToggleFilters();

                selectNotes();
            }
        }, 1500);

        return () => clearTimeout(delayDebounceFn)
    }, [state.noteTypes]);

    return [
        state,
        handleSorting,
        handlePageSizeChange,
        getAllAvailablePages,
        handlePageChange,
        handleViewNote,
        handleViewPatient,
        handleSignOffAndComplete,
        handleSignOff,
        cancelSignOff,
        handleToggleActions,
        handleToggleFilters,
        handleFilterChanges
    ]
}