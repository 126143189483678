import {useEffect, useRef, useState} from "react";
import {authService} from "../../services/auth.service";

interface LoginRxNTPageState {
    inProgress: boolean;
    redirectTo: string;
    token: string;
}

export function useFacade(patientId: number, redirectTo: string | null): [
    LoginRxNTPageState,
    any,
    Function
] {

    const [state, setState] = useState({
        inProgress: true,
        redirectTo: redirectTo,
        token: '',
        errors: {}
    } as LoginRxNTPageState);

    const formRef = useRef(null);

    const handleSubmit = () => {
        formRef.current.submit();
    }

    useEffect(() => {
        authService.loginInRxNT(patientId).subscribe(
            data => {
                setState(state => ({
                    ...state,
                    token: data,
                    inProgress: false
                }))
            }
        )
    }, [patientId])

    useEffect(() => {
        if (!state.inProgress) {
            handleSubmit();
        }
    }, [state.inProgress])

    return [
        state,
        formRef,
        handleSubmit,
    ];
}
