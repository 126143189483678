import { Query } from '@datorama/akita';
import { PermissionType, Practice, UserType } from '../../models/auth.enums';
import { AuthState, AuthStore, authStore } from './auth.store';
import { ExternalAuthModel, RefreshTokenModel } from "../../models/auth.models";
import { PRACTICE_ID } from "../../../../config";
import { roles } from '../../../common/constants/roles';
import { EmployeeType } from '../../../employee/models/employee.enums';
import { SubscriptionStatus } from '../../../payment/models/subscription.models';
import * as Sentry from "@sentry/browser";
import { Severity } from "@sentry/browser";
import moment from 'moment';

/**
 * Provides method for working with auth store
 */
export class AuthQuery extends Query<AuthState> {
    firstName$ = this.select(state => state.auth.firstName);
    lastName$ = this.select(state => state.auth.lastName);
    targetLocationId$ = this.select(state => state.auth.targetLocationId);
    isRegistrationCompleted$ = this.select(state => state.auth.registrationCompleted);
    userType$ = this.select(state => state.auth.type);
    userId$ = this.select(state => state.auth.id);
    isAgreementsChanged$ = this.select(state => state.auth.isAgreementsChanged);
    isNewAccount$ = this.select(state => state.isNewAccount);
    externalAuth$ = this.select(state => state.externalAuth)

    constructor(protected store: AuthStore) {
        super(store);
    }

    /**
     * Returns auth type
     */
    public getType(): UserType {
        return this.getValue().auth.type;
    }

    /**
     * Returns role id
     */
    public getRoleId(): number | null {
        return this.getValue().auth.roleId;
    }

    /**
     * Returns auth type
     */
    public getPracticeId(): number {
        return this.getValue().auth.practiceId;
    }

    /**
     * Returns auth type
     */
    public getEmail(): string {
        return this.getValue().auth.email;
    }

    /**
     * Returns name
     */
    public getName(): string {
        const store = this.getValue();
        return `${store.auth.firstName} ${store.auth.lastName}`;
    }

    /**
     * Returns name
     */
    public getFirstName(): string {
        const store = this.getValue();
        return store.auth.firstName;
    }

    /**
     * Returns user permissions
     */
    public getPermissions(): PermissionType[] {
        return this.getValue().auth.permissions;
    }

    /**
     * Returns refresh token
     */
    public getRefreshToken(): RefreshTokenModel {
        return this.getValue().auth.refreshTokenModel;
    }

    /**
     * Returns is user verified
     */
    public isVerified(): boolean {
        return this.getValue().auth.isVerified;
    }

    /**
     * Returns is registration completed
     */
    public isRegistrationCompleted(): boolean {
        return this.getValue().auth.registrationCompleted;
    }

    /**
     * Returns is user have confirmed agreements
     */
    public isAgreementsConfirmed(): boolean {
        return this.getValue().auth.isAgreementsConfirmed;
    }

    /**
     * Returns if the refresh token exists and it's not expired.
     */
    public isRefreshTokenValid(): boolean {
        const refreshTokenModel = this.getValue().auth.refreshTokenModel;
        if (!refreshTokenModel || !refreshTokenModel.refreshToken || !refreshTokenModel.refreshToken.length) {
            Sentry.addBreadcrumb({
                category: "auth",
                message: "The refresh token is not valid",
                level: Severity.Info
            })
            return false;
        }

        const now = new Date().getTime();
        var valid = now < new Date(refreshTokenModel.expirationDate).getTime();
        Sentry.addBreadcrumb({
            category: "auth",
            message: "The refresh token is valid: " + valid,
            level: Severity.Info
        })
        return valid
    }

    /**
     * Returns true when the token will expire within the number of hours
     */
    public tokenExpiresWithin(hours: number): boolean {
        const token = this.getValue().auth.token;
        if (!token || !token.length) {
            //There is no token, so it's... expired.
            return true;
        }
        const now = new Date();
        const expiration = new Date(this.getValue().auth.expires);
        const hoursDiff = moment(expiration).diff(now, "hours");
        return hoursDiff < hours
    }
    /**
     * Returns true when the auth token or the refresh token is valid
     */
    public isAuthTokenValid(): boolean {
        const token = this.getValue().auth.token;
        if (!token || !token.length) {
            return false;
        }

        const now = new Date().getTime();
        return now < this.getValue().auth.expires
    }

    /**
     * Returns true when the auth token or the refresh token is valid
     */
    public isLoggedIn(): boolean {
        return this.isAuthTokenValid() || this.isRefreshTokenValid();
    }

    public isUnspecifiedUser(): boolean {
        return this.getValue().auth.type === UserType.Unspecified;
    }

    /**
     * Returns user token
     */
    public getToken(): string {
        return this.getValue().auth.token;
    }

    /**
     * Returns user external auth
     */
    public getExternalAuth(): ExternalAuthModel {
        return this.getValue().auth.externalAuthentication;
    }

    /**
     * Returns target location id
     */
    public getTargetLocationId(): number {
        return this.getValue().auth.targetLocationId;
    }

    public getId(): number {
        return this.getValue().auth.id;
    }

    public getEmployeeId(): number {
        return this.getValue().auth.employeeId;
    }

    public getPatientId(): number {
        return this.getValue().auth.patientId;
    }

    public getPatientAgreementsChanged(): boolean {
        return this.getValue().auth.isAgreementsChanged;
    }

    public getPhoneNumber(): string {
        return this.getValue().auth.phoneNumber;
    }

    public getSubscriptionStatus(): number {
        return this.getValue().auth.subscriptionStatus;
    }

    public isSubscriptionStatusActive(): boolean {
        return this.getSubscriptionStatus() === SubscriptionStatus.Active;
    }

    public hasPlan(): boolean {
        if (this.isEmployeeUser()) {
            return true;
        }

        return !!this.getValue().auth.plan;
    }

    public getPlan(): string | null {
        if (this.isEmployeeUser()) {
            return null;
        }

        return this.getValue().auth.plan;
    }

    public isEmployeeUser(): boolean {
        return this.getValue().auth.type === UserType.Employee
    }

    public isPatientUser(): boolean {
        return this.getValue().auth.type === UserType.Patient
    }

    public getEmployeeType(): EmployeeType | null {
        switch (this.getValue().auth.roleId) {
            case roles.portalAdmin:
            case roles.licensingProvider:
            case roles.provider:
                return EmployeeType.Provider;

            case roles.staff:
            case roles.careCoordinator:
            case roles.admin:
                return EmployeeType.Staff;

            case roles.coach:
            case roles.fellow:
                return EmployeeType.HealthCoach;

            case roles.registrationManager:
                return EmployeeType.RegistrationManager;
            default:
                return null;
        }
    }

    public hasPermission(permission: PermissionType): boolean {
        return this.getPermissions().includes(permission);
    }

    public isDefaultPractice(): boolean {
        return this.getPracticeId().toString() === PRACTICE_ID;
    }

    public isWildHealthPractice(): boolean {
        return this.getCurrentPracticeId() === Practice.WildHealth;
    }

    public isLicensingPractice(): boolean {
        return !(this.getCurrentPracticeId() === Practice.WildHealth);
    }

    public getCurrentPracticeId(): number {
        return this.isLoggedIn()
            ? this.getValue().auth.practiceId
            : Number(PRACTICE_ID);
    }

    /**
 * Returns is premium 
 */
    public getIsPremium(): boolean {
        return this.getValue().auth.isPremium;
    }
}

export const authQuery = new AuthQuery(authStore);
