import Axios from 'axios-observable';
import {Observable} from "rxjs";
import {authHeader} from '../../common/helpers/auth-header';
import {snackService} from '../../common/snack/state';
import {notificationsService} from '../../notifications/services/notifications.service';
import {Identify} from '../../tracking/tracker'
import {
    AuthModel, ExternalAuthorizationProviderModel,
    ForgotPasswordModel,
    LoginModel, LoginOnBehalfModel, LoginWithAuth2Model, RefreshTokenModel,
    RestorePasswordModel, SendProfileVerificationModel,
    VerifyProfileModel
} from '../models/auth.models';
import {AuthStore, authStore} from '../stores/auth';
import {ResetPasswordModel, SetPasswordModel} from "../../common/models/user.models";
import {UserType} from '../models/auth.enums';
import {resetStores} from "@datorama/akita";
import {applicationStore} from "../../../stores/applicationStore";

/**
 * Provides method for working with auth and identity
 */
export class AuthService {
    private url = `${process.env.REACT_APP_API_URL}Auth/`;
    private requiresExternalLogoutVal = false;

    constructor(private authStore: AuthStore) {
    }

    /**
     * Logins user
     * @param model
     */
    public login(model: LoginModel): Observable<AuthModel> {
        return new Observable(observer => {
            Axios.post<AuthModel>(`${this.url}Authenticate`, model)
                .pipe()
                .subscribe(
                    (response) => {
                        this.authorize(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Logins user with auth2
     * @param model
     */
    public loginWithAuth2(model: LoginWithAuth2Model): Observable<AuthModel> {
        return new Observable(observer => {
            Axios.post<AuthModel>(`${this.url}Authenticate/Auth2`, model)
                .pipe()
                .subscribe(
                    (response) => {

                        this.authorize(response.data);

                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Login on behalf
     * @param model
     */
    public loginOnBehalf(model: LoginOnBehalfModel): Observable<AuthModel> {
        const config = {headers: authHeader()};

        return new Observable(observer => {
            Axios.post(`${this.url}Authenticate/OnBehalf`, model, config)
                .pipe()
                .subscribe(
                    (response) => {

                        this.authorize(response.data);

                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Re login user
     */
    public reLogin(locationId?: number): Observable<AuthModel> {
        const config = {headers: authHeader()};

        return new Observable(observer => {
            Axios.post(`${this.url}Reauthenticate?locationId=${locationId ?? ''}`, {}, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.authorize(response.data);

                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Refresh Token
     *  */
    public refreshToken(refreshTokenModel: RefreshTokenModel): Observable<AuthModel> {
        return new Observable(observer => {
            Axios.put(`${this.url}RefreshToken`, refreshTokenModel)
                .pipe()
                .subscribe(
                    (response) => {
                        this.authorize(response.data);
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Logouts user
     */
    public logout(): void {
        notificationsService.disconnect();

        resetStores();
    }

    public authorize(model: AuthModel): void {
        if (model.identifyPayload !== undefined) {
            Identify(model.identifyPayload.trackingIdentifier, model.identifyPayload);
        }

        this.authStore.update({auth: model});

        if (this.authStore.getValue().auth.type !== UserType.Unspecified) {
            notificationsService.connect();
        }
    }

    /**
     * This returns the value of the request and then is set to false, this is intended to only return the value a single time
     * @returns whether an external logout needs to occur
     */
    public requiresExternalLogout(): boolean {
        const retVal = this.requiresExternalLogoutVal;
        this.requiresExternalLogoutVal = false;
        return retVal;
    }

    /**
     * Sends forgot password link
     * @param model
     */
    public forgotPassword(model: ForgotPasswordModel): Observable<any> {
        return new Observable(observer => {
            Axios.post(`${this.url}ForgotPassword`, model)
                .pipe()
                .subscribe(
                    (response) => {
                        snackService.success('A link was sent to your email');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Restores password
     * @param model
     */
    public restorePassword(model: RestorePasswordModel): Observable<any> {
        return new Observable(observer => {
            Axios.post(`${this.url}RestorePassword`, model)
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Reset user password
     * @param model
     */
    public resetPassword(model: ResetPasswordModel): Observable<any> {
        const config = {
            headers: authHeader()
        };

        return new Observable(observer => {
            Axios.put(`${this.url}ResetPassword`, model, config)
                .pipe()
                .subscribe(
                    (response) => {
                        snackService.success('Your password has been updated.');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Set user password
     * @param model
     */
    public setUpPassword(model: SetPasswordModel): Observable<any> {
        const config = {
            headers: authHeader()
        };

        return new Observable(observer => {
            Axios.post(`${this.url}SetUpPassword`, model, config)
                .pipe()
                .subscribe(
                    (response) => {
                        snackService.success('Your password set up.');
                        this.authorize(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Set user password
     * @param model
     */
    public setUpPreauthorizePassword(model: SetPasswordModel): Observable<any> {
        const config = {
            headers: authHeader()
        };

        return new Observable(observer => {
            Axios.post(`${this.url}SetUpPassword/Preauthorize`, model, config)
                .pipe()
                .subscribe(
                    (response) => {
                        snackService.success('Your password set up.');
                        this.authorize(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Verification Send
     */
    public sendVerification(model: SendProfileVerificationModel): Observable<any> {
        const config = {headers: authHeader()};
        return new Observable(observer => {
            Axios.post(`${this.url}Verification/Send`, model, config)
                .pipe()
                .subscribe(
                    () => {
                        snackService.success("Verification code sent successfully!");
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Verify profile
     */
    public verify(model: VerifyProfileModel): Observable<any> {
        const config = {headers: authHeader()};
        return new Observable(observer => {
            Axios.post(`${this.url}Verification/Submit`, model, config)
                .pipe()
                .subscribe(
                    () => {
                        const auth = this.authStore.getValue().auth;
                        auth.isVerified = true;
                        this.authStore.update({auth: auth});
                        snackService.success("Account verified successfully!");
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Checks if email exists
     */
    public isEmailExist(email: string): Observable<any> {
        return new Observable(observer => {
            Axios.get(`${this.url}EmailExist/${email}`)
                .pipe()
                .subscribe(
                    response => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public isEmailExistForPatient(email: string): Observable<any> {
        const config = {headers: authHeader()};
        const model = {email};
        return new Observable(observer => {
            Axios.post(`${this.url}EmailExistForPatient`, model, config)
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public getExternalAuthorizationProviders(): Observable<ExternalAuthorizationProviderModel[]> {
        return new Observable(observer => {
            Axios.get<ExternalAuthorizationProviderModel[]>(`${this.url}Providers`, null)
                .pipe()
                .subscribe(
                    (response) => {
                        applicationStore.update({authorizationProviders: response.data})
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public loginInRxNT(patientId: number): Observable<string> {
        const config = {headers: authHeader()};

        return new Observable(observer => {
            Axios.get<string>(`${process.env.REACT_APP_API_URL}Prescriptions/${patientId}`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }
}

export const authService = new AuthService(authStore);
