import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { onEmit } from "../../../common/helpers/on-emit";
import { ConversationModelBaseView, EmployeeConversationModel } from "../../models/conversation.models";
import { conversationsViewService } from "../../services/conversationsView.service";
import { patientConversationsQuery } from "../../stores/patientConversationsStore/patientConversations.query";

interface InternalChatComponentState {
    messages: ConversationModelBaseView[];
    isLoadingMessages: boolean;
    scrollToIndex: number;
}

export function useFacade(targetConversation?: EmployeeConversationModel): [InternalChatComponentState] {
    const [state, setState] = useState({
        scrollToIndex: -1,
        messages: [],
        isLoadingMessages: true,
    } as InternalChatComponentState);


    useEffect(() => {
        setState(state => ({ ...state, isLoadingMessages: true }))
        const subscriptions: Subscription[] = [

            onEmit<any>(patientConversationsQuery.targetInternalConversation$, messages => {
                setState(state => ({ ...state, messages: messages }))
            }),
        ];

        if (targetConversation && targetConversation.vendorExternalId) {
            conversationsViewService.getAllMessagesInternal(targetConversation.vendorExternalId).subscribe(
                () => setState(state => ({ ...state, isLoadingMessages: false })),
                () => setState(state => ({ ...state, isLoadingMessages: false }))
            );
        } else {
            setState(state => ({ ...state, isLoadingMessages: false }))
        }

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [targetConversation]);


    return [state];
}
