import { Box, Button, Grid, Tab, Tabs } from "@material-ui/core";
import React, { Fragment } from "react";
import { UpdateAddressForm } from "../../../../../common/components/updateAddressForm/UpdateAddressForm";
import { WildHealthButton } from "../../../../../common/components/wildHealthButton/WildHealthButton";
import WildHealthLinearProgress from "../../../../../common/components/WildHealthLinearProgress";
import { useFacade } from "./addressInfoWidgetComponent.hooks";
import { useStyles } from "./addressInfoWidgetComponent.style";
import EditIcon from '@material-ui/icons/Edit'
export interface AddressInfoWidgetComponentProps {
  patientId: number | null;
}

export const AddressInfoWidgetComponent: React.FC<
  AddressInfoWidgetComponentProps
> = (props: AddressInfoWidgetComponentProps) => {
  const [
    {
      isLoading,
      tabIndex,
      isEdit,
      patient,
      patientDraft,
      errors,
    },
    handleChangeTab,
    handleChanges,
    handleDiscardChanges,
    handleSubmit,
    handleEditModeToggle
  ] = useFacade(props.patientId);

  const classes = useStyles();

  const renderContent = () => {
    if (isLoading) {
      return <WildHealthLinearProgress />;
    }

    return (
      <Fragment key="address_info_new">
        <Box padding={3}>
          <Tabs
            value={tabIndex}
            onChange={handleChangeTab}
            classes={{ indicator: classes.borderTitle }}
          >
            <Tab label="Billing address" value="0" className={classes.tabTitle} />
            <Tab
              label="Shipping address"
              value="1"
              className={classes.tabTitle}
            />
          </Tabs>
          {tabIndex == "0" && (
            <Box mt={3}>
              <Grid item xs={12} md={12} lg={12}>
                <Box>
                  <Box display="flex" justifyContent="flex-end">
                    <Button className={classes.button} onClick={() => { handleEditModeToggle() }}>
                      <EditIcon fontSize='small' />
                      <Box className={classes.buttonText}>Edit</Box>
                    </Button>
                  </Box>  
                  <UpdateAddressForm
                    classStyle={classes.textGray}
                    address={
                      isEdit
                        ? patientDraft.billingAddress
                        : patient.billingAddress
                    }
                    prefix={"billingAddress."}
                    errors={errors}
                    handleChanges={isEdit ? handleChanges : null}
                  />
                </Box>
              </Grid>
              <Box>
                {isEdit && (
                    <Box display="flex" pt={5}>
                      <Box>
                        <WildHealthButton
                          id="patient-address-information-discard"
                          color="tertiary"
                          onClick={() => handleDiscardChanges()}
                        >
                          Discard
                        </WildHealthButton>
                      </Box>
                      <Box ml={2}>
                        <WildHealthButton
                          id="patient-address-information-save"
                          onClick={() => handleSubmit()}
                        >
                          Save Changes
                        </WildHealthButton>
                      </Box>
                    </Box>
                  )}
              </Box>
            </Box>
          )}
          {tabIndex === "1" && (
            <Box mt={3}>
              <Grid item xs={12} md={12} lg={12}>
                <Box>
                  <Box display="flex" justifyContent="flex-end">
                    <Button className={classes.button} onClick={() => { handleEditModeToggle() }}>
                      <EditIcon fontSize='small' />
                      <Box className={classes.buttonText}>Edit</Box>
                    </Button>
                  </Box>  
                  <UpdateAddressForm
                   classStyle={classes.textGray}
                    address={
                      isEdit
                        ? patientDraft.shippingAddress
                        : patient.shippingAddress
                    }
                    prefix={"shippingAddress."}
                    errors={errors}
                    handleChanges={isEdit ? handleChanges : null}
                  />
                </Box>
              </Grid>
              <Box>
                {isEdit  && (
                    <Box display="flex" pt={5}>
                      <Box>
                        <WildHealthButton
                          id="patient-address-information-discard"
                          color="tertiary"
                          onClick={() => handleDiscardChanges()}
                        >
                          Discard
                        </WildHealthButton>
                      </Box>
                      <Box ml={2}>
                        <WildHealthButton
                          id="patient-address-information-save"
                          onClick={() => handleSubmit()}
                        >
                          Save Changes
                        </WildHealthButton>
                      </Box>
                    </Box>
                  )}
              </Box>
            </Box>
          )}
          </Box>
      </Fragment>
    );
  };

  return (
    <Box>
      <Box>{renderContent()}</Box>
    </Box>
  );
};
