import { createStyles, makeStyles } from "@material-ui/core/styles";
import { colors } from "../../../../../common/constants/colors";

export const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontSize: 16,
      lineHeight: '22px',
      fontWeight: 500,
      color: colors.black,
    },
    appointmentName: {
      fontWeight: 500,
      color: colors.gray1,
    },
    employeeName: {
      fontWeight: 400,
      lineWeight: 19,
      color: colors.gray1,
    },
    patientBox: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 25,
    },
    patientName: {
      fontSize: 18,
      fontWeight: 500,
      lineWeight: 21,
      color: colors.black,
    },
    avatar: {
      width: 67,
      height: 67
    },
    time: {
      fontSize: 16,
      fontWeight: 400,
      color: colors.black,
    },
    date: {
      fontWeight: 400,
      color: colors.black,
    },
    dateIcon: {
      fill: colors.gray2,
    },
    timeIcon: {
      fill: colors.gray2,
    },
    dateTime: {
      display: 'flex',
      alignItems: 'center',
    },
    divider: {
      background: colors.separators,
    },
    indicator: {
      background: "none",
    },
    tabsWrapper: {
      height: 56,
      alignItems: 'center',
      background: "#F5F6F6",
      color: "#7C8A9D",
      fontFamily: 'Be Vietnam Pro',
      fontStyle: 'normal',
      "& div.MuiTabs-scroller": {
        "& .MuiTabs-flexContainer": {
          justifyContent: "space-evenly"
        }
      }
    },
    tabRoot: {
      width: `50%`,
      textTransform: 'none',
      fontSize: 14,
      fontFamily: 'Be Vietnam Pro'
    },
    selectedTab: {
      display: "flex",
      background: "white",
      color: "#1F72A2",
      boxShadow: '0px 6px 8px rgba(168, 173, 198, 0.12)',
      width: '45%'
    },
    historyLinkText: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "16px",
      marginRight: 11,
    },
    historyLink: {
      color: colors.main,
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
    },
  })
);


