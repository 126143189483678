import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { questionnaireService } from "../../services/questionnaire.service";
import {navigationService} from "../../../../services/navigation.service";
import {useHistory} from "react-router-dom";

export function useFacade(questionnaireId: number): boolean {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            questionnaireService.startById(questionnaireId)
                .subscribe(
                    result => navigationService.toMyQuestionnaire(history, result.id),
                    error =>  setIsLoading(false)
                )
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    };

    useEffect(useEffectCB, []);

    return isLoading;
}