import {Subscription} from 'rxjs';
import {useEffect, useState} from 'react';
import {paymentPlansService} from "../../services/paymentPlans.service";
import {paymentPlansQuery} from "../../stores/paymentPlansStore";
import {PaymentPlanModel} from "../../models/paymentPlan.models";
import {onEmit} from "../../../common/helpers/on-emit";
import {PaymentPeriodModel} from "../../models/paymentPeriod.models";
import { PageSliderNavigationContext } from '../../../common/models/navigation.models';

interface SelectPaymentPeriodComponentState {
    selectedPlan: PaymentPlanModel;
    selectedPeriod: PaymentPeriodModel;
}

export function useFacade(navigationContext: PageSliderNavigationContext): [SelectPaymentPeriodComponentState, Function] {
    const [state, setState] = useState({
        selectedPlan: paymentPlansQuery.getSelectedPlan(),
        selectedPeriod: paymentPlansQuery.getSelectedPeriod(),
    } as SelectPaymentPeriodComponentState);

    const selectPeriod = (period: PaymentPeriodModel) => {
        paymentPlansService.selectPeriod(period);
        navigationContext.goNext();
    };

    /**
     * Load all payment plans
     * Manage subscriptions with auto-cleanup
     */
    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<PaymentPlanModel>(paymentPlansQuery.selectedPlan$, selectedPlan => {
                setState(state => ({
                    ...state,
                    selectedPlan
                }));

                if (selectedPlan && selectedPlan.periods.length === 1) {
                    selectPeriod(selectedPlan.periods[0]);
                }
            })
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [state, selectPeriod]
}
