import {useEffect, useState} from "react";
import {useHistory} from "react-router";
import {navigationService} from "../../../../services/navigation.service";
import {LoginOnBehalfModel} from "../../models/auth.models";
import {authService} from "../../services/auth.service";
import {Subscription} from "rxjs";

interface LoginOnBehalfPageState {
    isLoading: boolean;
}

/**
 * Custom Hook to manage a view Model for Create shortcuts component
 */
export function useFacade(businessId: number, practiceId: number): [LoginOnBehalfPageState] {
    const history = useHistory();
    const [state, setState] = useState({
        isLoading: true
    } as LoginOnBehalfPageState);

    useEffect(() => {
        const model: LoginOnBehalfModel = {
            businessId: businessId,
            practiceId: practiceId
        };

        const subscriptions: Subscription[] = [
            authService.loginOnBehalf(model).subscribe(
                () => {
                    setState(state => ({...state, isLoading: false}));
                    navigationService.toDashboard(history);
                    window.location.reload();
                },
                () => {
                    navigationService.toDashboard(history);
                }
            )
        ];



        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [history, businessId, practiceId]);

    return [state,];
}
