import { ChangeEvent, useState } from "react";
import { IErrorState } from "../../../common/validation/error-state";
import { cancelSubscriptionComponentValidator } from "./cancelSubscriptionComponent.validator";
import {CancellationReasonType} from "../../../payment/models/subscription.models";

interface CancelSubscriptionComponentState extends IErrorState{
    reasonType: CancellationReasonType;
    reason: string;
    date: Date;
}

export function useFacade(handleSubmit: Function, handleClose: Function): [
    CancelSubscriptionComponentState,
    (reasonType: CancellationReasonType) => void,
    (event: ChangeEvent<HTMLTextAreaElement>) => void,
    (date: Date) => void,
    () => void,
    () => void,
] {
    const [state, setState] = useState({
        reasonType: CancellationReasonType.TheCost,
        reason: '',
        date: new Date(),
        errors: {}
    } as CancelSubscriptionComponentState);

    const handleCancel = () => {
        setState(state => ({...state, reason: '', date: new Date(), errors: {}}));
        handleClose();
    }

    const handleReasonTypeChange = (reasonType: any) => {
        setState(state => ({...state, reasonType: reasonType}));
    }

    const handleReasonChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const value = event.currentTarget.value;
        cancelSubscriptionComponentValidator.validateAndSetState(state, setState, 'reason', value);
        setState(state => ({...state, reason: value}));
    }

    const handleDateChange = (date: Date) => {
        setState(state => ({...state, date: date}));
    }

    const handleConfirm = () => {
        cancelSubscriptionComponentValidator.validateObjectAndSetState(state, setState, state);

        if (cancelSubscriptionComponentValidator.stateIsValid(state)) {
            const isToday = state.date.toDateString() === new Date().toDateString();
            handleSubmit(state.reasonType, state.reason, isToday ? null : state.date);

            setState(state => ({
                ...state,
                reasonType: CancellationReasonType.TheCost,
                reason: '',
                date: new Date(),
                errors: {}
            }));
        }
    }

    return [
        state,
        handleReasonTypeChange,
        handleReasonChange,
        handleDateChange,
        handleCancel,
        handleConfirm
    ];
}
