import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { onEmit } from "../../../common/helpers/on-emit";
import {ConversationType, EmployeeConversationModel, llmConversationTypes} from "../../models/conversation.models";
import { conversationsService } from "../../services/conversations.service";
import { employeeConversationsQuery } from "../../stores/employeeConversationsStore/employeeConversations.query";
import { notificationsQuery, notificationsStore } from "../../../notifications/stores/notifications";
import { NotificationType } from "../../../notifications/models/notifications.models";
import { notificationsService } from "../../../notifications/services/notifications.service";
import { EmployeeConversationsTabs } from "../../stores/employeeConversationsStore/employeeConversations.store";
import { ConversationState } from "../../models/conversationState.models";
import { MessageSettingsModel } from "../../models/message.models";
import { employeeService } from "../../../employee/services/employees.service";

interface EmployeeConversationsComponentState {
    targetConversation: EmployeeConversationModel;
    forwardTurnOn: boolean;
}

export function useFacade(): [
    EmployeeConversationsComponentState,
    () => void,
    () => void
] {
    const [state, setState] = useState({
        targetConversation: null,
        forwardTurnOn: false
    } as EmployeeConversationsComponentState);

    const handleGoBack = () => {
        resetTargetConversation();
        conversationsService.selectConversation(null);
    }

    const removeNotifications = () => {
        const notifications = notificationsQuery.getNotificationsByTypes([
            NotificationType.UnreadMessagesCount,
            NotificationType.NewMessage,
            NotificationType.UnreadMessagesManual
        ]);

        if (notifications?.length) {
            const selectedTab = employeeConversationsQuery.getTab();
            const ignoreTypes = selectedTab === EmployeeConversationsTabs.Internal
                ? [ConversationType.HealthCare, ConversationType.Support]
                : [ConversationType.Internal];

            const ignoreTypeNames = selectedTab === EmployeeConversationsTabs.Internal
                ? ['HealthCare', 'Support']
                : ['Internal'];

            const skipNotifications = notifications
                .filter(x => x.type === NotificationType.NewMessage)
                .filter(x => ignoreTypeNames.includes(JSON.parse(x.linkData)?.conversationType))
                .map(x => x.id);

            const notificationsToDelete = notifications.filter(x => !skipNotifications.includes(x.id));
            if (notificationsToDelete.length) {
                // Check if messages in corresponding conversations all read
                const conversations = employeeConversationsQuery.getConversations();
                if (conversations.length) {
                    const unreadConversations = conversations
                        .filter(x => !ignoreTypes.includes(x.type))
                        .filter(x => x.state === ConversationState.Active)
                        .filter(x => x.proxy !== undefined)
                        .filter(x => x.unreadMessages > 0 || x.unreadMessages === undefined)

                    if (!unreadConversations.length) {
                        notificationsStore.deleteNotifications(notificationsToDelete);
                        notificationsService.deleteBulk(notificationsToDelete);
                    }
                }
            }
        }
    }

    const resetTargetConversation = () => {
        setState(state => ({
            ...state,
            targetConversation: null
        }));
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<EmployeeConversationModel>(employeeConversationsQuery.targetConversation$, targetConversation => {
                if (targetConversation) {

                    // prevent default conversation components from loading llm conversations
                    if (llmConversationTypes.includes(targetConversation.type)) {
                        return;
                    }

                    setState(state => ({
                        ...state,
                        targetConversation: targetConversation
                    }));
                }
            }),
            onEmit<EmployeeConversationModel[]>(employeeConversationsQuery.conversations$, () => {
                if (employeeConversationsQuery.getTargetConversation()) {
                    removeNotifications();
                }
            }),
            onEmit<MessageSettingsModel>(employeeConversationsQuery.messageSettings$, setting => {
                setState(state => ({ ...state, forwardTurnOn: setting.forwardEmployeeEnabled }));
            }),
        ]

        conversationsService.getConversationsSettings().subscribe();

        employeeService.getActive().subscribe();

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [])

    return [state, handleGoBack, resetTargetConversation];
}
