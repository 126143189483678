import {WildHealthButton} from "../../../common/components/wildHealthButton/WildHealthButton";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import commonUseStyles from '../../../common/styles/common.styles';
import {Box, Button, FormControl, FormHelperText, Grid, MenuItem, Select, TextField} from "@material-ui/core";
import {useStyles} from "./employeeAccountComponent.styles";
import {useFacade} from "./employeeAccountComponent.hooks";
import emptyPhoto from "@img/placeholders/EmptyPhoto.png";
import React from "react";
import clsx from "clsx";
import {WildHealthDatePicker} from "../../../common/components/WildHealthDatePicker";
import {Gender} from "../../../common/models/user.models";
import {phoneNumberInput} from "../../../common/components/PhoneNumberInput";
import moment from "moment";
import {authQuery} from "../../../auth/stores/auth";
import {EmployeeType} from "../../../employee/models/employee.enums";
import {OptionalComponent} from "../../../common/components/OptionalComponent";
import {Practice} from "../../../auth/models/auth.enums";
import {Autocomplete} from "@material-ui/lab";
import {addressQuery} from "../../stores/addressStore";

export const EmployeeAccountComponent: React.FC = () => {
    const [
        {
            isLoading,
            isPhotoUrlLoading,
            isStateLoading,
            isSubmitted,
            profile,
            photoUrl,
            errors
        },
        handleStatesChange,
        handlePhotoUpload,
        handleChanges,
        handleSubmit,
        deletePhoto
    ] = useFacade();

    const commonClasses = commonUseStyles();
    const classes = useStyles();

    if (isLoading || isPhotoUrlLoading || isStateLoading) {
        return (
            <Box p={5}>
                <WildHealthLinearProgress />
            </Box>
        )
    }

    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
        >
            <Grid
                item
                md={6}
                sm={12}
            >
                <OptionalComponent practiceId={Practice.WildHealth} children={
                    <Box mt={5}>
                        <Box className={commonClasses.mediumTitle} mb={1}>
                            Profile Photo
                        </Box>
                        <Box className={classes.photoContainer}>
                            <Box className={clsx(classes.photo, { [classes.photoBorder]: !photoUrl })}>
                                <img className={classes.photoItem} style={{ width: '100%' }}
                                     src={photoUrl ? photoUrl : emptyPhoto}
                                     alt="img" />
                            </Box>
                            {
                                !isSubmitted &&
                                <Box className={classes.photoContainer}>
                                    <Box className={classes.photoButton}>
                                        <Button
                                            className={clsx(classes.photoUploadButton, commonClasses.whButton)}
                                            component="label"
                                        >
                                            Upload
                                            <input type="file" style={{ display: "none" }}
                                                   onChange={(e) => handlePhotoUpload(e)}
                                                   accept={'das'} />
                                        </Button>
                                    </Box>
                                    <Box className={classes.photoButton}>
                                        <WildHealthButton
                                            id="employee-account-delete-photo"
                                            loading={isSubmitted}
                                            color='tertiary'
                                            onClick={() => deletePhoto()}
                                        >
                                            Delete
                                        </WildHealthButton>
                                    </Box>
                                </Box>
                            }
                        </Box>
                    </Box>
                }/>

                <Box mt={5}>
                    <Box className={commonClasses.mediumTitle}>
                        Personal Information
                    </Box>
                    <Box mt={3}>
                        <form autoComplete="off">
                            <Box width={1} display="flex" justifyContent="space-between">
                                <Box pr={1} width={1}>
                                    <Box pb={1}>
                                        <span className={commonClasses.inputLabel}>
                                            First Name*
                                        </span>
                                    </Box>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        required
                                        disabled={!handleChanges}
                                        id={`firstName`}
                                        variant="outlined"
                                        value={profile?.firstName}
                                        InputProps={{className: 'input'}}
                                        error={!!errors[`firstName`]}
                                        helperText={errors[`firstName`]}
                                        onChange={(v) => {
                                            handleChanges(v.target.id, v.target.value)
                                        }}
                                    />
                                </Box>
                                <Box pl={1} width={1}>
                                    <Box pb={1}>
                                        <span className={commonClasses.inputLabel}>
                                            Last Name*
                                        </span>
                                    </Box>
                                    <TextField
                                        required
                                        fullWidth
                                        size="small"
                                        disabled={!handleChanges}
                                        id={`lastName`}
                                        variant="outlined"
                                        value={profile?.lastName}
                                        InputProps={{className: 'input'}}
                                        error={!!errors[`lastName`]}
                                        helperText={errors[`lastName`]}
                                        onChange={(v) => {
                                            handleChanges(v.target.id, v.target.value)
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box mt={2} width={1} display="flex" justifyContent="space-between">
                                <Box pr={1} width={0.5}>
                                    <Box pb={1}>
                                        <span className={commonClasses.inputLabel}>
                                            Date of Birth
                                        </span>
                                    </Box>
                                    <WildHealthDatePicker
                                        id={`birthday`}
                                        disableFuture
                                        fullWidth
                                        size="small"
                                        disabled={!handleChanges}
                                        placeholder="yyyy-mm-dd"
                                        openTo="year"
                                        format="MM/DD/yyyy"
                                        maxDate={moment(new Date()).add(-18, 'years')}
                                        value={profile?.birthday
                                            ? moment(profile?.birthday).format('YYYY-MM-DD')
                                            : null
                                        }
                                        error={!!errors[`birthday`]}
                                        helperText={errors[`birthday`]}
                                        inputVariant="outlined"
                                        views={["year", "month", "date"]}
                                        onChange={(momentDate) =>
                                            handleChanges(`birthday`, momentDate.format("YYYY-MM-DD")
                                            )}
                                    />
                                </Box>
                                <Box pl={1} width={0.5}>
                                    <Box pb={1}>
                                        <span className={commonClasses.inputLabel}>
                                            Biological Gender
                                        </span>
                                    </Box>
                                    <FormControl
                                        variant="outlined"
                                        error={!!errors[`gender`]}
                                        color='primary'
                                        size="small" fullWidth>
                                        <Select
                                            style={{background: '#FFF'}}
                                            id={`gender`}
                                            value={profile?.gender}
                                            error={!!errors[`gender`]}
                                            inputProps={{
                                                name: 'Biological Gender',
                                                id: 'gender-label',
                                            }}
                                            onChange={(v) => handleChanges(`gender`, v.target.value)}>
                                            <MenuItem value={Gender.Male}>{Gender[Gender.Male]}</MenuItem>
                                            <MenuItem value={Gender.Female}>{Gender[Gender.Female]}</MenuItem>
                                        </Select>
                                        <FormHelperText>{errors[`gender`]}</FormHelperText>
                                    </FormControl>
                                </Box>
                            </Box>
                            <Box width={1} mt={2} display="flex" justifyContent="space-between">
                                <Box pr={1} width={0.5}>
                                    <Box pb={1}>
                                        <span className={commonClasses.inputLabel}>
                                            Phone Number*
                                        </span>
                                    </Box>
                                    <TextField
                                        fullWidth
                                        required
                                        size="small"
                                        disabled={!handleChanges}
                                        id={`phoneNumber`}
                                        variant="outlined"
                                        value={profile?.phoneNumber}
                                        error={!!errors[`phoneNumber`]}
                                        helperText={errors[`phoneNumber`]}
                                        InputProps={{
                                            className: 'input',
                                            inputComponent: phoneNumberInput,
                                        }}
                                        onChange={(v) => {
                                            handleChanges(v.target.id, v.target.value)
                                        }}
                                    />
                                </Box>
                                <Box pl={1} width={0.5}>
                                    <Box pb={1}>
                                        <span className={commonClasses.inputLabel}>
                                            Email*
                                        </span>
                                    </Box>
                                    <TextField
                                        required
                                        fullWidth
                                        size="small"
                                        id={`email`}
                                        variant="outlined"
                                        value={profile?.email}
                                        InputProps={{className: 'input'}}
                                        error={!!errors[`email`]}
                                        helperText={errors[`email`]}
                                        onChange={(v) => {
                                            handleChanges(v.target.id, v.target.value)
                                        }}
                                    />
                                </Box>
                            </Box>
                        </form>
                    </Box>
                    <Box width={1} mt={2} display="flex" justifyContent="space-between">
                        {
                            authQuery.getEmployeeType() === EmployeeType.Provider &&
                            <OptionalComponent practiceId={Practice.WildHealth} children={
                                <Box pr={1} width={0.5}>
                                    <Box pb={1}>
                                <span className={commonClasses.inputLabel}>
                                    State Licenses ({profile?.stateLicenses.length} total licenses)
                                </span>
                                    </Box>
                                    <Autocomplete
                                        size='small'
                                        disableClearable
                                        multiple
                                        options={addressQuery.getStates().filter(x => !profile?.stateLicenses.some(t => t.id === x.id))}
                                        getOptionLabel={x => x.name}
                                        onChange={(e, values) => handleStatesChange(values)}
                                        value={profile?.stateLicenses}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                InputProps={{ ...params.InputProps, type: 'search' }}
                                            />
                                        )}
                                    />
                                </Box>
                            }/>
                        }
                        <Box width={0.488}>
                            <Box pb={1}>
                                <span className={commonClasses.inputLabel}>
                                    Credentials
                                </span>
                            </Box>
                            <TextField
                                fullWidth
                                size="small"
                                disabled={!handleChanges}
                                id="credentials"
                                variant="outlined"
                                value={profile?.credentials}
                                error={!!errors['credentials']}
                                helperText={errors['credentials']}
                                onChange={(v) => {
                                    handleChanges(v.target.id, v.target.value)
                                }}
                            />
                        </Box>
                    </Box>
                    <Box mt={2}>
                        <Box width={1} display="flex" justifyContent="space-between">
                            <Box pr={1} width={1}>
                                <Box pb={1}>
                                <span className={commonClasses.inputLabel}>
                                    RxNT Login
                                </span>
                                </Box>
                                <TextField
                                    fullWidth
                                    size="small"
                                    disabled={!handleChanges}
                                    id={`rxntUserName`}
                                    variant="outlined"
                                    autoComplete="false"
                                    value={profile?.rxntUserName}
                                    InputProps={{className: 'input'}}
                                    error={!!errors[`rxntUserName`]}
                                    helperText={errors[`rxntUserName`]}
                                    onChange={(v) => {
                                        handleChanges(v.target.id, v.target.value)
                                    }}
                                />
                            </Box>
                            <Box pl={1} width={1}>
                                <Box pb={1}>
                                <span className={commonClasses.inputLabel}>
                                    RxNT Password
                                </span>
                                </Box>
                                <TextField
                                    required
                                    fullWidth
                                    size="small"
                                    disabled={!handleChanges}
                                    id={`rxntPassword`}
                                    variant="outlined"
                                    type="password"
                                    autoComplete="false"
                                    value={profile?.rxntPassword}
                                    InputProps={{className: 'input'}}
                                    error={!!errors[`rxntPassword`]}
                                    helperText={errors[`rxntPassword`]}
                                    onChange={(v) => {
                                        handleChanges(v.target.id, v.target.value)
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                    {
                        authQuery.getEmployeeType() === EmployeeType.Provider &&
                        <OptionalComponent practiceId={Practice.WildHealth} children={
                            <Box width={1} mt={2}>
                                <Box pb={1}>
                            <span className={commonClasses.inputLabel}>
                                Your Bio
                            </span>
                                </Box>
                                <TextField
                                    fullWidth
                                    multiline
                                    size="small"
                                    rows={3}
                                    disabled={!handleChanges}
                                    id="bio"
                                    variant="outlined"
                                    value={profile?.bio}
                                    error={!!errors['bio']}
                                    helperText={errors['bio']}
                                    onChange={(v) => {
                                        handleChanges(v.target.id, v.target.value)
                                    }}
                                />
                            </Box>
                        }/>
                    }
                    <Box pt={5} pb={5} display='flex' flexDirection='row-reverse'>
                        <WildHealthButton 
                            id="employee-account-save" 
                            loading={isSubmitted} onClick={(event) => handleSubmit(event)}
                            >
                            Save Changes
                        </WildHealthButton>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}