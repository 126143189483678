import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { onEmit } from "../../../common/helpers/on-emit";
import { IErrorState } from "../../../common/validation/error-state";
import { PatientMedicationModel } from "../../models/patientMedicationModel";
import { medicationsService } from "../../services/medications.service";
import { patientsMedicationsService } from "../../services/patientMedications.service";
import { patientMedicationsQuery } from "../../stores/patientMedicationsStore/patientMedications.query";
import { patientMedicationsStore } from "../../stores/patientMedicationsStore/patientMedications.store";
import { pageSizes } from "../../../common/pagination/models/page-sizes";
import { getAvailablePages } from "../../../common/pagination/helpers/get-evailable-pages";
import { PatientAllergyModel } from "../../models/patientAllergiesModel";
import { patientAllergiesQuery } from "../../stores/patientAllergyStore/patientAllergies.query";

interface PatientMedicationsState extends IErrorState {
    isLoading: boolean;
    totalCount: number;
    selectedPage: number;
    pageSize: number;
    isEditorOpened: boolean;
    isMenuOpened: boolean;
    selectedId: number;
    medications: PatientMedicationModel[];
    medicationsPart: PatientMedicationModel[];
    anchorEl: any;
    editModel: PatientMedicationModel;
    allergies: PatientAllergyModel[];
}

export function useFacade(patientId: number | null): [
    PatientMedicationsState,
    Function,
    Function,
    Function,
    (value: any) => void,
    (page: number) => void,
    () => number[],
    Function,
] {
    const [state, setState] = useState({
        isLoading: true,
        totalCount: 0,
        selectedPage: 1,
        pageSize: pageSizes[2],
        isMenuOpened: false,
        isEditorOpened: false,
        selectedId: 0,
        medications: [],
        medicationsPart: [],
        anchorEl: null,
        errors: {},
        allergies: [],
    } as PatientMedicationsState);

    const handlePageSizeChange = (value: any) => {

        if (value === state.pageSize) {
            return;
        }

        const start = 0;
        const end = 1 * value > state.totalCount ? state.totalCount : state.selectedPage * value;
        const medicationsPartView = state.medications.slice(start, end)

        setState(state => ({
            ...state,
            pageSize: value,
            selectedPage: 1,
            medicationsPart: medicationsPartView
        }));
    }

    const handlePageChange = (page: number) => {
        if (page === state.selectedPage) {
            return;
        }

        const start = (page - 1) * state.pageSize;
        const end = page * state.pageSize > state.totalCount ? state.totalCount : page * state.pageSize;
        const medicationsPartView = state.medications.slice(start, end)

        setState(state => ({
            ...state,
            selectedPage: page,
            medicationsPart: medicationsPartView
        }));
    }

    const getAllAvailablePages = () => {
        return getAvailablePages(state);
    }

    const handleEdit = () => {
        setState({
            ...state,
            isEditorOpened: true,
            isMenuOpened: false
        });

        patientMedicationsStore.setEditModel(state.medications.find(c => c.id === state.selectedId));
    }

    const handleDelete = () => {
        const service = patientId ? medicationsService : patientsMedicationsService;
        service.delete(state.selectedId).subscribe();

        handleToggleActions(state.selectedId, state.anchorEl);
    }

    const handleCloseDialog = () => {
        setState({
            ...state,
            isEditorOpened: false,
            selectedId: 0
        });

        patientMedicationsStore.setEditModel();
    }

    const handleToggleActions = (id: number, anchorEl: Element) => {
        setState(state => ({
            ...state,
            isMenuOpened: !state.isMenuOpened,
            selectedId: id,
            anchorEl: anchorEl
        }))
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<PatientMedicationModel[]>(patientMedicationsQuery.patientMedications$, medications => {
                const medicationsPartView = medications.slice(0, pageSizes[2])

                setState(state => ({
                    ...state,
                    medications: medications,
                    totalCount: medications.length,
                    selectedPage: 1,
                    pageSize: pageSizes[2],
                    medicationsPart: medicationsPartView,
                }));
            }),
            onEmit<PatientAllergyModel[]>(patientAllergiesQuery.patientAllergies$, allergies => {
                if (allergies) {
                    setState(state => ({
                        ...state,
                        allergies
                    }));
                }
            })
        ];

        patientId
            ? medicationsService.get(patientId).subscribe(() => setState((state) => ({ ...state, isLoading: false })))
            : patientsMedicationsService.get().subscribe(() => setState((state) => ({ ...state, isLoading: false })));

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [patientId]);

    return [
        state,
        handleEdit,
        handleDelete,
        handleCloseDialog,
        handlePageSizeChange,
        handlePageChange,
        getAllAvailablePages,
        handleToggleActions,
    ];
}