import { Box, Checkbox, Grid, TextField } from '@material-ui/core';
import React, { MutableRefObject } from 'react';
import commonUseStyles from '../../../common/styles/common.styles';
import { NotePhysicalModel } from '../../models/notes.models';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { colors } from '../../../common/constants/colors';
import { noteSectionsNames, NoteSections } from '../createSoapComponent/createSoapNoteComponent.static';
import { handleShortcutInsert } from "../../../common/helpers/handle-shortcut-insert";
import { TextFieldNoteComponent } from '../textFieldNoteComponent/TextFieldNoteComponent';


export interface PhysicalComponentProps {
    errors: {},
    title: string,
    data: NotePhysicalModel,
    handleDisableVitals: (value: boolean) => void,
    handleNumericChanges: (key: string, value: number) => void,
    handlePhysicalExamChanges: (value: string) => void
}

export const PhysicalComponent: React.FC<PhysicalComponentProps> = (props: PhysicalComponentProps) => {
    const {
        errors,
        data,
        handleDisableVitals,
        handleNumericChanges,
        handlePhysicalExamChanges
    } = props;

    const commonClasses = commonUseStyles();

    const controlIcon = <CheckBoxOutlineBlankIcon style={{ fill: colors.mediumGray }} />;
    const controlCheckedIcon = <CheckBoxIcon style={{ fill: colors.main }} />
    const isObjective = props.title === noteSectionsNames[NoteSections.Objective]

    const optiamlExam = 'Gen: Awake and alert in no acute distress\n' +
        'Skin: Visualized areas dry without rash or worrisome lesion\n' +
        'HEENT: Normocephalic/atraumatic, extraocular movements intact\n' +
        'Neck: Full range of motion\n' +
        'Respiratory: Breaths are easy and unlabored\n' +
        'Psychological: Normal mood and affect\n' +
        'Musculoskeletal: Extremities demonstrate full range of motion\n' +
        'Neurologic:  Face symmetric, speech unimpaired, no evidence of cognitive dysfunction.'

    return (
        <Box>
            <Box>
                <span className={commonClasses.textMedium}>Vitals</span>
            </Box>
            <Box mt={2} display='flex' alignItems='center'>
                <Box>
                    <Checkbox
                        icon={controlIcon}
                        color="primary"
                        checkedIcon={controlCheckedIcon}
                        checked={data.unableToObtain}
                        onChange={(event) => handleDisableVitals(event.target.checked)}
                    />
                </Box>
                <Box ml={2}>
                    <span>Unable to obtain vitals from the patient during the appointment.</span>
                </Box>
            </Box>
            {
                !data.unableToObtain &&
                <Box>
                    {
                        !!errors['physicalData'] &&
                        <Box my={2} ml={1}>
                            <span className={commonClasses.error}>{errors['physicalData']}</span>
                        </Box>
                    }
                    <Grid container>
                        <Grid md={6} xs={12}>
                            <Box display='flex' justifyContent='space-between' mt={3}>
                                <Box px={1}>
                                    <Box pb={1}>
                                        <span className={commonClasses.inputLabel}>Systolic BP</span>
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        <Box>
                                            <TextField
                                                fullWidth
                                                type='number'
                                                size="small"
                                                variant="outlined"
                                                value={data.systolicBP.toString()}
                                                InputProps={{ className: 'input' }}
                                                error={!!errors['systolicBP']}
                                                helperText={errors['systolicBP']}
                                                onChange={(e) => handleNumericChanges('systolicBP', +e.target.value)}
                                            />
                                        </Box>
                                        <Box ml={2}>
                                            <span>
                                                /
                                            </span>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box> </Box>
                                <Box px={1}>
                                    <Box pb={1}>
                                        <span className={commonClasses.inputLabel}>Diastolic BP</span>
                                    </Box>
                                    <TextField
                                        fullWidth
                                        type='number'
                                        size="small"
                                        variant="outlined"
                                        value={data.diastolicBP.toString()}
                                        InputProps={{ className: 'input' }}
                                        error={!!errors['diastolicBP']}
                                        helperText={errors['diastolicBP']}
                                        onChange={(e) => handleNumericChanges('diastolicBP', +e.target.value)}
                                    />
                                </Box>
                                <Box px={1}>
                                    <Box pb={1}>
                                        <span className={commonClasses.inputLabel}>HR</span>
                                    </Box>
                                    <TextField
                                        fullWidth
                                        type='number'
                                        size="small"
                                        variant="outlined"
                                        value={data.heartRate.toString()}
                                        InputProps={{ className: 'input' }}
                                        error={!!errors['heartRate']}
                                        helperText={errors['heartRate']}
                                        onChange={(e) => handleNumericChanges('heartRate', +e.target.value)}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid md={6} xs={12}>
                            <Box display='flex' justifyContent='space-between' mt={3}>
                                <Box px={1}>
                                    <Box pb={1}>
                                        <span className={commonClasses.inputLabel}>Temperature (°F)</span>
                                    </Box>
                                    <TextField
                                        fullWidth
                                        type='number'
                                        size="small"
                                        variant="outlined"
                                        value={data.temperature.toString()}
                                        InputProps={{ className: 'input' }}
                                        error={!!errors['temperature']}
                                        helperText={errors['temperature']}
                                        onChange={(e) => handleNumericChanges('temperature', +e.target.value)}
                                    />
                                </Box>
                                <Box px={1}>
                                    <Box pb={1}>
                                        <span className={commonClasses.inputLabel}>Weight (lbs)</span>
                                    </Box>
                                    <TextField
                                        fullWidth
                                        type='number'
                                        size="small"
                                        variant="outlined"
                                        value={data.weight.toString()}
                                        InputProps={{ className: 'input' }}
                                        error={!!errors['weight']}
                                        helperText={errors['weight']}
                                        onChange={(e) => handleNumericChanges('weight', +e.target.value)}
                                    />
                                </Box>
                                <Box px={1}>
                                    <Box pb={1}>
                                        <span className={commonClasses.inputLabel}>Height (inch)</span>
                                    </Box>
                                    <TextField
                                        fullWidth
                                        type='number'
                                        size="small"
                                        variant="outlined"
                                        value={data.height.toString()}
                                        InputProps={{ className: 'input' }}
                                        error={!!errors['height']}
                                        helperText={errors['height']}
                                        onChange={(e) => handleNumericChanges('height', +e.target.value)}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            }
            <Box mt={5}>
                <Box>
                    <span className={commonClasses.textMedium}>{isObjective ? 'Objective' : 'Physical Exam'}</span>
                </Box>
                <Box mt={2}>
                    <WildHealthButton
                        onClick={() => handlePhysicalExamChanges(optiamlExam)}
                        id='normal-pe'
                        color='secondary'
                    >
                        Normal PE
                    </WildHealthButton>
                </Box>
                <Box mt={3}>
                    <Grid md={6} xs={12}>
                        <TextFieldNoteComponent
                            rows={12}
                            title=''
                            id="physical-exam-text-input"
                            content={data.physicalExam}
                            handleChanged={handlePhysicalExamChanges}
                            handleKeyDown={handleShortcutInsert}
                            error={!!errors['physicalExam']}
                            helperText={errors['physicalExam']}
                        />
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
}