import { AddressStore, addressStore } from "../stores/addressStore";
import { StateModel } from "../models/address.model";
import Axios from "axios-observable";
import { Observable } from "rxjs";

export class AddressService {
    private url = `${process.env.REACT_APP_API_URL}Address`;

    constructor(private store: AddressStore) {
    }

    public getStates(searchQuery?: string): Observable<StateModel[]> {
        const url = searchQuery 
            ? `${this.url}/states?searchQuery=${searchQuery}`
            : `${this.url}/states`;

        return new Observable<any>(observer => {
            Axios.get(url).pipe()
                .subscribe(
                    response => {
                        this.store.setStates(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }
}

export const addressService = new AddressService(addressStore);

