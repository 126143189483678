import { AgreementsComponentProps } from "./AgreementsComponent";
import { AgreementPageProps } from "./pages/agreementPageProps";

export function useFacade(props: AgreementsComponentProps): [() => JSX.Element]{
    const renderAgreement = (): JSX.Element => {
        const currentAgreementPageProps: AgreementPageProps = {
            data: {
                planName: props.paymentPlan.name,
                periodInMonths: props.paymentPeriod.periodInMonths.toString()
            }
        };

        return props.currentAgreementPage(currentAgreementPageProps);
    }

    return [renderAgreement];
}