export enum BannerDisplayType {
  Patient = 1,
  Employee = 2,
  All = 5
}

export const bannerTypesNames = new Map<BannerDisplayType, string>([
  [BannerDisplayType.Patient, 'Patient'],
  [BannerDisplayType.Employee, 'Employee'],
  [BannerDisplayType.All, 'All']
]);

export interface BannerModel {
  id: number;
  type: BannerDisplayType;
  name: string;
  copy: string;
  isActive: boolean;
  priority: number;
}