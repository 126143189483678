import Axios from 'axios-observable';
import {Observable} from 'rxjs';
import {authHeader} from '../../common/helpers/auth-header';
import {snackService} from '../../common/snack/state';
import {SpecialTestModel} from '../models/notes.models';
import {NotesStore, notesStore} from '../stores/notes';

/**
 * Provides method for working with Common orders
 */
export class CommonOrdersService {
    private url = `${process.env.REACT_APP_API_URL}Orders/Common`;

    constructor(private store: NotesStore) {
    }

    private getConfig = () => ({headers: authHeader()});

    public getAllCommonOrders(): Observable<SpecialTestModel[]> {

        return new Observable((observer) => {
            Axios.get<SpecialTestModel[]>(this.url, this.getConfig())
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({commonOrders: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getCommonOrderById(id: number): Observable<SpecialTestModel> {
    
        return new Observable(observer => {
            Axios.get<SpecialTestModel>(`${this.url}/${id}`, this.getConfig())
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.error();
                    }
                );
        })
    }

    public createCommonOrder(model: SpecialTestModel): Observable<SpecialTestModel> {
    
        return new Observable(observer => {
            Axios.post<SpecialTestModel>(this.url, model, this.getConfig())
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.addCommonOrder(response.data);
                        snackService.success("Common Order successfully created!")
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.error();
                    }
                );
        })
    }

    public updateCommonOrder(model: SpecialTestModel): Observable<SpecialTestModel> {
    
        return new Observable(observer => {
            Axios.put<SpecialTestModel>(this.url, model, this.getConfig())
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.updateCommonOrder(response.data);
                        snackService.success("Common Order successfully updated!")
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.error();
                    }
                );
        })
    }

    public deleteCommonOrder(id: number) {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.delete(`${this.url}/${id}`, config)
                .pipe()
                .subscribe(
                    () => {
                        this.store.removeCommonOrder(id);
                        snackService.success('Common Order successfully deleted');
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }
}

export const commonOrdersService = new CommonOrdersService(notesStore);
