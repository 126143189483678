import React from "react";
import {
    Box, Grid, Menu, MenuItem, IconButton, TableContainer, Table, TableFooter, TableHead, TableBody, Divider,
    useMediaQuery, useTheme, Tooltip,
} from "@material-ui/core";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import InfoIcon from '@material-ui/icons/Info';
import emptyMedications from "@img/placeholders/EmptyReport.png";
import clsx from "clsx";
import moment from "moment";
import { useFacade } from "./patientMedicationsComponent.hooks";
import { ProtectedElement } from "../../../common/components/ProtectedElement";
import { PermissionType, UserType } from "../../../auth/models/auth.enums";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { PatientMedicationUpdateComponent } from '../patientMedicationUpdateComponent/PatientMedicationUpdateComponent'
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { PatientMedicationModel } from "../../models/patientMedicationModel";
import { SupplementsPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/SupplementsPlaceHolder";
import { useStyles } from "./patientMedicationsComponent.styles";
import { PaginationComponent } from "../../../common/pagination/components/paginationComponent/PaginationComponent";
import { pageSizes } from "../../../common/pagination/models/page-sizes";
import commonUseStyles from '../../../common/styles/common.styles';
import { WildHealthPlaceHolderBase } from '../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolderBase';
import { toCurrentTimeZone } from "../../../timezones/helpers/timezone";

interface PatientMedicationsComponentProps {
    patientId?: number;
    fullWidth?: boolean;
    handleToggleDialog: () => void;
}

export const PatientMedicationsComponent: React.FC<PatientMedicationsComponentProps> = (props: PatientMedicationsComponentProps) => {
    const {
        patientId,
        handleToggleDialog
    } = props;

    const [
        state,
        handleEdit,
        handleDelete,
        handleCloseDialog,
        handlePageSizeChange,
        handlePageChange,
        getAllAvailablePages,
        handleToggleActions,
    ] = useFacade(patientId);

    const classes = useStyles();
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const customTooltipClasses = {
        tooltip: classes.customWidth,
        arrow: classes.arrow,
        tooltipPlacementBottom: classes.tooltipPlacement,
        tooltipPlacementTop: classes.tooltipPlacement,
    };

    const displayDataValue = (value: Date) => {
        return value ? moment(toCurrentTimeZone(value)).format("MM/DD/yyyy") : 'N/A';
    }

    const renderTable = () => {
        if (!state.isLoading && (!state.medications || !state.medications.length)) {
            return isSmallScreen ? (
                <Box minHeight="calc(100vh - 303px)" height={1}>
                    <WildHealthPlaceHolderBase message="You have no medication listed at the moment.">
                        <img className={classes.placeholderImage}
                            src={emptyMedications}
                            alt="img" />
                    </WildHealthPlaceHolderBase>
                </Box>
            ) : (
                <SupplementsPlaceHolder message={`You have no medication listed at the moment.`} />
            );
        }

        return (
            <Box minHeight="300px">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <WildHealthTableRow>
                                <WildHealthTableCell style={{ width: '30%' }}>
                                    <Box pl={2}>
                                        Name
                                    </Box>
                                </WildHealthTableCell>
                                <WildHealthTableCell style={{ width: '20%' }}>
                                    Strength
                                </WildHealthTableCell>
                                <WildHealthTableCell style={{ width: '20%' }}>
                                    Dosing Instruction
                                </WildHealthTableCell>
                                <WildHealthTableCell style={{ width: '20%' }}>
                                    Start Date
                                </WildHealthTableCell>
                                <WildHealthTableCell style={{ width: '10%' }}>
                                    <ProtectedElement
                                        element={<span>Action</span>}
                                        permissions={[PermissionType.Coaching, PermissionType.ManagePatients]}
                                    />
                                    <ProtectedElement
                                        element={<span>Action</span>}
                                        permissions={[]}
                                        userType={UserType.Patient}
                                    />
                                </WildHealthTableCell>
                            </WildHealthTableRow>
                        </TableHead>
                        <TableBody>
                            {
                                state.medicationsPart.map((item, index) =>
                                    <WildHealthTableRow key={index}>
                                        <WildHealthTableCell>
                                            <Box pl={2}>
                                                {
                                                    item.name
                                                }
                                            </Box>
                                        </WildHealthTableCell>
                                        <WildHealthTableCell>
                                            <Box display="flex" flexWrap="wrap">
                                                {item.dosage}
                                            </Box>
                                        </WildHealthTableCell>
                                        <WildHealthTableCell>
                                            <Box display="flex" flexWrap="wrap">
                                                {item.instructions}
                                            </Box>
                                        </WildHealthTableCell>
                                        <WildHealthTableCell>
                                            <Box display="flex" flexWrap="wrap">
                                                {displayDataValue(item.startDate)}
                                            </Box>
                                        </WildHealthTableCell>
                                        <WildHealthTableCell className={classes.actions}>
                                            <Box display='flex' alignItems='center'>
                                                <ProtectedElement
                                                    element={renderToggleButtonCell(item)}
                                                    permissions={[PermissionType.Coaching, PermissionType.ManagePatients]}
                                                />
                                                <ProtectedElement
                                                    element={renderToggleButtonCell(item)}
                                                    permissions={[]}
                                                    userType={UserType.Patient}
                                                />
                                            </Box>
                                        </WildHealthTableCell>
                                    </WildHealthTableRow>
                                )
                            }
                        </TableBody>
                        {!isSmallScreen &&
                            <TableFooter>
                                <WildHealthTableRow className={classes.row}>
                                    <WildHealthTableCell colSpan={6}>
                                        <PaginationComponent
                                            pageSizes={pageSizes}
                                            pageSize={state.pageSize}
                                            selectedPage={state.selectedPage}
                                            handlePageSizeChange={handlePageSizeChange}
                                            totalCount={state.totalCount}
                                            availablePages={getAllAvailablePages()}
                                            handlePageSelect={handlePageChange} />
                                    </WildHealthTableCell>
                                </WildHealthTableRow>
                            </TableFooter>
                        }
                    </Table>
                </TableContainer>
            </Box>
        )
    }

    const renderCreateButton = (title: string, size: any) => (
        <WildHealthButton
            id={`patient-medications-edit-${title}`}
            size={size}
            onClick={() => handleEdit()}
        >
            {title}
        </WildHealthButton>
    )

    const renderToggleButtonCell = (item: PatientMedicationModel) => (
        <IconButton id={`patient-medications-more-acrions-${item.id}`} onClick={(e) => {
            handleToggleActions(item.id, e.currentTarget)
        }}>
            <MoreHorizIcon />
        </IconButton>
    )

    const renderMenuItems = () => (
        <Menu
            keepMounted
            anchorEl={state.anchorEl}
            open={state.isMenuOpened}
            onClose={() => {
                handleToggleActions(0)
            }}
        >
            <MenuItem id="patient-medications-edit" onClick={() => {
                handleEdit()
            }}>
                Edit
            </MenuItem>
            <MenuItem id="patient-medications-delete" onClick={() => {
                handleDelete()
            }}>
                Delete
            </MenuItem>
        </Menu>
    );

    return (
        state.isLoading ?
            <WildHealthLinearProgress /> :
            <>
                <Box className={clsx(isSmallScreen && classes.smallRoot, "flat-scroll")}>
                    {!isSmallScreen && (
                        <Box p={4} pb={0} display='flex' justifyContent='space-between'>
                            <Box fontSize={20} fontWeight={500}>List of Medications</Box>
                            <Box display='flex' alignItems='center' gridGap={24}>
                                <Box display='flex' alignItems='center' gridGap={8} className="wh-tw-cursor-pointer" onClick={handleToggleDialog}>
                                    <InfoIcon className="wh-tw-w-5 wh-tw-h-5 wh-tw-text-primaryV" />
                                    <Box className="wh-tw-text-primaryV wh-tw-text-sm wh-tw-font-medium">Do you need help?</Box>
                                </Box>
                                <ProtectedElement
                                    element={<Box display='flex' alignItems='center' gridGap={8} className="wh-tw-cursor-pointer" >
                                        <Tooltip placement="bottom" arrow
                                            title={<Box className={classes.notificationToolTipText}>
                                                <Box>
                                                    {state.allergies.length
                                                        ? state.allergies.map(allergy => {
                                                            return <Box key={allergy.id}>
                                                                <span className={classes.itemDot}>&#x2022;</span>
                                                                <span className={classes.itemName}>{allergy.name}</span>
                                                                <span className={classes.itemReaction}>{`- ${allergy.reaction}`}</span>
                                                            </Box>
                                                        })
                                                        : <span className={classes.itemName}>No known drug allergies</span>}
                                                </Box>
                                            </Box>}
                                            classes={customTooltipClasses}
                                        >
                                            <InfoIcon className="wh-tw-w-5 wh-tw-h-5 wh-tw-text-primaryV" />
                                        </Tooltip>

                                        <Box className="wh-tw-text-primaryV wh-tw-text-sm wh-tw-font-medium">See Allergy List</Box>
                                    </Box>}
                                    permissions={[PermissionType.Coaching, PermissionType.ManagePatients]}
                                />
                                <Box>
                                    <ProtectedElement
                                        element={renderCreateButton('Add Medication', "large")}
                                        permissions={[PermissionType.Coaching, PermissionType.ManagePatients]}
                                    />
                                    <ProtectedElement
                                        element={renderCreateButton('Add Medication', "large")}
                                        permissions={[]}
                                        userType={UserType.Patient}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    )}

                    <Box mt={3}>
                        <Grid container>
                            <Grid item sm={12} md={12} xs={12}>
                                {
                                    renderTable()
                                }
                                <ProtectedElement
                                    element={renderMenuItems()}
                                    permissions={[PermissionType.Coaching, PermissionType.ManagePatients]}
                                />
                                <ProtectedElement
                                    element={renderMenuItems()}
                                    permissions={[]}
                                    userType={UserType.Patient}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box>
                        <PatientMedicationUpdateComponent
                            opened={state.isEditorOpened}
                            handleClose={() => handleCloseDialog()}
                            patientId={props.patientId}
                        />
                    </Box>
                </Box>
                {isSmallScreen && (
                    <>
                        {state.medications && state.medications.length > 0 && <Divider className={commonClasses.renderDivider} orientation="horizontal" />}
                        <Box className={commonClasses.bgWhiteMain}>
                            <Box display="flex" flexDirection="row" justifyContent="flex-end">
                                {state.medications && state.medications.length > 0 && (
                                    <Box flex={1} margin="auto" pl={2}>
                                        <PaginationComponent
                                            pageSizes={pageSizes}
                                            pageSize={state.pageSize}
                                            selectedPage={state.selectedPage}
                                            handlePageSizeChange={handlePageSizeChange}
                                            totalCount={state.totalCount}
                                            availablePages={getAllAvailablePages()}
                                            handlePageSelect={handlePageChange} />
                                    </Box>
                                )}
                                <IconButton aria-label={`patient-medications-add`} onClick={() => handleEdit()}>
                                    <AddCircleIcon className={classes.fixedButton} />
                                </IconButton>
                            </Box>
                        </Box>
                    </>
                )}
            </>
    )
}
