import {Store, StoreConfig} from '@datorama/akita';
import {ShortcutGroupModel, ShortcutModel} from "../../models/shortcut.models";

export enum ShortcutGroupTabs {
    Global = 0,
    Personal = 1,
}

/**
 * Represents shortcuts state
 */
export interface ShortcutsState {
    groups: ShortcutGroupModel[];
    tab: ShortcutGroupTabs;
    targetShortcut: ShortcutModel;
}

/**
 * Creates initial state
 */
export function createInitialState(): ShortcutsState {
    return {
        groups: [],
        tab: 0,
        targetShortcut: null
    };
}

/**
 * Provides shortcuts states management
 */
@StoreConfig({name: 'coaches', resettable: true })
export class ShortcutsStore extends Store<ShortcutsState> {
    constructor() {
        super(createInitialState());
    }

    public init(group: ShortcutGroupModel[]): void {
        this.update({groups: this._sort(group)});
    }

    public insertGroup(group: ShortcutGroupModel): void {
        this.update({groups: [...this.getValue().groups, group]});
    }

    public updateGroup(group: ShortcutGroupModel) {
        const groups = this.getValue().groups;
        this.update({ groups: groups.map(x => x.id === group.id ? group : x) });
    }

    public deleteGroup(id: number): void {
        this.update({groups: this.getValue().groups.filter(i => i.id !== id)});
    }

    public insertShortcut(shortcut: ShortcutModel): void {
        this.update({groups: this._insertShortcut(this.getValue().groups, shortcut)});
    }

    public updateShortcut(shortcut: ShortcutModel): void {
        let groups = this.getValue().groups;
        groups = this._deleteShortcut(groups, shortcut);
        groups = this._insertShortcut(groups, shortcut);
        this.update({groups: groups});
    }

    public deleteShortcut(shortcut: ShortcutModel): void {
        this.update({groups: this._deleteShortcut(this.getValue().groups, shortcut)});
    }

    public setTargetShortcut(shortcut: ShortcutModel): void {
        this.update({ targetShortcut: shortcut });
    }

    public resetTargetShortcut(): void {
        this.update({
            targetShortcut: null
        });
    }

    public setTab(tab: ShortcutGroupTabs): void {
        this.update({ tab: tab });
    }

    //#region private

    private _sort(groups: ShortcutGroupModel[]): ShortcutGroupModel[] {
        groups = groups.map(x => {
            x.shortcuts = x.shortcuts.sort(createCompareFunction('displayName'));
            return x;
        });

        return groups.sort(createCompareFunction('name'))
    }

    private _deleteShortcut(groups: ShortcutGroupModel[], shortcut: ShortcutModel): ShortcutGroupModel[] {
        return groups.map(x => {
            return x.shortcuts.findIndex(k => k.id === shortcut.id) < 0
                ? x
                : {
                    id: x.id,
                    name: x.name,
                    isPersonal: x.isPersonal,
                    shortcuts: x.shortcuts.filter(t => {
                        return t.id !== shortcut.id;
                    })
                }
        })
    }

    private _insertShortcut(groups: ShortcutGroupModel[], shortcut: ShortcutModel): ShortcutGroupModel[] {
        return this._sort(groups.map(x => {
            return x.id !== shortcut.groupId
                ? x
                : {
                    id: x.id,
                    name: x.name,
                    isPersonal: x.isPersonal,
                    shortcuts: [...x.shortcuts, shortcut]
                }
        }));
    }

    //#endregion
}

function createCompareFunction(sortBy: string) {
    return (a, b): number => {
        if (a[sortBy] > b[sortBy]) {
            return 1;
        }
        if (b[sortBy] > a[sortBy]) {
            return -1;
        }

        return createCompareFunction('id')(a, b);
    }
}

export const shortcutsStore = new ShortcutsStore();
