import { Box, Button, ButtonGroup, CircularProgress, Divider, FormControl, Grid, MenuItem, Select } from '@material-ui/core';
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import GetAppIcon from '@material-ui/icons/GetApp';
import moment from "moment";
import React from 'react';
import WildHealthLinearProgress from "../../../../common/components/WildHealthLinearProgress";
import { getInitQuestionnaires, NoteRecommendation } from "../../../constants/initialConsult.constants";
import { MedicationsSupplementsComponent } from '../../medicationsSupplements/MedicationsSupplementsComponent';
import { NoteInternalRecommendationComponent } from "../../noteInternalRecommendations/NoteInternalRecommendation";
import { NoteQuestionnaireResult } from "../../noteQuestionnaireResult/NoteQuestionnaireResult";
import { NoteSignatureComponent } from "../../noteSignature/NoteSignatureComponent";
import { useFacade } from './initialConsultComponentOld.hooks';
import { useStyles } from '.././initialConsultComponent.styles';
import { NoteModel } from "../../../models/notes.models";
import { confirmService } from '../../../../../services/confirm.service';
import EditIcon from '@material-ui/icons/Edit';
import { WildHealthStatusLabel } from '../../../../common/components/wildHealthStatusLabel/WildHealthStatusLabel';
import { IsSectionAmendICC } from '../../../helpers/noteHelpers';
import { NoteRecommendationsComponentOld } from '../../noteRecommendations/Old/NoteRecommendationsComponentOld';

interface InitialConsultComponentProps {
    note: NoteModel;
    patientId: number;
    handleGoToNotes: () => void;
    handleGoToEditNote: (note: NoteModel) => void;
}

export const InitialConsultComponentOld: React.FC<InitialConsultComponentProps> = (props: InitialConsultComponentProps) => {
    const {
        note,
        patientId,
        handleGoToNotes,
        handleGoToEditNote
    } = props;

    const [
        {
            content,
            medications,
            supplements,
            isPdfDownloading,
            noteId,
            originalContent
        },
        handleDownloadPdf,
        handleChooseNote
    ] = useFacade(patientId, note);

    const classes = useStyles();

    const isLoading = !content || !note;

    if (isLoading)
        return (<WildHealthLinearProgress />)

    const renderTitle = () => {
        return <span>{note.name} - {moment(note.visitDate).format("ll")}</span>
    }

    const renderInternalNotes = () => {
        return <NoteInternalRecommendationComponent internalContent={content.internalContent} />
    }

    const renderMedicationsSupplements = () => {
        return <MedicationsSupplementsComponent medications={medications} supplements={supplements} />
    }

    const renderRecommendations = () => {
        const recommendations = JSON.parse(content?.content) as NoteRecommendation[];
        return (
            <NoteRecommendationsComponentOld
                recommendations={recommendations && recommendations.length ? recommendations.filter(x => x.isSelected) : []}
            />
        );
    }

    const renderQuestionnaire = () => {
        return <NoteQuestionnaireResult logs={content.logs} questionnaire={getInitQuestionnaires()} />
    }

    const handleEditNote = () => {
        confirmService.confirm('Are you sure?', 'Are you sure you wish to amend this note? All changes will be logged.')
            .subscribe(() => {
                handleGoToEditNote(note);
            });
    }

    const displayEditButton = () => {

        return (
            <Button id="edit_current_note" onClick={() => handleEditNote()}>
                <Box display="flex" alignItems="center" mt={1}>
                    <Box>
                        <EditIcon />
                    </Box>
                </Box>
            </Button>
        );
    }

    const displaySelectVersion = () => {
        if (note.amendedNotes?.length == 0 || note.amendedNotes == null || note.amendedNotes.every(x => x.completedAt === null)) {
            return
        }

        return (
            <Box display="flex" alignItems="center" justifyContent="flex-start" ml={2}>
                <Box pr={2}>
                    <WildHealthStatusLabel color='normal'>Amended Note</WildHealthStatusLabel>
                </Box>
                <Box width="120px">
                    <FormControl color='primary' variant="outlined" size="small" fullWidth>
                        <Select
                            style={{ background: '#FFF' }}
                            id="choseNoteVersion"
                            value={noteId}
                            onChange={(v) => handleChooseNote(v.target.value)}>
                            <MenuItem key={0} value={note.id}>Original</MenuItem>
                            {
                                note.amendedNotes.filter(x => x.completedAt != null).map((note, i) => {
                                    return <MenuItem key={i + 1} value={note.id}>Amended - {moment(note.completedAt).format("MM/DD/YYYY")}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Box>
        );
    }

    const displayDownloadPdfButton = () => {
        if (isPdfDownloading === true) {
            return (
                <Button>
                    <Box width="70px" display="flex" justifyContent="center">
                        <CircularProgress size={24} className={classes.colorMain} />
                    </Box>
                </Button >
            )
        }
        return (
            <Button id="initial-consult-download-pdf" onClick={() => {
                handleDownloadPdf(note, content, medications, supplements);
            }}>
                <Box display="flex" alignItems="center" mt={1}>
                    <Box>
                        <GetAppIcon />
                    </Box>
                    <Box mt={-1}>
                        PDF
                    </Box>
                </Box>
            </Button>);
    }

    const renderNote = () => {
        return (
            <>
                <Grid container spacing={5}>
                    <Grid item xs={7}>
                        {
                            renderInternalNotes()
                        }
                    </Grid>
                    <Grid item xs={7}>
                        {
                            renderRecommendations()
                        }
                    </Grid>
                    <Grid item xs={7}>
                        {
                            renderMedicationsSupplements()
                        }
                    </Grid>
                    <Grid item xs={7}>
                        {
                            renderQuestionnaire()
                        }
                    </Grid>
                </Grid>
                {
                    originalContent && content &&
                    (
                        <Box mt={3}>
                            <Box py={2}>
                                <Divider />
                            </Box>
                            {
                                IsSectionAmendICC(originalContent, content) &&
                                <Box>Note was updated.</Box>
                            }
                        </Box>
                    )
                }
                <Box mt={5}>
                    <NoteSignatureComponent note={note} noteId={noteId} />
                </Box>
            </>
        )
    }

    return (
        <Box m={5} mt={0} pt={3}>
            <Box display="flex " alignItems="center" justifyContent='space-between'>
                <ButtonGroup size="large" variant="text" color="default" aria-label="large outlined primary button group">
                    <Button
                        id="initial-consult-to-notes"
                        onClick={() => handleGoToNotes()}
                        startIcon={<ArrowBackIosRoundedIcon />}
                    >
                        To Notes
                    </Button>
                    <Button disabled className={classes.title}>
                        {
                            isLoading
                                ? 'Loading . . .'
                                : renderTitle()
                        }
                    </Button>
                    {
                        displaySelectVersion()
                    }
                </ButtonGroup>
                <Box display="flex " alignItems="center">
                    {
                        displayEditButton()
                    }
                    {
                        displayDownloadPdfButton()
                    }
                </Box>
            </Box>

            <Box mt={5}>
                <Box mt={5}>
                    {
                        isLoading
                            ? <WildHealthLinearProgress />
                            : renderNote()
                    }
                </Box>
            </Box>
        </Box>
    );
}
