import React from 'react';
import { Box, Table, TableBody, TableContainer, TableHead, useTheme, useMediaQuery, Divider, Button, List, ListItem, ListItemIcon, ListItemText, Popover } from '@material-ui/core';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { useStyles } from './patientCourseHistoryComponent.styles';
import commonUseStyles from '../../../common/styles/common.styles';
import { useFacade } from './patientCourseHistoryComponent.hooks';
import { WildHealthPlaceHolderBase } from '../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolderBase';
import emptyCourses from "@img/placeholders/EmptyReport.png";
import clsx from 'clsx';
import { WildHealthTableRow } from '../../../common/components/wildHealthTable/WildHealthTableRow';
import { WildHealthTableCell } from '../../../common/components/wildHealthTable/WildHealthTableCell';
import moment from 'moment';
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { WildHealthLinkButton } from '../../../common/components/WildHealthLinkButton';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FilterListIcon from '@material-ui/icons/FilterList';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { WildHealthStatusLabel } from '../../../common/components/wildHealthStatusLabel/WildHealthStatusLabel';
import { AssignCourseButtonComponent } from "../assignCourseButtonComponent/AssignCourseButtonComponent";
import { authQuery } from '../../../auth/stores/auth';
import { PatientCourseHistoryModel } from '../../models/courses.model';
import { PatientCourseStatus } from '../../models/courses.enums';
import { UserType } from '../../../auth/models/auth.enums';

const filtersPopoverProps: any = {
  id: "notificationsPopover",
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
};

interface PatientCourseHistoryComponentProps {
  patientId?: number;
}

export const PatientCourseHistoryComponent: React.FC<PatientCourseHistoryComponentProps> = (props: PatientCourseHistoryComponentProps) => {
  const { patientId } = props;

  const [
    {
      histories,
      isLoading,
      filtersState,
    },
    handleToggleFilters,
    handleOnNotStartedStatusChanges,
    handleOnInProgressStatusChanges,
    handleOnCompletedStatusChanges,
    handleClearFilters,
    handleApplyFilters,
    handleGoToNorthPassAcademy
  ] = useFacade(patientId);

  const classes = useStyles();
  const commonClasses = commonUseStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const getStatus = (course: PatientCourseHistoryModel) => {
    if (course.status && course.status === PatientCourseStatus.Completed) {
      return <WildHealthStatusLabel color='good'><span className={clsx(commonClasses.colorGood, commonClasses.textMedium, commonClasses.size14)}>Completed</span></WildHealthStatusLabel>;
    } else {
      return <WildHealthStatusLabel color='average1'><span className={clsx(commonClasses.colorAverage1, commonClasses.textMedium, commonClasses.size14)}>In progress</span></WildHealthStatusLabel>;
    }
  }

  const bannerComponent: JSX.Element = (
    <Box mt={isSmallScreen ? "12px" : "30px"} className={classes.banner}>
      <Box className={classes.nextUpSection}>
        <Box id="patient-course-next-up" className={classes.nextUp} >
          Next Up
        </Box>
        {isSmallScreen && (
          <Box id="north-pass-academy-start" className={classes.start} onClick={() => handleGoToNorthPassAcademy()}>
            Start
          </Box>
        )}
      </Box>
      <Box className={clsx(classes.bannerMessage, commonClasses.size16, commonClasses.textRegular, commonClasses.colorBlack)}>
        <Box>Browse our challenges & resources available in our Academy </Box>
      </Box>
      {!isSmallScreen && (
        <Box id="north-pass-academy-start" className={classes.start} onClick={() => handleGoToNorthPassAcademy()}>
          Start
        </Box>
      )}
    </Box>
  )

  const renderDate = (dt: Date) => {
    
    if(dt != null) {
      return moment(dt).format('MM/DD/YYYY')
    } 

    return ""
  }

  const renderCourseHistories = (): JSX.Element => {
    if (isLoading) {
      return (
        <Box mt="30px">
          <WildHealthLinearProgress />
        </Box>);
    }

    if (!histories || !histories.courses.length) {
      return (
        <Box mt="30px">
          <WildHealthPlaceHolderBase message="No courses.">
            <img
              src={emptyCourses}
              alt="img"
              className={classes.image} />
          </WildHealthPlaceHolderBase>
        </Box>
      );
    }

    if (isSmallScreen) {
      return (
        <Box mt="20px">
          {
            histories.courses.map((course, index) =>
              <Box mb="10px" className={commonClasses.bgWhiteMain} key={index}>
                <Box pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
                  <Box>
                    <Box className={clsx(commonClasses.textMedium, commonClasses.size14, commonClasses.colorBlack)}>
                      {renderDate(histories.createdAt)}
                    </Box>
                  </Box>
                  {/* <Box>
                    {getStatus(histories)}
                  </Box> */}
                </Box>
                <Box px={2} py={1.5}>
                  <span className={clsx(commonClasses.textRegular, commonClasses.size14, commonClasses.colorBlack)}>{course.name}</span>
                </Box>
                <Divider />
                <Box px={2} pb={2} mt={1.5} display="flex" alignItems="center">
                </Box>
              </Box>
            )
          }
        </Box>
      );
    } else {
      return (
        <Box mt="30px">
          <TableContainer>
            <Table>
              <TableHead>
                <WildHealthTableRow>
                  {/* <WildHealthTableCell style={{ width: '30%' }}>Date Started</WildHealthTableCell> */}
                  <WildHealthTableCell style={{ width: '30%' }}>Course Name</WildHealthTableCell>
                  {/* <WildHealthTableCell>Course Status</WildHealthTableCell> */}
                </WildHealthTableRow>
              </TableHead>
              <TableBody>
                {
                  histories.courses.map((course, index) =>
                    <WildHealthTableRow key={index}>
                      {/* <WildHealthTableCell style={{ display: 'grid' }}>
                        <span
                          className={commonClasses.textMedium}>{renderDate(histories.createdAt)}</span>
                      </WildHealthTableCell> */}
                      <WildHealthTableCell>{course.name}</WildHealthTableCell>
                      {/* <WildHealthTableCell>{getStatus(histories)}</WildHealthTableCell> */}
                    </WildHealthTableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      );
    }
  }

  return (
    <Box p={isSmallScreen ? 2 : "30px"} className={clsx(classes.historyContainer, commonClasses.size14, !isSmallScreen && commonClasses.bgWhiteMain)}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <span className={clsx(commonClasses.size24, commonClasses.textSemiBold)}>{PagesNamesService.courseHistory}</span>
        </Box>
        {/* <Button
          id="course-histories-filter"
          aria-describedby={filtersPopoverProps.id}
          onClick={(event) => handleToggleFilters(event)}
          startIcon={<FilterListIcon />}
          className={commonClasses.grayButton}
        >
          Filter
        </Button> */}
      </Box>
      {bannerComponent}
      <Box mt={1}>
        {
          renderCourseHistories()
        }
      </Box>
      {authQuery.getType() === UserType.Employee && (
        <Box mt={4} display="flex " alignItems="center" justifyContent="flex-end">
          <AssignCourseButtonComponent patientId={patientId} />
        </Box>
      )}
      <Popover
        id={filtersPopoverProps.id}
        anchorEl={filtersState.anchorEl}
        onClose={handleToggleFilters}
        open={filtersState.isOpen}
        anchorOrigin={filtersPopoverProps.anchorOrigin}
        transformOrigin={filtersPopoverProps.transformOrigin}
      >
        <List className={classes.filters}>
          <ListItem
            id={`course-history-filter-statuses-${filtersState.filters.notStartedStatus.title}`}
            button
            onClick={() => handleOnNotStartedStatusChanges(!filtersState.filters.notStartedStatus.isSelected)}
          >
            <ListItemIcon className={classes.nested}>
              {filtersState.filters.notStartedStatus.isSelected ? <CheckBoxIcon className={classes.activeIcon} /> : <CheckBoxOutlineBlankIcon />}
            </ListItemIcon>
            <ListItemText primary={filtersState.filters.notStartedStatus.title} />
          </ListItem>
          <ListItem
            id={`course-history-filter-statuses-${filtersState.filters.inProgressStatus.title}`}
            button
            onClick={() => handleOnInProgressStatusChanges(!filtersState.filters.inProgressStatus.isSelected)}
          >
            <ListItemIcon className={classes.nested}>
              {filtersState.filters.inProgressStatus.isSelected ? <CheckBoxIcon className={classes.activeIcon} /> : <CheckBoxOutlineBlankIcon />}
            </ListItemIcon>
            <ListItemText primary={filtersState.filters.inProgressStatus.title} />
          </ListItem>
          <ListItem
            id={`course-history-filter-statuses-${filtersState.filters.completedStatus.title}`}
            button
            onClick={() => handleOnCompletedStatusChanges(!filtersState.filters.completedStatus.isSelected)}
          >
            <ListItemIcon className={classes.nested}>
              {filtersState.filters.completedStatus.isSelected ? <CheckBoxIcon className={classes.activeIcon} /> : <CheckBoxOutlineBlankIcon />}
            </ListItemIcon>
            <ListItemText primary={filtersState.filters.completedStatus.title} />

          </ListItem>
        </List>

        <Box p={2} display="flex" justifyContent="space-between" alignItems='center'>
          <WildHealthLinkButton
            onClick={() => handleClearFilters()}
            content={<Box className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorGray1)}>Clear all</Box>}
            id="course-history-filter-clear-all"
          />
          <WildHealthLinkButton
            onClick={() => handleApplyFilters()}
            content={<Box className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorMain)}>Apply</Box>}
            id="course-history-filter-apply"
          />
        </Box>
      </Popover>
    </Box>
  )
}