import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";
import {Subscription} from "recompose";
import {navigationService} from "../../../../services/navigation.service";
import {onEmit} from "../../../common/helpers/on-emit";
import {NotificationModel, NotificationType} from '../../models/notifications.models';
import {notificationsService} from "../../services/notifications.service";
import {notificationsQuery} from "../../stores/notifications";
import { confirmService } from "../../../../services/confirm.service";
import {labOrdersService} from "../../../orders/services/labOrders.service";

interface NotificationsComponentState {
    notifications: NotificationModel[];
    anchorEl: HTMLButtonElement;
    showNotifications: boolean;
}

const invisibleTypes = [
    NotificationType.UnreadMessagesCount,
    NotificationType.NewSupportTicket,
    NotificationType.NewMessage,
    NotificationType.UnreadMessagesManual
];

/**
 * Custom Hook to manage a view Model for Notifications component
 */
export function useFacade(patientId: number | null = null): [NotificationsComponentState, Function, Function, Function, Function] {
    const [state, setState] = useState({
        notifications: [],
        anchorEl: null,
        showNotifications: false
    } as NotificationsComponentState);

    const _history = useHistory();

    const notificationsNavigation = new Map<number, (linkData?: any) => void>([
        [NotificationType.PatientAssignment, (linkData: any) => navigationService.toManagePatientProfile(_history, linkData.patientId)],
        [NotificationType.LabResults, (linkData: any) => navigationService.toPatientLabResults(_history, linkData.patientId)],
        [NotificationType.AppointmentRequest, () => {}],
        [NotificationType.ChangeHealthPlan, (linkData?: any) => navigationService.toManagePatientProfile(_history, linkData.patientId)],
        [NotificationType.NewEnrollment, () => navigationService.toIntakePatients(_history)],
        [NotificationType.HealthLogReminder, () => navigationService.toMyHealthForms(_history)],
        [NotificationType.DnaKitFailed, (linkData?: any) => navigationService.toManagePatientProfile(_history, linkData.patientId)],
        [NotificationType.PatientTransferred, (linkData?: any) => navigationService.toManagePatientProfile(_history, linkData.patientId)],
        [NotificationType.AppointmentCancelled, () => {}],
        [NotificationType.FellowEnrolled, () => {}],
        [NotificationType.FellowshipPatientEnrolled, () => {}],
        [NotificationType.PracticumPatientAdded, () => {}],
        [NotificationType.NewMessage, (linkData: any) => navigationService.toConversations(_history, linkData.conversationId)],
        [NotificationType.HealthReportReview, (linkData: any) => navigationService.toPatientHealthReport(_history, linkData.patientId)],
        [NotificationType.HealthReportReviewed, (linkData: any) => navigationService.toPatientHealthReport(_history, linkData.patientId)],
        [NotificationType.NewHealthReport, () => navigationService.toMyHealthReport(_history)],
        [NotificationType.NewDocuments, (linkData: any) => navigationService.toPatientDocuments(_history, linkData.patientId)],
        [NotificationType.NoteSignOff, (linkData: any) => navigationService.toSignOffNote(_history, linkData.noteId)],
        [NotificationType.NewNote, (linkData: any) => navigationService.toPatientNote(_history, linkData.noteId, linkData.noteType)],
        [NotificationType.NewAlert, () => navigationService.toDashboard(_history)],
        [NotificationType.PatientJourneyRewardUnlocked, () => navigationService.toPatientJourneyTasks(_history, null, 2)],
        [NotificationType.UpcomingLabs, (linkData: any) => {
            labOrdersService.getMyRequisition(linkData.orderId).subscribe(
                (blob) => {
                    const downloadUrl = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.click();
                });
        }]
    ]);

    const handleNavigateTo = (notification: NotificationModel): void => {

        const linkData = JSON.parse(notification.linkData);

        const navigate = notificationsNavigation.get(notification.type);

        if (navigate) {
            navigate(linkData);
        }
    }
    const handleDelete = (id: number) => {
        notificationsService.delete(id);
    }

    const handleDeleteAll = () => {
        confirmService.confirm(
            'Are you sure?',
            'Are you sure you want to clear all notifications?',
            'Yes',
            'Cancel',
            'danger')
            .subscribe(
                () => notificationsService.deleteAll()
            );
    }

    const handleToggleNotifications = (event?: React.MouseEvent<HTMLButtonElement>) => {
        const anchorEl = event && event.currentTarget ? event.currentTarget : null;
        setState(state => ({...state, showNotifications: !state.showNotifications, anchorEl: anchorEl}));
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<NotificationModel[]>(notificationsQuery.notifications$, notifications => {
                const visibleNotifications = notifications?.filter(x => !invisibleTypes.includes(x.type)) ?? [];
                const showNotifications = state.showNotifications && visibleNotifications?.length > 0;
                const filteredNotification = visibleNotifications.filter(x => !patientId || +JSON.parse(x.linkData).patientId === patientId)

                setState(state => ({
                    ...state,
                    notifications: filteredNotification,
                    showNotifications: showNotifications
                }));
            })
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [state.showNotifications, patientId]);

    return [state, handleNavigateTo, handleDelete, handleDeleteAll, handleToggleNotifications];
}