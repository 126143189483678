import {useEffect, useState} from "react";
import {Subscription} from "rxjs";
import { useFlags } from 'launchdarkly-react-client-sdk';
import {CoverageModel, InsuranceModel} from "../../models/insurance.models";
import {insuranceService} from "../../services/insurance.service";
import {insuranceQuery} from "../../stores/insurance.query";
import {onEmit} from "../../../common/helpers/on-emit";
import {IErrorState} from "../../../common/validation/error-state";
import {AttachmentModel} from "../../../common/models/attachment.models";
import { SubscriptionModel, SubscriptionType } from "../../../payment/models/subscription.models";
import { subscriptionQuery } from "../../../payment/stores/subscriptionStore";
import { subscriptionService } from "../../../payment/services/subscription.service";
import { isFeatureFlag } from "../../../common/components/featureFlags/featureFlags";
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";

interface InsuranceComponentState extends IErrorState {
    insurances: InsuranceModel[];
    attachments: AttachmentModel[];
    coverageList: CoverageModel[];

    isUploadButtonLoading: boolean;
    isInsurancesLoading: boolean;
    isCoveragesLoading: boolean;
    isAttachmentsLoading: boolean;
    isShowConfirmDialog: boolean;
    isInsuranceAvailable: boolean;
    isAddButtonLoading: boolean;
}

/**
 * Custom Hook to manage a view Model for Insurance component
 */
export function useFacade(): [
    InsuranceComponentState,
    boolean,
    () => void,
    (coverage: CoverageModel) => void
] {
    const featureFlags = useFlags();
    const isEnabledInsuranceAdd = isFeatureFlag(featureFlags, FeatureFlag.EnabledInsuranceAdd);
    const [state, setState] = useState({
        insurances: [],
        coverageList: [],
        attachments: [],
        isInsurancesLoading: true,
        isAttachmentsLoading: true,
        isCoveragesLoading: true,
        isShowConfirmDialog: false,
        isInsuranceAvailable: false,
        isAddButtonLoading: false
    } as InsuranceComponentState);

    const handleAddInsurance = () => {
        if (state.isAddButtonLoading) {
            return;
        }

        insuranceService.requestCreateInsurance(0).subscribe(
            (model) => {
                setState(state => ({
                    ...state,
                    isAddButtonLoading: true
                }));
                const cb = () => {
                    setState(state => ({ ...state, isAddButtonLoading: false }));
                }
                insuranceService.createCoverage(model).subscribe(cb, cb);
            }
        )
    }

    const handleEditInsurance = (coverage: CoverageModel) => {
        insuranceService.requestUpdateInsurance(coverage, 0).subscribe(
            (model) => {
                insuranceService.updateCoverage(model).subscribe();
            }
        )
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<InsuranceModel[]>(insuranceQuery.insurances$, insurances => {
                setState(state => ({
                    ...state,
                    insurances: insurances,
                }))
            }),
            onEmit<AttachmentModel[]>(insuranceQuery.attachments$, attachments => {
                setState(state => ({
                    ...state,
                    attachments: attachments,
                }))
            }),
            onEmit<CoverageModel[]>(insuranceQuery.coverages$, coverages => {
                if (coverages !== null && coverages.length) {
                    setState(state => ({
                        ...state,
                        coverageList: coverages, 
                    }))
                }
            }),
            onEmit<boolean>(insuranceQuery.insuranceAvailability$, available => {
                    setState(state => ({
                        ...state,
                        isInsuranceAvailable: available, 
                    }))
            }),
            onEmit<SubscriptionModel>(subscriptionQuery.subscription$, subscription=>{    
                if(subscription){
                    setState(state => ({...state, isShowConfirmDialog: subscription.type === SubscriptionType.Regular}));
                }
            }),
        ];

        insuranceService.getAvailableInsurances().subscribe(() => {
            setState((state) => ({...state, isInsurancesLoading: false}))
        }, () => {
            setState((state) => ({...state, isInsurancesLoading: false}))
        });

        insuranceService.getCoverages().subscribe(() => {
            setState((state) => ({...state, isCoveragesLoading: false}))
        }, () => {
            setState((state) => ({...state, isCoveragesLoading: false}))
        });

        insuranceService.getInsuranceUploads().subscribe(
            () => {
                setState((state) => ({...state, isAttachmentsLoading: false}))
            },
            () => {
                setState((state) => ({...state, isAttachmentsLoading: false}))
            }
        );

        insuranceService.getInsuranceAvailability().subscribe();

        subscriptionService.getCurrent()

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [
        state,
        isEnabledInsuranceAdd,
        handleAddInsurance,
        handleEditInsurance
    ];
}