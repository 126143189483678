import { Box, CircularProgress, Divider } from '@material-ui/core';
import React from 'react';
import { BackTitleComponent } from '../../../../components/pageTitleComponent/PageTitleComponent';
import { ExpandableComponent } from '../../../common/components/expandableComponent/ExpandableComponent';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import {
    AppointmentConfigurationModel,
    MdmPatientType,
    NoteModel,
    NotePmhVersion,
    NotesType
} from '../../models/notes.models';
import { NextAppointmentDateComponent } from '../nextAppointmentDateComponent/NextAppointmentDateComponent';
import { NoteMdmComponent } from '../noteMdmComponent/NoteMdmComponent';
import { NotePlanComponent } from '../notePlanComponent/NotePlanComponent';
import { PhysicalComponent } from '../physicalComponent/PhysicalComponent';
import { PmhComponent } from '../pmhComponent/PmhComponent';
import { RosComponent } from '../rosComponent/RosComponent';
import { SupplementsMedicationsComponent } from '../supplementsMedicationsComponent/SupplementsMedicationsComponent';
import { useFacade } from './createHistoryAndPhysicalFollowUpNoteComponent.hooks';
import { NoteSections, noteSectionsNames } from './createHistoryAndPhysicalFollowUpNoteComponent.static';
import { useStyles } from './CreateHistoryAndPhysicalFollowUpNoteComponent.styles';
import { colors } from "../../../common/constants/colors";
import { SelectShortcutComponent } from "../../../healthReport/components/selectShortcutComponent/SelectShortcutComponent";
import { NoteGeneralInfoComponent } from "../noteGeneralInfo/NoteGeneralInfoComponent";
import { DiagnosisComponent } from "../diagnosisComponent/DiagnosisComponent";
import { TextFieldNoteComponent } from '../textFieldNoteComponent/TextFieldNoteComponent';
import { handleShortcutInsert } from '../../../common/helpers/handle-shortcut-insert';
import { SignOffDialogComponent } from "../signOffDIalogComponent/SignOffDialogComponent";
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import { FeatureComponent } from "../../../common/components/featureFlags/FeatureComponent";
import {canDiscardNote, isFutureAppointment} from "../../helpers/noteHelpers";
import {SpecialtyTestsComponent} from "../specialtyTestsComponent/SpecialtyTestsComponent";
import moment from "moment";

export interface CreateHistoryAndPhysicalNoteComponentProps {
    patientId: number;
    appointmentId?: number | null;
    appointmentConfiguration: AppointmentConfigurationModel | null;
    handleGoBack: () => void;
    goBackTitle: string;
    note?: NoteModel;
    originalNote?: NoteModel;
}

const mdmPatientTypes = new Map<MdmPatientType, string>()
    .set(MdmPatientType.Established, 'Established Patient');

export const CreateHistoryAndPhysicalFollowUpNoteComponent: React.FC<CreateHistoryAndPhysicalNoteComponentProps> = (props: CreateHistoryAndPhysicalNoteComponentProps) => {
    const {
        patientId,
        appointmentId = null,
        appointmentConfiguration,
        handleGoBack,
        note,
        originalNote
    } = props;

    const classes = useStyles();

    const [
        state,
        handleExpandSection,
        handleTitleChanges,
        handleAppointmentDateChanges,
        handleAppointmentChanges,
        handleChangeDuration,
        handleHpiChanges,
        handlePmhItemChanges,
        handleAddMedications,
        handleAddSupplements,
        handleFullscriptSupplements,
        handleEditMedications,
        handleEditSupplements,
        handleRemoveMedications,
        handleRemoveSupplements,
        handleMedicationStopped,
        handleSupplementStopped,
        handleEnableRos,
        handleRosChanges,
        handleDisableVitals,
        handleVitalsChanges,
        handlePhysicalExamChanges,
        handleMdmTextChange,
        handleMdmPatientTypeChange,
        handleCodeChange,
        handleCategoryChange,
        handleCategoryItemChange,
        handleModeChange,
        handleSpentTimeChanges,
        handleDiagnosisChanges,
        handlePlanTextChanges,
        handleLabTextChanges,
        handleGoalsChanges,
        handleTestChanges,
        handleEducationChanges,
        handleNextAppointmentDateChanges,
        handleSaveAndComplete,
        handleSaveAsDraft,
        handleSignOff,
        handleDiscard,
        getIcdCodesAutoComplete,
        refs,
        handleCoachOtherTextChanges,
        handleTextInfoChange,

        handleToggleActions,
        handleEditRowValue,
        handleEditRowName,
        handleAddNewRow,
        handleDelete,
        handleEdit,
        handleBlur,
        saveValue,
        getIcdCodesAutoPmhComplete,
        handleAddInformation,
        handleEditNewItem,
        handleSaveNewItem,
        handleEditItem,
        isCanCompleteNote,
        handleValidateGoals,
        editPmhState,
        handleHideApoe,
        handleSelectedSoursApoe,
        handleEditApoe,
        handleDateColonoscopyChanges,
        handleDatePsaChanges,
    ] = useFacade(patientId, handleGoBack, appointmentId, appointmentConfiguration, note, originalNote);

    const isLoading = (state.areAllergiesLoading && state.areQuestionnaireResultsLoading) || state.isNoteContentLoading || state.isCommonGoalLoading || state.isCommonMdmLoading || state.isCommonSupplementLoading || state.isCommonOrderLoading;

    const sectionsContent = {
        [NoteSections.PMH]:
            <PmhComponent
                patientId={patientId}
                data={state.content.PMH}
                dataNew={state.content.PMHnew}
                newVersion={state.content?.pmhVersion === NotePmhVersion.New}
                map={state.map}
                handleChangesOldPmh={handlePmhItemChanges}

                getIcdCodesAutoComplete={getIcdCodesAutoPmhComplete}
                handleToggleActions={handleToggleActions}
                handleEditRowValue={handleEditRowValue}
                handleEditRowName={handleEditRowName}
                handleAddNewRow={handleAddNewRow}
                handleDelete={handleDelete}
                handleEdit={handleEdit}
                handleBlur={handleBlur}
                saveValue={saveValue}
                handleAddInformation={handleAddInformation}
                handleEditNewItem={handleEditNewItem}
                handleSaveNewItem={handleSaveNewItem}
                handleEditItem={handleEditItem}
                state={editPmhState}
                handleHideApoe={handleHideApoe}
                handleSelectedSoursApoe={handleSelectedSoursApoe}
                handleEditApoe={handleEditApoe}
                handleDateColonoscopyChanges={handleDateColonoscopyChanges}
                handleDatePsaChanges={handleDatePsaChanges}
            />,
        [NoteSections.MedicationsAndSupplements]:
            <SupplementsMedicationsComponent
                patientId={patientId}
                noteType={NotesType.HistoryAndPhysicalFollowUp}
                medications={state.content.medicationsSupplements.medications}
                supplements={state.content.medicationsSupplements.supplements}
                rxntMedications={state.content.medicationsSupplements.rxntMedications}
                fullscriptSupplements={state.content.medicationsSupplements.fullscriptSupplements}
                handleAddMedications={handleAddMedications}
                handleAddSupplements={handleAddSupplements}
                handleFullscriptSupplements={handleFullscriptSupplements}
                handleEditMedications={handleEditMedications}
                handleEditSupplements={handleEditSupplements}
                handleRemoveMedications={handleRemoveMedications}
                handleRemoveSupplements={handleRemoveSupplements}
                handleMedicationStopped={handleMedicationStopped}
                handleSupplementStopped={handleSupplementStopped}
            />,
        [NoteSections.ROS]:
            <RosComponent
                errors={state.errors}
                variants={state.rosVariants}
                selectedVariants={state.content.ROS.rosValues}
                rosEnabled={state.content.ROS.rosEnabled}
                handleEnableRos={handleEnableRos}
                handleChanges={handleRosChanges}
            />,
        [NoteSections.Physical]:
            <PhysicalComponent
                errors={state.errors}
                title={noteSectionsNames[NoteSections.Physical]}
                data={state.content.physicalData}
                handleNumericChanges={handleVitalsChanges}
                handleDisableVitals={handleDisableVitals}
                handlePhysicalExamChanges={handlePhysicalExamChanges}
            />,
        [NoteSections.MDM]:
            <NoteMdmComponent
                error={state.errors['mdmData']}
                mdms={state.mdmCodes}
                mdmText={state.content.mdmData.mdmText}
                mdmPatientTypes={mdmPatientTypes}
                selectedMdmPatientType={state.content.mdmData.selectedMdmPatientType}
                selectedCodeId={state.content.mdmData.selectedCodeId}
                showCodeDescription={state.content.mdmData.showCodeDescription}
                selectedCategoryIds={state.content.mdmData.selectedCategoryIds ?? [state.content.mdmData.selectedCategoryId]}
                selectedCategoryItems={state.content.mdmData.selectedCategoryItems}
                selectedMode={state.content.mdmData.selectedMode}
                spentTime={state.content.mdmData.spentTime}
                appointmentDuration={state.note?.appointment?.duration ?? state.content.duration.value}
                handleMdmTextChange={handleMdmTextChange}
                handleMdmPatientTypeChange={handleMdmPatientTypeChange}
                handleCodeChange={handleCodeChange}
                handleCategoryChange={handleCategoryChange}
                handleCategoryItemChange={handleCategoryItemChange}
                handleModeChange={handleModeChange}
                handleSpentTimeChanges={handleSpentTimeChanges}
            />,
        [NoteSections.Diagnosis]:
            <DiagnosisComponent
                error={state.errors['diagnosis']}
                addText='Add Diagnosis'
                selectedDiagnosis={state.content.diagnosis}
                getAutoComplete={getIcdCodesAutoComplete}
                grid={6}
                dataKey='diagnosis'
                handleChanges={handleDiagnosisChanges}
                handleTextInfoChange={handleTextInfoChange}
            />,
        [NoteSections.Plan]:
            <NotePlanComponent
                notesType={NotesType.HistoryAndPhysicalFollowUp}
                patientId={patientId}
                handleValidateGoals={handleValidateGoals}
                data={state.content.plan}
                visitDate={state.appointmentDate}
                handlePlanTextChanges={handlePlanTextChanges}
                handleLabTextChanges={handleLabTextChanges}
                handleGoalsChanges={handleGoalsChanges}
                handleCoachOtherTextChanges={handleCoachOtherTextChanges}
                handleEducationChanges={handleEducationChanges}
            />,
        [NoteSections.SpecialTests]:
            <SpecialtyTestsComponent
                specialTests={state.content.specialTests ?? []}
                handleTestUpdates={handleTestChanges}
            />,
        [NoteSections.NextAppointment]:
            <NextAppointmentDateComponent
                note={state.note}
                noteType={NotesType.HistoryAndPhysicalFollowUp}
                patientId={patientId}
                appointments={[]}
                handleChanges={handleNextAppointmentDateChanges}
            />
    } as { [id: number]: JSX.Element }

    const renderSections = (): JSX.Element => {
        return (
            <>
                {
                    Object.keys(sectionsContent).map(key => (
                        <Box id={`history-and-physical-note-section-${key}`} mt={3}>
                            <ExpandableComponent
                                title={noteSectionsNames[key]}
                                content={sectionsContent[key]}
                                expanded={state.sections[key]}
                                refCurrent={Array.from(refs.values()).find(x => x[1].toString() === key)?.[0]}
                                handleExpand={() => handleExpandSection(+key)}
                            />
                        </Box>
                    ))
                }
            </>
        );
    }

    const renderHpi = () => {
        return (
            <Box mt={3}>
                <Box ml={2}>
                    <span className={classes.subTitle}>HPI</span>
                </Box>
                <Box my={1}>
                    <Divider />
                </Box>
                <Box mt={3}>
                    <TextFieldNoteComponent
                        rows={3}
                        id="hpi-follow-up-notes"
                        title=''
                        content={state.content.HPI}
                        handleChanged={handleHpiChanges}
                        handleKeyDown={handleShortcutInsert}
                    />
                </Box>
            </Box>
        )
    }

    const renderContent = (): JSX.Element => {
        if (isLoading) {
            return <WildHealthLinearProgress />
        }

        return (
            <Box m={5}>
                <NoteGeneralInfoComponent
                    name="History and Physical"
                    patientId={patientId}
                    note={state.note}
                    duration={state.content.duration}
                    visitDate={state.appointmentDate}
                    title={state.title}
                    selectedTargetType={state.selectedTargetType}
                    handleTitleChanges={handleTitleChanges}
                    handleAppointmentDateChanges={handleAppointmentDateChanges}
                    handleAppointmentChanges={handleAppointmentChanges}
                    handleChangeDuration={handleChangeDuration}
                    classes={classes}
                />
                {
                    renderHpi()
                }
                {
                    renderSections()
                }
                {
                    !state.isProcessing &&
                    <Box mt={5} pb={5} display='flex' alignItems='center' justifyContent="space-between">
                        <Box display="flex">
                            <Box>
                                <WildHealthButton
                                    width={195}
                                    size="large"
                                    style={{ whiteSpace: 'nowrap' }}
                                    disabled={isFutureAppointment(state.note) || state.note?.isCompleted || state.isProcessing || !isCanCompleteNote()}
                                    id='sing-physical-history'
                                    onClick={() => handleSaveAndComplete()}
                                >
                                    <span>Sign and Complete</span>
                                </WildHealthButton>
                            </Box>
                            <Box ml={2}>
                                <WildHealthButton
                                    width={151}
                                    style={{ whiteSpace: 'nowrap' }}
                                    size="large"
                                    disabled={state.note?.isCompleted || state.isProcessing}
                                    id='sing-physical-history'
                                    color="secondary"
                                    onClick={() => handleSaveAsDraft()}
                                >
                                    <span>Save as Draft</span>
                                </WildHealthButton>
                            </Box>
                            <FeatureComponent featureFlag={FeatureFlag.SignOffNotes}>
                                <Box ml={2}>
                                    <WildHealthButton
                                        width={151}
                                        style={{ whiteSpace: 'nowrap' }}
                                        size="large"
                                        disabled={state.note?.isCompleted || state.isProcessing || !state.note}
                                        id='sing-physical-history'
                                        color="secondary"
                                        onClick={() => handleSignOff()}
                                    >
                                        <span>Send for Approval</span>
                                    </WildHealthButton>
                                </Box>
                            </FeatureComponent>
                        </Box>
                        <Box>
                            <WildHealthButton
                                size="large"
                                width={111}
                                disabled={state.isProcessing || !canDiscardNote(state.note)}
                                id='cancel-physical-history'
                                onClick={() => { handleDiscard() }}
                                color='tertiary'
                            >
                                Discard
                            </WildHealthButton>
                        </Box>
                    </Box>
                }
                {
                    state.isProcessing &&
                    <Box display="flex" justifyContent="center">
                        <Box>
                            <CircularProgress size={24} style={{ color: colors.main }} />
                        </Box>
                    </Box>
                }
            </Box>
        )
    }

    return (
        <Box>
            <Box className={classes.bgMain}>
                <BackTitleComponent
                    title='Back to Notes'
                    action={() => { handleGoBack() }}
                />
            </Box>
            {
                renderContent()
            }
            <SelectShortcutComponent />
            <SignOffDialogComponent />
        </Box>
    );
}
