import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: "#999999",
      [theme.breakpoints.down("xs")]: {
        fontSize: 12,
      },
    },
    pagesContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      [theme.breakpoints.down("xs")]: {
        justifyContent: "space-between",
        width: "100%",
      },
    },
    pagePagination: {
      "& ul > li:not(:first-child):not(:last-child) > button:not(.Mui-selected)": {
        display: "none",
      },
    },
    selectedPage: {
      color: "#3982ad",
      backgroundColor: "#f2f6f8",
      maxWidth: "64px",
      minWidth: "0px",
      width: "36px",
    },
    regularPage: {
      color: "#c3cbd7",
      maxWidth: "64px",
      minWidth: "0px",
      width: "36px",
    },
  })
);
