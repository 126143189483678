import {NotesType} from "../models/notes.models";

export const noteTypeNames = new Map<NotesType, string>()
    .set(NotesType.Initial, 'Initial Coaching Consult')
    .set(NotesType.Blank, 'Blank')
    .set(NotesType.FollowUp, 'Follow-Up')
    .set(NotesType.Internal, 'Internal Note')
    .set(NotesType.HistoryAndPhysicalInitial, 'History And Physical Initial Note')
    .set(NotesType.HistoryAndPhysicalFollowUp, 'History And Physical Follow-Up Note')
    .set(NotesType.HistoryAndPhysicalGroupVisit, 'History And Physical Group Visit Note')
    .set(NotesType.Soap, 'SOAP Note');