import React from "react";
import { AuthenticatedLayoutComponent } from "../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent";
import { PagesNamesService } from "../../../constants/pagesNames.constants";
import { TitlesService } from "../../../constants/title.constants";
import { MenuItemTypes } from "../../../models/menu.models";

import { HealthPlanComponent } from "../components/HealthPlan/HealthPlanComponent";

export const PatientHealthPlanPage: React.FC = () => {
    return (
        <AuthenticatedLayoutComponent
            hideBackground
            title={TitlesService.healthPlanTitle}
            pageName={PagesNamesService.healthPlan}
            selectedMenuItem={MenuItemTypes.HealthPlan}
        >
            <HealthPlanComponent />
        </AuthenticatedLayoutComponent>
    )
}
