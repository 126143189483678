import React from "react";
import {Box} from "@material-ui/core";
import {handleShortcutInsert} from "../../../common/helpers/handle-shortcut-insert";
import {TextFieldNoteComponent} from "../textFieldNoteComponent/TextFieldNoteComponent";
import {useStyles} from "./EducationsComponent.styles";

export interface EducationsComponentProps {
    dataKey: string;
    education: string;
    handleEducationChanges: (key: string, education: string) => void;
}

export const EducationsComponent: React.FC<EducationsComponentProps> = (props: EducationsComponentProps) => {
    let {
        dataKey,
        education,
        handleEducationChanges,
    } = props;

    const classes = useStyles();

    const handleChanges = (value: string) => {
        handleEducationChanges(dataKey, value);
    }

    return (
        <Box py={2}>
            <TextFieldNoteComponent
                id={`education-text-input-${dataKey}`}
                rows={3}
                title='ADD NEW EDUCATION'
                className={classes.subtitle}
                content={education ?? ''}
                handleChanged={handleChanges}
                handleKeyDown={handleShortcutInsert}
            />
        </Box>
    );
}