import { ReactComponent as SaveIcon } from '@img/icons/Save.svg';
import { Box, FormControlLabel } from '@material-ui/core';
import React, { useState } from 'react';
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { WildHealthSwitcher } from "../../../common/components/WIldHealthSwitcher";
import { shortcutView } from "../../../common/helpers/view-text-with-link";
import { insertString } from "../../../common/helpers/insert-string";
import { getFullRecommendationContent, HealthReportMode, RecommendationModel } from "../../models/healthReport.models";
import { selectShortcutService } from "../../services/selectShortcut.service";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { useStyles } from "./HealthRecommendationComponent.styles";
import clsx from "clsx";
import {createTextWithLinks} from "../../../common/helpers/create-text-with-links";
import { authQuery } from "../../../auth/stores/auth";
import { UserType } from "../../../auth/models/auth.enums";
import {sanitizeRecommendationContent} from "../../helpers/sanitizeRecommendationContent";

enum HealthRecommendationMode {
    AutoGenerated = 1,
    ManuallyEntered = 2
}

interface HealthRecommendationComponentProps {
    recommendation: RecommendationModel;
    handleContentChanges: Function;
    handleManualContentChanges: Function;
    handleSaveChanges: Function;
    isChanged: boolean;
    mode: HealthReportMode;
    rows: number;
}

export const HealthRecommendationComponent: React.FC<HealthRecommendationComponentProps> = (props: HealthRecommendationComponentProps) => {
    const {
        recommendation,
        handleContentChanges,
        handleManualContentChanges,
        handleSaveChanges,
        isChanged,
        mode,
        rows
    } = props;

    const [recommendationMode, setRecommendationMode] = useState(HealthRecommendationMode.AutoGenerated);
    const classes = useStyles();

    const handleInputModeToggle = () => {
        switch (recommendationMode) {
            case HealthRecommendationMode.ManuallyEntered: setRecommendationMode(HealthRecommendationMode.AutoGenerated); break;
            case HealthRecommendationMode.AutoGenerated: setRecommendationMode(HealthRecommendationMode.ManuallyEntered); break;
            default: break;
        }
    }

    const renderPlaceholder = () => {
        return <WildHealthPlaceHolder height={`${rows * 30}px`} message={"No recommendations provided"} />
    }

    const renderRecommendation = (mode: HealthReportMode) => {
        switch (mode) {
            case HealthReportMode.EditMode: return renderEditableRecommendation();
            case HealthReportMode.ReadonlyMode: return renderReadonlyRecommendation();
            case HealthReportMode.PrintMode: return renderPrintableRecommendation();
            default: return renderReadonlyRecommendation();
        }
    }

    const renderEditableRecommendation = () => {
        return (
            <Box width={1}>
                <Box display="flex" justifyContent="space-between" alignItems='center'>
                    <Box>
                        <WildHealthButton
                            id="health-recommendation-save"
                            onClick={() => handleSaveChanges()}
                            disabled={!isChanged}>
                            <Box>
                                <SaveIcon />
                            </Box>
                            <Box ml={2} className={classes.saveChanges}>
                                Save changes
                            </Box>
                        </WildHealthButton>
                    </Box>
                    <Box display="flex">
                        <Box>
                            Auto
                        </Box>
                        <Box ml={3}>
                            <FormControlLabel
                                control={<WildHealthSwitcher
                                    checked={recommendationMode === HealthRecommendationMode.ManuallyEntered}
                                    onChange={() => handleInputModeToggle()}
                                    name="inputsModeToggle" />
                                }
                                label=""
                            />
                        </Box>
                        <Box>
                            Manual
                        </Box>
                    </Box>
                </Box>

                <Box width={1}>
                    {
                        recommendationMode === HealthRecommendationMode.AutoGenerated &&
                        <textarea
                            id = "recommendation-auto-text-area"
                            rows={rows - 2}
                            className={classes.recommendation}
                            value={sanitizeRecommendationContent(recommendation?.content ?? '')}
                            onKeyDown={(e) => {handleOnKeyDownAuto(e, "recommendation-auto-text-area")}}
                            onChange={(e) => {contentOnChanges(e.target.value)}}>

                        </textarea>
                    }
                    {
                        recommendationMode === HealthRecommendationMode.ManuallyEntered &&
                        <textarea
                            id = "recommendation-manual-text-area"
                            rows={rows - 2}
                            className={classes.recommendation}
                            value={sanitizeRecommendationContent(recommendation?.manualContent ?? '')}
                            onKeyDown={(e) => {handleOnKeyDownManual(e, "recommendation-manual-text-area")}}
                            onChange={(e) => {
                                manualContentOnChanges(e.target.value);
                            }}>

                        </textarea>
                    }
                </Box>
            </Box>
        )
    }

    const renderReadonlyRecommendation = () => {
        if (!recommendation?.content?.length && !recommendation?.manualContent?.length) {
            return renderPlaceholder();
        }

        const recommendationContent = getFullRecommendationContent(recommendation);

        return (
            recommendationContent && recommendationContent.match(/[^\r\n]+/g) &&
            <Box className={clsx(classes.recommendation, classes.readonly)} height={`${rows * 30}px`}>
                {
                    recommendationContent.match(/[^\r\n]+/g)?.map((item, index) =>
                        <div key={index} className={classes.textWrap} dangerouslySetInnerHTML={{ __html: `${createTextWithLinks(shortcutView(item))}` }} />
                    )
                }
            </Box>
        )
    }

    const renderPrintableRecommendation = () => {
        if (!recommendation || !recommendation?.printableContent?.length) {
            return renderPlaceholder();
        }

        return (
            <div className={classes.printable} style={{ height: `${rows * 30}px` }}>
                {sanitizeRecommendationContent(recommendation.printableContent ?? '')}
            </div>
        )
    }

    const handleOnKeyDownAuto = (event:any, id: string) => {
        if (event.keyCode === 51 && event.altKey) {
            const userType = authQuery.getType();
            if (UserType.Employee === userType) {
                const value = (document.getElementById(id) as any).value;
                const cursorPos = (document.getElementById(id) as any).selectionStart;
                setTimeout(() => {
                        selectShortcutService.select().subscribe((data) => {
                            data && handleContentChanges(insertString(value, data, cursorPos + 1))   
                        })
                }, 1)
            }
        }
    }
    
    const handleOnKeyDownManual = (event:any, id: string) => {
        if (event.keyCode === 51 && event.altKey) {
            const userType = authQuery.getType();
            if (UserType.Employee === userType) {
                const value = (document.getElementById(id) as any).value;
                const cursorPos = (document.getElementById(id) as any).selectionStart;
                setTimeout(() => {
                        selectShortcutService.select().subscribe((data) => {
                            data && handleManualContentChanges(insertString(value, data, cursorPos + 1))   
                        })
                }, 1)
            }
        }
    }
    


    const contentOnChanges = (value: string) => {
            handleContentChanges(value);
    }

    const manualContentOnChanges = (value: string) => {
        handleManualContentChanges(value);
    }

    return (
        <>
            <Box className={classes.fullwidth}>
                {renderRecommendation(mode)}
            </Box>
        </>
    )
}
