import React from "react";
import { Box, Checkbox, FormControlLabel, Grid, TextField } from "@material-ui/core";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { UpdateAddressForm } from "../../../common/components/updateAddressForm/UpdateAddressForm";
import { useFacade } from "./practiceAccountComponent.hooks";
import { phoneNumberInput } from "../../../common/components/PhoneNumberInput";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { colors } from "../../../common/constants/colors";
import commonUseStyles from '../../../common/styles/common.styles';
import { FeatureComponent } from "../../../common/components/featureFlags/FeatureComponent";
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";

export const PracticeAccountComponent: React.FC = () => {
    const [
        {
            isLoading,
            isSubmitted,
            practice,
            errors
        },
        handleChanges,
        handleSubmit
    ] = useFacade();
    const commonClasses = commonUseStyles();

    if (isLoading) {
        return (
            <Box p={5}>
                <WildHealthLinearProgress />
            </Box>
        )
    }

    const controlIcon = <CheckBoxOutlineBlankIcon style={{ fill: colors.main }} />;
    const controlCheckedIcon = <CheckBoxIcon style={{ fill: colors.main }} />;

    return (
        <Box p={5}>
            <Grid container spacing={3}>
                <Grid item md={6} sm={12}>
                    <Box className={commonClasses.primaryTitle}>
                        Practice Information
                    </Box>

                    <Box mt={3} width={1}>
                        <TextField
                            required
                            fullWidth
                            size="small"
                            variant="outlined"
                            value={practice.name}
                            label="Practice Name"
                            error={!!errors['name']}
                            helperText={errors['name']}
                            onChange={(v) => handleChanges('name', v.target.value)}
                        />
                    </Box>

                    <Box mt={2}>
                        <UpdateAddressForm prefix={'address.'} address={practice.address} errors={errors} handleChanges={handleChanges} />
                    </Box>

                    <Box mt={2} width={1}>
                        <Box className={commonClasses.subtitle}>Phone Number</Box>

                        <TextField
                            required
                            fullWidth
                            size="small"
                            variant="outlined"
                            value={practice.phoneNumber}
                            error={!!errors['phoneNumber']}
                            helperText={errors['phoneNumber']}
                            InputProps={{
                                className: 'input',
                                inputComponent: phoneNumberInput,
                            }}
                            onChange={(v) => handleChanges('phoneNumber', v.target.value)}
                        />
                    </Box>

                    <Box mt={2} width={1}>
                        <Box className={commonClasses.subtitle}>Practice Email</Box>

                        <TextField
                            required
                            fullWidth
                            size="small"
                            variant="outlined"
                            value={practice.email}
                            error={!!errors['email']}
                            helperText={errors['email']}
                            onChange={(v) => handleChanges('email', v.target.value)}
                        />
                    </Box>

                    <Box pt={5}>
                        <WildHealthButton id="practice-account-save" loading={isSubmitted} onClick={(event) => handleSubmit(event)}>
                            Save
                        </WildHealthButton>
                    </Box>
                </Grid>

                <Grid item md={6} sm={12}>
                    <FeatureComponent featureFlag={FeatureFlag.EnabledRegistration}>
                        <Box className={commonClasses.primaryTitle}>
                            Preferred URL
                        </Box>

                        <Box width={1} mt={3} display="flex" justifyContent="flex-start">
                            <Box display="flex" mt={1.3}>
                                <Box className="text-medium">
                                    app.wildhealth.com/join/
                                </Box>
                            </Box>
                            <Box ml={1} minWidth='120px' alignItems="center">
                                <TextField
                                    required
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    value={practice.preferredUrl}
                                    label="Preferred Url"
                                    error={!!errors['preferredUrl']}
                                    helperText={errors['preferredUrl']}
                                    onChange={(v) => handleChanges('preferredUrl', v.target.value)}
                                />
                            </Box>
                        </Box>
                        </FeatureComponent>

                    <Box mt={3}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    icon={controlIcon}
                                    color="primary"
                                    checkedIcon={controlCheckedIcon}
                                    checked={practice.hideAddressOnSignUp}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChanges('hideAddressOnSignUp', e.target.checked)}
                                />
                            }
                            label={<Box component="div" fontWeight={400}>Hide Address On Patient Sign-up Page</Box>}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}