import {
    Box,
    Grid,
    Table,
    TableBody,
    TableContainer,
    TableFooter,
    TableHead,
    Tooltip,
    Popover,
    MenuItem,
    Button,
    IconButton,
    Dialog,
    DialogContent,
    DialogTitle,
} from "@material-ui/core";
import {ArrowBack} from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import DateRangeIcon from "@material-ui/icons/DateRange";
import React, {useState} from "react";
import {useHistory} from "react-router";
import {navigationService} from "../../../../services/navigation.service";
import {
    SelectDataDialogComponent
} from "../../../common/components/selectDataSetDialogComponent/SelectDataDialogComponent";
import {SelectViewModeComponent} from "../../../common/components/selectViewModeComponent/SelectViewModeComponent";
import {BackButton} from "../../../common/components/wildHealthButton/BackButton";
import {OptionsButton} from "../../../common/components/wildHealthButton/OptionsButton";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import {CommonPlaceHolder} from "../../../common/components/wildHealthPlaceHolder/CommonPlaceHolder";
import {WildHealthTableCell} from "../../../common/components/wildHealthTable/WildHealthTableCell";
import {WildHealthTableRow} from "../../../common/components/wildHealthTable/WildHealthTableRow";
import {PaginationComponent} from "../../../common/pagination/components/paginationComponent/PaginationComponent";
import {getAvailablePages} from "../../../common/pagination/helpers/get-evailable-pages";
import {
    SelectDataRangePopoverComponent
} from "../../../common/components/selectDataRangePopoverComponent/SelectDataRangePopoverComponent";
import {VitalRowComponent} from "../vitalRowComponent/VitalRowComponent";
import {useFacade} from "./vitalsHistoryComponentMobile.hooks";
import {VitalsHistoryMode, VitalsHistoryView} from "../../stores";
import {useStyles, VitalsTextField} from "./vitalsHistoryComponentMobile.styles";
import {VitalModel, VitalValueModel} from "../../models/vital.model";
import {mediumPageSizes} from "../../../common/pagination/models/page-sizes";
import clsx from "clsx";
import {vitalService} from "../../services/vital.service";
import {VitalsNames, vitalsValidator} from "../../validators/vital.validator";
import {
    useStyles as useCheckboxDropdownStyles
} from "../../../common/components/checkBoxDropdown/checkboxDropdown.styles";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import {ReactComponent as EditIcon} from "@img/icons/edit.svg";

export interface VitalsHistoryComponentProps {
    patientId: number | null;
    isDataSetDialogOpen: boolean;
}

export const VitalsHistoryComponentMobile: React.FC<VitalsHistoryComponentProps> = (
    props: VitalsHistoryComponentProps
) => {
    const [
        state,
        columns,
        switchMode,
        switchView,
        handleCancel,
        handleSaveChanges,
        handleToggleDataSetDialog,
        handleAddDataSet,
        handleEditVitalValue,
        handlePageSizeChange,
        handlePageChange,
        handlePopoverOpen,
        handlePopoverClose,
        handleSelectDateRangeToggle,
        handleDateRangeChange,
        handleEditVitalItem,
    ] = useFacade(props.patientId, props.isDataSetDialogOpen);

    const {
        isLoading,
        isSubmitting,
        isChanged,
        canAddNewDataSet,
        mode,
        view,
        isDataSetDialogOpen,
        items,
        popovers,
        startDate,
        endDate,
        isSelectDateRangeOpen,
        selectDateRangeAnchor,
        errors,
        itemEditDate,
        itemEditIndex,
    } = state;

    const classes = useStyles();
    const checkboxDropdownClasses = useCheckboxDropdownStyles();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState(null);
    const hasItems = items && items.length > 0 && items[0].values?.length > 0;

    const viewModes = [
        {
            id: VitalsHistoryView.Graph,
            title: "Graph View",
        },
        {
            id: VitalsHistoryView.Values,
            title: "Values View",
        },
    ];

    const renderAddNewDataSet = () => {
        const button = (
            <OptionsButton
                id='vitals-history-set-add-new-data'
                className={clsx(checkboxDropdownClasses.placeHolder, {
                    [checkboxDropdownClasses.placeHolderOpen]: isDataSetDialogOpen,
                })}
                onClick={() => handleToggleDataSetDialog(true)}
                disabled={isSubmitting || isLoading || !canAddNewDataSet}
                variant='outlined'
                size='small'
            >
                <Box display='flex' alignItems='center'>
                    <Box ml={1}>
                        <AddIcon fontSize='small' className={classes.MenuItemicon}/>
                    </Box>
                    <Box ml={1}>
                        <span className={classes.menuItemTitle}>Add New Data Set</span>
                    </Box>
                </Box>
            </OptionsButton>
        );

        return canAddNewDataSet ? (
            button
        ) : (
            <Tooltip title='Please save or discard changes to proceed with adding new data set'>
                <span>{button}</span>
            </Tooltip>
        );
    };

    const renderSetDateRanges = () => {
        return (
            <Box>
                <OptionsButton
                    id='vitals-history-set-date-range'
                    className={clsx(checkboxDropdownClasses.placeHolder, {
                        [checkboxDropdownClasses.placeHolderOpen]: isSelectDateRangeOpen,
                    })}
                    onClick={(e) => handleSelectDateRangeToggle(e.currentTarget)}
                    disabled={isSubmitting || isLoading}
                    variant='outlined'
                    size='small'
                    fullWidth
                >
                    <Box display='flex' alignItems='center'>
                        <Box ml={1}>
                            <DateRangeIcon fontSize='small' className={classes.MenuItemicon}/>
                        </Box>
                        <Box ml={1}>
                            <span className={classes.menuItemTitle}>Set Date Ranges</span>
                        </Box>
                    </Box>
                </OptionsButton>
            </Box>
        );
    };

    const renderGoBack = () => {
        return (
            <Box className={classes.backButton} pb={2}>
                <BackButton
                    id='vitals-history-go-back'
                    onClick={() => navigationService.goBack(history)}
                >
                    <Box display='flex' alignItems='center'>
                        <ArrowBack fontSize='small'/>
                        <Box ml={1}>
                            <span>Back to Dashboard</span>
                        </Box>
                    </Box>
                </BackButton>
            </Box>
        );
    };

    const renderMenuPopOver = () => {
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        return (
            <Box>
                <Button id='vital-history-mobile-menu-toggle-btn' onClick={handleClick}>
                    <MoreHorizIcon/>
                </Button>
                <Popover
                    keepMounted
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                >
                    <MenuItem
                        id={`select-menu-item-add-new-data-set`}
                        key={0}
                        onClick={handleClose}
                    >
                        {renderAddNewDataSet()}
                    </MenuItem>
                    <MenuItem id={`select-menu-item-set-sate-ranges`} key={1} onClick={handleClose}>
                        {renderSetDateRanges()}
                    </MenuItem>
                </Popover>
            </Box>
        );
    };

    const renderOptions = () => {
        return (
            <Box py={2} display='flex' justifyContent='space-between' alignItems='center'>
                <Box pr={1}>
                    <SelectViewModeComponent
                        selectedId={view}
                        options={viewModes}
                        disabled={isLoading || !hasItems || isSubmitting}
                        handleChanges={(id: number) => switchView(id as VitalsHistoryView)}
                    />
                </Box>
                {renderMenuPopOver()}
            </Box>
        );
    };

    const getVitals = () => {
        const vitals: Array<VitalModel[]> = [];

        items.forEach((vital) => {
            if (!vitals.map((i) => i[0].displayName).includes(vital.displayName)) {
                vitals.push([vital]);
            } else {
                const item = vitals.find((i) => i[0].displayName === vital.displayName);
                item.push(vital);
            }
        });

        return vitals;
    };

    const renderTable = () => {
        if (isLoading) {
            return (
                <Box p={2}>
                    <WildHealthLinearProgress/>
                </Box>
            );
        }

        if (!hasItems) {
            return <CommonPlaceHolder message='No vitals history in this period.'/>;
        }

        const groupedVitals = getVitals();

        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <WildHealthTableRow>
                            {columns.map((item, index) => (
                                <WildHealthTableCell key={index} className={classes.cell}>
                                    <Box display='flex' alignItems='center'>
                                        {item}
                                        {view === 1 && index !== 0 && hasItems && item && (
                                            <>
                                                {" "}
                                                <IconButton
                                                    id='vitals-history-edit'
                                                    onClick={() => {
                                                        switchMode(VitalsHistoryMode.Edit);
                                                        handleEditVitalItem(item, index - 1);
                                                    }}
                                                    className={classes.editIcon}
                                                >
                                                    <EditIcon/>
                                                </IconButton>
                                                ,
                                            </>
                                        )}
                                    </Box>
                                </WildHealthTableCell>
                            ))}
                        </WildHealthTableRow>
                    </TableHead>
                    <TableBody>
                        {groupedVitals.map((vitals, index) => {
                            let onChangeCB = (value) => value;

                            // if (vitals[0].name === VitalsNames.Weight) {
                            //     const bmi = groupedVitals.find((i) => i[0].name === VitalsNames.BMI);
                            //     const bmiFormulaValue = 703;
                            //     onChangeCB = (value: string, index?: number) => {
                            //         if (height <= 0 || !Number(value)) {
                            //             handleEditVitalValue(bmi[0].id, bmi[0].values[index].id, "");
                            //         } else {
                            //             const newBmi =
                            //                 (Number(value) / Math.pow(height, 2)) * bmiFormulaValue;
                            //             handleEditVitalValue(
                            //                 bmi[0].id,
                            //                 bmi[0].values[index].id,
                            //                 newBmi.toString().slice(0, 5)
                            //             );
                            //         }
                            //     };
                            // }

                            return (
                                <React.Fragment key={index}>
                                    <VitalRowComponent
                                        index={index}
                                        vitals={vitals}
                                        view={view}
                                        mode={mode}
                                        errors={errors}
                                        onChangeCB={onChangeCB}
                                        handleChanges={handleEditVitalValue}
                                        popoverProps={{
                                            popoverAnchorEl: popovers.find((x) => x.id === vitals[0].id)
                                                ?.anchor,
                                            handlePopoverOpen: handlePopoverOpen,
                                            handlePopoverClose: handlePopoverClose,
                                        }}
                                    />
                                </React.Fragment>
                            );
                        })}
                    </TableBody>
                    {mode !== VitalsHistoryMode.Edit && (
                        <TableFooter>
                            <WildHealthTableRow style={{backgroundColor: "white"}}>
                                <WildHealthTableCell colSpan={11}>
                                    <PaginationComponent
                                        pageSizes={mediumPageSizes}
                                        pageSize={state.pageSize}
                                        selectedPage={state.selectedPage}
                                        handlePageSizeChange={handlePageSizeChange}
                                        totalCount={state.totalCount}
                                        availablePages={getAvailablePages(state)}
                                        handlePageSelect={handlePageChange}
                                    />
                                </WildHealthTableCell>
                            </WildHealthTableRow>
                        </TableFooter>
                    )}
                </Table>
            </TableContainer>
        );
    };

    const renderEditDialog = () => {
        const groupedVitals = getVitals();

        return (
            <Dialog
                fullWidth
                fullScreen
                open={mode === VitalsHistoryMode.Edit}
                onClose={() => switchMode(VitalsHistoryMode.Normal)}
                aria-labelledby='edit-dialog'
                PaperProps={{
                    style: {borderRadius: 10},
                }}
                className={classes.dialog}
            >
                <DialogTitle id='dialog-title'>
                    <Box display='flex' justifyContent='space-between' alignItems='flex-start'>
                        <Button
                            id='vitals-history-cancel'
                            disabled={isSubmitting}
                            onClick={() => {
                                switchMode(VitalsHistoryMode.Normal);
                                handleCancel();
                            }}
                            className={classes.dialogButtonText}
                        >
                            Cancel
                        </Button>
                        <Box display='flex' flexDirection='column' alignItems='center'>
                            Edit Vitals
                            <Box className={classes.dialogDate}>{itemEditDate}</Box>
                        </Box>
                        <Box>
                            <Button
                                id='vitals-history-save'
                                disabled={
                                    isSubmitting || !isChanged || !vitalsValidator.stateIsValid(state)
                                }
                                onClick={() => handleSaveChanges()}
                                className={classes.dialogButtonText}
                            >
                                Save Changes
                            </Button>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    {" "}
                    <Grid container spacing={2}>
                        {groupedVitals.map((vitals) => {
                            let onChangeCB = (value) => value;

                            return <>{renderEditValues(vitals, handleEditVitalValue, onChangeCB)}</>;
                        })}
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    };

    const renderEditValues = (vitals, handleChanges, onChangeCB) => {
        const inputProps = () => {
            return {
                min: 0,
                style: {
                    padding: "8px 5px",
                },
                onKeyDown: (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault(),
            };
        };

        const vital = vitals[0];

        const vitalName = (
            <Box mb={1} className={classes.vitalDisplayName}>
                {vital.displayName}
                <span>
          &nbsp;
                    {vital.dimension && `(${vital.dimension === '"' ? "in" : vital.dimension})`}
        </span>
            </Box>
        );

        let vitalItem = [vitalName];

        const getValue = (item: VitalValueModel, vitalId: number, index: number) => {
            const error = errors[`${vitals.find((i) => i.id === vitalId).name}${item.id}`];

            const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value;
                handleChanges(vitalId, item.id, value);
                onChangeCB(value, index);
            };

            return (
                <React.Fragment key={vitalId}>
                    <VitalsTextField
                        fullWidth
                        type='number'
                        size='small'
                        variant='outlined'
                        value={item.value ?? ""}
                        error={!!error}
                        helperText={error}
                        InputProps={{
                            inputProps: inputProps(),
                            readOnly: item.name === VitalsNames.BMI,
                        }}
                        onChange={onChange}
                    />
                </React.Fragment>
            );
        };

        vitalItem.push(
            <Box display='flex' alignItems='flex-start' maxWidth={268}>
                {vitals
                    .map((x, index) => getValue(x.values[itemEditIndex], x.id, index))
                    .reduce((a, c) => (!a.length ? [c] : [...a, <Box p={1}>/</Box>, c]), [])}
            </Box>
        );

        return (
            <Grid item xs={6}>
                {vitalItem}
            </Grid>
        );
    };

    const renderContent = () => {
        return (
            <>
                {renderOptions()}
                {mode === VitalsHistoryMode.Edit ? renderEditDialog() : renderTable()}

                <SelectDataDialogComponent
                    title='Add New Data Set'
                    message='Select Date For New Data Set'
                    actionName='Add Data Set'
                    open={isDataSetDialogOpen}
                    handleClose={() => handleToggleDataSetDialog(false)}
                    handleSubmit={(date: Date) => handleAddDataSet(date)}
                    handleCheckDate={(date: Date) => vitalService.checkDate(date, props.patientId)}
                    disableDates={[]}
                    disableFuture
                />

                <SelectDataRangePopoverComponent
                    isOpen={isSelectDateRangeOpen}
                    anchorEl={selectDateRangeAnchor}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(dates: Array<Date>) => handleDateRangeChange(dates[0], dates[1])}
                    handleClose={() => handleSelectDateRangeToggle()}
                />
            </>
        );
    };

    return (
        <Box>
            {renderGoBack()}
            {renderContent()}
        </Box>
    );
};
