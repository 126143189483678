import { Box, Divider, Grid } from '@material-ui/core';
import React from 'react';
import { AuthenticatedLayoutComponent } from '../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { TitlesService } from '../../../../constants/title.constants';
import { PaymentPlanDisplayComponent } from '../../../common/components/paymentPlanDisplayComponent/PaymentPlanDisplayComponent';
import { UpdateAddressForm } from "../../../common/components/updateAddressForm/UpdateAddressForm";
import { UpdateUserForm } from "../../../common/components/UpdateUserForm";
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { AssignEmployeesComponent } from "../../components/assignEmployeesComponent/AssignEmployeesComponent";
import { useFacade } from "./submitPatientPage.hooks";
import { PatientOptionsComponent } from "../../components/patientOptionsComponent/PatientOptionsComponent";
import { EmployeeType } from '../../models/employee.enums';
import { SelectLocationComponent } from '../../../locations/components/selectLocationComponent/SelectLocationComponent';
import { BackTitleComponent } from '../../../../components/pageTitleComponent/PageTitleComponent';
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from 'clsx';
import { OptionalComponent } from '../../../common/components/OptionalComponent';
import { Practice } from '../../../auth/models/auth.enums';

export const SubmitPatientPage: React.FC = (props: any) => {
    const patientId = props.match.params.patientId;
    const [
        {
            patient,
            employees,
            locations,
            isLoading,
            errors,
        },
        handleEmployeeSelect,
        handleOptionsChanges,
        handleChanges,
        handleSubmit,
        handleLocationChanges
    ] = useFacade(patientId);
    const commonClasses = commonUseStyles();

    const renderContent = () => {
        return (
            <Box p={5}>
                <Box mb={2} display="flex" justifyContent="space-between">
                    <Box>
                        <span className={clsx(commonClasses.primaryTitle, 'size-1_5')}>Create patient</span>
                        <Box mt={2}>
                            <span className="text-medium">Patient Demographics </span>
                        </Box>
                    </Box>
                </Box>

                <Divider className="divider" />

                <Box mt={4}>
                    <Grid container
                        spacing={3}
                        justify="space-between">

                        <Grid item xs={12} md={4} lg={4}>
                            <Box>
                                <Box my={2} className={commonClasses.primaryTitle}>
                                    Information:
                                </Box>
                                <UpdateUserForm
                                    user={patient}
                                    prefix={""}
                                    errors={errors}
                                    emailIsReadonly={false}
                                    handleChanges={handleChanges} />
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={2} lg={2}>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                            <Box mt={2}>
                                <SelectLocationComponent
                                    locationId={patient.locationId}
                                    locations={locations}
                                    handleChanges={handleLocationChanges} />
                            </Box>
                            <Box mt={2}>
                                <PaymentPlanDisplayComponent patient={patient} />
                            </Box>
                            <OptionalComponent practiceId={Practice.WildHealth}>
                                <Box>
                                    <Box pt={1.5}>
                                        <PatientOptionsComponent
                                            disabled={false}
                                            options={patient.options}
                                            optionName='isFellow'
                                            title='Fellowship Patient:'
                                            handleChanges={handleOptionsChanges}
                                        />
                                    </Box>
                                </Box>
                            </OptionalComponent>

                        </Grid>


                        <Grid item xs={12} md={6} lg={6}>
                            <Box>
                                <Box my={2} className={commonClasses.primaryTitle}>
                                    Billing address:
                                </Box>
                                <UpdateAddressForm
                                    address={patient.billingAddress}
                                    prefix={"billingAddress."}
                                    errors={errors}
                                    handleChanges={handleChanges} />
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                            <Box>
                                <Box my={2} className={commonClasses.primaryTitle}>
                                    Shipping address:
                                </Box>
                                <UpdateAddressForm
                                    address={patient.shippingAddress}
                                    prefix={"shippingAddress."}
                                    errors={errors}
                                    handleChanges={handleChanges} />
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={4} lg={4}>
                            <Box mt={3}>
                                <Box my={2} className={commonClasses.primaryTitle}>
                                    Assigned Coaches:
                                </Box>
                                <AssignEmployeesComponent
                                    disabled={false}
                                    selectedEmployeesIds={patient.assignedEmployees.map(el => el.id)}
                                    handleEmployeeSelect={handleEmployeeSelect}
                                    employees={employees.filter(c => c.type === EmployeeType.HealthCoach)}
                                />
                            </Box>
                            <Box mt={3}>
                                <Box my={2} className={commonClasses.primaryTitle}>
                                    Assigned Providers:
                                </Box>
                                <AssignEmployeesComponent
                                    disabled={false}
                                    selectedEmployeesIds={patient.assignedEmployees.map(el => el.id)}
                                    handleEmployeeSelect={handleEmployeeSelect}
                                    employees={employees.filter(c => c.type === EmployeeType.Provider)}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box mt={5} pt={3}>
                    <WildHealthButton
                        id="submit-patient-validate-assign"
                        onClick={() => handleSubmit()}
                    >
                        Validate and Assign
                    </WildHealthButton>
                </Box>
            </Box>
        )
    }

    const pageName = (
        <BackTitleComponent title={PagesNamesService.submitPatient} />
    );

    return (
        <AuthenticatedLayoutComponent
            pageName={pageName}
            title={TitlesService.submitPatientTitle}
        >
            {
                !patient || !locations.length || isLoading ?
                    <WildHealthLinearProgress /> :
                    renderContent()
            }
        </AuthenticatedLayoutComponent>
    )
}
