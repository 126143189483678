import { Box, Grid } from "@material-ui/core";
import React from "react";
import { useStyles } from "./wildHealthPlaceHolderBase.styles";

export interface WildHealthPlaceHolderBaseProps {
    message: string;
    children?: any;
}

export const WildHealthPlaceHolderBase: React.FC<WildHealthPlaceHolderBaseProps> = (props: WildHealthPlaceHolderBaseProps) => {
    const {
        message,
        children
    } = props;

    const classes = useStyles();

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.root}
        >
            <Box textAlign="center" width="inherit">
                <Box className={classes.message} textAlign="center">{message}</Box>
                <Box display="flex" justifyContent="center" className={classes.children}>{children}</Box>
            </Box>
        </Grid>
    );
}
