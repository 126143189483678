import { useState } from "react";
import { BaseAppointmentModel, EditAppointmentModel } from "../../models/appointments.models";
import { appointmentsService } from "../../services/appointments.service";
import { IErrorState } from "../../../common/validation/error-state";
import { editAppointmentDialogValidator } from "./editAppointmentDialogComponent.validator";


interface EditAppointmentDialogComponentState extends IErrorState {
    appointmentDraft: BaseAppointmentModel;
    isLoading: boolean;
}

export function useFacade(appointment: BaseAppointmentModel, handleClose: Function): [
    EditAppointmentDialogComponentState,
    (comment: string) => void,
    () => void
] {
    const [state, setState] = useState({
        appointmentDraft: appointment,
        isLoading: false,
        errors: {}
    } as EditAppointmentDialogComponentState);

    const handleEditComment = (comment: string) => {
        editAppointmentDialogValidator.validateAndSetState(state, setState, 'comment', comment);
        state.appointmentDraft.comment = comment;
        setState({ ...state, appointmentDraft: state.appointmentDraft })
    }

    const handleSubmit = () => {
        const editModel = {
            id: state.appointmentDraft.id,
            comment: state.appointmentDraft.comment
        } as EditAppointmentModel;

        editAppointmentDialogValidator.validateObjectAndSetState(state, setState, state.appointmentDraft);
        if (!editAppointmentDialogValidator.stateIsValid(state)) {
            return;
        }

        const cb = () => {
            setState({ ...state, isLoading: false });
            handleClose();
        }

        setState((state) => ({ ...state, isLoading: true }));
        appointmentsService.editAppointment(editModel).subscribe(cb, cb)
    }

    return [state, handleEditComment, handleSubmit];
}