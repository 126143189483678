import { Table, TableBody, TableContainer, TableFooter, TableHead, Box } from "@material-ui/core";
import { useHistory } from "react-router";
import React from 'react';
import { AuthenticatedLayoutComponent } from '../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { PagesNamesService } from "../../../constants/pagesNames.constants";
import { TitlesService } from '../../../constants/title.constants';
import { MenuItemTypes } from "../../../models/menu.models";
import WildHealthLinearProgress from '../../common/components/WildHealthLinearProgress';
import { useFacade } from "./AwaitingApprovalPage.hooks";
import { WildHealthTableRow } from "../../common/components/wildHealthTable/WildHealthTableRow";
import { WildHealthTableCell } from "../../common/components/wildHealthTable/WildHealthTableCell";
import { PaginationComponent } from "../../common/pagination/components/paginationComponent/PaginationComponent";
import { WildHealthLink, WildHealthLinkButton } from "../../common/components/WildHealthLinkButton";
import { WildHealthPlaceHolder } from "../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { RoutesConstants } from "../../../constants/route.constants";
import { pageSizes } from "../../common/pagination/models/page-sizes";
import { WildHealthButton } from "../../common/components/wildHealthButton/WildHealthButton";
import { navigationService } from "../../../services/navigation.service";
import { HealthReportOwnerModel } from "../../healthReport/models/healthReport.models";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { useStyles } from "./awaitingApprovalPage.styles";
import moment from "moment";
import { toCurrentTimeZone } from "../../timezones/helpers/timezone";

export const AwaitingApprovalPage: React.FC = () => {
    const [
        {
            isLoading,
            reports,
            totalCount,
            pageSize,
            selectedPage
        },
        handlePageSizeChange,
        getAllAvailablePages,
        handlePageChange,
        handlePublishReport,
    ] = useFacade();
    const _history = useHistory();
    const classes = useStyles();

    const redirectedToReport = (patient: HealthReportOwnerModel) => {
        navigationService.toPatientHealthReport(_history, patient.id)
    }

    const renderContent = () => {
        if (!reports || !reports.length) {
            return <WildHealthPlaceHolder message="No current records found" />
        }

        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <WildHealthTableRow>
                            <WildHealthTableCell style={{ width: '30%' }} >
                                Patient
                            </WildHealthTableCell>
                            <WildHealthTableCell style={{ width: '20%' }} >
                                Date Requested
                            </WildHealthTableCell>
                            <WildHealthTableCell style={{ width: '25%' }} >
                                Report Link
                            </WildHealthTableCell>
                            <WildHealthTableCell style={{ width: '25%' }} >
                                Action
                            </WildHealthTableCell>
                        </WildHealthTableRow>
                    </TableHead>
                    <TableBody>
                        {
                            reports.map((report, index) =>
                                <WildHealthTableRow key={index}>
                                    <WildHealthTableCell>
                                        <WildHealthLink
                                            id={`awaiting-approval-patient-profile-${report.owner.id}`}
                                            href={`${RoutesConstants.managePatientProfile}/${report.owner.id}`}
                                            content={`${report.owner.firstName} ${report.owner.lastName}`}
                                        />
                                    </WildHealthTableCell>
                                    <WildHealthTableCell>
                                        {moment(toCurrentTimeZone(new Date(report.status.date))).format("MM/DD/yyyy")}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell>
                                        <WildHealthLinkButton
                                            id={`awaiting-approval-health-report-${report.owner.id}`}
                                            onClick={() => redirectedToReport(report.owner)}
                                            content={<span className={classes.linkBtn}><Box pr={1}> Health Report</Box> <OpenInNewIcon /></span>}
                                        />
                                    </WildHealthTableCell>
                                    <WildHealthTableCell>
                                        <WildHealthButton
                                            id="awaiting-approval-reviewed"
                                            onClick={() => handlePublishReport(report.id, report.owner.id)}
                                        >
                                            Reviewed &amp; Sign Off
                                        </WildHealthButton>
                                    </WildHealthTableCell>
                                </WildHealthTableRow>
                            )
                        }
                    </TableBody>
                    <TableFooter>
                        <WildHealthTableRow style={{ backgroundColor: "white" }}>
                            <WildHealthTableCell colSpan={6}>
                                <PaginationComponent
                                    pageSizes={pageSizes}
                                    pageSize={pageSize}
                                    selectedPage={selectedPage}
                                    handlePageSizeChange={handlePageSizeChange}
                                    totalCount={totalCount}
                                    availablePages={getAllAvailablePages()}
                                    handlePageSelect={handlePageChange} />
                            </WildHealthTableCell>
                        </WildHealthTableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        )
    }

    return (
        <AuthenticatedLayoutComponent
            title={TitlesService.awaitingApprovalTitle}
            pageName={PagesNamesService.awaitingApproval}
            selectedMenuItem={MenuItemTypes.AwaitingApproval}
        >
            {
                isLoading
                    ? <WildHealthLinearProgress />
                    : renderContent()

            }
        </AuthenticatedLayoutComponent>
    )
}

