import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: "10px"
    },
    main: {
        maxWidth: 1128,
        margin: '0 auto',
    },
    subscriptionCard: {
        marginTop: 10,
        borderRadius: 5,
        border: 'none',
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    },
    cardDivider: {
        backgroundColor: "#f1f1f1",
        margin: 'auto',
        height: 3,
    },
    subscriptionItem: {
        marginTop: 20,
        fontFamily: 'OpenSans',
        fontSize: 18,
        lineHeight: 0.92,
        '& span': {
            fontWeight: 'bold',
        },
    },
    subscriptionItemSubtext: {
        marginTop: 5,
        fontFamily: 'OpenSans',
        fontSize: 15,
    },
    rightCardColumn: {
        paddingLeft: 20,
        borderLeft: '3px solid #f1f1f1',
        borderRadius: 1,
    },
    subscriptionAction: {
        margin: 10,
        paddingRight: 10,
        paddingLeft: 10,
        backgroundColor: '#537b98',
        color: '#ffffff',
    },

}));