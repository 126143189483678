import { Box } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { WildHealthRadio } from '../../../common/components/WildHealthRadio';
import commonUseStyles from '../../../common/styles/common.styles';
import { AppointmentWithType, appointmentWithTypeNames } from '../../models/appointments.enums';

interface SelectAppointmentWithTypeComponentProps {
    handleChange: (value: AppointmentWithType) => void,
    value: AppointmentWithType,
    availableTypes: AppointmentWithType[],
    isReadonly?: boolean;
    disabled?: boolean;
}

export const SelectAppointmentWithTypeComponent: React.FC<SelectAppointmentWithTypeComponentProps> = (props: SelectAppointmentWithTypeComponentProps) => {
    const {
        handleChange,
        value,
        availableTypes,
        isReadonly = false,
        disabled = false
    } = props;

    const commonClasses = commonUseStyles();

    return (
        <Box>
            <Box>
                <span className={clsx(commonClasses.size14, commonClasses.colorGray1)}>Schedule With</span>
            </Box>
            <Box mt={1}>
                {
                    isReadonly && 
                    <span>{appointmentWithTypeNames.get(value)}</span>
                }
                {
                    !isReadonly && 
                    <Box display="flex" justifyContent="flex-start">
                        {
                           availableTypes.map((type, i) => (
                                <Box key={i} display="flex" justifyContent="flex-start" alignItems="center">
                                    <Box>
                                        <WildHealthRadio
                                            disabled={disabled}
                                            checked={value === type}
                                            onChange={() => handleChange(type)}
                                            value={value}
                                        />
                                    </Box>
                                    <Box>
                                        {appointmentWithTypeNames.get(type)}
                                    </Box>
                                </Box>
                            ))
                        }
                    </Box>
                }
            </Box>
        </Box>
    );
}