import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            paddingBottom: 30,
            padding: 0,
            boxShadow: 'none',
        },
        main: {
            flexGrow: 1,
        },
    }),
);
