import { Box, Container, FormControl, FormHelperText, IconButton, InputAdornment, OutlinedInput } from "@material-ui/core";
import React from 'react';
import { TitlesService } from '../../../../constants/title.constants';
import { logoService } from "../../../../services/logo.service";
import { useFacade } from "./setUpPasswordPage.hooks";
import { useStyles } from '../loginPage/loginPage.styles';
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import commonUseStyles from '../../../common/styles/common.styles';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';

export const SetUpPasswordPage: React.FC = () => {
    const code = new URLSearchParams(window.location.search).get('code');
    const [
        {
            model,
            errors,
            showPassword,
            showConfirmPassword,
            isLoading
        },
        handleChanges,
        handleSubmit,
        handleClickShowPassword,
        handleClickShowConfirmPassword,
    ] = useFacade(code);
    const classes = useStyles();
    const commonClasses = commonUseStyles();

    if (!model.code) {
        return (
            <Box className={classes.title} mt={18} textAlign="center">
                Access Denied!
            </Box>
        );
    }

    const rederSetUpPassword = () => {
        return <>
            <Box className={classes.title} mt={2} textAlign="center">
                Set Password
            </Box>
            <Box display='flex' justifyContent="center">
                <Box width={416} display='flex' flexDirection='column' mt={4}>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <FormControl
                            variant="outlined"
                            color='primary'
                            required
                            error={!!errors[`password`]}
                            size="small"
                            fullWidth
                            classes={{ root: classes.root }}
                        >
                            <Box className={commonClasses.label} mb={1} mt={3}>Set Password</Box>
                            <OutlinedInput
                                id="newPassword"
                                placeholder="Enter you password"
                                type={showPassword ? 'text' : 'password'}
                                required
                                value={model.newPassword}
                                error={!!errors['newPassword']}
                                disabled={isLoading}
                                onChange={(v) => handleChanges(v.target.id, v.currentTarget.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            disabled={isLoading}
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText error >{errors[`newPassword`]}</FormHelperText>
                        </FormControl>
                        <FormControl
                            variant="outlined"
                            color='primary'
                            required
                            error={!!errors[`newPassword`]}
                            size="small"
                            fullWidth
                            classes={{ root: classes.root }}
                        >
                            <Box className={commonClasses.label} mb={1} mt={3}>Confirm Password</Box>
                            <OutlinedInput
                                id="confirmPassword"
                                placeholder="Enter you password"
                                type={showConfirmPassword ? 'text' : 'password'}
                                required
                                value={model.confirmPassword}
                                error={!!errors['confirmPassword']}
                                disabled={isLoading}
                                onChange={(v) => handleChanges(v.target.id, v.currentTarget.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            disabled={isLoading}
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowConfirmPassword}
                                            edge="end"
                                        >
                                            {showConfirmPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText error >{errors[`confirmPassword`]}</FormHelperText>
                        </FormControl>

                        <Box mt={4}>
                            <WildHealthButton
                                size="large"
                                id="restore-password-reset"
                                fullWidth
                                loading={isLoading}
                                onClick={(e) => handleSubmit(e)}
                            >
                                Set Password
                            </WildHealthButton>
                        </Box>
                    </form>
                </Box>
            </Box>
        </>
    }

    return (
        <>
            <title>{TitlesService.restorePasswordTitle}</title>
            <div className={classes.loginPage}>
                <Box display="flex" justifyContent="center">
                    <Box height="47px" my="65px">
                        <img className={classes.logo}
                            src={logoService.getLogo().primary}
                            alt="logo" />
                    </Box>
                </Box>
                <Box className={classes.contentBox}>
                    <Container maxWidth="sm">
                        {
                            rederSetUpPassword()
                        }
                    </Container>
                </Box>
            </div>
        </>
    );
}
