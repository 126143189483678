import { Box, List, ListSubheader, useMediaQuery, useTheme } from '@material-ui/core';
import moment from 'moment';
import React, {useEffect, useRef} from 'react';
import { MessagesPlaceHolder } from '../../../common/components/wildHealthPlaceHolder/MessagesPlaceHolder';
import { MessageGroupModel } from '../../models/message.models';
import { useStyles } from "./threadComponent.styles";
import { groupBy } from "../../../common/helpers/group.by";
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { toCurrentTimeZone } from '../../../timezones/helpers/timezone';
import { MessageComponentViewForAiDemo } from '../messageComponent/MessageComponentViewForAiDemo';

interface ThreadComponentProps {
    isLoadingMessages?: boolean;
    isAiLoading?: boolean;
    messageGroups: MessageGroupModel[];
    lastReadMessageIndex?: number;
    scrollToIndex?: number;
    threadStartId?: string;
    background?: string;
    isCompact?: boolean;
    handleSetMessageUnread?:Function;
    isProxy?: boolean;
    handleUpdateInteraction?: Function;
}

export const ThreadComponentForAiDemo: React.FC<ThreadComponentProps> = (props: ThreadComponentProps) => {
    const {
        isLoadingMessages = false,
        isAiLoading = false,
        messageGroups,
        scrollToIndex = -1,
        threadStartId = 'thread-start',
        background = "#FFF",
        isCompact = false,
    } = props;

    const classes = useStyles();
    const messagesUnreadRef = useRef(null)
    const theme = useTheme();
    const isMiddleScreen = useMediaQuery(theme.breakpoints.down('xs'));

   useEffect(() => {

        if (!isLoadingMessages) {
            messagesUnreadRef.current?.scrollIntoView({ behavior: "smooth" })
        }
    }, [isLoadingMessages,messageGroups]);

    if (!messageGroups.length && !isLoadingMessages) {
        return (
            <Box className={classes.noMessage}>
                <MessagesPlaceHolder message="No messages yet." />
            </Box>
        )
    }

    const groupedByDate = groupBy(messageGroups, x => moment(toCurrentTimeZone(x.date)).format('MMM DD, yyyy'));

    const displayMessagesDate = (groups, date, index) => {
        const dateFormated = moment(toCurrentTimeZone(date)).format('MMM DD, yyyy');

        if (index === 0) {
            return dateFormated
        }

        if (index + 1 === groups.length) {
            return moment(toCurrentTimeZone(groups[index - 1].messages[0].date)).format('MMM DD, yyyy') === dateFormated ? '' : dateFormated
        }


        if (moment(toCurrentTimeZone(groups[index - 1].messages[0].date)).format('MMM DD, yyyy') === dateFormated) {
            return ''
        }
        return dateFormated

    }

    const renderMessage = (group, isLastMessage) => {
        return <MessageComponentViewForAiDemo
                  isLoading={isAiLoading}
                  isCompact={isCompact}
                  messageGroup={group}
                  isMyMessage={group.isMyMessage}
                  messageToScrollIndex={scrollToIndex}
                  isLastMessage={isLastMessage} />
    }


    return (
        <>
        <List id='thread-list' className={classes.root} style={{ background: background }}>
            <Box id={threadStartId} p={3} height='4px'>
                {isLoadingMessages && <WildHealthLinearProgress />}
            </Box>
            {
                Array.from(groupedByDate).map(([dateKey, groups], index) => {
                    const isLastGroup = index === Array.from(groupedByDate).length - 1;
                    return (
                        <Box key={index}>
                            {
                                groups.map((group, i) => {

                                    const messageIndex = group.messages[0].originalMessage.state ? group.messages[0].originalMessage.state.index : group.messages[0].originalMessage.index;
                                    const isLastMessage = isLastGroup && groups.length - 1 === i;

                                    return (
                                        <Box key={messageIndex}>
                                            <div />
                                            {displayMessagesDate(groups, group.messages[0].date, i) &&
                                                <ListSubheader disableSticky={isMiddleScreen}>
                                                    <Box
                                                        pt={1}
                                                        display="flex"
                                                        justifyContent="center"
                                                    >
                                                        <div className={classes.header}>
                                                            {displayMessagesDate(groups, group.messages[0].date, i)}
                                                        </div>
                                                    </Box>
                                                </ListSubheader>}
                                                <Box id={`message-${messageIndex}`}>
                                                    {
                                                        renderMessage(group, isLastMessage)
                                                    }
                                                </Box>
                                            
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                    )
                })
            }
        </List>

        </>
    )
};
