import { Box } from '@material-ui/core';
import React from 'react';
import { useFacade } from './dnaComponent.hooks';

interface DnaComponentProps {
    patientId: number;
    addOnsLoading: boolean;
}

export const DnaComponent: React.FC<DnaComponentProps> = (props: DnaComponentProps) => {
    const { patientId, addOnsLoading } = props;

    const [
        content,
    ] = useFacade(patientId, addOnsLoading);

    return (
        <Box>
            {content}
        </Box>
    );
}
