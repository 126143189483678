import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { onEmit } from "../../../common/helpers/on-emit";
import { PaymentPlanHistoryModel } from "../../../patients/models/patient.model";
import { patientProfileQuery } from "../../../employee/stores/patientProfileStore";

interface MembershipCardComponentState {
    paymentPlanHistories: PaymentPlanHistoryModel[];
    selectedHistoryIndex: number;
    isOpen: boolean;
}

export function useFacade(): [
    MembershipCardComponentState,
    (index: number) => void,
    () => void
] {
    const [state, setState] = useState({
        paymentPlanHistories: [],
        selectedHistoryIndex: null,
        isOpen: false
    } as MembershipCardComponentState);

    const handleExpand = (index: number) => {
        setState(state => ({ ...state, selectedHistoryIndex: index === state.selectedHistoryIndex ? null : index }))
    }

    const handleToggle = () => {
        setState({...state, isOpen: !state.isOpen});
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<PaymentPlanHistoryModel[]>(patientProfileQuery.paymentPlanHistories$, paymentPlanHistories => {
                const sortingHistories = paymentPlanHistories.sort((h1, h2) => {
                    return +new Date(h2.startDate) - +new Date(h1.startDate);
                })
                setState(state => ({
                    ...state,
                    paymentPlanHistories: sortingHistories,
                }));
            }),
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, []);

    return [
        state,
        handleExpand,
        handleToggle
    ];
}