import { ConfirmAgreementModel } from "./agreement.model";
import { PaymentPeriodModel } from "./paymentPeriod.models";
import { PaymentPlanModel } from "./paymentPlan.models";
import { PaymentCouponModel, PaymentPriceModel } from "./paymentPrice.models";
import { IntegrationVendor } from "./payment.models";

export interface SubscriptionModel {
    id: number;
    startDate: Date;
    endDate: Date;
    canceledAt: Date;
    isActive: boolean;
    displayName: string;
    paymentPlan: PaymentPlanModel;
    paymentPeriod: PaymentPeriodModel;
    status: SubscriptionStatus;
    type: SubscriptionType;
    cancellationRequest: SubscriptionCancellationRequestModel | null;
    canBeReplaced: boolean;
    canBeActivated: boolean;
    paymentPrice: PaymentPriceModel;
    integrationVendor: IntegrationVendor;
    paymentCoupon?: PaymentCouponModel;
    price: number;
    pauses: SubscriptionPauseModel[];
    pause: SubscriptionPauseModel;
    manualPaymentId: number | null;
}

export enum SubscriptionStatus {
    Active = 1,
    Expired = 5,
    NotActive = 10,
    Canceled = 15,
    Deactivated = 20,
    Paused = 25
}

export enum CancellationReasonType {
    Other = 0,
    TheCost = 1,
    DidNotLike = 2,
    DidNotUse = 3,
    WantADoctor = 4,
    FeelBetter = 5,
    CanceledInPaymentSystem = 98,
    Renewed = 99,
    Replaced = 100
}

export enum SubscriptionPauseStatus
{
    Pending = 1,
    Active = 10,
    Ended = 20,
    Terminated = 30
}

export const CancellationReasonTypes = new Map<CancellationReasonType, string>()
    .set(CancellationReasonType.TheCost, 'The Cost')
    .set(CancellationReasonType.DidNotLike, 'Didn’t like it enough')
    .set(CancellationReasonType.DidNotUse, 'Didn’t use it enough')
    .set(CancellationReasonType.WantADoctor, 'I want an in person doctor')
    .set(CancellationReasonType.FeelBetter, 'I feel better')
    .set(CancellationReasonType.Other, 'Other')

export const SubscriptionStatusNames = new Map<SubscriptionStatus, string>([
    [SubscriptionStatus.Active, "Active"],
    [SubscriptionStatus.Expired, "Expired"],
    [SubscriptionStatus.NotActive, "Not Active"],
    [SubscriptionStatus.Canceled, "Canceled"],
    [SubscriptionStatus.Deactivated, "Deactivated"],
]);

export enum SubscriptionType{
    Regular = 1,
    Insurance = 5
}

export interface BuyNewSubscriptionModel {
    paymentPeriodId: number;
    paymentPriceId: number;
    addOnIds: number[];
    founderId: number;
    agreements: ConfirmAgreementModel[];
    promoCode?: string;
}

export interface SubscriptionCancellationRequestModel {
    id: number;
    reason: string;
    date: Date | null;
}

export interface CancelSubscriptionModel {
    id: number;
    reasonType: CancellationReasonType;
    reason: string;
    date: Date | null;
}

export interface ActivateSubscriptionModel {
    patientId: number;
    paymentPriceId: number;
    startDate: Date;
}

export interface PatientSubscriptionModel {
    name: string;
    startDate: Date;
    renewalDate: Date;
    status: string;
}

export interface ChangeSubscriptionPaymentPriceModel {
    subscriptionId: number,
    newPaymentPriceId: number,
    couponCode: string,
    employerProductId: number
}

export interface PauseSubscriptionModel {
    id: number;
    pauseDurationInMonth: number;
}

export interface SubscriptionPauseModel {
    startDate: Date;
    endDate: Date;
    status: SubscriptionPauseStatus;
    subscriptionId: number;
}

interface PaymentScheduleItemModel {
    amount: number;
    dueDate: Date;
}

interface BillableScheduleModel {
    total: number;
    deposit: number;
    downPayment: number;
    remainingPaidOverMonths: number;
    scheduleItems: PaymentScheduleItemModel[];
}

export interface CreateManualPaymentModel extends BillableScheduleModel {
    patientId: number;
    subscriptionId: number;
}

export interface ManualPaymentModel extends BillableScheduleModel {
    id: number;
}
