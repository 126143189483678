import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { colors } from "../../modules/common/constants/colors";

type Props = {
  topBannerHeight: number;
};

export const useStyles = makeStyles<Theme, Props>((theme) =>
  createStyles({
    root: {
      height: 56,
      backgroundColor: '#FFFFFF',
      color: colors.gray2,
      zIndex: theme.zIndex.drawer - 1,
      top: ({ topBannerHeight }) => topBannerHeight
    },
    logo: {
      width: 126,
    },
    titleSection: {
      display: 'flex',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  }),
);