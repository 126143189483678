import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../../../../common/constants/colors';
import { appointmentColors } from '../../../constants/appointmentColors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            height: '100%',
            borderRadius: '2px',
            paddingRight: 10,
            paddingLeft: 10,
            '& button': {
                fontSize: 12,
                height: 24,
                textTransform: 'none',
                borderRadius: '2px',
            },
            '& .join-button': {
                minWidth: 76
            },
            '& .note-button': {
                minWidth: 'max-content',
                display: 'flex',
                alignItems: 'center'
            }
        },
        info: {
            minHeight: 52.5,
            fontSize: 12,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        wc: {
            color: appointmentColors.purple,
            backgroundColor: appointmentColors.purpleLight,
            borderLeft: `2px solid ${appointmentColors.purple}`,
            '&:hover': {
                backgroundColor: appointmentColors.purpleMid
            },
            '& .join-button': {
                color: colors.white,
                backgroundColor: appointmentColors.purple,
                '&:hover': {
                    backgroundColor: appointmentColors.purple
                },
            },
            '& .note-button': {
                marginLeft: 12,
                color: appointmentColors.purple,
                backgroundColor: colors.white,
                border: `1px solid ${appointmentColors.purple}`,
                '&:hover': {
                    backgroundColor: colors.white
                },
            }
        },
        fmc: {
            color: appointmentColors.blue,
            backgroundColor: appointmentColors.blueLight,
            borderLeft: `2px solid ${appointmentColors.blue}`,
            '&:hover': {
                backgroundColor: appointmentColors.blueMid,
            },
            '& .join-button': {
                color: colors.white,
                backgroundColor: appointmentColors.blue,
                '&:hover': {
                    backgroundColor: appointmentColors.blue
                },
            },
            '& .note-button': {
                marginLeft: 12,
                color: appointmentColors.blue,
                backgroundColor: colors.white,
                border: `1px solid ${appointmentColors.blue}`,
                '&:hover': {
                    backgroundColor: colors.white
                },
            }
        },
        icc: {
            color: appointmentColors.green,
            backgroundColor: appointmentColors.greenLight,
            borderLeft: `2px solid ${appointmentColors.green}`,
            '&:hover': {
                backgroundColor: appointmentColors.greenMid
            },
            '& .join-button': {
                color: colors.white,
                backgroundColor: appointmentColors.green,
                '&:hover': {
                    backgroundColor: appointmentColors.green
                },
            },
            '& .note-button': {
                marginLeft: 12,
                color: appointmentColors.green,
                backgroundColor: colors.white,
                border: `1px solid ${appointmentColors.green}`,
                '&:hover': {
                    backgroundColor: colors.white
                },
            }
        },
        fcc: {
            color: appointmentColors.orange,
            backgroundColor: appointmentColors.orangeLight,
            borderLeft: `2px solid ${appointmentColors.orange}`,
            '&:hover': {
                backgroundColor: appointmentColors.orandeMid
            },
            '& .join-button': {
                color: colors.white,
                backgroundColor: appointmentColors.orange,
                '&:hover': {
                    backgroundColor: appointmentColors.orange
                },
            },
            '& .note-button': {
                marginLeft: 12,
                color: appointmentColors.orange,
                backgroundColor: colors.white,
                border: `1px solid ${appointmentColors.orange}`,
                '&:hover': {
                    backgroundColor: colors.white
                },
            }
        },
        imc: {
            color: appointmentColors.strongBlue,
            backgroundColor: appointmentColors.strongBlueLight,
            borderLeft: `2px solid ${appointmentColors.strongBlue}`,
            '&:hover': {
                backgroundColor: appointmentColors.strongBlueMid
            },
            '& .join-button': {
                color: colors.white,
                backgroundColor: appointmentColors.strongBlue,
                '&:hover': {
                    backgroundColor: appointmentColors.strongBlue
                },
            },
            '& .note-button': {
                marginLeft: 12,
                color: appointmentColors.strongBlue,
                backgroundColor: colors.white,
                border: `1px solid ${appointmentColors.strongBlue}`,
                '&:hover': {
                    backgroundColor: colors.white
                },
            }
        },
        other: {
            color: appointmentColors.red,
            backgroundColor: appointmentColors.redLight,
            borderLeft: `2px solid ${appointmentColors.red}`,
            '&:hover': {
                backgroundColor: appointmentColors.redMid
            },
            '& .join-button': {
                color: colors.white,
                backgroundColor: appointmentColors.red,
                '&:hover': {
                    backgroundColor: appointmentColors.red
                },
            },
            '& .note-button': {
                marginLeft: 12,
                color: appointmentColors.red,
                backgroundColor: colors.white,
                border: `1px solid ${appointmentColors.red}`,
                '&:hover': {
                    backgroundColor: colors.white
                },
            }
        },
        menuButton: {
            width: 24,
            marginLeft: 12
        }
    })
);