import { Box, Dialog, DialogActions, DialogContent, FormControl, IconButton, MenuItem, Select } from "@material-ui/core";
import React from "react";
import { SelectRecommendationSetDialogProps } from "../../../common/dialogs/dialogProps";
import { useFacade } from "./selectRecommendationSetDialog.hooks";
import DialogTitle from "@material-ui/core/DialogTitle";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { WildHealthRadio } from "../../../common/components/WildHealthRadio";
import { HealthReportDialogName, RecommendationSetType } from "../../models/healthReport.models";
import { toCurrentTimeZone } from "../../../timezones/helpers/timezone";
import moment from "moment";
import { colors } from '../../../../modules/common/constants/colors';
import { RecommendationsDialogComponent } from "../recommendationsDialogComponent/RecommendationsDialogComponent";
import CloseIcon from "@material-ui/icons/Close";

export const SelectRecommendationSetDialog: React.FC<SelectRecommendationSetDialogProps> = (props: SelectRecommendationSetDialogProps) => {
    const {
        title,
        patientId,
        open,
        handleClose,
        handleReportGenerate,
        handleReportRegenerate,
    } = props;

    const reportRecomendationSet = title === HealthReportDialogName.New
        ? [
            RecommendationSetType.NewAndSkipReview,
            RecommendationSetType.New,
            RecommendationSetType.Previous,
        ]
        : [
            RecommendationSetType.NewAndSkipReview,
            RecommendationSetType.Compare,
            RecommendationSetType.New,
        ]

    const [
        state,
        handleTypeChange,
        handleSubmit,
        handleReportChange,
        handleCloseRecommendationsDialog,
        handleGoBackRecommendationsDialog,
    ] = useFacade(handleClose, reportRecomendationSet[0], patientId, open, title, handleReportGenerate, handleReportRegenerate);

    const selectRecommendationSet = (
        <>
            <Box m={3} my={1} fontWeight="500">
                Select a Recommendation Set
            </Box>
            <Box m={3} mt={1}>
                {
                    reportRecomendationSet.map((type, index) => {
                        return (
                            <Box key={index} display='flex' alignItems='center' ml={-1}>
                                <Box>
                                    <WildHealthRadio
                                        checked={state.selectedType === type}
                                        onChange={() => handleTypeChange(type)}
                                        value={type}
                                    />
                                </Box>
                                <Box>{type}</Box>
                            </Box>
                        )
                    })
                }
            </Box>
        </>
    );

    const selectReport = (
        <>
            <Box m={3} my={1} fontWeight="500">
                {state.selectedType === RecommendationSetType.Previous ? "Use Set from Selected Report" : "Compare With Previous Report"}

            </Box>
            <Box m={3} mb={1} color={colors.gray1} fontSize="14px">Select Report</Box>
            <Box m={3} mt={1}>
                <FormControl
                    fullWidth
                    color='primary'
                    variant="outlined"
                    size="small"
                >
                    <Select
                        fullWidth
                        value={state.report?.id ?? 0}
                        onChange={(event) => handleReportChange(Number(event.target.value))}
                    >
                        {
                            state.reports.map((report, index) =>
                                <MenuItem
                                    key={report.id}
                                    value={report.id}
                                >
                                    {index === state.reports.length - 1 ? `Current Report` : `Report (${moment(toCurrentTimeZone(report.status.date)).format("MMM DD, yyyy")})`}
                                </MenuItem>)
                        }
                    </Select>
                </FormControl>
            </Box>
        </>
    );

    return (
        <Dialog fullWidth open={open} onClose={() => handleClose()} aria-labelledby="form-dialog-title">
            <DialogContent>
                <DialogTitle>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        {title}
                        <IconButton id="select-recommendation-close" onClick={() => handleClose()}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                {state.isSelectReport ? selectReport : selectRecommendationSet}
            </DialogContent>
            <DialogActions>
                <Box display="flex" justifyContent="flex-end" width="100%" px={3} pb={2}>
                    <Box mr={1.5}>
                        <WildHealthButton
                            id="select-recommendation-cancel"
                            color="tertiary"
                            onClick={() => handleClose()}
                        >
                            Cancel
                        </WildHealthButton>
                    </Box>
                    <Box>
                        <WildHealthButton
                            id="select-recommendation-save"
                            onClick={() => handleSubmit()}
                            disabled={state.isSelectReport && !state.report}
                        >
                            Proceed
                        </WildHealthButton>
                    </Box>
                </Box>
            </DialogActions>
            <RecommendationsDialogComponent
                title={title}
                type={state.selectedType}
                patientId={patientId}
                open={state.isRecommendationsDialogOpen}
                handleClose={() => handleCloseRecommendationsDialog()}
                handleGoBack={() => handleGoBackRecommendationsDialog()}
                handleReportGenerate={handleReportGenerate}
                handleReportRegenerate={handleReportRegenerate}
            />
        </Dialog>
    );
}
