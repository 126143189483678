import React from "react";
import { AuthenticatedLayoutComponent } from "../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent";
import { PagesNamesService } from "../../../../constants/pagesNames.constants";
import { TitlesService } from "../../../../constants/title.constants";
import { MenuItemTypes } from "../../../../models/menu.models";
import { ConversationTemplatesComponent } from "../../components/conversationTemplatesComponent/ConversationTemplatesComponent";

export const ManageConversationTemplatesPage: React.FC = () => {
    return (
        <AuthenticatedLayoutComponent
            hideBackground
            title={TitlesService.conversationTemplatesTitle}
            pageName={PagesNamesService.conversationTemplates}
            selectedMenuItem={MenuItemTypes.ManageConversationTemplates}
        >
            <ConversationTemplatesComponent />
        </AuthenticatedLayoutComponent>
    )
}
