import React from 'react';
import { Box, Card, CardContent, Divider } from '@material-ui/core';
import { displayMoney } from "../../../common/helpers/display-money";
import { PaymentPlanModel } from "../../models/paymentPlan.models";
import { PaymentPeriodModel } from "../../models/paymentPeriod.models";
import { DiscountModel, DiscountSource, PaymentPriceModel, PaymentStrategy } from "../../models/paymentPrice.models";
import { SelectAddOnModel } from '../../../addons/models/addOns.models';
import { getPaymentPlanFullName } from "../../helpers/getPlanFullName";
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from 'clsx';

interface BillingSummaryComponentProps {
    selectedPlan?: PaymentPlanModel;
    selectedPeriod?: PaymentPeriodModel;
    selectedPrice?: PaymentPriceModel;
    selectedAddOns?: SelectAddOnModel[];
    hasDiscount?: boolean;
    hasContainer?: boolean;
}

export const BillingSummaryComponent: React.FC<BillingSummaryComponentProps> = (props: BillingSummaryComponentProps) => {
    const {
        selectedPlan,
        selectedPeriod,
        selectedPrice,
        selectedAddOns,
        hasDiscount,
        hasContainer,
    } = props;

    const commonClasses = commonUseStyles();

    const startupFee = selectedPrice?.startupFee ?? 0;
    const subTotalPrice = selectedPrice?.originalPrice ?? 0;
    const discount = selectedPrice?.discount ?? 0;
    const totalPrice = (selectedPrice?.price ?? 0) + startupFee +
        (selectedAddOns?.reduce((sum, current) => sum + current.price, 0) ?? 0);

    const showYearMonth = (discountModel: DiscountModel, paymentStrategy:PaymentStrategy) => {
        if(discountModel.source == DiscountSource.Coupon || discountModel.source == DiscountSource.Insurance){
            if(paymentStrategy == PaymentStrategy.FullPayment){
                return " / year";
            }
            if(paymentStrategy == PaymentStrategy.PartialPayment){
                return " / month";
            }
        }
    }
        
    const renderContent = () => {
        return (
            <Box px={2} py={1}>
                <Box display="flex" justifyContent="space-between">
                    {
                        selectedPlan && [
                            <Box key={`plan-${subTotalPrice}`} className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorBlack)}>
                                {selectedPlan && getPaymentPlanFullName(selectedPlan, selectedPeriod)}
                            </Box>,
                            <Box key={`plan-info-${subTotalPrice}`} className={clsx(commonClasses.size16, commonClasses.textMedium, commonClasses.colorBlack)}>
                                <span className={commonClasses.textMedium}>
                                    {displayMoney(subTotalPrice, '$')}
                                </span>
                            </Box>
                        ]
                    }
                </Box>

                {
                    selectedAddOns?.map((item, index) =>
                        <Box mt={2} key={index} display="flex" justifyContent="space-between">
                            <Box className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorBlack)}>
                                <span>
                                    {item.name}
                                </span>
                            </Box>
                            <Box className={clsx(commonClasses.size16, commonClasses.textMedium, commonClasses.colorBlack)}>
                                <span className={commonClasses.textMedium}>
                                    {displayMoney(item.price, '$')}
                                </span>
                            </Box>
                        </Box>
                    )
                }
                <Box mt={2}>
                    {
                        hasDiscount &&
                        <Box key={`startup-fee-${startupFee}`} display="flex" justifyContent="space-between">
                            <Box className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorBlack)}>
                                <span>
                                    Startup Fee
                                </span>
                            </Box>
                            <Box width={0.20} textAlign="right" className={clsx(commonClasses.size16, commonClasses.textMedium, commonClasses.colorBlack)}>
                                <span className={commonClasses.textMedium}>
                                    {displayMoney(startupFee, '$')}
                                </span>
                            </Box>
                        </Box>
                    }
                    {
                            hasDiscount && discount > 0 && !!selectedPrice.discounts &&
                            <Box>
                                {
                                    selectedPrice.discounts.map(x=>{
                                        return(
                                        <Box key={`promo-code-${x.amount}-${x.source}`} display="flex" justifyContent="space-between">
                                            <Box className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorDarkGray)}>
                                                <span>
                                                    {x.name}
                                                </span>
                                            </Box>
                                            <Box textAlign="right">
                                                <span className={clsx(commonClasses.textMedium, commonClasses.colorAccent1)}>
                                                    {displayMoney(x.amount, '$', true, true)}{showYearMonth(x, selectedPrice.strategy)}
                                                </span>
                                            </Box>
                                        </Box>)
                                    })
                                }  
                            </Box>
                        }
                    <Box mt={2}>
                        <Divider />
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                        <Box className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorBlack)}>
                            <span>
                                Total:
                            </span>
                        </Box>
                        <Box width={0.20} textAlign="right" >
                            <span className={clsx(commonClasses.size22, commonClasses.textBold)}>
                                {displayMoney(totalPrice, '$')}
                            </span>
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    }

    return (
        hasContainer ?
            <Card>
                <CardContent>
                    {renderContent()}
                </CardContent>
            </Card> :
            renderContent()
    )
};