import { Box, Container, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { PaymentPlanModel } from '../../models/paymentPlan.models';
import { useStyles } from './SelectPaymentPlanComponent.styles';
import { SelectTrialPaymentPlanComponent } from "../selectTrialPaymentPlanComponent/SelectTrialPaymentPlanComponent";
import { PageSliderNavigationContext } from "../../../common/models/navigation.models";
import { useFacade } from "./SelectPaymentPlanComponent.hooks";
import { SelectPaymentPlanCardTestedComponent } from '../selectPaymentPlanCardTestComponent/SelectPaymentPlanCardComponent';
import commonUseStyles from '../../../common/styles/common.styles';
import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx';
import { Check } from '@material-ui/icons';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import { FooterComponent } from "../../../../components/footerComponent/FooterComponent";

interface SelectPaymentPlanComponentProps {
    plans: PaymentPlanModel[];
    trialPlan?: PaymentPlanModel;
    specifiedPlan?: string;
    defaultPlan?: string;
    isExclusive?: boolean;
    navigationContext: PageSliderNavigationContext;
}

export const SelectPaymentPlanComponent: React.FC<SelectPaymentPlanComponentProps> = (props: SelectPaymentPlanComponentProps) => {
    const {
        plans,
        trialPlan,
        isExclusive,
        specifiedPlan = null,
        defaultPlan = null,
        navigationContext
    } = props;

    const [
        {
            showMobileFooter
        },
        prevScrollY,
        handleTrialPlanSelect,
        handlePlanSelect,
        isPlanAutoSelect
    ] = useFacade(plans, specifiedPlan, defaultPlan, navigationContext);

    const classes = useStyles();
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const mostPlan = plans.find((p) => p.mostPopular);

    if (isPlanAutoSelect()) {
        return <></>
    }

    const renderFreeTrialPlan = () => {
        return (
            <>
                <SelectTrialPaymentPlanComponent />
                <Box className={classes.trialPlan} display="flex" style={{ margin: "5px", padding: "2px" }}>
                    <Box id="select-payment-plan-free-trial" style={{ cursor: 'pointer' }} width={1} py={3} textAlign="center" className={commonClasses.primaryTitle}
                        onClick={() => handleTrialPlanSelect(trialPlan)}>
                        click here for your free trial
                    </Box>
                </Box>
            </>
        );
    }

    const renderExclusiveTitle = () => {
        return (
            <>
                <Box className={classes.trialPlan} display="flex" style={{ margin: "5px", padding: "2px" }}>
                    <Box width={1} py={3} textAlign="center" className={commonClasses.primaryTitle}>
                        Executive health plans
                    </Box>
                </Box>
            </>
        );
    }

    const renderPlans = () => {
        return (
            <Box className={classes.main}>
                <Grid container alignItems="stretch" justify='center' spacing={2}>
                    {
                        plans.map((plan: PaymentPlanModel) => {
                            return (
                                <Grid key={plan.id} item md={4} sm={6} xs={12}>
                                    <Box height={1} width={1} display="flex" flexDirection="column">
                                        {
                                            plan.mostPopular &&
                                            <Box className={classes.mostPopularRibbon}>
                                                <span className={classes.mostPopularText}>
                                                    most popular plan
                                                </span>
                                            </Box>
                                        }
                                        <Box height={1} width={1} display="flex" alignItems="stretch" className={plan.mostPopular ? classes.ribbonRelation : classes.notMostPopular}>
                                            <SelectPaymentPlanCardTestedComponent
                                                plan={plan}
                                                isSelected={false}
                                                handlePlanSelect={handlePlanSelect} />
                                        </Box>
                                    </Box>
                                </Grid>
                            )
                        })}
                </Grid>
                <div ref={prevScrollY}>
                    {!isSmallScreen ? (
                        <>
                            <Box mt={3} className={classes.centerSection}>
                                <Box className={classes.centerContext} display="flex" alignItems="center">
                                    <Box display="flex" alignItems="center">
                                        <CheckIcon />
                                        <Box className={classes.cancelContext}>
                                            Cancel any time
                                        </Box>
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        <CheckIcon />
                                        <Box className={classes.saveContext}>
                                            Save with your HSA or FSA
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box mt={3} mb={0.5}>
                                <Box display="flex" alignItems="center">
                                    <Box flex={1} pr={3} className={classes.detailFeatureContext}>
                                        Detailed features
                                    </Box>
                                    <Box flex={1}>
                                        <Grid container alignItems="stretch" justify='center' spacing={3}>
                                            {
                                                plans.map((plan: PaymentPlanModel) => {
                                                    return (
                                                        <Grid key={plan.id} item md={4} sm={4}>
                                                            <Box height={1} width={1} display="flex" justifyContent="center" flexDirection="column" py={2} className={plan.mostPopular ? classes.mostPlanCard : classes.planCard}>
                                                                <Box className={plan.mostPopular ? classes.mostPlanName : classes.planName}>
                                                                    {plan.displayName}
                                                                </Box>
                                                                <Box mt={1} display="flex" justifyContent="center" alignItems="center">
                                                                    <Box className={clsx(commonClasses.primaryFont, classes.startPrice)}>
                                                                        $
                                                                    </Box>
                                                                    <Box ml={0.5} className={clsx(commonClasses.primaryFont, classes.startPrice)}>
                                                                        {plan.startPrice}
                                                                    </Box>
                                                                    <Box ml={0.5} className={commonClasses.label}>
                                                                        /month
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                    )
                                                })}
                                        </Grid>
                                    </Box>
                                </Box>
                                <Box display="flex" alignItems="center" className={classes.featuresTopBorder}>
                                    <Box flex={1} pr={3} className={classes.featuresContext}>
                                        In-Depth Precision Health Report with 500+ Biomarkers Analyzed
                                    </Box>
                                    <Box flex={1}>
                                        <Grid container alignItems="stretch" justify='center' spacing={3}>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'CORE' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box>
                                                        <Check className={commonClasses.colorMain} />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'ADVANCED' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box>
                                                        <Check className={commonClasses.colorMain} />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'OPTIMIZATION' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box>
                                                        <Check className={commonClasses.colorMain} />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                                <Box display="flex" alignItems="center" className={classes.featuresTopBorder}>
                                    <Box flex={1} pr={3} className={classes.featuresContext}>
                                        Personalized Health Score, Based On Our Proprietary Algorithm
                                    </Box>
                                    <Box flex={1}>
                                        <Grid container alignItems="stretch" justify='center' spacing={3}>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'CORE' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box>
                                                        <Check className={commonClasses.colorMain} />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'ADVANCED' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box>
                                                        <Check className={commonClasses.colorMain} />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'OPTIMIZATION' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box>
                                                        <Check className={commonClasses.colorMain} />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                                <Box display="flex" alignItems="center" className={classes.featuresTopBorder}>
                                    <Box flex={1} pr={3} className={classes.featuresContext}>
                                        Personalized Diet, Recovery & Supplement Plans
                                    </Box>
                                    <Box flex={1}>
                                        <Grid container alignItems="stretch" justify='center' spacing={3}>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'CORE' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box>
                                                        <Check className={commonClasses.colorMain} />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'ADVANCED' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box>
                                                        <Check className={commonClasses.colorMain} />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'OPTIMIZATION' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box>
                                                        <Check className={commonClasses.colorMain} />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                                <Box display="flex" alignItems="center" className={classes.featuresTopBorder}>
                                    <Box flex={1} pr={3} className={classes.featuresContext}>
                                        <span>12 Months of Ongoing Care with <span className={classes.featuresBoldContext}>Comprehensive Doctor Visits</span></span>
                                    </Box>
                                    <Box flex={1}>
                                        <Grid container alignItems="stretch" justify='center' spacing={3}>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'CORE' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box className={clsx(commonClasses.textMedium, commonClasses.size22, commonClasses.colorMain)}>
                                                        1&nbsp;<span className={clsx(commonClasses.textMedium, commonClasses.size18, commonClasses.colorMain)}>visit</span>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'ADVANCED' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box className={clsx(commonClasses.textMedium, commonClasses.size22, commonClasses.colorMain)}>
                                                        2&nbsp;<span className={clsx(commonClasses.textMedium, commonClasses.size18, commonClasses.colorMain)}>visits</span>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'OPTIMIZATION' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box className={clsx(commonClasses.textMedium, commonClasses.size22, commonClasses.colorMain)}>
                                                        4&nbsp;<span className={clsx(commonClasses.textMedium, commonClasses.size18, commonClasses.colorMain)}>visits</span>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                                <Box display="flex" alignItems="center" className={classes.featuresTopBorder}>
                                    <Box flex={1} pr={3} className={classes.featuresContext}>
                                        <span>12 Months of Ongoing Care with <span className={classes.featuresBoldContext}>Health Coach Visits</span></span>
                                    </Box>
                                    <Box flex={1}>
                                        <Grid container alignItems="stretch" justify='center' spacing={3}>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'CORE' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box className={clsx(commonClasses.textMedium, commonClasses.size22, commonClasses.colorMain)}>
                                                        4&nbsp;<span className={clsx(commonClasses.textMedium, commonClasses.size18, commonClasses.colorMain)}>visits</span>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'ADVANCED' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box className={clsx(commonClasses.textMedium, commonClasses.size22, commonClasses.colorMain)}>
                                                        6&nbsp;<span className={clsx(commonClasses.textMedium, commonClasses.size18, commonClasses.colorMain)}>visits</span>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'OPTIMIZATION' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box className={clsx(commonClasses.textMedium, commonClasses.size22, commonClasses.colorMain)}>
                                                        12&nbsp;<span className={clsx(commonClasses.textMedium, commonClasses.size18, commonClasses.colorMain)}>visits</span>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                                <Box display="flex" alignItems="center" className={classes.featuresTopBorder}>
                                    <Box flex={1} pr={3} className={classes.featuresContext}>
                                        Unlimited Messaging
                                    </Box>
                                    <Box flex={1}>
                                        <Grid container alignItems="stretch" justify='center' spacing={3}>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'CORE' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box>
                                                        <Check className={commonClasses.colorMain} />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'ADVANCED' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box>
                                                        <Check className={commonClasses.colorMain} />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'OPTIMIZATION' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box>
                                                        <Check className={commonClasses.colorMain} />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                                <Box display="flex" alignItems="center" className={classes.featuresTopBorder}>
                                    <Box flex={1} pr={3} className={classes.featuresContext}>
                                        Primary Care Needs
                                    </Box>
                                    <Box flex={1}>
                                        <Grid container alignItems="stretch" justify='center' spacing={3}>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'CORE' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box>
                                                        <Check className={commonClasses.colorMain} />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'ADVANCED' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box>
                                                        <Check className={commonClasses.colorMain} />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'OPTIMIZATION' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box>
                                                        <Check className={commonClasses.colorMain} />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                                <Box display="flex" alignItems="center" className={classes.featuresTopBorder}>
                                    <Box flex={1} pr={3} className={classes.featuresContext}>
                                        Ongoing Health Tracking
                                    </Box>
                                    <Box flex={1}>
                                        <Grid container alignItems="stretch" justify='center' spacing={3}>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'CORE' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box>
                                                        <Check className={commonClasses.colorMain} />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'ADVANCED' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box>
                                                        <Check className={commonClasses.colorMain} />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'OPTIMIZATION' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box>
                                                        <Check className={commonClasses.colorMain} />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                                <Box display="flex" alignItems="center" className={classes.featuresTopBorder}>
                                    <Box flex={1} pr={3} className={classes.featuresContext}>
                                        Upload & Analyze Past Health Results
                                    </Box>
                                    <Box flex={1}>
                                        <Grid container alignItems="stretch" justify='center' spacing={3}>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'CORE' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box>
                                                        <Check className={commonClasses.colorMain} />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'ADVANCED' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box>
                                                        <Check className={commonClasses.colorMain} />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'OPTIMIZATION' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box>
                                                        <Check className={commonClasses.colorMain} />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                                <Box display="flex" alignItems="center" className={classes.featuresTopBorder}>
                                    <Box flex={1} pr={3} className={classes.featuresContext}>
                                        25% Discount on Thorne Products
                                    </Box>
                                    <Box flex={1}>
                                        <Grid container alignItems="stretch" justify='center' spacing={3}>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'CORE' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box>
                                                        <Check className={commonClasses.colorMain} />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'ADVANCED' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box>
                                                        <Check className={commonClasses.colorMain} />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'OPTIMIZATION' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box>
                                                        <Check className={commonClasses.colorMain} />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                                <Box display="flex" alignItems="center" className={classes.featuresTopBorder}>
                                    <Box flex={1} pr={3} className={classes.featuresContext}>
                                        Advanced Testing Options
                                    </Box>
                                    <Box flex={1}>
                                        <Grid container alignItems="stretch" justify='center' spacing={3}>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'CORE' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box />
                                                </Box>
                                            </Grid>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'ADVANCED' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box>
                                                        <Check className={commonClasses.colorMain} />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'OPTIMIZATION' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box>
                                                        <Check className={commonClasses.colorMain} />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                                <Box display="flex" alignItems="center" className={classes.featuresTopBorder}>
                                    <Box flex={1} pr={3} className={classes.featuresContext}>
                                        Wearable Device Integration
                                    </Box>
                                    <Box flex={1}>
                                        <Grid container alignItems="stretch" justify='center' spacing={3}>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'CORE' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box />
                                                </Box>
                                            </Grid>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'ADVANCED' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box>
                                                        <Check className={commonClasses.colorMain} />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'OPTIMIZATION' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box>
                                                        <Check className={commonClasses.colorMain} />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                                <Box display="flex" alignItems="center" className={classes.featuresTopBorder}>
                                    <Box flex={1} pr={3} className={classes.featuresContext}>
                                        Advanced Analysis of Wearable Device Data
                                    </Box>
                                    <Box flex={1}>
                                        <Grid container alignItems="stretch" justify='center' spacing={3}>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'CORE' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box />
                                                </Box>
                                            </Grid>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'ADVANCED' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box />
                                                </Box>
                                            </Grid>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'OPTIMIZATION' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box>
                                                        <Check className={commonClasses.colorMain} />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                                <Box display="flex" alignItems="center" className={classes.featuresBothBorder}>
                                    <Box flex={1} pr={3} className={classes.featuresContext}>
                                        In-Depth Specialty Program Focusing On Optimizing Mental Health & Mindfulness
                                    </Box>
                                    <Box flex={1}>
                                        <Grid container alignItems="stretch" justify='center' spacing={3}>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'CORE' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box />
                                                </Box>
                                            </Grid>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'ADVANCED' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box />
                                                </Box>
                                            </Grid>
                                            <Grid item md={4} sm={4}>
                                                <Box height={1} width={1} display="flex" alignItems='center' justifyContent="center" className={mostPlan && mostPlan.name === 'OPTIMIZATION' ? classes.mostPlanFeatureCard : classes.planFeatureCard}>
                                                    <Box>
                                                        <Check className={commonClasses.colorMain} />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </Box>
                            <Box mt={3} display="flex" alignItems="center" >
                                <Box flex={1} width={1} pr={3} />
                                <Box flex={1}>
                                    <Grid container alignItems="stretch" justify='center' spacing={3}>
                                        {
                                            plans.map((plan: PaymentPlanModel) => {
                                                return (
                                                    <Grid key={plan.id} item md={4} sm={4}>
                                                        <WildHealthButton
                                                            id="select-payment-plan"
                                                            size="large"
                                                            fullWidth
                                                            onClick={() => handlePlanSelect(plan)}
                                                            color={plan.mostPopular ? "primary" : "secondary"}
                                                            style={{
                                                                borderRadius: 8,
                                                            }}
                                                        >
                                                            {plan.isLimited ? " Join waitlist!" : "Join Now!"}
                                                        </WildHealthButton>
                                                    </Grid>
                                                )
                                            })}
                                    </Grid>
                                </Box>
                            </Box>
                        </>
                    ) : (
                            <Box mt={9} textAlign="center" flexDirection="column">
                                <Box width={1} pb={2} className={classes.sameFeatureBorder}>
                                    <Box mb={2} className={clsx(commonClasses.size16, commonClasses.textMedium, commonClasses.colorAccent1)}>Same features for all plans</Box>
                                    <Box mb={2} className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorLinkBlack)}>In-Depth Precision Health Report with 500+ Biomarkers Analyzed</Box>
                                    <Box mb={2} className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorLinkBlack)}>Personalized Health Score, Based On Our Proprietary Algorithm</Box>
                                    <Box mb={2} className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorLinkBlack)}>Ongoing Health Tracking</Box>
                                    <Box mb={2} className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorLinkBlack)}>Upload & Analyze Past Health Results</Box>
                                    <Box mb={2} className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorLinkBlack)}>25% Discount on Thorne Products</Box>
                                    <Box mb={2} className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorLinkBlack)}>Personalized Diet, Recovery & Supplement Plans</Box>
                                    <Box mb={2} className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorLinkBlack)}>Unlimited Messaging</Box>
                                    <Box className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorLinkBlack)}>Primary Care Needs</Box>
                                </Box>
                                <Box mt={2} className={clsx(commonClasses.size16, commonClasses.textMedium, commonClasses.colorAccent1)}>Various features </Box>
                                <Box flex={1} mt={2}>
                                    <Box className={classes.featuresContext}>
                                        12 Months of Ongoing Care with
                                </Box>
                                    <Box className={classes.featuresBoldContext}>
                                        Comprehensive Doctor Visits
                                </Box>
                                    <Box py={2}>
                                        <Grid container alignItems="stretch" justify='center' spacing={1}>
                                            <Grid item sm={4} xs={4}>
                                                <Box width={1} display="flex" alignItems='center' justifyContent="center" className={clsx(commonClasses.textMedium, commonClasses.size22, commonClasses.colorMain)}>
                                                    1&nbsp;<span className={clsx(commonClasses.textMedium, commonClasses.size18, commonClasses.colorMain)}>visit</span>
                                                </Box>
                                            </Grid>
                                            <Grid item sm={4} xs={4}>
                                                <Box width={1} display="flex" alignItems='center' justifyContent="center" className={clsx(commonClasses.textMedium, commonClasses.size22, commonClasses.colorMain)}>
                                                    2&nbsp;<span className={clsx(commonClasses.textMedium, commonClasses.size18, commonClasses.colorMain)}>visits</span>
                                                </Box>
                                            </Grid>
                                            <Grid item sm={4} xs={4}>
                                                <Box width={1} display="flex" alignItems='center' justifyContent="center" className={clsx(commonClasses.textMedium, commonClasses.size22, commonClasses.colorMain)}>
                                                    4&nbsp;<span className={clsx(commonClasses.textMedium, commonClasses.size18, commonClasses.colorMain)}>visits</span>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                                <Box flex={1} pt={1} className={classes.featuresTopBorder}>
                                    <Box className={classes.featuresContext}>
                                        12 Months of Ongoing Care with
                                </Box>
                                    <Box className={classes.featuresBoldContext}>
                                        Health Coach Visits
                                </Box>
                                    <Box py={2}>
                                        <Grid container alignItems="stretch" justify='center' spacing={1}>
                                            <Grid item sm={4} xs={4}>
                                                <Box width={1} display="flex" alignItems='center' justifyContent="center" className={clsx(commonClasses.textMedium, commonClasses.size22, commonClasses.colorMain)}>
                                                    4&nbsp;<span className={clsx(commonClasses.textMedium, commonClasses.size18, commonClasses.colorMain)}>visits</span>
                                                </Box>
                                            </Grid>
                                            <Grid item sm={4} xs={4}>
                                                <Box width={1} display="flex" alignItems='center' justifyContent="center" className={clsx(commonClasses.textMedium, commonClasses.size22, commonClasses.colorMain)}>
                                                    6&nbsp;<span className={clsx(commonClasses.textMedium, commonClasses.size18, commonClasses.colorMain)}>visits</span>
                                                </Box>
                                            </Grid>
                                            <Grid item sm={4} xs={4}>
                                                <Box width={1} display="flex" alignItems='center' justifyContent="center" className={clsx(commonClasses.textMedium, commonClasses.size22, commonClasses.colorMain)}>
                                                    12&nbsp;<span className={clsx(commonClasses.textMedium, commonClasses.size18, commonClasses.colorMain)}>visits</span>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                                <Box flex={1} pt={1} className={classes.featuresTopBorder}>
                                    <Box className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorLinkBlack)}>Advanced Testing Options</Box>
                                    <Box py={2}>
                                        <Grid container alignItems="stretch" justify='center' spacing={1}>
                                            <Grid item sm={4} xs={4} />
                                            <Grid item sm={4} xs={4}>
                                                <Box width={1} display="flex" alignItems='center' justifyContent="center">
                                                    <Box>
                                                        <Check className={commonClasses.colorMain} />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item sm={4} xs={4}>
                                                <Box width={1} display="flex" alignItems='center' justifyContent="center">
                                                    <Box>
                                                        <Check className={commonClasses.colorMain} />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                                <Box flex={1} pt={1} className={classes.featuresTopBorder}>
                                    <Box className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorLinkBlack)}>Wearable Device Integration</Box>
                                    <Box py={2}>
                                        <Grid container alignItems="stretch" justify='center' spacing={1}>
                                            <Grid item sm={4} xs={4} />
                                            <Grid item sm={4} xs={4}>
                                                <Box width={1} display="flex" alignItems='center' justifyContent="center">
                                                    <Box>
                                                        <Check className={commonClasses.colorMain} />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item sm={4} xs={4}>
                                                <Box width={1} display="flex" alignItems='center' justifyContent="center">
                                                    <Box>
                                                        <Check className={commonClasses.colorMain} />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                                <Box flex={1} pt={1} className={classes.featuresTopBorder}>
                                    <Box className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorLinkBlack)}>Advanced Analysis of Wearable Device Data</Box>
                                    <Box py={2}>
                                        <Grid container alignItems="stretch" justify='center' spacing={1}>
                                            <Grid item sm={4} xs={4} />
                                            <Grid item sm={4} xs={4} />
                                            <Grid item sm={4} xs={4}>
                                                <Box width={1} display="flex" alignItems='center' justifyContent="center">
                                                    <Box>
                                                        <Check className={commonClasses.colorMain} />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                                <Box flex={1} pt={1} className={classes.featuresTopBorder}>
                                    <Box className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorLinkBlack)}>In-Depth Specialty Program Focusing On Optimizing Mental Health & Mindfulness</Box>
                                    <Box py={2}>
                                        <Grid container alignItems="stretch" justify='center' spacing={1}>
                                            <Grid item sm={4} xs={4} />
                                            <Grid item sm={4} xs={4} />
                                            <Grid item sm={4} xs={4}>
                                                <Box width={1} display="flex" alignItems='center' justifyContent="center">
                                                    <Box>
                                                        <Check className={commonClasses.colorMain} />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </Box>
                        )}
                </div>
            </Box>
        )
    }

    return (
        <>
            {
                trialPlan && renderFreeTrialPlan()
            }
            {
                isExclusive && renderExclusiveTitle()
            }
            <Container className={isSmallScreen ? classes.containerSmall : classes.container}>
                <Box mt={1} display="flex" justifyContent="center">
                    <Box className={isSmallScreen ? classes.choosePlanSM : classes.choosePlan} textAlign="center">
                        Choose Your Precision Care Plan
                    </Box>
                </Box>
                <Box mt={isSmallScreen ? 2 : 6} pb={isSmallScreen ? 0 : 5}>
                    <Box>
                        {
                            renderPlans()
                        }
                    </Box>
                </Box>
            </Container>

            {isSmallScreen && (
                <>
                    <Box mt={1} mb={30} className={classes.centerSectionSM}>
                        <Box className={classes.centerContext}>
                            <Box display="flex" alignItems="center">
                                <CheckIcon />
                                <Box className={classes.cancelContext}>
                                    Cancel any time
                                </Box>
                            </Box>
                            <Box mt={0.5} display="flex" alignItems="center">
                                <CheckIcon />
                                <Box className={classes.saveContext}>
                                    Save with your HSA or FSA
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    {showMobileFooter && (
                        <Box className={classes.stickyFooter}>
                            <Box width={1} px={2}>
                                <Grid container alignItems="stretch" justify='center' spacing={1}>
                                    {
                                        plans.map((plan: PaymentPlanModel) => {
                                            return (
                                                <Grid key={plan.id} item sm={4} xs={4}>
                                                    <Box height={1} width={1} display="flex" justifyContent="center" flexDirection="column" py={2} textAlign='center'>
                                                        <Box className={classes.planName}>
                                                            {plan.displayName}
                                                        </Box>
                                                        <Box mt={1} display="flex" justifyContent="center" alignItems="center">
                                                            <Box className={clsx(commonClasses.primaryFont, classes.startPrice)}>
                                                                $
                                                            </Box>
                                                            <Box ml={0.5} className={clsx(commonClasses.primaryFont, classes.startPrice)}>
                                                                {plan.startPrice}
                                                            </Box>
                                                        </Box>
                                                        <Box className={commonClasses.label}>
                                                            /month
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            )
                                        })}
                                </Grid>
                            </Box>
                            <Box flex={1} width={1} px={2} pb={4}>
                                <Grid container alignItems="stretch" justify='center' spacing={1}>
                                    {
                                        plans.map((plan: PaymentPlanModel) => {
                                            return (
                                                <Grid key={plan.id} item sm={4} xs={4}>
                                                    <WildHealthButton
                                                        id="select-payment-plan"
                                                        size="large"
                                                        fullWidth
                                                        onClick={() => handlePlanSelect(plan)}
                                                        color={"secondary"}
                                                        style={{
                                                            borderRadius: 8,
                                                            height: 80,
                                                        }}
                                                    >
                                                        {plan.isLimited ? " Join waitlist!" : "Join Now!"}
                                                    </WildHealthButton>
                                                </Grid>
                                            )
                                        })}
                                </Grid>
                            </Box>
                            <FooterComponent position="static" />
                        </Box>
                    )}
                </>
            )}
        </>
    )
}
