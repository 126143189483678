import { Box, FormControl, FormGroup, FormHelperText, useMediaQuery, useTheme } from "@material-ui/core";
import React from 'react';
import { CheckAnswer, emptyCheckAnswer } from "../../models/questionnaire.models";
import { QuestionComponentProps } from "./QuestionComponentProps";
import { CheckboxQuestionaris, FormControlLabelQuestionaris, IOSSlider } from "./questionnaire.styles";
import commonUseStyles from "../../../common/styles/common.styles";
import clsx from "clsx";

export const QuestionRateComponent: React.FC<QuestionComponentProps> = (props: QuestionComponentProps) => {
    const {
        question,
        answer,
        handleChanges,
        error
    } = props;

    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    let checkAnswer: CheckAnswer;

    const variants = [...Array(question.max + 1 - question.min).keys()].map(x => `${x + question.min}`);

    try {
        checkAnswer = JSON.parse(answer);
        if (!checkAnswer) {
            checkAnswer = emptyCheckAnswer();
        }
    } catch (e) {
        checkAnswer = emptyCheckAnswer();
    }

    const isChecked = (variant: string) => {
        return checkAnswer.v.includes(variant);
    }

    const isSlideValue = () => {
        const variant = variants.find(v => checkAnswer.v.includes(v));
        return variant ? parseInt(variant) : question.min;
    }

    const wrapResult = () => {
        return JSON.stringify(checkAnswer);
    }

    const handleCheck = (variant: string) => {
        checkAnswer.v = [];
        checkAnswer.v.push(variant);
        handleChanges(wrapResult())
    }

    const handleChange = (value: number | number[]) => {
        checkAnswer.v = [];
        checkAnswer.v.push(value.toString());
        handleChanges(wrapResult())
    }

    const renderRateHints = (): JSX.Element => {
        if (!question.variants.length) {
            return (<></>);
        }
        const variantsPrefix1 = question.variants[0].split(":");
        const variantsPrefix2 = question.variants[1].split(":");
        return (
            <Box width={1} display='flex' justifyContent='space-between' className={clsx(commonClasses.size14, commonClasses.colorGray1)}>
                <Box>
                    {isSmallScreen ? (<span>{variantsPrefix1[0]}{variantsPrefix1[1] && ":"}<br />{variantsPrefix1[1]}</span>) : (<span>{question.variants[0]}</span>)}
                </Box>
                <Box textAlign={isSmallScreen && "right"}>
                    {isSmallScreen ? (<span>{variantsPrefix2[0]}{variantsPrefix2[1] && ":"}<br />{variantsPrefix2[1]}</span>) : (<span>{question.variants[1]}</span>)}
                </Box>
            </Box>
        )
    }

    return (
        <>
            <Box mt={3}
                className={"check-question"}>
                {isSmallScreen ? (
                    <IOSSlider aria-label="ios slider" max={question.max} min={question.min} value={isSlideValue()} valueLabelDisplay="on" onChange={(e, v) => handleChange(v)} />
                ) : (
                    <FormControl
                        color='primary'
                        fullWidth
                        error={!!error}
                        component="fieldset">
                        <FormHelperText>{error}</FormHelperText>
                        <FormGroup>
                            <Box display="flex" justifyContent="space-between" width={1}>
                                {
                                    variants.map((variant, i) => {
                                        return (
                                            <Box key={i} style={{whiteSpace: 'nowrap'}}>
                                                <FormControlLabelQuestionaris
                                                    className={isChecked(variant) ? 'checked' : ''}
                                                    key={i}
                                                    control={
                                                        <CheckboxQuestionaris
                                                            className="check-box"
                                                            checked={isChecked(variant)}
                                                            size="small"
                                                            icon={<></>}
                                                            checkedIcon={<></>}
                                                            name={variant}
                                                            key={variant}
                                                            onChange={(v) => handleCheck(v.target.name)}
                                                        />
                                                    }
                                                    label={variant}
                                                />
                                            </Box>
                                        )
                                    })
                                }
                            </Box>
                        </FormGroup>
                    </FormControl>
                )}
            </Box>
            {
                renderRateHints()
            }
        </>
    )
}