import React from "react";
import { Box, Button, ButtonGroup, CircularProgress, Divider, IconButton, useTheme, useMediaQuery } from "@material-ui/core";
import { colors } from "../../../common/constants/colors";
import GetAppIcon from "@material-ui/icons/GetApp";
import { PageTitleComponent } from "../../../../components/pageTitleComponent/PageTitleComponent";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { AuthenticatedLayoutComponent } from "../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent";
import { TitlesService } from "../../../../constants/title.constants";
import { MenuItemTypes } from "../../../../models/menu.models";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { useFacade } from "./patientHistoryAndPhysicalFollowUpNote.hooks";
import commonUseStyles from "../../../common/styles/common.styles";
import {
    getApoeInfo,
    itemWithDescription,
    renderEducation,
    renderEmptyMedicationsAndSupplementsToStart,
    renderEmptyMedicationsAndSupplementsToStop,
    renderGoals,
    renderItemsSection,
    renderMdm,
    renderMedicationsAndSupplementsToStart,
    renderMedicationsAndSupplementsToStop,
    renderNextAppointmentDate,
    renderTextSection, renderVitals
} from "../../helpers/noteHelpers";
import { ActionsButton } from "../../../healthReport/components/healthReportToolbarComponent/healthReportToolbarComponent.styles";
import clsx from 'clsx';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import { NoteSignatureComponent } from "../../components/noteSignature/NoteSignatureComponent";
import {useFlags} from "launchdarkly-react-client-sdk";
import {FeatureComponent} from "../../../common/components/featureFlags/FeatureComponent";
import {FeatureFlag} from "../../../common/components/featureFlags/featureFlags.models";

export const PatientHistoryAndPhysicalFollowUpNotePage: React.FC = (props: any) => {
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const noteId = Number(props.match.params.noteId);
    const flags = useFlags();

    const [
        {
            note,
            content,
            internalContent,
            isPdfDownloading,
            mdmCodes
        },
        handleGoBack,
        handleDownloadPdf
    ] = useFacade(noteId);

    const isLoading = !content || !note;

    const displayDownloadPdfButton = () => {
        if (isPdfDownloading === true) {
            return (
                <Box width="70px" display="flex" justifyContent="center">
                    <CircularProgress size={24} style={{ color: colors.main }} />
                </Box>
            )
        }

        return (
            <ActionsButton id="patient-download-pdf" onClick={() => {
                handleDownloadPdf(note, content);
            }}>
                <Box display="flex" alignItems="center" mt={1}>
                    <Box>
                        <GetAppIcon />
                    </Box>
                </Box>
            </ActionsButton>
        );
    }

    const renderNoteHeader = () => {
        return (
            <Box display="flex " alignItems="center" justifyContent="space-between">
                <ButtonGroup size="large" variant="text" color="default" aria-label="large outlined primary button group">
                    <Button disabled>
                        <span className={commonClasses.secondaryTitle}>
                            {
                                note.name
                            }
                        </span>
                    </Button>
                    {
                        note.title &&
                        <Button disabled>
                            <span className={commonClasses.secondaryTitle}>
                                {
                                    note.title
                                }
                            </span>
                        </Button>
                    }
                </ButtonGroup>
                {
                    displayDownloadPdfButton()
                }
            </Box>
        )
    }

    const pageName = (
        <PageTitleComponent
            startAdornment={
                <>
                    <IconButton id="patient-history-physical-follow-up-go-back" onClick={() => handleGoBack()}>
                        <ArrowBackIcon />
                    </IconButton>
                    <span className={commonClasses.subtitle}>
                        back to notes
                    </span>
                </>
            }
            title={''}
            endAdornment={null}
        />
    );

    const renderPlan = () => {
        return (
            <Box>
                <span className={isSmallScreen ? commonClasses.mediumTitle : commonClasses.secondaryTitle}>
                    Plan
                </span>
                <Box py={2}>
                    <Divider />
                </Box>
                <span style={{ whiteSpace: 'pre-line' }} className={isSmallScreen ? commonClasses.smallTitle : commonClasses.subtitle}>
                    {
                        internalContent.plan.planText ?? 'These are our main recommendations from this visit. These should be your primary focus.'
                    }
                </span>
            </Box>
        )
    }

    const renderNoteContent = (): JSX.Element => {
        return (
            <Box>
                {
                    isSmallScreen ? (
                        <Box mb="30px">
                            <Box className={commonClasses.secondaryTitle}>{note.name}</Box>
                            {
                                note.title &&
                                <Box mt={1} className={clsx(commonClasses.mediumTitle)}>{note.title}</Box>
                            }
                        </Box>
                    ) : renderNoteHeader()
                }
                <Box p={!isSmallScreen && 1.8}>
                    <Box>
                        <Box>
                            {
                                renderPlan()
                            }
                        </Box>
                        <Box mt={isSmallScreen ? "20px" : 4}>
                            {
                                internalContent.plan.dietGoals?.length > 0 &&
                                <Box>
                                    {
                                        renderGoals('Diet', internalContent.plan.dietGoals, commonClasses, isSmallScreen)
                                    }
                                </Box>
                            }
                            {
                                internalContent.plan.dietEducations?.length > 0 &&
                                <Box>
                                    {
                                        renderEducation(internalContent.plan.dietEducations, commonClasses)
                                    }
                                </Box>
                            }
                            {
                                internalContent.plan.exerciseGoals?.length > 0 &&
                                <Box mt={isSmallScreen ? "20px" : 4}>
                                    {
                                        renderGoals('Exercise/Recovery', internalContent.plan.exerciseGoals, commonClasses, isSmallScreen)
                                    }
                                </Box>
                            }
                            {
                                internalContent.plan.exerciseEducations?.length > 0 &&
                                <Box>
                                    {
                                        renderEducation(internalContent.plan.exerciseEducations, commonClasses)
                                    }
                                </Box>
                            }
                            {
                                internalContent.plan.sleepGoals?.length > 0 &&
                                <Box mt={isSmallScreen ? "20px" : 4}>
                                    {
                                        renderGoals('Sleep', internalContent.plan.sleepGoals, commonClasses, isSmallScreen)
                                    }
                                </Box>
                            }
                            {
                                internalContent.plan.sleepEducations?.length > 0 &&
                                <Box>
                                    {
                                        renderEducation(internalContent.plan.sleepEducations, commonClasses)
                                    }
                                </Box>
                            }
                            {
                                internalContent.plan.neuroGoals?.length > 0 &&
                                <Box mt={isSmallScreen ? "20px" : 4}>
                                    {
                                        renderGoals('Neuro', internalContent.plan.neuroGoals, commonClasses, isSmallScreen)
                                    }
                                </Box>
                            }
                            {
                                internalContent.plan.neuroEducations?.length > 0 &&
                                <Box>
                                    {
                                        renderEducation(internalContent.plan.neuroEducations, commonClasses)
                                    }
                                </Box>
                            }
                            {
                                internalContent.plan.longevityGoals?.length > 0 &&
                                <Box mt={isSmallScreen ? "20px" : 4}>
                                    {
                                        renderGoals('Longevity', internalContent.plan.longevityGoals, commonClasses, isSmallScreen)
                                    }
                                </Box>
                            }
                            {
                                internalContent.plan.longevityEducations?.length > 0 &&
                                <Box>
                                    {
                                        renderEducation(internalContent.plan.longevityEducations, commonClasses)
                                    }
                                </Box>
                            }
                            {
                                internalContent.plan.mindfulnessGoals?.length > 0 &&
                                <Box mt={isSmallScreen ? "20px" : 4}>
                                    {
                                        renderGoals('Mindfulness', internalContent.plan.mindfulnessGoals, commonClasses, isSmallScreen)
                                    }
                                </Box>
                            }
                            {
                                internalContent.plan.mindfulnessEducations?.length > 0 &&
                                <Box>
                                    {
                                        renderEducation(internalContent.plan.mindfulnessEducations, commonClasses)
                                    }
                                </Box>
                            }
                            {
                                internalContent.plan.coachOtherText?.length > 0 &&
                                <Box mt={isSmallScreen ? "20px" : 4}>
                                    {
                                        renderTextSection('Other', internalContent.plan.coachOtherText, commonClasses, false)
                                    }
                                </Box>
                            }
                        </Box>
                        {
                            internalContent.plan.labsText?.length > 0 &&
                            <Box mt={isSmallScreen ? "20px" : 4}>
                                {
                                    renderTextSection('Labs', internalContent.plan.labsText, commonClasses, isSmallScreen, false)
                                }
                            </Box>
                        }
                        {
                            internalContent.diagnosis?.length > 0 &&
                            <Box mt={4}>
                                {
                                    renderItemsSection('Diagnosis', internalContent.diagnosis.map(x => {
                                        return x.additionalInformation
                                            ? x.code + " " +  x.description + ' - ' + x.additionalInformation
                                            : x.code + " " +  x.description
                                    }), commonClasses, isSmallScreen, true, '', true)
                                }
                            </Box>
                        }
                        {
                            internalContent.chiefComplaint.length > 0 &&
                            <Box mt={4}>
                                {
                                    renderTextSection('Chief Complaint', internalContent.chiefComplaint, commonClasses, isSmallScreen)
                                }
                            </Box>
                        }
                        {
                            internalContent.HPI.length > 0 &&
                            <Box mt={4}>
                                {
                                    renderTextSection('HPI', internalContent.HPI, commonClasses, isSmallScreen)
                                }
                            </Box>
                        }
                        <Box mt={4}>
                            <Box>
                                <span className={commonClasses.secondaryTitle}>
                                    PMH
                                </span>
                                <Box py={2}>
                                    <Divider />
                                </Box>
                                {internalContent?.pmhVersion
                                    ? <>
                                        <FeatureComponent notFeatureFlag={FeatureFlag.RxntIntegration}>
                                            {
                                                internalContent.PMHnew?.allergies?.length > 0 &&
                                                renderItemsSection('Allergies', internalContent.PMHnew.allergies.map(el => itemWithDescription(el)), commonClasses, isSmallScreen, false, '', false,)
                                            }
                                        </FeatureComponent>
                                        <FeatureComponent featureFlag={FeatureFlag.RxntIntegration}>
                                            {
                                                internalContent.PMHnew?.rxntAllergies?.length > 0 &&
                                                renderItemsSection('Allergies', internalContent.PMHnew.rxntAllergies?.map(el => el.description.length ? `${el.name} - ${el.description}` : el.name) ?? [], commonClasses, isSmallScreen, false, '', false,)
                                            }
                                        </FeatureComponent>
                                        {
                                            internalContent.PMHnew.currentMedicalConditions?.length > 0 &&
                                            <Box mt={isSmallScreen ? "20px" : 4}>
                                                {
                                                    renderItemsSection('Problem List', internalContent.PMHnew.currentMedicalConditions.map(el => itemWithDescription(el)), commonClasses, isSmallScreen, false, '', false,)
                                                }
                                            </Box>
                                        }
                                        {
                                            internalContent.PMHnew.famHX?.length > 0 &&
                                            <Box mt={isSmallScreen ? "20px" : 4}>
                                                {
                                                    renderItemsSection('Fam HX', internalContent.PMHnew.famHX.map(el => itemWithDescription(el)), commonClasses, isSmallScreen, false, '', false,)
                                                }
                                            </Box>
                                        }
                                        {
                                            internalContent.PMHnew.screeningHealthMaintenance?.length > 0 &&
                                            <Box mt={isSmallScreen ? "20px" : 4}>
                                                {
                                                   renderItemsSection('Screening/Health Maintenance', internalContent.PMHnew.screeningHealthMaintenance.filter(el => !el.key.includes('APOE')).map(el => itemWithDescription(el)), commonClasses, isSmallScreen, false, '', false, getApoeInfo(internalContent.PMHnew.screeningHealthMaintenance))
                                                }
                                            </Box>
                                        }
                                        {
                                            internalContent.PMHnew.surgicalHX?.length > 0 &&
                                            <Box mt={isSmallScreen ? "20px" : 4}>
                                                {
                                                    renderItemsSection('Surgical HX', internalContent.PMHnew.surgicalHX.map(el => itemWithDescription(el)), commonClasses, isSmallScreen, false, '', false,)
                                                }
                                            </Box>
                                        }
                                        {
                                            [...internalContent.PMHnew.socialHX, ...internalContent.PMHnew.lifestyleHX]?.length > 0 &&
                                            <Box mt={isSmallScreen ? "20px" : 4}>
                                                {
                                                    renderItemsSection('Social/Lifestyle HX', [...internalContent.PMHnew.socialHX, ...internalContent.PMHnew.lifestyleHX].map(el => itemWithDescription(el)), commonClasses, isSmallScreen, false, '', false,)
                                                }
                                            </Box>
                                        }
                                    </>
                                    : <>
                                        {
                                            internalContent.PMH.allergies?.length > 0 &&
                                            <Box>
                                                {
                                                    renderItemsSection('Allergies', internalContent.PMH.allergies, commonClasses, isSmallScreen, false)
                                                }
                                            </Box>
                                        }
                                        {
                                            internalContent.PMH.currentMedicalConditions?.length > 0 &&
                                            <Box mt={isSmallScreen ? "20px" : 4}>
                                                {
                                                    renderItemsSection('Problem List', internalContent.PMH.currentMedicalConditions, commonClasses, isSmallScreen, false)
                                                }
                                            </Box>
                                        }
                                        {
                                            internalContent.PMH.famHX?.length > 0 &&
                                            <Box mt={isSmallScreen ? "20px" : 4}>
                                                {
                                                    renderItemsSection('Fam HX', internalContent.PMH.famHX, commonClasses, isSmallScreen, false)
                                                }
                                            </Box>
                                        }
                                        {
                                            internalContent.PMH.screeningHealthMaintenance?.length > 0 &&
                                            <Box mt={isSmallScreen ? "20px" : 4}>
                                                {
                                                    renderItemsSection('Screening/Health Maintenance', internalContent.PMH.screeningHealthMaintenance, commonClasses, isSmallScreen, false)
                                                }
                                            </Box>
                                        }
                                        {
                                            internalContent.PMH.surgicalHX?.length > 0 &&
                                            <Box mt={isSmallScreen ? "20px" : 4}>
                                                {
                                                    renderItemsSection('Surgical HX', internalContent.PMH.surgicalHX, commonClasses, isSmallScreen, false)
                                                }
                                            </Box>
                                        }
                                        {
                                            internalContent.PMH.socialLifestyleHX?.length > 0 &&
                                            <Box mt={isSmallScreen ? "20px" : 4}>
                                                {
                                                    renderItemsSection('Social/Lifestyle HX', internalContent.PMH.socialLifestyleHX, commonClasses, isSmallScreen, false)
                                                }
                                            </Box>
                                        }</>
                                }

                            </Box>
                        </Box>
                        {
                            internalContent.ROS.rosEnabled &&
                            <Box mt={4}>
                                {
                                    renderItemsSection('ROS', internalContent.ROS.rosValues, commonClasses, isSmallScreen, true, internalContent.ROS.rosValues.length > 0 ? 'All systems reviewed and negative except:' : "All systems reviewed and negative")
                                }
                            </Box>
                        }
                        {
                            (internalContent.medicationsSupplements.supplements.filter(x => !x.isInCurrent).length > 0 ||
                                internalContent.medicationsSupplements.medications.filter(x => !x.isInCurrent).length > 0 ||
                                internalContent.medicationsSupplements.rxntMedications?.filter(x => !x.isInCurrent).length > 0 ||
                                internalContent.medicationsSupplements.fullscriptSupplements?.length > 0) &&
                            <Box mt={4}>
                                {
                                    renderMedicationsAndSupplementsToStart(internalContent.medicationsSupplements, commonClasses, flags)
                                }
                            </Box>
                        }
                        {
                            (internalContent.medicationsSupplements.supplements.filter(x => !x.isInCurrent).length <= 0 &&
                                internalContent.medicationsSupplements.medications.filter(x => !x.isInCurrent).length <= 0 &&
                                internalContent.medicationsSupplements.rxntMedications?.filter(x => !x.isInCurrent).length <= 0 &&
                                internalContent.medicationsSupplements.fullscriptSupplements?.length <= 0) &&
                            <Box mt={4}>
                                {
                                    renderEmptyMedicationsAndSupplementsToStart(commonClasses)
                                }
                            </Box>
                        }
                        {
                            (internalContent.medicationsSupplements.supplements.filter(x => x.isStopped).length > 0 ||
                                internalContent.medicationsSupplements.rxntMedications?.filter(x => x.isStopped).length > 0 ||
                                internalContent.medicationsSupplements.medications.filter(x => x.isStopped).length > 0) &&
                            <Box mt={4}>
                                {
                                    renderMedicationsAndSupplementsToStop(internalContent.medicationsSupplements, commonClasses, flags)
                                }
                            </Box>
                        }
                        {
                            (internalContent.medicationsSupplements.supplements.filter(x => x.isStopped).length <= 0 &&
                                internalContent.medicationsSupplements.rxntMedications?.filter(x => x.isStopped).length <= 0 &&
                                internalContent.medicationsSupplements.medications.filter(x => x.isStopped).length <= 0) &&
                            <Box mt={4}>
                                {
                                    renderEmptyMedicationsAndSupplementsToStop(commonClasses)
                                }
                            </Box>
                        }
                        <Box mt={4}>
                            {
                                renderVitals(internalContent.physicalData, commonClasses, isSmallScreen)
                            }
                        </Box>
                        {
                            internalContent.mdmData.selectedCodeId &&
                            <Box mt={4}>
                                {
                                    renderMdm(internalContent.mdmData, mdmCodes, commonClasses, internalContent.duration?.value)
                                }
                            </Box>
                        } 
                        {
                            note.nextCoachAppointmentDate && note.nextProviderAppointmentDate &&
                            <Box pt={4}>
                                {
                                    renderNextAppointmentDate(note.nextCoachAppointmentDate, note.nextProviderAppointmentDate, commonClasses, isSmallScreen)
                                }
                            </Box>
                        }
                        <Box mt={4}>
                            <NoteSignatureComponent note={note} noteId={note.id} />
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    }

    return (
        <AuthenticatedLayoutComponent
            title={TitlesService.noteDetailTitle}
            pageName={pageName}
            selectedMenuItem={MenuItemTypes.Notes}
        >
            <Box className={isSmallScreen && commonClasses.bgMain}>
                {isSmallScreen && (
                    <>
                        <Box py={2} display="flex" alignItems="center">
                            <IconButton id="patient-history-physical-follow-up-go-back" onClick={() => handleGoBack()}>
                                <ArrowBackIcon />
                            </IconButton>
                            <Box className={clsx(commonClasses.size14, commonClasses.textRegular, commonClasses.colorGray1)}>
                                Back to Notes
                            </Box>
                        </Box>
                        <Box position="fixed" right={16} bottom={40} zIndex={1202}>
                            <WildHealthButton id="download-pdf" width="48px" loading={isPdfDownloading} style={{ borderRadius: "50%", minWidth: 48, height: 48 }} onClick={() => handleDownloadPdf(note, content)}>
                                <GetAppIcon className={commonClasses.colorWhite} />
                            </WildHealthButton>
                        </Box>
                    </>
                )}
                {
                    isLoading ?
                        <WildHealthLinearProgress /> :
                        <Box p={isSmallScreen ? 2 : 5} pb={4} className={isSmallScreen && commonClasses.bgWhiteMain}>
                            {renderNoteContent()}
                        </Box>
                }
            </Box>
        </AuthenticatedLayoutComponent>
    )
};