import React from 'react';
import { Box } from "@material-ui/core";
import { NoteMarkdownTypes } from '../../models/notes.models';
import { WildHealthLinkButton } from '../../../common/components/WildHealthLinkButton';
import { useStyles } from './textMarkdownComponent.styles';
import { displayTextWithLink, displayMessageTextWithLink } from '../../../common/helpers/display-text-with-link';
import clsx from 'clsx';


interface TextMarkdownProps {
    content: string;
    classLink?: any;
}

export const TextMarkdownComponent: React.FC<TextMarkdownProps> = (props: TextMarkdownProps) => {
    const classes = useStyles();

    const checkMarkdown = (part: string) => {
        if (part.match(/^#\s/)) {
            return NoteMarkdownTypes.H1
        }
        if (part.match(/^##\s/)) {
            return NoteMarkdownTypes.H2
        }
        if (part.match(/^###\s/)) {
            return NoteMarkdownTypes.H3
        }
        if (part.match(/^[*][\d\w].*[*]$/)) {
            return NoteMarkdownTypes.Italic
        }
        if (part.match(/^[*][*][\d\w].*[*][*]$/)) {
            return NoteMarkdownTypes.Bold
        }
        if (part.match(/^[>]/)) {
            return NoteMarkdownTypes.Blockquote
        }
        if (part.match(/^[-]\s[\d\w].*/)) {
            return NoteMarkdownTypes.List
        }
        if (part.match(/^[*]\s[\d\w].*/)) {
            return NoteMarkdownTypes.List
        }
        if (part.match(/^[+]\s[\d\w].*/)) {
            return NoteMarkdownTypes.List
        }
        if (part.match(/^[-][-][-][-\s]*$/)) {
            return NoteMarkdownTypes.Line
        }
        if (part.match(/^\[[\s\d\w].*\][(][\s\d\w].*[)]$/)) {
            return NoteMarkdownTypes.Link
        }
    }

    const createPartHeader = (text: string) => {
        return text.replace(/^[#]*\s/, '');
    }

    const createPartBold = (text: string) => {
        return text.replace(/[**]/g, '');
    }

    const createPartItalic = (text: string) => {
        return text.replace(/[*]/g, '');
    }

    const createPartBlockquote = (text: string) => {
        return text.replace(/^[>]/, '');
    }

    const createPartLine = (text: string) => {
        return text.replace(/[-]/gi, '');
    }

    const createPartList = (text: string) => {
        return text.replace(/^[-]|[+]|[*]/, '●');
    }

    const createTitleLink = (text: string) => {
        const title = text.match(/^\[[\s\d\w].*\]/)[0]
        return title.slice(1, -1);
    }

    const getLink = (text: string) => {
        const title = text.match(/[(][\s\d\w].*[)]$/)[0]
        return title.slice(1, -1).trim();
    }

    const getTextSections = (text: string) => {

        if (!text) {
            return;
        }

        const res = [];
        const parts = text.split(/\n/);

        parts.forEach(part => {
            let trimPart = part.trim()
            let updatePart;

            switch (checkMarkdown(trimPart)) {
                case NoteMarkdownTypes.H1:
                case NoteMarkdownTypes.H2:
                case NoteMarkdownTypes.H3:
                    updatePart = createPartHeader(trimPart);
                    break;
                case NoteMarkdownTypes.Bold:
                    updatePart = createPartBold(trimPart);
                    break;
                case NoteMarkdownTypes.Italic:
                    updatePart = createPartItalic(trimPart);
                    break;
                case NoteMarkdownTypes.Blockquote:
                    updatePart = createPartBlockquote(trimPart);
                    break;
                case NoteMarkdownTypes.Line:
                    updatePart = createPartLine(trimPart);
                    break;
                case NoteMarkdownTypes.List:
                    updatePart = createPartList(trimPart);
                    break;
                case NoteMarkdownTypes.Link:
                    res.push(
                        <WildHealthLinkButton
                            onClick={() => { window.open(getLink(trimPart), '_blank') }}
                            content={<span className={classes.link}>{createTitleLink(trimPart)}</span>}
                            id="schedule-appointment-link"
                        />);
                    return;
                default:
                    updatePart = trimPart;
            }

            const linkStyle = props.classLink ? props.classLink : ''
            res.push(<Box className={clsx(classes[checkMarkdown(trimPart) ?? ''], classes.breakWord)}>
                {updatePart ? displayTextWithLink(updatePart, linkStyle) : <br />}
            </Box>);
        })

        return res;
    }

    
    return <Box>{getTextSections(props.content)}</Box>
}

export const TextLinkMarkdownComponent: React.FC<TextMarkdownProps> = (props: TextMarkdownProps) => {
    const classes = useStyles();

    const checkMarkdown = (part: string) => {
        if (part.match(/\[[\s\d\w].*\][(][\s\d\w].*[)]$/)) {
            return NoteMarkdownTypes.Link
        }
    }

    const createTitleLink = (text: string) => {
        const title = text.match(/^\[[\s\d\w].*\]/)[0]
        return title.slice(1, -1);
    }

    const getLink = (text: string) => {
        const title = text.match(/[(][\s\d\w].*[)]$/)[0]
        return title.slice(1, -1).trim();
    }

    const getTextSections = (text: string) => {

        if (!text) {
            return;
        }

        const res = [];
        const parts = text.split(/\)/);

        parts.forEach((part, index) => {
            let trimPart = part.trim()
            if (index !== parts.length - 1) {
                trimPart += ')';
            }
            let trimPart2 = trimPart.split(/\[/);
            trimPart2.forEach((subPart, subIndex) => {
                let trimSubPart = subPart.trim()
                if (subIndex > 0) {
                    trimSubPart = '[' + trimSubPart;
                }
                let updatePart;

                switch (checkMarkdown(trimSubPart)) {
                    case NoteMarkdownTypes.Link:
                        res.push(
                            <WildHealthLinkButton
                                onClick={() => { window.open(getLink(trimSubPart), '_blank') }}
                                content={<span className={clsx([props.classLink ? props.classLink : classes.link], classes.breakWord, classes.string)}>{createTitleLink(trimSubPart)}</span>}
                                id="banner-link-url"
                            />);
                        return;
                    default:
                        updatePart = trimSubPart;
                }

                res.push(<span className="wh-tw-px-0.5 wh-tw-whitespace-pre-line wh-tw-break-words">{displayMessageTextWithLink(updatePart, props.classLink ? props.classLink : classes.link)}</span>);
            })
        })

        return res;
    }

    
    return <Box>{getTextSections(props.content)}</Box>
}