import {Query} from '@datorama/akita';
import {DocumentsState, documentsStore, DocumentsStore} from "./documents.store";

/**
 * Provides inputs queries
 */
export class DocumentsQuery extends Query<DocumentsState> {
    documentList$ = this.select(state => state.documentList);
    kbDocuments$ = this.select(state => state.kbDocuments);
    documentSourceTypes$ = this.select(state => state.documentSourceTypes);

    constructor(protected store: DocumentsStore) {
        super(store);
    }


}

export const documentsQuery = new DocumentsQuery(documentsStore);
