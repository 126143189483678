import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { useFlags } from 'launchdarkly-react-client-sdk';
import { onEmit } from "../../../common/helpers/on-emit";
import {CoverageModel, InsuranceModel, VerificationsHistoryModel} from "../../models/insurance.models";
import { insuranceService } from "../../services/insurance.service";
import { insuranceVerificationService } from "../../services/insuranceVerification.service";
import { insuranceQuery } from "../../stores/insurance.query";
import { SubscriptionModel, SubscriptionStatus, SubscriptionType } from "../../../payment/models/subscription.models";
import { subscriptionService } from "../../../payment/services/subscription.service";
import { subscriptionQuery } from "../../../payment/stores/subscriptionStore";
import { patientsQuery } from "../../../patients/stores/patientsStore";
import { isFeatureFlag } from "../../../common/components/featureFlags/featureFlags";
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";

interface InsuranceVerificationComponentState {
    isInsuranceLoading: boolean;
    isSubscriptionLoading: boolean;
    isPayByCash: boolean;
    isPayByCashLoading: boolean;
    isAddButtonLoading: boolean;
    isInsuranceAvailable: boolean;
    insuranceUnavailibilityReason: string;
    patientInsurances: Array<CoverageModel>;
    verificationHistory: Array<VerificationsHistoryModel>;
    insurances: InsuranceModel[];
}

export function useFacade(patientId: number): [
    InsuranceVerificationComponentState,
    boolean,
    boolean,
    () => void,
    () => void,
    (id: number) => void,
    (id: number) => void,
    (id: number) => void,
] {
    const featureFlags = useFlags();
    const isEnabledInsuranceAdd = isFeatureFlag(featureFlags, FeatureFlag.EnabledInsuranceAdd);
    const isEnabledInsuranceVerificationToggle = isFeatureFlag(featureFlags, FeatureFlag.EnabledInsuranceVerificationToggle);
    
    const [state, setState] = useState({
        isInsuranceLoading: true,
        isSubscriptionLoading: true,
        insurances: [],
        isPayByCash: true,
        isPayByCashLoading: false,
        isAddButtonLoading: false,
        isInsuranceAvailable: false,
        insuranceUnavailibilityReason: '',
        patientInsurances: [],
        verificationHistory: [],
    } as InsuranceVerificationComponentState);


    const handleCashPay = () => {
        setState(state => ({
            ...state,
            isPayByCashLoading: true
        }));

        const cb = () => setState(state => ({ ...state, isPayByCashLoading: false }));
        const success = () => state.isPayByCash ?  setState(state => ({ ...state, isPayByCashLoading: false, isPayByCash: false })) : setState(state => ({ ...state, isPayByCashLoading: false, isPayByCash: true }));

        const action = state.isPayByCash ? insuranceService.turnOnInsurance(patientId) : insuranceService.turnOffInsurance(patientId);

        action.subscribe(() => {
            subscriptionService.getPatientCurrent(patientId).subscribe(success, success)
        }, cb);
    }

    const handleAddInsurance = () => {
        if (state.isAddButtonLoading || state.isPayByCashLoading) {
            return;
        }

        insuranceService.requestCreateInsurance(patientId).subscribe(
            (model) => {
                setState(state => ({
                    ...state,
                    isAddButtonLoading: true
                }));
                insuranceService.createCoverageOnBehalf(model).subscribe(
                    () => {
                        setState(state => ({
                            ...state,
                            isAddButtonLoading: false
                        }));
                    },
                    () => {
                        setState(state => ({
                            ...state,
                            isAddButtonLoading: false
                        }));
                    }
                );
            }
        )
    }

    const displayName = (id: number) => {
        return state.insurances.find(el => el.id === id)?.name ?? ''
    }

    const displayNameHistory = (id: number) => {
        const insurance = state.patientInsurances.find(el => (+el.id) === id)?.insurance;
        if (!insurance) {
            return ''
        }
        return insurance.name;
    }

    const displayNumberCoverageHistory = (id: number) => {
        return state.patientInsurances.find(el => (+el.id) === id)?.memberId
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<VerificationsHistoryModel[]>(insuranceQuery.verificationList$, verificationList => {
                setState(state => ({
                    ...state,
                    verificationHistory: verificationList,
                }));
            }),
            onEmit<CoverageModel[]>(insuranceQuery.coverages$, coverages => {
                setState(state => ({
                    ...state,
                    patientInsurances: coverages,
                }));
            }),
            onEmit<InsuranceModel[]>(insuranceQuery.insurances$, insurances => {
                setState(state => ({
                    ...state,
                    insurances: insurances,
                }))
            }),
            onEmit<SubscriptionModel>(subscriptionQuery.subscription$, subscription => {
                if (subscription?.type === SubscriptionType.Insurance && subscription?.status === SubscriptionStatus.Active) {
                    setState((state) => ({ ...state, isPayByCash: false }))
                }
            })
        ];

        if (patientsQuery.getTargetPatientSubscription()) {
            subscriptionService.getPatientCurrent(patientId).subscribe(
                () => {
                    setState((state) => ({ ...state, isSubscriptionLoading: false }))
                },
                () => {
                    setState((state) => ({ ...state, isSubscriptionLoading: false }))
                }
            )
        } else {
            setState((state) => ({ ...state, isSubscriptionLoading: false }))
        }

        insuranceService.getInsuranceAvailabilityOnBehalf(patientId).subscribe(
            (available) => {
                setState(state => ({
                    ...state,
                    isInsuranceAvailable: available.isAvailable,
                    insuranceUnavailibilityReason: available.unavailabilityReason
                }));
            },
            () => {
                setState(state => ({
                    ...state,
                    isInsuranceAvailable: false
                }));
            }
        )

        insuranceService.getInsurances().subscribe(() => {
            insuranceService.getCoveragesByEmploy(patientId).subscribe(() => {
                setState((state) => ({ ...state, isInsuranceLoading: false }))
            }, () => {
                setState((state) => ({ ...state, isInsuranceLoading: false }))
            })
            insuranceVerificationService.getInsuranceVerification(patientId).subscribe();
        },
            () => {
                setState((state) => ({ ...state, isInsuranceLoading: false }))
            }
        );

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, []);

    return [
        state,
        isEnabledInsuranceAdd,
        isEnabledInsuranceVerificationToggle,
        handleCashPay,
        handleAddInsurance,
        displayName,
        displayNameHistory,
        displayNumberCoverageHistory
    ];
}