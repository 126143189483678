import {HealthSummaryState, healthSummaryStore, HealthSummaryStore} from "./healthSummary.store"
import { HealthSummaryDataModel } from "../models/healthSummary.models"
import {Query} from '@datorama/akita'

/**
 * Provides Health Summary queries
 */
export class HealthSummaryQuery extends Query<HealthSummaryState> {
    data$ = this.select(state => state.data)
    map$ = this.select(state => state.map)

    constructor(protected store: HealthSummaryStore) {
        super(store)
    }

    public getDataItem(key: string): HealthSummaryDataModel {
        return this.getValue().data.find(x=> x.key === key)
    }

    public getDataItemName(key: string): string {
        return this.getValue().map.flatMap(x=> x.items).find(x=> x.key === key)?.name
    }
}

export const healthSummaryQuery = new HealthSummaryQuery(healthSummaryStore)
