import React from 'react';
import {
    Box,
    FormControlLabel,
} from '@material-ui/core';
import { PatientOptionsModel } from "../../../patients/models/patient.model";
import { WildHealthSwitcher } from "../../../common/components/WIldHealthSwitcher";

interface PatientOptionsComponentProps {
    options: PatientOptionsModel;
    optionName: string;
    title: string;
    handleChanges: Function;
    disabled: boolean;
}

export const PatientOptionsComponent: React.FC<PatientOptionsComponentProps> = (props: PatientOptionsComponentProps) => {
    const {
        options,
        optionName,
        title,
        handleChanges,
        disabled
    } = props;

    return (
        <>
            <Box display="flex" alignItems='center'>
                <Box width={0.4}>
                    {title}
                </Box>

                <Box display="flex">
                    <Box p={1}>
                        <span>
                            No
                        </span>
                    </Box>
                    <Box ml={3}>
                        <FormControlLabel
                            control={<WildHealthSwitcher disabled={disabled} checked={options[optionName]} onChange={() => handleChanges(optionName, !options[optionName])} name={optionName} />}
                            label=""
                        />
                    </Box>
                    <Box p={1}>
                        <span>
                            Yes
                        </span>
                    </Box>
                </Box>
            </Box>
        </>
    )
};