import Axios from 'axios-observable';
import {patientSupplementsStore, PatientSupplementsStore} from "../stores/patientSupplementsStore/patientSupplements.store";
import {authHeader} from "../../common/helpers/auth-header";
import {snackService} from "../../common/snack/state";
import {PatientSupplementModel, SupplementSource} from '../models/patientSupplementModel';
import { Observable } from 'rxjs';
import {FullscriptSupplementModel} from "../models/fullscriptSupplementModels";

/**
 * Provides method for working with patient supplements
 */
export class SupplementsService {
    constructor(private patientSupplementsStore: PatientSupplementsStore) {
    }

    public get(patientId: number): Observable<PatientSupplementModel[]> {
        const url = `${process.env.REACT_APP_API_URL}Supplements?patientId=${patientId}`;
        const config = { headers: authHeader() };

        return new Observable((observer) => {
            Axios.get<PatientSupplementModel[]>(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.patientSupplementsStore.update({patientSupplements: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        })
    }

    public create(model: PatientSupplementModel, patientId: number, isFromNotes = false): Observable<any> {
        const url = `${process.env.REACT_APP_API_URL}Supplements?patientId=${patientId}`;
        const config = { headers: authHeader() };

        return new Observable((observer) => {
            Axios.post(url, model, config)
                .pipe()
                .subscribe(
                    (response) => {
                        !isFromNotes && snackService.success('New Supplement Added!');
                        patientSupplementsStore.create(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        })
    }

    public edit(model: PatientSupplementModel): Observable<any> {
        const url = `${process.env.REACT_APP_API_URL}Supplements`;
        const config = { headers: authHeader() };

        return new Observable((observer) => {
            Axios.put(url, model, config)
                .pipe()
                .subscribe(
                    (response) => {
                        snackService.success('Changes Saved!');
                        patientSupplementsStore.edit(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        })
    }

    public delete(id: number, isFromNotes = false): Observable<any> {
        const url = `${process.env.REACT_APP_API_URL}Supplements/${id}`;
        const config = { headers: authHeader() };

        return new Observable((observer) => {
            Axios.delete(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        !isFromNotes && snackService.deleteSuccess('Supplement Was Deleted!');
                        patientSupplementsStore.delete(response.data.id)
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        })
    }

    public getDefaultLink(): Observable<string> {
        const url = `${process.env.REACT_APP_API_URL}Supplements/Link/Default`;
        const config = { headers: authHeader() };

        return new Observable((observer) => {
            Axios.get(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        })
    }

    public searchInFullscript(query: string): Observable<FullscriptSupplementModel[]>
    {
        const url = `${process.env.REACT_APP_API_URL}Supplements/Fullscript?query=${query}`;
        const config = { headers: authHeader() };

        return new Observable((observer) => {
            Axios.get<FullscriptSupplementModel[]>(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        })
    }

    public byTreatmentPlan(treatmentPlanId: string): Observable<FullscriptSupplementModel[]>
    {
        const url = `${process.env.REACT_APP_API_URL}Supplements/Fullscript/TreatmentPlan?treatmentPlan=${treatmentPlanId}`;
        const config = { headers: authHeader() };

        return new Observable((observer) => {
            Axios.get<FullscriptSupplementModel[]>(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        })
    }
}

export const supplementsService = new SupplementsService(patientSupplementsStore);
