import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  IconButton,
  Popover,
  Typography
} from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import SearchIcon from '@material-ui/icons/Search';
import { useFacade } from './manageRecommendationsComponent.hooks';
import { useStyles, SearchTextField } from "./manageRecommendationsComponent.styles";
import { WildHealthContainer } from '../../../common/components/wildHealthContainer/WildHealthContainer';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { FeatureComponent } from "../../../common/components/featureFlags/FeatureComponent";
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import { RecommendationCategoryTagNames } from '../../../recommendations/models/recommendations.model';
import { WildHealthMenuItem } from '../../../common/components/wildHealthMenu/WildHealthMenu';
import { pageSizes } from "../../../common/pagination/models/page-sizes";
import { PaginationComponent } from "../../../common/pagination/components/paginationComponent/PaginationComponent";
import { CreateRecommendationDialog } from '../createRecommendationDialog/CreateRecommendationDialog';
import { CreateRecommendationTriggerDialog } from '../createRecommendationTriggerDialog/CreateRecommendationTriggerDialog';

export const ManageRecommendationsComponent: React.FC = () => {
  const [
    {
      isLoading,
      isMetricLoading,
      isSubmitting,
      isOpen,
      isOpenAddTrigger,
      menuAnchorEl,
      searchKey,
      metrics,
      recommendations,
      selectedRecommendation,
      createRecommendation,
      createRecommendationTrigger,
      errors,
      sortingColumns,
      sortingSource,
      totalCount,
      pageSize,
      selectedPage,
    },
    handleOnFilter,
    handleSorting,
    getAllAvailablePages,
    handlePageSizeChange,
    handlePageChange,
    handleChanges,
    handleTriggerChanges,
    handleToggleAction,
    handleToggleRecommendation,
    handleSave,
    handleDelete,
    handleToggleAddTrigger,
    handleSaveTrigger,
    handleAddComponent,
    handleRemoveComponent
  ] = useFacade();

  const classes = useStyles();
  const loading = isLoading || isMetricLoading;

  const renderTable = () => {
    if (loading) {
      return <WildHealthLinearProgress />
    }

    if (!loading && (!recommendations || !recommendations.length)) {
      return <WildHealthPlaceHolder message="No available recommendations found" />
    }

    return (
      <>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {
                  sortingColumns.map((item, index) =>
                    <WildHealthTableCell key={index}>
                      {item.source
                        ? <TableSortLabel
                            id={`sorting-recommendations-${item.title}`}
                            active={sortingSource === item.source}
                            direction={sortingSource === item.source ? item.direction : 'asc'}
                            onClick={() => handleSorting(item.source, item.direction)}
                          >
                            {item.title}
                          </TableSortLabel>
                        : item.title
                      }
                    </WildHealthTableCell>
                  )
                }
                <WildHealthTableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {recommendations.slice((selectedPage - 1) * pageSize, (selectedPage - 1) * pageSize + pageSize).map(recommendation => (
                <WildHealthTableRow key={recommendation.id}>
                  <WildHealthTableCell align="left" style={{ width: '55%' }}>
                    <Box className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-black">
                      {recommendation.content}
                    </Box>
                  </WildHealthTableCell>
                  <WildHealthTableCell align="left">
                    <Box className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-black">
                      {recommendation.order}
                    </Box>
                  </WildHealthTableCell>
                  <WildHealthTableCell align="left">
                    <Box className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-black">
                      {recommendation.recommendationTriggers.length}
                    </Box>
                  </WildHealthTableCell>
                  <WildHealthTableCell align="left">
                    <Box className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-black">
                      {recommendation.tags.map(tag => (RecommendationCategoryTagNames[tag])).join(', ')}
                    </Box>
                  </WildHealthTableCell>
                  <WildHealthTableCell width="5%" align="right">
                    <IconButton
                      id={`action-recommendation-${recommendation.id}`}
                      className={classes.menuIcon}
                      disabled={isSubmitting}
                      onClick={(e) => handleToggleAction(recommendation.id, e.currentTarget)}
                    >
                      <MoreHorizIcon />
                    </IconButton>
                  </WildHealthTableCell>
                </WildHealthTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box py={1} px={2}>
          <PaginationComponent
            pageSizes={pageSizes}
            pageSize={pageSize}
            selectedPage={selectedPage}
            handlePageSizeChange={handlePageSizeChange}
            totalCount={totalCount}
            availablePages={getAllAvailablePages()}
            handlePageSelect={handlePageChange}
          />
        </Box>
      </>
    )
  }

  return (
    <Box py={2} px={10} width={1}>
      <Box my={2} display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <FeatureComponent featureFlag={FeatureFlag.Navigation}>
            <Box className="wh-tw-font-semibold wh-tw-text-2xl wh-tw-black">{PagesNamesService.recommendations}</Box>
          </FeatureComponent>
        </Box>
        <Box display='flex' alignItems='center'>
          <Box width="310px">
            <SearchTextField
              fullWidth
              variant="outlined"
              className={classes.search}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnFilter(e.target.value)}
              placeholder='Search by Content'
              InputProps={{
                inputProps: {
                  className: classes.searchBase,
                },
                startAdornment: <SearchIcon className="wh-tw-text-gray2" />,
              }}
              value={searchKey}
            />
          </Box>
          <Box ml={2}>
            <WildHealthButton
              id="create-recommendation-button"
              disabled={loading}
              loading={isSubmitting}
              onClick={() => handleToggleRecommendation()}
              style={{ height: 48 }}
            >
              <Box className="wh-tw-text-base wh-tw-font-medium">New</Box>
            </WildHealthButton>
          </Box>
        </Box>
      </Box>

      <WildHealthContainer margin={0} minHeight={'100%'}>
        {renderTable()}
      </WildHealthContainer>
      
      <Popover
        id="recommendationActionPopover"
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => handleToggleAction(0, null)}
      >
        <WildHealthMenuItem
          id="recommendation-edit"
          onClick={() => handleToggleRecommendation()}
        >
          <Typography variant="inherit" noWrap>
            Edit
          </Typography>
        </WildHealthMenuItem>
        <WildHealthMenuItem
          id="add-recommendation-trigger"
          onClick={() => handleToggleAddTrigger(0)}
        >
          <Typography variant="inherit" noWrap>
            Add Trigger
          </Typography>
        </WildHealthMenuItem>
        <WildHealthMenuItem
          id="recommendation-delete"
          onClick={() => handleDelete()}
        >
          <Typography variant="inherit" noWrap>
            Delete
          </Typography>
        </WildHealthMenuItem>
      </Popover>
      <CreateRecommendationDialog
        isOpen={isOpen}
        isLoading={isSubmitting}
        params={createRecommendation}
        metrics={metrics}
        errors={errors}
        handleChanges={handleChanges}
        handleClose={() => handleToggleAction(0, null)}
        handleSave={handleSave}
        handleAddTrigger={handleToggleAddTrigger}
      />
      <CreateRecommendationTriggerDialog
        isOpen={isOpenAddTrigger}
        isLoading={isSubmitting}
        params={createRecommendationTrigger}
        recommendation={selectedRecommendation}
        metrics={metrics}
        errors={errors}
        handleChanges={handleTriggerChanges}
        handleClose={() => handleToggleAction(0, null)}
        handleSave={handleSaveTrigger}
        handleAddComponent={handleAddComponent}
        handleRemoveComponent={handleRemoveComponent}
      />
    </Box>
  );
}