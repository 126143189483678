import {useEffect, useState} from "react";
import {Subscription} from "recompose";
import {onEmit} from "../../../common/helpers/on-emit";
import {IErrorState} from "../../../common/validation/error-state";
import {LocationModel} from "../../../locations/models/locations.models";
import {locationsQuery} from "../../../locations/stores";
import {PermissionModel} from "../../models/permission.models";
import {RoleModel} from "../../models/role.models";
import {employeeService} from "../../services/employees.service";
import {permissionsService} from "../../services/permissions.service";
import {rolesService} from "../../services/roles.service";
import {employeesQuery} from "../../stores/employeesStore";
import {createEmployeeButtonComponentValidator} from "./createEmployeeButtonComponent.validator";
import {CreateEmployeeModel} from "../../models/employee.models";
import {authQuery} from "../../../auth/stores/auth";
import {roles} from "../../../common/constants/roles";
import { Gender } from "../../../common/models/user.models";


interface CreateEmployeeButtonComponentState extends IErrorState {
    isLoading: boolean;
    isOpen: boolean;
    rxntExpanded: boolean;
    employee: CreateEmployeeModel;
    permissions: PermissionModel[];
    locations: LocationModel[];
    roles: RoleModel[];
}

const initModel: CreateEmployeeModel = {
    firstName: '',
    lastName: '',
    email: '',
    roleId: 0,
    locationIds: [],
    permissions: [],
    credentials: '',
    registerInSchedulerSystem: false,
    npi: '',
    gender: Gender.None,
    rxntUserName: '',
    rxntPassword: ''
}

const defaultRolesForCalendar = [
    roles.coach,
    roles.provider,
    roles.careCoordinator
];

/**
 * Custom Hook to manage a view Model for Notifications component
 */
export function useFacade(): [
    CreateEmployeeButtonComponentState,
    (event: React.ChangeEvent<HTMLInputElement>) => void,
    (event: React.ChangeEvent<HTMLInputElement>) => void,
    (event: React.ChangeEvent<HTMLInputElement>) => void,
    (field: string, value: any) => void,
    (value: boolean) => void,
    () => void,
    (locationId?: number) => void,
    () => void,
    () => void,
] {

    const [state, setState] = useState({
        isLoading: false,
        isOpen: false,
        rxntExpanded: false,
        employee: Object.assign({}, initModel),
        locations: [],
        permissions: [],
        roles: [],
        errors: {}
    } as CreateEmployeeButtonComponentState);

    const handleChanges = (field: string, value: any) => {
        createEmployeeButtonComponentValidator.validateAndSetState(state, setState, field, value);
        const employee = state.employee;
        employee[field] = value;
        setState({
            ...state,
            employee: employee
        });
    }

    const handleSelectRole = (event: React.ChangeEvent<HTMLInputElement>) => {
        const id = parseInt((event.target as HTMLInputElement).value);
        const role = state.roles.find(i => i.id === id);

        if (role) {
            setState({
                ...state,
                employee: {
                    ...state.employee,
                    roleId: role.id,
                    permissions: role.permissions.map(x => x.id),
                    registerInSchedulerSystem: defaultRolesForCalendar.includes(role.id)
                }
            });
        }
    }

    const handleSelectLocation = (event) => {
        const locationIds = event.target.value;
        createEmployeeButtonComponentValidator.validateAndSetState(state, setState, 'locationIds', locationIds);
        setState({
            ...state,
            employee: {
                ...state.employee,
                locationIds: locationIds
            }
        });
    }

    const handleAddCalendar = (value: boolean) => {
        const employee = state.employee;
        employee.registerInSchedulerSystem = value;
        setState({
            ...state,
            employee: employee
        });
    }

    const handleSelectPermission = (event: React.ChangeEvent<HTMLInputElement>) => {
        const id = parseInt((event.target as HTMLInputElement).value);
        const item = state.permissions.find(i => i.id === id);

        if (item) {
            state.employee.permissions = ((event.target as HTMLInputElement).checked && item) ?
                [...state.employee.permissions, item.id] :
                state.employee.permissions.filter(i => i !== id);
            setState(state => ({ ...state }));
        }
    }

    const handleOpen = (locationId?: number) => {
        setState(state => ({
            ...state,
            isOpen: true,
            errors: {},
            employee: {
                ...state.employee,
                roleId: state.roles[0]?.id ?? 0,
                permissions: state.roles[0]?.permissions?.map(x => x.id) ?? [],
                locationIds: [locationId || authQuery.getTargetLocationId()]
            }
        }));
    }

    const handleCreate = () => {
        createEmployeeButtonComponentValidator.validateObjectAndSetState(state, setState, state.employee);
        if (!createEmployeeButtonComponentValidator.stateIsValid(state)) {
            return;
        }
        setState(state => ({
            ...state,
            isLoading: true,
        }));
        employeeService.createEmployee(state.employee).subscribe(() => {
            setState(state => ({
                ...state,
                errors: {},
                isOpen: false,
                rxntExpanded: false,
                isLoading: false,
                employee: Object.assign({}, initModel)
            }));
        },
        () => {
            setState(state => ({
                ...state,
                isLoading: false,
            }));
        })
    }

    const handleClose = () => {
        setState(state => ({
            ...state,
            errors: {},
            isOpen: false,
            rxntExpanded: false,
            employee: Object.assign({}, initModel)
        }));
    }

    const toggleRxNT = () => {
        setState(state => ({
            ...state,
            rxntExpanded: !state.rxntExpanded
        }));
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<RoleModel[]>(employeesQuery.roles$, roles => {
                const role = roles[0];
                setState(state => ({
                    ...state,
                    roles: roles,
                    employee: {
                        ...state.employee,
                        roleId: role?.id ?? 0,
                        permissions: role?.permissions?.map(x => x.id) ?? [],
                        registerInSchedulerSystem: defaultRolesForCalendar.includes(role?.id)
                    }
                }))
            }),
            onEmit<PermissionModel[]>(employeesQuery.permissions$, permissions => {
                setState(state => ({
                    ...state,
                    permissions: permissions
                }))
            }),
            onEmit<LocationModel[]>(locationsQuery.availableLocations$, locations => {
                setState(state => ({
                    ...state,
                    locations: locations,
                    employee: {
                        ...state.employee,
                        locationIds: [authQuery.getTargetLocationId()]
                    }
                }))
            }),
        ];

        rolesService.get();
        permissionsService.get();

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    };

    useEffect(useEffectCB, []);

    return [
        state,
        handleSelectLocation,
        handleSelectRole,
        handleSelectPermission,
        handleChanges,
        handleAddCalendar,
        handleCreate,
        handleOpen,
        handleClose,
        toggleRxNT
    ];
}