import {
    HealthSummaryDataModel,
    HealthSummaryMapItemModel,
    HealthSummaryMapModel
} from '../../models/healthSummary.models'
import { HealthSummaryWrapperComponent } from '../wrapperComponent/HealthSummaryWrapperComponent'
import { FeatureComponent } from '../../../common/components/featureFlags/FeatureComponent'
import { FeatureFlag } from '../../../common/components/featureFlags/featureFlags.models'
import { BlockItemComponent } from '../blockItemComponent/BlockItemComponent'
import { getKey, onlyUnique } from '../../helpers/healthSummaryHelper'
import { useStyles } from './HealthSummaryBlockItemsComponent.styles'
import { useFacade } from './healthSummaryBlockItemsComponent.hooks'
import { Box, Button, TextField } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import moment from 'moment'
import React from 'react'

export interface HealthSummaryBlockItemsComponentProps {
    data: HealthSummaryDataModel[]
    map: HealthSummaryMapModel
    rowFormat: boolean
    patientId?: number
}

export const HealthSummaryBlockItemsComponent: React.FC<HealthSummaryBlockItemsComponentProps> = (props: HealthSummaryBlockItemsComponentProps) => {
    const {
        data,
        map,
        rowFormat,
        patientId
    } = props

    const [
        handleAddInformation,
        handleEditNewItem,
        handleSaveNewItem,
        handleEditMode,
        cancelChanges,
        saveChanges,
        state
    ] = useFacade(map, patientId)

    const classes = useStyles()

    const buildItems = (item: HealthSummaryMapItemModel) => {
        const newItem = (key: string, val: string, name: string = item.name, tooltip: string = '') => ({ key: key, name: name, value: val, tooltip: tooltip })

        const valueItems = data.filter(x => getKey(x.key) === item.key)

        const result = valueItems.map(valueItem => {
            if (!valueItem) {
                return newItem(item.key, '', item.name, '')
            }

            //use name as value if it is needed
            const value = map.useNameValue ? valueItem.name : valueItem.value
            //using name to view label
            if (item.viewLabelCondition === value) {
                return newItem(valueItem.key, item.name, '', valueItem.tooltip)
            }

            //apply date format
            if (item.dateFormat) {
                const date = moment(value)

                const formatedValue = date.isValid() ? date.format(item.dateFormat) : value

                return newItem(valueItem.key, formatedValue)
            }

            return newItem(valueItem.key, value, item.name, valueItem.tooltip)
        })

        return result
    }

    const getItemsByMapKey = (items, section) => {

        switch (map.key) {
            case 'PROBLEMS_LIST':
                return items.map((item) =>
                    item.value &&
                    <Box className={classes.container}>
                        <BlockItemComponent
                            editMode={!newItem(section) && state.editMode}
                            value={item.value}
                            itemKey={item.key}
                            name={map.useNameValue ? '' : item.name}
                            tooltip={item.tooltip}
                        />
                    </Box>)
            default:
                return items.map((item) =>
                    item.value &&
                    <Box className={classes.container}>
                        <BlockItemComponent
                            editMode={!newItem(section) && state.editMode}
                            value={item.value}
                            itemKey={item.key}
                            name={map.useNameValue ? '' : item.name}
                        />
                    </Box>)
        }
    }

    const getItems = (subSection: string) => {
        const newItem = (item) => ({
            key: item.key,
            name: map.useNameValue ? '' : item.name,
            value: map.useNameValue ? item.name : item.value,
            tooltip: item?.tooltip
        })

        const subSectionItems = data
            .filter(x => x.key.includes(map.key) && x.key.includes(subSection ?? ''))
            .map(x => newItem(x))

        return map.items
            .filter(x => x.subSection === subSection)
            .flatMap(x => buildItems(x))
            .concat(subSectionItems)
    }

    const subSubsectionItem = (section: string, items) => ({
        section: section,
        items: items,
        hasItems: !!items.length
    })

    const sections = map.items
        .map(x => x.subSection)
        .filter(onlyUnique)
        .map(section => {
            return subSubsectionItem(section, getItems(section))
        })

    const newItem = (section: string) => state.draft.find(x => x.section === section && x.isNew)

    const AddInformationItem = (section: string) =>
        <FeatureComponent featureFlag={FeatureFlag.HealthSummaryEdit}>
            {
                state.editMode && !newItem(section) &&
                <Button className={classes.button} onClick={() => {
                    handleAddInformation(section)
                }}>
                    <AddIcon fontSize='small' />
                    <Box className={classes.buttonText}>Add Information</Box>
                </Button>
            }
            {
                newItem(section) &&
                <Box display='flex' alignItems='center'>
                    <TextField
                        onChange={(e) => {
                            handleEditNewItem(e.target.value)
                        }}
                        onBlur={() => {
                            handleSaveNewItem()
                        }}
                        value={newItem(section).value}
                        placeholder="Type here..."
                        variant="outlined"
                        size="small"
                        autoFocus
                        required
                    />
                </Box>
            }
        </FeatureComponent>
    return <HealthSummaryWrapperComponent
        disableUpdateButton={map.editRestricted}
        patientId={patientId}
        handleCancel={cancelChanges}
        isLoading={state.isLoading}
        handleEdit={handleEditMode}
        editMode={state.editMode}
        handleSave={saveChanges}
        isEmpty={!data.length}
        title={map.section}
        mapKey={map.key}
    >
        <Box className={classes.columnFormat}>
            <Box className={rowFormat ? classes.rowFormat : classes.columnFormat}>
                {
                    sections.map(({ section, items, hasItems }) => {
                        return (hasItems || state.editMode) &&
                            <Box>
                                <Box className={classes.title}>{section}</Box>
                                <Box className={classes.itemsBlock}>
                                    {
                                        getItemsByMapKey(items, section)
                                    }
                                </Box>
                                {AddInformationItem(section)}
                            </Box>
                    }
                    )
                }
            </Box>
        </Box>
        {/* figure our requirements about footer */}
        {/* { getFooter() } */}
    </HealthSummaryWrapperComponent>
}