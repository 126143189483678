import {availabilityStore, AvailabilityStore} from '../stores/availability/availability.store';
import {authHeader} from '../../common/helpers/auth-header';
import {snackService} from '../../common/snack/state';
import {authQuery} from '../../auth/stores/auth';
import Axios from 'axios-observable';
import {Observable} from 'rxjs';
import moment from 'moment';
import {PotentialEmployeesAvailabilityModel} from "../models/availability.models";

export class AvailabilityService {
    private url = `${process.env.REACT_APP_API_URL}SchedulerAvailability`;

    constructor(private availabilityStore: AvailabilityStore) {}

    public get(from: Date, to: Date) {
        const fromParam = moment(from).format('YYYY-MM-DDTHH:mm:ss');
        const toParam = moment(to).format('YYYY-MM-DDTHH:mm:ss');

        const config = {headers: authHeader()};

        Axios.get(`${this.url}?from=${fromParam}&to=${toParam}`, config).pipe()
            .subscribe(
                response => {
                    this.availabilityStore.setAvailability(authQuery.getEmployeeId(), response.data)
                },
                error => snackService.commonErrorHandler(error)
            );
    }

    public getCalendarById(employeeId: number, from: Date, to: Date, isEmployee: boolean) {
        const config = {headers: authHeader()};

        return new Observable(observer => {
            Axios.get(`${this.url}/${employeeId}
                ?from=${from.toISOString()}&to=${to.toISOString()}${isEmployee && "&employeeRequest=true"}`
                , config).pipe()
                .subscribe(
                    response => {
                        this.availabilityStore.setCurrentAvailability(employeeId, response.data)
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        })
    }

    public getByEmployeeIds(employeeIds: number[], from: Date, to: Date, configurationId: number, isEmployee: boolean): Observable<any> {
        const config = {headers: authHeader()};

        return new Observable(observer => {

            let url = `${this.url}/${employeeIds.join(',')}?from=${from.toISOString()}&to=${to.toISOString()}`;

            if (configurationId) {
                url += `&configurationId=${configurationId}`;
            }
            
            if (isEmployee) {
                url += `&employeeRequest=true`;
            }

            Axios.get(url, config).pipe()
                .subscribe(
                    response => {
                        this.availabilityStore.setAvailabilityByEmployeeIds(employeeIds, response.data)
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        })
    }

    public getPotential(from: Date, to: Date, configurationId: number, patientId: number): Observable<PotentialEmployeesAvailabilityModel> {
        const config = {headers: authHeader()};

        let query = `from=${from.toISOString()}`;
        query += `&to=${to.toISOString()}`;
        query += `&configurationId=${configurationId}`;
        query += patientId ? `&patientId=${patientId}` : '';

        return new Observable(observer => {
            Axios.get<PotentialEmployeesAvailabilityModel>(`${this.url}/employees?${query}`, config)
                .pipe()
                .subscribe(
                    response => {
                        this.availabilityStore.setBulkAvailability(response.data.employees, response.data.availability.users);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        })
    }
}

export const availabilityService = new AvailabilityService(availabilityStore);
