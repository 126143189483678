import { useEffect, useState } from "react";
import { AttachedFileModel } from "../../models/message.models";
import { Subscription } from "recompose";
import { onEmit } from "../../../common/helpers/on-emit";
import { employeeConversationsQuery } from "../../stores/employeeConversationsStore/employeeConversations.query";
import { conversationsService } from "../../services/conversations.service";

export interface Group {
    title: string;
    items: AttachedFileModel[];
}

interface AttachmentsDialogComponentState {
    isLoading: boolean;
    selectedNumber: number;
    isSliderDialogOpen: boolean;
    groups: Group[];
}


export function useFacade(conversationProxy: boolean): [
    AttachmentsDialogComponentState,
    (media: AttachedFileModel) => void,
    Function,
    (media: AttachedFileModel) => void,
] {
    const [state, setState] = useState({
        isLoading: false,
        isSliderDialogOpen: false,
        selectedNumber: 0,
        groups: [{
            title: 'PDFs',
            items: [],
        }, {
            title: 'Images',
            items: [],
        }],
    } as AttachmentsDialogComponentState);

    const handleToggleSliderDialog = (toShow: boolean, index: number) => {
        setState({ ...state, isSliderDialogOpen: toShow, selectedNumber: index });
    }

    const setUrl = (media: AttachedFileModel) => {
        if (conversationProxy) {
            if(media?.file !== null && media?.file !== undefined){
                media.file.getContentTemporaryUrl().then(url => {
                    media.url = url;
                });
            }
        } else {
            const model = { mediaSid: media.file[0].sid }
            conversationsService.createMediaUrl(model).subscribe((url: string) => {
                media.url = url
            })
        }
    }

    const handleGroup = (attachments: AttachedFileModel[]) => {
        const pdfs = state.groups[0];
        const images = state.groups[1];

        pdfs.items = [];
        images.items = [];

        attachments.forEach(media => {
            if (media.file.state.contentType === 'application/pdf') {
                pdfs.items.push(media);
            } else {
                images.items.push(media);
            }
        });

        const result = [];

        if (pdfs.items.length) {
            pdfs.title = `PDFs (${pdfs.items.length})`;
            result.push(pdfs);
        }

        if (images.items.length) {
            images.title = `Images (${images.items.length})`;
            result.push(images);
        }

        setState(state => ({ ...state, groups: result }));
    }

    const handleGroupView = (attachments: AttachedFileModel[]) => {
        const pdfs = state.groups[0];
        const images = state.groups[1];

        pdfs.items = [];
        images.items = [];

        attachments.forEach(media => {
            if (media.file[0].contentType === 'application/pdf') {

                pdfs.items.push(media);
            } else {
                images.items.push(media);
            }
        });

        const result = [];

        if (pdfs.items.length) {
            pdfs.title = `PDFs`;
            result.push(pdfs);
        }

        if (images.items.length) {
            images.title = `Images`;
            result.push(images);
        }

        setState(state => ({ ...state, groups: result }));
    }

    const handleDownloadAttachment = (media: AttachedFileModel) => {
        if (media.url) {
            window.open(media.url)
        } else {
            if (conversationProxy) {
                return media.file.getContentTemporaryUrl().then(url => window.open(url))
            }
            const model = { mediaSid: media.file[0].sid }
            conversationsService.createMediaUrl(model).subscribe((url: string) => {
                window.open(url)
            })
        }
    }


    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<AttachedFileModel[]>(conversationProxy
                ? employeeConversationsQuery.targetAttachments$
                : employeeConversationsQuery.targetAttachmentsView$, attachments => {
                    conversationProxy ? handleGroup(attachments) : handleGroupView(attachments)
                }),
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, []);

    return [state, handleDownloadAttachment, handleToggleSliderDialog, setUrl];
}