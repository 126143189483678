import {Observable} from "rxjs";
import Axios from "axios-observable";
import {authHeader} from "../../common/helpers/auth-header";
import {snackService} from "../../common/snack/state";
import {CreateReferralOrderModel, ReferralOrderModel, UpdateReferralOrderModel} from "../models/referralOrders.models";
import {ordersStore, OrdersStore} from "../stores/ordersStore/orders.store";

export class ReferralOrdersService {
    private url = `${process.env.REACT_APP_API_URL}Orders/Referral`;

    constructor(private store: OrdersStore) {
    }

    public create(model: CreateReferralOrderModel): Observable<ReferralOrderModel> {
        return new Observable<ReferralOrderModel>(observer => {
            Axios.post<ReferralOrderModel>(`${this.url}`, model, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.addReferralOrder(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    },
                );
        });
    }

    public update(model: UpdateReferralOrderModel): Observable<ReferralOrderModel> {
        return new Observable<ReferralOrderModel>(observer => {
            Axios.put<ReferralOrderModel>(`${this.url}`, model, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.updateReferralOrder(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    },
                );
        });
    }

    public get(patientId: number): Observable<ReferralOrderModel[]> {
        return new Observable<ReferralOrderModel[]>(observer => {
            Axios.get<ReferralOrderModel[]>(`${this.url}?patientId=${patientId}`, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({ referralOrders: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    },
                );
        });
    } 

    public getMyOrders(): Observable<ReferralOrderModel[]> {
        return new Observable<ReferralOrderModel[]>(observer => {
            Axios.get<ReferralOrderModel[]>(`${this.url}/My`, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({ referralOrders: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    },
                );
        });
    }

    public getForReview(): Observable<ReferralOrderModel[]> {
        return new Observable<ReferralOrderModel[]>(observer => {
            Axios.get<ReferralOrderModel[]>(`${this.url}/forReview`, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({ orderReferralForReview: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    },
                );
        });
    }

    public delete(id: number) {
        const config = { headers: authHeader() };

        return new Observable(observer => {
            Axios.delete(`${this.url}/${id}`, config).pipe()
                .subscribe(
                    response => {
                        this.store.deleteReferralOrder(response.data);
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }
}

export const referralOrdersService = new ReferralOrdersService(ordersStore);