import React from 'react';
import { Box, Grid, TextField, FormControl, FormHelperText, IconButton, InputAdornment, OutlinedInput } from "@material-ui/core";
import { useFacade } from "./applyPaymentCouponComponent.hooks";
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import { colors } from '../../../common/constants/colors';
import { useStyles } from "./paymentCouponComponent.styles";

interface PaymentCouponComponentProps {
    handleCouponChanges: Function;
    applyCoupon: Function;
    couponCode: string;
    isSmallScreen?: boolean;
}

export const ApplyPaymentCouponTestedComponent: React.FC<PaymentCouponComponentProps> = (props: PaymentCouponComponentProps) => {
    const [
        {
            model,
            errors
        },
        handleCouponChanges,
        handleCouponApply
    ] = useFacade(props.couponCode, props.applyCoupon);
    const classes = useStyles();

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={props.isSmallScreen ? 6 : 8}>
                    <TextField
                        id={`couponCode`}
                        size="small"
                        placeholder="Enter Promo Code"
                        variant="outlined"
                        style={{
                            width: '100%',
                            fontSize: 16,
                            lineHeight: 24,
                            fontWeight: 400,
                            fontFamily: 'Lato'
                        }}
                        InputProps={{ className: 'input', classes }}
                        value={model.couponCode}
                        error={!!errors['couponCode']}
                        helperText={errors['couponCode']}
                        onChange={(event) => handleCouponChanges(event.target.value)}
                    />
                </Grid>
                <Grid item xs={props.isSmallScreen ? 6 : 4}>
                    <WildHealthButton
                        id="apply-payment"
                        size="large"
                        fullWidth
                        style={{
                            maxWidth: 250,
                            fontSize: 16,
                            fontWeight: 500,
                            borderRadius: 8,
                            height: 40,
                            color: model.couponCode ? colors.primary : colors.mediumGray,
                        }}
                        disabled={!model.couponCode}
                        color={'secondary'}
                        onClick={() => handleCouponApply()} >
                        Apply
                    </WildHealthButton>
                </Grid>
            </Grid>
        </>
    )
};

export const ApplyPaymentCouponComponent: React.FC<PaymentCouponComponentProps> = (props: PaymentCouponComponentProps) => {
    const [
        {
            model,
            errors
        },
        handleCouponChanges,
        handleCouponApply
    ] = useFacade(props.couponCode, props.applyCoupon);
    const classes = useStyles();

    return (
        <>
            <FormControl
                variant="outlined"
                color='primary'
                required
                error={!!errors[`couponCode`]}
                size="small"
                fullWidth
                classes={{ root: classes.root }}
            >
                <OutlinedInput
                    id="couponCode"
                    placeholder="Promo Code"
                    type="text"
                    value={model.couponCode}
                    error={!!errors['couponCode']}
                    onChange={(event) => handleCouponChanges(event.target.value)}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                disabled={!model.couponCode}
                                aria-label="coupon code apply"
                                onClick={() => handleCouponApply()}
                                edge="end"
                            >
                                <Box className={classes.applyBtnText}>Apply</Box>
                            </IconButton>
                        </InputAdornment>
                    }
                />
                <FormHelperText>{errors[`couponCode`]}</FormHelperText>
            </FormControl>
        </>
    )
};