import { NoteModel, SectionType } from "../../models/notes.models";
import React from "react";
import { Box, Button, ButtonGroup, CircularProgress, Divider, useTheme, useMediaQuery, FormControl, Select, MenuItem } from "@material-ui/core";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import commonUseStyles from "../../../common/styles/common.styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useFacade } from "./soapNote.hooks";
import {
    IsSectionAmendSoap,
    renderEmptyMedicationsAndSupplementsToStart,
    renderEmptyMedicationsAndSupplementsToStop,
    renderItemsSection, renderMdm,
    renderMedicationsAndSupplementsToStart,
    renderMedicationsAndSupplementsToStop,
    renderNextAppointmentDate,
    renderNoteTitle,
    renderTextSection, renderVitals
} from "../../helpers/noteHelpers";
import { TextMarkdownComponent } from "../textMarkdownComponent/TextMarkdownComponent";
import { NoteSignatureComponent } from "../noteSignature/NoteSignatureComponent";
import EditIcon from '@material-ui/icons/Edit';
import { confirmService } from '../../../../services/confirm.service';
import { WildHealthStatusLabel } from "../../../common/components/wildHealthStatusLabel/WildHealthStatusLabel";
import moment from "moment";
import {useFlags} from "launchdarkly-react-client-sdk";

export interface SoapNoteComponentProps {
    patientId: number;
    handleGoBack: () => void;
    goBackTitle: string;
    note: NoteModel;
    handleGoToEditNote: (note: NoteModel) => void;
}

export const SoapNoteComponent: React.FC<SoapNoteComponentProps> = (props: SoapNoteComponentProps) => {
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const flags = useFlags();

    const {
        patientId,
        handleGoBack,
        goBackTitle,
        note,
        handleGoToEditNote
    } = props;

    const [
        {
            content,
            mdmCodes,
            internalContent,
            isPdfDownloading,
            isContentLoading,
            noteId,
            originalContent
        },
        handleDownloadPdf,
        handleChooseNote
    ] = useFacade(patientId, note);

    const displayDownloadPdfButton = () => {
        if (isPdfDownloading === true) {
            return (
                <Button>
                    <Box width="70px" display="flex" justifyContent="center">
                        <CircularProgress size={24} className={commonClasses.colorMain} />
                    </Box>
                </Button >
            )
        }
        return (
            <Button id="initial-consult-download-pdf" onClick={() => {
                handleDownloadPdf(note, content);
            }}>
                <Box display="flex" alignItems="center" mt={1}>
                    <Box>
                        <GetAppIcon />
                    </Box>
                    <Box mt={-1}>
                        PDF
                    </Box>
                </Box>
            </Button>);
    }

    const handleEditNote = () => {
        confirmService.confirm('Are you sure?', 'Are you sure you wish to amend this note? All changes will be logged.')
            .subscribe(() => {
                handleGoToEditNote(note);
            });
    }

    const displayEditButton = () => {

        return (
            <Button id="edit_current_note" onClick={() => handleEditNote()}>
                <Box display="flex" alignItems="center" mt={1}>
                    <Box>
                        <EditIcon />
                    </Box>
                </Box>
            </Button>
        );
    }

    const displaySelectVersion = () => {
        if (note.amendedNotes?.length === 0 || note.amendedNotes == null || note.amendedNotes.every(x => x.completedAt === null)) {
            return
        }

        return (
            <Box display="flex" alignItems="center" justifyContent="flex-start" ml={2}>
                <Box pr={2}>
                    <WildHealthStatusLabel color='normal'>Amended Note</WildHealthStatusLabel>
                </Box>
                <Box width="120px">
                    <FormControl color='primary' variant="outlined" size="small" fullWidth>
                        <Select
                            style={{ background: '#FFF' }}
                            id="choseNoteVersion"
                            value={noteId}
                            onChange={(v) => handleChooseNote(v.target.value)}>
                            <MenuItem key={0} value={note.id}>Original</MenuItem>
                            {
                                note.amendedNotes.filter(x => x.completedAt != null).map((note, i) => {
                                    return <MenuItem key={i + 1} value={note.id}>Amended - {moment(note.completedAt).format("MM/DD/YYYY")}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Box>
        );
    }

    const renderPlan = () => {
        return (
            <Box>
                <span className={isSmallScreen ? commonClasses.mediumTitle : commonClasses.secondaryTitle}>
                    Plan
                </span>
                <Box py={2}>
                    <Divider />
                </Box>
                <span style={{ whiteSpace: 'pre-line' }} className={isSmallScreen ? commonClasses.smallTitle : commonClasses.subtitle}>
                    {
                        internalContent.plan.planText
                            ? <TextMarkdownComponent content={internalContent.plan?.planText} />
                            : 'These are our main recommendations from this visit. These should be your primary focus.'
                    }
                </span>
            </Box>
        )
    }

    const isSomeSectionWasUpdated = () => {
        const sectionForChecking = [
            SectionType.Diagnosis,
            SectionType.Physical,
            SectionType.Plan,
            SectionType.Mdm,
            SectionType.MedicationAndSupplements,
            SectionType.Ros
        ];

        return sectionForChecking.some(el =>   IsSectionAmendSoap(originalContent, internalContent, el))
    }

    const renderNoteContent = (): JSX.Element => {
        return (
            <Box p={!isSmallScreen && 1.8}>
                <Box>
                    <Box>
                        {
                            renderPlan()
                        }
                    </Box>
                    {
                        internalContent.plan.labsText?.length > 0 &&
                        <Box mt={isSmallScreen ? "20px" : 4}>
                            {
                                renderTextSection('Labs', internalContent.plan.labsText, commonClasses, isSmallScreen)
                            }
                        </Box>
                    }
                    {
                        internalContent.diagnosis?.length > 0 &&
                        <Box mt={4}>
                            {
                                renderItemsSection('Diagnosis', internalContent.diagnosis.map(x => {
                                    return x.additionalInformation
                                        ? x.code + " " + x.description + ' - ' + x.additionalInformation
                                        : x.code + " " + x.description
                                }), commonClasses, isSmallScreen, true, '', true)
                            }
                        </Box>
                    }
                    {
                        internalContent.subjective?.length > 0 &&
                        <Box mt={4}>
                            {
                                renderTextSection('Subjective', internalContent.subjective, commonClasses, isSmallScreen)
                            }
                        </Box>
                    }
                    {
                        internalContent?.ROS?.rosEnabled &&
                        <Box mt={4}>
                            {
                                renderItemsSection('ROS', internalContent.ROS.rosValues, commonClasses, isSmallScreen, true, internalContent.ROS.rosValues.length > 0 ? 'All systems reviewed and negative except:' : "All systems reviewed and negative")
                            }
                        </Box>
                    }
                    {
                        (internalContent.medicationsSupplements.supplements.filter(x => !x.isInCurrent).length > 0 ||
                            internalContent.medicationsSupplements.medications.filter(x => !x.isInCurrent).length > 0 ||
                            internalContent.medicationsSupplements.rxntMedications?.filter(x => !x.isInCurrent).length > 0 ||
                            internalContent.medicationsSupplements.fullscriptSupplements?.length > 0) &&
                        <Box mt={4}>
                            {
                                renderMedicationsAndSupplementsToStart(internalContent.medicationsSupplements, commonClasses, flags)
                            }
                        </Box>
                    }
                    {
                        (internalContent.medicationsSupplements.supplements.filter(x => !x.isInCurrent).length <= 0 &&
                            internalContent.medicationsSupplements.medications.filter(x => !x.isInCurrent).length <= 0 &&
                            internalContent.medicationsSupplements.rxntMedications?.filter(x => !x.isInCurrent).length <= 0 &&
                            internalContent.medicationsSupplements.fullscriptSupplements?.length <= 0) &&
                        <Box mt={4}>
                            {
                                renderEmptyMedicationsAndSupplementsToStart(commonClasses)
                            }
                        </Box>
                    }
                    {
                        (internalContent.medicationsSupplements.supplements.filter(x => x.isStopped).length > 0 ||
                            internalContent.medicationsSupplements.rxntMedications?.filter(x => x.isStopped).length > 0 ||
                            internalContent.medicationsSupplements.medications.filter(x => x.isStopped).length > 0) &&
                        <Box mt={4}>
                            {
                                renderMedicationsAndSupplementsToStop(internalContent.medicationsSupplements, commonClasses, flags)
                            }
                        </Box>
                    }
                    {
                        (internalContent.medicationsSupplements.supplements.filter(x => x.isStopped).length <= 0 &&
                            internalContent.medicationsSupplements.rxntMedications?.filter(x => x.isStopped).length <= 0 &&
                            internalContent.medicationsSupplements.medications.filter(x => x.isStopped).length <= 0) &&
                        <Box mt={4}>
                            {
                                renderEmptyMedicationsAndSupplementsToStop(commonClasses)
                            }
                        </Box>
                    }
                    <Box mt={4}>
                        {
                            renderVitals(internalContent.physicalData, commonClasses, isSmallScreen)
                        }
                    </Box>
                    {
                        internalContent.mdmData.selectedCodeId &&
                        <Box mt={4}>
                            {
                                renderMdm(internalContent.mdmData, mdmCodes, commonClasses, internalContent.duration?.value)
                            }
                        </Box>
                    }
                    {
                        note.nextCoachAppointmentDate && note.nextProviderAppointmentDate &&
                        <Box pt={4}>
                            {
                                renderNextAppointmentDate(note.nextCoachAppointmentDate, note.nextProviderAppointmentDate, commonClasses, isSmallScreen)
                            }
                        </Box>
                    }
                    {
                        originalContent && internalContent &&
                        (
                            <Box mt={3}>
                                <Box py={2}>
                                    <Divider />
                                </Box>
                                {
                                    isSomeSectionWasUpdated() &&
                                    <Box pb={1} className={commonClasses.textBold}>Amended areas in this note:</Box>
                                }
                                {
                                    IsSectionAmendSoap(originalContent, internalContent, SectionType.Diagnosis) &&
                                    <Box>Diagnoses were updated.</Box>
                                }
                                {
                                    IsSectionAmendSoap(originalContent, internalContent, SectionType.Physical) &&
                                    <Box>Physical was updated.</Box>
                                }
                                {
                                    IsSectionAmendSoap(originalContent, internalContent, SectionType.Plan) &&
                                    <Box>Plan was updated.</Box>
                                }
                                {
                                    IsSectionAmendSoap(originalContent, internalContent, SectionType.MedicationAndSupplements) &&
                                    <Box>Medication And Supplements were updated.</Box>
                                }
                                {
                                    IsSectionAmendSoap(originalContent, internalContent, SectionType.Mdm) &&
                                    <Box>Mdm data was updated.</Box>
                                }
                                {
                                    IsSectionAmendSoap(originalContent, internalContent, SectionType.Ros) &&
                                    <Box>ROS was updated.</Box>
                                }
                            </Box>
                        )
                    }
                    <Box mt={4}>
                        <NoteSignatureComponent note={note} noteId={noteId} />
                    </Box>
                </Box>
            </Box>
        )
    }

    return (
        <Box m={5} mt={0} pt={3}>
            <Box display="flex " alignItems="center" justifyContent='space-between'>
                <ButtonGroup size="large" variant="text" color="default" aria-label="large outlined primary button group">
                    <Button
                        id="initial-consult-to-notes"
                        onClick={() => handleGoBack()}
                        startIcon={<ArrowBackIosRoundedIcon />}
                    >
                        {goBackTitle}
                    </Button>
                    <Button disabled>
                        {
                            isContentLoading
                                ? 'Loading . . .'
                                : renderNoteTitle(note)
                        }
                    </Button>
                    {
                        displaySelectVersion()
                    }
                </ButtonGroup>
                <Box display="flex " alignItems="center">
                    {
                        displayEditButton()
                    }
                    {
                        displayDownloadPdfButton()
                    }
                </Box>
            </Box>

            <Box mt={5}>
                <Box mt={5}>
                    {
                        isContentLoading && !internalContent
                            ? <WildHealthLinearProgress />
                            : renderNoteContent()
                    }
                </Box>
            </Box>
        </Box>
    );
}