import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { onEmit } from "../../../common/helpers/on-emit";
import { PatientMedicationModel } from "../../../patientSupplements/models/patientMedicationModel";
import { PatientSupplementModel } from "../../../patientSupplements/models/patientSupplementModel";
import { medicationsService } from "../../../patientSupplements/services/medications.service";
import { supplementsService } from "../../../patientSupplements/services/supplements.service";
import { patientMedicationsQuery } from "../../../patientSupplements/stores/patientMedicationsStore/patientMedications.query";
import { patientSupplementsQuery } from "../../../patientSupplements/stores/patientSupplementsStore/patientSupplements.query";
import { GetMaximumVersionOfNote, IsSectionAmendICC } from "../../helpers/noteHelpers";
import {downloadNoteOptions, IccContent, NoteContentModel, NoteModel} from "../../models/notes.models";
import { initialConsultNotePdfService, initialConsultNotePdfServiceOld } from "../../services/notes-pdf.service";
import { notesService } from "../../services/notes.service";
import { notesQuery, notesStore } from "../../stores/notes";
import { confirmService } from "../../../../services/confirm.service";
import { UserType } from "../../../auth/models/auth.enums";

interface InitialConsultComponentState {
    note: NoteModel;
    content: NoteContentModel;
    medications: PatientMedicationModel[];
    supplements: PatientSupplementModel[];
    isPdfDownloading: boolean;
    originalContent: NoteContentModel;
    noteId: number;
    internalContent: IccContent;
}

export function useFacade(patientId: number, note: NoteModel): [InitialConsultComponentState, Function, Function] {
    const [state, setState] = useState({
        note: note,
        content: null,
        medications: [],
        supplements: [],
        isPdfDownloading: false,
        noteId: GetMaximumVersionOfNote(note).id,
        originalContent: null,
        internalContent: {currentMedicalConditions: [], diastolicBloodPressure: 0, intention: "", internalContent: "", systolicBloodPressure: 0}
    });

    const handleDownloadPdf = (note: NoteModel,
        content: NoteContentModel,
        medications: PatientMedicationModel[],
        supplements: PatientSupplementModel[]) => {
        const cb = () => setState({ ...state, isPdfDownloading: false });
        confirmService.confirmAndSelectedNoteViewType(
            'Select the view type of the note',
            downloadNoteOptions,
        ).subscribe(
            (type) => {
                const isEmployeeView = type === UserType.Employee
                setState({
                    ...state,
                    isPdfDownloading: true
                });

                notesService.downloadNote(state.noteId, note.name, isEmployeeView).subscribe(
                    cb,
                    () => {
                        const result = IsSectionAmendICC(state.originalContent, state.content) ? "Note was updated." : "";
                        if(note.isOldIcc){
                            initialConsultNotePdfServiceOld.downloadNotePdf(note, content, medications, supplements, isEmployeeView, state.noteId, result,).subscribe(cb, cb);
                        }
                        else{
                            initialConsultNotePdfService.downloadNotePdf(note, content, medications, supplements, isEmployeeView, state.noteId, result,).subscribe(cb, cb);
                        }
                    }
                )
            },
            () => { }
        );
    }

    const handleChooseNote = (noteIdNew: number) => {
        setState(state => ({
            ...state,
            noteId: noteIdNew,
            isContentLoading: true
        }));

        notesService.getContentByEmployee(noteIdNew, patientId).subscribe(
            () => {
                setState(state => ({
                    ...state,
                    isContentLoading: false
                }))
            }
        );
    }


    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<NoteContentModel>(notesQuery.targetContent$, targetContent => {
                if (targetContent) {
                    if (targetContent.noteId == state.note?.id) {
                        setState(state => ({
                            ...state,
                            content: targetContent,
                            originalContent: targetContent,
                            isContentLoading: false,
                            internalContent: JSON.parse(targetContent.internalContent)
                        }));
                    } else {
                        setState(state => ({
                            ...state,
                            content: targetContent,
                            isContentLoading: false,
                            internalContent: JSON.parse( targetContent.internalContent)
                        }));
                    }
                }
            }),
            onEmit<PatientMedicationModel[]>(patientMedicationsQuery.patientMedications$, medications => {
                setState(state => ({ ...state, medications: medications }));
            }),
            onEmit<PatientSupplementModel[]>(patientSupplementsQuery.patientSupplements$, supplements => {
                setState(state => ({ ...state, supplements: supplements }));
            })
        ];
        notesService.getContentByEmployee(GetMaximumVersionOfNote(note).id, patientId).subscribe(
            () => {
                if (note?.id !== GetMaximumVersionOfNote(note).id) {
                    notesService.getContentByEmployee(note.id, patientId, true).subscribe(
                        (originalNoteContent) => {
                            setState(state => ({
                                ...state,
                                originalContent: originalNoteContent,
                            }))
                        }
                    );
                }
            }
        );

        supplementsService.get(patientId).subscribe();
        medicationsService.get(patientId).subscribe();

        return () => {
            notesStore.resetTargetContent()
            subscriptions.map(it => it.unsubscribe())
        };
    }, [note, patientId]);

    return [state, handleDownloadPdf, handleChooseNote];
}