import React from 'react';
import { Box, Button, useMediaQuery, useTheme } from '@material-ui/core';
import { PageSliderNavigationContext } from "../models/navigation.models";
import { ChevronLeft } from '@material-ui/icons';
import { commonUseStyles } from "../../common/styles/common.styles";
import { WildHealthButton } from "../../common/components/wildHealthButton/WildHealthButton";
import clsx from 'clsx';

/**
 * Represents page slider component state
 */
interface PageSliderComponentProps {
    context: PageSliderNavigationContext;
    hideNext?: boolean;
    backCenter?: boolean;
}

export const PageSliderComponent: React.FC<PageSliderComponentProps> = (props: PageSliderComponentProps) => {
    const {
        context,
        hideNext = true,
        backCenter = false,
    } = props;

    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Box flex={isSmallScreen && backCenter && hideNext && 1}>
                    <Box display={context.canGoBack() ? 'flex' : 'none'} justifyContent={isSmallScreen && backCenter && hideNext && "center"}>
                        <Button
                            id="page-slider-go-back"
                            onClick={() => context.goBack()}
                            className={commonClasses.backBtn}
                        >
                            <Box display="flex" alignItems="center">
                                <ChevronLeft className={commonClasses.colorNavy} />
                                <Box ml={0.5} className={clsx(commonClasses.size16, commonClasses.colorNavy)}>
                                    {context.backButton}
                                </Box>
                            </Box>
                        </Button>
                    </Box>
                </Box>

                <Box display={context.canGoNext() && !backCenter ? 'flex' : hideNext ? 'none' : ''} flex={isSmallScreen && !context.canGoBack() && !hideNext && 1}>
                    <WildHealthButton
                        fullWidth={true}
                        id="page-sider-go-next"
                        disabled={!context.canGoNext() && !hideNext}
                        size='large'
                        onClick={() => context.goNext()}
                    >
                        {context.nextButton}
                    </WildHealthButton>
                </Box>
            </Box>
        </>)
}
