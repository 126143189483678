import {Observable, Subject} from "rxjs";
import {CallbackModel} from "../../common/models/callback.model";
import {
    AgreementModel,
    AgreementOnViewModel,
    AgreementsOnConfirmModel,
    ConfirmAgreementModel
} from "../models/agreement.model";
import {PaymentPeriodModel} from "../models/paymentPeriod.models";
import {PaymentPriceModel} from "../models/paymentPrice.models";
import {PaymentPlanModel} from "../models/paymentPlan.models";

export class ConfirmAgreementService {
    public onConfirm = new Subject<CallbackModel<AgreementsOnConfirmModel>>();
    public onView = new Subject<CallbackModel<AgreementOnViewModel>>();

    public view(
        agreement: AgreementModel,
        paymentPlan: PaymentPlanModel,
        paymentPeriod: PaymentPeriodModel,
        paymentPrice: PaymentPriceModel) {
        const data: AgreementOnViewModel = {
            agreement: agreement,
            paymentPlan: paymentPlan,
            paymentPeriod: paymentPeriod,
            paymentPrice: paymentPrice
        }

        this.onView.next({
            data: data,
            callback: () => {}
        });
    }


    public confirm(
        paymentPlan: PaymentPlanModel,
        paymentPeriod: PaymentPeriodModel,
        paymentPrice: PaymentPriceModel): Observable<ConfirmAgreementModel[]> {
        const data: AgreementsOnConfirmModel = {
            agreements: paymentPeriod.agreements.slice(),
            paymentPlan: paymentPlan,
            paymentPeriod: paymentPeriod,
            paymentPrice: paymentPrice
        }

        if (!data.agreements || !data.agreements.length) {
            return new Observable(observer => {
                observer.next([]);
                observer.complete();
            })
        }

        return new Observable(observer => {
            this.onConfirm.next({
                data: data,
                callback: (result: ConfirmAgreementModel[]) => {
                    result
                        ? observer.next(result)
                        : observer.error();

                    observer.complete();
                }
            });
        });
    }
}

export const confirmAgreementService = new ConfirmAgreementService();