import { Store, StoreConfig } from "@datorama/akita";
import moment from "moment";
import { mediumPageSizes } from "../../common/pagination/models/page-sizes";
import { TileVitalModel, VitalModel } from "../models/vital.model";

export enum VitalsHistoryMode {
    Normal,
    Edit,
}

export enum VitalsHistoryView {
    Graph,
    Values,
}

/**
 * Represents vital state
 */
export interface VitalState {
    latestVital: TileVitalModel;
    vitals: VitalModel[];
    vitalsAverage: VitalModel[];
    totalVitals: number;
    view: VitalsHistoryView;
    mode: VitalsHistoryMode;
    startDate: Date;
    endDate: Date;
    selectedPage: number;
    pageSize: number;
}

/**
 * Creates initial state
 */
export function createInitialState(): VitalState {
    let year = new Date().getFullYear();

    const start = moment().year(year).startOf("year").toDate();
    const end = moment().year(year).endOf("year").toDate();

    return {
        latestVital: null,
        vitals: [],
        vitalsAverage: [],
        totalVitals: 0,
        view: 0,
        mode: 0,
        startDate: start,
        endDate: end,
        selectedPage: 1,
        pageSize: mediumPageSizes[1],
    };
}

/**
 * Provides vitals states management
 */
@StoreConfig({name: 'vitals', resettable: true })
export class VitalStore extends Store<VitalState> {
    constructor() {
        super(createInitialState());
    }

    public setPage(page: number) {
        this.update({selectedPage: page});
    }

    public setPageSize(pageSize: number) {
        this.update({pageSize: pageSize});
    }

    public setView(view: VitalsHistoryView) {
        this.update({view: view});
    }

    public setMode(mode: VitalsHistoryMode) {
        this.update({mode: mode});
    }

    public setTotal(total: number) {
        this.update({totalVitals: total});
    }

    public setDateRange(startDate: Date, endDate: Date) {
        this.update({startDate: startDate, endDate: endDate});
    }

    public addVital(item: VitalModel) {
        const value = this.getValue();
        this.update({vitals: [...value.vitals, item], totalVitals: value.totalVitals++});
    }

    public updateVital(item: VitalModel) {
        const vitals = this.getValue().vitals;
        this.update({ vitals: vitals.map(x => x.id === item.id ? item : x) });
    }
}

export const vitalStore = new VitalStore();
