import {IErrorState} from "../../validation/error-state";
import {useEffect, useState} from "react";
import {GeneralValidator} from "../../validation/general-validator";
import moment from "moment";
import {Observable} from "rxjs";

interface SelectDataDialogComponentState extends IErrorState {
    canSubmit: boolean;
    selectedDate: Date;
}

export const useFacade = (open: boolean, handleSubmit: Function, handleClose: Function, disableDates: Date[], handleCheckDate?: (date: Date) => Observable<void>): [
    SelectDataDialogComponentState,
    Function,
    Function,
    Function,
    Function
] => {
    const [state, setState] = useState({
        canSubmit: false,
        selectedDate: null,
        errors: {}
    } as SelectDataDialogComponentState);

    const dateFormat = 'YYYY-MM-DD';

    const toStringDate = (momentDate: moment.Moment): string => momentDate.format(dateFormat);

    const getDisableDates = (dates: Date[]): string[] => dates?.map(date => toStringDate(moment(date))) ?? [];

    const isDateInDisabledRange = (day: moment.Moment): boolean => getDisableDates(disableDates).includes(toStringDate(day));

    const dateAvailable = () => {
        GeneralValidator.removeError(state, 'selectedDate');
        setState(state => ({ ...state, canSubmit: true}));
    }

    const dateUnavailable = (error: string) => {
        GeneralValidator.addError(state, 'selectedDate', error);
        setState(state => ({ ...state, canSubmit: false}));
    }

    const handleDateChanges = async (date: Date) => {
        const now = new Date();
        const formatted = new Date(date.getFullYear(), date.getMonth(), date.getDate(), now.getHours(), now.getMinutes(), now.getSeconds());
        setState(state => ({...state, selectedDate: formatted, canSubmit: false}));

        isDateInDisabledRange(moment(date))
            ? dateUnavailable('Selected date is currently in use.')
            : dateAvailable();

        setState(state => ({ ...state, canSubmit: false}));

        if (handleCheckDate) {
            handleCheckDate(formatted).subscribe(dateAvailable, dateUnavailable);
        } else {
            setState(state => ({ ...state, canSubmit: true}));
        }
    }

    const handleCloseDialog = () => {
        setState(state => ({
            ...state,
            selectedDate: new Date()
        }))

        handleClose();
    }

    const handleSubmitForm = (event: any) => {
        event.preventDefault();

        if (!GeneralValidator.isValidState(state)) {
            return;
        }

        handleSubmit(state.selectedDate);
        handleCloseDialog();
    }

    const useEffectCB = () => {
        
        open && handleDateChanges(new Date());

        return () => {
        };
    };

    useEffect(useEffectCB, [open]);

    return [
        state,
        isDateInDisabledRange,
        handleDateChanges,
        handleCloseDialog,
        handleSubmitForm
    ]
}