import React from 'react';
import {Box, Table, TableBody, TableContainer, TableHead, TableRow} from '@material-ui/core';
import {BodyCircuitModel} from "../../models/exercisePlan.models";
import { ExerciseReportTableCell } from '../exerciseReportTable/ExerciseReportTableCell';
import { useStyles } from './../../a4Pages/healthReport.styles';

interface ExerciseBodyCircuitComponentProps {
    bodyCircuit: BodyCircuitModel;
}

export const ExerciseBodyCircuitComponent: React.FC<ExerciseBodyCircuitComponentProps> = (props: ExerciseBodyCircuitComponentProps) => {
    const {bodyCircuit} = props;
    const classes = useStyles();

    return(
        <>
            <Box width="100%">
                <Box>
                    {bodyCircuit.strengthCircuits.map((strengthCircuit, x) => {
                        return <Box key={x}>
                            <Box>
                                <span className={classes.exerciseReportSubTitle}>
                                    {strengthCircuit.name}
                                </span>
                            </Box>
                            <TableContainer>
                                <Table size="small" style={{border:"2px solid #A9A9A9"}}>
                                    <TableHead>
                                        <TableRow>
                                            <ExerciseReportTableCell>Exercise</ExerciseReportTableCell>
                                            <ExerciseReportTableCell>Genotype</ExerciseReportTableCell>
                                            <ExerciseReportTableCell>Set x Rep</ExerciseReportTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {strengthCircuit.exercises.map((exercise, y) => {
                                            return <TableRow key={`${x}${y}`}>
                                                <ExerciseReportTableCell style={{width: '30%'}}>{exercise.name}</ExerciseReportTableCell>
                                                <ExerciseReportTableCell style={{width: '33%'}}>{exercise.genotype}</ExerciseReportTableCell>
                                                <ExerciseReportTableCell style={{width: '37%'}}>{exercise.intensity}</ExerciseReportTableCell>
                                            </TableRow>
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    })}
                </Box>

                <Box>
                    {bodyCircuit.hiitWorkouts.map((hiitWorkout, x) => {
                        return <Box key={x}>
                            <Box mt={1}>
                                <span className={classes.exerciseReportSubTitle}>
                                    {hiitWorkout.name}
                                </span>
                            </Box>
                            <TableContainer>
                                <Table size="small" style={{border:"2px solid #A9A9A9"}}>
                                    <TableHead>
                                        <TableRow>
                                            <ExerciseReportTableCell> </ExerciseReportTableCell>
                                            <ExerciseReportTableCell>Name</ExerciseReportTableCell>
                                            <ExerciseReportTableCell>Set x Rep</ExerciseReportTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <ExerciseReportTableCell rowSpan={hiitWorkout.exercises.length + 1} style={{width: '30%'}}>{hiitWorkout.exercises[0].description}</ExerciseReportTableCell>
                                        </TableRow>
                                        {hiitWorkout.exercises.map((exercise, y) => {
                                            return <TableRow key={`${x}${y}`}>
                                                <ExerciseReportTableCell style={{width: '33%'}}>{exercise.name}</ExerciseReportTableCell>
                                                <ExerciseReportTableCell style={{width: '37%'}}>{exercise.intensity}</ExerciseReportTableCell>
                                            </TableRow>
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    })}
                </Box>
            </Box>
        </>
    );
}

export const ExerciseBodyCircuitMobileComponent: React.FC<ExerciseBodyCircuitComponentProps> = (props: ExerciseBodyCircuitComponentProps) => {
    const {bodyCircuit} = props;
    const classes = useStyles();

    return(
        <>
            <Box width="100%">
                <Box>
                    {bodyCircuit.strengthCircuits.map((strengthCircuit, x) => {
                        return <Box key={x}>
                            <Box px={2}>
                                <span className={classes.exerciseReportSubTitle}>
                                    {strengthCircuit.name}
                                </span>
                            </Box>
                            <Box mt="20px" width="100%" style={{ borderTop: "2px solid #A9A9A9",  borderBottom: "2px solid #A9A9A9" }}>
                                {
                                    strengthCircuit.exercises.map((exercise, i) => {
                                        return <Box key={`${exercise.name}-${i}`} style={i !== 0 ? {borderTop: "1px solid #A9A9A9"} : {}}>
                                            <Box width={1} px={2} pt={2} display="flex" className="size-0_875 normal-line-height" style={i % 2 === 1 ? {backgroundColor:"#f3f7fa"} : {}}>
                                                <Box width={0.35} className="text-medium">Exercise</Box>
                                                <Box width={0.65} className="text-light-medium">{exercise.name}</Box>
                                            </Box>
                                            <Box width={1} px={2} pt="10px" display="flex" className="size-0_875 normal-line-height" style={i % 2 === 1 ? {backgroundColor:"#f3f7fa"} : {}}>
                                                <Box width={0.35} className="text-medium">Genotype</Box>
                                                <Box width={0.65} className="text-light-medium">{exercise.genotype}</Box>
                                            </Box>
                                            <Box width={1} px={2} pt="10px" pb={2} display="flex" className="size-0_875 normal-line-height" style={i % 2 === 1 ? {backgroundColor:"#f3f7fa"} : {}}>
                                                <Box width={0.35} className="text-medium">Set x Rep</Box>
                                                <Box width={0.65} className="text-light-medium">{exercise.intensity}</Box>
                                            </Box>
                                        </Box>
                                    })
                                }
                            </Box>
                        </Box>
                    })}
                </Box>

                <Box>
                    {bodyCircuit.hiitWorkouts.map((hiitWorkout, x) => {
                        return <Box key={x} mt="30px">
                            <Box px={2}>
                                <span className={classes.exerciseReportSubTitle}>
                                    {hiitWorkout.name}
                                </span>
                            </Box>
                            <Box px={2} mt={1} className="size-0_875 text-medium" lineHeight="18px">
                                <span>
                                    {hiitWorkout?.exercises[0]?.description}
                                </span>
                            </Box>
                            <Box mt="20px" width="100%" style={{ borderTop: "2px solid #A9A9A9",  borderBottom: "2px solid #A9A9A9" }}>
                                {
                                    hiitWorkout.exercises.map((exercise, y) => {
                                        return <Box key={`${x}${y}`} style={y !== 0 ? {borderTop: "1px solid #A9A9A9"} : {}}>
                                            <Box width={1} px={2} pt={2} display="flex" className="size-0_875 normal-line-height" style={y % 2 === 1 ? {backgroundColor:"#f3f7fa"} : {}}>
                                                <Box width={0.35} className="text-medium">Name</Box>
                                                <Box width={0.65} className="text-light-medium">{exercise.name}</Box>
                                            </Box>
                                            <Box width={1} px={2} pt="10px" pb={2} display="flex" className="size-0_875 normal-line-height" style={y % 2 === 1 ? {backgroundColor:"#f3f7fa"} : {}}>
                                                <Box width={0.35} className="text-medium">Set x Rep</Box>
                                                <Box width={0.65} className="text-light-medium">{exercise.intensity}</Box>
                                            </Box>
                                        </Box>
                                    })
                                }
                            </Box>
                        </Box>
                    })}
                </Box>
            </Box>
        </>
    );
}
