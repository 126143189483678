import { AuthenticatedLayoutComponent } from '../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent'
import { HealthSummaryComponent } from '../components/healthSummaryComponent/HealthSummaryComponent'
import { PagesNamesService } from '../../../constants/pagesNames.constants'
import { TitlesService } from '../../../constants/title.constants'
import { MenuItemTypes } from '../../../models/menu.models'
import React from 'react'

export const PatientHealthSummaryPage: React.FC = () => {
    return (
        <AuthenticatedLayoutComponent
            selectedMenuItem={MenuItemTypes.HealthSummary}
            pageName={PagesNamesService.healthSummary}
            title={TitlesService.healthSummaryTitle}
            displayChildren='flex'
            hideBackground
        >
            <HealthSummaryComponent/>
        </AuthenticatedLayoutComponent>
    )
}