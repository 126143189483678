import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

type Props = {
    topBannerHeight: number;
};

export const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
    root: {
        borderColor: colors.mediumGray,
        borderRadius: 4,
        '& .MuiOutlinedInput-root': {
            borderColor: colors.mediumGray,
            borderRadius: 4,
        },
    },
    container: {
        backgroundColor: colors.white,
        paddingTop: 40,
        [theme.breakpoints.down('xs')]: {
            paddingTop: 12,
        },
    },
    main: {
        maxWidth: 736,
        margin: '0 auto',
    },
    inputFieldMain: {
        maxWidth: 456,
        margin: '0 auto',
    },
    concernMain: {
        maxWidth: 481,
        margin: '0 auto',
    },
    concernInterstitialMain: {
        maxWidth: 529,
        margin: '0 auto',
    },
    clinicallyProvenResultsMain: {
        maxWidth: 552,
        margin: '0 auto',
    },
    orderSummeryMain: {
        maxWidth: 360,
        margin: '0 auto',
    },
    concernBorder: {
        border: '1px solid #00000033',
        borderRadius: 4,
    },
    concernBorderActive: {
        border: `2px solid ${colors.mint}`,
        borderRadius: 4,
    },
    workingTogetherImage: {
        width: 291,
        [theme.breakpoints.down('xs')]: {
            width: 219,
        },
    },
    sandraAvatar: {
        width: 56,
        height: 56
    },
    termsPolicyLink: {
        color: colors.charcoal,
        cursor: 'pointer',
        paddingLeft: '4px',
        textDecoration: 'underline',
    },
    btnLink: {
        border: 'none',
        backgroundColor: colors.white,
        color: colors.primaryV,
        textAlign: 'start',
        fontSize: 16,
        fontWeight: 500,
        padding: 14,
        paddingLeft: 0,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            fontSize: 14,
        },
    },
    addIcon: {
        fill: colors.primaryV,
        width: 18,
        height: 18
    },
    couponBtnLink1: {
        border: 'none',
        backgroundColor: colors.white,
        color: colors.main,
        textAlign: 'center',
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '24px',
        padding: 0,
        cursor: 'pointer',
        borderBottom: '1px solid',
        borderBottomColor: colors.main,
    },
    couponBtnLink2: {
        border: 'none',
        backgroundColor: colors.white,
        color: colors.linkBlack,
        textAlign: 'center',
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '24px',
        padding: 0,
        cursor: 'pointer',
    },
    label: {
        fontSize: 14,
        fontWeight: 500,
        color: colors.gray900,
        textTransform: 'uppercase',
        [theme.breakpoints.down('xs')]: {
            fontSize: 12,
        },
    },
    title: {
        fontSize: 40,
        fontWeight: 500,
        lineHeight: '50px',
        color: colors.navy,
        [theme.breakpoints.down('xs')]: {
            fontSize: 28,
            lineHeight: '34px',
        },
    },
    smallTitle: {
        fontSize: 18,
        lineHeight: '25px',
        fontWeight: 500,
        color: colors.navy,
    },
    paymentMethodBlock: {
        padding: '12px 24px',
        borderRadius: 8,
        backgroundColor: colors.gray6
    },
    helperText: {
        paddingRight: 4,
        lineHeight: '24px',
        fontWeight: 500,
    },
    link: {
        color: colors.darkMint,
    },
    linkText: {
        fontWeight: 500,
    },
    setPasswordSection: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 24,
        paddingBottom: 24,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 16,
            paddingBottom: 16,
        },
    },
    doneIcon: {
        fill: colors.mint,
    },
    downIcon: {
        fill: colors.mint,
        width: 15
    },
    linkEmailSent: {
        width: 'fit-content',
        border: `1px solid ${colors.mint}`,
        borderRadius: 8,
        backgroundColor: '#F7FBF9',
        display: 'flex',
        alignItems: 'center',
        padding: '12px 16px',
        paddingRight: 24,
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    backBtnSection: {
        position: 'fixed',
        left: 120,
        top: ({ topBannerHeight }) => {
            if (topBannerHeight > 0) {
                return topBannerHeight + 24;
            } else {
                return 24;
            }
        },
        zIndex: 1999,
        [theme.breakpoints.down('sm')]: {
            left: 0,
            top: ({ topBannerHeight }) => {
                if (topBannerHeight > 0) {
                    return topBannerHeight + 26;
                } else {
                    return 26;
                }
            }
        },
    },
    stickyReviewBtnSection: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        background: colors.white,
        padding: 24,
        boxShadow: '0px -3px 6px rgba(185, 185, 185, 0.5)'
    },
    securityBlock: {
        background: 'linear-gradient(100.15deg, #194E67 14.09%, #A7CDC0 144.49%)',
        borderRadius: 11
    }
}));