import { Store, StoreConfig } from '@datorama/akita';
import {ProductModel} from "../../models/product.model";
import {PatientProductModel} from "../../models/patientProducts.model";


/**
 * Represents products state
 */
export interface ProductsState {
    products: ProductModel[];
    patientProducts: PatientProductModel[];
}

/**
 *  Creates initial state
 */
export function createInitialState(): ProductsState {
    return {
        products: [],
        patientProducts: [],
    };
}

/**
 * Provide products state store and management
 */
@StoreConfig({ name: 'products', resettable: true })
export class ProductsStore extends Store<ProductsState> {
    constructor() {
        super(createInitialState());
    }

}

export const productsStore = new ProductsStore();
