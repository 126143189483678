import React, { useEffect } from "react";
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useStyles } from "./messageComponent.styles";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import clsx from "clsx";
import { MessageAlertActionType } from "../../models/conversation.models";

interface ForwardMessageDialogProps {
    open: boolean;
    isForwarding: boolean;
    handleClose: () => void;
    handleSubmit: (action: MessageAlertActionType) => void;
}

export const ForwardMessageDialog: React.FC<ForwardMessageDialogProps> = (props: ForwardMessageDialogProps) => {
    const {
        open,
        isForwarding,
        handleClose,
        handleSubmit
    } = props;

    const classes = useStyles();

    useEffect(() => {
        let timer = null;
        if (open) {
            timer = setTimeout(() => {
                handleClose()
            }, 1000 * 60 * 30);
        }

        return () => {
            if (timer) {
                clearTimeout(timer)
            }
        };
    }, [open]);

    return (
        <Dialog open={open} onClose={() => handleClose()} aria-labelledby="form-dialog-title">
            <DialogTitle className={classes.dialogTitle} disableTypography>
                <IconButton id="appointment-options-close" className={classes.dialogTitleCloseAction} onClick={() => handleClose()}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={clsx(classes.dialogContent, "wh-tw-w-[450px]")}>
                <Box>
                    <Box className="wh-tw-text-xl wh-tw-text-black wh-tw-font-medium">
                        Your Message was Forwarded!
                    </Box>
                    <Box mt={2} mb={1}>
                        That message is best directed to our Support Team, so we've gone ahead and sent it their way. You can expect a response within 1 business day.
                    </Box>
                </Box>
            </DialogContent>

            <DialogActions className={classes.dialogActions}>
                <Box mx={1.5}>
                    <WildHealthButton
                        id="ignore-forward" 
                        color="tertiary"
                        size="large"
                        onClick={() => handleSubmit(MessageAlertActionType.Rejected)}
                    >
                        Ignore
                    </WildHealthButton>
                </Box>
                <Box>
                    <WildHealthButton
                        id="forward-to-support"
                        color="secondary"
                        size="large"
                        loading={isForwarding}
                        onClick={() => handleSubmit(MessageAlertActionType.Accepted)}
                    >
                        Forward to Support
                    </WildHealthButton>
                </Box>
            </DialogActions>
        </Dialog>
    );
}
