import React, { useState } from "react";
import { Box } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

interface PaginationComponentProps {
  selectedPage: number;
  pageSize: number;
  totalCount: number;
  handlePageSelect: (pageNumber: number) => void;
}

export const SmallPaginationComponent: React.FC<PaginationComponentProps> = (
  props: PaginationComponentProps
) => {
  const {
    selectedPage,
    pageSize,
    totalCount,
    handlePageSelect,
  } = props;

  const [page, setPage] = useState(selectedPage);

  const handleChange = (event, value) => {
    setPage(value);
    handlePageSelect(value);
  };

  return (
    <Box display='flex' justifyContent='center' width='100%'>
      <Pagination
        count={Math.ceil(totalCount / pageSize)}
        page={page}
        onChange={handleChange}
        variant='outlined'
        shape='rounded'
      />
    </Box>
  );
};
