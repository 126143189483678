export enum NoteSections {
    ROS,
    MedicationsAndSupplements,
    Objective,
    MDM,
    Diagnosis,
    Plan,
    SpecialTests,
    NextAppointment
}

export const noteSectionsNames : { [id: string]: string; } = {
    [NoteSections.ROS]: 'ROS',
    [NoteSections.MedicationsAndSupplements]: 'Medications And Supplements',
    [NoteSections.Objective]: 'Objective',
    [NoteSections.MDM]: 'MDM',
    [NoteSections.Diagnosis]: 'Diagnosis',
    [NoteSections.Plan]: 'Plan',
    [NoteSections.SpecialTests]: 'Additional Tests',
    [NoteSections.NextAppointment]: 'Next Appointment',
}