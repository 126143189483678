import { useState } from "react";
import { PatientAppointmentModel } from '../../models/appointments.models';
import {
    AppointmentLocationType
} from '../../models/appointments.enums';
import { RoutesConstants } from '../../../../constants/route.constants';

interface PatientAppointmentCardComponentState {
    isExpanded: boolean;
    menuAnchor: HTMLElement | null
}

/**
 * Custom Hook to manage a view Model for Appointments component
 */
export function useFacade(appointment: PatientAppointmentModel): [
    PatientAppointmentCardComponentState,
    () => void,
    (event: React.MouseEvent<HTMLButtonElement>) => void,
    (event: any) => void,
    () => void
] {
    const [state, setState] = useState({
        isExpanded: false,
        menuAnchor: null
    } as PatientAppointmentCardComponentState);

    const handleExpand = () => {
        setState({ ...state, isExpanded: !state.isExpanded });
    }

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setState({ ...state, menuAnchor: event.currentTarget });
    };

    const handleCloseMenu = (event: any) => {
        event.stopPropagation();
        setState({ ...state, menuAnchor: null });
    };

    const handleOpenGoogleMap = () => {
        if (appointment.locationType === AppointmentLocationType.InPerson) {
            const encodedAddress = encodeURIComponent(`${appointment.location?.streetAddress1}, ${appointment.location?.city}, ${appointment.location?.state} ${appointment.location?.zipCode}`);
            window.open(`${RoutesConstants.googleMapSearch}${encodedAddress}`, '_blank');
        }
        return;
    }

    return [state, handleExpand, handleOpenMenu, handleCloseMenu, handleOpenGoogleMap];
}