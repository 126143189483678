import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Chip,
  Popover,
  IconButton,
  CircularProgress
} from '@material-ui/core';
import React from 'react';
import { WildHealthContainer } from '../../../common/components/wildHealthContainer/WildHealthContainer';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { useFacade } from './manageInsurancesComponent.hooks';
import { useStyles, SearchTextField } from "./manageInsurancesComponent.styles";
import { FeatureComponent } from "../../../common/components/featureFlags/FeatureComponent";
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from 'clsx';
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { pageSizes } from "../../../common/pagination/models/page-sizes";
import { PaginationComponent } from "../../../common/pagination/components/paginationComponent/PaginationComponent";
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import { ReactComponent as TrashIcon } from "@img/icons/Trash.svg";
import { Autocomplete } from "@material-ui/lab";
import { addressQuery } from '../../../account/stores/addressStore';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import { StateModel } from '../../../account/models/address.model';

export const ManageInsurancesComponent: React.FC = () => {
  const [
    {
      isLoading,
      isStateLoading,
      isSubmitted,
      isAddingInsurance,
      insuranceCarriers,
      insurances,
      selectedCarrierId,
      selectedStates,
      searchKey,
      menuAnchorEl,
      selectedState,
      oldStates,
      removingInsuranceId,
      totalCount,
      pageSize,
      selectedPage,
      sortingColumns,
      sortingSource
    },
    handleChanges,
    handleStatesChange,
    handleAddInsurance,
    handleCancel,
    handleAddState,
    handleRemove,
    handleRemoveAllStates,
    handleToggleStates,
    handleOnFilter,
    getAllAvailablePages,
    handlePageSizeChange,
    handlePageChange,
    handleSorting
  ] = useFacade();

  const commonClasses = commonUseStyles();
  const classes = useStyles();

  const renderTable = () => {
    if (isLoading) {
      return <WildHealthLinearProgress />
    }

    if (!isLoading && (!insurances || !insurances.length)) {
      return <WildHealthPlaceHolder message="No available insurances found" />
    }

    return (
      <>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {
                  sortingColumns.map((item, index) =>
                    <WildHealthTableCell key={index}>
                      {item.source
                        ? <TableSortLabel
                            id={`sorting-insurance-${item.title}`}
                            active={sortingSource === item.source}
                            direction={sortingSource === item.source ? item.direction : 'asc'}
                            onClick={() => handleSorting(item.source, item.direction)}
                        >
                            {item.title}
                        </TableSortLabel>
                        : item.title
                      }
                    </WildHealthTableCell>
                  )
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {insurances.slice((selectedPage - 1) * pageSize, (selectedPage - 1) * pageSize + pageSize).map((insurance, index) => (
                <WildHealthTableRow key={index}>
                  <WildHealthTableCell align="left" style={{ width: '30%' }}><Box className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorBlack)}>{insurance.name}</Box></WildHealthTableCell>
                  <WildHealthTableCell align="left">
                    <Box display="flex" alignItems="center" flexWrap="wrap">
                      {insurance.states.map(state => (
                        <Box key={state.id} className={classes.stateItem}>
                          <span>{state.name}</span>
                          <CloseIcon className={classes.removeIcon} onClick={() => handleRemove(insurance.id, state.id)} />
                        </Box>
                      ))}
                      <Box className={classes.plusButton} onClick={(e) => handleToggleStates(insurance.id, e.currentTarget)}>
                        <AddIcon className={classes.plusIcon} />
                      </Box>
                    </Box>
                  </WildHealthTableCell>
                  <WildHealthTableCell align="right" style={{ width: '5%' }}>
                    <IconButton
                      id="mobile-menu-toggle-btn"
                      aria-describedby={insurance.name}
                      disabled={isSubmitted}
                      onClick={() => handleRemoveAllStates(insurance.id)}
                    >
                      {isSubmitted && removingInsuranceId === insurance.id ? <CircularProgress size={24} className={commonClasses.colorMain} /> : <TrashIcon />}
                    </IconButton>
                  </WildHealthTableCell>
                </WildHealthTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box py={1} px={2}>
          <PaginationComponent
            pageSizes={pageSizes}
            pageSize={pageSize}
            selectedPage={selectedPage}
            handlePageSizeChange={handlePageSizeChange}
            totalCount={totalCount}
            availablePages={getAllAvailablePages()}
            handlePageSelect={handlePageChange}
          />
        </Box>
      </>
    )
  }

  const renderAddInsurance = () => {
    if (isStateLoading) {
      return <WildHealthLinearProgress />
    }

    return (
      <Box p="30px" className={commonClasses.bgWhiteMain}>
        <Box className={clsx(commonClasses.size20, commonClasses.textMedium, commonClasses.colorBlack)}>Add insurance</Box>
        <Box px={20} width={1} mt="30px">
          <Box>
            <FormControl
              variant="outlined"
              color='primary'
              required
              size="small"
              disabled={isStateLoading || isSubmitted}
              classes={{ root: classes.root }}
              fullWidth>
              <Box className={commonClasses.inputLabel} mb={1}>Insurance Carrier</Box>
              <Select
                style={{ background: '#FFF' }}
                classes={{ root: classes.root }}
                value={selectedCarrierId}
                inputProps={{
                  name: 'insurance id',
                  id: 'insurance-label',
                  classes,
                }}
                renderValue={() => {
                  if (selectedCarrierId === null || !selectedCarrierId) {
                    return <Box className={commonClasses.colorMediumGray}>Select insurance</Box>;
                  }

                  return insuranceCarriers?.find(i => i.id === selectedCarrierId).name;
                }}
                displayEmpty
                onChange={(v) => handleChanges(v.target.value)}
              >
                {
                  insuranceCarriers.map((insurance, index) => (
                    <MenuItem value={insurance.id} key={`${insurance.id}_${index}`}>{insurance.name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Box>
          <Box mt="20px">
            <Box mb={1}>
              <span className={commonClasses.inputLabel}>Eligible States</span>
            </Box>
            <Autocomplete
              size='small'
              disableClearable
              multiple
              disabled={isStateLoading || isSubmitted}
              options={addressQuery.getStates().filter(x => !selectedStates.some(t => t.id === x.id))}
              getOptionLabel={x => x.name}
              onChange={(e, values) => handleStatesChange(values)}
              value={selectedStates}
              renderTags={(value: StateModel[], getTagProps) =>
                value.map((option: StateModel, index: number) => (
                  <Chip label={option.name} classes={{root: classes.chip}} deleteIcon={<CloseIcon />} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  InputProps={{ ...params.InputProps, type: 'search' }}
                  placeholder={selectedStates.length ? '' : 'Select states'}
                />
              )}
            />
          </Box>
          <Box mt="30px" display="flex" alignItems="center">
            <Box width="132px" mr="20px">
              <WildHealthButton
                id="cancel-state-button"
                disabled={isStateLoading || isSubmitted || isAddingInsurance}
                onClick={() => handleCancel()}
                color="secondary"
                fullWidth
                style={{ height: 48 }}
              >
                <Box className={clsx(commonClasses.size16, commonClasses.textMedium)}>Cancel</Box>
              </WildHealthButton>
            </Box>
            <Box width="132px">
              <WildHealthButton
                id="add-insurance-button"
                disabled={isSubmitted}
                loading={isStateLoading || isAddingInsurance}
                onClick={() => handleAddInsurance()}
                fullWidth
                style={{ height: 48 }}
              >
                <Box className={clsx(commonClasses.size16, commonClasses.textMedium)}>Add</Box>
              </WildHealthButton>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box py={2} px={10} width={1}>
      <Box my={2} display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <FeatureComponent featureFlag={FeatureFlag.Navigation}>
            <Box className={clsx(commonClasses.size24, commonClasses.textSemiBold, commonClasses.colorBlack)}>{PagesNamesService.eligibleInsurances}</Box>
          </FeatureComponent>
        </Box>
      </Box>
      {renderAddInsurance()}
      <Box mt={5} mb={2} display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Box className={clsx(commonClasses.size20, commonClasses.textMedium, commonClasses.colorBlack)}>All available insurances</Box>
        </Box>
        <Box width="310px">
          <SearchTextField
            fullWidth
            variant="outlined"
            className={classes.search}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnFilter(e.target.value)}
            placeholder='Search by Carrier or State'
            InputProps={{
              inputProps: {
                className: classes.searchBase,
              },
              startAdornment: <SearchIcon className={classes.icon} />,
            }}
            value={searchKey}
          />
        </Box>
      </Box>

      <WildHealthContainer margin={0} minHeight={'100%'}>
        {renderTable()}
      </WildHealthContainer>
      
      <Popover
        keepMounted
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={() => handleToggleStates(0)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box width="260px">
          <Autocomplete
            size='small'
            disableClearable
            placeholder="Select"
            classes={{ root: classes.root }}
            options={addressQuery.getStates().filter(x => !oldStates?.some(s => s.id === x.id))}
            getOptionLabel={x => x.name}
            onChange={(e, value) => handleAddState(value)}
            value={selectedState}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                InputProps={{ ...params.InputProps, type: 'search' }}
              />
            )}
          />
        </Box>
      </Popover>
    </Box>
  );
}