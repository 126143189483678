import React from 'react';
import { Box, Grid, IconButton, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { useFacade } from './healthPlanPastGoalsComponent.hooks';
import { WildHealthTableCell } from '../../../common/components/wildHealthTable/WildHealthTableCell';
import moment from 'moment';
import { WildHealthTableRow } from '../../../common/components/wildHealthTable/WildHealthTableRow';
import { toCurrentTimeZone } from '../../../timezones/helpers/timezone';
import { colors } from '../../../common/constants/colors';
import { PaginationComponent } from '../../../common/pagination/components/paginationComponent/PaginationComponent';
import { pageSizes } from '../../../common/pagination/models/page-sizes';
import { SearchComponent } from '../../../common/components/searchComponent/SearchComponent';
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from 'clsx';
import { goalCategoryNames } from '../../../notes/constants/goalCategoryNames';
import { healthGoalIcons } from '../../../widgets/components/healthPlanWidgetComponent/constants/healthGoalIcons';
import { GoalCategory, GoalModel } from '../../../notes/models/notes.models';
import { useStyles } from './healthPlanPastGoalsComponent.styles';
import { ChevronLeft } from '@material-ui/icons';



interface HealthPlanPastGoalsComponentProps {
    patientId: number;
    isMobile?: boolean;
    handleOpenGoal: (goal: GoalModel) => void;
    renderGoal?: (goal: GoalModel, index: number) => JSX.Element;
}

export const HealthPlanPastGoalsComponent: React.FC<HealthPlanPastGoalsComponentProps> = (props: HealthPlanPastGoalsComponentProps) => {
    const {
        patientId = null,
        isMobile = false,
        handleOpenGoal,
        renderGoal,
    } = props;

    const [
        {
            isLoading,
            goals,
            totalCount,
            pageSize,
            selectedPage,
        },
        handleSearchSubmit,
        getAllAvailablePages,
        handlePageSizeChange,
        handlePageChange,
        handleGoBack,
    ] = useFacade(patientId, isMobile);

    const commonClasses = commonUseStyles();
    const classes = useStyles();


    if (isLoading) {
        return <WildHealthLinearProgress />
    }

    const renderCategory = (category: GoalCategory) => {
        return (
            <Box className={classes.category} display="inline-flex" alignItems='center'>
                <Box ml={0.5}>
                    {healthGoalIcons.get(category)}
                </Box>
                <Box ml={0.5} mr={0.5} mt={0.5}>
                    {goalCategoryNames.get(category)}
                </Box>
            </Box>
        )
    }

    const renderTable = () => {
        return (
            <>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <WildHealthTableCell style={{ width: '15%' }} align="left">
                                    Date
                                </WildHealthTableCell>
                                <WildHealthTableCell style={{ width: '15%' }} align="left">
                                    Category
                                </WildHealthTableCell>
                                <WildHealthTableCell style={{ width: '70%' }} align="left">
                                    Goal
                                </WildHealthTableCell>


                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {goals.map((goal, index) => (
                                <WildHealthTableRow key={index}>
                                    <WildHealthTableCell align="left">
                                        {moment(toCurrentTimeZone(new Date(goal.date))).format('MMM YYYY')}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="left">
                                        {renderCategory(goal.category)}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="left" onClick={() => handleOpenGoal(goal)}>
                                        <Box>
                                            <Box className={clsx(classes.link, 'text-ellipsis--1')} fontWeight='400' color={colors.gray1}>{goal.name}</Box>
                                        </Box>
                                    </WildHealthTableCell>
                                </WildHealthTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box py={1} px={2}>
                    <PaginationComponent
                        pageSizes={pageSizes}
                        pageSize={pageSize}
                        selectedPage={selectedPage}
                        handlePageSizeChange={handlePageSizeChange}
                        totalCount={totalCount}
                        availablePages={getAllAvailablePages()}
                        handlePageSelect={handlePageChange}
                    />
                </Box>
            </>
        )
    }

    const renderWeb = () => {
        return <Box mb={5} width={1}>
            <Box mt={5} mb={2} display="flex" alignItems="center" justifyContent="space-between">
                <Box>
                    <Box className={clsx(commonClasses.size24, commonClasses.textMedium, commonClasses.colorBlack)}>Past Goals</Box>
                </Box>
                <Box width="310px">
                    <SearchComponent handleSearch={handleSearchSubmit} placeholder="Search" />
                </Box>
            </Box>
            <Box className={commonClasses.bgWhiteMain}>
                {goals?.length ? renderTable() : <Box height='100px' color={colors.gray1} fontSize='20px' display='flex' justifyContent='center' alignItems='center' width='100%'>
                    No past goals yet
                    </Box>}
            </Box>
        </Box>
    }

    const renderMobile = () => {
        return <Box mb={5} width={1} className={commonClasses.bgMain}>
            <Box my={2} alignItems="center">
                <Box display='flex' alignItems='center'>
                    <IconButton onClick={() => handleGoBack()} >
                        <ChevronLeft className={commonClasses.colorBlack} />
                    </IconButton>
                    <Box className={clsx(commonClasses.size22, commonClasses.textMedium, commonClasses.colorBlack)}>Past Goals</Box>
                </Box>

                <SearchComponent handleSearch={handleSearchSubmit} placeholder="Search" />
            </Box>
            {goals?.length
                ? <Box mt={4}>
                    <Grid container spacing={2}>
                        {
                            goals.map((goal, index) => {
                                return renderGoal(goal, index);
                            })
                        }
                    </Grid>
                </Box>
                : <Box height='100px' color={colors.gray1} fontSize='20px' display='flex' justifyContent='center' alignItems='center' width='100%' mt={4}>No past goals yet</Box>}
        </Box>
    }

    return isMobile ? renderMobile() : renderWeb();

}