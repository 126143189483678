import { useStyles } from './BlockItemComponent.styles'
import { useFacade } from './blockItemComponent.hooks'
import { Box, TextField, Tooltip } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import React from 'react'
import { isFocusInCurrentTarget } from "../../../common/helpers/is-focus-in-current-target";
import { colors } from '../../../common/constants/colors';
import InfoIcon from '@material-ui/icons/InfoRounded';

interface BlockItemComponentProps {
    editMode: boolean
    itemKey: string
    value: string
    name: string
    tooltip?: string
    sectionOfNote?: string
    handleEditItem?: Function
}

export const BlockItemComponent: React.FC<BlockItemComponentProps> = (props: BlockItemComponentProps) => {
    const {
        editMode,
        itemKey,
        value,
        name,
        tooltip = '',
        sectionOfNote,
        handleEditItem = () => { },
    } = props

    const [
        state,
        handleEditValue,
        handleEditMode,
        handleDelete,
        handleBlur,
        setInitialState,
    ] = useFacade()

    const classes = useStyles()

    const renderViewElement = () =>
        <Box className={classes.rootView}>
            <Box display='flex' alignItems='center' style={{ whiteSpace: 'pre-line' }}>
                {
                    name &&
                    <Box mr={1}>
                        <b>{name}:</b>
                    </Box>
                }
                <Box style={{ whiteSpace: 'pre-line' }}>
                    {value}
                </Box>

                {
                    tooltip && <Box width='30px' minWidth='30px' display='flex' justifyContent='flex-end'>
                        <Tooltip placement="top" arrow
                            title={tooltip}>
                            <InfoIcon fontSize='small' style={{ fill: colors.gray2 }} />
                        </Tooltip>
                    </Box>
                }
                {
                    editMode &&
                    <Box className={classes.button} onClick={() => handleEditMode(true, value, itemKey)}>
                        <EditIcon fontSize='small' />
                    </Box>
                }
            </Box>
        </Box>

    const renderEditElement = () =>
        <Box display='flex' alignItems='center' >
            <TextField
                onChange={(e) => { handleEditValue(e.target.value) }}
                onBlur={(e) => {
                    setTimeout(() => {
                        if (!isFocusInCurrentTarget(e)) {
                            if (sectionOfNote) {
                                handleEditItem(sectionOfNote, state.selectedKey, state.editValue)
                                setInitialState()
                            } else {
                                handleBlur()
                            }
                        }
                    }, 250)
                }}
                placeholder="Type here..."
                value={state.editValue}
                variant="outlined"
                size="small"
                fullWidth
                autoFocus
                required
            />
            {
                <Box id='block-delete-button' className={classes.deleteButton} onClick={() => {
                    if (sectionOfNote) {
                        handleEditItem(sectionOfNote, state.selectedKey, state.editValue, true)
                        setInitialState()
                    } else {
                        handleDelete()
                    }
                }}>
                    <DeleteIcon fontSize='small' />
                </Box>
            }
        </Box>

    return editMode && state.editMode ? renderEditElement() : renderViewElement()
}