import {useState, useEffect} from "react";
import {Subscription} from "rxjs";
import {inputsQuery} from "../../stores/inputsStore";
import {onEmit} from '../../../common/helpers/on-emit';
import {inputsService} from "../../services/inputs.service";
import {generalInputsComponentValidator} from "./generalInputsComponent.validator";
import {IErrorState} from "../../../common/validation/error-state";
import {GeneralInputsModel} from "../../models/input.models";

interface GeneralInputsComponentState extends IErrorState {
    inputs: GeneralInputsModel;
    isSaving: boolean;
    isVitalSaving: boolean;
    isCalculating: boolean;
    errorMesa: string;
}

export function useFacade(patientId: number): [GeneralInputsComponentState, Function, Function, Function, Function] {
    const [state, setState] = useState({
        inputs: null,
        errors: {},
        isSaving: false,
        isVitalSaving: false,
        isCalculating: false,
        errorMesa:""
    } as GeneralInputsComponentState);

    const handleChanges = (field: string, value: any) => {
        generalInputsComponentValidator.validateAndSetState(state, setState, field, value);
        typeof state.inputs[field] === "boolean"
            ? state.inputs[field] = !state.inputs[field]
            : state.inputs[field] = value;

        setState({...state, inputs: {...state.inputs}});
    }

    const handleNumericChanges = (field: string, value: any) => {
        generalInputsComponentValidator.validateAndSetState(state, setState, field, value);
        state.inputs[field] = value.toString().length ? Number(value) : null;
        setState({...state, inputs: {...state.inputs}});
    }

    const handleSubmit = () => {
        generalInputsComponentValidator.validateObjectAndSetState(state, setState, state.inputs);
        if (!generalInputsComponentValidator.stateIsValid(state)) {
            return;
        }

        setState(state => ({...state, isSaving: true}))
        const cb = () => {
            setState(state => ({
                ...state,
                isSaving: false,
            }));
        }

        inputsService.updateGeneralInputs(state.inputs, patientId).subscribe(cb, cb);
    }

    const handleCalculateMesa = () => {
        setState(state => ({...state, isCalculating: true}))
        inputsService.getMesaScore(patientId).subscribe((mesa)=>{
            let result = parseFloat(mesa.result);
            setState(state => ({
                ...state,
                inputs:{
                    ...state.inputs,
                    mesa: result
                },
                errorMesa: '',
                isCalculating: false
            }));
        }, (error)=>{
            setState(state => ({
                ...state,
                errorMesa: error.response.data.messages[0],
                isCalculating: false
            }));
        });
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<GeneralInputsModel>(inputsQuery.generalInputs$, inputs => {
                setState(state => ({...state, inputs}));
            })
        ];

        inputsService.getGeneralInputs(patientId);

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [patientId]);

    return [state, handleChanges, handleNumericChanges, handleSubmit, handleCalculateMesa];
}