import { useEffect, useState } from 'react';
import { Subscription } from 'recompose';
import { onEmit } from '../../../../../common/helpers/on-emit';
import { employeesQuery } from "../../../../stores/employeesStore";
import { preauthorizeRequestService } from '../../../../../payment/services/preauthorizeRequest.service';
import { PreauthorizeRequestModel } from '../../../../../payment/models/preauthorizeRequest.model';
import { IErrorState } from '../../../../../common/validation/error-state';
import { updatePreauthorizedEmployeeValidator } from "./updatePreauthorizedEmployee.validator";
import { getLastObject } from '../../../../../common/helpers/get-last-object';

interface ManagePreauthorizedEmployeesComponentState extends IErrorState {
    isLoading: boolean;
    isUpdating: boolean;
    requests: PreauthorizeRequestModel[];
    selectedRequest: PreauthorizeRequestModel;
    isEditOpen: boolean;
}

export function useFacade(productId: number): [
    ManagePreauthorizedEmployeesComponentState,
    (id: number) => void,
    (field: string, value: any) => void,
    () => void
] {

    const [state, setState] = useState({
        isLoading: true,
        isUpdating: false,
        employer: null,
        requests: [],
        selectedRequest: null,
        isEditOpen: false,
        errors: {},
    } as ManagePreauthorizedEmployeesComponentState);

    const handleToggleEditProfile = (id: number) => {
        const request = state.requests.find(x => x.id === id);
        if (request) {
            setState(state => ({ ...state, selectedRequest: Object.assign({}, request), isEditOpen: true }));
        } else {
            setState(state => ({ ...state, selectedRequest: null, isEditOpen: false, errors: {} }));
        }
    }

    const handleChanges = (field: string, value: any) => {
        updatePreauthorizedEmployeeValidator.validateAndSetState(state, setState, field, value);

        const params = state.selectedRequest;
        const keys = field.split(".");
        const key = keys.pop();
        const lastObject = getLastObject(keys, params);
        lastObject[key] = value;

        setState(state => ({...state, selectedRequest: Object.assign({}, params)}));
    }

    const handleUpdateProfile = () => {
        updatePreauthorizedEmployeeValidator.validateObjectAndSetState(state, setState, state.selectedRequest);

        if (!updatePreauthorizedEmployeeValidator.stateIsValid(state)) {
            return
        }
        setState(state => ({...state, isUpdating: true}));

        const cb = () => setState(state => ({...state, isUpdating: false, isEditOpen: false, selectedRequest: null}))
        preauthorizeRequestService.updateRequest(state.selectedRequest).subscribe(cb, cb)
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<PreauthorizeRequestModel[]>(employeesQuery.requests$, requests => {
                setState(state => ({...state, requests: requests.filter(x => x.employerProductId === productId) }))
            }),
        ];

        const cb = () => setState(state => ({ ...state, isLoading: false }))

        preauthorizeRequestService.getPreauthorizeRequests().subscribe(cb, cb);

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, []);

    return [
        state,
        handleToggleEditProfile,
        handleChanges,
        handleUpdateProfile
    ];
}