import React from 'react';
import { ReactComponent as Person } from '@img/icons/Person.svg';
import { IconProps } from './IconProps';
import { colors } from '../../modules/common/constants/colors';

const PersonIcon: React.FC<IconProps> = (props: IconProps) => {
  const { isSelected } = props;

  return (
    <Person fill={isSelected ? colors.main : colors.gray2} />
  )
}

export default PersonIcon;