import React from "react";
import { useReducer } from "react";
import { AgreementModel } from "../../models/agreement.model";
import { PaymentPeriodModel } from "../../models/paymentPeriod.models";
import { PaymentPlanModel } from "../../models/paymentPlan.models";
import { PaymentPriceModel } from "../../models/paymentPrice.models";
import { confirmAgreementService } from "../../services/confirmAgreement.service";
import { agreementPagesProvider } from "../AgreementsComponent/pages/agreementsPagesProvider";

export interface ViewAgreementsDialogState {
    isOpen: boolean;
    agreement: AgreementModel;
    agreementPage: Function;
    paymentPlan: PaymentPlanModel;
    paymentPeriod: PaymentPeriodModel;
    paymentPrice: PaymentPriceModel;
}

const defaultState: ViewAgreementsDialogState = {
    isOpen: false,
    agreement: null,
    agreementPage: null,
    paymentPlan: null,
    paymentPeriod: null,
    paymentPrice: null
}

const updateReducer = (state, updatedState) => {
    return { ...state, ...updatedState }
}

export function useFacade(initialState = defaultState): [
    ViewAgreementsDialogState,
    React.RefObject<any>,
    () => void] {

    const [state, setState] = useReducer(updateReducer, initialState);

    const dialogRef = React.createRef<any>();

    const handleClose = () => {
        setState(defaultState);
    }

    confirmAgreementService.onView.subscribe((model) => {
        if (!model.data || !model.data.agreement) {
            model.callback(null);
            return;
        }

        setState({
            ...state,
            isOpen: true,
            agreement: model.data.agreement,
            agreementPage: agreementPagesProvider.getPage(model.data.agreement),
            paymentPlan: model.data.paymentPlan,
            paymentPeriod: model.data.paymentPeriod,
            paymentPrice: model.data.paymentPrice
        });
    });

    return [state, dialogRef, handleClose];
}