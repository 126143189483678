import {useEffect, useState} from "react";
import {Subscription} from "rxjs";
import {onEmit} from "../../../common/helpers/on-emit";
import {IErrorState} from "../../../common/validation/error-state";
import {newPatientSupplementModel, PatientSupplementModel, SupplementSource} from "../../models/patientSupplementModel";
import {patientsSupplementsService} from "../../services/patientSupplements.service";
import {supplementsService} from "../../services/supplements.service";
import {patientSupplementsQuery} from "../../stores/patientSupplementsStore/patientSupplements.query";
import {PatientSupplementsUpdateProps} from "./PatientSupplementsUpdateComponent";
import {patientSupplementsUpdateComponentValidator} from "./patientSupplementsUpdateComponent.validator";

interface PatientSupplementsUpdateComponentState extends IErrorState {
    model: PatientSupplementModel;
    isLoading: boolean;
}

const getInitialState = (): PatientSupplementsUpdateComponentState => ({
    model: newPatientSupplementModel(SupplementSource.Supplement),
    isLoading: false,
    errors: {}
});

export function useFacade(props: PatientSupplementsUpdateProps): [
    PatientSupplementsUpdateComponentState,
    Function,
    Function,
    Function
] {
    const [state, setState] = useState(getInitialState());

    const handleChanges = (field: string, value: string) => {
        patientSupplementsUpdateComponentValidator.validateAndSetState(state, setState, field, value);

        const model = { ...state.model };
        model[field] = value
        setState({ ...state, model: model })
    }

    const handleSave = (patientId: number) => {
        patientSupplementsUpdateComponentValidator.validateObjectAndSetState(state, setState, state.model);
        if (!patientSupplementsUpdateComponentValidator.stateIsValid(state)) {
            return;
        }

        setState(state => ({
            ...state,
            ...Object.assign({}, getInitialState())
        }));
        props.handleClose();

        const service = props.patientId ? supplementsService : patientsSupplementsService;
        const action = state.model.id ? service.edit : service.create;
        action(state.model, patientId).subscribe();
    }

    const handleClose = () => {
        setState(state => ({
            ...state,
            ...getInitialState()
        }))

        props.handleClose();
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<PatientSupplementModel>(patientSupplementsQuery.editModel$, editModel => {
                setState(state => ({ ...state, model: editModel }));
                if (!Boolean(editModel.id)) {
                    setState(state => ({ ...state, isLoading: true }))
                    supplementsService.getDefaultLink().subscribe(link => {
                        setState(state => ({ ...state, model: { ...state.model, purchaseLink: link }, isLoading: false }))
                    })
                }
            })
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [
        state,
        handleClose,
        handleChanges,
        handleSave
    ];
}