import { ChangeEventHandler, useState } from 'react';
import { labOrdersService } from "../../services/labOrders.service";

export interface MarkLabOrderAsPaidDialogState { 
    isOpen: boolean;
    orderId: number;
    reason: string;
    canSubmit: boolean;
    handleMarkAsPaid: Function;
}

export function useFacade(): [MarkLabOrderAsPaidDialogState, ChangeEventHandler, Function, Function]{
    const [state, setState] = useState({
        isOpen: false,
        orderId: 0,
        reason: '',
        canSubmit: false
    } as MarkLabOrderAsPaidDialogState);

    const setDefaultState = () => {
        setState({
            ...state,
            isOpen: false,
            orderId: 0,
            reason: '',
            canSubmit: false,
            handleMarkAsPaid: null
        });
    }

    const handleUpdateReason = (e: any) => {
        const reason = e.target.value;
        const canSubmit = e.target.value.length > 0;
        setState({
            ...state,
            reason: reason,
            canSubmit: canSubmit
        })
    }

    const handleClose = () => {
        setDefaultState();
    }

    const handleSubmit = () => {
        state.handleMarkAsPaid(state.orderId, state.reason);
        setDefaultState();
    }

    labOrdersService.onMarkAsPaid.subscribe((callbackModel) => {
        setState({
            ...state,
            isOpen: true,
            handleMarkAsPaid: callbackModel.callback,
            orderId: callbackModel.data
        });
    })

    return [state, handleUpdateReason, handleClose, handleSubmit]
}