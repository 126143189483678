import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            [theme.breakpoints.down('sm')]: {
                padding: 0
            },
        },
        thread: {
            minHeight: 600,
            maxHeight: 600,
            overflowY: "auto",
        }
    })
);


