import React, { useEffect, useRef } from 'react';
import {ConversationModelBase, ConversationModelBaseView} from "../../models/conversation.models";
import { Box, List, ListSubheader } from "@material-ui/core";
import clsx from "clsx";
import { useStyles, } from "../conversationComponent/conversationComponent.styles";
import { convertMessagesToGroupsForView } from "../../helpers/messages.converter";
import { groupBy } from "../../../common/helpers/group.by";
import moment from 'moment';
import { toCurrentTimeZone } from '../../../timezones/helpers/timezone';
import { MessageComponentView } from '../messageComponent/MessageComponentView';
import { useStylesThread } from "../threadComponent/threadComponent.styles";
import { MessagesPlaceHolder } from '../../../common/components/wildHealthPlaceHolder/MessagesPlaceHolder';

interface ConversationComponentProps {
    messages: ConversationModelBaseView[];
    conversation: ConversationModelBase;
    threadHeight?: string;
    background?: string;
    isCompact?: boolean;
}

export const ConversationViewComponent: React.FC<ConversationComponentProps> = (props: ConversationComponentProps) => {
    const {
        conversation,
        threadHeight,
        background = '#FFF',
        isCompact = false
    } = props;

    const classes = useStyles();
    const messagesUnreadRef = useRef(null)
    const classesThread = useStylesThread()

    const scrollTo = () => {
        messagesUnreadRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        setTimeout(() => {
            scrollTo()
        }, 500);

    }, []);

    if (!props.messages?.length) {
        return (
            <Box height='100%'>
                <MessagesPlaceHolder message="No messages yet." />
            </Box>
        )
    }

    const messageGroups = props.messages ? convertMessagesToGroupsForView(props.messages, conversation.patients[0].vendorExternalIdentity, conversation.patients, conversation.employees) : [];

    const groupedByDate = groupBy(messageGroups, x => moment(toCurrentTimeZone(x.date)).format('MMM DD, yyyy'));

    const displayMessagesDate = (groups, date, index) => {

        const dateFormated = moment(toCurrentTimeZone(date)).format('MMM DD, yyyy');

        if (index === 0) {
            return dateFormated
        }

        if (index + 1 === groups.length) {
            return moment(toCurrentTimeZone(groups[index - 1].messages[0].date)).format('MMM DD, yyyy') === dateFormated ? '' : dateFormated
        }

        if (moment(toCurrentTimeZone(groups[index - 1].messages[0].date)).format('MMM DD, yyyy') === dateFormated) {
            return ''
        }
        return dateFormated

    }

    return (
        <Box
            id='thread'
            minHeight={'100%'}
            style={{ background: background }}
            className={clsx(classes.thread, 'flat-scroll')}
            height={threadHeight}
            maxHeight={threadHeight}
        >
            <List id='thread-list' className={classesThread.root}>
                <Box p={3} height='4px'>
                </Box>
                {
                    Array.from(groupedByDate).map(([dateKey, groups], index) => {
                        return (
                            <Box key={index}>
                                {
                                    groups.map((group, i) => {
                                        const messageIndex = group.messages[0].originalMessage.index;
                                        return (
                                            <Box key={messageIndex}>
                                                {displayMessagesDate(groups, group.messages[0].date, i) &&
                                                    <ListSubheader>
                                                        <Box pt={1} display="flex" justifyContent="center">
                                                            <div className={classesThread.header}>
                                                                {displayMessagesDate(groups, group.messages[0].date, i)}
                                                            </div>
                                                        </Box>
                                                    </ListSubheader>}
                                                <Box>
                                                    <MessageComponentView isCompact={isCompact} messageGroup={group} isMyMessage={group.isMyMessage} />
                                                </Box>
                                            </Box>
                                        )
                                    })
                                }
                                <span ref={messagesUnreadRef}></span>
                            </Box>
                        )
                    })
                }
            </List>
        </Box>

    )
}