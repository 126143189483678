import React from "react";
import { Box, Table, TableBody, TableContainer, TableHead, TableRow, IconButton, Grid, useTheme, useMediaQuery, Tooltip } from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import moment from "moment";
import clsx from "clsx";
import { ReactComponent as CeliacAndGluten } from '@img/CeliacAndGluten.svg';
import { ReactComponent as AcrobatPdfIcon } from '@img/icons/AcrobatPdfIcon.svg';
import { ReactComponent as LockedDocumentIcon } from '@img/icons/LockedDocumentIcon.svg';
import { toCurrentTimeZone } from '../../../timezones/helpers/timezone';
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { useStyles } from "./addOnReportsComponent.styles";
import { useFacade } from "./addOnReportsComponent.hooks";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { ReportSectionKey, SectionModel, PatientAddOnReportModel } from "../../models/healthReport.models";
import { BackButton } from '../../../common/components/wildHealthButton/BackButton';
import { EmployeeType, employeeTypesNames } from "../../../employee/models/employee.enums";
import { AppointmentWithType, AppointmentStatus } from "../../../appointments/models/appointments.enums";
import { CreatePatientAppointmentDialogRedesignComponent } from "../../../appointments/components/createPatientAppointmentUpdatedDialog/CreatePatientAppointmentDialogRedesignComponent";
import { PatientAppointmentModel } from "../../../appointments/models/appointments.models";
import { getAvailableWithTypes, getAvailableAppointmentType } from "../../../appointments/helpers/appointmentHelper";

interface AddOnReportsComponentProps {
    patientId?: number | null;
}

export const AddOnReportsComponent: React.FC<AddOnReportsComponentProps> = (props: AddOnReportsComponentProps) => {
    const { patientId } = props;
    const [
        {
            isLoading,
            isPatientReportLoading,
            isAppointmentsLoading,
            isAppointmentTypesLoading,
            isGenerating,
            isDownloading,
            isShowReport,
            patientReport,
            availableAddOnReports,
            assignedEmployees,
            appointmentTypes,
            appointments,
            locations,
            timeZone,
        },
        handleDownloadReport,
        handleToggleView,
        handleGenerateReport
    ] = useFacade(patientId);

    const classes = useStyles();

    const customTooltipClasses = {
        tooltip: classes.customWidth,
        arrow: classes.arrow,
        tooltipPlacementBottom: classes.tooltipPlacement,
        tooltipPlacementTop: classes.tooltipPlacement,
    };
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const getAppointmentsByWithType = (withTypes: AppointmentWithType[], appointments: PatientAppointmentModel[]): PatientAppointmentModel[] => {
        return appointments.filter(x => x.status !== AppointmentStatus.Canceled && withTypes.includes(x.withType));
    }

    if (isLoading || isPatientReportLoading) {
        return (
            <WildHealthLinearProgress />
        )
    }

    const renderTable = () => {
        if (!isLoading && !patientReport) {
            return <Box p={2} className="wh-tw-text-sm wh-tw-font-medium wh-tw-bg-white">You have no reports available</Box>
        }

        const content = JSON.parse(patientReport.contentJson)

        if (isSmallScreen) {
            return (
                <Box p={1.5} display="flex" justifyContent="space-between" className="wh-tw-bg-white">
                    <Box onClick={() => handleToggleView()}>
                        <Box className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-black" display="flex" alignItems="center" gridGap={8}>
                            {content?.chapters[0]?.title}
                            <ChevronRightIcon />
                        </Box>
                        <Box className="wh-tw-text-sm wh-tw-text-gray1">
                            {moment(toCurrentTimeZone(patientReport.createdAt)).format('L')}
                        </Box>
                    </Box>
                    <Box>
                        <IconButton
                            id={`download-report-button`}
                            disabled={isDownloading}
                            onClick={() => handleDownloadReport()}
                        >
                            <GetAppIcon className="wh-tw-text-primaryV" />
                        </IconButton>
                    </Box>
                </Box>
            )
        }

        return <Box className="wh-tw-bg-white">
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <WildHealthTableCell>
                                Report Name
                            </WildHealthTableCell>
                            <WildHealthTableCell width="150px">
                                Date
                            </WildHealthTableCell>
                            <WildHealthTableCell width="200px">
                                Action
                            </WildHealthTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <WildHealthTableRow>
                            <WildHealthTableCell align="left">
                                <Box className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-black">
                                    {content?.chapters[0]?.title}
                                </Box>
                            </WildHealthTableCell>
                            <WildHealthTableCell align="left">
                                <Box className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-black">
                                    {moment(toCurrentTimeZone(patientReport.createdAt)).format('L')}
                                </Box>
                            </WildHealthTableCell>
                            <WildHealthTableCell>
                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                    <WildHealthButton
                                        id="view-report-button"
                                        onClick={() => handleToggleView()}
                                        color="secondary"
                                        border="none"
                                    >
                                        View Report
                                    </WildHealthButton>
                                    <IconButton
                                        id={`download-report-button`}
                                        disabled={isDownloading}
                                        onClick={() => handleDownloadReport()}
                                    >
                                        <GetAppIcon className="wh-tw-text-primaryV" />
                                    </IconButton>
                                </Box>
                            </WildHealthTableCell>
                        </WildHealthTableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    }

    const renderGenericContent = (sections: SectionModel[], key: ReportSectionKey) => {
        const content = sections.find(x => x.key === key)?.content
        if (content) {
            return (
                <>
                    <Box className="wh-tw-text-sm wh-tw-font-medium">{content.title}</Box>
                    <Box className="wh-tw-text-sm">{content.text}</Box>
                </>
            )
        }
        return <></>
    }

    const renderRecommendationsContent = (sections: SectionModel[], key: ReportSectionKey) => {
        const content = sections.find(x => x.key === key)?.content
        if (content) {
            return <Box className="wh-tw-text-sm">{content?.recommendations?.value}</Box>
        }
        return <></>
    }

    const renderRiskValues = (sections: SectionModel[], key: ReportSectionKey) => {
        const content = sections.find(x => x.key === key)?.content
        if (content) {
            const CeliacDiseaseRisk = content.reportValueList.find(x => x.key === 'CeliacDiseaseRisk.classification')
            const RelativeRisk = content.reportValueList.find(x => x.key === 'GlutenSensitivity.value')
            const RelativeRiskPercentile = parseInt(RelativeRisk?.value) * 10

            return (
                <>
                    <Box display="flex" alignItems="center" gridGap={16}>
                        <Box className="wh-tw-text-sm wh-tw-font-medium">{CeliacDiseaseRisk?.label}</Box>
                        <Box px={1} py={0.5} className="wh-tw-bg-bad wh-tw-text-white wh-tw-text-sm wh-tw-rounded">{CeliacDiseaseRisk?.value}</Box>
                    </Box>
                    <Box display="flex" alignItems="center" gridGap={16} mt={1}>
                        <Box className="wh-tw-text-sm wh-tw-font-medium">{RelativeRisk?.label}</Box>
                        <Box px={1} py={0.5} className="wh-tw-bg-low wh-tw-text-white wh-tw-text-sm wh-tw-rounded">{RelativeRiskPercentile}th Percentile</Box>
                    </Box>
                </>
            )
        }
        return <></>
    }

    const renderCeliacPresent = (sections: SectionModel[], key: ReportSectionKey) => {
        const content = sections.find(x => x.key === key)?.content
        if (content) {
            return (
                <>
                    <Box className="wh-tw-text-sm wh-tw-font-medium">{content.title}</Box>
                    <Box mt={1.5} className="wh-tw-bg-white wh-tw-rounded-lg">
                        <Box display="flex" alignItems="center">
                            <Box flex={1} py={2} pl={3} className="wh-tw-text-gray1 wh-tw-text-sm wh-tw-font-medium">Celiac Haplotype</Box>
                            <Box flex={1} textAlign="center" className="wh-tw-text-gray1 wh-tw-text-sm wh-tw-font-medium">Result</Box>
                        </Box>
                        {content.reportValueList.map((item, index) => (
                            <Box key={index} display="flex" alignItems="center" className="wh-tw-border wh-tw-border-solid wh-tw-border-gray100">
                                <Box flex={1} py={2} pl={3} className="wh-tw-text-sm wh-tw-border-r wh-tw-border-solid wh-tw-border-gray100">{item.label}</Box>
                                <Box flex={1} display="flex" justifyContent="center">
                                    <Box px={1} py={0.5} className={clsx("wh-tw-text-sm", item.value == 'True' && "wh-tw-bg-bad wh-tw-text-white wh-tw-rounded")}>
                                        {item.value == 'True' ? 'Yes' : 'No'}
                                    </Box>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </>
            )
        }
        return <></>
    }

    const renderReportContent = () => {

        const content = JSON.parse(patientReport.contentJson)

        return (
            <Box>
                <Box>
                    <BackButton id="go-to-dashboard" onClick={() => handleToggleView()}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                        >
                            <ChevronLeftIcon />
                            <Box ml={1}>
                                <span>Additional Reports</span>
                            </Box>
                        </Box>
                    </BackButton>
                </Box>
                <Box my={2} display="flex" alignItems="center" justifyContent="space-between">
                    <Box>
                        <Box className="wh-tw-text-sm wh-tw-font-medium">Genetic Risk Review</Box>
                        <Box className="wh-tw-text-xl wh-tw-font-semibold">Celiac Disease & Gluten Sensitivity</Box>
                    </Box>
                    {!isSmallScreen && (
                        <Box display="flex" alignItems="center" gridGap={16}>
                            <WildHealthButton
                                id="download-report-button"
                                color="secondary"
                                size="large"
                                loading={isDownloading}
                                onClick={() => handleDownloadReport()}
                            >
                                <Box mr={2}>Download</Box>
                                <GetAppIcon />
                            </WildHealthButton>
                            {renderScheduleToReviewButton()}
                        </Box>
                    )}
                </Box>
                <Box py={2.5} px={3} className="wh-tw-bg-white wh-tw-rounded-lg">
                    <Box>
                        {renderGenericContent(content?.chapters[0]?.pages[0]?.sections, ReportSectionKey.CeliacDisease)}
                    </Box>
                    <Box mt={2}>
                        {renderGenericContent(content?.chapters[0]?.pages[0]?.sections, ReportSectionKey.GlutenSensitivity)}
                    </Box>
                    <Box mt={2}>
                        {renderGenericContent(content?.chapters[0]?.pages[0]?.sections, ReportSectionKey.Symptoms)}
                    </Box>
                    <Box mt={2}>
                        {renderGenericContent(content?.chapters[0]?.pages[0]?.sections, ReportSectionKey.CausalityAndDiagnosis)}
                    </Box>
                    <Box mt={2}>
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={7} md={7}>
                                {renderRiskValues(content?.chapters[0]?.pages[0]?.sections, ReportSectionKey.RiskValues)}
                            </Grid>
                            <Grid item xs={12} sm={5} md={5}>
                                <Box display="flex" justifyContent="flex-end"><CeliacAndGluten /></Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box mt={2}>
                        {renderRecommendationsContent(content?.chapters[0]?.pages[0]?.sections, ReportSectionKey.CeliacAndGlutenSensitivityRecommendation)}
                    </Box>
                </Box>
                <Box mt={5}>
                    {renderCeliacPresent(content?.chapters[0]?.pages[0]?.sections, ReportSectionKey.CeliacHaplotypes)}
                </Box>
            </Box>
        )
    }

    const renderScheduleToReviewButton = () => {
        if (isAppointmentsLoading || isAppointmentTypesLoading) {
            return <></>
        }
        
        const withTypes: Array<AppointmentWithType> = [AppointmentWithType.HealthCoachAndProvider, AppointmentWithType.Provider];
        const targetAppointments = getAppointmentsByWithType(withTypes, appointments);
        const availableWithTypes = getAvailableWithTypes(appointmentTypes);
        const targetWithType = availableWithTypes.find(x => withTypes.includes(x));
        const availableType = getAvailableAppointmentType(appointmentTypes, targetWithType);
        const employee = assignedEmployees?.find(el => el.role.displayName === employeeTypesNames.get(EmployeeType.Provider))
        const disableSchedule = !(!!availableType && !!employee && targetAppointments?.length > 0);

        return (
            <Box>
                <CreatePatientAppointmentDialogRedesignComponent
                    locations={locations}
                    assignedEmployees={assignedEmployees}
                    appointmentTypes={appointmentTypes}
                    appointmentWithType={targetWithType}
                    timeZone={timeZone}
                    disableSchedule={disableSchedule}
                    position={EmployeeType.Provider}
                    patientId={patientId}
                    forScheduleToReview={true}
                />
            </Box>
        )
    }

    const renderCannotGeneratePatientReportReason = (availableAddOnReport: PatientAddOnReportModel) => {
        if (patientId) {
            return !availableAddOnReport.hasDnaResults ? 'We are missing a DNA file to generate this report.  If you feel like this patient should have a DNA file uploaded, please create a ticket in the bug channel to get this resolved.' : 'We are missing data to generate this report for you. Please create a ticket in the bug channel to get this resolved.';
        } else {
            return !availableAddOnReport.hasDnaResults ? 'We are missing a DNA file to generate this report for you. Please reach out to support@wildhealth.com if you feel like this is an error and they will help you resolve this.' : 'We are missing data to generate this report for you. Please reach out to support@wildhealth.com and they will help you resolve this.';
        }
    }

    return (
        <Box width={1} p={3}>
            {isShowReport && patientReport ? renderReportContent() : <>
                <Box my={1} display="flex" alignItems="center" justifyContent="space-between">
                    <Box className="wh-tw-text-xl wh-tw-font-semibold">My Reports</Box>
                    {patientReport && renderScheduleToReviewButton()}
                </Box>
                <Box mt={1.5}>
                    {renderTable()}
                </Box>
                <Box mt={5}>
                    <Box className="wh-tw-text-xl wh-tw-font-semibold">Recommended For You</Box>
                    <Box mt={1.5}>
                        <Grid container spacing={2}>
                            {availableAddOnReports && availableAddOnReports.length > 0 && availableAddOnReports.map(x => (
                                <Grid item xs={12} sm={6} md={4}>
                                    <Box key={x.patientReportId} p={3} pt={2} className="wh-tw-bg-white">
                                        <Box display="flex" alignItems="center" justifyContent="space-between">
                                            <Box p={1} className="wh-tw-text-primaryV wh-tw-text-xs wh-tw-font-semibold wh-tw-bg-primary06 wh-tw-rounded">Free</Box>
                                            {x.canGeneratePatientReport && x.hasDnaResults ?
                                                <Box><AcrobatPdfIcon /></Box> :
                                                <Tooltip key="index" placement="top" arrow
                                                    title={<Box>
                                                        <Box className="wh-tw-text-white wh-tw-text-sm wh-tw-font-semibold" pb={0.5}>Report is Locked</Box>
                                                        <Box className="wh-tw-text-white wh-tw-text-xs">{renderCannotGeneratePatientReportReason(x)}</Box>
                                                    </Box>}
                                                    classes={customTooltipClasses}>
                                                    <LockedDocumentIcon />
                                                </Tooltip>
                                            }
                                        </Box>
                                        <Box my={2} className="wh-tw-text-sm wh-tw-font-medium">{x.reportTypeName}</Box>
                                        <Box>
                                            <WildHealthButton
                                                id="generate-report-button"
                                                color={x.canGeneratePatientReport ? 'secondary' : 'tertiary'}
                                                size="large"
                                                loading={isGenerating}
                                                disabled={!x.canGeneratePatientReport || !!patientReport}
                                                onClick={() => handleGenerateReport(x.reportType)}
                                            >
                                                Generate
                                            </WildHealthButton>
                                        </Box>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Box>
            </>}
        </Box>
    )
}