import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { onEmit } from "../../../common/helpers/on-emit";
import { DnaOrderModel } from "../../models/dnaOrders.models";
import { dnaOrdersService } from "../../services/dnaOrdersService";
import { dnaOrdersQuery } from "../../stores/dnaOrdersStore";
import { authQuery } from '../../../auth/stores/auth';
import { UserType } from "../../../auth/models/auth.enums";

interface DnaViewModel extends DnaOrderModel {
    isOpen: boolean;
    isEdit: boolean;
    isUpdating: boolean;
}

interface DnaOrdersComponentState {
    isLoading: boolean;
    orders: DnaViewModel[];
    selectedOrder: DnaViewModel;
    isOpened: boolean;
}

export function useFacade(patientId: number): [
    DnaOrdersComponentState,
    Array<string>,
    (selectedOrderId: number) => void,
    (selectedOrderId: number) => void,
    (selectedOrderId: number, field: string, value: string) => void,
    (selectedOrderId: number) => void
] {
    const [state, setState] = useState({
        isLoading: true,
        orders: [],
        selectedOrder: null,
        isOpened: false,
    } as DnaOrdersComponentState);

    const columns = authQuery.getType() === UserType.Employee ? [
        'Order Date',
        'Status',
        'Provider Order #',
        'Barcode',
        'Outbound Shipping #',
        'Return Shipping #',
        ''
    ] : [
        'Order Date',
        'Status',
        'Provider Order #',
        'Barcode',
        'Outbound Shipping #',
        'Return Shipping #'
    ];

    const handleToggleOrderDetails = (selectedOrderId: number) => {
        const item = state.orders.find(i => i.id === selectedOrderId);
        if (item) {
            item.isOpen = !item.isOpen;
            setState(state => ({ ...state, selectedOrder: item, isOpened: item.isOpen }));
        }
    }

    const handleToggleEditOrder = (selectedOrderId: number) => {
        const item = state.orders.find(i => i.id === selectedOrderId);
        if (item) {
            item.isEdit = !item.isEdit;
            setState(state => ({ ...state }));
        }
    }

    const handleChange = (selectedOrderId: number, field: string, value: string) => {
        const item = state.orders.find(i => i.id === selectedOrderId);
        if (item) {
            item[field] = value;
            setState(state => ({ ...state }));
        }
    }

    const handleUpdateOrder = (selectedOrderId: number) => {
        const item = state.orders.find(i => i.id === selectedOrderId);
        if (item) {
            item.isUpdating = !item.isUpdating;
            setState(state => ({ ...state }));

            const updateModel = {
                id: item.id,
                number: item.number,
                barcode: item.barcode,
                patientShippingNumber: item.patientShippingNumber,
                laboratoryShippingNumber: item.laboratoryShippingNumber,
            }

            const cb = () => {
                item.isEdit = !item.isEdit;
                item.isUpdating = !item.isUpdating;
                setState(state => ({ ...state }));
            }

            dnaOrdersService.updatePatientDnaOrders(updateModel).subscribe(cb, cb);
        }
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<DnaOrderModel[]>(dnaOrdersQuery.orders$, orders => {
                setState(state => ({ ...state, orders: orders.map(i => { return i as DnaViewModel }) }));
            }),
        ];

        const cb = () => setState(state => ({ ...state, isLoading: false }));

        if (patientId) {
            dnaOrdersService.get(patientId).subscribe(cb, cb);
        } else {
            dnaOrdersService.getMyOrders().subscribe(cb, cb);
        }


        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [patientId]);

    return [state, columns, handleToggleOrderDetails, handleToggleEditOrder, handleChange, handleUpdateOrder];
}