import { useState, useEffect } from "react";
import { onEmit } from "../../../common/helpers/on-emit";
import { IErrorState } from "../../../common/validation/error-state";
import { Subscription } from "rxjs";
import { EmployeeShortModel } from "../../../employee/models/employee.models";
import { employeesQuery } from "../../../employee/stores/employeesStore";
import { EmployeeType } from "../../../employee/models/employee.enums";
import { healthReportServices } from "../../services/healthReport.services";
import { employeeService } from "../../../employee/services/employees.service";
import { patientsQuery } from "../../../patients/stores/patientsStore/patients.query";
import { PatientModel } from "../../../patients/models/patient.model";

interface ResetPatientPasswordDialogState extends IErrorState {
    newReviewer: EmployeeShortModel;
    reviewers: EmployeeShortModel[];
}

export function useFacade( handleClose: Function, reportReviewerId: number, patientId: number, reportId: number): [ResetPatientPasswordDialogState, Function, Function] {
    const [state, setState] = useState({
        newReviewer: null,
        reviewers: [],
        errors: {}
    } as ResetPatientPasswordDialogState);

    const handleReviewerChange = (id: number) => {
        setState(state => ({
            ...state,
            newReviewer: state.reviewers.find(c => c.id === id),
        }));
    }

    const getSaveModel = () => {
        return {
            id: reportId,
            patientId: patientId,
            reviewerId: state.newReviewer.id,
        };
    }

    const handleSubmitForm = (event: Event) => {
        event.preventDefault();

        healthReportServices.saveNewReviewer(getSaveModel())
        handleClose();
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<EmployeeShortModel[]>(employeesQuery.couchesAndProviders$, employees => {
                setState(state => ({
                    ...state,
                    newReviewer: employees.find(c => c.id === reportReviewerId),
                    reviewers: employees.filter(c => c.type === EmployeeType.Provider)
                }));
            }),
            onEmit<PatientModel>(patientsQuery.targetPatient$, patient => {
                if (patient?.id == patientId) {
                    employeeService.getActiveCoachesAndProviders(patient.locationId).subscribe();
                }
            }),
        ];
    
        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [patientId]);

    return [state, handleReviewerChange, handleSubmitForm];
}
