import Axios from 'axios-observable';
import { Observable } from 'rxjs';
import { authHeader } from "../../common/helpers/auth-header";
import { snackService } from "../../common/snack/state";
import {
    CreateEmployeeModel,
    CreateFellowModel,
    EmployeeModel,
    EmployeeShortModel,
    FellowShortModel,
    UpdateEmployeeModel,
    UpdateFellowModel
} from '../models/employee.models';
import { employeesStore, EmployeesStore } from "../stores/employeesStore";
import { ResetPasswordModel } from "../../common/models/user.models";
import moment from 'moment';
import { authQuery } from '../../auth/stores/auth';
import { appointmentsStore } from '../../appointments/stores/appointments';
import { BuyProductModel } from '../../products/models/buyProduct.model';

/**
 * Provides method for working with employees
 */
export class EmployeesService {
    private url = `${process.env.REACT_APP_API_URL}Employees`;
    private patientsUrl = `${process.env.REACT_APP_API_URL}Patients/`;
    private paymentPlansUrl = `${process.env.REACT_APP_API_URL}PaymentPlans/`;

    constructor(private employeesStore: EmployeesStore) {
    }

    /**
     * Returns active Employee by id
     */
    public getActiveBy(id: number): void {
        const config = {
            headers: authHeader()
        };

        Axios.get(`${this.url}/${id}`, config)
            .pipe()
            .subscribe(
                response => this.employeesStore.update({ employee: response.data }),
                error => snackService.commonErrorHandler(error)
            );
    }

    /**
     * Returns active Employee related to practice
     */
    public getAllPracticeEmployees(): void {
        const config = {
            headers: authHeader()
        };

        Axios.get(`${this.url}/AllPractice`, config)
            .pipe()
            .subscribe(
                response => this.employeesStore.update({ employees: response.data }),
                error => snackService.commonErrorHandler(error)
            );
    }

    /**
     * Returns all active Employees
     */
    public getActiveByUserId(id: number): Observable<EmployeeModel> {
        const config = {
            headers: authHeader()
        };

        return new Observable<EmployeeModel>((observer) =>
            Axios.get(`${this.url}/user/${id}`, config)
                .pipe()
                .subscribe(
                    response => {
                        this.employeesStore.update({ employee: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    /**
     * Returns all active Employees
     */
    public getActive(searchQuery: string = "", locationId: number | null = null, roleId: number | null = null) {
        const config = {
            headers: authHeader()
        };

        return new Observable<EmployeeShortModel[]>((observer) =>
            Axios.get(`${this.url}?locationId=${locationId ?? ''}&searchQuery=${searchQuery}&roleId=${roleId ?? ''}`, config)
                .pipe()
                .subscribe(
                    response => {
                        this.employeesStore.update({ employees: response.data })
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    /**
     * Returns all active coaches and providers
     */
    public getActiveCoachesAndProviders(locationId: number | null = null): Observable<EmployeeShortModel[]> {
        const config = {
            headers: authHeader()
        };

        return new Observable<EmployeeShortModel[]>((observer) =>
            Axios.get<EmployeeShortModel[]>(`${this.url}/CoachesAndProviders?locationId=${locationId ?? ''}`, config)
                .pipe()
                .subscribe(
                    response => {
                        this.employeesStore.update({ couchesAndProviders: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    (error) => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                )
        );
    }

    /**
     * Returns all active staff and care coordinators
     */
    public getActiveStaff(locationId: number | null = null): Observable<EmployeeShortModel[]> {
        const config = {
            headers: authHeader()
        };

        return new Observable<EmployeeShortModel[]>((observer) =>
            Axios.get<EmployeeShortModel[]>(`${this.url}/Staff?locationId=${locationId ?? ''}`, config)
                .pipe()
                .subscribe(
                    response => {
                        this.employeesStore.update({ staff: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    (error) => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                )
        );
    }

    /**
     * Returns all active fellows public info
     */
    public getActiveFellowsPublicInfo(practiceId: number): void {
        const config = {
            headers: authHeader()
        };

        Axios.get(`${this.url}/Fellows/PublicInfo?practiceId=${practiceId}`, config)
            .pipe()
            .subscribe(
                response => this.employeesStore.update({ fellowsPublicInfo: response.data }),
                error => snackService.commonErrorHandler(error)
            );
    }

        /**
     * Returns all active fellows public info by roster
     */
        public getActiveFellowsPublicInfoByRoster(practiceId: number, rosterId: number): Observable<any> {
            const config = {
                headers: authHeader()
            };
            return new Observable((observer) =>
            Axios.get(`${this.url}/Fellows/PublicInfoByRoster?practiceId=${practiceId}&rosterId=${rosterId}`, config)
                .pipe()
                .subscribe(
                    response => {
                        this.employeesStore.update({ fellowsPublicInfo: response.data })
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
            )
        }

    

    /**
     * Returns all active fellows by date range
     */
    public getActiveFellows(start: Date, end: Date): Observable<any> {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.get(`${this.url}/Fellows?start=${moment(start).toISOString()}&end=${moment(end).toISOString()}`, config)
                .pipe()
                .subscribe(
                    response => {
                        this.employeesStore.update({ fellows: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    () => {
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    /**
     * Returns all active care coordinators
     */
    public getActiveCareCoordinators(locationId: number | null = null): void {
        const config = {
            headers: authHeader()
        };

        Axios.get(`${this.url}/CareCoordinators?locationId=${locationId ?? ''}`, config)
            .pipe()
            .subscribe(
                response => this.employeesStore.update({ careCoordinators: response.data }),
                error => snackService.commonErrorHandler(error)
            );
    }

    public getAssignedTo(): Observable<EmployeeModel[]> {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.get(`${this.url}/AssignedTo`, config)
                .pipe()
                .subscribe(
                    response => {
                        this.employeesStore.update({ patientEmployees: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    () => {
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    public getAssignedToById(patientId: number): Observable<EmployeeModel[]> {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.get(`${this.url}/AssignedTo/${patientId}`, config)
                .pipe()
                .subscribe(
                    response => {
                        this.employeesStore.update({ patientEmployees: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    () => {
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    public createEmployee(model: CreateEmployeeModel) {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.post(`${this.url}/Register`, model, config)
                .pipe()
                .subscribe(
                    response => {
                        this.employeesStore.addEmployee(response.data);
                        snackService.success('Employee successfully registered');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    public updateEmployee(model: UpdateEmployeeModel) {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.put(`${this.url}/Update`, model, config)
                .pipe()
                .subscribe(
                    response => {
                        employeesStore.updateEmployee(response.data);
                        snackService.success('Employee successfully updated');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    public resentEmployeeInvite(id: number) {
        const config = {
            headers: authHeader()
        };

        return Axios.post(`${this.url}/${id}/ResentInvite/`, {}, config)
            .pipe()
            .subscribe(
                () => {
                    snackService.success('Invite for Employee successfully resend.');
                },
                error => snackService.commonErrorHandler(error)
            );
    }

    public deleteEmployee(id: number) {
        const config = {
            headers: authHeader()
        };

        return Axios.delete(`${this.url}/${id}/Delete/`, config)
            .pipe()
            .subscribe(
                () => {
                    employeesStore.removeEmployee(id);
                    snackService.success('Employee successfully deleted.');
                },
                error => snackService.commonErrorHandler(error)
            );
    }

    public resetEmployeePassword(id: number, model: ResetPasswordModel) {
        const config = {
            headers: authHeader()
        };

        return Axios.post(`${this.url}/${id}/ResetPassword/`, model, config)
            .pipe()
            .subscribe(
                () => {
                    snackService.success('Employee password has been updated.');
                },
                error => snackService.commonErrorHandler(error)
            );
    }

    public selectFellow(fellow: FellowShortModel) {
        this.employeesStore.update({ selectedFellow: fellow });
    }

    public linkFellow(fellow: FellowShortModel) {
        this.employeesStore.update({ linkedFellow: fellow });
    }

    public createFellow(model: CreateFellowModel) {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.post(`${this.url}/FellowRegister`, model, config)
                .pipe()
                .subscribe(
                    response => {
                        this.employeesStore.addFellow(response.data);
                        snackService.success('Fellow successfully registered');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    public updateFellow(model: UpdateFellowModel) {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.put(`${this.url}/FellowUpdate`, model, config)
                .pipe()
                .subscribe(
                    response => {
                        employeesStore.updateFellow(response.data);
                        snackService.success('Fellow successfully updated');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    public deleteFellow(id: number) {
        const config = {
            headers: authHeader()
        };

        return Axios.delete(`${this.url}/Fellows/${id}`, config)
            .pipe()
            .subscribe(
                () => {
                    employeesStore.removeFellow(id);
                    snackService.success('Fellow successfully deleted.');
                },
                error => snackService.commonErrorHandler(error)
            );
    }

    /**
     * Returns all active fellows by date range
     */
    public getActivePMFellows(start?: Date, end?: Date): Observable<any> {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.get(`${this.url}/Fellows?start=${start ? moment(start).toISOString() : ''}&end=${end ? moment(end).toISOString() : ''}`, config)
                .pipe()
                .subscribe(
                    response => {
                        this.employeesStore.update({ fellows: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    () => {
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    /**
     * Returns Employee Dashboard Data
     */
    public getDashboardData() {
        const config = {
            headers: authHeader()
        };

        return new Observable<any>((observer) =>
            Axios.get(`${this.url}/${authQuery.getEmployeeId()}/Dashboard/Data`, config)
                .pipe()
                .subscribe(
                    response => {
                        appointmentsStore.setEmployeeAppointments(response.data.upcomingAppointments);
                        appointmentsStore.setEmployeeRecentlyAddedAppointments(response.data.recentlyAddedAppointments);
                        appointmentsStore.setRecentlyNoUploadedLabFileAppointments(response.data.recentlyNoUploadedLabFileAppointments);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    /**
     * Returns all active fellows by roster
     */
    public getFellowsByRoster(rosterId): Observable<any> {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.get(`${this.url}/FellowsByRoster?rosterId=${rosterId}`, config)
                .pipe()
                .subscribe(
                    response => {
                        this.employeesStore.update({ fellows: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    () => {
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    /**
     *  Change the Subscriprtion candidate
     * @param patientId
     */
    public changeSubscriptionCandidate(patientId: number): Observable<any> {
        const config = {headers: authHeader()};

        return new Observable<any>((observer) => {
            Axios.post(`${this.patientsUrl}${patientId}/ChangeSubscriptionCandidate`, {}, config)
                .pipe()
                .subscribe(
                    response => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                )
        });
    }


    /**
     *  Get the populate payment plan recommend
     * @param recommendModel
     */
    public paymentPlanRecommend(recommendModel: any): Observable<any> {
        const config = {headers: authHeader()};

        return new Observable<any>((observer) => {
            Axios.post(`${this.paymentPlansUrl}recommend`, recommendModel, config)
                .pipe()
                .subscribe(
                    response => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                )
        });
    }


    /**
     *  Migrate the payment plan
     * @param migrationModel
     */
    public migratePaymentPlan(migrationModel: any): Observable<any> {
        const config = {headers: authHeader()};

        return new Observable<any>((observer) => {
            Axios.post(`${this.paymentPlansUrl}migrate`, migrationModel, config)
                .pipe()
                .subscribe(
                    response => {
                        snackService.success('Changes successfully confirmed!');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.error('Card was declined. Patient should contact their financial institution.');
                        observer.error(error);
                        observer.complete();
                    }
                )
        });
    }

    /**
      *  Buy the products by the patient
      * @param buyProductModel
      */
    public buyProducts(buyProductModel: BuyProductModel): Observable<any> {
        const config = {headers: authHeader()};

        return new Observable<any>((observer) => {
            Axios.post(`${this.patientsUrl}${buyProductModel.patientId}/BuyProducts`, buyProductModel, config)
                .pipe()
                .subscribe(
                    response => {
                        snackService.success('Changes successfully confirmed!');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                )
        });
    }
}

export const employeeService = new EmployeesService(employeesStore);