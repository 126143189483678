import React from "react";
import _ from "lodash";
import clsx from "clsx";
import moment from "moment";
import { Box, Divider, Popover, Collapse, useTheme, useMediaQuery } from "@material-ui/core";
import { FixedSizeList as List } from "react-window";
import AutoSizer from 'react-virtualized-auto-sizer';
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ToggleButton } from "@material-ui/lab";
import { FilterButtonGroup } from "../../../common/components/wildHealthButton/FilterButtonGroup";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { ChatsPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/ChatsPlaceHolder";
import {toCurrentTimeZone} from "../../../timezones/helpers/timezone";
import {
    ChatFilterTypes,
    chatFilterTypesNames,
    EmployeeConversationModel,
    chatPatientFilterTypesNames,
    ChatPatientFilterTypes,
} from "../../models/conversation.models";
import { ChatItemComponentProps } from "../chatItemComponent/ChatItemComponent";
import { useFacade } from "./chatsListComponent.hooks";
import {
    NewMessageButton,
    SearchTextField,
    useStyles,
} from "./chatsListComponent.styles";
import commonUseStyles from "../../../common/styles/common.styles";
import { authQuery } from "../../../auth/stores/auth";

const filtersPopoverProps: any = {
    id: "notificationsPopover",
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
};

interface ChatsListComponentProps {
    isLoading: boolean;
    isInternal?: boolean;
    conversations: EmployeeConversationModel[];
    targetConversation: EmployeeConversationModel;
    filters?: ChatFilterTypes[];
    newMessageComponent: React.FC<any>;
    newMessageProps: any;
    chatTemplate: React.FC<ChatItemComponentProps>;
}

export const ChatsListComponent: React.FC<ChatsListComponentProps> = (props: ChatsListComponentProps) => {

    const {
        isLoading,
        isInternal = false,
        targetConversation,
        filters,
        newMessageComponent,
        newMessageProps,
        chatTemplate,
    } = props;

    const classes = useStyles();
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const [
        { isNewMessageOpen, isOpenFavoriteCollapse, isOpenRecentCollapse, conversations, search, filter, filterPatient, patientFilterState },
        handleToggleNewMessage,
        handleFiltering,
        handleConversationSelect,
        handleTogglePatientFilter,
        handleUpdateFavoriteConversation,
        handleToggleFavoriteThreads,
        handleToggleRecentThreads
    ] = useFacade(props.conversations, targetConversation, filters, isInternal);


    const getDisplayDate = (
        date: Date,
        today: moment.Moment,
        yesterday: moment.Moment
    ) => {
        const convertedDate = date.toString().includes('GMT')
            ? moment(date)
            : moment(toCurrentTimeZone(new Date(date.toString() + 'Z')));

        return convertedDate.isSame(today, "d")
            ? convertedDate.format("hh:mm a")
            : convertedDate.isSame(yesterday, "d")
                ? "Yesterday"
                : convertedDate.format("MMM DD, yyyy");
    };

    const renderChat = (
        item: EmployeeConversationModel,
        index: number,
        today: moment.Moment,
        yesterday: moment.Moment,
        isFavorite: boolean,
        style: React.CSSProperties
    ) => {

        const displayDate = getDisplayDate(
            item.lastMessageAt ? new Date(item.lastMessageAt) : new Date(item.startDate),
            today,
            yesterday
        );

        const klass = item.vendorExternalId;

        return <div style={style} className={klass}>
            <React.Fragment key={`chat-template-${index}`}>
                {
                    React.createElement(chatTemplate, {
                    item,
                    targetConversationId: targetConversation?.id,
                    index,
                    displayDate,
                    isFavorite,
                    handleConversationSelect,
                    handleUpdateFavoriteConversation
                })}
            </React.Fragment>
            <Divider
                key={`divider-${index}`}
                className={classes.divider}
                orientation="horizontal"
            />
        </div>;
    };

    const renderChats = () => {
        const today = moment(new Date()).startOf("day");
        const yesterday = moment(new Date()).subtract(1, "days").startOf("day");
        const items = conversations;
        const favoriteConversations = conversations.filter(x => x?.proxy?.attributes?.FavoriteEmployeeIds && x?.proxy?.attributes?.FavoriteEmployeeIds.includes(authQuery.getEmployeeId()) && x.patients[0].hasActiveSubscription)
        const unfavoriteConversations = conversations.filter(x => !favoriteConversations.map(x => x.id).includes(x.id))

        if (favoriteConversations && favoriteConversations.length > 0) {
            const favoriteHeight = (isSmallScreen ? 95 : 80) * favoriteConversations.length;
            return (
                <>
                    {favoriteConversations && favoriteConversations.length > 0 && (
                        <>
                            <Box display="flex" alignItems="center" gridGap={8} mx={3} my={0.5}>
                                <Box onClick={handleToggleFavoriteThreads} className="wh-tw-cursor-pointer">
                                    {isOpenFavoriteCollapse ? <ExpandLessIcon className="wh-tw-text-gray1" /> : <ExpandMoreIcon className="wh-tw-text-gray1" />}
                                </Box>
                                <Box className="wh-tw-text-xs wh-tw-text-gray1">Favorite Threads</Box>
                            </Box>
                            <Collapse in={isOpenFavoriteCollapse} timeout="auto" unmountOnExit>
                                <Box className={clsx(classes.favoriteChats, "flat-scroll")} height={`${favoriteHeight}px`}>
                                    <AutoSizer>
                                        {({height, width}) =>
                                            <List component="nav" height={height} width={width} itemSize={isSmallScreen ? 85 : 60} itemCount={favoriteConversations.length}>
                                                {
                                                    (info) => {
                                                        var index = info.index;
                                                        var item = favoriteConversations[index];
                                                        var style = info.style;
                                                        return renderChat(item, index, today, yesterday, true, style)
                                                    }
                                                }
                                            </List>
                                        }
                                    </AutoSizer>
                                </Box>
                            </Collapse>
                            <Box display="flex" alignItems="center" gridGap={8} mx={3} mt={2.5} mb={0.5}>
                                <Box onClick={handleToggleRecentThreads} className="wh-tw-cursor-pointer">
                                    {isOpenRecentCollapse ? <ExpandLessIcon className="wh-tw-text-gray1" /> : <ExpandMoreIcon className="wh-tw-text-gray1" />}
                                </Box>
                                <Box className="wh-tw-text-xs wh-tw-text-gray1">Recent Threads</Box>
                            </Box>
                        </>
                    )}
                    <Collapse in={isOpenRecentCollapse} timeout="auto" unmountOnExit>
                        <Box className={clsx(classes.chats, "flat-scroll")}>
                            <AutoSizer>
                                {({height, width}) =>
                                    <List component="nav" height={height} width={width} itemSize={isSmallScreen ? 85 : 60} itemCount={unfavoriteConversations.length}>
                                        {
                                            (info) => {
                                                var index = info.index;
                                                var item = unfavoriteConversations[index];
                                                var style = info.style;
                                                return renderChat(item, index, today, yesterday, false, style)
                                            }
                                        }
                                    </List>
                                }
                            </AutoSizer>
                        </Box>
                    </Collapse>
                </>
            )
        }

        return (
            <Box className={clsx(classes.chats, "flat-scroll")}>
                <AutoSizer>
                    {({height, width}) =>
                        <List component="nav" height={height} width={width} itemSize={isSmallScreen ? 85 : 60} itemCount={conversations.length}>
                            {
                                //FIXME what is itemSize?  the item's height? ---^
                                (info) => {
                                    var index = info.index;
                                    var item = items[index];
                                    var style = info.style;
                                    return renderChat(item, index, today, yesterday, false, style)
                                }
                            }
                        </List>
                    }
                </AutoSizer>
            </Box>
        );
    }

    return (
        <Box>
            <Box mx={3} my={1}>
                <SearchTextField
                    fullWidth
                    variant="outlined"
                    className={classes.search}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleFiltering(e.target.value, filter, filterPatient)
                    }
                    placeholder="Search by Name"
                    InputProps={{
                        inputProps: {
                            className: classes.searchBase,
                        },
                        startAdornment: <SearchIcon className={classes.icon} />,
                    }}
                />
            </Box>

            {!isLoading && (
                <>
                    <Box mx={3} my={1} display="flex">
                        {filters.length > 1 ? (
                            <FilterButtonGroup
                                exclusive
                                value={filter}
                                onChange={(e, v) => handleFiltering(search, v, filterPatient)}
                            >
                                {filters.map((i, index) => (
                                    <ToggleButton key={index} value={i}>
                                        {chatFilterTypesNames.get(i)}
                                    </ToggleButton>
                                ))}
                            </FilterButtonGroup>
                        ) : (
                                <Box className={classes.activeThread}>Active Thread</Box>
                            )}
                    </Box>
                    <Box mx={3} my={2} display="flex" alignItems="center" onClick={(e) => handleTogglePatientFilter(e)}>
                        <span className={clsx(commonClasses.uppercase, commonClasses.colorGray1, commonClasses.size12, commonClasses.textMedium)}>Displaying <span className={clsx(commonClasses.underline, commonClasses.colorMain)}>{chatPatientFilterTypesNames.get(filterPatient)}</span> patients</span>
                        <ExpandMoreIcon className={classes.moreIcon} />
                    </Box>
                </>
            )}
            {isLoading ? (
                <Box px={3}>
                    <WildHealthLinearProgress />
                </Box>
            ) : conversations.length > 0 ? renderChats() : (
                <Box className={classes.chats} display="flex" justifyContent="center">
                    <Box maxWidth="285px">
                        <ChatsPlaceHolder message="No messages to display." />
                    </Box>
                </Box>
            )}
            {!isLoading && <Box mx={2} pb={1} className={classes.newMessageContainer}>
                <NewMessageButton
                    id="chats-list-toggle-new-message"
                    className={classes.newMessage}
                    onClick={() => handleToggleNewMessage()}
                >
                    <AddIcon />
                </NewMessageButton>

                {React.createElement(newMessageComponent, {
                    ...newMessageProps,
                    isOpen: isNewMessageOpen,
                    handleCancel: handleToggleNewMessage,
                })}
                
                <Popover
                    id={filtersPopoverProps.id}
                    anchorEl={patientFilterState.anchorEl}
                    onClose={handleTogglePatientFilter}
                    open={patientFilterState.isOpen}
                    anchorOrigin={filtersPopoverProps.anchorOrigin}
                    transformOrigin={filtersPopoverProps.transformOrigin}
                >
                    <Box flexDirection="column" className={classes.popoverContent}>
                        <Box px="20px" py="12px" className={clsx(commonClasses.size14, commonClasses.colorBlack, commonClasses.pointer)} onClick={() => handleFiltering(search, filter, ChatPatientFilterTypes.All)}>All Patients</Box>
                        <Box px="20px" py="12px" className={clsx(commonClasses.size14, commonClasses.colorBlack, commonClasses.pointer)} onClick={() => handleFiltering(search, filter, ChatPatientFilterTypes.Active)}>Active Patients</Box>
                        <Box px="20px" py="12px" className={clsx(commonClasses.size14, commonClasses.colorBlack, commonClasses.pointer)} onClick={() => handleFiltering(search, filter, ChatPatientFilterTypes.InActive)}>Inactive Patients</Box>
                    </Box>
                </Popover>
            </Box>
            }
        </Box>
    );
};
