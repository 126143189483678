import { useState } from "react";
import { restorePasswordPageValidator } from "../../../auth/pages/restorePasswordPage/restorePasswordPage.validator";
import { IErrorState } from "../../../common/validation/error-state";

interface ResetPatientPasswordDialogState extends IErrorState {
    newPassword: string;
    confirmPassword: string;
}

export function useFacade(handleSubmit: Function, handleClose: Function): [ResetPatientPasswordDialogState, Function, Function, Function] {
    const [state, setState] = useState({
        newPassword: "",
        confirmPassword: "",
        errors: {}
    } as ResetPatientPasswordDialogState);

    const handleChanges = (key: string, value: string) => {
        state[key] = value;
        
        restorePasswordPageValidator.validateAndSetState(state, setState, key, value);
    }

    const handleCloseDialog = () => {
        handleClose();
        setState({
            newPassword: "",
            confirmPassword: "",
            errors: {}
        });
    }

    const handleSubmitForm = (event: Event) => {
        event.preventDefault();

        restorePasswordPageValidator.validateObjectAndSetState(state, setState, state);

        if(restorePasswordPageValidator.stateIsValid(state)){
            handleSubmit(state.newPassword, state.confirmPassword);
            handleCloseDialog();
        }
    }
    return [state, handleChanges, handleSubmitForm, handleCloseDialog];
}
