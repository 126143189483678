import { useEffect, useState } from 'react';
import { Subscription } from 'recompose';
import { onEmit } from '../../../common/helpers/on-emit';
import { LocationModel } from '../../models/locations.models';
import { locationsService } from '../../services/locations.service';
import { locationsQuery } from '../../stores';
import {PatientModel, TransferPatientToLocationModel} from "../../../patients/models/patient.model";
import {patientsService} from "../../../patients/services/patients.service";
import {confirmService} from "../../../../services/confirm.service";
import {displayLocation} from "../../helpers/displayLocation";
import {authQuery} from "../../../auth/stores/auth";

interface TransferPatientToLocationComponentState {
    locations: LocationModel[],
    currentLocation: LocationModel;
}

export function useFacade(patient: PatientModel): [
    TransferPatientToLocationComponentState,
    (locationId: number) => void,
    () => void
] {
    const [state, setState] = useState({
        locations: []
    } as TransferPatientToLocationComponentState);

    const handleChangeLocation = (locationId: number) => {
        const location = state.locations.find(x => x.id === locationId);
        if (location) {
            setState({
                ...state,
                currentLocation: location
            });
        }
    }
    const handleTransfer = () => {
        if (patient.locationId === state.currentLocation?.id) {
            return;
        }

        const model: TransferPatientToLocationModel = {
            id: patient.id,
            locationId: state.currentLocation?.id
        }

        confirmService.confirm(`Please confirm patient transfer to pod ${displayLocation(state.currentLocation)}`).subscribe(() => {
            patientsService.transferPatientToLocation(model).subscribe()
        })
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<LocationModel[]>(locationsQuery.availableLocations$, locations => {
                setState(state => ({
                    ...state,
                    locations: locations,
                    currentLocation: locations.find(x => x.id === patient.locationId)
                }))
            }),
        ];

        locationsService.getAvailable(authQuery.getCurrentPracticeId())
            .subscribe(() => setState(state => ({
                ...state,
                isLoading: false
            })));

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [patient]);

    return [
        state,
        handleChangeLocation,
        handleTransfer
    ];
}