import { Query } from '@datorama/akita';
import { EmployeesState, employeesStore, EmployeesStore } from "./employeesStore";

/**
 * Provides employees queries
 */
export class EmployeesQuery extends Query<EmployeesState> {
    couchesAndProviders$ = this.select(state => state.couchesAndProviders);
    staff$ = this.select(state => state.staff);
    careCoordinators$ = this.select(state => state.careCoordinators);
    fellowsPublicInfo$ = this.select(state => state.fellowsPublicInfo);
    fellows$ = this.select(state => state.fellows);
    employees$ = this.select(state => state.employees);
    employee$ = this.select(state => state.employee);
    permissions$ = this.select(state => state.permissions);
    patientEmployees$ = this.select(state => state.patientEmployees);
    roles$ = this.select(state => state.roles);
    requests$ = this.select(state => state.preauthorizeRequests);

    constructor(protected store: EmployeesStore) {
        super(store);
    }
}

export const employeesQuery = new EmployeesQuery(employeesStore);
