import {GeneralValidator} from "../../../common/validation/general-validator";
import {
    functionValidationRule,
    rangeValidationRule,
    requiredValidationRule
} from "../../../common/validation/general-validation-rules";
import {MdmCategory, MdmCategoryIds, mdmCategoryItemsCount, MdmDataModel, MdmMode, NotePhysicalModel, RosModel} from "../../models/notes.models";


export const createHistoryAndPhysicalNoteComponentValidator = new GeneralValidator({
    'ROS':[functionValidationRule((data: RosModel) => {
        if(!data.rosEnabled){
            return false;
        }

        return true
    }, 'ROS section must be completed.')],
    'diagnosis': [functionValidationRule((data) => {
        return data?.length > 0
    }, 'At least one diagnosis must be selected.')],
    'mdmData': [functionValidationRule((data: MdmDataModel) => {
      
        if (!data.selectedMdmPatientType) {
            return false;
        }
        if (!data.selectedCodeId) {
            return false;
        }
        if (data.selectedMode === MdmMode.TimeBased) {
            if (!data.spentTime) {
                return false;
            }
        } else {
            if (data.selectedCodeId === MdmCategory.EstablishedPatientG0438
                || data.selectedCodeId === MdmCategory.EstablishedPatientG0439
                || data.selectedCodeId === MdmCategory.NewPatientG0438) {
                return true
            }
            
            if (!data.selectedCategoryIds) {
                return false;
            }

            if (data.selectedCategoryIds.length < 1) {
                return false;
            }

            if (data.selectedCodeId === MdmCategory.EstablishedPatient99205 || data.selectedCodeId === MdmCategory.NewPatient99205) {
                if (data.selectedCategoryIds.length < 2) {
                    return false;
                }
               
                const el99205 = data.selectedCategoryIds.find(el => el === MdmCategoryIds.EstablishedPatientOne99205 || el === MdmCategoryIds.NewPatientOne99205)
                if (el99205 && data.selectedCategoryItems.length < 4) {
                    return false
                }  
            }

            if (data.selectedCodeId === MdmCategory.EstablishedPatient99203 || data.selectedCodeId === MdmCategory.EstablishedPatient99204) {
                const categoryId = data.selectedCategoryIds[0]
                if (mdmCategoryItemsCount[categoryId] > data.selectedCategoryItems?.length) {
                    return false;
                }
            }

            
            if (data.selectedCodeId === MdmCategory.NewPatient99203 || data.selectedCodeId === MdmCategory.NewPatient99204) {
                const categoryId = data.selectedCategoryIds[0]
                if (mdmCategoryItemsCount[categoryId] > data.selectedCategoryItems?.length) {
                    return false;
                }
            }
            
        }

        return true;
    }, 'Coding section must be fully completed.')],
    'physicalExam': [requiredValidationRule(1, 'Physical Exam cannot be left blank')],
    'systolicBP': [rangeValidationRule(0, 280)],
    'diastolicBP': [rangeValidationRule(0, 200)],
    'heartRate': [rangeValidationRule(0, 220)],
    'temperature': [functionValidationRule((data) => {
        if (data === 0) {
            return true
        }

        return rangeValidationRule(93, 107).function(data);
    }, 'Value should be in range from 93 to 107')],
    'height': [functionValidationRule((data) => {
        if (data === 0) {
            return true
        }

        return rangeValidationRule(18, 120).function(data);
    }, 'Value should be in range from 18 to 120')],
    'weight': [rangeValidationRule(0, 1200, 'Not more than 1200lbs')],
    'physicalData': [functionValidationRule((data: NotePhysicalModel) => {
        if (data.unableToObtain) {
            return true;
        }

        const vitals = data.diastolicBP
            + data.systolicBP
            + data.height
            + data.weight
            + data.temperature
            + data.heartRate;

        return vitals !== 0;
    }, 'At least 1 field must be completed')]
});