import { useState, useEffect } from "react";
import { Subscription } from "rxjs";
import { appointmentsService } from "../../services/appointments.service";
import {availabilityService} from "../../services/availability.service";
import { AppointmentStatus, AppointmentsViewMode, DayOfWeek, AppointmentPurposeType } from "../../models/appointments.enums";
import { AvailabilityModel, EmployeeAvailabilityModel } from "../../models/availability.models";
import { onEmit } from "../../../common/helpers/on-emit";
import { availabilityQuery } from "../../stores/availability/availability.query";
import { EmployeeAppointmentModel, AppointmentSequenceNumbersModel } from "../../models/appointments.models";
import { appointmentsQuery } from "../../stores/appointments";
import { questionnaireService } from "../../../questionnaire/services/questionnaire.service";
import { profileService } from "../../../account/services/profile.service";
import { authQuery } from "../../../auth/stores/auth";

const getRelatedDayDate = (dayOfWeek: DayOfWeek, date: Date): Date => {
    const dayOrder = date.getDay() === 0 ? 7 : date.getDay();
    const result = new Date();
    result.setDate(date.getDate() - dayOrder + dayOfWeek);
    result.setHours(0, 0, 0, 0);
    return result;
}

interface CoachAppointmentsCalendarComponentState {
    scheduleMode: AppointmentsViewMode;
    selectedDate: Date;
    startOfWeek: Date;
    endOfWeek: Date;
    availability: AvailabilityModel[];
    appointments: EmployeeAppointmentModel[];
    isAvailabilityLoading: boolean;
    isAppointmentsLoading: boolean;
    isFollowUpFormsLoading: boolean;
    isSequenceNumbersLoading: boolean;
    isAppointmentTagsLoading: boolean;
    isPhotosLoading: boolean;
}

export function useFacade(employeeId: number, handleToggleStatisticLoading: (status: boolean) => void): [
    CoachAppointmentsCalendarComponentState,
    (scheduleMode: AppointmentsViewMode) => void,
    (date: Date) => void,
    (dayOfWeek: DayOfWeek, hour?: number) => Date,
    () => void,
    () => void,
    () => void,
] {
    const [state, setState] = useState({
        scheduleMode: AppointmentsViewMode.Week,
        selectedDate: availabilityQuery.getEarliestAvailability(),
        startOfWeek: getRelatedDayDate(DayOfWeek.Monday, new Date()),
        endOfWeek: getRelatedDayDate(DayOfWeek.Sunday, new Date()),
        availability: [],
        appointments: [],
        isAvailabilityLoading: true,
        isAppointmentsLoading: true,
        isFollowUpFormsLoading: true,
        isSequenceNumbersLoading: true,
        isAppointmentTagsLoading: true,
        isPhotosLoading: true
    } as CoachAppointmentsCalendarComponentState);

    const handleScheduleModeChange = (scheduleMode: AppointmentsViewMode) => {
        setState({...state, scheduleMode});
    }

    const handleDateChange = (date: Date) => {
        state.selectedDate = date;

        if(state.startOfWeek > date || state.endOfWeek < date) {
            state.startOfWeek = getDayDate(DayOfWeek.Monday);
            state.endOfWeek = getDayDate(DayOfWeek.Sunday);
        }
        setState({...state});
    }

    const handleGoNext = () => {
        const daysRange = state.scheduleMode === AppointmentsViewMode.Day ? 1 : 7
        state.selectedDate.setDate(state.selectedDate.getDate() + daysRange);
        state.startOfWeek = getDayDate(DayOfWeek.Monday);
        state.endOfWeek = getDayDate(DayOfWeek.Sunday);
        setState({...state});
    }

    const handleGoPrev = () => {
        const daysRange = state.scheduleMode === AppointmentsViewMode.Day ? 1 : 7
        state.selectedDate.setDate(state.selectedDate.getDate() - daysRange);
        state.startOfWeek = getDayDate(DayOfWeek.Monday);
        state.endOfWeek = getDayDate(DayOfWeek.Sunday);
        setState({...state});
    }

    const handleGoCurrent = () => {
        state.selectedDate = new Date()
        if(state.startOfWeek > new Date() || state.endOfWeek < new Date()) {
            state.startOfWeek = getDayDate(DayOfWeek.Monday);
            state.endOfWeek = getDayDate(DayOfWeek.Sunday);
        }
        setState({...state})
    }

    const getDayDate = (dayOfWeek: DayOfWeek, hour: number = 0): Date => {
        const dayOrder = state.selectedDate.getDay() === 0 ? 7 : state.selectedDate.getDay();
        const date = new Date(state.selectedDate);
        date.setDate(state.selectedDate.getDate() - dayOrder + dayOfWeek);
        date.setHours(hour, 0, 0, 0);
        return date;
    }

    useEffect(() => {
        setState(state => ({
            ...state,
            isAvailabilityLoading: true,
            isAppointmentsLoading: true ,
            isFollowUpFormsLoading: true,
            isSequenceNumbersLoading: true,
            isAppointmentTagsLoading: true,
            isPhotosLoading: true
        }));

        const availabilityCB = () => setState(state => ({...state, isAvailabilityLoading: false}))
        const appointmentsCB = () => setState(state => ({...state, isAppointmentsLoading: false}))
        const getFollowUpFormsCB = () => setState(state => ({ ...state, isFollowUpFormsLoading: false }))
        const getSequenceNumbersCB = () => setState(state => ({ ...state, isSequenceNumbersLoading: false }))
        const getTagsCB = () => setState(state => ({ ...state, isAppointmentTagsLoading: false }))
        const getPhotosCB = () => setState(state => ({ ...state, isPhotosLoading: false }))
        const isEmployee = authQuery.isEmployeeUser();

        const subscriptions: Subscription[] = [
            onEmit<EmployeeAvailabilityModel>(availabilityQuery.currentAvailability$, employeeAvailability => {
                if(employeeAvailability && employeeAvailability.employeeId === employeeId) {
                    setState(state => ({
                        ...state, 
                        availability: employeeAvailability.availability
                    }));
                }
            }),
            onEmit<EmployeeAppointmentModel[]>(appointmentsQuery.employeeAppointments$, appointments => {
                setState(state => ({...state,
                    appointments: appointments.filter(x => x.status !== AppointmentStatus.Canceled),
                }));
            }),
        ];

        handleToggleStatisticLoading(true);
        appointmentsService.getAppointmentsStatistic(employeeId, state.selectedDate).subscribe(() => handleToggleStatisticLoading(false), () => handleToggleStatisticLoading(false));
        availabilityService.getCalendarById(employeeId, state.startOfWeek, state.endOfWeek, isEmployee).subscribe(availabilityCB, availabilityCB);
        appointmentsService.getEmployeeAppointmentsById(employeeId, state.startOfWeek, state.endOfWeek).subscribe((appointments) => {
            const filteredAppointments = appointments.filter(x => x.status !== AppointmentStatus.Canceled);
            if (filteredAppointments && filteredAppointments.length) {
                const employeeIds = filteredAppointments.map(x => x.employees).reduce((x1: number[], x2) => {
                    for (const item of x2)
                    {
                        if(!x1.includes(item.id)) {
                            x1.push(item.id)
                        }
                    }

                    return x1;
                }, [])
                profileService.getEmployeeProfilePhotos(employeeIds.join(',')).subscribe(getPhotosCB, getPhotosCB)
                const followUpAppointmentIds = filteredAppointments.filter(x => x.purpose === AppointmentPurposeType.FollowUp).map(item => item.id);
                const hasPaitentAppointments = filteredAppointments.filter(x => x.patient);
                if (followUpAppointmentIds.length) {
                    questionnaireService.getFollowUpForms(followUpAppointmentIds).subscribe(getFollowUpFormsCB, getFollowUpFormsCB)
                } else {
                    getFollowUpFormsCB();
                }
                if (hasPaitentAppointments.length) {
                    appointmentsService.getEmployeeAppointmentsByIds(hasPaitentAppointments.map(x => x.id)).subscribe(getTagsCB, getTagsCB);
                    const sequenceNumbersModel = hasPaitentAppointments.map(x => {
                        return {
                            id: x.id,
                            patientId: x.patient.id,
                        }
                    }) as AppointmentSequenceNumbersModel[]
                    appointmentsService.getAppointmentSequenceNumbers(sequenceNumbersModel).subscribe(getSequenceNumbersCB, getSequenceNumbersCB);
                } else {
                    getSequenceNumbersCB();
                    getTagsCB();
                }
            } else {
                getFollowUpFormsCB();
                getSequenceNumbersCB();
                getTagsCB();
                getPhotosCB();
            }
            appointmentsCB();
        }, appointmentsCB);

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [employeeId, state.startOfWeek, state.endOfWeek]);

    const scrollCalendar = () => {
        setTimeout(() => {
            const element = window.document.getElementById('appointments-calendar-container');
            if (element) {
                element.scroll(0, 240);
            } else {
                scrollCalendar();
            }
        }, 500);
    }

    useEffect(() => {
        scrollCalendar();
        return () => {}
    }, [])

    return [
        state,
        handleScheduleModeChange,
        handleDateChange,
        getDayDate,
        handleGoNext,
        handleGoPrev,
        handleGoCurrent
    ];
}