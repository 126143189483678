
import { useFacade } from './wildHealthAutocompleteComponent.hooks'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'
import { Observable } from 'rxjs'
import React from 'react'

interface WildHealthAutocompleteComponentProps {
    id?: string
    value?: string
    getAutoComplete: (searchQuery: string) => Observable<string[]>
    saveChanges: (e: any, value: string) => void
}

export const WildHealthAutocompleteComponent: React.FC<WildHealthAutocompleteComponentProps> = (props: WildHealthAutocompleteComponentProps) => {
    const [
        handleKeyDown,
        handleChanges,
        handleSubmit,
        state
    ] = useFacade(props.getAutoComplete, props.saveChanges, props.value)

    const elementId = props.id ?? 'autocomplete-input'

    return <Autocomplete
        id={elementId}
        freeSolo
        size="small"
        disableClearable
        fullWidth
        options={state.options}
        value={state.value}
        onChange={(e, value) => handleChanges(value)}
        onKeyDown={(e) => handleKeyDown(e, elementId)}
        renderInput={(params) => (
            <TextField
                {...params}
                fullWidth
                variant='outlined'
                value={state.value}
                autoFocus
                InputProps={{
                    ...params.InputProps,
                    className: 'input',
                    type: 'search'
                }}
                onBlur={(e) => handleSubmit(e)}
                onChange={(e) => handleChanges(e.target.value)}
            />
        )}
    />
}