import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontFamily: 'Be Vietnam Pro',
            fontWeight: 500,
            fontSize: 20,
        },
        content: {
            padding: 5,
            [theme.breakpoints.up('sm')]: {
                padding: 30,
            },
        },
        divider: {
            height: 1,
            background: '#EFF3F8',
        },
    })
);
