import {
    AddReactionModel,
    ConversationAuthorModel,
    ConversationModelBase, ReactionModel, ReactionType,
} from "../../models/conversation.models";
import {MutableRefObject, useEffect, useRef, useState} from "react";
import {BaseConversationComponentState, useBaseFacade} from "../conversationComponent/conversationComponent.hooks";
import {conversationsService} from "../../services/conversations.service";

export interface PlaygroundThreadComponentState {

}

let isReacted = false;

export function useFacade(conversation: ConversationModelBase, author: ConversationAuthorModel): [
    PlaygroundThreadComponentState  & BaseConversationComponentState,
    MutableRefObject<any>,
    (message: any, reaction: ReactionType) => void,
    (reaction: ReactionModel, message: any) => void
] {
    const [state, setState] = useState({

    } as PlaygroundThreadComponentState);

    const [
        baseState
    ] = useBaseFacade(conversation, author);

    const lastMessageRef = useRef(null);

    const handleReaction = (message: any, reaction: ReactionType) => {
        const model: AddReactionModel = {
            conversationId: conversation.vendorExternalId,
            participantId: author.conversationIdentity,
            messageId: message.sid,
            type: reaction
        };

        isReacted = true;

        conversationsService.submitReaction(model).subscribe(
            () => {},
            () => {
                isReacted = false;
            }
        );
    }

    const handleRemoveReaction = (reaction: ReactionModel, message: any) => {
        isReacted = true;

        conversationsService.removeReaction(reaction.Id, conversation.vendorExternalId, message.sid).subscribe(
            () => {},
            () => {
                isReacted = false;
            }
        );
    }

    useEffect(() => {
        if (isReacted) {
            isReacted = false;
            return;
        }

        if (!baseState.isLoadingMessages) {
            lastMessageRef.current?.scrollIntoView({ behavior: "smooth" })
        }
    }, [baseState.isLoadingMessages, baseState.messages]);

    return [
        {
            ...state,
            ...baseState
        },
        lastMessageRef,
        handleReaction,
        handleRemoveReaction
    ];
}