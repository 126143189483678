import { GeneralValidator } from "../../../common/validation/general-validator";
import {
  requiredValidationRule, notNegativeValidationRule,
} from "../../../common/validation/general-validation-rules";

export const createRecommendationTriggerValidator = new GeneralValidator({
  'recommendationId': [requiredValidationRule()],
  'logicalOperator': [requiredValidationRule()],
  'confidence': [notNegativeValidationRule()],
  'recommendationType': [requiredValidationRule()]
});