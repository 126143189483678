import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { colors } from "../../modules/common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        titleSize:{
            fontSize: 14,
            fontWeight: 400
        },
        divider:{
            fontColor: `${colors.grey}66`,
            height: 30,
            opacity: 0.5,
            width: 0,
            border: "0.5px solid gray"
        },
        titleSubscription:{
            whiteSpace: "nowrap",
            display: "flex",
            alignItems: "center",
        },
        notificationToolTipText: {
            lineHeight: 1.5,
            fontFamily: 'Be Vietnam Pro',
            fontWeight: 400,
            fontSize: 12,
            color: colors.white
        },
        arrow: {
            color: colors.lightBlack,
        },
        customWidth: {
            width: 165,
            maxWidth: 165,
            backgroundColor: colors.lightBlack,
            padding: 10,
            boxShadow: ` 0px 4px 9px rgba(168, 173, 198, 0.15)`,
        },
        tooltipPlacement: {
            margin: '8px 0',
        }
    }),
)