import { makeStyles } from '@material-ui/core/styles';
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles({
    paymentPlan: {
        background: `${colors.main} !important`,
        '& .plan-name': {
            fontSize: '25px !important',
            color: '#537b98',
        },
        '& .select-button': {
            fontSize: '17px !important',
        },
    },
    chosePlan: {
        color: colors.main
    },
});