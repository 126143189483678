import { Tooltip, createStyles, makeStyles, Theme, withStyles } from "@material-ui/core";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontSize: 20,
            fontWeight: 600,
            paddingRight: 10,
            paddingBottom: 10,
        },
        itemContainer: {
            maxHeight: 650,
            overflowY: 'auto'
        },
        itemContent: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            [theme.breakpoints.down("xs")]: {
                display: 'inherit',
            },
        },
        scoreSection: {
            height: 40,
            maxHeight: 40,
            fontSize: 14,
        },
        divider: {
            height: 16,
            borderLeft: `2px solid ${colors.gray1}`
        },
        indicator:{
            color: colors.gray1,
            fontWeight:600
        },
        cardFooter: {
            boxSizing: 'border-box',
            margin: '30px -30px 0',
            display: 'flex',
            justifyContent: 'flex-end',
            borderTop: `2px solid ${colors.stroke}`,
            paddingRight: 26,
            [theme.breakpoints.down("xs")]: {
                margin: '30px 0 0',
                paddingRight: 0,
            },
        },
        chartTooltip: {
            maxWidth: 320,
            fontSize: 12,
            padding: 10,
            color: colors.white,
            backgroundColor: colors.lightBlack,
            borderRadius: 2,
            marginLeft: -100
        },
        paddingRight: {
            paddingRight: 8,
        },
        tooltip: {
            width: '225px',
            maxWidth: '225px',
            padding: 8,
            background: colors.lightBlack,
        },
        arrow: {
            color: colors.lightBlack,
        },
        tooltipPlacement: {
            margin: '12px 0',
        },
    })
);

export const OptionTooltip = withStyles((theme: Theme) => ({
}))(Tooltip);