import LogRocket from 'logrocket';

export function Page() {

    // @ts-ignore
    window.analytics.page();
}

export function PageDetail(name: string, props: { [name: string]: string }) {

    // @ts-ignore
    window.analytics.page(name, props)
}

export function Identify(identifier: string, props: any) {

    // @ts-ignore
    window.analytics.identify(identifier, props)

    // LogRocket data
    LogRocket.identify(identifier, props);
}

export function Track(name: string, props: any, traits?: any) {

    if (traits === undefined) {

        // @ts-ignore
        window.analytics.track(name, props)
    } else {
        // @ts-ignore
        window.analytics.track(
            name,
            props,
            {
                traits: traits
            })
    }
}

export function PlatformName() {
    return window.location.host.includes('wildhealth') ? 'WH' : window.location.host.includes('localhost') ? 'dev' : 'unknown';
}
