import React, {FC, Fragment} from 'react';
import { Box, Collapse, ListItem, ListItemText, TextField, FormControl, Select, MenuItem } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { WildHealthButton } from "../../../../common/components/wildHealthButton/WildHealthButton";
import { useStyles } from "../manageApiResponseComponent.styles";
import {ToolEnum} from "../manageApiResponseComponent.hooks";
import { EmployerProductModel } from '../../../models/employerProduct.model';

type EndpointAdminToolProps = {
    id: string
    params: any
    handleChanges: (field: string, value: any, tool: ToolEnum) => void,
    handleOpenToggle: ()=>void
    handleClick: (any)=>void
    title: string
    errors:any
    isOpen: boolean
    isLoading: boolean
    toolMenu: ToolEnum
    products: EmployerProductModel[]
}


const ChangeSubscriptionPriceComponent: FC<EndpointAdminToolProps> = (props): JSX.Element => {
    const {id, isLoading, title, errors, params, handleOpenToggle, handleClick, handleChanges, isOpen, products} = props;

    const classes = useStyles();

    return (
        <Fragment>
            <ListItem id={id} className={classes.divider} button onClick={handleOpenToggle}>
                <ListItemText primary={<span className="text-medium">{title}</span>}/>
                {isOpen ? <ExpandLess className="wh-tw-text-primaryV" /> : <ExpandMore />}
            </ListItem>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <Box p={2}>
                    <Box>
                        <Box className="wh-tw-text-sm wh-tw-text-darkGray" mb={1}>Subscription Id *</Box>
                        <TextField
                            fullWidth
                            size="small"
                            required
                            type="number"
                            InputProps={{className: 'input', classes}}
                            id="subscriptionId"
                            placeholder="Vendor Id"
                            value={params.subscriptionId}
                            variant="outlined"
                            disabled={!isOpen}
                            error={!!errors['subscriptionId']}
                            helperText={errors['subscriptionId']}
                            onChange={(v) => handleChanges("subscriptionId", v.target.value, ToolEnum.CHANGE_SUBSCRIPTION_PRICE)}
                        />
                    </Box>
                    <Box my={2}>
                        <Box className="wh-tw-text-sm wh-tw-text-darkGray" mb={1}>Price Id *</Box>
                        <TextField
                            fullWidth
                            size="small"
                            required
                            InputProps={{className: 'input', classes}}
                            id="priceId"
                            placeholder="Price Id"
                            value={params.priceId}
                            variant="outlined"
                            disabled={!isOpen}
                            error={!!errors['priceId']}
                            helperText={errors['priceId']}
                            onChange={(v) => handleChanges("priceId", v.target.value, ToolEnum.CHANGE_SUBSCRIPTION_PRICE)}
                        />
                    </Box>
                    <Box my={2}>
                        <Box className="wh-tw-text-sm wh-tw-text-darkGray" mb={1}>CouponCode</Box>
                        <TextField
                            fullWidth
                            size="small"
                            required
                            InputProps={{className: 'input', classes}}
                            id="couponCode"
                            placeholder="CouponCode"
                            value={params.couponCode}
                            variant="outlined"
                            disabled={!isOpen}
                            error={!!errors['couponCode']}
                            helperText={errors['couponCode']}
                            onChange={(v) => handleChanges("couponCode", v.target.value, ToolEnum.CHANGE_SUBSCRIPTION_PRICE)}
                        />
                    </Box>
                    <Box my={2}>
                        <FormControl
                            variant="outlined"
                            color='primary'
                            required
                            size="small"
                            disabled={!isOpen}
                            classes={{ root: classes.root }}
                            fullWidth>
                            <Box className="wh-tw-text-sm wh-tw-text-darkGray" mb={1}>Employer Product</Box>
                            <Select
                                style={{ background: '#FFF' }}
                                classes={{ root: classes.root }}
                                value={params.employerProductId}
                                inputProps={{
                                name: 'employer Product Id',
                                id: 'vendor-label',
                                classes,
                                }}
                                renderValue={() => {
                                    if (params.employerProductId === -1) {
                                        return <Box >No Change</Box>;
                                    }
                                    if (params.employerProductId === 0) {
                                        return <Box >Remove</Box>;
                                    }

                                    return products.find(p => p.id === params.employerProductId)?.name
                                }}
                                displayEmpty
                                onChange={(v) => handleChanges("employerProductId", v.target.value, ToolEnum.CHANGE_SUBSCRIPTION_PRICE)}
                            >
                                <MenuItem value={-1}>No Change</MenuItem>
                                <MenuItem value={0}>Remove</MenuItem>
                                {products.map(p => (
                                  <MenuItem key={p.id} value={p.id}>{p.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <WildHealthButton
                        fullWidth
                        id="execute-button"
                        loading={isLoading}
                        onClick={handleClick}
                    >
                        Execute
                    </WildHealthButton>
                </Box>
            </Collapse>
        </Fragment>
    )

}

export default ChangeSubscriptionPriceComponent;