import React from "react";
import { Box, Table, TableBody, TableContainer, TableFooter, TableHead } from "@material-ui/core";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { toCurrentTimeZone } from "../../../timezones/helpers/timezone";
import {ManageRxntButtonComponent} from "../manageRxntButton/ManageRxntButtonComponent";
import moment from "moment";
import {RxntMedicationModel} from "../../models/patientMedicationModel";

interface MedicationsRxntComponentProps {
    patientId: number;
    items: RxntMedicationModel[];
}

export const MedicationsRxNTComponent: React.FC<MedicationsRxntComponentProps> = (props: MedicationsRxntComponentProps) => {
    const {
        items,
        patientId
    } = props;

    const displayDataValue = (value: Date) => {
        return value ? moment(toCurrentTimeZone(value)).format("MM/DD/yyyy") : 'N/A';
    }

    return (
        <Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <WildHealthTableRow>
                            <WildHealthTableCell style={{ width: '40%' }}>
                                <Box pl={2}>
                                    Name
                                </Box>
                            </WildHealthTableCell>
                            <WildHealthTableCell style={{ width: '15%' }}>
                                Dosing Instruction
                            </WildHealthTableCell>
                            <WildHealthTableCell style={{ width: '15%' }}>
                                Number of refills
                            </WildHealthTableCell>
                            <WildHealthTableCell style={{ width: '15%' }}>
                                Start Date
                            </WildHealthTableCell>
                            <WildHealthTableCell style={{ width: '15%' }}>
                                Last Prescription Date
                            </WildHealthTableCell>
                        </WildHealthTableRow>
                    </TableHead>
                    <TableBody>
                        {
                            items.map((item, index) =>
                                <WildHealthTableRow key={index}>
                                    <WildHealthTableCell>
                                        <Box pl={2}>
                                            {
                                                item.name
                                            }
                                        </Box>
                                    </WildHealthTableCell>
                                    <WildHealthTableCell>
                                        <Box display="flex" flexWrap="wrap">
                                            {item.dosingInstructions}
                                        </Box>
                                    </WildHealthTableCell>
                                    <WildHealthTableCell>
                                        <Box display="flex" flexWrap="wrap">
                                            {item.numberOfRefills}
                                        </Box>
                                    </WildHealthTableCell>
                                    <WildHealthTableCell>
                                        <Box display="flex" flexWrap="wrap">
                                            {displayDataValue(item.startDate)}
                                        </Box>
                                    </WildHealthTableCell>
                                    <WildHealthTableCell>
                                        <Box display="flex" flexWrap="wrap">
                                            {displayDataValue(item.lastDatePrescribed)}
                                        </Box>
                                    </WildHealthTableCell>
                                </WildHealthTableRow>
                            )
                        }
                    </TableBody>
                    <TableFooter>
                        <Box display='flex' alignItems='stretch' py={1}>
                            <ManageRxntButtonComponent patientId={patientId}/>
                        </Box>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Box>
    )
}
