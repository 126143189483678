import Axios from "axios-observable";
import { Observable } from "rxjs";
import { authHeader } from "../../common/helpers/auth-header";
import { snackService } from "../../common/snack/state";
import { CreateVitalModel, createVitalValueModel, TileVitalModel, UpdateVitalValueModel, VitalModel, VitalsDateRangeType } from "../models/vital.model";
import { VitalStore, vitalStore } from "../stores";

export class VitalService {
    private url = `${process.env.REACT_APP_API_URL}PatientVitals`;
    private latestVitalUrl = `${this.url}/latest`;

    constructor(private vitalStore: VitalStore) { }

    /**
     * Returns vital
     */
    public getVital(patientId: number | null = null): Observable<TileVitalModel> {
        let url = this.latestVitalUrl;
        if (patientId) {
            url = `${url}?patientId=${patientId}`;
        }
        return new Observable(observer => {
            Axios.get<TileVitalModel>(url, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.vitalStore.update({ latestVital: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    getLatestVital(vitals: Array<VitalModel>): TileVitalModel {
        const firstIndex = 0;
        const tileVital: TileVitalModel = {};
        vitals.forEach(vital => {
            tileVital[vital.name] = {
                id: vital.id,
                displayName: vital.displayName,
                dimension: vital.values && vital.values.length > 0
                    ? vital.dimension
                    : null,
                value: vital.values && vital.values.length > 0
                    ? vital.values[firstIndex].value
                    : null,
                valueId: vital.values && vital.values.length > 0
                    ? vital.values[firstIndex].id
                    : null,
                date: vital.values && vital.values.length > 0
                    ? vital.values[firstIndex].date
                    : null,
            }
        });
        return tileVital;
    }

    /**
     * Create vital values
     */
    public createVital(vitals: Array<CreateVitalModel>, patientId: number | null = null): Observable<Array<VitalModel>> {
        let url = this.url;
        if (patientId) {
            url = `${url}?patientId=${patientId}`;
        }
        return new Observable(observer => {
            Axios.post<Array<VitalModel>>(url, vitals, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.vitalStore.update({ vitals: response.data });
                        const latestVital = this.getLatestVital(response.data);
                        this.vitalStore.update({ latestVital: latestVital });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    /**
     * Get vitals values in data range
     */
    public get(page: number, pageSize: number, start?: Date, end?: Date, patientId: number | null = null): Observable<void> {
        let url = `${this.url}?page=${page - 1}&pageSize=${pageSize}&start=${start.toISOString()}&end=${end.toISOString()}`;
        if (patientId) {
            url = `${url}&patientId=${patientId}`;
        }

        return new Observable<void>(observer => {
            //Used 'fetch' because there is issue with converting date from UTC to Local DateTime
            fetch(url, { headers: authHeader() })
                .then(async (response) => {
                    response.json().then(json => {
                        if (json.data) {
                            const vitals = json.data.map(i => ({ ...i, values: i.values.map(v => createVitalValueModel(v.id, i.name, v.value, new Date(`${v.date}Z`), v.sourceType)) }));
                            this.vitalStore.update({ vitals: vitals, totalVitals: json.totalCount });

                            observer.next();
                            observer.complete();
                        } else {
                            observer.error();
                            observer.complete();
                        }

                    })
                })
                .catch(error => {
                    snackService.commonErrorHandler(error);
                    observer.error(error);
                    observer.complete();
                });
        })
    }

    public getVitalAverage(patientId: number, dateRange: VitalsDateRangeType): Observable<any> {
        let url = `${this.url}/Average?patientId=${patientId}&dateRange=${dateRange}`;

        return new Observable(observer => {
            Axios.get<any>(url, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.vitalStore.update({ vitalsAverage: response.data })
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();

                    }

                );
        })
    }

    public create(items: CreateVitalModel[], patientId: number | null = null): Observable<void> {
        let url = this.url;
        if (patientId) {
            url = `${url}?patientId=${patientId}`;
        }

        return new Observable<void>(observer => {
            Axios.post<VitalModel[]>(url, items, { headers: authHeader() })
                .pipe()
                .subscribe(
                    () => {
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public remove(items: number[], patientId: number | null = null): Observable<void> {
        const ids = items && items.length
            ? items.map(id => `ids=${id}`).join('&')
            : '';

        let url = `${this.url}?${ids}`;
        if (patientId) {
            url = `${url}&patientId=${patientId}`;
        }

        return new Observable<void>(observer => {
            Axios.delete<VitalModel[]>(url, { headers: authHeader() })
                .pipe()
                .subscribe(
                    () => {
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public updateVitalValue(items: UpdateVitalValueModel[], patientId: number | null = null): Observable<void> {
        let url = this.url;
        if (patientId) {
            url = `${url}?patientId=${patientId}`;
        }

        return new Observable<void>(observer => {
            Axios.patch<UpdateVitalValueModel[]>(url, items, { headers: authHeader() })
                .pipe()
                .subscribe(
                    () => {
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public checkDate(date: Date, patientId: number | null = null): Observable<void> {
        let url = `${this.url}/CheckDate?date=${date.toISOString()}`
        if (patientId) {
            url = `${url}&patientId=${patientId}`;
        }

        return new Observable<void>(observer => {
            Axios.get(url, { headers: authHeader() })
                .pipe()
                .subscribe(
                    () => {
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        const messages = error?.response?.data?.messages;
                        observer.error(messages?.length ? messages[0] : error);
                        observer.complete();
                    }
                );
        })
    }
}

export const vitalService = new VitalService(vitalStore);
