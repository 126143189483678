import { WildHealthPlaceHolderBase } from './WildHealthPlaceHolderBase'
import { RoutesConstants } from '../../../../constants/route.constants'
import { ImageConstants } from '../../../../constants/image.constants'
import { useStyles } from './commonPlaceHolder.styles'
import { Box } from '@material-ui/core'
import React from 'react'

export interface HealthSummaryPlaceHolderProps {
    title: string;
    children: React.ReactNode
}

export const HealthSummaryPlaceHolder: React.FC<HealthSummaryPlaceHolderProps> = (props: HealthSummaryPlaceHolderProps) => {
    const classes = useStyles();

    return (
        <WildHealthPlaceHolderBase message={props.title}>
            {props.children}
            <Box mt='30px'>
                <img src={RoutesConstants.cdnBaseLink + ImageConstants.emptyReportPath}
                    alt="img" className={classes.image} />
            </Box>
        </WildHealthPlaceHolderBase>
    );
}
