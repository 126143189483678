import { Box, useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { HealthRecommendationComponent } from "../components/healthRecommendationComponent/HealthRecommendationComponent";
import {
    HealthReportMode,
    HealthReportSections,
    HealthReportVersion,
    recommendationRowsCapacity, RecommendationTypesNumbers
} from "../models/healthReport.models";
import { HealthReportBasePage, HealthReportMobileBasePage } from "./HealthReportBasePage";
import { useStyles } from './healthReport.styles';
import { HealthReportPageProps } from "./healthReportPageProps";
import commonUseStyles from "../../common/styles/common.styles";
import clsx from "clsx";
import { WildHealthButton } from '../../common/components/wildHealthButton/WildHealthButton';
import { RecommendationSingleSelectComponent } from '../components/recommendationSingleSelectComponent/RecommendationSingleSelectComponent';

export const HealthReportLongevity2Page: React.FC<HealthReportPageProps> = (props: HealthReportPageProps) => {
    const {
        report,
        pageNumber,
        handleChanges,
        handleSaveChanges,
        isChanged,
        mode,
        practice,
        recommendations,
        handleSelected,
        handleChangesNewRecommendation,
        handleAddRecommendation,
        handleSaveChangesNew,
    } = props;
    const commonClasses = commonUseStyles();
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        props.mode !== HealthReportMode.PrintMode && isSmallScreen ? (
            <HealthReportMobileBasePage section={HealthReportSections.Longevity} pageNumber={pageNumber} practice={practice}>
                <Box>
                    <Box px={2}>
                        <Box>
                            <span className={classes.reportTitle}>Your Personalized Longevity Report</span>
                        </Box>
                        <Box mt="10px">
                            <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeMediumReport, commonClasses.textRegularReport)}>Fasting</span>
                        </Box>
                        <Box mt="12px" fontSize="14px" lineHeight="22px">
                            <p>
                                Using one or all of the following fasting methods will be beneficial for just about anyone.
                                Time Restricted Feeding. A lot of people call this intermittent fasting. But since there is no caloric restriction, it is not truly fasting, you are just restricting the time window within which you eat, ideally the same number of calories as if you didn’t restrict the time. So, consume the same amount of food/calories you typically do in a day but just consume it in a smaller feeding window.
                            </p>
                            <p>For example:</p>
                            <p>A. 12 hour fast/12hour feeding window: eat between 7am and 7pm. You can of course adjust the times.</p>
                            <p>B.16 hour fast/8 hour feeding window: eat between 11am and 7pm. Now you are only eating in an 8 hour window.</p>
                            <p>
                                As your body becomes used to this you can even narrow the feeding window more if you wish so you’ll be eating within a 7 or 6 hour window. Time restricted feeding has been shown to reduce insulin levels and increase insulin sensitivity, as well as reduce
                                blood pressure.
                            </p>
                            <p>
                                Intermittent Fasting. This is truly fasting, meaning you will restrict calories for 1-5 days.
                                First option is a 0 calorie fast. You are allowed to only consume coffee or water for 1-3 days. Don’t put food in your mouth.
                                Second option is a Fasting-mimicking diet. Day 1- consume about 1100 calories, day 2-5 consume about 800 calories.
                                Low protein. You can consume vegetables, extra virgin olive oil, nuts, seeds, soups, salads, but no meat and no junk.
                                Benefit: prolonged fasting or calorie restriction greater than 24 hours results in autophagy (where the body eats itself)
                                and apoptosis (programmed cell death) allowing your body to clean up damaged proteins, old cells that have lost their
                                programing, and even pre-cancerous cells.
                            </p>
                        </Box>
                        <Box mt="20px">
                            <Box pb="10px">
                                <span className={classes.reportTitle}>Your Longevity Lifestyle Recommendations</span>
                                <Box display="flex" justifyContent="flex-end" width={1}>
                                    {
                                        parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation) && HealthReportMode.EditMode === mode && <WildHealthButton
                                            id="health-recommendation-new-save"
                                            onClick={() => handleSaveChangesNew()}
                                            disabled={!isChanged}>
                                            <Box >
                                                Save changes
                                            </Box>
                                        </WildHealthButton>
                                    }
                                </Box>
                            </Box>
                            {parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
                                ? <Box>
                                    <RecommendationSingleSelectComponent
                                        currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.Longevity)}
                                        recommendation={report.longevityReport.recommendation}
                                        type={RecommendationTypesNumbers.Longevity}
                                        handleSelected={handleSelected}
                                        handleChanges={handleChangesNewRecommendation}
                                        handleAddRecommendation={handleAddRecommendation}
                                        mode={mode}
                                    />
                                </Box>
                                : <HealthRecommendationComponent
                                    mode={mode}
                                    rows={recommendationRowsCapacity[RecommendationTypesNumbers.Longevity]}
                                    recommendation={report.longevityReport.recommendation}
                                    handleContentChanges={!handleChanges ? handleChanges : (value) => {
                                        handleChanges('longevityReport.recommendation.content', value)
                                    }}
                                    handleManualContentChanges={!handleChanges ? handleChanges : (value) => {
                                        handleChanges('longevityReport.recommendation.manualContent', value)
                                    }}
                                    handleSaveChanges={handleSaveChanges}
                                    isChanged={isChanged} />
                            }
                        </Box>
                    </Box>
                </Box>
            </HealthReportMobileBasePage>
        ) : (
            <HealthReportBasePage section={HealthReportSections.Longevity} pageNumber={pageNumber} practice={practice}>
                <Box>
                    <Box px={15} pt={5}>
                        <Box>
                            <span className="report-title">Your Personalized Longevity Report</span>
                        </Box>
                        <Box mt={3}>
                            <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeSmallReport)}>Fasting</span>
                        </Box>
                        <Box>
                            <p>
                                Using one or all of the following fasting methods will be beneficial for just about anyone.
                                Time Restricted Feeding. A lot of people call this intermittent fasting. But since there is no caloric restriction, it is not truly fasting, you are just restricting the time window within which you eat, ideally the same number of calories as if you didn’t restrict the time. So, consume the same amount of food/calories you typically do in a day but just consume it in a smaller feeding window.
                            </p>
                            <p>For example:</p>
                            <p>A. 12 hour fast/12hour feeding window: eat between 7am and 7pm. You can of course adjust the times.</p>
                            <p>B.16 hour fast/8 hour feeding window: eat between 11am and 7pm. Now you are only eating in an 8 hour window.</p>
                            <p>
                                As your body becomes used to this you can even narrow the feeding window more if you wish so you’ll be eating within a 7 or 6 hour window. Time restricted feeding has been shown to reduce insulin levels and increase insulin sensitivity, as well as reduce
                                blood pressure.
                            </p>
                            <p>
                                Intermittent Fasting. This is truly fasting, meaning you will restrict calories for 1-5 days.
                                First option is a 0 calorie fast. You are allowed to only consume coffee or water for 1-3 days. Don’t put food in your mouth.
                                Second option is a Fasting-mimicking diet. Day 1- consume about 1100 calories, day 2-5 consume about 800 calories.
                                Low protein. You can consume vegetables, extra virgin olive oil, nuts, seeds, soups, salads, but no meat and no junk.
                                Benefit: prolonged fasting or calorie restriction greater than 24 hours results in autophagy (where the body eats itself)
                                and apoptosis (programmed cell death) allowing your body to clean up damaged proteins, old cells that have lost their
                                programing, and even pre-cancerous cells.
                            </p>
                        </Box>
                        <Box mt={10}>
                            <Box pb={2} display="flex" justifyContent="space-between">
                                <span className="report-title">Your Longevity Lifestyle Recommendations</span>
                                {
                                    parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation) && HealthReportMode.EditMode === mode && <WildHealthButton
                                        id="health-recommendation-new-save"
                                        onClick={() => handleSaveChangesNew()}
                                        disabled={!isChanged}>
                                        <Box >
                                            Save changes
                                        </Box>
                                    </WildHealthButton>
                                }
                            </Box>
                            {parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
                                ? <Box>
                                    <RecommendationSingleSelectComponent
                                        currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.Longevity)}
                                        recommendation={report.longevityReport.recommendation}
                                        type={RecommendationTypesNumbers.Longevity}
                                        handleSelected={handleSelected}
                                        handleChanges={handleChangesNewRecommendation}
                                        handleAddRecommendation={handleAddRecommendation}
                                        mode={mode}
                                    />
                                </Box>
                                : <HealthRecommendationComponent
                                    mode={mode}
                                    rows={recommendationRowsCapacity[RecommendationTypesNumbers.Longevity]}
                                    recommendation={report.longevityReport.recommendation}
                                    handleContentChanges={!handleChanges ? handleChanges : (value) => {
                                        handleChanges('longevityReport.recommendation.content', value)
                                    }}
                                    handleManualContentChanges={!handleChanges ? handleChanges : (value) => {
                                        handleChanges('longevityReport.recommendation.manualContent', value)
                                    }}
                                    handleSaveChanges={handleSaveChanges}
                                    isChanged={isChanged} />
                            }
                        </Box>
                    </Box>
                </Box>
            </HealthReportBasePage>
        )
    )
}
