import React from 'react';
import { AgreementModel } from '../../models/agreement.model';
import { PaymentPeriodModel } from '../../models/paymentPeriod.models';
import { PaymentPlanModel } from '../../models/paymentPlan.models';
import { PaymentPriceModel } from '../../models/paymentPrice.models';
import { useFacade } from "./agreementsComponent.hooks";

export interface AgreementsComponentProps {
    agreement: AgreementModel;
    paymentPlan: PaymentPlanModel;
    paymentPeriod: PaymentPeriodModel;
    paymentPrice: PaymentPriceModel;
    currentAgreementPage: Function;
}

export const AgreementsComponent: React.FC<AgreementsComponentProps> = (props: AgreementsComponentProps) => {
    const [
        renderAgreement,
    ] = useFacade(props);

    if (!props.agreement) {
        return <> </>
    }

    return (
        <>
            {
                renderAgreement()
            }
        </>
    );
}