export enum ConversationSubject {
    Billing = 1,
    TechSupport = 2,
    Scheduling = 3,
    PlanQuestions = 4,
    Feedback = 5,
    TestKits = 6,
    Other = 7,
    Labs = 100,
    ExercisePlan = 101,
    Nutrition = 102,
    LifestyleRoutines = 103,
    Mindfulness = 104,
    Sleep = 105,
    SupplementsMedications = 106
}

export const supportConversationSubjects = new Map<ConversationSubject, string>([
    [ConversationSubject.Billing, 'Billing'],
    [ConversationSubject.TechSupport, 'Tech Support'],
    [ConversationSubject.Scheduling, 'Scheduling'],
    [ConversationSubject.PlanQuestions, 'Plan Questions'],
    [ConversationSubject.Feedback, 'Feedback'],
    [ConversationSubject.TestKits, 'DNA/Epigenetic Age Kits'],
    [ConversationSubject.Other, 'Other']
]);

export const healthCareConversationSubjects = new Map<ConversationSubject, string>([
    [ConversationSubject.Labs, 'Labs'],
    [ConversationSubject.ExercisePlan, 'Exercise Plan'],
    [ConversationSubject.Nutrition, 'Nutrition'],
    [ConversationSubject.LifestyleRoutines, 'Lifestyle Routines'],
    [ConversationSubject.Mindfulness, 'Mindfulness'],
    [ConversationSubject.Sleep, 'Sleep'],
    [ConversationSubject.SupplementsMedications, 'Supplements/Medications'],
]);