import {useEffect, useState} from "react";
import {Subscription} from "recompose";
import {authQuery} from "../../../auth/stores/auth";
import {PermissionType, UserType} from "../../../auth/models/auth.enums";
import {EmployeeConversationsStore, employeeConversationsStore} from "../../stores/employeeConversationsStore/employeeConversations.store";
import {employeeConversationsQuery} from "../../stores/employeeConversationsStore/employeeConversations.query";
import {Client as ConversationsClient} from "@twilio/conversations";
import {PatientConversationsStore, patientConversationsStore} from "../../stores/patientConversationsStore/patientConversations.store"
import { patientConversationsQuery} from "../../stores/patientConversationsStore/patientConversations.query";
import {conversationsService} from "../../services/conversations.service"
import { ConnectionStatuses, EmployeeConversationModel, PatientConversationModel } from "../../models/conversation.models";
import { onEmit } from "../../../common/helpers/on-emit";
import { ConversationState } from "../../models/conversationState.models";
import { profileService } from "../../../account/services/profile.service";

interface LoadConversationStoreComponentState {
    patientId: number | null;
    conversations: EmployeeConversationModel[] | PatientConversationModel[];
    conversationHealthCare: PatientConversationModel[],
    conversationSupport: PatientConversationModel[],
    conversationProxies: any[];
    targetConversation: EmployeeConversationModel | PatientConversationModel;
    userType: UserType | null;
}

let context: LoadConversationStoreComponentState = null;
let conversationsJoined: EmployeeConversationModel[] | PatientConversationModel[] = [];
let conversationsClient: ConversationsClient = null;
let conversationsJoinedInterval: any;
let conversationsDirty: boolean = true;
let conversationsStore: PatientConversationsStore | EmployeeConversationsStore = null;

export function useFacade(): void {

    const[state, setState] = useState({
        patientId: null,
        conversationProxies: [],
        conversations: [],
        conversationHealthCare: [],
        conversationSupport: [],
        targetConversation: null,
        userType: null,
    } as LoadConversationStoreComponentState);

    context = state

    switch(authQuery.getType()){
        case(UserType.Employee):
            context.userType = UserType.Employee;
            conversationsStore = employeeConversationsStore;
            break;
        case(UserType.Patient):
            context.userType = UserType.Patient;
            conversationsStore = patientConversationsStore;
            break;
        default:
            context.userType = UserType.Unspecified;
            break;
    }

    const reset = () => {
        console.log("resetting conversations");

        unsubscribe();

        setState(state => ({
            ...state,
            targetConversation: null,
            conversationProxies: [],
            conversations: []
        }));

        conversationsJoined = [];

        if (conversationsStore) conversationsStore.resetConversations();
        employeeConversationsStore.resetAttachments();
        employeeConversationsStore.resetAttachmentsView();
    }

    const start = () => {      
        if (context.userType == UserType.Employee){
            startEmployee();
        }
        else if (context.userType == UserType.Patient){
            startPatient();
        }
    }

    const startEmployee = () => {
        conversationsService.getEmployeeAuthor().subscribe(
            (author) => {
                conversationsService.getEmployeeConversations().subscribe(
                    (conversations) => {
                        employeeConversationsStore.setExpectedConversations(conversations);
                        const employeeIds = conversations.map(r => r.employees).reduce((e1: number[], e2) => {
                            for (const item of e2)
                            {
                                if(!e1.includes(item.employeeId)) {
                                    e1.push(item.employeeId)
                                }
                            }
    
                            return e1;
                        }, [])
                        const setConversations = () =>  {
                            setState(state => ({ ...state, conversations: conversations }))
                            initConversations(author.conversationAuthToken).then();
                        };
                        if (employeeIds.length) {
                            profileService.getEmployeeProfilePhotos(employeeIds.join(',')).subscribe(setConversations, setConversations)
                        } else {
                            setConversations();
                        }
                    }
                );
            }
        )
    }

    const startPatient = () => {
        patientConversationsStore.setIsLoading(true);
        conversationsService.getPatientAuthor().subscribe(
            (author) => {
                conversationsService.getPatientStaffConversations().subscribe(
                    (conversations) => {
                        patientConversationsStore.setSupportConversations(conversations);

                        conversationsService.getPatientHealthCareConversation().subscribe(
                            (conversation) => {
                                patientConversationsStore.setHealthCareConversation(conversation);
                                
                                patientConversationsStore.setIsLoading(false);

                                initConversations(author.conversationAuthToken).then()
                            }
                        );
                    }
                )
            }
        )
    }

    const reconnect = () => {
        reset();
        start();
    }


    const pushConversationJoined = function (conversation: EmployeeConversationModel | PatientConversationModel): void {

        if (!conversation) {
            return;
        }

        if (!conversationsJoined.find(i => i.id == conversation.id)) {
            conversationsJoined.push(conversation);
        } else {
            conversationsJoined = conversationsJoined.map(i => i.id === conversation.id ? conversation : i);
        }

        conversationsDirty = true;
    }

    const setOldestUnreadMessage = (conversation: EmployeeConversationModel | PatientConversationModel, proxy: any) => {

        // If we have at least one unread message, we now want to determine the oldest unread message and set that
        if(conversation.unreadMessages > 0) {

            pushConversationJoined(conversation);

            proxy.getMessages(200).then((result) => {

                const lastReadMessageIndex = proxy.lastReadMessageIndex;

                const targetIndex = lastReadMessageIndex === 0 || lastReadMessageIndex === null ? 0 : lastReadMessageIndex + 1;
                const oldestUnreadMessage = result.items.find(o => o.index == targetIndex);

                if (oldestUnreadMessage) {
                    conversation.oldestUnreadMessageAt = oldestUnreadMessage.dateCreated;
                    conversationsStore.updateConversation(conversation);
    
                    pushConversationJoined(conversation);
                }
            });
        }
    }

    const isActiveConversation = (conversation: EmployeeConversationModel | PatientConversationModel) => {
        if(context.userType === UserType.Employee) {
            if (conversation.employees.find(i => i.email === authQuery.getEmail() && !i.isDeleted)?.isActive) {
                return true;
            }
        }
        if(context.userType === UserType.Patient) {
            if (conversation.state === ConversationState.Active) {
                return true;
            }
        }
        return false;
    }

    const isAuthor = (conversation: EmployeeConversationModel | PatientConversationModel, message: any) => {
        let participant = null;
        if(context.userType === UserType.Employee) {
            participant = conversation.employees.find(i => i.email === authQuery.getEmail());
        }
        else if(context.userType === UserType.Patient) {
            participant = conversation.patients.find(i => i.email === authQuery.getEmail());
        }

        if(participant) {
            return participant.vendorExternalIdentity === message.state.author
        }

        return false
    }

    const setUnreadMessages = (conversation: EmployeeConversationModel | PatientConversationModel, proxy: any) => {

        // Get number of unread messages
        proxy.getUnreadMessagesCount().then((count) => {

            conversation.unreadMessages = count;

            conversationsStore.updateConversation(conversation);

            // If there's no read messages by this user then the count is null, we will want to get the latest message to determine unread count
            if(count === null) {
                proxy.getMessages(200).then((result) => {
                    if(result.items.length > 0) {
                        conversation.unreadMessages = result.items[result.items.length - 1].index + 1;

                        conversationsStore.updateConversation(conversation);

                        setOldestUnreadMessage(conversation, proxy);
                    }
                });
            } else {
                setOldestUnreadMessage(conversation, proxy);
            }
        });
    }

    const setProxy = (conversation: EmployeeConversationModel | PatientConversationModel, proxy: any) => {
        if (conversation && proxy) {
            conversation.proxy = proxy;
            conversation.lastMessageAt = proxy.lastMessage ? proxy.lastMessage.dateCreated : null;
            if (isActiveConversation(conversation)) {
                setUnreadMessages(conversation, proxy);
            }

            pushConversationJoined(conversation);
        }

        // Means this just got added from client before we could add to our state, store the proxy for later matching
        if(!conversation) {
            conversationsStore.addOrphanedProxy(proxy);
        }
    }

    const connectionStateChanged = (status: string) => {
        employeeConversationsStore.updateConnectionStatus(status);
        setState(state => ({ ...state, status: status }));
        switch (status) {
            case ConnectionStatuses.connecting:
                break; // Wait for connected and set is loading == false
            case ConnectionStatuses.connected:
                // setMessagingReady();
                break;
            case ConnectionStatuses.disconnecting:
                break; // Wait for disconnect and restart connection
            case ConnectionStatuses.disconnected:
                reconnect();
                break;
            case ConnectionStatuses.denied:
                reconnect();
                break;

            default:
                break;
        }
    }

    const conversationJoined = (proxy: any) => {
        let conversation = context.conversations?.find(i => i && i.vendorExternalId === proxy.sid);

        setProxy(conversation, proxy);
    };

    const conversationLeft = (proxy: any) => {
        setState(state => ({
            ...state,
            conversationProxies: state.conversationProxies.filter(i => i.sid !== proxy.sid),
            conversations: state.conversations.filter(i => i.vendorExternalId !== proxy.sid),
            targetConversation: context.targetConversation
        }));
    }


    const messageAdded = (message) => {
        const conversation = context.conversations?.find(x => x && x.vendorExternalId === message?.conversation?.sid);
        if (conversation) {
            conversation.lastMessageAt = new Date();
            if (isActiveConversation(conversation) && !isAuthor(conversation, message)) {
                if (conversation.unreadMessages) conversation.unreadMessages += 1;
                else conversation.unreadMessages = 1;

                if (conversation.employees.length === 0 && !isAuthor(conversation, message)) {
                    reconnect()
                }
            }
            conversationsStore.updateConversation(conversation);
        }
    }

    const messageUpdated = (message) => {
        const conversation = context.conversations?.find(x => x && x.vendorExternalId === message?.conversation?.sid);
        if (conversation) {
            conversation.lastMessageAt = new Date();

            conversationsStore.updateConversation(conversation);
        }
    }

    const trySetConversationsJoined = function (): void {

        if(conversationsDirty) {
            conversationsStore.setConversationsJoined(conversationsJoined);

            conversationsDirty = false;
        }
    }

    const initConversations = async (token: string) => {
        if (!token) {
            return;
        }

        unsubscribe();

        conversationsClient = new ConversationsClient(token);

        conversationsClient.on("connectionStateChanged", connectionStateChanged);
        conversationsClient.on("conversationJoined", conversationJoined);
        conversationsClient.on("conversationLeft", conversationLeft);
        conversationsClient.on("messageAdded", messageAdded);
        conversationsClient.on("messageUpdated", messageUpdated);

        conversationsJoinedInterval = setInterval(() => {
            trySetConversationsJoined()
        }, 1000);
    }

    const unsubscribe = () => {
        if (conversationsClient) {
            conversationsClient.off("connectionStateChanged", connectionStateChanged);
            conversationsClient.off('conversationJoined', conversationJoined);
            conversationsClient.off('conversationLeft', conversationLeft);
            conversationsClient.off("messageAdded", messageAdded)
            conversationsClient.off("messageUpdated", messageUpdated);
        }
    }

    useEffect(() => {
        if (state.conversationHealthCare.length || state.conversationSupport.length) {
            setState(state => ({
                ...state,
                conversations: [...state.conversationHealthCare, ...state.conversationSupport]
            }));
        }
    }, [state.conversationHealthCare, state.conversationSupport]);

    const useEffectCB = () => {

        const subscriptions: Subscription[] = [
            onEmit<EmployeeConversationModel[]>(employeeConversationsQuery.conversations$, conversations => {
                if(context.userType === UserType.Employee){
                    setState(state => ({
                        ...state,
                        conversations: conversations
                    }));
                }
            }),
            onEmit<PatientConversationModel[]>(patientConversationsQuery.supportConversations$, conversations => {
                if(context.userType === UserType.Patient){
                    setState(state => ({
                        ...state,
                        conversationSupport: [
                            ...conversations,
                        ]
                    }));
                }
            }),
            onEmit<PatientConversationModel>(patientConversationsQuery.heathCareConversation$, conversation => {
                if(context.userType === UserType.Patient){
                    setState(state => ({
                        ...state,
                        conversationHealthCare: [
                            conversation
                        ]
                    }));
                }
            }),
            onEmit<UserType>(authQuery.userType$, userType => {
                if ((authQuery.hasPermission(PermissionType.Conversations) && userType === UserType.Employee) 
                        || userType === UserType.Patient) {
                    reset();
        
                    start();
                } else if (userType === UserType.Unspecified) {
                    reset();
                }
            })
        ];

        return () => {
            unsubscribe();
            subscriptions.map(it => it.unsubscribe())
            conversationsClient?.shutdown()?.then();
            if (context.conversations) {
                context.conversations.forEach(x => x.proxy = null);
            }
        };
    }

    useEffect(useEffectCB, []);
}
