import React from 'react';
import { AuthenticatedLayoutComponent } from '../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from "../../../../models/menu.models";
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { DocumentsComponent } from '../../../documents/components/documentsComponent/DocumentsComponent';

export const PatientDocumentsPage: React.FC = () => {


    return (
        <AuthenticatedLayoutComponent
            title={TitlesService.documentsTitle}
            pageName={PagesNamesService.medicalHistory}
            selectedMenuItem={MenuItemTypes.Documents}
        >
            <DocumentsComponent />
        </AuthenticatedLayoutComponent>
    )
}
