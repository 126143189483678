import {useEffect, useState} from "react";
import {Subscription} from "recompose";
import { authService } from "../../../auth/services/auth.service";
import { authQuery } from "../../../auth/stores/auth";
import {onEmit} from "../../../common/helpers/on-emit";
import {IErrorState} from "../../../common/validation/error-state";
import {LocationModel} from "../../../locations/models/locations.models";
import {locationsQuery} from "../../../locations/stores";
import {EmployeeModel, UpdateEmployeeModel} from "../../models/employee.models";
import {PermissionModel} from "../../models/permission.models";
import {RoleModel} from "../../models/role.models";
import {employeeService} from "../../services/employees.service";
import {employeesQuery} from "../../stores/employeesStore";
import {updateEmployeeComponentValidator} from "./updateEmployeeComponent.validator";
import { Gender } from "../../../common/models/user.models";

interface UpdateEmployeeComponentState extends IErrorState {
    employee: UpdateEmployeeModel;
    roles: RoleModel[];
    locations: LocationModel[];
    permissions: PermissionModel[];
    isSubmitted: boolean;
    showSchedulerId: boolean;
    rxntExpanded: boolean;
}

const initModel: UpdateEmployeeModel = {
    id: 0,
    firstName: '',
    lastName: '',
    locationIds: [],
    roleId: 0,
    schedulerAccountId: '',
    permissions: [],
    email: '',
    gender: Gender.None,
    credentials: '',
    npi: '',
    rxntUserName: '',
    rxntPassword: ''
};

/**
 * Custom Hook to manage a view Model for Notifications component
 */
export function useFacade(
    employeeId: number,
    isOpen: boolean,
    selfManagingEmployee: boolean,
    handleToggleDialog: (value: boolean) => void
): [
    UpdateEmployeeComponentState,
    (event: React.ChangeEvent<HTMLInputElement>) => void,
    (event: React.ChangeEvent<HTMLInputElement>) => void,
    (event: React.ChangeEvent<HTMLInputElement>) => void,
    (field: string, value: any) => void,
    () => void,
    (value: string) => void,
    () => void,
    () => void
] {

    const [state, setState] = useState({
        employee: initModel,
        errors: {},
        locations: [],
        roles: [],
        isSubmitted: false,
        showSchedulerId: false
    } as UpdateEmployeeComponentState);

    const handleChanges = (field: string, value: any) => {
        updateEmployeeComponentValidator.validateAndSetState(state, setState, field, value);
        const employee = state.employee;
        employee[field] = value;
        setState({...state, employee});
    }

    const toggleShowSchedulerId = () => {
        setState({...state, showSchedulerId: !state.showSchedulerId});
    }

    const handleSchedulerAccountIdChanges = (value: string) => {
        const employee = state.employee;
        employee.schedulerAccountId = getValidSchedulerAccountId(value);
        setState({...state, employee: employee});
    }

    const getValidSchedulerAccountId = (value: string): string => {
        const defaultGuidLength = 36;
        const expectedValue = (v: string): boolean => v.length === defaultGuidLength && v.split('-').length > 2;

        if (value.length === 0 || expectedValue(value)) {
            return value;
        }

        const isUrl = value.includes('http');
        if (isUrl) {
            const parts = value.split('/');
            const id = parts.find(part => expectedValue(part));

            return id ?? '';
        }

        return '';
    }

    const handleSelectRole = (event: React.ChangeEvent<HTMLInputElement>) => {
        const id = parseInt((event.target as HTMLInputElement).value);
        const role = state.roles.find(i => i.id === id);

        if (role) {
            state.employee.roleId = role.id;
            state.employee.permissions = role.permissions.map(i => i.id);
            setState(state => ({...state}));
        }
    }

    const handleSelectLocation = (event) => {
        const locationIds = event.target.value;
        updateEmployeeComponentValidator.validateAndSetState(state, setState, 'locationIds', locationIds);
        setState({
            ...state,
            employee: {
                ...state.employee,
                locationIds: locationIds
            }
        });
    }

    const handleSelectPermission = (event: React.ChangeEvent<HTMLInputElement>) => {
        const id = parseInt((event.target as HTMLInputElement).value);
        const item = state.permissions.find(i => i.id === id);

        if (item) {
            state.employee.permissions = ((event.target as HTMLInputElement).checked && item) ?
                [...state.employee.permissions, item.id] :
                state.employee.permissions.filter(i => i !== id);
            setState(state => ({...state}));
        }
    }

    const handleUpdate = () => {
        updateEmployeeComponentValidator.validateObjectAndSetState(state, setState, state.employee);
        if (!updateEmployeeComponentValidator.stateIsValid(state)) {
            return;
        }

        if (state.isSubmitted) {
            return;
        }

        setState((state) => ({
            ...state,
            isSubmitted: true
        }));

        const cb = () => {
            setState((state) => ({
                ...state,
                errors: {},
                employee: Object.assign({}, initModel),
                isSubmitted: false
            }));

            handleToggleDialog(false);
        }

        const action = selfManagingEmployee ? () => { authService.reLogin(authQuery.getTargetLocationId()).subscribe(); cb(); } : cb;

        employeeService.updateEmployee(state.employee).subscribe(() => action(), () => {
            setState((state) => ({
                ...state,
                isSubmitted: false
            }));
        });
    }

    const toggleRxNT = () => {
        setState(state => ({
            ...state,
            rxntExpanded: !state.rxntExpanded
        }));
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<EmployeeModel>(employeesQuery.employee$, employee => {
                if (employee && employee.id === employeeId) {
                    setState(state => ({
                        ...state,
                        employee: {
                            id: employee.id,
                            firstName: employee.firstName,
                            lastName: employee.lastName,
                            locationIds: employee.locations.map(x => x.id),
                            roleId: employee.role.id,
                            schedulerAccountId: employee.schedulerAccountId,
                            permissions: employee.permissions,
                            email: employee.email,
                            gender: employee.gender,
                            credentials: employee.credentials,
                            npi: employee.npi,
                            rxntUserName: employee.rxntUserName,
                            rxntPassword: employee.rxntPassword
                        },
                        rxntExpanded: false,
                        roles: state.roles.some(x => x.id === employee.role.id)
                            ? state.roles
                            : [...state.roles, employee.role]
                    }));
                }
            }),
            onEmit<RoleModel[]>(employeesQuery.roles$, roles => {
                setState(state => ({...state, roles: roles}))
            }),
            onEmit<PermissionModel[]>(employeesQuery.permissions$, permissions => {
                setState(state => ({...state, permissions: permissions}))
            }),
            onEmit<LocationModel[]>(locationsQuery.availableLocations$, locations => {
                setState(state => ({...state, locations: locations}))
            }),
        ];

        if (isOpen) {
            setState((state) => ({
                ...state,
                errors: {},
                rxntExpanded: false,
                employee: Object.assign({}, initModel)
            }));

            employeeService.getActiveBy(employeeId);
        }

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    };

    useEffect(useEffectCB, [isOpen]);

    return [
        state,
        handleSelectLocation,
        handleSelectRole,
        handleSelectPermission,
        handleChanges,
        handleUpdate,
        handleSchedulerAccountIdChanges,
        toggleShowSchedulerId,
        toggleRxNT
    ];
}