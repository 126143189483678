import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import '@styles/index.scss';
import '@styles/main.css';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import {AppInsightsContext} from '@microsoft/applicationinsights-react-js';
import {reactPlugin} from './appInsights';
import GlobalStyles from "./app/styles/global.styles";
import {authQuery} from "./app/modules/auth/stores/auth";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";


function hasEnvVariable(name) {
    return process.env[name] != null && 
           process.env[name].trim() != ''
}

if(hasEnvVariable('REACT_APP_SENTRY_DSN') &&
   hasEnvVariable('REACT_APP_SENTRY_SAMPLE_RATE') &&
   hasEnvVariable('REACT_APP_SENTRY_ENVIRONMENT')) 
{ 
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_SAMPLE_RATE),
        environment: process.env.REACT_APP_SENTRY_ENVIRONMENT
    });
}
else 
{
    console.log('Skipped sentry.init(...)')
}

const getFaviconEl = () => {
    return document.getElementById("favicon");
}

const setupFavicon = () => {
    const favicon = getFaviconEl();
    // @ts-ignore
    favicon.href = "/" + authQuery.getCurrentPracticeId() + "/favicon.ico"
}

setupFavicon();

ReactDOM.render(
    <BrowserRouter>
        <AppInsightsContext.Provider value={reactPlugin}>
            <GlobalStyles/>
            <App/>
        </AppInsightsContext.Provider>
    </BrowserRouter>,
    document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
