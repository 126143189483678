import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { inputsService } from "../../services/inputs.service";
import { patientInputsService } from "../../services/patientInputs.service";

interface DataFilesComponentState {
    isLoading: boolean;
}

export function useFacade(patientId: number): [DataFilesComponentState] {
    const [state, setState] = useState({
        isLoading: true,
    } as DataFilesComponentState);

    useEffect(() => {
        const subscriptions: Subscription[] = [
        ];

        const action = patientId ? inputsService.getUploads(patientId) : patientInputsService.getUploads();
        action.subscribe(() => setState(state => ({...state, isLoading: false})));

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [patientId]);

    return [state];
}