import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles(() =>
    createStyles({
        container: {
            height: 335,
            display: "flex",
            flexDirection: "column",
            maxHeight: 335,
            minHeight: 335,
        },
        divider: {
            backgroundColor: colors.stroke,
        },
        thread: {
            flex: 1,
            minHeight: 200,
            overflow: 'auto',
            outline: 'none !important',
        },
        sendMessage: {
            flexShrink: 0,
            outline: 'none !important',
        },
        closedTicket: {
            flexShrink: 0,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: colors.gray8,
            color: colors.black,
            fontSize: 14,
            padding: 8,
        },
        typingAvatar: {
            width: 18,
            height: 18,
            fontSize: 10,
        },
        dropContainer: {
            height: '100%',
            border: `1px solid ${colors.main}`,
            background: `${colors.lightGray2}99`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
        },
        dropIcon: {
            color: colors.main,
            fontSize: 40,
        },
        dropTitle: {
            color: colors.main,
        },
        noneOutline: {
            outline: 'none !important',
        },
        viewOnlyMessage: {
            width: '100%',
            paddingTop: 12,
            paddingBottom: 12,
            borderTop: '1px solid' + colors.stroke
        }
    })
);

export const useDropStyles = makeStyles(() =>
    createStyles({
        dropContainer: {
            height: '100%',
            border: `1px solid ${colors.main}`,
            background: `${colors.lightGray2}99`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
        },
        dropIcon: {
            color: colors.main,
            fontSize: 40,
        },
        dropTitle: {
            color: colors.main,
        },
    })
);
