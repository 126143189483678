import moment from "moment";
import { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { interval, Observable, Subscription } from "rxjs";
import { confirmService } from "../../../../services/confirm.service";
import { AppointmentTargetType } from "../../../appointments/models/appointments.enums";
import { PatientAppointmentModel } from "../../../appointments/models/appointments.models";
import { durations } from "../../../appointments/models/times.models";
import { appointmentsService } from "../../../appointments/services/appointments.service";
import { appointmentsQuery } from "../../../appointments/stores/appointments";
import { PermissionType, Roles, UserType } from "../../../auth/models/auth.enums";
import { DataSpecificationsEnum } from "../../../common/constants/data-specifications";
import { onEmit } from "../../../common/helpers/on-emit";
import { Gender } from "../../../common/models/user.models";
import { IErrorState } from "../../../common/validation/error-state";
import { PatientModel } from "../../../patients/models/patient.model";
import { patientsService } from "../../../patients/services/patients.service";
import { patientsQuery } from "../../../patients/stores/patientsStore";
import { PatientAllergyModel } from "../../../patientSupplements/models/patientAllergiesModel";
import { medicationsService } from "../../../patientSupplements/services/medications.service";
import { patientAllergiesService } from "../../../patientSupplements/services/patientAllergiesService";
import { supplementsService } from "../../../patientSupplements/services/supplements.service";
import { CheckAnswer, HealthGoalsQuestionNames, QuestionnaireResultModel, QuestionnaireSubType } from "../../../questionnaire/models/questionnaire.models";
import { questionnaireService } from "../../../questionnaire/services/questionnaire.service";
import { DetailedQuestionNames } from "../../../questionnaire/static/detailedQuestionnaire.static";
import { MedicalHistoryAndLifestyleQuestionNames } from "../../../questionnaire/static/medicalHistroryQuestionnaire.static";
import {
    MedicationItem,
    SupplementItem,
    NoteModel,
    GoalModel,
    IcdCode,
    SaveNoteModel,
    NotesType,
    HistoryAndPhysicalContent,
    MdmMode,
    MdmPatientType,
    MdmModel,
    SignOffNoteModel,
    NotePmhVersion,
    HealthSummaryNoteKeySectionNames, NoteGoalsVersion, GoalCategory, SpecialTestModel, AppointmentConfigurationModel
} from "../../models/notes.models";
import { mdmCodesService } from "../../services/mdmCodes.service";
import { notesService, SignOffModel } from "../../services/notes.service";
import { NoteSections } from "./createHistoryAndPhysicalFollowUpNoteComponent.static";
import { isElementEnabled } from "../../../common/components/ProtectedElement";
import { icdCodesService } from "../../services/icdCodes.service";
import { snackService } from "../../../common/snack/state";
import { handleCompare } from "../../../common/sorting/helpers/handle-compare";
import { SortingDirection } from "../../../common/sorting/models/sorting-destination";
import { createHistoryAndPhysicalFollowUpNoteComponentValidator } from "./createHistoryAndPhysicalFollowUpNoteComponent.validator";
import { GeneralValidator } from "../../../common/validation/general-validator";
import { createSoapNoteComponentValidator } from "../createSoapComponent/createSoapNoteComponent.validator";
import { vitalsErrorMessages } from "../../../vital/validators/vital.validator";
import {
    createHistoryAndPhysicalNoteComponentValidator
} from "../createHistoryAndPhysicalComponent/createHistoryAndPhysicalNoteComponent.validator";
import { HealthSummaryDataModel, HealthSummaryDraftModel, HealthSummaryMapModel } from "../../../healthSummary/models/healthSummary.models";
import { buildKey, getKey } from "../../../healthSummary/helpers/healthSummaryHelper";
import { healthSummaryQuery } from "../../../healthSummary/stores";
import { healthSummaryService } from "../../../healthSummary/services/healthSummary.service";
import { authQuery } from "../../../auth/stores/auth";
import { goalsService } from "../../services/goals.service";
import { isProviderRole } from "../../../common/constants/roles";
import { inputsService } from "../../../inputs/services/inputs.service";
import { YesNo } from "../../../inputs/models/input.models";
import { ApoeSoursConstants } from "../../../healthReport/constants/apoe.constants";
import { mdmPlansService } from "../../services/mdmPlans.service";
import { commonSupplementsService } from "../../services/commonSupplements.service";
import { commonOrdersService } from "../../services/commonOrders.service";
import {FullscriptSupplementModel} from "../../../patientSupplements/models/fullscriptSupplementModels";
import {prescriptionService} from "../../../patientSupplements/services/prescription.service";

export interface HealthSummaryListItemsNoteComponentState {
    isEditorOpened: boolean
    isMenuOpened: boolean
    editRowValue: string
    selectedKey: string
    editRowName: string
    addNewRow: boolean
    anchorEl: any
    editSection: string
    currentMap: HealthSummaryMapModel
    draft: any[]
}
interface CreateHistoryAndPhysicalNoteComponentState extends IErrorState {
    sections: { [id: number]: boolean };
    map: HealthSummaryMapModel[];
    title: string;
    appointmentDate: Date;
    selectedTargetType: AppointmentTargetType;
    nextCoachAppointmentDate: Date;
    nextProviderAppointmentDate: Date;
    content: HistoryAndPhysicalContent;

    note: NoteModel;
    appointments: PatientAppointmentModel[];
    patient: PatientModel;
    allergies: PatientAllergyModel[];
    icdCodes: IcdCode[];
    mdmCodes: MdmModel[];
    rosVariants: string[];

    areAllergiesLoading: boolean;
    areQuestionnaireResultsLoading: boolean;
    isNoteContentLoading: boolean;
    isProcessing: boolean;
    isAutoSaving: boolean;
    isChanged: boolean;

    isFirstSaveOriginalNote: boolean;
    originalNote: NoteModel;
    isCommonMdmLoading: boolean;
    isCommonGoalLoading: boolean;
    isCommonSupplementLoading: boolean;
    isCommonOrderLoading: boolean;
}

let stateContext: CreateHistoryAndPhysicalNoteComponentState = null;

export function useFacade(
    patientId: number,
    handleGoBack: () => void,
    appointmentId: number | null,
    appointmentConfiguration: AppointmentConfigurationModel | null,
    note: NoteModel,
    originalNote: NoteModel
): [
        CreateHistoryAndPhysicalNoteComponentState,
        (section: NoteSections) => void,
        (value: string) => void,
        (value: Date) => void,
        (appointmentId: number) => void,
        (durationValue: number, type: AppointmentTargetType) => void,
        (value: string) => void,
        (key: string, values: string[]) => void,
        (medication: MedicationItem) => void,
        (supplement: SupplementItem) => void,
        (supplements: FullscriptSupplementModel[]) => void,
        (medication: MedicationItem) => void,
        (supplement: SupplementItem) => void,
        (id: number) => void,
        (id: number) => void,
        (id: number, isChecked: boolean) => void,
        (id: number, isChecked: boolean) => void,
        (value: boolean) => void,
        (values: string[]) => void,
        (value: boolean) => void,
        (field: string, value: number) => void,
        (value: string) => void,
        (value: string) => void,
        (value: MdmPatientType) => void,
        (id: number, showCodeDescription: boolean) => void,
        (ids: number[]) => void,
        (id: number, inputValue: string) => void,
        (mode: MdmMode) => void,
        (time: number) => void,
        (key: string, values: IcdCode[]) => void,
        (value: string) => void,
        (value: string) => void,
        (field: string, goals: GoalModel[]) => void,
        (tests: SpecialTestModel[]) => void,
        (field: string, educations: string) => void,
        (date: Date, role: Roles) => void,
        () => void,
        () => void,
        () => void,
        () => void,
        (searchQuery: string) => Observable<IcdCode[]>,
        Map<string, [MutableRefObject<any>, NoteSections]>,
        (value: string) => void,
        (value: string, id: number) => void,

        Function,
        Function,
        Function,
        Function,
        Function,
        Function,
        Function,
        Function,
        (searchQuery: string) => Observable<string[]>,
        Function,
        Function,
        Function,
        Function,
        Function,
        (goalName: string, id:number) => boolean,
        HealthSummaryListItemsNoteComponentState,
        Function,
        Function,
        Function,
        Function,
        Function,
    ] {
    const autoSaveTimer = useMemo(() => interval(5000), []);
    const [state, setState] = useState(
        {
            sections: {
                [NoteSections.PMH]: false,
                [NoteSections.MedicationsAndSupplements]: false,
                [NoteSections.ROS]: false,
                [NoteSections.Physical]: false,
                [NoteSections.MDM]: false,
                [NoteSections.Diagnosis]: false,
                [NoteSections.Plan]: false,
                [NoteSections.SpecialTests]: false,
                [NoteSections.NextAppointment]: false,
            },
            map: [],
            title: '',
            appointmentDate: appointmentConfiguration?.visitDate ?? new Date(),
            selectedTargetType: AppointmentTargetType.Patient,
            nextCoachAppointmentDate: null,
            nextProviderAppointmentDate: null,
            isFirstSaveOriginalNote: !!originalNote,
            originalNote: originalNote,

            content: {
                appointmentConfiguration: appointmentConfiguration,
                duration: appointmentConfiguration?.duration
                    ? durations.find(x => x.value === appointmentConfiguration?.duration)
                    : durations[0],
                pmhVersion: NotePmhVersion.New,
                chiefComplaint: '',
                HPI: '',
                PMH: {
                    allergies: [],
                    currentMedicalConditions: [],
                    famHX: [],
                    screeningHealthMaintenance: [],
                    surgicalHX: [],
                    PHQ2: [],
                    socialLifestyleHX: [],
                },
                PMHnew: {
                    allergies: [],
                    rxntAllergies: [],
                    currentMedicalConditions: [],
                    famHX: [],
                    screeningHealthMaintenance: [],
                    screeningHealthMaintenanceApoe: {
                        hideApoe: YesNo.None,
                        score: '',
                        source: ApoeSoursConstants.BloodTest,
                        colonoscopy:  {
                            isDeleted: false,
                            patientId: patientId,
                            isNew: true,
                            value: '',
                            name: '',
                            key: DetailedQuestionNames.DATE_OF_LAST_SCREENING,
                            id: null,
                            tooltip: '',
                        },
                        psa: {
                            isDeleted: false,
                            patientId: patientId,
                            isNew: true,
                            value: '',
                            name: '',
                            key: DetailedQuestionNames.DATE_OF_PSA_OR_PROSTATE_EXAM,
                            id: null,
                            tooltip: '',
                        },
                    },
                    surgicalHX: [],
                    PHQ2: [],
                    socialHX: [],
                    lifestyleHX: [],
                },
                medicationsSupplements: {
                    supplements: [],
                    medications: [],
                    rxntMedications: [],
                    fullscriptSupplements: []
                },
                ROS: {
                    rosEnabled: false,
                    rosValues: [],
                },
                physicalData: {
                    unableToObtain: false,
                    systolicBP: 0,
                    diastolicBP: 0,
                    heartRate: 0,
                    temperature: 0,
                    height: 0,
                    weight: 0,
                    physicalExam: ''
                },
                mdmData: {
                    mdmText: '',
                    selectedMdmPatientType: MdmPatientType.Established,
                    selectedCodeId: null,
                    showCodeDescription: null,
                    selectedCategoryId: null,
                    selectedCategoryIds: [],
                    selectedCategoryItems: [],
                    selectedMode: MdmMode.Mdm,
                    spentTime: 0,
                },
                diagnosis: [],
                plan: {
                    planText: '',
                    dietGoals: [],
                    exerciseGoals: [],
                    sleepGoals: [],
                    neuroGoals: [],
                    longevityGoals: [],
                    mindfulnessGoals: [],
                    labsGoals: [],
                    labsText: '',
                    coachOtherText: ''
                },
                specialTests: []
            },

            note: note,
            appointments: [],
            patient: null,
            allergies: [],
            icdCodes: [],
            mdmCodes: [],
            rosVariants: [],

            areAllergiesLoading: true,
            areQuestionnaireResultsLoading: true,
            isNoteContentLoading: true,
            isProcessing: false,
            isAutoSaving: false,
            isChanged: false,
            isCommonMdmLoading: true,
            isCommonGoalLoading: true,
            isCommonSupplementLoading: true,
            isCommonOrderLoading: true,

            errors: {}
        } as CreateHistoryAndPhysicalNoteComponentState
    );

    const [editPmhState, setEditPmhState] = useState({
        isEditorOpened: false,
        isMenuOpened: false,
        selectedKey: null,
        addNewRow: false,
        anchorEl: null,
        editSection: '',
        currentMap: null,
        draft: [],
    } as HealthSummaryListItemsNoteComponentState)

    const setInitialState = () => {
        setEditPmhState({
            ...editPmhState,
            isEditorOpened: false,
            selectedKey: null,
            addNewRow: false,
            editRowValue: '',
            editRowName: '',
            editSection: '',
            currentMap: null,
            draft: []
        })
    }

    stateContext = state;

    const refs = new Map<string, [MutableRefObject<any>, NoteSections]>();

    refs.set("ROS", [useRef(null), NoteSections.ROS]);
    refs.set("physicalData", [useRef(null), NoteSections.Physical]);
    refs.set("physicalExam", [refs.get("physicalData")[0], NoteSections.Physical]);
    refs.set("diastolicBP", [refs.get("physicalData")[0], NoteSections.Physical]);
    refs.set("systolicBP", [refs.get("physicalData")[0], NoteSections.Physical]);
    refs.set("mdmData", [useRef(null), NoteSections.MDM]);
    refs.set("diagnosis", [useRef(null), NoteSections.Diagnosis]);

    const handleToggleActions = (key: string, anchorEl: Element, section: string,) => {
        setEditPmhState(editPmhState => ({
            ...editPmhState,
            isMenuOpened: !editPmhState.isMenuOpened,
            anchorEl: anchorEl,
            selectedKey: key,
            editSection: section,
        }))
    }

    const createPmhAlergiesKey = (section, item) => {
        return `${section}-${item.id}`
    }

    const isCanCompleteNote = () => {
        if(!isProviderRole(authQuery.getRoleId())){
            return false;
        }

        if (!!originalNote) {
            return originalNote.completedById === authQuery.getEmployeeId();
        }

        if (stateContext?.note?.assignedTo) {
            return stateContext.note.assignedTo.id === authQuery.getEmployeeId()
        }

        if (stateContext?.note?.completedById) {
            return stateContext.note.completedById === authQuery.getEmployeeId()
        }

        return true;
    }

    const handleHideApoe = (val: YesNo) => {
        state.content.PMHnew.screeningHealthMaintenanceApoe = {
            ...state.content.PMHnew?.screeningHealthMaintenanceApoe,
            hideApoe: val
        }

        setState((state) => ({
            ...state,
            isChanged: true,
        }))
    }

    const handleSelectedSoursApoe = (val: string) => {
        state.content.PMHnew.screeningHealthMaintenanceApoe = {
            ...state.content.PMHnew?.screeningHealthMaintenanceApoe,
            source: val
        }

        setState((state) => ({
            ...state,
            isChanged: true,
        }))
    }

    const handleEditApoe = (value) => {
        state.content.PMHnew.screeningHealthMaintenanceApoe = {
            ...state.content.PMHnew?.screeningHealthMaintenanceApoe,
            score: value
        }

        setState((state) => ({
            ...state,
            isChanged: true,
        }))
    }

    const handleDateColonoscopyChanges = (val) => {
        state.content.PMHnew.screeningHealthMaintenanceApoe = {
            ...state.content.PMHnew?.screeningHealthMaintenanceApoe,
            colonoscopy: {
                ...state.content.PMHnew?.screeningHealthMaintenanceApoe?.colonoscopy,
                value: val
            }
        }

        setState((state) => ({
            ...state,
            isChanged: true,
        }))
    }

    const handleDatePsaChanges = (val) => {
        state.content.PMHnew.screeningHealthMaintenanceApoe = {
            ...state.content.PMHnew?.screeningHealthMaintenanceApoe,
            psa: {
                ...state.content.PMHnew?.screeningHealthMaintenanceApoe?.psa,
                value: val
            }
        }

        setState((state) => ({
            ...state,
            isChanged: true,
        }))
    }

    const handleEdit = () => {
        const dataItem = state.content.PMHnew[editPmhState.editSection].find(el => el.key === editPmhState.selectedKey)

        setEditPmhState({
            ...editPmhState,
            editRowName: dataItem.name,
            editRowValue: dataItem.value,
            isEditorOpened: true,
            isMenuOpened: false
        })
    }

    const handleDelete = () => {
        const newData = state.content.PMHnew[editPmhState.editSection].filter(el => el.key !== editPmhState.selectedKey)
        state.content.PMHnew[editPmhState.editSection] = newData
        setState((state) => ({
            ...state,
            isChanged: true,
        }))
        setEditPmhState({
            ...editPmhState,
            isMenuOpened: false,
            selectedKey: null,
            anchorEl: null,
            editSection: ''
        })

    }

    const handleEditRowName = (value: string) => {

        setEditPmhState({
            ...editPmhState,
            editRowName: value
        })
    }

    const handleEditRowValue = (value: string) => {
        setEditPmhState(editPmhState => ({
            ...editPmhState,
            editRowValue: value
        }))
    }

    const handleAddNewRow = (section: string, map: HealthSummaryMapModel) => {
        setEditPmhState({
            ...editPmhState,
            addNewRow: true,
            editSection: section,
            currentMap: map,
        })
    }

    const handleBlur = (e, value) => {
        if (value) {
            setEditPmhState(editPmhState => ({
                ...editPmhState,
                editRowValue: value
            }))
        }

        if (e?.currentTarget?.id.includes('list-edit') && e?.relatedTarget) {
            return
        }

        if (editPmhState.editRowName && editPmhState.editRowValue) {
            saveChanges({ name: editPmhState.editRowName, value: editPmhState.editRowValue })
            return
        }

        if (editPmhState.addNewRow && !editPmhState.editRowName && !editPmhState.editRowValue) {
            setInitialState()
            return
        }
    }


    const handleEditNewItem = (value: string) => {
        const draft = editPmhState.draft
        const item = editPmhState.draft.find(x => x.isNew)

        draft[draft.indexOf(draft.find(x => x.key === item.key))].value = value

        setEditPmhState({
            ...editPmhState,
            draft: draft
        })
    }

    const handleEditItem = (sectionOfNote, selectedKey, editValue, isDelete = false) => {
        if (isDelete) {
            const updateItems = state.content.PMHnew[sectionOfNote].filter(el => el.key !== selectedKey)
            state.content.PMHnew[sectionOfNote] = updateItems
        } else {
            const updateItems = state.content.PMHnew[sectionOfNote].map(el => {
                if (el.key === selectedKey) {
                    return {
                        ...el,
                        value: editValue,
                        isNew: false,
                    }
                }
                return el
            })
            state.content.PMHnew[sectionOfNote] = updateItems
        }

        setState((state) => ({
            ...state,
            isChanged: true,
        }))
    }

    const handleAddInformation = (subSection: string, sectionOfNote: string, map: HealthSummaryMapModel) => {
        const newDataItem = {
            name: '',
            value: '',
            key: buildKey(map.key, subSection),
            section: subSection,
            isNew: true,
            patientId: patientId
        } as HealthSummaryDraftModel

        setEditPmhState({
            ...editPmhState,
            draft: [...editPmhState.draft, newDataItem]
        })
    }

    const handleSaveNewItem = (sectionOfNote: string,) => {
        const newData = [...state.content.PMHnew[sectionOfNote], ...editPmhState.draft]
        state.content.PMHnew[sectionOfNote] = newData
        setState((state) => ({
            ...state,
            isChanged: true,
        }))
        setInitialState()
    }

    const parseValue = (value: string) => {
        if (!value) {
            return ''
        }

        const arr = value.split(' ')

        if (!arr || !arr.length) {
            return ''
        }

        return arr;
    }

    const saveValue = (value: string) => {
        const parsedValue = parseValue(value)

        if (!parsedValue) {
            if (!editPmhState.isEditorOpened) {
                setInitialState()
            }
            return
        }

        const editValue = parsedValue.length > 1 ? parsedValue[0] : ''
        const editName = parsedValue.length > 1 ? value.replace(editValue, '').trim() : value

        setEditPmhState(editPmhState => ({
            ...editPmhState,
            editRowValue: editValue,
            editRowName: editName
        }))

        const result = { name: editName, value: editValue }

        saveChanges(result)
    }

    const saveChanges = ({ name, value }) => {
        if (!name || !value) {
            setInitialState()
            return
        }

        const isNew = !editPmhState.selectedKey

        const model = {
            key: isNew ? buildKey(editPmhState.currentMap.key) : editPmhState.selectedKey,
            name: name ?? editPmhState.editRowName,
            value: value ?? editPmhState.editRowValue,
        } as HealthSummaryDataModel

        if (isNew) {
            const emptyModel = {
                isDeleted: false,
                patientId: patientId,
                isNew: false,
                id: Math.floor(Math.random() * 10000),
                tooltip: ''
            }
            const newData = [...state.content.PMHnew[editPmhState.editSection], { ...emptyModel, ...model }]
            state.content.PMHnew[editPmhState.editSection] = newData
            setState((state) => ({
                ...state,
                isChanged: true,
            }))

        } else {
            const updateItems = state.content.PMHnew[editPmhState.editSection].map(el => {
                if (el.key === editPmhState.selectedKey) {
                    return {
                        ...el,
                        ...model,
                    }
                }
                return el
            })
            state.content.PMHnew[editPmhState.editSection] = updateItems
            setState((state) => ({
                ...state,
                isChanged: true,
            }))
        }
        setInitialState()
    }


    const handleExpandSection = (section: NoteSections) => {
        const sections = state.sections;
        sections[section] = !sections[section];
        setState(state => ({ ...state, sections: sections }));
    }

    const handleTitleChanges = (value: string) => {
        setState(state => ({ ...state, title: value, isChanged: true }));
    }

    const handleAppointmentDateChanges = (value: Date) => {
        setState(state => ({ ...state, appointmentDate: value, isChanged: true }));
    }

    const handleAppointmentChanges = (appointmentId: number) => {
        if (state.note?.appointment?.id === appointmentId) {
            return;
        }

        const model = getSaveModel(state);

        model.appointmentId = appointmentId;

        notesService.saveAsDraft(model, patientsQuery.getTargetPatientIsPremium()).subscribe(
            (note) => {
                setState(state => ({
                    ...state,
                    note: note
                }))
            },
            () => {
                snackService.error('Note already exists for this appointment.');
            }
        );
    }

    const getTargetDurations = (type: AppointmentTargetType) => {
        const patientMode = type === AppointmentTargetType.Patient;

        return durations.filter(x => x.patientMode === patientMode);
    }

    const handleChangeDuration = (durationValue: number, type: AppointmentTargetType) => {
        const selectedDuration = getTargetDurations(type).find(x => x.value === durationValue);
        const content = state.content;
        content.duration = selectedDuration;
        setState(state => ({ ...state, content: content, isChanged: true }));
    }

    const handleHpiChanges = (value: string) => {
        setState(state => ({
            ...state,
            content: {
                ...state.content,
                HPI: value
            },
            isChanged: true
        }));
    }

    const handlePmhItemChanges = (key: string, values: string[]) => {
        state.content.PMH[key] = values;
        setState(state => ({ ...state, isChanged: true }))
    }

    const handleAddMedications = (medication: MedicationItem) => {
        medication.id = Date.now();
        state.content.medicationsSupplements.medications.push(medication);
        setState(state => ({ ...state, isChanged: true }));
    }

    const handleAddSupplements = (supplement: SupplementItem) => {
        state.content.medicationsSupplements.supplements.push({
            ...supplement,
            id: Date.now() + Math.floor(Math.random() * 100),
        });
        setState(state => ({ ...state, isChanged: true }));
    }

    const handleFullscriptSupplements = (supplements: FullscriptSupplementModel[]) => {
        // init array if it's null. required for notes compatibility
        // which created before feature released
        if (!state.content.medicationsSupplements.fullscriptSupplements) {
            state.content.medicationsSupplements.fullscriptSupplements = [];
        }
        state.content.medicationsSupplements.fullscriptSupplements.push(...supplements);
        setState(state => ({ ...state, isChanged: true }));
    }

    const handleEditMedications = (medication: MedicationItem) => {
        const item = state.content.medicationsSupplements.medications.find(i => i.id === medication.id);
        if (item) {
            item.name = medication.name;
            item.dosage = medication.dosage;
            item.instructions = medication.instructions;
            item.startDate = medication.startDate;
            setState(state => ({ ...state, isChanged: true }));
        }
    }

    const handleEditSupplements = (supplement: SupplementItem) => {
        const item = state.content.medicationsSupplements.supplements.find(i => i.id === supplement.id);
        if (item) {
            item.name = supplement.name;
            item.dosage = supplement.dosage;
            item.instructions = supplement.instructions;
            item.purchaseLink = supplement.purchaseLink;
            setState(state => ({ ...state, isChanged: true }));
        }
    }

    const handleRemoveMedications = (id: number) => {
        state.content.medicationsSupplements.medications = state.content.medicationsSupplements.medications.filter(i => i.id !== id);
        setState(state => ({ ...state, isChanged: true }));
    }

    const handleRemoveSupplements = (id: number) => {
        state.content.medicationsSupplements.supplements = state.content.medicationsSupplements.supplements.filter(i => i.id !== id);
        setState(state => ({ ...state, isChanged: true }));
    }

    const handleMedicationStopped = (id: number, isChecked: boolean) => {
        const item = state.content.medicationsSupplements.medications.find(i => i.id === id);
        if (item) {
            item.isStopped = isChecked;
            setState(state => ({ ...state, isChanged: true }));
        }
    };

    const handleSupplementStopped = (id: number, isChecked: boolean) => {
        const item = state.content.medicationsSupplements.supplements.find(i => i.id === id);
        if (item) {
            item.isStopped = isChecked;
            setState(state => ({ ...state, isChanged: true }));
        }
    };

    const handleEnableRos = (value: boolean) => {
        state.content.ROS.rosEnabled = value;
        setState(state => ({ ...state, isChanged: true }))

        createHistoryAndPhysicalNoteComponentValidator.validateAndSetState(state, setState, 'ROS', state.content.ROS);
    }

    const handleRosChanges = (values: string[]) => {
        state.content.ROS.rosValues = values;
        setState(state => ({ ...state, isChanged: true }))

        createHistoryAndPhysicalNoteComponentValidator.validateAndSetState(state, setState, 'ROS', state.content.ROS);
    }

    const validateVitals = () => {
        createSoapNoteComponentValidator.validateAndSetState(state, setState, 'physicalData', state.content.physicalData);
        createSoapNoteComponentValidator.validateObjectAndSetState(state, setState, state.content.physicalData);
        const systolicValue = state.content.physicalData.systolicBP;
        const diastolicValue = state.content.physicalData.diastolicBP;
        let errors = state.errors;
        if ((systolicValue || diastolicValue) && diastolicValue >= systolicValue) {
            errors = GeneralValidator.addError(state, `diastolicBP`, vitalsErrorMessages.diastolicIsGraterThanSystolic);
        } else if (systolicValue && !diastolicValue) {
            errors = GeneralValidator.addError(state, `diastolicBP`, vitalsErrorMessages.systolicAndDiastolicNotFiled);
        } else if (diastolicValue && !systolicValue) {
            errors = GeneralValidator.addError(state, `systolicBP`, vitalsErrorMessages.systolicAndDiastolicNotFiled);
        }

        setState(state => ({
            ...state,
            errors: errors
        }));
    }

    const validateNote = (): boolean => {
        createSoapNoteComponentValidator.validateObjectAndSetState(state, setState, state.content);

        validateVitals();

        if (state.content.physicalData.unableToObtain) {
            GeneralValidator.removeError(state, 'physicalData');
            GeneralValidator.removeError(state, 'systolicBP');
            GeneralValidator.removeError(state, 'diastolicBP');
            GeneralValidator.removeError(state, 'heartRate');
            GeneralValidator.removeError(state, 'temperature');
            GeneralValidator.removeError(state, 'height');
            GeneralValidator.removeError(state, 'weight');
        }

        return GeneralValidator.isValidState(state);
    }

    const handleDisableVitals = (value: boolean) => {
        state.content.physicalData.unableToObtain = value;
        setState(state => ({ ...state, isChanged: true }));
        if (value) {
            GeneralValidator.removeError(state, 'systolicBP');
            GeneralValidator.removeError(state, 'diastolicBP');
            GeneralValidator.removeError(state, 'heartRate');
            GeneralValidator.removeError(state, 'temperature');
            GeneralValidator.removeError(state, 'height');
            GeneralValidator.removeError(state, 'weight');
            GeneralValidator.removeError(state, 'physicalData');
        }
    }

    const handleVitalsChanges = (field: string, value: number) => {
        state.content.physicalData[field] = value;
        setState(state => ({ ...state, isChanged: true }));
        validateVitals();
    }

    const handlePhysicalExamChanges = (value: string) => {
        state.content.physicalData.physicalExam = value;
        setState(state => ({ ...state, isChanged: true }));
        createSoapNoteComponentValidator.validateAndSetState(state, setState, 'physicalExam', value);
    }

    const handleMdmTextChange = (value: string) => {
        state.content.mdmData.mdmText = value;
        setState(state => ({ ...state, isChanged: true }));
    }

    const handleMdmPatientTypeChange = (value: MdmPatientType) => {
        state.content.mdmData.selectedMdmPatientType = value;
        state.content.mdmData.selectedCodeId = null;
        state.content.mdmData.selectedCategoryIds = [];
        state.content.mdmData.selectedCategoryItems = [];
        state.content.mdmData.spentTime = 0;
        setState(state => ({ ...state, isChanged: true }));
        createHistoryAndPhysicalFollowUpNoteComponentValidator.validateAndSetState(state, setState, 'mdmData', state.content.mdmData);
    }

    const handleCodeChange = (id: number, showCodeDescription: boolean) => {
        if (state.content.mdmData.selectedCodeId !== id) {
            state.content.mdmData.selectedCodeId = id;
            state.content.mdmData.selectedCategoryIds = [];
            state.content.mdmData.selectedCategoryItems = [];
            state.content.mdmData.spentTime = 0;
            createHistoryAndPhysicalNoteComponentValidator.validateAndSetState(state, setState, 'mdmData', state.content.mdmData);
        }

        state.content.mdmData.showCodeDescription = showCodeDescription;

        setState(state => ({ ...state }));
    }

    const handleCategoryChange = (ids: number[]) => {
        const mdmCode = state.mdmCodes.find(x => x.id === state.content.mdmData.selectedCodeId);
        const removedCategories = mdmCode.categories.filter(x => !ids.some(t => t === x.id));
        const removedItems = removedCategories.map(x => x.items).flat();
        const items = state.content.mdmData.selectedCategoryItems.filter(x => !removedItems.some(t => t.id === x.id));
        state.content.mdmData.selectedCategoryIds = ids;
        state.content.mdmData.selectedCategoryItems = items;
        setState(state => ({ ...state, isChanged: true }));
        createSoapNoteComponentValidator.validateAndSetState(state, setState, 'mdmData', state.content.mdmData);
    }

    const handleCategoryItemChange = (id: number, inputValue: string) => {
        const item = state.content.mdmData.selectedCategoryItems.find(x => x.id === id)
        if (item !== undefined) {
            if (inputValue === null) {
                state.content.mdmData.selectedCategoryItems = state.content.mdmData.selectedCategoryItems.filter(x => x.id !== id);
            }
            else {
                item.inputValue = inputValue;
            }
        }
        else {
            state.content.mdmData.selectedCategoryItems.push({
                id: id,
                inputValue: inputValue
            })
        }

        setState(state => ({ ...state, isChanged: true }));
        createHistoryAndPhysicalFollowUpNoteComponentValidator.validateAndSetState(state, setState, 'mdmData', state.content.mdmData);
    }

    const handleModeChange = (mode: MdmMode) => {
        switch (mode) {
            case MdmMode.Mdm: {
                state.content.mdmData.spentTime = 0;
            }
            case MdmMode.TimeBased: {
                state.content.mdmData.selectedCategoryIds = [];
                state.content.mdmData.selectedCategoryItems = [];
            }
        }

        state.content.mdmData.selectedMode = mode;
        setState(state => ({ ...state, isChanged: true }));
        createHistoryAndPhysicalFollowUpNoteComponentValidator.validateAndSetState(state, setState, 'mdmData', state.content.mdmData);
    }

    const handleSpentTimeChanges = (time: number) => {
        state.content.mdmData.spentTime = time;
        setState(state => ({ ...state, isChanged: true }));
        createHistoryAndPhysicalFollowUpNoteComponentValidator.validateAndSetState(state, setState, 'mdmData', state.content.mdmData);
    }

    const handleDiagnosisChanges = (key: string, values: IcdCode[]) => {
        state.content.diagnosis = values;
        setState(state => ({ ...state, isChanged: true }));
        createHistoryAndPhysicalFollowUpNoteComponentValidator.validateAndSetState(state, setState, 'diagnosis', state.content.diagnosis);
    }

    const handleTextInfoChange = (value: string, id: number) => {
        const diagnosisForAddText = state.content.diagnosis.find(el => el.id === id)
        diagnosisForAddText.additionalInformation = value;
        setState(state => ({ ...state, isChanged: true }));
    }

    const handlePlanTextChanges = (value: string) => {
        state.content.plan.planText = value;
        setState(state => ({ ...state, isChanged: true }));
    }

    const handleLabTextChanges = (value: string) => {
        state.content.plan.labsText = value;
        setState(state => ({ ...state, isChanged: true }));
    }

    const handleCoachOtherTextChanges = (value: string) => {
        state.content.plan.coachOtherText = value;
        setState(state => ({ ...state, isChanged: true }));
    }

    const handleGoalsChanges = (field: string, goals: GoalModel[]) => {
        state.content.plan[field] = goals;
        setState(state => ({ ...state, isChanged: true }));
    }

    const handleTestChanges = (tests: SpecialTestModel[]) => {
        const content = state.content;
        content.specialTests = tests;
        setState(state => ({
            ...state,
            content: content
        }))
    }

    const handleEducationChanges = (field: string, educations: string) => {
        state.content.plan[field] = educations;
        setState(state => ({ ...state, isChanged: true }));
    }

    const handleNextAppointmentDateChanges = (date: Date, role: Roles) => {
        switch (role) {
            case Roles.Coach:
                setState(state => ({ ...state, nextCoachAppointmentDate: date, isChanged: true })); break;
            case Roles.Provider:
                setState(state => ({ ...state, nextProviderAppointmentDate: date, isChanged: true })); break;
        }
    }

    const getAnswer = (result: QuestionnaireResultModel, questionKey: string): string => result?.answers?.find(x => x.key === questionKey)?.value;

    const getAnswerVariants = (result: QuestionnaireResultModel, questionKey: string): string[] => {
        const answer = getAnswer(result, questionKey);
        if (!answer) {
            return [];
        }

        const parsedAnswer: CheckAnswer = JSON.parse(answer);

        if (!parsedAnswer) {
            return [];
        }

        return [...parsedAnswer.v, ...parsedAnswer.o];
    };

    const prefillHpi = (
        medicalHistoryQuestionnaire: QuestionnaireResultModel,
        goalsQuestionnaire: QuestionnaireResultModel,
        patient: PatientModel) => {

        if (medicalHistoryQuestionnaire === undefined || goalsQuestionnaire === undefined || patient === null) {
            return;
        }

        const firstName = patient.firstName;
        const age = moment().diff(patient.birthday, 'years');
        const medicalConditions = getAnswerVariants(medicalHistoryQuestionnaire, MedicalHistoryAndLifestyleQuestionNames.MEDICAL_HISTORY_CONDITIONS).join(', ');
        const intention = getAnswer(goalsQuestionnaire, HealthGoalsQuestionNames.YOUR_INTENTION);
        const exerciseGoals = getAnswer(goalsQuestionnaire, HealthGoalsQuestionNames.PERSONAL_EXERCISE_GOAL);
        const nutritionGoals = getAnswer(goalsQuestionnaire, HealthGoalsQuestionNames.PERSONAL_NUTRITION_GOAL);
        const sleepGoals = getAnswer(goalsQuestionnaire, HealthGoalsQuestionNames.PERSONAL_SLEEP_GOAL);
        const mindfulnesGoals = getAnswer(goalsQuestionnaire, HealthGoalsQuestionNames.PERSONAL_MINDFULNESS_GOAL);
        const socialConnectionGoals = getAnswer(goalsQuestionnaire, HealthGoalsQuestionNames.PERSONAL_SOCIAL_CONNECTION_GOAL);

        const prefillText =
            `${firstName} is a ${age} y/o ${Gender[patient.gender]} with ${medicalConditions} who presents to establish care, discuss chronic disease management/prevention, and longevity. ` +
            `They have the specific intention: “${intention}”. \n` +
            `The patient has the following goals: \n` +
            `Exercise goal: “${exerciseGoals}“\n` +
            `Nutrition goal: “${nutritionGoals}“\n` +
            `Sleep goal: “${sleepGoals}“\n` +
            `Mindfulness goal: “${mindfulnesGoals}“\n` +
            `Social connection goal: “${socialConnectionGoals}“\n`;


        setState((state) => ({
            ...state,
            isChanged: true,
            content: {
                ...state.content,
                HPI: prefillText
            }
        }));
    }

    // Saving
    const getSaveModel = (state: CreateHistoryAndPhysicalNoteComponentState): SaveNoteModel => {
        return {
            id: state.note?.id,
            name: 'History And Physical Follow-Up',
            title: state.title,
            type: NotesType.HistoryAndPhysicalFollowUp,
            visitDate: state.appointmentDate,
            patientId: patientId,
            internalContent: null,
            content: JSON.stringify(state.content),
            logs: [],
            nextCoachAppointmentDate: state.nextCoachAppointmentDate,
            nextProviderAppointmentDate: state.nextProviderAppointmentDate,
            appointmentId: state.note ? state.note.appointment?.id : appointmentId,
            version: state.note?.version,
            originalNoteId: state.originalNote?.id
        } as SaveNoteModel;
    }

    const getSaveModelFromOriginalNote = (content): SaveNoteModel => {
        return {
            id: null,
            name: 'History And Physical Follow-Up',
            title: state.originalNote.title,
            type: NotesType.HistoryAndPhysicalFollowUp,
            visitDate: state.appointmentDate,
            patientId: patientId,
            internalContent: null,
            content: JSON.stringify(content),
            logs: [],
            nextCoachAppointmentDate: state.nextCoachAppointmentDate,
            nextProviderAppointmentDate: state.nextProviderAppointmentDate,
            version: state.originalNote?.version,
            originalNoteId: state.originalNote?.id
        } as SaveNoteModel;
    }

    const handleSaveAndComplete = () => {
        if (!validateNote()) {
            for (const elem of refs) {
                if (state.errors[elem[0]] !== undefined) {
                    const sections = state.sections;

                    if (!sections[elem[1][1]]) {
                        sections[elem[1][1]] = true;
                    }

                    setState(state => ({ ...state, sections: sections }));

                    setTimeout(() => { elem[1][0].current.scrollIntoView({ block: "center", behavior: "smooth" }) }, 0)

                    break;
                }
            }
            return;
        }

        confirmService.confirm('Are you sure? This action can not be undone').subscribe(() => {
            setState(state => ({
                ...state,
                isChanged: false,
                isProcessing: true
            }));

            notesService.saveAsCompleted(getSaveModel(state)).subscribe(
                () => {
                    handleGoBack()
                },
                () => {
                    setState(state => ({
                        ...state,
                        isProcessing: false
                    }));
                })
        });
    }

    const handleSaveAsDraft = () => {
        if (state.isAutoSaving) {
            setTimeout(() => {
                setState(state => ({
                    ...state,
                    isChanged: false,
                    isProcessing: true
                }));

                notesService.saveAsDraft(getSaveModel(state), patientsQuery.getTargetPatientIsPremium()).subscribe(
                    () => {
                        handleGoBack()
                    },
                    (error) => {
                        snackService.commonErrorHandler(error);
                        setState(state => ({
                            ...state,
                            isProcessing: false
                        }));
                    }
                );
            }, 2000)
            return;
        }

        setState(state => ({
            ...state,
            isChanged: false,
            isProcessing: true
        }));

        notesService.saveAsDraft(getSaveModel(state), patientsQuery.getTargetPatientIsPremium()).subscribe(
            () => {
                handleGoBack()
            },
            (error) => {
                snackService.commonErrorHandler(error);
                setState(state => ({
                    ...state,
                    isProcessing: false
                }));
            }
        );
    }

    const handleValidateGoals = (goalName: string, id: number) =>{
        return false;
    }

    const handleSignOff = () => {
        notesService.openSignOff({
          patientId: state.note.patientId,
            noteType: state.note.type
        } as SignOffModel).subscribe(([employeeId, additionalNote]) => {
            const model: SignOffNoteModel = {
                noteId: stateContext.note.id,
                assignToId: employeeId,
                additionalNote: additionalNote
            };

            setState(state => ({
                ...state,
                isProcessing: true
            }));

            notesService.signOff(model).subscribe(
                () => {
                    handleSaveAsDraft();
                    handleGoBack();
                },
                () => {
                    setState(state => ({
                        ...state,
                        isProcessing: false
                    }))
                }
            );
        });
    }

    const autoSave = () => {
        if (stateContext.isChanged
            && !stateContext.isAutoSaving
            && !stateContext.isProcessing
            && !state.isFirstSaveOriginalNote
            && isElementEnabled([PermissionType.Coaching, PermissionType.ManagePatients], UserType.Employee)) {

            setState(state => ({
                ...state,
                isAutoSaving: true
            }));

            notesService.saveAsDraft(getSaveModel(stateContext), patientsQuery.getTargetPatientIsPremium()).subscribe(
                (note) => {
                    setState(state => ({
                        ...state,
                        note: {
                            ...state.note,
                            id: state?.note?.id ?? note.id,
                            title: note.title,
                            visitDate: note.visitDate,
                            nextCoachAppointmentDate: note.nextCoachAppointmentDate,
                            nextProviderAppointmentDate: note.nextProviderAppointmentDate,
                        },
                        isChanged: false,
                        isAutoSaving: false,
                    }));
                },
                () => {
                    setState(state => ({
                        ...state,
                        isAutoSaving: false,
                    }));
                });
        }
    }


    const handleDiscard = () => {
        confirmService.confirm('Are you sure? This action can not be undone').subscribe(() => {
            discard();
        });
    }

    const discard = () => {
        if (stateContext.isAutoSaving) {
            setTimeout(() => discard(), 50);
            return;
        }

        if (!state.note) {
            return handleGoBack();
        }

        setState(state => ({
            ...state,
            isChanged: false,
            isProcessing: true,
        }));

        notesService.delete(state.note).subscribe(() => handleGoBack());
    }

    const setNoteData = (note: NoteModel) => {
        setState(state => ({
            ...state,
            title: note.title,
            appointmentDate: note.visitDate,
            nextCoachAppointmentDate: note.nextCoachAppointmentDate,
            nextProviderAppointmentDate: note.nextProviderAppointmentDate
        }));

        notesService.getContentByEmployee(note.id, patientId).subscribe(result => {
            const content = JSON.parse(result.content) as HistoryAndPhysicalContent
            setState(state => ({
                ...state,
                content: content,
                isNoteContentLoading: false
            }));
        })
    }

    const getIcdCodesAutoComplete = (searchQuery: string): Observable<IcdCode[]> => {
        return new Observable<IcdCode[]>((observer) => {
            icdCodesService.get(searchQuery).subscribe(
                (data) => {
                    observer.next(data);
                    observer.complete();
                },
                error => {
                    observer.next([]);
                    observer.complete();
                }
            )
        })
    }

    const getIcdCodesAutoPmhComplete = (searchQuery: string): Observable<string[]> => {
        return new Observable<string[]>((observer) => {
            icdCodesService.get(searchQuery).subscribe(
                (data) => {
                    observer.next(data.map((x) => x.code + ' ' + x.description));
                    observer.complete();
                },
                error => {
                    observer.next([]);
                    observer.complete();
                }
            )
        })
    }

    const getSectionData = (section: HealthSummaryMapModel, data: HealthSummaryDataModel[]) => {
        var keys = [...section.items.map(x => x.key), section.key]

        return data.filter(x => keys.includes(getKey(x.key)))
    }

    const getHpiFromQuestionnaireResults = () => {
        questionnaireService.getLatestHealthFormsResults(patientId).subscribe(questionnaireResults => {
            if (questionnaireResults.length) {
                const results = questionnaireResults
                    .filter(x => x.submittedAt)
                    .sort((r1, r2) => {
                        return new Date(r1.submittedAt).getTime() - new Date(r2.submittedAt).getTime();
                    });

                const medicalHistoryQuestionnaire = results?.filter(q => q.questionnaire.subType === QuestionnaireSubType.MedicalHistoryIncomplete)?.pop();
                const goalsQuestionnaire = results?.filter(q => q.questionnaire.subType === QuestionnaireSubType.GoalsIncomplete)?.pop();

                prefillHpi(medicalHistoryQuestionnaire, goalsQuestionnaire, state.patient);
            }

            setState((state) => ({ ...state, areQuestionnaireResultsLoading: false }))
        });
    }

    const getPmhAndHpiData = (map: HealthSummaryMapModel[]) => {
        healthSummaryService.getData(patientId).subscribe(data => {

            const hpiData = data?.find(x => x.key === HealthSummaryNoteKeySectionNames.HPI)?.value;

            if (hpiData && hpiData.length) {
                setState((state) => ({
                    ...state,
                    isChanged: true,
                    areQuestionnaireResultsLoading: false,
                    content: {
                        ...state.content,
                        HPI: hpiData
                    }
                }));
            } else {
                getHpiFromQuestionnaireResults()
            }

            patientAllergiesService.getByPatientId(patientId).subscribe((allergies) => {
                prescriptionService.getPatientAllergies(patientId).subscribe(rxntAllergies => {
                    inputsService.getApoe(patientId).subscribe(
                        (apoeInfo) => {
                            if (data.length || allergies.length) {
                                setState((state) => ({
                                    ...state,
                                    isChanged: true,
                                    areAllergiesLoading: false,
                                    content: {
                                        ...state.content,
                                        PMHnew: {
                                            ...state.content.PMHnew,
                                            rxntAllergies: rxntAllergies,
                                            allergies: allergies.map(x => ({
                                                key: createPmhAlergiesKey(HealthSummaryNoteKeySectionNames.ALLERGIES, x),
                                                name: x.name,
                                                value: x.reaction,
                                                isDeleted: false,
                                                patientId: patientId,
                                                isNew: false,
                                                id: x.id,
                                                tooltip: ''
                                            })),
                                            currentMedicalConditions: getSectionData(map.find(el => el.key === HealthSummaryNoteKeySectionNames.PROBLEMS_LIST), data),
                                            famHX: getSectionData(map.find(el => el.key === HealthSummaryNoteKeySectionNames.FAMILY_HISTORY), data),
                                            surgicalHX: getSectionData(map.find(el => el.key === HealthSummaryNoteKeySectionNames.SURGICAL_HISTORY), data),
                                            socialHX: getSectionData(map.find(el => el.key === HealthSummaryNoteKeySectionNames.SOCIAL_HISTORY), data),
                                            lifestyleHX: getSectionData(map.find(el => el.key === HealthSummaryNoteKeySectionNames.LIFESTYLE_HISTORY), data),
                                            screeningHealthMaintenance: getSectionData(map.find(el => el.key === HealthSummaryNoteKeySectionNames.SCREENING_HEALTH_MAINTENANCE), data),
                                            screeningHealthMaintenanceApoe: {
                                                ...apoeInfo,
                                                source: apoeInfo.source ?? ApoeSoursConstants.BloodTest
                                            },
                                        }
                                    }
                                }))
                            }
                        }
                    )
                })
            });
        })
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            autoSaveTimer.subscribe(() => {
                autoSave()
            }),
            onEmit<PatientAppointmentModel[]>(appointmentsQuery.patientAppointments$, appointments => {
                if (appointments.length) {
                    const appointment = appointments.find(x => x.id === appointmentId);
                    if (appointment) {
                        setState(state => ({
                            ...state,
                            appointments: appointments,
                            appointmentDate: appointment.startDate,
                            content: {
                                ...state.content,
                                duration: durations.find(el => el.value === appointment.duration)
                            }
                        }));
                    }
                    else {
                        setState(state => ({
                            ...state,
                            appointments: appointments
                        }));
                    }
                }
            }),
            onEmit<PatientModel>(patientsQuery.targetPatient$, targetPatient => {
                if (targetPatient?.id === Number(patientId)) {
                    state.patient = targetPatient;
                    setState(state => ({
                        ...state,
                        patient: targetPatient
                    }));
                }
            }),
            onEmit<HealthSummaryMapModel[]>(healthSummaryQuery.map$, map => {
                setState(state => ({
                    ...state,
                    map: map
                }))

                if (map && map.length && !stateContext.note) {
                    getPmhAndHpiData(map)
                }
            }),
        ];

        const commonGoalCB = () => setState(state => ({ ...state, isCommonGoalLoading: false }));

        const commonMDMCB = () => setState(state => ({ ...state, isCommonMdmLoading: false }));

        const commonSupplementCB = () => setState(state => ({ ...state, isCommonSupplementLoading: false }));

        const commonOrderCB = () => setState(state => ({ ...state, isCommonOrderLoading: false }));
        
        mdmPlansService.getAllCommonMdms().subscribe(commonMDMCB, commonMDMCB);
    
        goalsService.getAllCommonGoals().subscribe(commonGoalCB, commonGoalCB);
    
        commonSupplementsService.getAllCommonSupplements().subscribe(commonSupplementCB, commonSupplementCB);
    
        commonOrdersService.getAllCommonOrders().subscribe(commonOrderCB, commonOrderCB);

        mdmCodesService.get(NotesType.HistoryAndPhysicalFollowUp).subscribe(
            codes => {
                setState(state => ({
                    ...state,
                    mdmCodes: codes,
                }))
            }
        );

        if (stateContext.note && !originalNote) {
            setState((state) => ({
                ...state,
                isNoteContentLoading: true,
                areAllergiesLoading: false,
                areQuestionnaireResultsLoading: false
            }))
            setNoteData(stateContext.note);
        }
        else {
            setState((state) => ({
                ...state,
                isAutoSaving: true,
                isNoteContentLoading: false,
                areAllergiesLoading: true,
                areQuestionnaireResultsLoading: true
            }));

            if (originalNote) {
                notesService.getContentByEmployee(originalNote.id, patientId).subscribe(result => {
                    const content = JSON.parse(result.content) as HistoryAndPhysicalContent
                    setState(state => ({
                        ...state,
                        isNoteContentLoading: false,
                        content: content,
                        title: originalNote.title
                    }));

                    notesService.saveAsDraft(getSaveModelFromOriginalNote(content), patientsQuery.getTargetPatientIsPremium()).subscribe(
                        (note) => {
                            setState(state => ({
                                ...state,
                                note: note,
                                isAutoSaving: false,
                                isFirstSaveOriginalNote: false
                            }))
                        },
                        () => {
                            snackService.error('Note already exists for this appointment.');
                        }
                    );
                });
            }

            else {
                notesService.saveAsDraft(getSaveModel(state), patientsQuery.getTargetPatientIsPremium()).subscribe(
                    (note) => {
                        setState(state => ({
                            ...state,
                            note: note,
                            isAutoSaving: false
                        }))
                    },
                    () => {
                        snackService.error('Note already exists for this appointment.');
                    }
                );
            }

            medicationsService.get(patientId).subscribe((medications) => {
                setState(state => ({
                    ...state,
                    isChanged: true,
                    content: {
                        ...state.content,
                        medicationsSupplements: {
                            ...state.content.medicationsSupplements,
                            medications: medications.map(x => {
                                return {
                                    id: x.id,
                                    name: x.name,
                                    dosage: x.dosage,
                                    instructions: x.instructions,
                                    startDate: x.startDate,
                                    isInCurrent: true,
                                    isStopped: false,
                                    source: null
                                }
                            })
                        }
                    }
                }))
            });

            prescriptionService.getPatientActiveMeds(patientId).subscribe((medications) => {
                setState(state => ({
                    ...state,
                    isChanged: true,
                    content: {
                        ...state.content,
                        medicationsSupplements: {
                            ...state.content.medicationsSupplements,
                            rxntMedications: medications.map(x => {
                                return {
                                    ...x,
                                    isInCurrent: true,
                                    isStopped: false
                                }
                            })
                        }
                    }
                }))
            });

            supplementsService.get(patientId).subscribe((supplements) => {
                setState(state => ({
                    ...state,
                    isChanged: true,
                    content: {
                        ...state.content,
                        medicationsSupplements: {
                            ...state.content.medicationsSupplements,
                            supplements: supplements.map(x => {
                                return {
                                    id: x.id,
                                    name: x.name,
                                    dosage: x.dosage,
                                    purchaseLink: x.purchaseLink,
                                    instructions: x.instructions,
                                    source: x.source,
                                    isInCurrent: true,
                                    isStopped: false
                                }
                            })
                        }
                    }
                }))
            });

            goalsService.getCurrentGoalsByEmployee(patientId).subscribe(
                (goals) => {
                    setState((state) => ({
                        ...state,
                        isPrefilled: true,
                        isChanged: true,
                        content: {
                            ...state.content,
                            goalsVersion: NoteGoalsVersion.New,
                            plan: {
                                ...state.content.plan,
                                mindfulnessGoals: goals.filter(x => x.category === GoalCategory.Mindfulness),
                                longevityGoals: goals.filter(x => x.category === GoalCategory.Longevity),
                                exerciseGoals: goals.filter(x => x.category === GoalCategory.Exercise),
                                sleepGoals: goals.filter(x => x.category === GoalCategory.Sleep),
                                neuroGoals: goals.filter(x => x.category === GoalCategory.Neuro),
                                dietGoals: goals.filter(x => x.category === GoalCategory.Diet)
                            }
                        }
                    }))
                }
            );

            notesService.getByEmployee(patientId).subscribe((notes) => {
                const noteType = [
                    NotesType.HistoryAndPhysicalFollowUp,
                    NotesType.HistoryAndPhysicalInitial,
                    NotesType.HistoryAndPhysicalGroupVisit,
                    NotesType.FollowUp
                ]

                const lastNote = notes
                    .filter(x => noteType.includes(x.type))
                    .filter(x => x.isCompleted)
                    .sort((a1, a2) => handleCompare(a1, a2, SortingDirection.Asc, 'visitDate'))
                    .find(x => true);

                if (lastNote) {
                    notesService.getContentByEmployee(lastNote.id, patientId).subscribe((content) => {
                        const internalContent: HistoryAndPhysicalContent = JSON.parse(content.content);
                        setState((state) => ({
                            ...state,
                            isChanged: true,
                            content: {
                                ...state.content,
                                plan: {
                                    ...state.content.plan,
                                    longevityEducations: internalContent?.plan?.longevityEducations ?? state.content.plan.longevityEducations,
                                    exerciseEducations: internalContent?.plan?.exerciseEducations ?? state.content.plan.exerciseEducations,
                                    sleepEducations: internalContent?.plan?.sleepEducations ?? state.content.plan.sleepEducations,
                                    neuroEducations: internalContent?.plan?.neuroEducations ?? state.content.plan.neuroEducations,
                                    dietEducations: internalContent?.plan?.dietEducations ?? state.content.plan.dietEducations,
                                    mindfulnessEducations: internalContent?.plan?.mindfulnessEducations ?? state.content.plan.mindfulnessEducations
                                }
                            }
                        }))
                    })
                } else {

                }
            });

            patientsService.get(patientId, DataSpecificationsEnum.UpdatePatientSpecification);
        }

        healthSummaryService.getMap()
        appointmentsService.getPatientAppointmentsById(patientId, new Date()).subscribe();

        return () => {
            subscriptions.map(i => i.unsubscribe())
        };
    }, [patientId]);

    return [
        state,
        handleExpandSection,
        handleTitleChanges,
        handleAppointmentDateChanges,
        handleAppointmentChanges,
        handleChangeDuration,
        handleHpiChanges,
        handlePmhItemChanges,
        handleAddMedications,
        handleAddSupplements,
        handleFullscriptSupplements,
        handleEditMedications,
        handleEditSupplements,
        handleRemoveMedications,
        handleRemoveSupplements,
        handleMedicationStopped,
        handleSupplementStopped,
        handleEnableRos,
        handleRosChanges,
        handleDisableVitals,
        handleVitalsChanges,
        handlePhysicalExamChanges,
        handleMdmTextChange,
        handleMdmPatientTypeChange,
        handleCodeChange,
        handleCategoryChange,
        handleCategoryItemChange,
        handleModeChange,
        handleSpentTimeChanges,
        handleDiagnosisChanges,
        handlePlanTextChanges,
        handleLabTextChanges,
        handleGoalsChanges,
        handleTestChanges,
        handleEducationChanges,
        handleNextAppointmentDateChanges,
        handleSaveAndComplete,
        handleSaveAsDraft,
        handleSignOff,
        handleDiscard,
        getIcdCodesAutoComplete,
        refs,
        handleCoachOtherTextChanges,
        handleTextInfoChange,

        handleToggleActions,
        handleEditRowValue,
        handleEditRowName,
        handleAddNewRow,
        handleDelete,
        handleEdit,
        handleBlur,
        saveValue,
        getIcdCodesAutoPmhComplete,
        handleAddInformation,
        handleEditNewItem,
        handleSaveNewItem,
        handleEditItem,
        isCanCompleteNote,
        handleValidateGoals,
        editPmhState,
        handleHideApoe,
        handleSelectedSoursApoe,
        handleEditApoe,
        handleDateColonoscopyChanges,
        handleDatePsaChanges,
    ];
}
