import { useHistory } from 'react-router';
import { navigationService } from "../../../../services/navigation.service";

export function useFacade(): [
    () => void
] {
    const history = useHistory();

    const handleGoToDashboard = () => {
        navigationService.toDashboard(history);
    }

    return [handleGoToDashboard]
}
