import commonUseStyles from '../../../common/styles/common.styles';
import { Box, Button, Grid, TextField } from "@material-ui/core";
import { useFacade } from "./resetPasswordComponent.hooks";
import React from "react";

export interface ResetPasswordComponentProps {
    patientId?: number 
}

export const ResetPasswordComponent: React.FC<ResetPasswordComponentProps> = (props: ResetPasswordComponentProps) => {
    const { 
        patientId,
    } = props;

    const commonClasses = commonUseStyles();

    const [
        state,
        handleChanges,
        handleSubmitForm
    ] = useFacade(patientId);

    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
        >
            <Grid
                item
                md={6}
                sm={12}
            >
                <form onSubmit={(e) => handleSubmitForm(e)}>
                    <Box mt={5}>
                        <span className={commonClasses.mediumTitle}>Reset Password</span>
                    </Box>

                    <Box display='flex' flexDirection='row' justifyContent="space-between" mt={3}>
                        <Box width='100%' mr={3}>
                            <Box mt={1} className={commonClasses.inputLabel}>
                                New Password
                            </Box>
                            <Box mt={1}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    type="password"
                                    variant="outlined"
                                    value={state.newPassword}
                                    InputProps={{className: 'input'}}
                                    error={!!state.errors["newPassword"]}
                                    helperText={state.errors["newPassword"]}
                                    onChange={(v)=>handleChanges("newPassword", v.target.value)}
                                />
                            </Box>
                        </Box>

                        <Box width='100%' ml={3}>
                            <Box mt={1} className={commonClasses.inputLabel}>
                                Confirm New Password
                            </Box>
                            <Box mt={1}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    type="password"
                                    variant="outlined"
                                    value={state.confirmPassword}
                                    InputProps={{className: 'input'}}
                                    error={!!state.errors["confirmPassword"]}
                                    helperText={state.errors["confirmPassword"]}
                                    onChange={(v)=>handleChanges("confirmPassword", v.target.value)}
                                />
                            </Box>
                        </Box>
                    </Box>

                    <Box id="reset-password" my={3} display='flex' flexDirection='row-reverse'>
                        <Button className={commonClasses.whButton} type="submit">
                            Reset Password
                        </Button>
                    </Box>
                </form>
            </Grid>
        </Grid>
    );
}
