import React from "react";
import {useStyles} from "../playgroundChatComponent/playgroundChatComponent.styles";
import {Box, Divider} from "@material-ui/core";
import {SendPlaygroundMessageComponent} from "../sendPlaygroundMessageComponent/SendPlaygroundMessageComponent";
import {useFacade} from "./startGenericPlaygroundComponent.hooks";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";

export interface StartGenericPlaygroundComponentProps {
    handleCreated: Function;
}

export const StartGenericPlaygroundComponent: React.FC<StartGenericPlaygroundComponentProps> = (props: StartGenericPlaygroundComponentProps) => {

    const [
        {
            isLoading
        },
        handleSendMessage
    ] = useFacade(props.handleCreated);

    const classes = useStyles();

    return (
        <>
            <Box p={1} px={3} height={75} display="flex" justifyContent="space-between" alignItems="center">
                <Box pl={2}>
                    New Chat
                </Box>
            </Box>
            <Divider/>
            <Box className={classes.thread}>
                <Box p={3}>
                    {
                        isLoading &&
                        <WildHealthLinearProgress/>
                    }
                </Box>
            </Box>
            <Divider/>
            <SendPlaygroundMessageComponent
                message=""
                handleSubmit={handleSendMessage}
            />
        </>
    )
}