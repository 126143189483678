import React from "react"
import { Box, Dialog, DialogContent } from "@material-ui/core";
import { DialogTitle } from '../../../common/components/dialogTitle/DialogTitle';
import { SelectUserComponent } from "../../../common/components/selectUserComponent/SelectUserComponent";
import { useFacade } from "./assignLabsDialogComponent.hooks";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";

interface AssignLabsDialogComponentProps {
    isOpen: boolean;
    handleCancel: () => void;
    handleSubmit: (patientId: number) => void;
}

export const AssignLabsDialogComponent: React.FC<AssignLabsDialogComponentProps> = (props: AssignLabsDialogComponentProps) => {
    const { isOpen, handleCancel, handleSubmit } = props;

    const [
        {
            isPatientsLoading,
            selectedPatient,
            patients,
        },
        handlePatientSelect,
        handlePatientSearch,
        handleCloseDialog,
        handleApprove
    ] = useFacade(handleSubmit, handleCancel);

    return (
        <Dialog
            fullWidth
            open={isOpen}
            onClose={() => handleCloseDialog()}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id='dialog-title' onClose={() => handleCloseDialog()}>
                <Box px={2}>Assign to Patient</Box>
            </DialogTitle>
            <DialogContent>
                <Box p={1}>
                    <SelectUserComponent
                        title='Select Patient'
                        placeholder='Select Patient'
                        selectedUser={selectedPatient}
                        users={patients}
                        handleChange={(user) => handlePatientSelect(user)}
                        handleInputChange={handlePatientSearch}
                        isLoading={isPatientsLoading}
                    />
                </Box>
                <Box display="flex" justifyContent="flex-end" py={2} px={1}>
                    <Box>
                        <WildHealthButton
                            id="assign-to-patient-cancel"
                            onClick={() => handleCloseDialog()}
                            color='tertiary'
                            size='large'
                        >
                            Cancel
                        </WildHealthButton>
                    </Box>
                    <Box ml={1}>
                        <WildHealthButton
                            id="assign-to-patient"
                            disabled={!selectedPatient}
                            onClick={() => handleApprove()}
                            size='large'
                        >
                            Assign
                        </WildHealthButton>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}
