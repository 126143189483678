import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            paddingRight: 16,
            [theme.breakpoints.down('sm')]: {
                padding: 0
            },
        },
        chatListRoot: {
            width: 405,
            flexShrink: 0,
            [theme.breakpoints.down('sm')]: {
                width: '100%'
            },
        },
        chatList: {
            height: '100%',
            padding: 6,
            overflowX: 'auto',
            overflowY: 'auto',
            background: colors.white,
            boxShadow: '0px 7px 60px rgba(168, 173, 198, 0.12)',
            borderRadius: 12,
        },
        chatRoot: {
            width: '100%',
            flexDirection: 'column',
            marginRight: 16,
            [theme.breakpoints.down('sm')]: {
                margin: 0
            },
        },
        chat: {
            height: '100%',
            background: colors.white,
            boxShadow: '0px 7px 60px rgba(168, 173, 198, 0.12)',
            borderRadius: 12,
        },
        toggleButton: {
            position: 'fixed',
            zIndex: 1202,
            [theme.breakpoints.down('sm')]: {
                top: 100,
                right: 20
            },
            [theme.breakpoints.down('xs')]: {
                top: 45,
                right: 5
            },
        }
    })
);
