import { Box, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import "../../inputs/helpers/labInputs.extensions";
import { LabInputNames } from "../../inputs/constants/inputs.constants";
import { DnaTableComponent } from "../components/dnaTableComponent/DnaTableComponent";
import { HealthRecommendationComponent } from '../components/healthRecommendationComponent/HealthRecommendationComponent';
import { LabResultComponent } from "../components/labResultComponent/LabResultComponent";
import { LabelPosition, ProgressDiagramComponent } from "../components/progressDiagramComponent/ProgressDiagramComponent";
import { riskColorMap } from "../constants/healthReport.constants";
import { HealthReportMode, HealthReportSections, HealthReportVersion, recommendationRowsCapacity, RecommendationTypesNumbers } from '../models/healthReport.models';
import { HealthReportBasePage, HealthReportMobileBasePage } from "./HealthReportBasePage";
import { useStyles } from './healthReport.styles';
import { HealthReportPageProps } from "./healthReportPageProps";
import { renderScaleChartComponent } from "../helpers/renderScaleChartComponent";
import commonUseStyles from "../../common/styles/common.styles";
import clsx from "clsx";
import { WildHealthButton } from '../../common/components/wildHealthButton/WildHealthButton';
import { RecommendationSingleSelectComponent } from '../components/recommendationSingleSelectComponent/RecommendationSingleSelectComponent';

export const HealthReportDietAndNutrition4Page: React.FC<HealthReportPageProps> = (props: HealthReportPageProps) => {
    const {
        report,
        pageNumber,
        handleChanges,
        handleSaveChanges,
        isChanged,
        mode,
        practice,
        recommendations,
        handleSelected,
        handleChangesNewRecommendation,
        handleAddRecommendation,
        handleSaveChangesNew,
    } = props;
    const commonClasses = commonUseStyles();
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const labValues = report.labReport.values;

    return (
        props.mode !== HealthReportMode.PrintMode && isSmallScreen ? (
            <HealthReportMobileBasePage section={HealthReportSections.DietAndNutrition} pageNumber={pageNumber} practice={practice}>
                <Box>
                    <Box px={2}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box p={2} style={{ background: '#f0f0f0' }}>
                                    <Box>
                                        <span className={classes.reportTitle}>Micronutrients: methylation</span>
                                    </Box>
                                    <Box mt="10px">
                                        <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeMediumReport, commonClasses.textRegularReport)}>Your Methylation Genomics and Labs </span>
                                    </Box>
                                    <Box mt="20px">
                                        <Box>
                                            <span className={classes.reportSubTitle}>
                                                Methylation Status:
                                            </span><br />
                                        </Box>
                                        <Box mt={0.5}>
                                            <span className="report-value uppercase" style={{ fontSize: '24px', fontWeight: 400 }}>
                                                {report.dietAndNutritionReport.methylationScore.result}
                                            </span>
                                        </Box>
                                    </Box>
                                    <Box mt="10px">
                                        <ProgressDiagramComponent
                                            height={22}
                                            colors={['#d7cd00', '#8c935b']}
                                            data={[
                                                {
                                                    label: '',
                                                    value: report.dietAndNutritionReport.methylationScore.scorePercent,
                                                    labelPosition: LabelPosition.Bottom
                                                }
                                            ]}
                                            unavailable={!report.dietAndNutritionReport.methylationScore.isCalculated}
                                        />
                                    </Box>
                                    <Box mt="20px" display="flex" alignItems="center">
                                        <Box>
                                            <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeMediumReport)}>Homocysteine:</span>
                                        </Box>
                                        <Box ml={2}>
                                            <span className="report-value" style={{ fontSize: '24px', fontWeight: 400 }}>
                                                {labValues.getLabReportValue(LabInputNames.Homocysteine)?.value}
                                            </span>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box width={1} display="flex" justifyContent="center">
                                    {renderScaleChartComponent(report.dietAndNutritionReport.cholineRiskScore)}
                                </Box>
                                <Box display="flex" justifyContent="center" mt={1}>
                                    <span className="text-medium">Choline Risk Score</span>
                                </Box>
                                <Box mt={2}>
                                    <DnaTableComponent
                                        dna={report.dietAndNutritionReport.cholineRiskScore.dna}
                                        colorMap={riskColorMap}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box width={1} display="flex" justifyContent="center">
                                    {renderScaleChartComponent(report.dietAndNutritionReport.folateScore)}
                                </Box>
                                <Box display="flex" justifyContent="center" mt={1}>
                                    <span className="text-medium">Folate Risk Score</span>
                                </Box>
                                <Box mt={2}>
                                    <DnaTableComponent
                                        dna={report.dietAndNutritionReport.folateScore.dna}
                                        colorMap={riskColorMap}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box width={1} display="flex" justifyContent="center">
                                    {renderScaleChartComponent(report.dietAndNutritionReport.tmaoRiskScore)}
                                </Box>
                                <Box display="flex" justifyContent="center" mt={1}>
                                    <span className="text-medium">TMAO Score</span>
                                </Box>
                                <Box mt={2}>
                                    <DnaTableComponent
                                        dna={report.dietAndNutritionReport.tmaoRiskScore.dna}
                                        colorMap={riskColorMap}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box className="text-medium" mt="20px">
                                    <Box>
                                        <span className="report-sub-title">Choline Related Labs</span>
                                    </Box>
                                    <Box mt={1}>
                                        <LabResultComponent label="AST" value={labValues.getLabReportValue(LabInputNames.Ast)} />
                                        <LabResultComponent label="ALT" value={labValues.getLabReportValue(LabInputNames.Alt)} />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box className="text-medium" mt="20px">
                                    <Box>
                                        <span className="report-sub-title">Folate Related Labs</span>
                                    </Box>
                                    <Box mt={1}>
                                        <LabResultComponent label="B12" value={labValues.getLabReportValue(LabInputNames.B12)} />
                                        <LabResultComponent label="Folate" value={labValues.getLabReportValue(LabInputNames.Folate)} />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box className="text-medium" mt="20px">
                                    <Box>
                                        <span className="report-sub-title">TMAO related Labs</span>
                                    </Box>
                                    <Box mt={1}>
                                        <LabResultComponent label="Total Cholesterol" value={labValues.getLabReportValue(LabInputNames.TotalCholesterol)} />
                                        <LabResultComponent label="TMAO" value={labValues.getLabReportValue(LabInputNames.Tmao)} />
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box mt="20px">
                            <Box pb={2}>
                                <span className={classes.reportTitle}>Your Methylation Recommendations</span>
                                <Box display="flex" justifyContent="flex-end" width={1}>
                                    {
                                        parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation) && HealthReportMode.EditMode === mode && <WildHealthButton
                                            id="health-recommendation-new-save"
                                            onClick={() => handleSaveChangesNew()}
                                            disabled={!isChanged}>
                                            <Box >
                                                Save changes
                                            </Box>
                                        </WildHealthButton>
                                    }
                                </Box>
                            </Box>
                            {parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
                                ? <Box>
                                    <RecommendationSingleSelectComponent
                                        currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.Methylation)}
                                        recommendation={report.dietAndNutritionReport.methylationRecommendation}
                                        type={RecommendationTypesNumbers.Methylation}
                                        handleSelected={handleSelected}
                                        handleChanges={handleChangesNewRecommendation}
                                        handleAddRecommendation={handleAddRecommendation}
                                        mode={mode}
                                    />
                                </Box>
                                : <HealthRecommendationComponent
                                    mode={mode}
                                    rows={recommendationRowsCapacity[RecommendationTypesNumbers.Methylation]}
                                    recommendation={report.dietAndNutritionReport.methylationRecommendation}
                                    handleContentChanges={!handleChanges ? handleChanges : (value) => {
                                        handleChanges('dietAndNutritionReport.methylationRecommendation.content', value)
                                    }}
                                    handleManualContentChanges={!handleChanges ? handleChanges : (value) => {
                                        handleChanges('dietAndNutritionReport.methylationRecommendation.manualContent', value)
                                    }}
                                    handleSaveChanges={handleSaveChanges}
                                    isChanged={isChanged} />
                            }
                        </Box>
                    </Box>
                </Box>
            </HealthReportMobileBasePage>
        ) : (
            <HealthReportBasePage section={HealthReportSections.DietAndNutrition} pageNumber={pageNumber} practice={practice}>
                <Box>
                    <Box px={15} pt={5}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="space-between">
                                    <span className="report-title">Micronutrients: methylation</span>
                                    <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeMediumReport)}> Your Methylation Genomics and Labs </span>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box p={3} display="flex" justifyContent="space-between" my={5} style={{ background: '#f0f0f0' }}>
                                    <Box width={0.3}>
                                        <Box mt={3}>
                                            <span className="report-sub-title">
                                                Methylation Status:
                                            </span><br />
                                        </Box>
                                        <Box mt={2}>
                                            <span className="report-value uppercase" style={{ fontSize: '50px' }}>
                                                {report.dietAndNutritionReport.methylationScore.result}
                                            </span>
                                        </Box>
                                    </Box>
                                    <Box width={0.5} px={5} mt={3.5}>
                                        <ProgressDiagramComponent
                                            height={22}
                                            colors={['#d7cd00', '#8c935b']}
                                            data={[
                                                {
                                                    label: '',
                                                    value: report.dietAndNutritionReport.methylationScore.scorePercent,
                                                    labelPosition: LabelPosition.Bottom
                                                }
                                            ]}
                                            unavailable={!report.dietAndNutritionReport.methylationScore.isCalculated}
                                        />
                                    </Box>
                                    <Box width={0.2} ml={3}>
                                        <Box mt={3}>
                                            <Box>
                                                <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeMediumReport)}>Homocysteine:</span>
                                            </Box>
                                            <Box mt={3} ml={3}>
                                                <span className="report-value">
                                                    {labValues.getLabReportValue(LabInputNames.Homocysteine)?.value}
                                                </span>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box display="flex" justifyContent="center">
                                    <Box width={1}>
                                        {renderScaleChartComponent(report.dietAndNutritionReport.cholineRiskScore)}
                                    </Box>
                                </Box>
                                <Box display="flex" justifyContent="center" mt={1}>
                                    <span className="text-medium">Choline Risk Score</span>
                                </Box>
                                <Box display="flex" justifyContent="center" mt={2}>
                                    <Box width="200px">
                                        <DnaTableComponent
                                            dna={report.dietAndNutritionReport.cholineRiskScore.dna}
                                            colorMap={riskColorMap}
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box display="flex" justifyContent="center">
                                    <Box width={1}>
                                        {renderScaleChartComponent(report.dietAndNutritionReport.folateScore)}
                                    </Box>
                                </Box>
                                <Box display="flex" justifyContent="center" mt={1}>
                                    <span className="text-medium">Folate Risk Score</span>
                                </Box>
                                <Box mt={2} display="flex" justifyContent="center">
                                    <Box width="200px">
                                        <DnaTableComponent
                                            dna={report.dietAndNutritionReport.folateScore.dna}
                                            colorMap={riskColorMap}
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box display="flex" justifyContent="center">
                                    <Box width={1}>
                                        {renderScaleChartComponent(report.dietAndNutritionReport.tmaoRiskScore)}
                                    </Box>
                                </Box>
                                <Box display="flex" justifyContent="center" mt={1}>
                                    <span className="text-medium">TMAO Score</span>
                                </Box>
                                <Box mt={2} display="flex" justifyContent="center">
                                    <Box width="200px">
                                        <DnaTableComponent
                                            dna={report.dietAndNutritionReport.tmaoRiskScore.dna}
                                            colorMap={riskColorMap}
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box className="text-medium" pr={2} mt={5} mb={6}>
                                    <Box>
                                        <span className="report-sub-title">Choline Related Labs</span>
                                    </Box>
                                    <Box>
                                        <LabResultComponent label="AST" value={labValues.getLabReportValue(LabInputNames.Ast)} />
                                        <LabResultComponent label="ALT" value={labValues.getLabReportValue(LabInputNames.Alt)} />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box px={4} className="text-medium" mt={5} mb={6}>
                                    <Box>
                                        <span className="report-sub-title">Folate Related Labs</span>
                                    </Box>
                                    <Box>
                                        <LabResultComponent label="B12" value={labValues.getLabReportValue(LabInputNames.B12)} />
                                        <LabResultComponent label="Folate" value={labValues.getLabReportValue(LabInputNames.Folate)} />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box pl={2} className="text-medium" mt={5} mb={6}>
                                    <Box>
                                        <span className="report-sub-title">TMAO related Labs</span>
                                    </Box>
                                    <Box>
                                        <LabResultComponent label="Total Cholesterol" value={labValues.getLabReportValue(LabInputNames.TotalCholesterol)} />
                                        <LabResultComponent label="TMAO" value={labValues.getLabReportValue(LabInputNames.Tmao)} />
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box mt={7}>
                            <Box pb={2} display="flex" justifyContent="space-between">
                                <span className="report-title">Your Methylation Recommendations</span>
                                {
                                    parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation) && HealthReportMode.EditMode === mode && <WildHealthButton
                                        id="health-recommendation-new-save"
                                        onClick={() => handleSaveChangesNew()}
                                        disabled={!isChanged}>
                                        <Box >
                                            Save changes
                                        </Box>
                                    </WildHealthButton>
                                }
                            </Box>
                            {parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
                                ? <Box>
                                    <RecommendationSingleSelectComponent
                                        currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.Methylation)}
                                        recommendation={report.dietAndNutritionReport.methylationRecommendation}
                                        type={RecommendationTypesNumbers.Methylation}
                                        handleSelected={handleSelected}
                                        handleChanges={handleChangesNewRecommendation}
                                        handleAddRecommendation={handleAddRecommendation}
                                        mode={mode}
                                    />
                                </Box>
                                : <HealthRecommendationComponent
                                    mode={mode}
                                    rows={recommendationRowsCapacity[RecommendationTypesNumbers.Methylation]}
                                    recommendation={report.dietAndNutritionReport.methylationRecommendation}
                                    handleContentChanges={!handleChanges ? handleChanges : (value) => {
                                        handleChanges('dietAndNutritionReport.methylationRecommendation.content', value)
                                    }}
                                    handleManualContentChanges={!handleChanges ? handleChanges : (value) => {
                                        handleChanges('dietAndNutritionReport.methylationRecommendation.manualContent', value)
                                    }}
                                    handleSaveChanges={handleSaveChanges}
                                    isChanged={isChanged} />
                            }
                        </Box>
                    </Box>
                </Box>
            </HealthReportBasePage>
        )
    )
}
