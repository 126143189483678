import { Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: 5,
            [theme.breakpoints.up('sm')]: {
                padding: '20px 30px',
            },
        },
        title: {
            fontFamily: 'Be Vietnam Pro',
            fontWeight: 500,
            fontSize: 16,
            color: colors.gray1,
        },
        divider: {
            height: 1,
            background: colors.separators,
        },
    })
);

export const PricingButton = withStyles((theme: Theme) => ({
    root: {
        textTransform: 'none',
        color: colors.main,
        backgroundColor: 'rgba(31, 114, 162, 0.08)',
        borderRadius: 2,
        '&:hover': {
            backgroundColor: 'rgba(31, 114, 162, 0.04)',
        },
        '&:disabled': {
            color: `${colors.main}, 50%`,
            backgroundColor: 'rgba(31, 114, 162, 0.02)',
        },
    },
}))(Button);
