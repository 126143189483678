import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    title: {
        fontSize: 25,
        fontFamily: 'OpenSans',
    },
    image: {
        maxWidth: '100%',
    },
});