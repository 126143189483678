import React from "react";
import { Box, ListItem, ListItemIcon, Tooltip, useTheme, useMediaQuery } from "@material-ui/core";
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import clsx from "clsx";
import { displayShortName } from "../../helpers/messages.converter";
import { EmployeeConversationModel, EmployeeConversationParticipantModel } from "../../models/conversation.models";
import { MessageAvatarsComponent } from "../messageAvatarsComponent/MessageAvatarsComponent";
import { useStyles, UnreadMessages } from "./chatItemComponent.styles";
import { profileQuery } from "../../../account/stores/profileStore";
import { ConversationState } from "../../models/conversationState.models";
import { authQuery } from "../../../auth/stores/auth";

export interface ChatItemComponentProps {
    item: EmployeeConversationModel;
    index: number;
    displayDate: string;
    isFavorite: boolean;
    targetConversationId?: number;
    handleConversationSelect: (item: EmployeeConversationModel) => void;
    handleUpdateFavoriteConversation: (item: EmployeeConversationModel, isFavorite: boolean) => void;
}

export const ChatWithPatientItemComponent: React.FC<ChatItemComponentProps> = (props: ChatItemComponentProps) => {
    const {
        item,
        index,
        displayDate,
        isFavorite,
        targetConversationId,
        handleConversationSelect,
        handleUpdateFavoriteConversation,
    } = props;

    const classes = useStyles();
    const customTooltipClasses = {
        tooltip: classes.customWidth,
        arrow: classes.arrow,
        tooltipPlacementBottom: classes.tooltipPlacement,
        tooltipPlacementTop: classes.tooltipPlacement,
    };
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const participants = [...item.patients, ...item.employees.filter(el => el.isActive && !el.isDeleted)];
    const initials = participants.map((i: EmployeeConversationParticipantModel) => ({image: profileQuery.getPhoto(i?.employeeId ?? 0), initials: displayShortName(i.firstName, i.lastName)}));
    const patient = item.patients[0];

    return (
        <ListItem
            id={`chat-item-patient-conversation-${item.id}`}
            key={index}
            button
            className={clsx({[classes.activeChat]: targetConversationId === item.id}, item.patients[0].hasActiveSubscription && item.state !== ConversationState.Closed && classes.listItem, "wh-tw-h-full")}
        >
            <Box display='flex' width='100%' pt={0.5} mx={2} className={isSmallScreen && "wh-tw-h-full"} onClick={() => handleConversationSelect(item)}>
                <ListItemIcon>
                    {isSmallScreen ? (
                        <Box>
                            <MessageAvatarsComponent
                                participants={initials}
                            />
                        </Box>
                    ) : (
                        <UnreadMessages badgeContent={item.unreadMessages}>
                            <MessageAvatarsComponent participants={initials} />
                        </UnreadMessages>
                    )}
                </ListItemIcon>

                <Box display='flex' justifyContent='space-between' width='100%' ml={2}>
                    <Box>
                        <Box className={classes.chatNames}>{`${patient.firstName} ${patient.lastName}`}</Box>
                        <Box className={classes.chatSubTitle}>Plan: {patient.hasActiveSubscription ? patient.subscriptionPlanName : `Inactive (${patient.subscriptionPlanName})`}</Box>
                        <Box className={classes.chatLastMessage}/>
                    </Box>
                    <Box display='flex' alignItems='flex-end' flexDirection='column' className="date-section">
                        <span className={classes.chatDate}>{displayDate}</span>
                        {isSmallScreen && item.unreadMessages > 0 && <Box className={classes.unreadMessages} mt={1}>
                            {item.unreadMessages}
                        </Box>}
                    </Box>
                </Box>
            </Box>
            <Box className={clsx(classes.favoriteAction, "favorite-item")}>
                <Tooltip placement="top" arrow title={<p className={classes.notificationToolTipText}>{isFavorite ? 'Unfavorite a Thread' : 'Mark Thread as Favorite'}</p>} classes={customTooltipClasses}>
                    {isFavorite ? <StarIcon className="wh-tw-text-average1" onClick={() => handleUpdateFavoriteConversation(item, isFavorite)} /> : <StarBorderIcon className="wh-tw-text-gray2" onClick={() => handleUpdateFavoriteConversation(item, isFavorite)} />}
                </Tooltip>
            </Box>
        </ListItem>
    )
}

export const InternalChatItemComponent: React.FC<ChatItemComponentProps> = (props: ChatItemComponentProps) => {
    const {
        item,
        index,
        displayDate,
        isFavorite,
        targetConversationId,
        handleConversationSelect,
        handleUpdateFavoriteConversation,
    } = props;

    const classes = useStyles();
    const customTooltipClasses = {
        tooltip: classes.customWidth,
        arrow: classes.arrow,
        tooltipPlacementBottom: classes.tooltipPlacement,
        tooltipPlacementTop: classes.tooltipPlacement,
    };
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const employees = [...item.employees.filter(el => el.isActive && !el.isDeleted)];
    const initials = employees.map(i => ({image: profileQuery.getPhoto(i.employeeId), initials: displayShortName(i.firstName, i.lastName)}));
    const patient = item.patients[0];

    const renderEmployee = () => {
        if (employees.length > 1) {
            const otherEmployees = employees.filter(x => x.employeeId !== authQuery.getEmployeeId())
            return `${otherEmployees[0].firstName} ${otherEmployees[0].lastName}`;
        }
        return `${employees[0].firstName} ${employees[0].lastName}`;
    }

    return (
        <ListItem
            id={`chat-item-internal-conversation-${item.id}`}
            key={index}
            button
            className={clsx({[classes.activeChat]: targetConversationId === item.id}, item.patients[0].hasActiveSubscription && item.state !== ConversationState.Closed && classes.listItem, "wh-tw-h-full")}
        >
            <Box display='flex' width='100%' mx={2} className={isSmallScreen && "wh-tw-h-full"} onClick={() => handleConversationSelect(item)}>
                <ListItemIcon>
                    {isSmallScreen ? (
                        <Box>
                            <MessageAvatarsComponent
                                participants={initials}
                            />
                        </Box>
                    ) : (
                        <UnreadMessages badgeContent={item.unreadMessages}>
                            <MessageAvatarsComponent participants={initials} />
                        </UnreadMessages>
                    )}
                </ListItemIcon>
                <Box display='flex' justifyContent='space-between' width='100%' ml={2}>
                    <Box>
                        <Box className={classes.chatNames}>{renderEmployee()}</Box>
                        <Box className={classes.chatSubTitle}>{`Patient: ${patient.firstName} ${patient.lastName}`}</Box>
                        <Box className={classes.chatLastMessage}/>
                    </Box>
                    <Box display='flex' alignItems='flex-end' flexDirection='column' className="date-section">
                        <span className={classes.chatDate}>{displayDate}</span>
                        {isSmallScreen && item.unreadMessages > 0 && <Box className={classes.unreadMessages} mt={1}>
                            {item.unreadMessages}
                        </Box>}
                    </Box>
                </Box>
            </Box>
            <Box className={clsx(classes.favoriteAction, "favorite-item")}>
                <Tooltip placement="top" arrow title={<p className={classes.notificationToolTipText}>{isFavorite ? 'Unfavorite a Thread' : 'Mark Thread as Favorite'}</p>} classes={customTooltipClasses}>
                    {isFavorite ? <StarIcon className="wh-tw-text-average1" onClick={() => handleUpdateFavoriteConversation(item, isFavorite)} /> : <StarBorderIcon className="wh-tw-text-gray2" onClick={() => handleUpdateFavoriteConversation(item, isFavorite)} />}
                </Tooltip>
            </Box>
        </ListItem>
    )
}