import {
    Box,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Grid,
    Switch,
    TextField
} from "@material-ui/core";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React, { useEffect, useMemo, useState } from "react";
import { Subscription } from "recompose";
import { interval } from 'rxjs';
import { confirmService } from "../../../../../services/confirm.service";
import { PermissionType, Practice, UserType } from "../../../../auth/models/auth.enums";
import { OptionalComponent } from '../../../../common/components/OptionalComponent';
import { isElementEnabled } from "../../../../common/components/ProtectedElement";
import { WildHealthDatePicker } from "../../../../common/components/WildHealthDatePicker";
import { insertString } from "../../../../common/helpers/insert-string";
import { onEmit } from '../../../../common/helpers/on-emit';
import { IErrorState } from "../../../../common/validation/error-state";
import { selectShortcutService } from "../../../../healthReport/services/selectShortcut.service";
import { PatientModel } from "../../../../patients/models/patient.model";
import { patientsService } from "../../../../patients/services/patients.service";
import { patientsQuery } from "../../../../patients/stores/patientsStore";
import { PatientMedicationsSupplementsTabsComponent } from "../../../../patientSupplements/components/patientMedicationsSupplementsTabs/PatientMedicationsSupplementsTabsComponent";
import { QuestionComponent } from "../../../../questionnaire/components/questionComponent/QuestionComponent";
import {
    CheckAnswer,
    QuestionModel,
    QuestionnaireResultModel,
    QuestionnaireSection,
    QuestionnaireType
} from "../../../../questionnaire/models/questionnaire.models";
import { questionnaireService } from "../../../../questionnaire/services/questionnaire.service";
import {
    CheckManyToTextField,
    getInitQuestionnaires,
    InitialConsultToHealthLogQuestionNamesMap,
    InitialConsultToInitialQuestionNamesMap,
    initRecommendations,
    KeyMap,
    NoteRecommendation,
    NoteRecommendationTypes
} from "../../../constants/initialConsultOld.constants";
import { NoteLogModel, NoteModel, NotesType, SaveNoteModel, SignOffNoteModel } from "../../../models/notes.models";
import { notesService, SignOffModel } from "../../../services/notes.service";
import { createInitialConsultComponentValidator } from "../createInitialConsultComponent.validator";
import { colors } from "../../../../common/constants/colors";
import { authQuery } from "../../../../auth/stores/auth";
import { DataSpecificationsEnum } from "../../../../common/constants/data-specifications";
import { useHistory } from 'react-router';
import { snackService } from "../../../../common/snack/state";
import { SelectAppointmentToNotesDialog } from "../../SelectAppointmentToNotesComponent/SelectAppointmentToNotesDialog";
import { TextFieldNoteComponent } from "../../textFieldNoteComponent/TextFieldNoteComponent";
import { ReactComponent as CalendarIcon } from "@img/icons/CalendarIcon.svg";
import { WildHealthButton } from "../../../../common/components/wildHealthButton/WildHealthButton";
import moment from "moment";
import { SignOffDialogComponent } from "../../signOffDIalogComponent/SignOffDialogComponent";
import { FeatureFlag } from "../../../../common/components/featureFlags/featureFlags.models";
import { FeatureComponent } from "../../../../common/components/featureFlags/FeatureComponent";
import {canDiscardNote, isFutureAppointment} from "../../../helpers/noteHelpers";

let stateContext: CreateInitialConsultComponentState = null;

interface CreateInitialConsultComponentState extends IErrorState {
    isLoading: boolean;
    isChanged: boolean;
    isAutoSaving: boolean;
    isProcessing: boolean;
    isNew: boolean;
    note: NoteModel;
    title: string;
    recommendations: NoteRecommendation[];
    questionnaires: QuestionnaireSection[];
    logs: NoteLogModel[];
    internalContent: string;
    visitDate: Date;
    isOrderedInitialLabsCollected: boolean;
    isFreeTrial: boolean;
    isFirstSaveOriginalNote: boolean;
    originalNote: NoteModel;
}

let ignoreChangesEvent = false;

export function useFacade(patientId: number, note: NoteModel | null, classes: any, handleGoToNotes: Function, appointmentId: number | null, originalNote: NoteModel): [
    CreateInitialConsultComponentState,
    JSX.Element,
    JSX.Element,
    JSX.Element,
    JSX.Element,
    JSX.Element,
    JSX.Element[],
    JSX.Element,
    string,
    JSX.Element,
] {
    const noteName = "Initial Coach Consult";
    const history = useHistory();
    const autoSaveTimer = useMemo(() => interval(5000), []);
    const [isOpen, setIsOpen] = useState(false);
    const [state, setState] = useState({
        isLoading: true,
        isChanged: false,
        isAutoSaving: false,
        isProcessing: false,
        isNew: true,
        note: note,
        recommendations: initRecommendations.map(x => Object.assign({}, x)),
        questionnaires: getInitQuestionnaires(),
        logs: [],
        title: note?.title || '',
        internalContent: '',
        visitDate: note?.visitDate ?? new Date(),
        isOrderedInitialLabsCollected: false,
        isFreeTrial: false,
        errors: {},
        isFirstSaveOriginalNote: originalNote ? true : false,
        originalNote: originalNote,
    } as CreateInitialConsultComponentState);

    stateContext = state;

    const handleSaveAsDraft = () => {
        if (!createInitialConsultComponentValidator.stateIsValid(state)) {
            return;
        }

        if (state.isAutoSaving) {
            setTimeout(() => {
                setState(state => ({
                    ...state,
                    isChanged: false,
                    isProcessing: true,
                }));

                notesService.saveAsDraft(getData(state), patientsQuery.getTargetPatientIsPremium()).subscribe(
                    () => {
                        handleGoBack(history)
                    },
                    (error) => {
                        snackService.commonErrorHandler(error);
                        setState(state => ({
                            ...state,
                            isProcessing: true
                        }));
                    }
                );
            }, 2000)
            return;
        }

        setState(state => ({
            ...state,
            isChanged: false,
            isProcessing: true,
        }));

        notesService.saveAsDraft(getData(state), patientsQuery.getTargetPatientIsPremium()).subscribe(
            () => {
                handleGoBack(history)
            },
            (error) => {
                snackService.commonErrorHandler(error);
                setState(state => ({
                    ...state,
                    isProcessing: true
                }));
            }
        );
    }

    const isCanCompleteNote = () => {
        if (!!originalNote) {
            return originalNote.completedById === authQuery.getEmployeeId();
        }

        if (stateContext?.note?.assignedTo) {
            return stateContext.note.assignedTo.id === authQuery.getEmployeeId()
        }

        if (stateContext?.note?.completedById) {
            return stateContext.note.completedById === authQuery.getEmployeeId()
        }

        return true;
    }

    const handleSignOff = () => {
        notesService.openSignOff({
            patientId: state.note.patientId,
            noteType: state.note.type
        } as SignOffModel).subscribe(([employeeId, additionalNote]) => {
            const model: SignOffNoteModel = {
                noteId: stateContext.note.id,
                assignToId: employeeId,
                additionalNote: additionalNote
            };

            setState(state => ({
                ...state,
                isProcessing: true
            }));

            notesService.signOff(model).subscribe(
                () => {
                    handleSaveAsDraft();
                    handleGoBack(history);
                },
                () => {
                    setState(state => ({
                        ...state,
                        isProcessing: false
                    }))
                }
            );
        });
    }

    const handleSaveAndComplete = () => {
        if (!createInitialConsultComponentValidator.stateIsValid(state)) {
            return;
        }

        confirmService.confirm('Are you sure? This action can not  be undone').subscribe(() => {
            setState(state => ({
                ...state,
                isChanged: false,
                isProcessing: true,
            }));

            notesService.saveAsCompleted(getData(state)).subscribe(
                () => handleGoToNotes(),
                () => {
                    setState(state => ({
                        ...state,
                        isProcessing: false
                    }));
                });
        });
    }

    const handleAnswerChanges = (question: QuestionModel, answer: string) => {
        createInitialConsultComponentValidator.validateAndSetState(state, setState, question.name, answer);
        const log = state.logs.find(x => x.key === question.name);
        if (!log) {
            setState({
                ...state,
                isChanged: true,
                logs: [
                    ...state.logs, {
                        key: question.name,
                        value: answer
                    }
                ]
            })
        } else {
            log.value = answer;
            setState({
                ...state,
                isChanged: true,
                logs: [...state.logs]
            })
        }
    }

    const getAnswer = (question: QuestionModel): string => {
        return state.logs.find(x => x.key === question.name)?.value ?? question.default
    }

    const handleDiscard = () => {
        confirmService.confirm('Are you sure? This action can not be undone').subscribe(() => {
            discard();
        });
    }

    const handleGoBack = (history) => {
        handleGoToNotes();
    }

    const discard = () => {
        if (stateContext.isAutoSaving) {
            setTimeout(() => discard(), 50);
            return;
        }

        setState(state => ({
            ...state,
            isChanged: false,
            isProcessing: true,
        }));

        notesService.delete(state.note).subscribe(() => handleGoBack(history));
    }

    const handleInternalNotesChanged = (value: string) => {
        if (ignoreChangesEvent) {
            return;
        }

        setState({
            ...state,
            isChanged: true,
            internalContent: value
        });
    }

    const handleTextMessageChanged = (value: string) => {
        setState(state => ({
            ...state,
            isChanged: true,
            internalContent: value
        }));
    }

    const handleKeyDown = (event: any) => {
        ignoreChangesEvent = false;
        if (event.keyCode === 51 && event.altKey) {
            const userType = authQuery.getType();
            if (UserType.Employee === userType) {
                ignoreChangesEvent = true;

                setTimeout(() => {
                    handleOnInternalNotesChanged(
                        (document.getElementById('internal-notes') as any).data,
                        (document.getElementById('internal-notes') as any).selectionStart);
                }, 1)
            }
        }
    }

    const handleOnInternalNotesChanged = (value: string, cursorPos: number) => {
        const cb = (data) => {
            value = state.internalContent;
            if (data) {
                handleTextMessageChanged(insertString(value, data, cursorPos + 1));
            }
        }

        selectShortcutService.select().subscribe(cb);
    }

    const getData = (state: CreateInitialConsultComponentState): SaveNoteModel => {
        return {
            id: state.note?.id,
            content: getContent(state),
            internalContent: state.internalContent,
            name: noteName,
            title: state.title,
            type: NotesType.Initial,
            visitDate: state.visitDate,
            patientId: patientId,
            logs: state.logs,
            nextCoachAppointmentDate: null,
            nextProviderAppointmentDate: null,
            appointmentId: state.note ? state.note.appointment?.id : appointmentId,
            version: state.note?.version,
            originalNoteId: state.originalNote?.id
        }
    }

    const getSaveModelFromOriginalNote = (content): SaveNoteModel => {
        return {
            id: null,
            name: noteName,
            title: state.originalNote.title,
            type: NotesType.Initial,
            visitDate: state.visitDate,
            patientId: patientId,
            internalContent: state.internalContent,
            content: JSON.stringify(content),
            logs: [],
            nextCoachAppointmentDate: null,
            nextProviderAppointmentDate: null,
            version: state.originalNote?.version,
            originalNoteId: state.originalNote?.id
        } as SaveNoteModel;
    }

    const handleNoteTitleChange = (field: string, value: string) => {
        createInitialConsultComponentValidator.validateAndSetState(state, setState, field, value);
        setState(state => ({
            ...state,
            isChanged: true,
            title: value
        }));
    }

    const titleTextBox = (
        <TextField
            label='Enter title'
            type='text'
            size="small"
            variant="outlined"
            id='title'
            value={state.title}
            helperText={state.errors['title']}
            error={!!state.errors['title']}
            disabled={state.isProcessing}
            InputProps={{ className: 'input' }}
            onChange={(e) => {
                handleNoteTitleChange(e.target.id, e.target.value);
            }}
        />
    )

    const getContent = (state: CreateInitialConsultComponentState) => {
        return JSON.stringify(state.recommendations);
    }

    const handleVisitDateChanges = (momentDate: MaterialUiPickersDate) => {
        setState({
            ...state,
            isChanged: true,
            visitDate: momentDate?.toDate()
        });
    }

    const handleAppointmentChanges = (appointmentId: number) => {
        if (state.note?.appointment?.id === appointmentId) {
            return;
        }

        const model = getData(state);

        model.appointmentId = appointmentId;

        notesService.saveAsDraft(model, patientsQuery.getTargetPatientIsPremium()).subscribe(
            (note) => {
                setState(state => ({
                    ...state,
                    note: note
                }))
            },
            () => {
                snackService.error('Note already exists for this appointment.');
            }
        );
    }

    const handleSelectAppointmentSubmit = (appointmentId: number) => {
        if (!appointmentId) {
            if (!patientsQuery.getTargetPatient()?.isPremium) {
                snackService.error("There was an error while attaching a note.");
            }
        } else {
            handleAppointmentChanges(appointmentId);
        }

        setIsOpen(false);
    }

    const finalVisitDate = state.note?.appointment
        ? state.note.appointment.date
        : state.note?.visitDate
            ? state.note.visitDate
            : state.visitDate;

    const internalNotes = (
        <>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} mt={2}>
                <Box>
                    <Box mr={1}>
                        <span className={classes.sectionTitle}>Notes</span>
                    </Box>
                </Box>
                <Box display="flex" alignItems="center">
                    <Box mr={1}>Appointment Date:</Box>
                    {
                        state.note?.appointment
                            ? <WildHealthButton
                                width={256}
                                size="medium"
                                style={{ whiteSpace: 'nowrap' }}
                                color="datePicker"
                                id='sing-physical-history'
                                onClick={() => setIsOpen(true)}
                            >
                                <span>{moment(finalVisitDate).format("MM/DD/yyyy")}</span>
                                <CalendarIcon />
                            </WildHealthButton>
                            : <WildHealthDatePicker
                                required
                                size="small"
                                openTo="year"
                                format="MM/DD/yyyy"
                                id='AppointmentDate'
                                placeholder="mm-dd-yyyy"
                                value={finalVisitDate}
                                inputVariant="outlined"
                                views={["year", "month", "date"]}
                                useKeyboardDatePicker
                                onChange={(momentDate) => handleVisitDateChanges(momentDate)}
                            />
                    }
                </Box>
            </Box>
            <Box>
                <TextFieldNoteComponent
                    rows={10}
                    title='Internal Notes'
                    id="internal-notes"
                    content={state.internalContent}
                    handleChanged={handleInternalNotesChanged}
                    handleKeyDown={handleKeyDown}
                />
            </Box>
            <OptionalComponent>
                <SelectAppointmentToNotesDialog
                    handleSubmitSelectAppointment={(appointmentId) => handleSelectAppointmentSubmit(appointmentId)}
                    handleChangeSelectAppointment={() => { }}
                    handleSelectAppointmentDialog={(isOpen) => setIsOpen(isOpen)}
                    isOpen={isOpen}
                    patientId={patientId} />
            </OptionalComponent>
        </>
    );

    const questionnaire = (
        <Box mt={5}>
            {
                state.questionnaires.map((section, sectionIndex) =>
                    <React.Fragment key={sectionIndex}>
                        <Box pb={2}>
                            <span className={classes.sectionTitle}>{section.name}</span>
                        </Box>
                        <Box mb={10}>
                            {
                                section.questions.map((question, index) =>
                                    <Box key={index}>
                                        <QuestionComponent
                                            handleChanges={value => handleAnswerChanges(question, value)}
                                            answer={getAnswer(question)}
                                            question={question}
                                            error={state.errors[question.name]}
                                            inline={false}
                                            componentInNotes
                                        />
                                    </Box>
                                )
                            }
                        </Box>
                    </React.Fragment>
                )
            }
        </Box>
    );

    const medicationSupplements = (
        <PatientMedicationsSupplementsTabsComponent patientId={patientId} fullWidth={true} />
    );

    const handleOnRecommendationEditChange = (recommendationType: NoteRecommendationTypes, value: boolean) => {
        const item = state.recommendations.find(i => i.type === recommendationType);
        if (item) {
            item.isSelected = value;
            setState({
                ...state,
                isChanged: true,
                recommendations: state.recommendations
            });
        }
    }

    const handleOnRecommendationAddChange = (value: string, name: string,) => {
        const item = state.recommendations.find(i => i.name === name);
        if (!item) {
            return;
        }
        if (ignoreChangesEvent) {
            return;
        }

        item.content = value
        setState({
            ...state,
            isChanged: true,
            recommendations: state.recommendations
        });
    }

    const handleOnRecommendationAddTextChange = (name: string, value: string) => {
        const item = state.recommendations.find(i => i.name === name);
        if (!item) {
            return;
        }

        item.content = value
        setState({
            ...state,
            isChanged: true,
            recommendations: state.recommendations
        });
    }

    const handleOnRecommendationChange = (event: any, id: string, name: string,) => {
        ignoreChangesEvent = false;
        if (event.keyCode === 51 && event.altKey) {
            const userType = authQuery.getType();
            if (UserType.Employee === userType) {
                ignoreChangesEvent = true;
                const value = (document.getElementById(id) as any).value;
                const cursorPos = (document.getElementById(id) as any).selectionStart;
                setTimeout(() => {
                    selectShortcutService.select().subscribe((data) => {
                        data && handleOnRecommendationAddTextChange(name, insertString(value, data, cursorPos + 1))
                    })
                }, 1)
            }
        }
    }

    const recommendationsHeader = (
        <>
            <Grid item xs={7}>
                <Box mt={5}>
                    <span className={classes.sectionTitle}>
                        {'Recommendations '}
                    </span>
                    <span>(10 minutes)</span>
                </Box>
            </Grid>

            <Grid item xs={5}>
                <Box mt={5}>
                    <span className={classes.sectionTitle}>
                        SELECTS 2 CATEGORIES BASED ON ABOVE ASSESSMENT AND GOALS
                    </span>
                </Box>
            </Grid>
        </>
    );

    const recommendations = state.recommendations.map((item, index) => {
        const control =
            <Checkbox
                icon={<CheckBoxOutlineBlankIcon />}
                checkedIcon={<CheckBoxIcon />}
                name={`Recommendation${index}-CheckBox`}
                checked={item.isSelected} color='default'
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleOnRecommendationEditChange(item.type, event.target.checked)}
            />;

        return (
            <Grid key={index} item xs={7}>
                <Box mt={5}>
                    <Grid
                        container
                        spacing={5}
                        justify="space-between"
                    >
                        <Grid item xs={9} md={9} lg={9}>
                            <FormControlLabel control={control} label={item.name} />
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <TextFieldNoteComponent
                        isFollowUp
                        rows={10}
                        title=''
                        id={`recommendation-text-area${index}`}
                        content={item.content}
                        disabled={!item.isSelected}
                        key2={item.name}
                        handleChanged={handleOnRecommendationAddChange}
                        handleKeyDown={handleOnRecommendationChange}
                    />
                </Box>
            </Grid>
        )
    });

    const handleIsFreeTrialChanged = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setState({
            ...state,
            isFreeTrial: checked
        });
    }

    const handleIsOrderedInitialLabsCollectedChanged = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setState({
            ...state,
            isOrderedInitialLabsCollected: checked
        });
    }

    const scriptsGoal = (
        <>
            <Box mt={2}>
                <span className={classes.sectionTitle}>The FOUR goals of this visit are</span>
            </Box>
            <Box pl={5} mt={2}>
                <ol style={{ listStyleType: 'decimal' }}>
                    <li>Build rapport.</li>
                    <li>Establish goals - emphasize importance.</li>
                    <li>Verify intake information.</li>
                    <li>Complete recommendations and pitches.</li>
                </ol>
            </Box>
            <Box mt={3}>
                <span className={classes.sectionTitle}>Script</span>
                <span>{' (5 minutes)'}</span>
            </Box>
            <Box mt={2}>
                <Box>
                    <span>
                        Introduction as clients Health Coach and welcomes to Wild Health.
                    </span>
                </Box>
                <Box mt={2}>
                    <span>
                        Small talk with client: Where they live, family, occupation or other.
                    </span>
                </Box>
                <Box mt={2}>
                    <span>
                        Discuss Single nucleotide polymorphisms and how they are used.
                    </span>
                </Box>
                <Box mt={2}>
                    <span>
                        Sample from report: SNPs are a probability of risk towards certain health conditions that
                        epigenetically we want to improve through lifestyle modifications. Your unique DNA is an
                        operating system to tell your body what to do in order to metabolize foods or how to make
                        proteins. SNPs are a set of base pairs that have become switched and are not mutation.
                        These are very common in the population.  These switches are how we have adopted over time.
                        Where these SNPs are located on your DNA are how they affect you. Your DNA is unique to you
                        and your environment and lifestyle determines if your genes are switched to make you less
                        optimized.  We incorporate you labs to see how these switched genes are affecting you.
                        You may have a switched gene but it is not causing a biological dysfunction.
                    </span>
                </Box>
                <Box mt={2}>
                    <span>
                        We received your initial health survey but we want to cover some of those topics during this
                        call to make sure we are on the same page and to see if anything has changed. First we always
                        start out by identifying your top 2-3 goals.
                    </span>
                </Box>
            </Box>
        </>
    )

    const recommendationsWithScripts = [
        recommendations[0],
        <Grid key='recommendation-1' item xs={5}>
            <Box mt={10}>
                <Box>
                    <span>No supplements recommended on initial visit.</span>
                </Box>
                <Box mt={2}>
                    <span>
                        Pitch: (If client asks) We could guess on the best supplements/diet/etc., but then we would
                        be practicing just like the broken medical system based on statistics and epidemiology.
                        We don't want to tell you what is best for most people.  We want to tell you what is best for YOU.
                        We'll be able to do that as soon as we have your DNA and labs back and are able to put them in our
                        machine learning based algorithms.
                    </span>
                </Box>
            </Box>
        </Grid>,
        recommendations[1],
        <Grid key='recommendation-2' item xs={5}>
            <Box mt={10}>
                <span>
                    Healthy snack replacements: raw veggies/hummus, raw almonds, low glycemic fruit (berries).
                </span>
            </Box>
            <Box mt={3}>
                <Box>
                    <span>
                        Pitch: A great place to start is Michael Pollan's recommendation in The Omnivore's Dilemma:
                        Eat Food, mostly plants, not too much.  And you can probably take out the "mostly plants" part
                        until we have your DNA/labs.  For now, eat food, not too much.  And we should define food.
                        If your great-great grandmother wouldn't have recognized it as food 100 years ago,
                        then it's not food.
                    </span>
                </Box>
                <Box mt={2}>
                    <span>
                        There are important genetic and lab markers that determine how sensitive you are to things
                        like saturated fat and how well you absorb vitamins and minerals. Some individuals tolerate
                        a better high fat diet while others are predisposed to better health with a higher carbohydrate
                        diet. Our genetic algorithm uses polygenic risk scores to help us design a diet plan just for
                        you! Once we have your DNA/labs back then we're going to be able to get very specific with you
                        about exact foods and categories of food.  We'll even identify superfoods and kryptonite foods
                        for you.  We'll also talk about timing and when you should eat based on your DNA/Labs/Lifestyle.
                    </span>
                </Box>
                <Box mt={2}>
                    <span>
                        For now, choose healthy snack replacements. Examples include raw veggies/hummus, raw almonds,
                        low glycemic fruit (berries).
                    </span>
                </Box>
            </Box>
        </Grid>,
        recommendations[2],
        <Grid key='recommendation-3' item xs={5}>
            <Box mt={10}>
                <span>
                    Exercise is very important for our physical and psychological well-being. Moving frequently
                    throughout the day is just as important as intense exercise. By evaluating your genetic profile
                    we are able to use polygenic scores to determine whether you will respond better to strength or
                    endurance.  We can also evaluate your rate of recovery and personalized nutrients that may aid in
                    recovery.  If you're not exercising at all right now, then start by walking.  We'll get much more
                    specific with you once we have your data, but for now just move often, track your exercise, and pay
                    attention to what hurts and what feels good so we can discuss and create a truly personalized plan.
                </span>
            </Box>
        </Grid>,
        recommendations[3],
        <Grid key='recommendation-4' item xs={5}>
            <Box mt={10}>
                <span>
                    Book recommendation: Why We Sleep by Matthew Walker <a id="create-intitial-consult-link-book" target="_blank" rel="noopener noreferrer" href="https://www.amazon.com/Why-We-Sleep-Unlocking-Dreams/dp/1501144324/ref=asc_df_1501144324/?tag=hyprod-20&linkCode=df0&hvadid=312049124368&hvpos=&hvnetw=g&hvrand=15779005785335573933&hvpone=&hvptwo=&hvqmt=&hvdev=c&hvdvcmdl=&hvlocint=&hvlocphy=9014313&hvtargid=pla-542231888375&psc=1&tag=&ref=&adgrpid=61851652213&hvpone=&hvptwo=&hvadid=312049124368&hvpos=&hvnetw=g&hvrand=15779005785335573933&hvqmt=&hvdev=c&hvdvcmdl=&hvlocint=&hvlocphy=9014313&hvtargid=pla-542231888375"> LINK.</a>
                </span>
            </Box>
            <Box mt={3}>
                <Box>
                    <span>
                        Device: Oura ring, we can use the Oura cloud service to remotely track your sleep. Using N of
                        1 experiments we are able to make improvements in your sleep quality.
                    </span>
                </Box>
                <Box mt={2}>
                    <span>
                        Meditation Apps: Calm, Headspace.
                    </span>
                </Box>
                <Box mt={2}>
                    <span>
                        Breath Work Apps: Oak, Breathe.
                    </span>
                </Box>
                <Box mt={2}>
                    <span>
                        Breath work practice: 4-7-8 breathing 4 rounds 3 times per day.
                    </span>
                </Box>
            </Box>
        </Grid>,
    ]

    const endOfTemplate = (
        <>
            <OptionalComponent practiceId={Practice.WildHealth}>
                <>
                    <Box display="flex" justifyContent="center" mt={5}>
                        <Box>
                            <span className={classes.endOfNoteTitle}>(End of Note Template for Clarity)</span>
                        </Box>
                    </Box>

                    <Box display="flex" mt={3}>
                        <Box display="flex">
                            <Box p={1}>
                                <span>
                                    No
                                </span>
                            </Box>
                            <Box ml={3}>
                                <FormControlLabel
                                    label=""
                                    control={
                                        <Switch
                                            checked={state.isFreeTrial}
                                            onChange={handleIsFreeTrialChanged}
                                            color="default"
                                        />
                                    }
                                />
                            </Box>
                            <Box p={1}>
                                <span>
                                    Yes
                                </span>
                            </Box>
                        </Box>
                        <Box ml={5} p={1}>
                            <span className="text-medium">Is this a free trial patient?</span>
                        </Box>
                    </Box>
                    <Box>
                        {
                            state.isFreeTrial ?
                                <Box mt={2} mb={2}>
                                    <Box>
                                        <span className={classes.endSectionTitle}>Free Version Tier pitch/QA:</span>
                                        <span>{' (5 minutes)'}</span>
                                    </Box>
                                    <Box>
                                        <Box mt={2}>
                                            <span className={classes.smallTitle}>{'Goal based pitches: '}</span>
                                            <span>
                                                Based on the initial information you gave us the previous recommendation we discussed will likely be helpful. Generally, following the previously mentioned principles is healthy for everyone. However, each individual is different and what might be great for one might be harmful for another. Take for example the GSTP1 SNP, for some individuals Vit E produces a nice anti-inflammatory effect as an antioxidant. In individuals with the risk allele Vit E supplementation can actually produce inflammation. In order for us to achieve (goal indicated in survey or conversation) begin script below.
                                            </span>
                                        </Box>
                                        <Box mt={2}>
                                            <span className={classes.smallTitle}>{'Health Optimization: '}</span>
                                            <span>
                                                This is a common goal but people often times have difficulty defining what “optimization” is. In order to ensure that you are addressing all possible areas of concern we recommend our full lab panel and genetic evaluation. By combining these two measures we are able uncover current and potential areas of deficiency that can lead to disease and illness. We'll also help you better define what this means for you by identifying what areas need to be optimized that you may not have realized were off.  We'll give you an objective specific global optimization score calculated by our algorithm and together shoot for a specific goal number.  If the DNA and full lab panel don't fit in your budget, then we could start with just DNA and a smaller lab panel.
                                            </span>
                                        </Box>
                                        <Box mt={2}>
                                            <span className={classes.smallTitle}>{'Longevity and Healthy Aging: '}</span>
                                            <span>
                                                We must first ensure that the all of the underlying risk factors have been addressed. This includes minimizing cardiovascular risk factors, mitigating the risk of cognitive decline and other chronic disease. By combining all of your subjective and objective data we can use validated risk scores to ensure that you are prepared to live a long healthy life. After initial optimization we have focused 6 month longevity track based on your genetics. This track provides you with all of the lifestyle, dietary and biohacking tools to promote longevity and healthspan! We'll also help you better define what this means for you by identifying what areas need to be optimized that you may not have realized were off.  We'll give you an objective specific, global optimization score calculated by our algorithm and together shoot for a specific goal number. If the DNA and full lab panel don't fit in your budget, then we could start with just DNA and a smaller lab panel.
                                            </span>
                                        </Box>
                                        <Box mt={2}>
                                            <span className={classes.smallTitle}>{'Disease Treatment and Prevention: '}</span>
                                            <span>
                                                We first look at the person as a whole by taking multiple data points into consideration. Although heartburn occur in the stomach we must also make sure that gut biome, stress, sleep and all other factors are corrected. We view disease treatment and prevention from a holistic prospective and allow genetic predispositions to help guide our approach and interventions. We'll look at you as whole person, not a collection of organ systems to fix. Then we'll identify what areas need to be optimized for the entire system to work flawlessly.  That will include an objective specific global optimization score calculated by our algorithm and we'll fix that first.  Once your optimized as a whole, the vast majority of specific complaints just get better.  If it doesn't, then we'll dive into specialty treatment for the issue. If the DNA and full lab panel don't fit in your budget, then we could start with just DNA and a smaller lab panel.
                                            </span>
                                        </Box>
                                        <Box mt={2}>
                                            <span className={classes.smallTitle}>{'Mental Cognition and Brain Health: '}</span>
                                            <span>
                                                We must first look at the person and a whole. Although headaches occur in the neck and head we must also make sure that gut biome, stress, sleep and all other factors are corrected. We view disease treatment and prevention from a holistic prospective and allow genetic predispositions to help guide our approach and interventions.  We combine your genetics and your lifestyle.  For instance having one APOE 4 allele predisposes you to a 2-3x increased risk of cognitive decline. While a COMT variation may predispose an individual to difficulty focusing or anxiety. Both of these SNPs are modifiable and when armed with the right tools can be completely negated.   Then we'll identify what areas need to be optimized for the entire system to work flawlessly.  That will include an objective specific global optimization score calculated by our algorithm and we'll fix that first.  Once your optimized as a whole, the brain and cognition follow.  You can't separate the mind from the body.  After the holistic optimization, then we can dive super deep into brain optimization with a very robust track designed specifically for that with cutting edge tools personalized to you.  If the DNA and full lab panel don't fit in your budget, then we could start with just DNA and a smaller lab panel.
                                            </span>
                                        </Box>
                                        <Box mt={2}>
                                            <span className={classes.smallTitle}>{'Sports and Athletic Performance: '}</span>
                                            <span>
                                                Athletic performance is a delicate balance of intensity, recovery, frequency and fueling. Using polygenic risk scores we are able to produce exercise planning and nutritional recommendations that are designed for your body’s operating system(your DNA)! We also have a dedicated athletic performance track designed to optimize all aspects of athletic performance, we often recommend this track early on in the process for athletes who are already nearly optimized in other areas.  It's important to look at EVERYTHING, not just workouts and recovery, though.  We find quite often in our pro athletes that it's something else truly holding them back in their DNA or labs.  They always have the motivation and work ethic.  They just need to be armed with the right knowledge specific to them about their DNA and body that they've never been given before.  We can give them.....and you that information to take your athletic performance to the next level. If the DNA and full lab panel don't fit in your budget, then we could start with just DNA and a smaller lab panel.
                                            </span>
                                        </Box>
                                        <Box mt={2}>
                                            <span className={classes.smallTitle}>{'Diet and Nutrition Optimization: '}</span>
                                            <span>
                                                Based on what you have told me so far I think that the diet changes will be beneficial for you regardless of your genetic predispositions and current labs. Once we are able to add those two additional pieces of info we can make more definitive dietary recommendations. There are important genetic and lab markers that determine how sensitive you are to things like saturated fat, how well you absorb vitamins and minerals. Some individuals tolerate a high fat diet while others are predisposed to better health with a higher carbohydrate diet. Our genetic algorithm uses polygenic risk scores to help us tailor a diet plan just for you! If the DNA and full lab panel don't fit in your budget, then we could start with just DNA and a smaller lab panel.
                                            </span>
                                        </Box>
                                        <Box mt={2}>
                                            <span className={classes.smallTitle}>{'Improving Sleep and Proper Recovery: '}</span>
                                            <span>
                                                There might not be one more important factor to improve health and welling being as much as sleep. We view multiple SNPs that impact sleep like melatonin receptor SNP MTNR1A and ADORA2. However, even knowing these predisposition sleep habits can be difficult to change. That is why we recommend the sleep tracking device like Oura which we can view and help you make methodical changes based on your own personal N of 1 studies. As a health coach helping you improve sleep will be my top priority. If the DNA, Oura ring, and full lab panel don't fit in your budget, then we could start with just DNA and a smaller lab panel.
                                            </span>
                                        </Box>
                                        <Box mt={2}>
                                            <span className={classes.smallTitle}>{'Specific Medical Concern: '}</span>
                                            <span>
                                                We first look at the person as a whole by taking multiple data points into consideration. Although heartburn occurs in the stomach we must also make sure that gut biome, stress, sleep and all other factors are corrected. We view disease treatment and prevention from a holistic prospective and allow genetic predispositions to help guide our approach and interventions.  We'll look at you as whole person, not a collection of organ systems to fix. Then we'll identify what areas need to be optimized for the entire system to work flawlessly.  That will include an objective specific global optimization score calculated by our algorithm and we'll fix that first.  Once your optimized as a whole, the vast majority of specific complaints just get better.  If it doesn't, then we'll dive into specialty treatment for the issue. If the DNA and full lab panel don't fit in your budget, then we could start with just DNA and a smaller lab panel.
                                            </span>
                                        </Box>
                                    </Box>
                                </Box> :
                                <Box mt={5} mb={2}>
                                    <Box>
                                        <span className={classes.endSectionTitle}>For Precision and Optimization Plans Only</span>
                                    </Box>
                                    <Box display="flex" mt={3}>
                                        <Box display="flex">
                                            <Box p={1}>
                                                <span>
                                                    No
                                                </span>
                                            </Box>
                                            <Box ml={3}>
                                                <FormControlLabel
                                                    label=""
                                                    control={
                                                        <Switch
                                                            checked={state.isOrderedInitialLabsCollected}
                                                            onChange={handleIsOrderedInitialLabsCollectedChanged}
                                                            color="default"
                                                        />
                                                    }
                                                />
                                            </Box>
                                            <Box p={1}>
                                                <span>
                                                    Yes
                                                </span>
                                            </Box>
                                        </Box>
                                        <Box ml={5} p={1}>
                                            <span className="text-medium">Have you ordered or had your initial labs collected?</span>
                                        </Box>
                                    </Box>
                                    {
                                        state.isOrderedInitialLabsCollected ?
                                            <Box mt={3}>
                                                <span>
                                                    Since you've made the decision to obtain our recommended lab panel in the initial
                                                    phase, we will be able to give you the best picture of your health when combined
                                                    with your genetics.  After your genetics and labs have resulted we will call you
                                                    to schedule you for your first visit with your provider. Do you have any questions?
                                                </span>
                                            </Box> :
                                            <Box mt={3}>
                                                <span>
                                                    For both the precision care and optimization plans our clinicians are able to
                                                    combine labs with your genetics to get a full picture of how your genetics are
                                                    interacting with your day to day life. Our lab panel has multiple cholesterol,
                                                    hormone, and inflammatory markers that allow us to calculate risks and recommend
                                                    more precise interventions. By just utilizing your genetic information we can
                                                    still evaluate these risks but the combination of both is a much better picture
                                                    of your current health. We have negotiated with LabCorp to obtain the most
                                                    comprehensive panel at the cheapest price.
                                                </span>
                                            </Box>
                                    }
                                </Box>
                        }
                    </Box>
                </>
            </OptionalComponent>

            <Box py={10}>
                {
                    !state.isProcessing &&
                    <Box mt={5} pb={5} display='flex' alignItems='center' justifyContent="space-between">
                        <Box display="flex">
                            <Box>
                                <WildHealthButton
                                    width={195}
                                    size="large"
                                    style={{ whiteSpace: 'nowrap' }}
                                    disabled={isFutureAppointment(state.note) || state.note?.isCompleted || state.isProcessing || !isCanCompleteNote()}
                                    id='sing-physical-history'
                                    onClick={() => handleSaveAndComplete()}
                                >
                                    <span>Sign and Complete</span>
                                </WildHealthButton>
                            </Box>
                            <Box ml={2}>
                                <WildHealthButton
                                    width={151}
                                    style={{ whiteSpace: 'nowrap' }}
                                    size="large"
                                    disabled={state.note?.isCompleted || state.isProcessing}
                                    id='sing-physical-history'
                                    color="secondary"
                                    onClick={() => handleSaveAsDraft()}
                                >
                                    <span>Save as Draft</span>
                                </WildHealthButton>
                            </Box>
                            <FeatureComponent featureFlag={FeatureFlag.SignOffNotes}>
                                <Box ml={2}>
                                    <WildHealthButton
                                        width={151}
                                        style={{ whiteSpace: 'nowrap' }}
                                        size="large"
                                        disabled={state.note?.isCompleted || state.isProcessing || !state.note}
                                        id='sing-physical-history'
                                        color="secondary"
                                        onClick={() => handleSignOff()}
                                    >
                                        <span>Send for Approval</span>
                                    </WildHealthButton>
                                </Box>
                            </FeatureComponent>
                        </Box>
                        <Box>
                            <WildHealthButton
                                size="large"
                                width={111}
                                disabled={state.isProcessing || !canDiscardNote(stateContext.note)}
                                id='cancel-physical-history'
                                onClick={() => { handleDiscard() }}
                                color='tertiary'
                            >
                                Discard
                            </WildHealthButton>
                        </Box>
                    </Box>
                }
                {
                    state.isProcessing &&
                    <Box display="flex" justifyContent="center">
                        <Box>
                            <CircularProgress size={24} style={{ color: colors.main }} />
                        </Box>
                    </Box>
                }
                <SignOffDialogComponent />
            </Box>
        </>
    );


    const fillArrayWithNoteLogs = (array: NoteLogModel[], questionnaire: QuestionnaireResultModel, namesMap: KeyMap[]) => {
        namesMap.forEach(name => {
            const question = questionnaire.answers.find(a => a.key === name.Key1);
            if (question) {
                if (CheckManyToTextField.find(i => i === name.Key2)) {
                    const answer: CheckAnswer = JSON.parse(question.value);
                    if (answer) {
                        let value = answer.v.join('\n');
                        if (answer.o) {
                            value += `\n${answer.o}`;
                        }
                        array.push({ key: name.Key2, value: value });
                    }
                } else {
                    array.push({ key: name.Key2, value: question.value });
                }
            }
        });
    }

    const autoSave = () => {
        if (stateContext.isChanged && !stateContext.isAutoSaving && !state.isFirstSaveOriginalNote && isElementEnabled([PermissionType.Coaching, PermissionType.ManagePatients], UserType.Employee)) {
            if (!createInitialConsultComponentValidator.stateIsValid(stateContext)) {
                return;
            }

            setState(state => ({
                ...state,
                isAutoSaving: true
            }));

            notesService.saveAsDraft(getData(stateContext), patientsQuery.getTargetPatientIsPremium()).subscribe(note => {
                setState(state => ({
                    ...state,
                    note: {
                        ...state.note,
                        id: state?.note?.id ?? note.id,
                        title: note.title,
                        visitDate: note.visitDate,
                    },
                    isChanged: false,
                    isAutoSaving: false,
                }));
            })
        }
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            autoSaveTimer.subscribe(() => {
                autoSave()
            }),
            onEmit<PatientModel>(patientsQuery.targetPatient$, targetPatient => {
                if (targetPatient?.id === Number(patientId)) {
                    setState(state => ({
                        ...state,
                        isFreeTrial: targetPatient.subscription?.paymentPlan?.isTrial ?? false
                    }));
                }
            })
        ];

        if (note && !originalNote) {
            notesService.getContentByEmployee(note.id, patientId).subscribe(result =>
                setState(state => ({
                    ...state,
                    isLoading: false,
                    isNew: false,
                    recommendations: JSON.parse(result.content),
                    internalContent: result.internalContent,
                    logs: result.logs
                })));
        } else {

            setState(state => ({
                ...state,
                isAutoSaving: true
            }));

            if (originalNote) {
                notesService.getContentByEmployee(originalNote.id, patientId).subscribe(result => {
                    const content = JSON.parse(result.content);
                    setState(state => ({
                        ...state,
                        isNoteContentLoading: false,
                        recommendations: content,
                        internalContent: result.internalContent,
                        isNew: false,
                        logs: result.logs,
                        title: originalNote.title
                    }));

                    notesService.saveAsDraft(getSaveModelFromOriginalNote(content), patientsQuery.getTargetPatientIsPremium()).subscribe(
                        (note) => {
                            setState(state => ({
                                ...state,
                                note: note,
                                isAutoSaving: false,
                                isFirstSaveOriginalNote: false
                            }))
                        },
                        () => {
                            snackService.error('Note already exists for this appointment.');
                        }
                    );
                });
            }

            else {
                notesService.saveAsDraft(getData(state), patientsQuery.getTargetPatientIsPremium()).subscribe(
                    (note) => {
                        setState(state => ({
                            ...state,
                            note: note,
                            isAutoSaving: false
                        }))
                    },
                    () => {
                        snackService.error('Note already exists for this appointment.');
                    }
                );
            }


            questionnaireService.getLatestResults(patientId, [QuestionnaireType.HealthLog, QuestionnaireType.Initial]).subscribe(questionnaireResults => {
                const answers: NoteLogModel[] = [];

                if (questionnaireResults.length) {
                    const results = questionnaireResults
                        .filter(x => x.submittedAt)
                        .sort((r1, r2) => {
                            return new Date(r1.submittedAt).getTime() - new Date(r2.submittedAt).getTime();
                        });

                    const initial = results?.filter(q => q.questionnaire.type === QuestionnaireType.Initial)?.pop();
                    const healthLog = results?.filter(q => q.questionnaire.type === QuestionnaireType.HealthLog)?.pop();

                    if (initial) {
                        fillArrayWithNoteLogs(answers, initial, InitialConsultToInitialQuestionNamesMap);
                    }
                    if (healthLog) {
                        fillArrayWithNoteLogs(answers, healthLog, InitialConsultToHealthLogQuestionNamesMap);
                    }
                }

                setState(state => ({
                    ...state,
                    note: note,
                    logs: answers,
                    isLoading: false
                }));
            });
        }

        patientsService.get(patientId, DataSpecificationsEnum.PatientInitialConsultSpecification);

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, [patientId, note]);

    return [
        state,
        internalNotes,
        questionnaire,
        medicationSupplements,
        scriptsGoal,
        recommendationsHeader,
        recommendationsWithScripts,
        endOfTemplate,
        noteName,
        titleTextBox
    ];
}
