import MenuIcon from '@img/icons/Menu.svg';
import { Box, Drawer, Grid, IconButton, List } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useHistory } from 'react-router';
import { MenuItemTypes } from "../../models/menu.models";
import { WildHealthLinkButton } from "../../modules/common/components/WildHealthLinkButton";
import { logoService } from '../../services/logo.service';
import { navigationService } from '../../services/navigation.service';
import { useFacade } from './sideBarComponent.hooks';
import { useStyles } from './sideBarComponent.styles';

interface SideBarComponentProps {
    isOpen: boolean;
    selectedMenuItem: MenuItemTypes,
    topBannerHeight?: number
}

export const SideBarComponent: React.FC<SideBarComponentProps> = (props: SideBarComponentProps) => {
    const {
        isOpen,
        selectedMenuItem,
        topBannerHeight = 0
    } = props;

    const history = useHistory();
    const classes = useStyles({ topBannerHeight });

    const [
        handleToggle,
        renderMenuItems
    ] = useFacade(isOpen, selectedMenuItem, classes);

    return (
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: isOpen,
                [classes.drawerClose]: !isOpen,
            })}
            classes={{
                paper: clsx(classes.drawerPaper, {
                    [classes.drawerOpen]: isOpen,
                    [classes.drawerClose]: !isOpen,
                })
            }}
        >
            <Box display='flex' justifyContent='space-between' alignItems='center' mx={3} my={1.5}>
                <Box>
                    <IconButton id="side-bar-menu-toggle" onClick={() => handleToggle()}>
                        <img src={MenuIcon} alt="Menu Icon" />
                    </IconButton>
                </Box>
                {
                    isOpen && [
                        <Box key='link'>
                            <WildHealthLinkButton
                                id="side-bar-link-dashboard"
                                onClick={() => navigationService.toDashboard(history)}
                                content={<img className={classes.logo}
                                    src={logoService.getLogo().primary}
                                    alt="img" />}
                            />
                        </Box>,
                        <Box key='space' />
                    ]
                }
            </Box>

            <Grid
                className={clsx(classes.content, 'flat-scroll')}
                container
                direction="column"
                justify="space-between"
                alignItems="stretch"
            >
                <Grid item>
                    <List className={classes.menuList}>
                        {
                            renderMenuItems()
                        }
                    </List>
                </Grid>
            </Grid>
        </Drawer>
    )
}
