import React from "react";
import commonUseStyles from "../../../common/styles/common.styles";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import {WildHealthPlaceHolder} from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import {
    Box, Checkbox, FormControlLabel, FormGroup,
    IconButton, MenuItem, Popover,
    Table,
    TableBody,
    TableContainer,
    TableFooter,
    TableHead,
    TableSortLabel
} from "@material-ui/core";
import {WildHealthTableRow} from "../../../common/components/wildHealthTable/WildHealthTableRow";
import {WildHealthTableCell} from "../../../common/components/wildHealthTable/WildHealthTableCell";
import {SortingDirection} from "../../../common/sorting/models/sorting-destination";
import moment from "moment";
import {authQuery} from "../../../auth/stores/auth";
import {PaginationComponent} from "../../../common/pagination/components/paginationComponent/PaginationComponent";
import {pageSizes} from "../../../common/pagination/models/page-sizes";
import {noteTypeNames} from "../../constants/noteTypeNames";
import _ from "lodash";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import {WildHealthMenuItem} from "../../../common/components/wildHealthMenu/WildHealthMenu";
import LaunchIcon from '@material-ui/icons/Launch';
import DeleteIcon from '@material-ui/icons/Delete';
import ReplyIcon from '@material-ui/icons/Reply';
import {AdditionalNoteComponent} from "../additionalNoteComponent/AdditionalNoteComponent";
import {SignOffDialogComponent} from "../signOffDIalogComponent/SignOffDialogComponent";
import {useFacade} from "./SignOffNotesRequestsComponent.hooks";
import {reviewableNoteTypes} from "../../constants/reviewableNoteTypes";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import {colors} from "../../../common/constants/colors";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {ReactComponent as SortIcon} from '@img/icons/sort.svg';

export const SignOffNotesRequestsComponent: React.FC = () => {
    const [{
        isLoading,
        notes,
        noteTypes,
        totalCount,
        sortingColumns,
        sortingSource,
        pageSize,
        selectedPage,
        isMenuOpened,
        menuAnchorEl,
        isFilterOpened,
        filterAnchorEl
    },
        handleSorting,
        handlePageSizeChange,
        getAllAvailablePages,
        handlePageChange,
        handleViewDetails,
        handleViewPatient,
        handleSignOff,
        cancelSignOff,
        handleToggleActions,
        handleToggleFilters,
        handleFilterChanges
    ] = useFacade();

    const commonClasses = commonUseStyles();

    const renderTableBody = () => {
        return (
            <>
                <TableBody>
                    {
                        notes.map((note, index) =>
                            <WildHealthTableRow key={index} >
                                <WildHealthTableCell onClick={()=> handleViewPatient(note)} className={commonClasses.pointer}>
                                    {`${note.patient?.firstName} ${note.patient?.lastName}`}
                                </WildHealthTableCell>
                                <WildHealthTableCell>
                                    {moment(note.assignedAt).format('L')}
                                </WildHealthTableCell>
                                <WildHealthTableCell>
                                    <a onClick={() => handleViewDetails(note)} className={commonClasses.btnLink}>
                                        <Box display="flex" alignItems="center">
                                            <Box>
                                                {noteTypeNames.get(note.type)}
                                            </Box>
                                            <Box ml={1}>
                                                <LaunchIcon/>
                                            </Box>
                                        </Box>
                                    </a>

                                </WildHealthTableCell>
                                <WildHealthTableCell>
                                    <AdditionalNoteComponent note={note}/>
                                </WildHealthTableCell>
                                <WildHealthTableCell>
                                    {`${note.assignedTo?.firstName} ${note.assignedTo?.lastName}`}
                                </WildHealthTableCell>
                                <WildHealthTableCell>
                                    <IconButton
                                        id={`notes-more-actions-${note.id}`}
                                        size="small"
                                        onClick={(e) => handleToggleActions(note.id, e.currentTarget)}
                                    >
                                        <MoreHorizIcon/>
                                    </IconButton>
                                </WildHealthTableCell>
                            </WildHealthTableRow>
                        )
                    }
                </TableBody>
                <TableFooter>
                    <WildHealthTableRow style={{backgroundColor: "white"}}>
                        <WildHealthTableCell colSpan={authQuery.isLicensingPractice() ? 7 : 9}>
                            <PaginationComponent
                                pageSizes={pageSizes}
                                pageSize={pageSize}
                                selectedPage={selectedPage}
                                handlePageSizeChange={handlePageSizeChange}
                                totalCount={totalCount}
                                availablePages={getAllAvailablePages()}
                                handlePageSelect={handlePageChange}/>
                        </WildHealthTableCell>
                    </WildHealthTableRow>
                </TableFooter>
            </>
        )
    }

    const renderTable = () => {
        if (isLoading) {
            return <WildHealthLinearProgress/>
        }

        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <WildHealthTableRow>
                            <WildHealthTableCell style={{width: '20%'}} key={'patientName'}>
                                Patient Name
                            </WildHealthTableCell>
                            {
                                sortingColumns.map((item, index) =>
                                    <WildHealthTableCell style={{width: '20%'}} key={index}>
                                        {item.source
                                            ? <TableSortLabel
                                                id={`manage-all-notes-${item.title}`}
                                                active={sortingSource === item.source}
                                                direction={sortingSource === item.source ? item.direction : SortingDirection.Asc}
                                                onClick={() => handleSorting(item.source, item.direction)}
                                            >
                                                {item.title}
                                            </TableSortLabel>
                                            : item.title
                                        }
                                    </WildHealthTableCell>
                                )
                            }
                            <WildHealthTableCell style={{width: '10%'}} key={'additionalNote'}>
                                <Box display="flex" alignItems="center">
                                    <Box>
                                        Report Link
                                    </Box>
                                    <Box>
                                        <IconButton onClick={(e) => handleToggleFilters(e.currentTarget)}>
                                            <SortIcon/>
                                        </IconButton>
                                    </Box>
                                </Box>
                            </WildHealthTableCell>
                            <WildHealthTableCell style={{width: '10%'}} key={'additionalNote'}>
                                Additional Note
                            </WildHealthTableCell>
                            <WildHealthTableCell style={{width: '20%'}}>
                                User Reviewing Note
                            </WildHealthTableCell>
                            <WildHealthTableCell style={{width: '10%'}}>
                                Action
                            </WildHealthTableCell>
                        </WildHealthTableRow>
                    </TableHead>
                    {
                        _.size(notes) > 0 && renderTableBody()
                    }
                </Table>

                {
                    _.size(notes) === 0 && <WildHealthPlaceHolder message="No current records found"/>
                }

                <Popover
                    keepMounted
                    anchorEl={menuAnchorEl}
                    open={isMenuOpened}
                    onClose={() => handleToggleActions()}
                    getContentAnchorEl={null}
                >
                    <WildHealthMenuItem id="note-transfer" onClick={() => handleSignOff()}>
                        <ReplyIcon style={{transform: 'scaleX(-1)'}}/> <Box ml={1}>Transfer Request</Box>
                    </WildHealthMenuItem>
                    <WildHealthMenuItem id="note-cancel" onClick={() => cancelSignOff()}>
                        <DeleteIcon/> <Box ml={1}>Cancel Request</Box>
                    </WildHealthMenuItem>
                </Popover>

                <Popover
                    keepMounted
                    anchorEl={filterAnchorEl}
                    open={isFilterOpened}
                    onClose={() => handleToggleFilters()}
                    getContentAnchorEl={null}
                >
                    {
                        reviewableNoteTypes.map((type, index) => {
                            const label = <Box component="div">{noteTypeNames.get(type)}</Box>;
                            const controlIcon = <CheckBoxOutlineBlankIcon style={{fill: colors.main}}/>;
                            const controlCheckedIcon = <CheckBoxIcon style={{fill: colors.main}}/>;
                            const control = (
                                <Checkbox
                                    icon={controlIcon}
                                    color="primary"
                                    checkedIcon={controlCheckedIcon}
                                    name={`note-type-${index}-check-box`}
                                    checked={(noteTypes ?? []).includes(type)}
                                    onChange={() => {
                                        handleFilterChanges(type)
                                    }}
                                />
                            );

                            return (
                                <MenuItem key={index} value={type}>
                                    <FormGroup>
                                        <FormControlLabel control={control} label={label}/>
                                    </FormGroup>
                                </MenuItem>
                            )
                        })
                    }
                </Popover>

            </TableContainer>
        )
    }

    return (
        <Box p={5}>
            {
                renderTable()
            }
            <SignOffDialogComponent/>
        </Box>
    )
}