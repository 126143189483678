import { Box, Checkbox, TextField } from '@material-ui/core';
import React from 'react';
import { Add } from '@material-ui/icons';
import { useStyles } from './recommendationSingleSelectComponent.styles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { colors } from '../../../common/constants/colors';
import { getFullRecommendationContentNew, HealthReportMode, RecommendationDisplayModel, RecommendationModel, recommendationRowsCapacity } from '../../models/healthReport.models';
import clsx from 'clsx';
import { WildHealthPlaceHolder } from '../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder';
import { createTextWithLinks } from '../../../common/helpers/create-text-with-links';
import { shortcutView } from '../../../common/helpers/view-text-with-link';
import { authQuery } from '../../../auth/stores/auth';
import { UserType } from '../../../auth/models/auth.enums';
import { selectShortcutService } from '../../services/selectShortcut.service';
import { insertString } from '../../../common/helpers/insert-string';
import { RecommendationPlaceHolder } from '../../../common/components/wildHealthPlaceHolder/RecommendationPlaceHolder';
import DOMPurify from 'dompurify'
import {sanitizeRecommendationContent} from "../../helpers/sanitizeRecommendationContent";

export interface RecommendationSingleSelectComponentProps {
    mode: HealthReportMode,
    currentRecommendation: RecommendationDisplayModel[],
    recommendation?: RecommendationModel,
    type: number,
    handleSelected: (id: number | string, isSelected: boolean) => void,
    handleChanges: (id: number | string, value: string) => void,
    handleAddRecommendation: (id: number, index: number) => void,
    isGeneralPage?: boolean;

}

export const RecommendationSingleSelectComponent: React.FC<RecommendationSingleSelectComponentProps> = (props: RecommendationSingleSelectComponentProps) => {
    const {
        currentRecommendation,
        recommendation,
        mode,
        type,
        handleSelected,
        handleChanges,
        handleAddRecommendation,
        isGeneralPage = false
    } = props;

    const classes = useStyles();
    const controlIcon = <CheckBoxOutlineBlankIcon style={{ fill: colors.mediumGray }} />;
    const controlCheckedIcon = <CheckBoxIcon style={{ fill: colors.main }} />

    const renderPlaceholder = () => {
        return isGeneralPage
            ? <Box display="flex" alignItems="center" justifyContent="center">
                <RecommendationPlaceHolder message="No Recommendations" />
            </Box>
            : <Box display="flex" alignItems="center" justifyContent="center">
                <WildHealthPlaceHolder height={`${(recommendationRowsCapacity[type] - 1) * 30}px`} message={"No recommendations provided"} />
            </Box>
    }

    const renderEditableRecommendation = () => {
        return (
            <Box maxWidth="1100px">
                <Box maxHeight={isGeneralPage ? "" : `${(recommendationRowsCapacity[type] - 1) * 30}px`} className={clsx(classes.container, 'flat-scroll')}>
                    {currentRecommendation.map(el =>
                        <Box display="flex" mb={1.5} key={`${el.type}-${el.id}`}>
                            <Box>
                                <Checkbox
                                    icon={controlIcon}
                                    color="primary"
                                    checkedIcon={controlCheckedIcon}
                                    checked={el.isSelected}
                                    onChange={() => handleSelected(el.id, !el.isSelected)}
                                />
                            </Box>
                            <TextField
                                id={`recommendation-text-area-${el.type}-${el.id}`}
                                fullWidth
                                multiline
                                disabled={!el.isSelected}
                                className={el.isSelected ? "" : classes.textFiel}
                                variant="outlined"
                                value={sanitizeRecommendationContent(el.content)}
                                onKeyDown={(e) => { handleOnKeyDown(el.id, e, `recommendation-text-area-${el.type}-${el.id}`) }}
                                onChange={(v) => {
                                    handleChanges(el.id, v.target.value)
                                }}
                            />
                        </Box>

                    )}
                    {currentRecommendation.length === 0 && renderPlaceholder()}
                </Box>

                <Box mt={1} pl="10px" display='flex' alignItems='stretch'>
                    <Box onClick={() => handleAddRecommendation(type, currentRecommendation.length)
                    }>
                        <Add className={classes.addIcon} />
                    </Box>
                    <Box className={classes.addText} ml={2}>
                        <span>Add Recommendation</span>
                    </Box>
                </Box>

            </Box>
        )
    }


    const renderReadonlyRecommendation = () => {
        if ((!currentRecommendation?.length)) {
            return renderPlaceholder();
        }

        const recommendationContent = getFullRecommendationContentNew(currentRecommendation);

        return (
            recommendationContent && recommendationContent.match(/[^\r\n]+/g) &&
            <Box className={clsx(classes.recommendation, classes.readonly)} height={`${recommendationRowsCapacity[type] * 30}px`}>
                {
                    recommendationContent.match(/[^\r\n]+/g)?.map((item, index) =>
                        <div key={index} className={classes.textWrap} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(`${createTextWithLinks(shortcutView(item))}`) }} />
                    )
                }
            </Box>
        )
    }


    const renderPrintableRecommendation = () => {
        if (!recommendation || !recommendation?.printableContent?.length) {
            return renderPlaceholder();
        }

        return (
            <div className={classes.printable} style={{ height: `${recommendationRowsCapacity[type] * 30}px` }}>
                {recommendation.printableContent ?? ''}
            </div>
        )
    }

    const handleOnKeyDown = (id: string | number, event: any, idFild: string) => {
        if (event.keyCode === 51 && event.altKey) {
            const userType = authQuery.getType();
            if (UserType.Employee === userType) {
                const value = (document.getElementById(idFild) as any).value;
                const cursorPos = (document.getElementById(idFild) as any).selectionStart;
                setTimeout(() => {
                    selectShortcutService.select().subscribe((data) => {
                        data && handleChanges(id, insertString(value, data, cursorPos + 1))
                    })
                }, 1)
            }
        }
    }

    const renderRecommendation = (mode: HealthReportMode) => {
        switch (mode) {
            case HealthReportMode.EditMode: return renderEditableRecommendation();
            case HealthReportMode.ReadonlyMode: return renderReadonlyRecommendation();
            case HealthReportMode.PrintMode: return renderPrintableRecommendation();
            default: return renderReadonlyRecommendation();
        }
    }

    return <>{renderRecommendation(mode)}</>;
}