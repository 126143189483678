import { SortingColumn } from "./sorting-column";

export interface ISortingState {
    sortingColumns: SortingColumn[];
    sortingSource: string;
    activeSource?: string;
}

export const stateList = [
    {
        name: 'AL',
        isSelected: false,
    },
    {
        name: 'AK',
        isSelected: false,
    },
    {
        name: 'AZ',
        isSelected: false,
    },
    {
        name: 'AR',
        isSelected: false,
    },
    {
        name: 'CA',
        isSelected: false,
    },
    {
        name: 'CO',
        isSelected: false,
    },
    {
        name: 'CT',
        isSelected: false,
    },
    {
        name: 'DE',
        isSelected: false,
    },
    {
        name: 'FL',
        isSelected: false,
    },
    {
        name: 'GA',
        isSelected: false,
    },
    {
        name: 'HI',
        isSelected: false,
    },
    {
        name: 'ID',
        isSelected: false,
    },
    {
        name: 'IL',
        isSelected: false,
    },
    {
        name: 'IN',
        isSelected: false,
    },
    {
        name: 'IA',
        isSelected: false,
    },
    {
        name: 'KS',
        isSelected: false,
    },
    {
        name: 'KY',
        isSelected: false,
    },
    {
        name: 'LA',
        isSelected: false,
    },
    {
        name: 'ME',
        isSelected: false,
    },
    {
        name: 'MD',
        isSelected: false,
    },
    {
        name: 'MA',
        isSelected: false,
    },
    {
        name: 'MI',
        isSelected: false,
    },
    {
        name: 'MN',
        isSelected: false,
    },
    {
        name: 'MS',
        isSelected: false,
    },
    {
        name: 'MO',
        isSelected: false,
    },
    {
        name: 'MT',
        isSelected: false,
    },
    {
        name: 'NE',
        isSelected: false,
    },
    {
        name: 'NV',
        isSelected: false,
    },
    {
        name: 'NH',
        isSelected: false,
    },
    {
        name: 'NJ',
        isSelected: false,
    },
    {
        name: 'NM',
        isSelected: false,
    },
    {
        name: 'NY',
        isSelected: false,
    },
    {
        name: 'NC',
        isSelected: false,
    },
    {
        name: 'ND',
        isSelected: false,
    },
    {
        name: 'OH',
        isSelected: false,
    },
    {
        name: 'OK',
        isSelected: false,
    },
    {
        name: 'OR',
        isSelected: false,
    },
    {
        name: 'PA',
        isSelected: false,
    },
    {
        name: 'RI',
        isSelected: false,
    },
    {
        name: 'SC',
        isSelected: false,
    },
    {
        name: 'SD',
        isSelected: false,
    },
    {
        name: 'TN',
        isSelected: false,
    },
    {
        name: 'TX',
        isSelected: false,
    },
    {
        name: 'UT',
        isSelected: false,
    },
    {
        name: 'VT',
        isSelected: false,
    },
    {
        name: 'VA',
        isSelected: false,
    },
    {
        name: 'WA',
        isSelected: false,
    },
    {
        name: 'WV',
        isSelected: false,
    },
    {
        name: 'WI',
        isSelected: false,
    },
    {
        name: 'WY',
        isSelected: false,
    },
]