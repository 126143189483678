import { createStyles, makeStyles, StepIconProps } from "@material-ui/core";
import React from "react";
import { ReactComponent as Active } from '@img/stepperIcons/stepActive.svg';
import { ReactComponent as Inactive } from '@img/stepperIcons/stepInactive.svg';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { colors } from "../../constants/colors";


const useStyles = makeStyles(() =>
    createStyles({
        icon: {
            fill: colors.main,
        }
    })
)

export function WildHealthStepIcon(props: StepIconProps) {
    const { active, completed } = props;

    const classes = useStyles();

    return (
        completed
            ? <CheckCircleIcon className={classes.icon} />
            : active ?
                <Active />
                : <Inactive />
    )
}