import {useEffect, useState} from "react";
import {Subscription} from "rxjs";
import {onEmit} from "../../../common/helpers/on-emit";
import {IErrorState} from "../../../common/validation/error-state";
import {newPatientSupplementModel, PatientSupplementModel, SupplementSource} from "../../models/patientSupplementModel";
import {patientsSupplementsService} from "../../services/patientSupplements.service";
import {supplementsService} from "../../services/supplements.service";
import {patientSupplementsQuery} from "../../stores/patientSupplementsStore/patientSupplements.query";
import {PatientSupplementsUpdateProps} from "./PatientSupplementsUpdateFullscriptComponent";
import {
    patientSupplementsUpdateFullscriptComponentValidator
} from "./patientSupplementsUpdateFullscriptComponent.validator";
import {FullscriptSupplementModel} from "../../models/fullscriptSupplementModels";

interface PatientSupplementsUpdateFullscriptComponentState extends IErrorState {
    model: PatientSupplementModel;
    isFullscript: boolean;
    isLoading: boolean;
}

const getInitialState = (source: SupplementSource, isFullscript: boolean): PatientSupplementsUpdateFullscriptComponentState => ({
    model: newPatientSupplementModel(source),
    isLoading: false,
    isFullscript: isFullscript,
    errors: {}
});

export function useFacade(props: PatientSupplementsUpdateProps): [
    PatientSupplementsUpdateFullscriptComponentState,
    Function,
    Function,
    Function,
    Function,
    Function
] {
    const [state, setState] = useState(getInitialState(props.source, props.fullscript));

    const handleSelect = (supplement: FullscriptSupplementModel) => {
        if (supplement) {
            setState(state => ({
                ...state,
                model: {
                    ...state.model,
                    name: supplement.name + ' (' + supplement.brand.name + ')'
                }
            }))
        }
    }

    const handleChanges = (field: string, value: string) => {
        patientSupplementsUpdateFullscriptComponentValidator.validateAndSetState(state, setState, field, value);

        const model = { ...state.model };
        model[field] = value
        setState({ ...state, model: model })
    }

    const handleSave = (patientId: number) => {
        patientSupplementsUpdateFullscriptComponentValidator.validateObjectAndSetState(state, setState, state.model);
        if (!patientSupplementsUpdateFullscriptComponentValidator.stateIsValid(state)) {
            return;
        }

        setState(state => ({
            ...state,
            ...Object.assign({}, getInitialState(props.source, props.fullscript))
        }));

        props.handleClose();

        const service = props.patientId ? supplementsService : patientsSupplementsService;
        const action = state.model.id ? service.edit : service.create;

        state.model.source = props.source;

        action(state.model, patientId).subscribe();
    }

    const handleClose = () => {
        setState(state => ({
            ...state,
            ...getInitialState(props.source, props.fullscript)
        }))

        props.handleClose();
    }

    const toggleFullscript = () => {
        setState(state => ({
            ...state,
            isFullscript: !state.isFullscript
        }));
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<PatientSupplementModel>(patientSupplementsQuery.editModel$, editModel => {
                setState(state => ({ ...state, model: editModel }));
                if (!Boolean(editModel.id)) {
                    setState(state => ({ ...state, isLoading: true }))
                    supplementsService.getDefaultLink().subscribe(link => {
                        setState(state => ({ ...state, model: { ...state.model, purchaseLink: link }, isLoading: false }))
                    })
                }
            })
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [
        state,
        handleSelect,
        handleClose,
        handleChanges,
        handleSave,
        toggleFullscript,
    ];
}