import { Box, Grid } from '@material-ui/core';
import React from 'react';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { CommonPlaceHolder } from '../../../common/components/wildHealthPlaceHolder/CommonPlaceHolder';
import { MicrobiomeInputNames } from '../../constants/inputs.constants';
import { WildHealthTextField } from '../inputComponent/inputComponent.styles';
import { useFacade } from "./patientMicrobiomeInputsComponent.hooks";
import { useStyles } from "./patientMicrobiomeInputsComponent.styles";

export const PatientMicrobiomeInputsComponent: React.FC = () => {
    const classes = useStyles();
    const [
        {
            isLoading,
            inputs,
        }
    ] = useFacade();

    const inflammation = inputs.find(x => x.name === MicrobiomeInputNames.InflammationScore);
    const diversity = inputs.find(x => x.name === MicrobiomeInputNames.DiversityScore);

    const renderContent = () => {
        if (isLoading) {
            return <WildHealthLinearProgress/>
        }

        if (!inputs || !inputs.length) {
            return <CommonPlaceHolder message='No microbiome data uploaded.' />;
        }

        return (
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
                className={classes.content}
            >
                <Grid item>
                    <Box m={1}>
                        <Box className={classes.label}>
                            <span>Inflammation Score</span>
                        </Box>
                        <Box>
                            <WildHealthTextField
                                value={inflammation.value}
                                disabled
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item>
                    <Box m={1}>
                        <Box className={classes.label}>
                            <span>Diversity Score</span>
                        </Box>
                        <Box>
                            <WildHealthTextField
                                value={diversity.value}
                                disabled
                            />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        )
    }

    return (
        <Box className={classes.root}>
            <Box>
                <span className="size-1_25 text-medium">Microbiome</span>
            </Box>
            <Box mt={2}>
                {renderContent()}
            </Box>
        </Box>
    );
}