import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { confirmService } from '../../../../services/confirm.service';
import { ColumnViewModel } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { displayMoney } from "../../../common/helpers/display-money";
import { onEmit } from "../../../common/helpers/on-emit";
import { LabOrderModel } from "../../models/labOrders.models";
import { labOrdersService } from "../../services/labOrders.service";
import { labOrdersQuery } from "../../stores/labOrdersStore";

interface LabOrderPlacedComponentState {
    isLoading: boolean;
    order: LabOrderModel;
    total: number;
    isPaying: boolean;
}

export function useFacade(patientId: number, orderId: number, handleNavigateToOrders: () => void): [LabOrderPlacedComponentState, Array<ColumnViewModel>, (orderId: number) => void] {
    const [state, setState] = useState({
        isLoading: true,
        order: null,
        total: 0,
        isPaying: false,
    } as LabOrderPlacedComponentState);

    const columns: ColumnViewModel[] = [
        { title: 'Test Code', alignment: 'left' },
        { title: 'Description', alignment: 'left' },
        { title: 'Price', alignment: 'right' }
    ];

    const handleProcessCharge = (orderId: number) => {
        const cb = () => {
            setState(state => ({
                ...state,
                isPaying: false
            }));
            handleNavigateToOrders();
        }

        confirmService.confirm('Are you sure?', `This will charge the patient's payment source ${displayMoney(state.total, '$')}. Would you like to proceed?`).subscribe(
            () => {
                setState(state => ({
                    ...state,
                    isPaying: true
                }));

                labOrdersService.pay(orderId).subscribe(cb, cb)
            }
        );
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<LabOrderModel[]>(labOrdersQuery.orders$, labs => {
                if (!labs || !labs.length) {
                    return;
                }

                const order = labs.find(i => i.id === orderId);
                if (!order) {
                    return;
                }

                let total = 0;

                order.items.forEach(item => total += item.price);

                setState(state => ({
                    ...state,
                    order: order,
                    total: total,
                    isLoading: false,
                }));
            })
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [orderId]);

    return [state, columns, handleProcessCharge];
}