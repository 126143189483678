import {useEffect, useState} from "react";
import {AppointmentsStatisticModel} from "../../models/appointmentsStatistic.models";
import {Subscription} from "rxjs";
import {onEmit} from "../../../common/helpers/on-emit";
import {appointmentsQuery} from "../../stores/appointments";

interface AppointmentsStatisticComponentState {
    statistic: AppointmentsStatisticModel;
}

export function useFacade(employeeId: number): [AppointmentsStatisticComponentState] {
    const [state, setState] = useState({
        statistic: null
    } as AppointmentsStatisticComponentState);

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<AppointmentsStatisticModel>(appointmentsQuery.appointmentsStatistic$, statistic => {
                if(statistic && statistic.employeeId === employeeId) {
                    setState(state => ({
                        ...state,
                        statistic: statistic
                    }));
                }
            })
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [employeeId]);

    return [state];
}