import { GeneralValidator } from "../../../common/validation/general-validator";
import {
  notWhitespaceValidationRule,
  requiredValidationRule,
  notNegativeValidationRule
} from "../../../common/validation/general-validation-rules";

export const createPromoCodeComponentValidator = new GeneralValidator({
  'code': [notWhitespaceValidationRule(), requiredValidationRule()],
  'discount': [notWhitespaceValidationRule(), notNegativeValidationRule("Discount amount can not be negative")],
});