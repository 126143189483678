import icon from "@img/HealthReport_Pg22_Inflammation.png";
import { Box, Divider, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { LabInputNames } from "../../inputs/constants/inputs.constants";
import { HealthRecommendationComponent } from '../components/healthRecommendationComponent/HealthRecommendationComponent';
import { LabResultComponent } from "../components/labResultComponent/LabResultComponent";
import { LabelPosition, ProgressDiagramComponent } from "../components/progressDiagramComponent/ProgressDiagramComponent";
import { HealthReportMode, HealthReportSections, HealthReportVersion, recommendationRowsCapacity, RecommendationTypesNumbers } from '../models/healthReport.models';
import { HealthReportBasePage, HealthReportMobileBasePage } from "./HealthReportBasePage";
import { useStyles } from './healthReport.styles';
import { HealthReportPageProps } from "./healthReportPageProps";
import commonUseStyles from "../../common/styles/common.styles";
import clsx from "clsx";
import { WildHealthButton } from "../../common/components/wildHealthButton/WildHealthButton";
import { RecommendationSingleSelectComponent } from "../components/recommendationSingleSelectComponent/RecommendationSingleSelectComponent";

export const HealthReportChronicDisease5Page: React.FC<HealthReportPageProps> = (props: HealthReportPageProps) => {
    const {
        report,
        handleChanges,
        handleSaveChanges,
        isChanged,
        mode,
        pageNumber,
        practice,
        recommendations,
        handleSelected,
        handleChangesNewRecommendation,
        handleAddRecommendation,
        handleSaveChangesNew,
    } = props;
    const commonClasses = commonUseStyles();
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const labValues = report.labReport.values;

    return (
        props.mode !== HealthReportMode.PrintMode && isSmallScreen ? (
            <HealthReportMobileBasePage section={HealthReportSections.ChronicDisease} pageNumber={pageNumber} practice={practice}>
                <>
                    <Box px={2}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box>
                                    <Box>
                                        <span className={classes.reportTitle}>Inflammation</span>
                                    </Box>
                                    <Box mt="10px">
                                        <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeMediumReport, commonClasses.textRegularReport)}>Your Genetics and Labs</span>
                                    </Box>
                                    <Box mt="12px" fontSize="14px" lineHeight="22px">
                                        <p>Inflammation kills.</p>
                                        <p>
                                            Chronic low grade inflammation is at the root of cardiovascular disease,
                                            Alzheimer's, cancer, and autoimmune disease. Chronic inflammation can be
                                            treated with supplements, an anti-inflammatory diet, sleep, and healthy
                                            exercise, but the most important action is to identify the source.
                                        </p>
                                        <p>Some common sources include:</p>
                                        <p>1. Microbiome - “leaky gut”</p>
                                        <p>2. Overtraining - too much exercise, not enough recovery</p>
                                        <p>3. Inflammatory diet</p>
                                        <p>4. Poor sleep</p>
                                        <p>5. Insulin resistance</p>
                                        <p>6. High cholesterol</p>
                                        <p>7. Chronic infections</p>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box mt="10px" display="flex" justifyContent="center">
                                    <img src={icon} style={{ width: '60px', height: 'auto' }} alt="img" />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider className="report-divider" />
                    <Box px={2}>
                        <Box>
                            <span className={classes.reportTitle}>CRP = {labValues.getLabReportValue(LabInputNames.Crp)?.value}</span>
                        </Box>
                        <Box display="flex" justifyContent="space-between" mt="20px" lineHeight="22px">
                            <Box>
                                <span className={classes.reportSubTitle}>Low<br/>Inflammation</span>
                            </Box>
                            <Box textAlign="right">
                                <span className={classes.reportSubTitle}>High<br/>Inflammation</span>
                            </Box>
                        </Box>
                        <Box mt={2}>
                            <ProgressDiagramComponent
                                height={22}
                                colors={['#65704f', '#d60001']}
                                data={[
                                    {
                                        label: '',
                                        value: report.longevityReport.inflammationRiskScore.scorePercent,
                                        labelPosition: LabelPosition.Bottom
                                    }
                                ]}
                                unavailable={!report.longevityReport.inflammationRiskScore.isCalculated}
                            />
                        </Box>
                        <Box display="flex" justifyContent="space-between" mt={0.5} >
                            <Box>
                                <span className="text-accent text-medium">0</span>
                            </Box>
                            <Box>
                                <span className="text-accent text-medium">5.0</span>
                            </Box>
                        </Box>
                        <Box mt="20px">
                            <Box className="text-light-medium">
                                <Box>
                                    <span className={classes.reportSubTitle}>Inflammation Labs</span>
                                </Box>
                                <Box mt={1}>
                                    <LabResultComponent label="CRP" value={labValues.getLabReportValue(LabInputNames.Crp)} />
                                    <LabResultComponent label="A/G ratio" value={labValues.getLabReportValue(LabInputNames.AgRatio)} />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Divider className="report-divider" />
                    <Box px={2}>
                        <Box>
                            <Box>
                                <span className={classes.reportTitle}>Your Inflammation Recommendations</span>
                                <Box display="flex" justifyContent="flex-end" width={1}>
                                    {
                                        parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation) && HealthReportMode.EditMode === mode && <WildHealthButton
                                            id="health-recommendation-new-save"
                                            onClick={() => handleSaveChangesNew()}
                                            disabled={!isChanged}>
                                            <Box >
                                                Save changes
                                            </Box>
                                        </WildHealthButton>
                                    }
                                </Box>
                            </Box>
                            <Box mt="10px">
                            {parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
                                ? <Box>
                                    <RecommendationSingleSelectComponent
                                        currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.Inflammation)}
                                        recommendation={report.longevityReport.inflammationRecommendation}
                                        type={RecommendationTypesNumbers.Inflammation}
                                        handleSelected={handleSelected}
                                        handleChanges={handleChangesNewRecommendation}
                                        handleAddRecommendation={handleAddRecommendation}
                                        mode={mode}
                                    />
                                </Box>
                                : <HealthRecommendationComponent
                                    mode={mode}
                                    rows={recommendationRowsCapacity[RecommendationTypesNumbers.Inflammation]}
                                    recommendation={report.longevityReport.inflammationRecommendation}
                                    handleContentChanges={!handleChanges ? handleChanges : (value) => {
                                        handleChanges('longevityReport.inflammationRecommendation.content', value)
                                    }}
                                    handleManualContentChanges={!handleChanges ? handleChanges : (value) => {
                                        handleChanges('longevityReport.inflammationRecommendation.manualContent', value)
                                    }}
                                    handleSaveChanges={handleSaveChanges}
                                    isChanged={isChanged} />
                                }
                            </Box>
                        </Box>
                    </Box>
                </>
            </HealthReportMobileBasePage>
        ) : (
            <HealthReportBasePage section={HealthReportSections.ChronicDisease} pageNumber={pageNumber} practice={practice}>
                <>
                    <Box px={15} pt={5}>
                        <Grid container>
                            <Grid item xs={9}>
                                <Box>
                                    <Box>
                                        <span className="report-title">Inflammation</span>
                                    </Box>
                                    <Box>
                                        <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeMediumReport, commonClasses.textMediumReport)}>Your Genetics and Labs</span>
                                    </Box>
                                    <Box>
                                        <p>Inflammation kills.</p>
                                        <p>
                                            Chronic low grade inflammation is at the root of cardiovascular disease,
                                            Alzheimer's, cancer, and autoimmune disease. Chronic inflammation can be
                                            treated with supplements, an anti-inflammatory diet, sleep, and healthy
                                            exercise, but the most important action is to identify the source.
                                        </p>
                                        <p>Some common sources include:</p>
                                        <p>1. Microbiome - “leaky gut”</p>
                                        <p>2. Overtraining - too much exercise, not enough recovery</p>
                                        <p>3. Inflammatory diet</p>
                                        <p>4. Poor sleep</p>
                                        <p>5. Insulin resistance</p>
                                        <p>6. High cholesterol</p>
                                        <p>7. Chronic infections</p>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={3}>
                                <Box pt={2} px={10}>
                                    <img src={icon} style={{ width: '100%' }} alt="img" />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider className="report-divider" />
                    <Box px={15}>
                        <Box>
                            <span className="report-title">CRP = {labValues.getLabReportValue(LabInputNames.Crp)?.value}</span>
                        </Box>
                        <Box display="flex" justifyContent="space-between" mt={5} >
                            <Box>
                                <span className="report-sub-title">Low Inflammation</span>
                            </Box>
                            <Box>
                                <span className="report-sub-title">High Inflammation</span>
                            </Box>
                        </Box>
                        <Box mt={2}>
                            <ProgressDiagramComponent
                                height={22}
                                colors={['#65704f', '#d60001']}
                                data={[
                                    {
                                        label: '',
                                        value: report.longevityReport.inflammationRiskScore.scorePercent,
                                        labelPosition: LabelPosition.Bottom
                                    }
                                ]}
                                unavailable={!report.longevityReport.inflammationRiskScore.isCalculated}
                            />
                        </Box>
                        <Box display="flex" justifyContent="space-between" mt={2} >
                            <Box ml={2}>
                                <span className="text-accent text-medium">0</span>
                            </Box>
                            <Box mr={2}>
                                <span className="text-accent text-medium">5.0</span>
                            </Box>
                        </Box>
                        <Box mt={7}>
                            <Box className="text-light-medium">
                                <Box>
                                    <span className="report-sub-title">Inflammation Labs</span>
                                </Box>
                                <Box width="25%">
                                    <LabResultComponent label="CRP" value={labValues.getLabReportValue(LabInputNames.Crp)} />
                                    <LabResultComponent label="A/G ratio" value={labValues.getLabReportValue(LabInputNames.AgRatio)} />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Divider className="report-divider" />
                    <Box px={15}>
                        <Box>
                            <Box display="flex" justifyContent="space-between">
                                <span className="report-title">Your Inflammation Recommendations</span>
                                {
                                parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation) && HealthReportMode.EditMode === mode && <WildHealthButton
                                    id="health-recommendation-new-save"
                                    onClick={() => handleSaveChangesNew()}
                                    disabled={!isChanged}>
                                    <Box >
                                        Save changes
                                    </Box>
                                </WildHealthButton>
                            }
                            </Box>
                            <Box mt={2}>
                            {parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
                                ? <Box>
                                    <RecommendationSingleSelectComponent
                                        currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.Inflammation)}
                                        recommendation={report.longevityReport.inflammationRecommendation}
                                        type={RecommendationTypesNumbers.Inflammation}
                                        handleSelected={handleSelected}
                                        handleChanges={handleChangesNewRecommendation}
                                        handleAddRecommendation={handleAddRecommendation}
                                        mode={mode}
                                    />
                                </Box>
                                : <HealthRecommendationComponent
                                    mode={mode}
                                    rows={recommendationRowsCapacity[RecommendationTypesNumbers.Inflammation]}
                                    recommendation={report.longevityReport.inflammationRecommendation}
                                    handleContentChanges={!handleChanges ? handleChanges : (value) => {
                                        handleChanges('longevityReport.inflammationRecommendation.content', value)
                                    }}
                                    handleManualContentChanges={!handleChanges ? handleChanges : (value) => {
                                        handleChanges('longevityReport.inflammationRecommendation.manualContent', value)
                                    }}
                                    handleSaveChanges={handleSaveChanges}
                                    isChanged={isChanged} />
                                }
                            </Box>
                        </Box>
                    </Box>
                </>
            </HealthReportBasePage>
        )
    )
}
