import React from 'react';
import { withRouter } from 'react-router';
import { AuthenticatedLayoutComponent } from '../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from "../../../../models/menu.models";
import { ManageInsurancesComponent } from '../../components/manageInsurancesComponent/ManageInsurancesComponent';

class ManageInsurancesPage extends React.Component<any> {
    render() {
        return (
            <AuthenticatedLayoutComponent
                hideBackground
                title={TitlesService.eligibleInsurancesTitle}
                pageName={PagesNamesService.eligibleInsurances}
                selectedMenuItem={MenuItemTypes.EligibleInsurances}
            >
                <ManageInsurancesComponent />
            </AuthenticatedLayoutComponent>
        )
    }
}

export default withRouter(ManageInsurancesPage);
