import Axios from 'axios-observable';
import {Observable} from "rxjs";
import {authHeader} from '../../common/helpers/auth-header';
import {snackService} from '../../common/snack/state';
import {ExternalAuthModel, LoginWithAuth2Model} from '../models/auth.models';
import {AuthStore, authStore} from '../stores/auth';
import {ExternalAuthProvider} from '../models/auth.enums';

/**
 * Provides method for working with third party integrations
 */
export class ThirdPartyIntegrationService {
    private url = `${process.env.REACT_APP_API_URL}ThirdPartyIntegration/`;

    constructor(private authStore: AuthStore) {
    }

    public get(provider: ExternalAuthProvider): Observable<ExternalAuthModel> {
        const config = {
            headers: authHeader()
        };

        return new Observable(observer => {
            Axios.get<ExternalAuthModel>(`${this.url}?provider=${provider}`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.authStore.addExternalAuth(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public getSessionToken(provider: ExternalAuthProvider): Observable<string> {
        const config = {
            headers: authHeader()
        };

        return new Observable(observer => {
            Axios.get<string>(`${this.url}/sessionToken?provider=${provider}`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public create(model: LoginWithAuth2Model): Observable<ExternalAuthModel> {
        const config = {
            headers: authHeader()
        };

        return new Observable(observer => {
            Axios.post<ExternalAuthModel>(`${this.url}`, model, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.authStore.addExternalAuth(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public refresh(id: number): Observable<ExternalAuthModel> {
        const config = {
            headers: authHeader()
        };

        return new Observable(observer => {
            Axios.post<ExternalAuthModel>(`${this.url}${id}/refresh`, null, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.authStore.addExternalAuth(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public fullscript(): Observable<ExternalAuthModel> {
        const config = {
            headers: authHeader()
        };

        return new Observable(observer => {
            Axios.get<ExternalAuthModel>(`${this.url}/Fullscript`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.authStore.addExternalAuth(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }
}

export const thirdPartyIntegrationService = new ThirdPartyIntegrationService(authStore);
