import {Query} from "@datorama/akita";
import {RostersState, rostersStore, RostersStore} from "./rosters.store";

/**
 * Provides method for working with rosters store
 */
export class RostersQuery extends Query<RostersState> {
    rosters$ = this.select(state => state.rosters);

    constructor(protected store: RostersStore) {
        super(store);
    }

    getRosters() {
        return this.getValue().rosters;
    }

    isActive(rosterId: number) {
        return this.getValue().rosters.find(roster => roster.id === rosterId)?.isActive
    }
}

export const rostersQuery = new RostersQuery(rostersStore);