import React from 'react';
import {useFacade} from "./myAccount.hooks";
import {UserType} from "../../../auth/models/auth.enums";
import {PatientProfilePage} from "../patientAccountPage/PatientProfilePage";
import {EmployeeAccountPage} from "../employeeAccountPage/EmployeeAccountPage";

export const MyAccountPage: React.FC = () => {
    const [
        {
            userType
        }
    ] = useFacade();

    switch (userType) {
        case UserType.Employee: return <EmployeeAccountPage/>;
        case UserType.Patient: return <PatientProfilePage/>;
        default: return <></>;
    }
}
