import {useEffect, useState} from "react";
import {IErrorState} from "../../../common/validation/error-state";
import { PracticeProfileModel, getDefaultPracticeModel } from "../../models/practiceProfile.model";
import {onEmit} from "../../../common/helpers/on-emit";
import {profileQuery} from "../../stores/profileStore";
import {profileService} from "../../services/profile.service";
import {practiceAccountComponentValidator} from "./practiceAccountComponent.validator";
import { getLastObject } from "../../../common/helpers/get-last-object";

interface PracticeAccountComponentState extends IErrorState {
    isLoading: boolean;
    isSubmitted: boolean;
    practice: PracticeProfileModel;
}

export function useFacade(): [
    PracticeAccountComponentState,
    (field: string, value: string | boolean) => void,
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
] {
    const [state, setState] = useState({
        practice: getDefaultPracticeModel(),
        isLoading: true,
        isSubmitted: false,
        errors: {}
    } as PracticeAccountComponentState);

    const handleChanges = (field: string, value: string | boolean) => {
        practiceAccountComponentValidator.validateAndSetState(state, setState, field, value);

        const practice = state.practice;
        const keys = field.split(".");
        const key = keys.pop();
        const lastObject = getLastObject(keys, practice);
        lastObject[key] = value;

        setState(state => ({
            ...state,
            practice: practice
        }));
    }

    const handleSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();

        practiceAccountComponentValidator.validateObjectAndSetState(state, setState, state.practice);
        if (!practiceAccountComponentValidator.stateIsValid(state)) {
            return;
        }

        setState(state => ({
            ...state,
            isSubmitted: true
        }));

        const cb = () => {
            setState(state => ({
                ...state,
                isSubmitted: false
            }));
        }
        state.practice.email = state.practice.email.trim();
        
        profileService.updatePracticeProfile(state.practice).subscribe(cb, cb);
    }

    useEffect(() => {
        const subscriptions = [
            onEmit<PracticeProfileModel>(profileQuery.practiceProfile$, practice => {
                if (practice) {
                    setState(state => ({
                        ...state,
                        practice: practice
                    }));
                }
            })
        ];

        const cb = () => {
            setState(state => ({
                ...state,
                isLoading: false
            }));
        }

        profileService.getPracticeProfile().subscribe(cb, cb);

        return (() => {
            subscriptions.map(it => it.unsubscribe())
        });
    }, []);

    return [state, handleChanges, handleSubmit];
}
