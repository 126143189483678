import {Box, Switch, Table, TableBody, TableContainer, TableHead} from "@material-ui/core";
import React from "react";
import {WildHealthExternalLinkButton} from "../../../common/components/WildHealthLinkButton";
import {WildHealthPlaceHolder} from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import {WildHealthTableCell} from "../../../common/components/wildHealthTable/WildHealthTableCell";
import {WildHealthTableRow} from "../../../common/components/wildHealthTable/WildHealthTableRow";
import {SupplementItem} from "../../../notes/models/notes.models";
import {SupplementSource, supplementSources} from "../../models/patientSupplementModel";

interface SupplementsDCComponentProps {
    items: SupplementItem[];
    handleMedicationsIsStoppedChange: (id: number, isChecked: boolean) => void;
}

export const SupplementsDCComponent: React.FC<SupplementsDCComponentProps> = (props: SupplementsDCComponentProps) => {
    const {
        items,
        handleMedicationsIsStoppedChange,
    } = props;

    if (!items || !items.length) {
        return (
            <Box m={1}>
                <WildHealthPlaceHolder message="No data"/>
            </Box>
        );
    }

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <WildHealthTableRow>
                        <WildHealthTableCell>
                            Name
                        </WildHealthTableCell>
                        <WildHealthTableCell>
                            Strength
                        </WildHealthTableCell>
                        <WildHealthTableCell>
                            Dosing Instruction
                        </WildHealthTableCell>
                        <WildHealthTableCell>
                            Source
                        </WildHealthTableCell>
                        <WildHealthTableCell>
                            Purchase Link
                        </WildHealthTableCell>
                        <WildHealthTableCell style={{width: '20%'}}>
                            D/C
                        </WildHealthTableCell>
                    </WildHealthTableRow>
                </TableHead>
                <TableBody>
                {
                    items.map((item, index) =>
                        <WildHealthTableRow key={index}>
                            <WildHealthTableCell>
                                {item.isStopped ? <s>{item.name}</s> : item.name}
                            </WildHealthTableCell>
                            <WildHealthTableCell>
                                {item.dosage}
                            </WildHealthTableCell>
                            <WildHealthTableCell>
                                {item.instructions}
                            </WildHealthTableCell>
                            <WildHealthTableCell>
                                {supplementSources.get(item.source ?? SupplementSource.Supplement)}
                            </WildHealthTableCell>
                            <WildHealthTableCell>
                                <WildHealthExternalLinkButton id={`supplements-dcc-link-purchase-${item.id}`} link={item.purchaseLink}/>
                            </WildHealthTableCell>
                            <WildHealthTableCell style={{width: '20%'}}>
                                <Box minHeight='30px'>
                                    <Switch
                                        size="small"
                                        color="primary"
                                        name="checkedB"
                                        checked={item.isStopped}
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                        onChange={(e) => handleMedicationsIsStoppedChange(item.id, e.target.checked)}
                                    />
                                </Box>
                            </WildHealthTableCell>
                        </WildHealthTableRow>
                    )
                }
                </TableBody>
            </Table>
        </TableContainer>
    )
}
