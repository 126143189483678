import { Box, FormControl, MenuItem, Select } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import commonUseStyles from '../../../common/styles/common.styles';
import { AppointmentPurposeType, AppointmentWithType } from '../../models/appointments.enums';
import { AppointmentTypeModel } from '../../models/appointments.models';

interface SelectAppointmentPurposeComponentProps {
    handleChange: (value: AppointmentPurposeType) => void;
    value: AppointmentPurposeType;
    isReadonly: boolean;
    withType: AppointmentWithType;
    availableTypes: AppointmentTypeModel[];
}

export const SelectAppointmentPurposeComponent: React.FC<SelectAppointmentPurposeComponentProps> = (props: SelectAppointmentPurposeComponentProps) => {
    const {
        handleChange,
        value,
        isReadonly,
        withType,
        availableTypes
    } = props;

    const commonClasses = commonUseStyles();

    const availablePurposes = availableTypes.filter(x=> x.configurations.some(t => t.withType === withType));

    return (
        <Box>
            <Box>
                <span className={clsx(commonClasses.size14, commonClasses.colorGray1)}>Appointment Type</span>
            </Box>
            <Box mt={1}>
                {
                    isReadonly && 
                    <span>{availableTypes.find(x=> x.purpose === value && x.configurations.some(t => t.withType === withType))?.name}</span>
                }
                {
                    !isReadonly && 
                    <Box>
                        <FormControl color='primary' variant="outlined" size="small" fullWidth={true}>
                            <Select
                                style={{width: "100%"}}
                                value={value}
                                onChange={(event) => handleChange(event.target.value as AppointmentPurposeType)}>
                                {
                                    availablePurposes.map((x, i) => {
                                        return <MenuItem key={i} value={x.purpose}>{x.name}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Box>
                }
            </Box>
        </Box>
    );
}