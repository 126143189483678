import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Subscription } from "recompose";
import { navigationService } from "../../../../services/navigation.service";
import { onEmit } from "../../../common/helpers/on-emit";
import { patientsService } from "../../../patients/services/patients.service";
import { TodoPatientJourneyTaskModel } from "../../../patients/models/patient.model";
import { patientsQuery } from "../../../patients/stores/patientsStore";
import { PlatformName, Track } from "../../../tracking/tracker";

interface JourneyTasksWidgetComponentState {
    isLoading: boolean;
    tasks: TodoPatientJourneyTaskModel[];
    completes: number[];
    isSideAreaOpen: boolean;
    selectedTaskId: number;
}

export function useFacade(patientId: number | null): [
    JourneyTasksWidgetComponentState,
    (task: TodoPatientJourneyTaskModel, isArchived: boolean) => void,
    () => void,
    (id: number, open: boolean) => void
] {
    const [state, setState] = useState({
        isLoading: false,
        tasks: [],
        completes: [],
        isSideAreaOpen: false,
        selectedTaskId: 0
    } as JourneyTasksWidgetComponentState);

    const _history = useHistory();

    const handleComplete = (task: TodoPatientJourneyTaskModel, isArchived: boolean) => {
        Track("onboarding_medicare", {
            group: 'test',
            platform: PlatformName(),
        });
        setState(state => ({...state, isLoading: true, isSideAreaOpen: false, selectedTaskId: 0}));
        const cb = () => setState(state => ({...state, isLoading: false}))
        const handleNext = () => {
            if (!isArchived) {
                setState(state => ({...state, completes: [...state.completes, task.id]}));
            }
    
            patientsService.getTodoPatientJourneyTasks().subscribe(cb, cb)
        }
        if (isArchived) {
            Track("timeline_task_archived", {
                title: task.title,
                platform: PlatformName(),
            })
        } else {
            Track("timeline_task_completed", {
                title: task.title,
                source: 'patient',
                platform: PlatformName(),
            })
        }
        const action = isArchived ? patientsService.dismissPatientJourneyTask(task.id) : patientsService.completePatientJourneyTask(task.id)
        action.subscribe(handleNext, cb);
    }

    const handleToggleSideArea = (id: number, open: boolean) => {
        setState(state => ({ ...state, isSideAreaOpen: open, selectedTaskId: id }))
    }

    const handleGotoJourneyTaskPage = () => {
        navigationService.toPatientJourneyTasks(_history, patientId)
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<TodoPatientJourneyTaskModel[]>(patientsQuery.todoPatientJourneyTasks$, tasks => {
                setState(state => ({
                    ...state,
                    tasks
                }));
            }),
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [state, handleComplete, handleGotoJourneyTaskPage, handleToggleSideArea];
}