import React from "react";
import { LabRangeModel, InputModel } from "../models/input.models";
import { renderEmptyValue } from "../../common/helpers/empty-value";
import { sortLabInputValues } from "./sort-lab-input-values";
import { BiometricInputsGraphComponent } from "../components/biometricInputsGraphComponent/BiometricInputsGraphComponent";
import moment from "moment";

export const renderBiometricValuesGraph = (
  values: InputModel[],
  range?: LabRangeModel,
  height: number = 400,
  width: number = 440,
  renderRange = false
) => {

  const initializedValues = values
    .filter((x) => !!x.value)
    .sort(sortLabInputValues);

  if (initializedValues.length < 2) {
    return renderEmptyValue("Insufficient data for trending");
  }

  return (
    <BiometricInputsGraphComponent
      data={initializedValues.map((x) => {
        return {
          name: x.value,
          date: moment(x.date).format('MM/DD'),
        }
      })}
      renderRange={renderRange}
      minValue={range?.from}
      maxValue={range?.to}
      height={height}
      width={width}
    />
  );
};
