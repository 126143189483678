import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import RssFeedIcon from '@material-ui/icons/RssFeed';
import StayCurrentPortraitOutlinedIcon from '@material-ui/icons/StayCurrentPortraitOutlined';
import React from 'react';

export enum AppointmentPurposeType {
    Welcome = 0,
    Consult = 1,
    CatchUp = 3,
    SickVisit = 6,
    FollowUp = 5,
    IRR = 10,
    Other = 15,
    None = 20
}

export const AppointmentPurposeTypeNames = new Map<number, string>([
    [AppointmentPurposeType.Welcome, 'Welcome'],
    [AppointmentPurposeType.Consult, 'Consult'],
    [AppointmentPurposeType.FollowUp, 'Follow-Up'],
    [AppointmentPurposeType.IRR, 'IRR'],
    [AppointmentPurposeType.Other, 'Other'],
    [AppointmentPurposeType.SickVisit, 'Sick Visit'],
    [AppointmentPurposeType.CatchUp, 'Catch Up Medical Visit'],
]);

export enum AppointmentLocationType {
    Online = 10,
    Phone = 5,
    InPerson = 1,
}

export const PatientAppointmentLocationTypeIcons = new Map<number, JSX.Element>([
    [AppointmentLocationType.InPerson, <LocationOnOutlinedIcon/>],
    [AppointmentLocationType.Phone, <StayCurrentPortraitOutlinedIcon />],
    [AppointmentLocationType.Online, <RssFeedIcon />]
]);

export const AppointmentLocationTypeNames = new Map<AppointmentLocationType, string>([
    [AppointmentLocationType.InPerson, 'In Person'],
    [AppointmentLocationType.Phone, 'Phone'],
    [AppointmentLocationType.Online, 'Virtual']
]);

export enum AppointmentStatus {
    Pending = 1,
    Submitted = 5,
    Postponed = 10,
    Canceled = 15,
    All = 20
}

export const AppointmentStatusNames = new Map<number, string>([
    [AppointmentStatus.Pending, 'Pending Approval'],
    [AppointmentStatus.Submitted, 'Submitted'],
    [AppointmentStatus.Postponed, 'Postponed'],
    [AppointmentStatus.Canceled, 'Canceled'],
    [AppointmentStatus.All, 'All'],
]);

export enum DayOfWeek {
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
    Sunday = 7,
}

export const dayOfWeekNames : { [id: string]: string; } = {
    [DayOfWeek.Monday]: 'Mon',
    [DayOfWeek.Tuesday]: 'Tue',
    [DayOfWeek.Wednesday]: 'Wed',
    [DayOfWeek.Thursday]: 'Thu',
    [DayOfWeek.Friday]: 'Fri',
    [DayOfWeek.Saturday]: 'Sat',
    [DayOfWeek.Sunday]: 'Sun',
}

export enum AppointmentWithType {
    HealthCoach = 1,
    Provider = 5,
    HealthCoachAndProvider = 10,
    Staff = 15,
    Other = 100
}

export const appointmentWithTypeNames : Map<AppointmentWithType, string> = new Map<AppointmentWithType, string>([
    [AppointmentWithType.HealthCoach, 'Health Coach'],
    [AppointmentWithType.Provider, 'Provider'],
    [AppointmentWithType.HealthCoachAndProvider, 'Provider & Health Coach'],
    [AppointmentWithType.Staff, 'Staff']
])

export enum AppointmentsViewMode {
    Day = 1,
    Week = 2,
    WorkWeek = 3
}

export const appointmentsViewModeNames : { [id: string]: string; } = {
    [AppointmentsViewMode.Day]: 'Day',
    [AppointmentsViewMode.Week]: 'Week',
    [AppointmentsViewMode.WorkWeek]: 'Work Week',
}

export enum AppointmentTargetType {
    Patient = 1,
    Other = 2
}

export const appointmentTargetTypeNames : { [id: string]: string; } = {
    [AppointmentTargetType.Patient]: 'Patient Appointment',
    [AppointmentTargetType.Other]: 'Other Appointment',
}

export enum AppointmentCancellationReason{
    Cancelled = 0,
    Reschedule = 1
}

export enum AppointmentSignOffType {
    Labs30DaysPrior = 10
}

export enum AppointmentReasonType
{
    LabFollowUp = 1,
    OtherTestFollowUp = 2,
    NewProblem = 3,
    Diet = 4,
    Fitness = 5,
    Sleep = 6,
    MentalHealth = 7,
    CvdRisk = 8,
    DementiaRisk = 9,
    MetabolicHealth = 10,
    Longevity = 11,
    Other = 12
}

export const AppointmentReasonTypeNames = {
  [AppointmentReasonType.LabFollowUp]: "Lab Follow-Up",
  [AppointmentReasonType.OtherTestFollowUp]: "Other Test Follow-Up",
  [AppointmentReasonType.NewProblem]: "New Problem",
  [AppointmentReasonType.Diet]: "Diet",
  [AppointmentReasonType.Fitness]: "Fitness",
  [AppointmentReasonType.Sleep]: "Sleep",
  [AppointmentReasonType.MentalHealth]: "Mental Health",
  [AppointmentReasonType.CvdRisk]: "CVD Risk",
  [AppointmentReasonType.DementiaRisk]: "Dementia Risk",
  [AppointmentReasonType.MetabolicHealth]: "Metabolic Health",
  [AppointmentReasonType.Longevity]: "Longevity",
  [AppointmentReasonType.Other]: "Other"
};
