import { IconButton, List, ListItem, ListItemIcon, ListItemText, Popover } from "@material-ui/core";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppTwoToneIcon from '@material-ui/icons/ExitToAppTwoTone';
import PersonIcon from '@material-ui/icons/Person';
import React from 'react';
import { navigationService } from "../../../../services/navigation.service";
import { accountService } from "../../../../services/account.service";
import { authQuery } from "../../../../modules/auth/stores/auth";
import { useStyles } from "./userOptionsButtonComponent.styles";
import { useFacade } from "./userOptionsButtonComponent.hooks";
import { useHistory } from "react-router";
import VpnKeyIcon from '@material-ui/icons/VpnKey';

const UserOptionsPopoverProps: any = {
    id: "userOptionsPopover",
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
    }
};

export const UserOptionsButtonComponent: React.FC = () => {
    const [
        { anchorEl, showMenu },
        handleToggleMenu
    ] = useFacade();

    const history = useHistory();
    const classes = useStyles();
    const isRegistrationCompleted = authQuery.isRegistrationCompleted();

    const handleLogout = () => {
        accountService.logout()
        navigationService.postLogoutRedirect(history)
    }

    return (
        <React.Fragment key='user-options-btn'>
            <IconButton
                id="user-options-btn-toggle-menu"
                className={classes.iconButton}
                onClick={(event) => handleToggleMenu(event)}
            >
                <AccountCircleIcon />
            </IconButton>

            <Popover
                open={showMenu}
                anchorEl={anchorEl}
                onClose={handleToggleMenu}
                id={UserOptionsPopoverProps.id}
                anchorOrigin={UserOptionsPopoverProps.anchorOrigin}
                transformOrigin={UserOptionsPopoverProps.transformOrigin}
            >
                <List className={classes.list}>
                    {isRegistrationCompleted && (
                        <>
                            <ListItem id="user-options-btn-reset-password" key={0} button onClick={() => navigationService.toResetPassword(history)}>
                                <ListItemIcon>
                                    <VpnKeyIcon />
                                </ListItemIcon>
                                <ListItemText primary="Reset Password" />
                            </ListItem>
                            <ListItem id="user-options-btn-my-account" key={1} button onClick={() => navigationService.toMyMembership(history)}>
                                <ListItemIcon>
                                    <PersonIcon />
                                </ListItemIcon>
                                <ListItemText primary="My Account" />
                            </ListItem>
                        </>
                    )}
                    <ListItem id="user-options-btn-sign-out" key={2} onClick={() => handleLogout()} button>
                        <ListItemIcon>
                            <ExitToAppTwoToneIcon />
                        </ListItemIcon>
                        <ListItemText primary="Sign Out" />
                    </ListItem>
                </List>
            </Popover>
        </React.Fragment>
    )
}
