import React from 'react';
import '../healthRecommendationComponent/HealthRecommendationComponent.scss';
import {Box} from '@material-ui/core';
import {WildHealthPlaceHolder} from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import {sanitizeRecommendationContent} from "../../helpers/sanitizeRecommendationContent";

interface HealthRecommendationTextComponentProps {
    text: string;
    rows: number;
}

export const HealthRecommendationTextComponent: React.FC<HealthRecommendationTextComponentProps> = (props: HealthRecommendationTextComponentProps) => {
    const {
        text,
        rows
    } = props;

    const renderPlaceholder = () => {
        return <WildHealthPlaceHolder height={`${rows * 30}px`} message={"No recommendations provided"}/>
    }

    const renderPrintableRecommendation = () => {
        if (!text || !text.length) {
            return renderPlaceholder();
        }

        return (
            <div className="recommendation printable" style={{height: `${rows * 30}px`}}>
                {sanitizeRecommendationContent(text) ?? ''}
            </div>
        )
    }

    return (
        <>
            <Box className="recommendation-wrapper">
                {renderPrintableRecommendation()}
            </Box>
        </>
    )
}
