import { Box, Table, TableBody, TableContainer } from '@material-ui/core';
import React from 'react';
import { WildHealthContainer } from '../../../common/components/wildHealthContainer/WildHealthContainer';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { useFacade } from './syncRecordsDashboardComponent.hooks';
import { FeatureComponent } from "../../../common/components/featureFlags/FeatureComponent";
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from 'clsx';
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { useStyles } from "./syncRecordsDashboardComponent.styles";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

export const SyncRecordsDashboardComponent: React.FC = () => {
    const [
        {
            isLoading,
            syncRecordTypes,
        },
        handleGoTargetSyncRecordType
    ] = useFacade();

    const classes = useStyles();
    const commonClasses = commonUseStyles();

    const renderContent = () => {
        if (isLoading) {
            return <WildHealthLinearProgress />
        }

        if (!isLoading && !syncRecordTypes) {
            return <WildHealthPlaceHolder message="No Sync Records" />
        }

        return (
            <Box p={2}>
                {
                    syncRecordTypes.map((type, index) => (
                        <Box pb={2} key={index}>
                            <Box mb={2} className={clsx(commonClasses.size20, commonClasses.textBold, commonClasses.colorBlack)}>{type.name}</Box>
                            <TableContainer>
                                <Table>
                                    <TableBody>
                                        <WildHealthTableRow>
                                            {type.count > 0 ? (
                                                <WildHealthTableCell onClick={() => handleGoTargetSyncRecordType(type.path)}>
                                                    <Box className={commonClasses.pointer} display="flex" alignItems="center" justifyContent="space-between">
                                                        <span>{type.name}({type.count})</span>
                                                        <ArrowForwardIosIcon className={classes.icon} />
                                                    </Box>
                                                </WildHealthTableCell>
                                            ) : (
                                                <WildHealthTableCell>
                                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                                        <span>{type.name}({type.count})</span>
                                                    </Box>
                                                </WildHealthTableCell>
                                            )}
                                        </WildHealthTableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    ))
                }
            </Box>
        )
    }

    return (
        <Box p={2} width={1}>
            <Box my={2}>
                <FeatureComponent featureFlag={FeatureFlag.Navigation}>
                    <Box className={clsx(commonClasses.size24, commonClasses.textSemiBold, commonClasses.colorBlack)}>{PagesNamesService.syncRecords}</Box>
                </FeatureComponent>
            </Box>

            <WildHealthContainer margin={0} minHeight={'100%'}>
                {renderContent()}
            </WildHealthContainer>
        </Box>
    );
}