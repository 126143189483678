import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
            [theme.breakpoints.down("xs")]: {
                paddingTop: '55px !important',
                paddingLeft: '0px !important',
                paddingRight: '0px !important',
                paddingBottom: '0px !important',
                '& .MuiDialog-paperFullScreen': {
                    borderRadius: '20px 20px 0 0',
                },
            },
        },
        dialogContent: {
            [theme.breakpoints.down("xs")]: {
                padding: 16,
            },
        },
        dialogTitle: {
            display: 'flex',
            justifyContent: "space-between",
            alignItems: "center",
            margin: 0,
            paddingRight: theme.spacing(1),
            paddingLeft: theme.spacing(5),
            paddingTop: theme.spacing(4),
        },
        action: {
            color: colors.main,
            paddingRight: theme.spacing(5),
            paddingLeft: theme.spacing(5),
            paddingBottom: theme.spacing(4),
            paddingTop: theme.spacing(2.5),
        },
        field: {
            width: 468,
            margin: 5,
            [theme.breakpoints.down("sm")]: {
                width: "100%",
            },
        },
    }),
);
