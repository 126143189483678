import {createStyles, makeStyles} from "@material-ui/core/styles";
import {colors} from "../../../common/constants/colors";

export const useStyles = makeStyles(() =>
    createStyles({
        root: {
            margin: 3,
            position: 'relative'
        },
        internal: {
            backgroundColor: colors.internalMessageNotification
        },
        healthCare: {
            backgroundColor: colors.healthcareMessageNotification
        },
        support: {
            backgroundColor: colors.healthcareMessageNotification
        },
    })
);