import { createStyles, makeStyles } from '@material-ui/core/styles';
import {colors} from "../../../common/constants/colors";

export const useStyles = makeStyles(() =>
    createStyles({
        selectedProvider: {
            display: 'block',
            height: '254px',
            width: '100%',
            background: colors.lightGray,
            border: '1px solid ' + colors.accent1,
            borderRadius: '12px',
            paddingTop: '0px',
            paddingLeft: '12px',
            paddingRight: '12px',
        },
        selectedProviderAvatar: {
            width: '70px',
            height: '70px',
        },
        selectedProviderTitle: {
            height: '20px',
            paddingTop: '2px',
            fontSize: '11px',
            color: colors.white,
            background: colors.accent1,
            borderBottomRightRadius: '8px',
            borderBottomLeftRadius: '8px'
        },
        providerListItem: {
            display: 'flex',
            height: '56px',
            borderRadius: '12px',
            border: '1px solid ' + colors.stroke,
            padding: '11px',
            marginBottom: '10px',
        },
        radio: {
            color: colors.gray2,
            '&.Mui-checked': {
                color: colors.main,
            },
        }
    })
);
