export enum SourceType
{
  Blog = 10,
  Podcast = 20,
  ResearchArticle = 30,
  Other = 40
}

export enum AssetType
{
  Url = 1,
  File = 2
}

export enum DocumentChunkingStrategy
{
  RecursiveTextSplitter = 10
}

export enum DocumentGenerationStrategy
{
  AzureBlobFile = 1,
  YoutubeTranscript = 2
}

export enum HealthCategoryTag
{
  Unknown = 0,
  Nutrition = 10,
  Neurobehavioral = 20,
  Longevity = 30,
  Pgx = 40,
  Metabolic = 50,
  Cvd = 60,
  Mindfulness = 70,
  Autoimmune = 80,
  Dementia = 90,
  Methylation = 100,
  Hormones = 110,
  Inflammation = 120,
  Sleep = 130,
  MacroNutrient = 140,
  VitaminsAndMicronutrients = 150,
  KryptoniteFoods = 160,
  SuperFoods = 170,
  Microbiome = 180,
  ExerciseAndRecovery = 190,
  Cardiovascular = 200,
  Cancer = 210,
  RecommendedMacros = 220
}

export const HealthCategoryTagNames = {
  [HealthCategoryTag.Unknown]: "Unknown",
  [HealthCategoryTag.Nutrition]: "Nutrition",
  [HealthCategoryTag.Neurobehavioral]: "Neurobehavioral",
  [HealthCategoryTag.Longevity]: "Longevity",
  [HealthCategoryTag.Pgx]: "Pgx",
  [HealthCategoryTag.Metabolic]: "Metabolic",
  [HealthCategoryTag.Cvd]: "Cvd",
  [HealthCategoryTag.Mindfulness]: "Mindfulness",
  [HealthCategoryTag.Autoimmune]: "Autoimmune",
  [HealthCategoryTag.Dementia]: "Dementia",
  [HealthCategoryTag.Methylation]: "Methylation",
  [HealthCategoryTag.Hormones]: "Hormones",
  [HealthCategoryTag.Inflammation]: "Inflammation",
  [HealthCategoryTag.Sleep]: "Sleep",
  [HealthCategoryTag.MacroNutrient]: "MacroNutrient",
  [HealthCategoryTag.VitaminsAndMicronutrients]: "Vitamins/Micronutrients",
  [HealthCategoryTag.KryptoniteFoods]: "Kryptonite Foods",
  [HealthCategoryTag.SuperFoods]: "SuperFoods",
  [HealthCategoryTag.Microbiome]: "Microbiome",
  [HealthCategoryTag.ExerciseAndRecovery]: "Exercise/Recovery",
  [HealthCategoryTag.Cardiovascular]: "Cardiovascular",
  [HealthCategoryTag.Cancer]: "Cancer",
  [HealthCategoryTag.RecommendedMacros]: "Recommended Macros"
};

export interface DocumentChunkModel {
  id: number;
  content: string;
  chunkingStrategy: DocumentChunkingStrategy;
}

export interface DocumentSourceTypeModel {
  id: number;
  name: string;
  type: SourceType;
  assetType: AssetType;
  chunkingStrategy: DocumentChunkingStrategy;
  generationStrategy: DocumentGenerationStrategy;
}

export interface DocumentSourceTagModel {
  tag: HealthCategoryTag;
}

export interface DocumentSourcePersonaModel {
  id: number;
  name: string;
}

export interface DocumentSourceModel {
  id: number;
  name: string;
  url: string;
  documentChunks: DocumentChunkModel[];
  documentSourceType: DocumentSourceTypeModel;
  tags: DocumentSourceTagModel[];
  documentSourcePersonas: DocumentSourcePersonaModel[];
}

export interface DocumentSourceUploadModel {
  file: any;
  documentSourceTypeId: number;
  name: string;
  url: string;
  tags: HealthCategoryTag[];
  personaIds: number[];
}

export interface UpdateDocumentSourceModel {
  id: number;
  name: string;
  tags: HealthCategoryTag[];
  personaIds: number[];
}