import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { onEmit } from "../../../common/helpers/on-emit";
import { SortingDirection } from "../../../common/sorting/models/sorting-destination";
import { ISortingState } from "../../../common/sorting/models/sorting-state";
import { handleCompare } from "../../../common/sorting/helpers/handle-compare";
import { IErrorState } from "../../../common/validation/error-state";
import { SpecialTestModel } from "../../../notes/models/notes.models";
import { notesQuery } from "../../../notes/stores/notes";
import { createCommonOrderValidator } from "./createCommonOrder.validator";
import { confirmService } from "../../../../services/confirm.service";
import { commonOrdersService } from "../../../notes/services/commonOrders.service";

export enum SortingSource {
  None,
  Name = "company",
  Test = "name",
  Price = "cost",
}

interface ManageCommonOrdersComponentState extends ISortingState, IErrorState {
  isLoading: boolean;
  isSubmitting: boolean;
  isOpen: boolean;
  commonOrders: SpecialTestModel[];
  createCommonOrder: SpecialTestModel;
  removingId: number;
}

const initialParmas: SpecialTestModel = {
  id: 0,
  company: '',
  name: '',
  cost: '',
}

export function useFacade(): [
  ManageCommonOrdersComponentState,
  (field: string, value: any) => void,
  (source: string, direction: SortingDirection) => void,
  () => void,
  (id: number) => void,
  (id: number) => void
] {
  const [state, setState] = useState({
    isLoading: true,
    isSubmitting: false,
    isOpen: false,
    commonOrders: [],
    createCommonOrder: initialParmas,
    removingId: 0,
    sortingColumns: [
      { title: 'Company', source: SortingSource.Name, direction: SortingDirection.Asc },
      { title: 'Test', source: SortingSource.Test, direction: SortingDirection.Asc },
      { title: 'Cost', source: SortingSource.Price, direction: SortingDirection.Asc },
      { title: 'Action', source: null, direction: null },
    ],
    sortingSource: SortingSource.None,
    errors: {}
  } as ManageCommonOrdersComponentState);

  const setDirection = (source: string, direction: SortingDirection) => {
    const itemIndex = state.sortingColumns.findIndex(item => item.source === source);
    state.sortingColumns[itemIndex].direction = direction;
    setState(state => ({ ...state, columns: state.sortingColumns }));
  }

  const handleSorting = (source: string, direction: SortingDirection) => {
    if (state.sortingSource === source) {
      direction = direction === SortingDirection.Asc
        ? SortingDirection.Desc
        : SortingDirection.Asc;

      setDirection(source, direction);
    }
    setState(state => ({
      ...state,
      sortingSource: source,
      commonOrders: state.commonOrders.sort((p1, p2) => handleCompare(p1, p2, direction, source))
    }));
  }

  const handleChanges = (field: string, value: any) => {
    createCommonOrderValidator.validateAndSetState(state, setState, field, value);

    const params = state.createCommonOrder;
    
    params[field] = value;

    setState({...state, createCommonOrder: Object.assign({}, params)});
  }

  const handleSave = () => {
    createCommonOrderValidator.validateObjectAndSetState(state, setState, state.createCommonOrder);
    if (!createCommonOrderValidator.stateIsValid(state)) {
      return;
    }

    setState({...state, isSubmitting: true })

    const cb = () => setState(state => ({ ...state, isSubmitting: false, isOpen: false, createCommonOrder: Object.assign({}, initialParmas) }));

    const action = state.createCommonOrder.id > 0 ? commonOrdersService.updateCommonOrder(state.createCommonOrder) : commonOrdersService.createCommonOrder(state.createCommonOrder)
    
    action.subscribe(cb, cb)
  }
  
  const handleDelete = (id: number) => {
    confirmService.confirm(
      'Delete Common Order',
      'Are you sure you want to delete this order?',
      'Yes',
      'Cancel',
      'danger')
      .subscribe(() => {
        setState(state => ({
          ...state,
          isSubmitting: true,
          removingId: id
        }));

        const cb = () => setState(state => ({ ...state, isSubmitting: false, removingId: 0 }));
        commonOrdersService.deleteCommonOrder(id).subscribe(cb, cb);
      });
  }

  const handleToggle = (id: number) => {
    const item = state.commonOrders.find(x => x.id === id);
    setState((state) => ({
      ...state,
      isOpen: !state.isOpen,
      createCommonOrder: Object.assign({}, item ? item : initialParmas),
      errors: {}
    }))
  }

  const useEffectCB = () => {
    const subscriptions: Subscription[] = [
      onEmit<SpecialTestModel[]>(notesQuery.commonOrders$, commonOrders => {
        setState(state => ({
          ...state,
          commonOrders,
        }));
      }),
    ];

    const cb = () => setState(state => ({ ...state, isLoading: false }));

    commonOrdersService.getAllCommonOrders().subscribe(cb, cb)

    return () => {
      subscriptions.map(it => it.unsubscribe())
    };
  }

  useEffect(useEffectCB, []);

  return [
    state,
    handleChanges,
    handleSorting,
    handleSave,
    handleDelete,
    handleToggle
  ];
}