import { IconButton, TextField } from '@material-ui/core';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        message: {
            flex: 1,
        },
        icon: {
            fill: 'currentColor',
        },
        attachment: {
            border: `1px solid ${colors.stroke}`,
            borderRadius: 2,
            background: colors.gray8,
            display: 'flex',
            alignItems: 'center',
            maxWidth: 140,
            height: 44,

            "&:hover": {
                "& $removeAttachment": {
                    visibility: "visible",
                },
            },
        },
        attachmentIcon: {
            color: colors.gray2,
        },
        attachmentTitle: {
            fontSize: 14,
            fontWeight: 500,
            color: colors.black,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        removeAttachment: {
            visibility: 'hidden',
        },
        containerAttachments: {
            maxHeight: '20vh',
            overflow: 'auto',
        }
    })
);

export const InputField = withStyles({
    root: {
        '&:focus': {
            outline: 'none !important',
        },
        '& label.Mui-focused': {
            outline: 'none !important',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'transparent',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'transparent',
                outline: 'none !important',
            },
        },
    },
})(TextField);

export const DiscardButton = withStyles((theme: Theme) => ({
    root: {
        fill: 'currentColor',
        color: colors.gray1,
        background: colors.gray4,
        '&:hover': {
            color: `${colors.gray1}CC`,
            background: `${colors.gray4}CC`,
        },
        '&:disabled': {
            color: `${colors.gray1}99`,
            background: `${colors.gray4}99`,
        },
    },
}))(IconButton);

export const ConfirmButton = withStyles((theme: Theme) => ({
    root: {
        fill: 'currentColor',
        color: colors.white,
        background: colors.main,
        '&:hover': {
            color: `${colors.white}CC`,
            background: `${colors.main}CC`,
        },
        '&:disabled': {
            color: `${colors.white}99`,
            background: `${colors.main}99`,
        },
    },
}))(IconButton);