import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { colors } from '../../modules/common/constants/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        footer: {
            width: '100%',
            zIndex: 1199,
            backgroundColor: colors.bg,
            paddingInline: '24px',
            [theme.breakpoints.down('xs')]: {
                paddingInline: '16px',
            },
        },
        container: {
            maxWidth: 1128,
            padding: 0,
        },
        linkWrapper: {
            cursor: 'pointer',
            color: `${colors.primaryV} !important`,
            fontSize: 14,
            fontWeight: 400,
        },
        smLinkWrapper: {
            cursor: 'pointer',
            color: `${colors.primaryV} !important`,
            fontSize: 12,
            fontWeight: 400,
        },
        footerContainer: {
            minHeight: 80,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        logo1: {
            width: 120,
            height: 'auto',
        },
        logo2: {
            width: 97,
            height: 18,
        },
        vertical: {
            display: 'flex',
        },
        footerRedesign: {
            width: '100%',
            zIndex: 1201,
            backgroundColor: colors.white,
            paddingInline: '16px',
            borderTop: '1px solid rgba(0, 0, 0, 0.2)'
        },
        footerRedesignContainer: {
            minHeight: 58,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        linkRedesignWrapper: {
            cursor: 'pointer',
            color: `${colors.charcoal} !important`,
            fontSize: 12,
            fontWeight: 500,
        },
    }),
);