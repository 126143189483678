import React from "react";
import { Button, Box, Tooltip, useMediaQuery, useTheme } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import moment from "moment";
import { useFacade } from "./healthCareChatComponent.hooks";
import { useStyles } from "./healthCareChatComponent.styles";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { MessagesPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/MessagesPlaceHolder";
import { employeeTypesNames } from "../../../employee/models/employee.enums";
import {
  conversationTitles,
  ConversationType,
  PatientConversationModel,
} from "../../models/conversation.models";
import { healthCareConversationSubjects } from "../../models/conversationSubject.models";
import { ConversationComponent } from "../conversationComponent/ConversationComponent";
import { MessageAvatarsComponent } from "../messageAvatarsComponent/MessageAvatarsComponent";
import { displayShortName } from "../../helpers/messages.converter";
import { WildHealthClickableTooltip } from "../../../common/components/WildHealthClickableTooltip/WildHealthClickableTooltip";
import { profileQuery } from "../../../account/stores/profileStore";
import { toCurrentTimeZone } from "../../../timezones/helpers/timezone";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";

interface HealthCareChatComponentProps {
  isLoading: boolean;
  conversation: PatientConversationModel;
}

export const HealthCareChatComponent: React.FC<HealthCareChatComponentProps> = (
  props: HealthCareChatComponentProps
) => {
  const { isLoading, conversation } = props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const classes = useStyles();

  const customTooltipClasses = {
    tooltip: classes.tooltip,
    arrow: classes.arrow,
    tooltipPlacementTop: classes.tooltipPlacement,
    tooltipPlacementBottom: classes.tooltipPlacement,
  };

  const [{ author }, handleNavigateToChat] = useFacade();

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const maxParticipantsCount = 2;

  const tooltipContent = (
    <p className={classes.notificationToolTip}>
      <span className={classes.notificationToolTipText}>Topics for this thread:</span>
      <ul className={classes.notificationList}>
        {Array.from(healthCareConversationSubjects).map(([subject, title], index) => {
          return (
            <li key={index} className={classes.notificationToolTipText}>
              {title}
            </li>
          );
        })}
      </ul>
    </p>
  );

  const renderHeaderArea = () => {
    const defaultParticipantsInitials = [
      { image: "", initials: "HC" },
      { image: "", initials: "Pr" },
    ];
    const defaultParticipants = [
      { image: "", firstName: "Health", lastName: "Coach", position: "" },
      { image: "", firstName: "Provider", lastName: "", position: "" },
    ];

    const participantsInitials = conversation
      ? conversation.employees
        .filter((i) => i.isActive && !i.isDeleted)
        .map((i) => ({ image: profileQuery.getPhoto(i.employeeId), initials: displayShortName(i.firstName, i.lastName) }))
      : defaultParticipantsInitials;

    const participants = conversation
      ? conversation.employees
        .filter((i) => i.isActive && !i.isDeleted)
        .slice(0, maxParticipantsCount)
        .map((i) => ({
          image: profileQuery.getPhoto(i.employeeId),
          firstName: i.firstName,
          lastName: i.lastName,
          position: employeeTypesNames.get(i.type),
        }))
      : defaultParticipants;

    return (
      <Box className={classes.header}>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex">
            <Box mr={1.5} className={classes.title}>
              {conversationTitles.get(ConversationType.HealthCare)}
            </Box>
            <Box>
              <MessageAvatarsComponent
                participants={participantsInitials}
                size={isSmallScreen && 21}
              />
            </Box>
          </Box>
          {isSmallScreen ? (
            <Box display="flex" alignItems="center">
              <WildHealthClickableTooltip
                tooltipContent={tooltipContent}
                isOpened={open}
                handleClose={handleTooltipClose}
                customClasses={customTooltipClasses}
              >
                <InfoIcon className={classes.notificationIcon} onClick={handleTooltipOpen} />
              </WildHealthClickableTooltip>
              <Box>
                <Button
                  id="health-care-chat-open"
                  onClick={() => handleNavigateToChat()}
                  disabled={isLoading}
                >
                  <MoreHorizIcon />
                </Button>
              </Box>
            </Box>
          ) : (
              <Box display="flex">
                <WildHealthButton
                  id="health-care-chat-open"
                  color="secondary"
                  size="medium"
                  onClick={() => handleNavigateToChat()}
                  disabled={isLoading}
                >
                  Expand
                </WildHealthButton>
              </Box>
            )}
        </Box>
      </Box>
    );
  };

  const renderNotificationArea = () => {
    const participants = conversation
      ? conversation.employees?.filter(
        (i) =>
          i.isActive && i.settings?.awayMessageEnabled && i.settings?.message?.length > 0 && !i.isDeleted
      )
      : [];

    return (
      <Box>
        {participants.length > 0 && (
          <Box my={1} className={classes.awayNotification}>
            <Box mx={2}>
              {participants.map(function callback(item, index) {
                if (item.settings.awayMessageEnabled) {
                  return (
                    <Box
                      key={index}
                    >{`${item.firstName} ${item.lastName} - ${item.settings.message} Return Date - ${moment(toCurrentTimeZone(new Date(item.settings.awayMessageEnabledTo))).format("M/D/YY")}.`}</Box>
                  );
                }
              })}
            </Box>
          </Box>
        )}
        {!isSmallScreen && (
          <Box mx={2} p={1.5} className={classes.notification}>
            <Tooltip title={tooltipContent} placement="top" arrow>
              <InfoOutlinedIcon className="wh-tw-text-secondary wh-tw-cursor-pointer wh-tw-mr-2.5" />
            </Tooltip>
            <span>Use this thread if you have questions regarding your health. Call 911 for emergencies.</span>
          </Box>
        )}
      </Box>
    );
  };

  const renderContent = () => {
    return conversation.employees?.length ? (
      <ConversationComponent conversation={conversation} author={author} />
    ) : (
        <MessagesPlaceHolder message="Health Care conversation will become available when your health team has been assigned." />
      );
  };

  return (
    <Box className={classes.root}>
      {renderHeaderArea()}
      {renderNotificationArea()}
      {!isLoading && !conversation ? (
        <MessagesPlaceHolder message="Health Care conversation will become available when your health team has been assigned." />
      ) : isLoading ? (
        <Box p={3}>
          <WildHealthLinearProgress />
        </Box>
      ) : (
        renderContent()
      )}
    </Box>
  );
};
