import {
  Box,
  ListItem,
  Step,
  StepIconProps,
  StepLabel,
  Stepper,
  Collapse
} from "@material-ui/core";
import React, { useMemo } from "react";
import { PatientConversationModel } from "../../../models/conversation.models";
import { useStyles } from "../staffConversationsHistoryComponent.styles";
import moment from "moment";
import { ConversationState } from "../../../models/conversationState.models";
import { WildHealthStatusLabel } from "../../../../common/components/wildHealthStatusLabel/WildHealthStatusLabel";
import { handleCompare } from "../../../../common/sorting/helpers/handle-compare";
import { SortingDirection } from "../../../../common/sorting/models/sorting-destination";
import clsx from "clsx";
import CheckIcon from "@material-ui/icons/Check";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { ReactComponent as Active } from "@img/stepperIcons/stepActive.svg";
import { toCurrentTimeZone } from "../../../../timezones/helpers/timezone";

interface CollapseHistoryComponentProps {
  conversations: PatientConversationModel[];
  targetConversation?: PatientConversationModel;
  isOpenCollapse?: boolean;
  isClosed?: boolean;
  handleSelect: (PatientConversationModel) => void;
}

export const CollapseHistoryComponent: React.FC<
  CollapseHistoryComponentProps
> = (props: CollapseHistoryComponentProps) => {
  const classes = useStyles();
  const { conversations, targetConversation, handleSelect, isOpenCollapse, isClosed } = props;

  const filteredConversations = useMemo(() => {
    if (!conversations?.length) {
      return [];
    } else {
      const conversationsSorting = !isClosed 
        ? conversations.filter(x => x.state !== ConversationState.Closed).sort((a, b) => 
            handleCompare(a, b, SortingDirection.Asc, "startDate")
          )
        : conversations.filter(x => x.state === ConversationState.Closed).sort((a, b) => 
            handleCompare(a, b, SortingDirection.Asc, "startDate")
          );
      return conversationsSorting;
    }
  }, [conversations]);

  const renderStepIcon = (isActive: boolean) => {
    return (props: StepIconProps) =>
      isActive ? <Active className={classes.activeIcon} /> : <CheckCircleIcon className={classes.closedIcon} />;
  };

  return (
    <Collapse in={isOpenCollapse} timeout="auto" unmountOnExit>
      <Stepper connector={<></>} orientation="vertical">
        {filteredConversations.map((conversation, index) => {
          const isActive = conversation.state !== ConversationState.Closed;
          const selected = conversation.id === targetConversation?.id;
          const isLast = index !== filteredConversations.length - 1;

          return (
            <Step key={index} active={isActive}>
              <StepLabel StepIconComponent={renderStepIcon(isActive)} className={classes.stepLabelRoot}>
                <ListItem
                  id={`staff-conversations-history-select-${conversation.id}`}
                  className={clsx(classes.step, {
                    [classes.stepSelected]: selected,
                    [classes.stepBorder]: isLast,
                  })}
                  button
                  onClick={() => handleSelect(conversation)}
                >
                  <Box display="flex" justifyContent="space-between" flexWrap="wrap" width={1}>
                    <Box>
                      <WildHealthStatusLabel color={isActive ? "success" : "neutral"}>
                        {`Subject: ${conversation.subject}`}
                      </WildHealthStatusLabel>
                      <Box
                        pt={1}
                        pb={1.5}
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                      >
                        <span className={classes.statusDate}>
                          {`Started: (${moment(
                            toCurrentTimeZone(conversation.startDate)
                          ).format("MMMM DD, yyyy")})`}
                        </span>
                        {!isActive && (
                          <span className={classes.statusDate}>
                            {`Closed: (${moment(
                                toCurrentTimeZone(conversation.stateChangeDate)
                              ).format("MMMM DD, yyyy")})`}
                          </span>
                        )}
                      </Box>
                    </Box>
                    <Box>
                      <Box display="flex" alignItems="center" gridGap={4}>
                        {conversation?.unreadMessages > 0 && (
                          <WildHealthStatusLabel color="bad" borderRadius={50}>
                            {`${conversation.unreadMessages} New Messages`}
                          </WildHealthStatusLabel>
                        )}
                        {selected ? (
                          <CheckIcon
                            fontSize="small"
                            className={classes.icon}
                            data-testid="selected"
                          />
                        ) : (
                          <ChevronRightIcon fontSize="small" data-testid="unselected" />
                        )}
                      </Box>
                    </Box>
                  </Box>
                </ListItem>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Collapse>
  );
};
