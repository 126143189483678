import { Box, Button, Grid, Tab } from "@material-ui/core";
import React, { Fragment } from "react";
import WildHealthLinearProgress from "../../../../common/components/WildHealthLinearProgress";
import { useFacade } from "./addressInfoWidgetComponent.hooks";
import { useStyles, WildHealthTabs } from "./addressInfoWidgetComponent.style";
import BorderColorIcon from '@material-ui/icons/BorderColor';
import clsx from "clsx";
import { AddressModel } from "../../../../common/models/address.models";
import { WildHealthClickableTooltip } from "../../../../common/components/WildHealthClickableTooltip/WildHealthClickableTooltip";

export const AddressInfoWidgetComponent: React.FC<any> = () => {
  const [
    {
      isLoading,
      tabIndex,
      patient,
      open
    },
    handleChangeTab,
    handleTooltipOpen,
    handleTooltipClose
  ] = useFacade();

  const classes = useStyles();

  const customTooltipClasses = {
    tooltip: classes.customWidth,
    arrow: classes.arrow,
    tooltipPlacementBottom: classes.tooltipPlacement,
    tooltipPlacementTop: classes.tooltipPlacement,
  };

  const tooltipContent = (
    <>
      <p className={classes.notificationToolTipText}>
        Reach out to your care coordinator via messaging to edit the info
      </p>
    </>
  )
  
  if (isLoading) {
    return <Box p={4}>
      <WildHealthLinearProgress />
    </Box>
  }

  const renderAddresscontent = (address: AddressModel) => (
    <Grid container>
      <Grid item xs={12} md={12} lg={12}>
        <Box pb={2} display="flex" >
          <Box className={classes.textGray} >Address 1:</Box>
          <Box className={classes.breakWord}>{address.streetAddress1}</Box>
        </Box>
        <Box pb={2} display="flex">
          <Box className={classes.textGray} >Address 2: </Box>
          <Box className={classes.breakWord}>{address.streetAddress2} </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <Box pb={2} display="flex" >
          <Box className={classes.textGray} >City:</Box>
          <Box className={classes.breakWord}>{address.city}</Box>
        </Box>
        <Box pb={2} display="flex">
          <Box className={classes.textGray} >State: </Box>
          <Box className={classes.breakWord}>{address.state} </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <Box pb={2} display="flex" >
          <Box className={classes.textGray} >Zip Code: </Box>
          <Box className={classes.breakWord}>{address.zipCode}</Box>
        </Box>
        <Box display="flex">
          <Box className={classes.textGray} >Country: </Box>
          <Box className={classes.breakWord}>{address.country} </Box>
        </Box>
      </Grid>
    </Grid>
  )

  return (
    <Fragment key="address_info_new">
      <Box className={classes.tabHeaderSection}>
        <WildHealthTabs
          value={tabIndex}
          variant="fullWidth"
          onChange={handleChangeTab}
        >
          <Tab
            label="Billing address"
            value="0"
            className={clsx(classes.tab, {[classes.selectedTab]: tabIndex === "0"})}
          />
          <Tab
            label="Shipping address"
            value="1"
            className={clsx(classes.tab, {[classes.selectedTab]: tabIndex === "1"})}
          />
        </WildHealthTabs>
      </Box>
      <Box className={classes.tabContentSection}>
        <Box className={classes.editBtnSection}>
          <WildHealthClickableTooltip
            tooltipContent={tooltipContent}
            isOpened={open}
            handleClose={handleTooltipClose}
            customClasses={customTooltipClasses}
          >
            <Button className={classes.button} onClick={handleTooltipOpen} size="small">
              <BorderColorIcon fontSize='small' />
              <Box className={classes.buttonText}>Edit</Box>
            </Button>
          </WildHealthClickableTooltip>
        </Box>
        {tabIndex == "0" && renderAddresscontent(patient.billingAddress)}
        {tabIndex === "1" && renderAddresscontent(patient.shippingAddress)}
      </Box>
    </Fragment>
  );
};
