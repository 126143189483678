import React from "react";
import {
    ConversationAuthorModel,
    ConversationModelBase,
} from "../../models/conversation.models";
import { useFacade } from "./conversationComponent.hooks";
import { Avatar, Box, Divider, Grid, useTheme, useMediaQuery } from "@material-ui/core";
import { SendMessageComponent } from "../sendMessageComponent/SendMessageComponent";
import { SendMessageComponentMobile } from "../sendMessageComponentMobile/SendMessageComponentMobile";
import { EditScheduledMessage } from "../editScheduledMessage/EditScheduledMessage";
import { ScheduleMessageDialogComponent } from "../scheduleMessageDialogComponent/ScheduleMessageDialogComponent";
import clsx from "clsx";
import { ThreadComponent } from "../threadComponent/ThreadComponent";
import { useStyles, useDropStyles } from "./conversationComponent.styles";
import { convertMessagesToGroups, displayShortName, convertMessagesToGroupsForView } from "../../helpers/messages.converter";
import { ConversationState } from "../../models/conversationState.models";
import Dropzone from "react-dropzone";
import { CloudUploadOutlined } from "@material-ui/icons";
import moment from "moment";
import { toCurrentTimeZone } from "../../../timezones/helpers/timezone";
import { ActionButton } from "../sendMessageComponent/sendMessageComponent.styles";
import { ReactComponent as AttachIcon } from '@img/icons/Attach.svg';
import { authQuery } from "../../../auth/stores/auth";
import { EditAiMessage } from "../editAiMessage/EditAiMessage";
import { ProtectedElement } from '../../../common/components/ProtectedElement';
import { UserType } from '../../../auth/models/auth.enums';
import { SelectShortcutComponent } from "../../../healthReport/components/selectShortcutComponent/SelectShortcutComponent";

interface ConversationComponentProps {
    conversation: ConversationModelBase;
    author: ConversationAuthorModel;
    threadHeight?: string;
    canSchedule?: boolean;
}

export const ConversationComponent: React.FC<ConversationComponentProps> = (
    props: ConversationComponentProps
) => {
    const { conversation, author, threadHeight, canSchedule } = props;

    const classes = useStyles();
    const dropClasses = useDropStyles();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const [
        {
            isLoadingMessages,
            isScheduleDialogOpen,
            scheduledMessageForEditing,
            scheduledAttachmentsForEditing,
            toSendAttachments,
            scrollToIndex,
            lastReadMessageIndex,
            messages,
            messagesStaff,
            typing,
            emptyMessage,
            aiMessageForEditing,
            detailForAiMessage
        },
        handleSendMessage,
        handleDropAttachment,
        handleRemoveAttachment,
        handleTyping,
        handleOpenScheduleDialog,
        handleToggleScheduleDialog,
        handleScheduleMessage,
        handleEndEditScheduledMessage,
        handleSetMessageUnread,
        handleUpdateInteraction,
        handleEndEditAiMessage,
        handleEditInteraction
    ] = useFacade(conversation, author);

    const DynamicSendMessageComponent = isSmallScreen
        ? SendMessageComponentMobile
        : SendMessageComponent;

    const messageGroups = conversation && !isLoadingMessages
        ? !conversation.proxy
            ? convertMessagesToGroupsForView(
                messagesStaff,
                authQuery.isEmployeeUser() ? conversation.employees.find(e => e.employeeId === authQuery.getEmployeeId())?.vendorExternalIdentity : conversation.patients[0].vendorExternalIdentity,
                conversation.patients,
                conversation.employees
            )
            : convertMessagesToGroups(
                messages,
                author,
                conversation.patients,
                conversation.employees
            )
        : [];

    const isScheduledMessageEditing = Boolean(scheduledMessageForEditing);
    const isAiMessageForEditing = Boolean(aiMessageForEditing);

    const fileTypes = ["application/pdf", "image/jpeg", "image/png"];

    const displayNameAndDate = (name: string, date: Date): string => {
        return name ? `by ${name} on ${moment(toCurrentTimeZone(date)).format("MMM D, YYYY")}` : '';
    }

    return (
        <Dropzone onDrop={handleDropAttachment} accept={fileTypes}>
            {({ getRootProps, getInputProps, isDragActive }) => {
                const rootProps = getRootProps();
                const handleAttach = rootProps["onClick"];
                delete rootProps["onClick"];

                const sendMessageSection =
                    conversation?.state !== ConversationState.Closed ? (
                        <>
                            <Divider className={classes.divider} orientation="horizontal" />

                            <Box className={classes.sendMessage} {...rootProps}>
                                <Box
                                    className={classes.noneOutline}
                                    display={canSchedule && isScheduledMessageEditing ? "block" : "none"}
                                >
                                    <EditScheduledMessage
                                        message={
                                            isScheduledMessageEditing ? scheduledMessageForEditing.message : ""
                                        }
                                        attachments={isScheduledMessageEditing ? scheduledAttachmentsForEditing : []}
                                        attachmentsNew={toSendAttachments}
                                        handleConfirm={(message, removeAttachmentIds, attachments) => handleOpenScheduleDialog(message, removeAttachmentIds, attachments)}
                                        handleDiscard={() => handleEndEditScheduledMessage()}
                                        handleRemoveAttachmentNew={handleRemoveAttachment}
                                    />
                                    <Box p={1}>
                                        <ActionButton id="send-message-attach" size='medium' onClick={handleAttach}>
                                            <AttachIcon />
                                        </ActionButton>
                                    </Box>
                                </Box>
                                <Box
                                    className={classes.noneOutline}
                                    display={(canSchedule && isScheduledMessageEditing) || isAiMessageForEditing ? "none" : "block"}
                                >
                                    <DynamicSendMessageComponent
                                        handleSubmit={(message) => handleSendMessage(message)}
                                        handleRemoveAttachment={handleRemoveAttachment}
                                        handleSchedule={(message) => handleOpenScheduleDialog(message, [], [])}
                                        inputProps={getInputProps()}
                                        handleAttach={handleAttach}
                                        attachments={toSendAttachments}
                                        emptyMessage={emptyMessage}
                                        handleTyping={handleTyping}
                                        canSchedule={canSchedule}
                                        canAttach
                                    />
                                </Box>
                                <ProtectedElement
                                    element={<Box
                                        className={classes.noneOutline}
                                        display={isAiMessageForEditing ? "block" : "none"}
                                    >
                                        <EditAiMessage
                                            message={
                                                isAiMessageForEditing ? detailForAiMessage : ""
                                            }
                                            handleConfirm={(message) => handleEditInteraction(message)}
                                            handleDiscard={() => handleEndEditAiMessage()}
                                        />
                                    </Box>}
                                    permissions={[]}
                                    userType={UserType.Employee}
                                />
                                {canSchedule && (
                                    <ScheduleMessageDialogComponent
                                        isOpen={isScheduleDialogOpen}
                                        handleToggle={() => handleToggleScheduleDialog()}
                                        handleSubmit={(date, time) => handleScheduleMessage(date, time)}
                                    />
                                )}
                            </Box>
                            <ProtectedElement
                                element={<SelectShortcutComponent />}
                                permissions={[]}
                                userType={UserType.Employee}
                            />
                        </>
                    ) : (
                        <Box className={classes.closedTicket}>
                            {`This ticket was closed ${displayNameAndDate(conversation.stateChangeEmployeeName, conversation.stateChangeDate)}.`}
                        </Box>
                    );

                return isDragActive ? (
                    <div {...rootProps} className={dropClasses.dropContainer}>
                        <CloudUploadOutlined className={dropClasses.dropIcon} />
                        <span className={dropClasses.dropTitle}>Release to Upload</span>
                    </div>
                ) : (
                    <>
                        <Box
                            id={`thread-${conversation?.id}`}
                            className={clsx(classes.thread, "flat-scroll")}
                            height={threadHeight}
                            maxHeight={threadHeight}
                            {...rootProps}
                        >
                            <ThreadComponent
                                isLoadingMessages={isLoadingMessages}
                                messageGroups={messageGroups}
                                lastReadMessageIndex={lastReadMessageIndex}
                                handleSetMessageUnread={handleSetMessageUnread}
                                scrollToIndex={scrollToIndex}
                                threadStartId={`thread-start-${conversation?.id}`}
                                isProxy={Boolean(conversation.proxy)}
                                handleUpdateInteraction={handleUpdateInteraction}
                            />
                        </Box>
                        <Grid container alignItems="center">
                            {typing.map((item, index) => (
                                <Box key={index} display="flex" alignItems="center" ml={1} mb={1} px={1}>
                                    <Avatar
                                        className={classes.typingAvatar}
                                        variant="rounded"
                                        src={item.image}
                                    >
                                        {displayShortName(item.firstName, item.lastName)}
                                    </Avatar>
                                    <Box ml={1}>{`${item.firstName} ${item.lastName}`}</Box>
                                </Box>
                            ))}
                            {typing.length ? <Box mb={1}>is typing . . .</Box> : <></>}
                        </Grid>
                        {sendMessageSection}
                    </>
                );
            }}
        </Dropzone>
    );
};
