import React, { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { onEmit } from "../../../common/helpers/on-emit";
import { CallbackModel } from "../../../common/models/callback.model";
import { PaymentPlanModel } from "../../models/paymentPlan.models";
import { paymentPlansService } from "../../services/paymentPlans.service";
import { SendPatientToWaitListModel } from "../../../patients/models/patient.model";
import { IErrorState } from "../../../common/validation/error-state";
import Recaptcha from 'react-google-invisible-recaptcha';
import { waitListDialogComponentValidator } from "./waitListDialogComponent.validator";
import { patientsService } from "../../../patients/services/patients.service";
import { authQuery } from "../../../auth/stores/auth";
import { SITE_KEY } from "../../../../config";

interface WaitListDialogComponentState extends IErrorState {
    model: SendPatientToWaitListModel;
    limitationReason: string;
    isOpen: boolean;
    isSent: boolean;
    callback: Function;
    isLoading: boolean;
}

const defaultState: WaitListDialogComponentState = {
    model: {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        state: '',
        practiceId: authQuery.getCurrentPracticeId(),
        paymentPlanId: 0,
        antiSpamToken: ''
    },
    limitationReason: '',
    isOpen: false,
    isSent: false,
    callback: null,
    errors: {},
    isLoading: false
}

export function useFacade(): [WaitListDialogComponentState, Function, Function, Function, Function] {
    const _siteKey = SITE_KEY;
    let _recaptcha = undefined;
    const [state, setState] = useState(defaultState);

    const handleSubmit = () => {
        waitListDialogComponentValidator.validateObjectAndSetState(state, setState, state.model);
        if (!waitListDialogComponentValidator.stateIsValid(state)) {
            return;
        }
        setState((state) => ({
            ...state,
            isLoading: true
        }))

        _recaptcha.execute();
    }

    const renderRecaptcha = () => {
        return <Recaptcha
            ref={ref => _recaptcha = ref}
            sitekey={_siteKey}
            onResolved={(token) => {
                state.model.antiSpamToken = token;
                patientsService.sendPatientToWaitList(state.model).subscribe(() => {
                    setState((state) => ({
                        ...state,
                        isSent: true,
                        isLoading: false
                    }))
                },
                    () => {
                        setState((state) => ({
                            ...state,
                            isLoading: false
                        }))
                    })
            }} />
    }

    const handleClose = () => {
        setState(defaultState);
    }

    const handleChanges = (field: string, value: string) => {
        waitListDialogComponentValidator.validateAndSetState(state, setState, field, value);

        const model = state.model;
        model[field] = value;
        setState({
            ...state,
            model: model
        });
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<CallbackModel<PaymentPlanModel>>(paymentPlansService.limitedPlanOnSelect, model => {
                setState(state => ({
                    ...state,
                    isOpen: true,
                    isSent: false,
                    limitationReason: model.data.limitationReason,
                    errors: {},
                    model: {
                        firstName: '',
                        lastName: '',
                        phoneNumber: '',
                        email: '',
                        state: '',
                        paymentPlanId: model.data.id,
                        practiceId: authQuery.getCurrentPracticeId(),
                        antiSpamToken: ''
                    },
                    callback: model.callback
                }));
            })
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [state, renderRecaptcha, handleChanges, handleSubmit, handleClose];
}