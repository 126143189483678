import React from 'react';
import './ScaleChartComponent.scss';
import {
    PieChart, Pie, Cell,
} from 'recharts';
import { Box } from '@material-ui/core';

export interface ScaleChartDiagramData {
    name: string,
    color: string,
}

interface ScaleChartComponentProps {
    data: ScaleChartDiagramData[];
    result: string;
}


export const ScaleChartComponent: React.FC<ScaleChartComponentProps> = (props: ScaleChartComponentProps) => {
    const { data, result } = props;
    const dataSet = data.map((x) => {
        return {value: 100, name: x.name, color: x.color};
    });

    const renderPointer = (result: string, data: ScaleChartDiagramData[]) => {
        const angle = (data.findIndex(x => x.name === result) * (180 / data.length)) + (180 / data.length / 2);
        return (
            <div className='pointer'
                 style={{
                     height: '5px',
                     width: '40%',
                     marginLeft: '11.75%',
                     marginTop: '5px',
                     background: '#000',
                     display: 'flex',
                     transform: `rotate(${angle}deg)`,
                     transformOrigin: 'top right',
                     borderRadius: '2.5px',

                 }}>
                <div style={{
                    width: '15px',
                    height: '15px',
                    borderRadius: '7.5px',
                    marginTop: '-5px',
                    background: '#000',
                }}>

                </div>
            </div>
        )
    }

    return (
        <>
            <Box width={300} height={150}>
                <PieChart width={300} height={150}>
                    <Pie
                        data={dataSet}
                        cx={150}
                        cy={150}
                        startAngle={180}
                        endAngle={0}
                        innerRadius={60}
                        outerRadius={120}
                        fill="#8884d8"
                        paddingAngle={1}
                        dataKey="value"
                        isAnimationActive={false}>
                        {
                            dataSet.map((entry, index) => <Cell key={`cell-${index}`} fill={entry.color} />)
                        }
                    </Pie>
                </PieChart>
                {renderPointer(result, data)}
            </Box>
            
        </>
    );
}