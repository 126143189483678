import { Tabs } from "@material-ui/core";
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import { colors } from "../../constants/colors";

export const WildHealthTabs = withStyles({
  root: {
    flex: 1,
    borderBottom: `1px solid ${colors.stroke}`,
  },
  indicator: {
    backgroundColor: colors.main,
  },
})(Tabs);

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tab: {
      textTransform: "uppercase",
      minWidth: 72,
      fontSize: 12,
      fontWeight: 500,
      padding: 30,
      fontFamily: ["Be Vietnam Pro"].join(","),
      "&:hover": {
        color: colors.main,
        opacity: 0.8,
      },
      "&:selected": {
        color: colors.main,
      },
      "&:focus": {
        color: colors.main,
      },
    },
    selectedTab: {
      backgroundColor: "#F7FAFC !important",
      color: `${colors.main} !important`,
    },
  })
);

export const useStylesSubTabs = makeStyles((theme: Theme) =>
  createStyles({
    tab: {
      textTransform: "none",
      minWidth: 72,
      fontSize: 12,
      fontWeight: 500,
      padding: "20px 0",
      fontFamily: ["Be Vietnam Pro"].join(","),
      "&:hover": {
        color: colors.main,
        opacity: 0.8,
      },
      "&:selected": {
        color: colors.main,
      },
      "&:focus": {
        color: colors.main,
      },
    },
    selectedTab: {
      color: `${colors.main} !important`,
    },
  })
);

export const useStylesSimpleTabs = makeStyles((theme: Theme) =>
  createStyles({
    tab: {
      textTransform: "uppercase",
      minWidth: 72,
      fontSize: 12,
      fontWeight: 500,
      padding: 10,
      fontFamily: ["Be Vietnam Pro"].join(","),
      "&:hover": {
        color: colors.main,
        opacity: 0.8,
      },
      "&:selected": {
        color: colors.main,
      },
      "&:focus": {
        color: colors.main,
      },
    },
    selectedTab: {
      backgroundColor: "#F7FAFC !important",
      color: `${colors.main} !important`,
    },
  })
);

export const useStylesFullWidthTabs = makeStyles((theme: Theme) =>
  createStyles({
    tab: {
      width: '50%',
      textTransform: "uppercase",
      minWidth: 72,
      fontSize: 12,
      fontWeight: 500,
      padding: 10,
      fontFamily: ["Be Vietnam Pro"].join(","),

      [theme.breakpoints.down("xs")]: {
        textTransform: "none",
        fontSize: 14,
        fontWeight: "normal",
      },

      "&:hover": {
        color: colors.main,
        opacity: 0.8,
      },
      "&:selected": {
        color: colors.main,
      },
      "&:focus": {
        color: colors.main,
      },
    },
    selectedTab: {
      color: `${colors.main} !important`,
      fontWeight: 500,
    },
  })
);

export const useStylesSideBarTabs = makeStyles((theme: Theme) =>
  createStyles({
    tab: {
      textTransform: "uppercase",
      minWidth: 72,
      fontSize: 12,
      fontWeight: 500,
      padding: "23px 31px 23px 31px",
      fontFamily: ["Be Vietnam Pro"].join(","),
      "&:hover": {
        color: colors.main,
        opacity: 0.8,
      },
      "&:selected": {
        color: colors.main,
      },
      "&:focus": {
        color: colors.main,
      },
    },
    selectedTab: {
      backgroundColor: "#F7FAFC !important",
      color: `${colors.main} !important`,
    },
  })
);

export const useStylesThreeWidthTabs = makeStyles((theme: Theme) =>
  createStyles({
    tab: {
      textTransform: "none",
      minWidth: 100,
      fontSize: 12,
      fontWeight: 500,
      padding: "10px 16px",
      fontFamily: ["Be Vietnam Pro"].join(","),

      [theme.breakpoints.down("xs")]: {
        width: '33.3%',
        textTransform: "none",
        fontSize: 14,
        fontWeight: "normal",
      },

      "&:hover": {
        color: colors.main,
        opacity: 0.8,
      },
      "&:selected": {
        color: colors.main,
      },
      "&:focus": {
        color: colors.main,
      },
    },
    selectedTab: {
      color: `${colors.main} !important`,
      fontWeight: 500,
    },
  })
);

export const useStylesMobileWidthTabs = makeStyles((theme: Theme) =>
  createStyles({
    tab: {
      textTransform: "none",
      minWidth: 72,
      fontSize: 14,
      fontWeight: 500,
      padding: 10,
      fontFamily: ["Be Vietnam Pro"].join(","),

      "&:hover": {
        color: colors.main,
        opacity: 0.8,
      },
      "&:selected": {
        color: colors.main,
      },
      "&:focus": {
        color: colors.main,
      },
    },
    selectedTab: {
      color: `${colors.main} !important`,
      fontWeight: 500,
    },
  })
);
