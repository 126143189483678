import { Box, Avatar, Badge, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import AvatarGroup from "@material-ui/lab/AvatarGroup";

export interface ParticipantViewModel {
  image: string;
  initials: string;
}

interface MessageAvatarsComponentProps {
  participants: ParticipantViewModel[];
  maxParticipantsCount?: number;
  size?: number;
}

export const MessageAvatarsComponent: React.FC<MessageAvatarsComponentProps> = (
  props: MessageAvatarsComponentProps
) => {
  const { participants, maxParticipantsCount = 2, size = 40 } = props;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const smallScreenFirstAvatar = isSmallScreen && participants.length == maxParticipantsCount && (
    <Avatar
      variant="circular"
      src={participants[1]?.image}
      style={{ width: size, height: size, fontSize: 16 }}
    >
      {participants[1]?.initials}
    </Avatar>
  )

  const smallScreenSecondAvatar = isSmallScreen && (
    <Avatar
      variant="circular"
      src={participants[0]?.image}
      style={{ width: size, height: size, fontSize: 16 }}
    >
      {participants[0]?.initials}
    </Avatar>
  )

  const firstAvatar = participants.length > maxParticipantsCount && (
    <Avatar variant="circular" style={{ width: size, height: size, fontSize: 16 }}>
      {`+${participants.length - maxParticipantsCount + 1}`}
    </Avatar>
  );

  const secondAvatar = participants
    .slice(0, maxParticipantsCount - (participants.length > maxParticipantsCount ? 1 : 0))
    .map((i, index) => (
      <Avatar
        key={index}
        variant="circular"
        src={i?.image}
        style={{ width: size, height: size, fontSize: 16 }}
      >
        {i?.initials}
      </Avatar>
    ));

  return (
    <>
      {isSmallScreen ?
        participants.length < maxParticipantsCount ? (
          <Box pt={0.5}>
            <Badge overlap="rectangle">
              {smallScreenSecondAvatar}
            </Badge>
          </Box>
        ) : participants.length === maxParticipantsCount ? (
          <Box style={{ paddingLeft: 16 }}>
            <Badge
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              badgeContent={smallScreenFirstAvatar}
            >
              {smallScreenSecondAvatar}
            </Badge>
          </Box>
        ) : (
          <Box style={{ paddingLeft: 16 }}>
            <Badge
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              badgeContent={firstAvatar}
            >
              {smallScreenSecondAvatar}
            </Badge>
          </Box>
        ) : (
          <AvatarGroup>
            {secondAvatar}
            {firstAvatar}
          </AvatarGroup>
        )}
    </>
  );
};
