import './ShortcutGroupsListComponent.scss';
import React from "react";
import { Box, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction } from "@material-ui/core";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from "@material-ui/icons/Search";
import placeholderImg from "@img/placeholders/EmptyReport.png";
import { useFacade } from "./shortcutGroupsListComponent.hooks";
import { useStyles, SearchTextField } from "./shortcutGroupsListComponent.styles";
import { ShortcutGroupModel } from "../../../models/shortcut.models";
import { WildHealthPlaceHolderBase } from '../../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolderBase';

interface ShortcutGroupsListComponentProps {
    groups: ShortcutGroupModel[];
}

export const ShortcutGroupsListComponent: React.FC<ShortcutGroupsListComponentProps> = (props: ShortcutGroupsListComponentProps) => {
    const {
        groups,
    } = props;

    const [
        {
            expanded,
            selectedShortcut,
            filteredGroup
        },
        handleGroupClick,
        handleShortcutClick,
        renderGroupIcon,
        handleFiltering
    ] = useFacade(groups);

    const classes = useStyles();

    const renderList = () => {
        return (
            <Box className={classes.groups}>
                <List className={classes.list}> {
                    filteredGroup.map((group, index) =>
                        <div key={`group-${group.id}`}>
                            <ListItem
                                id={`shortcut-groups-${group.id}`}
                                className={selectedShortcut && expanded.includes(group.id) && group.id === selectedShortcut.groupId ? 'selected' : ''}
                                onClick={() => handleGroupClick(group)}
                                key={index}
                                button
                            >
                                <ListItemIcon>
                                    {renderGroupIcon(group)}
                                </ListItemIcon>
                                <ListItemText primary={<span className="wh-tw-font-medium">{group.name}</span>} />
                                {
                                    group.shortcuts.length > 0 && 
                                        <ListItemSecondaryAction>
                                            {!expanded.includes(group.id) ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                                        </ListItemSecondaryAction>
                                }
                            </ListItem>
                            {
                                expanded.includes(group.id) &&
                                <List disablePadding>
                                    {
                                        group.shortcuts.map((shortcut) =>
                                            <ListItem
                                                id={`shortcut-${shortcut.id}`}
                                                className={selectedShortcut && shortcut.id === selectedShortcut.id ? 'selected' : ''}
                                                onClick={() => handleShortcutClick(shortcut)}
                                                key={`shortcut-${shortcut.id}`}
                                                button
                                            >
                                                <ListItemIcon />
                                                <ListItemText primary={shortcut.displayName} />
                                            </ListItem>
                                        )
                                    }
                                </List>
                            }
                        </div>
                    )
                }
                </List>
            </Box>
        )
    }

    return (
        <Box>
            <Box mx={3} my={1}>
                <SearchTextField
                    fullWidth
                    variant="outlined"
                    className={classes.search}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleFiltering(e.target.value)
                    }
                    placeholder="Search"
                    InputProps={{
                        inputProps: {
                            className: classes.searchBase,
                        },
                        startAdornment: <SearchIcon className={classes.icon} />,
                    }}
                />
            </Box>
            {filteredGroup.length > 0 ? renderList() : (
                <Box className={classes.groups} display="flex" justifyContent="center">
                    <Box maxWidth="285px">
                        <WildHealthPlaceHolderBase message="No groups to display.">
                            <img
                                src={placeholderImg}
                                alt="img"
                                className={classes.image} />
                        </WildHealthPlaceHolderBase>
                    </Box>
                </Box>
            )}
        </Box>
    )
};