import { PatientProfileModel } from "../../account/models/patientProfile.model";
import { EmployeeType } from "../../employee/models/employee.enums";
import { ConversationState } from "./conversationState.models";
import { MessageSettingsModel } from "./message.models";

export class ConnectionStatuses {
    public static connecting = "connecting";
    public static connected = "connected";
    public static disconnecting = "disconnecting";
    public static disconnected = "disconnected";
    public static denied = "denied";
    public static joined = "joined";
}

export enum ConversationType {
    Support = 1,
    HealthCare = 5,
    Internal = 10,
    GenericPlayground = 15,
    PatientPlayground = 20
}

export const conversationTypeNames = new Map<ConversationType, string>([
    [ConversationType.HealthCare, 'Health'],
    [ConversationType.Support, 'Support'],
    [ConversationType.Internal, 'Internal'],
    [ConversationType.GenericPlayground, 'Generic Playground'],
    [ConversationType.PatientPlayground, 'Patient Playground'],
])

export const llmConversationTypes: ConversationType[] = [
    ConversationType.GenericPlayground,
    ConversationType.PatientPlayground
]

export const conversationTitles = new Map<ConversationType, string>([
    [ConversationType.HealthCare, 'Care Team'],
    [ConversationType.Support, 'Support'],
    [ConversationType.Internal, 'Internal'],
])

export interface ConversationAuthorModel {
    id: string;
    firstName: string;
    lastName: string;
    conversationAuthToken: string;
    conversationIdentity: string;
}

export interface ConversationModelBase {
    id: number;
    startDate: Date;
    endDate: Date;
    type: ConversationType;
    state: ConversationState;
    stateChangeDate: Date;
    stateChangeEmployeeId: number;
    stateChangeEmployeeName: string;
    vendorExternalId: string;
    subject: string;
    locationId: number;
    employees: EmployeeConversationParticipantModel[];
    patients: PatientConversationParticipantModel[];
    unreadMessages: number | undefined;
    lastMessageBody: string;
    lastMessageAt: Date;
    oldestUnreadMessageAt : Date;
    proxy: any;
    isOpen?: boolean;
    description: string;
}

export enum ReactionType
{
    None = 0,
    Like = 1,
    Dislike = 2
}

export enum InteractionType
{
    Recommendation = 1,
    RecommendationAccepted = 2,
    RecommendationRejected = 3,
    RecommendationEdited = 4
}

export interface ReactionModel {
    Id: string;
    Type: ReactionType;
    CreatedAt: Date;
    CreateBy: string;
}

export interface InteractionModel {
    Id: string;
    ReferenceId: string;
    Detail: string;
    Type: InteractionType;
    CreatedAt: Date;
    CreateBy: string;
}

export interface GenerateAiAttribute {
    Reactions: ReactionModel[];
    Interactions: InteractionModel[];
}

export interface AcceptInteractionModel extends RejectInteractionModel {
    detail: string;
}

export interface RejectInteractionModel {
    conversationId: string;
    messageId: string;
    referenceId: string;
    participantId: string;
}

export interface TargetInteractionModel {
    conversationId: string;
    messageSid: string;
    interaction: InteractionModel;
}

export interface ConversationModelBaseViewForAiDemo extends ConversationModelBaseView {
    isAi: boolean;
}

export interface ConversationModelBaseView {
    index: number;
    author: string;
    body: string;
    dateCreated: Date;
    dateUpdated: Date;
    sid: string;
    participantSid: string;
    media?: any[]
    accountSid: string;
    conversationSid: string;
    url: string;
    attributesString: string;
}

export interface ParticipantModel {
    userId: number;
    image: string;
    firstName: string;
    lastName: string;
    location: string;
    vendorExternalIds: string[];
    vendorExternalIdentity: string;
    disableRemove: boolean;
    subscriptionPlanName: string;
    hasActiveSubscription: boolean;
}

export interface PatientConversationParticipantModel extends PatientProfileModel, ParticipantModel {
    patientId: number;
    identity: string;
}

export interface EmployeeConversationParticipantModel extends ParticipantModel {
    id: number;
    employeeId: number;
    email: string;
    isOwner: boolean;
    isSigned: boolean;
    isActive: boolean;
    isDelegated: boolean;
    isDeleted: boolean;
    type: EmployeeType;
    settings: MessageSettingsModel;
    signDate: Date;
}

export interface PatientConversationModel extends ConversationModelBase {

}

export interface EmployeeConversationModel extends ConversationModelBase {

}

export interface LastReadMessageUpdateModel {
    conversationExternalVendorId: string;
    participantExternalVendorId: string;
    lastMessageReadIndex: number;
}

export interface StartPatientSupportConversation {
    subject: string;
}

export interface UpdateEmployeeSupportConversation {
    id: number;
    state: ConversationState;
}

export interface AddEmployeeToConversationModel {
    conversationId: number;
    employeeId: number;
}

export interface RemoveEmployeeFromConversationModel {
    conversationId: number;
    userId: number;
}

export interface CreateConversationModel {
    targetId: number;
    type: ConversationType;
}

export interface CreateMediaUrlModel {
    mediaSid: string;
}

export interface CreateInternalConversationModel {
    patientId: number;
    employeeIds: number[];
}

export enum ChatFilterTypes {
    Active = 1,
    InActive = 5,
    Closed = 10,
    Assigned = 15,
    SignedOff = 20,
}

export const chatFilterTypesNames = new Map<ChatFilterTypes, string>([
    [ChatFilterTypes.Active, 'All Chats'],
    [ChatFilterTypes.InActive, 'In Active'],
    [ChatFilterTypes.Closed, 'Closed'],
    [ChatFilterTypes.Assigned, 'Assigned'],
    [ChatFilterTypes.SignedOff, 'Signed Off'],
])

export interface LastConversationModel {
    sentDate: Date;
    sentByRole: string;
}

export enum ChatPatientFilterTypes {
    Active = 1,
    InActive = 5,
    All = 10,
}

export const chatPatientFilterTypesNames = new Map<ChatPatientFilterTypes, string>([
    [ChatPatientFilterTypes.Active, 'Active'],
    [ChatPatientFilterTypes.InActive, 'Inactive'],
    [ChatPatientFilterTypes.All, 'All']
])

export enum DeleteMessageReasonTypes {
    WrongRecipient = 1,
    WrongContent = 2,
    WrongMessageOrTypo = 3,
    AiMistake = 4
}

export const deleteMessageReasonTypesNames = {
  [DeleteMessageReasonTypes.WrongRecipient]: "This message was intended for someone else.",
  [DeleteMessageReasonTypes.WrongContent]: "The wrong link or attachment was sent to the patient.",
  [DeleteMessageReasonTypes.WrongMessageOrTypo]: "The message needs to be worded differently or there was a typo.",
  [DeleteMessageReasonTypes.AiMistake]: "The AI response was wrong or accepted by accident."
};

export interface DeleteMessageModel {
    conversationId: string;
    messageId: string;
    reason: DeleteMessageReasonTypes
}

export interface ConversationAwayMessageTemplateModel {
    id: number;
    title: string;
    body: string;
    isActive: boolean;
}

export interface CreateConversationAwayMessageTemplateModel {
    title: string;
    body: string;
}

export interface UpdateConversationAwayMessageTemplateModel {
    id: number;
    title: string;
    body: string;
}

export interface FavoriteConversationModel {
    id: number;
    employeeIds: number[];
}

export enum MessageAlertType {
    TicketRequestAlert = 1
}

export enum MessageAlertActionType
{
    Accepted = 1,
    Rejected = 5
}

export interface MessageAlertAudienceModel {
    type: string
}

export interface MessageAlertActionModel {
    type: MessageAlertType;
    details: string;
    reactedAt: Date;
    reactedBy: number;
}

export interface KeyValuePairModel {
    key: string,
    value: string;
}

export interface AlertModel {
    id: string;
    type: MessageAlertType;
    createdAt: Date;
    expiresAt: Date;
    audience: MessageAlertAudienceModel[];
    actions: MessageAlertActionModel[];
    data: KeyValuePairModel[];
}

export interface ReactOnConversationMessageAlertModel {
    alertId: string;
    conversationId: string;
    messageId: string;
    actionType: MessageAlertActionType;
    details: string;
}

export interface UpdateConversationSubject {
    id: number;
    newSubject: string;
}

export interface StartGenericPlaygroundConversationModel {
    prompt: string;
}

export interface StartPatientPlaygroundConversationModel {
    patientId: number;
    prompt: string;
}

export interface ConversationTemplateModel {
    id: number;
    name: string;
    description: string;
    text: string;
    order: number;
    type: ConversationType;
    isPrivate: boolean;
}

export interface UpdateConversationTemplateModel {
    id: number;
    name: string;
    description: string;
    text: string;
    order: number;
    type: ConversationType;
}


export interface AddReactionModel {
    messageId: string;
    conversationId: string;
    participantId: string;
    type: ReactionType;
}