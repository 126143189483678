import Axios from 'axios-observable';
import {Observable} from "rxjs";
import { authHeader } from "../../common/helpers/auth-header";
import { API_URL } from "../../../config";
import { snackService } from '../../common/snack/state';
import { ConversationAwayMessageTemplateModel, CreateConversationAwayMessageTemplateModel, UpdateConversationAwayMessageTemplateModel } from '../../conversations/models/conversation.models';
import { EmployeeConversationsStore, employeeConversationsStore } from '../../conversations/stores/employeeConversationsStore/employeeConversations.store';


export class AwayMessageTemplatesService {
    private url = `${API_URL}ConversationAwayMessageTemplates`;

    constructor(private employeeConversationsStore: EmployeeConversationsStore) {
    }

    public getAllTemplates(): Observable<ConversationAwayMessageTemplateModel[]> {
        const config = {
            headers: authHeader()
        };
        return new Observable<ConversationAwayMessageTemplateModel[]>(observer => {
            Axios.get<ConversationAwayMessageTemplateModel[]>(`${this.url}/all`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.employeeConversationsStore.update({ awayMessageTemplates: response.data })
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public createTemplate(model: CreateConversationAwayMessageTemplateModel) {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.post(`${this.url}`, model, config)
                .pipe()
                .subscribe(
                    response => {
                        snackService.success('Template successfully created');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    public updateTemplate(model: UpdateConversationAwayMessageTemplateModel) {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.put(`${this.url}`, model, config)
                .pipe()
                .subscribe(
                    response => {
                        snackService.success('Template successfully updated');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    public deleteBanner(id: number) {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.delete(`${this.url}/${id}`, config)
                .pipe()
                .subscribe(
                    () => {
                        snackService.success('Template successfully deleted');
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }
}

export const awayMessageTemplatesService = new AwayMessageTemplatesService(employeeConversationsStore);
