import { Box, IconButton } from '@material-ui/core';
import React from 'react';
import { useStyles } from '../staffChatComponent/staffChatComponent.styles';
import { MessagesPlaceHolder } from '../../../common/components/wildHealthPlaceHolder/MessagesPlaceHolder';
import { StaffChatState, useFacade } from "./staffChatViewComponent.hooks";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { ConversationViewComponent } from "../conversationViewComponent/ConversationViewComponent";
import { StaffConversationsHistoryComponent } from "../staffConversationsHistoryComponent/StaffConversationsHistoryComponent";
import { conversationTitles, ConversationType, PatientConversationModel } from "../../models/conversation.models";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { MessageAvatarsComponent } from '../messageAvatarsComponent/MessageAvatarsComponent';
import { SecondaryButton } from '../../../common/components/wildHealthButton/SecondaryButton';
import DownloadButton from "../../../common/components/wildHealthButton/downloadButton/DownloadButton";
import {colors} from "../../../common/constants/colors";
import GetAppIcon from "@material-ui/icons/GetApp";
import {
    StaffOrHealthCoachMessagesEnum
} from "../patientConversationsViewComponent/patientConversationsViewComponent.hooks";


interface StaffChatComponentProps {
    isLoading: boolean;
    conversations: PatientConversationModel[];
    openFullChat: () => void;
}

export const StaffChatViewComponent: React.FC<StaffChatComponentProps> = (props: StaffChatComponentProps) => {
    const { isLoading, conversations } = props;

    const [
        {
            view,
            isLoadingMessages,
            targetConversation,
            messagesStaff,
        },
        handleOpenConversation,
        handleNavigateToMessageHistory,
        downloadMessages
    ] = useFacade();

    const classes = useStyles();

    const renderHeaderArea = () => {
        return (
            <Box p={2} px={3}>
                <Box display='flex' justifyContent='space-between'>
                    <Box display='flex' alignItems='center'>
                        <Box mr={2}>
                            {
                                view !== StaffChatState.History &&
                                <IconButton id='staff-chat-view-go-back' onClick={() => handleNavigateToMessageHistory()}>
                                    <ArrowBackIcon />
                                </IconButton>
                            }
                        </Box>
                        <Box>
                            <MessageAvatarsComponent participants={[{ image: '', initials: 'S' }]} />
                        </Box>
                        <Box ml={2} display='flex' flexDirection='column'>
                            <Box className={classes.title}>
                                {conversationTitles.get(ConversationType.Support)}
                            </Box>
                        </Box>
                    </Box>
                    {view !== StaffChatState.History &&
                        <SecondaryButton id='staff-chat-view-open' onClick={() => props.openFullChat()} disabled={isLoadingMessages}>
                            Expand
                        </SecondaryButton>
                    }
                    <DownloadButton
                        id="download-button"
                        onClick={() => downloadMessages(StaffOrHealthCoachMessagesEnum.STAFF)}
                        disabled={isLoading || !messagesStaff?.length}
                        style = {{
                            background: colors.stroke
                        }}
                        width = {40}
                        square ={true}
                    >   <GetAppIcon/>
                    </DownloadButton>
                </Box>
            </Box>
        );
    }

    const renderConversationsHistory = () => {
        if (!conversations.length) {
            return <MessagesPlaceHolder message="No messages yet." />;
        }

        return (
            <Box m={2} mr={0}>
                <StaffConversationsHistoryComponent
                    height='calc(100vh - 410px)'
                    conversations={conversations}
                    handleSelect={(conversation) => handleOpenConversation(conversation)} />
            </Box>
        )
    }

    const renderSelectedConversation = () => {
        return (
            <>
                {
                    isLoadingMessages
                        ? <Box p={3}>< WildHealthLinearProgress /></Box >
                        : <ConversationViewComponent conversation={targetConversation} messages={messagesStaff} />
                }
            </>

        )
    }

    const renderContent = () => {
        switch (view) {
            case StaffChatState.History: return renderConversationsHistory();
            case StaffChatState.Selected: return renderSelectedConversation();
        }
    }

    return (
        <Box className={classes.root}>
            {renderHeaderArea()}
            {
                isLoading
                    ? <Box p={3}><WildHealthLinearProgress /></Box>
                    : renderContent()
            }
        </Box>
    )
};
