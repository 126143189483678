import { useState, useEffect } from "react";
import { IErrorState } from "../../../common/validation/error-state";
import { opsAdminToolsComponentValidator } from "./opsAdminToolsComponent.validator";
import { employeeService } from "../../services/employees.service";
import { BuyProductModel } from "../../../products/models/buyProduct.model";

export enum ToolEnum {
    CHANGE_SUBSCRIPTION_CANDIDATE = 'CHANGE_SUBSCRIPTION_CANDIDATE',
    PAYMENT_PLANS_RECOMMEND = 'PAYMENT_PLANS_RECOMMEND',
    PAYMENT_PLANS_MIGRATE = 'PAYMENT_PLANS_MIGRATE',
    BUY_PRODUCTS = 'BUY_PRODUCTS',
}

export interface PaymentPlanShortModel {
    name: string;
    displayName: string;
}

export interface PaymentCouponShortModel {
    code: string;
    detail: string | null;
}

type ParamsType = {
    CHANGE_SUBSCRIPTION_CANDIDATE: {
        patientId: number,
    },
    PAYMENT_PLANS_RECOMMEND: {
        fromSubscriptionId: number,
        startDate: Date,
        endDate: Date,
        planName: string,
        paymentStrategy: number,
        isInsurance: boolean,
        couponCode: string,
    },
    BUY_PRODUCTS: {
        patientId: number,
        productType: number,
        quantity: number,
        isPaidForByDefaultEmployer: boolean
    },
}

type MenuParamsState = {
    [key in ToolEnum]: ParamsType
}

const initialParamsType = {
    CHANGE_SUBSCRIPTION_CANDIDATE: {
        patientId: '',
    },
    PAYMENT_PLANS_RECOMMEND: {
        fromSubscriptionId: 0,
        startDate: new Date(),
        endDate: new Date(),
        planName: '',
        paymentStrategy: 1,
        isInsurance: false,
        couponCode: '',
    },
    BUY_PRODUCTS: {
        patientId: '',
        productType: 0,
        quantity: 0,
        isPaidForByDefaultEmployer: true
    },
}

export enum OpsAdminToolsStep {
    Step1 = 1,
    Step2 = 2,
    Step3 = 3
}

export interface StepItemModel {
    key: OpsAdminToolsStep;
    label: string;
}

const steps = [
    {
        key: OpsAdminToolsStep.Step1,
        label: "Step 1",
    },
    {
        key: OpsAdminToolsStep.Step2,
        label: "Step 2",
    },
    {
        key: OpsAdminToolsStep.Step3,
        label: "Step 3",
    },
] as StepItemModel[];

interface OpsAdminToolsComponentState extends IErrorState {
    isLoading: boolean;
    steps: StepItemModel[];
    currentStep: StepItemModel;
    currentToolEnum: ToolEnum;
    menuParams: MenuParamsState;
    subscriptionId: number;
    eligiblePaymentPlans: PaymentPlanShortModel[];
    eligiblePaymentCoupons: PaymentCouponShortModel[];
}

export function useFacade(patientId: number | null): [
    OpsAdminToolsComponentState,
    () => void,
    (toolEnum: ToolEnum) => void,
    any,
    (field: string, value: any, tool:ToolEnum) => void,
] {
    const [state, setState] = useState({
        isLoading: false,
        steps: steps,
        currentStep: steps[0],
        menuParams: initialParamsType as unknown as MenuParamsState,
        currentToolEnum: ToolEnum.CHANGE_SUBSCRIPTION_CANDIDATE,
        subscriptionId: 0,
        eligiblePaymentPlans: [],
        eligiblePaymentCoupons: [],
        errors: {},
    } as OpsAdminToolsComponentState);
    
    const handleToggle = (toolEnum: ToolEnum) => {
        setState(state => ({...state, currentToolEnum: toolEnum}));
    }

    const handleGoNext = () => {
        const currentIndex = state.steps.indexOf(state.currentStep);

        if(currentIndex + 1 === state.steps.length) {
            return;
        }

        setState(state => ({...state, currentStep: state.steps[currentIndex + 1]}))
    }

    const handleGoBack = () => {
        const currentIndex = state.steps.indexOf(state.currentStep);

        if(currentIndex === 0) {
            return;
        }

        setState(state => ({...state, currentStep: state.steps[currentIndex - 1]}))
    }

    const isValidState = (toolMenu: ToolEnum) => {
        const keys = Object.keys(state.menuParams[toolMenu]);
        const values = state.menuParams[toolMenu];
        const errorValues = state.errors;

        if(Object.keys(toolMenu).some(k => (!!errorValues[k])) || keys.every(k=> (!values[k]))) {
            return false;
        }
        return true
    }
    
    const changeSubscriptionCandidate = (patientId?: number) => {
        if (!isNaN(Number(patientId)) && !isValidState(ToolEnum.CHANGE_SUBSCRIPTION_CANDIDATE)) {
            return;
        }

        setState(state => ({
            ...state,
            isLoading: true
        }));

        const cb = () => setState(state => ({...state, isLoading: false}));

        employeeService
            .changeSubscriptionCandidate(!isNaN(Number(patientId)) ? patientId : state.menuParams[ToolEnum.CHANGE_SUBSCRIPTION_CANDIDATE]['patientId'])
            .subscribe((response) =>  {
                state.menuParams[ToolEnum.PAYMENT_PLANS_RECOMMEND] = response;
                state.menuParams[ToolEnum.PAYMENT_PLANS_RECOMMEND]['fromSubscriptionId'] = response.id;
                state.menuParams[ToolEnum.PAYMENT_PLANS_RECOMMEND]['isInsurance'] = false;
                setState(state => ({
                    ...state,
                    eligiblePaymentPlans: response.eligiblePaymentPlans,
                    eligiblePaymentCoupons: response.eligiblePaymentCoupons,
                    subscriptionId: response.id,
                    isLoading: false
                }))
                handleGoNext()
            }, cb)
    }

    const paymentPlansRecommend = () => {
        if (!isValidState(ToolEnum.PAYMENT_PLANS_RECOMMEND)) {
            return;
        }

        setState(state => ({
            ...state,
            isLoading: true
        }));

        const cb = () => setState(state => ({...state, isLoading: false}));

        employeeService
            .paymentPlanRecommend(state.menuParams[ToolEnum.PAYMENT_PLANS_RECOMMEND])
            .subscribe((response) => {
                state.menuParams[ToolEnum.PAYMENT_PLANS_RECOMMEND] = response;
                state.menuParams[ToolEnum.PAYMENT_PLANS_RECOMMEND]['fromSubscriptionId'] = state.subscriptionId;
                setState(state => ({
                    ...state,
                    isLoading: false
                }))
                handleGoNext()
            }, cb)
    }

    const paymentPlansMigrate = () => {
        if (!isValidState(ToolEnum.PAYMENT_PLANS_RECOMMEND)) {
            return;
        }

        setState(state => ({
            ...state,
            isLoading: true
        }));

        const cb = () => setState(state => ({...state, isLoading: false}));

        employeeService
            .migratePaymentPlan(state.menuParams[ToolEnum.PAYMENT_PLANS_RECOMMEND])
            .subscribe(() => {
                state.menuParams[ToolEnum.CHANGE_SUBSCRIPTION_CANDIDATE]['patientId'] = '';
                setState(state => ({
                    ...state,
                    subscriptionId: 0,
                    currentStep: steps[0],
                    isLoading: false,
                    errors: {}
                }))
            }, cb)
    }

    const buyProducts = () => {
        if (!isValidState(ToolEnum.BUY_PRODUCTS)) {
            return;
        }

        const keys = Object.keys(state.menuParams[ToolEnum.BUY_PRODUCTS]);
        const values = state.menuParams[ToolEnum.BUY_PRODUCTS];

        setState(state => ({
            ...state,
            isLoading: true
        }));

        const buyProductModel: BuyProductModel = {
            patientId: parseInt(values[keys[0]]),
            productType: parseInt(values[keys[1]]),
            quantity: parseInt(values[keys[2]]),
            isPaidForByDefaultEmployer: values[keys[3]]
        }

        const cb = () => setState(state => ({...state, isLoading: false}));

        employeeService.buyProducts(buyProductModel).subscribe(cb, cb)
    }

    const handleChanges = (field: string, value: any, tool: ToolEnum) => {

        opsAdminToolsComponentValidator.validateAndSetState(state, setState, field, value);

        setState({...state, ...state.menuParams[tool][field] = value });
    }

    const handleSubmit = {
        CHANGE_SUBSCRIPTION_CANDIDATE: changeSubscriptionCandidate,
        PAYMENT_PLANS_RECOMMEND: paymentPlansRecommend,
        PAYMENT_PLANS_MIGRATE: paymentPlansMigrate,
        BUY_PRODUCTS: buyProducts
    }

    const useEffectCB = () => {
        if (patientId) {
            handleChanges('patientId', patientId, ToolEnum.CHANGE_SUBSCRIPTION_CANDIDATE)
            changeSubscriptionCandidate(patientId)
        } else {
            state.menuParams[ToolEnum.CHANGE_SUBSCRIPTION_CANDIDATE]['patientId'] = '';
            setState(state => ({
                ...state,
                errors: {}
            }))
        }

        return () => {
        };
    };

    useEffect(useEffectCB, []);

    return [
        state,
        handleGoBack,
        handleToggle,
        handleSubmit,
        handleChanges
    ];
}