import {GeneralValidator} from "../../../common/validation/general-validator";
import {stringLengthValidationRule} from "../../../common/validation/general-validation-rules";

export const createLocationDialogComponentValidator = new GeneralValidator({
    'name': [stringLengthValidationRule(1, 50)],
    'description': [stringLengthValidationRule(0, 250)],
    'country': [stringLengthValidationRule(1, 50)],
    'state': [stringLengthValidationRule(1, 50)],
    'city': [stringLengthValidationRule(1, 50)],
    'zipCode': [stringLengthValidationRule(1, 10)],
    'streetAddress1': [stringLengthValidationRule(1, 100)],
    'streetAddress2': [stringLengthValidationRule(0, 100)],
});