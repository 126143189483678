import {useState} from 'react';
import {PageSliderNavigationContext} from "../../../common/models/navigation.models";
import {QuestionnaireModel, QuestionnairePages, QuestionnaireResultModel
} from "../../models/questionnaire.models";
import {navigationService} from "../../../../services/navigation.service";

/**
 * Represents Health Questionnaire Page state
 */
interface PatientQuestionnairePageState {
    currentPage: QuestionnairePages;
    result: QuestionnaireResultModel;
    questionnaire: QuestionnaireModel;
}

export function useFacade(result: QuestionnaireResultModel, questionnaire: QuestionnaireModel): [PatientQuestionnairePageState, Function] {
    const [state, setState] = useState({
        currentPage: QuestionnairePages.General,
        questionnaire: questionnaire,
        result: result
    } as PatientQuestionnairePageState);

    /**
     * Navigates to specified page
     * @param page
     */
    const goTo = (page: QuestionnairePages) => {
        setState({...state, currentPage: page});
        navigationService.goUp();
    }

    /**
     * Returns if can go back
     */
    const canGoBack = (): boolean => {
        return state.currentPage !== QuestionnairePages.General
    };

    /**
     * Goes back
     */
    const goBack = (): void => {
        switch (state.currentPage) {
            case QuestionnairePages.Medical:
                return goTo(QuestionnairePages.General);
            case QuestionnairePages.Nutrition:
                return goTo(QuestionnairePages.Medical)
            case QuestionnairePages.Exercise:
                return goTo(QuestionnairePages.Nutrition)
            case QuestionnairePages.CardioRespiratory:
                return goTo(QuestionnairePages.Exercise)
            case QuestionnairePages.Sleep:
                return goTo(QuestionnairePages.CardioRespiratory)
            case QuestionnairePages.Stress:
                return goTo(QuestionnairePages.Sleep)
        }
    };

    /**
     * Returns if can go next
     */
    const canGoNext = (): boolean => {
        if (state.currentPage !== QuestionnairePages.Stress) {
            return true;
        }
    };

    /**
     * Goes next
     */
    const goNext = (): void => {
        switch (state.currentPage) {
            case QuestionnairePages.General:
                return goTo(QuestionnairePages.Medical);
            case QuestionnairePages.Medical:
                return goTo(QuestionnairePages.Nutrition);
            case QuestionnairePages.Nutrition:
                return goTo(QuestionnairePages.Exercise);
            case QuestionnairePages.Exercise:
                return goTo(QuestionnairePages.CardioRespiratory);
            case QuestionnairePages.CardioRespiratory:
                return goTo(QuestionnairePages.Sleep);
            case QuestionnairePages.Sleep:
                return goTo(QuestionnairePages.Stress);
            default:
                return;
        }
    };

    /**
     * Returns navigation context
     */
    const getNavigationContext = (): PageSliderNavigationContext => {
        return {
            nextButton: "Next",
            backButton: "Back",
            canGoNext: canGoNext,
            canGoBack: canGoBack,
            goNext: goNext,
            goBack: goBack
        } as PageSliderNavigationContext
    }

    return [state, getNavigationContext]
}