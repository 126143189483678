import { Box, Divider, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { LabInputNames } from "../../inputs/constants/inputs.constants";
import { LabResultComponent } from "../components/labResultComponent/LabResultComponent";
import { LabelPosition, ProgressDiagramComponent } from "../components/progressDiagramComponent/ProgressDiagramComponent";
import { HealthReportSections, HealthReportMode } from "../models/healthReport.models";
import { HealthReportBasePage, HealthReportMobileBasePage } from "./HealthReportBasePage";
import { HealthReportPageProps } from "./healthReportPageProps";
import { renderScaleChartComponent } from "../helpers/renderScaleChartComponent";
import { useStyles } from './healthReport.styles';
import commonUseStyles from "../../common/styles/common.styles";
import clsx from "clsx";

export const HealthReportChronicDisease1Page: React.FC<HealthReportPageProps> = (props: HealthReportPageProps) => {
    const {
        report,
        pageNumber,
        practice
    } = props;

    const classes = useStyles();
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const labValues = report.labReport.values;

    return (
        props.mode !== HealthReportMode.PrintMode && isSmallScreen ? (
            <HealthReportMobileBasePage section={HealthReportSections.ChronicDisease} pageNumber={pageNumber} practice={practice}>
                <>
                    <Box px={2}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box>
                                    <span className={classes.reportTitle}>Cardiovascular Disease</span>
                                </Box>
                                <Box mt="10px">
                                    <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeMediumReport, commonClasses.textRegularReport)}>Your Cardiovascular Disease Risk</span>
                                </Box>
                                <Box mt="30px">
                                    <Box display="flex" justifyContent="space-between">
                                        <Box display="flex" alignItems="flex-end">
                                            <Box>
                                                <span className={clsx(classes.reportSubTitle, commonClasses.uppercase)}>10 year cvd risk</span>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <span className={clsx(commonClasses.textRegularReport, "report-accent-value-small")}>{report.longevityReport.mesaRiskScore.scorePercent.toFixed(1).toString()}%</span>
                                        </Box>
                                    </Box>
                                    <Box mt="20px">
                                        <ProgressDiagramComponent
                                            height={22}
                                            colors={['#8dc638', '#e4b000', '#de1a18', '#de1a18', '#de1a18', '#de1a18', '#de1a18', '#de1a18', '#de1a18']}
                                            data={[
                                                {
                                                    label: `${report.longevityReport.mesaRiskScore.scorePercent.toFixed(1).toString()}%`,
                                                    value: report.longevityReport.mesaRiskScore.scorePercent,
                                                    labelPosition: LabelPosition.Bottom
                                                }
                                            ]}
                                            unavailable={!report.longevityReport.mesaRiskScore.isCalculated}
                                        />
                                    </Box>
                                    <Box mt="30px" fontSize="14px">
                                        <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.textRegularReport)}>
                                            How your medical history affects your cardiovascular risk according to MESA.<span className={classes.referenceLink}>[1]</span>
                                        </span>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box pt="30px" textAlign="center">
                                    <span className={classes.reportTitle}> Your Cardiovascular Genetics and Labs </span>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box mt="10px">
                                    <Box textAlign="center">
                                        <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeBigReport, commonClasses.textRegularReport)} >
                                            Genetic CVD Risk
                                        </span>
                                    </Box>
                                    <Box display="flex" justifyContent="center" width="100%" mt="15px">
                                        {renderScaleChartComponent(report.longevityReport.cvdRiskScore)}
                                    </Box>
                                    <Box display="flex" justifyContent="center" mt={2}>
                                        <span className="text-medium">
                                            CVD Risk
                                        </span>
                                        <span className={classes.referenceLink}>[2]</span>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box mt="15px" fontSize="14px" lineHeight="22px">
                                    This genetic risk assessment is based on 27 different genes identified in a 2015 study from Harvard of over 48,000 patients identifying a 31-72% increase in cardiovascular disease risk based on these genetic factors alone. The orange quintile is associated with a 72% increase in risk, but also a 48% risk reduction with statin therapy.
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider className="report-divider" />
                    <Box px={2}>
                        <Box>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Box className="text-light-medium">
                                        <Box>
                                            <span className={classes.reportSubTitle}>Lipids</span>
                                        </Box>
                                        <Box mt={1}>
                                            <LabResultComponent label="Total Cholesterol" value={labValues.getLabReportValue(LabInputNames.TotalCholesterol)} />
                                            <LabResultComponent label="LDL" value={labValues.getLabReportValue(LabInputNames.LdlCholesterol)} />
                                            <LabResultComponent label="HDL" value={labValues.getLabReportValue(LabInputNames.HdlCholesterol)} />
                                            <LabResultComponent label="Triglycerides" value={labValues.getLabReportValue(LabInputNames.Triglycerides)} />
                                            <LabResultComponent label="LDL(p)" value={labValues.getLabReportValue(LabInputNames.LdlP)} />
                                            <LabResultComponent label="Lp(a)" value={labValues.getLabReportValue(LabInputNames.LpA)} />
                                            <LabResultComponent label="APO-B" value={labValues.getLabReportValue(LabInputNames.ApolipoproteinB)} />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box className="text-light-medium" mt="20px">
                                        <Box>
                                            <span className={classes.reportSubTitle}>Inflammation</span>
                                        </Box>
                                        <Box mt={1}>
                                            <LabResultComponent label="LP PLA2" value={labValues.getLabReportValue(LabInputNames.LpPla2)} />
                                            <LabResultComponent label="CRP" value={labValues.getLabReportValue(LabInputNames.Crp)} />
                                            <LabResultComponent label="Omega 3" value={labValues.getLabReportValue(LabInputNames.Omega3)} />
                                            <LabResultComponent label="Coq10" value={labValues.getLabReportValue(LabInputNames.CoQ10)} />
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </>
            </HealthReportMobileBasePage>
        ) : (
            <HealthReportBasePage section={HealthReportSections.ChronicDisease} pageNumber={pageNumber} practice={practice}>
                <>
                    <Box px={15} pt={5} pb={1}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box>
                                    <span className="report-title">Cardiovascular Disease</span>
                                </Box>
                                <Box mb={3}>
                                    <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeMediumReport, commonClasses.textMediumReport)}>Your Cardiovascular Disease Risk</span>
                                </Box>
                                <Box mt={5}>
                                    <Box display="flex" justifyContent="space-between">
                                        <Box display="flex" alignItems="flex-end">
                                            <Box>
                                                <span className="report-sub-title uppercase">10 year cvd risk</span>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <span className="report-accent-value">{report.longevityReport.mesaRiskScore.scorePercent.toFixed(1).toString()}%</span>
                                        </Box>
                                    </Box>
                                    <Box mt={2}>
                                        <ProgressDiagramComponent
                                            height={22}
                                            colors={['#8dc638', '#e4b000', '#de1a18', '#de1a18', '#de1a18', '#de1a18', '#de1a18', '#de1a18', '#de1a18']}
                                            data={[
                                                {
                                                    label: `${report.longevityReport.mesaRiskScore.scorePercent.toFixed(1).toString()}%`,
                                                    value: report.longevityReport.mesaRiskScore.scorePercent,
                                                    labelPosition: LabelPosition.Bottom
                                                }
                                            ]}
                                            unavailable={!report.longevityReport.mesaRiskScore.isCalculated}
                                        />
                                    </Box>
                                    <Box mt={3} display="flex">
                                        <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeSmallReport)}>
                                            How your medical history affects your cardiovascular risk according to MESA.
                                        </span>
                                        <span className={classes.referenceLink}>[1]</span>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box pt={3} textAlign="center">
                                    <span className="report-title"> Your Cardiovascular Genetics and Labs </span>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box mt={3} ml={2}>
                                    <Box px={5} fontSize="28px" textAlign="center">
                                        <span className={commonClasses.tertiaryFontReport} >
                                            Genetic CVD Risk
                                        </span>
                                    </Box>
                                    <Box display="flex" justifyContent="center" width="100%">
                                        {renderScaleChartComponent(report.longevityReport.cvdRiskScore)}
                                    </Box>
                                    <Box display="flex" justifyContent="center" mt={2}>
                                        <span className="text-medium">
                                            CVD Risk
                                        </span>
                                        <span className={classes.referenceLink}>[2]</span>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box mt={5}>
                                    This genetic risk assessment is based on 27 different genes identified in a 2015 study from Harvard of over 48,000 patients identifying a 31-72% increase in cardiovascular disease risk based on these genetic factors alone. The orange quintile is associated with a 72% increase in risk, but also a 48% risk reduction with statin therapy.
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider className="report-divider" />
                    <Box px={15} pt={3}>
                        <Box>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Box className="text-light-medium" px={3}>
                                        <Box>
                                            <span className="report-sub-title">Lipids</span>
                                        </Box>
                                        <Box>
                                            <LabResultComponent label="Total Cholesterol" value={labValues.getLabReportValue(LabInputNames.TotalCholesterol)} />
                                            <LabResultComponent label="LDL" value={labValues.getLabReportValue(LabInputNames.LdlCholesterol)} />
                                            <LabResultComponent label="HDL" value={labValues.getLabReportValue(LabInputNames.HdlCholesterol)} />
                                            <LabResultComponent label="Triglycerides" value={labValues.getLabReportValue(LabInputNames.Triglycerides)} />
                                            <LabResultComponent label="LDL(p)" value={labValues.getLabReportValue(LabInputNames.LdlP)} />
                                            <LabResultComponent label="Lp(a)" value={labValues.getLabReportValue(LabInputNames.LpA)} />
                                            <LabResultComponent label="APO-B" value={labValues.getLabReportValue(LabInputNames.ApolipoproteinB)} />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box className="text-light-medium" px={5}>
                                        <Box>
                                            <span className="report-sub-title">Inflammation</span>
                                        </Box>
                                        <Box>
                                            <LabResultComponent label="LP PLA2" value={labValues.getLabReportValue(LabInputNames.LpPla2)} />
                                            <LabResultComponent label="CRP" value={labValues.getLabReportValue(LabInputNames.Crp)} />
                                            <LabResultComponent label="Omega 3" value={labValues.getLabReportValue(LabInputNames.Omega3)} />
                                            <LabResultComponent label="Coq10" value={labValues.getLabReportValue(LabInputNames.CoQ10)} />
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </>
            </HealthReportBasePage>
        )
    )
}
