import { CoursesState, CoursesStore, coursesStore } from './courses.store';
import { Query } from '@datorama/akita';
import { BaseCourseModel } from '../../models/courses.model';

/**
 * Provides method for working with courses store
 */
export class CoursesQuery extends Query<CoursesState> {
  patientCourseHistories$ = this.select(state => state.patientCourseHistories);
  courses$ = this.select(state => state.courses);

  constructor(protected store: CoursesStore) {
    super(store);
  }

  /**
   * Returns course histories
   */
  public getToken(): Array<BaseCourseModel> {
    return this.getValue().courses;
  }
}

export const coursesQuery = new CoursesQuery(coursesStore);