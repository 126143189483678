import { HealthReportMode, HealthReportSections } from "../models/healthReport.models";
import { TitlesService } from "../../../constants/title.constants";
import { HealthReportPageProps } from "./healthReportPageProps";
import { HealthReportBasePage, HealthReportMobileBasePage } from "./HealthReportBasePage";
import whImg from "@img/HealthReport_Pg2_Image.jpg";
import { Box, useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import clsx from "clsx";
import commonUseStyles from "../../common/styles/common.styles";
import { RoutesConstants } from '../../../constants/route.constants';
import { ImageConstants } from '../../../constants/image.constants';

export const HealthReportDietAndNutrition0Page: React.FC<HealthReportPageProps> = (props: HealthReportPageProps) => {
    const { pageNumber, practice } = props;

    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        props.mode !== HealthReportMode.PrintMode && isSmallScreen ? (
            <HealthReportMobileBasePage section={HealthReportSections.DietAndNutrition} pageNumber={pageNumber} practice={practice} firstPage>
                <Box>
                    <Box px={2}>
                        <Box>
                            <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeBigReport, commonClasses.textRegularReport)}>Macronutrients</span>
                        </Box>
                        <Box mt={1.5} fontSize="14px" lineHeight="22px">
                            There is no one ideal diet for all of us. We are all genetically different and have
                            spent years
                            modifying our epigenetics, training our bodies to turn specific genes on and off. This
                            makes us
                            all unique. At {TitlesService.simpleBaseTitle}, our goal is to help you find the right
                            diet for you, based on your
                            own preferences, your human operating system (your genes), and your current state of
                            health.
                            This section summarizes our assessment of a diet that will optimize your potential, fend
                            off
                            chronic disease, and promote longevity.
                        </Box>
                        <Box mt="30px">
                            <img src={RoutesConstants.cdnBaseLink + ImageConstants.healthReportPg2ImagePath} style={{ width: '100%', height: 'auto' }} alt="img" />
                        </Box>
                    </Box>
                </Box>
            </HealthReportMobileBasePage>
        ) : (
            <HealthReportBasePage section={HealthReportSections.DietAndNutrition} pageNumber={pageNumber} practice={practice}>
                <Box>
                    <Box px={15} pt={5}>
                        <Box>
                            <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeBigReport)}>Macronutrients</span>
                        </Box>
                        <Box mt={3}>
                            There is no one ideal diet for all of us. We are all genetically different and have
                            spent years
                            modifying our epigenetics, training our bodies to turn specific genes on and off. This
                            makes us
                            all unique. At {TitlesService.simpleBaseTitle}, our goal is to help you find the right
                            diet for you, based on your
                            own preferences, your human operating system (your genes), and your current state of
                            health.
                            This section summarizes our assessment of a diet that will optimize your potential, fend
                            off
                            chronic disease, and promote longevity.
                        </Box>
                        <Box mt={10}>
                            {
                                props.mode === HealthReportMode.PrintMode
                                    ? <img src={whImg} style={{ width: '100%', height: '950px' }} alt="img" />
                                    : <img src={RoutesConstants.cdnBaseLink + ImageConstants.healthReportPg2ImagePath} style={{ width: '100%', height: '950px' }} alt="img" />
                            }
                        </Box>
                    </Box>
                </Box>
            </HealthReportBasePage>
        )
    )
}
