import { ChangeEvent, useState } from 'react';
import { confirmService } from '../../../../services/confirm.service';
import { IErrorState } from '../../validation/error-state';
import { confirmWithReasonDialogValidator } from './confirmWithReasonDialog.validator';

export interface ConfirmWithReasonDialogState extends IErrorState {
    isOpen: boolean;
    title: string;
    message: string;
    placeholder: string;
    acceptTitle: string;
    reason: string;
    handleAnswer: Function;
}

const defaultState = {
    isOpen: false,
    title: '',
    message: '',
    placeholder: '',
    acceptTitle: 'Submit',
    reason: '',
    errors: {},
} as ConfirmWithReasonDialogState

export function useFacade(): [
    ConfirmWithReasonDialogState,
    (event: ChangeEvent<HTMLTextAreaElement>) => void,
    () => void,
    () => void,
]{
    const [state, setState] = useState(Object.assign({}, defaultState));

    const handleReasonChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const value = event.currentTarget.value;
        confirmWithReasonDialogValidator.validateAndSetState(state, setState, 'reason', value);
        setState(state => ({...state, reason: value}));
    }

    const handleCancel = () => {
        handleAnswer(false);
    }

    const handleConfirm = () => {
        confirmWithReasonDialogValidator.validateAndSetState(state, setState, 'reason', state.reason);

        if (confirmWithReasonDialogValidator.stateIsValid(state)) {
            handleAnswer(true);
        }
    }

    const handleAnswer = (result: boolean) => {
        setState(state => ({...defaultState, reason: '', errors: {}}));
        state.handleAnswer(result, state.reason);
    }

    confirmService.onQuestionWithReason.subscribe((question) => {
        setState(state => ({...state, isOpen: true, title: question.data.title, message: question.data.message, acceptTitle: question.data.acceptTitle ?? state.acceptTitle, reason: question.data.reason, handleAnswer: question.callback}));
    })

    return [state, handleReasonChange, handleCancel, handleConfirm];
}