import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { onEmit } from "../../../../../common/helpers/on-emit";
import { IErrorState } from "../../../../../common/validation/error-state";
import { addEmployeesButtonComponentValidator } from "./addEmployeesButtonComponent.validator";
import { CreatePreauthorizeRequestModel } from "../../../../../payment/models/preauthorizeRequest.model";
import { GeneralValidator } from "../../../../../common/validation/general-validator";
import { EmployerProductModel } from '../../../../models/employerProduct.model';
import { employerProductsQuery } from '../../../../stores/employerProductsStore';
import { preauthorizeRequestService } from "../../../../../payment/services/preauthorizeRequest.service";
import { paymentPlansService } from "../../../../../payment/services/paymentPlans.service";
import { authQuery } from "../../../../../auth/stores/auth";
import { PaymentPlanModel } from "../../../../../payment/models/paymentPlan.models";
import { PaymentPeriodModel } from "../../../../../payment/models/paymentPeriod.models";
import { getPlanForAutoSelect } from "../../../../../payment/helpers/getPlanForAutoSelect";
import { paymentPlansQuery } from "../../../../../payment/stores/paymentPlansStore";
import { getCorrespondingPrice } from "../../../../../payment/helpers/getCorrespondingPrice";
import { PaymentPriceModel, PaymentStrategy, PaymentPriceType } from "../../../../../payment/models/paymentPrice.models";


interface CreateEmployeeButtonComponentState extends IErrorState {
    isLoading: boolean;
    isOpen: boolean;
    employer: EmployerProductModel;
    creatRequests: CreatePreauthorizeRequestModel[];
    paymentPlan: PaymentPlanModel;
    paymentPeriod: PaymentPeriodModel;
    paymentPrice: PaymentPriceModel;
}

const initModel: CreatePreauthorizeRequestModel = {
    firstName: '',
    lastName: '',
    email: '',
    paymentPlanId: 0,
    paymentPeriodId: 0,
    paymentPriceId: 0,
    employerProductId: 0,
};

const getDefaultPaymentPrice = (period: PaymentPeriodModel): PaymentPriceModel => {
    const promoCodePricePrice = period.prices.find(x => x.type === PaymentPriceType.PromoCode);
    if (promoCodePricePrice) {
        return promoCodePricePrice;
    }

    return getCorrespondingPrice(period.prices, PaymentStrategy.FullPayment, PaymentPriceType.Default) ?? period.prices[0];
}

export function useFacade(productId: number): [
    CreateEmployeeButtonComponentState,
    () => boolean,
    (index: number, field: string, value: any) => void,
    () => void,
    (status: boolean) => void,
    () => void
] {

    const [state, setState] = useState({
        isLoading: false,
        isOpen: false,
        employer: null,
        creatRequests: [],
        paymentPlan: null,
        paymentPeriod: null,
        paymentPrice: null,
        errors: {}
    } as CreateEmployeeButtonComponentState);

    const isCheckedForCreating = () =>
      Boolean(
        state.creatRequests.filter(x => x.firstName).length === state.creatRequests.length &&
        state.creatRequests.filter(x => x.lastName).length === state.creatRequests.length &&
        state.creatRequests.filter(x => x.email).length === state.creatRequests.length &&
        Object.keys(state.errors).length === 0
      );

    const handleChanges = (index: number, field: string, value: any) => {
        const error = addEmployeesButtonComponentValidator.validate(field, value);
        error
          ? setState(state => ({ ...state, errors: GeneralValidator.addError(state, `${field}-${index}`, error) }))
          : setState(state => ({ ...state, errors: GeneralValidator.removeError(state, `${field}-${index}`) }));
        const creatRequests = state.creatRequests.map((x, i) => (index === i ? ({...x, [field]: value}) : x));
        setState(state => ({
          ...state,
          creatRequests,
        }));
    }

    const handleAddRequest = () => {
        setState(state => ({
            ...state,
            creatRequests: [...state.creatRequests, Object.assign({}, initModel)]
        }));
    }

    const handleToggle = (status: boolean) => {
        setState(state => ({
            ...state,
            isOpen: status,
            errors: {},
            creatRequests: status ? [Object.assign({}, initModel)]: []
        }));
    }

    const handleCreate = () => {
        if (!state.employer) return;

        let action = null;
        const models = state.creatRequests.map(x => ({
            ...x,
            employerProductId: state.employer.id,
            paymentPlanId: state.paymentPlan?.id,
            paymentPeriodId: state.paymentPeriod?.id,
            paymentPriceId: state.paymentPrice?.id
        }))
        if (models.length > 1) {
            action = preauthorizeRequestService.createPreauthorizeRequests(models)
        } else {
            action = preauthorizeRequestService.createPreauthorizeRequest(models[0])
        }
        setState(state => ({...state, isLoading: true}));

        const cb = () => setState(state => ({...state, isLoading: false, isOpen: false, creatRequests: []}))
        
        action.subscribe(cb, cb)
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<EmployerProductModel>(employerProductsQuery.selectedEmployer$, selectedEmployer => {
                if (selectedEmployer && selectedEmployer.id === productId) {
                    setState(state => ({
                        ...state,
                        employer: selectedEmployer,
                    }));
                }
            }),
            onEmit<PaymentPlanModel>(paymentPlansQuery.selectedPlan$, selectedPlan => {
                setState(state => ({
                    ...state,
                    paymentPlan: selectedPlan
                }));

                if (selectedPlan && selectedPlan.periods.length === 1) {
                    paymentPlansService.selectPeriod(selectedPlan.periods[0]);
                    setState(state => ({
                        ...state,
                        paymentPeriod: selectedPlan.periods[0]
                    }));
    
                    if (selectedPlan.periods?.[0]) {
                        const paymentPrice = getDefaultPaymentPrice(selectedPlan.periods?.[0]);
                        setState(state => ({
                            ...state,
                            paymentPrice: paymentPrice
                        }));
                    }
                }
            }),
        ];

        paymentPlansService.getActive(authQuery.getCurrentPracticeId(), null).subscribe((plans) => {
            if (plans.length) {
                const plan = getPlanForAutoSelect(plans, null, "STANDARD");
                if (plan) {
                    paymentPlansService.selectPlan(plan);
                }
            }
        });

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    };

    useEffect(useEffectCB, []);

    return [
        state,
        isCheckedForCreating,
        handleChanges,
        handleCreate,
        handleToggle,
        handleAddRequest
    ];
}