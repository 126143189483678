import React from 'react';
import { Box, IconButton, Dialog, DialogActions, DialogContent, useMediaQuery, useTheme } from "@material-ui/core";
import Cancel from "@img/icons/cancel.svg";
import clsx from "clsx";
import { useFacade, TutorialModel } from "./tutorialDialog.hooks";
import { useStyles } from "./tutorialDialog.styles";
import { WildHealthButton } from "../../components/wildHealthButton/WildHealthButton";

export interface TutorialDialogProps {
    opened: boolean;
    tutorialContent: TutorialModel[];
    handleClose: () => void;
}

export const TutorialDialog: React.FC<TutorialDialogProps> = (props: TutorialDialogProps) => {
    const [
        {
            currentStep,
        },
        handleGoNext,
        handleSkip,
        handleClose
    ] = useFacade(props);
    
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <Dialog
            onClose={() => handleClose()}
            open={props.opened}
            className={classes.dialog}
        >
            <DialogContent className={classes.content}>
                <IconButton
                    id="supplements-tutorial-close"
                    onClick={() => handleClose()}
                    className={classes.dialogCloseButton}
                >
                    <img src={Cancel} alt='Cancel' />
                </IconButton>
                <Box display="flex" justifyContent="center" width={1} className={classes.imageSection}>
                    {currentStep?.featuredImage}
                </Box>
                <Box textAlign="center" px={isSmallScreen ? 2 : 7.5}>
                    <Box mb={2} className="wh-tw-text-lg wh-tw-font-medium wh-tw-text-black" px={isSmallScreen ? 2 : 7.5}>
                        {currentStep?.title}
                    </Box>
                    <Box textAlign="center" className="wh-tw-text-base wh-tw-font-normal wh-tw-text-gray1">
                        {currentStep?.content}
                    </Box>
                    {props.tutorialContent && props.tutorialContent.length > 1 &&
                        <Box display="flex" alignItems="center" justifyContent="center" gridGap={10} mt={4}>
                            {props.tutorialContent.map(content => (
                                <Box key={content.id} className={clsx("wh-tw-w-1.5 wh-tw-h-1.5 wh-tw-bg-gray2 wh-tw-rounded-full", currentStep?.id === content.id && 'wh-tw-bg-primary')}></Box>
                            ))}
                        </Box>
                    }
                </Box>
            </DialogContent>

            <DialogActions className={classes.action}>
                <Box display="flex" alignItems="center" justifyContent={isSmallScreen ? "center" : "flex-end"} width={1}>
                    {
                        currentStep?.id !== props.tutorialContent[props.tutorialContent.length - 1].id && (
                            <Box px={4} mr={3} className="wh-tw-text-primaryV wh-tw-font-medium wh-tw-cursor-pointer" onClick={() => handleSkip()}>Skip</Box>
                        )
                    }
                    
                    {
                        currentStep?.id !== props.tutorialContent[props.tutorialContent.length - 1].id ? (
                            <WildHealthButton
                                id="tutorial-next-button"
                                onClick={() => handleGoNext()}
                                size='large'
                                fullWidth={isSmallScreen}
                            >
                                Next
                            </WildHealthButton>
                        ) : (
                            <WildHealthButton
                                id="tutorial-close-button"
                                onClick={() => handleClose()}
                                size='large'
                                fullWidth={isSmallScreen}
                            >
                                Done
                            </WildHealthButton>
                        )
                    }
                </Box>
            </DialogActions>
        </Dialog>
    );
}
