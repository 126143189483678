import {Observable, Subject} from "rxjs";

export class SelectShortcutService {
    public onSelect = new Subject<Function>();

    public select(): Observable<string> {
        return new Observable(observer => {
            this.onSelect.next((content: string) => {
                    observer.next(content);
                    observer.complete();
                }
            );
        });
    }
}

export const selectShortcutService = new SelectShortcutService();