import { useEffect, useState, useRef } from "react";
import { Subscription } from "recompose";
import { onEmit } from "../../modules/common/helpers/on-emit";
import { applicationQuery } from "../../stores/applicationStore";
import { profileService } from "../../modules/account/services/profile.service";
import { authQuery } from "../../modules/auth/stores/auth";
import { applicationService } from "../../services/application.service";
import { agreementsQuery } from "../../modules/payment/stores/agreementsStore";
import { agreementsService } from "../../modules/payment/services/agreements.service";
import { AgreementConfirmationWithChangesModel } from "../../modules/payment/models/agreement.model";
import { DelinquentStatusModel } from "../../modules/patients/models/patient.model";
import { authService } from "../../modules/auth/services/auth.service";
import { BannerModel } from "../../models/banner.model";
import { profileQuery } from "../../modules/account/stores/profileStore";

interface AuthenticatedLayoutComponentState {
    isSideBarOpen: boolean;
    bannerHeight: number;
    delinquentStatus: DelinquentStatusModel[];
    isDelinquent: boolean;
    isAgreementsChanged: boolean;
    agreementsChanges: AgreementConfirmationWithChangesModel[];
    topBanners: BannerModel[];
    isStripeIntegration: boolean;
}

interface ScrollElement {
    current: HTMLDivElement
}

export function useFacade(): [AuthenticatedLayoutComponentState, ScrollElement, Function, Function, Function, Function] {

    const [state, setState] = useState({
        isSideBarOpen: applicationQuery.getIsSideBarOpen(),
        bannerHeight: 0,
        isDelinquent: false,
        isAgreementsChanged: false,
        agreementsChanges: [],
        topBanners: [],
        isStripeIntegration: false
    } as AuthenticatedLayoutComponentState);

    const prevScrollY = useRef<HTMLHeadingElement>(null);

    const deactivateBanner = () => {
        agreementsService.getAcceptChangesAgreements(state.agreementsChanges[0].id).subscribe(
            () => {
                authService.reLogin(authQuery.getTargetLocationId()).subscribe((response) => {
                    if (response.isAgreementsChanged) {
                        agreementsService.getChangedAgreements().subscribe()
                    }
                });
            },
        )
    }

    const handleCloseBanner = (bannerId: number) => {
        applicationService.closeBanner(bannerId).subscribe();
    }

    const handleGoBillingInfo = () => {
        window.open(state.delinquentStatus[0].paymentLink, '_blank').focus();
    }

    const handleCloseDelinquentBanner = () => {
        window.sessionStorage.setItem('delinquent', 'close')
        setState(state => ({...state, isDelinquent: false}))
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<boolean>(applicationQuery.isSideBarOpen$, isSideBarOpen => {
                setState(state => ({ ...state, isSideBarOpen: isSideBarOpen }));
            }),
            onEmit<BannerModel[]>(applicationQuery.banners$, banners => {
                setState(state => ({ ...state, topBanners: banners.filter(x => x.isActive) }));
            }),
            onEmit<boolean>(authQuery.isAgreementsChanged$, isAgreementsChanged => {
                setState(state => ({ ...state, isAgreementsChanged: isAgreementsChanged }));
            }),
            onEmit<AgreementConfirmationWithChangesModel[]>(agreementsQuery.agreementsChanges$, agreementsChanges => {
                if (agreementsChanges) {
                    setState(state => ({ ...state, agreementsChanges: agreementsChanges }));
                }
            }),
            onEmit<DelinquentStatusModel[]>(profileQuery.delinquentStatus$, delinquentStatus => {
                setState(state => ({ ...state, delinquentStatus, isDelinquent: delinquentStatus.length > 0 }));
            })
        ];

        if (authQuery.isPatientUser()) {
            profileService.isPatientDelinquent().subscribe()
        }

        if (!applicationQuery.getInformation()) {
            applicationService.getInfo();
        }
        applicationService.getBanners().subscribe();

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    useEffect(() => {
        function scrollEvent() {
            if (prevScrollY) {

                if (prevScrollY.current) {
                    let rect = prevScrollY.current.getBoundingClientRect()
                    setState(state => ({ ...state, bannerHeight: rect.bottom > 0 ? rect.bottom : 0 }))
                }
            }
        }

        if (prevScrollY && prevScrollY?.current) {
            let rect = prevScrollY.current.getBoundingClientRect()
            setState(state => ({ ...state, bannerHeight: rect.bottom > 0 ? rect.bottom : 0 }))
        }

        document.addEventListener('scroll', scrollEvent)

        return () => {
            document.removeEventListener('scroll', scrollEvent)
        }
    }, [state.isAgreementsChanged, state.agreementsChanges, state.topBanners, state.isDelinquent ]);

    return [state, prevScrollY, deactivateBanner, handleCloseBanner, handleGoBillingInfo, handleCloseDelinquentBanner];
}