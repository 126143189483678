import { NextTimeModel, NextTimeSpan } from "../../models/notes.models";

export const coachNextAppointmentTimes = 
[
    {
        timeSpan: NextTimeSpan.Month,
        quantity: 1,
        isManual: false,
    },
    {
        timeSpan: NextTimeSpan.Month,
        quantity: 2,
        isManual: false,
    },
    {
        timeSpan: NextTimeSpan.Month,
        quantity: 3,
        isManual: false,
    },
    {
        timeSpan: NextTimeSpan.Week,
        quantity: 1,
        isManual: true
    },
] as NextTimeModel[];

export const providerNextAppointmentTimes = 
[
    {
        timeSpan: NextTimeSpan.Month,
        quantity: 3,
        isManual: false,
    },
    {
        timeSpan: NextTimeSpan.Month,
        quantity: 6,
        isManual: false,
    },
    {
        timeSpan: NextTimeSpan.Year,
        quantity: 1,
        isManual: false,
    },
    {
        timeSpan: NextTimeSpan.Month,
        quantity: 1,
        isManual: true
    },
] as NextTimeModel[];

export const manualTimeSpans = [NextTimeSpan.Week, NextTimeSpan.Month, NextTimeSpan.Year];
