import { useState } from "react";
import { useHistory } from "react-router";
import { navigationService } from "../../../../services/navigation.service";
import { IErrorState } from "../../../common/validation/error-state";
import { RestorePasswordModel } from "../../models/auth.models";
import { authService } from "../../services/auth.service";
import { authQuery } from "../../stores/auth";
import { restorePasswordPageValidator } from "./restorePasswordPage.validator";

/**
 * Represents Create Shortcuts Component state
 */
interface RestorePasswordPageState extends IErrorState {
    model: RestorePasswordModel;
    isResetSuccess: boolean;
    showPassword: boolean;
    showConfirmPassword: boolean;
    isLoading: boolean;
}

/**
 * Custom Hook to manage a view Model for Create shortcuts component
 */
export function useFacade(code: string): [RestorePasswordPageState, Function, Function, Function, () => void, () => void] {
    const history = useHistory();
    if (authQuery.isLoggedIn()) {
        navigationService.toDashboard(history);
    }

    const [state, setState] = useState({
        model: {
            newPassword: null,
            confirmPassword: null,
            code: code
        },
        errors: {},
        isResetSuccess: false,
        showPassword: false,
        showConfirmPassword: false,
        isLoading: false,
    } as RestorePasswordPageState);

    const handleClickShowPassword = () => {
        setState({ ...state, showPassword: !state.showPassword });
    };

    const handleClickShowConfirmPassword = () => {
        setState({ ...state, showConfirmPassword: !state.showConfirmPassword });
    };

    const handleChanges = (field: string, value: string) => {
        state.model[field] = value;
        setState({
            ...state,
            model: {...state.model}
        });

        restorePasswordPageValidator.validateAndSetState(state, setState, field, value);
    }

    const handleSubmit = (event: Event) => {

        event.preventDefault();
        restorePasswordPageValidator.validateObjectAndSetState(state, setState, state.model);
        if (!restorePasswordPageValidator.stateIsValid(state)) {
            return;
        }

        setState({...state, isLoading: true});
        authService.restorePassword(state.model).subscribe(() => {
            setState(state => ({
                ...state,
                isResetSuccess: true,
                isLoading: false,
            }));
        }, () => setState(state => ({...state, isLoading: false})))
    }

    const handleLogin = () => {
        navigationService.toDashboard(history); 
    }

    return [state, handleChanges, handleSubmit, handleLogin, handleClickShowPassword, handleClickShowConfirmPassword];
}
