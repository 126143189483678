import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles(() =>
    createStyles({
        button: {
            padding: 7,
            fontWeight: 400,
            textTransform: 'capitalize'
        },
        search: {
            background: colors.white,
        },
        searchBase: {
            padding: 16,
            fontFamily: 'Be Vietnam Pro',
            fontSize: 14,
        },
        icon: {
            color: colors.gray1
        },
    })
);