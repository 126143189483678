import React, {FC, Fragment} from 'react';
import { Box, Collapse, ListItem, ListItemText, TextField, FormControl, Table, TableBody, TableContainer, TableHead, TableRow, Breadcrumbs } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { WildHealthButton } from "../../../../common/components/wildHealthButton/WildHealthButton";
import { WildHealthTableRow } from "../../../../common/components/wildHealthTable/WildHealthTableRow";
import { WildHealthTableCell } from "../../../../common/components/wildHealthTable/WildHealthTableCell";
import WildHealthLinearProgress from '../../../../common/components/WildHealthLinearProgress';
import { WildHealthPlaceHolder } from "../../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { useStyles } from "../manageApiResponseComponent.styles";
import commonUseStyles from "../../../../common/styles/common.styles";
import { ToolEnum } from '../manageApiResponseComponent.hooks';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import clsx from 'clsx';
import moment from "moment";
import { SubscriptionModel } from '../../../../payment/models/subscription.models';
import { PatientProductModel } from '../../../../products/models/patientProducts.model';
import { ProductTypeNames } from '../../../../products/enums/productType';
import { ProductSubTypeNames } from '../../../../products/enums/productSubType';

type EndpointAdminToolProps = {
    id: string;
    params: any;
    handleChanges: (field: string, value: any, tool: ToolEnum) => void;
    handleOpenToggle: () => void;
    handleGetActivePatientProducts: () => void;
    handleRestoreSubscriptionPrice: () => void;
    handleResetPatientProducts: () => void;
    title: string;
    errors: any;
    isOpen: boolean;
    isLoading: boolean;
    patientName: string;
    patientSubscription: SubscriptionModel;
    eligiblePatientProducts: PatientProductModel[];
}


const ManagePatientProductsComponent: FC<EndpointAdminToolProps> = (props): JSX.Element => {
    const {
        id, isLoading, title, errors, params, isOpen, patientName, patientSubscription, eligiblePatientProducts,
        handleOpenToggle,
        handleChanges,
        handleGetActivePatientProducts,
        handleRestoreSubscriptionPrice,
        handleResetPatientProducts
    } = props;

    const classes = useStyles();
    const commonClasses = commonUseStyles();

    const  capitalize=(str)=> {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const getParamTitle = (paramName) => {
        if(paramName.includes('Id')){
            return paramName.split('Id').map(capitalize).join(" ")+' Id';
        }
    }

    const getDate = (date) => {
        return date
            ? moment(date).format('MM/DD/YYYY')
            : '-'
    }

    const [step, setStep] = React.useState(ToolEnum.GET_ACTIVE_PATIENT_PRODUCTS);

    const renderTable = () => {
      if (isLoading) {
        return <WildHealthLinearProgress />
      }
  
      if (!isLoading && (!eligiblePatientProducts || !eligiblePatientProducts.length)) {
        return <WildHealthPlaceHolder message="No Patient Products found" />
      }

      return (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <WildHealthTableCell>Type</WildHealthTableCell>
                <WildHealthTableCell>SubType</WildHealthTableCell>
                <WildHealthTableCell>Expires</WildHealthTableCell>
                <WildHealthTableCell>UsedAt</WildHealthTableCell>
                <WildHealthTableCell>UsedBy</WildHealthTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {eligiblePatientProducts.map((product, index) => (
                <WildHealthTableRow key={index}>
                  <WildHealthTableCell>
                    <Box className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorBlack)}>
                      {ProductTypeNames[product.productType]}
                    </Box>
                  </WildHealthTableCell>
                  <WildHealthTableCell>
                    <Box className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorBlack)}>
                      {ProductSubTypeNames[product.productSubType]}
                    </Box>
                  </WildHealthTableCell>
                  <WildHealthTableCell>
                    <Box className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorBlack)}>
                      {getDate(product.expiredAt)}
                    </Box>
                  </WildHealthTableCell>
                  <WildHealthTableCell>
                    <Box className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorBlack)}>
                      {getDate(product.usedAt)}
                    </Box>
                  </WildHealthTableCell>
                  <WildHealthTableCell>
                    <Box className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorBlack)}>
                      {product.usedBy ?? '-'}
                    </Box>
                  </WildHealthTableCell>
                </WildHealthTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )
    }

    const renderStep1 = (params: any) => (
        <Box p={2}>
            {
                Object.keys(params).map((param, index) => {
                    return (
                    <Box pb={2} key={index}>
                        <FormControl
                            variant="outlined"
                            color='primary'
                            required
                            error={!!errors[param]}
                            size="small"
                            disabled={!isOpen}
                            classes={{root: classes.root}}
                            fullWidth>
                            <Box className={commonClasses.label} mb={1}>{getParamTitle(param)} *</Box>
                            <TextField
                                fullWidth
                                size="small"
                                required
                                InputProps={{className: 'input', classes}}
                                id={param}
                                type={typeof params[param]}
                                placeholder={getParamTitle(param)}
                                value={params[param]}
                                variant="outlined"
                                disabled={!isOpen}
                                error={!!errors[param]}
                                helperText={errors[param]}
                                onChange={(v) => handleChanges(v.target.id, v.target.value, ToolEnum.GET_ACTIVE_PATIENT_PRODUCTS)}
                            />
                        </FormControl>
                    </Box>)
                })
            }
            <WildHealthButton
                fullWidth
                id="get-active-patient-products-button"
                loading={isLoading}
                onClick={handleGetActivePatientProducts}
            >
                Execute
            </WildHealthButton>
        </Box>
    )

    const renderStep2 = () => (
        <Box p={2}>
            <Box>
                <Box className={commonClasses.label} mb={1}>Patient Name</Box>
                <TextField
                    fullWidth
                    size="small"
                    InputProps={{className: 'input', classes}}
                    id="patientName"
                    placeholder="Patient Name"
                    value={patientName}
                    variant="outlined"
                    disabled={true}
                />
            </Box>
            <Box my={2}>
                <Box className={commonClasses.label} mb={1}>Subscription</Box>
                <TextField
                    fullWidth
                    size="small"
                    InputProps={{className: 'input', classes}}
                    id="subscription.displayName"
                    placeholder="Subscription DisplayName"
                    value={patientSubscription.displayName}
                    variant="outlined"
                    disabled={true}
                />
            </Box>
            <Box my={2}>
                <Box className={commonClasses.label} mb={1}>Start Date</Box>
                <TextField
                    fullWidth
                    size="small"
                    InputProps={{className: 'input', classes}}
                    id="subscription.startDate"
                    placeholder="Subscription StartDate"
                    value={getDate(patientSubscription.startDate)}
                    variant="outlined"
                    disabled={true}
                />
            </Box>
            <Box my={2}>
                <Box className={commonClasses.label} mb={1}>End Date</Box>
                <TextField
                    fullWidth
                    size="small"
                    InputProps={{className: 'input', classes}}
                    id="subscription.endDate"
                    placeholder="Subscription EndDate"
                    value={getDate(patientSubscription.endDate)}
                    variant="outlined"
                    disabled={true}
                />
            </Box>
            <Box my={2}>
                <Box className={commonClasses.label} mb={1}>Products</Box>
                {renderTable()}
            </Box>
            <Box display="flex" alignItems="center">
              <Box flex={1} px={2}>
                <WildHealthButton
                    fullWidth
                    id="patient-subscription-price-restore-button"
                    loading={isLoading}
                    onClick={handleRestoreSubscriptionPrice}
                >
                    Restore
                </WildHealthButton>
              </Box>
              <Box flex={1} px={2}>
                <WildHealthButton
                    fullWidth
                    id="patient-products-reset-button"
                    loading={isLoading}
                    onClick={handleResetPatientProducts}
                >
                    Reset
                </WildHealthButton>
              </Box>
            </Box>
            
        </Box>
    )

    return (
        <Fragment>
            <ListItem id={id} className={classes.divider} button onClick={handleOpenToggle}>
                <ListItemText primary={<span className="text-medium">{title}</span>}/>
                {isOpen ? <ExpandLess className={classes.activeIcon}/> : <ExpandMore/>}
            </ListItem>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <Box p={2}>
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                        <Box className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorMain, commonClasses.pointer)} onClick={() => setStep(ToolEnum.GET_ACTIVE_PATIENT_PRODUCTS)}>
                            Step 1
                        </Box>
                        <Box className={step === ToolEnum.GET_ACTIVE_PATIENT_PRODUCTS ? clsx(commonClasses.label, commonClasses.pointer) : clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorMain, commonClasses.pointer)} onClick={() => patientSubscription && setStep(ToolEnum.MANAGE_PATIENT_PRODUCTS)}>
                            Step 2
                        </Box>
                    </Breadcrumbs>
                </Box>
                {step === ToolEnum.GET_ACTIVE_PATIENT_PRODUCTS && renderStep1(params[ToolEnum.GET_ACTIVE_PATIENT_PRODUCTS])}
                {step === ToolEnum.MANAGE_PATIENT_PRODUCTS && renderStep2()}
            </Collapse>
        </Fragment>
    )

}

export default ManagePatientProductsComponent;