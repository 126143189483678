import { useEffect, useState } from "react";
import { confirmService } from "../../../../services/confirm.service";
import { documentsService } from "../../../inputs/services/documents.service";
import { patientDocumentsService } from "../../../inputs/services/patientDocuments.service";
import { documentsStore } from "../../../inputs/stores/documentsStore/documents.store";
import { DocumentCategoryModel, DocumentModel, ViewableDocumentModel } from "../../models/documents.models";

interface UploadInputsFilesComponentState {
    isLoading: boolean;
    newDocumentsExist: DocumentModel[];
    isDownloading: boolean;
    isUploading: boolean;
    isActionsOpen: boolean;
    actionsAnchorEl: HTMLButtonElement;
    selectedFile: DocumentModel;
}

export function useFacade(patientId: number, file: DocumentCategoryModel): [
    UploadInputsFilesComponentState,
    (fileId?: number, target?: HTMLButtonElement) => void,
    (file: DocumentModel) => void,
    (fileId: number) => void,
    (fileId: number) => void,
    (fileId: number, isViewable: boolean) => void
] {
    const [state, setState] = useState({
        isLoading: true,
        newDocumentsExist: file.documents.filter(el => !el.isViewed),
        isDownloading: false,
        isUploading: false,
        isActionsOpen: false,
        actionsAnchorEl: null,
        selectedFile: null,
    } as UploadInputsFilesComponentState);

    const handleToggleActions = (fileId?: number, target?: HTMLButtonElement) => {
        setState(state => ({
            ...state,
            actionsAnchorEl: target,
            isActionsOpen: !state.isActionsOpen,
            selectedFile: file.documents.find(i => i.id === fileId),
        }));
    }

    const handleDownload = (fileId: number) => {
        setState(state => ({
            ...state,
            isDownloading: true,
            isActionsOpen: false,
        }));

        const cb = () => {
            setState(state => ({
                ...state,
                isDownloading: false,
            }));
        }

        const input = file.documents.find(i => i.id === fileId);
        if (input) {
            setState(state => ({
                ...state,
                selectedFile: input,
            }));

            const action = patientId ? documentsService.download(fileId, input.name) : patientDocumentsService.download(fileId, input.name)
            action.subscribe(cb, cb)
        }
    }

    const handleTransfer = (file: DocumentModel) => {
        confirmService.confirmAndSelected(
            'Transfer File',
            'Select another category to transfer the file to',
            `${file.type}`,
            'Transfer')
            .subscribe((reason) => handleOrderReplacement(file.id, +reason));
    }

    const handleOrderReplacement = (id: number, type: number) => {
        const cb = () => {
            setState(state => ({
                ...state,
                isActionsOpen: false
            }));
        }
        documentsService.transfer({ id: id, attachmentType: type }).subscribe(() => {
            const action = patientId ? documentsService.getDocuments(patientId) :  patientDocumentsService.getDocuments()
            action.subscribe(cb, cb)

         },
         () => cb);
    }

    const handleDelete = (fileId: number) => {
        confirmService.confirm(
            'Delete File',
            'Are you sure you want to delete this file?',
            'Delete',
            'Cancel',
            'danger'
        ).subscribe(() => {

            documentsService.deleteUpload(fileId).subscribe(() => {
                documentsStore.deleteFileInput(fileId)
                setState(state => ({
                    ...state,
                    isActionsOpen: false
                }));
               
             });

        })
    }

    const viewAllDocuments = () => {
        if(state.newDocumentsExist.length) {
            state.newDocumentsExist.forEach(el => {
                documentsStore.viewFile(el.id);
                documentsService.viewDocument(el.id).subscribe()
            })
        }
    }

    const handleSetViewable = (fileId: number, isViewable: boolean) => {
        const viewableDocumentModel = {
            id: fileId,
            isViewableByPatient: isViewable
        } as ViewableDocumentModel
        documentsService.setViewableByPatient(viewableDocumentModel).subscribe();
    }

    useEffect(() => {
        patientId && setTimeout(() => {
            viewAllDocuments()
        }, 2000)
    },
    [])

    return [
        state,
        handleToggleActions,
        handleTransfer,
        handleDownload,
        handleDelete,
        handleSetViewable
    ];
}
