import { Box, Button, ButtonGroup, CircularProgress, Grid } from "@material-ui/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { Subscription } from "recompose";
import { interval } from 'rxjs';
import { confirmService } from "../../../../services/confirm.service";
import { PermissionType, UserType } from "../../../auth/models/auth.enums";
import { authQuery } from "../../../auth/stores/auth";
import { isElementEnabled } from "../../../common/components/ProtectedElement";
import { WildHealthDatePicker } from "../../../common/components/WildHealthDatePicker";
import { colors } from "../../../common/constants/colors";
import { insertString } from "../../../common/helpers/insert-string";
import { selectShortcutService } from "../../../healthReport/services/selectShortcut.service";
import { NoteModel, NotesType, SaveNoteModel } from "../../models/notes.models";
import { notesService } from "../../services/notes.service";
import { TextFieldNoteComponent } from "../textFieldNoteComponent/TextFieldNoteComponent";
import { useStyles } from "./createNote.styles";
import {patientsQuery} from "../../../patients/stores/patientsStore";

interface CreateNoteProps {
    patientId: number;
    note: NoteModel | null;
    getData?: (data: any) => SaveNoteModel;
    handleGoToNotes: Function;
    type: NotesType;
    getValidateResult: () => boolean;
    originalNote: NoteModel | null;
}

export interface CreateNoteState {
    isLoading: boolean;
    inProgress: boolean;
    isChanged: boolean;
    isAutoSaving: boolean;
    isNew: boolean;
    note: NoteModel;
    internalContent: string;
    visitDate: Date;
}

let stateContext: CreateNoteState = null;
let ignoreChangesEvent = false;

export function useFacade(props: CreateNoteProps): [
    CreateNoteState,
    Dispatch<SetStateAction<CreateNoteState>>,
    JSX.Element,
    JSX.Element,
    (state: CreateNoteState) => SaveNoteModel,
] {
    const {
        patientId,
        note,
        getData: baseData,
        handleGoToNotes,
        type,
        getValidateResult: noteTitleValidate,
        originalNote
    } = props;

    const autoSaveTimer = useMemo(() => interval(5000), []);

    const [state, setState] = useState({
        isLoading: true,
        inProgress: false,
        isChanged: false,
        isAutoSaving: false,
        isNew: true,
        note: note,
        internalContent: '',
        visitDate: new Date(),
    } as CreateNoteState);

    stateContext = state;

    const classes = useStyles();

    const getData = (state: CreateNoteState): SaveNoteModel | null => {
        return baseData ? baseData({
            id: state.note?.id,
            internalContent: state.internalContent,
            patientId: patientId,
            visitDate: state.visitDate,
            nextCoachAppointmentDate: null,
            nextProviderAppointmentDate: null
        }) : null;
    }

    const handleVisitDateChanges = (momentDate: MaterialUiPickersDate) => {
        setState({
            ...state,
            isChanged: true,
            visitDate: momentDate.toDate()
        });
    }

    const handleSaveAsDraft = () => {
        if (!noteTitleValidate()) return;
        setState(state => ({ ...state, inProgress: true }));

        notesService.saveAsDraft(getData(state), patientsQuery.getTargetPatientIsPremium()).subscribe(
            () => {
                handleGoToNotes();
                setState(state => ({ ...state, inProgress: false }));
            },
            () => {
                setState(state => ({ ...state, inProgress: false }));
            }
        );
        
        const result = getData(state);
        result.files.map((x) => x.noteId = result.id);

        notesService.saveFiles(result.files).subscribe();
    }

    const handleSaveAndComplete = () => {
        if (!noteTitleValidate()) return;
        confirmService.confirm('Are you sure? This action can not be undone').subscribe(() => {
            setState(state => ({ ...state, inProgress: true }));
            notesService.saveAsCompleted(getData(state)).subscribe(
                () => {
                    handleGoToNotes();
                    setState(state => ({ ...state, inProgress: false }));
                },
                () => {
                    setState(state => ({ ...state, inProgress: false }));
                }
            );

            
            const result = getData(state);
            result.files.map((x) => x.noteId = result.id);

            notesService.saveFiles(result.files).subscribe();
        });
    }

    const handleDiscard = () => {
        confirmService.confirm('Are you sure? This action can not be undone').subscribe(() => {
            notesService.delete(state.note).subscribe(() => handleGoToNotes());
        });
    }

    const handleInternalNotesChanged = (value: string) => {
        setState(state => ({
            ...state,
            isChanged: true,
            internalContent: value
        }));
    }


    const handleKeyDown = (event: any) => {
        ignoreChangesEvent = false;

        if (event.keyCode === 51 && event.altKey) {
            const userType = authQuery.getType();
            if (UserType.Employee === userType) {
                ignoreChangesEvent = true;
                const value = (document.getElementById('internal-notes') as any).value;
                const cursorPos = (document.getElementById('internal-notes') as any).selectionStart;
                setTimeout(() => {
                    handleOnInternalNotesChanged(value, cursorPos);
                }, 1)
            }
        }
    }

    const handleOnInternalNotesChanged = (value: string, cursorPos: number) => {
        const cb = (data) => {
            value = state.internalContent;
            if (data) {
                handleInternalNotesChanged(insertString(value, data, cursorPos + 1));
            }
        }

        selectShortcutService.select().subscribe(cb);
    }

    const noteTitle = (
        <Box>
            {
                type === NotesType.Internal && <Box display="flex" justifyContent="space-between" mb={2} mt={2}>
                    <Box />
                    <Box display="flex">
                        <Grid
                            container
                            direction='row'
                            justify='flex-start'
                            alignItems='center'
                        >
                            <span>Note Date:</span>
                        </Grid>
                        <WildHealthDatePicker
                            required
                            size="small"
                            openTo="year"
                            format="MM/DD/yyyy"
                            id='NoteDate'
                            placeholder="mm-dd-yyyy"
                            value={state.visitDate}
                            inputVariant="outlined"
                            views={["year", "month", "date"]}
                            onChange={(momentDate) => handleVisitDateChanges(momentDate)}
                        />
                    </Box>
                </Box>
            }
            <TextFieldNoteComponent
                rows={10}
                disabled={state.inProgress}
                uppercase={true}
                title='Notes'
                id='internal-notes'
                content={state.internalContent}
                className={classes.title}
                handleChanged={handleInternalNotesChanged}
                handleKeyDown={handleKeyDown}
            />
        </Box>
    );

    const endOfTemplate = (
        <Box display="flex" justifyContent="center" py={10}>
            {
                state.inProgress ?
                    <Button disabled={state.inProgress} variant='outlined'>
                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                            className={classes.inProgress}
                        >
                            <CircularProgress size={24} style={{ color: colors.main }} />
                            <Box ml={2}>Processing</Box>
                        </Grid>
                    </Button>
                    :
                    <ButtonGroup>
                        <Button onClick={handleSaveAsDraft} disabled={state.inProgress || !isElementEnabled([PermissionType.Coaching, PermissionType.ManagePatients], UserType.Employee) || state.isLoading}>
                            Save As Draft
                        </Button>
                        <Button onClick={handleSaveAndComplete} color='primary' disabled={state.inProgress || !isElementEnabled([PermissionType.Coaching, PermissionType.ManagePatients], UserType.Employee) || state.isLoading}>
                            Sign and Complete
                        </Button>
                        <Button onClick={handleDiscard} color='secondary' disabled={state.inProgress}>
                            Discard
                        </Button>
                    </ButtonGroup>
            }
        </Box>
    );

    const autoSave = () => {
        if (!stateContext.inProgress && stateContext.isChanged && !stateContext.isAutoSaving && isElementEnabled([PermissionType.Coaching, PermissionType.ManagePatients], UserType.Employee) && !originalNote) {
            setState(state => ({
                ...state,
                isAutoSaving: true
            }));

            notesService.saveAsDraft(getData(stateContext), patientsQuery.getTargetPatientIsPremium()).subscribe(note => {
                setState(state => ({
                    ...state,
                    note: {
                        ...state.note,
                        id: state?.note?.id ?? note.id,
                        visitDate: note.visitDate,
                    },
                    isChanged: false,
                    isLoading: false,
                    isAutoSaving: false
                }));
            })
        }
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            autoSaveTimer.subscribe(() => {
                autoSave()
            }),
        ];


        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, [patientId, note]);
    return [
        state,
        setState,
        noteTitle,
        endOfTemplate,
        getData,
    ];
}
