import React from 'react';
import {Box} from '@material-ui/core';
import {QuestionComponent} from "../questionComponent/QuestionComponent";
import {QuestionnaireSurveyProps} from "../../pages/initialQuestionnaireSurveyComponent/pages/questionnaireSurvey.props";
import commonUseStyles from '../../../common/styles/common.styles';

interface QuestionnairesListComponentProps extends QuestionnaireSurveyProps {
    title?: string;
    questions: string[];
} 

export const QuestionnairesListComponent: React.FC<QuestionnairesListComponentProps> = (props: QuestionnairesListComponentProps) => {
    const {
        errors,
        title,
        result,
        questions,
        questionnaire,
        handleChanges
    } = props;
    const commonClasses = commonUseStyles();

    const renderTitle = () => {
        return (
            <Box mt={3} className={commonClasses.secondaryTitle}>
                {title}
            </Box>
        )
    }

    return (
        <>
            {
                title && renderTitle()
            }
            {
                questions.map((question, index) => {
                    return (
                        <Box key={index}>
                            <div id={question}/>
                            <QuestionComponent
                                inline={false}
                                error={errors[question]}
                                answer={result?.answers?.find(x => x.key === question)?.value || null}
                                handleChanges={(value) => handleChanges(question, value)}
                                question={questionnaire.questions.find(x => x.name === question)}
                            />
                        </Box>
                    )
                }
            )}
        </>
    )
}