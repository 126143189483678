import { Box, CircularProgress, IconButton, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, useTheme, useMediaQuery } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import moment from 'moment';
import React from 'react';
import { NoteModel } from '../../models/notes.models';
import { useFacade } from './noteCardComponent.hooks';
import { colors } from "../../../common/constants/colors";
import commonUseStyles from "../../../common/styles/common.styles";
import clsx from 'clsx';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';

interface NoteCardComponentProps {
    keyIndex: string;
    note: NoteModel;
}

export const NoteCardComponent: React.FC<NoteCardComponentProps> = (props: NoteCardComponentProps) => {
    const {
        keyIndex,
        note,
    } = props;

    const [
        {
            isDownloading
        },
        handleViewDetails,
        handleDownloadPdf
    ] = useFacade();

    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const showDownloadButton = () => {

        if (isDownloading) {
            return (
                <Box width="70px" display="flex" justifyContent="flex-end">
                    <CircularProgress size={24} style={{ color: colors.main }} />
                </Box>
            );
        }
        return (
            <IconButton id="notes-card-download-pdf" edge="end" onClick={() => handleDownloadPdf(note)}>
                <GetAppIcon className={isSmallScreen && commonClasses.colorMain} />
            </IconButton>
        );
    }

    const renderSmallContent = () => {
        return <>
            <Box display="flex" alignItems="center" justifyContent="space-between" key={keyIndex}>
                <Box className={clsx(commonClasses.size14, commonClasses.textRegular, commonClasses.colorGray1)}>
                    {moment(note.visitDate).format("ll")}
                </Box>
                <Box>
                    {
                        showDownloadButton()
                    }
                </Box>
            </Box>
            <Box mt="12px" className={clsx(commonClasses.size20, commonClasses.textMedium, commonClasses.colorBlack)}>
                {`${note.name} ${note.title ? ' - ' + note.title : ''}`}
            </Box>
            <Box mt="30px">
                <WildHealthButton id="view-note" onClick={() => handleViewDetails(note)}>
                    View
                </WildHealthButton>
            </Box>
        </>
    }

    return (
        <>
            {isSmallScreen ? renderSmallContent() : (
                <ListItem
                    id="notes-card-view-details"
                    button
                    key={keyIndex}
                    onClick={() => handleViewDetails(note)}
                >
                    <ListItemIcon>
                        <SpeakerNotesIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={`${note.name} ${note.title ? ' - ' + note.title : ''}`}
                        secondary={moment(note.visitDate).format("ll")}
                    />
                    <ListItemSecondaryAction>
                        {
                            showDownloadButton()
                        }
                    </ListItemSecondaryAction>
                </ListItem>
            )}
        </>
    );
}
