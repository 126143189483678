import {ConversationModelBase} from "../../models/conversation.models";
import {useState} from "react";
import {conversationsService} from "../../services/conversations.service";

export enum GenericPlaygroundViewState {
    Empty,
    TargetConversation,
    NewConversation
}
interface GenericPlaygroundComponentState {
    chatListHidden: boolean;
    targetConversation: ConversationModelBase | null;
    viewState: GenericPlaygroundViewState;
}


export function useFacade(): [
    GenericPlaygroundComponentState,
    () => void,
    () => void,
    (conversation: ConversationModelBase) => void,
    (conversation: ConversationModelBase) => void
] {
    const [state, setState] = useState({
        chatListHidden: true,
        viewState: GenericPlaygroundViewState.Empty
    } as GenericPlaygroundComponentState);

    const handleChatListToggle = () => {
        setState(state => ({
            ...state,
            chatListHidden: !state.chatListHidden
        }))
    }

    const handleCreateConversation = () => {
        setState(state => ({
            ...state,
            targetConversation: null,
            viewState: GenericPlaygroundViewState.NewConversation
        }))
    }

    const handleConversationCreated = (conversation: ConversationModelBase) => {
        conversationsService.selectConversation(conversation);

        setState(state => ({
            ...state,
            targetConversation: conversation,
            viewState: GenericPlaygroundViewState.TargetConversation
        }))
    }

    const handleSelectConversation = (conversation: ConversationModelBase) => {
        conversationsService.selectConversation(conversation);

        setState(state => ({
            ...state,
            targetConversation: conversation,
            viewState: GenericPlaygroundViewState.TargetConversation
        }))
    }

    return [
        state,
        handleChatListToggle,
        handleCreateConversation,
        handleConversationCreated,
        handleSelectConversation
    ];
}