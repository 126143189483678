import { FileModel } from "../../common/models/file.models";


export enum DocumentCategory {
    LabReports = 3,
    ImagingReports = 4,
    Consults = 5,
    ECGs = 6,
    HospitalReports = 7,
    Consents = 8,
    MedicalForms = 9,
    OldRecords = 10,
    Miscellaneous = 11,
    Prescriptions = 22,
    EpigeneticReports = 24
}

export const documentCategorySections = [
    DocumentCategory.LabReports,
    DocumentCategory.EpigeneticReports,
    DocumentCategory.ImagingReports,
    DocumentCategory.Consults,
    DocumentCategory.ECGs,
    DocumentCategory.HospitalReports,
    DocumentCategory.Consents,
    DocumentCategory.MedicalForms,
    DocumentCategory.OldRecords,
    DocumentCategory.Miscellaneous,
    DocumentCategory.Prescriptions
];

export const documentCategoryNames : { [el: number]: string; } = {
    [DocumentCategory.LabReports]: 'Lab Reports',
    [DocumentCategory.EpigeneticReports]: 'Epigenetic Reports',
    [DocumentCategory.ImagingReports]: 'Imaging Reports',
    [DocumentCategory.Consults]: 'Consults',
    [DocumentCategory.ECGs]: 'ECGs',
    [DocumentCategory.HospitalReports]: 'Hospital Reports',
    [DocumentCategory.Consents]: 'Consents',
    [DocumentCategory.MedicalForms]: 'Medical Forms',
    [DocumentCategory.OldRecords]: 'Outside Records',
    [DocumentCategory.Miscellaneous]: 'Miscellaneous',
    [DocumentCategory.Prescriptions]: 'Prescriptions'
}

export interface UpdateDnaFileSynchronizationStatusModel {
    fileName: string;
    isSynchronized: boolean;
}

export interface DocumentCategoryModel {
    name: string,
    id: number,
    type: number,
    documents: DocumentModel[],
}

export interface DocumentsModel {
    id: number;
    uploadedAt: Date;
    file: FileModel;
}

export interface DocumentModel {
    dateUploaded: string;
    id: number;
    name: string;
    referenceId: number;
    type: number;
    isViewableByPatient: boolean;
    isViewed?: boolean;
}

export interface TransferDocumentModel {
    id: number;
    attachmentType: number;
}

export interface DocumentsToSendModel {
    file: File;
    name: string;
}

export interface ViewableDocumentModel {
    id: number;
    isViewableByPatient: boolean;
}

