import React from 'react';
import {createStyles, withStyles, Theme, LinearProgress, Box } from "@material-ui/core";
import { colors } from "../../../../common/constants/colors";

interface HeaderProgressBarProps {
    isOpened: boolean;
    progress: number;
}

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 12,
      zIndex: 1999,
    },
    colorPrimary: {
      backgroundColor: colors.white,
    },
    bar: {
      backgroundColor: colors.navy,
    },
  }),
)(LinearProgress);

const HeaderProgressBar: React.FC<HeaderProgressBarProps> = (props: HeaderProgressBarProps) => {

    const { isOpened, progress } = props;

    return (
        isOpened && progress > 0 ?
        <Box position="absolute" width={1} zIndex="1999"><BorderLinearProgress variant="determinate" value={progress} /></Box>
        : null
    )
}

export default HeaderProgressBar;