import React, { useMemo } from "react";
import { Box, Button, CircularProgress, Divider, useMediaQuery, useTheme } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { SelectTimezoneComponent } from '../selectTimezone/SelectTimezoneComponent';
import { useFacade } from "./patientRescheduleAppointmentDialogComponent.hooks";
import { appointmentWithTypeNames } from '../../models/appointments.enums';
import clsx from 'clsx';
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { SelectDateTimeComponent } from "../selectDateTime/SelectDateTimeComponent";
import commonUseStyles from "../../../common/styles/common.styles";
import { useStyles } from "./PatientRescheduleAppointmentDialogComponent.styles";
import { DialogTitle } from "../../../common/components/dialogTitle/DialogTitle";
import { getTimeZoneName } from "../../../timezones/helpers/timezone";
import { FeatureComponent } from "../../../common/components/featureFlags/FeatureComponent";
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import { AppointmentReasonComponent } from "../appointmentReason/AppointmentReasonComponent";
import { availabilityQuery } from "../../stores/availability/availability.query";

interface PatientRescheduleAppointmentDialogComponentProps {
    patientId?: number;
}

export const PatientRescheduleAppointmentDialogComponent: React.FC<PatientRescheduleAppointmentDialogComponentProps> = (props: PatientRescheduleAppointmentDialogComponentProps) => {
    const { patientId } = props;

    const [
        state,
        handleChangeDate,
        handleChangeTime,
        handleSubmit,
        handleClose,
        handleChangeReason,
        handleSelectReason
    ] = useFacade(patientId);

    const commonClasses = commonUseStyles();
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const minDate = useMemo(() => {
        return availabilityQuery.getEarliestAvailability();
    }, [state.appointment, state.appointmentConfiguration]);

    if (!state.appointment) {
        return (<></>);
    }

    return (
        <>
            <Dialog
                fullScreen={isSmallScreen}
                maxWidth="md"
                open={state.open}
                onClose={() => handleClose()}
                className={classes.dialog}
            >
                {
                    !isSmallScreen &&
                    <DialogTitle
                        id="customized-dialog-title"
                        onClose={() => handleClose()}
                    >
                        <span className={clsx(commonClasses.size24, commonClasses.textMedium, classes.dialogTitle)}>
                            Rescheduling Appointment
                        </span>
                    </DialogTitle>
                }
                <DialogContent className={classes.dialogContent}>
                    <Box width={isSmallScreen ? 1 : "548px"}>
                        {
                            isSmallScreen &&
                            <Box py={1} display="flex" justifyContent="space-between">
                                <Button disabled={state.isLoading} onClick={() => handleClose()}>
                                    <span className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorGray1, commonClasses.capitalize)}>
                                        Cancel
                                    </span>
                                </Button>
                                <Box>
                                    <span className={clsx(commonClasses.size20, commonClasses.textMedium, commonClasses.colorBlack)}>
                                        Rescheduling Appt
                                    </span>
                                </Box>
                                <Button disabled={state.isLoading} onClick={(e) => handleSubmit(e)}>
                                    {state.isLoading ? <CircularProgress size={24} className={commonClasses.colorMain} /> : <span className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorMain, commonClasses.capitalize)}>
                                        Save
                                    </span>}
                                </Button>
                            </Box>
                        }
                        <Box my={2}>
                            <Box>
                                <span className={clsx(commonClasses.size14, commonClasses.colorGray1)}>Schedule With</span>
                            </Box>
                            <Box mt={1}>
                                <span>{appointmentWithTypeNames.get(state.appointment.withType)}</span>
                            </Box>
                        </Box>
                        <Divider />
                        <Box my={2}>
                            <Box>
                                <span className={clsx(commonClasses.size14, commonClasses.colorGray1)}>Appointment Type</span>
                            </Box>
                            <Box mt={1}>
                                <span>{state.appointment.name}</span>
                            </Box>
                        </Box>
                        {state.appointmentType?.requiresReasonType && (
                            <FeatureComponent featureFlag={FeatureFlag.AppointmentReasons}>
                                <Divider />
                                <Box my={2}>
                                    <AppointmentReasonComponent
                                        reason={state.appointment.reason}
                                        selectedReasonType={state.selectedReasonType}
                                        errors={state.errors}
                                        handleChangeReason={handleChangeReason}
                                        handleSelectReason={handleSelectReason}
                                    />
                                </Box>
                            </FeatureComponent>
                        )}
                        <Divider />
                        <Box py={2}>
                            <SelectTimezoneComponent
                                timeZones={state.timeZones}
                                value={state.timeZoneId}
                            />
                        </Box>
                        <Divider />
                        <Box my={2}>
                            <SelectDateTimeComponent
                                selectedDate={state.selectedDate}
                                selectedTime={state.selectedTime}
                                handleChangeDate={handleChangeDate}
                                handleChangeTime={handleChangeTime}
                                availability={state.availability}
                                minDate={minDate}
                                isLoading={state.isAvailabilityLoading}
                                error={!!state.errors['selectedTime']}
                                errorMessage={state.errors['selectedTime']}
                                patientTimezone={`Timezone: ${getTimeZoneName()}`}
                            />
                        </Box>
                    </Box>
                </DialogContent>
                {
                    !isSmallScreen &&
                    <DialogActions classes={{ root: classes.dialogActions }}>
                        <WildHealthButton
                            id="patient-reschedule-appointment-cancel"
                            onClick={() => handleClose()}
                            width={120}
                            size='large'
                            color='tertiary'
                            disabled={state.isLoading}>
                            Cancel
                        </WildHealthButton>

                        <WildHealthButton
                            id="patient-reschedule-appointment"
                            onClick={(e) => handleSubmit(e)}
                            width={260}
                            size='large'
                            loading={state.isLoading}>
                            Reschedule Appointment
                        </WildHealthButton>
                    </DialogActions>
                }
            </Dialog>
        </>
    )
}