import React from 'react';
import { useHistory } from "react-router-dom";
import {
    Box,
    IconButton,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    Dialog,
    DialogContent,
    TextField,
} from "@material-ui/core";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { ReactComponent as EditIcon } from '@img/icons/Edit1.svg';
import { useFacade } from "./manageEmployersProfileComponent.hooks";
import { useStyles } from "./manageEmployersProfileComponent.styles";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { FeatureComponent } from '../../../common/components/featureFlags/FeatureComponent';
import { FeatureFlag } from '../../../common/components/featureFlags/featureFlags.models';
import { SearchComponent } from '../../../common/components/searchComponent/SearchComponent';
import { navigationService } from '../../../../services/navigation.service';

export const ManageEmployersProfileComponent: React.FC = () => {
    const history = useHistory();
    const classes = useStyles();
    
    const [
        {
            isLoading,
            isAdding,
            isAddOpen,
            employers,
            request,
            errors,
        },
        handleSearchSubmit,
        handleToggle,
        handleChanges,
        handleCreate
    ] = useFacade();

    const renderTable = () => {
        if (isLoading) {
            return <WildHealthLinearProgress/>
        }

        if (!isLoading && (!employers || !employers.length)) {
            return <WildHealthPlaceHolder message="No current employers found"/>
        }

        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <WildHealthTableRow>
                            <WildHealthTableCell>
                                <Box className="wh-tw-text-black wh-tw-text-sm wh-tw-font-semibold wh-tw-font-beVietnamPro">Business Name</Box>
                            </WildHealthTableCell>
                            <WildHealthTableCell style={{ width: '20%' }} />
                        </WildHealthTableRow>
                    </TableHead>
                    <TableBody>
                        {
                            employers.map(item =>
                                <WildHealthTableRow key={item.id}>
                                    <WildHealthTableCell>
                                        <Box className="wh-tw-cursor-pointer" gridGap={4} onClick={() => navigationService.toEmployerProfileDetail(history, item.id)}>
                                            <span className="wh-tw-text-sm wh-tw-text-primaryV wh-tw-font-semibold">{item.name}</span>
                                        </Box>
                                    </WildHealthTableCell>
                                    <WildHealthTableCell>
                                        <Box width={1} display="flex" justifyContent="flex-end" className="wh-tw-opacity-0 hover:wh-tw-opacity-100">
                                            <Box display="flex" alignItems="center" gridGap={8} className="wh-tw-cursor-pointer">
                                                <span className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-primaryV">Edit Info</span>
                                                <EditIcon className="wh-tw-cursor-pointer wh-tw-fill-primaryV" />
                                            </Box>
                                        </Box>
                                    </WildHealthTableCell>
                                </WildHealthTableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    return (
        <Box>
            <Box my={2} display="flex" alignItems="center" justifyContent="space-between">
                <Box>
                    <FeatureComponent featureFlag={FeatureFlag.Navigation}>
                        <Box className="wh-tw-text-2xl wh-tw-font-medium">
                            Employers Profiles 
                        </Box>
                    </FeatureComponent>
                </Box>
                <Box display="flex" alignItems="center" gridGap={12}>
                    <SearchComponent handleSearch={handleSearchSubmit} placeholder="Search by Business Name" />
                    <WildHealthButton
                        id="add-employer-profile"
                        size='medium'
                        color="primaryV"
                        borderRadius={4}
                        onClick={() => handleToggle(true)}
                    >
                        <span className="wh-tw-pr-1">Add Employer Profile</span>
                        <AddIcon />
                    </WildHealthButton>
                </Box>
            </Box>
            <Box className="wh-tw-bg-white" width={1}>
                {
                    renderTable()
                }
            </Box>
            <Dialog
                maxWidth="md"
                onClose={() => handleToggle(false)}
                className={classes.root}
                open={isAddOpen}
            >
                <MuiDialogTitle
                    className={classes.dialogTitle}
                    disableTypography
                >
                    <IconButton
                        id="update-employee-edit"
                        className={classes.dialogTitleCloseAction}
                        onClick={() => handleToggle(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                </MuiDialogTitle>

                <DialogContent>
                    <Box className="wh-tw-text-xl wh-tw-font-medium" mb={3}>Add Employer Profile</Box>
                    <Box width={456}>
                        <Box mb={3}>
                            <Box className="wh-tw-text-xxs wh-tw-text-gray600 wh-tw-uppercase" mb={1}>Business name <span className="wh-tw-text-primaryV">*</span></Box>
                            <TextField
                                fullWidth
                                required
                                InputProps={{ className: 'input', classes }}
                                id="name"
                                variant="outlined"
                                disabled={isAdding || !isAddOpen}
                                error={!!errors['name']}
                                helperText={errors['name']}
                                value={request?.name}
                                onChange={(event) => handleChanges(event.target.id, event.target.value)}
                            />
                        </Box>
                        <Box mb={3}>
                            <Box className="wh-tw-text-xxs wh-tw-text-gray600 wh-tw-uppercase" mb={1}>Key url</Box>
                            <TextField
                                fullWidth
                                required
                                InputProps={{ className: 'input', classes }}
                                id="key"
                                variant="outlined"
                                disabled={isAdding || !isAddOpen}
                                error={!!errors['key']}
                                helperText={errors['key']}
                                value={request?.key}
                                onChange={(event) => handleChanges(event.target.id, event.target.value)}
                            />
                        </Box>
                        <Box>
                            <Box className="wh-tw-text-xxs wh-tw-text-gray600 wh-tw-uppercase" mb={1}>CDN Url</Box>
                            <TextField
                                fullWidth
                                InputProps={{ className: 'input', classes }}
                                id="bannerCdnUrl"
                                variant="outlined"
                                disabled={isAdding || !isAddOpen}
                                error={!!errors['bannerCdnUrl']}
                                helperText={errors['bannerCdnUrl']}
                                value={request?.bannerCdnUrl}
                                onChange={(event) => handleChanges(event.target.id, event.target.value)}
                            />
                        </Box>
                    </Box>
                    <Box my={3} display="flex" alignItems="center" justifyContent="flex-end" gridGap={16}>
                        <Box>
                            <WildHealthButton
                                id="add-employer-cancel"
                                onClick={() => handleToggle(false)}
                                size="large"
                                color="secondary"
                                borderRadius={50}
                                disabled={isAdding || !isAddOpen}
                            >
                                Cancel
                            </WildHealthButton>
                        </Box>
                        <Box>
                            <WildHealthButton
                                id="add-employer-create"
                                onClick={() => handleToggle(false)}
                                size="large"
                                borderRadius={50}
                                disabled={!isAddOpen || Object.keys(errors).length > 0}
                                loading={isAdding}
                            >
                                <Box px={2}>Create</Box>
                            </WildHealthButton>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    )
}