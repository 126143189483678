import React from 'react';
import { Box, useTheme, useMediaQuery } from "@material-ui/core";
import {
    CheckAnswer,
    emptyCheckAnswer,
    QuestionModel,
    QuestionnaireModel,
    QuestionnaireResultModel,
    QuestionType
} from "../../models/questionnaire.models"
import { useStyles } from './AnswerComponent.styles'
import { QuestionRateComponent } from "../questionComponent/QuestionRateComonent";
import { groupAnswerList, subQuestionList } from "../../static/detailedQuestionnaire.static";
import commonUseStyles from '../../../common/styles/common.styles';
import { TextMarkdownComponent } from '../../../notes/components/textMarkdownComponent/TextMarkdownComponent';
import { ReactComponent as DotIcon } from '@img/icons/Dot.svg';

export interface AnswerComponentProps {
    questionnaire?: QuestionnaireModel;
    result?: QuestionnaireResultModel;
    question: QuestionModel;
    answer: string | null;
    componentInNotes?: boolean;
}

const parseCheckMany = (value: string) => {
    let checkAnswer: CheckAnswer;

    try {
        checkAnswer = JSON.parse(value);

        if (!checkAnswer) {
            return emptyCheckAnswer();
        }

        if (!Array.isArray(checkAnswer.o) && typeof checkAnswer.o === 'string') {
            checkAnswer.o = [checkAnswer.o]
        }

        return checkAnswer;
    } catch (e) {
        return emptyCheckAnswer();
    }
}

export const AnswerComponent: React.FC<AnswerComponentProps> = (props: AnswerComponentProps) => {
    const {
        questionnaire,
        result,
        question,
        answer,
        componentInNotes = false,
    } = props;

    const classes = useStyles();
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const renderNowResult = () => {
        return <span>(no answer)</span>
    }

    const renderAnswer = (answer: string, question: QuestionModel) => {
        if (question.type === QuestionType.Rate) {
            return <QuestionRateComponent
                answer={answer}
                handleChanges={() => { }}
                question={question}
                inline={false}
                error={''}
            />
        }
        if (question.type === QuestionType.SelectOne || question.type === QuestionType.CheckOne) {
            return answer;
        }
        if (question.type === QuestionType.CheckMany || question.type === QuestionType.SelectMany) {
            if (!answer || !answer.length) {
                return renderNowResult();
            }

            const checkAnswer = parseCheckMany(answer)

            if ((!checkAnswer.v || !checkAnswer.v.length) && (!checkAnswer.o || !checkAnswer.o.length)) {
                return renderNowResult();
            }

            return (
                <ul itemType="none">
                    {checkAnswer.v.map((variant, i) => (
                        <li key={i}>
                            <Box display="flex" alignItems="center">
                                <Box>
                                    <DotIcon style={{ width: '8px', height: '8px' }} />
                                </Box>
                                <Box ml={1}>
                                    {variant}
                                </Box>
                            </Box>
                            {
                                groupAnswerList.includes(question.name) && questionnaire.questions.filter(x => x.parentAnswers.includes(variant)).map(q => (
                                    <Box>
                                        <span className={classes.subQuestion}>{q.displayName}: <span className={classes.subAnswer}>{result.answers.find(x => x.key === q.name)?.value}</span></span>
                                    </Box>
                                ))
                            }
                        </li>
                    ))}
                    {
                        question.allowOther && checkAnswer.o && checkAnswer.o.length > 0 &&
                        checkAnswer.o.map((variant, i) => (

                            <li key={i}>
                                <Box display="flex">
                                    <Box>
                                        <DotIcon style={{ width: '8px', height: '8px' }} />
                                    </Box>
                                    <Box ml={1} className={commonClasses.breakWord}>
                                        {variant}
                                    </Box>
                                </Box>
                            </li>
                        ))
                    }
                </ul>
            )
        }
        return answer && answer.length
            ? componentInNotes ? <TextMarkdownComponent content={answer} /> : <span>{answer}</span>
            : renderNowResult();
    }

    return (
        <>
            {
                question && !subQuestionList.includes(question.name) &&
                <Box pt={!isSmallScreen && 2}>
                    {
                        question.displayName && question.displayName.length &&
                        <Box minWidth={1 / 5} mr={3} pb={1}>
                            <span className={classes.question}>{question.displayName}</span>
                        </Box>
                    }
                    {
                        question.description && question.description.length &&
                        <Box minWidth={1 / 5} mr={3} pb={1}>
                            <span className={classes.description}>{question.description}</span>
                        </Box>
                    }
                    <Box className={classes.answer}>
                        {
                            answer && answer.length
                                ? renderAnswer(answer, question)
                                : renderNowResult()
                        }
                    </Box>
                </Box>
            }
        </>
    )
}