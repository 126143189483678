import React from 'react';
import './QuestionComponent.scss';
import { Box } from "@material-ui/core";
import { QuestionComponentProps } from "./QuestionComponentProps";
import { WildHealthDatePicker } from '../../../common/components/WildHealthDatePicker';

export const QuestionDateComponent: React.FC<QuestionComponentProps> = (props: QuestionComponentProps) => {
    const {
        question,
        answer,
        handleChanges,
        error
    } = props;

    return (
        <>
            <Box display="flex" justifyContent="flex-start" className="input-question">
                <WildHealthDatePicker
                    size="small"
                    id={question.name}
                    inputVariant="outlined"
                    value={answer}
                    className="time-question"
                    placeholder="MM/YY"
                    format="MM/yyyy"
                    error={!!error}
                    helperText={error}
                    onChange={(v) => { handleChanges(v.toISOString()) }}
                />
            </Box>
        </>
    )
}