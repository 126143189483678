import {useEffect, useState} from "react";
import {Subscription} from "recompose";
import {onEmit} from "../../../common/helpers/on-emit";
import {PatientModel} from "../../../patients/models/patient.model";
import {patientsQuery} from "../../../patients/stores/patientsStore";
import {Gender} from "../../../common/models/user.models";
import {addOnsService} from "../../../addons/services/addOns.service";
import {authQuery} from "../../../auth/stores/auth";

interface ordersComponentState {
    isLoading: boolean;
    gender: Gender;
}

export function useFacade(patientId): [ordersComponentState] {
    const [state, setState] = useState({
        isLoading: true
    } as ordersComponentState);

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<PatientModel>(patientsQuery.targetPatient$, patient => {
                if (patient && patient.id === patientId) {
                    setState(state => ({
                        ...state,
                        gender: patient.gender
                    }));
                    const cb = () => setState(state => ({...state, isLoading: false}));
                    addOnsService.getOrdering(authQuery.getPracticeId(), patient.gender).subscribe(cb, cb)
                }
            })
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [patientId]);

    return [state];
}