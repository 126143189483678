import { Box, Checkbox, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, {useState} from 'react';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { colors } from '../../../common/constants/colors';
import commonUseStyles from '../../../common/styles/common.styles';

export interface RosComponentProps {
    rosEnabled: boolean;
    variants: string[];
    selectedVariants: string[];
    handleChanges: (values: string[]) => void,
    handleEnableRos: (value: boolean) => void,
    errors: {}
}

export const RosComponent: React.FC<RosComponentProps> = (props: RosComponentProps) => {
    const {
        rosEnabled,
        variants,
        selectedVariants,
        handleChanges,
        handleEnableRos,
        errors
    } = props;

    const controlIcon = <CheckBoxOutlineBlankIcon style={{ fill: colors.mediumGray }} />;
    const controlCheckedIcon = <CheckBoxIcon style={{ fill: colors.main }} />
    const [other, setOther] = useState('');
    const commonClasses = commonUseStyles();


    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && other && other.length) {
            handleChanges([...selectedVariants, other]);
        }
    }

    return (
        <Box>
            {
                !!errors['ROS'] &&
                    <Box my={2} ml={1}>
                        <span className={commonClasses.error}>{errors['ROS']}</span>
                    </Box>
            }
            <Box display='flex' alignItems='center'>
                <Box>
                    <Checkbox
                        icon={controlIcon}
                        color="primary"
                        checkedIcon={controlCheckedIcon}
                        checked={rosEnabled}
                        onChange={(event) => handleEnableRos(event.target.checked)}
                    />
                </Box>
                <Box ml={2}>
                    <span>All systems reviewed and negative except</span>
                </Box>
            </Box>
            {
                rosEnabled && 
                <Box mt={2}>
                    <Autocomplete
                        size='small'
                        multiple
                        disableClearable
                        disableCloseOnSelect
                        options={variants}
                        value={selectedVariants}
                        getOptionLabel={variant => variant}
                        onChange={(e, values) => handleChanges(values)}
                        renderOption={(props) => (
                            <Box display='flex' alignItems='center'>
                                <Box>
                                    <Checkbox
                                        icon={controlIcon}
                                        color="primary"
                                        checkedIcon={controlCheckedIcon}
                                        checked={selectedVariants.includes(props)}
                                    />
                                </Box>
                                <Box ml={2}>
                                    <span>{props}</span>
                                </Box>
                            </Box>
                        )}
                        renderInput={(params) => (
                        <TextField
                            {...params}
                            margin="normal"
                            variant="outlined"
                            value={selectedVariants}
                            onKeyPress={(e) => handleKeyPress(e)}
                            onChange={(e) => setOther(e.target.value)}
                            InputProps={{ ...params.InputProps, type: 'search' }}
                        />
                        )}
                    />
                </Box>
            }
        </Box>
    );
}