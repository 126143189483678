import { createStyles, makeStyles } from "@material-ui/core/styles";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles(() =>
    createStyles({
        category: {
            background: colors.lightGray2,
            color: colors.gray1,
            borderRadius: 6,
            fontWeight: 600,
            fontSize: 12,
            height: 28,
            padding: 4,
            paddingTop: 6
        },
        link: {
            fontSize: 14,
            fontWeight: 500,
            color: colors.black,
            cursor: 'pointer',
            '&:hover': {
                color:  colors.main,
                textDecoration: 'underline',
            }
        },
    })
);
