import { ListItemText, Theme } from "@material-ui/core";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      minHeight: '100%'
    },
    header: {
      fontFamily: "Be Vietnam Pro",
      fontSize: 14,
      lineHeight: "14px",
      background: colors.gray4,
      color: colors.gray1,
      padding: "5px 10px",
      borderRadius: 17,
      fontWeight: "normal",
      [theme.breakpoints.down("xs")]: {
        background: "transparent",
      },
    },
    divider: {
      background: colors.main,
      margin: "0 32px",
    },
    noMessage: {
      height: "100%",
      [theme.breakpoints.down("xs")]: {
        height: "calc(100vh - 302px)",
      },
    }
  })
);

export const useStylesThread = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
    },
    header: {
      fontFamily: "Be Vietnam Pro",
      fontSize: 14,
      lineHeight: "14px",
      background: colors.gray4,
      color: colors.gray1,
      padding: "5px 10px",
      borderRadius: 17,
      fontWeight: "normal",
    },
  })
);

export const UnreadMessages = withStyles({
  root: {
    display: "flex",
    margin: "0 16px",

    "& .MuiListItemText-primary": {
      color: colors.main,
    },
  },
})(ListItemText);
