import React from "react";
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Checkbox, IconButton} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { useStyles } from "./messageComponent.styles";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { DeleteMessageReasonTypes, deleteMessageReasonTypesNames } from '../../models/conversation.models';
import { getNumericKeys } from '../../../common/helpers/enum-mapper';

interface DeleteMessageDialogProps {
    open: boolean;
    isDeleting: boolean;
    reason: DeleteMessageReasonTypes;
    handleDelete: () => void;
    handleClose: () => void;
    handleChanges: (value: any) => void
}

export const DeleteMessageDialog: React.FC<DeleteMessageDialogProps> = (props: DeleteMessageDialogProps) => {
    const {
        open,
        isDeleting,
        reason,
        handleDelete,
        handleClose,
        handleChanges
    } = props;

    const classes = useStyles();

    const controlIcon = <RadioButtonUncheckedIcon className="wh-tw-text-mediumGray" />;
    const controlCheckedIcon = <RadioButtonCheckedIcon className="wh-tw-text-primaryV" />

    return (
        <Dialog fullWidth open={open} onClose={() => handleClose()} aria-labelledby="form-dialog-title">
            <DialogTitle className={classes.dialogTitle} disableTypography>
                <Box className="wh-tw-text-2xl wh-tw-font-medium wh-tw-text-black">Delete Message</Box>
                <IconButton id="appointment-options-close" className={classes.dialogTitleCloseAction} onClick={() => handleClose()}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Box>
                    <Box className="wh-tw-text-base wh-tw-text-black">
                        Are you sure you want to delete this message? This action cannot be undone.
                    </Box>
                    <Box mt={3}>
                        <Box mb={1}>
                            <span className="wh-tw-text-gray1">Please select a reason for deletion</span>
                        </Box>
                        {
                            getNumericKeys(deleteMessageReasonTypesNames).map((value, i) => {
                                return (
                                    <Box display="flex" alignItems="center" key={i}>
                                        <Checkbox
                                            icon={controlIcon}
                                            color="primary"
                                            checkedIcon={controlCheckedIcon}
                                            checked={reason === value}
                                            onChange={() => handleChanges(value)}
                                        />
                                        <Box mr={2} className="wh-tw-text-black">{deleteMessageReasonTypesNames[value]}</Box>
                                    </Box>
                                )
                            })
                        }
                    </Box>
                </Box>
            </DialogContent>

            <DialogActions className={classes.dialogActions}>
                <Box mx={1.5}>
                    <WildHealthButton
                        id="cancel-delete" 
                        color="tertiary"
                        size="large"
                        onClick={() => handleClose()}
                    >
                        Cancel
                    </WildHealthButton>
                </Box>
                <Box>
                    <WildHealthButton
                        id="delete-message"
                        color="danger"
                        size="large"
                        disabled={!reason}
                        loading={isDeleting}
                        onClick={() => handleDelete()}
                    >
                        Delete
                    </WildHealthButton>
                </Box>
            </DialogActions>
        </Dialog>
    );
}
