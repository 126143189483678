import React from "react";
import {useFacade} from "./healthReportBadgeComponent.hooks";
import {Badge} from "@material-ui/core";

interface HealthReportBadgeComponentProps {
    type: "reports" | "notes" | null;
}

export const HealthReportBadgeComponent: React.FC<HealthReportBadgeComponentProps> = (props: HealthReportBadgeComponentProps) => {
    const [notCompleted] = useFacade(props.type);

    return (
        <>
            {
                notCompleted
                    ? <Badge badgeContent={'...'} color="secondary"/>
                    : <></>
            }
        </>
    );
}