import Axios from 'axios-observable';
import { Observable } from 'rxjs';
import { authHeader } from "../../common/helpers/auth-header";
import { snackService } from "../../common/snack/state";
import { leadSourcesStore, LeadSourcesStore } from '../stores/leadSources.store';
import {LeadSourceModel} from "../models/leadSource.models";

/**
 * Provides method for working with lead sources
 */
export class LeadSourcesService {
    private url = `${process.env.REACT_APP_API_URL}LeadSources`;

    constructor(private leadSourcesStore: LeadSourcesStore) {
    }

    public getAll(): Observable<LeadSourceModel[]> {
        const config = {
            headers: authHeader()
        };

        return new Observable<LeadSourceModel[]>(observer => {
            Axios.get<LeadSourceModel[]>(this.url, config)
                .pipe()
                .subscribe(
                    response => {
                        this.leadSourcesStore.update({ leadSources: response.data })
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getActive(practiceId: number): void {
        const config = {
            headers: authHeader()
        };

        Axios.get(`${this.url}/Active?practiceId=${practiceId}`, config)
            .pipe()
            .subscribe(
                response => {
                    this.leadSourcesStore.update({ leadSources: response.data })
                },
                error => snackService.commonErrorHandler(error)
            );
    }

    public getEmployeeActive(): void {
        const config = {
            headers: authHeader()
        };

        Axios.get(`${this.url}/Employee/Active`, config)
            .pipe()
            .subscribe(
                response => {
                    this.leadSourcesStore.update({ leadSources: response.data })
                },
                error => snackService.commonErrorHandler(error)
            );
    }

    public create(name: string): Observable<any> {
        const config = {
            headers: authHeader()
        };

        return new Observable(observer => {
            Axios.post(`${this.url}`, { name: name }, config)
            .pipe()
            .subscribe(
                response => {
                    this.leadSourcesStore.createLeadSource(response.data);
                    snackService.success("Lead source successfully created!");
                    observer.next();
                    observer.complete()
                },
                error => {
                    snackService.commonErrorHandler(error);
                    observer.error();
                    observer.complete()
                }
            );
        })
        
    }

    public changeActivity(id: number): void {
        const config = {
            headers: authHeader()
        };

        Axios.put(`${this.url}/${id}/ChangeActivity`, {}, config)
            .pipe()
            .subscribe(
                response => {
                    this.leadSourcesStore.changeActivity(response.data);
                    snackService.success("Lead Source status successfully updated!");
                },
                error => snackService.commonErrorHandler(error)
            );
    }

    public delete(id: number): void {
        const config = {
            headers: authHeader()
        };

        Axios.delete(`${this.url}/${id}`, config)
            .pipe()
            .subscribe(
                response => {
                    this.leadSourcesStore.deleteLeadSource(response.data);
                    snackService.success("Lead source successfully deleted");
                },
                error => snackService.commonErrorHandler(error)
            );
    }
}

export const leadSourcesService = new LeadSourcesService(leadSourcesStore);