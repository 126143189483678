import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles(() =>
    createStyles({
        rootView: {
            fontSize: 14,
            minHeight: 28,
            borderRadius: 2,
            display: 'flex',
            alignItems: 'center',
            width: 'fit-content',
            whiteSpace: 'nowrap',
            paddingRight: 10,
            paddingLeft: 10,
            backgroundColor: colors.separators,
        },
        button: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: '8px',
            color: colors.main,
            fontSize: 14,
            cursor: 'pointer'
        },
        iconInfo: {
            cursor: 'pointer',
            '&:hover ~ .tooltip-element': {
                display: 'flex',
            },
        },
        notificationToolTipText: {
            lineHeight: 1.5,
            fontFamily: 'Be Vietnam Pro',
            fontWeight: 400,
            fontSize: 14,
            color: colors.white,
        },
        arrow: {
            color: colors.lightBlack,
        },
        customWidth: {
            width: '35vw',
            maxWidth: '35vw',
            backgroundColor: colors.lightBlack,
            padding: 16,
            boxShadow: ` 0px 4px 9px rgba(168, 173, 198, 0.15)`,
        },
        tooltipPlacement: {
            margin: '8px 0',
        },
    })
);
