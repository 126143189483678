import React from 'react';
import clsx from 'clsx';
import { Box, IconButton } from '@material-ui/core';
import { useStyles } from "./SelectFellowShipRoleComponent.styles";
import { useFacade } from "./selectFellowshipRoleComponent.hooks";
import { CreatePracticumPatientForm } from "./CreatePracticumPatientForm";
import { Add } from '@material-ui/icons';
import { FellowshipRegistrationNextButton } from "../fellowshipRegistrationButton/FellowshipRegistrationNextButton";
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import commonUseStyles from '../../../common/styles/common.styles';
import { colors } from '../../../common/constants/colors';

interface SelectFellowshipRoleComponentProps {
    goNext: () => void
}

export const SelectPMFellowshipRoleComponent: React.FC<SelectFellowshipRoleComponentProps> = (props: SelectFellowshipRoleComponentProps) => {
    const {
        goNext
    } = props;

    const [
        {
            pmFellows,
            isLoading,
            practicumPatients,
            errors
        },
        handleCreatePracticumPatient,
        handleDeletePracticumPatient,
        handleUndoDeletePracticumPatient,
        handlePracticumPatientChanges,
        handleSubmitPracticumPatients,
        canGoNext
    ] = useFacade()

    const classes = useStyles();
    const commonClasses = commonUseStyles();

    const renderPracticumPatients = (): JSX.Element => {
        return (
            <Box mt={6}>
                <Box>
                    <h2 className={clsx(commonClasses.size24, commonClasses.textSemiBold, commonClasses.colorBlack)}>Practicum Patient</h2>
                </Box>
                {
                    practicumPatients.filter(x => !x.deleted).map((patient, index) => (
                        <Box key={index} mt={4}>
                            <CreatePracticumPatientForm
                                practicumPatient={patient}
                                fellows={pmFellows}
                                handleChanges={handlePracticumPatientChanges}
                                handleDelete={handleDeletePracticumPatient}
                                errors={errors}
                            />
                        </Box>
                    ))
                }
                <Box mt={4} display="flex" justifyContent="space-between" alignItems="start">
                    <Box display="flex" justifyContent="flex-start" alignItems="center">
                        <Box>
                            <IconButton
                                id="select-fellowship-role-add-another"
                                onClick={() => handleCreatePracticumPatient()}
                            >
                                <Add
                                    style={{
                                        color: colors.main,
                                        backgroundColor: colors.gray3
                                    }}
                                />
                            </IconButton>
                        </Box>
                        <Box ml={1}>
                            <span className={clsx(commonClasses.colorMain, commonClasses.textMedium, commonClasses.size14)}>Add Another Patient</span>
                        </Box>
                    </Box>
                    <Box>
                        {
                            practicumPatients.filter(x => x.deleted).map((patient, index) => (
                                <Box key={index} className={classes.undoDeleteBox} display="flex" alignItems="center" mt={1} px={3}>
                                    <Box>
                                        <span>Patient {patient.number} Deleted</span>
                                    </Box>
                                    <Box
                                        ml={5}
                                        id="select-fellowship-role-undo"
                                        onClick={() => handleUndoDeletePracticumPatient(patient.number)}
                                    >
                                        <span className={classes.undoDelete}>Undo</span>
                                    </Box>
                                </Box>
                            ))
                        }
                    </Box>
                </Box>
            </Box>
        );
    }

    const renderContent = (): JSX.Element => {
        if (!pmFellows || !pmFellows.length) {
            return (
                <Box mt={5}>
                    <WildHealthLinearProgress />
                </Box>
            )
        }

        return (
            <>
                <Box mt={6}>
                    {
                        renderPracticumPatients()
                    }
                </Box>
                <Box mt={5}>
                    <FellowshipRegistrationNextButton
                        canGoNext={canGoNext()}
                        isLoading={isLoading}
                        goNext={handleSubmitPracticumPatients}
                        label={"Submit"}
                    />
                </Box>
            </>
        );
    }
    return (
        <Box px="20%">
            {
                renderContent()
            }
        </Box>
    );
}