import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            overflowY: "auto",
            minHeight: '100%'
        }
    })
);
