import { Box, Dialog, DialogActions, DialogContent, IconButton, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { WildHealthButton } from '../../components/wildHealthButton/WildHealthButton';
import { useFacade } from "./infoDialog.hooks";
import { useStyles } from "./InfoDialog.styles";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Cancel from "@img/icons/cancel.svg";

export const CommonInfoDialog: React.FC = () => {
    const [state, handleClose] = useFacade();

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleClose(true);
        }
    }

    const classes = useStyles();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box onKeyPress={(e) => handleKeyPress(e)}>
            <Dialog
                open={state.isOpen}
                onClose={() => handleClose(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.dialog}
            >
                {!fullScreen && (
                    <MuiDialogTitle
                        className={classes.dialogTitle}
                        disableTypography
                    >
                        <Box pl={1} pr={1} textAlign='end'>
                            <IconButton id="info-dialog-close" onClick={() => {
                                handleClose(false)
                            }}>
                                <img src={Cancel} alt='Cancel' />
                            </IconButton>
                        </Box>

                    </MuiDialogTitle>
                )}

                <DialogContent className={classes.content}>
                    {fullScreen && <IconButton
                        id="info-dialog-close"
                        onClick={() => {
                            handleClose(false)
                        }}
                        className={classes.dialogCloseButton}
                    >
                        <img src={Cancel} alt='Cancel' />
                    </IconButton>}
                    {state.image && <Box> <img
                        src={state.image}
                        alt='img' /></Box>}
                    <Box className={classes.title}>{state.title}</Box>
                    <Typography align='center'>
                        {state.message}
                    </Typography>
                </DialogContent>

                <DialogActions className={classes.dialogActions}>
                    {state.acceptTitle && <WildHealthButton id={`info-dialog-${state.acceptTitle}-${state.title}`} size='large' color={state.variant === 'danger' ? 'danger' : 'primary'} onClick={() => handleClose(true)} fullWidth={fullScreen}>
                        {state.acceptTitle}
                    </WildHealthButton>}
                </DialogActions>
            </Dialog>
        </Box>
    );
}
