import { Store, StoreConfig } from '@datorama/akita';
import { HealthSummaryDataModel } from '../../../healthSummary/models/healthSummary.models';
import { StateModel } from '../../models/address.model';
import { BusinessProfileModel } from '../../models/businessProfile.model';
import {EmployeeProfileModel} from "../../models/employeeProfile.model";
import {PatientProfileModel} from "../../models/patientProfile.model";
import {PracticeInfoModel, PracticeInfoShortModel} from "../../models/practice.model";
import { PracticeProfileModel } from '../../models/practiceProfile.model';

/**
 * Represents profile state
 */
export interface AddressState {
    states: StateModel[];
}

export function createInitialState(): AddressState {
    return {
        states: []
    };
}

@StoreConfig({name: 'address', resettable: true })
export class AddressStore extends Store<AddressState> {
    constructor() {
        super(createInitialState());
    }

    public setStates(states: StateModel[]) {
        this.update({states: states})
    }
}

export const addressStore = new AddressStore();
