import React from "react";
import { AreaChart, Area, ResponsiveContainer } from "recharts";
import { colors } from "../../../common/constants/colors";
import { useTheme, useMediaQuery } from "@material-ui/core";
interface LabInputsGraphComponentProps {
  data: number[];
  renderRange?: boolean;
  minValue?: number;
  maxValue?: number;
  height: number;
  width: number;
}

export const LabInputsGraphComponent: React.FC<LabInputsGraphComponentProps> = (
  props: LabInputsGraphComponentProps
) => {
  const { data, renderRange, minValue, maxValue, height, width } = props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const graphData: object[] = data.map((value) => {
    return {
      min: minValue,
      max: maxValue,
      value: value,
    };
  });

  const CheckIsNormal = (value: number): boolean => {
    if (minValue !== null && value <= minValue) {
      return false;
    }

    return !(maxValue !== null && value >= maxValue);
  };

  const CustomizedDot = (props) => {
    const { cx, cy, value } = props;

    if (CheckIsNormal(value[1])) {
      return (
        <circle
          key={`${cx}-${value[1]}`}
          cx={cx}
          cy={cy}
          r={2}
          stroke='#bfdde9'
          strokeWidth={3.5}
          fill='bfdde9'
        />
      );
    }

    return (
      <circle
        cx={cx}
        cy={cy}
        r={2}
        stroke={colors.bad}
        strokeWidth={3.5}
        fill={colors.bad}
      />
    );
  };

  return (
    <ResponsiveContainer
      width={isSmallScreen ? "100%" : width}
      height={isSmallScreen ? 20 : height}
    >
      <AreaChart
        data={graphData}
        margin={{
          top: 5,
          right: 5,
          left: 5,
          bottom: 5,
        }}
      >
        {renderRange && minValue !== null && (
          <Area
            key='min-value'
            isAnimationActive={false}
            type='step'
            dataKey='min'
            stroke={colors.gray2}
            strokeWidth={1}
            strokeDasharray='10 10'
            fill={colors.white}
            fillOpacity={0}
          />
        )}
        {renderRange && maxValue !== null && (
          <Area
            key='max-value'
            isAnimationActive={false}
            type='step'
            dataKey='max'
            stroke={colors.gray2}
            strokeWidth={1}
            strokeDasharray='10 10'
            fill={colors.white}
            fillOpacity={0}
          />
        )}
        <Area
          key='value'
          isAnimationActive={false}
          type='linear'
          dataKey='value'
          stroke='#bedae7'
          strokeWidth={2}
          fill='#f2f7f9'
          fillOpacity={0.25}
          dot={CustomizedDot}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
