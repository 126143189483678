import Axios from "axios-observable";
import { Observable } from "rxjs";
import { MdmModel } from "../models/notes.models";

/**
 * Provides method for working with MDM codes
 */
export class MdmCodesService {
    private url = `${process.env.REACT_APP_API_URL}MdmCodes`;

    constructor() {
    }

    public get(noteType: number): Observable<MdmModel[]> {
        const url = `${this.url}?noteType=${noteType}`;

        return new Observable<MdmModel[]>(observer => {
            Axios.get(url).pipe()
                .subscribe(
                    response => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }
}

export const mdmCodesService = new MdmCodesService();