import { Box, Dialog, DialogActions, DialogContent, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { useFacade } from "./viewAgreementsDialog.hooks";
import { AgreementsComponent } from "../AgreementsComponent/AgreementsComponent";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";

export const AgreementDialog: React.FC = () => {
    const [
        {
            isOpen,
            agreement,
            agreementPage,
            paymentPlan,
            paymentPeriod,
            paymentPrice
        },
        dialogRef,
        handleClose,
    ] = useFacade();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            fullWidth
            open={isOpen}
            maxWidth={'md'}
            fullScreen={isMobile}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent id="agreements-dialog-content" ref={dialogRef}>
                <Box mt={5} width={1}>
                    <AgreementsComponent
                        agreement={agreement}
                        currentAgreementPage={agreementPage}
                        paymentPlan={paymentPlan}
                        paymentPeriod={paymentPeriod}
                        paymentPrice={paymentPrice}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Box width={140}>
                    <WildHealthButton
                        id="view-agreements-close"
                        onClick={() => handleClose()}
                        fullWidth
                    >
                        Close
                    </WildHealthButton>
                </Box>
            </DialogActions>
        </Dialog>
    );
}