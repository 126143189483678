import Axios from 'axios-observable';
import {Observable} from 'rxjs';
import {authHeader} from '../../common/helpers/auth-header';
import {snackService} from '../../common/snack/state';
import {MdmCommonModel, MdmReasonModel} from '../models/notes.models';
import {NotesStore, notesStore} from '../stores/notes';

/**
 * Provides method for working with MDM plans
 */
export class MdmPlansService {
    private url = `${process.env.REACT_APP_API_URL}MdmPlans`;

    constructor(private store: NotesStore) {
    }

    private getConfig = () => ({headers: authHeader()});

    public getAllCommonMdms(): Observable<MdmCommonModel[]> {
        const url = `${this.url}/Common`;

        return new Observable((observer) => {
            Axios.get<MdmCommonModel[]>(url, this.getConfig())
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({commonMdms: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getCommonMdmById(id: number): Observable<MdmCommonModel> {
        const url = `${this.url}/Common/MdmPlan/${id}`;
    
        return new Observable(observer => {
            Axios.get<MdmCommonModel>(url, this.getConfig())
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({ targetCommonMdm: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.error();
                    }
                );
        })
    }

    public createCommonMdmPlan(model: MdmCommonModel): Observable<MdmCommonModel> {
        const url = `${this.url}/Common/MdmPlan`;
    
        return new Observable(observer => {
            Axios.post<MdmCommonModel>(url, model, this.getConfig())
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.addCommonMdm(response.data);
                        snackService.success("Common Mdm Plan successfully created!")
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.error();
                    }
                );
        })
    }

    public updateCommonMdmPlan(model: MdmCommonModel): Observable<MdmCommonModel> {
        const url = `${this.url}/Common/UpdateMdmPlan`;
    
        return new Observable(observer => {
            Axios.put<MdmCommonModel>(url, model, this.getConfig())
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.updateCommonMdm(response.data);
                        snackService.success("Common Mdm Plan successfully updated!")
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.error();
                    }
                );
        })
    }

    public createMdmReason(model: MdmReasonModel): Observable<MdmReasonModel> {
        const url = `${this.url}/Common/MdmPlanReason`;
    
        return new Observable(observer => {
            Axios.post<MdmReasonModel>(url, model, this.getConfig())
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.addReason(response.data);
                        snackService.success("Mdm Reason successfully created!")
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.error();
                    }
                );
        })
    }

    public updateMdmReason(model: MdmReasonModel): Observable<MdmReasonModel> {
        const url = `${this.url}/Common/UpdateMdmPlanReason`;
    
        return new Observable(observer => {
            Axios.post<MdmReasonModel>(url, model, this.getConfig())
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.updateReason(response.data);
                        snackService.success("Mdm Reason successfully updated!")
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.error();
                    }
                );
        })
    }

    public deleteCommonMdmPlan(id: number) {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.delete(`${this.url}/Common/${id}`, config)
                .pipe()
                .subscribe(
                    () => {
                        this.store.removeCommonMdm(id);
                        snackService.success('Common Mdm Plan successfully deleted');
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }

    public deleteMdmReason(mdmId: number, reasonId: number) {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.delete(`${this.url}/Common/${mdmId}/Reasons/${reasonId}`, config)
                .pipe()
                .subscribe(
                    () => {
                        this.store.removeReason(reasonId);
                        snackService.success('Mdm Reason successfully deleted');
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }
}

export const mdmPlansService = new MdmPlansService(notesStore);
