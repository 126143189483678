interface ObjectWithName {
  firstName?: string;
  lastName?: string;
  title?: string;
}

export function fullName(obj: ObjectWithName) {
  if (!obj) return "";
  const { firstName, lastName } = obj;
  if (!firstName && !lastName) return "";
  return [firstName || "", lastName || ""].join(" ").trim();
}