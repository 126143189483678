import { HealthSummaryDataModel, HealthSummaryMapModel } from '../../models/healthSummary.models'
import { healthSummaryService } from '../../services/healthSummary.service'
import { healthSummaryQuery, healthSummaryStore } from '../../stores'
import { useState } from 'react'
import {useFlags} from "launchdarkly-react-client-sdk";

export interface HealthSummaryContentComponentState {
    isLoading: boolean
    editMode: boolean
    value: string
}

export function useFacade(map: HealthSummaryMapModel, patientId: number): [
    Function,
    Function,
    Function,
    Function,
    HealthSummaryContentComponentState
] {
    const [state, setState] = useState({
        isLoading: false,
        editMode: false,
        value: ''
    } as HealthSummaryContentComponentState);

    const flags = useFlags();

    const handleEditValue = (value: string) => {
        setState({
            ...state,
            value: value
        })
    }

    const cancelChanges = () => {
        setState({
            ...state,
            editMode: false,
            value: ''
        })

        healthSummaryStore.refreshData()
    }

    const handleEditMode = (editMode: boolean) => {
        const item = healthSummaryQuery.getDataItem(map.key)

        if(!item) {
            const newDataItem = {
                name: '',
                value: '',
                key: map.key,
                isNew: true,
                patientId: patientId
            } as HealthSummaryDataModel

            healthSummaryStore.add(newDataItem)
        } 

        const value = editMode && item ? item.value : ''

        setState({
            ...state,
            editMode: editMode,
            value: value
        })
    }

    const saveChanges = () => {
        setState(state => ({
            ...state,
            isLoading: true,
            editMode: false
        }))

        const dataItem = healthSummaryQuery.getDataItem(map.key)

        if (!dataItem) {
            return
        }

        dataItem.value = state.value

        healthSummaryStore.edit(dataItem)

        const callback = () => {
            setState(state => ({
                ...state,
                isLoading: false
            }))
        }

        dataItem.isNew 
            ? healthSummaryService.create(patientId, map, dataItem, flags).subscribe(callback)
            : healthSummaryService.update(patientId, map, dataItem, flags).subscribe(callback)
    }

    return [
        handleEditValue,
        handleEditMode,
        cancelChanges,
        saveChanges,
        state
    ]
}