import {GeneralValidator} from "../../../common/validation/general-validator";
import {
    maxLengthValidationRule,
    phoneNumberValidationRule,
    requiredValidationRule,
    notWhitespaceValidationRule,
} from "../../../common/validation/general-validation-rules";

export const businessAccountComponentValidator = new GeneralValidator({
    'name': [notWhitespaceValidationRule(), requiredValidationRule(), maxLengthValidationRule(50)],
    'phoneNumber': [phoneNumberValidationRule],
    'taxIdNumber': [notWhitespaceValidationRule(), requiredValidationRule(), maxLengthValidationRule(50)],
    'paymentToken': [requiredValidationRule(1, 'Please enter card data.')],

    'address.country': [notWhitespaceValidationRule(), requiredValidationRule(), maxLengthValidationRule(50)],
    'address.city': [notWhitespaceValidationRule(), requiredValidationRule(), maxLengthValidationRule(50)],
    'address.state': [notWhitespaceValidationRule(), requiredValidationRule(), maxLengthValidationRule(50)],
    'address.zipCode': [notWhitespaceValidationRule(), requiredValidationRule(), maxLengthValidationRule(10)],
    'address.streetAddress1': [notWhitespaceValidationRule(), requiredValidationRule(), maxLengthValidationRule(100)],
    'address.streetAddress2': [notWhitespaceValidationRule(), maxLengthValidationRule(100)],

    'billingAddress.country': [notWhitespaceValidationRule(), requiredValidationRule(), maxLengthValidationRule(50)],
    'billingAddress.city': [notWhitespaceValidationRule(), requiredValidationRule(), maxLengthValidationRule(50)],
    'billingAddress.state': [notWhitespaceValidationRule(), requiredValidationRule(), maxLengthValidationRule(50)],
    'billingAddress.zipCode': [notWhitespaceValidationRule(), requiredValidationRule(), maxLengthValidationRule(10)],
    'billingAddress.streetAddress1': [notWhitespaceValidationRule(), requiredValidationRule(), maxLengthValidationRule(100)],
    'billingAddress.streetAddress2': [notWhitespaceValidationRule(), maxLengthValidationRule(100)],
});
