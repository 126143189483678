import React from "react";
import { Box } from "@material-ui/core";
import { useStyles } from "./WildHealthStatusLabel.styles";
import clsx from "clsx";

interface WildHealthStatusLabelProps {
    color?: 'good' | 'selected' | 'average1' | 'average2' | 'bad' | 'normal' | 'neutral' | 'success';
    minWidth?: number;
    px?: number;
    borderRadius?: number;
    fontWeight?: number;
    children?: any;
}

export const WildHealthStatusLabel: React.FC<WildHealthStatusLabelProps> = (props: WildHealthStatusLabelProps) => {
    const {
        color = 'neutral',
        minWidth = 64,
        px = 14,
        borderRadius = 4,
        fontWeight = 400,
        children,
    } = props;

    const classes = useStyles({
        minWidth: minWidth,
        paddingRight: px,
        paddingLeft: px,
        borderRadius: borderRadius,
        fontWeight: fontWeight
    })();

    return (
        <Box
            className={clsx(classes.root, {
                [classes.good]: color === 'good',
                [classes.selected]: color === 'selected',
                [classes.average1]: color === 'average1',
                [classes.average2]: color === 'average2',
                [classes.bad]: color === 'bad',
                [classes.normal]: color === 'normal',
                [classes.neutral]: color === 'neutral',
                [classes.success]: color === 'success',
            })}
        >
            <span>{children}</span>
        </Box>
    );
};