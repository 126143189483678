import { Box, Container } from '@material-ui/core';
import React from 'react';
import { LayoutComponent } from '../../../../components/layoutComponent/LayoutComponent';
import { TitlesService } from '../../../../constants/title.constants';
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { QuestionnaireProgressBarComponent } from "../../components/questionnaireProgressBarComponent/questionnaireProgressBarComponent";
import { QuestionnairePages, SaveQuestionnaireResultModel } from "../../models/questionnaire.models";
import { CardioRespiratorySurvey } from "./pages/CardioRespiratorySurvey";
import { ExerciseSurvey } from "./pages/ExerciseSurvey";
import { GeneralSurvey } from "./pages/GeneralSurvey";
import { MedicalSurvey } from "./pages/MedicalSurvey";
import { NutritionSurvey } from "./pages/NutritionSurvey";
import { SleepSurvey } from "./pages/SleepSurvey";
import { StressSurvey } from "./pages/StressSurvey";
import { useAnonymousFacade, useAuthorizedFacade, useFacade } from "./initialQuestionnaireSurveyComponent.hooks";
import { Observable } from 'rxjs';

export const AnonymousInitialQuestionnaireSurveyPage: React.FC = (props: any) => {
    const intakeId = props?.match?.params?.intakeId;
    const [
        saveResult,
        submitResult,
        startQuestionnaire,
        getPatient,
        savePatient
    ] = useAnonymousFacade(intakeId);

    return (
        <LayoutComponent title={TitlesService.questionnaireTitle}>
            <Container maxWidth="lg" className="content">
                <Box mt={5}>
                    <InitialQuestionnaireSurveyComponent
                        saveResult={saveResult}
                        submitResult={submitResult}
                        startQuestionnaire={startQuestionnaire}
                        getPatient={getPatient}
                        savePatient={savePatient}
                    />
                </Box>
            </Container>
        </LayoutComponent>
    );
}

export const AuthorizedInitialQuestionnaireSurveyComponent: React.FC = () => {
    const [
        saveResult,
        submitResult,
        startQuestionnaire,
        getPatient,
        savePatient
    ] = useAuthorizedFacade();

    return (
        <InitialQuestionnaireSurveyComponent
            saveResult={saveResult}
            submitResult={submitResult}
            startQuestionnaire={startQuestionnaire}
            getPatient={getPatient}
            savePatient={savePatient}
        />
    );
}

interface InitialQuestionnaireSurveyComponentProps {
    saveResult: Function;
    submitResult: (result: SaveQuestionnaireResultModel, history: any) => Observable<unknown>;
    startQuestionnaire: Function;
    getPatient: Function;
    savePatient: Function;
}

const InitialQuestionnaireSurveyComponent: React.FC<InitialQuestionnaireSurveyComponentProps> = (props: InitialQuestionnaireSurveyComponentProps) => {
    const {
        saveResult,
        submitResult,
        startQuestionnaire,
        getPatient,
        savePatient
    } = props;

    const [
        {
            currentPage,
            questionnaire,
            result,
            patient,
            isLoading,
            errors,
        },
        getNavigationContext,
        handleChanges,
        handlePatientChanges
    ] = useFacade(saveResult, submitResult, startQuestionnaire, getPatient, savePatient)

    if (!result || !questionnaire || !patient) {
        return <WildHealthLinearProgress/>
    }

    if (isLoading) {
        return (
            <>
                <Box mb={5}>
                    <WildHealthLinearProgress />
                </Box>
            </>
        );
    }

    return (
        <>
            <Box mb={5}>
                <QuestionnaireProgressBarComponent currentPage={currentPage}/>
            </Box>
            {
                currentPage === QuestionnairePages.General &&
                <GeneralSurvey
                    errors={errors}
                    result={result}
                    patient={patient}
                    questionnaire={questionnaire}
                    handleChanges={handleChanges}
                    handlePatientChanges={handlePatientChanges}
                    navigationContext={getNavigationContext()}
                />
            }
            {
                currentPage === QuestionnairePages.Medical &&
                <MedicalSurvey
                    errors={errors}
                    result={result}
                    questionnaire={questionnaire}
                    handleChanges={handleChanges}
                    navigationContext={getNavigationContext()}
                />
            }
            {
                currentPage === QuestionnairePages.Nutrition &&
                <NutritionSurvey
                    errors={errors}
                    result={result}
                    questionnaire={questionnaire}
                    handleChanges={handleChanges}
                    navigationContext={getNavigationContext()}
                />
            }
            {
                currentPage === QuestionnairePages.Exercise &&
                <ExerciseSurvey
                    errors={errors}
                    result={result}
                    questionnaire={questionnaire}
                    handleChanges={handleChanges}
                    navigationContext={getNavigationContext()}
                />
            }
            {
                currentPage === QuestionnairePages.CardioRespiratory &&
                <CardioRespiratorySurvey
                    errors={errors}
                    result={result}
                    questionnaire={questionnaire}
                    handleChanges={handleChanges}
                    navigationContext={getNavigationContext()}
                />
            }
            {
                currentPage === QuestionnairePages.Sleep &&
                <SleepSurvey
                    errors={errors}
                    result={result}
                    questionnaire={questionnaire}
                    handleChanges={handleChanges}
                    navigationContext={getNavigationContext()}
                />
            }
            {
                currentPage === QuestionnairePages.Stress &&
                <StressSurvey
                    errors={errors}
                    result={result}
                    questionnaire={questionnaire}
                    handleChanges={handleChanges}
                    navigationContext={getNavigationContext()}
                />
            }
        </>
    )
}
