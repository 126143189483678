import { makeStyles, withStyles, createStyles, Switch, Theme } from '@material-ui/core';
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) => ({
  selectedPlan: {
    border: '1px solid',
    borderColor: colors.main,
    borderRadius: 8,
  },
  yearlyPaymentContext: {
    color: colors.linkBlack,
    fontWeight: 600,
    paddingLeft: 4,
  },
  activePlan: {
    border: '2px solid',
    borderColor: colors.primaryV,
    borderRadius: 12,
    backgroundColor: colors.mint50,
    position: 'relative'
  },
  tipCard: {
    position: 'absolute',
    top: -14,
    borderRadius: 100,
    backgroundColor: colors.primaryV,
    padding: '4px 10px',
    fontSize: 12,
    fontWeight: 500,
    color: colors.white,
    width: 'fit-content'
  },
  strategyContext: {
    fontSize: 18,
    fontWeight: 500,
    color: colors.navy,
    textTransform: 'capitalize',
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  }
}));

export const WildHealthSwitcher = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 65,
      height: 36,
      padding: 0,
    },
    switchBase: {
      padding: 5,
      '&$checked': {
        transform: 'translateX(30px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: colors.secondary,
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 26,
      height: 26,
    },
    track: {
      borderRadius: 36 / 2,
      border: 'none',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      backgroundColor: colors.secondary,
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }),
)(Switch);