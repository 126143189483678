import { ReactComponent as OpenSideIcon } from '@img/icons/OpenSide.svg';
import { Box, Divider, Drawer, IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import clsx from 'clsx';
import React from 'react';
import { BackButton } from '../../../common/components/wildHealthButton/BackButton';
import { MessagesPlaceHolder } from '../../../common/components/wildHealthPlaceHolder/MessagesPlaceHolder';
import { employeeTypesNames } from '../../../employee/models/employee.enums';
import { conversationTitles, ConversationType, PatientConversationModel } from "../../models/conversation.models";
import { AttachmentsViewComponent } from '../attachmentsViewComponent/AttachmentsViewComponent';
import { ChatMembersComponent } from '../chatMembersComponent/ChatMembersComponent';
import { ConversationViewComponent } from '../conversationViewComponent/ConversationViewComponent';
import { MessageAvatarsComponent } from '../messageAvatarsComponent/MessageAvatarsComponent';
import { useFacade } from './fullHealthCareViewChatComponent.hooks';
import { useStyles } from '../fullHealthCareChatComponent/fullHealthCareChatComponent.styles';
import { displayShortName } from '../../helpers/messages.converter';
import { profileQuery } from '../../../account/stores/profileStore';

export interface FullViewChatComponentProps {
    conversation: PatientConversationModel;
    messages: any;
    closeFullChat: () => void;
}

export const FullHealthCareViewChatComponent: React.FC<FullViewChatComponentProps> = (props: FullViewChatComponentProps) => {
    const classes = useStyles();

    const [
        {
            isSideAreaOpen
        },
        handleToggleSideArea,
    ] = useFacade();

    const theme = useTheme();
    const isMiddleScreen = useMediaQuery(theme.breakpoints.down('md'));

    const renderGoBack = () => {
        return (
            <Box pb={2}>
                <BackButton
                    id='full-health-care-view-close'
                    onClick={() => props.closeFullChat()}
                >
                    <Box
                        display="flex"
                        alignItems="center"
                    >
                        <ArrowBack fontSize="small" />
                        <Box ml={1}>
                            <span>Back</span>
                        </Box>
                    </Box>
                </BackButton>
            </Box>
        )
    }

    const renderHeaderArea = () => {
        const defaultParticipants = [{image: '', initials: 'HC'}, {image: '', initials: 'Pr'}];

        const participantsInitials = props.conversation ?
        props.conversation.employees.filter(i => i.isActive && !i.isDeleted).map(i => ({image: profileQuery.getPhoto(i.employeeId), initials: displayShortName(i.firstName, i.lastName)})) : defaultParticipants;

        return (
            <Box
                p={2}
                height='100%'
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                className={classes.header}
            >
                <Box display='flex'>
                    <Box>
                        <MessageAvatarsComponent participants={participantsInitials} />
                    </Box>
                    <Box ml={2} className={classes.title}>
                        {conversationTitles.get(ConversationType.HealthCare)}
                    </Box>
                </Box>
                {
                    isMiddleScreen &&
                    <Box ml={2}>
                        <IconButton id='full-health-care-view-side-area' onClick={() => handleToggleSideArea(!isSideAreaOpen)}>
                            <OpenSideIcon className={classes.icon} />
                        </IconButton>
                        <Drawer
                            anchor='right'
                            open={isSideAreaOpen}
                            onClose={() => handleToggleSideArea(!isSideAreaOpen)}
                        >
                            <Box className={classes.sideMobile}>
                                {renderSideArea}
                            </Box>
                        </Drawer>
                    </Box>
                }
            </Box>
        )
    }

    const renderSideArea = (className: any) => {
        const members = props.conversation ?
        props.conversation.employees
                .filter(i => i.isActive && !i.isDeleted)
                .map(i => ({image: profileQuery.getPhoto(i.employeeId), firstName: i.firstName, lastName: i.lastName, position: employeeTypesNames.get(i.type), initials: displayShortName(i.firstName, i.lastName)})) :
            [{firstName: 'Health', lastName: 'Coach', initials: 'HC', position: 'Health Coach'}, {firstName: 'Provider', lastName: '', initials: 'Pr', position: 'Provider'}]

        return (
            <Box className={className}>
                <ChatMembersComponent members={members}/>

                <Divider className={classes.divider} orientation='horizontal'/>

                <AttachmentsViewComponent />
            </Box>
        )
    }

    const renderContent = () => {
        return props.conversation.employees?.length
        ? <ConversationViewComponent conversation={props.conversation}  messages={props.messages} threadHeight='calc(100vh - 247px)' />
        : <MessagesPlaceHolder message="Health Care conversation will become available when your health team has been assigned."/> 
    }

    return (
        <Box className={classes.root}>
            {renderGoBack()}

            <div className={classes.chat}>
                <div className={clsx('g', classes.messages)}>
                    {renderHeaderArea()}

                    <Divider className={classes.divider} orientation='horizontal' />
                    {
                        !props.conversation ?
                        <MessagesPlaceHolder message="Health Care conversation will become available when your health team has been assigned."/> 
                        : renderContent()
                    }
                </div>
                {
                    !isMiddleScreen && 
                    <div className={clsx('g', classes.side)}>
                        <Divider className={classes.divider} orientation='vertical'/>

                        {renderSideArea(clsx('flat-scroll', classes.sideContainer, classes.sideMaxHeight))}
                    </div>
                }
            </div>
        </Box>
    )
};
