import { Box, Button, CircularProgress, TextField } from "@material-ui/core";
import React from "react";
import { phoneNumberInput } from "../../../../../common/components/PhoneNumberInput";
import WildHealthLinearProgress from "../../../../../common/components/WildHealthLinearProgress";
import { useFacade } from "./pharmacyInfoWidgetComponent.hooks";
import { useStyles } from "./pharmacyInfoWidgetComponent.styles";
import EditIcon from '@material-ui/icons/Edit'
import { WildHealthButton } from "../../../../../common/components/wildHealthButton/WildHealthButton";



export interface PharmacyInfoWidgetComponentProps {
  patientId: number | null;
}

export const PharmacyInfoWidgetComponent: React.FC<PharmacyInfoWidgetComponentProps> = (
  props: PharmacyInfoWidgetComponentProps
) => {

  const [
    {
      edit,
      isLoading,
      isSaveChanges,
      pharmacyInfo,
      pharmacyInfoDraft,
      errors,
    },
    handleChanges,
    handleEditToggle,
    handleReset,
    handleSave,
  ] = useFacade(props.patientId);

  const classes = useStyles();

  if (isLoading) {
    return <WildHealthLinearProgress />;
  }

  const renderEditContent = () => {
    return <>
      <Box width="100%">
        <form autoComplete="off">
          <Box width={1} >
            <TextField
              fullWidth
              size="small"
              disabled={!handleChanges}
              id={`streetAddress`}
              variant="outlined"
              value={pharmacyInfoDraft.streetAddress}
              label="Address"
              InputProps={{ className: 'input' }}
              error={!!errors[`streetAddress`]}
              helperText={errors[`streetAddress`]}
              onChange={(v) => {
                handleChanges(v.target.id, v.target.value)
              }}
            />
          </Box>

          <Box mt={2} width={1} display="flex" justifyContent="space-between">
            <Box pr={1} width={0.5}>
              <TextField
                fullWidth
                size="small"
                disabled={!handleChanges}
                id={`name`}
                variant="outlined"
                value={pharmacyInfoDraft.name}
                label="Name"
                InputProps={{ className: 'input' }}
                error={!!errors[`name`]}
                helperText={errors[`name`]}
                onChange={(v) => {
                  handleChanges(v.target.id, v.target.value)
                }}
              />
            </Box>
            <Box pl={1} width={0.5}>
              <TextField
                fullWidth
                size="small"
                disabled={!handleChanges}
                id={`phone`}
                variant="outlined"
                label="Phone"
                value={pharmacyInfoDraft.phone}
                error={!!errors[`phone`]}
                helperText={errors[`phone`]}
                InputProps={{
                  className: 'input',
                  inputComponent: phoneNumberInput,
                }}
                onChange={(v) => {
                  handleChanges(v.target.id, v.target.value)
                }}
              />
            </Box>
          </Box>

          <Box mt={2} width={1} display="flex" justifyContent="space-between">
            <Box pr={1} width={0.5}>
              <TextField
                fullWidth
                size="small"
                disabled={!handleChanges}
                id={`city`}
                variant="outlined"
                value={pharmacyInfoDraft.city}
                label="City"
                InputProps={{ className: 'input' }}
                error={!!errors[`city`]}
                helperText={errors[`city`]}
                onChange={(v) => {
                  handleChanges(v.target.id, v.target.value)
                }}
              />
            </Box>
            <Box pl={1} width={0.5}>
              <TextField
                fullWidth
                size="small"
                disabled={!handleChanges}
                id={`state`}
                variant="outlined"
                value={pharmacyInfoDraft.state}
                label="State"
                InputProps={{ className: 'input' }}
                error={!!errors[`state`]}
                helperText={errors[`state`]}
                onChange={(v) => {
                  handleChanges(v.target.id, v.target.value)
                }}
              />
            </Box>
          </Box>

          <Box mt={2} width={1} display="flex" justifyContent="space-between">
            <Box pr={1} width={0.5}>
              <TextField
                fullWidth
                size="small"
                disabled={!handleChanges}
                id={`zipCode`}
                variant="outlined"
                value={pharmacyInfoDraft.zipCode}
                label="Zip Code"
                InputProps={{ className: 'input' }}
                error={!!errors[`zipCode`]}
                helperText={errors[`zipCode`]}
                onChange={(v) => {
                  handleChanges(v.target.id, v.target.value)
                }}
              />
            </Box>
            <Box pl={1} width={0.5}>
              <TextField
                fullWidth
                size="small"
                disabled={!handleChanges}
                id={`country`}
                variant="outlined"
                value={pharmacyInfoDraft.country}
                label="Country"
                InputProps={{ className: 'input' }}
                error={!!errors[`country`]}
                helperText={errors[`country`]}
                onChange={(v) => {
                  handleChanges(v.target.id, v.target.value)
                }}
              />
            </Box>
          </Box>
        </form>
        <Box display='flex' mt={2}>
          <Box>
            <WildHealthButton
              id="save-changes-pharmacy-info"
              width='140px'
              disabled={isSaveChanges}
              onClick={() => handleSave()}>
              {isSaveChanges ? <CircularProgress size={24} /> : 'Save changes'}
            </WildHealthButton>
          </Box>
          <Box mx={2}>

            <WildHealthButton
              id="reset-changes-pharmacy-info"
              color='secondary'
              disabled={isSaveChanges}
              width='140px'
              onClick={() => handleReset()}
            >
              Reset
            </WildHealthButton>
          </Box>
        </Box>
      </Box>
    </>
  }

  const renderContent = () => {
    return (
      <Box width='100%'>
        <Box display='flex' width='100%'>
          <Box className={classes.textGray} >Address: </Box>
          <Box className={classes.breakWord}>
            {pharmacyInfo.streetAddress ? pharmacyInfo.streetAddress : '-'}
          </Box>
        </Box>
        <Box display='flex' mt={2}>
          <Box pr={1} display='flex' width='50%'>
            <Box className={classes.textGray} >Name: </Box>
            <Box className={classes.breakWord}>
              {pharmacyInfo.name ? pharmacyInfo.name : '-'}
            </Box>
          </Box>

          <Box display='flex' width='50%'>
            <Box className={classes.textGray} >Phone:</Box>
            <Box className={classes.breakWord}>{pharmacyInfo.phone ? pharmacyInfo.phone : '-'}</Box>
          </Box>

        </Box>
        <Box display='flex' mt={2}>
          <Box pr={1} display='flex' width='50%'>
            <Box className={classes.textGray} >City: </Box>
            <Box className={classes.breakWord}>{pharmacyInfo.city ? pharmacyInfo.city : '-'}</Box>
          </Box>

          <Box display='flex' width='50%'>
            <Box className={classes.textGray} >State:</Box>
            <Box className={classes.breakWord}>{pharmacyInfo.state ? pharmacyInfo.state : '-'}</Box>
          </Box>

        </Box>
        <Box display='flex' mt={2}>
          <Box pr={1} display='flex' width='50%'>
            <Box className={classes.textGray} >Zip Code: </Box>
            <Box className={classes.breakWord}>{pharmacyInfo.zipCode ? pharmacyInfo.zipCode : '-'}</Box>
          </Box>

          <Box display='flex' width='50%'>
            <Box className={classes.textGray} >Country:</Box>
            <Box className={classes.breakWord}>{pharmacyInfo.country ? pharmacyInfo.country : '-'}</Box>
          </Box>

        </Box>
      </Box>
    );

  };

  return (
    <Box>
      <Box>
        <Box mb={3} display='flex' justifyContent="space-between">
          <Box className={classes.title}>
            Pharmacy Info
          </Box>
          <Box>
            {!edit && <Button
              id='edit-pharmacy-info'
              className={classes.button}
              onClick={() => { handleEditToggle() }}
            >
              <EditIcon fontSize='small' />
              <Box className={classes.buttonText}>Edit</Box>
            </Button>}
          </Box>

        </Box>
      </Box>

      <Box>
        {edit ? renderEditContent() : renderContent()}
      </Box>
    </Box >
  )
};

