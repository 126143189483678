import React, { useRef } from 'react';
import { Helmet } from "react-helmet";
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Box, Divider, Paper, Grid, Dialog, DialogContent, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { ReactComponent as DotIcon } from '@img/icons/Dot.svg';
import { ReactComponent as EnvelopeOpenIcon } from '@img/icons/EnvelopeOpen.svg';
import { ReactComponent as HeartbeatIcon } from '@img/icons/Heartbeat.svg';
import { ReactComponent as ArticleDraftIcon } from '@img/icons/ArticleDraft.svg';
import { ReactComponent as VideoPlayIcon } from '@img/icons/VideoPlay.svg';
import { ReactComponent as EditIcon } from '@img/icons/EditIcon.svg';
import { ReactComponent as EmailSentIcon } from '@img/icons/EmailSentIcon.svg';
import Preview from '@img/CheckoutConfirmVideoPreview.svg';
import { useFacade } from "./paymentSucceededPage.hooks";
import { useStyles } from './paymentSucceededPage.styles';
import { LayoutComponent } from "../../../../components/layoutComponent/LayoutComponent";
import { TitlesService } from '../../../../constants/title.constants';
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { DialogTitle } from '../../../common/components/dialogTitle/DialogTitle';
import { CreatedPatientModel } from "../../../patients/models/patient.model";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { RoutesConstants } from '../../../../constants/route.constants';
import { ImageConstants } from '../../../../constants/image.constants';
import { VideoConstants } from '../../../../constants/video.constants';
import commonUseStyles from "../../../common/styles/common.styles";
import { authQuery } from '../../../auth/stores/auth';
import { isFeatureFlag, anyFlags } from '../../../common/components/featureFlags/featureFlags';
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import { colors } from '../../../common/constants/colors';
import { navigationService } from '../../../../services/navigation.service';

export const PaymentSucceededPage: React.FC = (props: any) => {
    const patient = props?.location?.state?.patient ?? {};
    const { email, intakeId } = patient ?? {} as CreatedPatientModel;
    const [
        {
            isLoading,
            activeMembership,
            videoDialogOpen,
        },
        handleGoToQuestionnaire,
        handleGoToDashboard,
        handleToggleVideoDialog,
        handleLogin,
    ] = useFacade();

    const featureFlags = useFlags();
    const commonClasses = commonUseStyles();
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const videoRef = useRef<HTMLVideoElement>(null);
    const isOnboardingRedesign = isFeatureFlag(featureFlags, FeatureFlag.OnboardingRedesignV2);
    
    if (!anyFlags(featureFlags) || isLoading) {
        return <WildHealthLinearProgress />
    }

    if (!intakeId) {
        handleGoToDashboard();
        return (<> </>);
    }

    const renderCongratulationStep = () => (
        <>
            <Box display="flex" justifyContent="center" px={3}>
                <Box className={classes.congratulationSection}>
                    <Box display="flex" justifyContent="center" mt={!isSmallScreen && 7}>
                        <EmailSentIcon />
                    </Box>
                    <Box textAlign="center" my={2}>
                        <span className={clsx(commonClasses.colorNavy, commonClasses.title)}>Congratulations!</span>
                    </Box>
                    {isSmallScreen ? (
                        <Box textAlign="center" mt={1}>
                            <span className={clsx(commonClasses.colorGray900)}>We sent you a confirmation email to <span className={commonClasses.colorDarkMint}>{email}</span> Please, check your inbox or your spam folder, if necessary.</span>
                        </Box>
                    ) : (
                        <>
                            <Box textAlign="center">
                                <span className={clsx(commonClasses.size18, commonClasses.colorGray900)}>We sent you a confirmation email to</span>
                            </Box>
                            <Box textAlign="center" mt={0.5}>
                                <span className={clsx(commonClasses.size18, commonClasses.colorDarkMint)}>{email}</span>
                            </Box>
                            <Box textAlign="center" mt={3}>
                                <span className={clsx(commonClasses.size14, commonClasses.colorGray900)}>Please, check your inbox or your spam folder, if necessary.</span>
                            </Box>
                        </>
                    )}
                    <Box textAlign="center" mt={isSmallScreen ? 3 : 7}>
                        <WildHealthButton
                            size="large"
                            id="physician-pairing-quize-btn"
                            onClick={() => navigationService.toPhysicianPairingQuizForm()}
                            color="primary"
                            borderRadius={50}
                        >
                            Take Physician Pairing Quiz
                        </WildHealthButton>
                    </Box>
                    <Box textAlign="center" mt={2}>
                        <WildHealthButton
                            size="large"
                            id="login-btn"
                            onClick={() => handleLogin()}
                            color="secondary"
                            border="none"
                            borderRadius={50}
                        >
                            Login
                        </WildHealthButton>
                    </Box>
                </Box>
            </Box>
        </>
    )

    return (
        <LayoutComponent title={TitlesService.paymentSuccessTitle} redesign={isOnboardingRedesign} hideMenu={isOnboardingRedesign}>
            <Helmet>
                {/* <!-- CONVERSION TRACKING --> */}
                <script src="./ConversionTracking.js" type="text/javascript" />
                {/* <!-- /CONVERSION TRACKING --> */}
            </Helmet>
            {isOnboardingRedesign ? (
                <Paper variant="elevation" className={classes.whForm}>
                    {renderCongratulationStep()}
                    <Divider className="divider" />
                </Paper>
            ) : (
                <Paper variant="elevation" className="wh-form">
                    <Box textAlign="center">
                        <Box className={classes.headContent}>
                            <span className={classes.headerText}>What’s next?</span>
                        </Box>
                    </Box>
                    <Grid container className={classes.root}>
                        <Grid item xs={12} md={6}>
                            <Box className={classes.container}>
                                <Box className={classes.videoTextContent}>
                                    <span className={classes.watchVideoText}>Watch this video to get an overview of Clarity</span>
                                </Box>

                                <Box className={classes.guideVideo} onClick={() => handleToggleVideoDialog(true)}>
                                    <Box className={classes.videoPlayIcon}>
                                        <VideoPlayIcon className={classes.videoIcon} />
                                    </Box>
                                    <img src={RoutesConstants.cdnBaseLink + ImageConstants.videoPreviewImagePath} className={classes.image} alt='overview video' />
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Box className={classes.container}>
                                <Box display="flex" className={classes.rightContent}>
                                    <Box mt={3}>
                                        <Box className={classes.iconBox}>
                                            <EnvelopeOpenIcon />
                                        </Box>
                                        <Divider orientation="vertical" className={classes.iconBoxDivider} />
                                        <Box className={classes.iconBox}>
                                            <HeartbeatIcon />
                                        </Box>
                                        <Divider orientation="vertical" className={classes.iconBoxDivider} />
                                        <Box className={classes.iconBox}>
                                            <ArticleDraftIcon fill={colors.lightBlue2} />
                                        </Box>
                                        {!authQuery.isLicensingPractice() && (
                                            <>
                                                <Divider orientation="vertical" className={classes.iconBoxDivider} />
                                                <Box className={classes.iconBox}>
                                                    <EditIcon />
                                                </Box>
                                            </>
                                        )}
                                    </Box>
                                    {!authQuery.isLicensingPractice() ? (
                                        <Box>
                                            <Box>
                                                <span className={clsx(isSmallScreen ? commonClasses.size16 : commonClasses.size18, commonClasses.textBold, commonClasses.colorLinkBlack)}>Schedule your welcome call</span>
                                            </Box>
                                            <Box mt={1}>
                                                <span className={classes.listText}>Your care coordinator will reach out shortly to schedule a call to give you an overview of the whole program and answer any questions.</span>
                                            </Box>
                                            <Box className={classes.listItem3}>
                                                <span className={clsx(isSmallScreen ? commonClasses.size16 : commonClasses.size18, commonClasses.textBold, commonClasses.colorLinkBlack)}>In the meantime, start completing your health forms</span>
                                            </Box>
                                            <Box mt={1}>
                                                <span className={classes.listText}>This will assist our algorithm in creating the perfect diet, exercise, supplements, and lifestyle plan to optimize your health.</span>
                                            </Box>
                                            <Box mt={1}>
                                                <WildHealthButton
                                                    id='payment-succeeded-to-questionnaire'
                                                    style={{
                                                        borderWidth: 2,
                                                        borderRadius: '8px',
                                                    }}
                                                    color="secondary"
                                                    size="large"
                                                    onClick={() => handleGoToQuestionnaire()}
                                                >
                                                    Fill Out Health Forms
                                                </WildHealthButton>
                                            </Box>
                                            <Box className={classes.listItem3}>
                                                <span className={clsx(isSmallScreen ? commonClasses.size16 : commonClasses.size18, commonClasses.textBold, commonClasses.colorLinkBlack)}>Keep an eye out for your DNA kit to arrive in the mail</span>
                                            </Box>
                                            <Box mt={1}>
                                                <span className={classes.listText}>Expect to receive this in the next 7-10 days. It will arrive with all instructions and a prepaid UPS return label affixed to the back of the kit box.</span>
                                            </Box>
                                            <Box className={classes.listItem3}>
                                                <span className={clsx(isSmallScreen ? commonClasses.size16 : commonClasses.size18, commonClasses.textBold, commonClasses.colorLinkBlack)}>If applicable, complete your add-on services</span>
                                            </Box>
                                            <Box mt={1}>
                                                <span className={classes.listText}>We'll follow up with your lab paperwork and ship your Epigenetic age kit if you selected these add-ons.</span>
                                            </Box>
                                        </Box>
                                    ) : (
                                        <Box>
                                            <Box>
                                                <span className={classes.listText}>We sent you a confirmation email to</span>
                                            </Box>
                                            <Box mt={1}>
                                                <span className={classes.emailText}>{email}.</span>
                                            </Box>
                                            <Box mt={1}>
                                                <span className={classes.listText}>Please, check your inbox or your spam folder, if necessary.</span>
                                            </Box>
                                            <Box className={classes.listItem1}>
                                                <span className={classes.listText}>The next step in your journey is to complete your health forms so we can better understand your goals and current lifestyle.</span>
                                            </Box>
                                            <Box mt={1}>
                                                <WildHealthButton
                                                    id='payment-succeeded-to-questionnaire'
                                                    style={{
                                                        borderWidth: 2,
                                                        borderRadius: '8px',
                                                    }}
                                                    color="secondary"
                                                    size="large"
                                                    onClick={() => handleGoToQuestionnaire()}
                                                >
                                                    Fill Out Health Forms
                                            </WildHealthButton>
                                            </Box>
                                            <Box className={classes.listItem2}>
                                                <Box display="flex" alignItems="center">
                                                    <Box className={classes.dotIcon}>
                                                        <DotIcon />
                                                    </Box>
                                                    <span className={classes.listText}>After completing your health forms, please complete the health log in your first week.</span>
                                                </Box>
                                                <Box display="flex" alignItems="center" mt={1}>
                                                    <Box className={classes.dotIcon}>
                                                        <DotIcon />
                                                    </Box>
                                                    <span className={classes.listText}>Once the health log is completed, you’ll be able to schedule your appointment with our health coach.</span>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Divider className="divider" />
                </Paper>
            )}
            <Dialog
                fullWidth
                maxWidth='md'
                open={videoDialogOpen}
                onClose={() => handleToggleVideoDialog(false)}
            >
                <DialogTitle
                    id="customized-dialog-title"
                    classes={{ root: classes.dialogTitle }}
                    onClose={() => handleToggleVideoDialog(false)}
                >
                    <span className={clsx(commonClasses.size18, commonClasses.textMedium)}>Preview</span>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Box className={classes.previewVideo}>
                        <video ref={videoRef} className={classes.videoTag} src={RoutesConstants.cdnBaseLink + VideoConstants.checkoutVideoPath} poster={Preview} controls autoPlay />
                    </Box>
                </DialogContent>
            </Dialog>
        </LayoutComponent>
    )
}
