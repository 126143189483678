import { Store, StoreConfig } from '@datorama/akita';
import { EmployeeModel, EmployeeShortModel, FellowModel, FellowShortModel } from "../../models/employee.models";
import { PermissionModel } from '../../models/permission.models';
import { RoleModel } from '../../models/role.models';
import { PreauthorizeRequestModel } from '../../../payment/models/preauthorizeRequest.model';

/**
 * Represents employees state
 */
export interface EmployeesState {
    employees: EmployeeShortModel[];
    couchesAndProviders: EmployeeShortModel[];
    staff: EmployeeShortModel[];
    fellowsPublicInfo: FellowShortModel[];
    fellows: FellowModel[];
    careCoordinators: EmployeeShortModel[];
    permissions: PermissionModel[];
    roles: RoleModel[];
    employee: EmployeeModel;
    patientEmployees: EmployeeModel[];
    selectedFellow: FellowShortModel;
    linkedFellow: FellowShortModel;
    preauthorizeRequests: PreauthorizeRequestModel[];
}

/**
 * Creates initial state
 */
export function createInitialState(): EmployeesState {
    return {
        employees: [],
        couchesAndProviders: [],
        staff: [],
        fellowsPublicInfo: [],
        fellows: [],
        careCoordinators: [],
        permissions: [],
        roles: [],
        employee: null,
        patientEmployees: [],
        selectedFellow: null,
        linkedFellow: null,
        preauthorizeRequests: []
    };
}

/**
 * Provides employees states management
 */
@StoreConfig({ name: 'employees', resettable: true })
export class EmployeesStore extends Store<EmployeesState> {
    constructor() {
        super(createInitialState());
    }

    public addEmployee(model: EmployeeShortModel) {
        this.update({ employees: [...this.getValue().employees, model] });
    }

    public updateEmployee(employee: EmployeeShortModel) {
        const employees = this.getValue().employees;
        this.update({ employees: employees.map(x => x.id === employee.id ? employee : x) });
    }

    public removeEmployee(employeeId: number) {
        this.update({ employees: this.getValue().employees.filter(i => i.id !== employeeId) });
    }

    public addFellow(model: FellowModel) {
        this.update({ fellows: [...this.getValue().fellows, model] });
    }

    public updateFellow(fellow: FellowModel) {
        const fellows = this.getValue().fellows;
        this.update({ fellows: fellows.map(x => x.id === fellow.id ? fellow : x) });
    }

    public removeFellow(fellowId: number) {
        this.update({ fellows: this.getValue().fellows.filter(i => i.id !== fellowId) });
    }

    public addRequest(request: PreauthorizeRequestModel) {
        this.update({ preauthorizeRequests: [...this.getValue().preauthorizeRequests, request] });
    }

    public addRequests(requests: PreauthorizeRequestModel[]) {
        this.update({ preauthorizeRequests: [...this.getValue().preauthorizeRequests, ...requests] });
    }

    public updateRequest(request: PreauthorizeRequestModel) {
        const preauthorizeRequests = this.getValue().preauthorizeRequests;
        this.update({ preauthorizeRequests: preauthorizeRequests.map(x => x.id === request.id ? request : x) });
    }

    public removeRequest(requestId: number) {
        this.update({ preauthorizeRequests: this.getValue().preauthorizeRequests.filter(i => i.id !== requestId) });
    }
}

export const employeesStore = new EmployeesStore();
