import { Box, useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { HealthReportMode, HealthReportSections } from "../models/healthReport.models";
import { HealthReportBasePage, HealthReportMobileBasePage } from "./HealthReportBasePage";
import { HealthReportPageProps } from "./healthReportPageProps";
import commonUseStyles from "../../common/styles/common.styles";
import clsx from "clsx";
import { RoutesConstants } from '../../../constants/route.constants';
import { ImageConstants } from '../../../constants/image.constants';
import img from "@img/HealthReport_Pg14_Image.png";

export const HealthReportNeurobehavioral0Page: React.FC<HealthReportPageProps> = (props: HealthReportPageProps) => {
    const { pageNumber, practice } = props;
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        props.mode !== HealthReportMode.PrintMode && isSmallScreen ? (
        <HealthReportMobileBasePage section={HealthReportSections.Neurobehavioral} pageNumber={pageNumber} practice={practice} firstPage>
            <Box>
                <Box px={2}>
                    <Box>
                        <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeBigReport, commonClasses.textRegularReport)}>Personalized Mindfulness Plan</span>
                    </Box>
                    <Box mt={1.5} fontSize="14px" lineHeight="22px">
                        <Box>
                            <p>
                                Stress and anxiety are real and have a physical effect on the body and mind. Mindfulness
                                can help stabilize cortisol and helps the overall balance of hormones in our body.
                                Mindfulness has even been shown to lower blood pressure by 10-15mm Hg.
                            </p>
                        </Box>
                        <Box mt="10px">
                            <p>
                                Mindfulness practices are one tool in your toolbox to calm your body intentionally.
                            </p>
                        </Box>
                        <Box mt="10px">
                            <p>Aim for 10-15 minutes per day to calm the mind.</p>
                        </Box>
                        <Box mt="10px">
                            <p>Here are some tools to consider:</p>
                        </Box>
                        <Box mt="10px">
                            <p>
                                Simple “Gratefulness Journals” have been shown to improve mood, joy and decrease stress.
                                Keeping this in your car between appointments might be beneficial. <a id="report-link-amazon" target="_black" href="https://www.amazon.com/Gratitude-Journal-Affirmation-Productivity-Mindfulness/dp/B09NPVVKWR/ref=asc_df_B09NPVVKWR/?tag=hyprod-20&linkCode=df0&hvadid=563725415476&hvpos=&hvnetw=g&hvrand=11022365268517101909&hvpone=&hvptwo=&hvqmt=&hvdev=c&hvdvcmdl=&hvlocint=&hvlocphy=9014878&hvtargid=pla-1595539873819&psc=1&gclid=Cj0KCQiA6LyfBhC3ARIsAG4gkF_bCZuIcp9vMs5KSiZqEog0uJHaxgC3sosD4Av6aiKFN1uJsGuHXXkaAvPwEALw_wcB">
                                    Click here
                                </a> to see a simple option.
                            </p>
                        </Box>
                        <Box mt="10px">
                            <p>Meditation apps like “Head Space” or “Calm” can guide you as well.</p>
                        </Box>
                        <Box mt="10px">
                            <p>Music can have an incredible effect on your mood. Use it intentionally.</p>
                        </Box>
                    </Box>
                    <Box mt="30px">
                        <img src={RoutesConstants.cdnBaseLink + ImageConstants.healthReportPg14ImagePath} style={{ width: '100%', height: 'auto' }} alt="img" />
                    </Box>
                </Box>
            </Box>
        </HealthReportMobileBasePage>
        ) : (
            <HealthReportBasePage section={HealthReportSections.Neurobehavioral} pageNumber={pageNumber} practice={practice}>
                <Box>
                    <Box px={15} pt={5}>
                        <Box>
                            <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeBigReport, commonClasses.textMediumReport)}>Personalized Mindfulness Plan</span>
                        </Box>
                        <Box mt={2}>
                            <Box>
                                <p>
                                    Stress and anxiety are real and have a physical effect on the body and mind. Mindfulness
                                    can help stabilize cortisol and helps the overall balance of hormones in our body.
                                    Mindfulness has even been shown to lower blood pressure by 10-15mm Hg.
                                </p>
                            </Box>
                            <Box mt={1.5}>
                                <p>
                                    Mindfulness practices are one tool in your toolbox to calm your body intentionally.
                                </p>
                            </Box>
                            <Box mt={1.5}>
                                <p>Aim for 10-15 minutes per day to calm the mind.</p>
                            </Box>
                            <Box mt={1.5}>
                                <p>Here are some tools to consider:</p>
                            </Box>
                            <Box mt={1.5}>
                                <p>
                                    Simple “Gratefulness Journals” have been shown to improve mood, joy and decrease stress.
                                    Keeping this in your car between appointments might be beneficial. <a id="report-link-amazon" target="_black" href="https://www.amazon.com/Gratitude-Journal-Affirmation-Productivity-Mindfulness/dp/B09NPVVKWR/ref=asc_df_B09NPVVKWR/?tag=hyprod-20&linkCode=df0&hvadid=563725415476&hvpos=&hvnetw=g&hvrand=11022365268517101909&hvpone=&hvptwo=&hvqmt=&hvdev=c&hvdvcmdl=&hvlocint=&hvlocphy=9014878&hvtargid=pla-1595539873819&psc=1&gclid=Cj0KCQiA6LyfBhC3ARIsAG4gkF_bCZuIcp9vMs5KSiZqEog0uJHaxgC3sosD4Av6aiKFN1uJsGuHXXkaAvPwEALw_wcB">
                                        Click here
                                    </a> to see a simple option.
                                </p>
                            </Box>
                            <Box mt={1.5}>
                                <p>Meditation apps like “Head Space” or “Calm” can guide you as well.</p>
                            </Box>
                            <Box mt={1.5}>
                                <p>Music can have an incredible effect on your mood. Use it intentionally.</p>
                            </Box>
                        </Box>
                        <Box mt={10}>
                            {
                                props.mode === HealthReportMode.PrintMode
                                    ? <img src={img} style={{ width: '100%' }} alt="img" />
                                    : <img src={RoutesConstants.cdnBaseLink + ImageConstants.healthReportPg14ImagePath} style={{ width: '100%' }} alt="img" />
                            }
                        </Box>
                    </Box>
                </Box>
            </HealthReportBasePage>
        )
    )
}
