import { useEffect, useState } from "react";
import { DataSpecificationsEnum } from "../../../common/constants/data-specifications";
import { patientsService } from "../../../patients/services/patients.service";

interface PatientProfilePageState {
    isLoading: boolean;
}

export function useFacade(): [PatientProfilePageState] {
    const [state, setState] = useState({
        isLoading: true,
    } as PatientProfilePageState);

    const useEffectCB = () => {
        const cb = () => setState(state => ({ ...state, isLoading: false }));
        
        patientsService.getMyInfo(DataSpecificationsEnum.UpdatePatientSpecification).subscribe(cb, cb);
    }

    useEffect(useEffectCB, []);

    return [state];
}