import React from 'react';
import { Box, TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { DialogComponentProps } from "../../../../common/dialogs/dialogProps";
import { useFacade } from "./createShortcutGroupComponent.hooks";
import { WildHealthButton } from "../../../../common/components/wildHealthButton/WildHealthButton";

export const CreateShortcutGroupComponent: React.FC<DialogComponentProps> = (props: DialogComponentProps) => {
    const [
        {
            group,
            errors,
            isSubmitted
        },
        handleNameChanges,
        handleSubmit,
        handleClose
    ] = useFacade(props);

    return (
        <>
            <Dialog maxWidth="md" open={props.open} onClose={() => handleClose()} aria-labelledby="form-dialog-title">
                <form onSubmit={(e) => { handleSubmit(e) }}>
                    <DialogTitle id="form-dialog-title">Create shortcut Group</DialogTitle>
                    <DialogContent>
                        <Box style={{ width: '500px' }}>
                            <Box width={1} mt={2}>
                                <TextField
                                    id="name"
                                    size="small"
                                    label="Name"
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{ className: 'input' }}
                                    error={!!errors['name']}
                                    helperText={errors['name']}
                                    value={group?.name ?? ''}
                                    disabled={isSubmitted}
                                    onChange={(v) => {
                                        handleNameChanges(v.target.value)
                                    }}
                                />
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions className="wh-tw-p-6">
                        <WildHealthButton
                            id="create-shortcut-group-cancel"
                            color='secondary'
                            size="large"
                            borderRadius={50}
                            disabled={isSubmitted}
                            onClick={() => handleClose()}
                        >
                            Cancel
                        </WildHealthButton>
                        <WildHealthButton
                            id="create-shortcut-group-create"
                            size="large"
                            borderRadius={50}
                            loading={isSubmitted}
                            disabled={!group?.name}
                            onClick={(e) => { handleSubmit(e) }}
                        >
                            Create
                        </WildHealthButton>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}