import {useEffect, useState} from "react";
import {useHistory} from "react-router";
import {Subscription} from "rxjs";
import {onEmit} from "../../../common/helpers/on-emit";
import {SubscriptionModel, SubscriptionStatus} from '../../../payment/models/subscription.models';
import {subscriptionService} from "../../../payment/services/subscription.service";
import {subscriptionQuery} from "../../../payment/stores/subscriptionStore";
import {navigationService} from "../../../../services/navigation.service";
import { paymentService } from "../../../payment/services/payment.service";
import { authQuery } from "../../../auth/stores/auth/auth.query";
import { IntegrationVendor } from "../../../payment/models/payment.models";
import { PaymentPlanHistoryModel, CallToActionModel, ActionType } from "../../../patients/models/patient.model";
import { patientsService } from "../../../patients/services/patients.service";
import { patientProfileQuery } from "../../../employee/stores/patientProfileStore";
import { patientsQuery } from "../../../patients/stores/patientsStore";

interface PatientMembershipPageState {
    isLoading: boolean;
    isHistoryLoading: boolean;
    isCallToActionsLoading: boolean;
    isStripeIntegration: boolean;
    activeMembership: SubscriptionModel;
    paymentPlanHistories: PaymentPlanHistoryModel[];
    shouldDisplayBillingInfo: boolean;
    callToActions: CallToActionModel[];
}

export function useFacade(): [PatientMembershipPageState, Function, Function, Function] {
    const history = useHistory();
    const [state, setState] = useState({
        isLoading: true,
        isHistoryLoading: true,
        isCallToActionsLoading: true,
        activeMembership: null,
        isStripeIntegration: false,
        paymentPlanHistories: [],
        callToActions: []
    } as PatientMembershipPageState);

    const handleGoToBuySubscription = () => {
        navigationService.toBuyNewSubscription(history);
    }

    const handleGoEditBillingInfo = () => {
        if (!state.isStripeIntegration) {
            return;
        }
        paymentService.getPatientSubscriptionsLink().subscribe((res) => {
            window.open(res, '_blank').focus();
        });
    }

    const handleGoCancellationForm = () => {
        navigationService.toCancellationForm();
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<SubscriptionModel[]>(subscriptionQuery.mySubscriptions$, subscriptions => {
                const active = subscriptions.find(s => s.status === SubscriptionStatus.Active || s.status === SubscriptionStatus.Paused);
                setState(state => ({
                    ...state,
                    activeMembership: active,
                    isStripeIntegration: active?.integrationVendor == IntegrationVendor.Stripe
                }));
            }),
            onEmit<PaymentPlanHistoryModel[]>(patientProfileQuery.paymentPlanHistories$, paymentPlanHistories => {
                setState(state => ({
                    ...state,
                    paymentPlanHistories: paymentPlanHistories,
                }));
            }),
            onEmit<CallToActionModel[]>(patientsQuery.callToActions$, callToActions => {
                setState(state => ({
                    ...state,
                    callToActions: callToActions.filter(x => x.type === ActionType.StandardPlanUpSell),
                }));
            }),
        ];

        subscriptionService.getMy().subscribe(() => {
            setState(state => ({
                ...state,
                isLoading: false,
            }));
        });
        const cb = () => setState(state => ({...state, isHistoryLoading: false}));
        const callToActionCB = () => setState(state => ({...state, isCallToActionsLoading: false}));

        patientsService.getPaymentPlanUpdateHistory(authQuery.getPatientId()).subscribe(cb, cb);
        patientsService.getActiveCallToActions().subscribe(callToActionCB, callToActionCB);

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, []);

    return [state, handleGoEditBillingInfo, handleGoToBuySubscription, handleGoCancellationForm];
}