import React from "react";
import {EmployeeShortModel} from "../../../employee/models/employee.models";
import {
    Avatar,
    Box,
    Grid, InputLabel,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText, Radio
} from "@material-ui/core";
import commonUseStyles from "../../../common/styles/common.styles";
import {useStyles} from "./SelectProviderComponent.styles";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import {ProviderInfoComponent} from "../providerInfo/ProviderInfoComponent";
import {getPhotoSrc} from "../../../common/helpers/getPhotoSrc";

interface SelectProviderComponentProps {
    selectedProvider: EmployeeShortModel,
    recommendedProvider: EmployeeShortModel,
    providers: EmployeeShortModel[],
    onProviderSelect: (employeeId: any) => void,
    isLoading: boolean
    error: string;
}

export const SelectProviderComponent: React.FC<SelectProviderComponentProps> = (props: SelectProviderComponentProps) => {
    const {
        selectedProvider,
        recommendedProvider,
        providers,
        onProviderSelect,
        isLoading,
        error
    } = props;

    const classes = useStyles();
    const commonClasses = commonUseStyles();

    const renderContent = () => {
        if (isLoading) {
            return (
                <Box py={2}>
                    <WildHealthLinearProgress/>
                </Box>
            )
        }

        return (
            <Grid container spacing={2}>
                <Grid item md={5} sm={6} xs={12}>
                    {
                        recommendedProvider &&
                        <List>
                            <ListItem button className={classes.selectedProvider}>
                                <Box textAlign="center" className={classes.selectedProviderTitle}>
                                    RECOMMENDED PROVIDER
                                </Box>
                                <Box display="flex" mt={3} justifyContent="center">
                                    <ListItemAvatar className={classes.selectedProviderAvatar}>
                                        <Avatar src={getPhotoSrc(recommendedProvider.photoId)} className={classes.selectedProviderAvatar}>{recommendedProvider.firstName[0]}</Avatar>
                                    </ListItemAvatar>
                                </Box>
                                <Box display="flex" mt={1} justifyContent="center">
                                    <ListItemText primary={
                                        <Box textAlign="center">
                                            {recommendedProvider.firstName + ' ' + recommendedProvider.lastName}
                                        </Box>
                                    } secondary={
                                        <Box textAlign="center" display="flex" justifyContent="center">
                                            <Box>
                                                <ProviderInfoComponent provider={recommendedProvider} />
                                            </Box>
                                        </Box>
                                    } />
                                </Box>
                                <Box display="flex" mt={2} justifyContent="center">
                                    <Radio onClick={() => onProviderSelect(recommendedProvider.id)} className={classes.radio} checked={recommendedProvider?.id === selectedProvider.id} />
                                </Box>
                            </ListItem>
                        </List>
                    }
                </Grid>
                <Grid item md={7} sm={6} xs={12}>
                    <Box>
                        <List>
                            {
                                providers.map((employee, index) => {
                                    return (
                                        <ListItem onClick={() => onProviderSelect(employee.id)} className={classes.providerListItem} key={index} button>
                                            <ListItemAvatar>
                                                <Avatar src={getPhotoSrc(employee.photoId)} sizes="small">{employee.firstName[0]}</Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary={employee.firstName + ' ' + employee.lastName} secondary={
                                                <Box>
                                                    <ProviderInfoComponent provider={employee} />
                                                </Box>
                                            } />
                                            <ListItemSecondaryAction>
                                                <Radio onClick={() => onProviderSelect(employee.id)} className={classes.radio} checked={selectedProvider?.id === employee.id} />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )
                                })
                            }
                        </List>
                    </Box>
                </Grid>
            </Grid>
        )
    }

    return (
        <Box>
            <Box>
               <span className={commonClasses.colorGray1}>
                   Providers that match selected timeslot.
               </span>
            </Box>
            {
                error  &&
                <Box>
                    <InputLabel error>
                        {error}
                    </InputLabel>
                </Box>
            }
            <Box mt={2}>
                {
                    renderContent()
                }
            </Box>
        </Box>
    );
}

