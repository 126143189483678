import React from 'react';
import clsx from 'clsx';
import {
  Box, 
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  IconButton,
  Popover,
  ListItemIcon,
  Typography
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useFacade } from './manageBannersComponent.hooks';
import { useStyles, SearchTextField } from "./manageBannersComponent.styles";
import { CreateBannerComponent } from '../createBannerComponent/CreateBannerComponent';
import { UpdateBannerComponent } from '../updateBannerComponent/UpdateBannerComponent';
import commonUseStyles from '../../../common/styles/common.styles';
import { WildHealthContainer } from '../../../common/components/wildHealthContainer/WildHealthContainer';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { FeatureComponent } from "../../../common/components/featureFlags/FeatureComponent";
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { WildHealthMenuItem } from '../../../common/components/wildHealthMenu/WildHealthMenu';
import { WildHealthStatusLabel } from "../../../common/components/wildHealthStatusLabel/WildHealthStatusLabel";
import { pageSizes } from "../../../common/pagination/models/page-sizes";
import { PaginationComponent } from "../../../common/pagination/components/paginationComponent/PaginationComponent";
import { PermissionType } from '../../../auth/models/auth.enums';
import { ProtectedElement } from '../../../common/components/ProtectedElement';
import { bannerTypesNames } from '../../../../models/banner.model';

export const ManageBannersComponent: React.FC = () => {
  const [
    {
      isLoading,
      banners,
      selectedBanner,
      searchKey,
      isEditOpen,
      menuAnchorEl,
      sortingColumns,
      sortingSource,
      totalCount,
      pageSize,
      selectedPage,
    },
    handleOnFilter,
    getAllAvailablePages,
    handlePageSizeChange,
    handlePageChange,
    handleSorting,
    handleToggleBannerActions,
    handleToggleEditBanner,
    handleDeleteBanner
  ] = useFacade();

  const classes = useStyles();
  const commonClasses = commonUseStyles();

  const renderTable = () => {
    if (isLoading) {
      return <WildHealthLinearProgress />
    }

    if (!isLoading && (!banners || !banners.length)) {
      return <WildHealthPlaceHolder message="No Banners found" />
    }

    return (
      <>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {
                  sortingColumns.map((item, index) =>
                    <WildHealthTableCell key={index}>
                      {item.source
                        ? <TableSortLabel
                            id={`sorting-banners-${item.title}`}
                            active={sortingSource === item.source}
                            direction={sortingSource === item.source ? item.direction : 'asc'}
                            onClick={() => handleSorting(item.source, item.direction)}
                        >
                            {item.title}
                        </TableSortLabel>
                        : item.title
                      }
                    </WildHealthTableCell>
                  )
                }
                <WildHealthTableCell style={{ width: '5%' }}>Actions</WildHealthTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {banners.slice((selectedPage - 1) * pageSize, (selectedPage - 1) * pageSize + pageSize).map((banner, index) => (
                <WildHealthTableRow key={index}>
                  <WildHealthTableCell width="15%" align="left">
                    <Box className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorBlack)}>
                      {banner.name}
                    </Box>
                  </WildHealthTableCell>
                  <WildHealthTableCell width="35%" align="left">
                    <Box className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorBlack)}>
                      {banner.copy}
                    </Box>
                  </WildHealthTableCell>
                  <WildHealthTableCell align="left">
                    <Box className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorBlack)}>{bannerTypesNames.get(banner.type)}</Box>
                  </WildHealthTableCell>
                  <WildHealthTableCell align="left">
                    <Box className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorBlack)}>
                      {banner.priority}
                    </Box>
                  </WildHealthTableCell>
                  <WildHealthTableCell align="left">
                    <WildHealthStatusLabel 
                      color={banner.isActive ? "good" : "bad"}
                      minWidth={78}
                    >
                      {banner.isActive ? "Active" : "Inactive"}
                    </WildHealthStatusLabel>
                  </WildHealthTableCell>
                  <WildHealthTableCell width="5%" align="right">
                    <IconButton
                      id={`edit-banner-${banner.id}`}
                      className={classes.menuIcon}
                      onClick={(e) => handleToggleBannerActions(banner.id, e.currentTarget)}
                    >
                      <MoreHorizIcon />
                    </IconButton>
                  </WildHealthTableCell>
                </WildHealthTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box py={1} px={2}>
          <PaginationComponent
            pageSizes={pageSizes}
            pageSize={pageSize}
            selectedPage={selectedPage}
            handlePageSizeChange={handlePageSizeChange}
            totalCount={totalCount}
            availablePages={getAllAvailablePages()}
            handlePageSelect={handlePageChange}
          />
        </Box>
      </>
    )
  }

  return (
    <Box py={2} px={10} width={1}>
      <Box my={2} display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <FeatureComponent featureFlag={FeatureFlag.Navigation}>
            <Box className={clsx(commonClasses.size24, commonClasses.textSemiBold, commonClasses.colorBlack)}>{PagesNamesService.banners}</Box>
          </FeatureComponent>
        </Box>
      </Box>
      <CreateBannerComponent />
      <Box mt={5} mb={2} display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Box className={clsx(commonClasses.size20, commonClasses.textMedium, commonClasses.colorBlack)}>All banners</Box>
        </Box>
        <Box width="310px">
          <SearchTextField
            fullWidth
            variant="outlined"
            className={classes.search}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnFilter(e.target.value)}
            placeholder='Search by Name or Description'
            InputProps={{
              inputProps: {
                className: classes.searchBase,
              },
              startAdornment: <SearchIcon className={classes.icon} />,
            }}
            value={searchKey}
          />
        </Box>
      </Box>

      <WildHealthContainer margin={0} minHeight={'100%'}>
        {renderTable()}
      </WildHealthContainer>
      
      <UpdateBannerComponent
        isOpen={isEditOpen}
        selectedBanner={selectedBanner}
        handleToggleDialog={handleToggleEditBanner}
      />
      <ProtectedElement
        element={
          <Popover
            id="bannerActionPopover"
            anchorEl={menuAnchorEl}
            open={Boolean(menuAnchorEl)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            onClose={() => handleToggleBannerActions(0, null)}
          >
            <WildHealthMenuItem
              id="banner-edit"
              onClick={() => handleToggleEditBanner(true)}
            >
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <Typography variant="inherit" noWrap>
                Edit
              </Typography>
            </WildHealthMenuItem>
            <WildHealthMenuItem
              id="banner-delete"
              onClick={() => handleDeleteBanner()}
            >
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <Typography variant="inherit" noWrap>
                Delete
              </Typography>
            </WildHealthMenuItem>
          </Popover>
        }
        permissions={[PermissionType.ManageEmployees]}
      />
    </Box>
  );
}