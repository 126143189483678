import React from 'react';
import { Box, CircularProgress, Grid, TextField } from '@material-ui/core';
import { AddressModel } from "../../models/address.models";
import commonUseStyles from '../../../common/styles/common.styles';
import { addressesKeys, useFacade } from './updateAddressForm.hooks';
import { Autocomplete } from '@material-ui/lab';
import { colors } from '../../constants/colors';

interface UpdateAddressFormProps {
    address: AddressModel,
    prefix: string;
    errors: { [id: string]: string; };
    handleChanges: Function,
    disabled?: boolean,
    classStyle?: any;
}

export const UpdateAddressForm: React.FC<UpdateAddressFormProps> = (props: UpdateAddressFormProps) => {
    const {
        address,
        prefix,
        errors,
        handleChanges,
        classStyle = "",
    } = props;

    const [
        state,
        selectAddress,
        handleAddressChange,
    ] = useFacade(prefix, address.streetAddress1, handleChanges);

    const renderInput = (isLoading: boolean, params: any, error: boolean, onChange: (value: string) => void, helperText?: string, placeholder?: string) => {
        return (
            <TextField
                {...params}
                variant="outlined"
                error={error}
                placeholder={placeholder}
                onChange={(e) => onChange(e.currentTarget.value)}
                helperText={helperText}
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <React.Fragment>
                            {!isLoading ? <CircularProgress style={{ color: colors.main }} size={20} /> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                }}
            />
        )
    }

    const commonClasses = commonUseStyles();

    if (!handleChanges) {
        return (
            <Box height="235px">
                <Box width={1} display="flex">
                    <Box display="inline" width={'100px'} className={classStyle}>Address 1: </Box>
                    <Box display="inline">{address.streetAddress1}</Box>
                </Box>
                <Box mt={2} width={1} display="flex">
                    <Box display="inline" width={'100px'} className={classStyle}>Address 2: </Box>
                    <Box display="inline">{address.streetAddress2}</Box>
                </Box>
                <Box mt={2} display="flex" justifyContent="space-between">
                    <Box pr={1} width={0.5} display="flex">
                        <Box display="inline" width={'100px'} className={classStyle}>City: </Box>
                        <Box display="inline">{address.city}</Box>
                    </Box>
                    <Box pl={1} width={0.5} display="flex">
                        <Box display="inline" width={'100px'} className={classStyle}>State: </Box>
                        <Box display="inline">{address.state}</Box>
                    </Box>
                </Box>
                <Box mt={2} display="flex" justifyContent="space-between">

                    <Box pr={1} width={0.5} display="flex">
                        <Box display="inline" width={'100px'} className={classStyle}>Zip Code: </Box>
                        <Box display="inline">{address.zipCode}</Box>

                    </Box>
                    <Box pl={1} width={0.5} display="flex">
                        <Box display="inline" width={'100px'} className={classStyle}>Country: </Box>
                        <Box display="inline">{address.country}</Box>

                    </Box>
                </Box>
            </Box>
        )
    }

    return (
        <Box style={{ minHeight: "235px" }}>
            <form autoComplete="off" style={{ width: '100%' }}>
                <Box width={1}>
                    <Box className={commonClasses.subtitle}>Address 1 (Optional)</Box>

                    <Autocomplete
                        size='small'
                        disableClearable
                        value={address.streetAddress1}
                        options={state.suggestions.data.map(i => (`${i.structured_formatting?.main_text}, ${i.structured_formatting?.secondary_text}`))}
                        onChange={(e, value) => selectAddress(value)}
                        renderInput={(params) => renderInput(
                            state.ready,
                            params,
                            !!errors[`${prefix}${addressesKeys.streetAddress1}`],
                            handleAddressChange,
                            errors[`${prefix}${addressesKeys.streetAddress1}`],
                            'Address'
                        )}
                    />
                </Box>
                <Box mt={2} width={1}>
                    <Box className={commonClasses.subtitle}>Address 2 (Optional)</Box>

                    <TextField
                        fullWidth
                        size='small'
                        value={address.streetAddress2}
                        variant="outlined"
                        placeholder='Apartment, suite, etc.'
                        error={!!errors[`${prefix}${addressesKeys.streetAddress2}`]}
                        onChange={(e) => handleChanges(`${prefix}${addressesKeys.streetAddress2}`, e.currentTarget.value)}
                        helperText={errors[`${prefix}${addressesKeys.streetAddress2}`]}
                    />
                </Box>
                <Box mt={2}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={6}>
                            <Box className={commonClasses.subtitle}>City</Box>
                            <TextField
                                fullWidth
                                required
                                size="small"
                                id={`${prefix}${addressesKeys.city}`}
                                placeholder="City"
                                variant="outlined"
                                InputProps={{ className: 'input' }}
                                error={!!errors[`${prefix}city`]}
                                helperText={errors[`${prefix}city`]}
                                value={address.city ?? ""}
                                onChange={(v) => {
                                    handleChanges(v.target.id, v.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <Box className={commonClasses.subtitle}>State</Box>
                            <TextField
                                fullWidth
                                required
                                size="small"
                                id={`${prefix}${addressesKeys.state}`}
                                placeholder="State"
                                variant="outlined"
                                InputProps={{ className: 'input' }}
                                error={!!errors[`${prefix}${addressesKeys.state}`]}
                                helperText={errors[`${prefix}${addressesKeys.state}`]}
                                value={address.state ?? ""}
                                onChange={(v) => {
                                    handleChanges(v.target.id, v.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <Box className={commonClasses.subtitle}>Zip Code</Box>
                            <TextField
                                fullWidth
                                required
                                size="small"
                                id={`${prefix}${addressesKeys.zipCode}`}
                                placeholder="Postal/Zip Code"
                                variant="outlined"
                                InputProps={{ className: 'input' }}
                                error={!!errors[`${prefix}${addressesKeys.zipCode}`]}
                                helperText={errors[`${prefix}${addressesKeys.zipCode}`]}
                                value={address.zipCode ?? ""}
                                onChange={(v) => {
                                    handleChanges(v.target.id, v.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <Box className={commonClasses.subtitle}>Country</Box>
                            <TextField
                                fullWidth
                                required
                                size="small"
                                id={`${prefix}${addressesKeys.country}`}
                                placeholder="Country"
                                variant="outlined"
                                InputProps={{ className: 'input' }}
                                error={!!errors[`${prefix}${addressesKeys.country}`]}
                                helperText={errors[`${prefix}${addressesKeys.country}`]}
                                value={address.country ?? ""}
                                onChange={(v) => {
                                    handleChanges(v.target.id, v.target.value)
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </Box>
    )
}
