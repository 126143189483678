import {ScaleChartDiagramData} from "../components/scaleChartComponent/ScaleChartComponent";

export const detailedRiskData: ScaleChartDiagramData[] = [
    {name: 'Optimal', color: '#5d8d29'},
    {name: 'Normal', color: '#bbd359'},
    {name: 'Moderate', color: '#e8d54a'},
    {name: 'Advanced', color: '#d09b4b'},
    {name: 'High', color: '#ef9535'}
];

export const unavailableRiskData: ScaleChartDiagramData[] = [
    {name: 'Optimal', color: '#b9b9b9'},
    {name: 'Normal', color: '#b9b9b9'},
    {name: 'Moderate', color: '#b9b9b9'},
    {name: 'Advanced', color: '#b9b9b9'},
    {name: 'High', color: '#b9b9b9'}
];

export const riskColorMap: { [id: string]: string; } = {
    "Moderate": "#e7d54c",
    "High": "#fd9502",
    "Optimal": "#5c8e05",
    "null": "#b9b9b9"
};

export const importantRiskColorMap: { [id: string]: string; } = {
    "Moderate": "#e7d54c",
    "High": "#fd9502",
    "Optimal": "#5c8e05",
    "null": "#b9b9b9"
};

export const exerciseRiskColorMap: { [id: string]: string; } = {
    "Optimal": "#129ad4",
    "High": "#fc002e",
    "Moderate": "#a57ba2",
    "null": "#b9b9b9"
};