import {PaymentPlanModel} from "../models/paymentPlan.models";

export const getPlanForAutoSelect = (plans: PaymentPlanModel[], specifiedPlan: string, defaultPlan: string) => {
    if (plans.length === 1) {
        return plans[0];
    }
    else  {
        if (specifiedPlan) {
            const plan = plans.find(x => x.name === specifiedPlan);
            if (plan) {
                return plan;
            } else if (defaultPlan) {
                const plan = plans.find(x => x.name === defaultPlan);
                if (plan) {
                    return plan;
                }
            }
        } else if (defaultPlan) {
            const plan = plans.find(x => x.name === defaultPlan);
            if (plan) {
                return plan;
            }
        }
    }
}