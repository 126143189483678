import {useEffect, useState} from 'react';
import { PatientAppointmentModel } from "../../../appointments/models/appointments.models";
import { appointmentsService } from "../../../appointments/services/appointments.service";
import { onEmit } from "../../../common/helpers/on-emit";
import { Subscription } from "recompose";
import { AppointmentStatus } from '../../../appointments/models/appointments.enums';
import { PatientModel } from '../../../patients/models/patient.model';
import { patientsQuery } from '../../../patients/stores/patientsStore';

export interface SelectAppointmentToNotesState {
    appointmentConfigurationOpen: boolean;
    isOpen: boolean;
    appointments: PatientAppointmentModel[];
    patient: PatientModel;
    appointmentId : number| null;
}

export function useFacade(patientId: number): [SelectAppointmentToNotesState, Function, Function] {
    const [state, setState] = useState({
        appointmentConfigurationOpen: false,
        isOpen: false,
        appointments:[],
        patient: null,
        appointmentId: null
    } as SelectAppointmentToNotesState);

    const handleAppointment = (appointmentId: number | null) => {
        setState(state => ({...state, appointmentId: appointmentId}));
    }

    const handleNoAppointment = (value: boolean) => {
        setState(state => ({...state, appointmentConfigurationOpen: value}));
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<PatientModel>(patientsQuery.targetPatient$, targetPatient => {
                if (targetPatient?.id === patientId) {
                    setState(state => ({...state, patient: targetPatient}));
                }
            })
        ];
    
        const daysBefore = 31;
        const daysAfter = 30;
    
        let dateBefore = new Date(new Date().setDate(new Date().getDate() - daysAfter));
        let dateAfter = new Date(new Date().setDate(new Date().getDate() + daysBefore));
        
        appointmentsService
            .getPatientAppointmentsById(patientId, dateBefore, dateAfter)
            .subscribe(appointments => {
                const sorted = appointments.filter((appointment) => appointment.status !== AppointmentStatus.Canceled && !appointment.note);

                setState(state => ({...state, appointments: sorted}));
            });

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    };


    useEffect(useEffectCB, []);

    return [state,handleAppointment, handleNoAppointment];
}