import Axios from 'axios-observable';
import { Observable } from "rxjs";
import { authHeader } from '../../common/helpers/auth-header';
import { snackService } from "../../common/snack/state";
import { AgreementConfirmationModel, AgreementConfirmationWithChangesModel, ConfirmAgreementModel } from "../models/agreement.model";
import { agreementsStore, AgreementsStore } from "../stores/agreementsStore";

/**
 * Provides methods for working with agreements
 */
export class AgreementsService {
    private url = `${process.env.REACT_APP_API_URL}Agreements`;

    constructor(private store: AgreementsStore) {
    }

    /**
     * returns agreement confirmations
     * @param intakeId
     */
    public getConfirmations(intakeId: string): Observable<any> {
        const url = `${this.url}/Patient/${intakeId}/Confirmations`;
        return new Observable(observer => {
            Axios.get<AgreementConfirmationModel[]>(url)
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({ confirmations: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * downloads agreement confirmation
     * @param id
     * @param intakeId
     */
    public downloadConfirmation(id: number, intakeId: string): Observable<any> {
        const url = `${this.url}/Patient/${intakeId}/Confirmations/${id}`;
        return new Observable(observer => {
            Axios.get(url, {
                responseType: "blob"
            })
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Views agreement confirmation
     */
    public viewinlineConfirmation(id: number, intakeId: string): Observable<any> {
        return new Observable(observer => {
            Axios.get(`${this.url}/Patient/${intakeId}/Confirmations/${id}/viewinline`, {
                headers: authHeader(),
                responseType: 'blob',
            })
                .pipe()
                .subscribe(
                    (response) => {
                        const file = new Blob([response.data], { type: 'application/pdf' });

                        const fileURL = URL.createObjectURL(file);

                        window.open(fileURL, '_blank');

                        snackService.success('File successfully opened!');
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public getNotConfirmed() {
        const url = `${this.url}/NotConfirmed`;
        return new Observable(observer => {
            Axios.get(url, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({ notConfirmedAgreements: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public confirm(agreements: ConfirmAgreementModel[]) {
        const url = `${this.url}/Confirm`;
        return new Observable(observer => {
            Axios.post(url, agreements, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({ confirmations: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public getPatientAgreementConfirmations(): Observable<any> {
        const url = `${this.url}/Confirmations`;
        return new Observable(observer => {
            Axios.get(url, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({ confirmations: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public getAgreementConfirmation(id: number): Observable<any> {
        const url = `${this.url}/Confirmations/${id}`;
        return new Observable(observer => {
            Axios.get(url, {
                headers: authHeader(),
                responseType: "blob"
            })
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Views agreement confirmation
     */
    public viewinlineAgreementConfirmation(id: number): Observable<any> {
        return new Observable(observer => {
            Axios.get(`${this.url}/Confirmations/${id}/viewinline`, {
                headers: authHeader(),
                responseType: 'blob',
            })
                .pipe()
                .subscribe(
                    (response) => {
                        const file = new Blob([response.data], { type: 'application/pdf' });

                        const fileURL = URL.createObjectURL(file);

                        window.open(fileURL, '_blank');

                        snackService.success('File successfully opened!');
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public getChangedAgreements(): Observable<any> {
        const config = { headers: authHeader() };

        const url = `${this.url}/Changed`;
        return new Observable(observer => {
            Axios.get<AgreementConfirmationWithChangesModel[]>(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({ agreementsChanges: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    
    public getAcceptChangesAgreements(id: number): Observable<any> {
        const config = { headers: authHeader() };
        const url = `${this.url}/${id}/AcceptChanges`;
        return new Observable(observer => {
            Axios.get<any>(url, config)
                .pipe()
                .subscribe(
                    () => {
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }
}

export const agreementsService = new AgreementsService(agreementsStore);