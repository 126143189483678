import { makeStyles } from '@material-ui/core/styles';
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme) => ({
  root: {
    borderColor: colors.mediumGray,
    borderRadius: 4,
    '& .MuiOutlinedInput-root': {
      borderColor: colors.mediumGray,
      borderRadius: 4,
    },
  },
  label: {
    color: colors.darkPurple,
    fontSize: 14,
    fontWeight: 400,
  },
}));
