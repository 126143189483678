import React from "react";
import { AuthenticatedLayoutComponent } from "../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent";
import { PagesNamesService } from "../../../../constants/pagesNames.constants";
import { TitlesService } from "../../../../constants/title.constants";
import { MenuItemTypes } from "../../../../models/menu.models";
import { HealthReportTemplatesComponent } from "../../components/healthReportTemplatesComponent/HealthReportTemplatesComponent";

export const HealthReportTemplatesPage: React.FC = () => {
    return (
        <AuthenticatedLayoutComponent
            hideBackground
            title={TitlesService.healthReportTemplatesTitle}
            pageName={PagesNamesService.healthReportTemplates}
            selectedMenuItem={MenuItemTypes.HealthReportTemplates}
        >
            <HealthReportTemplatesComponent />
        </AuthenticatedLayoutComponent>
    )
}
