import { Box } from "@material-ui/core";
import React from "react";

export interface WildHealthTabPanelProps {
    keepMounted?: boolean;
    children?: JSX.Element;
    selected: string;
    index: string;
    other?: any;
    minHeight?: number;
}

export const WildHealthTabPanel: React.FC<WildHealthTabPanelProps> = (props: WildHealthTabPanelProps) => {
    const {
        keepMounted,
        children,
        selected,
        index,
        other,
        minHeight = 300
    } = props;

    const toRender = !keepMounted ? selected === index : true;

    return (
        <div
            key={index}
            role="tabpanel"
            hidden={selected !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {
                toRender &&
                <Box minHeight={minHeight} display="flex" flexDirection="column">
                    {
                        children
                    }
                </Box>
            }
        </div>
    );
}
