import React, { useEffect, useState } from 'react';
import { Subscription } from "rxjs";
import { LabOrdersComponent } from "../labOrdersComponent/LabOrdersComponent";
import { NewLabsOrderComponent } from "../newLabsOrderComponent/NewLabsOrderComponent";
import { LabOrderPlacedComponent } from "../labOrderPlacedComponent/LabOrderPlacedComponent";
import { LabOrderInvoiceComponent } from "../labOrderInvoiceComponent/LabOrderInvoiceComponent";
import {Gender} from "../../../common/models/user.models";

enum LabStates {
    Orders,
    OrderNew,
    InvoicePlaced,
    Invoice,
}

interface LabComponentState {
    viewState: LabStates;
    selectedOrderId: number
}

const defaultState: LabComponentState = {
    viewState: LabStates.Orders,
    selectedOrderId: 0,
}

export function useFacade(patientId: number, gender: Gender): [JSX.Element] {
    const [state, setState] = useState(defaultState);

    const handleNavigateToOrders = () => {
        setState(state => ({ ...state, ...defaultState }))
    }

    const handleNavigateToOrderNew = () => {
        setState(state => ({ ...state, viewState: LabStates.OrderNew }))
    }

    const handleNavigateToOrderInvoicePlaced = (orderId: number) => {
        setState(state => ({ ...state, viewState: LabStates.InvoicePlaced, selectedOrderId: orderId }))
    }

    const handleNavigateToInvoice = (orderId: number) => {
        setState(state => ({ ...state, viewState: LabStates.Invoice, selectedOrderId: orderId }))
    }

    const stateViews = new Map<LabStates, JSX.Element>([
        [
            LabStates.Orders,
            <LabOrdersComponent
                patientId={patientId}
                orderId={state.selectedOrderId}
                handleNavigateToOrderNew={handleNavigateToOrderNew}
                handleNavigateToInvoice={handleNavigateToOrderInvoicePlaced}
            />
        ],
        [
            LabStates.OrderNew,
            <NewLabsOrderComponent
                patientId={patientId}
                gender={gender}
                handleNavigateToOrders={handleNavigateToOrders}
            />
        ],
        [
            LabStates.InvoicePlaced,
            <LabOrderPlacedComponent
                patientId={patientId}
                orderId={state.selectedOrderId}
                handleNavigateToOrders={handleNavigateToOrders}
                handleNavigateToInvoice={handleNavigateToInvoice}
            />
        ],
        [
            LabStates.Invoice,
            <LabOrderInvoiceComponent
                patientId={patientId}
                orderId={state.selectedOrderId}
                handleNavigateToOrders={handleNavigateToOrders}
            />
        ],
    ]);

    useEffect(() => {
        const subscriptions: Subscription[] = [
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [stateViews.get(state.viewState)];
}
