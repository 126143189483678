import Axios from "axios-observable";
import { Observable } from "rxjs";
import { authHeader } from "../../common/helpers/auth-header";
import { snackService } from "../../common/snack/state";
import { HealthScoreModel } from "../models/score.model";
import { HealthScoreStore, healthScoreStore } from "../stores";

export class HealthScoreService {
    constructor(private healthScoreStore: HealthScoreStore) {}
    
    /**
     * Returns Health Score
     */


    public getByPatient(): Observable<any> {
        const url = `${process.env.REACT_APP_API_URL}HealthScore/Patient`;
        const config = {headers: authHeader()};

        return new Observable((observer) => {
            Axios.get<HealthScoreModel>(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.healthScoreStore.update({healthScore: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        })
    }

    
    public getHealthScoreAvailable(): Observable<any> {
        const url = `${process.env.REACT_APP_API_URL}HealthScore/Patient/Availability`;
        const config = {headers: authHeader()};

        return new Observable(observer => {
            Axios.get(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.healthScoreStore.update({isHealthScoreAvailable: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public getHealthScoreAvailableByEmployee(patientId: number): Observable<any> {
        const url = `${process.env.REACT_APP_API_URL}HealthScore/Patient/${patientId}/Availability`;
        const config = {headers: authHeader()};

        return new Observable(observer => {
            Axios.get(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.healthScoreStore.update({isHealthScoreAvailable: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public getByEmployee(patientId: number): Observable<any> {
        const url = `${process.env.REACT_APP_API_URL}HealthScore/Patient/${patientId}`;
        const config = {headers: authHeader()};

        return new Observable((observer) => {
            Axios.get<HealthScoreModel>(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.healthScoreStore.update({healthScore: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        })
    }

}

export const healthScoreService = new HealthScoreService(healthScoreStore);
