import { Box, Grid, List } from '@material-ui/core';
import React from 'react';
import { WildHealthContainer } from '../../../common/components/wildHealthContainer/WildHealthContainer';
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import {ToolEnum, useFacade} from './manageApiResponseComponent.hooks';
import {useStyles} from './manageApiResponseComponent.styles';
import {FeatureComponent} from "../../../common/components/featureFlags/FeatureComponent";
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from 'clsx';
import {FeatureFlag} from "../../../common/components/featureFlags/featureFlags.models";
import {PagesNamesService} from '../../../../constants/pagesNames.constants';
import EndpointAdminComponent from "./endPointAdmin/EndpointAdminComponent";
import LoginOnBehalfOfUserComponent from "./endPointAdmin/LoginOnBehalfOfUser";
import ViewAllIntegrationIdsComponent from "./endPointAdmin/ViewAllIntegrationIds";
import BuyProductsComponent from "./endPointAdmin/BuyProductsComponent";
import ChangeSubscriptionComponent from "./endPointAdmin/ChangeSubscriptionComponent";
import ChangePaymentPlanInsuranceStatesComponent from "./endPointAdmin/ChangePaymentPlanInsuranceStates";
import ManagePatientProductsComponent from "./endPointAdmin/ManagePatientProductsComponent";
import CreateDefaultPaymentPlanComponent from "./endPointAdmin/CreateDefaultPaymentPlanComponent";
import ChangeSubscriptionPriceComponent from "./endPointAdmin/ChangeSubscriptionPriceComponent";

export const ManageApiResponseComponent: React.FC = () => {
  const [
    {
      isLoading,
      isStateLoading,
      isProductsLoading,
      filtersState,
      responseView,
      params,
      errors,
      menuParams,
      menuStates,
      eligiblePaymentCoupons,
      eligiblePaymentPlans,
      activePaymentPlans,
      patientName,
      patientSubscription,
      eligiblePatientProducts,
      products
    },
    handleToggle,
    handleToggleLoginBehalf,
    handleToggleIntegration,
    handleToggleChangeInsuranceStates,
    getLoginOnBehalfOfUser,
    getAllIntegrationIds,
    handleAddInsuranceStates,
    handleRemoveInsuranceStates,
    handleRestoreSubscriptionPrice,
    handleResetPatientProducts,
    handleSubmit,
    handleChanges,
    handleChangesGeneric
  ] = useFacade();

  const classes = useStyles();
  const commonClasses = commonUseStyles();
  const loading = isLoading || isStateLoading || isProductsLoading;

  const renderResponse = () => {
    if (loading) {
      return <WildHealthLinearProgress />
    }

    if (responseView) {
      return <Box mt={2}>
        <pre className={classes.responseSection}>{JSON.stringify(responseView, null, 4)}</pre>
      </Box>
    }

    return <></>
  }

  return (
    <Box py={2}>
      <Box mb={2} display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <FeatureComponent featureFlag={FeatureFlag.Navigation}>
            <Box className={clsx(commonClasses.size24, commonClasses.textSemiBold, commonClasses.colorBlack)}>{PagesNamesService.engineeringAdminTool}</Box>
          </FeatureComponent>
        </Box>
      </Box>

      <WildHealthContainer margin={0} minHeight={'100%'}>
        <Grid container spacing={2}>
          <Grid item md={4}>
            <Box p={1}>
              <Box>
                <span className={clsx(commonClasses.size20, commonClasses.colorBlack, commonClasses.textMedium)}>Request</span>
              </Box>
              <List className={clsx(classes.filters, 'flat-scroll')}>
                <LoginOnBehalfOfUserComponent
                  id="login-on-behalf-of-user"
                  params={params}
                  isOpen={filtersState.isLoginBehalfUserOpen}
                  errors={errors}
                  title={"login-on-behalf-of user"}
                  isLoading={loading}
                  handleOpenToggle={handleToggleLoginBehalf}
                  handleChanges={handleChanges}
                  handleClick={getLoginOnBehalfOfUser}
                />
                <EndpointAdminComponent
                  id="delete-unspecified-user"
                  params={menuParams[ToolEnum.Delete_Unspecified_User]}
                  isOpen={menuStates[ToolEnum.Delete_Unspecified_User]}
                  errors={errors}
                  title={"Delete Unspecified User"}
                  isLoading={isLoading}
                  handleOpenToggle={handleToggle[ToolEnum.Delete_Unspecified_User]}
                  handleChanges={handleChangesGeneric}
                  handleClick={handleSubmit[ToolEnum.Delete_Unspecified_User]}
                  toolMenu={ToolEnum.Delete_Unspecified_User}
                />
                <ViewAllIntegrationIdsComponent
                  id="view-all-integration-ids"
                  params={params}
                  isOpen={filtersState.isIntegrationOpen}
                  errors={errors}
                  title={"View all IntegrationIds"}
                  isLoading={loading}
                  handleOpenToggle={handleToggleIntegration}
                  handleChanges={handleChanges}
                  handleClick={getAllIntegrationIds}
                />
                <EndpointAdminComponent
                  id="change-practice-copy"
                  params={menuParams[ToolEnum.CHANGE_PRACTICE_COPY]}
                  isOpen={menuStates[ToolEnum.CHANGE_PRACTICE_COPY]}
                  errors={errors}
                  title={"Change practice Copy"}
                  isLoading={loading}
                  handleOpenToggle={handleToggle[ToolEnum.CHANGE_PRACTICE_COPY]}
                  handleChanges={handleChangesGeneric}
                  handleClick={handleSubmit[ToolEnum.CHANGE_PRACTICE_COPY]}
                  toolMenu={ToolEnum.CHANGE_PRACTICE_COPY}
                />
                <ChangeSubscriptionPriceComponent
                  id="change-subscription-price"
                  params={menuParams[ToolEnum.CHANGE_SUBSCRIPTION_PRICE]}
                  isOpen={menuStates[ToolEnum.CHANGE_SUBSCRIPTION_PRICE]}
                  errors={errors}
                  title={"Change Subscription price"}
                  isLoading={loading}
                  products={products}
                  handleOpenToggle={handleToggle[ToolEnum.CHANGE_SUBSCRIPTION_PRICE]}
                  handleChanges={handleChangesGeneric}
                  handleClick={handleSubmit[ToolEnum.CHANGE_SUBSCRIPTION_PRICE]}
                  toolMenu={ToolEnum.CHANGE_SUBSCRIPTION_PRICE}
                />
                <ChangeSubscriptionComponent
                  id="change-subscription"
                  params={menuParams}
                  isOpen={menuStates[ToolEnum.CHANGE_SUBSCRIPTION_CANDIDATE]}
                  errors={errors}
                  title={"Change Subscription"}
                  isLoading={loading}
                  handleOpenToggle={handleToggle[ToolEnum.CHANGE_SUBSCRIPTION_CANDIDATE]}
                  handleChanges={handleChangesGeneric}
                  handleChangeSubscriptionCandidate={handleSubmit[ToolEnum.CHANGE_SUBSCRIPTION_CANDIDATE]}
                  handlePaymentPlanRecommend={handleSubmit[ToolEnum.PAYMENT_PLANS_RECOMMEND]}
                  handlePaymentPlanMigration={handleSubmit[ToolEnum.PAYMENT_PLANS_MIGRATE]}
                  eligiblePaymentCoupons={eligiblePaymentCoupons}
                  eligiblePaymentPlans={eligiblePaymentPlans}
                />
                <BuyProductsComponent
                  id="buy-products"
                  params={menuParams[ToolEnum.BUY_PRODUCTS]}
                  isOpen={menuStates[ToolEnum.BUY_PRODUCTS]}
                  errors={errors}
                  title={"Buy Products"}
                  isLoading={loading}
                  handleOpenToggle={handleToggle[ToolEnum.BUY_PRODUCTS]}
                  handleChanges={handleChangesGeneric}
                  handleClick={handleSubmit[ToolEnum.BUY_PRODUCTS]}
                  toolMenu={ToolEnum.BUY_PRODUCTS}
                />
                <ChangePaymentPlanInsuranceStatesComponent
                  id="change-payment-plan-insurance-states"
                  params={params}
                  activePaymentPlans={activePaymentPlans}
                  isOpen={filtersState.isChangeInsuranceStatesOpen}
                  errors={errors}
                  title={"Change Payment Plan Insurance states"}
                  isLoading={loading}
                  handleOpenToggle={handleToggleChangeInsuranceStates}
                  handleChanges={handleChanges}
                  handleAddClick={handleAddInsuranceStates}
                  handleRemoveClick={handleRemoveInsuranceStates}
                />
                <EndpointAdminComponent
                  id="reset-patient-products"
                  params={menuParams[ToolEnum.RESET_PATIENT_PRODUCTS]}
                  isOpen={menuStates[ToolEnum.RESET_PATIENT_PRODUCTS]}
                  errors={errors}
                  title={"Reset Patient Products"}
                  isLoading={loading}
                  handleOpenToggle={handleToggle[ToolEnum.RESET_PATIENT_PRODUCTS]}
                  handleChanges={handleChangesGeneric}
                  handleClick={handleSubmit[ToolEnum.RESET_PATIENT_PRODUCTS]}
                  toolMenu={ToolEnum.RESET_PATIENT_PRODUCTS}
                />
                <ManagePatientProductsComponent
                  id="manage-patient-products"
                  params={menuParams}
                  isOpen={menuStates[ToolEnum.MANAGE_PATIENT_PRODUCTS]}
                  errors={errors}
                  title={"Manage Patient Product"}
                  isLoading={loading}
                  handleOpenToggle={handleToggle[ToolEnum.MANAGE_PATIENT_PRODUCTS]}
                  handleChanges={handleChangesGeneric}
                  handleGetActivePatientProducts={handleSubmit[ToolEnum.GET_ACTIVE_PATIENT_PRODUCTS]}
                  handleRestoreSubscriptionPrice={handleRestoreSubscriptionPrice}
                  handleResetPatientProducts={handleResetPatientProducts}
                  patientName={patientName}
                  patientSubscription={patientSubscription}
                  eligiblePatientProducts={eligiblePatientProducts}
                />
                <CreateDefaultPaymentPlanComponent
                  id="create-default-payment-plan"
                  params={menuParams[ToolEnum.CREATE_DEFAULT_PAYMENT_PLAN]}
                  isOpen={menuStates[ToolEnum.CREATE_DEFAULT_PAYMENT_PLAN]}
                  paymentPlans={activePaymentPlans}
                  errors={errors}
                  title={"Create Default Payment Plan"}
                  isLoading={loading}
                  handleOpenToggle={handleToggle[ToolEnum.CREATE_DEFAULT_PAYMENT_PLAN]}
                  handleChanges={handleChangesGeneric}
                  handleCreateDefaultPaymentPlan={handleSubmit[ToolEnum.CREATE_DEFAULT_PAYMENT_PLAN]}
                  toolMenu={ToolEnum.CREATE_DEFAULT_PAYMENT_PLAN}
                />
              </List>
            </Box>
          </Grid>
          <Grid item md={8}>
            <Box p={1}>
              <Box>
                <span className={clsx(commonClasses.size20, commonClasses.colorBlack, commonClasses.textMedium)}>Response</span>
              </Box>
              {renderResponse()}
            </Box>
          </Grid>
        </Grid>
      </WildHealthContainer>
    </Box>
  );
}