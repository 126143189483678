import { Gender } from "../../../common/models/user.models";
import { birthdayValidationRule, emailValidationRule, firstNameValidationRule, functionValidationRule, lastNameValidationRule, notEmptyValidationRule, notWhitespaceValidationRule, optionalValidation, phoneNumberValidationRule } from "../../../common/validation/general-validation-rules";
import { GeneralValidator } from "../../../common/validation/general-validator";

export const createPracticumPatientValidator = new GeneralValidator({
    'firstName': [firstNameValidationRule, notWhitespaceValidationRule()],
    'lastName': [lastNameValidationRule, notWhitespaceValidationRule()],
    'email': [emailValidationRule],
    'birthday': [birthdayValidationRule],
    'phoneNumber': [optionalValidation('', [phoneNumberValidationRule])],
    'fellowId': [notEmptyValidationRule("Please, select a fellow.")],
    'gender': [functionValidationRule((value) => {
        //here should be '=='. Dont replace.
        /* eslint-disable */
        if (value == Gender.Female) return true;
        /* eslint-disable */
        return value == Gender.Male;
    }, "Gender should be selected.")],
});
