import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontSize: '1.6rem',
            color: '#292929 !important'
        },
        smallTitle: {
            fontSize: '1.2rem',
            fontWeight: 400,
        },
        endOfNoteTitle: {
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontSize: 20,
            fontWeight: 500,
            lineHeight: 1.2,
        },
        sectionTitle: {
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            maxWidth: 500,
            fontSize: 22,
            fontWeight: 500,
            lineHeight: 1.2,
        },
        endSectionTitle: {
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            maxWidth: 500,
            fontSize: 18,
            fontWeight: 500,
            lineHeight: 1,
        },
        textArea: {
            height: 100,
            width: '100%',
            resize: 'none',
        },
        recommendationTextArea: {
            height: 200,
            width: '100%',
            resize: 'none',
        }
    })
);
