import React from 'react';
import { AuthenticatedLayoutComponent } from "../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent";
import { TitlesService } from "../../../../constants/title.constants";
import { MenuItemTypes } from "../../../../models/menu.models";
import { Box } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { navigationService } from '../../../../services/navigation.service';
import { useHistory } from 'react-router';
import { SyncRecordListComponent } from "../../components/syncRecordListComponent/SyncRecordListComponent";
import { commonUseStyles } from "../../../common/styles/common.styles";
import clsx from 'clsx';
import { SyncRecordStatus } from '../../models/syncRecordStatus.enums';

export const SyncRecordListPage: React.FC<any> = (props: any) => {
    const statusId = Number(props.match.params.statusId);
    const showSearchResult = props?.location?.state?.showSearchResult as boolean;

    const history = useHistory();
    const commonClasses = commonUseStyles();

    const getAvailableReconcile = (statusId: SyncRecordStatus) => {
        switch (statusId) {
            case SyncRecordStatus.ReadyForSync:
                return false;
            case SyncRecordStatus.SyncComplete:
                return true;
            case SyncRecordStatus.FailedSync:
                return false;
            default:
                return true;
        }
    }
    
    return (
        <AuthenticatedLayoutComponent
            title={TitlesService.syncRecordsTitle}
            selectedMenuItem={MenuItemTypes.SyncRecords}
            hideBackground
        >
            <Box id="sync-records-dashboard-go-back" py={2} display="flex" alignItems="center" className={clsx(commonClasses.colorGray1, commonClasses.backgroundColorBg, commonClasses.pointer)} onClick={() => {navigationService.goBack(history)}}>
                <Box>
                    <ArrowBack fontSize="small"/>
                </Box>
                <Box ml={1}>
                    <span>Back</span>
                </Box>
            </Box>
            <Box className={commonClasses.bgWhiteMain} >
                <SyncRecordListComponent statusId={statusId} isAvailableReconcile={getAvailableReconcile(statusId)} showSearchResult={showSearchResult} />
            </Box>
            
        </AuthenticatedLayoutComponent>
    )
}