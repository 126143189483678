import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Subscription } from 'rxjs';
import { confirmService } from '../../../../services/confirm.service';
import { AppointmentTypeModel, PatientAppointmentModel, AppointmentsSummaryModel } from '../../../appointments/models/appointments.models';
import { appointmentsService } from '../../../appointments/services/appointments.service';
import { appointmentsQuery } from '../../../appointments/stores/appointments';
import { onEmit } from '../../../common/helpers/on-emit';
import { EmployeeModel } from '../../../employee/models/employee.models';
import { employeeService } from '../../../employee/services/employees.service';
import { employeesQuery } from '../../../employee/stores/employeesStore';
import { LocationModel } from '../../../locations/models/locations.models';
import { locationsService } from "../../../locations/services/locations.service";
import { locationsQuery } from '../../../locations/stores';
import { patientsService } from '../../../patients/services/patients.service';
import { patientsQuery } from '../../../patients/stores/patientsStore';
import { authQuery } from "../../../auth/stores/auth";
import { TimeZoneModel } from "../../../timezones/models/timezone.model";
import { profileService } from '../../../account/services/profile.service';
import { navigationService } from '../../../../services/navigation.service';

interface AppointmentWidgetComponentState {
    isLoading: boolean;
    isPhotosLoading: boolean;
    isAppointmentTypesLoading: boolean;
    isManageOpen: boolean;
    menuAnchorEl: HTMLElement;
    assignedEmployees: EmployeeModel[];
    appointmentTypes: AppointmentTypeModel[];
    appointments: PatientAppointmentModel[];
    locations: LocationModel[];
    timeZone: TimeZoneModel;
    selectedId: number;
    appointmentsSummary: AppointmentsSummaryModel;
}

export function useFacade(patientId: number | null): [
    AppointmentWidgetComponentState,
    (appointmentId: number, menuAnchorEl?: HTMLElement) => void,
    () => void,
    () => void,
    (joinLink: string) => void,
    () => void,
    (appointmentId: number, status: boolean) => void,
] {
    const history = useHistory();
    const [state, setState] = useState({
        isLoading: true,
        isPhotosLoading: true,
        isAppointmentTypesLoading: false,
        isManageOpen: false,
        menuAnchorEl: null,
        assignedEmployees: [],
        appointmentTypes: [],
        appointments: [],
        locations: [],
        timeZone: null,
        appointmentsSummary: null
    } as AppointmentWidgetComponentState);

    const handleReschedule = () => {
        const appointment = state.appointments.find(x => x.id === state.selectedId);
        if (!appointment) {
            return;
        }

        setState(state => ({ ...state, isManageOpen: false, menuAnchorEl: null }));

        appointmentsService.openPatientAppointmentReschedule(appointment, null).subscribe();
    }

    const handleCancel = () => {
        setState(state => ({ ...state, isManageOpen: false, menuAnchorEl: null }));

        confirmService.confirm(
            'Cancel Appointment',
            'Are you sure you want to cancel the appointment?',
            'Cancel Appointment',
            'Discard',
            'danger')
            .subscribe(
                () => appointmentsService.cancelAsPatient(state.selectedId)
            );
    }

    const handleJoin = (joinLink: string) => {
        window.open(joinLink);
    }

    const handleToggleMenu = (appointmentId: number, menuAnchorEl?: HTMLElement) => {
        setState(state => ({ 
            ...state, 
            menuAnchorEl,
            selectedId: appointmentId
        }))
    }

    const handleToggleManage = (appointmentId: number, status: boolean) => {
        setState(state => ({ 
            ...state, 
            isManageOpen: status,
            selectedId: appointmentId
        }))
    }

    const handleGoToMyProfile = () => {
        navigationService.toMyProfile(history);
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<PatientAppointmentModel[]>(appointmentsQuery.patientAppointments$, appointments => {
                setState(state => ({ ...state, appointments: appointments }));
            }),
            onEmit<AppointmentTypeModel[]>(appointmentsQuery.appointmentTypes$, types => {
                setState(state => ({ ...state, appointmentTypes: types }))
            }),
            onEmit<EmployeeModel[]>(employeesQuery.patientEmployees$, employees => {
                setState(state => ({ ...state, assignedEmployees: employees }));
            }),
            onEmit<TimeZoneModel>(patientsQuery.patientTimeZone$, timeZone => {
                setState(state => ({ ...state, timeZone: timeZone }));
            }),
            onEmit<LocationModel[]>(locationsQuery.availableLocations$, locations => {
                setState(state => ({ ...state, locations: locations }));
            }),
            onEmit<AppointmentsSummaryModel>(appointmentsQuery.appointmentsSummary$, appointmentsSummary => {
                setState(state => ({ ...state, appointmentsSummary: appointmentsSummary }));
            })
        ];

        const cb = () => setState(state => ({ ...state, isLoading: false }));
        const photosCB = () => setState(state => ({ ...state, isPhotosLoading: false }));
        const appointmentTypeCB = () => setState(state => ({ ...state, isAppointmentTypesLoading: false }));
        const getPhotos = (employees: EmployeeModel[]) => {
            if (employees && employees.length) {
                profileService.getEmployeeProfilePhotos(employees.map(e => e.id).join(',')).subscribe(photosCB, photosCB)
            } else {
                photosCB()
            }
        }

        patientsService.getPatientTimeZone(patientId);
        locationsService.getAvailable(authQuery.getCurrentPracticeId()).subscribe();
        if (!state.isAppointmentTypesLoading) {
            setState(state => ({
                ...state,
                isAppointmentTypesLoading: true
            }));
            appointmentsService.getAvailableAppointmentTypesAsync(patientId).subscribe(appointmentTypeCB, appointmentTypeCB);
        }

        if (patientId) {
            appointmentsService.getAppointmentsSummary(patientId);
            employeeService.getAssignedToById(patientId).subscribe((employees) => getPhotos(employees));
            appointmentsService.getPatientAppointmentsById(patientId, new Date()).subscribe(cb, cb);
        } else {
            appointmentsService.getAppointmentsSummary(authQuery.getPatientId());
            employeeService.getAssignedTo().subscribe((employees) => getPhotos(employees));
            appointmentsService.getPatientAppointments(new Date()).subscribe(cb, cb);
        }

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [patientId]);

    return [state, handleToggleMenu, handleReschedule, handleCancel, handleJoin, handleGoToMyProfile, handleToggleManage];
}