import { paymentPlansService } from "../../services/paymentPlans.service";
import { PaymentPlanModel } from "../../models/paymentPlan.models";
import { useEffect } from "react";
import { Track, PlatformName } from "../../../tracking/tracker"
import {useFlags} from "launchdarkly-react-client-sdk";
import {getValueFeatureFlag} from "../../../common/components/featureFlags/featureFlags";
import {FeatureFlag} from "../../../common/components/featureFlags/featureFlags.models";

const DEFAULT_PLAN = "STANDARD";

export function useFacade(plans: PaymentPlanModel[], planName?: string): [] {
  const featureFlags = useFlags();
  const defaultPlan = getValueFeatureFlag(featureFlags, FeatureFlag.DefaultPlan) ?? DEFAULT_PLAN;

  const handlePlanSelect = (plan: PaymentPlanModel) => {
    paymentPlansService.selectPlan(plan);

    Track("select_plan", {
      type: 'select_plan',
      plan_type: plan.displayName,
      acValue: plan.displayName,
      platform: PlatformName()
    })
  };

  useEffect(() => {
    Track("onboarding_consultation", {
      group: 'test',
      platform: PlatformName(),
    });

    if (planName && plans?.length) {
      let recommendedPlan = plans.find(x => x.name === planName);
      if (!recommendedPlan) {
        recommendedPlan = plans.find(x => x.name === defaultPlan)
      }
      handlePlanSelect(recommendedPlan);
    }
  }, [plans, planName])

  return []
}
