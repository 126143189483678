import moment from "moment";
import * as pdfMake from 'pdfmake/build/pdfmake';
import {Margins, PageSize} from 'pdfmake/interfaces';
import {logoService} from "../../../services/logo.service";
import {addOnProvidersNames} from "../../addons/models/addOns.models";
import {authQuery} from "../../auth/stores/auth";
import {displayMoney} from "../../common/helpers/display-money";
import { toCurrentTimeZone } from "../../timezones/helpers/timezone";
import {LabOrderModel} from "../models/labOrders.models";

const pdfMakeX = require('pdfmake/build/pdfmake.js');
const pdfFontsX = require('pdfmake-unicode/dist/pdfmake-unicode.js');

pdfMakeX.vfs = pdfFontsX.pdfMake.vfs;

export abstract class InvoicePdfService {
    protected pdfDocument = {
        pageSize: "LETTER" as PageSize,
        pageMargins: [60, 40, 60, 50] as Margins,
        header: null,
        footer: function (currentPage, pageCount) {
            if (currentPage === pageCount) {
                const result = {
                    table: {
                        widths: ['*'],
                        body: []
                    },
                    layout: 'noBorders'
                }

                if (authQuery.isDefaultPractice()) {
                    result.table.body.push([
                        {
                            text: 'p: 800.975.1562 | e: info@wildhealth.com | a: 535 Wellington Way # 330 Lexington KY 40503',
                            alignment: 'center',
                            style: 'footerText',
                        }

                    ]);
                }

                result.table.body.push([{
                    text: [
                        {
                            text: 'Privacy Policy',
                            style: 'footerText',
                            link: 'https://www.wildhealth.com/privacy-policy'
                        },
                        {
                            text: ' | ',
                            style: 'footerText'
                        },
                        {
                            text: 'Terms of Use',
                            style: 'footerText',
                            link: 'https://www.wildhealth.com/terms-of-use'
                        },
                        {
                            text: ' | ',
                            style: 'footerText'
                        },
                        {
                            text: 'Copyright ' + new Date().getFullYear(),
                            style: 'footerText'
                        },

                    ],
                    alignment: 'center',
                    style: 'footerText'
                }]);

                return result;
            }
        },
        content: [],
        styles: {
            invoiceTitle: {
                fontFamily: 'Roboto',
                fontWeight: 'bold',
                fontSize: 46,
            },
            invoiceSubTitle: {
                fontFamily: 'Open Sans',
                fontWeight: 600,
                fontSize: 24,
                color: '#7C8A9D',
            },
            companyTitle: {
                color: '#7C8A9D',
                fontFamily: 'Roboto',
                fontWeight: 400,
                fontSize: 14,
            },
            companySubTitle: {
                fontFamily: 'Roboto',
                fontWeight: 500,
                fontSize: 16,
            },
            tableHeader: {
                fontFamily: 'Roboto',
                fontWeight: 500,
                fontSize: 14,
                color: '#AFBACA',
            },
            tableValue: {
                fontFamily: 'Roboto',
                fontWeight: 500,
                fontSize: 14,
                color: '##091D36',
            },
            total: {
                fontFamily: 'Open Sans',
                fontWeight: 600,
                fontSize: 24,
                padding: '10px 0px',
            },
        },
    }

    constructor() {
        const self = this;
        var image = new Image();
        image.src = logoService.getLogo().clarityHeader;
        image.onload = function () {
            var canvas, ctx, dataURL, base64;
            canvas = document.createElement("canvas");
            ctx = canvas.getContext("2d");
            canvas.width = image.width;
            canvas.height = image.height;
            ctx.drawImage(image, 0, 0);
            dataURL = canvas.toDataURL("image/png");
            base64 = dataURL.replace(/^data:image\/png;base64,/, "");

            self.pdfDocument.header = function (currentPage) {
                if (currentPage === 1)
                    return [
                        {
                            image: `data:image/jpeg;base64,${base64}`,
                            fit: [615, 100],
                        }
                    ]
            }
        };
    }

    protected clear() {
        this.pdfDocument.content = [];
    }
}

interface TextCell {
    text: string,
    style?: string,
    margin?: number[];
    alignment?: string;
}

export class LabInvoicePdfService extends InvoicePdfService {

    public downloadNotePdf(invoice: LabOrderModel, invoiceLogo: string) {
        this.buildPdfContent(invoice, invoiceLogo)
        pdfMake.createPdf(this.pdfDocument).download(`${addOnProvidersNames.get(invoice.provider)}-${moment(toCurrentTimeZone(invoice.orderedAt)).format('MM/DD/YYYY')}`);
    }

    public toDataURL(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    public buildPdfContent(invoice: LabOrderModel, invoiceLogo: string) {
        this.clear();

        const headerBody = [
            [
                [
                    {
                        margin: [0, 70, 0, 0],
                        text: 'Invoice',
                        style: 'invoiceTitle',
                    },
                    {
                        text: 'Lab Order Summary',
                        style: 'invoiceSubTitle',
                    },
                    {
                        table: {
                            widths: ['*', '*'],
                            body: [
                                [
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: 'Invoice Date',
                                            style: 'companyTitle',
                                        },
                                        {
                                            text: moment(toCurrentTimeZone(invoice.orderedAt)).format('MM/DD/YYYY'),
                                            style: 'companySubTitle',
                                        },
                                    ],
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: 'Paid Date',
                                            style: 'companyTitle',
                                        },
                                        {
                                            text: invoice.paidAt ? moment(toCurrentTimeZone(invoice.paidAt)).format('MM/DD/YYYY') : 'Not Paid yet',
                                            style: 'companySubTitle',
                                        },
                                    ]
                                ]],
                        },
                        layout: 'noBorders'
                    },
                ],
                [
                    {
                        margin: [0, 70, 0, 0],
                        image: invoiceLogo,
                        style: 'invoiceSubTitle',
                        // height: 60,
                    },
                    {
                        margin: [0, 10, 0, 0],
                        text: 'Lab Company',
                        style: 'companyTitle',
                    },
                    {
                        text: addOnProvidersNames.get(invoice.provider),
                        style: 'companySubTitle',
                    },
                ],
            ],
        ];

        this.pdfDocument.content.push({
            table: {
                margin: [0, 30, 0, 0],
                widths: ['*', '150'],
                body: headerBody,
            },
            layout: 'noBorders'
        });

        const ValuesBody: Array<TextCell[]> = [[
            {
                margin: [0, 30, 0, 0],
                text: 'Test Code',
                style: 'tableHeader',
            },
            {
                margin: [0, 30, 0, 0],
                text: 'Description',
                style: 'tableHeader',
            },
            {
                margin: [0, 30, 0, 0],
                text: 'Price',
                style: 'tableHeader',
                alignment: 'right',
            }
        ]];

        ValuesBody.push();
        invoice.items.forEach(item => {

            ValuesBody.push([
                {
                    text: item.sku,
                    style: 'tableValue',
                },
                {
                    text: item.description,
                    style: 'tableValue',
                },
                {
                    text: displayMoney(item.price, '$'),
                    style: 'tableValue',
                    alignment: 'right',
                }
            ]);
        });

        let total = 0;
        invoice.items.forEach(item => total += item.price);

        ValuesBody.push([
            {
                text: '',
            },
            {
                text: '',
            },
            {
                text: `Total: ${displayMoney(total, '$')}`,
                style: 'tableValue',
                alignment: 'right',
            }
        ]);

        this.pdfDocument.content.push({
            table: {
                widths: ['*', '*', 200],
                body: ValuesBody,
                headerRows: 1,
            },
            layout: 'lightHorizontalLines'
        });
    }
}


export const labInvoicePdfService = new LabInvoicePdfService();

