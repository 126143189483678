import React from 'react';
import {
  Box,
  FormControl,
  TextField,
  Select,
  MenuItem,
  Chip,
  Collapse,
  Button
} from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import moment from "moment";
import clsx from 'clsx';
import { useFacade } from './configurationEditComponent.hooks';
import { useStyles } from "./configurationEditComponent.styles";
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { FeatureComponent } from "../../../common/components/featureFlags/FeatureComponent";
import commonUseStyles from '../../../common/styles/common.styles';
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import { WildHealthDatePicker } from '../../../common/components/WildHealthDatePicker';
import { addressQuery } from '../../../account/stores/addressStore';
import { StateModel } from '../../../account/models/address.model';
import { InsuranceServiceType } from '../../enums/insuranceServiceType';
import { insuranceServiceTypeLabel } from '../../constants/insurance.constants';
import { InsurancePlanModel } from '../../models/insurance.models';

interface ConfigurationEditComponentProps {
  configurationId: number;
}

export const ConfigurationEditComponent: React.FC<ConfigurationEditComponentProps> = (props: ConfigurationEditComponentProps) => {
  const [
    {
      isLoading,
      isStateLoading,
      isUpdating,
      isServiceConfigUpdating,
      insuranceConfiguration,
      rteConfiguration,
      claimAutomationConfig,
      serviceConfigurations,
      mainConfigCollapse,
      logoUrl,
      allInsurancePlans
    },
    handleChanges,
    handleChangeRTEConfigs,
    handleChangeClaimAutomationConfigs,
    handleChangeAddressOverrides,
    handleChangeBasicServiceConfigs,
    handleChangeSubServiceConfigs,
    handleServiceConfigMultiSelectChange,
    handleUpdateInsuranceConfiguration,
    handleAddServiceConfig,
    handleUpdateServiceConfiguration,
    handleRemoveServiceconfig,
    handleMainConfigToggle,
    handleLogoUpload,
    handleDeleteLogo
  ] = useFacade(props.configurationId);

  const commonClasses = commonUseStyles();
  const classes = useStyles();
  
  if (isLoading) {
    return <WildHealthLinearProgress />
  }

  const renderBasicConfigurationSection = () => {
    return (
      <Collapse in={mainConfigCollapse.basicConfig} timeout="auto" unmountOnExit>
        <Box p={3} className={commonClasses.bgWhiteMain}>
          <Box mb={2}>
            <FormControl
              variant="outlined"
              color='primary'
              required
              size="small"
              classes={{root: classes.root}}
              fullWidth>
              <Box className={commonClasses.label} mb={1}>Alias</Box>
              <TextField
                fullWidth
                size="small"
                required
                InputProps={{className: 'input', classes}}
                id={'payerNameAlias'}
                type="text"
                placeholder="Alias"
                value={insuranceConfiguration.payerNameAlias}
                variant="outlined"
                disabled={!handleChanges}
                onChange={(v) => handleChanges(v.target.id, v.target.value)}
              />
            </FormControl>
          </Box>
          <Box mb={2}>
            <Box className={commonClasses.label} mb={1}>Logo</Box>
            <Box display="flex" alignItems="center">
              <Box className={clsx(classes.photo, classes.photoBorder)}>
                {(insuranceConfiguration.payerLogo || logoUrl) && (
                  <img className={classes.photoItem} style={{ width: '100%' }}
                    src={logoUrl ? logoUrl : insuranceConfiguration.payerLogo}
                    alt="img" />
                )}
              </Box>
              {
                !isLoading && !isUpdating &&
                  <Box display="flex" alignItems="center">
                    <Box className={classes.photoButton}>
                      <Button
                        className={clsx(classes.photoUploadButton, commonClasses.whButton)}
                        component="label"
                      >
                        Upload
                        <input type="file" style={{ display: "none" }}
                          onChange={(e) => handleLogoUpload(e)}
                          accept={'das'} />
                      </Button>
                    </Box>
                    {logoUrl &&
                      <Box className={classes.photoButton}>
                        <WildHealthButton
                          id="configuration-delete-logo"
                          loading={isLoading || isUpdating}
                          color='tertiary'
                          onClick={() => handleDeleteLogo()}
                        >
                          Delete
                        </WildHealthButton>
                      </Box>
                    }
                  </Box>
              }
            </Box>
          </Box>
          <Box mb={2}>
            <FormControl
              variant="outlined"
              color='primary'
              required
              size="small"
              classes={{root: classes.root}}
              fullWidth>
              <Box className={commonClasses.label} mb={1}>Group Id Display Name</Box>
              <TextField
                fullWidth
                size="small"
                required
                InputProps={{className: 'input', classes}}
                id={'groupIdDisplayName'}
                type="text"
                placeholder="Group Id Display Name"
                value={insuranceConfiguration.groupIdDisplayName}
                variant="outlined"
                disabled={!handleChanges}
                onChange={(v) => handleChanges(v.target.id, v.target.value)}
              />
            </FormControl>
          </Box>
          <Box mb={2}>
            <FormControl
              variant="outlined"
              color='primary'
              required
              size="small"
              classes={{root: classes.root}}
              fullWidth>
              <Box className={commonClasses.label} mb={1}>Member Id Display Name</Box>
              <TextField
                fullWidth
                size="small"
                required
                InputProps={{className: 'input', classes}}
                id={'memberIdDisplayName'}
                type="text"
                placeholder="Member Id Display Name"
                value={insuranceConfiguration.memberIdDisplayName}
                variant="outlined"
                disabled={!handleChanges}
                onChange={(v) => handleChanges(v.target.id, v.target.value)}
              />
            </FormControl>
          </Box>
          <Box mb={2}>
            <FormControl
              variant="outlined"
              color='primary'
              required
              size="small"
              classes={{ root: classes.root }}
              fullWidth>
              <Box className={commonClasses.label} mb={1}>Supports Eligibility Lookup</Box>
              <Select
                style={{ background: '#FFF' }}
                id="requireGroupNumber"
                classes={{ root: classes.root }}
                value={insuranceConfiguration.supportsEligibilityLookup ? "True" : "False"}
                inputProps={{
                  classes,
                }}
                onChange={(v) => handleChanges("supportsEligibilityLookup", v.target.value.toString() === 'False' ? false : true)}
              >
                <MenuItem value="False">False</MenuItem>
                <MenuItem value="True">True</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box mb={2}>
            <FormControl
              variant="outlined"
              color='primary'
              size="small"
              classes={{root: classes.root}}
              fullWidth>
              <Box className={commonClasses.label} mb={1}>Provider Instructions (Optional)</Box>
              <TextField
                fullWidth
                size="small"
                InputProps={{className: 'input', classes}}
                id={'providerInstructions'}
                type="text"
                placeholder="Provider Instructions (Optional)"
                value={insuranceConfiguration.providerInstructions}
                variant="outlined"
                disabled={!handleChanges}
                onChange={(v) => handleChanges(v.target.id, v.target.value)}
              />
            </FormControl>
          </Box>
          <Box mb={2}>
            <FormControl
              variant="outlined"
              color='primary'
              size="small"
              classes={{root: classes.root}}
              fullWidth>
              <Box className={commonClasses.label} mb={1}>Patient Messaging (Optional)</Box>
              <TextField
                fullWidth
                size="small"
                InputProps={{className: 'input', classes}}
                id={'patientMessaging'}
                type="text"
                placeholder="Patient Messaging (Optional)"
                value={insuranceConfiguration.patientMessaging}
                variant="outlined"
                
                disabled={!handleChanges}
                onChange={(v) => handleChanges(v.target.id, v.target.value)}
              />
            </FormControl>
          </Box>
          <Box mb={2}>
            <Box className={commonClasses.label} mb={1}>Start Date</Box>
            <WildHealthDatePicker
              id={`startDate`}
              fullWidth
              size="small"
              disabled={!handleChanges}
              placeholder="yyyy-mm-dd"
              openTo="year"
              format="MM/DD/yyyy"
              value={insuranceConfiguration.startDate
                ? moment(insuranceConfiguration.startDate).format('YYYY-MM-DD')
                : null
              }
              inputVariant="outlined"
              views={["year", "month", "date"]}
              onChange={(momentDate) =>
                handleChanges(`startDate`, momentDate.format("YYYY-MM-DD")
              )}
            />
          </Box>
          <Box mb={2}>
            <Box className={commonClasses.label} mb={1}>End Date</Box>
              <WildHealthDatePicker
                id={`endDate`}
                fullWidth
                size="small"
                disabled={!handleChanges}
                placeholder="yyyy-mm-dd"
                openTo="year"
                format="MM/DD/yyyy"
                value={insuranceConfiguration.endDate
                  ? moment(insuranceConfiguration.endDate).format('YYYY-MM-DD')
                  : null
                }
                inputVariant="outlined"
                views={["year", "month", "date"]}
                onChange={(momentDate) =>
                  handleChanges(`endDate`, momentDate.format("YYYY-MM-DD")
                )}
              />
          </Box>
        </Box>
      </Collapse>
    )
  }

  const renderRTEConfigurationSection = () => {
    return (
      <Collapse in={mainConfigCollapse.rteConfig} timeout="auto" unmountOnExit>
        <Box p={3} className={commonClasses.bgWhiteMain}>
          <Box mb={2}>
            <FormControl
              variant="outlined"
              color='primary'
              required
              size="small"
              classes={{root: classes.root}}
              fullWidth>
              <Box className={commonClasses.label} mb={1}>NPI</Box>
              <TextField
                fullWidth
                size="small"
                required
                InputProps={{className: 'input', classes}}
                id={'npi'}
                type="text"
                placeholder="NPI"
                value={rteConfiguration.npi}
                variant="outlined"
                disabled={!handleChangeRTEConfigs}
                onChange={(v) => handleChangeRTEConfigs(v.target.id, v.target.value)}
              />
            </FormControl>
          </Box>
          <Box mb={2}>
            <FormControl
              variant="outlined"
              color='primary'
              required
              size="small"
              classes={{root: classes.root}}
              fullWidth>
              <Box className={commonClasses.label} mb={1}>Trading Partner Id</Box>
              <TextField
                fullWidth
                size="small"
                required
                InputProps={{className: 'input', classes}}
                id={'tradingPartnerId'}
                type="text"
                placeholder="Trading Partner Id"
                value={rteConfiguration.tradingPartnerId}
                variant="outlined"
                disabled={!handleChangeRTEConfigs}
                onChange={(v) => handleChangeRTEConfigs(v.target.id, v.target.value)}
              />
            </FormControl>
          </Box>
          <Box mb={2}>
            <FormControl
              variant="outlined"
              color='primary'
              required
              size="small"
              classes={{ root: classes.root }}
              fullWidth>
              <Box className={commonClasses.label} mb={1}>Require Group Number</Box>
              <Select
                style={{ background: '#FFF' }}
                id="requireGroupNumber"
                classes={{ root: classes.root }}
                value={rteConfiguration.requireGroupNumber ? "True" : "False"}
                inputProps={{
                  classes,
                }}
                onChange={(v) => handleChangeRTEConfigs("requireGroupNumber", v.target.value.toString() === 'False' ? false : true)}
              >
                <MenuItem value="False">False</MenuItem>
                <MenuItem value="True">True</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box mb={2}>
            <FormControl
              variant="outlined"
              color='primary'
              required
              size="small"
              classes={{ root: classes.root }}
              fullWidth>
              <Box className={commonClasses.label} mb={1}>Include CPT Code</Box>
              <Select
                style={{ background: '#FFF' }}
                id="includeCptCode"
                classes={{ root: classes.root }}
                value={rteConfiguration.includeCptCode ? "True" : "False"}
                inputProps={{
                  classes,
                }}
                onChange={(v) => handleChangeRTEConfigs("includeCptCode", v.target.value.toString() === 'False' ? false : true)}
              >
                <MenuItem value="False">False</MenuItem>
                <MenuItem value="True">True</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box mb={2}>
            <FormControl
              variant="outlined"
              color='primary'
              required
              size="small"
              classes={{root: classes.root}}
              fullWidth>
              <Box className={commonClasses.label} mb={1}>Failure Management</Box>
              <TextField
                fullWidth
                size="small"
                required
                InputProps={{className: 'input', classes}}
                id={'failureManagementType'}
                type="number"
                placeholder="Failure Management"
                value={rteConfiguration.failureManagementType}
                variant="outlined"
                disabled={!handleChangeRTEConfigs}
                onChange={(v) => handleChangeRTEConfigs(v.target.id, v.target.value)}
              />
            </FormControl>
          </Box>
          <Box mb={2}>
            <FormControl
              variant="outlined"
              color='primary'
              required
              size="small"
              classes={{root: classes.root}}
              fullWidth>
              <Box className={commonClasses.label} mb={1}>Member Id Validation Regex</Box>
              <TextField
                fullWidth
                size="small"
                required
                InputProps={{className: 'input', classes}}
                id={'memberIdValidationRegex'}
                type="number"
                placeholder="Member Id Validation Regex"
                value={rteConfiguration.memberIdValidationRegex}
                variant="outlined"
                disabled={!handleChangeRTEConfigs}
                onChange={(v) => handleChangeRTEConfigs(v.target.id, v.target.value)}
              />
            </FormControl>
          </Box>
        </Box>
      </Collapse>
    )
  }

  const renderClaimsAutoConfigurationSection = () => {
    return (
      <Collapse in={mainConfigCollapse.claimsAutoConfig} timeout="auto" unmountOnExit>
        <Box p={3} className={commonClasses.bgWhiteMain}>
          <Box mb={2}>
            <FormControl
              variant="outlined"
              color='primary'
              required
              size="small"
              classes={{ root: classes.root }}
              fullWidth>
              <Box className={commonClasses.label} mb={1}>Use Subscriber Name</Box>
              <Select
                style={{ background: '#FFF' }}
                id="useSubscriberName"
                classes={{ root: classes.root }}
                value={claimAutomationConfig.useSubscriberName ? "True" : "False"}
                inputProps={{
                  classes,
                }}
                onChange={(v) => handleChangeClaimAutomationConfigs("useSubscriberName", v.target.value.toString() === 'False' ? false : true)}
              >
                <MenuItem value="False">False</MenuItem>
                <MenuItem value="True">True</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box mb={2}>
            <FormControl
              variant="outlined"
              color='primary'
              required
              size="small"
              classes={{root: classes.root}}
              fullWidth>
              <Box className={commonClasses.label} mb={1}>Address Override - Street Address 1</Box>
              <TextField
                fullWidth
                size="small"
                required
                InputProps={{className: 'input', classes}}
                id={'streetAddress1'}
                type="text"
                placeholder="Address Override - Street Address 1"
                value={claimAutomationConfig.addressOverride.streetAddress1}
                variant="outlined"
                disabled={!handleChangeAddressOverrides}
                onChange={(v) => handleChangeAddressOverrides(v.target.id, v.target.value)}
              />
            </FormControl>
          </Box>
          <Box mb={2}>
            <FormControl
              variant="outlined"
              color='primary'
              required
              size="small"
              classes={{root: classes.root}}
              fullWidth>
              <Box className={commonClasses.label} mb={1}>Address Override - Street Address 2</Box>
              <TextField
                fullWidth
                size="small"
                required
                InputProps={{className: 'input', classes}}
                id={'streetAddress2'}
                type="text"
                placeholder="Address Override - Street Address 2"
                value={claimAutomationConfig.addressOverride.streetAddress2}
                variant="outlined"
                disabled={!handleChangeAddressOverrides}
                onChange={(v) => handleChangeAddressOverrides(v.target.id, v.target.value)}
              />
            </FormControl>
          </Box>
          <Box mb={2}>
            <FormControl
              variant="outlined"
              color='primary'
              required
              size="small"
              classes={{root: classes.root}}
              fullWidth>
              <Box className={commonClasses.label} mb={1}>Address Override - City</Box>
              <TextField
                fullWidth
                size="small"
                required
                InputProps={{className: 'input', classes}}
                id={'city'}
                type="text"
                placeholder="Address Override - City"
                value={claimAutomationConfig.addressOverride.city}
                variant="outlined"
                disabled={!handleChangeAddressOverrides}
                onChange={(v) => handleChangeAddressOverrides(v.target.id, v.target.value)}
              />
            </FormControl>
          </Box>
          <Box mb={2}>
            <FormControl
              variant="outlined"
              color='primary'
              required
              size="small"
              classes={{root: classes.root}}
              fullWidth>
              <Box className={commonClasses.label} mb={1}>Address Override - State</Box>
              <TextField
                fullWidth
                size="small"
                required
                InputProps={{className: 'input', classes}}
                id={'state'}
                type="text"
                placeholder="Address Override - State"
                value={claimAutomationConfig.addressOverride.state}
                variant="outlined"
                disabled={!handleChangeAddressOverrides}
                onChange={(v) => handleChangeAddressOverrides(v.target.id, v.target.value)}
              />
            </FormControl>
          </Box>
          <Box mb={2}>
            <FormControl
              variant="outlined"
              color='primary'
              required
              size="small"
              classes={{root: classes.root}}
              fullWidth>
              <Box className={commonClasses.label} mb={1}>Address Override - Zipcode</Box>
              <TextField
                fullWidth
                size="small"
                required
                InputProps={{className: 'input', classes}}
                id={'zipCode'}
                type="text"
                placeholder="Address Override - Zipcode"
                value={claimAutomationConfig.addressOverride.zipCode}
                variant="outlined"
                disabled={!handleChangeAddressOverrides}
                onChange={(v) => handleChangeAddressOverrides(v.target.id, v.target.value)}
              />
            </FormControl>
          </Box>
          <Box mb={2}>
            <FormControl
              variant="outlined"
              color='primary'
              required
              size="small"
              classes={{root: classes.root}}
              fullWidth>
              <Box className={commonClasses.label} mb={1}>Address Override - Country</Box>
              <TextField
                fullWidth
                size="small"
                required
                InputProps={{className: 'input', classes}}
                id={'country'}
                type="text"
                placeholder="Address Override - Country"
                value={claimAutomationConfig.addressOverride.country}
                variant="outlined"
                disabled={!handleChangeAddressOverrides}
                onChange={(v) => handleChangeAddressOverrides(v.target.id, v.target.value)}
              />
            </FormControl>
          </Box>
        </Box>
      </Collapse>
    )
  }

  const renderServiceConfigurations = () => {
    if (!serviceConfigurations || !serviceConfigurations.length) {
      return <></>;
    }
    
    return (
      <React.Fragment>
        {serviceConfigurations.map((config, index) => (
          <React.Fragment key={index}>
            <Box my={2} display="flex" alignItems="center" justifyContent="space-between">
              <Box display="flex" alignItems="center" className={commonClasses.pointer} gridGap={8} onClick={() => handleChangeBasicServiceConfigs('isOpen', !config.isOpen, index)}>
                <Box className={clsx(commonClasses.size20, commonClasses.textMedium, commonClasses.colorBlack)}>Service Configuration {index + 1}</Box>
                {config.isOpen ? <ExpandLess /> : <ExpandMore />}
              </Box>
            </Box>
            <Collapse in={config.isOpen} timeout="auto" unmountOnExit>
              <Box p={3} mb={serviceConfigurations.length - 1 !== index && 4} className={commonClasses.bgWhiteMain}>
                <Box p={1}>
                  <Box mb={2}>
                    <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      size="small"
                      classes={{root: classes.root}}
                      fullWidth>
                      <Box className={commonClasses.label} mb={1}>Label</Box>
                      <TextField
                        fullWidth
                        size="small"
                        required
                        InputProps={{className: 'input', classes}}
                        id={'label'}
                        type="text"
                        placeholder="Label"
                        value={config.label}
                        variant="outlined"
                        disabled={!handleChangeBasicServiceConfigs}
                        onChange={(v) => handleChangeBasicServiceConfigs(v.target.id, v.target.value, index)}
                      />
                    </FormControl>
                  </Box>
                  <Box mb={2}>
                    <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      size="small"
                      classes={{root: classes.root}}
                      fullWidth>
                      <Box className={commonClasses.label} mb={1}>Type</Box>
                      <Select
                        style={{ background: '#FFF' }}
                        id={'type'}
                        classes={{ root: classes.root }}
                        value={config.type}
                        inputProps={{
                          classes,
                        }}
                        onChange={(v) => handleChangeBasicServiceConfigs('type', v.target.value, index)}
                      >
                        <MenuItem value={InsuranceServiceType.ProviderVisit}>{insuranceServiceTypeLabel[InsuranceServiceType.ProviderVisit]}</MenuItem>
                        <MenuItem value={InsuranceServiceType.HealthCoachVisit}>{insuranceServiceTypeLabel[InsuranceServiceType.HealthCoachVisit]}</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box mb={2}>
                    <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      size="small"
                      classes={{root: classes.root}}
                      fullWidth>
                      <Box className={commonClasses.label} mb={1}>Fee</Box>
                      <TextField
                        fullWidth
                        size="small"
                        required
                        InputProps={{className: 'input', classes}}
                        id={'fee'}
                        type="number"
                        placeholder="Fee"
                        value={config.fee}
                        variant="outlined"
                        disabled={!handleChangeBasicServiceConfigs}
                        onChange={(v) => handleChangeBasicServiceConfigs(v.target.id, v.target.value, index)}
                      />
                    </FormControl>
                  </Box>
                  <Box mb={2}>
                    <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      size="small"
                      classes={{root: classes.root}}
                      fullWidth>
                      <Box className={commonClasses.label} mb={1}>Minimum Visit Length</Box>
                      <TextField
                        fullWidth
                        size="small"
                        required
                        InputProps={{className: 'input', classes}}
                        id={'minimumVisitLength'}
                        type="number"
                        placeholder="Minimum Visit Length"
                        value={config.minimumVisitLength}
                        variant="outlined"
                        disabled={!handleChangeBasicServiceConfigs}
                        onChange={(v) => handleChangeBasicServiceConfigs(v.target.id, v.target.value, index)}
                      />
                    </FormControl>
                  </Box>
                  <Box mb={2}>
                    <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      size="small"
                      classes={{root: classes.root}}
                      fullWidth>
                      <Box className={commonClasses.label} mb={1}>Maximum Visit Length</Box>
                      <TextField
                        fullWidth
                        size="small"
                        required
                        InputProps={{className: 'input', classes}}
                        id={'maximumVisitLength'}
                        type="number"
                        placeholder="Maximum Visit Length"
                        value={config.maximumVisitLength}
                        variant="outlined"
                        disabled={!handleChangeBasicServiceConfigs}
                        onChange={(v) => handleChangeBasicServiceConfigs(v.target.id, v.target.value, index)}
                      />
                    </FormControl>
                  </Box>
                  <Box mb={2}>
                    <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      size="small"
                      classes={{ root: classes.root }}
                      fullWidth>
                      <Box className={commonClasses.label} mb={1}>New Patients Only</Box>
                      <Select
                        style={{ background: '#FFF' }}
                        id="newPatientsOnly"
                        classes={{ root: classes.root }}
                        value={config.newPatientsOnly ? "True" : "False"}
                        inputProps={{
                          classes,
                        }}
                        onChange={(v) => handleChangeBasicServiceConfigs("newPatientsOnly", v.target.value.toString() === 'False' ? false : true, index)}
                      >
                        <MenuItem value="False">False</MenuItem>
                        <MenuItem value="True">True</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box mb={2}>
                    <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      size="small"
                      classes={{ root: classes.root }}
                      fullWidth>
                      <Box className={commonClasses.label} mb={1}>Supports Claims</Box>
                      <Select
                        style={{ background: '#FFF' }}
                        id="supportsClaims"
                        classes={{ root: classes.root }}
                        value={config.supportsClaims ? "True" : "False"}
                        inputProps={{
                          classes,
                        }}
                        onChange={(v) => handleChangeBasicServiceConfigs("supportsClaims", v.target.value.toString() === 'False' ? false : true, index)}
                      >
                        <MenuItem value="False">False</MenuItem>
                        <MenuItem value="True">True</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box mb={2}>
                    <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      size="small"
                      classes={{ root: classes.root }}
                      fullWidth>
                      <Box className={commonClasses.label} mb={1}>Supports Real Time Eligibility</Box>
                      <Select
                        style={{ background: '#FFF' }}
                        id="supportsRte"
                        classes={{ root: classes.root }}
                        value={config.supportsRte ? "True" : "False"}
                        inputProps={{
                          classes,
                        }}
                        onChange={(v) => handleChangeBasicServiceConfigs("supportsRte", v.target.value.toString() === 'False' ? false : true, index)}
                      >
                        <MenuItem value="False">False</MenuItem>
                        <MenuItem value="True">True</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Box p={1}>
                  <Box mb={2} className={clsx(commonClasses.size20, commonClasses.textMedium, commonClasses.colorBlack)}>Real Time Eligibility Submission Configuration</Box>
                  <Box mb={2}>
                    <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      size="small"
                      classes={{root: classes.root}}
                      fullWidth>
                      <Box className={commonClasses.label} mb={1}>Service Type Code</Box>
                      <TextField
                        fullWidth
                        size="small"
                        required
                        InputProps={{className: 'input', classes}}
                        id={'serviceTypeCode'}
                        type="text"
                        placeholder="Service Type Code"
                        value={config.rteSubmissionConfig.serviceTypeCode}
                        variant="outlined"
                        disabled={!handleChangeSubServiceConfigs}
                        onChange={(v) => handleChangeSubServiceConfigs(v.target.id, v.target.value, 'rteSubmissionConfig', index)}
                      />
                    </FormControl>
                  </Box>
                  <Box mb={2}>
                    <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      size="small"
                      classes={{root: classes.root}}
                      fullWidth>
                      <Box className={commonClasses.label} mb={1}>Payer Override</Box>
                      <TextField
                        fullWidth
                        size="small"
                        required
                        InputProps={{className: 'input', classes}}
                        id={'payerOverride'}
                        type="text"
                        placeholder="Payer Override"
                        value={config.rteSubmissionConfig.payerOverride}
                        variant="outlined"
                        disabled={!handleChangeSubServiceConfigs}
                        onChange={(v) => handleChangeSubServiceConfigs(v.target.id, v.target.value, 'rteSubmissionConfig', index)}
                      />
                    </FormControl>
                  </Box>
                </Box>
                <Box p={1}>
                  <Box mb={2} className={clsx(commonClasses.size20, commonClasses.textMedium, commonClasses.colorBlack)}>Real Time Eligibility Response Configuration</Box>
                  <Box mb={2}>
                    <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      size="small"
                      classes={{ root: classes.root }}
                      fullWidth>
                      <Box className={commonClasses.label} mb={1}>Use 271 Price</Box>
                      <Select
                        style={{ background: '#FFF' }}
                        id="use271Price"
                        classes={{ root: classes.root }}
                        value={config.rteResponseConfig.use271Price ? "True" : "False"}
                        inputProps={{
                          classes,
                        }}
                        onChange={(v) => handleChangeSubServiceConfigs("use271Price", v.target.value.toString() === 'False' ? false : true, 'rteResponseConfig', index)}
                      >
                        <MenuItem value="False">False</MenuItem>
                        <MenuItem value="True">True</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box mb={2}>
                    <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      size="small"
                      classes={{root: classes.root}}
                      fullWidth>
                      <Box className={commonClasses.label} mb={1}>Pre-deductible Subsidy</Box>
                      <TextField
                        fullWidth
                        size="small"
                        required
                        InputProps={{className: 'input', classes}}
                        id={'preDeductibleSubsidy'}
                        type="number"
                        placeholder="Pre-deductible Subsidy"
                        value={config.rteResponseConfig.preDeductibleSubsidy}
                        variant="outlined"
                        disabled={!handleChangeSubServiceConfigs}
                        onChange={(v) => handleChangeSubServiceConfigs(v.target.id, v.target.value, 'rteResponseConfig', index)}
                      />
                    </FormControl>
                  </Box>
                  <Box mb={2}>
                    <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      size="small"
                      classes={{root: classes.root}}
                      fullWidth>
                      <Box className={commonClasses.label} mb={1}>Post-deductible Subsidy</Box>
                      <TextField
                        fullWidth
                        size="small"
                        required
                        InputProps={{className: 'input', classes}}
                        id={'postDeductibleSubsidy'}
                        type="number"
                        placeholder="Post-deductible Subsidy"
                        value={config.rteResponseConfig.postDeductibleSubsidy}
                        variant="outlined"
                        disabled={!handleChangeSubServiceConfigs}
                        onChange={(v) => handleChangeSubServiceConfigs(v.target.id, v.target.value, 'rteResponseConfig', index)}
                      />
                    </FormControl>
                  </Box>
                  <Box mb={2}>
                    <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      size="small"
                      classes={{root: classes.root}}
                      fullWidth>
                      <Box className={commonClasses.label} mb={1}>Pre-deductible Organization Subsidy</Box>
                      <TextField
                        fullWidth
                        size="small"
                        required
                        InputProps={{className: 'input', classes}}
                        id={'preDeductibleOrganizationSubsidy'}
                        type="number"
                        placeholder="Pre-deductible Organization Subsidy"
                        value={config.rteResponseConfig.preDeductibleOrganizationSubsidy}
                        variant="outlined"
                        disabled={!handleChangeSubServiceConfigs}
                        onChange={(v) => handleChangeSubServiceConfigs(v.target.id, v.target.value, 'rteResponseConfig', index)}
                      />
                    </FormControl>
                  </Box>
                  <Box mb={2}>
                    <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      size="small"
                      classes={{root: classes.root}}
                      fullWidth>
                      <Box className={commonClasses.label} mb={1}>Post-deductible Organization Subsidy</Box>
                      <TextField
                        fullWidth
                        size="small"
                        required
                        InputProps={{className: 'input', classes}}
                        id={'postDeductibleOrganizationSubsidy'}
                        type="number"
                        placeholder="Post-deductible Organization Subsidy"
                        value={config.rteResponseConfig.postDeductibleOrganizationSubsidy}
                        variant="outlined"
                        disabled={!handleChangeSubServiceConfigs}
                        onChange={(v) => handleChangeSubServiceConfigs(v.target.id, v.target.value, 'rteResponseConfig', index)}
                      />
                    </FormControl>
                  </Box>
                  <Box mb={2}>
                    <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      size="small"
                      classes={{ root: classes.root }}
                      fullWidth>
                      <Box className={commonClasses.label} mb={1}>Use Out Of Pocket Maximum</Box>
                      <Select
                        style={{ background: '#FFF' }}
                        id="useOutOfPocketMaximum"
                        classes={{ root: classes.root }}
                        value={config.rteResponseConfig.useOutOfPocketMaximum ? "True" : "False"}
                        inputProps={{
                          classes,
                        }}
                        onChange={(v) => handleChangeSubServiceConfigs("useOutOfPocketMaximum", v.target.value.toString() === 'False' ? false : true, 'rteResponseConfig', index)}
                      >
                        <MenuItem value="False">False</MenuItem>
                        <MenuItem value="True">True</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box mb={2}>
                    <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      size="small"
                      classes={{ root: classes.root }}
                      fullWidth>
                      <Box className={commonClasses.label} mb={1}>Allow Zero Percent CoInsurance</Box>
                      <Select
                        style={{ background: '#FFF' }}
                        id="allowZeroPercentCoinsurance"
                        classes={{ root: classes.root }}
                        value={config.rteResponseConfig.allowZeroPercentCoinsurance ? "True" : "False"}
                        inputProps={{
                          classes,
                        }}
                        onChange={(v) => handleChangeSubServiceConfigs("allowZeroPercentCoinsurance", v.target.value.toString() === 'False' ? false : true, 'rteResponseConfig', index)}
                      >
                        <MenuItem value="False">False</MenuItem>
                        <MenuItem value="True">True</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box mb={2}>
                    <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      size="small"
                      classes={{root: classes.root}}
                      fullWidth>
                      <Box className={commonClasses.label} mb={1}>Response Service Types</Box>
                      <TextField
                        fullWidth
                        size="small"
                        required
                        InputProps={{className: 'input', classes}}
                        id={'responseServiceTypes'}
                        type="text"
                        placeholder="Response Service Types"
                        value={config.rteResponseConfig.responseServiceTypes}
                        variant="outlined"
                        disabled={!handleChangeSubServiceConfigs}
                        onChange={(v) => handleChangeSubServiceConfigs(v.target.id, v.target.value, 'rteResponseConfig', index)}
                      />
                    </FormControl>
                  </Box>
                  <Box mb={2}>
                    <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      size="small"
                      classes={{root: classes.root}}
                      fullWidth>
                      <Box className={commonClasses.label} mb={1}>Copay Pre Deductible Regex</Box>
                      <TextField
                        fullWidth
                        size="small"
                        required
                        InputProps={{className: 'input', classes}}
                        id={'copayPreDeductibleRegex'}
                        type="text"
                        placeholder="Copay Pre Deductible Regex"
                        value={config.rteResponseConfig.copayPreDeductibleRegex}
                        variant="outlined"
                        disabled={!handleChangeSubServiceConfigs}
                        onChange={(v) => handleChangeSubServiceConfigs(v.target.id, v.target.value, 'rteResponseConfig', index)}
                      />
                    </FormControl>
                  </Box>
                  <Box mb={2}>
                    <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      size="small"
                      classes={{root: classes.root}}
                      fullWidth>
                      <Box className={commonClasses.label} mb={1}>Copay Post Deductible Regex</Box>
                      <TextField
                        fullWidth
                        size="small"
                        required
                        InputProps={{className: 'input', classes}}
                        id={'copayPostDeductibleRegex'}
                        type="text"
                        placeholder="Copay Post Deductible Regex"
                        value={config.rteResponseConfig.copayPostDeductibleRegex}
                        variant="outlined"
                        disabled={!handleChangeSubServiceConfigs}
                        onChange={(v) => handleChangeSubServiceConfigs(v.target.id, v.target.value, 'rteResponseConfig', index)}
                      />
                    </FormControl>
                  </Box>
                  <Box mb={2}>
                    <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      size="small"
                      classes={{root: classes.root}}
                      fullWidth>
                      <Box className={commonClasses.label} mb={1}>Determine Deductible Regex</Box>
                      <TextField
                        fullWidth
                        size="small"
                        required
                        InputProps={{className: 'input', classes}}
                        id={'determineDeductibleRegex'}
                        type="text"
                        placeholder="Determine Deductible Regex"
                        value={config.rteResponseConfig.determineDeductibleRegex}
                        variant="outlined"
                        disabled={!handleChangeSubServiceConfigs}
                        onChange={(v) => handleChangeSubServiceConfigs(v.target.id, v.target.value, 'rteResponseConfig', index)}
                      />
                    </FormControl>
                  </Box>
                  <Box mb={2}>
                    <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      size="small"
                      classes={{root: classes.root}}
                      fullWidth>
                      <Box className={commonClasses.label} mb={1}>Determine Copay Regex</Box>
                      <TextField
                        fullWidth
                        size="small"
                        required
                        InputProps={{className: 'input', classes}}
                        id={'determineCopayRegex'}
                        type="text"
                        placeholder="Determine Copay Regex"
                        value={config.rteResponseConfig.determineCopayRegex}
                        variant="outlined"
                        disabled={!handleChangeSubServiceConfigs}
                        onChange={(v) => handleChangeSubServiceConfigs(v.target.id, v.target.value, 'rteResponseConfig', index)}
                      />
                    </FormControl>
                  </Box>
                  <Box mb={2}>
                    <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      size="small"
                      classes={{root: classes.root}}
                      fullWidth>
                      <Box className={commonClasses.label} mb={1}>Determine Coinsurance Regex</Box>
                      <TextField
                        fullWidth
                        size="small"
                        required
                        InputProps={{className: 'input', classes}}
                        id={'determineCoinsuranceRegex'}
                        type="text"
                        placeholder="Determine Coinsurance Regex"
                        value={config.rteResponseConfig.determineCoinsuranceRegex}
                        variant="outlined"
                        disabled={!handleChangeSubServiceConfigs}
                        onChange={(v) => handleChangeSubServiceConfigs(v.target.id, v.target.value, 'rteResponseConfig', index)}
                      />
                    </FormControl>
                  </Box>
                  <Box mb={2}>
                    <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      size="small"
                      classes={{ root: classes.root }}
                      fullWidth>
                      <Box className={commonClasses.label} mb={1}>Always Apply Copay After Deductible</Box>
                      <Select
                        style={{ background: '#FFF' }}
                        id="alwaysApplyCopayAfterDeductible"
                        classes={{ root: classes.root }}
                        value={config.rteResponseConfig.alwaysApplyCopayAfterDeductible ? "True" : "False"}
                        inputProps={{
                          classes,
                        }}
                        onChange={(v) => handleChangeSubServiceConfigs("alwaysApplyCopayAfterDeductible", v.target.value.toString() === 'False' ? false : true, 'rteResponseConfig', index)}
                      >
                        <MenuItem value="False">False</MenuItem>
                        <MenuItem value="True">True</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box mb={2}>
                    <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      size="small"
                      classes={{ root: classes.root }}
                      fullWidth>
                      <Box className={commonClasses.label} mb={1}>Non Covered Exclusions</Box>
                      <Select
                        style={{ background: '#FFF' }}
                        id="nonCoveredExclusions"
                        classes={{ root: classes.root }}
                        value={config.rteResponseConfig.nonCoveredExclusions ? "True" : "False"}
                        inputProps={{
                          classes,
                        }}
                        onChange={(v) => handleChangeSubServiceConfigs("nonCoveredExclusions", v.target.value.toString() === 'False' ? false : true, 'rteResponseConfig', index)}
                      >
                        <MenuItem value="False">False</MenuItem>
                        <MenuItem value="True">True</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box mb={2}>
                    <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      size="small"
                      classes={{root: classes.root}}
                      fullWidth>
                      <Box className={commonClasses.label} mb={1}>Include Eligibility Message Regex</Box>
                      <TextField
                        fullWidth
                        size="small"
                        required
                        InputProps={{className: 'input', classes}}
                        id={'includeEligibilityMessageRegex'}
                        type="text"
                        placeholder="Include Eligibility Message Regex"
                        value={config.rteResponseConfig.includeEligibilityMessageRegex}
                        variant="outlined"
                        disabled={!handleChangeSubServiceConfigs}
                        onChange={(v) => handleChangeSubServiceConfigs(v.target.id, v.target.value, 'rteResponseConfig', index)}
                      />
                    </FormControl>
                  </Box>
                  <Box mb={2}>
                    <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      size="small"
                      classes={{root: classes.root}}
                      fullWidth>
                      <Box className={commonClasses.label} mb={1}>Exclude Eligibility Message Regex</Box>
                      <TextField
                        fullWidth
                        size="small"
                        required
                        InputProps={{className: 'input', classes}}
                        id={'excludeEligibilityMessageRegex'}
                        type="text"
                        placeholder="Exclude Eligibility Message Regex"
                        value={config.rteResponseConfig.excludeEligibilityMessageRegex}
                        variant="outlined"
                        disabled={!handleChangeSubServiceConfigs}
                        onChange={(v) => handleChangeSubServiceConfigs(v.target.id, v.target.value, 'rteResponseConfig', index)}
                      />
                    </FormControl>
                  </Box>
                  <Box mb={2}>
                    <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      size="small"
                      classes={{root: classes.root}}
                      fullWidth>
                      <Box className={commonClasses.label} mb={1}>Group Id Regex</Box>
                      <TextField
                        fullWidth
                        size="small"
                        required
                        InputProps={{className: 'input', classes}}
                        id={'groupIdRegex'}
                        type="text"
                        placeholder="Group Id Regex"
                        value={config.rteResponseConfig.groupIdRegex}
                        variant="outlined"
                        disabled={!handleChangeSubServiceConfigs}
                        onChange={(v) => handleChangeSubServiceConfigs(v.target.id, v.target.value, 'rteResponseConfig', index)}
                      />
                    </FormControl>
                  </Box>
                  <Box mb={2}>
                    <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      size="small"
                      classes={{root: classes.root}}
                      fullWidth>
                      <Box className={commonClasses.label} mb={1}>Eligible Group Numbers</Box>
                      <TextField
                        fullWidth
                        size="small"
                        required
                        InputProps={{className: 'input', classes}}
                        id={'eligibleGroupNumbers'}
                        type="text"
                        placeholder="Eligible Group Numbers"
                        value={config.rteResponseConfig.eligibleGroupNumbers}
                        variant="outlined"
                        disabled={!handleChangeSubServiceConfigs}
                        onChange={(v) => handleChangeSubServiceConfigs(v.target.id, v.target.value, 'rteResponseConfig', index)}
                      />
                    </FormControl>
                  </Box>
                  <Box mb={2}>
                    <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      size="small"
                      classes={{ root: classes.root }}
                      fullWidth>
                      <Box className={commonClasses.label} mb={1}>Reject If No Service Type Benefit</Box>
                      <Select
                        style={{ background: '#FFF' }}
                        id="rejectIfNoServiceTypeBenefit"
                        classes={{ root: classes.root }}
                        value={config.rteResponseConfig.rejectIfNoServiceTypeBenefit ? "True" : "False"}
                        inputProps={{
                          classes,
                        }}
                        onChange={(v) => handleChangeSubServiceConfigs("rejectIfNoServiceTypeBenefit", v.target.value.toString() === 'False' ? false : true, 'rteResponseConfig', index)}
                      >
                        <MenuItem value="False">False</MenuItem>
                        <MenuItem value="True">True</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box mb={2}>
                    <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      size="small"
                      classes={{root: classes.root}}
                      fullWidth>
                      <Box className={commonClasses.label} mb={1}>Block Plan Descriptions</Box>
                      <TextField
                        fullWidth
                        size="small"
                        required
                        InputProps={{className: 'input', classes}}
                        id={'blockPlanDescriptions'}
                        type="text"
                        placeholder="Block Plan Descriptions"
                        value={config.rteResponseConfig.blockPlanDescriptions}
                        variant="outlined"
                        disabled={!handleChangeSubServiceConfigs}
                        onChange={(v) => handleChangeSubServiceConfigs(v.target.id, v.target.value, 'rteResponseConfig', index)}
                      />
                    </FormControl>
                  </Box>
                  <Box mb={2}>
                    <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      size="small"
                      classes={{root: classes.root}}
                      fullWidth>
                      <Box className={commonClasses.label} mb={1}>Block Insurance Types</Box>
                      <TextField
                        fullWidth
                        size="small"
                        required
                        InputProps={{className: 'input', classes}}
                        id={'blockInsurancePlans'}
                        type="text"
                        placeholder="Block Insurance Types"
                        value={config.rteResponseConfig.blockInsurancePlans}
                        variant="outlined"
                        disabled={!handleChangeSubServiceConfigs}
                        onChange={(v) => handleChangeSubServiceConfigs(v.target.id, v.target.value, 'rteResponseConfig', index)}
                      />
                    </FormControl>
                  </Box>
                  <Box mb={2}>
                    <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      size="small"
                      classes={{ root: classes.root }}
                      fullWidth>
                      <Box className={commonClasses.label} mb={1}>Allow Zero Dollar Deductible</Box>
                      <Select
                        style={{ background: '#FFF' }}
                        id="allowZeroDollarDeductible"
                        classes={{ root: classes.root }}
                        value={config.rteResponseConfig.allowZeroDollarDeductible ? "True" : "False"}
                        inputProps={{
                          classes,
                        }}
                        onChange={(v) => handleChangeSubServiceConfigs("allowZeroDollarDeductible", v.target.value.toString() === 'False' ? false : true, 'rteResponseConfig', index)}
                      >
                        <MenuItem value="False">False</MenuItem>
                        <MenuItem value="True">True</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box mb={2}>
                    <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      size="small"
                      classes={{ root: classes.root }}
                      fullWidth>
                      <Box className={commonClasses.label} mb={1}>Allow Zero Dollar Copay</Box>
                      <Select
                        style={{ background: '#FFF' }}
                        id="allowZeroDollarCopay"
                        classes={{ root: classes.root }}
                        value={config.rteResponseConfig.allowZeroDollarCopay ? "True" : "False"}
                        inputProps={{
                          classes,
                        }}
                        onChange={(v) => handleChangeSubServiceConfigs("allowZeroDollarCopay", v.target.value.toString() === 'False' ? false : true, 'rteResponseConfig', index)}
                      >
                        <MenuItem value="False">False</MenuItem>
                        <MenuItem value="True">True</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Box p={1}>
                  <Box mb={2} className={clsx(commonClasses.size20, commonClasses.textMedium, commonClasses.colorBlack)}>Claim Submission Configuration</Box>
                  <Box mb={2}>
                    <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      size="small"
                      classes={{root: classes.root}}
                      fullWidth>
                      <Box className={commonClasses.label} mb={1}>Claims Partner Id</Box>
                      <TextField
                        fullWidth
                        size="small"
                        required
                        InputProps={{className: 'input', classes}}
                        id={'claimsPartnerId'}
                        type="text"
                        placeholder="Claims Partner Id"
                        value={config.claimSubmissionConfig.claimsPartnerId}
                        variant="outlined"
                        disabled={!handleChangeSubServiceConfigs}
                        onChange={(v) => handleChangeSubServiceConfigs(v.target.id, v.target.value, 'claimSubmissionConfig', index)}
                      />
                    </FormControl>
                  </Box>
                  <Box mb={2}>
                    <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      size="small"
                      classes={{root: classes.root}}
                      fullWidth>
                      <Box className={commonClasses.label} mb={1}>CPT Code Override</Box>
                      <TextField
                        fullWidth
                        size="small"
                        required
                        InputProps={{className: 'input', classes}}
                        id={'cptCode'}
                        type="text"
                        placeholder="CPT Code Override"
                        value={config.claimSubmissionConfig.cptCode}
                        variant="outlined"
                        disabled={!handleChangeSubServiceConfigs}
                        onChange={(v) => handleChangeSubServiceConfigs(v.target.id, v.target.value, 'claimSubmissionConfig', index)}
                      />
                    </FormControl>
                  </Box>
                  <Box mb={2}>
                    <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      size="small"
                      classes={{root: classes.root}}
                      fullWidth>
                      <Box className={commonClasses.label} mb={1}>Modifier</Box>
                      <TextField
                        fullWidth
                        size="small"
                        required
                        InputProps={{className: 'input', classes}}
                        id={'modifier'}
                        type="text"
                        placeholder="Modifier"
                        value={config.claimSubmissionConfig.modifier}
                        variant="outlined"
                        disabled={!handleChangeSubServiceConfigs}
                        onChange={(v) => handleChangeSubServiceConfigs(v.target.id, v.target.value, 'claimSubmissionConfig', index)}
                      />
                    </FormControl>
                  </Box>
                  <Box mb={2}>
                    <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      size="small"
                      classes={{ root: classes.root }}
                      fullWidth>
                      <Box className={commonClasses.label} mb={1}>Lock Code Entries</Box>
                      <Select
                        style={{ background: '#FFF' }}
                        id="lockCodeEntries"
                        classes={{ root: classes.root }}
                        value={config.claimSubmissionConfig.lockCodeEntries ? "True" : "False"}
                        inputProps={{
                          classes,
                        }}
                        onChange={(v) => handleChangeSubServiceConfigs("lockCodeEntries", v.target.value.toString() === 'False' ? false : true, 'claimSubmissionConfig', index)}
                      >
                        <MenuItem value="False">False</MenuItem>
                        <MenuItem value="True">True</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box mb={2}>
                    <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      size="small"
                      classes={{root: classes.root}}
                      fullWidth>
                      <Box className={commonClasses.label} mb={1}>Place Of Service</Box>
                      <TextField
                        fullWidth
                        size="small"
                        required
                        InputProps={{className: 'input', classes}}
                        id={'placeOfService'}
                        type="text"
                        placeholder="Place Of Service"
                        value={config.claimSubmissionConfig.placeOfService}
                        variant="outlined"
                        disabled={!handleChangeSubServiceConfigs}
                        onChange={(v) => handleChangeSubServiceConfigs(v.target.id, v.target.value, 'claimSubmissionConfig', index)}
                      />
                    </FormControl>
                  </Box>
                  <Box mb={2}>
                    <FormControl
                      variant="outlined"
                      color='primary'
                      required
                      size="small"
                      classes={{ root: classes.root }}
                      fullWidth>
                      <Box className={commonClasses.label} mb={1}>Include Rendering Provider Information</Box>
                      <Select
                        style={{ background: '#FFF' }}
                        id="includeRenderingProviderInformation"
                        classes={{ root: classes.root }}
                        value={config.claimSubmissionConfig.includeRenderingProviderInformation ? "True" : "False"}
                        inputProps={{
                          classes,
                        }}
                        onChange={(v) => handleChangeSubServiceConfigs("includeRenderingProviderInformation", v.target.value.toString() === 'False' ? false : true, 'claimSubmissionConfig', index)}
                      >
                        <MenuItem value="False">False</MenuItem>
                        <MenuItem value="True">True</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Box p={1}>
                  <Box mb={2} className={clsx(commonClasses.size20, commonClasses.textMedium, commonClasses.colorBlack)}>States Configuration</Box>
                  <Autocomplete
                    size='small'
                    disableClearable
                    multiple
                    disabled={isStateLoading || isServiceConfigUpdating}
                    options={addressQuery.getStates().filter(x => !config.stateIds.some(t => t === x.id))}
                    getOptionLabel={x => x.name}
                    onChange={(e, values) => handleServiceConfigMultiSelectChange(values, index, 'stateIds')}
                    value={addressQuery.getStates().filter(x => config.stateIds.some(t => t === x.id))}
                    renderTags={(value: StateModel[], getTagProps) =>
                      value.map((option: StateModel, index: number) => (
                        <Chip label={option.name} classes={{root: classes.chip}} deleteIcon={<CloseIcon />} {...getTagProps({ index })} />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        InputProps={{ ...params.InputProps, type: 'search' }}
                        placeholder={config.stateIds.length ? '' : 'Select states'}
                      />
                    )}
                  />
                </Box>
                <Box p={1}>
                    <Box mb={2} className={clsx(commonClasses.size20, commonClasses.textMedium, commonClasses.colorBlack)}>Insurance Plan Configuration</Box>
                    <Autocomplete
                      size='small'
                      disableClearable
                      multiple
                      disabled={isStateLoading || isServiceConfigUpdating}
                      options={allInsurancePlans.filter(x => !config.insurancePlanIds.some(t => t === x.id))}
                      getOptionLabel={x => x.name}
                      onChange={(e, values) => handleServiceConfigMultiSelectChange(values, index, 'insurancePlanIds')}
                      value={allInsurancePlans.filter(x => config.insurancePlanIds.some(t => t === x.id))}
                      renderTags={(value: InsurancePlanModel[], getTagProps) =>
                        value.map((option: InsurancePlanModel, index: number) => (
                          <Chip label={option.name} classes={{root: classes.chip}} deleteIcon={<CloseIcon />} {...getTagProps({ index })} />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          InputProps={{ ...params.InputProps, type: 'search' }}
                          placeholder={config.insurancePlanIds.length ? '' : 'Select insurance plans'}
                        />
                      )}
                    />
                  </Box>
                <Box px={1} mt={2} display="flex" alignItems="center" justifyContent="space-between">
                  <Box width="132px">
                    <WildHealthButton
                      id="add-insurance-button"
                      disabled={isServiceConfigUpdating}
                      loading={isLoading || isServiceConfigUpdating}
                      fullWidth
                      style={{ height: 48 }}
                      onClick={() => handleUpdateServiceConfiguration(index)}
                    >
                      <Box className={clsx(commonClasses.size16, commonClasses.textMedium)}>Save</Box>
                    </WildHealthButton>
                  </Box>
                  <Box width="132px">
                    <WildHealthButton
                      id="add-insurance-button"
                      disabled={isServiceConfigUpdating}
                      loading={isLoading || isServiceConfigUpdating}
                      fullWidth
                      style={{ height: 48 }}
                      onClick={() => handleRemoveServiceconfig(index)}
                    >
                      <Box className={clsx(commonClasses.size16, commonClasses.textMedium)}>Remove</Box>
                    </WildHealthButton>
                  </Box>
                </Box>
              </Box>
            </Collapse>
          </React.Fragment>
        ))}
      </React.Fragment>
    )
  }

  return (
    <Box py={2} px={10} width={1}>
      <Box my={2} display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <FeatureComponent featureFlag={FeatureFlag.Navigation}>
            <Box className={clsx(commonClasses.size24, commonClasses.textSemiBold, commonClasses.colorBlack)}>{insuranceConfiguration.insurance.name} - Configuration</Box>
          </FeatureComponent>
        </Box>
      </Box>
      <Box my={2} display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" className={commonClasses.pointer} gridGap={8} onClick={() => handleMainConfigToggle('basicConfig', !mainConfigCollapse.basicConfig)}>
          <Box className={clsx(commonClasses.size20, commonClasses.textMedium, commonClasses.colorBlack)}>Basic Configuration</Box>
          {mainConfigCollapse.basicConfig ? <ExpandLess /> : <ExpandMore />}
        </Box>
        
        <Box width="132px">
          <WildHealthButton
            id="add-insurance-button"
            disabled={isUpdating}
            loading={isLoading || isUpdating}
            onClick={handleUpdateInsuranceConfiguration}
            fullWidth
            style={{ height: 48 }}
          >
            <Box className={clsx(commonClasses.size16, commonClasses.textMedium)}>Save</Box>
          </WildHealthButton>
        </Box>
      </Box>
      {renderBasicConfigurationSection()}
      
      <Box my={2} display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" className={commonClasses.pointer} gridGap={8} onClick={() => handleMainConfigToggle('rteConfig', !mainConfigCollapse.rteConfig)}>
          <Box className={clsx(commonClasses.size20, commonClasses.textMedium, commonClasses.colorBlack)}>Real Time Eligibility Configuration</Box>
          {mainConfigCollapse.rteConfig ? <ExpandLess /> : <ExpandMore />}
        </Box>
      </Box>
      {renderRTEConfigurationSection()}
      
      <Box my={2} display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" className={commonClasses.pointer} gridGap={8} onClick={() => handleMainConfigToggle('claimsAutoConfig', !mainConfigCollapse.claimsAutoConfig)}>
          <Box className={clsx(commonClasses.size20, commonClasses.textMedium, commonClasses.colorBlack)}>Claims Automation Configuration</Box>
          {mainConfigCollapse.claimsAutoConfig ? <ExpandLess /> : <ExpandMore />}
        </Box>
      </Box>
      {renderClaimsAutoConfigurationSection()}
      
      <Box my={2} display="flex" alignItems="center">
        <Box>
          <Box className={clsx(commonClasses.size20, commonClasses.textMedium, commonClasses.colorBlack)}>Services Configuration</Box>
        </Box>
        
        <Box ml={3}>
          <button id="add-service-config-button" className={classes.addBtn} disabled={isServiceConfigUpdating} onClick={() => handleAddServiceConfig()}>
            <AddIcon />
          </button>
        </Box>
      </Box>
      {renderServiceConfigurations()}
    </Box>
  );
}