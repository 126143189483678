import React from "react";
import { Box } from "@material-ui/core";

export interface WildHealthPlaceHolderProps {
    message: string;
    height?: string;
}

export const WildHealthPlaceHolder: React.FC<WildHealthPlaceHolderProps> = (props: WildHealthPlaceHolderProps) => {
    const {
        message,
        height
    } = props;

    return (
        <Box height={height ?? '200px'} display="flex" justifyContent="center" alignItems="center" style={{ background: '#e3e3e3', width: '100%', borderRadius: '5px', padding: 16, textAlign: "center" }}>
            <span style={{ fontSize: '22px', color: '#acacac' }}>
                {message}
            </span>
        </Box>
    );
};