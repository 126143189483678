import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { CssBaseline, Toolbar, Box, useMediaQuery, useTheme, IconButton } from "@material-ui/core";
import { MenuItemTypes } from '../../models/menu.models';
import { WildHealthContainer } from '../../modules/common/components/wildHealthContainer/WildHealthContainer';
import { isFeatureFlag, anyFlags } from '../../modules/common/components/featureFlags/featureFlags';
import { FeatureFlag } from '../../modules/common/components/featureFlags/featureFlags.models';
import { AuthenticatedHeaderComponent } from '../authenticatedHeaderComponent/AuthenticatedHeaderComponent';
import { AuthenticatedHeaderNavbarComponent } from '../authenticatedHeaderNavbarComponent/AuthenticatedHeaderNavbarComponent';
import { AuthenticatedMobileHeaderComponent } from '../authenticatedMobileHeaderComponent/AuthenticatedMobileHeaderComponent';
import { SideBarComponent } from '../sideBarComponent/SideBarComponent';
import { useFacade } from './authenticatedLayoutComponent.hooks';
import { useStyles } from './authenticatedLayoutComponent.styles';
import { FooterComponent } from "../footerComponent/FooterComponent";
import { RoutesConstants } from "../../constants/route.constants";
import { authQuery } from "../../modules/auth/stores/auth";
import { useFlags } from 'launchdarkly-react-client-sdk';
import CloseIcon from '@material-ui/icons/Close';
import { colors } from "../../modules/common/constants/colors";
import { TextLinkMarkdownComponent } from "../../modules/notes/components/textMarkdownComponent/TextMarkdownComponent";
import clsx from "clsx";

interface LayoutComponentProps {
  title: string;
  pageName?: JSX.Element | string;
  selectedMenuItem?: MenuItemTypes;
  hideBackground?: boolean;
  contentMargin?: number | string;
  displayChildren?: any;
  children: React.ReactNode;
}

export const AuthenticatedLayoutComponent: React.FC<LayoutComponentProps> = (
  props: LayoutComponentProps
) => {
  const {
    title,
    pageName,
    selectedMenuItem,
    hideBackground,
    contentMargin,
    displayChildren,
    children,
  } = props;
  const [
    {
      isSideBarOpen,
      bannerHeight,
      isDelinquent,
      isAgreementsChanged,
      agreementsChanges,
      topBanners,
      delinquentStatus
    },
    prevScrollY,
    deactivateBanner,
    handleCloseBanner,
    handleGoBillingInfo,
    handleCloseDelinquentBanner
  ] = useFacade();

  const { pathname } = useLocation();
  const mobilePath =
    pathname.toLowerCase().includes(RoutesConstants.mobileGetStarted) ||
    pathname.toLowerCase().includes(RoutesConstants.mobileBuySubscriptionSuccess);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const classes = useStyles();
  const featureFlags = useFlags();
  const isTopNavigation = isFeatureFlag(featureFlags, FeatureFlag.Navigation);
  const isCloseDelinquent = window.sessionStorage.getItem('delinquent') === 'close'

  if (!anyFlags(featureFlags)) return <></>;

  return (
    <>
      {authQuery.isPatientUser() && (
        <Helmet>
          {/* <!-- Implement Smart App Banner --> */}
          <meta name="apple-itunes-app" content="app-id=1561666113, app-argument=https://app.wildhealth.com/messaging/?source=sms_notification" />
          <meta name="apple-itunes-app" content="app-id=1561666113, app-argument=https://wh-develop.wildhealth.com/messaging/?source=sms_notification" />
          <meta name="apple-itunes-app" content="app-id=1561666113, app-argument=https://appstaging1.wildhealth.com/messaging/?source=sms_notification" />
          <meta name="google-play-app" content="app-id=com.wildhealth, app-argument=https://app.wildhealth.com/messaging/?source=sms_notification" />
          <meta name="google-play-app" content="app-id=com.wildhealth, app-argument=https://wh-develop.wildhealth.com/messaging/?source=sms_notification" />
          <meta name="google-play-app" content="app-id=com.wildhealth, app-argument=https://appstaging1.wildhealth.com/messaging/?source=sms_notification" />
        </Helmet>
      )}
      <div ref={prevScrollY}>
        {topBanners && topBanners.length > 0 && (
          <Box className={classes.banner} display='flex' alignItems="center">
            <Box flex={1}>
              <TextLinkMarkdownComponent content={topBanners[0].copy} classLink={classes.bannerLinkUrl} />
            </Box>
            <Box ml={1}>
              <IconButton id="banner-notifying-close" onClick={() => handleCloseBanner(topBanners[0].id)}>
                <CloseIcon style={{ fill: colors.white }} />
              </IconButton>
            </Box>
          </Box>
        )}
        {isDelinquent && !isCloseDelinquent && (
          <Box className={clsx(classes.banner, "wh-tw-text-bad wh-tw-bg-badBg")} display="flex"alignItems="center">
            <Box flex={1}>
              <span>{delinquentStatus[0].reason} <span className={clsx(classes.link, "wh-tw-cursor-pointer")} onClick={() => handleGoBillingInfo()}>{delinquentStatus[0].paymentLinkText}</span> </span>
            </Box>
            <Box ml={1}>
              <IconButton id="delinquent-banner-close" onClick={() => handleCloseDelinquentBanner()}>
                <CloseIcon className="wh-tw-text-black" />
              </IconButton>
            </Box>
          </Box>
        )}
        {isAgreementsChanged && agreementsChanges.length > 0 && (
        <Box className={classes.banner} display='flex'>
          <Box>
            {agreementsChanges[0].changes.message}
          </Box>
          <Box ml={1}>
            <IconButton id="banner-notifying-close" onClick={() => { deactivateBanner()}}>
              <CloseIcon style={{ fill: colors.white }} />
            </IconButton>
          </Box>
        </Box>
        )}
      </div>
      <div className={classes.root}>
        <title>{title}</title>
        <CssBaseline />
        {isSmallScreen ? (
          !mobilePath && <AuthenticatedMobileHeaderComponent title={pageName} selectedMenuItem={selectedMenuItem ?? MenuItemTypes.None} topBannerHeight={bannerHeight} />
        ) : (
          <>
            {isTopNavigation && authQuery.isEmployeeUser() ? (
              <>
                {!mobilePath && <AuthenticatedHeaderNavbarComponent title={pageName} selectedMenuItem={selectedMenuItem ?? MenuItemTypes.None} topBannerHeight={bannerHeight} />}
              </>
            ) : (
              <>
                {!mobilePath && <AuthenticatedHeaderComponent title={pageName} isSideBarOpen={isSideBarOpen} topBannerHeight={bannerHeight} />}
                <SideBarComponent selectedMenuItem={selectedMenuItem ?? MenuItemTypes.None} isOpen={isSideBarOpen} topBannerHeight={bannerHeight} />
              </>
            )}
          </>
        )}
        <main className={classes.content}>
          <Toolbar />
          <WildHealthContainer margin={contentMargin} hideBackground={hideBackground} display={displayChildren}>
            {children}
          </WildHealthContainer>
          <FooterComponent position="absolute" />
        </main>
      </div>
    </>
  );
}
