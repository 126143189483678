import { useEffect, useState } from "react";
import { pageSizes } from "../../../common/pagination/models/page-sizes";
import { IPaginationState } from "../../../common/pagination/models/pagination-state";
import { getAvailablePages } from "../../../common/pagination/helpers/get-evailable-pages";
import { Subscription } from "rxjs/internal/Subscription";
import { onEmit } from "../../../common/helpers/on-emit";
import { notificationsService } from "../../../notifications/services/notifications.service";
import { notificationsQuery } from "../../../notifications/stores/notifications";
import { AlertsHistoryModel } from "../../../notifications/models/alerts.models";

interface AlertsHistoryState extends IPaginationState {
  isLoading: boolean;
  alerts: AlertsHistoryModel[];
  page: number;
  rowsPerPage: number;
  searchQuery: string;
}

export function useFacade(): [
  AlertsHistoryState,
  (query: string) => void,
  () => number[],
  (value: any) => void,
  (page: number) => void,
] {

  const [state, setState] = useState({
    isLoading: true,
    alerts: [],
    page: 0,
    rowsPerPage: pageSizes[0],
    totalCount: 0,
    selectedPage: 1,
    pageSize: pageSizes[0],
    searchQuery: '',
  } as AlertsHistoryState);

  const getAllAvailablePages = () => {
    return getAvailablePages(state);
  }

  const handlePageSizeChange = (value: any) => {
    if (value === state.pageSize) {
      return;
    }
    const cb = () => setState(state => ({ ...state, isLoading: false }));
    setState(state => ({
      ...state,
      pageSize: value,
      selectedPage: 1,
      isLoading: true,
    }));
    notificationsService.getAlertsHistory(0, value, state.searchQuery).subscribe(cb, cb)
  }

  const handlePageChange = (page: number) => {
    if (page === state.selectedPage) {
      return;
    }
    const cb = () => setState(state => ({ ...state, isLoading: false }));
    setState(state => ({
      ...state,
      selectedPage: page,
      isLoading: true
    }));
    notificationsService.getAlertsHistory(page - 1, state.pageSize, state.searchQuery).subscribe(cb, cb)
  }

  const handleSearchSubmit = (query = '') => {

    setState(state => ({ ...state, isLoading: true, searchQuery: query }));
    const cb = () => setState(state => ({ ...state, isLoading: false }));

    notificationsService.getAlertsHistory(0, state.pageSize, query).subscribe(cb, cb)
  }

  const useEffectCB = () => {
    const subscriptions: Subscription[] = [
      onEmit<AlertsHistoryModel[]>(notificationsQuery.alertsHistory$, alerts => {
        setState(state => ({
          ...state,
          alerts,
        }));
      }),
      onEmit<number>(notificationsQuery.alertsHistoryTotalCount$, alertsTotalCount => {
        setState(state => ({
          ...state,
          totalCount: alertsTotalCount,
        }));
      }),
    ];

    const cb = () => setState(state => ({ ...state, isLoading: false }));

    notificationsService.getAlertsHistory(state.selectedPage - 1, state.pageSize, '').subscribe(cb, cb)
    return () => {
      subscriptions.map(it => it.unsubscribe())
    };
  }

  useEffect(useEffectCB, []);

  return [
    state,
    handleSearchSubmit,
    getAllAvailablePages,
    handlePageSizeChange,
    handlePageChange,
  ];
}