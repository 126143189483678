import { availabilityQuery } from "../../stores/availability/availability.query";
import {employeeService} from "../../../employee/services/employees.service";
import { EmployeeAvailabilityModel } from "../../models/availability.models";
import {EmployeeShortModel} from "../../../employee/models/employee.models";
import {appointmentsService} from "../../services/appointments.service";
import {employeesQuery} from "../../../employee/stores/employeesStore";
import {onEmit} from "../../../common/helpers/on-emit";
import {authQuery} from "../../../auth/stores/auth";
import {useEffect, useState} from "react";
import {Subscription} from "rxjs";
import {timezonesQuery} from "../../../timezones/stores/timezones";
import {timezonesService} from "../../../timezones/services/timezones.service";
import {TimeZoneModel} from "../../../timezones/models/timezone.model";

interface CoachAppointmentPageState {
    anchorEl: HTMLButtonElement;
    employees: EmployeeShortModel[];
    employeesFiltered: EmployeeShortModel[];
    me: EmployeeShortModel;
    selectedEmployee: EmployeeShortModel;
    createDialogOpened: boolean;
    timeZone: string;
    timeZones: TimeZoneModel[];
    isOpen: boolean;
    isStatisticLoading: boolean;
}

export function useFacade(): [
    CoachAppointmentPageState, 
    (employeeId) => void,
    () => void,
    (search: string) => void,
    Function,
    (status: boolean) => void,
] {
    const [state, setState] = useState({
        anchorEl: null,
        selectedEmployee: null,
        employees: [],
        employeesFiltered: [],
        me: null,
        timeZone: '',
        isOpen: false,
        isStatisticLoading: true
    } as CoachAppointmentPageState);

    const handleToggle = (event?: React.MouseEvent<HTMLButtonElement>) => {
        const anchorEl = event && event.currentTarget ? event.currentTarget : null;
        setState(state => ({
            ...state,
            isOpen: !state.isOpen,
            anchorEl: anchorEl
        }));
    }

    const handleEmployeeChange = (employeeId) => {
        const selectedEmployee = [...state.employees, state.me].find(x => x.id === employeeId);
        setState({
            ...state,
            selectedEmployee: selectedEmployee,
            isOpen: !state.isOpen,
        });
    }

    const handleFiltering = (search: string) => {
        let filtered = state.employees?.filter(x => `${x.firstName} ${x.lastName}`.toLowerCase().includes(search.toLowerCase()))
        setState(state => ({ ...state, employeesFiltered: filtered }))
    };

    const handleOpenCreateDialog = () => {
        setState({...state, createDialogOpened: true});
        appointmentsService.openAppointmentSchedule(new Date(), null).subscribe(model => {
            appointmentsService.createAsEmployee(model).subscribe(() => {

            });
        })
    }

    const handleToggleStatisticsLoading = (status: boolean) => {
        setState(state => ({ ...state, isStatisticLoading: status }))
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<EmployeeShortModel[]>(employeesQuery.employees$, employees => {
                const me = employees.find(x => x.userId === authQuery.getId());
                setState(state => ({
                    ...state,
                    employees: employees.filter(x => x.id !== me?.id),
                    employeesFiltered: employees.filter(x => x.id !== me?.id),
                    selectedEmployee: me ?? employees[0],
                    me: me,
                }));
            }),
            onEmit<EmployeeAvailabilityModel>(availabilityQuery.currentAvailability$, current => {
                if(current && current.availability && current.availability.length){
                    setState(state => ({
                        ...state,
                        timeZone: current.availability[0].timeZone
                    }));
                }
            }),
            onEmit<TimeZoneModel[]>(timezonesQuery.timezones$, timezones => {
                setState(state => ({
                    ...state,
                    timeZones: timezones
                }));
            })
        ];

        employeeService.getAllPracticeEmployees();

        timezonesService.getTimezones().subscribe();

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [state, handleEmployeeChange, handleOpenCreateDialog, handleFiltering, handleToggle, handleToggleStatisticsLoading]
}