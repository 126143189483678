import React from 'react';
import { useFacade } from './manageNotesComponent.hooks';
import {Box} from "@material-ui/core";
import { SelectAppointmentToNotesDialog } from '../SelectAppointmentToNotesComponent/SelectAppointmentToNotesDialog';
import { OptionalComponent } from '../../../common/components/OptionalComponent';

export interface ManageNotesComponentProps {
    patientId?: number | null;
}

export const ManageNotesComponent: React.FC<ManageNotesComponentProps> = (props: ManageNotesComponentProps) => {
    const [
        view,
        isOpen,
        handleSelectAppointmentDialog,
        handleChangeSelectAppointment,
        handleSubmitSelectAppointment,
        handleConfigureAppointment
    ] = useFacade(props.patientId);

    return (
        <Box>
            {view}
            {   isOpen &&
                <OptionalComponent>
                    <SelectAppointmentToNotesDialog 
                            handleSubmitSelectAppointment={handleSubmitSelectAppointment} 
                            handleChangeSelectAppointment={handleChangeSelectAppointment} 
                            handleSelectAppointmentDialog={handleSelectAppointmentDialog}
                            handleConfigureAppointment={handleConfigureAppointment}
                            isOpen={isOpen}
                            patientId={props.patientId}/>
                </OptionalComponent>
            }
        </Box>
        
    )
}
