import { Box, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React from 'react';
import { useHistory } from 'react-router';
import {navigationService} from "../../services/navigation.service";
import { PatientMetaTagsComponent } from '../PatientMetaTagsComponent/PatientMetaTagsComponent';

interface PageTitleComponentProps {
    startAdornment?: JSX.Element;
    title: string;
    endAdornment?: JSX.Element;
    patientId?: number;
}

export const PageTitleComponent: React.FC<PageTitleComponentProps> = (props: PageTitleComponentProps) => {
    return (
        <Box display="flex" justifyContent='space-between'>
            <Box display="flex" alignItems='center'>
            {props.startAdornment}

            <Box
                ml={props.startAdornment ? 2 : 0}
                mr={props.endAdornment ? 2 : 0}
            >
                {props.title}
            </Box>

            {props.endAdornment}
            </Box>
            <PatientMetaTagsComponent patientId={props.patientId}></PatientMetaTagsComponent>
        </Box>
    )
}

interface BackTitleComponentProps {
    title: string;
    actionSize?: 'small' | 'medium';
    action?: () => void;
}

export const BackTitleComponent: React.FC<BackTitleComponentProps> = (props: BackTitleComponentProps) => {
    const { title, actionSize, action } = props;
    const history = useHistory();

    return (
        <PageTitleComponent
            startAdornment={
                <IconButton id={`page-title-go-back-${title}`} onClick={() => action ? action() : navigationService.goBack(history)} size={actionSize}>
                    <ArrowBackIcon />
                </IconButton>
            }
            title={title}
        />
    )
}
