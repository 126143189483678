/**
 * Represents page slide navigation context
 */
export interface PageSliderNavigationContext {
    nextButton: string;
    backButton: string;
    canGoBack: Function;
    goBack: Function;
    canGoNext: Function;
    goNext: Function;
    goTo?: Function;
}

export enum NavigationItemsType {
    Appointments = 1,
    Biometrics = 2,
    MyGoals = 3,
    Labs = 4,
    Messaging = 5,
    VisitNotes= 6,
}
export interface NavigationItemModel {

    type: NavigationItemsType,
    name: string,
    badge: boolean,
}


