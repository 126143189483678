import {useEffect, useState} from "react";
import {Subscription} from "rxjs";
import {onEmit} from "../../../common/helpers/on-emit";
import { labOrdersService } from "../../../orders/services/labOrders.service";
import { labOrdersQuery } from "../../../orders/stores/labOrdersStore";

export function useFacade(): [boolean] {
    const [unassignedLab, setUnassignedLab] = useState(false);

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<boolean>(labOrdersQuery.isUnmatchedFile$, isUnmatchedFile => {
                setUnassignedLab(() => isUnmatchedFile);
            }),
        ];

        labOrdersService.getAnyUnmatchedFiles().subscribe()

        return (() => {
            subscriptions.map(it => it.unsubscribe())
        })
    }, []);

    return [unassignedLab];
}