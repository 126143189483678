import React from 'react';
import { Box, Container, FormControl, FormHelperText, IconButton, InputAdornment, Link, OutlinedInput, TextField, Typography, Divider } from "@material-ui/core";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { ReactComponent as WHClarityLogo } from '@img/logos/WHClarityLogo.svg';
import { ReactComponent as GoogleIcon } from '@img/icons/GoogleIcon.svg';
import { useFacade } from "./loginPage.hooks";
import { useStyles } from './loginPage.styles';
import { TitlesService } from "../../../../constants/title.constants";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import {ExternalAuthProvider} from "../../models/auth.enums";
import {FeatureComponent} from "../../../common/components/featureFlags/FeatureComponent";
import {FeatureFlag} from "../../../common/components/featureFlags/featureFlags.models";
import { colors } from '../../../common/constants/colors';

export const LoginPageComponent: React.FC<any> = (props) => {
    const from = props?.location?.state?.from;
    const [
        {
            inProgress,
            showPassword,
            model,
            errors
        },
        handleClickShowPassword,
        renderRecaptcha,
        handleChanges,
        handleSubmit,
        handleForgotPassword,
        goToGetStartedPage,
        handleKeyDown,
        goToAuth2LoginPage
    ] = useFacade(from);

    const classes = useStyles();

    return (
        <>
            <title>{TitlesService.loginTitle}</title>
            <div className="wh-tw-w-full wh-tw-h-full wh-tw-min-h-screen wh-tw-bg-white">
                <Box display="flex" justifyContent="center" alignItems="center" height="102px">
                    <WHClarityLogo />
                </Box>
                <Box className={classes.contentBox}>
                    <Container maxWidth="sm">
                        <Box className="wh-tw-text-3xl md:wh-tw-text-[40px] wh-tw-font-medium wh-tw-text-secondary wh-tw-font-poppins" textAlign="center">
                            Log In
                        </Box>
                        <Box className="wh-tw-flex wh-tw-justify-center">
                            <Box maxWidth={456} display='flex' flexDirection='column' mt={3}>
                                <form onSubmit={(e) => handleSubmit(e)}>
                                    <Box className="wh-tw-text-xxs wh-tw-font-medium wh-tw-text-gray600 wh-tw-uppercase" mb={1}>Email <span className="wh-tw-text-primaryV">*</span></Box>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        placeholder="Enter your email address"
                                        required
                                        id="email"
                                        value={model.email}
                                        classes={{ root: classes.root }}
                                        variant="outlined"
                                        helperText={errors['email']}
                                        error={!!errors['email']}
                                        disabled={inProgress}
                                        type="email"
                                        onChange={(v) => handleChanges(v.target.id, v.target.value)}
                                        onKeyPress={(e) => handleKeyDown(e)}
                                    />
                                    <FormControl
                                        variant="outlined"
                                        color='primary'
                                        required
                                        error={!!errors[`password`]}
                                        size="small"
                                        fullWidth
                                        classes={{ root: classes.root }}
                                    >
                                        <Box className="wh-tw-text-xxs wh-tw-font-medium wh-tw-text-gray600 wh-tw-uppercase" mb={1} mt={3}>Password <span className="wh-tw-text-primaryV">*</span></Box>
                                        <OutlinedInput
                                            id="password"
                                            placeholder="Enter your password"
                                            type={showPassword ? 'text' : 'password'}
                                            value={model.password}
                                            error={!!errors['password']}
                                            disabled={inProgress}
                                            onChange={(v) => handleChanges(v.target.id, v.target.value)}
                                            onKeyPress={(e) => handleKeyDown(e)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        disabled={inProgress}
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOutlinedIcon className="wh-tw-text-gray600" /> : <VisibilityOffOutlinedIcon className="wh-tw-text-gray600" />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        <FormHelperText>{errors[`password`]}</FormHelperText>
                                    </FormControl>
                                    <Box my={3}>
                                        <Link id="login-page-forgot-password" className="wh-tw-text-primaryV wh-tw-font-medium" onClick={() => handleForgotPassword()}>
                                            <Typography
                                                className="wh-tw-font-medium wh-tw-font-beVietnamPro"
                                                display="inline"
                                            >
                                                Forgot Password?
                                            </Typography>
                                        </Link>
                                    </Box>
                                    <Box display="flex" justifyContent="center" width={1} mb={3}>
                                        <Box className={classes.loginBtnSection}>
                                            <WildHealthButton
                                                size="large"
                                                id="login-page-sign-in"
                                                onClick={(e) => handleSubmit(e)}
                                                fullWidth
                                                borderRadius={50}
                                                loading={inProgress}
                                            >
                                                Log In
                                            </WildHealthButton>
                                        </Box>
                                    </Box>
                                    <FeatureComponent featureFlag={FeatureFlag.Auth2Google}>
                                        <>
                                            <Box display="flex" alignItems="center" gridGap={12} mb={3}>
                                                <Divider className="wh-tw-bg-gray400 wh-tw-flex-1" />
                                                <Box className="wh-tw-text-xxs wh-tw-font-medium wh-tw-text-gray600">Other Log In Option</Box>
                                                <Divider className="wh-tw-bg-gray400 wh-tw-flex-1" />
                                            </Box>
                                            <Box mb={3} display="flex" justifyContent="center" width={1}>
                                                <Box className={classes.loginBtnSection}>
                                                    <WildHealthButton
                                                        size="large"
                                                        id="login-with-google"
                                                        onClick={(e) => goToAuth2LoginPage(ExternalAuthProvider.Google)}
                                                        fullWidth
                                                        borderRadius={50}
                                                        color="white"
                                                        style={{
                                                            border: `1px solid ${colors.black}`,
                                                        }}
                                                        disabled={inProgress}
                                                    >
                                                        <GoogleIcon />
                                                        <Box ml={1}>Sign In With Google</Box>
                                                    </WildHealthButton>
                                                </Box>
                                            </Box>
                                        </>
                                    </FeatureComponent>
                                    <Box pt={1.5} display="flex" justifyContent="center" alignItems="center" className="wh-tw-text-charcoal">
                                        <span className="wh-tw-pr-1 wh-tw-font-medium wh-tw-font-poppins">Don`t have an account?</span>
                                        <Link id="login-page-forgot-password" onClick={() => goToGetStartedPage()}>
                                            <Typography
                                                className="wh-tw-text-darkMint wh-tw-font-semibold wh-tw-font-poppins"
                                                display="inline"
                                            >
                                                Sign Up
                                            </Typography>
                                        </Link>
                                    </Box>
                                    {renderRecaptcha()}
                                </form>
                            </Box>
                        </Box>
                    </Container>
                </Box>
            </div>
        </>
    );
}
