import {Box, Container} from "@material-ui/core";
import React from 'react';
import {TitlesService} from '../../../../constants/title.constants';
import {logoService} from "../../../../services/logo.service";
import {useStyles} from '../loginPage/loginPage.styles';
import {useParams} from "react-router";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import {useFacade} from "./thirdPartyIntegrationCodePage.hooks";

export const ThirdPartyIntegrationCodePage: React.FC<any> = (props) => {
    const searchParams = new URLSearchParams(props.location.search);
    const params = useParams();

    const [
        {
            model,
            isLoading
        },
        renderRecaptcha
    ] = useFacade(searchParams.get('code'), params['provider'], searchParams.get('state'));


    const classes = useStyles();

    return (
        <>
            <title>{TitlesService.loginTitle}</title>
            <div className={classes.loginPage}>
                <Box display="flex" justifyContent="center" alignItems="center" height="102px">
                    <img className={classes.logo}
                         src={logoService.getLogo().primary}
                         alt="logo"/> </Box>

                <Box className={classes.contentBox}>
                    <Container maxWidth="sm">
                        {
                            isLoading && <WildHealthLinearProgress/>
                        }
                    </Container>
                </Box>
                {renderRecaptcha()}
            </div>
        </>
    );
}