import React from 'react';
import { Box, Collapse, Typography, Dialog, DialogContent, useTheme, useMediaQuery } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ReactComponent as DownloadIcon } from '@img/icons/DownloadIcon.svg';
import moment from 'moment';
import { useFacade } from "./patientLabInputsComponent.hooks";
import { useStyles } from "./patientLabInputsComponent.styles";
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { labInputGroupNames } from '../../models/input.models';
import { PatientLabInputsSectionComponent } from "../patientLabInputsSectionComponent/PatientLabInputsSectionComponent";
import { CommonPlaceHolder } from '../../../common/components/wildHealthPlaceHolder/CommonPlaceHolder';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import { WildHealthMenuItem } from "../../../common/components/wildHealthMenu/WildHealthMenu";
import { toCurrentTimeZone } from '../../../timezones/helpers/timezone';
import { ManageLabInputDatesComponent } from "../manageLabInputDatesComponent/ManageLabInputDatesComponent";
import { DialogTitle } from "../../../common/components/dialogTitle/DialogTitle";

export const PatientLabInputsComponent: React.FC = () => {
    const [
        {
            isLoading,
            labInputs,
            sections,
            files,
            isActionsOpen,
            dataSets,
            hiddenDataSets,
            showDataSet,
            isHighlightExpand
        },
        handleExpand,
        handleToggleActions,
        handleView,
        getDropDownItems,
        handleColumnCheck,
        handleHighlightExpand,
        getDropDownItemsForMobile,
        handleColumnCheckForMobile
    ] = useFacade();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const classes = useStyles();

    const dropDownItems = isSmallScreen ? getDropDownItemsForMobile() : getDropDownItems();

    const renderButtons = () => {
        return <>
            <Box display="flex" justifyContent="flex-end">
                {
                    dropDownItems.length > 0 &&
                    <Box px={1.5}>
                        {isSmallScreen
                            ? <ManageLabInputDatesComponent items={dropDownItems} handleChanges={handleColumnCheckForMobile} />
                            : <ManageLabInputDatesComponent items={dropDownItems} handleChanges={handleColumnCheck} />
                        }
                    </Box>
                }
                <Box>
                    <WildHealthButton id="upload-inputs-file-view" size="large" color="primaryV" disabled={!files || !files.length} borderRadius={isSmallScreen ? 50 : 4} onClick={() => handleToggleActions()}>
                        <Box mr={1}>Download</Box>
                        <DownloadIcon />
                    </WildHealthButton>
                </Box>
            </Box>
        </>
    }

    const renderContent = () => {
        if (isLoading) {
            return <WildHealthLinearProgress />
        }

        if (!labInputs || !labInputs.length) {
            return (<CommonPlaceHolder message="No available data." />)
        }

        const highlightedLabs = labInputs.filter(x => x.isHighlighted);

        return (
            <Box>
                {
                    highlightedLabs && highlightedLabs.length > 0 && (
                        <Box py={2}>
                            <Box display="flex" justifyContent="flex-start" alignItems="stretch" mt={1}>
                                <Box id={`highlighted-patient-lab-inputs-expand`} onClick={() => handleHighlightExpand()}
                                    className="wh-tw-cursor-pointer">
                                    {isHighlightExpand ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                                </Box>
                                <Box ml={1}>
                                    <span
                                        className="wh-tw-text-lg wh-tw-font-medium wh-tw-font-beVietnamPro">Highlights</span>
                                </Box>
                            </Box>
                            <Collapse in={isHighlightExpand} timeout="auto" unmountOnExit>
                                <Box mt={2}>
                                    <PatientLabInputsSectionComponent
                                        isHightlighted
                                        inputs={highlightedLabs} 
                                        sectionIndex={0}
                                        dataSets={dataSets}
                                        hiddenDataSets={hiddenDataSets}
                                    />
                                </Box>
                            </Collapse>
                        </Box>
                    )
                }
                {
                    sections.map((section, index) => {
                        return (
                            <Box key={index} py={2}>
                                <Box display="flex" justifyContent="flex-start" alignItems="stretch" mt={1}>
                                    <Box id={`patient-lab-inputs-expand-${section.group}`} onClick={() => handleExpand(section.group)}
                                        className="wh-tw-cursor-pointer">
                                        {section.expanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                                    </Box>
                                    <Box ml={1}>
                                        <span
                                            className="wh-tw-text-lg wh-tw-font-medium wh-tw-font-beVietnamPro">{labInputGroupNames[section.group]}</span>
                                    </Box>
                                </Box>
                                <Collapse in={section.expanded} timeout="auto" unmountOnExit>
                                    <Box mt={2}>
                                        <PatientLabInputsSectionComponent
                                            inputs={labInputs.filter(x => x.group === section.group)} 
                                            sectionIndex={index}
                                            dataSets={dataSets}
                                            hiddenDataSets={hiddenDataSets}
                                            showDataSet={showDataSet}
                                        />
                                    </Box>
                                </Collapse>
                            </Box>
                        )
                    })
                }
            </Box>
        )
    }

    return (
        <>
            <Box p={isSmallScreen ? 2 : 3}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <span className="wh-tw-text-2xl wh-tw-font-medium wh-tw-font-poppins">Labs</span>
                    {
                        renderButtons()
                    }
                </Box>
                <Box pt={1}>
                    {
                        renderContent()
                    }
                </Box>
            </Box>
            <Dialog
                open={isActionsOpen}
                onClose={() => handleToggleActions()}
                className={classes.dialog}
            >
                {!isSmallScreen && (
                    <DialogTitle
                        id='dialog-title'
                        onClose={() => handleToggleActions()}
                    >
                    </DialogTitle>
                )}
                <DialogContent className={classes.dialogContent}>
                    <Box mb={2} px={4} pt={isSmallScreen && 2} className="wh-tw-text-xl wh-tw-font-medium wh-tw-font-poppins" textAlign="center">{isSmallScreen ? 'Download Lab Results' : 'Lab Results By Collection Date'}</Box>
                    <Box mb={1.5} className="wh-tw-text-gray600 wh-tw-font-beVietnamPro" textAlign="center">Select lab results to download</Box>
                    <Box>
                    {
                        files && files.length ? files.map((item, index) =>
                            <WildHealthMenuItem
                                key={index}
                                id={`view-report-files-${item.id}`}
                                onClick={() => handleView(item.id)}
                                disabled={!item || isLoading}
                            >
                                <Typography variant="inherit" noWrap className="wh-tw-text-primaryV">
                                    {moment(toCurrentTimeZone(new Date(item.observationDate))).format("MM/DD/yyyy")}
                                </Typography>
                            </WildHealthMenuItem>
                        ) :
                            <WildHealthMenuItem
                                id="no-view-report-files-yet"
                                disabled={true}
                            >
                                <Typography variant="inherit" noWrap>
                                    {`No Report Files yet.`}
                                </Typography>
                            </WildHealthMenuItem>
                        }
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
}