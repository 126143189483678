import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import { Subscription } from "rxjs";
import { navigationService } from "../../../../services/navigation.service";
import { onEmit } from "../../../common/helpers/on-emit";
import { PageSliderNavigationContext } from "../../../common/models/navigation.models";
import { SelectPaymentPeriodComponent } from "../../components/selectPaymentPeriodComponent/SelectPaymentPeriodComponent";
import { PaymentPeriodModel } from "../../models/paymentPeriod.models";
import { PaymentPlanModel } from "../../models/paymentPlan.models";
import { paymentPlansService } from "../../services/paymentPlans.service";
import { paymentPlansQuery, PaymentSteps } from "../../stores/paymentPlansStore";
import { authQuery } from "../../../auth/stores/auth";
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getValueFeatureFlag, isFeatureFlag, anyFlags } from '../../../common/components/featureFlags/featureFlags';
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import { PaymentCouponModel } from "../../models/paymentPrice.models";
import { useLocation } from "react-router-dom";
import {
    FinishCheckoutComponent,
} from "../../components/finishCheckoutComponent/FinishCheckoutComponent";
import {
    FinishCheckoutRedesignComponent,
} from "../../components/finishCheckoutRedesignComponent/FinishCheckoutComponent";
import {
    FinishCheckoutExperimentComponent,
} from "../../components/finishCheckoutExperimentComponent/FinishCheckoutComponent";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { SelectPaymentPlanComponent } from "../../components/selectPaymentPlanComponent/SelectPaymentPlanComponent";
import { addressService } from "../../../account/services/address.service";
import { confirmService } from "../../../../services/confirm.service";

interface GetStartedState {
    plans: PaymentPlanModel[];
    trialPlan: PaymentPlanModel;
    selectedPlan: PaymentPlanModel | null;
    selectedPeriod: PaymentPeriodModel | null;
    step: PaymentSteps;
    coupon: PaymentCouponModel;
    progress: number;
    progressBarOpened: boolean;
    bannerOpened: boolean;
    bannerHeight: number;
    isNewAccountId: number;
}

interface ScrollElement {
    current: HTMLDivElement
}

const DEFAULT_PLAN = "STANDARD";

/**
 * Custom Hook to manage a view Model for GetStarted page view components
 */
export function useFacade(employerKey: string): [JSX.Element, GetStartedState, boolean, ScrollElement, Function] {
    const history = useHistory();
    const featureFlags = useFlags();
    const search = useLocation().search;
    const specifiedPlan = new URLSearchParams(search).get('plan');
    const defaultPlan = getValueFeatureFlag(featureFlags, FeatureFlag.DefaultPlan) ?? DEFAULT_PLAN;
    const OnboardingRedesignV2 = isFeatureFlag(featureFlags, FeatureFlag.OnboardingRedesignV2);
    const CheckoutSocialProof = isFeatureFlag(featureFlags, FeatureFlag.CheckoutSocialProof);
    const [state, setState] = useState({
        selectedPlan: null,
        selectedPeriod: null,
        step: PaymentSteps.SelectPlan,
        progress: 0,
        progressBarOpened: false,
        coupon: null,
        bannerOpened: true,
        bannerHeight: 0,
        isNewAccountId: 0
    } as GetStartedState);

    const prevScrollY = useRef<HTMLHeadingElement>(null);

    const handleClose = () => {
        setState(state => ({ ...state, bannerOpened: !state.bannerOpened }));
    }

    const canGoBack = () => {
        if (state.step === PaymentSteps.SelectPeriod) {
            return state.plans.length > 1;
        }

        if (state.step === PaymentSteps.Checkout) {
            if (state.selectedPlan.periods.length > 1) {
                return true;
            } else {
                return state.plans.length > 1;
            }
        }

        return false;
    }

    const canGoNext = () => {
        switch (state.step) {
            case PaymentSteps.SelectPlan:
                return state.selectedPlan;
            case PaymentSteps.SelectPeriod:
                return state.selectedPeriod
        }
    }

    const handleNext = () => {
        switch (state.step) {
            case PaymentSteps.SelectPlan:
                return setState(state => ({ ...state, step: PaymentSteps.SelectPeriod }));
            case PaymentSteps.SelectPeriod:
                return setState(state => ({ ...state, step: PaymentSteps.Checkout, progressBarOpened: true }));
        }
    };

    const goBackFromCheckoutPage = () => {
        const step = state.selectedPlan.periods.length === 1
            ? PaymentSteps.SelectPlan
            : PaymentSteps.SelectPeriod;

        setState(state => ({
            ...state,
            step: step,
            progressBarOpened: false
        }));
    }

    const handleBack = () => {
        switch (state.step) {
            case PaymentSteps.SelectPeriod:
                return setState(state => ({ ...state, step: PaymentSteps.SelectPlan }));
            case PaymentSteps.Checkout:
                return goBackFromCheckoutPage();
        }
    };

    const navigationContext = {
        nextButton: "Next",
        backButton: "Back",
        canGoNext: canGoNext,
        canGoBack: canGoBack,
        goNext: handleNext,
        goBack: handleBack
    } as PageSliderNavigationContext

    const checkoutContent = () => {
        if (!state.plans || !state.plans.length || !anyFlags(featureFlags)) {
            return <WildHealthLinearProgress />
        }

        return CheckoutSocialProof ? <FinishCheckoutExperimentComponent
            plans={state.plans}
            specifiedPlan={specifiedPlan}
            defaultPlan={defaultPlan}
            navigationContext={navigationContext}
            employerKey={employerKey}
            topBannerHeight={state.bannerHeight}
            handleSetProgress={(progress) => setState(state => ({ ...state, progress}))}
        /> : OnboardingRedesignV2 ? <FinishCheckoutRedesignComponent
            plans={state.plans}
            specifiedPlan={specifiedPlan}
            defaultPlan={defaultPlan}
            navigationContext={navigationContext}
            employerKey={employerKey}
            topBannerHeight={state.bannerHeight}
            handleSetProgress={(progress) => setState(state => ({ ...state, progress}))}
        /> : <FinishCheckoutComponent
            plans={state.plans}
            specifiedPlan={specifiedPlan}
            defaultPlan={defaultPlan}
            navigationContext={navigationContext}
            employerKey={employerKey}
        />
    }

    const StepsContent = new Map<number, JSX.Element>([
        [PaymentSteps.SelectPlan, <SelectPaymentPlanComponent plans={state.plans} trialPlan={state.trialPlan} specifiedPlan={specifiedPlan} defaultPlan={defaultPlan} navigationContext={navigationContext} />],
        [PaymentSteps.SelectPeriod, <SelectPaymentPeriodComponent navigationContext={navigationContext} />],
        [PaymentSteps.Checkout, checkoutContent()]
    ]);

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<PaymentPlanModel[]>(paymentPlansQuery.regularPlans$, plans => setState(state => ({
                ...state,
                plans: plans
            }))),
            onEmit<PaymentPlanModel>(paymentPlansQuery.trialPlan$, trialPlan => setState(state => ({
                ...state,
                trialPlan: trialPlan
            }))),
            onEmit<PaymentCouponModel>(paymentPlansQuery.coupon$, coupon => {
                setState(state => ({
                    ...state,
                    coupon: coupon
                }));
            }),
            onEmit<number>(authQuery.userId$, userId => setState(state => ({
                ...state,
                isNewAccountId: userId
            }))),
        ];

        addressService.getStates().subscribe();
        if (authQuery.isLoggedIn() && authQuery.isRegistrationCompleted()) {
            navigationService.toDashboard(history);
        } else {
            if (employerKey) {
                paymentPlansService.getEmployerProduct(employerKey).subscribe(() => {
                    paymentPlansService.getActive(authQuery.getCurrentPracticeId(), employerKey).subscribe();
                }, () => {
                    confirmService.alert('Alert', 'This plan is no longer active. The button below will redirect you to checkout using our current active plan.').subscribe(() => {
                        navigationService.toGetStarted(history);
                    });
                });
            } else {
                paymentPlansService.getActive(authQuery.getCurrentPracticeId(), null).subscribe();
            }
        }      

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    useEffect(() => {
        navigationService.goUp();
    }, [state.step])

    useEffect(() => {
        function scrollEvent() {
            if (prevScrollY) {

                if (prevScrollY.current) {
                    let rect = prevScrollY.current.getBoundingClientRect()
                    setState(state => ({ ...state, bannerHeight: rect.bottom > 0 ? rect.bottom : 0 }))
                }
            }
        }

        if (prevScrollY && prevScrollY?.current) {
            let rect = prevScrollY.current.getBoundingClientRect()
            setState(state => ({ ...state, bannerHeight: rect.bottom > 0 ? rect.bottom : 0 }))
        }

        document.addEventListener('scroll', scrollEvent)

        return () => {
            document.removeEventListener('scroll', scrollEvent)
        }
    }, [state.coupon, state.bannerOpened]);

    return [StepsContent.get(state.step), state, OnboardingRedesignV2, prevScrollY, handleClose];
}
