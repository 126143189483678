import { Badge } from '@material-ui/core';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        activeChat: {
            background: colors.lightGray2,
        },
        chatNames: {
            color: colors.black,
            fontFamily: 'Be Vietnam Pro',
            fontWeight: 500,
            fontSize: 14,
            maxWidth: 175
        },
        chatSubTitle: {
            color: colors.black,
            fontFamily: 'Be Vietnam Pro',
            fontSize: 12,
        },
        chatLastMessage: {
            color: colors.gray1,
            fontWeight: 400,
            fontSize: 12,
        },
        chatDate: {
            color: colors.gray1,
            fontFamily: 'Be Vietnam Pro',
            fontSize: 12,
        },
        chats: {
            flex: 1,
            minHeight: 300,
            height: 'calc(100vh - 304px)',
            maxHeight: 'calc(100vh - 304px)',
            overflow: 'auto',
        },
        unreadMessages: {
            width: 24,
            height: 24,
            borderRadius: '100px',
            backgroundColor: `${colors.main}24`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: colors.mainDark,
            fontSize: 12,
            fontWeight: 700
        },
        listItem: {
            '&:hover .favorite-item': {
                visibility: 'visible'
            },
            '&:hover .date-section': {
                visibility: 'hidden'
            }
        },
        favoriteAction: {
            position: 'absolute',
            right: 32,
            visibility: 'hidden',
            '&:hover': {
                visibility: 'visible'
            }
        },
        notificationToolTipText: {
            fontFamily: 'Be Vietnam Pro',
            fontSize: 12,
            fontWeight: 400,
            color: colors.white,
        },
        customWidth: {
            maxWidth: 'none',
            backgroundColor: colors.lightBlack,
            padding: 8,
        },
        arrow: {
            color: colors.lightBlack,
        },
        tooltipPlacement: {
            margin: '8px 0',
        },
    })
);

export const UnreadMessages = withStyles((theme: Theme) => ({
    root: {
        '& .MuiBadge-badge': {
            background: colors.main,
            color: colors.white
        }
    },
}))(Badge);
