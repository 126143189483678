import React from "react";
import { WildHealthPlaceHolderBase } from "./WildHealthPlaceHolderBase";
import { ReactComponent as emptyAttachments } from '@img/placeholders/EmptyAttachments.svg';
import { useStyles } from "./commonPlaceHolder.styles";
import { CommonPlaceHolderProps } from "./CommonPlaceHolder";

interface AttachmentsPlaceHolderProps extends CommonPlaceHolderProps {
}

export const AttachmentsPlaceHolder: React.FC<AttachmentsPlaceHolderProps> = (props: AttachmentsPlaceHolderProps) => {
    const classes = useStyles();

    return (
        <WildHealthPlaceHolderBase message={props.message}>
            {React.createElement(emptyAttachments, { className: classes.image })}
        </WildHealthPlaceHolderBase>
    );
}
