import {
    Box, IconButton, Dialog, DialogActions, DialogContent, TextField, Typography,
    Button, useMediaQuery, useTheme,
} from "@material-ui/core";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import clsx from 'clsx';
import React from 'react';
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { useFacade } from "./patientSupplementsUpdateComponent.hooks";
import { useStyles } from "./patientSupplementsUpdateComponent.styles";
import { PatientSupplementModel } from "../../models/patientSupplementModel";
import { colors } from '../../../common/constants/colors';
import Cancel from "@img/icons/cancel.svg";
import { ProtectedElement } from "../../../common/components/ProtectedElement";
import { PermissionType } from "../../../auth/models/auth.enums";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import commonUseStyles from '../../../common/styles/common.styles';

export interface PatientSupplementsUpdateProps {
    opened: boolean;
    handleClose: () => void;
    patientId?: number;
    model?: PatientSupplementModel;
}

export const PatientSupplementsUpdateComponent: React.FC<PatientSupplementsUpdateProps> = (props: PatientSupplementsUpdateProps) => {
    const classes = useStyles();
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const [
        {
            model,
            errors,
            isLoading
        },
        handleClose,
        handleFieldChange,
        handleSave
    ] = useFacade(props);

    const linkInput = (
        <>
            <Box pl={0.5} pt={2.5} fontSize={14} color={colors.gray1}>Add a Link to Order Online</Box>
            <TextField
                fullWidth
                size="small"
                variant="outlined"
                id="purchaseLink"
                value={model.purchaseLink}
                className={clsx(classes.field, 'input')}
                error={!!errors['purchaseLink']}
                helperText={errors['purchaseLink']}
                onChange={(event) => handleFieldChange(event.target.id, event.target.value)}
            />
        </>)

    const renderContent = (): JSX.Element => {
        if (isLoading) {
            return (<WildHealthLinearProgress />)
        }

        return (
            <Box p={!isSmallScreen && 1.5} pt={0.5} display="flex" flexDirection="column">
                <Box display="flex" pl={0.5} fontSize={14} color={colors.gray1}>
                    Name of Supplement
                    <Box pl={0.5} color={colors.bad}>*</Box>
                </Box>
                <TextField
                    required
                    fullWidth
                    id="name"
                    size="small"
                    variant="outlined"
                    value={model.name}
                    className={clsx(classes.field, 'input')}
                    error={!!errors['name']}
                    helperText={errors['name']}
                    onChange={(event) => handleFieldChange(event.target.id, event.target.value)}
                />
                <Box pl={0.5} pt={2.5} fontSize={14} color={colors.gray1}>Strength</Box>
                <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    id="dosage"
                    value={model.dosage}
                    className={clsx(classes.field, 'input')}
                    error={!!errors['dosage']}
                    helperText={errors['dosage']}
                    onChange={(event) => handleFieldChange(event.target.id, event.target.value)}
                />
                <Box pl={0.5} pt={2.5} fontSize={14} color={colors.gray1}>Dosing Instructions</Box>
                <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    id="instructions"
                    value={model.instructions}
                    className={clsx(classes.field, 'input')}
                    error={!!errors['instructions']}
                    helperText={errors['instructions']}
                    onChange={(event) => handleFieldChange(event.target.id, event.target.value)}
                />
                <ProtectedElement
                    element={linkInput}
                    permissions={[PermissionType.Coaching, PermissionType.ManagePatients]}
                />
            </Box>
        )
    }

    return (
        <>
            <Dialog
                fullScreen={isSmallScreen}
                className={classes.dialog}
                onClose={() => handleClose()}
                open={props.opened}
            >
                {
                    !isSmallScreen &&
                    <MuiDialogTitle
                        className={classes.dialogTitle}
                        disableTypography
                    >
                        <Typography variant="h6">{model.id > 0 ? 'Edit Supplement' : 'Add Supplement'}</Typography>
                        <Box pl={1} pr={1}>
                            <IconButton id="patient-supplements-update-close" onClick={() => {
                                handleClose()
                            }} >
                                <img src={Cancel} alt='Cancel' />
                            </IconButton>
                        </Box>

                    </MuiDialogTitle>
                }
                <DialogContent className={classes.dialogContent}>
                    {
                        isSmallScreen &&
                        <Box py={1} display="flex" justifyContent="space-between">
                            <Button onClick={() => handleClose()}>
                                <span className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorGray1, commonClasses.capitalize)}>
                                    Cancel
                                </span>
                            </Button>
                            <Box>
                                <span className={clsx(commonClasses.size20, commonClasses.textMedium, commonClasses.colorBlack)}>
                                    {model.id > 0 ? 'Edit Supplement' : 'Add Supplement'}
                                </span>
                            </Box>
                            <Button onClick={() => handleSave(props.patientId)}>
                                <span className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorMain, commonClasses.capitalize)}>
                                    {model.id > 0 ? 'Save' : 'Add'}
                                </span>
                            </Button>
                        </Box>
                    }
                    {
                        renderContent()
                    }
                </DialogContent>
                {
                    !isSmallScreen &&
                    <DialogActions className={classes.action}>
                        <WildHealthButton
                            id="patient-supplements-update-cancel"
                            onClick={() => { handleClose() }}
                            color="tertiary"
                            size='large'
                        >
                            Cancel
                        </WildHealthButton>
                        <Box pl={0.5}>
                            <WildHealthButton
                                id="patient-supplements-update-save"
                                onClick={() => { handleSave(props.patientId) }}
                                autoFocus
                                size='large'
                            >
                                {model.id > 0 ? 'Save Changes' : 'Add Supplement'}
                            </WildHealthButton>
                        </Box>
                    </DialogActions>
                }
            </Dialog>
        </>
    );
}
