import {ClassNameMap} from "@material-ui/core/styles/withStyles";
import React from "react";
import {Box, useMediaQuery, useTheme} from "@material-ui/core";
import {WildHealthTabControl} from "../../../common/components/wildHealthTabControl/WildHealthTabControl";
import {TabItem} from "../../../common/components/wildHealthTabControl/wildHealthTabControl.hooks";
import {PatientMedicationsComponent} from "../patientMedicationsComponent/PatientMedicationsComponent";
import {PatientSupplementsComponent} from "../patientSupplementsComponent/PatientSupplementsComponent";
import {useFacade} from "./patientMedicationsSupplementsTabsComponent.hooks";
import "./PatientMedicationsSupplementsTabsComponent.scss";
import {
    useStyles,
    useStylesFullWidthTabs
} from "../../../common/components/wildHealthTabControl/wildHealthTabControl.styles";
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from "clsx";
import {TutorialDialog} from "../../../common/dialogs/tutorialDialog/TutorialDialog";
import InfoIcon from '@material-ui/icons/Info';
import {ReactComponent as Supplements1stStep} from "@img/Supplements1stStep.svg";
import {ReactComponent as Supplements2ndStep} from "@img/Supplements2ndStep.svg";
import {ReactComponent as Success} from "@img/success.svg";
import {TutorialModel} from "../../../common/dialogs/tutorialDialog/tutorialDialog.hooks";
import {FeatureComponent} from "../../../common/components/featureFlags/FeatureComponent";
import {FeatureFlag} from "../../../common/components/featureFlags/featureFlags.models";
import {
    PatientSupplementsFullscriptComponent
} from "../patientSupplementsFullscriptComponent/PatientSupplementsFullscriptComponent";
import {PatientMedicationsRxNTComponent} from "../patientMedicationsRxNTComponent/PatientMedicationsRxNTComponent";
import {SupplementSource} from "../../models/patientSupplementModel";

interface PatientMedicationsSupplementsTabsComponentProps {
    patientId?: number;
    className?: ClassNameMap<'tab' | 'selectedTab'>;
    fullWidth?: boolean;
    hideTips?: boolean;
    supplementSource?: SupplementSource;
}

export const PatientMedicationsSupplementsTabsComponent: React.FC<PatientMedicationsSupplementsTabsComponentProps> = (props: PatientMedicationsSupplementsTabsComponentProps) => {
    const {
        patientId,
        fullWidth,
        className,
        hideTips,
        supplementSource
    } = props;

    const [
        {
            selectedTab,
            isDialogOpened
        },
        handleChangeTab,
        handleToggleDialog
    ] = useFacade(patientId);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const initialClassName = useStyles();
    const smallClassName = useStylesFullWidthTabs();
    const commonClasses = commonUseStyles();

    const tabs: TabItem[] = [
        {
            title: 'Supplements',
            content: <>
                <FeatureComponent featureFlag={FeatureFlag.FullscriptIntegration}>
                    <PatientSupplementsFullscriptComponent supplementSource={supplementSource} patientId={patientId} fullWidth={fullWidth} hideTips={hideTips} handleToggleDialog={handleToggleDialog} />
                </FeatureComponent>
                <FeatureComponent notFeatureFlag={FeatureFlag.FullscriptIntegration}>
                    <PatientSupplementsComponent patientId={patientId} fullWidth={fullWidth} handleToggleDialog={handleToggleDialog} />
                </FeatureComponent>
            </>,
            keepMounted: true,
        },
        {
            title: 'Medications',
            content: <>
                <FeatureComponent featureFlag={FeatureFlag.RxntIntegration}>
                    <PatientMedicationsRxNTComponent patientId={patientId} fullWidth={fullWidth} handleToggleDialog={handleToggleDialog} />
                </FeatureComponent>
                <FeatureComponent notFeatureFlag={FeatureFlag.RxntIntegration}>
                    <PatientMedicationsComponent patientId={patientId} fullWidth={fullWidth} handleToggleDialog={handleToggleDialog} />
                </FeatureComponent>
                </>,
            keepMounted: true,
        },
    ];

    const tutorialContent: TutorialModel[] = [
        {
            id: 1,
            featuredImage: <Supplements1stStep />,
            title: 'Upload the lists of your supplements and medications',
            content: 'Get started by adding prescribed supplements and medications using the “Add” button. It will help you store necessary data about your medications.'
        },
        {
            id: 2,
            featuredImage: <Supplements2ndStep />,
            title: 'Provide your intake details',
            content: 'It’s crucial to know all details about your medications and supplements intake. Simply fill in the correct dosage instructions and strength of your medications.'
        },
        {
            id: 3,
            featuredImage: <Success />,
            title: 'Well done!',
            content: 'Click “Add” to create your first list of supplements and medications.'
        },
    ]

    return (
        <Box className={isSmallScreen && commonClasses.bgMain}>
            {isSmallScreen &&
                <Box pt="20px" pb="10px" px={2} display='flex' justifyContent='space-between' alignItems='center'>
                    <Box className={clsx(commonClasses.size24, commonClasses.textSemiBold, commonClasses.colorBlack)}>
                        Supplements/Meds
                    </Box>
                    <Box display='flex' alignItems='center' gridGap={8} className="wh-tw-cursor-pointer" onClick={handleToggleDialog}>
                        <InfoIcon className="wh-tw-w-5 wh-tw-h-5 wh-tw-text-primaryV" />
                        <Box className="wh-tw-text-primaryV wh-tw-text-sm wh-tw-font-medium">Help</Box>
                    </Box>
                </Box>
            }
            <WildHealthTabControl
                items={tabs}
                className={isSmallScreen ? smallClassName : className ?? initialClassName}
                initTab={selectedTab}
                onChangeCB={handleChangeTab}
            />
            <TutorialDialog
                opened={isDialogOpened}
                tutorialContent={tutorialContent}
                handleClose={handleToggleDialog}
            />
        </Box>
    )
}
