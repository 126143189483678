import React from "react";
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Divider,
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    useMediaQuery,
    useTheme
} from "@material-ui/core";
import WatchLaterOutlinedIcon from '@material-ui/icons/WatchLaterOutlined';
import { ReactComponent as CalendarIcon } from "@img/icons/CalendarIcon.svg";
import moment from "moment";
import { useFacade } from "./appointmentWidgetComponent.hooks";
import { useStyles } from "./appointmentWidgetComponent.styles";
import {
    PatientRescheduleAppointmentDialogComponent
} from '../../../appointments/components/patientRescheduleAppointmentDialog/PatientRescheduleAppointmentDialogComponent';
import {
    AppointmentPurposeType,
    AppointmentStatus,
    AppointmentWithType
} from '../../../appointments/models/appointments.enums';
import { PatientAppointmentModel } from '../../../appointments/models/appointments.models';
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { DialogTitle } from "../../../common/components/dialogTitle/DialogTitle";
import { displayShortName } from "../../../conversations/helpers/messages.converter";
import {getAvailableAppointmentType, getAvailableWithTypes} from "../../../appointments/helpers/appointmentHelper";
import { CreatePatientAppointmentDialogRedesignComponent } from "../../../appointments/components/createPatientAppointmentUpdatedDialog/CreatePatientAppointmentDialogRedesignComponent";
import { WildHealthStatusLabel } from "../../../common/components/wildHealthStatusLabel/WildHealthStatusLabel";
import { AppointmentOptionsDialogComponent } from "../../../appointments/components/appointmentOptionsDialogComponent/AppointmentOptionsDialogComponent";
import { EmployeeType, employeeTypesNames, employeeTypesNamesForAppointment } from "../../../employee/models/employee.enums";
import { profileQuery } from "../../../account/stores/profileStore";

export interface AppointmentWidgetComponentProps {
    patientId: number | null;
}

export const AppointmentWidgetRedesignComponent: React.FC<AppointmentWidgetComponentProps> = (props: AppointmentWidgetComponentProps) => {
    const { patientId } = props
    const [
        {
            isLoading,
            isPhotosLoading,
            isAppointmentTypesLoading,
            isManageOpen,
            assignedEmployees,
            appointmentTypes,
            appointments,
            locations,
            timeZone,
            appointmentsSummary
        },
        handleToggleMenu,
        handleReschedule,
        handleCancel,
        handleJoin,
        handleGoToMyProfile,
        handleToggleManage
    ] = useFacade(patientId);

    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const coachSectionWithTypes: Array<AppointmentWithType> = [AppointmentWithType.HealthCoach];
    const providerSectionWithTypes: Array<AppointmentWithType> = [AppointmentWithType.HealthCoachAndProvider, AppointmentWithType.Provider];

    const hasFollowUp = Boolean(appointmentTypes.find((appointmentType) => appointmentType.purpose === AppointmentPurposeType.FollowUp));
    const denomenator = appointmentsSummary?.availableCoachAppointments;

    const getAppointmentsByWithType = (withTypes: AppointmentWithType[], appointments: PatientAppointmentModel[]): PatientAppointmentModel[] => {
        return appointments.filter(x => x.status !== AppointmentStatus.Canceled && withTypes.includes(x.withType));
    }

    const getTitle = (appointment: PatientAppointmentModel): string => {
        return appointment.employees
            .map(e => `${e.firstName} ${e.lastName}`)
            .join(', ');
    }

    const getAvatarLetters = (appointment: PatientAppointmentModel): string => {
        return appointment.employees.length === 1
            ? displayShortName(appointment.employees[0].firstName, appointment.employees[0].lastName)
            : appointment.employees.map(x => x.firstName.trim()[0]).join(',');
    }

    const renderCreateAppointment = (disableSchedule, targetWithType, position: EmployeeType) => {
        return (
            <Box>
                <CreatePatientAppointmentDialogRedesignComponent
                    locations={locations}
                    assignedEmployees={assignedEmployees}
                    appointmentTypes={appointmentTypes}
                    appointmentWithType={targetWithType}
                    timeZone={timeZone}
                    disableSchedule={disableSchedule}
                    position={position}
                    hasFollowUp={hasFollowUp}
                    patientId={patientId}
                />
            </Box>
        );
    }

    const renderAppointment = (appointment) => {
        return (
            <>
            <Box className={classes.appointmentContent}>
                <Box className={classes.employeeAvatars}>
                    <Avatar src={profileQuery.getPhoto(appointment.employees[0].id)}>
                        {getAvatarLetters(appointment)}
                    </Avatar>
                </Box>
                <Box ml={1.5} width='100%'>
                    <Box display='flex' justifyContent='space-between'>
                        <Box>
                            <Box className="wh-tw-font-medium wh-tw-text-black">
                                {getTitle(appointment)}
                            </Box>
                            <Box className={classes.dateTime}>
                                <Box>
                                    <CalendarIcon className={classes.dateIcon} />
                                </Box>
                                <Box ml={0.5} className={classes.date}>
                                    {moment(appointment.startDate).format(isSmallScreen ? 'ddd, MMM DD' : 'ddd, MMMM DD')}
                                </Box>

                                <Box ml={isSmallScreen? 1 : 2}>
                                    <WatchLaterOutlinedIcon className={classes.timeIcon} />
                                </Box>
                                <Box ml={0.5} className={classes.time}>
                                    {`${moment(appointment.startDate).format('hh:mm a')} - ${moment(appointment.endDate).format('hh:mm a')}`}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box className={classes.manageButtons}>
                <Box flex={1} width={1}>
                    <WildHealthButton
                        id="appointment-widget-more-menu"
                        fullWidth
                        color="secondary"
                        size="medium"
                        onClick={(e) => handleToggleManage(appointment.id, true)}
                    >
                        Manage Appointment
                    </WildHealthButton>
                </Box>
                <Box flex={1} width={1}>
                    <WildHealthButton
                        id="appointment-widget-join"
                        fullWidth
                        color="primary"
                        size="medium"
                        onClick={() => handleJoin(appointment.joinLink)}
                    >
                        Join
                    </WildHealthButton>
                </Box>
            </Box>
            </>
        )
    }

    const renderAppointmentsSection = (targetAppointments) => {
        const byStartDate = (a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
        const appointment = targetAppointments.sort(byStartDate)[0];
        return <Box my={1} className={classes.appointmentContainer}>
            {renderAppointment(appointment)}
        </Box>
    }

    const renderSection = (position: EmployeeType, withTypes: AppointmentWithType[]) => {

        const targetAppointments = getAppointmentsByWithType(withTypes, appointments);
        const availableWithTypes = getAvailableWithTypes(appointmentTypes);
        const targetWithType = availableWithTypes.find(x => withTypes.includes(x));
        const availableType = getAvailableAppointmentType(appointmentTypes, targetWithType);
        const employee = assignedEmployees?.find(el => el.role.displayName === employeeTypesNames.get(position))
        const isCanCreate = position === EmployeeType.HealthCoach ? denomenator > 0 : true;
        const disableSchedule = !(!!availableType && !!employee && isCanCreate);

        return (
            <Box>
                <Box display="flex" alignItems='center'>
                    <Typography className={classes.position}>
                        {employeeTypesNamesForAppointment.get(position)} Visit
                    </Typography>
                    {disableSchedule && targetAppointments.length === 0 && <Box px={1}>
                        <WildHealthStatusLabel color='bad' fontWeight={500}>Not Eligible</WildHealthStatusLabel>
                    </Box>}
                </Box>
                <Box mt={1.5}>
                    {(!targetAppointments || !targetAppointments.length)
                        ? renderCreateAppointment(disableSchedule, targetWithType, position)
                        : renderAppointmentsSection(targetAppointments)
                    }
                </Box>
            </Box>
        )
    }

    const renderContent = () => {
        if (isLoading || isPhotosLoading || isAppointmentTypesLoading) {
            return <WildHealthLinearProgress />
        }

        const targetAppointments = getAppointmentsByWithType([...coachSectionWithTypes, ...providerSectionWithTypes], appointments);

        return (
            <Box className={classes.content}>
                <PatientRescheduleAppointmentDialogComponent patientId={patientId} />

                {renderSection(EmployeeType.HealthCoach, coachSectionWithTypes)}
                <Divider className={classes.divider} />
                {renderSection(EmployeeType.Provider, providerSectionWithTypes)}
                {(!patientId && targetAppointments && targetAppointments.length > 0) && <Box mt={2}>
                    <Box className="wh-tw-text-xxs wh-tw-text-gray600">By joining the session, you consent to the session being recorded, subject to your opt-out preferences in your account settings. You may opt-out by <span className="wh-tw-cursor-pointer wh-tw-text-xxs wh-tw-font-semibold wh-tw-text-mint600" onClick={handleGoToMyProfile}>clicking here to change your settings</span>.</Box>
                </Box>}
                <Dialog
                    open={isManageOpen}
                    onClose={() => handleToggleManage(0, false)}
                    className={classes.dialog}
                >
                    {
                        !isSmallScreen &&
                        <DialogTitle
                            id="dialog-title"
                            onClose={() => handleToggleManage(0, false)}
                        >
                            <Box px={1} className="wh-tw-text-xl wh-tw-text-black wh-tw-font-medium">
                                Manage Appointment
                            </Box>
                        </DialogTitle>
                    }
                    <DialogContent className={classes.dialogContent}>
                        <Box width={1}>
                            {
                                isSmallScreen &&
                                <Box py={1} textAlign="center">
                                    <span className="wh-tw-text-xl wh-tw-text-black wh-tw-font-medium">
                                        Manage Appointment
                                    </span>
                                </Box>
                            }
                            <Box my={2}>
                                <Box>
                                    <span className="wh-tw-text-gray600">Data shows, patients who meet more frequently with their providers have greater outcomes and improvements. Consider rescheduling instead of cancelling!</span>
                                </Box>
                                <Box mt={2.5}>
                                    <span className="wh-tw-text-gray600">While we don’t charge for cancellations, we ask that you please make any changes at least 48-hours prior to your scheduled appointment time.</span>
                                </Box>
                            </Box>
                            {
                                isSmallScreen &&
                                <Box py={1} textAlign="center">
                                    <Box>
                                        <WildHealthButton
                                            id="reschedule-appointment-button"
                                            onClick={() => handleReschedule()}
                                            size='large'
                                            fullWidth>
                                            Reschedule
                                        </WildHealthButton>
                                    </Box>
                                    <Box mt={1}>
                                        <WildHealthButton
                                            id="cancel-appointment-button"
                                            onClick={() => handleCancel()}
                                            size='large'
                                            color='secondary'
                                            border='none'
                                            fullWidth>
                                            Cancel Appointment
                                        </WildHealthButton>
                                    </Box>
                                </Box>
                            }
                        </Box>
                    </DialogContent>
                    {
                        !isSmallScreen &&
                        <DialogActions classes={{ root: classes.dialogActions }}>
                            <WildHealthButton
                                id="cancel-appointment-button"
                                onClick={() => handleCancel()}
                                size='large'
                                color='secondary'
                                border='none'
                                borderRadius={50}>
                                Cancel Appointment
                            </WildHealthButton>

                            <WildHealthButton
                                id="reschedule-appointment-button"
                                onClick={() => handleReschedule()}
                                size='large'
                                borderRadius={50}>
                                Reschedule
                            </WildHealthButton>
                        </DialogActions>
                    }
                </Dialog>
                {
                    props.patientId && <>
                        <Box>
                            <Divider className={classes.divider} />
                        </Box>
                        <AppointmentOptionsDialogComponent patientId={props.patientId} />
                    </>
                }
            </Box>
        )
    }

    return (
        <Card className={classes.root} variant="elevation" elevation={0}>
            <CardContent className={classes.cardContent}>
                <Typography className={classes.title} gutterBottom>
                    Upcoming Appointments
                </Typography>
                <Box className="wh-tw-mt-4">
                    {renderContent()}
                </Box>
            </CardContent>
        </Card>
    );
}