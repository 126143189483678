import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { colors } from "../../../common/constants/colors";

type Props = {
  isShow: boolean;
};

export const useStyles = makeStyles<Theme, Props>((theme: Theme) =>
    createStyles({
        tooltip: {
            position: ({ isShow }) => isShow ? 'relative' : 'absolute',
            background: colors.white,
            color: colors.black,
            border: `1px solid ${colors.stroke}`,
            boxShadow: ` 0px 4px 9px rgba(168, 173, 198, 0.15)`,
            borderRadius: 2,
            padding: 16,
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 14,
            display: ({ isShow }) => isShow ? 'flex' : 'none',
            width: 'max-content',
            maxWidth: '50vw',
            zIndex: 11,
            [theme.breakpoints.down('sm')]: {
                maxWidth: '80vw',
            },
        },
        patientInfo: {
            minWidth: 150,
            fontSize: 14,
            wordBreak: 'break-word'
        },
        infoTitle: {
            minWidth: 70,
            fontSize: 14,
        },
        infoTitleSecondaty: {
            minWidth: 110,
            fontSize: 14,
        },
        patientSubtext: {
            color: colors.gray1
        }

    }),
);
