import { useEffect, useState, useCallback } from "react";
import { Subscription } from "rxjs";
import { groupBy, mapValues } from 'lodash';
import moment from "moment";
import { onEmit } from "../../../common/helpers/on-emit";
import { LabGroup, LabInputModel, FileInputModel, FileInputType } from "../../models/input.models";
import { LabInputsDatasetViewModel } from "../../models/input.models";
import { patientInputsService } from "../../services/patientInputs.service";
import { inputsQuery } from "../../stores/inputsStore";
import { sortLabInputDataSets } from "../../helpers/sort-lab-input-data-sets";
import { toCurrentTimeZone } from "../../../timezones/helpers/timezone";
import { CheckboxDropdownItem } from "../../../common/models/checkboxDropdown.models";

interface LabInputSectionViewModel {
    group: LabGroup;
    expanded: boolean;
}

interface PatientLabInputsComponentState {
    isLoading: boolean;
    isHighlightExpand: boolean;
    labInputs: LabInputModel[];
    sections: LabInputSectionViewModel[];
    files: FileInputModel[];
    isActionsOpen: boolean;
    dataSets: LabInputsDatasetViewModel[];
    hiddenDataSets: string[];
    showDataSet: string;
}

const AllKey = "ALL";

export function useFacade(): [
    PatientLabInputsComponentState,
    (group: LabGroup) => void,
    () => void,
    (fileId: number) => void,
    () => CheckboxDropdownItem[],
    (dataSetId: string) => void,
    () => void,
    () => CheckboxDropdownItem[],
    (dataSetId: string) => void,
] {
    const [state, setState] = useState({
        isLoading: true,
        isHighlightExpand: true,
        labInputs: [],
        sections: [
            {
                group: LabGroup.Lipids,
                expanded: true
            },
            {
                group: LabGroup.Methylation,
                expanded: true
            },
            {
                group: LabGroup.VitaminsAndMicronutrients,
                expanded: true
            },
            {
                group: LabGroup.InsulinResistanceAndMetabolism,
                expanded: true
            },
            {
                group: LabGroup.Hormones,
                expanded: true
            },
            {
                group: LabGroup.Inflammation,
                expanded: true
            },
            {
                group: LabGroup.CBC,
                expanded: true
            },
            {
                group: LabGroup.Metabolic,
                expanded: true
            },
            {
                group: LabGroup.Other,
                expanded: true
            }
        ],
        files: [],
        dataSets: [],
        hiddenDataSets: [],
        showDataSet: '',
    } as PatientLabInputsComponentState);

    const getDataSets = useCallback((inputs: LabInputModel[]): LabInputsDatasetViewModel[] => {
        const allValues = inputs.flatMap(x => x.values);
  
        const dataSets = groupBy(allValues, 'dataSetId');

        const result = mapValues(dataSets, values => ({
            id: values[0].dataSetId,
            date: toCurrentTimeZone(values[0].date),
            selected: true,
            values
        } as LabInputsDatasetViewModel));
    
        return Object.values(result).sort(sortLabInputDataSets) as LabInputsDatasetViewModel[];
    }, []);

    const handleHighlightExpand = () => {
        setState(state => ({ ...state, isHighlightExpand: !state.isHighlightExpand }));
    }

    const handleExpand = (group: LabGroup) => {
        const section = state.sections.find(x => x.group === group);
        section.expanded = !section.expanded;
        setState({ ...state, sections: state.sections });
    }

    const handleToggleActions = () => {
        setState(state => ({
            ...state,
            isActionsOpen: !state.isActionsOpen,
        }));
    }

    const handleView = (fileId: number) => {
        const cb = () => {
            setState(state => ({
                ...state,
                isActionsOpen: false,
            }));
        }

        const input = state.files.find(i => i.id === fileId);
        if (input) {
            setState(state => ({
                ...state,
                selectedFile: input,
            }));

            const action = patientInputsService.viewinline(input);
            action.subscribe(cb, cb);
        }
    }

    const getDropDownItems = (): CheckboxDropdownItem[] => {
        if (!state.dataSets.length) {
            return [];
        }

        const dataSets = state.dataSets
           .sort(sortLabInputDataSets)
           .map(dataSet => ({
                key: dataSet.id,
                name: moment(dataSet.date).format("MM/DD/YYYY"),
                selected: !state.hiddenDataSets.includes(dataSet.id)
            }));

        return [
            {
                key: AllKey,
                name: "View All Labs",
                selected: dataSets.every(x => x.selected === true)
            },
        ].concat(dataSets)
    }

    const handleColumnCheck = (dataSetId: string) => {
        switch (dataSetId) {
            case AllKey:
                setState({
                    ...state,
                    hiddenDataSets: []
                });
                return;
            default:
                const hiddenDataSets = state.hiddenDataSets.some(id => id === dataSetId)
                    ? state.hiddenDataSets.filter(id => id !== dataSetId)
                    : [...state.hiddenDataSets, dataSetId]
                setState({
                    ...state,
                    hiddenDataSets: hiddenDataSets
                });
                return;
        }
    }

    const getDropDownItemsForMobile = (): CheckboxDropdownItem[] => {
        if (!state.dataSets.length) {
            return [];
        }

        const dataSets = state.dataSets
           .sort(sortLabInputDataSets)
           .map(dataSet => ({
                key: dataSet.id,
                name: moment(dataSet.date).format("MM/DD/YYYY"),
                selected: state.showDataSet === dataSet.id
            }));

        return dataSets;
    }

    const handleColumnCheckForMobile = (dataSetId: string) => {
        setState({
            ...state,
            showDataSet: dataSetId
        });
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<LabInputModel[]>(inputsQuery.labInputs$, labInputs => {
                setState(state => ({
                    ...state,
                    labInputs: labInputs,
                    dataSets: labInputs && labInputs.length ? getDataSets(labInputs) : [],
                    showDataSet: labInputs && labInputs.length ? getDataSets(labInputs)?.[0]?.id : ''
                }));
            }),
            onEmit<FileInputModel[]>(inputsQuery.uploads$, files => {
                setState(state => ({
                    ...state,
                    files: files.filter(x => x.type === FileInputType.LabResults),
                }));
            })
        ];

        patientInputsService.getUploads().subscribe();
        patientInputsService.getLabInputs().subscribe(
            () => setState(state => ({ ...state, isLoading: false })),
            () => setState(state => ({ ...state, isLoading: false }))
        );

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [state, handleExpand, handleToggleActions, handleView, getDropDownItems, handleColumnCheck, handleHighlightExpand, getDropDownItemsForMobile, handleColumnCheckForMobile];
}
