import React from 'react';
import {Box} from '@material-ui/core';
import {PageSliderComponent} from "../../../../common/components/PageSliderComponent";
import {QuestionnaireSurveyResultProps} from "./questionnaireSurveyResultProps";
import {InitialQuestionNames} from "../../../models/questionnaire.models";
import {QuestionnairesResultListComponent} from "../../questionnairesResultListComponent/QuestionnairesResultListComponent";

export const MedicalSurveyResult: React.FC<QuestionnaireSurveyResultProps> = (props: QuestionnaireSurveyResultProps) => {
    const {
        result,
        gender,
        questionnaire,
        navigationContext
    } = props;

    return (
        <>
            <QuestionnairesResultListComponent
                gender={gender}
                questionnaire={questionnaire}
                result={result}
                questions={[
                    InitialQuestionNames.MEDICAL_CONDITIONS,
                    InitialQuestionNames.FAMILY_MEDICAL_CONDITIONS,
                    InitialQuestionNames.SMOKING_STATUS,
                    InitialQuestionNames.DIABETES_STATUS,
                    InitialQuestionNames.CANCER_SCREENING,
                    InitialQuestionNames.HEALTH_FEARS,
                    InitialQuestionNames.CURRENT_MEDICATIONS,
                    InitialQuestionNames.CURRENT_MEDICATIONS_SUPPLEMENT
                ]}
                title="Medical Information:"
            />
            <Box pt={5}>
                <PageSliderComponent context={navigationContext}/>
            </Box>
        </>
    )
}
