import React from 'react';
import { withRouter } from 'react-router';
import { AuthenticatedLayoutComponent } from '../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from "../../../../models/menu.models";
import { ManageMessagingComponent } from '../../components/manageMessagingComponent/ManageMessagingComponent';

class ManageMessagesPage extends React.Component<any> {
    render() {
        return (
            <AuthenticatedLayoutComponent
                hideBackground
                title={TitlesService.messagingAdminPanelTitle}
                pageName={PagesNamesService.messagingAdminPanel}
                selectedMenuItem={MenuItemTypes.MessagingAdminPanel}
            >
                <ManageMessagingComponent />
            </AuthenticatedLayoutComponent>
        )
    }
}

export default withRouter(ManageMessagesPage);
