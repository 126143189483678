import { Store, StoreConfig } from '@datorama/akita';
import {ApplicationInformationModel, TutorialStatusModel} from "../../models/application.models";
import { MenuItem } from '../../models/menu.models';
import { BannerModel } from '../../models/banner.model';
import {ExternalAuthorizationProviderModel} from "../../modules/auth/models/auth.models";

export interface ApplicationState {
    isSideBarOpen: boolean;
    information: ApplicationInformationModel;
    menuItems: MenuItem[];
    banners: BannerModel[];
    tutorialStatus: TutorialStatusModel;
    authorizationProviders: ExternalAuthorizationProviderModel[];
}

/**
 * Creates initial state
 */
export function createInitialState(): ApplicationState {
    return {
        information: null,
        isSideBarOpen: false,
        menuItems: [],
        banners: [],
        tutorialStatus: null,
        authorizationProviders: []
    };
}

/**
 * Provides application state management
 */
@StoreConfig({name: 'application', resettable: true })
export class ApplicationStore extends Store<ApplicationState> {
    constructor() {
        super(createInitialState());
    }

    public removeBanner(bannerId: number) {
        this.update({ banners: this.getValue().banners.filter(i => i.id !== bannerId) });
    }
}

export const applicationStore = new ApplicationStore();
