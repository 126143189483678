import Axios from 'axios-observable';
import { authHeader } from '../../common/helpers/auth-header';
import { snackService } from '../../common/snack/state';
import { Observable } from 'rxjs';
import { coursesStore, CoursesStore } from '../stores/courses';

export class CoursesService {
  private url = `${process.env.REACT_APP_API_URL}lms`;

  constructor(private coursesStore: CoursesStore) {
  }

  public getCourses(): Observable<any> {
    const config = { headers: authHeader() };
    return new Observable(observer => {
      Axios.get(`${this.url}/courses/challenge`, config).pipe()
        .subscribe(
          response => {
            this.coursesStore.setCourses(response.data);
            observer.next(response.data);
            observer.complete();
          },
          error => {
            snackService.commonErrorHandler(error);
            observer.error(error);
            observer.complete();
          }
        );
    });
  }

  public getPatientCourseHistoriesByUserId(userId: number): Observable<any> {
    const config = { headers: authHeader() };

    return new Observable(observer => {
      Axios.get(`${this.url}/users/${userId}`, config).pipe()
        .subscribe(
          response => {
            this.coursesStore.setPatientCourseHistories(response.data);
            observer.next(response.data);
            observer.complete();
          },
          error => {
            snackService.commonErrorHandler(error);
            observer.error(error);
            observer.complete();
          }
        );
    });
  }

  public getPatientCourseHistoriesByPatientId(patientId: number): Observable<any> {
    const config = { headers: authHeader() };

    return new Observable(observer => {
      Axios.get(`${this.url}/patients/${patientId}`, config).pipe()
        .subscribe(
          response => {
            this.coursesStore.setPatientCourseHistories(response.data);
            observer.next(response.data);
            observer.complete();
          },
          error => {
            snackService.commonErrorHandler(error);
            observer.error(error);
            observer.complete();
          }
        );
    });
  }

  public assignCourse(userId: number, courseId: string): Observable<any> {
    const config = { headers: authHeader() };
    return new Observable(observer => {
      Axios.post(`${this.url}/users/${userId}/Courses/${courseId}`, null, config).pipe()
        .subscribe(
          response => {
            snackService.success("Course successfully assigned!");
            observer.next(response.data);
            observer.complete();
          },
          error => {
            snackService.commonErrorHandler(error);
            observer.error(error);
            observer.complete();
          }
        );
    });
  }

  public assignCourseByPatientId(patientId: number, courseId: string): Observable<any> {
    const config = { headers: authHeader() };
    return new Observable(observer => {
      Axios.post(`${this.url}/patients/${patientId}/Courses/${courseId}`, null, config).pipe()
        .subscribe(
          response => {
            snackService.success("Course successfully assigned!");
            observer.next(response.data);
            observer.complete();
          },
          error => {
            snackService.commonErrorHandler(error);
            observer.error(error);
            observer.complete();
          }
        );
    });
  }

  public delete(userId: number, courseId: string): Observable<any> {
    const config = { headers: authHeader() };

    return new Observable<any>(observer => {
      Axios.delete<any>(`${this.url}/users/${userId}/Courses/${courseId}`, config).pipe()
        .subscribe(
          response => {
            snackService.success('Course successfully unassingned!')
            observer.next(response.data);
            observer.complete();
          },
          error => {
            snackService.error('Failed to unassign course!');
            observer.error(error);
            observer.complete();
          }
        );
    })
  }

  public deleteCourseByPatientId(patientId: number, courseId: string): Observable<any> {
    const config = { headers: authHeader() };

    return new Observable<any>(observer => {
      Axios.delete<any>(`${this.url}/patients/${patientId}/Courses/${courseId}`, config).pipe()
        .subscribe(
          response => {
            snackService.success('Course successfully unassingned!')
            observer.next(response.data);
            observer.complete();
          },
          error => {
            snackService.error('Failed to unassign course!');
            observer.error(error);
            observer.complete();
          }
        );
    })
  }
}

export const coursesService = new CoursesService(coursesStore);
