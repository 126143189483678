import {Query} from '@datorama/akita';
import { PatientMedicationsState, PatientMedicationsStore, patientMedicationsStore } from './patientMedications.store';

/**
 * Provides coaches queries
 */
export class PatientMedicationsQuery extends Query<PatientMedicationsState> {

    patientMedications$ = this.select(state => state.patientMedications);
    rxntMedications$ = this.select(state => state.rxntMedications);
    editModel$ = this.select(state => state.editModel);

    constructor(protected store: PatientMedicationsStore) {
        super(store);
    }
}

export const patientMedicationsQuery = new PatientMedicationsQuery(patientMedicationsStore);