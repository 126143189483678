import {HealthSummaryDataModel, HealthSummaryMapModel} from '../../models/healthSummary.models'
import {healthSummaryService} from '../../services/healthSummary.service'
import {icdCodesService} from '../../../notes/services/icdCodes.service'
import {buildKey} from '../../helpers/healthSummaryHelper'
import {healthSummaryQuery} from '../../stores'
import {Observable} from 'rxjs'
import {useState} from 'react'
import {useFlags} from "launchdarkly-react-client-sdk";
import {isFeatureFlag} from "../../../common/components/featureFlags/featureFlags";
import {FeatureFlag} from "../../../common/components/featureFlags/featureFlags.models";

export interface HealthSummaryListItemsComponentState {
    isEditorOpened: boolean
    isMenuOpened: boolean
    editRowValue: string
    selectedKey: string
    editRowName: string
    isLoading: boolean
    addNewRow: boolean
    anchorEl: any
    editMode: boolean
}

export function useFacade(map: HealthSummaryMapModel, patientId?: number): [
    (searchQuery: string) => Observable<string[]>,
    Function,
    Function,
    Function,
    Function,
    Function,
    Function,
    Function,
    Function,
    Function,
    Function,
    Function,
    HealthSummaryListItemsComponentState,
    boolean
] {
    const [state, setState] = useState({
        isEditorOpened: false,
        isMenuOpened: false,
        selectedKey: null,
        addNewRow: false,
        isLoading: false,
        anchorEl: null,
        editMode: false,
    } as HealthSummaryListItemsComponentState);

    const flags = useFlags();

    const setInitialState = () => {
        setState({
            ...state,
            isEditorOpened: false,
            selectedKey: null,
            addNewRow: false,
            editRowValue: '',
            isLoading: false,
            editRowName: '',
            editMode: false,
        })
    }

    const handleToggleActions = (key: string, anchorEl: Element) => {
        setState(state => ({
            ...state,
            isMenuOpened: !state.isMenuOpened,
            anchorEl: anchorEl,
            selectedKey: key
        }))
    }

    const handleEdit = () => {
        const dataItem = healthSummaryQuery.getDataItem(state.selectedKey)

        setState({
            ...state,
            editRowName: dataItem.name || healthSummaryQuery.getDataItemName(state.selectedKey),
            editRowValue: dataItem.value,
            isEditorOpened: true,
            isMenuOpened: false
        })
    }

    const handleDelete = () => {
        setState({
            ...state,
            isMenuOpened: false,
            selectedKey: null,
            anchorEl: null
        })

        healthSummaryService.delete(patientId, map, state.selectedKey, flags)
    }

    const handleEditRowName = (value: string) => {
        setState({
            ...state,
            editRowName: value
        })
    }

    const handleEditRowValue = (value: string) => {
        setState(state => ({
            ...state,
            editRowValue: value
        }))
    }

    const handleAddNewRow = () => {
        setState({
            ...state,
            addNewRow: true
        })
    }

    const getIcdCodesAutoComplete = (searchQuery: string): Observable<string[]> => {
        return new Observable<string[]>((observer) => {
            icdCodesService.get(searchQuery).subscribe(
                (data) => {
                    observer.next(data.map((x) => x.code + ' ' + x.description));
                    observer.complete();
                },
                error => {
                    observer.next([]);
                    observer.complete();
                }
            )
        })
    }

    const handleBlur = (e, value) => {
        if (value) {
            setState(state => ({
                ...state,
                editRowValue: value
            }))
        }

        if (e?.currentTarget?.id.includes('list-edit') && e?.relatedTarget) {
            return
        }

        if (state.editRowName && state.editRowValue) { 
            saveChanges({name: state.editRowName, value: state.editRowValue})
            return
        }

        if (state.addNewRow && !state.editRowName && !state.editRowValue) {
            setInitialState()
            return
        }
    }

    const parseValue = (value: string) => { 
        if (!value) {
            return ''
        }

        const arr = value.split(' ')

        if (!arr || !arr.length) {
            return ''
        }

        return arr;
    }

    const saveValue = (value: string) => {
        const parsedValue = parseValue(value)

        if (!parsedValue) { 
            return
        }

        const editValue = parsedValue.length > 1 ? parsedValue[0] : ''
        const editName = parsedValue.length > 1 ? value.replace(editValue, '').trim() : value

        setState(state => ({
            ...state,
            editRowValue: editValue,
            editRowName: editName
        }))

        const result = {name: editName, value: editValue}

        saveChanges(result)
    }
    
    const saveChanges = ({name, value}) => {
        if (map.useNameValue && (!name || !value)) { 
            setInitialState()
            return
        }

        setState(state => ({...state, isLoading: true }))
        
        const isNew = !state.selectedKey

        const model = { 
            key: isNew ? buildKey(map.key) : state.selectedKey, 
            name: name ?? state.editRowName, 
            value: value ?? state.editRowValue
        } as HealthSummaryDataModel

        const callback = () => {
            if (map.useJsonData) {
                const service = healthSummaryService.getJsonDataService(map.key, flags)
                service.get(patientId)
            }

            setInitialState()
        }

        isNew 
            ? healthSummaryService.create(patientId, map, model, flags).subscribe(callback, callback)
            : healthSummaryService.update(patientId, map, model, flags).subscribe(callback, callback)
    }

    const cancelChanges = () => {
        setInitialState()
        return
    }

    const handleEditMode = () => {
        setState(state => ({...state, editMode: true, addNewRow: true }))
    }

    const saveNewChanges = () => {
        if (state.editRowName && state.editRowValue) { 
            if (map.key === 'PROBLEMS_LIST') {
                return
            }
            saveChanges({name: state.editRowName, value: state.editRowValue})
            return
        }

        if (state.addNewRow && !state.editRowName && !state.editRowValue) {
            setInitialState()
            return
        }
    }

    return [
        getIcdCodesAutoComplete,
        handleToggleActions,
        handleEditRowValue,
        handleEditRowName,
        handleAddNewRow,
        handleDelete,
        handleEdit,
        handleBlur,
        saveValue,
        cancelChanges,
        handleEditMode,
        saveNewChanges,
        state,
        isFeatureFlag(flags, FeatureFlag.RxntIntegration)
    ]
}