import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles({
    btnLink: {
        border: 'none',
        backgroundColor: colors.white,
        color: colors.main,
        fontSize: 16,
        padding: 0,
        cursor: 'pointer',
        paddingLeft: '4px',
        paddingRight: '4px',
    },
    textWithLink: {
        textAlign: 'center',
    },
    agreementsWrap: {
        color: colors.gray1,
        width: '100%', 
        display: "flex",
        justifyContent: "center",
    },
    agreements: {
        display: "flex", 
        maxWidth: 634, 
        flexWrap: "wrap", 
        alignItems: "center", 
        justifyContent: "center",
    },
    error: {
        color: colors.bad,
    }
});