
import React from "react";
import {EmployeeModel, EmployeeShortModel} from "../../../employee/models/employee.models";
import { Divider, FormControl, MenuItem, Select } from "@material-ui/core";

interface SelectEmployeeComponentProps {
    selectedEmployee: EmployeeShortModel | EmployeeModel,
    employees: EmployeeShortModel[] | EmployeeModel[],
    onEmployeeChange: (employeeId: any) => void,
    me?: EmployeeShortModel | EmployeeModel,
    menuItem?: (employee: EmployeeShortModel | EmployeeModel) => JSX.Element,
    disabled?: boolean,
    variant?: 'outlined' | 'standard' | 'filled'
}

export const SelectEmployeeComponent: React.FC<SelectEmployeeComponentProps> = (props: SelectEmployeeComponentProps) => {
    const {
        me = null,
        selectedEmployee,
        employees,
        onEmployeeChange,
        menuItem = (employee: EmployeeShortModel | EmployeeModel): JSX.Element => (<span>{`${employee.firstName} ${employee.lastName}`}</span>),
        disabled = false,
        variant = 'outlined'
    } = props;    

    return (
        <FormControl
            variant={variant}
            color='primary'
            size='small'
            fullWidth
            disabled={disabled}
        >
            <Select
                disableUnderline
                value={selectedEmployee ? selectedEmployee.id : null}
                onChange={(event) => onEmployeeChange(event.target.value)}>
                {
                    me && <MenuItem value={me.id}> Me </MenuItem>
                }
                {
                    me && <Divider />
                }
                {
                    (employees as Array<EmployeeShortModel | EmployeeModel>).map((employee, index) => (
                        <MenuItem key={index} value={employee.id}>
                            {
                                menuItem(employee)
                            }
                        </MenuItem>
                        )
                    )
                }
            </Select>
        </FormControl>
    );
}

