import React from 'react';
import { authQuery } from '../../auth/stores/auth';
import { PermissionType, UserType } from '../../auth/models/auth.enums';

export interface ProtectedElementProps {
    element: any;
    defaultContent?: any;
    permissions: PermissionType[];
    userType?: UserType;
}

export const isElementEnabled = (permissions: PermissionType[], userType: UserType) => {
    switch(userType){
        case UserType.Patient: return authQuery.getType() === UserType.Patient;
        case UserType.Employee: return permissions.length 
            ? authQuery.getPermissions().some(p => permissions.includes(p))
            : authQuery.getType() === UserType.Employee;
        default: return false;
    }
}

export const ProtectedElement: React.FC<ProtectedElementProps> = (props: ProtectedElementProps) => {
    const {
        element,
        defaultContent = '',
        permissions,
        userType = UserType.Employee
    } = props;

    return (isElementEnabled(permissions, userType) ? element : defaultContent);
};

