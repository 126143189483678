import { createStyles, makeStyles, Theme } from "@material-ui/core";
import {colors} from "../../../common/constants/colors";


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sections: {
            backgroundColor: colors.white,
            padding: 30,
            height: '100%',
            paddingBottom: 0,
            [theme.breakpoints.down("xs")]: {
                padding: 16,
            },
        },
        emptySections: {
            backgroundColor: colors.gray3,
            height: '100%',
            justifyContent: 'center',
        },
        emptyContainer: {
            position: 'absolute',
            background: 'rgba(236, 238, 239, 0.5)',
            top: 0,
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.down("xs")]: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
            },
        },
        title: {
            fontWeight: 600,
            fontSize: 24,
        },
        subTitle: {
            padding: 24,
        },
        banner: {
            backgroundColor: colors.white,
            width: '100%',
            padding: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 16,
            [theme.breakpoints.down("xs")]: {
                display: 'inherit',
                padding: '16px',
            },
        },
        nextUp: {
            backgroundColor: colors.average2,
            padding: '5px 10px 5px 10px',
            fontSize: '14px',
            width: 'auto',

            [theme.breakpoints.down("xs")]: {
                width: 'fit-content',
            },
        },
        bannerMessage: {
            width: '75%',
            textAlign: 'center',

            [theme.breakpoints.down("xs")]: {
                textAlign: 'left',
                width: '100%',
                marginTop: 16
            },
        },

    }),
);