import React, {Component} from "react";
import {ErrorBoundaryComponent} from '../../../modules/common/components/errorBoundaryComponent/ErrorBoundaryComponent';
import {RoutesConstants} from "../../../constants/route.constants";
import {withScope, captureException} from '@sentry/browser'
type ErrorPageProps = {
    history: any
}

type State = {
    hasError: boolean
}

export default class ErrorBoundaryPage extends Component<ErrorPageProps, State> {

    state: State;

    constructor(props) {
        super(props)
        this.state = {hasError: false};
        this.goToDashboard = this.goToDashboard.bind(this);
    }

    static getDerivedStateFromError(error) {
        return {hasError: true}
    }

    componentDidCatch(error, errorInfo) {
        try {
            withScope((scope) => {
                const errorBoundaryError = new Error(error.message);
                errorBoundaryError.name = `ErrorBoundary Rendered with: ${errorBoundaryError.name}`;
                errorBoundaryError.stack = errorInfo.componentStack;
                error.cause=errorBoundaryError
                captureException(error, {contexts: {react: {componentStack: errorInfo.componentStack}}});
            });
        }catch (err){
            // if its an error on sentry integration, it should be quiet
        }
    }

    goToDashboard() {
        const {history} = this.props
        this.setState(state => ({hasError: !state.hasError}))
        history.push(RoutesConstants.dashboard)
    }

    render() {

        const {children} = this.props;
        const {hasError} = this.state;

        return hasError ? <ErrorBoundaryComponent key={'fb-error'} onClick={this.goToDashboard}/> : children
    }

}