import React from 'react';
import {Route, useHistory, useLocation} from 'react-router-dom';
import {RoutesConstants} from '../constants/route.constants';
import {MyAccountPage} from '../modules/account/pages/myAccountPage/MyAccountPage';
import {PatientMembershipPage} from '../modules/account/pages/patientMembershipPage/PatientMembershipPage';
import {ResetPasswordPage} from '../modules/account/pages/resetPassword/ResetPasswordPage';
import CoachAppointmentsPage from './../modules/appointments/pages/coachAppointmentsPage/CoachAppointmentsPage';
import PatientPastAppointmentsPage
    from './../modules/appointments/pages/patientPastAppointmentsPage/PatientPastAppointmentsPage';
import PatientUpcommingAppointmentsPage
    from './../modules/appointments/pages/patientUpcommingAppointmentsPage/PatientUpcommingAppointmentPage';
import Loadable from 'react-loadable';
import {PermissionType, Practice, UserType} from "../modules/auth/models/auth.enums";
import {ForgotPasswordPage} from '../modules/auth/pages/forgotPasswordPage/ForgotPasswordPage';
import {LoginOnBehalfPage} from "../modules/auth/pages/loginOnBehalfPage/LoginOnBehalfPage";
import {LoginPageComponent} from '../modules/auth/pages/loginPage/LoginPage';
import {RestorePasswordPage} from "../modules/auth/pages/restorePasswordPage/RestorePasswordPage";
import {SetUpPasswordPage} from "../modules/auth/pages/setUpPasswordPage/SetUpPasswordPage";
import {VerifyProfilePage} from "../modules/auth/pages/verifyProfilePage/VerifyProfilePage";
import {AuthenticatedRoute} from '../modules/common/components/AuthenticatedRoute';
import {AuthorizedRoute} from "../modules/common/components/AuthorizedRoute";
import {
    EmployeeConversationsPage
} from "../modules/conversations/pages/employeeConversationsPage/EmployeeConversationsPage";
import {MessageSettingsPage} from "../modules/conversations/pages/messageSettingsPage/MessageSettingsPage";
import {PatientChatPage} from "../modules/conversations/pages/patientChatPage/PatientChatPage";
import {
    PatientConversationsPage
} from "../modules/conversations/pages/patientConversationsPage/PatientConversationsPage";
import {PatientsSubmissionsPage} from "../modules/conversations/pages/patientsSubmissionsPage/PatientsSubmissionsPage";
import {DNAFilesMonitorPage} from '../modules/dnaFiles/pages/DnaFilesMonitorPage/DNAFilesMonitorPage';
import {ManageAllPatientsPage} from "../modules/employee/pages/manageAllPatients/ManageAllPatientsPage";
import {ManageEmployeesPage} from "../modules/employee/pages/manageEmployeesPage/ManageEmployeesPage";
import ManageIntakePatientsPage from "./../modules/employee/pages/manageIntakePatientsPage/ManageIntakePatientsPage";
import {ManagePatientProfilePage} from '../modules/employee/pages/managePatientProfilePage/ManagePatientProfilePage';
import {ManageShortcutsPage} from "../modules/employee/pages/manageShortcutsPage/ManageShortcutsPage";
import MyPatientsPage from "./../modules/employee/pages/myPatientsPage/MyPatientsPage";
import EngineeringAdminPage from "./../modules/employee/pages/engineeringAdminPage/EngineeringAdminPage";
import EmployerAdminPage from "./../modules/employee/pages/employerAdminPage/EmployerAdminPage";
import {ManagePromoCodesPage} from "../modules/employee/pages/managePromoCodesPage/ManagePromoCodesPage";
import {ManageBannersPage} from "../modules/employee/pages/manageBannersPage/ManageBannersPage";
import SyncRecordsDashboardPage from "../modules/syncRecords/pages/syncRecordsDashboardPage/SyncRecordsDashboardPage";
import {SyncRecordTypePage} from "../modules/syncRecords/pages/syncRecordTypePage/SyncRecordTypePage";
import {SyncRecordListPage} from "../modules/syncRecords/pages/syncRecordListPage/SyncRecordListPage"
import {SyncRecordReconcilePage} from "../modules/syncRecords/pages/syncRecordReconcilePage/SyncRecordReconcilePage"
import {SubmitPatientPage} from "../modules/employee/pages/submitPatientPage/SubmitPatientPage";
import {FellowPatientsPage} from "../modules/fellowship/pages/FellowPatients/FellowPatientsPage";
import {FellowshipGetStartedPage} from "../modules/fellowship/pages/FellowshipGetStarted/FellowshipGetStartedPage";
import {
    PMFellowshipGetStartedPage
} from "../modules/fellowship/pages/PMFellowshipGetStarted/PMFellowshipGetStartedPage";
import {FellowshipPage} from "../modules/fellowship/pages/FellowshipPage/FellowshipPage";
import {
    FellowshipSubmissionReceivedPage
} from "../modules/fellowship/pages/FellowshipSubmissionReceived/FellowshipSubmissionReceivedPage";
import {HabitScorePage} from "../modules/habitScorePage/pages/HabitScorePage";
import {PatientHealthReportPage} from "../modules/healthReport/pages/PatientHealthReportPage/PatientHealthReportPage";
import {PatientAddOnReportsPage} from "../modules/healthReport/pages/PatientAddOnReportsPage/PatientAddOnReportsPage";
import {HealthScorePage} from "../modules/healthScore/pages/HealthScorePage";
import {PatientDataFilesPage} from "../modules/inputs/pages/patientDataFilesPage/PatientDataFilesPage";
import {PatientGeneralPage} from "../modules/inputs/pages/patientGeneralPage/PatientGeneralPage";
import {PatientDocumentsPage} from "../modules/inputs/pages/patientDocumentsPage/PatientDocumentsPage";
import {PatientLabsPage} from "../modules/inputs/pages/patientLabsPage/PatientLabsPage";
import {PatientMicrobiomePage} from "../modules/inputs/pages/patientMicrobiomePage/PatientMicrobiomePage";
import {LeadSourcesPage} from "../modules/leadSources/pages/LeadSourcesPage";
import {CreateFollowUpNotePage} from "../modules/notes/pages/createFollowUpNotePage/CreateFollowUpNotePage";
import {
    CreateInitialConsultNotePage
} from "../modules/notes/pages/createInitialConsultNotePage/CreateInitialConsultNotePage";
import {CreateSimpleNotePage} from "../modules/notes/pages/createSimpleNotePage/CreateSimpleNotePage";
import {PatientBlankPage} from "../modules/notes/pages/patientBlankPage/PatientBlankPage";
import {PatientFollowUpPage} from "../modules/notes/pages/patientFollowUp/PatientFollowUpPage";
import {PatientNotesPage} from "../modules/notes/pages/patientNotes/PatientNotesPage";
import {PatientOrdersPage} from "../modules/orders/pages/patientOrdersPage/PatientOrdersPage";
import {ReferralLandingPage} from '../modules/patients/pages/referralLandingPage/ReferralLandingPage';
import {
    PatientSupplementsPage
} from "../modules/patientSupplements/pages/patientSupplementsPage/PatientSupplementsPage";
import {BuyNewSubscriptionPage} from "../modules/payment/pages/buyNewSubscriptionPage/BuyNewSubscriptionPage";
import {
    BuySubscriptionSucceededPage
} from "../modules/payment/pages/buySubscriptionSucceededPage/BuySubscriptionSucceededPage";
import {ConfirmAgreementsPage} from "../modules/payment/pages/confirmAgreementsPage/ConfirmAgreementsPage";
import {GetStartedPage} from "../modules/payment/pages/getStartedPage/GetStartedPage";
import {ConsultationPage} from "../modules/payment/pages/consultationPage/ConsultationPage";
import {JoinPracticePage} from '../modules/payment/pages/joinPracticePage/JoinPracticePage';
import {JoinPracticeSucceededPage} from "../modules/payment/pages/joinPracticeSucceededPage/JoinPracticeSucceededPage";
import {PaymentSucceededPage} from "../modules/payment/pages/paymentSucceededPage/PaymentSucceededPage";
import {
    AnonymousInitialQuestionnaireSurveyPage
} from "../modules/questionnaire/pages/initialQuestionnaireSurveyComponent/InitialQuestionnaireSurveyComponent";
import {
    IntakeSpecialistQuestionnaireResultPage
} from "../modules/questionnaire/pages/intakeSpecialistQuestionnaireResultPage/IntakeSpecialistQuestionnaireResultPage";
import {PatientHealthFormsPage} from '../modules/questionnaire/pages/patientHealthFormsPage/PatientHealthFormsPage';
import {
    PatientQuestionnairePage
} from "../modules/questionnaire/pages/patientQuestionnairePage/PatientQuestionnairePage";
import {
    QuestionnaireCompletedPage
} from "../modules/questionnaire/pages/questionnaireCompletedPage/QuestionnaireCompletedPage";
import {StartQuestionnairePage} from "../modules/questionnaire/pages/startQuestionnairePage/StartQuestionnairePage";
import {VitalsHistoryPage} from "../modules/vital/pages/vitalsHistoryPage/VitalsHistoryPage";
import {PrivacyPolicyPage} from '../pages/privacyPolicyPage/PrivacyPolicyPage';
import {TermsOfServicePage} from '../pages/termsOfServicePage/TermsOfServicePage';
import {DraftNotesPage} from "../modules/notes/pages/draftNotesPage/DraftNotesPage";
import {AwaitingApprovalPage} from "../modules/awaitingApprovalPage/pages/AwaitingApprovalPage";
import {Box} from '@material-ui/core';
import WildHealthLinearProgress from '../modules/common/components/WildHealthLinearProgress';
import {authQuery} from '../modules/auth/stores/auth';
import {navigationService} from './navigation.service';
import {InsurancePage} from '../modules/insurance/pages/InsurancePage/InsurancePage';
import ManageInsurancesPage from "../modules/insurance/pages/ManageInsurancesPage/ManageInsurancesPage";
import InsuranceConfigurationsPage
    from "../modules/insurance/pages/InsuranceConfigurationsPage/InsuranceConfigurationsPage";
import {
    InsuranceConfigurationEditPage
} from "../modules/insurance/pages/InsuranceConfigurationEditPage/InsuranceConfigurationEditPage";
import {PatientHealthSummaryPage} from '../modules/healthSummary/pages/PatientHealthSummaryPage';
import {HistoryAndPhysicalNotePage} from '../modules/notes/pages/historyAndPhysicalNotePage/HistoryAndPhysicalNotePage';
import {
    HistoryAndPhysicalFollowUpNotePage
} from '../modules/notes/pages/historyAndPhysicalFollowUpNotePage/HistoryAndPhysicalFollowUpNotePage';
import {SoapNotePage} from '../modules/notes/pages/soapNotePage/SoapNotePage';
import {CreateSoapNotePage} from "../modules/notes/pages/createSoapNotePage/CreateSoapNotePage";
import {PatientSoapNotePage} from "../modules/notes/pages/patientSoapNotePage/PatientSoapNotePage";
import {
    PatientHistoryAndPhysicalNotePage
} from "../modules/notes/pages/PatientHistoryAndPhysicalNotePage/PatientHistoryAndPhysicalNotePage";
import {
    PatientHistoryAndPhysicalFollowUpNotePage
} from "../modules/notes/pages/patientHistoryAndPhysicalFollowUpNotePage/PatientHistoryAndPhysicalFollowUpNotePage";
import PatientNorthPassAcademyPage
    from './../modules/northPassAcademy/pages/patientNorthPassAcademyPage/PatientNorthPassAcademyPage';
import {
    AccountMembershipActivationPage
} from '../modules/payment/pages/accountMembershipActivationPage/AccountMembershipActivationPage';
import {SignOffNotesPage} from "../modules/notes/pages/signOffNotesPage/signOffNotesPage";
import {PatientNotePage} from "../modules/notes/pages/patientNotePage/PatientNotePage";
import {SignOffNotePage} from "../modules/notes/pages/SignOffNotePage/SignOffNotePage";
import ManageMessagesPage from '../modules/conversations/pages/manageMessagesPage/ManageMessagesPage';
import MessageForwardingPage from '../modules/conversations/pages/messageForwardingPage/MessageForwardingPage';
import PatientResponsibilityPage from '../modules/insurance/pages/PatientResponsibilityPage/PatientResponsibilityPage';
import {
    PatientHistoryAndPhysicalGroupVisitNotePage
} from "../modules/notes/pages/PatientHistoryAndPhysicalGroupVisitNotePage/PatientHistoryAndPhysicalGroupVisitNotePage";
import {
    HistoryAndPhysicalGroupVisitNotePage
} from "../modules/notes/pages/historyAndPhysicalGroupVisitNotePage/HistoryAndPhysicalGroupVisitNotePage";
import AlertsHistoryPage from '../modules/conversations/pages/alertsHistoryPage/AlertsHistoryPage';
import {PatientHealthPlanPage} from '../modules/healthPlan/pages/PatientHealthPlanPage';
import {UnmatchedLabsPage} from '../modules/unmatchedLabs/pages/unmatchedLabsPage/UnmatchedLabsPage';
import {DNAOrderDropshipPage} from '../modules/orders/pages/dnaOrderDropshipPage/DnaOrderDropshipPage';
import {
    HealthReportTemplatesPage
} from '../modules/healthReport/pages/HealthReportTemplatesPage/HealthReportTemplatesPage';
import ManageCommonGoalsPage from '../modules/employee/pages/manageCommonGoalsPage/ManageCommonGoalsPage';
import ManageCommonMdmsPage from '../modules/employee/pages/manageCommonMdmPage/ManageCommonMdmsPage';
import {ManageCommonGoalEditPage} from '../modules/employee/pages/manageCommonGoalEditPage/ManageCommonGoalEditPage';
import {ManageCommonMdmEditPage} from '../modules/employee/pages/manageCommonMdmEditPage/ManageCommonMdmEditPage';
import ManageCommonSupplementsPage
    from '../modules/employee/pages/manageCommonSupplementsPage/ManageCommonSupplementsPage';
import ManageCommonOrdersPage from '../modules/employee/pages/manageCommonOrdersPage/ManageCommonOrdersPage';
import {
    PatientInitialConsultBasePage
} from '../modules/notes/pages/patientInitialConsult/PatientInitialConsultPageBasic';
import {
    ManageRecommendationsPage
} from '../modules/recommendations/pages/manageRecommendationsPage/ManageRecommendationsPage';
import {ReferralOrderPagePage} from "../modules/orders/pages/referralOrderPage/ReferralOrderPage";
import {OtherOrderPagePage} from "../modules/orders/pages/otherOrderPage/OtherOrderPage";
import {OpsAdminToolsPage} from '../modules/employee/pages/opsAdminToolsPage/OpsAdminToolsPage';
import { PatientMyTasksPage } from '../modules/patients/pages/myTasksPage/MyTasksPage';
import { ManageKbDocumentsPage } from '../modules/kbDocuments/pages/manageKbDocumentsPage/ManageKbDocumentsPage';
import {Auth2CodePage} from "../modules/auth/pages/auth2CodePage/Auth2CodePage";
import {Auth2LoginPage} from "../modules/auth/pages/auth2LoginPage/Auth2LoginPage";
import {roles, isStaffRole, isStaffOrAdmin} from '../modules/common/constants/roles';
import {GenericPlaygroundPage} from "../modules/conversations/pages/genericPlaygroundPage/GenericPlaygroundPage";
import {
    ManageConversationTemplatesPage
} from '../modules/conversations/pages/manageConversationTemplatePage/ManageConversationTemplatesPage';
import {PreauthorizeSignUpPage} from "../modules/payment/pages/preauthorizeSignUpPage/PreauthorizeSignUpPage";
import {
    ThirdPartyIntegrationCodePage
} from "../modules/auth/pages/thirdPartyIntegrationCodePage/ThirdPartyIntegrationCodePage";
import {
    ThirdPartyIntegrationLoginPage
} from "../modules/auth/pages/thirdPartyIntegrationLoginPage/ThirdPartyIntegrationLoginPage";
import { ManageEmployersProfilePage } from '../modules/employee/pages/manageEmployersProfilePage/ManageEmployersProfilePage';
import { EmployerProfileDetailPage } from '../modules/employee/pages/employerProfileDetailPage/EmployerProfileDetailPage';
import {LoginRxNTPage} from "../modules/auth/pages/loginRxNTPage/LoginRxNTPage";

const NotFoundRedirect = () => {
    const history = useHistory();
    const location = useLocation();

    const fragment = <></>;

    if (!authQuery.isLoggedIn()) {
        navigationService.toLogin(history, (location.pathname + location.search).replace('//', '/'));
        return fragment;
    }

    navigationService.toDashboard(history);
    return fragment;
}

export function DashboardPreload() {
    const Loading = () => <Box m={5}><WildHealthLinearProgress /></Box>;

    const Dashboard = Loadable({
        loader: () => import('./../pages/dashboardPage/DashboardPage'),
        loading: Loading
    });

    Dashboard.preload();

    return Dashboard;
}

export const commonRoutes = (): JSX.Element[] => {
    const routesProps = [
        {
            path: `${RoutesConstants.consultationGetStarted}`,
            component: ConsultationPage,
        },
        {
            path: `${RoutesConstants.employerGetStarted}/:employerKey`,
            component: GetStartedPage,
        },
        {
            path: `${RoutesConstants.preauthorizeSignUp}/:requestId`,
            component: PreauthorizeSignUpPage
        },
        {
            path: `${RoutesConstants.getStarted}`,
            component: GetStartedPage,
        },
        {
            path: `${RoutesConstants.mobileGetStarted}`,
            component: GetStartedPage,
        },
        {
            path: RoutesConstants.paymentSuccess,
            component: PaymentSucceededPage,
        },
        {
            path: RoutesConstants.mobilePaymentSuccess,
            component: PaymentSucceededPage,
        },
        {
            path: `${RoutesConstants.healthQuestionnaire}/:intakeId`,
            component: AnonymousInitialQuestionnaireSurveyPage,
        },
        {
            path: RoutesConstants.restorePassword,
            component: RestorePasswordPage,
        },
        {
            path: RoutesConstants.setUpPassword,
            component: SetUpPasswordPage,
        },
        {
            path: RoutesConstants.forgotPassword,
            component: ForgotPasswordPage,
        },
        {
            path: RoutesConstants.mobileForgotPassword,
            component: ForgotPasswordPage,
        },
        {
            path: RoutesConstants.auth2Code,
            component: Auth2CodePage
        },
        {
            path: RoutesConstants.auth2Login,
            component: Auth2LoginPage
        },
        {
            path: RoutesConstants.healthQuestionnaireCompleted,
            component: QuestionnaireCompletedPage,
        },
        {
            path: `${RoutesConstants.joinPractice}/:practiceUrl`,
            component: JoinPracticePage,
        },
        {
            path: RoutesConstants.joinPracticeSuccess,
            component: JoinPracticeSucceededPage,
        },
        {
            path: RoutesConstants.fellowshipGetStarted,
            component: FellowshipGetStartedPage,
        },
        {
            path: RoutesConstants.pmFellowshipGetStarted,
            component: PMFellowshipGetStartedPage,
        },
        {
            path: RoutesConstants.fellowshipSubmissionReceived,
            component: FellowshipSubmissionReceivedPage,
        },
        {
            component: NotFoundRedirect,
        },
    ];

    return routesProps.map((props, index) =>
        React.createElement(Route, { key: `common-${index}`, ...props })
    );
}

export const basicRoutesWH = (): JSX.Element[] => {
    const routesProps = [
        {
            exact: true,
            path: RoutesConstants.default,
            component: LoginPageComponent,
        },
        {
            path: RoutesConstants.login,
            component: LoginPageComponent,
        },
        {
            path: RoutesConstants.privacyPolicy,
            component: PrivacyPolicyPage,
        },
        {
            path: RoutesConstants.termsOfService,
            component: TermsOfServicePage,
        },
        {
            path: RoutesConstants.paymentSuccess,
            component: PaymentSucceededPage,
        },
        {
            path: RoutesConstants.mobilePaymentSuccess,
            component: PaymentSucceededPage,
        },
    ];

    return routesProps.map((props, index) =>
        React.createElement(Route, { key: `wh-${index}`, ...props })
    );
}

export const patientRoutes = (): JSX.Element[] => {
    const authorizedRoutesProps = [
        {
            path: RoutesConstants.buyNewSubscription,
            component: BuyNewSubscriptionPage,
            userType: UserType.Patient,
            permissions: [PermissionType.None],
        },
        {
            path: RoutesConstants.accountMembershipActivation,
            component: AccountMembershipActivationPage,
            userType: UserType.Patient,
            permissions: [PermissionType.None],
        },
        {
            path: RoutesConstants.buySubscriptionSuccess,
            component: BuySubscriptionSucceededPage,
            userType: UserType.Patient,
            permissions: [PermissionType.None],
        },
        {
            path: RoutesConstants.mobileBuySubscriptionSuccess,
            component: BuySubscriptionSucceededPage,
            userType: UserType.Patient,
            permissions: [PermissionType.None],
        },
        {
            path: RoutesConstants.generalInputs,
            component: PatientGeneralPage,
            userType: UserType.Patient,
            permissions: [PermissionType.None],
        },
        {
            path: RoutesConstants.documents,
            component: PatientDocumentsPage,
            userType: UserType.Patient,
            permissions: [PermissionType.None],
        },
        {
            path: `${RoutesConstants.questionnaire}/:questionnaireResultId`,
            component: PatientQuestionnairePage,
            userType: UserType.Patient,
            permissions: [PermissionType.None],
        },
        {
            path: RoutesConstants.healthSummary,
            component: PatientHealthSummaryPage,
            userType: UserType.Patient,
            permissions: [PermissionType.None],
        },
        {
            path: RoutesConstants.healthForms,
            component: PatientHealthFormsPage,
            userType: UserType.Patient,
            permissions: [PermissionType.None],
        },
        {
            path: RoutesConstants.healthReport,
            component: PatientHealthReportPage,
            userType: UserType.Patient,
            permissions: [PermissionType.None],
        },
        {
            path: RoutesConstants.addOnReports,
            component: PatientAddOnReportsPage,
            userType: UserType.Patient,
            permissions: [PermissionType.None],
        },
        {
            path: RoutesConstants.healthPlan,
            component: PatientHealthPlanPage,
            userType: UserType.Patient,
            permissions: [PermissionType.None],
        },
        {
            path: RoutesConstants.supplements,
            component: PatientSupplementsPage,
            userType: UserType.Patient,
            permissions: [PermissionType.None],
        },
        {
            path: RoutesConstants.patientPastAppointments,
            component: PatientPastAppointmentsPage,
            userType: UserType.Patient,
            permissions: [PermissionType.None],
        },
        {
            path: RoutesConstants.patientUpcommingAppointments,
            component: PatientUpcommingAppointmentsPage,
            userType: UserType.Patient,
            permissions: [PermissionType.None],
        },
        {
            path: RoutesConstants.patientNotes,
            component: PatientNotesPage,
            userType: UserType.Patient,
            permissions: [PermissionType.None],
        },
        {
            path: `${RoutesConstants.patientInitialConsult}/:noteId`,
            component: PatientInitialConsultBasePage,
            userType: UserType.Patient,
            permissions: [PermissionType.None],
        },
        {
            path: `${RoutesConstants.patientSoap}/:noteId`,
            component: PatientSoapNotePage,
            userType: UserType.Patient,
            permissions: [PermissionType.None],
        },
        {
            path: `${RoutesConstants.patientHistoryAndPhysical}/:noteId`,
            component: PatientHistoryAndPhysicalNotePage,
            userType: UserType.Patient,
            permissions: [PermissionType.None],
        },
        {
            path: `${RoutesConstants.patientHistoryAndPhysicalGroupVisit}/:noteId`,
            component: PatientHistoryAndPhysicalGroupVisitNotePage,
            userType: UserType.Patient,
            permissions: [PermissionType.None],
        },
        {
            path: `${RoutesConstants.patientHistoryAndPhysicalFollowup}/:noteId`,
            component: PatientHistoryAndPhysicalFollowUpNotePage,
            userType: UserType.Patient,
            permissions: [PermissionType.None],
        },
        {
            path: `${RoutesConstants.startQuestionnaire}/:questionnaireId`,
            component: StartQuestionnairePage,
            userType: UserType.Patient,
            permissions: [PermissionType.None],
        },
        {
            path: RoutesConstants.resetPassword,
            component: ResetPasswordPage,
            userType: UserType.Patient,
            permissions: [PermissionType.None],
        },
        {
            path: `${RoutesConstants.patientFollowUp}/:noteId`,
            component: PatientFollowUpPage,
            userType: UserType.Patient,
            permissions: [PermissionType.None],
        },
        {
            path: RoutesConstants.labsInputs,
            component: PatientLabsPage,
            userType: UserType.Patient,
            permissions: [PermissionType.None],
        },
        {
            path: RoutesConstants.microbiomeInputs,
            component: PatientMicrobiomePage,
            userType: UserType.Patient,
            permissions: [PermissionType.None],
        },
        {
            path: RoutesConstants.dataFilesInputs,
            component: PatientDataFilesPage,
            userType: UserType.Patient,
            permissions: [PermissionType.None],
        },
        {
            path: RoutesConstants.vitalsHistory,
            component: VitalsHistoryPage,
            userType: UserType.Patient,
            permissions: [PermissionType.None],
        },
        {
            path: RoutesConstants.conversations,
            component: PatientConversationsPage,
            userType: UserType.Patient,
            permissions: [PermissionType.None],
        },
        {
            path: `${RoutesConstants.chat}/:threadId`,
            component: PatientChatPage,
            userType: UserType.Patient,
            permissions: [PermissionType.None],
        },
        {
            path: RoutesConstants.patientOrders,
            component: PatientOrdersPage,
            userType: UserType.Patient,
            permissions: [PermissionType.None],
        },
        {
            path: `${RoutesConstants.patientBlank}/:noteId`,
            component: PatientBlankPage,
            userType: UserType.Patient,
            permissions: [PermissionType.None],
        },
        {
            path: RoutesConstants.habitScore,
            component: HabitScorePage,
            userType: UserType.Patient,
            permissions: [PermissionType.None],
        },
        {
            path: RoutesConstants.healthScore,
            component: HealthScorePage,
            userType: UserType.Patient,
            permissions: [PermissionType.None],
        },
        {
            path: RoutesConstants.referralLanding,
            component: ReferralLandingPage,
            userType: UserType.Patient,
            permissions: [PermissionType.None],
        },
        {
            path: RoutesConstants.accountInsurance,
            component: InsurancePage,
            userType: UserType.Patient,
            permissions: [PermissionType.None],
        },
        {
            path: RoutesConstants.patientCourseHistory,
            component: PatientNorthPassAcademyPage,
            userType: UserType.Patient,
            permissions: [PermissionType.None],
        },
        {
            path: `${RoutesConstants.patientNote}/:noteId`,
            component: PatientNotePage,
            userType: UserType.Patient,
            permissions: [PermissionType.None],
        },
        {
            path: RoutesConstants.accountMembership,
            component: PatientMembershipPage,
            userType: UserType.Patient,
            permissions: [PermissionType.None],
        },
        {
            path: RoutesConstants.patientJourneyTasks,
            component: PatientMyTasksPage,
            userType: UserType.Patient,
            permissions: [PermissionType.None],
        },
    ];

    const authenticatedRoutesProps = [
        {
            path: RoutesConstants.verifyProfile,
            component: VerifyProfilePage,
        },
        {
            path: RoutesConstants.confirmAgreements,
            component: ConfirmAgreementsPage,
        },
        {
            path: RoutesConstants.accountProfile,
            component: MyAccountPage
        },
    ];

    const authenticatedRoutesPropsStartIndex = authorizedRoutesProps.length + 1;

    return [
        ...authorizedRoutesProps.map((props, index) =>
            React.createElement(AuthorizedRoute, { key: `patient-${index}`, ...props })
        ),
        ...authenticatedRoutesProps.map((props, index) =>
            React.createElement(AuthenticatedRoute, { key: `patient-${authenticatedRoutesPropsStartIndex + index}`, ...props })
        )
    ];
}

export const employeesRoutes = (): JSX.Element[] => {
    const authorizedRoutesProps = [
        {
            path: RoutesConstants.myPatients,
            component: MyPatientsPage,
            userType: UserType.Employee,
            permissions: [PermissionType.Coaching, PermissionType.ViewOnly],
        },
        {
            path: RoutesConstants.shortcuts,
            component: ManageShortcutsPage,
            userType: UserType.Employee,
            permissions: [PermissionType.Shortcuts, PermissionType.ViewOnly],
        },
        {
            path: `${RoutesConstants.submitPatient}/:patientId`,
            component: SubmitPatientPage,
            userType: UserType.Employee,
            permissions: [PermissionType.ManagePatients, PermissionType.ViewOnly],
        },
        {
            path: `${RoutesConstants.managePatientProfile}/:patientId`,
            component: ManagePatientProfilePage,
            userType: UserType.Employee,
            permissions: [PermissionType.Coaching, PermissionType.ManagePatients, PermissionType.ViewOnly],
        },
        {
            path: `${RoutesConstants.healthQuestionnaireResult}/:patientId`,
            component: IntakeSpecialistQuestionnaireResultPage,
            userType: UserType.Employee,
            permissions: [PermissionType.ManagePatients, PermissionType.ViewOnly],
        },
        {
            path: RoutesConstants.intakePatients,
            component: ManageIntakePatientsPage,
            userType: UserType.Employee,
            permissions: [PermissionType.ManagePatients, PermissionType.ViewOnly],
        },
        {
            path: RoutesConstants.coachAppointments,
            component: CoachAppointmentsPage,
            userType: UserType.Employee,
            permissions: [PermissionType.Appointments, PermissionType.ViewOnly],
        },
        {
            path: RoutesConstants.manageEmployees,
            component: ManageEmployeesPage,
            userType: UserType.Employee,
            permissions: [PermissionType.ManageEmployees, PermissionType.ManageLocations, PermissionType.ViewOnly],
        },
        {
            path: RoutesConstants.managePatients,
            component: ManageAllPatientsPage,
            userType: UserType.Employee,
            permissions: [PermissionType.ManagePatients, PermissionType.ViewOnly],
        },
        {
            path: RoutesConstants.dnafilesMonitor,
            component: DNAFilesMonitorPage,
            userType: UserType.Employee,
            permissions: [PermissionType.DNAFileManager],
        },
        {
            path: RoutesConstants.dnaOrderDropship,
            component: DNAOrderDropshipPage,
            userType: UserType.Employee,
            permissions: [PermissionType.Coaching, PermissionType.ManagePatients, PermissionType.ViewOnly],
        },
        {
            path: `${RoutesConstants.createInitialConsultNote}/:patientId`,
            component: CreateInitialConsultNotePage,
            userType: UserType.Employee,
            permissions: [PermissionType.Coaching, PermissionType.ViewOnly],
        },
        {
            path: RoutesConstants.leadSources,
            component: LeadSourcesPage,
            userType: UserType.Employee,
            permissions: [PermissionType.PatientAnalytics, PermissionType.ViewOnly],
        },
        {
            path: RoutesConstants.loginOnBehalf,
            component: LoginOnBehalfPage,
            userType: UserType.Employee,
            permissions: [PermissionType.ManageLicensees],
        },
        {
            path: RoutesConstants.fellowship,
            component: FellowshipPage,
            userType: UserType.Employee,
            permissions: [PermissionType.ManageFellowship],
        },
        {
            path: `${RoutesConstants.fellowPatients}/:fellowId`,
            component: FellowPatientsPage,
            userType: UserType.Employee,
            permissions: [PermissionType.ManageFellowship],
        },
        {
            path: RoutesConstants.employeeConversations,
            component: EmployeeConversationsPage,
            userType: UserType.Employee,
            permissions: [PermissionType.Conversations],
        },
        {
            path: RoutesConstants.patientsSubmissions,
            component: PatientsSubmissionsPage,
            userType: UserType.Employee,
            permissions: [PermissionType.Conversations],
        },
        {
            path: RoutesConstants.messageSettings,
            component: MessageSettingsPage,
            userType: UserType.Employee,
            permissions: [PermissionType.Conversations],
        },
        {
            path: RoutesConstants.genericPlayground,
            component: GenericPlaygroundPage,
            userType: UserType.Employee,
            permissions: [PermissionType.Conversations],
        },
        {
            path: `${RoutesConstants.createFollowUpNote}/:patientId`,
            component: CreateFollowUpNotePage,
            userType: UserType.Employee,
            permissions: [PermissionType.Coaching, PermissionType.ViewOnly],
        },
        {
            path: `${RoutesConstants.createSoapNote}/:patientId`,
            component: CreateSoapNotePage,
            userType: UserType.Employee,
            permissions: [PermissionType.Coaching, PermissionType.ViewOnly],
        },
        {
            path: `${RoutesConstants.createSimpleNote}/:type/:patientId`,
            component: CreateSimpleNotePage,
            userType: UserType.Employee,
            permissions: [PermissionType.Coaching, PermissionType.ViewOnly],
        },
        {
            path: `${RoutesConstants.createHistoryAndPhysicalNote}/:patientId`,
            component: HistoryAndPhysicalNotePage,
            userType: UserType.Employee,
            permissions: [PermissionType.Coaching, PermissionType.ViewOnly],
        },
        {
            path: `${RoutesConstants.createHistoryAndPhysicalGroupVisitNote}/:patientId`,
            component: HistoryAndPhysicalGroupVisitNotePage,
            userType: UserType.Employee,
            permissions: [PermissionType.Coaching, PermissionType.ViewOnly],
        },
        {
            path: `${RoutesConstants.createSoapNote}/:patientId`,
            component: SoapNotePage,
            userType: UserType.Employee,
            permissions: [PermissionType.Coaching, PermissionType.ViewOnly],
        },
        {
            path: `${RoutesConstants.createHistoryAndPhysicalFollowUpNote}/:patientId`,
            component: HistoryAndPhysicalFollowUpNotePage,
            userType: UserType.Employee,
            permissions: [PermissionType.Coaching, PermissionType.ViewOnly],
        },
        {
            path: RoutesConstants.vitalsHistory,
            component: VitalsHistoryPage,
            userType: UserType.Employee,
            permissions: [PermissionType.Coaching, PermissionType.ViewOnly],
        },
        {
            path: RoutesConstants.accountProfile,
            component: MyAccountPage,
            userType: UserType.Employee,
            permissions: [PermissionType.None],
        },
        {
            path: RoutesConstants.draftNotes,
            component: DraftNotesPage,
            userType: UserType.Employee,
            permissions: [PermissionType.None],
        },
        {
            path: RoutesConstants.signOffNotes,
            component: SignOffNotesPage,
            userType: UserType.Employee,
            permissions: [PermissionType.None],
        },
        {
            path: RoutesConstants.awaitingApproval,
            component: AwaitingApprovalPage,
            userType: UserType.Employee,
            permissions: [PermissionType.Coaching, PermissionType.ManagePatients, PermissionType.ViewOnly],
        },
        (authQuery.getRoleId() === roles.portalAdmin) && {
            path: RoutesConstants.engineeringAdminTool,
            component: EngineeringAdminPage,
            userType: UserType.Employee,
            permissions: [PermissionType.ManageEmployees, PermissionType.ViewOnly],
        },
        (authQuery.getRoleId() === roles.portalAdmin) && {
            path: RoutesConstants.employerAdmin,
            component: EmployerAdminPage,
            userType: UserType.Employee,
            permissions: [PermissionType.ManageEmployees, PermissionType.ViewOnly],
        },
        (authQuery.getRoleId() === roles.portalAdmin) && {
            path: RoutesConstants.manageMessaging,
            component: ManageMessagesPage,
            userType: UserType.Employee,
            permissions: [PermissionType.ManageMessages, PermissionType.ViewOnly],
        },
        (authQuery.getRoleId() === roles.portalAdmin) && {
            path: RoutesConstants.managePromoCodes,
            component: ManagePromoCodesPage,
            userType: UserType.Employee,
            permissions: [PermissionType.ManageEmployees, PermissionType.ViewOnly],
        },
        (authQuery.getRoleId() === roles.portalAdmin) && {
            path: RoutesConstants.manageInsurances,
            component: ManageInsurancesPage,
            userType: UserType.Employee,
            permissions: [PermissionType.ManageInsurance, PermissionType.ViewOnly],
        },
        (authQuery.getRoleId() === roles.portalAdmin) && {
            path: `${RoutesConstants.insuranceConfigurations}/:configurationId`,
            component: InsuranceConfigurationEditPage,
            userType: UserType.Employee,
            permissions: [PermissionType.ManageInsurance, PermissionType.ViewOnly],
        },
        (authQuery.getRoleId() === roles.portalAdmin) && {
            path: RoutesConstants.insuranceConfigurations,
            component: InsuranceConfigurationsPage,
            userType: UserType.Employee,
            permissions: [PermissionType.ManageInsurance, PermissionType.ViewOnly],
        },
        (isStaffOrAdmin(authQuery.getRoleId())) && {
            path: RoutesConstants.patientResponsibility,
            component: PatientResponsibilityPage,
            userType: UserType.Employee,
            permissions: [PermissionType.ManageInsurance, PermissionType.ViewOnly],
        },
        (authQuery.getRoleId() === roles.portalAdmin) && {
            path: RoutesConstants.manageBanners,
            component: ManageBannersPage,
            userType: UserType.Employee,
            permissions: [PermissionType.ManageEmployees, PermissionType.ViewOnly],
        },
        (authQuery.getRoleId() === roles.portalAdmin) && {
            path: RoutesConstants.healthReportTemplates,
            component: HealthReportTemplatesPage,
            userType: UserType.Employee,
            permissions: [PermissionType.ManageEmployees],
        },
        (authQuery.getRoleId() === roles.portalAdmin) && {
            path: `${RoutesConstants.manageCommonGoals}/:goalId`,
            component: ManageCommonGoalEditPage,
            userType: UserType.Employee,
            permissions: [PermissionType.ManagePatients, PermissionType.ViewOnly],
        },
        (authQuery.getRoleId() === roles.portalAdmin) && {
            path: RoutesConstants.manageCommonGoals,
            component: ManageCommonGoalsPage,
            userType: UserType.Employee,
            permissions: [PermissionType.ManagePatients, PermissionType.ViewOnly],
        },
        (authQuery.getRoleId() === roles.portalAdmin) && {
            path: `${RoutesConstants.manageCommonMdms}/:mdmId`,
            component: ManageCommonMdmEditPage,
            userType: UserType.Employee,
            permissions: [PermissionType.ManagePatients, PermissionType.ViewOnly],
        },
        (authQuery.getRoleId() === roles.portalAdmin) && {
            path: RoutesConstants.manageCommonMdms,
            component: ManageCommonMdmsPage,
            userType: UserType.Employee,
            permissions: [PermissionType.ManagePatients, PermissionType.ViewOnly],
        },
        (authQuery.getRoleId() === roles.portalAdmin) && {
            path: RoutesConstants.manageCommonSupplements,
            component: ManageCommonSupplementsPage,
            userType: UserType.Employee,
            permissions: [PermissionType.ManagePatients, PermissionType.ViewOnly],
        },
        (authQuery.getRoleId() === roles.portalAdmin) && {
            path: RoutesConstants.manageCommonOrders,
            component: ManageCommonOrdersPage,
            userType: UserType.Employee,
            permissions: [PermissionType.ManagePatients, PermissionType.ViewOnly],
        },
        (authQuery.getRoleId() === roles.portalAdmin) && {
            path: RoutesConstants.manageRecommendations,
            component: ManageRecommendationsPage,
            userType: UserType.Employee,
            permissions: [PermissionType.ManageEmployees, PermissionType.ViewOnly],
        },
        {
            path: RoutesConstants.kbDocuments,
            component: ManageKbDocumentsPage,
            userType: UserType.Employee,
            permissions: [PermissionType.ManageKbDocuments],
        },
        isStaffRole(authQuery.getRoleId()) && {
            path: RoutesConstants.opsAdminTools,
            component: OpsAdminToolsPage,
            userType: UserType.Employee,
            permissions: [PermissionType.OpsAdminTools],
        },
        {
            path: RoutesConstants.syncRecords,
            component: SyncRecordsDashboardPage,
            userType: UserType.Employee,
            permissions: [PermissionType.ManageEmployees, PermissionType.ViewOnly],
        },
        {
            path: `${RoutesConstants.syncRecordType}/:typeName/:practiceId`,
            component: SyncRecordTypePage,
            userType: UserType.Employee,
            permissions: [PermissionType.ManageEmployees, PermissionType.ViewOnly],
        },
        {
            path: `${RoutesConstants.syncRecordsList}/:statusId`,
            component: SyncRecordListPage,
            userType: UserType.Employee,
            permissions: [PermissionType.ManageEmployees, PermissionType.ViewOnly],
        },
        {
            path: `${RoutesConstants.syncRecordsReconcile}/:recordId`,
            component: SyncRecordReconcilePage,
            userType: UserType.Employee,
            permissions: [PermissionType.ManageEmployees, PermissionType.ViewOnly],
        },
        {
            path: `${RoutesConstants.signOffNote}/:noteId`,
            component: SignOffNotePage,
            userType: UserType.Employee,
            permissions: [PermissionType.Coaching, PermissionType.ViewOnly],
        },
        {
            path: RoutesConstants.messageForwarding,
            component: MessageForwardingPage,
            userType: UserType.Employee,
            permissions: [PermissionType.ManageForwarding, PermissionType.ViewOnly],
        },
        {
            path: RoutesConstants.alertsHistory,
            component: AlertsHistoryPage,
            userType: UserType.Employee,
            permissions: [PermissionType.None],
        },
        {
            path: RoutesConstants.unmatchedLabs,
            component: UnmatchedLabsPage,
            userType: UserType.Employee,
            permissions: [PermissionType.AssignUnmatchedResults],
        },
        {
            path: RoutesConstants.referralOrder,
            component: ReferralOrderPagePage,
            userType: UserType.Employee,
            permissions: [PermissionType.None],
        },
        {
            path: RoutesConstants.otherOrder,
            component: OtherOrderPagePage,
            userType: UserType.Employee,
            permissions: [PermissionType.None],
        },
        {
            path: RoutesConstants.patientJourneyTasks,
            component: PatientMyTasksPage,
            userType: UserType.Employee,
            permissions: [PermissionType.Coaching, PermissionType.ViewOnly],
        },
        {
            path: RoutesConstants.thirdPartyIntegrationCode,
            component: ThirdPartyIntegrationCodePage,
            userType: UserType.Employee,
            permissions: [PermissionType.None],
        },
        {
            path: RoutesConstants.thirdPartyIntegrationLogin,
            component: ThirdPartyIntegrationLoginPage,
            userType: UserType.Employee,
            permissions: [PermissionType.None],
        },
        {
            path: RoutesConstants.loginRxNT,
            component: LoginRxNTPage,
            userType: UserType.Employee,
            permissions: [PermissionType.None],
        },
        (isStaffRole(authQuery.getRoleId()) || (authQuery.getRoleId() === roles.portalAdmin)) && {
            path: RoutesConstants.manageConversationTemplates,
            component: ManageConversationTemplatesPage,
            userType: UserType.Employee,
            permissions: [PermissionType.None],
        },
        (authQuery.getRoleId() === roles.portalAdmin) && {
            path: `${RoutesConstants.manageEmployersProfiles}/:productId`,
            component: EmployerProfileDetailPage,
            userType: UserType.Employee,
            permissions: [PermissionType.ManageEmployees, PermissionType.ViewOnly],
        },
        (authQuery.getRoleId() === roles.portalAdmin) && {
            path: RoutesConstants.manageEmployersProfiles,
            component: ManageEmployersProfilePage,
            userType: UserType.Employee,
            permissions: [PermissionType.ManageEmployees, PermissionType.ViewOnly],
        },
    ].filter(x => x);

    return authorizedRoutesProps.map((props, index) =>
        React.createElement(AuthorizedRoute, { key: `employee-${index}`, ...props })
    );
}

export const practice = new Map<Practice, () => JSX.Element[]>([
    [Practice.WildHealth, basicRoutesWH],
]);

export const flows = new Map<UserType, () => JSX.Element[]>([
    [UserType.Patient, patientRoutes],
    [UserType.Employee, employeesRoutes],
]);
