import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 2,
      backgroundColor: colors.white,
      boxShadow: "0px 7px 60px rgba(168, 173, 198, 0.12)",
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      maxHeight: "calc(100vh - 156px) !important",
      minHeight: 625,
      [theme.breakpoints.down("xs")]: {
        minHeight: "calc(100vh - 156px) !important",
      },
    },
    header: {
      padding: "16px 24px",
      [theme.breakpoints.down("xs")]: {
        padding: "16px 0 16px 16px",
        borderBottom: `1px solid ${colors.separators}`,
        position: "relative",
      },
    },
    title: {
      color: colors.black,
      fontWeight: 500,
      fontSize: 20,
      [theme.breakpoints.down("xs")]: { fontSize: 18 },
    },
    subject: {
      fontSize: 12,
      color: colors.gray2,
    },
    historyTitle: {
      fontFamily: "Be Vietnam Pro",
      fontWeight: 500,
      fontSize: 18,
      color: colors.black,
      paddingLeft: 24,
      [theme.breakpoints.down("xs")]: { fontSize: 16, paddingLeft: 0 },
    },
    newMessage: {
      maxHeight: "calc(100vh - 370px)",
      minHeight: 420,
      overflow: "auto",
      [theme.breakpoints.down("xs")]: {
        maxHeight: "calc(100vh - 180px)",
      },
    },
    divider: {
      backgroundColor: colors.stroke,
    },
    thread: {
      flex: 1,
      minHeight: 200,
      height: "calc(100vh - 398px)",
      maxHeight: "calc(100vh - 398px)",
      overflow: "auto",
    },
    sendMessage: {
      flexShrink: 0,
    },
    employeeType: {
      fontFamily: "Be Vietnam Pro",
      fontSize: 12,
      color: colors.gray1,
    },
    employeeNames: {
      fontFamily: "Be Vietnam Pro",
      fontSize: 16,
      fontWeight: 500,
      color: colors.black,
    },
    notification: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontFamily: "Be Vietnam Pro",
      fontSize: 14,
      borderRadius: 8,
      background: colors.navy100,
      color: colors.secondary,
    },
    notificationIcon: {
      fontSize: 24,
      cursor: 'pointer',
      [theme.breakpoints.down("xs")]: {
        color: colors.gray2,
        opacity: 0.4,
        fontSize: 16,
        "&:hover": {
          color: colors.gray1,
        },
      },
    },
    notificationToolTip: {
      fontFamily: "Be Vietnam Pro",
      fontSize: 14,
      margin: "5px",
    },
    notificationList: {
      listStyle: "inside",
    },
    notificationToolTipText: {
      margin: "5px",
    },
    popper: {
      left: "-5px !important",
    },
    tooltip: {
      width: '225px',
      maxWidth: '225px',
      padding: 8,
      background: colors.lightBlack,
    },
    arrow: {
      color: colors.lightBlack,
    },
    tooltipPlacement: {
      margin: '12px 0',
    },
    subjectSection: {
      zIndex: 1000,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      width: "100%",
      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.25)',
      backgroundColor: colors.white,
      fontSize: 14,
      fontWeight: 400,
      color: colors.black,
      padding: 12,
      marginLeft: -16,
      marginTop: 16,
    },
    subjectTitle: {
      fontSize: 14,
      fontWeight: 500,
      color: colors.black,
      marginLeft: 4,
    }
  })
);
