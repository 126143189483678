import {useEffect, useState} from "react";
import {PaymentPlanModel} from "../../models/paymentPlan.models";
import {Subscription} from "rxjs";
import {onEmit} from "../../../common/helpers/on-emit";
import {paymentPlansQuery} from "../../stores/paymentPlansStore";
import {paymentPlansService} from "../../services/paymentPlans.service";
import {authQuery} from "../../../auth/stores/auth";
import {PaymentPriceModel} from "../../models/paymentPrice.models";

interface ActivateSubscriptionComponentState {
    paymentPlans: PaymentPlanModel[];
    selectedPaymentPrice: PaymentPriceModel;
}

export function useFacade(handleSubmit: Function, handleClose: Function): [
    Function,
    Function
] {
    const [state, setState] = useState({
        paymentPlans: [],
        selectedPaymentPrice: null
    } as ActivateSubscriptionComponentState);

    const handleSubmitForm = (startDate: Date) => {
        handleSubmit(state.selectedPaymentPrice, startDate);

        handleCloseDialog();
    }

    const handleCloseDialog = () => {
        handleClose();
        setState({
            ...state,
            selectedPaymentPrice: null
        });
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<PaymentPlanModel[]>(paymentPlansQuery.regularPlans$, plans => {
                setState(state => ({
                    ...state,
                    paymentPlans: plans,
                    selectedPaymentPrice: plans[0]?.periods[0]?.prices[0]
                }));
            })
        ];

        paymentPlansService.getActive(authQuery.getPracticeId()).subscribe();

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [handleSubmitForm, handleCloseDialog];
}
