import React from 'react';
import { WildHealthTabControl } from "../../../common/components/wildHealthTabControl/WildHealthTabControl";
import { useFacade } from "./fellowshipComponent.hooks"

interface FellowshipComponentProps {
    pmFellowship?: boolean;
    rosterId: number;
}

export const FellowshipComponent: React.FC<FellowshipComponentProps> = (props: FellowshipComponentProps) => {
    const {rosterId, pmFellowship = false} = props;
    const [tabItems] = useFacade( pmFellowship, rosterId);

    return (
        <>
            <WildHealthTabControl items={tabItems} />
        </>
    )
}