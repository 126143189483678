import Axios from "axios-observable";
import { Observable } from "rxjs";
import { authHeader } from "../../common/helpers/auth-header";
import { snackService } from "../../common/snack/state";
import { PatientReportModel, ReportStrategy, PatientAddOnReportModel } from "../models/healthReport.models";
import { ReportType } from "../models/reportTemplate.models";
import { healthReportsStore, HealthReportsStore } from "../stores/healthReportsStore";


export class AddOnReportsServices {
    private url = `${process.env.REACT_APP_API_URL}Reports`;

    constructor(private healthReportsStore: HealthReportsStore) {
    }

    public GetPatientReport(reportType: ReportType): Observable<PatientReportModel> {
        let url = `${this.url}/${reportType}`;
        return new Observable(observer => {
            Axios.get(url, {
                headers: authHeader()
            })
                .pipe()
                .subscribe(
                    (response) => {
                        this.healthReportsStore.update({patientReport: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.next(error);
                        observer.error();
                    }
                );
        })
    }

    public GetPatientReportByEmployee(reportType: ReportType, patientId: number): Observable<PatientReportModel> {
        let url = `${this.url}/${reportType}/patient/${patientId}`;
        return new Observable(observer => {
            Axios.get(url, {
                headers: authHeader()
            })
                .pipe()
                .subscribe(
                    (response) => {
                        this.healthReportsStore.update({patientReport: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.next(error);
                        observer.error();
                    }
                );
        })
    }

    public GetPatientReportById(reportType: ReportType, reportId: number): Observable<PatientReportModel> {
        let url = `${this.url}/${reportType}/${reportId}`;
        return new Observable(observer => {
            Axios.get(url, {
                headers: authHeader()
            })
                .pipe()
                .subscribe(
                    (response) => {
                        this.healthReportsStore.update({patientReport: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.next(error);
                        observer.error();
                    }
                );
        })
    }

    public CreatePatientReport(reportType: ReportType): Observable<PatientReportModel> {

        return new Observable(observer => {
            Axios.post(`${this.url}/${reportType}`, {}, {
                headers: authHeader()
            })
                .pipe()
                .subscribe(
                    (response) => {
                        this.healthReportsStore.update({patientReport: response.data});
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.next();
                        observer.error();
                    }
                );
        })
    }

    public CreatePatientReportByEmployee(reportType: ReportType, patientId: number): Observable<PatientReportModel> {

        return new Observable(observer => {
            Axios.post(`${this.url}/${reportType}/patient/${patientId}`, {}, {
                headers: authHeader()
            })
                .pipe()
                .subscribe(
                    (response) => {
                        this.healthReportsStore.update({patientReport: response.data});
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.next();
                        observer.error();
                    }
                );
        })
    }

    public GetAvailableReports(reportStrategy: ReportStrategy): Observable<PatientAddOnReportModel[]> {
        let url = `${this.url}/available/${reportStrategy}`;
        return new Observable(observer => {
            Axios.get(url, {
                headers: authHeader()
            })
                .pipe()
                .subscribe(
                    (response) => {
                        this.healthReportsStore.update({addOnReports: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.next(error);
                        observer.error();
                    }
                );
        })
    }

    public GetAvailableReportsByEmployee(reportStrategy: ReportStrategy, patientId: number): Observable<PatientAddOnReportModel[]> {
        let url = `${this.url}/available/${reportStrategy}/patient/${patientId}`;
        return new Observable(observer => {
            Axios.get(url, {
                headers: authHeader()
            })
                .pipe()
                .subscribe(
                    (response) => {
                        this.healthReportsStore.update({addOnReports: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.next(error);
                        observer.error();
                    }
                );
        })
    }
}

export const addOnReportsServices = new AddOnReportsServices(healthReportsStore);
