import {useEffect, useState} from "react";
import {Subscription} from "rxjs";
import {
    CoverageModel,
    CreateCoverageModel,
    InsuranceModel,
    PolicyHolderModel,
    UpdateCoverageModel
} from "../../models/insurance.models";
import {insuranceService} from "../../services/insurance.service";
import {insuranceQuery} from "../../stores/insurance.query";
import {onEmit} from "../../../common/helpers/on-emit";
import {IErrorState} from "../../../common/validation/error-state";
import {insuranceComponentValidator} from "./insuranceComponent.validator";
import {AttachmentModel, AttachmentType} from "../../../common/models/attachment.models";
import { PlatformName, Track } from "../../../tracking/tracker";

interface InsuranceComponentState extends IErrorState {
    insurances: InsuranceModel[];
    attachments: AttachmentModel[];
    coverage: CoverageModel;
    coverageDraft: CoverageModel;

    editMode: boolean;
    containPolicyHolder: boolean;

    isSaveButtonLoading: boolean;
    isUploadButtonLoading: boolean;
    isInsurancesLoading: boolean;
    isCoveragesLoading: boolean;
    isAttachmentsLoading: boolean;
}

const getEmptyPolicyHolder = () => {
    return {
        firstName: '',
        lastName: '',
        relationship: '',
        dateOfBirth: null
    } as PolicyHolderModel
}

const getEmptyInsurance = (state: InsuranceComponentState) => {
    return {
        insurance: {
            id: 0,
            name: '',
            states: []
        },
        memberId: '',
        policyHolder: state?.containPolicyHolder ? getEmptyPolicyHolder() : null,
    } as CoverageModel
}

/**
 * Custom Hook to manage a view Model for Insurance component
 */
export function useFacade(handleSubmit?: Function, editMode?: boolean, insuranceStateId?: number, age?: number): [
    InsuranceComponentState,
    () => void,
    (field: string, value: string) => void,
    () => void,
    () => void,
    (any, AttachmentType, string) => void
] {
    const [state, setState] = useState({
        insurances: [],
        coverage: null,
        coverageDraft: editMode ? getEmptyInsurance(null) : null,
        editMode: editMode ?? false,
        containPolicyHolder: false,

        isSaveButtonLoading: false,
        isUploadButtonLoading: false,
        isInsurancesLoading: true,
        isAttachmentsLoading: true,
        isCoveragesLoading: true,

        errors: {}
    } as InsuranceComponentState);

    const policyHolderFields = [
        "policyHolder.firstName",
        "policyHolder.relationship",
        "policyHolder.lastName",
        "policyHolder.dateOfBirth"
    ]

    const removePolicyHolderErrors = () => {
        Object.keys(state.errors).forEach(key => {
            if (policyHolderFields.includes(key)) {
                delete state.errors[key];
            }
        });
    }

    const handlePolicyHolderCheck = () => {
        let draft = state.coverageDraft;

        draft.policyHolder = state.containPolicyHolder === false
            ? state.coverage === null || state.coverage.policyHolder === null
                ? getEmptyPolicyHolder()
                : JSON.parse(JSON.stringify(state.coverage.policyHolder))
            : null

        setState({
            ...state,
            containPolicyHolder: !state.containPolicyHolder
        })
    }

    const handleChanges = (field: string, value: string) => {

        const draft = state.coverageDraft;

        insuranceComponentValidator.validateAndSetState(state, setState, field, value);

        const fields = field.split('.');

        if (fields.length > 1) {
            const obj = draft[fields[0]];
            obj[fields[1]] = value;
        } else {
            draft[field] = value;
        }

        setState({...state, coverageDraft: draft});
    }

    const handleAddInsurance = () => {
        setState({...state, coverageDraft: getEmptyInsurance(state), editMode: true})
    }

    const handleFileUpload = (event: any, type: AttachmentType, coverageId: string) => {
        const file = event.target.files[0];
        if (file) {
            setState(state => ({...state, isUploadButtonLoading: true}));

            insuranceService.uploadInsurance(file, type, coverageId).subscribe(
                () => setState(state => ({...state, isUploadButtonLoading: false})),
                () => setState(state => ({...state, isUploadButtonLoading: false}))
            );
        }
    }

    const handleSave = () => {
        if (!state.containPolicyHolder) {
            removePolicyHolderErrors();
        }

        if (state.coverageDraft.policyHolder != null) {
            insuranceComponentValidator.validateAndSetState(state, setState, 'policyHolder.firstName', state.coverageDraft.policyHolder.firstName);
            insuranceComponentValidator.validateAndSetState(state, setState, 'policyHolder.lastName', state.coverageDraft.policyHolder.lastName);
            insuranceComponentValidator.validateAndSetState(state, setState, 'policyHolder.dateOfBirth', state.coverageDraft.policyHolder.dateOfBirth);
            insuranceComponentValidator.validateAndSetState(state, setState, 'policyHolder.relationship', state.coverageDraft.policyHolder.relationship);
        }

        insuranceComponentValidator.validateObjectAndSetState(state, setState, state.coverageDraft);

        if (!insuranceComponentValidator.stateIsValid(state)) {
            return;
        }

        setState((state) => ({...state, isSaveButtonLoading: true}));

        Track("button_click", {
            type: 'add_insurance',
            acValue: 'add_insurance',
            platform: PlatformName()
        })

        if (state.coverage) {
            const model: UpdateCoverageModel = {
                id: state.coverageDraft.id,
                memberId: state.coverageDraft.memberId,
                insuranceId: state.coverageDraft.insurance?.id,
                policyHolder: state.coverageDraft.policyHolder,
                isPrimary: true,
                frontImage: null,
                backImage: null
            };

            insuranceService.updateCoverage(model).subscribe(() => {
                    setState(state => ({
                        ...state,
                        isSaveButtonLoading: false,
                        editMode: editMode
                    }));

                    handleSubmit && handleSubmit()
                },
                () => {
                    setState(state => ({...state, isSaveButtonLoading: false}))
                });
        } else {
            const model: CreateCoverageModel = {
                insuranceId: state.coverageDraft.insurance.id,
                memberId: state.coverageDraft.memberId,
                policyHolder: state.coverageDraft.policyHolder,
                isPrimary: true,
                frontImage: null,
                backImage: null
            };

            insuranceService.createCoverage(model).subscribe(() => {
                setState(state => ({
                    ...state,
                    isSaveButtonLoading: false,
                    editMode: editMode
                }));
                
                handleSubmit && handleSubmit()
            }, () => {
                setState(state => ({...state, isSaveButtonLoading: false}))
            });
        }
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<InsuranceModel[]>(insuranceQuery.insurances$, insurances => {
                setState(state => ({
                    ...state,
                    insurances: insurances,
                }))
            }),
            onEmit<AttachmentModel[]>(insuranceQuery.attachments$, attachments => {
                setState(state => ({
                    ...state,
                    attachments: attachments?.filter(x => x.description === state.coverage?.id.toString()),
                }))
            }),
            onEmit<CoverageModel[]>(insuranceQuery.coverages$, coverages => {
                if (coverages !== null && coverages.length) {
                    const coverage = coverages[coverages.length - 1];
                    setState(state => ({
                        ...state,
                        coverage: coverage,
                        attachments: insuranceQuery.getAttachments().filter(x => x.description === coverage.id.toString()),
                        coverageDraft: JSON.parse(JSON.stringify(coverage)),
                        editMode: editMode,
                        containPolicyHolder: coverage.policyHolder !== null
                    }))
                }
            }),
        ];

        insuranceService.getInsurances(insuranceStateId, age).subscribe(() => {
            setState((state) => ({...state, isInsurancesLoading: false}))
        }, () => {
            setState((state) => ({...state, isInsurancesLoading: false}))
        });

        insuranceService.getCoverages().subscribe(() => {
            setState((state) => ({...state, isCoveragesLoading: false}))
        }, () => {
            setState((state) => ({...state, isCoveragesLoading: false}))
        });

        insuranceService.getInsuranceUploads().subscribe(
            () => {
                setState((state) => ({...state, isAttachmentsLoading: false}))
            },
            () => {
                setState((state) => ({...state, isAttachmentsLoading: false}))
            }
        );

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [
        state,
        handleAddInsurance,
        handleChanges,
        handlePolicyHolderCheck,
        handleSave,
        handleFileUpload
    ];
}