import { Box, Button, Checkbox, CircularProgress, Divider, FormControlLabel, Grid, TextField } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import clsx from "clsx";
import { default as React, useEffect, useMemo, useState } from "react";
import { Subscription } from "recompose";
import { interval } from 'rxjs';
import { confirmService } from "../../../../services/confirm.service";
import { PatientAppointmentModel } from "../../../appointments/models/appointments.models";
import { appointmentsService } from "../../../appointments/services/appointments.service";
import { appointmentsQuery } from "../../../appointments/stores/appointments";
import { PermissionType, UserType } from "../../../auth/models/auth.enums";
import { isElementEnabled } from "../../../common/components/ProtectedElement";
import { WildHealthDatePicker } from "../../../common/components/WildHealthDatePicker";
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { WildHealthRadio } from "../../../common/components/WildHealthRadio";
import { WildHealthTabControl } from "../../../common/components/wildHealthTabControl/WildHealthTabControl";
import { useStylesSimpleTabs } from "../../../common/components/wildHealthTabControl/wildHealthTabControl.styles";
import { insertString } from "../../../common/helpers/insert-string";
import { onEmit } from "../../../common/helpers/on-emit";
import { IErrorState } from "../../../common/validation/error-state";
import { selectShortcutService } from "../../../healthReport/services/selectShortcut.service";
import { MedicationsComponent } from "../../../patientSupplements/components/medicationsComponent/MedicationsComponent";
import {
    MedicationsDCComponent
} from '../../../patientSupplements/components/medicationsSupplementsDCComponent/MedicationsDCComponent';
import {
    SupplementsDCComponent
} from '../../../patientSupplements/components/medicationsSupplementsDCComponent/SupplementsDCComponent';
import { SupplementsComponent } from "../../../patientSupplements/components/supplementsComponent/SupplementsComponent";
import { medicationsService } from "../../../patientSupplements/services/medications.service";
import { supplementsService } from "../../../patientSupplements/services/supplements.service";
import {
    AppointmentConfigurationModel,
    FollowUpContent,
    GoalCategory,
    GoalModel,
    InterventionModel,
    MedicationItem,
    MedicationsSupplementsItem,
    NextTimeModel,
    NextTimeSpan,
    nextTimeSpanNames,
    NoteGoalsVersion,
    NoteModel,
    NotesGroupModel,
    NotesType,
    SaveNoteModel,
    SignOffNoteModel,
    StepModel,
    SupplementItem
} from "../../models/notes.models";
import { notesService, SignOffModel } from "../../services/notes.service";
import { notesQuery } from "../../stores/notes";
import { CreateFollowUpComponentFieldNames, createFollowUpComponentValidator } from "./createFollowUpComponent.validator";
import monthlyCalendar from "@img/icons/monthlyCalendar.svg";
import timeCircle from "@img/icons/timeCircle.svg";
import moment from "moment";
import { colors } from "../../../common/constants/colors";
import { authQuery } from "../../../auth/stores/auth";
import { SelectIntervalComponent } from '../selectIntervalComponent/SelectIntervalComponent';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { snackService } from "../../../common/snack/state";
import { toCurrentTimeZone } from "../../../timezones/helpers/timezone";
import { patientsQuery } from "../../../patients/stores/patientsStore";
import { patientsService } from "../../../patients/services/patients.service";
import { DataSpecificationsEnum } from "../../../common/constants/data-specifications";
import { PatientModel } from "../../../patients/models/patient.model";
import { SubscriptionModel } from "../../../payment/models/subscription.models";
import { SelectAppointmentToNotesDialog } from "../SelectAppointmentToNotesComponent/SelectAppointmentToNotesDialog";
import { OptionalComponent } from "../../../common/components/OptionalComponent";
import { TextFieldNoteComponent } from "../textFieldNoteComponent/TextFieldNoteComponent";
import { handleCompare } from "../../../common/sorting/helpers/handle-compare";
import { SortingDirection } from "../../../common/sorting/models/sorting-destination";
import { NotePlanComponent } from "../notePlanComponent/NotePlanComponent";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { ReactComponent as CalendarIcon } from "@img/icons/CalendarIcon.svg";
import { SignOffDialogComponent } from "../signOffDIalogComponent/SignOffDialogComponent";
import { FeatureComponent } from "../../../common/components/featureFlags/FeatureComponent";
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import {canDiscardNote, isFutureAppointment} from "../../helpers/noteHelpers";
import { goalsService } from "../../services/goals.service";
import { mdmPlansService } from "../../services/mdmPlans.service";
import { commonSupplementsService } from "../../services/commonSupplements.service";
import {SelectDurationComponent} from "../../../appointments/components/selectDuration/SelectDurationComonent";
import {durations} from "../../../appointments/models/times.models";
import {FullscriptSupplementModel} from "../../../patientSupplements/models/fullscriptSupplementModels";
import {
    RxntMedicationsDCComponent
} from "../../../patientSupplements/components/medicationsSupplementsDCComponent/RxntMedicationsDCComponent";
import {
    MedicationsRxNTComponent
} from "../../../patientSupplements/components/medicationsRxNTComponent/MedicationsRxNTComponent";

let stateContext: CreateFollowUpComponentState = null;

interface CreateFollowUpComponentState extends IErrorState {
    isLoading: boolean;
    isChanged: boolean;
    isAutoSaving: boolean;
    isProcessing: boolean,
    isPrefilled: boolean;
    isNew: boolean;
    note: NoteModel;
    patientSubscription: SubscriptionModel;
    prewNote: any;
    title: string;
    internalContent: string;
    content: FollowUpContent;
    visitDate: Date;

    appointments: PatientAppointmentModel[];

    enableCoachNextAppointment: boolean;
    selectedCoachNextTimeMode: NextTimeModel;
    selectedCoachNextTimeSpan: NextTimeSpan;
    selectedCoachNextTimeQuantity: number;
    enableProviderNextAppointment: boolean;
    selectedProviderNextTimeMode: NextTimeModel;
    selectedProviderNextTimeSpan: NextTimeSpan;
    selectedProviderNextTimeQuantity: number;
    isFirstSaveOriginalNote: boolean;
    originalNote?: NoteModel;
    isCommonMdmLoading: boolean;
    isCommonGoalLoading: boolean;
    isCommonSupplementLoading: boolean;
    selectedTab: number;
}

const coachNextAppointmentTimes =
    [
        {
            timeSpan: NextTimeSpan.Month,
            quantity: 1,
            isManual: false,
        },
        {
            timeSpan: NextTimeSpan.Month,
            quantity: 2,
            isManual: false,
        },
        {
            timeSpan: NextTimeSpan.Month,
            quantity: 3,
            isManual: false,
        },
        {
            timeSpan: NextTimeSpan.Week,
            quantity: 1,
            isManual: true
        },
    ] as NextTimeModel[];

const defaultCoachAppointmentTime = coachNextAppointmentTimes[0];

const providerNextAppointmentTimes =
    [
        {
            timeSpan: NextTimeSpan.Month,
            quantity: 3,
            isManual: false,
        },
        {
            timeSpan: NextTimeSpan.Month,
            quantity: 6,
            isManual: false,
        },
        {
            timeSpan: NextTimeSpan.Year,
            quantity: 1,
            isManual: false,
        },
        {
            timeSpan: NextTimeSpan.Month,
            quantity: 1,
            isManual: true
        },
    ] as NextTimeModel[];

const defaultProviderAppointmentTime = providerNextAppointmentTimes[0];

let ignoreChangesEvent = false;

const manualTimeSpans = [NextTimeSpan.Week, NextTimeSpan.Month, NextTimeSpan.Year];

export function useFacade(patientId: number, note: NoteModel | null, classes: any, commonClasses: any, handleGoToNotes: Function, appointmentId: number | null, appointmentConfiguration: AppointmentConfigurationModel | null, originalNote: NoteModel | null): [
    CreateFollowUpComponentState,
    JSX.Element,
    JSX.Element,
    JSX.Element,
    JSX.Element,
    JSX.Element,
    JSX.Element,
    JSX.Element,
    string,
    JSX.Element,
] {
    const noteName = "Follow-Up";
    const autoSaveTimer = useMemo(() => interval(5000), []);
    const [isOpen, setIsOpen] = useState(false);
    const [state, setState] = useState({
        isLoading: true,
        isChanged: false,
        isAutoSaving: false,
        isProcessing: false,
        isPrefilled: false,
        isNew: true,
        note: note,
        patientSubscription: null,
        prewNote: {},
        title: note?.title || '',
        internalContent: '',
        content: {
            todayCoachingContent: '',
            goalsVersion: NoteGoalsVersion.New,
            appointmentConfiguration: appointmentConfiguration,
            goals: [],
            steps: [],
            plan: {
                dietGoals: [],
                exerciseGoals: [],
                sleepGoals: [],
                neuroGoals: [],
                longevityGoals: [],
                mindfulnessGoals: [],
                labsGoals: [],
            },
            medicationsSupplements: {
                medications: [],
                supplements: [],
                rxntMedications: [],
                fullscriptSupplements: []
            },
        },
        nextCoachAppointmentDate: null,
        nextProviderAppointmentDate: null,
        visitDate: appointmentConfiguration?.visitDate ?? new Date(),

        appointments: [],

        enableCoachNextAppointment: false,
        selectedCoachNextTimeMode: defaultCoachAppointmentTime,
        selectedCoachNextTimeSpan: defaultCoachAppointmentTime.timeSpan,
        selectedCoachNextTimeQuantity: defaultCoachAppointmentTime.quantity,
        selectedProviderNextTimeMode: defaultProviderAppointmentTime,
        enableProviderNextAppointment: false,
        selectedProviderNextTimeSpan: defaultProviderAppointmentTime.timeSpan,
        selectedProviderNextTimeQuantity: defaultProviderAppointmentTime.quantity,
        errors: {},
        isFirstSaveOriginalNote: !!originalNote,
        originalNote: originalNote,
        isCommonMdmLoading: true,
        isCommonGoalLoading: true,
        isCommonSupplementLoading: true,
        selectedTab: 0
    } as CreateFollowUpComponentState);

    const controlIcon = <CheckBoxOutlineBlankIcon style={{ fill: colors.main }} />;
    const controlCheckedIcon = <CheckBoxIcon style={{ fill: colors.main }} />;

    stateContext = state;

    const isCanCompleteNote = () => {
        if (!!originalNote) {
            return originalNote.completedById === authQuery.getEmployeeId();
        }

        if (stateContext?.note?.assignedTo) {
            return stateContext.note.assignedTo.id === authQuery.getEmployeeId()
        }

        if (stateContext?.note?.completedById) {
            return stateContext.note.completedById === authQuery.getEmployeeId()
        }

        return true;
    }

    const handleSaveAsDraft = () => {
        if (!createFollowUpComponentValidator.stateIsValid(state)) {
            return;
        }

        if (state.isAutoSaving) {
            setTimeout(() => {
                setState(state => ({
                    ...state,
                    isChanged: false,
                    isProcessing: true
                }));

                notesService.saveAsDraft(getData(state), patientsQuery.getTargetPatientIsPremium()).subscribe(
                    () => {
                        handleGoToNotes()
                    },
                    (error) => {
                        snackService.commonErrorHandler(error);
                        setState(state => ({
                            ...state,
                            isProcessing: true
                        }));
                    }
                );
            }, 2000)
            return;
        }

        setState(state => ({
            ...state,
            isChanged: false,
            isProcessing: true
        }));

        notesService.saveAsDraft(getData(state), patientsQuery.getTargetPatientIsPremium()).subscribe(
            () => {
                handleGoToNotes()
            },
            (error) => {
                snackService.commonErrorHandler(error);
                setState(state => ({
                    ...state,
                    isProcessing: true
                }));
            }
        );
    }

    const handleSaveAndComplete = () => {
        validateContent(state.content);

        if (!createFollowUpComponentValidator.stateIsValid(state)) {
            return;
        }

        confirmService.confirm('Are you sure? This action can not be undone').subscribe(() => {
            setState(state => ({
                ...state,
                isChanged: false,
                isProcessing: true
            }));

            notesService.saveAsCompleted(getData(state)).subscribe(
                () => handleGoToNotes(),
                () => {
                    setState(state => ({
                        ...state,
                        isProcessing: false
                    }));
                }
            );
        });
    }

    const handleSignOff = () => {
        notesService.openSignOff({
            patientId: state.note.patientId,
            noteType: state.note.type
        } as SignOffModel).subscribe(([employeeId, additionalNote]) => {
            const model: SignOffNoteModel = {
                noteId: stateContext.note.id,
                assignToId: employeeId,
                additionalNote: additionalNote
            };

            setState(state => ({
                ...state,
                isProcessing: true
            }));

            notesService.signOff(model).subscribe(
                () => {
                    handleSaveAsDraft();
                    handleGoToNotes();
                },
                () => {
                    setState(state => ({
                        ...state,
                        isProcessing: false
                    }))
                }
            );
        });
    }

    const handleDiscard = () => {
        confirmService.confirm('Are you sure? This action can not be undone').subscribe(() => {
            discard();
        });
    }

    const discard = () => {
        if (stateContext.isAutoSaving) {
            setTimeout(() => discard(), 50);
            return;
        }

        setState(state => ({
            ...state,
            isChanged: false,
            isProcessing: true,
        }));

        notesService.delete(state.note).subscribe(() => handleGoToNotes());
    }

    const handleInternalNotesChanged = (value: string) => {
        if (ignoreChangesEvent) {
            return;
        }

        createFollowUpComponentValidator.validateAndSetState(state, setState, CreateFollowUpComponentFieldNames.internalNote, value);
        setState(state => ({
            ...state,
            isChanged: true,
            internalContent: value
        }));
    }

    const handleInternalNotesTextChanged = (value: string) => {
        createFollowUpComponentValidator.validateAndSetState(state, setState, CreateFollowUpComponentFieldNames.internalNote, value);
        setState(state => ({
            ...state,
            isChanged: true,
            internalContent: value
        }));
    }

    const handleTodayCoachingContentChanged = (value: string) => {
        if (ignoreChangesEvent) {
            return;
        }

        createFollowUpComponentValidator.validateAndSetState(state, setState, CreateFollowUpComponentFieldNames.internalNote, value);
        setState(state => ({
            ...state,
            isChanged: true,
            content: {
                ...state.content,
                todayCoachingContent: value
            }
        }));
    }

    const handleTodayCoachingContentTextChanged = (value: string) => {
        createFollowUpComponentValidator.validateAndSetState(state, setState, CreateFollowUpComponentFieldNames.internalNote, value);
        setState(state => ({
            ...state,
            isChanged: true,
            content: {
                ...state.content,
                todayCoachingContent: value
            }
        }));
    }


    const handleOnKeyDownInternal = (event: any, id: string) => {
        ignoreChangesEvent = false;
        if (event.keyCode === 51 && event.altKey) {
            const userType = authQuery.getType();
            if (UserType.Employee === userType) {
                ignoreChangesEvent = true;
                const value = (document.getElementById(id) as any).value;
                const cursorPos = (document.getElementById(id) as any).selectionStart;
                setTimeout(() => {
                    selectShortcutService.select().subscribe((data) => {
                        data && handleInternalNotesTextChanged(insertString(value, data, cursorPos + 1))
                    })
                }, 1)
            }
        }
    }

    const handleOnKeyDownTodayCoaching = (event: any, id: string) => {
        ignoreChangesEvent = false;
        if (event.keyCode === 51 && event.altKey) {
            const userType = authQuery.getType();
            if (UserType.Employee === userType) {
                ignoreChangesEvent = true;
                const value = (document.getElementById(id) as any).value;
                const cursorPos = (document.getElementById(id) as any).selectionStart;
                setTimeout(() => {
                    selectShortcutService.select().subscribe((data) => {
                        data && handleTodayCoachingContentTextChanged(insertString(value, data, cursorPos + 1))
                    })
                }, 1)
            }
        }
    }

    const handleOnInternalNotesChanged = (value: string) => {
        handleInternalNotesChanged(value);
    }

    const getNextAppointmentDate = (isProvider: boolean): Date => {
        const nextAppointmentEnabled = isProvider ? state.enableProviderNextAppointment : state.enableCoachNextAppointment;
        if (!nextAppointmentEnabled) {
            return null;
        }

        const date = new Date();
        const targetTimeSpan = isProvider ? state.selectedProviderNextTimeSpan : state.selectedCoachNextTimeSpan;
        const targetQuantity = isProvider ? state.selectedProviderNextTimeQuantity : state.selectedCoachNextTimeQuantity;

        switch (targetTimeSpan) {
            case NextTimeSpan.Week:
                return moment(date).add(targetQuantity, 'w').toDate();
            case NextTimeSpan.Month:
                return moment(date).add(targetQuantity, 'M').toDate();
            case NextTimeSpan.Year:
                return moment(date).add(targetQuantity, 'y').toDate();
        }

        return null;
    }

    const getData = (state: CreateFollowUpComponentState): SaveNoteModel => {
        return {
            id: state.note?.id,
            content: JSON.stringify(state.content),
            internalContent: state.internalContent,
            name: noteName,
            title: state.title,
            type: NotesType.FollowUp,
            visitDate: state.visitDate,
            patientId: patientId,
            logs: [],
            nextCoachAppointmentDate: getNextAppointmentDate(false),
            nextProviderAppointmentDate: getNextAppointmentDate(true),
            appointmentId: state.note ? state.note.appointment?.id : appointmentId,
            version: state.note?.version,
            originalNoteId: state.originalNote?.id
        }
    }

    const getSaveModelFromOriginalNote = (content): SaveNoteModel => {
        return {
            id: null,
            name: noteName,
            title: state.originalNote.title,
            type: NotesType.FollowUp,
            visitDate: state.visitDate,
            patientId: patientId,
            internalContent: state.internalContent,
            content: JSON.stringify(content),
            logs: [],
            nextCoachAppointmentDate: getNextAppointmentDate(false),
            nextProviderAppointmentDate: getNextAppointmentDate(true),
            version: state.originalNote?.version,
            originalNoteId: state.originalNote?.id
        } as SaveNoteModel;
    }

    const handleNoteTitleChange = (value: string) => {
        createFollowUpComponentValidator.validateAndSetState(state, setState, CreateFollowUpComponentFieldNames.noteTitle, value);
        setState(state => ({
            ...state,
            isChanged: true,
            title: value
        }));
    }

    const titleTextBox = (
        <TextField
            label='Enter title'
            type='text'
            size="small"
            variant="outlined"
            id='title'
            value={state.title}
            helperText={state.errors['title']}
            error={!!state.errors['title']}
            disabled={state.isProcessing}
            InputProps={{ className: 'input' }}
            onChange={(e) => {
                handleNoteTitleChange(e.target.value);
            }}
        />
    )

    const handleVisitDateChanges = (momentDate: MaterialUiPickersDate) => {
        setState({
            ...state,
            isChanged: true,
            visitDate: momentDate?.toDate()
        });
    }

    const handleAddNewGoal = () => {
        const id = Date.now();
        state.content.goals.push({
            id: id,
            name: '',
            date: null,
            category: GoalCategory.Other,
            isCompleted: null,
            isTopGoal: false,
            completionDate: null,
            interventions: []
        });
        setState(state => ({ ...state, isChanged: true }));
    }

    const handleGoalNameChange = (goal: GoalModel, value: string) => {
        if (ignoreChangesEvent) {
            return;
        }

        goal.name = value;
        setState(state => ({ ...state, isChanged: true }));
        createFollowUpComponentValidator.validateAndSetState(state, setState, CreateFollowUpComponentFieldNames.goalName, value, goal.id.toString());
    }

    const handleGoalNameTextChange = (goal: GoalModel, value: string) => {
        goal.name = value;
        setState(state => ({ ...state, isChanged: true }));
        createFollowUpComponentValidator.validateAndSetState(state, setState, CreateFollowUpComponentFieldNames.goalName, value, goal.id.toString());
    }

    const handleOnKeyDownGoal = (event: any, id: string, goalId: number,) => {
        ignoreChangesEvent = false;
        const goal = state.content.goals.find(i => i.id === goalId);
        if (goal) {
            if (event.keyCode === 51 && event.altKey) {
                const userType = authQuery.getType();
                if (UserType.Employee === userType) {
                    ignoreChangesEvent = true;
                    const value = goal.name;
                    const cursorPos = (document.getElementById(id) as any).selectionStart;
                    setTimeout(() => {
                        selectShortcutService.select().subscribe((data) => {
                            data && handleGoalNameTextChange(goal, insertString(value, data, cursorPos + 1))
                        })
                    }, 1)
                }
            }
        }
    }

    const handleOnGoalNameChange = (value: string, goalId: number,) => {
        const goal = state.content.goals.find(i => i.id === goalId);
        if (goal) {
            handleGoalNameChange(goal, value);
        }
    }

    const handleRemoveGoal = (goalId: number) => {
        createFollowUpComponentValidator.validateAndSetState(state, setState, CreateFollowUpComponentFieldNames.goalName, ' ', goalId.toString());
        state.content.goals = state.content.goals.filter(i => i.id !== goalId);
        setState(state => ({ ...state, isChanged: true }));
    }

    const handleAddNewIntervention = (goalId: number) => {
        const goal = state.content.goals.find(i => i.id === goalId);
        if (goal) {
            const id = Date.now();
            goal.interventions.push({ id: id, name: '' });
            setState(state => ({ ...state, isChanged: true }));
        }
    }

    const handleInterventionChange = (goalId: number, intervention: InterventionModel, value: string) => {
        if (ignoreChangesEvent) {
            return;
        }

        intervention.name = value;
        setState(state => ({ ...state, isChanged: true }));
        createFollowUpComponentValidator.validateAndSetState(state, setState, CreateFollowUpComponentFieldNames.interventionName, value, goalId.toString() + intervention.id.toString());
    }

    const handleValidateGoals = (goalName: string, id: number) => {
        return false;
    }

    const handleInterventionTextChange = (goalId: number, intervention: InterventionModel, value: string) => {
        intervention.name = value;
        setState(state => ({ ...state, isChanged: true }));
        createFollowUpComponentValidator.validateAndSetState(state, setState, CreateFollowUpComponentFieldNames.interventionName, value, goalId.toString() + intervention.id.toString());
    }

    const handleOnKeyDownGoalInervention = (event: any, id: string, goalId: number, interventionId: number) => {
        ignoreChangesEvent = false;
        const goal = state.content.goals.find(i => i.id === goalId);
        if (goal) {
            const intervention = goal.interventions.find(i => i.id === interventionId);
            if (intervention) {
                if (event.keyCode === 51 && event.altKey) {
                    const userType = authQuery.getType();
                    if (UserType.Employee === userType) {
                        ignoreChangesEvent = true;
                        const value = intervention.name;
                        const cursorPos = (document.getElementById(id) as any).selectionStart;
                        setTimeout(() => {
                            selectShortcutService.select().subscribe((data) => {
                                data && handleInterventionTextChange(goalId, intervention, insertString(value, data, cursorPos + 1))
                            })
                        }, 1)
                    }
                }
            }
        }
    }

    const handleOnInterventionChange = (value: string, goalId: number, interventionId: number) => {
        const goal = state.content.goals.find(i => i.id === goalId);
        if (goal) {
            const intervention = goal.interventions.find(i => i.id === interventionId);
            if (intervention) {
                handleInterventionChange(goalId, intervention, value);
            }
        }
    }

    const handleRemoveIntervention = (goalId: number, interventionId: number) => {
        const goal = state.content.goals.find(i => i.id === goalId);
        if (goal) {
            createFollowUpComponentValidator.validateAndSetState(state, setState, CreateFollowUpComponentFieldNames.interventionName, ' ', goalId.toString() + interventionId.toString());
            goal.interventions = goal.interventions.filter(i => i.id !== interventionId);
            setState(state => ({ ...state, isChanged: true }));
        }
    }

    const handleAddNewNextStep = () => {
        const id = Date.now();
        state.content.steps.push({ id: id, name: '' });
        setState(state => ({ ...state, isChanged: true }));
    }

    const handleNextStepChange = (step: StepModel, value: string) => {
        if (ignoreChangesEvent) {
            return;
        }

        step.name = value;
        setState(state => ({ ...state, isChanged: true }));
        createFollowUpComponentValidator.validateAndSetState(state, setState, CreateFollowUpComponentFieldNames.nextStepName, value, step.id.toString());
    }

    const handleNextStepTextChange = (step: StepModel, value: string) => {
        step.name = value;
        setState(state => ({ ...state, isChanged: true }));
        createFollowUpComponentValidator.validateAndSetState(state, setState, CreateFollowUpComponentFieldNames.nextStepName, value, step.id.toString());
    }


    const handleOnKeyDownNextStep = (event: any, id: string, stepId: number) => {
        ignoreChangesEvent = false;
        const step = state.content.steps.find(i => i.id === stepId);
        if (step) {
            if (event.keyCode === 51 && event.altKey) {
                const userType = authQuery.getType();
                if (UserType.Employee === userType) {
                    ignoreChangesEvent = true;
                    const value = step.name;
                    const cursorPos = (document.getElementById(id) as any).selectionStart;
                    setTimeout(() => {
                        selectShortcutService.select().subscribe((data) => {
                            data && handleNextStepTextChange(step, insertString(value, data, cursorPos + 1))
                        })
                    }, 1)
                }
            }
        }
    }

    const handleOnNextStepChange = (value: string, stepId: number) => {
        const step = state.content.steps.find(i => i.id === stepId);
        if (step) {
            handleNextStepChange(step, value);
        }
    }


    const handleRemoveNextStep = (stepId: number) => {
        createFollowUpComponentValidator.validateAndSetState(state, setState, CreateFollowUpComponentFieldNames.nextStepName, ' ', stepId.toString());
        state.content.steps = state.content.steps.filter(i => i.id !== stepId);
        setState(state => ({ ...state, isChanged: true }));
    }

    const handleMedicationsIsStoppedChange = (list: MedicationsSupplementsItem[], id: number, isChecked: boolean) => {
        const item = list.find(i => i.id === id);
        if (item) {
            item.isStopped = isChecked;
            setState(state => ({ ...state, isChanged: true }));
        }
    };

    const handleAddMedications = (medication: MedicationItem) => {
        medication.id = Date.now();
        state.content.medicationsSupplements.medications.push(medication);
        setState(state => ({ ...state, isChanged: true }));
    }

    const handleAddSupplements = (supplement: SupplementItem) => {
        state.content.medicationsSupplements.supplements.push({
            ...supplement,
            id: Date.now() + Math.floor(Math.random() * 100),
        });
        setState(state => ({ ...state, isChanged: true }));
    }

    const handleFullscriptSupplements = (supplements: FullscriptSupplementModel[]) => {
        // init array if it's null. required for notes compatibility
        // which created before feature released
        if (!state.content.medicationsSupplements.fullscriptSupplements) {
            state.content.medicationsSupplements.fullscriptSupplements = [];
        }
        state.content.medicationsSupplements.fullscriptSupplements.push(...supplements);
        setState(state => ({ ...state, isChanged: true }));
    }

    const handleEditMedications = (medication: MedicationItem) => {
        const item = state.content.medicationsSupplements.medications.find(i => i.id === medication.id);
        if (item) {
            item.name = medication.name;
            item.dosage = medication.dosage;
            item.instructions = medication.instructions;
            item.startDate = medication.startDate;

            setState(state => ({ ...state, isChanged: true }));
        }
    }

    const handleEditSupplements = (supplement: SupplementItem) => {
        const item = state.content.medicationsSupplements.supplements.find(i => i.id === supplement.id);
        if (item) {
            item.name = supplement.name;
            item.dosage = supplement.dosage;
            item.instructions = supplement.instructions;
            item.purchaseLink = supplement.purchaseLink;

            setState(state => ({ ...state, isChanged: true }));
        }
    }

    const handleRemoveMedications = (id: number) => {
        state.content.medicationsSupplements.medications = state.content.medicationsSupplements.medications.filter(i => i.id !== id);
        setState(state => ({ ...state, isChanged: true }));
    }

    const handleRemoveSupplements = (id: number) => {
        state.content.medicationsSupplements.supplements = state.content.medicationsSupplements.supplements.filter(i => i.id !== id);
        setState(state => ({ ...state, isChanged: true }));
    }

    const getTimeLabel = (quantity: number, timeSpan: NextTimeSpan, isManual: boolean, displayQuantity: boolean): string => {
        return isManual
            ? 'Manual'
            : `${displayQuantity ? `${quantity} ` : ''} ${nextTimeSpanNames[timeSpan]}${quantity > 1 ? 's' : ''}`;
    }

    const handleCoachNextAppointmentChange = (value: boolean) => {
        setState({ ...state, enableCoachNextAppointment: value });
    }

    const handleCoachNextTimeModeChange = (timeModel: NextTimeModel) => {
        setState({ ...state, selectedCoachNextTimeMode: timeModel, selectedCoachNextTimeQuantity: timeModel.quantity, selectedCoachNextTimeSpan: timeModel.timeSpan })
    }

    const handleCoachNextTimeSpanChange = (timeSpan: NextTimeSpan) => {
        setState({ ...state, selectedCoachNextTimeSpan: timeSpan });
    }

    const handleCoachNextTimeQuantityChange = (quantity: number) => {
        if (!quantity) {
            setState({ ...state, selectedCoachNextTimeQuantity: 0 });
        } else {
            setState({ ...state, selectedCoachNextTimeQuantity: Math.trunc(quantity) });
        }
    }

    const handleAppointmentChanges = (appointmentId: number) => {
        if (state.note?.appointment?.id === appointmentId) {
            return;
        }

        const model = getData(state);

        model.appointmentId = appointmentId;

        notesService.saveAsDraft(model, patientsQuery.getTargetPatientIsPremium()).subscribe(
            (note) => {
                setState(state => ({
                    ...state,
                    note: note
                }))
            },
            () => {
                snackService.error('Note already exists for this appointment.');
            }
        );
    }

    const handleSelectAppointmentSubmit = (appointmentId: number) => {
        if (!appointmentId) {
            if (!patientsQuery.getTargetPatient()?.isPremium) {
                snackService.error("There was an error while attaching a note.");
            }
        } else {
            handleAppointmentChanges(appointmentId);
        }

        setIsOpen(false);
    }

    const handleChangeTab = (tab: number) => {
        setState(state => ({
            ...state,
            selectedTab: tab,
        }));
    }

    const finalVisitDate = state.note?.appointment
        ? state.note.appointment.date
        : state.note?.visitDate
            ? state.note.visitDate
            : state.visitDate;

    const getTime = () => {
        return moment(finalVisitDate).format('HH:mm');
    }

    const handleTimeChanges = (time: string) => {
        const hoursMinutes = time.split(":");
        const dateResult = new Date(finalVisitDate);
        dateResult.setHours(+hoursMinutes[0], +hoursMinutes[1]);

        setState({
            ...state,
            isChanged: true,
            visitDate: new Date(dateResult),
            note: {
                ...state.note,
                visitDate: new Date(dateResult)
            }
        });
    }

    const handleChangeDuration = (durationValue: number) => {
        const selectedDuration = durations.find(x => x.value === durationValue);
        const content = state.content;
        content.appointmentConfiguration.duration = selectedDuration.value;
        setState(state => ({ ...state, content: content, isChanged: true }));
    }

    const noteTitle = (
        <Box>
            <Box display="flex" justifyContent="space-between" mb={2} mt={2}>
                <Box />
                <Box display="flex" alignItems="center">
                    {
                        state.note?.appointment
                            ? <>
                                <Box mr={1}>Appointment Date:</Box>
                                <WildHealthButton
                                    width={256}
                                    size="medium"
                                    style={{ whiteSpace: 'nowrap' }}
                                    color="datePicker"
                                    id='sing-physical-history'
                                    onClick={() => setIsOpen(true)}
                                >
                                    <span>{moment(finalVisitDate).format("MM/DD/yyyy")}</span>
                                    <CalendarIcon />
                                </WildHealthButton>
                            </>
                            : <>
                                <Box>
                                    <Box pb={1}>
                                        <span className="wh-tw-text-sm wh-tw-font-gray1">Appointment Date</span>
                                    </Box>
                                    <WildHealthDatePicker
                                        required
                                        size="small"
                                        openTo="year"
                                        format="MM/DD/yyyy"
                                        id='AppointmentDate'
                                        placeholder="mm-dd-yyyy"
                                        useKeyboardDatePicker
                                        value={finalVisitDate}
                                        inputVariant="outlined"
                                        views={["year", "month", "date"]}
                                        onChange={(momentDate) => handleVisitDateChanges(momentDate)}
                                    />
                                </Box>
                                <Box ml={3}>
                                    <Box pb={1}>
                                        <span className="wh-tw-text-sm wh-tw-font-gray1">Time</span>
                                    </Box>
                                    <TextField
                                        id="time"
                                        size="small"
                                        className={classes.timePicker}
                                        type="time"
                                        fullWidth
                                        variant="outlined"
                                        value={getTime()}
                                        onChange={(e)=>{ handleTimeChanges(e.target.value)}}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                    />
                                </Box>
                                <Box ml={3}>
                                    <SelectDurationComponent
                                        durations={durations.filter(x => x.patientMode)}
                                        selected={durations.find(x => x.value === state.content?.appointmentConfiguration?.duration)}
                                        handleChange={(v) => handleChangeDuration(v)}
                                    />
                                </Box>
                            </>
                    }
                </Box>
            </Box>
            <TextFieldNoteComponent
                isFollowUp
                rows={10}
                uppercase={true}
                id='internal-notes'
                title='Internal Notes'
                content={state.internalContent}
                className={classes.title}
                handleChanged={handleOnInternalNotesChanged}
                handleKeyDown={handleOnKeyDownInternal}
                error={!!state.errors[CreateFollowUpComponentFieldNames.internalNote]}
                helperText={state.errors[CreateFollowUpComponentFieldNames.internalNote]}
            />
            <OptionalComponent>
                <SelectAppointmentToNotesDialog
                    handleSubmitSelectAppointment={(appointmentId) => handleSelectAppointmentSubmit(appointmentId)}
                    handleChangeSelectAppointment={() => { }}
                    handleSelectAppointmentDialog={(isOpen) => setIsOpen(isOpen)}
                    isOpen={isOpen}
                    patientId={patientId} />
            </OptionalComponent>
        </Box>
    );

    const todayCoaching = (
        <>
            <Box className={classes.title}>
                <span className="wh-tw-uppercase">
                    {'Today’s Coaching'}
                </span>
            </Box>
            <Box>
                <TextFieldNoteComponent
                    isFollowUp
                    rows={10}
                    id='today-coaching-notes'
                    content={state.content.todayCoachingContent}
                    className={classes.title}
                    handleChanged={handleTodayCoachingContentChanged}
                    handleKeyDown={handleOnKeyDownTodayCoaching}
                />
            </Box>
        </>
    )

    const recommendedListTabs = [
        {
            title: 'Medications',
            content: (
                <>
                    <FeatureComponent notFeatureFlag={FeatureFlag.RxntIntegration}>
                        <MedicationsComponent
                            items={state.content.medicationsSupplements.medications.filter(i => !i.isInCurrent)}
                            handleAddMedications={handleAddMedications}
                            handleEditMedications={handleEditMedications}
                            handleRemoveMedications={handleRemoveMedications}
                        />
                    </FeatureComponent>
                    <FeatureComponent featureFlag={FeatureFlag.RxntIntegration}>
                        <MedicationsRxNTComponent
                            patientId={patientId}
                            items={state.content.medicationsSupplements.rxntMedications?.filter(i => !i.isInCurrent) ?? []}
                        />
                    </FeatureComponent>
                </>
            ),
            keepMounted: true,
        },
        {
            title: 'Supplements',
            content: (
                <SupplementsComponent
                    patientId={patientId}
                    items={state.content.medicationsSupplements.supplements.filter(i => !i.isInCurrent)}
                    fullscriptSupplements={state.content.medicationsSupplements.fullscriptSupplements}
                    handleAddSupplements={handleAddSupplements}
                    handleFullscriptSupplements={handleFullscriptSupplements}
                    handleEditSupplements={handleEditSupplements}
                    handleRemoveSupplements={handleRemoveSupplements}
                />
            ),
            keepMounted: true,
        },
    ];

    const currentListTabs = [
        {
            title: 'Medications',
            content: (
                <>
                    <FeatureComponent notFeatureFlag={FeatureFlag.RxntIntegration}>
                        <MedicationsDCComponent
                            items={state.content.medicationsSupplements.medications.filter(i => i.isInCurrent)}
                            handleMedicationsIsStoppedChange={(id: number, isChecked: boolean) => handleMedicationsIsStoppedChange(state.content.medicationsSupplements.medications, id, isChecked)}
                        />
                    </FeatureComponent>
                    <FeatureComponent featureFlag={FeatureFlag.RxntIntegration}>
                        <RxntMedicationsDCComponent items={state.content.medicationsSupplements.rxntMedications}/>
                    </FeatureComponent>
                </>
            ),
            keepMounted: true,
        },
        {
            title: 'Supplements',
            content: (
                <SupplementsDCComponent
                    items={state.content.medicationsSupplements.supplements.filter(i => i.isInCurrent)}
                    handleMedicationsIsStoppedChange={(id: number, isChecked: boolean) => handleMedicationsIsStoppedChange(state.content.medicationsSupplements.supplements, id, isChecked)}
                />
            ),
            keepMounted: true,
        },
    ];


    const handleGoalsChanges = (field: string, goals: GoalModel[]) => {
        state.content.plan[field] = goals;
        setState({ ...state, isChanged: true });
    }

    const handleEducationChanges = (field: string, educations: string) => {
        state.content.plan[field] = educations;
        setState({ ...state, isChanged: true });
    }

    const goals = (
        <Box my={2}>
            <Box display='flex' justifyContent="space-between" alignItems="center">
                <Box className={classes.title}>
                    <span className='uppercase'>Goals</span>
                </Box>

                {state.content?.goalsVersion !== NoteGoalsVersion.New
                    && <Button id="create-follow-up-add-goal" onClick={() => handleAddNewGoal()}>
                        <AddIcon />
                        Add New Goal
                    </Button>}
            </Box>
            {state.content?.goalsVersion === NoteGoalsVersion.New
                ? <NotePlanComponent
                    notesType={NotesType.FollowUp}
                    handleValidateGoals={handleValidateGoals}
                    data={state.content.plan}
                    visitDate={state.visitDate}
                    patientId={patientId}
                    handleGoalsChanges={handleGoalsChanges}
                    handleEducationChanges={handleEducationChanges}
                />
                : <Box>
                    {
                        state.content?.goals?.length ?
                            state.content.goals.map((goal, goalIndex) =>
                                <Box mt={5} key={goalIndex}>
                                    <Grid container spacing={1}>
                                        <Grid key={goal.id} item xs={6}>
                                            <Box display="flex">
                                                <TextFieldNoteComponent
                                                    isFollowUp
                                                    uppercase
                                                    className={classes.sectionTitle}
                                                    rows={1}
                                                    title={`Goal ${goalIndex + 1}`}
                                                    label="Goal Name"
                                                    id={`goal-${goal.id}-name`}
                                                    content={goal.name}
                                                    handleChanged={handleOnGoalNameChange}
                                                    handleKeyDown={handleOnKeyDownGoal}
                                                    error={!!state.errors[CreateFollowUpComponentFieldNames.goalName + goal.id]}
                                                    helperText={state.errors[CreateFollowUpComponentFieldNames.goalName + goal.id]}
                                                    key={`goal-${goal.id}-name`}
                                                    key2={goal.id}
                                                />
                                                <Box>
                                                    <Button
                                                        id="create-follow-up-add-goal"
                                                        onClick={() => handleRemoveGoal(goal.id)}
                                                        color='secondary'
                                                    >
                                                        <DeleteOutlineIcon />
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} />

                                    </Grid>

                                    <Box my={2}>
                                        <Button id="create-follow-up-add-intervention" onClick={() => handleAddNewIntervention(goal.id)}>
                                            <AddIcon />
                                            Add Intervention
                                        </Button>
                                    </Box>

                                    <Grid
                                        container
                                        spacing={1}
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="flex-start"
                                    >
                                        {
                                            goal.interventions.map((intervention, interventionIndex) =>
                                                <Grid item xs={6} key={interventionIndex}>
                                                    <Box mr={1}>
                                                        <Box key={intervention.id} display="flex" mb={2}>
                                                            <TextFieldNoteComponent
                                                                isFollowUp
                                                                uppercase
                                                                className={classes.sectionTitle}
                                                                rows={5}
                                                                title={`Intervention ${interventionIndex + 1}`}
                                                                label="Intervention Name"
                                                                id={`intervention-${intervention.id}-name`}
                                                                content={intervention.name}
                                                                handleChanged={handleOnInterventionChange}
                                                                handleKeyDown={handleOnKeyDownGoalInervention}
                                                                error={!!state.errors[CreateFollowUpComponentFieldNames.interventionName + goal.id + intervention.id]}
                                                                helperText={state.errors[CreateFollowUpComponentFieldNames.interventionName + goal.id + intervention.id]}
                                                                key={`intervention-${intervention.id}-name`}
                                                                key2={goal.id}
                                                                key3={intervention.id}
                                                            />
                                                            <Box>

                                                                <Box mb={1}>
                                                                    <Button
                                                                        id="create-follow-up-remove-intervention"
                                                                        onClick={() => handleRemoveIntervention(goal.id, intervention.id)}
                                                                        color='secondary'
                                                                    >
                                                                        <DeleteOutlineIcon />
                                                                    </Button>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                </Box>
                            ) :
                            <Box my={2}>
                                <WildHealthPlaceHolder message="No goals yet." />
                            </Box>
                    }
                </Box>
            }
            <Divider className={classes.divider} />
        </Box>
    );

    let coachAppointment;

    const displayAppointmentDate = (appointment: PatientAppointmentModel) => {
        return (
            <Box display="flex" alignItems="center" mt={2}>
                <Box>
                    <img alt="Monthly calendar" src={monthlyCalendar} />
                </Box>
                <Box ml={1}>
                    <span>{moment(appointment.startDate).format('dddd DD, MMMM yyyy')}</span>
                </Box>
                <Box ml={2}>
                    <img alt="Time circle" src={timeCircle} />
                </Box>
                <Box ml={1}>
                    <span>{moment(appointment.startDate).format('HH:mm')}</span>
                </Box>
            </Box>
        )
    }


    const renderNextAppointmentDates = () => {
        return (
            (state.prewNote.nextCoachAppointmentDate && moment(state.prewNote.nextCoachAppointmentDate) > moment(new Date()))
                || (state.prewNote.nextProviderAppointmentDate && moment(state.prewNote.nextProviderAppointmentDate) > moment(new Date()))
                ?
                <Box mb={4}>
                    <Box mb={2}>
                        <span className={clsx(commonClasses.textMedium, commonClasses.size16, commonClasses.colorGray1)}>Previous Note Appointment Times</span>
                    </Box>
                    {state.patientSubscription && <Box display='flex' justifyContent='space-between' width={0.25}>
                        <Box>
                            <span>Plan start date:</span>
                        </Box>
                        <Box>
                            <span>{moment(toCurrentTimeZone(state.patientSubscription.startDate)).format('MM-DD-YYYY')}</span>
                        </Box>
                    </Box>
                    }
                    {state.prewNote.nextCoachAppointmentDate &&
                        <Box display='flex' justifyContent='space-between' width={0.25}>
                            <Box>
                                <span>Coach Visit:</span>
                            </Box>
                            <Box>
                                <span>{moment(toCurrentTimeZone(state.prewNote.nextCoachAppointmentDate)).format('MM-DD-YYYY')}</span>
                            </Box>
                        </Box>
                    }
                    {state.prewNote.nextProviderAppointmentDate &&
                        <Box display='flex' justifyContent='space-between' width={0.25}>
                            <Box>
                                <span>Provider Visit:</span>
                            </Box>
                            <Box>
                                <span>{moment(toCurrentTimeZone(state.prewNote.nextProviderAppointmentDate)).format('MM-DD-YYYY')}</span>
                            </Box>
                        </Box>
                    }
                </Box>
                : <></>
        )
    }

    const nextAppointments = (
        <Box my={2}>
            <Box mb={2}>
                <span className={clsx(commonClasses.textMedium, commonClasses.size16)}>Next Appointment</span>
            </Box>
            {renderNextAppointmentDates()}
            <Divider />
            <Box mt={2}>
                <Box display="flex" alignItems="center">
                    <FormControlLabel
                        control={
                            <Checkbox
                                icon={controlIcon}
                                color="primary"
                                checkedIcon={controlCheckedIcon}
                                checked={state.enableCoachNextAppointment}
                                onChange={(e) => handleCoachNextAppointmentChange(e.target.checked)}
                            />
                        }
                        label={<Box component="div" fontWeight={400}>Coach Visit</Box>}
                    />
                </Box>
                {
                    Boolean(coachAppointment)
                        ? <>
                            {
                                displayAppointmentDate(coachAppointment)
                            }
                        </>
                        : <>
                            <Box display="flex" justifyContent="flex-start" alignItems="center" mt={1}>
                                {
                                    coachNextAppointmentTimes.map((time, index) => (
                                        <Box key={index} display="flex" alignItems="center" mr={2}>
                                            <Box>
                                                <WildHealthRadio
                                                    checked={(time.timeSpan === state.selectedCoachNextTimeMode.timeSpan && time.quantity === state.selectedCoachNextTimeMode.quantity) || (time.isManual && state.selectedCoachNextTimeMode.isManual)}
                                                    onChange={() => handleCoachNextTimeModeChange(time)}
                                                    disabled={!state.enableCoachNextAppointment}
                                                />
                                            </Box>
                                            <Box>
                                                <span>{getTimeLabel(time.quantity, time.timeSpan, time.isManual, true)}</span>
                                            </Box>
                                        </Box>
                                    ))
                                }
                            </Box>
                            {
                                state.selectedCoachNextTimeMode.isManual &&
                                <Box ml={1} mt={1} display="flex" alignItems="center">
                                    <Box width={150}>
                                        <TextField
                                            InputProps={{ inputProps: { min: 1 } }}
                                            value={state.selectedCoachNextTimeQuantity}
                                            onChange={(v) => handleCoachNextTimeQuantityChange(+v.target.value as number)}
                                            variant="outlined"
                                            size="small"
                                            disabled={!state.enableCoachNextAppointment}
                                        />
                                    </Box>
                                    <Box ml={1} width={150}>
                                        <SelectIntervalComponent
                                            handleChange={handleCoachNextTimeSpanChange}
                                            disabled={!state.enableCoachNextAppointment}
                                            items={manualTimeSpans}
                                            selected={state.selectedCoachNextTimeSpan}
                                        />
                                    </Box>
                                </Box>
                            }
                        </>
                }

            </Box>
        </Box>
    )

    const renderViewElement = (supplement: SupplementItem) =>
        <Box display='flex' key={supplement.id} mr={1.5} mb={1.5} className={classes.rootView}>
            <Box>
                {supplement.name}
            </Box>
            <Box
                id="select-common-supplements-follow-up"
                className={classes.button}
                onClick={() => {
                    handleChangeTab(1)
                    handleAddSupplements(supplement)
                }}>
                <AddIcon fontSize='small' />
            </Box>
        </Box>

    const renderCommonSupplements = () => {
        return <Box mb={3} mt={1}>
            <Box className={commonClasses.colorGray1}>Common Supplements</Box>
            <Box mt={1.5} display='flex' flexWrap='wrap'>
                {notesQuery.getCommonSupplements().map(supplement => renderViewElement(supplement))}
            </Box>
        </Box>
    }

    const supplements = (
        <Box my={2}>
            <Box display='flex' justifyContent="space-between" alignItems="center">
                <Box className={classes.title}>
                    <span className='uppercase'>Supplements</span>
                </Box>

                <Box />
            </Box>
            {renderCommonSupplements()}
            <Box my={1}>
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <Box>
                            <Box display='flex' justifyContent="space-between" alignItems="center">
                                <Box alignItems="center" className={classes.sectionTitle} py={1}>
                                    <span className='uppercase'>Recommended List</span>
                                </Box>
                            </Box>
                            <Box className={classes.recommendedList}>
                                <WildHealthTabControl items={recommendedListTabs} className={useStylesSimpleTabs()} initTab={state.selectedTab} onChangeCB={handleChangeTab} />
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Box>
                            <Box display='flex' justifyContent="space-between" alignItems="center">
                                <Box className={classes.sectionTitle} py={1}>
                                    <span className='uppercase'>Current List</span>
                                </Box>
                            </Box>
                            <WildHealthTabControl items={currentListTabs} className={useStylesSimpleTabs()} />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Divider className={classes.divider} />
        </Box>
    );

    const nextSteps = (
        <Box my={2}>
            <Box display='flex' justifyContent="space-between" alignItems="center">
                <Box className={classes.title}>
                    <span className='uppercase'>Next Steps</span>
                </Box>

                <Button id="create-follow-up-add-new-step" onClick={() => handleAddNewNextStep()}>
                    <AddIcon />
                    Add New Step
                </Button>
            </Box>
            <Box>
                {
                    state.content.steps.length ?
                        state.content.steps.map((item, index) =>
                            <Box key={index} my={2} display='flex'>
                                <TextFieldNoteComponent
                                    isFollowUp
                                    rows={1}
                                    uppercase
                                    title={`step ${index + 1}`}
                                    label="Next Step Name"
                                    id={`next-step-${item.id}-name`}
                                    content={item.name}
                                    handleChanged={handleOnNextStepChange}
                                    handleKeyDown={handleOnKeyDownNextStep}
                                    error={!!state.errors[CreateFollowUpComponentFieldNames.nextStepName + item.id]}
                                    helperText={state.errors[CreateFollowUpComponentFieldNames.nextStepName + item.id]}
                                    key={`next-step-${item.id}-name`}
                                    key2={item.id}
                                />
                                <Box mt={5.5}>
                                    <Button
                                        id="create-follow-up-remove-next-step"
                                        onClick={() => handleRemoveNextStep(item.id)}
                                        color='secondary'
                                    >
                                        <DeleteOutlineIcon />
                                    </Button>
                                </Box>
                            </Box>
                        ) :
                        <Box my={2}>
                            <WildHealthPlaceHolder message="No next steps yet." />
                        </Box>
                }
            </Box>
        </Box>
    );

    const endOfTemplate = (
        <>
            {
                !state.isProcessing &&
                <Box mt={5} pb={5} display='flex' alignItems='center' justifyContent="space-between">
                    <Box display="flex">
                        <Box>
                            <WildHealthButton
                                width={195}
                                size="large"
                                style={{ whiteSpace: 'nowrap' }}
                                disabled={isFutureAppointment(state.note) || state.note?.isCompleted || state.isProcessing || !isCanCompleteNote()}
                                id='sing-physical-history'
                                onClick={() => handleSaveAndComplete()}
                            >
                                <span>Sign and Complete</span>
                            </WildHealthButton>
                        </Box>
                        <Box ml={2}>
                            <WildHealthButton
                                width={151}
                                style={{ whiteSpace: 'nowrap' }}
                                size="large"
                                disabled={state.note?.isCompleted || state.isProcessing}
                                id='sing-physical-history'
                                color="secondary"
                                onClick={() => handleSaveAsDraft()}
                            >
                                <span>Save as Draft</span>
                            </WildHealthButton>
                        </Box>
                        <FeatureComponent featureFlag={FeatureFlag.SignOffNotes}>
                            <Box ml={2}>
                                <WildHealthButton
                                    width={151}
                                    style={{ whiteSpace: 'nowrap' }}
                                    size="large"
                                    disabled={state.note?.isCompleted || state.isProcessing || !state.note}
                                    id='sing-physical-history'
                                    color="secondary"
                                    onClick={() => handleSignOff()}
                                >
                                    <span>Send for Approval</span>
                                </WildHealthButton>
                            </Box>
                        </FeatureComponent>
                    </Box>
                    <Box>
                        <WildHealthButton
                            size="large"
                            width={111}
                            disabled={state.isProcessing || !canDiscardNote(stateContext.note)}
                            id='cancel-physical-history'
                            onClick={() => { handleDiscard() }}
                            color='tertiary'
                        >
                            Discard
                        </WildHealthButton>
                    </Box>
                </Box>
            }
            {
                state.isProcessing &&
                <Box display="flex" justifyContent="center">
                    <Box>
                        <CircularProgress size={24} style={{ color: colors.main }} />
                    </Box>
                </Box>
            }
            <SignOffDialogComponent />
        </>
    );

    const validateContent = (content: FollowUpContent) => {
        if (state.content?.goalsVersion !== NoteGoalsVersion.New) {
            content.goals && content.goals.forEach((goal) => {
                createFollowUpComponentValidator.validateAndSetState(state, setState, CreateFollowUpComponentFieldNames.goalName, goal.name, goal.id.toString());

                goal.interventions.forEach((item) => createFollowUpComponentValidator.validateAndSetState(state, setState, CreateFollowUpComponentFieldNames.interventionName, item.name, goal.id.toString() + item.id.toString()));
            });
        }

        content.steps.forEach((item) => createFollowUpComponentValidator.validateAndSetState(state, setState, CreateFollowUpComponentFieldNames.nextStepName, item.name, item.id.toString()));
    }

    const autoSave = () => {
        if (stateContext.isChanged
            && !stateContext.isAutoSaving
            && !stateContext.isProcessing
            && !state.isFirstSaveOriginalNote
            && isElementEnabled([PermissionType.Coaching, PermissionType.ManagePatients], UserType.Employee)) {
            if (!createFollowUpComponentValidator.stateIsValid(stateContext)) {
                return;
            }

            setState(state => ({
                ...state,
                isAutoSaving: true
            }));

            notesService.saveAsDraft(getData(stateContext), patientsQuery.getTargetPatientIsPremium()).subscribe(note => {
                setState(state => ({
                    ...state,
                    note: {
                        ...state.note,
                        id: state?.note?.id ?? note.id,
                    },
                    isChanged: false,
                    isAutoSaving: false,
                }));
            },
                () => {
                    setState(state => ({
                        ...state,
                        isAutoSaving: false,
                    }));
                });
        }
    }

    const getContent = (noteId: number) => {
        notesService.getContentByEmployee(noteId, patientId).subscribe(result => {
            state.isLoading = false
            setState(state => ({
                ...state,
                isLoading: false,
                isNew: false,
                isPrefilled: true,
                content: JSON.parse(result.content),
                internalContent: result.internalContent,
            }));
        })
    }

    const getMedicationsSupplements = () => {
        setState(state => ({
            ...state,
            isAutoSaving: true
        }));

        if (originalNote) {
            notesService.getContentByEmployee(originalNote.id, patientId).subscribe(result => {
                const content = JSON.parse(result.content) as FollowUpContent
                setState(state => ({
                    ...state,
                    isNoteContentLoading: false,
                    content: content,
                    internalContent: result.internalContent,
                    title: originalNote.title
                }));

                notesService.saveAsDraft(getSaveModelFromOriginalNote(content), patientsQuery.getTargetPatientIsPremium()).subscribe(
                    (note) => {
                        setState(state => ({
                            ...state,
                            note: note,
                            isAutoSaving: false,
                            isFirstSaveOriginalNote: false
                        }))
                    },
                    () => {
                        snackService.error('Note already exists for this appointment.');
                    }
                );
            });
        }

        else {
            notesService.saveAsDraft(getData(state), patientsQuery.getTargetPatientIsPremium()).subscribe(
                (note) => {
                    setState(state => ({
                        ...state,
                        note: note,
                        isLoading: false,
                        isAutoSaving: false
                    }))
                },
                () => {
                    snackService.error('Note already exists for this appointment.');
                }
            );
        }


        medicationsService.get(patientId).subscribe(medications => {
            state.content.medicationsSupplements.medications = medications.map(i => {
                return {
                    id: i.id,
                    name: i.name,
                    dosage: i.dosage,
                    instructions: i.instructions,
                    isStopped: false,
                    isInCurrent: true
                } as MedicationItem
            });

            setState(state => ({ ...state }));

            supplementsService.get(patientId).subscribe(supplements => {
                state.content.medicationsSupplements.supplements = supplements.map(i => {
                    return {
                        id: i.id,
                        name: i.name,
                        dosage: i.dosage,
                        isStopped: false,
                        purchaseLink: i.purchaseLink,
                        source: i.source,
                        isInCurrent: true
                    } as SupplementItem
                });

                setState(state => ({
                    ...state,
                    isLoading: false
                }));
            });
        });
    }

    const isNoteWithAppointment = (type: number) => {
        switch (type) {
            case NotesType.FollowUp:
            case NotesType.Soap:
            case NotesType.HistoryAndPhysicalInitial:
            case NotesType.HistoryAndPhysicalFollowUp:
                return true;
            default:
                return false;
        }
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            autoSaveTimer.subscribe(() => {
                autoSave()
            }),
            onEmit<NotesGroupModel[]>(notesQuery.notesGroups$, notesGroups => {
                if (notesGroups.some(group => group.notes.some(note => note.patientId !== patientId))) {
                    return;
                }

                if (state.originalNote) {
                    return;
                }

                let notes: NoteModel[] = [];
                notesGroups.forEach(group => {
                    notes.push(...group.notes.filter(n => n.isCompleted && isNoteWithAppointment(n.type)));
                });

                const previousNote = notes && notes.length
                    ? notes.sort((a, b) => (a.visitDate < b.visitDate) ? 1 : ((b.visitDate < a.visitDate) ? -1 : 0))[0] : null;

                const previousFollowUpNotes = notes &&
                    notes.filter(x => x.type === NotesType.FollowUp)
                        .sort((a, b) => new Date(b.visitDate).getTime() - new Date(a.visitDate).getTime())

                const previousFollowUpNote = previousFollowUpNotes &&
                    previousFollowUpNotes.length && previousFollowUpNotes[0];

                if (previousNote) {
                    setState(state => ({ ...state, prewNote: previousNote }));
                }

                if (previousFollowUpNote) {
                    patientsService.get(patientId, DataSpecificationsEnum.UpdatePatientSpecification);
                }

                if (!stateContext.note && !stateContext.isPrefilled) {
                    goalsService.getCurrentGoalsByEmployee(patientId).subscribe(
                        (goals) => {
                            setState((state) => ({
                                ...state,
                                isPrefilled: true,
                                isChanged: true,
                                content: {
                                    ...state.content,
                                    goalsVersion: NoteGoalsVersion.New,
                                    plan: {
                                        ...state.content.plan,
                                        mindfulnessGoals: goals.filter(x => x.category === GoalCategory.Mindfulness),
                                        longevityGoals: goals.filter(x => x.category === GoalCategory.Longevity),
                                        exerciseGoals: goals.filter(x => x.category === GoalCategory.Exercise),
                                        sleepGoals: goals.filter(x => x.category === GoalCategory.Sleep),
                                        neuroGoals: goals.filter(x => x.category === GoalCategory.Neuro),
                                        dietGoals: goals.filter(x => x.category === GoalCategory.Diet)
                                    }
                                }
                            }))
                        }
                    );

                    notesService.getByEmployee(patientId).subscribe((notes) => {
                        const noteType = [
                            NotesType.HistoryAndPhysicalInitial,
                            NotesType.HistoryAndPhysicalFollowUp,
                            NotesType.HistoryAndPhysicalGroupVisit,
                            NotesType.FollowUp
                        ];

                        const lastNote = notes
                            .filter(x => noteType.includes(x.type))
                            .filter(x => x.isCompleted)
                            .sort((a1, a2) => handleCompare(a1, a2, SortingDirection.Asc, 'visitDate'))
                            .find(x => true);

                        const lastFollowUpNote =  notes
                            .filter(x => noteType.includes(NotesType.FollowUp))
                            .filter(x => x.isCompleted)
                            .sort((a1, a2) => handleCompare(a1, a2, SortingDirection.Asc, 'visitDate'))
                            .find(x => true);

                        if (lastFollowUpNote) {
                            notesService.getContentByEmployee(lastFollowUpNote.id, patientId).subscribe((content) => {
                                const internalContent: FollowUpContent = JSON.parse(content.content);
                                setState((state) => ({
                                    ...state,
                                    isPrefilled: true,
                                    isChanged: true,
                                    content: {
                                        ...state.content,
                                        todayCoachingContent: internalContent?.todayCoachingContent ?? ''
                                    }
                                }))
                            }) 
                        }

                        if (lastNote) {
                            notesService.getContentByEmployee(lastNote.id, patientId).subscribe((content) => {
                                const internalContent: FollowUpContent = JSON.parse(content.content);
                                setState((state) => ({
                                    ...state,
                                    isPrefilled: true,
                                    isChanged: true,
                                    content: {
                                        ...state.content,
                                        goalsVersion: NoteGoalsVersion.New,
                                        plan: {
                                            ...state.content.plan,
                                            longevityEducations: internalContent?.plan?.longevityEducations ?? state.content.plan.longevityEducations,
                                            exerciseEducations: internalContent?.plan?.exerciseEducations ?? state.content.plan.exerciseEducations,
                                            sleepEducations: internalContent?.plan?.sleepEducations ?? state.content.plan.sleepEducations,
                                            neuroEducations: internalContent?.plan?.neuroEducations ?? state.content.plan.neuroEducations,
                                            dietEducations: internalContent?.plan?.dietEducations ?? state.content.plan.dietEducations,
                                            mindfulnessEducations: internalContent?.plan?.mindfulnessEducations ?? state.content.plan.mindfulnessEducations
                                        }
                                    }
                                }))
                            })
                        }
                    });
                }
            }),
            onEmit<PatientAppointmentModel[]>(appointmentsQuery.patientAppointments$, appointments => {
                setState(state => ({ ...state, appointments: appointments }));
            }),
            onEmit<PatientModel>(patientsQuery.targetPatient$, targetPatient => {
                if (targetPatient) {
                    if (note) {
                        targetPatient.id === note.patientId && setState(state => ({ ...state, patientSubscription: targetPatient.subscription }))
                        return
                    }
                    setState(state => ({ ...state, patientSubscription: targetPatient.subscription }))
                }
            }),
        ];
  
        const commonGoalCB = () => setState(state => ({ ...state, isCommonGoalLoading: false }));

        const commonMDMCB = () => setState(state => ({ ...state, isCommonMdmLoading: false }));

        const commonSupplementCB = () => setState(state => ({ ...state, isCommonSupplementLoading: false }));
        
        mdmPlansService.getAllCommonMdms().subscribe(commonMDMCB, commonMDMCB);
    
        goalsService.getAllCommonGoals().subscribe(commonGoalCB, commonGoalCB);

        commonSupplementsService.getAllCommonSupplements().subscribe(commonSupplementCB, commonSupplementCB);

        if (stateContext.note && !originalNote) {
            getContent(stateContext.note.id);
        } else {
            getMedicationsSupplements();
        }

        appointmentsService.getPatientAppointmentsById(patientId, new Date()).subscribe();
        notesService.getByEmployee(patientId).subscribe();

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, [patientId, note]);

    return [
        state,
        noteTitle,
        todayCoaching,
        goals,
        supplements,
        nextSteps,
        endOfTemplate,
        nextAppointments,
        noteName,
        titleTextBox
    ];
}
