import { Box, Dialog, DialogContent } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import commonUseStyles from '../../../common/styles/common.styles';
import { DialogTitle } from '../../../common/components/dialogTitle/DialogTitle';
import { useStyles } from '../scheduledMessagesComponent/scheduledMessagesComponent.styles';
import { ManageableMemberViewModel, ManageChatMembersComponent } from '../chatMembersComponent/ManageChatMembersComponent';

interface ManageChatMembersDialogComponentProps {
    members: ManageableMemberViewModel[];
    disableAddMember?: boolean;
    disableRemoveMember?: boolean;
    handleToggleAddUser: () => void;
    handleRemoveUserFromThread: (id: number) => void;
    open: boolean;
    handleClose: () => void;
}

export const ActiveUsersDialogComponent: React.FC<ManageChatMembersDialogComponentProps> = (props: ManageChatMembersDialogComponentProps) => {
    const {
        members,
        disableAddMember,
        disableRemoveMember,
        handleToggleAddUser,
        handleRemoveUserFromThread,
        open,
        handleClose,
    } = props;

    const commonClasses = commonUseStyles();
    const classes = useStyles()

    return (
        <>
            <Dialog
                fullWidth
                maxWidth='sm'
                open={open}
                onClose={handleClose}>

                <DialogTitle
                    id="customized-dialog-title-scheduled"
                    onClose={() => handleClose()}
                >
                    <span className={clsx(commonClasses.size24, commonClasses.textMedium, classes.dialogTitle)}>
                        Active Users on Thread
                    </span>
                </DialogTitle>

                <DialogContent>
                    <Box mb={4} ml={1}>
                        <ManageChatMembersComponent
                            isDialogActiveUsers={true}
                            members={members}
                            disableAddMember={disableAddMember}
                            disableRemoveMember={disableRemoveMember}
                            handleAddMember={() => handleToggleAddUser()}
                            handleRemoveMember={(id) => handleRemoveUserFromThread(id)}
                        />
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
}