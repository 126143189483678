import {useEffect, useState} from "react";
import {GoalModel} from "../../../../notes/models/notes.models";
import {goalsService} from "../../../../notes/services/goals.service";


interface HealthGoalDialogComponentState {
    isOpen: boolean;
    goal: GoalModel;
    callBack: Function;
}

export function useFacade(): [
    HealthGoalDialogComponentState,
    () => void
] {
    const [state, setState] = useState({
        isOpen: false,
        goal: null,
        callBack: null
    } as HealthGoalDialogComponentState);

    const close = () => {
        setState(state => ({
            ...state,
            isOpen: false,
            goal: null,
            callBack: null
        }));
    }

    const handleClose = () => {
        state.callBack();
        close();
    }

    useEffect(() => {
        const subscription = goalsService.onView.subscribe((model) => {
            const goal = model.data;

            setState(state => ({
                ...state,
                isOpen: true,
                callBack: model.callback,
                goal: goal
            }));
        });

        return () => subscription.unsubscribe();
    }, [])

    return [
        state,
        handleClose,
    ];
}