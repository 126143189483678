import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: colors.white,
            minHeight: '100vh',
        },
        logo: {
            width: 160,
            maxHeight: 50,
        }
    }),
);