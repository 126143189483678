import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        report: {
            minHeight: 300,
            maxHeight: 'calc(100vh - 311px)',
            overflowX: 'auto',
        },
        root: {
            display: 'flex',
            backgroundColor:"#fff",
            height:'calc(100vh - 175px)'
        },
        toolBarSection: {
            marginRight: 8,
            [theme.breakpoints.down("xs")]: {
                marginRight: 0,
                marginTop: 8,
            }
        }
    }),
);