import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.down("xs")]: {
        boxShadow: "0px -5px 9px rgba(168, 173, 198, 0.1)",
        borderRadius: "6px 6px 0px 0px",
      },
    },
    subjectItem: {
      background: colors.gray3,
      [theme.breakpoints.down("xs")]: {
        backgroundColor: colors.white,
        fontSize: 14,
        ".MuiListItem-divider": {
          borderBottomColor: colors.separators,
        },
        "& span": {
          textAlign: "center",
          width: "100%",
        },
      },
    },
  })
);
