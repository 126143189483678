import React from "react";
import {Box, Tooltip} from "@material-ui/core";
import {colors} from "../../../common/constants/colors";
import {SpecialTestModel} from "../../models/notes.models";
import InfoIcon from "@material-ui/icons/Info";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from '@material-ui/icons/Delete';
import {useStyles} from "./SpecialtyTestsComponent.styles";
import { notesQuery } from "../../stores/notes";

interface SpecialtyTestsComponentProps {
    specialTests: SpecialTestModel[];
    handleTestUpdates: Function;
}

export const SpecialtyTestsComponent: React.FC<SpecialtyTestsComponentProps> = (props: SpecialtyTestsComponentProps) => {

    const {
        specialTests,
        handleTestUpdates
    } = props;

    const classes = useStyles();
    const customTooltipClasses = {
        tooltip: classes.customWidth,
        arrow: classes.arrow,
        tooltipPlacementBottom: classes.tooltipPlacement,
        tooltipPlacementTop: classes.tooltipPlacement,
    };

    const isSelected = (test: SpecialTestModel) => {
        return specialTests.some(x => x.id === test.id);
    }

    const handleAddTest = (test: SpecialTestModel) => {
        handleTestUpdates([...specialTests, test]);
    }

    const handleRemoveTest = (test: SpecialTestModel) => {
        handleTestUpdates(specialTests.filter(x => x.id !== test.id));
    }

    const renderViewElement = (test: SpecialTestModel, index: number) => {
        const selected = isSelected(test);

        return (
            <Box display='flex' key={index} mr={1.5} mb={1.5} className={classes.rootView}>
                <Box width='28px' minWidth='28px' display='flex' alignItems='center' mr={1} justifyContent='flex-end'>
                    <Box position='relative' pt={0.5}>
                        <Tooltip placement="bottom" arrow
                                 title={<Box className={classes.notificationToolTipText}>
                                     {test.company} - {test.name} {test.cost.length ? '- ' + test.cost : ''}
                                 </Box>}
                                 classes={customTooltipClasses}
                        >
                            <InfoIcon className={classes.iconInfo} fontSize='small' style={{ fill: colors.gray2 }} />
                        </Tooltip>
                    </Box>
                </Box>
                <Box>
                    {test.name}
                </Box>
                <Box
                    className={classes.button}
                    onClick={() => selected ? handleRemoveTest(test) : handleAddTest(test)}>
                    {
                        selected
                            ? <DeleteIcon fontSize='small' />
                            : <AddIcon fontSize='small' />
                    }
                </Box>
            </Box>
        )
    }


    return (
        <>
            <Box mb={3} mt={1}>
                <Box mt={1.5} display='flex' flexWrap='wrap'>
                    {notesQuery.getCommonOrders().map((test, index) => renderViewElement(test, index))}
                </Box>
            </Box>
        </>
    );
}