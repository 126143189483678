import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { onEmit } from "../../../common/helpers/on-emit";
import { PatientOnBoardingStatus, PatientShortModel, PatientViewModel } from "../../../patients/models/patient.model";
import { patientsService } from "../../../patients/services/patients.service";
import { patientsQuery } from "../../../patients/stores/patientsStore";
import { ISortingState } from "../../../common/sorting/models/sorting-state";
import { SortingDirection } from "../../../common/sorting/models/sorting-destination";
import { handleCompare } from "../../../common/sorting/helpers/handle-compare";
import { EmployeeType } from "../../models/employee.enums";
import { authQuery } from "../../../auth/stores/auth";
import { PaymentPlanFilterModel, PaymentPlanModel } from "../../../payment/models/paymentPlan.models";
import {  StateViewModel } from "../manageAllPatientsComponent/manageAllPatientsComponent.hooks";
import { stateList } from "../../../common/sorting/models/sorting-state";
import { AddOnFilterModel, addOnsList } from "../../../addons/models/addOns.models";
import { getPaymentPlanFullName } from "../../../payment/helpers/getPlanFullName";
import { paymentPlansService } from "../../../payment/services/paymentPlans.service";
import {orderTypeNames} from "../../../orders/models/orders.models";

export enum SortingSource {
    none,
    patientName = 'lastName',
    plan = 'plan',
    joinedPractice = 'joinedPractice',
    providers = 'providers',
    coaches = 'coaches',
    location = 'location',
}

export interface IntakePatientsFilterModel {
    paymentPlans: PaymentPlanFilterModel[];
    searchQuery: string;
    addOns: AddOnFilterModel[],
    state: StateViewModel[],
}

interface IntakePatientsFiltersState {
    isOpen: boolean;
    anchorEl: HTMLButtonElement;
    isPodsOpen: boolean;
    isPlansOpen: boolean;
    isProvidersOpen: boolean;
    isCoachesOpen: boolean;
    isAddOnsOpen: boolean;
    isStateOpen: boolean;
    isAllAddOnsSelected: boolean;
    isAllStateSelected: boolean;
    filters: IntakePatientsFilterModel;
}

interface ManageIntakePatientsComponentState extends ISortingState {
    isLoading: boolean;
    patients: PatientViewModel[];
    paymentPlansInitial: PaymentPlanFilterModel[];
    stateInitial: StateViewModel[],
    filtersState: IntakePatientsFiltersState;
}

export function useFacade(): [
    ManageIntakePatientsComponentState,
    (event?: React.MouseEvent<HTMLButtonElement>) => void,
    () => void,
    () => void,
    () => void,
    () => void,
    (query: string) => void,
    (value: string) => void,
    (source: string, direction: SortingDirection) => void,
    (periodId: number, toSelect: boolean) => void,
    () => void,
    (name: string, toSelect: boolean) => void,
    (name: string, toSelect: boolean) => void,
    (value: string) => void,
    () => void,
    () => void,
] {
    const [state, setState] = useState({
        isLoading: true,
        patients: [],
        sortingColumns: authQuery.isLicensingPractice() ? [
            { title: 'Patient Name', source: SortingSource.patientName, direction: SortingDirection.Asc },
            { title: 'Start Date', source: SortingSource.joinedPractice, direction: SortingDirection.Desc },
            { title: 'Providers', source: SortingSource.providers, direction: SortingDirection.Asc },
            { title: 'Health Coaches', source: SortingSource.coaches, direction: SortingDirection.Asc },
            { title: 'Add-Ons' },
            { title: 'State' },
            { title: 'Pod', source: SortingSource.location, direction: SortingDirection.Asc },
        ]
            : [
                { title: 'Patient Name', source: SortingSource.patientName, direction: SortingDirection.Asc },
                { title: 'Start Date', source: SortingSource.joinedPractice, direction: SortingDirection.Desc },
                { title: 'Plan', source: SortingSource.plan, direction: SortingDirection.Asc },
                { title: 'Providers', source: SortingSource.providers, direction: SortingDirection.Asc },
                { title: 'Health Coaches', source: SortingSource.coaches, direction: SortingDirection.Asc },
                { title: 'Add-Ons' },
                { title: 'Discount'},
                { title: 'State'},
                { title: 'Pod', source: SortingSource.location, direction: SortingDirection.Asc },
            ],
        sortingSource: SortingSource.none,
        stateInitial: stateList,
        paymentPlansInitial: [],
        filtersState: {
            isOpen: false,
            anchorEl: null,
            isPodsOpen: false,
            isProvidersOpen: false,
            isCoachesOpen: false,
            isPlansOpen: false,
            isAddOnsOpen: false,
            isStateOpen: false,
            isAllStateSelected: false,
            isAllAddOnsSelected: false,
            filters: {
                paymentPlans: [],
                searchQuery: '',
                addOns: addOnsList,
                state: stateList,
            },
        },
    } as ManageIntakePatientsComponentState);

    const handleSelectAllState = () => {
        state.filtersState.filters.state.forEach(p => p.isSelected = !state.filtersState.isAllStateSelected);
        state.filtersState.isAllStateSelected = !state.filtersState.isAllStateSelected
        setState(state => ({ ...state }));
    }

    const handleSelectAllAddOns = () => {
        state.filtersState.filters.addOns.forEach(p => p.isSelected = !state.filtersState.isAllAddOnsSelected);
        state.filtersState.isAllAddOnsSelected = !state.filtersState.isAllAddOnsSelected
        setState(state => ({ ...state }));
    }

    const handleSearchState = (value: string) => {
        state.filtersState.filters.state = state.stateInitial.filter(el => el.name.toLocaleLowerCase().includes(value.toLocaleLowerCase()))
        state.filtersState.isAllStateSelected = !state.filtersState.filters.state.some(p => p.isSelected === false);
        setState(state => ({ ...state }));
    }

    const handleSearchPlan = (value: string) => {
        state.filtersState.filters.paymentPlans = state.paymentPlansInitial.filter(el => el.name.toLocaleLowerCase().includes(value.toLocaleLowerCase()))
        setState(state => ({ ...state }));
    }

    const setDirection = (source: string, direction: SortingDirection) => {
        let itemIndex = state.sortingColumns.findIndex(item => item.source === source);
        state.sortingColumns[itemIndex].direction = direction;
        setState(state => ({
            ...state, columns:
                state.sortingColumns
        }));
    }

    const handleClearFilters = () => {
        state.filtersState.filters.paymentPlans.forEach(p => p.isSelected = false);
        state.filtersState.filters.state.forEach(p => p.isSelected = false);
        state.filtersState.filters.addOns.forEach(p => p.isSelected = false);
        state.filtersState.isAllStateSelected = false;
        state.filtersState.isAllAddOnsSelected = false;
        setState(state => ({ ...state }));
    }

    const handleToggleFilters = (event?: React.MouseEvent<HTMLButtonElement>) => {
        const anchorEl = event && event.currentTarget ? event.currentTarget : null;
        state.filtersState.isOpen = !state.filtersState.isOpen;
        state.filtersState.anchorEl = anchorEl;
        setState(state => ({ ...state }));
    }

    const handleTogglePlans = () => {
        state.filtersState.isPlansOpen = !state.filtersState.isPlansOpen;
        setState(state => ({ ...state }));
    }

    const handleToggleAddOns = () => {
        state.filtersState.isAddOnsOpen = !state.filtersState.isAddOnsOpen;
        setState(state => ({ ...state }));
    }

    const handleToggleState = () => {
        state.filtersState.isStateOpen = !state.filtersState.isStateOpen;
        setState(state => ({ ...state }));
    }

    const handleSorting = (source: string, direction: SortingDirection) => {
        if (state.sortingSource === source) {
            direction = direction === SortingDirection.Asc
                ? SortingDirection.Desc
                : SortingDirection.Asc;

            setDirection(source, direction);
        }

        setState(state => ({
            ...state,
            sortingSource: source,
            patients: state.patients.sort((p1, p2) => handleCompare(p1, p2, direction, source))
        }));
    }

    const handleSearch = (query: string) => {
        setState(state => ({
            ...state,
            isLoading: true
        }));

        const filters = state.filtersState.filters;

        patientsService.getNewPatients(
            PatientOnBoardingStatus.New,
            query ?? '',
            filters.paymentPlans.filter(x => x.isSelected).map(x => x.periodId),
            filters.addOns.filter(x => x.isSelected).map(x => x.type),
            filters.state.filter(x => x.isSelected).map(x => x.name),
        )
            .subscribe(() => {
                setState(state => ({
                    ...state,
                    isLoading: false
                }));
            });
    }

    const handleApplyFilters = () => {
        setState(state => ({ ...state, isLoading: true, selectedPage: 1 }));

        const filters = state.filtersState.filters;

        patientsService.getNewPatients(
            PatientOnBoardingStatus.New,
            filters.searchQuery,
            filters.paymentPlans.filter(x => x.isSelected).map(x => x.periodId),
            filters.addOns.filter(x => x.isSelected).map(x => x.type),
            filters.state.filter(x => x.isSelected).map(x => x.name),
        ).subscribe(() => setState(state => ({ ...state, isLoading: false })));
    }

    const handleOnAddOnsChanges = (name: string, toSelect: boolean) => {
        const item = state.filtersState.filters.addOns.find(p => p.name === name);

        if (item) {
            item.isSelected = toSelect;
        }

        state.filtersState.isAllAddOnsSelected = !state.filtersState.filters.addOns.some(p => p.isSelected === false);
        setState(state => ({ ...state }));
    }

    const handleOnPaymentChanges = (periodId: number, toSelect: boolean) => {
        const item = state.filtersState.filters.paymentPlans.find(p => p.periodId === periodId);

        if (item) {
            item.isSelected = toSelect;
            setState(state => ({ ...state }));
        }
    }

    const handleOnStateChanges = (name: string, toSelect: boolean) => {
        const item = state.filtersState.filters.state.find(p => p.name === name);

        if (item) {
            item.isSelected = toSelect;
        }
        state.filtersState.isAllStateSelected = !state.filtersState.filters.state.some(p => p.isSelected === false);
        setState(state => ({ ...state }));
    }

    const setPlans = (plans: PaymentPlanModel[]) => {
        state.filtersState.filters.paymentPlans = [];
        let paymentPlansInitial = []
        plans.forEach(plan => {
            if (plan.periods) {
                plan.periods.forEach(period => {
                    paymentPlansInitial.push({
                        id: plan.id,
                        periodId: period.id,
                        name: getPaymentPlanFullName(plan, period),
                        isSelected: false
                    });
                    state.filtersState.filters.paymentPlans.push({
                        id: plan.id,
                        periodId: period.id,
                        name: getPaymentPlanFullName(plan, period),
                        isSelected: false
                    });

                });
            }
        })

        setState(state => ({ ...state, paymentPlansInitial: paymentPlansInitial }));
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            paymentPlansService.get(authQuery.getCurrentPracticeId()).subscribe(plans => setPlans(plans)),
            onEmit<PatientShortModel[]>(patientsQuery.patients$, patients => {

                setState(state => ({
                    ...state,
                    patients: patients.map(p => {
                        return {
                            id: p.id,
                            lastName: p.lastName,
                            name: `${p.firstName} ${p.lastName}`,
                            plan: p.subscription?.displayName,
                            joinedPractice: new Date(p.createdAt).getTime(),
                            providers: p.employees.filter(x => x?.type === EmployeeType.Provider).map(x => {
                                return {
                                    firstName: x.firstName,
                                    lastName: x.lastName
                                }
                            }),
                            coaches: p.employees.filter(x => x?.type === EmployeeType.HealthCoach).map(x => {
                                return {
                                    firstName: x.firstName,
                                    lastName: x.lastName
                                }
                            }),
                            orderTypes: p.orderTypes.map(x => orderTypeNames.get(x)),
                            location: p.location,
                            shippingAddress: p.shippingAddress,
                            paymentPrice: p.subscription?.paymentPrice
                        }
                    })
                }))
            }),
        ];

        patientsService.getNewPatients(
            PatientOnBoardingStatus.New,
            '',
            [],
            [],
            [],
        )
            .subscribe(() => {
                setState(state => ({
                    ...state,
                    isLoading: false
                }));
            });

        return (() => {
            subscriptions.map(it => it.unsubscribe())
        })
    }

    useEffect(useEffectCB, []);

    return [
        state,
        handleToggleFilters,
        handleToggleAddOns,
        handleToggleState,
        handleTogglePlans,
        handleApplyFilters,
        handleSearch,
        handleSearchState,
        handleSorting,
        handleOnPaymentChanges,
        handleClearFilters,
        handleOnStateChanges,
        handleOnAddOnsChanges,
        handleSearchPlan,
        handleSelectAllState,
        handleSelectAllAddOns,
    ];
}