import {Subscription} from 'rxjs';
import {useEffect, useState} from 'react';
import {PageSliderNavigationContext} from "../../../common/models/navigation.models";
import {
    QuestionnaireModel,
    QuestionnairePages,
    QuestionnaireResultModel,
    QuestionnaireType
} from "../../models/questionnaire.models";
import {navigationService} from "../../../../services/navigation.service";
import {useHistory} from "react-router";
import {onEmit} from "../../../common/helpers/on-emit";
import {questionnaireQuery} from "../../stores/questionnaireStore";
import {questionnaireService} from "../../services/questionnaire.service";

/**
 * Represents Health Questionnaire Page state
 */
interface IntakeSpecialistQuestionnaireResultPageState {
    isLoading: boolean;
    currentPage: QuestionnairePages;
    result: QuestionnaireResultModel;
    questionnaire: QuestionnaireModel;
}

export function useFacade(patientId: number): [IntakeSpecialistQuestionnaireResultPageState, Function, Function] {
    const history = useHistory();
    const [state, setState] = useState({
        isLoading: true,
        currentPage: QuestionnairePages.General,
        questionnaire: null,
        result: null
    } as IntakeSpecialistQuestionnaireResultPageState);

    /**
     * Navigates to specified page
     * @param page
     */
    const goTo = (page: QuestionnairePages) => {
        setState({...state, currentPage: page});
        navigationService.goUp();
    }

    const goToSubmitPatientPage = () => {
        navigationService.toSubmitPatient(history, patientId)
    }

    /**
     * Returns if can go back
     */
    const canGoBack = (): boolean => {
        return state.currentPage !== QuestionnairePages.General
    };

    /**
     * Goes back
     */
    const goBack = (): void => {
        switch (state.currentPage) {
            case QuestionnairePages.Medical:
                return goTo(QuestionnairePages.General);
            case QuestionnairePages.Nutrition:
                return goTo(QuestionnairePages.Medical)
            case QuestionnairePages.Exercise:
                return goTo(QuestionnairePages.Nutrition)
            case QuestionnairePages.CardioRespiratory:
                return goTo(QuestionnairePages.Exercise)
            case QuestionnairePages.Sleep:
                return goTo(QuestionnairePages.CardioRespiratory)
            case QuestionnairePages.Stress:
                return goTo(QuestionnairePages.Sleep)
        }
    };

    /**
     * Returns if can go next
     */
    const canGoNext = (): boolean => {
        return true;
    };

    /**
     * Goes next
     */
    const goNext = (): void => {
        switch (state.currentPage) {
            case QuestionnairePages.General:
                return goTo(QuestionnairePages.Medical);
            case QuestionnairePages.Medical:
                return goTo(QuestionnairePages.Nutrition);
            case QuestionnairePages.Nutrition:
                return goTo(QuestionnairePages.Exercise);
            case QuestionnairePages.Exercise:
                return goTo(QuestionnairePages.CardioRespiratory);
            case QuestionnairePages.CardioRespiratory:
                return goTo(QuestionnairePages.Sleep);
            case QuestionnairePages.Sleep:
                return goTo(QuestionnairePages.Stress);
            case QuestionnairePages.Stress:
                return goToSubmitPatientPage();
            default:
                return;
        }
    };

    /**
     * Returns navigation context
     */
    const getNavigationContext = (): PageSliderNavigationContext => {
        return {
            nextButton: state.currentPage !== QuestionnairePages.Stress ? "Next" : "Proceed to Create Patient",
            backButton: "Back",
            canGoNext: canGoNext,
            canGoBack: canGoBack,
            goNext: goNext,
            goBack: goBack
        } as PageSliderNavigationContext
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<QuestionnaireResultModel[]>(questionnaireQuery.questionnaireResults$, results => {
                if (results) {
                    const initialResult = results.find(x => x.questionnaire.type === QuestionnaireType.Initial);
                    const isSubmitted = !!initialResult?.submittedAt;
                    setState(state => ({
                        ...state,
                        result: isSubmitted ? initialResult : null,
                        questionnaire: isSubmitted ? initialResult.questionnaire : null
                    }));
                }
            }),
        ];

        questionnaireService.getResults(patientId).subscribe(() => {
            setState((state) => ({
                ...state,
                isLoading: false
            }))
        });

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [patientId]);

    return [state, getNavigationContext, goToSubmitPatientPage]
}