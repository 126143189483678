import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { onEmit } from "../../../common/helpers/on-emit";
import { SortingDirection } from "../../../common/sorting/models/sorting-destination";
import { ISortingState } from "../../../common/sorting/models/sorting-state";
import { pageSizes } from "../../../common/pagination/models/page-sizes";
import { IPaginationState } from "../../../common/pagination/models/pagination-state";
import { handleCompare } from "../../../common/sorting/helpers/handle-compare";
import { getAvailablePages } from "../../../common/pagination/helpers/get-evailable-pages";
import { BannerModel } from "../../../../models/banner.model";
import { bannersService } from "../../services/banners.service";
import { bannersQuery } from "../../stores/bannersStore";
import { confirmService } from "../../../../services/confirm.service";

export enum SortingSource {
    None,
    Priority = "priority",
    Type = "type",
    Status = "isActive"
}

interface ManageBannersState extends IPaginationState, ISortingState {
    isLoading: boolean;
    banners: BannerModel[];
    originalBanners: BannerModel[];
    searchKey: string;
    isEditOpen: boolean;
    menuAnchorEl: HTMLButtonElement | null;
    selectedBanner: BannerModel | null;
}

let stateContext: ManageBannersState;

export function useFacade(): [
    ManageBannersState,
    (query: string) => void,
    () => number[],
    (value: any) => void,
    (page: number) => void,
    (source: string, direction: SortingDirection) => void,
    (bannerId: number, menuAnchorEl: HTMLButtonElement) => void,
    (status: boolean) => void,
    () => void
] {
    const [state, setState] = useState({
        isLoading: true,
        banners: [],
        originalBanners: [],
        searchKey: '',
        isEditOpen: false,
        menuAnchorEl: null,
        selectedBanner: null,
        sortingColumns: [
            {title: 'Banner Name'},
            {title: 'Description'},
            {title: 'Type', direction: SortingDirection.Asc, source: SortingSource.Type},
            {title: 'Priority', direction: SortingDirection.Asc, source: SortingSource.Priority},
            {title: 'Status', direction: SortingDirection.Asc, source: SortingSource.Status}
        ],
        sortingSource: SortingSource.None,
        totalCount: 0,
        selectedPage: 1,
        pageSize: pageSizes[0]
    } as ManageBannersState);

    stateContext = state;

    const handleOnFilter = (search: string) => {
        setState(state => ({...state, searchKey: search}));
    }

    const handleSearchSubmit = (query: string) => {
        const searchQuery = query ?? '';

        const trimedSearch = decodeURIComponent(searchQuery).trim().toLowerCase();
        const arrayTrimSearchKey = trimedSearch.split(' ');

        const filteredBanners = state.originalBanners?.filter(
        (e) =>
            arrayTrimSearchKey.find((item) =>
                e.name?.toLowerCase().includes(item)
            ) !== undefined ||
            arrayTrimSearchKey.find((item) =>
                e.copy?.toLowerCase().includes(item)
            ) !== undefined
        );
      
        setState(state => ({
            ...state,
            isLoading: false,
            selectedPage: 1,
            banners: searchQuery ? filteredBanners : state.originalBanners,
            totalCount:  searchQuery ? filteredBanners.length : state.originalBanners.length,
        }));
    }

    const setDirection = (source: string, direction: SortingDirection) => {
        const itemIndex = state.sortingColumns.findIndex(item => item.source === source);
        state.sortingColumns[itemIndex].direction = direction;
        setState(state => ({ ...state, columns: state.sortingColumns }));
    }

    const handleSorting = (source: string, direction: SortingDirection) => {
        if (state.sortingSource === source) {
            direction = direction === SortingDirection.Asc
                ? SortingDirection.Desc
                : SortingDirection.Asc;

            setDirection(source, direction);
        }
        setState(state => ({
            ...state,
            sortingSource: source,
            banners: state.banners.sort((p1, p2) => handleCompare(p1, p2, direction, source))
        }));
    }

    const getAllAvailablePages = () => {
        return getAvailablePages(state);
    }

    const handlePageSizeChange = (value: any) => {
        if (value === state.pageSize) {
            return;
        }

        setState(state => ({
            ...state,
            pageSize: value,
            selectedPage: 1
        }));
    }

    const handlePageChange = (page: number) => {
        if (page === state.selectedPage) {
            return;
        }

        setState(state => ({
            ...state,
            selectedPage: page
        }));
    }

    const handleToggleBannerActions = (bannerId: number, menuAnchorEl: HTMLButtonElement) => {
        const banner = state.banners.find(f => f.id === bannerId);
        setState(state => ({
            ...state,
            menuAnchorEl,
            selectedBanner: banner,
        }));
    }
  
    const handleToggleEditBanner = (status: boolean) => {
        setState(state => ({
            ...state,
            isEditOpen: status,
            menuAnchorEl: null
        }))
    }
  
    const handleDeleteBanner = () => {
        setState(state => ({
            ...state,
            menuAnchorEl: null
        }));
  
        confirmService.confirm(
            'Delete Banner',
            'Are you sure you want to delete this banner?',
            'Yes',
            'Cancel',
            'danger')
            .subscribe(() => {
                bannersService.deleteBanner(state.selectedBanner.id).subscribe();
            });
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<BannerModel[]>(bannersQuery.banners$, banners => {
                setState(state => ({
                    ...state,
                    banners: banners,
                    originalBanners: banners,
                    totalCount: banners.length
                }));
            }),
        ];

        const cb = () => setState(state => ({ ...state, isLoading: false }));

        bannersService.getAllBanners().subscribe(cb, cb);

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, []);

    useEffect(() => {
      const delayDebounceFn = setTimeout(() => {
        if (!stateContext.isLoading) {
          handleSearchSubmit(stateContext.searchKey);
        }
      }, 1000);

      return () => clearTimeout(delayDebounceFn)
    }, [stateContext.searchKey]);

    return [
        state,
        handleOnFilter,
        getAllAvailablePages,
        handlePageSizeChange,
        handlePageChange,
        handleSorting,
        handleToggleBannerActions,
        handleToggleEditBanner,
        handleDeleteBanner
    ];
}