import { useState, useEffect } from "react";
import { IErrorState } from "../../../common/validation/error-state";
import { addNewLabPricingComponentValidator } from "../addNewLabPricingComponent/addNewLabPricingComponent.validator";
import { AddOnProvider, UpdateAddOnModel } from "../../../addons/models/addOns.models";
import { OrderType } from "../../models/orders.models";
import { pricingService } from "../../services/pricing.service";
import { addOnsService } from "../../../addons/services/addOns.service";

interface UpdateLabPricingComponentState extends IErrorState {
  isLoading: boolean;
  isOpen: boolean;
  addOn: UpdateAddOnModel;
}

const initModel: UpdateAddOnModel = {
  id: 0,
  alternativeOffer: null,
  integrationId: '',
  name: '',
  description: '',
  instructions: '',
  price: 0,
  orderType: OrderType.Lab,
  provider: AddOnProvider.ImageMark,
  alternativeAddon: null
};


export function useFacade(handleGoBack: Function): [
  UpdateLabPricingComponentState,
  (field: string, value: any) => void,
  () => void,
  () => void,
  () => void
] {
  const [state, setState] = useState({
    isLoading: false,
    isOpen: false,
    addOn: Object.assign({}, initModel),
    errors: {}
  } as UpdateLabPricingComponentState);

  const handleChanges = (field: string, value: any) => {
    addNewLabPricingComponentValidator.validateAndSetState(state, setState, field, value);

    const addOn = state.addOn;

    addOn[field] = value;

    setState({
      ...state,
      addOn: addOn
    });
  }

  const handleUpdate = () => {
    addNewLabPricingComponentValidator.validateObjectAndSetState(state, setState, state.addOn);
    if (!addNewLabPricingComponentValidator.stateIsValid(state)) {
      return;
    }
    setState(state => ({
      ...state,
      isLoading: true,
    }));
    addOnsService.updateAddOn(state.addOn).subscribe(() => {
      setState(state => ({
        ...state,
        errors: {},
        isOpen: false,
        isLoading: false,
        addOn: Object.assign({}, initModel)
      }));
    },
    () => {
      setState(state => ({
        ...state,
        isLoading: false,
      }));
    })
  }

  const handleClose = () => {
    setState(state => ({
      ...state,
      errors: {},
      isOpen: false,
      addOn: Object.assign({}, initModel)
    }));
  }

  const handleBack = () => {
    handleGoBack(state.addOn.provider);
    handleClose();
  }

  useEffect(() => {
    const subscription = pricingService.onUpdate.subscribe((model) => {
      const [
        addOn,
      ] = model.data;

      setState(state => ({
        ...state,
        isOpen: true,
        addOn
      }));
    });

    return () => subscription.unsubscribe();
  }, [])

  return [
      state,
      handleChanges,
      handleUpdate,
      handleClose,
      handleBack
  ];
}