import { NotesState, NotesStore, notesStore } from './notes.store';
import { Query } from '@datorama/akita';
import {GoalModel, NoteModel, MdmCommonModel, GoalCommonModel, GoalCategory, SupplementItem, SpecialTestModel} from "../../models/notes.models";

/**
 * Provides method for working with notes store
 */
export class NotesQuery extends Query<NotesState> {
    currentGoals$ = this.select(state => state.currentGoals);
    pastGoals$ = this.select(state => state.pastGoals);
    pastGoalsTotal$ = this.select(state => state.pastGoalsTotal);
    goalsTab$ =  this.select(state => state.goalsTab);
    notes$ = this.select(state => state.notes);
    draftNotes$ = this.select(state=>state.draftNotes);
    totalCount$ = this.select(state=>state.totalCount);
    notesGroups$ = this.select(state => state.notesGroups);
    targetContent$ = this.select(state => state.targetContent);
    notificationAwaitingApproval$ = this.select(state => state.notificationAwaitingApproval);
    commonGoals$ = this.select(state => state.commonGoals);
    targetCommonGoal$ = this.select(state => state.targetCommonGoal);
    commonMdms$ = this.select(state => state.commonMdms);
    targetCommonMdm$ = this.select(state => state.targetCommonMdm);
    commonSupplements$ = this.select(state => state.commonSupplements);
    commonOrders$ = this.select(state => state.commonOrders);

    constructor(protected store: NotesStore) {
        super(store);
    }

    public getNote(noteId: number): NoteModel {
        return this.getValue().notes.find(x => x.id === noteId);
    }
    public getGoals(): GoalModel[] {
        return this.getValue().currentGoals
    }
    public getCommonMdms(): MdmCommonModel[] {
        return this.getValue().commonMdms
    }
    public getCommonGoals(category: GoalCategory): GoalCommonModel[] {
        return this.getValue().commonGoals.filter(x => x.category === category)
    }
    public getCommonSupplements(): SupplementItem[] {
        return this.getValue().commonSupplements
    }
    public getCommonOrders(): SpecialTestModel[] {
        return this.getValue().commonOrders
    }
}

export const notesQuery = new NotesQuery(notesStore);
