import {Box, Dialog, DialogActions, DialogContent, DialogContentText, useMediaQuery, useTheme} from '@material-ui/core';
import clsx from 'clsx';
import React, {useState, useEffect} from 'react';
import {DialogTitle} from '../../../common/components/dialogTitle/DialogTitle';
import {WildHealthButton} from '../../../common/components/wildHealthButton/WildHealthButton';
import commonUseStyles from '../../../common/styles/common.styles';
import {useStyles} from "./ConfirmDialog.styles";

export interface ConfirmAlternativeAddOnDialogState {
    isOpen: boolean;
}

export interface ConfirmAlternativeAddOnDialogProps {
    isChecked: boolean;
    popUpData: { text: string; alternativeAddOnId: number };
    onConfirm: (id: number) => void;
}

export const ConfirmAlternativeAddOnDialog: React.FC<ConfirmAlternativeAddOnDialogProps> = (props: ConfirmAlternativeAddOnDialogProps) => {
    const {
        isChecked,
        popUpData,
        onConfirm,
    } = props;
    const [state, setState] = useState({
        isOpen: false,
    } as ConfirmAlternativeAddOnDialogState);


    const classes = useStyles();
    const commonClasses = commonUseStyles();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));


    const handleAnswer = (result: boolean) => {
        setState({...state, isOpen: result});
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleAnswer(true);
        }
    }

    const confirmAlternativeAddOn = (id: number) => {
        onConfirm(id)
        handleAnswer(false);
    }

    useEffect(() => {
        handleAnswer(isChecked)
    }, [isChecked]);

    return (
        <Box onKeyPress={(e) => handleKeyPress(e)}>
            <Dialog
                fullWidth
                fullScreen={fullScreen}
                open={state.isOpen}
                onClose={() => handleAnswer(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    id="dialog-title"
                    onClose={() => handleAnswer(false)}
                    classes={{root: classes.dialogTitle}}
                >
                    <span className={clsx(commonClasses.size24, commonClasses.textMedium)}>Get a head start on your health!</span>
                </DialogTitle>

                <DialogContent classes={{root: classes.dialogContent}}>
                    <DialogContentText>
                        <span className={commonClasses.colorBlack} dangerouslySetInnerHTML={{__html: popUpData.text}}/>
                    </DialogContentText>
                </DialogContent>

                <DialogActions classes={{root: classes.dialogActions}}>
                    <WildHealthButton id="confirm-dialog-cancel" color='secondary' onClick={() => handleAnswer(false)}>
                        No, thanks
                    </WildHealthButton>
                    <WildHealthButton id="confirm-dialog-get-insights" onClick={() => confirmAlternativeAddOn(props.popUpData.alternativeAddOnId)}>
                        Get personalized insights
                    </WildHealthButton>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
