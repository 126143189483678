import React from 'react';
import { Box, Step, StepLabel, Stepper } from '@material-ui/core';
import { FellowshipStepConnector, FellowshipStepIcon } from "./fellowshipStepper";
import { useStyles } from "./FellowshipGetStartedPage.styles";
import { useFacade } from "./fellowshipGetStartedPage.hooks";
import { logoService } from '../../../../services/logo.service';
import clsx from 'clsx';
import commonUseStyles from '../../../common/styles/common.styles';

export const FellowshipGetStartedPage: React.FC = (props: any) => {
    const [
        {
            steps,
            currentStep,
        },
        StepsContent
    ] = useFacade();

    const classes = useStyles();
    const commonClasses = commonUseStyles();

    return (
        <div className={classes.root}>
            <Box className={classes.sidebar}>
                <Box ml="70px" mt="20px">
                    <Box>
                        <img className={classes.logo}
                            src={logoService.getLogo().primary} alt="img" />
                    </Box>
                    <Box mt={25}>
                        <Stepper
                            activeStep={steps.indexOf(currentStep)}
                            connector={<FellowshipStepConnector />}
                            orientation="vertical"
                            classes={{
                                root: classes.stepper
                            }}
                        >
                            {
                                steps.map((step, index) => (
                                    <Step key={index}>
                                        <StepLabel StepIconComponent={FellowshipStepIcon}>
                                            {step.label}
                                        </StepLabel>
                                    </Step>
                                ))
                            }
                        </Stepper>
                    </Box>
                </Box>
            </Box>
            <Box className={classes.content} py="80px">
                <Box width={1} textAlign="center">
                    <span className={clsx(commonClasses.size30, commonClasses.textSemiBold, commonClasses.colorBlack)}>Wild Health Fellowship Registration</span>
                </Box>
                {
                    StepsContent.get(currentStep.key)
                }
            </Box>
        </div>
    );
}

