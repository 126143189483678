import {useEffect} from "react";
import {useHistory} from "react-router";
import {Subscription} from "recompose";
import {interval} from 'rxjs';
import {navigationService} from "../../../../services/navigation.service";
import {authService} from "../../../auth/services/auth.service";
import {authQuery} from "../../../auth/stores/auth";
import * as Sentry from "@sentry/browser";
import {Severity} from "@sentry/browser";
import {RefreshTokenModel} from "../../../auth/models/auth.models";

export function useFacade(): void {


    //In develop and production, we have the following:
    //  the auth token expires 8 hours after it’s issued
    //  the refresh token expires 43200 minutes, or 30 days, after it is issued.
    //  the UI checks every `periodMilliseconds` if the auth token is expired yet, and if so, it immediately goes for a refresh.
    //  When the token is refreshed, the auth store updates the auth model using the response from the RefreshToken endpoint.
    //
    //  The RefreshToken endpoint uses authService.Authenticate, which issues an entire new token,
    //  including a new refresh token from scratch, with expiration dates according to the configuration described above.
    //
    //
    //A situation occurs where xhr requests to refresh are queued, but not yet processed in the backend, and they are all
    //suddenly submitted at once.  A refresh occurs, but subsequent 'failures' to refresh a token that has already been redeemed
    //results in the UI logging the user out, and this is why Clarity has been logging users out.
    //
    //There is no advantage to checking so often because the refresh token is good for an entire month, so let's increase the interval
    //from 10 seconds to 1 hour.

    // 1000ms * 60 = 60000 ms = 60 seconds
    // 60 seconds * 60 mins = 3600 seconds = 1 hour
    //const oneHour = 1000 * 60 * 60;

    const thirtySeconds = 1000 * 30;
    const sourceTimer = interval(thirtySeconds);

    const history = useHistory();

    //In the api, the token is (currently) configured to expire 8 hours after it is issued.
    //This four-hour window lets the user run with the token for "tryRefreshWithinHoursOfExpiration"
    //hours before the UI will try to refresh it.
    //This time buffer eliminates a race condition between a successful refresh and a 401-failed request.
    const tryRefreshWithinHoursOfExpiration = 4;

    const handleRefreshFailure = (refreshToken: RefreshTokenModel) =>
    {
        Sentry.addBreadcrumb({
            category: "auth",
            message: "The refresh token could not be redeemed: " + refreshToken.refreshToken,
            level: Severity.Error
        })
        if(authQuery.isLoggedIn() && !authQuery.tokenExpiresWithin(tryRefreshWithinHoursOfExpiration))
        {
            //The token is still valid, but the refresh failed...
            //This situation should never occur.
            const id = authQuery.getId()
            const message = `Multiple refresh requests for token with id ${id} occurred.  The user is still logged in.`;
            Sentry.captureMessage(message, Severity.Error)
        }
        else
        {
            authService.logout();
            navigationService.toLogin(history);
        }
    }

    function doLogout()
    {
        Sentry.addBreadcrumb({
            category: "auth",
            message: "The refresh token is not valid",
            level: Severity.Error
        })
        authService.logout();
        navigationService.toLogin(history);
    }

    function tryRefresh(refreshToken: RefreshTokenModel) {
        Sentry.addBreadcrumb({
            category: "auth",
            message: "The refresh token is valid",
            level: Severity.Info
        })

        authService.refreshToken(refreshToken).subscribe(
            () => {
                Sentry.addBreadcrumb({
                    category: "auth",
                    message: "The refresh token was redeemed:" + refreshToken.refreshToken,
                    level: Severity.Info
                })
            },
            () => {
                handleRefreshFailure(refreshToken)
            })
    }

    const checkIsLoggedIn = () => {
        //if the token is missing, or it's expired, or it's going to expire
        //in the next "tryRefreshWithinHoursOfExpiration" hours, then try to do a refresh.
        console.log("checking token validity...")
        if (!authQuery.isAuthTokenValid() || authQuery.tokenExpiresWithin(tryRefreshWithinHoursOfExpiration))
        {
            Sentry.addBreadcrumb({
                category: "auth",
                message: "The auth token is expired, doesn't exist, or it expires soon.",
                level: Severity.Info
            })

            const refreshToken = authQuery.getRefreshToken();

            //If the refresh token looks good, then use it.
            //If it's no good (or the refresh fails), then the user is out of luck.
            if (authQuery.isRefreshTokenValid())
            {
                tryRefresh(refreshToken);
            }
            else
            {
                doLogout();
            }
        }
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [sourceTimer.subscribe(checkIsLoggedIn)];

        return () => {
           subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, []);
}
