import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from "../../constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        icon: {
            display: 'block'
        },
        up: {
            paddingBottom: 2
        },
        down: {
            paddingTop: 2
        },
        active: {
            fill: colors.main,
        },
        disabled: {
            fill: colors.gray2,
        },
        pointer: {
            cursor: 'pointer'
        }
    })
);
