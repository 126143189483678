import { Switch, createStyles, makeStyles, withStyles, Theme } from "@material-ui/core";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      '& .MuiDialog-paper': {
        borderRadius: 20,
      },
      [theme.breakpoints.down("xs")]: {
        '& .MuiDialog-paper': {
          borderRadius: '28px 28px 0 0',
          margin: 0,
          marginTop: 'auto',
          width: '100%'
        },
      },
    },
    dialogContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: 'center',
      maxWidth: 415,
      paddingTop: 30,
      [theme.breakpoints.down("xs")]: {
        marginRight: 0,
        marginLeft: 0,
        maxWidth: '100%',
      },
    },
  })
);

export const ShowUntestedSwitcher = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 60,
      height: 30,
      padding: 0,
    },
    switchBase: {
      padding: 2,
      color: colors.mint500,
      '&$checked': {
        transform: 'translateX(30px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: colors.mint500,
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 26,
      height: 26,
    },
    track: {
      borderRadius: 36 / 2,
      border: `1px solid ${colors.mint500}`,
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.0)',
      backgroundColor: '#fff',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }),
)(Switch);
