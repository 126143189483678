import React from 'react';
import { useHistory } from 'react-router';
import { Box, Collapse, Divider, Link, Grid, Tooltip, TableContainer, Table, TableHead, TableBody } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { ReactComponent as ExportIcon } from '@img/icons/Export.svg';
import { ReactComponent as TopGoal } from '@img/icons/TopGoal.svg';
import emptyResult from "@img/placeholders/EmptyReport.png";
import warning from "@img/icons/warning.svg";
import moment from "moment";
import clsx from 'clsx';
import { useStyles } from './visitPrepComponent.styles';
import { useFacade, SectionEnum } from './visitPrepComponent.hooks';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { WildHealthPlaceHolderBase } from '../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolderBase';
import { ChronologicalSummaryComponent } from '../ChronologicalSummaryComponent/ChronologicalSummaryComponent';
import { navigationService } from '../../../../services/navigation.service';
import { WildHealthTabControl } from '../../../common/components/wildHealthTabControl/WildHealthTabControl';
import { TabItem } from '../../../common/components/wildHealthTabControl/wildHealthTabControl.hooks';
import { useStylesThreeWidthTabs } from "../../../common/components/wildHealthTabControl/wildHealthTabControl.styles";
import { GoalModel } from '../../../notes/models/notes.models';
import { colors } from '../../../common/constants/colors';
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { TextMarkdownComponent } from '../../../notes/components/textMarkdownComponent/TextMarkdownComponent';
import { toCurrentTimeZone } from '../../../timezones/helpers/timezone';
import { PatientMedicationModel } from '../../../patientSupplements/models/patientMedicationModel';
import { LabInputNotificationType, LabInputModel } from '../../../inputs/models/input.models';
import { LabInputRangeComponent } from '../../../inputs/components/LabInputRangeComponent/LabInputRangeComponent';
import { ChronologicalEventType } from '../../models/patient.model';
import { getDataSetByLabLatestValue } from '../../../inputs/helpers/labInputs.extensions';

export interface VisitPrepComponentProps {
    patientId: number | null;
}

const FilterByEventType = [
    ChronologicalEventType.LabsOrdered,
    ChronologicalEventType.NotePublished,
    ChronologicalEventType.SupplementUpdated,
    ChronologicalEventType.InternalMessageSent,
]

export const VisitPrepComponent: React.FC<VisitPrepComponentProps> = (props: VisitPrepComponentProps) => {
    const { patientId } = props;
    const history = useHistory();
    const [
        state,
        handleExpand,
        handleDownload,
        getDataSets,
        handleChangePlanTab
    ] = useFacade(patientId)

    const classes = useStyles()
    const initialClassName = useStylesThreeWidthTabs();

    const customTooltipClasses = {
        tooltip: classes.customWidth,
        arrow: classes.arrow,
        tooltipPlacementBottom: classes.tooltipPlacement,
        tooltipPlacementTop: classes.tooltipPlacement,
    };

    if (state.isLoading) {
        return (
            <WildHealthLinearProgress />
        );
    }

    if (!state.visitPrep || (!state.visitPrep.healthCoachPlan && !state.visitPrep.providerPlan && !state.visitPrep.mdm && !state.visitPrep.goals.length && !state.visitPrep.medications.length && !state.visitPrep.supplements.length && !state.visitPrep.newLabs.length && !state.visitPrep.recentDocuments.length)) {
        return <Box p={3} className={classes.root}>
            <Box flex={1} className={classes.emptyPage}>
                <WildHealthPlaceHolderBase message={`No information yet.`}>
                    <img style={{ width: 'inherit', height: "200px" }}
                    src={emptyResult}
                    alt="img" />
                </WildHealthPlaceHolderBase>
            </Box>
            <Box pb={4}>
                <ChronologicalSummaryComponent patientId={patientId} filterTypes={FilterByEventType} />
            </Box>
        </Box>
    }

    const renderEmptyValue = () => {
        return <Box>
            <span className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-gray500 wh-tw-font-beVietnamPro">No information yet.</span>
        </Box>
    }

    const displayDataValue = (value: Date) => {
        return value ? moment(toCurrentTimeZone(value)).format("MM/DD/yyyy") : 'N/A';
    }

    const renderLabValue = (input: LabInputModel, dataSetId: string): JSX.Element => {
        const inputValue = input.values.find(x => x.dataSetId === dataSetId);
        if (inputValue === undefined || !inputValue.isInitialized) {
            return (<Tooltip placement="top" arrow classes={customTooltipClasses} title="No Result">
                <span className="text-accent-secondary">-</span>
            </Tooltip>
            );
        }

        const notification = inputValue.notification;

        return (<Box display="flex" justifyContent="center">
            <Tooltip placement="top" arrow classes={customTooltipClasses} title={notification?.notificationType === LabInputNotificationType.Error ? 'Out of Range' : 'In Range'}>
                <Box display="flex" alignItems="center" justifyContent="center" className={clsx(notification?.notificationType === LabInputNotificationType.Error && "wh-tw-w-fit wh-tw-rounded-full wh-tw-border wh-tw-border-error wh-tw-border-solid wh-tw-px-2 wh-tw-py-0.5")}>
                    <Box>
                        <span
                            className={clsx(notification?.notificationType === LabInputNotificationType.Error && "wh-tw-text-bad")}>{inputValue.value}</span>
                    </Box>
                    {
                        notification?.notificationType === LabInputNotificationType.Error &&
                        <Box ml={1}>
                            <img alt="img" src={warning} />
                        </Box>
                    }
                </Box>
            </Tooltip>
        </Box>);
    }

    const renderPlanSection = () => {
        const tabs: TabItem[] = [
            {
                title: 'Provider',
                content: <Box pt={2} px={1}>{state.visitPrep.providerPlan ? <TextMarkdownComponent content={state.visitPrep.providerPlan.text} /> : renderEmptyValue()}</Box>,
                keepMounted: true,
                minHeight: 32
            },
            {
                title: 'Health Coach',
                content: <Box pt={2} px={1}>{state.visitPrep.healthCoachPlan ? <TextMarkdownComponent content={state.visitPrep.healthCoachPlan.text} /> : renderEmptyValue()}</Box>,
                keepMounted: true,
                minHeight: 32
            }
        ];

        return <WildHealthTabControl items={tabs} className={initialClassName} initTab={state.selectedPlanTab} onChangeCB={handleChangePlanTab} />
    }

    const renderGoals = (goals: GoalModel[]) => {
        return (
            <Box>
                {
                    goals.map((goal, index) => {
                        return (
                            <Box p={2.5} mb={2} width={1} key={goal.id} position='relative' style={{
                                borderRadius: '12px',
                                border: `1px solid ${colors.primary}`,
                                background: `${colors.mint50}80`
                            }}>
                                {goal.isTopGoal && <Box position='absolute' width='34px' height='29px' top='-5px' left='74px'><TopGoal /></Box>}
                                <Grid container spacing={2}>
                                    <Grid item md={2} sm={12} xs={12}>
                                        <Box>
                                            <span className="wh-tw-text-sm wh-tw-text-gray500">
                                                Goal {index + 1}:
                                            </span>
                                        </Box>
                                    </Grid>
                                    <Grid item md={5} sm={12} xs={12}>
                                        <span className="wh-tw-text-sm">
                                            {
                                                goal.name
                                            }
                                        </span>
                                    </Grid>
                                    <Grid item md={5} sm={12} xs={12}>
                                        <Box display="flex" justifyContent="flex-end">
                                            {
                                                goal.isCompleted
                                                    ? <Box display="flex" alignItems="center">
                                                        <Box ml={1}>
                                                            <span className="wh-tw-text-sm wh-tw-font-semibold">🎉 Success Achieved</span>
                                                        </Box>
                                                    </Box>
                                                    : <Box display="flex" alignItems="center">
                                                        <Box >
                                                            <FiberManualRecordIcon style={{
                                                                color: colors.average1,
                                                                height: '8px',
                                                                width: '8px'
                                                            }} />
                                                        </Box>
                                                        <Box ml={1}>
                                                            <span className="wh-tw-text-sm wh-tw-font-semibold">Striving for Success</span>
                                                        </Box>
                                                    </Box>
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item md={2} sm={12} xs={12}>
                                        <Box>
                                            <span className="wh-tw-text-sm wh-tw-text-gray500">
                                                Est. Completion Date:
                                            </span>
                                        </Box>
                                    </Grid>
                                    <Grid item md={10} sm={12} xs={12}>
                                        <span className="wh-tw-text-sm">
                                            {
                                                goal.completionDate
                                                    ? moment(goal.completionDate).format('L')
                                                    : 'No completion date'
                                            }
                                        </span>
                                    </Grid>
                                    <Grid item md={2} sm={12} xs={12}>
                                        <Box>
                                            <span className="wh-tw-text-sm wh-tw-text-gray500">
                                                Interventions:
                                            </span>
                                        </Box>
                                    </Grid>
                                    <Grid item md={10} sm={12} xs={12}>
                                        <Box>
                                            {
                                                goal.interventions.map((intervention, index) => {
                                                    return (
                                                        <Box key={index}>
                                                            <TextMarkdownComponent content={intervention.name} />
                                                        </Box>
                                                    )
                                                })
                                            }
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        )
                    })
                }
            </Box>
        )
    }

    const renderGoalsSection = () => {
        const topGoals = state.visitPrep.goals.filter(x => x.isTopGoal);
        const regularGoals = state.visitPrep.goals.filter(x => !x.isTopGoal);
        const tabs: TabItem[] = [
            {
                title: 'Top Goals',
                content: <Box pt={2} px={1}>{topGoals && !!topGoals.length ? renderGoals(topGoals) : renderEmptyValue()}</Box>,
                keepMounted: true,
                minHeight: 32
            },
            {
                title: 'Regular Goals',
                content: <Box pt={2} px={1}>{regularGoals && !!regularGoals.length ? renderGoals(regularGoals) : renderEmptyValue()}</Box>,
                keepMounted: true,
                minHeight: 32
            }
        ];

        return <WildHealthTabControl items={tabs} className={initialClassName} />
    }

    const renderNewLabSection = () => {
        const highlightedLabs = state.visitPrep.newLabs.filter(x => x.isHighlighted);
        const anyLabs = highlightedLabs.map(x => x.values).flat().length > 0;

        const dataSets = state.visitPrep.newLabs && state.visitPrep.newLabs.length ? getDataSets(state.visitPrep.newLabs) : [];
        return (
            <Box pt={2} px={1}>
                {!!state.visitPrep.newLabs.length && anyLabs
                    ? <>
                        <Box display="flex" alignItems="center" mb={3}>
                            <Box className="wh-tw-text-sm">Collection Date: <span className="wh-tw-text-sm wh-tw-font-semibold">{moment(dataSets?.[0]?.date).format("MM/DD/YYYY")}</span></Box>
                            <Box ml={1}>
                                <Link
                                    id='go-to-patient-lab-result'
                                    className="wh-tw-text-primaryV"
                                    onClick={() => navigationService.toPatientLabResults(history, patientId)}
                                >
                                    <ExportIcon />
                                </Link>
                            </Box>
                        </Box>
                        {highlightedLabs.length > 0 && <>
                            <Box className="wh-tw-font-semibold" mb={1}>Highlights</Box>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <WildHealthTableRow>
                                            <WildHealthTableCell style={{ width: '35%' }}>
                                                <Box className="wh-tw-text-sm wh-tw-font-semibold wh-tw-text-black">
                                                    Biomarker
                                                </Box>
                                            </WildHealthTableCell>
                                            <WildHealthTableCell style={{ width: '25%' }}>
                                                <Box className="wh-tw-text-sm wh-tw-font-semibold wh-tw-text-black">
                                                    Range
                                                </Box>
                                            </WildHealthTableCell>
                                            <WildHealthTableCell style={{ width: '25%' }} align="center">
                                                <Box className="wh-tw-text-sm wh-tw-font-semibold wh-tw-text-black">
                                                    Recent Value
                                                </Box>
                                            </WildHealthTableCell>
                                            <WildHealthTableCell style={{ width: '15%' }}>
                                                <Box className="wh-tw-text-sm wh-tw-font-semibold wh-tw-text-black">
                                                    Date
                                                </Box>
                                            </WildHealthTableCell>
                                        </WildHealthTableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            highlightedLabs.map((input, index) => {
                                                const dataSetByLatestValue = getDataSetByLabLatestValue(dataSets, input);

                                                if (!dataSetByLatestValue) {
                                                    return <></>
                                                }

                                                const range = input.values[0]?.range;
                                                return (
                                                    <WildHealthTableRow key={index}>
                                                        <WildHealthTableCell>
                                                            <Box className="wh-tw-text-sm">
                                                                {
                                                                    input.displayName
                                                                }
                                                            </Box>
                                                        </WildHealthTableCell>
                                                        <WildHealthTableCell>
                                                            {range ? <LabInputRangeComponent range={range} /> : ''}
                                                        </WildHealthTableCell>
                                                        <WildHealthTableCell align="center">
                                                            {renderLabValue(input, dataSetByLatestValue.id)}
                                                        </WildHealthTableCell>
                                                        <WildHealthTableCell>
                                                            {
                                                                moment(dataSetByLatestValue.values[0].date).format("MM/DD/YYYY")
                                                            }
                                                        </WildHealthTableCell>
                                                    </WildHealthTableRow>
                                                );
                                            }
                                            )
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>}
                    </>
                    : renderEmptyValue()
                }
            </Box>
        )
    }

    const renderMedicationAndSupplements = (items: PatientMedicationModel[]) => {
        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <WildHealthTableRow>
                            <WildHealthTableCell style={{ width: '40%' }}>
                                <Box className="wh-tw-text-sm wh-tw-font-semibold wh-tw-text-black">
                                    Name
                                </Box>
                            </WildHealthTableCell>
                            <WildHealthTableCell style={{ width: '30%' }}>
                                <Box className="wh-tw-text-sm wh-tw-font-semibold wh-tw-text-black">
                                    Strength
                                </Box>
                            </WildHealthTableCell>
                            <WildHealthTableCell style={{ width: '30%' }}>
                                <Box className="wh-tw-text-sm wh-tw-font-semibold wh-tw-text-black">
                                    Dosing Instructions
                                </Box>
                            </WildHealthTableCell>
                        </WildHealthTableRow>
                    </TableHead>
                    <TableBody>
                        {
                            items.map((item, index) =>
                                <WildHealthTableRow key={index}>
                                    <WildHealthTableCell>
                                        <Box className="wh-tw-text-sm">
                                            {
                                                item.name
                                            }
                                        </Box>
                                    </WildHealthTableCell>
                                    <WildHealthTableCell>
                                        <Box className="wh-tw-text-sm" display="flex" flexWrap="wrap">
                                            {item.dosage}
                                        </Box>
                                    </WildHealthTableCell>
                                    <WildHealthTableCell>
                                        <Box className="wh-tw-text-sm" display="flex" flexWrap="wrap">
                                            {item.instructions}
                                        </Box>
                                    </WildHealthTableCell>
                                </WildHealthTableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const renderMedicationsAndSupplementsSection = () => {
        const tabs: TabItem[] = [
            {
                title: 'Medications',
                content: <Box pt={2} px={1}>{state.visitPrep.medications && !!state.visitPrep.medications.length ? renderMedicationAndSupplements(state.visitPrep.medications) : renderEmptyValue()}</Box>,
                keepMounted: true,
                minHeight: 32
            },
            {
                title: 'Supplements',
                content: <Box pt={2} px={1}>{state.visitPrep.supplements && !!state.visitPrep.supplements.length ? renderMedicationAndSupplements(state.visitPrep.supplements) : renderEmptyValue()}</Box>,
                keepMounted: true,
                minHeight: 32
            }
        ];

        return <WildHealthTabControl items={tabs} className={initialClassName} />
    }

    const renderRecentDocumentSection = () => {
        return (
            <Box pt={2} px={1}>
                {!!state.visitPrep.recentDocuments.length
                    ? <TableContainer>
                        <Table>
                            <TableHead>
                                <WildHealthTableRow>
                                    <WildHealthTableCell style={{ width: '40%' }}>
                                        <Box className="wh-tw-text-sm wh-tw-font-semibold wh-tw-text-black">
                                            File Name
                                        </Box>
                                    </WildHealthTableCell>
                                    <WildHealthTableCell style={{ width: '30%' }}>
                                        <Box className="wh-tw-text-sm wh-tw-font-semibold wh-tw-text-black">
                                            Uploaded By
                                        </Box>
                                    </WildHealthTableCell>
                                    <WildHealthTableCell style={{ width: '30%' }}>
                                        <Box className="wh-tw-text-sm wh-tw-font-semibold wh-tw-text-black">
                                            Date
                                        </Box>
                                    </WildHealthTableCell>
                                </WildHealthTableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    state.visitPrep.recentDocuments.map((item, index) =>
                                        <WildHealthTableRow key={index}>
                                            <WildHealthTableCell>
                                                <Box className="wh-tw-text-primaryV wh-tw-text-sm" onClick={() => handleDownload(item.id)}>
                                                    {
                                                        item.name
                                                    }
                                                </Box>
                                            </WildHealthTableCell>
                                            <WildHealthTableCell>
                                                <Box className="wh-tw-text-sm" display="flex" flexWrap="wrap">
                                                    {item.uploadedBy}
                                                </Box>
                                            </WildHealthTableCell>
                                            <WildHealthTableCell>
                                                <Box className="wh-tw-text-sm" display="flex" flexWrap="wrap">
                                                    {displayDataValue(item.dateUploaded)}
                                                </Box>
                                            </WildHealthTableCell>
                                        </WildHealthTableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    : renderEmptyValue()
                }
            </Box>
        )
    }

    return (
        <Box p={3} className={classes.root}>
            <Box flex={1}>
                <Box>
                    <Box display="flex" justifyContent="flex-start" alignItems="center">
                        <Box id={`plan-section-expand`} onClick={() => handleExpand(SectionEnum.PLAN)} className="wh-tw-cursor-pointer">
                            {state.sectionExpandStates[SectionEnum.PLAN] ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                        </Box>
                        <Box ml={1}>
                            <span className="wh-tw-text-lg wh-tw-font-medium wh-tw-font-beVietnamPro">1. Plan</span>
                        </Box>
                        <Box ml={1}>
                            <Tooltip placement="top" arrow title={<p className={classes.notificationToolTipText}>See Visit Note</p>} classes={customTooltipClasses}>
                                <Link
                                    id='go-to-patient-note'
                                    className="wh-tw-text-primaryV"
                                    onClick={() => navigationService.toPatientProfileNote(history, patientId, !state.selectedPlanTab ? state.visitPrep.providerPlan?.noteId : state.visitPrep.healthCoachPlan?.noteId)}
                                >
                                    <ExportIcon />
                                </Link>
                            </Tooltip>
                        </Box>
                    </Box>
                    <Collapse in={state.sectionExpandStates[SectionEnum.PLAN]} timeout="auto" unmountOnExit>
                        {renderPlanSection()}
                    </Collapse>
                </Box>
                <Divider className="wh-tw-bg-dividers wh-tw-my-4" />
                <Box>
                    <Box display="flex" justifyContent="flex-start" alignItems="center">
                        <Box id={`mdm-section-expand`} onClick={() => handleExpand(SectionEnum.MDM)} className="wh-tw-cursor-pointer">
                            {state.sectionExpandStates[SectionEnum.MDM] ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                        </Box>
                        <Box ml={1}>
                            <span className="wh-tw-text-lg wh-tw-font-medium wh-tw-font-beVietnamPro">2. MDM</span>
                        </Box>
                        <Box ml={1}>
                            <Link
                                id='go-to-patient-note'
                                className="wh-tw-text-primaryV"
                                onClick={() => navigationService.toPatientProfileNote(history, patientId, state.visitPrep.mdm?.noteId)}
                            >
                                <ExportIcon />
                            </Link>
                        </Box>
                    </Box>
                    <Collapse in={state.sectionExpandStates[SectionEnum.MDM]} timeout="auto" unmountOnExit>
                        <Box pt={2} px={1}>
                            {state.visitPrep.mdm ? state.visitPrep.mdm.text : renderEmptyValue()}
                        </Box>
                    </Collapse>
                </Box>
                <Divider className="wh-tw-bg-dividers wh-tw-my-4" />
                <Box>
                    <Box display="flex" justifyContent="flex-start" alignItems="center">
                        <Box id={`goals-section-expand`} onClick={() => handleExpand(SectionEnum.GOALS)} className="wh-tw-cursor-pointer">
                            {state.sectionExpandStates[SectionEnum.GOALS] ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                        </Box>
                        <Box ml={1}>
                            <span className="wh-tw-text-lg wh-tw-font-medium wh-tw-font-beVietnamPro">3. Goals</span>
                        </Box>
                        <Box ml={1}>
                            <Link
                                id='go-to-patient-goals'
                                className="wh-tw-text-primaryV"
                                onClick={() => navigationService.toPatientHealthPlan(history, patientId)}
                            >
                                <ExportIcon />
                            </Link>
                        </Box>
                    </Box>
                    <Collapse in={state.sectionExpandStates[SectionEnum.GOALS]} timeout="auto" unmountOnExit>
                        {renderGoalsSection()}
                    </Collapse>
                </Box>
                <Divider className="wh-tw-bg-dividers wh-tw-my-4" />
                <Box>
                    <Box display="flex" justifyContent="flex-start" alignItems="center">
                        <Box id={`new-labs-section-expand`} onClick={() => handleExpand(SectionEnum.NEW_LABS)} className="wh-tw-cursor-pointer">
                            {state.sectionExpandStates[SectionEnum.NEW_LABS] ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                        </Box>
                        <Box ml={1}>
                            <span className="wh-tw-text-lg wh-tw-font-medium wh-tw-font-beVietnamPro">4. New Labs</span>
                        </Box>
                    </Box>
                    <Collapse in={state.sectionExpandStates[SectionEnum.NEW_LABS]} timeout="auto" unmountOnExit>
                        {renderNewLabSection()}
                    </Collapse>
                </Box>
                <Divider className="wh-tw-bg-dividers wh-tw-my-4" />
                <Box>
                    <Box display="flex" justifyContent="flex-start" alignItems="center">
                        <Box id={`medications-supplements-section-expand`} onClick={() => handleExpand(SectionEnum.MEDICATIONS_AND_SUPPLEMENTS)} className="wh-tw-cursor-pointer">
                            {state.sectionExpandStates[SectionEnum.MEDICATIONS_AND_SUPPLEMENTS] ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                        </Box>
                        <Box ml={1}>
                            <span className="wh-tw-text-lg wh-tw-font-medium wh-tw-font-beVietnamPro">5. Medications & Supplements</span>
                        </Box>
                        <Box ml={1}>
                            <Link
                                id='go-to-patient-medications-and-supplements'
                                className="wh-tw-text-primaryV"
                                onClick={() => navigationService.toPatientsProfileSupplements(history, patientId)}
                            >
                                <ExportIcon />
                            </Link>
                        </Box>
                    </Box>
                    <Collapse in={state.sectionExpandStates[SectionEnum.MEDICATIONS_AND_SUPPLEMENTS]} timeout="auto" unmountOnExit>
                        {renderMedicationsAndSupplementsSection()}
                    </Collapse>
                </Box>
                <Divider className="wh-tw-bg-dividers wh-tw-my-4" />
                <Box>
                    <Box display="flex" justifyContent="flex-start" alignItems="center">
                        <Box id={`recent-document-section-expand`} onClick={() => handleExpand(SectionEnum.RECENT_DOCUMENTS)} className="wh-tw-cursor-pointer">
                            {state.sectionExpandStates[SectionEnum.RECENT_DOCUMENTS] ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                        </Box>
                        <Box ml={1}>
                            <span className="wh-tw-text-lg wh-tw-font-medium wh-tw-font-beVietnamPro">6. Recent Documents</span>
                        </Box>
                        <Box ml={1}>
                            <Tooltip placement="top" arrow title={<p className={classes.notificationToolTipText}>Documents uploaded since last medical visit</p>} classes={customTooltipClasses}>
                                <InfoOutlinedIcon className="wh-tw-text-gray400" />
                            </Tooltip>
                        </Box>
                    </Box>
                    <Collapse in={state.sectionExpandStates[SectionEnum.RECENT_DOCUMENTS]} timeout="auto" unmountOnExit>
                        {renderRecentDocumentSection()}
                    </Collapse>
                </Box>
            </Box>
            <Box pb={4}>
                <ChronologicalSummaryComponent patientId={patientId} filterTypes={FilterByEventType} />
            </Box>
        </Box>
    )
}

