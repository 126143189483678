import Axios from 'axios-observable';
import { Observable } from 'rxjs';
import { authHeader } from "../../common/helpers/auth-header";
import { snackService } from "../../common/snack/state";
import { dnaFilesStore, DNAFilesStore } from "../stores/dnaFiles";
import {UpdateDnaFileSynchronizationStatusModel} from "../models/dnaFlies.models";
const FileDownload = require('js-file-download');

export class DNAFilesService{
    constructor(private dnaFilesStore : DNAFilesStore) { }

    private url = `${process.env.REACT_APP_API_URL}DNAFiles`;

    public getAll(): void {
        const config = {
            headers: authHeader()
        };

        Axios.get(`${this.url}`, config)
            .pipe()
            .subscribe(
                response => this.dnaFilesStore.updateDnaFiles(response.data),
                error => snackService.commonErrorHandler(error)
            );
    }
    
    public downloadFile(fileName: string): Observable<boolean> {
        snackService.info('File downloading has been started.');

        return new Observable<boolean>(observer => {
            Axios.get(`${this.url}/download/?fileName=${fileName}`,
            {
                headers: authHeader(),
                responseType: 'blob'
            })
            .pipe()
            .subscribe(
                (response) => {
                    FileDownload(response.data, fileName);
                    snackService.success('DNA File successfully downloaded!');
                    observer.next(response.data);
                    observer.complete();
                },
                error => {
                    snackService.commonErrorHandler(error);
                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }

    public retryFile(fileName: string): Observable<boolean> {
        snackService.info('File retry has been started.');

        const config = {
            headers: authHeader()
        };

        return new Observable<boolean>(observer => {
            Axios.put(`${this.url}/retry?fileName=${fileName}`, null, config)
                .pipe()
                .subscribe(
                    () => {
                        snackService.success('DNA File successfully retried!');
                        observer.next(true);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public getFileSize(fileName: string): void {
        Axios.get(`${this.url}/Size/?fileName=${fileName}`,
            {
                headers: authHeader()
            })
            .pipe()
            .subscribe(
                (response) => {
                    snackService.info(`File size is ${response.data}MB. Downloading may take some time. Please do not reload this page`);
                },
                error => snackService.commonErrorHandler(error)
            );
    }

    public syncStatusChange(model: UpdateDnaFileSynchronizationStatusModel): void {
        const config = { headers: authHeader() };
        Axios.put(`${this.url}/synchronizationStatus`, model, config)
            .pipe()
            .subscribe(
                (response) => {
                    this.dnaFilesStore.updateDnaFile(response.data);
                    snackService.success('DNA File synchronization status has been changed!');
                },
                error => snackService.commonErrorHandler(error)
            );
    }
}

export const dnaFilesService = new DNAFilesService(dnaFilesStore);