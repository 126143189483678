import React from 'react';
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    List,
    ListItem,
    ListItemText,
    Divider,
    IconButton
} from "@material-ui/core";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import SearchIcon from "@material-ui/icons/Search";
import { ReactComponent as LinkIcon } from '@img/icons/Link.svg';
import { useFacade } from "./sendSignUpButtonComponent.hooks";
import { useStyles, SearchTextField } from "./sendSignUpButtonComponent.styles";
import { WildHealthButton } from "../../../../../common/components/wildHealthButton/WildHealthButton";

interface SendSignUpButtonComponentProps {
    productId: number;
}

export const SendSignUpButtonComponent: React.FC<SendSignUpButtonComponentProps> = (props: SendSignUpButtonComponentProps) => {
    const classes = useStyles();
    const [
        {
            isLoading,
            isOpen,
            requests,
            filteredRequests,
            selectedIds
        },
        handleSelect,
        handleSelectAll,
        handleSend,
        handleToggle,
        handleFilter,
        handleCopyLink
    ] = useFacade(props.productId);

    return (
        <>
            <WildHealthButton
                id="send-sign-up-email-button"
                size='medium'
                color="secondary"
                borderRadius={4}
                disabled={isLoading}
                onClick={() => handleToggle(true)}
            >
                <span className="wh-tw-font-beVietnamPro">Send Sign Up Email</span>
            </WildHealthButton>

            <Dialog
                fullWidth
                open={isOpen}
                onClose={() => handleToggle(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.root}
            >
                <MuiDialogTitle
                    className={classes.dialogTitle}
                    disableTypography
                >
                    <IconButton
                        id="close-button"
                        className={classes.dialogTitleCloseAction}
                        onClick={() => handleToggle(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                </MuiDialogTitle>
                <DialogContent className="wh-tw-px-8">
                    <Box className="wh-tw-text-xl wh-tw-font-medium" mb={3}>Send Sign Up Email</Box>
                    <Box pb={3}>
                        <SearchTextField
                            fullWidth
                            variant="outlined"
                            className={classes.search}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                handleFilter(e.target.value)
                            }
                            placeholder="Search by Name/Email"
                            InputProps={{
                                inputProps: {
                                    className: classes.searchBase,
                                },
                                endAdornment: <SearchIcon />,
                            }}
                        />
                    </Box>
                    {
                        <Box className={classes.list}>
                            {requests
                                ? (
                                    <>
                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                        <Box className="wh-tw-text-black wh-tw-text-sm wh-tw-font-semibold wh-tw-font-beVietnamPro">Select All</Box>
                                        <Box className="wh-tw-cursor-pointer" onClick={() => handleSelectAll()}>
                                            {selectedIds.length === requests.length ? <CheckCircleIcon fontSize="large" className="wh-tw-text-primaryV" /> : <RadioButtonUncheckedIcon fontSize="large" className="wh-tw-text-primaryV" />}
                                        </Box>
                                    </Box>
                                    {
                                        filteredRequests.map((request) => {
                                            return (
                                                <Box key={`request-${request.id}`} display="flex" alignItems="center" justifyContent="space-between" pt={2}>
                                                    <Box>
                                                        <Box className="wh-tw-text-black wh-tw-text-sm wh-tw-font-semibold wh-tw-font-beVietnamPro">{`${request.firstName} ${request.lastName}`}</Box>
                                                        <Box className="wh-tw-text-gray500 wh-tw-text-sm wh-tw-font-beVietnamPro">{request.email}</Box>
                                                    </Box>
                                                    <Box className="wh-tw-cursor-pointer" onClick={() => handleSelect(request.id)}>
                                                        {selectedIds.includes(request.id) ? <CheckCircleIcon fontSize="large" className="wh-tw-text-primaryV" /> : <RadioButtonUncheckedIcon fontSize="large" className="wh-tw-text-primaryV" />}
                                                    </Box>
                                                </Box>
                                            )
                                        })
                                    }
                                    </>
                                )
                                : <List>
                                    <ListItem>
                                        <ListItemText primary={'No requests yet'} />
                                    </ListItem>
                                </List>
                            }
                        </Box>
                    }
                </DialogContent>
                <Divider />
                <DialogActions className="wh-tw-py-4 wh-tw-px-8">
                    <Box width={1} display="flex" alignItems="center" justifyContent="space-between">
                        <Box>
                            {selectedIds.length === 1 && <>
                                <Box display="flex" alignItems="center" gridGap={4} className="wh-tw-cursor-pointer" onClick={() => handleCopyLink(selectedIds[0])}>
                                    <LinkIcon />
                                    <Box className="wh-tw-text-sm wh-tw-text-primaryV wh-tw-font-beVietnamPro wh-tw-font-semibold">Copy Sign Up Link</Box>
                                </Box>
                            </>}
                        </Box>
                        <WildHealthButton
                            id="send-button"
                            size="large"
                            borderRadius={50}
                            loading={isLoading}
                            disabled={!selectedIds.length}
                            onClick={() => handleSend()}
                        >
                            <Box px={2} className="wh-tw-font-beVietnamPro wh-tw-font-medium">Send</Box>
                        </WildHealthButton>
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    );
}
