import { Store, StoreConfig } from '@datorama/akita';
import { BannerModel } from '../../../../models/banner.model';

/**
 * Represents Banners state
 */
export interface BannersState {
    banners: BannerModel[];
}

/**
 * Creates initial state
 */
export function createInitialState(): BannersState {
    return {
        banners: [],
    };
}

/**
 * Provides banners state management
 */
@StoreConfig({ name: 'banners', resettable: true })
export class BannersStore extends Store<BannersState> {
    constructor() {
        super(createInitialState());
    }

    public addBanner(newBanner: BannerModel) {
        this.update({ banners: [...this.getValue().banners, newBanner] });
    }

    public updateBanner(banner: BannerModel) {
        const banners = this.getValue().banners;
        this.update({ banners: banners.map(x => x.id === banner.id ? banner : x) });
    }

    public removeBanner(id: number) {
        this.update({ banners: this.getValue().banners.filter(i => i.id !== id) });
    }
}

export const bannersStore = new BannersStore();
