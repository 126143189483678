import {Observable} from "rxjs";
import Axios from "axios-observable";
import {authHeader} from "../../common/helpers/auth-header";
import {snackService} from "../../common/snack/state";
import {ordersStore, OrdersStore} from "../stores/ordersStore/orders.store";
import { CreateOtherOrderModel, OtherOrderModel, UpdateOtherOrderModel } from "../models/otherOrders.models";

export class OtherOrdersService {
    private url = `${process.env.REACT_APP_API_URL}Orders/Other`;

    constructor(private store: OrdersStore) {
    }

    public create(model: CreateOtherOrderModel): Observable<OtherOrderModel> {
        return new Observable<OtherOrderModel>(observer => {
            Axios.post<OtherOrderModel>(`${this.url}`, model, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.addOtherOrder(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    },
                );
        });
    }

    public get(patientId: number): Observable<OtherOrderModel[]> {
        return new Observable<OtherOrderModel[]>(observer => {
            Axios.get<OtherOrderModel[]>(`${this.url}?patientId=${patientId}`, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({ otherOrders: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    },
                );
        });
    } 

    public update(model: UpdateOtherOrderModel): Observable<OtherOrderModel> {
        return new Observable<OtherOrderModel>(observer => {
            Axios.put<OtherOrderModel>(`${this.url}`, model, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.updateOtherOrder(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    },
                );
        });
    }

    public getMyOrders(): Observable<OtherOrderModel[]> {
        return new Observable<OtherOrderModel[]>(observer => {
            Axios.get<OtherOrderModel[]>(`${this.url}/My`, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({ otherOrders: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    },
                );
        });
    }

    public getForReview(): Observable<OtherOrderModel[]> {
        return new Observable<OtherOrderModel[]>(observer => {
            Axios.get<OtherOrderModel[]>(`${this.url}/forReview`, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({ orderOtherForReview: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    },
                );
        });
    }

    public delete(id: number) {
        const config = { headers: authHeader() };

        return new Observable(observer => {
            Axios.delete(`${this.url}/${id}`, config).pipe()
                .subscribe(
                    response => {
                        this.store.deleteOtherOrder(response.data);
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }
}

export const otherOrdersService = new OtherOrdersService(ordersStore);