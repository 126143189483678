import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { onEmit } from "../../../common/helpers/on-emit";
import { referralOrdersService } from "../../services/referralOrders.service";
import { ordersQuery } from "../../stores/ordersStore/orders.query";
import { ReferralOrderModel } from "../../models/referralOrders.models";
import { referralOrderPdfService } from "../../services/orders-pdf.service";
import { OrderStatus } from "../../models/orders.models";

export enum ReferralOrdersViewState {
    AllOrders = 0,
    NewOrder = 1,
    UpdateOrder = 2
}

interface ReferralOrdersComponentState {
    isLoading: boolean;
    isProcessing: boolean;
    orders: ReferralOrderModel[];
    state: ReferralOrdersViewState;
    targetOrder: ReferralOrderModel;
}

export function useFacade(patientId: number): [
    ReferralOrdersComponentState,
    Array<string>,
    (orderId: number) => void,
    (order: ReferralOrderModel) => void,
    (order: ReferralOrderModel) => void,
    () => void,
    () => void
] {
    const [state, setState] = useState({
        isLoading: true,
        isProcessing: false,
        orders: [],
    } as ReferralOrdersComponentState);

    const columns = [
        'Requested Referral',
        'Referral Date',
        'Status',
        'Action',
    ];


    const handleDownloadOrder = (order: ReferralOrderModel) => {
        referralOrderPdfService.download(order)
    }

    const handleDeleteOrder = (orderId: number) => {
        setState(state => ({
            ...state,
            isProcessing: true
        }));

        const cb = () => setState(state => ({ ...state, isProcessing: false }));

        referralOrdersService.delete(orderId).subscribe(cb, cb);
    }

    const handleOpenOrder = (order: ReferralOrderModel) => {
        if (order.status !== OrderStatus.Signed ) {
            setState(state => ({
                ...state,
                state: ReferralOrdersViewState.UpdateOrder,
                targetOrder: order
            }));

            return
        }
        
        handleDownloadOrder(order);
    }

    const handleNewOrder = () => {
        setState(state => ({
            ...state,
            targetOrder: null,
            state: ReferralOrdersViewState.NewOrder
        }));
    }

    const handleGoBack = () => {
        setState(state => ({
            ...state,
            targetOrder: null,
            state: ReferralOrdersViewState.AllOrders
        }));
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<ReferralOrderModel[]>(ordersQuery.referralOrders$, orders => {
                setState(state => ({
                    ...state,
                    orders
                }));
            }),
        ];

        const cb = () => setState(state => ({ ...state, isLoading: false }));

        if (patientId) {
            referralOrdersService.get(patientId).subscribe(cb, cb);
        } else {
            referralOrdersService.getMyOrders().subscribe(cb, cb);
        }

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [patientId]);

    return [state, columns, handleDeleteOrder, handleDownloadOrder, handleOpenOrder, handleNewOrder, handleGoBack];
}