import {Store, StoreConfig} from '@datorama/akita';
import {newPatientSupplementModel, PatientSupplementModel, SupplementSource} from "../../models/patientSupplementModel";

/**
 * Represents coaches state
 */
export interface PatientSupplementsState {
    patientSupplements: PatientSupplementModel[];
    editModel: PatientSupplementModel;
}

/**
 * Creates initial state
 */
export function createInitialState(): PatientSupplementsState {
    return {
        patientSupplements: [],
        editModel: newPatientSupplementModel(SupplementSource.Supplement)
    };
}

/**
 * Provides coaches states management
 */
@StoreConfig({name: 'patientSupplements', resettable: true })
export class PatientSupplementsStore extends Store<PatientSupplementsState> {
    constructor() {
        super(createInitialState());
    }

    public create(model: PatientSupplementModel) {
        this.update({patientSupplements: [...this.getValue().patientSupplements, model]});
    }

    public edit(model: PatientSupplementModel) {
        const patientSupplements = this.getValue().patientSupplements.map(p => p.id === model.id ? model : p);
        this.update({patientSupplements: patientSupplements});
    }

    public delete(medicationId: number) {
        const supplements = this.getValue().patientSupplements.filter(x => x.id !== medicationId);
        this.update({patientSupplements: supplements});
    }

    public setEditModel(model?: PatientSupplementModel){
        this.update({editModel: Object.assign({}, model ?? newPatientSupplementModel(SupplementSource.Supplement))});
    }
}

export const patientSupplementsStore = new PatientSupplementsStore();