import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Subscription } from "recompose";
import { navigationService } from "../../../../services/navigation.service";
import { LocationModel } from "../../../locations/models/locations.models";
import { authQuery } from "../../../auth/stores/auth";
import { accountService } from "../../../../services/account.service";
import { onEmit } from "../../../common/helpers/on-emit";
import { locationsQuery } from "../../../locations/stores";
import { locationsService } from "../../../locations/services/locations.service";

interface ProfileComponentState {
  anchorEl: HTMLButtonElement;
  userName: string;
  targetLocationId: number,
  locations: LocationModel[]
  isOpen: boolean;
}

/**
 * Custom Hook to manage a view Model for Notifications component
 */
export function useFacade(): [ProfileComponentState, Function, Function, Function, Function, Function, Function] {
  const history = useHistory();
  const [state, setState] = useState({
    anchorEl: null,
    userName: authQuery.getName(),
    targetLocationId: authQuery.getTargetLocationId(),
    locations: [],
    isOpen: false
  } as ProfileComponentState);

  const handleToggle = (event?: React.MouseEvent<HTMLButtonElement>) => {
    const anchorEl = event && event.currentTarget ? event.currentTarget : null;
    setState(state => ({
      ...state,
      isOpen: !state.isOpen,
      anchorEl: anchorEl
    }));
  }

  const handleSwitchLocation = (location: LocationModel) => {
    accountService.switchLocation(location, history);
  }

  const handleGoToMyProfile = () => {
    navigationService.toMyProfile(history);
  }

  const handleGoToMyMembership = () => {
    navigationService.toMyMembership(history);
  }

  const handleGoToMyInsurance = () => {
    navigationService.toInsurance(history);
  }

  const handleLogOut = () => {
    accountService.logout();
  }

  useEffect(() => {
    const subscriptions: Subscription[] = [
      onEmit<string>(authQuery.firstName$, () => {
        setState(state => ({
          ...state,
          userName: authQuery.getName()
        })
        )
      }),
      onEmit<string>(authQuery.lastName$, () => {
        setState(state => ({
          ...state,
          userName: authQuery.getName()
        })
        )
      }),
      onEmit<LocationModel[]>(locationsQuery.availableLocations$, locations => {
        setState(state => ({
          ...state,
          locations: locations
        })
        )
      }),
      onEmit<number>(authQuery.targetLocationId$, targetLocationId => {
        setState(state => ({
          ...state,
          targetLocationId: targetLocationId
        }))
      })
    ];

    locationsService.getAvailable(authQuery.getCurrentPracticeId()).subscribe();

    return () => {
      subscriptions.map(it => it.unsubscribe())
    };
  }, []);

  return [state, handleToggle, handleSwitchLocation, handleGoToMyProfile, handleGoToMyMembership, handleLogOut, handleGoToMyInsurance];
}