import { Box, Dialog, DialogActions, DialogContent, DialogContentText, FormControlLabel, RadioGroup } from '@material-ui/core';
import React from 'react';
import { DialogTitle } from '../../components/dialogTitle/DialogTitle';
import { WildHealthButton } from '../../components/wildHealthButton/WildHealthButton';
import { useFacade } from "./confirmAndSelectedOneDialog.hooks";
import { UserType } from '../../../auth/models/auth.enums';
import { WildHealthRadio } from '../../components/WildHealthRadio';

export const CommonConfirmAndSelectedOneDialog: React.FC = () => {
    const [
        {
            isOpen,
            title,
            message,
            options,
            acceptTitle,
            newChoice,
        },
        handleCancel,
        handleConfirm,
        handleDataCategoriesSelect,
    ] = useFacade();

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleConfirm();
        }
    }

    return (
        <Box onKeyPress={(e) => handleKeyPress(e)}>
            <Dialog
                fullWidth
                open={isOpen}
                onClose={() => handleCancel()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="dialog-title" onClose={() => handleCancel()}>
                    <Box p={2} pb={0}>
                        {title}
                    </Box>
                </DialogTitle>

                <DialogContent>
                    <Box px={2}>
                        <DialogContentText id="alert-dialog-description">
                            {message}
                        </DialogContentText>
                        <RadioGroup value={newChoice} onChange={(event) => handleDataCategoriesSelect(+event.target.value)}>
                            {
                                ([...options.keys()]).map(x => {
                                    return <FormControlLabel checked={x === newChoice} key={x} value={x} control={<WildHealthRadio />} label={options.get(x)} />
                                })
                            }
                    </RadioGroup>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Box mx={2} mb={4}>
                        <WildHealthButton size='medium' id={`confirm-with-select-one-${title}`} color="tertiary" onClick={() => handleCancel()}>
                            Cancel
                        </WildHealthButton>
                    </Box>
                    <Box mr={4} mb={4}>
                        <WildHealthButton size='medium' id={`confirm-with-select-one-${acceptTitle}-${title}`} disabled={!newChoice} onClick={() => handleConfirm()}>
                            {acceptTitle}
                        </WildHealthButton>
                    </Box>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
