import Axios from "axios-observable";
import { Observable } from "rxjs";
import { authHeader } from "../../common/helpers/auth-header";
import { snackService } from "../../common/snack/state";
import { DnaOrderModel, ReplaceOrderModel, UpdateOrderModel } from "../models/dnaOrders.models";
import { CreateOrderModel, ReplaceOrderResponseModel, OrderStatus } from "../models/orders.models";
import { dnaOrdersStore, DnaOrdersStore } from "../stores/dnaOrdersStore";

export class DnaOrdersService {
    private url = `${process.env.REACT_APP_API_URL}Orders/Dna`;

    constructor(private store: DnaOrdersStore) {
    }

    public get(patientId: number): Observable<DnaOrderModel[]> {
        return new Observable<DnaOrderModel[]>(observer => {
            Axios.get<DnaOrderModel[]>(`${this.url}?patientId=${patientId}`, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({ orders: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    },
                );
        });
    }

    public getMyOrders(): Observable<DnaOrderModel[]> {
        return new Observable<DnaOrderModel[]>(observer => {
            Axios.get<DnaOrderModel[]>(`${this.url}/GetPatientDnaOrders`, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({ orders: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    },
                );
        });
    }

    public create(model: CreateOrderModel): Observable<DnaOrderModel> {
        return new Observable<DnaOrderModel>(observer => {
            Axios.post<DnaOrderModel>(`${this.url}`, model, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.addOrder(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    },
                );
        });
    }

    public replace(model: ReplaceOrderModel): Observable<ReplaceOrderResponseModel<DnaOrderModel>> {
        return new Observable<ReplaceOrderResponseModel<DnaOrderModel>>(observer => {
            Axios.post<ReplaceOrderResponseModel<DnaOrderModel>>(`${this.url}/Replace`, model, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.replaceOrder(response.data.replacedOrder, response.data.newOrder);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    },
                );
        });
    }

    public updatePatientDnaOrders(model: UpdateOrderModel): Observable<DnaOrderModel> {
        return new Observable<DnaOrderModel>(observer => {
            Axios.put<DnaOrderModel>(`${this.url}/UpdatePatientDnaOrders`, model, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        snackService.success("DNA Order successfully updated!");
                        this.store.updateOrder(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    },
                );
        });
    }

    public getByOrderStatus(status: OrderStatus): Observable<DnaOrderModel[]> {
        return new Observable<DnaOrderModel[]>(observer => {
            Axios.get<DnaOrderModel[]>(`${this.url}/GetByOrderStatus?status=${status}`, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({ orders: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    },
                );
        });
    }

    public updateDnaOrderShipping(model: UpdateOrderModel): Observable<DnaOrderModel> {
        return new Observable<DnaOrderModel>(observer => {
            Axios.post<DnaOrderModel>(`${this.url}/UpdateDnaOrderShipping`, model, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        snackService.success("DNA Order successfully updated!");
                        this.store.updateOrder(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    },
                );
        });
    }

    public deleteDnaOrderShipping(id: number): Observable<DnaOrderModel> {
        return new Observable<DnaOrderModel>(observer => {
            Axios.delete<DnaOrderModel>(`${this.url}/DeleteDnaOrderShipping/${id}`, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        snackService.success("DNA Order successfully deleted!");
                        this.store.deleteOrder(id);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    },
                );
        });
    }
}

export const dnaOrdersService = new DnaOrdersService(dnaOrdersStore);