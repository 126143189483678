import { Box, Dialog, DialogActions, DialogContent, FormControl, MenuItem, Select } from "@material-ui/core";
import React from "react";
import { ChangeReviewerDialogComponentProps } from "../../../common/dialogs/dialogProps";
import { useFacade } from "./changeReviewerDialogComponent.hooks";
import DialogTitle from "@material-ui/core/DialogTitle";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";

export const ChangeReviewerDialogComponent: React.FC<ChangeReviewerDialogComponentProps> = (props: ChangeReviewerDialogComponentProps) => {
    const {
        patientId,
        reportId,
        reportReviewerId,
        open,
        handleClose
    } = props;

    const [
        state,
        handleReviewerChange,
        handleSubmitForm,
    ] = useFacade(handleClose, reportReviewerId, patientId, reportId);

    const selectProvider = (
        <FormControl
            color='primary'
            variant="outlined"
            size="small"
        >
            <Select
                value={state.newReviewer?.id ?? 0}
                onChange={(event) => handleReviewerChange(Number(event.target.value))}
            >
                {
                    state.reviewers.map((reviewer) =>
                        <MenuItem
                            key={reviewer.id}
                            value={reviewer.id}
                        >
                            <Box display="flex">
                                <Box mr={1}>{reviewer.firstName}</Box>
                                <Box>{reviewer.lastName}</Box>
                            </Box>
                        </MenuItem>)
                }
            </Select>
        </FormControl>
    );

    return (
        <Dialog fullWidth open={open} onClose={() => handleClose()} aria-labelledby="form-dialog-title">
            <form onSubmit={(e) => handleSubmitForm(e)}>
                <DialogContent>
                    <DialogTitle
                        id="form-dialog-title">Edit Provider</DialogTitle>
                    <Box m={3}>Please select the provider you would like to review and sign
                        this report.
                    </Box>
                    <Box p={3}>
                        <Box display="flex" alignItems="center">
                            <Box mr={1}>
                                <span className="text-medium">Under Review by</span>
                            </Box>
                            {selectProvider}
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Box display="flex" justifyContent="flex-end" width="100%" px={3} pb={2}>
                        <Box mr={1.5}>
                            <WildHealthButton
                                id="change-reviewer-cancel"
                                color="tertiary"
                                onClick={() => handleClose()}
                            >
                                Cancel
                            </WildHealthButton>
                        </Box>
                        <Box>
                            <WildHealthButton
                                id="change-reviewer-save"
                                onClick={(e) => handleSubmitForm(e)}
                            >
                                Save
                            </WildHealthButton>
                        </Box>
                    </Box>
                </DialogActions>
            </form>
        </Dialog>
    );
}
