import {useEffect, useState} from "react";
import {Subscription} from "rxjs";
import {onEmit} from "../../../common/helpers/on-emit";
import {notificationsQuery} from "../../../notifications/stores/notifications";
import {NotificationModel, NotificationType} from "../../../notifications/models/notifications.models";

const interestedTypes = [
    NotificationType.NewSupportTicket
];

export function useFacade(): [boolean] {
    const [newTickets, setNewTickets] = useState(false);

    const hasNewTickets = (notifications: NotificationModel[]): boolean => {
        notifications = notifications.filter(x => interestedTypes.includes(x.type));

        return notifications.length > 0;
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<NotificationModel[]>(notificationsQuery.notifications$, notifications => {
                if (notifications && notifications.length) {
                    setNewTickets(() => (hasNewTickets(notifications)));
                } else {
                    setNewTickets(() => false);
                }
            }),
        ];

        return (() => {
            subscriptions.map(it => it.unsubscribe())
        })
    }, []);

    return [newTickets];
}