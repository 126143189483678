import { useEffect, useState } from "react";
import { IErrorState } from "../../../common/validation/error-state";
import { createPromoCodeComponentValidator } from "./createPromoCodeComponent.validator";
import { PaymentPlanModel } from "../../../payment/models/paymentPlan.models";
import { authQuery } from "../../../auth/stores/auth";
import { addressService } from "../../../account/services/address.service";
import { paymentPlansService } from "../../../payment/services/paymentPlans.service";
import { CreatePromoCodeModel, DiscountType } from "../../../payment/models/paymentCoupon.model";
import { GeneralValidator } from "../../../common/validation/general-validator";

interface CreatePromoCodesState extends IErrorState {
  isLoading: boolean;
  isSubmitted: boolean;
  isSpecific: boolean;
  isSeeMoreOption: boolean;
  activePaymentPlans: PaymentPlanModel[];
  params: CreatePromoCodeModel;
  menuAnchorEl: HTMLButtonElement;
}

export function useFacade(): [
  CreatePromoCodesState,
  () => void,
  (menuAnchorEl?: HTMLButtonElement) => void,
  (event: React.ChangeEvent<HTMLInputElement>) => void,
  (field: string, value: any) => void,
  () => void,
  () => void,
] {
    const initialParmas = {
      code: '',
      description: '',
      discount: 0,
      discountType: DiscountType.Percentage,
      paymentPlans: [],
      isDiscountStartupFee: false,
      isDiscountLabs: false,
      isAppliedForInsurance: false,
      expirationDate: null
    }
    
    const [state, setState] = useState({
      isLoading: true,
      isSubmitted: false,
      isSpecific: false,
      isSeeMoreOption: false,
      activePaymentPlans: [],
      params: initialParmas,
      menuAnchorEl: null,
      errors: {},
    } as CreatePromoCodesState);

    const handleToggleMoreOptions = () => {
      setState(state => ({
        ...state,
        isSeeMoreOption: !state.isSeeMoreOption
      }))
    }

    const handleToggleDiscountType = (menuAnchorEl?: HTMLButtonElement) => {
      setState(state => ({ 
        ...state, 
        menuAnchorEl,
      }))
    }

    const handleToggleSpecificChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setState({ ...state, isSpecific: event.target.checked });
    };

    const handleChanges = (field: string, value: any) => {
      createPromoCodeComponentValidator.validateAndSetState(state, setState, field, value);

      const params = state.params;
      if (field === 'paymentPlans') {
        const index = params.paymentPlans.findIndex(x => x === value);
        index > -1 ? params.paymentPlans.splice(index, 1) : params.paymentPlans.push(value);
        if (!params.paymentPlans.length) {
          GeneralValidator.addError(state, 'paymentPlans', 'Please select the product.');
          setState(state => ({
            ...state,
            errors: state.errors
          }))
        } else {
          GeneralValidator.removeError(state, 'paymentPlans');
          setState(state => ({
            ...state,
            errors: state.errors
          }))
        }
      } else {
        params[field] = value;
      }

      setState({...state, params: Object.assign({}, params)});
      handleToggleDiscountType();
    }

    const handleCreate = () => {
      createPromoCodeComponentValidator.validateObjectAndSetState(state, setState, state.params);
      if (!createPromoCodeComponentValidator.stateIsValid(state)) {
        return;
      }
      if (state.isSpecific && !state.params.paymentPlans.length) {
        GeneralValidator.addError(state, 'paymentPlans', 'Please select the product.');
        setState({
          ...state,
          errors: state.errors
        });
        return;
      }
      if (!state.isSpecific) {
        state.params.paymentPlans = [];
      }

      setState({...state, isSubmitted: true })

      const cb = () => setState(state => ({ ...state, isSubmitted: false }));

      paymentPlansService.createPromoCode(state.params).subscribe(() => {
        setState(state => ({
          ...state,
          isSubmitted: false,
          params: Object.assign({}, initialParmas)
        }))
      }, cb);
    }

    const handleCancel = () => {
      setState(state => ({
        ...state,
        params: Object.assign({}, initialParmas)
      }))
    }

    useEffect(() => {
      const cb = () => setState(state => ({ ...state, isLoading: false }));
      const getStateCB = () => setState(state => ({ ...state, isStateLoading: false }))

      paymentPlansService.getActive(authQuery.getCurrentPracticeId(), null).subscribe((plans) => 
        setState(state => ({
          ...state,
          activePaymentPlans: plans.filter(x => !x.isTrial && !x.isFounder && !x.isLimited),
          isLoading: false
        }))
      , cb);
      addressService.getStates().subscribe(getStateCB, getStateCB);      
    }, []);

    return [
      state,
      handleToggleMoreOptions,
      handleToggleDiscountType,
      handleToggleSpecificChange,
      handleChanges,
      handleCancel,
      handleCreate
    ];
}