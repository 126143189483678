import React from 'react';
import { Box, Divider, Dialog, DialogContent, useMediaQuery, useTheme } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useStyles } from "./standardUpsellDialog.styles";
import { useFacade } from "./standardUpsellDialog.hooks";
import { DialogTitle } from '../../../common/components/dialogTitle/DialogTitle';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import { patientStore } from '../../../patients/stores/patientsStore';

export const StandardUpsellRequestDialog: React.FC = () => {
    const [
        {
            isRequestOpen,
        },
        handleRequest,
    ] = useFacade();

    const classes = useStyles();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            fullWidth
            fullScreen={fullScreen}
            maxWidth="xs"
            open={isRequestOpen}
            onClose={() => patientStore.toggleRequestDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle
                id="dialog-title"
                onClose={() => patientStore.toggleRequestDialog(false)}
                classes={{root: classes.dialogTitle}}
            >
            </DialogTitle>

            <DialogContent classes={{root: classes.dialogContent}}>
                <Box width={1}>
                    <Box className="wh-tw-text-xl wh-tw-font-medium wh-tw-text-black">Standard Membership includes:</Box>
                    <Box px={1.5} py={2}>
                        <Box display="flex" alignItems="center" gridGap={12}>
                            <Box><CheckIcon className="wh-tw-text-mint400" /></Box>
                            <Box>4 x Physician Visits</Box>
                        </Box>
                        <Box pt={1} display="flex" alignItems="center" gridGap={12}>
                            <Box><CheckIcon className="wh-tw-text-mint400" /></Box>
                            <Box>4 x Lab Tests</Box>
                        </Box>
                        <Box pt={1} display="flex" alignItems="center" gridGap={12}>
                            <Box><CheckIcon className="wh-tw-text-mint400" /></Box>
                            <Box>Unlimited Health Coaching </Box>
                        </Box>
                        <Box pt={1} display="flex" alignItems="center" gridGap={12}>
                            <Box><CheckIcon className="wh-tw-text-mint400" /></Box>
                            <Box>Unlimited Messaging</Box>
                        </Box>
                        <Box pt={1} display="flex" alignItems="center" gridGap={12}>
                            <Box><CheckIcon className="wh-tw-text-mint400" /></Box>
                            <Box>Member Perks & Discounts</Box>
                        </Box>
                    </Box>
                    <Box>
                        <Box p="5px" className="wh-tw-bg-mint100 wh-tw-rounded wh-tw-w-fit">
                            <Box className="wh-tw-text-xxs wh-tw-text-black wh-tw-font-semibold">50% Off</Box>
                        </Box>
                        <Box mt={0.75} display="flex" alignItems="center">
                            <Box mr={0.5} className="wh-tw-text-xs wh-tw-text-gray400 wh-tw-line-through">$395</Box>
                            <Box mr={1.5} className="wh-tw-text-base wh-tw-text-black">$197.50/mo</Box>
                            <Divider orientation="vertical" className="wh-tw-bg-gray400 wh-tw-h-4" />
                            <Box ml={1.5} mr={0.5} className="wh-tw-text-xs wh-tw-text-gray400 wh-tw-line-through">$4,345</Box>
                            <Box className="wh-tw-text-base wh-tw-text-black">$2,172.50/yr</Box>
                        </Box>
                    </Box>
                    <Divider className="wh-tw-bg-dividers wh-tw-my-4" />
                    <Box className="wh-tw-text-base wh-tw-font-medium wh-tw-text-black">Your Current Membership:</Box>
                    <Box px={0.5} py={1}>
                        <Box display="flex" alignItems="center" gridGap={8}>
                            <Box><FiberManualRecordIcon className="wh-tw-text-gray600 wh-tw-w-1.5 wh-tw-h-1.5" /></Box>
                            <Box className="wh-tw-text-sm wh-tw-gray600">1 x Physician Visit</Box>
                        </Box>
                        <Box pt={0.5} display="flex" alignItems="center" gridGap={8}>
                            <Box><FiberManualRecordIcon className="wh-tw-text-gray600 wh-tw-w-1.5 wh-tw-h-1.5" /></Box>
                            <Box className="wh-tw-text-sm wh-tw-gray600">1 x Initial Lab Test</Box>
                        </Box>
                        <Box pt={0.5} display="flex" alignItems="center" gridGap={8}>
                            <Box><FiberManualRecordIcon className="wh-tw-text-gray600 wh-tw-w-1.5 wh-tw-h-1.5" /></Box>
                            <Box className="wh-tw-text-sm wh-tw-gray600">$199 Additional Physician Visits</Box>
                        </Box>
                    </Box>
                    <Box mt={3} display="flex" justifyContent="center">
                        <WildHealthButton
                            id="standard-up-sell-request"
                            color="primary"
                            size="large"
                            borderRadius={50}
                            onClick={() => handleRequest()}
                        >
                            Request Upgrade
                        </WildHealthButton>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
}
