import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { onEmit } from "../../../common/helpers/on-emit";
import { CallbackModel } from "../../../common/models/callback.model";
import { IErrorState } from "../../../common/validation/error-state";
import { EmployeeShortModel } from "../../../employee/models/employee.models";
import { employeeService } from "../../../employee/services/employees.service";
import { employeesQuery } from "../../../employee/stores/employeesStore";
import { LocationModel, LocationType, UpdateLocationModel } from "../../models/locations.models";
import { locationsService } from "../../services/locations.service";
import { updateLocationDialogComponentValidator } from "./updateLocationDialogComponent.validator";


export interface EmployeeViewModel {
    id: number;
    firstName: string;
    lastName: string;
}

interface UpdateLocationDialogComponentState extends IErrorState {
    model: UpdateLocationModel;
    careCoordinators: EmployeeViewModel[];
    isOpen: boolean;
    callback: Function;
}

const defaultState: UpdateLocationDialogComponentState = {
    model: {
        id: 0,
        name: '',
        description: '',
        country: 'US',
        city: '',
        state: '',
        zipCode: '',
        streetAddress1: '',
        streetAddress2: '',
        careCoordinatorId: null,
        type: LocationType.Default
    },
    careCoordinators: [],
    isOpen: false,
    callback: null,
    errors: {}
}

export function useFacade(): [UpdateLocationDialogComponentState, (id: number) => void, Function, Function, Function] {
    const [state, setState] = useState(defaultState);

    const handleSelectedCareCoordinatorChange = (id: number) => {
        setState(state => ({...state, model: {...state.model, careCoordinatorId: id}}));
    }

    const handleChanges = (key: string, value: string) => {
        updateLocationDialogComponentValidator.validateAndSetState(state, setState, key, value);
        const model = state.model;
        model[key] = value;
        setState({...state, model});
    }

    const handleSubmit = () => {
        updateLocationDialogComponentValidator.validateObjectAndSetState(state, setState, state.model);
        if (!updateLocationDialogComponentValidator.stateIsValid(state)) {
            return;
        }

        state.callback(state.model);
        setState(state => ({...state, isOpen: false}));
    }

    const handleClose = () => {
        state.callback(null);
        setState(defaultState);
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<CallbackModel<LocationModel>>(locationsService.locationOnUpdate, model => {
                setState(state => ({
                    ...state,
                    isOpen: true,
                    errors: {},
                    model: {
                        id: model.data.id,
                        name: model.data.name,
                        description: model.data.description,
                        country: model.data.country,
                        city: model.data.city,
                        state: model.data.state,
                        zipCode: model.data.zipCode,
                        streetAddress1: model.data.streetAddress1,
                        streetAddress2: model.data.streetAddress2,
                        careCoordinatorId: model.data.careCoordinatorId,
                        type: model.data.type
                    },
                    callback: model.callback
                }));
            }),
            onEmit<EmployeeShortModel[]>(employeesQuery.careCoordinators$, employees => {
                const careCoordinators = employees.map(item => {
                    return {
                        id: item.id,
                        firstName: item.firstName,
                        lastName: item.lastName
                    }
                });

                setState(state => ({
                    ...state,
                    careCoordinators: careCoordinators
                }));
            })
        ];

        if (state.model.id) {
            employeeService.getActiveCareCoordinators(state.model.id);
        }

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [state.isOpen, state.model.id]);

    return [state, handleSelectedCareCoordinatorChange, handleChanges, handleSubmit, handleClose];
}