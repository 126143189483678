import { Box, Card, CardContent, CardHeader, Divider, Grid, List, useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { AuthenticatedLayoutComponent } from "../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent";
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from "../../../../models/menu.models";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { EmployeeType } from '../../../employee/models/employee.enums';
import { EmployeeShortModel } from '../../../employee/models/employee.models';
import { NoteCardComponent } from '../../components/noteCard/NoteCardComponent';
import { useFacade } from './patientNotesPage.hooks';
import { useStyles } from './patientNotesPage.styles';
import commonUseStyles from "../../../common/styles/common.styles";
import clsx from 'clsx';
import emptyNotes from "@img/placeholders/EmptyReport.png";
import { WildHealthPlaceHolderBase } from '../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolderBase';

export const PatientNotesPage: React.FC = () => {
    const [
        state,
    ] = useFacade();
    const classes = useStyles();
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    let employeeTypeMap = {};
    employeeTypeMap[EmployeeType.HealthCoach] = 'Health Coach';
    employeeTypeMap[EmployeeType.Provider] = 'Precision Provider';

    const employeeDisplayName = (employee: EmployeeShortModel) => {
        return `${employeeTypeMap[employee.type]}: ${employee.firstName} ${employee.lastName}`
    }

    const renderSmallContent = () => {
        return <>
            {
                state.groups.map((item, index) =>
                    <React.Fragment key={`note-group-item-${index}`}>
                        <Box mb={2} className={clsx(commonClasses.size16, commonClasses.textMedium, commonClasses.colorBlack)}>
                            {employeeDisplayName(item.employee)}
                        </Box>
                        <Divider className={classes.cardDivider} />
                        {item.notes.map((note, index) =>
                            <Card key={`note-card-item-${index}`} className={classes.card}>
                                <NoteCardComponent keyIndex={`note-item-${index}`} note={note} />
                            </Card>
                        )}
                    </React.Fragment>
                )
            }
        </>
    }

    const renderNotes = () => {
        if (!state.groups || !state.groups.length) {
            return isSmallScreen ? (
                <Box height="calc(100vh - 176px)">
                    <WildHealthPlaceHolderBase message="No Notes yet">
                        <img style={{ width: '100%' }}
                            src={emptyNotes}
                            alt="img" />
                    </WildHealthPlaceHolderBase>
                </Box>
            ) : <WildHealthPlaceHolder message="No Notes yet" />
        }

        return (
            <>
                {isSmallScreen ? renderSmallContent() : (
                    <>
                        {
                            state.groups.map((item, index) =>
                                <Card key={`note-card-${index}`} className={classes.card}>
                                    <CardHeader title={employeeDisplayName(item.employee)} />

                                    <Divider className={classes.cardDivider} />

                                    <CardContent>
                                        <List>
                                            {item.notes.map((note, index) => <React.Fragment key={`note-item-${index}`}> <NoteCardComponent keyIndex={`note-item-${index}`} note={note} /></React.Fragment>)}
                                        </List>
                                    </CardContent>
                                </Card>
                            )
                        }
                    </>
                )}
            </>
        )
    }

    return (
        <AuthenticatedLayoutComponent
            title={TitlesService.notesTitle}
            pageName={PagesNamesService.notes}
            selectedMenuItem={MenuItemTypes.Notes}
        >
            <Box mt={3} className={isSmallScreen && commonClasses.bgMain}>
                {isSmallScreen && (
                    <Box px={2} className={clsx(commonClasses.size24, commonClasses.textSemiBold, commonClasses.colorBlack)}>
                        Notes
                    </Box>
                )}
                {state.isLoading
                    ? <WildHealthLinearProgress />
                    : <Grid item md={6} sm={12}>
                        <Box p={isSmallScreen ? 2 : 5}>
                            {renderNotes()}
                        </Box>
                    </Grid>
                }
            </Box>
        </AuthenticatedLayoutComponent>
    )
};