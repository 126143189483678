import {GeneralValidator} from "../../../common/validation/general-validator";
import {
    rangeValidationRule,
    stringLengthValidationRule,
    checkManyQuestionValidationRule,
    notWhitespaceValidationRule
} from "../../../common/validation/general-validation-rules";

export const questionnairesResultListComponentValidator = new GeneralValidator({
    'MEDICAL_HISTORY_CONDITIONS': [checkManyQuestionValidationRule],
    'MEDICAL_HISTORY_CONDITIONS_COMMENT': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'MEDICAL_HISTORY_CONDITIONS_FAMILY': [checkManyQuestionValidationRule],
    'MEDICAL_HISTORY_CONDITIONS_FAMILY_COMMENT': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'MEDICAL_HISTORY_DIABET': [stringLengthValidationRule(0, 500, 'This field is required')],
    'MEDICAL_HISTORY_DIABET_TYPE': [stringLengthValidationRule(0, 500, 'This field is required')],
    'MEDICAL_HISTORY_CHOLESTEROL': [stringLengthValidationRule(0, 500, 'This field is required')],
    'MEDICAL_HISTORY_BLOOD_PRESSURE': [stringLengthValidationRule(0, 500, 'This field is required')],
    'CORONARY_CALCIUM_SCORE': [stringLengthValidationRule(0, 500, 'This field is required')],
    'SCORE': [rangeValidationRule(0, 100)],
    'SMOKING_HISTORY': [stringLengthValidationRule(0, 500, 'This field is required')],
    'ALCOHOL_HISTORY': [stringLengthValidationRule(0, 500, 'This field is required')],
    'MEDICATIONS': [],
    'SUPPLEMENT': [],
    'ANY_SUPPLEMENTS': [stringLengthValidationRule(0, 500, 'This field is required')],
    'SUPPLEMENTS': [],
    'DRUG_ALLERGIES': [stringLengthValidationRule(0, 500, 'This field is required')],
    'ALLERGIES': [],
    'SPECIFIC_KIND_OF_DIET': [stringLengthValidationRule(0, 500, 'This field is required')],
    'RESTRICTED_FOOD': [checkManyQuestionValidationRule],
    'RESTRICTED_FOOD_COMMENT': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'OILY_FISH': [rangeValidationRule(0, 1000)],
    'RED_MEAT': [rangeValidationRule(0, 1000)],
    'PROCESSED_MEAT': [rangeValidationRule(0, 1000)],
    'SERVINGS_VEGETABLES': [rangeValidationRule(0, 1000)],
    'SERVINGS_CARBOHYDRATES': [rangeValidationRule(0, 1000)],
    'SERVINGS_LEGUMES': [rangeValidationRule(0, 1000)],
    'PRACTICE_FEEDING': [stringLengthValidationRule(0, 500, 'This field is required')],
    'PRACTICE_FEEDING_COMMENT': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'PRIMARY_EXERCISE_GOAL': [stringLengthValidationRule(0, 500, 'This field is required')],
    'PRIMARY_EXERCISE_GOAL_COMMENT': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'AVERAGE_EXERCISE_TIME': [rangeValidationRule(0, 172)],
    'PUSH_UPS_PERFORMING': [rangeValidationRule(0, 1000)],
    'RUN_RUN': [rangeValidationRule(0, 1000)],
    'REP_BENCH_PRESS': [rangeValidationRule(0, 1000)],
    'DEADLIFT': [rangeValidationRule(0, 1000)],
    'REP_SQUAT': [rangeValidationRule(0, 1000)],
    'AVERAGE_HOURS_OF_SLEEP': [rangeValidationRule(0, 24)],
    'AVERAGE_HOURS_OF_SLEEP_COMMENT': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'SLEEP_ROUTINE': [stringLengthValidationRule(0, 500, 'This field is required')],
    'SLEEP_ROUTINE_COMMENT': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'FALL_ASLEEP_NIGHT': [stringLengthValidationRule(0, 500, 'This field is required')],
    'WAKE_UP': [stringLengthValidationRule(0, 500, 'This field is required')],
    'RESTED_READY_WAKEUP': [stringLengthValidationRule(0, 500, 'This field is required')],
    'ENERGY_THROUGHOUT_THE_DAY': [stringLengthValidationRule(0, 500, 'This field is required')],
    'TYPICAL_STRESS': [stringLengthValidationRule(0, 500, 'This field is required')],
    'STRESS_OR_PRACTICE_MINDFULNESS': [checkManyQuestionValidationRule],
    'STRESS_OR_PRACTICE_MINDFULNESS_COMMENT': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'PERFORM_YOUR_ROUTINES': [stringLengthValidationRule(0, 500, 'This field is required')],
    'PERFORM_YOUR_ROUTINES_COMMENT': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'TV_HOURS': [rangeValidationRule(0, 24)],
    'NON_TV_SCREEN': [rangeValidationRule(0, 24)],

    'YOUR_INTENTION': [stringLengthValidationRule(1, 500), notWhitespaceValidationRule()],
    'YOUR_EXERCISE_GOALS': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'PERSONAL_EXERCISE_GOAL': [stringLengthValidationRule(1, 500), notWhitespaceValidationRule()],
    'EXERCISE_ROUTINE_LOOK_LIKE': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'YOUR_NUTRITION_GOALS': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'PERSONAL_NUTRITION_GOAL': [stringLengthValidationRule(1, 500), notWhitespaceValidationRule()],
    'FUTURE_NUTRITION_PLAN': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'TYPE_OF_FOOD_DO_YOU_EAT': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'YOUR_SLEEP_GOALS': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'PERSONAL_SLEEP_GOAL': [stringLengthValidationRule(1, 500), notWhitespaceValidationRule()],
    'GOOD_AND_BAD_SLEEP_ROUTINE': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'YOUR_MINDFULNESS_GOALS': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'PERSONAL_MINDFULNESS_GOAL': [stringLengthValidationRule(1, 500), notWhitespaceValidationRule()],
    'MINDFULNESS_PRACTICE_LOOK': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'YOUR_SOCIAL_CONNECTIONS': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'PERSONAL_SOCIAL_CONNECTION_GOAL': [stringLengthValidationRule(1, 500), notWhitespaceValidationRule()],
    'DESCRIBE_SOCIAL_CONNECTIONS': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'TAKE_TIME_TO_WALK_OUTSIDE': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'TAKE_TIME_TO_WALK_OUTSIDE_COMMENT': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'HEALS_HAPPINESS_PERFORMANCE': [stringLengthValidationRule(1, 500), notWhitespaceValidationRule()],
    'HEALTH_FEELING': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'HEALS_COMMENTS': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'OVERALL_PHYSICAL_HEALTH': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'FREE_CHRONIC_PAIN': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'FREE_CHRONIC_PAIN_COMMENTS': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'GET_SICK': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'SICK_COMMENTS': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'HEALTH_PHYSICAL_FEELING': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'HEALS_PHYSICAL_COMMENTS': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'HEALTH_MORE_OR_LESS': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'HEALS_MORE_OR_LESS_COMMENTS': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'HAPPINESS_ENJOY_LIFE': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'HAPPINESS_ENJOY_LIFE_COMMENT': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'HAPPINESS_CONNECTIONS': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'HAPPINESS_CONNECTIONS_COMMENT': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'HAPPINESS_OPTIMISTIC_PESSIMISTIC': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'HAPPINESS_OPTIMISTIC_PESSIMISTIC_COMMENT': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'HAPPINESS_COACH_THERAPIST': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'HAPPINESS_COACH_THERAPIST_COMMENT': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'DEPRESSION_BORDERED': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'DEPRESSION_BORDERED_COMMENT': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'HAPPINESS_INTO_MY_80': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'HAPPINESS_INTO_MY_80_COMMENT': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'HAPPINESS_OVERALL': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'HAPPINESS_OVERALL_COMMENT': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'PERFORMANCE_METRICS': [checkManyQuestionValidationRule],
    'PERFORMANCE_METRICS_EXPLAIN': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'PERFORMANCE_ACTIVITY_IMPORTANT': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'PERFORMANCE_ACTIVITY_IMPORTANT_COMMENT': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'PERFORMANCE_IMPROVEMENT': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'PERFORMANCE_IMPROVEMENT_COMMENT': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'PERFORMANCE_CONFIDENT_80S': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'PERFORMANCE_CONFIDENT_80S_COMMENT': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'PERFORMANCE_OVERALL': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
    'PERFORMANCE_OVERALL_COMMENT': [stringLengthValidationRule(0, 500), notWhitespaceValidationRule()],
});