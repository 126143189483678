import { Box,  } from '@material-ui/core';
import React, { useState } from 'react';
import commonUseStyles from '../../../common/styles/common.styles';
import { useStyles } from "./MessageEventComponent.styles";
import { ChronologicalEventModel, ChronologicalEventType } from '../../models/patient.model';
import { WildHealthLinkButton } from '../../../common/components/WildHealthLinkButton';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface MessageEventComponentProps {
    item: ChronologicalEventModel
}

export const MessageEventComponent: React.FC<MessageEventComponentProps> = (props: MessageEventComponentProps) => {
    const {
        item
    } = props;

    const [state, setState] = useState({ seeMore: false });

    const classes = useStyles();
    const commonClasses = commonUseStyles();
    
    if (!item.body || item.body?.length < 68) {
        return  <Box className={commonClasses.size14}>{item.body}</Box>
    }

    return (
        <>
        <Box className={commonClasses.size14}>{state.seeMore ? item.body : `${item.body.slice(0, 68)}...`}</Box>
        <WildHealthLinkButton
            onClick={() =>  setState(state => ({ ...state, seeMore: !state.seeMore }))}
            content={
                <Box display="flex" alignItems='center'>
                    <span className={classes.action}> {state.seeMore ? 'See Less' : 'See More'} </span>
                    {state.seeMore ? <ExpandLessIcon className={classes.expandIcon} /> : <ExpandMoreIcon className={classes.expandIcon} />}
                </Box>
            }
            id={`chronological-action-${ChronologicalEventType.LabsOrdered}-${item.payload?.orderId}`}
        />
    </>
    );
}