import { Box, Button } from "@material-ui/core";
import moment from "moment";
import React, { useState } from "react";
import Calendar from "react-calendar";
import { useStyles } from "./SelectDataDialogComponent.styles";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";

interface SelectDataRangePopoverComponentProps {
  handleDateChanges: Function;
}

interface SelectDataRangePopoverComponentState {
  activeStartDate: Date;
}

export const IOSCalender: React.FC<SelectDataRangePopoverComponentProps> = (
  props: SelectDataRangePopoverComponentProps
) => {
  const { handleDateChanges } = props;

  const classes = useStyles();
  const [state, setState] = useState({
    activeStartDate: new Date(),
  } as SelectDataRangePopoverComponentState);

  const handleShowPreviousMonthView = () => {
    state.activeStartDate.setMonth(state.activeStartDate.getMonth() - 1);
    setState((state) => ({ ...state }));
  };

  const handleShowNextMonthView = () => {
    state.activeStartDate.setMonth(state.activeStartDate.getMonth() + 1);
    setState((state) => ({ ...state }));
  };

  const handleCanMoveNext = () => {
    const today = new Date();
    return !(
      state.activeStartDate.getFullYear() === today.getFullYear() &&
      state.activeStartDate.getMonth() === today.getMonth()
    );
  };

  return (
    <Box display='flex'>
      <Box>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Box>
            <span className={classes.dateTitle}>
              {moment(state.activeStartDate).format("MMMM yyyy")}
            </span>
          </Box>
          <Box>
            <Button
              id='select-data-range-prev-month'
              onClick={handleShowPreviousMonthView}
              className={classes.navigateButton}
            >
              <ArrowBackIosRoundedIcon className={classes.navigationIcon} />
            </Button>
            <Button
              id='select-data-range-next-month'
              disabled={!handleCanMoveNext()}
              className={classes.navigateButton}
              onClick={handleShowNextMonthView}
            >
              <ArrowForwardIosRoundedIcon className={classes.navigationIcon} />
            </Button>
          </Box>
        </Box>
        <Box maxWidth='400px'>
          <Calendar
            onChange={(momentDate) =>
              handleDateChanges(momentDate && moment(momentDate).toDate())
            }
            activeStartDate={state.activeStartDate}
            calendarType='US'
            showNavigation={false}
            maxDate={new Date()}
            className={classes.calander}
          />
        </Box>
      </Box>
    </Box>
  );
};
