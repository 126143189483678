import Axios from 'axios-observable';
import moment from 'moment';
import { Observable } from "rxjs";
import { authHeader } from "../../common/helpers/auth-header";
import { ResetPasswordModel } from "../../common/models/user.models";
import { PaginationModel } from "../../common/pagination/models/pagination-models";
import { snackService } from "../../common/snack/state";
import {
    CreatedPatientModel,
    RegisterPatientModel,
    PatientModel,
    PatientOnBoardingStatus,
    PatientShortModel,
    SendPatientToWaitListModel,
    SubmitPatientModel,
    TransferPatientToLocationModel,
    UpdatePatientModel,
    UpdatePatientStatusesModel,
    PatientStartRegistrationModel,
    FellowshipNoteModel,
    MyPatientModel,
    MyPatientsFilterModel,
    ChronologicalEventModel,
    PatientNpsScoreModel,
    PatientTaskModel,
    EngagementPatientModel,
    TodoPatientJourneyTaskModel,
    CompletedPatientJourneyTaskModel,
    PatientJourneyModel,
    PaymentPlanHistoryModel,
    CallToActionModel,
    ReactOnCallToActionModel,
    HealthCoachEngagementTaskModel,
    RegisterPreauthorizePatientModel,
} from "../models/patient.model";
import { patientStore, PatientStore } from "../stores/patientsStore";
import { authService } from "../../auth/services/auth.service";
import { DataSpecificationsEnum } from "../../common/constants/data-specifications";
import { OrderType } from "../../orders/models/orders.models";
import { patientProfileStore } from '../../employee/stores/patientProfileStore';
const FileDownload = require('js-file-download');

/**
 * Provides method for working with patients
 */
export class PatientsService {
    private url = `${process.env.REACT_APP_API_URL}patients`;
    private urlEngagement = `${process.env.REACT_APP_API_URL}Engagement`;
    private urlPatientJourneyTasks = `${process.env.REACT_APP_API_URL}PatientJourneyTasks`;
    private urlCallToActions = `${process.env.REACT_APP_API_URL}CallToActions`;

    constructor(private patientStore: PatientStore) {
    }

    /**
     * Returns new patients
     */
    getNewPatients(
        status: PatientOnBoardingStatus,
        query: string,
        paymentPeriods: number[] = [],
        orderTypes: OrderType[] = [],
        state: string[] = [],

    ) {
        const config = { headers: authHeader() };

        return new Observable<PatientShortModel[]>(observer => {

            const periodsIds = paymentPeriods && paymentPeriods.length
                ? `&${paymentPeriods.map(id => `periodsIds=${id}`).join('&')}`
                : '';

            const orderTypesQuery = orderTypes && orderTypes.length
                ? `&${orderTypes.map(type => `orderTypes=${type}`).join('&')}`
                : '';

            const stateAbbreviations = state && state.length
                ? `&${state.map(name => `stateAbbreviations=${name}`).join('&')}`
                : '';

            Axios.get<PaginationModel<PatientShortModel>>(`${this.url}/new?` +
                `onBoardingStatus=${status}&` +
                `searchQuery=${query}&` +
                `${periodsIds}` +
                `${orderTypesQuery}&` +
                `${stateAbbreviations}&`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.patientStore.update({ patients: response.data.data });
                        observer.next(response.data.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Returns patients assigned to employee
     * @param employeeId
     */
    getMyPatients(model: MyPatientsFilterModel): Observable<MyPatientModel[]> {
        const config = { headers: authHeader() };

        return new Observable<MyPatientModel[]>(observer => {
            Axios.post<Array<MyPatientModel>>(`${this.url}/my`, model, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.patientStore.update({ myPatients: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Returns all patients
     */
    getAll(
        paymentPeriods: number[] = [],
        coachesIds: number[] = [],
        providersIds: number[] = [],
        pods: number[] = [],
        addOns: number[] = [],
        state: string[] = [],
        query: string,
        skip: number,
        take: number,
        sortingSource: string = null,
        sortingDirection: string = null,
        ignoreWithoutCompletedRegitration: boolean = true
    ): Observable<PatientShortModel[]> {
        const config = { headers: authHeader() };
        return new Observable<PatientShortModel[]>(observer => {
            const coachesIdsQuery = coachesIds && coachesIds.length
                ? `&${coachesIds.map(id => `coachesIds=${id}`).join('&')}`
                : '';

            const providersIdsQuery = providersIds && providersIds.length
                ? `&${providersIds.map(id => `providersIds=${id}`).join('&')}`
                : '';

            const periodsIds = paymentPeriods && paymentPeriods.length
                ? `&${paymentPeriods.map(id => `periodsIds=${id}`).join('&')}`
                : '';

            const locationsIds = pods && pods.length
                ? `&${pods.map(id => `locationsIds=${id}`).join('&')}`
                : '';

            const orderTypes = addOns && addOns.length
                ? `&${addOns.map(type => `orderTypes=${type}`).join('&')}`
                : '';

            const stateAbbreviations = state && state.length
                ? `&${state.map(name => `stateAbbreviations=${name}`).join('&')}`
                : '';

            Axios.get<PaginationModel<PatientShortModel>>(`${this.url}/all?` +
                `searchQuery=${query}&` +
                `skip=${skip}&` +
                `take=${take}` +
                `${coachesIdsQuery}` +
                `${providersIdsQuery}` +
                `${periodsIds}` +
                `${locationsIds}&` +
                `${orderTypes}&` +
                `${stateAbbreviations}&` +
                `ignoreWithoutCompletedRegitration=${ignoreWithoutCompletedRegitration}&` +
                `sortingSource=${sortingSource}&` +
                `sortingDirection=${sortingDirection}`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.patientStore.update({
                            patients: response.data.data,
                            patientsTotalCount: response.data.totalCount
                        });
                        observer.next(response.data.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public get(id: number, spec: DataSpecificationsEnum): void {
        const config = { headers: authHeader() };

        Axios.get(`${this.url}/${id}?spec=${spec}`, config)
            .pipe()
            .subscribe(
                (response) => {
                    this.patientStore.update({ targetPatient: response.data });
                    this.patientStore.updateCoachPatients(response.data);
                },
                error => {
                    snackService.commonErrorHandler(error);
                }
            );
    }

    public getPatientForDashboard(id: number, spec: DataSpecificationsEnum): Observable<any> {
        const config = { headers: authHeader() };
        return new Observable(observer => {
        Axios.get(`${this.url}/${id}?spec=${spec}`, config)
            .pipe()
            .subscribe(
                (response) => {
                    this.patientStore.update({ targetPatient: response.data });
                    this.patientStore.updateCoachPatients(response.data);
                    observer.next(response.data);
                    observer.complete();
                },
                error => {
                    snackService.commonErrorHandler(error);
                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }

    public getMyInfo(spec: DataSpecificationsEnum): Observable<any> {
        const config = { headers: authHeader() };
        return new Observable(observer => {
            Axios.get(`${this.url}/GetMyInfo?spec=${spec}`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.patientStore.update({ targetPatient: response.data });

                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Registers new patient
     * @param model
     */
    public register(model: RegisterPatientModel): Observable<CreatedPatientModel> {
        return new Observable(observer => {
            Axios.post<CreatedPatientModel>(`${this.url}/register`, model, { timeout: 90000 })
                .pipe()
                .subscribe(
                    (response) => {
                        if (response.data.authentication) {
                            authService.authorize(response.data.authentication);
                        }

                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Registers new patient
     * @param model
     */
    public registerPreauthorize(model: RegisterPreauthorizePatientModel): Observable<CreatedPatientModel> {
        return new Observable(observer => {
            Axios.post<CreatedPatientModel>(`${this.url}/register/preauthorize`, model, { timeout: 90000 })
                .pipe()
                .subscribe(
                    (response) => {
                        if (response.data.authentication) {
                            authService.authorize(response.data.authentication);
                        }

                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Sends patient to waitlist
     * @param model
     */
    public sendPatientToWaitList(model: SendPatientToWaitListModel): Observable<void> {
        return new Observable(observer => {
            Axios.post(`${this.url}/waitList`, model)
                .pipe()
                .subscribe(
                    () => {
                        snackService.success('Request was successfully sent to waitlist!');
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * transfer patient to location
     * @param model
     */
    public transferPatientToLocation(model: TransferPatientToLocationModel): Observable<PatientModel> {
        const config = { headers: authHeader() };

        return new Observable(observer => {
            Axios.put<PatientModel>(`${this.url}/transfer`, model, config)
                .pipe()
                .subscribe(
                    (response) => {
                        snackService.success('Patient was successfully transferred to location!');
                        this.patientStore.update({ targetPatient: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Submits user
     * @param model
     */
    public submit(model: SubmitPatientModel): Observable<PatientModel> {
        const config = { headers: authHeader() };

        return new Observable(observer => {
            Axios.post(`${this.url}/submit`, model, config)
                .pipe()
                .subscribe(
                    (response) => {
                        patientStore.updateShortPatientModel(response.data);
                        snackService.success('Patient successfully submitted!');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Updates user
     * @param model
     */
    public update(model: UpdatePatientModel): Observable<PatientModel> {
        const config = { headers: authHeader() };

        return new Observable(observer => {
            Axios.post(`${this.url}/update`, model, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.patientStore.update({ targetPatient: response.data });
                        snackService.success('Patient successfully updated!');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public updateStatuses(model: UpdatePatientStatusesModel): Observable<PatientModel> {
        const config = { headers: authHeader() };

        return new Observable(observer => {
            Axios.post(`${this.url}/UpdateStatuses`, model, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.patientStore.updatePatientStatuses(response.data);
                        snackService.success('Patient statuses successfully updated!');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public resetPatientPassword(patientId: number, model: ResetPasswordModel): void {
        const config = { headers: authHeader() };

        Axios.put(`${this.url}/${patientId}/resetPassword`, model, config)
            .pipe()
            .subscribe(
                () => {
                    snackService.success('Patient password has been updated.');
                },
                error => {
                    snackService.commonErrorHandler(error);
                }
            );
    }

    public startRegistration(patients: PatientStartRegistrationModel[]): Observable<any> {
        const config = { headers: authHeader() };

        return new Observable(observer => {
            Axios.post(`${this.url}/startRegistration`, patients, config)
                .pipe()
                .subscribe(
                    () => {
                        snackService.success('Patients have been created.');
                        snackService.postNativeDeviceEvent('Patients have been created.');
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        })
    }

    public getPracticumPatients(start: Date, end: Date) {
        const config = { headers: authHeader() };

        Axios.get(`${this.url}/Practicum?start=${moment(start).toISOString()}&end=${moment(end).toISOString()}`, config)
            .pipe()
            .subscribe(
                (response) => {
                    this.patientStore.update({ practicumPatients: response.data });
                },
                error => {
                    snackService.commonErrorHandler(error);
                }
            );
    }

    public getPracticumPatientsByRoster(id: number) {
        const config = { headers: authHeader() };

        Axios.get(`${this.url}/PracticumByRoster?rosterId=${id}`, config)
            .pipe()
            .subscribe(
                (response) => {
                    this.patientStore.update({ practicumPatients: response.data });
                },
                error => {
                    snackService.commonErrorHandler(error);
                }
            );
    }

    public getEmployeePracticumPatients(employeeId: number) {
        const config = { headers: authHeader() };

        Axios.get(`${this.url}/Practicum/${employeeId}`, config)
            .pipe()
            .subscribe(
                (response) => {
                    this.patientStore.update({ practicumPatients: response.data });
                },
                error => {
                    snackService.commonErrorHandler(error);
                }
            );
    }

    public getPatientTimeZone(patientId: number | null = null) {
        const config = { headers: authHeader() };

        const url = patientId
            ? `${this.url}/TimeZone?patientId=${patientId}`
            : `${this.url}/TimeZone`;

        Axios.get(url, config)
            .pipe()
            .subscribe(
                (response) => {
                    this.patientStore.update({ patientTimeZone: response.data });
                },
                error => {
                    snackService.commonErrorHandler(error);
                }
            );
    }

    public refreshPatientsTimezone(timezone: string): Observable<any> {
        const config = { headers: authHeader() };

        return new Observable(observer => {
            Axios.post<any>(`${this.url}/SetTimezone?timezone=${timezone}`, null, config)
                .pipe()
                .subscribe(
                    () => {
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        });
    }

    public updateFellowshipNote(model: FellowshipNoteModel): void {
        const config = { headers: authHeader() };

        Axios.put(`${this.url}/FellowshipNote`, model, config)
            .pipe()
            .subscribe(
                () => { },
                error => {
                    snackService.commonErrorHandler(error);

                }
            );
    }

    public getPatientTimeline(patientId: number): Observable<ChronologicalEventModel[]> {
        const config = { headers: authHeader() };
        return new Observable<ChronologicalEventModel[]>(observer => {
            Axios.get(`${this.url}/${patientId}/timeline`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.patientStore.update({ chronologicalEvents: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public getNpsScore(patientId: number):  Observable<PatientNpsScoreModel[]> {
        const config = { headers: authHeader() };
        return new Observable<PatientNpsScoreModel[]>(observer => {
            Axios.get(`${this.url}/${patientId}/nps`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        patientProfileStore.update({ npsScores: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }
    
    /**
     * Returns patient task to employee
     * @param employeeId
     */
    getPatientTasksByEmployee(patientId: number): Observable<PatientTaskModel> {
        const config = { headers: authHeader() };

        return new Observable<PatientTaskModel>(observer => {
            Axios.get<PatientTaskModel>(`${this.urlEngagement}/patient/${patientId}`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        const result = response.data ? [response.data] : []
                        this.patientStore.update({ patientTasks:  result});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
    * Returns patient task to patient
    */
    getPatientTasks(): Observable<PatientTaskModel> {
        const config = { headers: authHeader() };

        return new Observable<PatientTaskModel>(observer => {
            Axios.get<PatientTaskModel>(`${this.urlEngagement}/patient`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        const result = response.data ? [response.data] : []
                        this.patientStore.update({ patientTasks: result });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    
    /**
    * Returns Patients Requiring Engagement
    */
    getEngagementPatient(): Observable<EngagementPatientModel[]> {
        const config = { headers: authHeader() };

        return new Observable<EngagementPatientModel[]>(observer => {
            Axios.get<EngagementPatientModel[]>(`${this.urlEngagement}/careCoordinator`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.patientStore.update({ patientsEngagement: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    getEngagementPatientByHC(): Observable<HealthCoachEngagementTaskModel[]> {
        const config = { headers: authHeader() };

        return new Observable<HealthCoachEngagementTaskModel[]>(observer => {
            Axios.get<HealthCoachEngagementTaskModel[]>(`${this.urlEngagement}/healthCoach`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.patientStore.update({ healthCoachEngagements: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    findMoreEngagementPatientByHC(): Observable<HealthCoachEngagementTaskModel[]> {
        const config = { headers: authHeader() };

        return new Observable<HealthCoachEngagementTaskModel[]>(observer => {
            Axios.get<HealthCoachEngagementTaskModel[]>(`${this.urlEngagement}/healthCoach/findMore`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.patientStore.update({ healthCoachEngagements: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public completeHealthCoachTask(id: number): Observable<HealthCoachEngagementTaskModel> {
        const config = { headers: authHeader() };

        return new Observable(observer => {
            Axios.put(`${this.urlEngagement}/healthCoach/${id}/complete`, {}, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.patientStore.removeCompletedEngagementTask(id);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                    }
                );
        })
    }

    public getPatientJourneyTasks(): Observable<PatientJourneyModel> {
        const config = { headers: authHeader() };
        return new Observable<PatientJourneyModel>(observer => {
            Axios.get(`${this.urlPatientJourneyTasks}`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.patientStore.update({ patientJourneyTasks: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public getPatientJourneyTasksByEmployee(patientId: number): Observable<PatientJourneyModel> {
        const config = { headers: authHeader() };
        return new Observable<PatientJourneyModel>(observer => {
            Axios.get(`${this.urlPatientJourneyTasks}/${patientId}`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.patientStore.update({ patientJourneyTasks: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public getTodoPatientJourneyTasks(count: number = 4): Observable<TodoPatientJourneyTaskModel[]> {
        const config = { headers: authHeader() };
        return new Observable<TodoPatientJourneyTaskModel[]>(observer => {
            Axios.get(`${this.urlPatientJourneyTasks}/todo/${count}`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.patientStore.update({ todoPatientJourneyTasks: response.data, });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public getTodoPatientJourneyTasksByEmployee(patientId: number, count: number = 4): Observable<TodoPatientJourneyTaskModel[]> {
        const config = { headers: authHeader() };
        return new Observable<TodoPatientJourneyTaskModel[]>(observer => {
            Axios.get(`${this.urlPatientJourneyTasks}/${patientId}/todo/${count}`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.patientStore.update({ todoPatientJourneyTasks: response.data, });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public completePatientJourneyTask(id: number): Observable<CompletedPatientJourneyTaskModel> {
        const config = { headers: authHeader() };

        return new Observable(observer => {
            Axios.put(`${this.urlPatientJourneyTasks}/${id}/complete`, {}, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.patientStore.updateCompletedJourneyTasks(response.data);
                        snackService.success("Task Completed!")
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                    }
                );
        })
    }

    public dismissPatientJourneyTask(id: number): Observable<CompletedPatientJourneyTaskModel> {
        const config = { headers: authHeader() };

        return new Observable(observer => {
            Axios.put(`${this.urlPatientJourneyTasks}/${id}/dismiss`, {}, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.patientStore.updateCompletedJourneyTasks(response.data);
                        snackService.success("Task Archived!")
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                    }
                );
        })
    }

    public undoPatientJourneyTask(id: number): Observable<CompletedPatientJourneyTaskModel> {
        const config = { headers: authHeader() };

        return new Observable(observer => {
            Axios.put(`${this.urlPatientJourneyTasks}/${id}/undo`, {}, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.patientStore.updateCompletedJourneyTasks(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                    }
                );
        })
    }

    public downloadFileForTask(id: number, name: string): Observable<any> {
        const url = `${this.urlPatientJourneyTasks}/${id}/asset`;
        return new Observable(observer => {

            Axios.get(url, {
                headers: authHeader(),
                responseType: 'blob',
            })
                .pipe()
                .subscribe(
                    (response) => {
                        FileDownload(response.data, decodeURIComponent(name));
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public getPaymentPlanUpdateHistory(patientId: number):  Observable<PaymentPlanHistoryModel[]> {
        const config = { headers: authHeader() };
        return new Observable<PaymentPlanHistoryModel[]>(observer => {
            Axios.get(`${this.url}/${patientId}/paymentPlanUpdates`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        patientProfileStore.update({ paymentPlanHistories: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public getActiveCallToActions():  Observable<CallToActionModel[]> {
        const config = { headers: authHeader() };
        return new Observable<CallToActionModel[]>(observer => {
            Axios.get(`${this.urlCallToActions}/Active`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.patientStore.update({ callToActions: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public requestStandardUpsell(request: ReactOnCallToActionModel): Observable<CallToActionModel> {
        const config = { headers: authHeader() };

        return new Observable<CallToActionModel>(observer => {
            Axios.post(`${this.urlCallToActions}/React`, request, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.patientStore.updateCallToActions(response.data);
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        })
    }
}

export const patientsService = new PatientsService(patientStore);
