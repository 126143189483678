import React from "react";
import {GoalCategory, GoalModel} from "../../../../notes/models/notes.models";
import {useStyles} from "./healthPlanItemComponent.style";
import {Box} from "@material-ui/core";
import {goalCategoryNames} from "../../../../notes/constants/goalCategoryNames";
import StarIcon from "@material-ui/icons/Star";
import moment from "moment";
import {healthGoalIcons} from "../constants/healthGoalIcons";
import {healthGoalImages} from "../constants/healthGoalImages";

export interface HealthPlanItemComponentProps {
    goal: GoalModel;
    onClick: Function;
}

export const HealthPlanItemComponent: React.FC<HealthPlanItemComponentProps> = (props: HealthPlanItemComponentProps) => {
    const goal = props.goal;
    const onClick = props.onClick;
    const classes = useStyles();

    const renderCategory = (category: GoalCategory) => {
        return (
            <Box className={classes.category} display="flex" alignItems='center'>
                <Box>
                    {healthGoalIcons.get(category)}
                </Box>
                <Box ml={0.5} mt={0.5}>
                    {goalCategoryNames.get(category)}
                </Box>
            </Box>
        )
    }

    return (
        <Box className={classes.root} display="flex" onClick={() => onClick()}>
            <Box className={classes.imageContainer} style={{
                backgroundImage: `url(${healthGoalImages.get(goal.category)})`
            }}>

            </Box>
            <Box className={classes.container} flexGrow={1}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box>
                        {
                            renderCategory(goal.category)
                        }
                    </Box>
                    <Box className={classes.date} display="flex" alignItems="center">
                        {
                            goal.date &&
                            <Box>
                                {moment(goal.date).format('MMMM YYYY')}
                            </Box>
                        }
                        {
                            goal.isTopGoal &&
                            <Box ml={1}>
                                <StarIcon/>
                            </Box>
                        }
                    </Box>
                </Box>
                <Box className={classes.title}>
                    {goal.name.slice(0, 50)}
                    {goal.name.length > 50 && <span>...</span>}
                </Box>
            </Box>
        </Box>
    )
}