import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { onEmit } from "../../../common/helpers/on-emit";
import { DocumentSourceUploadModel, DocumentSourceTypeModel, AssetType } from '../../models/documentSource.model';
import { kbDocumentsService } from '../../services/kbDocuments.service';
import { IErrorState } from '../../../common/validation/error-state';
import { uploadDocumentSourceValidator } from "./uploadDocumentSource.validator";
import { documentsQuery } from "../../../inputs/stores/documentsStore";
import { GeneralValidator } from "../../../common/validation/general-validator";

export interface UploadDocumentSourceDialogState extends IErrorState  {
    isOpen: boolean;
    types: DocumentSourceTypeModel[];
    uploadDocumentSource: DocumentSourceUploadModel;
    handleUpload: Function;
}

const initParam = {
    file: null,
    documentSourceTypeId: 0,
    name: '',
    url: '',
    tags: [],
    personaIds: []
}

export function useFacade(): [
    UploadDocumentSourceDialogState,
    Function,
    Function,
    Function,
    (field: string, value: any) => void
] {
    const [state, setState] = useState({
        isOpen: false,
        types: [],
        uploadDocumentSource: {
            file: null,
            documentSourceTypeId: 0,
            name: '',
            url: '',
            tags: [],
            personaIds: []
        },
        errors: {}
    } as UploadDocumentSourceDialogState);

    const setDefaultState = () => {
        setState(state => ({
            ...state,
            isOpen: false,
            uploadDocumentSource: Object.assign({}, initParam),
            handleUpload: null,
            errors: {}
        }))
    }

    const handleFileUpload = (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        setState(state => ({...state, uploadDocumentSource: {...state.uploadDocumentSource, file }, errors: GeneralValidator.removeError(state, 'file')}))
    }

    const handleChanges = (field: string, value: any) => {
        uploadDocumentSourceValidator.validateAndSetState(state, setState, field, value);
  
        const params = state.uploadDocumentSource;
        
        if (field === 'tags') {
            const index = params.tags.findIndex(x => x === value);
            index > -1 ? params.tags.splice(index, 1) : params.tags.push(value);
        } else {
            if (field === 'url') {
                setState(state => ({
                    ...state,
                    errors: GeneralValidator.removeError(state, 'url')
                }));
            }
            params[field] = value;
        }
    
        setState(state => ({...state, uploadDocumentSource: Object.assign({}, params)}))
    }

    const checkAssetType = (): boolean => {
        const type = state.types.find(x => x.id === state.uploadDocumentSource.documentSourceTypeId)

        if (type && type.assetType === AssetType.File) {
            setState(state => ({
                ...state,
                errors: GeneralValidator.removeError(state, 'url')
            }));
            if (!state.uploadDocumentSource.file) {
                setState(state => ({
                    ...state,
                    errors: GeneralValidator.addError(state, 'file', 'Please select a file.')
                }));
        
                return false;
            } else {
                setState(state => ({
                    ...state,
                    errors: GeneralValidator.removeError(state, 'file')
                }));

                return true;
            }
        }

        if (type && type.assetType === AssetType.Url) {
            setState(state => ({
                ...state,
                errors: GeneralValidator.removeError(state, 'file')
            }));
            if (!state.uploadDocumentSource.url) {
                setState(state => ({
                    ...state,
                    errors: GeneralValidator.addError(state, 'url', 'This field is required.')
                }));
        
                return false;
            } else {
                setState(state => ({
                    ...state,
                    errors: GeneralValidator.removeError(state, 'url')
                }));

                return true;
            }
        }

        return false;
    }

    const handleSubmit = () => {
        uploadDocumentSourceValidator.validateObjectAndSetState(state, setState, state.uploadDocumentSource);
        if (!uploadDocumentSourceValidator.stateIsValid(state)) {
            return;
        }

        if (!checkAssetType()) {
            return;
        }

        setState(state => ({...state, isOpen: false}))
        state.handleUpload?.(state.uploadDocumentSource);
        setDefaultState();
    }

    const handleClose = () => {
        state.handleUpload?.(null);
        setDefaultState();
    }

    kbDocumentsService.onUpload.subscribe((callbackModel) => {
        setState(state => ({
            ...state,
            isOpen: true,
            handleUpload: callbackModel.callback
        }))
    })

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<DocumentSourceTypeModel[]>(documentsQuery.documentSourceTypes$, types => {
                setState(state => ({
                    ...state,
                    types,
                }));
            }),
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [
        state,
        handleFileUpload,
        handleSubmit,
        handleClose,
        handleChanges
    ];
}