import { Box, Button, ButtonGroup } from '@material-ui/core';
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import React from 'react';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { SelectShortcutComponent } from '../../../healthReport/components/selectShortcutComponent/SelectShortcutComponent';
import { useStyles } from './createNote.styles';

export interface CreateNoteProps {
    isLoading: boolean;
    noteName: string;
    children: JSX.Element | JSX.Element[];
    handleGoBack: (boolean?) => void;
    goBackTitle: string;
    titleTextBox?: JSX.Element;
}

export const CreateNote: React.FC<CreateNoteProps> = (props: CreateNoteProps) => {
    const {
        isLoading,
        noteName,
        children,
        handleGoBack,
        goBackTitle,
        titleTextBox
    } = props;

    const classes = useStyles();

    if (isLoading) {
        return (
            <WildHealthLinearProgress />
        );
    }

    return (
        <>
            <Box display="flex" alignItems="center">
                <ButtonGroup
                    size="large"
                    variant="text"
                    color="default"
                    aria-label="large outlined primary button group"
                >
                    <Button
                        id="create-note-go-back"
                        onClick={() => handleGoBack()}
                        startIcon={<ArrowBackIosRoundedIcon />}
                    >
                        {goBackTitle}
                    </Button>
                    <Button disabled className={classes.title}>
                        {noteName}
                    </Button>
                </ButtonGroup>
                {titleTextBox}
            </Box>

            <Box>
                {children}

                <SelectShortcutComponent />
            </Box>
        </>
    );
}
