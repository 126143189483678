import {useEffect, useState} from "react";
import {Subscription} from "rxjs";
import {onEmit} from "../../../common/helpers/on-emit";
import {CallbackModel} from "../../../common/models/callback.model";
import {PaymentPlanModel} from "../../models/paymentPlan.models";
import {paymentPlansService} from "../../services/paymentPlans.service";

interface SelectTrialPaymentPlanComponentState {
    plan: PaymentPlanModel;
    isOpen: boolean;
    callback: Function;
}

const defaultState: SelectTrialPaymentPlanComponentState = {
    plan: null,
    isOpen: false,
    callback: null
}

export function useFacade(): [SelectTrialPaymentPlanComponentState, Function, Function] {
    const [state, setState] = useState(defaultState);

    const handleSelect = () => {
        state.callback(state.plan);
        setState(defaultState);
    }

    const handleClose = () => {
        state.callback(null);
        setState(defaultState);
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<CallbackModel<PaymentPlanModel>>(paymentPlansService.trialOrderOnShow, model => {
                setState(state => ({
                    ...state,
                    isOpen: true,
                    plan: model.data,
                    callback: model.callback
                }));
            })
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [state, handleSelect, handleClose];
}