import { createStyles, makeStyles } from "@material-ui/core";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles(() =>
    createStyles({
        reviewedBy: {
            background: colors.gray3,
            color: colors.gray1,
            borderRadius: '2px',
            padding: '5px'
        },
        notificationToolTipText: {
            lineHeight: 1.5,
            fontFamily: 'Be Vietnam Pro',
            fontWeight: 400,
            fontSize: 14,
            color: colors.white,
        },
        arrow: {
            color: colors.lightBlack,
        },
        customWidth: {
            maxWidth: 'none',
            backgroundColor: colors.lightBlack,
            padding: 16,
            boxShadow: ` 0px 4px 9px rgba(168, 173, 198, 0.15)`,
        },
        tooltipPlacement: {
            margin: '8px 0',
        }
    }),
);
