import { GeneralValidator } from "../../../common/validation/general-validator";
import {
  notWhitespaceValidationRule,
  requiredValidationRule,
  functionValidationRule,
  emailValidationRule,
} from "../../../common/validation/general-validation-rules";

export const manageApiResponseComponentValidator = new GeneralValidator({
  'userId': [requiredValidationRule()],
  'vendor': [requiredValidationRule()],
  'vendorId': [notWhitespaceValidationRule(), requiredValidationRule()],
  'practiceId': [notWhitespaceValidationRule(),requiredValidationRule()],
  'patientId': [notWhitespaceValidationRule(),requiredValidationRule()],
  'employeeId': [notWhitespaceValidationRule(),requiredValidationRule()],
  'subscriptionId':[notWhitespaceValidationRule(),requiredValidationRule()],
  'priceId' : [notWhitespaceValidationRule(),requiredValidationRule()],
  'paymentPlanId' : [notWhitespaceValidationRule(),requiredValidationRule()],
  'selectedStates': [functionValidationRule((value) => {
    return value;
  }, "State should be selected.")],
  'name': [notWhitespaceValidationRule(), requiredValidationRule()],
  'displayName': [notWhitespaceValidationRule(), requiredValidationRule()],
  'title': [notWhitespaceValidationRule(), requiredValidationRule()],
  'paymentPlanTemplateId': [requiredValidationRule()],
  'periodInMonths': [requiredValidationRule()],
  'stripeProductId': [notWhitespaceValidationRule(), requiredValidationRule()],
  'desiredId': [requiredValidationRule()],
  'email': [emailValidationRule],
});