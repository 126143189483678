import React from 'react';
import './QuestionComponent.scss';
import { Box, Radio, FormControl, FormControlLabel, RadioGroup, FormHelperText } from "@material-ui/core";
import { QuestionComponentProps } from "./QuestionComponentProps";

export const QuestionCheckOneComponent: React.FC<QuestionComponentProps> = (props: QuestionComponentProps) => {
    const {
        question,
        answer,
        handleChanges,
        error
    } = props;

    const selected: string = answer && answer.length ? answer : '';

    return (
        <>
            <Box className={"check-question"}>
                <FormControl
                    error={!!error}
                    color='primary'
                    component="fieldset">
                    <FormHelperText>{error}</FormHelperText>
                    <RadioGroup
                        aria-label={question.name}
                        name={question.name}
                        row={question.variants.length <= 4}
                        onChange={(v) => handleChanges(v.target.name)}>
                        {
                            question.variants.map((variant, i) => {
                                return <FormControlLabel
                                    key={i}
                                    control={
                                        <Radio
                                            checked={variant === selected}
                                            size="small"
                                            name={variant}
                                            key={variant}
                                        />
                                    }
                                    label={variant}
                                />
                            })
                        }
                    </RadioGroup>
                </FormControl>
            </Box>
        </>
    )
}