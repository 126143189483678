import { Store, StoreConfig } from '@datorama/akita';
import {AttachmentModel} from "../../common/models/attachment.models";
import {CoverageModel, VerificationsHistoryModel, InsuranceModel, InsuranceConfiguration} from "../models/insurance.models";
import { CommentModel } from '../../comments/models/comment.models';


/**
 * Represents Insurance state
 */
export interface InsuranceState {
    insurances: InsuranceModel[];
    coverages: CoverageModel[];
    attachments: AttachmentModel[];
    verificationList: VerificationsHistoryModel[];
    insuranceAvailability: boolean;
    insuranceConfigurations: InsuranceConfiguration[];
    targetInsuranceConfiguration: InsuranceConfiguration;
    comments: CommentModel[];
}

/**
 * Creates initial state
 */
export function createInitialState(): InsuranceState {
    return {
        insurances: [],
        coverages: [],
        attachments: [],
        verificationList: [],
        insuranceAvailability: false,
        insuranceConfigurations: [],
        targetInsuranceConfiguration: null,
        comments: []
    };
}

/**
 * Provides Insurance states management
 */
@StoreConfig({name: 'insurance', resettable: true })
export class InsuranceStore extends Store<InsuranceState> {
    constructor() {
        super(createInitialState());
    }

    public addAttachment(attachments: AttachmentModel[]) {
        this.update({
            attachments: [...this.getValue().attachments, ...attachments]
        });
    }

    public addCoverage(coverage: CoverageModel) {
        if (coverage.id && this.getValue().coverages.find(x => x.id === coverage.id)) {
            this.update({
                coverages: this.getValue().coverages.map(x => x.id === coverage.id ? coverage : x)
            });
        } else {
            this.update({
                coverages: [...this.getValue().coverages, coverage]
            });
        }
    }

    public updateInsuranceStates(insurance: InsuranceModel) {
        const state = this.getValue();

        let insurances: any[]
        if (state.insurances.find(i => i.id === insurance.id)) {
            insurances = state.insurances.map(i => i.id === insurance.id ? insurance : i);
        } else {
            insurances = [...state.insurances, insurance].map(i => i.id === insurance.id ? insurance : i)
        }

        this.update({ insurances: insurances });
    }

    public updateInsuranceConfiguration(configuration: InsuranceConfiguration) {
        const state = this.getValue();

        let configurations: InsuranceConfiguration[]
        if (state.insuranceConfigurations.find(i => i.id === configuration.id)) {
            configurations = state.insuranceConfigurations.map(i => i.id === configuration.id ? configuration : i);
        } else {
            configurations = [...state.insuranceConfigurations, configuration].map(i => i.id === configuration.id ? configuration : i)
        }

        this.update({ insuranceConfigurations: configurations });
    }

    public removeInsuranceConfiguration(configurationId: number) {
        const state = this.getValue();

        const configurations = state.insuranceConfigurations.filter(i => i.id !== configurationId);

        this.update({ insuranceConfigurations: configurations });
    }

    public updateComment(comment: CommentModel) {
        if (comment.id && this.getValue().comments.find(x => x.id === comment.id)) {
            this.update({
                comments: this.getValue().comments.map(x => x.id === comment.id ? comment : x)
            });
        } else {
            this.update({
                comments: [comment, ...this.getValue().comments]
            });
        }
    }

    public removeComment(commentId: number) {
        const state = this.getValue();

        const comments = state.comments.filter(i => i.id !== commentId);

        this.update({ comments });
    }

    public resetComments(): void {
        this.update({ comments: [] });
    }
}

export const insuranceStore = new InsuranceStore();