import React from 'react';
import {SortingDirection} from "../../sorting/models/sorting-destination";
import {Box} from '@material-ui/core';
import { ReactComponent as VectorUp } from '@img/icons/VectorUp.svg';
import { ReactComponent as VectorDown } from '@img/icons/VectorDown.svg';
import {useStyles} from "./WildHealhTableSortLabel.styles";
import clsx from 'clsx';

interface WildHealhTableSortLabelProps {
    isActive: boolean,
    sortingDirection: SortingDirection,
    onClick: Function,
    title: string | JSX.Element,
    id: string,
}

export const WildHealhTableSortLabel: React.FC<WildHealhTableSortLabelProps> = (props: WildHealhTableSortLabelProps) => {
    const {
        isActive,
        sortingDirection,
        onClick,
        title,
        id,
    } = props;

    const classes = useStyles();

    return (
        <Box display="flex" alignItems="center">
            <Box>
                {title}
            </Box>
            <Box ml={1}
                id={id ?? ''}
                onClick={()=>onClick()}
            >
                <Box className={classes.pointer}>
                    <VectorUp 
                        className={clsx(classes.icon, classes.up, {
                            [classes.active]: isActive && sortingDirection === SortingDirection.Asc,
                            [classes.disabled]: !isActive 
                        })}
                    />
                    <VectorDown
                        className={clsx(classes.icon, classes.down, {
                            [classes.active]: isActive && sortingDirection === SortingDirection.Desc,
                            [classes.disabled]: !isActive
                        })}
                    />
                </Box>
            </Box>
        </Box>
    )
}