import { Query } from '@datorama/akita';
import {
    TimezonesState, TimezonesStore,
    timezonesStore
} from './timezones.store';
import {TimeZoneModel} from "../../models/timezone.model";

/**
 * Provides method for working with timezones store
 */
export class TimezonesQuery extends Query<TimezonesState> {
    timezones$ = this.select(state => state.timezones);
    myTimezone$ = this.select(state => state.myTimezone);

    constructor(protected store: TimezonesStore) {
        super(store);
    }

    /**
     * Returns timezones
     */
    public getTimezones(): TimeZoneModel[] {
        return this.getValue().timezones;
    }

    public getMyTimezone(): TimeZoneModel {
        const browserTimezone = this.getValue().browserTimezone;
        const myTimezone = this.getValue().myTimezone;
        return myTimezone ?? browserTimezone;
    }
}

export const timezonesQuery = new TimezonesQuery(timezonesStore);