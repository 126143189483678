import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
            paddingTop: '30px !important',
            paddingLeft: '40px !important',
            paddingRight: '40px !important',
            paddingBottom: '30px !important',
            [theme.breakpoints.down("xs")]: {
                paddingLeft: '0px !important',
                paddingRight: '0px !important',
                paddingBottom: '0px !important',
                '& .MuiDialog-paperFullScreen': {
                    borderRadius: '20px 20px 0 0',
                },
            },
        },
        dialogTitle: {
            padding: 24,
        },
        dialogContent: {
            paddingLeft: 40,
            paddingRight: 40,
            paddingBottom: 30,
            [theme.breakpoints.down("xs")]: {
                padding: 16,
            },
        },
        dialogActions: {
            paddingTop: 20,
            paddingBottom: 30,
            paddingLeft: 40,
            paddingRight: 40,
            boxShadow: '0px 2px 13px 0px rgba(168, 173, 198, 0.25)'
        },
    })
);