import { Box, IconButton, Dialog, DialogActions, DialogContent, TextField, Typography, Button, useMediaQuery, useTheme } from "@material-ui/core";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import clsx from 'clsx';
import React from 'react';
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { useFacade } from "./patientAllergyUpdateComponent.hooks";
import { useStyles } from "./patientAllergyUpdateComponent.styles";
import { colors } from '../../../common/constants/colors';
import Cancel from "@img/icons/cancel.svg";
import { PatientAllergyModel } from "../../models/patientAllergiesModel";
import commonUseStyles from '../../../common/styles/common.styles';

export interface PatientAllergyUpdateProps {
    opened: boolean;
    handleClose: () => void;
    patientId?: number;
    model?: PatientAllergyModel;
}

export const PatientAllergyUpdateComponent: React.FC<PatientAllergyUpdateProps> = (props: PatientAllergyUpdateProps) => {
    const classes = useStyles();
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const [
        {
            model,
            errors,
        },
        handleClose,
        handleChanges,
        handleSave
    ] = useFacade(props);

    return (
        <>
            <Dialog
                fullScreen={isSmallScreen}
                className={classes.dialog}
                onClose={() => handleClose()}
                open={props.opened}
            >
                {
                    !isSmallScreen &&
                    <MuiDialogTitle
                        className={classes.dialogTitle}
                        disableTypography
                    >
                        <Typography variant="h6">{model.id > 0 ? 'Edit Allergy' : 'Add Allergy'}</Typography>
                        <Box pl={1} pr={1}>
                            <IconButton id="patient-allergy-update-close" onClick={() => {
                                handleClose()
                            }} >
                                <img src={Cancel} alt='cancel' />
                            </IconButton>
                        </Box>
                    </MuiDialogTitle>
                }
                <DialogContent className={classes.dialogContent}>
                    {
                        isSmallScreen &&
                        <Box py={1} display="flex" justifyContent="space-between">
                            <Button onClick={() => handleClose()}>
                                <span className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorGray1, commonClasses.capitalize)}>
                                    Cancel
                                </span>
                            </Button>
                            <Box>
                                <span className={clsx(commonClasses.size20, commonClasses.textMedium, commonClasses.colorBlack)}>
                                    {model.id > 0 ? 'Edit Allergy' : 'Add Allergy'}
                                </span>
                            </Box>
                            <Button onClick={() => {
                                handleSave(props.patientId)
                            }}>
                                <span className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorMain, commonClasses.capitalize)}>
                                    {model.id > 0 ? 'Save' : 'Add'}
                                </span>
                            </Button>
                        </Box>
                    }
                    <Box p={!isSmallScreen && 1.5} pt={0.5} display="flex" flexDirection="column">
                        <Box display="flex" pl={0.5} fontSize={14} color={colors.gray1}>
                            Name of Medication
                            <Box pl={0.5} color={colors.bad}>*</Box>
                        </Box>
                        <TextField
                            required
                            fullWidth
                            id="name"
                            size="small"
                            variant="outlined"
                            value={model.name}
                            className={clsx(classes.field, 'input')}
                            error={!!errors['name']}
                            helperText={errors['name']}
                            onChange={(event) => handleChanges(event.target.id, event.target.value)}
                        />
                        <Box pl={0.5} pt={2.5} fontSize={14} color={colors.gray1}>Reaction</Box>
                        <TextField
                            fullWidth
                            size="small"
                            id="reaction"
                            variant="outlined"
                            value={model.reaction}
                            className={clsx(classes.field, 'input')}
                            error={!!errors['reaction']}
                            helperText={errors['reaction']}
                            onChange={(event) => handleChanges(event.target.id, event.target.value)}
                        />
                    </Box>
                </DialogContent>
                {
                    !isSmallScreen &&
                    <DialogActions className={classes.action}>
                        <WildHealthButton
                            id="patient-allergy-update-cancel"
                            onClick={() => {
                                handleClose()
                            }}
                            color="tertiary"
                            size='large'
                        >
                            Cancel
                        </WildHealthButton>
                        <Box pl={0.5}>
                            <WildHealthButton
                                id="patient-allergy-update-save"
                                onClick={() => {
                                    handleSave(props.patientId)
                                }}
                                autoFocus
                                size='large'
                            >
                                {model.id > 0 ? 'Save Changes' : 'Add Allergy'}
                            </WildHealthButton>
                        </Box>
                    </DialogActions>
                }
            </Dialog>
        </>
    );
}
