import {AppointmentLocationType, AppointmentPurposeType, AppointmentWithType, AppointmentReasonType, AppointmentReasonTypeNames} from "../../models/appointments.enums";
import {createPatientAppointmentValidator} from "./CreatePatientAppointmentFormComponent.validator";
import {AvailabilityModel, EmployeeAvailabilityModel} from "../../models/availability.models";
import {
    AppointmentTypeConfigurationModel,
    AppointmentTypeModel,
    CreateAppointmentModel
} from "../../models/appointments.models";
import {availabilityQuery} from "../../stores/availability/availability.query";
import {timezonesService} from "../../../timezones/services/timezones.service";
import {
    getAvailabilityEndDate,
    getAvailabilityStartDate,
    getTargetAppointmentType
} from "../../helpers/appointmentHelper";
import { authQuery } from "../../../auth/stores/auth";
import {patientsService} from "../../../patients/services/patients.service";
import {isCoachRole, isProviderRole} from "../../../common/constants/roles";
import {LocationModel} from "../../../locations/models/locations.models";
import {appointmentsService} from "../../services/appointments.service";
import {availabilityService} from "../../services/availability.service";
import {TimeZoneModel} from "../../../timezones/models/timezone.model";
import {getCurrentTimezone} from "../../../timezones/helpers/timezone";
import {EmployeeModel, EmployeeShortModel} from "../../../employee/models/employee.models";
import {IErrorState} from "../../../common/validation/error-state";
import {timezonesQuery} from "../../../timezones/stores/timezones";
import {onEmit} from "../../../common/helpers/on-emit";
import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {Subscription} from "recompose";
import moment from "moment";
import {GeneralValidator} from "../../../common/validation/general-validator";
import { subscriptionService } from "../../../payment/services/subscription.service";
import { SubscriptionModel } from "../../../payment/models/subscription.models";
import { subscriptionQuery } from "../../../payment/stores/subscriptionStore";
import {ProductModel} from "../../../products/models/product.model";
import {PatientProductModel} from "../../../products/models/patientProducts.model";
import {ProductType} from "../../../products/enums/productType";
import {productsService} from "../../../products/services/products.service";
import {productsQuery} from "../../../products/stores/productsStore";
import {patientProductsService} from "../../../products/services/patientProducts.service";
import { patientsQuery } from "../../../patients/stores/patientsStore";
import {availabilityStore} from "../../stores/availability/availability.store";
import { source } from "../../constants/appointment.constants";
import { navigationService } from "../../../../services/navigation.service";
import { usersService } from "../../../../services/users.service";

interface CreateAppointmentFormComponentState extends IErrorState {
    appointment: CreateAppointmentModel;
    selectedDate: Date;
    selectedWithType: AppointmentWithType;
    products: ProductModel[];
    patientProducts: PatientProductModel[];
    potentialProviders: EmployeeShortModel[];
    recommendedProvider: EmployeeShortModel;
    selectedProvider: EmployeeShortModel;
    selectedPurpose: AppointmentPurposeType;
    selectedTimeZoneId: string;
    availabilities: EmployeeAvailabilityModel[];
    commonAvailability: AvailabilityModel[];
    isLoading: boolean;
    timeSlotSelected: boolean;
    timeZones: TimeZoneModel[];
    availability: AvailabilityModel[];
    selectedLocationType: AppointmentLocationType;
    selectedLocation: LocationModel;
    selectedAppointmentType: AppointmentTypeModel;
    selectedAppointmentConfiguration: AppointmentTypeConfigurationModel;
    subscription: SubscriptionModel;
    selectedReasonType: AppointmentReasonType;
    isFullText: boolean;
    meetingRecordingConsent: boolean;
}

/**
 * Custom Hook to manage a view Model for Appointments component
 */
export function useFacade(
    toggleDialog: Function,
    assignedEmployees: EmployeeModel[],
    appointmentWithType: AppointmentWithType,
    locations: LocationModel[],
    appointmentTypes: AppointmentTypeModel[],
    timeZone: TimeZoneModel,
    patientId: number | null = null
): [
    CreateAppointmentFormComponentState,
    boolean,
    (purpose: AppointmentPurposeType) => void,
    (timezone: string) => void,
    (date: Date) => void,
    (time: Date) => void,
    () => void,
    (coachType: AppointmentWithType) => void,
    (locationType: AppointmentLocationType) => void,
    (employeeId: number) => void,
    () => boolean,
    () => boolean,
    (reason: string) => void,
    (value: any) => void,
    () => void,
    () => void
] {
    const history = useHistory();

    const appointmentType = getTargetAppointmentType(appointmentTypes, appointmentWithType);

    const appointmentConfiguration = appointmentType?.configurations[0];

    const [state, setState] = useState({
        appointment: {
            locationType: AppointmentLocationType.Online,
            startDate: null,
            endDate: null,

            employeeIds: [],
            appointmentTypeId: null,
            appointmentTypeConfigurationId: null,
            locationId: locations[0]?.id,
            timeZoneId: timeZone?.id,
            patientId: patientId,
            reason: '',
            source: source
        },
        products: [],
        patientProducts: [],
        potentialProviders: [],
        recommendedProvider: null,
        selectedProvider: null,
        selectedDate: availabilityQuery.getEarliestAvailability(),
        selectedWithType: appointmentWithType,
        selectedPurpose: appointmentType?.purpose,
        selectedTimeZoneId: timeZone?.id,
        selectedLocationType: AppointmentLocationType.Online,
        selectedLocation: locations[0],
        isLoading: false,
        timeSlotSelected: false,
        availabilities: [],
        commonAvailability: [],
        errors: {},
        timeZones: [],
        subscription: null,
        availability: [],
        selectedAppointmentType: appointmentType,
        selectedAppointmentConfiguration: appointmentConfiguration,
        selectedReasonType: null,
        isFullText: false,
        meetingRecordingConsent: false,
    } as CreateAppointmentFormComponentState);

    const defaultDuration = 30;

    const healthCoach = assignedEmployees.find(x => isCoachRole(x.role.id));
    const provider = assignedEmployees.find(x => isProviderRole(x.role.id));

    const getEndDate = () => {
        return moment(new Date(state.appointment.startDate))
            .add(state.selectedAppointmentConfiguration.duration, 'm')
            .toDate();
    }

    const getAvailability = (employee: EmployeeModel | EmployeeShortModel) => {
        if (state.selectedAppointmentType?.selectEmployee && !provider) {
            return state.commonAvailability ?? [];
        }

        setState(state => ({
            ...state,
            selectedDate: availabilityQuery.getEarliestAvailability()
        }));

        return state.availabilities.find(x => x.employeeId === employee?.id)?.availability ?? [];
    }

    const handleChangeAppointmentPurpose = (purpose: AppointmentPurposeType) => {
        setState({...state, selectedPurpose: purpose});
    }

    const handleChangeTimezone = (timezone: string) => {
        state.appointment.timeZoneId = timezone;
        setState({...state, appointment: state.appointment});
    }

    const handleChangeLocationType = (locationType: AppointmentLocationType) => {
        state.appointment.locationType = locationType;
        setState({...state, selectedLocationType: locationType, appointment: state.appointment});
    }

    const handleChangeReason = (reason: string) => {
        state.appointment.reason = reason;
        setState({...state, appointment: state.appointment});
    }

    const handleSelectReason = (value: any) => {
        setState(state => ({
            ...state,
            selectedReasonType: value,
            errors: GeneralValidator.removeError({ errors: GeneralValidator.removeError(state, 'otherReason')}, 'selectedReason')
        }));
    }

    const reasonIsSelected = (): boolean => {
        if (!state.selectedAppointmentType?.requiresReasonType) {
            return true;
        }

        if (!state.selectedReasonType) {
            setState(state => ({
                ...state,
                errors: GeneralValidator.addError(state, 'selectedReason', 'Please, select a reason.')
            }));
    
            return false;
        }

        if (state.selectedReasonType !== AppointmentReasonType.Other && state.selectedReasonType !== AppointmentReasonType.OtherTestFollowUp) {
            setState(state => ({
                ...state,
                errors: GeneralValidator.removeError(state, 'selectedReason')
            }));

            return true;
        }

        if (state.appointment.reason) {
            setState(state => ({
                ...state,
                errors: GeneralValidator.removeError(state, 'otherReason')
            }));

            return true;
        }

        setState(state => ({
            ...state,
            errors: GeneralValidator.addError(state, 'otherReason', 'Please, enter a specific reason.')
        }));

        return false;
    }

    const queryPotentialProviders = () => {
        const duration = getAppointmentDuration();
        const startDate = availabilityQuery.getEarliestAvailability();
        const endDate = getAvailabilityEndDate();

        setState(state => ({
            ...state,
            isLoading: true
        }));

        availabilityService.getPotential(startDate, endDate, duration, patientId).subscribe({
            next: (data) => {
                const recommendedProvider = state.recommendedProvider ?? data.employees[0];
                const potentialProviders = data.employees.filter(x => x.id !== recommendedProvider?.id);
                const currentProvider = state.selectedProvider;
                const selectedProvider = data.employees.length
                    ? data.employees.find(x => x.id === currentProvider?.id) ?? data.employees[0]
                    : null;

                const employeeAvailabilities = data.employees.map(e => ({
                    employeeId: e.id,
                    availability: data.availability.users[e.id].filter(x => moment(x.start).minutes() % 15 === 0)
                }) as EmployeeAvailabilityModel);

                setState(state => ({
                    ...state,
                    isLoading: false,
                    commonAvailability: data.availability.common,
                    availabilities: [...state.availabilities, ...employeeAvailabilities],
                    recommendedProvider: recommendedProvider,
                    potentialProviders: potentialProviders,
                    selectedProvider: selectedProvider
                }));

                assertProviderIsSelected(selectedProvider);
            },
            error: () => {
                setState(state => ({
                    ...state,
                    isLoading: false
                }));
            }
        });
    }

    const assertProviderIsSelected = (selectedProvider = null): boolean => {
        if (!state.selectedAppointmentType?.selectEmployee) {
            return true;
        }

        if (provider) {
            return true;
        }

        if (selectedProvider ?? state.selectedProvider) {
            setState(state => ({
                ...state,
                errors: GeneralValidator.removeError(state, 'selectedProvider')
            }));

            return true;
        }

        setState(state => ({
            ...state,
            errors: GeneralValidator.addError(state, 'selectedProvider', 'Please, select a provider.')
        }));

        return false;
    }

    const handleChangeDate = (date: Date) => {
        setState({...state, selectedDate: date});
    }

    const getAppointmentDuration = (): number => {

        return state.selectedAppointmentConfiguration?.duration ?? defaultDuration;
    }

    const handleChangeTime = (time: Date) => {
        createPatientAppointmentValidator.validateAndSetState(state, setState, 'startDate', time);

        state.appointment.startDate = time;

        setState({
            ...state,
            timeSlotSelected: true,
            appointment: state.appointment
        });
    }

    const handleChangeCoachType = (coachType: AppointmentWithType) => {
        switch (coachType) {
            case AppointmentWithType.HealthCoach:
                state.appointment.employeeIds = [healthCoach.id];
                setState({...state, selectedWithType: coachType, appointment: state.appointment});
                break;
            case AppointmentWithType.Provider:
                state.appointment.employeeIds = [provider.id];
                setState({...state, selectedWithType: coachType, appointment: state.appointment});
                break;
            case AppointmentWithType.HealthCoachAndProvider:
                state.appointment.employeeIds = [healthCoach.id, provider.id];
                setState({...state, selectedWithType: coachType, appointment: state.appointment});
                break;
        }
    }

    const setAvailability = () => {
        switch (state.selectedWithType) {
            case AppointmentWithType.HealthCoach:
                setState(state => ({...state, availability: getAvailability(healthCoach)}))
                break;
            case AppointmentWithType.Provider:
                setState(state => ({...state, availability: getAvailability(getProvider())}))
                break;
            case AppointmentWithType.HealthCoachAndProvider:
                setState(state => ({...state, availability: getAvailability(getProvider())}))
                break;
        }
    }

    const getProvider = (): EmployeeShortModel | EmployeeModel => {
        const appointmentType = state.selectedAppointmentType;

        if (!provider && appointmentType?.selectEmployee) {
            return state.selectedProvider;
        }

        return provider;
    }

    const getEmployeeIds = (withType: AppointmentWithType): number[] => {
        let ids = [];

        switch (withType) {
            case AppointmentWithType.HealthCoach:
                ids = [healthCoach?.id];
                break;
            case AppointmentWithType.Provider:
                ids = [getProvider()?.id];
                break;
            case AppointmentWithType.HealthCoachAndProvider:
                ids = [getProvider()?.id];
                break;
        }

        return ids.filter(x => x);
    }

    const canContinue = (): boolean => {
        createPatientAppointmentValidator.validateObjectAndSetState(state, setState, state.appointment);

        if (!assertProviderIsSelected()) {
            return false;
        }

        return createPatientAppointmentValidator.stateIsValid(state);
    }

    const handleRequestAppointment = () => {
        createPatientAppointmentValidator.validateObjectAndSetState(state, setState, state.appointment);

        if (!reasonIsSelected()) {
            return;
        }

        if (!assertProviderIsSelected()) {
            return;
        }

        if (!createPatientAppointmentValidator.stateIsValid(state)) {
            return;
        }

        setState(state => ({...state, isLoading: true}));

        state.appointment.timeZoneId = state.selectedTimeZoneId;
        state.appointment.employeeIds = getEmployeeIds(state.selectedWithType);
        state.appointment.appointmentTypeId = state.selectedAppointmentType?.id;
        state.appointment.appointmentTypeConfigurationId = state.selectedAppointmentConfiguration?.id;
        state.appointment.endDate = getEndDate();
        state.appointment.reasonType = state.selectedReasonType;

        if (state.selectedReasonType && state.selectedReasonType !== AppointmentReasonType.Other && state.selectedReasonType !== AppointmentReasonType.OtherTestFollowUp) {
            state.appointment.reason = AppointmentReasonTypeNames[state.selectedReasonType];
        }

        if (patientId) {
            appointmentsService.createAsEmployee(state.appointment).subscribe(() => {
                patientsService.getPatientTasksByEmployee(patientId).subscribe();
                toggleDialog();
            })
        } else {
            appointmentsService.createAsPatient(state.appointment).subscribe(() => {
                patientsService.getPatientTimeZone();
                patientsService.getPatientTasks().subscribe();
                toggleDialog();
            });
        }
    }

    const queryAvailability = (isEmployee: boolean) => {
        setState((state) => ({...state, isLoading: true}));

        const employeeIds = getEmployeeIds(state.selectedWithType).filter(x => x);
        if (employeeIds.length) {
            availabilityService.getByEmployeeIds(employeeIds, availabilityQuery.getEarliestAvailability(), getAvailabilityEndDate(), state.selectedAppointmentConfiguration?.id, isEmployee).subscribe();
        }

        if (state.selectedAppointmentType?.selectEmployee && !provider) {
            queryPotentialProviders()
        }
    }

    const getPatientProducts = () => {
        if (patientId) {
            patientProductsService.getPatientProducts(patientId).subscribe();
        } else {
            patientProductsService.getMyProducts().subscribe();
        }
    }

    const getPatientSubscription = () => {
        if (patientId) {
            patientsQuery.getTargetPatientSubscription() && subscriptionService.getPatientCurrent(patientId).subscribe();
        }
        else{
            subscriptionService.getCurrent();
        }
    }

    const getAllProducts = () => {
        productsService.getProducts().subscribe()
    }

    const productAvailable = (productType: ProductType): boolean => {
        return state.patientProducts.some(x => x.productType === productType && !x.isUsed)
    }

    const canChargePatient = () => {
        const appointmentType = state.selectedAppointmentType;

        if (!appointmentType?.requiredProductType) {
            return false
        }

        const product = state.products.find(x => x.type === appointmentType.requiredProductType);
        if (!product) {
            return false
        }

        return !productAvailable(product.type);
    }

    const handleProviderSelect = (providerId: number) => {
        const selectedProvider = [...state.potentialProviders, state.recommendedProvider].find(x => x.id === providerId);

        setState(state => ({
            ...state,
            selectedProvider: selectedProvider
        }));

        assertProviderIsSelected();
    }

    const handleReadMore = () => {
        setState({ ...state, isFullText: !state.isFullText });
    }

    const handleGoToAccountSetting = () => {
        navigationService.toMyProfile(history);
    }

    useEffect(() => {
        setAvailability();
    }, [state.availabilities])

    const useEffectCB = () => {
        availabilityStore.reset();

        const subscriptions: Subscription[] = [
            onEmit<EmployeeAvailabilityModel[]>(availabilityQuery.availabilities$, availabilities => {
                const isFullyLoaded = getEmployeeIds(state.selectedWithType).every(x => availabilities.some(c => c.employeeId === x));
                if (isFullyLoaded) {
                    setState((state) => ({...state, isLoading: false}))
                }

                setState((state) => ({
                    ...state, availabilities: availabilities.map(a => ({
                        employeeId: a.employeeId,
                        availability: a.availability.filter(x => moment(x.start).minutes() % 15 === 0)
                    }) as EmployeeAvailabilityModel)
                }));
            }),
            onEmit<ProductModel[]>(productsQuery.products$, products => {
                if (products) {
                    setState(state => ({...state, products: products}));
                }
            }),
            onEmit<PatientProductModel[]>(productsQuery.patientProducts$, products => {
                if (products) {
                    setState(state => ({...state, patientProducts: products}));
                }
            }),
            onEmit<SubscriptionModel>(subscriptionQuery.subscription$, subscription=>{
                if(subscription){
                    setState(state => ({...state, subscription: subscription}));
                }
            }),
            onEmit<TimeZoneModel[]>(timezonesQuery.timezones$, timeZones => {
                if (timeZone) {
                    setState((state) => ({
                        ...state,
                        timeZones: timeZones,
                    }));
                } else {
                    const timeZone = getCurrentTimezone();

                    setState({
                        ...state,
                        timeZones: timeZones,
                        selectedTimeZoneId: state.selectedTimeZoneId || timeZone?.id
                    });
                }
            })
        ];

        queryAvailability(authQuery.isEmployeeUser());

        getPatientProducts();

        getAllProducts();

        getPatientSubscription();

        timezonesService.getTimezones().subscribe();

        if (!patientId) {
            usersService.getMe().subscribe((response) => setState(state => ({ ...state, meetingRecordingConsent: response.meetingRecordingConsent })))
        }

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, []);

    return [
        state,
        state.selectedAppointmentType?.selectEmployee && !provider,
        handleChangeAppointmentPurpose,
        handleChangeTimezone,
        handleChangeDate,
        handleChangeTime,
        handleRequestAppointment,
        handleChangeCoachType,
        handleChangeLocationType,
        handleProviderSelect,
        canChargePatient,
        canContinue,
        handleChangeReason,
        handleSelectReason,
        handleReadMore,
        handleGoToAccountSetting
    ];
}
