import {
    Box,
    Container,
    Divider,
    Grid,
    useMediaQuery,
    useTheme,
    Breadcrumbs
} from '@material-ui/core';
import React from 'react';
import {UpdateAddressForm} from "../../../common/components/updateAddressTestForm/UpdateAddressForm";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import {PageSliderNavigationContext} from "../../../common/models/navigation.models";
import {BillingSummaryComponent} from "../billingSummaryTestComponent/BillingSummaryComponent";
import {WelcomePatientInfoComponent} from '../welcomePatientInfoComponent/WelcomePatientInfoComponent';
import {PatientInfoComponent} from '../patientInfoRedesignComponent/PatientInfoRedesignComponent';
import {PaymentSummaryComponent} from "../paymentSummaryTestComponent/PaymentSummaryComponentGetStarted";
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from 'clsx';
import {RegistrationBackButton} from '../registrationButton/RegistrationBackButton';
import {RegistrationNextButton} from '../registrationButton/RegistrationNextTestButton';
import {PageSliderComponent} from "../../../common/components/PageSliderComponent";
import AddIcon from '@material-ui/icons/Add';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import {ReactComponent as ProvenResultsDesktop} from '@img/getStarted/ProvenResultsDesktop.svg';
import {ReactComponent as ProvenResultsMobile} from '@img/getStarted/ProvenResultsMobile.svg';
import {ReactComponent as GridLockIcon} from '@img/icons/GridLock.svg';
import {ReactComponent as StarIcon} from '@img/icons/StarIcon.svg';
import WorkingTogether from '@img/getStarted/Together.png';
import SandraAvatar from '@img/getStarted/SandraAvatar.png';
import {navigationService} from "../../../../services/navigation.service";
import {WildHealthCheckbox} from '../../../common/components/WildHealthCheckbox';
import {SelectAddOnComponent} from '../../../addons/components/selectAddOnTestComponent/selectAddOnComponent';
import {colors} from '../../../common/constants/colors';
import {PreauthorizeGetStartedStep, HealthConcernStatus, useFacade} from "./finishCheckoutPreauthorizeComponent.hooks";
import {useStyles} from "./finishCheckoutPreauthorizeComponent.styles";
import {PreauthorizeRequestModel} from "../../models/preauthorizeRequest.model";
import {PaymentPlanModel} from "../../models/paymentPlan.models";
import {PaymentPeriodModel} from "../../models/paymentPeriod.models";
import {PaymentPriceModel} from "../../models/paymentPrice.models";

interface FinishCheckoutPreauthorizeComponentProps {
    paymentPlan: PaymentPlanModel;
    paymentPeriod: PaymentPeriodModel;
    paymentPrice: PaymentPriceModel;
    navigationContext: PageSliderNavigationContext;
    preauthorizeRequest: PreauthorizeRequestModel;
    topBannerHeight?: number;
    handleSetProgress: (progress: number) => void;
}

export const FinishCheckoutPreauthorizeComponent: React.FC<FinishCheckoutPreauthorizeComponentProps> = (props: FinishCheckoutPreauthorizeComponentProps) => {
    const {
        paymentPlan,
        paymentPeriod,
        paymentPrice,
        navigationContext,
        preauthorizeRequest,
        topBannerHeight = 0,
        handleSetProgress
    } = props;

    const [
        {
            addOns,
            payer,
            errors,
            sameAsBillingAddress,
            step,
            isSubmitted,
            isAgreeSMS,
            isAgreeContinue,
            isLoading,
            healthConcernStatus,
            showBottom,
            isGetUserInfo
        },
        isMarketingSMSButton,
        isConsultationGetStartedPath,
        handleChanges,
        renderRecaptcha,
        handleToggleSelectingAddOn,
        getSelectedAddOns,
        handleSameAsBillingAddress,
        handleSubmitNextStep,
        handleSubmitBackStep,
        handleAgree,
        isCheckedWelcomePatient,
        isCheckedNameInfo,
        isCheckedPersonalInfo,
        isCheckedShippingDetail,
        isCheckedPaymentCardDetail,
        handleChangeHealthConcernStatus,
        prevScrollY,
        handleOnBlurValidate,
    ] = useFacade(preauthorizeRequest, paymentPeriod, handleSetProgress);

    const classes = useStyles({topBannerHeight});
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    if (isGetUserInfo) {
        return <WildHealthLinearProgress/>
    }

    const renderHealthConcernStep = () => (
        <Box className={classes.concernMain}>
            <Box textAlign="center">
                <span className={classes.title}>What’s your main Goal?</span>
            </Box>
            <Box mt={isSmallScreen ? 2 : 4.5} p={2} className={clsx({
                [classes.concernBorderActive]: healthConcernStatus === HealthConcernStatus.MaximizeHealthSpanLongevity,
                [classes.concernBorder]: healthConcernStatus !== HealthConcernStatus.MaximizeHealthSpanLongevity
            })}>
                <WildHealthCheckbox
                    id="maximize-health-span-concern"
                    fill={colors.lightMint}
                    label={<span className={clsx(commonClasses.colorCharcoal, {
                        [commonClasses.size18]: !isSmallScreen,
                    })}>Maximize health span &amp; longevity</span>}
                    checkStatus={healthConcernStatus === HealthConcernStatus.MaximizeHealthSpanLongevity}
                    handleCheck={() => handleChangeHealthConcernStatus(HealthConcernStatus.MaximizeHealthSpanLongevity)}
                />
            </Box>
            <Box mt={isSmallScreen ? 2 : 3} p={2} className={clsx({
                [classes.concernBorderActive]: healthConcernStatus === HealthConcernStatus.TreatNewChronicConditions,
                [classes.concernBorder]: healthConcernStatus !== HealthConcernStatus.TreatNewChronicConditions
            })}>
                <WildHealthCheckbox
                    id="treat-new-chronic-conditions"
                    fill={colors.lightMint}
                    label={<span className={clsx(commonClasses.colorCharcoal, {
                        [commonClasses.size18]: !isSmallScreen,
                    })}>Treat new or chronic conditions</span>}
                    checkStatus={healthConcernStatus === HealthConcernStatus.TreatNewChronicConditions}
                    handleCheck={() => handleChangeHealthConcernStatus(HealthConcernStatus.TreatNewChronicConditions)}
                />
            </Box>
            <Box mt={isSmallScreen ? 2 : 3} p={2} className={clsx({
                [classes.concernBorderActive]: healthConcernStatus === HealthConcernStatus.OptimizeMyOverallHealth,
                [classes.concernBorder]: healthConcernStatus !== HealthConcernStatus.OptimizeMyOverallHealth
            })}>
                <WildHealthCheckbox
                    id="optimize-my-overall-health"
                    fill={colors.lightMint}
                    label={<span className={clsx(commonClasses.colorCharcoal, {
                        [commonClasses.size18]: !isSmallScreen,
                    })}>Optimize my overall health</span>}
                    checkStatus={healthConcernStatus === HealthConcernStatus.OptimizeMyOverallHealth}
                    handleCheck={() => handleChangeHealthConcernStatus(HealthConcernStatus.OptimizeMyOverallHealth)}
                />
            </Box>
            <Box mt={isSmallScreen ? 2 : 3} p={2} className={clsx({
                [classes.concernBorderActive]: healthConcernStatus === HealthConcernStatus.ElevateMyPerformance,
                [classes.concernBorder]: healthConcernStatus !== HealthConcernStatus.ElevateMyPerformance,
            })}>
                <WildHealthCheckbox
                    id="elevate-my-performance"
                    fill={colors.lightMint}
                    label={<span className={clsx(commonClasses.colorCharcoal, {
                        [commonClasses.size18]: !isSmallScreen,
                    })}>Elevate my performance</span>}
                    checkStatus={healthConcernStatus === HealthConcernStatus.ElevateMyPerformance}
                    handleCheck={() => handleChangeHealthConcernStatus(HealthConcernStatus.ElevateMyPerformance)}
                />
            </Box>
        </Box>
    )

    const renderClinicallyProvenResultsStep = () => (
        <Box className={classes.clinicallyProvenResultsMain}>
            <Box textAlign="center">
                <span className={classes.title}>Clinically Proven Results</span>
            </Box>
            <Box display="flex" justifyContent="center" mt={isSmallScreen ? 3 : 5}>
                {isSmallScreen ? <ProvenResultsMobile width="100%"/> : <ProvenResultsDesktop width="100%"/>}
            </Box>
            <Box mt={2.5} textAlign="center">
                {!isSmallScreen && (
                    <Box textAlign="center" pt={3} pb={2}>
                        <span className={clsx(commonClasses.size24, commonClasses.textMedium, commonClasses.colorNavy)}>Clinically Proven Results</span>
                    </Box>
                )}
                <Grid container justify="center">
                    <Grid item justify="center" xs={4} md={4} lg={4}>
                        <Box display="flex" alignItems="center" justifyContent="center">
                            <ArrowDownwardIcon className={classes.downIcon}/>
                            <span
                                className={clsx(commonClasses.size34, commonClasses.textMedium, commonClasses.colorNavy)}>69</span>
                            <span
                                className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorGray700,
                                    isSmallScreen && '-wh-tw-mt-3')}>%</span>
                        </Box>
                        <Box px={2} className={clsx(commonClasses.textRegular, commonClasses.colorGray700, {
                            [commonClasses.size14]: !isSmallScreen,
                            [commonClasses.size12]: isSmallScreen,
                        })}>{isSmallScreen ? `Reduction in Inflammation` : `Reduction in inflammation`}</Box>
                    </Grid>
                    <Grid item justify="center" xs={4} md={4} lg={4}>
                        <Box display="flex" alignItems="center" justifyContent="center">
                            <ArrowUpwardIcon className={classes.downIcon}/>
                            <span
                                className={clsx(commonClasses.size34, commonClasses.textMedium, commonClasses.colorNavy)}>47</span>
                            <span
                                className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorGray700,
                                    isSmallScreen && '-wh-tw-mt-3')}>%</span>
                        </Box>
                        <Box className={clsx(commonClasses.textRegular, commonClasses.colorGray700, {
                            [commonClasses.size14]: !isSmallScreen,
                            [commonClasses.size12]: isSmallScreen,
                        })}>{isSmallScreen ? `Improvement of Diabetic Markers` : `Improvement of diabetic markers`}</Box>
                    </Grid>
                    <Grid item justify="center" xs={4} md={4} lg={4}>
                        <Box display="flex" alignItems="center" justifyContent="center">
                            <ArrowDownwardIcon className={classes.downIcon}/>
                            <span
                                className={clsx(commonClasses.size34, commonClasses.textMedium, commonClasses.colorNavy)}>58</span>
                            <span
                                className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorGray700,
                                    isSmallScreen && '-wh-tw-mt-3')}>%</span>
                        </Box>
                        <Box className={clsx(commonClasses.textRegular, commonClasses.colorGray700, {
                            [commonClasses.size14]: !isSmallScreen,
                            [commonClasses.size12]: isSmallScreen,
                        })}>{isSmallScreen ? `Reduced Risk of Cardiovascular Disease` : `Reduced cardiovascular disease`}</Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )

    const renderWorkingTogetherStep = () => (
        <Box className={classes.clinicallyProvenResultsMain}>
            <Box display="flex" justifyContent="center">
                <img src={WorkingTogether} className={classes.workingTogetherImage}/>
            </Box>
            <Box textAlign="center" px={4} mt={3.5}>
                <span className={classes.title}>Working Together to Deliver the Health Results You Deserve</span>
            </Box>
            <Box mt={isSmallScreen ? "14px" : 5} px={isSmallScreen && 2} textAlign="center">
                <span className={clsx(commonClasses.colorGray700, commonClasses.textRegular, {
                    [commonClasses.size14]: isSmallScreen,
                })}>Comprehensive and collaborative care is what makes our unparalleled patient outcomes possible. Beyond their individual expertise, your providers work together to execute on a personalized and highly effective approach to transform your health for good.</span>
            </Box>
        </Box>
    )

    const renderSetupPasswordStep = () => {
        return (
            <Box className={classes.inputFieldMain}>
                <Box textAlign="center">
                    <span
                        className={classes.title}>Create an Account to Start Your Personal Health Journey</span>
                </Box>
                <Box p={2} mt={3} display="flex" className={classes.securityBlock}>
                    <Box mr={1.25}>
                        <GridLockIcon />
                    </Box>
                    <Box className={clsx(commonClasses.colorWhite, {
                        [commonClasses.size14]: !isSmallScreen,
                        [commonClasses.size12]: isSmallScreen,
                    })}>Patient privacy is our highest priority. We encrypt all confidential data, and never share or sell your genetic information with anyone.</Box>
                </Box>
                <WelcomePatientInfoComponent
                    payer={payer}
                    handleChanges={handleChanges}
                    hiddenFields={[]}
                    readonlyFields={['email']}
                    errors={errors}
                />
                <Box mt={2} display="flex" alignItems="center">
                    <WildHealthCheckbox
                        id="Agree-Continue-CheckBox"
                        label={''}
                        fill={colors.lightMint}
                        checkStatus={isAgreeContinue}
                        handleCheck={(event) => handleAgree(event, 'isAgreeContinue')}
                    />
                    <span className={clsx(commonClasses.size12, commonClasses.colorCharcoal)}>
                         By checking this box, you acknowledge and agree to the
                         <span onClick={() => navigationService.toTermsOfUse()} className={classes.termsPolicyLink}>Terms of Use</span>
                         <span>, </span>
                         <span onClick={() => navigationService.toTermsOfService()} className={classes.termsPolicyLink}>Terms of Service</span>
                         <span>, </span>
                         <span onClick={() => navigationService.toPrivacyPolicy()} className={classes.termsPolicyLink}>Privacy Policy</span>
                         <span>, and</span>
                         <span onClick={() => navigationService.toNoticeOfPrivacy()}
                               className={classes.termsPolicyLink}>Notice of Privacy Practices</span>.
                     </span>
                </Box>
                {
                    renderRecaptcha()
                }
            </Box>
        )
    }

    const renderCongratulationsStep = () => (
        <Box className={classes.clinicallyProvenResultsMain}>
            <Box textAlign="center" mt={3}>
                <span className={classes.title}>Congratulations!</span>
            </Box>
            <Box textAlign="center" px={3} mt={3}>
                <span className={clsx(commonClasses.size20, commonClasses.textMedium, commonClasses.colorNavy)}>You made a commitment to yourself, and joined a community of thousands who are improving their health and wellbeing.</span>
            </Box>
            <Box mt={5} textAlign="center">
                <Box pt={1.5} pb={2.5} display="flex" alignItems="center" justifyContent="center">
                    <StarIcon/>
                    <StarIcon/>
                    <StarIcon/>
                    <StarIcon/>
                    <StarIcon/>
                </Box>
                <Box px={isSmallScreen ? 2 : 2.5}>
                    <span className={clsx(commonClasses.textRegular, commonClasses.colorNavy, {
                        [commonClasses.size14]: isSmallScreen,
                    })}>“It took only a few weeks to feel the effects of implementing the diet and supplement recommendations from my care team. I can’t wait to see my progress over time.”</span>
                </Box>
            </Box>
            <Box display="flex" justifyContent="center" mt={2.5}>
                <img src={SandraAvatar} className={classes.sandraAvatar}/>
            </Box>
            <Box textAlign="center" mt={2.5}>
                <span className={clsx(commonClasses.size14, commonClasses.textSemiBold, commonClasses.colorNavy)}>Sandra M.</span>
            </Box>
        </Box>
    )

    const renderNameInterstitialStep = () => (
        <Box className={classes.inputFieldMain}>
            <Box textAlign="center">
                <span className={classes.title}>Let’s get acquainted.</span>
            </Box>
            <PatientInfoComponent
                payer={payer}
                handleChanges={handleChanges}
                hiddenFields={['phoneNumber', 'state']}
                readonlyFields={[]}
                errors={errors}
                handleValidate={handleOnBlurValidate}
            />
        </Box>
    )

    const renderAgreeSmsMarketing = () => (
        <Box mt={3}>
            <WildHealthCheckbox
                id="Agree-SMS"
                fill={colors.lightMint}
                label={<Box ml={1} component="div" display="flex"
                            className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorCharcoal)}>
                    <span>
                        I agree to receive occasional SMS marketing communications
                    </span>
                </Box>}
                checkStatus={isAgreeSMS}
                handleCheck={(event) => handleAgree(event, 'isAgreeSMS')}
            />
        </Box>
    )

    const renderPersonalInfoStep = () => (
        <Box className={classes.inputFieldMain}>
            <Box textAlign="center">
                <span className={classes.title}>Hi, {payer.firstName}!</span>
            </Box>
            <Box textAlign="center" px={2} mt={4.5}>
                <span>Now, add a few more details.</span>
            </Box>
            <PatientInfoComponent
                payer={payer}
                handleChanges={handleChanges}
                hiddenFields={['firstName', 'lastName', 'birthday', 'gender']}
                readonlyFields={[]}
                errors={errors}
                handleValidate={handleOnBlurValidate}
            />
            {
                isMarketingSMSButton && renderAgreeSmsMarketing()
            }
        </Box>
    )

    const renderMarketingSMSSubCopy = () => (
        <Box pt={4} className={classes.inputFieldMain}>
            <span className={clsx(commonClasses.size12, commonClasses.textRegular, commonClasses.colorGray700)}>*By checking this box, you acknowledge and agree that Wild Health and Wellington Provider Group may use your contact information to send communications by email and text message, including automatically generated text messages, on Wild Health's products and other related educational content. I understand that my consent to receive these communications is not a condition of receiving any good or service. I also understand that message and data charges may apply.</span>
        </Box>
    )

    const renderShippingStep = () => (
        <Box className={classes.inputFieldMain}>
            <Box textAlign="center">
                <span className={classes.title}>Shipping</span>
            </Box>
            <Box mt={2} display="flex" justifyContent="center">
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>} aria-label="breadcrumb">
                    <Box className={clsx(commonClasses.textMedium, commonClasses.colorNavy, {
                        [commonClasses.size12]: isSmallScreen,
                        [commonClasses.size14]: !isSmallScreen,
                    })}>
                        Shipping
                    </Box>
                    <Box className={clsx(commonClasses.textMedium, commonClasses.colorDarkGray, {
                        [commonClasses.size12]: isSmallScreen,
                        [commonClasses.size14]: !isSmallScreen,
                    })}>
                        Billing Info
                    </Box>
                    <Box className={clsx(commonClasses.textMedium, commonClasses.colorDarkGray, {
                        [commonClasses.size12]: isSmallScreen,
                        [commonClasses.size14]: !isSmallScreen,
                    })}>
                        Review
                    </Box>
                </Breadcrumbs>
            </Box>
            <Box mt={4.5}>
                <Box textAlign="center" px={2} mb={3}>
                    <span>Tell us where to send your Wild Health Genetic Test Kit — and any other surprises along the way.</span>
                </Box>
                <UpdateAddressForm
                    address={payer.shippingAddress}
                    prefix={"shippingAddress."}
                    errors={errors}
                    handleChanges={handleChanges}
                    handleValidate={handleOnBlurValidate}
                />
            </Box>
        </Box>
    )

    const renderPaymentStep = () => (
        <>
            <Box className={classes.inputFieldMain}>
                <Box textAlign="center">
                    <span className={classes.title}>Billing</span>
                </Box>
                <Box mt={2} display="flex" justifyContent="center">
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>} aria-label="breadcrumb">
                        <Box className={clsx(commonClasses.textMedium, commonClasses.colorDarkGray, {
                            [commonClasses.size12]: isSmallScreen,
                            [commonClasses.size14]: !isSmallScreen,
                        })}>
                            Shipping
                        </Box>
                        <Box className={clsx(commonClasses.textMedium, commonClasses.colorNavy, {
                            [commonClasses.size12]: isSmallScreen,
                            [commonClasses.size14]: !isSmallScreen,
                        })}>
                            Billing Info
                        </Box>
                        <Box className={clsx(commonClasses.textMedium, commonClasses.colorDarkGray, {
                            [commonClasses.size12]: isSmallScreen,
                            [commonClasses.size14]: !isSmallScreen,
                        })}>
                            Review
                        </Box>
                    </Breadcrumbs>
                </Box>
                <Box mt={sameAsBillingAddress ? 1.5 : 3}>
                    {sameAsBillingAddress ? (
                        <WildHealthCheckbox
                            id="use-shipping-address"
                            fill={colors.lightMint}
                            label={<Box ml={1} component="div" display="flex"
                                        className={clsx(commonClasses.textMedium, commonClasses.colorNavy, {
                                            [commonClasses.size14]: isSmallScreen,
                                        })}>
                                <span>Use Shipping Address</span>
                            </Box>}
                            checkStatus={!sameAsBillingAddress}
                            handleCheck={handleSameAsBillingAddress}
                        />
                    ) : (
                        <button id="finish-checkout-as-billing-address" className={classes.btnLink}
                                onClick={() => handleSameAsBillingAddress()}>
                            <Box mr={1}>
                                <span>Add a different billing address</span>
                            </Box>
                            <AddIcon className={classes.addIcon}/>
                        </button>
                    )}
                </Box>
                {
                    sameAsBillingAddress &&

                    <Box>
                        <Box my={3} className={classes.smallTitle}>
                            Billing Address
                        </Box>
                        <UpdateAddressForm
                            address={payer.billingAddress}
                            prefix={"billingAddress."}
                            errors={errors}
                            handleChanges={handleChanges}
                            handleValidate={handleOnBlurValidate}
                        />
                    </Box>
                }
            </Box>
            <div ref={prevScrollY}>
                {renderDefaultNextButton()}
            </div>
        </>
    )

    const renderCheckoutStep = () => (
        <Box className={classes.orderSummeryMain}>
            <Box textAlign="center">
                <span className={classes.title}>Order Summary</span>
            </Box>
            <Box mt={2} display="flex" justifyContent="center">
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>} aria-label="breadcrumb">
                    <Box className={clsx(commonClasses.textMedium, commonClasses.colorDarkGray, {
                        [commonClasses.size12]: isSmallScreen,
                        [commonClasses.size14]: !isSmallScreen,
                    })}>
                        Shipping
                    </Box>
                    <Box className={clsx(commonClasses.textMedium, commonClasses.colorDarkGray, {
                        [commonClasses.size12]: isSmallScreen,
                        [commonClasses.size14]: !isSmallScreen,
                    })}>
                        Billing Info
                    </Box>
                    <Box className={clsx(commonClasses.textMedium, commonClasses.colorNavy, {
                        [commonClasses.size12]: isSmallScreen,
                        [commonClasses.size14]: !isSmallScreen,
                    })}>
                        Review
                    </Box>
                </Breadcrumbs>
            </Box>
            <Box>
                <Box mt={3}>
                    <PaymentSummaryComponent
                        priceType={paymentPrice.type}
                        selectedPeriod={paymentPeriod}
                        selectedPrice={paymentPrice}
                        handlePriceSelect={() => {
                        }}
                        selectedPlan={paymentPlan}
                        isCouponApplied={false}/>
                </Box>
                <Box>
                    <Box mt={2}>
                        <Box>
                            <span className={clsx(commonClasses.textMedium, commonClasses.colorCharcoal, {
                                [commonClasses.size18]: !isSmallScreen,
                            })}>Fast-track your health optimization</span>
                        </Box>
                        <Box>
                            {
                                addOns.map((item) => {
                                    return (
                                        <Box key={item.id}>
                                            <SelectAddOnComponent addOn={item}
                                                                  handleSelectingItem={handleToggleSelectingAddOn}/>
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                    </Box>
                </Box>
                <Box my={2}>
                    <Divider/>
                </Box>
                <Box>
                    <BillingSummaryComponent
                        selectedPlan={paymentPlan}
                        selectedPeriod={paymentPeriod}
                        selectedPrice={paymentPrice}
                        selectedAddOns={getSelectedAddOns()}
                        hasDiscount
                    />
                </Box>

                <Box mt={3}>
                    <Box mt={2} className={classes.paymentMethodBlock}>
                        <Box
                            className={clsx(commonClasses.size12, commonClasses.textMedium, commonClasses.colorGray700, commonClasses.uppercase)}>
                            Shipping address
                        </Box>
                        <Box mt={1.5}>
                            <Box
                                className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorLinkBlack)}>
                                <span>{payer.firstName} {payer.lastName}</span>
                            </Box>
                            <Box
                                className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorLinkBlack)}>
                                <span>{payer.shippingAddress.streetAddress1}</span>
                            </Box>
                            <Box
                                className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorLinkBlack)}>
                                <span>{payer.shippingAddress.city}, {payer.shippingAddress.country} {payer.shippingAddress.zipCode}</span>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {
                renderRecaptcha()
            }
            <div ref={prevScrollY}>
                {renderDefaultNextButton()}
            </div>
        </Box>
    )

    const btnName = () => {
        switch (step) {
            case PreauthorizeGetStartedStep.SetupPassword:
                return "Get Healthy";
            case PreauthorizeGetStartedStep.Payment:
                return "Continue to Payment";
            case PreauthorizeGetStartedStep.Review:
                return "Purchase Membership";
            default:
                return "Continue";
        }
    }

    const disabledStatus = () => {
        switch (step) {
            case PreauthorizeGetStartedStep.HealthConcern:
                return healthConcernStatus === null;
            case PreauthorizeGetStartedStep.SetupPassword:
                return !isCheckedWelcomePatient();
            case PreauthorizeGetStartedStep.NameInterstitial:
                return !isCheckedNameInfo();
            case PreauthorizeGetStartedStep.PersonalInfo:
                return !isCheckedPersonalInfo();
            case PreauthorizeGetStartedStep.Shipping:
                return !isCheckedShippingDetail();
            case PreauthorizeGetStartedStep.Payment:
                return !isCheckedPaymentCardDetail();
            case PreauthorizeGetStartedStep.Review:
                return false;
        }
    }

    const renderBackButton = () => {
        if (step !== PreauthorizeGetStartedStep.HealthConcern && step !== PreauthorizeGetStartedStep.NameInterstitial && step !== PreauthorizeGetStartedStep.Congratulations) {
            return <RegistrationBackButton goBack={handleSubmitBackStep} label=""/>;
        } else {
            if (!isConsultationGetStartedPath && step !== PreauthorizeGetStartedStep.NameInterstitial && step !== PreauthorizeGetStartedStep.Congratulations) {
                return <PageSliderComponent context={{...navigationContext, backButton: ""}}/>;
            }
        }
        return <></>;
    }

    const renderDefaultNextButton = () => {
        return (
            <Box mt={5}>
                <Grid container justify="center">
                    <Grid item xs={12}
                          md={step === PreauthorizeGetStartedStep.Review ? 12 : step === PreauthorizeGetStartedStep.Shipping || step === PreauthorizeGetStartedStep.Payment ? 4 : 3}>
                        <RegistrationNextButton
                            goNext={handleSubmitNextStep}
                            label={btnName()}
                            isLoading={isLoading || isSubmitted}
                            disabled={disabledStatus()}
                        />
                    </Grid>
                </Grid>
            </Box>
        )
    }

    const renderNextButton = (step: PreauthorizeGetStartedStep): JSX.Element => {
        switch (step) {
            case PreauthorizeGetStartedStep.Payment:
                return <></>;
            case PreauthorizeGetStartedStep.Review:
                return <></>;
            default:
                return renderDefaultNextButton();
        }
    }

    return (
        <>
            <Box className={classes.backBtnSection}>
                {!isSubmitted && renderBackButton()}
            </Box>
            <Container maxWidth="lg" className={classes.container}>
                <Box className={classes.main}>
                    {step === PreauthorizeGetStartedStep.HealthConcern && renderHealthConcernStep()}
                    {step === PreauthorizeGetStartedStep.ClinicallyProvenResults && renderClinicallyProvenResultsStep()}
                    {step === PreauthorizeGetStartedStep.WorkingTogether && renderWorkingTogetherStep()}
                    {step === PreauthorizeGetStartedStep.SetupPassword && renderSetupPasswordStep()}
                    {step === PreauthorizeGetStartedStep.Congratulations && renderCongratulationsStep()}
                    {step === PreauthorizeGetStartedStep.NameInterstitial && renderNameInterstitialStep()}
                    {step === PreauthorizeGetStartedStep.PersonalInfo && renderPersonalInfoStep()}
                    {step === PreauthorizeGetStartedStep.Shipping && renderShippingStep()}
                    {step === PreauthorizeGetStartedStep.Payment && renderPaymentStep()}
                    {step === PreauthorizeGetStartedStep.Review && renderCheckoutStep()}
                    {
                        renderNextButton(step)
                    }

                    {step === PreauthorizeGetStartedStep.PersonalInfo && isMarketingSMSButton && isAgreeSMS && renderMarketingSMSSubCopy()}
                </Box>
            </Container>
            {isSmallScreen && step === PreauthorizeGetStartedStep.Review && showBottom && (
                <Box className={classes.stickyReviewBtnSection}>
                    <RegistrationNextButton
                        goNext={handleSubmitNextStep}
                        label={btnName()}
                        isLoading={isLoading || isSubmitted}
                        disabled={disabledStatus()}
                    />
                </Box>
            )}
            {isSmallScreen && step === PreauthorizeGetStartedStep.Payment && sameAsBillingAddress && showBottom && (
                <Box className={classes.stickyReviewBtnSection}>
                    <RegistrationNextButton
                        goNext={handleSubmitNextStep}
                        label={btnName()}
                        isLoading={isLoading || isSubmitted}
                        disabled={disabledStatus()}
                    />
                </Box>
            )}
        </>
    )
}
