import { Box } from "@material-ui/core";
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from "react-router-dom";
import { Subscription } from 'rxjs';
import { navigationService } from "../../../../services/navigation.service";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { displayMoney } from "../../../common/helpers/display-money";
import { onEmit } from "../../../common/helpers/on-emit";
import { IErrorState } from "../../../common/validation/error-state";
import { ApplyCouponModel, MerchantCredentialsModel } from "../../models/payment.models";
import { PaymentPeriodModel } from "../../models/paymentPeriod.models";
import { PaymentPlanModel } from "../../models/paymentPlan.models";
import {PaymentCouponModel, PaymentPriceModel, PaymentStrategy} from "../../models/paymentPrice.models";
import { BuyNewSubscriptionModel } from '../../models/subscription.models';
import { paymentService } from "../../services/payment.service";
import { paymentPlansService } from "../../services/paymentPlans.service";
import { subscriptionService } from '../../services/subscription.service';
import { paymentPlansQuery, paymentPlansStore } from "../../stores/paymentPlansStore";
import { paymentQuery } from "../../stores/paymentStore";
import { AddOnModel, SelectAddOnModel } from "../../../addons/models/addOns.models";
import { addOnsService } from "../../../addons/services/addOns.service";
import { addOnsQuery } from "../../../addons/stores";
import { AgreementModel } from "../../models/agreement.model";
import { profileService } from "../../../account/services/profile.service";
import { authQuery } from "../../../auth/stores/auth";
import { RoutesConstants } from '../../../../constants/route.constants';
import { OrderType } from "../../../orders/models/orders.models";
import { authService } from "../../../auth/services/auth.service";

/**
 * Represents payment form state
 */
interface FinishOnboardingComponentState extends IErrorState {
    practiceId: number | null;
    publicKey: string | null;
    paymentPlan: PaymentPlanModel;
    paymentPeriod: PaymentPeriodModel;
    paymentPrice: PaymentPriceModel;
    addOns: SelectAddOnModel[];
    isSubmitted: boolean;
    couponCode: string;
    coupon:PaymentCouponModel;
    isCouponApplied: boolean;
    agreements: AgreementModel[];
    isPaymentPlanApplied: boolean;
    exclusivePrices: PaymentPriceModel[];
}

const getDefaultPaymentPrice = (period: PaymentPeriodModel): PaymentPriceModel => {
    const exclusivePrice = period.prices.find(x => x.isExclusive);
    if (exclusivePrice) {
        return exclusivePrice;
    }

    const annualPrice = period.prices.find(x => x.strategy === PaymentStrategy.FullPayment);
    return annualPrice
        ? annualPrice
        : period.prices[0];
}

/**
 * Custom Hook to manage a view Model for SelectPlan page view components
 */
export function useFacade(): [
    FinishOnboardingComponentState,
    (id: number) => void,
    () => JSX.Element,
    (id: number, value: boolean) => void,
    () => SelectAddOnModel[],
    (value: string) => void,
    (code: string) => void,
    () => void] {

    const history = useHistory();
    const { pathname } = useLocation();
    const mobileGetStartedPath = pathname.toLowerCase().includes(RoutesConstants.mobileGetStarted);
    const paymentPlan = paymentPlansQuery.getSelectedPlan();
    const paymentPeriod = paymentPlansQuery.getSelectedPeriod();
    const paymentPrice = getDefaultPaymentPrice(paymentPeriod);
    const coupon = paymentPlansQuery.getCoupon();
    const couponCode = paymentPlansQuery.getCouponCode();
    const isCouponApplied = paymentPlansQuery.isPaymentCouponApplied();

    const [state, setState] = useState({
        practiceId: null,
        publicKey: null,
        paymentPlan,
        paymentPeriod,
        paymentPrice,
        addOns: [],
        isSubmitted: false,
        couponCode:couponCode,
        coupon: coupon,
        isCouponApplied: isCouponApplied,
        agreements: paymentPeriod.agreements,
        isPaymentPlanApplied: false,
        errors: {},
        exclusivePrices:[]
    } as FinishOnboardingComponentState);

    const handleSelectingAddOn = (id: number, value: boolean) => {
        const addOns = state.addOns;
        const addOn = addOns.find(x => x.id === id);
        addOn.isSelected = value;

        setState({ ...state, addOns });
    }

    const getSelectedAddOns = () => {
        return state.addOns.filter(i => i.isSelected);
    }

    const getSelectedAddOnsPrice = () => {
        return getSelectedAddOns().reduce((a, b) => a + b.price, 0);
    }

    const handlePriceSelect = (id: number) => {
        const paymentPrice = paymentPeriod.prices.find(x => x.id === id);
        setState({ ...state, paymentPrice });
    }

    const handleCouponChanges = (value: string) => {
        setState({ ...state, couponCode: value });
    }

    const applyPaymentPlan = () => {
        paymentPlansService.updateSelectedPeriod(state.paymentPeriod);
        paymentPlansStore.setCouponApplied(state.coupon);
        setState(state => ({ ...state, isPaymentPlanApplied: true }));
    }

    const applyCoupon = (code: string) => {
        const model: ApplyCouponModel = {
            paymentPeriodId: state.paymentPeriod.id,
            paymentPriceType: state.paymentPrice.type,
            code: code
        };

        const action = paymentPlansService.newPromoCodeApply(model);

        action.subscribe((exclusivePrices: PaymentPriceModel[]) => {
            if (exclusivePrices && exclusivePrices.length) {
                const adjustedPrices = paymentPeriod.prices.map(price => {
                    const exclusivePrice = exclusivePrices.find(x => x.strategy === price.strategy);
                    return exclusivePrice ?? price;
                });
    
                // we need the complete coupon model
                const retrievedCoupon = exclusivePrices[0].paymentCoupon;
    
    
                const newSelectedPeriod = Object.assign(paymentPeriod, {
                    prices: adjustedPrices
                });
    
                const addOns = state.addOns;
    
                const freeAddOnsPrice = adjustedPrices.find(x => x.isAddOnsForFree);
                if (freeAddOnsPrice) {
                    addOns.forEach(x => {
                        if (freeAddOnsPrice.freeAddOnsTypes.includes(x.orderType)) {
                            x.price = 0;
                        }
                    });
                }
    
                const paymentPrice = newSelectedPeriod
                    .prices
                    .find(x => x.paymentCoupon?.code === retrievedCoupon.code);

                if (paymentPrice) {
                    setState(state => ({
                        ...state,
                        addOns: addOns,
                        couponCode: code,
                        paymentPeriod: newSelectedPeriod,
                        paymentPrice: paymentPrice,
                        isCouponApplied: true,
                        exclusivePrices: exclusivePrices
                    }));
        
                    paymentPlansService.updateSelectedPeriod(newSelectedPeriod);
                    paymentPlansStore.setCouponApplied(retrievedCoupon);
                }
            }
        }, () => {
        });
    }

    const renderPaymentForm = () => {
        const totalPrice = state.paymentPrice.price + state.paymentPrice.startupFee + getSelectedAddOnsPrice();

        return (
            <Box>
                <Box mt={2}>
                    {
                        !state.isSubmitted
                            ? <WildHealthButton id="finish-buy-subscription" onClick={() => handlePaymentSubmit()} type="submit">Confirm Purchase </WildHealthButton>
                            : <WildHealthLinearProgress />
                    }
                </Box>
                <Box mt={2}>
                    <span className="text-bold">(Total Charge: {displayMoney(totalPrice, '$')})</span>
                </Box>
            </Box>
        )
    }

    /**
     * Handles payment submit
     */
    const handlePaymentSubmit = () => {

        setState({ ...state, isSubmitted: true });

        const buyNewSubscriptionModel: BuyNewSubscriptionModel = {
            paymentPeriodId: state.paymentPeriod.id,
            paymentPriceId: state.paymentPrice.id,
            addOnIds: state.addOns.filter(x => x.isSelected).map(x => x.id),
            founderId: null,
            agreements: state.paymentPeriod.agreements.map(x => ({ agreementId: x.id, isConfirmed: true })),
            promoCode: state.couponCode
        }
        
        subscriptionService.Replace(buyNewSubscriptionModel).subscribe(
            patient => {
                if (mobileGetStartedPath) {
                    authService.reLogin(authQuery.getTargetLocationId()).subscribe(
                        () => {
                            navigationService.toMobileBuySubscriptionSuccess(history, patient);
                            window.location.reload();
                        }
                    );

                } else {
                    authService.reLogin(authQuery.getTargetLocationId()).subscribe(
                        () => {
                            navigationService.toBuySubscriptionSuccess(history, patient);
                            window.location.reload();
                        }
                    );

                }
            },
            () => {
                setState({
                    ...state,
                    isSubmitted: false
                });
            }
        );
    };

    /**
     * Load all payment plans
     * Manage subscriptions with auto-cleanup
     */
    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<MerchantCredentialsModel>(paymentQuery.credentials$, credentials => {
                setState(state => ({
                    ...state,
                    publicKey: credentials?.publicKey,
                    practiceId: credentials?.practiceId
                }));
            }),
            onEmit<PaymentPlanModel>(paymentPlansQuery.selectedPlan$, selectedPlan => {
                setState(state => ({ ...state, selectedPlan }));
            }),
            onEmit<AddOnModel[]>(addOnsQuery.addOns$, addOns => {
                const items = addOns.map(i => {
                    return {
                        ...i,
                        isSelected: i.orderType === OrderType.Lab
                    }
                });

                let isCouponApplied=false;
                if(state.isCouponApplied && !!state.exclusivePrices){
                    const addOns = items;
                    const freeAddOnsPrice = state.exclusivePrices.find(x => x.isAddOnsForFree);
                    if (freeAddOnsPrice) {
                        addOns.forEach(x => {
                            if (freeAddOnsPrice.freeAddOnsTypes.includes(x.orderType)) {
                                x.price = 0;
                            }
                        });
                    }
                    isCouponApplied = true;
                }
                setState(state => ({ ...state, addOns: items, isCouponApplied: isCouponApplied }));
            }),
        ];

        paymentService.getCredentials(authQuery.getCurrentPracticeId());
        profileService.getPatientProfile().subscribe((profile) => {
            addOnsService.getOptional(paymentPlan.id, profile.gender);
        });

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [paymentPlan]);

    return [
        state,
        handlePriceSelect,
        renderPaymentForm,
        handleSelectingAddOn,
        getSelectedAddOns,
        handleCouponChanges,
        applyCoupon,
        applyPaymentPlan
    ]
}
