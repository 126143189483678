import { Box, useMediaQuery, useTheme, } from '@material-ui/core';
import React from 'react';
import { AuthenticatedLayoutComponent } from '../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { BackTitleComponent } from '../../../../components/pageTitleComponent/PageTitleComponent';
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from "../../../../models/menu.models";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { HealthLogQuestionnaireResultComponent } from "../../components/healthLogQuestionnaireResultComponent/HealthLogQuestionnaireResultComponent";
import { HealthLogQuestionnaireSurveyComponent } from "../../components/healthLogQuestionnaireSurveyComponent/HealthLogQuestionnaireSurveyComponent";
import { InitialQuestionnaireResultComponent } from "../../components/initialQuestionnaireResultComponent/InitialQuestionnaireResultComponent";
import { InitialQuestionnaireResultComponentHealthForms } from "../../components/initialQuestionnaireResultComponent/InitialQuestionnaireResultComponentHealthForms";
import { MedicalHistoryQuestionnaireSurveyComponent } from "../../components/medicalHistoryQuestionnaireSurveyComonent/MedicalHistoryQuestionnaireSurveyComponent";
import { HealthGoalsQuestionnaireSurveyComponent } from "../../components/healthGoalsQuestionnaireSurveyComponent/HealthGoalsQuestionnaireSurveyComponent";
import { renderQuestionnaireName } from "../../helpers/render-questionnaire-name";
import { QuestionnaireSubType, QuestionnaireType } from '../../models/questionnaire.models';
import { AuthorizedInitialQuestionnaireSurveyComponent } from "../initialQuestionnaireSurveyComponent/InitialQuestionnaireSurveyComponent";
import { DetailedQuestionnaireSurveyComponent } from "../../components/detailedQuestionnaireSurveyComponent/DetailedQuestionnaireSurveyComponent";
import { useFacade } from "./patientQuestionnairePage.hooks";
import { useStyles } from "./patientQuestionnairePage.styles";
import { FollowUpFormSurveyComponent } from '../../components/followUpFormSurveyComponent/FollowUpFormSurveyComponent';
import { FollowUpFormResultComponent } from '../../components/followUpFormResultComponent/FollowUpFormResultComponent';

export const PatientQuestionnairePage: React.FC = (props: any) => {
    const questionnaireResultId = Number(props.match.params.questionnaireResultId);
    const [
        {
            result,
            profile,
            questionnaire,
            isLoading
        },
        handleGoToQuestionnaires,
        handleGoToDashboard
    ] = useFacade(questionnaireResultId);
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const getQuestionnaire = () => {
        switch (questionnaire.type) {
            case QuestionnaireType.Initial: return <AuthorizedInitialQuestionnaireSurveyComponent />;
            case QuestionnaireType.HealthLog: return <HealthLogQuestionnaireSurveyComponent result={result} questionnaire={questionnaire} />;
            case QuestionnaireType.HealthForms:
                switch (questionnaire.subType) {
                    case QuestionnaireSubType.MedicalHistoryIncomplete: return <MedicalHistoryQuestionnaireSurveyComponent gender={profile.gender} questionnaire={questionnaire} result={result} />;
                    case QuestionnaireSubType.GoalsIncomplete: return <HealthGoalsQuestionnaireSurveyComponent gender={profile.gender} questionnaire={questionnaire} result={result} />;
                    case QuestionnaireSubType.DetailedHistoryIncomplete: return <DetailedQuestionnaireSurveyComponent gender={profile.gender} questionnaire={questionnaire} result={result} />;
                    default: return <></>;
                }
            case QuestionnaireType.FollowUpCallForms: return <FollowUpFormSurveyComponent questionnaire={questionnaire} result={result} />
            default: return <></>;
        }
    }

    const getResult = () => {
        switch (questionnaire.type) {
            case QuestionnaireType.HealthForms: return <InitialQuestionnaireResultComponentHealthForms gender={profile.gender} result={result} questionnaire={questionnaire} />;
            case QuestionnaireType.Initial: return <InitialQuestionnaireResultComponent gender={profile.gender} result={result} questionnaire={questionnaire} />;
            case QuestionnaireType.HealthLog: return <HealthLogQuestionnaireResultComponent gender={profile.gender} result={result} questionnaire={questionnaire} />;
            case QuestionnaireType.FollowUpCallForms: return <FollowUpFormResultComponent gender={profile.gender} result={result} questionnaire={questionnaire} />;
            default: return <></>;
        }
    }

    const renderContent = () => {
        switch (!result.submittedAt) {
            case true: return getQuestionnaire();
            case false: return getResult();
        }
    }

    const isBghide = () => {
        return !!(!isLoading && result.submittedAt && questionnaire.type === QuestionnaireType.Initial)
    }

    return (
        <AuthenticatedLayoutComponent
            title={TitlesService.questionnaireTitle}
            pageName='Medical History'
            selectedMenuItem={MenuItemTypes.HealthForms}
            hideBackground={isBghide()}

        >
            <Box pb={isSmallScreen ? "10px" : 2.5} className={classes.bgMain}>
                <BackTitleComponent
                    title={isSmallScreen ? 'Back to Dashboard' : 'Back to Health Forms'}
                    action={() => { isSmallScreen ? handleGoToDashboard() : handleGoToQuestionnaires() }}
                />
                <Box px={2} pt={isSmallScreen && "20px"} fontSize={24} fontWeight={isSmallScreen ? 600 : 500}>{!isLoading && renderQuestionnaireName(result)} </Box>
            </Box>

            <Box>
                {
                    isLoading
                        ? <WildHealthLinearProgress />
                        : renderContent()
                }
            </Box>
        </AuthenticatedLayoutComponent>
    )
}
