import {Margins, PageSize} from 'pdfmake/interfaces';
import {
    CheckAnswer,
    emptyCheckAnswer,
    QuestionModel,
    QuestionType
} from '../../questionnaire/models/questionnaire.models';
import {PatientMedicationModel} from "../../patientSupplements/models/patientMedicationModel";
import {PatientSupplementModel} from "../../patientSupplements/models/patientSupplementModel";
import {
    AttachmentModel,
    FollowUpContent,
    GoalModel,
    HistoryAndPhysicalContent,
    IccContent,
    MdmMode,
    MdmModel,
    MdmPatientType,
    NoteContentModel,
    NoteGoalsVersion,
    NoteMarkdownTypes,
    NoteModel,
    NotesType,
    SoapContent
} from "../models/notes.models";
import {getInitQuestionnaires, NoteRecommendation} from "../constants/initialConsult.constants";
import moment from "moment";
import pdfMake from 'pdfmake/build/pdfmake';
import {authQuery} from "../../auth/stores/auth";
import {logoService} from "../../../services/logo.service";
import {colors} from '../../common/constants/colors';
import {toTimeZoneDate} from "../../timezones/helpers/timezone";
import {timezonesQuery} from "../../timezones/stores/timezones";
import {notesService} from './notes.service';
import {Observable} from "rxjs";
import {YesNo} from '../../inputs/models/input.models';
import {ApoeSoursConstants} from '../../healthReport/constants/apoe.constants';
import {mdmCodesService} from './mdmCodes.service';
import {pinyonFont} from "../../../styles/Pinyon-Script";
import {isFeatureFlag} from "../../common/components/featureFlags/featureFlags";
import {FeatureFlag} from "../../common/components/featureFlags/featureFlags.models";

const pdfMakeX = require('pdfmake/build/pdfmake.js');
const pdfFontsX = require('pdfmake-unicode/dist/pdfmake-unicode.js');
const dateFormat = "MM/DD/YYYY";

pdfMakeX.vfs = pdfFontsX.pdfMake.vfs;
window.pdfMake.vfs["Pinyon-Script.ttf"] = pinyonFont;

pdfMake.fonts={
    Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-Italic.ttf'
    },
    PinyonScript: {
        normal: 'Pinyon-Script.ttf',
        bold: 'Pinyon-Script.ttf',
        italics: 'Pinyon-Script.ttf',
        bolditalics: 'Pinyon-Script.ttf'
    }
}

export abstract class NotesPdfService {
    protected pdfDocument = {
        pageSize: "LETTER" as PageSize,
        pageMargins: [60, 40, 60, 50] as Margins,
        header: null,
        footer: function (currentPage, pageCount) {
            const result = {
                table: {
                    widths: ['*'],
                    body: []
                },
                layout: 'noBorders'
            }

            if (currentPage === pageCount) {
                if (authQuery.isWildHealthPractice()) {
                    result.table.body.push([
                        {
                            text: 'p: 800.975.1562 | e: info@wildhealth.com | a: 535 Wellington Way # 330 Lexington KY 40503',
                            alignment: 'center',
                            style: 'footerText',
                        }

                    ]);
                }

                result.table.body.push([{
                    text: [
                        {
                            text: 'Privacy Policy',
                            style: 'footerText',
                            link: 'https://www.wildhealth.com/privacy-policy'
                        },
                        {
                            text: ' | ',
                            style: 'footerText'
                        },
                        {
                            text: 'Terms of Use',
                            style: 'footerText',
                            link: 'https://www.wildhealth.com/terms-of-use'
                        },
                        {
                            text: ' | ',
                            style: 'footerText'
                        },
                        {
                            text: 'Copyright ' + new Date().getFullYear(),
                            style: 'footerText'
                        },

                    ],
                    alignment: 'center',
                    style: 'footerText'
                }]);
            }

            result.table.body.push([
                {
                    text: currentPage + '.',
                    alignment: 'right',
                    style: 'footerText',
                    margin: [0, 0, 20, 0]
                }
            ]);

            return result;

        },
        content: [],
        styles: {
            title: {
                fontSize: 18,
                bold: true,
                color: colors.main
            },
            titleHeader: {
                fontSize: 20,
                bold: true,
                color: colors.black
            },
            subTitle: {
                fontSize: 15,
                color: colors.main
            },
            text: {
                fontSize: 12,
            },
            empty: {
                background: colors.white
            },
            link: {
                color: colors.main
            },
            question: {
                fontSize: 12,
                italics: true
            },
            footerText: {
                fontSize: 9
            },
            h1: {
                fontSize: 18,
                bold: true,
            },
            h2: {
                fontSize: 16,
                bold: true,
            },
            h3: {
                fontSize: 14,
                bold: true,
            },
            bold: {
                bold: true,
            },
            italic: {
                italics: true,
            },
            blockquote: {
                background: colors.stroke,
            },
            line: {
                decorationColor: colors.stroke,
                color: colors.white,
            },
            signedByText: {
                font: 'PinyonScript',
                fontWeight: 'normal',
                fontSize: 24,
            },
        },
    }

    protected renderSignature(pdfDocument, note: NoteModel, isAmended: boolean, noteId: number) {
        const timezone = timezonesQuery.getMyTimezone();
        const lastNote = note.id == noteId ? note : note.amendedNotes.find(x => x.id == noteId);
        const completedAt = moment(toTimeZoneDate(lastNote.completedAt, timezone));
        const completedBy = lastNote.completedBy
            ? lastNote.completedBy
            : lastNote.employee.firstName + ' ' + lastNote.employee.lastName;

        pdfDocument.content.push({
            text: `${completedBy}`,
            alignment: "right",
            style: "signedByText",
            margin: [0, 36, 0, 0]
        });

        pdfDocument.content.push({
            text: isAmended ? `Amended and electronically signed by ${completedBy}` : `Electronically signed by ${completedBy}`,
            alignment: "right",
            style: "text",
            margin: [0, 5, 0, 0]
        });

        pdfDocument.content.push({
            text: "On " + completedAt.format('ll') + ", at " + completedAt.format('LT'),
            alignment: "right",
            style: "text",
            margin: [0, 5, 0, 0]
        });
    }

    protected personalizeFooter(note: NoteModel) {
        this.pdfDocument.footer = function (currentPage, pageCount) {
            const result = {
                table: {
                    widths: ['*'],
                    body: []
                },
                layout: 'noBorders'
            }

            if (currentPage === pageCount) {
                if (authQuery.isWildHealthPractice()) {
                    result.table.body.push([
                        {
                            text: 'p: 800.975.1562 | e: info@wildhealth.com | a: 535 Wellington Way # 330 Lexington KY 40503',
                            alignment: 'center',
                            style: 'footerText',
                        }

                    ]);
                }

                result.table.body.push([{
                    text: [
                        {
                            text: 'Privacy Policy',
                            style: 'footerText',
                            link: 'https://www.wildhealth.com/privacy-policy'
                        },
                        {
                            text: ' | ',
                            style: 'footerText'
                        },
                        {
                            text: 'Terms of Use',
                            style: 'footerText',
                            link: 'https://www.wildhealth.com/terms-of-use'
                        },
                        {
                            text: ' | ',
                            style: 'footerText'
                        },
                        {
                            text: 'Copyright ' + new Date().getFullYear(),
                            style: 'footerText'
                        },

                    ],
                    alignment: 'center',
                    style: 'footerText'
                }]);
            }

            if (currentPage > 1) {
                result.table.body.push([
                    {
                        text: note.patient.firstName + " " + note.patient.lastName + ", " + note.patientId + "  " + currentPage + '.',
                        alignment: 'right',
                        style: 'footerText',
                        margin: [0, 0, 20, 0]
                    }
                ]);
            } else {
                result.table.body.push([
                    {
                        text: currentPage + '.',
                        alignment: 'right',
                        style: 'footerText',
                        margin: [0, 0, 20, 0]
                    }
                ]);
            }

            return result;

        }
    }

    constructor() {
        const self = this;
        var image = new Image();
        image.src = logoService.getLogo().clarityHeader;
        image.onload = function () {
            var canvas, ctx, dataURL, base64;
            canvas = document.createElement("canvas");
            ctx = canvas.getContext("2d");
            canvas.width = image.width;
            canvas.height = image.height;
            ctx.drawImage(image, 0, 0);
            dataURL = canvas.toDataURL("image/png");
            base64 = dataURL.replace(/^data:image\/png;base64,/, "");

            const headerImageFit = [615, 100];
            const headerImageMargins = [0, 0, 0, 0];
            const headerImageAlignment = '';

            self.pdfDocument.header = function (currentPage) {
                if (currentPage === 1)
                    return [
                        {
                            image: `data:image/jpeg;base64,${base64}`,
                            alignment: headerImageAlignment,
                            fit: headerImageFit,
                            margin: headerImageMargins
                        }
                    ]
            }
        };
    }

    protected getApoeInfo = (items) => {
        const apoeStatus = items.find(it => it.key === 'APOE_STATUS_VALUE');
        const apoeLabel = items.find(it => it.key === 'APOE_STATUS_LABEL');
        const apoeScore = items.find(it => it.key === 'APOE_ACCURACY_SCORE_VALUE');
        return !!apoeStatus ? `${apoeLabel?.value}: Apo-${(apoeStatus.value && apoeStatus.value !== null) ? apoeStatus.value : ''} (${parseFloat(apoeScore.value)}%)` : ''
    }

    protected checkMarkdown = (part: string) => {
        if (part.match(/^#\s/)) {
            return NoteMarkdownTypes.H1
        }
        if (part.match(/^##\s/)) {
            return NoteMarkdownTypes.H2
        }
        if (part.match(/^###\s/)) {
            return NoteMarkdownTypes.H3
        }
        if (part.match(/^[*][\d\w].*[*]$/)) {
            return NoteMarkdownTypes.Italic
        }
        if (part.match(/^[*][*][\d\w].*[*][*]$/)) {
            return NoteMarkdownTypes.Bold
        }
        if (part.match(/^[>]/)) {
            return NoteMarkdownTypes.Blockquote
        }
        if (part.match(/^[-]\s[\d\w].*/)) {
            return NoteMarkdownTypes.List
        }
        if (part.match(/^[-][-][-][-\s]*$/)) {
            return NoteMarkdownTypes.Line
        }
    }

    protected createPartHeader = (text: string) => {
        return text.replace(/^[#]*\s/, '');
    }

    protected createPartBold = (text: string) => {
        return text.replace(/[**]/g, '');
    }

    protected createPartItalic = (text: string) => {
        return text.replace(/[*]/g, '');
    }

    protected createPartBlockquote = (text: string) => {
        return text.replace(/^[>]/, '');
    }

    protected createPartCode = (text: string) => {
        return text.replace(/[`]/g, '');
    }

    protected createPartList = (text: string) => {
        return text.replace(/^[-]/, '●');
    }

    protected displayInfoDiagnosis = (text: string) => {
        if (!text) {
            return '';
        }
        return '- ' + text;
    }

    protected getPdfTextSections(text: string) {
        const res = [];
        const parts = text.split(/\n/);

        parts.forEach(part => {
            let trimPart = part.trim()

            if (this.checkMarkdown(trimPart)) {
                let updatePart;

                switch (this.checkMarkdown(trimPart)) {
                    case NoteMarkdownTypes.H1:
                    case NoteMarkdownTypes.H2:
                    case NoteMarkdownTypes.H3:
                        updatePart = this.createPartHeader(trimPart);
                        break;
                    case NoteMarkdownTypes.Bold:
                        updatePart = this.createPartBold(trimPart);
                        break;
                    case NoteMarkdownTypes.Italic:
                        updatePart = this.createPartItalic(trimPart);
                        break;
                    case NoteMarkdownTypes.Blockquote:
                        updatePart = this.createPartBlockquote(trimPart);
                        break;
                    case NoteMarkdownTypes.List:
                        updatePart = this.createPartList(trimPart);
                        break;
                    default:
                        updatePart = trimPart;
                }
                res.push({
                    text: `${updatePart}\n`,
                    style: this.checkMarkdown(trimPart)
                });
            } else {
                res.push({
                    text: `${part}\n`
                });
                ;

            }
        })

        return res;
    }

    protected getPdfAnswer(answer: string, question: QuestionModel) {
        if (!answer.length)
            return [{
                text: "(no answer)",
                style: "text",
                margin: [0, 5, 0, 0]
            }]

        if (question.type === QuestionType.CheckMany) {

            let checkAnswer: CheckAnswer;

            try {
                checkAnswer = JSON.parse(answer);

                if (!checkAnswer) {
                    checkAnswer = emptyCheckAnswer();
                }
            } catch (e) {
                checkAnswer = emptyCheckAnswer();
            }

            if (!checkAnswer.v.length && (!checkAnswer.o || !checkAnswer.o.length)) {
                return [{
                    text: "(no answer)",
                    style: "text",
                    margin: [0, 5, 0, 0]
                }]
            }

            return checkAnswer.v.map((variant) => ({
                text: variant,
                style: "text",
                margin: [0, 5, 0, 0]
            }));
        }

        return this.getPdfTextSections(answer)
    }

    protected getEmptyPdfSection(text: string) {
        return {
            table: {
                widths: ['*'],
                body: [
                    [
                        {
                            text: text,
                            fillColor: "#f8f8f8",
                            alignment: "center",
                            margin: [0, 15, 0, 15],
                            color: "#b2b4b5"
                        }
                    ]
                ]
            },
            layout: 'noBorders'
        }
    }

    protected clear() {
        this.pdfDocument.content = [];
    }
}

export class InitialConsultNotePdfService extends NotesPdfService {

    public downloadNotePdf(
        note: NoteModel,
        content: NoteContentModel,
        medications: PatientMedicationModel[],
        supplements: PatientSupplementModel[],
        isEmployee: boolean,
        noteId: number,
        amendedText?: string
    ): Observable<boolean> {
        return new Observable<boolean>(observer => {
            this.buildPdfContent(note, content, medications, supplements, isEmployee, noteId, amendedText)
            this.personalizeFooter(note);
            pdfMake.createPdf(this.pdfDocument).getBlob((blob) => {
                notesService.uploadNote(blob, noteId, isEmployee).subscribe(
                    () => {
                        const newFile = new Blob([blob], { type: 'application/pdf' });
                        const url = URL.createObjectURL(newFile);
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        a.download = note.name;
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);

                        observer.next(true);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                )
            })
        })
    }

    public buildPdfContent(
        note: NoteModel,
        content: NoteContentModel,
        medications: PatientMedicationModel[],
        supplements: PatientSupplementModel[],
        isEmployee: boolean,
        noteId: number,
        amendedText?: string
    ) {
        const internalContent: IccContent =  JSON.parse(content.internalContent);

        const recommendation = JSON.parse(content?.content) as string;
        this.clear();

        this.pdfDocument.content.push({
            text: "Initial Coaching Consult",
            style: "titleHeader",
            margin: [0, 80, 0, 0],
        })


        if (!note.appointment) {
            this.pdfDocument.content.push({
                text: `${note.patient.firstName} ${note.patient.lastName}, ${note.patientId}`,
                alignment: "left",
                style: "text",
                margin: [0, 5, 0, 0]
            })

        } else {
            this.pdfDocument.content.push({
                text: `${note.patient.firstName} ${note.patient.lastName}, ${note.patientId}    |    ${moment(note.appointment.date).format(dateFormat)} at ${moment(note.appointment.date).format('LT')}`,
                alignment: "left",
                style: "text",
                margin: [0, 5, 0, 0]
            })
        }

        if (isEmployee) {
            this.pdfDocument.content.push({
                text: "INTERNAL NOTES",
                style: "title",
                margin: [0, 20, 0, 10],
            });
            if (internalContent.internalContent) {
                this.pdfDocument.content.push({
                    text: this.getPdfTextSections(internalContent.internalContent),
                    style: "text",
                    margin: [0, 5, 0, 0]
                })
            } else {
                this.pdfDocument.content.push({
                    text: "NO INTERNAL NOTES",
                    style: "title",
                    margin: [0, 40, 0, 10],
                });
            }
        }

        if(internalContent?.intention.length > 0){
            this.pdfDocument.content.push({
                text: "INTENTION",
                style: "title",
                margin: [0, 40, 0, 0],
            })
    
            this.pdfDocument.content.push({
                text: this.getPdfTextSections(internalContent.intention),
                style: "text",
                margin: [0, 5, 0, 0]
            })
        }

        if(internalContent.systolicBloodPressure > 0 && internalContent.diastolicBloodPressure > 0){
            this.pdfDocument.content.push({
                text: "BLOOD PRESSURE",
                style: "title",
                margin: [0, 40, 0, 0],
            })
    
            this.pdfDocument.content.push({
                text: this.getPdfTextSections(`${internalContent.systolicBloodPressure} / ${internalContent.diastolicBloodPressure}`),
                style: "text",
                margin: [0, 5, 0, 0]
            })
        }

        if(internalContent.currentMedicalConditions.length > 0){
            this.pdfDocument.content.push({
                text: "PROBLEM LIST",
                style: "title",
                margin: [0, 40, 0, 0],
            })
    
            internalContent.currentMedicalConditions.map((item) => {
                this.pdfDocument.content.push({
                    text: this.getPdfTextSections(`${item.name}  ${item.value}`),
                    style: "text",
                    margin: [0, 5, 0, 0]
                })
            });
        }

        this.pdfDocument.content.push({
            text: "MEDICATIONS",
            style: "title",
            margin: [0, 20, 0, 10],
        })

        if (medications.length > 0) {
            medications.forEach((medication, i) => {
                this.pdfDocument.content.push({
                    text: `${i + 1}. ${medication.name}${medication.dosage ? `, ${medication.dosage}` : ''}`,
                    style: "text",
                    margin: [0, 5, 0, 0]
                })
            })
        } else {
            this.pdfDocument.content.push(this.getEmptyPdfSection("Medications are empty"));
        }

        this.pdfDocument.content.push({
            text: "SUPPLEMENTS",
            style: "title",
            margin: [0, 20, 0, 10],
        })

        if (supplements.length > 0) {
            supplements.forEach((supplement, i) => {
                const url = supplement.purchaseLink ? supplement.purchaseLink.startsWith('http://') || supplement.purchaseLink.startsWith('https://') ? supplement.purchaseLink : `http://${supplement.purchaseLink}` : '';

                const content: any = {
                    text: `${i + 1}. ${supplement.name} ${supplement.dosage ? `, ${supplement.dosage}` : ''}${url ? ` - Purchase here` : ''}`,
                    style: "text",
                    margin: [0, 5, 0, 0],
                };

                if (url)
                    content.link = url;

                this.pdfDocument.content.push(content);
            })
        } else {
            this.pdfDocument.content.push(this.getEmptyPdfSection("Supplements are empty"));
        }

        if(recommendation.length > 0){
            this.pdfDocument.content.push({
                text: "What are SNPs?",
                style: "title",
                margin: [0, 40, 0, 0],
            })
    
            this.pdfDocument.content.push({
                text: this.getPdfTextSections('A SNP (Single Nucleotide Polymorphism) is a small change in the genetic code where one base (A, T, C, or G) is swapped for another. These variations are the most common type of genetic difference between people. While most SNPs have no effect, some can influence how we look, how our bodies function, or our risk of certain diseases.'),
                style: "text",
                margin: [0, 5, 0, 0]
            })
    
            this.pdfDocument.content.push({
                text: this.getPdfTextSections('Imagine a gene as a tower made of LEGO blocks. Each block (nucleotide) has a specific place in the structure. A SNP would be like swapping out one specific LEGO block for a slightly different one. Most of the time, the tower still stands as intended, but occasionally, that one block could be crucial for the stability of the entire structure.'),
                style: "text",
                margin: [0, 5, 0, 0]
            })
            this.pdfDocument.content.push({
                text: "TODAY’S COACHING",
                style: "title",
                margin: [0, 20, 0, 0],
            })
    
            this.pdfDocument.content.push({
                text: this.getPdfTextSections(recommendation),
                style: "text",
                margin: [0, 5, 0, 0]
            })
        }

        if (isEmployee) {
            if (amendedText != null && amendedText.length > 0) {
                this.pdfDocument.content.push({
                    text: this.getPdfTextSections(`${amendedText}`),
                    style: "text",
                    margin: [0, 5, 0, 0],
                });
            }
        }

        this.renderSignature(this.pdfDocument, note, amendedText != null && amendedText.length > 0, noteId);
    }
}

export class InitialConsultNotePdfServiceOld extends NotesPdfService {

    public downloadNotePdf(
        note: NoteModel,
        content: NoteContentModel,
        medications: PatientMedicationModel[],
        supplements: PatientSupplementModel[],
        isEmployee: boolean,
        noteId: number,
        amendedText?: string
    ): Observable<boolean> {
        return new Observable<boolean>(observer => {
            this.buildPdfContent(note, content, medications, supplements, isEmployee, noteId, amendedText)
            this.personalizeFooter(note);
            pdfMake.createPdf(this.pdfDocument).getBlob((blob) => {
                notesService.uploadNote(blob, noteId, isEmployee).subscribe(
                    () => {
                        const newFile = new Blob([blob], { type: 'application/pdf' });
                        const url = URL.createObjectURL(newFile);
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        a.download = note.name;
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);

                        observer.next(true);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                )
            })
        })
    }

    public buildPdfContent(
        note: NoteModel,
        content: NoteContentModel,
        medications: PatientMedicationModel[],
        supplements: PatientSupplementModel[],
        isEmployee: boolean,
        noteId: number,
        amendedText?: string
    ) {

        const recommendations = JSON.parse(content?.content) as NoteRecommendation[];
        this.clear();

        this.pdfDocument.content.push({
            text: "Initial Coaching Consult",
            style: "titleHeader",
            margin: [0, 80, 0, 0],
        })


        if (!note.appointment) {
            this.pdfDocument.content.push({
                text: `${note.patient.firstName} ${note.patient.lastName}, ${note.patientId}`,
                alignment: "left",
                style: "text",
                margin: [0, 5, 0, 0]
            })

        } else {
            this.pdfDocument.content.push({
                text: `${note.patient.firstName} ${note.patient.lastName}, ${note.patientId}    |    ${moment(note.appointment.date).format(dateFormat)} at ${moment(note.appointment.date).format('LT')}`,
                alignment: "left",
                style: "text",
                margin: [0, 5, 0, 0]
            })
        }

        if (isEmployee) {
            this.pdfDocument.content.push({
                text: "INTERNAL NOTES",
                style: "title",
                margin: [0, 20, 0, 10],
            });
            if (content.internalContent) {
                this.pdfDocument.content.push({
                    text: this.getPdfTextSections(content.internalContent),
                    style: "text",
                    margin: [0, 5, 0, 0]
                })
            } else {
                this.pdfDocument.content.push({
                    text: "NO INTERNAL NOTES",
                    style: "title",
                    margin: [0, 40, 0, 10],
                });
            }
        }

        this.pdfDocument.content.push({
            text: "RECOMMENDATIONS",
            style: "title",
            margin: [0, 40, 0, 0],
        })

        recommendations.filter(x => x.isSelected).forEach(recommendation => {
            this.pdfDocument.content.push({
                text: `${recommendation.name}\n`,
                style: "subTitle",
                margin: [0, 10, 0, 0],
            })
            const contentParts = this.getPdfTextSections(recommendation.content);
            const recommendationSection = {
                text: [],
                margin: [0, 10, 0, 0],
            }
            contentParts.forEach(part => {
                recommendationSection.text.push({
                    text: part,
                    style: "text"
                })
            })
            this.pdfDocument.content.push(recommendationSection);
        })

        this.pdfDocument.content.push({
            text: "MEDICATIONS",
            style: "title",
            margin: [0, 20, 0, 10],
        })

        if (medications.length > 0) {
            medications.forEach((medication, i) => {
                this.pdfDocument.content.push({
                    text: `${i + 1}. ${medication.name}${medication.dosage ? `, ${medication.dosage}` : ''}`,
                    style: "text",
                    margin: [0, 5, 0, 0]
                })
            })
        } else {
            this.pdfDocument.content.push(this.getEmptyPdfSection("Medications are empty"));
        }

        this.pdfDocument.content.push({
            text: "SUPPLEMENTS",
            style: "title",
            margin: [0, 20, 0, 10],
        })

        if (supplements.length > 0) {
            supplements.forEach((supplement, i) => {
                const url = supplement.purchaseLink ? supplement.purchaseLink.startsWith('http://') || supplement.purchaseLink.startsWith('https://') ? supplement.purchaseLink : `http://${supplement.purchaseLink}` : '';

                const content: any = {
                    text: `${i + 1}. ${supplement.name}${supplement.dosage ? `, ${supplement.dosage}` : ''}${url ? ` - Purchase here` : ''}`,
                    style: "text",
                    margin: [0, 5, 0, 0],
                };

                if (url)
                    content.link = url;

                this.pdfDocument.content.push(content);
            })
        } else {
            this.pdfDocument.content.push(this.getEmptyPdfSection("Supplements are empty"));
        }

        this.pdfDocument.content.push({
            text: "QUESTIONNAIRE",
            style: "title",
            margin: [0, 20, 0, 0],
        })

        getInitQuestionnaires().forEach((section) => {
            this.pdfDocument.content.push({
                text: section.name,
                style: "subTitle",
                margin: [0, 15, 0, 0],
            });

            section.questions.forEach((question) => {
                this.pdfDocument.content.push({
                    text: question.displayName,
                    style: "question",
                    margin: [0, 10, 0, 0],
                    italic: true
                });
                const answer = content.logs.find(x => x.key === question.name)?.value ?? '';

                const answerData = this.getPdfAnswer(answer, question);
                answerData.forEach(answerItem => {
                    this.pdfDocument.content.push(answerItem);
                })
            })
        })

        if (isEmployee) {
            if (amendedText != null && amendedText.length > 0) {
                this.pdfDocument.content.push({
                    text: this.getPdfTextSections(`${amendedText}`),
                    style: "text",
                    margin: [0, 5, 0, 0],
                });
            }
        }

        this.renderSignature(this.pdfDocument, note, amendedText != null && amendedText.length > 0, noteId);
    }
}

export class FollowUpNotePdfService extends NotesPdfService {
    public downloadNotePdf(note: NoteModel, content: FollowUpContent, isEmployee: boolean, noteId: number, internalContent: string, flags, amendedList?: Array<string>): Observable<boolean> {
        return new Observable<boolean>(observer => {
            this.buildPdfContent(note, content, isEmployee, noteId, internalContent, flags, amendedList)
            this.personalizeFooter(note);
            pdfMake.createPdf(this.pdfDocument).getBlob((blob) => {
                notesService.uploadNote(blob, noteId, isEmployee).subscribe(
                    () => {
                        const newFile = new Blob([blob], { type: 'application/pdf' });
                        const url = URL.createObjectURL(newFile);
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        a.download = note.name;
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);

                        observer.next(true);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                )
            });
        })
    }

    private renderGoals(name: string, goals: GoalModel[]) {
        this.pdfDocument.content.push({
            text: name,
            style: "subTitle",
            margin: [0, 15, 0, 0],
        });

        goals.forEach((item, index) => {
            this.pdfDocument.content.push({
                text: `Goal ${index + 1}`,
                style: "subTitle",
                margin: [0, 15, 0, 0]
            });

            
            if(item.completionDate){
                this.pdfDocument.content.push({
                    text: this.getPdfTextSections(`Completion date ${moment(item.completionDate).format(dateFormat)} at ${moment(item.completionDate).format('LT')}`),
                    style: "text",
                    margin: [1, 10, 0, 0]
                });
    
            }

            this.pdfDocument.content.push({
                text: this.getPdfTextSections(item.name),
                style: "text",
                margin: [1, 10, 0, 0]
            });

            if (item.interventions.length) {
                this.pdfDocument.content.push({
                    text: 'Interventions',
                    style: "subTitle",
                    margin: [30, 15, 0, 0],
                });

                item.interventions.forEach((item, index) => {
                    this.pdfDocument.content.push({
                        text: this.getPdfTextSections(`${index + 1}. ` + `${item.name}`),
                        style: "text",
                        margin: [30, 5, 0, 0],
                    });
                });
            } else {
                this.pdfDocument.content.push({
                    text: 'No Interventions',
                    style: "subTitle",
                    margin: [0, 5, 0, 10],
                });
            }
        });
    }

    private renderEducation(text: string) {
        this.pdfDocument.content.push({
            text: `Education:`,
            style: "subTitle",
            margin: [0, 15, 0, 0]
        });

        this.pdfDocument.content.push({
            text: text,
            style: "text",
            margin: [1, 10, 0, 0]
        });
    }

    public buildPdfContent(note: NoteModel, content: FollowUpContent, isEmployee: boolean, noteId: number, internalContent: string, flags, amendedList?: Array<string>) {
        this.clear();

        this.pdfDocument.content.push({
            text: "Follow Up",
            style: "titleHeader",
            margin: [0, 80, 0, 0],
        })

        if (!note.appointment) {
            this.pdfDocument.content.push({
                text: `${note.patient.firstName} ${note.patient.lastName}, ${note.patientId}`,
                alignment: "left",
                style: "text",
                margin: [0, 5, 0, 0]
            })

        } else {
            this.pdfDocument.content.push({
                text: `${note.patient.firstName} ${note.patient.lastName}, ${note.patientId}    |    ${moment(note.appointment.date).format(dateFormat)} at ${moment(note.appointment.date).format('LT')}`,
                alignment: "left",
                style: "text",
                margin: [0, 5, 0, 0]
            })
        }

        if (isEmployee) {
            if (internalContent) {
                this.pdfDocument.content.push({
                    text: "INTERNAL NOTES",
                    style: "title",
                    margin: [0, 20, 0, 10],
                });

                this.pdfDocument.content.push({
                    text: this.getPdfTextSections(internalContent),
                    style: "text",
                    margin: [0, 5, 0, 0]
                })
            } else {
                this.pdfDocument.content.push({
                    text: "INTERNAL NOTES",
                    style: "title",
                    margin: [0, 20, 0, 10],
                });
                this.pdfDocument.content.push({
                    text: "NO INTERNAL NOTES",
                    style: "title",
                    margin: [0, 40, 0, 10],
                });
            }
        }

        if (content?.goalsVersion === NoteGoalsVersion.New) {
            if (content?.goals.length) {
                this.pdfDocument.content.push({
                    text: "GOALS",
                    style: "title",
                    margin: [0, 40, 0, 0],
                });

                content.goals.forEach((item, index) => {
                    this.pdfDocument.content.push({
                        text: `Goal ${index + 1}`,
                        style: "subTitle",
                        margin: [0, 15, 0, 0]
                    });

                    if(item.completionDate){
                        this.pdfDocument.content.push({
                            text: this.getPdfTextSections(`Completion date ${moment(item.completionDate).format(dateFormat)} at ${moment(item.completionDate).format('LT')}`),
                            style: "text",
                            margin: [1, 10, 0, 0]
                        });
                    }

                    this.pdfDocument.content.push({
                        text: this.getPdfTextSections(item.name),
                        style: "text",
                        margin: [1, 10, 0, 0]
                    });

                    if (item.interventions.length) {
                        this.pdfDocument.content.push({
                            text: 'Interventions',
                            style: "subTitle",
                            margin: [30, 15, 0, 0],
                        });

                        item.interventions.forEach((item, index) => {
                            this.pdfDocument.content.push({
                                text: this.getPdfTextSections(`${index + 1}. ` + `${item.name}`),
                                style: "text",
                                margin: [30, 5, 0, 0],
                            });
                        });
                    } else {
                        this.pdfDocument.content.push({
                            text: 'No Interventions',
                            style: "subTitle",
                            margin: [0, 5, 0, 10],
                        });
                    }
                });
                return;
            }
            if (content.plan.dietGoals.length === 0
                && content.plan.exerciseGoals.length === 0
                && content.plan.sleepGoals.length === 0
                && content.plan.mindfulnessGoals.length === 0
                && content.plan.neuroGoals.length === 0
                && content.plan.longevityGoals.length === 0) {
                return this.pdfDocument.content.push({
                    text: "NO GOALS",
                    style: "title",
                    margin: [0, 40, 0, 10],
                });
            }
            this.pdfDocument.content.push({
                text: "GOALS",
                style: "title",
                margin: [0, 40, 0, 0],
            });
            if (content.plan.dietGoals.length > 0) {
                this.renderGoals('Diet', content.plan.dietGoals);
            }

            if (content.plan.dietEducations?.length > 0) {
                this.renderEducation(content.plan.dietEducations);
            }

            if (content.plan.exerciseGoals.length > 0) {
                this.renderGoals('Exercise/Recovery', content.plan.exerciseGoals);
            }

            if (content.plan.exerciseEducations?.length > 0) {
                this.renderEducation(content.plan.exerciseEducations);
            }

            if (content.plan.sleepGoals.length > 0) {
                this.renderGoals('Sleep', content.plan.sleepGoals);
            }

            if (content.plan.sleepEducations?.length > 0) {
                this.renderEducation(content.plan.sleepEducations);
            }

            if (content.plan.neuroGoals.length > 0) {
                this.renderGoals('Neuro', content.plan.neuroGoals);
            }

            if (content.plan.neuroEducations?.length > 0) {
                this.renderEducation(content.plan.neuroEducations);
            }

            if (content.plan.longevityGoals.length > 0) {
                this.renderGoals('Longevity', content.plan.longevityGoals);
            }

            if (content.plan.longevityEducations?.length > 0) {
                this.renderEducation(content.plan.longevityEducations);
            }
            if (content.plan.mindfulnessGoals?.length > 0) {
                this.renderGoals('Mindfulness', content.plan.mindfulnessGoals);
            }

            if (content.plan.mindfulnessEducations?.length > 0) {
                this.renderEducation(content.plan.mindfulnessEducations);
            }
        } else {
            if (content.goals.length) {
                this.pdfDocument.content.push({
                    text: "GOALS",
                    style: "title",
                    margin: [0, 40, 0, 0],
                });

                content.goals.forEach((item, index) => {
                    this.pdfDocument.content.push({
                        text: `Goal ${index + 1}`,
                        style: "subTitle",
                        margin: [0, 15, 0, 0]
                    });

                    if(item.completionDate){
                        this.pdfDocument.content.push({
                            text: this.getPdfTextSections(`Completion date ${moment(item.completionDate).format(dateFormat)} at ${moment(item.completionDate).format('LT')}`),
                            style: "text",
                            margin: [1, 10, 0, 0]
                        });
            
                    }

                    this.pdfDocument.content.push({
                        text: this.getPdfTextSections(item.name),
                        style: "text",
                        margin: [1, 10, 0, 0]
                    });

                    if (item.interventions.length) {
                        this.pdfDocument.content.push({
                            text: 'Interventions',
                            style: "subTitle",
                            margin: [30, 15, 0, 0],
                        });

                        item.interventions.forEach((item, index) => {
                            this.pdfDocument.content.push({
                                text: this.getPdfTextSections(`${index + 1}. ` + `${item.name}`),
                                style: "text",
                                margin: [30, 5, 0, 0],
                            });
                        });
                    } else {
                        this.pdfDocument.content.push({
                            text: 'No Interventions',
                            style: "subTitle",
                            margin: [0, 5, 0, 10],
                        });
                    }
                });
            } else {
                this.pdfDocument.content.push({
                    text: "NO GOALS",
                    style: "title",
                    margin: [0, 80, 0, 10],
                });
            }

        }

        const rxntIntegration = isFeatureFlag(flags, FeatureFlag.RxntIntegration);
        const medications = content.medicationsSupplements.medications.filter(i => !i.isInCurrent);
        const supplements = content.medicationsSupplements.supplements.filter(i => !i.isInCurrent);
        const rxntMedications = content.medicationsSupplements.rxntMedications?.filter(i => !i.isInCurrent);
        const fullscriptSupplements = content.medicationsSupplements.fullscriptSupplements;
        const stoppedMedications = content.medicationsSupplements.medications.filter(i => i.isStopped);
        const stoppedSupplements = content.medicationsSupplements.supplements.filter(i => i.isStopped);
        const stoppedRxntMedications = content.medicationsSupplements.rxntMedications?.filter(i => i.isStopped);

        if ((medications.length && !rxntIntegration) || (rxntMedications?.length && rxntIntegration) || supplements.length || fullscriptSupplements?.length) {
            this.pdfDocument.content.push({
                text: "Medications and Supplements Added During the Visit",
                style: "title",
                margin: [0, 30, 0, 10],
            });

            if (medications.length > 0 && !rxntIntegration) {
                this.pdfDocument.content.push({
                    text: 'Medications:',
                    style: "text",
                    margin: [0, 8, 0, 0]
                });

                medications.forEach((item, index) => {
                    this.pdfDocument.content.push({
                        text: `${index + 1}. ${item.name}${item.dosage ? `, ${item.dosage}` : ''}`,
                        style: "text",
                        margin: [0, 5, 0, 0]
                    })
                });
            }

            if (rxntMedications?.length > 0 && rxntIntegration) {
                this.pdfDocument.content.push({
                    text: 'Medications:',
                    style: "text",
                    margin: [0, 8, 0, 0]
                });

                rxntMedications?.forEach((item, index) => {
                    this.pdfDocument.content.push({
                        text: `${index + 1}. ${item.name}${item.dosingInstructions ? `, ${item.dosingInstructions}` : ''}`,
                        style: "text",
                        margin: [0, 5, 0, 0]
                    })
                });
            }

            if (supplements.length > 0 || fullscriptSupplements?.length > 0) {
                this.pdfDocument.content.push({
                    text: 'Supplements:',
                    style: "text",
                    margin: [0, 8, 0, 0]
                });

                supplements.forEach((item, index) => {
                    const url = item.purchaseLink
                        ? item.purchaseLink.startsWith('http://') || item.purchaseLink.startsWith('https://')
                            ? item.purchaseLink
                            : `http://${item.purchaseLink}`
                        : '';

                    const content: any = {
                        text: `${index + 1}. ${item.name}${item.dosage ? `, ${item.dosage}` : ''}${url ? ` - Purchase here` : ''}`,
                        style: "text",
                        margin: [0, 5, 0, 0],
                    };

                    if (url) {
                        content.link = url;
                    }

                    this.pdfDocument.content.push(content);
                });

                (fullscriptSupplements ?? []).forEach((item, index) => {
                    const url = item.purchaseLink
                        ? item.purchaseLink.startsWith('http://') || item.purchaseLink.startsWith('https://')
                            ? item.purchaseLink
                            : `http://${item.purchaseLink}`
                        : '';

                    const content: any = {
                        text: `${index + 1}. ${item.name}${item.dosage ? `, ${item.dosage}` : ''}${url ? ` - Purchase via Fullscript` : ''}`,
                        style: "text",
                        margin: [0, 5, 0, 0],
                    };

                    if (url) {
                        content.link = url;
                    }

                    this.pdfDocument.content.push(content);
                });
            }
        } else {
            this.pdfDocument.content.push({
                text: "Medications and Supplements Added During the Visit",
                style: "title",
                margin: [0, 30, 0, 10],
            });

            this.pdfDocument.content.push({
                text: 'No changes were made during this visit.',
                style: "text",
                margin: [0, 8, 0, 0]
            });
        }

        if ((stoppedMedications.length && !rxntIntegration) || (stoppedRxntMedications?.length && rxntIntegration) || stoppedSupplements.length) {
            let globalIndex = 0;

            this.pdfDocument.content.push({
                text: "MEDICATIONS/SUPPLEMENTS STOPPED",
                style: "title",
                margin: [0, 20, 0, 10],
            });

            if (stoppedMedications.length > 0 && !rxntIntegration) {
                stoppedMedications.forEach((item, index) => {
                    this.pdfDocument.content.push({
                        text: `${index + 1}. ${item.name}${item.dosage ? `, ${item.dosage}` : ''}`,
                        style: "text",
                        margin: [0, 5, 0, 0]
                    })
                });
            }

            if (stoppedRxntMedications?.length > 0 && rxntIntegration) {
                stoppedRxntMedications?.forEach((item, index) => {
                    this.pdfDocument.content.push({
                        text: `${index + 1}. ${item.name}${item.dosingInstructions ? `, ${item.dosingInstructions}` : ''}`,
                        style: "text",
                        margin: [0, 5, 0, 0]
                    })
                });
            }

            stoppedSupplements.forEach((item) => {
                const url = item.purchaseLink ? item.purchaseLink.startsWith('http://') || item.purchaseLink.startsWith('https://') ? item.purchaseLink : `http://${item.purchaseLink}` : '';

                const content: any = {
                    text: `${++globalIndex}. ${item.name}${item.dosage ? `, ${item.dosage}` : ''}${url ? ` - Purchase here` : ''}`,
                    style: "text",
                    margin: [0, 5, 0, 0],
                };

                if (url)
                    content.link = url;

                this.pdfDocument.content.push(content);
            });
        } else {
            this.pdfDocument.content.push({
                text: "NO MEDICATIONS/SUPPLEMENTS STOPPED",
                style: "title",
                margin: [0, 20, 0, 10],
            });
        }

        if (content.todayCoachingContent) {
            this.pdfDocument.content.push({
                text: "TODAY’S COACHING",
                style: "title",
                margin: [0, 20, 0, 10],
            });

            this.pdfDocument.content.push({
                text: this.getPdfTextSections(content.todayCoachingContent),
                style: "text",
                margin: [0, 5, 0, 0]
            })
        }


        if (content.steps.length) {
            this.pdfDocument.content.push({
                text: "NEXT STEPS",
                style: "title",
                margin: [0, 20, 0, 10],
            });

            content.steps.forEach((item, index) => {
                this.pdfDocument.content.push({
                    text: this.getPdfTextSections(`${index + 1}. ` + `${item.name}`),
                    style: "text",
                    margin: [0, 5, 0, 0]
                })
            });
        } else {
            this.pdfDocument.content.push({
                text: "NO NEXT STEPS",
                style: "title",
                margin: [0, 20, 0, 10],
            });
        }

        if (isEmployee) {
            if (amendedList != null && amendedList.length > 0) {
                amendedList.map(text => {
                    this.pdfDocument.content.push({
                        text: this.getPdfTextSections(`${text}`),
                        style: "text",
                        margin: [0, 5, 0, 0],
                    });
                });
            }
        }

        this.renderSignature(this.pdfDocument, note, amendedList != null && amendedList.length > 0, noteId);
    }
}

export class InternalNotePdfService extends NotesPdfService {

    public downloadNotePdf(note: NoteModel, content: string, attachmentsEmployee: AttachmentModel[], isEmployee: boolean, noteId: number, isContentUpdated: boolean, isAttachmentsUpdated: boolean): Observable<boolean> {
        return new Observable<boolean>(observer => {
            this.buildPdfContent(note, content, attachmentsEmployee, isEmployee, isContentUpdated, isAttachmentsUpdated, noteId);
            pdfMake.createPdf(this.pdfDocument).getBlob((blob) => {
                notesService.uploadNote(blob, noteId, isEmployee).subscribe(
                    () => {
                        const newFile = new Blob([blob], { type: 'application/pdf' });
                        const url = URL.createObjectURL(newFile);
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        a.download = note.name;
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);

                        observer.next(true);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                )
            })
        });
    }

    public buildPdfContent(note: NoteModel, content: string, attachmentsEmployee: AttachmentModel[], isEmployee: boolean, isContentUpdated: boolean, isAttachmentsUpdated: boolean, noteId: number) {
        this.clear();
        this.pdfDocument.content.push({
            text: note.type === NotesType.Blank ? "Blank" : "Internal",
            style: "titleHeader",
            margin: [0, 80, 0, 0],
        })

        if (!note.appointment) {
            this.pdfDocument.content.push({
                text: `${note.patient.firstName} ${note.patient.lastName}, ${note.patientId}`,
                alignment: "left",
                style: "text",
                margin: [0, 5, 0, 0]
            })

        } else {
            this.pdfDocument.content.push({
                text: `${note.patient.firstName} ${note.patient.lastName}, ${note.patientId}    |    ${moment(note.appointment.date).format(dateFormat)} at ${moment(note.appointment.date).format('LT')}`,
                alignment: "left",
                style: "text",
                margin: [0, 5, 0, 0]
            })
        }

        this.pdfDocument.content.push({
            text: "Notes",
            style: "title",
            margin: [0, 40, 0, 0],
        });

        this.pdfDocument.content.push({
            text: content,
            style: "text",
            margin: [0, 0, 0, 0],
        });

        if (attachmentsEmployee?.length > 0) {
            this.pdfDocument.content.push({
                text: "Attachments:",
                style: "title",
                margin: [0, 30, 0, 0],
            });

            attachmentsEmployee?.forEach((item, index) => {
                this.pdfDocument.content.push({
                    text: `Attachment ${index + 1} - ${item.fileName}`,
                    style: "text",
                    margin: [0, 5, 0, 0]
                })
            });
        }

        if (isEmployee) {
            if (isContentUpdated || isAttachmentsUpdated) {
                this.pdfDocument.content.push({
                    text: this.getPdfTextSections(`Amended areas in this note:`),
                    style: "text",
                    margin: [0, 5, 0, 0],
                });
            }
            if (isContentUpdated) {
                this.pdfDocument.content.push({
                    text: this.getPdfTextSections(`Internal Content was updated.`),
                    style: "text",
                    margin: [0, 5, 0, 0],
                });
            }
            if (isAttachmentsUpdated) {
                this.pdfDocument.content.push({
                    text: this.getPdfTextSections(`Files was updated.`),
                    style: "text",
                    margin: [0, 5, 0, 0],
                });
            }
        }

        this.renderSignature(this.pdfDocument, note, isContentUpdated || isAttachmentsUpdated, noteId);
    }
}

export class SoapNotePdfService extends NotesPdfService {
    public downloadNotePdf(note: NoteModel, content: SoapContent, isEmployee: boolean, noteId: number, flags, amendedList?: Array<string>): Observable<boolean> {
        return new Observable<boolean>(observer => {
            mdmCodesService.get(note.type).subscribe(codes => {
                this.buildPdfContent(note, content, isEmployee, noteId, codes, amendedList);
                this.personalizeFooter(note);
                pdfMake.createPdf(this.pdfDocument).getBlob((blob) => {
                    notesService.uploadNote(blob, noteId, isEmployee).subscribe(
                        () => {
                            const newFile = new Blob([blob], { type: 'application/pdf' });
                            const url = URL.createObjectURL(newFile);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            a.download = note.name;
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);

                            observer.next(true);
                            observer.complete();
                        },
                        error => {
                            observer.error(error);
                            observer.complete();
                        }
                    )
                })
            })
        });
    }

    public buildPdfContent(note: NoteModel, content: SoapContent, isEmployee: boolean, noteId: number, codes: MdmModel[], flags, amendedList?: Array<string>) {
        this.clear();

        this.pdfDocument.content.push({
            text: "SOAP",
            style: "titleHeader",
            margin: [0, 80, 0, 0],
        })

        const rxntIntegration = isFeatureFlag(flags, FeatureFlag.RxntIntegration);
        const medications = content.medicationsSupplements.medications.filter(i => !i.isInCurrent);
        const supplements = content.medicationsSupplements.supplements.filter(i => !i.isInCurrent);
        const rxntMedications = content.medicationsSupplements.rxntMedications?.filter(i => !i.isInCurrent);
        const fullscriptSupplements = content.medicationsSupplements.fullscriptSupplements;
        const stoppedMedications = content.medicationsSupplements.medications.filter(i => i.isStopped);
        const stoppedSupplements = content.medicationsSupplements.supplements.filter(i => i.isStopped);
        const stoppedRxntMedications = content.medicationsSupplements.rxntMedications?.filter(i => i.isStopped);


        if (!note.appointment) {
            this.pdfDocument.content.push({
                text: `${note.patient.firstName} ${note.patient.lastName}, ${note.patientId}`,
                alignment: "left",
                style: "text",
                margin: [0, 5, 0, 0]
            })

        } else {
            this.pdfDocument.content.push({
                text: `${note.patient.firstName} ${note.patient.lastName}, ${note.patientId}    |    ${moment(note.appointment.date).format(dateFormat)} at ${moment(note.appointment.date).format('LT')}`,
                alignment: "left",
                style: "text",
                margin: [0, 5, 0, 0]
            })
        }

        if (isEmployee) {
            if (content.subjective.length > 0) {
                this.pdfDocument.content.push({
                    text: "Subjective",
                    style: "title",
                    margin: [0, 30, 0, 0],
                });

                this.pdfDocument.content.push({
                    text: this.getPdfTextSections(content.subjective),
                    style: "text",
                    margin: [0, 5, 0, 0],
                });
            }

            if ((medications.length && !rxntIntegration) || (rxntMedications?.length && rxntIntegration) || supplements.length || fullscriptSupplements?.length) {
                this.pdfDocument.content.push({
                    text: "Medications and Supplements Added During the Visit",
                    style: "title",
                    margin: [0, 30, 0, 10],
                });

                if (medications.length > 0 && !rxntIntegration) {
                    this.pdfDocument.content.push({
                        text: 'Medications:',
                        style: "text",
                        margin: [0, 8, 0, 0]
                    });

                    medications.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: `${index + 1}. ${item.name}${item.dosage ? `, ${item.dosage}` : ''}`,
                            style: "text",
                            margin: [0, 5, 0, 0]
                        })
                    });
                }

                if (rxntMedications?.length > 0 && rxntIntegration) {
                    this.pdfDocument.content.push({
                        text: 'Medications:',
                        style: "text",
                        margin: [0, 8, 0, 0]
                    });

                    rxntMedications?.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: `${index + 1}. ${item.name}${item.dosingInstructions ? `, ${item.dosingInstructions}` : ''}`,
                            style: "text",
                            margin: [0, 5, 0, 0]
                        })
                    });
                }

                if (supplements.length > 0 || fullscriptSupplements?.length > 0) {
                    this.pdfDocument.content.push({
                        text: 'Supplements:',
                        style: "text",
                        margin: [0, 8, 0, 0]
                    });

                    supplements.forEach((item, index) => {
                        const url = item.purchaseLink
                            ? item.purchaseLink.startsWith('http://') || item.purchaseLink.startsWith('https://')
                                ? item.purchaseLink
                                : `http://${item.purchaseLink}`
                            : '';

                        const content: any = {
                            text: `${index + 1}. ${item.name}${item.dosage ? `, ${item.dosage}` : ''}${url ? ` - Purchase here` : ''}`,
                            style: "text",
                            margin: [0, 5, 0, 0],
                        };

                        if (url) {
                            content.link = url;
                        }

                        this.pdfDocument.content.push(content);
                    });

                    (fullscriptSupplements ?? []).forEach((item, index) => {
                        const url = item.purchaseLink
                            ? item.purchaseLink.startsWith('http://') || item.purchaseLink.startsWith('https://')
                                ? item.purchaseLink
                                : `http://${item.purchaseLink}`
                            : '';

                        const content: any = {
                            text: `${index + 1}. ${item.name}${item.dosage ? `, ${item.dosage}` : ''}${url ? ` - Purchase via Fullscript` : ''}`,
                            style: "text",
                            margin: [0, 5, 0, 0],
                        };

                        if (url) {
                            content.link = url;
                        }

                        this.pdfDocument.content.push(content);
                    });
                }
            } else {
                this.pdfDocument.content.push({
                    text: "Medications and Supplements Added During the Visit",
                    style: "title",
                    margin: [0, 30, 0, 10],
                });

                this.pdfDocument.content.push({
                    text: 'No changes were made during this visit.',
                    style: "text",
                    margin: [0, 8, 0, 0]
                });
            }

            if (stoppedMedications.length || stoppedSupplements.length) {
                this.pdfDocument.content.push({
                    text: "Medications and Supplements Stopped During the Visit",
                    style: "title",
                    margin: [0, 30, 0, 10],
                });

                if (stoppedMedications.length > 0 && !rxntIntegration) {
                    this.pdfDocument.content.push({
                        text: 'Medications:',
                        style: "text",
                        margin: [0, 8, 0, 0]
                    });

                    stoppedMedications.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: `${index + 1}. ${item.name}${item.dosage ? `, ${item.dosage}` : ''}`,
                            style: "text",
                            margin: [0, 5, 0, 0]
                        })
                    });
                }

                if (stoppedRxntMedications?.length > 0 && rxntIntegration) {
                    this.pdfDocument.content.push({
                        text: 'Medications:',
                        style: "text",
                        margin: [0, 8, 0, 0]
                    });


                    stoppedRxntMedications?.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: `${index + 1}. ${item.name}${item.dosingInstructions ? `, ${item.dosingInstructions}` : ''}`,
                            style: "text",
                            margin: [0, 5, 0, 0]
                        })
                    });
                }

                if (stoppedSupplements.length > 0) {
                    this.pdfDocument.content.push({
                        text: 'Supplements:',
                        style: "text",
                        margin: [0, 8, 0, 0]
                    });

                    stoppedSupplements.forEach((item, index) => {
                        const content: any = {
                            text: `${index + 1}. ${item.name}${item.dosage ? `, ${item.dosage}` : ''}`,
                            style: "text",
                            margin: [0, 5, 0, 0],
                        };

                        this.pdfDocument.content.push(content);
                    });
                }
            } else {
                this.pdfDocument.content.push({
                    text: "Medications and Supplements Stopped During the Visit",
                    style: "title",
                    margin: [0, 30, 0, 10],
                });

                this.pdfDocument.content.push({
                    text: 'No changes were made during this visit.',
                    style: "text",
                    margin: [0, 8, 0, 0]
                });
            }

            if (content.ROS.rosEnabled) {
                this.pdfDocument.content.push({
                    text: "ROS",
                    style: "title",
                    margin: [0, 30, 0, 0],
                });

                if (content.ROS.rosValues.length > 0) {
                    this.pdfDocument.content.push({
                        text: `All systems reviewed and negative except:`,
                        style: "text",
                        margin: [0, 5, 0, 0],
                    });

                    content.ROS.rosValues.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: `${index + 1}. ${item}`,
                            style: "text",
                            margin: [0, 5, 0, 0],
                        });
                    })
                }
                else {
                    this.pdfDocument.content.push({
                        text: `All systems reviewed and negative`,
                        style: "text",
                        margin: [0, 5, 0, 0],
                    });
                }
            }

            this.pdfDocument.content.push({
                text: "Objective",
                style: "title",
                margin: [0, 30, 0, 0],
            });

            if (!content.physicalData.unableToObtain) {
                this.pdfDocument.content.push({
                    text: "Vitals",
                    style: "subTitle",
                    margin: [0, 15, 0, 0],
                });

                this.pdfDocument.content.push({
                    text: `Blood Pressure:        ${!!content.physicalData.systolicBP ? content.physicalData.systolicBP : ''} ${!!content.physicalData.diastolicBP ? '/ ' + content.physicalData.diastolicBP : ''}`,
                    style: "text",
                    margin: [0, 5, 0, 0],
                });

                this.pdfDocument.content.push({
                    text: `HR:                            ${!!content.physicalData.heartRate ? content.physicalData.heartRate : ''}`,
                    style: "text",
                    margin: [0, 5, 0, 0],
                });

                this.pdfDocument.content.push({
                    text: `Temperature:             ${!!content.physicalData.temperature ? content.physicalData.temperature + "°F" : ''}`,
                    style: "text",
                    margin: [0, 5, 0, 0],
                });

                this.pdfDocument.content.push({
                    text: `Weight (lbs):              ${!!content.physicalData.weight ? content.physicalData.weight : ''}`,
                    style: "text",
                    margin: [0, 5, 0, 0],
                });

                this.pdfDocument.content.push({
                    text: `Height (inches):         ${!!content.physicalData.height ? content.physicalData.height : ''}`,
                    style: "text",
                    margin: [0, 5, 0, 0],
                });
            }


            this.pdfDocument.content.push({
                text: this.getPdfTextSections(`Objective:\n` + `${content.physicalData.physicalExam}`),
                style: "text",
                margin: [0, 5, 0, 0],
            });

            if (content.mdmData) {
                const mdmPatientTypes = new Map<MdmPatientType, string>()
                    .set(MdmPatientType.Established, 'Established Patient')
                    .set(MdmPatientType.New, 'New Patient');

                this.pdfDocument.content.push({
                    text: "MDM",
                    style: "title",
                    margin: [0, 30, 0, 10],
                });

                this.pdfDocument.content.push({
                    text: content.mdmData.mdmText,
                    style: "text",
                    margin: [0, 8, 0, 0]
                });

                this.pdfDocument.content.push({
                    text: "Coding",
                    style: "subTitle",
                    margin: [0, 15, 0, 0],
                });

                this.pdfDocument.content.push({
                    text: mdmPatientTypes.get(content.mdmData.selectedMdmPatientType),
                    style: "text",
                    margin: [0, 8, 0, 0]
                });

                this.pdfDocument.content.push({
                    text: mdmPatientTypes.get(content.mdmData.selectedCodeId),
                    style: "text",
                    margin: [0, 8, 0, 0]
                });

                if (content.mdmData.selectedMode == MdmMode.TimeBased) {
                    this.pdfDocument.content.push({
                        text: "Time Based Billing",
                        style: "subTitle",
                        margin: [0, 15, 0, 0],
                    });

                    this.pdfDocument.content.push({
                        text: `I spent ${content.mdmData.spentTime + content.duration?.value} minutes meeting with the patient, reviewing results, updating plan and treatments, and/or consulting another provider.`,
                        style: "text",
                        margin: [0, 8, 0, 0]
                    });
                }
                else {
                    const mdmCode = codes.find(x => x.id === content.mdmData.selectedCodeId);

                    if (mdmCode) {
                        const categories = mdmCode.categories.filter(x => (content.mdmData.selectedCategoryIds ?? [content.mdmData.selectedCategoryId]).some(t => t === x.id));

                        this.pdfDocument.content.push({
                            text: mdmCode.title,
                            style: "subTitle",
                            margin: [0, 15, 0, 0],
                        });

                        if (content.mdmData.showCodeDescription) {
                            this.pdfDocument.content.push({
                                text: mdmCode.description,
                                style: "text",
                                margin: [0, 8, 0, 0]
                            });
                        }

                        categories.map((category) => {
                            content.mdmData.selectedCategoryItems.map((item) => {
                                const allItems = category.items;
                                const categoryItem = allItems?.find(x => x.id === item.id);

                                if (categoryItem) {
                                    this.pdfDocument.content.push({
                                        text: `${categoryItem.description} ${categoryItem.allowInput
                                            ? ` ${item.inputValue ?? ''}`
                                            : ''}`,
                                        style: "text",
                                        margin: [0, 8, 0, 0]
                                    });
                                }
                            })
                        });
                    }
                }

            }

            if (content.diagnosis.length > 0) {
                this.pdfDocument.content.push({
                    text: "Diagnosis",
                    style: "title",
                    margin: [0, 30, 0, 0],
                });

                content.diagnosis.forEach((diagnosis, index) => {
                    this.pdfDocument.content.push({
                        text: `${index + 1}. ${diagnosis.code} ${diagnosis.description} ${this.displayInfoDiagnosis(diagnosis.additionalInformation)}`,
                        style: "text",
                        margin: [0, 5, 0, 0],
                    });
                })
            }

            this.pdfDocument.content.push({
                text: "Plan",
                style: "title",
                margin: [0, 40, 0, 0],
            });

            this.pdfDocument.content.push({
                text: this.getPdfTextSections(content.plan.planText) ?? 'These are our main recommendations from this visit. These should be your primary focus.',
                style: "text",
                margin: [0, 0, 0, 0],
            });

            if (content.plan.labsText.length > 0) {
                this.pdfDocument.content.push({
                    text: "Labs",
                    style: "title",
                    margin: [0, 30, 0, 0],
                });

                this.pdfDocument.content.push({
                    text: this.getPdfTextSections(content.plan.labsText),
                    style: "text",
                    margin: [0, 5, 0, 0],
                });
            }

            if (note.nextCoachAppointmentDate && note.nextProviderAppointmentDate) {
                this.pdfDocument.content.push({
                    text: "Next Appointment",
                    style: "title",
                    margin: [0, 30, 0, 0],
                });

                if (note.nextCoachAppointmentDate) {
                    this.pdfDocument.content.push({
                        text: `Coach Visit:               ${moment(note.nextCoachAppointmentDate).endOf('day').fromNow()}`,
                        style: "text",
                        margin: [0, 5, 0, 0],
                    });
                }

                if (note.nextProviderAppointmentDate) {
                    this.pdfDocument.content.push({
                        text: `Provider:                    ${moment(note.nextProviderAppointmentDate).endOf('day').fromNow()}`,
                        style: "text",
                        margin: [0, 5, 0, 0],
                    });
                }
            }

            if (amendedList != null && amendedList.length > 0) {
                amendedList.map(text => {
                    this.pdfDocument.content.push({
                        text: this.getPdfTextSections(`${text}`),
                        style: "text",
                        margin: [0, 5, 0, 0],
                    });
                });
            }
        } else {
            this.pdfDocument.content.push({
                text: "Plan",
                style: "title",
                margin: [0, 40, 0, 0],
            });

            this.pdfDocument.content.push({
                text: this.getPdfTextSections(content.plan.planText) ?? 'These are our main recommendations from this visit. These should be your primary focus.',
                style: "text",
                margin: [0, 0, 0, 0],
            });

            if (content.plan.labsText.length > 0) {
                this.pdfDocument.content.push({
                    text: "Labs",
                    style: "title",
                    margin: [0, 30, 0, 0],
                });

                this.pdfDocument.content.push({
                    text: this.getPdfTextSections(content.plan.labsText),
                    style: "text",
                    margin: [0, 5, 0, 0],
                });
            }

            if (content.mdmData) {
                const mdmPatientTypes = new Map<MdmPatientType, string>()
                    .set(MdmPatientType.Established, 'Established Patient')
                    .set(MdmPatientType.New, 'New Patient');

                this.pdfDocument.content.push({
                    text: "MDM",
                    style: "title",
                    margin: [0, 30, 0, 10],
                });

                this.pdfDocument.content.push({
                    text: content.mdmData.mdmText,
                    style: "text",
                    margin: [0, 8, 0, 0]
                });

                this.pdfDocument.content.push({
                    text: "Coding",
                    style: "subTitle",
                    margin: [0, 15, 0, 0],
                });

                this.pdfDocument.content.push({
                    text: mdmPatientTypes.get(content.mdmData.selectedMdmPatientType),
                    style: "text",
                    margin: [0, 8, 0, 0]
                });

                this.pdfDocument.content.push({
                    text: mdmPatientTypes.get(content.mdmData.selectedCodeId),
                    style: "text",
                    margin: [0, 8, 0, 0]
                });

                if (content.mdmData.selectedMode == MdmMode.TimeBased) {
                    this.pdfDocument.content.push({
                        text: "Time Based Billing",
                        style: "subTitle",
                        margin: [0, 15, 0, 0],
                    });

                    this.pdfDocument.content.push({
                        text: `I spent ${content.mdmData.spentTime + content.duration?.value} minutes meeting with the patient, reviewing results, updating plan and treatments, and/or consulting another provider.`,
                        style: "text",
                        margin: [0, 8, 0, 0]
                    });
                }
                else {
                    const mdmCode = codes.find(x => x.id === content.mdmData.selectedCodeId);

                    if (mdmCode) {
                        const categories = mdmCode.categories.filter(x => (content.mdmData.selectedCategoryIds ?? [content.mdmData.selectedCategoryId]).some(t => t === x.id));

                        this.pdfDocument.content.push({
                            text: mdmCode.title,
                            style: "subTitle",
                            margin: [0, 15, 0, 0],
                        });

                        if (content.mdmData.showCodeDescription) {
                            this.pdfDocument.content.push({
                                text: mdmCode.description,
                                style: "text",
                                margin: [0, 8, 0, 0]
                            });
                        }

                        categories.map((category) => {
                            content.mdmData.selectedCategoryItems.map((item) => {
                                const allItems = category.items;
                                const categoryItem = allItems?.find(x => x.id === item.id);

                                if (categoryItem) {
                                    this.pdfDocument.content.push({
                                        text: `${categoryItem.description} ${categoryItem.allowInput
                                            ? ` ${item.inputValue ?? ''}`
                                            : ''}`,
                                        style: "text",
                                        margin: [0, 8, 0, 0]
                                    });
                                }
                            })
                        });
                    }
                }

            }

            if (content.diagnosis.length > 0) {
                this.pdfDocument.content.push({
                    text: "Diagnosis",
                    style: "title",
                    margin: [0, 30, 0, 0],
                });

                content.diagnosis.forEach((diagnosis, index) => {
                    this.pdfDocument.content.push({
                        text: `${index + 1}. ${diagnosis.code} ${diagnosis.description} ${this.displayInfoDiagnosis(diagnosis.additionalInformation)}`,
                        style: "text",
                        margin: [0, 5, 0, 0],
                    });
                })
            }

            if (content.subjective.length > 0) {
                this.pdfDocument.content.push({
                    text: "Subjective",
                    style: "title",
                    margin: [0, 30, 0, 0],
                });

                this.pdfDocument.content.push({
                    text: this.getPdfTextSections(content.subjective),
                    style: "text",
                    margin: [0, 5, 0, 0],
                });
            }

            if (content.ROS.rosEnabled) {
                this.pdfDocument.content.push({
                    text: "ROS",
                    style: "title",
                    margin: [0, 30, 0, 0],
                });

                if (content.ROS.rosValues.length > 0) {
                    this.pdfDocument.content.push({
                        text: `All systems reviewed and negative except:`,
                        style: "text",
                        margin: [0, 5, 0, 0],
                    });

                    content.ROS.rosValues.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: `${index + 1}. ${item}`,
                            style: "text",
                            margin: [0, 5, 0, 0],
                        });
                    })
                }
                else {
                    this.pdfDocument.content.push({
                        text: `All systems reviewed and negative`,
                        style: "text",
                        margin: [0, 5, 0, 0],
                    });
                }
            }

            if ((medications.length && !rxntIntegration) || (rxntMedications?.length && rxntIntegration) || supplements.length || fullscriptSupplements?.length) {
                this.pdfDocument.content.push({
                    text: "Medications and Supplements Added During the Visit",
                    style: "title",
                    margin: [0, 30, 0, 10],
                });

                if (medications.length > 0 && !rxntIntegration) {
                    this.pdfDocument.content.push({
                        text: 'Medications:',
                        style: "text",
                        margin: [0, 8, 0, 0]
                    });

                    medications.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: `${index + 1}. ${item.name}${item.dosage ? `, ${item.dosage}` : ''}`,
                            style: "text",
                            margin: [0, 5, 0, 0]
                        })
                    });
                }

                if (rxntMedications?.length > 0 && rxntIntegration) {
                    this.pdfDocument.content.push({
                        text: 'Medications:',
                        style: "text",
                        margin: [0, 8, 0, 0]
                    });

                    rxntMedications?.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: `${index + 1}. ${item.name}${item.dosingInstructions ? `, ${item.dosingInstructions}` : ''}`,
                            style: "text",
                            margin: [0, 5, 0, 0]
                        })
                    });
                }

                if (supplements.length > 0 || fullscriptSupplements?.length > 0) {
                    this.pdfDocument.content.push({
                        text: 'Supplements:',
                        style: "text",
                        margin: [0, 8, 0, 0]
                    });

                    supplements.forEach((item, index) => {
                        const url = item.purchaseLink
                            ? item.purchaseLink.startsWith('http://') || item.purchaseLink.startsWith('https://')
                                ? item.purchaseLink
                                : `http://${item.purchaseLink}`
                            : '';

                        const content: any = {
                            text: `${index + 1}. ${item.name}${item.dosage ? `, ${item.dosage}` : ''}${url ? ` - Purchase here` : ''}`,
                            style: "text",
                            margin: [0, 5, 0, 0],
                        };

                        if (url) {
                            content.link = url;
                        }

                        this.pdfDocument.content.push(content);
                    });

                    (fullscriptSupplements ?? []).forEach((item, index) => {
                        const url = item.purchaseLink
                            ? item.purchaseLink.startsWith('http://') || item.purchaseLink.startsWith('https://')
                                ? item.purchaseLink
                                : `http://${item.purchaseLink}`
                            : '';

                        const content: any = {
                            text: `${index + 1}. ${item.name}${item.dosage ? `, ${item.dosage}` : ''}${url ? ` - Purchase via Fullscript` : ''}`,
                            style: "text",
                            margin: [0, 5, 0, 0],
                        };

                        if (url) {
                            content.link = url;
                        }

                        this.pdfDocument.content.push(content);
                    });
                }
            } else {
                this.pdfDocument.content.push({
                    text: "Medications and Supplements Added During the Visit",
                    style: "title",
                    margin: [0, 30, 0, 10],
                });

                this.pdfDocument.content.push({
                    text: 'No changes were made during this visit.',
                    style: "text",
                    margin: [0, 8, 0, 0]
                });
            }

            if ((stoppedMedications.length && !rxntIntegration) || (stoppedRxntMedications?.length && rxntIntegration) || stoppedSupplements.length) {
                this.pdfDocument.content.push({
                    text: "Medications and Supplements Stopped During the Visit",
                    style: "title",
                    margin: [0, 30, 0, 10],
                });

                if (stoppedMedications.length > 0 && !rxntIntegration) {
                    this.pdfDocument.content.push({
                        text: 'Medications:',
                        style: "text",
                        margin: [0, 8, 0, 0]
                    });

                    stoppedMedications.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: `${index + 1}. ${item.name}${item.dosage ? `, ${item.dosage}` : ''}`,
                            style: "text",
                            margin: [0, 5, 0, 0]
                        })
                    });
                }

                if (stoppedRxntMedications?.length > 0 && rxntIntegration) {
                    this.pdfDocument.content.push({
                        text: 'Medications:',
                        style: "text",
                        margin: [0, 8, 0, 0]
                    });

                    stoppedRxntMedications?.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: `${index + 1}. ${item.name}${item.dosingInstructions ? `, ${item.dosingInstructions}` : ''}`,
                            style: "text",
                            margin: [0, 5, 0, 0]
                        })
                    });
                }

                if (stoppedSupplements.length > 0) {
                    this.pdfDocument.content.push({
                        text: 'Supplements:',
                        style: "text",
                        margin: [0, 8, 0, 0]
                    });

                    stoppedSupplements.forEach((item, index) => {
                        const content: any = {
                            text: `${index + 1}. ${item.name}${item.dosage ? `, ${item.dosage}` : ''}`,
                            style: "text",
                            margin: [0, 5, 0, 0],
                        };

                        this.pdfDocument.content.push(content);
                    });
                }
            } else {
                this.pdfDocument.content.push({
                    text: "Medications and Supplements Stopped During the Visit",
                    style: "title",
                    margin: [0, 30, 0, 10],
                });

                this.pdfDocument.content.push({
                    text: 'No changes were made during this visit.',
                    style: "text",
                    margin: [0, 8, 0, 0]
                });
            }

            this.pdfDocument.content.push({
                text: "Objective",
                style: "title",
                margin: [0, 30, 0, 0],
            });

            if (!content.physicalData.unableToObtain) {
                this.pdfDocument.content.push({
                    text: "Vitals",
                    style: "subTitle",
                    margin: [0, 15, 0, 0],
                });

                this.pdfDocument.content.push({
                    text: `Blood Pressure:        ${!!content.physicalData.systolicBP ? content.physicalData.systolicBP : ''} ${!!content.physicalData.diastolicBP ? '/ ' + content.physicalData.diastolicBP : ''}`,
                    style: "text",
                    margin: [0, 5, 0, 0],
                });

                this.pdfDocument.content.push({
                    text: `HR:                            ${!!content.physicalData.heartRate ? content.physicalData.heartRate : ''}`,
                    style: "text",
                    margin: [0, 5, 0, 0],
                });

                this.pdfDocument.content.push({
                    text: `Temperature:             ${!!content.physicalData.temperature ? content.physicalData.temperature + "°F" : ''}`,
                    style: "text",
                    margin: [0, 5, 0, 0],
                });

                this.pdfDocument.content.push({
                    text: `Weight (lbs):              ${!!content.physicalData.weight ? content.physicalData.weight : ''}`,
                    style: "text",
                    margin: [0, 5, 0, 0],
                });

                this.pdfDocument.content.push({
                    text: `Height (inches):         ${!!content.physicalData.height ? content.physicalData.height : ''}`,
                    style: "text",
                    margin: [0, 5, 0, 0],
                });
            }

            this.pdfDocument.content.push({
                text: this.getPdfTextSections(`Objective:\n` + `${content.physicalData.physicalExam}`),
                style: "text",
                margin: [0, 5, 0, 0],
            });

            if (note.nextCoachAppointmentDate && note.nextProviderAppointmentDate) {
                this.pdfDocument.content.push({
                    text: "Next Appointment",
                    style: "title",
                    margin: [0, 30, 0, 0],
                });

                if (note.nextCoachAppointmentDate) {
                    this.pdfDocument.content.push({
                        text: `Coach Visit:               ${moment(note.nextCoachAppointmentDate).endOf('day').fromNow()}`,
                        style: "text",
                        margin: [0, 5, 0, 0],
                    });
                }

                if (note.nextProviderAppointmentDate) {
                    this.pdfDocument.content.push({
                        text: `Provider:                    ${moment(note.nextProviderAppointmentDate).endOf('day').fromNow()}`,
                        style: "text",
                        margin: [0, 5, 0, 0],
                    });
                }
            }
        }

        this.renderSignature(this.pdfDocument, note, amendedList != null && amendedList.length > 0, noteId);
    }
}

export class HistoryAndPhysicalPdfService extends NotesPdfService {
    public downloadNotePdf(note: NoteModel, content: HistoryAndPhysicalContent, isEmployee: boolean, noteId: number, flags, amendedList?: Array<string>): Observable<boolean> {
        return new Observable<boolean>(observer => {
            mdmCodesService.get(note.type).subscribe(codes => {
                this.buildPdfContent(note, content, isEmployee, noteId, codes, flags, amendedList);
                this.personalizeFooter(note);
                pdfMake.createPdf(this.pdfDocument).getBlob((blob) => {
                    notesService.uploadNote(blob, noteId, isEmployee).subscribe(
                        () => {
                            const newFile = new Blob([blob], { type: 'application/pdf' });
                            const url = URL.createObjectURL(newFile);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            a.download = note.name;
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);

                            observer.next(true);
                            observer.complete();
                        },
                        error => {
                            observer.error(error);
                            observer.complete();
                        }
                    )
                })
            });


        })
    }

    private renderGoals(name: string, goals: GoalModel[]) {
        this.pdfDocument.content.push({
            text: name,
            style: "subTitle",
            margin: [0, 15, 0, 0],
        });

        const sortedGoals = goals.sort((x, y) => {
            return (x.isTopGoal === y.isTopGoal) ? 0 : x.isTopGoal ? -1 : 1;
        });

        sortedGoals.forEach((item, index) => {
            this.pdfDocument.content.push({
                text: item.isTopGoal ? `Top Goal ${index + 1}` : `Goal ${index + 1}`,
                style: "subTitle",
                margin: [0, 15, 0, 0]
            });

            if(item.completionDate){
                this.pdfDocument.content.push({
                    text: this.getPdfTextSections(`Completion date ${moment(item.completionDate).format(dateFormat)} at ${moment(item.completionDate).format('LT')}`),
                    style: "text",
                    margin: [1, 10, 0, 0]
                });
    
            }

            this.pdfDocument.content.push({
                text: this.getPdfTextSections(item.name),
                style: "text",
                margin: [1, 10, 0, 0]
            });

            if (item.interventions.length) {
                this.pdfDocument.content.push({
                    text: 'Interventions',
                    style: "subTitle",
                    margin: [30, 15, 0, 0],
                });

                item.interventions.forEach((item, index) => {
                    this.pdfDocument.content.push({
                        text: this.getPdfTextSections(`${index + 1}. ` + `${item.name}`),
                        style: "text",
                        margin: [30, 5, 0, 0],
                    });
                });
            } else {
                this.pdfDocument.content.push({
                    text: 'No Interventions',
                    style: "subTitle",
                    margin: [0, 5, 0, 10],
                });
            }
        });
    }

    private getItemName(item) {
        if (item.name) {
            if (item.value) {
                return `${item.name} - ${item.value}`;
            } else {
                return item.name;
            }
        }
        return item.value
    }

    private renderEducation(text: string) {
        this.pdfDocument.content.push({
            text: `Education:`,
            style: "subTitle",
            margin: [0, 15, 0, 0]
        });

        this.pdfDocument.content.push({
            text: text,
            style: "text",
            margin: [1, 10, 0, 0]
        });
    }

    public buildPdfContent(note: NoteModel, content: HistoryAndPhysicalContent, isEmployee: boolean, noteId: number, mdmCodes: MdmModel[], flags, amendedList?: Array<string>) {
        this.clear();

        const rxntIntegration = isFeatureFlag(flags, FeatureFlag.RxntIntegration);
        const medications = content.medicationsSupplements.medications.filter(i => !i.isInCurrent);
        const supplements = content.medicationsSupplements.supplements.filter(i => !i.isInCurrent);
        const rxntMedications = content.medicationsSupplements.rxntMedications?.filter(i => !i.isInCurrent);
        const fullscriptSupplements = content.medicationsSupplements.fullscriptSupplements;
        const stoppedMedications = content.medicationsSupplements.medications.filter(i => i.isStopped);
        const stoppedSupplements = content.medicationsSupplements.supplements.filter(i => i.isStopped);
        const stoppedRxntMedications = content.medicationsSupplements.rxntMedications?.filter(i => i.isStopped);

        this.pdfDocument.content.push({
            text: "History and Physical",
            style: "titleHeader",
            margin: [0, 80, 0, 0],
        })

        if (!note.appointment) {
            this.pdfDocument.content.push({
                text: `${note.patient.firstName} ${note.patient.lastName}, ${note.patientId}`,
                alignment: "left",
                style: "text",
                margin: [0, 5, 0, 0]
            })
        } else {
            this.pdfDocument.content.push({
                text: `${note.patient.firstName} ${note.patient.lastName}, ${note.patientId}    |    ${moment(note.appointment.date).format(dateFormat)} at ${moment(note.appointment.date).format('LT')}`,
                alignment: "left",
                style: "text",
                margin: [0, 5, 0, 0]
            })
        }

        if (isEmployee) {
            if (content.HPI.length > 0) {
                this.pdfDocument.content.push({
                    text: "HPI",
                    style: "title",
                    margin: [0, 30, 0, 0],
                });

                this.pdfDocument.content.push({
                    text: this.getPdfTextSections(content.HPI),
                    style: "text",
                    margin: [0, 5, 0, 0],
                });
            }

            this.pdfDocument.content.push({
                text: "PMH",
                style: "title",
                margin: [0, 30, 0, 0],
            });

            if (content?.pmhVersion) {
                if (content.PMHnew.allergies.length > 0 && !rxntIntegration) {
                    this.pdfDocument.content.push({
                        text: "Allergies",
                        style: "subTitle",
                        margin: [0, 15, 0, 0],
                    });

                    content.PMHnew.allergies.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: this.getPdfTextSections(`${index + 1}. ` + `${this.getItemName(item)}`),
                            style: "text",
                            margin: [0, 5, 0, 0],
                        });
                    });
                }

                if (content.PMHnew.rxntAllergies?.length > 0 && rxntIntegration) {
                    this.pdfDocument.content.push({
                        text: "Allergies",
                        style: "subTitle",
                        margin: [0, 15, 0, 0],
                    });

                    content.PMHnew.rxntAllergies?.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: this.getPdfTextSections(`${index + 1}. ` + `${item.description ? `${item.name} - ${item.description}` : item.name}`),
                            style: "text",
                            margin: [0, 5, 0, 0],
                        });
                    });
                }

                if (content.PMHnew.currentMedicalConditions.length > 0) {
                    this.pdfDocument.content.push({
                        text: "Problem List",
                        style: "subTitle",
                        margin: [0, 15, 0, 0],
                    });

                    content.PMHnew.currentMedicalConditions.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: this.getPdfTextSections(`${index + 1}. ` + `${this.getItemName(item)}`),
                            style: "text",
                            margin: [0, 5, 0, 0],
                        });
                    });
                }

                if (content.PMHnew.famHX.length > 0) {
                    this.pdfDocument.content.push({
                        text: "Fam HX",
                        style: "subTitle",
                        margin: [0, 15, 0, 0],
                    });

                    content.PMHnew.famHX.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: this.getPdfTextSections(`${index + 1}. ` + `${this.getItemName(item)}`),
                            style: "text",
                            margin: [0, 5, 0, 0],
                        });
                    });
                }

                if (content.PMHnew.screeningHealthMaintenanceApoe) {
                    if (content.PMHnew.screeningHealthMaintenanceApoe.hideApoe !== YesNo.Yes) {
                        this.pdfDocument.content.push({
                            text: "Screening/Health Maintenance",
                            style: "subTitle",
                            margin: [0, 15, 0, 0],
                        });


                        this.pdfDocument.content.push({
                            text: `Your APOE Status: Apo-${content.PMHnew.screeningHealthMaintenanceApoe.score}  ${content.PMHnew.screeningHealthMaintenanceApoe.source === ApoeSoursConstants.BloodTest ? `(99%)` : ''}`,
                            style: "text",
                            margin: [0, 5, 0, 0],
                        });
                    }

                } else if (content.PMHnew.screeningHealthMaintenance.length > 0) {
                    this.pdfDocument.content.push({
                        text: "Screening/Health Maintenance",
                        style: "subTitle",
                        margin: [0, 15, 0, 0],
                    });


                    const apoe = this.getApoeInfo(content.PMHnew.screeningHealthMaintenance)

                    if (!!apoe) {
                        this.pdfDocument.content.push({
                            text: apoe,
                            style: "text",
                            margin: [0, 5, 0, 0],
                        });
                    }

                    content.PMHnew.screeningHealthMaintenance.filter(el => !el.key.includes('APOE')).forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: this.getPdfTextSections(`${index + 1}. ` + `${this.getItemName(item)}`),
                            style: "text",
                            margin: [0, 5, 0, 0],
                        });
                    });
                }



                if (content.PMHnew.surgicalHX.length > 0) {
                    this.pdfDocument.content.push({
                        text: "Surgical HX",
                        style: "subTitle",
                        margin: [0, 15, 0, 0],
                    });

                    content.PMHnew.surgicalHX.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: this.getPdfTextSections(`${index + 1}. ` + `${this.getItemName(item)}`),
                            style: "text",
                            margin: [0, 5, 0, 0],
                        });
                    });
                }

                if ([...content.PMHnew.socialHX, ...content.PMHnew.lifestyleHX].length > 0) {
                    this.pdfDocument.content.push({
                        text: "Social/Lifestyle HX",
                        style: "subTitle",
                        margin: [0, 15, 0, 0],
                    });

                    [...content.PMHnew.socialHX, ...content.PMHnew.lifestyleHX].forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: this.getPdfTextSections(`${index + 1}. ` + `${this.getItemName(item)}`),
                            style: "text",
                            margin: [0, 5, 0, 0],
                        });
                    });
                }
            } else {
                if (content.PMH.allergies.length > 0) {
                    this.pdfDocument.content.push({
                        text: "Allergies",
                        style: "subTitle",
                        margin: [0, 15, 0, 0],
                    });

                    content.PMH.allergies.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: this.getPdfTextSections(`${index + 1}. ` + `${item}`),
                            style: "text",
                            margin: [0, 5, 0, 0],
                        });
                    });
                }

                if (content.PMH.currentMedicalConditions.length > 0) {
                    this.pdfDocument.content.push({
                        text: "Problem List",
                        style: "subTitle",
                        margin: [0, 15, 0, 0],
                    });

                    content.PMH.currentMedicalConditions.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: this.getPdfTextSections(`${index + 1}. ` + `${item}`),
                            style: "text",
                            margin: [0, 5, 0, 0],
                        });
                    });
                }

                if (content.PMH.famHX.length > 0) {
                    this.pdfDocument.content.push({
                        text: "Fam HX",
                        style: "subTitle",
                        margin: [0, 15, 0, 0],
                    });

                    content.PMH.famHX.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: this.getPdfTextSections(`${index + 1}. ` + `${item}`),
                            style: "text",
                            margin: [0, 5, 0, 0],
                        });
                    });
                }

                if (content.PMH.screeningHealthMaintenance.length > 0) {
                    this.pdfDocument.content.push({
                        text: "Screening/Health Maintenance",
                        style: "subTitle",
                        margin: [0, 15, 0, 0],
                    });

                    content.PMH.screeningHealthMaintenance.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: this.getPdfTextSections(`${index + 1}. ` + `${item}`),
                            style: "text",
                            margin: [0, 5, 0, 0],
                        });
                    });
                }

                if (content.PMH.surgicalHX.length > 0) {
                    this.pdfDocument.content.push({
                        text: "Surgical HX",
                        style: "subTitle",
                        margin: [0, 15, 0, 0],
                    });

                    content.PMH.surgicalHX.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: this.getPdfTextSections(`${index + 1}. ` + `${item}`),
                            style: "text",
                            margin: [0, 5, 0, 0],
                        });
                    });
                }

                if (content.PMH.socialLifestyleHX.length > 0) {
                    this.pdfDocument.content.push({
                        text: "Social/Lifestyle HX",
                        style: "subTitle",
                        margin: [0, 15, 0, 0],
                    });

                    content.PMH.socialLifestyleHX.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: this.getPdfTextSections(`${index + 1}. ` + `${item}`),
                            style: "text",
                            margin: [0, 5, 0, 0],
                        });
                    });
                }
            }

            if ((medications.length && !rxntIntegration) || (rxntMedications?.length && rxntIntegration) || supplements.length || fullscriptSupplements?.length) {
                this.pdfDocument.content.push({
                    text: "Medications and Supplements Added During the Visit",
                    style: "title",
                    margin: [0, 30, 0, 10],
                });

                if (medications.length > 0 && !rxntIntegration) {
                    this.pdfDocument.content.push({
                        text: 'Medications:',
                        style: "text",
                        margin: [0, 8, 0, 0]
                    });

                    medications.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: `${index + 1}. ${item.name}${item.dosage ? `, ${item.dosage}` : ''}`,
                            style: "text",
                            margin: [0, 5, 0, 0]
                        })
                    });
                }

                if (rxntMedications?.length > 0 && rxntIntegration) {
                    this.pdfDocument.content.push({
                        text: 'Medications:',
                        style: "text",
                        margin: [0, 8, 0, 0]
                    });

                    rxntMedications?.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: `${index + 1}. ${item.name}${item.dosingInstructions ? `, ${item.dosingInstructions}` : ''}`,
                            style: "text",
                            margin: [0, 5, 0, 0]
                        })
                    });
                }

                if (supplements.length > 0 || fullscriptSupplements?.length > 0) {
                    this.pdfDocument.content.push({
                        text: 'Supplements:',
                        style: "text",
                        margin: [0, 8, 0, 0]
                    });

                    supplements.forEach((item, index) => {
                        const url = item.purchaseLink
                            ? item.purchaseLink.startsWith('http://') || item.purchaseLink.startsWith('https://')
                                ? item.purchaseLink
                                : `http://${item.purchaseLink}`
                            : '';

                        const content: any = {
                            text: `${index + 1}. ${item.name}${item.dosage ? `, ${item.dosage}` : ''}${url ? ` - Purchase here` : ''}`,
                            style: "text",
                            margin: [0, 5, 0, 0],
                        };

                        if (url) {
                            content.link = url;
                        }

                        this.pdfDocument.content.push(content);
                    });

                    (fullscriptSupplements ?? []).forEach((item, index) => {
                        const url = item.purchaseLink
                            ? item.purchaseLink.startsWith('http://') || item.purchaseLink.startsWith('https://')
                                ? item.purchaseLink
                                : `http://${item.purchaseLink}`
                            : '';

                        const content: any = {
                            text: `${index + 1}. ${item.name}${item.dosage ? `, ${item.dosage}` : ''}${url ? ` - Purchase via Fullscript` : ''}`,
                            style: "text",
                            margin: [0, 5, 0, 0],
                        };

                        if (url) {
                            content.link = url;
                        }

                        this.pdfDocument.content.push(content);
                    });
                }
            } else {
                this.pdfDocument.content.push({
                    text: "Medications and Supplements Added During the Visit",
                    style: "title",
                    margin: [0, 30, 0, 10],
                });

                this.pdfDocument.content.push({
                    text: 'No changes were made during this visit.',
                    style: "text",
                    margin: [0, 8, 0, 0]
                });
            }

            if ((stoppedMedications.length && !rxntIntegration) || (stoppedRxntMedications?.length && rxntIntegration) || stoppedSupplements.length) {
                this.pdfDocument.content.push({
                    text: "Medications and Supplements Stopped During the Visit",
                    style: "title",
                    margin: [0, 30, 0, 10],
                });

                if (stoppedMedications.length > 0 && !rxntIntegration) {
                    this.pdfDocument.content.push({
                        text: 'Medications:',
                        style: "text",
                        margin: [0, 8, 0, 0]
                    });

                    stoppedMedications.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: `${index + 1}. ${item.name}${item.dosage ? `, ${item.dosage}` : ''}`,
                            style: "text",
                            margin: [0, 5, 0, 0]
                        })
                    });
                }

                if (stoppedRxntMedications?.length > 0 && rxntIntegration) {
                    this.pdfDocument.content.push({
                        text: 'Medications:',
                        style: "text",
                        margin: [0, 8, 0, 0]
                    });

                    stoppedRxntMedications?.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: `${index + 1}. ${item.name}${item.dosingInstructions ? `, ${item.dosingInstructions}` : ''}`,
                            style: "text",
                            margin: [0, 5, 0, 0]
                        })
                    });
                }

                if (stoppedSupplements.length > 0) {
                    this.pdfDocument.content.push({
                        text: 'Supplements:',
                        style: "text",
                        margin: [0, 8, 0, 0]
                    });

                    stoppedSupplements.forEach((item, index) => {
                        const content: any = {
                            text: `${index + 1}. ${item.name}${item.dosage ? `, ${item.dosage}` : ''}`,
                            style: "text",
                            margin: [0, 5, 0, 0],
                        };

                        this.pdfDocument.content.push(content);
                    });
                }
            } else {
                this.pdfDocument.content.push({
                    text: "Medications and Supplements Stopped During the Visit",
                    style: "title",
                    margin: [0, 30, 0, 10],
                });

                this.pdfDocument.content.push({
                    text: 'No changes were made during this visit.',
                    style: "text",
                    margin: [0, 8, 0, 0]
                });
            }

            if (content.ROS.rosEnabled) {
                this.pdfDocument.content.push({
                    text: "ROS",
                    style: "title",
                    margin: [0, 30, 0, 0],
                });

                if (content.ROS.rosValues.length > 0) {
                    this.pdfDocument.content.push({
                        text: `All systems reviewed and negative except:`,
                        style: "text",
                        margin: [0, 5, 0, 0],
                    });

                    content.ROS.rosValues.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: `${index + 1}. ${item}`,
                            style: "text",
                            margin: [0, 5, 0, 0],
                        });
                    })
                }
                else {
                    this.pdfDocument.content.push({
                        text: `All systems reviewed and negative`,
                        style: "text",
                        margin: [0, 5, 0, 0],
                    });
                }
            }

            this.pdfDocument.content.push({
                text: "Objective",
                style: "title",
                margin: [0, 30, 0, 0],
            });

            if (!content.physicalData.unableToObtain) {
                this.pdfDocument.content.push({
                    text: "Vitals",
                    style: "subTitle",
                    margin: [0, 15, 0, 0],
                });

                this.pdfDocument.content.push({
                    text: `Blood Pressure:        ${!!content.physicalData.systolicBP ? content.physicalData.systolicBP : ''} ${!!content.physicalData.diastolicBP ? '/ ' + content.physicalData.diastolicBP : ''}`,
                    style: "text",
                    margin: [0, 5, 0, 0],
                });

                this.pdfDocument.content.push({
                    text: `HR:                            ${!!content.physicalData.heartRate ? content.physicalData.heartRate : ''}`,
                    style: "text",
                    margin: [0, 5, 0, 0],
                });

                this.pdfDocument.content.push({
                    text: `Temperature:             ${!!content.physicalData.temperature ? content.physicalData.temperature + "°F" : ''}`,
                    style: "text",
                    margin: [0, 5, 0, 0],
                });

                this.pdfDocument.content.push({
                    text: `Weight (lbs):              ${!!content.physicalData.weight ? content.physicalData.weight : ''}`,
                    style: "text",
                    margin: [0, 5, 0, 0],
                });

                this.pdfDocument.content.push({
                    text: `Height (inches):         ${!!content.physicalData.height ? content.physicalData.height : ''}`,
                    style: "text",
                    margin: [0, 5, 0, 0],
                });
            }

            this.pdfDocument.content.push({
                text: this.getPdfTextSections(`Objective:\n` + `${content.physicalData.physicalExam}`),
                style: "text",
                margin: [0, 5, 0, 0],
            });

            if (content.mdmData) {
                const mdmPatientTypes = new Map<MdmPatientType, string>()
                    .set(MdmPatientType.Established, 'Established Patient')
                    .set(MdmPatientType.New, 'New Patient');

                this.pdfDocument.content.push({
                    text: "MDM",
                    style: "title",
                    margin: [0, 30, 0, 10],
                });

                this.pdfDocument.content.push({
                    text: content.mdmData.mdmText,
                    style: "text",
                    margin: [0, 8, 0, 0]
                });

                this.pdfDocument.content.push({
                    text: "Coding",
                    style: "subTitle",
                    margin: [0, 15, 0, 0],
                });

                this.pdfDocument.content.push({
                    text: mdmPatientTypes.get(content.mdmData.selectedMdmPatientType),
                    style: "text",
                    margin: [0, 8, 0, 0]
                });

                this.pdfDocument.content.push({
                    text: mdmPatientTypes.get(content.mdmData.selectedCodeId),
                    style: "text",
                    margin: [0, 8, 0, 0]
                });

                if (content.mdmData.selectedMode == MdmMode.TimeBased) {
                    this.pdfDocument.content.push({
                        text: "Time Based Billing",
                        style: "subTitle",
                        margin: [0, 15, 0, 0],
                    });

                    this.pdfDocument.content.push({
                        text: `I spent ${content.mdmData.spentTime + content.duration?.value} minutes meeting with the patient, reviewing results, updating plan and treatments, and/or consulting another provider.`,
                        style: "text",
                        margin: [0, 8, 0, 0]
                    });
                }
                else {
                    const mdmCode = mdmCodes.find(x => x.id === content.mdmData.selectedCodeId);

                    if (mdmCode) {
                        const categories = mdmCode.categories.filter(x => (content.mdmData.selectedCategoryIds ?? [content.mdmData.selectedCategoryId]).some(t => t === x.id));

                        this.pdfDocument.content.push({
                            text: mdmCode.title,
                            style: "subTitle",
                            margin: [0, 15, 0, 0],
                        });

                        if (content.mdmData.showCodeDescription) {
                            this.pdfDocument.content.push({
                                text: mdmCode.description,
                                style: "text",
                                margin: [0, 8, 0, 0]
                            });
                        }

                        categories.map((category) => {
                            content.mdmData.selectedCategoryItems.map((item) => {
                                const allItems = category.items;
                                const categoryItem = allItems?.find(x => x.id === item.id);

                                if (categoryItem) {
                                    this.pdfDocument.content.push({
                                        text: `${categoryItem.description} ${categoryItem.allowInput
                                            ? ` ${item.inputValue ?? ''}`
                                            : ''}`,
                                        style: "text",
                                        margin: [0, 8, 0, 0]
                                    });
                                }
                            })
                        });
                    }
                }
            }


            if (content.diagnosis.length > 0) {
                this.pdfDocument.content.push({
                    text: "Diagnosis",
                    style: "title",
                    margin: [0, 30, 0, 0],
                });

                content.diagnosis.forEach((diagnosis, index) => {
                    this.pdfDocument.content.push({
                        text: `${index + 1}. ${diagnosis.code} ${diagnosis.description} ${this.displayInfoDiagnosis(diagnosis.additionalInformation)}`,
                        style: "text",
                        margin: [0, 5, 0, 0],
                    });
                })
            }

            if (content.chiefComplaint.length > 0) {
                this.pdfDocument.content.push({
                    text: "Chief Complaint",
                    style: "title",
                    margin: [0, 30, 0, 0],
                });

                this.pdfDocument.content.push({
                    text: this.getPdfTextSections(content.chiefComplaint),
                    style: "text",
                    margin: [0, 5, 0, 0],
                });
            }

            this.pdfDocument.content.push({
                text: "Plan",
                style: "title",
                margin: [0, 40, 0, 0],
            });

            this.pdfDocument.content.push({
                text: this.getPdfTextSections(content.plan.planText) ?? 'These are our main recommendations from this visit. These should be your primary focus.',
                style: "text",
                margin: [0, 0, 0, 0],
            });

            if (content.plan.dietGoals.length > 0) {
                this.renderGoals('Diet', content.plan.dietGoals);
            }

            if (content.plan.dietEducations?.length > 0) {
                this.renderEducation(content.plan.dietEducations);
            }

            if (content.plan.exerciseGoals.length > 0) {
                this.renderGoals('Exercise/Recovery', content.plan.exerciseGoals);
            }

            if (content.plan.exerciseEducations?.length > 0) {
                this.renderEducation(content.plan.exerciseEducations);
            }

            if (content.plan.sleepGoals.length > 0) {
                this.renderGoals('Sleep', content.plan.sleepGoals);
            }

            if (content.plan.sleepEducations?.length > 0) {
                this.renderEducation(content.plan.sleepEducations);
            }

            if (content.plan.neuroGoals.length > 0) {
                this.renderGoals('Neuro', content.plan.neuroGoals);
            }

            if (content.plan.neuroEducations?.length > 0) {
                this.renderEducation(content.plan.neuroEducations);
            }

            if (content.plan.longevityGoals.length > 0) {
                this.renderGoals('Longevity', content.plan.longevityGoals);
            }

            if (content.plan.longevityEducations?.length > 0) {
                this.renderEducation(content.plan.longevityEducations);
            }

            if (content.plan.mindfulnessGoals?.length > 0) {
                this.renderGoals('Mindfulness', content.plan.mindfulnessGoals);
            }

            if (content.plan.mindfulnessEducations?.length > 0) {
                this.renderEducation(content.plan.mindfulnessEducations);
            }

            if (content.plan.coachOtherText?.length > 0) {
                this.pdfDocument.content.push({
                    text: "Other",
                    style: "subTitle",
                    margin: [0, 15, 0, 0],
                });

                this.pdfDocument.content.push({
                    text: this.getPdfTextSections(content.plan.coachOtherText),
                    style: "text",
                    margin: [0, 5, 0, 0],
                });
            }

            if (content.plan.labsText.length > 0) {
                this.pdfDocument.content.push({
                    text: "Labs",
                    style: "title",
                    margin: [0, 30, 0, 0],
                });

                this.pdfDocument.content.push({
                    text: this.getPdfTextSections(content.plan.labsText),
                    style: "text",
                    margin: [0, 5, 0, 0],
                });
            }


            if (note.nextCoachAppointmentDate && note.nextProviderAppointmentDate) {
                this.pdfDocument.content.push({
                    text: "Next Appointment",
                    style: "title",
                    margin: [0, 30, 0, 0],
                });

                if (note.nextCoachAppointmentDate) {
                    this.pdfDocument.content.push({
                        text: `Coach Visit:               ${moment(note.nextCoachAppointmentDate).endOf('day').fromNow()}`,
                        style: "text",
                        margin: [0, 5, 0, 0],
                    });
                }

                if (note.nextProviderAppointmentDate) {
                    this.pdfDocument.content.push({
                        text: `Provider:                    ${moment(note.nextProviderAppointmentDate).endOf('day').fromNow()}`,
                        style: "text",
                        margin: [0, 5, 0, 0],
                    });
                }
            }

            if (amendedList != null && amendedList.length > 0) {
                amendedList.map(text => {
                    this.pdfDocument.content.push({
                        text: this.getPdfTextSections(`${text}`),
                        style: "text",
                        margin: [0, 5, 0, 0],
                    });
                });
            }

        } else {
            this.pdfDocument.content.push({
                text: "Plan",
                style: "title",
                margin: [0, 40, 0, 0],
            });

            this.pdfDocument.content.push({
                text: this.getPdfTextSections(content.plan.planText) ?? 'These are our main recommendations from this visit. These should be your primary focus.',
                style: "text",
                margin: [0, 0, 0, 0],
            });

            if (content.plan.dietGoals.length > 0) {
                this.renderGoals('Diet', content.plan.dietGoals);
            }

            if (content.plan.dietEducations?.length > 0) {
                this.renderEducation(content.plan.dietEducations);
            }

            if (content.plan.exerciseGoals.length > 0) {
                this.renderGoals('Exercise/Recovery', content.plan.exerciseGoals);
            }

            if (content.plan.exerciseEducations?.length > 0) {
                this.renderEducation(content.plan.exerciseEducations);
            }

            if (content.plan.sleepGoals.length > 0) {
                this.renderGoals('Sleep', content.plan.sleepGoals);
            }

            if (content.plan.sleepEducations?.length > 0) {
                this.renderEducation(content.plan.sleepEducations);
            }

            if (content.plan.neuroGoals.length > 0) {
                this.renderGoals('Neuro', content.plan.neuroGoals);
            }

            if (content.plan.neuroEducations?.length > 0) {
                this.renderEducation(content.plan.neuroEducations);
            }

            if (content.plan.longevityGoals.length > 0) {
                this.renderGoals('Longevity', content.plan.longevityGoals);
            }

            if (content.plan.longevityEducations?.length > 0) {
                this.renderEducation(content.plan.longevityEducations);
            }

            if (content.plan.mindfulnessGoals?.length > 0) {
                this.renderGoals('Mindfulness', content.plan.mindfulnessGoals);
            }

            if (content.plan.mindfulnessEducations?.length > 0) {
                this.renderEducation(content.plan.mindfulnessEducations);
            }

            if (content.plan.coachOtherText?.length > 0) {
                this.pdfDocument.content.push({
                    text: "Other",
                    style: "subTitle",
                    margin: [0, 15, 0, 0],
                });

                this.pdfDocument.content.push({
                    text: this.getPdfTextSections(content.plan.coachOtherText),
                    style: "text",
                    margin: [0, 5, 0, 0],
                });
            }

            if (content.plan.labsText.length > 0) {
                this.pdfDocument.content.push({
                    text: "Labs",
                    style: "title",
                    margin: [0, 30, 0, 0],
                });

                this.pdfDocument.content.push({
                    text: this.getPdfTextSections(content.plan.labsText),
                    style: "text",
                    margin: [0, 5, 0, 0],
                });
            }


            if (content.mdmData) {
                const mdmPatientTypes = new Map<MdmPatientType, string>()
                    .set(MdmPatientType.Established, 'Established Patient')
                    .set(MdmPatientType.New, 'New Patient');

                this.pdfDocument.content.push({
                    text: "MDM",
                    style: "title",
                    margin: [0, 30, 0, 10],
                });

                this.pdfDocument.content.push({
                    text: content.mdmData.mdmText,
                    style: "text",
                    margin: [0, 8, 0, 0]
                });

                this.pdfDocument.content.push({
                    text: "Coding",
                    style: "subTitle",
                    margin: [0, 15, 0, 0],
                });

                this.pdfDocument.content.push({
                    text: mdmPatientTypes.get(content.mdmData.selectedMdmPatientType),
                    style: "text",
                    margin: [0, 8, 0, 0]
                });

                this.pdfDocument.content.push({
                    text: mdmPatientTypes.get(content.mdmData.selectedCodeId),
                    style: "text",
                    margin: [0, 8, 0, 0]
                });

                if (content.mdmData.selectedMode == MdmMode.TimeBased) {
                    this.pdfDocument.content.push({
                        text: "Time Based Billing",
                        style: "subTitle",
                        margin: [0, 15, 0, 0],
                    });

                    this.pdfDocument.content.push({
                        text: `I spent ${content.mdmData.spentTime + content.duration?.value} minutes meeting with the patient, reviewing results, updating plan and treatments, and/or consulting another provider.`,
                        style: "text",
                        margin: [0, 8, 0, 0]
                    });
                }
                else {
                    const mdmCode = mdmCodes.find(x => x.id === content.mdmData.selectedCodeId);

                    if (mdmCode) {
                        const categories = mdmCode.categories.filter(x => (content.mdmData.selectedCategoryIds ?? [content.mdmData.selectedCategoryId]).some(t => t === x.id));

                        this.pdfDocument.content.push({
                            text: mdmCode.title,
                            style: "subTitle",
                            margin: [0, 15, 0, 0],
                        });

                        if (content.mdmData.showCodeDescription) {
                            this.pdfDocument.content.push({
                                text: mdmCode.description,
                                style: "text",
                                margin: [0, 8, 0, 0]
                            });
                        }

                        categories.map((category) => {
                            content.mdmData.selectedCategoryItems.map((item) => {
                                const allItems = category.items;
                                const categoryItem = allItems?.find(x => x.id === item.id);

                                if (categoryItem) {
                                    this.pdfDocument.content.push({
                                        text: `${categoryItem.description} ${categoryItem.allowInput
                                            ? ` ${item.inputValue ?? ''}`
                                            : ''}`,
                                        style: "text",
                                        margin: [0, 8, 0, 0]
                                    });
                                }
                            })
                        });
                    }
                }
            }

            if (content.diagnosis.length > 0) {
                this.pdfDocument.content.push({
                    text: "Diagnosis",
                    style: "title",
                    margin: [0, 30, 0, 0],
                });

                content.diagnosis.forEach((diagnosis, index) => {
                    this.pdfDocument.content.push({
                        text: `${index + 1}. ${diagnosis.code} ${diagnosis.description} ${this.displayInfoDiagnosis(diagnosis.additionalInformation)}`,
                        style: "text",
                        margin: [0, 5, 0, 0],
                    });
                })
            }

            if (content.chiefComplaint.length > 0) {
                this.pdfDocument.content.push({
                    text: "Chief Complaint",
                    style: "title",
                    margin: [0, 30, 0, 0],
                });

                this.pdfDocument.content.push({
                    text: this.getPdfTextSections(content.chiefComplaint),
                    style: "text",
                    margin: [0, 5, 0, 0],
                });
            }

            if (content.HPI.length > 0) {
                this.pdfDocument.content.push({
                    text: "HPI",
                    style: "title",
                    margin: [0, 30, 0, 0],
                });

                this.pdfDocument.content.push({
                    text: this.getPdfTextSections(content.HPI),
                    style: "text",
                    margin: [0, 5, 0, 0],
                });
            }

            this.pdfDocument.content.push({
                text: "PMH",
                style: "title",
                margin: [0, 30, 0, 0],
            });

            if (content?.pmhVersion) {
                if (content.PMHnew.allergies.length > 0) {
                    this.pdfDocument.content.push({
                        text: "Allergies",
                        style: "subTitle",
                        margin: [0, 15, 0, 0],
                    });

                    content.PMHnew.allergies.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: this.getPdfTextSections(`${index + 1}. ` + `${this.getItemName(item)}`),
                            style: "text",
                            margin: [0, 5, 0, 0],
                        });
                    });
                }

                if (content.PMHnew.rxntAllergies?.length > 0 && rxntIntegration) {
                    this.pdfDocument.content.push({
                        text: "Allergies",
                        style: "subTitle",
                        margin: [0, 15, 0, 0],
                    });

                    content.PMHnew.rxntAllergies?.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: this.getPdfTextSections(`${index + 1}. ` + `${item.description ? `${item.name} - ${item.description}` : item.name}`),
                            style: "text",
                            margin: [0, 5, 0, 0],
                        });
                    });
                }

                if (content.PMHnew.currentMedicalConditions.length > 0) {
                    this.pdfDocument.content.push({
                        text: "Problem List",
                        style: "subTitle",
                        margin: [0, 15, 0, 0],
                    });

                    content.PMHnew.currentMedicalConditions.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: this.getPdfTextSections(`${index + 1}. ` + `${this.getItemName(item)}`),
                            style: "text",
                            margin: [0, 5, 0, 0],
                        });
                    });
                }

                if (content.PMHnew.famHX.length > 0) {
                    this.pdfDocument.content.push({
                        text: "Fam HX",
                        style: "subTitle",
                        margin: [0, 15, 0, 0],
                    });

                    content.PMHnew.famHX.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: this.getPdfTextSections(`${index + 1}. ` + `${this.getItemName(item)}`),
                            style: "text",
                            margin: [0, 5, 0, 0],
                        });
                    });
                }

                if (content.PMHnew.screeningHealthMaintenanceApoe) {
                    if (content.PMHnew.screeningHealthMaintenanceApoe.hideApoe !== YesNo.Yes) {
                        this.pdfDocument.content.push({
                            text: "Screening/Health Maintenance",
                            style: "subTitle",
                            margin: [0, 15, 0, 0],
                        });


                        this.pdfDocument.content.push({
                            text: `Your APOE Status: Apo-${content.PMHnew.screeningHealthMaintenanceApoe.score}  ${content.PMHnew.screeningHealthMaintenanceApoe.source === ApoeSoursConstants.BloodTest ? `(99%)` : ''}`,
                            style: "text",
                            margin: [0, 5, 0, 0],
                        });
                    }

                } else if (content.PMHnew.screeningHealthMaintenance.length > 0) {
                    this.pdfDocument.content.push({
                        text: "Screening/Health Maintenance",
                        style: "subTitle",
                        margin: [0, 15, 0, 0],
                    });


                    const apoe = this.getApoeInfo(content.PMHnew.screeningHealthMaintenance)

                    if (!!apoe) {
                        this.pdfDocument.content.push({
                            text: apoe,
                            style: "text",
                            margin: [0, 5, 0, 0],
                        });
                    }

                    content.PMHnew.screeningHealthMaintenance.filter(el => !el.key.includes('APOE')).forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: this.getPdfTextSections(`${index + 1}. ` + `${this.getItemName(item)}`),
                            style: "text",
                            margin: [0, 5, 0, 0],
                        });
                    });
                }



                if (content.PMHnew.surgicalHX.length > 0) {
                    this.pdfDocument.content.push({
                        text: "Surgical HX",
                        style: "subTitle",
                        margin: [0, 15, 0, 0],
                    });

                    content.PMHnew.surgicalHX.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: this.getPdfTextSections(`${index + 1}. ` + `${this.getItemName(item)}`),
                            style: "text",
                            margin: [0, 5, 0, 0],
                        });
                    });
                }

                if ([...content.PMHnew.socialHX, ...content.PMHnew.lifestyleHX].length > 0) {
                    this.pdfDocument.content.push({
                        text: "Social/Lifestyle HX",
                        style: "subTitle",
                        margin: [0, 15, 0, 0],
                    });

                    [...content.PMHnew.socialHX, ...content.PMHnew.lifestyleHX].forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: this.getPdfTextSections(`${index + 1}. ` + `${this.getItemName(item)}`),
                            style: "text",
                            margin: [0, 5, 0, 0],
                        });
                    });
                }
            } else {
                if (content.PMH.allergies.length > 0) {
                    this.pdfDocument.content.push({
                        text: "Allergies",
                        style: "subTitle",
                        margin: [0, 15, 0, 0],
                    });

                    content.PMH.allergies.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: this.getPdfTextSections(`${index + 1}. ` + `${item}`),
                            style: "text",
                            margin: [0, 5, 0, 0],
                        });
                    });
                }

                if (content.PMH.currentMedicalConditions.length > 0) {
                    this.pdfDocument.content.push({
                        text: "Problem List",
                        style: "subTitle",
                        margin: [0, 15, 0, 0],
                    });

                    content.PMH.currentMedicalConditions.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: this.getPdfTextSections(`${index + 1}. ` + `${item}`),
                            style: "text",
                            margin: [0, 5, 0, 0],
                        });
                    });
                }

                if (content.PMH.famHX.length > 0) {
                    this.pdfDocument.content.push({
                        text: "Fam HX",
                        style: "subTitle",
                        margin: [0, 15, 0, 0],
                    });

                    content.PMH.famHX.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: this.getPdfTextSections(`${index + 1}. ` + `${item}`),
                            style: "text",
                            margin: [0, 5, 0, 0],
                        });
                    });
                }

                if (content.PMH.screeningHealthMaintenance.length > 0) {
                    this.pdfDocument.content.push({
                        text: "Screening/Health Maintenance",
                        style: "subTitle",
                        margin: [0, 15, 0, 0],
                    });

                    content.PMH.screeningHealthMaintenance.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: this.getPdfTextSections(`${index + 1}. ` + `${item}`),
                            style: "text",
                            margin: [0, 5, 0, 0],
                        });
                    });
                }

                if (content.PMH.surgicalHX.length > 0) {
                    this.pdfDocument.content.push({
                        text: "Surgical HX",
                        style: "subTitle",
                        margin: [0, 15, 0, 0],
                    });

                    content.PMH.surgicalHX.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: this.getPdfTextSections(`${index + 1}. ` + `${item}`),
                            style: "text",
                            margin: [0, 5, 0, 0],
                        });
                    });
                }

                if (content.PMH.socialLifestyleHX.length > 0) {
                    this.pdfDocument.content.push({
                        text: "Social/Lifestyle HX",
                        style: "subTitle",
                        margin: [0, 15, 0, 0],
                    });

                    content.PMH.socialLifestyleHX.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: this.getPdfTextSections(`${index + 1}. ` + `${item}`),
                            style: "text",
                            margin: [0, 5, 0, 0],
                        });
                    });
                }
            }

            if (content.ROS.rosEnabled) {
                this.pdfDocument.content.push({
                    text: "ROS",
                    style: "title",
                    margin: [0, 30, 0, 0],
                });

                if (content.ROS.rosValues.length > 0) {
                    this.pdfDocument.content.push({
                        text: `All systems reviewed and negative except:`,
                        style: "text",
                        margin: [0, 5, 0, 0],
                    });

                    content.ROS.rosValues.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: `${index + 1}. ${item}`,
                            style: "text",
                            margin: [0, 5, 0, 0],
                        });
                    })
                }
                else {
                    this.pdfDocument.content.push({
                        text: `All systems reviewed and negative`,
                        style: "text",
                        margin: [0, 5, 0, 0],
                    });
                }
            }

            if ((medications.length && !rxntIntegration) || (rxntMedications && rxntIntegration) || supplements.length || fullscriptSupplements?.length) {
                this.pdfDocument.content.push({
                    text: "Medications and Supplements Added During the Visit",
                    style: "title",
                    margin: [0, 30, 0, 10],
                });

                if (medications.length > 0 && !rxntIntegration) {
                    this.pdfDocument.content.push({
                        text: 'Medications:',
                        style: "text",
                        margin: [0, 8, 0, 0]
                    });

                    medications.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: `${index + 1}. ${item.name}${item.dosage ? `, ${item.dosage}` : ''}`,
                            style: "text",
                            margin: [0, 5, 0, 0]
                        })
                    });
                }

                if (rxntMedications?.length > 0 && rxntIntegration) {
                    this.pdfDocument.content.push({
                        text: 'Medications:',
                        style: "text",
                        margin: [0, 8, 0, 0]
                    });

                    rxntMedications?.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: `${index + 1}. ${item.name}${item.dosingInstructions ? `, ${item.dosingInstructions}` : ''}`,
                            style: "text",
                            margin: [0, 5, 0, 0]
                        })
                    });
                }

                if (supplements.length > 0 || fullscriptSupplements?.length > 0) {
                    this.pdfDocument.content.push({
                        text: 'Supplements:',
                        style: "text",
                        margin: [0, 8, 0, 0]
                    });

                    supplements.forEach((item, index) => {
                        const url = item.purchaseLink
                            ? item.purchaseLink.startsWith('http://') || item.purchaseLink.startsWith('https://')
                                ? item.purchaseLink
                                : `http://${item.purchaseLink}`
                            : '';

                        const content: any = {
                            text: `${index + 1}. ${item.name}${item.dosage ? `, ${item.dosage}` : ''}${url ? ` - Purchase here` : ''}`,
                            style: "text",
                            margin: [0, 5, 0, 0],
                        };

                        if (url) {
                            content.link = url;
                        }

                        this.pdfDocument.content.push(content);
                    });

                    (fullscriptSupplements ?? []).forEach((item, index) => {
                        const url = item.purchaseLink
                            ? item.purchaseLink.startsWith('http://') || item.purchaseLink.startsWith('https://')
                                ? item.purchaseLink
                                : `http://${item.purchaseLink}`
                            : '';

                        const content: any = {
                            text: `${index + 1}. ${item.name}${item.dosage ? `, ${item.dosage}` : ''}${url ? ` - Purchase via Fullscript` : ''}`,
                            style: "text",
                            margin: [0, 5, 0, 0],
                        };

                        if (url) {
                            content.link = url;
                        }

                        this.pdfDocument.content.push(content);
                    });
                }
            } else {
                this.pdfDocument.content.push({
                    text: "Medications and Supplements Added During the Visit",
                    style: "title",
                    margin: [0, 30, 0, 10],
                });

                this.pdfDocument.content.push({
                    text: 'No changes were made during this visit.',
                    style: "text",
                    margin: [0, 8, 0, 0]
                });
            }

            if ((stoppedMedications.length && !rxntIntegration) || (stoppedRxntMedications?.length && rxntIntegration) || stoppedSupplements.length) {
                this.pdfDocument.content.push({
                    text: "Medications and Supplements Stopped During the Visit",
                    style: "title",
                    margin: [0, 30, 0, 10],
                });

                if (stoppedMedications.length > 0 && !rxntIntegration) {
                    this.pdfDocument.content.push({
                        text: 'Medications:',
                        style: "text",
                        margin: [0, 8, 0, 0]
                    });

                    stoppedMedications.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: `${index + 1}. ${item.name}${item.dosage ? `, ${item.dosage}` : ''}`,
                            style: "text",
                            margin: [0, 5, 0, 0]
                        })
                    });
                }

                if (stoppedRxntMedications?.length > 0 && rxntIntegration) {
                    this.pdfDocument.content.push({
                        text: 'Medications:',
                        style: "text",
                        margin: [0, 8, 0, 0]
                    });

                    stoppedRxntMedications?.forEach((item, index) => {
                        this.pdfDocument.content.push({
                            text: `${index + 1}. ${item.name}${item.dosingInstructions ? `, ${item.dosingInstructions}` : ''}`,
                            style: "text",
                            margin: [0, 5, 0, 0]
                        })
                    });
                }

                if (stoppedSupplements.length > 0) {
                    this.pdfDocument.content.push({
                        text: 'Supplements:',
                        style: "text",
                        margin: [0, 8, 0, 0]
                    });

                    stoppedSupplements.forEach((item, index) => {
                        const content: any = {
                            text: `${index + 1}. ${item.name}${item.dosage ? `, ${item.dosage}` : ''}`,
                            style: "text",
                            margin: [0, 5, 0, 0],
                        };

                        this.pdfDocument.content.push(content);
                    });
                }
            } else {
                this.pdfDocument.content.push({
                    text: "Medications and Supplements Stopped During the Visit",
                    style: "title",
                    margin: [0, 30, 0, 10],
                });

                this.pdfDocument.content.push({
                    text: 'No changes were made during this visit.',
                    style: "text",
                    margin: [0, 8, 0, 0]
                });
            }

            this.pdfDocument.content.push({
                text: "Objective",
                style: "title",
                margin: [0, 30, 0, 0],
            });

            if (!content.physicalData.unableToObtain) {
                this.pdfDocument.content.push({
                    text: "Vitals",
                    style: "subTitle",
                    margin: [0, 15, 0, 0],
                });

                this.pdfDocument.content.push({
                    text: `Blood Pressure:        ${!!content.physicalData.systolicBP ? content.physicalData.systolicBP : ''} ${!!content.physicalData.diastolicBP ? '/ ' + content.physicalData.diastolicBP : ''}`,
                    style: "text",
                    margin: [0, 5, 0, 0],
                });

                this.pdfDocument.content.push({
                    text: `HR:                            ${!!content.physicalData.heartRate ? content.physicalData.heartRate : ''}`,
                    style: "text",
                    margin: [0, 5, 0, 0],
                });

                this.pdfDocument.content.push({
                    text: `Temperature:             ${!!content.physicalData.temperature ? content.physicalData.temperature + "°F" : ''}`,
                    style: "text",
                    margin: [0, 5, 0, 0],
                });

                this.pdfDocument.content.push({
                    text: `Weight (lbs):              ${!!content.physicalData.weight ? content.physicalData.weight : ''}`,
                    style: "text",
                    margin: [0, 5, 0, 0],
                });

                this.pdfDocument.content.push({
                    text: `Height (inches):         ${!!content.physicalData.height ? content.physicalData.height : ''}`,
                    style: "text",
                    margin: [0, 5, 0, 0],
                });
            }

            this.pdfDocument.content.push({
                text: this.getPdfTextSections(`Objective:\n` + `${content.physicalData.physicalExam}`),
                style: "text",
                margin: [0, 5, 0, 0],
            });

            if (note.nextCoachAppointmentDate && note.nextProviderAppointmentDate) {
                this.pdfDocument.content.push({
                    text: "Next Appointment",
                    style: "title",
                    margin: [0, 30, 0, 0],
                });

                if (note.nextCoachAppointmentDate) {
                    this.pdfDocument.content.push({
                        text: `Coach Visit:               ${moment(note.nextCoachAppointmentDate).endOf('day').fromNow()}`,
                        style: "text",
                        margin: [0, 5, 0, 0],
                    });
                }

                if (note.nextProviderAppointmentDate) {
                    this.pdfDocument.content.push({
                        text: `Provider:                    ${moment(note.nextProviderAppointmentDate).endOf('day').fromNow()}`,
                        style: "text",
                        margin: [0, 5, 0, 0],
                    });
                }
            }

        }

        this.renderSignature(this.pdfDocument, note, amendedList != null && amendedList.length > 0, noteId);
    }
}

export const initialConsultNotePdfService = new InitialConsultNotePdfService();
export const initialConsultNotePdfServiceOld = new InitialConsultNotePdfServiceOld();
export const followUpNotePdfService = new FollowUpNotePdfService();
export const internalNotePdfService = new InternalNotePdfService();
export const historyAndPhysicalPdfService = new HistoryAndPhysicalPdfService();
export const soapNotePdfService = new SoapNotePdfService();

