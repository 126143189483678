import React from 'react';
import { AuthenticatedLayoutComponent } from "../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent";
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from "../../../../models/menu.models";
import { PatientsSubmissionsComponent } from "../../components/patientsSubmissionsComponent/PatientsSubmissionsComponent";

export const PatientsSubmissionsPage: React.FC = () => {

    return (
        <AuthenticatedLayoutComponent
            hideBackground
            displayChildren='flex'
            title={TitlesService.patientsSubmissionsTitle}
            pageName={PagesNamesService.patientsSubmissions}
            selectedMenuItem={MenuItemTypes.PatientsSubmissions}
        >
            <PatientsSubmissionsComponent />
        </AuthenticatedLayoutComponent>
    )
};
