import { useState } from "react";
import { IPersonModel } from "../../../common/models/user.models";
import { patientsService } from '../../../patients/services/patients.service';

interface AssignLabsDialogComponentState {
    isPatientsLoading: boolean;
    patients: IPersonModel[];
    selectedPatient: IPersonModel;
    selectedUsersIds: number[];
}

const defaultState: AssignLabsDialogComponentState = {
    isPatientsLoading: false,
    patients: [],
    selectedPatient: null,
    selectedUsersIds: [],
}

export function useFacade(handleSubmit: (patientId: number) => void, handleCancel: () => void): [AssignLabsDialogComponentState,
    (id: number) => void,
    (searchQuery: string) => void,
    () => void,
    () => void] {
    const [state, setState] = useState(defaultState);

    const handlePatientSelect = (id: number) => {
        const user = state.patients.find(i => i.id === id);
        if (user) {
            setState({...state, selectedPatient: user});
        }
    }

    const handlePatientSearch = (searchQuery: string) => {
        setState(state => ({...state, isPatientsLoading: true}));

        const successCB = (patients) => setState(state => ({...state, isPatientsLoading: false, patients: patients}));
        const errorCB = () => setState(state => ({...state, isPatientsLoading: false}));

        patientsService.getAll([],[],[],[],[],[], searchQuery, 0, 1000).subscribe(successCB, errorCB);
    }

    const handleCloseDialog = () => {
        setState({...defaultState});
        handleCancel();
    }

    const handleApprove = () => {
        handleSubmit(state.selectedPatient.id);
        handleCloseDialog();
    }

    return [state,
        handlePatientSelect,
        handlePatientSearch,
        handleCloseDialog,
        handleApprove
    ];
}
