import chartView from "@img/icons/chartView.svg";
import valuesView from "@img/icons/valuesView.svg";
import { Box, Grid, Table, TableBody, TableContainer, TableFooter, TableHead } from '@material-ui/core';
import React from 'react';
import { SelectViewModeComponent } from '../../../common/components/selectViewModeComponent/SelectViewModeComponent';
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { PaginationComponent } from "../../../common/pagination/components/paginationComponent/PaginationComponent";
import { getAvailablePages } from "../../../common/pagination/helpers/get-evailable-pages";
import { useFacade } from './biometricComponent.hooks';
import { VitalsHistoryView } from '../../stores';
import { useStyles } from './biometricComponent.styles';
import { VitalModel, VitalsDateRangeType } from "../../models/vital.model";
import { graphPageSizes } from "../../../common/pagination/models/page-sizes";
import { BiometricRowComponent } from "../biometricRowComponent/BiometricRowComponent";
import { SelectRangeComponent } from "../../../common/components/selectRangeComponent/SelectRangeComponent";
import { HealthReportPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/HealthReportPlaceHolder";

export interface BiometricComponentProps {
    patientId: number | null;
}

export const BiometricComponent: React.FC<BiometricComponentProps> = (props: BiometricComponentProps) => {
    const [
        state,
        columns,
        switchView,
        switchRange,
        handlePageSizeChange,
        handlePageChange,
    ] = useFacade(props.patientId);

    const {
        isLoading,
        view,
        range,
        avg,
        items,
    } = state;

    const classes = useStyles();

    const hasItems = items && items.length > 0 && items[0].values?.length > 0;

    const viewModes = [
        {
            id: VitalsHistoryView.Graph,
            title: 'Graph View',
            icon: <img alt="img"
                src={chartView}
                width={24} />,
        },
        {
            id: VitalsHistoryView.Values,
            title: 'Values View',
            icon: <img alt="img"
                src={valuesView}
                width={24} />,
        },
    ]

    const rangeModes = [
        {
            id: VitalsDateRangeType.SevenDays,
            title: 'Last 7 days',
        },
        {
            id: VitalsDateRangeType.ThirtyDays,
            title: 'Last 30 days',
        },
        {
            id: VitalsDateRangeType.SixMonth,
            title: 'Last 6 months',
        },
        {
            id: VitalsDateRangeType.Year,
            title: 'Last year',
        },
    ]

    const renderOptions = () => {
        return (
            <Box display='flex' justifyContent="space-between">
                <Box className={classes.title}>Biometrics</Box>
                <Box>
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="flex-end"
                    >
                        <Grid
                            item
                            xs
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-end"
                        >
                            <Box pr={1}>
                                <SelectViewModeComponent
                                    selectedId={view}
                                    options={viewModes}
                                    disabled={isLoading}
                                    handleChanges={(id: number) => switchView(id as VitalsHistoryView)}
                                />
                            </Box>
                            <Box pr={1}>
                                <SelectRangeComponent
                                    selectedId={range}
                                    options={rangeModes}
                                    disabled={isLoading}
                                    handleChanges={(id: number) => switchRange(id as VitalsDateRangeType)}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        )
    }

    const getVitals = () => {
        const vitals: Array<VitalModel[]> = [];

        items.forEach(vital => {
            if (!vitals.map(i => i[0].displayName).includes(vital.displayName)) {
                vitals.push([vital]);
            } else {
                const item = vitals.find(i => i[0].displayName === vital.displayName);
                item.push(vital);
            }
        });

        return vitals;
    }

    const renderTable = () => {
        if (isLoading) {
            return (
                <Box p={2}>
                    <WildHealthLinearProgress />
                </Box>
            )
        }

        if (!hasItems) {
            return <Box>
                {renderOptions()}
                <Box className={classes.placeHolder}><HealthReportPlaceHolder message='No Biometrics yet' /></Box>
            </Box>
        }

        const groupedVitals = getVitals();

        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <WildHealthTableRow>
                            {
                                columns.map((item, index) =>
                                    <WildHealthTableCell key={index}
                                        className={classes.cell}>{item}</WildHealthTableCell>
                                )
                            }
                        </WildHealthTableRow>
                    </TableHead>
                    <TableBody>
                        {
                            groupedVitals.map((vitals, index) => {

                                return (
                                    <React.Fragment key={index}>
                                        <BiometricRowComponent
                                            avg={avg}
                                            index={index}
                                            vitals={vitals}
                                            view={view}
                                            rowWidth={state.pageSize}
                                            selectedPage={state.selectedPage}
                                        />
                                    </React.Fragment>
                                )
                            })
                        }
                    </TableBody>
                    <TableFooter>
                        <WildHealthTableRow style={{ backgroundColor: "white" }}>
                            <WildHealthTableCell colSpan={11}>
                                <PaginationComponent
                                    pageSizes={graphPageSizes}
                                    pageSize={state.pageSize}
                                    selectedPage={state.selectedPage}
                                    handlePageSizeChange={handlePageSizeChange}
                                    totalCount={state.totalCount}
                                    availablePages={getAvailablePages(state)}
                                    handlePageSelect={handlePageChange} />
                            </WildHealthTableCell>
                        </WildHealthTableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        )
    }

    return (
        <Box p={4}>
            {hasItems && renderOptions()}
            {renderTable()}
        </Box>
    )
}