import { Box, Button, Divider, Grid, Table, TableBody, TableContainer, TableHead, CircularProgress, useTheme, useMediaQuery } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import React from 'react';
import { addOnProvidersNames } from '../../../addons/models/addOns.models';
import { OptionalComponent } from '../../../common/components/OptionalComponent';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { WildHealthTableCell } from '../../../common/components/wildHealthTable/WildHealthTableCell';
import { WildHealthTableRow } from '../../../common/components/wildHealthTable/WildHealthTableRow';
import { displayMoney } from '../../../common/helpers/display-money';
import commonUseStyles from '../../../common/styles/common.styles';
import { useFacade } from './labOrderPlacedComponent.hooks';
import { useStyles } from './labOrderPlacedComponent.styles';
import { ProtectedElement } from "../../../common/components/ProtectedElement";

interface LabOrderPlacedComponentProps {
    patientId: number;
    orderId: number;
    handleNavigateToOrders: () => void;
    handleNavigateToInvoice: (orderId: number) => void;
}

export const LabOrderPlacedComponent: React.FC<LabOrderPlacedComponentProps> = (props: LabOrderPlacedComponentProps) => {
    const { patientId, orderId, handleNavigateToOrders, handleNavigateToInvoice } = props;

    const classes = useStyles();
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const [
        {
            isLoading,
            order,
            total,
            isPaying,
        },
        columns,
        handleProcessCharge,
    ] = useFacade(patientId, orderId, handleNavigateToOrders);

    const renderTable = () => {

        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <WildHealthTableRow>
                            {
                                columns.map((item, index) =>
                                    <WildHealthTableCell key={index} align={item.alignment}>
                                        {item.title}
                                    </WildHealthTableCell>
                                )
                            }
                        </WildHealthTableRow>
                    </TableHead>
                    <TableBody>
                        {
                            order.items.map((item, index) =>
                                <WildHealthTableRow key={index}>
                                    <WildHealthTableCell align="left">
                                        {item.sku}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="left">
                                        {item.description}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="right">
                                        {displayMoney(item.price, '$')}
                                    </WildHealthTableCell>
                                </WildHealthTableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const renderContent = () => {

        if (isLoading) {
            return <WildHealthLinearProgress />;
        }

        return (
            <Box px={isSmallScreen && 2}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                >
                    <Box mt={3} m={1}>
                        <h2>Your order has been successfully placed!</h2>
                    </Box>
                    <Box m={1} mb={3} className={commonClasses.colorGray1}>
                        The lab requisition was sent to the patient via their email. Please proceed to process the invoice.
                    </Box>

                    <Box className={classes.root}>
                        <Box className={classes.invoice}>
                            <Box display='flex' justifyContent='space-between'>
                                <Box m={2} className={commonClasses.textMedium}>Lab Order Summary</Box>
                                <Box m={2}>{addOnProvidersNames.get(order.provider)}</Box>
                            </Box>

                            <Divider className={classes.divider} />

                            {renderTable()}

                            <Box className={classes.total} display='flex' justifyContent='flex-end'>
                                <Box pr={3}>Total</Box>
                                <span>{displayMoney(total, '$')}</span>
                            </Box>
                        </Box>

                        <Box display='flex' justifyContent='space-between' alignItems='center' my={2}>
                            <Box>
                                <Button id="lab-orders-placed-go-back" onClick={() => handleNavigateToOrders()}>
                                    <Box display='flex' alignItems='center'>
                                        <ArrowBackIosIcon />
                                        <Box ml={1}>Back</Box>
                                    </Box>
                                </Button>
                            </Box>
                            <Box>
                                <ProtectedElement element={
                                    <>
                                        <OptionalComponent
                                            defaultContent={
                                                <WildHealthButton id="lab-order-placed-invoice" onClick={() => handleNavigateToInvoice(orderId)}>
                                                    Process Invoice
                                                </WildHealthButton>
                                            }
                                        >
                                            <WildHealthButton id="lab-order-placed-charge" disabled={isPaying || order.paidAt !== null} onClick={() => handleProcessCharge(orderId)}>
                                                {isPaying ? (
                                                    <>
                                                        <CircularProgress size={24} className={commonClasses.colorMain} />
                                                        <Box pl={2}>Processing charge...</Box>
                                                    </>
                                                ) : "Process Charge"}
                                            </WildHealthButton>
                                        </OptionalComponent>
                                    </>
                                } permissions={[]}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Box>
        )
    }

    return renderContent();
}
