export enum AttachmentType {
    None = 0,
    InsuranceCardFront = 1,
    ScheduledMessageAttachment = 2,
    LabReports = 3,
    ImagingReports = 4,
    Consults = 5,
    ECGs = 6,
    HospitalReports = 7,
    Consents = 8,
    MedicalForms = 9,
    OldRecords = 10,
    Miscellaneous = 11,
    AgreementConfirmationDocument = 12,
    ProfilePhoto = 13,
    InsuranceCardBack = 14,
    EpigeneticReports = 24
}

export interface AttachmentModel {
    id: number,
    type: AttachmentType;
    name: string;
    description: string;
    referenceId: number;
    dateUploaded: Date;
    isViewed: boolean;
    isViewableByPatient: boolean;
    uploadedBy: string;
    uploadedById: number;
}