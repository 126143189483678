import { useEffect, useState } from "react";
import { confirmService } from "../../../../services/confirm.service";
import { snackService } from "../../../common/snack/state";
import { healthReportServices } from "../../services/healthReport.services";
import { RecommendationSetType } from "../../models/healthReport.models";

interface ResetPatientPasswordDialogState {
    selectAll: boolean,
    isPrepereRecommendation: boolean,
}

export function useFacade(handleClose: Function,  patientId: number, open: boolean, handleReportGenerate: Function, handleReportRegenerate: Function): 
    [ResetPatientPasswordDialogState, Function, Function, Function, Function] {
    const [state, setState] = useState({
        selectAll: false,
        isPrepereRecommendation: false,
    } as ResetPatientPasswordDialogState);

    const setInitialState = () => {
        setState(state => ({
            ...state,
            selectAll: false,
        }));
    }

    const handleSubmitUpdate = () => {
        setState(state => ({
            ...state,
            isPrepereRecommendation: !state.isPrepereRecommendation,
        }));

        confirmService.confirm(
            'Confirm Report Regenerate',
            'Have you finished editing all recommendations?',
            'Confirm',
            'Cancel',)
            .subscribe(
              () =>  {
                handleReportRegenerate()
                handleClose();
              });
    }


    const handleSubmit = () => {
        setState(state => ({
            ...state,
            isPrepereRecommendation: !state.isPrepereRecommendation,
        }));

        confirmService.confirm(
            'Confirm New Report Generation',
            'Have you finished editing all recommendations?',
            'Confirm',
            'Cancel',)
            .subscribe(
              () =>  {
                handleReportGenerate()
                handleClose();
              });
    }

    const handleCancel = () => {
        confirmService.confirm(
            'Cancel New Report Generation',
            'Are you sure you want to cancel the report generation?',
            'Yes',
            'No',
            'danger')
            .subscribe(
              () =>  {
                healthReportServices.getLast(patientId).subscribe(() => {  
                    handleClose();
                    snackService.deleteSuccess('New report generation was cancelled.')
                },
                () => {
                    handleClose();
                });
                
              }
            );
    }

    const handleCancelRegenerate = () => {
        confirmService.confirm(
            'Cancel Report Regenerate',
            'Are you sure you want to cancel the report regenerate?',
            'Yes',
            'No',
            'danger')
            .subscribe(
              () =>  {
                healthReportServices.getLast(patientId).subscribe(() => {  
                    handleClose();
                    snackService.deleteSuccess('Report regenerate was cancelled.')
                },
                () => {
                    handleClose();
                });
                
              }
            );
    }

    useEffect(() => {
        if (!open) {
            setInitialState()
        } 

    }, [open]);


    return [state, handleSubmitUpdate, handleSubmit, handleCancel, handleCancelRegenerate];
}
