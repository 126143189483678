import React from "react";
import { DialogComponentProps } from "../../../common/dialogs/dialogProps";
import {useFacade} from "./activateSubscriptionComponent.hooks";
import {SelectDataDialogComponent} from "../../../common/components/selectDataSetDialogComponent/SelectDataDialogComponent";

export const ActivateSubscriptionComponent: React.FC<DialogComponentProps> = (props: DialogComponentProps) => {
    const {
        open,
        handleSubmit,
        handleClose
    } = props;

    const [
        handleSubmitForm,
        handleCloseDialog
    ] = useFacade(handleSubmit, handleClose);

    return (
        <>
            <SelectDataDialogComponent
                open={open}
                disablePast={true}
                title='Activate Membership'
                message='Choose a start date'
                actionName='Confirm'
                handleClose={() => handleCloseDialog(false)}
                handleSubmit={(selectedDate) => handleSubmitForm(selectedDate)}
                disableDates={[]}/>
        </>
    )
}
