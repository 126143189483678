import { IconButton, OutlinedInput } from "@material-ui/core";
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      padding: "10px 0",
      flexDirection: "column",
      alignItems: "center",
      gap: 2,
    },

    messageContainer: {
      display: "flex",
      position: "relative",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },

    message: {
      flex: 1,
    },

    icon: {
      fill: "currentColor",
      width: 20,
      height: 20,
    },

    attachmentsList: {
      display: "flex",
      overflow: "auto",
      maxWidth: 267,
      gap: 13,
      background: colors.separators,
      borderRadius: "22px 22px 0px 0px",
      paddingTop: 19,
      paddingBottom: 10,
      paddingLeft: 10,
    },
    attachmentContainer: {
      position: "relative",
      flexShrink: 0,
      height: 83,
      width: 77,
      "&:hover": {
        "& $removeAttachment": {
          visibility: "visible",
        },
      },
    },
    imageAttachment: {
      backgroundRepeat: "no-repeat",
      borderRadius: 2,
      backgroundSize: "68px 74px",
      height: 74,
      width: 68,
      position: "absolute",
      top: 9,
    },
    attachment: {
      border: `1px solid ${colors.stroke}`,
      borderRadius: 2,
      background: colors.lightGray,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: 6,
      maxWidth: 68,
      height: 74,
      position: "absolute",
      top: 9,
      padding: "10px 8px",
    },
    attachmentIcon: {
      color: colors.gray2,
      opacity: 0.6,
    },
    attachmentTitle: {
      width: "100%",
      fontSize: 10,
      fontWeight: 400,
      color: colors.gray1,
      overflow: "hidden",
      textOverflow: "ellipsis",
      lineHeight: "12px",
    },
    removeAttachment: {
      visibility: "hidden",
      position: "absolute",
      background: colors.gray1,
      borderRadius: "50%",
      color: colors.separators,
      right: 0,
      zIndex: 1,
    },
    disabledMessage: {
      position: "absolute",
      zIndex: 1,
      display: "flex",
      width: "100%",
      height: "100%",
      opacity: 0,
    },

    rootInput: {
      "&:focus": {
        outline: "none !important",
      },
      "& label.Mui-focused": {
        outline: "none !important",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "transparent",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "transparent",
        },
        "&:hover fieldset": {
          borderColor: "transparent",
        },
        "&.Mui-focused fieldset": {
          outline: "none !important",
          borderColor: "transparent",
        },
      },
      [theme.breakpoints.down("xs")]: {
        background: colors.separators,
        width: 267,
        borderRadius: "22px",
        paddingRight: 0,
      },
    },
  })
);

export const InputField = withStyles((theme: Theme) => ({
  root: {
    "&:focus": {
      outline: "none !important",
    },
    "& label.Mui-focused": {
      outline: "none !important",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "transparent",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "transparent",
      },
      "&:hover fieldset": {
        borderColor: "transparent",
      },
      "&.Mui-focused fieldset": {
        outline: "none !important",
        borderColor: "transparent",
      },
    },
    [theme.breakpoints.down("xs")]: {
      background: colors.separators,
      width: 267,
      borderRadius: "22px",
      paddingRight: 0,
    },
  },
}))(OutlinedInput);

export const ActionButton = withStyles((theme: Theme) => ({
  root: {
    fill: "currentColor",
    color: colors.main,
    "&:hover": {
      color: `#${colors.main}CC`,
    },
    "&:disabled": {
      color: colors.gray2,
    },
  },
}))(IconButton);
