import {IErrorState} from "../../../common/validation/error-state";
import {LoginWithAuth2Model} from "../../models/auth.models";
import {useHistory} from "react-router";
import {authQuery} from "../../stores/auth";
import {navigationService} from "../../../../services/navigation.service";
import React, {useEffect, useState} from "react";
import {ExternalAuthProvider} from "../../models/auth.enums";
import {SITE_KEY} from "../../../../config";
import Recaptcha from "react-google-invisible-recaptcha";
import {thirdPartyIntegrationService} from "../../services/thirdPartyIntegration.service";

interface ThirdPartyIntegrationCodePageState extends IErrorState {
    model: LoginWithAuth2Model;
    isLoading: boolean;
    recapReady: boolean;
}

export function useFacade(code: string, provider: string, redirectTo: string): [ThirdPartyIntegrationCodePageState, () => JSX.Element] {
    const _history = useHistory();

    const _siteKey = SITE_KEY;
    let _recaptcha = undefined;

    const [state, setState] = useState({
        model: {
            code: code,
            provider: ExternalAuthProvider[provider]
        },
        isLoading: false,
        recapReady: false
    } as ThirdPartyIntegrationCodePageState);

    const renderRecaptcha = () => {
        return (
            <Recaptcha
                ref={ref => {_recaptcha = ref; }}
                sitekey={_siteKey}
                onResolved={(token) => {
                    state.model.antiSpamToken = token;
                    auth();
                }}
                onLoaded={() => {
                    setState(state => ({...state, recapReady: true}));
                }}
            />
        )
    }

    const auth = () => {
        setState(state => ({
            ...state,
            isLoading: true
        }));

        thirdPartyIntegrationService.create(state.model).subscribe(
            () => {
                if (redirectTo && redirectTo.length) {
                    _history.push(redirectTo);
                } else {
                    if (!authQuery.isRegistrationCompleted()) {
                        navigationService.toGetStarted(_history);
                    } else {
                        navigationService.toDashboard(_history);
                    }
                }
            },
            () => {
                //
            })
    }

    useEffect(() => {
        if(state.recapReady) {
            _recaptcha.execute();
        }
        return () => {}
    }, [state.recapReady]);

    return [state, renderRecaptcha];
}