import {
    Box,
    IconButton,
    InputAdornment,
    OutlinedInput,
} from '@material-ui/core';
import React, { MouseEventHandler } from 'react';
import { useStyles, ActionButton } from '../sendMessageComponent/sendMessageComponent.styles';
import { EmojiComponent } from '../emojiComponent/EmojiComponent';
import { FeatureComponent } from '../../../common/components/featureFlags/FeatureComponent';
import { FeatureFlag } from '../../../common/components/featureFlags/featureFlags.models';
import { ReactComponent as SendIcon } from '@img/icons/Send.svg';
import { ReactComponent as AttachIcon } from '@img/icons/Attach.svg';
import { ReactComponent as ReactionIcon } from '@img/icons/Reaction.svg';
import { ReactComponent as ScheduleIcon } from '@img/icons/Schedule.svg';
import DescriptionIcon from '@material-ui/icons/Description';
import CloseIcon from '@material-ui/icons/Close';
import { AttachFileModel } from '../../models/message.models';
import { SelectShortcutComponent } from '../../../healthReport/components/selectShortcutComponent/SelectShortcutComponent';
import { ProtectedElement } from '../../../common/components/ProtectedElement';
import { UserType } from '../../../auth/models/auth.enums';
import {useFacade} from "../sendMessageComponent/sendMessageComponent.hooks";

interface SendMessageCompactComponentProps {
    canAttach?: boolean;
    canSchedule?: boolean;
    inputProps?: any;
    placeholder?: string;
    handleAttach?: MouseEventHandler;
    attachments?: AttachFileModel[];
    emptyMessage?: boolean;
    handleRemoveAttachment?: (index: number) => void;
    handleSubmit: (message: any) => void;
    handleTyping: () => void;
    handleSchedule?: (message: string) => void;
}

export const SendMessageCompactComponent: React.FC<SendMessageCompactComponentProps> = (props: SendMessageCompactComponentProps) => {
    const {
        handleSubmit,
        handleSchedule: schedule,
        handleTyping,
        handleRemoveAttachment,
        canAttach,
        canSchedule,
        inputProps,
        handleAttach,
        placeholder = 'Type message here...',
        attachments = [],
        emptyMessage,
    } = props;

    const classes = useStyles();

    const [
        {
            message,
            emojiAnchorElement
        },
        handleKeyDown,
        handleChangeMessage,
        handleToggleReaction,
        handleInsertReaction,
        handleSchedule,
        handleSendMessage
    ] = useFacade(handleTyping, handleSubmit, schedule, emptyMessage);

    return (
        <div className={classes.root}>
            <Box className={classes.message}>
                {
                    inputProps &&
                    <input id="files" {...inputProps} />
                }

                <Box display="flex" alignItems="flex-end">
                    {
                        canAttach &&
                        <Box>
                            <ActionButton id="send-message-attach" size='medium' onClick={handleAttach}>
                                <AttachIcon className={classes.icon} />
                            </ActionButton>
                        </Box>
                    }
                    {
                        canSchedule &&
                        <Box>
                            <ActionButton
                                id="send-message-schedule"
                                size='medium'
                                onClick={() => handleSchedule()}
                                disabled={message.trim().length < 1}
                            >
                                <ScheduleIcon className={classes.icon} />
                            </ActionButton>
                        </Box>
                    }
                    <Box pl={0.5} py={1}>
                        <OutlinedInput
                            className={classes.compactMessageInput}
                            fullWidth
                            value={message}
                            id='type-message'
                            multiline
                            rowsMax={7}
                            inputProps={{ className: 'flat-scroll', size: 'small' }}
                            placeholder={placeholder}
                            onKeyDown={(e) => handleKeyDown(e, message)}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeMessage(e.target.value)}
                            endAdornment={
                                <InputAdornment position="end" className={classes.compactMessageInputAdornment}>
                                    <FeatureComponent featureFlag={FeatureFlag.ConversationsEmojis}>
                                        <ActionButton style={{padding: '0'}} id="send-message-reaction" size='medium' onClick={(e) => handleToggleReaction(e.currentTarget)}>
                                            <ReactionIcon className={classes.icon} />
                                        </ActionButton>
                                    </FeatureComponent>
                                </InputAdornment>
                            }
                        />
                    </Box>
                    <Box>
                        <ActionButton
                            id="send-message"
                            onClick={() => handleSendMessage()}
                            disabled={message.trim().length < 1 && !(attachments && attachments.length)}
                        >
                            <SendIcon className={classes.sendIcon} />
                        </ActionButton>
                    </Box>
                </Box>
                <Box display="flex" flexWrap="wrap">
                    {
                        attachments && attachments.length ?
                            attachments.map((item, index) =>
                                item.file.type.includes('image') ?
                                    <Box key={index} className={classes.imageAttachment} style={{ backgroundImage: `url(${item.url})` }}>
                                        <IconButton id={`send-message-remove-attach-img-${index}`} className={classes.removeAttachment} onClick={() => handleRemoveAttachment(index)}>
                                            <CloseIcon style={{ fontSize: 20 }} />
                                        </IconButton>
                                    </Box> :
                                    <Box key={index} className={classes.attachment} ml={1} mb={1} px={1}>
                                        <DescriptionIcon className={classes.attachmentIcon} />
                                        <Box className={classes.attachmentTitle} ml={1}>{item.file.name}</Box>
                                        <IconButton id={`send-message-remove-attach-${index}`} className={classes.removeAttachment} size='small' onClick={() => handleRemoveAttachment(index)}>
                                            <CloseIcon style={{ fontSize: 14 }} />
                                        </IconButton>
                                    </Box>
                            ) : <></>
                    }
                </Box>
                <FeatureComponent featureFlag={FeatureFlag.ConversationsEmojis}>
                    {
                        Boolean(emojiAnchorElement) && (
                            <EmojiComponent
                                anchorEl={emojiAnchorElement}
                                handleClose={() => handleToggleReaction()}
                                handleSelectEmoji={handleInsertReaction}
                            />
                        )
                    }
                </FeatureComponent>
            </Box>

            <ProtectedElement
                element={
                    <SelectShortcutComponent />
                }
                permissions={[]}
                userType={UserType.Employee}
            />
        </div>
    )
};
