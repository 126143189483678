import { DeleteNotificationsModel, NotificationModel } from "../models/notifications.models";
import { NotificationsConstants } from '../../../constants/notification.contants';
import {notificationsQuery, NotificationsQuery, NotificationsStore, notificationsStore} from '../stores/notifications';
import { authHeader } from '../../common/helpers/auth-header';
import { snackService } from '../../common/snack/state';
import { authQuery } from '../../auth/stores/auth';
import Axios from 'axios-observable';
import { Observable } from "rxjs";
import { AlertsHistoryModel, AlertsModel } from "../models/alerts.models";
import { PaginationModel } from "../../common/pagination/models/pagination-models";

/**
 * Provides method for working with notifications and identity
 */
export class NotificationsService {
    private url = `${process.env.REACT_APP_API_URL}Notifications`;
    private urlAlerts = `${process.env.REACT_APP_API_URL}DashboardAlerts`;
    private interval = null;

    constructor(
        private notificationsStore: NotificationsStore,
        private notificationsQuery: NotificationsQuery) {

    }

    public connect() {
        if(this.interval) {
            return;
        }

        this.getAll();

        this.interval = setInterval(() => {
            if (!authQuery.isLoggedIn()) {
                return this.disconnect();
            }

            this.getNewNotifications(this.notificationsQuery.getLastNotification()?.id ?? null);
        }, NotificationsConstants.checkNewNotificationsInterval);
    }

    public disconnect() : void {
        clearInterval(this.interval);
        this.interval = null;
    }

    public getAll() {
        const config = { headers: authHeader() };
        Axios.get(this.url, config).pipe()
            .subscribe(
                response => this.notificationsStore.update({notifications: response.data}),
                error => snackService.commonErrorHandler(error)
            );
    }

    public getNewNotifications(lastNotificationId: number | null) {
        const config = { headers: authHeader() };
        const query = lastNotificationId !== null
            ? `?lastNotificationId=${lastNotificationId}`
            : '';

        Axios.get(`${this.url}/new${query}`, config).pipe()
            .subscribe(
                response => this.notificationsStore.append(response.data),
                error => snackService.commonErrorHandler(error)
            );
    }

    public delete(id: number) {
        Axios.delete(`${this.url}/${id}`, { headers: authHeader() }).pipe()
            .subscribe(
                () => this.notificationsStore.deleteNotification(id),
                error => snackService.commonErrorHandler(error)
            );
    }

    public deleteAll() {
        Axios.delete(this.url, { headers: authHeader() }).pipe()
            .subscribe(
                () => this.notificationsStore.update({ notifications:[] }),
                error => snackService.commonErrorHandler(error)
            );
    }

    public deleteBulk(notifications: NotificationModel[]) {
        const model: DeleteNotificationsModel = {
            ids: notifications.map(x => x.id)
        };

        const url = `${this.url}/Bulk/Delete`;

        Axios.post(url, model, { headers: authHeader() }).pipe()
            .subscribe(
                () => notificationsStore.deleteNotifications(notifications),
                () => {}
            );
    }

    public getAlerts():  Observable<AlertsModel[]> {
        const config = { headers: authHeader() };

        return new Observable((observer) =>
        Axios.get(`${this.urlAlerts}`, config)
                .pipe()
                .subscribe(
                    response => {
                        this.notificationsStore.update({alerts: response.data})
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    };

    public getAlertsHistory(
        page: number,
        pageSize: number,
        searchQuery: string = null,
    ): Observable<AlertsHistoryModel[]> {
        const config = { headers: authHeader() };
        return new Observable<AlertsHistoryModel[]>(observer => {

            Axios.get<PaginationModel<AlertsHistoryModel>>(`${this.urlAlerts}/history?` +
                `searchName=${searchQuery}&` +
                `pageSize=${pageSize}&` +
                `page=${page}`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.notificationsStore.update({ 
                            alertsHistory: response.data.data,
                            alertsHistoryTotalCount: response.data.totalCount,
                        });
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public deleteAlert(id: number): Observable<any> {
        const url = `${this.urlAlerts}/resolve/${id}`;
        const config = { headers: authHeader() };


        return new Observable<any>(observer => {
            Axios.delete<any>(url, config).pipe()
                .subscribe(
                    response => {
                        this.notificationsStore.deleteAlert(id)
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }
}

export const notificationsService = new NotificationsService(notificationsStore, notificationsQuery);
