import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: colors.bg,
            [theme.breakpoints.down("xs")]: {
                height: "fit-content",
            },
        },
        content: {
            padding: 0,
        },
        title: {
            fontSize: 13,
            fontWeight: 500,
            paddingTop: 12,
            color: colors.black
        },
        itemContainer: {
            background: colors.white,
            minHeight: 96,
            borderRadius: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
        },
        badge: {
            right: -8,
        }

    })
);

