import {
    Box,
    createStyles,
    Dialog,
    DialogContent,
    IconButton,
    Theme, Typography, useMediaQuery, useTheme, withStyles,
    WithStyles
} from "@material-ui/core";
import React from "react";
import { WildHealthButton } from "../../../../common/components/wildHealthButton/WildHealthButton";
import { colors } from "../../../../common/constants/colors";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { useFacade } from "./healthGoalDialogComponent.hooks";
import { GoalCategory, GoalModel } from "../../../../notes/models/notes.models";
import StarIcon from "@material-ui/icons/Star";
import { healthGoalIcons } from "../constants/healthGoalIcons";
import { goalCategoryNames } from "../../../../notes/constants/goalCategoryNames";
import { useStyles } from "./healthGoalDialogComponent.styles";
import moment from "moment";
import { createTextWithLinks } from "../../../../common/helpers/create-text-with-links";
import { shortcutView } from "../../../../common/helpers/view-text-with-link";
import { healthGoalFullImages } from "../constants/healthGoalImages";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2)
        },
        title: {
            color: colors.systemBlack,
            textTransform: 'none',
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    }
    );

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose?: () => void;
}

export const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;

    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6" className={classes.title}>{children}</Typography>
            {
                onClose &&
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            }
        </MuiDialogTitle>
    );
});

export const HealthGoalDialogComponent: React.FC = () => {
    const [
        {
            isOpen,
            goal
        },
        handleClose
    ] = useFacade();

    const theme = useTheme();

    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const classes = useStyles();

    const getTitle = (goal: GoalModel) => {
        if (!goal) {
            return <span>Loading...</span>
        }

        if (goal.isCompleted) {
            return <span>Past Goal</span>
        }

        if (goal.isTopGoal) {
            return (
                <Box display="flex">
                    <span>Top Goal</span>
                    <Box mt={0.5} ml={1}>
                        <StarIcon />
                    </Box>
                </Box>
            )
        }

        return <span>Goal</span>
    }

    const renderCategory = (category: GoalCategory) => {
        return (
            <Box className={classes.category} display="flex" alignItems='center'>
                <Box>
                    {healthGoalIcons.get(category)}
                </Box>
                <Box ml={0.5} mt={0.5}>
                    {goalCategoryNames.get(category)}
                </Box>
            </Box>
        )
    }

    if (!goal) {
        return <></>
    }

    return (
        <Dialog
            fullScreen={isSmallScreen}
            scroll='paper'
            fullWidth
            maxWidth='md'
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <Box mx={3}>
                <DialogTitle id="dialog-title" onClose={handleClose}>{getTitle(goal)}</DialogTitle>
            </Box>

            <DialogContent>
                <Box px={2}>
                    <Box className={classes.imageContainer} style={{
                        backgroundImage: `url(${healthGoalFullImages.get(goal.category)})`
                    }}>
                    </Box>

                    <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                        <Box>
                            {
                                renderCategory(goal.category)
                            }
                        </Box>
                        <Box display='flex' className={classes.date} width='100%' px={2} alignItems="center" justifyContent="space-between">
                            {
                                goal.date &&
                                <Box>
                                    {moment(goal.date).format('MMM YYYY')}
                                </Box>
                            }
                            <Box>
                                {
                                    goal.completionDate &&
                                    <Box>Duration: {moment(goal.completionDate).startOf('day').from(goal.date)}</Box>
                                }

                            </Box>
                        </Box>
                    </Box>

                    <Box mt={2} className={classes.title}>
                        {goal.name}
                    </Box>

                    <Box mt={2}>
                        {
                            goal.interventions.map((intervention, index) => {
                                return (
                                    <Box pb={2} key={index} className={classes.intervention}>
                                        <span>
                                            {(index + 1) + '. '}
                                        </span>
                                        <span dangerouslySetInnerHTML={{ __html: `${createTextWithLinks(shortcutView(intervention.name))}` }} />
                                    </Box>
                                )
                            })
                        }
                    </Box>
                </Box>
            </DialogContent>

            <Box display="flex" justifyContent="flex-end" px={5} py={3}>
                <Box>
                    <WildHealthButton id="cancel-sign-off" size="large" color="tertiary" onClick={() => handleClose()}>
                        Close
                    </WildHealthButton>
                </Box>
            </Box>
        </Dialog>
    );
}