import { Button, Collapse, List, ListItem, ListItemText, Popover, Box } from "@material-ui/core";
import ExitToAppTwoToneIcon from '@material-ui/icons/ExitToAppTwoTone';
import MoreIcon from '@material-ui/icons/MoreVert';
import clsx from 'clsx';
import React, { useState } from "react";
import { useHistory } from "react-router";
import { UserType } from "../../../modules/auth/models/auth.enums";
import { NotificationsButtonComponent } from "../../../modules/notifications/components/notificationComponent/NotificationsButtonComponent";
import { UserOptionsButtonComponent } from "../../../modules/userOptions/components/userOptionsButton/UserOptionsButtonComponent";
import { accountService } from "../../../services/account.service";
import { navigationService } from "../../../services/navigation.service";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { WildHealthLinkButton } from "../../../modules/common/components/WildHealthLinkButton";
import { authQuery } from "../../../modules/auth/stores/auth";
import { MenuItem } from "../../../models/menu.models";
import { useStyles } from "./commonHeaderComponent.styles";
import { logoService } from "../../../services/logo.service";
import { EmployerProductModel } from "../../../modules/payment/models/employerProduct.model";
import { paymentPlansQuery } from "../../../modules/payment/stores/paymentPlansStore";
import { RoutesConstants } from "../../../constants/route.constants";

const mobileMenuPopoverProps: any = {
    id: "mobileMenuPopover",
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'right',
    }
};

const childrenMenuItemsPopoverProps: any = {
    id: "mobileMenuPopover",
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
    }
};

const menuItems: MenuItem[] = [];

interface MenuItemState {
    key: string;
    isOpen: boolean;
}

interface CommonHeaderComponentState extends MenuItemState {
    anchorEl: HTMLButtonElement;
    isMobileMenuOpen: boolean;
    MenuItemsStates: MenuItemState[];
    employerProduct: EmployerProductModel;
}

export function useFacade(userType: UserType, employerKey: boolean): [
    JSX.Element,
    JSX.Element,
    JSX.Element,
    JSX.Element[],
    JSX.Element,
    JSX.Element[],
] {
    const employerProduct = paymentPlansQuery.getEmployerProduct();

    const [state, setState] = useState({
        isMobileMenuOpen: false,
        anchorEl: null,
        MenuItemsStates: menuItems.filter(i => i.children).map(i => { return { key: `MainMenuItem-${menuItems.indexOf(i)}`, isOpen: false } as MenuItemState }),
        employerProduct: employerProduct
    } as CommonHeaderComponentState);

    const history = useHistory();

    const classes = useStyles({});

    const handleMobileMenuToggle = (event?: React.MouseEvent<HTMLButtonElement>) => {
        const anchorEl = event && event.currentTarget ? event.currentTarget : null;
        setState(state => ({ ...state, isMobileMenuOpen: !state.isMobileMenuOpen, anchorEl: anchorEl }));
    }

    const handleHeaderMenuItemToggle = (event?: React.MouseEvent<HTMLButtonElement>) => {
        const anchorEl = event && event.currentTarget ? event.currentTarget : null;
        let element: MenuItemState;
        if (anchorEl)
            element = state.MenuItemsStates.find(i => i.key === event.currentTarget.id);
        else
            element = state.MenuItemsStates.find(i => i.isOpen);

        if (element) {
            element.isOpen = !element.isOpen;
            setState(({ ...state, MenuItemsStates: state.MenuItemsStates, anchorEl: anchorEl }));
        }
    }

    const handleMobileMenuItemToggle = (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const toOpen = !!(event && event.currentTarget);
        let element = state.MenuItemsStates.find(i => toOpen ? i.key === event.currentTarget.id : i.isOpen);

        if (element) {
            element.isOpen = !element.isOpen;
            setState(({ ...state, MenuItemsStates: state.MenuItemsStates }));
        }
    }

    const Title = (
        authQuery.isLoggedIn() ?
            <WildHealthLinkButton
                onClick={() => navigationService.toDashboard(history)}
                content={<Box className={classes.logoSection}>
                    <img className={classes.redesignLogo} src={logoService.getLogo().primary} alt="img" />
                    {employerKey && employerProduct?.bannerCdnUrl && <><Box className={classes.plusStyle}>+</Box><img className={classes.employerLogo} src={RoutesConstants.whBlobCdnBaseLink + employerProduct?.bannerCdnUrl} alt="img" /></>}
                </Box>}
                id="header-link-dashboard"
            /> :
            <WildHealthLinkButton
                onClick={() => navigationService.toWildHealth()}
                content={<Box className={classes.logoSection}>
                    <img className={classes.redesignLogo} src={logoService.getLogo().primary} alt="img" />
                    {employerKey && employerProduct?.bannerCdnUrl &&<><Box className={classes.plusStyle}>+</Box><img className={classes.employerLogo} src={RoutesConstants.whBlobCdnBaseLink + employerProduct?.bannerCdnUrl} alt="img" /></>}
                </Box>}
                id="header-link-wild-health"
            />
    );

    const handleLogout = () => {
        accountService.logout()
        navigationService.postLogoutRedirect(history)
    }

    const renderOptionsBtn = () => {
        const isUnspecifiedUser = authQuery.isUnspecifiedUser();
        return (userType === UserType.Employee || isUnspecifiedUser) ?
            <Button
                id="header-logout-btn"
                key='logout-btn'
                color="inherit"
                onClick={() => handleLogout()}
            >
                {isUnspecifiedUser ? <Box className={classes.logoutText}>Logout</Box> : <ExitToAppTwoToneIcon />}
            </Button>
            : <UserOptionsButtonComponent />
    }

    const UserAccountButtons = (
        <>
            {authQuery.isRegistrationCompleted() && <NotificationsButtonComponent />}
            {renderOptionsBtn()}
        </>

    );

    const menuButtons = [
    ]

    const menus = menuItems.concat(menuButtons);

    const MobileMenuButton = (
        menus.length ?
            <Button
                id="mobile-menu-toggle"
                key={mobileMenuPopoverProps.id}
                color="inherit"
                className={classes.mobileMenuButton}
                aria-describedby={mobileMenuPopoverProps.id}
                onClick={(event) => handleMobileMenuToggle(event)}
            >
                <MoreIcon />
            </Button> : <></>
    );

    const handleRenderMobileChildrenMenuItems = (key: string, isOpen: boolean, items: MenuItem[]) => {
        return (
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {
                        items.map((item, index) =>
                            <ListItem
                                button
                                id={`mobile-menu-${item.title}${index}`}
                                key={`${key}${index}`}
                                onClick={() => item.action(history)}
                                className={classes.mobileChildrenMenuItem}
                            >
                                <ListItemText primary={item.title} />
                            </ListItem>
                        )
                    }
                </List>
            </Collapse>
        )
    }

    const MobileMenu = (
        <Popover
            id={mobileMenuPopoverProps.id}
            anchorEl={state.anchorEl}
            open={state.isMobileMenuOpen}
            className={classes.mobileMenu}
            onClose={() => handleMobileMenuToggle()}
            anchorOrigin={mobileMenuPopoverProps.anchorOrigin}
            transformOrigin={mobileMenuPopoverProps.transformOrigin}
        >
            <List>
                {
                    menus.map((item, index) => {
                        const key = `MainMenuItem-${index}`;
                        let collapse: JSX.Element | null;
                        let isOpen: boolean = false;
                        let action: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;

                        if (item.children) {
                            action = handleMobileMenuItemToggle;
                            const element = state.MenuItemsStates.find(i => i.key === key);
                            if (element) {
                                isOpen = element.isOpen;
                                collapse = handleRenderMobileChildrenMenuItems(key, isOpen, item.children);
                            }
                        }
                        else action = () => item.action(history);

                        return (
                            <>
                                <ListItem
                                    button
                                    id={key}
                                    key={key}
                                    onClick={action}
                                >
                                    <ListItemText>
                                        <span className={classes.mobileMenuItem}>{item.title}</span>
                                    </ListItemText>
                                    {item.children ? isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon /> : null}
                                </ListItem>
                                {item.children && collapse}
                            </>
                        );
                    })
                }
            </List>
        </Popover>
    );

    const handleRenderChildrenMenuItems = (key: string, isOpen: boolean, items: MenuItem[]) => {
        return (
            <Popover
                id={key}
                key={key}
                open={isOpen}
                anchorEl={state.anchorEl}
                className={classes.sectionDesktop}
                onClose={() => handleHeaderMenuItemToggle()}
                anchorOrigin={childrenMenuItemsPopoverProps.anchorOrigin}
                transformOrigin={childrenMenuItemsPopoverProps.transformOrigin}
            >
                <List>
                    {items.map((item, index) =>
                        <ListItem
                            button
                            id={`children-main-menu-${item.title}`}
                            key={index}
                            onClick={() => item.action(history)}
                        >
                            <ListItemText primary={item.title} />
                        </ListItem>
                    )}
                </List>
            </Popover>
        );
    }

    let DesktopChildrenMenus: JSX.Element[] = [];

    const DesktopMenu = menuItems.map((item, index) => {
        const key = `MainMenuItem-${index}`;
        let startIcon: any;
        let action: (event?: React.MouseEvent<HTMLButtonElement>) => void;

        if (item.children) {
            action = handleHeaderMenuItemToggle;
            const element = state.MenuItemsStates.find(i => i.key === key);
            if (element) {
                startIcon = element.isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />;
                DesktopChildrenMenus.push(handleRenderChildrenMenuItems(key, element.isOpen, item.children));
            }
            else startIcon = null;
        }
        else action = () => item.action(history);

        return (
            <Button
                key={key}
                id={`main-menu-${item.title}`}
                aria-describedby={key}
                className={clsx(classes.menuItem, classes.sectionDesktop)}
                onClick={action}
                startIcon={startIcon}
            >
                {item.title}
            </Button>
        );
    }
    );

    return [Title, MobileMenuButton, MobileMenu, DesktopMenu, UserAccountButtons, DesktopChildrenMenus];
}