import React from 'react';
import {
    Box,
    Popover,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    IconButton,
    ListItemIcon,
    Typography,
    Dialog,
    DialogContent,
    TextField,
    FormControl,
    FormHelperText,
    Select,
    MenuItem
  } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import { useFacade } from './conversationTemplatesComponent.hooks';
import { useStyles } from './conversationTemplatesComponent.styles';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { WildHealthContainer } from '../../../common/components/wildHealthContainer/WildHealthContainer';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { WildHealthMenuItem } from '../../../common/components/wildHealthMenu/WildHealthMenu';
import { FeatureComponent } from "../../../common/components/featureFlags/FeatureComponent";
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { conversationTypeNames, ConversationType } from '../../models/conversation.models';



export const ConversationTemplatesComponent: React.FC = () => {
    const [
        {
            isLoading,
            isSubmitting,
            isOpen,
            menuAnchorEl,
            conversationTemplates,
            createTemplate,
            selectedId,
            errors,
            sortingColumns,
            sortingSource,
        },
        handleSorting,
        handleToggleTemplateModify,
        handleToggleTemplateActions,
        handleSaveTemplate,
        handleDeleteTemplate,
        handleChanges
    ] = useFacade();

    const classes = useStyles();

    const renderTable = () => {
        if (isLoading) {
            return <WildHealthLinearProgress />
        }

        if (!isLoading && (!conversationTemplates || !conversationTemplates.length)) {
            return <WildHealthPlaceHolder message="No Conversation Templates found" />
        }

        return (
            <Box p={2}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {
                                    sortingColumns.map((item, index) =>
                                        <WildHealthTableCell key={index}>
                                            {item.source
                                                ? <TableSortLabel
                                                    id={`sorting-templates-${item.title}`}
                                                    active={sortingSource === item.source}
                                                    direction={sortingSource === item.source ? item.direction : 'asc'}
                                                    onClick={() => handleSorting(item.source, item.direction)}
                                                >
                                                    {item.title}
                                                </TableSortLabel>
                                                : item.title
                                            }
                                        </WildHealthTableCell>
                                    )
                                }
                                
                                <WildHealthTableCell align="right">
                                    Action
                                </WildHealthTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {conversationTemplates.map(template => (
                                <WildHealthTableRow key={template.id}>
                                    <WildHealthTableCell align="left">
                                        <Box className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-black">
                                            {template.name}
                                        </Box>
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="left">
                                        <Box className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-black">
                                            {template.description}
                                        </Box>
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="left">
                                        <Box className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-black">
                                            {template.text}
                                        </Box>
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="left">
                                        <Box className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-black">
                                            {template.order}
                                        </Box>
                                    </WildHealthTableCell>
                                    <WildHealthTableCell align="left">
                                        <Box className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-black">
                                            {conversationTypeNames.get(template.type)}
                                        </Box>
                                    </WildHealthTableCell>
                                    <WildHealthTableCell width="10%" align="right">
                                        <IconButton
                                            id={`edit-template-${template.id}`}
                                            disabled={isSubmitting || isLoading}
                                            onClick={(e) => handleToggleTemplateActions(template.id, e.currentTarget)}
                                        >
                                            <MoreHorizIcon />
                                        </IconButton>
                                    </WildHealthTableCell>
                                </WildHealthTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Popover
                    id="templateActionPopover"
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    onClose={() => handleToggleTemplateActions(0, null)}
                >
                    <WildHealthMenuItem
                        id="template-edit-toggle-btn"
                        onClick={() => handleToggleTemplateModify(selectedId)}
                    >
                        <ListItemIcon>
                            <EditIcon />
                        </ListItemIcon>
                        <Typography variant="inherit" noWrap>
                            Edit
                        </Typography>
                    </WildHealthMenuItem>
                    <WildHealthMenuItem
                        id="template-delete-button"
                        onClick={handleDeleteTemplate}
                    >
                        <ListItemIcon>
                            <DeleteIcon />
                        </ListItemIcon>
                        <Typography variant="inherit" noWrap>
                            Delete
                        </Typography>
                    </WildHealthMenuItem>
                </Popover>
            </Box>
        )
    }

    return (
        <Box py={2} px={5} width={1}>
            <Box my={2} display="flex" alignItems="center" justifyContent="space-between">
                <Box>
                    <FeatureComponent featureFlag={FeatureFlag.Navigation}>
                        <Box className="wh-tw-font-semibold wh-tw-text-2xl wh-tw-black">{PagesNamesService.conversationTemplates}</Box>
                    </FeatureComponent>
                </Box>
                <Box>
                    <WildHealthButton
                        id="create-template-button"
                        disabled={isLoading || isSubmitting}
                        onClick={() => handleToggleTemplateModify(0)}
                        fullWidth
                        style={{ height: 48 }}
                    >
                        <Box className="wh-tw-text-base wh-tw-font-medium">New Template</Box>
                    </WildHealthButton>
                </Box>
            </Box>

            <WildHealthContainer margin={0} minHeight={'100%'}>
                {renderTable()}
            </WildHealthContainer>
            <Dialog
                onClose={() => handleToggleTemplateModify(-1)}
                className={classes.root}
                open={isOpen}
            >
                <DialogContent className={classes.dialogContent}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box>
                            <span className="wh-tw-text-2xl wh-tw-black wh-tw-font-medium">{selectedId > 0 ? 'Edit Template' : 'New Template'}</span>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <IconButton
                                id="modify-template-dialog-close"
                                className={classes.dialogTitleCloseAction}
                                onClick={() => handleToggleTemplateModify(-1)}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box mt={5}>
                        <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Name <span className="wh-tw-text-error">*</span></Box>
                        <TextField
                            id={`name`}
                            fullWidth
                            required
                            size="small"
                            InputProps={{ className: 'input', classes }}
                            variant="outlined"
                            value={createTemplate.name}
                            error={!!errors['name']}
                            helperText={errors['name']}
                            disabled={!isOpen || isSubmitting}
                            onChange={(event) => handleChanges(event.target.id, event.target.value)}
                        />
                    </Box>
                    <Box mt={2}>
                        <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Description <span className="wh-tw-text-error">*</span></Box>
                        <TextField
                            id={`description`}
                            fullWidth
                            required
                            multiline
                            rows={4}
                            size="small"
                            InputProps={{ className: 'input', classes }}
                            variant="outlined"
                            value={createTemplate.description}
                            error={!!errors['description']}
                            helperText={errors['description']}
                            disabled={!isOpen || isSubmitting}
                            onChange={(event) => handleChanges(event.target.id, event.target.value)}
                        />
                    </Box>
                    <Box mt={2}>
                        <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Text <span className="wh-tw-text-error">*</span></Box>
                        <TextField
                            id={`text`}
                            fullWidth
                            required
                            size="small"
                            InputProps={{ className: 'input', classes }}
                            variant="outlined"
                            value={createTemplate.text}
                            error={!!errors['text']}
                            helperText={errors['text']}
                            disabled={!isOpen || isSubmitting}
                            onChange={(event) => handleChanges(event.target.id, event.target.value)}
                        />
                    </Box>
                    <Box mt={2}>
                        <FormControl
                            variant="outlined"
                            color='primary'
                            required
                            error={!!errors[`type`]}
                            size="small"
                            disabled={!isOpen || isSubmitting}
                            classes={{ root: classes.root }}
                            fullWidth>
                            <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Conversation Type <span className="wh-tw-text-error">*</span></Box>
                            <Select
                                style={{ background: '#FFF' }}
                                classes={{ root: classes.root }}
                                value={createTemplate.type}
                                error={!!errors[`type`]}
                                inputProps={{
                                    name: 'Conversation Type',
                                    id: 'type-label',
                                    classes,
                                }}
                                renderValue={() => {
                                    if (createTemplate.type === null) {
                                        return <Box className="wh-tw-text-mediumGray">Select</Box>;
                                    }

                                    return conversationTypeNames.get(createTemplate.type);
                                }}
                                displayEmpty
                                onChange={(v) => handleChanges("type", v.target.value)}
                            >
                                {/* <MenuItem value={ConversationType.GenericPlayground}>Generic Playground</MenuItem> */}
                                <MenuItem value={ConversationType.PatientPlayground}>Patient Playground</MenuItem>
                            </Select>
                            <FormHelperText>{errors[`type`]}</FormHelperText>
                        </FormControl>
                    </Box>
                    <Box mt={2}>
                        <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Order <span className="wh-tw-text-error">*</span></Box>
                        <TextField
                            id={`order`}
                            fullWidth
                            required
                            type="number"
                            size="small"
                            InputProps={{ className: 'input', classes }}
                            variant="outlined"
                            value={createTemplate.order}
                            error={!!errors['order']}
                            helperText={errors['order']}
                            disabled={!isOpen || isSubmitting}
                            onChange={(event) => handleChanges(event.target.id, event.target.value)}
                        />
                    </Box>
                    <Box mt={5} display="flex" justifyContent="flex-end" alignItems="center">
                        <Box mx={2}>
                            <WildHealthButton
                                id="modify-template-cancel"
                                onClick={() => handleToggleTemplateModify(-1)}
                                color="tertiary"
                                size='medium'
                                disabled={!isOpen || isSubmitting}
                            >
                                Cancel
                            </WildHealthButton>
                        </Box>
                        <Box>
                            <WildHealthButton
                                id="save-template-button"
                                size='medium'
                                onClick={handleSaveTemplate}
                                loading={isSubmitting}
                            >
                                Save
                            </WildHealthButton>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    );
}