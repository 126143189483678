import { Store, StoreConfig } from '@datorama/akita';
import { EmployerProductModel } from '../../models/employerProduct.model';

/**
 * Represents employer Products state
 */
export interface EmployerProductsState {
    employerProducts: EmployerProductModel[];
    selectedEmployer: EmployerProductModel | null;
}

/**
 * Creates initial state
 */
export function createInitialState(): EmployerProductsState {
    return {
        employerProducts: [],
        selectedEmployer: null
    };
}

/**
 * Provides employer Products state management
 */
@StoreConfig({ name: 'employerProducts', resettable: true })
export class EmployerProductsStore extends Store<EmployerProductsState> {
    constructor() {
        super(createInitialState());
    }

    public addEmployer(model: EmployerProductModel) {
        this.update({ employerProducts: [...this.getValue().employerProducts, model] });
    }

    public updateEmployer(employerProduct: EmployerProductModel) {
        const employerProducts = this.getValue().employerProducts;
        this.update({ employerProducts: employerProducts.map(x => x.key === employerProduct.key ? employerProduct : x) });
    }

    public removeEmployer(key: string) {
        this.update({ employerProducts: this.getValue().employerProducts.filter(i => i.key !== key) });
    }
}

export const employerProductsStore = new EmployerProductsStore();
