import { healthSummaryQuery, healthSummaryStore } from '../../stores'
import { useState } from 'react'

export interface BlockItemsComponentState {
    selectedKey: string
    editValue: string
    editMode: boolean
}

export function useFacade(): [
    BlockItemsComponentState,
    Function,
    Function,
    Function,
    Function,
    Function
] {
    const [state, setState] = useState({
        selectedKey: null,
        editMode: false,
        editValue: null
    } as BlockItemsComponentState)

    const handleEditMode = (editMode: boolean, value: string, selectedKey: string) => {
        setState({
            ...state,
            selectedKey: selectedKey,
            editMode: editMode,
            editValue: value,
        })
    }

    const setInitialState = () => {
        setState(state => ({
            ...state,
            selectedKey: null,
            editMode: false,
            editValue: null
        }))
    }

    const handleDelete = () => {
        healthSummaryStore.delete(state.selectedKey, true)

        setInitialState()
    }

    const handleBlur = () => {
        const dataItem = healthSummaryQuery.getDataItem(state.selectedKey)

        if (!dataItem) {
            return
        }

        dataItem.value = state.editValue

        healthSummaryStore.edit(dataItem)

        setInitialState()
    }

    const handleEditValue = (value: string) => {
        setState({
            ...state,
            editValue: value
        })
    }

    return [
        state,
        handleEditValue,
        handleEditMode,
        handleDelete,
        handleBlur,
        setInitialState,
    ]
}