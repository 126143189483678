import {useEffect, useState} from "react";
import {
    CoveragePriority,
    InsuranceModel,
    UpdateCoverageOnBehalfModel
} from "../../models/insurance.models";
import {insuranceService} from "../../services/insurance.service";
import {IErrorState} from "../../../common/validation/error-state";
import {Subscription} from "rxjs";
import {onEmit} from "../../../common/helpers/on-emit";
import {insuranceQuery} from "../../stores/insurance.query";
import {insuranceComponentValidator} from "../insuranceComponent/insuranceComponent.validator";
import {confirmService} from "../../../../services/confirm.service";

interface UpdateInsuranceModalComponentState extends IErrorState {
    isOpen: boolean;
    containPolicyHolder: boolean;
    insurances: InsuranceModel[];
    model: UpdateCoverageOnBehalfModel;
    frontImage: File;
    backImage: File;
    callback: Function;
}

export function useFacade(): [
    UpdateInsuranceModalComponentState,
    (field: string, value: string) => void,
    (isPrimary: boolean) => void,
    (frontImage: File) => void,
    (backImage: File) => void,
    () => void,
    () => void,
    () => void,
] {
    const [state, setState] = useState({
        isOpen: false,
        containPolicyHolder: false,
        insurances: [],
        callback: null,
        frontImage: null,
        backImage: null,
        errors: {},
        model: {
            id: 0,
            patientId: 0,
            insuranceId: 0,
            memberId: '',
            isPrimary: false,
            frontImage: null,
            backImage: null,
            policyHolder: null
        }
    } as UpdateInsuranceModalComponentState);

    const handleChanges = (field: string, value: string) => {
        const model = state.model;

        insuranceComponentValidator.validateAndSetState(state, setState, field, value);

        const fields = field.split('.');

        if (fields.length > 1) {
            const obj = model[fields[0]];
            obj[fields[1]] = value;
        } else {
            model[field] = value;
        }

        setState({...state, model: model});
    }

    const handleSetAsPrimary = (isPrimary: boolean) => {
        setState(state => ({
            ...state,
            model: {
                ...state.model,
                isPrimary: isPrimary
            }
        }));
    }

    const handleUploadFrontImage = (frontImage: File) => {
        setState(state => ({
            ...state,
            frontImage: frontImage
        }));
    }

    const handleUploadBackImage = (backImage: File) => {
        setState(state => ({
            ...state,
            backImage: backImage
        }));
    }

    const togglePolicyHolder = () => {
        setState(state => ({
            ...state,
            containPolicyHolder: !state.containPolicyHolder,
            model: {
                ...state.model,
                policyHolder: !state.containPolicyHolder
                    ? {
                        firstName: '',
                        lastName: '',
                        dateOfBirth: null,
                        relationship: ''
                    } : null
            }
        }));
    }

    const handleClose = () => {
        state.callback(null);
        close();
    }

    const handleSubmit = () => {
        confirmService.confirmInsurance().subscribe(
            () => {
                const model = state.model;
                model.frontImage = state.frontImage;
                model.backImage = state.backImage;
                state.callback(state.model);
                close();
            });
    }

    const close = () => {
        setState(state => ({
            ...state,
            isOpen: false,
            containPolicyHolder: false,
            callback: null,
            frontImage: null,
            backImage: null,
            errors: {},
            model: {
                id: 0,
                patientId: 0,
                insuranceId: 0,
                memberId: '',
                isPrimary: false,
                frontImage: null,
                backImage: null,
                policyHolder: null
            }
        }));
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<InsuranceModel[]>(insuranceQuery.insurances$, insurances => {
                setState(state => ({
                    ...state,
                    insurances: insurances,
                }))
            })
        ];

        subscriptions.push(insuranceService.onUpdateInsurance.subscribe((data) => {
            const patientId = data.data.patientId;
            const coverage = data.data.coverage;
            setState(state => ({
                ...state,
                isOpen: true,
                containPolicyHolder: false,
                callback: data.callback,
                errors: {},
                model: {
                    id: coverage.id,
                    patientId: patientId,
                    insuranceId: coverage.insurance.id,
                    memberId: coverage.memberId,
                    isPrimary: coverage.priority === CoveragePriority.Primary,
                    frontImage: null,
                    backImage: null,
                    policyHolder: coverage.policyHolder
                }
            }));
        }));

        return () => {
            subscriptions.map(it => it.unsubscribe())
        }
    }, [])

    return [
        state,
        handleChanges,
        handleSetAsPrimary,
        handleUploadFrontImage,
        handleUploadBackImage,
        togglePolicyHolder,
        handleClose,
        handleSubmit
    ];
}