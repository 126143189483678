import {RecommendationTypes} from "../../healthReport/models/healthReport.models";

export enum LogicalOperator
{
  And = 1,
  Or = 2
}

export const LogicalOperatorNames = {
  [LogicalOperator.And]: "And",
  [LogicalOperator.Or]: "Or"
};

export const RecommendationCategoriesNames = {
  [LogicalOperator.And]: "And",
  [LogicalOperator.Or]: "Or"
};

export enum RecommendationCategoryTag
{
  Unknown = 0,
  Nutrition = 10,
  Neurobehavioral = 20,
  Longevity = 30,
  Pgx = 40,
  Metabolic = 50,
  Cvd = 60,
  Mindfulness = 70,
  Autoimmune = 80,
  Dementia = 90,
  Methylation = 100,
  Hormones = 110,
  Inflammation = 120,
  Sleep = 130,
  MacroNutrient = 140,
  VitaminsAndMicronutrients = 150,
  KryptoniteFoods = 160,
  SuperFoods = 170,
  Microbiome = 180,
  ExerciseAndRecovery = 190,
  Cardiovascular = 200,
  Cancer = 210,
  RecommendedMacros = 220,
  Celiac = 230,
  AddOnReport = 240
}

export const RecommendationCategoryTagNames = {
  [RecommendationCategoryTag.Unknown]: "Unknown",
  [RecommendationCategoryTag.Nutrition]: "Nutrition",
  [RecommendationCategoryTag.Neurobehavioral]: "Neurobehavioral",
  [RecommendationCategoryTag.Longevity]: "Longevity",
  [RecommendationCategoryTag.Pgx]: "Pgx",
  [RecommendationCategoryTag.Metabolic]: "Metabolic",
  [RecommendationCategoryTag.Cvd]: "Cvd",
  [RecommendationCategoryTag.Mindfulness]: "Mindfulness",
  [RecommendationCategoryTag.Autoimmune]: "Autoimmune",
  [RecommendationCategoryTag.Dementia]: "Dementia",
  [RecommendationCategoryTag.Methylation]: "Methylation",
  [RecommendationCategoryTag.Hormones]: "Hormones",
  [RecommendationCategoryTag.Inflammation]: "Inflammation",
  [RecommendationCategoryTag.Sleep]: "Sleep",
  [RecommendationCategoryTag.MacroNutrient]: "MacroNutrient",
  [RecommendationCategoryTag.VitaminsAndMicronutrients]: "Vitamins/Micronutrients",
  [RecommendationCategoryTag.KryptoniteFoods]: "Kryptonite Foods",
  [RecommendationCategoryTag.SuperFoods]: "SuperFoods",
  [RecommendationCategoryTag.Microbiome]: "Microbiome",
  [RecommendationCategoryTag.ExerciseAndRecovery]: "Exercise/Recovery",
  [RecommendationCategoryTag.Cardiovascular]: "Cardiovascular",
  [RecommendationCategoryTag.Cancer]: "Cancer",
  [RecommendationCategoryTag.RecommendedMacros]: "Recommended Macros",
  [RecommendationCategoryTag.Celiac]: "Celiac",
  [RecommendationCategoryTag.AddOnReport]: "Add On Report"
};

export enum RecommendationClassification
{
  Medical = 1,
  NonMedical = 2
}

export const RecommendationClassificationNames = {
  [RecommendationClassification.Medical]: "Medical",
  [RecommendationClassification.NonMedical]: "NonMedical"
};

export enum VerificationMethod
{
  Automatic = 1,
  AppointmentCompletion = 2,
  ManualReviewProvider = 3,
  ManualReviewHealthCoach = 4
}

export const VerificationMethodNames = {
  [VerificationMethod.Automatic]: "Automatic",
  [VerificationMethod.AppointmentCompletion]: "AppointmentCompletion",
  [VerificationMethod.ManualReviewProvider]: "ManualReviewProvider",
  [VerificationMethod.ManualReviewHealthCoach]: "ManualReviewHealthCoach"
};

export interface RecommendationModel extends CreateRecommendationModel {
  id: number;
  recommendationTriggers: RecommendationTriggerModel[];
}

export interface CreateRecommendationModel {
  content: string;
  order: number;
  tags: RecommendationCategoryTag[];
  classification: RecommendationClassification;
  verificationMethods: VerificationMethod[];
}

export interface RecommendationTriggerModel extends CreateRecommendationTriggerModel {
  id: number;
}

export interface CreateRecommendationTriggerModel {
  recommendationId: number;
  confidence: number;
  logicalOperator: LogicalOperator;
  type: RecommendationTypes;
  recommendationTriggerComponents: RecommendationTriggerComponentModel[];
}

export interface RecommendationTriggerComponentModel extends CreateRecommendationTriggerComponentModel {
  id: number;
}

export interface CreateRecommendationTriggerComponentModel {
  metricId: number;
  classificationTypeOptionId: number;
}

export enum MetricSource
{
  Labs = 1,
  DNA = 2,
  Biometrics = 3,
  Questionnaire = 4,
  Calculator = 5,
  Microbiome = 6,
  AddOnReport = 7
}

export const MetricSourceNames = {
  [MetricSource.Labs]: "Labs",
  [MetricSource.DNA]: "DNA",
  [MetricSource.Biometrics]: "Biometrics",
  [MetricSource.Questionnaire]: "Questionnaire",
  [MetricSource.Calculator]: "Calculator",
  [MetricSource.Microbiome]: "Microbiome"
};

export enum MetricType
{
  Raw = 1, 
  Calculated = 2
}

export const MetricTypeNames = {
  [MetricType.Raw]: "Raw",
  [MetricType.Calculated]: "Calculated"
};

export enum MetricValueType
{
  Decimal = 1,
  Integer = 2,
  Percent = 3,
  String = 4
}

export const MetricValueTypeNames = {
  [MetricValueType.Decimal]: "Decimal",
  [MetricValueType.Integer]: "Integer",
  [MetricValueType.Percent]: "Percent",
  [MetricValueType.String]: "String"
};

export interface MetricModel {
  id: number;
  label: string;
  identifier: string;
  source: MetricSource;
  type: MetricType;
  metricValueType: MetricValueType;
  classificationType: ClassificationTypeModel;
}

export interface ClassificationTypeModel {
  name: string;
  options: ClassificationTypeOptionModel[];
}

export interface ClassificationTypeOptionModel {
  id: number;
  name: string;
}
