import React from "react"
import { Box } from '@material-ui/core';
import { WildHealthPlaceHolder } from '../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder';

export const EmptyInternalNotesComponent: React.FC = () => {
    return (
        <Box>
            <Box>
                <WildHealthPlaceHolder message="No internal notes yet." />
            </Box>
        </Box>
    );
}
