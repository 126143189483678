import {
    phoneNumberValidationRule
} from "../../../common/validation/general-validation-rules";
import { GeneralValidator } from "../../../common/validation/general-validator";

export const sendProfileVerificationComponentValidator = new GeneralValidator({
    'newPhoneNumber': [
        phoneNumberValidationRule,
    ]
});
