import React, {FC, Fragment, useState} from 'react';
import { Box, Collapse, ListItem, ListItemText, TextField } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { WildHealthButton } from "../../../../common/components/wildHealthButton/WildHealthButton";
import { useStyles } from "../manageApiResponseComponent.styles";
import commonUseStyles from "../../../../common/styles/common.styles";
import {ToggleButton} from "@material-ui/lab";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

type EndpointAdminToolProps = {
    id: string
    params: any
    handleChanges: (field: string, value: any) => void,
    handleOpenToggle: () => void
    handleClick: (any) => void
    title: string
    errors: any
    isOpen: boolean
    isLoading: boolean
}


const LoginOnBehalfOfUserComponent: FC<EndpointAdminToolProps> = (props): JSX.Element => {
    const {id, isLoading, title, errors, params, handleOpenToggle, handleClick, handleChanges, isOpen} = props;

    const classes = useStyles();
    const commonClasses = commonUseStyles();
    const [mode,setMode] = useState('user');

    const handleToggle=(event: React.MouseEvent<HTMLElement>, newMode: string | null) => {
        setMode(newMode)
        if(newMode === 'user')
        {
            params.patientId = null;
            params.employeeId = null;
        } else if(newMode === 'patient') {
            params.userId = null;
            params.employeeId = null;
        } else {
            params.userId = null;
            params.patientId = null;
        }
    }

    return (
        <Fragment>
            <ListItem id={id} className={classes.divider} button onClick={handleOpenToggle}>
                <ListItemText primary={<span className="text-medium">{title}</span>}/>
                {isOpen ? <ExpandLess className={classes.activeIcon}/> : <ExpandMore/>}
            </ListItem>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <Box p={2}>
                    <ToggleButtonGroup
                        value={mode}
                        exclusive
                        onChange={handleToggle}
                    >
                        <ToggleButton value="user">
                            User Id
                        </ToggleButton>
                        <ToggleButton value="patient">
                            Patient id
                        </ToggleButton>
                        <ToggleButton value="employee">
                            Employee id
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <Box mb={2}>
                      <Box className={commonClasses.label} mb={1}>UserId *</Box>
                      <TextField
                        fullWidth
                        size="small"
                        required
                        type="number"
                        InputProps={{ className: 'input', classes }}
                        id="userId"
                        placeholder="UserId"
                        value={params.userId}
                        variant="outlined"
                        disabled={mode !== 'user'}
                        error={!!errors['userId']}
                        helperText={errors['userId']}
                        onChange={(v) => handleChanges(v.target.id, v.target.value)}
                      />
                    </Box>
                    <Box mb={2} >
                        <Box className={commonClasses.label} mb={1}>PatientId *</Box>
                        <TextField
                            fullWidth
                            size="small"
                            required
                            type="number"
                            InputProps={{ className: 'input', classes }}
                            id="patientId"
                            placeholder="PatientId"
                            value={params.patientId}
                            variant="outlined"
                            disabled={mode !== 'patient'}
                            error={!!errors['patientId']}
                            helperText={errors['patientId']}
                            onChange={(v) => handleChanges(v.target.id, v.target.value)}
                        />
                    </Box>
                    <Box mb={2} >
                        <Box className={commonClasses.label} mb={1}>EmplyeeId *</Box>
                        <TextField
                            fullWidth
                            size="small"
                            required
                            type="number"
                            InputProps={{ className: 'input', classes }}
                            id="employeeId"
                            placeholder="EmployeeId"
                            value={params.employeeId}
                            variant="outlined"
                            disabled={mode !== 'employee'}
                            error={!!errors['employeeId']}
                            helperText={errors['employeeId']}
                            onChange={(v) => handleChanges(v.target.id, v.target.value)}
                        />
                    </Box>
                    <WildHealthButton
                        fullWidth
                        id="login-on-behalf-of-user-execute"
                        loading={isLoading}
                        onClick={handleClick}
                    >
                        Execute
                    </WildHealthButton>
                </Box>
            </Collapse>
        </Fragment>
    )

}

export default LoginOnBehalfOfUserComponent;