import { Box, useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { ExercisePlanScheduleComponent, ExercisePlanScheduleMobileComponent } from "../components/exercisePlanScheduleComponent/ExercisePlanScheduleComponent";
import { HealthReportSections, HealthReportMode } from "../models/healthReport.models";
import { HealthReportBasePage, HealthReportMobileBasePage } from "./HealthReportBasePage";
import { HealthReportPageProps } from "./healthReportPageProps";

export const HealthReportExerciseAndRecovery3Page: React.FC<HealthReportPageProps> = (props: HealthReportPageProps) => {
    const {
        pageNumber,
        report,
        practice
    } = props;

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        props.mode !== HealthReportMode.PrintMode && isSmallScreen ? (
            <HealthReportMobileBasePage section={HealthReportSections.ExerciseAndRecovery} pageNumber={pageNumber} practice={practice}>
                <Box>
                    <ExercisePlanScheduleMobileComponent plan={report.exerciseAndRecoveryReport.exercisePlan} />
                </Box>
            </HealthReportMobileBasePage>
        ) : (
            <HealthReportBasePage section={HealthReportSections.ExerciseAndRecovery} pageNumber={pageNumber} practice={practice}>
                <Box px={10} pt={5}>
                    <ExercisePlanScheduleComponent plan={report.exerciseAndRecoveryReport.exercisePlan} />
                </Box>
            </HealthReportBasePage>
        )
    )
}
