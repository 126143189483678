import {Gender} from "../../common/models/user.models";

export enum QuestionnairePages {
    General,
    Medical,
    Nutrition,
    Exercise,
    CardioRespiratory,
    Sleep,
    Stress
}

export enum QuestionnaireType {
    Initial = 1,
    HealthLog = 2,
    HealthForms = 3,
    FollowUpCallForms = 4
}

export enum QuestionnaireSubType
{
    None = 0,
    GoalsIncomplete = 1,
    MedicalHistoryIncomplete = 2,
    DetailedHistoryIncomplete = 3
}

export enum QuestionnaireSubject {
    DemographicsBiometrics = 'Demographics & Biometrics',
    Intention = 'Intention',
    Goals = 'Goals',
    Health = 'Health',
    Happiness = 'Happiness',
    Performance = 'Performance',

    MedicalHistory = 'Medical History',
    Lifestyle = 'Lifestyle',

    SurgicalHistory = 'Surgical History',
    SocialHistory = 'Social History',
    WomensHealthHistory = "Women's Health History",
    FamilyHistory = 'Family History',
    Screening = 'Screening',
    Vaccines = 'Vaccines',
    Review = 'Review of Systems',
}

/**
 * Represents Questionnaire
 */
export interface QuestionnaireModel extends QuestionnaireShortModel {
    questions: QuestionModel[];
}

export interface QuestionnaireShortModel {
    id: number;
    name: string;
    description: string;
    type: QuestionnaireType;
    subType: QuestionnaireSubType;
    appointmentId?: number;
}

export interface HealthFormModel extends QuestionnaireModel {
    resultId: number;
    questionnaireStatusType: QuestionnaireStatusType;
}

export interface BannerModel {
    message: string;
    type: QuestionnaireSubType;
}

export enum QuestionnaireStatusType
{
    None = 0,
    New = 1,
    InCompleted = 2,
    Completed = 3
}

export interface QuestionnaireSection {
    name?: string;
    note?: string;
    questions: Array<QuestionModel>;
}

export interface AnswerModel {
    key: string,
    value: string;
}

/**
 * Represents Questionnaire Result
 */
export interface QuestionnaireResultModel {
    id: number;
    sequenceNumber: number | null;
    submittedAt: Date | null;
    createdAt: Date;
    modifiedAt: Date | null;
    questionnaire: QuestionnaireModel;
    answers: AnswerModel[];
    type?: QuestionnaireType;
    subType?: QuestionnaireSubType;
    appointmentId?: number | null;
}

export interface SaveQuestionnaireResultModel {
    questionnaireResultId: number;
    answers: AnswerModel[];
    patientId?: number;
}

/**
 * Represents question type
 */
export enum QuestionType {
    CheckMany = 1,
    CheckOne = 5,
    SelectMany = 10,
    SelectOne = 15,
    TextInput = 20,
    NumericInput = 25,
    TimeInput = 30,
    Rate =  35,
    FillOutForm = 40,
    DateInput = 45
}

/**
 * Represents question group subject from server
 */
 export enum GroupSubjectFromServer {
    SetYourExerciseGoals = 'Set Your Exercise Goals',
    SetYourNutritionGoals = 'Set Your Nutrition Goals',
    SetYourSleepGoals = 'Set Your Sleep Goals',
    SetYourMindfulnessGoals = 'Set Your Mindfulness Goals',
    SetYourConnectionGoals = 'Set Your Connection Goals',
}

/**
* Represents question group title 
*/
export enum GroupSubjectTitle {
   SetYourExerciseGoals = 'Exercise Goals',
   SetYourNutritionGoals = 'Nutrition Goals',
   SetYourSleepGoals = 'Sleep Goals',
   SetYourMindfulnessGoals = 'Mindfulness Goals',
   SetYourConnectionGoals = 'Connection Goals',
}

/**
 * Represents question
 */
export interface QuestionModel {
    name: string;
    questionSubject?: string;
    displayName: string;
    description: string;
    detail?: string;
    info?: string;
    hint?: string;
    type: QuestionType;
    allowOther?: boolean;
    min?: number;
    max?: number;
    dimension?: string;
    default?: string;
    variants?: string[];
    optional?: boolean;
    childrenNames: string[];
    parentAnswers: string[];
    gender: Gender;
}

/**
 * Represents answer for question with type 'CheckMany'
 */
export interface CheckAnswer {
    v: string[];
    o: string[];
}

export function emptyCheckAnswer(): CheckAnswer {
    return {
        v: [],
        o: []
    }
}

export class BloodPressure {
    public static top = "120";
    public static bottom = "80";
}


/**
 * Represents defined initial question names
 */
export class InitialQuestionNames {
    static readonly MEDICAL_CONDITIONS: string = "MEDICAL_CONDITIONS";
    static readonly FAMILY_MEDICAL_CONDITIONS: string = "FAMILY_MEDICAL_CONDITIONS";
    static readonly HEALTH_PRIORITY: string = "HEALTH_PRIORITY";
    static readonly EXCITED_TO_LEARN: string = "EXCITED_TO_LEARN";
    static readonly HEALTH_FEARS: string = "HEALTH_FEARS";
    static readonly CURRENT_MEDICATIONS: string = "CURRENT_MEDICATIONS";
    static readonly CURRENT_MEDICATIONS_SUPPLEMENT: string = "CURRENT_MEDICATIONS_SUPPLEMENT";
    static readonly SPECIFIC_DIET: string = "SPECIFIC_DIET";
    static readonly REGULAR_FOOD: string = "REGULAR_FOOD";
    static readonly START_CONSUMING_FOOD: string = "START_CONSUMING_FOOD";
    static readonly STOP_CONSUMING_FOOD: string = "STOP_CONSUMING_FOOD";
    static readonly PRIMARY_EXERCISES: string = "PRIMARY_EXERCISES";
    static readonly EXERCISE_TYPE: string = "EXERCISE_TYPE";
    static readonly EXERCISE_FREQUENCY: string = "EXERCISE_FREQUENCY";
    static readonly EXERCISE_PROGRAM_FOR: string = "EXERCISE_PROGRAM_FOR";
    static readonly EXERCISE_LIMITATIONS: string = "EXERCISE_LIMITATIONS";
    static readonly PULSE_REST: string = "PULSE_REST";
    static readonly PULSE_AFTER_TEST: string = "PULSE_AFTER_TEST";
    static readonly PULSE_1_MIN_AFTER_TEST: string = "PULSE_1_MIN_AFTER_TEST";
    static readonly HEIGHT: string = "HEIGHT";
    static readonly WEIGHT: string = "WEIGHT";
    static readonly WAIST: string = "WAIST";
    static readonly MAX_PUSH_UPS: string = "MAX_PUSH_UPS";
    static readonly MAX_BUR_PRESS_1_MIN: string = "MAX_BUR_PRESS_1_MIN";
    static readonly SLEEP_QUALITY: string = "SLEEP_QUALITY";
    static readonly ROUTINE_BEFORE_SLEEP: string = "ROUTINE_BEFORE_SLEEP";
    static readonly START_SLEEP: string = "START_SLEEP";
    static readonly STOP_SLEEP: string = "STOP_SLEEP";
    static readonly ALARM_CLOCK: string = "ALARM_CLOCK";
    static readonly RESTED_AFTER_SLEEP: string = "RESTED_AFTER_SLEEP";
    static readonly ENERGY_AFTER_SLEEP: string = "ENERGY_AFTER_SLEEP";
    static readonly STRESS_LEVEL: string = "STRESS_LEVEL";
    static readonly DEAL_WITH_STRESS: string = "DEAL_WITH_STRESS";
    static readonly SBP: string = "SBP";
    static readonly ETHNICITY: string = "ETHNICITY";
    static readonly FASTING: string = "FASTING";
    static readonly CANCER_SCREENING: string = "CANCER_SCREENING";
    static readonly SMOKING_STATUS: string = "SMOKING_STATUS";
    static readonly DIABETES_STATUS: string = "DIABETES_STATUS";
    static readonly MEDITATION_FREQUENCY: string = "MEDITATION_FREQUENCY";
    static readonly EAT_FIRST_TIME: string = "EAT_FIRST_TIME";
    static readonly EAT_SECOND_TIME: string = "EAT_SECOND_TIME";
    static readonly EAT_THIRD_TIME: string = "EAT_THIRD_TIME";
    static readonly WHAT_EAT_FIRST_TIME: string = "EAT_FIRST_TIME";
    static readonly WHAT_EAT_SECOND_TIME: string = "EAT_SECOND_TIME";
    static readonly WHAT_EAT_THIRD_TIME: string = "EAT_THIRD_TIME";
    static readonly EAT_THROUGHOUT_DAY: string = "EAT_THROUGHOUT_DAY";
    static readonly RESTRICTED_FEEDING_DURING_DAY: string = "RESTRICTED_FEEDING_DURING_DAY";
    static readonly EXERCISING_DURING_DAY: string = "EXERCISING_DURING_DAY";
    static readonly ACTIVITIES_BEFORE_SLEEP: string = "ACTIVITIES_BEFORE_SLEEP";
    static readonly CONSUME_BEFORE_SLEEP: string = "CONSUME_BEFORE_SLEEP";
    static readonly FALL_ASLEEP: string = "FALL_ASLEEP";
    static readonly WAKE_UP: string = "WAKE_UP";
    static readonly WOKE_UP_FEEL: string = "WOKE_UP_FEEL";
    static readonly WOKE_UP_ENERGY: string = "WOKE_UP_ENERGY";
    static readonly MEDITATION_DURING_WEEK: string = "MEDITATION_DURING_WEEK";
}

export class HealthLogQuestionNames {
    static readonly FIRST_MEAL_TIME: string = "FIRST_MEAL_TIME";
    static readonly FIRST_MEAL: string = "FIRST_MEAL";
    static readonly SECOND_MEAL_TIME: string = "SECOND_MEAL_TIME";
    static readonly SECOND_MEAL: string = "SECOND_MEAL";
    static readonly LAST_MEAL_TIME: string = "LAST_MEAL_TIME";
    static readonly LAST_MEAL: string = "LAST_MEAL";
    static readonly LIST_OF_SNACKS: string = "LIST_OF_SNACKS";
    static readonly REGULAR_PRACTICE: string = "REGULAR_PRACTICE";
    static readonly WEEKS_EXERCISE: string = "WEEKS_EXERCISE";
    static readonly TYPES_OF_EXERCISE: string = "TYPES_OF_EXERCISE";
    static readonly ACTIVITIES_LIST: string = "ACTIVITIES_LIST";
    static readonly CONSUME_BEFORE_NIGHT: string = "CONSUME_BEFORE_NIGHT";
    static readonly FALL_ASLEEP_TIME: string = "FALL_ASLEEP_TIME";
    static readonly WAKE_UP_TIME: string = "WAKE_UP_TIME";
    static readonly WAKE_UP_WITH_ALARM: string = "WAKE_UP_WITH_ALARM";
    static readonly FEEL_IN_THE_MORNING: string = "FEEL_IN_THE_MORNING";
    static readonly ENERGY_THROUGHOUT_THE_DAY: string = "ENERGY_THROUGHOUT_THE_DAY";
    static readonly PRACTICES_A_WEEK: string = "PRACTICES_A_WEEK";
}



export class HealthGoalsQuestionNames {
    static readonly YOUR_INTENTION = "YOUR_INTENTION";
    static readonly YOUR_EXERCISE_GOALS = "YOUR_EXERCISE_GOALS";
    static readonly PERSONAL_EXERCISE_GOAL = "PERSONAL_EXERCISE_GOAL";
    static readonly EXERCISE_ROUTINE_LOOK_LIKE = "EXERCISE_ROUTINE_LOOK_LIKE";
    static readonly YOUR_NUTRITION_GOALS = "YOUR_NUTRITION_GOALS";
    static readonly PERSONAL_NUTRITION_GOAL = "PERSONAL_NUTRITION_GOAL";
    static readonly TYPE_OF_FOOD_DO_YOU_EAT = "TYPE_OF_FOOD_DO_YOU_EAT";
    static readonly YOUR_SLEEP_GOALS = "YOUR_SLEEP_GOALS";
    static readonly PERSONAL_SLEEP_GOAL = "PERSONAL_SLEEP_GOAL";
    static readonly GOOD_AND_BAD_SLEEP_ROUTINE = "GOOD_AND_BAD_SLEEP_ROUTINE";
    static readonly YOUR_MINDFULNESS_GOALS = "YOUR_MINDFULNESS_GOALS";
    static readonly PERSONAL_MINDFULNESS_GOAL = "PERSONAL_MINDFULNESS_GOAL";
    static readonly MINDFULNESS_PRACTICE_LOOK = "MINDFULNESS_PRACTICE_LOOK";
    static readonly YOUR_SOCIAL_CONNECTIONS = "YOUR_SOCIAL_CONNECTIONS";
    static readonly PERSONAL_SOCIAL_CONNECTION_GOAL = "PERSONAL_SOCIAL_CONNECTION_GOAL";
    static readonly DESCRIBE_SOCIAL_CONNECTIONS = "DESCRIBE_SOCIAL_CONNECTIONS";
    static readonly TAKE_TIME_TO_WALK_OUTSIDE = "TAKE_TIME_TO_WALK_OUTSIDE";
    static readonly TAKE_TIME_TO_WALK_OUTSIDE_COMMENT = "TAKE_TIME_TO_WALK_OUTSIDE_COMMENT";
    static readonly HEALS_HAPPINESS_PERFORMANCE = "HEALS_HAPPINESS_PERFORMANCE";
    static readonly HEALTH_FEELING = "HEALTH_FEELING";
    static readonly HEALS_COMMENTS = "HEALS_COMMENTS";
    static readonly OVERALL_PHYSICAL_HEALTH = "OVERALL_PHYSICAL_HEALTH";
    static readonly FREE_CHRONIC_PAIN = "FREE_CHRONIC_PAIN";
    static readonly FREE_CHRONIC_PAIN_COMMENTS = "FREE_CHRONIC_PAIN_COMMENTS";
    static readonly GET_SICK = "GET_SICK";
    static readonly SICK_COMMENTS = "SICK_COMMENTS";
    static readonly HEALTH_PHYSICAL_FEELING = "HEALTH_PHYSICAL_FEELING";
    static readonly HEALS_PHYSICAL_COMMENTS = "HEALS_PHYSICAL_COMMENTS";
    static readonly HEALTH_MORE_OR_LESS = "HEALTH_MORE_OR_LESS";
    static readonly HEALS_MORE_OR_LESS_COMMENTS = "HEALS_MORE_OR_LESS_COMMENTS";
    static readonly HAPPINESS_ENJOY_LIFE = "HAPPINESS_ENJOY_LIFE";
    static readonly HAPPINESS_ENJOY_LIFE_COMMENT = "HAPPINESS_ENJOY_LIFE_COMMENT";
    static readonly HAPPINESS_CONNECTIONS = "HAPPINESS_CONNECTIONS";
    static readonly HAPPINESS_CONNECTIONS_COMMENT = "HAPPINESS_CONNECTIONS_COMMENT";
    static readonly HAPPINESS_OPTIMISTIC_PESSIMISTIC = "HAPPINESS_OPTIMISTIC_PESSIMISTIC";
    static readonly HAPPINESS_OPTIMISTIC_PESSIMISTIC_COMMENT = "HAPPINESS_OPTIMISTIC_PESSIMISTIC_COMMENT";
    static readonly HAPPINESS_COACH_THERAPIST = "HAPPINESS_COACH_THERAPIST";
    static readonly HAPPINESS_COACH_THERAPIST_COMMENT = "HAPPINESS_COACH_THERAPIST_COMMENT";
    static readonly HAPPINESS_INTO_MY_80 = "HAPPINESS_INTO_MY_80";
    static readonly HAPPINESS_INTO_MY_80_COMMENT = "HAPPINESS_INTO_MY_80_COMMENT";
    static readonly HAPPINESS_OVERALL = "HAPPINESS_OVERALL";
    static readonly HAPPINESS_OVERALL_COMMENT = "HAPPINESS_OVERALL_COMMENT";
    static readonly PERFORMANCE_METRICS = "PERFORMANCE_METRICS";
    static readonly PERFORMANCE_METRICS_EXPLAIN = "PERFORMANCE_METRICS_EXPLAIN";
    static readonly PERFORMANCE_ACTIVITY_IMPORTANT = "PERFORMANCE_ACTIVITY_IMPORTANT";
    static readonly PERFORMANCE_ACTIVITY_IMPORTANT_COMMENT = "PERFORMANCE_ACTIVITY_IMPORTANT_COMMENT";
    static readonly PERFORMANCE_IMPROVEMENT = "PERFORMANCE_IMPROVEMENT";
    static readonly PERFORMANCE_IMPROVEMENT_COMMENT = "PERFORMANCE_IMPROVEMENT_COMMENT";
    static readonly PERFORMANCE_CONFIDENT_80S = "PERFORMANCE_CONFIDENT_80S";
    static readonly PERFORMANCE_CONFIDENT_80S_COMMENT = "PERFORMANCE_CONFIDENT_80S_COMMENT";
    static readonly PERFORMANCE_OVERALL = "PERFORMANCE_OVERALL";
    static readonly PERFORMANCE_OVERALL_COMMENT = "PERFORMANCE_OVERALL_COMMENT";    
}