import React from "react";
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { Member, useStyles } from "./chatMembersComponent.styles";
import clsx from "clsx";

export interface MemberViewModel {
  id?: number;
  image?: string;
  firstName: string;
  lastName: string;
  initials?: string;
  position?: string;
}

interface ChatMembersComponentProps {
  members: MemberViewModel[];
  title?: string;
}

export const ChatMembersComponent: React.FC<ChatMembersComponentProps> = (
  props: ChatMembersComponentProps
) => {
  const { members, title } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Box>
      <Box className={classes.title}>{title ?? 'Chat Members'}</Box>
      <List className={clsx("flat-scroll", classes.list)}>
        {members.map((item, index) => (
          <>
            <ListItem key={index} divider={isSmallScreen && index < members.length - 1}>
              <ListItemAvatar>
                <Avatar
                  className={classes.avatar}
                  variant="rounded"
                  src={item.image}
                >{`${item.initials}`}</Avatar>
              </ListItemAvatar>
              <Member
                primary={`${item.firstName} ${item.lastName}`}
                secondary={item.position}
              />
            </ListItem>
          </>
        ))}
      </List>
    </Box>
  );
};
