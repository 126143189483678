import React from 'react';
import {
  Box,
  TextField,
  IconButton,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
  Divider
} from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from "../manageRecommendationsComponent/manageRecommendationsComponent.styles";
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import { RecommendationTriggerModel, RecommendationModel, LogicalOperatorNames, MetricModel, CreateRecommendationTriggerComponentModel } from '../../../recommendations/models/recommendations.model';
import { IErrorState } from "../../../common/validation/error-state";
import { getNumericKeys } from '../../../common/helpers/enum-mapper';
import {recommendationName} from "../../../healthReport/models/healthReport.models";

export interface CreateRecommendationTriggerDialogProps extends IErrorState {
  isOpen: boolean;
  isLoading: boolean;
  params: RecommendationTriggerModel;
  recommendation: RecommendationModel | null;
  metrics: MetricModel[];
  handleChanges: (field: string, value: any, index: number) => void;
  handleClose: () => void;
  handleSave: () => void;
  handleAddComponent: () => void;
  handleRemoveComponent: (index: number) => void;
}

export const CreateRecommendationTriggerDialog: React.FC<CreateRecommendationTriggerDialogProps> = (props: CreateRecommendationTriggerDialogProps) => {
  const { isOpen, isLoading, params, recommendation, metrics, errors, handleChanges, handleClose, handleSave, handleAddComponent, handleRemoveComponent } = props;

  const classes = useStyles();

  const renderMetric = (component: CreateRecommendationTriggerComponentModel, index: number) => {
    const metric = component.metricId ? metrics.find(x => x.id === component.metricId) : null
    return (
      <Autocomplete
        size='small'
        disableClearable
        placeholder="Select"
        classes={{ root: classes.root }}
        options={metrics.filter(x => component.metricId !== x.id)}
        getOptionLabel={x => x.label}
        value={metric}
        onChange={(e, value) => handleChanges('metricId', value, index)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Select"
            InputProps={{ ...params.InputProps, type: 'search' }}
          />
        )}
      />
    )
  }

  const renderClassificationTypeOption = (component: CreateRecommendationTriggerComponentModel, index: number) => {
    const typeOptions = component.metricId ? metrics.find(x => x.id === component.metricId).classificationType.options : []
    return <FormControl
      variant="outlined"
      color='primary'
      required
      error={!!errors[`classificationTypeOptionId`]}
      size="small"
      disabled={!isOpen || isLoading || !component.metricId}
      classes={{ root: classes.root }}
      fullWidth>
      <Select
        style={{ background: '#FFF' }}
        classes={{ root: classes.root }}
        value={component.classificationTypeOptionId}
        error={!!errors[`classificationTypeOptionId`]}
        inputProps={{
          id: 'classificationTypeOptionId-label',
          classes,
        }}
        renderValue={() => {
          if (component.classificationTypeOptionId === null) {
              return <Box className="wh-tw-text-mediumGray">Select</Box>;
          }

          return typeOptions?.find(o => o.id === component.classificationTypeOptionId)?.name;
        }}
        displayEmpty
        onChange={(v) => handleChanges("classificationTypeOptionId", v.target.value, index)}
      >
        {
          typeOptions.map((option) => {
            return <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
          })
        }
      </Select>
      <FormHelperText>{errors[`classificationTypeOptionId`]}</FormHelperText>
    </FormControl>;
  }

  return (
    <>
      <Dialog
        onClose={() => handleClose()}
        className={classes.root}
        open={isOpen}
      >
        <DialogContent className={classes.dialogContent}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <span className="wh-tw-text-2xl wh-tw-black wh-tw-font-medium">{params.id > 0 ? 'Update Recommendation Trigger' : 'New Recommendation Trigger'}</span>
            </Box>
            <Box display="flex" alignItems="center">
              <IconButton
                id="new-recommendation-trigger-dialog-close"
                className={classes.dialogTitleCloseAction}
                onClick={() => handleClose()}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Box mt={5}>
            <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Recommendation</Box>
            <TextField
              id={`content`}
              fullWidth
              disabled
              multiline
              rows={5}
              size="small"
              InputProps={{ className: 'input', classes }}
              variant="outlined"
              value={recommendation?.content}
            />
          </Box>
          <Box mt={2}>
            <FormControl
              variant="outlined"
              color='primary'
              required
              error={!!errors[`logicalOperator`]}
              size="small"
              disabled={!isOpen || isLoading}
              classes={{ root: classes.root }}
              fullWidth>
              <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Logical Operation *</Box>
              <Select
                style={{ background: '#FFF' }}
                classes={{ root: classes.root }}
                value={params.logicalOperator}
                error={!!errors[`logicalOperator`]}
                inputProps={{
                  id: 'logicalOperator-label',
                  classes,
                }}
                onChange={(v) => handleChanges("logicalOperator", v.target.value, 0)}
              >
                {
                  getNumericKeys(LogicalOperatorNames).map((value, i) => {
                    return <MenuItem key={i} value={value}>{LogicalOperatorNames[value]}</MenuItem>
                  })
                }
              </Select>
              <FormHelperText>{errors[`logicalOperator`]}</FormHelperText>
            </FormControl>
          </Box>
          <Box mt={2}>
            <FormControl
                variant="outlined"
                color='primary'
                required
                error={!!errors[`recommendationType`]}
                size="small"
                disabled={!isOpen || isLoading}
                classes={{ root: classes.root }}
                fullWidth>
              <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Recommendation Type *</Box>
              <Select
                  style={{ background: '#FFF' }}
                  classes={{ root: classes.root }}
                  value={params.type}
                  error={!!errors[`recommendationType`]}
                  inputProps={{
                    id: 'recommendationType-label',
                    classes,
                  }}
                  onChange={(v) => handleChanges("type", v.target.value, 0)}
              >
                {
                  getNumericKeys(recommendationName).map((value, i) => {
                    return <MenuItem key={i} value={value}>{recommendationName[value]}</MenuItem>
                  })
                }
              </Select>
              <FormHelperText>{errors[`recommendationType`]}</FormHelperText>
            </FormControl>
          </Box>
          <Box mt={2}>
            <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Confidence *</Box>
            <TextField
              id={`confidence`}
              fullWidth
              required
              type="number"
              size="small"
              InputProps={{ className: 'input', classes }}
              variant="outlined"
              value={params.confidence}
              error={!!errors['confidence']}
              helperText={errors['confidence']}
              disabled={!isOpen || isLoading}
              onChange={(event) => handleChanges(event.target.id, event.target.value, 0)}
            />
          </Box>
          <Box mt={4}>
            <Box mb={2} display="flex" justifyContent="space-between">
              <Box display="flex" alignItems="flex-end" className="wh-tw-text-sm wh-tw-text-gray1">Compnents</Box>
              <Box>
                <WildHealthButton
                  id="add-component-button"
                  loading={isLoading}
                  onClick={() => handleAddComponent()}
                >
                  <Box className="wh-tw-text-base wh-tw-font-medium">Add Component</Box>
                </WildHealthButton>
              </Box>
            </Box>
            <Divider className="wh-tw-bg-stroke" />
            {params.recommendationTriggerComponents.length > 0 && (
              <Box my={2} p={1}>
                {params.recommendationTriggerComponents.map((component, index) => (
                  <Box key={component.id} display="flex" alignItems="center" justifyContent="space-between">
                    <Box flex={1} display="flex" alignItems="center" gridGap={6}>
                      <Box width={0.5}>
                        {renderMetric(component, index)}
                      </Box>
                      <Box width={0.5} pt={0.5}>
                        {renderClassificationTypeOption(component, index)}
                      </Box>
                    </Box>
                    <Box>
                      <IconButton
                        id={`remove-component-${index}`}
                        className={classes.menuIcon}
                        disabled={isLoading}
                        onClick={(e) => handleRemoveComponent(index)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
          <Box mt={5} display="flex" justifyContent="flex-end" alignItems="center">
            <Box mx={2}>
              <WildHealthButton
                id="new-recommendation-trigger-cancel"
                onClick={() => handleClose()}
                color="tertiary"
                size='medium'
                disabled={!isOpen || isLoading}
              >
                Cancel
              </WildHealthButton>
            </Box>
            <Box>
              <WildHealthButton
                id="save-recommendation-trigger-button"
                size='medium'
                onClick={handleSave}
                loading={isLoading}
              >
                {params.id > 0 ? 'Update' : 'Save'}
              </WildHealthButton>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}