import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Subscription } from "recompose";
import { navigationService } from "../../../../services/navigation.service";
import { onEmit } from "../../../common/helpers/on-emit";
import {
    ConversationAuthorModel,
    ConversationType,
    PatientConversationModel,
    StartPatientSupportConversation
} from "../../models/conversation.models";
import { ConversationSubject, supportConversationSubjects } from "../../models/conversationSubject.models";
import { conversationsService } from "../../services/conversations.service";
import { employeeConversationsStore } from "../../stores/employeeConversationsStore/employeeConversations.store";
import { patientConversationsQuery } from "../../stores/patientConversationsStore/patientConversations.query";
import { patientConversationsStore } from "../../stores/patientConversationsStore/patientConversations.store";

export enum StaffChatState {
    History,
    New,
    Selected
}

interface StaffChatComponentState {
    view: StaffChatState;
    creatingTicket: boolean;
    author: ConversationAuthorModel;
    targetConversation: PatientConversationModel;
}


export function useFacade(targetConversationId?: number): [
    StaffChatComponentState,
    () => void,
    (conversation: any) => void,
    () => void,
    (subject: ConversationSubject) => void,
    () => void,
] {
    const history = useHistory();
    const [state, setState] = useState({
        view: StaffChatState.History,
        creatingTicket: false,
        author: null,
        targetConversation: null,
    } as StaffChatComponentState);

    const handleNavigateToChat = () => {
        navigationService.toChat(history, ConversationType.Support)
    }

    const handleOpenConversation = (conversation) => {
        patientConversationsStore.setTargetConversation(conversation);
        setTimeout(() => {
            employeeConversationsStore.resetAttachments();
            employeeConversationsStore.resetAttachmentsView();
        }, 1);

        setState(state => ({
            ...state,
            view: StaffChatState.Selected,
            targetConversation: conversation
        }));
    }

    const handleNewConversationClick = () => {
        setState(state => ({
            ...state,
            view: StaffChatState.New
        }));
    }

    const handleStartConversation = (subject: ConversationSubject) => {
        const model: StartPatientSupportConversation = {
            subject: supportConversationSubjects.get(subject)
        }

        setState(state => ({ ...state, creatingTicket: true }));
        employeeConversationsStore.resetAttachments()
        employeeConversationsStore.resetAttachmentsView();
        conversationsService.startPatientSupportConversations(model).subscribe(
            (conversation) => {
                patientConversationsStore.setTargetConversation(conversation);
                setState(state => ({ ...state, creatingTicket: false }));
            }
        )
    }

    const handleNavigateToMessageHistory = () => {
        patientConversationsStore.setTargetConversation(null);
        setState(state => ({ ...state, view: StaffChatState.History }));
    }

    const getTargetConversation = (conversations: PatientConversationModel[], targetConversationId?: number) => {
        if (conversations && conversations.length) {
            const conversation = conversations.find(i => i.id === targetConversationId);
            if (conversation) {
                employeeConversationsStore.resetAttachments()
                employeeConversationsStore.resetAttachmentsView();
                patientConversationsStore.setTargetConversation(conversation);
            }
        }
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<PatientConversationModel>(patientConversationsQuery.targetConversation$, conversation => {
                if (conversation) {
                    setState(state => ({ ...state, targetConversation: conversation, view: StaffChatState.Selected }));
                } else {
                    setState(state => ({ ...state, view: StaffChatState.History }));
                }
            }),
            onEmit<PatientConversationModel[]>(patientConversationsQuery.supportConversations$, conversations =>
                getTargetConversation(conversations, targetConversationId)
            ),
            onEmit<ConversationAuthorModel>(patientConversationsQuery.author$, author =>
                setState(state => ({ ...state, author: author }))
            ),
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [targetConversationId]);

    return [
        state,
        handleNavigateToChat,
        handleOpenConversation,
        handleNewConversationClick,
        handleStartConversation,
        handleNavigateToMessageHistory
    ];
}
