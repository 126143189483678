import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxHeight: 'calc(100vh - 204px)',
            overflowY: 'auto',
            [theme.breakpoints.down('sm')]: {
                padding: 0
            },
        }
    })
);


