import {
    CreatePatientAppointmentDialogComponent
} from '../createPatientAppointmentDialog/CreatePatientAppointmentDialogComponent';
import { WildHealthPlaceHolderBase } from '../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolderBase';
import { AppointmentsSummaryModel, AppointmentTypeModel, PatientAppointmentModel } from '../../models/appointments.models';
import { AppointmentStatus, AppointmentWithType } from '../../models/appointments.enums';
import { AppointmentCardComponent } from '../appointmentCard/AppointmentCardComponent';
import { LocationModel } from '../../../locations/models/locations.models';
import { EmployeeModel } from '../../../employee/models/employee.models';
import commonUseStyles from '../../../common/styles/common.styles';
import { Box, Link, Tooltip, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { TimeZoneModel } from "../../../timezones/models/timezone.model";
import { EmployeeType, employeeTypesNames, employeeTypesNamesForAppointment } from '../../../employee/models/employee.enums';
import InfoIcon from '@material-ui/icons/InfoRounded';
import { colors } from '../../../common/constants/colors';
import { authQuery } from '../../../auth/stores/auth';
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import { FeatureComponent } from "../../../common/components/featureFlags/FeatureComponent";
import {getAvailableAppointmentType, getAvailableWithTypes} from '../../helpers/appointmentHelper';
import { implicitUnlimitedAmount } from '../../constants/appointment.constants'
import { SubscriptionModel, SubscriptionStatus, SubscriptionType } from '../../../payment/models/subscription.models';

interface AppointmentCardsComponentProps {
    appointments: PatientAppointmentModel[];
    appointmentTypes: AppointmentTypeModel[];
    handleAddAppointmentToCalendar: (appointmentId: number) => void;
    handleCancelAppointment: (appointmentId: number) => void;
    handleEditAppointment: (appointmentId: number) => void;
    handleJoinAppointment: (joinLink: string) => void;
    handleBuyProducts: () => void;
    assignedEmployees: EmployeeModel[];
    locations: LocationModel[];
    timeZone: TimeZoneModel;
    patientId?: number | null;
    isBuyProductsLink: boolean;
    subscription: SubscriptionModel;
    appointmentsSummary?: AppointmentsSummaryModel;
}

export const AppointmentCardsComponent: React.FC<AppointmentCardsComponentProps> = (props: AppointmentCardsComponentProps) => {
    const {
        appointments,
        appointmentTypes,
        handleAddAppointmentToCalendar,
        handleCancelAppointment,
        handleEditAppointment,
        handleJoinAppointment,
        handleBuyProducts,
        assignedEmployees,
        locations,
        timeZone,
        patientId = null,
        isBuyProductsLink = false,
        subscription,
        appointmentsSummary,
    } = props;

    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

    const coachSectionWithTypes: Array<AppointmentWithType> = [AppointmentWithType.HealthCoach];
    const providerSectionWithTypes: Array<AppointmentWithType> = [AppointmentWithType.HealthCoachAndProvider, AppointmentWithType.Provider];
    const denomenator = appointmentsSummary?.availableCoachAppointments;

    const getAppointmentsByWithType = (withTypes: AppointmentWithType[], appointments: PatientAppointmentModel[]): PatientAppointmentModel[] => {
        return appointments
            .filter(x => x.status !== AppointmentStatus.Canceled
                && withTypes.includes(x.withType));
    }

    const renderAppointments = (withTypes: AppointmentWithType[], personType: EmployeeType,) => {
        const targetAppointments = getAppointmentsByWithType(withTypes, appointments);

        const emptyMessage = `No upcoming ${employeeTypesNamesForAppointment.get(personType)} ${personType === EmployeeType.HealthCoach ? 'Sessions' : 'Visits'}`;

        if (!targetAppointments || !targetAppointments.length) {
            return (
                <Box mt="68px">
                    <WildHealthPlaceHolderBase message={emptyMessage} />
                </Box>
            );
        }

        return (
            <>
                {
                    targetAppointments.map((appointment, i) => (
                        <Box mt='20px' key={i}>
                            <AppointmentCardComponent
                                handleAddAppointmentToCalendar={handleAddAppointmentToCalendar}
                                handleCancelAppointment={handleCancelAppointment}
                                handleEditAppointment={handleEditAppointment}
                                handleJoinAppointment={handleJoinAppointment}
                                appointment={appointment}
                                timeZone={timeZone}
                            />
                        </Box>
                    ))
                }
            </>
        )
    }

    const renderCoachVisitsInfo = () => {
        const hasHealthCoachingVisits = denomenator > 0;
        const unlimitedVisits = denomenator >= implicitUnlimitedAmount;
        const isPCP = subscription?.status === SubscriptionStatus.Active && subscription.paymentPlan.name === 'PRECISION_CARE_PACKAGE'

        if (isPCP) {
            return <></>
        }

        return <Box display="flex" alignItems="center" className={commonClasses.size14}>
            { hasHealthCoachingVisits &&
                <Box
                    minWidth="50px"
                    maxHeight="26px"
                    fontWeight="bold"
                    borderRadius="2px"
                    px={1} py={0.5}
                    mr={1}
                    className={clsx(commonClasses.backgroundColorAccent2)}
                >
                    {appointmentsSummary?.completedCoachAppointments}/
                    {unlimitedVisits
                        ? <>&infin;</>
                        : denomenator
                    }
                </Box>
            }
            { hasHealthCoachingVisits
                ? <Box>Your plan includes {unlimitedVisits === true ? 'unlimited' : denomenator} Health Coach {denomenator === 1 ? 'Session' : 'Sessions'}!</Box>
                : <Box>No Health Coaching visits available, Reach out to your Health Coach if you have any questions</Box>
            }

        </Box>
    }

    const canPurchaseVisitCredits = () => {
        const availableWithTypes = getAvailableWithTypes(appointmentTypes);
        const targetWithType = availableWithTypes.find(x => providerSectionWithTypes.includes(x));
        const availableType = getAvailableAppointmentType(appointmentTypes, targetWithType);

        return availableType && isBuyProductsLink
    }

    const renderProviderVisitsInfo = () => {
        return <>
            <Box display="flex" alignItems="center" mb={1} className={commonClasses.size14}>
                <Box
                    minWidth="42px"
                    fontWeight="bold"
                    maxHeight="26px"
                    borderRadius="2px"
                    px={1} py={0.5}
                    mr={1}
                    className={clsx(commonClasses.backgroundColorAccent2)}
                >{appointmentsSummary?.completedProviderMembershipVisits}/{appointmentsSummary?.availableProviderMembershipVisits}
                </Box>
                <Box mr={1} fontWeight='400'>Included Visits Used</Box>
                <Box className={commonClasses.colorGray2}>
                    <Tooltip placement="top" arrow
                        title={`Your membership includes ${appointmentsSummary?.availableProviderMembershipVisits} ${appointmentsSummary?.availableProviderMembershipVisits === 1 ? 'visit' : 'visits'} every year. Additional visits can be purchased below.`}>
                        <InfoIcon />
                    </Tooltip>
                </Box>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="space-between" className={commonClasses.size14}>
                <Box display="flex" alignItems="center">
                    <Box
                        minWidth="42px"
                        maxHeight="26px"
                        fontWeight="bold"
                        borderRadius="2px"
                        px={1} py={0.5}
                        mr={1}
                        className={clsx(commonClasses.backgroundColorAccent2)}
                    >{appointmentsSummary?.completedAdditionalProviderVisits}/{appointmentsSummary?.availableAdditionalProviderVisits}
                    </Box>
                    <Box fontWeight='400'>Visit Credits Used</Box>
                </Box>
                <Box>
                    {canPurchaseVisitCredits() && <Link
                        color={colors.main}
                        id='purchase-visit-credits'
                        onClick={() => handleBuyProducts()}
                    >
                        <Typography display='inline' className={commonClasses.size14}>
                            <span className={commonClasses.colorMain}>
                                Purchase Visit Credits
                            </span>
                        </Typography>
                    </Link>}
                </Box>
            </Box>
        </>
    }

    const renderProviderVisitsInfoWithInsurance = () => {
        return <Box display="flex" alignItems="center" className={commonClasses.size14}>
            <Box
                minWidth="28px"
                maxHeight="26px"
                fontWeight="bold"
                borderRadius="2px"
                px={1} py={0.5}
                mr={1}
                className={clsx(commonClasses.backgroundColorAccent2)}
            >
                {appointmentsSummary?.completedAdditionalProviderVisits}
            </Box>
            <Box fontWeight='400'>Completed Provider Visits</Box>
        </Box>
    }

    const renderSection = (personType: EmployeeType, withTypes: AppointmentWithType[]) => {
        const availableWithTypes = getAvailableWithTypes(appointmentTypes);
        const availableTypes = appointmentTypes.filter(x => x.configurations.some(t => availableWithTypes.includes(t.withType)));
        const targetWithType = availableWithTypes.find(x => withTypes.includes(x));

        const availableType = getAvailableAppointmentType(appointmentTypes, targetWithType);

        const targetAppointments = getAppointmentsByWithType(withTypes, appointments);

        const isCanCreate = personType === EmployeeType.HealthCoach ? denomenator > 0 : true;

        const employee = assignedEmployees?.find(el => el.role.displayName === employeeTypesNames.get(personType))

        return <Box width="47%">
            <Box px={4} py={2} className={clsx(commonClasses.backgroundColorLightGray, commonClasses.textMedium)}>
                <Box minHeight="40px" display="flex" justifyContent="space-between" alignItems="center">
                    <Box>
                        <span className={commonClasses.size18}>
                            {`${employeeTypesNamesForAppointment.get(personType)} ${personType === EmployeeType.HealthCoach ? 'Sessions' : 'Visits'}`}
                        </span>
                    </Box>
                    <Box>
                        {
                            !targetAppointments?.length && targetWithType && availableType && isCanCreate && employee &&
                            <CreatePatientAppointmentDialogComponent
                                locations={locations}
                                assignedEmployees={assignedEmployees}
                                appointmentTypes={availableTypes}
                                appointmentWithType={targetWithType}
                                timeZone={timeZone}
                                patientId={patientId}
                            />
                        }
                    </Box>
                </Box>
                {
                    !authQuery.isLicensingPractice() &&
                    <FeatureComponent featureFlag={FeatureFlag.PatientProduct}>
                        <Box minHeight="80px" display="flex" flexDirection="column" justifyContent="center" className={commonClasses.size14}>
                            {personType === EmployeeType.HealthCoach
                                ? renderCoachVisitsInfo()
                                : subscription?.type === SubscriptionType.Insurance && subscription?.status === SubscriptionStatus.Active
                                    ? renderProviderVisitsInfoWithInsurance()
                                    : renderProviderVisitsInfo()
                            }
                        </Box>
                    </FeatureComponent>
                }
            </Box>

            {
                renderAppointments(withTypes, personType)
            }
        </Box>
    }

    return (
        <Box mx={!isSmallScreen && "109px"}>
            <Box display="flex" justifyContent="space-between">
                {renderSection(EmployeeType.HealthCoach, coachSectionWithTypes)}
                {renderSection(EmployeeType.Provider, providerSectionWithTypes)}
            </Box>
        </Box>
    );
}
