import React from "react";
import {AuthenticatedLayoutComponent} from "../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent";
import {PagesNamesService} from "../../../../constants/pagesNames.constants";
import {TitlesService} from "../../../../constants/title.constants";
import {MenuItemTypes} from "../../../../models/menu.models";
import {PatientLabInputsComponent} from "../../components/patientLabInputsComponent/PatientLabInputsComponent";

export const PatientLabsPage: React.FC = () => {
    return (
        <AuthenticatedLayoutComponent
            title={TitlesService.labsInputsTitle}
            pageName={PagesNamesService.results}
            selectedMenuItem={MenuItemTypes.LabsInputs}
            hideBackground
        >
            <PatientLabInputsComponent/>
        </AuthenticatedLayoutComponent>
    )
}
