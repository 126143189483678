import {useEffect, useState} from "react";
import {authQuery} from "../../../auth/stores/auth";
import {UserType} from "../../../auth/models/auth.enums";
import {insertString} from "../../../common/helpers/insert-string";
import {selectShortcutService} from "../../../healthReport/services/selectShortcut.service";

interface SendPlaygroundMessageComponentState {
    message: string;
}

let ignoreChangesEvent = false;

export function useFacade(onSubmit: Function, message: string): [
    SendPlaygroundMessageComponentState,
    (event: any) => void,
    (value: string) => void,
    () => void,
] {
    const [state, setState] = useState({
        message: message
    } as SendPlaygroundMessageComponentState);

    const handleKeyDown = (event: any) => {
        ignoreChangesEvent = false;

        if (event.keyCode === 13 && !event.shiftKey) {
            ignoreChangesEvent = true;
            handleSendMessage();
        }

        if (event.keyCode === 51 && event.altKey) {
            const userType = authQuery.getType();
            if (UserType.Employee === userType) {
                ignoreChangesEvent = true;

                setTimeout(() => {
                    handleOnTextMessageChanged((document.getElementById('type-message') as any).value, (document.getElementById('type-message') as any).selectionStart);
                }, 1)
            }
        }
    }

    const handleMessageChanged = (value: string) => {
        setState(state => ({
            ...state,
            message: value
        }));
    }

    const handleOnTextMessageChanged = (value: string, cursorPos: number) => {
        const cb = (data) => {
            value = state.message;
            if (data) {
                handleMessageChanged(insertString(value, data, cursorPos + 1));
            }
        }

        selectShortcutService.select().subscribe(cb);
    }

    const handleSendMessage = () => {
        if (onSubmit(state.message)) {
            setState(state => ({
                ...state,
                message: ''
            }))
        }
    }

    useEffect(() => {

        setState(state => ({
            ...state,
            message: message
        }));

        return () => {}
    }, [message])

    return [
        state,
        handleKeyDown,
        handleMessageChanged,
        handleSendMessage,
    ];
}