import moment from "moment";
import { useState } from "react";
import { AvailabilityModel } from "../../../appointments/models/availability.models";

interface ConversationComponentState {
    canSubmit: boolean;
    selectedTime: Date;
    selectedDate: Date;
    minDate: Date;
}

export function useFacade(handleSubmit: (date: Date, time: Date) => void, handleToggle: () => void): [ConversationComponentState, () => AvailabilityModel[], (time: Date) => void, (date: Date) => void, () => void,  () => void] {

    const [state, setState] = useState({
        canSubmit: false,
        selectedTime: null,
        selectedDate: new Date(),
        minDate: new Date(),
    } as ConversationComponentState);

    const handleChangeTime = (time: Date) => {
        setState(state => ({...state, selectedTime: time, canSubmit: true}));
    }

    const handleChangeDate = (date: Date) => {
        setState(state => ({...state, selectedDate: date, selectedTime: null, canSubmit: false}));
    }
    const handleCancel = () => {
        handleToggle();
        setState(state => ({...state, selectedDate: new Date(), selectedTime: null, canSubmit: false}));
    }

    const handleConfirm = () => {
        handleSubmit(state.selectedDate, state.selectedTime);
        setState(state => ({...state, selectedDate: new Date(), selectedTime: null, canSubmit: false}));
    }

    const getAvailability = () => {
        const isToday = moment(state.selectedDate).isSame(moment(new Date()).startOf('day'), 'd');
        let iterations = 10;
        const date = state.selectedDate;

        date.setMinutes(0);
        date.setHours(8);

        if (isToday) {
            const endHour = 18;

            const hours = new Date().getHours();
            date.setHours(hours);
            iterations = endHour - (hours > endHour ? endHour : hours);
        }

        let previousTime = moment(date);
        const result = [];

        for (let i = 0; i < iterations; i++) {
        const time = previousTime.add(1, 'hours');

            result.push({
                start: time.toDate(),
                end: time.toDate(),
                timeZone: ''
            });

            previousTime = time;
        }

        return result;
    }

    return [state,
        getAvailability,
        handleChangeTime,
        handleChangeDate,
        handleConfirm,
        handleCancel
    ];
}
