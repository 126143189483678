import {IcdCode} from "../../../notes/models/notes.models";
import {useEffect, useState} from "react";
import {commonDiagnoses} from "../../../notes/helpers/noteHelpers";

interface OrderDiagnosisComponentState {
    isLoading: boolean;
    searchQuery: string;
    enableAdd: boolean;
    valueToEdit: IcdCode;
    indexToEdit: number | null;
    diagnosis: IcdCode[];
    diagnosisCommon: IcdCode[];
}

let stateContext: OrderDiagnosisComponentState = null;

export function useFacade(selectedDiagnosis: IcdCode[], handleChanges: Function, getAutoComplete: Function): [
    OrderDiagnosisComponentState,
    (index: number) => void,
    () => void,
    (value: string) => void,
    (diagnosis: IcdCode) => void,
    (value: string) => void,
    (index: number) => void,
    (diagnosis: IcdCode) => void
] {
    const [state, setState] = useState({
        isLoading: false,
        searchQuery: '',
        enableAdd: false,
        valueToEdit: null,
        indexToEdit: null,
        diagnosis: [],
        diagnosisCommon: commonDiagnoses.sort((a,b) => {
            if (a.description < b.description) {
                return -1;
            }
            if (a.description > b.description) {
                return 1;
            }
            return 0;
        }),
    } as OrderDiagnosisComponentState);

    stateContext = state;

    const handleDeleteItem = (index: number) => {
        selectedDiagnosis.splice(index, 1);

        setState(state => ({
            ...state,
            searchQuery: '',
            valueToEdit: null,
            indexToEdit: null,
            diagnosis: []
        }))

        handleChanges(selectedDiagnosis);
    }

    const handleEnableAdd = () => {
        setState(state => ({
            ...state,
            enableAdd: true,
            searchQuery: '',
            indexToEdit: null,
            valueToEdit: null,
            diagnosis: [],
        }))
    }

    const handleAddChanges = (value: string) => {
        setState(state => ({ ...state, searchQuery: value }));
    }

    const handleAddSubmit = (diagnosis: IcdCode) => {
        if (diagnosis && diagnosis?.id) {
            diagnosis.additionalInformation = '';
            selectedDiagnosis.push(diagnosis);
            handleChanges(selectedDiagnosis);
        }

        setState(state => ({
            ...state,
            enableAdd: false,
            searchQuery: '',
            diagnosis: [],
        }))
    }

    const handleEnableEdit = (index: number) => {
        const diagnosis = selectedDiagnosis[index];
        setState(state => ({
            ...state,
            searchQuery: diagnosis.code + ' ' + diagnosis.description,
            indexToEdit: index,
            enableAdd: false,
            valueToEdit: diagnosis,
            diagnosis: []
        }))
    }

    const handleEditChanges = (value: string) => {
        setState(state => ({ ...state, searchQuery: value }))
    }

    const handleSubmitEdit = (diagnosis: IcdCode) => {
        if (diagnosis && diagnosis.id) {
            diagnosis.additionalInformation = '';
            selectedDiagnosis[state.indexToEdit] = diagnosis;
            handleChanges(selectedDiagnosis);
        }

        setState(state => ({
            ...state,
            searchQuery: '',
            enableAdd: false,
            valueToEdit: null,
            indexToEdit: null,
            diagnosis: []
        }))
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (getAutoComplete && stateContext.searchQuery) {
                setState(state => ({
                    ...state,
                    isLoading: true
                }));
                getAutoComplete(stateContext.searchQuery).subscribe(
                    (diagnosis) => {
                        const selectedCodes = selectedDiagnosis.map(k => k.code);
                        setState(state => ({
                            ...state,
                            diagnosis: diagnosis.filter(x => !selectedCodes.includes(x.code)),
                            isLoading: false
                        }));
                    },
                    () => {
                        setState(state => ({
                            ...state,
                            diagnosis: [],
                            isLoading: false
                        }));
                    })
            }
        }, 1500);

        return () => clearTimeout(delayDebounceFn)
    }, [state.searchQuery, getAutoComplete]);


    return [
        state,
        handleDeleteItem,
        handleEnableAdd,
        handleAddChanges,
        handleAddSubmit,
        handleEditChanges,
        handleEnableEdit,
        handleSubmitEdit
    ]
}