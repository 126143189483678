import {Store, StoreConfig} from '@datorama/akita';
import {DraftNoteModel, GoalModel, GoalsFilterState, NoteContentModel, NoteModel, NotesGroupModel, GoalCommonModel, MdmCommonModel, InterventionModel, MdmReasonModel, SupplementItem, SpecialTestModel} from '../../models/notes.models';
import {timezonesQuery} from "../../../timezones/stores/timezones";
import {toTimeZoneDate} from "../../../timezones/helpers/timezone";
import { SortingDirection } from '../../../common/sorting/models/sorting-destination';
import moment from 'moment';

export interface NotesState {
    currentGoals: GoalModel[];
    pastGoals: GoalModel[];
    goalsTab: GoalsFilterState;
    pastGoalsTotal: number;
    notes: NoteModel[];
    notesForReview: NoteModel[];
    notesUnderReview: NoteModel[];
    draftNotes: DraftNoteModel[];
    totalCount:number;
    notesGroups: NotesGroupModel[];
    targetContent: NoteContentModel;
    notificationAwaitingApproval: boolean;
    commonGoals: GoalCommonModel[];
    targetCommonGoal: GoalCommonModel;
    commonMdms: MdmCommonModel[];
    targetCommonMdm: MdmCommonModel;
    commonSupplements: SupplementItem[];
    commonOrders: SpecialTestModel[];
}

/**
 * Creates initial notes state
 */
export function createInitialState(): NotesState {
    return {
        currentGoals: [],
        pastGoals: [],
        pastGoalsTotal: 0,
        goalsTab: GoalsFilterState.TopGoals,
        notes: [],
        notesForReview: [],
        notesUnderReview: [],
        draftNotes: [],
        totalCount: 0,
        notesGroups: [],
        targetContent: null,
        notificationAwaitingApproval: false,
        commonGoals: [],
        targetCommonGoal: null,
        commonMdms: [],
        targetCommonMdm: null,
        commonSupplements: [],
        commonOrders: []
    };
}

/**
 * Provides notes state management
 */
@StoreConfig({name: 'notes', resettable: true })
export class NotesStore extends Store<NotesState> {
    constructor() {
        super(createInitialState());
    }

    public setNotes(notes: NoteModel[]): void {
        notes = notes.map(x => NotesStore._applyTimeZoneToAppointment(x));
        const groups = this._groupedVisitNotes(notes);

        this.update({
            notes: notes,
            notesGroups: groups
        });
    }

    public setGoalsTab(tab: GoalsFilterState): void {
        this.update({
            goalsTab: tab,
        });
    }

    public resetTargetContent(): void {
        this.update({
            targetContent: null,
        });
    }

    public updateDraftNotesWithSort(notes: DraftNoteModel[], sortingSource: string, sortingDirection: string): void {
        if (sortingSource === 'date') {
            if (sortingDirection === SortingDirection.Asc) {
               notes.sort((a, b) => (+moment(a.visitDate).diff(moment().startOf('day'), 'seconds')) - (+moment(b.visitDate).diff(moment().startOf('day'), 'seconds')))
            } else {
                notes.sort((a, b) => (+moment(b.visitDate).diff(moment().startOf('day'), 'seconds')) - (+moment(a.visitDate).diff(moment().startOf('day'), 'seconds')))
            }
            this.update({
                draftNotes: notes
            });
        } else {
            this.update({
                draftNotes: notes
            });
        }
    }

    public addOrUpdateNote(note: NoteModel) {
        const group = this.getValue().notesGroups.find(x => x.employee.id === note.employee.id);

        note = NotesStore._applyTimeZoneToAppointment(note);

        if (group) {
            const existingNote = group.notes.find(x => x.id === note.id);
            if (existingNote) {
                group.notes = group.notes.map(x => x.id === note.id ? note : x);
                this.update({
                    notesGroups: [...this.getValue().notesGroups],
                    notes: [...this.getValue().notes.map(x => x.id === note.id ? note : x)]
                });
            } else {
                group.notes.push(note);
                this.update({
                    notesGroups: [...this.getValue().notesGroups],
                    notes: [...this.getValue().notes, note]
                });
            }
        } else {
            const newGroup = {
                employee: note.employee,
                notes: [note]
            }
            this.update({
                notesGroups: [...this.getValue().notesGroups, newGroup],
                notes: [...this.getValue().notes, note]
            });
        }

        if (this.getValue().notesForReview.find(x => x.id === note.id)) {
            this.update({
                notesForReview: this.getValue().notesForReview.map(x => x.id === note.id ? note : x)
            });
        }

        if (this.getValue().notesUnderReview.find(x => x.id === note.id)) {
            this.update({
                notesUnderReview: this.getValue().notesUnderReview.map(x => x.id === note.id ? note : x)
            });
        }
    }

    public deleteDraft(note: DraftNoteModel) {
        if (note.deletedBy) {
            this.addOrUpdateNote(note);
        }
        else {
            this.update({
                draftNotes: [...this.getValue().draftNotes.filter(x => x.id !== note.id)],
                totalCount: this.getValue().totalCount-1
            });
        }
    }

    public addCommonGoal(newCommonGoal: GoalCommonModel) {
        this.update({ commonGoals: [...this.getValue().commonGoals, newCommonGoal] });
    }

    public updateCommonGoal(commonGoal: GoalCommonModel) {
        const commonGoals = this.getValue().commonGoals;
        this.update({ commonGoals: commonGoals.map(x => x.id === commonGoal.id ? commonGoal : x), targetCommonGoal: commonGoal });
    }

    public removeCommonGoal(id: number) {
        this.update({ commonGoals: this.getValue().commonGoals.filter(i => i.id !== id) });
    }

    public addIntervention(newIntervention: InterventionModel) {
        const interventions = [...this.getValue().targetCommonGoal.interventions, newIntervention];
        this.update({ targetCommonGoal: {...this.getValue().targetCommonGoal, interventions} });
    }

    public updateIntervention(intervention: InterventionModel) {
        const interventions = [...this.getValue().targetCommonGoal.interventions];
        this.update({ targetCommonGoal: {...this.getValue().targetCommonGoal, interventions: interventions.map(x => x.id === intervention.id ? intervention : x)} });
    }

    public removeIntervention(id: number) {
        const interventions = [...this.getValue().targetCommonGoal.interventions];
        this.update({ targetCommonGoal: {...this.getValue().targetCommonGoal, interventions: interventions.filter(i => i.id !== id)} });
    }

    public addCommonMdm(newCommonMdm: MdmCommonModel) {
        this.update({ commonMdms: [...this.getValue().commonMdms, newCommonMdm] });
    }

    public updateCommonMdm(commonMdm: MdmCommonModel) {
        const commonMdms = this.getValue().commonMdms;
        this.update({ commonMdms: commonMdms.map(x => x.id === commonMdm.id ? commonMdm : x), targetCommonMdm: commonMdm });
    }

    public removeCommonMdm(id: number) {
        this.update({ commonMdms: this.getValue().commonMdms.filter(i => i.id !== id) });
    }

    public addReason(newReason: MdmReasonModel) {
        const reasons = [...this.getValue().targetCommonMdm.reasons, newReason];
        this.update({ targetCommonMdm: {...this.getValue().targetCommonMdm, reasons} });
    }

    public updateReason(reason: MdmReasonModel) {
        const reasons = [...this.getValue().targetCommonMdm.reasons];
        this.update({ targetCommonMdm: {...this.getValue().targetCommonMdm, reasons: reasons.map(x => x.id === reason.id ? reason : x)} });
    }

    public removeReason(id: number) {
        const reasons = [...this.getValue().targetCommonMdm.reasons];
        this.update({ targetCommonMdm: {...this.getValue().targetCommonMdm, reasons: reasons.filter(i => i.id !== id)} });
    }

    public addCommonSupplement(newCommonSupplement: SupplementItem) {
        this.update({ commonSupplements: [...this.getValue().commonSupplements, newCommonSupplement] });
    }

    public updateCommonSupplement(commonSupplement: SupplementItem) {
        const commonSupplements = this.getValue().commonSupplements;
        this.update({ commonSupplements: commonSupplements.map(x => x.id === commonSupplement.id ? commonSupplement : x) });
    }

    public removeCommonSupplement(id: number) {
        this.update({ commonSupplements: this.getValue().commonSupplements.filter(i => i.id !== id) });
    }

    public addCommonOrder(newCommonOrder: SpecialTestModel) {
        this.update({ commonOrders: [...this.getValue().commonOrders, newCommonOrder] });
    }

    public updateCommonOrder(commonOrder: SpecialTestModel) {
        const commonOrders = this.getValue().commonOrders;
        this.update({ commonOrders: commonOrders.map(x => x.id === commonOrder.id ? commonOrder : x) });
    }

    public removeCommonOrder(id: number) {
        this.update({ commonOrders: this.getValue().commonOrders.filter(i => i.id !== id) });
    }

    //#region private

    private static _applyTimeZoneToAppointment(note: NoteModel): NoteModel {
        if (!note.appointment) {
            return note;
        }

        const timezone = timezonesQuery.getMyTimezone();

        const copy = Object.assign({}, note);

        copy.appointment.date = toTimeZoneDate(note.appointment.date, timezone);

        return copy;
    }

    private _groupedVisitNotes(notes: NoteModel[]): NotesGroupModel[] {
        const employeeIds = Array.from(new Set(notes.map(c => c.employee.id)));
        return employeeIds.map(id => ({
            employee: notes.find(c => c.employee.id === id)?.employee,
            notes: notes.filter(c => c.employee.id === id)
        }));
    }

    //#endregion
}

export const notesStore = new NotesStore();
