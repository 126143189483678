import { useState } from "react";
import { insuranceVerificationService } from "../../services/insuranceVerification.service";

interface InsuranceCardHistoryComponentState {
    isVerifyInsuranceOpen: boolean;
    selectedCoverageId: string;
    isRunningVerification: boolean;
}

export function useFacade(): [InsuranceCardHistoryComponentState, Function] {
    const [state, setState] = useState({
        isVerifyInsuranceOpen: false,
        selectedCoverageId: '',
    } as InsuranceCardHistoryComponentState);

    const handleViewDetails = (insuranceVerificationId: number) => {
        insuranceVerificationService.requestInsuranceVerificationDetails(insuranceVerificationId).subscribe(
            (model) => {
                setState(state => ({
                    ...state,
                    isAddButtonLoading: true
                }));
            }
        )
    }

    return [state, handleViewDetails];
}