import React from "react"
import { WildHealthStatusLabel } from "../../../common/components/wildHealthStatusLabel/WildHealthStatusLabel";

interface DnaOrderResultsComponentProps {
    isResulted: boolean;
    isReplaced: boolean;
}

export const DnaOrderResultsComponent: React.FC<DnaOrderResultsComponentProps> = (props: DnaOrderResultsComponentProps) => {
    const { isResulted, isReplaced } = props;

    if (isReplaced) {
        return (
            <WildHealthStatusLabel color={'average1'} px={21}>
                Replacement Ordered
            </WildHealthStatusLabel>
        )
    }

    if (isResulted) {
        return (
            <WildHealthStatusLabel color={'good'}>
                Yes
            </WildHealthStatusLabel>
        )
    } else {
        return (
            <WildHealthStatusLabel color={'bad'}>
                No
            </WildHealthStatusLabel>
        )
    }
}