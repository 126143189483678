import { Box, Table, TableBody, TableContainer, TableHead, IconButton, TableSortLabel, Button, Popover, FormControl } from '@material-ui/core';
import React from 'react';
import { useFacade } from "./syncRecordListComponent.hooks";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import moment from "moment";
import { toCurrentTimeZone } from '../../../timezones/helpers/timezone';
import clsx from 'clsx';
import commonUseStyles from '../../../common/styles/common.styles';
import EditIcon from '@material-ui/icons/Edit';
import { SmallPaginationComponent } from "../../../common/pagination/components/paginationComponent/SmallPaginationComponent";
import { useStyles } from "../syncRecordReconcileComponent/syncRecordReconcileComponent.styles";
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@material-ui/icons/Close';
import { WildHealthDatePicker } from '../../../common/components/WildHealthDatePicker';

const filtersPopoverProps: any = {
    id: "notificationsPopover",
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'right',
    },
};

interface SyncRecordListComponentProps {
  statusId: number;
  isAvailableReconcile: boolean;
  showSearchResult: boolean;
}

export const SyncRecordListComponent: React.FC<SyncRecordListComponentProps> = (props: SyncRecordListComponentProps) => {
  const { statusId, isAvailableReconcile, showSearchResult } = props;
  const [
    {
      isLoading,
      sortingColumns,
      sortingSource,
      syncRecords,
      targetSyncRecordListStatus,
      totalCount,
      selectedPage,
      pageSize,
      isOpen,
      anchorEl,
      filterDate
    },
    handlePageChange,
    handleGotoRecordReconcile,
    handleSorting,
    handleToggleFilter,
    handleApplyFilter
  ] = useFacade(statusId, showSearchResult);

  const classes = useStyles();
  const commonClasses = commonUseStyles();

  const getFilterDate = () => {
    return filterDate
      ? moment(filterDate).format('YYYY-MM-DD')
      : null
  }

  const renderTable = (): JSX.Element => {

    if (isLoading) {
      return (<WildHealthLinearProgress />)
    }

    if (!isLoading && !syncRecords.length) {
      return (
        <Box pt={8}>
          <WildHealthPlaceHolder message="No Sync Records" />
        </Box>
      );
    }

    return (
      <Box mt={2}>
        <TableContainer>
          <Table>
            <TableHead>
              <WildHealthTableRow>
                {
                  sortingColumns.map((item, index) =>
                    <WildHealthTableCell key={index}>
                      {item.source
                        ? <TableSortLabel
                            id={`sorting-sync-records-${item.title}`}
                            active={sortingSource === item.source}
                            direction={sortingSource === item.source ? item.direction : 'asc'}
                            onClick={() => handleSorting(item.source, item.direction)}
                        >
                            {item.title}
                        </TableSortLabel>
                        : item.title
                      }
                    </WildHealthTableCell>
                  )
                }
                {isAvailableReconcile && <WildHealthTableCell style={{ width: '5%' }} />}
              </WildHealthTableRow>
            </TableHead>
            <TableBody>
              {
                syncRecords.map((record, index) =>
                  <WildHealthTableRow key={index}>
                    <WildHealthTableCell>
                      {record.id}
                    </WildHealthTableCell>
                    <WildHealthTableCell>{moment(toCurrentTimeZone(new Date(record.createdAt))).format('MM/DD/YYYY')}</WildHealthTableCell>
                    <WildHealthTableCell>{record.detail}</WildHealthTableCell>
                    {isAvailableReconcile && (
                      <WildHealthTableCell align="left">
                        <IconButton
                          id={`edit-fellow-roster-${record.id}`}
                          onClick={() => handleGotoRecordReconcile(record.id)}
                        >
                          <EditIcon />
                        </IconButton>
                      </WildHealthTableCell>
                    )}
                  </WildHealthTableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
        <Box py={1} px={2}>
          <SmallPaginationComponent
              selectedPage={selectedPage}
              pageSize={pageSize}
              totalCount={totalCount}
              handlePageSelect={handlePageChange} />
        </Box>
      </Box>
    )
  }

  return (
    <Box p="30px">
      <Box mb={2} display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <span className={clsx(commonClasses.size20, commonClasses.textMedium)}>{targetSyncRecordListStatus}</span>
        </Box>
        <Box>
          <Button
            id="records-filter"
            aria-describedby={filtersPopoverProps.id}
            onClick={(event) => handleToggleFilter(event)}
            startIcon={<FilterListIcon />}
            className={commonClasses.whButton}
          >
            {!filterDate ? 'Filter' : `${filterDate}`}
          </Button>
        </Box>
      </Box>
      {
        renderTable()
      }

      <Popover
          id={filtersPopoverProps.id}
          anchorEl={anchorEl}
          onClose={handleToggleFilter}
          open={isOpen}
          anchorOrigin={filtersPopoverProps.anchorOrigin}
          transformOrigin={filtersPopoverProps.transformOrigin}
      >
          <Box py={2} pl={2} display="flex" alignItems="center">
              <Box mr={2} width="250px">
                <FormControl
                  variant="outlined"
                  color='primary'
                  size="small"
                  classes={{ root: classes.root }}
                  fullWidth>
                  <WildHealthDatePicker
                    id={`filterDate`}
                    fullWidth
                    size="small"
                    placeholder="MM/DD/YYYY"
                    format="MM/DD/YYYY"
                    openTo="year"
                    value={getFilterDate()}
                    inputVariant="outlined"
                    views={["year", "month", "date"]}
                    onChange={(momentDate) =>handleApplyFilter(momentDate === null ?
                      "" : momentDate.format("YYYY-MM-DD"))}
                  />
                </FormControl>
              </Box>
              <IconButton id="clear-button" onClick={() => handleApplyFilter('')}>
                <CloseIcon />
              </IconButton>
          </Box>
      </Popover>
    </Box>
  )
}