import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { onEmit } from "../../../common/helpers/on-emit";
import { EmployerProductModel } from "../../models/employerProduct.model";
import { employerProductsService } from "../../services/employerProducts.service";
import { employerProductsQuery } from "../../stores/employerProductsStore";

interface EmployerProfileDetailPageState {
    employer: EmployerProductModel;
    isLoading: boolean;
}

export function useFacade(productId: number): [
    EmployerProfileDetailPageState
] {
    const [state, setState] = useState({
        isLoading: true,
        employer: null,
    } as EmployerProfileDetailPageState);

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<EmployerProductModel>(employerProductsQuery.selectedEmployer$, selectedEmployer => {
                if (selectedEmployer && selectedEmployer.id === productId) {
                    setState(state => ({
                        ...state,
                        employer: selectedEmployer,
                    }));
                }
            }),
        ];

        const cb = () => setState(state => ({ ...state, isLoading: false }));

        employerProductsService.getEmployerProductById(productId).subscribe(cb, cb);

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    };

    useEffect(useEffectCB, []);

    return [ state ];
}