import React from "react";
import {Box, IconButton, useMediaQuery, useTheme} from "@material-ui/core";
import {useStyles} from "./genericPlaygroundComponent.styles";
import {GenericPlaygroundViewState, useFacade} from "./genericPlaygroundComponent.hooks";
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import CloseIcon from '@material-ui/icons/Close';
import {PlaygroundChatListComponent} from "../playgroundChatListComponent/PlaygroundChatListComponent";
import {ConversationType} from "../../models/conversation.models";
import {PlaygroundChaComponent} from "../playgroundChatComponent/PlaygroundChatComponent";
import {MessagesPlaceHolder} from "../../../common/components/wildHealthPlaceHolder/MessagesPlaceHolder";
import {StartGenericPlaygroundComponent} from "../startGenericPlaygroundComponent/StartGenericPlaygroundComponent";


export const GenericPlaygroundComponent: React.FC = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isMiddleScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [
        {
            chatListHidden,
            targetConversation,
            viewState
        },
        handleChatListToggle,
        handleCreateConversation,
        handleConversationCreated,
        handleSelectConversation
    ] = useFacade();

    const renderChatsList = () => {
        return (
            <Box className={classes.chatListRoot} hidden={isMiddleScreen && chatListHidden}>
                <Box className={classes.chatList}>
                    <PlaygroundChatListComponent
                        type={ConversationType.GenericPlayground}
                        targetConversation={targetConversation}
                        handleSelect={handleSelectConversation}
                        handleCreate={handleCreateConversation}
                    />
                </Box>
            </Box>
        )
    }

    const renderChatView = () => {
        switch(viewState) {
            case GenericPlaygroundViewState.Empty:
                return <MessagesPlaceHolder message="Please select thread or start messaging." />
            case GenericPlaygroundViewState.TargetConversation:
                return <PlaygroundChaComponent targetConversation={targetConversation} type={ConversationType.GenericPlayground}/>
            case GenericPlaygroundViewState.NewConversation:
                return <StartGenericPlaygroundComponent handleCreated={handleConversationCreated}/>
        }
    }

    const renderChat = () => {
        return (
            <Box className={classes.chatRoot} hidden={isMiddleScreen && !chatListHidden}>
                <Box className={classes.chat}>
                    {
                        renderChatView()
                    }
                </Box>
            </Box>
        )
    }

    const renderChatListToggleButton = () => {
        return (
            <Box hidden={!isMiddleScreen} className={classes.toggleButton}>
                <IconButton className="wh-tw-text-primaryV" onClick={() => handleChatListToggle()}>
                    {
                        chatListHidden
                            ? <FormatListBulletedIcon/>
                            : <CloseIcon/>
                    }
                </IconButton>
            </Box>
        )
    }

    return (
        <Box display='flex' className={classes.root}>
            {renderChat()}
            {renderChatsList()}
            {renderChatListToggleButton()}
        </Box>
    )
}