import { Box, Table, TableBody, TableContainer, TableHead, TableRow, useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { ExerciseReportTableCell } from "../components/exerciseReportTable/ExerciseReportTableCell";
import { HealthReportSections, HealthReportMode } from "../models/healthReport.models";
import { HealthReportBasePage, HealthReportMobileBasePage } from "./HealthReportBasePage";
import { HealthReportPageProps } from "./healthReportPageProps";

export const HealthReportExerciseAndRecovery4Page: React.FC<HealthReportPageProps> = (props: HealthReportPageProps) => {
    const {
        pageNumber,
        report,
        practice
    } = props;

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const getHR = (percent: number) => Math.round((report.exerciseAndRecoveryReport.exercisePlan.maxHeartRate / 110) * percent);

    const traningZoneData = [
        {
            level: 1,
            zone: "Easy/ Recovery",
            hr1percent: "50.00%",
            hr1value: getHR(50),
            hr2percent: "60.00%",
            hr2value: getHR(60),
            rpe: "1 - 2",
            effort: "Recovery. Should feel easy",
            psyAdapatations: "Recovery. Improves fatigue and soreness"
        },
        {
            level: 2,
            zone: "Endurance",
            hr1percent: "60.00%",
            hr1value: getHR(60),
            hr2percent: "70.00%",
            hr2value: getHR(70),
            rpe: "2 - 3",
            effort: "Long slow distance workouts, strength workouts",
            psyAdapatations: "Increases energy utilization, developing lactate clearance, promotes muscle cell adaptations and bulidng capilary beds"
        },
        {
            level: 3,
            zone: "Endurance",
            hr1percent: "70.00%",
            hr1value: getHR(70),
            hr2percent: "80.00%",
            hr2value: getHR(80),
            rpe: "4 - 5",
            effort: "Tempo efforts. Hard but comfortable",
            psyAdapatations: "Increases oxygen utilization and transportation. Improves energy utilization"
        },
        {
            level: 4,
            zone: "Threshold Intervals",
            hr1percent: "80.00%",
            hr1value: getHR(80),
            hr2percent: "90.00%",
            hr2value: getHR(90),
            rpe: "6 - 7",
            effort: "Anaerobic threshold",
            psyAdapatations: "Improvements in maximum fitness, lung capacity and high speed endurance. Short efforts paired with active recovery"
        },
        {
            level: 5,
            zone: "Intensity Intervals",
            hr1percent: "90.00%",
            hr1value: getHR(90),
            hr2percent: "100.00%",
            hr2value: getHR(100),
            rpe: "8 - 9",
            effort: "3-8 minute intervals, aerobic system, building VO2	",
            psyAdapatations: "Above your onset of blood lactate accumulation or \"OBLA\"., Improves speed and intensity tolerance."
        },
        {
            level: 6,
            zone: "Max Efforts",
            hr1percent: "98.00%",
            hr1value: getHR(98),
            hr2percent: "110.00%",
            hr2value: getHR(110),
            rpe: "10",
            effort: "All out, short intervals (up to 3min), running/skiing economy, Speed sprints and strides.",
            psyAdapatations: "Improves speed and economy of movement"
        }
    ]

    return (
        props.mode !== HealthReportMode.PrintMode && isSmallScreen ? (
            <HealthReportMobileBasePage section={HealthReportSections.ExerciseAndRecovery} pageNumber={pageNumber} practice={practice}>
                <Box pt="30px" width="100%" style={{ borderTop: "2px solid #A9A9A9",  borderBottom: "2px solid #A9A9A9" }}>
                    <Box px={2} pb="10px" className="size-0_875 text-medium" lineHeight="18px">Training zones for cardio: Training levels can be based on Heart as a % of Estimated Max HR, % of Threshold HR, or RPE (rate of perceived exertion)</Box>
                    {
                        traningZoneData.map((data, i) => {
                            return <Box key={`${data.level}-${i}`} style={i !== 0 ? {borderTop: "1px solid #A9A9A9"} : {}}>
                                <Box width={1} px={2} pt={2} display="flex" className="size-0_875 normal-line-height" style={i % 2 === 1 ? {backgroundColor:"#f3f7fa"} : {}}>
                                    <Box width={0.45} className="text-medium">Level</Box>
                                    <Box width={0.55} className="text-light-medium">{data.level}</Box>
                                </Box>
                                <Box width={1} px={2} pt="10px" display="flex" className="size-0_875 normal-line-height" style={i % 2 === 1 ? {backgroundColor:"#f3f7fa"} : {}}>
                                    <Box width={0.45} className="text-medium">Training Zone</Box>
                                    <Box width={0.55} className="text-light-medium">{data.zone}</Box>
                                </Box>
                                <Box width={1} px={2} pt="10px" display="flex" className="size-0_875 normal-line-height" style={i % 2 === 1 ? {backgroundColor:"#f3f7fa"} : {}}>
                                    <Box width={0.45} className="text-medium">% of HRMax</Box>
                                    <Box width={0.55} className="text-light-medium">
                                        <Box width={1} display="flex">
                                            <Box width={0.3}>{data.hr1percent}</Box>
                                            <Box width={0.7} pl="10px" style={{borderLeft: '1px solid #EAEFF5'}}>{data.hr1value}</Box>
                                        </Box>
                                        <Box width={1} display="flex" mt="10px">
                                            <Box width={0.3}>{data.hr2percent}</Box>
                                            <Box width={0.7} pl="10px" style={{borderLeft: '1px solid #EAEFF5'}}>{data.hr2value}</Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box width={1} px={2} pt="10px" display="flex" className="size-0_875 normal-line-height" style={i % 2 === 1 ? {backgroundColor:"#f3f7fa"} : {}}>
                                    <Box width={0.45} className="text-medium">"RPE (on 1-10 Scale)"</Box>
                                    <Box width={0.55} className="text-light-medium">{data.rpe}</Box>
                                </Box>
                                <Box width={1} px={2} pt="10px" display="flex" className="size-0_875 normal-line-height" style={i % 2 === 1 ? {backgroundColor:"#f3f7fa"} : {}}>
                                    <Box width={0.45} className="text-medium">Effort Loads</Box>
                                    <Box width={0.55} className="text-light-medium">{data.effort}</Box>
                                </Box>
                                <Box width={1} px={2} pt="10px" pb={2} display="flex" className="size-0_875 normal-line-height" style={i % 2 === 1 ? {backgroundColor:"#f3f7fa"} : {}}>
                                    <Box width={0.45} className="text-medium">Physiological Adaptations</Box>
                                    <Box width={0.55} className="text-light-medium">{data.psyAdapatations}</Box>
                                </Box>
                            </Box>
                        })
                    }
                </Box>
                <Box mt="30px" pt="30px" width="100%" style={{ borderTop: "2px solid #A9A9A9",  borderBottom: "2px solid #A9A9A9" }}>
                    <Box px={2} pb="10px" className="size-0_875 text-medium" lineHeight="18px">Cardio Sprint Interval Training (SIT)</Box>
                    <Box>
                        <Box width={1} px={2} pt={2} display="flex" className="size-0_875 normal-line-height">
                            <Box width={0.3} className="text-medium">SIT #1</Box>
                            <Box width={0.7} className="text-light-medium">6x 20 sec on 3 min active recovery between intervals</Box>
                        </Box>
                        <Box width={1} px={2} pt="10px" display="flex" className="size-0_875 normal-line-height">
                            <Box width={0.3} className="text-medium">SIT #2</Box>
                            <Box width={0.7} className="text-light-medium">3 x  3 min on/3 min recovery</Box>
                        </Box>
                        <Box width={1} px={2} pt="10px" pb={2} display="flex" className="size-0_875 normal-line-height">
                            <Box width={0.3} className="text-medium">SIT #3</Box>
                            <Box width={0.7} className="text-light-medium">Cardio Intervals pyramid: (On/ Recovery) 1:00/:30 1:30/:30, 2:00/ 1:00, 3:00/1:30, 2:00/1:00, 1:30/30, 1:00/30</Box>
                        </Box>
                    </Box>
                    <Box>
                        <Box width={1} px={2} py={2} display="flex" className="size-0_875 normal-line-height text-medium" style={{borderTop: "1px solid #A9A9A9"}}>Program Notes: running, rowing, biking or swimming. Max effort for each interval with active recovery inbetween</Box>
                    </Box>
                </Box>
                <Box mt="30px" pt="30px" width="100%" style={{ borderTop: "2px solid #A9A9A9",  borderBottom: "2px solid #A9A9A9" }}>
                    <Box px={2} pb="10px" className="size-0_875 text-medium" lineHeight="18px">Calculate HR max</Box>
                    <Box>
                        <Box width={1} px={2} pt={2} display="flex" className="size-0_875 normal-line-height">
                            <Box width={0.4} className="text-medium">Current Age</Box>
                            <Box width={0.6} className="text-light-medium">{report.alphaReport.chronologicalAge}</Box>
                        </Box>
                        <Box width={1} px={2} pt="10px" pb={2} display="flex" className="size-0_875 normal-line-height">
                            <Box width={0.4} className="text-medium">HRMax</Box>
                            <Box width={0.6} className="text-light-medium">{report.exerciseAndRecoveryReport.exercisePlan.maxHeartRate}</Box>
                        </Box>
                    </Box>
                </Box>
            </HealthReportMobileBasePage>
        ) : (
            <HealthReportBasePage section={HealthReportSections.ExerciseAndRecovery} pageNumber={pageNumber} practice={practice}>
                <Box px={10} pt={5}>
                    <Box>
                        <TableContainer>
                            <Table size="small" style={{ border: "2px solid #A9A9A9" }}>
                                <TableHead>
                                    <TableRow>
                                        <ExerciseReportTableCell align="center" colSpan={7}>Training zones for cardio: Training levels can be based on Heart as a % of Estimated Max HR, % of Threshold HR, or RPE (rate of perceived exertion)</ExerciseReportTableCell>
                                    </TableRow>
                                    <TableRow>
                                        <ExerciseReportTableCell align="center">Level</ExerciseReportTableCell>
                                        <ExerciseReportTableCell align="center">Training Zone</ExerciseReportTableCell>
                                        <ExerciseReportTableCell align="center" colSpan={2}>% of HRMax	</ExerciseReportTableCell>
                                        <ExerciseReportTableCell align="center">"RPE (on 1-10 Scale)"</ExerciseReportTableCell>
                                        <ExerciseReportTableCell align="center">Effort Loads</ExerciseReportTableCell>
                                        <ExerciseReportTableCell align="center">Physiological Adaptations</ExerciseReportTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        traningZoneData.map((data, i) => {
                                            return <React.Fragment key={i}>
                                                <TableRow key={i}>
                                                    <ExerciseReportTableCell style={{ width: '6%' }} align="center" rowSpan={2}>{data.level}</ExerciseReportTableCell>
                                                    <ExerciseReportTableCell style={{ width: '12%' }} align="center" rowSpan={2}>{data.zone}</ExerciseReportTableCell>
                                                    <ExerciseReportTableCell style={{ width: '12%' }} align="center">{data.hr1percent}</ExerciseReportTableCell>
                                                    <ExerciseReportTableCell style={{ width: '12%' }} align="center">{data.hr2percent}</ExerciseReportTableCell>
                                                    <ExerciseReportTableCell style={{ width: '12%' }} align="center" rowSpan={2}>{data.rpe}</ExerciseReportTableCell>
                                                    <ExerciseReportTableCell style={{ width: '23%' }} align="center" rowSpan={2}>{data.effort}</ExerciseReportTableCell>
                                                    <ExerciseReportTableCell style={{ width: '23%' }} align="center" rowSpan={2}>{data.psyAdapatations}</ExerciseReportTableCell>
                                                </TableRow>
                                                <TableRow key={`${i}-1`}>
                                                    <ExerciseReportTableCell style={{ width: '12%' }} align="center">{data.hr1value}</ExerciseReportTableCell>
                                                    <ExerciseReportTableCell style={{ width: '12%' }} align="center">{data.hr2value}</ExerciseReportTableCell>
                                                </TableRow>
                                            </React.Fragment>
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Box display="flex" justifyContent="space-between" mt={10}>
                        <Box width="50%">
                            <TableContainer>
                                <Table size="small" style={{ border: "2px solid #A9A9A9" }}>
                                    <TableHead>
                                        <TableRow>
                                            <ExerciseReportTableCell align="center" colSpan={2}>Cardio Sprint Interval Training (SIT)</ExerciseReportTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <ExerciseReportTableCell align="center">SIT #1</ExerciseReportTableCell>
                                            <ExerciseReportTableCell align="center">6x 20 sec on 3 min active recovery between intervals</ExerciseReportTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <ExerciseReportTableCell align="center">SIT #2</ExerciseReportTableCell>
                                            <ExerciseReportTableCell align="center">3 x  3 min on/3 min recovery</ExerciseReportTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <ExerciseReportTableCell align="center">SIT #3</ExerciseReportTableCell>
                                            <ExerciseReportTableCell align="center">Cardio Intervals pyramid: (On/ Recovery) 1:00/:30 1:30/:30, 2:00/ 1:00, 3:00/1:30, 2:00/1:00, 1:30/30, 1:00/30</ExerciseReportTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <ExerciseReportTableCell align="center" colSpan={2}>Program Notes: running, rowing, biking or swimming. Max effort for each interval with active recovery inbetween</ExerciseReportTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                        <Box width="40%">
                            <TableContainer>
                                <Table size="small" style={{ border: "2px solid #A9A9A9" }}>
                                    <TableHead>
                                        <TableRow>
                                            <ExerciseReportTableCell align="center" colSpan={4}>Calculate HR max</ExerciseReportTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <ExerciseReportTableCell align="center">Current Age</ExerciseReportTableCell>
                                            <ExerciseReportTableCell align="center">{report.alphaReport.chronologicalAge}</ExerciseReportTableCell>
                                            <ExerciseReportTableCell align="center">HRMax</ExerciseReportTableCell>
                                            <ExerciseReportTableCell align="center">{report.exerciseAndRecoveryReport.exercisePlan.maxHeartRate}</ExerciseReportTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>
            </HealthReportBasePage>
        )
    )
}