import { Store, StoreConfig } from '@datorama/akita';
import { ConversationAuthorModel, ConversationModelBaseView, ConversationType, PatientConversationModel } from "../../models/conversation.models";

/**
 * Represents Patient Conversations state
 */
export interface PatientConversationsState {
    supportConversations: PatientConversationModel[];
    targetInternalConversation: ConversationModelBaseView[];
    heathCareConversation: PatientConversationModel;
    author: ConversationAuthorModel;
    targetConversation: PatientConversationModel;
    targetHealthcareConversation: ConversationModelBaseView[];
    targetStaffConversation: ConversationModelBaseView[];
    orphanedProxies: any[];
    isLoading: boolean;
    isSendForwardMessage: boolean;
}

/**
 * Creates initial state
 */
export function createInitialState(): PatientConversationsState {
    return {
        supportConversations: [],
        heathCareConversation: null,
        targetInternalConversation: null,
        author: null,
        targetConversation: null,
        targetHealthcareConversation: null,
        targetStaffConversation: null,
        orphanedProxies: [],
        isLoading: true,
        isSendForwardMessage: true
    };
}

/**
 * Provides Patient Conversations states management
 */
@StoreConfig({ name: 'patientConversations', resettable: true })
export class PatientConversationsStore extends Store<PatientConversationsState> {
    constructor() {
        super(createInitialState());
    }

    public addSupportConversation(conversation: PatientConversationModel) {
        this.update({
            supportConversations: [...this.getValue().supportConversations, conversation]
        });
    }

    public setTargetConversation(conversation: PatientConversationModel) {
        this.update({ targetConversation: conversation });
    }

    public updateSupportConversation(conversation: PatientConversationModel): void {
        const state = this.getValue();
        const conversations = state.supportConversations.map(i => i.id === conversation.id ? conversation : i);
        const targetConversation = conversation.id === state.targetConversation?.id ? conversation : state.targetConversation;
        this.update({ supportConversations: conversations, targetConversation: targetConversation });
    }

    public resetConversations(): void {
        this.update({
            supportConversations: [],
            heathCareConversation: null,
            targetConversation: null,
        });
    }

    public addOrphanedProxy(proxy: any): void {
        const orphanedProxies = this.getValue().orphanedProxies;

        this.update({ orphanedProxies: [proxy, ...orphanedProxies] });

        const supportConversations = this.getValue().supportConversations;
        const healthCareConversation = this.getValue().heathCareConversation;
        const matchedSupportConversation = supportConversations.find(c => c && c.vendorExternalId === proxy.sid);

        if (matchedSupportConversation) {
            matchedSupportConversation.proxy = proxy;

            this.updateSupportConversation(matchedSupportConversation);
        }
        if (healthCareConversation && healthCareConversation.vendorExternalId === proxy.sid) {
            healthCareConversation.proxy = proxy;
            this.update({ heathCareConversation: healthCareConversation });
        }
    }

    public setHealthCareConversation(conversation: PatientConversationModel): void {
        this.update({heathCareConversation: conversation});
    }

    public setSupportConversations(conversations: PatientConversationModel[]): void {
        this.update({supportConversations: conversations});
    }

    public setConversationsJoined(conversationsJoined: PatientConversationModel[]): void {
        const state = this.getValue();

        const healthCareConversation = conversationsJoined.filter(i => i.type === ConversationType.HealthCare)[0] ?? state.heathCareConversation;
        const supportConversations = state.supportConversations.map(i => conversationsJoined.find(a => a.id === i.id) ?? i);

        this.update({heathCareConversation: healthCareConversation, supportConversations: supportConversations})
    }

    public setIsLoading(isLoading: boolean): void {
        this.update({isLoading: isLoading});
    }

    public updateConversation(conversation: PatientConversationModel): void {
        if(conversation.type === ConversationType.HealthCare) {
            this.setHealthCareConversation(conversation);
        } else {
            this.updateSupportConversation(conversation);
        }
    }

    public updateSendMessageStatus() {
        this.update({ isSendForwardMessage: !this.getValue().isSendForwardMessage });
    }
}

export const patientConversationsStore = new PatientConversationsStore();