import { Box, IconButton, ListItem } from '@material-ui/core';
import moment from 'moment';
import React, {useEffect, createRef} from 'react';
import { Message, CompactMessage, MyMessage, MyCompactMessage, useStyles } from './messageComponent.styles';
import clsx from 'clsx';
import { MessageAvatarComponent } from "../messageAvatarComponent/MessageAvatarComponent";
import { MessageGroupModel } from "../../models/message.models";
import DescriptionIcon from '@material-ui/icons/Description';
import { employeeConversationsStore } from '../../stores/employeeConversationsStore/employeeConversations.store';
import { conversationsService } from '../../services/conversations.service';
import GetAppIcon from '@material-ui/icons/GetApp';
import { authQuery } from '../../../auth/stores/auth';
import { TextLinkMarkdownComponent } from '../../../notes/components/textMarkdownComponent/TextMarkdownComponent';
import { employeeConversationsQuery } from '../../stores/employeeConversationsStore/employeeConversations.query';
import { profileQuery } from '../../../account/stores/profileStore';

interface MessageComponentView {
    messageGroup: MessageGroupModel;
    isLastMessage?: boolean;
    isMyMessage?: boolean;
    isCompact?: boolean;
    isLoading?: boolean;
    messageToScrollIndex?: number;
}

export const MessageComponentViewForAiDemo: React.FC<MessageComponentView> = (props: MessageComponentView) => {
    const classes = useStyles();

    const scrollToMessageRef = createRef<HTMLDivElement>()
    const {
        messageGroup,
        isMyMessage,
        isLastMessage = false,
        isCompact = false,
        isLoading = false,
        messageToScrollIndex = -1
    } = props;

    const formatDate = (date: Date) => {
        return moment(date).format('HH:mm a');
    }

    const handleDownloadAttachment = (sid: string) => {
        const model = { mediaSid: sid }
        conversationsService.createMediaUrl(model).subscribe((data: string) => {
            window.open(data)
        })
    }

    const getMessageAuthor = (messageGroup:MessageGroupModel) => {
        let result = "";

        if(!!messageGroup.authorFirstName && messageGroup.authorFirstName !== "Unknown"){
            result = result + messageGroup.authorFirstName + " ";
        }
        if(!!messageGroup.authorLastName && messageGroup.authorLastName !== "Unknown"){
            result = result + messageGroup.authorLastName + " ";
        }
        if(!!messageGroup.employeeType){
            result = result + "- "+ messageGroup.employeeType;
        }

        return result;
    }

    useEffect(() => {
        if (authQuery.isLoggedIn() && employeeConversationsQuery.getAttachmentAdding()) {
            messageGroup.messages.forEach(message => {
                if (!message.originalMessage?.body && message.originalMessage?.media) {
                    employeeConversationsStore.addTargetAttachmentsView([{ file: message.originalMessage.media, url: '', fileName: message.originalMessage.media?.filename ?? message.originalMessage.body }]);
                }
            })
        } else {
            employeeConversationsStore.resetAttachmentsView();
        }
    }, [messageGroup, authQuery]);

    useEffect(() => {
        if (!isLoading) {
            scrollToMessage()
        }
    }, [])

    const scrollToMessage = () => {
        scrollToMessageRef?.current?.scrollIntoView({ block: "start",behavior:'smooth'});
    }

    return (
      <>
        <ListItem className={clsx({ [classes.messages]: !isMyMessage, [classes.myMessages]: isMyMessage })}>
            {!isMyMessage && <MessageAvatarComponent firstName="A" lastName="I" image="" isAi={true} />}
            <Box className={isCompact ? classes.compactMessagesBlock : classes.messagesBlock}>
                {
                    messageGroup.messages.map((message, index) => {
                        let formattedMessage: any = message.originalMessage;

                        if (formattedMessage?.body) {

                            const converted = formattedMessage.body as string;
                            const messageIndex = formattedMessage.index as number;
                            formattedMessage = converted.length ? <span className={classes.messageView}>{converted}</span> : converted;
                            const thisMessageMustBeScrolledTo = messageIndex <= messageToScrollIndex;
                            formattedMessage = (
                                <div ref = { thisMessageMustBeScrolledTo ? scrollToMessageRef : null}>
                                  <TextLinkMarkdownComponent content={converted} classLink={isMyMessage ? classes.myMessageLinkUrl : classes.messageLinkUrl} />
                                </div>
                            )

                        } else {
                            const media = formattedMessage.media;

                            formattedMessage = (
                                <Box display='flex' alignItems='center' className={classes.breakText}>
                                    <IconButton
                                        className={clsx(classes.fileButton, { [classes.messageFileButton]: !isMyMessage, [classes.myMessageFileButton]: isMyMessage })}
                                        onClick={() => handleDownloadAttachment(media[0].sid)}
                                        size='small'
                                    >
                                        <DescriptionIcon className={classes.fileIcon} />
                                        <GetAppIcon className={classes.downloadIcon} />
                                    </IconButton>

                                    <Box ml={1}>{media[0].filename ?? formattedMessage.body}</Box>
                                </Box>
                            );

                        }

                        const messageComponent = isMyMessage
                            ? isCompact
                                ? MyCompactMessage
                                : MyMessage
                            : isCompact
                                ? CompactMessage
                                : Message;


                                return (
                                    <>
                                    {
                                        !isMyMessage && 
                                        <span className={classes.messageAuthor}>
                                            { getMessageAuthor(messageGroup) } 
                                        </span>
                                    }
                                    {
                                            React.createElement(messageComponent, {
                                            key: index,
                                            primary: formattedMessage,
                                            secondary: index === messageGroup.messages.length - 1 ? formatDate(message.date) : ''
                                        })
                                    }
                                    </>
                                )
                    })
                }
            </Box>
            {isMyMessage && <MessageAvatarComponent firstName={messageGroup.authorFirstName} lastName={messageGroup.authorLastName} image={profileQuery.getPhoto(messageGroup.employeeId)} />}
        </ListItem>
        {isLoading && (
            <>
                {
                    messageGroup.messages.map((message, index) => {
                      let formattedMessage: any = message.originalMessage;
                          const messageIndex = formattedMessage.index as number;
                          const thisMessageMustBeScrolledTo = messageIndex == messageToScrollIndex;
                        if (!isLastMessage) return <></>

                        return (
                          <ListItem className={classes.messages}>
                              <MessageAvatarComponent firstName="A" lastName="I" image="" isAi={true} marginBottom={1} />
                              <Box className={isCompact ? classes.compactMessagesBlock : classes.messagesBlock}>
                                  {
                                      React.createElement(CompactMessage, {
                                          key: index,
                                          primary: (
                                            <div ref = { thisMessageMustBeScrolledTo ? scrollToMessageRef : null} className="loader">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                          ),
                                          secondary: ''
                                      })
                                  }
                            </Box>
                        </ListItem>
                        )
                    })
                }
            </>
        )}
      </>
    )
};
