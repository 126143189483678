import { Box, Grid, Table, TableBody, TableContainer, TableHead } from '@material-ui/core';
import React from 'react';
import { useFacade } from './labOrderInvoiceComponent.hooks';
import { useStyles } from './labOrderInvoiceComponent.styles';
import { WildHealthTableRow } from '../../../common/components/wildHealthTable/WildHealthTableRow';
import { WildHealthTableCell } from '../../../common/components/wildHealthTable/WildHealthTableCell';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { WildHealthHeaderComponent } from '../../../common/components/wildHealthHeaderComponent/WildHealthHeaderComponent';
import { BackTitleComponent } from '../../../../components/pageTitleComponent/PageTitleComponent';
import { addOnProvidersImages, addOnProvidersNames } from '../../../addons/models/addOns.models';
import { displayMoney } from '../../../common/helpers/display-money';
import moment from 'moment';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import { toCurrentTimeZone } from '../../../timezones/helpers/timezone';

interface LabOrderInvoiceComponentProps {
    patientId: number;
    orderId: number;
    handleNavigateToOrders: () => void;
}

export const LabOrderInvoiceComponent: React.FC<LabOrderInvoiceComponentProps> = (props: LabOrderInvoiceComponentProps) => {
    const { patientId, orderId, handleNavigateToOrders } = props;

    const classes = useStyles();

    const [
        {
            isLoading,
            invoice,
            total,
        },
        columns,
        handleExportInvoice,
    ] = useFacade(patientId, orderId);

    const renderTable = () => {

        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <WildHealthTableRow>
                        {
                            columns.map((item, index) =>
                                <WildHealthTableCell key={index} align={item.alignment}>
                                    {item.title}
                                </WildHealthTableCell>
                            )
                        }
                        </WildHealthTableRow>
                    </TableHead>
                    <TableBody>
                    {
                        invoice.items.map((item, index) =>
                            <WildHealthTableRow key={index}>
                                <WildHealthTableCell align="left">
                                    {item.sku}
                                </WildHealthTableCell>
                                <WildHealthTableCell align="left">
                                    {item.description}
                                </WildHealthTableCell>
                                <WildHealthTableCell align="right">
                                    {displayMoney(item.price, '$')}
                                </WildHealthTableCell>
                            </WildHealthTableRow>
                        )
                    }
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const renderContent = () => {

        if (isLoading) {
            return <WildHealthLinearProgress/>;
        }

        return (
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
            >
                <Box className={classes.root}>
                    <Box display='flex' justifyContent='space-between' alignItems='center' my={2}>
                        <Box className={classes.title}>Invoice</Box>
                        <Box>
                            <WildHealthButton
                                id="lab-order-invoice-export"
                                onClick={() => handleExportInvoice()}
                            >
                                Export
                            </WildHealthButton>
                        </Box>
                    </Box>

                    <Box className={classes.invoice}>
                        <Box display='flex' justifyContent='space-between'>
                            <Box>
                                <Box className={classes.invoiceTitle}>Invoice</Box>
                                <Box className={classes.invoiceSubTitle}>Lab Order Summary</Box>
                                <Box display='flex' justifyContent='space-between'>
                                    <Box mr={1}>
                                        <Box mt={4} className={classes.companyTitle}>Invoice Date</Box>
                                        <Box className={classes.companySubTitle}>{moment(toCurrentTimeZone(invoice.orderedAt)).format('MM/DD/YYYY')}</Box>
                                    </Box>

                                    <Box>
                                        <Box mt={4} className={classes.companyTitle}>Paid Date</Box>
                                        <Box className={classes.companySubTitle}>
                                        {
                                            invoice.paidAt ? moment(toCurrentTimeZone(invoice.paidAt)).format('MM/DD/YYYY') :
                                            'Not Paid yet'
                                        }
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                            <Box>
                                <Box mb={2}>
                                    <img src={addOnProvidersImages.get(invoice.provider)} alt="img"/>
                                </Box>

                                <Box className={classes.companyTitle}>Lab Company</Box>
                                <Box className={classes.companySubTitle}>{addOnProvidersNames.get(invoice.provider)}</Box>
                            </Box>
                        </Box>

                        {renderTable()}

                        <Box className={classes.total} display='flex' justifyContent='flex-end'>
                            <Box pr={3}>Total</Box>
                            <span>{displayMoney(total, '$')}</span>
                        </Box>
                    </Box>
                </Box>
            </Grid>
        )
    }

    const title = (
        <BackTitleComponent
            title='Order Invoice'
            actionSize='small'
            action={handleNavigateToOrders}
        />
    )

    return (
        <WildHealthHeaderComponent title={title}>
            {renderContent()}
        </WildHealthHeaderComponent>
    );
}
