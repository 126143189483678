import {
    CreatePatientAppointmentDialogComponent
} from '../createPatientAppointmentDialog/CreatePatientAppointmentDialogComponent';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { WildHealthPlaceHolderBase } from '../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolderBase';
import { AppointmentsSummaryModel, AppointmentTypeModel, PatientAppointmentModel } from '../../models/appointments.models';
import { AppointmentStatus, AppointmentWithType } from '../../models/appointments.enums';
import { AppointmentCardComponent } from '../appointmentCard/AppointmentCardComponent';
import { LocationModel } from '../../../locations/models/locations.models';
import { EmployeeModel } from '../../../employee/models/employee.models';
import { Box, Link, Typography } from '@material-ui/core';
import React from 'react';
import { TimeZoneModel } from "../../../timezones/models/timezone.model";
import { EmployeeType, employeeTypesNames, employeeTypesNamesForAppointment } from '../../../employee/models/employee.enums';
import { colors } from '../../../common/constants/colors';
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from 'clsx';
import InfoIcon from '@material-ui/icons/InfoRounded';
import { authQuery } from '../../../auth/stores/auth';
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import { FeatureComponent } from "../../../common/components/featureFlags/FeatureComponent";
import { WildHealthClickableTooltip } from '../../../common/components/WildHealthClickableTooltip/WildHealthClickableTooltip';
import {getAvailableAppointmentType, getAvailableWithTypes} from '../../helpers/appointmentHelper';
import { implicitUnlimitedAmount } from '../../constants/appointment.constants'
import { SubscriptionModel, SubscriptionStatus, SubscriptionType } from '../../../payment/models/subscription.models';

interface AppointmentCardsComponentProps {
    isLoading: boolean;
    personType: EmployeeType;
    withTypes: AppointmentWithType[];
    appointments: PatientAppointmentModel[];
    appointmentTypes: AppointmentTypeModel[];
    handleAddAppointmentToCalendar: (appointmentId: number) => void;
    handleCancelAppointment: (appointmentId: number) => void;
    handleEditAppointment: (appointmentId: number) => void;
    handleJoinAppointment: (joinLink: string) => void;
    handleBuyProducts: () => void;
    assignedEmployees: EmployeeModel[];
    locations: LocationModel[];
    timeZone: TimeZoneModel;
    canAccept?: boolean;
    patientId?: number | null;
    isBuyProductsLink: boolean;
    subscription: SubscriptionModel;
    appointmentsSummary: AppointmentsSummaryModel;
}

export const MobileAppointmentCardsComponent: React.FC<AppointmentCardsComponentProps> = (props: AppointmentCardsComponentProps) => {
    MobileAppointmentCardsComponent.defaultProps = {
        canAccept: false
    };

    const {
        isLoading,
        subscription,
        appointmentsSummary,
        personType,
        withTypes,
        appointments,
        appointmentTypes,
        handleAddAppointmentToCalendar,
        handleCancelAppointment,
        handleEditAppointment,
        handleJoinAppointment,
        handleBuyProducts,
        assignedEmployees,
        locations,
        timeZone,
        patientId = null,
        isBuyProductsLink = false,
    } = props;

    const commonClasses = commonUseStyles();

    const customTooltipClasses = {
        tooltip: commonClasses.tooltip,
        arrow: commonClasses.arrow,
        tooltipPlacementTop: commonClasses.tooltipPlacement,
        tooltipPlacementBottom: commonClasses.tooltipPlacement,
    }

    const availableWithTypes = getAvailableWithTypes(appointmentTypes);
    const availableTypes = appointmentTypes.filter(x => x.configurations.some(t => availableWithTypes.includes(t.withType)));
    const targetWithType = availableWithTypes.find(x => withTypes.includes(x));

    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const availableType = getAvailableAppointmentType(appointmentTypes, targetWithType);

    const getAppointmentsByWithType = (withTypes: AppointmentWithType[], appointments: PatientAppointmentModel[]): PatientAppointmentModel[] => {
        return appointments
            .filter(x => x.status !== AppointmentStatus.Canceled
                && withTypes.includes(x.withType));
    }

    const denomenator = appointmentsSummary?.availableCoachAppointments;

    const targetAppointments = getAppointmentsByWithType(withTypes, appointments);

    const isCanCreate = (!targetAppointments || !targetAppointments.length) && personType === EmployeeType.HealthCoach ? denomenator > 0 : true;

    const employee = assignedEmployees?.find(el => el.role.displayName === employeeTypesNames.get(personType))

    const renderCoachVisitsInfo = () => {
        const hasHealthCoachingVisits = denomenator > 0
        const unlimitedVisits = denomenator >= implicitUnlimitedAmount;

        return <Box display="flex" alignItems="center" className={commonClasses.size14}>
            { hasHealthCoachingVisits &&       
                <Box
                    minWidth="48px"
                    fontWeight="bold"
                    borderRadius="2px"
                    maxHeight="30px"
                    px={1} py={0.5}
                    mr={1}
                    className={clsx(commonClasses.backgroundColorAccent2)}
                >  
                    {appointmentsSummary?.completedCoachAppointments}/
                    {unlimitedVisits
                        ? <>&infin;</>
                        : denomenator
                    }
                </Box>
            }  
            { hasHealthCoachingVisits
                ? <Box>Your plan includes {unlimitedVisits === true ? 'unlimited' : denomenator} Health Coach {denomenator === 1 ? 'Session' : 'Sessions'}!</Box>
                : <Box>No Health Coaching visits available, Reach out to your Health Coach if you have any questions</Box>
            }
            
        </Box>
    }

    const canPurchaseVisitCredits = () => {
        const providerSectionWithTypes = [
            AppointmentWithType.HealthCoachAndProvider,
            AppointmentWithType.Provider
        ];

        const availableWithTypes = getAvailableWithTypes(appointmentTypes);
        const targetWithType = availableWithTypes.find(x => providerSectionWithTypes.includes(x));
        const availableType = getAvailableAppointmentType(appointmentTypes, targetWithType);

        return availableType && isBuyProductsLink
    }

    const renderProviderVisitsInfo = () => {
        return <>
            <Box display="flex" alignItems="center" mb={1}>
                <Box
                    minWidth="48px"
                    maxHeight="30px"
                    fontWeight="bold"
                    borderRadius="2px"
                    px={1} py={0.5}
                    mr={1}
                    className={clsx(commonClasses.backgroundColorAccent2)}
                >{appointmentsSummary?.completedProviderMembershipVisits}/{appointmentsSummary?.availableProviderMembershipVisits}
                </Box>
                <Box mr={1}>Included Visits Used</Box>
                <Box className={commonClasses.colorGray2}>
                    <Box display="flex" alignItems="center">
                        <WildHealthClickableTooltip
                            tooltipContent={`Your membership includes ${appointmentsSummary?.availableProviderMembershipVisits} ${appointmentsSummary?.availableProviderMembershipVisits === 1 ? 'visit' : 'visits'} every year. Additional visits can be purchased below.`}
                            isOpened={open}
                            handleClose={handleTooltipClose}
                            customClasses={customTooltipClasses}
                        >
                            <InfoIcon className={commonClasses.notificationIcon} onClick={handleTooltipOpen} />
                        </WildHealthClickableTooltip>
                    </Box>
                </Box>
            </Box>
            <Box display="flex" alignItems="center" mb={1}>
                <Box
                    minWidth="48px"
                    maxHeight="30px"
                    fontWeight="bold"
                    borderRadius="2px"
                    px={1} py={0.5}
                    mr={1}
                    className={clsx(commonClasses.backgroundColorAccent2)}
                >{appointmentsSummary?.completedAdditionalProviderVisits}/{appointmentsSummary?.availableAdditionalProviderVisits}
                </Box>
                <Box>Visit Credits Used</Box>
            </Box>
            {canPurchaseVisitCredits() && <Link
                color={colors.main}
                id='purchase-visit-credits'
                onClick={() => handleBuyProducts()}
            >
                <Typography display='inline'>
                    <span className={clsx(commonClasses.colorMain, commonClasses.size14)}>  Purchase Visit Credits </span>
                </Typography>
            </Link>}
        </>
    }

    const renderProviderVisitsInfoWithInsurense = () => {
        return <Box display="flex" alignItems="center">
        <Box
            minWidth="48px"
            fontWeight="bold"
            borderRadius="2px"
            maxHeight="30px"
            px={1} py={0.5}
            mr={1}
            className={clsx(commonClasses.backgroundColorAccent2)}
        >{appointmentsSummary?.completedAdditionalProviderVisits}
        </Box>
        <Box>Completed Provider Visits</Box>
    </Box>
    }

    const renderAppointmentInfo = () => {
        return <Box mt={3} px={4} py={2}
            className={clsx(commonClasses.backgroundColorLightGray, commonClasses.boxShadow, commonClasses.backgroundColorLightGray)}>
            <Box pb={1} fontWeight='bold'>
                <span className={commonClasses.size18}>
                    {`${employeeTypesNamesForAppointment.get(personType)} ${personType === EmployeeType.HealthCoach ? 'Sessions' : 'Visits'}`}
                </span>
            </Box>
            {!authQuery.isLicensingPractice() &&
                <FeatureComponent featureFlag={FeatureFlag.PatientProduct}>
                    <Box minHeight="80px" display="flex" flexDirection="column" justifyContent="center"
                        className={commonClasses.size14}>
                        {personType === EmployeeType.HealthCoach
                            ? renderCoachVisitsInfo()
                            : subscription?.type === SubscriptionType.Insurance && subscription?.status === SubscriptionStatus.Active
                                ? renderProviderVisitsInfoWithInsurense()
                                : renderProviderVisitsInfo()
                        }
                    </Box>
                </FeatureComponent>}
        </Box>
    }

    const renderAppointments = (withTypes: AppointmentWithType[], personType: EmployeeType) => {
        const targetAppointments = getAppointmentsByWithType(withTypes, appointments);

        const emptyMessage = `No upcoming ${employeeTypesNamesForAppointment.get(personType)} ${personType === EmployeeType.HealthCoach ? 'Sessions' : 'Visits'}`;

        if (!targetAppointments || !targetAppointments.length) {
            return (
                <>{renderAppointmentInfo()}
                    <Box px={3}>
                        <WildHealthPlaceHolderBase message={emptyMessage} />
                    </Box>
                </>
            );
        }

        return (
            <>
                {renderAppointmentInfo()}
                {
                    targetAppointments.map((appointment, i) => (
                        <Box mt='20px' key={i}>
                            <AppointmentCardComponent
                                handleAddAppointmentToCalendar={handleAddAppointmentToCalendar}
                                handleCancelAppointment={handleCancelAppointment}
                                handleEditAppointment={handleEditAppointment}
                                handleJoinAppointment={handleJoinAppointment}
                                appointment={appointment}
                                timeZone={timeZone}
                            />
                        </Box>
                    ))
                }
            </>
        )
    }

    if (isLoading || !appointments) {
        return (<WildHealthLinearProgress />);
    }

    return (
        <Box width={1}>
            {
                renderAppointments(withTypes, personType)
            }
            {
                targetWithType && availableType && isCanCreate && employee &&
                <CreatePatientAppointmentDialogComponent
                    locations={locations}
                    assignedEmployees={assignedEmployees}
                    appointmentTypes={availableTypes}
                    appointmentWithType={targetWithType}
                    timeZone={timeZone}
                    patientId={patientId}
                />
            }
        </Box>
    );
}
