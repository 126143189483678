import React from 'react';
import {Box} from '@material-ui/core';
import {PageSliderComponent} from "../../../../common/components/PageSliderComponent";
import {QuestionnaireSurveyResultProps} from "./questionnaireSurveyResultProps";
import {InitialQuestionNames} from "../../../models/questionnaire.models";
import {QuestionnairesResultListComponent} from "../../questionnairesResultListComponent/QuestionnairesResultListComponent";

export const ExerciseSurveyResult: React.FC<QuestionnaireSurveyResultProps> = (props: QuestionnaireSurveyResultProps) => {
    const {
        result,
        gender,
        questionnaire,
        navigationContext
    } = props;

    return (
        <>
            <QuestionnairesResultListComponent
                gender={gender}
                questionnaire={questionnaire}
                result={result}
                questions={[
                    InitialQuestionNames.PRIMARY_EXERCISES,
                    InitialQuestionNames.EXERCISE_TYPE,
                    InitialQuestionNames.EXERCISE_FREQUENCY,
                    InitialQuestionNames.EXERCISE_PROGRAM_FOR,
                    InitialQuestionNames.EXERCISE_LIMITATIONS,
                    InitialQuestionNames.MAX_PUSH_UPS,
                    InitialQuestionNames.MAX_BUR_PRESS_1_MIN
                ]}
                title="Exercise:"
            />
            <Box pt={5}>
                <PageSliderComponent context={navigationContext}/>
            </Box>
        </>
    )
}
