import {
    Box,
    Checkbox,
    CircularProgress, Collapse,
    Dialog,
    DialogActions,
    DialogContent, Divider,
    FormControl,
    FormControlLabel, FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@material-ui/core";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import React from 'react';
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { displayLocation } from "../../../locations/helpers/displayLocation";
import { useFacade } from "./updateEmployeeComponent.hooks";
import { useStyles } from "./updateEmployeeComponent.styles";
import { PermissionType, UserType } from "../../../auth/models/auth.enums";
import { ProtectedElement } from "../../../common/components/ProtectedElement";
import { WildHealthLinkButton } from "../../../common/components/WildHealthLinkButton";
import commonUseStyles from "../../../common/styles/common.styles";
import { isProviderRole } from "../../../common/constants/roles";
import { Gender } from "../../../common/models/user.models";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

export interface UpdateEmployeeButtonComponentProps {
    employeeId: number;
    isOpen: boolean;
    selfManagingEmployee: boolean;
    handleToggleDialog: (value: boolean) => void;
}

export const UpdateEmployeeComponent: React.FC<UpdateEmployeeButtonComponentProps> = (props: UpdateEmployeeButtonComponentProps) => {
    const { employeeId, isOpen, selfManagingEmployee, handleToggleDialog } = props;
    const classes = useStyles();
    const [
        {
            employee,
            roles,
            permissions,
            rxntExpanded,
            locations,
            errors,
            showSchedulerId,
            isSubmitted
        },
        handleSelectLocation,
        handleSelectRole,
        handlePermissionChange,
        handleChanges,
        handleUpdate,
        handleSchedulerAccountIdChanges,
        toggleShowSchedulerId,
        toggleRxNT
    ] = useFacade(employeeId, isOpen, selfManagingEmployee, handleToggleDialog);

    const commonClasses = commonUseStyles();

    const renderSelectedLocations = (selected: number[]) => {
        return selected.map(id => {
            const location = locations.find(x => x.id === id);
            return location
                ? displayLocation(location)
                : ''
        }).filter(x => x && x.length).join(', ')
    }

    const hasSchedulerAccountId = employee.schedulerAccountId?.length;

    return (
        <>
            <Dialog
                onClose={() => handleToggleDialog(false)}
                className={classes.root}
                open={isOpen}
            >
                <MuiDialogTitle
                    className={classes.dialogTitle}
                    disableTypography
                >
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <Typography variant="h6">Edit User</Typography>
                    </Grid>
                    <IconButton
                        id="update-employee-edit-user"
                        className={classes.dialogTitleCloseAction}
                        onClick={() => handleToggleDialog(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                </MuiDialogTitle>

                <DialogContent dividers>
                    <Box mb={1}>
                        <TextField
                            required
                            fullWidth
                            size="small"
                            variant="outlined"
                            value={employee.firstName}
                            label="First Name"
                            className={clsx(classes.field, 'input')}
                            error={!!errors['firstName']}
                            helperText={errors['firstName']}
                            onChange={(event) => handleChanges('firstName', event.target.value)}
                        />
                        <TextField
                            required
                            fullWidth
                            size="small"
                            variant="outlined"
                            value={employee.lastName}
                            label="Last Name"
                            className={clsx(classes.field, 'input')}
                            error={!!errors['lastName']}
                            helperText={errors['lastName']}
                            onChange={(event) => handleChanges('lastName', event.target.value)}
                        />
                        <FormControl
                            error={!!errors['locationIds']}
                            color='primary'
                            className={classes.field}
                            variant="outlined"
                            size="small">
                            <InputLabel className={classes.selectLabel}>
                                Pod
                            </InputLabel>
                            <ProtectedElement
                                userType={UserType.Employee}
                                element={
                                    <Select
                                        multiple
                                        label="Location"
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                        }}
                                        value={employee?.locationIds ?? []}
                                        onChange={handleSelectLocation}
                                        error={!!errors['locationIds']}
                                        renderValue={renderSelectedLocations}>
                                        {
                                            locations
                                                .map((location, index) =>
                                                    <MenuItem key={index} value={location.id}>
                                                        <Checkbox
                                                            className={classes.permissionCheck}
                                                            checked={employee?.locationIds?.includes(location.id)}
                                                            color="default"
                                                        />
                                                        {
                                                            displayLocation(location)
                                                        }
                                                    </MenuItem>
                                                )
                                        }
                                    </Select>
                                }
                                defaultContent={
                                    <Select multiple value={employee?.locationIds ?? []} disabled={true}>
                                        {
                                            locations
                                                .map((location, index) =>
                                                    <MenuItem key={index} value={location.id}>
                                                        {
                                                            displayLocation(location)
                                                        }
                                                    </MenuItem>
                                                )
                                        }
                                    </Select>
                                }
                                permissions={[PermissionType.MigrationBetweenLocations]} />
                            <FormHelperText>{errors[`locationIds`]}</FormHelperText>
                        </FormControl>
                        <TextField
                            size="small"
                            variant="outlined"
                            value={employee.credentials}
                            label="Credentials"
                            className={clsx(classes.field, 'input')}
                            error={!!errors['credentials']}
                            helperText={errors['credentials']}
                            onChange={(event) => handleChanges('credentials', event.target.value)}
                        />
                        {isProviderRole(employee.roleId) && (
                            <TextField
                                fullWidth
                                size="small"
                                variant="outlined"
                                value={employee.npi}
                                label="NPI"
                                className={clsx(classes.field, 'input')}
                                error={!!errors['npi']}
                                helperText={errors['npi']}
                                onChange={(event) => handleChanges('npi', event.target.value)}
                            />
                        )}
                    </Box>
                    <Box mb={1}>
                        <FormControl
                            className={classes.field}
                            color='primary'
                            variant="outlined"
                            size="small"
                        >
                            <InputLabel
                                className={classes.selectLabel}
                            >
                                Role
                            </InputLabel>
                            <Select
                                value={employee?.roleId}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                                onChange={handleSelectRole}
                            >
                                {
                                    roles &&
                                    roles
                                        .map((item, index) =>
                                            <MenuItem key={index} value={item.id}>
                                                {item.displayName}
                                            </MenuItem>
                                        )
                                }
                            </Select>
                        </FormControl>
                        <TextField
                            size="small"
                            variant="outlined"
                            value={employee.email}
                            label="Email"
                            className={clsx(classes.field, 'input')}
                            error={!!errors['email']}
                            helperText={errors['email']}
                            onChange={(event) => handleChanges('email', event.target.value)}
                        />
                    </Box>
                    <Box mb={1}>
                        <FormControl
                            className={classes.field}
                            color='primary'
                            variant="outlined"
                            size="small"
                        >
                            <InputLabel
                                className={classes.selectLabel}
                            >
                                Gender
                            </InputLabel>
                            <Select
                                value={employee.gender}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                                onChange={(event) => handleChanges('gender', event.target.value)}
                            >
                                <MenuItem value={Gender.Male}>{Gender[Gender.Male]}</MenuItem>
                                <MenuItem value={Gender.Female}>{Gender[Gender.Female]}</MenuItem>
                                <MenuItem value={Gender.None}>{Gender[Gender.None]}</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box ml={1} pb={2}>
                        <WildHealthLinkButton
                            id='show-scheduler-id'
                            onClick={toggleShowSchedulerId}
                            content={
                                hasSchedulerAccountId
                                    ? <Box className={commonClasses.colorGood}>
                                        The Account is synchronized with scheduler system
                                    </Box>
                                    : <Box className={commonClasses.colorGray1}>
                                        The Account is not synchronized with scheduler system
                                    </Box>
                            }
                        />
                    </Box>
                    {
                        showSchedulerId &&
                        <Box pb={1}>
                            <TextField
                                fullWidth
                                size="small"
                                variant="outlined"
                                value={employee.schedulerAccountId}
                                label="Scheduler System Id"
                                className={clsx(classes.fullWidthField, 'input')}
                                placeholder="Paste Url or Id from Timekit"
                                error={!!errors['schedulerAccountId']}
                                helperText={errors['schedulerAccountId']}
                                onChange={(event) => handleSchedulerAccountIdChanges(event.target.value)}
                            />
                        </Box>
                    }

                    <Divider/>
                    <Box display="flex" justifyContent="flex-start" alignItems="center" py={2}>
                        <Box id={`rxnt-credentials-section-expand`} onClick={() => toggleRxNT()} className="wh-tw-cursor-pointer">
                            {rxntExpanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                        </Box>
                        <Box ml={1}>
                            <span className={classes.sectionTitle}>RxNT credentials</span>
                        </Box>
                    </Box>
                    <Collapse in={rxntExpanded} timeout="auto" unmountOnExit>
                        <Box mb={1}>
                            <TextField
                                required
                                fullWidth
                                size="small"
                                variant="outlined"
                                value={employee.rxntUserName}
                                label="RxNT Login"
                                autoComplete='false'
                                className={clsx(classes.field, 'input')}
                                error={!!errors['rxntUserName']}
                                helperText={errors['rxntUserName']}
                                onChange={(event) => handleChanges('rxntUserName', event.target.value)}
                            />
                            <TextField
                                required
                                fullWidth
                                size="small"
                                variant="outlined"
                                type="password"
                                autoComplete='false'
                                value={employee.rxntPassword}
                                label="RxNT Password"
                                className={clsx(classes.field, 'input')}
                                error={!!errors['rxntPassword']}
                                helperText={errors['rxntPassword']}
                                onChange={(event) => handleChanges('rxntPassword', event.target.value)}
                            />
                        </Box>
                    </Collapse>
                    <Divider/>

                    <Box mt={1} ml={1} mr={1} mb={5}>
                        <Box>
                            <span className={classes.sectionTitle}>Permissions</span>
                        </Box>
                        {
                            permissions &&
                            permissions
                                .map((item, index) => {
                                    const control = (
                                        <Checkbox
                                            className={classes.permissionCheck}
                                            checked={employee?.permissions?.find(i => i === item.id) != null}
                                            onChange={handlePermissionChange}
                                            color="default"
                                        />
                                    );

                                    return (
                                        <FormControlLabel
                                            className={classes.permission}
                                            control={control}
                                            value={item.id}
                                            label={item.displayName}
                                            key={index}
                                        />
                                    );
                                })
                        }
                    </Box>
                </DialogContent>

                <DialogActions>
                    <WildHealthButton
                        id="update-employee-cancel"
                        onClick={() => handleToggleDialog(false)}
                        color="tertiary"
                        disabled={isSubmitted}
                    >
                        Cancel
                    </WildHealthButton>
                    <WildHealthButton
                        id="update-employee-update"
                        onClick={handleUpdate}
                        autoFocus
                        disabled={isSubmitted}
                    >
                        {
                            isSubmitted
                                ? <CircularProgress size={24} className={classes.progress} />
                                : ' Update'
                        }
                    </WildHealthButton>
                </DialogActions>
            </Dialog>
        </>
    );
}
