import { useEffect, useState } from "react";
import { PaymentPlanModel } from "../../models/paymentPlan.models";
import { PaymentPeriodModel } from "../../models/paymentPeriod.models";
import { offerPaymentPlanComponentValidator } from "./offerPaymentPlanComponent.validator";
import { IErrorState } from "../../../common/validation/error-state";
import { Subscription } from "rxjs";
import { onEmit } from "../../../common/helpers/on-emit";
import { paymentPlansQuery } from "../../stores/paymentPlansStore";
import { paymentPlansService } from "../../services/paymentPlans.service";
import { authQuery } from "../../../auth/stores/auth";

export interface OfferPaymentPlanComponentState extends IErrorState {
    paymentPlans: PaymentPlanModel[];
    selectedPlan: PaymentPlanModel;
    selectedPeriod: PaymentPeriodModel;
}

const defaultInitialState: OfferPaymentPlanComponentState = {
    paymentPlans: [],
    selectedPlan: null,
    selectedPeriod: null,
    errors: {}
}

export function useFacade(handleSubmit: Function, handleClose: Function, initialState = defaultInitialState): [OfferPaymentPlanComponentState, Function, Function, Function, Function] {
    const [state, setState] = useState(initialState);

    const handlePlanSelect = (planId: number) => {
        const plan = state.paymentPlans.find(x => x.id === planId);
        offerPaymentPlanComponentValidator.validateAndSetState(state, setState, 'selectedPlan', plan);

        if (plan) {
            const period = state.selectedPlan?.id === plan.id
                ? state.selectedPeriod
                : null;

            setState({
                ...state,
                selectedPlan: plan,
                selectedPeriod: period
            });
        }
    }

    const handlePeriodSelect = (periodId: number) => {
        const period = state.selectedPlan?.periods.find(x => x.id === periodId);
        offerPaymentPlanComponentValidator.validateAndSetState(state, setState, 'selectedPeriod', period);

        if (period) {
            setState({ ...state, selectedPeriod: period });
        }
    }

    const handleCloseDialog = () => {
        handleClose();
        setState({
            ...state,
            selectedPlan: null,
            selectedPeriod: null,
            errors: {}
        });
    }

    const handleSubmitForm = (event: Event) => {
        event.preventDefault();

        offerPaymentPlanComponentValidator.validateObjectAndSetState(state, setState, state);
        if (offerPaymentPlanComponentValidator.stateIsValid(state)) {
            handleSubmit(state.selectedPlan, state.selectedPeriod);
            handleCloseDialog();
        }
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<PaymentPlanModel[]>(paymentPlansQuery.proposedPlans$, plans => {
                setState(state => ({
                    ...state,
                    paymentPlans: plans,
                }));
            })
        ];

        paymentPlansService.getActive(authQuery.getPracticeId()).subscribe();

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [state, handlePlanSelect, handlePeriodSelect, handleSubmitForm, handleCloseDialog];
}
