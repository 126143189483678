import { onEmit } from "../../../common/helpers/on-emit";
import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { IErrorState } from "../../../common/validation/error-state";
import { CreateFellowModel } from "../../../employee/models/employee.models";
import { BaseCourseModel } from '../../models/courses.model';
import { coursesQuery } from "../../stores/courses";
import { coursesService } from "../../services/courses.service";

interface AddFellowButtonComponentState extends IErrorState {
  isLoading: boolean;
  isOpen: boolean;
  fellow: CreateFellowModel;
  courses: BaseCourseModel[];
  selectedCourses: BaseCourseModel[];
}

const initModel: CreateFellowModel = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  credentials: '',
  rosterId: 0
};

export function useFacade(patientId: number): [
  AddFellowButtonComponentState,
  Function,
  () => void,
  (status: boolean) => void
] {

  const [state, setState] = useState({
    isLoading: false,
    isOpen: false,
    fellow: Object.assign({}, initModel),
    errors: {},
    courses: [],
    selectedCourses: [],
  } as AddFellowButtonComponentState);

  const handleCoursesSelect = (courses) => {
    setState(state => ({ ...state, selectedCourses: courses }));
  }

  const handleToggle = (status: boolean) => {
    setState(state => ({
      ...state,
      isOpen: status,
    }));
  }

  const handleAssign = () => {
    if (!state.selectedCourses.length && !patientId) return;
    setState(state => ({
      ...state,
      isLoading: true,
    }));

    state.selectedCourses.map(c => {
      coursesService.assignCourseByPatientId(patientId, c.id).subscribe();
    })

    setState(state => ({
      ...state,
      isLoading: false,
      isOpen: false,
    }));
  }

  const useEffectCB = () => {
    const subscriptions: Subscription[] = [
      onEmit<BaseCourseModel[]>(coursesQuery.courses$, courses => {
        setState(state => ({ ...state, courses: courses }));
      }),
    ];
    const cb = () => {
      setState(state => ({ ...state, isLoading: false }));
    }

    coursesService.getCourses().subscribe(cb, cb);

    return () => {
      subscriptions.map(it => it.unsubscribe())
    };
  }

  useEffect(useEffectCB, []);

  return [
    state,
    handleCoursesSelect,
    handleAssign,
    handleToggle,
  ];
}