import {
    Box,
    Divider,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableContainer,
    TableFooter,
    TableHead,
    Tooltip,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import {WildHealthButton} from "../../../common/components/wildHealthButton/WildHealthButton";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import React from "react";
import {PermissionType, UserType} from "../../../auth/models/auth.enums";
import {ProtectedElement} from "../../../common/components/ProtectedElement";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import {SupplementsPlaceHolder} from "../../../common/components/wildHealthPlaceHolder/SupplementsPlaceHolder";
import {WildHealthTableCell} from "../../../common/components/wildHealthTable/WildHealthTableCell";
import {WildHealthTableRow} from "../../../common/components/wildHealthTable/WildHealthTableRow";
import {PatientSupplementModel, SupplementSource, supplementSources} from "../../models/patientSupplementModel";
import {useFacade} from "./patientSupplementsFullscriptComponent.hooks";
import {PaginationComponent} from "../../../common/pagination/components/paginationComponent/PaginationComponent";
import {useStyles} from "./patientSupplementsFullscriptComponent.styles";
import {pageSizes} from "../../../common/pagination/models/page-sizes";
import {TitlesService} from "../../../../constants/title.constants";
import commonUseStyles from '../../../common/styles/common.styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import InfoIcon from '@material-ui/icons/Info';
import clsx from "clsx";
import emptySupplements from "@img/placeholders/EmptyReport.png";
import {ReactComponent as SupplementOrderedIcon} from "@img/icons/SupplementOrdered.svg";
import {ReactComponent as AddSupplementIcon} from "@img/icons/AddSupplement.svg";
import {WildHealthPlaceHolderBase} from '../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolderBase';
import moment from "moment";
import {
    PatientSupplementsUpdateFullscriptComponent
} from "../patientSupplementsUpdateFullscriptComponent/PatientSupplementsUpdateFullscriptComponent";
import {authQuery} from "../../../auth/stores/auth";
import {
    RecommendFullscriptSupplementsComponent
} from "../recommendFullscriptSupplementsComponent/RecommendFullscriptSupplementsComponent";

interface PatientSupplementsComponentProps {
    patientId?: number;
    fullWidth?: boolean;
    hideTips?: boolean;
    handleToggleDialog: () => void;
    supplementSource?: SupplementSource;
}

export const PatientSupplementsFullscriptComponent: React.FC<PatientSupplementsComponentProps> = (props: PatientSupplementsComponentProps) => {
    const {
        patientId,
        hideTips,
        handleToggleDialog,
        supplementSource = SupplementSource.Supplement
    } = props;

    const [
        state,
        handleEdit,
        handleDelete,
        handleCloseDialog,
        toggleRecommendation,
        handlePageSizeChange,
        handlePageChange,
        getAllAvailablePages,
        handleToggleActions,
        handleReadMore,
        handleFullscriptSupplements
    ] = useFacade(patientId);

    const classes = useStyles();
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const customTooltipClasses = {
        tooltip: classes.customWidth,
        arrow: classes.arrow,
        tooltipPlacementBottom: classes.tooltipPlacement,
        tooltipPlacementTop: classes.tooltipPlacement,
    };

    const renderOrderedAt = (supplement: PatientSupplementModel) => {
        if (supplement.source !== SupplementSource.Fullscript) {
            return <></>
        }

        if (!supplement.orderedAt) {
            return (
                <Box display="flex" p={1} alignItems="center" className="wh-tw-rounded wh-tw-text-gray600 wh-tw-bg-gray100">
                    <Box ml={1}>
                        <span>
                            Not Ordered
                        </span>
                    </Box>
                </Box>
            )
        }

        return <>
            <Box display="flex" p={1} alignItems="center" className="wh-tw-rounded wh-tw-text-primaryV wh-tw-bg-mint50">
                <Box>
                    <SupplementOrderedIcon/>
                </Box>
                <Box ml={1}>
                    <span>
                        {moment(supplement.orderedAt).format('L')}
                    </span>
                </Box>
            </Box>
        </>
    }

    const renderPurchaseLink = (supplement: PatientSupplementModel) => {
        if (!supplement.purchaseLink) {
            return <></>
        }

        const wording = supplement.source === SupplementSource.Fullscript
            ? 'Purchase via Fullscript'
            : 'Purchase Here';

        return <Box>
            <ProtectedElement
                element={renderCreateLink(wording, supplement.purchaseLink)}
                permissions={[]}
                userType={UserType.Patient}
            />
        </Box>
    }

    const renderTable = () => {
        if (!state.isLoading && (!state.supplements || !state.supplements.length)) {
            return isSmallScreen ? (
                <Box mt="52px">
                    <WildHealthPlaceHolderBase message="You have no supplements listed at the moment.">
                        <img className={classes.placeholderImage}
                             src={emptySupplements}
                             alt="img" />
                    </WildHealthPlaceHolderBase>
                </Box>
            ) : (
                <SupplementsPlaceHolder message={`You have no supplements listed at the moment.`} />
            )
        }

        return (
            <Box minHeight="300px">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <WildHealthTableRow>
                                <WildHealthTableCell style={{ width: '18%' }}>
                                    <Box pl={2}>
                                        Name
                                    </Box>
                                </WildHealthTableCell>
                                <WildHealthTableCell style={{ width: '12%' }}>
                                    Strength
                                </WildHealthTableCell>
                                <WildHealthTableCell style={{ width: '12%' }}>
                                    Dosing Instruction
                                </WildHealthTableCell>
                                <WildHealthTableCell style={{ width: '12%' }}>
                                    Source
                                </WildHealthTableCell>
                                <WildHealthTableCell style={{ width: '12%' }}>
                                    Date
                                </WildHealthTableCell>
                                <WildHealthTableCell style={{ width: '12%' }}>
                                    Status
                                </WildHealthTableCell>
                                <WildHealthTableCell style={{ width: '12%' }}>
                                    Purchase Link
                                </WildHealthTableCell>
                                <WildHealthTableCell style={{ width: '10%' }}>
                                    <Box display='flex' alignItems='center'>
                                        <ProtectedElement
                                            element={<span>Action</span>}
                                            permissions={[PermissionType.Coaching, PermissionType.ManagePatients]}
                                        />
                                        <ProtectedElement
                                            element={<span>Action</span>}
                                            permissions={[]}
                                            userType={UserType.Patient}
                                        />
                                    </Box>
                                </WildHealthTableCell>
                            </WildHealthTableRow>
                        </TableHead>
                        <TableBody>
                            {
                                state.supplementsPart.map((item, index) =>
                                    <WildHealthTableRow key={index}>
                                        <WildHealthTableCell>
                                            <Box pl={2}>
                                                {
                                                    item.name
                                                }
                                            </Box>
                                        </WildHealthTableCell>
                                        <WildHealthTableCell>
                                            <Box display="flex" flexWrap="wrap">
                                                {item.dosage}
                                            </Box>
                                        </WildHealthTableCell>
                                        <WildHealthTableCell>
                                            <Box display="flex" flexWrap="wrap">
                                                {item.instructions}
                                            </Box>
                                        </WildHealthTableCell>
                                        <WildHealthTableCell>
                                            <Box display="flex" flexWrap="wrap">
                                                {supplementSources.get(item.source)}
                                            </Box>
                                        </WildHealthTableCell>
                                        <WildHealthTableCell>
                                            <Box display="flex" flexWrap="wrap">
                                                {moment(item.createdAt ?? new Date()).format('L')}
                                            </Box>
                                        </WildHealthTableCell>
                                        <WildHealthTableCell>
                                            <Box display="flex" flexWrap="wrap">
                                                {
                                                    renderOrderedAt(item)
                                                }
                                            </Box>
                                        </WildHealthTableCell>
                                        <WildHealthTableCell>
                                            <Box display="flex" flexWrap="wrap">
                                                {
                                                    renderPurchaseLink(item)
                                                }
                                            </Box>
                                        </WildHealthTableCell>
                                        <WildHealthTableCell className={classes.actions}>
                                            <Box display='flex' alignItems='center' justifyContent="space-between">
                                                <ProtectedElement
                                                    element={renderToggleButtonCell(item)}
                                                    permissions={[PermissionType.Coaching, PermissionType.ManagePatients]}
                                                />
                                                <ProtectedElement
                                                    element={renderToggleButtonCell(item)}
                                                    permissions={[]}
                                                    userType={UserType.Patient}
                                                />
                                            </Box>
                                        </WildHealthTableCell>
                                    </WildHealthTableRow>
                                )
                            }
                        </TableBody>
                        {!isSmallScreen &&
                            <TableFooter>
                                <WildHealthTableRow className={classes.row}>
                                    <WildHealthTableCell colSpan={12}>
                                        <PaginationComponent
                                            pageSizes={pageSizes}
                                            pageSize={state.pageSize}
                                            selectedPage={state.selectedPage}
                                            handlePageSizeChange={handlePageSizeChange}
                                            totalCount={state.totalCount}
                                            availablePages={getAllAvailablePages()}
                                            handlePageSelect={handlePageChange} />
                                    </WildHealthTableCell>
                                </WildHealthTableRow>
                            </TableFooter>
                        }
                    </Table>
                </TableContainer>
            </Box>
        )
    }

    const renderInfoText = () => {
        if (!state.isLoading && (!state.supplements || !state.supplements.length)) {
            return (
                <>
                    <Box pt={2} p={isSmallScreen && 2}>
                        <ProtectedElement
                            element={
                                <>
                                    <Box className={clsx(classes.description, isSmallScreen && !state.isFullText ? classes.descriptionCollapsed : classes.descriptionExpanded)}>
                                        We take supplement quality very seriously and have identified the list of supplements from reliable sources for you below.
                                        We have also negotiated a significant discount for our clients that order through Thorne. In order to receive this discount, use the affiliate links below and the discount will appear at checkout.
                                        If you have an existing Thorne account, you will need to create a new account with an alternative email to be eligible to receive the discount.
                                        Any additional problems, please contact us at
                                        <a id="patient-supplements-link-email" href={`mailto:${TitlesService.supplementsEmail}`} className={classes.siteLink}>{TitlesService.supplementsEmail}</a>.
                                    </Box>
                                    {isSmallScreen && <Box id="supplement-description-read-more" onClick={() => handleReadMore()} className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorMain)}>
                                        <span>{state.isFullText ? 'read less' : 'read more'}</span>
                                    </Box>}
                                </>
                            }
                            permissions={[]}
                            userType={UserType.Patient}
                        />
                    </Box>
                </>
            )
        }
    };

    const renderCreateButton = (title: string, size: any) => (
        <WildHealthButton
            id={`patient-supplements-${{ title }}`}
            size={size}
            onClick={() => handleEdit()}
        >
            {title}
        </WildHealthButton>
    );

    const renderCreateLink = (title: string, link: string) => {
        const correctedLink = link.startsWith('http://') || link.startsWith('https://') ? link : `http://${link}`;
        return <a id={`patient-supplements-link-${title}`} href={correctedLink} className="wh-tw-text-primaryV" target="_blank">{title}</a>
    };

    const renderToggleButtonCell = (item: PatientSupplementModel) => (
        <IconButton id={`patient-supplements-more-actions-${item.id}`} onClick={(e) => {
            handleToggleActions(item.id, e.currentTarget)
        }}>
            <MoreHorizIcon />
        </IconButton>
    )

    const renderMenuItems = () => (
        <Menu
            keepMounted
            anchorEl={state.anchorEl}
            open={state.isMenuOpened}
            onClose={() => {
                handleToggleActions(0)
            }}
        >
            <MenuItem id="patient-supplements-edit" onClick={() => {
                handleEdit()
            }}>
                Edit
            </MenuItem>
            <MenuItem id="patient-supplements-delete" onClick={() => {
                handleDelete()
            }}>
                Delete
            </MenuItem>
        </Menu>
    );


    return (
        state.isLoading ?
            <WildHealthLinearProgress /> :
            <>
                <Box className={clsx(isSmallScreen && classes.smallRoot, "flat-scroll")}>
                    {isSmallScreen ? renderInfoText() : (
                        <Box p={4} pb={0} display='flex' flexDirection='column'>
                            <Box display='flex' justifyContent='space-between'>
                                <Box fontSize={20} fontWeight={500}>List of Supplements</Box>
                                <Box display='flex' alignItems='center' gridGap={24}>
                                    {
                                        !hideTips &&
                                        <Box display='flex' alignItems='center' gridGap={8} className="wh-tw-cursor-pointer" onClick={handleToggleDialog}>
                                            <InfoIcon className="wh-tw-w-5 wh-tw-h-5 wh-tw-text-primaryV" />
                                            <Box className="wh-tw-text-primaryV wh-tw-text-sm wh-tw-font-medium">Do you need help?</Box>
                                        </Box>
                                    }
                                    <ProtectedElement
                                        element={<Box display='flex' alignItems='center' gridGap={8} className="wh-tw-cursor-pointer" >
                                            <Tooltip placement="bottom" arrow
                                                     title={<Box className={classes.notificationToolTipText}>
                                                         <Box>
                                                             {state.allergies.length
                                                                 ? state.allergies.map((allergy, index) => {
                                                                     return <Box key={index}>
                                                                         <span className={classes.itemDot}>&#x2022;</span>
                                                                         <span className={classes.itemName}>{allergy.name}</span>
                                                                         <span className={classes.itemReaction}>{`- ${allergy.description}`}</span>
                                                                     </Box>
                                                                 })
                                                                 : <span className={classes.itemName}>No known drug allergies</span>}
                                                         </Box>
                                                     </Box>}
                                                     classes={customTooltipClasses}
                                            >
                                                <InfoIcon className="wh-tw-w-5 wh-tw-h-5 wh-tw-text-primaryV" />
                                            </Tooltip>

                                            <Box className="wh-tw-text-primaryV wh-tw-text-sm wh-tw-font-medium">See Allergy List</Box>
                                        </Box>}
                                        permissions={[PermissionType.Coaching, PermissionType.ManagePatients]}
                                    />
                                    <Box>
                                        <ProtectedElement
                                            element={<>
                                                <Box display="flex" alignItems="center">
                                                    <Box>
                                                        <WildHealthButton
                                                            id={`patient-supplements-fullscript`}
                                                            size="large"
                                                            onClick={() => toggleRecommendation()}
                                                        >
                                                            Recommend with Fullscript
                                                        </WildHealthButton>
                                                    </Box>
                                                    <Box ml={2}>
                                                        <IconButton onClick={() => handleEdit()}>
                                                            <AddSupplementIcon/>
                                                        </IconButton>
                                                    </Box>
                                                </Box>
                                            </>}
                                            permissions={[PermissionType.Coaching, PermissionType.ManagePatients]}
                                        />
                                        <ProtectedElement
                                            element={renderCreateButton('Add Supplements', "large")}
                                            permissions={[]}
                                            userType={UserType.Patient}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box width='80%'>
                                {
                                    renderInfoText()
                                }
                            </Box>
                        </Box>
                    )}

                    <Box mt={3}>
                        <Grid container>
                            <Grid item sm={12} md={12} xs={12}>
                                {
                                    renderTable()
                                }
                                <ProtectedElement
                                    element={renderMenuItems()}
                                    permissions={[PermissionType.Coaching, PermissionType.ManagePatients]}
                                />
                                <ProtectedElement
                                    element={renderMenuItems()}
                                    permissions={[]}
                                    userType={UserType.Patient}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box>
                        <PatientSupplementsUpdateFullscriptComponent
                            opened={state.isEditorOpened}
                            fullscript={authQuery.isPatientUser()}
                            source={supplementSource}
                            handleClose={() => handleCloseDialog()}
                            patientId={props.patientId}
                        />
                        <ProtectedElement
                            element={
                                <RecommendFullscriptSupplementsComponent
                                    open={state.isRecommendationOpen}
                                    patientId={props.patientId}
                                    handleClose={() => toggleRecommendation()}
                                    onOrdered={handleFullscriptSupplements}
                                />
                            }
                            permissions={[PermissionType.Coaching, PermissionType.ManagePatients]}
                        />

                    </Box>
                </Box>
                {isSmallScreen && (
                    <>
                        {state.supplements && state.supplements.length > 0 && <Divider className={commonClasses.renderDivider} orientation="horizontal" />}
                        <Box className={commonClasses.bgWhiteMain}>
                            <Box display="flex" flexDirection="row" justifyContent="flex-end">
                                {state.supplements && state.supplements.length > 0 && (
                                    <Box flex={1} margin="auto" pl={2}>
                                        <PaginationComponent
                                            pageSizes={pageSizes}
                                            pageSize={state.pageSize}
                                            selectedPage={state.selectedPage}
                                            handlePageSizeChange={handlePageSizeChange}
                                            totalCount={state.totalCount}
                                            availablePages={getAllAvailablePages()}
                                            handlePageSelect={handlePageChange} />
                                    </Box>
                                )}
                                <IconButton aria-label={`patient-supplements-add`} onClick={() => handleEdit()}>
                                    <AddCircleIcon className={classes.fixedButton} />
                                </IconButton>
                            </Box>
                        </Box>
                    </>
                )}
            </>
    )
}
