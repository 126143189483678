import Axios from 'axios-observable';
import { authHeader } from "../../common/helpers/auth-header";
import { snackService } from "../../common/snack/state";
import { documentsStore, DocumentsStore } from "../stores/documentsStore";
import { FileInputModel, FileInputType, } from "../models/input.models";
import { Observable, Subject } from "rxjs";
import { CallbackModel } from "../../common/models/callback.model";
import { DocumentCategory, documentCategoryNames, DocumentModel, TransferDocumentModel, ViewableDocumentModel } from '../../documents/models/documents.models';
const FileDownload = require('js-file-download');

/**
 * Provides method for working with inputs
 */
export class DocumentsService {
    private urlDocuments = `${process.env.REACT_APP_API_URL}Documents`;
    private urlUploads = `${this.urlDocuments}/UploadDocuments`;

    constructor(private documentsStore: DocumentsStore) {
    }

    public onUpload = new Subject<CallbackModel<FileInputType>>();

    /**
     * Returns all patients uploads
     */
    public getDocuments(patientId: number): Observable<DocumentModel[]> {

        const config = { headers: authHeader() };

        return new Observable((observer) => {
            Axios.get<DocumentModel[]>(`${this.urlDocuments}/GetDocuments/Employee/Patient?patientId=${patientId}`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.documentsStore.update({ documentList: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    /**
     * Downloads uploaded file
     */
    public download(id: number, name: string): Observable<any> {
        return new Observable(observer => {

            Axios.get(`${this.urlDocuments}/DownloadDocument/Employee/Patient?documentId=${id}`, {
                headers: authHeader(),
                responseType: 'blob',
            })
                .pipe()
                .subscribe(
                    (response) => {
                        FileDownload(response.data, decodeURIComponent(name));
                        snackService.success('File successfully downloaded!');
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    /**
     * Transfer uploaded file
     */

    public transfer(model: TransferDocumentModel): Observable<any> {
        return new Observable(observer => {
            Axios.put(`${this.urlDocuments}/TransferDocument`, model, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    },
                );
        });
    }

    /**
     * Returns all patients uploads
     */
    public upload(patientId: number): Observable<FileInputModel> {
        return new Observable(observer => {
            this.onUpload.next({
                data: patientId,
                callback: (files: any, documentType: DocumentCategory, viewableFileIndexes: number[], isSendToKb: boolean) => {
                    if (!files) {
                        observer.error();
                        observer.complete();
                        return;
                    }

                    const formData = new FormData();
                    formData.append('patientId', patientId.toString());
                    formData.append('attachmentType', documentType.toString());
                    files.forEach((item) => {
                        formData.append('documents', item.file, item.name);
                    });
                    if (viewableFileIndexes.length) {
                        viewableFileIndexes.forEach(item => {
                            formData.append('IsViewableByPatientFileIndexes[]', item.toString());
                        });
                    } else {
                        formData.append('IsViewableByPatientFileIndexes[]', '-1');
                    }
                    const generativeAiKbInlineModel = {
                        isSendToKb: isSendToKb,
                    };
                    formData.append('generativeAiKbInline', JSON.stringify(generativeAiKbInlineModel));

                    Axios.post(`${this.urlUploads}/Employee/Patient`, formData, { headers: authHeader() })
                        .pipe()
                        .subscribe(
                            (response) => {
                                snackService.success(`Files successfully uploaded to ${documentCategoryNames[documentType]}!`);
                                observer.next(response.data);
                                observer.complete();
                            },
                            error => {
                                snackService.commonErrorHandler(error);
                                observer.error(error);
                                observer.complete();
                            }
                        );
                }
            });
        });
    }

    // /**
    //  * Views uploaded file
    //  */
    public viewinline(input: DocumentModel): Observable<any> {
        return new Observable(observer => {
            Axios.get(`${this.urlDocuments}/Document/${input.id}/viewinline/Employee/Patient`, {
                headers: authHeader(),
                responseType: 'blob',
            })
                .pipe()
                .subscribe(
                    (response) => {
                        const file = new Blob([response.data], { type: response.data.type });
                        const fileURL = URL.createObjectURL(file);
                        window.open(fileURL, '_blank');

                        snackService.success('File successfully opened!');
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

        // /**
    //  * Viewedfile
    //  */
    
    public viewDocument(id: number): Observable<void> {
        return new Observable<void>(observer => {
            Axios.patch<[]>(`${this.urlDocuments}/Document/${id}/SetViewed`, [], { headers: authHeader() })
                .pipe()
                .subscribe(
                    () => {
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }


    /**
     * Deletes upload
     */
    public deleteUpload(id: number): Observable<any> {
        return new Observable(observer => {
            Axios.delete(`${this.urlDocuments}/DeleteDocument?attachmentId=${id}`, { headers: authHeader() })
                .pipe()
                .subscribe(
                    () => {
                        snackService.success('File successfully deleted!');
                        observer.next();
                        observer.complete();
                    },
                    error => snackService.commonErrorHandler(error)
                );
        }
        )
    }

    /**
     * Update the attachment as viewable by the Patient
     */

    public setViewableByPatient(model: ViewableDocumentModel): Observable<any> {
        return new Observable(observer => {
            Axios.put(`${this.urlDocuments}/Document/SetViewableByPatient`, model, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.documentsStore.updateDocument(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    },
                );
        });
    }

}

export const documentsService = new DocumentsService(documentsStore);
