import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    List,
    Menu,
    MenuItem,
    Typography
} from '@material-ui/core';
import React from 'react';
import { PermissionType } from '../../../auth/models/auth.enums';
import { ProtectedElement } from '../../../common/components/ProtectedElement';
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { EmployeeType } from '../../../employee/models/employee.enums';
import { EmployeeShortModel } from '../../../employee/models/employee.models';
import { NoteModel, NotesGroupModel, NotesType } from '../../models/notes.models';
import { EmptyVisitNotesComponent } from '../emptyVisitNotes/EmptyVisitNotesComponent';
import { EmptyInternalNotesComponent } from '../emptyInternalNotes/EmptyInternalNotesComponent';
import { ManageNoteCardComponent } from '../manageNoteCard/ManageNoteCardComponent';
import { useFacade } from './managedNotesCardsComponent.hooks';
import { useStyles } from './managedNotesCardsComponent.styles';
import AddIcon from '@material-ui/icons/Add';
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import {FeatureFlag} from "../../../common/components/featureFlags/featureFlags.models";
import {FeatureComponent} from "../../../common/components/featureFlags/FeatureComponent";
import { useHistory, useLocation } from 'react-router';
import { RoutesConstants } from '../../../../constants/route.constants';

interface ManagedNotesCardsComponentProps {
    patientId: number;
    handleGoToCreateNote: (type: NotesType) => void;
    handleGoToNote: (note: NoteModel) => void;
    handleDownloadNotePdf: (note: NoteModel) => void;
    downloadingNoteId: Number | null;
    handleEditNote: (note: NoteModel) => void;
}

export const ManagedNotesCardsComponent: React.FC<ManagedNotesCardsComponentProps> = (props: ManagedNotesCardsComponentProps) => {
    const {
        patientId,
        handleGoToCreateNote,
        handleGoToNote,
        handleDownloadNotePdf,
        downloadingNoteId,
        handleEditNote
    } = props;

    const [
        {
            isLoading,
            internalNotesGroups,
            visitNotesGroups,
            createNote
        },
        handleCreateNoteToggle,
        handleGetCurrentNote
    ] = useFacade(patientId);
    const classes = useStyles();
    const location = useLocation();
    const _history = useHistory();
    const locationSearch = location?.search;

    if (locationSearch) {    
        visitNotesGroups.forEach(group => group.notes.find(note => {
            if(note.id === +locationSearch.replace(/[^0-9]/g, '')) {
                _history.push({
                    pathname: `${RoutesConstants.managePatientProfile}/${patientId}`,
                    search: '',
                });
                handleGoToNote(note)
                return
            }
            if (note.amendedNotes?.length) {
                note.amendedNotes.find(amendedNote => {
                    if(amendedNote.id === +locationSearch.replace(/[^0-9]/g, '')) {
                        _history.push({
                            pathname: `${RoutesConstants.managePatientProfile}/${patientId}`,
                            search: '',
                        });
                        handleGoToNote(note)
                        return
                    }
                })
            }
        }))
    }

    let employeeTypeMap = {};
    employeeTypeMap[EmployeeType.HealthCoach] = 'Health Coach';
    employeeTypeMap[EmployeeType.Provider] = 'Precision Provider';

    const employeeDisplayName = (employee: EmployeeShortModel) => {
        return `${employeeTypeMap[employee.type]}: ${employee.firstName} ${employee.lastName}`
    }

    const renderNoteGroups = (groups: NotesGroupModel[]) => {
        return (
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
            >
                {
                    groups.map(item =>
                        <Grid item key={item.employee.id} xs={12} md={6}>
                            <Card className={classes.card}>
                                <CardHeader
                                    title={employeeDisplayName(item.employee)}
                                />

                                <Divider className={classes.cardDivider} />

                                <CardContent>
                                    <List>
                                        {
                                            item.notes.map(item =>
                                            !item.originalNoteId &&
                                                <React.Fragment key={`${item.employee.id}-${item.id}`}>
                                                    <ManageNoteCardComponent
                                                        patientId={patientId}
                                                        keyIndex={`${item.employee.id}-${item.id}`}
                                                        note={handleGetCurrentNote(item)}
                                                        handleGoToNote={handleGoToNote}
                                                        handleDownloadNotePdf={handleDownloadNotePdf}
                                                        downloadingNoteId={downloadingNoteId}
                                                        handleGoToEditNote={handleEditNote}
                                                    />
                                                </React.Fragment>
                                            )
                                        }
                                    </List>
                                </CardContent>
                            </Card>
                        </Grid>
                    )
                }
            </Grid>
        )
    };

    const renderNotes = () => {
        return (
            <Box>
                <Box>
                    <Typography className={classes.title} color="textPrimary">Internal Notes</Typography>
                </Box>
                <Box>
                    {
                        (!internalNotesGroups || !internalNotesGroups.length) ?
                            <EmptyInternalNotesComponent /> :
                            renderNoteGroups(internalNotesGroups)
                    }
                </Box>
                <Box mt={2}>
                    <Typography className={classes.title} color="textPrimary">Visit Notes</Typography>
                </Box>
                <Box>
                    {
                        (!visitNotesGroups || !visitNotesGroups.length) ?
                            <EmptyVisitNotesComponent /> :
                            renderNoteGroups(visitNotesGroups)
                    }
                </Box>
            </Box>
        )
    };

    return (
        <Box m={5} mt={0} pt={3}>
            <Box display="flex">
                <Box>
                    <ProtectedElement
                        element={
                            <WildHealthButton
                                id="managed-notes-create"
                                onClick={(event) => handleCreateNoteToggle(true, event)}
                            >
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                >
                                    <AddIcon />
                                    Create a Note
                                </Grid>
                            </WildHealthButton>}
                        permissions={[PermissionType.VisitNoteCreation]}
                    />
                </Box>
            </Box>

            <Box mt={3}>
                {
                    isLoading
                        ? <WildHealthLinearProgress />
                        : renderNotes()
                }
            </Box>

            <Menu
                keepMounted
                open={createNote.isOpen}
                anchorEl={createNote.anchorEl}
                onClose={() => handleCreateNoteToggle(false)}
            >
                <MenuItem id="managed-notes-create-initial-coaching" onClick={() => handleGoToCreateNote(NotesType.Initial)}>
                    Initial Coaching Consult
                </MenuItem>
                <MenuItem id="managed-notes-create-initial-follow-up" onClick={() => handleGoToCreateNote(NotesType.FollowUp)}>
                    Follow-Up
                </MenuItem>
                <FeatureComponent featureFlag={FeatureFlag.SoapNote}>
                    <MenuItem id="managed-notes-create-initial-follow-up" onClick={() => handleGoToCreateNote(NotesType.Soap)}>
                        SOAP
                    </MenuItem>
                </FeatureComponent>
                <MenuItem id="managed-notes-create-blank" onClick={() => handleGoToCreateNote(NotesType.Blank)}>
                    Blank
                </MenuItem>
                <MenuItem id="managed-notes-create-internal-note" onClick={() => handleGoToCreateNote(NotesType.Internal)}>
                    Internal Note
                </MenuItem>
                <FeatureComponent featureFlag={FeatureFlag.ImhNote}>
                    <MenuItem id="managed-notes-create-initial-history-and-physical" onClick={() => handleGoToCreateNote(NotesType.HistoryAndPhysicalInitial)}>
                        Initial Medical History and Physical
                    </MenuItem>
                </FeatureComponent>
                <FeatureComponent featureFlag={FeatureFlag.FmhNote}>
                    <MenuItem id="managed-notes-create-follow-up-history-and-physical" onClick={() => handleGoToCreateNote(NotesType.HistoryAndPhysicalFollowUp)}>
                        Follow-Up Medical History and Physical
                    </MenuItem>
                </FeatureComponent>
                <FeatureComponent featureFlag={FeatureFlag.FmhNote}>
                    <MenuItem id="managed-notes-create-group-visit-history-and-physical" onClick={() => handleGoToCreateNote(NotesType.HistoryAndPhysicalGroupVisit)}>
                        Group Visit Medical History and Physical
                    </MenuItem>
                </FeatureComponent>
            </Menu>
        </Box>
    );
}
