import { createStyles, makeStyles} from "@material-ui/core";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles(() =>
  createStyles({
    expandIcon: {
      fill: colors.main,
      width: 18,
    },
    action: {
      color: colors.main,
      fontSize: 14,
      fontWeight: 500,
    },
  }),
);