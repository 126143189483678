import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            padding: 5,
            [theme.breakpoints.up('sm')]: {
                padding: 30,
            },
        },
        subTitle: {
            color: '#7C8A9D',
            fontFamily: 'Be Vietnam Pro',
            fontWeight: 500,
            fontSize: 16,
            margin: 5,
        },
        tooltip: {
            maxWidth: 240,
        },
    })
);
