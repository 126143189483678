import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bgMain: {
            backgroundColor: colors.bg,
            paddingLeft: 28,
            transform: 'translate(-28px, 0px)',
            width: `calc(100% + 56px)`,
        }
    })
);
