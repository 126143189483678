import { Box, Popover, PopoverOrigin, Tab } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import clsx from 'clsx';
import React, { useEffect, useState } from "react";
import { WildHealthMenuItem } from '../wildHealthMenu/WildHealthMenu';
import { WildHealthTabPanel } from "../wildHealthTabPanel/WildHealthTabPanel";

function TabProps(key: string) {
    return {
        id: key,
        key: key,
        'aria-controls': `scrollable-auto-tabpanel-${key}`,
        disableRipple: true,
    };
}

function MenuProps(key: string) {
    return {
        id: key,
        key: key,
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
        } as PopoverOrigin,
        transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
        } as PopoverOrigin,
    };
}

export interface TabItem {
    title: string | JSX.Element;
    content?: JSX.Element;
    children?: TabItem[],
    keepMounted?: boolean;
    disabled?: boolean;
    minHeight?: number;
    action?: () => void,
}

interface TabItemState {
    key: string;
    isOpen: boolean;
}

interface WildHealthTabControlState {
    selectedTab: number,
    selectedContent: string,
    anchorEl: HTMLButtonElement;
    childItemsStates: TabItemState[];
}

export function useFacade(
    items: TabItem[],
    classes: ClassNameMap<'tab' | 'selectedTab'>,
    initTab?: number,
    onChangeCB?: (tab: number) => void
    ):
    [
        number,
        (event: React.ChangeEvent<{}>, value: number) => void,
        JSX.Element[],
        JSX.Element[],
        JSX.Element[],
    ] {

    const [state, setState] = useState({
        selectedTab: initTab,
        selectedContent: `tab-content-${initTab}`,
        anchorEl: null,
        childItemsStates: items.filter(i => i.children).map(i => { return {key: `child-tab-item-${items.indexOf(i)}`, isOpen: false} as TabItemState })
    } as WildHealthTabControlState);

    const handleChangeTab = (event: React.ChangeEvent<{}>, value: number) => {
        setState(state => ({...state, selectedTab: value, selectedContent: `tab-content-${value}`}));
        if (onChangeCB) {
            onChangeCB(value);
        }
    };

    const handleChangeContent = (tab: number, value: number) => {
        state.childItemsStates.forEach(i => i.isOpen = false);
        setState(state => ({...state, selectedTab: tab, selectedContent: `tab-content-${tab}-${value}`}));
    };

    const handleMenuOpen = (event?: React.MouseEvent<HTMLButtonElement> | any) => {
        handleMenuToggle(true, event);
    }

    const handleMenuClose = (event?: React.MouseEvent<HTMLButtonElement> | any) => {
        handleMenuToggle(false, event);
    }

    const handleMenuToggle = (open: boolean, event?: React.MouseEvent<HTMLButtonElement> | any) => {
        const anchorEl = event && event.currentTarget ? event.currentTarget : null;
        let element: TabItemState;
        if (anchorEl)
            element = state.childItemsStates.find(i => i.key === `child-${event.currentTarget.id}`);
        else 
            element = state.childItemsStates.find(i => i.isOpen);

        if (element) {
            element.isOpen = open;
            setState(({...state, childItemsStates: state.childItemsStates, anchorEl: anchorEl}));
        }
    }

    const handleRenderChildrenMenuItems = (index: number, key: string, isOpen: boolean, items: TabItem[]) => {
        return (
            <Popover
                keepMounted
                open={isOpen}
                anchorEl={state.anchorEl}
                onClose={() => handleMenuClose()}
                getContentAnchorEl={null}
                {...MenuProps(key)}
            >
            {
                items.map((child, childIndex) =>
                    <WildHealthMenuItem
                        id={`tab-control-${child.title}`}
                        key={childIndex}
                        disabled={child.disabled}
                        className={clsx({[classes.selectedTab]: state.selectedContent === `tab-content-${index}-${childIndex}`})}
                        onClick={() => handleChangeContent(index, childIndex)}
                    >
                        {child.title}
                    </WildHealthMenuItem>
                )
            }
            </Popover>
        );
    }

    let menus: JSX.Element[] = [];

    const tabs = items.map((item, index) => {
        const key = `tab-item-${index}`;
        const childKey = `child-tab-item-${index}`;

        if (item.children) {
            const element = state.childItemsStates.find(i => i.key === childKey);
            if (element) {
                menus.push(handleRenderChildrenMenuItems(index, childKey, element.isOpen, item.children));
            }
        }

        const label = (
            <Box display='flex' alignContent='center' justifyContent='center'>
                <Box mt={0.4}>{item.title}</Box>
                {item.children?.length ? <ExpandMoreRoundedIcon /> : null}
            </Box>
        );

        return (
            <Tab
                id="tab-control-open-menu"
                aria-describedby={childKey}
                disabled={item.disabled}
                onClick={(event) => handleMenuOpen(event)}
                className={clsx(classes.tab, {[classes.selectedTab]: state.selectedTab === index})}
                label={label}
                {...TabProps(key)}
            />
        );
    });

    const tabPanels: JSX.Element[] = [];

    const setTabPanels = (tabPanels: JSX.Element[], item: TabItem, index: number, initialKey: string) => {
        const key = `${initialKey}-${index}`;

        if (item.content) {
            tabPanels.push(
                <WildHealthTabPanel key={index} selected={state.selectedContent} index={key} keepMounted={item.keepMounted} minHeight={item.minHeight}>
                    {item.content}
                </WildHealthTabPanel>
            );
        }

        if (item.children?.length) {
            item.children.forEach((child, childIndex) => setTabPanels(tabPanels, child, childIndex, key));
        }
    }

    items.forEach((item, index) => setTabPanels(tabPanels, item, index, 'tab-content'));

    useEffect(() => {

        setState(state => ({...state,
            selectedTab: initTab,
            selectedContent: `tab-content-${initTab}`
        }));

        return () => {};
    }, [initTab]);

    return [
        state.selectedTab,
        handleChangeTab,
        tabs,
        tabPanels,
        menus,
    ];
}