import moment from "moment";
import { useState, useEffect } from "react";
import { Subscription } from "rxjs/internal/Subscription";
import { Roles } from "../../../auth/models/auth.enums";
import { DataSpecificationsEnum } from "../../../common/constants/data-specifications";
import { onEmit } from "../../../common/helpers/on-emit";
import { PatientModel } from "../../../patients/models/patient.model";
import { patientsService } from "../../../patients/services/patients.service";
import { patientsQuery } from "../../../patients/stores/patientsStore";
import { SubscriptionModel } from "../../../payment/models/subscription.models";
import { NextTimeModel, NextTimeSpan, NoteModel, NotesGroupModel, NotesType } from "../../models/notes.models";
import { notesService } from "../../services/notes.service";
import { notesQuery } from "../../stores/notes";
import { coachNextAppointmentTimes, providerNextAppointmentTimes } from "./nextAppointmentDateComponent.static";


interface NextAppointmentDateComponentState {
    prewNote: NoteModel;
    patientSubscription: SubscriptionModel;
    enableCoachNextAppointment: boolean;
    selectedCoachNextTimeMode: NextTimeModel;
    selectedCoachNextTimeSpan: NextTimeSpan;
    selectedCoachNextTimeQuantity: number;
    enableProviderNextAppointment: boolean;
    selectedProviderNextTimeMode: NextTimeModel;
    selectedProviderNextTimeSpan: NextTimeSpan;
    selectedProviderNextTimeQuantity: number;
}

const defaultProviderAppointmentTime = providerNextAppointmentTimes[0];

const defaultCoachAppointmentTime = coachNextAppointmentTimes[0];

export function useFacade(handleChanges: (date: Date, role: Roles) => void, patientId: number, noteType: number): [
    NextAppointmentDateComponentState,
    (value: boolean) => void,
    (timeModel: NextTimeModel) => void,
    (timeSpan: NextTimeSpan) => void,
    (quantity: number) => void,
    (value: boolean) => void,
    (timeModel: NextTimeModel) => void,
    (timeSpan: NextTimeSpan) => void,
    (quantity: number) => void,
] {
    const [state, setState] = useState({
        prewNote: {},
        patientSubscription: null,
        enableCoachNextAppointment: false,
        selectedCoachNextTimeMode: defaultCoachAppointmentTime,
        selectedCoachNextTimeSpan: defaultCoachAppointmentTime.timeSpan,
        selectedCoachNextTimeQuantity: defaultCoachAppointmentTime.quantity,
        selectedProviderNextTimeMode: defaultProviderAppointmentTime,
        enableProviderNextAppointment: false,
        selectedProviderNextTimeSpan: defaultProviderAppointmentTime.timeSpan,
        selectedProviderNextTimeQuantity: defaultProviderAppointmentTime.quantity,

    } as NextAppointmentDateComponentState)

    const getNextAppointmentDate = (role: Roles): Date => {
        const nextAppointmentEnabled = role === Roles.Provider ? state.enableProviderNextAppointment : state.enableCoachNextAppointment;
        if (!nextAppointmentEnabled) {
            return null;
        }

        const date = new Date();
        const targetTimeSpan = role === Roles.Provider ? state.selectedProviderNextTimeSpan : state.selectedCoachNextTimeSpan;
        const targetQuantity = role === Roles.Provider ? state.selectedProviderNextTimeQuantity : state.selectedCoachNextTimeQuantity;

        switch (targetTimeSpan) {
            case NextTimeSpan.Week:
                return moment(date).add(targetQuantity, 'w').toDate();
            case NextTimeSpan.Month:
                return moment(date).add(targetQuantity, 'M').toDate();
            case NextTimeSpan.Year:
                return moment(date).add(targetQuantity, 'y').toDate();
            default:
                return null;
        }
    }

    const handleCoachNextAppointmentChange = (value: boolean) => {
        state.enableCoachNextAppointment = value;
        handleChanges(getNextAppointmentDate(Roles.Coach), Roles.Coach);
    }

    const handleCoachNextTimeModeChange = (timeModel: NextTimeModel) => {
        state.selectedCoachNextTimeMode = timeModel;
        state.selectedCoachNextTimeQuantity = timeModel.quantity;
        state.selectedCoachNextTimeSpan = timeModel.timeSpan;
        handleChanges(getNextAppointmentDate(Roles.Coach), Roles.Coach);
    }

    const handleCoachNextTimeSpanChange = (timeSpan: NextTimeSpan) => {
        state.selectedCoachNextTimeSpan = timeSpan;
        handleChanges(getNextAppointmentDate(Roles.Coach), Roles.Coach);
    }

    const handleCoachNextTimeQuantityChange = (quantity: number) => {
        if (!quantity) {
            state.selectedCoachNextTimeQuantity = 0;
        } else {
            state.selectedCoachNextTimeQuantity = Math.trunc(quantity);
        }
        handleChanges(getNextAppointmentDate(Roles.Coach), Roles.Coach);
    }

    const handleProviderNextAppointmentChange = (value: boolean) => {
        state.enableProviderNextAppointment = value;
        handleChanges(getNextAppointmentDate(Roles.Provider), Roles.Provider);
    }

    const handleProviderNextTimeModeChange = (timeModel: NextTimeModel) => {
        state.selectedProviderNextTimeMode = timeModel;
        state.selectedProviderNextTimeQuantity = timeModel.quantity;
        state.selectedProviderNextTimeSpan = timeModel.timeSpan;
        handleChanges(getNextAppointmentDate(Roles.Provider), Roles.Provider);
    }

    const handleProviderNextTimeSpanChange = (timeSpan: NextTimeSpan) => {
        state.selectedProviderNextTimeSpan = timeSpan;
        handleChanges(getNextAppointmentDate(Roles.Provider), Roles.Provider);
    }

    const handleProviderNextTimeQuantityChange = (quantity: number) => {
        if (!quantity) {
            state.selectedProviderNextTimeQuantity = 0
        } else {
            state.selectedProviderNextTimeQuantity = Math.trunc(quantity)
        }

        handleChanges(getNextAppointmentDate(Roles.Provider), Roles.Provider);
    }

    const isNoteWithAppointment = (type: number) => {
        switch (type) {
            case NotesType.FollowUp:
            case NotesType.Soap:
            case NotesType.HistoryAndPhysicalInitial:
            case NotesType.HistoryAndPhysicalFollowUp:
            case NotesType.HistoryAndPhysicalGroupVisit:
                return true;
            default:
                return false;
        }
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<NotesGroupModel[]>(notesQuery.notesGroups$, notesGroups => {
                if (notesGroups.some(group => group.notes.some(note => note.patientId !== patientId))) {
                    return;
                }

                let notes: NoteModel[] = [];
                notesGroups.forEach(group => {
                    notes.push(...group.notes.filter(n => n.isCompleted && isNoteWithAppointment(n.type)));
                });

                const previousNote = notes && notes.length
                    ? notes.sort((a, b) => (a.visitDate < b.visitDate) ? 1 : ((b.visitDate < a.visitDate) ? -1 : 0))[0] : null;

                if (previousNote) {
                    setState(state => ({ ...state, prewNote: previousNote }));
                }

                if (previousNote) {
                    patientsService.get(patientId, DataSpecificationsEnum.UpdatePatientSpecification);
                }
            }),
            onEmit<PatientModel>(patientsQuery.targetPatient$, targetPatient => {
                if (targetPatient && targetPatient.id === patientId) {
                    setState(state => ({ ...state, patientSubscription: targetPatient.subscription }))
                }
            }),
        ];

        notesService.getByEmployee(patientId).subscribe(() => { });

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [
        state,
        handleCoachNextAppointmentChange,
        handleCoachNextTimeModeChange,
        handleCoachNextTimeSpanChange,
        handleCoachNextTimeQuantityChange,
        handleProviderNextAppointmentChange,
        handleProviderNextTimeModeChange,
        handleProviderNextTimeSpanChange,
        handleProviderNextTimeQuantityChange
    ];
}