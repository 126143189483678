import React from 'react';
import {Box, Typography, Button, IconButton} from "@material-ui/core";
import {useStyles} from "./paymentCouponComponent.styles";
import { ReactComponent as CouponIcon } from '@img/icons/TicketIcon.svg';
import CloseIcon from '@material-ui/icons/Close';

interface PaymentCouponAppliedComponentProps {
    paymentCoupon: string;
    couponDiscount: string;
    handleRemove:Function
}

export const PaymentCouponAppliedTestedComponent: React.FC<PaymentCouponAppliedComponentProps> = (props: PaymentCouponAppliedComponentProps) => {
    const {paymentCoupon, couponDiscount, handleRemove} = props;
    const classes = useStyles();

    return (
        <>
            <Box className={classes.appliedCouponBox} id="couponApplied" display="flex" alignItems= "center">
                <Box p={1} flexGrow={1}>
                    <Typography>Discount code
                        <span className={classes.appliedCouponSpan}> "{paymentCoupon}" </span>applied! You got {couponDiscount}
                    </Typography>
                </Box>
                <Box p={1} >
                    <Button onClick={()=>handleRemove()} >Remove</Button>
                </Box>
            </Box>
        </>
    )
}

export const PaymentCouponAppliedComponent: React.FC<PaymentCouponAppliedComponentProps> = (props: PaymentCouponAppliedComponentProps) => {
    const {paymentCoupon, handleRemove} = props;
    const classes = useStyles();

    return (
        <>
            <Box className={classes.couponCodeBox} id="couponApplied" mt={1.5}>
                <CouponIcon />
                <Box px={1} className={classes.couponCodeText}>{paymentCoupon}</Box>
                <IconButton
                    aria-label="remove coupon code"
                    onClick={() => handleRemove()}
                    edge="end"
                >
                    <CloseIcon className={classes.removeIcon} />
                </IconButton>
            </Box>
        </>
    )
}