import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Subscription } from "recompose";
import { navigationService } from "../../../../services/navigation.service";
import { authQuery } from "../../../auth/stores/auth";
import { onEmit } from "../../../common/helpers/on-emit";
import { LocationModel } from "../../../locations/models/locations.models";
import { locationsQuery } from "../../../locations/stores";
import { EmployeeConversationModel } from "../../models/conversation.models";
import { conversationsService } from "../../services/conversations.service";
import { employeeConversationsQuery } from "../../stores/employeeConversationsStore/employeeConversations.query";
import { employeeConversationsStore } from "../../stores/employeeConversationsStore/employeeConversations.store";
import { NotificationModel, NotificationType } from "../../../notifications/models/notifications.models";
import { notificationsQuery } from "../../../notifications/stores/notifications";
import { notificationsService } from "../../../notifications/services/notifications.service";

interface PatientsSubmissionsComponentState {
    isLoading: boolean;
    isNavigating: boolean;
    locations: LocationModel[];
    targetLocationId: number;
    targetChatId: number;
    conversations: EmployeeConversationModel[];
}

export const patientsSubmissionsComponentColumns = [
    'Patient',
    'Pod',
    'Subject',
    'Action',
]

export function useFacade(): [
    PatientsSubmissionsComponentState, 
    (locationId?: number) => void, 
    (conversation: EmployeeConversationModel) => void,
    (conversationId: number) => void,] {

    const [state, setState] = useState({
        isLoading: true,
        isNavigating: false,
        locations: [],
        targetLocationId: 0,
        targetChatId: 0,
        conversations: [],
    } as PatientsSubmissionsComponentState);

    const history = useHistory();

    const handleNavigateToChat = (conversation: EmployeeConversationModel) => {
        setState(state => ({ ...state, isNavigating: true, targetChatId: conversation.id }));

        const cb = () => setState(state => ({ ...state, isNavigating: false, targetChatId: 0 }));
        const model = {
            conversationId: conversation.id,
            employeeId: authQuery.getEmployeeId(),
        };

        conversationsService.addEmployeeToConversationModel(model).subscribe((conversation) => {
            employeeConversationsStore.addConversation(conversation)
            employeeConversationsStore.resetAttachments();
            conversationsService.selectConversation(conversation);
            navigationService.toConversations(history);
            cb();
        }, cb);
    }

    const handleFiltering = (locationId?: number) => {
        const conversations = locationId > 0 ? state.conversations.filter(i => i.locationId === locationId) : employeeConversationsStore.getValue().supportSubmissions;
        setState(state => ({ ...state, conversations, targetLocationId: locationId }))
    }

    const handleToggleMessage = (conversationId : number) => {
        const expandedConversation = state.conversations.find(el => el.id === conversationId);
        expandedConversation.isOpen = !expandedConversation.isOpen
        setState(state => ({ ...state}))
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<EmployeeConversationModel[]>(employeeConversationsQuery.supportSubmissions$, conversations =>
                setState(state => ({ ...state, conversations: conversations.map(el => {
                    return {
                        ...el,
                        isOpen: true
                    }
                }) }))
            ),
            onEmit<LocationModel[]>(locationsQuery.availableLocations$, locations =>
                setState(state => ({ ...state, locations: locations }))
            ),
            onEmit<NotificationModel[]>(notificationsQuery.notifications$, () => {
                const conversationNotifications = notificationsQuery.getNotificationsByTypes([
                    NotificationType.NewSupportTicket
                ]);

                if (conversationNotifications?.length) {
                    notificationsService.deleteBulk(conversationNotifications);
                }
            })
        ];

        const cb = () => setState(state => ({ ...state, isLoading: false }));
        conversationsService.getSupportSubmissions().subscribe(cb, cb);

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [state, handleFiltering, handleNavigateToChat, handleToggleMessage];
}