import React from 'react';
import { Box, Card, IconButton, Grid, Divider, Dialog, DialogContent, useTheme, useMediaQuery } from "@material-ui/core";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import { useStyles } from "./membershipCardComponent.styles";
import { useFacade } from "./membershipCardComponent.hooks";
import { toCurrentTimeZone } from "../../../timezones/helpers/timezone";

export const MembershipCardComponent: React.FC = () => {
    const [
        {
            paymentPlanHistories,
            selectedHistoryIndex,
            isOpen
        },
        handleExpand,
        handleToggle
    ] = useFacade();
    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const renderContent = () => {
        const events = paymentPlanHistories?.find((x, index) => index === selectedHistoryIndex)?.events;
        if (events && events.length > 0) {
            return (
                <Box px={4}>
                    {events.map((event, index) => (
                        <Box my={5} key={index}>
                            <Box className="wh-tw-text-[10px] wh-tw-text-gray1">{index + 1}</Box>
                            <Box className="wh-tw-text-xs wh-tw-font-medium">{event.title}</Box>
                            <Box className="wh-tw-text-[10px] wh-tw-text-gray1">{moment(toCurrentTimeZone(event.createdAt)).format('ll')}</Box>
                        </Box>
                    ))}
                </Box>
            )
        }
    }

    return (
        <Box mt={2}>
            {paymentPlanHistories.map((item, index) => (
                <Card className={classes.subscriptionCard} variant="outlined" key={index}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={7}>
                            <Box>
                                <Box className="wh-tw-text-gray1 wh-tw-text-xs wh-tw-uppercase">Plan Name</Box>
                                <Box mt={1} className="wh-tw-text-lg wh-tw-font-medium">{item.paymentPlanName}</Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5}>
                            <Box display="flex" justifyContent={!smallScreen && "flex-end"} gridGap={30}>
                                <Box>
                                    <Box className="wh-tw-text-gray1 wh-tw-text-xs wh-tw-uppercase" textAlign="right">Start Date</Box>
                                    <Box mt={1.5} className="wh-tw-text-sm">{moment(toCurrentTimeZone(item.startDate)).format('ll')}</Box>
                                </Box>
                                <Box>
                                    <Box className="wh-tw-text-gray1 wh-tw-text-xs wh-tw-uppercase" textAlign="right">End Date</Box>
                                    <Box mt={1.5} className="wh-tw-text-sm">{moment(toCurrentTimeZone(item.endDate)).format('ll')}</Box>
                                </Box>
                                <Box>
                                    <IconButton
                                        id={`expand-button`}
                                        size="small"
                                        onClick={() => handleExpand(index)}
                                    >
                                        {selectedHistoryIndex === index ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                                    </IconButton>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    {selectedHistoryIndex === index && (
                        <Box width={1} pt={2.5}>
                            <Divider className="wh-tw-bg-stroke" />
                            <Box mt={2.5}>
                                <Grid container spacing={3}>
                                    {item.events.map((event, index) => (
                                        <Grid item xs={12} sm={6} md={4} key={`history-event-${index}`}>
                                            <Box display="flex" justifyContent="space-between">
                                                <Box>
                                                    <Box className="wh-tw-text-xs wh-tw-font-medium">{event.title}</Box>
                                                    <Box className="wh-tw-text-[10px] wh-tw-text-gray1">{moment(toCurrentTimeZone(event.createdAt)).format('ll')}</Box>
                                                </Box>
                                                <Box mx={2} onClick={handleToggle}><ChevronRightIcon className="wh-tw-text-gray2" /></Box>
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        </Box>
                    )}
                </Card>
            ))}
            <Dialog fullWidth maxWidth="md" open={isOpen} onClose={() => handleToggle()} aria-labelledby="form-dialog-title">
                <DialogContent>
                    {renderContent()}
                </DialogContent>
            </Dialog>
        </Box>
    );
}