export enum NoteSections {
    PMH,
    MedicationsAndSupplements,
    ROS,
    Physical,
    MDM,
    Diagnosis,
    Plan,
    SpecialTests,
    NextAppointment
}

export const noteSectionsNames : { [id: string]: string; } = {
    [NoteSections.PMH]: 'PMH',
    [NoteSections.MedicationsAndSupplements]: 'Medications And Supplements',
    [NoteSections.ROS]: 'ROS',
    [NoteSections.Physical]: 'Physical',
    [NoteSections.MDM]: 'MDM',
    [NoteSections.Diagnosis]: 'Diagnosis',
    [NoteSections.Plan]: 'Plan',
    [NoteSections.SpecialTests]: 'Additional Tests',
    [NoteSections.NextAppointment]: 'Next Appointment',
}