import { Box } from '@material-ui/core';
import React from 'react';
import { useFacade } from './patientInitialConsultPageBasic.hooks';
import { PatientInitialConsultPageNew } from './New/PatientInitialConsultPageNew';
import { PatientInitialConsultPageOld } from './Old/PatientInitialConsultPageOld';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';



export const PatientInitialConsultBasePage: React.FC = (props: any) => {
    const noteId = Number(props.match.params.noteId);

    const [
        {
            isLoading,
            note
        },
    ] = useFacade(noteId);

    if (isLoading) {
        return <WildHealthLinearProgress />
    }

    return (
        <Box>
            {
                note.isOldIcc?
                <PatientInitialConsultPageOld noteId={noteId}></PatientInitialConsultPageOld>
                :
                <PatientInitialConsultPageNew noteId={noteId}></PatientInitialConsultPageNew>
            }
        </Box>
    )
};