import {Box, Tooltip, useMediaQuery, useTheme} from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import {WildHealthTableCell} from "../../../common/components/wildHealthTable/WildHealthTableCell";
import {WildHealthTableRow} from "../../../common/components/wildHealthTable/WildHealthTableRow";
import {renderEmptyValue} from "../../../common/helpers/empty-value";
import {ValuesDetailsComponent} from "../../../inputs/components/valuesDetailsComponent/ValuesDetailsComponent";
import {
  ValuesDetailsComponentForBloodPressure
} from "../../../inputs/components/valuesDetailsComponent/ValuesDetailsComponentForBloodPressure";
import {renderLabValuesGraph} from "../../../inputs/helpers/render-lab-values-graph";
import {LabInputNotificationType, LabRangeModel, VitalValueSourceType,} from "../../../inputs/models/input.models";
import {VitalModel, VitalValueModel} from "../../models/vital.model";
import {VitalsHistoryMode, VitalsHistoryView} from "../../stores";
import {useStyles, VitalsTextField} from "./vitalRowComponent.styles";
import WarningIcon from "@material-ui/icons/Warning";
import warning from "@img/icons/warning.svg";
import {VitalsNames, vitalsNormalValues} from "../../validators/vital.validator";

interface PopoverProps {
  popoverAnchorEl: HTMLElement | null;
  handlePopoverOpen: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    inputId: number
  ) => void;
  handlePopoverClose: (id: number) => void;
}

interface VitalRowComponentProps {
  index: number;
  vitals: VitalModel[];
  view: VitalsHistoryView;
  mode: VitalsHistoryMode;
  popoverProps: PopoverProps;
  errors: { [id: string]: string };
  handleChanges: (vitalId: number, valueId: number, value: string) => void;
  onChangeCB: (value: string, index?: number) => string;
}

export const VitalRowComponent: React.FC<VitalRowComponentProps> = (
  props: VitalRowComponentProps
) => {
  const { index, vitals, view, mode, popoverProps, errors, handleChanges, onChangeCB } =
    props;

  const { popoverAnchorEl, handlePopoverOpen, handlePopoverClose } = popoverProps;

  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  if (!vitals.length) {
    return <></>;
  }

  const vital = vitals[0];

  const nameCell = (
    <WildHealthTableCell key={`name-${index}`} className={classes.cellName}>
      <Box display='flex' alignItems='flex-end'>
        <Box>{vital.displayName}</Box>
        <Box className={classes.dimension}>
          <span>
            &nbsp;
            {vital.dimension && `(${vital.dimension === '"' ? "in" : vital.dimension})`}
          </span>
        </Box>
      </Box>
    </WildHealthTableCell>
  );

  const inputProps = () => {
    return {
      min: 0,
      style: {
        padding: "8px 5px",
      },
      onKeyDown: (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault(),
    };
  };

  const renderNotificationImage = (notificationType: LabInputNotificationType) => {
    switch (notificationType) {
      case LabInputNotificationType.Empty:
        return <></>;
      case LabInputNotificationType.Error:
        return <img src={warning} alt='img' />;
      case LabInputNotificationType.Warning:
        return <WarningIcon className='warning' />;
    }
  };

  const renderWarning = (value: VitalValueModel) => {
    return (
      <Box mt='1.5px' ml={1}>
        <Tooltip
          title={value.notification.message}
          classes={{
            tooltip: classes.notificationToolTipPopper,
            arrow: classes.notificationToolTipArrow,
          }}
          arrow
        >
          {renderNotificationImage(value.notification.notificationType)}
        </Tooltip>
      </Box>
    );
  };

  const renderPopover = (range: LabRangeModel) => {
    return (
      <ValuesDetailsComponent
        id={vital.id}
        values={vital.values}
        vitalValues={vitals}
        range={range}
        title='Trend Details'
        isOpen={Boolean(popoverAnchorEl)}
        anchorEl={isSmallScreen ? null : popoverAnchorEl}
        handleClose={handlePopoverClose}
      />
    );
  };

  const renderPopoverForBloodPressure = (range: LabRangeModel) => {
    return (
      <ValuesDetailsComponentForBloodPressure
        id={vital.id}
        values={vital.values}
        vitalValues={vitals}
        range={range}
        title='Trend Details'
        isOpen={Boolean(popoverAnchorEl)}
        anchorEl={isSmallScreen ? null : popoverAnchorEl}
        handleClose={handlePopoverClose}
      />
    );
  };

  const renderGraph = () => {
    const range = vitalsNormalValues.get(vital.displayName);
    return [
      nameCell,
      <WildHealthTableCell key={`trends-${index}`}>
        {
          <Box
            className={classes.graphContainer}
            onMouseOver={(e) => handlePopoverOpen(e, vital.id)}
            onMouseOut={() => handlePopoverClose(vital.id)}
          >
            {vital.values.filter((x) => x.isInitialized).length < 2
              ? renderEmptyValue("Insufficient data for trending")
              : vitals.map((item) =>
                  renderLabValuesGraph(
                    item.values,
                    vitalsNormalValues.get(item.name),
                    50,
                    450
                  )
                )}
            {vitals.length > 1
              ? renderPopoverForBloodPressure(range)
              : renderPopover(range)}
          </Box>
        }
      </WildHealthTableCell>,
    ];
  };

  const renderViewValues = () => {
    let cells = [nameCell];

    const getWarning = (index: number) => {
      const hasError = vitals.find(
        (i) =>
          i.values[index].notification?.notificationType ===
          LabInputNotificationType.Error
      );
      if (hasError) {
        const value = hasError.values[index];
        if (value && value.notification) {
          return renderWarning(value);
        }
      }

      return <></>;
    };

    const getValue = (value: VitalValueModel) => {
      const outOfNorm =
        value.notification?.notificationType === LabInputNotificationType.Error;

      return !value.isInitialized ? (
        renderEmptyValue("-")
      ) : (
        <Box key={`${value.id}-${value.name}`} display='flex' alignItems='center'>
          <Box className={clsx({ [classes.danger]: outOfNorm })}>{value.value}</Box>
        </Box>
      );
    };

    vital.values.forEach((item, index) => {
      const warning = getWarning(index);

      cells.push(
        <WildHealthTableCell key={`${item.id}-${item.name}`}>
          <Box display='flex' alignItems='center'>
            {vitals
              .map((x) => getValue(x.values[index]))
              .reduce(
                (a, c) => (!a.length ? [c] : [...a, <span key={c.key}> / </span>, c]),
                []
              )}
            {warning}
          </Box>
        </WildHealthTableCell>
      );
    });

    cells.push(<WildHealthTableCell />);

    return cells;
  };

  const renderEditValues = () => {
    let cells = [nameCell];
    const getValue = (item: VitalValueModel, vitalId: number, index: number) => {
      const error = errors[`${vitals.find((i) => i.id === vitalId).name}${item.id}`];

      const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        handleChanges(vitalId, item.id, value);
        onChangeCB(value, index);
      };

      return (
        <React.Fragment key={vitalId}>
          <VitalsTextField
            fullWidth
            size='small'
            variant='outlined'
            type="number"
            disabled={item.sourceType === VitalValueSourceType.MobileApplication}
            value={item.value ? item.value : ""}
            className={classes.textField}
            error={!!error}
            helperText={error}
            InputProps={{
              inputProps: inputProps(),
              readOnly: item.name === VitalsNames.BMI
            }}
            onChange={onChange}
          />
        </React.Fragment>
      );
    };

    vital.values.forEach(
      (item, index) =>
        cells.push(
          <WildHealthTableCell key={index} className={classes.cell}>
            <Box display='flex' alignItems='flex-start' maxWidth={268}>
              {vitals
                .map((x) => getValue(x.values[index], x.id, index))
                .reduce(
                  (a, c) =>
                    !a.length
                      ? [c]
                      : [
                          ...a,
                          <Box p={1} key={index}>
                            /
                          </Box>,
                          c,
                        ],
                  []
                )}
            </Box>
          </WildHealthTableCell>
        )
    );

    cells.push(
      <React.Fragment key={cells.length}>
        <WildHealthTableCell />
      </React.Fragment>
    );

    return cells;
  };

  const modes = new Map<VitalsHistoryMode, JSX.Element[]>([
    [VitalsHistoryMode.Edit, renderEditValues()],
    [VitalsHistoryMode.Normal, renderViewValues()],
  ]);

  const views = new Map<VitalsHistoryView, JSX.Element[]>([
    [VitalsHistoryView.Graph, renderGraph()],
    [VitalsHistoryView.Values, modes.get(mode)],
  ]);

  return <WildHealthTableRow key={index}>{views.get(view)}</WildHealthTableRow>;
};
