import { Box, Table, TableBody, TableContainer } from '@material-ui/core';
import React from 'react';
import { WildHealthContainer } from '../../../common/components/wildHealthContainer/WildHealthContainer';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { useFacade } from './syncRecordTypeComponent.hooks';
import { FeatureComponent } from "../../../common/components/featureFlags/FeatureComponent";
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from 'clsx';
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import { titleBysyncRecordStatus } from '../../models/syncRecordStatus.enums';
import { useStyles } from "./syncRecordTypeComponent.styles";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { SearchComponent } from '../../../common/components/searchComponent/SearchComponent';

interface SyncRecordTypeComponentProps {
  typeName: string;
  practiceId: number;
}

export const SyncRecordTypeComponent: React.FC<SyncRecordTypeComponentProps> = (props: SyncRecordTypeComponentProps) => {
    const [
        {
            isLoading,
            syncRecordStatusCounts,
            searchQuery,
            searchSyncRecords
        },
        handleGoTargetSyncRecordList,
        handleSearchSubmit
    ] = useFacade(props.typeName, props.practiceId);

    const classes = useStyles();
    const commonClasses = commonUseStyles();

    const renderContent = () => {
        if (isLoading) {
            return <WildHealthLinearProgress />
        }

        if (!isLoading && !syncRecordStatusCounts) {
            return <WildHealthPlaceHolder message="No Sync Records" />
        }

        return (
            <Box p={2}>
                {
                    Object.keys(syncRecordStatusCounts).map((category, index) => {
                        return (
                            <Box pb={2} key={index}>
                                <Box mb={2} className={clsx(commonClasses.size20, commonClasses.textBold, commonClasses.colorBlack)}>{titleBysyncRecordStatus.get(syncRecordStatusCounts[category][0].statusId)}</Box>
                                <TableContainer>
                                    <Table>
                                        <TableBody>
                                        {
                                            syncRecordStatusCounts[category].map(syncRecordStatusCount => (
                                                <WildHealthTableRow key={syncRecordStatusCount.statusId}>
                                                    <WildHealthTableCell onClick={() => handleGoTargetSyncRecordList(syncRecordStatusCount)}>
                                                        <Box className={commonClasses.pointer} display="flex" alignItems="center" justifyContent="space-between">
                                                            <span>{syncRecordStatusCount.status}({syncRecordStatusCount.count})</span>
                                                            <ArrowForwardIosIcon className={classes.icon} />
                                                        </Box>
                                                    </WildHealthTableCell>
                                                </WildHealthTableRow>
                                            ))
                                        }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        )
                    })
                }
            </Box>
        )
    }

    const renderSearchResultContent = () => {
        if (isLoading) {
            return <WildHealthLinearProgress />
        }

        if (!isLoading && !searchSyncRecords) {
            return <WildHealthPlaceHolder message="No Search Results" />
        }

        return (
            <Box p={2}>
                <Box pb={2}>
                    <TableContainer>
                        <Table>
                            <TableBody>
                            {
                                searchSyncRecords.map(syncRecordList => (
                                    <WildHealthTableRow key={syncRecordList.statusId}>
                                        <WildHealthTableCell onClick={() => handleGoTargetSyncRecordList(syncRecordList)}>
                                            <Box className={commonClasses.pointer} display="flex" alignItems="center" justifyContent="space-between">
                                                <span>{syncRecordList.status}({syncRecordList.total})</span>
                                                <ArrowForwardIosIcon className={classes.icon} />
                                            </Box>
                                        </WildHealthTableCell>
                                    </WildHealthTableRow>
                                ))
                            }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        )
    }

    return (
        <Box p={2} width={1}>
            <Box my={2} display="flex" alignItems="center" justifyContent="space-between">
                <Box>
                    <FeatureComponent featureFlag={FeatureFlag.Navigation}>
                        <Box className={clsx(commonClasses.size24, commonClasses.textSemiBold, commonClasses.colorBlack, commonClasses.capitalize)}>{props.typeName}</Box>
                    </FeatureComponent>
                </Box>
                <Box>
                    <SearchComponent handleSearch={handleSearchSubmit} placeholder="Search by Name, ID or Email" />
                </Box>
            </Box>

            <WildHealthContainer margin={0} minHeight={'100%'}>
                {searchQuery ? renderSearchResultContent() : renderContent()}
            </WildHealthContainer>
        </Box>
    );
}