import { colors } from '../../../common/constants/colors'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
    container: {
        marginRight: '10px',
        marginTop: '10px',
        marginBottom: '10px',
        display: 'flex'
    },
    title: {
        fontWeight: 500
    },
    itemsBlock: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    columnFormat: {
        display: 'flex',
        flexDirection: 'column'
    },
    rowFormat: {
        display: 'flex',
        flexDirection: 'row'
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '8px',
        color: colors.main,
        fontSize: 14
    },
    buttonText: {
        marginLeft: '6px',
        textTransform: 'capitalize'
    }
})