import React from 'react';
import { Box, Dialog, DialogActions, DialogContent, Grid, TextField, Typography } from "@material-ui/core";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import clsx from 'clsx';
import moment from "moment";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { useFacade } from "./medicationComponent.hooks";
import { useStyles } from "./medicationComponent.styles";
import { MedicationComponentFieldNames } from "./medicationComponent.validator";
import { MedicationComponentProps } from "./medicationComponent.props";
import { WildHealthDatePicker } from "../../../common/components/WildHealthDatePicker";
import { toCurrentTimeZone } from '../../../timezones/helpers/timezone';

export const MedicationEditDialogComponent: React.FC<MedicationComponentProps> = (props: MedicationComponentProps) => {
    const {
        isOpened,
     } = props;

    const classes = useStyles();
    const [
        {
            model,
            errors,
        },
        handleChanges,
        handleClose,
        handleSubmit,
    ] = useFacade(props);

    const getDate = (date) => {
        return date
            ? moment(toCurrentTimeZone(date)).format('YYYY-MM-DD')
            : new Date()
    }

    return (
        <Dialog
            onClose={() => handleClose()}
            open={isOpened}
        >
            <MuiDialogTitle
                className={classes.dialogTitle}
                disableTypography
            >
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <Typography variant="h6">Edit Medication</Typography>
                </Grid>
            </MuiDialogTitle>

            <DialogContent dividers>
                <Box mb={1} display="flex" flexDirection="column">
                    <TextField
                        required
                        fullWidth
                        size="small"
                        label="Name"
                        variant="outlined"
                        value={model.name}
                        className={clsx(classes.field, 'input')}
                        error={!!errors[MedicationComponentFieldNames.itemName]}
                        helperText={errors[MedicationComponentFieldNames.itemName]}
                        onChange={(event) => handleChanges(MedicationComponentFieldNames.itemName, event.target.value)}
                    />
                    <TextField
                        fullWidth
                        size="small"
                        label="Strength"
                        variant="outlined"
                        value={model.dosage}
                        className={clsx(classes.field, 'input')}
                        error={!!errors[MedicationComponentFieldNames.dosage]}
                        helperText={errors[MedicationComponentFieldNames.dosage]}
                        onChange={(event) => handleChanges(MedicationComponentFieldNames.dosage, event.target.value)}
                    />
                    <TextField
                        fullWidth
                        size="small"
                        label="Dosing Instructions"
                        variant="outlined"
                        value={model.instructions}
                        className={clsx(classes.field, 'input')}
                        error={!!errors[MedicationComponentFieldNames.instructions]}
                        helperText={errors[MedicationComponentFieldNames.instructions]}
                        onChange={(event) => handleChanges(MedicationComponentFieldNames.instructions, event.target.value)}
                    />
                    <Box width={1} p={0.5}>
                        <WildHealthDatePicker
                            id={`start-date`}
                            fullWidth
                            label="Start Date"
                            size="small"
                            placeholder="yyyy-mm-dd"
                            openTo="date"
                            format="MM/DD/yyyy"
                            value={getDate(model.startDate)}
                            error={!!errors[`startDate`]}
                            helperText={errors[`startDate`]}
                            inputVariant="outlined"
                            views={["year", "month", "date"]}
                            onChange={(momentDate) => handleChanges(MedicationComponentFieldNames.startDate, momentDate)}
                        />
                    </Box>
                </Box>
            </DialogContent>

            <DialogActions>
                <WildHealthButton
                    id="medication-edit-cancel"
                    onClick={() => handleClose()}
                    color="tertiary"
                >
                    Cancel
                </WildHealthButton>
                <WildHealthButton
                    id="medication-edit"
                    onClick={() => handleSubmit()}
                    autoFocus
                >
                    Edit
                </WildHealthButton>
            </DialogActions>
        </Dialog>
    );
}
