import { Box, Grid, Tooltip } from "@material-ui/core";
import React from "react";
import moment from "moment";
import { WildHealthStatusLabel } from "../../modules/common/components/wildHealthStatusLabel/WildHealthStatusLabel";
import { PatientTagModel, TagSentiment, PatientNpsScoreTypeNames } from "../../modules/patients/models/patient.model";
import { useFacade } from "./patientMetaTags.hooks";
import { useStyles } from "./patientMetaTags.style";
import clsx from "clsx";

interface PatientMetaTagsComponentProps {
    patientId: number;
    isMessages?: boolean;
}

export const PatientMetaTagsComponent: React.FC<PatientMetaTagsComponentProps> = (props: PatientMetaTagsComponentProps) => {
    const { patientId, isMessages = false } = props;
    const classes = useStyles();

    const customTooltipClasses = {
        tooltip: classes.customWidth,
        arrow: classes.arrow,
        tooltipPlacementBottom: classes.tooltipPlacement,
        tooltipPlacementTop: classes.tooltipPlacement,
    };

    const [
        {
            metaTags,
            npsScores,
            isLoading,
            isNpsLoading
        }
    ] = useFacade(patientId);

    if (isLoading || isNpsLoading) {
        return <></>;
    }

    const renderTagColor = (tag: PatientTagModel) => {
        if (tag.name.toLowerCase().includes('due')) {
            return "bad";
        }
        switch (tag.sentiment) {
            case TagSentiment.Danger: return "bad";
            case TagSentiment.Success: return "good";
            case TagSentiment.Warning: return "average1";
            case TagSentiment.Normal:
            default: return "normal";
        }
    }

    return (
        isMessages
            ? <Box>
                <Box className={classes.titleSize} mb={1}>
                    {
                        metaTags.subscriptionPlan
                    }
                </Box>
                <Box>
                    {
                        metaTags.metaTags.length > 0 &&
                        <Grid container spacing={2} key={`patient-tag-${patientId}`}>
                            {
                                metaTags.metaTags.map((metaTad, index) => (
                                    <Grid item key={`patient-tag-${index}-${patientId}`}>
                                        <WildHealthStatusLabel color={renderTagColor(metaTad)}>{metaTad.name}</WildHealthStatusLabel>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    }
                </Box>
            </Box>
            : <Box display="flex" justifyContent='flex-end' alignContent="space-around" p={2.3} mt={1} className={classes.titleSize}>
                <Box className={classes.titleSubscription} mr={2.2}>
                    {
                        metaTags.subscriptionPlan
                    }
                </Box>
                {
                    metaTags.metaTags.length > 0 &&
                    <Grid container spacing={2} key={`patient-tag-${patientId}`}>
                        <Box className={classes.divider} key="divider" mt={1}></Box>
                        {
                            metaTags.metaTags.map((metaTad, index) => (
                                <Grid item key={`patient-tag-${index}-${patientId}`}>
                                    <WildHealthStatusLabel color={renderTagColor(metaTad)}>{metaTad.name}</WildHealthStatusLabel>
                                </Grid>
                            ))
                        }
                    </Grid>
                }
                {npsScores.length > 0 && (
                    <>
                    <Box className={classes.divider} mr={1.25}></Box>
                    <Box className={classes.titleSubscription}>
                        <Box pl={1} pr={0.5}>NPS: </Box>
                        {npsScores.map((item, index) => (
                            <Tooltip key="index" placement="top" arrow
                                title={<Box className={classes.notificationToolTipText}>
                                    {PatientNpsScoreTypeNames.get(item.npsScoreType)} {`(${moment(item.submittedAtWeek).format('MM/DD/YY')})`}
                                </Box>}
                                classes={customTooltipClasses}>
                                <Box pr={0.2}>
                                    <span className={clsx('wh-tw-underline wh-tw-cursor-pointer', 
                                        item.npsScore > 70 && 'wh-tw-text-good',
                                        item.npsScore >= 50 && item.npsScore < 71 && 'wh-tw-text-average1',
                                        item.npsScore < 50 && 'wh-tw-text-bad')}>{item.npsScore}</span>
                                    {npsScores.length - 1 != index && (<span>,</span>)}
                                </Box>
                            </Tooltip>
                        ))}
                    </Box>
                    </>
                )}
            </Box>
    );
}
