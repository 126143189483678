import { makeStyles } from "@material-ui/core";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: colors.white,
    maxWidth: 465,
    minHeight: 'calc(100vh - 220px)'
  },
  statusPointer: {
      marginTop: 10,
      borderRadius: 5,
      border: 'none',
      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: colors.lightGray,
      }
  },
  icon: {
    fill: colors.darkGray,
    width: 24,
    height: 24
  }
}));
