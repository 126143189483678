import {Query} from '@datorama/akita';
import { ApoeModel, LabInputModel, LabInputsDatasetEditableViewModel } from '../../models/input.models';
import {InputsState, inputsStore, InputsStore} from "./inputs.store";

/**
 * Provides inputs queries
 */
export class InputsQuery extends Query<InputsState> {
    uploads$ = this.select(state => state.fileInputs);
    labInputs$ = this.select(state => state.labInputs);
    generalInputs$ = this.select(state => state.generalInputs);
    dataSets$ = this.select(state => state.dataSets);
    microbiomeInputs$ = this.select(state => state.microbiomeInputs);
    viewMode$ = this.select(state => state.viewMode);
    apoeInfo$ = this.select(state => state.apoeInfo);

    constructor(protected store: InputsStore) {
        super(store);
    }

    public getDataSets(): LabInputsDatasetEditableViewModel[] {
        return this.getValue().dataSets;
    }

    public getLabInputs(): LabInputModel[] {
        return this.getValue().labInputs;
    }

    public getApoe(): ApoeModel {
        return this.getValue().apoeInfo;
    }
}

export const inputsQuery = new InputsQuery(inputsStore);
