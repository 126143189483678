import React from 'react';
import { Box, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Drawer, useTheme, useMediaQuery } from "@material-ui/core";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import clsx from 'clsx';
import { useFacade } from "./journeyTasksWidgetComponent.hooks";
import { useStyles } from "./journeyTasksWidgetComponent.styles";
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import { handleCta } from '../../../patients/helpers/taskCtaHandler';

interface JourneyTasksWidgetComponentProps {
  patientId: number | null;
}

export const JourneyTasksWidgetComponent: React.FC<JourneyTasksWidgetComponentProps> = (props: JourneyTasksWidgetComponentProps) => {
    const [
        {
            isLoading,
            tasks,
            completes,
            selectedTaskId,
            isSideAreaOpen
        },
        handleComplete,
        handleGotoJourneyTaskPage,
        handleToggleSideArea
    ] = useFacade(props.patientId);

    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const renderSideArea = () => {
        const targetTask = tasks.find(x => x.id === selectedTaskId);
        if (targetTask) {
            return (
                <Box className={clsx('flat-scroll', classes.sideContainer)} display="flex" flexDirection="column">
                    {!isSmallScreen && 
                        <Box display="flex" alignItems="center" justifyContent="space-between" pb={2}>
                            <Box className="wh-tw-font-medium wh-tw-text-xl wh-tw-text-gray900">My Task</Box>
                            <IconButton
                                id={`task-${targetTask.id}`}
                                size="small"
                                onClick={() => handleToggleSideArea(0, false)}
                            >
                                <CloseIcon className="wh-tw-text-gray900" />
                            </IconButton>
                        </Box>
                    }
                    <Box py={3}>
                        <Box className="wh-tw-font-medium wh-tw-text-lg wh-tw-text-center md:wh-tw-text-left">{targetTask.title}</Box>
                        <Box mt={1.5} className="wh-tw-text-sm wh-tw-text-gray600">{targetTask.description}</Box>
                    </Box>
                    {targetTask.taskCTA &&
                        <Box flex={1} display="flex" alignItems="flex-end" justifyContent="center" pt={2}>
                            <WildHealthButton
                                id="mark-complete-button"
                                fullWidth
                                size="large"
                                borderRadius={50}
                                disabled={isLoading || !!props.patientId}
                                loading={isLoading}
                                onClick={() => handleCta(targetTask)}
                            >
                                {targetTask.taskCTA.title}
                            </WildHealthButton>
                        </Box>
                    }
                    
                </Box>
            )
        }

        return <></>
    }

    const renderContent = () => {
        return <List className={classes.list}>
            {!!tasks.length && tasks.map((item, index) =>
                <ListItem className={classes.listItem} key={index}>
                    <ListItemIcon>
                        <IconButton
                            id={`task-${item.id}`}
                            size="small"
                            onClick={() => handleComplete(item, false)}
                            disabled={isLoading || !!props.patientId}
                        >
                            {completes.includes(item.id) ? <CheckCircleIcon className="wh-tw-text-primaryV" /> : <RadioButtonUncheckedIcon className="wh-tw-text-primaryV" />}
                        </IconButton>
                    </ListItemIcon>
                    <ListItemText
                        primary={item.title}
                        className="wh-tw-cursor-pointer"
                        onClick={() => !props.patientId && handleCta(item)}
                    />
                    <ListItemSecondaryAction>
                        <IconButton
                            id={`task-${item.id}-side-area-drawer`}
                            size="small"
                            onClick={() => handleToggleSideArea(item.id, true)}
                        >
                            <KeyboardArrowRightIcon className="wh-tw-text-gray600" />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            )}
            <ListItem className="wh-tw-flex wh-tw-items-center wh-tw-justify-center" onClick={() => handleGotoJourneyTaskPage()} button key={-1}>
                <Box display='flex' alignItems='center'>
                    <Box mr={1} className="wh-tw-text-primaryV wh-tw-text-sm">
                    View Full Roadmap
                    </Box>
                    <ArrowRightAltIcon className="wh-tw-text-primaryV" />
                </Box>
            </ListItem>
        </List>
    }

    return (
        <>
            {renderContent()}
            <Drawer
                id="side-area-drawer"
                anchor='right'
                open={isSideAreaOpen}
                classes={{ paper: classes.papper }}
                onClose={() => handleToggleSideArea(0, false)}
            >
                <Box className={classes.sideMobile}>
                    {renderSideArea()}
                </Box>
            </Drawer>
        </>
    );
}
