import { Store, StoreConfig } from '@datorama/akita';
import {
    AppointmentCancellationReason,
    AppointmentStatus,
    AppointmentWithType,
} from '../../models/appointments.enums';
import {
    AppointmentOptions,
    AppointmentsSummaryModel,
    AppointmentTypeModel,
    BaseAppointmentModel,
    EmployeeAppointmentModel,
    UpcomingAppointment,
    AppointmentSequenceNumbersModel, AppointmentsSequenceInfoModel
} from '../../models/appointments.models';
import { PatientAppointmentModel } from '../../models/appointments.models';
import { timezonesQuery } from "../../../timezones/stores/timezones";
import { toTimeZoneDate } from "../../../timezones/helpers/timezone";
import {AppointmentsStatisticModel} from "../../models/appointmentsStatistic.models";

export interface AppointmentsState {
    employeeAppointmentsSource: EmployeeAppointmentModel[];
    recentlyAddedAppointmentsSource: EmployeeAppointmentModel[];
    patientAppointmentsSource: PatientAppointmentModel[];
    upcomingAppointmentSource: UpcomingAppointment;
    appointmentOptionsSource: AppointmentOptions[];
    employeeAppointments: EmployeeAppointmentModel[];
    recentlyAddedAppointments:EmployeeAppointmentModel[];
    recentlyNoUploadedLabFileAppointments:EmployeeAppointmentModel[];
    patientAppointments: PatientAppointmentModel[];
    upcomingAppointment: UpcomingAppointment;
    appointmentOptions: AppointmentOptions[];
    appointmentTypes: AppointmentTypeModel[];
    allAppointmentTypes: AppointmentTypeModel[];
    appointmentAvailableTypes: AppointmentWithType[];
    appointmentsSummary: AppointmentsSummaryModel;
    appointmentsStatistic: AppointmentsStatisticModel;
    appointmentsIncludeTags: EmployeeAppointmentModel[];
    appointmentSequenceInfo: AppointmentsSequenceInfoModel[];
}

/**
 * Creates initial appointment state
 */
export function createInitialState(): AppointmentsState {
    return {
        employeeAppointmentsSource: [],
        patientAppointmentsSource: [],
        upcomingAppointmentSource: null,
        appointmentOptionsSource: [],
        patientAppointments: [],
        employeeAppointments: [],
        recentlyAddedAppointmentsSource:[],
        recentlyAddedAppointments:[],
        recentlyNoUploadedLabFileAppointments: [],
        upcomingAppointment: null,
        appointmentOptions: [],
        appointmentTypes: [],
        allAppointmentTypes: [],
        appointmentAvailableTypes: [],
        appointmentsSummary: null,
        appointmentsStatistic: null,
        appointmentsIncludeTags: [],
        appointmentSequenceInfo: []
    };
}

/**
 * Provides appointment state management
 */
@StoreConfig({ name: 'appointments', resettable: true })
export class AppointmentsStore extends Store<AppointmentsState> {
    constructor() {
        super(createInitialState());
    }

    public setPatientAppointmentTypes(appointmentTypes: AppointmentTypeModel[]) {
        this.update({
            appointmentTypes: appointmentTypes.filter(x => !x.isExcluded),
            allAppointmentTypes: appointmentTypes
        })
    }

    public setPatientAppointments(appointmentsSource: PatientAppointmentModel[]): void {
        const appointmentsView = appointmentsSource.map(x => AppointmentsStore._toPatientView(x));

        this.update({
            patientAppointmentsSource: appointmentsSource,
            patientAppointments:  appointmentsView.filter(x => x.cancellationReason !== AppointmentCancellationReason.Reschedule)
        })
    }

    public setEmployeeAppointments(appointmentsSource: EmployeeAppointmentModel[]): void {
        const appointmentsView = appointmentsSource.map(x => AppointmentsStore._toEmployeeView(x));

        this.update({
            employeeAppointmentsSource: appointmentsSource,
            employeeAppointments: appointmentsView.filter(x => x.cancellationReason !== AppointmentCancellationReason.Reschedule)
        })
    }

    public setEmployeeRecentlyAddedAppointments(appointmentsSource: EmployeeAppointmentModel[]): void {
        const appointmentsView = appointmentsSource.map(x => AppointmentsStore._toEmployeeView(x));

        this.update({
            recentlyAddedAppointmentsSource: appointmentsSource,
            recentlyAddedAppointments: appointmentsView
        })
    }

    public setRecentlyNoUploadedLabFileAppointments(appointmentsSource: EmployeeAppointmentModel[]): void {
        const appointmentsView = appointmentsSource.map(x => AppointmentsStore._toEmployeeView(x));

        this.update({
            recentlyNoUploadedLabFileAppointments: appointmentsView,
        })
    }

    public signOffAppointment(appointmentId: number): void {
        this.update({ recentlyNoUploadedLabFileAppointments: this.getValue().recentlyNoUploadedLabFileAppointments.filter(i => i.id !== appointmentId) });
    }

    public insertPatientAppointment(appointmentSource: PatientAppointmentModel): void {
        const appointmentView = AppointmentsStore._toPatientView(appointmentSource);

        this.update({
            patientAppointmentsSource: [...this.getValue().patientAppointmentsSource, appointmentSource],
            patientAppointments: [...this.getValue().patientAppointments, appointmentView]
        });
    }

    public insertEmployeeAppointment(appointmentSource: EmployeeAppointmentModel): void {
        const appointmentView = AppointmentsStore._toEmployeeView(appointmentSource);

        this.update({
            employeeAppointmentsSource: [...this.getValue().employeeAppointmentsSource, appointmentSource],
            employeeAppointments: [...this.getValue().employeeAppointments, appointmentView]
        });
    }

    public editPatientAppointment(appointmentSource: PatientAppointmentModel): void {
        const appointmentView = AppointmentsStore._toPatientView(appointmentSource);

        const appointmentsSource = this.getValue().patientAppointmentsSource.map((x) => (x.id === appointmentSource.id) ? Object.assign(x, appointmentSource) : x);
        const appointmentsView = this.getValue().patientAppointments.map((x) => (x.id === appointmentView.id) ? Object.assign(x, appointmentView) : x);

        this.update({
            patientAppointmentsSource: appointmentsSource,
            patientAppointments: appointmentsView
        });
    }

    public editAppointment(appointment: BaseAppointmentModel): void {
        const existingSource = this.getValue().patientAppointmentsSource.find(x => x.id === appointment.id);
        if (!existingSource) {
            return;
        }

        const updatedSource = Object.assign(existingSource, appointment);
        const appointmentView = AppointmentsStore._toEmployeeView(existingSource);

        const appointmentsSource = this.getValue().patientAppointmentsSource.map((x) => (x.id === updatedSource.id) ? updatedSource : x);
        const appointmentsView = this.getValue().patientAppointments.map((x) => (x.id === appointmentView.id) ? appointmentView : x);

        this.update({
            employeeAppointmentsSource: appointmentsSource,
            employeeAppointments: appointmentsView
        });
    }

    public cancelPatientAppointment(appointment: BaseAppointmentModel): void {
        const existingSource = this.getValue().patientAppointmentsSource.find(x => x.id === appointment.id);
        const existingView = this.getValue().patientAppointments.find(x => x.id === appointment.id);

        if (!existingSource) {
            return;
        }

        existingSource.status = AppointmentStatus.Canceled;
        existingView.status = AppointmentStatus.Canceled;

        this.update({
            patientAppointmentsSource: this.getValue().patientAppointmentsSource.map(x => x.id === existingSource.id ? existingSource : x),
            patientAppointments: this.getValue().patientAppointments.map(x => x.id === existingView.id ? existingView : x),
        });
    }

    public cancelEmployeeAppointment(appointment: BaseAppointmentModel): void {
        const existingSource = this.getValue().employeeAppointmentsSource.find(x => x.id === appointment.id);
        const existingView = this.getValue().employeeAppointments.find(x => x.id === appointment.id);

        if (!existingSource) {
            return;
        }

        existingSource.status = AppointmentStatus.Canceled;
        existingView.status = AppointmentStatus.Canceled;

        this.update({
            employeeAppointmentsSource: this.getValue().employeeAppointmentsSource.map(x => x.id === existingSource.id ? existingSource : x),
            employeeAppointments: this.getValue().employeeAppointments.map(x => x.id === existingView.id ? existingView : x),
        });
    }

    public cancelEmployeeAppointmentForDashboard(appointment: BaseAppointmentModel): void {
        const existingSource = this.getValue().employeeAppointmentsSource.find(x => x.id === appointment.id);
        const existingView = this.getValue().employeeAppointments.find(x => x.id === appointment.id);

        if (!existingSource) {
            return;
        }

        existingSource.status = AppointmentStatus.Canceled;
        existingView.status = AppointmentStatus.Canceled;

        this.update({
            employeeAppointmentsSource: this.getValue().employeeAppointmentsSource.filter(x => x.id !== existingSource.id),
            employeeAppointments: this.getValue().employeeAppointments.filter(x => x.id !== existingView.id),
        });
    }

    public rescheduleAppointment(id: number, appointment: BaseAppointmentModel): void {
        const existingSource = this.getValue().employeeAppointmentsSource.find(x => x.id === id);
        if (!existingSource) {
            return;
        }

        const employeeAppointmentsSource = this.getValue().employeeAppointmentsSource.filter(x => x.id !== id);
        const employeeAppointments = this.getValue().employeeAppointments.filter(x => x.id !== id);

        this.update({
            employeeAppointmentsSource: [...employeeAppointmentsSource, appointment],
            employeeAppointments: [...employeeAppointments, AppointmentsStore._toEmployeeView(appointment)],
        });
    }

    public reschedulePatientAppointment(id: number, appointment: BaseAppointmentModel): void {
        const existingSource = this.getValue().patientAppointmentsSource.find(x => x.id === id);
        if (!existingSource) {
            return;
        }

        const patientAppointmentsSource = this.getValue().patientAppointmentsSource.filter(x => x.id !== id);
        const patientAppointments = this.getValue().patientAppointments.filter(x => x.id !== id);

        this.update({
            patientAppointmentsSource: [...patientAppointmentsSource, appointment],
            patientAppointments: [...patientAppointments, AppointmentsStore._toPatientView(appointment)],
        });
    }

    public updateAppointmentOptions(options: AppointmentOptions): void {
        const allOptions = this.getValue().appointmentOptions;
        this.update({ appointmentOptions: allOptions.filter(x => x.id === options.id ? options : x) });
    }

    // #region private

    private static _toPatientView(appointment: PatientAppointmentModel): PatientAppointmentModel {
        const timezone = timezonesQuery.getMyTimezone();

        const copy = Object.assign({}, appointment);

        copy.startDate = toTimeZoneDate(copy.startDate, timezone);
        copy.endDate = toTimeZoneDate(copy.endDate, timezone);
        if (copy.cancelledAt) {
            copy.cancelledAt = toTimeZoneDate(copy.cancelledAt, timezone);
        }

        return copy;
    }

    private static _toEmployeeView(appointment: EmployeeAppointmentModel): EmployeeAppointmentModel {
        const timezone = timezonesQuery.getMyTimezone();

        const copy = Object.assign({}, appointment);

        copy.startDate = toTimeZoneDate(copy.startDate, timezone);
        copy.endDate = toTimeZoneDate(copy.endDate, timezone);

        if (copy.cancelledAt) {
            copy.cancelledAt = toTimeZoneDate(copy.cancelledAt, timezone);
        }

        return copy;
    }

    // #endregion
}

export const appointmentsStore = new AppointmentsStore();
