import { LabInputModel, LabInputsDatasetViewModel, LabInputsDatasetEditableViewModel, LabValidationSource, LabRangeModel } from "../models/input.models";
import {sortLabInputValues} from "./sort-lab-input-values";
import {LabReportValueModel} from "../../healthReport/models/healthReport.models";

declare global {
    interface Array<T> {
        getLabReportValue(name: string): LabReportValueModel;
    }
}

export const getLabLatestValue = (input: LabInputModel): number | null => {
    const latestValue = input.values.sort(sortLabInputValues)[0];

    return latestValue?.isInitialized
        ? Number(latestValue.value)
        : null;
}

export const getDataSetByLabLatestValue = (dataSets: LabInputsDatasetEditableViewModel[] | LabInputsDatasetViewModel[], input: LabInputModel): any => {
    const datesetForRecentValue = input.values && input.values.length > 0 ? dataSets.find(x => x.id === [...input.values].sort(sortLabInputValues)[0].dataSetId) : dataSets[0];

    return datesetForRecentValue ?? dataSets[0];
}

export const getRange = (input: LabInputModel): LabRangeModel | null => {
    if (input.values && input.values.length > 0) {
        const latestValue = input.values.sort(sortLabInputValues)[0];
        const range = latestValue?.range;
        const ranges = input.values.map(x => x.range);
        const wildHealthOptimalRange = ranges.find(x => x.source === LabValidationSource.Custom);
    
        return range ?? wildHealthOptimalRange;
    }

    return null;
}


/*eslint no-extend-native: ["error", { "exceptions": ["Array"] }]*/
Array.prototype.getLabReportValue = function (name: string): LabReportValueModel {
    const _self = this as Array<LabReportValueModel>;
    return _self.find(x => x.name === name);
};

export { };
