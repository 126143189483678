import { Box, Divider } from '@material-ui/core';
import React from 'react';
import { AddOnProvider } from '../../../addons/models/addOns.models';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { PricingDialogComponent } from '../pricingDialogComponent/PricingDialogComponent';
import { AddNewLabPricingDialogComponent } from "../addNewLabPricingComponent/AddNewLabPricingComponent";
import { UpdateLabPricingDialogComponent } from "../updateLabPricingComponent/UpdateLabPricingComponent";
import { UploadExcelFileDialogComponent } from "../uploadDocumentDialogComponent/UploadExcelFileDialogComponent";
import { useFacade } from './labOrderIFrameComponent.hooks';
import { useStyles, PricingButton } from './labOrderIFrameComponent.styles';
import {Gender} from "../../../common/models/user.models";

interface LabOrderIFrameComponentProps {
    patientId: number;
    gender: Gender;
}

export const LabOrderIFrameComponent: React.FC<LabOrderIFrameComponentProps> = (props: LabOrderIFrameComponentProps) => {
    const { patientId, gender } = props;

    const classes = useStyles();

    const [
        {
            isLoading,
            iframeUrl,
        },
        handleViewPricing,
        handleAddNewAddOn,
        handleModifyAddOn,
        handleUploadAddOnsDocument
    ] = useFacade(patientId, gender);

    return (
        <Box width='100%'>
            <Box className={classes.root}>
                <Box className={classes.title}>
                    Pricing Lookup
                </Box>

                <Box mt={2} display='flex'>
                    <Box mr='10px'>
                        <PricingButton id="lab-orders-frame-lab-corp" size='small' onClick={() => handleViewPricing(AddOnProvider.LabCorp)}>
                            LabCorp
                        </PricingButton>
                    </Box>

                    <Box mr='10px'>
                        <PricingButton id="lab-orders-frame-quest" size='small' onClick={() => handleViewPricing(AddOnProvider.Quest)}>
                            Quest
                        </PricingButton>
                    </Box>


                    <Box>
                        <PricingButton id="lab-orders-frame-boston" size='small' onClick={() => handleViewPricing(AddOnProvider.Boston)}>
                            Boston
                        </PricingButton>
                    </Box>
                </Box>
            </Box>

            <Divider className={classes.divider} />

            <Box className={classes.root}>
            {
                isLoading ?
                <WildHealthLinearProgress /> :
                <iframe
                    title='labs order'
                    src={iframeUrl}
                    height={800}
                    width="100%"
                    key = "lab_order_iframe"
                />
            }
            </Box>

            <PricingDialogComponent handleNewPricing={handleAddNewAddOn} handleModifyLabPricing={handleModifyAddOn} handleUpdatePricingByDocument={handleUploadAddOnsDocument} />
            <AddNewLabPricingDialogComponent handleGoBack={(provider) => handleViewPricing(provider)} />
            <UpdateLabPricingDialogComponent handleGoBack={(provider) => handleViewPricing(provider)} />
            <UploadExcelFileDialogComponent />
        </Box>
    );
}
