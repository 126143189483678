import React from "react";
import { Box, Grid, TextField } from "@material-ui/core";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { UpdateAddressForm } from "../../../common/components/updateAddressForm/UpdateAddressForm";
import { useFacade } from "./businessAccountComponent.hooks";
import { phoneNumberInput } from "../../../common/components/PhoneNumberInput";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import commonUseStyles from '../../../common/styles/common.styles';

export const BusinessAccountComponent: React.FC = () => {
    const [
        {
            isLoading,
            isSubmitted,
            business,
            errors
        },
        handleChanges,
        handleSubmit
    ] = useFacade();
    const commonClasses = commonUseStyles();

    if (isLoading) {
        return (
            <Box p={5}>
                <WildHealthLinearProgress />
            </Box>
        )
    }

    return (
        <Box p={5}>
            <Grid container spacing={3}>
                <Grid item md={6} sm={12}>
                    <Box className={commonClasses.primaryTitle}>
                        Business Information
                    </Box>

                    <Box mt={3} width={1}>
                        <Box className={commonClasses.subtitle}>Business Name</Box>

                        <TextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            value={business.name}
                            error={!!errors['name']}
                            helperText={errors['name']}
                            onChange={(v) => handleChanges('name', v.target.value)}
                        />
                    </Box>

                    <Box mt={1}>
                        <UpdateAddressForm prefix={'address.'} address={business.address} errors={errors} handleChanges={handleChanges} />
                    </Box>

                    <Box mt={2} width={1}>
                        <Box className={commonClasses.subtitle}>Phone Number</Box>

                        <TextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            value={business.phoneNumber}
                            error={!!errors['phoneNumber']}
                            helperText={errors['phoneNumber']}
                            InputProps={{
                                className: 'input',
                                inputComponent: phoneNumberInput,
                            }}
                            onChange={(v) => handleChanges('phoneNumber', v.target.value)}
                        />
                    </Box>

                    <Box mt={2} width={1}>
                        <Box className={commonClasses.subtitle}>Tax ID Number</Box>

                        <TextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            value={business.taxIdNumber}
                            error={!!errors['taxIdNumber']}
                            helperText={errors['taxIdNumber']}
                            onChange={(v) => handleChanges('taxIdNumber', v.target.value)}
                        />
                    </Box>

                    <Box pt={5}>
                        <WildHealthButton id="bisiness-account-save" loading={isSubmitted} onClick={(event) => handleSubmit(event)}>
                            Save
                        </WildHealthButton>
                    </Box>
                </Grid>

                <Grid item md={6} sm={12}>
                    <Box className={commonClasses.primaryTitle}>
                        Billing Address
                    </Box>

                    <Box mt={3}>
                        <UpdateAddressForm prefix={'billingAddress.'} address={business.billingAddress} errors={errors} handleChanges={handleChanges} />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}