import {Box, IconButton, Popover, Table, TableBody, TableContainer, TableHead} from "@material-ui/core";
import {Add} from "@material-ui/icons";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import clsx from "clsx";
import React from "react";
import {WildHealthExternalLinkButton} from "../../../common/components/WildHealthLinkButton";
import {WildHealthMenuItem} from "../../../common/components/wildHealthMenu/WildHealthMenu";
import {WildHealthTableCell} from "../../../common/components/wildHealthTable/WildHealthTableCell";
import {WildHealthTableRow} from "../../../common/components/wildHealthTable/WildHealthTableRow";
import commonUseStyles from "../../../common/styles/common.styles";
import {SupplementItem} from "../../../notes/models/notes.models";
import {SupplementAddNewDialogComponent} from '../supplementsEditDialogComponent/SupplementAddNewDialogComponent';
import {SupplementEditDialogComponent} from '../supplementsEditDialogComponent/SupplementEditDialogComponent';
import {useFacade} from './supplementsComponent.hooks';
import {authQuery} from "../../../auth/stores/auth";
import {defaultDosage} from "../../constants/medicationsAndSupplementsContants";
import {WildHealthButton} from "../../../common/components/wildHealthButton/WildHealthButton";
import {
    RecommendFullscriptSupplementsComponent
} from "../recommendFullscriptSupplementsComponent/RecommendFullscriptSupplementsComponent";
import {FullscriptSupplementModel} from "../../models/fullscriptSupplementModels";
import {FeatureComponent} from "../../../common/components/featureFlags/FeatureComponent";
import {FeatureFlag} from "../../../common/components/featureFlags/featureFlags.models";
import {SupplementSource} from "../../models/patientSupplementModel";

interface SupplementsComponentProps {
    patientId: number;
    items: SupplementItem[];
    fullscriptSupplements: FullscriptSupplementModel[];
    handleAddSupplements: (medication: SupplementItem) => void;
    handleFullscriptSupplements: (supplements: FullscriptSupplementModel[]) => void;
    handleEditSupplements: (medication: SupplementItem) => void;
    handleRemoveSupplements: (id: number) => void;
}

export const SupplementsComponent: React.FC<SupplementsComponentProps> = (props: SupplementsComponentProps) => {
    const {
        items,
        fullscriptSupplements,
        handleAddSupplements,
        handleFullscriptSupplements,
        handleEditSupplements,
        handleRemoveSupplements,
    } = props;

    const [
        {
            isMenuOpened,
            isAddNewOpened,
            isRecommendationOpened,
            isEditorOpened,
            anchorEl,
            selectedId,
        },
        handleAddNewToggle,
        toggleRecommendation,
        handleEditToggle,
        handleToggleActions,
    ] = useFacade();

    const classes = commonUseStyles();

    const selectedItem = items.find(i => i.id === selectedId);

    return (
        <Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <WildHealthTableRow>
                            <WildHealthTableCell>
                                Name
                            </WildHealthTableCell>
                            <WildHealthTableCell>
                                Strength
                            </WildHealthTableCell>
                            <WildHealthTableCell>
                                Dosing Instruction
                            </WildHealthTableCell>
                            <WildHealthTableCell>
                                Purchase Link
                            </WildHealthTableCell>
                            <WildHealthTableCell style={{width: '20%'}}></WildHealthTableCell>
                        </WildHealthTableRow>
                    </TableHead>
                    <TableBody>
                    {
                        items.map((item, index) =>
                            <WildHealthTableRow key={index}>
                                <WildHealthTableCell>
                                    {item.isStopped ? <s>{item.name}</s> : item.name}
                                </WildHealthTableCell>
                                <WildHealthTableCell>
                                    {item.dosage}
                                </WildHealthTableCell>
                                <WildHealthTableCell>
                                    {item.instructions}
                                </WildHealthTableCell>
                                <WildHealthTableCell>
                                    <WildHealthExternalLinkButton id={`supplements-link-purchase-${item.id}`}  link={item.purchaseLink}/>
                                </WildHealthTableCell>
                                <WildHealthTableCell>
                                    <IconButton
                                        id={`supplements-more-actions-${item.id}`}
                                        size="small"
                                        onClick={(e) => handleToggleActions(item.id, e.currentTarget)}
                                    >
                                        <MoreHorizIcon />
                                    </IconButton>
                                </WildHealthTableCell>
                            </WildHealthTableRow>
                        )
                    }
                    {
                        (fullscriptSupplements ?? []).map((item, index) =>
                            <WildHealthTableRow key={index}>
                                <WildHealthTableCell>
                                    {item.name}
                                </WildHealthTableCell>
                                <WildHealthTableCell>
                                    {item.dosage}
                                </WildHealthTableCell>
                                <WildHealthTableCell>
                                    {item.instructions}
                                </WildHealthTableCell>
                                <WildHealthTableCell>

                                </WildHealthTableCell>
                                <WildHealthTableCell>

                                </WildHealthTableCell>
                            </WildHealthTableRow>
                        )
                    }
                    </TableBody>
                </Table>
            </TableContainer>
            <Box display="flex" alignItems="center" justifyContent="space-between" mt={1}>
                <Box display='flex' alignItems='stretch' py={1}>
                    <Box onClick={() => handleAddNewToggle(true)}>
                        <Add className={clsx(classes.colorMain, classes.backgroundGray3, classes.pointer)} />
                    </Box>
                    <Box className={classes.colorMain} ml={2}>
                        <span>Add Supplement</span>
                    </Box>
                </Box>
                <Box>
                    <FeatureComponent featureFlag={FeatureFlag.FullscriptIntegration}>
                        <WildHealthButton
                            id={`patient-supplements-fullscript`}
                            size="large"
                            onClick={() => toggleRecommendation(true)}
                        >
                            Recommend with Fullscript
                        </WildHealthButton>
                    </FeatureComponent>
                </Box>
            </Box>
            <Popover
                keepMounted
                anchorEl={anchorEl}
                open={isMenuOpened}
                onClose={() => handleToggleActions()}
                getContentAnchorEl={null}
            >
                <WildHealthMenuItem id="supplements-edit" onClick={() => handleEditToggle(true)}>
                    Edit
                </WildHealthMenuItem>
                <WildHealthMenuItem id="supplements-delete" onClick={() => { handleRemoveSupplements(selectedId); handleToggleActions(0); }}>
                    Delete
                </WildHealthMenuItem>
            </Popover>

            <SupplementAddNewDialogComponent
                isOpened={isAddNewOpened}
                initModel={{
                    id: 0,
                    name: '',
                    dosage: '',
                    instructions: authQuery.isEmployeeUser() ? defaultDosage : '',
                    purchaseLink: '',
                    isInCurrent: false,
                    isStopped: false,
                    source: SupplementSource.Notes
                }}
                handleSave={handleAddSupplements}
                handleClose={() => handleAddNewToggle(false)}
            />
            <SupplementEditDialogComponent
                isOpened={isEditorOpened}
                initModel={selectedItem}
                handleSave={handleEditSupplements}
                handleClose={() => handleEditToggle(false)}
            />
            <RecommendFullscriptSupplementsComponent
                open={isRecommendationOpened}
                patientId={props.patientId}
                onOrdered={handleFullscriptSupplements}
                handleClose={() => toggleRecommendation(false)}
            />
        </Box>
    )
}
