import React from 'react';
import { AuthenticatedLayoutComponent } from "../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent";
import { TitlesService } from "../../../../constants/title.constants";
import { FellowshipComponent } from "../../components/fellowship/FellowshipComponent";
import { PagesNamesService } from "../../../../constants/pagesNames.constants";
import { navigationNumber, NoPmRoster } from '../../../rosters/models/roster.model';

export const FellowshipPage: React.FC<any> = (props: any) => {
    let rosterId = props?.location?.state?.id ?? 1

    const isPmFellowship = !(rosterId === NoPmRoster.January21 || rosterId === NoPmRoster.July21 || rosterId === NoPmRoster.January22) ;

    return (
        <AuthenticatedLayoutComponent
            title={TitlesService.fellowshipTitle}
            pageName={`${PagesNamesService.fellowship}-${rosterId}`}
            selectedMenuItem={navigationNumber + rosterId}
        >
            <FellowshipComponent rosterId={rosterId} pmFellowship={isPmFellowship} />
        </AuthenticatedLayoutComponent>
    )
}