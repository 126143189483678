import {useCallback, useEffect, useState} from 'react';
import {selectShortcutService} from "../../services/selectShortcut.service";
import {ShortcutGroupModel, ShortcutModel, ShortcutGroupType} from "../../../employee/models/shortcut.models";
import {Subscription} from "rxjs";
import {onEmit} from "../../../common/helpers/on-emit";
import {shortcutsQuery} from "../../../employee/stores/shortcutsStore";
import {shortcutsService} from "../../../employee/services/shortcuts.service";

export interface SelectShortcutComponentState {
    isLoading: boolean;
    isOpen: boolean;
    search: string;
    handleSelect: Function;
    groups:ShortcutGroupModel[];
    filteredGroups: ShortcutGroupModel[];
    shortcuts: ShortcutModel[];
    filteredShortcuts: ShortcutModel[];
    selectedId: number;
    typeId: ShortcutGroupType;
    groupId: number;
}

export function useFacade(): [SelectShortcutComponentState, Function, Function, Function, Function, Function, Function]{
    const [state, setState] = useState({
        isLoading: true,
        isOpen: false,
        selectedId: 0,
        search: '',
        typeId: null,
        groupId: null
    } as SelectShortcutComponentState);

    const setInitialState = () => {
        setState({
            ...state,
            isOpen: false,
            selectedId: 0,
            search: '',
            typeId: null,
            groupId: null,
            handleSelect: null,
            filteredGroups: state.groups,
            filteredShortcuts: state.shortcuts
        });
    }

    const handleSelect = (id: number) => {
        setState(state => ({...state, selectedId: id}));
    }
    
    const handleType = (id: ShortcutGroupType) => {
        switch (id) {
            case ShortcutGroupType.All:
                setState({
                    ...state,
                    typeId: id,
                    filteredGroups: state.groups,
                    filteredShortcuts: state.shortcuts,
                });
                return;
            case ShortcutGroupType.Global:
                const globalGroups = state.groups.filter(g => !g.isPersonal);
                const globalShortcuts = [].concat.apply([], globalGroups.map(x => x.shortcuts));
                setState({
                    ...state,
                    typeId: id,
                    groupId: globalGroups.find(g => g.id === state.groupId)?.id ?? -1,
                    filteredGroups: globalGroups,
                    filteredShortcuts: globalShortcuts,
                });
                return;
            case ShortcutGroupType.Personal:
                const personalGroups = state.groups.filter(g => g.isPersonal);
                const personalShortcuts = [].concat.apply([], personalGroups.map(x => x.shortcuts));
                setState({
                    ...state,
                    typeId: id,
                    groupId: personalGroups.find(g => g.id === state.groupId)?.id ?? -1,
                    filteredGroups: personalGroups,
                    filteredShortcuts: personalShortcuts,
                });
                return;
        }
    }
    
    const handleGroup = (id: number) => {
        if (id < 0) {
            const allShortcuts = [].concat.apply([], state.filteredGroups.map(x => x.shortcuts));
            setState({
                ...state,
                groupId: id,
                filteredShortcuts: allShortcuts,
            });
        } else {
            const shortcuts = [].concat.apply([], state.filteredGroups.filter(g => g.id === id).map(x => x.shortcuts));
            setState({
                ...state,
                groupId: id,
                filteredShortcuts: shortcuts,
            });
        }
    }
    
    const handleApply = () => {
        const shortcut = state.shortcuts.find(x => x.id === state.selectedId);
        if (shortcut) {
            state.handleSelect(shortcut.content);
        }
        setInitialState();
    }

    const handleClose = () => {
        setState({...state, isOpen: false});
        setInitialState();
    }

    const handleFilter = useCallback((search: string) => {
        const trimedSearch = decodeURIComponent(search).trim().toLowerCase();
        const arrayTrimSearchKey = trimedSearch.split(' ');

        let filtered = state.filteredShortcuts?.filter(s => arrayTrimSearchKey.find(item => s.name?.toLowerCase().includes(item) || s.displayName?.toLowerCase().includes(item)) !== undefined);

        setState(state => ({ ...state, search: search, filteredShortcuts: filtered }));
    }, [state.shortcuts]);

    useEffect(() => {
        handleFilter(state.search);

        return () => { };
    }, [handleFilter]);

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<ShortcutGroupModel[]>(shortcutsQuery.groups$, groups => {
                const shortcuts = [].concat.apply([], groups.map(x => x.shortcuts));
                setState(state => ({
                    ...state,
                    groups,
                    shortcuts,
                    filteredGroups: groups,
                    filteredShortcuts: shortcuts
                }));
            }),
            selectShortcutService.onSelect.subscribe((handleSelect: Function) => {
                setState(state => ({
                    ...state, isOpen: true, handleSelect
                }));
            })
        ];

        const cb = () => setState(state => ({ ...state, isLoading: false }));

        shortcutsService.get().subscribe(cb, cb);

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [state, handleSelect, handleFilter, handleClose, handleApply, handleType, handleGroup];
}