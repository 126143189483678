import { useState } from 'react';
import { DropEvent, FileRejection } from 'react-dropzone';
import { snackService } from '../../../common/snack/state';
import { DocumentsToSendModel } from '../../../documents/models/documents.models';
import { addOnsService } from '../../../addons/services/addOns.service';

export interface UploadInputsDialogState {
    isOpen: boolean;
    editIndex: number,
    isLoading: boolean;
    handleUpload: Function;
    toSendAttachments: DocumentsToSendModel[];
}

export function useFacade(): [
    UploadInputsDialogState,
    Function,
    Function,
    Function,
    (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => void,
    (index: number) => void,
    (index: number) => void,
    (index: number) => void,
    (index: number, value: string) => void,
    Function,
] {
    const [state, setState] = useState({
        isOpen: false,
        isLoading: false,
        toSendAttachments: [],
    } as UploadInputsDialogState);


    const setDefaultState = () => {
        setState({
            ...state,
            editIndex: null,
            isOpen: false,
            isLoading: false,
            handleUpload: null,
            toSendAttachments: []
        });
    }

    const getAllowedExtensions = (): string => {
        return ".xlsx";
    }

    const removeTypeFromName = (name: string) => {
        const indexPoint = name.lastIndexOf('.')
        return name.slice(0, indexPoint)
    }

    const getType = (name: string) => {
        const indexOfSlesh = name.lastIndexOf('.')
        return name.slice(indexOfSlesh + 1)
    }

    const handleEditAttachment = (index: number) => {
        if (Number.isInteger(state.editIndex)) {
            handleSaveAttachment(state.editIndex)
        } else {
            const files = state.toSendAttachments;
            const fileToCange = files[index];
            fileToCange.name = removeTypeFromName(fileToCange.name)

            setState({ ...state, toSendAttachments: [...files], editIndex: index });
        }

    }

    const handleSaveAttachment = (index: number) => {
        const files = state.toSendAttachments;
        const fileToSave = files[index];
        fileToSave.name = `${fileToSave.name}.${getType(fileToSave.file.name)}`

        setState({ ...state, toSendAttachments: [...files], editIndex: null });
    }

    const handleChanges = (index: number, value: string) => {
        const files = state.toSendAttachments;
        const fileToCange = files[index];
        fileToCange.name = value

        setState({ ...state, toSendAttachments: [...files] });
    }

    const handleFileUpload = (event) => {
        event.preventDefault();
        const file = event.target.files[0];

        if (!getAllowedExtensions().includes(getType(file.name))) {
            snackService.error("This file types is not allowed.")
            return
        }

        setState(state => ({
            ...state,
            toSendAttachments: [
                {
                    file: file,
                    name: file.name,
                }
            ]
        }));

        event.target.value = '';
    }

    const handleDropAttachment = (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {

        //The only dropped file might be rejected, so we should check this first.

        if (fileRejections && fileRejections.length) {
            snackService.error(`This file type is not allowed. The file must be one of: ${getAllowedExtensions().replaceAll(',', ', ')}`)
        }


        if (acceptedFiles && acceptedFiles.length) {
            setState(state => ({
                ...state,
                toSendAttachments: [
                    {
                        file: acceptedFiles[0],
                        name: acceptedFiles[0].name,
                    }
                ]
            }));
        }
    }

    const handleRemoveAttachment = (index: number) => {
        setState(state => ({
            ...state,
            toSendAttachments: state.toSendAttachments.filter((i, itemIndex) => index !== itemIndex),
            editIndex: null
        }));
    }

    const handleSubmit = () => {
        setState({ ...state, isLoading: true });

        if (state.toSendAttachments.length > 0) {
            state.handleUpload(state.toSendAttachments);

            setState(state => ({
                ...state,
                toSendAttachments: [],
                isLoading: false,
                isOpen: false
            }));
        }
        setDefaultState();
    }

    const handleClose = () => {
        state.handleUpload(null, null);
        setDefaultState();
    }

    addOnsService.onUpload.subscribe((callbackModel) => {
      setState({
          ...state,
          isOpen: true,
          handleUpload: callbackModel.callback,
      });
    })

    return [
        state, 
        handleFileUpload, 
        handleSubmit, 
        handleClose, 
        handleDropAttachment,
        handleRemoveAttachment, 
        handleEditAttachment, 
        handleSaveAttachment,
        handleChanges, 
        getAllowedExtensions];
}