import React, {useState} from "react";
import {NoteModel} from "../../models/notes.models";
import EventNoteIcon from "@material-ui/icons/EventNote";
import {Box} from "@material-ui/core";
import clsx from "clsx";
import commonUseStyles from "../../../common/styles/common.styles";
import {useStyles} from "./AdditionalNoteComponent.styles";

export interface AdditionalNoteComponentProps {
    note: NoteModel
}

export const AdditionalNoteComponent: React.FC<AdditionalNoteComponentProps> = (props) => {
    const {note} = props;
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = (show: boolean) => {
        setIsOpen(show);
    }

    const commonClasses = commonUseStyles();
    const classes = useStyles();

    if (!note.additionalNote?.length) {
        return <></>
    }

    return (
        <>
            <a className={commonClasses.colorMain}
                 onMouseEnter={() => handleClick(true)}
                 onMouseLeave={() => handleClick(false)}>
                <EventNoteIcon/>
            </a>
            {
                isOpen &&
                <Box className={clsx(classes.tooltip, 'tooltip-element')}>
                    <Box>
                        {note.additionalNote}
                    </Box>
                </Box>
            }
        </>
    )
}