import { Box, Dialog, DialogActions, DialogContent, DialogContentText, useMediaQuery, useTheme, Button } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { DialogTitle } from '../../components/dialogTitle/DialogTitle';
import { WildHealthButton } from '../../components/wildHealthButton/WildHealthButton';
import commonUseStyles from '../../styles/common.styles';
import { useFacade } from "./confirmInsuranceDialog.hooks";
import { useStyles } from "./ConfirmDialog.styles";
import { isIOS } from "react-device-detect";

export const ConfirmInsuranceDialog: React.FC = () => {
    const [state, handleAnswer] = useFacade();

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleAnswer(true);
        }
    }

    const classes = useStyles();
    const commonClasses = commonUseStyles();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box onKeyPress={(e) => handleKeyPress(e)}>
            <Dialog
                fullWidth
                maxWidth='sm'
                open={state.isOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {!fullScreen && (
                    <DialogTitle
                        id="dialog-title"
                        classes={{ root: classes.dialogTitle }}
                    >
                        <Box display='flex' justifyContent='center' className={clsx(commonClasses.size18, commonClasses.textMedium)}> Insurance Confirmation</Box>
                    </DialogTitle>
                )}

                <DialogContent classes={{ root: classes.dialogContent }}>
                    {
                        fullScreen ? (
                            <>
                                <Box px={isIOS ? 4 : 3}>
                                    <Box pb={isIOS ? 0.5 : "20px"} textAlign={isIOS && "center"} className={clsx(commonClasses.size18, commonClasses.textMedium, commonClasses.colorBlack)}>
                                        Insurance Confirmation
                                    </Box>
                                    <Box pb={isIOS ? "20px" : "40px"} className={clsx(commonClasses.colorBlack, commonClasses.size14)}>
                                        <Box >As an insurance patient you’ll have access to:</Box>
                                        <Box pl={1}> &#9679; Going forward, you’ll have unlimited provider visits billed through your insurance</Box>
                                        <Box pl={1}> &#9679; A discount of up to 20% on your membership starting your next billing cycle</Box>
                                    </Box>
                                </Box>
                                <Box display="flex" alignItems="center" justifyContent={!isIOS && "flex-end"} className={isIOS && classes.actionSection}>
                                    <Box flex={isIOS && 1} py="10px" textAlign="center">
                                        <Button onClick={() => handleAnswer(false)}>
                                            <span className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorGray1)}>
                                                Cancel
                                            </span>
                                        </Button>
                                    </Box>
                                    <Box flex={isIOS && 1} py="10px" px={!isIOS && 2} className={isIOS && classes.acceptBtn}>
                                        <Button onClick={() => handleAnswer(true)}>
                                            <span className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorMain)}>
                                                Save
                                            </span>
                                        </Button>
                                    </Box>
                                </Box>
                            </>
                        ) : (
                            <DialogContentText className={clsx(commonClasses.colorBlack, commonClasses.size14)}>
                                <Box >As an insurance patient you’ll have access to:</Box>
                                <Box pl={1}> &#9679; Going forward, you’ll have unlimited provider visits billed through your insurance</Box>
                                <Box pl={1}> &#9679; A discount of up to 20% on your membership starting your next billing cycle</Box>
                            </DialogContentText>
                        )
                    }
                </DialogContent>
                {!fullScreen && (
                    <DialogActions classes={{ root: classes.dialogActions }}>
                        <Box display='flex' justifyContent='center' width='100%' pb={8}>
                            <WildHealthButton style={{ marginRight: '16px' }} borderRadius='8px' id='confirm-insurance-dialog-cancel' size='large' color='referral' onClick={() => handleAnswer(false)}>
                                Cancel
                            </WildHealthButton>
                            <WildHealthButton id='confirm-insurance-dialog-save' borderRadius='8px' size='large' color='tertiary' onClick={() => handleAnswer(true)}>
                                Save
                            </WildHealthButton>
                        </Box>
                    </DialogActions>

                )
                }
            </Dialog >
        </Box >
    );
}
