import React from "react";
import {authQuery} from "../../auth/stores/auth";
import {ProtectedRoute, RedirectPermissions} from "./ProtectedRoute";

export const AuthenticatedRoute = ({component: Component, ...rest}) => {
    const redirectPermission = authQuery.isLoggedIn()
        ? RedirectPermissions.Normal : RedirectPermissions.Unauthorized;

    return (
        <ProtectedRoute
            redirectPermission={redirectPermission}
            component={Component}
            {...rest}
        />
    )
}