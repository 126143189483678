import { Store, StoreConfig } from '@datorama/akita';
import moment from "moment";
import { TimeZoneModel } from "../../models/timezone.model";
import "moment-timezone";

export interface TimezonesState {
    browserTimezone: TimeZoneModel;
    timezones: TimeZoneModel[];
    myTimezone: TimeZoneModel;
}

/**
 * Creates initial timezones state
 */
export function createInitialState(): TimezonesState {
    return {
        browserTimezone: {
            id: moment.tz.guess().toString(),
            olsonId: moment.tz.guess().toString(),
            displayName: moment.tz.guess().toString(),
            standardName: moment.tz.guess().toString(),
            daylightName: moment.tz.guess().toString(),
            baseUtcOffset: {
                totalHours: moment().utcOffset() / 60,
                totalMinutes: moment().utcOffset(),
            }
        },
        timezones: [],
        myTimezone: null
    };
}

/**
 * Provides timezones state management
 */
@StoreConfig({ name: 'timezones', resettable: true })
export class TimezonesStore extends Store<TimezonesState> {
    constructor() {
        super(createInitialState());
    }

    public setTimezones(timezones: TimeZoneModel[]): void {
        this.update({timezones: timezones});
    }
}

export const timezonesStore = new TimezonesStore();