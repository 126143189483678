import React from "react";
import {ShortcutModel} from "../../../models/shortcut.models";
import {Box} from "@material-ui/core";
import {displayDate} from "../../../../common/helpers/display-date";
import { toCurrentTimeZone } from "../../../../timezones/helpers/timezone";

interface ShortcutAuditInformationComponentProps {
    shortcut: ShortcutModel
}

export const ShortcutAuditInformationComponent: React.FC<ShortcutAuditInformationComponentProps> = (props: ShortcutAuditInformationComponentProps) => {
    const { shortcut } = props;
    return (
        <Box minWidth="50%">
            <Box width={1} display="flex" justifyContent="space-between">
                <Box flex={1} display="flex">
                    <Box pr={1}>
                        <span>Created on: </span>
                    </Box>
                    <Box>
                        <span>{displayDate(toCurrentTimeZone(shortcut.createdAt))}</span>
                    </Box>
                </Box>
                <Box flex={1} display="flex" justifyContent="flex-end">
                    <Box pr={1}>
                        <span>Created by: </span>
                    </Box>
                    <Box minWidth={20}>
                        <span>{shortcut.createdBy}</span>
                    </Box>
                </Box>
            </Box>
            <Box width={1} mt={1} display="flex" justifyContent="space-between">
                <Box flex={1} display="flex">
                    <Box pr={1}>
                        <span>Modified on: </span>
                    </Box>
                    <Box>
                        <span>{shortcut.modifiedAt ? displayDate(toCurrentTimeZone(shortcut.modifiedAt)) : ''}</span>
                    </Box>
                </Box>
                <Box flex={1} display="flex" justifyContent="flex-end">
                    <Box pr={1}>
                        <span>Modified by: </span>
                    </Box>
                    <Box minWidth={20}>
                        <span>{shortcut.modifiedBy}</span>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
};