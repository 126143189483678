import { createStyles, makeStyles, withStyles, Theme } from "@material-ui/core/styles";
import { colors } from "../../../common/constants/colors";
import { TextField } from "@material-ui/core";
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backButton: {
      backgroundColor: colors.bg,
    },
    cell: {
      width: 300,
      minWidth: 150,
      maxWidth: 300,
      "&:last-child": {
        width: "auto",
        minWidth: 0,
      },
      [theme.breakpoints.down("xs")]: {
        width: "fit-content",
        minWidth: "fit-content",
      },
    },
    menuItemTitle: {
      fontWeight: 400,
    },
    MenuItemicon: {
      color: colors.gray2,
    },
    editIcon: {
      padding: 0,
    },
    textField: {
      borderColor: colors.gray4,
    },
    dialog: {
      marginTop: 30,
    },
    dialogButtonText: {
      fontSize: 14,
      color: colors.gray1,
      fontWeight: 500,
    },
    dialogDate: {
      fontSize: 14,
      fontWeight: 400,
    },
    vitalDisplayName: { fontSize: 14, fontWeight: 400, color: colors.gray1 },
  })
);

export const VitalsTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: colors.main,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: colors.main,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: colors.gray4,
      },
      "&:hover fieldset": {
        borderColor: colors.gray1,
      },
      "&.Mui-focused fieldset": {
        borderColor: colors.main,
      },
    },
  },
})(TextField);
