import React from "react";
import { useFacade } from './wildHealthSaveClickableElement.hooks';

export const WildHealthSaveClickableElement = ({component: Component, ...props}) => {
    const { onClick, timeout = 250 } = props;
    const [
        canExecute,
        handleOnClick,
    ] = useFacade(onClick, timeout);

    return (React.createElement(Component, {...props, disabled: props.disabled || !canExecute, onClick: () => handleOnClick()}))
}
