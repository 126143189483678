import { useState } from 'react';
import { confirmService } from '../../../../services/confirm.service';
import { IErrorState } from '../../validation/error-state';

export interface ConfirmWithSelectedOneDialogState extends IErrorState {
    isOpen: boolean;
    title: string;
    message: string;
    acceptTitle: string;
    options: Map<number, string>
    newChoice: number;
    handleAnswer: Function;
}

const defaultState = {
    isOpen: false,
    title: '',
    message: '',
    acceptTitle: 'Download',
    options: new Map<number, string>()
} as ConfirmWithSelectedOneDialogState

export function useFacade(): [
    ConfirmWithSelectedOneDialogState,
    () => void,
    () => void,
    (newChoice: number) => void,
] {
    const [state, setState] = useState(Object.assign({}, defaultState));

    const handleCancel = () => {
        handleAnswer(false);
    }

    const handleConfirm = () => {
        handleAnswer(true);
    }

    const handleDataCategoriesSelect = (newChoice: number) => {
        setState({ ...state, newChoice: newChoice });
    }

    const handleAnswer = (result: boolean) => {
        setState(state => ({ ...defaultState, }));
        state.handleAnswer(result, state.newChoice);
    }

    confirmService.onQuestionWithSelectedOne.subscribe((question) => {
        setState(state => ({
            ...state,
            isOpen: true,
            options: question.data.options,
            title: question.data.title,
            message: question.data.message,
            handleAnswer: question.callback,
            acceptTitle: question.data.acceptTitle ?? state.acceptTitle,
            newChoice: question.data.options.values()[0]
        }));
    })

    return [state, handleCancel, handleConfirm, handleDataCategoriesSelect];
}