import { Store, StoreConfig } from '@datorama/akita';
import { HealthReportModel, HealthReportOwnerModel, HealthReportRecommendationDefaultModel, HealthReportShortModel, RecommendationtSimpleModel, ReportsUnderReviewModel, PatientReportModel, PatientAddOnReportModel } from "../../models/healthReport.models";
import { RecommendationModel, MetricModel, RecommendationTriggerModel } from '../../../recommendations/models/recommendations.model';

/**
 * Represents health reports state
 */
export interface HealthReportsState {
    report: HealthReportModel;
    reportForRecommendation: HealthReportModel;
    reports: HealthReportShortModel[];
    reportsUnderReview: ReportsUnderReviewModel[];
    reportsTotalCount: number;
    notificationAwaitingApproval: boolean;
    recommendationsDefault: HealthReportRecommendationDefaultModel[];
    recommendationToSend: RecommendationtSimpleModel[];
    recommendations: RecommendationModel[];
    metrics: MetricModel[];
    patientReport: PatientReportModel;
    addOnReports: PatientAddOnReportModel[];
}

/**
 * Creates initial state
 */
export function createInitialState(): HealthReportsState {
    return {
        report: null,
        reportForRecommendation: null,
        reports: [],
        reportsUnderReview: [],
        reportsTotalCount: 0,
        notificationAwaitingApproval: false,
        recommendationsDefault: [],
        recommendationToSend: [],
        recommendations: [],
        metrics: [],
        patientReport: null,
        addOnReports: []
    };
}

/**
 * Provides health reports states management
 */
@StoreConfig({name: 'healthReports', resettable: true })
export class HealthReportsStore extends Store<HealthReportsState> {
    constructor() {
        super(createInitialState());
    }

    public resetReportForRecommendation(): void {
        this.update({ reportForRecommendation: null });
    }

    public getCurrentReport(): HealthReportModel {
        return this.getValue().report;
    }

    public getCurrentReportOwner(): HealthReportOwnerModel {
        return this.getValue().report.owner;
    }

    public getRecommendationToSend(): RecommendationtSimpleModel[] {
        return this.getValue().recommendationToSend;
    }

    public putRecommendationToSend(recommendation: RecommendationtSimpleModel[]): void {
        this.update({ recommendationToSend: recommendation });
    }


    public addRecommendation(recommendation: RecommendationModel) {
        this.update({ recommendations: [...this.getValue().recommendations, recommendation] });
    }

    public updateRecommendation(recommendation: RecommendationModel) {
        const recommendations = this.getValue().recommendations;
        this.update({ recommendations: recommendations.map(x => x.id === recommendation.id ? recommendation : x) });
    }

    public removeRecommendation(id: number) {
        this.update({ recommendations: this.getValue().recommendations.filter(i => i.id !== id) });
    }

    public addRecommendationTrigger(trigger: RecommendationTriggerModel) {
        const recommendation = this.getValue().recommendations.find(x => x.id === trigger.recommendationId);
        if (recommendation) {
            const triggers = [...recommendation.recommendationTriggers, trigger];
            this.update({ recommendations: this.getValue().recommendations.map(x => x.id === recommendation.id ? {...recommendation, recommendationTriggers: triggers} : x) });
        }
    }

    public updateRecommendationTrigger(trigger: RecommendationTriggerModel) {
        const recommendation = this.getValue().recommendations.find(x => x.id === trigger.recommendationId);
        if (recommendation) {
            const triggers = recommendation.recommendationTriggers.map(x => x.id === trigger.id ? trigger : x);

            this.update({ recommendations: this.getValue().recommendations.map(x => x.id === recommendation.id ? {...recommendation, recommendationTriggers: triggers} : x) });
        }
    }

    public removeRecommendationTrigger(recommendationId: number, triggerId: number) {
        const recommendation = this.getValue().recommendations.find(x => x.id === recommendationId);
        if (recommendation) {
            const triggers = recommendation.recommendationTriggers.filter(x => x.id !== triggerId);

            this.update({ recommendations: this.getValue().recommendations.map(x => x.id === recommendation.id ? {...recommendation, recommendationTriggers: triggers} : x) });
        }
    }
}

export const healthReportsStore = new HealthReportsStore();
