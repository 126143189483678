import React from "react";
import { Box, Grid, useTheme, useMediaQuery } from "@material-ui/core";
import { useFlags } from "launchdarkly-react-client-sdk";
import clsx from "clsx";
import { useFacade } from "./widgetContainerBodyComponent.hooks";
import { authQuery } from "../../../auth/stores/auth";
import { isFeatureFlag, anyFlags } from "../../../common/components/featureFlags/featureFlags";
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import { AppointmentWidgetComponent } from "../appointmentWidgetComponent/AppointmentWidgetComponent";
import { AppointmentWidgetRedesignComponent } from "../appointmentWidgetComponent/AppointmentWidgetRedesignComponent";
import { MyTasksWidgetComponent } from "../myTasksWidgetComponent/MyTasksWidgetCompoent";
import { NavigationWidgetComponent } from "../navigationWidgetComponent/NavigationWidgetComponent";
import { NotificationsWidgetComponent } from "../../../notifications/components/notificationComponent/NotificationsWidgetComponent";
import { patientsQuery } from "../../../patients/stores/patientsStore";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { JourneyTasksWidgetComponent } from "../journeyTasksWidgetComponent/JourneyTasksWidgetComponent";
import { StandardUpsellWidgetComponent } from "../standardUpsellWidgetCompoent/StandardUpsellWidgetComponent";

export interface WidgetContainerBodyComponentProps {
  patientId: number | null;
}

export const WidgetContainerBodyComponent: React.FC<WidgetContainerBodyComponentProps> = (
  props: WidgetContainerBodyComponentProps
) => {
  const { patientId } = props;
  const [
    { 
      isLoading,
      notifications,
      showTimeline
    },
    handleToggle
  ] = useFacade(patientId);

  const featureFlags = useFlags();
  const isNewAppointmentWidget = isFeatureFlag(featureFlags, FeatureFlag.AppointmentWidgetRedesign);
  const isPatientTimelineWidget = isFeatureFlag(featureFlags, FeatureFlag.PatientTimelineWidget);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const isPatientPremium = patientId ? patientsQuery.getTargetPatientIsPremium() : authQuery.getIsPremium()
  const hasActiveStandardPlan = patientId ? patientsQuery.getTargetPatientSubscription()?.isActive && patientsQuery.getTargetPatientSubscription()?.paymentPlan?.displayName === "Standard" : authQuery.isSubscriptionStatusActive() && authQuery.getPlan() === "Standard";

  const renderJouneyTaskAndNotification = () => {
    if (isPatientPremium || !hasActiveStandardPlan) {
      if (patientId) return <></>
      return <>
        <Box p={1}>
          <Box display="flex" alignItems="center" p={1} className="wh-tw-bg-gray100 wh-tw-rounded">
            <Box display="flex" alignItems="center" justifyContent="center" flex={1} p={1} className="wh-tw-text-background wh-tw-bg-secondary wh-tw-font-semibold wh-tw-text-sm wh-tw-text-center wh-tw-rounded wh-tw-cursor-pointer" onClick={() => handleToggle(false)}>
              Notifications
              {notifications.length > 0 && (
                <Box ml={1} className="wh-tw-px-1.5 wh-tw-rounded-3xl wh-tw-bg-error wh-tw-text-white wh-tw-font-semibold wh-tw-text-xs">{notifications.length}</Box>
              )}
            </Box>
          </Box>
        </Box>
        <NotificationsWidgetComponent patientId={patientId} />
      </>
    }
    
    return (
      <Box mb={2} maxWidth="100%" className="wh-tw-bg-white">
        {(!anyFlags(featureFlags) || isLoading) ? (
          <WildHealthLinearProgress />
        ) : (
          <>
            <Box p={1}>
              <Box display="flex" alignItems="center" p={1} className="wh-tw-bg-gray100 wh-tw-rounded">
                {isPatientTimelineWidget && <Box flex={1} p={1} className={clsx(showTimeline ? "wh-tw-text-background wh-tw-bg-secondary" : "wh-tw-text-gray600", "wh-tw-font-semibold wh-tw-text-sm wh-tw-text-center wh-tw-rounded wh-tw-cursor-pointer")} onClick={() => handleToggle(true)}>My Tasks</Box>}
                {!patientId && <Box display="flex" alignItems="center" justifyContent="center" flex={1} p={1} className={clsx(!showTimeline ? "wh-tw-text-background wh-tw-bg-secondary" : "wh-tw-text-gray600", "wh-tw-font-semibold wh-tw-text-sm wh-tw-text-center wh-tw-rounded wh-tw-cursor-pointer")} onClick={() => handleToggle(false)}>
                  Notifications
                  {notifications.length > 0 && (
                    <Box ml={1} className="wh-tw-px-1.5 wh-tw-rounded-3xl wh-tw-bg-error wh-tw-text-white wh-tw-font-semibold wh-tw-text-xs">{notifications.length}</Box>
                  )}
                </Box>}
              </Box>
            </Box>
            {showTimeline ? (
              isPatientTimelineWidget ? <JourneyTasksWidgetComponent patientId={patientId} /> : <></>
            ) : (
              <NotificationsWidgetComponent patientId={patientId} />
            )}
          </>
        )}
      </Box>
    )
  }

  return (
    <Grid container spacing={!isSmallScreen && 3} direction="row">
      <Grid container item direction="column" xs={12} sm={12} md={12} lg={7} xl={7}>
        {!isPatientPremium && <Box mb={2} maxWidth="100%">
          <MyTasksWidgetComponent patientId={patientId} />
        </Box>}
        {!patientId && <StandardUpsellWidgetComponent />}
        {isSmallScreen && renderJouneyTaskAndNotification()}
        <Box mb={2} maxWidth="100%">
          <NavigationWidgetComponent patientId={patientId} />
        </Box>
        {!isSmallScreen && renderJouneyTaskAndNotification()}
      </Grid>
      <Grid container item direction="column" xs={12} sm={12} md={12} lg={5} xl={5}>
        {authQuery.isSubscriptionStatusActive() && (
          <Box mb={2}>
            {isNewAppointmentWidget
              ? <AppointmentWidgetRedesignComponent patientId={patientId} />
              : <AppointmentWidgetComponent patientId={patientId} />}
          </Box>
        )}
      </Grid>
    </Grid>
  );
};
