import React from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from "./pmFellowshipGetStartedPage.styles";
import { useFacade } from "./pmFellowshipGetStartedPage.hooks";
import clsx from 'clsx';
import commonUseStyles from '../../../common/styles/common.styles';

export const PMFellowshipGetStartedPage: React.FC = (props: any) => {
    const [
        {
            currentStep,
        },
        StepsContent
    ] = useFacade();

    const classes = useStyles();
    const commonClasses = commonUseStyles();

    return (
        <div className={classes.root}>
            <Box className={classes.content} py="80px">
                <Box width={1} textAlign="center">
                    <span className={clsx(commonClasses.size30, commonClasses.textSemiBold, commonClasses.colorBlack)}>Practicum Patient Submission</span>
                </Box>
                {
                    StepsContent.get(currentStep.key)
                }
            </Box>
        </div>
    );
}

