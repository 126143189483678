
export enum LeftByType {
  Employee = 0,
  Patient = 5,
  Clarity = 10
}

export interface CommentModel {
  id: number;
  description: string;
  leftBy: string;
  leftByType: LeftByType;
  createdAt: Date;
  createdBy: number;
  modifiedAt: Date;
}

export interface CreateCommentModel {
  commentableUniversalId: string;
  description: string;
}