import React from "react";
import {
    AuthenticatedLayoutComponent
} from "../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent";
import {TitlesService} from "../../../../constants/title.constants";
import {PagesNamesService} from "../../../../constants/pagesNames.constants";
import {GenericPlaygroundComponent} from "../../components/genericPlaygroundComponent/GenericPlaygroundComponent";
import {isFeatureFlag} from "../../../common/components/featureFlags/featureFlags";
import {FeatureFlag} from "../../../common/components/featureFlags/featureFlags.models";
import {useFlags} from "launchdarkly-react-client-sdk";
import {useMediaQuery, useTheme} from "@material-ui/core";

export const GenericPlaygroundPage: React.FC = () => {
    const featureFlags = useFlags();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <AuthenticatedLayoutComponent
            hideBackground
            displayChildren='flex'
            contentMargin={isSmallScreen ? '0px' : isFeatureFlag(featureFlags, FeatureFlag.Navigation) ? '30px 0 0 30px' : '30px 0 0 0'}
            title={TitlesService.genericPlaygroundTitle}
            pageName={PagesNamesService.genericPlayground}
            selectedMenuItem={null}
        >
            <GenericPlaygroundComponent />
        </AuthenticatedLayoutComponent>
    )
};