import { MenuItem, withStyles } from "@material-ui/core";
import { colors } from '../../../common/constants/colors';

export const WildHealthWarningMenuItem = withStyles((theme) => ({
    root: {
        borderRadius: 2,
        margin: 5,
        marginTop: 5,
        padding: 15,
        color: colors.bad
    },
}))(MenuItem);