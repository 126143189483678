import React from "react";
import { Box, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { NavigateButton, useStyles } from "./myTasksWidgetComponent.style";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useFacade } from "./myTasksWidgetComponent.hooks";
import { PatientTaskModel } from "../../../patients/models/patient.model";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { MyTaskItemComponent } from "./myTaskItemComponent/MyTaskItemComponent";
import { myTaskFullImages, EngagementCriteriaType } from "./constants/myTaskImages";
import { ReactComponent as EmptyTaskCheck } from '@img/icons/EmptyTaskCheck.svg';

export interface MyTasksWidgetComponentProps {
    patientId: number | null;
}

export const MyTasksWidgetComponent: React.FC<MyTasksWidgetComponentProps> = (props: MyTasksWidgetComponentProps) => {
    const [
        isLoading,
        currentPage,
        pages,
        tasks,
        handleNavigate,
    ] = useFacade(props.patientId);

    const classes = useStyles();

    const renderTask = (task: PatientTaskModel, index: number) => {
        return (
            <Grid key={index} item md={12} lg={12} sm={12} xs={12}>
                <MyTaskItemComponent task={task} patientId={props.patientId} />
            </Grid>
        )
    }

    const renderContent = () => {
        if (isLoading) {
            return <WildHealthLinearProgress />
        }

        if (!tasks || !tasks.length) {
            return (
                <Box className={classes.imageContainer} style={{
                    backgroundImage: `url(${myTaskFullImages.get(EngagementCriteriaType.Empty)})`
                }}>
                    <Box><EmptyTaskCheck/></Box>
                    <Box>You're all caught up! Check back soon for any new tasks.</Box>
                </Box>
            )
        }

        return (
            <>
                <Grid container spacing={2}>
                    {
                        tasks.map((task, index) => {
                            return renderTask(task, index);
                        })
                    }
                </Grid>
            </>
        )
    }

    return (
        <Card className={classes.root} variant="elevation" elevation={0}>
            <CardContent className={classes.content}>
                <Box alignItems="center" justifyContent="space-between" className="wh-tw-hidden sm:wh-tw-flex">
                    <Typography className={classes.title}>Next Steps</Typography>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        className={classes.navigateContainer}
                    >
                        {/* <NavigateButton                              in the near future we will allow multipe cards in there CLAR-5666 comment by Felix
                            id="health-plan-widget-show-prev"
                            disabled={currentPage === 1 || isLoading}
                            className={classes.navigateButton}
                            onClick={() => handleNavigate(currentPage - 1)}
                        >
                            <ChevronLeftIcon />
                        </NavigateButton>

                        <NavigateButton
                            id="health-plan-widget-show-next"
                            disabled={currentPage >= pages || isLoading}
                            className={classes.navigateButton}
                            onClick={() => handleNavigate(currentPage + 1)}
                        >
                            <ChevronRightIcon />
                        </NavigateButton> */ }
                    </Box>
                </Box>
                <Box className="sm:wh-tw-mt-4">
                    {renderContent()}
                </Box>
            </CardContent>
        </Card>
    );
}