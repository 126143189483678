import React from 'react';
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    TextField,
    useMediaQuery,
    useTheme
} from "@material-ui/core";
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import { DialogTitle } from '../../../common/components/dialogTitle/DialogTitle';
import {useStyles} from "./configureNoteAppointmentDialog.styles";
import {useFacade} from "./configureNoteAppointmentDialog.hooks";
import {
    SelectAppointmentTypeComponent
} from "../../../appointments/components/selectAppointmentType/SelectAppointmentTypeComponent";
import {WildHealthDatePicker} from "../../../common/components/WildHealthDatePicker";
import moment from "moment";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import {durations} from "../../../appointments/models/times.models";
import {SelectDurationComponent} from "../../../appointments/components/selectDuration/SelectDurationComonent";

interface ConfigureNoteAppointmentDialogProps {
    patientId: number;
    isOpen: boolean;
    handleCloseDialog: Function;
    handleConfigureAppointment: Function;
}

export const ConfigureNoteAppointmentDialog: React.FC<ConfigureNoteAppointmentDialogProps> = (props: ConfigureNoteAppointmentDialogProps) =>{
    const [
        state,
        handleAppointmentType,
        handleAppointmentDate,
        handleAppointmentTime,
        handleAppointmentDuration,
        canConfirm,
        toConfiguration
    ] = useFacade(props.patientId);

    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const getTime = () => {
        return moment(state.appointmentDate).format('HH:mm');
    }

    return (
        <Dialog
            fullWidth
            fullScreen={fullScreen}
            maxWidth="sm"
            open={props.isOpen}
            onClose={() => props.handleCloseDialog()}
        >
            <DialogTitle
                id="dialog-title"
                classes={{ root: classes.dialogTitle }}
                onClose={() => props.handleCloseDialog()}
            >
                <span className="wh-tw-text-2xl wh-tw-font-medium">Appointment Details</span>
            </DialogTitle>

            <DialogContent className={classes.dialogContent}>
                <DialogContentText>
                    <Box width={1}>
                        {
                            state.appointmentTypes.length > 0
                                ? <SelectAppointmentTypeComponent
                                    types={state.appointmentTypes}
                                    handleChange={handleAppointmentType}
                                    value={state.appointmentType}
                                /> : <WildHealthLinearProgress/>
                        }
                    </Box>
                    <Box width={1} mt={4}>
                        <Box>
                            <span className="wh-tw-text-gray1 wh-tw-text-sm">Appointment Date</span>
                        </Box>
                        <Box mt={1}>
                            <WildHealthDatePicker
                                required
                                fullWidth
                                size="small"
                                openTo="year"
                                format="MM/DD/yyyy"
                                id='AppointmentDate'
                                placeholder="mm-dd-yyyy"
                                value={state.appointmentDate}
                                inputVariant="outlined"
                                views={["year", "month", "date"]}
                                useKeyboardDatePicker
                                onChange={(momentDate) => handleAppointmentDate(momentDate)}
                            />
                        </Box>
                    </Box>
                    <Box width={1} mt={4}>
                        <Box>
                            <span className="wh-tw-text-gray1 wh-tw-text-sm">Appointment Time</span>
                        </Box>
                        <Box mt={1}>
                            <TextField
                                id="time"
                                size="small"
                                type="time"
                                fullWidth
                                variant="outlined"
                                value={getTime()}
                                onChange={(e)=>{ handleAppointmentTime(e.target.value)}}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    step: 300, // 5 min
                                }}
                            />
                        </Box>
                    </Box>
                    <Box width={1} mt={4}>
                        <SelectDurationComponent
                            durations={durations.filter(x => x.patientMode)}
                            selected={state.appointmentDuration}
                            handleChange={(v) => handleAppointmentDuration(v)}
                        />
                    </Box>
                </DialogContentText>
            </DialogContent>

            <DialogActions classes={{ root: classes.dialogActions }}>
                <WildHealthButton id="cancel-select" size='large' color='tertiary' onClick={() => {props.handleCloseDialog(); }}>
                    Cancel
                </WildHealthButton>
                <WildHealthButton id="confirm-select" size='large'  onClick={() => props.handleConfigureAppointment(toConfiguration())} disabled={!canConfirm()}>
                    Confirm
                </WildHealthButton>
            </DialogActions>
        </Dialog>
    )
}
