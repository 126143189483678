import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        regular: {
            fontFamily: 'Be Vietnam Pro',
            fontSize: 15,
            fontWeight: 300,
            lineHeight: 1.33,
            letterSpacing: 0.15,
        },
        textWrap: {
            // wordBreak: 'break-all',
            display: 'flex',
            flexWrap: "wrap"
        },
        renderText: {
            fontFamily: 'Be Vietnam Pro',
            fontSize: 15,
            fontWeight: 400,
            lineHeight: 1.33,
            letterSpacing: 0.15,
        },
    })
);
