import React from "react";
import {Box} from "@material-ui/core";
import "./LabResultComponent.scss";
import {LabInputNotificationType} from "../../../inputs/models/input.models";
import {LabReportValueModel} from "../../models/healthReport.models";

interface LabResultComponentProps {
    label: string;
    value: LabReportValueModel;
}

export const LabResultComponent: React.FC<LabResultComponentProps> = (props: LabResultComponentProps) => {
    const {
        label,
        value
    } = props;

    const outOfNorm = (notification): boolean => {
        return notification?.type === LabInputNotificationType.Error;
    }

    const displayValue = (value: LabReportValueModel) => {
        if (value?.value === null || value?.value === undefined) {
            return <span className="text-medium"> &mdash; </span>
        }

        return <span className={ outOfNorm(value?.notification) ? "text-medium color-red" : "text-medium" }>{value?.value}</span>
    }

    return (
        <Box display="flex" justifyContent="space-between" mt={1}>
            <Box>
                <span className="text-medium">{label}</span>
            </Box>
            <Box>
                { displayValue(value) }
            </Box>
        </Box>
    )
}