import { useState } from "react";
import { useHistory } from "react-router-dom";
import { confirmService } from "../../../../../services/confirm.service";
import { navigationService } from "../../../../../services/navigation.service";
import { EmployeeAppointmentModel } from "../../../models/appointments.models";
import { appointmentsService } from "../../../services/appointments.service";

interface AppointmentItemComponentState {
    infoAnchor: HTMLElement | null;
    editDialogOpen: boolean;
}

export function useFacade(): [
    AppointmentItemComponentState,
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => void,
    () => void,
    (appointmentId: number) => void,
    (joinLink: string) => void,
    (toggle: boolean) => void,
    (appointment: EmployeeAppointmentModel) => void,
    (id: number) => void
] {
    const [state, setState] = useState({
        infoAnchor: null,
        editDialogOpen: false
    } as AppointmentItemComponentState);

    const handleCloseInfoPopover = () => {
        setState({ ...state, infoAnchor: null });
    };

    const handleOpenInfoPopover = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setState({ ...state, infoAnchor: event.currentTarget });
    };

    const handleToggleEditDialog = (toggle: boolean) => {
        setState({ ...state, editDialogOpen: toggle });
    }

    const handleRescheduleAppointment = (appointment: EmployeeAppointmentModel) => {
        appointmentsService.openAppointmentReschedule(appointment).subscribe();
    }

    const history = useHistory();

    const goToPatientProfile = (id: number) => {
        navigationService.toManagePatientProfile(history, id)
    }

    const handleCancelAppointment = (appointmentId: number) => {
        confirmService.confirm(
            'Cancel Appointment',
            'Are you sure you want to cancel the appointment?',
            'Cancel Appointment',
            'Discard',
            'danger')
            .subscribe(
                () => appointmentsService.cancelAsEmployee(appointmentId)
            );
    }

    const handleJoinAppointment = (joinLink: string) => {
        window.open(joinLink, '_blank');
    }

    return [
        state,
        handleOpenInfoPopover,
        handleCloseInfoPopover,
        handleCancelAppointment,
        handleJoinAppointment,
        handleToggleEditDialog,
        handleRescheduleAppointment,
        goToPatientProfile
    ];
}