import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    subscriptionCard: {
        marginTop: 8,
        padding: 32,
        borderRadius: 2,
        border: 'none',
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    },
});