import { WidgetContainerBodyComponent } from "../widgetContainerBodyComponent/WidgetContainerBodyComponent";
import React from "react";
import { Box, useTheme, useMediaQuery, Typography } from "@material-ui/core";
import { useStyles } from "./widgetContainerComponent.styles";

export interface WidgetContainerComponentProps {
  patientId: number | null;
}

export const WidgetContainerComponent: React.FC<WidgetContainerComponentProps> = (
  props: WidgetContainerComponentProps
) => {
  const { patientId } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        {isSmallScreen && (
          <Typography className={classes.title}>Dashboard</Typography>
        )}
        <WidgetContainerBodyComponent patientId={patientId} />
      </Box>
    </Box>
  );
};
