import { useState } from "react";
import {useHistory} from "react-router-dom";
import {navigationService} from "../../../../services/navigation.service";

interface CreatePatientAppointmentDialogComponentState {
    toShowDialog: boolean;
    toContinueDialog: boolean;
}

/**
 * Custom Hook to manage a view Model for Appointments component
 */
export function useFacade(): [CreatePatientAppointmentDialogComponentState, () => void, () => void, () => void] {
    const history = useHistory();
    const [state, setState] = useState({
        toShowDialog: false,
        toContinueDialog: false,
    } as CreatePatientAppointmentDialogComponentState);

    const handleContinueDialog = () => {
        setState(state => ({
            ...state,
            toContinueDialog: !state.toContinueDialog
        }));
    }

    const handleToggleDialog = () => {
        if (state.toShowDialog) {
            return setState(state => ({
                ...state,
                toShowDialog: !state.toShowDialog,
                toContinueDialog: false
            }));
        }
        setState(state => ({
            ...state,
            toShowDialog: !state.toShowDialog
        }));
    }

    const handleGoToConversations = () => {
        navigationService.toConversations(history);
    }

    return [state, handleToggleDialog, handleGoToConversations, handleContinueDialog];
}