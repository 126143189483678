import React from "react";
import { useFacade } from "./insuranceCheckoutComponent.hooks";
import commonUseStyles from "../../../common/styles/common.styles";
import { useStyles } from "./InsuranceComponent.styles";
import {
    Box,
    Checkbox,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress"
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { colors } from "../../../common/constants/colors";
import { WildHealthDatePicker } from "../../../common/components/WildHealthDatePicker";
import {RelationshipNames, Relationships} from "../../models/insurance.models";
import { RegistrationNextTestedButton, RegistrationNextButton } from "../../../payment/components/registrationButton/RegistrationNextTestButton";
import { User } from "../../../common/models/user.models";
import clsx from "clsx";
import { WildHealthCheckbox } from '../../../common/components/WildHealthCheckbox';
import {getAge} from "../../../common/helpers/get-age";

interface InsuranceCheckoutComponentProps {
    user: User;
    handleSubmit?: Function;
    handleCancel?: Function;
}

interface InsuranceCheckoutRedesignComponentProps extends InsuranceCheckoutComponentProps {
    isMedicareFlow: boolean;
}

export const InsuranceCheckoutComponent: React.FC<InsuranceCheckoutComponentProps> = (props: InsuranceCheckoutComponentProps) => {
    const [
        state,
        handleAddInsurance,
        handleChanges,
        handlePolicyHolderCheck,
        handleSave,
        handleFileUpload
    ] = useFacade(props.handleSubmit, true, props.user.state.id);

    const classes = useStyles();
    const commonClasses = commonUseStyles();

    const controlIcon = <CheckBoxOutlineBlankIcon style={{ fill: colors.main }} />;
    const controlCheckedIcon = <CheckBoxIcon style={{ fill: colors.main }} />;

    const renderInsuranceForm = (): JSX.Element => {
        return (
            <>
                <Grid container>
                    <Grid lg={12} md={12} sm={12} xs={12}>
                        <Box>
                            <Box textAlign="center">
                                <span className={classes.formTitle}>Enter your insurance information</span>
                            </Box>
                            <Box mt={3}>
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <FormControl
                                            error={!!state.errors[`insurance.id`]}
                                            disabled={!state.editMode}
                                            variant="outlined"
                                            color='primary'
                                            required
                                            size="small"
                                            fullWidth>
                                            <InputLabel>Insurance Provider</InputLabel>
                                            <Select
                                                label='Insurance Provider *'
                                                style={{ background: '#FFF' }}
                                                id="gender"
                                                className={classes.selectItem}
                                                value={state.editMode ? state.coverageDraft?.insurance?.id : state.coverage?.insurance?.id}
                                                onChange={(v) => handleChanges('insurance.id', v.target.value as string)}
                                            >
                                                {
                                                    state.insurances.map((value) => {
                                                        return <MenuItem className={classes.menuItem} key={`insurance-${value.id}`}
                                                            value={value.id}>{value.name}</MenuItem>
                                                    })
                                                }
                                            </Select>
                                            <FormHelperText>{state.errors[`insurance.id`]}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            disabled={!state.editMode}
                                            required
                                            fullWidth
                                            size="small"
                                            id={`memberId`}
                                            variant="outlined"
                                            value={state.editMode ? state.coverageDraft?.memberId : state.coverage.memberId}
                                            label="Member ID"
                                            InputProps={{ className: 'input' }}
                                            error={!!state.errors[`memberId`]}
                                            helperText={state.errors[`memberId`]}
                                            onChange={(v) => {
                                                handleChanges(v.target.id, v.target.value)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <Box display='flex' alignItems='center'>
                                            <Box>
                                                <Checkbox
                                                    disabled={!state.editMode}
                                                    icon={controlIcon}
                                                    color="primary"
                                                    checkedIcon={controlCheckedIcon}
                                                    checked={state.containPolicyHolder}
                                                    onChange={() => handlePolicyHolderCheck()}
                                                />
                                            </Box>
                                            <Box>
                                                <span>I am not the main policyholder</span>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    {
                                        state.containPolicyHolder &&
                                        <>
                                            <Grid item md={6} xs={12}>
                                                <TextField
                                                    disabled={!state.editMode}
                                                    required
                                                    fullWidth
                                                    size="small"
                                                    id={`policyHolder.firstName`}
                                                    variant="outlined"
                                                    value={state.editMode ? state.coverageDraft?.policyHolder?.firstName : state.coverage.policyHolder?.firstName}
                                                    label="Policyholder First Name"
                                                    InputProps={{ className: 'input' }}
                                                    error={!!state.errors[`policyHolder.firstName`]}
                                                    helperText={state.errors[`policyHolder.firstName`]}
                                                    onChange={(v) => {
                                                        handleChanges(v.target.id, v.target.value)
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <TextField
                                                    disabled={!state.editMode}
                                                    required
                                                    fullWidth
                                                    size="small"
                                                    id={`policyHolder.lastName`}
                                                    variant="outlined"
                                                    value={state.editMode ? state.coverageDraft?.policyHolder?.lastName : state.coverage.policyHolder?.lastName}
                                                    label="Policyholder Last Name"
                                                    InputProps={{ className: 'input' }}
                                                    error={!!state.errors[`policyHolder.lastName`]}
                                                    helperText={state.errors[`policyHolder.lastName`]}
                                                    onChange={(v) => {
                                                        handleChanges(v.target.id, v.target.value)
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <WildHealthDatePicker
                                                    disabled={!state.editMode}
                                                    label='Policyholder Date of birth'
                                                    required
                                                    fullWidth
                                                    style={{ background: '#FFF' }}
                                                    size="small"
                                                    openTo="year"
                                                    format="MM/DD/yyyy"
                                                    id='policyHolder.dateOfBirth'
                                                    placeholder="MM/DD/yyyy"
                                                    value={state.editMode ? state.coverageDraft?.policyHolder?.dateOfBirth !== null? new Date(state.coverageDraft?.policyHolder?.dateOfBirth) : null : new Date(state.coverage.policyHolder?.dateOfBirth)}
                                                    inputVariant="outlined"
                                                    views={["year", "month", "date"]}
                                                    onChange={(momentDate) => handleChanges(`policyHolder.dateOfBirth`, momentDate.toISOString())}
                                                    disableFuture
                                                    error={!!state.errors[`policyHolder.dateOfBirth`]}
                                                    helperText={state.errors[`policyHolder.dateOfBirth`]}
                                                />
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <FormControl
                                                    error={!!state.errors[`policyHolder.relationship`]}
                                                    disabled={!state.editMode}
                                                    variant="outlined"
                                                    color='primary'
                                                    required
                                                    size="small"
                                                    fullWidth>
                                                    <InputLabel>Relationship to policyholder</InputLabel>
                                                    <Select
                                                        label='Relationship to policyholder *'
                                                        style={{ background: '#FFF' }}
                                                        id="gender"
                                                        value={state.editMode ? state.coverageDraft?.policyHolder?.relationship : state.coverageDraft.policyHolder?.relationship}
                                                        onChange={(v) => handleChanges('policyHolder.relationship', v.target.value as string)}
                                                    >
                                                        {
                                                            Object
                                                                .keys(Relationships)
                                                                .filter(x => Relationships[x] !== Relationships.SELF)
                                                                .map((key) => {
                                                                    return <MenuItem key={`relationship-${key}`}
                                                                        value={key}>{key}</MenuItem>
                                                                })
                                                        }
                                                    </Select>
                                                    <FormHelperText>{state.errors[`policyHolder.relationship`]}</FormHelperText>
                                                </FormControl>
                                            </Grid>
                                        </>
                                    }
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Box mt={6}>
                    <Grid container justify="center">
                        <Grid item xs={12} md={4} lg={4}>
                            <RegistrationNextTestedButton
                                goNext={() => handleSave()}
                                label={state.coverage ? "Continue" : "Add Insurance"}
                                isLoading={state.isSaveButtonLoading}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box mt="20px" textAlign="center">
                    <span className={clsx(commonClasses.size14, commonClasses.colorMain, commonClasses.pointer)} onClick={() => props.handleCancel()}>My insurance is not listed</span>
                </Box>
            </>
        )
    }

    const renderContent = (): JSX.Element => {
        const isLoading =
            state.isCoveragesLoading ||
            state.isAttachmentsLoading ||
            state.isInsurancesLoading;

        if (isLoading) {
            return (<WildHealthLinearProgress />)
        }

        return renderInsuranceForm();
    }

    return (
        <Box>
            {
                renderContent()
            }
        </Box>
    );
}

export const InsuranceCheckoutRedesignComponent: React.FC<InsuranceCheckoutRedesignComponentProps> = (props: InsuranceCheckoutRedesignComponentProps) => {
    const {
        handleSubmit,
        handleCancel,
        user,
        isMedicareFlow
    } = props;

    const stateId = user.state.id;
    const age = isMedicareFlow ? getAge(user.birthday) : null;

    const [
        state,
        handleAddInsurance,
        handleChanges,
        handlePolicyHolderCheck,
        handleSave,
        handleFileUpload
    ] = useFacade(handleSubmit, true, stateId, age);

    const classes = useStyles();
    const commonClasses = commonUseStyles();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const renderInsuranceForm = (): JSX.Element => {
        return (
            <>
                <Grid container>
                    <Grid lg={12} md={12} sm={12} xs={12}>
                        <Box>
                            <Box textAlign="center">
                                <span className={classes.title}>Insurance Information</span>
                            </Box>
                            <Box className={classes.inputFieldMain}>
                                <Box mt={2} mb={isSmallScreen ? 4 : 5} px={isSmallScreen && 4} textAlign="center">
                                    <span className={classes.description}>We’ll need the information listed on your insurance card in order to check your eligibility.</span>
                                </Box>
                                <Grid container spacing={isSmallScreen ? 2 : 3}>
                                    <Grid item md={12} xs={12}>
                                        <FormControl
                                            variant="outlined"
                                            color='primary'
                                            required
                                            size="small"
                                            error={!!state.errors[`insurance.id`]}
                                            disabled={!state.editMode}
                                            classes={{ root: classes.root }}
                                            fullWidth>
                                            <Box mb={1}>
                                                <span className={classes.label}>Insurance Provider <span className={commonClasses.colorMint}>*</span></span>
                                            </Box>
                                            <Select
                                                style={{ background: '#FFF' }}
                                                id="insurance-provider"
                                                classes={{ root: classes.root }}
                                                displayEmpty
                                                value={state.editMode ? state.coverageDraft?.insurance?.id : state.coverage?.insurance?.id}
                                                onChange={(v) => handleChanges('insurance.id', v.target.value as string)}
                                                inputProps={{
                                                    name: 'Insurance provider',
                                                    id: 'insurance-provider-label',
                                                    classes,
                                                }}
                                            >
                                                {
                                                    state.insurances.map((value) => {
                                                        return <MenuItem className={classes.menuItem} key={`insurance-${value.id}`}
                                                            value={value.id}>{value.name}</MenuItem>
                                                    })
                                                }
                                            </Select>
                                            <FormHelperText>{state.errors[`insurance.id`]}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <Box mb={1}>
                                            <span className={classes.label}>Member ID <span className={commonClasses.colorMint}>*</span></span>
                                        </Box>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            required
                                            InputProps={{ className: 'input', classes }}
                                            id="memberId"
                                            placeholder="Member ID"
                                            value={state.editMode ? state.coverageDraft?.memberId : state.coverage.memberId}
                                            variant="outlined"
                                            disabled={!state.editMode}
                                            error={!!state.errors[`memberId`]}
                                            helperText={state.errors[`memberId`]}
                                            onChange={(v) => {
                                                handleChanges(v.target.id, v.target.value)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <WildHealthCheckbox
                                            id="insurance-policy-holder"
                                            fill={colors.lightMint}
                                            label={<Box ml={1} component="div" display="flex" className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorNavy)}>
                                                <span>
                                                    I am not the main policyholder
                                                </span>
                                            </Box>}
                                            checkStatus={state.containPolicyHolder}
                                            disabled={!state.editMode}
                                            handleCheck={handlePolicyHolderCheck}
                                        />
                                    </Grid>
                                    {
                                        state.containPolicyHolder &&
                                        <>
                                            <Grid item md={12} xs={12}>
                                                <Box mt={4}>
                                                    <span className={classes.subTitle}>Main Policy Holder Information</span>
                                                </Box>
                                            </Grid>
                                            <Grid item md={12} xs={12}>
                                                <Box mb={1}>
                                                    <span className={classes.label}>Policy Holder First Name <span className={commonClasses.colorMint}>*</span></span>
                                                </Box>
                                                <TextField
                                                    disabled={!state.editMode}
                                                    required
                                                    fullWidth
                                                    size="small"
                                                    id={`policyHolder.firstName`}
                                                    variant="outlined"
                                                    placeholder="First Name"
                                                    value={state.editMode ? state.coverageDraft?.policyHolder?.firstName : state.coverage.policyHolder?.firstName}
                                                    InputProps={{ className: 'input', classes }}
                                                    error={!!state.errors[`policyHolder.firstName`]}
                                                    helperText={state.errors[`policyHolder.firstName`]}
                                                    onChange={(v) => {
                                                        handleChanges(v.target.id, v.target.value)
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item md={12} xs={12}>
                                                <Box mb={1}>
                                                    <span className={classes.label}>Policy Holder Last Name <span className={commonClasses.colorMint}>*</span></span>
                                                </Box>
                                                <TextField
                                                    disabled={!state.editMode}
                                                    required
                                                    fullWidth
                                                    size="small"
                                                    id={`policyHolder.lastName`}
                                                    variant="outlined"
                                                    placeholder="Last Name"
                                                    value={state.editMode ? state.coverageDraft?.policyHolder?.lastName : state.coverage.policyHolder?.lastName}
                                                    InputProps={{ className: 'input', classes }}
                                                    error={!!state.errors[`policyHolder.lastName`]}
                                                    helperText={state.errors[`policyHolder.lastName`]}
                                                    onChange={(v) => {
                                                        handleChanges(v.target.id, v.target.value)
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item md={12} xs={12}>
                                                <FormControl
                                                    variant="outlined"
                                                    color='primary'
                                                    required
                                                    error={!!state.errors[`policyHolder.dateOfBirth`]}
                                                    size="small"
                                                    disabled={!state.editMode}
                                                    classes={{ root: classes.root }}
                                                    fullWidth>
                                                    <Box mb={1}>
                                                        <span className={classes.label}>Policy Holder Birth Date <span className={commonClasses.colorMint}>*</span></span>
                                                    </Box>
                                                    <WildHealthDatePicker
                                                        disabled={!state.editMode}
                                                        required
                                                        fullWidth
                                                        style={{ background: '#FFF' }}
                                                        size="small"
                                                        openTo="year"
                                                        format="MM/DD/yyyy"
                                                        id='policyHolder.dateOfBirth'
                                                        placeholder="MM/DD/yyyy"
                                                        value={state.editMode ? state.coverageDraft?.policyHolder?.dateOfBirth !== null? new Date(state.coverageDraft?.policyHolder?.dateOfBirth) : null : new Date(state.coverage.policyHolder?.dateOfBirth)}
                                                        inputVariant="outlined"
                                                        views={["year", "month", "date"]}
                                                        onChange={(momentDate) => handleChanges(`policyHolder.dateOfBirth`, momentDate.toISOString())}
                                                        disableFuture
                                                        error={!!state.errors[`policyHolder.dateOfBirth`]}
                                                        helperText={state.errors[`policyHolder.dateOfBirth`]}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={12} xs={12}>
                                                <FormControl
                                                    variant="outlined"
                                                    color='primary'
                                                    required
                                                    size="small"
                                                    error={!!state.errors[`policyHolder.relationship`]}
                                                    disabled={!state.editMode}
                                                    classes={{ root: classes.root }}
                                                    fullWidth>
                                                    <Box mb={1}>
                                                        <span className={classes.label}>Relationship to Policy Holder <span className={commonClasses.colorMint}>*</span></span>
                                                    </Box>
                                                    <Select
                                                        style={{ background: '#FFF' }}
                                                        id="relationship-to-policy-holder"
                                                        classes={{ root: classes.root }}
                                                        displayEmpty
                                                        value={state.editMode ? state.coverageDraft?.policyHolder?.relationship : state.coverageDraft.policyHolder?.relationship}
                                                        onChange={(v) => handleChanges('policyHolder.relationship', v.target.value as string)}
                                                        inputProps={{
                                                            name: 'Insurance provider',
                                                            id: 'insurance-provider-label',
                                                            classes,
                                                        }}
                                                        renderValue={() => {
                                                            const relationship = state.editMode ? state.coverageDraft?.policyHolder?.relationship : state.coverageDraft.policyHolder?.relationship
                                                            if (relationship) {
                                                                return RelationshipNames.get(relationship);
                                                            }
                                                            
                                                            return <Box className={commonClasses.colorMediumGray}>Select</Box>;
                                                        }}
                                                    >
                                                        {
                                                            Object
                                                                .keys(Relationships)
                                                                .filter(x => Relationships[x] !== Relationships.SELF)
                                                                .map((key) => {
                                                                    return <MenuItem key={`relationship-${key}`}
                                                                        value={key}>{RelationshipNames.get(key)}</MenuItem>
                                                                })
                                                        }
                                                    </Select>
                                                    <FormHelperText>{state.errors[`policyHolder.relationship`]}</FormHelperText>
                                                </FormControl>
                                            </Grid>
                                        </>
                                    }
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Box mt={6}>
                    <Grid container justify="center">
                        <Grid item xs={12} md={3} lg={3}>
                            <RegistrationNextButton
                                goNext={() => handleSave()}
                                label={state.coverage ? "Continue" : "Add Insurance"}
                                isLoading={state.isSaveButtonLoading}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box mt={3} textAlign="center">
                    <span className={clsx(commonClasses.textMedium, commonClasses.colorDarkMint, commonClasses.pointer)} onClick={() => handleCancel()}>My insurance is not listed</span>
                </Box>
            </>
        )
    }

    const renderContent = (): JSX.Element => {
        const isLoading =
            state.isCoveragesLoading ||
            state.isAttachmentsLoading ||
            state.isInsurancesLoading;

        if (isLoading) {
            return (<WildHealthLinearProgress />)
        }

        return renderInsuranceForm();
    }

    return (
        <Box>
            {
                renderContent()
            }
        </Box>
    );
}