import React from "react";
import {useParams} from "react-router";
import {useFacade} from "./thirdPartyIntegrationLoginPage.hooks";

export const ThirdPartyIntegrationLoginPage: React.FC<any> = (props) => {
    const from = props?.location?.state?.from;
    const params = useParams();
    useFacade(params['provider'], from);

    return (
        <>

        </>
    );
}