import { useEffect, useState, ChangeEventHandler } from "react";
import { Subscription } from "recompose";
import { onEmit } from "../../../common/helpers/on-emit";
import { IErrorState } from "../../../common/validation/error-state";
import { MdmReasonModel, MdmCommonModel } from "../../../notes/models/notes.models";
import { mdmPlansService } from "../../../notes/services/mdmPlans.service";
import { notesQuery } from "../../../notes/stores/notes";
import { confirmService } from "../../../../services/confirm.service";
import { createCommonMdmValidator } from "../manageCommonMdmsComponent/createCommonMdm.validator";

interface CommonMdmPlanEditComponentState extends IErrorState {
  isLoading: boolean;
  isSubmitting: boolean;
  isOpen: boolean;
  targetMdmPlan: MdmCommonModel;
  createReason: MdmReasonModel;
  selectedId: number;
  canSubmit: boolean;
}

const initialParmas: MdmReasonModel = {
  id: 0,
  name: '',
  mdmPlanId: 0,
}

export function useFacade(mdmPlanId: number): [
  CommonMdmPlanEditComponentState,
  (field: string, value: any) => void,
  ChangeEventHandler,
  () => void,
  () => void,
  (id: number) => void,
  (id: number) => void
] {
  const [state, setState] = useState({
    isLoading: true,
    isSubmitting: false,
    isOpen: false,
    canSubmit: false,
    targetMdmPlan: null,
    createReason: initialParmas,
    selectedId: 0,
    errors: {}
  } as CommonMdmPlanEditComponentState);

  const handleChanges = (field: string, value: any) => {
    createCommonMdmValidator.validateAndSetState(state, setState, field, value);

    const params = state.targetMdmPlan;
    
    params[field] = value;

    setState({...state, targetMdmPlan: Object.assign({}, params)});
  }

  const handleChangeMdmReason = (e: any) => {
    const canSubmit = e.target.value.length > 0;
    const params = state.createReason;
    params.name = e.target.value;
    setState({
      ...state,
      createReason: Object.assign({}, params),
      canSubmit: canSubmit,
    })
  }

  const handleUpdateMdmPlan = () => {
    createCommonMdmValidator.validateObjectAndSetState(state, setState, state.targetMdmPlan);
    if (!createCommonMdmValidator.stateIsValid(state)) {
      return;
    }

    setState({...state, isSubmitting: true })

    const cb = () => setState(state => ({ ...state, isSubmitting: false,  }));
    
    mdmPlansService.updateCommonMdmPlan(state.targetMdmPlan).subscribe(cb, cb)
  }
  
  const handleSaveMdmReason = () => {
      if (!state.canSubmit) {
          return;
      }
      setState({...state, isSubmitting: true })

      const action = state.selectedId > 0 ? mdmPlansService.updateMdmReason(state.createReason) : mdmPlansService.createMdmReason(state.createReason);

      const cb = () => setState(state => ({ ...state, isSubmitting: false, isOpen: false, selectedId: -1, canSubmit: false, createReason: Object.assign({}, initialParmas) }));

      action.subscribe(cb, cb);
  }
  
  const handleDeleteMdmReason = (id: number) => {
    const item = state.targetMdmPlan.reasons.find(x => x.id === id);
    if (!item) {
        return;
    }
    confirmService.confirm(
      'Delete Reason',
      'Are you sure you want to delete this reason?',
      'Yes',
      'Cancel',
      'danger')
      .subscribe(() => {
        setState(state => ({
          ...state,
          isSubmitting: true,
          selectedId: id
        }));

        const cb = () => setState(state => ({ ...state, isSubmitting: false, selectedId: 0 }));
        mdmPlansService.deleteMdmReason(item.mdmPlanId, item.id).subscribe(cb, cb);
      });
  }

  const handleToggleMdmReasonModify = (id: number) => {
      const reason = state.targetMdmPlan.reasons.find(t => t.id === id)
      setState((state) => ({
          ...state,
          isOpen: !state.isOpen,
          selectedId: id,
          canSubmit: Boolean(reason),
          createReason: Object.assign({}, reason ? reason : { ...initialParmas, mdmPlanId }),
          errors: {}
      }))
  }

  const useEffectCB = () => {
    const subscriptions: Subscription[] = [
      onEmit<MdmCommonModel>(notesQuery.targetCommonMdm$, targetCommonMdm => {
        setState(state => ({
          ...state,
          targetMdmPlan: targetCommonMdm,
        }));
      }),
    ];

    const cb = () => setState(state => ({ ...state, isLoading: false }));
    
    mdmPlansService.getCommonMdmById(mdmPlanId).subscribe(cb, cb)

    return () => {
      subscriptions.map(it => it.unsubscribe())
    };
  }

  useEffect(useEffectCB, []);

  return [
    state,
    handleChanges,
    handleChangeMdmReason,
    handleUpdateMdmPlan,
    handleSaveMdmReason,
    handleDeleteMdmReason,
    handleToggleMdmReasonModify
  ];
}