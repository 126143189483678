import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { onEmit } from "../../../common/helpers/on-emit";
import { CompletedPatientJourneyTaskModel, PatientJourneyModel, CompletedPatientJourneyTaskCategoryModel } from "../../models/patient.model";
import { patientsService } from "../../services/patients.service";
import { patientsQuery } from "../../stores/patientsStore";
import { PlatformName, Track } from "../../../tracking/tracker";

interface PatientCompletedTasksComponentState {
    isUpdating: boolean;
    completedTask: CompletedPatientJourneyTaskCategoryModel[];
    isSideAreaOpen: boolean;
    selectedTask: CompletedPatientJourneyTaskModel;
    categoriesExpanded: string[];
}

export function useFacade(): [
    PatientCompletedTasksComponentState,
    (task: CompletedPatientJourneyTaskModel | null, open: boolean) => void,
    (category: string) => void,
    (id: number) => void
] {
    const [state, setState] = useState({
        isUpdating: false,
        completedTask: [],
        isSideAreaOpen: false,
        selectedTask: null,
        categoriesExpanded: []
    } as PatientCompletedTasksComponentState);

    const handleToggleSideArea = (task: CompletedPatientJourneyTaskModel | null, open: boolean) => {
        if (task) {
            Track("timeline_task_details", {
                title: task.title,
                platform: PlatformName(),
            });
        }
        setState(state => ({ ...state, isSideAreaOpen: open, selectedTask: task }))
    }

    const handleExpand = (category: string) => {
        const categoriesExpanded = state.categoriesExpanded.some(x => x === category)
            ? state.categoriesExpanded.filter(x => x !== category)
            : [...state.categoriesExpanded, category]
        setState({
            ...state,
            categoriesExpanded: categoriesExpanded
        });
    }

    const handleUndo = (id: number) => {
        setState(state => ({...state, isUpdating: true }));
        const cb = () => setState(state => ({...state, isUpdating: false}))
        const handleNext = () => {
            patientsService.getPatientJourneyTasks().subscribe(cb, cb)
        }

        patientsService.undoPatientJourneyTask(id).subscribe(handleNext, cb);
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<PatientJourneyModel>(patientsQuery.patientJourneyTasks$, patientJourneyTasks => {
                setState(state => ({
                    ...state,
                    completedTask: patientJourneyTasks.completed
                }));
            }),
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [
        state,
        handleToggleSideArea,
        handleExpand,
        handleUndo
    ];
}