import { Box } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import commonUseStyles from '../../../common/styles/common.styles';
import { SelectAddOnModel } from '../../models/addOns.models';
import { SelectAddOnsComponent } from '../selectAddOnsTestComponent/selectAddOnsComponent';

interface SelectAddOnsCardComponentProps {
    items: SelectAddOnModel[];
    handleSelectingItem: (id: number, value: boolean) => void;
}

export const SelectAddOnsCardComponent: React.FC<SelectAddOnsCardComponentProps> = (props: SelectAddOnsCardComponentProps) => {
    const { items, handleSelectingItem } = props;

    const commonClasses = commonUseStyles();

    return (
        <Box>
            <Box>
                <span className={clsx(commonClasses.size16, commonClasses.textMedium, commonClasses.colorDarkGray)}>Get A Head Start On Your Personalized Care</span>
            </Box>
            <Box py={2} pl={2}>
                <SelectAddOnsComponent items={items} handleSelectingItem={handleSelectingItem} />
            </Box>
        </Box>
    );
}
