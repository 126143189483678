import { Avatar, Box, Button, Divider, Grid } from "@material-ui/core";
import React from "react";
import WildHealthLinearProgress from "../../../../common/components/WildHealthLinearProgress";
import { displayAge, displayDate } from "../../../../common/helpers/display-date";
import { Gender } from "../../../../common/models/user.models";
import { useFacade } from "./generalInfoWidgetComponent.hooks";
import { useStyles } from "./generalInfoWidgetComponent.styles";
import BorderColorIcon from '@material-ui/icons/BorderColor';
import commonUseStyles from "../../../../common/styles/common.styles";
import { fullName } from "../../../../common/helpers/display-name";
import { EmployeeShortModel } from "../../../../employee/models/employee.models";
import { EmployeeType } from "../../../../employee/models/employee.enums";
import { WildHealthClickableTooltip } from "../../../../common/components/WildHealthClickableTooltip/WildHealthClickableTooltip";

export const GeneralInfoWidgetComponent: React.FC<any> = () => {
  const [
    {
      isLoading,
      patient,
      open
    },
    handleTooltipOpen,
    handleTooltipClose
  ] = useFacade();

  const classes = useStyles();
  const commonClasses = commonUseStyles();

  const customTooltipClasses = {
    tooltip: classes.customWidth,
    arrow: classes.arrow,
    tooltipPlacementBottom: classes.tooltipPlacement,
    tooltipPlacementTop: classes.tooltipPlacement,
  };

  const tooltipContent = (
    <>
      <p className={classes.notificationToolTipText}>
        Reach out to your care coordinator via messaging to edit the info
      </p>
    </>
  )

  if (isLoading) {
    return <WildHealthLinearProgress />;
  }

  const displayAssignedEmployees = (employees: EmployeeShortModel[]) => {
    if (employees.length) {
      return <Box className={classes.breakWord}>{employees.map((e) => fullName(e)).join(", ")}</Box>
    }
    return <Box className={classes.breakGrayWord}>Not assigned</Box>
  }

  return (
    <Box width='100%'>
      <Box display='flex' justifyContent='space-between'>
        <Box>
          <Box display="flex">
            <Avatar variant="rounded" src='' ></Avatar>
            <Box>
              <Box className={commonClasses.textBold} ml={2}>{`${patient.firstName} ${patient.lastName}`}</Box>
              <Box className={commonClasses.colorGray1} ml={2}>
                <span>{`${displayAge(patient.birthday)} `}</span>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <WildHealthClickableTooltip
            tooltipContent={tooltipContent}
            isOpened={open}
            handleClose={handleTooltipClose}
            customClasses={customTooltipClasses}
          >
            <Button className={classes.button} onClick={handleTooltipOpen} size="small">
              <BorderColorIcon fontSize='small' />
              <Box className={classes.buttonText}>Edit</Box>
            </Button>
          </WildHealthClickableTooltip>
        </Box>
      </Box>
      <Box className={classes.dividerSection}>
        <Divider className={classes.renderDivider} />
      </Box>
      <Grid container>
        <Grid item xs={12} md={6} lg={6}>
          <Box pb={2} display="flex" >
            <Box className={classes.textGray} >Phone:</Box>
            <Box className={classes.breakWord}>{patient.phoneNumber}</Box>
          </Box>
          <Box pb={2} display="flex">
            <Box className={classes.textGray} >Date of birth: </Box>
            <Box className={classes.breakWord}>{displayDate(patient.birthday)} </Box>
          </Box>
          <Box pb={2} display="flex">
            <Box className={classes.textGray} >Provider: </Box>
            {displayAssignedEmployees(patient.assignedEmployees.filter(el => el.type === EmployeeType.Provider))}
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Box pb={2} display="flex" >
            <Box className={classes.textGray} >Email: </Box>
            <Box className={classes.breakWord}>{patient.email}</Box>
          </Box>
          <Box pb={2} display="flex">
            <Box className={classes.textGray} >Gender: </Box>
            <Box className={classes.breakWord}>{Gender[patient.gender]}</Box>
          </Box>
          <Box display="flex">
            <Box className={classes.textGray} >Health Coach: </Box>
            {displayAssignedEmployees(patient.assignedEmployees.filter(el => el.type === EmployeeType.HealthCoach))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
