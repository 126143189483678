import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& label.Mui-focused': {
                color: colors.main,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: colors.main,
                },
            },
        },
        dialogTitle: {
            margin: 0,
            padding: theme.spacing(2),
        },
        dialogTitleCloseAction: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        action: {
            color: colors.main,
        },
        select: {
            width: 510,
            margin: 5,
        },
        field: {
            width: 250,
            margin: 5,
        },
        fullWidthField: {
            width: 510,
            margin: 5,
        },
        role: {
            width: 140,
        },
        permission: {
            width: 250,
        },
        permissionCheck: {
            color: colors.main,
            '&$checked': {
                color: colors.main,
            },
        },
        sectionTitle: {
            fontFamily: 'Be Vietnam Pro',
            fontSize: 16,
        },
        progress: {
            color: colors.main
        },
        icon: {
            borderRadius: '50%',
            width: 24,
            height: 24,
            boxShadow: 'inset 0 0 0 1px rgba(24,22,26,.2), inset 0 -1px 0 rgba(24,22,26,.1)',
            backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
            '$root.Mui-focusVisible &': {
                outline: '2px auto rgba(19,124,189,.6)',
                outlineOffset: 2,
            },
            'input:hover ~ &': {
                backgroundColor: '#ebf1f5',
            },
            'input:disabled ~ &': {
                boxShadow: 'none',
                background: 'rgba(206,217,224,.5)',
            },
        },
        checkedIcon: {
            backgroundColor: '#537b98',
            backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
            '&:before': {
                display: 'block',
                width: 24,
                height: 24,
                backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
                content: '""',
            },
            'input:hover ~ &': {
                backgroundColor: colors.main,
            },
        },
    }),
);
