import React from 'react';
import {Box, Divider, Grid} from '@material-ui/core';
import {UpdateUserForm} from "../../../../common/components/UpdateUserForm";
import {UpdateAddressForm} from "../../../../common/components/updateAddressForm/UpdateAddressForm";
import {QuestionnaireSurveyProps} from "./questionnaireSurvey.props";
import {InitialQuestionNames} from "../../../models/questionnaire.models";
import {QuestionnairesListComponent} from "../../../components/questionnairesListComponent/QuestionnairesListComponent";
import {PageSliderComponent} from '../../../../common/components/PageSliderComponent';
import {PatientProfileModel} from "../../../../account/models/patientProfile.model";
import commonUseStyles from '../../../../common/styles/common.styles';

export const GeneralQuestionnairePageFields = [
    InitialQuestionNames.HEALTH_PRIORITY,
    InitialQuestionNames.EXCITED_TO_LEARN
];

export const GeneralQuestionnairePatientFields = [
    'firstName',
    'lastName',
    'birthday',
    'gender',
    'email',
    'phoneNumber',
    'billingAddress.streetAddress1',
    'billingAddress.streetAddress2',
    'billingAddress.city',
    'billingAddress.state',
    'billingAddress.zipCode',
    'billingAddress.country',
    'shippingAddress.streetAddress1',
    'shippingAddress.streetAddress2',
    'shippingAddress.city',
    'shippingAddress.state',
    'shippingAddress.zipCode',
    'shippingAddress.country',
];

export interface QuestionnaireSurveyGeneralPageProps extends QuestionnaireSurveyProps {
    patient: PatientProfileModel;
    handlePatientChanges: Function;
}

export const GeneralSurvey: React.FC<QuestionnaireSurveyGeneralPageProps> = (props: QuestionnaireSurveyGeneralPageProps) => {
    const {
        errors,
        result,
        patient,
        questionnaire,
        handleChanges,
        navigationContext,
        handlePatientChanges,
    } = props;
    const commonClasses = commonUseStyles();

    return (
        <>
            <Box mt={4}>
                <Grid container
                      spacing={5}
                      justify="space-between">
                    <Grid item xs={12} md={6} lg={6}>
                        <Box my={5} className={commonClasses.primaryTitle}>
                            My Information:
                        </Box>
                        <UpdateUserForm
                            user={patient}
                            prefix=""
                            emailIsReadonly={false}
                            errors={errors}
                            handleChanges={handlePatientChanges}/>
                    </Grid>

                    <Grid item xs={12} md={5} lg={5}>
                        <Box>
                            <Box my={5} className={commonClasses.primaryTitle}>
                                Billing Address:
                            </Box>
                            <UpdateAddressForm
                                address={patient.billingAddress}
                                prefix={"billingAddress."}
                                errors={errors}
                                handleChanges={handlePatientChanges}/>
                        </Box>
                        <Box my={5} className={commonClasses.primaryTitle}>
                            Shipping Address:
                        </Box>
                        <UpdateAddressForm
                            address={patient.shippingAddress}
                            prefix={"shippingAddress."}
                            errors={errors}
                            handleChanges={handlePatientChanges}/>
                    </Grid>
                </Grid>
            </Box>

            <Box mt={5}>
                <Divider className="divider"/>
            </Box>
            <QuestionnairesListComponent
                questionnaire={questionnaire}
                errors={errors}
                handleChanges={handleChanges}
                navigationContext={navigationContext}
                questions={[
                    InitialQuestionNames.HEALTH_PRIORITY,
                    InitialQuestionNames.EXCITED_TO_LEARN
                ]}
                result={result}
                title={"General Information:"}
            />
            <Box mt={5}>
                <PageSliderComponent context={navigationContext}/>
            </Box>
        </>
    )
}
