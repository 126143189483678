import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chat: {
            width: '100%',
            height: '100%',
            display: 'grid',
            boxShadow: '0px 7px 60px rgba(168, 173, 198, 0.12)',
            background: colors.white,
            borderRadius: 2,

            gridTemplateColumns: '100%',
            gridTemplateAreas: '"messages"',
        },
        header: {
            display: 'flex',
            flexDirection: 'column',
            height: 110,
            [theme.breakpoints.down('sm')]: {
                height: 'auto',
                display: 'block',
            },
        },
        messages: {
            gridArea: 'messages',
            display: 'flex',
            flexDirection: 'column',
            maxHeight: 'calc(100vh - 174px) !important',
            minHeight: 565,
            [theme.breakpoints.down('sm')]: {
                minHeight: "calc(100vh - 114px) !important",
            },
        },
        title: {
            overflow: 'hidden',
            color: colors.black,
            fontWeight: 500,
            fontSize: 20,
            height: 30,
        },
        subject: {
            fontSize: 12,
            color: colors.gray2,
        },
        divider: {
            backgroundColor: colors.stroke,
        },
        icon: {
            stroke: colors.main,
            height: 26,
            width: 26,
        },
        addUser: {
            textTransform: 'none',
            color: colors.main,
            fontFamily: 'Be Vietnam Pro',
            fontWeight: 500,
            fontSize: 14,
        },
        patientInfo: {
            marginTop: 16,
            color: colors.black,
            fontWeight: 400,
            fontSize: 16,
            [theme.breakpoints.down('xs')]: {
                marginTop: 4,
                fontSize: 14,
            },
        },
        patientName: {
            color: colors.main,
            cursor: 'pointer',

            '&:hover': {
                color: `${colors.main}CC`,
                
            }
        },
        notificationToolTipText: {
            fontFamily: 'Be Vietnam Pro',
            fontSize: 12,
            fontWeight: 400,
            color: colors.white,
        },
        arrow: {
            color: colors.lightBlack,
        },
        customWidth: {
            maxWidth: 'none',
            backgroundColor: colors.lightBlack,
            padding: 10,
            display: 'flex',
            justifyContent: 'center'
        },
        tooltipPlacement: {
            margin: '8px 0',
        },
        sideContainer: {
            height: '100%',
            width: '100%',
            overflow: 'auto',
        },
        goBack: {
          [theme.breakpoints.down("xs")]: {
            background: colors.white,
            borderBottom: "1px solid",
            borderBottomColor: colors.stroke,
          },
        },
        titleAndAvatars: {
          display: "flex",
          [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
            alignItems: "center",
            gap: 0,
          },
        },
    })
);
