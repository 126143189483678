import { Query } from '@datorama/akita';
import { EmployerProductsState, employerProductsStore, EmployerProductsStore } from "./employerProductsStore";
import { EmployerProductModel } from '../../models/employerProduct.model';

/**
 * Provides employerProducts queries
 */
export class EmployerProductsQuery extends Query<EmployerProductsState> {
    employerProducts$ = this.select(state => state.employerProducts);
    selectedEmployer$ = this.select(state => state.selectedEmployer);

    constructor(protected store: EmployerProductsStore) {
        super(store);
    }

    /**
     * Returns employer products
     */
    public getEmployerProducts(): EmployerProductModel[] {
        return this.getValue().employerProducts;
    }

    /**
     * Returns selected employer product
     */
    public getSelectedEmployer(): EmployerProductModel {
        return this.getValue().selectedEmployer;
    }
}

export const employerProductsQuery = new EmployerProductsQuery(employerProductsStore);
