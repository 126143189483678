import { Box, FormControl, MenuItem, Select, Table, TableBody, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import React from 'react';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { WildHealthTableCell } from '../../../common/components/wildHealthTable/WildHealthTableCell';
import { WildHealthTableRow } from '../../../common/components/wildHealthTable/WildHealthTableRow';
import { getNumericKeys } from "../../../common/helpers/enum-mapper";
import { diabetesNames, dietNames, ethnicityNames, exerciseNames } from "../../constants/inputs.constants";
import { DiabetesType, DietType, Ethnicity, ExerciseType, SmokingCategory, YesNo } from "../../models/input.models";
import { useFacade } from "./patientGeneralInputsComponent.hooks";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";

export const PatientGeneralInputsComponent: React.FC = () => {
    const [
        {
            inputs,
            errors,
            isSaving,
            isVitalSaving
        },
        handleChanges,
        handleNumericChanges,
        handleSubmit,
    ] = useFacade();

    if (!inputs) {
        return (
            <Box py={3}>
                <WildHealthLinearProgress />
            </Box>
        );
    }

    return (
        <Box py={5}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <WildHealthTableCell align="left">
                                Name
                            </WildHealthTableCell>
                            <WildHealthTableCell align="left">
                                Current Value
                            </WildHealthTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <WildHealthTableRow>
                            <WildHealthTableCell align="left">
                                Client Exercise Goal
                            </WildHealthTableCell>
                            <WildHealthTableCell align="left">
                                <FormControl color='primary' variant="outlined" size="small" fullWidth>
                                    <Select
                                        style={{ background: '#FFF' }}
                                        id="exerciseGoal"
                                        value={inputs.exerciseGoal}
                                        onChange={(v) => handleChanges("exerciseGoal", v.target.value)}>
                                        {
                                            getNumericKeys(ExerciseType).map((exerciseType, i) => {
                                                return <MenuItem key={i} value={exerciseType}>{exerciseNames[exerciseType]}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </WildHealthTableCell>
                        </WildHealthTableRow>
                        <WildHealthTableRow>
                            <WildHealthTableCell align="left">
                                Client Diet Choice
                            </WildHealthTableCell>
                            <WildHealthTableCell align="left">
                                <FormControl color='primary' variant="outlined" size="small" fullWidth>
                                    <Select
                                        style={{ background: '#FFF' }}
                                        id="dietChoice"
                                        value={inputs.dietChoice}
                                        onChange={(v) => handleChanges("dietChoice", v.target.value)}>
                                        {
                                            getNumericKeys(DietType).map((dietType, i) => {
                                                return <MenuItem key={i} value={dietType}>{dietNames[dietType]}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </WildHealthTableCell>
                        </WildHealthTableRow>
                        <WildHealthTableRow>
                            <WildHealthTableCell align="left">
                                Exercise Activities per week (0-7)
                            </WildHealthTableCell>
                            <WildHealthTableCell align="left">
                                <TextField
                                    fullWidth
                                    error={!!errors["exerciseActivitiesFrequency"]}
                                    helperText={errors["exerciseActivitiesFrequency"]}
                                    type="number"
                                    InputProps={{ className: 'input' }}
                                    value={inputs.exerciseActivitiesFrequency ?? ''}
                                    onChange={(v) => handleNumericChanges("exerciseActivitiesFrequency", v.target.value)}
                                    variant="outlined"
                                    size="small"
                                />
                            </WildHealthTableCell>
                        </WildHealthTableRow>
                        <WildHealthTableRow>
                            <WildHealthTableCell align="left">
                                MindFullness Activities per week (0-7)
                            </WildHealthTableCell>
                            <WildHealthTableCell align="left">
                                <TextField
                                    fullWidth
                                    error={!!errors["meditationFrequency"]}
                                    helperText={errors["meditationFrequency"]}
                                    type="number"
                                    InputProps={{ className: 'input' }}
                                    value={inputs.meditationFrequency ?? ''}
                                    onChange={(v) => handleNumericChanges("meditationFrequency", v.target.value)}
                                    variant="outlined"
                                    size="small"
                                />
                            </WildHealthTableCell>
                        </WildHealthTableRow>
                        <WildHealthTableRow>
                            <WildHealthTableCell align="left">
                                REM
                            </WildHealthTableCell>
                            <WildHealthTableCell align="left">
                                <TextField
                                    fullWidth
                                    error={!!errors["rem"]}
                                    helperText={errors["rem"]}
                                    type="number"
                                    InputProps={{ className: 'input' }}
                                    value={inputs.rem ?? ''}
                                    onChange={(v) => handleNumericChanges("rem", v.target.value)}
                                    variant="outlined"
                                    size="small"
                                />
                            </WildHealthTableCell>
                        </WildHealthTableRow>
                        <WildHealthTableRow>
                            <WildHealthTableCell align="left">
                                Ethnicity
                            </WildHealthTableCell>
                            <WildHealthTableCell align="left">
                                <FormControl color='primary' variant="outlined" size="small" fullWidth>
                                    <Select
                                        style={{ background: '#FFF' }}
                                        id="ethnicity"
                                        value={inputs.ethnicity}
                                        onChange={(v) => handleChanges("ethnicity", v.target.value)}>
                                        {
                                            getNumericKeys(Ethnicity).map((eth, i) => {
                                                return <MenuItem key={i} value={eth}>{ethnicityNames[eth]}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </WildHealthTableCell>
                        </WildHealthTableRow>
                        <WildHealthTableRow>
                            <WildHealthTableCell align="left">
                                Smoking Status
                            </WildHealthTableCell>
                            <WildHealthTableCell align="left">
                                <FormControl color='primary' variant="outlined" size="small" fullWidth>
                                    <Select
                                        style={{ background: '#FFF' }}
                                        id="smokingCategory"
                                        value={inputs.smokingCategory}
                                        onChange={(v) => handleChanges("smokingCategory", v.target.value)}>
                                        {
                                            getNumericKeys(SmokingCategory).map((category, i) => {
                                                return <MenuItem key={i} value={category}>{SmokingCategory[category]}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </WildHealthTableCell>
                        </WildHealthTableRow>
                        <WildHealthTableRow>
                            <WildHealthTableCell align="left">
                                Diabetes Status
                            </WildHealthTableCell>
                            <WildHealthTableCell align="left">
                                <FormControl color='primary' variant="outlined" size="small" fullWidth>
                                    <Select
                                        style={{ background: '#FFF' }}
                                        id="diabetesType"
                                        value={inputs.diabetesType}
                                        onChange={(v) => handleChanges("diabetesType", v.target.value)}>
                                        {
                                            getNumericKeys(DiabetesType).map((type, i) => {
                                                return <MenuItem key={i} value={type}>{diabetesNames[type]}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </WildHealthTableCell>
                        </WildHealthTableRow>
                        <WildHealthTableRow>
                            <WildHealthTableCell align="left">
                                Deep Sleep (hours)
                            </WildHealthTableCell>
                            <WildHealthTableCell align="left">
                                <TextField
                                    fullWidth
                                    error={!!errors["deepSleep"]}
                                    helperText={errors["deepSleep"]}
                                    type="number"
                                    InputProps={{ className: 'input' }}
                                    value={inputs.deepSleep ?? ''}
                                    onChange={(v) => handleNumericChanges("deepSleep", v.target.value)}
                                    variant="outlined"
                                    size="small"
                                />
                            </WildHealthTableCell>
                        </WildHealthTableRow>
                        <WildHealthTableRow>
                            <WildHealthTableCell align="left">
                                Sleep (hours)
                            </WildHealthTableCell>
                            <WildHealthTableCell align="left">
                                <TextField
                                    fullWidth
                                    error={!!errors["sleep"]}
                                    helperText={errors["sleep"]}
                                    type="number"
                                    InputProps={{ className: 'input' }}
                                    value={inputs.sleep ?? ''}
                                    onChange={(v) => handleNumericChanges("sleep", v.target.value)}
                                    variant="outlined"
                                    size="small"
                                />
                            </WildHealthTableCell>
                        </WildHealthTableRow>
                        <WildHealthTableRow>
                            <WildHealthTableCell align="left">
                                Chronological Age
                            </WildHealthTableCell>
                            <WildHealthTableCell align="left">
                                <TextField
                                    fullWidth
                                    type="number"
                                    value={inputs.chronologicalAge ?? ''}
                                    variant="outlined"
                                    size="small"
                                    disabled
                                />
                            </WildHealthTableCell>
                        </WildHealthTableRow>
                        <WildHealthTableRow>
                            <WildHealthTableCell align="left">
                                <span>Age Bio </span>
                                <a id="patient-inputs-calculate-age" target="_blank" rel="noopener noreferrer" href="https://www.onegevity.com/products/agebio#:~:text=Hood%20invented%20automated%20DNA%20sequencing%2cmaximize%20those%20additional%20healthy%20years.">
                                    (calculate online)
                                </a>
                            </WildHealthTableCell>
                            <WildHealthTableCell align="left">
                                <TextField
                                    fullWidth
                                    error={!!errors["realAge"]}
                                    helperText={errors["realAge"]}
                                    type="number"
                                    InputProps={{ className: 'input' }}
                                    value={inputs.realAge ?? ''}
                                    onChange={(v) => handleNumericChanges("realAge", v.target.value)}
                                    variant="outlined"
                                    size="small"
                                />
                            </WildHealthTableCell>
                        </WildHealthTableRow>
                        <WildHealthTableRow>
                            <WildHealthTableCell align="left">
                                Epigenetic Age
                            </WildHealthTableCell>
                            <WildHealthTableCell align="left">
                                <TextField
                                    fullWidth
                                    error={!!errors["biologicalAge"]}
                                    helperText={errors["biologicalAge"]}
                                    type="number"
                                    InputProps={{ className: 'input' }}
                                    value={inputs.biologicalAge ?? ''}
                                    onChange={(v) => handleNumericChanges("biologicalAge", v.target.value)}
                                    variant="outlined"
                                    size="small"
                                />
                            </WildHealthTableCell>
                        </WildHealthTableRow>
                        <WildHealthTableRow>
                            <WildHealthTableCell align="left">
                                Fasting Regularly
                            </WildHealthTableCell>
                            <WildHealthTableCell align="left">
                                <FormControl color='primary' variant="outlined" size="small" fullWidth>
                                    <Select
                                        style={{ background: '#FFF' }}
                                        id="fastingRegularly"
                                        value={inputs.fastingRegularly}
                                        onChange={(v) => handleChanges("fastingRegularly", v.target.value)}>
                                        {
                                            getNumericKeys(YesNo).map((yn, i) => {
                                                return <MenuItem key={i} value={yn}>{YesNo[yn]}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </WildHealthTableCell>
                        </WildHealthTableRow>
                        <WildHealthTableRow>
                            <WildHealthTableCell align="left">
                                Cancer Screening Completed
                            </WildHealthTableCell>
                            <WildHealthTableCell align="left">
                                <FormControl color='primary' variant="outlined" size="small" fullWidth>
                                    <Select
                                        style={{ background: '#FFF' }}
                                        id="cancerScreeningCompleted"
                                        value={inputs.cancerScreeningCompleted}
                                        onChange={(v) => handleChanges("cancerScreeningCompleted", v.target.value)}>
                                        {
                                            getNumericKeys(YesNo).map((yn, i) => {
                                                return <MenuItem key={i} value={yn}>{YesNo[yn]}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </WildHealthTableCell>
                        </WildHealthTableRow>
                        <WildHealthTableRow>
                            <WildHealthTableCell align="left">
                                Angina or Heart Attack in a 1st Degree Relative &lt; 60
                            </WildHealthTableCell>
                            <WildHealthTableCell align="left">
                                <FormControl color='primary' variant="outlined" size="small" fullWidth>
                                    <Select
                                        style={{ background: '#FFF' }}
                                        id="familyHeartAttack"
                                        value={inputs.familyHeartAttack}
                                        onChange={(v) => handleChanges("familyHeartAttack", v.target.value)}>
                                        {
                                            getNumericKeys(YesNo).map((yn, i) => {
                                                return <MenuItem key={i} value={yn}>{YesNo[yn]}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </WildHealthTableCell>
                        </WildHealthTableRow>
                        <WildHealthTableRow>
                            <WildHealthTableCell align="left">
                                Chronic Kidney Disease (stage 4 or 5)
                            </WildHealthTableCell>
                            <WildHealthTableCell align="left">
                                <FormControl color='primary' variant="outlined" size="small" fullWidth>
                                    <Select
                                        style={{ background: '#FFF' }}
                                        id="chronicKidneyDisease"
                                        value={inputs.chronicKidneyDisease}
                                        onChange={(v) => handleChanges("chronicKidneyDisease", v.target.value)}>
                                        {
                                            getNumericKeys(YesNo).map((yn, i) => {
                                                return <MenuItem key={i} value={yn}>{YesNo[yn]}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </WildHealthTableCell>
                        </WildHealthTableRow>
                        <WildHealthTableRow>
                            <WildHealthTableCell align="left">
                                Atrial Fibrillation
                            </WildHealthTableCell>
                            <WildHealthTableCell align="left">
                                <FormControl color='primary' variant="outlined" size="small" fullWidth>
                                    <Select
                                        style={{ background: '#FFF' }}
                                        id="atrialFibrillation"
                                        value={inputs.atrialFibrillation}
                                        onChange={(v) => handleChanges("atrialFibrillation", v.target.value)}>
                                        {
                                            getNumericKeys(YesNo).map((yn, i) => {
                                                return <MenuItem key={i} value={yn}>{YesNo[yn]}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </WildHealthTableCell>
                        </WildHealthTableRow>
                        <WildHealthTableRow>
                            <WildHealthTableCell align="left">
                                On Blood Pressure Treatment
                            </WildHealthTableCell>
                            <WildHealthTableCell align="left">
                                <FormControl color='primary' variant="outlined" size="small" fullWidth>
                                    <Select
                                        style={{ background: '#FFF' }}
                                        id="bloodPressureTreatment"
                                        value={inputs.bloodPressureTreatment}
                                        onChange={(v) => handleChanges("bloodPressureTreatment", v.target.value)}>
                                        {
                                            getNumericKeys(YesNo).map((yn, i) => {
                                                return <MenuItem key={i} value={yn}>{YesNo[yn]}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </WildHealthTableCell>
                        </WildHealthTableRow>
                        <WildHealthTableRow>
                            <WildHealthTableCell align="left">
                                Rheumatoid Arthritis
                            </WildHealthTableCell>
                            <WildHealthTableCell align="left">
                                <FormControl color='primary' variant="outlined" size="small" fullWidth>
                                    <Select
                                        style={{ background: '#FFF' }}
                                        id="rheumatoidArthritis"
                                        value={inputs.rheumatoidArthritis}
                                        onChange={(v) => handleChanges("rheumatoidArthritis", v.target.value)}>
                                        {
                                            getNumericKeys(YesNo).map((yn, i) => {
                                                return <MenuItem key={i} value={yn}>{YesNo[yn]}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </WildHealthTableCell>
                        </WildHealthTableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <Box m={2} display="flex" justifyContent="flex-end">
                <WildHealthButton
                    id="patient-general-inputs-save"
                    loading={isSaving || isVitalSaving}
                    onClick={() => handleSubmit()}
                >
                    Save Changes
                </WildHealthButton>
            </Box>
        </Box>
    )
}