import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
    ConnectionStatuses,
    ConversationAuthorModel,
    ConversationModelBase,
    LastReadMessageUpdateModel,
    ParticipantModel,
    ConversationModelBaseView,
    AcceptInteractionModel,
    TargetInteractionModel
} from "../../models/conversation.models";
import { ConversationState } from "../../models/conversationState.models";
import { DropEvent, FileRejection } from "react-dropzone";
import { employeeConversationsStore } from "../../stores/employeeConversationsStore/employeeConversations.store";
import { scheduleMessageService } from "../../services/scheduleMessage.service";
import { Subscription, timer } from "rxjs";
import { scheduledMessagesStore } from "../../stores/scheduledMessagesStore/scheduledMessages.store";
import { AttachFileModel, ScheduledMessageModel, UploadedAttachmentModel, MessageSettingsModel } from "../../models/message.models";
import { authQuery } from "../../../auth/stores/auth";
import { scheduledMessagesQuery } from "../../stores/scheduledMessagesStore/scheduledMessages.query";
import { onEmit } from "../../../common/helpers/on-emit";
import { conversationsService } from "../../services/conversations.service";
import { UserType } from "../../../auth/models/auth.enums";
import moment from "moment";
import { timezonesQuery } from "../../../timezones/stores/timezones";
import { timezonesService } from "../../../timezones/services/timezones.service";
import { TimeZoneModel } from "../../../timezones/models/timezone.model";
import { patientConversationsStore } from "../../stores/patientConversationsStore/patientConversations.store";
import { Client as ConversationsClient } from "@twilio/conversations";
import { employeeConversationsQuery } from "../../stores/employeeConversationsStore/employeeConversations.query";
import { patientConversationsQuery } from "../../stores/patientConversationsStore/patientConversations.query";
import { conversationsViewService } from "../../services/conversationsView.service";
import { navigationService } from "../../../../services/navigation.service";
import { getConversationAttributes } from "../../helpers/getAttributes";

interface Paginator {
    hasNextPage: boolean;
    hasPrevPage: boolean;
    items: any[];
    nextPage: Function;
    prevPage: Function;
}

interface UnreadMessage {
    index: number;
    isVisible: boolean;
    observer: IntersectionObserver;
}

export interface BaseConversationComponentState {
    isLoadingMessages: boolean;
    paginator: Paginator;
    messages: any[];
    messagesStaff: ConversationModelBaseView[];
    currentPage: number;
    perPage: number;
    scrollToIndex: number;
    lastReadMessageIndex: number;
    typing: ParticipantModel[];
    unreadMessages: UnreadMessage[];
    unreadMessageClicked: boolean;
}

interface ConversationComponentState {
    isScheduleDialogOpen: boolean;
    scheduledMessageForEditing: ScheduledMessageModel;
    scheduledAttachmentsForEditing: UploadedAttachmentModel[];
    removeAttachments: number[];
    toSendAttachments: AttachFileModel[];
    messageToSchedule: string;
    timeZones: TimeZoneModel[];
    emptyMessage: boolean;
    aiMessageForEditing: TargetInteractionModel;
    detailForAiMessage: string;
    awayMessageEnabled: boolean;
}

interface ConversationInternalComponentState {
    removeAttachments: number[];
    toSendAttachments: AttachFileModel[];
    awayMessageEnabled: boolean;
}

let conversationsClient: ConversationsClient = null;
let stateContext: BaseConversationComponentState;

export function useBaseFacade(conversation: ConversationModelBase, tempAuthor: ConversationAuthorModel): [ //, author: ConversationAuthorModel): [
    BaseConversationComponentState,
    (message: any) => void,
    () => void,
    (conversationProxy: any) => void] {

    const [state, setState] = useState({
        isLoadingMessages: true,
        paginator: null,
        messages: [],
        messagesStaff: null,
        currentPage: 1,
        perPage: 20,
        scrollToIndex: -1,
        lastReadMessageIndex: -1,
        typing: [],
        unreadMessages: [],
        unreadMessageClicked: false
    } as BaseConversationComponentState);

    const [author, setAuthor] = useState<ConversationAuthorModel>(tempAuthor)

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<ConversationAuthorModel>(employeeConversationsQuery.author$, author => {
                if (author && authQuery.getType() === UserType.Employee) {
                    setAuthor(author)
                }
            }),
            onEmit<ConversationAuthorModel>(patientConversationsQuery.author$, author => {
                if (author && authQuery.getType() === UserType.Patient) {
                    setAuthor(author)
                }
            }),
            onEmit<ConversationModelBaseView[]>(patientConversationsQuery.targetStaffConversation$, messages =>
                setState(state => ({ ...state, messagesStaff: messages }))
            )
        ]

        if (conversation) {
            subscriptions.push(
                timer(3000).subscribe(() => getMessageByVendorExternalId(conversation))
            )    
        }
        
        return () => {
            subscriptions.map(item => item.unsubscribe())
        }
    }, [conversation])

    const getMessageByVendorExternalId = (conversation: ConversationModelBase) => {
        if (conversation && conversation.proxy) {
            return;
        }
        
        if (conversation.vendorExternalId) {
            if (authQuery.getType() === UserType.Employee) {
                conversationsViewService.getPatientAllMessagesStaff(conversation.vendorExternalId).subscribe(() => {
                    setState(state => ({ ...state, isLoadingMessages: false }))
                }, () => setState(state => ({ ...state, isLoadingMessages: false })));
            }
            else {
                conversationsViewService.getAllMessagesSupport(conversation.vendorExternalId).subscribe(() => {
                    setState(state => ({ ...state, isLoadingMessages: false }))
                }, () => setState(state => ({ ...state, isLoadingMessages: false })));
            }
        } else {
            setState(state => ({ ...state, isLoadingMessages: false }))
        }
    }

    stateContext = state;

    const handleSendMessage = (message: any) => {
        if (!employeeConversationsQuery.getConnectionStatus()) return;
        if (conversation.proxy?.status === ConnectionStatuses.joined) {

            const employeeParticipant = conversation.employees.find(i => i.email === authQuery.getEmail() && !i.isDeleted);
            if (employeeParticipant && !employeeParticipant.isActive) {
                conversationsService.addEmployeeToConversationModel({ conversationId: conversation.id, employeeId: employeeParticipant.employeeId }).subscribe();
            }
        }
    };

    const handleMessageAdded = useCallback((message: any) => {

        //todo: if scroll not in the end check who is author of message. If author Not me mark this message as unread.

        if (state.messages.find(x => x.state.sid === message.state.sid)) {
            return;
        }
        updateLastReadMessageIndex(conversation, message, message.author)
    }, [conversation?.proxy]);

    const handleMessageUpdated = useCallback((message: any) => {
        setState(state => ({
            ...state,
            messages: state.messages.map(x => x.sid === message.message.sid ? message.message : x)
        }))
    }, [conversation?.proxy]);

    const updateLastReadMessageIndex = (conversation: ConversationModelBase, message: any, participantExternalVendorId: string) => {

        if (stateContext.messages.includes(message)) {
            setState(state => ({
                ...state,
                scrollToIndex: message.state.index,
                lastReadMessageIndex: message.state.index,
            }));
        }
        else {
            setState(state => ({
                ...state,
                messages: [...state.messages, message],
                scrollToIndex: message.state.index,
                lastReadMessageIndex: message.state.index,
            }));
        }

        if (message && conversation.proxy?.status === ConnectionStatuses.joined) {
            // Tell our API about the read message index - this is for the purpose of keeping this known locally for unread message notifications
            const lastReadMessageUpdateModel: LastReadMessageUpdateModel = {
                conversationExternalVendorId: conversation.proxy.sid,
                participantExternalVendorId: participantExternalVendorId,
                lastMessageReadIndex: message.state.index
            }
            // Tell Twilio about the read message index
            conversation.proxy.updateLastReadMessageIndex(message.state.index);

            if (participantExternalVendorId !== message.state.author) {

                conversationsService.lastReadMessageUpdate(lastReadMessageUpdateModel).subscribe();
            }
        }

        if (conversation.unreadMessages > 0) {
            conversation.unreadMessages = 0;
            patientConversationsStore.updateSupportConversation(conversation);
        }
    }

    const handleTyping = () => {
        if (conversation?.proxy?.status === ConnectionStatuses.joined) {
            conversation.proxy.typing();
        }
    }

    const startTyping = useCallback((member) => {
        const pushTypingIfNotExist = (participant: ParticipantModel) => {
            if (stateContext.typing.includes(participant)) {
                return;
            }

            setState(state => ({ ...state, typing: [...stateContext.typing, participant] }));
        }

        if (authQuery.getType() === UserType.Patient) {
            return;
        }

        const patient = conversation.patients.find(i => i.vendorExternalIds?.includes(member.state.sid));

        if (patient) {
            pushTypingIfNotExist(patient);
        } else {
            const employee = conversation.employees.find(i => i.vendorExternalIds?.includes(member.state.sid));
            if (employee) {
                pushTypingIfNotExist(employee);
            }
        }
    }, [conversation?.employees, conversation?.patients]);

    const endTyping = useCallback((member) => {
        setState(state => ({ ...state, typing: stateContext.typing.filter(i => !i.vendorExternalIds?.includes(member.state.sid)) }));
    }, []);

    const createObserverForUnreadMessages = useCallback((lastReadMessageIndex: number, lasMessageIndex: number) => {

        if (lastReadMessageIndex ?? -1 >= lasMessageIndex) {
            return
        }

        const unreadMessageCB = (entries) => {
            if (entries[0]['isIntersecting'] === true) {
                if (entries[0]['intersectionRatio'] === 1) {
                    const message = stateContext.unreadMessages.find(i => `message-${i.index}` === entries[0].target.id);
                    if (message) {
                        message.isVisible = true;
                    }
                }
            }
        }

        const unreadMessages = [];

        for (let i = (lastReadMessageIndex ?? -1) + 1; i <= lasMessageIndex; i++) {
            unreadMessages.push({
                index: i,
                isVisible: false,
                observer: new IntersectionObserver((entries) => unreadMessageCB(entries), { threshold: [1] })
            });
        }

        setState(state => ({ ...state, unreadMessages: unreadMessages }));
    }, []);

    const createObserverForLoadMoreMessages = useCallback(() => {
        const threadStart = document.querySelector(`#thread-start-${conversation.id}`);

        const threadStartObserver = new IntersectionObserver((entries) => {
            if (entries[0]['isIntersecting'] === true) {
                if (entries[0]['intersectionRatio'] === 1) {
                    if (canLoadMore()) {
                        handleLoadMore();
                    }
                }
            }
        }, { threshold: [0.25, 0.5, 1] });

        if (threadStart) {
            threadStartObserver.observe(threadStart);
        }
    }, []);

    const createObserverForMessagesLoaded = useCallback((scrollToIndex: number) => {
        const threadStart = document.querySelector(`#thread-${conversation.id}`);
        const scrollToMessage = threadStart.querySelector(`#message-${scrollToIndex}`)

        const messagesLoadedObserver = new IntersectionObserver((entries) => {
            if (entries[0]['isIntersecting'] === true) {
                if (entries[0]['intersectionRatio'] === 1) {
                    messagesLoadedObserver.unobserve(scrollToMessage)
                    createObserverForLoadMoreMessages()
                }
            }
        }, { threshold: [0.25, 0.5, 1] });

        if (scrollToMessage) {
            messagesLoadedObserver.observe(scrollToMessage);
        }
    }, []);

    const canLoadMore = useCallback(() => {
        return (
            conversation.state !== ConversationState.Closed &&
            !stateContext.isLoadingMessages &&
            stateContext.paginator?.hasPrevPage
        )
    }, [conversation?.state]);

    const loadMessagesCB = useCallback((conversationProxy: any, paginator: any) => {

        const lastMessageIndex = conversationProxy.channelState.lastMessage.index;
        const lastReadIndex = conversationProxy.channelState.lastReadMessageIndex;
        const scrollToIndex = lastReadIndex && lastReadIndex <= lastMessageIndex ?
            lastReadIndex :
            lastMessageIndex;


        setState(state => ({
            ...state,
            messages: paginator.items,
            paginator: paginator,
            lastReadMessageIndex: scrollToIndex,
            scrollToIndex: scrollToIndex,
            isLoadingMessages: false,
        }));

        createObserverForMessagesLoaded(scrollToIndex);

        const pos = paginator?.items.length - 1;
        const index = paginator?.items.slice()[pos ?? 0]?.state?.index;
        createObserverForUnreadMessages(conversationProxy.channelState.lastReadMessageIndex, index ?? 0);
    }, [conversation, createObserverForUnreadMessages, canLoadMore]);

    const loadMessages = useCallback((conversationProxy: any) => {
        if (conversationProxy && conversationProxy?.status === ConnectionStatuses.joined) {
            conversationProxy.getMessages(state.perPage)
                .then(paginator => loadMessagesCB(conversationProxy, paginator))
                .catch(() => setState(state => ({ ...state, isLoadingMessages: false })));
        }
    }, [loadMessagesCB, state.perPage]);

    const handleLoadMore = () => {

        setState(state => ({ ...state, isLoadingMessages: true, scrollToIndex: -1 }));

        stateContext.paginator.prevPage().then((paginator) => {
            stateContext.currentPage++;
            setState(state => ({
                ...state,
                messages: [...paginator.items, ...stateContext.messages],
                scrollToIndex: paginator.items[paginator.items.length - 1].state.index,
                paginator: paginator,
                isLoadingMessages: false,
            }));
        }).catch(() => setState(state => ({ ...state, isLoadingMessages: false })));
    };

    const connectionStateChanged = useCallback((status: string) => {
        setState(state => ({ ...state, status: status }));
    }, []);

    const conversationJoined = useCallback((proxy: any) => {

        // Means this just got added from client before we could add to our state, store the proxy for later matching
        if (conversation && conversation.vendorExternalId === proxy.sid) {
            employeeConversationsStore.addOrphanedProxy(proxy);
            patientConversationsStore.addOrphanedProxy(proxy);

            conversation.proxy = proxy;

            employeeConversationsStore.updateConversation(conversation);
        }

    }, [conversation]);

    const checkReadMessages = (author: ConversationAuthorModel, conversation: ConversationModelBase) => {
        if(conversation === null || conversation === undefined){
            return;
        }
        //TODO: this method should be simpler, refactoring when possible
        // adding an state when setUnreadMessasge to this conversation to avoid removing the badge and the
        // line of unread message.

        if (!stateContext.unreadMessageClicked) {

            let lastVisibleIndex = stateContext.lastReadMessageIndex;

            stateContext.unreadMessages.forEach(message => {
                if (message.isVisible) {
                    lastVisibleIndex = message.index;
                }
            });

            const lastMessage = stateContext.messages[stateContext.messages.length - 1];

            if (lastMessage) {

                const unreadMessages = lastMessage?.state?.index - lastVisibleIndex;
                if (author && conversation.unreadMessages > 0) {
                    updateLastReadMessageIndex(conversation, lastMessage, author.conversationIdentity);
                }

                if (author && unreadMessages > 0) {

                    if (conversation.employees.find(i => i.email === authQuery.getEmail() && !i.isDeleted)?.isActive) {
                        conversation.unreadMessages = unreadMessages >= 0 ? unreadMessages : 0;
                    }

                    employeeConversationsStore.updateConversation(conversation);
                }

                if (conversation.unreadMessages > 0) {
                    conversation.unreadMessages = 0;
                    patientConversationsStore.updateSupportConversation(conversation);
                }
            }
        }
    }

    const unsubscribeJoined = () => {
        if (conversationsClient) {
            conversationsClient.off('connectionStateChanged', connectionStateChanged);
            conversationsClient.off('conversationJoined', conversationJoined);
        }
    }

    const subscribe = useCallback(async () => {
        if (conversation && conversation.proxy) {
            conversation.proxy.on('messageAdded', handleMessageAdded);
            conversation.proxy.on('messageUpdated', handleMessageUpdated);
            conversation.proxy.on('typingStarted', startTyping);
            conversation.proxy.on('typingEnded', endTyping);

            loadMessages(conversation.proxy);

        } else if (author) {
            conversationsClient = new ConversationsClient(author.conversationAuthToken);
            conversationsClient.on("connectionStateChanged", connectionStateChanged);
            conversationsClient.on("conversationJoined", conversationJoined);
        }
    }, [handleMessageAdded, startTyping, endTyping, conversation, loadMessages, author]);

    const unsubscribe = useCallback(() => {

        setState(state => ({ ...state, typing: [] }));
        if (conversation && conversation.proxy) {
            conversation.proxy.off('messageAdded', handleMessageAdded);
            conversation.proxy.off('messageUpdated', handleMessageUpdated);
            conversation.proxy.off('typingStarted', startTyping);
            conversation.proxy.off('typingEnded', endTyping);
        }
    }, [handleMessageAdded, startTyping, endTyping, conversation]);

    const useEffectCB = () => {
        setState(state => ({ ...state, isLoadingMessages: true, messages: [] }));
        subscribe();
        return () => { unsubscribe(); };
    }

    useEffect(useEffectCB, [conversation?.id, conversation?.proxy?.sid]);

    useEffect(() => {
        const subscriptions: Subscription[] = [];

        if (author) {
            // increased the time to check read message since the scrolling is still rolling.
            // just to see the effect of the unread message is gone after read it.
            setState((state) => ({ ...state, unreadMessageClicked: false }));
            subscriptions.push(
                timer(1000, 1000).subscribe(() => checkReadMessages(author, authQuery.getType() === UserType.Patient ? conversation : conversation ?? employeeConversationsQuery.getTargetConversation())))
        }

        return () => {
            unsubscribeJoined();
            subscriptions.map(it => it.unsubscribe());
        };
    }, [author, conversation?.proxy?.sid]);

    return [state,
        handleSendMessage,
        handleTyping,
        loadMessages
    ];
}

export function useFacade(conversation: ConversationModelBase, author: ConversationAuthorModel): [
    ConversationComponentState & BaseConversationComponentState,
    (message: any) => void,
    <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void,
    (index: number) => void,
    () => void,
    (message: string, removeAttachmentIds: number[], attachments: UploadedAttachmentModel[]) => void,
    () => void,
    (date: Date, time: Date) => void,
    () => void,
    (messageSelected: LastReadMessageUpdateModel) => void,
    (message: string) => void,
    () => void,
    (message: string) => void
] {

    const [state, setState] = useState({
        isScheduleDialogOpen: false,
        scheduledMessageForEditing: null,
        scheduledAttachmentsForEditing: [],
        removeAttachments: [],
        toSendAttachments: [],
        messageToSchedule: '',
        timeZones: [],
        emptyMessage: false,
        unreadMessageClicked: false,
        aiMessageForEditing: null,
        detailForAiMessage: '',
        awayMessageEnabled: false
    } as ConversationComponentState);

    const [
        baseState,
        sendMessage,
        handleTyping,
        loadMessages
    ] = useBaseFacade(conversation, author);

    const handleToggleScheduleDialog = () => {
        setState(state => ({ ...state, isScheduleDialogOpen: !state.isScheduleDialogOpen }));
    }

    const handleOpenScheduleDialog = (message: string, removeAttachmentIds: number[], attachments: UploadedAttachmentModel[]) => {
        setState(state => ({
            ...state,
            isScheduleDialogOpen: true,
            messageToSchedule: message,
            removeAttachments: removeAttachmentIds,
            scheduledAttachmentsForEditing: attachments
        }));
    }

    const handleScheduleMessage = (date: Date, time: Date) => {
        const cb = () => {
            setState(state => ({
                ...state,
                emptyMessage: !state.emptyMessage,
                messageToSchedule: '',
                toSendAttachments: []
            }));
            handleEndEditScheduledMessage();
        }
        const defaultTimeZoneName = 'UTC';

        const model = {
            message: state.messageToSchedule,
            conversationId: conversation.id,
            participantId: conversation.employees.find(i => i.email === authQuery.getEmail() && !i.isDeleted).id,
            timeToSend: moment.utc(new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.getHours(), time.getMinutes())).toDate(),
            timeToSendStr: moment.utc(new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.getHours(), time.getMinutes())).format("YYYY-MM-DD HH:mm:ss"),
            timeZoneName: defaultTimeZoneName,
            attachments: state.toSendAttachments,
        }

        if (Boolean(state.scheduledMessageForEditing)) {
            scheduleMessageService
                .reschedule({
                    ...model,
                    id: state.scheduledMessageForEditing.id,
                    removeAttachments: state.removeAttachments,
                    uploadedAttachments: state.scheduledAttachmentsForEditing
                })
                .subscribe(cb, cb);
        } else {
            scheduleMessageService.schedule(model).subscribe(cb, cb);
        }

        handleToggleScheduleDialog();
    }

    const handleSetMessageUnread = (messageSelected: LastReadMessageUpdateModel) => {
        stateContext.unreadMessageClicked = true;

        // Tell Twilio about the read message index

        if (messageSelected.lastMessageReadIndex === 0) {
            conversation.proxy.setAllMessagesUnread().then(() => {
                conversation.unreadMessages = conversation.proxy.messagesEntity.messagesByIndex.size;
                employeeConversationsStore.updateConversation(conversation);
                conversationsService.selectConversation(null)
            });
        } else {
            conversation.proxy.updateLastReadMessageIndex(messageSelected.lastMessageReadIndex - 1).then(unreadMessageCount => {
                conversation.unreadMessages = unreadMessageCount;
                employeeConversationsStore.updateConversation(conversation);
                conversationsService.selectConversation(null)
            });
        }

        // Tell our API about the read message index - this is for the purpose of keeping this known locally for unread message notifications
        conversationsService.setMessagesUnread(messageSelected).subscribe();

    }

    const handleEndEditScheduledMessage = () => {
        scheduledMessagesStore.endEditing();
        setState(state => ({ ...state, toSendAttachments: [] }))
    }

    const handleSendMessage = (message: any) => {
        if (!employeeConversationsQuery.getConnectionStatus()) return;
        if (conversation.proxy?.status !== ConnectionStatuses.joined) return;
        const attributes = getConversationAttributes(conversation);
        if (state.toSendAttachments.length > 0) {
            state.toSendAttachments.forEach((item, index) => {
                const formData = new FormData();
                formData.append('file', item.file);
                if (state.toSendAttachments.length - 1 === index) {
                    if (message.length) {
                        conversation.proxy.prepareMessage()
                            .setBody(message)
                            .addMedia(formData)
                            .setAttributes(attributes)
                            .build()
                            .send();
                    } else {
                        conversation.proxy.sendMessage(formData, attributes);
                    }
                } else {
                    conversation.proxy.sendMessage(formData, attributes);
                }
                URL.revokeObjectURL(item.url);
            });

            setState(state => ({ ...state, toSendAttachments: [] }));
        } else {
            if (message.length) conversation.proxy.sendMessage(message, attributes);
        }
        
        sendMessage(message);
    };

    const handleDropAttachment = <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => {
        const attachments = acceptedFiles.map(item => ({ file: item, url: URL.createObjectURL(item) }));

        setState(state => ({ ...state, toSendAttachments: [...state.toSendAttachments, ...attachments] }));
    }

    const handleRemoveAttachment = (index: number) => {
        const file = state.toSendAttachments.find((i, itemIndex) => index === itemIndex);
        if (file) {
            URL.revokeObjectURL(file.url);
            setState(state => ({
                ...state,
                toSendAttachments: state.toSendAttachments.filter((i, itemIndex) => index !== itemIndex)
            }));
        }
    }

    const handleUpdateInteraction = (message: string) => {
        loadMessages(conversation.proxy);
        if (message.trim().length > 0) {
            const attributes = getConversationAttributes(conversation);
            conversation.proxy.sendMessage(message, attributes);
        }
    }

    const handleEndEditAiMessage = () => {
        employeeConversationsStore.endAiEditing();
    }

    const handleEditInteraction = (message: string) => {
        const participantExternalVendorId = employeeConversationsQuery.getAuthor().conversationIdentity;
        const acceptInteraction: AcceptInteractionModel = {
            conversationId: state.aiMessageForEditing.conversationId,
            messageId: state.aiMessageForEditing.messageSid,
            referenceId: state.aiMessageForEditing.interaction.ReferenceId,
            participantId: participantExternalVendorId,
            detail: message
        }
        const cb = () => {
            setState(state => ({
                ...state,
                detailForAiMessage: '',
            }));
            handleEndEditAiMessage();
        }
        
        employeeConversationsStore.aiMessageAccept();
        conversationsService.editAiMessage(acceptInteraction).subscribe(() => {
            cb();
            handleUpdateInteraction(message);
        }, cb)
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<ScheduledMessageModel>(scheduledMessagesQuery.editingTarget$, message =>
                setState(state => ({ ...state, scheduledMessageForEditing: message }))
            ),
            onEmit<UploadedAttachmentModel[]>(scheduledMessagesQuery.uploadedAttachments$, attachments =>
                setState(state => ({ ...state, scheduledAttachmentsForEditing: attachments }))
            ),
            onEmit<TimeZoneModel[]>(timezonesQuery.timezones$, timezones => {
                setState(state => ({ ...state, timeZones: timezones }));
            }),
            onEmit<TargetInteractionModel>(employeeConversationsQuery.editingTargetAiMessage$, targetAiMessage => {
                if (Boolean(targetAiMessage)) {
                    setState(state => ({ ...state, detailForAiMessage: targetAiMessage.interaction.Detail }))
                }
                setState(state => ({ ...state, aiMessageForEditing: targetAiMessage }))
            }),
        ];

        if (conversation && conversation.employees) {
            const employeeParticipant = conversation.employees.find(i => i.email === authQuery.getEmail() && !i.isDeleted);
            if (employeeParticipant) {
                scheduleMessageService.get(employeeParticipant.id).subscribe();
            }
        }
        timezonesService.getTimezones().subscribe();

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(() => {
        if (state.scheduledMessageForEditing) {
            setState(state => ({ ...state, toSendAttachments: [] }))
        }
    }, [state.scheduledMessageForEditing]);

    useEffect(useEffectCB, [conversation]);

    return [{ ...state, ...baseState },
        handleSendMessage,
        handleDropAttachment,
        handleRemoveAttachment,
        handleTyping,
        handleOpenScheduleDialog,
        handleToggleScheduleDialog,
        handleScheduleMessage,
        handleEndEditScheduledMessage,
        handleSetMessageUnread,
        handleUpdateInteraction,
        handleEndEditAiMessage,
        handleEditInteraction
    ];
}

export function useFacadeInternal(conversation: ConversationModelBase, author: ConversationAuthorModel): [
    ConversationInternalComponentState & BaseConversationComponentState,
    (message: any) => void,
    () => void,
    <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void,
    (index: number) => void,
    () => void
] {

    const [state, setState] = useState({
        removeAttachments: [],
        toSendAttachments: [],
        awayMessageEnabled: false
    } as ConversationComponentState);

    const history = useHistory();

    const [
        baseState,
        sendMessage,
        handleTyping,
    ] = useBaseFacade(conversation, author);

    const handleSendMessage = (message: any) => {
        if (!employeeConversationsQuery.getConnectionStatus()) return;
        if (conversation.proxy?.status !== ConnectionStatuses.joined) return;
        const attributes = getConversationAttributes(conversation);

        if (state.toSendAttachments.length > 0) {
            state.toSendAttachments.forEach((item, index) => {
                const formData = new FormData();
                formData.append('file', item.file);
                if (state.toSendAttachments.length - 1 === index) {
                    if (message.length) {
                        conversation.proxy.prepareMessage()
                            .setBody(message)
                            .addMedia(formData)
                            .setAttributes(attributes)
                            .build()
                            .send();
                    } else {
                        conversation.proxy.sendMessage(formData, attributes);
                    }
                } else {
                    conversation.proxy.sendMessage(formData, attributes);
                }
                URL.revokeObjectURL(item.url);
            });

            setState(state => ({ ...state, toSendAttachments: [] }));
        } else {
            if (message.length) conversation.proxy.sendMessage(message, attributes);
        }
        sendMessage(message);
    };

    const handleDropAttachment = <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => {
        const attachments = acceptedFiles.map(item => ({ file: item, url: URL.createObjectURL(item) }));

        setState(state => ({ ...state, toSendAttachments: [...state.toSendAttachments, ...attachments] }));
    }

    const handleRemoveAttachment = (index: number) => {
        const file = state.toSendAttachments.find((i, itemIndex) => index === itemIndex);
        if (file) {
            URL.revokeObjectURL(file.url);
            setState(state => ({
                ...state,
                toSendAttachments: state.toSendAttachments.filter((i, itemIndex) => index !== itemIndex)
            }));
        }
    }

    const handleNavigateToSettings = () => {
        navigationService.toMessageSettings(history);
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<UploadedAttachmentModel[]>(scheduledMessagesQuery.uploadedAttachments$, attachments =>
                setState(state => ({ ...state, scheduledAttachmentsForEditing: attachments }))
            ),
            onEmit<MessageSettingsModel>(employeeConversationsQuery.messageSettings$, setting => {
                setState(state => ({ ...state, awayMessageEnabled: setting.awayMessageEnabled }));
            }),
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }


    useEffect(useEffectCB, [conversation]);

    return [{ ...state, ...baseState },
        handleSendMessage,
        handleTyping,
        handleDropAttachment,
        handleRemoveAttachment,
        handleNavigateToSettings
    ];
}