import { Button, Theme, withStyles } from "@material-ui/core";
import { colors } from "../../constants/colors";

export const SecondaryButton = withStyles((theme: Theme) => ({
    root: {
        color: colors.main,
        backgroundColor: 'transparent',
        border: `1px solid ${colors.main}`,
        textTransform: 'none',
        borderRadius: 2,
        '&:hover': {
            backgroundColor: `${colors.main}11`,
        },
        '&:disabled': {
            color: colors.gray2,
            backgroundColor: colors.disabled,
            border: `1px solid ${colors.main}44`,
        },
    },
}))(Button);
