import { Box } from '@material-ui/core';
import React from 'react';
import { PracticeProfileModel } from '../../account/models/practiceProfile.model';
import { HealthRecommendationTextComponent } from "../components/healthRecommendationTestComponent/HealthRecommendationTextComponent";
import {
    HealthReportSections,
    recommendationRowsCapacity,
    RecommendationTypes
} from "../models/healthReport.models";
import { HealthReportBasePage } from "./HealthReportBasePage";

export interface HealthReportRecommendationPageProps {
    section: HealthReportSections;
    pageNumber: number;
    title: string;
    recommendation: string;
    practice: PracticeProfileModel;
}

export const HealthReportRecommendationPage: React.FC<HealthReportRecommendationPageProps> = (props: HealthReportRecommendationPageProps) => {
    const {
        section,
        pageNumber,
        title,
        recommendation,
        practice
    } = props;

    return (
        <HealthReportBasePage section={section} pageNumber={pageNumber} practice={practice}>
            <Box px={15} pt={5}>
                <Box>
                    <span className="report-title">{title}</span>
                </Box>
                <Box mt={1}>
                    <HealthRecommendationTextComponent
                        rows={recommendationRowsCapacity[RecommendationTypes.Extra]}
                        text={recommendation} />
                </Box>
            </Box>
        </HealthReportBasePage>
    )
}
