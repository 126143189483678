import {Store, StoreConfig} from '@datorama/akita';
import {newPatientAllergyModel, PatientAllergyModel, RxntAllergyModel} from '../../models/patientAllergiesModel';

/**
 * Represents coaches state
 */
export interface PatientAllergiesState {
    patientAllergies: PatientAllergyModel[];
    rxntAllergies: RxntAllergyModel[];
    editModel: PatientAllergyModel;
}

/**
 * Creates initial state
 */
export function createInitialState(): PatientAllergiesState {
    return {
        patientAllergies: [],
        rxntAllergies: [],
        editModel: newPatientAllergyModel()
    };
}

/**
 * Provides coaches states management
 */
@StoreConfig({name: 'patientAllergies', resettable: true })
export class PatientAllergiesStore extends Store<PatientAllergiesState> {
    constructor() {
        super(createInitialState());
    }

    public create(model: PatientAllergyModel) {
        this.update({patientAllergies: [...this.getValue().patientAllergies, model]});
    }

    public edit(model: PatientAllergyModel) {
        const patientAllergies = this.getValue().patientAllergies.map(p => p.id === model.id ? model : p);;
        this.update({patientAllergies: patientAllergies});
    }

    public delete(medicationId: number) {
        const allergies = this.getValue().patientAllergies.filter(x => x.id !== medicationId);
        this.update({patientAllergies: allergies});
    }

    public setEditModel(model?: PatientAllergyModel){
        this.update({editModel: Object.assign({}, model ?? newPatientAllergyModel())});
    }
}

export const patientAllergiesStore = new PatientAllergiesStore();