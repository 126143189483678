import { Box, Table, TableBody, TableContainer, TableHead, TableRow, useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { ExerciseReportTableCell } from "../components/exerciseReportTable/ExerciseReportTableCell";
import { HealthReportSections, HealthReportMode } from "../models/healthReport.models";
import { HealthReportBasePage, HealthReportMobileBasePage } from "./HealthReportBasePage";
import { HealthReportPageProps } from "./healthReportPageProps";
import { useStyles } from './healthReport.styles';

export const HealthReportExerciseAndRecovery5Page: React.FC<HealthReportPageProps> = (props: HealthReportPageProps) => {
    const {
        pageNumber,
        practice
    } = props;
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const coreData1 = [
        {
            exercise: "Prone Plank Progression",
            reps: "reps",
            program1: 2,
            variation: "",
            program2: 2,
            log: "",
            notes: "Progression: Elbows 15secs, 5 Rt arm Ext., 5 Lf arm Ext., 5 Rt leg Ext., 5 Lf leg Ext., 5 Rt/LF opposites, 5 Lf/Rt opposites, Elbows 15secs"
        },
        {
            exercise: "Side Plank Progression",
            reps: "reps",
            program1: 2,
            variation: "",
            program2: 2,
            log: "",
            notes: "Progression: Elbow 15secs, Lift upper leg 15secs, Adduction w/ 6 lower leg knee drives; Repeat on Rt and then Lf side"
        },
        {
            exercise: "Supermans",
            reps: "15 reps",
            program1: 10,
            variation: "",
            program2: 10,
            log: "",
            notes: "5 secs holds of Y, T, and W progression"
        },
        {
            exercise: "Deadbug",
            reps: "30 reps alternating",
            program1: 20,
            variation: "",
            program2: 20,
            log: "",
            notes: "total reps listed, divide evenly between sides"
        },
        {
            exercise: "Hip Bridges",
            reps: "15reps",
            program1: 15,
            variation: "1 leg lift",
            program2: 15,
            log: "",
            notes: "Push-up and hold for 5 count"
        },
        {
            exercise: "Hip Circles (hydrants)",
            reps: "reps",
            program1: 20,
            variation: "",
            program2: 20,
            log: "",
            notes: "total reps listed, divide evenly between sides"
        },
        {
            exercise: "Bird-Dogs",
            reps: "reps",
            program1: 10,
            variation: "",
            program2: 10,
            log: "",
            notes: "total reps listed, divide evenly between sides"
        },
        {
            exercise: "Hands overhead Body Weight Squat",
            reps: "reps",
            program1: 12,
            variation: "",
            program2: 12,
            log: "",
            notes: "Hands extended overhead (can hold stick in hands if helps) Slow down, hold, slow up, hold (3,2,3,2)"
        },
    ]

    const coreData2 = [
        {
            exercise: "Lunge - Reverse and twist to outside leg",
            reps: " 12 reps",
            program1: 2,
            variation: "Add DB or OH hold to make harder",
            program2: 2,
            log: "",
            notes: "Progression: Elbows 15secs, 5 Rt arm Ext., 5 Lf arm Ext., 5 Rt leg Ext., 5 Lf leg Ext., 5 Rt/LF opposites, 5 Lf/Rt opposites, Elbows 15secs"
        },
        {
            exercise: "Side Plank",
            reps: "30 sec/ side",
            program1: 2,
            variation: "Leg in hip abduction/ 15 sec",
            program2: 2,
            log: "",
            notes: "Progression: Elbow 15secs, Lift upper leg 15secs, Adduction w/ 6 lower leg knee drives; Repeat on Rt and then Lf side"
        },
        {
            exercise: "Supermans",
            reps: "reps",
            program1: 10,
            variation: "",
            program2: 10,
            log: "",
            notes: "5 secs holds of Y, T, and W progression"
        },
        {
            exercise: "Band pull apart",
            reps: "reps",
            program1: 15,
            variation: "",
            program2: 20,
            log: "",
            notes: "total reps listed, divide evenly between sides"
        },
        {
            exercise: "Band good morning",
            reps: "reps",
            program1: 15,
            variation: "",
            program2: 15,
            log: "",
            notes: "Light band, focus on form"
        },
        {
            exercise: "Push up/ plank hold",
            reps: "10 reps",
            program1: 2,
            variation: "",
            program2: 20,
            log: "",
            notes: "Hold 5 sec at low plank position"
        },
        {
            exercise: "Scap retraction pullup hold",
            reps: "6-10 reps",
            program1: 10,
            variation: "",
            program2: 10,
            log: "",
            notes: "Hold count of 3 at scap retraction "
        },
    ]

    return (
        props.mode !== HealthReportMode.PrintMode && isSmallScreen ? (
            <HealthReportMobileBasePage section={HealthReportSections.ExerciseAndRecovery} pageNumber={pageNumber} practice={practice}>
                <Box>
                    <Box px={2}>
                        <Box>
                            <span className={classes.exerciseReportSubTitle}>Mobility/Core #1</span>
                        </Box>
                        <Box mt={0.5}>
                            <span className={classes.exerciseReportSubTitle}>Focus:body weight resistance training</span>
                        </Box>
                    </Box>
                    <Box mt="20px" width="100%" style={{ borderTop: "2px solid #A9A9A9",  borderBottom: "2px solid #A9A9A9" }}>
                        {
                            coreData1.map((data, i) => {
                                return <Box key={`${data.exercise}-${i}`} style={i !== 0 ? {borderTop: "1px solid #A9A9A9"} : {}}>
                                    <Box width={1} px={2} pt={2} display="flex" className="size-0_875 normal-line-height" style={i % 2 === 1 ? {backgroundColor:"#f3f7fa"} : {}}>
                                        <Box width={0.45} className="text-medium">Exercise</Box>
                                        <Box width={0.55} className="text-light-medium">{data.exercise}</Box>
                                    </Box>
                                    <Box width={1} px={2} pt="10px" display="flex" className="size-0_875 normal-line-height" style={i % 2 === 1 ? {backgroundColor:"#f3f7fa"} : {}}>
                                        <Box width={0.45} className="text-medium">Reps</Box>
                                        <Box width={0.55} className="text-light-medium">{data.reps}</Box>
                                    </Box>
                                    <Box width={1} px={2} pt="10px" display="flex" className="size-0_875 normal-line-height" style={i % 2 === 1 ? {backgroundColor:"#f3f7fa"} : {}}>
                                        <Box width={0.45} className="text-medium">Set #1</Box>
                                        <Box width={0.55} className="text-light-medium">
                                            <Box width={1} display="flex">
                                                <Box pr={1}>Program</Box>
                                                <Box>{data.program1}</Box>
                                            </Box>
                                            <Box width={1} display="flex" mt="10px">
                                                <Box pr={1}>Variation</Box>
                                                <Box>{data.variation}</Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box width={1} px={2} pt="10px" display="flex" className="size-0_875 normal-line-height" style={i % 2 === 1 ? {backgroundColor:"#f3f7fa"} : {}}>
                                        <Box width={0.45} className="text-medium">Set #2</Box>
                                        <Box width={0.55} className="text-light-medium">
                                            <Box width={1} display="flex">
                                                <Box pr={1}>Program</Box>
                                                <Box>{data.program2}</Box>
                                            </Box>
                                            <Box width={1} display="flex" mt="10px">
                                                <Box pr={1}>Log</Box>
                                                <Box>{data.log}</Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box width={1} px={2} pt="10px" pb={2} display="flex" className="size-0_875 normal-line-height" style={i % 2 === 1 ? {backgroundColor:"#f3f7fa"} : {}}>
                                        <Box width={0.45} className="text-medium">Notes</Box>
                                        <Box width={0.55} className="text-light-medium">{data.notes}</Box>
                                    </Box>
                                </Box>
                            })
                        }
                    </Box>
                </Box>
                <Box mt="30px">
                    <Box px={2}>
                        <Box>
                            <span className={classes.exerciseReportSubTitle}>Mobility/Core #2</span>
                        </Box>
                        <Box mt={0.5}>
                            <span className={classes.exerciseReportSubTitle}>Focus:body weight resistance training</span>
                        </Box>
                    </Box>
                    <Box mt="20px" width="100%" style={{ borderTop: "2px solid #A9A9A9",  borderBottom: "2px solid #A9A9A9" }}>
                        {
                            coreData2.map((data, i) => {
                                return <Box key={`${data.exercise}-${i}`} style={i !== 0 ? {borderTop: "1px solid #A9A9A9"} : {}}>
                                    <Box width={1} px={2} pt={2} display="flex" className="size-0_875 normal-line-height" style={i % 2 === 1 ? {backgroundColor:"#f3f7fa"} : {}}>
                                        <Box width={0.45} className="text-medium">Exercise</Box>
                                        <Box width={0.55} className="text-light-medium">{data.exercise}</Box>
                                    </Box>
                                    <Box width={1} px={2} pt="10px" display="flex" className="size-0_875 normal-line-height" style={i % 2 === 1 ? {backgroundColor:"#f3f7fa"} : {}}>
                                        <Box width={0.45} className="text-medium">Reps</Box>
                                        <Box width={0.55} className="text-light-medium">{data.reps}</Box>
                                    </Box>
                                    <Box width={1} px={2} pt="10px" display="flex" className="size-0_875 normal-line-height" style={i % 2 === 1 ? {backgroundColor:"#f3f7fa"} : {}}>
                                        <Box width={0.45} className="text-medium">Set #1</Box>
                                        <Box width={0.55} className="text-light-medium">
                                            <Box width={1} display="flex">
                                                <Box pr={1}>Program</Box>
                                                <Box>{data.program1}</Box>
                                            </Box>
                                            <Box width={1} display="flex" mt="10px">
                                                <Box pr={1}>Variation</Box>
                                                <Box>{data.variation}</Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box width={1} px={2} pt="10px" display="flex" className="size-0_875 normal-line-height" style={i % 2 === 1 ? {backgroundColor:"#f3f7fa"} : {}}>
                                        <Box width={0.45} className="text-medium">Set #2</Box>
                                        <Box width={0.55} className="text-light-medium">
                                            <Box width={1} display="flex">
                                                <Box pr={1}>Program</Box>
                                                <Box>{data.program2}</Box>
                                            </Box>
                                            <Box width={1} display="flex" mt="10px">
                                                <Box pr={1}>Log</Box>
                                                <Box>{data.log}</Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box width={1} px={2} pt="10px" pb={2} display="flex" className="size-0_875 normal-line-height" style={i % 2 === 1 ? {backgroundColor:"#f3f7fa"} : {}}>
                                        <Box width={0.45} className="text-medium">Notes</Box>
                                        <Box width={0.55} className="text-light-medium">{data.notes}</Box>
                                    </Box>
                                </Box>
                            })
                        }
                    </Box>
                </Box>
            </HealthReportMobileBasePage>
        ) : (
            <HealthReportBasePage section={HealthReportSections.ExerciseAndRecovery} pageNumber={pageNumber} practice={practice}>
                <Box px={10} pt={5}>
                    <Box>
                        <Box display="flex" justifyContent="space-between">
                            <Box>
                                <span className={classes.exerciseReportSubTitle}>Mobility/Core #1</span>
                            </Box>
                            <Box>
                                <span className={classes.exerciseReportSubTitle}>Focus:body weight resistance training</span>
                            </Box>
                        </Box>
                        <Box mt={1}>
                            <TableContainer>
                                <Table size="small" style={{ border: "2px solid #A9A9A9" }}>
                                    <TableHead>
                                        <TableRow>
                                            <ExerciseReportTableCell align="center" rowSpan={2}>Exercise</ExerciseReportTableCell>
                                            <ExerciseReportTableCell align="center"></ExerciseReportTableCell>
                                            <ExerciseReportTableCell align="center" colSpan={2}>Set #1</ExerciseReportTableCell>
                                            <ExerciseReportTableCell align="center" colSpan={2}>Set #2</ExerciseReportTableCell>
                                            <ExerciseReportTableCell align="center" rowSpan={2}>Notes</ExerciseReportTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <ExerciseReportTableCell align="center"></ExerciseReportTableCell>
                                            <ExerciseReportTableCell align="center">Program</ExerciseReportTableCell>
                                            <ExerciseReportTableCell align="center">Variation</ExerciseReportTableCell>
                                            <ExerciseReportTableCell align="center">Program</ExerciseReportTableCell>
                                            <ExerciseReportTableCell align="center">Log</ExerciseReportTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            coreData1.map((data, i) =>
                                                <TableRow key={i}>
                                                    <ExerciseReportTableCell style={{ width: '15%' }} align="center">{data.exercise}</ExerciseReportTableCell>
                                                    <ExerciseReportTableCell style={{ width: '10%' }} align="center">{data.reps}</ExerciseReportTableCell>
                                                    <ExerciseReportTableCell style={{ width: '10%' }} align="center">{data.program1}</ExerciseReportTableCell>
                                                    <ExerciseReportTableCell style={{ width: '10%' }} align="center">{data.variation}</ExerciseReportTableCell>
                                                    <ExerciseReportTableCell style={{ width: '10%' }} align="center">{data.program2}</ExerciseReportTableCell>
                                                    <ExerciseReportTableCell style={{ width: '10%' }} align="center">{data.log}</ExerciseReportTableCell>
                                                    <ExerciseReportTableCell style={{ width: '35%' }} align="center">{data.notes}</ExerciseReportTableCell>
                                                </TableRow>
                                            )
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                    <Box mt={10}>
                        <Box display="flex" justifyContent="space-between">
                            <Box>
                                <span className={classes.exerciseReportSubTitle}>Mobility/Core #2</span>
                            </Box>
                            <Box>
                                <span className={classes.exerciseReportSubTitle}>Focus:body weight resistance training</span>
                            </Box>
                        </Box>
                        <Box mt={1}>
                            <TableContainer>
                                <Table size="small" style={{ border: "2px solid #A9A9A9" }}>
                                    <TableHead>
                                        <TableRow>
                                            <ExerciseReportTableCell align="center" rowSpan={2}>Exercise</ExerciseReportTableCell>
                                            <ExerciseReportTableCell align="center"></ExerciseReportTableCell>
                                            <ExerciseReportTableCell align="center" colSpan={2}>Set #1</ExerciseReportTableCell>
                                            <ExerciseReportTableCell align="center" colSpan={2}>Set #2</ExerciseReportTableCell>
                                            <ExerciseReportTableCell align="center" rowSpan={2}>Notes</ExerciseReportTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <ExerciseReportTableCell align="center"></ExerciseReportTableCell>
                                            <ExerciseReportTableCell align="center">Program</ExerciseReportTableCell>
                                            <ExerciseReportTableCell align="center">Variation</ExerciseReportTableCell>
                                            <ExerciseReportTableCell align="center">Program</ExerciseReportTableCell>
                                            <ExerciseReportTableCell align="center">Log</ExerciseReportTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            coreData2.map((data, i) =>
                                                <TableRow key={i}>
                                                    <ExerciseReportTableCell style={{ width: '15%' }} align="center">{data.exercise}</ExerciseReportTableCell>
                                                    <ExerciseReportTableCell style={{ width: '10%' }} align="center">{data.reps}</ExerciseReportTableCell>
                                                    <ExerciseReportTableCell style={{ width: '10%' }} align="center">{data.program1}</ExerciseReportTableCell>
                                                    <ExerciseReportTableCell style={{ width: '10%' }} align="center">{data.variation}</ExerciseReportTableCell>
                                                    <ExerciseReportTableCell style={{ width: '10%' }} align="center">{data.program2}</ExerciseReportTableCell>
                                                    <ExerciseReportTableCell style={{ width: '10%' }} align="center">{data.log}</ExerciseReportTableCell>
                                                    <ExerciseReportTableCell style={{ width: '35%' }} align="center">{data.notes}</ExerciseReportTableCell>
                                                </TableRow>
                                            )
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>
            </HealthReportBasePage>
        )
    )
}
