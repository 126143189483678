import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        photoContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
        },
        photo: {
            height: '72px',
            width: '72px',
            marginRight: '8px',
            marginTop: '8px',
            marginBottom: '8px',
        },
        photoBorder: {
            borderWidth: '1px',
            borderColor: 'grey',
            borderStyle: 'groove'
        },
        photoItem: {
            height: '72px',
            width: '72px',
        },
        photoButton: {
            margin: '8px'
        },
        photoUploadButton: {
            height: '34px',
            width: '83px'
        }
    }),
);
