import {colors} from "../constants/colors";

export const shortcutView = (content: string) => {
    return content.split(' ').map((el: string) => {
        if (el !== ' ' && linkValidationRule(el)) {
            return (
                `<a href=${el} style="color:${colors.main}; text-decoration: underline; padding-right: 4px;" target="_blank">Link</a>`
            );
        } else {
            return el;
        }
    }).join(" ")
}

const linkValidationRule = (value: string) => {
    if (!value) {
        return
    }

    return new RegExp('^(http|ftp|https):\\/\\/([\\w_-]+(?:(?:\\.[\\w_-]+)+))([\\w.,@?^=%&:\\/~+#-]*[\\w@?^=%&\\/~+#-])').test(value);
}
