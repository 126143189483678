import { Box } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import { AuthenticatedLayoutComponent } from "../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent";
import { TitlesService } from "../../../../constants/title.constants";
import { MenuItemTypes } from "../../../../models/menu.models";
import { navigationService } from "../../../../services/navigation.service";
import { PatientTitleComponent } from "../../../patients/components/PatientTitleComponent/PatientTitleComponent";
import { CreateInitialConsultComponent } from "../../components/createInitialConsult/CreateInitialConsultComponent";
import { NoteModel } from "../../models/notes.models";

export const CreateInitialConsultNotePage: React.FC = (props: any) => {
    const patientId = Number(props.match.params.patientId);
    const appointmentId = props?.location?.state?.appointmentId as number | null;
    const note = props?.location?.state?.note as NoteModel | null;
    const fromDraftNotePage = props?.location?.state?.fromDraftNotePage as boolean | false;
    const history = useHistory();

    return (
        <AuthenticatedLayoutComponent
            title={TitlesService.appointmentsTitle}
            selectedMenuItem={MenuItemTypes.Appointments}
        >
            <Box p='30px'>
                
                { 
                    fromDraftNotePage && 
                    <PatientTitleComponent patientId = {patientId} />
                }

                <CreateInitialConsultComponent
                    note={note}
                    patientId={patientId}
                    appointmentId={appointmentId}
                    appointmentConfiguration={null}
                    handleGoBack={fromDraftNotePage ? () => navigationService.goBack(history) : () => navigationService.toCoachAppointments(history)}
                    goBackTitle={"Back to schedule"}
                />
            </Box>
            
        </AuthenticatedLayoutComponent>
    )
}
