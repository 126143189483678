import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../../../../common/constants/colors';
import { appointmentColors } from '../../../constants/appointmentColors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            height: '100%',
            borderRadius: '2px',
            paddingRight: 10,
            paddingLeft: 10
        },
        info: {
            height: '100%',
            minHeight: 52.5,
            fontSize: 12,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        wc: {
            color: appointmentColors.purple,
            backgroundColor: appointmentColors.purpleLight,
            borderLeft: `2px solid ${appointmentColors.purple}`,
            '&:hover': {
                backgroundColor: appointmentColors.purpleMid
            },
            '& button': {
                textTransform: 'none',
                color: colors.white,
                backgroundColor: appointmentColors.purple,
                '&:hover': {
                    backgroundColor: appointmentColors.purple
                },
            }
        },
        fmc: {
            color: appointmentColors.blue,
            backgroundColor: appointmentColors.blueLight,
            borderLeft: `2px solid ${appointmentColors.blue}`,
            '&:hover': {
                backgroundColor: appointmentColors.blueMid,
            },
            '& button': {
                textTransform: 'none',
                color: colors.white,
                backgroundColor: appointmentColors.blue,
                '&:hover': {
                    backgroundColor: appointmentColors.blue
                },
            }
        },
        icc: {
            color: appointmentColors.green,
            backgroundColor: appointmentColors.greenLight,
            borderLeft: `2px solid ${appointmentColors.green}`,
            '&:hover': {
                backgroundColor: appointmentColors.greenMid
            },
            '& button': {
                textTransform: 'none',
                color: colors.white,
                backgroundColor: appointmentColors.green,
                '&:hover': {
                    backgroundColor: appointmentColors.green
                },
            }
        },
        fcc: {
            color: appointmentColors.orange,
            backgroundColor: appointmentColors.orangeLight,
            borderLeft: `2px solid ${appointmentColors.orange}`,
            '&:hover': {
                backgroundColor: appointmentColors.orandeMid
            },
            '& button': {
                textTransform: 'none',
                color: colors.white,
                backgroundColor: appointmentColors.orange,
                '&:hover': {
                    backgroundColor: appointmentColors.orange
                },
            }
        },
        imc: {
            color: appointmentColors.strongBlue,
            backgroundColor: appointmentColors.strongBlueLight,
            borderLeft: `2px solid ${appointmentColors.strongBlue}`,
            '&:hover': {
                backgroundColor: appointmentColors.strongBlueMid
            },
            '& button': {
                textTransform: 'none',
                color: colors.white,
                backgroundColor: appointmentColors.strongBlue,
                '&:hover': {
                    backgroundColor: appointmentColors.strongBlue
                },
            }
        },
        other: {
            color: appointmentColors.red,
            backgroundColor: appointmentColors.redLight,
            borderLeft: `2px solid ${appointmentColors.red}`,
            '&:hover': {
                backgroundColor: appointmentColors.redMid
            },
            '& button': {
                textTransform: 'none',
                color: colors.white,
                backgroundColor: appointmentColors.red,
                '&:hover': {
                    backgroundColor: appointmentColors.red
                },
            }
        },
        userName: {
            fontWeight: 500,
            wordBreak: 'break-all',

        },
        recordingIcon: {
            marginLeft: 4,
            marginRight: 4
        }
    })
);