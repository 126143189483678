import { Box, TextField, FormControl, FormHelperText, InputAdornment, OutlinedInput, IconButton } from '@material-ui/core';
import React from 'react';
import { IErrorState } from "../../../common/validation/error-state";
import commonUseStyles from '../../../common/styles/common.styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useStyles } from "./loginInfoComponent.styles";

interface LoginInfoComponentProps extends IErrorState {
    handleChanges: (field: string, value: any) => void;
}

export const LoginInfoComponent: React.FC<LoginInfoComponentProps> = (props: LoginInfoComponentProps) => {
    const { handleChanges, errors } = props;
    const commonClasses = commonUseStyles();
    const classes = useStyles();

    const [state, setState] = React.useState({
        showPassword: false,
        showConfirmPassword: false,
    });

    const handleClickShowPassword = () => {
        setState({ ...state, showPassword: !state.showPassword });
    };

    const handleClickShowConfirmPassword = () => {
        setState({ ...state, showConfirmPassword: !state.showConfirmPassword });
    };

    return (
        <form autoComplete="off">
            <Box mt={"30px"} width={1}>
                <Box className={commonClasses.subtitle}>Email</Box>
                <TextField
                    fullWidth
                    size="small"
                    required
                    id="email"
                    InputProps={{ className: 'input', classes }}
                    variant="outlined"
                    helperText={errors['email']}
                    error={!!errors['email']}
                    onChange={(v) => handleChanges(v.target.id, v.target.value)}
                />
            </Box>
            <Box mt={2} width={1}>
                <FormControl
                    variant="outlined"
                    color='primary'
                    required
                    error={!!errors[`password`]}
                    size="small"
                    fullWidth
                    classes={{ root: classes.root }}
                >
                    <Box className={commonClasses.subtitle}>Password</Box>
                    <OutlinedInput
                        id="password"
                        type={state.showPassword ? 'text' : 'password'}
                        error={!!errors['password']}
                        onChange={(v) => handleChanges(v.target.id, v.target.value)}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                >
                                    {state.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <FormHelperText>{errors[`password`]}</FormHelperText>
                </FormControl>
            </Box>
            <Box mt={2} width={1}>
                <FormControl
                    variant="outlined"
                    color='primary'
                    required
                    error={!!errors[`confirmPassword`]}
                    size="small"
                    fullWidth
                    classes={{ root: classes.root }}
                >
                    <Box className={commonClasses.subtitle}>Confirm Password</Box>
                    <OutlinedInput
                        id="confirmPassword"
                        type={state.showConfirmPassword ? 'text' : 'password'}
                        error={!!errors['confirmPassword']}
                        onChange={(v) => handleChanges(v.target.id, v.target.value)}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowConfirmPassword}
                                >
                                    {state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <FormHelperText>{errors[`confirmPassword`]}</FormHelperText>
                </FormControl>
            </Box>
        </form>
    )
}
