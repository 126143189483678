import React from 'react';
import { Box } from '@material-ui/core';
import { PaymentPlanModel } from '../../models/paymentPlan.models';
import { useStyles } from './reselectPaymentPlanCardComponent.styles';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from 'clsx';
import { PaymentBenefitsComponent } from '../paymentBenefitsComponent/PaymentBenefitsComponent';

interface ReselectPaymentPlanCardComponentProps {
    plan: PaymentPlanModel;
    handlePlanSelect: Function
}

export const ReselectPaymentPlanCardComponent: React.FC<ReselectPaymentPlanCardComponentProps> = (props: ReselectPaymentPlanCardComponentProps) => {
    const {
        plan,
        handlePlanSelect,
    } = props;

    const commonClasses = commonUseStyles();
    const classes = useStyles();

    const renderSelectButton = (): JSX.Element => {
        return (
            <WildHealthButton
                id="select-payment-plan"
                size="large"
                onClick={() => handlePlanSelect(plan)}
            >
                Choose  plan
            </WildHealthButton>
        );
    }

    const renderHeaderSection = () => {
        return (
            <Box mb={5}>
                <Box>
                    <span className={classes.planName}>{plan.displayName}</span>
                </Box>
                <Box mt={4}>
                    <Box display="flex">
                        <Box ml={0.5}>
                            <h1 className={clsx(commonClasses.primaryFont, commonClasses.textMedium, classes.startPrice, classes.moneyStyle)}>${plan.startPrice}</h1>
                        </Box>
                        <Box mt={3.5}>
                            <span className={clsx(commonClasses.size18, commonClasses.textRegular, commonClasses.colorDarkGray)}>
                                / per month
                            </span>
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    }

    return (
        <Box className={clsx(classes.planCard, commonClasses.size14)}>
            {
                renderHeaderSection()
            }
            {
                renderSelectButton()
            }
            <Box mt={3} className={classes.benefitsContainer}>
                {plan.inclusions && plan.inclusions.length ? <>
                    <Box className={clsx(commonClasses.textMedium, commonClasses.size16)}>Includes:</Box>
                    <PaymentBenefitsComponent benefits={plan.inclusions} />
                </>
                : <></>}
                {plan.benefits && plan.benefits.length ? <>
                    <Box mt={1} className={clsx(commonClasses.textMedium, commonClasses.size16)}>Key Benefits:</Box>
                    <PaymentBenefitsComponent benefits={plan.benefits} />
                </>
                : <></>}
            </Box>
        </Box>
    )
}


