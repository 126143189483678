import React from "react";
import ReactDOM from "react-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import {
    PatientReportModel
} from "../models/healthReport.models";
import { AddOnReportPrintComponent, AddOnReportPrintComponentProps } from "../components/addOnReportsComponent/AddOnReportPrintComponent";
import { toCurrentTimeZone } from "../../timezones/helpers/timezone";


export class AddOnReportPdfServices {
    private _snapshotFormat: string = "JPEG";

    public async generate(report: PatientReportModel, patientId: number | null) {
        const rootContainer = AddOnReportPdfServices._createRootContainer();
        const mainPageProps: AddOnReportPrintComponentProps = {
            patientId,
            patientReport: report
        };

        const pdf = new jsPDF("p", "mm", "letter");

        await this._generatePage(pdf, AddOnReportPrintComponent, mainPageProps, rootContainer);

        pdf.save(`WH_AddOnReport_${report.reportTemplateName}_${report.patientId}_${moment(toCurrentTimeZone(report.createdAt)).format("YYYYMMDD")}.pdf`);

        AddOnReportPdfServices._dispose(rootContainer);
    }

    private async _generatePage(pdf: jsPDF, page: Function, props: AddOnReportPrintComponentProps, container: HTMLElement) {
        const snapshot = await this._getSnapshot<AddOnReportPrintComponentProps>(page, props, container);
        const [width, height] = AddOnReportPdfServices._getSize(snapshot, pdf);
        await pdf.addImage(AddOnReportPdfServices._toImage(snapshot), this._snapshotFormat, 0, 0, width, height);
    }

    private async _getSnapshot<TProps>(page: Function, props: TProps, rootContainer: HTMLElement): Promise<HTMLCanvasElement> {
        const element = await this._renderPage(page, props, rootContainer);
        return await html2canvas(element);
    }

    private static _toImage(snapshot: HTMLCanvasElement): string {
        return snapshot.toDataURL("image/jpeg", 1);
    }

    private static _getSize(snapshot: HTMLCanvasElement, pdf: any): [number, number] {
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const imageWidth = snapshot.width;
        const imageHeight = snapshot.height;
        const ratio = imageWidth / imageHeight >= pageWidth / pageHeight ? pageWidth / imageWidth : pageHeight / imageHeight;

        return [imageWidth * ratio, imageHeight * ratio];
    }

    private async _renderPage<TProps>(page: Function, props: TProps, rootContainer: HTMLElement): Promise<HTMLElement> {
        return new Promise(function (resolve) {
            // @ts-ignore
            const element = React.createElement(page, props);
            //const container = document.createElement("div");
            ReactDOM.render(element, rootContainer.children[0]);
            //rootContainer.appendChild(container);
            setTimeout(() => {
                // @ts-ignore
                resolve(rootContainer.children[0]);
            }, 1)
        })
    }

    private static _dispose(page: HTMLElement): void {
        page.parentNode.removeChild(page);
    }

    private static _createRootContainer(): HTMLElement {
        const container = document.createElement("div");

        container.style.position = "absolute";
        container.style.top = "-16384px";
        container.style.left = "500px";
        container.style.background = "#FFFFFF";

        container.appendChild(document.createElement("div"));

        document.getElementsByTagName('BODY')[0].append(container);

        return container;
    }
}

export const addOnReportPdfServices = new AddOnReportPdfServices();