import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  IconButton,
  CircularProgress,
  Dialog,
  DialogContent
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import TrashIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit'
import { useFacade } from './manageCommonMdmsComponent.hooks';
import { useStyles } from "./manageCommonMdmsComponent.styles";
import { WildHealthContainer } from '../../../common/components/wildHealthContainer/WildHealthContainer';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { FeatureComponent } from "../../../common/components/featureFlags/FeatureComponent";
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';

export const ManageCommonMdmsComponent: React.FC = () => {
  const [
    {
      isLoading,
      isSubmitting,
      isOpen,
      commonMdms,
      createCommonMdm,
      removingMdmId,
      errors,
      sortingColumns,
      sortingSource,
    },
    handleChanges,
    handleSorting,
    handleCreateMdmPlan,
    handleDeleteMdmPlan,
    handleGoToEdit,
    handleToggleNewMdm
  ] = useFacade();

  const classes = useStyles();

  const renderTable = () => {
    if (isLoading) {
      return <WildHealthLinearProgress />
    }

    if (!isLoading && (!commonMdms || !commonMdms.length)) {
      return <WildHealthPlaceHolder message="No available common MDM plans found" />
    }

    return (
      <>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {
                    sortingColumns.map((item, index) =>
                        <WildHealthTableCell key={index}>
                            {item.source
                                ? <TableSortLabel
                                    id={`sorting-mdms-${item.title}`}
                                    active={sortingSource === item.source}
                                    direction={sortingSource === item.source ? item.direction : 'asc'}
                                    onClick={() => handleSorting(item.source, item.direction)}
                                >
                                    {item.title}
                                </TableSortLabel>
                                : item.title
                            }
                        </WildHealthTableCell>
                    )
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {commonMdms.map(mdmPlan => (
                <WildHealthTableRow key={mdmPlan.id}>
                  <WildHealthTableCell align="left" style={{ width: '20%' }}>
                      <Box className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-black">
                          {mdmPlan.name}
                      </Box>
                  </WildHealthTableCell>
                  <WildHealthTableCell align="left" style={{ width: '65%' }}>
                      <Box className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-black">
                          {mdmPlan.description}
                      </Box>
                  </WildHealthTableCell>
                  <WildHealthTableCell style={{ width: '15%' }}>
                    <IconButton
                      id="edit-mdm-btn"
                      aria-describedby={mdmPlan.name}
                      disabled={isSubmitting}
                      onClick={() => handleGoToEdit(mdmPlan.id)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      id="remove-mdm-btn"
                      aria-describedby={mdmPlan.name}
                      disabled={isSubmitting}
                      onClick={() => handleDeleteMdmPlan(mdmPlan.id)}
                    >
                      {isSubmitting && removingMdmId === mdmPlan.id ? <CircularProgress size={24} className="wh-tw-text-primaryV" /> : <TrashIcon />}
                    </IconButton>
                  </WildHealthTableCell>
                </WildHealthTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }

  return (
    <Box py={2} px={10} width={1}>
      <Box my={2} display="flex" alignItems="center" justifyContent="space-between">
          <Box>
              <FeatureComponent featureFlag={FeatureFlag.Navigation}>
                  <Box className="wh-tw-font-semibold wh-tw-text-2xl wh-tw-black">{PagesNamesService.commonMdms}</Box>
              </FeatureComponent>
          </Box>
          <Box>
              <WildHealthButton
                  id="create-common-mdm-button"
                  disabled={isLoading}
                  loading={isSubmitting}
                  onClick={handleToggleNewMdm}
                  fullWidth
                  style={{ height: 48 }}
              >
                  <Box className="wh-tw-text-base wh-tw-font-medium">New Common MDM Plan</Box>
              </WildHealthButton>
          </Box>
      </Box>

      <WildHealthContainer margin={0} minHeight={'100%'}>
        {renderTable()}
      </WildHealthContainer>
      <Dialog
          onClose={handleToggleNewMdm}
          className={classes.root}
          open={isOpen}
      >
          <DialogContent className={classes.dialogContent}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Box>
                      <span className="wh-tw-text-2xl wh-tw-black wh-tw-font-medium">New Common MDM Plan</span>
                  </Box>
                  <Box display="flex" alignItems="center">
                      <IconButton
                          id="new-common-mdm-dialog-close"
                          className={classes.dialogTitleCloseAction}
                          onClick={handleToggleNewMdm}
                      >
                          <CloseIcon />
                      </IconButton>
                  </Box>
              </Box>
              <Box mt={5}>
                  <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Name*</Box>
                  <TextField
                      id={`name`}
                      fullWidth
                      required
                      size="small"
                      InputProps={{ className: 'input', classes }}
                      variant="outlined"
                      value={createCommonMdm.name}
                      error={!!errors['name']}
                      helperText={errors['name']}
                      disabled={!isOpen || isSubmitting}
                      onChange={(event) => handleChanges(event.target.id, event.target.value)}
                  />
              </Box>
              <Box mt={2}>
                  <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Description*</Box>
                  <TextField
                      id={`description`}
                      fullWidth
                      required
                      multiline
                      rows="5"
                      size="small"
                      InputProps={{ className: 'input', classes }}
                      variant="outlined"
                      value={createCommonMdm.description}
                      error={!!errors['description']}
                      helperText={errors['description']}
                      disabled={!isOpen || isSubmitting}
                      onChange={(event) => handleChanges(event.target.id, event.target.value)}
                  />
              </Box>
              <Box mt={5} display="flex" justifyContent="flex-end" alignItems="center">
                  <Box mx={2}>
                      <WildHealthButton
                          id="new-mdm-cancel"
                          onClick={handleToggleNewMdm}
                          color="tertiary"
                          size='medium'
                          disabled={!isOpen || isSubmitting}
                      >
                          Cancel
                      </WildHealthButton>
                  </Box>
                  <Box>
                      <WildHealthButton
                          id="save-mdm-button"
                          size='medium'
                          onClick={handleCreateMdmPlan}
                          loading={isSubmitting}
                      >
                          Save
                      </WildHealthButton>
                  </Box>
              </Box>
          </DialogContent>
      </Dialog>
    </Box>
  );
}