import React from 'react';
import { AuthenticatedLayoutComponent } from '../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from "../../../../models/menu.models";
import { OpsAdminToolsComponent } from '../../components/opsAdminToolsComponent/OpsAdminToolsComponent';

export const OpsAdminToolsPage: React.FC = (props: any) => {
    const patientId = props?.location?.state?.patientId as number | null;

    return (
        <AuthenticatedLayoutComponent
            hideBackground
            title={TitlesService.opsAdminToolsTitle}
            pageName={PagesNamesService.opsAdminTools}
            selectedMenuItem={MenuItemTypes.OpsAdminTools}
        >
            <OpsAdminToolsComponent patientId={patientId} />
        </AuthenticatedLayoutComponent>
    )
}
