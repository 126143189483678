import { Box, Divider, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { CircleDiagramComponent } from "../components/circleDiagramComponent/CircleDiagramComponent";
import { ColorDescriptionComponent } from '../components/colorDescriptionComponent/colorDescriptionComponent';
import { HealthRecommendationComponent } from "../components/healthRecommendationComponent/HealthRecommendationComponent";
import { HealthReportMode, HealthReportSections, HealthReportVersion, recommendationName, recommendationRowsCapacity, RecommendationTypesNumbers } from "../models/healthReport.models";
import { HealthReportBasePage, HealthReportMobileBasePage } from "./HealthReportBasePage";
import { useStyles } from './healthReport.styles';
import { HealthReportPageProps } from "./healthReportPageProps";
import commonUseStyles from "../../common/styles/common.styles";
import clsx from "clsx";
import { WildHealthButton } from '../../common/components/wildHealthButton/WildHealthButton';
import { RecommendationSingleSelectComponent } from '../components/recommendationSingleSelectComponent/RecommendationSingleSelectComponent';

export const HealthReportDietAndNutrition2Page: React.FC<HealthReportPageProps> = (props: HealthReportPageProps) => {
    const {
        report,
        recommendations,
        pageNumber,
        handleChanges,
        handleSaveChanges,
        isChanged,
        mode,
        practice,
        handleSelected,
        handleChangesNewRecommendation,
        handleAddRecommendation,
        handleSaveChangesNew,
    } = props;
    const commonClasses = commonUseStyles();
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const cycleDiagramData = [
        {
            name: "CARBS",
            value: report.dietAndNutritionReport.recommendedMacros.carb,
            color: "#80b31b"
        },
        {
            name: "FAT",
            value: report.dietAndNutritionReport.recommendedMacros.fat,
            color: "#eed511"
        },
        {
            name: "PROTEIN",
            value: report.dietAndNutritionReport.recommendedMacros.pro,
            color: "#129ad4"
        }
    ];
   
    return (
        props.mode !== HealthReportMode.PrintMode && isSmallScreen ? (
            <HealthReportMobileBasePage section={HealthReportSections.DietAndNutrition} pageNumber={pageNumber} practice={practice}>
                <Box>
                    <Box px={2}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box>
                                    <span className={classes.reportTitle}>Macronutrients</span>
                                </Box>
                                <Box mt="10px" fontSize="14px" lineHeight="22px">
                                    <p>
                                        Macronutrient tracking can be extremely time intensive and overwhelming, but is essential to
                                        understand where your calories come from. Genetically, we are all different and may benefit
                                        from significant variations in our macronutrient profile. Furthermore, many people
                                        experience significant weight loss (or desired weight gains) through macronutrient tracking.
                                        There are extensive phone apps that assist in calorie and macronutrient counting.
                                    </p>
                                </Box>
                                <Box mt="20px" lineHeight="19px">
                                    <p>
                                        <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeSmallReport)}>
                                            To estimate your daily calories per gram of carbohydrate, fat, and protein use the following formula:
                                        </span>
                                    </p>
                                </Box>
                                <Box mt="20px">
                                    <p>
                                        1 gram Carbohydrate = 4 calories<br />
                                        1 gram Protein = 4 calories<br />
                                        1 gram of Fat = 9 calories
                                    </p>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box mt="30px">
                                    <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeMediumReport, commonClasses.textRegularReport)}>Your recommended macros:</span>
                                </Box>
                                <Box display="flex" justifyContent="center" mt="30px">
                                    <Box display="flex" justifyContent="center">
                                        <CircleDiagramComponent data={cycleDiagramData} radius={80} percentColor="black" />
                                    </Box>
                                    <Box display="flex" alignItems="flex-end" ml={2}>
                                        <Box mb={3}>
                                            <Box>
                                                <ColorDescriptionComponent
                                                    color="#80b31b"
                                                    description="Carbs"
                                                />
                                            </Box>
                                            <Box mt="1em">
                                                <ColorDescriptionComponent
                                                    color="#eed511"
                                                    description="Fats"
                                                />
                                            </Box>
                                            <Box mt="1em">
                                                <ColorDescriptionComponent
                                                    color="#129ad4"
                                                    description="Proteins"
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box px={2}>
                        <Box mt={5}>
                            <Box pb={2}>
                                <span className={classes.reportTitle}>Your Macronutrients Recommendations</span>
                                <Box display="flex" justifyContent="flex-end" width={1}>
                                    {
                                        parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation) && HealthReportMode.EditMode === mode && <WildHealthButton
                                            id="health-recommendation-new-save"
                                            onClick={() => handleSaveChangesNew()}
                                            disabled={!isChanged}>
                                            <Box >
                                                Save changes
                                            </Box>
                                        </WildHealthButton>
                                    }
                                </Box>
                            </Box>
                            {parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
                                ? <Box>
                                    <RecommendationSingleSelectComponent
                                        currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.Macronutrient)}
                                        recommendation={report.dietAndNutritionReport.macronutrientRecommendation}
                                        type={RecommendationTypesNumbers.Macronutrient}
                                        handleSelected={handleSelected}
                                        handleChanges={handleChangesNewRecommendation}
                                        handleAddRecommendation={handleAddRecommendation}
                                        mode={mode}
                                    />
                                </Box>
                                : <HealthRecommendationComponent
                                    mode={mode}
                                    rows={recommendationRowsCapacity[RecommendationTypesNumbers.Macronutrient]}
                                    recommendation={report.dietAndNutritionReport.macronutrientRecommendation}
                                    handleContentChanges={!handleChanges ? handleChanges : (value) => {
                                        handleChanges('dietAndNutritionReport.macronutrientRecommendation.content', value)
                                    }}
                                    handleManualContentChanges={!handleChanges ? handleChanges : (value) => {
                                        handleChanges('dietAndNutritionReport.macronutrientRecommendation.manualContent', value)
                                    }}
                                    handleSaveChanges={handleSaveChanges}
                                    isChanged={isChanged} />
                            }
                        </Box>
                    </Box>
                </Box>
            </HealthReportMobileBasePage>
        ): (
            <HealthReportBasePage section={HealthReportSections.DietAndNutrition} pageNumber={pageNumber} practice={practice}>
                <Box>
                    <Box px={15} pt={5}>
                        <Grid container>
                            <Grid item xs={6}>
                                <Box>
                                    <span className="report-title">Macronutrients</span>
                                </Box>
                                <Box mt={3}>
                                    <p>
                                        Macronutrient tracking can be extremely time intensive and overwhelming, but is essential to
                                        understand where your calories come from. Genetically, we are all different and may benefit
                                        from significant variations in our macronutrient profile. Furthermore, many people
                                        experience significant weight loss (or desired weight gains) through macronutrient tracking.
                                        There are extensive phone apps that assist in calorie and macronutrient counting.
                                    </p>

                                    <br />
                                    <p>
                                        <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeSmallReport)}>
                                            To estimate your daily calories per gram of carbohydrate, fat, and protein use the following formula:
                                        </span>
                                    </p>
                                    <br />
                                    <p>
                                        1 gram Carbohydrate = 4 calories<br />
                                        1 gram Protein = 4 calories<br />
                                        1 gram of Fat = 9 calories
                                    </p>
                                </Box>
                            </Grid>
                            <Grid item xs={6} md={6} lg={6}>
                                <Box textAlign="center">
                                    <span className={clsx(commonClasses.tertiaryFontReport, commonClasses.sizeMediumReport, commonClasses.textMediumReport)}>Your recommended macros:</span>
                                </Box>
                                <Box display="flex" justifyContent="flex-start" mt={3}>
                                    <Box display="flex" justifyContent="center" ml={10}>
                                        <CircleDiagramComponent data={cycleDiagramData} radius={150} percentColor="black" />
                                    </Box>
                                    <Box display="flex" alignItems="flex-end" ml={3}>
                                        <Box mb={3}>
                                            <Box>
                                                <ColorDescriptionComponent
                                                    color="#80b31b"
                                                    description="Carbs"
                                                />
                                            </Box>
                                            <Box mt="1em">
                                                <ColorDescriptionComponent
                                                    color="#eed511"
                                                    description="Fats"
                                                />
                                            </Box>
                                            <Box mt="1em">
                                                <ColorDescriptionComponent
                                                    color="#129ad4"
                                                    description="Proteins"
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider className="report-divider" />
                    <Box pr={15} pl={15}>
                        <Box mt={5}>
                            <Box pb={2} display="flex" justifyContent="space-between">
                                <span className="report-title">Your Macronutrients Recommendations</span>
                                {
                                    parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation) && HealthReportMode.EditMode === mode && <WildHealthButton
                                        id="health-recommendation-new-save"
                                        onClick={() => handleSaveChangesNew()}
                                        disabled={!isChanged}>
                                        <Box >
                                            Save changes
                                        </Box>
                                    </WildHealthButton>
                                }
                            </Box>
                            {parseFloat(report.status.version) === parseFloat(HealthReportVersion.SetRecommendation)
                                ? <Box>
                                    <RecommendationSingleSelectComponent
                                        currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.Macronutrient)}
                                        recommendation={report.dietAndNutritionReport.macronutrientRecommendation}
                                        type={RecommendationTypesNumbers.Macronutrient}
                                        handleSelected={handleSelected}
                                        handleChanges={handleChangesNewRecommendation}
                                        handleAddRecommendation={handleAddRecommendation}
                                        mode={mode}
                                    />
                                </Box>
                                : <HealthRecommendationComponent
                                    mode={mode}
                                    rows={recommendationRowsCapacity[RecommendationTypesNumbers.Macronutrient]}
                                    recommendation={report.dietAndNutritionReport.macronutrientRecommendation}
                                    handleContentChanges={!handleChanges ? handleChanges : (value) => {
                                        handleChanges('dietAndNutritionReport.macronutrientRecommendation.content', value)
                                    }}
                                    handleManualContentChanges={!handleChanges ? handleChanges : (value) => {
                                        handleChanges('dietAndNutritionReport.macronutrientRecommendation.manualContent', value)
                                    }}
                                    handleSaveChanges={handleSaveChanges}
                                    isChanged={isChanged} />
                            }
                        </Box>
                    </Box>
                </Box>
            </HealthReportBasePage>
        )
    )
}
