
export enum ReportType {
  Health = 1,
  Celiac = 2
}

export const ReportTypesNames = new Map<ReportType, string>([
  [ReportType.Health, 'Health'],
  [ReportType.Celiac, 'Celiac'],
]);

export interface ReportTemplateModel {
  id: number;
  createdAt: Date;
  version: string;
  comment: string;
  templateString: string;
  reportType: ReportType;
}