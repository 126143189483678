import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { colors } from "../../constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      boxShadow: "0px 7px 40px rgba(168, 173, 198, 0.18)",
    },
    header: {
      display: "flex",
      justifyContent: "pace-between",
      [theme.breakpoints.down("xs")]: {
        alignItems: "center",
      },
    },
    divider: {
      backgroundColor: "#EFF3F8",
      width: 1,
      height: "auto",
    },
    navigateButton: {
      minWidth: "40px !important",
      width: 40,
      height: 40,
      margin: 3,
      borderRadius: 4,
    },
    navigationIcon: {
      width: 14,
      height: 14,
      [theme.breakpoints.down("xs")]: {
        color: colors.main,
      },
    },
    dateTitle: {
      fontFamily: "Be Vietnam Pro",
      fontSize: 22,
      fontWeight: 500,
      color: "#3F4157",
      [theme.breakpoints.down("xs")]: {
        fontSize: 20,
        fontWeight: 600,
        color: colors.black,
      },
    },
    calander: {
      [theme.breakpoints.down("xs")]: {
        "& .react-calendar__tile--range": {
          background: colors.white,
        },
        "& .react-calendar__tile": {
          fontSize: 20,
        },
        "& .react-calendar__month-view__weekdays__weekday": {
          fontSize: 13,
          fontWeight: 600,
          color: "#3c3c434d",
        },
        "& .react-calendar__month-view__days__day--weekend ": {
          color: colors.black,
        },
        "& .react-calendar__month-view__days__day": {
          color: colors.black,
        },
      },
    },
  })
);
