import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        timeInput: {
            width: '90px'
        },
        recommendedTime: {
            fontSize: 14,
            color: colors.gray1,
            fontStyle: 'italic'
        },
        mdmSection: {
            backgroundColor: colors.bg,
            borderRight: `2px solid ${colors.separators}`
        },
        timeSection: {
            backgroundColor: colors.bg,
            width: '100%'
        },
    })
);
