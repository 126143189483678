import {SelectAppointmentWithTypeComponent} from "../selectAppointmentWithType/SelectAppointmentWithTypeComponent";
import {SelectAppointmentTypeComponent} from "../selectAppointmentType/SelectAppointmentTypeComponent";
import {SelectUserComponent} from "../../../common/components/selectUserComponent/SelectUserComponent";
import {AppointmentAttendeesComponent} from "../appointmentAttendees/AppointmentAttendeesComponent";
import {AppointmentCommentComponent} from "../appointmentComment/AppointmentCommentComponent";
import {WildHealthButton} from "../../../common/components/wildHealthButton/WildHealthButton";
import {AppointmentTargetType, AppointmentWithType} from '../../models/appointments.enums';
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import {isCoachRole, isProviderRole, isStaffRole} from "../../../common/constants/roles";
import {SelectTargetTypeComponent} from "../selectTargetType/SelectTargetTypeComponent";
import {SelectDateTimeComponent} from "../selectDateTime/SelectDateTimeComponent";
import {SelectEmployeeComponent} from "../selectEmployee/SelectEmployeeComponent";
import {SelectDurationComponent} from "../selectDuration/SelectDurationComonent";
import {SelectLocationComponent} from "../selectLocation/SelectLocationComponent";
import {DialogTitle} from "../../../common/components/dialogTitle/DialogTitle";
import {Box, Divider, TextField} from "@material-ui/core";
import {useFacade} from "./coachCreateAppoinmentDialogComponent.hooks";
import {getTimeZoneName} from "../../../timezones/helpers/timezone";
import commonUseStyles from "../../../common/styles/common.styles";
import {useStyles} from "./CoachCreateAppointmentDialog.styles";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import WatchLater from '@material-ui/icons/WatchLater';
import {durations, durationsPerPurpose} from "../../models/times.models";
import {authQuery} from "../../../auth/stores/auth";
import Dialog from "@material-ui/core/Dialog";
import React from "react";
import clsx from 'clsx';
import { FeatureComponent } from "../../../common/components/featureFlags/FeatureComponent";
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import { AppointmentReasonComponent } from "../appointmentReason/AppointmentReasonComponent";

export const CoachCreateAppointmentDialogComponent: React.FC = () => {
    const [
        state,
        handlePatientSelect,
        handlePatientSearch,
        handleEmployeeSelect,
        handleEmployeesSelect,
        handleChangeTargetType,
        handleChangeAppointmentType,
        handleChangeAppointmentWithType,
        handleChangeLocationType,
        handleChangeDate,
        handleChangeTime,
        handleChangeDuration,
        handleShowDuration,
        handleChangeComment,
        handleChangeName,
        handleSubmit,
        handleClose,
        handleChangeReason,
        handleSelectReason
    ] = useFacade();

    const classes = useStyles();
    const commonClasses = commonUseStyles();

    const withTypes = [
        AppointmentWithType.HealthCoach,
        AppointmentWithType.HealthCoachAndProvider,
        AppointmentWithType.Staff
    ].concat(authQuery.isDefaultPractice() ? [] : [AppointmentWithType.Provider]);

    const coaches = state.patientEmployees.filter(x => isCoachRole(x.role.id));
    const providers = state.patientEmployees.filter(x => isProviderRole(x.role.id));
    const staffs = state.employees.filter(x => isStaffRole(x.roleId) && x.locationIds.includes(state.selectedPatient?.location?.id));
    const selectedCoach = state.selectedEmployees.find(x => isCoachRole(x.roleId));
    const selectedProvider = state.selectedEmployees.find(x => isProviderRole(x.roleId));
    const selectedStaff = state.selectedEmployees.find(x => isStaffRole(x.roleId));
    
    const showTypeSection = Boolean(state.selectedPatient);
    const showScheduleWithSection = Boolean(state.selectedAppointmentType);
    const showDateSection = Boolean(state.selectedEmployees.length);

    if (!state.patients || !state.locations || !state.availabilities || !state.employees || !state.appointmentTypes) {
        return <WildHealthLinearProgress />
    }

    const filterPatientDurations = () => {
        if (!state.selectedAppointmentType) {
            const durationValues = durationsPerPurpose.get(state.selectedAppointmentType.purpose);
            durations.filter(x => x.patientMode && (!durationValues || durationValues.some(t => t === x.value)));
        }

        const availableDurations = state.selectedAppointmentType
            .configurations
            .filter(x => x.withType === state.selectedWithType)
            .map(x => x.duration);

        return durations.filter(x => x.patientMode && availableDurations.includes(x.value));
    }

    const filterWithTypes = () => {
        if (!state.selectedAppointmentType) {
            return withTypes;
        }

        return state.selectedAppointmentType
            .configurations
            .filter(x => x.duration === state.selectedDuration.value)
            .map(x => x.withType);
    }

    return (
        <>
            <Dialog maxWidth="md" open={state.open} onClose={() => handleClose()}>
                <DialogTitle
                    id="customized-dialog-title"
                    classes={{ root: classes.dialogTitle }}
                    onClose={() => handleClose()}
                >
                    <span className={clsx(commonClasses.size24, commonClasses.textMedium)}>
                        Scheduling Appointment
                    </span>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Box width='548px'>
                        <Box mb={2}>
                            <SelectTargetTypeComponent
                                value={state.selectedTargetType}
                                handleChange={handleChangeTargetType}
                            />
                        </Box>
                        {
                            state.selectedTargetType === AppointmentTargetType.Patient &&
                            <>
                                <Divider />

                                <Box my={2}>
                                    <SelectUserComponent
                                        title='Patient Name'
                                        selectedUser={state.selectedPatient}
                                        users={state.patients.map(i => ({ id: i.id, firstName: i.firstName, lastName: i.lastName, birthday: i.birthday }))}
                                        handleChange={(user) => handlePatientSelect(user)}
                                        handleInputChange={handlePatientSearch}
                                        error={!!state.errors['selectedPatient']}
                                        helperText={state.errors['selectedPatient']}
                                        isLoading={state.isPatientsLoading}
                                        id='selectedPatient'
                                    />
                                </Box>
                            </>
                        }
                        {
                            state.selectedTargetType === AppointmentTargetType.Other &&
                            <>
                                <Divider />

                                <Box my={2}>
                                    <Box>
                                        <span className={clsx(commonClasses.size14, commonClasses.colorGray1)}>Appointment Name</span>
                                    </Box>
                                    <Box mt={1}>
                                        <TextField
                                            id='name'
                                            required
                                            fullWidth
                                            size="small"
                                            variant="outlined"
                                            value={state.name}
                                            error={!!state.errors['name']}
                                            helperText={state.errors['name']}
                                            onChange={(v) => handleChangeName(v.target.value)}
                                        />
                                    </Box>
                                </Box>
                            </>
                        }


                        {
                            state.selectedTargetType === AppointmentTargetType.Patient && showTypeSection &&
                            <>
                                {
                                    state.isAppointmentTypesLoading
                                        ? <WildHealthLinearProgress/>
                                        : <Box display='flex' alignItems='flex-end' my={2}>
                                            <Box width={1}>
                                                <SelectAppointmentTypeComponent
                                                    types={state.appointmentTypes}
                                                    handleChange={handleChangeAppointmentType}
                                                    value={state.selectedAppointmentType}
                                                />
                                            </Box>
                                            <Box
                                                hidden={state.selectedAppointmentType == null}
                                                id="coach-create-appointment-show"
                                                className={clsx(commonClasses.pointer, commonClasses.colorMain)}
                                                onClick={() => handleShowDuration(!state.showDuration)}
                                                ml={2} mb={0.25}
                                            >
                                                <WatchLater />
                                            </Box>
                                        </Box>
                                }
                                {
                                    state.showDuration &&
                                    <Box my={2}>
                                        <SelectDurationComponent
                                            durations={filterPatientDurations()}
                                            selected={state.selectedDuration}
                                            handleChange={(v) => handleChangeDuration(v, state.selectedTargetType)}
                                        />
                                    </Box>
                                }
                                <Box my={2}>
                                    <SelectLocationComponent
                                        location={state.selectedLocation}
                                        selectedLocationType={state.selectedLocationType}
                                        handleChangeLocationType={handleChangeLocationType}
                                        phoneNumber={state.selectedPatient?.phoneNumber}
                                    />
                                </Box>
                            </>
                        }
                        
                        {showScheduleWithSection && state.selectedAppointmentType?.requiresReasonType && (
                            <FeatureComponent featureFlag={FeatureFlag.AppointmentReasons}>
                                <Divider />
                                <Box my={2}>
                                    <AppointmentReasonComponent
                                        reason={state.reason}
                                        selectedReasonType={state.selectedReasonType}
                                        errors={state.errors}
                                        handleChangeReason={handleChangeReason}
                                        handleSelectReason={handleSelectReason}
                                    />
                                </Box>
                            </FeatureComponent>
                        )}

                        {
                            state.selectedTargetType === AppointmentTargetType.Patient && showScheduleWithSection &&
                            <>
                                <Divider />

                                <Box my={2}>
                                    <SelectAppointmentWithTypeComponent
                                        handleChange={handleChangeAppointmentWithType}
                                        availableTypes={filterWithTypes()}
                                        value={state.selectedWithType}
                                    />
                                </Box>
                                <Box display='flex' my={2} whiteSpace>
                                    {
                                        (state.selectedWithType === AppointmentWithType.HealthCoach ||
                                            state.selectedWithType === AppointmentWithType.HealthCoachAndProvider) &&
                                        <Box width={1}>
                                            <Box>
                                                <span className={clsx(commonClasses.size14, commonClasses.colorGray1)}>Select Health Coach</span>
                                            </Box>
                                            <Box mt={1} width={1}>
                                                <SelectEmployeeComponent
                                                    selectedEmployee={selectedCoach}
                                                    employees={state.selectedAppointmentType.canAssignAnyEmployee ? state.allCoaches : coaches}
                                                    onEmployeeChange={handleEmployeeSelect}
                                                />
                                            </Box>
                                        </Box>
                                    }
                                    {
                                        state.selectedWithType === AppointmentWithType.HealthCoachAndProvider && <Box width='20px' />
                                    }
                                    {
                                        (state.selectedWithType === AppointmentWithType.Provider ||
                                            state.selectedWithType === AppointmentWithType.HealthCoachAndProvider) &&
                                        <Box width={1}>
                                            <Box>
                                                <span className={clsx(commonClasses.size14, commonClasses.colorGray1)}>Select Provider</span>
                                            </Box>
                                            <Box mt={1} width={1}>
                                                <SelectEmployeeComponent
                                                    selectedEmployee={selectedProvider}
                                                    employees={state.selectedAppointmentType.canAssignAnyEmployee ? state.allProviders : providers}
                                                    onEmployeeChange={handleEmployeeSelect}
                                                />
                                            </Box>
                                        </Box>
                                    }
                                    {
                                        state.selectedWithType === AppointmentWithType.Staff &&
                                        <Box width={1}>
                                            <Box>
                                                <span className={clsx(commonClasses.size14, commonClasses.colorGray1)}>Select Staff</span>
                                            </Box>
                                            <Box mt={1} width={1}>
                                                <SelectEmployeeComponent
                                                    selectedEmployee={selectedStaff}
                                                    employees={staffs}
                                                    onEmployeeChange={handleEmployeeSelect}
                                                />
                                            </Box>
                                        </Box>
                                    }
                                </Box>
                            </>
                        }
                        {
                            state.selectedTargetType === AppointmentTargetType.Other &&
                            <Box my={2}>
                                <AppointmentAttendeesComponent
                                    employees={state.employees}
                                    handleChange={handleEmployeesSelect}
                                    error={!!state.errors['selectedEmployees']}
                                    helperText={state.errors['selectedEmployees']}
                                    id='selectedEmployees'
                                />
                            </Box>
                        }

                        {
                            showDateSection &&
                            <>
                                <Divider />

                                <Box my={2}>
                                    <SelectDateTimeComponent
                                        selectedDate={state.selectedDate}
                                        selectedTime={state.selectedTime}
                                        handleChangeDate={handleChangeDate}
                                        handleChangeTime={handleChangeTime}
                                        availability={state.availability}
                                        minDate={state.minDate}
                                        isLoading={state.isAvailabilityLoading}
                                        error={!!state.errors['selectedTime']}
                                        errorMessage={state.errors['selectedTime']}
                                        patientTimezone={`Timezone: ${getTimeZoneName()}`}
                                        hideTime={state.selectedDuration.fullDay}
                                    />
                                </Box>

                                {
                                    state.selectedTargetType === AppointmentTargetType.Other &&
                                    <>
                                        <Divider />

                                        <Box my={2}>
                                            <SelectDurationComponent
                                                durations={durations.filter(x => !x.patientMode)}
                                                selected={state.selectedDuration}
                                                handleChange={(v) => handleChangeDuration(v, state.selectedTargetType)}
                                            />
                                        </Box>
                                    </>
                                }

                                <Divider />

                                <Box my={2}>
                                    <AppointmentCommentComponent
                                        comment={state.comment}
                                        handleChangeComment={(e) => handleChangeComment(e.currentTarget.value)}
                                        rows={5}
                                    />
                                </Box>
                            </>
                        }
                    </Box>
                </DialogContent>
                <DialogActions classes={{ root: classes.dialogActions }}>
                    <WildHealthButton
                        id="coach-create-appointment-cancel"
                        onClick={() => handleClose()}
                        width={120}
                        size='large'
                        color='tertiary'
                        disabled={state.isLoading}>
                        Cancel
                    </WildHealthButton>

                    <WildHealthButton
                        id="coach-create-appointment-create"
                        onClick={(e) => handleSubmit(e)}
                        width={120}
                        size='large'
                        loading={state.isLoading}>
                        Create
                    </WildHealthButton>
                </DialogActions>
            </Dialog>
        </>
    )
}