import React from 'react';
import {makeStyles, createStyles} from "@material-ui/core/styles";
import {Box} from '@material-ui/core';
import {appointmentColors} from "../../../../../appointments/constants/appointmentColors";
import { AppointmentTagsModel } from '../../../../../appointments/models/appointments.models';
import {colors} from "../../../../../common/constants/colors";


const useStyles = makeStyles(() =>
    createStyles({
        root:{
            background: appointmentColors.orange,
            color: colors.white,
            padding: 2,
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 12
        }
    }));

export interface AppointmentTagsProps {
    tags: AppointmentTagsModel[];
    color?: string;
}

const AppointmentTags: React.FC<AppointmentTagsProps> = (props: AppointmentTagsProps): JSX.Element => {
    const classes = useStyles();

    const {
        tags,
        color = appointmentColors.orange
    } = props;

    return (
    <Box>
        {
            tags.sort(x => x.order).map(tag => {
                return ( 
                    <Box mr={1} mt={0.5} display="inline-block" className={classes.root} style={{background: color}}>
                        {
                            tag.displayValue
                        }
                    </Box>
                )
            })
        }
    </Box>
    )
}

export default AppointmentTags;