import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { onEmit } from "../../../../../common/helpers/on-emit";
import { OrderStatus } from "../../../../../orders/models/orders.models";
import { referralOrdersService } from "../../../../../orders/services/referralOrders.service";
import { otherOrdersService } from "../../../../../orders/services/otherOrders.service";
import { OtherOrderModel } from "../../../../../orders/models/otherOrders.models";
import { ordersQuery } from "../../../../../orders/stores/ordersStore/orders.query";
import { ReferralOrderModel } from "../../../../../orders/models/referralOrders.models";
import { referralOrderPdfService, otherOrderPdfService } from "../../../../../orders/services/orders-pdf.service";
import { notesService } from "../../../../../notes/services/notes.service";
import { NoteModel, NotesType, downloadNoteOptions } from "../../../../../notes/models/notes.models";
import { notesQuery } from "../../../../../notes/stores/notes";
import { confirmService } from "../../../../../../services/confirm.service";
import { UserType } from "../../../../../auth/models/auth.enums";
import { handleDownloadNoteByEmployeePdfBd, GetMaximumVersionOfNote } from "../../../../../notes/helpers/noteHelpers";
import { getInitPharmacyInfo, PatientPharmacyInfoModel, PatientModel } from "../../../../../patients/models/patient.model";
import { profileQuery } from "../../../../../account/stores/profileStore";
import { patientsQuery } from "../../../../../patients/stores/patientsStore";
import { demographicPdfService } from "../../../../services/demographic-pdf.service";
import { CoverageModel, InsuranceModel } from "../../../../../insurance/models/insurance.models";
import { insuranceService } from "../../../../../insurance/services/insurance.service";
import { insuranceQuery } from "../../../../../insurance/stores/insurance.query";
import { CoverageStatus } from "../../../../../insurance/enums/coverageStatus";
import { subscriptionService } from "../../../../../payment/services/subscription.service";
import { SubscriptionModel, SubscriptionType, SubscriptionStatus } from "../../../../../payment/models/subscription.models";
import { subscriptionQuery } from "../../../../../payment/stores/subscriptionStore";
import {useFlags} from "launchdarkly-react-client-sdk";

interface DemographicExportWidgetComponentState {
  isLoading: boolean;
  isDownloading: boolean;
  isLoadingReferral: boolean;
  isLoadingDiagnostic: boolean;
  isLoadingNotes: boolean;
  isLoadingInsurance: boolean;
  isLoadingSubscription: boolean;
  isPayByCash: boolean;
  isActionsOpen: boolean;
  isSelectedDemographic: boolean;
  isSelectedDiagnostic: boolean;
  isSelectedReferral: boolean;
  isSelectedNote: boolean;
  referralOrders: ReferralOrderModel[];
  selectReferral: ReferralOrderModel;
  diagnosticOrders: OtherOrderModel[];
  selectDiagnostic: OtherOrderModel;
  notes: NoteModel[];
  selectNote: NoteModel;
  patient: PatientModel;
  pharmacyInfo: PatientPharmacyInfoModel;
  coverages: CoverageModel[];
  insurances: InsuranceModel[];
}

export function useFacade(patientId: number | null): [
  DemographicExportWidgetComponentState,
  () => void,
  () => void,
  () => void,
  () => void,
  () => void,
  (id: any) => void,
  (id: any) => void,
  () => void
] {
  const [state, setState] = useState({
    isLoading: true,
    isDownloading: false,
    isLoadingReferral: true,
    isLoadingDiagnostic: true,
    isLoadingNotes: true,
    isLoadingInsurance: true,
    isLoadingSubscription: true,
    isPayByCash: true,
    isActionsOpen: false,
    isSelectedDemographic: false,
    isSelectedDiagnostic: false,
    isSelectedReferral: false,
    isSelectedNote: false,
    referralOrders: [],
    selectReferral: null,
    diagnosticOrders: [],
    selectDiagnostic: null,
    notes: [],
    selectNote: null,
    patient: {},
    pharmacyInfo: {},
    coverages: [],
    insurances: []
  } as DemographicExportWidgetComponentState);

  const flags = useFlags();

  const handleReset = () => {
    setState((state) => ({
      ...state,
      isSelectedDemographic: false,
      isSelectedDiagnostic: false,
      isSelectedReferral: false,
      isSelectedNote: false,
      selectDiagnostic: null,
      selectReferral: null,
      selectNote: null
    }));
  }

  const handleToggleActions = () => {
    handleReset();
    setState((state) => ({ ...state, isActionsOpen: !state.isActionsOpen }));
  }

  const handleToggleDemographic = () => {
    setState((state) => ({ ...state, isSelectedDemographic: !state.isSelectedDemographic }));
  }

  const handleToggleDiagnostic = () => {
    setState((state) => ({ ...state, isSelectedDiagnostic: !state.isSelectedDiagnostic }));
  }

  const handleSelectOrder = (id: number) => {
    const diagnosticOrder = state.diagnosticOrders.find(x => x.id === id)
    if (diagnosticOrder) {
      setState((state) => ({ ...state, selectDiagnostic: diagnosticOrder }));
    } else {
      const referralOrder = state.referralOrders.find(x => x.id === id)
      if (referralOrder) {
        setState((state) => ({ ...state, selectReferral: referralOrder }));
      }
    }
  }

  const handleToggleReferral = () => {
    setState((state) => ({ ...state, isSelectedReferral: !state.isSelectedReferral }));
  }

  const handleToggleNotes = () => {
    setState((state) => ({ ...state, isSelectedNote: !state.isSelectedNote }));
  }

  const handleSelectNote = (id: number) => {
    const note = state.notes.find(x => x.id === id)
    if (note) {
      setState((state) => ({ ...state, selectNote: note }));
    }
  }

  const handleDownloadDemographicSheet = () => {
    const activeCoverages = state.coverages.filter(x => x.status === CoverageStatus.Active).sort((a, b) => a.priority > b.priority ? 1 : -1);
    demographicPdfService.download(state.patient, state.pharmacyInfo, state.isPayByCash, activeCoverages?.[0])
  }

  const handleDownloadDiagnosticOrder = (order: OtherOrderModel) => {
    otherOrderPdfService.download(order)
  }


  const handleDownloadReferralOrder = (order: ReferralOrderModel) => {
    referralOrderPdfService.download(order)
  }

  const handleDownloadNotePdf = (note: NoteModel, flags) => {
      const noteId = GetMaximumVersionOfNote(note)?.id;
      const currentNote = note.id === noteId ? note : note.amendedNotes.find(x => x.id === noteId);
      if (note.type !== NotesType.Internal) {
          confirmService.confirmAndSelectedNoteViewType(
              'Select the view type of the note',
              downloadNoteOptions,
          ).subscribe(
              (type) => {
                  const isEmployee = type === UserType.Employee;
                  setState(state => ({ ...state, isDownloading: true }));
                  const cb = () => setState(state => ({ ...state, isDownloading: false }));
                  notesService.downloadNote(noteId, note.name, isEmployee).subscribe(
                      cb,
                      () => {
                          handleDownloadNoteByEmployeePdfBd(currentNote, type, flags).subscribe(cb, cb);
                      }
                  )
              },
              () => {}
          );
      }
      else {
          setState(state => ({ ...state, isDownloading: true }));
          const cb = () => setState(state => ({ ...state, isDownloading: false }));
          notesService.downloadNote(noteId, note.name, true).subscribe(
              cb,
              () => {
                  handleDownloadNoteByEmployeePdfBd(currentNote, UserType.Employee, flags).subscribe(cb, cb);
              }
          )
      }
  }

  const handleDownload = () => {
    setState((state) => ({ ...state, isActionsOpen: false }));
    if (state.isSelectedDemographic) {
      handleDownloadDemographicSheet();
    }
    if (state.isSelectedDiagnostic && state.selectDiagnostic) {
      handleDownloadDiagnosticOrder(state.selectDiagnostic);
    }
    if (state.isSelectedReferral && state.selectReferral) {
      handleDownloadReferralOrder(state.selectReferral);
    }
    if (state.isSelectedNote && state.selectNote) {
      handleDownloadNotePdf(state.selectNote, flags);
    }
    handleReset();
  }

  useEffect(() => {
    if (!state.isLoadingReferral && !state.isLoadingDiagnostic && !state.isLoadingNotes && !state.isLoadingInsurance && !state.isLoadingSubscription) {
      setState((state) => ({ ...state, isLoading: false }));
    }
  }, [state.isLoadingReferral, state.isLoadingDiagnostic, state.isLoadingNotes, state.isLoadingInsurance, state.isLoadingSubscription]);

  useEffect(() => {
    const subscriptions: Subscription[] = [
      onEmit<PatientModel>(patientsQuery.targetPatient$, targetPatient => {
        if (targetPatient?.id === Number(patientId)) {
          setState(state => ({
            ...state,
            patient: targetPatient,
          }));
        }
      }),
      onEmit<PatientPharmacyInfoModel>(profileQuery.pharmacyInfo$, pharmacyInfo =>
        setState(state => ({ ...state, pharmacyInfo: pharmacyInfo ?? getInitPharmacyInfo() }))
      ),
      onEmit<OtherOrderModel[]>(ordersQuery.otherOrders$, orders => {
        setState(state => ({
          ...state,
          diagnosticOrders: orders.filter(x => x.status === OrderStatus.Signed).sort((x, y) => (new Date(y.orderedAt)).getTime() - (new Date(x.orderedAt)).getTime()),
        }));
      }),
      onEmit<ReferralOrderModel[]>(ordersQuery.referralOrders$, orders => {
        setState(state => ({
          ...state,
          referralOrders: orders.filter(x => x.status === OrderStatus.Signed).sort((x, y) => (new Date(y.orderedAt)).getTime() - (new Date(x.orderedAt)).getTime()),
        }));
      }),
      onEmit<NoteModel[]>(notesQuery.notes$, notes => {
        setState(state => ({
          ...state,
          notes: notes.filter(x => !x.deletedBy).sort((x, y) => (new Date(y.completedAt)).getTime() - (new Date(x.completedAt)).getTime()),
        }));
      }),
      onEmit<CoverageModel[]>(insuranceQuery.coverages$, coverages => {
        setState(state => ({ ...state, coverages }));
      }),
      onEmit<InsuranceModel[]>(insuranceQuery.insurances$, insurances => {
        setState(state => ({ ...state, insurances }));
      }),
      onEmit<SubscriptionModel>(subscriptionQuery.subscription$, subscription => {
        if (subscription?.type === SubscriptionType.Insurance && subscription?.status === SubscriptionStatus.Active) {
          setState((state) => ({ ...state, isPayByCash: false }))
        }
      })
    ];

    const cbRef = () => setState((state) => ({ ...state, isLoadingReferral: false }));
    const cbDiagnostic = () => setState((state) => ({ ...state, isLoadingDiagnostic: false }));
    const cbNotes = () => setState((state) => ({ ...state, isLoadingNotes: false }));
    const cbInsurance = () => setState((state) => ({ ...state, isLoadingInsurance: false }));
    const cbSubscription = () => setState((state) => ({ ...state, isLoadingSubscription: false }));


    referralOrdersService.get(patientId).subscribe(cbRef, cbRef);
    otherOrdersService.get(patientId).subscribe(cbDiagnostic, cbDiagnostic);
    notesService.getByEmployee(patientId).subscribe(cbNotes, cbNotes);
    insuranceService.getAvailableInsurancesByEmployee(patientId).subscribe(() => {
      insuranceService.getCoveragesByEmploy(patientId).subscribe(cbInsurance, cbInsurance)
    }, cbInsurance)
    if (patientsQuery.getTargetPatientSubscription()) {
      subscriptionService.getPatientCurrent(patientId).subscribe(cbSubscription, cbSubscription)
    } else {
      cbSubscription()
    }

    return () => {
      subscriptions.map((it) => it.unsubscribe());
    };
  }, [patientId]);

  return [
    state,
    handleToggleActions,
    handleToggleDemographic,
    handleToggleDiagnostic,
    handleToggleReferral,
    handleToggleNotes,
    handleSelectOrder,
    handleSelectNote,
    handleDownload
  ];
}
