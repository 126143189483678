import {Box, CircularProgress} from "@material-ui/core";
import React from 'react';
import { useFacade } from "./insuranceInfoComponent.hooks";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import {CreateInsuranceModalComponent} from "../createInsuranceModalComponent/CreateInsuranceModalComponent";
import {WildHealthButton} from "../../../common/components/wildHealthButton/WildHealthButton";
import {ConfirmInsuranceDialog} from "../../../common/dialogs/confirmDialog/ConfirmInsuranceDialog";
import {InsurancesListComponent} from "../insurancesListComponent/InsurancesListComponent";
import {UpdateInsuranceModalComponent} from "../updateInsuranceModalComponent/UpdateInsuranceModalComponent";
import { CommentsDialogComponent } from "../../../comments/components/CommentsDialogComponent/CommentsDialogComponent";
import { WildHealthTooltip } from "../../../common/components/WildHealthTooltip";

interface InsuranceInformationComponentProps {
    patientId: number;
}

export const InsuranceInformationComponent: React.FC<InsuranceInformationComponentProps> = (props: InsuranceInformationComponentProps) => {
    const {
        patientId,
    } = props;
    const [
        {
            isInsuranceLoading,
            isInsuranceAvailable,
            insuranceUnavailibilityReason,
            isAddButtonLoading,
            patientInsurances,
            attachments,
            isOpenComments,
            selectedUniversalId
        },
        isEnabledInsuranceAdd,
        handleAddInsurance,
        handleEditInsurance,
        handleOpenComments,
        handleCloseComments
    ] = useFacade(patientId);

    if (isInsuranceLoading) {
        return (
            <Box p={5}>
                <WildHealthLinearProgress />
            </Box>
        );
    }

    return (
        <Box p={5} className="wh-tw-text-xs">
            <Box>
                <Box py={2} display="flex" justifyContent="space-between" alignItems="center">
                    <Box><span className="wh-tw-text-xl wh-tw-font-medium"> Insurance Information </span></Box>
                    <WildHealthTooltip title={isEnabledInsuranceAdd ? insuranceUnavailibilityReason : 'We currently are no longer accepting new insurance patients'} placement="top">
                        <Box>
                            <WildHealthButton 
                                size="large" 
                                id="add-insurance-button" 
                                onClick={() => handleAddInsurance()} 
                                disabled={isAddButtonLoading || !isInsuranceAvailable || !isEnabledInsuranceAdd}
                            >
                                <Box>
                                    {
                                        isAddButtonLoading && <CircularProgress size={18} className="wh-tw-text-primaryV" />
                                    }
                                </Box>
                                <Box ml={2}>
                                    <span>Add Insurance Card</span>
                                </Box>
                            </WildHealthButton>
                        </Box>
                    </WildHealthTooltip>

                </Box>
            </Box>
            <Box>
                <InsurancesListComponent
                    insurances={patientInsurances}
                    handleEdit={handleEditInsurance}
                    handleOpen={handleOpenComments}
                    patientId={patientId}
                    attachments={attachments}
                />
            </Box>
            <CommentsDialogComponent
                isOpen={isOpenComments}
                universalId={selectedUniversalId}
                handleClose={handleCloseComments}
            />
            <CreateInsuranceModalComponent/>
            <UpdateInsuranceModalComponent/>
            <ConfirmInsuranceDialog/>
        </Box>
    )
}
