import React from 'react';
import { AuthenticatedLayoutComponent } from "../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent";
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from "../../../../models/menu.models";
import { MessageSettingsComponent } from "../../components/messageSettingsComponent/MessageSettingsComponent";

export const MessageSettingsPage: React.FC = () => {
    return (
        <AuthenticatedLayoutComponent
            hideBackground
            displayChildren='flex'
            title={TitlesService.messageSettingsTitle}
            pageName={PagesNamesService.messageSettings}
            selectedMenuItem={MenuItemTypes.Messaging}
        >
            <MessageSettingsComponent />
        </AuthenticatedLayoutComponent>
    )
};
