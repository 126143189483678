import React from 'react';
import { Box, IconButton, Button, Tooltip, TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { useFacade } from "./uploadExcelFileDialog.hooks";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import commonUseStyles from '../../../common/styles/common.styles';
import Dropzone from 'react-dropzone';
import { useStyles } from "./uploadExcelFileDialogComponent.styles";
import clsx from 'clsx';
import { Delete } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import BackupIcon from '@material-ui/icons/Backup';

export const UploadExcelFileDialogComponent: React.FC = () => {
    const [
        {
            isOpen,
            toSendAttachments,
            editIndex,
        },
        handleFileUpload,
        handleSubmit,
        handleClose,
        handleDropAttachment,
        handleRemoveAttachment,
        handleEditAttachment,
        handleSaveAttachment,
        handleChanges,
        getAllowedExtensions,
    ] = useFacade();
    const commonClasses = commonUseStyles();
    const classes = useStyles();

    const fileTypes = [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ]

    const getFileSize = (size: number) => {
        if (size < 1024) {
            return size + ' bytes';
        } else if (size > 1024 && size < 1048576) {
            return (size / 1024).toFixed(1) + ' KB';
        } else if (size > 1048576) {
            return (size / 1048576).toFixed(1) + ' MB';
        }
    }

    const renderTitle = (item, index) => {

        return editIndex === index
            ?
            <TextField
                type='text'
                size="small"
                variant="outlined"
                value={item.name}
                InputProps={{
                    className: 'input',
                    endAdornment: (
                        <Tooltip title="Save" placement="top" arrow>
                            <IconButton
                                id={`input-${index}`}
                                size="small"
                                onClick={() => { handleSaveAttachment(index) }}
                            >
                                <EditIcon color="primary" fontSize='small' />
                            </IconButton>
                        </Tooltip>
                    ),
                }}
                onChange={(v) => handleChanges(index, v.target.value)}
            />
            : <Box key={index} className={classes.attachment} mb={1} px={1}>
                <Box mr={1} className={classes.attachmentTitle} ml={1}>{item.name}</Box>
                <Tooltip title="Edit" placement="top" arrow>
                    <IconButton
                        id={`input-${index}`}
                        size="small"
                        onClick={() => { handleEditAttachment(index) }}
                    >
                        <EditIcon color="primary" fontSize='small' />
                    </IconButton>
                </Tooltip>
            </Box>
    }

    return (
        <>
            <Dialog maxWidth="md" open={isOpen} onClose={() => handleClose()} aria-labelledby="form-dialog-title">
                <DialogContent className={classes.dialogContent}>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box>
                      <span className={clsx(commonClasses.size24, commonClasses.textMedium)}>Upload Document</span>
                    </Box>
                    <Box display="flex " alignItems="center">
                      <IconButton
                        id="upload-document-close"
                        className={classes.dialogTitleCloseAction}
                        onClick={() => handleClose()}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box display="flex" mt={5} mb={2}>
                    <Dropzone
                        onDrop={handleDropAttachment}
                        maxFiles={1}
                        accept={fileTypes}
                    >
                        {
                            ({ getRootProps }) => {
                                const rootProps = getRootProps();
                                delete rootProps['onClick']

                                return (
                                    <div {...rootProps} className={classes.dropContainer}>
                                        <BackupIcon className={classes.dropIcon} />
                                        <span className={classes.dropTitle}>Drag and Drop your file or </span>
                                        <span className={classes.dropTitle}>
                                            <Button className={classes.label} component="label">
                                                Browse
                                                <input type="file" style={{ display: "none" }}
                                                    onChange={(e) => handleFileUpload(e)}
                                                    accept={getAllowedExtensions()}
                                                />
                                            </Button>

                                        </span>
                                    </div>)
                            }
                        }
                    </Dropzone>

                  </Box>
                  {
                    toSendAttachments && toSendAttachments.length ?
                      toSendAttachments.map((item, index) =>
                          <Box mt={1} display='flex' justifyContent='space-between'>
                              {renderTitle(item, index)}

                              <Box mt={1} display='flex'>
                                  <span className={commonClasses.colorGray2}>{getFileSize(item.file.size)}</span>
                                  <span className={classes.deleteIcon}><Delete onClick={() => handleRemoveAttachment(index)} /></span>
                              </Box>
                          </Box>
                      ) : <></>
                  }
                </DialogContent>
                <DialogActions>
                    <Box mb={4}>
                        <WildHealthButton
                            id="upload-excel-file-cancel"
                            color="tertiary"
                            size='medium'
                            onClick={() => handleClose()}
                        >
                            Cancel
                        </WildHealthButton>
                    </Box>
                    <Box pr={2} mb={4}>
                        <WildHealthButton
                            id="upload-excel-file"
                            size='medium'
                            onClick={() => handleSubmit()}
                            disabled={!toSendAttachments.length || Number.isInteger(editIndex)}
                        >
                            Upload
                        </WildHealthButton>
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    )
}