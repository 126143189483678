import { Store, StoreConfig } from '@datorama/akita';
import { UnmatchedDocumentModel, UnmatchedDocumentType, UnmatchedLabResultModel } from '../../../unmatchedLabs/models/unmatchedLabs.models';
import { LabOrderModel } from '../../models/labOrders.models';

/**
 * Represents lab orders state
 */
export interface LabOrdersState {
    orders: LabOrderModel[];
    unmatchedDocuments: UnmatchedDocumentModel[];
    isUnmatchedFile: boolean;
}

/**
 * Creates initial state
 */
export function createInitialState(): LabOrdersState {
    return {
        orders: [],
        unmatchedDocuments: [],
        isUnmatchedFile: false,
    };
}

@StoreConfig({ name: 'labOrders', resettable: true })
export class LabOrdersStore extends Store<LabOrdersState> {
    constructor() {
        super(createInitialState());
    }

    public addOrder(order: LabOrderModel): void {
        this.update({ orders: [...this.getValue().orders, order] });
    }

    public updateOrder(order: LabOrderModel): void {
        const orders = this.getValue().orders.map((x) => (x.id === order.id) ? order : x);
        this.update({ orders: orders });
    }
    
    public getDocumentsFromResults(results: UnmatchedLabResultModel[]): void {
        const documents = [];
        results.forEach(lab => documents.push(
            ...lab.documents.filter(doc => doc.type === UnmatchedDocumentType.LABRES && doc.mimeType === "application/pdf").map(doc => {
                return {
                    ...doc,
                    labResultId: lab.id,
                    name: `${doc.id}_LabResults${doc.updatedAt}.${doc.mimeType.split('/')[1]}`
                }
            })
        ))

        this.update({ unmatchedDocuments: documents });
    }

    public deleteDocument(labResultId: number): void {
        this.update({ unmatchedDocuments: this.getValue().unmatchedDocuments.filter(x => x.labResultId !== labResultId) });
    }
}

export const labOrdersStore = new LabOrdersStore();
