import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { onEmit } from '../../../common/helpers/on-emit';
import { employeeConversationsQuery } from "../../stores/employeeConversationsStore/employeeConversations.query";
import { selectShortcutService } from "../../../healthReport/services/selectShortcut.service";
import { insertString } from "../../../common/helpers/insert-string";

interface EditAiMessageState {
    message: string;
    acceptingLoading: boolean;
    ignoreChangesEvent: boolean;
}

export function useFacade(
    initMessage: string,
    handleConfirm: (message: string) => void
): [
        EditAiMessageState,
        (event: any, message: string) => void,
        (value: string) => void,
    ] {

    const [state, setState] = useState({
        message: initMessage,
        acceptingLoading: false,
        ignoreChangesEvent: false
    } as EditAiMessageState);

    const handleChangeMessage = (value: string) => {
        if (state.ignoreChangesEvent) {
            return;
        }

        setState(state => ({ ...state, message: value }));
    }

    const handleKeyDown = (event: any, message: string) => {
        setState(state => ({ ...state, ignoreChangesEvent: false }));

        if (event.keyCode === 13 && !event.shiftKey) {
            handleConfirm(message.trim())
        }
        
        if (event.keyCode === 51 && event.altKey) {
            setState(state => ({ ...state, ignoreChangesEvent: true }));

            const value = (document.getElementById('editing-ai-message') as any).value;
            const cursorPos = (document.getElementById('editing-ai-message') as any).selectionStart;
            setTimeout(() => {
                handleOnTextMessageChanged(value, cursorPos);
            }, 1)
        }
    }

    const handleTextMessageChanged = (value: string) => {
        setState(state => ({
            ...state,
            message: value
        }));
    }

    const handleOnTextMessageChanged = (value: string, cursorPos: number) => {
        const cb = (data) => {
            value = state.message;
            if (data) {
                handleTextMessageChanged(insertString(value, data, cursorPos + 1));
            }
        }

        selectShortcutService.select().subscribe(cb);
    }

    useEffect(() => {
        setState(state => ({ ...state, message: initMessage }));
    }, [initMessage]);

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<boolean>(employeeConversationsQuery.aiMessageAcceptLoading$, loading => 
                setState(state => ({ ...state, acceptingLoading: loading }))
            ),
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [
        state,
        handleKeyDown,
        handleChangeMessage,
    ];
}
