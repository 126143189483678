import {Box} from "@material-ui/core";
import React from "react";
import {AgreementBasePage} from "./AgreementBasePage";
import {AgreementHeaderComponent} from "./AgreementHeaderComponent";
import {AgreementPageProps} from "./agreementPageProps";

export const CoachingClientSingleAgreementPage: React.FC<AgreementPageProps> = (props: AgreementPageProps) => {
    const renderMemberships = () => {
        return (
            <Box className="paragraph">
                <Box className="paragraph"><span
                    className="paragraph-number">3.1.1.</span> <span
                    className="text-bold"> Employee Sponsored Membership:</span> Access to Clarity depending on employer agreement.
                </Box>
            </Box>
        )
    }

    return (
        <AgreementBasePage>
            <Box py={7} className="agreement">
                <AgreementHeaderComponent/>
                <Box className="text-light-medium">
                    <Box mt={2} textAlign="center">
                        <span className="agreement-title">COACHING CLIENT AGREEMENT</span>
                    </Box>
                    <Box mt={2}>
                        <Box className="indent">
                            This is an Agreement between Wild Health Inc, a Delaware Corporation located at 535
                            Wellington Way, Suite #330, Lexington, Kentucky 40503 and 212 South 2nd Street, Danville,
                            Kentucky, 40422 (<span className="text-bold">“Coach”</span>), and health coach or clinician
                            (<span className="text-bold">“Provider”</span>) in their capacity as agents of Wild Health,
                            and you, (<span className="text-bold">“Client” or “You”</span>).
                        </Box>
                    </Box>
                    <Box mt={2}>
                        <Box>
                            NOT HEALTH INSURANCE. THIS AGREEMENT IS NOT HEALTH INSURANCE AND DOES NOT MEET ANY
                            INDIVIDUAL HEALTH INSURANCE MANDATE THAT MAY BE REQUIRED BY FEDERAL LAW, INCLUDING THE
                            FEDERAL PATIENT PROTECTION AND AFFORDABLE CARE ACT AND COVERS ONLY LIMITED ROUTINE HEALTH
                            CARE SERVICES AS DESIGNATED IN THIS AGREEMENT. THIS AGREEMENT IS NOT WORKERS’ COMPENSATION
                            INSURANCE AND DOES NOT REPLACE AN EMPLOYER’S OBLIGATIONS UNDER APPLICABLE FEDERAL OR STATE
                            LAW.
                        </Box>
                    </Box>
                    <Box mt={2}>
                        <Box className="text-bold">Background</Box>
                    </Box>
                    <Box mt={2}>
                        <Box className="indent">
                            Client is desirous of implementing coaching services into the Client's life and health
                            practices, as discussed more fully below. Coach is skilled and capable in the areas Client
                            needs and would like to coach Client. This Agreement sets forth the terms and conditions
                            whereby Coach agrees to provide professional coaching Services (as described below). Coach
                            will be engaged for the limited purpose of providing these Services to the Client.
                        </Box>
                    </Box>
                    <Box mt={2}>
                        <Box className="text-bold">1. Coaching Terms</Box>
                        <Box className="paragraphs-section">
                            <Box className="paragraph"><span className="paragraph-number">1.1. </span>
                                A coaching relationship between two Parties is essentially a relationship whereby the
                                Coach assists the Client in meeting the Client's potential within the areas the coaching
                                relationship is meant to focus on.
                            </Box>
                            <Box className="paragraph"><span className="paragraph-number">1.2. </span>
                                Client hereby acknowledges and agrees:
                                <Box className="paragraph"><span
                                    className="paragraph-number">1.2.1.</span> Client is solely and
                                    exclusively responsible for the choices that Client makes with
                                    regard to this coaching relationship, as well as the Coach's
                                    recommendations and
                                    input;
                                </Box>
                                <Box className="paragraph"><span
                                    className="paragraph-number">1.2.2.</span> Client is solely and
                                    exclusively responsible for Client's own mental health,
                                    physical health, business decisions, and any other actions or inaction
                                    Client chooses
                                    to take;
                                </Box>
                                <Box className="paragraph"><span
                                    className="paragraph-number">1.2.3.</span> Coach is not liable for any
                                    result or non-result or any consequences which may
                                    come about due to Client's relationship with Coach;
                                </Box>
                            </Box>
                            <Box className="paragraph"><span className="paragraph-number">1.3.</span> Coaching
                                is neither a therapeutic or medical relationship, Coach may not provide
                                therapy or medical services and Client is responsible for procuring these
                                services at
                                Client's own will and discretion if needed.
                            </Box>
                            <Box className="paragraph"><span className="paragraph-number">1.4.</span> Client
                                agrees to respond to Coach no later than the following amount of time after
                                being
                                reached out to for communication: 72 hours. In the event of an emergency or
                                other
                                similar conflict, Coach will give the Client as much notice as possible if
                                there is the
                                possibility of interruption to the Services, whether that interruption is
                                temporary or long-term.
                            </Box>
                        </Box>
                    </Box>
                    <Box mt={2}>
                        <Box className="text-bold">2. Description of Services</Box>
                        <Box className="paragraphs-section">
                            <Box className="paragraph"><span className="paragraph-number">2.1.</span> The
                                Client hereby engages the Coach, and the Coach accepts such engagement to
                                provide the following coaching services for the Client (hereinafter, the
                                "Services"):
                                <Box ml="40px">
                                    <ul className="circle">
                                        <li>Assistance with general health and lifestyle changes, weight
                                            management, goal setting, and general wellbeing
                                        </li>
                                        <li>Genetic-based health optimization</li>
                                        <li>Genetics-based nutrition and fasting protocol</li>
                                        <li>Epigenetic counseling</li>
                                        <li>Supplement recommendation</li>
                                        <li>Sleep optimization</li>
                                        <li>Exercise and physical activity plans and management</li>
                                        <li>Accountability</li>
                                    </ul>
                                </Box>
                            </Box>
                            <Box className="paragraph"><span className="paragraph-number">2.2.</span> Coach
                                and Client will use the following methods of contact for meetings throughout
                                the
                                coaching relationship: Phone, Video messaging, Email, Secure text, or
                                successive
                                secure communication platform.
                            </Box>
                            <Box className="paragraph"><span className="paragraph-number">2.3.</span> Coach
                                may agree in a separate, written document to expand the scope of Services to
                                include additional tasks. Such written document may be informal, such as an
                                email and
                                will include additional fees, as applicable.
                            </Box>
                        </Box>
                    </Box>
                    <Box mt={2}>
                        <Box className="text-bold">3. Schedule and Fees</Box>
                        <Box className="paragraphs-section">
                            <Box className="paragraph"><span className="paragraph-number">3.1. </span><span
                                className="underline">Level of Service</span>. Coach and Client agree to visits based on
                                selected plan below.
                                <Box ml="20px">
                                    {
                                        renderMemberships()
                                    }
                                </Box>
                            </Box>
                            <Box className="paragraph"><span className="paragraph-number">3.2. </span><span
                                className="underline">Changes to Schedule</span>.
                                The Parties agree to the following number of meetings selected
                                above. Client understands and acknowledges that it is Client’s
                                responsibility to schedule
                                visits, if Client fails to schedule a visit during a 30-day period, Wild
                                Health reserves the
                                right to reduce the overall available visits under a plan by a pro-rata
                                amount to reflect the
                                missed visit(s) as permitted under state or federal law. Coach and Client
                                may agree to a
                                change of the schedule between them, through an additional written document.
                                Client or
                                Coach may, from time to time, need to cancel or reschedule any of the
                                coaching
                                meetings. If Coach is responsible for the reschedule, Coach will become
                                available to
                                Client as the soonest possible opportunity within ten (10) business days. If
                                Client is
                                responsible for the cancellation or reschedule, Client agrees to notify
                                Coach no less than
                                1 business day prior to the scheduled meeting. If Client cancels or
                                reschedules within the
                                24-hour period, Client agrees to pay the full amount required for the
                                meeting, if
                                requested by Coach (at Coach's sole and exclusive discretion). The Parties
                                will then
                                attempt to reschedule the meeting in good faith.
                            </Box>
                            <Box className="paragraph"><span className="paragraph-number">3.3. </span><span
                                className="underline">Holidays</span>.
                                Coach will be unavailable on the following holidays: New Years’ Eve, New
                                Years’ Day, Good Friday, Memorial Day, Independence Day, Labor Day,
                                Thanksgiving
                                Day, day after Thanksgiving, Christmas Eve, and Christmas Day.
                            </Box>
                            <Box className="paragraph"><span className="paragraph-number">3.4. </span><span
                                className="underline">Fees Generally</span>.
                                In exchange for the services described herein, Client or Guarantor
                                agrees to pay Wild Health, the amount as set forth in Section 3.1 above.
                                Fees are payable
                                prior to performance of any services. If Coach cancels this Agreement before
                                the
                                agreement termination date, then Coach shall refund the Client’s pro-rated
                                share of any
                                payment remaining after deducting individual charges for services rendered
                                to Client up
                                to cancellation. Coach may amend the Membership Fee Schedule at any time, as
                                it may
                                determine in its sole discretion, upon providing Client at least sixty (60)
                                days’ advance
                                written notice. For employer sponsored memberships, individual membership
                                fees will
                                only apply if the employee/member elects to continue services after
                                termination or
                                employee sponsorship ends or elects services above and beyond that which the
                                employer
                                has agreed to cover.
                            </Box>
                            <Box className="paragraph"><span className="paragraph-number">3.5. </span><span
                                className="underline">Invoice</span>.
                                A monthly invoice will be provided via email (Preferred). Or, in the case
                                that
                                Client does not have access to email, Client will receive a monthly invoice
                                via mail.
                            </Box>
                            <Box className="paragraph"><span className="paragraph-number">3.6. </span><span
                                className="underline">Payment</span>.
                                Payment may be remitted to Wild Health via credit/debit card, cash or check.
                                Cash and check payments will only be accepted on site at the Coach office
                                (address listed above).
                                An Automatic Credit/Debit Authorization Form at Exhibit A may be
                                executed to authorize automatic payment of invoices. Payment will be
                                considered late
                                fifteen (15) days after the invoice date and a $15 late fee applied to the
                                amounts owed.
                                Late payment may result in termination of the Client’s subscription solely
                                at the
                                discretion of Coach.
                            </Box>
                            <Box className="paragraph"><span className="paragraph-number">3.7. </span><span
                                className="underline">Credit Card Authorization</span>.
                                If an invoice remains unpaid for sixty (60) days after invoice
                                date, Client authorizes Coach to charge the credit card on file for the
                                Client in the
                                amount of the past-due invoice, plus any late charges as set forth in the
                                Automatic
                                Credit/Debit Authorization Form, attached hereto at Exhibit A.
                            </Box>
                            <Box className="paragraph"><span className="paragraph-number">3.8. </span><span
                                className="underline">Refund</span>.
                                Client shall not be entitled to any refund for any monies spent under this
                                Agreement. If Client wishes to terminate this Agreement prior to its planned
                                expiration,
                                Client will forfeit each of the fees until then paid to the Coach, unless
                                otherwise
                                prohibited by applicable federal or state law.
                            </Box>
                            <Box className="paragraph"><span className="paragraph-number">3.9. </span><span
                                className="underline">Non-Participation in Insurance</span>.
                                Client acknowledges that neither Coach, nor the
                                Provider participate in any health insurance or HMO plans. Neither of the
                                above make
                                any representations whatsoever that any fees paid under this Agreement are
                                covered by
                                your health insurance or other third-party payment plans applicable to the
                                Client. The
                                Client shall retain full and complete responsibility for any such
                                determination. Wild
                                Health will not bill your health insurance for services provided under this
                                Agreement.
                                Payments made by Client under this Agreement may not count towards health
                                insurance
                                deductibles or maximum out-of-pocket expenses. This agreement acknowledges
                                your
                                understanding that Medicare, Medicaid, or any other federal health care
                                program cannot
                                be billed for any services performed for you by the Physician. You agree not
                                to attempt
                                to obtain reimbursement for any such services from any federal health care
                                program.
                            </Box>
                        </Box>
                    </Box>
                    <Box mt={2}>
                        <Box className="text-bold">4. General Terms</Box>
                        <Box className="paragraphs-section">
                            <Box className="paragraph"><span className="paragraph-number">4.1. </span><span
                                className="underline">Term; Termination</span>.
                                This Agreement will commence on the date first written above and will extend for the
                                period of the membership selected above automatically renewing thereafter except in the
                                case of the free trial which terminates after 30 days. Notwithstanding the above, both
                                Client and Coach shall have the absolute and unconditional right to terminate the
                                Agreement, without the showing of any cause for termination, upon giving thirty (30)
                                days prior written notice to the other party. Unless previously terminated as set forth
                                above, at the expiration of the initial term (and each succeeding term), the Agreement
                                will automatically renew for successive terms. In addition, this agreement may be
                                terminated immediately should the Client fail to provide payment for a sixty (60) day
                                period.
                            </Box>
                            <Box className="paragraph"><span className="paragraph-number">4.2. </span><span
                                className="underline">Early Termination Fee</span>.
                                To the extent permissible by state and federal law, Wild Health
                                may apply an early termination fee should client terminate this Agreement
                                without cause
                                prior to its expiration, such early termination fee will not exceed 3 months
                                of the
                                applicable subscription monthly fee amount.
                            </Box>
                            <Box className="paragraph"><span className="paragraph-number">4.3. </span><span
                                className="underline">Severability</span>.
                                If for any reason any provision of this Agreement shall be deemed, by a
                                court of competent jurisdiction, to be legally invalid or unenforceable in
                                any jurisdiction
                                to which it applies, the validity of the remainder of the Agreement shall
                                not be affected,
                                and that provision shall be deemed modified to the minimum extent necessary
                                to make
                                that provision consistent with applicable law and in its modified form, and
                                that provision
                                shall then be enforceable.
                            </Box>
                            <Box className="paragraph"><span className="paragraph-number">4.4. </span><span
                                className="underline">Amendment</span>.
                                No amendment of this Agreement shall be binding on a party unless it is
                                made in writing and signed by all the parties. Notwithstanding the
                                foregoing, Coach may
                                unilaterally amend this Agreement to the extent required by federal, state,
                                or local law or
                                regulation (“Applicable Law”) by sending Client thirty (30) days’ advance
                                written notice
                                of any such change. Any such changes are incorporated by reference into this
                                Agreement
                                without the need for signature by the parties and are effective as of the
                                date established
                                by Coach. Moreover, if Applicable Law requires this Agreement to contain
                                provisions
                                that are not expressly set forth in this Agreement, then, to the extent
                                necessary, such
                                provisions shall be incorporated by reference into this Agreement and shall
                                be deemed a
                                part of this Agreement as though they had been expressly set forth in this
                                Agreement.
                            </Box>
                            <Box className="paragraph"><span className="paragraph-number">4.5. </span><span
                                className="underline">Assignment</span>.
                                This Agreement, and any rights Client may have under it, may not be
                                assigned or transferred by Client to another person(s).
                            </Box>
                            <Box className="paragraph"><span className="paragraph-number">4.6. </span><span
                                className="underline">Relationship of Parties</span>.
                                Client and Coach intend and agree that Coach, in performing his
                                duties under this Agreement, may be an independent contractor, as defined by
                                the
                                guidelines promulgated by the United States Internal Revenue Service and/or
                                the United
                                States Department of Labor, and the independent contractor shall have
                                exclusive control
                                of his work and the manner in which it is performed.
                            </Box>
                            <Box className="paragraph"><span className="paragraph-number">4.7. </span><span
                                className="underline">Legal Significance</span>.
                                Client acknowledges that this Agreement is a legal document and
                                creates certain rights and responsibilities. Client also acknowledges having
                                had a
                                reasonable time to seek legal advice regarding the Agreement and has either
                                chosen not
                                to do so or has done so and is satisfied with the terms and conditions of
                                the Agreement.
                            </Box>
                            <Box className="paragraph"><span className="paragraph-number">4.8. </span><span
                                className="underline">Entire Agreement</span>.
                                This Agreement contains the entire agreement between the parties
                                and supersedes all prior oral and written understandings and agreements
                                regarding the
                                subject matter of this Agreement.
                            </Box>
                            <Box className="paragraph"><span className="paragraph-number">4.9. </span><span
                                className="underline">Jurisdiction and Venue</span>.
                                This Agreement shall be governed by and construed in
                                accordance with the internal laws of the Commonwealth of Kentucky without
                                giving
                                effect to any choice or conflict of law provision or rule. Each party
                                irrevocably submits
                                to the exclusive jurisdiction and venue of the federal and state courts
                                located in the
                                following county in any legal suit, action, or proceeding arising out of or
                                based upon this
                                Agreement or the Services provided hereunder: Fayette County, Kentucky.
                            </Box>
                            <Box className="paragraph"><span className="paragraph-number">4.10. </span><span
                                className="underline">Service</span>.
                                All written notices are deemed served if sent to the address of the party
                                written
                                above or appearing in Exhibit A by first class U.S. mail.
                            </Box>
                            <Box className="paragraph"><span className="paragraph-number">4.11. </span><span
                                className="underline">Data Aggregation for Research</span>.
                                By agreeing to this form, you release Coach to utilize
                                deidentified demographic, results, or survey data for research purposes.
                            </Box>
                            <Box className="paragraph"><span className="paragraph-number">4.12. </span><span
                                className="underline">Portfolio Use</span>.
                                Coach shall be permitted to use all produced items of work Coach's
                                professional portfolio, if applicable, but may not use Client's name,
                                likeness, or other
                                identifying details without express written permission from the Client.
                            </Box>
                            <Box className="paragraph"><span className="paragraph-number">4.13. </span><span
                                className="underline">Liability</span>.
                                Coach’s liability in contract, tort or otherwise arising through or in
                                connection with this Agreement or through or in connection with the
                                completion of
                                obligations under this Agreement shall be limited to Fees paid by the Client
                                to the Coach.
                                To the extent it is lawful, neither Party shall be liable to the other Party
                                in contract, tort,
                                negligence, breach of statutory duty or otherwise for any loss, damage,
                                costs or expenses
                                of any nature whatsoever incurred or suffered by that other Party of an
                                indirect or
                                consequential nature including without limitation any economic loss, data
                                loss, loss of
                                goodwill, or other loss of turnover, profits, or business.
                            </Box>
                        </Box>
                    </Box>
                    <Box mt={2}>
                        <Box className="indent">
                            By agreeing I am entering into this Agreement with Coach on the date indicated after my
                            signature (“Effective Date”). I acknowledge and agree that all my questions have been
                            answered and I understand the terms outlined in the Agreement and any attachment hereto.
                        </Box>
                    </Box>
                    <Box mt={5} textAlign="center">
                        <span className="agreement-title">EXHIBIT A</span>
                    </Box>
                    <Box textAlign="center">
                        <span
                            className="agreement-title">AUTOMATIC DEBIT/ CREDIT CARD BILLING AUTHORIZATION
                        </span>
                    </Box>
                    <Box mt={2}>
                        <Box className="indent">
                            By agreeing you authorize Coach to automatically bill amounts owed pursuant to the fee
                            schedule for services provided to You by Coach, either monthly as owed or after sixty (60)
                            days following non-payment by another form, in Coach’s sole discretion and pursuant to the
                            terms of the Agreement. When automatically billed, a receipt for each payment will be
                            provided to you and a charge will appear on your credit card statement.
                        </Box>
                    </Box>
                    <Box mt={2}>
                        <Box className="text-bold">Payment Information</Box>
                        Start billing on: Effective Date of Agreement; reoccurring monthly.
                    </Box>
                    <Box mt={2}>
                        <Box className="indent">
                            I understand that this authorization will remain in effect until I cancel it in writing and
                            agree to notify Coach in writing of any changes in my account information or termination of
                            this authorization within fifteen (15) days prior to the next billing date. I understand
                            that payments may be executed on the next business day. I acknowledge that the origination
                            of credit card transactions to my account must comply with provisions of federal, state, and
                            local laws. I certify that I am an authorized user of this credit card and will not dispute
                            these scheduled transactions; so long as the transactions correspond to the terms indicated
                            in the Agreement and this Authorization Form.
                        </Box>
                    </Box>
                </Box>
            </Box>
        </AgreementBasePage>
    );
}