import { useEffect, useState } from 'react';
import { useHistory } from "react-router";
import { Subscription } from 'rxjs';
import { onEmit } from '../../../common/helpers/on-emit';
import { patientStore, patientsQuery } from '../../stores/patientsStore';
import { patientsService } from '../../services/patients.service';
import { authQuery } from '../../../auth/stores/auth';
import { navigationService } from '../../../../services/navigation.service';

interface PatientJourneyTasksComponentState {
    isLoading: boolean;
    selectedTab: number;
}

export function useFacade(patientId: number | null, selectedTab: number | null): [
    PatientJourneyTasksComponentState,
    (tab: number) => void,
] {
    const _history = useHistory();
    const [state, setState] = useState({
        isLoading: true,
        selectedTab: selectedTab ?? 0,
    } as PatientJourneyTasksComponentState);

    patientStore.setTab(selectedTab ?? 0);

    const handleChangeTab = (tab: number) => {
        patientStore.setTab(tab);
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<number>(patientsQuery.tab$, tab =>
                setState(state => ({...state, selectedTab: tab}))
            ),
        ];

        const cb = () => setState(state => ({...state, isLoading: false}))

        const isPatientPremium = patientId ? patientsQuery.getTargetPatientIsPremium() : authQuery.getIsPremium();
        const hasActiveStandardPlan = patientId ? patientsQuery.getTargetPatientSubscription()?.isActive && patientsQuery.getTargetPatientSubscription()?.paymentPlan?.displayName === "Standard" : authQuery.isSubscriptionStatusActive() && authQuery.getPlan() === "Standard";
        if (isPatientPremium || !hasActiveStandardPlan) {
            if (patientId) {
                return navigationService.toPatientDashboard(_history, patientId);
            }
            return navigationService.toDashboard(_history);
        }

        if (patientId) {
            patientsService.getPatientJourneyTasksByEmployee(patientId).subscribe(cb, cb)
        } else {
            if (authQuery.isPatientUser()) {
                patientsService.getPatientJourneyTasks().subscribe(cb, cb)
            } else {
                navigationService.toDashboard(_history);
            }
        }

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [
        state,
        handleChangeTab
    ];
}