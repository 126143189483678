import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontFamily: 'Be Vietnam Pro',
            fontSize: '1.6rem',
            fontWeight: 500,
            lineHeight: 1.18,
            letterSpacing: 0.17,
            color: '#292929 !important'
        },
        list: {
            width: '100%',
            maxWidth: 1070,
        },
        subTitle: {
            fontFamily: 'Be Vietnam Pro',
            fontSize: 16,
            fontWeight: 500,
            lineHeight: 1.38,
            letterSpacing: 0.8,
        },
        sectionTitle: {
            fontFamily: 'Be Vietnam Pro',
            fontSize: 16,
            fontWeight: 500,
            lineHeight: 1.38,
            letterSpacing: 0.8,
            color: 'rgba(0, 0, 0, 0.56)',
        },
        regular: {
            fontFamily: 'Be Vietnam Pro',
            fontSize: 15,
            fontWeight: 300,
            lineHeight: 1.33,
            letterSpacing: 0.15,
        },
        fileButton: {
            width: 100,
            height: 100,
            "&:hover": {
                "& $fileIcon": {
                    display: "none",
                },
                "& $downloadIcon": {
                    display: "inline-block",
                },
            },
        },
        fileIcon: {
            width: 80,
            height: 80,
            color: colors.gray2,
            display: 'inline-block',
        },
        downloadIcon: {
            width: 80,
            height: 80,
            color: colors.gray2,
            display: 'none',
        },
        divider: {
            backgroundColor: "#f9f9fb",
            margin: 'auto',
            height: 2,
        },
        internalNote: {
            // wordBreak: 'break-all',
            display: 'flex',
            flexWrap: 'wrap',
        },
        attachments: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            backgroundColor: theme.palette.background.paper,
        },
        icon: {
            color: 'white',
        },
        renderText: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        colorMain: {
            color: colors.main
        },
    })
);
