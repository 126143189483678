import { Observable, Subject } from "rxjs";
import { CallbackModel, CallbackSimpleModel } from "../modules/common/models/callback.model"


interface ConfirmViewModel {
    title: string,
    message: string,
    acceptTitle: string,
    cancelTitle: string,
    variant: 'default' | 'danger'
}

interface ConfirmInfoViewModel {
    title: string,
    message: string,
    image: string,
    acceptTitle: string,
    variant: 'default' | 'danger'
}

interface ConfirmAlertViewModel {
    title: string,
    message: string,
    acceptTitle: string,
    variant: 'default' | 'danger'
}

interface ConfirmWithTitleViewModel {
    title: string,
    message: string,
    placeholder: string;
    acceptTitle: string,
    reason: string,
}

interface ConfirmWithSelectedModel {
    title: string,
    message: string,
    initialChoise: string;
    acceptTitle: string,
    newChoise: string,
}

interface ConfirmWithSelectedOneModel {
    title: string,
    message: string,
    acceptTitle: string,
    newChoice: string,
    options: Map<number, string>
}

interface ConfirmInsurance {

}

export class ConfirmService {
    public onQuestion = new Subject<CallbackModel<ConfirmViewModel>>();
    public onQuestionInfo = new Subject<CallbackModel<ConfirmInfoViewModel>>();
    public onQuestionWithReason = new Subject<CallbackModel<ConfirmWithTitleViewModel>>();
    public onQuestionWithSelected = new Subject<CallbackModel<ConfirmWithSelectedModel>>();
    public onQuestionWithSelectedOne = new Subject<CallbackModel<ConfirmWithSelectedOneModel>>();
    public onQuestionInsurance = new Subject<CallbackSimpleModel<ConfirmInsurance>>();
    public onQuestionAlert = new Subject<CallbackModel<ConfirmAlertViewModel>>();

    public confirm(title: string, message?: string, acceptTitle?: string, cancelTitle?: string, variant?: 'default' | 'danger'): Observable<boolean> {
        return new Observable(observer => {
            this.onQuestion.next({
                data: { title: title, message: message, acceptTitle: acceptTitle, cancelTitle: cancelTitle, variant: variant },
                callback: (result: boolean) => {
                    if (result) {
                        observer.next();
                    }
                    observer.complete();
                }
            });
        });
    }

    public discard(title: string, message?: string): Observable<boolean> {
        return new Observable(observer => {
            this.onQuestion.next({
                data: { title: title, message: message, acceptTitle: 'Cancel Request', cancelTitle: 'Discard', variant: 'danger' },
                callback: (result: boolean) => {
                    if (result) {
                        observer.next();
                    }
                    observer.complete();
                }
            });
        });
    }

    public confirmInsurance(): Observable<boolean> {
        return new Observable(observer => {
            this.onQuestionInsurance.next({
                callback: (result: boolean) => {
                    if (result) {
                        observer.next();
                    } else {
                        observer.error()
                    }
                    observer.complete();
                }
            });
        });
    }

    public confirmInfo(title: string, message?: string, image?: string, acceptTitle?: string, variant?: 'default' | 'danger'): Observable<boolean> {
        return new Observable(observer => {
            this.onQuestionInfo.next({
                data: { title: title, message: message, image: image, acceptTitle: acceptTitle, variant: variant },
                callback: (result: boolean) => {
                    if (result) {
                        observer.next();
                    }
                    observer.complete();
                }
            });
        });
    }

    public confirmWithReason(title: string, message?: string, placeholder?: string, acceptTitle?: string, reason?: string): Observable<string> {
        return new Observable(observer => {
            this.onQuestionWithReason.next({
                data: { title: title, message: message, placeholder: placeholder, acceptTitle: acceptTitle, reason: reason },
                callback: (result: boolean, reason: string) => {
                    if (result) {
                        observer.next(reason);
                    }
                    observer.complete();
                }
            });
        });
    }

    public confirmAndSelected(title: string, message?: string, initialChoise?: string, acceptTitle?: string, newChoise?: string): Observable<string> {
        return new Observable(observer => {
            this.onQuestionWithSelected.next({
                data: { title: title, message: message, initialChoise: initialChoise, acceptTitle: acceptTitle, newChoise: newChoise },
                callback: (result: boolean, newChoise: string) => {
                    if (result) {
                        observer.next(newChoise);
                    }
                    observer.complete();
                }
            });
        });
    }

    public confirmAndSelectedNoteViewType(title: string, options?: Map<number, string>, message?: string, acceptTitle?: string, newChoice?: string): Observable<number> {
        return new Observable(observer => {
            this.onQuestionWithSelectedOne.next({
                data: {
                    title: title,
                    options: options,
                    message: message,
                    acceptTitle: acceptTitle,
                    newChoice: newChoice
                },

                callback: (result: boolean, newChoise: string) => {
                    if (result) {
                        observer.next(+newChoise);
                    }
                    observer.complete();
                }
            });
        });
    }

    public alert(title: string, message?: string, acceptTitle?: string, variant?: 'default' | 'danger'): Observable<boolean> {
        return new Observable(observer => {
            this.onQuestionAlert.next({
                data: { title: title, message: message, acceptTitle: acceptTitle, variant: variant },
                callback: (result: boolean) => {
                    if (result) {
                        observer.next();
                    }
                    observer.complete();
                }
            });
        });
    }
}

export const confirmService = new ConfirmService();