const getId = () => Math.floor(Math.random() * 10000)

export const getKey = (key: string): string => key.split('-')[0]

export const buildKey = (sectionKey: string, subSection: string = null): string => 
    subSection
        ? `${sectionKey}-${subSection}-${getId()}`
        : `${sectionKey}-${getId()}`


export const onlyUnique = (val, i, self) => self.indexOf(val) === i