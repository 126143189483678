import { Button, Theme, withStyles } from "@material-ui/core";
import { colors } from "../../constants/colors";

export const AccentButton = withStyles((theme: Theme) => ({
    root: {
        color: colors.white,
        backgroundColor: colors.main,
        border: `1px solid ${colors.main}`,
        textTransform: 'none',
        borderRadius: 2,
        '&:hover': {
            backgroundColor: `${colors.mainDark}`,
            border: `1px solid ${colors.mainDark}`,
        },
        '&:disabled': {
            color: colors.gray2,
            backgroundColor: colors.disabled,
            border: `1px solid ${colors.disabled}`,
        },
    },
}))(Button);
