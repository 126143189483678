import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { onEmit } from "../../../common/helpers/on-emit";
import { getAvailablePages } from "../../../common/pagination/helpers/get-evailable-pages";
import { pageSizes } from "../../../common/pagination/models/page-sizes";
import { IPaginationState } from "../../../common/pagination/models/pagination-state";
import { GoalModel, GoalsFilterState } from "../../../notes/models/notes.models";
import { goalsService } from "../../../notes/services/goals.service";
import { notesQuery, notesStore } from "../../../notes/stores/notes";
import { useHistory } from 'react-router';


interface HealthPlanPastGoalsComponentState extends IPaginationState {
    isLoading: boolean;
    goals: GoalModel[];
    page: number;
    rowsPerPage: number;
    searchQuery: string;
}

export function useFacade(patientId?: number, isMobile?: boolean): [
    HealthPlanPastGoalsComponentState,
    (query: string) => void,
    () => number[],
    (value: any) => void,
    (page: number) => void,
    () => void,
] {
    const [state, setState] = useState({
        isLoading: true,
        goals: [],
        page: 0,
        rowsPerPage: isMobile ? '' : pageSizes[0],
        totalCount: 0,
        selectedPage: 1,
        pageSize: isMobile ? '' : pageSizes[0],
        searchQuery: '',
    } as HealthPlanPastGoalsComponentState);
    const history = useHistory();

    const handleGoBack = () => {
        notesStore.setGoalsTab(GoalsFilterState.TopGoals)
        history.goBack();
    }

    const getAllAvailablePages = () => {
        return getAvailablePages(state);
    }

    const handlePageSizeChange = (value: any) => {
        if (value === state.pageSize) {
            return;
        }
        setState(state => ({
            ...state,
            pageSize: value,
            selectedPage: 1,
            isLoading: true,
        }));
        getPastGoal(state.searchQuery, 0, value)
    }

    const handlePageChange = (page: number) => {
        if (page === state.selectedPage) {
            return;
        }
        setState(state => ({
            ...state,
            selectedPage: page,
            isLoading: true
        }));
        getPastGoal(state.searchQuery, page - 1, state.pageSize)
    }

    const handleSearchSubmit = (query = '') => {
        setState(state => ({ ...state, isLoading: true, searchQuery: query }));
        getPastGoal(query, 0, state.pageSize)
    }

    const getPastGoal = (query: string, skip: number, take: number) => {
        const cb = () => setState((state) => ({ ...state, isLoading: false }));

        if (patientId) {
            goalsService.getPastGoalsByEmployee(patientId, query, skip * take, take).subscribe(cb, cb);
            cb()
        } else {
            goalsService.getPastGoalsByPatient(query, skip * take, take).subscribe(cb, cb);
            cb()
        }
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<GoalModel[]>(
                notesQuery.pastGoals$,
                (goals) => {
                    setState((state) => ({
                        ...state,
                        goals: goals,
                    }));
                }
            ),
            onEmit<number>(
                notesQuery.pastGoalsTotal$,
                (pastGoalsTotal) => {
                    setState((state) => ({
                        ...state,
                        totalCount: pastGoalsTotal,
                    }));
                }
            ),
        ];


        getPastGoal('', state.selectedPage - 1, state.pageSize,)

        return () => {
            subscriptions.map((it) => it.unsubscribe());
        };
    }, [patientId]);

    return [
        state,
        handleSearchSubmit,
        getAllAvailablePages,
        handlePageSizeChange,
        handlePageChange,
        handleGoBack,
    ];
}