import { HealthSummaryDataModel } from '../models/healthSummary.models'
import { healthSummaryStore, HealthSummaryStore } from '../stores'
import { Observable } from 'rxjs'
import {prescriptionService} from "../../patientSupplements/services/prescription.service";

export class HealthSummaryRxntMedicationsService {
    public mapKey = 'MEDICATIONS'

    constructor(private healthSummaryStore: HealthSummaryStore) {
    }

    public get(patientId?: number): void {
        const method = patientId
            ? prescriptionService.getPatientActiveMeds(patientId)
            : prescriptionService.getMyActiveMeds()

        method.subscribe((medications) => {
            const medicationsData = medications.map((x, index) => ({
                    key: `${this.mapKey}-${index}`,
                    name: x.name,
                    value: x.dosingInstructions}
            ) as HealthSummaryDataModel)

            this.healthSummaryStore.sectionLoaded()
            this.healthSummaryStore.clearSection(this.mapKey)
            this.healthSummaryStore.setData(medicationsData)
        }, () => {
            this.healthSummaryStore.sectionLoaded()
            this.healthSummaryStore.clearSection(this.mapKey)
            this.healthSummaryStore.setData([])
        })
    }

    public create(patientId: number, model: HealthSummaryDataModel): Observable<any> {
        return null;
    }

    public update(patientId: number, model: HealthSummaryDataModel): Observable<any> {
        return null;
    }

    public delete(key: string): void {

    }
}

export const healthSummaryRxntMedicationsService = new HealthSummaryRxntMedicationsService(healthSummaryStore)