import React from 'react';
import './CardioTimerComponent.scss';
import ReplayIcon from '@material-ui/icons/Replay';
import ClearIcon from '@material-ui/icons/Clear';
import { Box, Button } from "@material-ui/core";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";

export class CardioTimerComponent extends React.Component {
    leftSeconds: number = 0;
    totalSeconds: number;
    isRunning: boolean;
    timer: any;

    start = (seconds: number) => {
        this.isRunning = true;
        if (seconds) {
            clearTimeout(this.timer);
            this.totalSeconds = seconds;
            this.leftSeconds = seconds;
            this.updateState();
        }

        this.timer = setTimeout(() => {
            this.setState({
                leftSeconds: --this.leftSeconds,
            });
            if (this.leftSeconds !== 0) {
                this.start(null);
            } else {
                this.disable();
            }
        }, 1000);
    }

    reset = () => {
        this.leftSeconds = this.totalSeconds;
        this.isRunning = true;
        this.start(this.totalSeconds);
        this.updateState();
    }

    disable = () => {
        clearTimeout(this.timer);
        this.isRunning = false;
        this.leftSeconds = 0;
        this.updateState();
    }

    updateState() {
        this.setState({
            leftSeconds: this.leftSeconds,
            isRunning: this.isRunning,
            totalSeconds: this.totalSeconds
        });
    }

    sec2time(seconds): any {
        let secondsPart = seconds % 60;
        let minutesPart = Math.floor(seconds / 60);
        let secondsOutput = secondsPart < 10 ? "0" + secondsPart : secondsPart;
        let minutesOutput = minutesPart < 10 ? "0" + minutesPart : minutesPart;
        return minutesOutput + " : " + secondsOutput;
    }

    render() {
        return (
            <>
                <Box mt={3} display="flex" justifyContent="center">
                    <Box mr={2}>
                        <WildHealthButton
                            id="cardio-timer-start-test"
                            onClick={() => { this.start(45) }}
                        >
                            Start Test
                        </WildHealthButton>
                    </Box>
                    <Box ml={2}>
                        <WildHealthButton
                            id="cardio-timer-start-rest"
                            onClick={() => { this.start(60) }}
                        >
                            Start Rest
                        </WildHealthButton>
                    </Box>
                </Box>
                <Box className={this.isRunning ? 'cardio-timer in-progress' : 'cardio-timer'} display="flex"
                    justifyContent="center">
                    <Box mt={3}>
                        <Box className='clock-face'>
                            <Box className="clock-face-content">{this.sec2time(this.leftSeconds)}</Box>
                        </Box>

                        <Box mt={1} display="flex" justifyContent="center">
                            <Box mr={2}>
                                <Button className="reset-button" disabled={!this.isRunning} onClick={this.reset}>
                                    <ReplayIcon />
                                </Button>
                            </Box>
                            <Box ml={2}>
                                <Button className="disable-button" disabled={!this.isRunning} onClick={this.disable}>
                                    <ClearIcon style={{ color: this.isRunning ? "#f1f1f1" : "#aaaaaa" }} />
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </>
        );
    }
}