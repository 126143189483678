import React from 'react';
import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { QuestionComponent } from '../questionComponent/QuestionComponent';
import { QuestionnaireSurveyProps } from "../../pages/initialQuestionnaireSurveyComponent/pages/questionnaireSurvey.props";
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from 'clsx';
import { Gender } from "../../../common/models/user.models";
import { isGenderMatch } from "../../helpers/is-gender-match";
import { ReactComponent as InfoIcon } from "@img/icons/info.svg";

export interface QuestionnaireSectionComponentProps extends QuestionnaireSurveyProps {
    gender: Gender;
    questions: string[];
    questionComponents?: { [id: string]: JSX.Element };
    questionRequirements?: { [id: string]: () => boolean };
    hideSubject?: boolean;
    disabled?: boolean;
}

export const QuestionnaireSectionComponent: React.FC<QuestionnaireSectionComponentProps> = (props: QuestionnaireSectionComponentProps) => {
    const {
        errors,
        result,
        gender,
        questions,
        questionnaire,
        handleChanges,
        questionComponents = {},
        questionRequirements = {},
        hideSubject = false,
        disabled = false
    } = props;

    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const subject = questionnaire.questions.find(x => questions.some(q => q === x.name))?.questionSubject;

    const renderQuestion = (question: string, key?: string): JSX.Element => {
        const parentQuestion = questionnaire.questions.find(x => x.childrenNames.includes(question));
        const requirement = questionRequirements[question];
        const parentRequirement = questionRequirements[parentQuestion?.name];
        const showInfoContainer = question === 'TETANUS_VACCINE' || question === 'PNEUMONIA_VACCINE' || question === 'FLU_VACCINE_SEASON';

        if ((!requirement || requirement() === true) && (!parentRequirement || parentRequirement() === true)) {

            const originQuestion = questionnaire.questions.find(x => x.name === question);

            if (!isGenderMatch(originQuestion, gender)) {
                return <React.Fragment key={`empty-gender-${key}`} />;
            }

            const component = questionComponents[question];

            return component ?? (
                <>
                    {isSmallScreen && showInfoContainer && (
                        <>
                            <Box display="flex" alignItems="center" className={commonClasses.infoContainer}>
                                <InfoIcon />
                                <Box width={1} pl="14px" className={clsx(commonClasses.size14, commonClasses.colorWhite, commonClasses.textRegular)}>
                                    {question === 'TETANUS_VACCINE' && 'Your next tetanus is recommended to be done in July, 2031.'}
                                    {(question === 'PNEUMONIA_VACCINE' || question === 'FLU_VACCINE_SEASON') && 'Your next pneumonia vaccine is recommended to be done in July, 2025.'}
                                </Box>
                            </Box>
                        </>
                    )}
                    <Box my={2} key={key}>
                        <QuestionComponent
                            disabled={disabled}
                            inline={false}
                            error={errors[question]}
                            answer={result?.answers?.find(x => x.key === question)?.value || null}
                            handleChanges={(value) => handleChanges(question, value)}
                            question={questionnaire.questions.find(x => x.name === question)}
                        />
                    </Box>
                </>
            )
        }

        return (<React.Fragment key={`empty-${key}`} />);
    }

    return (
        <Box>
            {
                !hideSubject &&
                <Box my={2}>
                    <span className={clsx(commonClasses.size20, commonClasses.textMedium)}>{subject}</span>
                </Box>
            }
            {
                questions.map((question, index) => (
                    renderQuestion(question, index.toString())
                ))
            }
        </Box>
    )
}