import moment from "moment";
import pdfMake from 'pdfmake/build/pdfmake';
import {Margins, PageSize} from 'pdfmake/interfaces';
import {logoService} from "../../../services/logo.service";
import {authQuery} from "../../auth/stores/auth";
import {pinyonFont} from "../../../styles/Pinyon-Script";
import { PatientModel, PatientPharmacyInfoModel } from "../../patients/models/patient.model";
import { Gender } from "../../common/models/user.models";
import { CoverageModel } from "../../insurance/models/insurance.models";

const pdfMakeX = require('pdfmake/build/pdfmake.js');
const pdfFontsX = require('pdfmake-unicode/dist/pdfmake-unicode.js');

pdfMakeX.vfs = pdfFontsX.pdfMake.vfs;
window.pdfMake.vfs["Pinyon-Script.ttf"] = pinyonFont;

pdfMake.fonts = {
    Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-Italic.ttf'
    },
    PinyonScript: {
        normal: 'Pinyon-Script.ttf',
        bold: 'Pinyon-Script.ttf',
        italics: 'Pinyon-Script.ttf',
        bolditalics: 'Pinyon-Script.ttf'
    }
}

export abstract class PdfService {
    protected pdfDocument = {
        pageSize: "LETTER" as PageSize,
        pageMargins: [60, 40, 60, 50] as Margins,
        header: null,
        footer: function (currentPage, pageCount) {
            if (currentPage === pageCount) {
                const result = {
                    table: {
                        widths: ['*'],
                        body: []
                    },
                    layout: 'noBorders'
                }

                if (authQuery.isDefaultPractice()) {
                    result.table.body.push([
                        {
                            text: 'p: 800.975.1562 | e: info@wildhealth.com | a: 535 Wellington Way # 330 Lexington KY 40503',
                            alignment: 'center',
                            style: 'footerText',
                        }

                    ]);
                }

                result.table.body.push([{
                    text: [
                        {
                            text: 'Privacy Policy',
                            style: 'footerText',
                            link: 'https://www.wildhealth.com/privacy-policy'
                        },
                        {
                            text: ' | ',
                            style: 'footerText'
                        },
                        {
                            text: 'Terms of Use',
                            style: 'footerText',
                            link: 'https://www.wildhealth.com/terms-of-use'
                        },
                        {
                            text: ' | ',
                            style: 'footerText'
                        },
                        {
                            text: 'Copyright ' + new Date().getFullYear(),
                            style: 'footerText'
                        },

                    ],
                    alignment: 'center',
                    style: 'footerText'
                }]);

                return result;
            }
        },
        content: [],
        styles: {
            invoiceTitle: {
                fontFamily: 'Roboto',
                fontWeight: 'bold',
                fontSize: 24,
            },
            invoiceSubTitle: {
                fontFamily: 'Open Sans',
                fontWeight: 600,
                fontSize: 18,
            },
            companyTitle: {
                color: '#7C8A9D',
                fontFamily: 'Roboto',
                fontWeight: 400,
                fontSize: 14,
            },
            companySubTitle: {
                fontFamily: 'Roboto',
                fontWeight: 500,
                fontSize: 16,
            },
            tableHeader: {
                fontFamily: 'Roboto',
                fontWeight: 500,
                fontSize: 14,
                color: '#AFBACA',
            },
            tableValue: {
                fontFamily: 'Roboto',
                fontWeight: 500,
                fontSize: 14,
                color: '##091D36',
            },
            total: {
                fontFamily: 'Open Sans',
                fontWeight: 600,
                fontSize: 24,
                padding: '10px 0px',
            },
            signedByText: {
                font: 'PinyonScript',
                fontWeight: 'normal',
                fontSize: 24,
            },
        },
    }

    constructor() {
        const self = this;
        var image = new Image();
        image.src = logoService.getLogo().clarityHeader;
        image.onload = function () {
            var canvas, ctx, dataURL, base64;
            canvas = document.createElement("canvas");
            ctx = canvas.getContext("2d");
            canvas.width = image.width;
            canvas.height = image.height;
            ctx.drawImage(image, 0, 0);
            dataURL = canvas.toDataURL("image/png");
            base64 = dataURL.replace(/^data:image\/png;base64,/, "");

            self.pdfDocument.header = function (currentPage) {
                if (currentPage === 1)
                    return [
                        {
                            image: `data:image/jpeg;base64,${base64}`,
                            fit: [615, 100],
                        }
                    ]
            }
        };
    }

    protected clear() {
        this.pdfDocument.content = [];
    }
}


export class DemographicPdfService extends PdfService {

    public download(patientInfo: PatientModel, pharmacyInfo: PatientPharmacyInfoModel, isPayByCash: boolean, coverage: CoverageModel) {
        this.buildPdfContent(patientInfo, pharmacyInfo, isPayByCash, coverage)
        pdfMake.createPdf(this.pdfDocument).download(`Demographic-Sheet-${patientInfo.id}`);
    }

    private buildPdfContent(patientInfo: PatientModel, pharmacyInfo: PatientPharmacyInfoModel, isPayByCash: boolean, coverage: CoverageModel) {
        this.clear();

        const body = [
            [
                [
                    {
                        margin: [0, 70, 0, 0],
                        text: 'Demographic Sheet',
                        style: 'invoiceTitle',
                    },

                    {
                        table: {
                            widths: ['*', '*'],
                            body: [
                                [
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: 'Patient Full Name:',
                                            style: 'companyTitle',
                                        }
                                    ],
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: `${patientInfo.firstName} ${patientInfo.lastName}`,
                                            style: 'companySubTitle',
                                        },
                                    ]
                                ],
                                [
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: 'Date of Birth:',
                                            style: 'companyTitle',
                                        }
                                    ],
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: moment(patientInfo.birthday).format('L'),
                                            style: 'companySubTitle',
                                        },
                                    ]
                                ],
                                [
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: 'Biological Gender:',
                                            style: 'companyTitle',
                                        }
                                    ],
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: Gender[patientInfo.gender],
                                            style: 'companySubTitle',
                                        },
                                    ]
                                ],
                                [
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: 'Shipping Address:',
                                            style: 'companyTitle',
                                        }
                                    ],
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: `${patientInfo.shippingAddress.streetAddress1}, ${patientInfo.shippingAddress.city}, ${patientInfo.shippingAddress.state}`,
                                            style: 'companySubTitle',
                                        },
                                    ]
                                ],
                                [
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: 'Phone Number:',
                                            style: 'companyTitle',
                                        }
                                    ],
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: patientInfo.phoneNumber,
                                            style: 'companySubTitle',
                                        },
                                    ]
                                ],
                                [
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: 'Email:',
                                            style: 'companyTitle',
                                        }
                                    ],
                                    [
                                        {
                                            margin: [0, 10, 0, 0],
                                            text: patientInfo.email,
                                            style: 'companySubTitle',
                                        },
                                    ]
                                ],
                            ],
                        },
                        layout: 'noBorders'
                    },
                ]
            ],
        ];

        if (isPayByCash) {
            body[0][0][1].table.body.push(
                [
                    [
                        {
                            margin: [0, 10, 0, 0],
                            text: 'Insurance:',
                            style: 'companyTitle',
                        }
                    ],
                    [
                        {
                            margin: [0, 10, 0, 0],
                            text: 'Cash Pay Only',
                            style: 'companySubTitle',
                        },
                    ]
                ]
            )
        } else {
            body[0][0][1].table.body.push(
                [
                    [
                        {
                            margin: [0, 10, 0, 0],
                            text: 'Insurance Name:',
                            style: 'companyTitle',
                        }
                    ],
                    [
                        {
                            margin: [0, 10, 0, 0],
                            text: coverage?.insurance?.name ?? '',
                            style: 'companySubTitle',
                        },
                    ],
                ],
                [
                    [
                        {
                            margin: [0, 10, 0, 0],
                            text: 'Policy Holder Name:',
                            style: 'companyTitle',
                        }
                    ],
                    [
                        {
                            margin: [0, 10, 0, 0],
                            text: `${coverage?.policyHolder?.firstName ?? ''} ${coverage?.policyHolder?.lastName ?? ''}`,
                            style: 'companySubTitle',
                        },
                    ],
                ],
                [
                    [
                        {
                            margin: [0, 10, 0, 0],
                            text: 'Policy Number:',
                            style: 'companyTitle',
                        }
                    ],
                    [
                        {
                            margin: [0, 10, 0, 0],
                            text: coverage?.memberId ?? '',
                            style: 'companySubTitle',
                        },
                    ]
                ]
            )
        }

        body[0][0].push(
            {
                margin: [0, 20, 0, 0],
                text: 'Pharmacy Info',
                style: 'invoiceSubTitle',
            },

            {
                table: {
                    widths: ['*', '*'],
                    body: [
                        [
                            [
                                {
                                    margin: [0, 10, 0, 0],
                                    text: 'Address:',
                                    style: 'companyTitle',
                                }
                            ],
                            [
                                {
                                    margin: [0, 10, 0, 0],
                                    text: pharmacyInfo.streetAddress,
                                    style: 'companySubTitle',
                                },
                            ]
                        ],
                        [
                            [
                                {
                                    margin: [0, 10, 0, 0],
                                    text: 'City:',
                                    style: 'companyTitle',
                                }
                            ],
                            [
                                {
                                    margin: [0, 10, 0, 0],
                                    text: pharmacyInfo.city,
                                    style: 'companySubTitle',
                                },
                            ]
                        ],
                        [
                            [
                                {
                                    margin: [0, 10, 0, 0],
                                    text: 'Zip Code:',
                                    style: 'companyTitle',
                                }
                            ],
                            [
                                {
                                    margin: [0, 10, 0, 0],
                                    text: pharmacyInfo.zipCode,
                                    style: 'companySubTitle',
                                },
                            ]
                        ],
                        [
                            [
                                {
                                    margin: [0, 10, 0, 0],
                                    text: 'State:',
                                    style: 'companyTitle',
                                }
                            ],
                            [
                                {
                                    margin: [0, 10, 0, 0],
                                    text: pharmacyInfo.state,
                                    style: 'companySubTitle',
                                },
                            ]
                        ],
                        [
                            [
                                {
                                    margin: [0, 10, 0, 0],
                                    text: 'Country:',
                                    style: 'companyTitle',
                                }
                            ],
                            [
                                {
                                    margin: [0, 10, 0, 0],
                                    text: pharmacyInfo.country,
                                    style: 'companySubTitle',
                                },
                            ]
                        ]
                    ],
                },
                layout: 'noBorders'
            },
        )

        this.pdfDocument.content.push({
            table: {
                margin: [0, 30, 0, 0],
                widths: ['*', '150'],
                body: body,
            },
            layout: 'noBorders'
        });
    }
}


export const demographicPdfService = new DemographicPdfService();