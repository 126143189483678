import { AnswerModel } from "../models/questionnaire.models";

export const hasAnswer = (answers: AnswerModel[], question: string, value: string = null) => {
    const answer = answers.find(x => x.key === question);
    try {
        const data = JSON.parse(answer.value);
        
        if(!data.v.length && !data.o.length)
        {
            return false;
        }
        return value === null || data.v.includes(value);

    } catch {
        if(!answer) {
            return false;
        }

        const isEqual = !Boolean(value) || answer.value === value;

        return answer.value !== null && answer.value !== '' && isEqual;
    }
}