import { Box, Grid } from "@material-ui/core";
import React from "react";

interface AgreementBasePageProps {
    children: React.ReactNode;
}

export const AgreementBasePage: React.FC<AgreementBasePageProps> = (props: AgreementBasePageProps) => {
    const {
        children
    } = props;

    return (
        <>
            <Box>
                <Grid container
                    direction="column"
                    justify="center"
                    alignItems="center"
                >
                    <Grid item md={11} sm={12}>
                        {children}
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}