import { usTimeZoneAbbrs } from "../constants/timezone.constants";
import { TimeZoneModel } from "../models/timezone.model";
import { timezonesQuery } from "../stores/timezones";
import moment from "moment-timezone";


export const getTimeZoneName = (timezone?: string): string => {
    const thisTimeZone = timezone || moment.tz.guess();

    const abbr = moment().tz(thisTimeZone).zoneAbbr();
    return usTimeZoneAbbrs[abbr] || thisTimeZone;
};

export const getCurrentTimezone = (timeZoneName: string = null): TimeZoneModel => {
    const thisTimeZoneName = timeZoneName || getTimeZoneName();
    const timezones = timezonesQuery.getTimezones();

    return timezones.find(x => x.standardName === thisTimeZoneName || x.daylightName === thisTimeZoneName);
}

export const displayTimeZone = (timeZone: TimeZoneModel): string => {
    return timeZone
        ? timeZone.displayName
        : moment.tz.guess();
}

export const displayShortTimeZone = (timeZone: TimeZoneModel): string => {
    return timeZone
        ? timeZone.standardName
            .split(" ")
            .map(x => x.slice(0, 1))
            .join("")
            .trim()
        : moment.tz.guess();
}

export const toTimeZoneDate = (date: Date, timeZone: TimeZoneModel): Date => {
    return timeZone ? moment.utc(date).tz(timeZone.olsonId).toDate() : date;
}

export const toCurrentTimeZone = (date: Date): Date => {
    return moment.utc(date).tz(moment.tz.guess()).toDate();
}

export const displayTimePatientTimeZone = (date: Date, timeZone?: TimeZoneModel) => {
    return timeZone ? moment.utc(date).tz(`${timeZone?.olsonId}`).format("hh:mm a z") : moment(date).tz(moment.tz.guess()).format("hh:mm a z");
};