import {Store, StoreConfig} from '@datorama/akita';
import {
    newPatientMedicationModel,
    PatientMedicationModel,
    RxntMedicationModel
} from '../../models/patientMedicationModel';

/**
 * Represents coaches state
 */
export interface PatientMedicationsState {
    patientMedications: PatientMedicationModel[];
    rxntMedications: RxntMedicationModel[];
    editModel: PatientMedicationModel;
}

/**
 * Creates initial state
 */
export function createInitialState(): PatientMedicationsState {
    return {
        patientMedications: [],
        rxntMedications: [],
        editModel: newPatientMedicationModel()
    };
}

/**
 * Provides coaches states management
 */
@StoreConfig({name: 'patientMedications', resettable: true })
export class PatientMedicationsStore extends Store<PatientMedicationsState> {
    constructor() {
        super(createInitialState());
    }

    public create(model: PatientMedicationModel) {
        this.update({patientMedications: [...this.getValue().patientMedications, model]});
    }

    public edit(model: PatientMedicationModel) {
        const patientMedications = this.getValue().patientMedications.map(p => p.id === model.id ? model : p);;
        this.update({patientMedications: patientMedications});
    }

    public delete(medicationId: number) {
        const medications = this.getValue().patientMedications.filter(x => x.id !== medicationId);
        this.update({patientMedications: medications});
    }

    public setEditModel(model?: PatientMedicationModel){
        this.update({editModel: Object.assign({}, model ?? newPatientMedicationModel())});
    }
}

export const patientMedicationsStore = new PatientMedicationsStore();