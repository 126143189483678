import React from 'react';
import { withRouter } from 'react-router';
import { AuthenticatedLayoutComponent } from '../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from "../../../../models/menu.models";
import { ManageCommonGoalsComponent } from '../../components/manageCommonGoalsComponent/ManageCommonGoalsComponent';

class ManageCommonGoalsPage extends React.Component<any> {
    render() {
        return (
            <AuthenticatedLayoutComponent
                hideBackground
                title={TitlesService.commonGoalsTitle}
                pageName={PagesNamesService.commonGoals}
                selectedMenuItem={MenuItemTypes.ManageCommonGoals}
            >
                <ManageCommonGoalsComponent />
            </AuthenticatedLayoutComponent>
        )
    }
}

export default withRouter(ManageCommonGoalsPage);
