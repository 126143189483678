import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        list: {
            fontSize: 14,
            borderRadius: 8,
            width: '100%',
            backgroundColor: theme.palette.background.paper,
        },
        listItem: {
            borderBottom: `1px solid ${colors.stroke}`,
            paddingRight: 80,
        },
        empty: {
            borderRadius: 8,
            width: '100%',
            background: colors.white,
            padding: 14,
        },
        sideMobile: {
            width: 400,
            height: '100%',
            [theme.breakpoints.down("xs")]: {
                width: '100%',
                height: 'min-content',
            },
        },
        sideContainer: {
            height: '100%',
            width: '100%',
            overflow: 'auto',
            padding: 20,
            paddingBottom: 24,
            [theme.breakpoints.down("xs")]: {
                paddingBottom: 40,
            },
        },
        papper: {
            borderTopLeftRadius: 16,
            borderBottomLeftRadius: 16,
            [theme.breakpoints.down("xs")]: {
                height: 'min-content',
                marginTop: 'auto',
                bottom: 0,
                borderTopLeftRadius: 20,
                borderTopRightRadius: 20,
                borderBottomLeftRadius: 0,
            },
        },
    }),
);
