import React, { useEffect, useState } from 'react';
import { Box, IconButton, Table, TableBody, TableContainer, TableHead } from "@material-ui/core";
import { Add } from '@material-ui/icons';
import { colors } from '../../../common/constants/colors';
import { PatientAllergyModel } from '../../../patientSupplements/models/patientAllergiesModel';
import { PatientAllergyUpdateComponent } from '../../../patientSupplements/components/patientAllergyUpdateComponent/PatientAllergyUpdateComponent';
import { WildHealthTableCell } from '../../../common/components/wildHealthTable/WildHealthTableCell';
import { WildHealthTableRow } from '../../../common/components/wildHealthTable/WildHealthTableRow';
import { SecondaryButton } from '../../../common/components/wildHealthButton/SecondaryButton';
import clsx from 'clsx';
import commonUseStyles from '../../../common/styles/common.styles';
import { Subscription } from 'rxjs';
import { onEmit } from '../../../common/helpers/on-emit';
import { patientAllergiesQuery } from '../../../patientSupplements/stores/patientAllergyStore/patientAllergies.query';
import { QuestionModel, QuestionType } from '../../models/questionnaire.models';
import { patientAllergiesService } from '../../../patientSupplements/services/patientAllergiesService';
import { MedicalHistoryAndLifestyleQuestionNames } from '../../static/medicalHistroryQuestionnaire.static';

export interface QuestionnaireAllergiesComponentProps {
    patientId?: number | null;
    question: QuestionModel;
    handleChanges: (field: string, value: string) => void
}

interface QuestionnaireAllergiesComponentState {
    allergies: PatientAllergyModel[];
    dialogOpened: boolean;
}

export const QuestionnaireAllergiesComponent: React.FC<QuestionnaireAllergiesComponentProps> = (props: QuestionnaireAllergiesComponentProps) => {
    const {
        patientId = null,
        question,
        handleChanges
    } = props;

    const commonClasses = commonUseStyles();

    const [state, setState] = useState({
        allergies: [],
        dialogOpened: false
    } as QuestionnaireAllergiesComponentState)

    const handleOpenDialog = (open: boolean) => {
        setState({ ...state, dialogOpened: open })
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<PatientAllergyModel[]>(patientAllergiesQuery.patientAllergies$, allergies => {
                if (allergies && allergies.length) {
                    setState(state => ({ ...state, allergies: allergies }))
                    handleChanges(MedicalHistoryAndLifestyleQuestionNames.ALLERGIES, allergies.map(x => x.id).join(','));
                }
            })
        ];

        if (patientId) {
            patientAllergiesService.getByPatientId(patientId).subscribe()
        }
        else {
            patientAllergiesService.get().subscribe()
        }

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    const renderContent = (): JSX.Element => {
        if (state.allergies.length) {
            return (
                <Box>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <WildHealthTableRow>
                                    <WildHealthTableCell style={{ width: '45%' }} >Name of Medication</WildHealthTableCell>
                                    <WildHealthTableCell>Reaction</WildHealthTableCell>
                                </WildHealthTableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    state.allergies.map((allergy, index) => (
                                        <WildHealthTableRow key={index}>
                                            <WildHealthTableCell>{allergy.name}</WildHealthTableCell>
                                            <WildHealthTableCell>{allergy.reaction}</WildHealthTableCell>
                                        </WildHealthTableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box display="flex" justifyContent="flex-start" alignItems="center" mt={1}>
                        <Box>
                            <IconButton id="questionnaire-allergies-open" data-testid="questionnaireAllergiesOpen" onClick={() => handleOpenDialog(true)}>
                                <Add style={{ color: colors.main, backgroundColor: colors.gray3 }} />
                            </IconButton>
                        </Box>
                        <Box>
                            <span className={clsx(commonClasses.colorMain, commonClasses.textMedium, commonClasses.size14)}>Add Allergy</span>
                        </Box>
                    </Box>
                </Box>
            )
        }

        return (
            <Box textAlign='center'>
                <Box mt={5}>
                    <span className={clsx(commonClasses.colorGray1, commonClasses.textSemiBold, commonClasses.size20, commonClasses.secondaryFont)}>No data yet</span>
                </Box>
                <Box mt={3}>
                    <span className={commonClasses.colorGray1}>Add medication allergies by clicking the button below.</span>
                </Box>
                <Box mt={2}>
                    <SecondaryButton id="questionnaire-allergies-add" data-testid="questionnaireAllergiesAdd" onClick={() => handleOpenDialog(true)}>Add Allergy</SecondaryButton>
                </Box>
            </Box>
        )
    }

    return (
        <>
            <PatientAllergyUpdateComponent
                opened={state.dialogOpened}
                patientId={patientId}
                handleClose={() => handleOpenDialog(false)}
            />
            <Box>
                <span className={commonClasses.textMedium}>{question.displayName}</span>
                {
                    (question.type === QuestionType.CheckMany || question.type === QuestionType.SelectMany) &&
                    <span className={commonClasses.colorGray1}> (Check all that apply.)</span>
                }
                {
                    question.optional &&
                    <span className={commonClasses.colorGray1}> (Optional)</span>
                }
            </Box>
            {
                renderContent()
            }
        </>
    )
}