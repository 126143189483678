import {DialogTitle} from "../../../common/components/dialogTitle/DialogTitle";
import {
    Box,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl, FormHelperText,
    Grid, IconButton,
    InputLabel,
    MenuItem,
    Select, TextField
} from "@material-ui/core";
import {WildHealthButton} from "../../../common/components/wildHealthButton/WildHealthButton";
import React from "react";
import {useFacade} from "./createInsuranceModalComponent.hooks";
import {WildHealthDatePicker} from "../../../common/components/WildHealthDatePicker";
import {RelationshipNames, Relationships} from "../../models/insurance.models";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import {colors} from "../../../common/constants/colors";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { ReactComponent as PhotoIcon } from '@img/icons/camera.svg';
import Dropzone from "react-dropzone";
import {useStyles} from "./createInsuranceModalComponent.styles";

export const CreateInsuranceModalComponent: React.FC = () => {

    const [
        {
            isOpen,
            containPolicyHolder,
            insurances,
            frontImage,
            backImage,
            model,
            errors
        },
        handleChanges,
        handleSetAsPrimary,
        handleUploadFrontImage,
        handleUploadBackImage,
        togglePolicyHolder,
        handleClose,
        handleSubmit
    ] = useFacade();

    const controlIcon = <CheckBoxOutlineBlankIcon style={{ fill: colors.main }} />;
    const controlCheckedIcon = <CheckBoxIcon style={{ fill: colors.main }} />;
    const fileTypes = ["image/jpeg", "image/png"];
    const classes = useStyles();

    const renderDropZone = (image: File, handleUpload: Function) => {
        return (
            <>
                {
                    !image &&
                    <Dropzone
                        maxFiles={1}
                        accept={fileTypes}
                        onDrop={(t) => handleUpload(t[0])}
                    >
                        {
                            ({ getRootProps, getInputProps, open }) => {
                                const rootProps = getRootProps();
                                delete rootProps['onClick']

                                return (
                                    <div {...rootProps} className={classes.dropContainer}>
                                    <span className={classes.dropTitle}>
                                        <input {...getInputProps()} />
                                        <IconButton onClick={open}>
                                            <PhotoIcon/>
                                        </IconButton>
                                    </span>
                                    </div>
                                )
                            }
                        }
                    </Dropzone>
                }
                {
                    image &&
                    <Box className={classes.uploadedImage} style={{backgroundImage:`url(${URL.createObjectURL(image)})`}}>

                    </Box>
                }
            </>
        )
    }

    return (
        <Dialog fullWidth open={isOpen} onClose={() => handleClose()} aria-labelledby="form-dialog-title">
            <Box mt={2} mx={3} ><DialogTitle onClose={() => handleClose()} id='dialog-title'>Add Insurance</DialogTitle></Box>
            <Box m={2} mt={0}>
                <DialogContent>
                    <Box pb={2}>
                        <Grid container>
                            <Grid lg={12} md={12} sm={12} xs={12}>
                                <Box>
                                    <Box mt={3}>
                                        <Grid container spacing={2}>
                                            <Grid item md={12} xs={12}>
                                                <FormControl
                                                    error={!!errors[`insuranceId`]}
                                                    variant="outlined"
                                                    color='primary'
                                                    required
                                                    size="small"
                                                    fullWidth>
                                                    <InputLabel>Insurance Provider</InputLabel>
                                                    <Select
                                                        label='Insurance Provider *'
                                                        style={{ background: '#FFF' }}
                                                        value={model.insuranceId}
                                                        onChange={(v) => handleChanges('insuranceId', v.target.value as string)}
                                                    >
                                                        {
                                                            insurances.sort((a, b) => a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1).map((value) => {
                                                                return <MenuItem key={`insurance-${value.id}`} value={value.id}>{value.name}</MenuItem>
                                                            })
                                                        }
                                                    </Select>
                                                    <FormHelperText>{errors[`insurance.id`]}</FormHelperText>
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={12} xs={12}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    size="small"
                                                    id={`memberId`}
                                                    variant="outlined"
                                                    value={model.memberId}
                                                    label="Member ID"
                                                    InputProps={{ className: 'input' }}
                                                    error={!!errors[`memberId`]}
                                                    helperText={errors[`memberId`]}
                                                    onChange={(v) => {
                                                        handleChanges(v.target.id, v.target.value)
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item md={12} sm={12}>
                                                <span className="text-accent-secondary">
                                                    Please upload both the front and the back of your insurance card. (Optional)
                                                </span>
                                            </Grid>
                                            <Grid item md={6} sm={12}>
                                                {
                                                    renderDropZone(frontImage, handleUploadFrontImage)
                                                }
                                            </Grid>
                                            <Grid item md={6} sm={12}>
                                                {
                                                    renderDropZone(backImage, handleUploadBackImage)
                                                }
                                            </Grid>
                                            <Grid item md={12} xs={12}>
                                                <Box display='flex' alignItems='center'>
                                                    <Box>
                                                        <Checkbox
                                                            icon={controlIcon}
                                                            color="primary"
                                                            checkedIcon={controlCheckedIcon}
                                                            checked={model.isPrimary}
                                                            onChange={() => handleSetAsPrimary(!model.isPrimary)}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <span>Set as Primary</span>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item md={12} xs={12}>
                                                <Box display='flex' alignItems='center'>
                                                    <Box>
                                                        <Checkbox
                                                            icon={controlIcon}
                                                            color="primary"
                                                            checkedIcon={controlCheckedIcon}
                                                            checked={containPolicyHolder}
                                                            onChange={() => togglePolicyHolder()}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <span>I am not the main policyholder</span>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            {
                                                containPolicyHolder &&
                                                <>
                                                    <Grid item md={6} xs={12}>
                                                        <TextField
                                                            required
                                                            fullWidth
                                                            size="small"
                                                            id={`policyHolder.firstName`}
                                                            variant="outlined"
                                                            value={model?.policyHolder?.firstName}
                                                            label="Policyholder First Name"
                                                            InputProps={{ className: 'input' }}
                                                            error={!!errors[`policyHolder.firstName`]}
                                                            helperText={errors[`policyHolder.firstName`]}
                                                            onChange={(v) => {
                                                                handleChanges(v.target.id, v.target.value)
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item md={6} xs={12}>
                                                        <TextField
                                                            required
                                                            fullWidth
                                                            size="small"
                                                            id={`policyHolder.lastName`}
                                                            variant="outlined"
                                                            value={model?.policyHolder?.lastName}
                                                            label="Policyholder Last Name"
                                                            InputProps={{ className: 'input' }}
                                                            error={!!errors[`policyHolder.lastName`]}
                                                            helperText={errors[`policyHolder.lastName`]}
                                                            onChange={(v) => {
                                                                handleChanges(v.target.id, v.target.value)
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item md={6} xs={12}>
                                                        <WildHealthDatePicker
                                                            label='Policyholder Date of birth'
                                                            required
                                                            fullWidth
                                                            style={{ background: '#FFF' }}
                                                            size="small"
                                                            openTo="year"
                                                            format="MM/DD/yyyy"
                                                            id='policyHolder.dateOfBirth'
                                                            placeholder="MM/DD/yyyy"
                                                            value={model?.policyHolder?.dateOfBirth !== null? new Date(model?.policyHolder?.dateOfBirth) : null}
                                                            inputVariant="outlined"
                                                            views={["year", "month", "date"]}
                                                            onChange={(momentDate) => handleChanges(`policyHolder.dateOfBirth`, momentDate.toISOString())}
                                                            disableFuture
                                                            error={!!errors[`policyHolder.dateOfBirth`]}
                                                            helperText={errors[`policyHolder.dateOfBirth`]}
                                                        />
                                                    </Grid>
                                                    <Grid item md={6} xs={12}>
                                                        <FormControl
                                                            error={!!errors[`policyHolder.relationship`]}
                                                            variant="outlined"
                                                            color='primary'
                                                            required
                                                            size="small"
                                                            fullWidth>
                                                            <InputLabel>Relationship to policyholder</InputLabel>
                                                            <Select
                                                                label='Relationship to policyholder *'
                                                                style={{ background: '#FFF' }}
                                                                id="gender"
                                                                value={model?.policyHolder?.relationship}
                                                                onChange={(v) => handleChanges('policyHolder.relationship', v.target.value as string)}
                                                            >
                                                                {
                                                                    Object
                                                                        .keys(Relationships)
                                                                        .filter(x => Relationships[x] !== Relationships.SELF)
                                                                        .map((key) => {
                                                                            return <MenuItem key={`relationship-${key}`}
                                                                                             value={key}>{RelationshipNames.get(key)}</MenuItem>
                                                                        })
                                                                }
                                                            </Select>
                                                            <FormHelperText>{errors[`policyHolder.relationship`]}</FormHelperText>
                                                        </FormControl>
                                                    </Grid>
                                                </>
                                            }
                                        </Grid>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>

                <DialogActions style={{ padding: '8px 24px' }}>
                    <Box mr={5}>
                        <WildHealthButton
                            id="cancel-verify-insurance"
                            onClick={() => handleClose()}
                            width={100}
                            size='large'
                            color='tertiary'
                        >
                            Cancel
                        </WildHealthButton>
                    </Box>
                    <Box>
                        <WildHealthButton
                            width={100}
                            size='large'
                            id="verify-insurance-dialog"
                            onClick={() => handleSubmit()}>
                            Save
                        </WildHealthButton>
                    </Box>
                </DialogActions>
            </Box>
        </Dialog>
    );
}
