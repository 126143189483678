import {AppointmentPurposeType} from "./appointments.enums";

export interface TimeRangeModel {
    label: string;
    startValue: number;
    endValue: number;
    isHourStart: boolean;
    isQuarterStart: boolean;
    isHalfStart: boolean;
}

export interface DurationModel{
    value: number;
    name: string;
    fullDay: boolean;
    patientMode?: boolean;
}

export const durationsPerPurpose: Map<AppointmentPurposeType, number[]> = new Map<AppointmentPurposeType, number[]>()
    .set(AppointmentPurposeType.SickVisit, [15, 25])


export const durations: DurationModel[] = [ 
    { value: 15, name: '15 mins', fullDay: false, patientMode: true },
    { value: 25, name: '25 mins', fullDay: false, patientMode: true },
    { value: 55, name: '55 mins', fullDay: false, patientMode: true },
    { value: 15, name: '15 mins', fullDay: false, patientMode: false },
    { value: 30, name: '30 mins', fullDay: false, patientMode: false },
    { value: 45, name: '45 mins', fullDay: false, patientMode: false },
    { value: 60, name: '1 hour', fullDay: false, patientMode: false },
    { value: 60*2, name: '2 hour', fullDay: false, patientMode: false },
    { value: 60*3, name: '3 hour', fullDay: false, patientMode: false },
    { value: 60*4, name: '4 hour', fullDay: false, patientMode: false },
    { value: 60*5, name: '5 hour', fullDay: false, patientMode: false },
    { value: 60*6, name: '6 hour', fullDay: false, patientMode: false },
    { value: 60*7, name: '7 hour', fullDay: false, patientMode: false },
    { value: 60*8, name: '8 hour', fullDay: false, patientMode: false },
    { value: 60*9, name: '9 hour', fullDay: false, patientMode: false },
    { value: 60*10, name: '10 hour', fullDay: false, patientMode: false },
    { value: 60*24, name: 'All day', fullDay: true, patientMode: false }
];