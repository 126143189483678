import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { colors } from "../../../common/constants/colors";
import { RoutesConstants } from '../../../../constants/route.constants';
import { ImageConstants } from '../../../../constants/image.constants';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        roadMap: {
            backgroundImage: `url(${RoutesConstants.cdnBaseLink}${ImageConstants.healthReportRoadMapPath})`,
            backgroundSize: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            width: '100%',
            height: '1100px',
        },
        leaf: {
            position: 'relative',
            width: '140px',
            height: '140px',
            borderRadius: '140px 0',
            background: colors.main,
        },
        leafContainer: {
            letterSpacing: '3.96px',
            position: 'relative',
            color: colors.white,
            height: '20px',
            width: '20px',
            fontFamily: `'Poppins', serif !important`,
        },
        grid: {
            border: `1px solid ${colors.systemBlack}`,
            height: "100px",
        },
        white: {
            color: colors.white,
        },
        labelWrap: {
            marginTop: "-25px",
            paddingBottom: "25px"
        },
        text: {
            fontWeight: 500,
            fontSize: '1.25rem !important',
        },
        overallScoreDescription: {
            position: 'relative',
            color: colors.white,
            fontWeight: 400,
        },
        overallScoreIndicator: {
            display: 'flex',
            width: '25px',
            height: '25px',
            borderRadius: '50%',
            position: 'relative',
            color: colors.white,
            letterSpacing: '2.2px',
        },
        AtGoal: {
            background: '#4c9000',
        },
        IncompleteData: {
            background: '#b9b9b9',
        },
        AreaOfImprovement: {
            background: '#eea429',
        },

    })
);