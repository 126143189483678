import {Box, Container, Paper} from "@material-ui/core";
import React from 'react';
import {TitlesService} from '../../../../constants/title.constants';
import {useFacade} from "./loginOnBehalfPage.hooks";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import {logoService} from '../../../../services/logo.service';
import {useStyles} from '../loginPage/loginPage.styles';

export const LoginOnBehalfPage: React.FC = () => {
    const businessId = new URLSearchParams(window.location.search).get('businessId');
    const practiceId = new URLSearchParams(window.location.search).get('practiceId');
    const [
        {
            isLoading
        }
    ] = useFacade(Number(businessId), Number(practiceId));
    const classes = useStyles();

    return (
        <>
            <title>{TitlesService.loginTitle}</title>
            <div className={classes.loginPage}>
                <Box className={classes.contentBox}>
                    <Container maxWidth="sm" className="content">
                        <Paper variant="outlined" square className={classes.loginForm}>
                            <Box>
                                <img src={logoService.getLogo().primary} alt="logo"/>
                            </Box>
                            <Box mt={5}>
                                {
                                    isLoading ?? <WildHealthLinearProgress/>
                                }
                            </Box>
                        </Paper>
                    </Container>
                </Box>
            </div>
        </>
    );
}
