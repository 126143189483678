import { Observable, Subject } from "rxjs";
import { AddOnProvider, AddOnModel } from "../../addons/models/addOns.models";
import {CallbackModel} from "../../common/models/callback.model"
import {Gender} from "../../common/models/user.models";

export class PricingService {
    public onView = new Subject<CallbackModel<[AddOnProvider, Gender]>>();
    public onNew = new Subject<CallbackModel<[AddOnProvider]>>();
    public onUpdate = new Subject<CallbackModel<[AddOnModel]>>();

    public open(labProvider: AddOnProvider, gender: Gender): Observable<boolean> {
        return new Observable(observer => {
            this.onView.next({
                data: [
                    labProvider,
                    gender
                ],
                callback: () => {
                    observer.next();
                    observer.complete();
                }
            });
        });
    }

    public openNew(labProvider: AddOnProvider): Observable<boolean> {
        return new Observable(observer => {
            this.onNew.next({
                data: [
                    labProvider
                ],
                callback: () => {
                    observer.next();
                    observer.complete();
                }
            });
        });
    }

    public openUpdate(addOnItem: AddOnModel): Observable<boolean> {
        return new Observable(observer => {
            this.onUpdate.next({
                data: [
                    addOnItem
                ],
                callback: () => {
                    observer.next();
                    observer.complete();
                }
            });
        });
    }
}

export const pricingService = new PricingService();