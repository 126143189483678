import {useEffect, useState} from "react";
import {Subscription} from "rxjs";
import {onEmit} from "../../../common/helpers/on-emit";
import {PaymentPlanModel} from "../../../payment/models/paymentPlan.models";
import {paymentPlansService} from "../../../payment/services/paymentPlans.service";
import {paymentPlansQuery} from "../../../payment/stores/paymentPlansStore";
import {authQuery} from "../../../auth/stores/auth";

export interface SelectFellowshipPlanComponentState {
    plans: PaymentPlanModel[];
    selectedPlan: PaymentPlanModel;
}

export function useFacade(goNext: () => void): [
    SelectFellowshipPlanComponentState,
    (plan: PaymentPlanModel) => void,
] {
    const [state, setState] = useState({
        plans: [],
        selectedPlan: null
    } as SelectFellowshipPlanComponentState);

    const handlePlanSelect = (plan: PaymentPlanModel) => {
        paymentPlansService.selectPlan(plan, true);
        if (plan && plan.periods.length === 1) {
            paymentPlansService.selectPeriod(plan.periods[0]);
        }
        setState({...state, selectedPlan: plan})
        goNext();
    };

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<PaymentPlanModel[]>(paymentPlansQuery.regularPlans$, plans => {
                setState(state => ({
                    ...state,
                    plans: plans
                }));
            })
        ];

        paymentPlansService.getActive(authQuery.getCurrentPracticeId()).subscribe();

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [])

    return [state, handlePlanSelect]
}