import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontFamily: 'Be Vietnam Pro',
            fontSize: '1.6rem',
            fontWeight: 500,
            lineHeight: 1.18,
            letterSpacing: 0.17,
            color: '#292929 !important'
        },
        subTitle: {
            fontFamily: 'Be Vietnam Pro',
            fontSize: 14,
        },
        sectionTitle: {
            fontFamily: 'Be Vietnam Pro',
            fontSize: 16,
            fontWeight: 500,
            lineHeight: 1.38,
            letterSpacing: 0.8,
            color: 'rgba(0, 0, 0, 0.56)',
        },
        regular: {
            fontFamily: 'Be Vietnam Pro',
            fontSize: 15,
            fontWeight: 300,
            lineHeight: 1.33,
            letterSpacing: 0.15,
        },
        recommendedList: {
            borderRight: '2px solid #f9f9fb',
        },
        inputRoot: {
            color: 'inherit',
        },
        inputField: {
            transition: theme.transitions.create('width'),
            padding: theme.spacing(1, 1, 1, 0),
            paddingLeft: `10px`,
            borderRadius: theme.shape.borderRadius,
            height: 24,
        },
        input: {
            position: 'relative',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: theme.shape.borderRadius,
            marginLeft: 0,
            width: '100% !important',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(1),
                width: 'auto',
            },
        },
        divider: {
            backgroundColor: "#f9f9fb",
            margin: 'auto',
            height: 2,
        },
        rootView: {
            fontSize: 14,
            minHeight: 28,
            borderRadius: 2,
            display: 'flex',
            alignItems: 'center',
            width: 'fit-content',
            whiteSpace: 'nowrap',
            paddingRight: 10,
            paddingLeft: 10,
            backgroundColor: colors.separators,
        },
        button: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: '8px',
            color: colors.main,
            fontSize: 14
        },
    })
);
