export enum SyncRecordStatus
{
    PendingCleansing = 1,
    PendingValidation = 5,

    ReadyForSync = 10,
    SyncComplete = 15,
    Locked = 20,
    FailedSync = 25,
    
    DorothyDuplicatePolicyId = 100,
    DorothyDuplicateBirthdateZipCodeFirstLast = 105,
    DorothyRequiresDiscovery = 110,
    DorothyBlankZipCode = 115,
    DorothyInvalidBirthday = 120,
    DorothyInvalidBirthdayFormat = 125,
    DorothyInvalidAddress = 130,
    DorothyInvalidName = 135,
    DorothyUnshippableAddress = 140,
    
    DorothyDuplicatePolicyIdDiscarded = 101,
    DorothyDuplicateBirthdateZipCodeDiscarded = 106,
    DorothyRequiresDiscoveryDiscarded = 111,
    DorothyBlankZipCodeDiscarded = 116,
    DorothyInvalidBirthdayDiscarded = 121,
    DorothyInvalidAddressDiscarded = 131,
    DorothyInvalidNameDiscarded = 136,
    DorothyUnshippableAddressDiscarded = 141
}

export enum SyncDatumBooleanKeys
{
    SubscriptionOptIn = "SubscriptionOptIn",
    SmsOptIn = "SmsOptIn",
    SmsMarketingOptIn = "SmsMarketingOptIn"
}

export enum SyncRecordAction
{
    DorothySendValidateAddressEmail = 100,
    DorothySubscriptionOptOut = 110
}

export const syncRecordStatusNames: { [status: string]: string } = {
    [SyncRecordStatus.PendingCleansing]: "PendingCleansing",
    [SyncRecordStatus.PendingValidation]: "PendingValidation",

    [SyncRecordStatus.ReadyForSync]: "ReadyForSync",
    [SyncRecordStatus.SyncComplete]: "SyncComplete",
    [SyncRecordStatus.Locked]: "Locked",
    [SyncRecordStatus.FailedSync]: "FailedSync",

    [SyncRecordStatus.DorothyDuplicatePolicyId]: "DorothyDuplicatePolicyId",
    [SyncRecordStatus.DorothyDuplicateBirthdateZipCodeFirstLast]: "DorothyDuplicateBirthdateZipCodeFirstLast",
    [SyncRecordStatus.DorothyRequiresDiscovery]: "DorothyRequiresDiscovery",
    [SyncRecordStatus.DorothyBlankZipCode]: "DorothyBlankZipCode",
    [SyncRecordStatus.DorothyInvalidBirthday]: "DorothyInvalidBirthday",
    [SyncRecordStatus.DorothyInvalidBirthdayFormat]: "DorothyInvalidBirthdayFormat",
    [SyncRecordStatus.DorothyInvalidAddress]: "DorothyInvalidAddress",
    [SyncRecordStatus.DorothyInvalidName]: "DorothyInvalidName",
    [SyncRecordStatus.DorothyUnshippableAddress]: "DorothyUnshippableAddress",
    
    [SyncRecordStatus.DorothyDuplicatePolicyIdDiscarded]: "DorothyDuplicatePolicyIdDiscarded",
    [SyncRecordStatus.DorothyDuplicateBirthdateZipCodeDiscarded]: "DorothyDuplicateBirthdateZipCodeDiscarded",
    [SyncRecordStatus.DorothyRequiresDiscoveryDiscarded]: "DorothyRequiresDiscoveryDiscarded",
    [SyncRecordStatus.DorothyBlankZipCodeDiscarded]: "DorothyBlankZipCodeDiscarded",
    [SyncRecordStatus.DorothyInvalidBirthdayDiscarded]: "DorothyInvalidBirthdayDiscarded",
    [SyncRecordStatus.DorothyInvalidAddressDiscarded]: "DorothyInvalidAddressDiscarded",
    [SyncRecordStatus.DorothyInvalidNameDiscarded]: "DorothyInvalidNameDiscarded",
    [SyncRecordStatus.DorothyUnshippableAddressDiscarded]: "DorothyUnshippableAddressDiscarded"
}

export const syncRecordActionNames: { [action: number]: string } = {
    [SyncRecordAction.DorothySendValidateAddressEmail]: "Send Validate Address Email",
    [SyncRecordAction.DorothySubscriptionOptOut]: "Opt Out of Subscription"
}

export const titleBysyncRecordStatus = new Map<SyncRecordStatus, string>([
    [SyncRecordStatus.PendingCleansing, 'Pending'],
    [SyncRecordStatus.PendingValidation, 'Pending'],

    [SyncRecordStatus.ReadyForSync, 'Passed Validation'],
    [SyncRecordStatus.SyncComplete, 'Passed Validation'],
    [SyncRecordStatus.FailedSync, 'Passed Validation'],

    [SyncRecordStatus.Locked, 'Locked'],

    [SyncRecordStatus.DorothyDuplicatePolicyId, 'Failed Validation'],
    [SyncRecordStatus.DorothyDuplicateBirthdateZipCodeFirstLast, 'Failed Validation'],
    [SyncRecordStatus.DorothyRequiresDiscovery, 'Failed Validation'],
    [SyncRecordStatus.DorothyBlankZipCode, 'Failed Validation'],
    [SyncRecordStatus.DorothyInvalidBirthday, 'Failed Validation'],
    [SyncRecordStatus.DorothyInvalidBirthdayFormat, 'Failed Validation'],
    [SyncRecordStatus.DorothyInvalidAddress, 'Failed Validation'],
    [SyncRecordStatus.DorothyInvalidName, 'Failed Validation'],
    [SyncRecordStatus.DorothyUnshippableAddress, 'Failed Validation'],

    [SyncRecordStatus.DorothyDuplicatePolicyIdDiscarded, 'Discarded'],
    [SyncRecordStatus.DorothyDuplicateBirthdateZipCodeDiscarded, 'Discarded'],
    [SyncRecordStatus.DorothyRequiresDiscoveryDiscarded, 'Discarded'],
    [SyncRecordStatus.DorothyBlankZipCodeDiscarded, 'Discarded'],
    [SyncRecordStatus.DorothyInvalidBirthdayDiscarded, 'Discarded'],
    [SyncRecordStatus.DorothyInvalidAddressDiscarded, 'Discarded'],
    [SyncRecordStatus.DorothyInvalidNameDiscarded, 'Discarded'],
    [SyncRecordStatus.DorothyUnshippableAddressDiscarded, 'Discarded']
]);
