import { Box, createStyles, Dialog, DialogContent, IconButton, Theme, Typography, WithStyles, withStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { CreatePatientAppointmentFormComponent } from '../createPatinentAppointmentForm/CreatePatientAppointmentFormComponent';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import { useStyles } from './CreatePatientAppointmentDialogComponent.styles';
import { LocationModel } from '../../../locations/models/locations.models';
import { useFacade } from "./createPatientAppointmentDialogComponent.hook";
import { EmployeeModel } from '../../../employee/models/employee.models';
import { AppointmentTypeModel } from '../../models/appointments.models';
import { AppointmentWithType } from '../../models/appointments.enums';
import commonUseStyles from '../../../common/styles/common.styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import clsx from 'clsx';
import { TimeZoneModel } from "../../../timezones/models/timezone.model";
import AddCircleIcon from '@material-ui/icons/AddCircle';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    }
    );

interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;

    const commonClasses = commonUseStyles();

    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography className={clsx(commonClasses.size24, commonClasses.textMedium)}>{children}</Typography>
            {onClose ? (
                <IconButton id="create-patient-appointment-close" aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

interface ScheduleAppointmentComponentProps {
    assignedEmployees: EmployeeModel[];
    appointmentWithType: AppointmentWithType;
    appointmentTypes: AppointmentTypeModel[];
    canChargePatient?: boolean;
    locations: LocationModel[];
    timeZone: TimeZoneModel;
    patientId?: number | null;
    disableSchedule?: boolean;
    buttonType?: any;
}

export const CreatePatientAppointmentDialogComponent: React.FC<ScheduleAppointmentComponentProps> = (props: ScheduleAppointmentComponentProps) => {
    const {
        assignedEmployees,
        appointmentWithType,
        appointmentTypes,
        locations,
        timeZone,
        patientId = null,
        disableSchedule = false,
        buttonType = WildHealthButton,
    } = props;

    const [
        state,
        handleToggleDialog,
        handleContinueDialog
    ] = useFacade();

    const commonClasses = commonUseStyles();
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <>
            {
                isSmallScreen ?
                    <Box position="fixed" right={4} bottom={40} zIndex={1202}>
                        <IconButton aria-label="open-dialog" disabled={disableSchedule} onClick={() => handleToggleDialog()}>
                            <AddCircleIcon className={classes.fixedButton} />
                        </IconButton>
                    </Box> :
                    React.createElement(buttonType, { disabled: disableSchedule, onClick: handleToggleDialog }, 'Schedule a Visit')
            }
            <Dialog
                fullScreen={isSmallScreen}
                maxWidth="md"
                open={state.toShowDialog}
                className={classes.dialog}
                onClose={handleToggleDialog}>
                {
                    !isSmallScreen &&
                    <DialogTitle id="customized-dialog-title" onClose={handleToggleDialog}>
                        <span className={clsx(commonClasses.size24, commonClasses.textMedium, classes.dialogTitle)}>
                            {state.toContinueDialog ? "Appointment Confirmation" : "Schedule Appointment"}  
                        </span>
                    </DialogTitle>
                }
                <DialogContent className={classes.dialogContent}>
                    <Box width={isSmallScreen ? 1 : "548px"}>
                        <CreatePatientAppointmentFormComponent
                            isContinueDialog={state.toContinueDialog}
                            handleContinueDialog={handleContinueDialog}
                            toggleDialog={handleToggleDialog}
                            assignedEmployees={assignedEmployees}
                            appointmentTypes={appointmentTypes}
                            locations={locations}
                            appointmentWithType={appointmentWithType}
                            handleCloseDialog={handleToggleDialog}
                            timeZone={timeZone}
                            patientId={patientId}
                        />
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
}
