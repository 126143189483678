import { colors } from '../../common/constants/colors';

export interface ScoreTermsModel { 
    age: number,
    name: string,
    term: string,
    displayValueLowerBound: number,
    displayValueUpperBound: number,
    termCategory: string,
    patientValue: string,
    hazarRatio: number,
    displayValue: number,
    referenceLink: string,
    hoverOverText: string,
    hoverOverName: string,
    patient: number,
    patientNote: string,
    race: string,
    reference: string,
    sex: string,
    termSubcategory: string,
}

export interface HealthPatientScoreModel {   
    clarityPortalPatientId: string,
    externalPatientId: string,
    healthScoreModel: number,
    healthScoreVersion: number,
    patient: number,
    score: number,
    totalTerms: number,
    scoredTerms: number,
}

export interface HealthScoreCategoriesModel {   
    name: string,
    value: number, // count of result
}

export interface HealthScoreCategoriesDisplaytModel {   
    name: string,
    hoverText: string,
} 
export interface HealthScoreModel {   
    categories: HealthScoreCategoriesModel[],
    patientScore: HealthPatientScoreModel,
    terms: ScoreTermsModel[],
}

export interface BiomarkersModel {   
    name: string,
    score: number,
}

export interface HabitBiomarkersModel {   
    name: string,
    score: number,
    items: ScoreTermsModel[]
}

export const ColorsForHealth = [
    colors.main, 
    colors.lightGray, 
];
export const ColorsForHabit = [
    colors.main, 
    colors.accent2, 
    colors.average1, 
    colors.accent3, 
    colors.average2, 
    colors.lightGray, 
];