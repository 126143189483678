import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardTitle: {
            fontSize: 18,
            fontWeight: 500,
            paddingLeft: 12,
            paddingRight: 10,
        },
        cardDocumentCoun: {
            fontSize: 14,
            fontWeight: 500,
            padding: 4,
            backgroundColor: colors.gray3,
            color: colors.gray1,
        },
        cardUnreadLabele: {
            fontSize: 12,
            fontWeight: 700,
            color: colors.bad,
            backgroundColor: `${colors.bad}14`,
            display: 'flex',
            height: 'min-content',
        },
        cardMain: {
            position: 'relative',
            display: 'flex',
            boxSizing: 'border-box',
            boxShadow: "0px 4px 9px rgba(168, 173, 198, 0.15)",
            borderRadius: "2px",
            border: `1px solid ${colors.stroke}`,
            paddingLeft: 20,
            justifyContent: 'space-between',
            alignItems: 'center',
            minHeight: 74,
        },
        cardActive: {
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: `${colors.stroke}CC`,
            },
        }
    })
);