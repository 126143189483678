export class FollowUpFormQuestionNames {
    static readonly REFLECT_LAST_VISIT: string = 'REFLECT_LAST_VISIT'
    static readonly RATE_OVERALL_PROGRESS: string = 'RATE_OVERALL_PROGRESS'
    static readonly CLARIFY_PREVIOUS_QUESTIONS: string = 'CLARIFY_PREVIOUS_QUESTIONS'
    static readonly HOPING_UPCOMING_VISITS: string = 'HOPING_UPCOMING_VISITS'
    static readonly RATE_SUCCESS_EXERCISE: string = 'RATE_SUCCESS_EXERCISE'
    static readonly RATE_SUCCESS_NUTRITION: string = 'RATE_SUCCESS_NUTRITION'
    static readonly RATE_SUCCESS_SLEEP: string = 'RATE_SUCCESS_SLEEP'
    static readonly RATE_SUCCESS_MINDFULNESS: string = 'RATE_SUCCESS_MINDFULNESS'
    static readonly RATE_SUCCESS_SOCIAL_CONNECTION: string = 'RATE_SUCCESS_SOCIAL_CONNECTION'
    static readonly YOUR_WEIGHT_BIOMETRICS: string = 'YOUR_WEIGHT_BIOMETRICS'
    static readonly YOUR_WAIST_CIRCUMFERENCE_BIOMETRICS: string = 'YOUR_WAIST_CIRCUMFERENCE_BIOMETRICS'
    static readonly CHECKING_BIOMETRICS_SYSTOLIC_BP: string = 'CHECKING_BIOMETRICS_SYSTOLIC_BP'
    static readonly CHECKING_BIOMETRICS_DIASTOLIC_BP: string = 'CHECKING_BIOMETRICS_DIASTOLIC_BP'
}