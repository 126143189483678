import {shortcutsStore, ShortcutsStore} from "../stores/shortcutsStore/shortcuts.store";
import Axios from "axios-observable";
import {authHeader} from "../../common/helpers/auth-header";
import {snackService} from "../../common/snack/state";
import {
    CreateShortcutGroupModel,
    CreateShortcutModel,
    ShortcutGroupModel,
    ShortcutModel,
    UpdateShortcutModel,
    UpdateShortcutGroupModel
} from "../models/shortcut.models";
import {Observable} from "rxjs";

/**
 * Provides method for working with shortcuts
 */
export class ShortcutsService {
    private shortcutsUrl = `${process.env.REACT_APP_API_URL}Shortcuts`;
    private shortcutGroupsUrl = `${process.env.REACT_APP_API_URL}ShortcutGroups`;

    constructor(private shortcutsStore: ShortcutsStore) {
    }

    public get(): Observable<ShortcutGroupModel[]> {
        const config = {
            headers: authHeader()
        };
        return new Observable(observer => {
            Axios.get(`${this.shortcutGroupsUrl}/My`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.shortcutsStore.init(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public createGroup(model: CreateShortcutGroupModel): Observable<ShortcutGroupModel> {
        const config = {
            headers: authHeader()
        };
        return new Observable(observer => {
            Axios.post(`${this.shortcutGroupsUrl}`, model, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.shortcutsStore.insertGroup(response.data);
                        snackService.success('Shortcut group successfully created!');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public updateShortcutGroup(model: UpdateShortcutGroupModel): Observable<ShortcutGroupModel> {
        const config = {
            headers: authHeader()
        };
        return new Observable(observer => {
            Axios.put(`${this.shortcutGroupsUrl}`, model, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.shortcutsStore.updateGroup(response.data);
                        snackService.success('Shortcut group successfully updated!');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public deleteShortcutGroup(id: number): Observable<ShortcutGroupModel> {
        const config = {
            headers: authHeader()
        };
        return new Observable(observer => {
            Axios.delete(`${this.shortcutGroupsUrl}/${id}`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.shortcutsStore.deleteGroup(id);
                        snackService.success('Shortcut group successfully deleted!');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public getShortcut(id: number): Observable<ShortcutModel> {
        const config = {
            headers: authHeader()
        };
        return new Observable(observer => {
            Axios.get(`${this.shortcutsUrl}/${id}`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.shortcutsStore.updateShortcut(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public createShortcut(model: CreateShortcutModel): Observable<ShortcutModel> {
        const config = {
            headers: authHeader()
        };
        return new Observable(observer => {
            Axios.post(`${this.shortcutsUrl}`, model, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.shortcutsStore.insertShortcut(response.data);
                        snackService.success('Shortcut successfully created!');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public updateShortcut(model: UpdateShortcutModel): Observable<ShortcutModel> {
        const config = {
            headers: authHeader()
        };
        return new Observable(observer => {
            Axios.put(`${this.shortcutsUrl}`, model, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.shortcutsStore.updateShortcut(response.data);
                        snackService.success('Shortcut successfully updated!');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public deleteShortcut(id: number): Observable<ShortcutModel> {
        const config = {
            headers: authHeader()
        };
        return new Observable(observer => {
            Axios.delete(`${this.shortcutsUrl}/${id}`, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.shortcutsStore.deleteShortcut(response.data);
                        snackService.success('Shortcut successfully deleted!');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }
}

export const shortcutsService = new ShortcutsService(shortcutsStore);