import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: '622px',
    },
    headerText: {
      fontSize: '36px',
      fontWeight: 500,
      lineHeight: '54px',
      color: colors.main,
    },
    headerTextSM: {
      fontSize: '22px',
      fontWeight: 500,
      lineHeight: '34px',
      color: colors.main,
    },
    image: {
      width: "100%",
      objectFit: "fill",
    },
    shareContent: {
      width: 216,
    },
    orContent: {
      height: 80,
    },
    orDivider: {
      flex: 1,
    },
    shareBtn: {
      flex: 1,
      width: 56,
    },
    codeContent: {
      width: 128,
      textAlign: 'center',
    }
  })
);
