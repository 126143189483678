import { Box, useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { HealthReportSections, HealthReportMode } from "../models/healthReport.models";
import { HealthReportBasePage, HealthReportMobileBasePage } from "./HealthReportBasePage";
import { useStyles } from './healthReport.styles';
import { HealthReportPageProps } from './healthReportPageProps';

export const HealthReportAppendixPage: React.FC<HealthReportPageProps> = (props: HealthReportPageProps) => {
    const {
        pageNumber,
        practice
    } = props;
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        props.mode !== HealthReportMode.PrintMode && isSmallScreen ? (
            <HealthReportMobileBasePage section={HealthReportSections.Appendix} pageNumber={pageNumber} practice={practice} firstPage>
                <Box px={2}>
                    <Box fontSize="14px" lineHeight="22px">
                        <span className={classes.referenceLinkPage}>[1]</span> RL McClelland, PhD; NW Jorgensen, MS; M Budoff, MD; MJ Blaha, MD, MPH; WS Post, MD, MS; Richard A. Kronmal, PhD; DE Bild, MD, MPH; S Shea, MD, MS; K Liu, PhD; KE Watson, MD, PhD; AR Folsom, MD; A Khera, MD; C Ayers, MS; AA Mahabadi, MD; N Lehmann, PhD; K Jöckel, PhD; S Moebus, PhD; JJ Carr, MD, MS; R Erbel, MD, PhD; GL Burke, MD, MS. 10-Year Coronary Heart Disease Risk Prediction Using Coronary Artery Calcium and Traditional Risk Factors: Derivation in the MESA (Multi-Ethnic Study of Atherosclerosis) With Validation in the HNR (Heinz Nixdorf Recall) Study and the DHS (Dallas Heart Study). J Am Coll Cardiol. 2015 Oct 13;66(15):1643-53. PMID:26449133. PMCID:4603537.
                    </Box>
                </Box>
                <Box px={2}>
                    <Box mt="10px" fontSize="14px" lineHeight="22px">
                        <span className={classes.referenceLinkPage}>[2]</span> Mega JL, Stitziel NO, Smith JG, Chasman DI, Caulfield M, Devlin JJ, Nordio F, Hyde C, Cannon CP, Sacks F, Poulter N, Sever P, Ridker PM, Braunwald E, Melander O, Kathiresan S, Sabatine MS. Genetic risk, coronary heart disease events, and the clinical benefit of statin therapy: an analysis of primary and secondary prevention trials. Lancet. 2015 Jun 6;385(9984):2264-2271. doi: 10.1016/S0140-6736(14)61730-X. Epub 2015 Mar 4. PMID: 25748612; PMCID: PMC4608367.
                    </Box>
                </Box>
            </HealthReportMobileBasePage>
        ) : (
            <HealthReportBasePage section={HealthReportSections.Appendix} pageNumber={pageNumber} practice={practice}>
                <Box px={15} pt={3}>
                    <Box mt={1}>
                        <span className={classes.referenceLinkPage}>[1]</span> RL McClelland, PhD; NW Jorgensen, MS; M Budoff, MD; MJ Blaha, MD, MPH; WS Post, MD, MS; Richard A. Kronmal, PhD; DE Bild, MD, MPH; S Shea, MD, MS; K Liu, PhD; KE Watson, MD, PhD; AR Folsom, MD; A Khera, MD; C Ayers, MS; AA Mahabadi, MD; N Lehmann, PhD; K Jöckel, PhD; S Moebus, PhD; JJ Carr, MD, MS; R Erbel, MD, PhD; GL Burke, MD, MS. 10-Year Coronary Heart Disease Risk Prediction Using Coronary Artery Calcium and Traditional Risk Factors: Derivation in the MESA (Multi-Ethnic Study of Atherosclerosis) With Validation in the HNR (Heinz Nixdorf Recall) Study and the DHS (Dallas Heart Study). J Am Coll Cardiol. 2015 Oct 13;66(15):1643-53. PMID:26449133. PMCID:4603537.
                    </Box>
                </Box>
                <Box px={15} pt={3}>
                    <Box mt={1}>
                        <span className={classes.referenceLinkPage}>[2]</span> Mega JL, Stitziel NO, Smith JG, Chasman DI, Caulfield M, Devlin JJ, Nordio F, Hyde C, Cannon CP, Sacks F, Poulter N, Sever P, Ridker PM, Braunwald E, Melander O, Kathiresan S, Sabatine MS. Genetic risk, coronary heart disease events, and the clinical benefit of statin therapy: an analysis of primary and secondary prevention trials. Lancet. 2015 Jun 6;385(9984):2264-2271. doi: 10.1016/S0140-6736(14)61730-X. Epub 2015 Mar 4. PMID: 25748612; PMCID: PMC4608367.
                    </Box>
                </Box>
            </HealthReportBasePage>
        )
    )
}
