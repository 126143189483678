import React, { useState } from "react";

interface UserOptionsComponentState {
    anchorEl: HTMLButtonElement;
    showMenu: boolean;
}

/**
 * Custom Hook to manage a view Model for User Options component
 */
export function useFacade(): [UserOptionsComponentState, (event?: React.MouseEvent<HTMLButtonElement>) => void] {
    const [state, setState] = useState({
        anchorEl: null,
        showMenu: false
    } as UserOptionsComponentState);

    const handleToggleMenu = (event?: any) => {
        const anchorEl = event && event.currentTarget ? event.currentTarget : null;
        setState(state => ({...state, showMenu: !state.showMenu, anchorEl: anchorEl}));
    }

    return [state, handleToggleMenu];
}