import React from 'react';
import { WildHealthTabControl } from "../../../common/components/wildHealthTabControl/WildHealthTabControl";
import { useFacade } from "./patientProfileComponent.hooks";

interface PatientInformationComponentProps {
    patientId: number;
    patientIntakeId: string;
}

export const PatientProfileComponent: React.FC<PatientInformationComponentProps> = (props: PatientInformationComponentProps) => {
    const [
        tabItems,
    ] = useFacade(props.patientId, props.patientIntakeId);

    return (
        <WildHealthTabControl items={tabItems} />
    );
}