import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        image: {
            maxWidth: 300,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                maxWidth: 444,
            },
        },
        groupList: {
            width: 405,
            flexShrink: 0,
            overflowX: 'auto',
            overflowY: 'auto',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                paddingTop: 16
            },
        },
        groups: {
            minHeight: 300,
            height: 'calc(100vh - 268px)',
            maxHeight: 'calc(100vh - 204px)',
            overflow: 'auto',
        },
    }),
);
