import { Box, TextField, Grid, IconButton } from '@material-ui/core';
import { Add, Delete, Edit } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { UserType } from '../../../auth/models/auth.enums';
import { authQuery } from '../../../auth/stores/auth';
import { selectShortcutService } from '../../../healthReport/services/selectShortcut.service';
import { insertString } from '../../helpers/insert-string';
import { useStyles } from './ItemsListComponent.styles';
import { Observable } from "rxjs";
import { isFocusInCurrentTarget } from "../../helpers/is-focus-in-current-target";
import { TextMarkdownComponent } from '../../../notes/components/textMarkdownComponent/TextMarkdownComponent';

export interface ItemsListComponentProps {
    title?: string;
    addText?: string;
    subTitle?: string;
    grid?: 6 | 12;
    dataKey: string;
    items: string[];
    getAutoComplete: (searchQuery: string) => Observable<string[]>;
    handleChanges: (key: string, values: string[]) => void
}

interface ItemsListComponentState {
    valueToAdd: string;
    valueToEdit: string;
    indexToEdit: number | null;
    options: string[];
}

let stateContext: ItemsListComponentState = null;

let focus = true;

let isSubmitted = true;

export const ItemsListComponent: React.FC<ItemsListComponentProps> = (props: ItemsListComponentProps) => {
    const {
        title = null,
        addText = null,
        subTitle = null,
        grid = 12,
        dataKey,
        items,
        getAutoComplete,
        handleChanges,
    } = props;

    const [state, setState] = useState({
        valueToAdd: null,
        valueToEdit: null,
        indexToEdit: null,
        options: []
    } as ItemsListComponentState);

    stateContext = state;

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (getAutoComplete && stateContext.valueToAdd) {
                getAutoComplete(stateContext.valueToAdd).subscribe((options) => {
                    setState(state => ({
                        ...state,
                        options: options
                    }));
                })
            }
        }, 2000);

        return () => clearTimeout(delayDebounceFn)
    }, [stateContext.valueToAdd, getAutoComplete]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (getAutoComplete && stateContext.valueToEdit) {
                getAutoComplete(stateContext.valueToEdit).subscribe((options) => {
                    setState(state => ({
                        ...state,
                        options: options
                    }));
                })
            }
        }, 2000);

        return () => clearTimeout(delayDebounceFn)
    }, [stateContext.valueToEdit, getAutoComplete]);

    const classes = useStyles();

    const handleDeleteItem = (index: number) => {
        items.splice(index, 1);

        setState(state => ({
            ...state,
            valueToAdd: null,
            valueToEdit: null,
            indexToEdit: null,
            options: []
        }));

        handleChanges(dataKey, items);

        isSubmitted = true;
    }

    const handleEnableAdd = () => {
        if (state.valueToEdit) {
            items[state.indexToEdit] = state.valueToEdit;
            handleChanges(dataKey, items);
        }

        if (!state.valueToEdit && Number.isInteger(state.indexToEdit)) {
            items.splice(state.indexToEdit, 1);
            handleChanges(dataKey, items);
        }

        setTimeout(()=>{
            setState(state => ({
                ...state,
                valueToAdd: '',
                valueToEdit: null,
                indexToEdit: null,
                options: []
            }));
    
            isSubmitted = false;
        },250);
    }

    const handleAddChanges = (value: string) => {
        setState(state => ({ ...state, valueToAdd: value }));
    }

    const handleAddSubmit = () => {
        if (isSubmitted) {
            return;
        }

        if (state.valueToAdd !== null && state.valueToAdd !== '') {
            items.push(state.valueToAdd);
            handleChanges(dataKey, items);
        }

        setState(state => ({
            ...state,
            valueToAdd: null,
            options: []
        }));

        isSubmitted = true;
    }

    const handleEnableEdit = (index: number) => {
        if (state.valueToEdit) {
            items[state.indexToEdit] = state.valueToEdit;
            handleChanges(dataKey, items);
        }

        if (!state.valueToEdit && Number.isInteger(state.indexToEdit)) {
            items.splice(state.indexToEdit, 1);
            handleChanges(dataKey, items);
        }

        setTimeout(()=>{
            setState(state => ({
                ...state,
                indexToEdit: index,
                valueToAdd: null,
                valueToEdit: items[index],
                options: []
            }));
            isSubmitted = false;
        },250);

    }

    const handleEditChanges = (value: string) => {
        setState(state => ({ ...state, valueToEdit: value }))
    }

    const handleSubmitEdit = () => {
        if (isSubmitted) {
            return;
        }

        if (state.valueToEdit !== null && state.valueToEdit !== '') {
            items[state.indexToEdit] = state.valueToEdit;
            handleChanges(dataKey, items);
        } else if (state.indexToEdit !== null) {
            items.splice(state.indexToEdit, 1);
            handleChanges(dataKey, items);
        }

        setState(state => ({
            ...state,
            valueToAdd: null,
            valueToEdit: null,
            indexToEdit: null,
            options: []
        }));

        isSubmitted = true;
    }

    const handleOnAddKeyDown = (event: any, id: string) => {
        if (event.keyCode === 51 && event.altKey) {
            const userType = authQuery.getType();
            if (UserType.Employee === userType) {
                const value = (document.getElementById(id) as any).value;
                const cursorPos = (document.getElementById(id) as any).selectionStart;
                focus = false;
                setTimeout(() => {
                    selectShortcutService.select().subscribe((data) => {
                        focus = true;
                        data && handleAddChanges(insertString(value, data, cursorPos + 1))
                    })
                }, 1)
            }
        }
        if (event.keyCode === 13 && !event.shiftKey) {
            handleAddSubmit()
        }

        event.stopPropagation();
    }

    const handleOnEditKeyDown = (event: any, id: string) => {
        if (event.keyCode === 51 && event.altKey) {
            const userType = authQuery.getType();
            if (UserType.Employee === userType) {
                const value = (document.getElementById(id) as any).value;
                const cursorPos = (document.getElementById(id) as any).selectionStart;
                focus = false;
                setTimeout(() => {
                    selectShortcutService.select().subscribe((data) => {
                        focus = true;
                        data && handleEditChanges(insertString(value, data, cursorPos + 1))
                    })
                }, 1)
            }
        }
        if (event.keyCode === 13 && !event.shiftKey) {
            handleSubmitEdit()
        }

        event.stopPropagation();
    }

    return (
        <Box>
            {
                title &&
                <Box>
                    <span className={classes.title}>{title}</span>
                </Box>
            }
            {
                subTitle &&
                <Box pt={2}>
                    <span className={classes.subtitle}>{subTitle}</span>
                </Box>
            }
            <Box mt={2}>
                <Grid container spacing={2}>
                    {
                        items.map((item, i) => state.indexToEdit === i
                            ? (
                                <Grid item md={grid} sm={12}>
                                    <Box display='flex' alignItems='center' justifyContent="space-between" width={1} onBlur={(e) => {
                                        setTimeout(() => {
                                            if (!isFocusInCurrentTarget(e)) {
                                                focus && handleSubmitEdit()
                                            }
                                        }, 200)
                                    }}>
                                        <Box width={0.9}>
                                            <TextField
                                                autoFocus
                                                id={`${dataKey}-item-list-edit`}
                                                onKeyDown={(e) => handleOnEditKeyDown(e, `${dataKey}-item-list-edit`)}
                                                fullWidth
                                                minRows={1}
                                                maxRows={15}
                                                multiline
                                                type='text'
                                                size="small"
                                                variant="outlined"
                                                value={state.valueToEdit}
                                                InputProps={{ className: 'input' }}
                                                onChange={(e) => handleEditChanges(e.target.value)}
                                            />
                                        </Box>
                                        <Box ml={2}>
                                            <IconButton className={classes.deleteIcon} onClick={() => handleDeleteItem(i)}>
                                                <Delete />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Grid>
                            )
                            : <Grid item md={grid} sm={12}>
                                <Box id={`pmh=${dataKey}-${i}`} className={classes.item}>
                                    <Box>
                                        <TextMarkdownComponent content={item} />
                                    </Box>
                                    <Box className={classes.editIcon} onClick={() => handleEnableEdit(i)} ml={2}>
                                        <Edit />
                                    </Box>
                                </Box>
                            </Grid>
                        )
                    }
                    {
                        state.valueToAdd !== null
                            ? (
                                <Grid item md={grid} sm={12}>
                                    <Box>
                                        <TextField
                                            autoFocus
                                            id={`${dataKey}-item-list-add`}
                                            onBlur={() => {
                                                setTimeout(() => {
                                                    focus && handleAddSubmit()
                                                }, 200)
                                            }}
                                            multiline
                                            label={addText}
                                            minRows={1}
                                            maxRows={15}
                                            onKeyDown={(e) => handleOnAddKeyDown(e, `${dataKey}-item-list-add`)}
                                            fullWidth
                                            type='text'
                                            size="small"
                                            variant="outlined"
                                            value={state.valueToAdd}
                                            InputProps={{ className: 'input' }}
                                            onChange={(e) => handleAddChanges(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                            )
                            : (
                                <Grid item md={grid} sm={12}>
                                    <Box display='flex' alignItems='stretch'>
                                        <Box onClick={() => handleEnableAdd()}>
                                            <Add className={classes.addIcon} />
                                        </Box>
                                        <Box className={classes.addText} ml={2}>
                                            <span>{addText ?? 'Add'}</span>
                                        </Box>
                                    </Box>
                                </Grid>
                            )
                    }
                </Grid>
            </Box>
        </Box>
    );
}