import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 14,
            '& ::-webkit-scrollbar': {
                height: 12,
            },
            '& ::-webkit-scrollbar-track': {
                '-webkit-border-radius': '3px',
                borderRadius: '3px',
            },
            '& ::-webkit-scrollbar-thumb': {
                '-webkit-border-radius': '3px',
                borderRadius: '3px',
                background: '#dfe0dc',
                borderRight: `${colors.white} 2px solid`
            },
        },
        stickyLeft: {
            position: 'sticky',
            left: 0,
            backgroundColor: colors.white,
            zIndex: 1
        },
        tableToolbar: {
            borderBottom: '1px solid #f6f8fb'
        },
        shadowRight: {
            boxShadow: '0px 9px 15px rgba(168, 173, 198, 0.20) !important'
        },
        sideMobile: {
          width: 400,
          height: '100%',
          [theme.breakpoints.down("xs")]: {
            width: '100%',
          },
        },
        sideContainer: {
          height: '100%',
          width: '100%',
          overflow: 'auto',
          padding: 20
        },
        notificationToolTipText: {
            fontFamily: 'Be Vietnam Pro',
            fontSize: 12,
            fontWeight: 400,
            color: colors.white,
        },
        customWidth: {
            maxWidth: 'none',
            backgroundColor: colors.navy900,
            padding: 8,
        },
        arrow: {
            color: colors.navy900,
        },
        tooltipPlacement: {
            margin: '8px 0',
        },
    })
)