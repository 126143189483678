import {useEffect, useState} from "react";
import { useHistory } from "react-router";
import {Subscription} from "rxjs";
import {onEmit} from "../../../common/helpers/on-emit";
import {
    ActivateSubscriptionModel, CancellationReasonType,
    CancelSubscriptionModel,
    SubscriptionModel,
    SubscriptionStatus
} from '../../../payment/models/subscription.models';
import {subscriptionService} from "../../../payment/services/subscription.service";
import { subscriptionQuery, subscriptionStore } from "../../../payment/stores/subscriptionStore";
import { paymentPlansService } from "../../../payment/services/paymentPlans.service";
import { OfferPaymentPlanModel, PaymentPlanModel } from "../../../payment/models/paymentPlan.models";
import { PaymentPeriodModel } from "../../../payment/models/paymentPeriod.models";
import { snackService } from "../../../common/snack/state";
import {PaymentPriceModel} from "../../../payment/models/paymentPrice.models";
import { PatientModel, PaymentPlanHistoryModel } from "../../../patients/models/patient.model";
import { patientsQuery } from "../../../patients/stores/patientsStore";
import {authService} from "../../../auth/services/auth.service";
import {authQuery} from "../../../auth/stores/auth";
import {navigationService} from "../../../../services/navigation.service";
import { patientsService } from "../../../patients/services/patients.service";
import { patientProfileQuery } from "../../../employee/stores/patientProfileStore";
import { confirmService } from "../../../../services/confirm.service";
import { toCurrentTimeZone } from "../../../timezones/helpers/timezone";

interface ManagePlanComponentState {
    isLoading: boolean;
    isHistoryLoading: boolean;
    isPremiumBillingLoading: boolean;
    isSuspending: boolean;
    isOfferPlanOpen: boolean;
    isCancelPlanOpen: boolean;
    isActivateSubscriptionOpen: boolean;
    isPauseSubscriptionOpen: boolean;
    suspendMonth: number;
    menuAnchorEl: HTMLButtonElement | null;
    activeMembership: SubscriptionModel;
    paymentPlanHistories: PaymentPlanHistoryModel[];
    patient: PatientModel;
}

export function useFacade(patientId: number): [
    ManagePlanComponentState,
    (toShow: boolean) => void,
    (toShow: boolean) => void,
    (toShow: boolean) => void,
    (plan: PaymentPlanModel, period: PaymentPeriodModel) => void,
    (paymentPrice: PaymentPriceModel, startDate: Date) => void,
    (reasonType: CancellationReasonType, reason: string, date: Date) => void,
    () => void,
    () => void,
    (menuAnchorEl: HTMLButtonElement) => void,
    () => void,
    () => void,
    (value: number) => void,
    () => void
] {
    const _history = useHistory();
    const [state, setState] = useState({
        isLoading: true,
        isHistoryLoading: true,
        isPremiumBillingLoading: true,
        isSuspending: false,
        isOfferPlanOpen: false,
        isCancelPlanOpen: false,
        isActivateSubscriptionOpen: false,
        isPauseSubscriptionOpen: false,
        suspendMonth: 0,
        menuAnchorEl: null,
        activeMembership: null,
        paymentPlanHistories: [],
        patient: null
    } as ManagePlanComponentState);

    const handleOfferPlan = (plan: PaymentPlanModel, period: PaymentPeriodModel) => {
        const model = {
            patientId: patientId,
            paymentPlanId: plan.id,
            paymentPeriodId: period.id
        } as OfferPaymentPlanModel

        paymentPlansService.offerPaymentPlan(model);
    }

    const handleActivateSubscription = (paymentPrice: PaymentPriceModel, startDate: Date) => {
        const model = {
            patientId: patientId,
            paymentPriceId: paymentPrice.id,
            startDate: startDate
        } as ActivateSubscriptionModel

        setState({...state, isCancelPlanOpen: false});

        subscriptionService.Activate(model).subscribe(
            () => {
                authService.reLogin(authQuery.getTargetLocationId()).subscribe();
            }
        );
    }

    const handleCancelSubscription = (reasonType: CancellationReasonType, reason: string, date: Date) => {
        const model = {
            id: state.activeMembership.id,
            reasonType: reasonType,
            reason: reason,
            date: date
        } as CancelSubscriptionModel

        setState({...state, isCancelPlanOpen: false});

        subscriptionService.Cancel(model).subscribe(() => {
            subscriptionService.get(patientId).subscribe();
            snackService.success(`Subscription successfully canceled.`);
        });
    }

    const handleToggleAction = (menuAnchorEl: HTMLButtonElement) => {
        setState(state => ({
            ...state,
            menuAnchorEl,
        }));
    }

    const handleTogglePauseSubscriptionDialog = () => {
        setState(state => ({...state, menuAnchorEl: null, suspendMonth: 0, isPauseSubscriptionOpen: !state.isPauseSubscriptionOpen}));
    }

    const handleToggleOfferPlanDialog = (toShow: boolean) => {
        setState({...state, isOfferPlanOpen: toShow});
    }

    const handleToggleCancelSubscriptionDialog = (toShow: boolean) => {
        setState({...state, isCancelPlanOpen: toShow});
    }

    const handleToggleActivateSubscriptionDialog = (toShow: boolean) => {
        setState({...state, isActivateSubscriptionOpen: toShow});
    }

    const handleViewBillingDetail = () => {
        if (!state.patient?.linkToPaymentVendor) {
            return;
        }
        window.open(state.patient.linkToPaymentVendor, '_blank').focus();
    }

    const handleGoToOpsAdminTools = () => {
        navigationService.toOpsAdminTools(_history, patientId);
    }

    const handleChangeMonths = (value: number) => {
        setState(state => ({...state, suspendMonth: value}));
    }

    const handleSuspend = () => {
        setState(state => ({...state, isPauseSubscriptionOpen: false, isSuspending: true}));

        const model = {
            id: state.activeMembership.id,
            pauseDurationInMonth: state.suspendMonth
        }

        const cb = () => setState(state => ({...state, isSuspending: false}))
        subscriptionService.pausePlan(model).subscribe(cb, cb)
    }

    const handleReactivePlan = () => {
        handleToggleAction(null);
        confirmService.confirm(
            'Reactivate the Plan',
            'Are you sure you wish to reactivate the plan? The patient will be charged.',
            'Reactivate the Plan',
            'Cancel',
            'default')
            .subscribe(() =>  {
                subscriptionService.reactivePlan(state.activeMembership.id).subscribe()
            });
    }

    const useEffectCB = () => {
        subscriptionStore.reset();
        const subscriptions: Subscription[] = [
            onEmit<SubscriptionModel[]>(subscriptionQuery.patientSubscriptions$, subscriptions => {
                const active = subscriptions.find(s => s.status === SubscriptionStatus.Active || s.status === SubscriptionStatus.Paused);
                const premiumBillingCB = () => setState(state => ({...state, isPremiumBillingLoading: false}));
                if (subscriptions.length > 0 && subscriptions[0]?.manualPaymentId) {
                    subscriptionService.getManualPayment(subscriptions[0]?.manualPaymentId).subscribe(premiumBillingCB, premiumBillingCB);
                } else {
                    premiumBillingCB();
                }
                setState(state => ({
                    ...state,
                    activeMembership: active,
                }));
            }),
            onEmit<PaymentPlanHistoryModel[]>(patientProfileQuery.paymentPlanHistories$, paymentPlanHistories => {
                setState(state => ({
                    ...state,
                    paymentPlanHistories: paymentPlanHistories,
                }));
            }),
            onEmit<PatientModel>(patientsQuery.targetPatient$, targetPatient => {
                if (targetPatient?.id === Number(patientId)) {
                    setState(state => ({
                        ...state,
                        patient: targetPatient,
                    }));
                }
            }),
        ];

        const cb = () => setState(state => ({...state, isLoading: false}));
        const historyCB = () => setState(state => ({...state, isHistoryLoading: false}));

        subscriptionService.get(patientId).subscribe(cb, cb);

        patientsService.getPaymentPlanUpdateHistory(patientId).subscribe(historyCB, historyCB);

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, []);

    return [
        state,
        handleToggleOfferPlanDialog,
        handleToggleCancelSubscriptionDialog,
        handleToggleActivateSubscriptionDialog,
        handleOfferPlan,
        handleActivateSubscription,
        handleCancelSubscription,
        handleViewBillingDetail,
        handleGoToOpsAdminTools,
        handleToggleAction,
        handleTogglePauseSubscriptionDialog,
        handleReactivePlan,
        handleChangeMonths,
        handleSuspend
    ];
}