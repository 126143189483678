import { Box, Paper, TextField } from "@material-ui/core";
import React from 'react';
import { accountService } from '../../../../services/account.service';
import { useFacade } from "./sendProfileVerificationComponent.hooks";
import { phoneNumberInput } from "../../../common/components/PhoneNumberInput";
import { logoService } from "../../../../services/logo.service";
import { useStyles } from '../../pages/loginPage/loginPage.styles';
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";

interface SendProfileVerificationComponentProps {
    handleVerificationSent: () => void
}

export const SendProfileVerificationComponent: React.FC<SendProfileVerificationComponentProps> = (props: SendProfileVerificationComponentProps) => {
    const [
        {
            model,
            inProgress,
            errors
        },
        renderRecaptcha,
        handleChanges,
        handleSubmit,
    ] = useFacade(props.handleVerificationSent);
    const classes = useStyles();

    return (
        <Paper variant="outlined" square className={classes.loginForm}>
            <Box display="flex" justifyContent="center">
                <Box height="75px">
                    <img className={classes.logo} src={logoService.getLogo().primary} alt="logo" />
                </Box>
            </Box>
            <Box mt={2} textAlign="center">
                <span className={classes.text}>We need to verify your identity.</span> <br />
                <span className={classes.text}>Please enter the number that you would like to receive a code.</span>
                <br />
            </Box>
            <Box mt={3}>
                <form>
                    <TextField
                        fullWidth
                        required
                        size="small"
                        disabled={inProgress}
                        id={`newPhoneNumber`}
                        variant="outlined"
                        label="Phone number"
                        value={model.newPhoneNumber}
                        error={!!errors[`newPhoneNumber`]}
                        helperText={errors[`newPhoneNumber`]}
                        InputProps={{
                            className: 'input',
                            inputComponent: phoneNumberInput,
                        }}
                        onChange={(v) => {
                            handleChanges(v.target.id, v.target.value)
                        }}
                    />
                    <Box display="flex" mt={5}>
                        <Box width={0.5} mr={1}>
                            <WildHealthButton
                                id="send-profile-verification-send"
                                loading={inProgress}
                                onClick={(e) => handleSubmit(e)}
                                fullWidth
                            >
                                Send
                            </WildHealthButton>
                        </Box>
                        <Box width={0.5} ml={1}>
                            <WildHealthButton
                                id="send-profile-verification-back"
                                disabled={inProgress}
                                onClick={() => accountService.logout()}
                                fullWidth
                            >
                                Back
                            </WildHealthButton>
                        </Box>
                    </Box>
                    {renderRecaptcha()}
                </form>
            </Box>
        </Paper>
    );
}
