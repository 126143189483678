import React from 'react';
import {QuestionnaireModel, QuestionnaireResultModel} from '../../models/questionnaire.models';
import {QuestionnaireComponent} from '../questionnaireComponent/QuestionnaireComponent';
import {
    MedicalHistoryAndLifestyleSectionNames,
    MedicalHistoryAndLifestyleSections
} from '../../static/medicalHistroryQuestionnaire.static';
import {medicalHistoryQuestionnaireValidator} from './medicalHistoryQuestionnaireSurveyComponent.validator';
import {Gender} from "../../../common/models/user.models";
import {useFlags} from "launchdarkly-react-client-sdk";
import {isFeatureFlag} from "../../../common/components/featureFlags/featureFlags";
import {FeatureFlag} from "../../../common/components/featureFlags/featureFlags.models";

export interface MedicalHistoryQuestionnaireSurveyComponentProps {
    questionnaire: QuestionnaireModel;
    result: QuestionnaireResultModel;
    gender: Gender;
    patientId?: number;
    handleGoBack?: Function;
}

export const MedicalHistoryQuestionnaireSurveyComponent: React.FC<MedicalHistoryQuestionnaireSurveyComponentProps> = (props: MedicalHistoryQuestionnaireSurveyComponentProps) => {
    const {questionnaire, result, gender, patientId, handleGoBack} = props;

    const flags = useFlags();
    const rxntIntegration = isFeatureFlag(flags, FeatureFlag.RxntIntegration);
    const sections = Object.assign({}, MedicalHistoryAndLifestyleSections);

    if (rxntIntegration) {
        delete sections[MedicalHistoryAndLifestyleSectionNames.ALLERGIES];
        delete sections[MedicalHistoryAndLifestyleSectionNames.MEDICATIONS];
    }

    return (
        <QuestionnaireComponent
            questionnaire={questionnaire}
            result={result}
            sections={sections}
            validator={medicalHistoryQuestionnaireValidator}
            gender={gender}
            patientId={patientId}
            handleGoBack={handleGoBack}
        />
    )
}