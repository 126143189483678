import Axios from 'axios-observable';
import {authHeader} from '../../common/helpers/auth-header';
import {snackService} from '../../common/snack/state';
import {
    AppointmentOptions,
} from '../models/appointments.models';
import {AppointmentsStore, appointmentsStore} from '../stores/appointments';
import {Observable} from 'rxjs';

export class AppointmentsOptionsService {
    private url = `${process.env.REACT_APP_API_URL}AppointmentOptions`;

    constructor(private appointmentsStore: AppointmentsStore) {
    }

    public getOptions(patientId: number): Observable<AppointmentOptions[]> {
        const config = { headers: authHeader() };

        return new Observable(observer => {
            Axios.get<AppointmentOptions[]>(`${this.url}?patientId=${patientId}`, config).pipe()
                .subscribe(
                    response => {
                        this.appointmentsStore.update({ appointmentOptions: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        });
    }

    public createOptions(model: AppointmentOptions): Observable<AppointmentOptions> {
        const config = { headers: authHeader() };

        return new Observable(observer => {
            Axios.post<AppointmentOptions>(`${this.url}`, model, config).pipe()
                .subscribe(
                    response => {
                        this.appointmentsStore.updateAppointmentOptions(response.data);
                        snackService.success('Appointment options updated successfully');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        });
    }

    public updateOptions(model: AppointmentOptions): Observable<AppointmentOptions> {
        const config = { headers: authHeader() };

        return new Observable(observer => {
            Axios.put<AppointmentOptions>(`${this.url}`, model, config).pipe()
                .subscribe(
                    response => {
                        this.appointmentsStore.updateAppointmentOptions(response.data);
                        snackService.success('Appointment options updated successfully');
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        });
    }
}

export const appointmentsOptionsService = new AppointmentsOptionsService(appointmentsStore);
