import { TableCell } from "@material-ui/core";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";

export const ExerciseReportTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#cadbe6",
            color: theme.palette.common.black,
            border: "1px solid #A9A9A9"
        },
        body: {
            fontSize: 14,
            fontWeight: 450,
            border: "1px solid #A9A9A9"
        },
    }),
)(TableCell);