import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Subscription } from 'recompose';
import { navigationService } from '../../../../services/navigation.service';
import { onEmit } from '../../../common/helpers/on-emit';
import { IErrorState } from '../../../common/validation/error-state';
import { FellowShortModel } from '../../../employee/models/employee.models';
import { employeeService } from '../../../employee/services/employees.service';
import { employeesQuery } from '../../../employee/stores/employeesStore';
import { patientsService } from '../../../patients/services/patients.service';
import { FellowRoleType, FellowRoleSubmissionType } from '../../models/fellowship.enums';
import { PracticumPatientCreateModel } from '../../models/fellowship.model';
import { createPracticumPatientValidator } from './createPracticumPatient.validator';
import { PatientStartRegistrationModel } from "../../../patients/models/patient.model";
import { authQuery } from "../../../auth/stores/auth";

interface SelectFellowshipRoleComponentState extends IErrorState {
    selectedRole: FellowRoleType | null;
    selectedFellowRoleSubmission: FellowRoleSubmissionType | null;
    isLoading: boolean;
    pmFellows: FellowShortModel[];
    quarterFellows: FellowShortModel[];
    selectedPatientFellowId: number | null;
    selectedQuarterDate: Date;
    selectedLinkingFellowId: number | null;
    practicumPatients: PracticumPatientCreateModel[];
}

const getEmptyPracticumPatient = (number: number): PracticumPatientCreateModel => {
    return {
        fellowId: null,
        firstName: null,
        lastName: null,
        birthday: null,
        phoneNumber: null,
        email: null,
        gender: null,
        deleted: false,
        number: number,
    } as PracticumPatientCreateModel
}

export function useFacade(): [
    SelectFellowshipRoleComponentState,
    () => void,
    (practicumPatientNumber: number) => void,
    (practicumPatientNumber: number) => void,
    (practicumPatientNumber: number, value: any, field: string) => void,
    () => void,
    () => boolean,
] {
    const [state, setState] = useState({
        selectedRole: FellowRoleType.Fellow,
        selectedFellowRoleSubmission: FellowRoleSubmissionType.SubmitPracticumPatient,
        isLoading: false,
        pmFellows: [],
        quarterFellows: [],
        selectedQuarterDate: null,
        selectedPatientFellowId: null,
        selectedLinkingFellowId: null,
        practicumPatients: [getEmptyPracticumPatient(1)],
        errors: {}
    } as SelectFellowshipRoleComponentState);

    const history = useHistory();

    const handleCreatePracticumPatient = () => {
        setState({
            ...state,
            practicumPatients: state.practicumPatients.concat([getEmptyPracticumPatient(state.practicumPatients.length + 1)])
        })
    }

    const handleDeletePracticumPatient = (practicumPatientNumber: number) => {
        setState({
            ...state,
            practicumPatients: state.practicumPatients.map(patient => patient.number === practicumPatientNumber ? {
                ...patient,
                deleted: true
            } : patient)
        });
        removePracticumPatientErrors(practicumPatientNumber);
    }

    const handleUndoDeletePracticumPatient = (practicumPatientNumber: number) => {
        setState({
            ...state,
            practicumPatients: state.practicumPatients.map(patient => patient.number === practicumPatientNumber ? {
                ...patient,
                deleted: false
            } : patient)
        });
    }

    const handlePracticumPatientChanges = (practicumPatientNumber: number, value: any, field: string) => {
        const practicumPatient = state.practicumPatients.find(x => x.number === practicumPatientNumber);

        practicumPatient[field] = value;

        createPracticumPatientValidator.validateAndSetState(state, setState, field, value, practicumPatientNumber.toString());

        setState({ ...state, practicumPatients: state.practicumPatients });
    }

    const removePracticumPatientErrors = (practicumPatientNumber: number) => {
        Object.keys(state.errors).forEach(key => {
            if (key.slice(key.length - 1) === practicumPatientNumber.toString()) {
                delete state.errors[key];
            }
        });
    }

    const validatePracticumPatient = (practicumPatient: PracticumPatientCreateModel) => {
        createPracticumPatientValidator.validateObjectAndSetState(state, setState, practicumPatient, practicumPatient.number.toString())
    }

    const handleSubmitPracticumPatients = () => {
        state.practicumPatients.filter(x => !x.deleted).forEach(patient => {
            validatePracticumPatient(patient);
        });

        if (createPracticumPatientValidator.stateIsValid(state)) {
            setState({ ...state, isLoading: true });
            const patients = state.practicumPatients.filter(x => !x.deleted).map((x) => ({
                practiceId: authQuery.getCurrentPracticeId(),
                fellowId: x.fellowId,
                firstName: x.firstName,
                lastName: x.lastName,
                email: x.email,
                birthday: x.birthday,
                phoneNumber: x.phoneNumber,
                gender: +x.gender
            } as PatientStartRegistrationModel));

            patientsService.startRegistration(patients).subscribe(() => {
                setState({ ...state, isLoading: false });
                navigationService.toFellowshipSubmissionReceived(history);
            },
            () => setState({ ...state, isLoading: false }))
        }
    }

    const canGoNext = (): boolean => {
        return (state.selectedRole === FellowRoleType.Fellow && state.selectedFellowRoleSubmission === FellowRoleSubmissionType.RegisterAsPatient && state.selectedLinkingFellowId !== null) ||
            (state.selectedRole === FellowRoleType.Fellow && state.selectedFellowRoleSubmission === FellowRoleSubmissionType.SubmitPracticumPatient && state.practicumPatients.filter(x => !x.deleted).length > 0) ||
            (state.selectedRole === FellowRoleType.FellowPatient && state.selectedPatientFellowId !== null);
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<FellowShortModel[]>(employeesQuery.fellowsPublicInfo$, fellows => {
                setState(state => ({ ...state, pmFellows: fellows.filter(x => new Date(x.createdAt) >= new Date("2022-07-01")) }));
            })
        ];

        employeeService.getActiveFellowsPublicInfo(authQuery.getCurrentPracticeId());

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, []);

    return [
        state,
        handleCreatePracticumPatient,
        handleDeletePracticumPatient,
        handleUndoDeletePracticumPatient,
        handlePracticumPatientChanges,
        handleSubmitPracticumPatients,
        canGoNext
    ];
}