import React from "react";
import { WildHealthPlaceHolderBase } from "./WildHealthPlaceHolderBase";
import { useStyles } from "./commonPlaceHolder.styles";
import { RoutesConstants } from '../../../../constants/route.constants';
import { ImageConstants } from '../../../../constants/image.constants';

export interface FellowshipRosterPlaceHolderProps {
    message: string;
}

export const FellowshipRosterPlaceHolder: React.FC<FellowshipRosterPlaceHolderProps> = (props: FellowshipRosterPlaceHolderProps) => {
    const classes = useStyles();

    return (
        <WildHealthPlaceHolderBase message={props.message}>
            <img src={RoutesConstants.cdnBaseLink + ImageConstants.emptyReportPath}
                alt="img" className={classes.image} />
        </WildHealthPlaceHolderBase>
    );
}
