import Axios from 'axios-observable';
import { Observable } from 'rxjs';
import {authHeader} from "../../common/helpers/auth-header";
import {snackService} from "../../common/snack/state";
import { PatientAllergyModel } from '../models/patientAllergiesModel';
import { patientAllergiesStore, PatientAllergiesStore } from '../stores/patientAllergyStore/patientAllergies.store';

/**
 * Provides method for working with patient allergies
 */
export class PatientsAllergieservice {
    constructor(private patientAllergiesStore: PatientAllergiesStore) {
    }

    public get(): Observable<any> {
        const url = `${process.env.REACT_APP_API_URL}PatientAlergies/my`;
        const config = { headers: authHeader() };

        return new Observable((observer) => {
            Axios.get(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.patientAllergiesStore.update({patientAllergies: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        })
    }

    public getByPatientId(patientId: number): Observable<any> {
        const url = `${process.env.REACT_APP_API_URL}PatientAlergies/${patientId}`;
        const config = { headers: authHeader() };

        return new Observable((observer) => {
            Axios.get(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        this.patientAllergiesStore.update({patientAllergies: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        })
    }

    public create(model: PatientAllergyModel): Observable<PatientAllergyModel> {
        const url = `${process.env.REACT_APP_API_URL}PatientAlergies/my`;
        const config = { headers: authHeader() };

        return new Observable((observer) =>
            Axios.post(url, model, config)
            .pipe()
            .subscribe(
                response => {
                    patientAllergiesStore.create(response.data);
                    snackService.success('New Allergy Added!');
                    observer.next(response.data);
                    observer.complete();
                },
                error => {
                    snackService.commonErrorHandler(error);
                    observer.error();
                    observer.complete();
                }
            )
        );
    }

    public edit(model: PatientAllergyModel): Observable<PatientAllergyModel> {
        const url = `${process.env.REACT_APP_API_URL}PatientAlergies/my`;
        const config = { headers: authHeader() };

        return new Observable((observer) =>
            Axios.put(url, model, config)
                .pipe()
                .subscribe(
                    response => {
                        snackService.success('Changes Saved!');
                        patientAllergiesStore.edit(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        )
    }

    public delete(id: number): void {
        const url = `${process.env.REACT_APP_API_URL}PatientAlergies/${id}`;
        const config = { headers: authHeader() };

        Axios.delete(url, config)
            .pipe()
            .subscribe(
                (response) => {
                    snackService.deleteSuccess('Allergy Was Deleted!');
                    patientAllergiesStore.delete(response.data.id);
                },
                error => snackService.commonErrorHandler(error)
            );
    }
}

export const patientAllergiesService = new PatientsAllergieservice(patientAllergiesStore);