import { Box, Button, Card, CardContent, Container, Grid } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { PageSliderComponent } from "../../../common/components/PageSliderComponent";
import { displayMoney } from "../../../common/helpers/display-money";
import { PageSliderNavigationContext } from "../../../common/models/navigation.models";
import { PaymentPeriodModel } from '../../models/paymentPeriod.models';
import { PaymentBenefitsComponent } from "../paymentBenefitsComponent/PaymentBenefitsComponent";
import { useStyles } from "./selectPaymentPeriodComponent.styles";
import { useFacade } from "./SelectPaymentPeriodComponent.hooks";
import {getPeriodDiscount} from "../../helpers/getPeriodDiscount";
import commonUseStyles from '../../../common/styles/common.styles';

interface SelectPaymentPeriodComponentProps {
    navigationContext: PageSliderNavigationContext;
}

export const SelectPaymentPeriodComponent: React.FC<SelectPaymentPeriodComponentProps> = (props: SelectPaymentPeriodComponentProps) => {
    const [
        {
            selectedPlan,
            selectedPeriod
        },
        selectPeriod
    ] = useFacade(props.navigationContext);

    const classes = useStyles();
    const commonClasses = commonUseStyles();
    const navigationContext = props.navigationContext;

    if (selectedPlan.periods.length === 1) {
        return <></>
    }

    const renderPeriodPrice = (period: PaymentPeriodModel) => {
        return (
            <Box>
                <Box textAlign="center">
                    <span className="size-1_25"> Starting at </span>
                </Box>
                <Box textAlign="center">
                    <span className="size-2">
                        {displayMoney(period.startPrice, '$')}
                    </span>
                </Box>
                <Box textAlign="center">
                    <span className="size-1_25">
                        per month with {displayMoney(period.startStartupFee ?? 0, '$')} startup fee
                    </span>
                </Box>
                <Box mt={2} textAlign="center" height="65px">
                    <span className="secondary-font size-1_25">
                        Save {displayMoney(getPeriodDiscount(period) ?? 0, '$')} by paying all {period.periodInMonths} months in advance
                    </span>
                </Box>
            </Box>
        )
    }

    return (
        <>
            <Container maxWidth="lg" className="content">
                <PageSliderComponent  context={navigationContext}/>
                <Box>
                    <Box mt={1} className={clsx("text-bold", classes.chosePlan, commonClasses.primaryTitle)}>
                        Choose Your Wild Health Plan Duration
                    </Box>
                </Box>
                <Box mt={5} pb={5}>
                    <Grid container alignItems="stretch" justify='center' spacing={2}>
                        {
                            selectedPlan?.periods?.map((period) => {
                                return (
                                    <Grid key={period.id} item md={4} sm={6} xs={12}>
                                        <Box height={1} display="flex" flexDirection="column" width={1}>
                                            <Box height={1} width={1} display="flex" alignItems="stretch" mt={2}>
                                                <Card>
                                                    <CardContent className={classes.paymentPlan} style={{height: '100%'}}>
                                                        <Box height={'calc(100% - 100px)'} px={2}>
                                                            <Box width={1}>
                                                                <Box height="125px" display="flex" alignItems="center">
                                                                    <Box width={1} textAlign="center">
                                                                        <span className={clsx("text-bold", "plan-name", commonClasses.primaryTitle)}>
                                                                            {selectedPlan.displayName}
                                                                        </span>
                                                                        <span className={clsx("text-bold", "plan-name", commonClasses.primaryTitle)}>
                                                                            {period.periodInMonths} {period.periodInMonths > 1 ? "Months" : "Month"}
                                                                        </span>
                                                                    </Box>
                                                                </Box>
                                                                {
                                                                    renderPeriodPrice(period)
                                                                }
                                                            </Box>
                                                            <Box mt={5}>
                                                                <Button
                                                                    id="registration-back-button-select"
                                                                    fullWidth
                                                                    size="small"
                                                                    onClick={() => selectPeriod(period)}
                                                                    className={selectedPeriod && selectedPeriod.id === period.id ? 'wh-selected-button' : commonClasses.whButton}>
                                                                    <span className="select-button"> Select </span>
                                                                </Button>
                                                            </Box>
                                                            <Box mt={5}>
                                                                <PaymentBenefitsComponent benefits={period.benefits}/>
                                                            </Box>
                                                        </Box>
                                                    </CardContent>
                                                </Card>
                                            </Box>
                                        </Box>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Box>
            </Container>
        </>
    )
}
