import { makeStyles } from "@material-ui/core";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme) => ({
  root: {
    borderColor: colors.mediumGray,
    borderRadius: 8,
    '& .MuiOutlinedInput-root': {
      borderColor: colors.mediumGray,
      borderRadius: 8,
    },
  },
  selectInput: {
    textOverflow: "ellipsis",
    overflow: "hidden"
  },
  dialog: {
    '& .MuiDialog-paper': {
      borderRadius: 20,
    },
    [theme.breakpoints.down("xs")]: {
      '& .MuiDialog-paper': {
        borderRadius: '28px 28px 0 0',
        margin: 0,
        marginTop: 'auto',
        width: '100%'
      },
    },
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
      marginLeft: 0,
      width: '100%',
    },
  },
  dialogAction: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  subscriptionCard: {
    marginTop: 8,
    padding: 32,
    borderRadius: 2,
    border: 'none',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  },
}));
