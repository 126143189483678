import { useEffect, useState, useCallback } from "react";
import { Subscription } from "recompose";
import { onEmit } from "../../../../../common/helpers/on-emit";
import { employeesQuery } from "../../../../stores/employeesStore";
import { preauthorizeRequestService } from '../../../../../payment/services/preauthorizeRequest.service';
import { PreauthorizeRequestModel } from '../../../../../payment/models/preauthorizeRequest.model';
import { snackService } from "../../../../../common/snack/state";


interface SendSignUpButtonComponentState {
    isLoading: boolean;
    isOpen: boolean;
    search: string;
    requests: PreauthorizeRequestModel[];
    filteredRequests: PreauthorizeRequestModel[];
    selectedIds: number[];
}

export function useFacade(productId: number): [
    SendSignUpButtonComponentState,
    (index: number) => void,
    () => void,
    () => void,
    (status: boolean) => void,
    (search: string) => void,
    (id: number) => void
] {
    const [state, setState] = useState({
        isLoading: false,
        isOpen: false,
        search: '',
        requests: [],
        filteredRequests: [],
        selectedIds: []
    } as SendSignUpButtonComponentState);

    const handleSelect = (id: number) => {
        setState(state => ({
            ...state,
            selectedIds: state.selectedIds.find(x => x === id) !== undefined ? state.selectedIds.filter(x => x !== id) : [...state.selectedIds, id]
        }));
    }

    const handleSelectAll = () => {
        setState(state => ({
            ...state,
            selectedIds: state.requests.map(x => x.id)
        }));
    }

    const handleToggle = (status: boolean) => {
        setState(state => ({
            ...state,
            isOpen: status,
            selectedIds: []
        }));
    }

    const handleSend = () => {
        if (!state.selectedIds.length) return;
        setState(state => ({...state, isLoading: true}));

        const cb = () => setState(state => ({...state, isLoading: false, isOpen: false, selectedIds: []}))

        preauthorizeRequestService.resentEmployeeInvite(state.selectedIds).subscribe(cb, cb)
    }

    const handleCopyLink = (id: number) => {
        const request = state.requests.find(x => x.id === id);
        if (request) {
            preauthorizeRequestService.getRegistrationLink(id).subscribe((link) => {
                navigator.clipboard.writeText(link);
                snackService.success('Link copied to clipboard');
            })
        }
    }

    const handleFilter = useCallback((search: string) => {
        const trimedSearch = decodeURIComponent(search).trim().toLowerCase();
        const arrayTrimSearchKey = trimedSearch.split(' ');

        let filtered = state.requests?.filter(s => arrayTrimSearchKey.find(item => s.firstName?.toLowerCase().includes(item) || s.lastName?.toLowerCase().includes(item) || s.email?.toLowerCase().includes(item)) !== undefined);

        setState(state => ({ ...state, search: search, filteredRequests: filtered }));
    }, [state.requests]);

    useEffect(() => {
        handleFilter(state.search);

        return () => { };
    }, [handleFilter]);

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<PreauthorizeRequestModel[]>(employeesQuery.requests$, requests => {
                const filtered = requests.filter(x => x.employerProductId === productId);
                setState(state => ({
                    ...state,
                    requests: filtered,
                    filteredRequests: filtered
                }))
            }),
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    };

    useEffect(useEffectCB, []);

    return [
        state,
        handleSelect,
        handleSelectAll,
        handleSend,
        handleToggle,
        handleFilter,
        handleCopyLink
    ];
}