import { Store, StoreConfig } from '@datorama/akita';
import { DnaFileModel } from '../../models/dnaFlies.models';

export interface DNAFilesState {
    dnaFiles: DnaFileModel[]
}

export function createInitialState(): DNAFilesState {
    return {
        dnaFiles: []
    };
}

@StoreConfig({name: 'dnaFiles', resettable: true })
export class DNAFilesStore extends Store<DNAFilesState> {
    constructor() {
        super(createInitialState());
    }

    public updateDnaFiles(dnaFiles: DnaFileModel[]): void {
        this.update({dnaFiles: dnaFiles});
    }

    public updateDnaFile(dnaFile: DnaFileModel) {
        const dnaFiles = this.getValue().dnaFiles.map(x => x.name === dnaFile.name ? dnaFile : x);
        this.update({dnaFiles: dnaFiles});
    }
}

export const dnaFilesStore = new DNAFilesStore();