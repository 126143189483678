import {createStyles, makeStyles} from "@material-ui/core";
import {colors} from "../../../common/constants/colors";

export const useStyles = makeStyles(() =>
    createStyles({
        tooltip: {
            position: 'absolute',
            width: '350px',
            height: '100px',
            background: colors.white,
            boxShadow: ` 0px 4px 9px rgba(168, 173, 198, 0.15)`,
            borderRadius: 12,
            padding: 16,
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 14,
            zIndex: 1000
        }
    }),
);