import { Box, Button, ButtonGroup, CircularProgress, FormControl, IconButton, InputLabel, ListItemIcon, MenuItem, Popover, Select, Tooltip, Typography } from '@material-ui/core';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import GetAppIcon from '@material-ui/icons/GetApp';
import moment from 'moment';
import clsx from 'clsx';
import React from "react";
import { PageSliderNavigationContext } from '../../../common/models/navigation.models';
import { HealthReportDialogName, HealthReportModel, HealthReportPages, HealthReportStatus } from "../../models/healthReport.models";
import { NavigationStep } from '../healthReportComponent/healthReportComponent.hooks';
import { PdfLoaderComponent } from "../pdfLoader/pdfLoaderComponent";
import { useFacade } from "./healthReportToolbarComponent.hooks";
import { ActionsButton, useStyles } from "./healthReportToolbarComponent.styles";
import { ProtectedElement } from '../../../common/components/ProtectedElement';
import { PermissionType } from '../../../auth/models/auth.enums';
import { WildHealthMenuItem } from '../../../common/components/wildHealthMenu/WildHealthMenu';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleFilledOutlined';
import ReplayIcon from '@material-ui/icons/CachedRounded';
import SendIcon from '@material-ui/icons/Send';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import CloseIcon from '@material-ui/icons/Close';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { toCurrentTimeZone } from '../../../timezones/helpers/timezone';
import { colors } from "../../../common/constants/colors";
import { ChangeReviewerDialogComponent } from '../changeReviewerDialogComponent/ChangeReviewerDialogComponent';
import { PracticeProfileModel } from '../../../account/models/practiceProfile.model';
import { SelectRecommendationSetDialog } from '../selectRecommendationSetDialog/SelectRecommendationSetDialog';
import { isFeatureFlag } from '../../../common/components/featureFlags/featureFlags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlag } from '../../../common/components/featureFlags/featureFlags.models';
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { commonUseStyles } from "../../../common/styles/common.styles"


interface HealthReportToolbarComponentProps {
    patientId?: number;
    report: HealthReportModel;
    navigationContext: PageSliderNavigationContext;
    navigationSteps: Array<NavigationStep>;
    currentPage: HealthReportPages;
    practice: PracticeProfileModel
}

export const HealthReportToolbarComponent: React.FC<HealthReportToolbarComponentProps> = (props: HealthReportToolbarComponentProps) => {
    const {
        patientId,
        report,
        navigationContext,
        navigationSteps,
        currentPage,
        practice
    } = props;

    const featureFlags = useFlags();
    const isNewRecommendations = isFeatureFlag(featureFlags, FeatureFlag.ReportRecommendations);

    const [
        {
            isBannerExist,
            isGenerating,
            isPublishing,
            isRegenerating,
            isChangeReviewerOpen,
            isSelectRecommendationSetOpen,
            actionsMenuAnchor,
            isOpen,
            reports,
            dialogName,
            actionSelectReportAnchor,
            selectedReportName,
            fixedSectionShow
        },
        onCloseBanner,
        displayStatus,
        getStatusDateLabel,
        getStatusColor,
        handleReportChange,
        canGenerateReport,
        handleReportGenerate,
        canRegenerateReport,
        handleReportRegenerate,
        handleToggleCloseChangeReviewer,
        canReportDownload,
        handleReportDownload,
        handleToggleActions,
        handlePublishReport,
        canPublishReport,
        handleCloseSelectRecommendationSet,
        handleOpenSelectRecommendationSet,
        handleSelectReportToggle,
        handleGoToReportPage,
        handleFixedSectionToggle
    ] = useFacade(patientId, report, practice, isNewRecommendations, navigationContext);

    const classes = useStyles();
    const commonClasses = commonUseStyles();

    const renderDownloadButton = () => (
        <Tooltip
            placement="top"
            arrow
            className={classes.toolButton}
            title="Download"
            key='generate-btn-download'
        >
            <ActionsButton
                id="health-report-toolbar-download"
                className={!canReportDownload(report.status.status) ? classes.disabledBtn : ''}
                onClick={() => handleReportDownload()}
            >
                <GetAppIcon className={classes.iconSize} />
            </ActionsButton>
        </Tooltip>
    );

    const selectReport = (
        <FormControl
            fullWidth
            color='primary'
            variant="outlined"
            size="small"
        >
            <InputLabel>Select Report</InputLabel>
            <Select
                fullWidth
                label="Select Report"
                value={report?.id ?? 0}
                onChange={(event) => handleReportChange(Number(event.target.value))}
            >
                {
                    reports.map((report, index) =>
                        <MenuItem
                            key={report.id}
                            value={report.id}
                        >
                            {index === reports.length - 1 ? `Current Report` : `Report (${moment(toCurrentTimeZone(report.status.date)).format("MMM DD, yyyy")})`}
                        </MenuItem>)
                }
            </Select>
        </FormControl>
    );

    const selectReportMobile = (
        <FormControl
            fullWidth
            color='primary'
            variant="outlined"
            size="small"
            classes={{ root: classes.reportSelectRoot }}
        >
            <Select
                fullWidth
                value={report?.id ?? 0}
                onChange={(event) => handleReportChange(Number(event.target.value))}
                classes={{ root: classes.reportSelectRoot }}
            >
                {
                    reports.map((report, index) =>
                        <MenuItem
                            key={report.id}
                            value={report.id}
                        >
                            {index === reports.length - 1 ? `Current Report` : `Report (${moment(toCurrentTimeZone(report.status.date)).format("MMM DD, yyyy")})`}
                        </MenuItem>)
                }
            </Select>
        </FormControl>
    );

    const renderGenerateButton = () => (
        <Tooltip
            placement="top" arrow
            key='generate-btn-generate'
            title={isGenerating ? 'Generating...' : 'Generate New Report'}
        >
            <ActionsButton
                id="health-report-toolbar-generate"
                onClick={() => isNewRecommendations ? handleOpenSelectRecommendationSet(HealthReportDialogName.New) : handleReportGenerate()}
                className={!canGenerateReport() ? classes.disabledBtn : ''}
            >
                {
                    isGenerating
                        ? <CircularProgress size={24} style={{ color: colors.main }} />
                        : <PlayCircleOutlineIcon className={classes.iconSize} />
                }
            </ActionsButton>
        </Tooltip>
    );

    const renderRegenerateButton = () => {
        let tooltip = 'Update Current Report';

        if (report.status.status === HealthReportStatus.Submitted) {
            tooltip = 'Published reports cannot be updated. Please generate a new report';
        }

        if (report.status.status === HealthReportStatus.Preparing) {
            tooltip = 'Preparing reports cannot be updated. Please generate a new report';
        }

        return (
            <Tooltip
                placement="top" arrow
                key='regenerate-btn-regenerate'
                title={tooltip}
            >
                <ActionsButton
                    id="health-report-toolbar-regenerate"
                    onClick={() => isNewRecommendations ? handleOpenSelectRecommendationSet(HealthReportDialogName.Update) : handleReportRegenerate()}
                    className={!canRegenerateReport() ? classes.disabledBtn : ''}
                >
                    {
                        isRegenerating
                            ? <CircularProgress size={24} style={{ color: colors.main }} />
                            : <ReplayIcon className={classes.iconSize} />
                    }
                </ActionsButton>
            </Tooltip>
        );
    }

    const renderPublishButton = () => (
        <Tooltip
            placement="top" arrow
            key='regenerate-btn-publish'
            title='Publish Report'
        >
            <ActionsButton
                id="health-report-toolbar-publish-report"
                onClick={() => handlePublishReport()}
                className={!canPublishReport() ? classes.disabledBtn : ''}
            >
                {
                    isPublishing
                        ? <CircularProgress size={24} style={{ color: colors.main }} />
                        : <SendIcon className={classes.rotateIcon} />
                }
            </ActionsButton>
        </Tooltip>
    );

    return (
        <Box className={classes.root}>
            {isBannerExist &&
                <Box className={classes.infoWrapper}>
                    <Box display='flex' className={classes.infoContainer}>
                        <InfoIcon style={{ fill: colors.white }} />
                        <Box width='100%' pl={2} pr={2} color={colors.white}>This report needs to be reviewed and signed.</Box>
                        <Box>
                            <IconButton id="health-report-toolbar-close-banner" onClick={() => onCloseBanner()}>
                                <CloseIcon style={{ fill: colors.white }} />
                            </IconButton>
                        </Box>

                    </Box>
                </Box>}
            <Box className={classes.toolContainer}>
                <Box className={classes.status} px={2}>
                    <Box mt={3}>
                        <Box display="flex">
                            <Box m={1} className={classes.boxCircle} style={{ backgroundColor: getStatusColor() }}></Box>
                            <span className="text-medium"> {displayStatus()}</span>
                        </Box>
                        <Box>
                            <span className="size-0_75">{getStatusDateLabel()}</span>
                        </Box>
                    </Box>

                    <Box mt={2}>
                        <Box className={classes.toolActions}>
                            <Box width="200px">{selectReport}</Box>

                            <ProtectedElement
                                element={patientId != null ? [renderGenerateButton(), renderRegenerateButton(), renderPublishButton()] : <></>}
                                permissions={[PermissionType.HealthReportGeneration]}
                            />
                            {renderDownloadButton()}
                        </Box>

                        <Box className={classes.toolActionsPad}>
                            <Tooltip className={classes.toolButton} title="Open Actions">
                                <Button id="health-report-toolbar-actions" onClick={(event) => handleToggleActions(event.currentTarget)}>
                                    <MoreVertIcon />
                                </Button>
                            </Tooltip>

                            <Popover
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(actionsMenuAnchor)}
                                anchorEl={actionsMenuAnchor}
                                onClose={() => handleToggleActions()}
                            >
                                <Box m='16px 8px 0 8px'>
                                    {selectReport}
                                </Box>

                                <ProtectedElement
                                    element={
                                        patientId != null &&
                                        <WildHealthMenuItem 
                                        id="health-report-toolbar-popover-generate" 
                                        onClick={() => isNewRecommendations ? handleOpenSelectRecommendationSet(HealthReportDialogName.New) : handleReportGenerate()} 
                                        disabled={!canGenerateReport()}>
                                            <ListItemIcon>
                                                {
                                                    isGenerating
                                                        ? <CircularProgress size={24} style={{ color: colors.main }} />
                                                        : <PlayCircleOutlineIcon />
                                                }
                                            </ListItemIcon>
                                            <Typography variant="inherit">{isGenerating ? 'Generating...' : 'Generate'}</Typography>
                                        </WildHealthMenuItem>
                                    }
                                    permissions={[PermissionType.HealthReportGeneration]}
                                />

                                <ProtectedElement
                                    element={
                                        patientId != null &&
                                        <WildHealthMenuItem 
                                        id="health-report-toolbar-popover-regenerate" 
                                        onClick={() => isNewRecommendations ? handleOpenSelectRecommendationSet(HealthReportDialogName.Update) : handleReportRegenerate()} 
                                        disabled={!canRegenerateReport()}>
                                            <ListItemIcon>
                                                {
                                                    isRegenerating
                                                        ? <CircularProgress size={24} style={{ color: colors.main }} />
                                                        : <ReplayIcon />
                                                }
                                            </ListItemIcon>
                                            <Typography variant="inherit">{isRegenerating ? 'Generating...' : 'Regenerate'}</Typography>
                                        </WildHealthMenuItem>
                                    }
                                    permissions={[PermissionType.HealthReportGeneration]}
                                />
                                <ProtectedElement
                                    element={
                                        patientId != null &&
                                        <WildHealthMenuItem id="health-report-toolbar-popover-publish" onClick={() => handlePublishReport()} disabled={!canPublishReport()}>
                                            <ListItemIcon>
                                                {
                                                    isPublishing
                                                        ? <CircularProgress size={24} style={{ color: colors.main }} />
                                                        : <SendIcon className={classes.rotateIcon} />
                                                }
                                            </ListItemIcon>
                                            <Typography variant="inherit">{isPublishing ? 'Publishing...' : 'Publish'}</Typography>
                                        </WildHealthMenuItem>
                                    }
                                    permissions={[PermissionType.HealthReportGeneration]}
                                />
                                <WildHealthMenuItem id="health-report-toolbar-popover-download" onClick={() => handleReportDownload()} disabled={!canReportDownload(report.status.status)}>
                                    <ListItemIcon>
                                        <GetAppIcon />
                                    </ListItemIcon>
                                    <Typography variant="inherit">Download</Typography>
                                </WildHealthMenuItem>
                            </Popover>
                        </Box>

                        <Box className={classes.toolActionsMobile}>
                            {selectReportMobile}
                        </Box>
                    </Box>
                </Box>
                <PdfLoaderComponent isOpen={isOpen} />
            </Box>

            <Box className={classes.navigationContainer}>
                <Button
                    id="health-report-toolbar-go-back"
                    key={-1}
                    color="primary"
                    onClick={() => navigationContext.goBack()}
                    disabled={!navigationContext.canGoBack()}
                    className={classes.navigationButton}
                >
                    <ArrowBackIosRoundedIcon />
                </Button>
                <Box className={clsx("flat-scroll", classes.stepsContainer)}>
                    <ButtonGroup id='navigation-step-container'>
                        {
                            navigationSteps.map((item, index) =>
                                <Button
                                    id={`${item.from}-step`}
                                    key={index * index}
                                    color='primary'
                                    onClick={() => navigationContext.goTo(item.from)}
                                    className={clsx(classes.stepButton, {
                                        [classes.stepButtonSelected]: item.from <= currentPage && currentPage <= item.to,
                                    })}
                                >
                                    {item.name}
                                </Button>
                            )
                        }
                    </ButtonGroup>
                </Box>
                <Button
                    id="health-report-toolbar-go-next"
                    key={navigationSteps.length}
                    color="primary"
                    onClick={() => navigationContext.goNext()}
                    disabled={!navigationContext.canGoNext()}
                    className={classes.navigationButton}
                >
                    <ArrowForwardIosRoundedIcon />
                </Button>
            </Box>
            <Box className={classes.navigationMobileContainer} px={2}>
                <WildHealthButton
                    fullWidth
                    id="select-report-setion-button" 
                    color='tertiary'
                    onClick={(event) => handleSelectReportToggle(event.currentTarget)}
                >
                    {selectedReportName ? selectedReportName : 'Select Report section'}
                    <ExpandMoreIcon />
                </WildHealthButton>

                <Popover
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={Boolean(actionSelectReportAnchor)}
                    anchorEl={actionSelectReportAnchor}
                    onClose={() => handleSelectReportToggle()}
                    className={classes.reportPagePopover}
                >
                    {
                        navigationSteps.map((item, index) =>
                            <Button
                                fullWidth
                                id={`${item.from}-step`}
                                key={index * index}
                                color='primary'
                                onClick={() => handleGoToReportPage(item)}
                                className={clsx(classes.stepMobileButton, {
                                    [classes.stepButtonSelected]: item.from <= currentPage && currentPage <= item.to,
                                })}
                            >
                                {item.name}
                            </Button>
                        )
                    }
                </Popover>

                {fixedSectionShow && (
                    <Box position="fixed" right={0} bottom="55px" zIndex={1202} px={2} className={classes.mobileDownloadSection}>
                        <Box width={1} p={1} pr={0} className={classes.fixedSection}>
                            <Box flex={1} mr={0.5} className={clsx(commonClasses.size12, commonClasses.textRegular, commonClasses.colorWhite)}>Please download the report for a better experience</Box>
                            <WildHealthButton
                                id="health-report-fixed-download-button" 
                                color="primary"
                                width="132px"
                                disabled={!canReportDownload(report.status.status)}
                                onClick={() => handleReportDownload()}
                            >
                                <GetAppIcon className={classes.downloadIcon} />
                                <Box ml="10px">Download</Box>
                            </WildHealthButton>
                            <IconButton id="health-report-fixed-section-close-button" className={classes.fixedCloseButton} onClick={() => handleFixedSectionToggle()}>
                                <CloseIcon style={{ fill: colors.white }} />
                            </IconButton>
                        </Box>
                    </Box>
                )}
            </Box>
            <ChangeReviewerDialogComponent
                patientId={report.owner.id}
                reportId={report.id}
                reportReviewerId={report.reviewer?.id}
                open={isChangeReviewerOpen}
                handleClose={() => handleToggleCloseChangeReviewer()}
            />
            {!isGenerating && !isRegenerating && <SelectRecommendationSetDialog
                title={dialogName}
                patientId={report.owner.id}
                open={isSelectRecommendationSetOpen}
                handleClose={() => handleCloseSelectRecommendationSet()}
                handleReportGenerate={handleReportGenerate}
                handleReportRegenerate={handleReportRegenerate}
            />}
        </Box>
    );
}