import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            padding: 5,
            [theme.breakpoints.up('sm')]: {
                padding: 30,
            },
            [theme.breakpoints.down('xs')]: {
                padding: 16,
                minHeight: 'calc(100vh - 225px)',
            },
        },
        downloadIcon: {
            fill: colors.main
        },
        deleteIcon: {
            fill: colors.bad
        }
    })
);
