import {ValueInputModel, VitalValueSourceType} from "../../inputs/models/input.models";

export interface TileVitalModel {
    HeartRate?: VitalDetailsModel | null;
    HRV?: VitalDetailsModel | null;
    RHR?: VitalDetailsModel | null;
    Weight?: VitalDetailsModel | null;
    Height?: VitalDetailsModel | null;
    BMI?: VitalDetailsModel | null;
    Waist?: VitalDetailsModel | null;
    Steps?: VitalDetailsModel | null;
    Calories?: VitalDetailsModel | null;
    SystolicBloodPressure?: VitalDetailsModel | null;
    DiastolicBloodPressure?: VitalDetailsModel | null;
}

export interface VitalDetailsModel {
    id: number | null;
    displayName?: string;
    dimension?: string;
    value: number | null;
    valueId: number | null;
    date: Date | null;
}

export interface VitalModel {
    id: number;
    name: string;
    displayName: string;
    dimension: string;
    values: VitalValueModel[];
}

export interface VitalValueModel extends ValueInputModel {

}

export function createVitalValueModel(id: number, name: string, value: number, date: Date, source: VitalValueSourceType) {
    return {
        id: id,
        name: name,
        value: value,
        isChanged: false,
        isInitialized: true,
        editable: true,
        notification: null,
        date: date,
        sourceType: source
    } as VitalValueModel;
}

export interface CreateVitalModel {
    name: string;
    value: number;
    dateTime: Date;
}

export interface UpdateVitalValueModel {
    vitalId: number;
    valueId: number;
    value: number;
    dateTime: Date;
}

export interface GeneralInputsVitalsModel {
  systolicBloodPressure: VitalDetailsModel | null;
  weight: VitalDetailsModel | null;
}

export enum VitalsDateRangeType {
    None = 0,
    SevenDays = 1,
    ThirtyDays = 5,
    SixMonth = 10,
    Year = 15
}
