import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { onEmit } from "../../../common/helpers/on-emit";
import { IErrorState } from "../../../common/validation/error-state";
import { newPatientAllergyModel, PatientAllergyModel } from "../../models/patientAllergiesModel";
import { allergiesService } from "../../services/allergies.service";
import { patientAllergiesService } from "../../services/patientAllergiesService";
import { patientAllergiesQuery } from "../../stores/patientAllergyStore/patientAllergies.query";
import { PatientAllergyUpdateProps } from "./PatientAllergyUpdateComponent";
import { patientAllergyUpdateComponentValidator} from "./patientAllergyUpdateComponent.validator";

interface PatientAllergyUpdateComponentState extends IErrorState {
    model: PatientAllergyModel;
}

const getInitialState = () => ({
    model: newPatientAllergyModel(),
    errors: {}
});


export function useFacade(props: PatientAllergyUpdateProps): [
    PatientAllergyUpdateComponentState,
    Function,
    Function,
    Function
] {

    const [state, setState] = useState(getInitialState() as PatientAllergyUpdateComponentState);

    const handleChanges = (field: string, value: string) => {
        patientAllergyUpdateComponentValidator.validateAndSetState(state, setState, field, value);

        const model = {...state.model};
        model[field] = value
        setState({...state, model: model})
    }

    const handleSave = (patientId: number) => {
        patientAllergyUpdateComponentValidator.validateObjectAndSetState(state, setState, state.model);
        if (!patientAllergyUpdateComponentValidator.stateIsValid(state)) {
            return;
        }

        setState(state => ({
            ...state, 
            ...Object.assign({}, getInitialState())
        }));
        props.handleClose();

        const service = props.patientId ? allergiesService : patientAllergiesService;
        const action = state.model.id ? service.edit : service.create;
        action(state.model, props.patientId).subscribe();
    }

    const handleClose = () => {
        setState(state => ({
            ...state,
            ...getInitialState()
        }))

        props.handleClose();
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<PatientAllergyModel>(patientAllergiesQuery.editModel$, editModel => {
                setState(state => ({...state, model: editModel }));
            })
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [
        state,
        handleClose,
        handleChanges,
        handleSave
    ];
}