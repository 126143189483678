import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { onEmit } from "../../../common/helpers/on-emit";
import { CallToActionModel, ActionStatus, ReactOnCallToActionModel, ActionReactionType, ActionType } from "../../../patients/models/patient.model";
import { patientsService } from "../../../patients/services/patients.service";
import { patientsQuery, patientStore } from "../../../patients/stores/patientsStore";

interface StandardUpsellDialogState {
    isSubmitting: boolean;
    isRequestOpen: boolean;
    isReceivedOpen: boolean;
    callToActions: CallToActionModel[];
}

export function useFacade(): [
    StandardUpsellDialogState,
    () => void,
] {
    const [state, setState] = useState({
        isSubmitting: false,
        isRequestOpen: false,
        isReceivedOpen: false,
    } as StandardUpsellDialogState);

    const handleRequest = () => {
        setState(state => ({ ...state, isSubmitting: true }))
        const cb = () => setState(state => ({ ...state, isSubmitting: false }));
        const request = {
            id: state.callToActions[0].id,
            reactionType: ActionReactionType.Accept,
            details: 'Accept'
        } as ReactOnCallToActionModel;
        patientsService.requestStandardUpsell(request).subscribe(() => {
            patientStore.toggleRequestDialog(false)
            patientStore.toggleRequestReceivedDialog(true)
        }, cb);
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<CallToActionModel[]>(patientsQuery.callToActions$, callToActions => {
                setState(state => ({
                    ...state,
                    callToActions: callToActions.filter(x => x.type === ActionType.StandardPlanUpSell && x.status === ActionStatus.Active),
                }));
            }),
            onEmit<boolean>(patientsQuery.isRequestOpen$, isRequestOpen => {
                setState(state => ({
                    ...state,
                    isRequestOpen,
                }));
            }),
            onEmit<boolean>(patientsQuery.isReceivedOpen$, isReceivedOpen => {
                setState(state => ({
                    ...state,
                    isReceivedOpen,
                }));
            }),
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, []);

    return [state, handleRequest];
}