import { useEffect, useState } from "react";
import { IErrorState } from "../../../common/validation/error-state";
import { BannerModel, BannerDisplayType } from "../../../../models/banner.model";
import { modifyBannerValidator } from "../manageBannersComponent/modifyBanner.validator";
import { bannersService } from "../../services/banners.service";

interface CreatePromoCodesState extends IErrorState {
  isLoading: boolean;
  isSubmitted: boolean;
  params: BannerModel;
}

export function useFacade(): [
  CreatePromoCodesState,
  (field: string, value: any) => void,
  () => void,
  () => void,
] {
    const initialParmas = {
      id: 0,
      name: '',
      copy: '',
      type: BannerDisplayType.All,
      isActive: true,
      priority: 0
    }
    
    const [state, setState] = useState({
      isLoading: true,
      isSubmitted: false,
      params: initialParmas,
      errors: {},
    } as CreatePromoCodesState);

    const handleChanges = (field: string, value: any) => {
      modifyBannerValidator.validateAndSetState(state, setState, field, value);

      const params = state.params;
      
      params[field] = value;

      setState({...state, params: Object.assign({}, params)});
    }

    const handleCreate = () => {
      modifyBannerValidator.validateObjectAndSetState(state, setState, state.params);
      if (!modifyBannerValidator.stateIsValid(state)) {
        return;
      }

      setState({...state, isSubmitted: true })

      const cb = () => setState(state => ({ ...state, isSubmitted: false }));

      bannersService.createBanner(state.params).subscribe(() => {
        setState(state => ({
          ...state,
          isSubmitted: false,
          params: Object.assign({}, initialParmas)
        }))
      }, cb);
    }

    const handleCancel = () => {
      setState(state => ({
        ...state,
        params: Object.assign({}, initialParmas)
      }))
    }

    useEffect(() => {
      setState(state => ({
        ...state,
        isLoading: false,
        params: Object.assign({}, initialParmas)
      }))
    }, []);

    return [
      state,
      handleChanges,
      handleCancel,
      handleCreate
    ];
}