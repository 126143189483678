import { Box, Divider, useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { ExpandableComponent } from '../../common/components/expandableComponent/ExpandableComponent';
import { WildHealthButton } from '../../common/components/wildHealthButton/WildHealthButton';
import commonUseStyles from '../../common/styles/common.styles';
import { RecommendationSingleSelectComponent } from '../components/recommendationSingleSelectComponent/RecommendationSingleSelectComponent';
import { HealthReportSections, recommendationNameOld, RecommendationTypes, RecommendationTypesNumbers, HealthReportMode } from "../models/healthReport.models";
import { HealthReportBasePage, HealthReportMobileBasePage } from "./HealthReportBasePage";
import { HealthReportPageProps } from "./healthReportPageProps";
import { useFacade } from "./healthReportRecommendationsPage.hooks"

export const HealthReportRecommendationsPage: React.FC<HealthReportPageProps> = (props: HealthReportPageProps) => {
    const {
        pageNumber,
        isChanged,
        mode,
        practice,
        recommendations,
        handleSelected,
        handleChangesNewRecommendation,
        handleAddRecommendation,
        handleSaveChangesNew,
        handleCancel,
    } = props;


    const [
        state,
        handleExpandSection,
    ] = useFacade();

    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const sectionsContent = {
        [RecommendationTypes.Macronutrient]:
            <RecommendationSingleSelectComponent
                currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.Macronutrient)}
                type={RecommendationTypesNumbers.Macronutrient}
                handleSelected={handleSelected}
                handleChanges={handleChangesNewRecommendation}
                handleAddRecommendation={handleAddRecommendation}
                mode={mode}
                isGeneralPage={true}
            />,
        [RecommendationTypes.VitaminsAndMicronutrients]:
            <RecommendationSingleSelectComponent
                currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.VitaminsAndMicronutrients)}
                type={RecommendationTypesNumbers.VitaminsAndMicronutrients}
                handleSelected={handleSelected}
                handleChanges={handleChangesNewRecommendation}
                handleAddRecommendation={handleAddRecommendation}
                mode={mode}
                isGeneralPage={true}
            />,
        [RecommendationTypes.KryptoniteFoods]:
            <RecommendationSingleSelectComponent
                currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.KryptoniteFoods)}
                type={RecommendationTypesNumbers.KryptoniteFoods}
                handleSelected={handleSelected}
                handleChanges={handleChangesNewRecommendation}
                handleAddRecommendation={handleAddRecommendation}
                mode={mode}
                isGeneralPage={true}
            />,
        [RecommendationTypes.SuperFoods]:
            <RecommendationSingleSelectComponent
                currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.SuperFoods)}
                type={RecommendationTypesNumbers.SuperFoods}
                handleSelected={handleSelected}
                handleChanges={handleChangesNewRecommendation}
                handleAddRecommendation={handleAddRecommendation}
                mode={mode}
                isGeneralPage={true}
            />,
        [RecommendationTypes.ExerciseAndRecovery]:
            <RecommendationSingleSelectComponent
                currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.ExerciseAndRecovery)}
                type={RecommendationTypesNumbers.ExerciseAndRecovery}
                handleSelected={handleSelected}
                handleChanges={handleChangesNewRecommendation}
                handleAddRecommendation={handleAddRecommendation}
                mode={mode}
                isGeneralPage={true}
            />,
        [RecommendationTypes.Sleep]:
            <RecommendationSingleSelectComponent
                currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.Sleep)}
                type={RecommendationTypesNumbers.Sleep}
                handleSelected={handleSelected}
                handleChanges={handleChangesNewRecommendation}
                handleAddRecommendation={handleAddRecommendation}
                mode={mode}
                isGeneralPage={true}
            />,
        [RecommendationTypes.Neurobehavioral]:
            <RecommendationSingleSelectComponent
                currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.Neurobehavioral)}
                type={RecommendationTypesNumbers.Neurobehavioral}
                handleSelected={handleSelected}
                handleChanges={handleChangesNewRecommendation}
                handleAddRecommendation={handleAddRecommendation}
                mode={mode}
                isGeneralPage={true}
            />,
        [RecommendationTypes.Microbiome]:
            <RecommendationSingleSelectComponent
                currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.Microbiome)}
                type={RecommendationTypesNumbers.Microbiome}
                handleSelected={handleSelected}
                handleChanges={handleChangesNewRecommendation}
                handleAddRecommendation={handleAddRecommendation}
                mode={mode}
                isGeneralPage={true}
            />,
        [RecommendationTypes.Cardiovascular]:
            <RecommendationSingleSelectComponent
                currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.Cardiovascular)}
                type={RecommendationTypesNumbers.Cardiovascular}
                handleSelected={handleSelected}
                handleChanges={handleChangesNewRecommendation}
                handleAddRecommendation={handleAddRecommendation}
                mode={mode}
                isGeneralPage={true}
            />,
        [RecommendationTypes.Dementia]:
            <RecommendationSingleSelectComponent
                currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.Dementia)}
                type={RecommendationTypesNumbers.Dementia}
                handleSelected={handleSelected}
                handleChanges={handleChangesNewRecommendation}
                handleAddRecommendation={handleAddRecommendation}
                mode={mode}
                isGeneralPage={true}
            />,
        [RecommendationTypes.InsulinResistance]:
            <RecommendationSingleSelectComponent
                currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.InsulinResistance)}
                type={RecommendationTypesNumbers.InsulinResistance}
                handleSelected={handleSelected}
                handleChanges={handleChangesNewRecommendation}
                handleAddRecommendation={handleAddRecommendation}
                mode={mode}
                isGeneralPage={true}
            />,
        [RecommendationTypes.Inflammation]:
            <RecommendationSingleSelectComponent
                currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.Inflammation)}
                type={RecommendationTypesNumbers.Inflammation}
                handleSelected={handleSelected}
                handleChanges={handleChangesNewRecommendation}
                handleAddRecommendation={handleAddRecommendation}
                mode={mode}
                isGeneralPage={true}
            />,
        [RecommendationTypes.Longevity]:
            <RecommendationSingleSelectComponent
                currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.Longevity)}
                type={RecommendationTypesNumbers.Longevity}
                handleSelected={handleSelected}
                handleChanges={handleChangesNewRecommendation}
                handleAddRecommendation={handleAddRecommendation}
                mode={mode}
                isGeneralPage={true}
            />,
        [RecommendationTypes.Supplements]:
            <RecommendationSingleSelectComponent
                currentRecommendation={recommendations?.filter(el => el.type === RecommendationTypesNumbers.Supplements)}
                type={RecommendationTypesNumbers.Supplements}
                handleSelected={handleSelected}
                handleChanges={handleChangesNewRecommendation}
                handleAddRecommendation={handleAddRecommendation}
                mode={mode}
                isGeneralPage={true}
            />,
    } as { [id: number]: JSX.Element }


    return (
        props.mode !== HealthReportMode.PrintMode && isSmallScreen ? (
            <HealthReportMobileBasePage section={HealthReportSections.Recommendations} pageNumber={pageNumber} practice={practice}>
                <Box px={2} display="flex" justifyContent="space-between">
                    <Box></Box>
                    {isChanged && <Box mt={3} mr={4} display="flex" alignItems="flex-end">
                        <Box mr={1.5}>
                            <WildHealthButton
                                id="health-recommendation-new-cancel-all"
                                color="tertiary"
                                onClick={() => handleCancel()}
                            >
                                Cancel
                            </WildHealthButton>
                        </Box>
                        <Box>
                            <WildHealthButton
                                id="health-recommendation-new-save-all"
                                onClick={() => handleSaveChangesNew()}
                            >
                                Save Changes
                            </WildHealthButton>
                        </Box>
                    </Box>}
                </Box>

                <Box p={2}>
                    {
                        Object.keys(sectionsContent).map(key => (
                            <Box id={`recommendation-component-${key}`} mt={3}>
                                <ExpandableComponent
                                    divider={false}
                                    title={recommendationNameOld[key]}
                                    content={sectionsContent[key]}
                                    expanded={state.sections[key]}
                                    refCurrent={null}
                                    handleExpand={() => handleExpandSection(+key)}
                                />
                                <Box mt={2}>
                                    <Divider className={commonClasses.renderDivider} />
                                </Box>
                            </Box>
                        ))
                    }
                </Box>
            </HealthReportMobileBasePage>
        ) : (
            <HealthReportBasePage section={HealthReportSections.Recommendations} pageNumber={pageNumber} practice={practice}>
                <Box height="60px" display="flex" justifyContent="space-between">
                    <Box></Box>
                    {isChanged && <Box mt={3} mr={4} display="flex" alignItems="flex-end">
                        <Box mr={1.5}>
                            <WildHealthButton
                                id="health-recommendation-new-cancel-all"
                                color="tertiary"
                                onClick={() => handleCancel()}
                            >
                                Cancel
                            </WildHealthButton>
                        </Box>
                        <Box>
                            <WildHealthButton
                                id="health-recommendation-new-save-all"
                                onClick={() => handleSaveChangesNew()}
                            >
                                Save Changes
                            </WildHealthButton>
                        </Box>
                    </Box>}
                </Box>

                <Box p={4} pt={0}>
                    {
                        Object.keys(sectionsContent).map(key => (
                            <Box id={`recommendation-component-${key}`} mt={3}>
                                <ExpandableComponent
                                    divider={false}
                                    title={recommendationNameOld[key]}
                                    content={sectionsContent[key]}
                                    expanded={state.sections[key]}
                                    refCurrent={null}
                                    handleExpand={() => handleExpandSection(+key)}
                                />
                                <Box mt={2}>
                                    <Divider className={commonClasses.renderDivider} />
                                </Box>
                            </Box>
                        ))
                    }
                </Box>
            </HealthReportBasePage>
        )
    )
}