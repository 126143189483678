import moment from 'moment'
import {AppointmentTypeModel} from "../models/appointments.models";
import {AppointmentWithType} from "../models/appointments.enums";

export const getAvailableWithTypes = (types: AppointmentTypeModel[]) => {
    const withTypes = types.map(x => x.configurations.map(t => t.withType)).flat();
    return withTypes.filter((v, i, a) => a.indexOf(v) === i);
}

export const getAppointmentTypesByWithType = (types: AppointmentTypeModel[], withType: AppointmentWithType) => {
    return types.filter(x => x.configurations.some(t => t.withType === withType));
}

export const getAvailabilityStartDate = (): Date => {
    const startDate = new Date();
    startDate.setHours(0,0,0,0);
    return startDate;
}

export const getAvailabilityEndDate = (): Date => {
    return moment(new Date()).add(6, 'months').add(-3, 'day').toDate()
}

export const getAvailableAppointmentType = (appointmentTypes: AppointmentTypeModel[], withType: AppointmentWithType) => {
    const type = appointmentTypes
        ?.find(x => x.configurations.some(t => t.withType === withType) && x.isScheduleAvailable);

    if (!type) {
        return null;
    }

    return type;
}

export const getTargetAppointmentType = (appointmentTypes: AppointmentTypeModel[], withType: AppointmentWithType) => {
    const type = appointmentTypes
        ?.find(x => x.configurations.some(t => t.withType === withType) && x.isScheduleAvailable);

    if (!type) {
        return null;
    }

    return type;
}

export const getAppointmentMinDate = (bufferInDays: number = 1) => {
    const currDate = new Date();
    return new Date(
        currDate.getFullYear(),
        currDate.getMonth(),
        currDate.getDate() + bufferInDays,
        currDate.getHours(),
        currDate.getMinutes(),
        currDate.getSeconds());
}