import { Box, Checkbox, TextField } from '@material-ui/core';
import React from 'react';
import { Add } from '@material-ui/icons';
import { useStyles } from './recommendationSelectComponent.styles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { colors } from '../../../common/constants/colors';
import { RecommendationDisplayModel } from '../../models/healthReport.models';
import { RecommendationPlaceHolder } from '../../../common/components/wildHealthPlaceHolder/RecommendationPlaceHolder';
import { selectShortcutService } from '../../services/selectShortcut.service';
import { UserType } from '../../../auth/models/auth.enums';
import { authQuery } from '../../../auth/stores/auth';
import { insertString } from '../../../common/helpers/insert-string';

export interface RecommendationSelectComponentProps {
    currentRecomemendation: RecommendationDisplayModel[],
    type: number,
    handleSelected: (id: number | string, isSelected: boolean) => void,
    handleChanges: (id: number | string, value: string) => void,
    handleAddRecommendation: (id: number, index: number) => void,
}

export const RecommendationSelectComponent: React.FC<RecommendationSelectComponentProps> = (props: RecommendationSelectComponentProps) => {
    const {
        currentRecomemendation,
        type,
        handleSelected,
        handleChanges,
        handleAddRecommendation,
    } = props;

    const classes = useStyles();
    const controlIcon = <CheckBoxOutlineBlankIcon style={{ fill: colors.mediumGray }} />;
    const controlCheckedIcon = <CheckBoxIcon style={{ fill: colors.main }} />
    
    const handleOnKeyDown = (id: string | number, event:any, idFild: string) => {
        if (event.keyCode === 51 && event.altKey) {
            const userType = authQuery.getType();
            if (UserType.Employee === userType) {
                const value = (document.getElementById(idFild) as any).value;
                const cursorPos = (document.getElementById(idFild) as any).selectionStart;
                setTimeout(() => {
                        selectShortcutService.select().subscribe((data) => {
                            data && handleChanges(id, insertString(value, data, cursorPos + 1))   
                        })
                }, 1)
            }
        }
    }

    return (
        <Box>
            {currentRecomemendation.map(el =>
                <Box display="flex" mb={1.5} key={`${el.type}-${el.id}`}>
                    <Box>
                        <Checkbox
                            icon={controlIcon}
                            color="primary"
                            checkedIcon={controlCheckedIcon}
                            checked={el.isSelected}
                            onChange={() => handleSelected(el.id, !el.isSelected)}
                        />
                    </Box>
                    <TextField
                        id={`recommendation-text-area-${el.type}-${el.id}`}
                        fullWidth
                        multiline
                        rowsMax={5}
                        disabled={!el.isSelected}
                        className={el.isSelected ? "" : classes.textFiel}
                        variant="outlined"
                        value={el.content}
                        onKeyDown={(e) => {handleOnKeyDown(el.id, e, `recommendation-text-area-${el.type}-${el.id}`)}}
                        onChange={(v) => {
                            handleChanges(el.id, v.target.value)
                        }}
                    />
                </Box>

            )}
            {currentRecomemendation.length === 0 && <Box display="flex" maxHeight="180px" alignItems="center" justifyContent="center">
                <RecommendationPlaceHolder message="No Recommendations" />
            </Box>}

            <Box mt={1} display='flex' alignItems='stretch'>
                <Box onClick={() => handleAddRecommendation(type, currentRecomemendation.length)
                    }>
                    <Add className={classes.addIcon} />
                </Box>
                <Box className={classes.addText} ml={2}>
                    <span>Add Recommendation</span>
                </Box>
            </Box>
        
        </Box>
    );
}