import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { getLastObject } from "../../../../../common/helpers/get-last-object";
import { onEmit } from "../../../../../common/helpers/on-emit";
import { IErrorState } from "../../../../../common/validation/error-state";
import { PatientModel, UpdatePatientModel } from "../../../../../patients/models/patient.model";
import { patientsService } from "../../../../../patients/services/patients.service";
import { patientsQuery } from "../../../../../patients/stores/patientsStore";
import { patientInformationComponentValidator } from "../../patientInformationComponentValidator/patientInformationComponent.validator";

interface AddressInfoWidgetComponentState extends IErrorState {
  isLoading: boolean;
  tabIndex: string;
  isEdit: boolean;
  patient: PatientModel;
  patientDraft: PatientModel;
}

const pageFields = [
  'billingAddress.streetAddress1',
  'billingAddress.streetAddress2',
  'billingAddress.city',
  'billingAddress.state',
  'billingAddress.zipCode',
  'billingAddress.country',
  'shippingAddress.streetAddress1',
  'shippingAddress.streetAddress2',
  'shippingAddress.city',
  'shippingAddress.state',
  'shippingAddress.zipCode',
  'shippingAddress.country',
];

export function useFacade(patientId: number | null): [
  AddressInfoWidgetComponentState,
  (event,tabIndex) => void,
  (field,value) => void,
  () => void,
  () => void,
  () => void
] {
  const [state, setState] = useState({
    isLoading: true,
    tabIndex: "0",
    isEdit: false,
    errors:{}
} as AddressInfoWidgetComponentState);


  const handleChanges = (field: string, value: string | boolean) => {
    patientInformationComponentValidator.validateAndSetState(state, setState, field, value);

    const patientDraft = state.patientDraft;
    const keys = field.split(".");
    const key = keys.pop();
    const lastObject = getLastObject(keys, patientDraft);
    lastObject[key] = value;

    setState({...state, patientDraft: {...patientDraft}});
  }

  const handleChangeTab = (event, newTabIndex) => {
    setState((state) => ({ 
      ...state, 
      tabIndex: newTabIndex }));
  }

  const handleDiscardChanges = () => {
    setState({
        ...state,
        errors: {},
        patientDraft: {
            ...state.patient,
            billingAddress: {...state.patient.billingAddress},
            shippingAddress: {...state.patient.shippingAddress},
            options: {...state.patient.options}
        },
        isEdit: false,
    });
  }


  const handleSubmit = () => {
    validateForm();
    if (!patientInformationComponentValidator.stateIsValid(state)) {
        return;
    }

    setState(state => ({ ...state, isLoading: true}));

    const model: UpdatePatientModel = {
        id: state.patient.id,
        firstName: state.patient.firstName,
        lastName: state.patient.lastName,
        email: state.patient.email,
        phoneNumber: state.patient.phoneNumber,
        birthday: state.patient.birthday,
        gender: state.patient.gender,
        billingAddress: state.patientDraft.billingAddress,
        shippingAddress: state.patientDraft.shippingAddress,
        employeeIds: state.patient.assignedEmployees.map(el => el.id),
        options: state.patient.options,
        leadSource: state.patient.leadSource,
        locationId: state.patient.locationId
    };

    patientsService.update(model).subscribe(
        () => handleEditModeToggle(),
        () => handleEditModeToggle()
    );
}

const validateForm = () => {
  pageFields.forEach(field => {
      const keys = field.split(".");
      const key = keys.pop();
      const lastObject = getLastObject(keys, state.patientDraft);
      const value = lastObject[key];
      patientInformationComponentValidator.validateAndSetState(state, setState, field, value);
  });
}

const handleEditModeToggle = () => {
    setState(state => ({
        ...state, 
        isEdit: !state.isEdit,
        isLoading: false
    }));
}

  useEffect(() => {
    const subscriptions: Subscription[] = [
      onEmit<PatientModel>(patientsQuery.targetPatient$, targetPatient => {
        if (targetPatient?.id === Number(patientId)) {
          setState(state => ({
            ...state,
            patient: targetPatient,
            patientDraft: targetPatient,
            isLoading: false,
          }));
        }
      }),
    ];

    return () => {
      subscriptions.map((it) => it.unsubscribe());
    };
  }, [patientId]);

  return [
    state,
    handleChangeTab,
    handleChanges,
    handleDiscardChanges,
    handleSubmit,
    handleEditModeToggle
  ];
}
