import { Store, StoreConfig } from '@datorama/akita';
import { DocumentModel } from '../../../documents/models/documents.models';
import { DocumentSourceModel, DocumentSourceTypeModel } from '../../../kbDocuments/models/documentSource.model';

/**
 * Represents documents state
 */
export interface DocumentsState {
    documentList: DocumentModel[];
    kbDocuments: DocumentSourceModel[];
    documentSourceTypes: DocumentSourceTypeModel[];
}

/**
 * Creates initial state
 */
export function createInitialState(): DocumentsState {
    return {
        documentList: [],
        kbDocuments: [],
        documentSourceTypes: []
    };
}

/**
 * Provides documents states management
 */
@StoreConfig({ name: 'documents', resettable: true })
export class DocumentsStore extends Store<DocumentsState> {
    constructor() {
        super(createInitialState());
    }

    public updateDocument(document: DocumentModel): void {
        const newDocumentList = this.getValue().documentList.map(x => x.id === document.id ? document : x);
        this.update({ documentList: newDocumentList });
    }

    public deleteFileInput(documentId: number): void {
        const newDocumentList = this.getValue().documentList.filter(x => x.id !== documentId);
        this.update({ documentList: newDocumentList });
    }

    public viewFile(documentId: number): void {
        const newDocumentList = this.getValue().documentList.map(el =>{
           return el.id !== documentId 
            ? el
            : {
                ...el,
                isViewed: true,
            }
        });

        this.update({ documentList: newDocumentList });
    }

    public updateKbDocument(document: DocumentSourceModel): void {
        const newDocumentList = this.getValue().kbDocuments.map(x => x.id === document.id ? document : x);
        this.update({ kbDocuments: newDocumentList });
    }

    public deleteKbDocument(documentId: number): void {
        const newDocumentList = this.getValue().kbDocuments.filter(x => x.id !== documentId);
        this.update({ kbDocuments: newDocumentList });
    }
}

export const documentsStore = new DocumentsStore();
