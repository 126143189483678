import {useState} from "react";
import {UserType} from "../../../auth/models/auth.enums";
import {authQuery} from "../../../auth/stores/auth";

interface MyAccountPageState {
    userType: UserType;
}

export function useFacade(): [MyAccountPageState] {
    const [state] = useState({
        userType: authQuery.getType()
    } as MyAccountPageState);

    return [state];
}