import { AgreementModel } from "../../../models/agreement.model";
import { CoachingClientAgreementPage } from "./CoachingClientAgreementPage";
import { PatientAgreementPage } from "./PatientAgreementPage";
import { PatientFoundersAgreementPage } from "./PatientFoundersAgreementPage";
import { CoachingFoundersClientAgreementPage } from "./CoachingFoundersClientAgreementPage";
import {PatientSingleAgreementPage} from "./PatientSingleAgreementPage";
import {CoachingClientSingleAgreementPage} from "./CoachingClientSingleAgreementPage";

const agreementsPagesMap: { [id: string]: Function } = {
    'PATIENT_AGREEMENT': PatientAgreementPage,
    'COACHING_AGREEMENT': CoachingClientAgreementPage,
    'PATIENT_FOUNDERS_AGREEMENT': PatientFoundersAgreementPage,
    'COACHING_FOUNDERS_AGREEMENT': CoachingFoundersClientAgreementPage,
    'SINGLE_PLAN_PATIENT_AGREEMENT': PatientSingleAgreementPage,
    'SINGLE_PLAN_COACHING_AGREEMENT': CoachingClientSingleAgreementPage,
};

export class AgreementsPagesProvider {

    /**
     * Returns agreements pages
     * @param agreements
     */
    public getPages(agreements: AgreementModel[]): Function[] {
        const pages: Function[] = [];
        agreements.forEach((agreement) => {
            pages.push(agreementsPagesMap[agreement.name]);
        })

        return pages;
    }

    /**
     * Returns agreement page
     * @param agreement
     */
    public getPage(agreement: AgreementModel): Function {
        return agreementsPagesMap[agreement.name];
    }
}

export const agreementPagesProvider = new AgreementsPagesProvider();
