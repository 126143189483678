import Axios from "axios-observable";
import { Observable } from "rxjs";
import { authHeader } from "../../common/helpers/auth-header";
import { snackService } from "../../common/snack/state";
import { VisitPrepModel } from "../models/visitPrep.model";
import { patientStore, PatientStore } from "../stores/patientsStore";

export class VisitPrepService {
    private url = `${process.env.REACT_APP_API_URL}VisitPrep`;

    constructor(private patientStore: PatientStore) { }

    public getVisitPrep(): Observable<VisitPrepModel> {
        return new Observable(observer => {
            Axios.get<VisitPrepModel>(this.url, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.patientStore.update({ visitPrep: response.data });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getVisitPrepByEmployee(patientId: number): Observable<VisitPrepModel> {
        let url = `${this.url}/${patientId}`;

        return new Observable(observer => {
            Axios.get<VisitPrepModel>(url, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.patientStore.update({ visitPrep: response.data })
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();

                    }

                );
        })
    }
}

export const visitPrepService = new VisitPrepService(patientStore);
