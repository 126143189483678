import { emailValidationRule, firstNameValidationRule, integerValidationRule, lastNameValidationRule, notWhitespaceValidationRule, optionalValidation, stringLengthValidationRule } from "../../../common/validation/general-validation-rules";
import { GeneralValidator } from "../../../common/validation/general-validator";

export const updateEmployeeComponentValidator = new GeneralValidator({
    'firstName': [firstNameValidationRule, notWhitespaceValidationRule()],
    'lastName': [lastNameValidationRule, notWhitespaceValidationRule()],
    'email': [emailValidationRule],
    'credentials': [stringLengthValidationRule(0, 100), notWhitespaceValidationRule()],
    'locationIds': [stringLengthValidationRule(1, null, 'Please, select a pod')],
    'npi': [optionalValidation('', [stringLengthValidationRule(10, 10), integerValidationRule])]
});
