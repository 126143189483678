import React from 'react';
import { Box, TextField, FormControl, FormHelperText, Select, MenuItem } from '@material-ui/core';
import { AppointmentReasonType, AppointmentReasonTypeNames } from '../../models/appointments.enums';
import { getNumericKeys } from '../../../common/helpers/enum-mapper';
import { IErrorState } from '../../../common/validation/error-state';

interface AppointmentReasonComponentProps extends IErrorState {
    reason: string;
    selectedReasonType: AppointmentReasonType;
    handleChangeReason: (value: string) => void;
    handleSelectReason: (value: any) => void;
}

export const AppointmentReasonComponent: React.FC<AppointmentReasonComponentProps> = (props: AppointmentReasonComponentProps) => {
    const {
        reason,
        selectedReasonType,
        errors,
        handleChangeReason,
        handleSelectReason
    } = props;

    const displayOtherReason = () => {
        if (selectedReasonType !== AppointmentReasonType.Other && selectedReasonType !== AppointmentReasonType.OtherTestFollowUp)
            return <></>

        return <Box mt={2}>
            <TextField
                id='reason'
                required
                fullWidth
                size="small"
                variant="outlined"
                placeholder="Please specify"
                helperText={errors['otherReason']}
                error={!!errors['otherReason']}
                value={reason}
                onChange={(e) => handleChangeReason(e.target.value)}
            />
        </Box>
    }

    return (
        <>
            <Box>
                <span className="wh-tw-text-sm wh-tw-text-gray1">Reason for the appointment</span>
            </Box>
            <Box mt={1}>
                <FormControl
                    variant="outlined"
                    color='primary'
                    required
                    error={!!errors[`selectedReason`]}
                    size="small" fullWidth>
                    <Select
                        style={{ background: '#FFF' }}
                        id="selectedReason"
                        value={selectedReasonType}
                        error={!!errors[`selectedReason`]}
                        renderValue={() => {
                            if (!selectedReasonType) {
                                return <Box className="wh-tw-text-mediumGray">Select the reason</Box>;
                            }

                            return <Box className="wh-tw-text-black">{AppointmentReasonTypeNames[selectedReasonType]}</Box>
                        }}
                        displayEmpty
                        onChange={(e) => handleSelectReason(e.target.value)}
                    >
                        {
                            getNumericKeys(AppointmentReasonTypeNames).map((value, i) => {
                                return <MenuItem key={i} value={value}>{AppointmentReasonTypeNames[value]}</MenuItem>
                            })
                        }
                    </Select>
                    <FormHelperText>{errors[`selectedReason`]}</FormHelperText>
                </FormControl>
            </Box>
            {selectedReasonType === AppointmentReasonType.LabFollowUp && <Box mt={1}>
                <span className="wh-tw-text-sm wh-tw-text-black">Lab results are required before the actual visit. </span>
            </Box>}
            {
                displayOtherReason()
            }
        </>
    );
}
