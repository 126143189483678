import {Store, StoreConfig} from "@datorama/akita";
import { AgreementConfirmationModel, AgreementConfirmationWithChangesModel, NotConfirmedAgreementModel} from "../../models/agreement.model";

/**
 * Agreements state
 */
export interface AgreementsState {
    confirmations: AgreementConfirmationModel[];
    notConfirmedAgreements: NotConfirmedAgreementModel[];
    agreementsChanges: AgreementConfirmationWithChangesModel[];
}

/**
 * Creates initial state
 */
export function createInitialState(): AgreementsState {
    return {
        confirmations: [],
        notConfirmedAgreements: [],
        agreementsChanges: [],
    };
}

/**
 * Provides agreements states management
 */
@StoreConfig({name: 'agreements', resettable: true })
export class AgreementsStore extends Store<AgreementsState> {
    constructor() {
        super(createInitialState());
    }
}

export const agreementsStore = new AgreementsStore();