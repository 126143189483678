import { Box, Dialog, DialogActions, DialogContent, FormControl, FormHelperText } from "@material-ui/core";
import React from "react";
import { DialogComponentProps } from "../../../common/dialogs/dialogProps";
import { useFacade } from "./offerPaymentPlanComponent.hooks";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import DialogTitle from "@material-ui/core/DialogTitle";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";

export const OfferPaymentPlanComponent: React.FC<DialogComponentProps> = (props: DialogComponentProps) => {
    const {
        open,
        handleSubmit,
        handleClose
    } = props;

    const [
        {
            paymentPlans,
            selectedPlan,
            selectedPeriod,
            errors
        },
        handlePlanSelect,
        handlePeriodSelect,
        handleSubmitForm,
        handleCloseDialog
    ] = useFacade(handleSubmit, handleClose);

    return (<>
        <Dialog fullWidth open={open} onClose={() => handleCloseDialog()} aria-labelledby="form-dialog-title">
            <form onSubmit={(e) => handleSubmitForm(e)}>
                <DialogContent>
                    <DialogTitle id="form-dialog-title">Offer Payment Plan</DialogTitle>
                    <Box p={3}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Box>
                                <span className="text-medium">Payment Plan:</span>
                            </Box>
                            <Box ml={3} width="310px">
                                <FormControl
                                    size="small"
                                    color='primary'
                                    error={!!errors[`selectedPlan`]}
                                    fullWidth>
                                    <Select style={{ background: '#FFFFFF' }}
                                        onChange={(event: React.ChangeEvent<{ value: number }>) => handlePlanSelect(event.target.value)}
                                        value={selectedPlan?.id ?? 0}
                                        error={!!errors[`selectedPlan`]}
                                        variant="outlined">
                                        {
                                            paymentPlans.map((plan, index) => {
                                                return <MenuItem key={index} value={plan.id}>{`${plan.displayName}`}</MenuItem>
                                            })
                                        }
                                    </Select>
                                    <FormHelperText>{errors[`selectedPlan`]}</FormHelperText>
                                </FormControl>
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                            <Box>
                                <span className="text-medium">Payment Period:</span>
                            </Box>
                            <Box ml={3} width="310px">
                                <FormControl
                                    size="small"
                                    color='primary'
                                    error={!!errors[`selectedPeriod`]}
                                    fullWidth>
                                    <Select style={{ background: '#FFFFFF', width: '100%' }}
                                        onChange={(event: React.ChangeEvent<{ value: number }>) => handlePeriodSelect(event.target.value)}
                                        value={selectedPeriod?.id ?? 0}
                                        error={!!errors[`selectedPeriod`]}
                                        disabled={!selectedPlan}
                                        variant="outlined">
                                        {
                                            selectedPlan?.periods?.map((period, index) => {
                                                return <MenuItem key={index} value={period.id}>{`${period.periodInMonths} months`}</MenuItem>
                                            })
                                        }
                                    </Select>
                                    <FormHelperText>{errors[`selectedPeriod`]}</FormHelperText>
                                </FormControl>
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Box display="flex" justifyContent="space-between" width="100%" px={3} pb={2}>
                        <Box>
                            <WildHealthButton
                                id="offer-payment-plan-cancel"
                                onClick={() => handleCloseDialog()}
                                color="secondary"
                            >
                                Cancel
                            </WildHealthButton>
                        </Box>
                        <Box>
                            <WildHealthButton id="offer-payment-plan-offer" onClick={(e) => handleSubmitForm(e)}>
                                Offer
                            </WildHealthButton>
                        </Box>
                    </Box>
                </DialogActions>
            </form>
        </Dialog>
    </>
    );
}
