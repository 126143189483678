import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
    createStyles({
        title: {
            fontFamily: 'Be Vietnam Pro',
            fontSize: '1.6rem',
            fontWeight: 500,
            lineHeight: 1.18,
            letterSpacing: 0.17,
            color: '#292929 !important'
        },
        inProgress: {
            background: '#00000000',
            margin: '6px 8px',
        },
    })
);
