import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles({
    root: {
        borderColor: colors.mediumGray,
        borderRadius: 8,
        '& .MuiOutlinedInput-root': {
            borderColor: colors.mediumGray,
            borderRadius: 8,
        },
    },
    container: {
        backgroundColor: colors.white,
        paddingTop: "48px"
    },
    containerSmall: {
        backgroundColor: colors.white,
        paddingTop: "56px"
    },
    howDidYouHeardAboutUsSelect: {
        backgroundColor: colors.white,
        width: 345,
    },
    textSecondary: {
        color: colors.gray1
    },
    main: {
        maxWidth: 648,
        margin: '0 auto',
    },
    welcomeMain: {
        maxWidth: 445,
        margin: '0 auto',
    },
    firstStepMain: {
        maxWidth: 345,
        margin: '0 auto',
    },
    termsPolicyLink: {
        color: colors.linkBlue,
        cursor: 'pointer',
        paddingLeft: '4px',
        textDecoration: 'underline',
    },
    btnLink: {
        border: 'none',
        backgroundColor: colors.white,
        color: colors.linkBlue,
        textAlign: 'start',
        fontSize: 14,
        fontWeight: 500,
        padding: 0,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
    },
    couponBtnLink1: {
        border: 'none',
        backgroundColor: colors.white,
        color: colors.main,
        textAlign: 'center',
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '24px',
        padding: 0,
        cursor: 'pointer',
        borderBottom: '1px solid',
        borderBottomColor: colors.main,
    },
    couponBtnLink2: {
        border: 'none',
        backgroundColor: colors.white,
        color: colors.linkBlack,
        textAlign: 'center',
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '24px',
        padding: 0,
        cursor: 'pointer',
    },
    subTitle: {
        color: colors.gray1,
        fontSize: 14,
    },
    title: {
        fontSize: 24,
        fontWeight: 600,
        lineHeight: '33px',
    },
    smallTitle: {
        fontSize: 20,
        lineHeight: '23px',
        fontWeight: 500,
        color: colors.darkGray,
    },
    orderDetail: {
        background: '#E7F2F34D',
        borderRadius: 8,
        padding: 32,
    },
    orderDetailSM: {
        background: '#E7F2F34D',
        borderRadius: 8,
        padding: 8,
    },
    paymentMethodBlock: {
        padding: 32,
        borderRadius: 8,
        border: '1px solid',
        borderColor: colors.lightGray1
    },
    creditCard: {
        borderRadius: 8,
        backgroundColor: '#F2F2F266',
    },
    formControl: {
        marginRight: 4
    }
});