import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { colors } from "../../modules/common/constants/colors";

type Props = {
    topBannerHeight: number;
};

export const useStyles = makeStyles<Theme, Props>((theme) =>
    createStyles({
        root: {
            height: 72,
            backgroundColor: '#FFFFFF',
            color: '#000000',
            zIndex: theme.zIndex.drawer - 1,
            paddingTop: 5,
            top: ({ topBannerHeight }) => topBannerHeight
        },
        title: {
            fontFamily: 'Poppins',
            fontWeight: 600,
            fontSize: 24,
            color: '#091D36',
            marginLeft: 28,
        },
        grow: {
            flexGrow: 1,
        },
        logo: {
            width: 100,
            maxHeight: 25,
        },
        toolbar: {
            paddingLeft: 28,
        },
        iconButton: {
            minWidth: 24,
            marginRight: 20,
            color: colors.gray1,
        },
        selectedButton: {
            minWidth: 24,
            marginRight: 20,
            color: colors.main,
        },
        content: {
            overflowX: 'hidden',
            overflowY: 'auto',
            height: '100%',
            width: '100%',
        },
        expandIcon: {
            fill: colors.gray1,
        },
        badge: {
            position: 'absolute',
            top: -15,
            right: -15,
        },
        menuList: {
            padding: 14,
            overflowX: 'hidden',
            overflowY: 'auto',
            paddingBottom: 50,
        },
        menuIconSize: {
            width: 20,
            height: 20,
        },
        menuIcon: {
            minWidth: 0,
        },
        menuTitle: {
            marginLeft: 20,
            fontFamily: 'Be Vietnam Pro',
            fontWeight: 500,
            fontSize: 14,
            lineHeight: 19,
        },
        menuItem: {
            borderRadius: 2,
            margin: '2px 0',
            padding: '5px 20px',
            color: colors.gray1,
        },
        menuItemSelected: {
            background: `${colors.main}0F !important`,
            color: colors.main,
        },
        toggleButton: {
            position: 'absolute',
            borderRadius: 0,
            width: '100%',
            bottom: 0,
            padding: 10,
        },
        nested: {
            paddingLeft: theme.spacing(2),
        },
    }),
);