import React from 'react';
import './ProgressDiagramComponent.scss';
import {Box} from "@material-ui/core";

export enum LabelPosition {
    Top,
    TopLeft,
    TopRight,
    Bottom,
    BottomLeft,
    BottomRight
}

export interface ProgressDiagramData {
    value: number;
    label: string;
    labelPosition: LabelPosition;
}

interface ProgressDiagramComponentProps {
    data: ProgressDiagramData[];
    colors: string[];
    height?: number;
    unavailable?: boolean;
}

const defaultHeight = 22;

export const ProgressDiagramComponent: React.FC<ProgressDiagramComponentProps> = (props: ProgressDiagramComponentProps) => {
    ProgressDiagramComponent.defaultProps = {
        height: defaultHeight,
        unavailable: false
    }

    let {
        data,
        colors,
        height,
        unavailable
    } = props;

    const positionPriorityShort: { [type: number]: number } = {
        1: LabelPosition.BottomRight,
        2: LabelPosition.TopRight,
    }

    const positionPriorityMedium: { [type: number]: number } = {
        1: LabelPosition.BottomLeft,
        2: LabelPosition.TopLeft,
        3: LabelPosition.BottomRight,
        4: LabelPosition.TopRight,
    }

    const positionPriorityLong: { [type: number]: number } = {
        1: LabelPosition.BottomLeft,
        2: LabelPosition.TopLeft,
        3: LabelPosition.Bottom,
        4: LabelPosition.Top,
        5: LabelPosition.BottomRight,
        6: LabelPosition.TopRight,
    }

    const updateLabelPositions = () => {
        data = data.sort((a, b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0));
        let dataPriority = 1;
        switch (data.length) {
            case 1:
                return;
            case 2:
                data.forEach(item => {
                    item.labelPosition = positionPriorityShort[dataPriority];
                    dataPriority++;
                });
                return;
            case 3:
            case 4:
                data.forEach(item => {
                    item.labelPosition = positionPriorityMedium[dataPriority];
                    dataPriority++;
                });
                return;
            default:
                data.forEach(item => {
                    if (dataPriority <= 6) {
                        item.labelPosition = positionPriorityLong[dataPriority];
                        dataPriority++;
                    }
                    else {
                        item.label = "";
                    }
                });
                return;
        }
    }

    updateLabelPositions();

    const createGradient = (colors: string[]): string => {
        return `linear-gradient(to right, ${unavailable ? "#b9b9b9, #b9b9b9" : colors.join(',')})`;
    }

    const setupLabelPosition = (position: LabelPosition): string => {
        switch (position) {
            case LabelPosition.Top: return "top";
            case LabelPosition.TopLeft: return "top-left";
            case LabelPosition.TopRight: return "top-right";
            case LabelPosition.Bottom: return "bottom";
            case LabelPosition.BottomLeft: return "bottom-left";
            case LabelPosition.BottomRight: return "bottom-right";
        }
    }

    const renderData = (data: ProgressDiagramData, key?: string) => {
        return (
            <div key={key} className="value" style={{ left: `calc(${data.value < 5 ? 5 : data.value}% - 17.5px)`, height: height * 1.23, width: height * 1.23 }}>
                <div className={`label ${setupLabelPosition(data.labelPosition)}`}>
                    <p className="label-text">{data.label}</p>
                </div>
            </div>
        )
    }

    return (
        <div>
            <Box px={3} style={{ backgroundImage: createGradient(colors) }}>
                <Box className="progress" style={{ height: height }}>
                    {
                        !unavailable && data.map((data, index) => {
                            return renderData(data, index.toString());
                        })
                    }
                </Box>
            </Box>
        </div>
    );
}
