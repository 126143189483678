import { Badge } from "@material-ui/core";
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import { colors } from "../../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      overflow: "auto",
      "& .MuiStepper-root": {
        padding: "16px 0 0 0",
      },
    },
    stepper: {
      "& .MuiStepConnector-lineVertical": {
        display: "none",
      },
    },
    step: {
      "&.MuiStepContent-root": {
        paddingLeft: 0,
        paddingBottom: 4,
        marginLeft: 8,
        border: 0
      },
    },
    stepBorder: {
      borderBottom: `1px solid ${colors.separators}`,
    },
    stepSelected: {
      background: colors.lightGray,
    },
    item: {
      display: "flex",
      justifyContent: "space-between",
    },
    subjectItem: {
      background: colors.gray3,
      marginBottom: "0.25rem",
    },
    stepLabel: {
      "&.MuiStepLabel-root": {
        alignItems: "flex-start",
      },
    },
    statusDateContainer: {
      display: "flex",
      flexDirection: 'column',
      justifyContent: "space-between",
    },
    statusDate: {
      fontWeight: "normal",
      fontSize: 12,
      paddingRight: 5,
      color: colors.gray1,
    },
    divider: {
      backgroundColor: colors.stroke,
    },
    checkIcon: {
      fill: colors.main,
      height: 18,
      width: 18,
    },
    activeIcon: {
      stroke: colors.main,
      height: 18,
      width: 18,
    },
    historyTitle: {
      fontFamily: "Be Vietnam Pro",
      fontWeight: 500,
      fontSize: 16,
      color: colors.black,
      paddingLeft: 0,
    },
    historyButton: {
      width: "fit-content",
      cursor: "pointer",
      gridGap: 10
    }
  })
);

export const UnreadMessages = withStyles((theme: Theme) => ({
  root: {
    "& .MuiBadge-badge": {
      background: colors.bad,
      color: colors.white,
    },
  },
}))(Badge);
