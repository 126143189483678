import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Subscription } from "recompose";
import { accountService } from "../../../../services/account.service";
import { navigationService } from "../../../../services/navigation.service";
import { authService } from "../../../auth/services/auth.service";
import { authQuery } from "../../../auth/stores/auth";
import { onEmit } from "../../../common/helpers/on-emit";
import { agreementPagesProvider } from "../../components/AgreementsComponent/pages/agreementsPagesProvider";
import { ConfirmAgreementModel, NotConfirmedAgreementModel } from "../../models/agreement.model";
import { agreementsService } from "../../services/agreements.service";
import { agreementsQuery, agreementsStore } from "../../stores/agreementsStore";

export interface ConfirmAgreementComponentState {
    isLoading: boolean;
    agreements: NotConfirmedAgreementModel[];
    currentAgreement: NotConfirmedAgreementModel;
    currentAgreementPage: Function;
}

const defaultState: ConfirmAgreementComponentState = {
    isLoading: true,
    agreements: [],
    currentAgreement: null,
    currentAgreementPage: null
}

export function useFacade(): [
    ConfirmAgreementComponentState,
    () => void,
    () => void] {

    const [state, setState] = useState(defaultState);
    const history = useHistory();


    const handleConfirm = () => {
        const benefitOfTheDoubtMargin = 10;

        if (((window.innerHeight + window.scrollY) + benefitOfTheDoubtMargin) >= document.body.offsetHeight) {
            setState(state => ({...state, isLoading: true}));

            const confirmation: ConfirmAgreementModel = {
                agreementId: state.currentAgreement.agreement.id,
                isConfirmed: true
            };

            agreementsService.confirm([confirmation]).subscribe(() => {
                if (!state.agreements.length) {
                    authService.reLogin().subscribe(() => {
                        navigationService.toDashboard(history);
                        setState(state => ({...state, isLoading: false}));
                    });
                } else {
                    agreementsStore.update({notConfirmedAgreements: state.agreements.slice()});
                    setState(state => ({...state, isLoading: false}));
                    navigationService.goUp();
                }
            });
        }
        else {
            navigationService.goDown();
        }
    }

    const handleDismiss = () => {
        accountService.logout();
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<NotConfirmedAgreementModel[]>(agreementsQuery.notConfirmedAgreements$, agreements => {
                if (agreements.length) {
                    const currentAgreement = agreements.shift();
                    setState(state => ({...state,
                        agreements: agreements.slice(),
                        currentAgreement: currentAgreement,
                        currentAgreementPage: agreementPagesProvider.getPage(currentAgreement.agreement),
                        signature: '',
                        errors: {},
                    }));
                }
            }),
        ];

        if (authQuery.isAgreementsConfirmed()) {
            navigationService.toDashboard(history);
        }

        agreementsService.getNotConfirmed()
            .subscribe(() => setState(state => ({
                ...state,
                isLoading: false
            })));

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, []);

    return [state, handleConfirm, handleDismiss];
}