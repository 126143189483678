import {useState} from 'react';
import {
    CardElement,
    useElements,
    useStripe
} from '@stripe/react-stripe-js';

interface PaymentFormState {
    error: string;
}

export function useFacade(callback: Function, validate: Function, needCardData: boolean): [PaymentFormState, Function, Function] {
    const [state, setState] = useState({
        error: null
    } as PaymentFormState);
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const card = elements.getElement(CardElement);
        const paymentMethod = await stripe.createPaymentMethod({ type: "card", card: card});

        const isValid = !(validate != null && !validate(paymentMethod?.paymentMethod?.id));

        if (needCardData) {
            if (paymentMethod.error) {
                setState({...state, error: paymentMethod.error.message});
            } else {
                setState({...state, error: null});
                if (isValid) {
                    callback(paymentMethod.paymentMethod.id);
                }
            }
        }
        else {
            setState({...state, error: null});
            callback("");
        }
    }

    const handleChange = (event) => {
        if (event.error) {
            setState({...state, error: event.error.message});
        } else {
            setState({...state, error: null});
        }
    }

    return [state, handleChange, handleSubmit]
}
