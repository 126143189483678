import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontSize: 20,
            fontWeight: 600,
            paddingRight: 10,
        },
        boxCircle: {
            width: 14,
            height: 14,
            borderRadius: '50%',
        },
        scoreSection: {
            position: "absolute",
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
        },
        scoreTitle: {
            fontWeight: 500,
            fontSize: 12,
            textTransform: 'uppercase',
        },
        scoreNumber: {
            fontWeight: 700,
            fontSize: 46,
        },
        tooltip: {
            width: '225px',
            maxWidth: '225px',
            padding: 8,
            background: colors.lightBlack,
        },
        arrow: {
            color: colors.lightBlack,
        },
        tooltipPlacement: {
            margin: '12px 0',
        },
    })
);