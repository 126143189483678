import React from "react";
import {useFacade} from "./unreadMessagesBadgeComponent.hooks";
import {Badge} from "@material-ui/core";
import {useStyles} from "./unreadMessagesBadgeComponent.styles";
import {ConversationType} from "../../models/conversation.models";


let colorsMap = new Map()
    .set(ConversationType.HealthCare, "healthCare")
    .set(ConversationType.Support, "support")
    .set(ConversationType.Internal, "internal")

export const UnreadMessagesBadgeComponent: React.FC = () => {
    const [newMessages, conversationTypes] = useFacade();

    const classes = useStyles();

    return (
        <>
            {
                newMessages
                    ? conversationTypes.map((type) => {
                        return <Badge key={`unread-messages-badge-${type}`} className={classes.root} variant="dot" classes={{ badge: classes[colorsMap.get(type)]}}/>
                    })
                    : <></>
            }
        </>
    );
}