import {Box} from '@material-ui/core';
import React from 'react';
import {navigationService} from '../../../../services/navigation.service';
import {WildHealthButton} from '../../../common/components/wildHealthButton/WildHealthButton';
import commonUseStyles from '../../../common/styles/common.styles';
import {GoalCommonModel, GoalModel, NotePlanModel, NotesType, GoalCategory} from '../../models/notes.models';
import {GoalsComponent} from '../goalsComponent/GoalsComponent';
import {handleShortcutInsert} from "../../../common/helpers/handle-shortcut-insert";
import {TextFieldNoteComponent} from '../textFieldNoteComponent/TextFieldNoteComponent';
import {WildHealthTabControl} from "../../../common/components/wildHealthTabControl/WildHealthTabControl";
import {EducationsComponent} from "../educationsComponent/EducationsComponent";
import { notesQuery } from '../../stores/notes';

export interface NotePlanComponentProps {
    patientId: number,
    notesType: number,
    visitDate: Date,
    data: NotePlanModel,

    handleGoalsChanges: (key: string, values: GoalModel[]) => void,
    handleEducationChanges: (key: string, education: string) => void,
    handlePlanTextChanges?: (value: string) => void,
    handleLabTextChanges?: (value: string) => void,
    handleCoachOtherTextChanges?: (value: string) => void,
    handleValidateGoals: (goalName: string, id:number) => boolean,
}

export const NotePlanComponent: React.FC<NotePlanComponentProps> = (props: NotePlanComponentProps) => {
    const {
        data,
        notesType,
        visitDate,
        handleGoalsChanges,
        handleEducationChanges,
        handlePlanTextChanges,
        handleLabTextChanges,
        handleCoachOtherTextChanges,
        handleValidateGoals
    } = props;

    const commonClasses = commonUseStyles();

    const renderSection = (title: string, goalsDataKey: string, goals: GoalModel[], educationsDataKey: string, education: string, commonGoals?: GoalCommonModel[]) => {
        if(goals === undefined || goals === null){
            goals = [];
        }
        
        return (
            <>
                <Box pb={2}>
                    <span className={commonClasses.textMedium}>{title}</span>
                </Box>
                <WildHealthTabControl items={[
                    {
                        title: 'Goals',
                        content: <GoalsComponent
                            commonGoals={commonGoals}
                            visitDate={visitDate}
                            dataKey={goalsDataKey}
                            goals={goals}
                            handleValidateGoals={handleValidateGoals}
                            handleGoalsChanges={handleGoalsChanges}
                        />,
                        keepMounted: false,
                    },
                    {
                        title: 'Education',
                        content: <EducationsComponent
                            dataKey={educationsDataKey}
                            education={education}
                            handleEducationChanges={handleEducationChanges}
                        />,
                        keepMounted: false,
                    },
                ]}/>
            </>
        )
    }

    return (
        <Box>
            {notesType !== NotesType.FollowUp && <Box>
                <TextFieldNoteComponent
                    id="plan-text-input"
                    rows={3}
                    title=''
                    content={data.planText}
                    handleChanged={handlePlanTextChanges}
                    handleKeyDown={handleShortcutInsert}
                />
            </Box>}
            {notesType !== NotesType.Soap && <><Box mt={5}>
                {
                    renderSection(
                        'Diet',
                        'dietGoals',
                        data.dietGoals,
                        'dietEducations',
                        data.dietEducations,
                        notesQuery.getCommonGoals(GoalCategory.Diet),
                    )
                }
            </Box>
                <Box mt={5}>
                    {
                        renderSection(
                            'Exercise/Recovery',
                            'exerciseGoals',
                            data.exerciseGoals,
                            'exerciseEducations',
                            data.exerciseEducations,
                            notesQuery.getCommonGoals(GoalCategory.Exercise)
                        )
                    }
                </Box>
                <Box mt={5}>
                    {
                        renderSection(
                            'Sleep',
                            'sleepGoals',
                            data.sleepGoals,
                            'sleepEducations',
                            data.sleepEducations,
                            notesQuery.getCommonGoals(GoalCategory.Sleep),
                        )
                    }
                </Box>
                <Box mt={5}>
                    {
                        renderSection(
                            'Neuro',
                            'neuroGoals',
                            data.neuroGoals,
                            'neuroEducations',
                            data.neuroEducations,
                            notesQuery.getCommonGoals(GoalCategory.Neuro)
                        )
                    }
                </Box>

                <Box mt={5}>
                    {
                        renderSection(
                            'Longevity',
                            'longevityGoals',
                            data.longevityGoals,
                            'longevityEducations',
                            data.longevityEducations,
                            notesQuery.getCommonGoals(GoalCategory.Longevity)
                        )
                    }
                </Box>

                <Box mt={5}>
                    {
                        renderSection(
                            'Mindfulness',
                            'mindfulnessGoals',
                            data.mindfulnessGoals,
                            'mindfulnessEducations',
                            data.mindfulnessEducations,
                            notesQuery.getCommonGoals(GoalCategory.Mindfulness)
                        )
                    }
                </Box>
                {notesType !== NotesType.FollowUp && <Box mt={5}>
                    <TextFieldNoteComponent
                        id="coach-text-input-others"
                        rows={3}
                        title='Other'
                        className={commonClasses.textMedium}
                        content={data.coachOtherText}
                        handleChanged={handleCoachOtherTextChanges}
                        handleKeyDown={handleShortcutInsert}
                    />
                </Box>
                }
            </>}
            {notesType !== NotesType.FollowUp && <Box mt={5}>
                <Box>
                    <span className={commonClasses.textMedium}>Labs</span>
                </Box>
                <Box mt={3}>
                    <WildHealthButton
                        id='note-order-labs-button'
                        onClick={() => {
                            navigationService.toPatientOrdersNewTab(props.patientId)
                        }}
                    >
                        Order Labs
                    </WildHealthButton>
                </Box>
                <Box mt={3}>
                    <TextFieldNoteComponent
                        id="labs-text-input"
                        rows={3}
                        title=''
                        content={data.labsText}
                        handleChanged={handleLabTextChanges}
                        handleKeyDown={handleShortcutInsert}
                    />
                </Box>
            </Box>}
        </Box>
    );
}