import {CreateShortcutGroupModel} from "../../../models/shortcut.models";
import {shortcutsService} from "../../../services/shortcuts.service";
import {useState} from "react";
import {IErrorState} from "../../../../common/validation/error-state";
import {createShortcutGroupComponentValidator} from "./createShortcutGroupComponent.validator";
import {DialogComponentProps} from "../../../../common/dialogs/dialogProps";
import { shortcutsQuery, ShortcutGroupTabs } from "../../../stores/shortcutsStore";

/**
 * Represents Create Shortcuts Group Component state
 */
interface CreateShortcutGroupComponentState extends IErrorState {
    group: CreateShortcutGroupModel;
    isSubmitted: boolean;
}

/**
 * Custom Hook to manage a view Model for Create shortcuts group component
 */
export function useFacade(props: DialogComponentProps): [CreateShortcutGroupComponentState, Function, Function, Function] {
    const [state, setState] = useState({
        group: {
            name: null,
            displayName: null,
            isPersonal: false
        },
        errors: {},
        isSubmitted: false
    } as CreateShortcutGroupComponentState);

    const handleNameChanges = (value: string) => {
        state.group.name = value;
        state.group.displayName = value;
        setState({
            ...state,
            group: {...state.group}
        });

        createShortcutGroupComponentValidator.validateAndSetState(state, setState, 'name', value);
    }

    const handleSubmit = (event: Event) => {
        event.preventDefault();
        if(!state.isSubmitted){

            createShortcutGroupComponentValidator.validateObjectAndSetState(state, setState, state.group);
            if (!createShortcutGroupComponentValidator.stateIsValid(state)) {
                return;
            }
            setState(state => ({...state, isSubmitted: true }))
            state.group.isPersonal = shortcutsQuery.getTab() === ShortcutGroupTabs.Personal;
            shortcutsService.createGroup(state.group).subscribe(() => {
                handleClose()
            }, () => {
                setInitialState();
            });
        }
        
    }

    const handleClose = () => {
        setInitialState();
        props.handleClose();
    }

    const setInitialState = () => {
        setState({
            ...state,
            errors: {},
            group: {
                name: null,
                displayName: null,
                isPersonal: shortcutsQuery.getTab() === ShortcutGroupTabs.Personal
            },
            isSubmitted: false
        });

    }

    return [state, handleNameChanges, handleSubmit, handleClose];
}