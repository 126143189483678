import {useEffect, useState} from "react";
import {BaseConversationComponentState, useBaseFacade} from "../conversationComponent/conversationComponent.hooks";
import {
    ConnectionStatuses,
    ConversationAuthorModel,
    ConversationModelBase,
    LastReadMessageUpdateModel,
    TargetInteractionModel,
    AcceptInteractionModel,
    ConversationType,
} from "../../models/conversation.models";
import {AttachFileModel, ScheduledMessageModel, UploadedAttachmentModel} from "../../models/message.models";
import {TimeZoneModel} from "../../../timezones/models/timezone.model";
import {authQuery} from "../../../auth/stores/auth";
import moment from "moment";
import {scheduleMessageService} from "../../services/scheduleMessage.service";
import {scheduledMessagesStore} from "../../stores/scheduledMessagesStore/scheduledMessages.store";
import {DropEvent, FileRejection} from "react-dropzone";
import {Subscription} from "rxjs";
import {onEmit} from "../../../common/helpers/on-emit";
import {scheduledMessagesQuery} from "../../stores/scheduledMessagesStore/scheduledMessages.query";
import {timezonesQuery} from "../../../timezones/stores/timezones";
import {timezonesService} from "../../../timezones/services/timezones.service";
import {conversationsViewService} from "../../services/conversationsView.service";
import { employeeConversationsStore } from "../../stores/employeeConversationsStore/employeeConversations.store";
import {conversationsService} from "../../services/conversations.service";
import { employeeConversationsQuery } from "../../stores/employeeConversationsStore/employeeConversations.query";
import { getConversationAttributes } from "../../helpers/getAttributes";
import { EmployeeType } from "../../../employee/models/employee.enums";

interface StickyChatComponentState {
    isScheduleDialogOpen: boolean;
    scheduledMessageForEditing: ScheduledMessageModel;
    toSendAttachments: AttachFileModel[];
    scheduledAttachmentsForEditing: UploadedAttachmentModel[];
    removeAttachments: number[];
    messageToSchedule: string;
    timeZones: TimeZoneModel[];
    emptyMessage: boolean;
    messagesViewLoading: boolean;
    messagesView: any[];
    aiMessageForEditing: TargetInteractionModel;
    detailForAiMessage: string;
    messagesViewForAi: any[];
    scrollToIndexForAi: number;
    isAiLoading: boolean;
    isJoining: boolean;
}

export function useFacade(conversation: ConversationModelBase, author: ConversationAuthorModel): [
    StickyChatComponentState & BaseConversationComponentState,
    (message: any) => void,
    <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void,
    (index: number) => void,
    (message: string, removeAttachmentIds?: number[], attachments?: UploadedAttachmentModel[]) => void,
    () => void,
    (date: Date, time: Date) => void,
    () => void,
    Function,
    (message: string) => void,
    () => void,
    (message: string) => void,
    (message: string) => void,
    () => void
] {
    const [state, setState] = useState({
        isScheduleDialogOpen: false,
        scheduledMessageForEditing: null,
        scheduledAttachmentsForEditing: [],
        removeAttachments: [],
        toSendAttachments: [],
        messageToSchedule: '',
        timeZones: [],
        emptyMessage: false,
        messagesViewLoading: true,
        messagesView: [],
        aiMessageForEditing: null,
        detailForAiMessage: '',
        messagesViewForAi: [],
        scrollToIndexForAi: -1,
        isAiLoading: false,
        isJoining: false
    } as StickyChatComponentState);

    const [
        baseState,
        sendMessage,
        handleTyping,
        loadMessages
    ] = useBaseFacade(conversation, author);

    const handleToggleScheduleDialog = () => {
        setState(state => ({ ...state, isScheduleDialogOpen: !state.isScheduleDialogOpen }));
    }

    const handleOpenScheduleDialog = (message: string, removeAttachmentIds?: number[], attachments?: UploadedAttachmentModel[]) => {
        if (removeAttachmentIds) {
            setState(state => ({ ...state, isScheduleDialogOpen: true, messageToSchedule: message, removeAttachments: removeAttachmentIds, scheduledAttachmentsForEditing: attachments }));
        } else {
            setState(state => ({ ...state, isScheduleDialogOpen: true, messageToSchedule: message }));
        }
    }

    const handleScheduleMessage = (date: Date, time: Date) => {
        const cb = () => {
            setState(state => ({ ...state, emptyMessage: !state.emptyMessage, messageToSchedule: '', toSendAttachments: [] }));
            handleEndEditScheduledMessage();
        }
        const defaultTimeZoneName = 'UTC';

        const model = {
            message: state.messageToSchedule,
            conversationId: conversation.id,
            participantId: conversation.employees.find(i => i.email === authQuery.getEmail() && !i.isDeleted).id,
            timeToSend: moment.utc(new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.getHours(), time.getMinutes())).toDate(),
            timeToSendStr: moment.utc(new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.getHours(), time.getMinutes())).format("YYYY-MM-DD HH:mm:ss"),
            timeZoneName: defaultTimeZoneName,
            attachments: state.toSendAttachments
        }

        if (Boolean(state.scheduledMessageForEditing)) {
            scheduleMessageService
                .reschedule({ ...model, id: state.scheduledMessageForEditing.id, removeAttachments: state.removeAttachments, uploadedAttachments: state.scheduledAttachmentsForEditing })
                .subscribe(cb, cb);
        } else {
            scheduleMessageService.schedule(model).subscribe(cb, cb);
        }

        handleToggleScheduleDialog();
    }

    const handleEndEditScheduledMessage = () => {
        scheduledMessagesStore.endEditing();
        setState(state => ({ ...state, toSendAttachments: [] }))
    }

    const handleSendMessage = (message: any) => {
        if (!employeeConversationsQuery.getConnectionStatus()) return;
        if (conversation.proxy?.status !== ConnectionStatuses.joined) return;
        const attributes = getConversationAttributes(conversation);
        if (state.toSendAttachments.length > 0) {
            state.toSendAttachments.forEach((item, index) => {
                const formData = new FormData();
                formData.append('file', item.file);
                if (state.toSendAttachments.length - 1 === index) {
                    if (message.length) {
                        conversation.proxy.prepareMessage()
                            .setBody(message)
                            .addMedia(formData)
                            .setAttributes(attributes)
                            .build()
                            .send();
                    } else {
                        conversation.proxy.sendMessage(formData, attributes);
                    }
                } else {
                    conversation.proxy.sendMessage(formData, attributes);
                }
                URL.revokeObjectURL(item.url);
            });

            setState(state => ({ ...state, toSendAttachments: [] }));
        } else {
            if (message.length) conversation.proxy.sendMessage(message, attributes);
        }
        
        sendMessage(message);
    };

    const handleSendMessageForAiDemo = (message: any) => {
        const model = {
            patientId: conversation.patients[0].patientId,
            query: message,
            conversation: state.messagesViewForAi.length > 0 ? state.messagesViewForAi.map(x => ({type: x.isAi ? 'ai' : 'human', content: x.body})) : []
        }
        const initialMessage = {
            sid: "",
            accountSid: "",
            conversationSid: "",
            body: message,
            author: "",
            participantSid: "",
            dateCreated: new Date(),
            dateUpdated: new Date(),
            index: 0,
            url: "",
            media: null,
            attributesString: "",
            isAi: false
        }
        setState(state => ({
            ...state,
            scrollToIndexForAi: state.messagesViewForAi.length,
            messagesViewForAi: [...state.messagesViewForAi, {...initialMessage, index: state.messagesViewForAi.length - 1, body: message, isAi: false}],
            isAiLoading: true
        }));
        const cb = () => setState(state => ({ ...state, isAiLoading: false }))
        conversationsService.sendMessageToAiDemo(model).subscribe((response) => {
            setState(state => ({
                ...state,
                scrollToIndexForAi: state.messagesViewForAi.length,
                messagesViewForAi: [...state.messagesViewForAi, {...initialMessage, index: state.messagesViewForAi.length - 1, body: response.text, isAi: true}],
                isAiLoading: false
            }));
        }, cb)
    };

    const handleDropAttachment = <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => {
        const attachments = acceptedFiles.map(item => ({ file: item, url: URL.createObjectURL(item) }));

        setState(state => ({ ...state, toSendAttachments: [...state.toSendAttachments, ...attachments] }));
    }

    const handleRemoveAttachment = (index: number) => {
        const file = state.toSendAttachments.find((i, itemIndex) => index === itemIndex);
        if (file) {
            URL.revokeObjectURL(file.url);
            setState(state => ({ ...state, toSendAttachments: state.toSendAttachments.filter((i, itemIndex) => index !== itemIndex) }));
        }
    }

    const handleSetMessageUnread = (messageSelected:LastReadMessageUpdateModel) => {

        // Tell Twilio about the read message index
        conversation.proxy.updateLastReadMessageIndex(messageSelected.lastMessageReadIndex).then(unreadMessageCount => {
            conversation.unreadMessages = unreadMessageCount;
            employeeConversationsStore.updateConversation(conversation);
            conversationsService.selectConversation(null)

        });
        // Tell our API about the read message index - this is for the purpose of keeping this known locally for unread message notifications
        conversationsService.setMessagesUnread(messageSelected).subscribe();
    }

    const handleUpdateInteraction = (message: string) => {
        loadMessages(conversation.proxy);
        if (message.trim().length > 0) {
            const attributes = getConversationAttributes(conversation);
            conversation.proxy.sendMessage(message, attributes);
        }
    }

    const handleEndEditAiMessage = () => {
        employeeConversationsStore.endAiEditing();
        employeeConversationsStore.aiMessageAccept();
    }

    const handleEditInteraction = (message: string) => {
        const participantExternalVendorId = employeeConversationsQuery.getAuthor().conversationIdentity;
        const acceptInteraction: AcceptInteractionModel = {
            conversationId: state.aiMessageForEditing.conversationId,
            messageId: state.aiMessageForEditing.messageSid,
            referenceId: state.aiMessageForEditing.interaction.ReferenceId,
            participantId: participantExternalVendorId,
            detail: message
        }
        const cb = () => {
            setState(state => ({
                ...state,
                detailForAiMessage: '',
            }));
            handleEndEditAiMessage();
        }
        
        employeeConversationsStore.aiMessageAccept();
        conversationsService.editAiMessage(acceptInteraction).subscribe(() => {
            cb();
            handleUpdateInteraction(message);
        }, cb)
    }

    const handleJoinThread = () => {
        if (state.isJoining) return;
        setState(state => ({...state, isJoining: true}))
        const cb = () => setState(state => ({...state, isJoining: false}))
        conversationsService.createConversation({ targetId: conversation.patients[0].patientId, type: authQuery.getEmployeeType() === EmployeeType.Staff ? ConversationType.Support : ConversationType.HealthCare }).subscribe(cb, cb)
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<ScheduledMessageModel>(scheduledMessagesQuery.editingTarget$, message =>
                setState(state => ({ ...state, scheduledMessageForEditing: message }))
            ),
            onEmit<UploadedAttachmentModel[]>(scheduledMessagesQuery.uploadedAttachments$, attachments =>
                setState(state => ({ ...state, scheduledAttachmentsForEditing: attachments }))
            ),
            onEmit<TimeZoneModel[]>(timezonesQuery.timezones$, timezones => {
                setState(state => ({ ...state, timeZones: timezones }));
            }),
            onEmit<TargetInteractionModel>(employeeConversationsQuery.editingTargetAiMessage$, targetAiMessage => {
                if (Boolean(targetAiMessage)) {
                    setState(state => ({ ...state, detailForAiMessage: targetAiMessage.interaction.Detail }))
                }
                setState(state => ({ ...state, aiMessageForEditing: targetAiMessage }))
            }),
        ];

        if (conversation && conversation.employees) {
            const employeeParticipant = conversation.employees.find(i => i.email === authQuery.getEmail() && !i.isDeleted);
            if (employeeParticipant) {
                scheduleMessageService.get(employeeParticipant.id).subscribe();
            }
        }

        timezonesService.getTimezones().subscribe();

        if (conversation && !conversation.proxy && conversation.vendorExternalId) {
            conversationsViewService.getPatientAllMessagesHealthCare(conversation.vendorExternalId).subscribe(
                (messages) => {
                    setState(state => ({
                        ...state,
                        messagesView: messages,
                        messagesViewLoading: false
                    }))
                },
                () => {
                    setState(state => ({
                        ...state,
                        messagesViewLoading: false
                    }))
                })
        }

        return () => {
            employeeConversationsStore.resetAttachments()
            employeeConversationsStore.resetAttachmentsView()
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(() => {
        if (state.scheduledMessageForEditing) {
            setState(state => ({ ...state, toSendAttachments: [] }))
        }
    }, [state.scheduledMessageForEditing]);

    useEffect(useEffectCB, [conversation]);

    return [{ ...state, ...baseState },
        handleSendMessage,
        handleDropAttachment,
        handleRemoveAttachment,
        handleOpenScheduleDialog,
        handleToggleScheduleDialog,
        handleScheduleMessage,
        handleEndEditScheduledMessage,
        handleSetMessageUnread,
        handleUpdateInteraction,
        handleEndEditAiMessage,
        handleEditInteraction,
        handleSendMessageForAiDemo,
        handleJoinThread
    ];
}
