import React, {FC, useState} from "react";
import {MenuItem, Box} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteIcon from '@material-ui/icons/Delete';
import {useStyles} from "../HoverMenuMessageComponent.styles";

type MenuMessageProps = {
    isMyMessage: boolean,
    handleSetUnread: () => void,
    handleDelete: () => void,
}

export const MenuMessage: FC<MenuMessageProps> = (props: MenuMessageProps) => {

    const classes = useStyles();

    const {isMyMessage, handleSetUnread, handleDelete} = props;

    const [showMore, setShowMore] = useState<boolean>(false);

    const toggleMenu = () => {
        setShowMore(!showMore)
    }



    return (<div>
            {!showMore ? (
                <MoreVertIcon className={classes.moreVert} onClick={toggleMenu}/>
            ) : isMyMessage ? (
                <MenuItem className = {classes.deleteMessageMenu} onClick={handleDelete}>
                    <DeleteIcon className="wh-tw-text-bad"/>
                    <Box px={1.5} className="wh-tw-w-28 wh-tw-text-bad">Delete</Box>
                </MenuItem>
            ) : (
                <MenuItem className = {classes.markUnreadMenu} onClick={handleSetUnread}>Mark unread</MenuItem>
            )}
        </div>
    )
}