import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        subtitle: {
            fontSize: 12,
            color: colors.gray1,
            textTransform: 'uppercase'
        }
    })
);
