
import { HealthGoalsQuestionNames } from "../models/questionnaire.models";
import { DetailedQuestionNames } from "../static/detailedQuestionnaire.static";
import { MedicalHistoryAndLifestyleQuestionNames } from "../static/medicalHistroryQuestionnaire.static";

export const questionViewIntention = [
    [HealthGoalsQuestionNames.YOUR_INTENTION]
]
export const questionViewGoals = [
    [HealthGoalsQuestionNames.YOUR_EXERCISE_GOALS,
    HealthGoalsQuestionNames.PERSONAL_EXERCISE_GOAL,
    HealthGoalsQuestionNames.EXERCISE_ROUTINE_LOOK_LIKE],
    [HealthGoalsQuestionNames.YOUR_NUTRITION_GOALS,
    HealthGoalsQuestionNames.PERSONAL_NUTRITION_GOAL,
    HealthGoalsQuestionNames.TYPE_OF_FOOD_DO_YOU_EAT],
    [HealthGoalsQuestionNames.YOUR_SLEEP_GOALS,
    HealthGoalsQuestionNames.PERSONAL_SLEEP_GOAL,
    HealthGoalsQuestionNames.GOOD_AND_BAD_SLEEP_ROUTINE],
    [HealthGoalsQuestionNames.YOUR_MINDFULNESS_GOALS,
    HealthGoalsQuestionNames.PERSONAL_MINDFULNESS_GOAL,
    HealthGoalsQuestionNames.MINDFULNESS_PRACTICE_LOOK],
    [HealthGoalsQuestionNames.YOUR_SOCIAL_CONNECTIONS,
    HealthGoalsQuestionNames.PERSONAL_SOCIAL_CONNECTION_GOAL,
    HealthGoalsQuestionNames.DESCRIBE_SOCIAL_CONNECTIONS,
    HealthGoalsQuestionNames.TAKE_TIME_TO_WALK_OUTSIDE,
    HealthGoalsQuestionNames.TAKE_TIME_TO_WALK_OUTSIDE_COMMENT,
    HealthGoalsQuestionNames.HEALS_HAPPINESS_PERFORMANCE],
]
export const questionViewHealth = [
    [HealthGoalsQuestionNames.HEALTH_FEELING,
    HealthGoalsQuestionNames.HEALS_COMMENTS],
    [HealthGoalsQuestionNames.OVERALL_PHYSICAL_HEALTH],
    [HealthGoalsQuestionNames.FREE_CHRONIC_PAIN,
    HealthGoalsQuestionNames.FREE_CHRONIC_PAIN_COMMENTS],
    [HealthGoalsQuestionNames.GET_SICK,
    HealthGoalsQuestionNames.SICK_COMMENTS],
    [HealthGoalsQuestionNames.HEALTH_PHYSICAL_FEELING,
    HealthGoalsQuestionNames.HEALS_PHYSICAL_COMMENTS],
    [HealthGoalsQuestionNames.HEALTH_MORE_OR_LESS,
    HealthGoalsQuestionNames.HEALS_MORE_OR_LESS_COMMENTS],
]
export const questionViewHappiness = [
    [HealthGoalsQuestionNames.HAPPINESS_ENJOY_LIFE,
    HealthGoalsQuestionNames.HAPPINESS_ENJOY_LIFE_COMMENT],
    [HealthGoalsQuestionNames.HAPPINESS_CONNECTIONS,
    HealthGoalsQuestionNames.HAPPINESS_CONNECTIONS_COMMENT],
    [HealthGoalsQuestionNames.HAPPINESS_OPTIMISTIC_PESSIMISTIC,
    HealthGoalsQuestionNames.HAPPINESS_OPTIMISTIC_PESSIMISTIC_COMMENT],
    [HealthGoalsQuestionNames.HAPPINESS_COACH_THERAPIST,
    HealthGoalsQuestionNames.HAPPINESS_COACH_THERAPIST_COMMENT],
    [HealthGoalsQuestionNames.HAPPINESS_INTO_MY_80,
    HealthGoalsQuestionNames.HAPPINESS_INTO_MY_80_COMMENT],
    [HealthGoalsQuestionNames.HAPPINESS_OVERALL,
    HealthGoalsQuestionNames.HAPPINESS_OVERALL_COMMENT],
]
export const questionViewPerformance = [
    [HealthGoalsQuestionNames.PERFORMANCE_METRICS,
    HealthGoalsQuestionNames.PERFORMANCE_METRICS_EXPLAIN],
    [HealthGoalsQuestionNames.PERFORMANCE_ACTIVITY_IMPORTANT,
    HealthGoalsQuestionNames.PERFORMANCE_ACTIVITY_IMPORTANT_COMMENT],
    [HealthGoalsQuestionNames.PERFORMANCE_IMPROVEMENT,
    HealthGoalsQuestionNames.PERFORMANCE_IMPROVEMENT_COMMENT],
    [HealthGoalsQuestionNames.PERFORMANCE_CONFIDENT_80S,
    HealthGoalsQuestionNames.PERFORMANCE_CONFIDENT_80S_COMMENT],
    [HealthGoalsQuestionNames.PERFORMANCE_OVERALL,
    HealthGoalsQuestionNames.PERFORMANCE_OVERALL_COMMENT],
]
export const questionViewDemographicsBiometrics = [
    [MedicalHistoryAndLifestyleQuestionNames.DEMOGRAPHICS_AND_BIOMETRICS_ETHNICITY],
    [MedicalHistoryAndLifestyleQuestionNames.DEMOGRAPHICS_AND_BIOMETRICS_HEIGHT,
    MedicalHistoryAndLifestyleQuestionNames.DEMOGRAPHICS_AND_BIOMETRICS_WAIST,
    MedicalHistoryAndLifestyleQuestionNames.DEMOGRAPHICS_AND_BIOMETRICS_WEIGHT],
    [MedicalHistoryAndLifestyleQuestionNames.DEMOGRAPHICS_AND_BIOMETRICS_SBP_TOP,
    MedicalHistoryAndLifestyleQuestionNames.DEMOGRAPHICS_AND_BIOMETRICS_SBP_BOTTOM],

]
export const questionViewMedicalHistory = [
    [MedicalHistoryAndLifestyleQuestionNames.DEMOGRAPHICS_AND_BIOMETRICS_SBP_TOP,
    MedicalHistoryAndLifestyleQuestionNames.DEMOGRAPHICS_AND_BIOMETRICS_SBP_BOTTOM],
    [MedicalHistoryAndLifestyleQuestionNames.MEDICAL_HISTORY_CONDITIONS],
    [MedicalHistoryAndLifestyleQuestionNames.MEDICAL_HISTORY_CONDITIONS_FAMILY],
    [MedicalHistoryAndLifestyleQuestionNames.MEDICAL_HISTORY_DIABET,
    MedicalHistoryAndLifestyleQuestionNames.MEDICAL_HISTORY_DIABET_TYPE],
    [MedicalHistoryAndLifestyleQuestionNames.MEDICAL_HISTORY_CHOLESTEROL],
    [MedicalHistoryAndLifestyleQuestionNames.MEDICAL_HISTORY_BLOOD_PRESSURE],
    [MedicalHistoryAndLifestyleQuestionNames.CORONARY_CALCIUM_SCORE,
    MedicalHistoryAndLifestyleQuestionNames.SCORE],
    [MedicalHistoryAndLifestyleQuestionNames.SMOKING_HISTORY],
    [MedicalHistoryAndLifestyleQuestionNames.ALCOHOL_HISTORY],
    [MedicalHistoryAndLifestyleQuestionNames.ANY_MEDICATIONS,
        MedicalHistoryAndLifestyleQuestionNames.MEDICATIONS],
    [MedicalHistoryAndLifestyleQuestionNames.ANY_SUPPLEMENTS,
        MedicalHistoryAndLifestyleQuestionNames.SUPPLEMENTS],
    [MedicalHistoryAndLifestyleQuestionNames.DRUG_ALLERGIES,
        MedicalHistoryAndLifestyleQuestionNames.ALLERGIES],
]

export const questionViewLifestyle = [
    [MedicalHistoryAndLifestyleQuestionNames.SPECIFIC_KIND_OF_DIET],
    [MedicalHistoryAndLifestyleQuestionNames.RESTRICTED_FOOD],
    [MedicalHistoryAndLifestyleQuestionNames.OILY_FISH],
    [MedicalHistoryAndLifestyleQuestionNames.RED_MEAT],
    [MedicalHistoryAndLifestyleQuestionNames.PROCESSED_MEAT],
    [MedicalHistoryAndLifestyleQuestionNames.SERVINGS_VEGETABLES],
    [MedicalHistoryAndLifestyleQuestionNames.PRACTICE_FEEDING],
    [MedicalHistoryAndLifestyleQuestionNames.PRIMARY_EXERCISE_GOAL],
    [MedicalHistoryAndLifestyleQuestionNames.AVERAGE_LIGHT_EXERCISE_TIME],
    [MedicalHistoryAndLifestyleQuestionNames.AVERAGE_INTENSE_EXERCISE_TIME],
    [MedicalHistoryAndLifestyleQuestionNames.AVERAGE_MODERATE_EXERCISE_TIME],
    [MedicalHistoryAndLifestyleQuestionNames.PUSH_UPS_PERFORMING],
    [MedicalHistoryAndLifestyleQuestionNames.RUN_RUN],
    [MedicalHistoryAndLifestyleQuestionNames.REP_BENCH_PRESS],
    [MedicalHistoryAndLifestyleQuestionNames.DEADLIFT],
    [MedicalHistoryAndLifestyleQuestionNames.REP_SQUAT],
    [MedicalHistoryAndLifestyleQuestionNames.AVERAGE_HOURS_OF_SLEEP],
    [MedicalHistoryAndLifestyleQuestionNames.SLEEP_ROUTINE],
    [MedicalHistoryAndLifestyleQuestionNames.FALL_ASLEEP_NIGHT],
    [MedicalHistoryAndLifestyleQuestionNames.WAKE_UP],
    [MedicalHistoryAndLifestyleQuestionNames.RESTED_READY_WAKEUP],
    [MedicalHistoryAndLifestyleQuestionNames.ENERGY_THROUGHOUT_THE_DAY],
    [MedicalHistoryAndLifestyleQuestionNames.TYPICAL_STRESS],
    [MedicalHistoryAndLifestyleQuestionNames.STRESS_OR_PRACTICE_MINDFULNESS],
    [MedicalHistoryAndLifestyleQuestionNames.PERFORM_YOUR_ROUTINES],
    [MedicalHistoryAndLifestyleQuestionNames.TV_HOURS],
    [MedicalHistoryAndLifestyleQuestionNames.non_TV_SCREEN],
    [MedicalHistoryAndLifestyleQuestionNames.PREFERRED_PHARMACY_PHONE],
    [MedicalHistoryAndLifestyleQuestionNames.PREFERRED_PHARMACY_NAME],
    [MedicalHistoryAndLifestyleQuestionNames.PREFERRED_PHARMACY_ADDRESS],
    [MedicalHistoryAndLifestyleQuestionNames.PREFERRED_PHARMACY_CITY],
    [MedicalHistoryAndLifestyleQuestionNames.PREFERRED_PHARMACY_ZIPCODE],
    [MedicalHistoryAndLifestyleQuestionNames.PREFERRED_PHARMACY_STATE],
    [MedicalHistoryAndLifestyleQuestionNames.PREFERRED_PHARMACY_COUNTRY]
]

export const detailedMedicalHistory = [
    [
        DetailedQuestionNames.CARDIOVASCULAR,
        DetailedQuestionNames.CARDIOVASCULAR_VARIANTS,
        DetailedQuestionNames.HIGH_BLOOD_PRESSURE_YEAR,
        DetailedQuestionNames.HIGH_BLOOD_PRESSURE_TREATMENT,
        DetailedQuestionNames.HIGH_BLOOD_PRESSURE_ACTIVE,
        DetailedQuestionNames.CORONARY_ARTERY_DISEASE_YEAR,
        DetailedQuestionNames.CORONARY_ARTERY_DISEASE_TREATMENT,
        DetailedQuestionNames.CORONARY_ARTERY_DISEASE_ACTIVE,
        DetailedQuestionNames.PULMONARY_EMBOLISM_YEAR,
        DetailedQuestionNames.PULMONARY_EMBOLISM_TREATMENT,
        DetailedQuestionNames.PULMONARY_EMBOLISM_ACTIVE,
        DetailedQuestionNames.DEEP_VIEN_THROMBOSIS_YEAR,
        DetailedQuestionNames.DEEP_VIEN_THROMBOSIS_TREATMENT,
        DetailedQuestionNames.DEEP_VIEN_THROMBOSIS_ACTIVE,
        DetailedQuestionNames.CAROTID_STENOSIS_YEAR,
        DetailedQuestionNames.CAROTID_STENOSIS_TREATMENT,
        DetailedQuestionNames.CAROTID_STENOSIS_ACTIVE,
        DetailedQuestionNames.HEART_ATTACK_YEAR,
        DetailedQuestionNames.HEART_ATTACK_TREATMENT,
        DetailedQuestionNames.HEART_ATTACK_ACTIVE,
        DetailedQuestionNames.CONGESTIVE_HEART_FAILURE_YEAR,
        DetailedQuestionNames.CONGESTIVE_HEART_FAILURE_TREATMENT,
        DetailedQuestionNames.CONGESTIVE_HEART_FAILURE_ACTIVE,
        DetailedQuestionNames.HIGH_CHOLESTEROL_YEAR,
        DetailedQuestionNames.HIGH_CHOLESTEROL_TREATMENT,
        DetailedQuestionNames.HIGH_CHOLESTEROL_ACTIVE,
        DetailedQuestionNames.BLOOD_CLOTTING_DISORDER_YEAR,
        DetailedQuestionNames.BLOOD_CLOTTING_DISORDER_TREATMENT,
        DetailedQuestionNames.BLOOD_CLOTTING_DISORDER_ACTIVE,
        DetailedQuestionNames.ANEURYSM_YEAR,
        DetailedQuestionNames.ANEURYSM_TREATMENT,
        DetailedQuestionNames.ANEURYSM_ACTIVE,
        DetailedQuestionNames.CARDIAC_VALVE_PROBLEM_YEAR,
        DetailedQuestionNames.CARDIAC_VALVE_PROBLEM_TREATMENT,
        DetailedQuestionNames.CARDIAC_VALVE_PROBLEM_ACTIVE,
        DetailedQuestionNames.AFIB_YEAR,
        DetailedQuestionNames.AFIB_TREATMENT,
        DetailedQuestionNames.AFIB_ACTIVE
    ],
    [
        DetailedQuestionNames.GASTROINTESTINAL_ISSUES,
        DetailedQuestionNames.GASTROINTESTINAL_ISSUES_VARIANTS
    ],
    [
        DetailedQuestionNames.SKIN_ISSUES,
        DetailedQuestionNames.SKIN_ISSUES_VARIANTS
    ],
    [
        DetailedQuestionNames.URINARY_ISSUES,
        DetailedQuestionNames.URINARY_ISSUES_VARIANTS
    ],
    [
        DetailedQuestionNames.KIDNEY_ISSUES,
        DetailedQuestionNames.KIDNEY_ISSUES_VARIANTS
    ],
    [
        DetailedQuestionNames.LUNG_ISSUES,
        DetailedQuestionNames.LUNG_ISSUES_VARIANTS
    ],
    [
        DetailedQuestionNames.NEUROLOGICAL_ISSUES,
        DetailedQuestionNames.NEUROLOGICAL_ISSUES_VARIANTS
    ],
    [
        DetailedQuestionNames.ENDOCRINE_HORMONAL_ISSUES,
        DetailedQuestionNames.ENDOCRINE_HORMONAL_ISSUES_VARIANTS
    ],
    [
        DetailedQuestionNames.MENTAL_HEALTH_ISSUES,
        DetailedQuestionNames.MENTAL_HEALTH_ISSUES_VARIANTS
    ],
    [
        DetailedQuestionNames.BLOOD_ISSUES,
        DetailedQuestionNames.BLOOD_ISSUES_VARIANTS
    ],
    [
        DetailedQuestionNames.MUSCULOSKELETAL_ISSUES,
        DetailedQuestionNames.MUSCULOSKELETAL_ISSUES_VARIANTS
    ],
    [
        DetailedQuestionNames.CANCER_ISSUES,
        DetailedQuestionNames.CANCER_ISSUES_VARIANTS,
        DetailedQuestionNames.BREAST_CANCER_YEAR,
        DetailedQuestionNames.BREAST_CANCER_TREATMENT,
        DetailedQuestionNames.BREAST_CANCER_ACTIVE,
        DetailedQuestionNames.PROSTATE_CANCER_YEAR,
        DetailedQuestionNames.PROSTATE_CANCER_TREATMENT,
        DetailedQuestionNames.PROSTATE_CANCER_ACTIVE,
        DetailedQuestionNames.LUNG_CANCER_YEAR,
        DetailedQuestionNames.LUNG_CANCER_TREATMENT,
        DetailedQuestionNames.LUNG_CANCER_ACTIVE,
        DetailedQuestionNames.COLON_CANCER_YEAR,
        DetailedQuestionNames.COLON_CANCER_TREATMENT,
        DetailedQuestionNames.COLON_CANCER_ACTIVE,
        DetailedQuestionNames.BONE_CANCER_YEAR,
        DetailedQuestionNames.BONE_CANCER_TREATMENT,
        DetailedQuestionNames.BONE_CANCER_ACTIVE,
        DetailedQuestionNames.LIVER_CANCER_YEAR,
        DetailedQuestionNames.LIVER_CANCER_TREATMENT,
        DetailedQuestionNames.LIVER_CANCER_ACTIVE,
        DetailedQuestionNames.KIDNEY_CANCER_YEAR,
        DetailedQuestionNames.KIDNEY_CANCER_TREATMENT,
        DetailedQuestionNames.KIDNEY_CANCER_ACTIVE,
        DetailedQuestionNames.BAIN_CANCER_YEAR,
        DetailedQuestionNames.BAIN_CANCER_TREATMENT,
        DetailedQuestionNames.BAIN_CANCER_ACTIVE,
        DetailedQuestionNames.SKIN_CANCER_YEAR,
        DetailedQuestionNames.SKIN_CANCER_TREATMENT,
        DetailedQuestionNames.SKIN_CANCER_ACTIVE,
    ],
    [
        DetailedQuestionNames.DIAGNOSED_EATING_DISORDER_ISSUES,
        DetailedQuestionNames.DIAGNOSED_EATING_DISORDER_ISSUES_VARIANTS,
    ],
]

export const detailedSurgeryHistory = [
    [
        DetailedQuestionNames.SURGERY_ISSUES,
        DetailedQuestionNames.SURGERY_ISSUES_VARIANTS,
        DetailedQuestionNames.APPENDECTOMY_SURGERY_YEAR,
        DetailedQuestionNames.CORONARY_ARTERY_BYPASS_SURGERY_YEAR,
        DetailedQuestionNames.CAROTID_ENDARTERECTOMY_SURGERY_YEAR,
        DetailedQuestionNames.CHOLECYSTECTOMY_SURGERY_YEAR,
        DetailedQuestionNames.BARIATRIC_SURGERY_YEAR,
        DetailedQuestionNames.HYSTERECTOMY_SURGERY_YEAR,
        DetailedQuestionNames.NEPHRECTOMY_SURGERY_YEAR,
        DetailedQuestionNames.SPLENECTOMY_SURGERY_YEAR,
        DetailedQuestionNames.TONSILLECTOMY_SURGERY_YEAR
    ]
]

export const detailedSocialHistory = [
    [
        DetailedQuestionNames.SMOKING,
        DetailedQuestionNames.YEARS_SMOKING,
        DetailedQuestionNames.CIGARETTES_DAY
    ],
    [
        DetailedQuestionNames.DRINK_ISSUES,
        DetailedQuestionNames.AVERAGE_DRINK_ISSUES,
        DetailedQuestionNames.REDUCE_DRINK_ISSUES,
        DetailedQuestionNames.CRITICIZING_ISSUES,
        DetailedQuestionNames.FELT_GUILTY_ISSUES,
        DetailedQuestionNames.EYE_OPENER_ISSUES 
    ],
    [
        DetailedQuestionNames.DRUGS_ISSUES,
        DetailedQuestionNames.DRUGS_ISSUES_VARIANTS
    ],
    [
        DetailedQuestionNames.PARTNERSHIP_STATUS
    ],
    [
        DetailedQuestionNames.HAVE_CHILDREN,
        DetailedQuestionNames.CHILDREN_NAME,
        DetailedQuestionNames.CHILDREN_AGE
    ],
    [
        DetailedQuestionNames.LIVE_ALONE
    ],
    [
        DetailedQuestionNames.SEXUALLY_ACTIVE,
        DetailedQuestionNames.CONTRACEPTION_PROTECTION,
        DetailedQuestionNames.LEARNING_CONTRACEPTION_PROTECTION
    ],
]

export const detailedWomenHealthHistory = [
    [
        DetailedQuestionNames.MENSTRUAL_CYCLE
    ],
    [
        DetailedQuestionNames.MENOPAUSE,
        DetailedQuestionNames.MENOPAUSE_AGE
    ],
    [
        DetailedQuestionNames.PREGNANT,
        DetailedQuestionNames.TOTAL_PREGNANCIES,
        DetailedQuestionNames.FULL_TERM_BIRTHS,
        DetailedQuestionNames.PREMATURE,
        DetailedQuestionNames.MISCARRIAGES,
        DetailedQuestionNames.ABORTIONS
    ],
    [
        DetailedQuestionNames.PREGNANCY_COMPLICATIONS,
        DetailedQuestionNames.PREGNANCY_COMPLICATIONS_VARIANTS
    ]
]

export const detailedFamilyHistory = [
    [
        DetailedQuestionNames.BIOLOGICAL_MOTHER_LIVING,
        DetailedQuestionNames.BIOLOGICAL_MOTHER_MEDICAL_PROBLEMS
    ],
    [
        DetailedQuestionNames.BIOLOGICAL_FATHER_LIVING,
        DetailedQuestionNames.BIOLOGICAL_FATHER_MEDICAL_PROBLEMS
    ],
    [
        DetailedQuestionNames.BIOLOGICAL_SIBLING,
        DetailedQuestionNames.BIOLOGICAL_SIBLING_LIVING,
        DetailedQuestionNames.BIOLOGICAL_SIBLING_MEDICAL_PROBLEMS
    ],
]

export const detailedScreeningHistory = [
    [
        DetailedQuestionNames.YOUR_GENE_MUTATIONS
    ],
    [
        DetailedQuestionNames.FAMILY_GENE_MUTATIONS,
        DetailedQuestionNames.MUTATIONS_DIAGNOSED,
        DetailedQuestionNames.CANCER_DIAGNOSED,
        DetailedQuestionNames.BRCA_FAMILY_GENE_MUTATIONS
    ],
    [
        DetailedQuestionNames.MAMMOGRAM,
        DetailedQuestionNames.DATE_OF_LAST_MAMMOGRAM,
        DetailedQuestionNames.MAMMOGRAM_ABNORMALITIES
    ],
    [
        DetailedQuestionNames.PAP_SMEAR,
        DetailedQuestionNames.DATE_OF_LAST_PAP_SMEAR,
        DetailedQuestionNames.PAP_SMEAR_ABNORMALITIES
    ],
    [
        DetailedQuestionNames.COLON_CANCER,
        DetailedQuestionNames.COLON_CANCER_DIAGNOSED,
        DetailedQuestionNames.COLON_CANCER_DIAGNOSED_AGE
    ],
    [
        DetailedQuestionNames.COLONOSCOPY,
        DetailedQuestionNames.SPECIFY_SCREENING,
        DetailedQuestionNames.DATE_OF_LAST_SCREENING,
        DetailedQuestionNames.COLONOSCOPY_ABNORMALITIES
    ],
    [
        DetailedQuestionNames.PSA_OR_PROSTATE,
        DetailedQuestionNames.DATE_OF_PSA_OR_PROSTATE_EXAM,
        DetailedQuestionNames.PSA_ABNORMALITIES
    ],
    [
        DetailedQuestionNames.LUNG_CANCER_SCREENINGS,
        DetailedQuestionNames.DATE_OF_LUNG_CANCER
    ],
    [
        DetailedQuestionNames.MORE_65_OSTEOPOROSIS,
        DetailedQuestionNames.MORE_65_ABNORMALITIES
    ],
    [
        DetailedQuestionNames.PHQ2_1,
        DetailedQuestionNames.PHQ2_2
    ],
    [
        DetailedQuestionNames.PHQ8_1,
        DetailedQuestionNames.PHQ8_2,
        DetailedQuestionNames.PHQ8_3,
        DetailedQuestionNames.PHQ8_4,
        DetailedQuestionNames.PHQ8_5,
        DetailedQuestionNames.PHQ8_6,
        DetailedQuestionNames.PHQ8_7,
        DetailedQuestionNames.PHQ8_8,
    ]
]

export const detailedVaccinesHistory = [
    [
        DetailedQuestionNames.TETANUS_VACCINE,
        DetailedQuestionNames.DATE_OF_LAST_TETANUS_VACCINE
    ],
    [
        DetailedQuestionNames.PNEUMONIA_VACCINE,
        DetailedQuestionNames.DATE_OF_LAST_PNEUMONIA_VACCINE
    ],
    [
        DetailedQuestionNames.FLU_VACCINE_SEASON
    ],
    [
        DetailedQuestionNames.COVID_VACCINE
    ]
]

export const detailedHealthReviewHistory = [
    [
        DetailedQuestionNames.HEALTH_WELLNESS_REVIEW
    ]
]