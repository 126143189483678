import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import { colors } from '../constants/colors';

export const WildHealthRadio = withStyles({
    root: {
        color: colors.main,
        '&$checked': {
            color: colors.main,
        },
    },
    checked: {},
    disabled: {
        color: colors.stroke,
        '&$checked': {
            color: colors.stroke,
        }
    }
})((props: RadioProps) => <Radio color="default" {...props} />);