import {Observable} from "rxjs";
import {useEffect, useState} from "react";
import {supplementsService} from "../../services/supplements.service";
import {SearchFullscriptSupplementComponentProps} from "./SearchFullscriptSupplementComponent";
import {FullscriptSupplementModel} from "../../models/fullscriptSupplementModels";

interface SearchFullscriptSupplementComponentState {
    isLoading: boolean;
    searchQuery: string;
    supplements: FullscriptSupplementModel[];
}

let stateContext: SearchFullscriptSupplementComponentState;

export function useFacade(props: SearchFullscriptSupplementComponentProps): [
    SearchFullscriptSupplementComponentState,
    (searchQuery: string) => void,
    (supplement: FullscriptSupplementModel) => void,
] {
    const [state, setState] = useState({
        isLoading: false,
        searchQuery: '',
        supplements: []
    });

    const handleSearchQueryChanges = (searchQuery: string) => {
        setState(state => ({
            ...state,
            searchQuery: searchQuery
        }));
    }

    const handleSubmit = (supplement: FullscriptSupplementModel) => {
        props.handleSelect(supplement);
    }

    const getAutocomplete = (searchQuery: string): Observable<FullscriptSupplementModel[]> => {
        return new Observable<FullscriptSupplementModel[]>((observer) => {
            supplementsService.searchInFullscript(searchQuery).subscribe(response => {
                observer.next(response);
                observer.complete();
            })
        })
    }

    stateContext = state;

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (stateContext.searchQuery) {
                setState(state => ({
                    ...state,
                    isLoading: true
                }));
                getAutocomplete(stateContext.searchQuery).subscribe(
                    (supplements) => {

                        setState(state => ({
                            ...state,
                            supplements: supplements,
                            isLoading: false
                        }));
                    },
                    () => {
                        setState(state => ({
                            ...state,
                            supplements: [],
                            isLoading: false
                        }));
                    })
            }
        }, 1500);

        return () => clearTimeout(delayDebounceFn)
    }, [state.searchQuery]);

    return [
        state,
        handleSearchQueryChanges,
        handleSubmit
    ]
}