import {useEffect, useState} from "react";
import {Subscription} from "recompose";
import {onEmit} from "../../../common/helpers/on-emit";
import {SortingDirection} from "../../../common/sorting/models/sorting-destination";
import {ISortingState} from "../../../common/sorting/models/sorting-state";
import {handleCompare} from "../../../common/sorting/helpers/handle-compare";
import {IErrorState} from "../../../common/validation/error-state";
import {SupplementItem} from "../../../notes/models/notes.models";
import {notesQuery} from "../../../notes/stores/notes";
import {createCommonSupplementValidator} from "./createCommonSupplement.validator";
import {confirmService} from "../../../../services/confirm.service";
import {commonSupplementsService} from "../../../notes/services/commonSupplements.service";
import {authQuery} from "../../../auth/stores/auth";
import {defaultDosage} from "../../../patientSupplements/constants/medicationsAndSupplementsContants";
import {SupplementSource} from "../../../patientSupplements/models/patientSupplementModel";

export enum SortingSource {
  None,
  Name = "name",
}

interface ManageCommonSupplementsComponentState extends ISortingState, IErrorState {
  isLoading: boolean;
  isSubmitting: boolean;
  isOpen: boolean;
  commonSupplements: SupplementItem[];
  createCommonSupplement: SupplementItem;
  removingId: number;
}

const initialParmas: SupplementItem = {
  id: 0,
  name: '',
  dosage: '',
  instructions: authQuery.isEmployeeUser() ? defaultDosage : '',
  source: SupplementSource.Notes,
  purchaseLink: '',
  isInCurrent: false,
  isStopped: false
}

export function useFacade(): [
  ManageCommonSupplementsComponentState,
  (field: string, value: any) => void,
  (source: string, direction: SortingDirection) => void,
  () => void,
  (id: number) => void,
  (id: number) => void
] {
  const [state, setState] = useState({
    isLoading: true,
    isSubmitting: false,
    isOpen: false,
    commonSupplements: [],
    createCommonSupplement: initialParmas,
    removingId: 0,
    sortingColumns: [
      { title: 'Name', source: SortingSource.Name, direction: SortingDirection.Asc },
      { title: 'Dosage', source: null, direction: null },
      { title: 'Instructions', source: null, direction: null },
      { title: 'PurchaseLink', source: null, direction: null },
      { title: 'Action', source: null, direction: null },
    ],
    sortingSource: SortingSource.None,
    errors: {}
  } as ManageCommonSupplementsComponentState);

  const setDirection = (source: string, direction: SortingDirection) => {
    const itemIndex = state.sortingColumns.findIndex(item => item.source === source);
    state.sortingColumns[itemIndex].direction = direction;
    setState(state => ({ ...state, columns: state.sortingColumns }));
  }

  const handleSorting = (source: string, direction: SortingDirection) => {
    if (state.sortingSource === source) {
      direction = direction === SortingDirection.Asc
        ? SortingDirection.Desc
        : SortingDirection.Asc;

      setDirection(source, direction);
    }
    setState(state => ({
      ...state,
      sortingSource: source,
      commonSupplements: state.commonSupplements.sort((p1, p2) => handleCompare(p1, p2, direction, source))
    }));
  }

  const handleChanges = (field: string, value: any) => {
    createCommonSupplementValidator.validateAndSetState(state, setState, field, value);

    const params = state.createCommonSupplement;
    
    params[field] = value;

    setState({...state, createCommonSupplement: Object.assign({}, params)});
  }

  const handleSave = () => {
    createCommonSupplementValidator.validateObjectAndSetState(state, setState, state.createCommonSupplement);
    if (!createCommonSupplementValidator.stateIsValid(state)) {
      return;
    }

    setState({...state, isSubmitting: true })

    const cb = () => setState(state => ({ ...state, isSubmitting: false, isOpen: false, createCommonSupplement: Object.assign({}, initialParmas) }));

    const action = state.createCommonSupplement.id > 0 ? commonSupplementsService.updateCommonSupplement(state.createCommonSupplement) : commonSupplementsService.createCommonSupplement(state.createCommonSupplement)
    
    action.subscribe(cb, cb)
  }
  
  const handleDelete = (id: number) => {
    confirmService.confirm(
      'Delete Common Supplement',
      'Are you sure you want to delete this supplement?',
      'Yes',
      'Cancel',
      'danger')
      .subscribe(() => {
        setState(state => ({
          ...state,
          isSubmitting: true,
          removingId: id
        }));

        const cb = () => setState(state => ({ ...state, isSubmitting: false, removingId: 0 }));
        commonSupplementsService.deleteCommonSupplement(id).subscribe(cb, cb);
      });
  }

  const handleToggle = (id: number) => {
    const item = state.commonSupplements.find(x => x.id === id);
    setState((state) => ({
      ...state,
      isOpen: !state.isOpen,
      createCommonSupplement: Object.assign({}, item ? item : initialParmas),
      errors: {}
    }))
  }

  const useEffectCB = () => {
    const subscriptions: Subscription[] = [
      onEmit<SupplementItem[]>(notesQuery.commonSupplements$, commonSupplements => {
        setState(state => ({
          ...state,
          commonSupplements,
        }));
      }),
    ];

    const cb = () => setState(state => ({ ...state, isLoading: false }));

    commonSupplementsService.getAllCommonSupplements().subscribe(cb, cb);

    return () => {
      subscriptions.map(it => it.unsubscribe())
    };
  }

  useEffect(useEffectCB, []);

  return [
    state,
    handleChanges,
    handleSorting,
    handleSave,
    handleDelete,
    handleToggle
  ];
}