import { Box, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import React from 'react';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from 'clsx';
import { AppointmentCommentComponent } from "../appointmentComment/AppointmentCommentComponent";
import { useStyles } from './EditAppointmentDialogComponent.styles';
import { useFacade } from './editAppointmentDialogComponent.hooks';
import { BaseAppointmentModel } from '../../models/appointments.models';
import { DialogTitle } from '../../../common/components/dialogTitle/DialogTitle';


interface EditAppointmentDialogComponentProps {
    appointment: BaseAppointmentModel
    open: boolean;
    handleClose: () => void
}

export const EditAppointmentDialogComponent: React.FC<EditAppointmentDialogComponentProps> = (props: EditAppointmentDialogComponentProps) => {
    const {
        appointment,
        open,
        handleClose
    } = props;

    const [
        state,
        handleEditComment,
        handleSubmit
    ] = useFacade(appointment, handleClose);

    const commonClasses = commonUseStyles();
    const classes = useStyles()

    return (
        <>
            <Dialog
                fullWidth
                maxWidth='sm'
                open={open}
                onClose={handleClose}>

                <DialogTitle
                    id="customized-dialog-title"
                    classes={{ root: classes.dialogTitle }}
                    onClose={() => handleClose()}
                >
                    <span className={clsx(commonClasses.size24, commonClasses.textMedium)}>
                        Edit Appointment
                    </span>
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    <Box>
                        <AppointmentCommentComponent
                            handleChangeComment={(e) => handleEditComment(e.currentTarget.value)}
                            comment={state.appointmentDraft.comment}
                            rows={5}
                            error={!!state.errors['comment']}
                            helperText={state.errors['comment']}
                        />
                    </Box>
                </DialogContent>
                <DialogActions classes={{ root: classes.dialogActions }}>
                    <WildHealthButton
                        id="edit-appointment-cancel"
                        size='large'
                        color='tertiary'
                        onClick={() => handleClose()}
                        disabled={state.isLoading}
                    >
                        Cancel
                    </WildHealthButton>
                    <WildHealthButton
                        id="edit-appointment-save"
                        size='large'
                        onClick={() => handleSubmit()}
                        loading={state.isLoading}
                    >
                        Save
                    </WildHealthButton>
                </DialogActions>
            </Dialog>
        </>
    );
}