import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { onEmit } from "../../../common/helpers/on-emit";
import { NavigationItemModel, NavigationItemsType } from "../../../common/models/navigation.models";
import { PatientConversationModel } from "../../../conversations/models/conversation.models";
import { patientConversationsQuery } from "../../../conversations/stores/patientConversationsStore/patientConversations.query";
import { authQuery } from "../../../auth/stores/auth";
import { UserType } from "../../../auth/models/auth.enums";
import { navigationService } from "../../../../services/navigation.service";
import { useHistory } from 'react-router';



interface NavigationWidgetComponentState {
    navigationItems: NavigationItemModel[];
    conversationHealthCare: PatientConversationModel[],
    conversationSupport: PatientConversationModel[],
}

const navigationPatientItems = [
    {
        type: NavigationItemsType.Appointments,
        name: 'Appointments',
        badge: false,
    },
    {
        type: NavigationItemsType.Biometrics,
        name: 'Biometrics',
        badge: false,
    },
    {
        type: NavigationItemsType.MyGoals,
        name: 'My Goals',
        badge: false,
    },
    {
        type: NavigationItemsType.Labs,
        name: 'Labs',
        badge: false,
    },
    {
        type: NavigationItemsType.Messaging,
        name: 'Messaging',
        badge: false,
    },
    {
        type: NavigationItemsType.VisitNotes,
        name: 'Visit Notes',
        badge: false,
    },
]

export function useFacade(
    patientId: number | null
): [
        NavigationWidgetComponentState,
        (type: NavigationItemsType) => void,
    ] {
    const [state, setState] = useState({
        navigationItems: navigationPatientItems,
        conversationHealthCare: [],
        conversationSupport: [],
    } as NavigationWidgetComponentState);

    const history = useHistory();

    const hasNewMessages = (conversations: PatientConversationModel[]): boolean => {
        return conversations.filter(c => c.unreadMessages > 0).length > 0
    }

    const setUnreadMessages = (conversations: PatientConversationModel[]) => {
        const navigationMessaging = state.navigationItems.find(el => el.type === NavigationItemsType.Messaging)
        navigationMessaging.badge = hasNewMessages(conversations)
        setState(state => ({
            ...state,
            navigationItems: [...state.navigationItems.filter(el => el.type !== NavigationItemsType.Messaging), navigationMessaging]
        }));

    }

    useEffect(() => {
        if (state.conversationHealthCare.length || state.conversationSupport.length) {
            setUnreadMessages([...state.conversationHealthCare, ...state.conversationSupport])
        }
    }, [state.conversationHealthCare, state.conversationSupport]);

    const handleNavigate = (type: NavigationItemsType) => {
        if (patientId) {
            switch (type) {
                case NavigationItemsType.Appointments:
                    navigationService.toPatientUpcomingAppts(history, patientId);
                    break;
                case NavigationItemsType.VisitNotes:
                    navigationService.toPatientProfileNote(history, patientId)
                    break;
                case NavigationItemsType.Messaging:
                    navigationService.toPatientConversation(history, patientId);
                    break;
                case NavigationItemsType.Labs:
                    navigationService.toPatientLabResults(history, patientId)
                    break;
                case NavigationItemsType.MyGoals:
                    navigationService.toPatientHealthPlan(history, patientId)
                    break;
                case NavigationItemsType.Biometrics:
                    navigationService.toVitalsHistory(history, patientId)
                    break;
                default:
                    navigationService.toPatientDashboard(history, patientId)
            }
        } else {
            switch (type) {
                case NavigationItemsType.Appointments:
                    navigationService.toPatientUpcommingAppointments(history);
                    break;
                case NavigationItemsType.VisitNotes:
                    navigationService.toPatientNotes(history)
                    break;
                case NavigationItemsType.Messaging:
                    navigationService.toConversations(history);
                    break;
                case NavigationItemsType.Labs:
                    navigationService.toLabs(history)
                    break;
                case NavigationItemsType.MyGoals:
                    navigationService.toMyHealthPlan(history)
                    break;
                case NavigationItemsType.Biometrics:
                    navigationService.toVitalsHistory(history)
                    break;
                default:
                    navigationService.toDashboard(history)
            }
        }

    };

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<PatientConversationModel>(patientConversationsQuery.heathCareConversation$, conversation => {
                if (conversation && authQuery.getType() === UserType.Patient) {
                    setState(state => ({
                        ...state,
                        conversationHealthCare: [conversation]
                    }));
                }
            }),
            onEmit<PatientConversationModel[]>(patientConversationsQuery.supportConversations$, conversations => {
                if (conversations && authQuery.getType() === UserType.Patient) {
                    setState(state => ({
                        ...state,
                        conversationSupport: conversations
                    }));
                }
            }),

        ];

        return () => {
            subscriptions.map((it) => it.unsubscribe());
        };
    }, [patientId]);

    return [
        state,
        handleNavigate,
    ];
}