import { ListItemText } from '@material-ui/core';
import { makeStyles, withStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: 0
    },
    dialogTitle: {
        paddingLeft: 32,
    },
    title: {
        color: colors.gray1,
        fontFamily: 'Be Vietnam Pro',
        fontWeight: 500,
        fontSize: 12,
        textTransform: 'uppercase',
        padding: "16px 16px 0 16px",
        [theme.breakpoints.down("xs")]: {
          paddingBottom: 10,
        },
    },
    titleIcon: {
        fill: colors.gray1,
    },
    document: {
        padding: "0 8px",
        borderRadius: 2,
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: 44,
    },
    fileIcon: {
        color: colors.gray2,
        display: "inline-block",
    },
}));

export const ScheduledMessage = withStyles((theme: Theme) => ({
    root: {
        "& .MuiListItemText-primary": {
            borderRadius: '6px 6px 0px 6px',
            background: colors.gray3,
            wordBreak: 'break-all',
            color: colors.black,
            overflow: 'auto',
            fontWeight: 500,
            maxHeight: 198,
            maxWidth: 254,
            fontSize: 16,
            padding: 8,
            [theme.breakpoints.down("xs")]: {
                maxWidth: 'none',
            },
        },
    }
}))(ListItemText);

export const ScheduledMessageDialog = withStyles({
    root: {
        "& .MuiListItemText-primary": {
            borderRadius: '6px 6px 0px 6px',
            background: colors.gray3,
            wordBreak: 'break-all',
            color: colors.black,
            fontWeight: 500,
            fontSize: 16,
            padding: 8,
        },
    }
})(ListItemText);

export const FileName = withStyles({
    root: {
        "& .MuiListItemText-primary": {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            maxWidth: 174,
        },
    },
})(ListItemText);
