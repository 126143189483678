import { TextField, Switch } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme) => ({
  root: {
    borderColor: colors.mediumGray,
    borderRadius: 4,
    '& .MuiOutlinedInput-root': {
      borderColor: colors.mediumGray,
      borderRadius: 4,
    },
  },
  label: {
    color: colors.darkPurple,
    fontSize: 14,
    fontWeight: 400,
  },
  searchBase: {
    padding: '10px 14px',
  },
  icon: {
    color: colors.darkPurple
  },
  activeIcon: {
    color: colors.main,
  },
  seeMoreBtn: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    cursor: 'pointer'
  }
}));

export const DiscountAmountField = withStyles({
  root: {
    borderColor: colors.mediumGray,
    borderRadius: 4,
    '& .MuiOutlinedInput-root': {
      borderColor: colors.mediumGray,
      borderRadius: 4,
    },
  },
})(TextField);

export const WildHealthSwitch = withStyles({
  switchBase: {
    color: colors.mediumGray,
    '&$checked': {
      color: colors.lightBlue2,
      '& + $track': {
        opacity: 1,
        backgroundColor: colors.main,
      },
    },
  },
  checked: {},
  track: {
    opacity: 1,
    backgroundColor: colors.lightGray1,
  },
})(Switch);
