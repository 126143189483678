import { useState, useEffect } from "react";
import { Subscription } from "rxjs";
import moment from "moment";
import { onEmit } from "../../../common/helpers/on-emit";
import {
    SubscriptionModel,
    SubscriptionStatus,
    ManualPaymentModel
} from '../../../payment/models/subscription.models';
import { subscriptionService } from "../../../payment/services/subscription.service";
import { subscriptionQuery } from "../../../payment/stores/subscriptionStore";
import { PaymentPlanHistoryModel } from "../../../patients/models/patient.model";
import { patientProfileQuery } from "../../../employee/stores/patientProfileStore";
import { premiumBillingComponentValidator } from "./premiumBillingComponent.validator";
import { IErrorState } from "../../../common/validation/error-state";
import { CreateManualPaymentModel } from "../../../payment/models/subscription.models";
import { getLastObject } from "../../../common/helpers/get-last-object";
import { patientsService } from "../../../patients/services/patients.service";

interface premiumBillingComponentState extends IErrorState {
    isAdding: boolean;
    isOpenReview: boolean;
    isCollapse: boolean;
    manualPayment: ManualPaymentModel;
    activeMembership: SubscriptionModel;
    subscriptions: SubscriptionModel[];
    paymentPlanHistories: PaymentPlanHistoryModel[];
    selectedDate: Date;
}

const initManualPayment = {
    id: 0,
    total: 0,
    deposit: 0,
    downPayment: 0,
    remainingPaidOverMonths: 0,
    scheduleItems: []
}

export function useFacade(patientId?: number): [
    premiumBillingComponentState,
    () => void,
    () => void,
    () => void,
    (field: string, value: any) => void,
    (value: any) => void,
    (value: any) => void,
    (value: any) => void,
    (field: string) => void,
    () => void,
    () => void
] {
    const [state, setState] = useState({
        isAdding: false,
        isOpenReview: false,
        isCollapse: false,
        manualPayment: null,
        activeMembership: null,
        subscriptions: [],
        paymentPlanHistories: [],
        selectedDate: moment(new Date()).add(1, 'months').toDate(),
        errors: {},
    } as premiumBillingComponentState);

    const handleToggle = () => {
        setState({...state, manualPayment: Object.assign({}, initManualPayment)});
    }

    const handleExpand = () => {
        setState(state => ({...state, isCollapse: !state.isCollapse}));
    }

    const handleOpenReview = () => {
        premiumBillingComponentValidator.validateObjectAndSetState(state, setState, state.manualPayment);

        if (!premiumBillingComponentValidator.stateIsValid(state)) {
            return
        }
        const amount = (state.manualPayment.total - state.manualPayment.downPayment - state.manualPayment.deposit) / state.manualPayment.remainingPaidOverMonths;
        const items = [];
        for (let index = 0; index < state.manualPayment.remainingPaidOverMonths; index++) {
            const item = {
                amount,
                dueDate: moment(state.selectedDate).add(index, 'months').toDate()
            }
            items.push(item);
        }
        
        setState(state => ({...state, isOpenReview: true, manualPayment: {...state.manualPayment, scheduleItems: items}}));
    }

    const handleCloseReview = () => {
        setState(state => ({...state, isOpenReview: false, manualPayment: {...state.manualPayment, scheduleItems: []}}));
    }

    const handleChangeDate = (value: any) => {
        premiumBillingComponentValidator.validateAndSetState(state, setState, 'selectedDate', value);

        setState({...state, selectedDate: value});
    }

    const handleChangeDownPayment = (value: any) => {
        premiumBillingComponentValidator.validateAndSetState(state, setState, 'downPayment', value);

        const params = state.manualPayment;
        params.downPayment = value;
        if (value > 0) {
            const amount = (state.manualPayment.total - value - state.manualPayment.deposit) / state.manualPayment.remainingPaidOverMonths;
    
            const items = state.manualPayment.scheduleItems.map(x => ({amount, dueDate: x.dueDate}));
            params.scheduleItems = items;
        }

        setState({...state, manualPayment: Object.assign({}, params)});
    }

    const handleChangeItemAmount = (value: any) => {
        if (value < 0) return;

        const items = state.manualPayment.scheduleItems.map(x => ({amount: value, dueDate: x.dueDate}));
        const total = Number(value * state.manualPayment.remainingPaidOverMonths) + Number(state.manualPayment.downPayment) + Number(state.manualPayment.deposit);

        setState(state => ({...state, manualPayment: {...state.manualPayment, total, scheduleItems: items}}));
    }

    const handleChanges = (field: string, value: any) => {
        premiumBillingComponentValidator.validateAndSetState(state, setState, field, value);

        const params = state.manualPayment;
        const keys = field.split(".");
        const key = keys.pop();
        const lastObject = getLastObject(keys, params);
        lastObject[key] = value;

        setState(state => ({...state, manualPayment: Object.assign({}, params)}));
    }

    const handleSubmitForm = () => {
        premiumBillingComponentValidator.validateObjectAndSetState(state, setState, state.manualPayment);

        if (!premiumBillingComponentValidator.stateIsValid(state)) {
            return
        }
        setState(state => ({...state, isAdding: true}));

        const model = {
            ...state.manualPayment,
            patientId: patientId,
            subscriptionId: state.activeMembership ? state.activeMembership.id : state.subscriptions?.[0]?.id
        } as CreateManualPaymentModel

        const cb = () => setState(state => ({...state, isAdding: false, isOpenReview: false}))
        subscriptionService.addPremiumBilling(model).subscribe(() => {
            subscriptionService.get(patientId).subscribe(cb, cb);
            patientsService.getPaymentPlanUpdateHistory(patientId).subscribe();
        }, cb)
    }

    const handleOnBlurValidate = (field: string): boolean => {
        switch (field) {
            case 'selectedDate':
                premiumBillingComponentValidator.validateAndSetState(state, setState, "selectedDate", state.selectedDate);

                return premiumBillingComponentValidator.stateIsValid(state);
        }
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<SubscriptionModel[]>(subscriptionQuery.patientSubscriptions$, subscriptions => {
                const active = subscriptions.find(s => s.status === SubscriptionStatus.Active || s.status === SubscriptionStatus.Paused);
                setState(state => ({
                    ...state,
                    subscriptions,
                    activeMembership: active,
                }));
            }),
            onEmit<ManualPaymentModel>(subscriptionQuery.manualPayment$, manualPayment => {
                setState(state => ({
                    ...state,
                    manualPayment: manualPayment,
                }));
            }),
            onEmit<PaymentPlanHistoryModel[]>(patientProfileQuery.paymentPlanHistories$, paymentPlanHistories => {
                setState(state => ({
                    ...state,
                    paymentPlanHistories: paymentPlanHistories,
                }));
            }),
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, []);

    return [state, handleToggle, handleOpenReview, handleCloseReview, handleChanges, handleChangeDownPayment, handleChangeItemAmount, handleChangeDate, handleOnBlurValidate, handleSubmitForm, handleExpand];
}
