import { AppointmentsSummaryModel, AppointmentTypeModel, PatientAppointmentModel } from '../../models/appointments.models';
import { locationsService } from "../../../locations/services/locations.service";
import { employeeService } from "../../../employee/services/employees.service";
import { patientsService } from "../../../patients/services/patients.service";
import { getCurrentTimezone } from '../../../timezones/helpers/timezone';
import { LocationModel } from "../../../locations/models/locations.models";
import { appointmentsService } from "../../services/appointments.service";
import { EmployeeModel } from "../../../employee/models/employee.models";
import { employeesQuery } from "../../../employee/stores/employeesStore";
import { patientsQuery } from "../../../patients/stores/patientsStore";
import { appointmentsQuery } from "../../stores/appointments";
import { locationsQuery } from "../../../locations/stores";
import { onEmit } from "../../../common/helpers/on-emit";
import { authQuery } from "../../../auth/stores/auth";
import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { timezonesQuery } from "../../../timezones/stores/timezones";
import { timezonesService } from "../../../timezones/services/timezones.service";
import { TimeZoneModel } from "../../../timezones/models/timezone.model";
import { AppointmentWithType } from '../../models/appointments.enums';
import { paymentService } from '../../../payment/services/payment.service';
import { IntegrationVendor } from '../../../payment/models/payment.models';
import { SubscriptionModel } from '../../../payment/models/subscription.models';
import { subscriptionQuery } from '../../../payment/stores/subscriptionStore';
import { subscriptionService } from '../../../payment/services/subscription.service';
import {productsService} from "../../../products/services/products.service";
import {ProductModel} from "../../../products/models/product.model";
import {ProductType} from "../../../products/enums/productType";
import {productsQuery} from "../../../products/stores/productsStore/products.query";
import { profileService } from '../../../account/services/profile.service';

interface PatientAppointmentPageState {
    appointments: PatientAppointmentModel[];
    appointmentsSummary: AppointmentsSummaryModel;
    products: ProductModel[];
    assignedEmployees: EmployeeModel[];
    availableTypes: AppointmentTypeModel[];
    locations: LocationModel[];
    timeZone: TimeZoneModel;
    timeZones: TimeZoneModel[];
    isLoading: boolean;
    isPhotosLoading: boolean;
    createAppointmentWithTypes: AppointmentWithType[];
    isBuyProductsLink: boolean;
    link: string;
    subscription: SubscriptionModel;
}

/**
 * Custom Hook to manage a view Model for Appointments component
 */
export function useFacade(patientId: number | null): [
    PatientAppointmentPageState,
    (appointmentId: number) => void,
    (appointmentId: number) => void,
    (appointmentId: number) => void,
    (joinLink: string) => void,
    () => void,] {

    const [state, setState] = useState({
        appointments: null,
        appointmentsSummary: null,
        products: [],
        assignedEmployees: [],
        availableTypes: [],
        locations: null,
        timeZone: null,
        isLoading: true,
        isPhotosLoading: true,
        isBuyProductsLink: false,
        link: '',
        createAppointmentWithTypes: [],
        subscription: null,
    } as PatientAppointmentPageState);

    const handleAddAppointmentToCalendar = (appointmentId: number) => {
        appointmentsService.submitAsPatient(appointmentId);
    }

    const handleCancelAppointment = (appointmentId: number) => {
        appointmentsService.openCancelAppointmentSchedule()
            .subscribe(
                () => appointmentsService.cancelAsPatient(appointmentId)
            );
    }

    const handleJoinAppointment = (joinLink: string) => {
        window.open(joinLink, '_blank').focus();
    }

    const handleBuyProducts = () => {
        if (!state.isBuyProductsLink) {
            return
        }

        if (state.link) {
            return window.open(state.link, '_blank').focus();
        }

        const product = state.products.find(x => x.type === ProductType.PhysicianVisit)
        if (!patientId) {
            paymentService.getPatientBuyProducts(product?.id).subscribe((link) => {
                setState(state => ({
                    ...state,
                    link: link,
                }));

                return window.open(link, '_blank').focus();
            })
        } else {
            paymentService.getPatientBuyProductsOnBehalf(product?.id, patientId).subscribe((link) => {
                setState(state => ({
                    ...state,
                    link: link,
                }));

                return window.open(link, '_blank').focus();
            })
        }
    }

    const handleEditAppointment = (appointmentId: number) => {
        const appointment = state.appointments.find(x => x.id === appointmentId);
        if (!appointment) {
            return;
        }

        appointmentsService.openPatientAppointmentReschedule(appointment, patientId).subscribe();
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<PatientAppointmentModel[]>(appointmentsQuery.patientAppointments$, appointments => {
                setState(state => ({ ...state, appointments: appointments }));
            }),
            onEmit<EmployeeModel[]>(employeesQuery.patientEmployees$, employees => {
                setState(state => ({ ...state, assignedEmployees: employees }));
            }),
            onEmit<LocationModel[]>(locationsQuery.availableLocations$, locations => {
                setState(state => ({ ...state, locations: locations }));
            }),
            onEmit<AppointmentTypeModel[]>(appointmentsQuery.appointmentTypes$, types => {
                setState(state => ({ ...state, availableTypes: types }))
            }),
            onEmit<TimeZoneModel>(patientsQuery.patientTimeZone$, timeZone => {
                if (timeZone) {
                    setState(state => ({ ...state, timeZone: timeZone }));
                }
            }),
            onEmit<AppointmentsSummaryModel>(appointmentsQuery.appointmentsSummary$, appointmentsSummary => {
                    setState(state => ({ ...state, appointmentsSummary: appointmentsSummary }));
            }),
            onEmit<ProductModel[]>(productsQuery.products$, products => {
                if (products) {
                    setState(state => ({ ...state, products: products }));
                }
            }),
            onEmit<TimeZoneModel[]>(timezonesQuery.timezones$, timezones => {
                setState(state => ({
                    ...state,
                    timeZones: timezones,
                    timeZone: state.timeZone || getCurrentTimezone()
                }));
            }),
            onEmit<SubscriptionModel>(subscriptionQuery.subscription$, subscription=>{
                if(subscription){
                    setState(state => ({...state, subscription: subscription}));
                }
            }),
        ];
        //that means on behalf of patient...

        const cb = () => setState(state => ({ ...state, isLoading: false }));
        const photosCB = () => setState(state => ({ ...state, isPhotosLoading: false }));
        const getPhotos = (appointments: PatientAppointmentModel[]) => {
            if (appointments && appointments.length) {
                const employeeIds = appointments.map(x => x.employees).reduce((x1: number[], x2) => {
                    for (const item of x2)
                    {
                        if(!x1.includes(item.id)) {
                            x1.push(item.id)
                        }
                    }

                    return x1;
                }, [])
                profileService.getEmployeeProfilePhotos(employeeIds.join(',')).subscribe(photosCB, photosCB)
            } else {
                photosCB()
            }
        }

        if (Boolean(patientId)) {
            appointmentsService.getAppointmentsSummary(patientId)
            if (patientsQuery.getTargetPatientSubscription()) {
                subscriptionService.getPatientCurrent(patientId).subscribe()
            }
            patientsService.getPatientTimeZone(patientId);
            productsService.getProducts().subscribe((products) => {
                if (products && products.length) {
                    paymentService.isIntegratedWithPaymentSystem(patientId, IntegrationVendor.Stripe).subscribe((resp) => {
                        setState(state => ({
                            ...state,
                            isBuyProductsLink: resp,
                        }));
                    })
                }
            });
            employeeService.getAssignedToById(patientId).subscribe();
            appointmentsService.getPatientAppointmentsById(patientId, new Date()).subscribe((appointments) => {
                getPhotos(appointments)
                cb()
            }, cb);
        }
        else {
            appointmentsService.getAppointmentsSummary(authQuery.getPatientId())
            subscriptionService.getCurrent();
            patientsService.getPatientTimeZone();
            productsService.getProducts().subscribe((products) => {
                if (products && products.length) {
                    paymentService.isIntegratedWithPaymentSystem(authQuery.getPatientId(), IntegrationVendor.Stripe).subscribe((resp) => {
                        setState(state => ({
                            ...state,
                            isBuyProductsLink: resp,
                        }));   
                    })
                }
            });
            employeeService.getAssignedTo().subscribe();
            appointmentsService.getPatientAppointments(new Date()).subscribe((appointments) => {
                getPhotos(appointments)
                cb()
            }, cb);
        }

        appointmentsService.getAvailableAppointmentTypes(patientId);
        locationsService.getAvailable(authQuery.getCurrentPracticeId()).subscribe();
        timezonesService.getTimezones().subscribe();

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, []);

    return [
        state,
        handleAddAppointmentToCalendar,
        handleCancelAppointment,
        handleEditAppointment,
        handleJoinAppointment,
        handleBuyProducts,
    ];
}