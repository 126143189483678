import {AddressModel} from "./address.models";
import {StateModel} from "../../account/models/address.model";
import { UserOptionsModel } from "../../userOptions/models/userOptions.models";

/**
 * Represents Gender
 */
export enum Gender {
    Male = 1,
    Female = 2,
    None = 99
}

export interface ResetPasswordModel {
    newPassword: string;
    confirmPassword: string;
}

export interface SetPasswordModel extends ResetPasswordModel {
    practiceId: number;
    code: string;
}

/**
 * Represents User Model
 */
export interface User extends IPersonModel {
    gender: Gender;
    email: string;
    password: string;
    phoneNumber: string;
    state: StateModel;
    billingAddress: AddressModel;
    shippingAddress: AddressModel;
    userOptions: UserOptionsModel;
}

export interface IPersonModel {
    id: number;
    universalId: string;
    firstName: string;
    lastName: string;
    birthday?: Date;
}