import React from 'react';
import { ReactComponent as Fellowship } from '@img/icons/fellowship.svg';
import { ReactComponent as FellowshipActive } from '@img/icons/fellowshipActive.svg';
import { IconProps } from './IconProps';

const FellowshipIcon: React.FC<IconProps> = (props: IconProps) => {
    const { isSelected } = props;

    const icons = new Map<boolean, JSX.Element>([
        [true, <FellowshipActive />],
        [false, <Fellowship />]
    ]);

    return (
        icons.get(isSelected)
    )
}

export default FellowshipIcon;