import React, {FC, Fragment} from 'react';
import { Box, Collapse, ListItem, ListItemText, TextField, FormControl, FormHelperText, Select, MenuItem, Breadcrumbs, Checkbox } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { WildHealthButton } from "../../../../common/components/wildHealthButton/WildHealthButton";
import { useStyles } from "../manageApiResponseComponent.styles";
import commonUseStyles from "../../../../common/styles/common.styles";
import { PaymentStrategy } from '../../../../payment/models/paymentPrice.models';
import { PaymentPlanShortModel, PaymentCouponShortModel, ToolEnum } from '../manageApiResponseComponent.hooks';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import clsx from 'clsx';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from "moment";
import DateFnsUtils from '@date-io/moment';
import { ReactComponent as CalendarIcon } from '@img/icons/CalendarIcon.svg';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { colors } from '../../../../common/constants/colors';

type EndpointAdminToolProps = {
    id: string;
    params: any;
    handleChanges: (field: string, value: any, tool: ToolEnum) => void;
    handleOpenToggle: () => void;
    handleChangeSubscriptionCandidate: (any) => void;
    handlePaymentPlanRecommend: (any) => void;
    handlePaymentPlanMigration: (any) => void;
    title: string;
    errors: any;
    isOpen: boolean;
    isLoading: boolean;
    eligiblePaymentPlans: PaymentPlanShortModel[];
    eligiblePaymentCoupons: PaymentCouponShortModel[];
}


const ChangeSubscriptionComponent: FC<EndpointAdminToolProps> = (props): JSX.Element => {
    const {
        id, isLoading, title, errors, params, isOpen, eligiblePaymentPlans, eligiblePaymentCoupons,
        handleOpenToggle,
        handleChanges,
        handleChangeSubscriptionCandidate,
        handlePaymentPlanRecommend,
        handlePaymentPlanMigration
    } = props;

    const classes = useStyles();
    const commonClasses = commonUseStyles();

    const  capitalize=(str)=> {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const getParamTitle = (paramName) => {
        if(paramName.includes('Id')){
            return paramName.split('Id').map(capitalize).join(" ")+' Id';
        }
    }

    const getDate = (date) => {
        return date
            ? moment(date).format('YYYY-MM-DD')
            : null
    }

    const renderPlanName = (planName) => {
        if (eligiblePaymentPlans && eligiblePaymentPlans.length) {
            return eligiblePaymentPlans.find(plan => plan.name === params.planName)?.displayName ?? planName;
        }
        return planName; 
    }

    const handleChangeDate = (momentDate) => {
        handleChanges(`startDate`, momentDate === null ?
            "" : momentDate.format("YYYY-MM-DD"), ToolEnum.PAYMENT_PLANS_RECOMMEND)
        handleChanges(`endDate`, momentDate === null ?
            "" : momentDate.add(1, 'year').format("YYYY-MM-DD"), ToolEnum.PAYMENT_PLANS_RECOMMEND)
    }

    const controlIcon = <CheckBoxOutlineBlankIcon style={{ fill: colors.mediumGray }} />;
    const controlCheckedIcon = <CheckBoxIcon style={{ fill: colors.main }} />

    const [step, setStep] = React.useState(ToolEnum.CHANGE_SUBSCRIPTION_CANDIDATE);

    const renderSubscriptionCandidate = (params: any) => (
        <Box p={2}>
            {
                Object.keys(params).map((param, index) => {
                    return (
                    <Box pb={2} key={index}>
                        <FormControl
                            variant="outlined"
                            color='primary'
                            required
                            error={!!errors[param]}
                            size="small"
                            disabled={!isOpen}
                            classes={{root: classes.root}}
                            fullWidth>
                            <Box className={commonClasses.label} mb={1}>{getParamTitle(param)} *</Box>
                            <TextField
                                fullWidth
                                size="small"
                                required
                                InputProps={{className: 'input', classes}}
                                id={param}
                                type={typeof params[param]}
                                placeholder={getParamTitle(param)}
                                value={params[param]}
                                variant="outlined"
                                disabled={!isOpen}
                                error={!!errors[param]}
                                helperText={errors[param]}
                                onChange={(v) => handleChanges(v.target.id, v.target.value, ToolEnum.CHANGE_SUBSCRIPTION_CANDIDATE)}
                            />
                        </FormControl>
                    </Box>)
                })
            }
            <WildHealthButton
                fullWidth
                id="change-subscription-candidate-button"
                loading={isLoading}
                onClick={handleChangeSubscriptionCandidate}
            >
                Execute
            </WildHealthButton>
        </Box>
    )

    const renderPaymentPlanRecommend = (params: any, toolMenu: ToolEnum) => (
        <Box p={2}>
            <Box>
                <Box className={commonClasses.label} mb={1}>Subscription Id</Box>
                <TextField
                    fullWidth
                    size="small"
                    InputProps={{className: 'input', classes}}
                    id="subscriptionId"
                    placeholder="Subscription Id"
                    value={params.fromSubscriptionId}
                    variant="outlined"
                    disabled={true}
                />
            </Box>
            <Box my={2}>
                <FormControl
                    variant="outlined"
                    color='primary'
                    required
                    error={!!errors[`startDate`]}
                    size="small"
                    disabled={!isOpen}
                    classes={{ root: classes.root }}
                    fullWidth>
                    <Box className={commonClasses.label} mb={1}>StartDate *</Box>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            id={`startDate`}
                            fullWidth
                            size="small"
                            required
                            placeholder="MM/DD/YYYY"
                            format="MM/DD/YYYY"
                            openTo="year"
                            value={getDate(params.startDate)}
                            helperText={null}
                            error={!!errors[`startDate`]}
                            inputVariant="outlined"
                            disabled={!isOpen}
                            views={["year", "month", "date"]}
                            onChange={(momentDate) => handleChangeDate(momentDate)}
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                                "edge": "end",
                            }}
                            keyboardIcon={<CalendarIcon fill={colors.gray500}/>}
                            minDate={new Date()}
                        />
                        <FormHelperText error>{errors[`startDate`]}</FormHelperText>
                    </MuiPickersUtilsProvider>
                </FormControl>
            </Box>
            <Box my={2}>
                <FormControl
                    variant="outlined"
                    color='primary'
                    required
                    error={!!errors[`endDate`]}
                    size="small"
                    disabled
                    classes={{ root: classes.root }}
                    fullWidth>
                    <Box className={commonClasses.label} mb={1}>EndDate *</Box>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            id={`endDate`}
                            fullWidth
                            size="small"
                            required
                            placeholder="MM/DD/YYYY"
                            format="MM/DD/YYYY"
                            openTo="year"
                            value={getDate(params.endDate)}
                            helperText={null}
                            error={!!errors[`endDate`]}
                            inputVariant="outlined"
                            disabled
                            views={["year", "month", "date"]}
                            onChange={(momentDate) => handleChanges(`endDate`, momentDate === null ?
                                "" : momentDate.format("YYYY-MM-DD"), ToolEnum.PAYMENT_PLANS_RECOMMEND)}
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                                "edge": "end",
                            }}
                            keyboardIcon={<CalendarIcon fill={colors.gray500}/>}
                            minDate={new Date(params.startDate)}
                        />
                        <FormHelperText error>{errors[`startDate`]}</FormHelperText>
                    </MuiPickersUtilsProvider>
                </FormControl>
            </Box>
            <Box my={2}>
                <FormControl
                    variant="outlined"
                    color='primary'
                    required
                    error={!!errors[`planName`]}
                    size="small"
                    disabled={!isOpen}
                    classes={{ root: classes.root }}
                    fullWidth>
                    <Box className={commonClasses.label} mb={1}>Plan Name *</Box>
                    <Select
                        style={{ background: '#FFF' }}
                        classes={{ root: classes.root }}
                        value={params.planName}
                        error={!!errors[`planName`]}
                        inputProps={{
                        name: 'Plan Name',
                        id: 'plan-name-label',
                        classes,
                        }}
                        renderValue={() => {
                            if (params.planName === null || !params.planName) {
                                return <Box className={commonClasses.colorMediumGray}>Select</Box>;
                            }

                            return renderPlanName(params.planName);
                        }}
                        displayEmpty
                        onChange={(v) => handleChanges("planName", v.target.value, ToolEnum.PAYMENT_PLANS_RECOMMEND)}
                    >
                        {eligiblePaymentPlans.map(plan => (
                            <MenuItem value={plan.name} key={plan.name}>{plan.displayName}</MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{errors[`planName`]}</FormHelperText>
                </FormControl>
            </Box>
            <Box my={2}>
                <FormControl
                    variant="outlined"
                    color='primary'
                    required
                    error={!!errors[`paymentStrategy`]}
                    size="small"
                    disabled={!isOpen}
                    classes={{ root: classes.root }}
                    fullWidth>
                    <Box className={commonClasses.label} mb={1}>Payment Strategy *</Box>
                    <Select
                        style={{ background: '#FFF' }}
                        classes={{ root: classes.root }}
                        value={params.paymentStrategy}
                        error={!!errors[`paymentStrategy`]}
                        inputProps={{
                        name: 'Payment Strategy',
                        id: 'payment-strategy-label',
                        classes,
                        }}
                        renderValue={() => {
                            if (params.paymentStrategy === null || !params.paymentStrategy) {
                                return <Box className={commonClasses.colorMediumGray}>Select</Box>;
                            }

                            return params.paymentStrategy === PaymentStrategy.FullPayment ? "Yearly" : "Monthly";
                        }}
                        displayEmpty
                        onChange={(v) => handleChanges("paymentStrategy", v.target.value, ToolEnum.PAYMENT_PLANS_RECOMMEND)}
                    >
                        <MenuItem value={PaymentStrategy.FullPayment}>Yearly</MenuItem>
                        <MenuItem value={PaymentStrategy.PartialPayment}>Monthly</MenuItem>
                    </Select>
                    <FormHelperText>{errors[`paymentStrategy`]}</FormHelperText>
                </FormControl>
            </Box>
            <Box my={2}>
                <FormControl
                    variant="outlined"
                    color='primary'
                    error={!!errors[`couponCode`]}
                    size="small"
                    disabled={!isOpen}
                    classes={{ root: classes.root }}
                    fullWidth>
                    <Box className={commonClasses.label} mb={1}>CouponCode *</Box>
                    <Select
                        style={{ background: '#FFF' }}
                        classes={{ root: classes.root }}
                        value={params.couponCode}
                        error={!!errors[`couponCode`]}
                        inputProps={{
                        name: 'Coupon Code',
                        id: 'coupon-code-label',
                        classes,
                        }}
                        renderValue={() => {
                            if (params.couponCode === null || !params.couponCode) {
                                return <Box className={commonClasses.colorMediumGray}>Select</Box>;
                            }

                            return params.couponCode;
                        }}
                        displayEmpty
                        onChange={(v) => handleChanges("couponCode", v.target.value, ToolEnum.PAYMENT_PLANS_RECOMMEND)}
                    >
                        <MenuItem value={''}>None</MenuItem>
                        {eligiblePaymentCoupons.map(couponCode => (
                            <MenuItem value={couponCode.code} key={couponCode.code}>{couponCode?.code}</MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{errors[`couponCode`]}</FormHelperText>
                </FormControl>
            </Box>
            <Box my={2} display="flex" alignItems="center">
                <Box className={commonClasses.label} mr={3}>Has insurance?</Box>
                <Box display="flex" alignItems="center">
                    <Checkbox
                        icon={controlIcon}
                        color="primary"
                        checkedIcon={controlCheckedIcon}
                        checked={params.isInsurance}
                        onChange={() => handleChanges("isInsurance", true, ToolEnum.PAYMENT_PLANS_RECOMMEND)}
                    />
                    <Box mr={2}>Yes</Box>
                    <Checkbox
                        icon={controlIcon}
                        color="primary"
                        checkedIcon={controlCheckedIcon}
                        checked={!params.isInsurance}
                        onChange={() => handleChanges("isInsurance", false, ToolEnum.PAYMENT_PLANS_RECOMMEND)}
                    />
                    <Box>No</Box>
                </Box>
            </Box>
            <WildHealthButton
                fullWidth
                id="payment-plan-execute-confirm-button"
                loading={isLoading}
                onClick={toolMenu === ToolEnum.PAYMENT_PLANS_RECOMMEND ? handlePaymentPlanRecommend : handlePaymentPlanMigration}
            >
                {toolMenu === ToolEnum.PAYMENT_PLANS_RECOMMEND ? 'Execute' : 'Confirm Change'}
            </WildHealthButton>
        </Box>
    )

    return (
        <Fragment>
            <ListItem id={id} className={classes.divider} button onClick={handleOpenToggle}>
                <ListItemText primary={<span className="text-medium">{title}</span>}/>
                {isOpen ? <ExpandLess className={classes.activeIcon}/> : <ExpandMore/>}
            </ListItem>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <Box p={2}>
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                        <Box className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorMain, commonClasses.pointer)} onClick={() => setStep(ToolEnum.CHANGE_SUBSCRIPTION_CANDIDATE)}>
                            Step 1
                        </Box>
                        <Box className={step === ToolEnum.CHANGE_SUBSCRIPTION_CANDIDATE ? clsx(commonClasses.label, commonClasses.pointer) : clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorMain, commonClasses.pointer)} onClick={() => params[ToolEnum.PAYMENT_PLANS_RECOMMEND]?.fromSubscriptionId && setStep(ToolEnum.PAYMENT_PLANS_RECOMMEND)}>
                            Step 2
                        </Box>
                        <Box className={step === ToolEnum.PAYMENT_PLANS_MIGRATE ? clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorMain, commonClasses.pointer) : clsx(commonClasses.label, commonClasses.pointer)} onClick={() => params[ToolEnum.PAYMENT_PLANS_RECOMMEND]?.fromSubscriptionId && setStep(ToolEnum.PAYMENT_PLANS_MIGRATE)}>
                            Step 3
                        </Box>
                    </Breadcrumbs>
                </Box>
                {step === ToolEnum.CHANGE_SUBSCRIPTION_CANDIDATE && renderSubscriptionCandidate(params[ToolEnum.CHANGE_SUBSCRIPTION_CANDIDATE])}
                {step === ToolEnum.PAYMENT_PLANS_RECOMMEND && renderPaymentPlanRecommend(params[ToolEnum.PAYMENT_PLANS_RECOMMEND], ToolEnum.PAYMENT_PLANS_RECOMMEND)}
                {step === ToolEnum.PAYMENT_PLANS_MIGRATE && renderPaymentPlanRecommend(params[ToolEnum.PAYMENT_PLANS_RECOMMEND], ToolEnum.PAYMENT_PLANS_MIGRATE)}
            </Collapse>
        </Fragment>
    )

}

export default ChangeSubscriptionComponent;