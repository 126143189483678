import {useState} from "react";
import {PatientModel} from "../../../patients/models/patient.model";
import {conversationsService} from "../../services/conversations.service";

interface SmsReminderToggleComponentState {
    isActive: boolean;
}

export function useFacade(patient: PatientModel): [
    SmsReminderToggleComponentState,
    (isActive: boolean) => void
] {
    const [state, setState] = useState({
        isActive: patient?.settings?.find(x => x.key === "UnreadMessagesSmsReminder")?.value === "True"
    } as SmsReminderToggleComponentState);

    const handleToggle = (isActive: boolean) => {
        conversationsService.togglePatientSmsReminders(patient.id, isActive)
            .subscribe(() => {
                setState(state => ({
                    ...state,
                    isActive: isActive
                }))
            });
    }

    return [
        state,
        handleToggle
    ]
}