import React from "react";
import { AreaChart, Area, ResponsiveContainer, CartesianGrid, XAxis, YAxis } from "recharts";
import { colors } from "../../../common/constants/colors";
import { useTheme, useMediaQuery } from "@material-ui/core";

interface BiometricGraphModel {
  name: number,
  date: string,
}

interface BiometricInputsGraphComponentProps {
  data: BiometricGraphModel[];
  renderRange?: boolean;
  minValue?: number;
  maxValue?: number;
  height: number;
  width: number;
}

export const BiometricInputsGraphComponent: React.FC<BiometricInputsGraphComponentProps> = (
  props: BiometricInputsGraphComponentProps
) => {
  const { data, renderRange, minValue, maxValue, height, width } = props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const graphData: object[] = data.map((item) => {
    return {
      min: 1,
      max: 200,
      name: item.name,
      date: item.date,
    };
  });

  const CheckIsNormal = (value: number): boolean => {
    if (minValue !== null && value <= minValue) {
      return false;
    }

    return !(maxValue !== null && value >= maxValue);
  };

  const CustomizedDot = (props) => {
    const { cx, cy, payload } = props;

    if (CheckIsNormal(payload.name)) {
      return (
        <circle
          key={`${cx}-${payload.name}`}
          cx={cx}
          cy={cy}
          r={3}
          stroke={colors.main}
          strokeWidth={3.5}
          fill={colors.main}
        />
      );
    }

    return (
      <circle
        cx={cx}
        cy={cy}
        r={3}
        stroke={colors.bad}
        strokeWidth={3.5}
        fill={colors.bad}
      />
    );
  };

  return (
    <ResponsiveContainer
      width={isSmallScreen ? "100%" : width}
      height={isSmallScreen ? 20 : height}
    >
      <AreaChart
        data={graphData}
        margin={{
          top: 5,
          right: 5,
          left: 5,
          bottom: 5,
        }}
      >
        <CartesianGrid stroke={colors.separators} fillOpacity={1} />
        <XAxis dataKey="date" stroke={colors.gray1} />
        <YAxis dataKey="name" stroke={colors.gray1} />
        {renderRange && minValue !== null && (
          <Area
            key='min-value'
            isAnimationActive={false}
            type='step'
            dataKey='min'
            stroke={colors.main}
            strokeWidth={1}
            fill={colors.main}
          />
        )}
        {renderRange && maxValue !== null && (
          <Area
            key='max-value'
            isAnimationActive={false}
            type='step'
            dataKey='max'
            stroke={colors.main}
            strokeWidth={1}
            fill={colors.main}
          />
        )}
        <Area
          key='value'
          isAnimationActive={false}
          type='linear'
          dataKey='name'
          stroke={colors.main}
          strokeWidth={2}
          fill={`${colors.main}20`}
          dot={CustomizedDot}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
