import Axios from 'axios-observable';
import {Observable} from 'rxjs';
import {authHeader} from '../../common/helpers/auth-header';
import {snackService} from '../../common/snack/state';
import {SupplementItem} from '../models/notes.models';
import {NotesStore, notesStore} from '../stores/notes';

/**
 * Provides method for working with Common supplements
 */
export class CommonSupplementsService {
    private url = `${process.env.REACT_APP_API_URL}Supplements`;

    constructor(private store: NotesStore) {
    }

    private getConfig = () => ({headers: authHeader()});

    public getAllCommonSupplements(): Observable<SupplementItem[]> {
        const url = `${this.url}/Common`;

        return new Observable((observer) => {
            Axios.get<SupplementItem[]>(url, this.getConfig())
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({commonSupplements: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getCommonSupplementById(id: number): Observable<SupplementItem> {
        const url = `${this.url}/Common/${id}`;
    
        return new Observable(observer => {
            Axios.get<SupplementItem>(url, this.getConfig())
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.error();
                    }
                );
        })
    }

    public createCommonSupplement(model: SupplementItem): Observable<SupplementItem> {
        const url = `${this.url}/Common`;
    
        return new Observable(observer => {
            Axios.post<SupplementItem>(url, model, this.getConfig())
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.addCommonSupplement(response.data);
                        snackService.success("Common Supplement successfully created!")
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.error();
                    }
                );
        })
    }

    public updateCommonSupplement(model: SupplementItem): Observable<SupplementItem> {
        const url = `${this.url}/Common`;
    
        return new Observable(observer => {
            Axios.put<SupplementItem>(url, model, this.getConfig())
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.updateCommonSupplement(response.data);
                        snackService.success("Common Supplement successfully updated!")
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error)
                        observer.error();
                    }
                );
        })
    }

    public deleteCommonSupplement(id: number) {
        const config = {
            headers: authHeader()
        };

        return new Observable((observer) =>
            Axios.delete(`${this.url}/Common/${id}`, config)
                .pipe()
                .subscribe(
                    () => {
                        this.store.removeCommonSupplement(id);
                        snackService.success('Common Supplement successfully deleted');
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                )
        );
    }
}

export const commonSupplementsService = new CommonSupplementsService(notesStore);
