import { useEffect, useState } from 'react';
import { Subscription } from 'rxjs';
import { onEmit } from '../../../common/helpers/on-emit';
import { ordersQuery } from '../../stores/ordersStore/orders.query';
import { ordersStore } from '../../stores/ordersStore/orders.store';
import { OrderTabs } from '../../models/orders.models';

interface PatientOrdersPageState {
    selectedTab: number;
}

export function useFacade(): [
    PatientOrdersPageState,
    (tab: number) => void,
] {
    const [state, setState] = useState({
        selectedTab: 0,
    } as PatientOrdersPageState);

    const handleChangeTab = (tab: OrderTabs) => {
        ordersStore.setTab(tab);
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<number>(ordersQuery.tab$, tab =>
                setState(state => ({...state, selectedTab: tab}))
            )
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [
        state,
        handleChangeTab
    ];
}