import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import {
    BannerModel,
    HealthFormModel,
    QuestionnaireResultModel,
    QuestionnaireShortModel,
    QuestionnaireType
} from "../../models/questionnaire.models";
import { onEmit } from "../../../common/helpers/on-emit";
import { navigationService } from "../../../../services/navigation.service";
import { useHistory } from "react-router";
import { questionnaireService } from "../../services/questionnaire.service";
import { questionnaireQuery } from "../../stores/questionnaireStore";
import { confirmService } from "../../../../services/confirm.service";
import Welcome from "@img/icons/welcome.svg";

interface QuestionnairePageState {
    isDialogOpened: boolean;
    followingQuestionnaires: QuestionnaireResultModel[];
    availableQuestionnaires: QuestionnaireShortModel[];
    completedQuestionnaires: QuestionnaireResultModel[];
    healthForms: HealthFormModel[];
    banner: BannerModel;

    isAvailableLoading: boolean;
    isResultsLoading: boolean;
    isHealthFormsLoading: boolean;
}

export function useFacade(): [
    QuestionnairePageState,
    (questionnaireResultId: number) => void,
    (questionnaireResultId: number) => void,
    (questionnaireId: number, isDialogOpen: boolean) => void,
    () => void,
    () => void
] {
    const [state, setState] = useState({
        isDialogOpened: false,
        followingQuestionnaires: [],
        availableQuestionnaires: [],
        completedQuestionnaires: [],
        healthForms: [],
        banner: null,
        isAvailableLoading: true,
        isResultsLoading: true,
        isHealthFormsLoading: true,
    } as QuestionnairePageState);

    const history = useHistory();

    const handleGoToQuestionnaire = (questionnaireResultId: number) => {
        navigationService.toMyQuestionnaire(history, questionnaireResultId);
    }

    const handleGoToBannerQuestionnaire = () => {
        const healthForm = state.healthForms.find(x => x.subType === state.banner.type);

        if (healthForm) {
            if (healthForm.resultId) {
                handleGoToQuestionnaire(healthForm.resultId);
            }
            else {
                handleStartQuestionnaire(healthForm.id)
            }
        }
    }

    const handleFinishQuestionnaire = (questionnaireResultId: number) => {
        navigationService.toMyQuestionnaire(history, questionnaireResultId);
    }

    const handleStartQuestionnaire = (questionnaireId: number, isDialogOpen = false) => {
        setState({
            ...state,
            isAvailableLoading: true,
            isResultsLoading: true,
            isHealthFormsLoading: true,
        });

        const questionnaire = state.availableQuestionnaires.find(x => x.id === questionnaireId);

        questionnaireService.startById(questionnaireId, questionnaire?.appointmentId).subscribe((result) => {
            if (isDialogOpen) {
                confirmService.confirmInfo(
                    `Before we start`,
                    "Please note that not all questions may be relevant to your specific health situation. If you can’t answer a specific question or if it’s not applicable, feel free to put in 0 or N/A. We'll discuss it during your initial visit and can adjust and personalize your responses before providing you with your full health report.",
                    Welcome,
                    'Sounds good')
                    .subscribe();
            }
            navigationService.toMyQuestionnaire(history, result.id);
        });
    }

    const handleCloseDialog = () => {
        setState({
            ...state,
            isDialogOpened: false,
        });
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<QuestionnaireResultModel[]>(questionnaireQuery.questionnaireResults$, results => {
                setState(state => ({
                    ...state,
                    followingQuestionnaires: results.filter(q => !q.submittedAt && q.questionnaire.type !== QuestionnaireType.HealthForms),
                    completedQuestionnaires: results.filter(q => q.submittedAt),
                }));
            }),
            onEmit<QuestionnaireShortModel[]>(questionnaireQuery.availableQuestionnaires$, questionnaires => {
                setState(state => ({
                    ...state,
                    availableQuestionnaires: questionnaires.filter(x => x.type !== QuestionnaireType.HealthForms),
                }));
            }),
            onEmit<HealthFormModel[]>(questionnaireQuery.healthForms$, healthForms => {
                setState(state => ({ ...state, healthForms: healthForms }));
            }),
            onEmit<BannerModel>(questionnaireQuery.questionnaireBanner$, banner => {
                setState(state => ({ ...state, banner: banner }));
            }),
        ];

        questionnaireService.getHealthForms().subscribe(() => {
            setState(state => ({ ...state, isHealthFormsLoading: false }));
        });

        questionnaireService.getAllMyResults().subscribe((results) => {
            setState(state => ({
                ...state,
                isResultsLoading: false,
                isDialogOpened: !results.length
            }));
        });

        questionnaireService.getMyAvailable().subscribe(() => {
            setState(state => ({ ...state, isAvailableLoading: false }));
        });

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }

    useEffect(useEffectCB, []);
    
    return [state, handleGoToQuestionnaire, handleFinishQuestionnaire, handleStartQuestionnaire, handleCloseDialog, handleGoToBannerQuestionnaire];
}