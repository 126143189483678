import { Box } from '@material-ui/core';
import React from 'react';
import commonUseStyles from '../../../common/styles/common.styles';
import {AppointmentConfigurationModel, NoteModel} from "../../models/notes.models";
import { CreateNote } from '../createNote/CreateNote';
import { useFacade } from './createFollowUpComponent.hooks';
import { useStyles } from './createFollowUpComponent.styles';

export interface CreateFollowUpComponentProps {
    note?: NoteModel,
    patientId: number;
    appointmentId?: number | null;
    appointmentConfiguration: AppointmentConfigurationModel | null;
    handleGoBack: (boolean?) => void;
    goBackTitle: string;
    originalNote?: NoteModel,
}

export const CreateFollowUpComponent: React.FC<CreateFollowUpComponentProps> = (props: CreateFollowUpComponentProps) => {
    const {
        note,
        patientId,
        appointmentConfiguration,
        appointmentId = null,
        handleGoBack,
        goBackTitle,
        originalNote
    } = props;

    const classes = useStyles();
    const commonClasses = commonUseStyles();
    
    const [
        {
            isLoading,
            isCommonMdmLoading,
            isCommonGoalLoading,
            isCommonSupplementLoading,
        },
        noteTitle,
        todayCoaching,
        goals,
        supplements,
        nextSteps,
        endOfTemplate,
        nextAppointments,
        noteName,
        titleTextBox,
    ] = useFacade(patientId, note, classes, commonClasses, handleGoBack, appointmentId, appointmentConfiguration, originalNote);

    return (
        <Box m={5} mt={0} pt={3}>
            <CreateNote
                isLoading={isLoading || isCommonMdmLoading || isCommonGoalLoading || isCommonSupplementLoading}
                noteName={noteName}
                handleGoBack={handleGoBack}
                goBackTitle={goBackTitle}
                titleTextBox={titleTextBox}
            >
                <Box>
                    <Box mt={5}>
                        {noteTitle}
                    </Box>
                    <Box mt={7}>
                        {goals}
                    </Box>
                    <Box mt={7}>
                        {supplements}
                    </Box>
                    <Box mt={7}>
                        {todayCoaching}
                    </Box>
                    <Box mt={7}>
                        {nextSteps}
                    </Box>
                    <Box mt={7}>
                        {nextAppointments}
                    </Box>
                    <Box mt={7}>
                        {endOfTemplate}
                    </Box>
                </Box>
            </CreateNote>
        </Box>
    );
}
