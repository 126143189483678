import {GoalCategory} from "../../../../notes/models/notes.models";
import { ReactComponent as Diet } from '@img/icons/Diet.svg';
import { ReactComponent as Exercise } from '@img/icons/Exercise.svg';
import { ReactComponent as Mindfulness } from '@img/icons/Mindfulness.svg';
import { ReactComponent as Longevity } from '@img/icons/Longevity.svg';
import { ReactComponent as Neuro } from '@img/icons/Neuro.svg';
import { ReactComponent as Sleep } from '@img/icons/Sleep.svg';
import { ReactComponent as Other } from '@img/icons/OtherGoal.svg';
import React from "react";

export const healthGoalIcons = new Map<GoalCategory, any>()
    .set(GoalCategory.Diet, <Diet style={{width: 18, height: 18}}/>)
    .set(GoalCategory.Exercise, <Exercise style={{width: 18, height: 18}}/>)
    .set(GoalCategory.Mindfulness, <Mindfulness/>)
    .set(GoalCategory.Longevity, <Longevity/>)
    .set(GoalCategory.Neuro, <Neuro/>)
    .set(GoalCategory.Sleep, <Sleep/>)
    .set(GoalCategory.Other, <Other/>);