import { EmployeeShortModel } from "../../employee/models/employee.models";

export interface MessageGroupModel {
    authorFirstName: string;
    authorLastName: string;
    employeeId?: number;
    employeeType?: string;
    isMyMessage: boolean;
    messages: any[];
}

export interface AttachFileModel {
    file: File;
    url: string;
}

export interface AttachedFileModel {
    file: any;
    url: string;
    fileName?: string;
}

export interface ScheduledMessageModel {
    id: number;
    message: string;
    timeToSend: Date;
    conversationId: number;
    participantId: number;
    uploadedAttachments: UploadedAttachmentModel[];
}

export interface ScheduleMessageModel {
    message: string;
    timeToSend: Date;
    conversationId: number;
    participantId: number;
    attachments: AttachFileModel[];
    timeToSendStr: string;
    timeZoneName: string;
}

export interface RescheduleMessageModel extends ScheduleMessageModel {
    id: number;
    removeAttachments: number[];
    uploadedAttachments: UploadedAttachmentModel[];
}

export interface MessageSettingsModel {
    awayMessageEnabled: boolean;
    awayMessageEnabledFrom: Date;
    awayMessageEnabledTo: Date;
    awayMessageTemplateId: number;
    forwardEmployeeEnabled: boolean;
    forwardEmployeeId: number;
    message: string;
}

export interface UpdateConversationsSettingsModel {
    awayMessageEnabled: boolean;
    awayMessageEnabledFrom: Date;
    awayMessageEnabledTo: Date;
    awayMessageTemplateId: number;
    forwardEmployeeEnabled: boolean;
    forwardEmployeeId: number;
}

export interface ScheduledMessageAttachFileModel {
    id: number;
    file: File;
}

export interface UploadedAttachmentModel {
    id: number;
    name: string;
    path: string;
}

export interface MessageTypeDeliveryModel {
    id: number;
    name: string;
}

export enum MessageType {
    Email = 1,
    Sms = 2,
    ClarityMessage = 3,
    MobileMessage = 4
}

export const nameMessageType = new Map<MessageType, string>([
    [MessageType.Email, 'Transactional Email'],
    [MessageType.Sms, 'SMS Text'],
    [MessageType.ClarityMessage, 'Clarity Message'],
    [MessageType.MobileMessage, 'Mobile App Notification']
])
export interface CreateMessageModel {
    subject: string;
    body: string;
    types: number[];
    patientsFilterModel: any;
}

export enum AudienceType {
    All = 1,
    LastMessageSent10DaysAgo = 10,
    LastMessageSent20DaysAgo = 11,
    LastMessageSent30DaysAgo = 12,
    LastMessageSent60DaysAgo = 13,
    LastUnansweredMessage10DaysAgo = 20,
    LastUnansweredMessage20DaysAgo = 21,
    LastUnansweredMessage30DaysAgo = 22,
    LastUnansweredMessage60DaysAgo = 23,
    LastCoachVisit10DaysAgo = 30,
    LastCoachVisit20DaysAgo = 31,
    LastCoachVisit30DaysAgo = 32,
    LastCoachVisit60DaysAgo = 33,
    LastAppointment10DaysAgo = 40,
    LastAppointment20DaysAgo = 41,
    LastAppointment30DaysAgo = 42,
    LastAppointment60DaysAgo = 43,
    PlanRenewalIn30Days = 50,
    PlanRenewalIn60Days = 51,
    SinceIccWithoutImc30Days = 60,
    SinceIccWithoutImc45Days = 61,
    SinceIccWithoutImc60Days = 62,
    SinceSignUpWithoutIcc5Days = 70,
    SinceSignUpWithoutIcc10Days = 71,
    SinceSignUpWithoutIcc20Days = 72,
    SinceSignUpWithoutIcc30Days = 73,
    IccDue = 80,
    ImcDue = 81,
    Fellowship = 82,
    InsuranceVerified = 83,
    InsurancePending = 84,
    InsuranceNotVerified = 85,
    PrecisionCarePackagePlan = 100,
    WildHealthLightPlan = 101,
    PrecisionCareHealthCoachingPlan = 102,
    SinglePlan = 103,
    AdvancedPlan = 104,
    OptimizationPlan = 105,
    CorePlan = 106,
    PrecisionCarePlan = 107
}

export const audienceTypeNames = new Map<AudienceType, string>([
    [AudienceType.All, 'All'],
    [AudienceType.LastMessageSent10DaysAgo, 'Patients who have not sent message > 10 days'],
    [AudienceType.LastMessageSent20DaysAgo, 'Patients who have not sent message > 20 days'],
    [AudienceType.LastMessageSent30DaysAgo, 'Patients who have not sent message > 30 days'],
    [AudienceType.LastMessageSent60DaysAgo, 'Patients who have not sent message > 60 days'],
    [AudienceType.LastUnansweredMessage10DaysAgo, 'Patients who have unanswered message > 10 days'],
    [AudienceType.LastUnansweredMessage20DaysAgo, 'Patients who have unanswered message > 20 days'],
    [AudienceType.LastUnansweredMessage30DaysAgo, 'Patients who have unanswered message > 30 days'],
    [AudienceType.LastUnansweredMessage60DaysAgo, 'Patients who have unanswered message > 60 days'],
    [AudienceType.LastCoachVisit10DaysAgo, 'Patients who have not had a visit with coach > 10 days'],
    [AudienceType.LastCoachVisit20DaysAgo, 'Patients who have not had a visit with coach > 20 days'],
    [AudienceType.LastCoachVisit30DaysAgo, 'Patients who have not had a visit with coach > 30 days'],
    [AudienceType.LastCoachVisit60DaysAgo, 'Patients who have not had a visit with coach > 60 days'],
    [AudienceType.LastAppointment10DaysAgo, 'Patients who have not had a appointment > 10 days'],
    [AudienceType.LastAppointment20DaysAgo, 'Patients who have not had a appointment > 20 days'],
    [AudienceType.LastAppointment30DaysAgo, 'Patients who have not had a appointment > 30 days'],
    [AudienceType.LastAppointment60DaysAgo, 'Patients who have not had a appointment > 60 days'],
    [AudienceType.PlanRenewalIn30Days, 'Patients who have plan renewal > 30 days'],
    [AudienceType.PlanRenewalIn60Days, 'Patients who have plan renewal > 60 days'],
    [AudienceType.SinceIccWithoutImc30Days, 'Patients who have since Icc without Imc > 30 days'],
    [AudienceType.SinceIccWithoutImc45Days, 'Patients who have since Icc without Imc > 45 days'],
    [AudienceType.SinceIccWithoutImc60Days, 'Patients who have since Icc without Imc > 60 days'],
    [AudienceType.SinceSignUpWithoutIcc5Days, 'Patients who have since Sign Up without Icc > 5 days'],
    [AudienceType.SinceSignUpWithoutIcc10Days, 'Patients who have since Sign Up without Icc > 10 days'],
    [AudienceType.SinceSignUpWithoutIcc20Days, 'Patients who have since Sign Up without Icc > 20 days'],
    [AudienceType.SinceSignUpWithoutIcc30Days, 'Patients who have since Sign Up without Icc > 30 days'],
    [AudienceType.IccDue, 'Patients who have Icc Due'],
    [AudienceType.ImcDue, 'Patients who have Imc Due'],
    [AudienceType.Fellowship, 'Patients who have fellowship'],
    [AudienceType.InsuranceVerified, 'Patients who have Insurance verified'],
    [AudienceType.InsurancePending, 'Patients who have Insurance pending'],
    [AudienceType.InsuranceNotVerified, 'Patients who have Insurance not verified'],
    [AudienceType.PrecisionCarePackagePlan, 'Patients with a Precision Care Package Plan'],
    [AudienceType.WildHealthLightPlan, 'Patients with a Wild Health Light Plan'],
    [AudienceType.PrecisionCareHealthCoachingPlan, 'Patients with a Precision Care Health Coaching Plan'],
    [AudienceType.SinglePlan, 'Patients with a Single Plan'],
    [AudienceType.AdvancedPlan, 'Patients with a Advanced Plan'],
    [AudienceType.OptimizationPlan, 'Patients with a Optimization Plan'],
    [AudienceType.CorePlan, 'Patients with a Core Plan'],
    [AudienceType.PrecisionCarePlan, 'Patients with a Precision Care Plan'],
]);

export enum MessageStatus {
    Pending = 1,
    Delivered = 10,
    Failed = 20
}

export const nameMessageStatus = new Map<MessageStatus, string>([
    [MessageStatus.Pending, 'Pending'],
    [MessageStatus.Delivered, 'Delivered'],
    [MessageStatus.Failed, 'Failed'],
])

export interface AdminPanelMessageModel {
    audienceType: AudienceType,
    body: string,
    employee: EmployeeShortModel,
    scheduledAt: Date | null,
    status: MessageStatus,
    subject: string,
    type: MessageType,
}

export interface AdminPanelMessageForwardingModel {
    awayMessage: string,
    employeeFirstName: string,
    employeeLastName: string,
    forwardedEmployeeFirstName: string,
    forwardedEmployeeLastName: string,
    startedForwarding?: Date,
}

export interface AiMessageModel {
    type: string;
    content: string;
}

export interface AiDemoModel {
    patientId: number;
    query: string;
    conversation: AiMessageModel[];
}

export interface TextCompletionResponseModel {
    text: string;
    id: string;
}
