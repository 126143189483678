import React from "react";
import { ClickAwayListener, Tooltip } from '@material-ui/core';

interface WildHealthClickableTooltipProps {
  handleClose: () => void;
  tooltipContent: any;
  isOpened: boolean;
  children: any;
  customClasses?: any;
  disableTouchListenerStatus?: boolean;
}

export const WildHealthClickableTooltip: React.FC<WildHealthClickableTooltipProps> = (props: WildHealthClickableTooltipProps) => {
  const {
    handleClose,
    tooltipContent,
    isOpened,
    children,
    customClasses,
    disableTouchListenerStatus = false
  } = props;

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Tooltip
        title={tooltipContent}
        PopperProps={{
          disablePortal: false,
        }}
        onClose={handleClose}
        open={isOpened}
        disableFocusListener
        disableHoverListener
        disableTouchListener={disableTouchListenerStatus}
        arrow
        classes={{
          ...customClasses
        }}
      >
        {children}
      </Tooltip>
    </ClickAwayListener>
  );
}
