import {VitalsNames} from "../validators/vital.validator";
import {VitalModel} from "../models/vital.model";
const bmiFormulaValue = 703;

export const calculateBmi = (items: VitalModel[], index) => {
    const weightVitals = items.find(i => i.name === VitalsNames.Weight);
    const heightVitals = items.find(i => i.name === VitalsNames.Height);

    const weight = weightVitals.values[index]?.value;
    const height = heightVitals.values[index]?.value;

    if (height && weight) {
        const newBmi = (Number(weight) / Math.pow(height, 2)) * bmiFormulaValue;
        return newBmi.toString().slice(0, 5);
    }

    return '';
}