import {Store, StoreConfig} from '@datorama/akita';
import {ApoeModel, FileInputModel, GeneralInputsModel, LabInputModel, LabInputsDatasetEditableViewModel, LabInputsViewMode, MesaModel, MicrobiomeInputModel} from "../../models/input.models";

/**
 * Represents inputs state
 */
export interface InputsState {
    fileInputs: FileInputModel[];
    labInputs: LabInputModel[];
    generalInputs: GeneralInputsModel;
    dataSets: LabInputsDatasetEditableViewModel[],
    microbiomeInputs: MicrobiomeInputModel[];
    viewMode: LabInputsViewMode;
    apoeInfo: ApoeModel;
    mesaScore: MesaModel;
}

/**
 * Creates initial state
 */
export function createInitialState(): InputsState {
    return {
        fileInputs: [],
        labInputs: [],
        generalInputs: null,
        dataSets: [],
        microbiomeInputs: [],
        viewMode: LabInputsViewMode.Graph,
        apoeInfo: null,
        mesaScore: null
    };
}

/**
 * Provides inputs states management
 */
@StoreConfig({name: 'inputs', resettable: true })
export class InputsStore extends Store<InputsState> {
    constructor() {
        super(createInitialState());
    }

    public addFileInput(fileInput: FileInputModel): void {
        const fileInputs = this.getValue().fileInputs;
        this.update({fileInputs: [...fileInputs, fileInput]});
    }

    public deleteFileInput(fileInput: FileInputModel): void {
        const fileInputs = this.getValue().fileInputs;
        this.update({fileInputs: fileInputs.filter(x => x.id !== fileInput.id)});
    }

    public setDataSets(dataSets: LabInputsDatasetEditableViewModel[]): void{
        let dataSetsState = this.getValue().dataSets;

        dataSets.forEach(dataSet => {
            dataSetsState = dataSetsState.filter(c=> c.id !== dataSet.id);
            dataSetsState = [...dataSetsState, dataSet];
        });

        this.update({dataSets: dataSetsState});
    }

    public setLabIntputs(labInputs: LabInputModel[]) : void {
        this.update({labInputs: labInputs});
    }

    public updateLabIntputs(labInput: LabInputModel) : void {
        const labInputs = this.getValue().labInputs;
        this.update({ labInputs: labInputs.map(x => x.id === labInput.id ? labInput : x) });
    }

    public setCommonViewMode(viewMode: LabInputsViewMode): void {
        this.update({viewMode: viewMode});
    }
}

export const inputsStore = new InputsStore();
