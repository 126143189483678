import React from "react";
import {Box, IconButton} from "@material-ui/core";
import {useStyles} from "./playgroundMessageComponent.styles";
import clsx from "clsx";
import moment from "moment";
import {MessageAvatarComponent} from "../messageAvatarComponent/MessageAvatarComponent";
import {ReactComponent as CopyIcon} from "@img/icons/CopyIcon.svg";
import {handleCopyToClipboard} from "../../../common/helpers/copy-to-clipboard";
import {ReactComponent as ThumbUpIcon} from "@img/icons/ThumbUp.svg";
import {ReactComponent as ThumbDownIcon} from "@img/icons/ThumbDown.svg";
import {ReactionType} from "../../models/conversation.models";

export interface PlaygroundMessageComponentProps {
    message: any;
    author: string;
    handleAddReaction: Function;
    handleRemoveReaction: Function;
}

export const PlaygroundMessageComponent: React.FC<PlaygroundMessageComponentProps> = (props: PlaygroundMessageComponentProps) => {
    const {
        message,
        author,
        handleAddReaction,
        handleRemoveReaction
    } = props;

    const classes = useStyles();

    const formatDate = (date: Date) => {
        return moment(date).format('HH:mm a');
    }

    const isReacted = (type: ReactionType) => {
        const reactions = message?.state?.attributes?.Reactions ?? [];

        return reactions.some(x => x.Type == type && x.CreatedBy == author);
    }

    const getReaction = (type: ReactionType) => {
        const reactions = message?.state?.attributes?.Reactions ?? [];

        return reactions.find(x => x.Type == type && x.CreatedBy == author);
    }

    const handleThumbUp = () => {
        isReacted(ReactionType.Like)
            ? handleRemoveReaction(getReaction(ReactionType.Like), message)
            : handleAddReaction(message, ReactionType.Like);
    }

    const handleThumbDown = () => {
        isReacted(ReactionType.Dislike)
            ? handleRemoveReaction(getReaction(ReactionType.Dislike), message)
            : handleAddReaction(message, ReactionType.Dislike);
    }

    const renderPlaygroundResponse = () => {
        let formattedMessage: any = message;

        if (!message?.state?.attributes?.Interactions?.length) {
            return <></>
        }

        const interaction = formattedMessage?.state?.attributes?.Interactions[0];
        const interactionBody = interaction.Detail;

        return (
            <Box>
                <Box className={clsx(classes.messageContainer)}>
                    <Box>
                        <MessageAvatarComponent isAi lastName="" firstName="" image=""/>
                    </Box>
                    <Box className={classes.messageBlock}>
                        <Box className={clsx(classes.message)}>
                            <span>
                                {interactionBody}
                            </span>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Box className={classes.date}>
                                {formatDate(message.dateCreated)}
                            </Box>
                            <Box display="flex">
                                <IconButton onClick={() => handleThumbUp()} className={isReacted(ReactionType.Like) ? classes.reacted : ''}>
                                    <ThumbUpIcon className="wh-tw-fill-primaryV"/>
                                </IconButton>
                                <IconButton onClick={() => handleThumbDown()} className={isReacted(ReactionType.Dislike) ? classes.reacted : ''}>
                                    <ThumbDownIcon/>
                                </IconButton>
                                <IconButton onClick={() => handleCopyToClipboard(interactionBody)}>
                                    <CopyIcon/>
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    }

    return (
        <>
            <Box>
                <Box className={clsx(classes.messageContainer, classes.myMessageContainer)}>
                    <Box className={classes.messageBlock}>
                        <Box className={clsx(classes.message, classes.myMessage)}>
                            <span>
                                 {
                                     message.body
                                 }
                            </span>
                        </Box>
                        <Box className={classes.date} display="flex" justifyContent="flex-end">
                            {formatDate(message.dateCreated)}
                        </Box>
                    </Box>
                </Box>
            </Box>

            {
                renderPlaygroundResponse()
            }
        </>
    )
}