import React from 'react';
import { AuthenticatedLayoutComponent } from "../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent";
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from "../../../../models/menu.models";
import { PatientConversationsComponent } from "../../components/patientConversationsComponent/PatientConversationsComponent";

export const PatientConversationsPage: React.FC = (props: any) => {
    const conversationId = Number(props?.location?.state?.conversationId);

    return (
        <AuthenticatedLayoutComponent
            hideBackground
            displayChildren='flex'
            title={TitlesService.messagingTitle}
            pageName={PagesNamesService.messaging}
            selectedMenuItem={MenuItemTypes.Messaging}
        >
            <PatientConversationsComponent targetConversationId={conversationId} />
        </AuthenticatedLayoutComponent>
    )
};
