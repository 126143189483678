import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            backgroundColor:"#fff",
            height:'calc(100vh - 175px)'
        },
        toolBarSection: {
            marginRight: 8,
            [theme.breakpoints.down("xs")]: {
                marginRight: 0,
                marginTop: 8,
            }
        },
        arrow: {
            color: colors.black,
        },
        customWidth: {
            width: '300px',
            maxWidth: '300px',
            backgroundColor: colors.black,
            padding: 8,
            boxShadow: ` 0px 4px 9px rgba(168, 173, 198, 0.15)`,
        },
        tooltipPlacement: {
            margin: '8px 0',
        }
    }),
);