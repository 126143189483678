import { Store, StoreConfig } from '@datorama/akita';
import { OnboardingConfigurationModel } from "../../models/onboardingConfiguration.model";

export interface OnboardingConfigurationState {
  practiceId: number;
  consultationFormId: string;
}

/**
 * Creates initial state
 */
export function createInitialState(): OnboardingConfigurationState {
  return {
    practiceId: null,
    consultationFormId: '',
  };
}

/**
 * Provides onboarding configuration states management
 */
@StoreConfig({ name: 'onboardingConfiguration', resettable: true })
export class OnboardingConfigurationStore extends Store<OnboardingConfigurationState> {
  constructor() {
    super(createInitialState());
  }

  public updateConfiguration(configuration: OnboardingConfigurationModel): void {

    this.update({
      practiceId: configuration.practiceId,
      consultationFormId: configuration.consultationFormId,
    });
  }
}

export const onboardingConfigurationStore = new OnboardingConfigurationStore();
