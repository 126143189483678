import {ConversationAuthorModel, ConversationModelBase} from "../../models/conversation.models";
import React from "react";
import {useFacade} from "./playgroundThreadComponent.hooks";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import {Box} from "@material-ui/core";
import {useStyles} from "./playgroundThreadComponent.styles";
import {PlaygroundMessageComponent} from "../playgroundMessageComponent/PlaygroundMessageComponent";

export interface PlaygroundThreadComponentProps {
    conversation: ConversationModelBase;
    author: ConversationAuthorModel;
}

export const PlaygroundThreadComponent: React.FC<PlaygroundThreadComponentProps> = (props: PlaygroundThreadComponentProps) => {
    const {
        conversation,
        author
    } = props;

    const [
        {
            isLoadingMessages,
            messages,
        },
        lastMessageRef,
        handleReaction,
        handleRemoveReaction
    ] = useFacade(conversation, author);

    const classes = useStyles()

    if (isLoadingMessages) {
        return <Box>
            <WildHealthLinearProgress/>
        </Box>
    }

    return (
        <Box className={classes.root}>
            {
                messages.map((message, index) => {
                    return <Box p={2} key={index}>
                        <div ref={index === messages.length - 1 ? lastMessageRef : null}>
                            <PlaygroundMessageComponent
                                message={message}
                                author={author.conversationIdentity}
                                handleAddReaction={handleReaction}
                                handleRemoveReaction={handleRemoveReaction}
                            />
                        </div>
                    </Box>
                })
            }
        </Box>
    )
}