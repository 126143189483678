import { functionValidationRule, regexpValidationRule, stringLengthValidationRule } from "../../../common/validation/general-validation-rules";
import { GeneralValidator } from "../../../common/validation/general-validator";

export const restorePasswordPageValidator = new GeneralValidator({
    'newPassword': [
        functionValidationRule((p: string) => {return !p.includes(" ")}, "Password should not contain spaces"),
        regexpValidationRule(new RegExp("(?=.*[0-9])"), "Password should contain at least one digit"),
        regexpValidationRule(new RegExp("(?=.*[a-z])"), "Password should contain at least one lower case"),
        regexpValidationRule(new RegExp("(?=.*[A-Z])"), "Password should contain at least one upper case"),
        stringLengthValidationRule(8, null, 'Password should contain at least 8 characters')
    ],
    'confirmPassword': [stringLengthValidationRule(1, null, 'Confirm password is required.')]
});
