import {Box, FormControl, FormLabel, TextField} from '@material-ui/core';
import React, { ChangeEvent } from 'react';
import commonUseStyles from "../../../common/styles/common.styles";
import clsx from "clsx";

interface AppointmentCommentComponentProps {
    comment: string;
    handleChangeComment: (event: ChangeEvent<HTMLTextAreaElement>) => void;
    error?: boolean;
    helperText?: React.ReactNode;
    rows?: number;
}

export const AppointmentCommentComponent: React.FC<AppointmentCommentComponentProps> = (props: AppointmentCommentComponentProps) => {
    const {
        comment,
        handleChangeComment,
        error = false,
        helperText = null,
        rows = 3
    } = props;

    const commonClasses = commonUseStyles();

    return (
        <Box>
            <FormControl color='primary' component="fieldset" fullWidth={true}>
                <FormLabel className={clsx(commonClasses.colorGray1, commonClasses.size14)}>
                    Note
                </FormLabel>
                <Box mt={1}>
                    <TextField
                        id='comment'
                        fullWidth
                        type='text'
                        size="small"
                        multiline
                        rows={rows}
                        error={error}
                        helperText={helperText}
                        variant="outlined"
                        value={comment}
                        onChange={handleChangeComment}
                    />
                </Box>
            </FormControl>
        </Box>
    );
}
