import { Query } from '@datorama/akita';
import { ScheduledMessagesState, scheduledMessagesStore, ScheduledMessagesStore } from "./scheduledMessages.store";

/**
 * Provides coaches queries
 */
export class ScheduledMessagesQuery extends Query<ScheduledMessagesState> {
    editingTarget$ = this.select(state => state.editingTarget);
    scheduledMessages$ = this.select(state => state.scheduledMessages);
    uploadedAttachments$ = this.select(state => state.uploadedAttachments);

    constructor(protected store: ScheduledMessagesStore) {
        super(store);
    }
}

export const scheduledMessagesQuery = new ScheduledMessagesQuery(scheduledMessagesStore);