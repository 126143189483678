import React from 'react';
import { Box, Dialog, DialogContent, useMediaQuery, useTheme } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { useStyles } from "./standardUpsellDialog.styles";
import { useFacade } from "./standardUpsellDialog.hooks";
import { DialogTitle } from '../../../common/components/dialogTitle/DialogTitle';
import { patientStore } from '../../../patients/stores/patientsStore';

export const StandardUpsellRequestReceivedDialog: React.FC = () => {
    const [
        {
            isReceivedOpen
        },
    ] = useFacade();

    const classes = useStyles();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            fullWidth
            fullScreen={fullScreen}
            maxWidth="xs"
            open={isReceivedOpen}
            onClose={() => patientStore.toggleRequestReceivedDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle
                id="dialog-title"
                onClose={() => patientStore.toggleRequestReceivedDialog(false)}
                classes={{root: classes.dialogTitle}}
            >
            </DialogTitle>

            <DialogContent classes={{root: classes.dialogContent}}>
                <Box width={1} height={1} display="flex" flexDirection="column" justifyContent="center">
                    <Box display="flex" justifyContent="center">
                        <CheckCircleOutlineIcon className="wh-tw-text-primaryV wh-tw-w-8 wh-tw-h-8" />
                    </Box>
                    <Box mt={2} textAlign="center" className="wh-tw-text-xl wh-tw-font-medium wh-tw-text-black">Request Received!</Box>
                    <Box mt={2} textAlign="center" className="wh-tw-text-base wh-tw-text-black">A care coordinator will be in touch within<br/> 24 hours to assist with your upgrade.</Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
}
