import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { onEmit } from "../../../common/helpers/on-emit";
import { HealthReportModel, HealthReportShortModel, RecommendationSetType, HealthReportDialogName, HealthReportRecommendationDefaultModel, RecommendationDisplayModel } from "../../models/healthReport.models";
import { healthReportServices } from "../../services/healthReport.services";
import { healthReportsQuery } from "../../stores/healthReportsStore/healthReports.query";
import { healthReportsStore } from "../../stores/healthReportsStore/healthReports.store";
import { confirmService } from "../../../../services/confirm.service";

interface ResetPatientPasswordDialogState {
    selectedType: RecommendationSetType;
    reports: HealthReportShortModel[];
    report: HealthReportModel,
    isSelectReport: boolean,
    isRecommendationsDialogOpen: boolean,
    recommendations: RecommendationDisplayModel[],
}

export function useFacade(handleClose: Function, reportRecomendationSet: RecommendationSetType, patientId: number, open: boolean, title: string, handleReportGenerate: Function, handleReportRegenerate: Function): 
    [ResetPatientPasswordDialogState, Function, Function, Function, Function, Function] {
    const [state, setState] = useState({
        selectedType: reportRecomendationSet,
        isSelectReport: false,
        isRecommendationsDialogOpen: false,
        reports: [],
        report: null,
        recommendations: [],
    } as ResetPatientPasswordDialogState);

    const setInitialState = () => {
        setState(state => ({
            ...state,
            selectedType: reportRecomendationSet,
            isSelectReport: false,
        }));
        healthReportsStore.resetReportForRecommendation()
    }

    const handleTypeChange = (type: RecommendationSetType) => {
        setState(state => ({
            ...state,
            selectedType: type,
        }));
    }

    const handleOpenRecommendationsDialog = () => {
        setState(state => ({
            ...state,
            isRecommendationsDialogOpen: true,
        }));
    }

    const handleSkipReview = () => {
        const selectedRecommendations = state.recommendations.filter(el => el.isSelected)
        const recommendationsToSend = selectedRecommendations.map(el => {
            return `${el.recommendationId}`.includes('xxx')
                ? {
                    type: el.type,
                    baseId: el.baseId,
                    content: el.content,
                }
                : {
                    recommendationId: el.recommendationId,
                    baseId: el.baseId,
                    type: el.type,
                    content: el.content,
                }
        })
        healthReportsStore.putRecommendationToSend(recommendationsToSend)

        handleClose();
        confirmService.confirm(
            'Skip Review',
            'Are you sure you want to skip the recommendation review?',
            'Skip',
            'Cancel',
            'default')
            .subscribe(
              () =>  {
                title === HealthReportDialogName.New ? handleReportGenerate() : handleReportRegenerate()
              });
    }

    const handleSubmit = () => {
        if (state.isSelectReport) {
            return handleOpenRecommendationsDialog()
        }
        switch (state.selectedType) {
            case RecommendationSetType.NewAndSkipReview:
                handleSkipReview();
                break;
            case RecommendationSetType.New:
                handleOpenRecommendationsDialog();
                break;
            case RecommendationSetType.Previous:
            case RecommendationSetType.Compare:
                setState(state => ({
                    ...state,
                    isSelectReport: true,
                }));
                break;
        }
    }

    const handleReportChange = (reportId: number) => {
        healthReportServices.getByIdForRecommendation(reportId, patientId);
    }

    const handleGoBackRecommendationsDialog = () => {
        setState(state => ({
            ...state,
            isRecommendationsDialogOpen: false,
        }));
    }

    const handleCloseRecommendationsDialog = () => {
        setState(state => ({
            ...state,
            isRecommendationsDialogOpen: false,
        }));
        handleClose()
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<HealthReportModel>(healthReportsQuery.reportForRecommendation$, report => {
                setState(state => ({
                    ...state,
                    report,
                }));
            }),
            onEmit<HealthReportShortModel[]>(healthReportsQuery.reports$, reports => {
                setState(state => ({
                    ...state,
                    reports
                }));
            }),
            onEmit<HealthReportRecommendationDefaultModel[]>(healthReportsQuery.recommendationsDefault$, recommendationsDefault => {
                setState(state => ({
                    ...state,
                    recommendations: recommendationsDefault.map(el => {
                        return {
                            recommendationId: el.recommendationId,
                            baseId: el.recommendationId,
                            type: el.recommendationType,
                            content: el.recommendation.content,
                            id: el.id,
                            isSelected: true,
                        }
                    })
                }));
            }),
        ];

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);


    useEffect(() => {
        if (!open) {
            setInitialState()
        } else {
            setState(state => ({
                ...state,
                selectedType: reportRecomendationSet
            }));
        }

    }, [open]);


    return [state, handleTypeChange, handleSubmit, handleReportChange, handleCloseRecommendationsDialog, handleGoBackRecommendationsDialog];
}
