import { ListItemText, Dialog } from "@material-ui/core";
import { createStyles, makeStyles, withStyles, Theme } from "@material-ui/core/styles";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: {
      paddingTop: '30px !important',
      paddingLeft: '40px !important',
      paddingRight: '40px !important',
    },
    dialogContent: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 30,
    },
    content: {
      background: colors.white,
    },
    contentContainer: {
      maxHeight: '400px',
      overflowY: 'auto',
      overflowX: 'hidden',
      "& > .MuiGrid-spacing-xs-1": {
        margin: 0,
      }
    },
    titleContainer: {
      display: "flex",
      alignItems: "center",
      padding: "16px 16px 0 16px",
      [theme.breakpoints.down("xs")]: {
        paddingBottom: 10,
      },
    },
    containerImg: {
      "& > img": {
        maxWidth: '90vw',
        maxHeight: '80vh',
      }
    },
    slider: {
      "& .awssld__wrapper": {
        maxHeight: '90vh',
      }
    },
    title: {
      color: colors.gray1,
      fontFamily: "Be Vietnam Pro",
      fontWeight: 500,
      fontSize: 12,
      textTransform: "uppercase",
      marginLeft: 8,
      [theme.breakpoints.down("xs")]: {
        marginLeft: 0,
      },
    },
    titleIcon: {
      fill: colors.gray1,
      transform: "rotate(45deg)",
    },
    fileButton: {
      "&:hover": {
        "& $fileIcon": {
          display: "none",
        },
        "& $downloadIcon": {
          display: "inline-block",
        },
      },
    },
    fileIcon: {
      color: colors.gray2,
      display: "inline-block",
    },
    downloadIcon: {
      color: colors.gray2,
      display: "none",
    },
    document: {
      padding: "0 8px",
      borderRadius: 2,
      display: "flex",
      alignItems: "center",
      background: colors.gray8,
      width: "100%",
      height: 44,
    },
    imageFile: {
      backgroundRepeat: "no-repeat",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 2,
      backgroundSize: 88,
      height: 88,
      minWidth: 88,

      "&:hover": {
        "& $downloadIcon": {
          display: "inline-block",
        },
      },
    },
    tabs: {
      color: colors.gray1,
      "& .MuiTabs-indicator": {
        backgroundColor: colors.main,
      },
      "& .MuiTab-root": {
        fontSize: 14,
        fontWeight: 400,
        textTransform: "none",
        "&.Mui-selected": {
          color: colors.main,
        },
      },
    },
    container: {
      width: "100%",
      height: "100%",
    },
  })
);

export const Title = withStyles({
  root: {
    "& .MuiListItemText-primary": {
      color: colors.black,
      fontWeight: 500,
      fontSize: 16,
    },
  },
})(ListItemText);

export const FileName = withStyles({
  root: {
    "& .MuiListItemText-primary": {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      maxWidth: 225,
    },
  },
})(ListItemText);

export const DialogSlider = withStyles({
  root: {
    "& .MuiDialog-paperFullWidth": {
      width: '100vw',
      margin: 0,
      height: '100vh',
      minHeight: '100vh',
      minWidth: '100vw',
      backgroundColor: 'inherit',
    },
  },
})(Dialog);
