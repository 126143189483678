import { useEffect, useState } from 'react';
import { Subscription } from 'rxjs';
import { onEmit } from '../../../common/helpers/on-emit';
import { meddsSuppsQuery } from '../../stores/meddsSuppsStore/meddsSupps.query';
import { meddsSuppsStore } from '../../stores/meddsSuppsStore/meddsSupps.store';
import { applicationService } from '../../../../services/application.service';
import { MenuItemTypes } from '../../../../models/menu.models';
import { TutorialStatusModel } from '../../../../models/application.models';
import { applicationQuery } from '../../../../stores/applicationStore';
import { authQuery } from '../../../auth/stores/auth';
import { UserType } from '../../../auth/models/auth.enums';
import { allergiesService } from '../../services/allergies.service';

interface PatientMedicationsSupplementsTabsComponentState {
    selectedTab: number;
    isDialogOpened: boolean;
    tutorialStatus: TutorialStatusModel;
}

export function useFacade(patientId?: number): [
    PatientMedicationsSupplementsTabsComponentState,
    (tab: number) => void,
    () => void,
] {
    const [state, setState] = useState({
        selectedTab: 0,
        isDialogOpened: false,
        tutorialStatus: null
    } as PatientMedicationsSupplementsTabsComponentState);

    const handleChangeTab = (tab: number) => {
        meddsSuppsStore.setTab(tab);
    }

    const handleToggleDialog = () => {
        setState({
            ...state,
            isDialogOpened: !state.isDialogOpened,
        });
        if (state.tutorialStatus && !state.tutorialStatus?.isAcknowledged) {
            applicationService.closeTutorial(state.tutorialStatus.id).subscribe()
        }
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<number>(meddsSuppsQuery.tab$, tab =>
                setState(state => ({...state, selectedTab: tab}))
            ),
            onEmit<TutorialStatusModel>(applicationQuery.tutorialStatus$, tutorialStatus => {
                if (tutorialStatus?.tutorialName === `${MenuItemTypes.Supplements}-tutorial`) {
                    setState(state => ({ ...state, tutorialStatus, isDialogOpened: !tutorialStatus.isAcknowledged}))
                }
            })
        ];

        if (authQuery.getType() === UserType.Patient) {
            applicationService.checkFirstVisit(`${MenuItemTypes.Supplements}-tutorial`).subscribe()
        }

        if (patientId) {
            allergiesService.get(patientId).subscribe()
        } 

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [
        state,
        handleChangeTab,
        handleToggleDialog
    ];
}