import Axios from 'axios-observable';
import { PermissionType } from '../../auth/models/auth.enums';
import { authHeader } from "../../common/helpers/auth-header";
import { snackService } from "../../common/snack/state";
import { EmployeesStore, employeesStore } from '../stores/employeesStore';

/**
 * Provides method for working with permissions
 */
export class PermissionsService {
    private url = `${process.env.REACT_APP_API_URL}Permissions`;

    constructor(private employeesStore: EmployeesStore) {
    }

    private inactivePermissions = [PermissionType.ClinicAnalytics];

    /**
     * Returns all permissions
     */
    public get(): void {
        const config = {
            headers: authHeader()
        };

        Axios.get(this.url, config)
            .pipe()
            .subscribe(
                response => {
                    //#18067 Temporaly hide permission from UI. TODO: Remove after implement permission logic 
                    const permissions = response.data.filter(x=> !this.inactivePermissions.includes(x.id))
                    this.employeesStore.update({ permissions: permissions })
                },
                error => snackService.commonErrorHandler(error)
            );
    }
}

export const permissionsService = new PermissionsService(employeesStore);
