import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Subscription } from "rxjs";
import { navigationService } from "../../../../../../services/navigation.service";
import { onEmit } from "../../../../../common/helpers/on-emit";
import { HealthFormModel, QuestionnaireResultModel, QuestionnaireShortModel, QuestionnaireStatusType, QuestionnaireType } from "../../../../../questionnaire/models/questionnaire.models";
import { questionnaireService } from "../../../../../questionnaire/services/questionnaire.service";
import { questionnaireQuery } from "../../../../../questionnaire/stores/questionnaireStore";

interface HealthFormsInfoWidgetComponentState {
  isLoadingResults: boolean;
  isLoadingAvailable: boolean;
  isLoadingHealthForm: boolean;
  isLoading: boolean;
  healthForms: HealthFormsModel[];
  pendingQuestionnaires: HealthFormsModel[];
  availableQuestionnaires: HealthFormsModel[];
}

interface HealthFormsModel {
  name: string,
  status: QuestionnaireStatusType
}

export function useFacade(patientId: number | null): [
  HealthFormsInfoWidgetComponentState,
  () => void
] {
  const [state, setState] = useState({
    isLoadingResults: true,
    isLoadingAvailable: true,
    isLoadingHealthForm: true,
    isLoading: true,
    healthForms: [],
    pendingQuestionnaires: [],
    availableQuestionnaires: [],
  } as HealthFormsInfoWidgetComponentState);

  const history = useHistory();

  const handleGoToHealthForms = () => {
    navigationService.toPatientHealthForms(history, patientId);
  }

  useEffect(() => {
    if (state.isLoadingResults && state.isLoadingAvailable && state.isLoadingHealthForm) {
      setState((state) => ({ ...state, isLoading: false }))
    }

  }, [state.isLoadingResults, state.isLoadingAvailable, state.isLoadingHealthForm]);

  useEffect(() => {
    const subscriptions: Subscription[] = [
      onEmit<QuestionnaireResultModel[]>(questionnaireQuery.questionnaireResults$, results => {
        setState(state => ({
          ...state,
          pendingQuestionnaires: results.filter(q => q.questionnaire.type !== QuestionnaireType.HealthForms).map((x) => {
            return {
              name: x.sequenceNumber ? x.questionnaire.name + ' ' + x.sequenceNumber : x.questionnaire.name,
              status: x.submittedAt ? QuestionnaireStatusType.Completed : QuestionnaireStatusType.InCompleted
            } as HealthFormsModel
          }),
        }));
      }),
      onEmit<QuestionnaireShortModel[]>(questionnaireQuery.availableQuestionnaires$, available => {
        setState(state => ({
          ...state,
          availableQuestionnaires: available.filter(x => x.type !== QuestionnaireType.HealthForms).map((x) => {
            return {
              name: x.name,
              status: QuestionnaireStatusType.New
            } as HealthFormsModel
          }),
        }));
      }),
      onEmit<HealthFormModel[]>(questionnaireQuery.healthForms$, healthForms => {
        setState(state => ({
          ...state,
          healthForms: healthForms.map((x) => {
            return {
              name: x.name,
              status: x.questionnaireStatusType
            } as HealthFormsModel
          }),
        }));
      }),
    ];

    const cbR = () => setState((state) => ({ ...state, isLoadingResults: false }));
    const cbA = () => setState((state) => ({ ...state, isLoadingAvailable: false }));
    const cbH = () => setState((state) => ({ ...state, isLoadingHealthForm: false }));


    questionnaireService.getHealthFormsByPatientId(patientId).subscribe(cbH, cbH);
    questionnaireService.getResults(patientId).subscribe(cbR, cbR);
    questionnaireService.getAvailable(patientId).subscribe(cbA, cbA);


    return () => {
      subscriptions.map((it) => it.unsubscribe());
    };
  }, [patientId]);

  return [
    state,
    handleGoToHealthForms
  ];
}
