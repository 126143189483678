import React from 'react';
import { AuthenticatedLayoutComponent } from '../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { TitlesService } from "../../../../constants/title.constants";
import { MenuItemTypes } from "../../../../models/menu.models";
import { useFacade } from "./AccountMembershipActivationPage.hooks";
import {WaitListDialogComponent} from "../../components/waitListDialogComponent/WaitListDialogComponent";
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';

export const AccountMembershipActivationPage: React.FC = () => {
    const [
        state,
        page
    ] = useFacade();

    return (
        <AuthenticatedLayoutComponent
            hideBackground
            title={TitlesService.accountMembershipActivationTitle}
            pageName={PagesNamesService.accountMembershipActivation}
            selectedMenuItem={MenuItemTypes.None}
        >
            { state.isLoading ? <WildHealthLinearProgress/> : page }
            <WaitListDialogComponent />
        </AuthenticatedLayoutComponent>
    )
}
