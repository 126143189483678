import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 2,
    },
    rootWithBackground: {
      background: "#fff",
    },
    rootMargin: {
      margin: 0,
      [theme.breakpoints.up("sm")]: {
          margin: 1,
          padding: '0px 28px'
      },
    },
  })
);
