import {useEffect, useState} from "react";
import {IErrorState} from "../../../common/validation/error-state";
import { BusinessProfileModel, getDefaultBusinessProfileModel } from "../../models/businessProfile.model";
import {onEmit} from "../../../common/helpers/on-emit";
import {profileQuery} from "../../stores/profileStore";
import {profileService} from "../../services/profile.service";
import {businessAccountComponentValidator} from "./businessAccountComponent.validator";
import { getLastObject } from "../../../common/helpers/get-last-object";

interface BusinessAccountComponentState extends IErrorState {
    isLoading: boolean;
    isSubmitted: boolean;
    business: BusinessProfileModel;
}

export function useFacade(): [
    BusinessAccountComponentState,
    (field: string, value: string) => void,
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
] {
    const [state, setState] = useState({
        business: getDefaultBusinessProfileModel(),
        isLoading: true,
        isSubmitted: false,
        errors: {}
    } as BusinessAccountComponentState);

    const handleChanges = (field: string, value: string) => {
        businessAccountComponentValidator.validateAndSetState(state, setState, field, value);

        const business = state.business;
        const keys = field.split(".");
        const key = keys.pop();
        const lastObject = getLastObject(keys, business);
        lastObject[key] = value;

        setState(state => ({
            ...state,
            business: business
        }));
    }

    const handleSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();

        businessAccountComponentValidator.validateObjectAndSetState(state, setState, state.business);
        if (!businessAccountComponentValidator.stateIsValid(state)) {
            return;
        }

        setState(state => ({
            ...state,
            isSubmitted: true
        }));

        const cb = () => {
            setState(state => ({
                ...state,
                isSubmitted: false
            }));
        }

        state.business.name = state.business.name.trim();
        profileService.updateBusinessProfile(state.business).subscribe(cb, cb);
    }

    useEffect(() => {
        const subscriptions = [
            onEmit<BusinessProfileModel>(profileQuery.businessProfile$, business => {
                if (business) {
                    setState(state => ({
                        ...state,
                        business: business
                    }));
                }
            })
        ];

        const cb = () => {
            setState(state => ({
                ...state,
                isLoading: false
            }));
        }

        profileService.getBusinessProfile().subscribe(cb, cb);

        return (() => {
            subscriptions.map(it => it.unsubscribe())
        });
    }, []);

    return [state, handleChanges, handleSubmit];
}
