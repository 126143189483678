import React from "react";
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Checkbox, IconButton} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { useStyles } from "./pauseSubscriptionDialog.styles";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";

interface PauseSubscriptionDialogProps {
    open: boolean;
    isSuspending: boolean;
    month: number;
    handleSuspend: () => void;
    handleClose: () => void;
    handleChanges: (value: any) => void
}

export const PauseSubscriptionDialog: React.FC<PauseSubscriptionDialogProps> = (props: PauseSubscriptionDialogProps) => {
    const {
        open,
        isSuspending,
        month,
        handleSuspend,
        handleClose,
        handleChanges
    } = props;

    const classes = useStyles();

    const controlIcon = <RadioButtonUncheckedIcon className="wh-tw-text-mediumGray" />;
    const controlCheckedIcon = <RadioButtonCheckedIcon className="wh-tw-text-primaryV" />

    return (
        <Dialog fullWidth open={open} onClose={() => handleClose()} aria-labelledby="form-dialog-title">
            <DialogTitle className={classes.dialogTitle} disableTypography>
                <Box className="wh-tw-text-2xl wh-tw-font-medium wh-tw-text-black">Suspend Plan</Box>
                <IconButton id="appointment-options-close" className={classes.dialogTitleCloseAction} onClick={() => handleClose()}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Box>
                    <Box className="wh-tw-text-base wh-tw-text-black">
                        When should the plan restart?
                    </Box>
                    <Box mt={2} display="flex" alignItems="center" gridGap={32}>
                        <Box display="flex" alignItems="center">
                            <Checkbox
                                icon={controlIcon}
                                color="primary"
                                checkedIcon={controlCheckedIcon}
                                checked={month === 1}
                                onChange={() => handleChanges(1)}
                            />
                            <Box mr={2} className="wh-tw-text-black">1 month</Box>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Checkbox
                                icon={controlIcon}
                                color="primary"
                                checkedIcon={controlCheckedIcon}
                                checked={month === 2}
                                onChange={() => handleChanges(2)}
                            />
                            <Box mr={2} className="wh-tw-text-black">2 months</Box>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Checkbox
                                icon={controlIcon}
                                color="primary"
                                checkedIcon={controlCheckedIcon}
                                checked={month === 3}
                                onChange={() => handleChanges(3)}
                            />
                            <Box mr={2} className="wh-tw-text-black">3 months</Box>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>

            <DialogActions className={classes.dialogActions}>
                <Box mx={1.5}>
                    <WildHealthButton
                        id="cancel-suspend" 
                        color="tertiary"
                        size="large"
                        onClick={() => handleClose()}
                    >
                        Cancel
                    </WildHealthButton>
                </Box>
                <Box>
                    <WildHealthButton
                        id="suspend-plan"
                        size="large"
                        disabled={!month}
                        loading={isSuspending}
                        onClick={() => handleSuspend()}
                    >
                        Suspend Plan
                    </WildHealthButton>
                </Box>
            </DialogActions>
        </Dialog>
    );
}
