import React from "react";
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { colors } from '../../../common/constants/colors';

interface RegistrationNextButtonProps {
  goNext: () => void;
  label?: string;
  icon?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
}

export const RegistrationNextTestedButton: React.FC<RegistrationNextButtonProps> = (props: RegistrationNextButtonProps) => {
  const {
    goNext,
    label,
    icon,
    isLoading = false,
    disabled = false,
  } = props;

  return (
    <WildHealthButton
      id="registration-next"
      fullWidth
      style={{
        color: colors.white,
        fontSize: 16,
        fontWeight: 500,
        borderRadius: 8,
        border: 'none',
        backgroundColor: !disabled && !isLoading ? colors.main : colors.mediumGray
      }}
      size="large"
      loading={isLoading}
      disabled={disabled}
      onClick={() => goNext()}
    >
      <p>{label ?? 'Next Step'}</p>
      {icon && <ArrowForwardIcon />}
    </WildHealthButton>
  );
}

export const RegistrationNextButton: React.FC<RegistrationNextButtonProps> = (props: RegistrationNextButtonProps) => {
  const {
    goNext,
    label,
    icon,
    isLoading = false,
    disabled = false,
  } = props;

  return (
    <WildHealthButton
      id="registration-next"
      fullWidth
      color="mint"
      style={{
          borderRadius: 100,
      }}
      size="large"
      loading={isLoading}
      disabled={disabled}
      onClick={() => goNext()}
    >
      <p>{label ?? 'Next Step'}</p>
      {icon && <ArrowForwardIcon />}
    </WildHealthButton>
  );
}