import React, {useEffect, useState} from "react";
import {NoteModel, NotesType} from "../../models/notes.models";
import {useHistory} from "react-router";
import {navigationService} from "../../../../services/navigation.service";
import {notesService} from "../../services/notes.service";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import {CreateInitialConsultNotePage} from "../createInitialConsultNotePage/CreateInitialConsultNotePage";
import {CreateFollowUpNotePage} from "../createFollowUpNotePage/CreateFollowUpNotePage";
import {HistoryAndPhysicalNotePage} from "../historyAndPhysicalNotePage/HistoryAndPhysicalNotePage";
import {
    HistoryAndPhysicalFollowUpNotePage
} from "../historyAndPhysicalFollowUpNotePage/HistoryAndPhysicalFollowUpNotePage";
import {SoapNotePage} from "../soapNotePage/SoapNotePage";
import {
    HistoryAndPhysicalGroupVisitNotePage
} from "../historyAndPhysicalGroupVisitNotePage/HistoryAndPhysicalGroupVisitNotePage";

interface SignOffNotePageState {
    isLoading: boolean;
    note: NoteModel;
}

export const SignOffNotePage: React.FC = (props: any) => {
    const noteId = Number(props.match.params.noteId);
    const history = useHistory();
    const [state, setState] = useState({
        isLoading: true,
        note: null
    } as SignOffNotePageState);

    const renderNote = () => {
        const newProps = Object.assign({}, props);
        newProps.location.state = {
            note: state.note,
            fromDraftNotePage: true
        }

        newProps.match.params['patientId'] = state.note.patientId;

        switch (state.note.type) {
            case NotesType.Initial: return <CreateInitialConsultNotePage {...newProps}/>;
            case NotesType.FollowUp: return <CreateFollowUpNotePage {...newProps}/>;
            case NotesType.HistoryAndPhysicalInitial: return <HistoryAndPhysicalNotePage {...newProps}/>;
            case NotesType.HistoryAndPhysicalFollowUp: return <HistoryAndPhysicalFollowUpNotePage {...newProps}/>;
            case NotesType.HistoryAndPhysicalGroupVisit: return <HistoryAndPhysicalGroupVisitNotePage {...newProps}/>;
            case NotesType.Soap: return <SoapNotePage {...newProps}/>;
        }
    }

    useEffect(() => {
        setState(state => ({
            ...state,
            isLoading: true
        }));

        notesService.getById(noteId).subscribe(
            (note) => {
                setState(state => ({
                    ...state,
                    note: note,
                    isLoading: false
                }))
            },
            () => navigationService.toDefault(history)
        );

        return () => {}
    }, [noteId]);

    if (state.isLoading) {
        return (
            <WildHealthLinearProgress/>
        )
    }

    return (
        <>
            {
                renderNote()
            }
        </>
    );
}