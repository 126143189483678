import {createStyles, makeStyles} from "@material-ui/core/styles";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles(() =>
    createStyles({
        root: {
            width: '350px',
            minHeight: '468px'
        },
        avatarIcon: {
            fill: colors.main
        },
        menuIcon: {
            fill: colors.gray2,
            width: 28,
            height: 28
        },
        collapsed: {
            fill: colors.main,
            width: 58,
            height: 58
        },
        header: {
            borderBottom: '1px solid ' + colors.stroke
        },
        avatar: {
            width: 50,
            height: 50,
            fontSize: 16,
        },
    })
);
