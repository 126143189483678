import {ExternalAuthorizationProviderModel} from "../models/auth.models";
import {ExternalAuthProvider} from "../models/auth.enums";

export class Auth2UrlBuilder {
    private _googleUrl = 'https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id={CLIENT_ID}&state={STATE}&scope=openid%20email&redirect_uri={REDIRECT_URI}'
    private _fullscriptUrl = '{BASE_URL}/oauth/authorize?client_id={CLIENT_ID}&redirect_uri={REDIRECT_URI}&response_type=code&state={STATE}'

    public build(provider: ExternalAuthorizationProviderModel, state: string): string {
        switch (provider.provider) {
            case ExternalAuthProvider.Google: return this._googleUrl
                .replace('{CLIENT_ID}', provider.clientId)
                .replace('{REDIRECT_URI}', provider.redirectUri)
                .replace('{STATE}', state);

            case ExternalAuthProvider.fullscript: return this._fullscriptUrl
                .replace('{BASE_URL}', provider.baseUrl)
                .replace('{CLIENT_ID}', provider.clientId)
                .replace('{REDIRECT_URI}', provider.redirectUri)
                .replace('{STATE}', state);
        }
    }
}

export const auth2UrlBuilder = new Auth2UrlBuilder();