import { useState } from "react";

interface VerifyInsuranceComponentState {
    isVerifiedItem: string,
}

export function useFacade( handleSubmit: Function, handleClose: Function): [
    VerifyInsuranceComponentState,
    (value: string) => void,
    () => void,
    () => void,
] {
    const [state, setState] = useState({
        isVerifiedItem: 'Yes',
    } as VerifyInsuranceComponentState);

    const handleCancel = () => {
        setState(state => ({ ...state, selectedVerifiedItem: null }));
        handleClose();
    }

    const handleVerifiedItemChanges = (value: string) => {
        setState(state => ({ ...state, isVerifiedItem: value }));
    }

    const handleConfirm = () => {
        handleSubmit(state.isVerifiedItem === 'Yes');
        setState(state => ({ ...state, selectedVerifiedItem: null }));
    }

    return [state, handleVerifiedItemChanges, handleCancel, handleConfirm];
}
