import {Query} from '@datorama/akita';
import { LeadSourcesState, leadSourcesStore, LeadSourcesStore } from './leadSources.store';

/**
 * Provides Lead Sources queries
 */
export class LeadSourcesQuery extends Query<LeadSourcesState> {

    leadSources$ = this.select(state => state.leadSources);

    constructor(protected store: LeadSourcesStore) {
        super(store);
    }
}

export const leadSourcesQuery = new LeadSourcesQuery(leadSourcesStore);
