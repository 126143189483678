import {useState, useEffect} from "react";
import { Subscription } from "recompose";
import { onEmit } from "../../../common/helpers/on-emit";
import {IErrorState} from "../../../common/validation/error-state";
import {getLastObject} from "../../../common/helpers/get-last-object";
import {manageApiResponseComponentValidator} from "./manageApiResponseComponent.validator";
import {engineeringService} from "../../services/engineering.service";
import {authService} from "../../../auth/services/auth.service";
import {BuyProductModel} from "../../../products/models/buyProduct.model";
import { PaymentPlanModel, CreatePriceModel, InclusionModel } from "../../../payment/models/paymentPlan.models";
import { authQuery } from "../../../auth/stores/auth";
import { addressService } from "../../../account/services/address.service";
import { paymentPlansService } from "../../../payment/services/paymentPlans.service";
import { StateModel } from "../../../account/models/address.model";
import { UpdateInsuranceStatesModel } from "../../../insurance/models/insurance.models";
import { SubscriptionModel } from "../../../payment/models/subscription.models";
import { PatientProductModel } from "../../../products/models/patientProducts.model";
import { UserType } from "../../../auth/models/auth.enums";
import { useHistory } from "react-router";
import { employerProductsService } from "../../services/employerProducts.service";
import { EmployerProductModel } from "../../models/employerProduct.model";
import { employerProductsQuery } from "../../stores/employerProductsStore";
import { paymentPlansQuery } from "../../../payment/stores/paymentPlansStore";

interface RequestParamState {
    userId: number;
    patientId:number;
    employeeId:number;
    vendor: number;
    vendorId: string;
    paymentPlanId: number;
    selectedStates: StateModel[];
}

export interface EngineeringFiltersState {
    isLoginBehalfUserOpen: boolean;
    isIntegrationOpen: boolean;
    isChangeInsuranceStatesOpen: boolean;
}

export enum ToolEnum {
    CHANGE_SUBSCRIPTION_PRICE = 'CHANGE_SUBSCRIPTION_PRICE',
    CHANGE_PRACTICE_COPY = 'CHANGE_PRACTICE_COPY',
    BUY_PRODUCTS = 'BUY_PRODUCTS',
    CHANGE_SUBSCRIPTION_CANDIDATE = 'CHANGE_SUBSCRIPTION_CANDIDATE',
    PAYMENT_PLANS_RECOMMEND = 'PAYMENT_PLANS_RECOMMEND',
    PAYMENT_PLANS_MIGRATE = 'PAYMENT_PLANS_MIGRATE',
    RESET_PATIENT_PRODUCTS = 'RESET_PATIENT_PRODUCTS',
    GET_ACTIVE_PATIENT_PRODUCTS = 'GET_ACTIVE_PATIENT_PRODUCTS',
    MANAGE_PATIENT_PRODUCTS = 'MANAGE_PATIENT_PRODUCTS',
    CREATE_DEFAULT_PAYMENT_PLAN = 'CREATE_DEFAULT_PAYMENT_PLAN',
    Delete_Unspecified_User = 'Delete_Unspecified_User'
}

export interface PaymentPlanShortModel {
    name: string;
    displayName: string;
}

export interface PaymentCouponShortModel {
    code: string;
    detail: string | null;
}

type ParamsType = {
    CHANGE_SUBSCRIPTION_PRICE: {
        subscriptionId: number,
        priceId: number,
        couponCode: string,
        employerProductId: number
    },
    CHANGE_PRACTICE_COPY: {
        patientId: number,
        practiceId: number
    },
    BUY_PRODUCTS: {
        patientId: number,
        productType: number,
        quantity: number,
        isPaidForByDefaultEmployer: boolean
    },
    CHANGE_SUBSCRIPTION_CANDIDATE: {
        patientId: number,
    },
    PAYMENT_PLANS_RECOMMEND: {
        fromSubscriptionId: number,
        startDate: Date,
        endDate: Date,
        planName: string,
        paymentStrategy: number,
        isInsurance: boolean,
        couponCode: string,
    },
    RESET_PATIENT_PRODUCTS: {
        patientId: number,
    },
    GET_ACTIVE_PATIENT_PRODUCTS: {
        patientId: number
    },
    CREATE_DEFAULT_PAYMENT_PLAN: {
        name: string,
        displayName: string,
        title: string,
        practiceId: number,
        paymentPlanTemplateId: number,
        periodInMonths: number,
        prices: CreatePriceModel[],
        inclusions: InclusionModel[],
        stripeProductId: string,
        isActive: boolean,
        desiredId: number,
        includeDefaultAddOns: boolean,
        canBeActivated: boolean,
        isPrecisionCarePackageFlow: boolean
    },
    Delete_Unspecified_User: {
        email: string
    }
}

type MenuParamsState = {
    [key in ToolEnum]: ParamsType
}

type MenuFilterState = {
    [key in ToolEnum]: boolean
}

const initialParamsType = {
    CHANGE_SUBSCRIPTION_PRICE: {
        subscriptionId: 0,
        priceId: 0,
        couponCode: '',
        employerProductId: -1
    },
    CHANGE_PRACTICE_COPY: {
        practiceId: 0,
        patientId: 0
    },
    BUY_PRODUCTS: {
        patientId: 0,
        productType: 20,
        quantity: 0,
        isPaidForByDefaultEmployer: true
    },
    CHANGE_SUBSCRIPTION_CANDIDATE: {
        patientId: 0,
    },
    PAYMENT_PLANS_RECOMMEND: {
        fromSubscriptionId: 0,
        startDate: new Date(),
        endDate: new Date(),
        planName: '',
        paymentStrategy: 1,
        isInsurance: false,
        couponCode: '',
    },
    RESET_PATIENT_PRODUCTS: {
        patientId: 0,
    },
    GET_ACTIVE_PATIENT_PRODUCTS: {
        patientId: 0,
    },
    CREATE_DEFAULT_PAYMENT_PLAN: {
        name: '',
        displayName: '',
        title: '',
        practiceId: 0,
        periodInMonths: 0,
        prices: [],
        inclusions: [],
        stripeProductId: '',
        isActive: true,
        desiredId: 0,
        includeDefaultAddOns: true,
        canBeActivated: true,
        isPrecisionCarePackageFlow: true
    },
    Delete_Unspecified_User: {
        email: ''
    },
}


interface ManageApiResponseState extends IErrorState {
    isLoading: boolean;
    isStateLoading: boolean;
    isProductsLoading: boolean;
    responseView: any;
    filtersState: EngineeringFiltersState;
    params: RequestParamState;
    menuParams: MenuParamsState;
    menuStates: MenuFilterState;
    eligiblePaymentPlans: PaymentPlanShortModel[];
    eligiblePaymentCoupons: PaymentCouponShortModel[];
    subscriptionId: number;
    activePaymentPlans: PaymentPlanModel[];
    patientName: string,
    patientSubscription: SubscriptionModel,
    eligiblePatientProducts: PatientProductModel[];
    products: EmployerProductModel[],
}

export function useFacade(): [
    ManageApiResponseState,
    any,
    () => void,
    () => void,
    () => void,
    () => void,
    () => void,
    () => void,
    (stateId: number) => void,
    () => void,
    () => void,
    any,
    (field: string, value: any) => void,
    (field: string, value: any, tool:ToolEnum) => void,
] {
    const history = useHistory();
    const params = {
        userId: null,
        patientId: null,
        employeeId: null,
        vendor: 1,
        vendorId: '',
        paymentPlanId: null,
        selectedStates: []
    }

    const [state, setState] = useState({
        isLoading: true,
        isStateLoading: true,
        isProductsLoading: true,
        responseView: null,
        filtersState: {
            isLoginBehalfUserOpen: false,
            isIntegrationOpen: false,
            isChangeInsuranceStatesOpen: false,
        },
        params: params,
        errors: {},
        menuParams: initialParamsType as unknown as MenuParamsState,
        menuStates: {
            CHANGE_PRACTICE_COPY: false,
            CHANGE_SUBSCRIPTION_PRICE: false,
            BUY_PRODUCTS: false,
            CHANGE_SUBSCRIPTION_CANDIDATE: false,
            RESET_PATIENT_PRODUCTS: false,
            MANAGE_PATIENT_PRODUCTS: false,
            CREATE_DEFAULT_PAYMENT_PLAN: false,
            Delete_Unspecified_User: false
        },
        eligiblePaymentPlans: [],
        eligiblePaymentCoupons: [],
        subscriptionId: 0,
        patientName: '',
        patientSubscription: null,
        eligiblePatientProducts: [],
        products: [],
        activePaymentPlans: []
    } as ManageApiResponseState);


    const handleToggleLoginBehalf = () => {
        state.filtersState.isLoginBehalfUserOpen = !state.filtersState.isLoginBehalfUserOpen;
        setState(state => ({...state}));
    }

    const handleToggleIntegration = () => {
        state.filtersState.isIntegrationOpen = !state.filtersState.isIntegrationOpen;
        setState(state => ({...state}));
    }

    const handleToggleChangeInsuranceStates = () => {
        state.filtersState.isChangeInsuranceStatesOpen = !state.filtersState.isChangeInsuranceStatesOpen;
        setState(state => ({...state}));
    }

    const handleToggleChangePractice = () => {
        state.menuStates[ToolEnum.CHANGE_PRACTICE_COPY] = !state.menuStates[ToolEnum.CHANGE_PRACTICE_COPY];
        setState(state => ({...state}));
    }

    const handleToggleChangeSubscriptionPrice = () => {
        state.menuStates[ToolEnum.CHANGE_SUBSCRIPTION_PRICE] = !state.menuStates[ToolEnum.CHANGE_SUBSCRIPTION_PRICE];
        setState(state => ({...state}));
    }

    const handleToggleBuyProducts = () => {
        state.menuStates[ToolEnum.BUY_PRODUCTS] = !state.menuStates[ToolEnum.BUY_PRODUCTS];
        setState(state => ({...state}));
    }
    
    const handleToggleChangeSubscription = () => {
        state.menuStates[ToolEnum.CHANGE_SUBSCRIPTION_CANDIDATE] = !state.menuStates[ToolEnum.CHANGE_SUBSCRIPTION_CANDIDATE];
        setState(state => ({...state}));
    }
    
    const handleToggleResetPatientProducts = () => {
        state.menuStates[ToolEnum.RESET_PATIENT_PRODUCTS] = !state.menuStates[ToolEnum.RESET_PATIENT_PRODUCTS];
        setState(state => ({...state}));
    }
    
    const handleToggleManagePatientProducts = () => {
        state.menuStates[ToolEnum.MANAGE_PATIENT_PRODUCTS] = !state.menuStates[ToolEnum.MANAGE_PATIENT_PRODUCTS];
        setState(state => ({...state}));
    }
    
    const handleToggleCreateDefaultPaymentPlan = () => {
        state.menuStates[ToolEnum.CREATE_DEFAULT_PAYMENT_PLAN] = !state.menuStates[ToolEnum.CREATE_DEFAULT_PAYMENT_PLAN];
        setState(state => ({...state}));
    }

    const handleToggleDeleteUnspecifiedUser = () => {
        state.menuStates[ToolEnum.Delete_Unspecified_User] = !state.menuStates[ToolEnum.Delete_Unspecified_User];
        setState(state => ({...state}));
    }

    const getLoginOnBehalfOfUser = () => {

        if ((state.errors.userId || !state.params.userId) && (state.errors.patientId || !state.params.patientId) && (state.errors.employeeId || !state.params.employeeId))
        {
            return;
        }

        setState(state => ({
            ...state,
            isLoading: true
        }));

        const cb = () => setState(state => ({...state, isLoading: false}));

        engineeringService.loginOnBehalfOfUser(state.params.userId, state.params.patientId, state.params.employeeId)
            .subscribe((response) => {
                authService.authorize(response)
                if (response?.type === UserType.Employee || response?.type === UserType.Patient) {
                    window.location.reload();
                    return;
                }
                setState(state => ({...state, responseView: response, isLoading: false}))
            }, cb)
    }

    const getAllIntegrationIds = () => {
        if (state.errors.vendorId || !state.params.vendorId) {
            return;
        }

        setState(state => ({
            ...state,
            isLoading: true
        }));
        const cb = () => setState(state => ({...state, isLoading: false}));
        engineeringService.getIntegrationInformation(state.params.vendor, state.params.vendorId).subscribe((response) => setState(state => ({
            ...state,
            responseView: response,
            isLoading: false
        })), cb)

    }

    const handleAddInsuranceStates = () => {
        if ((state.errors.paymentPlanId || !state.params.paymentPlanId) && (state.errors.selectedStates || !state.params.selectedStates.length)) {
            return;
        }

        setState(state => ({
            ...state,
            isLoading: true
        }));
        const cb = () => setState(state => ({...state, isLoading: false}));
        
        const updateInsuranceStates:UpdateInsuranceStatesModel = {
            id: state.params.paymentPlanId,
            states: state.params.selectedStates
        };
        
        engineeringService.addPaymentPlanInsuranceStates(updateInsuranceStates).subscribe((response) => {
            setState(state => ({
                ...state,
                responseView: response,
            }))
            paymentPlansService.getActive(authQuery.getCurrentPracticeId(), null).subscribe((plans) => 
                setState(state => ({
                    ...state,
                    activePaymentPlans: plans.filter(x => !x.isTrial && !x.isFounder && !x.isLimited),
                    isLoading: false
                }))
            , cb);
        }, cb)
    }

    const handleRemoveInsuranceStates = (stateId: number) => {
        if ((state.errors.paymentPlanId || !state.params.paymentPlanId) && (!stateId)) {
            return;
        }

        setState(state => ({
            ...state,
            isLoading: true
        }));
        const cb = () => setState(state => ({...state, isLoading: false}));
        
        const targetPaymentPlan = state.activePaymentPlans.find(i => i.id === state.params.paymentPlanId);
        const updateInsuranceStates:UpdateInsuranceStatesModel = {
            id: state.params.paymentPlanId,
            states: targetPaymentPlan.insuranceStates.filter(s => s.id === stateId)
        };
        
        engineeringService.removePaymentPlanInsuranceStates(updateInsuranceStates).subscribe((response) => {
            setState(state => ({
                ...state,
                responseView: response,
            }))
            paymentPlansService.getActive(authQuery.getCurrentPracticeId(), null).subscribe((plans) => 
                setState(state => ({
                    ...state,
                    activePaymentPlans: plans.filter(x => !x.isTrial && !x.isFounder && !x.isLimited),
                    isLoading: false
                }))
            , cb);
        }, cb)
    }

    const changePatientToAnotherPractice = () => {

        const keys = Object.keys(state.menuParams[ToolEnum.CHANGE_PRACTICE_COPY]);
        const values = state.menuParams[ToolEnum.CHANGE_PRACTICE_COPY];
        const errorValues = state.errors;

        if(Object.keys(ToolEnum.CHANGE_PRACTICE_COPY).some(k => (!!errorValues[k])) || keys.every(k=> (!values[k]))) {
            return;
        }
        setState(state => ({
            ...state,
            isLoading: true
        }));

        const cb = () => setState(state => ({...state, isLoading: false}));

        engineeringService.changePracticeCopy(
            parseInt(values[keys[0]]),
            parseInt(values[keys[1]])).subscribe((response) => setState(state => ({
            ...state,
            responseView: response,
            isLoading: false
        })), cb)

    }

    const changeSubscriptionPrice = () => {

        const keys = Object.keys(state.menuParams[ToolEnum.CHANGE_SUBSCRIPTION_PRICE]);
        const values = state.menuParams[ToolEnum.CHANGE_SUBSCRIPTION_PRICE];
        const errorValues = state.errors;

        if(Object.keys(ToolEnum.CHANGE_SUBSCRIPTION_PRICE).some(k => (!!errorValues[k])) || keys.every(k=> (!values[k]))) {
            return;
        }

        setState(state => ({
            ...state,
            isLoading: true
        }));

        const cb = () => setState(state => ({...state, isLoading: false}));
        const model = {
            subscriptionId: values[keys[0]],
            newPaymentPriceId: values[keys[1]],
            couponCode: values[keys[2]],
            employerProductId: values[keys[3]] > -1 ? values[keys[3]] : null
        }

        engineeringService
            .changeSubscriptionPrice(model)
            .subscribe((response) => setState(state => ({...state, responseView: response, isLoading: false})), cb)

    }

    const buyProducts = () => {

        const keys = Object.keys(state.menuParams[ToolEnum.BUY_PRODUCTS]);
        const values = state.menuParams[ToolEnum.BUY_PRODUCTS];
        const errorValues = state.errors;

        if(Object.keys(ToolEnum.BUY_PRODUCTS).some(k => (!!errorValues[k])) || keys.every(k=> (!values[k]))) {
            return;
        }

        setState(state => ({
            ...state,
            isLoading: true
        }));
        const buyProductModel: BuyProductModel = {
            patientId: parseInt(values[keys[0]]),
            productType: parseInt(values[keys[1]]),
            quantity: parseInt(values[keys[2]]),
            isPaidForByDefaultEmployer: values[keys[3]]
        }

        const cb = () => setState(state => ({...state, isLoading: false}));

        engineeringService.buyProducts(buyProductModel).subscribe((response) => setState(state => ({
            ...state,
            responseView: response,
            isLoading: false
        })), cb)
    }
            
    const changeSubscriptionCandidate = () => {

        const keys = Object.keys(state.menuParams[ToolEnum.CHANGE_SUBSCRIPTION_CANDIDATE]);
        const values = state.menuParams[ToolEnum.CHANGE_SUBSCRIPTION_CANDIDATE];
        const errorValues = state.errors;

        if(Object.keys(ToolEnum.CHANGE_SUBSCRIPTION_CANDIDATE).some(k => (!!errorValues[k])) || keys.every(k=> (!values[k]))) {
            return;
        }

        setState(state => ({
            ...state,
            isLoading: true
        }));

        const cb = () => setState(state => ({...state, isLoading: false}));

        engineeringService
            .changeSubscriptionCandidate(values[keys[0]])
            .subscribe((response) =>  {
                if (!response?.statusCode) {
                    state.menuParams[ToolEnum.PAYMENT_PLANS_RECOMMEND] = response;
                    state.menuParams[ToolEnum.PAYMENT_PLANS_RECOMMEND]['fromSubscriptionId'] = response.id;
                    setState(state => ({...state, eligiblePaymentPlans: response.eligiblePaymentPlans, eligiblePaymentCoupons: response.eligiblePaymentCoupons, subscriptionId: response.id}))
                }
                setState(state => ({...state, responseView: response, isLoading: false}))
            }, cb)
    }

    const paymentPlansRecommend = () => {

        const keys = Object.keys(state.menuParams[ToolEnum.PAYMENT_PLANS_RECOMMEND]);
        const values = state.menuParams[ToolEnum.PAYMENT_PLANS_RECOMMEND];
        const errorValues = state.errors;

        if(Object.keys(ToolEnum.PAYMENT_PLANS_RECOMMEND).some(k => (!!errorValues[k])) || keys.every(k=> (!values[k]))) {
            return;
        }

        setState(state => ({
            ...state,
            isLoading: true
        }));

        const cb = () => setState(state => ({...state, isLoading: false}));

        engineeringService
            .paymentPlanRecommend(values)
            .subscribe((response) => {
                if (!response?.statusCode) {
                    state.menuParams[ToolEnum.PAYMENT_PLANS_RECOMMEND] = response;
                    state.menuParams[ToolEnum.PAYMENT_PLANS_RECOMMEND]['fromSubscriptionId'] = state.subscriptionId;
                }
                setState(state => ({...state, responseView: response, isLoading: false}))
            }, cb)

    }

    const paymentPlansMigrate = () => {

        const keys = Object.keys(state.menuParams[ToolEnum.PAYMENT_PLANS_RECOMMEND]);
        const values = state.menuParams[ToolEnum.PAYMENT_PLANS_RECOMMEND];
        const errorValues = state.errors;

        if(Object.keys(ToolEnum.PAYMENT_PLANS_RECOMMEND).some(k => (!!errorValues[k])) || keys.every(k=> (!values[k]))) {
            return;
        }

        setState(state => ({
            ...state,
            isLoading: true
        }));

        const cb = () => setState(state => ({...state, isLoading: false}));

        engineeringService
            .migratePaymentPlan(values)
            .subscribe((response) => setState(state => ({...state, responseView: response, isLoading: false})), cb)

    }
            
    const resetPatientProducts = () => {

        const keys = Object.keys(state.menuParams[ToolEnum.RESET_PATIENT_PRODUCTS]);
        const values = state.menuParams[ToolEnum.RESET_PATIENT_PRODUCTS];
        const errorValues = state.errors;

        if(Object.keys(ToolEnum.RESET_PATIENT_PRODUCTS).some(k => (!!errorValues[k])) || keys.every(k=> (!values[k]))) {
            return;
        }

        setState(state => ({
            ...state,
            isLoading: true
        }));

        const cb = () => setState(state => ({...state, isLoading: false}));

        engineeringService
            .resetPatientProducts(values[keys[0]])
            .subscribe((response) => setState(state => ({...state, responseView: response, isLoading: false})), cb)
    }
            
    const getActivePatientProducts = () => {
        const keys = Object.keys(state.menuParams[ToolEnum.GET_ACTIVE_PATIENT_PRODUCTS]);
        const values = state.menuParams[ToolEnum.GET_ACTIVE_PATIENT_PRODUCTS];
        const errorValues = state.errors;

        if(Object.keys(ToolEnum.GET_ACTIVE_PATIENT_PRODUCTS).some(k => (!!errorValues[k])) || keys.every(k=> (!values[k]))) {
            return;
        }

        setState(state => ({
            ...state,
            isLoading: true
        }));

        const cb = () => setState(state => ({...state, isLoading: false}));

        engineeringService
            .getActivePatientProducts(values[keys[0]])
            .subscribe((response) =>  {
                if (!response?.statusCode) {
                    setState(state => ({...state, patientName: response.patientFullName, patientSubscription: response.subscription, eligiblePatientProducts: response.eligiblePatientProducts}))
                }
                setState(state => ({...state, responseView: response, isLoading: false}))
            }, cb)
    }

    const handleRestoreSubscriptionPrice = () => {

        if (!state.patientSubscription)
        {
            return;
        }

        setState(state => ({
            ...state,
            isLoading: true
        }));

        const cb = () => setState(state => ({...state, isLoading: false}));
        const model = {
            subscriptionId: state.patientSubscription.id,
            newPaymentPriceId: state.patientSubscription.paymentPrice.id,
            couponCode: null,
            employerProductId: null
        }

        engineeringService
            .changeSubscriptionPrice(model)
            .subscribe((response) => setState(state => ({...state, responseView: response, isLoading: false})), cb)

    }
            
    const handleResetPatientProducts = () => {

        const keys = Object.keys(state.menuParams[ToolEnum.GET_ACTIVE_PATIENT_PRODUCTS]);
        const values = state.menuParams[ToolEnum.GET_ACTIVE_PATIENT_PRODUCTS];
        const errorValues = state.errors;

        if(Object.keys(ToolEnum.GET_ACTIVE_PATIENT_PRODUCTS).some(k => (!!errorValues[k])) || keys.every(k=> (!values[k]))) {
            return;
        }

        setState(state => ({
            ...state,
            isLoading: true
        }));

        const cb = () => setState(state => ({...state, isLoading: false}));

        engineeringService
            .resetPatientProducts(values[keys[0]])
            .subscribe((response) => setState(state => ({...state, responseView: response, isLoading: false})), cb)
    }

    const createDefaultPaymentPlan = () => {

        const keys = Object.keys(state.menuParams[ToolEnum.CREATE_DEFAULT_PAYMENT_PLAN]);
        const defaultPaymentPlanModel = state.menuParams[ToolEnum.CREATE_DEFAULT_PAYMENT_PLAN];
        const errorValues = state.errors;

        if(Object.keys(ToolEnum.CREATE_DEFAULT_PAYMENT_PLAN).some(k => (!!errorValues[k])) || keys.every(k=> (!defaultPaymentPlanModel[k]))) {
            return;
        }

        setState(state => ({
            ...state,
            isLoading: true
        }));

        const cb = () => setState(state => ({...state, isLoading: false}));

        engineeringService.createDefaultPaymentPlan(defaultPaymentPlanModel).subscribe((response) => setState(state => ({
            ...state,
            responseView: response,
            isLoading: false
        })), cb)
    }

    const deleteUnspecifiedUser = () => {
        const keys = Object.keys(state.menuParams[ToolEnum.Delete_Unspecified_User]);
        const values = state.menuParams[ToolEnum.Delete_Unspecified_User];
        const errorValues = state.errors;

        if(Object.keys(ToolEnum.Delete_Unspecified_User).some(k => (!!errorValues[k])) || keys.every(k=> (!values[k]))) {
            return;
        }
        setState(state => ({
            ...state,
            isLoading: true
        }));

        const cb = () => setState(state => ({...state, isLoading: false}));

        engineeringService.deleteUnspecifiedUser(values[keys[0]]).subscribe((response) => setState(state => ({
            ...state,
            responseView: response,
            isLoading: false
        })), cb)
    }

    const handleChanges = (field: string, value: any ) => {

        manageApiResponseComponentValidator.validateAndSetState(state, setState, field, value);

        const params = state.params;
        const keys = field.split(".");
        const key = keys.pop();
        const lastObject = getLastObject(keys, params);
        lastObject[key] = value;

        setState({...state, params: Object.assign({}, params)});

    }

    const handleChangesGeneric = (field: string, value: any, tool: ToolEnum) => {

        manageApiResponseComponentValidator.validateAndSetState(state, setState, field, value);

        setState({...state, ...state.menuParams[tool][field] = value });

    }

    const handleToggle = {
        CHANGE_SUBSCRIPTION_PRICE: handleToggleChangeSubscriptionPrice,
        CHANGE_PRACTICE_COPY: handleToggleChangePractice,
        BUY_PRODUCTS: handleToggleBuyProducts,
        CHANGE_SUBSCRIPTION_CANDIDATE: handleToggleChangeSubscription,
        RESET_PATIENT_PRODUCTS: handleToggleResetPatientProducts,
        MANAGE_PATIENT_PRODUCTS: handleToggleManagePatientProducts,
        CREATE_DEFAULT_PAYMENT_PLAN: handleToggleCreateDefaultPaymentPlan,
        Delete_Unspecified_User: handleToggleDeleteUnspecifiedUser
    }

    const handleSubmit = {
        CHANGE_SUBSCRIPTION_PRICE: changeSubscriptionPrice,
        CHANGE_PRACTICE_COPY: changePatientToAnotherPractice,
        BUY_PRODUCTS: buyProducts,
        CHANGE_SUBSCRIPTION_CANDIDATE: changeSubscriptionCandidate,
        PAYMENT_PLANS_RECOMMEND: paymentPlansRecommend,
        PAYMENT_PLANS_MIGRATE: paymentPlansMigrate,
        RESET_PATIENT_PRODUCTS: resetPatientProducts,
        GET_ACTIVE_PATIENT_PRODUCTS: getActivePatientProducts,
        CREATE_DEFAULT_PAYMENT_PLAN: createDefaultPaymentPlan,
        Delete_Unspecified_User: deleteUnspecifiedUser
    }

    const useEffectCB = () => {
        const subscriptions: Subscription[] = [
            onEmit<PaymentPlanModel[]>(paymentPlansQuery.activePaymentPlans$, activePaymentPlans => {
                setState(state => ({
                    ...state,
                    activePaymentPlans,
                }));
            }),
            onEmit<EmployerProductModel[]>(employerProductsQuery.employerProducts$, employerProducts => {
                const products = employerProducts.filter(e => !e.isDefault)
                setState(state => ({
                    ...state,
                    products,
                }))
            }),
        ];

        const cb = () => setState(state => ({ ...state, isLoading: false }));
        const getStateCB = () => setState(state => ({ ...state, isStateLoading: false }))
        const employerProductsCB = () => setState(state => ({ ...state, isProductsLoading: false }));

        paymentPlansService.get(authQuery.getCurrentPracticeId(), null).subscribe(cb, cb);
        addressService.getStates().subscribe(getStateCB, getStateCB);   

        employerProductsService.getAllEmployerProducts().subscribe(employerProductsCB, employerProductsCB);

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };   
    }

    useEffect(useEffectCB, []);

    return [
        state,
        handleToggle,
        handleToggleLoginBehalf,
        handleToggleIntegration,
        handleToggleChangeInsuranceStates,
        getLoginOnBehalfOfUser,
        getAllIntegrationIds,
        handleAddInsuranceStates,
        handleRemoveInsuranceStates,
        handleRestoreSubscriptionPrice,
        handleResetPatientProducts,
        handleSubmit,
        handleChanges,
        handleChangesGeneric
    ];
}