import {IPaginationState} from "../models/pagination-state";

export const getAvailablePages = (state: IPaginationState) => {
    const maxPage = Math.ceil(state.totalCount / state.pageSize);

    if(maxPage === 1) {
        return [state.selectedPage];
    }

    if(state.selectedPage === 1) {
        return state.selectedPage + 1 === maxPage
            ? [state.selectedPage, state.selectedPage + 1]
            : [state.selectedPage, state.selectedPage + 1, state.selectedPage + 2];
    }

    if(state.selectedPage === maxPage) {
        return state.selectedPage - 1 === 1
            ? [state.selectedPage - 1, state.selectedPage]
            : [state.selectedPage - 2, state.selectedPage - 1, state.selectedPage];
    }

    return [state.selectedPage - 1, state.selectedPage, state.selectedPage + 1];
}
