import React from 'react';
import { AuthenticatedLayoutComponent } from "../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent";
import { TitlesService } from "../../../../constants/title.constants";
import { MenuItemTypes } from "../../../../models/menu.models";
import { Box } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { navigationService } from '../../../../services/navigation.service';
import { useHistory } from 'react-router';
import { SyncRecordReconcileComponent } from "../../components/syncRecordReconcileComponent/SyncRecordReconcileComponent";
import { commonUseStyles } from "../../../common/styles/common.styles";
import clsx from 'clsx';

export const SyncRecordReconcilePage: React.FC<any> = (props: any) => {
    const recordId = Number(props.match.params.recordId);

    const history = useHistory();
    const commonClasses = commonUseStyles();
    
    return (
        <AuthenticatedLayoutComponent
            title={TitlesService.syncRecordsTitle}
            selectedMenuItem={MenuItemTypes.SyncRecords}
            hideBackground
        >
            <Box id="sync-records-List-go-back" py={2} display="flex" alignItems="center" className={clsx(commonClasses.colorGray1, commonClasses.backgroundColorBg, commonClasses.pointer)} onClick={() => {navigationService.goBack(history)}}>
                <Box>
                    <ArrowBack fontSize="small"/>
                </Box>
                <Box ml={1}>
                    <span>Back</span>
                </Box>
            </Box>
            <Box className={commonClasses.bgWhiteMain} >
                <SyncRecordReconcileComponent recordId={recordId} />
            </Box>
            
        </AuthenticatedLayoutComponent>
    )
}