import React from 'react';
import { Box, FormControl, Grid, FormGroup, FormHelperText, useMediaQuery, useTheme } from "@material-ui/core";
import { QuestionComponentProps } from "./QuestionComponentProps";
import { CheckAnswer, emptyCheckAnswer } from "../../models/questionnaire.models";
import { CheckboxQuestionaris, FormControlLabelQuestionaris } from "./questionnaire.styles";
import CheckIcon from '@material-ui/icons/Check';

export const QuestionCheckComponentCastom: React.FC<QuestionComponentProps> = (props: QuestionComponentProps) => {
    const {
        question,
        answer,
        handleChanges,
        error
    } = props;
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    let checkAnswer: CheckAnswer;

    const deselectKeys = ['None', 'No', 'None of the above']

    try {
        checkAnswer = JSON.parse(answer);
        if (!checkAnswer) {
            checkAnswer = emptyCheckAnswer();
        }
    } catch (e) {
        checkAnswer = emptyCheckAnswer();
    }

    const isChecked = (variant: string) => {
        return checkAnswer.v.includes(variant);
    }

    const wrapResult = () => {
        return JSON.stringify(checkAnswer);
    }

    const handleCheck = (variant: string) => {
        if (deselectKeys.some(x => x === variant)) {
            checkAnswer.v = [];
        } else {
            checkAnswer.v = checkAnswer.v.filter(v => !deselectKeys.some(x => x === v));
        }

        isChecked(variant)
            ? checkAnswer.v.splice(checkAnswer.v.indexOf(variant), 1)
            : checkAnswer.v.push(variant);

        if (!checkAnswer.v.length) {
            handleChanges(null);
            return;
        }

        handleChanges(wrapResult());
    }
    return (
        <>
            <Box className={"check-question"}>
                <FormControl
                    fullWidth
                    color='primary'
                    error={!!error}
                    component="fieldset">
                    <FormHelperText>{error}</FormHelperText>
                    <FormGroup>
                        <Grid container spacing={isSmallScreen ? 1 : 3}>

                            {
                                question.variants.map((variant, i) => {
                                    return <Grid item md={6} sm={12} xs={12} key={i}>
                                        <FormControlLabelQuestionaris
                                            className={isChecked(variant) ? 'checked' : ''}
                                            key={i}
                                            control={
                                                <CheckboxQuestionaris
                                                    className="check-box"
                                                    checked={isChecked(variant)}
                                                    icon={<CheckIcon style={{ color: 'transparent' }} />}
                                                    checkedIcon={<CheckIcon />}
                                                    size="small"
                                                    name={variant}
                                                    key={variant}
                                                    onChange={(v) => handleCheck(v.target.name)}
                                                />
                                            }
                                            label={variant}
                                        />
                                    </Grid>
                                })
                            }
                        </Grid>
                    </FormGroup>
                </FormControl>
            </Box>
        </>
    )
}