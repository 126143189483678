import { QuestionnairesListComponent } from '../questionnairesListComponent/QuestionnairesListComponent'
import { QuestionnaireModel, QuestionnaireResultModel } from '../../models/questionnaire.models'
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton'
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress'
import { FollowUpFormQuestionNames } from '../../static/followUpForm.static'
import { useFacade } from './followUpFormSurveyComponent.hooks'
import { Box } from '@material-ui/core'
import React from 'react'

export interface FollowUpFormSurveyComponentProps {
    questionnaire: QuestionnaireModel;
    result: QuestionnaireResultModel;
    patientId?: number;
    handleGoBack?: Function;
}

export const FollowUpFormSurveyComponent: React.FC<FollowUpFormSurveyComponentProps> = (props: FollowUpFormSurveyComponentProps) => {
    const [
        {
            isLoading,
            questionnaire,
            result,
            errors,
        },
        handleChanges,
        handleSubmit
    ] = useFacade(props.result, props.questionnaire, props.patientId, props.handleGoBack);

    return isLoading
        ? <WildHealthLinearProgress />
        : <Box p={5}>
            <Box>
                {questionnaire.description}
            </Box>
            <Box mt={5}>
                <QuestionnairesListComponent
                    questions={[
                        FollowUpFormQuestionNames.REFLECT_LAST_VISIT
                    ]}
                    questionnaire={questionnaire}
                    handleChanges={handleChanges}
                    title='General Update:'
                    result={result}
                    errors={errors}
                />
            </Box>
            <Box mt={5}>
                <QuestionnairesListComponent
                    questions={[
                        FollowUpFormQuestionNames.RATE_OVERALL_PROGRESS,
                        FollowUpFormQuestionNames.CLARIFY_PREVIOUS_QUESTIONS
                    ]}
                    questionnaire={questionnaire}
                    handleChanges={handleChanges}
                    title='Goals & Interventions:'
                    result={result}
                    errors={errors}
                />
            </Box>
            <Box mt={5}>
                <QuestionnairesListComponent
                    questions={[
                        FollowUpFormQuestionNames.HOPING_UPCOMING_VISITS
                    ]}
                    questionnaire={questionnaire}
                    handleChanges={handleChanges}
                    title='Upcoming Visit:'
                    result={result}
                    errors={errors}
                />
            </Box>
            <Box mt={5}>
                <QuestionnairesListComponent
                    questions={[
                        FollowUpFormQuestionNames.RATE_SUCCESS_EXERCISE,
                        FollowUpFormQuestionNames.RATE_SUCCESS_NUTRITION,
                        FollowUpFormQuestionNames.RATE_SUCCESS_SLEEP,
                        FollowUpFormQuestionNames.RATE_SUCCESS_MINDFULNESS,
                        FollowUpFormQuestionNames.RATE_SUCCESS_SOCIAL_CONNECTION,
                    ]}
                    questionnaire={questionnaire}
                    handleChanges={handleChanges}
                    title='Checking in on your goals:'
                    result={result}
                    errors={errors}   
                />
            </Box>
            <Box mt={5}>
                <QuestionnairesListComponent
                    questions={[
                        FollowUpFormQuestionNames.YOUR_WEIGHT_BIOMETRICS,
                        FollowUpFormQuestionNames.YOUR_WAIST_CIRCUMFERENCE_BIOMETRICS,
                        FollowUpFormQuestionNames.CHECKING_BIOMETRICS_SYSTOLIC_BP,
                        FollowUpFormQuestionNames.CHECKING_BIOMETRICS_DIASTOLIC_BP
                    ]}
                    questionnaire={questionnaire}
                    handleChanges={handleChanges}
                    title='Checking in on your biometrics (optional):'
                    result={result}
                    errors={errors}             
                />
            </Box>

            <Box pt={5} display='flex' justifyContent='flex-end'>
                <Box>
                    <WildHealthButton id='follow-up-survey-submit' onClick={() => handleSubmit()}>
                        Submit
                    </WildHealthButton>
                </Box>
            </Box>
        </Box>
}
