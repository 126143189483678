import { HealthSummaryDataModel } from '../models/healthSummary.models'
import { healthSummaryStore, HealthSummaryStore } from '../stores'
import { authHeader } from '../../common/helpers/auth-header'
import { snackService } from '../../common/snack/state'
import Axios from 'axios-observable'
import { Observable } from 'rxjs'

export class HealthSummaryVitalsService {
    public mapKey = 'VITALS'

    private url = `${process.env.REACT_APP_API_URL}HealthSummary/vitals`

    constructor(private healthSummaryStore: HealthSummaryStore) {
    }

    /**
     * Returns patients health summary model
     */
    public get(patientId?: number): void {
        this.getVitals(patientId).subscribe((vitals) => {
            this.healthSummaryStore.sectionLoaded()
            this.healthSummaryStore.clearSection(this.mapKey)
            this.healthSummaryStore.setData(vitals)
        })
    }

    public getVitals(patientId?: number): Observable<any> {
        const url = this.url + (patientId ? `?patientId=${patientId}` : '')

        const config = { headers: authHeader() }

        return new Observable((observer) => {
            Axios.get<HealthSummaryDataModel[]>(url, config)
                .pipe()
                .subscribe(
                    (response) => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        })
    }

    public create(patientId: number, model: HealthSummaryDataModel): Observable<any> {
        return null
    }

    public update(patientId: number, model: HealthSummaryDataModel): Observable<any> {
        return null
    }

    public delete(key: string): void{
        return null
    }
}

export const healthSummaryVitalsService = new HealthSummaryVitalsService(healthSummaryStore)