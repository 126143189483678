import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemContent: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        width: "50vw",
      },
    },
    buttonTitle: {
      marginLeft: 8,
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        marginLeft: 0,
      },
    },
  })
);
