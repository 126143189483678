import { Box, Divider, Grid, Link, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import WildHealthLinearProgress from "../../../../../common/components/WildHealthLinearProgress";
import { useFacade } from "./ordersInfoWidgetComponent.hooks";
import { useStyles } from "./ordersInfoWidgetComponent.styles";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { CommonPlaceHolder } from "../../../../../common/components/wildHealthPlaceHolder/CommonPlaceHolder";


export interface OrdersInfoWidgetComponentProps {
  patientId: number | null;
}
interface OrderNameAndClass {
  name: string,
  class: string
}

export const OrdersInfoWidgetComponent: React.FC<OrdersInfoWidgetComponentProps> = (
  props: OrdersInfoWidgetComponentProps
) => {

  const [
    {
      isLoading
    },
    orders,
    handleGoToOrders
  ] = useFacade(props.patientId);

  const classes = useStyles();

  const getStatusColor = (status) => {
    switch (status) {
      case 'N/A':
        return classes.avatarNA;
      case 'Preparing':
      case 'Placed':
      case 'Ordered':
      case 'Shipping':
        return classes.avatarPreparing;
      case 'In Progress':
      case 'Pending':
      case 'Claim Processing':
      case 'Claim Pending Patient Payment':
        return classes.avatarOrange;
      case 'Failed':
      case 'Resulted or Failed':
      case 'Canceled':
        return classes.avatarDanger;
      default: return classes.avatarGood;
    }
  }

  const renderContent = () => {
    if (isLoading) {
      return <WildHealthLinearProgress />;
    }

    if (!orders.length) {
      return <Box >
        <CommonPlaceHolder message={`No orders yet.`} />
      </Box>
    }
    
    return (
      <Box mt={5}>
        <Grid container spacing={3}>
          {
            orders.map((x, index) => (
              <Fragment key={`${x.name}-${index}`}>
                <Grid item xs={8} sm={8} md={8} lg={8} key={index * index}>
                  <Box>
                    {x.name}
                  </Box>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} key={index * index * index}>
                  <Box display="flex" alignItems='center'>
                    <Box>
                      <Box className={getStatusColor(x.status)}></Box>
                    </Box>
                    <Box ml={2}>
                      {x.status}
                    </Box>
                  </Box>
                </Grid>
                <Divider className={classes.cardDivider}></Divider>
              </Fragment>
            ))
          }

        </Grid>
      </Box>
    );
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Box className={classes.title}>
          Orders
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Link
            id='SeeAllOrders'
            className={classes.historyLink}
            onClick={() => handleGoToOrders()}
          >
            <Typography className={classes.historyLinkText} display='inline'>
              <span> See All </span>
            </Typography>
            <ArrowForwardIcon color='inherit' fontSize='small' />
          </Link>
        </Box>
      </Box>
      <Box>
        {renderContent()}
      </Box>
    </Box >
  );
};
