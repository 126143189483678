import {Button, CircularProgress } from "@material-ui/core";
import React, {FC} from "react";
import commonUseStyles from "../../../styles/common.styles";
import {useStyles} from "../wildHealthButton.styles";

interface DownloadButtonProps {
    onClick?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    id: string;
    color?: 'primary' | 'secondary'
    style?: React.CSSProperties;
    disabled?: boolean;
    square?: boolean;
    width: string | number;
    height?: string | number;
    children?: any;
    loading?: boolean;
}

const DownloadButton: FC<DownloadButtonProps> = (props: DownloadButtonProps) => {

    const {
        id,
        loading = false,
        children,
        square: isSquareStyled = false,
        color = 'primary',
        onClick,
        width,
        height,
        disabled
    } = props;

    const classes = useStyles();
    const commonClasses = commonUseStyles();

    let style = {
        ...props.style
    }


    if (isSquareStyled) {
        style = {
            ...style,
            width: width, maxWidth: width, minWidth: width,
            maxHeight: width, minHeight: width,
            borderRadius: 0
        }
    }

    return (<Button id={id}
                    onClick={onClick}
                    color={color}
                    style={style}
                    disabled={disabled}>
                    {loading ? <CircularProgress size={24} className={commonClasses.colorMain} /> : children}
            </Button>)
}


export default DownloadButton