import React, { useState, useEffect } from 'react';
import { Box, IconButton } from "@material-ui/core";
import clsx from 'clsx';
import commonUseStyles from '../../../common/styles/common.styles';
import { AnswerModel, QuestionnaireModel } from '../../models/questionnaire.models';
import { QuestionComponent } from '../questionComponent/QuestionComponent';
import { Add } from '@material-ui/icons';
import { colors } from '../../../common/constants/colors';

export interface HideCommentQuestionComponentProps {
  questionName: string;
  questionnaire: QuestionnaireModel;
  handleChanges: (field: string, value: string) => void;
  answers: AnswerModel[];
  errors: any,
}

export const HideCommentQuestionComponent: React.FC<HideCommentQuestionComponentProps> = (props: HideCommentQuestionComponentProps) => {
  const {
    questionName,
    questionnaire,
    handleChanges,
    answers,
    errors
  } = props;

  const commonClasses = commonUseStyles();

  const [state, setState] = useState({
    showComment: false
  })

  const handleShowCommnet = () => {
    setState({ ...state, showComment: true })
  }

  const question = questionnaire.questions.find(x => x.name === questionName);

  useEffect(() => {
    const answer = answers.find(x => x.key === questionName)?.value;
    if (answer) {
      setState({ ...state, showComment: true })
    }
  }, []);

  return (
    <Box>
      {!state.showComment ? (
        <Box display="flex" justifyContent="flex-start" alignItems="center" mt={1}>
          <Box ml="-12px">
            <IconButton id="questionnaire-comment-show" data-testid="questionnaireCommentShowBtn" onClick={() => handleShowCommnet()}>
              <Add style={{ color: colors.main, backgroundColor: colors.gray3 }} />
            </IconButton>
          </Box>
          <Box>
            <span className={clsx(commonClasses.colorMain, commonClasses.textMedium, commonClasses.size14)}>Add Comment</span>
          </Box>
        </Box>
      ) : (
          <Box>
            {question && (
              <QuestionComponent
                disabled={false}
                inline={false}
                error={errors[questionName]}
                answer={answers?.find(x => x.key === questionName)?.value}
                question={question}
                handleChanges={(value) => handleChanges(questionName, value)}
              />
            )}
          </Box>
        )}
    </Box>
  )
}