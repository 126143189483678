import React from "react";
import { Box, Dialog, DialogContent, FormControl, TextField, IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close'
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { useFacade } from "./markLabOrderAsPaidDialog.hooks";


export const MarkLabOrderAsPaidDialog: React.FC = () => {

    const [
        {
            isOpen,
            canSubmit,
            reason
        },
        handleUpdateReason,
        handleClose,
        handleSubmit
    ] = useFacade();
    
    return (
        <>
            <Dialog maxWidth="md" open={isOpen} onClose={() => handleClose()} aria-labelledby="form-dialog-title">
                <DialogContent className="wh-tw-w-[548px] wh-tw-px-10 wh-tw-py-8">
                    <Box display='flex' justifyContent='space-between' alignItems='center'>
                        <Box>
                            <span className='wh-tw-text-2xl wh-tw-text-black wh-tw-font-medium'>Reason for Override</span>
                        </Box>
                        <Box display='flex' alignItems='center' position='absolute' right={5} top={5}>
                            <IconButton
                                id='add-device-dialog-close'
                                onClick={() => handleClose()}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box className="wh-tw-pt-8">
                        <Box className="wh-tw-text-black wh-tw-text-normal">
                            Please add a reason for the override below.
                        </Box>
                        <Box display="flex" justifyContent="between" className="wh-tw-pt-5">
                            <FormControl color='primary' fullWidth size="small" variant="outlined" className="drop-down">
                                <TextField
                                    id="lab-order-override-reason"
                                    multiline
                                    rows={4}
                                    placeholder="State a reason..."
                                    variant="outlined"
                                    value={reason}
                                    onChange={handleUpdateReason}
                                >

                                </TextField>
                            </FormControl>
                        </Box>
                    </Box>
                    <Box mt={5} display='flex' justifyContent='flex-end' alignItems='center'>
                        <Box mx={2}>
                            <WildHealthButton
                                id="lab-order-close"
                                color="tertiary"
                                size="medium"
                                onClick={() => handleClose()}
                            >
                                Close
                            </WildHealthButton>
                        </Box>
                        <Box>
                            <WildHealthButton
                                id="lab-order-override"
                                size="medium"
                                onClick={() => handleSubmit()}
                                disabled={!canSubmit}
                            >
                                Override
                            </WildHealthButton>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}