import { useState } from "react";
import { authQuery } from "../../../auth/stores/auth";
import { IPersonModel } from "../../../common/models/user.models";
import { employeeService } from "../../../employee/services/employees.service";
import { patientsService } from '../../../patients/services/patients.service';

interface CreateInternalConversationDialogComponentState {
    isPatientsLoading: boolean;
    isUsersLoading: boolean;
    canSubmit: boolean;
    patients: IPersonModel[];
    users: IPersonModel[];
    selectedPatient: IPersonModel;
    selectedUsersIds: number[];
}

const defaultState: CreateInternalConversationDialogComponentState = {
    isPatientsLoading: false,
    isUsersLoading: false,
    canSubmit: false,
    patients: [],
    users: [],
    selectedPatient: null,
    selectedUsersIds: [],
}

export function useFacade(handleSubmit: (patientId: number, employeeIds: number[]) => void, handleCancel: () => void): [CreateInternalConversationDialogComponentState,
    (id: number) => void,
    (usersIds: number[]) => void,
    (searchQuery: string) => void,
    (searchQuery: string) => void,
    () => void,
    () => void] {
    const [state, setState] = useState(defaultState);

    const handlePatientSelect = (id: number) => {
        const user = state.patients.find(i => i.id === id);
        if (user) {
            setState({...state, selectedPatient: user, canSubmit: state.selectedUsersIds?.length > 0});
        }
    }

    const handleUsersSelect = (usersIds: number[]) => {
        setState({...state,
            selectedUsersIds: usersIds,
            canSubmit: usersIds?.length > 0 && state.selectedPatient !== null
        });
    }

    const handlePatientSearch = (searchQuery: string) => {
        setState(state => ({...state, isPatientsLoading: true}));

        const successCB = (patients) => setState(state => ({...state, isPatientsLoading: false, patients: patients}));
        const errorCB = () => setState(state => ({...state, isPatientsLoading: false}));

        patientsService.getAll([],[],[],[],[],[], searchQuery, 0, 1000).subscribe(successCB, errorCB);
    }

    const handleUserSearch = (searchQuery: string) => {
        setState(state => ({...state, isUsersLoading: true}));

        const successCB = (employees) => setState(state => ({...state,
            isUsersLoading: false,
            users: employees.filter(i => i.email !== authQuery.getEmail())
        }));
        const errorCB = () => setState(state => ({...state, isUsersLoading: false}));

        employeeService.getActive(searchQuery).subscribe(successCB, errorCB);
    }

    const handleCloseDialog = () => {
        setState({...defaultState});
        handleCancel();
    }

    const handleApprove = () => {
        handleSubmit(state.selectedPatient.id, state.selectedUsersIds);
        handleCloseDialog();
    }

    return [state,
        handlePatientSelect,
        handleUsersSelect,
        handlePatientSearch,
        handleUserSearch,
        handleCloseDialog,
        handleApprove
    ];
}
