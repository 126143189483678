import React from 'react';
import { PaymentBenefitModel } from "../../models/paymentBenefit.models";
import { Box } from "@material-ui/core";
import { Check } from '@material-ui/icons';
import commonUseStyles from '../../../common/styles/common.styles';
import { colors } from '../../../common/constants/colors';

interface PaymentBenefitsComponentProps {
  benefits: PaymentBenefitModel[];
  planName: string;
}

export const PaymentBenefitsComponent: React.FC<PaymentBenefitsComponentProps> = (props: PaymentBenefitsComponentProps) => {
  const {
    benefits,
    planName
  } = props;

  const commonClasses = commonUseStyles();

  const renderBenefitText = (benefit: PaymentBenefitModel): JSX.Element => {
    const benefitTextItems = benefit.text.split("\n");

    const firstItem = benefitTextItems.shift();

    return (
      <Box>
        <Box>
          <div dangerouslySetInnerHTML={{ __html: firstItem }} />
        </Box>
        <Box mt={1} ml={2}>
          <ul style={{ listStyleType: 'disc', color: colors.gray1 }}>
            {
              benefitTextItems.map((item, index) => (
                <li key={index}>
                  {item}
                </li>
              ))
            }
          </ul>
        </Box>
      </Box>

    );
  }

  const renderOldContent = () => {
    return (<>
      <Box mt={3}>
        {
          benefits.map((benefit, index) => {
            return (
              <Box key={index} mt={1}>
                <Box display="flex" alignItems="start">
                  <Box>
                    <Check className={commonClasses.colorMain} />
                  </Box>
                  <Box ml={1}>
                    <span className={commonClasses.textSemiBold}>{benefit.title} </span>
                    {benefit.text && renderBenefitText(benefit)}
                  </Box>
                </Box>
              </Box>
            )
          })
        }
      </Box>
    </>
    )
  }

  return (
    <>
      <Box mt={3}>
        {(planName === "CORE" || planName === "ADVANCED") ? (
          <Box mt={1}>
            <Box display="flex" alignItems="start">
              <Box>
                <Check className={commonClasses.colorMain} />
              </Box>
              <Box ml={1}>
                <span className={commonClasses.textSemiBold}>In-Depth Genomic Analysis Included</span>
              </Box>
            </Box>
          </Box>
        ) : (
            <Box mt={1}>
              <Box display="flex" alignItems="start">
                <Box>
                  <Check className={commonClasses.colorMain} />
                </Box>
                <Box ml={1}>
                  <span className={commonClasses.textSemiBold}>Includes all features from advanced plan</span>
                </Box>
              </Box>
            </Box>
          )}
        {planName === "CORE" ? (
          <Box mt={1}>
            <Box display="flex" alignItems="start">
              <Box>
                <Check className={commonClasses.colorMain} />
              </Box>
              <Box ml={1}>
                <span className={commonClasses.textSemiBold}>
                  12 Months of Care With Up to 4 Health Coach & 1 MD
                </span>
              </Box>
            </Box>
          </Box>
        ) : (
            <Box mt={1}>
              <Box display="flex" alignItems="start">
                <Box>
                  <Check className={commonClasses.colorMain} />
                </Box>
                <Box ml={1}>
                  <span className={commonClasses.textSemiBold}>
                    12 Months of Care With Up to 4 MD & 12 Health Coach Visits
                  </span>
                </Box>
              </Box>
            </Box>
          )}
        {(planName === "CORE" || planName === "ADVANCED") && (
          <>
            <Box mt={1}>
              <Box display="flex" alignItems="start">
                <Box>
                  <Check className={commonClasses.colorMain} />
                </Box>
                <Box ml={1}>
                  <span className={commonClasses.textSemiBold}>
                    Personalized Health Score and Tracking
                  </span>
                </Box>
              </Box>
            </Box>
            <Box mt={1}>
              <Box display="flex" alignItems="start">
                <Box>
                  <Check className={commonClasses.colorMain} />
                </Box>
                <Box ml={1}>
                  <span className={commonClasses.textSemiBold}>
                    Unlimited Messaging
                  </span>
                </Box>
              </Box>
            </Box>
            {planName === "ADVANCED" && (
              <Box mt={1}>
                <Box display="flex" alignItems="start">
                  <Box>
                    <Check className={commonClasses.colorMain} />
                  </Box>
                  <Box ml={1}>
                    <span className={commonClasses.textSemiBold}>
                      Basic Data Analysis of Wearable Devices (Devices are Not Included)
                    </span>
                  </Box>
                </Box>
              </Box>
            )}
          </>
        )}
        {planName === "OPTIMIZATION" && (
          <>
            <Box mt={1}>
              <Box display="flex" alignItems="start">
                <Box>
                  <Check className={commonClasses.colorMain} />
                </Box>
                <Box ml={1}>
                  <span className={commonClasses.textSemiBold}>
                    Detailed Data Analysis of Wearable Devices (Devices Are Note Included)
                  </span>
                </Box>
              </Box>
            </Box>
            <Box mt={1}>
              <Box display="flex" alignItems="start">
                <Box>
                  <Check className={commonClasses.colorMain} />
                </Box>
                <Box ml={1}>
                  <span className={commonClasses.textSemiBold}>
                    Sleep Analysis & Optimizations
                  </span>
                </Box>
              </Box>
            </Box>
            <Box mt={1}>
              <Box display="flex" alignItems="start">
                <Box>
                  <Check className={commonClasses.colorMain} />
                </Box>
                <Box ml={1}>
                  <span className={commonClasses.textSemiBold}>
                    In Depth & Comprehensive 6 Months Specialty Tracks Focusing On:
                  </span>
                  <Box>
                    <Box mt={1} ml={2}>
                      <ul style={{ listStyleType: 'disc', color: colors.gray1, gap: 10 }}>
                        <li>Brain Optimization and Cognitive Performance</li>
                        <li>Mindfulness Boot Camp</li>
                        <li>Gut Optimization Program</li>
                        <li>Athletic Performance Program</li>
                        <li>Longevity and Health Span Optimization</li>
                      </ul>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  )
};