import { GeneralValidator } from "../../../common/validation/general-validator";
import {
    stringLengthValidationRule, checkManyQuestionValidationRule, maxLengthValidationRule, requiredValidationRule
} from "../../../common/validation/general-validation-rules";
import { InitialConsultNames } from "../../constants/initialConsult.constants";

export const createInitialConsultComponentValidator = new GeneralValidator({
    [InitialConsultNames.TOP_3_HEALTH_PRIORITIES]: [checkManyQuestionValidationRule],
    [InitialConsultNames.CURRENT_MEDICAL_CONDITIONS]: [stringLengthValidationRule(0, 500)],
    [InitialConsultNames.HEALTH_FEARS]: [checkManyQuestionValidationRule],
    [InitialConsultNames.SPECIFIC_DIET]: [stringLengthValidationRule(0, 500)],
    [InitialConsultNames.MEAL_CONSUMING_TIME_1]: [stringLengthValidationRule(0, 500)],
    [InitialConsultNames.MEAL_CONSUMING_TYPE_1]: [stringLengthValidationRule(0, 500)],
    [InitialConsultNames.MEAL_CONSUMING_TIME_2]: [stringLengthValidationRule(0, 500)],
    [InitialConsultNames.MEAL_CONSUMING_TYPE_2]: [stringLengthValidationRule(0, 500)],
    [InitialConsultNames.MEAL_CONSUMING_TIME_3]: [stringLengthValidationRule(0, 500)],
    [InitialConsultNames.MEAL_CONSUMING_TYPE_3]: [stringLengthValidationRule(0, 500)],
    [InitialConsultNames.LIST_OF_SNACKS]: [stringLengthValidationRule(0, 500)],
    [InitialConsultNames.STOP_CONSUMING_FOOD]: [stringLengthValidationRule(0, 500)],
    [InitialConsultNames.EXERCISE_FREQUENCY]: [stringLengthValidationRule(0, 500)],
    [InitialConsultNames.AVAILABLE_EQUIPMENT]: [stringLengthValidationRule(0, 500)],
    [InitialConsultNames.EXERCISE_TYPES]: [stringLengthValidationRule(0, 500)],
    [InitialConsultNames.ACTIVITIES_BEFORE_SLEEP]: [stringLengthValidationRule(0, 500)],
    [InitialConsultNames.FALL_ASLEEP_TIME]: [stringLengthValidationRule(0, 500)],
    [InitialConsultNames.WAKE_UP_TIME]: [stringLengthValidationRule(0, 500)],
    [InitialConsultNames.USE_ALARM_CLOCK]: [stringLengthValidationRule(0, 500)],
    [InitialConsultNames.FEEL_RESTED]: [stringLengthValidationRule(0, 500)],
    ['title']: [maxLengthValidationRule(40)],
    ['recommendations']: [requiredValidationRule()],
});