import {
    Box,
    Dialog,
    DialogContent,
    Grid,
    IconButton,
} from "@material-ui/core";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import React from 'react';
import WildHealthLinearProgress from '../../../../common/components/WildHealthLinearProgress';
import { WildHealthPlaceHolder } from "../../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { useFacade } from "./employerDetailDialogComponent.hooks";
import { useStyles } from "./employerDetailDialogComponent.styles";
import commonUseStyles from "../../../../common/styles/common.styles";
import { RoutesConstants } from "../../../../../constants/route.constants";

export interface EmployerDetailDialogComponentProps {
    employeeId: number;
    isOpen: boolean;
    handleToggleDialog: (status: boolean, employerId: number) => void;
}

export const EmployerDetailDialogComponent: React.FC<EmployerDetailDialogComponentProps> = (props: EmployerDetailDialogComponentProps) => {
    const { employeeId, isOpen, handleToggleDialog } = props;
    const classes = useStyles();
    const [
        { employer, isLoading }
    ] = useFacade(employeeId, isOpen);

    const commonClasses = commonUseStyles();

    const renderNoData = () => <WildHealthPlaceHolder height="100px" message="No data" />

    const renderDescription = (descriptions: string[]) => {
        if (descriptions && descriptions.length > 0) {
            return descriptions.map((description, index) => <Box pl={2} key={`description-${index}`} className={classes.contextStyle}>{description}</Box>)
        } else {
            return renderNoData();
        }
    }

    const renderInclusionsDescription = () => {
        if (employer.supportedPaymentPricesDescription && employer.supportedPaymentPricesDescription.length > 0) {
            return <Box pl={2} className={classes.contextStyle}>Inclusions are determined from supported PaymentPrices</Box>
        } else {
            return renderDescription(employer.inclusionsDescription);
        }
    }
    
    const renderContent = () => {
        if (isLoading) {
            return <WildHealthLinearProgress />
        }

        if (!isLoading && !employer) {
            return <WildHealthPlaceHolder message="No employer data" />
        }

        return (<>
            <Box mb={2} width={1} display="flex">
                <Box display="inline" width={'150px'}>
                    <span className={classes.sectionTitle}>Name:</span>
                </Box>
                <Box display="inline" className={classes.contextStyle}>{employer.name}</Box>
            </Box>
            <Box mb={2} width={1} display="flex">
                <Box display="inline" width={'150px'}>
                    <span className={classes.sectionTitle}>Key:</span>
                </Box>
                <Box display="inline" className={classes.contextStyle}>{employer.key}</Box>
            </Box>
            <Box mb={2} width={1}>
                <Box width={'150px'} mb={0.5}>
                    <span className={classes.sectionTitle}>Banner Image:</span>
                </Box>
                <Box display="flex" justifyContent="center">
                    {employer?.bannerCdnUrl ? <img className={classes.employerBanner} src={RoutesConstants.whBlobCdnBaseLink + employer?.bannerCdnUrl} alt="img" /> : renderNoData()}
                </Box>
            </Box>
            <Box mb={2} width={1}>
                <Box mb={0.5}>
                    <span className={classes.sectionTitle}>Supported Payment Price Ids:</span>
                </Box>
                {renderDescription(employer.supportedPaymentPricesDescription)}
            </Box>
            <Box mb={2} width={1}>
                <Box mb={0.5}>
                    <span className={classes.sectionTitle}>Inclusions:</span>
                </Box>
                {renderInclusionsDescription()}
            </Box>
            <Box mb={2} width={1}>
                <Box mb={0.5}>
                    <span className={classes.sectionTitle}>Discounts:</span>
                </Box>
                {renderDescription(employer.discountsDescription)}
            </Box>
        </>)
    }

    return (
        <>
            <Dialog
                onClose={() => handleToggleDialog(false, 0)}
                className={classes.root}
                open={isOpen}
                maxWidth="md"
            >
                <MuiDialogTitle
                    className={classes.dialogTitle}
                    disableTypography
                >
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <span className={clsx(commonClasses.size24, commonClasses.textMedium, commonClasses.colorBlack)}>Employer Information</span>
                    </Grid>
                    <IconButton
                        id="update-employee-edit-user"
                        className={classes.dialogTitleCloseAction}
                        onClick={() => handleToggleDialog(false, 0)}
                    >
                        <CloseIcon />
                    </IconButton>
                </MuiDialogTitle>

                <DialogContent dividers className={classes.dialogContent}>
                    <Box width="648px">
                        {renderContent()}
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
}
