import Axios from 'axios-observable';
import {Observable, Subject} from 'rxjs';
import {authHeader} from '../../common/helpers/auth-header';
import {snackService} from '../../common/snack/state';
import {
    AttachmentModel,
    DraftNoteModel, NoteDeletionReason,
    NoteModel,
    NotesType,
    SaveNoteModel,
    SaveNoteModelWithoutFile,
    SignOffNoteModel
} from '../models/notes.models';
import {NotesStore, notesStore} from '../stores/notes';
import {PaginationModel} from "../../common/pagination/models/pagination-models";
import { authQuery } from '../../auth/stores/auth';
import {CallbackModel} from "../../common/models/callback.model";
import {NonAppointmentsNoteTypes} from "../constants/nonAppointmentNoteTypes";
const FileDownload = require('js-file-download');

/**
 * Provides method for working with notes and identity
 */

export interface SignOffModel{
    patientId: number;
    noteType: NotesType;
}
export class NotesService {
    private url = `${process.env.REACT_APP_API_URL}Notes`;

    constructor(private store: NotesStore) {
    }

    private getConfig = () => ({headers: authHeader()});

    public onSignOff = new Subject<CallbackModel<SignOffModel>>();

    public openSignOff(model: SignOffModel): Observable<[number, string]> {
        return new Observable(observer => {
            this.onSignOff.next({
                data: model,
                callback: ([number, string]) => {
                    observer.next([number, string]);
                    observer.complete();
                }
            });
        });
    }

    public getById(id: number): Observable<NoteModel> {
        const url = `${this.url}/${id}`;

        return new Observable<NoteModel>(observer => {
            Axios.get(url, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getByEmployee(patientId: number): Observable<NoteModel[]> {
        const url = `${this.url}/employee?patientId=${patientId}`;

        return new Observable<NoteModel[]>(observer => {
            Axios.get(url, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        this.store.setNotes(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getAllDrafts(take: number,
                        skip: number,
                        sortingSource: string = null,
                        sortingDirection: string = null): Observable<PaginationModel<DraftNoteModel[]>> {
        const url = `${this.url}/employee/drafts?skip=${skip}&take=${take}&sortingSource=${sortingSource}&sortingDirection=${sortingDirection}`;

        return new Observable<PaginationModel<DraftNoteModel[]>>(observer => {
            Axios.get(url, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        this.store.updateDraftNotesWithSort(response.data.data,  sortingSource, sortingDirection );
                        this.store.update({
                            totalCount: response.data.totalCount
                        });
                        observer.next(response.data.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getNotesForReview(
        take: number,
        skip: number,
        noteTypes: NotesType[],
        sortingSource: string = null,
        sortingDirection: string = null): Observable<PaginationModel<NoteModel>> {

        const noteTypesQuery = noteTypes && noteTypes.length
            ? `&${noteTypes.map(type => `noteTypes=${type}`).join('&')}`
            : '';

        const url = `${this.url}/signOff/forReview?skip=${skip}&take=${take}${noteTypesQuery}&sortingSource=${sortingSource}&sortingDirection=${sortingDirection}`;

        return new Observable<PaginationModel<NoteModel>>(observer => {
            Axios.get(url, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        this.store.update({
                            notesForReview: response.data.data
                        });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getNotesUnderReview(
        take: number,
        skip: number,
        noteTypes: NotesType[],
        sortingSource: string = null,
        sortingDirection: string = null): Observable<PaginationModel<NoteModel>> {

        const noteTypesQuery = noteTypes && noteTypes.length
            ? `&${noteTypes.map(type => `noteTypes=${type}`).join('&')}`
            : '';

        const url = `${this.url}/signOff/underReview?skip=${skip}&take=${take}${noteTypesQuery}&sortingSource=${sortingSource}&sortingDirection=${sortingDirection}`;

        return new Observable<PaginationModel<NoteModel>>(observer => {
            Axios.get(url, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        this.store.update({
                            notesUnderReview: response.data.data
                        });
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getByPatient(): Observable<NoteModel[]> {
        const url = `${this.url}/patient`;

        return new Observable((observer) => {
            Axios.get<NoteModel[]>(url, this.getConfig())
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.setNotes(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getByIdForPatient(id: number): Observable<NoteModel> {
        const url = `${this.url}/patient/${id}`;

        return new Observable<NoteModel>(observer => {
            Axios.get(url, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        this.store.addOrUpdateNote(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                        observer.complete();
                    }
                );
        })
    }

    public getContentByEmployee(noteId: number, patientId: number, isOriginNoteForCompare = false): Observable<any> {
        const url = `${this.url}/content/employee?noteId=${noteId}&patientId=${patientId}`;

        return new Observable((observer) => {
            Axios.get(url, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        if (!isOriginNoteForCompare) {
                            this.store.update({targetContent: response.data});
                        }
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        });
    }

    public getContentByPatient(noteId: number): Observable<any> {
        const url = `${this.url}/content/patient?noteId=${noteId}`;

        return new Observable((observer) => {
            Axios.get(url, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        this.store.update({targetContent: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        });
    }

    public saveFiles(attachments:AttachmentModel[]):Observable<any>{
        const url = `${this.url}/saveFiles`
        
        if(!attachments)
        {
            return;
        }        
            
        const formData = new FormData();
        attachments.forEach((item,index) => {
            if(item.id){
                formData.append(`attachmentsForNote[${index}].attachmentId`, item.id?.toString());
            }

            formData.append(`attachmentsForNote[${index}].attachment`, item.file);

            if(item.noteId){
                formData.append(`attachmentsForNote[${index}].noteId`, item.noteId?.toString());
            }

            if(item.ForDelete){
                formData.append(`attachmentsForNote[${index}].forDelete`, item.ForDelete?.toString());
            }
        });

        return new Observable((observer) => {
            Axios.post(url, formData, {headers: authHeader()}).pipe()
            .subscribe(
                response =>{
                    observer.next(response.data);
                    observer.complete();
                },
                () =>{
                    observer.error();
                    observer.complete();
                }
            )
        });
    }

    public uploadNote (note:any, noteId: number, isEmployee: boolean):Observable<any>{
        const url = `${this.url}/pdf/${noteId}?withAmendments=${isEmployee}`       
            
        const formData = new FormData();
        formData.append(`notePdf`, note);

        return new Observable((observer) => {
            Axios.post(url, formData, {headers: authHeader()}).pipe()
            .subscribe(
                response =>{
                    observer.next(response.data);
                    observer.complete();
                },
                (error) =>{
                    snackService.commonErrorHandler(error)
                    observer.complete();
                }
            )
        });
    }

    public downloadNote(noteId: number, name: string, isEmployee: boolean): Observable<any> {
        const url = `${this.url}/pdf/${noteId}?withAmendments=${isEmployee}`;
        return new Observable(observer => {

            Axios.get(url, {
                headers: authHeader(),
                responseType: 'blob',
            })
                .pipe()
                .subscribe(
                    (response) => {
                        const newFile = new Blob([response.data], { type: 'application/pdf' });
                        const url = URL.createObjectURL(newFile);
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        a.download = name;
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        observer.next();
                        observer.complete();
                    },
                    () => {
                        observer.error()
                        observer.complete();
                    }
                );
        });
    }


    /**
     * Downloads uploaded file
     */
    public download(id: number, name: string): Observable<any> {
        const url = `${this.url}/Document/${id}/download`;
        return new Observable(observer => {

            Axios.get(url, {
                headers: authHeader(),
                responseType: 'blob',
            })
                .pipe()
                .subscribe(
                    (response) => {
                        FileDownload(response.data, decodeURIComponent(name));
                        snackService.success('File successfully downloaded!');
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }

    public saveAsDraft(data: SaveNoteModel, isPremium: boolean): Observable<NoteModel> {
        if (!data.id) {
            if(!isPremium && !data.appointmentId && authQuery.isWildHealthPractice() && !NonAppointmentsNoteTypes.includes(data.type) && data.originalNoteId == null){
                return new Observable((observer) => {
                    observer.error();
                    observer.complete();
                });
            }
        }

        const url = `${this.url}/draft`;

        const result = {
            id:data.id,
            appointmentId:data.appointmentId,
            content:data.content,
            internalContent: data.internalContent,
            logs: data.logs,
            name: data.name,
            nextCoachAppointmentDate: data.nextCoachAppointmentDate,
            nextProviderAppointmentDate: data.nextProviderAppointmentDate,
            patientId: data.patientId,
            title: data.title,
            type: data.type,
            visitDate: data.visitDate,
            originalNoteId: data.originalNoteId
        } as SaveNoteModelWithoutFile;

        return new Observable((observer) => {
            Axios.post<NoteModel>(url, result, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        this.store.addOrUpdateNote(response.data);

                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        observer.next(error);
                        observer.error();
                        observer.complete();
                    }
                );
        });
    }

    public saveAsCompleted(data: SaveNoteModel): Observable<NoteModel> {
        const url = `${this.url}/completed`;
        return new Observable((observer) => {
            Axios.post(url, data, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        this.store.addOrUpdateNote(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        });
    }

    public complete(id: number): Observable<NoteModel> {
        const url = `${this.url}/complete/${id}`;
        return new Observable((observer) => {
            Axios.put(url, null, this.getConfig()).pipe()
                .subscribe(
                    response => {
                        this.store.addOrUpdateNote(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        });
    }

    public delete(note: NoteModel, reason?: NoteDeletionReason) {
        const url = `${this.url}/${note.id}?reason=${reason ?? NoteDeletionReason.Other}`;
        return new Observable((observer) => {
            Axios.delete(url, this.getConfig()).pipe()
                .subscribe(
                    () => {
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        });
    }

    public deleteDraft(note: DraftNoteModel) {
        const url = `${this.url}/${note.id}`;
        return new Observable((observer) => {
            Axios.delete(url, this.getConfig()).pipe()
                .subscribe(
                    () => {
                        this.store.deleteDraft(note);
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        });
    }
    
    public signOff(model: SignOffNoteModel): Observable<NoteModel> {
        const url = `${this.url}/signOff`;
        return new Observable((observer) => {
            Axios.put<NoteModel>(url, model, this.getConfig()).pipe()
                .subscribe(
                    () => {
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        });
    }

    public cancelSignOff(id: number): Observable<NoteModel> {
        const url = `${this.url}/signOff/${id}`;
        return new Observable((observer) => {
            Axios.delete<NoteModel>(url, this.getConfig()).pipe()
                .subscribe(
                    () => {
                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        });
    }

    public getNotificationAwaitingApproval(): Observable<any> {
        return new Observable(observer => {
            Axios.get(`${this.url}/signOff/underReview/any`, { headers: authHeader() })
                .pipe()
                .subscribe(
                    (response) => {
                        this.store.update({
                            notificationAwaitingApproval: response.data,
                        });

                        observer.next();
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);

                        observer.error(error);
                        observer.complete();
                    }
                );
        });
    }
}

export const notesService = new NotesService(notesStore);
