import {
    Avatar,
    Box,
    Button,
    Collapse,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Popover,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableSortLabel,
    Tooltip
} from '@material-ui/core';
import moment from "moment";
import React from 'react';
import { RoutesConstants } from '../../../../constants/route.constants';
import { SearchComponent } from '../../../common/components/searchComponent/SearchComponent';
import { WildHealthContainer } from '../../../common/components/wildHealthContainer/WildHealthContainer';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { WildHealthLink, WildHealthLinkButton } from '../../../common/components/WildHealthLinkButton';
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { useFacade } from './manageIntakePatientsComponent.hooks';
import { useStyles } from "./manageIntakePatientsComponent.styles";
import './manageIntakePatientsComponent.scss';
import { toCurrentTimeZone } from '../../../timezones/helpers/timezone';
import { EmployeeType } from '../../models/employee.enums';
import { displayLocation } from '../../../locations/helpers/displayLocation';
import { authQuery } from '../../../auth/stores/auth';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from 'clsx';
import { displayShortName } from '../../../conversations/helpers/messages.converter';

const filtersPopoverProps: any = {
    id: "notificationsPopoverIntakePatients",
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
    },
    transformOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
    },
};

export const ManageIntakePatientsComponent: React.FC = () => {
    const [
        {
            isLoading,
            patients,
            filtersState,
            sortingColumns,
            sortingSource,
        },
        handleToggleFilters,
        handleToggleAddOns,
        handleToggleState,
        handleTogglePlans,
        handleApplyFilters,
        handleSearch,
        handleSearchState,
        handleSorting,
        handleOnPaymentChanges,
        handleClearFilters,
        handleOnStateChanges,
        handleOnAddOnsChanges,
        handleSearchPlan,
        handleSelectAllState,
        handleSelectAllAddOns,
    ] = useFacade();

    const classes = useStyles();
    const commonClasses = commonUseStyles();

    const cutLongText = (text: string): string => {
        return text.length > 20 ? `${text.slice(0, 20)}...` : text
    }

    const emptyPlaceholder = () => {
        return <span style={{ color: '#999999' }}>-</span>
    }

    const emptyAvaPlaceholder = () => {
        return <Box className={classes.avatarEmpty}>-</Box>
    }

    const renderEmployeesName = (employees) => {
        return employees.map(x => `${x.firstName} ${x.lastName}`).join(', ')
    }

    const renderAvatar = (employees, type: number) => {
        if (employees.length === 1) {
            return <Tooltip placement="top" arrow title={`${employees[0].firstName} ${employees[0].lastName}`}>
                <Avatar variant='circle' src={''} className={type === EmployeeType.HealthCoach ? classes.avatarCoaches : classes.avatarProvider}>
                    {displayShortName(employees[0].firstName, employees[0].lastName)}
                </Avatar>
            </Tooltip>
        }

        if (employees.length > 1) {
            return <Tooltip placement="top" arrow title={renderEmployeesName(employees)}>
                <Avatar variant='circle' src={''} className={type === EmployeeType.HealthCoach ? classes.avatarCoaches : classes.avatarProvider}>
                    {employees.length}
                </Avatar>
            </Tooltip>
        }

        return emptyAvaPlaceholder();
    }

    const renderTable = () => {
        if (isLoading) {
            return <WildHealthLinearProgress />
        }

        if (!patients || !patients.length) {
            return <WildHealthPlaceHolder message="No current records found" />
        }

        return (
            <TableContainer>
                <Table aria-label="customized table">
                    <TableHead>
                        <WildHealthTableRow>
                            {
                                sortingColumns.map((item, index) =>
                                    <WildHealthTableCell key={index} style={{ width: '20%' }} align="left">
                                        {
                                            item.source
                                                ?
                                                <TableSortLabel
                                                    id={`manage-intake-patients-${item.title}`}
                                                    active={sortingSource === item.source}
                                                    direction={sortingSource === item.source ? item.direction : 'asc'}
                                                    onClick={() => handleSorting(item.source, item.direction)}
                                                >
                                                    {item.title}
                                                </TableSortLabel>
                                                : item.title

                                        }
                                    </WildHealthTableCell>
                                )
                            }
                        </WildHealthTableRow>
                    </TableHead>
                    <TableBody>
                        {
                            patients.map((patient, index) =>
                                <WildHealthTableRow key={index}>
                                    <WildHealthTableCell>
                                        <WildHealthLink
                                            id={`manage-intake-patients-${patient.id}`}
                                            href={`${RoutesConstants.submitPatient}/${patient.id}`}
                                            content={patient.name}
                                        />
                                    </WildHealthTableCell>
                                    <WildHealthTableCell> {moment(toCurrentTimeZone(new Date(patient.joinedPractice))).format('MM/DD/YYYY')}</WildHealthTableCell>
                                    {!authQuery.isLicensingPractice() &&
                                        <WildHealthTableCell>
                                            {
                                                patient.plan ?? emptyPlaceholder()
                                            }
                                        </WildHealthTableCell>
                                    }
                                    <WildHealthTableCell>
                                        {renderAvatar(patient.providers, EmployeeType.Provider)}
                                    </WildHealthTableCell>
                                    <WildHealthTableCell>
                                        {renderAvatar(patient.coaches, EmployeeType.HealthCoach)}
                                    </WildHealthTableCell>
                                    <Tooltip placement="top" arrow title={patient.orderTypes.join(', ')}>
                                        <WildHealthTableCell> {patient.orderTypes.length ? cutLongText(patient.orderTypes.join(', ')) : emptyPlaceholder()} </WildHealthTableCell>
                                    </Tooltip>
                                    {!authQuery.isLicensingPractice() &&
                                        <WildHealthTableCell> {patient.paymentPrice && patient.paymentPrice.paymentCoupon ? patient.paymentPrice.paymentCoupon.code : emptyPlaceholder()} </WildHealthTableCell>
                                    }
                                    <WildHealthTableCell> {patient.shippingAddress ? patient.shippingAddress.state : emptyPlaceholder()} </WildHealthTableCell>
                                    <WildHealthTableCell> {patient.location ? displayLocation(patient.location) : emptyPlaceholder()} </WildHealthTableCell>
                                </WildHealthTableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    return (
        <>
            <Box my={2} display="flex" justifyContent="space-between">

            </Box>
            <Box my={2} display="flex" justifyContent="space-between">
                <Box width={420}>
                    <SearchComponent handleSearch={handleSearch} placeholder="Search by Name, ID or Email" />
                </Box>

                <Button
                    id="manage-intake-patients-filter"
                    aria-describedby={filtersPopoverProps.id}
                    onClick={(event) => handleToggleFilters(event)}
                    startIcon={<FilterListIcon />}
                    className={commonClasses.whButton}
                >
                    Filter
                </Button>
            </Box>
            <WildHealthContainer margin={0} minHeight={'100%'}>
                {renderTable()}
            </WildHealthContainer>

            <Popover
                id={filtersPopoverProps.id}
                anchorEl={filtersState.anchorEl}
                onClose={() => handleToggleFilters()}
                open={filtersState.isOpen}
                anchorOrigin={filtersPopoverProps.anchorOrigin}
                transformOrigin={filtersPopoverProps.transformOrigin}
            >
                <Box width="365px">
                    <List className={clsx(classes.filters, 'flat-scroll')}>

                        <ListItem id="manage-intake-patients-add-ons" button onClick={handleToggleAddOns}>
                            <ListItemText primary={<span className="text-medium">Add-Ons</span>} />
                            {filtersState.isAddOnsOpen ? <ExpandLess className={classes.activeIcon} /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={filtersState.isAddOnsOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem
                                    id={`manage-intake-patients-add-ons-select-all`}
                                    key="manage-intake-patients-add-ons-select-all" button className={classes.nested}
                                    onClick={() => handleSelectAllAddOns()}
                                >
                                    <ListItemIcon>
                                        {filtersState.isAllAddOnsSelected ? <CheckBoxIcon className={classes.activeIcon} /> : <CheckBoxOutlineBlankIcon />}
                                    </ListItemIcon>
                                    <ListItemText primary='Select All' />
                                </ListItem>
                                {
                                    filtersState.filters.addOns.map((item, index) =>
                                        <ListItem
                                            id={`manage-intake-patients-pod-${index}`}
                                            key={index} button className={classes.nested}
                                            onClick={() => handleOnAddOnsChanges(item.name, !item.isSelected)}
                                        >
                                            <ListItemIcon>
                                                {item.isSelected ? <CheckBoxIcon className={classes.activeIcon} /> : <CheckBoxOutlineBlankIcon />}
                                            </ListItemIcon>
                                            <ListItemText primary={item.name} />
                                        </ListItem>
                                    )
                                }
                            </List>
                        </Collapse>

                        <ListItem id="manage-intake-patients-state" className={classes.divider} button onClick={handleToggleState}>
                            <ListItemText primary={<span className="text-medium">State</span>} />
                            {filtersState.isStateOpen ? <ExpandLess className={classes.activeIcon} /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={filtersState.isStateOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem>
                                    <Box >
                                        <SearchComponent handleSearch={handleSearchState} handleChanges={handleSearchState} placeholder="Search by state" />
                                    </Box>
                                </ListItem>
                                <ListItem
                                    id={`manage-intake-patients-state-select-all`}
                                    key="manage-intake-patients-state-select-all" button className={classes.nested}
                                    onClick={() => handleSelectAllState()}
                                >
                                    <ListItemIcon>
                                        {filtersState.isAllStateSelected ? <CheckBoxIcon className={classes.activeIcon} /> : <CheckBoxOutlineBlankIcon />}
                                    </ListItemIcon>
                                    <ListItemText primary='Select All' />
                                </ListItem>
                                {
                                    filtersState.filters.state.map((item, index) =>
                                        <ListItem
                                            id={`manage-intake-patients-pod-${item.name}`}
                                            key={index} button className={classes.nested}
                                            onClick={() => handleOnStateChanges(item.name, !item.isSelected)}
                                        >
                                            <ListItemIcon>
                                                {item.isSelected ? <CheckBoxIcon className={classes.activeIcon} /> : <CheckBoxOutlineBlankIcon />}
                                            </ListItemIcon>
                                            <ListItemText primary={item.name} />
                                        </ListItem>
                                    )
                                }
                            </List>
                        </Collapse>
                        {!authQuery.isLicensingPractice() &&
                            <>
                                <ListItem id="manage-intake-patients-plans" className={authQuery.isLicensingPractice() ? '' : classes.divider} button onClick={handleTogglePlans}>
                                    <ListItemText primary={<span className="text-medium">Plans</span>} />
                                    {filtersState.isPlansOpen ? <ExpandLess className={classes.activeIcon} /> : <ExpandMore />}
                                </ListItem>
                                <Collapse in={filtersState.isPlansOpen} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItem>
                                            <Box >
                                                <SearchComponent handleSearch={handleSearchPlan} handleChanges={handleSearchPlan} placeholder="Search by plan" />
                                            </Box>
                                        </ListItem>
                                        {
                                            filtersState.filters.paymentPlans.map((item, index) =>
                                                <ListItem
                                                    id="manage-intake-patients-on-payment"
                                                    key={index} button className={classes.nested}
                                                    onClick={() => handleOnPaymentChanges(item.periodId, !item.isSelected)}
                                                >
                                                    <ListItemIcon>
                                                        {item.isSelected ? <CheckBoxIcon className={classes.activeIcon} /> : <CheckBoxOutlineBlankIcon />}
                                                    </ListItemIcon>
                                                    <ListItemText primary={item.name} />
                                                </ListItem>
                                            )
                                        }
                                    </List>
                                </Collapse>
                            </>
                        }
                    </List>
                    <Box p={2} display="flex" justifyContent="space-between" alignItems='center' width="100%">
                        <WildHealthLinkButton
                            onClick={() => handleClearFilters()}
                            content={<Box display="flex" alignItems='center'> <CloseIcon className={classes.closeIcon} />  <span className={classes.activeIcon}> Clear All </span></Box>}
                            id="manage-intake-patients-clear-all"
                        />

                        <WildHealthButton
                            id="manage-intake-patients-apply-filter"
                            onClick={() => handleApplyFilters()}
                        >
                            Apply filter
                        </WildHealthButton>
                    </Box>
                </Box>
            </Popover>
        </>
    )
}