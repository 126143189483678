import {selectShortcutService} from "../../healthReport/services/selectShortcut.service";
import {insertString} from "./insert-string";

export const handleShortcutInsert = (event: any, handleChanges: Function) => {
    if (event.keyCode === 51 && event.altKey) {
        const value = (document.getElementById(event.target.id) as any).value;
        const cursorPos = (document.getElementById(event.target.id) as any).selectionStart;
        setTimeout(() => {
            selectShortcutService.select().subscribe((data) => {
                data && handleChanges(insertString(value, data, cursorPos + 1))
            })
        }, 1)
    }

    event.stopPropagation();
}