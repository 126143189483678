import { Box, Checkbox, Divider, Grid, TextField, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import { WildHealthRadio } from "../../../common/components/WildHealthRadio";
import { MdmCategoryModel, MdmMode, MdmModel, SelectedMdmCategoryItemModel } from "../../models/notes.models";
import { useStyles } from "./MdmComponent.styles";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { colors } from '../../../common/constants/colors';
import commonUseStyles from "../../../common/styles/common.styles";


export interface MdmComponentProps {
    mdm: MdmModel;
    selectedCodeId: number;
    showCodeDescription: boolean | null;
    selectedCategoryIds: number[];
    selectedCategoryItems: SelectedMdmCategoryItemModel[];
    selectedMode: MdmMode;
    spentTime: number;
    appointmentDuration: number;
    handleCodeChange: (id: number, handleCodeChange: boolean) => void;
    handleCategoryChange: (ids: number[]) => void;
    handleCategoryItemChange: (id: number, inputValue: string) => void;
    handleModeChange: (mode: MdmMode) => void;
    handleSpentTimeChanges: (time: number) => void;
}

export const MdmComponent: React.FC<MdmComponentProps> = (props: MdmComponentProps) => {
    const {
        mdm,
        selectedCodeId,
        showCodeDescription,
        selectedCategoryIds,
        selectedCategoryItems,
        selectedMode,
        spentTime,
        appointmentDuration,
        handleCodeChange,
        handleCategoryChange,
        handleCategoryItemChange,
        handleModeChange,
        handleSpentTimeChanges
    } = props;

    const classes = useStyles();
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const controlIcon = <CheckBoxOutlineBlankIcon style={{ fill: colors.mediumGray }} />;
    const controlCheckedIcon = <CheckBoxIcon style={{ fill: colors.main }} />

    const disabled = mdm.id !== selectedCodeId;
    const mdmDisabled = disabled || selectedMode === MdmMode.TimeBased;
    const timeBasedDisable = disabled || selectedMode === MdmMode.Mdm;

    const mdmCountText = ['zero', 'one', 'two', 'three', 'four'];

    const handleCategoryItemSelect = (id: number, inputValue: string, category: MdmCategoryModel) => {
        const selectedCount = selectedCategoryItems.filter(x => category.items.some(t => t.id === x.id)).length;
        if (selectedCount < category.itemsToSelectCount || selectedCategoryItems.some(x => x.id === id)) {
            handleCategoryItemChange(id, inputValue);
        }
    }

    const handleCategorySelect = (category: MdmCategoryModel) => {
        let categoryIds = selectedCategoryIds.slice();

        if (selectedCategoryIds.some(x => x === category.id)) {
            categoryIds = categoryIds.filter(x => x !== category.id);
        } else {
            if (mdm.categoriesToComplete > 1) {
                if (categoryIds.length >= mdm.categoriesToComplete) {
                    return;
                }

                categoryIds.push(category.id);
            } else {
                categoryIds = [category.id]
            }
        }

        handleCategoryChange(categoryIds);

        if (category.items.length === 1) {
            handleCategoryItemChange(category.items[0].id, null);
        }
    }

    const renderCategoryOption = (category) => {
        if (mdm.categoriesToComplete > 1) {
            return (
                <Checkbox
                    disabled={mdmDisabled}
                    icon={controlIcon}
                    color="primary"
                    checkedIcon={controlCheckedIcon}
                    checked={selectedCategoryIds.some(x => x === category.id)}
                    onChange={() => handleCategorySelect(category)}
                    value={category.id}
                />
            )
        }

        return (
            <WildHealthRadio
                disabled={mdmDisabled}
                checked={selectedCategoryIds.some(x => x === category.id)}
                onChange={() => handleCategorySelect(category)}
                value={category.id}
            />
        )
    }

    return (
        <Box display={!isSmallScreen && 'flex'}>
            <Box display='flex' alignItems='flex-start' pr={1}>
                <Box display='flex' alignItems='center'>
                    <Box>
                        <WildHealthRadio
                            checked={mdm.id === selectedCodeId}
                            onChange={() => handleCodeChange(mdm.id, mdm.employeeOnly ? true : showCodeDescription)}
                            value={selectedCodeId}
                        />
                    </Box>
                    <Box fontWeight={500} minWidth='62px' display='flex' justifyContent="space-between">
                        <span>{mdm.code}</span>
                        {mdm.employeeOnly && <span className={commonClasses.colorBad}>*</span>}
                    </Box>
                </Box>
            </Box>
            <Box className={classes.timeSection}>
                {mdm.employeeOnly
                    ?
                    <Box p={2} width='100%'display='flex' flexDirection='column' className={classes.mdmSection}>
                        <Box display='flex' alignItems='center'>
                            <Box display='flex'>
                                <span className={commonClasses.textBold}>{mdm.title}</span>
                                <Box pl={0.5} className={commonClasses.colorGray1}>(For Patients who are Wild Health Employees only)</Box>
                            </Box>
                        </Box>
                        <Box mt={2} display="flex" alignItems="center">
                            <Box>
                                <span>
                                    {mdm.description}
                                </span>
                            </Box>
                        </Box>
                    </Box>

                    : <Grid container>
                        <Grid xs={12} md={7} item>
                            <Box p={2} className={classes.mdmSection}>
                                <Box display='flex' alignItems='center'>
                                    <Box>
                                        <WildHealthRadio
                                            disabled={disabled}
                                            checked={selectedMode === MdmMode.Mdm}
                                            onChange={() => handleModeChange(MdmMode.Mdm)}
                                            value={selectedMode}
                                        />
                                    </Box>
                                    <Box ml={2}>
                                        <span className={commonClasses.textBold}>{mdm.title}</span>
                                    </Box>
                                </Box>
                                <Box mt={2} display="flex" alignItems="center">

                                    <Box mr={2}>
                                        <Checkbox
                                            icon={controlIcon}
                                            color="primary"
                                            checkedIcon={controlCheckedIcon}
                                            checked={mdm.id === selectedCodeId && (showCodeDescription ?? false)}
                                            onChange={() => handleCodeChange(mdm.id, !showCodeDescription)}
                                        />
                                    </Box>

                                    <Box>
                                        <span>
                                            {mdm.description}
                                        </span>
                                    </Box>
                                </Box>
                                <Box mt={2}>
                                    <Divider />
                                </Box>
                                <Box mt={2}>
                                    <Box>
                                        <span>Data review - complete {mdmCountText[mdm.categoriesToComplete]} of the following {mdm.categories.length} categories:</span>
                                    </Box>
                                    {
                                        mdm.categories.map((category) => (
                                            <>
                                                <Box mt={2} display='flex' alignItems='center'>
                                                    <Box>
                                                        {
                                                            renderCategoryOption(category)
                                                        }
                                                    </Box>
                                                    <Box ml={2} fontWeight={500}>
                                                        <span>{category.name}</span>
                                                    </Box>
                                                </Box>
                                                {
                                                    selectedCategoryIds.some(x => x === category.id) &&
                                                    <Box ml={3} mt={2}>
                                                        {
                                                            category.items.length > 1 &&
                                                            <Box>
                                                                <span>Select {category.itemsToSelectCount} of the following:</span>
                                                            </Box>
                                                        }

                                                        <Box mt={2}>
                                                            {
                                                                category.items.sort(x => x.order).map((item) => (
                                                                    <Box display='flex' alignItems='center'>
                                                                        {
                                                                            category.items.length > 1 &&
                                                                            <Box>
                                                                                <Checkbox
                                                                                    disabled={mdmDisabled}
                                                                                    icon={controlIcon}
                                                                                    color="primary"
                                                                                    checkedIcon={controlCheckedIcon}
                                                                                    checked={selectedCategoryItems.some(x => x.id === item.id)}
                                                                                    onChange={() => handleCategoryItemSelect(item.id, null, category)}
                                                                                />
                                                                            </Box>
                                                                        }
                                                                        <Box ml={2} display='flex' alignItems='center'>
                                                                            <Box>
                                                                                <span>{item.description}</span>
                                                                            </Box>
                                                                            {
                                                                                item.allowInput &&
                                                                                <Box ml={1}>
                                                                                    <TextField
                                                                                        disabled={!selectedCategoryItems.some(x => x.id === item.id)}
                                                                                        type='text'
                                                                                        size="small"
                                                                                        variant="outlined"
                                                                                        value={selectedCategoryItems.some(x => x.id === item.id) ? selectedCategoryItems.find(x => x.id === item.id).inputValue : ''}
                                                                                        InputProps={{ className: 'input' }}
                                                                                        onChange={(e) => handleCategoryItemChange(item.id, e.target.value)}
                                                                                    />
                                                                                </Box>
                                                                            }

                                                                        </Box>
                                                                    </Box>
                                                                ))
                                                            }
                                                        </Box>
                                                    </Box>
                                                }
                                            </>
                                        ))
                                    }
                                </Box>
                                <Box mt={2}>
                                    <span>{mdm.riskDescription}</span>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid xs={12} md={4} item>
                            <Box p={2} className={classes.timeSection}>
                                <Box display='flex' alignItems='center'>
                                    <Box>
                                        <WildHealthRadio
                                            disabled={disabled}
                                            checked={selectedMode === MdmMode.TimeBased}
                                            onChange={() => handleModeChange(MdmMode.TimeBased)}
                                            value={selectedMode}
                                        />
                                    </Box>
                                    <Box ml={2} fontWeight={500}>
                                        <span>Time Based Billing</span>
                                    </Box>
                                </Box>
                                <Box mt={2}>
                                    <Box display='flex' alignItems='center' flexWrap="wrap">
                                        <span>I spent </span>
                                        <Box px={1}>
                                            <TextField
                                                disabled={timeBasedDisable}
                                                className={classes.timeInput}
                                                type='number'
                                                size="small"
                                                variant="outlined"
                                                value={timeBasedDisable ? '' : spentTime === 0 ? '' : spentTime}
                                                InputProps={{ className: 'input' }}
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(e) => handleSpentTimeChanges(+e.target.value)}
                                            />
                                        </Box>
                                        <span>minutes reviewing results, </span>
                                    </Box>
                                    <Box>
                                        <span> updating plan and treatments, or consulting another provider.</span>
                                    </Box>
                                </Box>
                                <Box mt={2}>
                                    <span className={classes.recommendedTime}><span className={commonClasses.colorBad}>*</span>{mdm.minTime} - {mdm.maxTime} mins required for this level.</span>
                                </Box>
                                <Box mt={2}>
                                    <pre>
                                        Total time on DOS: <span className={commonClasses.textMedium}>{timeBasedDisable ? 0 : appointmentDuration + spentTime} minutes</span>
                                    </pre>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                }
            </Box>
        </Box>
    );
}
