import { Box, FormControl, MenuItem, Select, RadioGroup, FormControlLabel } from '@material-ui/core';
import React from 'react';
import { useStyles } from './ItemsListComponent.styles';
import { WildHealthCheckbox } from '../WildHealthCheckbox';
import { ApoeModel, YesNo } from '../../../inputs/models/input.models';
import { apoeNames, apoes, ApoeSoursConstants, apoeSoursNames } from '../../../healthReport/constants/apoe.constants';
import { colors } from '../../constants/colors';
import { WildHealthRadio } from '../WildHealthRadio';
import { WildHealthDatePicker } from '../WildHealthDatePicker';
import EditIcon from '@material-ui/icons/Edit'

export interface ItemsListApoeComponentProps {
    data: ApoeModel
    handleHideApoe: Function
    handleSelectedSours: Function
    handleEditValue: Function
    handleDatePsaChanges: Function
    handleDateColonoscopyChanges: Function
}

export const ItemsListApoeComponent: React.FC<ItemsListApoeComponentProps> = (props: ItemsListApoeComponentProps) => {
    const {
        data,
        handleHideApoe,
        handleSelectedSours,
        handleEditValue,
        handleDateColonoscopyChanges,
        handleDatePsaChanges,
    } = props;

    const classes = useStyles();

    const getRecommendationText = () => {
        if (!data?.score) {
            return 'We were unable to obtain an APOE that had a higher than 50% accuracy score.'
        }
        if (data?.hideApoe === YesNo.Yes) {
            return 'The patient requested to not be given their APOE result.'
        }


        return data?.source === ApoeSoursConstants.BloodTest
            ? 'This result is based in a blood test confirmation.'
            : 'The APOE result was obtained through outside source and was not a blood test.  The confidence in accuracy is not known.'
    }

    const renderEditElement = () =>
        <Box>
            <Box display='flex' flexWrap='wrap' width='100%' >
                <Box mr={2} minWidth='200px' width='50%'>
                    <FormControl color='primary' variant="outlined" size="small" fullWidth>
                        <Select
                            style={{ background: '#FFF' }}
                            id="apoe-name"
                            value={data?.score}
                            onChange={(v) => handleEditValue(v.target.value)}>
                            {
                                apoes.map(apoe => {
                                    return <MenuItem key={apoe} value={apoe}>{apoeNames[apoe]}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                    <Box pl={0.5} pt={1}>
                        <WildHealthCheckbox
                            id={`Patient-Opted-Out`}
                            color="secondary"
                            fill={colors.main}
                            label={<Box display="flex">
                                <span>Patient Opted Out</span>
                            </Box>}
                            checkStatus={data?.hideApoe === YesNo.Yes}
                            handleCheck={() => handleHideApoe(data?.hideApoe === YesNo.Yes ? YesNo.No : YesNo.Yes)}
                            defaultIcon
                        />
                    </Box>
                </Box>
                <Box minWidth='200px' >
                    <RadioGroup value={data?.source} onChange={(event) => handleSelectedSours(String(event.target.value))}>
                        <Box display='flex'>
                            <FormControlLabel disabled={data?.hideApoe === YesNo.Yes} key={ApoeSoursConstants.BloodTest} value={ApoeSoursConstants.BloodTest} control={<WildHealthRadio />} label={apoeSoursNames[ApoeSoursConstants.BloodTest]} />
                            <FormControlLabel disabled={data?.hideApoe === YesNo.Yes} key={ApoeSoursConstants.Other} value={ApoeSoursConstants.Other} control={<WildHealthRadio />} label={apoeSoursNames[ApoeSoursConstants.Other]} />
                        </Box>
                    </RadioGroup>
                </Box>
            </Box>
            <Box mt={2} display='flex' flexWrap='wrap' width='100%'>
                <Box pr={2} minWidth='200px' width='50%'>
                    <Box style={{ fontWeight: "bold" }}>Colonoscopy in past:</Box>
                    <Box mt={1}>
                        <WildHealthDatePicker
                            placeholder='MMM/DD/yyyy'
                            openTo='year'
                            size='small'
                            format='MM/DD/yyyy'
                            inputProps={{ disabled: true }}
                            inputVariant='outlined'
                            value={data?.colonoscopy?.value ?? ''}
                            error={false}
                            helperText={false}
                            onChange={(momentDate) => handleDateColonoscopyChanges(momentDate?.toDate())}
                            useKeyboardDatePicker
                            keyboardIcon={
                                <Box>
                                    <EditIcon fontSize='small' />
                                </Box>
                            }
                            name='colonoscopy'
                        />
                    </Box>
                </Box>
                <Box pr={2} minWidth='200px' width='50%'>
                    <Box style={{ fontWeight: "bold" }}>PSA in the past:</Box>
                    <Box mt={1}>
                        <WildHealthDatePicker
                            placeholder='MMM/DD/yyyy'
                            openTo='year'
                            size='small'
                            format='MM/DD/yyyy'
                            inputProps={{ disabled: true }}
                            inputVariant='outlined'
                            value={data?.psa?.value ?? ''}
                            onChange={(momentDate) => handleDatePsaChanges(momentDate?.toDate())}
                            useKeyboardDatePicker
                            error={false}
                            helperText={false}
                            keyboardIcon={
                                <Box>
                                    <EditIcon fontSize='small' />
                                </Box>
                            }
                            name='psa'
                        />
                    </Box>
                </Box>

            </Box>
        </Box>



    return (
        <Box>
            <Box display='flex' flexDirection='column'>
                <Box className={classes.container}>
                    {renderEditElement()}
                </Box>
                <Box display='flex' mt={3}>
                    <Box color={colors.bad} mr={1}>*</Box>
                    <Box color={colors.gray1} fontSize={14}>
                        {getRecommendationText()}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}