import {PaymentPeriodModel} from "./paymentPeriod.models";
import {PaymentBenefitModel} from "./paymentBenefit.models";
import {StateModel} from "../../account/models/address.model";
import { PaymentStrategy, PaymentPriceType } from "./paymentPrice.models";
import { ProductType } from "../../products/enums/productType";

export enum PaymentPlanType
{
    Default = 0,
    Upsell = 1
}

export enum PaymentPlanId {
    WildHealthLight = 15000,
    PrecisionCarePackage = 12000,
    HealthCoachingTier = 14000,
    SinglePlan = 10000,
}

/**
 * Represents payment plan simple model
 */
export interface PaymentPlanSimpleModel {
    id: number;
    name: string;
    displayName: string;
    isTrial: boolean;
    isFounder: boolean;
    type: PaymentPlanType;
}

/**
 * Represents payment plan
 */
export interface PaymentPlanModel extends PaymentPlanSimpleModel {
    startPrice: number;
    startStartupFee: number;
    title: string;
    order: number;
    insuranceStates: StateModel[];
    benefits: PaymentBenefitModel[];
    periods: PaymentPeriodModel[];
    isLimited: boolean;
    limitationReason: string;
    mostPopular: boolean;
    startOriginalPrice: number;
    inclusions?: PaymentIncludeModel[];
}

export interface OfferPaymentPlanModel {
    paymentPlanId: number;
    paymentPeriodId: number;
    patientId: number;
}

export interface PaymentPlanFilterModel {
    id: number;
    periodId: number;
    name: string;
    isSelected: boolean;
}

export interface PaymentIncludeModel {
    text: string;
}

export interface CreatePriceModel {
    strategy: PaymentStrategy;
    originalPrice: number;
    discount: number;
    price: number;
    isAutoRenewal: boolean;
    startupFee: number;
    discountInsurance: number;
    type: PaymentPriceType;
}


export enum InclusionOption {
    NoOne = 5,
    FirstFree = 10,
    FirstAnnualFree = 15,
    Limited = 20,
    Unlimited = 25
}

export const InclusionOptionNames: { [option: number]: string } = {
    [InclusionOption.NoOne]: 'No One',
    [InclusionOption.FirstFree]: 'First Free',
    [InclusionOption.FirstAnnualFree]: 'First Annual Free',
    [InclusionOption.Limited]: 'Limited',
    [InclusionOption.Unlimited]: 'Unlimited'
}

export interface InclusionModel {
    productType: ProductType;
    option: InclusionOption;
    count: number;
}