import React from 'react';
import { WildHealthTabControl } from "../../../../common/components/wildHealthTabControl/WildHealthTabControl";
import { TabItem } from "../../../../common/components/wildHealthTabControl/wildHealthTabControl.hooks";
import { useStylesFullWidthTabs } from '../../../../common/components/wildHealthTabControl/wildHealthTabControl.styles';
import { useFacade } from "./shortcutTabsComponent.hooks";
import { ShortcutGroupsListComponent } from '../shortcutGroupsListComponent/ShortcutGroupsListComponent';

export const ShortcutTabsComponent: React.FC = () => {
    const tabsStyles = useStylesFullWidthTabs();
    const [
        {
            selectedTab,
            groups
        },
        handleChangeTab,
    ] = useFacade();

    const globalGroups = groups.filter(i => !i.isPersonal);
    const personalGroups = groups.filter(i => i.isPersonal);

    const tabs: TabItem[] = [
        {
            title: `Global Shortcuts`,
            content: (
                <ShortcutGroupsListComponent
                    groups={globalGroups}
                />
            ),
            keepMounted: true,
        },
        {
            title: `Personal Shortcuts`,
            content: (
                <ShortcutGroupsListComponent
                    groups={personalGroups}
                />
            ),
            keepMounted: true,
        },
    ];

    return (
        <WildHealthTabControl
            items={tabs}
            className={tabsStyles}
            initTab={selectedTab}
            onChangeCB={handleChangeTab}
        />
    )
};
