export enum MenuItemTypes {
    None,
    Dashboard,
    Shortcuts,
    Patients,
    MyPatients,
    ManagePatients,
    AddOnOrders,
    IntakePatients,
    HealthScore,
    HabitScore,
    BioData,
    Results,
    GeneralInputs,
    LabsInputs,
    MicrobiomeInputs,
    DataFilesInputs,
    Supplements,
    Questionnaire,
    HealthSummary,
    HealthForms,
    Documents,
    HealthReport,
    MyHealthReport,
    AddOnReports,
    HealthReportTemplates,
    HealthPlan,
    Appointments,
    UpcommingAppointments,
    PastAppointments,
    Employees,
    Notes,
    Orders,
    DNAOrderDropship,
    DNAFiles,
    Analytics,
    Fellowship,
    Messaging,
    PatientsSubmissions,
    PendingNotes,
    AwaitingApproval,
    DraftNotes,
    SignOffNotes,
    ReferralLanding,
    Account,
    Insurance,
    Profile,
    Membership,
    Settings,
    LogOut,
    CourseHistory,
    EngineeringAdminTool,
    EmployerAdmin,
    SyncRecords,
    EligibleInsurances,
    InsuranceConfigurations,
    PromoCodes,
    MessagingAdminPanel,
    MessageForwarding,
    ManagePatientResponsibility,
    UnmatchedLabs,
    Banners,
    ManageCommonGoals,
    ManageCommonMdms,
    ManageCommonSupplements,
    ManageCommonOrders,
    NewReferralOrder,
    Recommendations,
    OpsAdminTools,
    KBDocuments,
    GenericPlayground,
    VisitPrep,
    ManageConversationTemplates,
    ManageEmployersProfiles
}

export interface MenuItem {
    title: string;
    icon?: (isSelected?: boolean) => JSX.Element;
    badge?: JSX.Element;
    type?: MenuItemTypes;
    children?: MenuItem[];
    action?: (history: any) => void;
    disabled?: boolean;
}