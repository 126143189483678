import {useState} from 'react';
import {confirmService} from "../../../../services/confirm.service";

export interface ConfirmDialogState {
    isOpen: boolean;
    title: string;
    message: string;
    acceptTitle: string,
    cancelTitle: string,
    variant: 'default' | 'danger',
    handleAnswer: Function;
}

export function useFacade(): [ConfirmDialogState, Function]{

    const [state, setState] = useState({
        isOpen: false,
        title: null,
        message: null,
        acceptTitle: 'Yes',
        cancelTitle: 'Cancel',
        variant: 'default'
    } as ConfirmDialogState);

    const handleAnswer = (result: boolean) => {
        setState({...state, isOpen: false, title: null, message: null, acceptTitle: 'Yes', cancelTitle: 'Cancel', variant: 'default'});
        state.handleAnswer(result);
        setState({...state, isOpen: false, title: null, message: null, acceptTitle: 'Yes', cancelTitle: 'Cancel', variant: 'default'});
    }

    confirmService.onQuestion.subscribe((question) => {
        setState({...state, 
            isOpen: true, 
            title: question?.data?.title,
            message: question.data.message,
            acceptTitle: question?.data?.acceptTitle ?? state.acceptTitle,
            cancelTitle: question?.data?.cancelTitle ?? state.cancelTitle,
            variant: question?.data?.variant ?? state.variant,
            handleAnswer: question.callback});
    })

    return [state, handleAnswer];
}