import {Observable} from "rxjs";
import Axios from "axios-observable";
import {snackService} from "../../common/snack/state";
import {authHeader} from "../../common/helpers/auth-header";
import {rostersStore, RostersStore} from "../stores/rosters";
import {RosterModel} from "../models/roster.model";
import {CreateRosterModel} from "../models/createRoster.model";

export class RostersService {
    private url = `${process.env.REACT_APP_API_URL}Rosters`;

    constructor(
        private store: RostersStore) {
    }

    public getAll(): Observable<RosterModel[]> {
        const config = { headers: authHeader() };
        return new Observable(observer => {
            Axios.get<RosterModel[]>(`${this.url}`, config).pipe()
                .subscribe(
                    response => {
                        this.store.update({rosters: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        });
    }

    public getActive(): Observable<RosterModel[]>{
        return new Observable(observer => {
            Axios.get<RosterModel[]>(`${this.url}/Active`).pipe()
                .subscribe(
                    response => {
                        this.store.update({rosters: response.data});
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        });
    }

    public create(model: CreateRosterModel): Observable<RosterModel> {
        const config = { headers: authHeader() };
        return new Observable(observer => {
            Axios.post<RosterModel>(`${this.url}`, model, config).pipe()
                .subscribe(
                    response => {
                        this.store.addRoster(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        });
    }

    public activate(id: number): Observable<RosterModel> {
        const config = { headers: authHeader() };
        return new Observable(observer => {
            Axios.put<RosterModel>(`${this.url}/${id}/Activate`, {}, config).pipe()
                .subscribe(
                    response => {
                        this.store.updateRoster(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        });
    }

    public deactivate(id: number): Observable<RosterModel> {
        const config = { headers: authHeader() };
        return new Observable(observer => {
            Axios.put<RosterModel>(`${this.url}/${id}/Deactivate`, {}, config).pipe()
                .subscribe(
                    response => {
                        this.store.updateRoster(response.data);
                        observer.next(response.data);
                        observer.complete();
                    },
                    error => {
                        snackService.commonErrorHandler(error);
                        observer.error();
                        observer.complete();
                    }
                );
        });
    }
}

export const rostersService = new RostersService(rostersStore);