import {
    Box,
    Divider,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    useMediaQuery,
    useTheme
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import clsx from 'clsx';
import moment from 'moment';
import React from 'react';
import {WildHealthButton} from '../../../common/components/wildHealthButton/WildHealthButton';
import commonUseStyles from '../../../common/styles/common.styles';
import {
    AppointmentPurposeTypeNames,
    AppointmentLocationType
} from '../../models/appointments.enums';
import {PatientAppointmentModel} from '../../models/appointments.models';
import {useFacade} from './AppointmentCardComponent.hook';
import {useStyles} from './AppointmentCardComponent.styles';
import arrowUp from "@img/icons/arrowUp.svg";
import arrowDown from "@img/icons/arrowDown.svg";
import location from "@img/icons/location.svg";
import monthlyCalendar from "@img/icons/monthlyCalendar.svg";
import timeCircle from "@img/icons/timeCircle.svg";
import {displayTimePatientTimeZone} from '../../../timezones/helpers/timezone';
import {TimeZoneModel} from "../../../timezones/models/timezone.model";
import {ProtectedElement} from '../../../common/components/ProtectedElement';
import {UserType} from '../../../auth/models/auth.enums';
import {displayShortName} from "../../../conversations/helpers/messages.converter";
import { FeatureComponent } from '../../../common/components/featureFlags/FeatureComponent';
import { FeatureFlag } from '../../../common/components/featureFlags/featureFlags.models';
import { profileQuery } from '../../../account/stores/profileStore';
import VideoCamOffIcon from '@material-ui/icons/VideocamOffOutlined';

interface AppointmentCardComponentProps {
    key?: number;
    appointment: PatientAppointmentModel;
    handleAddAppointmentToCalendar: (appointmentId: number) => void;
    handleCancelAppointment: (appointmentId: number) => void;
    handleEditAppointment: (appointmentId: number) => void;
    handleJoinAppointment: (joinLink: string) => void;
    timeZone: TimeZoneModel;
}

export const AppointmentCardComponent: React.FC<AppointmentCardComponentProps> = (props: AppointmentCardComponentProps) => {
    const [
        state,
        handleExpand,
        handleOpenMenu,
        handleCloseMenu,
        handleOpenGoogleMap,
    ] = useFacade(props.appointment);

    const classes = useStyles();
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

    const {
        appointment,
        handleCancelAppointment,
        handleEditAppointment,
        handleJoinAppointment
    } = props;

    const getAvatarLetters = (appointment: PatientAppointmentModel): string => {
        return appointment.employees.length === 1
            ? displayShortName(appointment.employees[0].firstName, appointment.employees[0].lastName)
            : appointment.employees.map(x => x.firstName.trim()[0]).join(',');
    }

    const cardHeaderAvatar = <Avatar variant="square" src={profileQuery.getPhoto(appointment.employees[0].id)}
                                     className={classes.avatar}>{getAvatarLetters(appointment)}</Avatar>;
    const cardHeaderAction = (
        <>
            <IconButton id="appointment-card-open-menu" onClick={(e) => handleOpenMenu(e)}>
                <MoreHorizIcon/>
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={state.menuAnchor}
                keepMounted
                open={Boolean(state.menuAnchor)}
                onClose={(e) => handleCloseMenu(e)}
            >
                <MenuItem id="appointment-card-reschedule" onClick={(e) => {
                    handleEditAppointment(appointment.id)
                    handleCloseMenu(e)
                }}>
                    Reschedule
                </MenuItem>
                <MenuItem id="appointment-card-cancel" onClick={(e) => {
                    handleCancelAppointment(appointment.id)
                    handleCloseMenu(e)
                }}>
                    Cancel
                </MenuItem>
            </Menu>
        </>
    );

    const getAppointmentType = () => {
        switch (appointment.locationType) {
            case AppointmentLocationType.InPerson:
                return `${appointment.location?.streetAddress1}, ${appointment.location?.city}, ${appointment.location?.state} ${appointment.location?.zipCode}`;
            case AppointmentLocationType.Online:
                return 'Online';
            case AppointmentLocationType.Phone:
                return "Phone";
            default:
                return "Unknown";
        }
    }

    const getTitle = (appointment: PatientAppointmentModel) => {
        return appointment.employees
            .map(e => `${e.firstName} ${e.lastName}`)
            .join(', ');
    }

    if (isSmallScreen) {
        return (
            <Card className={classes.card}>
                <CardHeader title={getTitle(appointment)}
                            subheaderTypographyProps={{className: commonClasses.colorGray1}}
                            titleTypographyProps={{className: clsx(commonClasses.textMedium, commonClasses.size18)}}
                            action={cardHeaderAction}
                            avatar={cardHeaderAvatar}
                            subheader={AppointmentPurposeTypeNames.get(appointment.purpose)}
                            className={classes.cardHeader}
                />

                <CardContent className={classes.cardContent}>
                    <Divider className={classes.divider}/>
                    <List>
                        <ListItem className={classes.listItem}>
                            <ListItemIcon>
                                <img src={monthlyCalendar} alt='date'/>
                            </ListItemIcon>

                            <ListItemText primary={moment(appointment.startDate).format("dddd DD, MMMM yyyy")}
                                          primaryTypographyProps={{className: commonClasses.textMedium}}/>
                        </ListItem>

                        <ListItem className={clsx(classes.listItem, commonClasses.backgroundColorBg)}>
                            <ListItemIcon>
                                <img src={timeCircle} alt='time'/>
                            </ListItemIcon>

                            <ListItemText
                                primary={
                                    <span>
                                        {displayTimePatientTimeZone(props.appointment.startDate, props.timeZone)}
                                        <span className={clsx(commonClasses.colorGray2, classes.timeDivider)}>|</span>
                                        {`${appointment.duration} minutes`}
                                    </span>
                                }
                                primaryTypographyProps={{className: commonClasses.textMedium}}/>
                        </ListItem>
                        <ProtectedElement
                            userType={UserType.Employee}
                            permissions={[]}
                            element={<ListItem
                                className={clsx(classes.listItemSecondary, commonClasses.backgroundColorBg)}>
                                <ListItemIcon>
                                </ListItemIcon>
                                <ListItemText
                                    secondary={
                                        <span className={classes.textSecondaty}>
                                            <span>{displayTimePatientTimeZone(props.appointment.startDate)}</span>
                                            <span>(My Timezone)</span>
                                        </span>
                                    }/>
                            </ListItem>}/>
                        {props.appointment.reason && (
                            <FeatureComponent featureFlag={FeatureFlag.AppointmentReasons}>
                                <ListItem className={classes.listItem}>
                                    <ListItemIcon />
                                    <ListItemText primary={<span>{props.appointment.reason}</span>} primaryTypographyProps={{className: commonClasses.textMedium}}/>
                                </ListItem>
                            </FeatureComponent>
                        )}
                        <ListItem className={classes.listItem}>
                            <ListItemIcon className={classes.locationIcon}>
                                <img src={location} alt='location'/>
                            </ListItemIcon>

                            <ListItemText primary={
                                <span
                                    className={appointment.locationType === AppointmentLocationType.InPerson && commonClasses.pointer}
                                    onClick={() => handleOpenGoogleMap()}>{getAppointmentType()}</span>
                            }
                                          primaryTypographyProps={{className: commonClasses.textMedium}}/>
                        </ListItem>
                        <Box mt="33px">
                            <WildHealthButton
                                id="smallscreen-appointment-card-join"
                                onClick={() => handleJoinAppointment(appointment.joinLink)}
                                fullWidth
                                color={'secondary'}
                            >
                                Join
                            </WildHealthButton>
                        </Box>
                    </List>
                    <Box mt="30px">
                        <Typography paragraph className={commonClasses.colorGray1}>
                            Zoom Link
                        </Typography>
                        <Typography paragraph style={{marginBottom: '20px'}}>
                            {
                                <Link
                                    id="smallscreen-appointment-card-join-link"
                                    className={commonClasses.colorMain}
                                    href={appointment.joinLink}
                                    target="_blank"
                                    rel="noopener"
                                >
                                    {appointment.joinLink}
                                </Link>
                            }
                        </Typography>
                        <Typography paragraph className={commonClasses.colorGray1}>
                            Note
                        </Typography>
                        <Typography paragraph>
                            {
                                appointment.comment && appointment.comment.length
                                    ? appointment.comment
                                    : "empty"
                            }
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        )
    }

    return (
        <Card className={classes.card}>
            <CardHeader title={getTitle(appointment)}
                        subheaderTypographyProps={{className: commonClasses.colorGray1}}
                        titleTypographyProps={{className: clsx(commonClasses.textMedium, commonClasses.size18)}}
                        action={cardHeaderAction}
                        avatar={cardHeaderAvatar}
                        subheader={AppointmentPurposeTypeNames.get(appointment.purpose)}
                        className={classes.cardHeader}
            />

            <CardContent className={classes.cardContent}>
                <Divider className={classes.divider}/>
                <List>
                    <ListItem className={classes.listItem}>
                        <ListItemIcon>
                            <img src={monthlyCalendar} alt='date'/>
                        </ListItemIcon>

                        <ListItemText primary={moment(appointment.startDate).format("dddd DD, MMMM yyyy")}
                                      primaryTypographyProps={{className: commonClasses.textMedium}}/>
                    </ListItem>

                    {<ListItem className={clsx(classes.listItem, commonClasses.backgroundColorBg)}>
                        <ListItemIcon>
                            <img src={timeCircle} alt='time'/>
                        </ListItemIcon>

                        <ListItemText
                            primary={
                                <span>
                                    {displayTimePatientTimeZone(props.appointment.startDate, props.timeZone)}
                                    <span className={clsx(commonClasses.colorGray2, classes.timeDivider)}>|</span>
                                    {`${appointment.duration} minutes`}
                                </span>
                            }
                            primaryTypographyProps={{className: commonClasses.textMedium}}/>
                    </ListItem>}
                    <ProtectedElement
                        userType={UserType.Employee}
                        permissions={[]}
                        element={<ListItem className={clsx(classes.listItemSecondary, commonClasses.backgroundColorBg)}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText
                                secondary={<span className={classes.textSecondaty}>
                                    <span>{displayTimePatientTimeZone(props.appointment.startDate)}</span>
                                    <span>(My Timezone)</span>
                                </span>}/>
                        </ListItem>}/>
                    {props.appointment.reason && (
                        <FeatureComponent featureFlag={FeatureFlag.AppointmentReasons}>
                            <ListItem className={classes.listItem}>
                                <ListItemIcon />
                                <ListItemText primary={<span>{props.appointment.reason}</span>} primaryTypographyProps={{className: commonClasses.textMedium}}/>
                            </ListItem>
                        </FeatureComponent>
                    )}
                    <ListItem className={classes.listItem}>
                        <ListItemIcon className={classes.locationIcon}>
                            <img src={location} alt='location'/>
                        </ListItemIcon>

                        <ListItemText primary={
                            <span
                                className={appointment.locationType === AppointmentLocationType.InPerson && commonClasses.pointer}
                                onClick={() => handleOpenGoogleMap()}>{getAppointmentType()}</span>
                        }
                                      primaryTypographyProps={{className: commonClasses.textMedium}}/>
                    </ListItem>
                    <Box display="flex" justifyContent="space-between" width="100%" px="10px">
                        <Box id="appointment-card-expand" display="flex" alignItems="center"
                             className={commonClasses.pointer}
                             onClick={() => handleExpand()}
                        >
                            <Box>
                                <span
                                    className={commonClasses.colorMain}>{state.isExpanded ? "Show less" : "Show more"}</span>
                            </Box>
                            <Box mt="6px" ml={0.5}>
                                <img src={state.isExpanded ? arrowUp : arrowDown} alt='Show more/less'/>
                            </Box>
                        </Box>
                        <Box>
                            <WildHealthButton
                                id="appointment-card-join"
                                onClick={() => handleJoinAppointment(appointment.joinLink)}
                                width={120}
                            >
                                Join
                            </WildHealthButton>
                        </Box>
                    </Box>
                </List>
            </CardContent>

            <Collapse in={state.isExpanded} timeout="auto" unmountOnExit addEndListener={() => {
            }}>
                <CardContent className={clsx(classes.cardContent, classes.expandContent)}>
                    <Divider className={classes.divider}/>
                    <Box mt="20px" px="10px">
                        <Typography paragraph className={commonClasses.colorGray1}>
                            Zoom Link
                        </Typography>
                        <Typography paragraph style={{marginBottom: '18px'}}>
                            {
                                <Link
                                    id="appointment-card-join-link"
                                    className={commonClasses.colorMain}
                                    href={appointment.joinLink}
                                    target="_blank"
                                    rel="noopener"
                                >
                                    {appointment.joinLink}
                                </Link>
                            }
                        </Typography>
                        <Typography paragraph className={commonClasses.colorGray1}>
                            Note
                        </Typography>
                        <Typography paragraph>
                            {
                                appointment.comment && appointment.comment.length
                                    ? appointment.comment
                                    : "empty"
                            }
                        </Typography>
                    </Box>
                    <ProtectedElement
                            userType={UserType.Employee}
                            permissions={[]}
                            element={
                                !(appointment.userOptions?.meetingRecordingConsent ?? false) && 
                                <Box className={classes.recordingConsentSection}>
                                    <VideoCamOffIcon className={classes.recordingConsentIcon}></VideoCamOffIcon>
                                    <Typography className={classes.recordingConsentText}>
                                        Appointment recording has been disabled by the patient
                                    </Typography>
                                </Box> 
                            }/>
                   
                </CardContent>
            </Collapse>
        </Card>
    );
}
