import {GeneralValidator} from "../../../common/validation/general-validator";
import {
    addressValidationRules,
    birthdayValidationRule,
    emailValidationRule,
    firstNameValidationRule,
    lastNameValidationRule,
    nullableRangeValidationRule,
    phoneNumberValidationRule,
    rangeValidationRule,
    stringLengthValidationRule,
    checkManyQuestionValidationRule,
    notWhitespaceValidationRule
} from "../../../common/validation/general-validation-rules";

export const questionnaireSurveyPageValidator = new GeneralValidator({
    // User form
    'firstName': [firstNameValidationRule, notWhitespaceValidationRule()],
    'lastName': [lastNameValidationRule, notWhitespaceValidationRule()],
    'email': [emailValidationRule],
    'birthday': [birthdayValidationRule],
    'phoneNumber': [phoneNumberValidationRule],

    // billingAddress form
    'billingAddress.streetAddress1': [addressValidationRules.streetAddress1, notWhitespaceValidationRule()],
    'billingAddress.streetAddress2': [addressValidationRules.streetAddress2, notWhitespaceValidationRule()],
    'billingAddress.city': [addressValidationRules.city, notWhitespaceValidationRule()],
    'billingAddress.state': [addressValidationRules.state, notWhitespaceValidationRule()],
    'billingAddress.country': [addressValidationRules.country, notWhitespaceValidationRule()],
    'billingAddress.zipCode': [addressValidationRules.zipCode, notWhitespaceValidationRule()],

    // shippingAddress form
    'shippingAddress.streetAddress1': [addressValidationRules.streetAddress1, notWhitespaceValidationRule()],
    'shippingAddress.streetAddress2': [addressValidationRules.streetAddress2, notWhitespaceValidationRule()],
    'shippingAddress.city': [addressValidationRules.city, notWhitespaceValidationRule()],
    'shippingAddress.state': [addressValidationRules.state, notWhitespaceValidationRule()],
    'shippingAddress.country': [addressValidationRules.country, notWhitespaceValidationRule()],
    'shippingAddress.zipCode': [addressValidationRules.zipCode, notWhitespaceValidationRule()],

    // General page
    'HEALTH_PRIORITY': [checkManyQuestionValidationRule],
    'EXCITED_TO_LEARN': [checkManyQuestionValidationRule],

    // Medical page
    'MEDICAL_CONDITIONS': [checkManyQuestionValidationRule],
    'FAMILY_MEDICAL_CONDITIONS': [checkManyQuestionValidationRule],
    'HEALTH_FEARS': [checkManyQuestionValidationRule],
    'CURRENT_MEDICATIONS': [stringLengthValidationRule(0, 500)],
    'CURRENT_MEDICATIONS_SUPPLEMENT': [stringLengthValidationRule(0, 500)],
    'SMOKING_STATUS': [stringLengthValidationRule(0, 500, 'This field is required')],
    'DIABETES_STATUS': [stringLengthValidationRule(0, 500, 'This field is required')],
    'CANCER_SCREENING': [stringLengthValidationRule(0, 500, 'This field is required')],

    // Nutrition page
    'SPECIFIC_DIET': [checkManyQuestionValidationRule],
    'REGULAR_FOOD': [stringLengthValidationRule(0, 500, 'This field is required')],
    'START_CONSUMING_FOOD': [stringLengthValidationRule(0, 500, 'This field is required')],
    'STOP_CONSUMING_FOOD': [stringLengthValidationRule(0, 500, 'This field is required')],
    'FASTING': [stringLengthValidationRule(0, 500, 'This field is required')],

    // Exercise page
    'PRIMARY_EXERCISES': [stringLengthValidationRule(0, 500, 'This field is required')],
    'EXERCISE_TYPE': [checkManyQuestionValidationRule],
    'EXERCISE_FREQUENCY': [stringLengthValidationRule(0, 500, 'This field is required')],
    'EXERCISE_PROGRAM_FOR': [stringLengthValidationRule(0, 500, 'This field is required')],
    'EXERCISE_LIMITATIONS': [checkManyQuestionValidationRule],
    'MAX_PUSH_UPS': [rangeValidationRule(0, 999)],
    'MAX_BUR_PRESS_1_MIN': [rangeValidationRule(0, 999)],

    // Cardio page
    'HEIGHT': [nullableRangeValidationRule(20, 96, "Value should be in range 20 - 96.")],
    'WEIGHT': [nullableRangeValidationRule(30, 800, "Value should be in range 30 - 800.")],
    'WAIST': [rangeValidationRule(0, 999)],
    'PULSE_REST': [rangeValidationRule(0, 999)],
    'PULSE_AFTER_TEST': [rangeValidationRule(0, 999)],
    'PULSE_1_MIN_AFTER_TEST': [rangeValidationRule(0, 999)],
    'SBP': [rangeValidationRule(0, 210)],
    'ETHNICITY': [stringLengthValidationRule(0, 500, 'This field is required')],

    // Sleep page
    'SLEEP_QUALITY': [stringLengthValidationRule(0, 500, 'This field is required')],
    'ROUTINE_BEFORE_SLEEP': [stringLengthValidationRule(0, 500, 'This field is required')],
    'START_SLEEP': [stringLengthValidationRule(0, 500, 'This field is required')],
    'STOP_SLEEP': [stringLengthValidationRule(0, 500, 'This field is required')],
    'ALARM_CLOCK': [stringLengthValidationRule(0, 500, 'This field is required')],
    'RESTED_AFTER_SLEEP': [stringLengthValidationRule(0, 500, 'This field is required')],
    'ENERGY_AFTER_SLEEP': [stringLengthValidationRule(0, 500, 'This field is required')],

    // Stress page
    'STRESS_LEVEL': [stringLengthValidationRule(0, 500, 'This field is required')],
    'DEAL_WITH_STRESS': [checkManyQuestionValidationRule],
    'MEDITATION_FREQUENCY': [stringLengthValidationRule(0, 500, 'This field is required')]
});