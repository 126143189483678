import { TextField } from '@material-ui/core';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';
import Fab from '@material-ui/core/Fab';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        newMessageContainer: {
            width: 375,
            textAlign: 'end',

            [theme.breakpoints.down('sm')]: {
                right: 10,
            },
        },
        newMessage: {
            zIndex: 1000,
        },
        search: {
            background: colors.white,
        },
        searchBase: {
            padding: 16,
            fontFamily: 'Be Vietnam Pro',
            fontSize: 14,
        },
        icon: {
            color: '#7C8A9D',
        },
        divider: {
            backgroundColor: colors.stroke,
        },
        activeThread: {
            fontFamily: 'Be Vietnam Pro',
            textTransform: 'uppercase',
            color: colors.gray1,
            fontSize: 12,
            fontWeight: 500,
            margin: '10px 0',
        },
        favoriteChats: {
            minHeight: 80,
            maxHeight: 'calc(100vh - 304px)',
            overflowY: 'auto',
        },
        chats: {
            minHeight: 300,
            height: 'calc(100vh - 368px)',
            maxHeight: 'calc(100vh - 304px)',
            overflow: 'auto',
        },
        moreIcon: {
            color: colors.main,
            marginLeft: 14,
            cursor: 'pointer'
        },
        popoverContent: {
            width: 205,
        }
    })
);

export const SearchTextField = withStyles({
    root: {
        '& label.Mui-focused': {
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'transparent',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': { 
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'transparent',
            },
        },
    },
})(TextField);

export const NewMessageButton = withStyles((theme: Theme) => ({
    root: {
        color: colors.white,
        backgroundColor: colors.main,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: colors.mainDark,
        },
        '&:disabled': {
            color: colors.gray2,
            backgroundColor: colors.disabled,
        },
    },
}))(Fab);
