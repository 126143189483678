import { Box, Grid } from "@material-ui/core";
import React from 'react';
import { AuthenticatedLayoutComponent } from '../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { PagesNamesService } from "../../../constants/pagesNames.constants";
import { TitlesService } from '../../../constants/title.constants';
import { MenuItemTypes } from "../../../models/menu.models";
import WildHealthLinearProgress from '../../common/components/WildHealthLinearProgress';
import { ColorsForHabit } from '../../healthScore/models/score.model';
import { useStyles } from "./habitScorePage.styles";
import { BiomarkersComponent } from "../../patients/components/BiomarkersComponent/BiomarkersComponent"
import { useFacade } from "./HabitScorePage.hooks";

export const HabitScorePage: React.FC = () => {
    const [
        {
            isLoading,
            generalScore,
        }
    ] = useFacade();
    const classes = useStyles();

    const renderContent = () => {
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box className={classes.sections} display='flex'>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Box className={classes.sections} display='flex'>
                        </Box>
                    </Grid>
                </Grid>
            </>
        )
    }

    return (
        <AuthenticatedLayoutComponent
            title={TitlesService.patientDashboardTitle}
            pageName={PagesNamesService.healthScore}
            selectedMenuItem={MenuItemTypes.HealthScore}
            hideBackground
        >
            {
                isLoading
                    ? <WildHealthLinearProgress />
                    : renderContent()

            }
        </AuthenticatedLayoutComponent>
    )
}

