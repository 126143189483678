import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  Select,
  MenuItem,
  TextField,
  FormHelperText,
  Checkbox,
  IconButton,
  CircularProgress,
  Dialog,
  DialogContent
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import TrashIcon from '@material-ui/icons/Delete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import EditIcon from '@material-ui/icons/Edit'
import { useFacade } from './commonGoalEditComponent.hooks';
import { useStyles } from "./commonGoalEditComponent.styles";
import { WildHealthContainer } from '../../../common/components/wildHealthContainer/WildHealthContainer';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { FeatureComponent } from "../../../common/components/featureFlags/FeatureComponent";
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import { GoalCategory, goalCategoryNames } from '../../../notes/models/notes.models';

interface CommonGoalEditComponentProps {
  goalId: number;
}

export const CommonGoalEditComponent: React.FC<CommonGoalEditComponentProps> = (props: CommonGoalEditComponentProps) => {
  const [
    {
      isLoading,
      isSubmitting,
      isOpen,
      targetGoal,
      createIntervention,
      selectedId,
      canSubmit,
      errors,
    },
    handleChanges,
    handleChangeIntervention,
    handleUpdateGoal,
    handleSaveIntervention,
    handleDeleteIntervention,
    handleToggleInterventionModify
  ] = useFacade(props.goalId);

  const classes = useStyles();

  const controlIcon = <CheckBoxOutlineBlankIcon className="wh-tw-text-mediumGray" />;
  const controlCheckedIcon = <CheckBoxIcon className="wh-tw-text-primaryV" />
  
  if (isLoading) {
    return <WildHealthLinearProgress />
  }

  const renderTable = () => {
    if (!isLoading && (!targetGoal || !targetGoal.interventions.length)) {
      return <WildHealthPlaceHolder message="No Interventions found" />
    }

    return (
      <>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <WildHealthTableCell>
                  Name
                </WildHealthTableCell>
                <WildHealthTableCell>
                  Action
                </WildHealthTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {targetGoal.interventions.map(intervention => (
                <WildHealthTableRow key={intervention.id}>
                  <WildHealthTableCell align="left">
                      <Box className="wh-tw-text-sm wh-tw-font-medium wh-tw-text-black">
                          {intervention.name}
                      </Box>
                  </WildHealthTableCell>
                  <WildHealthTableCell style={{ width: '15%' }}>
                    <IconButton
                      id="edit-intervention-btn"
                      disabled={isSubmitting}
                      onClick={() => handleToggleInterventionModify(intervention.id)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      id="remove-intervention-btn"
                      disabled={isSubmitting}
                      onClick={() => handleDeleteIntervention(intervention.id)}
                    >
                      {isSubmitting && selectedId === intervention.id ? <CircularProgress size={24} className="wh-tw-text-primaryV" /> : <TrashIcon />}
                    </IconButton>
                  </WildHealthTableCell>
                </WildHealthTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }

  return (
    <Box py={2} px={10} width={1}>
      <Box my={2} display="flex" alignItems="center" justifyContent="space-between">
          <Box>
              <FeatureComponent featureFlag={FeatureFlag.Navigation}>
                  <Box className="wh-tw-font-semibold wh-tw-text-2xl wh-tw-black">Common Goal Edit</Box>
              </FeatureComponent>
          </Box>
          <Box>
              <WildHealthButton
                  id="update-common-goal-button"
                  disabled={isLoading || isSubmitting}
                  onClick={handleUpdateGoal}
                  fullWidth
                  style={{ height: 48 }}
              >
                  <Box className="wh-tw-text-base wh-tw-font-medium">Save</Box>
              </WildHealthButton>
          </Box>
      </Box>
      
      <Box p={3} className="wh-tw-bg-white">
          <Box mb={2}>
              <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Name*</Box>
              <TextField
                  id={`name`}
                  fullWidth
                  required
                  size="small"
                  InputProps={{ className: 'input', classes }}
                  variant="outlined"
                  value={targetGoal.name}
                  error={!!errors['name']}
                  helperText={errors['name']}
                  disabled={isSubmitting}
                  onChange={(event) => handleChanges(event.target.id, event.target.value)}
              />
          </Box>
          <Box mb={2}>
              <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Completion Months*</Box>
              <TextField
                  id={`completionValue`}
                  fullWidth
                  required
                  type="number"
                  size="small"
                  InputProps={{ className: 'input', classes }}
                  variant="outlined"
                  value={targetGoal.completionValue}
                  error={!!errors['completionValue']}
                  helperText={errors['completionValue']}
                  disabled={isSubmitting}
                  onChange={(event) => handleChanges(event.target.id, event.target.value)}
              />
          </Box>
          <Box mb={2}>
              <FormControl
                  variant="outlined"
                  color='primary'
                  required
                  error={!!errors[`category`]}
                  size="small"
                  disabled={isSubmitting}
                  classes={{ root: classes.root }}
                  fullWidth>
                  <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Goal Category*</Box>
                  <Select
                      style={{ background: '#FFF' }}
                      classes={{ root: classes.root }}
                      value={targetGoal.category}
                      error={!!errors[`category`]}
                      inputProps={{
                          name: 'Goal category',
                          id: 'category-label',
                          classes,
                      }}
                      renderValue={() => {
                          if (targetGoal.category === null) {
                              return <Box className="wh-tw-text-mediumGray">Select</Box>;
                          }

                          return goalCategoryNames[targetGoal.category];
                      }}
                      displayEmpty
                      onChange={(v) => handleChanges("category", v.target.value)}
                  >
                      <MenuItem value={GoalCategory.Diet}>Diet</MenuItem>
                      <MenuItem value={GoalCategory.Exercise}>Exercise</MenuItem>
                      <MenuItem value={GoalCategory.Sleep}>Sleep</MenuItem>
                      <MenuItem value={GoalCategory.Neuro}>Neuro</MenuItem>
                      <MenuItem value={GoalCategory.Longevity}>Longevity</MenuItem>
                      <MenuItem value={GoalCategory.Mindfulness}>Mindfulness</MenuItem>
                      <MenuItem value={GoalCategory.Other}>Other</MenuItem>
                  </Select>
                  <FormHelperText>{errors[`category`]}</FormHelperText>
              </FormControl>
          </Box>
          <Box mb={2}>
              <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>IsCompleted*</Box>
              <Box display="flex" alignItems="center">
                  <Checkbox
                      icon={controlIcon}
                      color="primary"
                      checkedIcon={controlCheckedIcon}
                      checked={targetGoal.isCompleted}
                      onChange={() => handleChanges("isCompleted", true)}
                  />
                  <Box mr={2}>Yes</Box>
                  <Checkbox
                      icon={controlIcon}
                      color="primary"
                      checkedIcon={controlCheckedIcon}
                      checked={!targetGoal.isCompleted}
                      onChange={() => handleChanges("isCompleted", false)}
                  />
                  <Box>No</Box>
              </Box>
          </Box>
      </Box>
      
      <Box my={2} display="flex" alignItems="center">
        <Box>
          <Box className="wh-tw-font-medium wh-tw-text-2xl wh-tw-black">Interventions</Box>
        </Box>
        
        <Box ml={3}>
          <button id="add-goal-intervention-button" className={classes.addBtn} disabled={isSubmitting} onClick={() => handleToggleInterventionModify(0)}>
            <AddIcon />
          </button>
        </Box>
      </Box>
      <WildHealthContainer margin={0} minHeight={'100%'}>
        {renderTable()}
      </WildHealthContainer>
      <Dialog
          onClose={() => handleToggleInterventionModify(-1)}
          className={classes.root}
          open={isOpen}
      >
          <DialogContent className={classes.dialogContent}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Box>
                      <span className="wh-tw-text-2xl wh-tw-black wh-tw-font-medium">{selectedId > 0 ? 'Edit Intervention' : 'New Intervention'}</span>
                  </Box>
                  <Box display="flex" alignItems="center">
                      <IconButton
                          id="new-intervention-dialog-close"
                          className={classes.dialogTitleCloseAction}
                          onClick={() => handleToggleInterventionModify(-1)}
                      >
                          <CloseIcon />
                      </IconButton>
                  </Box>
              </Box>
              <Box mt={5}>
                  <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Name*</Box>
                  <TextField
                      id={`name`}
                      fullWidth
                      required
                      multiline
                      rows="5"
                      size="small"
                      InputProps={{ className: 'input', classes }}
                      variant="outlined"
                      value={createIntervention.name}
                      disabled={!isOpen || isSubmitting}
                      onChange={handleChangeIntervention}
                  />
              </Box>
              <Box mt={5} display="flex" justifyContent="flex-end" alignItems="center">
                  <Box mx={2}>
                      <WildHealthButton
                          id="modify-intervention-cancel"
                          onClick={() => handleToggleInterventionModify(-1)}
                          color="tertiary"
                          size='medium'
                          disabled={!isOpen || isSubmitting}
                      >
                          Cancel
                      </WildHealthButton>
                  </Box>
                  <Box>
                      <WildHealthButton
                          id="save-intervention-button"
                          size='medium'
                          disabled={!canSubmit}
                          onClick={handleSaveIntervention}
                          loading={isSubmitting}
                      >
                          Save
                      </WildHealthButton>
                  </Box>
              </Box>
          </DialogContent>
      </Dialog>
    </Box>
  );
}