import { Query } from "@datorama/akita";
import { HealthScoreState, healthScoreStore, HealthScoreStore } from "./score.store";

/**
 * Provides Health Score queries
 */
export class HealthScoreQuery extends Query<HealthScoreState> {
  healthScore$ = this.select((state) => state.healthScore);
  isHealthScoreAvailable$ = this.select((state) => state.isHealthScoreAvailable);

  constructor(protected store: HealthScoreStore) {
    super(store);
  }
}

export const healthScoreQuery = new HealthScoreQuery(healthScoreStore);
