import React from 'react';
import './QuestionComponent.scss';
import {Box} from "@material-ui/core";
import {QuestionComponentProps} from "./QuestionComponentProps";
import moment from "moment";
import {WildHealthTimePicker} from '../../../common/components/WildHealthTimePicker';

export const QuestionTimeComponent: React.FC<QuestionComponentProps> = (props: QuestionComponentProps) => {
    const {
        question,
        answer,
        handleChanges,
        error
    } = props;

    const parseTimeToDate = (value : string) => {
        if(!value){
            return new Date(0, 0, 0, 0, 0, 0);
        }

        const [hours, minutes] = value.split(':');
        return new Date(0, 0, 0, +hours, +minutes, 0);
    }

    const parseDateToTime = (date : moment.Moment) => date.format('HH:mm');

    return (
        <>
            <Box display="flex" justifyContent="flex-start" className="input-question">
                <WildHealthTimePicker
                        size="small"
                        id={question.name}
                        inputVariant="outlined"
                        value={parseTimeToDate(answer)}
                        className="time-question"
                        error={!!error}
                        helperText={error}
                        onChange={(v) => { handleChanges(parseDateToTime(v)) }}
                />
            </Box>

        </>
    )
}