import { Box, Divider, TextField } from '@material-ui/core';
import React from 'react';
import { Add } from '@material-ui/icons';
import { useStyles } from './recommendationSelectWithPreviousComponent.styles';
import { RecommendationDisplayModel } from '../../models/healthReport.models';
import { RecommendationPlaceHolder } from '../../../common/components/wildHealthPlaceHolder/RecommendationPlaceHolder';
import { WildHealthRadio } from '../../../common/components/WildHealthRadio';
import { commonUseStyles } from '../../../common/styles/common.styles';
import { authQuery } from '../../../auth/stores/auth';
import { UserType } from '../../../auth/models/auth.enums';
import { selectShortcutService } from '../../services/selectShortcut.service';
import { insertString } from '../../../common/helpers/insert-string';
import EditIcon from '@material-ui/icons/Edit';
import { colors } from '../../../common/constants/colors';

export interface RecommendationSelectWithPreviousComponentProps {
    currentRecomemendation: RecommendationDisplayModel[],
    newRecommendation: RecommendationDisplayModel[],
    type: number,
    handleSelectedNew: (id: number | string, isSelected: boolean) => void,
    handleSelectedPrev: (id: number | string, isSelected: boolean) => void,
    handleChangesPrev: (id: number | string, value: string) => void,
    handleAddRecommendation: (id: number, index: number) => void,
    hendleUpdateRecommendation: (recommendation: RecommendationDisplayModel) => void,
}

export const RecommendationSelectWithPreviousComponent: React.FC<RecommendationSelectWithPreviousComponentProps> = (props: RecommendationSelectWithPreviousComponentProps) => {
    const {
        currentRecomemendation,
        newRecommendation,
        type,
        handleSelectedNew,
        handleSelectedPrev,
        handleChangesPrev,
        handleAddRecommendation,
        hendleUpdateRecommendation,
    } = props;

    const classes = useStyles();
    const commonClasses = commonUseStyles();

    const handleOnKeyDownPrev = (id: string | number, event: any, idFild: string) => {
        if (event.keyCode === 51 && event.altKey) {
            const userType = authQuery.getType();
            if (UserType.Employee === userType) {
                const value = (document.getElementById(idFild) as any).value;
                const cursorPos = (document.getElementById(idFild) as any).selectionStart;
                setTimeout(() => {
                    selectShortcutService.select().subscribe((data) => {
                        data && handleChangesPrev(id, insertString(value, data, cursorPos + 1))
                    })
                }, 1)
            }
        }
    }

    const renderPlaceHolder = (title: string) => {
        return <Box display="flex" maxHeight="180px" alignItems="center" justifyContent="center">
            <RecommendationPlaceHolder message={title} />
        </Box>
    }

    const renderAddRecommendation = () => {
        return <Box mt={1} display="flex" width={currentRecomemendation.length > 0 ? "100%" : "max-content"}>
            <Box onClick={() => handleAddRecommendation(type, currentRecomemendation.length)
            }>
                <Add className={classes.addIcon} />
            </Box>
            <Box className={classes.addText} ml={2}>
                <span>Add Recommendation</span>
            </Box>
        </Box>
    }

    const renderRecommendationCurrent = (recommendation: RecommendationDisplayModel) => {
        return <Box my={1} display="flex" width="100%">
            <Box>
                <WildHealthRadio
                    checked={recommendation.isSelected}
                    onClick={() => handleSelectedPrev(recommendation.id, !recommendation.isSelected)}
                />
            </Box>
            <TextField
                id={`recommendation-text-area-prev-${recommendation.type}-${recommendation.id}`}
                fullWidth
                multiline
                disabled={!recommendation.isSelected}
                className={recommendation.isSelected ? "" : classes.textFiel}
                variant="outlined"
                value={recommendation.content}
                onKeyDown={(e) => { handleOnKeyDownPrev(recommendation.id, e, `recommendation-text-area-prev-${recommendation.type}-${recommendation.id}`) }}
                onChange={(v) => {
                    handleChangesPrev(recommendation.id, v.target.value)
                }}
            />
        </Box>
    }

    const renderRecommendationNew = (recommendation: RecommendationDisplayModel) => {
        return <Box display="flex" width="100%" key={`recommendation-text-area-new-${recommendation.type}-${recommendation.id}`}>
            <Box>
                <WildHealthRadio
                    checked={recommendation.isSelected}
                    onClick={() => handleSelectedNew(recommendation.id, !recommendation.isSelected)}
                />
            </Box>
            <Box className={classes.recommendation}>
                <button onClick={() => hendleUpdateRecommendation(recommendation)} className={classes.recommendationUpdate}>
                    <Box mr={1}>Update</Box>
                    <EditIcon style={{ color: colors.white, fontSize: 16 }}/>
                </button>
                <Box pl={2.5} pt={1.5}>{recommendation.content}</Box>
            </Box>
        </Box>
    }

    const getRecommendationForCompere = (id: number | string, content: string) => {
        const theSameRecommendation = newRecommendation.find(el => el.baseId === id)
        if (theSameRecommendation && theSameRecommendation.content.trim() !== content.trim()) {
            return <Box width="50%" display="flex">
                {renderRecommendationNew(theSameRecommendation)}
            </Box>

        }
        return theSameRecommendation ? <Box width="50%" display="flex"> </Box> : <Box className={classes.noRecommendationNew}> no new recommendation</Box>
    }

    const renderCurrentRecommendationsBloc = (el: RecommendationDisplayModel) => {
        return <Box display="flex" mb={1.5} key={`${el.type}-${el.id}`}>
            <Box display="flex" width="50%">
                {renderRecommendationCurrent(el)}
            </Box>
            {getRecommendationForCompere(el.baseId, el.content)}
            <Divider className={commonClasses.renderDivider} />
        </Box>
    }

    const renderNewRecommendationsBloc = (el: RecommendationDisplayModel) => {
        return <Box display="flex" mb={1.5} key={`${el.type}-prev-${el.id}`}>
            <Box className={classes.noRecommendation}> no previous recommendation</Box>
            <Box display="flex" width="100%">
                {renderRecommendationNew(el)}
            </Box>
            <Divider className={commonClasses.renderDivider} />
        </Box>
    }

    return (
        <Box display="flex" flexDirection="column">
            <Box width="100%" display="flex" pl={1.5} mb={1.5} >
                <Box display="flex" width="50%" color="secondary" className={classes.textSecondary}>previous recommendations </Box>
                <Box display="flex" width="50%" className={classes.textSecondary}>new recommendations </Box>
            </Box>

            {
                currentRecomemendation && newRecommendation && currentRecomemendation.length && newRecommendation.length
                    ? <>
                        <Box width="100%" display="flex" flexDirection="column">{newRecommendation.filter(recommendation => !currentRecomemendation.find(el => el.baseId === recommendation.baseId))
                            .map(recommendation => renderNewRecommendationsBloc(recommendation))}
                        </Box>
                        <Box width="100%" display="flex" flexDirection="column">
                            {currentRecomemendation.map(recommendation => renderCurrentRecommendationsBloc(recommendation))}
                            {renderAddRecommendation()}
                        </Box>
                    </>
                    : <Box width="100%" display="flex">
                        <Box display="flex" width="50%" flexDirection="column" alignItems="center" justifyContent="center">
                            {
                                currentRecomemendation && currentRecomemendation.length
                                    ? currentRecomemendation.map(recommendation => renderRecommendationCurrent(recommendation))
                                    : renderPlaceHolder("No Previous Recommendations")
                            }
                            {renderAddRecommendation()}
                        </Box>
                        <Box display="flex" width="50%" flexDirection="column" alignItems="center" justifyContent="center">
                            {
                                newRecommendation && newRecommendation.length
                                    ? newRecommendation.map(recommendation => renderRecommendationNew(recommendation))
                                    : renderPlaceHolder("No New Recommendations")
                            }
                        </Box>
                    </Box>
            }

        </Box>
    );
}
