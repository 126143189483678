import { OrderType } from "../../orders/models/orders.models";

export enum PaymentStrategy {
    FullPayment = 1,
    PartialPayment = 5
}

export enum PaymentPriceType {
    Default,
    Insurance,
    PromoCode,
    InsurancePromoCode = 3
}

export const PaymentPriceTypeNames: { [type: number]: string } = {
    [PaymentPriceType.Default]: 'Default',
    [PaymentPriceType.Insurance]: 'Insurance',
    [PaymentPriceType.PromoCode]: 'PromoCode',
    [PaymentPriceType.InsurancePromoCode]: 'InsurancePromoCode'
}

export interface PaymentCouponModel {
    name?: string;
    id?: number;
    code: string;
    detail: string;
}

export interface PaymentPriceModel {
    id: number;
    discount: number;
    price: number;
    originalPrice: number;
    startupFee: number;
    type: PaymentPriceType;
    strategy: PaymentStrategy;
    isExclusive: boolean;
    isAddOnsForFree: boolean;
    freeAddOnsTypes: OrderType[];
    isAutoRenewal: boolean;
    paymentCoupon: PaymentCouponModel;
    description: string;
    discounts: DiscountModel[];
}

export interface DiscountModel{
    name: string;
    amount: number;
    source: DiscountSource;
}

export enum DiscountSource {
    AnnualSaving = 5,
    Insurance = 10,
    Coupon = 15,
    StartupFee = 20
}

export const DiscountSourceNames = new Map<DiscountSource, string>([
    [DiscountSource.AnnualSaving, "AnnualSaving"],
    [DiscountSource.Insurance, "Insurance"],
    [DiscountSource.Coupon, "Coupon"],
    [DiscountSource.StartupFee, "StartupFee"],
]);