import React from "react";
import { Box } from "@material-ui/core";
import { WildHealthPlaceHolderBase } from "./WildHealthPlaceHolderBase";
import emptyMeds from "@img/placeholders/EmptyMeds.png";
import { useStyles } from "./commonPlaceHolder.styles";
import { CommonPlaceHolderProps } from "./CommonPlaceHolder";

interface SupplementsPlaceHolderProps extends CommonPlaceHolderProps {
}

export const SupplementsPlaceHolder: React.FC<SupplementsPlaceHolderProps> = (props: SupplementsPlaceHolderProps) => {
    const classes = useStyles();

    return (
        <Box mt={3}>
            <WildHealthPlaceHolderBase message={props.message}>
                <img
                    src={emptyMeds}
                    alt="img" className={classes.image} />
            </WildHealthPlaceHolderBase>
        </Box>
    );
}
