import React from "react";
import {IconButton} from "@material-ui/core";
import { ReactComponent as PlaygroundIcon } from '@img/icons/PlaygroundIcon.svg';
import {navigationService} from "../../../../services/navigation.service";
import {useHistory} from "react-router";

export const PlaygroundButton: React.FC = () => {
    const history = useHistory();

    return (
        <IconButton className="wh-tw-text-primaryV" onClick={() => navigationService.toGenericPlayground(history)}>
            <PlaygroundIcon/>
        </IconButton>
    )
}