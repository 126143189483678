import { Box, Container, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { PaymentPlanModel } from '../../models/paymentPlan.models';
import { useStyles } from './ConsultationComponent.styles';
import { useFacade } from "./ConsultationComponent.hooks";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { Widget } from '@typeform/embed-react'

interface SelectPaymentPlanComponentProps {
  plans: PaymentPlanModel[];
  planName?: string;
  consultationFormId: string;
}

export const ConsultationComponent: React.FC<SelectPaymentPlanComponentProps> = (props: SelectPaymentPlanComponentProps) => {
  const {
    plans,
    planName,
    consultationFormId,
  } = props;

  useFacade(plans, planName);

  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));


  if (!plans || !plans.length) {
    return <WildHealthLinearProgress />
  }

  return (
    <>
      <Container className={classes.container}>
        <Box mt={2} pb={2} className={isSmallScreen ? classes.mainSmall : classes.main}>
          <Widget
            id={consultationFormId}
            className={classes.consultationWidget}
          />
        </Box>
      </Container>
    </>
  )
}
