import { createInitialState, SnackStore, snackStore } from './snack.store';
import { SnackState, SnackType } from './snack.models';
import { httpStatusCode } from '../../constants/httpStatusCode';
import { Track, PlatformName } from '../../../tracking/tracker';
import { authQuery } from '../../../auth/stores/auth';
import { UserType } from '../../../auth/models/auth.enums';

/**
 * Populate needed options with default values
 * @param options
 */
const mapOptions = (options: SnackState): SnackState => {
    let result = createInitialState();

    let keys = Object.keys(result);
    for (let k of keys) {
        if (options[k] !== result[k]) {
            result[k] = options[k];
        }
    }

    return result;
}

/**
 * Provides service for showing notifications through snacks
 */
export class SnackService {
    constructor(private snackStore: SnackStore) {
    }

    private fireSegmentErrorEvent(error: any) {
        if (typeof error !== "string") {
            Track('error', {
                description: error?.response?.data,
                user_id: authQuery.getId(),
                user_type: authQuery.getType() === UserType.Employee ? 'employee' : 'patient',
                platform: PlatformName()
            });
            return;
        } else {
            Track('error', {
                description: error,
                user_id: authQuery.getId(),
                user_type: authQuery.getType() === UserType.Employee ? 'employee' : 'patient',
                platform: PlatformName()
            });
            return;
        }
    }

    private extractMessages(error: any): string[] {
        return error?.response?.data?.messages ?? this.getDefaultErrorMessage(error);
    }

    private createErrorMessage(error: any | string, options: SnackState = null): SnackState {
        options.messages = typeof error !== "string" ? this.extractMessages(error) : [error];
        options.type = SnackType.error;
        options.duration = 10000;

        return options;
    }

    private getDefaultErrorMessage(error: any): string[] {
        try {
            switch (error.response.status) {
                case httpStatusCode.BadRequest:
                    return ["There was a problem submitting the data. Please verify the information and try again."];
                case httpStatusCode.Unauthorized:
                    return ["Hmm, something's not right. Please log in to try again."];
                case httpStatusCode.Forbidden:
                    return ["Looks like you don't have access to this page! Please contact support if you believe this is an error."];
                case httpStatusCode.NotFound:
                    return ["This page does not exist. Contact support if you believe this is an error."];
                case httpStatusCode.RequestTimeout:
                    return ["Your request has timed out. Please reload the page."];
                case httpStatusCode.Conflict:
                    return ["Technical difficulties! Please reload the page."];
                case httpStatusCode.UnsupportedMediaType:
                    return ["Hmm, something's not right. Please reload the page. Contact support if you believe this is an error."];
                case httpStatusCode.ServiceUnavailable:
                    return ["Service unavailable"];
                default:
                    return ['Operation failed.'];
            }
        }
        catch {
            return ["Operation failed."];
        }

    }

    /**
     * Show snackbar
     * @param options
     */
    show(options: SnackState) {
        let validOptions = mapOptions(options);
        validOptions.isOpen = true;
        this.snackStore.update(validOptions);

        setTimeout(() => this.hide(this.snackStore), validOptions.duration);
    }

    /**
     * Hide snackbar
     */
    hide(store) {
        store.update({ isOpen: false } as SnackState);
    }

    /**
     * Show success message
     * @param message
     * @param options
     */
    success(message, options: SnackState = null) {
        if (!options) {
            options = createInitialState();
        }
        options.messages = [message];

        this.show(options);
    }

    /**
     * Show message on mobile
     * @param message
     */
    postNativeDeviceEvent(message) {
        if ((window as any).webkit != undefined) {
            if ((window as any).webkit.messageHandlers.iOSNative != undefined) {
                (window as any).webkit.messageHandlers.iOSNative.postMessage(message)
            }
        }
        if ((window as any).appInterface != undefined) {
            (window as any).appInterface.postMessage(message)
        }
    }

    /**
     * Shows deleteSuccess message
     * @param message
     * @param options
     */
    deleteSuccess(message, options: SnackState = null) {
        if (!options) {
            options = createInitialState();
        }

        this.show(this.createErrorMessage(message, options));
    }

    /**
     * Shows cancelledSuccess message
     * @param message
     * @param options
     */
    cancelledSuccess(message, options: SnackState = null) {
        if (!options) {
            options = createInitialState();
        }

        this.show(this.createErrorMessage(message, options));
    }

    /**
     * Shows error message
     * @param message
     * @param options
     */
    error(message, options: SnackState = null) {
        if (!options) {
            options = createInitialState();
        }

        this.show(this.createErrorMessage(message, options));
    }

    /**
     * Shows warning message
     * @param message
     * @param options
     */
    warning(message, options: SnackState = null) {
        if (!options) {
            options = createInitialState();
        }
        options.messages = [message];
        options.type = SnackType.warning;
        this.show(options);
    }

    /**
     * Show info message
     * @param message
     * @param options
     */
    info(message, options: SnackState = null) {
        if (!options) {
            options = createInitialState();
        }
        options.messages = [message];
        options.type = SnackType.info;
        this.show(options);
    }

    commonErrorHandler(error: any, options: SnackState = null) {
        if (!options) {
            options = createInitialState();
        }

        this.show(this.createErrorMessage(error, options));
        this.fireSegmentErrorEvent(error);
    }
}

export const snackService = new SnackService(snackStore);
