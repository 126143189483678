export class ImageConstants {
  public static loginBackgroundPath = '/images/bg.jpg';
  public static clarityPdfHeaderPath = '/images/logos/WH/Clarity_pdf_header.jpg';
  public static licensingWHHeaderLogoPath = '/images/logos/Licensing/wh_header_logo.jpeg';
  public static commonPlaceHolderPath = '/images/placeholders/Common.png';
  public static emptyReportPath = '/images/placeholders/EmptyReport.png';
  public static emptyReportCFPath = '/images/placeholders/EmptyReportCF.png';
  public static healthReportPg2ImagePath = '/images/HealthReport_Pg2_Image.jpg';
  public static healthReportPg8ImagePath = '/images/HealthReport_Pg8_Image.jpg';
  public static healthReportPg12ImagePath = '/images/HealthReport_Pg12_Image.jpg';
  public static healthReportPg13GraphPath = '/images/HealthReport_Pg13_graph.jpg';
  public static healthReportPg13ImagePath = '/images/HealthReport_Pg13_Image1.png';
  public static healthReportPg14ImagePath = '/images/HealthReport_Pg14_Image.jpg';
  public static healthReportPg16ImagePath = '/images/HealthReport_Pg16_Image.jpg';
  public static healthReportPg18ImagePath = '/images/HealthReport_Pg18_Image.jpg';
  public static healthReportPg23ImagePath = '/images/HealthReport_Pg23_Image.jpg';
  public static healthReportRoadMapPath = '/images/HealthReport_Road_Map.jpg';
  public static videoPreviewImagePath = '/images/video_preview.jpg';
  public static whCoverImagePath = '/images/WH_Cover_Image.jpg';
}
