import React from "react";
import { Box, Button, ButtonGroup, CircularProgress, Divider, IconButton, useTheme, useMediaQuery } from "@material-ui/core";
import { colors } from "../../../common/constants/colors";
import GetAppIcon from "@material-ui/icons/GetApp";
import { PageTitleComponent } from "../../../../components/pageTitleComponent/PageTitleComponent";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { AuthenticatedLayoutComponent } from "../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent";
import { TitlesService } from "../../../../constants/title.constants";
import { MenuItemTypes } from "../../../../models/menu.models";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { useFacade } from "./patientSoapNotePage.hooks";
import commonUseStyles from "../../../common/styles/common.styles";
import {
    renderNextAppointmentDate,
    renderVitals,
    renderMedicationsAndSupplementsToStop, renderMedicationsAndSupplementsToStart, renderItemsSection, renderTextSection, renderEmptyMedicationsAndSupplementsToStart, renderEmptyMedicationsAndSupplementsToStop, renderMdm
} from "../../helpers/noteHelpers";
import { ActionsButton } from "../../../healthReport/components/healthReportToolbarComponent/healthReportToolbarComponent.styles";
import { TextMarkdownComponent } from "../../components/textMarkdownComponent/TextMarkdownComponent";
import clsx from 'clsx';
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import {NoteSignatureComponent} from "../../components/noteSignature/NoteSignatureComponent";
import {useFlags} from "launchdarkly-react-client-sdk";

export const PatientSoapNotePage: React.FC = (props: any) => {
    const commonClasses = commonUseStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const noteId = Number(props.match.params.noteId);
    const flags = useFlags();

    const [
        {
            note,
            content,
            internalContent,
            isPdfDownloading,
            mdmCodes
        },
        handleGoBack,
        handleDownloadPdf
    ] = useFacade(noteId);

    const isLoading = !content || !note;

    const displayDownloadPdfButton = () => {
        if (isPdfDownloading === true) {
            return (
                <Box width="70px" display="flex" justifyContent="center">
                    <CircularProgress size={24} style={{ color: colors.main }} />
                </Box>
            )
        }

        return (
            <ActionsButton id="patient-download-pdf" onClick={() => {
                handleDownloadPdf(note, content);
            }}>
                <Box display="flex" alignItems="center" mt={1}>
                    <Box>
                        <GetAppIcon />
                    </Box>
                </Box>
            </ActionsButton>
        );
    }

    const renderNoteHeader = () => {
        return (
            <Box display="flex " alignItems="center" justifyContent="space-between">
                <ButtonGroup size="large" variant="text" color="default" aria-label="large outlined primary button group">
                    <Button disabled>
                        <span className={commonClasses.secondaryTitle}>
                            {
                                note.name
                            }
                        </span>
                    </Button>
                    {
                        note.title &&
                        <Button disabled>
                            <span className={commonClasses.secondaryTitle}>
                                {
                                    note.title
                                }
                            </span>
                        </Button>
                    }
                </ButtonGroup>
                {
                    displayDownloadPdfButton()
                }
            </Box>
        )
    }

    const pageName = (
        <PageTitleComponent
            startAdornment={
                <>
                    <IconButton id="patient-soap-note-go-back" onClick={() => handleGoBack()}>
                        <ArrowBackIcon />
                    </IconButton>
                    <span className={commonClasses.subtitle}>
                        back to notes
                    </span>
                </>
            }
            title={''}
            endAdornment={null}
        />
    );

    const renderPlan = () => {
        return (
            <Box>
                <span className={isSmallScreen ? commonClasses.mediumTitle : commonClasses.secondaryTitle}>
                    Plan
                </span>
                <Box py={2}>
                    <Divider />
                </Box>
                <span style={{ whiteSpace: 'pre-line' }} className={isSmallScreen ? commonClasses.smallTitle : commonClasses.subtitle}>
                    {
                        internalContent.plan.planText
                            ? <TextMarkdownComponent content={internalContent.plan?.planText} />
                            : 'These are our main recommendations from this visit. These should be your primary focus.'
                    }
                </span>
            </Box>
        )
    }

    const renderNoteContent = (): JSX.Element => {
        return (
            <Box>
                {
                    isSmallScreen ? (
                        <Box mb="30px">
                            <Box className={commonClasses.secondaryTitle}>{note.name}</Box>
                            {
                                note.title &&
                                <Box mt={1} className={clsx(commonClasses.mediumTitle)}>{note.title}</Box>
                            }
                        </Box>
                    ) : renderNoteHeader()
                }
                <Box p={!isSmallScreen && 1.8}>
                    <Box>
                        <Box>
                            {
                                renderPlan()
                            }
                        </Box>
                        {
                            internalContent.plan.labsText?.length > 0 &&
                            <Box mt={isSmallScreen ? "20px" : 4}>
                                {
                                    renderTextSection('Labs', internalContent.plan.labsText, commonClasses, isSmallScreen)
                                }
                            </Box>
                        }
                        {
                            internalContent.diagnosis?.length > 0 &&
                            <Box mt={4}>
                                {
                                    renderItemsSection('Diagnosis', internalContent.diagnosis.map(x => {
                                        return x.additionalInformation 
                                            ? x.code + " " +  x.description + ' - ' + x.additionalInformation
                                            : x.code + " " +  x.description
                                    }), commonClasses, isSmallScreen, true, '', true)
                                }
                            </Box>
                        }
                        {
                            internalContent.subjective?.length > 0 &&
                            <Box mt={4}>
                                {
                                    renderTextSection('Subjective', internalContent.subjective, commonClasses, isSmallScreen)
                                }
                            </Box>
                        }
                        {
                            internalContent.ROS.rosEnabled &&
                            <Box mt={4}>
                                {
                                    renderItemsSection('ROS', internalContent.ROS.rosValues, commonClasses, isSmallScreen, true, internalContent.ROS.rosValues.length > 0 ? 'All systems reviewed and negative except:' : "All systems reviewed and negative")
                                }
                            </Box>
                        }
                        {
                            (internalContent.medicationsSupplements.supplements.filter(x => !x.isInCurrent).length > 0 ||
                                internalContent.medicationsSupplements.medications.filter(x => !x.isInCurrent).length > 0 ||
                                internalContent.medicationsSupplements.rxntMedications?.filter(x => !x.isInCurrent).length > 0 ||
                                internalContent.medicationsSupplements.fullscriptSupplements?.length > 0) &&
                            <Box mt={4}>
                                {
                                    renderMedicationsAndSupplementsToStart(internalContent.medicationsSupplements, commonClasses, flags)
                                }
                            </Box>
                        }
                        {
                            (internalContent.medicationsSupplements.supplements.filter(x => !x.isInCurrent).length <= 0 &&
                                internalContent.medicationsSupplements.medications.filter(x => !x.isInCurrent).length <= 0 &&
                                internalContent.medicationsSupplements.rxntMedications?.filter(x => !x.isInCurrent).length <= 0 &&
                                internalContent.medicationsSupplements.fullscriptSupplements?.length <= 0) &&
                            <Box mt={4}>
                                {
                                    renderEmptyMedicationsAndSupplementsToStart(commonClasses)
                                }
                            </Box>
                        }
                        {
                            (internalContent.medicationsSupplements.supplements.filter(x => x.isStopped).length > 0 ||
                                internalContent.medicationsSupplements.rxntMedications?.filter(x => x.isStopped).length > 0 ||
                                internalContent.medicationsSupplements.medications.filter(x => x.isStopped).length > 0) &&
                            <Box mt={4}>
                                {
                                    renderMedicationsAndSupplementsToStop(internalContent.medicationsSupplements, commonClasses, flags)
                                }
                            </Box>
                        }
                        {
                            (internalContent.medicationsSupplements.supplements.filter(x => x.isStopped).length <= 0 &&
                                internalContent.medicationsSupplements.rxntMedications?.filter(x => x.isStopped).length <= 0 &&
                                internalContent.medicationsSupplements.medications.filter(x => x.isStopped).length <= 0) &&
                            <Box mt={4}>
                                {
                                    renderEmptyMedicationsAndSupplementsToStop(commonClasses)
                                }
                            </Box>
                        }
                        <Box mt={4}>
                            {
                                renderVitals(internalContent.physicalData, commonClasses, isSmallScreen)
                            }
                        </Box>
                        {
                            internalContent.mdmData.selectedCodeId &&
                            <Box mt={4}>
                                {
                                    renderMdm(internalContent.mdmData, mdmCodes, commonClasses, internalContent.duration?.value)
                                }
                            </Box>
                        } 
                        {
                            note.nextCoachAppointmentDate && note.nextProviderAppointmentDate &&
                            <Box pt={4}>
                                {
                                    renderNextAppointmentDate(note.nextCoachAppointmentDate, note.nextProviderAppointmentDate, commonClasses, isSmallScreen)
                                }
                            </Box>
                        }
                        <Box mt={4}>
                            <NoteSignatureComponent note={note} noteId={note.id} />
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    }

    return (
        <AuthenticatedLayoutComponent
            title={TitlesService.noteDetailTitle}
            pageName={pageName}
            selectedMenuItem={MenuItemTypes.Notes}
        >
            <Box className={isSmallScreen && commonClasses.bgMain}>
                {isSmallScreen && (
                    <>
                        <Box py={2} display="flex" alignItems="center">
                            <IconButton id="patient-soap-note-go-back" onClick={() => handleGoBack()}>
                                <ArrowBackIcon />
                            </IconButton>
                            <Box className={clsx(commonClasses.size14, commonClasses.textRegular, commonClasses.colorGray1)}>
                                Back to Notes
                            </Box>
                        </Box>
                        <Box position="fixed" right={16} bottom={40} zIndex={1202}>
                            <WildHealthButton id="download-pdf" width="48px" loading={isPdfDownloading} style={{ borderRadius: "50%", minWidth: 48, height: 48 }} onClick={() => handleDownloadPdf(note, content)}>
                                <GetAppIcon className={commonClasses.colorWhite} />
                            </WildHealthButton>
                        </Box>
                    </>
                )}
                {
                    isLoading ?
                        <WildHealthLinearProgress /> :
                        <Box p={isSmallScreen ? 2 : 5} pb={4} className={isSmallScreen && commonClasses.bgWhiteMain}>
                            {
                                renderNoteContent()
                            }
                        </Box>
                }
            </Box>
        </AuthenticatedLayoutComponent>
    )
};