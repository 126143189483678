import { makeStyles, Theme } from "@material-ui/core";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) => ({
    widget: {
        border: `1px solid ${colors.stroke}`,
        boxShadow: '0px 4px 10px rgba(168, 173, 198, 0.12)',
        borderRadius: '2px',
        marginBottom: 24,
        backgroundColor: colors.white,
    },
    switcherReverse: {
        transform: 'rotate(180deg)',
    },
    subtitle: {
        fontWeight: 500,
    },
    active: {
        color: colors.main,
        fontWeight: 500,
    },
    title: {
        textAlign: 'center',
        paddingBottom: 30,
        [theme.breakpoints.down('xs')]: {
            paddingTop: 4,
            paddingBottom: 20,
            textAlign: 'left'
        },
    }
}));
