import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from "../../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrappQuestionnaires: {
            background: colors.white,
            border: `1px solid ${colors.stroke}`,
            boxShadow: '0px 7px 60px rgba(168, 173, 198, 0.12)',
            borderRadius: '2px',
            maxWidth: 732,
            margin: '0 auto',
            marginBottom: 10,
            padding: 30,
        }
    })
);
