import { Box, Dialog, DialogActions, DialogContent, Grid, TextField, Typography } from "@material-ui/core";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import clsx from 'clsx';
import React from 'react';
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { useFacade } from "./supplementComponent.hooks";
import { useStyles } from "./supplementComponent.styles";
import { SupplementComponentFieldNames } from "./supplementComponent.validator";
import { SupplementComponentProps } from "./supplementComponent.props";


export const SupplementAddNewDialogComponent: React.FC<SupplementComponentProps> = (props: SupplementComponentProps) => {
    const {
        isOpened,
     } = props;

    const classes = useStyles();
    const [
        {
            model,
            errors,
        },
        handleChange,
        handleClose,
        handleSubmit,
    ] = useFacade(props);

    return (
        <Dialog
            onClose={() => handleClose()}
            open={isOpened}
        >
            <MuiDialogTitle
                className={classes.dialogTitle}
                disableTypography
            >
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <Typography variant="h6">New Supplement</Typography>
                </Grid>
            </MuiDialogTitle>

            <DialogContent dividers>
                <Box mb={1} display="flex" flexDirection="column">
                    <TextField
                        required
                        fullWidth
                        size="small"
                        label="Name"
                        variant="outlined"
                        value={model.name}
                        className={clsx(classes.field, 'input')}
                        error={!!errors[SupplementComponentFieldNames.itemName]}
                        helperText={errors[SupplementComponentFieldNames.itemName]}
                        onChange={(event) => handleChange(SupplementComponentFieldNames.itemName, event.target.value)}
                    />
                    <TextField
                        fullWidth
                        size="small"
                        label="Strength"
                        variant="outlined"
                        value={model.dosage}
                        className={clsx(classes.field, 'input')}
                        error={!!errors[SupplementComponentFieldNames.dosage]}
                        helperText={errors[SupplementComponentFieldNames.dosage]}
                        onChange={(event) => handleChange(SupplementComponentFieldNames.dosage, event.target.value)}
                    />
                    <TextField
                        fullWidth
                        size="small"
                        label="Dosing Instructions"
                        variant="outlined"
                        value={model.instructions}
                        className={clsx(classes.field, 'input')}
                        error={!!errors[SupplementComponentFieldNames.instructions]}
                        helperText={errors[SupplementComponentFieldNames.instructions]}
                        onChange={(event) => handleChange(SupplementComponentFieldNames.instructions, event.target.value)}
                    />
                    <TextField
                        fullWidth
                        size="small"
                        label="Purchase Link"
                        variant="outlined"
                        value={model.purchaseLink}
                        className={clsx(classes.field, 'input')}
                        error={!!errors[SupplementComponentFieldNames.purchaseLink]}
                        helperText={errors[SupplementComponentFieldNames.purchaseLink]}
                        onChange={(event) => handleChange(SupplementComponentFieldNames.purchaseLink, event.target.value)}
                    />
                </Box>
            </DialogContent>

            <DialogActions>
                <WildHealthButton
                    id="supplements-add-new-cancel"
                    onClick={() => handleClose()}
                    color="tertiary"
                >
                    Cancel
                </WildHealthButton>
                <WildHealthButton
                    id="supplements-add-new-create"
                    onClick={() => handleSubmit()}
                    autoFocus
                >
                    Create
                </WildHealthButton>
            </DialogActions>
        </Dialog>
    );
}
