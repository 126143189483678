import {useFlags} from 'launchdarkly-react-client-sdk';
import {FeatureFlag} from './featureFlags.models';
import {isFeatureFlag} from './featureFlags';
import React from 'react';

export interface FeatureComponentProps {
    featureFlag?: FeatureFlag;
    notFeatureFlag?: FeatureFlag;
    children: React.ReactNode;
}

export const FeatureComponent: React.FC<FeatureComponentProps> = (props: FeatureComponentProps) => {
    const featureFlags = useFlags();
    return <>
        {!!(props.featureFlag && isFeatureFlag(featureFlags, props.featureFlag)) && props.children}
        {(props.notFeatureFlag && !isFeatureFlag(featureFlags, props.notFeatureFlag)) && props.children}
    </>
};

