import { AddOnModel } from '../models/addOns.models';
import { AddOnsState, AddOnsStore, addOnsStore } from './addOns.store';
import { Query } from '@datorama/akita';
import { OrderType } from '../../orders/models/orders.models';

/**
 * Provides method for working with AddOns store
 */
export class AddOnsQuery extends Query<AddOnsState> {
    addOns$ = this.select(state => state.addOns);

    constructor(protected store: AddOnsStore) {
        super(store);
    }

    public getToken(): Array<AddOnModel> {
        return this.getValue().addOns;
    }

    public getByType(type: OrderType): Array<AddOnModel> {
        return this.getValue().addOns.filter(i => i.orderType === type);
    }
}

export const addOnsQuery = new AddOnsQuery(addOnsStore);
