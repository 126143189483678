import React, { useEffect, useRef, useState } from 'react';
import Recaptcha from 'react-google-invisible-recaptcha';
import { useHistory } from 'react-router';
import { useLocation } from "react-router-dom";
import { Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { SITE_KEY } from "../../../../config";
import { navigationService } from "../../../../services/navigation.service";
import { AddOnModel, SelectAddOnModel } from "../../../addons/models/addOns.models";
import { addOnsService } from "../../../addons/services/addOns.service";
import { addOnsQuery } from "../../../addons/stores";
import { getLastObject } from "../../../common/helpers/get-last-object";
import { onEmit } from "../../../common/helpers/on-emit";
import { IErrorState } from "../../../common/validation/error-state";
import { RegisterPreauthorizePatientModel } from "../../../patients/models/patient.model";
import { patientsService } from "../../../patients/services/patients.service";
import {MerchantCredentialsModel, PayerInformation} from "../../models/payment.models";
import { PaymentPriceModel} from "../../models/paymentPrice.models";
import { authService } from '../../../auth/services/auth.service';
import { PlatformName, Track } from "../../../tracking/tracker";
import { authQuery } from '../../../auth/stores/auth';
import { AuthConstants } from '../../../auth/constants/auth.constants';
import { RoutesConstants } from '../../../../constants/route.constants';
import { OrderType } from "../../../orders/models/orders.models";
import { usersService } from "../../../../services/users.service";
import { getFBTraits } from "../../../common/helpers/get-fb-traits";
import { useFlags } from "launchdarkly-react-client-sdk";
import { isFeatureFlag } from "../../../common/components/featureFlags/featureFlags";
import { FeatureFlag } from "../../../common/components/featureFlags/featureFlags.models";
import moment from "moment";
import { addressQuery } from '../../../account/stores/addressStore';
import {finishCheckoutPreauthorizeComponentValidator} from "./finishCheckoutPreauthorizeComponent.validator";
import {PreauthorizeRequestModel} from "../../models/preauthorizeRequest.model";
import {SetPasswordModel} from "../../../common/models/user.models";
import {paymentQuery} from "../../stores/paymentStore";
import {paymentService} from "../../services/payment.service";
import {PaymentPeriodModel} from "../../models/paymentPeriod.models";

export enum PreauthorizeGetStartedStep {
    HealthConcern = 0,
    ClinicallyProvenResults = 1,
    WorkingTogether = 2,
    SetupPassword = 3,
    Congratulations = 4,
    NameInterstitial = 5,
    PersonalInfo = 6,
    Shipping = 11,
    Payment = 12,
    Review = 13
}

export enum HealthConcernStatus {
    MaximizeHealthSpanLongevity,
    TreatNewChronicConditions,
    OptimizeMyOverallHealth,
    ElevateMyPerformance
}

const context = {};

interface ScrollElement {
    current: HTMLDivElement
}

/**
 * Represents payment form state
 */
interface FinishCheckoutPreauthorizeComponentState extends IErrorState {
    practiceId: number | null;
    isSubmitted: boolean;
    payer: PayerInformation;
    addOns: SelectAddOnModel[];
    sameAsBillingAddress: boolean;
    step: PreauthorizeGetStartedStep;
    paymentError: string;
    isAgreeContinue: boolean;
    isAgreeSMS: boolean;
    isLoading: boolean;
    healthConcernStatus: HealthConcernStatus;
    exclusivePrices: PaymentPriceModel[];
    showBottom: boolean;
    enterDiscountCode: boolean;
    isGetUserInfo: boolean;
}

/**
 * Custom Hook to manage a view Model for SelectPlan page view components
 */
export function useFacade(preauthorizeRequest: PreauthorizeRequestModel, paymentPeriod: PaymentPeriodModel, handleSetProgress: Function): [
    FinishCheckoutPreauthorizeComponentState,
    boolean,
    boolean,
    (field: string, value: string) => void,
    () => JSX.Element,
    (id: number, value: boolean) => void,
    () => SelectAddOnModel[],
    () => void,
    () => void,
    () => void,
    (event: React.ChangeEvent<HTMLInputElement>, field: string) => void,
    () => boolean,
    () => boolean,
    () => boolean,
    () => boolean,
    () => boolean,
    (status: HealthConcernStatus) => void,
    ScrollElement,
    (field: string) => void
] {

    const prevScrollY = useRef<HTMLHeadingElement>(null);

    const emptyAddress = {
        country: 'USA',
        city: '',
        state: '',
        zipCode: '',
        streetAddress1: '',
        streetAddress2: '',
    }

    const history = useHistory();
    const featureFlags = useFlags();
    const { pathname } = useLocation();
    const isMarketingSMSButton = isFeatureFlag(featureFlags, FeatureFlag.MarketingSmsButton);
    const mobileGetStartedPath = pathname.toLowerCase().includes(RoutesConstants.mobileGetStarted);
    const isConsultationGetStartedPath = pathname.toLowerCase().includes(RoutesConstants.consultationGetStarted);

    const recaptcha = useRef(null);
    const payer = {
        firstName: preauthorizeRequest.firstName,
        lastName: preauthorizeRequest.lastName,
        email: preauthorizeRequest.email,
        phoneNumber: '',
        birthday: null,
        gender: 99,
        password: '',
        confirmPassword: '',
        billingAddress: Object.assign({}, emptyAddress),
        shippingAddress: Object.assign({}, emptyAddress)
    }
    const [state, setState] = useState({
        payer: payer,
        isSubmitted: false,
        errors: {},
        addOns: [],
        sameAsBillingAddress: true,
        step: PreauthorizeGetStartedStep.HealthConcern,
        paymentError: null,
        isAgreeContinue: false,
        isAgreeSMS: false,
        isLoading: false,
        healthConcernStatus: null,
        exclusivePrices:[],
        showBottom: true,
        enterDiscountCode: false,
        isGetUserInfo: true
    } as FinishCheckoutPreauthorizeComponentState);

    const handleSubmitNextStep = () => {
        const nextStep = getNextStep();
        switch (state.step) {
            case PreauthorizeGetStartedStep.HealthConcern:
                Track("button_click", {
                    type: 'onboarding_results',
                    acValue: 'checkout_social_proof',
                    platform: PlatformName()
                })
                Track("onboarding_results", {
                    group: 'test',
                    platform: PlatformName(),
                })
                setState({
                    ...state,
                    step: nextStep,
                });

                return navigationService.goUp();
            case PreauthorizeGetStartedStep.ClinicallyProvenResults:
                Track("onboarding_team", {
                    group: 'test',
                    platform: PlatformName(),
                })
                setState({
                    ...state,
                    step: nextStep,
                });

                return navigationService.goUp();
            case PreauthorizeGetStartedStep.WorkingTogether:
                Track("onboarding_sign_up", {
                    group: 'test',
                    platform: PlatformName(),
                })
                setState({
                    ...state,
                    step: nextStep,
                });
                handleSetProgress(getProgress(nextStep));
                return navigationService.goUp();
            case PreauthorizeGetStartedStep.SetupPassword:
                return validateSetupPassword() && setupPassword();
            case PreauthorizeGetStartedStep.Congratulations:
                Track("onboarding_first_name", {
                    group: 'test',
                    platform: PlatformName(),
                })
                setState({
                    ...state,
                    step: nextStep,
                });

                return navigationService.goUp();
            case PreauthorizeGetStartedStep.NameInterstitial:
                if (validateNameInterStep()) {
                    Track("onboarding_personal_info", {
                        group: 'test',
                        platform: PlatformName(),
                    })
                    setState({
                        ...state,
                        step: nextStep,
                    });
                    handleSetProgress(getProgress(nextStep));

                    return navigationService.goUp();
                }
                return;
            case PreauthorizeGetStartedStep.PersonalInfo:
                if (validatePersonalInfoStep()) {
                    Track("button_click", {
                        type: 'billing_details',
                        acValue: 'billing_details',
                        platform: PlatformName()
                    })

                    updateUserStep2();
                }
                return;
            case PreauthorizeGetStartedStep.Shipping:
                if (validateShippingStep()) {
                    updateUserAddress();
                }
                return;
            case PreauthorizeGetStartedStep.Payment:
                if (validatePaymentStep()) {
                    Track("Button Click", {
                        type: 'checkout_order_summary',
                        platform: PlatformName()
                    })
                    Track("onboarding_checkout", {
                        group: 'test',
                        platform: PlatformName(),
                    });

                    handleSetProgress(getProgress(nextStep));

                    setState({ ...state, step: nextStep });

                    return navigationService.goUp();
                }
                return;
            case PreauthorizeGetStartedStep.Review:
                handleSubmitRegistration()
                return;
        }
    }

    const updateUserStep2 = () => {
        const user = {
            email: state.payer.email,
            firstName: state.payer.firstName,
            lastName: state.payer.lastName,
            gender: state.payer.gender.toString(),
            birthDate: state.payer.birthday,
            phoneNumber: state.payer.phoneNumber,
            smsMarketing: state.isAgreeSMS,
            shippingAddress: {...state.payer.shippingAddress, state: state.payer.state.abbreviation},
            billingAddress: {...state.payer.billingAddress, state: state.payer.state.name},
            meetingRecordingConsent: true
        };

        setState({
            ...state,
            isLoading: true
        });

        usersService.update(user).subscribe(() => {
            const payer = state.payer;
            payer.shippingAddress = state.payer.billingAddress;

            if (!payer.billingAddress.state) {
                payer.billingAddress.state = payer.state.name
            }

            Track("onboarding_billing_info", {
                group: 'test',
                platform: PlatformName(),
            })

            const nextStep = getNextStep();

            handleSetProgress(getProgress(nextStep));

            setState({
                ...state,
                payer: payer,
                step: nextStep,
                sameAsBillingAddress: false,
                isLoading: false
            });

            navigationService.goUp();
        }, () => {
            setState({
                ...state,
                isLoading: false
            });
        });
    }

    const updateUserAddress = () => {
        const user = {
            email: state.payer.email,
            firstName: state.payer.firstName,
            lastName: state.payer.lastName,
            gender: state.payer.gender.toString(),
            birthDate: state.payer.birthday,
            phoneNumber: state.payer.phoneNumber,
            smsMarketing: state.isAgreeSMS,
            shippingAddress: {...state.payer.shippingAddress, state: addressQuery.findState(state.payer.shippingAddress?.state)?.abbreviation},
            billingAddress: state.payer.billingAddress,
            meetingRecordingConsent: true
        };

        setState({
            ...state,
            isLoading: true
        });

        usersService.update(user).subscribe(() => {
            Track("onboarding_billing_info", {
                group: 'test',
                platform: PlatformName(),
            })

            const nextStep = getNextStep();
            handleSetProgress(getProgress(nextStep));

            setState({
                ...state,
                step: nextStep,
                isLoading: false
            });

            return navigationService.goUp();
        }, () => {
            setState({
                ...state,
                isLoading: false
            });
        });
    }

    const setupPassword = () => {
        Track("patient_sign_up", {
            acValue: 'preauthorize',
            plan: 'preauthorize',
            platform: PlatformName()
        })

        const password = !state.payer.password
            ? uuidv4() + AuthConstants.uuidPasswordInterpolation
            : state.payer.password;

        const model: SetPasswordModel = {
            practiceId: state.practiceId,
            code: preauthorizeRequest.token,
            newPassword: password,
            confirmPassword: password
        }

        setState({
            ...state,
            isLoading: true
        });

        authService.setUpPreauthorizePassword(model).subscribe(
            () => {
                const nextStep = state.step + 1;

                setState({
                    ...state,
                    step: nextStep,
                    isLoading: false
                });

                Track("onboarding_social_proof", {
                    group: 'test',
                    platform: PlatformName(),
                })

                handleSetProgress(getProgress(nextStep));

                navigationService.goUp();
            }
        );
    }

    const getProgress = (step?: PreauthorizeGetStartedStep): PreauthorizeGetStartedStep => {
        switch (step ?? state.step) {
            case PreauthorizeGetStartedStep.HealthConcern: return 14;
            case PreauthorizeGetStartedStep.ClinicallyProvenResults: return 14;
            case PreauthorizeGetStartedStep.WorkingTogether: return 14;
            case PreauthorizeGetStartedStep.SetupPassword: return 28;
            case PreauthorizeGetStartedStep.Congratulations: return 43;
            case PreauthorizeGetStartedStep.NameInterstitial: return 43;
            case PreauthorizeGetStartedStep.PersonalInfo: return 58;
            case PreauthorizeGetStartedStep.Shipping: return 72;
            case PreauthorizeGetStartedStep.Payment: return 72;
            case PreauthorizeGetStartedStep.Review: return 72;
        }
    }

    const getNextStep = (step?: PreauthorizeGetStartedStep): PreauthorizeGetStartedStep => {
        switch (step ?? state.step) {
            case PreauthorizeGetStartedStep.HealthConcern: return PreauthorizeGetStartedStep.ClinicallyProvenResults;
            case PreauthorizeGetStartedStep.ClinicallyProvenResults: return PreauthorizeGetStartedStep.WorkingTogether;
            case PreauthorizeGetStartedStep.WorkingTogether: return PreauthorizeGetStartedStep.SetupPassword;
            case PreauthorizeGetStartedStep.SetupPassword: return PreauthorizeGetStartedStep.Congratulations;
            case PreauthorizeGetStartedStep.Congratulations: return PreauthorizeGetStartedStep.NameInterstitial;
            case PreauthorizeGetStartedStep.NameInterstitial: return PreauthorizeGetStartedStep.PersonalInfo;
            case PreauthorizeGetStartedStep.PersonalInfo: return PreauthorizeGetStartedStep.Shipping;
            case PreauthorizeGetStartedStep.Shipping: return PreauthorizeGetStartedStep.Payment;
            case PreauthorizeGetStartedStep.Payment: return PreauthorizeGetStartedStep.Review;
        }
    }

    const getPreviousStep = (step?: PreauthorizeGetStartedStep): PreauthorizeGetStartedStep => {
        switch (step ?? state.step) {
            case PreauthorizeGetStartedStep.ClinicallyProvenResults: return PreauthorizeGetStartedStep.HealthConcern;
            case PreauthorizeGetStartedStep.WorkingTogether: return PreauthorizeGetStartedStep.ClinicallyProvenResults;
            case PreauthorizeGetStartedStep.SetupPassword: return PreauthorizeGetStartedStep.WorkingTogether;
            case PreauthorizeGetStartedStep.PersonalInfo: return PreauthorizeGetStartedStep.NameInterstitial;
            case PreauthorizeGetStartedStep.Shipping: return PreauthorizeGetStartedStep.PersonalInfo;
            case PreauthorizeGetStartedStep.Payment: return PreauthorizeGetStartedStep.Shipping;
            case PreauthorizeGetStartedStep.Review: return PreauthorizeGetStartedStep.Payment;
        }
    }

    const handleSubmitBackStep = () => {
        const previousStep = getPreviousStep();

        handleSetProgress(getProgress(previousStep));

        setState({ ...state, step: previousStep, errors: {}});
    }

    const handleSelectingAddOn = (id: number, value: boolean) => {
        const addOns = state.addOns;
        const addOn = addOns.find(x => x.id === id);
        addOn.isSelected = value;

        setState({
            ...state,
            addOns,
        });
    }

    const getSelectedAddOns = () => {
        return state.addOns.filter(i => i.isSelected);
    }

    const handleChanges = (field: string, value: any) => {
        updateValidationState(field, value);
        if (field === 'gender') {
            addOnsService.getOptional(preauthorizeRequest.paymentPlanId, value);
        }
        const payer = state.payer;
        const keys = field.split(".");
        const key = keys.pop();
        const lastObject = getLastObject(keys, payer);
        lastObject[key] = value;

        setState(state => ({ ...state, payer: Object.assign({}, payer) }));
    }

    const handleAgree = (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
        state[field] = event.target.checked;
        setState({ ...state });
    }

    const handleChangeHealthConcernStatus = (status: HealthConcernStatus) => {
        setState({
            ...state,
            healthConcernStatus: status
        })
    }

    const isCheckedWelcomePatient = () => {
        return Boolean(state.payer.email && state.payer.password && state.isAgreeContinue);
    }

    const isCheckedNameInfo = () => {
        return Boolean(state.payer.firstName && state.payer.lastName && moment(state.payer.birthday).format('MM/DD/YYYY') !== 'Invalid date' && state.payer.gender !== 99);
    }

    const isCheckedPersonalInfo = () => {
        return Boolean(state.payer.firstName && state.payer.lastName && moment(state.payer.birthday).format('MM/DD/YYYY') !== 'Invalid date' && state.payer.phoneNumber && state.payer.gender !== 99 && state.payer.state);
    }

    const isCheckedShippingDetail = () => {
        return Boolean(state.payer.shippingAddress.streetAddress1 && state.payer.shippingAddress.city && state.payer.shippingAddress.state && state.payer.shippingAddress.zipCode);
    }

    const isCheckedPaymentCardDetail = () => {
        return Boolean(state.payer.billingAddress.streetAddress1 && state.payer.billingAddress.city && state.payer.billingAddress.state && state.payer.billingAddress.zipCode);
    }

    const isCheckedProperStep = (payer: PayerInformation) => {
        if (Boolean(payer.firstName && payer.lastName && moment(payer.birthday).format('MM/DD/YYYY') !== 'Invalid date' && payer.gender !== 99)) {
            if (Boolean(payer.firstName && payer.lastName && moment(payer.birthday).format('MM/DD/YYYY') !== 'Invalid date' && payer.gender !== 99 && state.payer.phoneNumber && payer.state)) {
                if (Boolean(payer.shippingAddress.streetAddress1 && payer.shippingAddress.city && payer.shippingAddress.state && payer.shippingAddress.zipCode)) {
                    return PreauthorizeGetStartedStep.Payment;
                } else {
                    return PreauthorizeGetStartedStep.Shipping;
                }
            } else {
                Track("onboarding_personal_info", {
                    group: 'test',
                    platform: PlatformName(),
                })
                return PreauthorizeGetStartedStep.PersonalInfo;
            }
        }
        Track("onboarding_first_name", {
            group: 'test',
            platform: PlatformName(),
        })
        return PreauthorizeGetStartedStep.NameInterstitial;
    }

    const updateValidationState = (field: string, value: any) => {
        if (state.sameAsBillingAddress && field.includes('billingAddress')) {
            const shippingAddressField = field.replace('billingAddress', 'shippingAddress');
            finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, shippingAddressField, value);
        }

        finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, field, value);
    }

    const executeRecaptcha = () => {
        recaptcha.current?.reset();
        recaptcha.current?.execute()
    }

    const renderRecaptcha = () => {
        context['state'] = state;

        return <Recaptcha
            ref={recaptcha}
        sitekey={SITE_KEY}
        onResolved={(token) => recaptchaOnResolved(token, context)}
        style={{ display: 'none' }}
        />
    }

    const handleSameAsBillingAddress = () => {
        const payer = state.payer;
        payer.billingAddress = state.sameAsBillingAddress ? state.payer.shippingAddress : emptyAddress;

        setState({
            ...state,
            payer: payer,
            sameAsBillingAddress: !state.sameAsBillingAddress
        });
    }

    const handleUserInfoCheck = () => {
        const payer = state.payer;
        payer.shippingAddress = state.payer.billingAddress;
        payer.email = authQuery.getEmail();
        payer.password = uuidv4() + AuthConstants.uuidPasswordInterpolation;
        const cb = () => {
            setState(state => ({
                ...state,
                payer: payer,
                step: PreauthorizeGetStartedStep.NameInterstitial,
                isSubmitted: false,
                isGetUserInfo: false
            }));
            Track("onboarding_first_name", {
                group: 'test',
                platform: PlatformName(),
            })
            handleSetProgress(getProgress(PreauthorizeGetStartedStep.NameInterstitial));
        }
        usersService.getMyUserInfo().subscribe((response) => {
            payer.firstName = response.firstName;
            payer.lastName = response.lastName;
            payer.birthday = response.birthday;
            payer.gender = response.gender;
            payer.phoneNumber = response.phoneNumber;
            payer.shippingAddress = {
                ...response.shippingAddress,
                country: 'USA'
            };
            payer.billingAddress = {
                ...response.shippingAddress,
                country: 'USA'
            };
            if (response.shippingAddress.state) {
                payer.state = addressQuery.findState(payer.shippingAddress?.state);
            }
            const step = isCheckedProperStep(payer);
            setState(state => ({
                ...state,
                payer: payer,
                step: step,
                sameAsBillingAddress: !(step === PreauthorizeGetStartedStep.Payment || step === PreauthorizeGetStartedStep.Shipping),
                isSubmitted: false,
                isGetUserInfo: false
            }));
            handleSetProgress(getProgress(step));
        }, cb)
    }

    const recaptchaOnResolved = (antiSpamToken: string, context) => {
        const state = context['state'];

        const password = !state.payer.password
            ? uuidv4() + AuthConstants.uuidPasswordInterpolation
            : state.payer.password;

        const patient: RegisterPreauthorizePatientModel = {
            practiceId: state.practiceId,
            preauthorizeRequestToken: preauthorizeRequest.token,
            antiSpamToken: antiSpamToken,
            email: state.payer.email.trim(),
            phoneNumber: state.payer.phoneNumber,
            firstName: state.payer.firstName,
            lastName: state.payer.lastName,
            gender: Number(state.payer.gender),
            birthday: state.payer.birthday,
            password: password,
            confirmPassword: password,
            billingAddress: state.payer.billingAddress,
            shippingAddress: {...state.payer.shippingAddress, state: addressQuery.findState(state.payer.shippingAddress?.state)?.abbreviation},
            agreements: paymentPeriod.agreements.map(x => ({ agreementId: x.id, isConfirmed: true })),
            addOnIds: state.addOns.filter(x => x.isSelected).map(x => x.id)
        }

        const cb = () => setState(state => ({ ...state, isSubmitted: false }));

        const registerCB = (error: any) => {
            if (error?.response?.data?.messages && error?.response?.data?.messages[0].toLowerCase().includes('already has an active membership')) {
                authService.refreshToken(authQuery.getRefreshToken()).subscribe(
                    () => {
                        const traits = getFBTraits(state.payer);
                        Track(
                            "checkout_completed",
                            {
                                platform: PlatformName()
                            },
                            traits
                        );

                        const createdPatient: any = {
                            ...state.payer,
                            intakeId: authQuery.getPatientId()
                        }

                        if (mobileGetStartedPath) {
                            navigationService.toMobilePaymentSuccess(history, createdPatient);
                        } else {
                            navigationService.toPaymentSuccess(history, createdPatient);
                        }
                    }, cb
                )
            } else {
                setState(state => ({ ...state, isSubmitted: false }));
            }
        }

        if (state.step === PreauthorizeGetStartedStep.SetupPassword) {
            setupPassword();
        } else {
            patientsService.registerPreauthorize(patient).subscribe(
                patient => {
                    const traits = getFBTraits(state.payer, patient.universalId);
                    Track(
                        "checkout_completed",
                        {
                            platform: PlatformName()
                        },
                        traits
                    );

                    if (mobileGetStartedPath) {
                        navigationService.toMobilePaymentSuccess(history, patient);
                    } else {
                        navigationService.toPaymentSuccess(history, patient);
                    }
                }, (error) => registerCB(error)
            );
        }
    }

    const validateSetupPassword = (): boolean => {
        finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, "email", state.payer.email);
        finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, "password", state.payer.password);

        return finishCheckoutPreauthorizeComponentValidator.stateIsValid(state);
    }

    const handlOnBlurValidate = (field: string): boolean => {
        switch (field) {
            case 'firstName':
                finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, "firstName", state.payer.firstName);

                return finishCheckoutPreauthorizeComponentValidator.stateIsValid(state);
            case 'lastName':
                finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, "lastName", state.payer.lastName);

                return finishCheckoutPreauthorizeComponentValidator.stateIsValid(state);
            case 'birthday':
                finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, "birthday", state.payer.birthday);

                return finishCheckoutPreauthorizeComponentValidator.stateIsValid(state);
            case 'phoneNumber':
                finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, "phoneNumber", state.payer.phoneNumber);

                return finishCheckoutPreauthorizeComponentValidator.stateIsValid(state);
            case 'state':
                finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, "state", state.payer.state);

                return finishCheckoutPreauthorizeComponentValidator.stateIsValid(state);
            case 'shippingAddress.streetAddress1':
                finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, "shippingAddress.streetAddress1", state.payer.shippingAddress.streetAddress1);

                return finishCheckoutPreauthorizeComponentValidator.stateIsValid(state);
            case 'shippingAddress.city':
                finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, "shippingAddress.city", state.payer.shippingAddress.city);

                return finishCheckoutPreauthorizeComponentValidator.stateIsValid(state);
            case 'shippingAddress.state':
                finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, "shippingAddress.state", state.payer.shippingAddress.state);

                return finishCheckoutPreauthorizeComponentValidator.stateIsValid(state);
            case 'shippingAddress.zipCode':
                finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, "shippingAddress.zipCode", state.payer.shippingAddress.zipCode);

                return finishCheckoutPreauthorizeComponentValidator.stateIsValid(state);
            case 'billingAddress.streetAddress1':
                finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, "billingAddress.streetAddress1", state.payer.billingAddress.streetAddress1);

                return finishCheckoutPreauthorizeComponentValidator.stateIsValid(state);
            case 'billingAddress.city':
                finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, "billingAddress.city", state.payer.billingAddress.city);

                return finishCheckoutPreauthorizeComponentValidator.stateIsValid(state);
            case 'billingAddress.state':
                finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, "billingAddress.state", state.payer.billingAddress.state);

                return finishCheckoutPreauthorizeComponentValidator.stateIsValid(state);
            case 'billingAddress.zipCode':
                finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, "billingAddress.zipCode", state.payer.billingAddress.zipCode);

                return finishCheckoutPreauthorizeComponentValidator.stateIsValid(state);
        }
    }

    const validateNameInterStep = (): boolean => {
        finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, "firstName", state.payer.firstName);
        finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, "lastName", state.payer.lastName);
        finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, "birthday", state.payer.birthday);
        finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, "gender", state.payer.gender);

        return finishCheckoutPreauthorizeComponentValidator.stateIsValid(state);
    }

    const validatePersonalInfoStep = (): boolean => {
        finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, "firstName", state.payer.firstName);
        finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, "lastName", state.payer.lastName);
        finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, "birthday", state.payer.birthday);
        finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, "gender", state.payer.gender);
        finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, "phoneNumber", state.payer.phoneNumber);
        finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, "state", state.payer.state);

        return finishCheckoutPreauthorizeComponentValidator.stateIsValid(state);
    }

    const validateShippingStep = (): boolean => {
        finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, "shippingAddress.streetAddress1", state.payer.shippingAddress.streetAddress1);
        finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, "shippingAddress.streetAddress2", state.payer.shippingAddress.streetAddress2);
        finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, "shippingAddress.city", state.payer.shippingAddress.city);
        finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, "shippingAddress.state", state.payer.shippingAddress.state);
        finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, "shippingAddress.zipCode", state.payer.shippingAddress.zipCode);

        return finishCheckoutPreauthorizeComponentValidator.stateIsValid(state);
    }

    const validatePaymentStep = (): boolean => {
        finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, "billingAddress.streetAddress1", state.payer.billingAddress.streetAddress1);
        finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, "billingAddress.streetAddress2", state.payer.billingAddress.streetAddress2);
        finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, "billingAddress.city", state.payer.billingAddress.city);
        finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, "billingAddress.state", state.payer.billingAddress.state);
        finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, "billingAddress.zipCode", state.payer.billingAddress.zipCode);
        finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, "shippingAddress.streetAddress1", state.payer.shippingAddress.streetAddress1);
        finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, "shippingAddress.streetAddress2", state.payer.shippingAddress.streetAddress2);
        finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, "shippingAddress.city", state.payer.shippingAddress.city);
        finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, "shippingAddress.state", state.payer.shippingAddress.state);
        finishCheckoutPreauthorizeComponentValidator.validateAndSetState(state, setState, "shippingAddress.zipCode", state.payer.shippingAddress.zipCode);

        return finishCheckoutPreauthorizeComponentValidator.stateIsValid(state);
    }

    /**
     * Handles payment submit
     */
    const handleSubmitRegistration = () => {
        Track("Button Click", {
            type: 'checkout_started',
            platform: PlatformName()
        })
        Track("checkout_started", {
            platform: PlatformName()
        })
        setState({ ...state, isSubmitted: true });
        executeRecaptcha();
    };

    /**
     * Load all payment plans
     * Manage subscriptions with auto-cleanup
     */
    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<MerchantCredentialsModel>(paymentQuery.credentials$, credentials => {
                setState(state => ({
                    ...state,
                    practiceId: credentials?.practiceId
                }));
            }),
            onEmit<AddOnModel[]>(addOnsQuery.addOns$, addOns => {
                const items = addOns.map(i => {
                    return {
                        ...i,
                        isSelected: i.orderType === OrderType.Lab
                    }
                });

                setState(state => ({ ...state, addOns: items }));
            })
        ];

        addOnsService.getOptional(preauthorizeRequest.paymentPlanId, state.payer.gender);
        paymentService.getCredentials(authQuery.getCurrentPracticeId());

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [preauthorizeRequest, state.payer.gender]);

    useEffect(() => {
        if (authQuery.isLoggedIn()) {
            handleUserInfoCheck();
        } else {
            Track("onboarding_health_concern", {
                group: 'test',
                platform: PlatformName(),
            })
            setState(state => ({
                ...state,
                isGetUserInfo: false
            }));
            handleSetProgress(getProgress());
        }
    }, [])

    useEffect(() => {
        function scrollEvent() {
            if (prevScrollY) {

                if (prevScrollY.current) {
                    let rect = prevScrollY.current.getBoundingClientRect()

                    if (rect.top > window.innerHeight + 48) {
                        setState((state) => ({...state, showBottom: true }))
                    } else {
                        setState((state) => ({...state, showBottom: false }))
                    }
                }
            }
        }

        document.addEventListener('scroll', scrollEvent)

        return () => {
            document.removeEventListener('scroll', scrollEvent)
        }
    }, []);


    return [
        state,
        isMarketingSMSButton,
        isConsultationGetStartedPath,
        handleChanges,
        renderRecaptcha,
        handleSelectingAddOn,
        getSelectedAddOns,
        handleSameAsBillingAddress,
        handleSubmitNextStep,
        handleSubmitBackStep,
        handleAgree,
        isCheckedWelcomePatient,
        isCheckedNameInfo,
        isCheckedPersonalInfo,
        isCheckedShippingDetail,
        isCheckedPaymentCardDetail,
        handleChangeHealthConcernStatus,
        prevScrollY,
        handlOnBlurValidate,
    ]
}
