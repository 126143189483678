import { useEffect, useState } from "react";
import { Subscription } from "recompose";
import { GenerateAmendedChangesForFollowUp, GetMaximumVersionOfNote } from "../../helpers/noteHelpers";
import {downloadNoteOptions, FollowUpContent, NoteModel} from "../../models/notes.models";
import { followUpNotePdfService } from "../../services/notes-pdf.service";
import { notesService } from "../../services/notes.service";
import { notesStore } from "../../stores/notes";
import { confirmService } from "../../../../services/confirm.service";
import { UserType } from "../../../auth/models/auth.enums";

interface FollowUpComponentState {
    note: NoteModel;
    content: FollowUpContent;
    internalContent: string;
    isPdfDownloading: boolean;
    originalContent: FollowUpContent;
    originalInternalContent: string;
    noteId: number;
}

export function useFacade(patientId: number, note: NoteModel): [FollowUpComponentState, (note: NoteModel) => void, Function] {
    const [state, setState] = useState({
        note: note,
        content: null,
        internalContent: '',
        isPdfDownloading: false,
        noteId: GetMaximumVersionOfNote(note).id,
        originalContent: null
    } as FollowUpComponentState);

    const handleDownloadPdf = (note: NoteModel) => {
        const cb = () => setState({ ...state, isPdfDownloading: false });
        confirmService.confirmAndSelectedNoteViewType(
            'Select the view type of the note',
            downloadNoteOptions,
        ).subscribe(
            (type) => {
                setState(state => ({ ...state, isPdfDownloading: true }));
                const isEmployeeView = type === UserType.Employee
                notesService.downloadNote(state.noteId, note.name, isEmployeeView).subscribe(
                    cb,
                    () => {
                        followUpNotePdfService
                            .downloadNotePdf(
                                note,
                                state.content,
                                isEmployeeView,
                                state.noteId,
                                state.internalContent,
                                GenerateAmendedChangesForFollowUp(state.originalContent, state.content, state.internalContent, state.originalInternalContent)
                            ).subscribe(cb, cb);
                    }
                )
            },
            () => { }
        );
    }

    const handleChooseNote = (noteIdNew: number) => {
        setState(state => ({
            ...state,
            noteId: noteIdNew,
        }));

        notesService.getContentByEmployee(noteIdNew, patientId).subscribe(
            (result) => {
                setState(state => ({
                    ...state,
                    content: JSON.parse(result.content),
                    internalContent: result.internalContent,
                }))
            }
        );
    }


    useEffect(() => {
        const subscriptions: Subscription[] = [];

        notesService.getContentByEmployee(GetMaximumVersionOfNote(note).id, patientId).subscribe(
            (targetContent) => {
                if (note?.id !== GetMaximumVersionOfNote(note).id) {
                    notesService.getContentByEmployee(note.id, patientId, true).subscribe(
                        (originalNoteContent) => {
                            setState(state => ({
                                ...state,
                                content: JSON.parse(targetContent.content),
                                internalContent: targetContent.internalContent,
                                originalContent: JSON.parse(originalNoteContent.content),
                                originalInternalContent: originalNoteContent.internalContent
                            }))
                        }
                    );
                } else {
                    setState(state => ({
                        ...state,
                        content: JSON.parse(targetContent.content),
                        internalContent: targetContent.internalContent,
                        originalContent: JSON.parse(targetContent.content),
                        originalInternalContent: targetContent.internalContent
                    }))
                }
            }
        );

        return () => {
            notesStore.resetTargetContent()
            subscriptions.map(it => it.unsubscribe())
        };
    }, [note, patientId]);

    return [state, handleDownloadPdf, handleChooseNote];
}