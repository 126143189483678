import React, { useEffect, useState } from 'react';
import { Box, Divider } from "@material-ui/core";
import { onEmit } from "../../../../common/helpers/on-emit";
import commonUseStyles from '../../../../common/styles/common.styles';
import WildHealthLinearProgress from "../../../../common/components/WildHealthLinearProgress";
import { PatientSupplementModel } from '../../../../patientSupplements/models/patientSupplementModel';
import { patientSupplementsQuery } from '../../../../patientSupplements/stores/patientSupplementsStore/patientSupplements.query';
import { patientsSupplementsService } from '../../../../patientSupplements/services/patientSupplements.service';
import { supplementsService } from '../../../../patientSupplements/services/supplements.service';
import { Subscription } from 'rxjs';

export interface SupplementsAnswerComponentProps {
    patientId?: number | null;
    question: string;
}

export interface SupplementsAnswerComponentState {
    supplements: PatientSupplementModel[],
    isLoading: boolean
}

export const SupplementsAnswerComponent: React.FC<SupplementsAnswerComponentProps> = (props: SupplementsAnswerComponentProps) => {
    const {
        patientId,
        question,
    } = props;

    const [state, setState] = useState({
        supplements: [],
        isLoading: true
    } as SupplementsAnswerComponentState)

    const commonClasses = commonUseStyles();

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<PatientSupplementModel[]>(patientSupplementsQuery.patientSupplements$, supplements => {
                setState(state => ({
                    ...state,
                    supplements: supplements
                }));
            })
        ];

        const cb = () => {
            setState(state => ({
                ...state,
                isLoading: false
            }));
        }

        if (patientId) {
            supplementsService.get(patientId).subscribe(cb, cb)
        } else {
            patientsSupplementsService.get().subscribe(cb, cb)
        }


        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [])

    const renderAnswer = () => {
        if (!state.supplements.length) {
            return (
                <Box my={2}>
                    <span>(no answer)</span>
                </Box>
            )
        }

        return (
            <>
                {
                    state.supplements.map((supplement, index) => (
                        <React.Fragment key={index}>
                            <Box py={2}>
                                <Box>
                                    <span>Name of Supplement: <span className={commonClasses.textMedium}>{supplement.name}</span></span>
                                </Box>
                                <Box>
                                    <span>Strength: <span className={commonClasses.textMedium}>{supplement.dosage}</span></span>
                                </Box>
                                <Box>
                                    <span>Dosing Instruction: <span className={commonClasses.textMedium}>{supplement.instructions}</span></span>
                                </Box>
                            </Box>
                            <Divider key={`${index}-divider`} />
                        </React.Fragment>
                    ))
                }
            </>
        )
    }
    const renderContent = (): JSX.Element => {
        if (state.isLoading) {
            return (<WildHealthLinearProgress />)
        }

        return (
            <>
                <Box className={commonClasses.colorGray1}>
                    <span>{question}</span>
                </Box>
                {
                    renderAnswer()
                }
            </>
        )
    }

    return (
        <>
            {
                renderContent()
            }
        </>
    )
}
