import React, { FC } from 'react';
import {
  Box, Grid, Stepper, Step, StepLabel, StepIconProps, TextField, FormControl, FormHelperText, Select, MenuItem, Checkbox, Divider
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DateRangeIcon from '@material-ui/icons/DateRange';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from "moment";
import DateFnsUtils from '@date-io/moment';
import { OpsAdminToolsStep, PaymentPlanShortModel, PaymentCouponShortModel, StepItemModel, ToolEnum } from '../opsAdminToolsCompnent.hooks';
import { useStyles, useStepIconStyles, StepperConnector } from "../opsAdminToolsComponent.styles";
import clsx from 'clsx';
import { WildHealthButton } from "../../../../common/components/wildHealthButton/WildHealthButton";
import { PaymentStrategy } from '../../../../payment/models/paymentPrice.models';

function StepIcon(props: StepIconProps) {
    const classes = useStepIconStyles();
    const { active, completed } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            {completed ? <CheckCircleIcon className={classes.active} /> : active ? <RadioButtonUncheckedIcon className={classes.active} /> : <RadioButtonUncheckedIcon className={classes.incompleted} />}
        </div>
    );
}

type ChangeSubscriptionComponentProps = {
    isLoading: boolean;
    steps: StepItemModel[];
    currentStep: StepItemModel;
    menuParams: any;
    errors: any;
    eligiblePaymentPlans: PaymentPlanShortModel[];
    eligiblePaymentCoupons: PaymentCouponShortModel[];
    handleChanges: (field: string, value: any, tool: ToolEnum) => void;
    handleSubmit: (any) => void;
    handleGoBack: () => void;
}

const ChangeSubscriptionComponent: FC<ChangeSubscriptionComponentProps> = (props): JSX.Element => {
    const {
        isLoading, steps, currentStep, menuParams, errors, eligiblePaymentPlans, eligiblePaymentCoupons,
        handleChanges,
        handleSubmit,
        handleGoBack
    } = props;

    const classes = useStyles();
    

    const getDate = (date) => {
        return date
            ? moment(date).format('YYYY-MM-DD')
            : null
    }

    const renderPlanName = (planName) => {
        if (eligiblePaymentPlans && eligiblePaymentPlans.length) {
            return eligiblePaymentPlans.find(plan => plan.name === planName)?.displayName ?? planName;
        }
        return planName; 
    }

    const handleChangeDate = (momentDate) => {
        handleChanges(`startDate`, momentDate === null ?
            "" : momentDate.format("YYYY-MM-DD"), ToolEnum.PAYMENT_PLANS_RECOMMEND);
        handleChanges(`endDate`, momentDate === null ?
            "" : momentDate.add(1, 'year').format("YYYY-MM-DD"), ToolEnum.PAYMENT_PLANS_RECOMMEND);
    }

    const controlIcon = <RadioButtonUncheckedIcon className="wh-tw-text-mediumGray" />;
    const controlCheckedIcon = <RadioButtonCheckedIcon className="wh-tw-text-primaryV" />

    const renderSubscriptionCandidate = (params: any) => (
        <Box p={2}>
            <Box mb={8} className="wh-tw-text-lg wh-tw-font-medium wh-tw-text-black wh-tw-text-center">Please provide patient ID</Box>
            <Box>
                <FormControl
                    variant="outlined"
                    color='primary'
                    required
                    error={!!errors['patientId']}
                    size="small"
                    disabled={isLoading}
                    classes={{root: classes.root}}
                    fullWidth>
                    <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Patient ID</Box>
                    <TextField
                        fullWidth
                        size="small"
                        required
                        InputProps={{className: 'input', classes}}
                        id={'patientId'}
                        type="number"
                        value={params['patientId']}
                        variant="outlined"
                        disabled={isLoading}
                        error={!!errors['patientId']}
                        helperText={errors['patientId']}
                        onChange={(v) => handleChanges(v.target.id, v.target.value, ToolEnum.CHANGE_SUBSCRIPTION_CANDIDATE)}
                    />
                </FormControl>
            </Box>
            <Box mt={8}>
                <WildHealthButton
                    fullWidth
                    size="large"
                    id="change-subscription-candidate-button"
                    loading={isLoading}
                    disabled={!!errors['patientId'] || !params['patientId']}
                    onClick={handleSubmit[ToolEnum.CHANGE_SUBSCRIPTION_CANDIDATE]}
                >
                    Execute & Proceed
                </WildHealthButton>
            </Box>
        </Box>
    )

    const renderPaymentPlanRecommend = (params: any) => (
        <Box p={2}>
            <Box mb={8} className="wh-tw-text-lg wh-tw-font-medium wh-tw-text-black wh-tw-text-center">Modify Subscription</Box>
            <Box>
                <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Subscription ID</Box>
                <TextField
                    fullWidth
                    size="small"
                    InputProps={{className: 'input', classes}}
                    id="subscriptionId"
                    placeholder="Subscription Id"
                    value={params.fromSubscriptionId}
                    variant="outlined"
                    disabled={true}
                />
            </Box>
            <Box my={2}>
                <Grid container>
                    <Grid item md={6}>
                        <Box pr={2}>
                            <FormControl
                                variant="outlined"
                                color='primary'
                                required
                                error={!!errors[`startDate`]}
                                size="small"
                                disabled={isLoading}
                                classes={{ root: classes.root }}
                                fullWidth>
                                <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Start Date</Box>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        id={`startDate`}
                                        fullWidth
                                        size="small"
                                        required
                                        placeholder="MM/DD/YYYY"
                                        format="MM/DD/YYYY"
                                        openTo="year"
                                        value={getDate(params.startDate)}
                                        helperText={null}
                                        error={!!errors[`startDate`]}
                                        inputVariant="outlined"
                                        disabled={isLoading}
                                        views={["year", "month", "date"]}
                                        onChange={(momentDate) => handleChangeDate(momentDate)}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                            "edge": "end",
                                        }}
                                        keyboardIcon={<DateRangeIcon className="wh-tw-text-mediumGray" />}
                                        minDate={new Date()}
                                    />
                                    <FormHelperText error>{errors[`startDate`]}</FormHelperText>
                                </MuiPickersUtilsProvider>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item md={6}>
                        <Box pl={2}>
                            <FormControl
                                variant="outlined"
                                color='primary'
                                required
                                error={!!errors[`endDate`]}
                                size="small"
                                disabled
                                classes={{ root: classes.root }}
                                fullWidth>
                                <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>End Date</Box>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        id={`endDate`}
                                        fullWidth
                                        size="small"
                                        required
                                        placeholder="MM/DD/YYYY"
                                        format="MM/DD/YYYY"
                                        openTo="year"
                                        value={getDate(params.endDate)}
                                        helperText={null}
                                        error={!!errors[`endDate`]}
                                        inputVariant="outlined"
                                        disabled
                                        views={["year", "month", "date"]}
                                        onChange={(momentDate) => handleChanges(`endDate`, momentDate === null ?
                                            "" : momentDate.format("YYYY-MM-DD"), ToolEnum.PAYMENT_PLANS_RECOMMEND)}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                            "edge": "end",
                                        }}
                                        keyboardIcon={<DateRangeIcon className="wh-tw-text-mediumGray" />}
                                        minDate={new Date(params.startDate)}
                                    />
                                    <FormHelperText error>{errors[`startDate`]}</FormHelperText>
                                </MuiPickersUtilsProvider>
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box my={2}>
                <FormControl
                    variant="outlined"
                    color='primary'
                    required
                    error={!!errors[`planName`]}
                    size="small"
                    disabled={isLoading}
                    classes={{ root: classes.root }}
                    fullWidth>
                    <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Plan Name</Box>
                    <Select
                        style={{ background: '#FFF' }}
                        classes={{ root: classes.root }}
                        value={params.planName}
                        error={!!errors[`planName`]}
                        inputProps={{
                        name: 'Plan Name',
                        id: 'plan-name-label',
                        classes,
                        }}
                        renderValue={() => {
                            if (params.planName === null || !params.planName) {
                                return <Box className="wh-tw-text-mediumGray">Select</Box>;
                            }

                            return renderPlanName(params.planName);
                        }}
                        displayEmpty
                        onChange={(v) => handleChanges("planName", v.target.value, ToolEnum.PAYMENT_PLANS_RECOMMEND)}
                    >
                        {eligiblePaymentPlans.map(plan => (
                            <MenuItem value={plan.name} key={plan.name}>{plan.displayName}</MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{errors[`planName`]}</FormHelperText>
                </FormControl>
            </Box>
            <Box my={2}>
                <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Payment Strategy</Box>
                <Box display="flex" alignItems="center">
                    <Checkbox
                        icon={controlIcon}
                        color="primary"
                        checkedIcon={controlCheckedIcon}
                        checked={params.paymentStrategy === PaymentStrategy.PartialPayment}
                        onChange={() => handleChanges("paymentStrategy", PaymentStrategy.PartialPayment, ToolEnum.PAYMENT_PLANS_RECOMMEND)}
                    />
                    <Box mr={2}>Monthly</Box>
                    <Checkbox
                        icon={controlIcon}
                        color="primary"
                        checkedIcon={controlCheckedIcon}
                        checked={params.paymentStrategy === PaymentStrategy.FullPayment}
                        onChange={() => handleChanges("paymentStrategy", PaymentStrategy.FullPayment, ToolEnum.PAYMENT_PLANS_RECOMMEND)}
                    />
                    <Box>Yearly</Box>
                </Box>
            </Box>
            <Box my={2}>
                <FormControl
                    variant="outlined"
                    color='primary'
                    error={!!errors[`couponCode`]}
                    size="small"
                    disabled={isLoading}
                    classes={{ root: classes.root }}
                    fullWidth>
                    <Box className="wh-tw-text-sm wh-tw-text-gray1" mb={1}>Coupon Code</Box>
                    <Select
                        style={{ background: '#FFF' }}
                        classes={{ root: classes.root }}
                        value={params.couponCode}
                        error={!!errors[`couponCode`]}
                        inputProps={{
                        name: 'Coupon Code',
                        id: 'coupon-code-label',
                        classes,
                        }}
                        renderValue={() => {
                            if (params.couponCode === null || !params.couponCode) {
                                return <Box className="wh-tw-text-mediumGray">Select</Box>;
                            }

                            return params.couponCode;
                        }}
                        displayEmpty
                        onChange={(v) => handleChanges("couponCode", v.target.value, ToolEnum.PAYMENT_PLANS_RECOMMEND)}
                    >
                        <MenuItem value={''}>None</MenuItem>
                        {eligiblePaymentCoupons.map(couponCode => (
                            <MenuItem value={couponCode.code} key={couponCode.code}>{couponCode?.code}</MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{errors[`couponCode`]}</FormHelperText>
                </FormControl>
            </Box>
            <Box my={2}>
                <Box className="wh-tw-text-sm wh-tw-text-gray1" mr={3}>Has insurance?</Box>
                <Box display="flex" alignItems="center">
                    <Checkbox
                        icon={controlIcon}
                        color="primary"
                        checkedIcon={controlCheckedIcon}
                        checked={params.isInsurance}
                        disabled={true}
                    />
                    <Box mr={2}>Yes</Box>
                    <Checkbox
                        icon={controlIcon}
                        color="primary"
                        checkedIcon={<RadioButtonCheckedIcon className="wh-tw-text-mediumGray" />}
                        checked={!params.isInsurance}
                        disabled={true}
                    />
                    <Box>No</Box>
                </Box>
            </Box>
        </Box>
    )

    const renderPaymentPlanMigrate = (params: any) => (
        <Box p={2}>
            <Box mb={8} className="wh-tw-text-lg wh-tw-font-medium wh-tw-text-black wh-tw-text-center">Please Confirm Changes</Box>
            <Box px={4} py={3} className="wh-tw-bg-gray8">
                <Box display="flex" justifyContent="space-between">
                    <Box className="wh-tw-text-sm wh-tw-text-gray1">Subscription ID</Box>
                    <Box className="wh-tw-text-sm wh-tw-text-black">{params.fromSubscriptionId}</Box>
                </Box>
                <Box my={2}><Divider className="wh-tw-bg-stroke" /></Box>
                <Box>
                    <Box display="flex" justifyContent="space-between">
                        <Box className="wh-tw-text-sm wh-tw-text-gray1">Start Date</Box>
                        <Box className="wh-tw-text-sm wh-tw-text-black">{moment(params.startDate).format('L')}</Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between" mt={2}>
                        <Box className="wh-tw-text-sm wh-tw-text-gray1">EndDate</Box>
                        <Box className="wh-tw-text-sm wh-tw-text-black">{moment(params.endDate).format('L')}</Box>
                    </Box>
                </Box>
                <Box my={2}><Divider className="wh-tw-bg-stroke" /></Box>
                <Box display="flex" justifyContent="space-between">
                    <Box className="wh-tw-text-sm wh-tw-text-gray1">Plan Name</Box>
                    <Box className="wh-tw-text-sm wh-tw-text-black">{renderPlanName(params.planName)}</Box>
                </Box>
                <Box my={2}><Divider className="wh-tw-bg-stroke" /></Box>
                <Box display="flex" justifyContent="space-between">
                    <Box className="wh-tw-text-sm wh-tw-text-gray1">Payment Strategy</Box>
                    <Box className="wh-tw-text-sm wh-tw-text-black">{params.paymentStrategy === PaymentStrategy.FullPayment ? "Yearly" : "Monthly"}</Box>
                </Box>
                <Box my={2}><Divider className="wh-tw-bg-stroke" /></Box>
                <Box display="flex" justifyContent="space-between">
                    <Box className="wh-tw-text-sm wh-tw-text-gray1">Coupon Code</Box>
                    <Box className="wh-tw-text-sm wh-tw-text-black">{params.couponCode ?? ''}</Box>
                </Box>
                <Box my={2}><Divider className="wh-tw-bg-stroke" /></Box>
                <Box display="flex" justifyContent="space-between">
                    <Box className="wh-tw-text-sm wh-tw-text-gray1">Has Insurance?</Box>
                    <Box className="wh-tw-text-sm wh-tw-text-black">{params.isInsurance ? 'Yes' : 'No'}</Box>
                </Box>
            </Box>
        </Box>
    )

    return (
        <Box p={1} display="flex" alignItems="center" flexDirection="column">
            <Box mx="auto" width={0.5}>
                <Stepper
                alternativeLabel
                activeStep={steps.indexOf(currentStep)}
                connector={<StepperConnector />}
                orientation="horizontal"
                classes={{ root: classes.stepper }}
                >
                {
                    steps.map((step, index) => (
                    <Step key={`change-subscription-step-${index}`} classes={{ root: classes.step }}>
                        <StepLabel StepIconComponent={StepIcon} classes={{ iconContainer: classes.stepLabel }}>
                            {step.label}
                        </StepLabel>
                    </Step>
                    ))
                }
                </Stepper>
            </Box>
            <Box className="wh-tw-w-[500px]">
                {currentStep.key === OpsAdminToolsStep.Step1 && renderSubscriptionCandidate(menuParams[ToolEnum.CHANGE_SUBSCRIPTION_CANDIDATE])}
                {currentStep.key === OpsAdminToolsStep.Step2 && renderPaymentPlanRecommend(menuParams[ToolEnum.PAYMENT_PLANS_RECOMMEND])}
                {currentStep.key === OpsAdminToolsStep.Step3 && renderPaymentPlanMigrate(menuParams[ToolEnum.PAYMENT_PLANS_RECOMMEND])}
                {(currentStep.key === OpsAdminToolsStep.Step2 || currentStep.key === OpsAdminToolsStep.Step3) &&
                    <Box display="flex" alignItems="center" gridGap={24} mt={8} px={2}>
                        <Box width={132}>
                            <WildHealthButton
                                fullWidth
                                size="large"
                                color="tertiary"
                                id="back-button"
                                loading={isLoading}
                                onClick={handleGoBack}
                            >
                                Back
                            </WildHealthButton>
                        </Box>
                        <Box flex={1}>
                            <WildHealthButton
                                fullWidth
                                size="large"
                                id="execute-confirm-button"
                                loading={isLoading}
                                onClick={currentStep.key === OpsAdminToolsStep.Step2 ? handleSubmit[ToolEnum.PAYMENT_PLANS_RECOMMEND] : handleSubmit[ToolEnum.PAYMENT_PLANS_MIGRATE]}
                            >
                                Execute & Proceed
                            </WildHealthButton>
                        </Box>
                    </Box>
                }
            </Box>  
        </Box>
    )
}

export default ChangeSubscriptionComponent;