import { Button, CircularProgress } from "@material-ui/core";
import React from "react";
import { useFacade } from './wildHealthButton.hooks';
import { useStyles } from './wildHealthButton.styles';
import clsx from 'clsx';

interface WildHealthButtonProps {
    onClick?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    children?: any;
    style?: React.CSSProperties;
    size?: 'default' | 'large' | 'medium' | 'small';
    color?: 'primary' | 'primaryV' | 'secondary' | 'tertiary' | 'danger' | 'referral' | 'mint' | 'white' | 'datePicker';
    width?: number | string;
    autoFocus?: boolean;
    disabled?: boolean;
    border?: 'none';
    borderRadius?: number | string;
    timeout?: number;
    type?: any;
    fullWidth?: boolean;
    loading?: boolean;
    id: string;
}

export const WildHealthButton: React.FC<WildHealthButtonProps> = (props: WildHealthButtonProps) => {
    const {
        onClick,
        timeout = 250,
        size = 'default',
        color = 'primary',
        width = 'auto',
        border = '',
        borderRadius = '',
        fullWidth = false,
        loading = false
    } = props;
    const classes = useStyles();

    const [
        canExecute,
        handleOnClick,
    ] = useFacade(onClick, timeout);

    return (
        <Button
            id={props.id}
            className={clsx(classes.root, {
                [classes.none]: border === 'none',
                [classes.primary]: color === 'primary',
                [classes.primaryV]: color === 'primaryV',
                [classes.secondary]: color === 'secondary',
                [classes.datePicker]: color === 'datePicker',
                [classes.tertiary]: color === 'tertiary',
                [classes.danger]: color === 'danger',
                [classes.referral]: color === 'referral',
                [classes.mint]: color === 'mint',
                [classes.white]: color === 'white',
                [classes.default]: size === 'default',
                [classes.large]: size === 'large',
                [classes.medium]: size === 'medium',
                [classes.small]: size === 'small',
            })}
            autoFocus={props.autoFocus}
            disabled={props.disabled || !canExecute || loading}
            onClick={handleOnClick}
            style={{
                borderRadius: borderRadius ?? '',
                width: fullWidth ? "100%" : width,
                ...props.style,
            }}
            type={props.type}
            fullWidth={fullWidth}
        >
            {loading ? <CircularProgress size={24} className="wh-tw-text-primaryV" /> : props.children}
        </Button>
    );
}
