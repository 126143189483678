import { useEffect, useState } from "react";
import { authService } from "../../../auth/services/auth.service";
import { authQuery } from "../../../auth/stores/auth";
import { IErrorState } from "../../../common/validation/error-state";
import { FellowModel, UpdateFellowModel } from "../../../employee/models/employee.models";
import { employeeService } from "../../../employee/services/employees.service";
import { updateFellowComponentValidator } from "./updateFellowComponent.validator";

interface UpdateFellowComponentState extends IErrorState {
    fellow: UpdateFellowModel;
    isLoading: boolean;
}

const initModel: UpdateFellowModel = {
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    credentials: '',
    phoneNumber: '',
    rosterId: 0,
};

/**
 * Custom Hook to manage a view Model for Notifications component
 */
export function useFacade(
    selectedFellow: FellowModel,
    isOpen: boolean,
    selfManagingEmployee: boolean,
    handleToggleDialog: (value: boolean) => void
): [
        UpdateFellowComponentState,
        (field: string, value: any) => void,
        () => void
    ] {

    const [state, setState] = useState({
        fellow: initModel,
        errors: {},
        isLoading: false,
    } as UpdateFellowComponentState);

    const handleChanges = (field: string, value: any) => {
        updateFellowComponentValidator.validateAndSetState(state, setState, field, value);

        const fellow = state.fellow;

        fellow[field] = value;

        setState({ ...state, fellow });
    }

    const handleUpdate = () => {
        updateFellowComponentValidator.validateObjectAndSetState(state, setState, state.fellow);
        if (!updateFellowComponentValidator.stateIsValid(state)) {
            return;
        }

        if (state.isLoading) {
            return;
        }

        setState((state) => ({
            ...state,
            isLoading: true
        }));

        const cb = () => {
            setState((state) => ({
                ...state,
                errors: {},
                fellow: Object.assign({}, initModel),
                isLoading: false
            }));

            handleToggleDialog(false);
        }

        const action = selfManagingEmployee ? () => { authService.reLogin(authQuery.getTargetLocationId()).subscribe(); cb(); } : cb;

        employeeService.updateFellow(state.fellow).subscribe(() => action(), () => {
            setState((state) => ({
                ...state,
                isLoading: false
            }));
        });
    }

    const useEffectCB = () => {
        if (isOpen) {
            setState((state) => ({
                ...state,
                errors: {},
                fellow: Object.assign({}, selectedFellow)
            }));
        }
    };

    useEffect(useEffectCB, [isOpen]);

    return [
        state,
        handleChanges,
        handleUpdate
    ];
}