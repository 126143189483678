import React from "react";
import { Box, List, ListItem } from "@material-ui/core";
import { ConversationSubject } from "../../models/conversationSubject.models";
import { useStyles } from "./selectConversationSubjectComponent.styles";

interface SelectConversationSubjectComponentProps {
  subjects: Map<ConversationSubject, string>;
  disabled?: boolean;
  handleSelect: (ConversationSubject) => void;
}

export const SelectConversationSubjectComponent: React.FC<
  SelectConversationSubjectComponentProps
> = (props: SelectConversationSubjectComponentProps) => {
  const classes = useStyles();
  const { subjects, disabled, handleSelect } = props;

  return (
    <Box>
      <List className={classes.container}>
        {Array.from(subjects).map(([subject, title], index) => {
          return (
            <ListItem
              id={`select-conversation-${title}`}
              key={index}
              button
              disabled={disabled}
              className={classes.subjectItem}
              onClick={() => handleSelect(subject)}
              divider={index < Array.from(subjects).length - 1}
            >
              <span>{title}</span>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};
