import { Box, Button, TextField, Grid } from "@material-ui/core";
import React from "react";
import { phoneNumberInput } from "../../../../common/components/PhoneNumberInput";
import WildHealthLinearProgress from "../../../../common/components/WildHealthLinearProgress";
import { useFacade } from "./pharmacyInfoWidgetComponent.hooks";
import { useStyles } from "./pharmacyInfoWidgetComponent.styles";
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { WildHealthButton } from "../../../../common/components/wildHealthButton/WildHealthButton";


export const PharmacyInfoWidgetComponent: React.FC<any> = () => {

  const [
    {
      edit,
      isLoading,
      isSaveChanges,
      pharmacyInfo,
      pharmacyInfoDraft,
      errors,
    },
    handleChanges,
    handleEditToggle,
    handleReset,
    handleSave,
  ] = useFacade();

  const classes = useStyles();

  if (isLoading) {
    return <WildHealthLinearProgress />;
  }

  const renderEditContent = () => {
    return <>
      <Box width="100%">
        <form autoComplete="off">
          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              <Box pb={2} display="flex">
                <Box className={classes.textGray} pt="10px">Address:</Box>
                <TextField
                  fullWidth
                  size="small"
                  disabled={!handleChanges}
                  id={`streetAddress`}
                  variant="outlined"
                  value={pharmacyInfoDraft.streetAddress}
                  InputProps={{ className: 'input', classes }}
                  error={!!errors[`streetAddress`]}
                  helperText={errors[`streetAddress`]}
                  onChange={(v) => {
                    handleChanges(v.target.id, v.target.value)
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Box pb={2} display="flex">
                <Box className={classes.textGray} pt="10px">Name:</Box>
                <Box className={classes.breakWord}>
                  <TextField
                    fullWidth
                    size="small"
                    disabled={!handleChanges}
                    id={`name`}
                    variant="outlined"
                    value={pharmacyInfoDraft.name}
                    InputProps={{ className: 'input', classes }}
                    error={!!errors[`name`]}
                    helperText={errors[`name`]}
                    onChange={(v) => {
                      handleChanges(v.target.id, v.target.value)
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Box pb={2} display="flex">
                <Box className={classes.textGray} pt="10px">Phone: </Box>
                <TextField
                  fullWidth
                  size="small"
                  disabled={!handleChanges}
                  id={`phone`}
                  variant="outlined"
                  value={pharmacyInfoDraft.phone}
                  error={!!errors[`phone`]}
                  helperText={errors[`phone`]}
                  InputProps={{
                    className: 'input',
                    inputComponent: phoneNumberInput,
                    classes
                  }}
                  onChange={(v) => {
                    handleChanges(v.target.id, v.target.value)
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Box pb={2} display="flex">
                <Box className={classes.textGray} pt="10px">City:</Box>
                <Box className={classes.breakWord}>
                  <TextField
                    fullWidth
                    size="small"
                    disabled={!handleChanges}
                    id={`city`}
                    variant="outlined"
                    value={pharmacyInfoDraft.city}
                    InputProps={{ className: 'input', classes }}
                    error={!!errors[`city`]}
                    helperText={errors[`city`]}
                    onChange={(v) => {
                      handleChanges(v.target.id, v.target.value)
                    }}
                  />
                </Box>
              </Box>
              <Box pb={2} display="flex">
                <Box className={classes.textGray} pt="10px">Zip Code:</Box>
                <Box className={classes.breakWord}>
                  <TextField
                    fullWidth
                    size="small"
                    disabled={!handleChanges}
                    id={`zipCode`}
                    variant="outlined"
                    value={pharmacyInfoDraft.zipCode}
                    InputProps={{ className: 'input', classes }}
                    error={!!errors[`zipCode`]}
                    helperText={errors[`zipCode`]}
                    onChange={(v) => {
                      handleChanges(v.target.id, v.target.value)
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Box pb={2} display="flex">
                <Box className={classes.textGray} pt="10px">State:</Box>
                <TextField
                  fullWidth
                  size="small"
                  disabled={!handleChanges}
                  id={`state`}
                  variant="outlined"
                  value={pharmacyInfoDraft.state}
                  InputProps={{ className: 'input', classes }}
                  error={!!errors[`state`]}
                  helperText={errors[`state`]}
                  onChange={(v) => {
                    handleChanges(v.target.id, v.target.value)
                  }}
                />
              </Box>
              <Box display="flex">
                <Box className={classes.textGray} pt="10px">Country:</Box>
                <TextField
                  fullWidth
                  size="small"
                  disabled={!handleChanges}
                  id={`country`}
                  variant="outlined"
                  value={pharmacyInfoDraft.country}
                  InputProps={{ className: 'input', classes }}
                  error={!!errors[`country`]}
                  helperText={errors[`country`]}
                  onChange={(v) => {
                    handleChanges(v.target.id, v.target.value)
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </form>
        <Box display='flex' mt={2}>
          <Box>
            <WildHealthButton
              id="save-changes-pharmacy-info"
              width='140px'
              disabled={isSaveChanges}
              loading={isSaveChanges}
              onClick={() => handleSave()}
            >
              Save changes
            </WildHealthButton>
          </Box>
          <Box mx={2}>

            <WildHealthButton
              id="reset-changes-pharmacy-info"
              color='secondary'
              disabled={isSaveChanges}
              width='140px'
              onClick={() => handleReset()}
            >
              Reset
            </WildHealthButton>
          </Box>
        </Box>
      </Box>
    </>
  }

  const renderContent = () => {
    return (
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <Box py={2} display="flex" alignItems="center">
            <Box className={classes.textGray} >Address:</Box>
            <Box className={classes.breakWord}>{pharmacyInfo.streetAddress ? pharmacyInfo.streetAddress : '-'}</Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Box pb={2} display="flex" alignItems="center">
            <Box className={classes.textGray} >Name:</Box>
            <Box className={classes.breakWord}>{pharmacyInfo.name ? pharmacyInfo.name : '-'}</Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Box pb={2} display="flex" alignItems="center">
            <Box className={classes.textGray} >Phone: </Box>
            <Box className={classes.breakWord}>{pharmacyInfo.phone ? pharmacyInfo.phone : '-'} </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Box pb={2} display="flex" alignItems="center">
            <Box className={classes.textGray} >City:</Box>
            <Box className={classes.breakWord}>{pharmacyInfo.city ? pharmacyInfo.city : '-'}</Box>
          </Box>
          <Box pb={2} display="flex" alignItems="center">
            <Box className={classes.textGray} >Zip Code:</Box>
            <Box className={classes.breakWord}>{pharmacyInfo.zipCode ? pharmacyInfo.zipCode : '-'}</Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Box pb={2} display="flex" alignItems="center">
            <Box className={classes.textGray} >State: </Box>
            <Box className={classes.breakWord}>{pharmacyInfo.state ? pharmacyInfo.state : '-'} </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box className={classes.textGray} >Country: </Box>
            <Box className={classes.breakWord}>{pharmacyInfo.country ? pharmacyInfo.country : '-'} </Box>
          </Box>
        </Grid>
      </Grid>
    );

  };

  return (
    <Box>
      <Box>
        <Box className={classes.headerSection} display='flex' justifyContent="space-between">
          <Box className={classes.title}>
            Pharmacy Info
          </Box>
          <Box>
            {!edit && <Button
              id='edit-pharmacy-info'
              className={classes.button}
              onClick={() => { handleEditToggle() }}
            >
              <BorderColorIcon fontSize='small' />
              <Box className={classes.buttonText}>Edit</Box>
            </Button>}
          </Box>
        </Box>
      </Box>
      <Box>
        {edit ? renderEditContent() : renderContent()}
      </Box>
    </Box >
  )
};

