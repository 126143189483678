import {Box} from "@material-ui/core";
import React, {useState} from "react";
import {
    AppointmentConfigurationModel,
    downloadNoteOptions,
    NoteContentModel,
    NoteModel,
    NotesType
} from "../../models/notes.models";
import {notesService} from "../../services/notes.service";
import {CreateFollowUpComponent} from "../createFollowUp/CreateFollowUpComponent";
import {CreateInitialConsultComponent} from "../createInitialConsult/CreateInitialConsultComponent";
import {CreateSimpleComponent} from "../createSimple/CreateSimpleComponent";
import {FollowUpComponent} from '../followUp/FollowUpComponent';
import {SimpleComponent} from '../simple/SimpleComponent';
import {InitialConsultComponent} from '../initialConsult/InitialConsultComponent';
import {ManagedNotesCardsComponent} from '../managedNotesCards/ManagedNotesCardsComponent';
import {
    CreateHistoryAndPhysicalNoteComponent
} from "../createHistoryAndPhysicalComponent/CreateHistoryAndPhysicalNoteComponent";
import {
    CreateHistoryAndPhysicalFollowUpNoteComponent
} from "../createHistoryAndPhysicalFollowUpComponent/CreateHistoryAndPhysicalFollowUpNoteComponent";
import {CreateSoapNoteComponent} from "../createSoapComponent/CreateSoapNoteComponent";
import {HistoryAndPhysicalNoteComponent} from "../historyAndPhysicalComponent/HistoryAndPhysicalComponent";
import {SoapNoteComponent} from "../soapNoteComponent/SoapNoteComponent";
import {
    HistoryAndPhysicalFollowUpNoteComponent
} from "../historyAndPhysicalFollowUpComponent/HistoryAndPhysicalFollowUpComponent";
import {snackService} from "../../../common/snack/state";
import {authQuery} from "../../../auth/stores/auth";
import {handleDownloadNoteByEmployeePdfBd} from "../../helpers/noteHelpers";
import {
    CreateHistoryAndPhysicalGroupVisitNoteComponent
} from "../createHistoryAndPhysicalGroupVisitComponent/CreateHistoryAndPhysicalGroupVisitNoteComponent";
import {
    HistoryAndPhysicalGroupVisitNoteComponent
} from "../historyAndPhysicalGroupVisitComponent/HistoryAndPhysicalGroupVisitComponent";
import {NonAppointmentsNoteTypes} from "../../constants/nonAppointmentNoteTypes";
import {notesStore} from "../../stores/notes";
import {confirmService} from "../../../../services/confirm.service";
import {UserType} from "../../../auth/models/auth.enums";
import {CreateInitialConsultComponentOld} from "../createInitialConsult/Old/createInitialConsultOldComponent";
import {InitialConsultComponentOld} from "../initialConsult/Old/InitialConsultComponentOld";
import {patientsQuery} from "../../../patients/stores/patientsStore";
import {useFlags} from "launchdarkly-react-client-sdk";

enum NotesStates {
    Collection = 0,
    InitialConsult = 1,
    CreateInitialConsult = 2,
    FollowUp = 3,
    CreateFollowUp = 4,
    Internal = 5,
    CreateInternal = 6,
    Blank = 7,
    CreateBlank = 8,
    HistoryAndPhysicalInitial = 9,
    CreateHistoryAndPhysicalInitial = 10,
    HistoryAndPhysicalFollowUp = 11,
    CreateHistoryAndPhysicalFollowUp = 12,
    HistoryAndPhysicalGroupVisit = 13,
    CreateHistoryAndPhysicalGroupVisit = 14,
    Soap = 15,
    CreateSoap = 16,
    InitialConsultOld = 17,
    CreateInitialConsultOld = 18
}

export interface ManageNotesComponentState {
    targetNote: NoteModel;
    viewState: NotesStates;
    activeContent: NoteContentModel;
    downloadingNoteId: Number | null;
    isOpen: boolean;
    appointmentId: Number | null;
    appointmentConfiguration: AppointmentConfigurationModel | null;
    type: NotesType;
    isEdit: boolean;
    originalNote: NoteModel;
}

export function useFacade(patientId: number | null): [JSX.Element, boolean, Function, Function, Function, Function] {
    const [state, setState] = useState({
        viewState: NotesStates.Collection,
        targetNote: null,
        activeContent: null,
        downloadingNoteId: null,
        isOpen: false,
        appointmentId: null,
        appointmentConfiguration: null,
        type: null,
        isEdit: false,
        originalNote: null
    } as ManageNotesComponentState);

    const flags = useFlags();

    const handleGoToNotes = () => {
        setState({
            ...state,
            targetNote: null,
            viewState: NotesStates.Collection,
            originalNote: null,
        });
    }

    const handleGoToCreateNote = (type: NotesType) => {
        let viewState: NotesStates;

        switch (type) {
            case NotesType.Initial:
                viewState = NotesStates.CreateInitialConsult;
                break;
            case NotesType.FollowUp:
                viewState = NotesStates.CreateFollowUp;
                break;
            case NotesType.Internal:
                viewState = NotesStates.CreateInternal;
                break;
            case NotesType.Blank:
                viewState = NotesStates.CreateBlank;
                break;
            case NotesType.Soap:
                viewState = NotesStates.CreateSoap;
                break;
            case NotesType.HistoryAndPhysicalInitial:
                viewState = NotesStates.CreateHistoryAndPhysicalInitial;
                break;
            case NotesType.HistoryAndPhysicalFollowUp:
                viewState = NotesStates.CreateHistoryAndPhysicalFollowUp;
                break;
            case NotesType.HistoryAndPhysicalGroupVisit:
                viewState = NotesStates.CreateHistoryAndPhysicalGroupVisit;
                break;
            default:
                break;
        }

        setState(state => ({
            ...state,
            viewState: viewState,
            targetNote: null
        }));
    }

    const handleGoToEditNote = (note: NoteModel) => {
        let viewState: NotesStates;

        switch (note.type) {
            case NotesType.Initial:
                viewState = NotesStates.CreateInitialConsult;
                break;
            case NotesType.FollowUp:
                viewState = NotesStates.CreateFollowUp;
                break;
            case NotesType.Internal:
                viewState = NotesStates.CreateInternal;
                break;
            case NotesType.Blank:
                viewState = NotesStates.CreateBlank;
                break;
            case NotesType.Soap:
                viewState = NotesStates.CreateSoap;
                break;
            case NotesType.HistoryAndPhysicalInitial:
                viewState = NotesStates.CreateHistoryAndPhysicalInitial;
                break;
            case NotesType.HistoryAndPhysicalFollowUp:
                viewState = NotesStates.CreateHistoryAndPhysicalFollowUp;
                break;
            case NotesType.HistoryAndPhysicalGroupVisit:
                viewState = NotesStates.CreateHistoryAndPhysicalGroupVisit;
                break;
            default:
                break;
        }

        setState(state => ({
            ...state,
            viewState: viewState,
            targetNote: null,
            isEdit: true,
            originalNote: note
        }));
    }

    const handleSelectAppointmentDialog = (open: boolean) => {
        setState(state => ({
            ...state,
            isOpen: open,
            appointmentId: open ? null : state.appointmentId
        }));
    }

    const handleChangeSelectAppointment = (appointmentId: number) => {
        setState(state => ({
            ...state,
            appointmentId: appointmentId
        }));
    }

    const handleConfigureAppointment = (configuration: AppointmentConfigurationModel) => {
        if (!patientsQuery.getTargetPatient()?.isPremium) {
            return;
        }

        setState(state => ({
            ...state,
            appointmentId: null,
            appointmentConfiguration: configuration
        }));

        handleSelectAppointmentDialog(false);
        handleGoToCreateNote(state.type);
    }

    const handleSelectAppointmentToCreateNote = (type: NotesType) => {
        if (NonAppointmentsNoteTypes.includes(type) || !authQuery.isDefaultPractice()) {
            handleGoToCreateNote(type);
            return;
        }

        setState(state => ({
            ...state,
            type: type
        }));

        handleSelectAppointmentDialog(true);
    }

    const handleSubmitSelectAppointment = () => {
        if (state.appointmentId === 0 || state.appointmentId == null) {
            if (!patientsQuery.getTargetPatient()?.isPremium) {
                snackService.error("There was an error while attaching a note.");
                handleSelectAppointmentDialog(false);
                return;
            }
        }

        handleSelectAppointmentDialog(false);
        handleGoToCreateNote(state.type);
    }


    const handleGoToNote = (note: NoteModel) => {
        if (note.deletedBy) {
            return;
        }

        notesStore.resetTargetContent()

        let viewState: NotesStates;

        switch (note.type) {
            case NotesType.Initial:
                switch (note.isCompleted) {
                    case true:
                        if(note.isOldIcc){
                            viewState = NotesStates.InitialConsultOld
                            break;
                        }
                        viewState = NotesStates.InitialConsult;
                        break;
                    case false:
                        if(note.isOldIcc){
                            viewState = NotesStates.CreateInitialConsultOld
                            break;
                        }
                        viewState = NotesStates.CreateInitialConsult;
                        break;
                }
                break;
            case NotesType.FollowUp:
                switch (note.isCompleted) {
                    case true:
                        viewState = NotesStates.FollowUp;
                        break;
                    case false:
                        viewState = NotesStates.CreateFollowUp;
                        break;
                }
                break;
            case NotesType.Internal:
                switch (note.isCompleted) {
                    case true:
                        viewState = NotesStates.Internal;
                        break;
                    case false:
                        viewState = NotesStates.CreateInternal;
                        break;
                }
                break;
            case NotesType.Blank:
                switch (note.isCompleted) {
                    case true:
                        viewState = NotesStates.Blank;
                        break;
                    case false:
                        viewState = NotesStates.CreateBlank;
                        break;
                }
                break;
            case NotesType.Soap:
                switch (note.isCompleted) {
                    case true:
                        viewState = NotesStates.Soap;
                        break;
                    case false:
                        viewState = NotesStates.CreateSoap;
                        break;
                }
                break;
            case NotesType.HistoryAndPhysicalInitial:
                switch (note.isCompleted) {
                    case true:
                        viewState = NotesStates.HistoryAndPhysicalInitial;
                        break;
                    case false:
                        viewState = NotesStates.CreateHistoryAndPhysicalInitial;
                        break;
                }
                break;
            case NotesType.HistoryAndPhysicalFollowUp:
                switch (note.isCompleted) {
                    case true:
                        viewState = NotesStates.HistoryAndPhysicalFollowUp;
                        break;
                    case false:
                        viewState = NotesStates.CreateHistoryAndPhysicalFollowUp;
                        break;
                }
                break;
            case NotesType.HistoryAndPhysicalGroupVisit:
                switch (note.isCompleted) {
                    case true:
                        viewState = NotesStates.HistoryAndPhysicalGroupVisit;
                        break;
                    case false:
                        viewState = NotesStates.CreateHistoryAndPhysicalGroupVisit;
                        break;
                }
                break;
        }

        setState(state => ({
            ...state,
            targetNote: note,
            viewState: viewState,
        }));
    }

    const handleDownloadNotePdf = (note: NoteModel) => {
        if (note.type !== NotesType.Internal) {
            confirmService.confirmAndSelectedNoteViewType(
                'Select the view type of the note',
                downloadNoteOptions,
            ).subscribe(
                (type) => {
                    const isEmployee = type === UserType.Employee;
                    setState({ ...state, downloadingNoteId: note.id });
                    const cb = () => setState({ ...state, downloadingNoteId: null });
                    notesService.downloadNote(note.id, note.name, isEmployee).subscribe(
                        cb,
                        () => {
                            handleDownloadNoteByEmployeePdfBd(note, type, flags).subscribe(cb, cb);
                        }
                    )
                },
                () => {}
            );
        }
        else {
            setState({ ...state, downloadingNoteId: note.id });
            const cb = () => setState({ ...state, downloadingNoteId: null });
            notesService.downloadNote(note.id, note.name, true).subscribe(
                cb,
                () => {
                    handleDownloadNoteByEmployeePdfBd(note, UserType.Employee, flags).subscribe(cb, cb);
                }
            )
        }
    }

    const stateViews = new Map<NotesStates, any>([
        [
            NotesStates.Collection,
            {
                component: ManagedNotesCardsComponent,
                props: {
                    patientId: patientId,
                    handleGoToCreateNote: handleSelectAppointmentToCreateNote,
                    handleGoToNote: handleGoToNote,
                    handleDownloadNotePdf: handleDownloadNotePdf,
                    downloadingNoteId: state.downloadingNoteId,
                    handleEditNote: handleGoToEditNote
                }
            }
        ],
        [
            NotesStates.CreateInitialConsult,
            {
                component: CreateInitialConsultComponent,
                props: {
                    note: state.targetNote,
                    patientId: patientId,
                    appointmentId: state.appointmentId,
                    appointmentConfiguration: state.appointmentConfiguration,
                    handleGoBack: handleGoToNotes,
                    goBackTitle: "To Notes",
                    originalNote: state.originalNote
                }
            }
        ],
        [
            NotesStates.CreateInitialConsultOld,
            {
                component: CreateInitialConsultComponentOld,
                props: {
                    note: state.targetNote,
                    patientId: patientId,
                    appointmentId: state.appointmentId,
                    appointmentConfiguration: state.appointmentConfiguration,
                    handleGoBack: handleGoToNotes,
                    goBackTitle: "To Notes",
                    originalNote: state.originalNote
                }
            }
        ],
        [
            NotesStates.InitialConsult,
            {
                component: InitialConsultComponent,
                props: {
                    note: state.targetNote,
                    patientId: patientId,
                    handleGoToNotes: handleGoToNotes,
                    handleGoToEditNote: handleGoToEditNote
                }
            }
        ],
        [
            NotesStates.InitialConsultOld,
            {
                component: InitialConsultComponentOld,
                props: {
                    note: state.targetNote,
                    patientId: patientId,
                    handleGoToNotes: handleGoToNotes,
                    handleGoToEditNote: handleGoToEditNote
                }
            }
        ],
        [
            NotesStates.CreateFollowUp,
            {
                component: CreateFollowUpComponent,
                props: {
                    note: state.targetNote,
                    patientId: patientId,
                    handleGoBack: handleGoToNotes,
                    goBackTitle: "To Notes",
                    appointmentId: state.appointmentId,
                    appointmentConfiguration: state.appointmentConfiguration,
                    originalNote: state.originalNote
                }
            }
        ],
        [
            NotesStates.FollowUp,
            {
                component: FollowUpComponent,
                props: {
                    note: state.targetNote,
                    patientId: patientId,
                    handleGoToNotes: handleGoToNotes,
                    goBackTitle: "To Notes",
                    handleGoToEditNote: handleGoToEditNote
                }
            }
        ],
        [
            NotesStates.CreateInternal,
            {
                component: CreateSimpleComponent,
                props: {
                    note: state.targetNote,
                    patientId: patientId,
                    type: NotesType.Internal,
                    handleGoBack: handleGoToNotes,
                    goBackTitle: "To Notes",
                    originalNote: state.originalNote
                }
            }
        ],
        [
            NotesStates.Internal,
            {
                component: SimpleComponent,
                props: {
                    note: state.targetNote,
                    patientId: patientId,
                    handleGoToNotes: handleGoToNotes,
                    handleGoToEditNote: handleGoToEditNote
                }
            }
        ],
        [
            NotesStates.CreateBlank,
            {
                component: CreateSimpleComponent,
                props: {
                    note: state.targetNote,
                    patientId: patientId,
                    type: NotesType.Blank,
                    handleGoBack: handleGoToNotes,
                    goBackTitle: "To Notes",
                    originalNote: state.originalNote
                }
            }
        ],
        [
            NotesStates.Blank,
            {
                component: SimpleComponent,
                props: {
                    note: state.targetNote,
                    type: NotesType.Blank,
                    patientId: patientId,
                    handleGoToNotes: handleGoToNotes,
                    handleGoToEditNote: handleGoToEditNote
                }
            }
        ],
        [
            NotesStates.Soap,
            {
                component: SoapNoteComponent,
                props: {
                    note: state.targetNote,
                    patientId: patientId,
                    handleGoBack: handleGoToNotes,
                    goBackTitle: "To Notes",
                    handleGoToEditNote: handleGoToEditNote
                }
            }
        ],
        [
            NotesStates.CreateSoap,
            {
                component: CreateSoapNoteComponent,
                props: {
                    note: state.targetNote,
                    type: NotesType.Soap,
                    patientId: patientId,
                    handleGoBack: handleGoToNotes,
                    appointmentId: state.appointmentId,
                    appointmentConfiguration: state.appointmentConfiguration,
                    originalNote: state.originalNote
                }
            }
        ],
        [
            NotesStates.HistoryAndPhysicalInitial,
            {
                component: HistoryAndPhysicalNoteComponent,
                props: {
                    note: state.targetNote,
                    patientId: patientId,
                    handleGoBack: handleGoToNotes,
                    goBackTitle: "To Notes",
                    handleGoToEditNote: handleGoToEditNote
                }
            }
        ],
        [
            NotesStates.CreateHistoryAndPhysicalInitial,
            {
                component: CreateHistoryAndPhysicalNoteComponent,
                props: {
                    note: state.targetNote,
                    type: NotesType.HistoryAndPhysicalInitial,
                    patientId: patientId,
                    handleGoBack: handleGoToNotes,
                    appointmentId: state.appointmentId,
                    appointmentConfiguration: state.appointmentConfiguration,
                    originalNote: state.originalNote
                }
            }
        ],
        [
            NotesStates.HistoryAndPhysicalFollowUp,
            {
                component: HistoryAndPhysicalFollowUpNoteComponent,
                props: {
                    note: state.targetNote,
                    patientId: patientId,
                    handleGoBack: handleGoToNotes,
                    goBackTitle: "To Notes",
                    handleGoToEditNote: handleGoToEditNote
                }
            }
        ],
        [
            NotesStates.CreateHistoryAndPhysicalFollowUp,
            {
                component: CreateHistoryAndPhysicalFollowUpNoteComponent,
                props: {
                    note: state.targetNote,
                    type: NotesType.HistoryAndPhysicalFollowUp,
                    patientId: patientId,
                    handleGoBack: handleGoToNotes,
                    appointmentId: state.appointmentId,
                    appointmentConfiguration: state.appointmentConfiguration,
                    originalNote: state.originalNote
                }
            }
        ],
        [
            NotesStates.HistoryAndPhysicalGroupVisit,
            {
                component: HistoryAndPhysicalGroupVisitNoteComponent,
                props: {
                    note: state.targetNote,
                    patientId: patientId,
                    handleGoBack: handleGoToNotes,
                    goBackTitle: "To Notes",
                    handleGoToEditNote: handleGoToEditNote
                }
            }
        ],
        [
            NotesStates.CreateHistoryAndPhysicalGroupVisit,
            {
                component: CreateHistoryAndPhysicalGroupVisitNoteComponent,
                props: {
                    note: state.targetNote,
                    type: NotesType.HistoryAndPhysicalGroupVisit,
                    patientId: patientId,
                    handleGoBack: handleGoToNotes,
                    originalNote: state.originalNote
                }
            }
        ]
    ]);

    const view = stateViews.get(state.viewState);

    return [
        <Box>
            {React.createElement(view.component, view.props)}
        </Box>,
        state.isOpen,
        handleSelectAppointmentDialog,
        handleChangeSelectAppointment,
        handleSubmitSelectAppointment,
        handleConfigureAppointment
    ];
}