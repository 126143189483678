import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            color: colors.gray1,
            fontSize: 14,
        },
        filter: {
            display: "flex",
            width: '250px',
            background: colors.white,
            [theme.breakpoints.down("xs")]: {
                width: '100%'
            },
        },
        messagePreview: {
            backgroundColor: colors.bg,
        },
        descriptionTitle: {
            color: colors.gray2,
        },
        cursorPointer: {
            cursor: 'pointer'
        }
    })
);
