export interface UpdateDnaFileSynchronizationStatusModel {
    fileName: string;
    isSynchronized: boolean;
}

export interface UnmatchedLabResultModel {
    createdAt: Date;
    documents: UnmatchedDocumentModel[];
    id: number;
    labSource: string;
    orderNumber: string;
    updatedAt: Date;
}

export interface UnmatchedDocumentModel {
    createdAt: Date;
    id: number;
    mimeType: string;
    path: string;
    status: string;
    type: UnmatchedDocumentType;
    updatedAt: Date;
    labResultId: number;
    name: string;
}

export enum UnmatchedDocumentType {
    REQ = "REQ",
    ORDER = "ORDER",
    LABRES = "LABRES",
}
