import React, {useEffect, createRef} from 'react';
import { Box, IconButton, ListItem, Popover, Tooltip } from '@material-ui/core';
import { Message, MyMessage, DeletedMessage, MyDeletedMessage, useStyles, DiscardButton, ConfirmButton } from './messageComponent.styles';
import clsx from 'clsx';
import { MessageAvatarComponent } from "../messageAvatarComponent/MessageAvatarComponent";
import { MessageGroupModel } from "../../models/message.models";
import DescriptionIcon from '@material-ui/icons/Description';
import GetAppIcon from '@material-ui/icons/GetApp';
import {useFacade} from "./messageComponent.hooks";
import {HoverMenuMessageComponent} from '../hoverMenuMessageComponent/HoverMenuMessageComponent'
import { TextLinkMarkdownComponent } from '../../../notes/components/textMarkdownComponent/TextMarkdownComponent';
import { ProtectedElement } from '../../../common/components/ProtectedElement';
import { PermissionType, UserType } from '../../../auth/models/auth.enums';
import CheckIcon from '@material-ui/icons/Check';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import { InteractionType, TargetInteractionModel, deleteMessageReasonTypesNames } from '../../models/conversation.models';
import { WildHealthMenuItem } from '../../../common/components/wildHealthMenu/WildHealthMenu';
import { DeleteMessageDialog } from './DeleteMessageDialog';
import { authQuery } from '../../../auth/stores/auth';
import { fullName } from '../../../common/helpers/display-name';
import { profileQuery } from '../../../account/stores/profileStore';
import { ForwardMessageDialog } from './ForwardMessageDialog';

interface MessageComponentProps {
    messageGroup: MessageGroupModel;
    isLastMessage?: boolean;
    isMyMessage?: boolean;
    isLoading? :boolean;
    messageToScrollIndex?:number;
    handleSetMessageUnread?:Function;
    handleUpdateInteraction?: Function;
}

export const MessageComponent: React.FC<MessageComponentProps> = (props: MessageComponentProps) => {
    const classes = useStyles();

    const customTooltipClasses = {
        tooltip: classes.customWidth,
        arrow: classes.arrow,
        tooltipPlacementBottom: classes.tooltipPlacement,
        tooltipPlacementTop: classes.tooltipPlacement,
    };

    const scrollToMessageRef = createRef<HTMLDivElement>()

    const {
        messageGroup,
        isMyMessage,
        isLoading,
        messageToScrollIndex,
        handleSetMessageUnread,
        handleUpdateInteraction,
        isLastMessage = false
    } = props;

    const [
        formatDate,
        downloadMedia,
        state,
        handleOpenMenu,
        handleCloseMenu,
        handleAcceptInteraction,
        handleRejectInteraction,
        handleEditInteraction,
        handleOpenDeleteMessageDialog,
        handleCloseDeleteMessageDialog,
        handleChangeDeleteReason,
        handleDelete,
        handleCloseForward,
        handleSubmitForward
    ] = useFacade(messageGroup, isLastMessage, handleUpdateInteraction);

    const getMessageAuthor = (messageGroup:MessageGroupModel) => {
        let result = "";

        if(!!messageGroup.authorFirstName && messageGroup.authorFirstName !== "Unknown"){
            result = result + messageGroup.authorFirstName + " ";
        }
        if(!!messageGroup.authorLastName && messageGroup.authorLastName !== "Unknown"){
            result = result + messageGroup.authorLastName + " ";
        }
        if(!!messageGroup.employeeType){
            result = result + "- "+ messageGroup.employeeType;
        }

        return result;
    }

    const renderAiMessage = (messageGroup: MessageGroupModel) => {
        return (
            <>
                {
                    messageGroup.messages.map((message, index) => {
                        var isAiMessage = false;
                
                        let formattedMessage: any = message.originalMessage;
                        var targetAiMessage: TargetInteractionModel = {
                            conversationId: formattedMessage?.conversation?.sid,
                            messageSid: formattedMessage?.state?.sid,
                            interaction: null
                        };
                        if (!!formattedMessage?.state?.attributes?.Interactions) {
                            const interactions = formattedMessage?.state?.attributes?.Interactions;
                            if (interactions.length > 0) {
                                targetAiMessage.interaction = interactions[interactions.length - 1]
                                const converted = targetAiMessage.interaction.Detail;
                                const messageIndex = formattedMessage?.state?.index;
                                formattedMessage = <span className={classes.messageView}>{converted}</span>;
                                const thisMessageMustBeScrolledTo = messageIndex == messageToScrollIndex;
    
                                formattedMessage = (
                                    <div ref = { thisMessageMustBeScrolledTo ? scrollToMessageRef : null}>
                                        <TextLinkMarkdownComponent content={converted} classLink={classes.messageLinkUrl} />
                                    </div>
                                )
                                if (targetAiMessage.interaction.Type === InteractionType.Recommendation && isLastMessage) {
                                    isAiMessage = true;
                                }
                            }
                        }

                        if (!isAiMessage) return <></>

                        return (
                            <ListItem className={classes.messages}>
                                {<MessageAvatarComponent firstName="A" lastName="I" image="" isAi={true} />}
                                <Box className={classes.messagesBlock}>
                                    {
                                        React.createElement(Message, {
                                            key: index,
                                            primary: formattedMessage,
                                            secondary: index === messageGroup.messages.length - 1 ? formatDate(targetAiMessage.interaction.CreatedAt) : ''
                                        })
                                    }
                                </Box>
                                <Box mb={4} display="flex" gridGap={4}>
                                    <DiscardButton id={`action-open-menu-${index}`} onClick={(e) => handleOpenMenu(e)}>
                                        <MoreHorizIcon className="wh-tw-fill-current" />
                                    </DiscardButton>
                                    <ConfirmButton id={`ai-message-confirm-${index}`} disabled={Boolean(state.editingTarget) || state.acceptingLoading} onClick={() => handleAcceptInteraction(targetAiMessage)}>
                                        <CheckIcon className="wh-tw-fill-current" />
                                    </ConfirmButton>
                                </Box>
                                
                                <Popover
                                    anchorEl={state.menuAnchor}
                                    keepMounted
                                    open={Boolean(state.menuAnchor)}
                                    onClose={(e) => handleCloseMenu(e)}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                >
                                    <WildHealthMenuItem
                                        id="interaction-edit"
                                        onClick={() => handleEditInteraction(targetAiMessage)}
                                    >
                                        <EditIcon className="wh-tw-text-gray1" />
                                        <Box px={1.5} className="wh-tw-w-28">
                                            Edit
                                        </Box>
                                    </WildHealthMenuItem>
                                    <WildHealthMenuItem
                                        id="banner-edit"
                                        onClick={() => handleRejectInteraction(targetAiMessage)}
                                    >
                                        <DeleteIcon className="wh-tw-text-gray1"/>
                                        <Box px={1.5} className="wh-tw-w-28">
                                            Dismiss
                                        </Box>
                                    </WildHealthMenuItem>
                                </Popover>
                            </ListItem>
                        )
                    })
                }
            </>
        )
    }

    useEffect(() => {
        if (!isLoading) {
            scrollToMessage()
        }
    }, [])

    const scrollToMessage = () => {
        scrollToMessageRef?.current?.scrollIntoView({ block: "start",behavior:'smooth'});
    }

    const renderUserName = (userId: number) => {
        const employee = state.targetConversation?.employees?.find(e => e.userId === userId)
        if (employee) {
            return fullName(employee)
        }
        return '';
    }

    if (!messageGroup.messages.filter(x => !x.originalMessage.state?.attributes?.IsDeleted).length && authQuery.isPatientUser()) {
        return <></>
    }

    return (
        <>
        <ListItem className={clsx({ [classes.messages]: !isMyMessage, [classes.myMessages]: isMyMessage })}>
            {!isMyMessage && <MessageAvatarComponent firstName={messageGroup.authorFirstName} lastName={messageGroup.authorLastName} image={profileQuery.getPhoto(messageGroup.employeeId)} />}
            <Box className={classes.messagesBlock}>
                {
                    messageGroup.messages.map((message, index) => {
                        let formattedMessage: any = message.originalMessage;
                        let isDeleted = false;
                        if (formattedMessage?.state?.attributes?.IsDeleted) {
                            isDeleted = true;
                            const deletionDetails = formattedMessage?.state?.attributes?.DeletionDetails;
                                formattedMessage = (
                                    <Box display='flex' alignItems='center'>
                                        <Box className="wh-tw-text-sm" mr={1.5}>This message was deleted</Box>
                                        <Tooltip placement="top-end" arrow
                                            title={<p className={classes.notificationToolTipText}>{deleteMessageReasonTypesNames[deletionDetails.Reason]} {renderUserName(deletionDetails.DeletedBy)}</p>}
                                            classes={customTooltipClasses}>
                                            <InfoIcon className="wh-tw-text-gray1" />
                                        </Tooltip>
                                    </Box>
                                );
                        }
                        if (formattedMessage?.state?.type === 'media') {
                            const media = formattedMessage.state.media;
                            const converted = formattedMessage.body as string;
                            if (converted) {
                                const {participants,messagesEntity}  = formattedMessage.conversation;
                                const conversationSize = messagesEntity.messagesByIndex.size;
                                const participantExternalVendorId = participants.get(formattedMessage?.state?.participantSid)?.identity;
                                const conversationId = formattedMessage?.conversation?.sid;
                                const messageId = formattedMessage?.state?.sid;
                                const messageIndex = formattedMessage?.state?.index
                                formattedMessage = converted.length ? <span className={classes.messageView}>{converted}</span> : converted;
                                const thisMessageMustBeScrolledTo = messageIndex <= messageToScrollIndex;
    
                                formattedMessage = (
                                    <div ref = { thisMessageMustBeScrolledTo ? scrollToMessageRef : null}>
                                    <Box display='flex' alignItems='center' className={classes.breakText} mb={1}>
                                        <IconButton id="message-download-media" className={clsx(classes.fileButton, { [classes.messageFileButton]: !isMyMessage, [classes.myMessageFileButton]: isMyMessage })} onClick={() => downloadMedia(media)} size='small'>
                                            <DescriptionIcon className={classes.fileIcon} />
                                            <GetAppIcon className={classes.downloadIcon} />
                                        </IconButton>
    
                                        <Box ml={1}>{media.state?.filename}</Box>
                                    </Box>
                                    <HoverMenuMessageComponent
                                        isMyMessage={isMyMessage}
                                        messageIndex = {messageIndex}
                                        conversationSize = {conversationSize}
                                        participantId={participantExternalVendorId}
                                        conversationId = {conversationId}
                                        handleSetUnread={handleSetMessageUnread}
                                        handleOpenDeleteReason={() => handleOpenDeleteMessageDialog(conversationId, messageId)}
                                    >
                                        <TextLinkMarkdownComponent content={converted} classLink={isMyMessage ? classes.myMessageLinkUrl : classes.messageLinkUrl} />
                                    </HoverMenuMessageComponent>
                                    </div>
                                )
                            } else {
                                formattedMessage = (
                                    <Box display='flex' alignItems='center' className={classes.breakText}>
                                        <IconButton id="message-download-media" className={clsx(classes.fileButton, { [classes.messageFileButton]: !isMyMessage, [classes.myMessageFileButton]: isMyMessage })} onClick={() => downloadMedia(media)} size='small'>
                                            <DescriptionIcon className={classes.fileIcon} />
                                            <GetAppIcon className={classes.downloadIcon} />
                                        </IconButton>
    
                                        <Box ml={1}>{media.state?.filename ?? formattedMessage.state.body}</Box>
                                    </Box>
                                );
                            }
                        } else if (formattedMessage?.state?.type === 'text') {
                            const converted = formattedMessage.body as string;
                            const {participants,messagesEntity}  = formattedMessage.conversation;
                            const conversationSize = messagesEntity.messagesByIndex.size;
                            const participantExternalVendorId = participants.get(formattedMessage?.state?.participantSid)?.identity;
                            const conversationId = formattedMessage?.conversation?.sid;
                            const messageId = formattedMessage?.state?.sid;
                            const messageIndex = formattedMessage?.state?.index
                            formattedMessage = converted.length ? <span className={classes.messageView}>{converted}</span> : converted;
                            const thisMessageMustBeScrolledTo = messageIndex <= messageToScrollIndex;

                            formattedMessage = (
                                <div ref = { thisMessageMustBeScrolledTo ? scrollToMessageRef : null}>
                                <HoverMenuMessageComponent
                                    isMyMessage={isMyMessage}
                                    messageIndex = {messageIndex}
                                    conversationSize = {conversationSize}
                                    participantId={participantExternalVendorId}
                                    conversationId = {conversationId}
                                    handleSetUnread={handleSetMessageUnread}
                                    handleOpenDeleteReason={() => handleOpenDeleteMessageDialog(conversationId, messageId)}
                                >
                                    <TextLinkMarkdownComponent content={converted} classLink={isMyMessage ? classes.myMessageLinkUrl : classes.messageLinkUrl} />
                                </HoverMenuMessageComponent>
                                </div>
                            )
                        }

                        return (
                            <>
                            {
                                !isMyMessage && 
                                <span className={classes.messageAuthor}>
                                    { getMessageAuthor(messageGroup) } 
                                </span>
                            }
                            {
                                isDeleted 
                                    ? React.createElement(isMyMessage ? MyDeletedMessage : DeletedMessage, {
                                        key: index,
                                        primary: formattedMessage,
                                        secondary: index === messageGroup.messages.length - 1 ? formatDate(message.date) : ''
                                    })
                                    : React.createElement(isMyMessage ? MyMessage : Message, {
                                        key: index,
                                        primary: formattedMessage,
                                        secondary: index === messageGroup.messages.length - 1 ? formatDate(message.date) : ''
                                    })
                            }
                            </>
                        )
                    })
                }
            </Box>
            {isMyMessage && <MessageAvatarComponent firstName={messageGroup.authorFirstName} lastName={messageGroup.authorLastName} image={profileQuery.getPhoto(messageGroup.employeeId)} />}
        </ListItem>
        <ProtectedElement
            element={renderAiMessage(messageGroup)}
            permissions={[PermissionType.AiHealthCoachAssist]}
        />
        <ProtectedElement
            element={<DeleteMessageDialog open={state.isDeleteOpen} isDeleting={state.isDeleting} reason={state.deleteMessageRequest.reason} handleChanges={handleChangeDeleteReason} handleClose={handleCloseDeleteMessageDialog} handleDelete={handleDelete} />}
            permissions={[]}
        />
        <ProtectedElement
            element={<ForwardMessageDialog open={state.isForwardOpen} isForwarding={state.isForwarding} handleClose={handleCloseForward} handleSubmit={handleSubmitForward} />}
            permissions={[]}
            userType={UserType.Patient}
        />
        </>
    )
};
