import { useState, useEffect } from "react";
import { Subscription } from "rxjs";
import { GeneralInputsModel } from "../../models/input.models";
import { inputsQuery } from "../../stores/inputsStore";
import { onEmit } from '../../../common/helpers/on-emit';
import { IErrorState } from "../../../common/validation/error-state";
import { patientInputsService } from "../../services/patientInputs.service";
import { generalInputsComponentValidator } from "../generalInputsComponent/generalInputsComponent.validator"

interface PatientGeneralInputsComponentState extends IErrorState {
    inputs: GeneralInputsModel;
    isSaving: boolean;
    isVitalSaving: boolean;
}

export function useFacade(): [PatientGeneralInputsComponentState, Function, Function, Function] {
    const [state, setState] = useState({
        inputs: null,
        errors: {},
        isSaving: false,
        isVitalSaving: false
    } as PatientGeneralInputsComponentState);

    const handleChanges = (field: string, value: any) => {
        generalInputsComponentValidator.validateAndSetState(state, setState, field, value);
        typeof state.inputs[field] === "boolean"
            ? state.inputs[field] = !state.inputs[field]
            : state.inputs[field] = value;

        setState({ ...state, inputs: { ...state.inputs } });
    }

    const handleNumericChanges = (field: string, value: any) => {
        generalInputsComponentValidator.validateAndSetState(state, setState, field, value);
        state.inputs[field] = value.toString().length ? Number(value) : null;
        setState({ ...state, inputs: { ...state.inputs } });
    }

    const handleSubmit = () => {
        generalInputsComponentValidator.validateObjectAndSetState(state, setState, state.inputs);
        if (!generalInputsComponentValidator.stateIsValid(state)) {
            return;
        }
        setState(state => ({ ...state, isSaving: true }));
        const cb = () => {
            setState(state => ({
                ...state,
                isSaving: false,
            }));
        }

        patientInputsService.updateGeneralInputs(state.inputs).subscribe(cb, cb);
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<GeneralInputsModel>(inputsQuery.generalInputs$, inputs => {
                setState(state => ({ ...state, inputs }));
            })
        ];

        patientInputsService.getGeneralInputs();

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, []);

    return [state, handleChanges, handleNumericChanges, handleSubmit];
}