import { TextField } from "@material-ui/core";
import { createStyles, makeStyles, withStyles, Theme } from "@material-ui/core/styles";
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      borderColor: colors.gray4,
      '& input[type=number]': {
          '-moz-appearance': 'textfield',
      },
      '& input[type=number]::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
      },
      '& input[type=number]::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
      },
    },
    dimension: {
      color: colors.gray1,
      fontSize: 12,
    },
    danger: {
      color: colors.bad,
    },
    cell: {
      verticalAlign: "initial",
    },
    notificationToolTipPopper: {
      [theme.breakpoints.down("xs")]: {
        background: colors.lightBlack,
      },
    },
    notificationToolTipArrow: {
      [theme.breakpoints.down("xs")]: {
        color: colors.lightBlack,
      },
    },
    cellName: {
      [theme.breakpoints.down("xs")]: {
        width: "30%",
      },
    },
    graphContainer: {
      width: "fit-content",
      [theme.breakpoints.down("xs")]: {
        width: "70%",
      },
    },
  })
);

export const VitalsTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: colors.main,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: colors.main,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: colors.gray4,
      },
      "&:hover fieldset": {
        borderColor: colors.gray1,
      },
      "&.Mui-focused fieldset": {
        borderColor: colors.main,
      },
    },
  },
})(TextField);
