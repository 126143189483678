import { Box, Grid } from '@material-ui/core';
import React from 'react';
import { useStyles } from '../patientConversationsComponent/patientConversationsComponent.styles';
import { useFacade } from './patientConversationsViewComponent.hooks';
import { HealthCareChatViewComponent } from '../healthCareChatViewComponent/HealthCareChatViewComponent';
import { StaffChatViewComponent } from '../staffChatViewComponent/StaffChatViewComponent';
import { FullHealthCareViewChatComponent } from '../fullHealthCareViewChatComponent/FullHealthCareViewChatComponent';
import { FullStaffViewChatComponent } from '../fullStaffViewChatComponent/FullStaffViewChatComponent';

interface PatientConversationsComponentProps {
    patientId?: number;
}

export const PatientConversationsViewComponent: React.FC<PatientConversationsComponentProps> = (props: PatientConversationsComponentProps) => {
    const { patientId } = props;
    const classes = useStyles();

    const [
        {
            isSupportLoading,
            isSupportPhotosLoading,
            isHealthCareLoading,
            isHCPhotosLoading,
            supportConversations,
            healthCareConversation,
            isFullStaffChatOpen,
            isFullHealthCareChatOpen,
            messagesHealthcare,
            targetConversation
        },
        handleOpenStaffChat,
        handleOpenHealthCareChat,
        handleCloseStaffChat,
        handleCloseHealthCareChat,
        handleDownloadMessages,
    ] = useFacade(patientId);

    const renderFullChat = () => {
        return <>
           { isFullStaffChatOpen && <FullStaffViewChatComponent targetConversation={targetConversation}  conversations={supportConversations} closeFullChat={handleCloseStaffChat}/>}
           { isFullHealthCareChatOpen && <FullHealthCareViewChatComponent conversation={healthCareConversation} messages={messagesHealthcare} closeFullChat={handleCloseHealthCareChat}/>}
        </>
    }
    return (
        isFullStaffChatOpen || isFullHealthCareChatOpen
            ? renderFullChat()
            : 
            <Grid container className={classes.root}>
                <Grid item xs={12} md={6}>
                    <Box p={1} className={classes.container}>
                        <HealthCareChatViewComponent
                            isLoading={isHealthCareLoading || isHCPhotosLoading}
                            messages={messagesHealthcare}
                            conversation={healthCareConversation}
                            openFullChat={handleOpenHealthCareChat}
                            downloadMessages = {handleDownloadMessages}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Box p={1} className={classes.container}>
                        <StaffChatViewComponent
                            isLoading={isSupportLoading || isSupportPhotosLoading}
                            conversations={supportConversations}
                            openFullChat={handleOpenStaffChat}
                        />
                    </Box>
                </Grid>
            </Grid>


    )
};
