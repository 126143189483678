import React, {FC, useState} from 'react'
import {Box, Popover, Typography} from "@material-ui/core";
import {MenuMessage} from "./menuMessage/MenuMessage";
import {LastReadMessageUpdateModel} from "../../models/conversation.models";
import {useStyles} from "./HoverMenuMessageComponent.styles";
import {authQuery} from "../../../auth/stores/auth";

type HoverMenuMessageProps = {
    isMyMessage: boolean;
    messageIndex: number;
    conversationSize:number;
    conversationId: string;
    participantId: string;
    handleSetUnread: Function;
    handleOpenDeleteReason: () => void;
    children?: JSX.Element;
}

export const HoverMenuMessageComponent:FC<HoverMenuMessageProps> = (props) => {

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [anchorElForDelete, setAnchorElForDelete] = useState<HTMLElement | null>(null);

    const {
        isMyMessage,
        messageIndex,
        conversationSize,
        conversationId,
        participantId,
        handleSetUnread,
        handleOpenDeleteReason,
        children
    } = props;


    const classes = useStyles();


    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickAway = () => {
        handlePopoverClose()
    }

    const handleDeletePopoverClose = () => {
        setAnchorElForDelete(null);
    };

    const handleDeletePopoverOpen = (event) => {
        setAnchorElForDelete(event.currentTarget);
    };

    const handleDeleteClickAway = () => {
        handleDeletePopoverClose()
    }

    const handleDelete = () => {
        handleOpenDeleteReason()
        handleDeletePopoverClose()
    }

    const handleClick = () => {

        let newIndex = messageIndex < 0 ? 0 : messageIndex;

        handleSetUnread({
            conversationExternalVendorId: conversationId,
            participantExternalVendorId: participantId,
            lastMessageReadIndex: newIndex
        } as LastReadMessageUpdateModel)
        handlePopoverClose()
    }
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    if (authQuery.getEmployeeType()) {
        if (isMyMessage) {
            return (
                <div>
                    <Typography
                        id={id}
                        aria-owns="mouse-over-popover"
                        aria-describedby={id}
                        aria-haspopup="true"
                        onMouseEnter={handleDeletePopoverOpen}
                        onMouseLeave={handleDeletePopoverClose}
                    >
                        <Box display="flex" mt={-1} mr={-1} alignItems="flex-end" height="8px"></Box>
                    </Typography>
                    <Popover
                        id={id}
                        className={classes.popover}
                        disableScrollLock
                        classes={{
                            paper: classes.popoverContent
                        }}
                        open={Boolean(anchorElForDelete)}
                        anchorEl={anchorElForDelete}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right"
                        }}
                        PaperProps={{onMouseEnter: handleDeletePopoverOpen, onMouseLeave: handleDeleteClickAway}}
                    >
                        <MenuMessage isMyMessage={isMyMessage} handleDelete={handleDelete} handleSetUnread={handleClick}/>
                    </Popover>
                    {children}
                </div>
            )
        }

        return (
            <div>
                <Typography
                    id={id}
                    aria-owns="mouse-over-popover"
                    aria-describedby={id}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                >
                  <Box display="flex" mt={-1} mr={-1} alignItems="flex-end" height="8px"></Box>
                </Typography>
                <Popover
                    id={id}
                    className={classes.popover}
                    disableScrollLock
                    classes={{
                        paper: classes.popoverContent
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    PaperProps={{onMouseEnter: handlePopoverOpen, onMouseLeave: handleClickAway}}
                >
                    <MenuMessage isMyMessage={isMyMessage} handleDelete={handleDelete}  handleSetUnread={handleClick}/>
                </Popover>
                {children}
            </div>
        )
    } else {
        return children
    }
}