import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            [theme.breakpoints.down("xs")]: {
                padding: 16
            },
        },
        inputRoot: {
            borderColor: colors.gray4,
            borderRadius: 2,
            '& label.Mui-focused': {
                color: colors.main,
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: colors.main,
                },
                borderColor: colors.gray4,
                borderRadius: 2,
            },
        },
        backButton: {
            backgroundColor: colors.bg
        },
        title: {
            color: colors.black,
            fontWeight: 500,
            fontSize: 20,
        },
        content: {
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: colors.white,
            height: '100%',
            padding: 16
        },
        contentItems: {
            maxWidth: 500,
            width: '100%',
        },
        sectionTitle: {
            color: colors.black,
            fontWeight: 500,
            fontSize: 16,
            marginRight:"10px"
        },
        notificationToolTipText: {
            fontFamily: 'Be Vietnam Pro',
            fontSize: 12,
            fontWeight: 400,
            color: colors.white,
        },
        arrow: {
            color: colors.lightBlack,
        },
        customWidth: {
            width: '300px',
            maxWidth: '300px',
            backgroundColor: colors.lightBlack,
            padding: 10,
        },
        tooltipPlacement: {
            margin: '8px 0',
        },
    })
);
