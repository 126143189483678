import { useState } from "react";
import { restorePasswordPageValidator } from "../../../auth/pages/restorePasswordPage/restorePasswordPage.validator";
import { authService } from "../../../auth/services/auth.service";
import { ResetPasswordModel } from "../../../common/models/user.models";
import { IErrorState } from "../../../common/validation/error-state";
import { patientsService } from "../../../patients/services/patients.service";

interface ResetPasswordComponentState extends IErrorState {
    newPassword: string;
    confirmPassword: string;
}

export function useFacade(patientId?: number): [ResetPasswordComponentState, Function, Function] {
    const [state, setState] = useState({
        newPassword: '',
        confirmPassword: '',
        errors: {},
    } as ResetPasswordComponentState);

    const handleChanges = (key: string, value: string) => {
        state[key] = value;

        restorePasswordPageValidator.validateAndSetState(state, setState, key, value);
    }

    const handleSubmitForm = (event: Event) => {
        event.preventDefault();

        restorePasswordPageValidator.validateObjectAndSetState(state, setState, state);

        if (!restorePasswordPageValidator.stateIsValid(state)) {
            return
        }

        const model = {
            newPassword: state.newPassword,
            confirmPassword: state.confirmPassword
        } as ResetPasswordModel

        patientId 
            ? patientsService.resetPatientPassword(patientId, model)
            : authService.resetPassword(model).subscribe()
    }

    return [state, handleChanges, handleSubmitForm];
}
