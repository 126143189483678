import React from 'react';
import {Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField} from '@material-ui/core';
import {Gender, User} from "../models/user.models";
import {phoneNumberInput} from "./PhoneNumberInput";
import {displayDate, displayAge} from "../helpers/display-date";
import moment from 'moment';
import {WildHealthDatePicker} from "./WildHealthDatePicker";

interface UpdateUserFormProps {
    user: User,
    prefix: string;
    emailIsReadonly: boolean;
    errors: { [id: string]: string; };
    handleChanges: Function
}

export const UpdateUserForm: React.FC<UpdateUserFormProps> = (props: UpdateUserFormProps) => {
    const {
        user,
        prefix,
        emailIsReadonly,
        errors,
        handleChanges,
    } = props;

    const getBirthday = () => {
        return user.birthday
            ? moment(user.birthday).format('YYYY-MM-DD')
            : null
    }

    if (!handleChanges) {
        return (
            <Box height="260px">
                <Box width={1} display="flex">
                    <Box display="inline" width={'150px'} className="text-bold">First Name: </Box>
                    <Box display="inline">{user.firstName}</Box>
                </Box>
                <Box mt={2} width={1} display="flex">
                    <Box display="inline" width={'150px'} className="text-bold">Last Name: </Box>
                    <Box display="inline">{user.lastName}</Box>
                </Box>
                <Box mt={2} width={1} display="flex">
                    <Box display="inline" width={'150px'} className="text-bold">Date of Birth: </Box>
                    <Box display="inline"> {displayDate(user.birthday)} </Box>
                </Box>
                <Box mt={2} width={1} display="flex">
                    <Box display="inline" width={'150px'} className="text-bold">Age: </Box>
                    <Box display="inline"> {displayAge(user.birthday)} </Box>
                </Box>
                <Box mt={2} width={1} display="flex">
                    <Box display="inline" width={'150px'} className="text-bold">Gender: </Box>
                    <Box display="inline">{Gender[user.gender]}</Box>
                </Box>
                <Box mt={2} width={1} display="flex" >
                    <Box display="inline" width={'150px'} className="text-bold">Phone number: </Box>
                    <Box display="inline">{user.phoneNumber}</Box>
                </Box>
                <Box mt={2} width={1} display="flex" >
                    <Box display="inline" width={'150px'} className="text-bold">Email: </Box>
                    <Box display="inline">{user.email}</Box>
                </Box>
            </Box>
        );
    }

    return (
        <>
            <Box>
                <form autoComplete="off">
                    <Box width={1} display="flex" justifyContent="space-between">
                        <Box pr={1} width={1}>
                            <TextField
                                fullWidth
                                size="small"
                                required
                                disabled={!handleChanges}
                                id={`${prefix}firstName`}
                                variant="outlined"
                                value={user.firstName}
                                label="First Name"
                                InputProps={{className: 'input'}}
                                error={!!errors[`${prefix}firstName`]}
                                helperText={errors[`${prefix}firstName`]}
                                onChange={(v) => {
                                    handleChanges(v.target.id, v.target.value)
                                }}
                            />
                        </Box>
                        <Box pl={1} width={1}>
                            <TextField
                                required
                                fullWidth
                                size="small"
                                disabled={!handleChanges}
                                id={`${prefix}lastName`}
                                variant="outlined"
                                value={user.lastName}
                                label="Last Name"
                                InputProps={{className: 'input'}}
                                error={!!errors[`${prefix}lastName`]}
                                helperText={errors[`${prefix}lastName`]}
                                onChange={(v) => {
                                    handleChanges(v.target.id, v.target.value)
                                }}
                            />
                        </Box>
                    </Box>
                    <Box mt={2} width={1} display="flex" justifyContent="space-between">
                        <Box pr={1} width={0.5}>
                            <WildHealthDatePicker
                                id={`${prefix}birthday`}
                                disableFuture
                                fullWidth
                                size="small"
                                disabled={!handleChanges}
                                label="Date of Birth"
                                placeholder="yyyy-mm-dd"
                                openTo="year"
                                format="MM/DD/yyyy"
                                value={getBirthday()}
                                error={!!errors[`${prefix}birthday`]}
                                helperText={errors[`${prefix}birthday`]}
                                inputVariant="outlined"
                                views={["year", "month", "date"]}
                                onChange={(momentDate) =>
                                    handleChanges(`${prefix}birthday`, momentDate.format("YYYY-MM-DD")
                                )}
                            />
                        </Box>
                        <Box pl={1} width={0.5}>
                            <FormControl
                                variant="outlined"
                                error={!!errors[`gender`]}
                                color='primary'
                                size="small" fullWidth>
                                <InputLabel htmlFor="gender-label">Biological Gender</InputLabel>
                                <Select
                                    style={{background: '#FFF'}}
                                    id={`${prefix}gender`}
                                    value={user.gender}
                                    label="Biological Gender"
                                    error={!!errors[`${prefix}gender`]}
                                    inputProps={{
                                        name: 'Biological Gender',
                                        id: 'gender-label',
                                    }}
                                    onChange={(v) => handleChanges(`${prefix}gender`, v.target.value)}>
                                    <MenuItem value={Gender.Male}>{Gender[Gender.Male]}</MenuItem>
                                    <MenuItem value={Gender.Female}>{Gender[Gender.Female]}</MenuItem>
                                </Select>
                                <FormHelperText>{errors[`${prefix}gender`]}</FormHelperText>
                            </FormControl>
                        </Box>
                    </Box>
                    <Box width={1} mt={2} display="flex" justifyContent="space-between">
                        <Box pr={1} width={0.5}>
                            <TextField
                                fullWidth
                                required
                                size="small"
                                disabled={!handleChanges}
                                id={`${prefix}phoneNumber`}
                                variant="outlined"
                                label="Phone number"
                                value={user.phoneNumber}
                                error={!!errors[`${prefix}phoneNumber`]}
                                helperText={errors[`${prefix}phoneNumber`]}
                                InputProps={{
                                    className: 'input',
                                    inputComponent: phoneNumberInput,
                                }}
                                onChange={(v) => {
                                    handleChanges(v.target.id, v.target.value)
                                }}
                            />
                        </Box>
                        <Box pl={1} width={0.5}>
                            {
                                emailIsReadonly ?
                                    (
                                        <Box pt={1} width={1} display="flex" >
                                            <Box display="inline" width={'150px'} className="text-bold">Email: </Box>
                                            <Box display="inline">{user.email}</Box>
                                        </Box>
                                    ) :
                                    (
                                        <TextField
                                            required
                                            fullWidth
                                            size="small"
                                            id={`${prefix}email`}
                                            variant="outlined"
                                            value={user.email}
                                            label="Email"
                                            InputProps={{className: 'input'}}
                                            error={!!errors[`${prefix}email`]}
                                            helperText={errors[`${prefix}email`]}
                                            onChange={(v) => {
                                                handleChanges(v.target.id, v.target.value)
                                            }}
                                        />
                                    )
                            }
                        </Box>
                    </Box>
                </form>
            </Box>
        </>
    )
}
