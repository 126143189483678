import React from "react"
import { Box, Dialog, DialogContent } from "@material-ui/core";
import { DialogTitle } from '../../../common/components/dialogTitle/DialogTitle';
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { UnmatchedDocumentModel } from "../../models/unmatchedLabs.models";

interface LabFileViewDialogProps {
    isOpen: boolean;
    file: UnmatchedDocumentModel;
    handleCancel: () => void;
    handleSubmit: () => void;
}

export const LabFileViewDialogComponent: React.FC<LabFileViewDialogProps> = (props: LabFileViewDialogProps) => {
    const { isOpen, file, handleCancel, handleSubmit } = props;

    return (
        <Dialog
            maxWidth="lg"
            open={isOpen}
            onClose={() => handleCancel()}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id='dialog-title' onClose={() => handleCancel()}>
                <Box px={2}>{`Document${file?.id}`}</Box>
            </DialogTitle>
            <DialogContent>
                <Box p={1} display='flex' justifyContent='center'>
                    <iframe width='1030px' height='620px' src={file?.path}></iframe>
                </Box>
                <Box display="flex" justifyContent="center" p={2}>
                    <WildHealthButton
                        id="assign-to-patient-dialog-open"
                        onClick={() => handleSubmit()}
                        size='large'
                    >
                        Assign to Patient
                    </WildHealthButton>
                </Box>
            </DialogContent>
        </Dialog>
    )
}
