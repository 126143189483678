import { TextField } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme) => ({
  root: {
    borderColor: colors.mediumGray,
    borderRadius: 4,
    '& .MuiOutlinedInput-root': {
      borderColor: colors.mediumGray,
      borderRadius: 4,
    },
  },
  stateItem: {
    display: 'flex',
    gap: 6,
    alignItems: 'center',
    padding: '4px 6px',
    margin: 6,
    marginRight: 8,
    fontSize: 16,
    fontWeight: 400,
    color: colors.black,
    borderRadius: 2,
    backgroundColor: colors.separators,
  },
  removeIcon: {
    width: 18,
    height: 18,
    fill: colors.gray1,
    cursor: 'pointer'
  },
  trashIcon: {
    width: 30,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.white,
    borderRadius: 2,
    cursor: 'pointer',
  },
  plusButton: {
    width: 27,
    height: 27,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.white,
    borderRadius: 2,
    cursor: 'pointer',
    margin: 6,
  },
  plusIcon: {
    width: 18,
    height: 18,
    fill: colors.main
  },
  chip: {
    display: 'flex',
    gap: 6,
    alignItems: 'center',
    height: 27,
    margin: 2,
    lineHeight: '18px',
    backgroundColor: colors.separators,
    borderRadius: 2,
    padding: '4px 6px',
    overflow: 'hidden',
    fontSize: 16,
    fontWeight: 400,
    color: colors.black,
    '& svg': {
      fontSize: 18,
      cursor: 'pointer',
      fill: colors.gray1,
      margin: 0,
    },
    '& .MuiChip-label': {
      padding: 0,
    },
  },
  search: {
    backgroundColor: colors.white,
    borderColor: colors.white,
  },
  searchBase: {
    padding: '10px 14px',
    fontFamily: 'Be Vietnam Pro',
    fontSize: 16,
  },
  icon: {
    color: colors.gray2,
  },
}));

export const SearchTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: colors.main,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: colors.white,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: colors.white,
      },
      '&:hover fieldset': {
        borderColor: colors.white,
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.white,
      },
    },
  },
})(TextField);
