import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() =>
    createStyles({
        root: {
            flexGrow: 1,
            display: 'flex',
        },
        container: {
            width: '100%',
            height: '100%',
        },
        emptyPage: {
            display: 'flex',
            width: '100%'
        }
    })
)
