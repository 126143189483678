import { Box, Dialog, DialogContent, IconButton, List, ListItem, Popover } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { useFacade } from '../scheduledMessagesComponent/scheduledMessagesComponent.hooks';
import commonUseStyles from '../../../common/styles/common.styles';
import { DialogTitle } from '../../../common/components/dialogTitle/DialogTitle';
import { FileName } from '../attachmentsComponent/attachmentsComponent.styles';
import DescriptionIcon from '@material-ui/icons/Description';
import { ScheduledMessageDialog, useStyles } from '../scheduledMessagesComponent/scheduledMessagesComponent.styles';
import { ScheduledMessageModel, UploadedAttachmentModel } from '../../models/message.models';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { CommonPlaceHolder } from '../../../common/components/wildHealthPlaceHolder/CommonPlaceHolder';
import { WildHealthMenuItem } from '../../../common/components/wildHealthMenu/WildHealthMenu';

interface ScheduledMessagesDialogComponentProps {
    open: boolean;
    handleClose: () => void;
}

export const ScheduledMessagesDialogComponent: React.FC<ScheduledMessagesDialogComponentProps> = (props: ScheduledMessagesDialogComponentProps) => {
    const {
        open,
        handleClose,
    } = props;

    const [
        {
            editingTarget,
            messages,
            menuAnchorEl,
        },
        handleToggleActions,
        handleEdit,
        handleDelete,
    ] = useFacade(handleClose);

    const commonClasses = commonUseStyles();
    const classes = useStyles()

    const isEditing = Boolean(editingTarget);

    const renderAttachment = (attachment: UploadedAttachmentModel, index: number) => {
        return (
            <ListItem key={index} disableGutters classes={{ root: classes.root }}>
                <Box className={classes.document}>
                    <DescriptionIcon className={classes.fileIcon} />
                    <Box ml={1}>
                        <FileName primary={attachment.name} />
                    </Box>
                </Box>
            </ListItem>
        )
    }

    const renderMessage = (item: ScheduledMessageModel, index: number) => {
        const uploadedAttachs = item.uploadedAttachments ? item.uploadedAttachments.map(renderAttachment) : '';
        return (
            <ListItem key={index}>

                <Box ml={1} width='100%'>
                    <ScheduledMessageDialog
                        primary={<>
                            <Box>{item.message}</Box>
                            <List classes={{ root: classes.root }}>{uploadedAttachs}</List>
                        </>}
                        primaryTypographyProps={{ className: 'flat-scroll MuiListItemText-primary' }}
                    />
                </Box>
                <IconButton
                    id="scheduled-messages-more-actions"
                    onClick={(e) => handleToggleActions(e.currentTarget, item.id)}
                    disabled={isEditing}
                    size='small'
                >
                    <MoreHorizIcon />
                </IconButton>
            </ListItem>
        )
    }



    return (
        <>
            <Dialog
                fullWidth
                maxWidth='sm'
                open={open}
                onClose={handleClose}>

                <DialogTitle
                    id="customized-dialog-title-scheduled"
                    onClose={() => handleClose()}
                >
                    <span className={clsx(commonClasses.size24, commonClasses.textMedium, classes.dialogTitle)}>
                        Scheduled Messages
                    </span>
                </DialogTitle>

                <DialogContent>
                    <Box>
                        <List>
                            {
                                messages && messages.length ? messages.map((group, index) => renderMessage(group, index)) :
                                    <CommonPlaceHolder message='No scheduled messages.' />
                            }
                        </List>

                        <Popover
                            keepMounted
                            anchorEl={menuAnchorEl}
                            open={Boolean(menuAnchorEl)}
                            onClose={() => handleToggleActions()}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <WildHealthMenuItem id="scheduled-messages-edit" onClick={() => handleEdit()}>Edit</WildHealthMenuItem>
                            <WildHealthMenuItem id="scheduled-messages-delete" onClick={() => handleDelete()}>Delete</WildHealthMenuItem>
                        </Popover>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
}