import {useEffect, useState} from "react";
import {Subscription} from "rxjs";
import {GeneralValidator} from "../../../common/validation/general-validator";
import {onEmit} from "../../../common/helpers/on-emit";
import {InputModel, MicrobiomeInputModel} from "../../models/input.models";
import {inputsQuery} from "../../stores/inputsStore";
import {inputsService} from "../../services/inputs.service";
import {microbiomeInputsComponentValidator} from "./microbiomeInputsComponent.validator";
import {IErrorState} from "../../../common/validation/error-state";

interface MicrobiomeInputsComponentState extends IErrorState{
    inputs: MicrobiomeInputModel[];
    isLoading: boolean;
    isChanged: boolean;
}

export function useFacade(patientId: number): [MicrobiomeInputsComponentState, Function, Function, Function] {
    const [state, setState] = useState({
        inputs: [],
        isLoading: true,
        isChanged: false,
        errors: {}
    } as MicrobiomeInputsComponentState);

    const validateInput = (name: string, value: number | null) => {
        const error = microbiomeInputsComponentValidator.validate(name, value?.toString());
        error
            ? setState({...state, errors: GeneralValidator.addError(state, name, error)})
            : setState({...state, errors: GeneralValidator.removeError(state, name)});
    }

    const validateForm = () => {
        state.inputs.forEach(input => {
            const name = input.name;
            const value = input.value;
            validateInput(name, value);
        });
    }

    const handleEdit = (input: InputModel) => {
        input.editable = !input.editable;
        setState({...state});
    }

    const handleChanges = (input: InputModel, value: number) => {
        validateInput(input.name, value);

        if (input.value !== value || (value === 0 && input.isInitialized === false)) {
            input.isChanged = true;
            input.editable = true;
        }

        if (value === null) {
            input.isInitialized = false;
            input.value = 0;
        } else {
            input.value = value;
            input.isInitialized = true;
        }

        setState(state => ({...state, isChanged: true}));
    }

    const handleSubmit = () => {
        validateForm();
        if (!microbiomeInputsComponentValidator.stateIsValid(state)) {
            return;
        }

        inputsService.updateMicrobiomeInputs(state.inputs, patientId).subscribe(() => setState(state => ({...state, isChanged: false})));
    }

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<MicrobiomeInputModel[]>(inputsQuery.microbiomeInputs$, inputs => {
                setState(state => ({...state, inputs}));
            })
        ];

        inputsService.getMicrobiomeInputs(patientId).subscribe(() => setState(state => ({...state, isLoading: false})));

        return () => {
            subscriptions.map(it => it.unsubscribe())
        };
    }, [patientId]);

    return [state, handleEdit, handleChanges, handleSubmit];
}
