import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme) =>
  createStyles({
    notificationToolTipText: {
      fontFamily: 'Be Vietnam Pro',
      fontSize: 12,
      fontWeight: 400,
      color: colors.white,
    },
    arrow: {
      color: colors.lightBlack,
    },
    customWidth: {
      width: '278px',
      maxWidth: '278px',
      backgroundColor: colors.lightBlack,
      padding: 10,
    },
    tooltipPlacement: {
      margin: '8px 0',
    },
  })
);
