import { Button, InputBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React from 'react';
import { useStyles } from './searchComponent.styles';
import clsx from 'clsx';

interface SearchComponentProps {
    defaultValue?: string;
    placeholder: string;
    handleSearch: (value: string) => void,
    handleChanges?: (value: string) => void,
}

export const SearchComponent: React.FC<SearchComponentProps> = (props: SearchComponentProps) => {
    const {
        defaultValue,
        placeholder,
        handleSearch,
        handleChanges
    } = props;

    const [searchValue, setValue] = React.useState<string>(defaultValue);
    const classes = useStyles();

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        if (handleChanges) {
            handleChanges(event.target.value);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch(encodeURIComponent(searchValue));
        }
    }

    return (
        <InputBase
            placeholder={placeholder}
            className={clsx('input', classes.search)}
            onKeyPress={handleKeyPress}
            onChange={onChange}
            value={searchValue}
            classes={{
                root: classes.inputRoot,
                input: classes.inputField,
            }}
            endAdornment={
                <Button id="search" onClick={() => handleSearch(encodeURIComponent(searchValue))} disabled={!searchValue}>
                    <SearchIcon />
                </Button>
            }
        />
    );
}