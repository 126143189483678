import React from "react";
import { AuthenticatedLayoutComponent } from "../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent";
import { PagesNamesService } from "../../../../constants/pagesNames.constants";
import { TitlesService } from "../../../../constants/title.constants";
import { MenuItemTypes } from "../../../../models/menu.models";
import { VitalsHistoryComponent } from "../../components/vitalsHistoryComponent/VitalsHistoryComponent";
import { VitalsHistoryComponentMobile } from "../../components/vitalHistoryComponentMobile/VitalsHistoryComponentMobile";
import { useTheme, useMediaQuery } from "@material-ui/core";

export const VitalsHistoryPage: React.FC = (props: any) => {
  const isDataSetDialogOpen = props?.location?.state?.isDataSetDialogOpen as boolean;
  const patientId = props?.location?.state?.patientId as number | null;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const DynamicVitalHistoryComponent = isSmallScreen
    ? VitalsHistoryComponentMobile
    : VitalsHistoryComponent;

  return (
    <AuthenticatedLayoutComponent
      title={TitlesService.employeesTitle}
      pageName={PagesNamesService.vitalsHistory}
      selectedMenuItem={MenuItemTypes.Dashboard}
    >
      <DynamicVitalHistoryComponent
        patientId={patientId}
        isDataSetDialogOpen={isDataSetDialogOpen ? isDataSetDialogOpen : false}
      />
    </AuthenticatedLayoutComponent>
  );
};
