export const insertString = (str, subSrt, position) => {
    if(typeof(position) === undefined) {
        position = 0;
    }

    if(typeof(subSrt) === undefined) {
        subSrt = '';
    }

    if (!str || !str.length) {
        return subSrt;
    }

    return str.slice(0, position -1) + subSrt + str.slice(position - 1);
}