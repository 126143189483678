import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { onEmit } from "../../../common/helpers/on-emit";
import { questionnaireQuery } from "../../stores/questionnaireStore";


export function useFacade(): [boolean] {
    const [notCompleted, setNotCompleted] = useState(false);

    useEffect(() => {
        const subscriptions: Subscription[] = [
            onEmit<boolean>(questionnaireQuery.notificationMedicalHistory$, notification => {
                setNotCompleted(() => notification);
            }),
        ];

        return (() => {
            subscriptions.map(it => it.unsubscribe())
        })
    }, []);

    return [notCompleted];
}