import React from "react";
import { LabRangeModel, InputModel } from "../models/input.models";
import { LabResultsGraphComponent } from "../components/labResultsGraphComponent/LabResultsGraphComponent";
import { renderEmptyValue } from "../../common/helpers/empty-value";

export const renderLabResultValuesGraph = (
  values: InputModel[],
  range?: LabRangeModel,
  renderRange = false
) => {
  const initializedValues = values
    .filter((x) => x.isInitialized)
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  if (initializedValues.length < 2) {
    return renderEmptyValue("Insufficient data for trending");
  }

  return (
    <LabResultsGraphComponent
      data={initializedValues}
      renderRange={renderRange}
      minValue={range?.from}
      maxValue={range?.to}
    />
  );
};
