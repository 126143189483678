import { useCallback, useEffect, useState } from 'react';
import { authQuery } from '../../../auth/stores/auth';
import { ChatFilterTypes, ChatPatientFilterTypes, EmployeeConversationModel, FavoriteConversationModel } from "../../models/conversation.models";
import { employeeConversationsStore } from '../../stores/employeeConversationsStore/employeeConversations.store';
import { conversationsService } from '../../services/conversations.service';

interface patientFilterState {
    isOpen: boolean;
    anchorEl: HTMLElement;
}

export interface ChatListComponentState {
    isNewMessageOpen: boolean,
    isOpenFavoriteCollapse: boolean,
    isOpenRecentCollapse: boolean,
    search: string,
    filter: ChatFilterTypes,
    filterPatient: ChatPatientFilterTypes,
    conversations: EmployeeConversationModel[];
    patientFilterState: patientFilterState;
}

export function useFacade(conversations: EmployeeConversationModel[], targetConversation: EmployeeConversationModel, filters?: ChatFilterTypes[], isInternal?: boolean): [
    ChatListComponentState,
    () => void,
    (search: string, filter: ChatFilterTypes, filterPatient: ChatPatientFilterTypes) => void,
    (conversation: EmployeeConversationModel) => void,
    (event?: React.MouseEvent<HTMLElement>) => void,
    (conversation: EmployeeConversationModel, isFavorite: boolean) => void,
    () => void,
    () => void,
] {
    const [state, setState] = useState({
        isNewMessageOpen: false,
        isOpenFavoriteCollapse: true,
        isOpenRecentCollapse: true,
        search: '',
        filter: filters[0],
        filterPatient: ChatPatientFilterTypes.Active,
        conversations: conversations,
        patientFilterState: {
          isOpen: false,
          anchorEl: null,
        },
    } as ChatListComponentState);

    const handleToggleNewMessage = () => {
        setState(state => ({ ...state, isNewMessageOpen: !state.isNewMessageOpen }));
    }

    const handleTogglePatientFilter = (event?: React.MouseEvent<HTMLElement>) => {
        const anchorEl = event && event.currentTarget ? event.currentTarget : null;
        state.patientFilterState.isOpen = !state.patientFilterState.isOpen;
        state.patientFilterState.anchorEl = anchorEl;
        setState(state => ({ ...state }));
    }

    const filterPatientBySubscription = (conversations: EmployeeConversationModel[], filter: ChatPatientFilterTypes) => {
        switch (filter) {
            case ChatPatientFilterTypes.Active:
                return conversations.filter(i => i.patients[0].hasActiveSubscription);
            case ChatPatientFilterTypes.InActive:
                return conversations.filter(i => !i.patients[0].hasActiveSubscription);
            case ChatPatientFilterTypes.All:
            default:
                return conversations;
        }
    }

    const filterByActive = (conversations: EmployeeConversationModel[]) => {
        return conversations.filter(i => i.employees.find(i => i.email === authQuery.getEmail() && !i.isDeleted)?.isActive);
    }

    const filterByChatFilter = (conversations: EmployeeConversationModel[], filter: ChatFilterTypes) => {
        if (isInternal) {
            return filter === ChatFilterTypes.Closed ? conversations.filter(i => Number(i.state) === Number(filter) || !!i.employees.filter(e => e.isSigned && !e.isDeleted).length) : conversations.filter(i => Number(i.state) === Number(filter) && !i.employees.filter(e => e.isSigned && !e.isDeleted).length);
        }
        return conversations.filter(i => Number(i.state) === Number(filter));
    }

    const filterByMyPatients = (conversations: EmployeeConversationModel[]) => {
        const filterByExistedPatients = (conversation: EmployeeConversationModel) => {
            const author = conversation.employees.find(i => i.email === authQuery.getEmail() && !i.isDeleted);
            return !author?.isActive;
        }

        return conversations.filter(filterByExistedPatients);
    }

    const handleFiltering = useCallback((search: string, filter: ChatFilterTypes, filterPatient: ChatPatientFilterTypes) => {

        if (!filter) {
            return
        }

        const byName = (x: { firstName: string, lastName: string }) => `${x.firstName} ${x.lastName}`.toLowerCase().includes(search.toLowerCase());

        // Sorting should be:
        // 1. Conversations with oldest unread messages first
        // 2. Conversations with most recent engagement next
        // The idea is that conversations with non-recent responses are at the top and once they're responded to then they are moved to directly under the conversations with unread messages
        // https://wildhealth.atlassian.net/browse/CLAR-2812
        let filtered = conversations.filter(i =>
        (i.patients.filter(byName).length > 0 ||
            i.employees.filter(byName).length > 0)
        ).sort((a, b) => {
            if(a.oldestUnreadMessageAt !== undefined && b.oldestUnreadMessageAt === undefined) {
                return -1
            } else if(b.oldestUnreadMessageAt !== undefined && a.oldestUnreadMessageAt === undefined) {
                return 1;
            } else if(a.oldestUnreadMessageAt !== undefined && b.oldestUnreadMessageAt !== undefined) {
                return new Date(a.oldestUnreadMessageAt).getTime() - new Date(b.oldestUnreadMessageAt).getTime(); 
            } else {
                return new Date(b.lastMessageAt).getTime() - new Date(a.lastMessageAt).getTime();
            }
        });

        if (filter === ChatFilterTypes.Assigned) {
            filtered = filterByMyPatients(filtered);
        } else if (filter === ChatFilterTypes.SignedOff) {
            filtered = filterByChatFilter(filtered, ChatFilterTypes.Closed);
        } else {
            filtered = filterByActive(filterByChatFilter(filtered, filter));
        }

        filtered = filterPatientBySubscription(filtered, filterPatient);

        setState(state => ({ ...state, search: search, filter, filterPatient, conversations: filtered }));
    }, [conversations]);

    const handleConversationSelect = (conversation: EmployeeConversationModel) => {
        if (!targetConversation || conversation.id !== targetConversation.id) {
            employeeConversationsStore.setTargetConversation(conversation);
            setTimeout(() => {
                employeeConversationsStore.resetAttachments();
                employeeConversationsStore.resetAttachmentsView();
                employeeConversationsStore.enableToAttachmentAdding();
            }, 1);
        }
    }

    const handleUpdateFavoriteConversation = (conversation: EmployeeConversationModel, isFavorite: boolean) => {
        if (isFavorite) {
            conversationsService.removeFavoriteConversation(conversation.id).subscribe();
        } else {
            conversationsService.addFavoriteConversation(conversation.id).subscribe();
        }
    }
  
    const handleToggleFavoriteThreads = () => {
      setState(state => ({ ...state, isOpenFavoriteCollapse: !state.isOpenFavoriteCollapse }))
    }
    
    const handleToggleRecentThreads = () => {
      setState(state => ({ ...state, isOpenRecentCollapse: !state.isOpenRecentCollapse }))
    }

    useEffect(() => {
        handleFiltering(state.search, state.filter, state.filterPatient);

        return () => { };
    }, [handleFiltering]);

    return [
        state,
        handleToggleNewMessage,
        handleFiltering,
        handleConversationSelect,
        handleTogglePatientFilter,
        handleUpdateFavoriteConversation,
        handleToggleFavoriteThreads,
        handleToggleRecentThreads
    ];
}