import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {colors} from "../../../../common/constants/colors";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontSize: 20,
            fontWeight: 500,
            fontFamily: "Be Vietnam Pro",
            color: colors.white,
        },
        imageContainer: {
            minWidth: 180,
            minHeight: 180,
            borderRadius: 12,
            background: colors.lightGray2,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            color: colors.white,
        },
        label: {
            background: `${colors.white}25`,
            display: 'flex',
            fontSize: 12,
            justifyContent: 'center',
            alignItems: 'center',
            color: colors.white,
            borderRadius: 4,
            padding: 4,
            paddingRight: 10,
            width: 'max-content',
        },
        scheduleAppt: {
            color: colors.white,
            textTransform: 'capitalize',
            border: '1px solid',
        },
        openDialog: {
            color: colors.white,
            textTransform: 'capitalize',
        },
        dialog: {
            paddingTop: "30px !important",
            paddingLeft: "40px !important",
            paddingRight: "40px !important",
            paddingBottom: "30px !important",
            [theme.breakpoints.down("xs")]: {
              paddingLeft: '0px !important',
              paddingRight: '0px !important',
              paddingBottom: '0px !important',
              '& .MuiDialog-paperFullScreen': {
                borderRadius: '20px 20px 0 0',
              },
            },
          },
          dialogContent: {
            paddingLeft: 40,
            paddingRight: 40,
            paddingBottom: 30,
            [theme.breakpoints.down("xs")]: {
              padding: 16,
            },
          },
          dialogTitle: {
            padding: 24,
          },

    })
);
