import { Box, Divider, Drawer, useMediaQuery, useTheme, IconButton } from '@material-ui/core';
import React from 'react';
import { BackButton } from '../../../common/components/wildHealthButton/BackButton';
import { ArrowBack } from '@material-ui/icons';
import { useStyles } from '../fullStaffChatComponent/fullStaffChatComponent.styles';
import { useFacade } from './fullStaffViewChatComponent.hooks';
import { ReactComponent as OpenSideIcon } from '@img/icons/OpenSide.svg';
import clsx from 'clsx';
import {conversationTitles, ConversationType, PatientConversationModel} from "../../models/conversation.models";
import { MessageAvatarsComponent } from '../messageAvatarsComponent/MessageAvatarsComponent';
import { StaffConversationsHistoryComponent } from '../staffConversationsHistoryComponent/StaffConversationsHistoryComponent';
import { WildHealthTabControl } from '../../../common/components/wildHealthTabControl/WildHealthTabControl';
import { TabItem } from '../../../common/components/wildHealthTabControl/wildHealthTabControl.hooks';
import { useStylesSideBarTabs } from '../../../common/components/wildHealthTabControl/wildHealthTabControl.styles';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { ChatsPlaceHolder } from '../../../common/components/wildHealthPlaceHolder/ChatsPlaceHolder';
import { ConversationViewComponent } from '../conversationViewComponent/ConversationViewComponent';
import { AttachmentsViewComponent } from '../attachmentsViewComponent/AttachmentsViewComponent';

export interface FullStaffViewChatComponentProps {
    targetConversation: PatientConversationModel;
    conversations: PatientConversationModel[];
    closeFullChat: () => void;
}

export const FullStaffViewChatComponent: React.FC<FullStaffViewChatComponentProps> = (props: FullStaffViewChatComponentProps) => {
    const classes = useStyles();
    const sideBarTabsStyle = useStylesSideBarTabs();

    const [
        {
            isLoading,
            isSideAreaOpen,
            messagesStaff,
        },
        handleToggleSideArea,
        handleSelectConversation,
    ] = useFacade();

    const theme = useTheme();
    const isMiddleScreen = useMediaQuery(theme.breakpoints.down('md'));
  

    const renderGoBack = () => {
        return (
            <Box pb={2}>
                <BackButton
                    id="full-staff-view-chat-go-back"
                    onClick={() => props.closeFullChat()}
                >
                    <Box
                        display="flex"
                        alignItems="center"
                    >
                        <ArrowBack fontSize="small" />
                        <Box ml={1}>
                            <span>Back</span>
                        </Box>
                    </Box>
                </BackButton>
            </Box>
        )
    }

    const renderHeaderArea = () => {
        return (
            <Box
                p={2}
                height='100%'
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                className={classes.header}
            >
                <Box display='flex'>
                    <Box>
                        <MessageAvatarsComponent participants={[{image: null, initials: 'S'}]} />
                    </Box>
                    <Box ml={2} display='flex' flexDirection='column'>
                        <Box className={classes.title}>
                            {conversationTitles.get(ConversationType.Support)}
                        </Box>
                        <Box className={classes.subject}>
                            {props.targetConversation?.subject}
                        </Box>
                    </Box>
                </Box>
                <Box display='flex'>
                    {
                        isMiddleScreen &&
                        <Box ml={2}>
                            <IconButton id="full-staff-view-chat-side-area" onClick={() => handleToggleSideArea(!isSideAreaOpen)}>
                                <OpenSideIcon className={classes.icon} />
                            </IconButton>
                            <Drawer
                                anchor='right'
                                open={isSideAreaOpen}
                                onClose={() => handleToggleSideArea(!isSideAreaOpen)}
                            >
                                <Box className={classes.sideMobile}>
                                    {renderSideArea('100%')}
                                </Box>
                            </Drawer>
                        </Box>
                    }
                </Box>
            </Box>
        )
    }

    const renderSideArea = (height: string) => {

        const tabs: TabItem[] = [
            {
                title: 'Message History',
                content: (
                    isLoading ? <Box p={3}><WildHealthLinearProgress /></Box> :
                    !props.conversations.length ? <ChatsPlaceHolder message="No messages yet."/> :
                    <StaffConversationsHistoryComponent
                        height={height}
                        conversations={props.conversations}
                        targetConversation={props.targetConversation}
                        handleSelect={(conversation) => handleSelectConversation(conversation)}
                    />
                ),
                keepMounted: true,
                
            },
            {
                title: 'Attachments',
                content: (
                    isLoading ? <Box p={3}><WildHealthLinearProgress /></Box> :
                    <Box className={clsx('flat-scroll', classes.sideContainer)} height={height} maxHeight={height}>
                        <AttachmentsViewComponent />
                    </Box>
                ),
                keepMounted: true,
            },
        ];

        return (
            <Box width='100%'>
                <WildHealthTabControl
                    items={tabs}
                    className={sideBarTabsStyle}
                />
            </Box>
        )
    }

    const renderSelectedConversation = () => {
        return (
            <ConversationViewComponent conversation={props.targetConversation} messages={messagesStaff} threadHeight='calc(100vh - 247px)' />
        )
    }

    return (
        <Box className={classes.root}>
            {renderGoBack()}

            <div className={classes.chat}>
                <div className={clsx('g', classes.messages)}>
                    {renderHeaderArea()}

                    <Divider className={classes.divider} orientation='horizontal' />

                    {renderSelectedConversation()}
                </div>
                {
                    !isMiddleScreen &&
                    <div className={clsx('g', classes.side)}>
                        <Divider className={classes.divider} orientation='vertical'/>

                        {renderSideArea('calc(100vh - 300px)')}
                    </div>
                }
            </div>
        </Box>
    )
};
