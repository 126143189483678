import { nullableRangeValidationRule, rangeValidationRule } from "../../common/validation/general-validation-rules";
import { GeneralValidator } from "../../common/validation/general-validator";
import { LabRangeModel, LabRangeType, LabValidationSource } from "../../inputs/models/input.models";

export const vitalsErrorMessages = {
    bloodPressureIsAbnormal: 'Your Blood Pressure is abnormal. Norm: 90/60mmHg - 120/80mmHg',
    diastolicIsGraterThanSystolic: 'Diastolic cannot be greater than or equal to Systolic',
    systolicAndDiastolicNotFiled: 'Please enter a complete blood pressure',
}

export class VitalsNames {
    static readonly SystolicBloodPressure: string = "SystolicBloodPressure";
    static readonly DiastolicBloodPressure: string = "DiastolicBloodPressure";
    static readonly HeartRate: string = "HeartRate";
    static readonly HRV: string = "HRV";
    static readonly RHR: string = "RHR";
    static readonly Weight: string = "Weight";
    static readonly Height: string = "Height";
    static readonly BMI: string = "BMI";
    static readonly Waist: string = "Waist";
    static readonly Steps: string = "Steps";
    static readonly Calories: string = "Calories";
    static readonly Temperature: string = "Temperature";
}
export class VitalsKeys {
    static readonly selectedDate: string = "selectedDate";
    static readonly systolicBloodPressure: string = "systolicBloodPressure";
    static readonly diastolicBloodPressure: string = "diastolicBloodPressure";
    static readonly waist: string = "waist";
    static readonly weight: string = "weight";
}

export const vitalsValidatorKeys = [
    VitalsKeys.systolicBloodPressure,
    VitalsKeys.diastolicBloodPressure,
    VitalsKeys.weight,
    VitalsKeys.waist,
]

export const enterVitalsValidator = new GeneralValidator({
    [VitalsKeys.systolicBloodPressure]: [rangeValidationRule(0, 280)],
    [VitalsKeys.diastolicBloodPressure]: [rangeValidationRule(0, 200)],
    [VitalsKeys.weight]: [rangeValidationRule(0, 1200, 'Not more than 1200lbs')],
    [VitalsKeys.waist]: [rangeValidationRule(0, 125, 'Not more than 125in')],
})

export const vitalsValidator = new GeneralValidator({
    [VitalsNames.SystolicBloodPressure]: [rangeValidationRule(0, 280)],
    [VitalsNames.DiastolicBloodPressure]: [rangeValidationRule(0, 200)],
    [VitalsNames.HeartRate]: [rangeValidationRule(0, 220)],
    [VitalsNames.HRV]: [nullableRangeValidationRule(20, 200)],
    [VitalsNames.RHR]: [rangeValidationRule(0, 1000)],
    [VitalsNames.Weight]: [rangeValidationRule(0, 1200, 'Not more than 1200lbs')],
    [VitalsNames.Waist]: [rangeValidationRule(0, 125, 'Not more than 125in')],
    [VitalsNames.Steps]: [rangeValidationRule(0, 100000)],
    [VitalsNames.Calories]: [rangeValidationRule(0, 100000)],
})

export const vitalsNormalValuesValidator = new GeneralValidator({
    [VitalsNames.SystolicBloodPressure]: [rangeValidationRule(90, 120, vitalsErrorMessages.bloodPressureIsAbnormal)],
    [VitalsNames.DiastolicBloodPressure]: [rangeValidationRule(60, 80, vitalsErrorMessages.bloodPressureIsAbnormal)],
})

export const vitalsNormalValues = new Map<string, LabRangeModel>([
    [VitalsNames.SystolicBloodPressure, {from: 89, to: 121, dimension: '', type: LabRangeType.FromTo, source: LabValidationSource.ValueScope}],
    [VitalsNames.DiastolicBloodPressure, {from: 59, to: 81, dimension: '', type: LabRangeType.FromTo, source: LabValidationSource.ValueScope}],
]);
