import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles(theme => ({
    planCard1: {
        lineHeight: 1.5,
        border: `2px solid`,
        borderColor: colors.accent1,
        borderRadius: 8,
        height: '100%',
        boxShadow: '0px 4px 9px rgba(168, 173, 198, 0.15)',
        padding: '40px 32px',
    },
    planCard2: {
        lineHeight: 1.5,
        border: `2px solid ${colors.lightGray1}`,
        borderRadius: 8,
        height: '100%',
        boxShadow: '0px 4px 9px rgba(168, 173, 198, 0.15)',
        padding: '40px 32px',
    },
    planPricing: {
        textAlign: 'center',
    },
    planName: {
        color: colors.linkBlack,
        fontSize: 36,
        fontWeight: 500,
        lineHeight: '54px'
    },
    planBenefits: {
        textAlign: 'center',
    },
    label: {
        fontWeight: 'bold',
        fontSize: '12px',
        lineHeight: '22px',
        height: '22px',
        paddingRight: 10,
        paddingLeft: 10,
        border: 'none',
        backgroundColor: colors.lightBlue,
        color: colors.white,
        textAlign: 'center',
        borderRadius: 2,
    },
    startPrice: {
        fontSize: 48,
        lineHeight: '64px',
        letterSpacing: '0.02em',
    },
    spacingZero: {
        letterSpacing: 0,
    },
    saveMoneyStyle: {
        color: colors.accent1,
    }
}));