import { OrderType } from "../../orders/models/orders.models";
import LabCorp from '@img/companies/labCorp.png';
import Quest from '@img/companies/quest.png';
import Boston from '@img/companies/bostonHeart.png';

export enum AddOnProvider {
    ImageMark = 1,
    Elysium = 100,
    LabCorp = 200,
    Quest = 201,
    Boston = 202,
}

export const addOnProvidersNames = new Map<AddOnProvider, string>([
    [AddOnProvider.ImageMark, 'ImageMark'],
    [AddOnProvider.Elysium, 'Elysium'],
    [AddOnProvider.LabCorp, 'LabCorp'],
    [AddOnProvider.Quest, 'Quest'],
    [AddOnProvider.Boston, 'Boston Heart'],
]);

export const addOnProvidersImages = new Map<AddOnProvider, string>([
    [AddOnProvider.ImageMark, 'ImageMark'],
    [AddOnProvider.Elysium, 'Elysium'],
    [AddOnProvider.LabCorp, LabCorp],
    [AddOnProvider.Quest, Quest],
    [AddOnProvider.Boston, Boston],
]);

export interface AddOnModel {
    id: number;
    alternativeOffer: string;
    integrationId: string;
    name: string;
    description: string;
    instructions: string;
    price: number;
    orderType: OrderType;
    provider: AddOnProvider;
    alternativeAddon: AddOnModel;
    children: AddOnModel[];
    needSpecification: boolean;
}

export interface AddOnFilterModel {
    name: string;
    isSelected: boolean;
    type: number;
}

export interface SelectAddOnModel extends AddOnModel {
    isSelected: boolean;
}

export const addOnsList = [
    {
        name: 'Bloodwork',
        isSelected: false,
        type: OrderType.Lab,
    },
    {
        name: 'Epigenetic Age',
        isSelected: false,
        type: OrderType.Epigenetic,
    },
    {
        name: 'DNA',
        isSelected: false,
        type: OrderType.Dna,
    },
    {
        name: 'N/A',
        isSelected: false,
        type: OrderType.Default,
    },
]

export interface CreateAddOnModel {
    integrationId: string;
    name: string;
    price: number;
    orderType: OrderType;
    provider: AddOnProvider;
}

export interface UpdateAddOnModel {
    id: number;
    alternativeOffer: string | null;
    integrationId: string;
    name: string;
    description: string;
    instructions: string;
    price: number;
    orderType: OrderType;
    provider: AddOnProvider;
    alternativeAddon: AddOnModel | null;
}
