import { Query } from '@datorama/akita';
import { patientStore, PatientStore, PatientUnionState } from "./patients.store";
import { PatientModel } from "../../models/patient.model";
import { SubscriptionModel } from '../../../payment/models/subscription.models';

/**
 * Provides patient queries
 */
export class PatientsQuery extends Query<PatientUnionState> {
    patients$ = this.select(state => state.patients);
    patientsTotalCount$ = this.select(state => state.patientsTotalCount);
    targetPatient$ = this.select(state => state.targetPatient);
    coachPatients$ = this.select(state => state.coachPatients);
    practicumPatients$ = this.select(state => state.practicumPatients);
    patientTimeZone$ = this.select(state => state.patientTimeZone);
    myPatients$ = this.select(state => state.myPatients);
    chronologicalEvents$ = this.select(state => state.chronologicalEvents);
    patientTasks$ = this.select(state => state.patientTasks);
    patientsEngagement$ = this.select(state => state.patientsEngagement);
    patientJourneyTasks$ = this.select(state => state.patientJourneyTasks);
    todoPatientJourneyTasks$ = this.select(state => state.todoPatientJourneyTasks);
    completedJourneyTasks$ = this.select(state => state.completedJourneyTasks);
    callToActions$ = this.select(state => state.callToActions);
    isRequestOpen$ = this.select(state => state.isRequestOpen);
    isReceivedOpen$ = this.select(state => state.isReceivedOpen);
    healthCoachEngagements$ = this.select(state => state.healthCoachEngagements);
    tab$ = this.select(state => state.tab);
    visitPrep$ = this.select((state) => state.visitPrep);
    isDownloadingTask$ = this.select(state => state.isDownloadingTask);

    constructor(protected store: PatientStore) {
        super(store);
    }

    public getTargetPatient(): PatientModel {
        return this.getValue().targetPatient;
    }

    public getTargetPatientIsPremium(): boolean {
        return this.getValue().targetPatient?.isPremium;
    }

    public getTargetPatientSubscription(): SubscriptionModel {
        return this.getValue().targetPatient?.subscription;
    }
}

export const patientsQuery = new PatientsQuery(patientStore);
