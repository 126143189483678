import { Box, Divider, Paper } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import React, { useEffect } from 'react';
import { AuthenticatedLayoutComponent } from '../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from '../../../../models/menu.models';
import { CreatedPatientModel } from "../../../patients/models/patient.model";
import { useHistory } from 'react-router';
import { navigationService } from "../../../../services/navigation.service";
import { snackService } from "../../../common/snack/state";

export const BuySubscriptionSucceededPage: React.FC = (props: any) => {
    const history = useHistory();
    const patient = props?.location?.state?.patient ?? {};
    const {email, intakeId} = patient ?? {} as CreatedPatientModel;

    useEffect(() => {
        snackService.postNativeDeviceEvent('registrationSuccessEvent');
    }, []);

    if (!intakeId) {
        navigationService.toDashboard(history);
        return (<> </>);
    }

    return (
        <AuthenticatedLayoutComponent
            title={TitlesService.paymentSuccessTitle}
            pageName={PagesNamesService.buyNewSubscription}
            selectedMenuItem={MenuItemTypes.None}
        >
            <Paper variant="elevation" className="wh-form">
                <Box textAlign="center">
                    <Box height="120px" fontSize="100px">
                        <CheckCircleOutlineIcon fontSize="inherit" className="text-accent"/>
                    </Box>
                    <Box mt={1}>
                        <span className="secondary-font size-2">Success! Your payment has been processed!</span>
                    </Box>
                </Box>
                <Divider className="divider"/>
                <Box ml={"10%"} mt={10} mb={10}>
                    <Box>
                        <span className="size-2 text-medium uppercase text-accent">What’s next?</span>
                    </Box>
                    <Box mt={4}>
                        <span>We sent you a confirmation email to <span className="text-medium">{email}</span>. Please, check your inbox or your spam folder, if necessary.</span>
                    </Box>
                    <Box mt={4}>
                        <span>You will be receiving a DNA kit in the mail to collect your DNA sample to help us create your personalized health report. Please follow the instructions provided & drop it off at a UPS location as quickly as possible.</span>
                    </Box>
                    <Box mt={4}>
                        <span>Thank you, for choosing Wild Health and putting your trust in us. We are excited to work with you on your quest for optimal health.</span>
                    </Box>
                </Box>
                <Divider className="divider"/>
            </Paper>
        </AuthenticatedLayoutComponent>
    )
}
