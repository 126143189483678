import { makeStyles } from '@material-ui/core/styles';
import { colors } from "../../../common/constants/colors";

export const useStyles = makeStyles((theme) => ({
  root: {
    borderColor: colors.mediumGray,
    borderRadius: 4,
    '& .MuiOutlinedInput-root': {
      borderColor: colors.mediumGray,
      borderRadius: 4,
    },
  },
  couponCodeInput: {
    minWidth:   250,
  },
  applyBtn: {
    minWidth: 200
  },
  appliedCouponBox:{
    backgroundColor:colors.lightBlue2,
    borderRadius: 4
  },
  appliedCouponSpan: {
    color: colors.main
  },
  applyBtnText: {
    color: colors.primaryV,
    fontSize: 16,
    fontWeight: 500
  },
  couponCodeBox:{
    backgroundColor: colors.gray6,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    paddingLeft: 16,
    paddingRight: 16
  },
  couponCodeText: {
    color: colors.gray700,
    fontSize: 14,
    fontWeight: 500,
  },
  removeIcon: {
    fill: colors.gray700
  }
}));