import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { getLastObject } from "../../../../../common/helpers/get-last-object";
import { onEmit } from "../../../../../common/helpers/on-emit";
import { IErrorState } from "../../../../../common/validation/error-state";
import { PatientModel, UpdatePatientModel } from "../../../../../patients/models/patient.model";
import { patientsService } from "../../../../../patients/services/patients.service";
import { patientsQuery } from "../../../../../patients/stores/patientsStore/patients.query";
import { EmployeeShortModel } from "../../../../models/employee.models";
import { employeeService } from "../../../../services/employees.service";
import { employeesQuery } from "../../../../stores/employeesStore/employeesQuery";
import { patientInformationComponentValidator } from "../../patientInformationComponentValidator/patientInformationComponent.validator";

interface GeneralInfoWidgetComponentState extends IErrorState {
  isLoading: boolean;
  isSaveChanges: boolean;
  edit: boolean;
  patient: PatientModel;
  patientDraft: PatientModel;
  employees: EmployeeShortModel[],
}

const pageFields = [
  'firstName',
  'lastName',
  'birthday',
  'gender',
  'email',
  'phoneNumber',
  'assignedEmployeeIds',
];

export function useFacade(patientId: number | null): [
  GeneralInfoWidgetComponentState,
  Function,
  () => void,
  () => void,
  () => void,
  (id: number) => void,
] {
  const [state, setState] = useState({
    isLoading: true,
    isSaveChanges: false,
    edit: false,
    patient: {},
    employees: [],
    patientDraft: {},
    errors: {},
  } as GeneralInfoWidgetComponentState);

  const handleEditToggle = () => {
    setState(state => ({
      ...state,
      edit: !state.edit,
      patientDraft: {
        ...state.patient
      }
    }));
  }

  const handleReset = () => {
    setState(state => ({
      ...state,
      edit: !state.edit,
      patientDraft: {
        ...state.patient,
      }
    }));

  }

  const handleEmployeeSelect = (id: number) => {
    const draftPatient = state.patientDraft;

    if (draftPatient.assignedEmployees.map(el => el.id).includes(id)) {
      draftPatient.assignedEmployees = draftPatient.assignedEmployees.filter(x => x.id !== id);
    } else {
      draftPatient.assignedEmployees = [...draftPatient.assignedEmployees, state.employees.find(el => el.id === id)];
    }

    setState({
      ...state,
      patientDraft: { ...draftPatient }
    });

    patientInformationComponentValidator.validateAndSetState(state, setState, 'assignedEmployeeIds', draftPatient.assignedEmployees.map(el => el.id));
  }

  const handleSave = () => {
    validateForm();
    if (!patientInformationComponentValidator.stateIsValid(state)) {
      return;
    }

    setState(state => ({ ...state, isSaveChanges: true }));

    const model: UpdatePatientModel = {
      id: state.patientDraft.id,
      firstName: state.patientDraft.firstName,
      lastName: state.patientDraft.lastName,
      email: state.patientDraft.email,
      phoneNumber: state.patientDraft.phoneNumber,
      birthday: state.patientDraft.birthday,
      gender: state.patientDraft.gender,
      billingAddress: state.patientDraft.billingAddress,
      shippingAddress: state.patientDraft.shippingAddress,
      employeeIds: state.patientDraft.assignedEmployees.map(el => el.id),
      options: state.patientDraft.options,
      leadSource: state.patientDraft.leadSource?.leadSourceId === 0 ? null : state.patientDraft.leadSource,
      locationId: state.patient.locationId
    };

    patientsService.update(model).subscribe(
      () => {
        setState(state => ({ ...state, isSaveChanges: false, edit: !state.edit, }));
      },
      () => {
        setState(state => ({ ...state, isSaveChanges: false }));
      }
    );
  }

  /**
 * Validates patient profile form
 */
  const validateForm = () => {
    pageFields.forEach(field => {
      const keys = field.split(".");
      const key = keys.pop();
      const lastObject = getLastObject(keys, state.patientDraft);
      const value = lastObject[key];
      patientInformationComponentValidator.validateAndSetState(state, setState, field, value);
    });
  }

  /**
   * Handles changes. Additionally does field validation
   * @param field
   * @param value
   */
  const handleChanges = (field: string, value: string) => {
    patientInformationComponentValidator.validateAndSetState(state, setState, field, value);

    const patientDraft = state.patientDraft;
    const keys = field.split(".");
    const key = keys.pop();
    const lastObject = getLastObject(keys, patientDraft);
    lastObject[key] = value;

    setState({ ...state, patientDraft: { ...patientDraft } });
  }

  useEffect(() => {
    const subscriptions: Subscription[] = [
      onEmit<PatientModel>(patientsQuery.targetPatient$, targetPatient => {
        if (targetPatient?.id === Number(patientId)) {
          const cb = () => setState(state => ({
            ...state,
            patient: targetPatient,
            patientDraft: targetPatient,
            isLoading: false,
          }));

          employeeService.getActiveCoachesAndProviders(targetPatient.locationId).subscribe(cb, cb);
        }
      }),
      onEmit<EmployeeShortModel[]>(employeesQuery.couchesAndProviders$, employees => {
        setState(state => ({
          ...state, employees
        }));
      }),
    ];

    return () => {
      subscriptions.map((it) => it.unsubscribe());
    };
  }, [patientId]);

  return [
    state,
    handleChanges,
    handleEditToggle,
    handleReset,
    handleSave,
    handleEmployeeSelect
  ];
}
