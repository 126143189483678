import { Box, Container, Paper } from '@material-ui/core';
import React from 'react';
import { TitlesService } from '../../constants/title.constants';

export const PrivacyPolicyPage: React.FC = () => {
    return (
        <>
            <title>{TitlesService.privacyPolicyTitle}</title>
            {
                <Container maxWidth='lg'>
                    <Box py={2}>
                        <Paper elevation={3}>
                            <PrivacyPolicyComponent/>
                        </Paper>
                    </Box>
                </Container>
            }
        </>
    );
}

const PrivacyPolicyComponent: React.FC = () => {
    return (
        <Box px={10} py={7} className="agreement">
            <Box className="text-light-medium">
                <Box  mt={2} textAlign="center">
                    <span className="agreement-title">Wild Health - Privacy Policy</span>
                </Box>

                <Box mt={2}>
                    <p>
                        Wild Health, its providers, agents, and subsidiaries provides web, mobile, and traditional health, wellness, and other services designed to allow you to manage your related health information in one place. 
                    </p>
                </Box>

                <Box mt={2}>
                    <p>
                        Wild Health values your trust, and protecting your information is a high priority. Wild Health is committed to your privacy. This Privacy Policy explains what information Wild Health and its affiliates (collectively, “Wild Health” or “We”) collect, how information is collected, how we use that information, what information is shared with whom, and how Wild Health protects your information when you use our products and services (“Services”). 
                    </p>
                </Box>

                <Box mt={2}>
                    <p className="text-bold">1. Information We Collect</p>

                    <p>
                        The information Wild Health collects is highly variable based on the type of Services you use and the manner in which you access them. Depending on these factors, Wild Health may collect the following information about you: 
                    </p>

                    <Box className="paragraphs-section">
                        <Box display='flex'>
                            <Box mr={2}>•</Box>
                            <span>
                                Personal Information. This is information that directly or indirectly identifies you, such as your name, email address, or other identifying information about you. 
                            </span>
                        </Box>
                        <Box display='flex' mt={2}>
                            <Box mr={2}>•</Box>
                            <span>
                                Protected Health Information (“PHI”). PHI is your Personal Information that is protected under the U.S. Health Insurance Portability and Accountability Act of 1996 (“HIPAA”). HIPAA and other laws closely regulate how Wild Health may handle, protect, and share your PHI. 
                            </span>
                        </Box>
                        <Box display='flex' mt={2}>
                            <Box mr={2}>•</Box>
                            <span>
                                Other Information. Other Information is information that, by itself, does not individually identify you such as browser type, operating system, technical data, and usage. We may link together different types of Other Information or link Other Information to Personal Information.
                            </span>
                        </Box>
                    </Box>
                </Box>

                <Box mt={2}>
                    <p className="text-bold">2. How We Collect Information</p>

                    <p>
                        We collect information in the following ways:
                    </p>

                    <Box className="paragraphs-section">
                        <Box display='flex'>
                            <Box mr={2}>•</Box>
                            <Box>
                                <span className="text-bold">
                                    Information You Give Us Upon Registration.
                                </span>
                                {' '}Many of our Services require you to sign up for an account. When you do, we’ll ask for Personal Information like your name, email address, telephone number or credit card.
                            </Box>
                        </Box>
                        <Box display='flex'>
                            <Box mr={2}>•</Box>
                            <Box>
                                <span className="text-bold">
                                    Information Provided to Us by Your Healthcare Provider or Laboratory.
                                </span>
                                {' '}When you participate in the Clarity Platform (see the Clarity Terms of Use and WH ToS for more information on the Clarity Platform) and are asked to obtain your health screening, we may receive information such as your biometric data and blood and genetic test data and results. 
                            </Box>
                        </Box>
                        <Box display='flex'>
                            <Box mr={2}>•</Box>
                            <Box>
                                <span className="text-bold">
                                    Information from Other Sources.
                                </span>
                                {' '}We may obtain information about you from affiliates, partners, and other third parties. This information may be used to provide Services to you and to provide analysis about you. We may combine the information we obtain from third parties with information that we have collected about you.
                            </Box>
                        </Box>
                        <Box display='flex'>
                            <Box mr={2}>•</Box>
                            <Box>
                                <span className="text-bold">
                                    Information We Get from Your Use of Services.
                                </span>
                                {' '}We may collect information about the Services that you use and how you use them. This information includes:
                            </Box>
                        </Box>
                        <Box ml="20px">
                            <Box display='flex'>
                                <Box mr={2}>•</Box>
                                <Box>
                                    <span className="slogan">
                                        Information You Provide.
                                    </span>
                                    {' '}We may collect information provided by you while using our Services.
                                </Box>
                            </Box>
                            <Box display='flex'>
                                <Box mr={2}>•</Box>
                                <Box>
                                    <span className="slogan">
                                        Computer, Tablet, or Mobile Telephone information.
                                    </span>
                                    {' '}We may collect device-specific information such as your hardware model, operating system version, unique device identifiers, device sensors and mobile network information including phone number. Wild Health may associate your device identifiers or phone number with your account. We will comply with the usage/license restrictions and requirements applicable to the device from which the information comes.
                                </Box>
                            </Box>
                            <Box display='flex'>
                                <Box mr={2}>•</Box>
                                <Box>
                                    <span className="slogan">
                                        Information from Wearables:
                                    </span>
                                    {' '}When you connect your wearable fitness tracker, heart rate monitor, pedometer or other wearable technology (“Wearable”) with the Services, we may collect information about your steps, fitness activities, exercise frequency, sleep, and information about nutrition, such as calorie intake, nutritional statistics, blood pressure, and other biometric data. You may disable this integration through your device.
                                </Box>
                            </Box>
                            <Box display='flex'>
                                <Box mr={2}>•</Box>
                                <Box>
                                    <span className="slogan">
                                        Log Information.
                                    </span>
                                    {' '}When you use our Services or view content provided by Wild Health, we may automatically collect and store certain information in server logs. This may include:  details of how you used our service; Internet protocol address; device event information such as crashes, system activity, hardware settings; browser type, browser language, the date and time of your request and referral URL; and cookies that may uniquely identify your browser or your account.
                                </Box>
                            </Box>
                            <Box display='flex'>
                                <Box mr={2}>•</Box>
                                <Box>
                                    <span className="slogan">
                                        Local Storage.
                                    </span>
                                    {' '}We may collect and store information (including Personal Information) locally on your device using mechanisms such as browser web storage (including HTML 5) and application data caches.
                                </Box>
                            </Box>
                            <Box display='flex'>
                                <Box mr={2}>•</Box>
                                <Box>
                                    <span className="slogan">
                                        Cookies and Anonymous Identifiers.
                                    </span>
                                    {' '}We use various technologies to collect and store information when you visit a Wild Health service, and this may include sending one or more cookies or anonymous identifiers to your device, pursuant to our Cookie Policy. We also use cookies and anonymous identifiers when you interact with Services we offer to our partners, such as Wild Health features that may appear on other sites. See the section “Transparency, Your Privacy Rights, Choices, and Account Termination” below for information about your opt-out choices.
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Box mt={2}>
                    <p className="text-bold">3. How We Use Information We Collect</p>

                    <Box className="paragraphs-section">
                        <Box display='flex'>
                            <Box mr={2}>•</Box>
                            <Box>
                                <span className="text-bold">
                                    To Provide Services To You.
                                </span>
                                {' '}We use the information that we collect about you to provide, maintain, protect and improve the Services that Wild Health provides to you.
                            </Box>
                        </Box>
                        <Box display='flex'>
                            <Box mr={2}>•</Box>
                            <Box>
                                <span className="text-bold">
                                    Analytics.
                                </span>
                                {' '}We use analytics tools and other third-party technologies, such as Google Analytics, to collect non-Personal Information in the form of various usage and user metrics when you use our site. These tools and technologies collect and analyze certain types of information, including cookies, IP addresses, device and software identifiers, referring and exit URLs, onsite behavior and usage information, feature use metrics and statistics, usage and demographics, and other similar information. You can deactivate Google Analytics using a browser add-on if you do not wish the website analysis to take place. You can download the add-on here: tools.google.com/dlpage/gaoptout.
                            </Box>
                        </Box>
                        <Box display='flex'>
                            <Box mr={2}>•</Box>
                            <Box>
                                <span className="text-bold">
                                    To Improve Our Services.
                                </span>
                                {' '}We use de-identified, anonymous information that we collect about you for statistical and analysis purposes to improve our products and services, and to provide statistical analysis.
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Box mt={2}>
                    <p className="text-bold">4. Legal Grounds for Processing Your Personal Data</p>

                    <p>
                        We rely on the following legal grounds to process your Personal Information:
                    </p>

                    <Box className="paragraphs-section">
                        <Box display='flex'>
                            <Box mr={2}>•</Box>
                            <Box>
                                <span className="text-bold">
                                    Consent.
                                </span>
                                {' '}We may use your Personal Information as described in this Privacy Policy subject to your consent.  To withdraw your consent for any uses of Personal Information described above, please review the section titled “Privacy Rights, Choices, and Account Termination” or contact us at: <a href="mailto: Admin@wildhealth.com">Admin@WildHealth.com</a>.
                            </Box>
                        </Box>
                        <Box display='flex'>
                            <Box mr={2}>•</Box>
                            <Box>
                                <span className="text-bold">
                                    Performance of Services.
                                </span>
                                {' '}We may need to collect and use your Personal Information to enter into and perform under an agreement with you or your health care provider.
                            </Box>
                        </Box>
                        <Box display='flex'>
                            <Box mr={2}>•</Box>
                            <Box>
                                <span className="text-bold">
                                    Legitimate Interests.
                                </span>
                                {' '}We may use your Personal Information for our legitimate interests, including but not limited to marketing, to provide our Services and to improve our Services and the content on our sites.
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Box mt={2}>
                    <p className="text-bold">5. What Information We Share</p>

                    <p>
                        We do not sell your Personal Information to third parties and the information disclosed is the minimum amount necessary to fulfill the legitimate business purpose. We do not share Personal Information with companies, organizations and individuals outside of Wild Health except in the following circumstances: 
                    </p>

                    <Box className="paragraphs-section">
                        <Box display='flex'>
                            <Box mr={2}>•</Box>
                            <Box>
                                <span className="text-bold">
                                    With Your Consent.
                                </span>
                                {' '}We may share your Personal Information with companies, organizations or individuals outside of Wild Health when we have your consent to do so.
                            </Box>
                        </Box>
                        <Box display='flex'>
                            <Box mr={2}>•</Box>
                            <Box>
                                <span className="text-bold">
                                    Healthcare Providers.
                                </span>
                                {' '}We may share information with your healthcare providers and any clinics or organized healthcare organizations with whom they are associated, provided such sharing is allowable under HIPAA.
                            </Box>
                        </Box>
                        <Box display='flex'>
                            <Box mr={2}>•</Box>
                            <Box>
                                <span className="text-bold">
                                    Third-Party Service Providers.
                                </span>
                                {' '}We may disclose your PHI to our business associates who perform various functions on our behalf, but Wild Health requires these third parties to agree in writing to safeguard your PHI appropriately and in accordance with the law. Wild Health does not sell or rent your PHI to third parties. Wild Health does not use your PHI to market, sell, or otherwise promote goods or services that are not health-related benefits provided by your provider or other third-party entities.
                            </Box>
                        </Box>
                        <Box display='flex'>
                            <Box mr={2}>•</Box>
                            <Box>
                                <span className="text-bold">
                                    For External Services.
                                </span>
                                {' '}We may, from time to time, outsource some or all of the operations of our business to third-party service providers. In such cases, it may be necessary for us to disclose your information to those service providers. In some cases, the service providers may collect information directly from you on our behalf. We restrict how such service providers may access, use and disclose your information. We employ other companies and individuals to perform functions on our behalf. These agents may have access to your information as needed to perform their functions, but they are not permitted to use it for other purposes. 
                            </Box>
                        </Box>
                        <Box display='flex'>
                            <Box mr={2}>•</Box>
                            <Box>
                                <span className="text-bold">
                                    For Legal Reasons.
                                </span>
                                {' '}We may disclose any information about you when, in our opinion, such disclosure is necessary to prevent fraud or to comply with any statute, law, rule or regulation of any governmental authority or any order of any court of competent jurisdiction. We will share Personal Information with companies, organizations or individuals outside of Wild Health if we have a good-faith belief that access, use, preservation or disclosure of the information is reasonably necessary to: enforce applicable terms of use/service, including investigation of potential violations; detect, prevent, or otherwise address fraud, security or technical issues; and protect against harm to the rights, property or safety of Wild Health, our users or the public as required or permitted by law.
                            </Box>
                        </Box>
                        <Box display='flex'>
                            <Box mr={2}>•</Box>
                            <Box>
                                <span className="text-bold">
                                    In a Transaction.
                                </span>
                                {' '}As we continue to develop our business, we might sell, buy or merge with companies, subsidiaries, or business units. In such transactions, data generally is one of the transferred business assets but remains subject to the promises made in any pre-existing privacy statement/policy (unless, of course, the person consents otherwise). Also, in the unlikely event that Wild Health or all of its assets is acquired, your information may be one of the transferred assets. 
                            </Box>
                        </Box>
                        <Box display='flex'>
                            <Box mr={2}>•</Box>
                            <Box>
                                <span className="text-bold">
                                    After Being Fully Deidentified, Incapable of Reidentification.
                                </span>
                                {' '}We may share aggregated, de-identified information publicly and with our partners to evaluate the effectiveness, value, and analytic trends of the Services.
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Box mt={2}>
                    <p className="text-bold">6. How We Protect Your Information</p>

                    <p>
                        We take steps to secure Personal Information through administrative, technical, and physical safeguards designed to protect against the risk of accidental, unlawful, or unauthorized destruction, loss, alteration, access, disclosure, or use. Unfortunately, we cannot guarantee the security of information transmitted through the Internet, and where we have given you (or where you have chosen) a password, you are responsible for keeping this password confidential.  
                    </p>
                </Box>

                <Box mt={2}>
                    <p className="text-bold">7. Transparency, Privacy Rights and Choices, and Account Termination</p>

                    <p>
                        Our goal is to be clear about what information we collect, so that you can make meaningful choices about how it is used. Subject to your specific use of the Services, your privacy rights include: 
                    </p>

                    <Box className="paragraphs-section">
                        <Box display='flex'>
                            <Box mr={2}>•</Box>
                            <Box>
                                <span className="text-bold">
                                    Transparency and the right to information.
                                </span>
                                {' '} Through this policy we explain how we use and share your information. However, if you have questions or need additional information you can contact us any time at: <a href="mailto: Admin@wildhealth.com">Admin@WildHealth.com</a>.
                            </Box>
                        </Box>
                        <Box display='flex'>
                            <Box mr={2}>•</Box>
                            <Box>
                                <span className="text-bold">
                                    Right of access, restriction of processing, erasure.
                                </span>
                                {' '}You may contact us to request information about the Personal Information we have collected from you, or to request that your Personal Information be deleted.  We will do our best to honor your requests, subject to any legal, ethical and contractual obligations. To make a request or to ask us a question about our data practices, please contact us via email at: <a href="mailto: Admin@wildhealth.com">Admin@WildHealth.com</a>. When requesting access to your Personal Information, please note that we may request specific information from you to enable us to confirm your identity and right to access, as well as to search for and provide you with the Personal Information that we hold about you. You may designate an authorized agent to request information on your behalf, provided that the authorized agent complies with our verification procedures to ensure your permission has been obtained. 
                            </Box>
                        </Box>
                        <Box display='flex'>
                            <Box mr={2}>•</Box>
                            <Box>
                                <span className="text-bold">
                                    Your right to access or delete the Personal Information we hold about you is not absolute.
                                </span>
                                {' '}There are instances where applicable law or regulatory requirements allow or require us to refrain from taking certain actions with respect to some or all of the Personal Information that we hold about you. In addition, some or all of the Personal Information may have been destroyed, erased or made anonymous. In the event we cannot provide you with access to your Personal Information, or we are unable to delete it, we will inform you of the reasons why, subject to any legal or regulatory restrictions.
                            </Box>
                        </Box>
                        <Box display='flex'>
                            <Box mr={2}>•</Box>
                            <Box>
                                <span className="text-bold">
                                    Right to correct your information.
                                </span>
                                {' '}We endeavor to ensure that Personal Information in our possession is accurate, current and complete. If an individual believes that the Personal Information about him or her is incorrect, incomplete or outdated, he or she may request the revision or correction of that information. We reserve the right not to change any Personal Information we consider accurate.  If it is determined that Personal Information is inaccurate, incomplete or outdated, we will use reasonable efforts to revise it and, if necessary, use reasonable efforts to inform agents, service providers or other third parties, which were provided with inaccurate information, so records in their possession may be corrected or updated. 
                            </Box>
                        </Box>
                        <Box display='flex'>
                            <Box mr={2}>•</Box>
                            <Box>
                                <span className="text-bold">
                                    Right to withdraw your consent at any time.
                                </span>
                                {' '}When we process your Personal Information based on your consent, you have the right to withdraw that consent at any time.  This includes any HIPAA authorizations, consents regarding your participation in certain Services, email/text/other communication consents, or any other consent that you have provided to Wild Health. You may withdraw your consent by contacting us at: <a href="mailto: Admin@wildhealth.com">Admin@WildHealth.com</a>. Withdrawing consents may significantly limit your ability to use the Services.
                            </Box>
                        </Box>
                        <Box display='flex'>
                            <Box mr={2}>•</Box>
                            <Box>
                                <span className="text-bold">
                                    Right to object or opt-out at any time.
                                </span>
                                {' '}You have the right to object at any time to receiving marketing or promotional materials from us by either following the opt-out instructions in commercial e-mails or by contacting us, as well as the right to object to any processing of your Personal Information or to participating in any program. We will consider any request you make to us, and provide a reply in a timely manner, according to our legal, ethical and contractual obligations.  Some non-marketing communications are not subject to a general right to opt-out, such as communications about transactions and disclosures to comply with legal or ethical requirements.  
                            </Box>
                        </Box>
                        <Box display='flex'>
                            <Box mr={2}>•</Box>
                            <Box>
                                <span className="text-bold">
                                    Right to data portability.
                                </span>
                                {' '}You have the right to data portability of your own personal data by contacting us. There are instances where applicable law or regulatory requirements allow or require us to refrain from transmitting your Personal Information to certain recipients or may require additional documentation before transmitting your information. In addition, some or all of the Personal Information may have been destroyed, erased or made anonymous. In the event we cannot fulfill your data portability request or require additional information to do so, we will contact you. 
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Box mt={2}>
                    <p className="text-bold">8. Terminating Your Account </p>

                    <p>
                        You may terminate your account with us at any time through the account settings or emailing <a href="mailto: Admin@wildhealth.com">Admin@WildHealth.com</a>. Terminating your account will revoke any applicable consents or opt-ins but will be subject to any data retention requirements as described below.
                    </p>
                </Box>

                <Box mt={2}>
                    <p className="text-bold">9. Retention of Collected Information </p>

                    <p>
                        Except as otherwise permitted or required by applicable law or regulatory requirements, we will retain your Personal Information only for as long as we believe it is necessary to fulfill the purposes for which the Personal Information was collected (including for the purpose of meeting any legal, accounting or other reporting requirements or obligations). You may request that we delete the Personal Information about you that we hold, as described above. 
                    </p>
                </Box>

                <Box mt={2}>
                    <p className="text-bold">10. Accessing and Updating Your Personal Information </p>

                    <p>
                        Whenever you use our Services, we aim to provide you with access to your Personal Information. If that information is wrong, we strive to give you ways to update it quickly or to delete it – unless we have to keep that information for legitimate business or legal purposes. When updating your Personal Information, we may ask you to verify your identity before we can act on your request. Where we can provide information access and correction, we will do so for free, except where it would require a disproportionate effort. We aim to maintain our Services in a manner that protects information from accidental or malicious destruction. Because of this, after you delete information from our Services, we may not immediately delete residual copies from our active servers and may not remove information from our backup systems. Access, correction, or deletion requests can be made by contacting <a href="mailto: Admin@wildhealth.com">Admin@WildHealth.com</a>. 
                    </p>
                </Box>

                <Box mt={2}>
                    <p className="text-bold">11. Information on Children </p>

                    <p>
                        The Services are not directed to or intended for use by minors. In compliance with laws, we will not intentionally collect any Personal Information from children under the age of 18. If we learn we have collected or received Personal Information from a child under 18 without verification of parental consent, we will delete it. If you are the parent or guardian of a child under 18 years of age whom you believe might have provided us with their Personal Information, please contact us to request that it be deleted. 
                    </p>
                </Box>

                <Box mt={2}>
                    <p className="text-bold">12. When This Privacy Policy Applies </p>

                    <p>
                        Our Privacy Policy applies to all of the Services offered by Wild Health Inc. and its affiliates, including Services offered on other sites, but excludes Services that have separate privacy policies that do not incorporate this Privacy Policy, and Services that you may receive as a result of your participation in a program that expressly supersedes this Privacy Policy. 
                    </p>
                    <Box mt={2}>
                        <p>
                            Our Privacy Policy does not apply to Services offered by other companies or individuals, including products or sites that may be displayed to you in search results, sites that may include Services, or other sites linked from our Services. Our Privacy Policy does not cover the information practices of other companies and organizations who advertise our Services, and who may use cookies, pixel tags and other technologies to serve and offer relevant ads. 
                        </p>
                    </Box>
                </Box>

                <Box mt={2}>
                    <p className="text-bold">13. Compliance and Cooperation with Regulatory Authorities </p>

                    <p>
                        We regularly review our compliance with our Privacy Policy. When we receive a formal written complaint, we will contact the person who made the complaint to follow up. We work with the appropriate regulatory authorities, including local data protection authorities, to resolve any complaints regarding the transfer of Personal Information that we cannot resolve with our users directly. 
                    </p>
                </Box>

                <Box mt={2}>
                    <p className="text-bold">14. Jurisdictions Outside of the U.S. </p>

                    <p>
                        You may choose to use our services if you reside outside of the U.S.  We comply with any applicable laws and regulations based on the geographical location you select during your registration. Other jurisdictions, including but not limited to the European Economic Area (“EEA”), provide certain rights regarding Personal Information, including the ability to request erasure, to restrict processing of your Personal Information, or for a copy of your Personal Information. Nothing in this Privacy Policy limits or attempts to limit your rights under applicable laws, including your ability, depending on your country of residence, to file a complaint with your local Data Protection Authority. 
                    </p>

                    <Box mt={2}>
                        <p>
                            It is necessary for us to perform our obligations in accordance with any contract or engagement that we may have with you. It is in our legitimate interest or a third party's legitimate interest to use Personal Information in such a way to ensure that we provide the Services in the best way that we can. 
                        </p>
                    </Box>

                    <Box mt={2}>
                        <p>
                            Wild Health is located in the United States. When you submit Personal Information to us, or when others provide Personal Information to us, we will receive it and process it in the United States. 
                        </p>
                    </Box>

                    <Box mt={2}>
                        <p>
                            Without limiting any other rights contained in this Privacy Policy, privacy rights under European Data Protection law, which may be exercised by all users, include: 
                        </p>
                    </Box>

                    <Box className="paragraphs-section">
                        <Box display='flex' mt={2}>
                            <Box mr={2}>•</Box>
                            <Box>
                                <span className="text-bold">
                                    Transparency and the right to information.
                                </span>
                                {' '}Through this policy we explain how we use and share your Personal Information. However, if you have questions or need additional information you can contact us any time.
                            </Box>
                        </Box>

                        <Box display='flex' mt={2}>
                            <Box mr={2}>•</Box>
                            <Box>
                                <span className="text-bold">
                                    Right of access, restriction of processing, erasure.
                                </span>
                                {' '}You may contact us to request information about the Personal Information we have collected from you and to request the correction, modification or deletion of such Personal Information, which requests we will do our best to honor subject to any legal, ethical and contractual obligations. To make a request or to ask us a question about our data practices, please contact us via email at <a href="mailto: Admin@wildhealth.com">Admin@WildHealth.com</a>.
                            </Box>
                        </Box>

                        <Box mt={2} display='flex'>
                            <Box mr={2}>•</Box>
                            <Box>
                                <span className="text-bold">
                                    Right to withdraw your consent at any time.
                                </span>
                                {' '}When we process your Personal Information based on your consent, you have the right to withdraw your consent at any time.
                            </Box>
                        </Box>

                        <Box mt={2} display='flex'>
                            <Box mr={2}>•</Box>
                            <Box>
                                <span className="text-bold">
                                    Right to object at any time.
                                </span>
                                {' '} You have the right to object at any time to receiving marketing or promotional materials from us by either following the opt-out instructions in commercial e-mails or by contacting us, as well as the right to object to any processing of your Personal Information based on your specific situation. In the latter case, we will assess your request and provide a reply in a timely manner, according to our legal, ethical and contractual obligations. Some non-marketing communications are not subject to a general opt-out, such as communications about transactions and disclosures to comply with legal or ethical requirements. 
                            </Box>
                        </Box>

                        <Box mt={2} display='flex'>
                            <Box mr={2}>•</Box>
                            <Box>
                                <span className="text-bold">
                                    Right to lodge a complaint. 
                                </span>
                                {' '}You have the right to lodge a complaint with the competent Personal Data supervisory authority, if you believe that the processing of your Personal Information does not comply with legal requirements.
                            </Box>
                        </Box>
                    </Box>
                    <Box mt={2}>
                        <p>
                            If you reside or otherwise find yourself outside of the U.S., we are committed to facilitating the exercise of your rights granted by the European Data Protection law and/or the laws of your country.  If you have any inquiries or complaints about our handling of your Personal Information or about our privacy practices generally, please contact us (including our Data Protection Officer) at:  <a href="mailto: Admin@wildhealth.com">Admin@WildHealth.com</a>. We will respond to your inquiry promptly.
                        </p>
                    </Box>

                    <Box mt={2}>
                        <p>
                            We may require you to provide us with sufficient information such as your name, address, or any Other Information necessary for us to verify your identity. We reserve the right to request additional information from you, such as a photocopy of your passport or other identification card in order to verify your identity. 
                        </p>
                    </Box>
                </Box>

                <Box mt={2}>
                    <p className="text-bold">15. Changes to this Privacy Policy</p>

                    <p>
                        This Privacy Policy is not intended to and does not create any contractual or other legal rights in or on behalf of any party. If we change this Privacy Policy, we will post any privacy policy changes on this page and, if the changes are material, we will provide a more prominent notice by sending you an email and/or posting a notice in the Services. Wild Health reserves the right to modify this Privacy Policy at any time, so please review it frequently. 
                    </p>

                    <Box mt={2}>
                        <p>
                            Last modified: March 12, 2021
                        </p>
                    </Box>
                </Box>

            </Box>
        </Box>
    );
}