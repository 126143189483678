import {IcdCode} from "../../../notes/models/notes.models";
import {Observable} from "rxjs";
import React from "react";
import {useStyles} from "../../../notes/components/diagnosisComponent/diagnosisComponent.style";
import commonUseStyles from "../../../common/styles/common.styles";
import {Box, Checkbox, Grid, IconButton, TextField} from "@material-ui/core";
import {isFocusInCurrentTarget} from "../../../common/helpers/is-focus-in-current-target";
import {Autocomplete} from "@material-ui/lab";
import {Add, Delete, Edit} from "@material-ui/icons";
import {useFacade} from "./orderDiagnosisComponent.hooks";
import { ReactComponent as StartOutlined } from '@img/icons/StarOutlined.svg'
import { ReactComponent as StarChecked } from '@img/icons/starChecked.svg'
import {colors} from "../../../common/constants/colors";

export interface OrderDiagnosisComponentProps {
    title?: string;
    addText?: string;
    subTitle?: string;
    grid?: 6 | 12;
    selectedDiagnosis: IcdCode[];
    primaryDiagnosisId: number | null;
    getAutoComplete: (searchQuery: string) => Observable<IcdCode[]>;
    handleChanges: (key: string, values: IcdCode[]) => void;
    handleTextInfoChange: (values: string, id: number) => void;
    handlePrimaryChange: (id: number) => void;
}

export const OrderDiagnosisComponent: React.FC<OrderDiagnosisComponentProps> = (props: OrderDiagnosisComponentProps) => {
    const {
        addText = null,
        grid = 12,
        selectedDiagnosis,
        primaryDiagnosisId,
        getAutoComplete,
        handleChanges,
        handleTextInfoChange,
        handlePrimaryChange
    } = props;

    const [
        state,
        handleDeleteItem,
        handleEnableAdd,
        handleAddChanges,
        handleAddSubmit,
        handleEditChanges,
        handleEnableEdit,
        handleSubmitEdit,
    ] = useFacade(selectedDiagnosis, handleChanges, getAutoComplete);

    const classes = useStyles();
    const commonClasses = commonUseStyles();
    const starIcon = <StartOutlined></StartOutlined>;
    const statIconChecked = <StarChecked style={{ fill: colors.main }}></StarChecked>;

    const renderPrimaryCheckBox = (diagnosis: IcdCode) => {
        return (
            <Box display="flex" alignItems="center" ml={3}>
                <Box>
                    <Checkbox
                        icon={starIcon}
                        color="primary"
                        checkedIcon={statIconChecked}
                        checked={diagnosis.id === primaryDiagnosisId}
                        onChange={() => handlePrimaryChange(diagnosis.id)}
                    />
                </Box>
            </Box>
        )
    }
    return (
        <Box>
            <Box>
                <Grid container spacing={2}>
                    <Grid item md={grid} sm={12}>
                        {
                            selectedDiagnosis.map((item, i) => {
                                return (
                                    <Box display='flex' justifyContent="space-between" width={0.9}>
                                        {
                                            state.indexToEdit === i
                                                ? (

                                                    <Box mt={2.5} display='flex' alignItems='center'
                                                         justifyContent="space-between" width={1} onBlur={(e) => {
                                                        setTimeout(() => {
                                                            if (!isFocusInCurrentTarget(e)) {
                                                                handleSubmitEdit(null)
                                                            }
                                                        }, 250)
                                                    }}>
                                                        <Box width={0.9}>
                                                            <Autocomplete
                                                                id={`order-diagnosis-item-list-edit`}
                                                                size="small"
                                                                freeSolo
                                                                disableClearable
                                                                fullWidth
                                                                options={state.diagnosis}
                                                                value={state.valueToEdit}
                                                                getOptionLabel={option => option.code + ' ' + option.description}
                                                                onChange={(e, value: IcdCode) => handleSubmitEdit(value)}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        fullWidth
                                                                        variant='outlined'
                                                                        value={state.valueToEdit}
                                                                        autoFocus
                                                                        InputProps={{
                                                                            ...params.InputProps,
                                                                            className: 'input',
                                                                            type: 'search'
                                                                        }}
                                                                        onChange={(e) => handleEditChanges(e.target.value)}
                                                                        onBlur={() => handleAddSubmit(null)}
                                                                    />
                                                                )}
                                                            />
                                                        </Box>
                                                        <Box ml={2}>
                                                            <IconButton className={classes.deleteIcon}
                                                                        onClick={() => handleDeleteItem(i)}>
                                                                <Delete/>
                                                            </IconButton>
                                                        </Box>
                                                    </Box>

                                                )
                                                :
                                                <Box mt={2.5} position='relative' width={1}>
                                                    <Box id={`pmh=-${i}`} className={classes.item}>
                                                        <Box>
                                                            <span
                                                                className={commonClasses.breakWord}>{item.code + ' ' + item.description}</span>
                                                        </Box>
                                                        <Box className={classes.editIcon}
                                                             onClick={() => handleEnableEdit(i)} ml={2}>
                                                            <Edit/>
                                                        </Box>
                                                    </Box>
                                                    <Box className={classes.connectedLine}></Box>
                                                    <Box mt={1} ml={4}>
                                                        <TextField
                                                            fullWidth
                                                            maxRows={3}
                                                            multiline
                                                            type='text'
                                                            size="small"
                                                            label=''
                                                            variant="outlined"
                                                            placeholder={`${item.description} Diagnosis Details Here`}
                                                            value={item.additionalInformation}
                                                            InputProps={{className: 'input'}}
                                                            onChange={(e) => handleTextInfoChange(e.target.value, item.id)}
                                                        />
                                                    </Box>
                                                </Box>
                                        }
                                        <Box mt={3} width={0.1}>
                                            {
                                                renderPrimaryCheckBox(item)
                                            }
                                        </Box>
                                    </Box>

                                )
                            })
                        }

                        {
                            state.enableAdd
                                ?
                                <Box mt={2.5}>
                                    <Autocomplete
                                        id={`order-diagnosis-item-list-add`}
                                        freeSolo
                                        loading={state.isLoading}
                                        size="small"
                                        disableClearable
                                        options={state.diagnosis}
                                        getOptionLabel={option => option.code + ' ' + option.description}
                                        onChange={(e, value: IcdCode) => handleAddSubmit(value)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={addText}
                                                variant='outlined'
                                                autoFocus
                                                InputProps={{
                                                    ...params.InputProps,
                                                    className: 'input',
                                                    type: 'search'
                                                }}
                                                onChange={(e) => handleAddChanges(e.target.value)}
                                                onBlur={() => handleAddSubmit(null)}
                                            />
                                        )}
                                    />
                                </Box>
                                :
                                <Box mt={2.5} display='flex' alignItems='stretch'>
                                    <Box onClick={() => handleEnableAdd()}>
                                        <Add className={classes.addIcon} />
                                    </Box>
                                    <Box className={classes.addText} ml={2}>
                                        <span>{addText ?? 'Add Diagnosis'}</span>
                                    </Box>
                                </Box>
                        }
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}