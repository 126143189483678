import {
  Box,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemText,
  Popover,
  ListItemIcon,
  IconButton,
} from "@material-ui/core";
import React from "react";
import { useFacade } from "./mobileNavigationComponent.hooks";
import ListIcon from "@material-ui/icons/List";
import CloseIcon from "@material-ui/icons/Close";
import { MenuItemTypes } from "../../models/menu.models";
import clsx from "clsx";
import { useStyles } from "./mobileNavigationComponent.styles";
import SignOutIcon from "../iconComponent/SignOutIcon";
import { ReferralNavigationComponent } from "./ReferralNavigationComponent";
import { ProtectedElement } from "../../modules/common/components/ProtectedElement";
import { UserType } from "../../modules/auth/models/auth.enums";
import { SearchPatientComponent } from "../../modules/patients/components/SearchPatientComponent/SearchPatientComponent";
import { NotificationsButtonComponent } from "../../modules/notifications/components/notificationComponent/NotificationsButtonComponent";

const popoverProps: any = {
  id: "mobileMenuPopover",
  anchorPosition: {
    top: 0,
    left: 0,
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "right",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
};

interface MobileMenuComponentProps {
  selectedMenuItem: MenuItemTypes;
}

export const MobileNavigationComponent: React.FC<MobileMenuComponentProps> = (
  props: MobileMenuComponentProps
) => {
  const { selectedMenuItem } = props;

  const classes = useStyles();

  const [
    { anchorEl, isOpen, isUsersLoading, users, selectedUser },
    handleToggle,
    renderMenuItems,
    handleLogOut,
    handleUserSelect,
    handleUserSearch
  ] = useFacade(selectedMenuItem, classes);

  return (
    <>
      <Box position="absolute" top="4px" right="4px" display="flex" alignItems="center">
        <ProtectedElement
          element={<ReferralNavigationComponent />}
          userType={UserType.Patient}
          permissions={[]}
        />
        <ProtectedElement
          element={<NotificationsButtonComponent />}
          permissions={[]}
        />
        <IconButton
          id="mobile-menu-toggle-btn"
          aria-describedby={popoverProps.id}
          onClick={(event) => handleToggle(event)}
          className={clsx(classes.menuIcon)}
        >
          <ListIcon />
        </IconButton>
      </Box>

      <Popover
        id={`mobile-menu-toggle-${popoverProps.id}`}
        anchorEl={anchorEl}
        onClose={(event) => handleToggle(event)}
        open={isOpen}
        anchorOrigin={popoverProps.anchorOrigin}
        transformOrigin={popoverProps.transformOrigin}
        classes={classes}
      >
        <Box position="relative" display="flex" justifyContent="flex-end" pt={2} px={2}>
          <IconButton
            id="mobile-menu-close-btn"
            aria-describedby={popoverProps.id}
            onClick={(event) => handleToggle(event)}
            color="inherit"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box>
          <ProtectedElement
            element={
              <Box px={1.5} py={0.5}>
                <SearchPatientComponent
                  placeholder={'Search patient'}
                  selectedUser={selectedUser}
                  users={users}
                  handleChange={(user) => handleUserSelect(user)}
                  handleInputChange={handleUserSearch}
                  isLoading={isUsersLoading}
                />
              </Box>
            }
            permissions={[]}
          />
          <Grid
            className={clsx(classes.content, "flat-scroll")}
            container
            direction="column"
            justify="space-between"
            alignItems="stretch"
          >
            <Grid item>
              <List className={classes.menuList}>{renderMenuItems()}</List>
            </Grid>
          </Grid>
          <Divider />
          <Box px={1.5}>
            <List component="nav">
              <ListItem
                id={`nav-logout`}
                className={classes.menuItem}
                onClick={() => handleLogOut()}
                button
              >
                <ListItemIcon className={clsx(classes.menuIcon, classes.menuIconSize)}>
                  <SignOutIcon isSelected={false} />
                </ListItemIcon>
                <ListItemIcon className={clsx(classes.menuIcon, classes.menuIconSize)} />
                <ListItemText
                  className={classes.menuTitle}
                  primary={"Sign Out"}
                  hidden={false}
                />
              </ListItem>
            </List>
          </Box>
        </Box>
      </Popover>
    </>
  );
};
