import React from 'react';
import { AuthenticatedLayoutComponent } from '../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent';
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { TitlesService } from '../../../../constants/title.constants';
import { MenuItemTypes } from "../../../../models/menu.models";
import { ManageEmployersProfileComponent } from '../../components/manageEmployersProfileComponent/ManageEmployersProfileComponent';

export const ManageEmployersProfilePage: React.FC = () => {

    return (
        <AuthenticatedLayoutComponent
            title={TitlesService.employersProfilesTitle}
            pageName={PagesNamesService.employersProfiles}
            selectedMenuItem={MenuItemTypes.ManageEmployersProfiles}
            hideBackground
        >
            <ManageEmployersProfileComponent />
        </AuthenticatedLayoutComponent>
    )
}