import { SortingDirection } from "../../../common/sorting/models/sorting-destination";
import { DraftNoteModel, NoteModel, NotesType } from "../../models/notes.models";
import { IPaginationState } from "../../../common/pagination/models/pagination-state";
import { ISortingState } from "../../../common/sorting/models/sorting-state";
import { confirmService } from "../../../../services/confirm.service";
import { notesService } from "../../services/notes.service";
import { getAvailablePages } from "../../../common/pagination/helpers/get-evailable-pages";
import { Subscription } from "rxjs";
import { onEmit } from "../../../common/helpers/on-emit";
import { notesQuery } from "../../stores/notes";
import { useEffect, useState } from "react";
import { pageSizes } from "../../../common/pagination/models/page-sizes";
import { navigationService } from "../../../../services/navigation.service";
import { useHistory } from "react-router";


enum SortingSource {
    none,
    date = "date",
    noteType = "type"
}

interface ManageDraftNotesComponentState extends IPaginationState, ISortingState {
    isLoading: boolean;
    draftNotes: DraftNoteModel[];
}

export function useFacade(): [
    ManageDraftNotesComponentState,
    (source: string, direction: SortingDirection) => void,
    (value: any) => void,
    () => number[],
    (page: number) => void,
    (draftNote: DraftNoteModel) => void,
    (note: NoteModel) => void
] {

    const history = useHistory();
    const [state, setState] = useState({
        isLoading: true,
        draftNotes: [],
        sortingSource: SortingSource.date,
        sortingColumns: [
            { title: 'Note Type', source: SortingSource.noteType, direction: SortingDirection.Asc },
            { title: 'Start Date', source: SortingSource.date, direction: SortingDirection.Desc }
        ],
        totalCount: 0,
        selectedPage: 1,
        pageSize: pageSizes[0],
        targetNoteContent: null,
        targetNoteMedications: null,
        targetNoteSupplements: null,
        downloadingNoteId: null
    } as ManageDraftNotesComponentState);

    const handleDeleteDraftNote = (draftNote: DraftNoteModel) => {

        confirmService.confirm(`Are you sure you want to delete this Note?`)
            .subscribe(() => {
                notesService.deleteDraft(draftNote).subscribe(() => {
                });
            });
    }

    const setDirection = (source: string, direction: SortingDirection) => {
        const itemIndex = state.sortingColumns.findIndex(item => item.source === source);
        state.sortingColumns[itemIndex].direction = direction;
        setState(state => ({ ...state, columns: state.sortingColumns }));
    }

    const handlePageChange = (page: number) => {
        if (page === state.selectedPage) {
            return;
        }

        setState(state => ({
            ...state,
            isLoading: true,
            selectedPage: page
        }));

        const sortingColumn = state.sortingColumns.find(i => i.source === state.sortingSource);

        notesService.getAllDrafts(
            state.pageSize,
            (page - 1) * state.pageSize,
            state.sortingSource,
            sortingColumn.direction
        ).subscribe(() => setState(state => ({ ...state, isLoading: false })));
    }

    const handlePageSizeChange = (value: any) => {
        if (value === state.pageSize) {
            return;
        }

        setState(state => ({
            ...state,
            isLoading: true,
            pageSize: value,
            selectedPage: 1
        }));

        const sortingColumn = state.sortingColumns.find(i => i.source === state.sortingSource);

        notesService.getAllDrafts(value, 0,
            state.sortingSource,
            sortingColumn.direction).subscribe(() => setState(state => ({ ...state, isLoading: false })));
    }

    const getAllAvailablePages = () => {
        return getAvailablePages(state);
    }

    const handleSorting = (source: string, direction: SortingDirection) => {
        if (state.sortingSource === source) {
            direction = direction === SortingDirection.Asc
                ? SortingDirection.Desc
                : SortingDirection.Asc;
            setDirection(source, direction);
        }

        notesService.getAllDrafts(
            state.pageSize,
            0,
            source,
            direction
        ).subscribe(() => {
            setState(state => ({
                ...state,
                selectedPage: 1,
                sortingSource: source,
            }));
        });
    }

    const handleViewDetails = (note: NoteModel) => {

        switch (note.type) {
            case NotesType.Initial:
                return navigationService.toCreateInitialConsultNote(history, note.patientId, null, note, true);
            case NotesType.FollowUp:
                return navigationService.toCreateFollowUpNote(history, note.patientId, null, note, true);
            case NotesType.Blank:
            case NotesType.Internal:
                return navigationService.toCreateSimpleNote(history, note.type, note.patientId, null, note, true);
            case NotesType.Soap:
                return navigationService.toCreateSoapNote(history, note.patientId, null, note, true);
            case NotesType.HistoryAndPhysicalInitial:
                return navigationService.toCreateHistoryAndPhysicalNote(history, note.patientId, null, note, true);
            case NotesType.HistoryAndPhysicalFollowUp:
                return navigationService.toCreateHistoryAndPhysicalFollowUpNote(history, note.patientId, null, note, true);
            case NotesType.HistoryAndPhysicalGroupVisit:
                return navigationService.toCreateHistoryAndPhysicalGroupVisitNote(history, note.patientId, null, note, true);
            default:
                return;
        }
    }

    const useEffectCB = () => {

        const sortingColumn = state.sortingColumns.find(i => i.source === state.sortingSource);
        const subscriptions: Subscription[] = [
            onEmit<DraftNoteModel[]>(notesQuery.draftNotes$, draftNotes => {
                setState(state => ({ ...state, draftNotes }));
            }),
            onEmit<number>(notesQuery.totalCount$, totalCount => {
                setState(state => ({ ...state, totalCount }));
            })

        ];

        notesService.getAllDrafts(
            state.pageSize,
            0,
            state.sortingSource,
            sortingColumn.direction
        ).subscribe(() => setState(state => ({ ...state, isLoading: false })));

        return (() => {
            subscriptions.map(it => it.unsubscribe())
        })
    }

    useEffect(useEffectCB, []);

    return [
        state,
        handleSorting,
        handlePageSizeChange,
        getAllAvailablePages,
        handlePageChange,
        handleDeleteDraftNote,
        handleViewDetails
    ]

}