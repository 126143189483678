import {Box, CircularProgress, FormControlLabel, InputLabel} from "@material-ui/core";
import React from 'react';
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { useFacade } from "./insuranceVerificationComponent.hooks";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { WildHealthSwitcher } from "../../../common/components/WIldHealthSwitcher";
import { InsuranceCardComponent } from "../insuranceCard/InsuranceCardComponent";
import { InsuranceCardHistoryComponent } from "../insuranceCardHistory/InsuranceCardHistoryComponent";
import {CreateInsuranceModalComponent} from "../createInsuranceModalComponent/CreateInsuranceModalComponent";
import {ConfirmInsuranceDialog} from "../../../common/dialogs/confirmDialog/ConfirmInsuranceDialog";
import moment from 'moment';
import { toCurrentTimeZone } from "../../../timezones/helpers/timezone";
import { CoverageStatus } from "../../enums/coverageStatus";
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import { WildHealthTooltip } from "../../../common/components/WildHealthTooltip";

interface insuranceVerificationComponentProps {
    patientId: number;
}

export const InsuranceVerificationComponent: React.FC<insuranceVerificationComponentProps> = (props: insuranceVerificationComponentProps) => {
    const {
        patientId,
    } = props;
    const [
        {
            isInsuranceLoading,
            isSubscriptionLoading,
            isPayByCash,
            isInsuranceAvailable,
            insuranceUnavailibilityReason,
            isPayByCashLoading,
            isAddButtonLoading,
            patientInsurances,
            verificationHistory,
        },
        isEnabledInsuranceAdd,
        isEnabledInsuranceVerificationToggle,
        handleCashPay,
        handleAddInsurance,
        displayName,
        displayNameHistory,
        displayNumberCoverageHistory,
    ] = useFacade(patientId);

    if (isInsuranceLoading) {
        return (
            <Box p={5}>
                <WildHealthLinearProgress />
            </Box>
        );
    }

    const getInsuranceHistory = () => {
        return verificationHistory.filter(item => patientInsurances.find(el => (+el.id) === (+item.coverageId)))
    }

    const getIsInsuranceToggleDisabled = () => {
        const activeCoverages = patientInsurances.filter(c => c.status === CoverageStatus.Active)
        if (isEnabledInsuranceVerificationToggle && activeCoverages && activeCoverages.length) {
            const verificationHistories = verificationHistory.filter(item => activeCoverages.find(el => (+el.id) === (+item.coverageId)));
            if (verificationHistories && verificationHistories.length) {
                const lastVerification = verificationHistories[verificationHistories.length - 1];
                if (isPayByCash) {
                    if (lastVerification.isVerified && moment(moment(toCurrentTimeZone(lastVerification.runAt))).isAfter(moment(toCurrentTimeZone(new Date())).add('weeks', -2))) {
                        return false;
                    }
                    return true;
                } else {
                    return lastVerification.isVerified;
                }
            }
        }

        return isPayByCash;
    }

    return (
        <Box p={5} className="wh-tw-text-xs">
            <span className="wh-tw-text-xl wh-tw-font-medium"> Insurance Verification </span>
            <Box px={16} mt={6}>
                <Box py={2} display="flex" justifyContent="space-between" alignItems="center">
                    <Box>
                        <span className="wh-tw-text-base wh-tw-font-medium">Patients Insurance</span>
                    </Box>
                    <WildHealthTooltip title={isEnabledInsuranceAdd ? insuranceUnavailibilityReason : 'We currently are no longer accepting new insurance patients'} placement="top">
                        <Box>
                            <WildHealthButton 
                                size="large" 
                                id="add-insurance-button" 
                                onClick={() => handleAddInsurance()} 
                                disabled={isAddButtonLoading || !isInsuranceAvailable || !isEnabledInsuranceAdd}
                            >
                                <Box>
                                    {
                                        isAddButtonLoading && <CircularProgress size={18} className="wh-tw-text-primaryV" />
                                    }
                                </Box>
                                <Box ml={2}>
                                    <span>Add Insurance Card</span>
                                </Box>
                            </WildHealthButton>
                        </Box>
                    </WildHealthTooltip>
                </Box>
            </Box>
            <Box px={16}>
                {
                    (patientInsurances && patientInsurances.length) ?
                        <InsuranceCardComponent insurances={patientInsurances} patientId={patientId} displayName={displayName} isPayByCashLoading={isPayByCashLoading} /> :
                        <WildHealthPlaceHolder message="No Patients Insurance" />
                }
            </Box>
            <Box display="flex" justifyContent="space-between" mt={4} px={16}>
                <Box py={2}>
                    <span className="wh-tw-text-base wh-tw-font-medium">Payment Type</span>
                </Box>
            </Box>
            {!isSubscriptionLoading && <Box px={16} display="flex" alignItems="center">
                <InputLabel disabled={isPayByCashLoading || getIsInsuranceToggleDisabled()}>
                    Insurance
                </InputLabel>
                <Box display="flex" pl={2}>
                    <FormControlLabel
                        label="Cash Pay"
                        control={
                            <WildHealthSwitcher
                                disabled={isPayByCashLoading || getIsInsuranceToggleDisabled()}
                                checked={isPayByCash}
                                onChange={() => handleCashPay()}
                                name="editModeToggle" />
                        }
                    />
                </Box>
            </Box>}
            {
                (verificationHistory && !!getInsuranceHistory().length) &&
                <Box px={16}>
                    <Box py={2}>
                        <span className="wh-tw-text-base wh-tw-font-medium">History</span>
                    </Box>
                    <InsuranceCardHistoryComponent
                        insurances={getInsuranceHistory()}
                        displayNameHistory={displayNameHistory}
                        displayNumberCoverageHistory={displayNumberCoverageHistory} />
                </Box>
            }
            <CreateInsuranceModalComponent/>
            <ConfirmInsuranceDialog/>
        </Box>
    )
}
