import React from "react";
import { WildHealthButton } from '../../../common/components/wildHealthButton/WildHealthButton';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { colors } from '../../../common/constants/colors';

interface RegistrationNextButtonProps {
    skip: () => void;
    label?: string;
    icon?: boolean;
    disabled?: boolean;
}

export const RegistrationSkipButton: React.FC<RegistrationNextButtonProps> = (props: RegistrationNextButtonProps) => {
    const {
        skip,
        label,
        icon,
        disabled = false,
    } = props;

    return (
        <WildHealthButton
            id="registration-skip"
            fullWidth
            style={{
                color: colors.main,
                fontSize: 16,
                fontWeight: 500,
                borderRadius: 8,
                border: "2px solid " + colors.main,
                backgroundColor: "transparent"
            }}
            size="large"
            disabled={disabled}
            onClick={() => skip()}
        >
            <p>{label ?? 'Next Step'}</p>
            {icon && <ArrowForwardIcon />}
        </WildHealthButton>
    );
}

export const RegistrationSkipRedesignButton: React.FC<RegistrationNextButtonProps> = (props: RegistrationNextButtonProps) => {
    const {
        skip,
        label,
        icon,
        disabled = false,
    } = props;

    return (
        <WildHealthButton
            id="registration-skip"
            fullWidth
            style={{
                color: colors.navy,
                fontSize: 16,
                fontWeight: 500,
                borderRadius: 100,
                border: `1px solid ${colors.navy}`,
                backgroundColor: "transparent"
            }}
            size="large"
            disabled={disabled}
            onClick={() => skip()}
        >
            <p>{label ?? 'Next Step'}</p>
            {icon && <ArrowForwardIcon />}
        </WildHealthButton>
    );
}