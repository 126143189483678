import React from "react";
import {Paper} from "@material-ui/core";
import {TitlesService} from "../../../../constants/title.constants";
import {TabItem} from "../../../common/components/wildHealthTabControl/wildHealthTabControl.hooks";
import {authQuery} from "../../../auth/stores/auth";
import {PermissionType} from "../../../auth/models/auth.enums";
import {AuthenticatedLayoutComponent} from "../../../../components/authenticatedLayoutComponent/AuthenticatedLayoutComponent";
import {WildHealthTabControl} from "../../../common/components/wildHealthTabControl/WildHealthTabControl";
import {EmployeeAccountComponent} from "../../components/employeeAccountComponent/EmployeeAccountComponent";
import {BusinessAccountComponent} from "../../components/businessAccountComponent/BusinessAccountComponent";
import {PracticeAccountComponent} from "../../components/practiceAccountComponent/PracticeAccountComponent";
import { MenuItemTypes } from "../../../../models/menu.models";
import { ResetPasswordComponent } from "../../components/resetPasswordComponent/ResetPasswordComponent";

export const EmployeeAccountPage: React.FC = () => {
    const tabs: TabItem[] = [
        {
            title: 'Personal Information',
            content: <EmployeeAccountComponent />,
            keepMounted: true,
        },
        {
            title: 'Password',
            content: <ResetPasswordComponent />,
            keepMounted: true,
        }
    ];

    if (authQuery.hasPermission(PermissionType.ClinicAnalytics)) {
        tabs.push({
            title: 'Business',
            content: <BusinessAccountComponent/>,
            keepMounted: true,
        });
    }

    if (authQuery.hasPermission(PermissionType.ClinicAnalytics)) {
        tabs.push({
            title: 'Practice',
            content: <PracticeAccountComponent/>,
            keepMounted: true,
        });
    }

    return (
        <AuthenticatedLayoutComponent title={TitlesService.myAccountTitle} selectedMenuItem={MenuItemTypes.None}>
            <Paper elevation={0}>
                <WildHealthTabControl items={tabs} />
            </Paper>
        </AuthenticatedLayoutComponent>
    )
}