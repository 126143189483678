import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  TableSortLabel,
} from '@material-ui/core';
import React from 'react';
import { SearchComponent } from '../../../common/components/searchComponent/SearchComponent';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { WildHealthPlaceHolder } from "../../../common/components/wildHealthPlaceHolder/WildHealthPlaceHolder";
import { WildHealthTableCell } from "../../../common/components/wildHealthTable/WildHealthTableCell";
import { WildHealthTableRow } from "../../../common/components/wildHealthTable/WildHealthTableRow";
import { useFacade } from './messageForwardingComponent.hooks';
import commonUseStyles from '../../../common/styles/common.styles';
import clsx from 'clsx';
import { PagesNamesService } from '../../../../constants/pagesNames.constants';
import { pageSizes } from "../../../common/pagination/models/page-sizes";
import { PaginationComponent } from "../../../common/pagination/components/paginationComponent/PaginationComponent";
import { toCurrentTimeZone } from '../../../timezones/helpers/timezone';
import moment from 'moment';
import { colors } from '../../../common/constants/colors';


export const MessageForwardingComponent: React.FC = () => {
  const [
    {
      sortingDirection,
      isLoading,
      messages,
      totalCount,
      pageSize,
      selectedPage,
    },
    handleSearchSubmit,
    getAllAvailablePages,
    handlePageSizeChange,
    handlePageChange,
    handleSorting,
  ] = useFacade();
  const commonClasses = commonUseStyles();

  const displayName = (firstName = '', lastName = ''): string => {
    return `${firstName} ${lastName}`
  }

  const renderTable = () => {
    if (isLoading) {
      return <WildHealthLinearProgress />
    }

    if (!isLoading && (!messages || !messages.length)) {
      return <WildHealthPlaceHolder message="No current records found" />
    }

    return (
      <>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <WildHealthTableCell style={{ width: '15%' }} align="left">
                  Health Coach
                </WildHealthTableCell>
                <WildHealthTableCell style={{ width: '15%' }} align="left">
                  Forwarded Health Coach
                </WildHealthTableCell>
                <WildHealthTableCell style={{ width: '55%' }} align="left">
                  Away message
                </WildHealthTableCell>

                <WildHealthTableCell style={{ width: '15%' }} align="left" >
                  <TableSortLabel
                    id={`sort-message-forwarding`}
                    direction={sortingDirection}
                    onClick={() => handleSorting(sortingDirection)}
                  >
                    Start date
                  </TableSortLabel>
                </WildHealthTableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {messages.map((message, index) => (
                <WildHealthTableRow key={index}>
                  <WildHealthTableCell align="left">
                    {displayName(message.employeeFirstName, message.employeeLastName)}
                  </WildHealthTableCell>
                  <WildHealthTableCell align="left">
                    {displayName(message.forwardedEmployeeFirstName, message.forwardedEmployeeLastName)}
                  </WildHealthTableCell>
                  <WildHealthTableCell align="left">
                    <Tooltip placement="top" arrow title={`${message.awayMessage}`}>
                      <Box fontWeight='400' color={colors.gray1} className='text-ellipsis--2'>{message.awayMessage}</Box>
                    </Tooltip>
                  </WildHealthTableCell>
                  <WildHealthTableCell align="left">
                    {moment(toCurrentTimeZone(new Date(message.startedForwarding))).format('MM/DD/YYYY')}
                  </WildHealthTableCell>
                </WildHealthTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box py={1} px={2}>
          <PaginationComponent
            pageSizes={pageSizes}
            pageSize={pageSize}
            selectedPage={selectedPage}
            handlePageSizeChange={handlePageSizeChange}
            totalCount={totalCount}
            availablePages={getAllAvailablePages()}
            handlePageSelect={handlePageChange}
          />
        </Box>
      </>
    )
  }

  return (
    <Box py={2} px={10} width={1}>
      <Box mt={5} mb={2} display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Box className={clsx(commonClasses.size24, commonClasses.textMedium, commonClasses.colorBlack)}>{PagesNamesService.messageForwarding}</Box>
        </Box>
        <Box width="310px">
          <SearchComponent handleSearch={handleSearchSubmit} placeholder="Search by Name" />
        </Box>
      </Box>
      <Box className={commonClasses.bgWhiteMain}>
        {renderTable()}
      </Box>
    </Box>
  );
}
