import React from 'react';
import {useFacade} from "./unmatchedLabsBadgeComponent.hooks";
import {Badge} from "@material-ui/core";

export const UnmatchedLabsBadgeComponent: React.FC = () => {
    const [unassignedLab] = useFacade();

    return (
        <>
            {
                unassignedLab
                    ? <Badge variant="dot" color="secondary"/>
                    : <></>
            }
        </>
    );
}