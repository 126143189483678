import { useState } from 'react';

export interface TextFieldNoteState {
    isEdit: boolean;
}

export function useFacade(): [TextFieldNoteState, Function] {
    const [state, setState] = useState({
        isEdit: true,
    } as TextFieldNoteState);

    const handleEdit = () => {
        setState(state => ({ ...state, isEdit: !state.isEdit }));
    }

    return [state, handleEdit];
}