import { Box, Dialog, DialogActions, DialogContent, DialogContentText, Button, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { DialogTitle } from '../../../common/components/dialogTitle/DialogTitle';
import { WildHealthButton } from "../../../common/components/wildHealthButton/WildHealthButton";
import commonUseStyles from "../../../common/styles/common.styles";
import { useFacade } from "./cancelAppointmentDialogComponent.hooks";
import { useStyles } from "./cancelAppointmentDialogComponent.styles";
import { isIOS } from "react-device-detect";

export const CancelAppointmentDialogComponent: React.FC = () => {
  const [state, handleAnswer] = useFacade();

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleAnswer(true);
    }
  }

  const classes = useStyles();
  const commonClasses = commonUseStyles();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box onKeyPress={(e) => handleKeyPress(e)}>
      <Dialog
        fullWidth
        open={state.isOpen}
        onClose={() => handleAnswer(false)}
        className={isSmallScreen && isIOS && classes.dialog}
      >
        {!isSmallScreen && (
          <DialogTitle
            id="dialog-title"
            onClose={() => handleAnswer(false)}
            classes={{ root: classes.dialogTitle }}
          >
            <span className={clsx(commonClasses.size24, commonClasses.textMedium)}>{'Cancel Appointment'}</span>
          </DialogTitle>
        )}

        <DialogContent classes={{ root: classes.dialogContent }}>
          {
            isSmallScreen ? (
              <>
                <Box px={isIOS ? 4 : 3}>
                  <Box pb={isIOS ? 0.5 : "20px"} textAlign={isIOS && "center"}>
                    <span className={clsx(commonClasses.size20, commonClasses.textSemiBold, commonClasses.colorBlack)}>
                      {'Cancel Appointment'}
                    </span>
                  </Box>
                  <Box pb={isIOS ? "20px" : "40px"} textAlign={isIOS && "center"}>
                    <span className={clsx(commonClasses.size16, commonClasses.textRegular, commonClasses.colorBlack)}>
                      {'Are you sure you want to cancel the appointment?'}
                    </span>
                  </Box>
                </Box>
                <Box display="flex" alignItems="center" justifyContent={!isIOS && "flex-end"} className={isIOS && classes.actionSection}>
                  <Box flex={isIOS && 1} py="10px" className={isIOS && classes.discardBtn}>
                    <Button onClick={() => handleAnswer(false)}>
                      <span className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorMain)}>
                        {'Discard'}
                      </span>
                    </Button>
                  </Box>
                  <Box flex={isIOS && 1} py="10px" px={!isIOS && 2} textAlign="center">
                    <Button onClick={() => handleAnswer(true)}>
                      <span className={clsx(commonClasses.size14, commonClasses.textMedium, commonClasses.colorBad)}>
                        {'Cancel Appt'}
                      </span>
                    </Button>
                  </Box>
                </Box>
              </>
            ) : (
              <DialogContentText>
                <span className={commonClasses.colorBlack}>{'Are you sure you want to cancel the appointment?'}</span>
              </DialogContentText>
            )
          }
        </DialogContent>
        {!isSmallScreen && (
          <DialogActions classes={{ root: classes.dialogActions }}>
            <WildHealthButton id={`confirm-dialog-${state.cancelTitle}-${state.title}`} size='large' color='tertiary' onClick={() => handleAnswer(false)}>
              {'Discard'}
            </WildHealthButton>
            <WildHealthButton id={`confirm-dialog-${state.acceptTitle}-${state.title}`} size='large' color={'danger'} onClick={() => handleAnswer(true)}>
              {'Cancel Appointment'}
            </WildHealthButton>
          </DialogActions>
        )}
      </Dialog>
    </Box>
  );
}
