import { Box, Table, TableBody, TableContainer, TableHead, useMediaQuery, useTheme, } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { WildHealthHeaderComponent } from '../../../common/components/wildHealthHeaderComponent/WildHealthHeaderComponent';
import WildHealthLinearProgress from '../../../common/components/WildHealthLinearProgress';
import { CommonPlaceHolder } from '../../../common/components/wildHealthPlaceHolder/CommonPlaceHolder';
import { WildHealthTableRow } from '../../../common/components/wildHealthTable/WildHealthTableRow';
import { useFacade } from './appointmentsOrderComponent.hooks';
import { useStyles } from './appointmentsOrderComponent.styles';
import { toCurrentTimeZone } from '../../../timezones/helpers/timezone';
import commonUseStyles from "../../../common/styles/common.styles";
import clsx from 'clsx';
import { WildHealthTableCell } from '../../../common/components/wildHealthTable/WildHealthTableCell';
import { paymentStatusNames } from '../../../payment/models/payment.models';
import {PatientProductModel} from "../../../products/models/patientProducts.model";
import {ProductPaymentFlowType} from "../../../products/enums/paymentFlowType";

interface AppointmentsComponentProps {
    patientId?: number;
}

export const AppointmentsOrderComponent: React.FC<AppointmentsComponentProps> = (props: AppointmentsComponentProps) => {
    const { patientId } = props;
    const classes = useStyles();
    const commonClasses = commonUseStyles();

    const [
        {
            isLoading,
            orders,
        },
        columns,
        // handleGoToInvoice,
    ] = useFacade(patientId);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const title = 'Appointments';

    const emptyPlaceHolder = () => {
        return <span className="text-accent-secondary">(empty)</span>
    }

    const displayValue = (value: Date) => {
        return value ? moment(toCurrentTimeZone(value)).format("MM/DD/yyyy") : emptyPlaceHolder();
    }

    const displayStatus = (status: number, id: number) => {
        // ----- Disabled until we get access to OpenPM invoice links ------ //
        // if (status === PaymentStatus.PendingInsurancePayment || status === PaymentStatus.PendingOutstandingPayment) {
        //     return <Box display="flex" alignItems="center">
        //         <Box>{paymentStatusNames[status]}-</Box>
        //         <Link
        //             id={`link-to-invoice-${id}`}
        //             className={classes.invoiceLink}
        //             onClick={() => handleGoToInvoice(id)}
        //           >    
        //               Invoice
        //           </Link>
        //     </Box>
        // }
        return paymentStatusNames[status]
    }

    const displayPrice = (item: PatientProductModel) => {
        if (item.paymentFlow === ProductPaymentFlowType.Insurance)
        {
            return 'Billed Insurance';
        }

        return `${item.price}$`;
    }

    const mapOrders = () => {
        const rows = [];

        orders.forEach((item, index) => {
            rows.push(
                <WildHealthTableRow key={`appointments-order-row-${index}`}>
                    <WildHealthTableCell align="left">
                        <Box display='flex' alignItems='center'>
                            <Box>
                                Additional Provider Visit
                            </Box>
                        </Box>
                    </WildHealthTableCell>
                    <WildHealthTableCell align="left">
                        <Box py={1}>
                            {displayValue(item.createdAt)}
                        </Box>
                    </WildHealthTableCell>
                    <WildHealthTableCell align="left">
                    {displayPrice(item)}
                    </WildHealthTableCell>
                    <WildHealthTableCell align="left">
                    {displayStatus(item.paymentStatus, item.id)}
                    </WildHealthTableCell>
                </WildHealthTableRow>
            );
        });
        return rows;
    }

    const renderSmallContent = () => {
        if (isLoading) {
            return (
                <WildHealthLinearProgress />
            )
        }

        if (!orders.length) {
            return <Box height="calc(100vh - 225px)" className={commonClasses.bgWhiteMain}>
                <CommonPlaceHolder message={`No appointment orders yet.`} />
            </Box>
        }

        return (
            <Box className={classes.content}>
                {
                    orders.map((item, index) =>
                        <Box mb="10px" p={2} className={commonClasses.bgWhiteMain} key={index}>
                            <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                                <span className={clsx(commonClasses.textMedium, commonClasses.size14, commonClasses.colorGray2)}>{'Product Type'}</span>
                                <Box className={clsx(commonClasses.textRegular, commonClasses.size14, commonClasses.colorBlack)}>
                                Additional Provider Visit
                                </Box>
                            </Box>
                            <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                                <span className={clsx(commonClasses.textMedium, commonClasses.size14, commonClasses.colorGray2)}>{'Purchase Date'}</span>
                                <Box className={clsx(commonClasses.textRegular, commonClasses.size14, commonClasses.colorBlack)}>
                                    {displayValue(item.createdAt)}
                                </Box>
                            </Box>
                            <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                                <span className={clsx(commonClasses.textMedium, commonClasses.size14, commonClasses.colorGray2)}>{'Total price'}</span>
                                <Box className={clsx(commonClasses.textRegular, commonClasses.size14, commonClasses.colorBlack)}>
                                    {`${item.price}$`}
                                </Box>
                            </Box>
                            <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                                <span className={clsx(commonClasses.textMedium, commonClasses.size14, commonClasses.colorGray2)}>{'Payment Status'}</span>
                                <Box className={clsx(commonClasses.textRegular, commonClasses.size14, commonClasses.colorBlack)}>
                                    {displayStatus(item.paymentStatus, item.id)}
                                </Box>
                            </Box>
                        </Box>
                    )
                }
            </Box>
        )
    }

    const renderContent = () => {
        if (isLoading) {
            return (
                <WildHealthLinearProgress />
            )
        }

        if (!orders.length) {
            return <CommonPlaceHolder message={`You have no appointments listed at the moment.`} />
        }

        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <WildHealthTableRow>
                            {
                                columns.map((item, index) =>
                                    <WildHealthTableCell key={`appointments-order-cell-${index}`} align="left">
                                        {item}
                                    </WildHealthTableCell>
                                )
                            }
                        </WildHealthTableRow>
                    </TableHead>
                    <TableBody>
                        {mapOrders()}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const header = (
        <Box display='flex' justifyContent='space-between'>
            <span>
                {title}
            </span>
        </Box>
    )

    return isSmallScreen ? renderSmallContent() : (
        <WildHealthHeaderComponent title={header}>
            <Box className={classes.content}>
                {renderContent()}
            </Box>
        </WildHealthHeaderComponent>
    );
}