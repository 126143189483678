import React from 'react';
import { Helmet } from "react-helmet";
import { Paper, Box } from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import clsx from 'clsx';
import { useFacade } from "./getStartedPage.hooks";
import { useStyles } from './GetStartedPage.styles';
import { LayoutComponent } from '../../../../components/layoutComponent/LayoutComponent';
import { TitlesService } from "../../../../constants/title.constants";
import { WaitListDialogComponent } from "../../components/waitListDialogComponent/WaitListDialogComponent";
import commonUseStyles from '../../../common/styles/common.styles';
import HeaderCouponApplied from "../../components/finishCheckoutRedesignComponent/headerCouponApplied/HeaderCouponApplied";
import HeaderProgressBar from "../../components/finishCheckoutRedesignComponent/headerProgressBar/HeaderProgressBar";
import WildHealthLinearProgress from "../../../common/components/WildHealthLinearProgress";
import { anyFlags } from '../../../common/components/featureFlags/featureFlags';

export const GetStartedPage: React.FC = (props: any) => {
    const employerKey = props?.match?.params?.employerKey ?? new URLSearchParams(props.location.search).get('employerKey');

    const [
        page,
        { plans, coupon, progress, progressBarOpened, bannerOpened, bannerHeight, isNewAccountId },
        isOnboardingRedesign,
        prevScrollY,
        handleClose
    ] = useFacade(employerKey);

    const featureFlags = useFlags();
    const classes = useStyles();
    const commonClasses = commonUseStyles();
    
    if (!plans || !plans.length || !anyFlags(featureFlags)) {
        return <WildHealthLinearProgress />
    }

    return (
        <Box>
            {isNewAccountId > 0 &&
                <Helmet>
                    {/* <!-- CONVERSION TRACKING --> */}
                    {/* <script src="./ConversionTracking.js" type="text/javascript" /> */}
                    {/* <!-- /CONVERSION TRACKING --> */}
                </Helmet>
            }
            <div ref={prevScrollY}>
                <HeaderCouponApplied coupon={coupon} handleClose={handleClose} bannerOpened={bannerOpened} />
                <HeaderProgressBar progress={progress} isOpened={progressBarOpened} />
            </div>
            <LayoutComponent title={TitlesService.getStartedTitle} employerKey={Boolean(employerKey)} redesign={isOnboardingRedesign} topBannerHeight={bannerHeight}>
                <Paper
                    className={clsx(classes.content, commonClasses.whWhiteForm)}
                    variant="elevation"
                    square
                >
                    {page}
                </Paper>
                <WaitListDialogComponent />
            </LayoutComponent>
        </Box>
    )
}
