import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../../../common/constants/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingBottom: 30,
            fontSize: 14,
            width: 460
        },
        content: {
            fontSize: 14,
            paddingLeft: 20,
            paddingRight: 20,
            width: 460
        },
        timeDivider: {
            paddingLeft: 8,
            paddingRight: 8,
            color: colors.gray2
        },
        avatar: {
            width: 34,
            height: 34
        },
        notShowHeader: {
            backgroundColor: colors.bad,
        },
    })
);