import React from 'react';
import { ManagePlanComponent } from '../../../account/components/managePlan/ManagePlanComponent';
import { ResetPasswordComponent } from '../../../account/components/resetPasswordComponent/ResetPasswordComponent';
import { PermissionType, UserType } from "../../../auth/models/auth.enums";
import { isElementEnabled } from "../../../common/components/ProtectedElement";
import { TabItem } from '../../../common/components/wildHealthTabControl/wildHealthTabControl.hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isFeatureFlag } from '../../../common/components/featureFlags/featureFlags';
import { FeatureFlag } from '../../../common/components/featureFlags/featureFlags.models';
import { PatientInformationComponent } from '../patientInformationComponent/PatientInformationComponent';
import { PatientInformationRedesignComponent } from '../patientInformationRedesignComponent/patientInformationRedesignComponent/PatientInformationRedesignComponent';
import {
    InsuranceVerificationComponent
} from "../../../insurance/components/insuranceVerificationComponent/InsuranceVerificationComponent";
import {
    InsuranceInformationComponent
} from "../../../insurance/components/insuranceInformationComponent/InsuranceInformationComponent";

export function useFacade(patientId: number, patientIntakeId: string): [TabItem[]] {
    const featureFlags = useFlags();
    const isNewPatientProfile = isFeatureFlag(featureFlags, FeatureFlag.NewPatientProfile);
    
    const tabItems: TabItem[] = [
        {
            title: 'Personal Information',
            content: isNewPatientProfile ? <PatientInformationRedesignComponent patientId={patientId} /> : <PatientInformationComponent patientId={patientId} />,
            keepMounted: true,
        },

    ];

    if (isElementEnabled([PermissionType.ManageInsurance], UserType.Employee)) {
        tabItems.push({
            title: 'Insurance Information',
            content: <InsuranceInformationComponent patientId={patientId} />,
        });

        tabItems.push({
            title: 'Insurance Verification',
            content: <InsuranceVerificationComponent patientId={patientId} />,
        });
    }

    tabItems.push({
        title: 'Care Team',
        content: <></>,
        keepMounted: true,
        disabled: true,
    },
        {
            title: 'Membership',
            content: <ManagePlanComponent patientId={patientId} patientIntakeId={patientIntakeId} />,
            keepMounted: true,
        });

    if (isElementEnabled([PermissionType.Coaching, PermissionType.ManagePatients], UserType.Employee)) {
        tabItems.push({
            title: 'Password',
            content: <ResetPasswordComponent patientId={patientId} />,
        });
    }

    return [tabItems];
}