import {InviteCodeState, inviteCodeStore, InviteCodeStore} from './inviteCode.store';
import {Query} from '@datorama/akita';

/**
 * Provides invite code queries
 */
export class InviteCodeQuery extends Query<InviteCodeState> {
    inviteCode$ = this.select(state => state.inviteCode);

    constructor(protected store: InviteCodeStore) {
        super(store);
    }

    getInviteCode(): string {
        return this.getValue().inviteCode;
    }

}

export const inviteCodeQuery = new InviteCodeQuery(inviteCodeStore);