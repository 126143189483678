import {
  LabInputNotificationType,
  LabRangeModel,
  ValueInputModel,
} from "../../models/input.models";
import { VitalModel } from "../../../vital/models/vital.model";
import React from "react";
import {
  Box,
  Divider,
  Grid,
  Popover,
  Tooltip,
  useTheme,
  useMediaQuery,
  IconButton,
} from "@material-ui/core";
import moment from "moment";
import warning from "@img/icons/warning.svg";
import { renderLabValuesGraph } from "../../helpers/render-lab-values-graph";
import commonUseStyles from "../../../common/styles/common.styles";
import clsx from "clsx";
import {
  VitalsNames,
  vitalsNormalValues,
} from "../../../vital/validators/vital.validator";
import CloseIcon from "@material-ui/icons/Close";
import { useStyles } from "./ValuesDetails.style";
import { isIOS } from "react-device-detect";
interface ValuesDetailsComponentProps {
  isOpen: boolean;
  id: number;
  title?: string;
  anchorEl: any;
  values: ValueInputModel[];
  vitalValues: VitalModel[];
  range: LabRangeModel;
  handleClose: (id: number) => void;
}

export const ValuesDetailsComponentForBloodPressure: React.FC<
  ValuesDetailsComponentProps
> = (props: ValuesDetailsComponentProps) => {
  const { isOpen, id, title, anchorEl, vitalValues, handleClose } = props;

  const commonClasses = commonUseStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();

  const dateEquals = (date1: Date, date2: Date) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate() &&
      date1.getHours() === date2.getHours() &&
      date1.getMinutes() === date2.getMinutes() &&
      date1.getSeconds() === date2.getSeconds()
    );
  };

  const initializedVitalValues = vitalValues.filter((x) =>
    x.values.every((v) => v.isInitialized)
  );

  const systolicValues = initializedVitalValues.find(
    (x) => x.name === "SystolicBloodPressure"
  )?.values;
  const diastolicValues = initializedVitalValues.find(
    (x) => x.name === "DiastolicBloodPressure"
  )?.values;

  if (!systolicValues || !diastolicValues) {
    return <></>;
  }

  const resultValues = systolicValues.map((x) => ({
    ...x,
    diastolicValue: diastolicValues.find((v) =>
      dateEquals(new Date(x.date), new Date(v.date))
    ),
  }));

  if (!resultValues.length) {
    return <></>;
  }

  const outOfNorm = (value: ValueInputModel) => {
    return value.notification?.notificationType === LabInputNotificationType.Error;
  };

  return (
    <Popover
      style={{
        pointerEvents: "none",
      }}
      PaperProps={{
        style: isSmallScreen
          ? {
            width: "100vw",
            maxWidth: "100vw",
            borderRadius: isIOS ? "10px 10px 0 0" : "0",
            height: "50vh",
          }
          : { width: "500px" },
      }}
      marginThreshold={isSmallScreen ? 0 : 16}
      id='mouse-over-popover'
      open={isOpen}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      onClose={() => handleClose(id)}
      disableRestoreFocus
      BackdropProps={{ invisible: isSmallScreen ? false : true }}
    >
      <Box p={2}>
        <Box className={classes.title}>
          {title}
          {isSmallScreen ? (
            <IconButton
              aria-label='close'
              onClick={() => handleClose(id)}
              className={classes.closeIcon}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </Box>
        <Box my={2} className={commonClasses.size14}>
          <Grid container justify="space-between">
            {resultValues.map((value, index) => {
              const systolicOutOfNorm = outOfNorm(value);
              const diastolicOutOfNorm = outOfNorm(value.diastolicValue);

              return (
                <Grid item md={6} sm={12} key={index}>
                  <Grid container>
                    <Grid item md={6} sm={12}>
                      <span className={commonClasses.colorGray1}>
                        {moment(value.date).format("MM/DD/YYYY")}:
                      </span>
                    </Grid>
                    <Grid item md={6} sm={12}>
                      <Box display='flex' alignItems='center' justifyContent='flex-start'>
                        <Box className={commonClasses.textMedium}>
                          <span
                            className={clsx({
                              [commonClasses.colorBad]: systolicOutOfNorm,
                            })}
                          >
                            {value.value}
                          </span>
                          /
                          <span
                            className={clsx({
                              [commonClasses.colorBad]: diastolicOutOfNorm,
                            })}
                          >
                            {value.diastolicValue.value}
                          </span>
                        </Box>
                        {(systolicOutOfNorm || diastolicOutOfNorm) && (
                          <Box ml={1} mt='1.5px'>
                            <Tooltip title={value.notification?.message}>
                              <img src={warning} alt='img' />
                            </Tooltip>
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Box>
        {!isSmallScreen && <Divider className={commonClasses.colorSeparators} />}
        <Box>
          <Box mt={2}>
            <span className={commonClasses.textMedium}>Full Graph View</span>
          </Box>
          <Box mt={2} fontSize={13}>
            <span className={commonClasses.colorGray1}>Systolic Blood Pressure</span>
          </Box>
          <Box justifyContent='center' mt={2}>
            {renderLabValuesGraph(
              systolicValues,
              vitalsNormalValues.get(VitalsNames.SystolicBloodPressure),
              100,
              450,
              true
            )}
          </Box>
          <Box mt={2} fontSize={13}>
            <span className={commonClasses.colorGray1}>Diastolic Blood Pressure</span>
          </Box>
          <Box justifyContent='center' mt={2}>
            {renderLabValuesGraph(
              diastolicValues,
              vitalsNormalValues.get(VitalsNames.DiastolicBloodPressure),
              100,
              450,
              true
            )}
          </Box>
        </Box>
      </Box>
    </Popover>
  );
};
