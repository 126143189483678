import React from 'react';
import { ReactComponent as AllPatients } from '@img/icons/AllPatients.svg';
import { IconProps } from './IconProps';
import { colors } from '../../modules/common/constants/colors';

const AllPatientsIcon: React.FC<IconProps> = (props: IconProps) => {
    const { isSelected } = props;

    return (
        <AllPatients fill={isSelected ? colors.main : colors.gray2} />
    )
}

export default AllPatientsIcon;