import React from "react";
import {Box} from "@material-ui/core";
import {useFacade} from "./manageRxntButtonComponent.hooks";
import {SecondaryButton} from "../../../common/components/wildHealthButton/SecondaryButton";
import AddIcon from "@material-ui/icons/Add";

interface ManageRxntButtonComponentProps {
    patientId: number;
    title?: string
}

export const ManageRxntButtonComponent: React.FC<ManageRxntButtonComponentProps> = (props: ManageRxntButtonComponentProps) => {
    const {
        patientId,
        title
    } = props;

    const [
        handleLoginToRxNT
    ] = useFacade(patientId);

    return (
        <Box>
            <SecondaryButton id={`login-rxnt-button`} size="medium" onClick={() => handleLoginToRxNT(patientId)}>
                <AddIcon/> {title ?? 'Manage RxNT'}
            </SecondaryButton>
        </Box>
    )
}