import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      '& .MuiDialog-paper': {
        borderRadius: 20,
      },
      [theme.breakpoints.down("xs")]: {
        '& .MuiDialog-paper': {
          borderRadius: '28px 28px 0 0',
          margin: 0,
          marginTop: 'auto',
          width: '100%'
        },
      },
    },
    dialogContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: 'center',
      maxWidth: 350,
      paddingTop: 30,
      [theme.breakpoints.down("xs")]: {
        marginRight: 0,
        marginLeft: 0,
        maxWidth: '100%',
      },
    },
  })
);
