export const appointmentColors = {
    purple: '#AB5DBA',
    purpleMid: '#EBD9F1',
    purpleLight: '#F9EEFD',

    blue: '#5BAED4',
    blueMid: '#D6EBF6',
    blueLight: '#EFF7FB',

    strongBlue: '#1F72A2',
    strongBlueMid: '#E3EDF2', 
    strongBlueLight: '#F1F8FC',

    green: '#8ABD7D',
    greenMid: '#DAF0D4',
    greenLight: '#F0FAED',

    orange: '#FFA125',
    orandeMid: '#F6E4D8',
    orangeLight: '#FFF1E7',

    yellow: '#FDE166',
    yellowMid: '#F7F1D6',
    yellowLight: '#FEFAE6',

    red: '#EB4B4F',
    redMid: '#F8EAEA',
    redLight: '#FFF6F6'
};